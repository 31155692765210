import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const backendEntityConfigurationModel = ({ values }) => {
  const [
    mfaEnabled,
    linkedTo,
    linkedType,
  ] = getMany(values, [
    'mfaEnabled',
    'linkedTo',
    'linkedType',
  ])

  return removeUndefined({
    linked_type: linkedType,
    linked_to: linkedTo,
    mfa_enabled: mfaEnabled,
  })
}

export default backendEntityConfigurationModel
