import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import createAction from 'utilities/actions/createAction'
import { DISABLE_API_KEY } from 'constants/amplitudeConstants'
import { DELETE_API_KEY_F_REQUEST } from 'constants/flowConstants'

const deleteAPIKeyRequest = ({
  apiKeyId = '',
  credentials,
}) => createAction({
  type: DELETE_API_KEY_F_REQUEST,
  id: apiKeyId,
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(DISABLE_API_KEY, {
      apiKeyId,
    }),
  },
})

export default deleteAPIKeyRequest
