import paymentsAPI from 'utilities/api/paymentsAPI'
import { FEE_PROFILES } from 'constants/apiConstants'

const postFeeProfileAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: FEE_PROFILES,
    service: FEE_PROFILES,
  })
}

export default postFeeProfileAPI
