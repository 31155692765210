import reduce from 'lodash/reduce'
import set from 'lodash/set'

// converts form tag values to resource tag object, generally used in a form submit function
// ex: [{ name: tagName, value: tagValue }] => { tagName: tagValue }
export const convertTagValues = ({ tags = [] }) => {
  return reduce(tags, (newTagsObj, tag) => {
    const { name, value } = tag
    set(newTagsObj, name, value)

    return newTagsObj
  }, {})
}
