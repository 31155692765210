import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResendUnacceptedEvents from './ResendUnacceptedEvents'
import { getWebhookSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchWebhookRequest from 'utilities/actions/patch/patchWebhookRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { RESEND_WEBHOOK_UNACCEPTED_EVENTS } from 'constants/amplitudeConstants'

import {
  RESEND_UNACCEPTED_EVENTS_ERROR_MSG,
  RESEND_UNACCEPTED_EVENTS_SUCCESS_MSG,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    webhookId,
    enabled,
  ] = getMany(props, [
    'webhookId',
    'enabled',
  ])

  const webhook = getWebhookSelector(state, webhookId)

  return {
    credentials,
    webhookId,
    webhook,
    enabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requeueWebhookAction: ({ credentials, webhookId }) => dispatch(patchWebhookRequest({
      credentials,
      webhookId,
      values: {
        retry_unaccepted_events: true,
      },
      amplitudeEventName: RESEND_WEBHOOK_UNACCEPTED_EVENTS,
      customSuccessMessage: RESEND_UNACCEPTED_EVENTS_SUCCESS_MSG,
      customErrorMessage: RESEND_UNACCEPTED_EVENTS_ERROR_MSG,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ResendUnacceptedEventsC extends Component {
  resendUnacceptedEvents = () => {
    const {
      credentials,
      webhookId,
      closeModal,
      requeueWebhookAction,
    } = this.props

    requeueWebhookAction({ credentials, webhookId })
    closeModal()
  }

  render() {
    return (
      <ResendUnacceptedEvents
        {...this.props}
        resendUnacceptedEvents={this.resendUnacceptedEvents}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendUnacceptedEventsC)
