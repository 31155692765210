import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_PROFILE } from 'constants/apiConstants'

const getUnderwritingProfileAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: UNDERWRITING_PROFILE({ id }),
    meta,
  })
}

export default getUnderwritingProfileAPI
