import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import formatMoney from 'utilities/formatters/formatMoney'
import getMany from 'utilities/get/getMany'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import map from 'lodash/map'
import merge from 'lodash/merge'

import {
  SUBSCRIPTION_TYPE,
  SCHEDULE,
  TAGS,
} from 'constants/language/languageConstants'

const createSubscriptionScheduleSectionData = ({ subscriptionSchedule, subscriptionAmounts }) => {
  const subscriptionScheduleAmounts = merge({}, subscriptionSchedule, { amounts: subscriptionAmounts })

  const [
    periodOffset,
    fixedTimeIntervalOffset,
    subscriptionType,
  ] = getMany(subscriptionSchedule, [
    'periodOffset',
    'fixedTimeIntervalOffset',
    'subscriptionType',
  ])

  const sectionFields = [
    {
      label: SUBSCRIPTION_TYPE,
      value: 'subscriptionType',
      formatter: ({ value }) => subscriptionScheduleTypeMapping[value],
    },
    {
      label: SCHEDULE,
      formatter: () => convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset }),
    },
    {
      label: TAGS,
      value: 'tags',
      formatter: ({ value }) => TagsDisplaySection({
        data: map(value, (tagValue, label) => ({ value: tagValue, label })),
        emptyMessage: '-',
      }),
    },
  ].concat(map((subscriptionAmounts), ({ id, amountNickname }) => {
    return {
      label: `Amount - ${amountNickname}`,
      value: `amounts.${id}.amount`,
      formatter: ({ value }) => formatMoney({ amount: value }),
    }
  }))

  return {
    sectionData: [subscriptionScheduleAmounts],
    sectionTitle: 'titleText',
    sectionName: 'id',
    sectionFields,
  }
}

export default createSubscriptionScheduleSectionData
