import './BusinessLocationTabsS.scss'
import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import BusinessLocationMaps from './BusinessLocationMaps'
import GooglePlaceDetails from 'components/Customer/Shared/Map/GooglePlaceDetails'
import formatAddress from 'utilities/formatters/formatAddress'
import get from 'lodash/get'

import {
  MAPS,
  GOOGLE_PLACE_DETAILS,
} from 'constants/language/languageConstants'

class BusinessLocationTabs extends Component {
  render() {
    const { doingBusinessAs, businessName, businessAddress } = this.props
    const businessNameMapQuery = `${doingBusinessAs || businessName}, ${get(businessAddress, 'city')}, ${get(businessAddress, 'region')}`
    const businessAddressMapQuery = formatAddress({ address: businessAddress })

    const tabs = [
      {
        id: 'maps',
        name: MAPS,
        component: BusinessLocationMaps,
        props: {
          businessNameMapQuery,
          businessAddressMapQuery,
        },
      },
      {
        id: 'google-place-details',
        name: GOOGLE_PLACE_DETAILS,
        component: GooglePlaceDetails,
        props: {
          address: businessNameMapQuery,
        },
      },
    ]

    return (
      <div className='BusinessLocationTabs'>
        <TabsC
          tabs={tabs}
          persistQueries={['subjectId', 'entityId', 'entityType']}
        />
      </div>
    )
  }
}

export default BusinessLocationTabs
