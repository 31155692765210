import './PaymentLinkConfigurationsFormS.scss'
import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Loader from 'components/Shared/Loader/Loader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import ButtonGroup from 'components/Shared/ButtonGroup/ButtonGroup'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import GuestPaymentLinkFormC from 'components/Customer/Forms/GuestPaymentLinkForm/GuestPaymentLinkFormC'
import GuestPayoutLinkFormC from 'components/Customer/Forms/GuestPayoutLinkForm/GuestPayoutLinkFormC'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import { PAYMENT_LINK_CONFIGURATION_FORM } from 'constants/formConstants'

import {
  SAVE,
  RESET_ALL,
  LOGO,
  PREVIEW,
  MOBILE,
  DESKTOP,
  BRAND_COLORS,
  HEADER_COLOR,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  PAYMENT_LINKS,
  PAYMENT_LINKS_BRANDING_LOGO_LABEL,
  PAYMENT_LINKS_BRANDING_COLORS_INSTRUCTIONS,
  PAYMENT_LINKS_BRANDING_SUBTITLE,
  PAYMENT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS,
  LIVE_PREVIEW,
  LIVE_PREVIEW_DESCRIPTION_FOR_PAYMENT_LINKS,
  PAYMENT_LINK_BRANDING_HEADER_TOOLTIP,
  PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP,
  PAYMENT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP,
  PAYOUT_LINKS,
  PAYOUT_LINKS_BRANDING_SUBTITLE,
  PAYOUT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS,
  PAYOUT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP,
  PAYOUT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP,
  PAYOUT_LINKS_BRANDING_COLORS_INSTRUCTIONS,
} from 'constants/language/languageConstants'

const PaymentLinkConfigurationsForm = ({
  handleSubmit = () => {},
  isFetching = true,
  updateColor = () => {},
  handleClick = () => {},
  handleClose = () => {},
  headerColor = '',
  buttonBackgroundColor = '',
  buttonTextColor = '',
  displayHeaderColorPicker = false,
  displayButtonBackgroundColorPicker = false,
  displayButtonTextColorPicker = false,
  config,
  instructions,
  resetForm = () => {},
  previewPaymentLink = {},
  guestPaymentLinkComponentKey = '',
  initialSidebarBackgroundColor = '',
  pristine = false,
  isPayoutLink,
}) => {
  const [activePreviewMode, setActivePreviewMode] = useState('mobile')

  return (
    <form className='PaymentLinkConfigurationsForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <div className='content-container flex'>
        <div className='form-content'>
          <div className='form-holder'>
            <div className='payment-links-branding-overview'>
              <h3>{isPayoutLink ? PAYOUT_LINKS : PAYMENT_LINKS}</h3>
              <div className='subtitle p-1 secondary'>{isPayoutLink ? PAYOUT_LINKS_BRANDING_SUBTITLE : PAYMENT_LINKS_BRANDING_SUBTITLE}</div>
              <div>{isPayoutLink ? PAYOUT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS : PAYMENT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS}</div>
            </div>

            {isFetching && <Loader />}

            {!isFetching && (
              <>
                <div className='logo-section'>
                  <h6>{LOGO}</h6>

                  <Field
                    name='paymentLinkLogo'
                    label={PAYMENT_LINKS_BRANDING_LOGO_LABEL}
                    component={FileUploader}
                    config={config}
                    instructions={instructions}
                  />
                </div>

                <div className='brand-colors-section'>
                  <h6>{BRAND_COLORS}</h6>
                  <div className='p-2 branding-colors-instructions'>{isPayoutLink ? PAYOUT_LINKS_BRANDING_COLORS_INSTRUCTIONS : PAYMENT_LINKS_BRANDING_COLORS_INSTRUCTIONS}</div>

                  <Field
                    name='showLivePreview'
                    className='live-preview-toggle'
                    label={LIVE_PREVIEW}
                    helperText={LIVE_PREVIEW_DESCRIPTION_FOR_PAYMENT_LINKS}
                    component={ToggleSwitchC}
                    formName={PAYMENT_LINK_CONFIGURATION_FORM}
                  />

                  <div className='flex items-center'>
                    <Field
                      name='paymentLinkHeaderColor'
                      label={HEADER_COLOR}
                      placeholder={initialSidebarBackgroundColor}
                      component={InputField}
                      tooltip={PAYMENT_LINK_BRANDING_HEADER_TOOLTIP}
                      tooltipPosition='right'
                    />

                    <div>
                      <div
                        className='color-block'
                        style={{ backgroundColor: headerColor }}
                        onClick={() => handleClick('displayHeaderColorPicker')}
                      />
                      { displayHeaderColorPicker ? (
                        <div className='color-picker-popup'>
                          <div className='color-picker-cover' onClick={() => handleClose('displayHeaderColorPicker')} />
                          <ChromePicker
                            color={headerColor}
                            onChange={(color) => { updateColor('paymentLinkHeaderColor', color) }}
                            disableAlpha
                          />
                        </div>
                      ) : null }
                    </div>
                  </div>

                  <div className='flex items-center'>
                    <Field
                      name='paymentLinkButtonBackgroundColor'
                      label={BUTTON_BACKGROUND_COLOR}
                      placeholder='#0B5DBC'
                      component={InputField}
                      tooltip={isPayoutLink ? PAYOUT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP : PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP}
                      tooltipPosition='right'
                    />

                    <div>
                      <div
                        className='color-block'
                        style={{ backgroundColor: buttonBackgroundColor }}
                        onClick={() => handleClick('displayButtonBackgroundColorPicker')}
                      />
                      { displayButtonBackgroundColorPicker ? (
                        <div className='color-picker-popup'>
                          <div className='color-picker-cover' onClick={() => handleClose('displayButtonBackgroundColorPicker')} />
                          <ChromePicker
                            color={buttonBackgroundColor}
                            onChange={(color) => { updateColor('paymentLinkButtonBackgroundColor', color) }}
                            disableAlpha
                          />
                        </div>
                      ) : null }
                    </div>
                  </div>

                  <div className='flex items-center'>
                    <Field
                      name='paymentLinkButtonTextColor'
                      label={BUTTON_TEXT_COLOR}
                      placeholder='#FFFFFF'
                      component={InputField}
                      tooltip={isPayoutLink ? PAYOUT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP : PAYMENT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP}
                      tooltipPosition='right'
                    />

                    <div>
                      <div
                        className='color-block'
                        style={{ backgroundColor: buttonTextColor }}
                        onClick={() => handleClick('displayButtonTextColorPicker')}
                      />
                      { displayButtonTextColorPicker ? (
                        <div className='color-picker-popup'>
                          <div className='color-picker-cover' onClick={() => handleClose('displayButtonTextColorPicker')} />
                          <ChromePicker
                            color={buttonTextColor}
                            onChange={(color) => { updateColor('paymentLinkButtonTextColor', color) }}
                            disableAlpha
                          />
                        </div>
                      ) : null }
                    </div>
                  </div>
                </div>

                <div className='buttons flex'>
                  <Button onClick={resetForm} label={RESET_ALL} variant='secondary' className='reset-all-button' />
                  <Button type='submit' disabled={pristine} label={SAVE} />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='preview flex columm'>
          <div className='preview-header flex space-between'>
            <div className='flex column'>
              <h5>{PREVIEW}</h5>
            </div>

            <ButtonGroup
              options={[
                { label: MOBILE, onClick: () => setActivePreviewMode('mobile') },
                { label: DESKTOP, onClick: () => setActivePreviewMode('desktop') },
              ]}
            />
          </div>

          <div className='preview-container flex flex-grow items-center'>
            { activePreviewMode === 'mobile' && (
              <div className='mobile-container'>
                <div className='mobile-line line-1' />
                <div className='mobile-line line-2' />
                <div className='mobile-line line-3' />
                <div className='mobile-line line-4' />

                <div className='mobile-content-wrapper'>
                  {isPayoutLink ? (
                    <GuestPayoutLinkFormC
                      payoutLink={previewPaymentLink}
                      previewMode='mobile'
                      brandColor={headerColor}
                      accentColor={buttonBackgroundColor}
                      buttonFontColor={buttonTextColor}
                      key={guestPaymentLinkComponentKey}
                      noValidation
                    />
                  ) : (
                    <GuestPaymentLinkFormC
                      paymentLink={previewPaymentLink}
                      previewMode='mobile'
                      brandColor={headerColor}
                      accentColor={buttonBackgroundColor}
                      buttonFontColor={buttonTextColor}
                      key={guestPaymentLinkComponentKey}
                      noValidation
                    />
                  )}
                </div>
              </div>
            )}

            { activePreviewMode === 'desktop' && (
              <div className='desktop-size-container'>
                <div className='desktop-container'>
                  <div className='container-1'>
                    <div className='desktop-content-wrapper'>
                      {isPayoutLink ? (
                        <GuestPayoutLinkFormC
                          payoutLink={previewPaymentLink}
                          previewMode='desktop'
                          key={guestPaymentLinkComponentKey}
                          noValidation
                        />
                      ) : (
                        <GuestPaymentLinkFormC
                          paymentLink={previewPaymentLink}
                          previewMode='desktop'
                          key={guestPaymentLinkComponentKey}
                          noValidation
                        />
                      )}
                    </div>
                  </div>
                  <div className='container-2' />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

PaymentLinkConfigurationsForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  updateColor: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  headerColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  displayHeaderColorPicker: PropTypes.bool,
  displayButtonBackgroundColorPicker: PropTypes.bool,
  displayButtonTextColorPicker: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
  resetForm: PropTypes.func,
  initialSidebarBackgroundColor: PropTypes.string,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: PAYMENT_LINK_CONFIGURATION_FORM,
})(PaymentLinkConfigurationsForm)
