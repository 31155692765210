import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import Subscriptions from './Subscriptions'
import showModalAction from 'utilities/actions/showModalAction'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_A_SUBSCRIPTION } from 'constants/language/languageConstants'
import { SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL } from 'constants/modalConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import filter from 'lodash/filter'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import {
  DATE_QUICK_FILTER,
  SUBSCRIPTION_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  SUBSCRIPTIONS_AMOUNT,
  SUBSCRIPTION_PLAN_ID,
  ID,
  MERCHANT_ACCOUNT_LINKED_TO,
} from 'constants/filterConstants'

import {
  CREATE_SUBSCRIPTION_PATH,
  SUBSCRIPTIONS_PATH,
} from 'constants/pathConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

const allowedFilters = [
  SUBSCRIPTIONS_AMOUNT(),
  ID,
  MERCHANT_ACCOUNT_LINKED_TO,
  SUBSCRIPTION_PLAN_ID,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  SUBSCRIPTION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const environment = get(credentials, 'environment')
  const subscriptionsPath = SUBSCRIPTIONS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const hiddenFilters = get(props, 'hiddenFilters', [])
  const mergedFilters = filter(allowedFilters, ({ field }) => !hiddenFilters.includes(field))
  const subscriptionPlanId = get(props, 'subscriptionPlanId')
  const subscriptionPlanMerchantId = get(props, 'merchantId')
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const createPaymentEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const isRolePlatformCredential = isRolePlatform({ credentials })

  return {
    initialQueries: mergedQueries,
    isFetching,
    subscriptionsPath,
    credentialId,
    credentials,
    quickFilters,
    allowedFilters: mergedFilters,
    initialFilters: createdLast3MonthQueries(),
    subscriptionPlanId,
    subscriptionPlanMerchantId,
    applicationId,
    createPaymentEnabledApplicationMerchants,
    isRolePlatformCredential,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSelectSenderForCreatePaymentModal: (modalProps) => dispatch(showModalAction({
      modalType: SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
      className: 'modal-md no-pad overflow-visible',
      modalProps:
        { canCloseModal: false,
          isCreateSubscriptionFlow: true,
        } })),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

class SubscriptionsC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId,
      credentials,
      getApplicationMerchants,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      credentialId,
      createPaymentEnabledApplicationMerchants,
      subscriptionPlanId,
      subscriptionPlanMerchantId,
      isRolePlatformCredential,
    } = this.props

    const hasOneMerchant = size(createPaymentEnabledApplicationMerchants) === 1
    const merchantId = hasOneMerchant ? get(createPaymentEnabledApplicationMerchants, '[0].id') : subscriptionPlanMerchantId

    const queries = removeUndefined({
      merchantId,
      subscriptionPlanId,
    })

    const actions = [
      {
        label: CREATE_A_SUBSCRIPTION,
        action: () => goToPath({
          pathname: CREATE_SUBSCRIPTION_PATH({ credentialId }),
          queries,
        }),
        className: 'create-subscription-button',
        condition: !isRolePlatformCredential,
      },
    ]

    return (
      <Subscriptions
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsC)
