import Refund from './Refund'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import { splitRefundsColumnDescriptors } from 'components/Customer/Pages/Payment/columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import showModalAction from 'utilities/actions/showModalAction'
import formatDate from 'utilities/formatters/formatDate'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import displayFundingDetailsValue from 'utilities/display/displayFundingDetailsValue'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getSplitTransfersRequest from 'utilities/actions/get/getSplitTransfersRequest'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import { FAILED } from 'constants/transferConstants'
import { APPROVED } from 'constants/settlementConstants'
import { NON_MERCHANT_ROLES } from 'constants/roleConstants'
import { REMOVE_SETTLEMENT_ENTRIES } from 'constants/modalConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import filter from 'lodash/filter'

import {
  getTransferWithRelatedSelector,
  getSplitTransfersByParentIdSelector,
} from 'state-layer/selectors'

import {
  isRolePlatform,
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  REFUND_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  STATE,
  READY_TO_SETTLE,
  FAILURE_CODE,
  CREATED_ON,
  REMOVE_FROM_SETTLEMENT,
  PARENT_SETTLEMENT_CLOSE,
  SETTLEMENT_RESOURCE_TITLE,
  SETTLEMENT_STATE,
  CREATED_VIA,
  FUNDING_STATUS,
  TRACE_ID,
  REFUND,
  SPLIT_TRANSACTION,
  ORIGINAL_PAYMENT,
  SPLIT_REFUND_SETTLEMENT_STATE_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

import {
  PAYMENTS_PATH,
  APPLICATION_PATH,
  IDENTITY_PATH,
  PAYMENT_PATH,
  SETTLEMENT_PATH,
  FEES_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetchingRefund = get(state, `transfersR.${FETCHING}`)
  const isFetchingSplitRefunds = get(state, `splitTransfersR.${FETCHING}`)
  const refundId = get(props, 'params.transferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const refund = getTransferWithRelatedSelector(state, refundId)
  const paymentPath = PAYMENTS_PATH({ credentialId })
  const isFlex = isFlexPlatform()
  const feesPath = ({ id = '' }) => `${FEES_PATH({ credentialId })}/${id}`

  const [
    traceId = '',
    currency = '',
    transferState = '',
    readyToSettleAt = '',
    displayAmount = '',
    displayAmountWithCurrencyCode = '',
    displayCreatedAt = '',
    paymentInstrument = {},
    tags,
    messages,
    raw,
    settlement,
    settlementEntry,
    shouldFund,
    externallyFunded,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    displayFailureCode,
    parentTransfer,
    parentTransferSettlement,
    subtype,
    device,
    buyerIdentity,
    splitRefundIds,
    fees,
  ] = getMany(refund, [
    'traceId',
    'currency',
    'state',
    'readyToSettleAt',
    'displayAmount',
    'displayAmountWithCurrencyCode',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
    'messages',
    'raw',
    'settlement',
    'settlementEntry',
    'settlementEntry.shouldFund',
    'externallyFunded',
    'applicationId',
    'application.name',
    'merchantIdentityId',
    'merchantIdentity.entity.business_name',
    'merchantIdentity.entity.doing_business_as',
    'displayFailureCode',
    'parentTransfer',
    'parentTransferSettlement',
    'subtype',
    'device',
    'buyerIdentity',
    'splitTransferIds',
    'fees',
  ])

  const isSplitParentRefund = !isEmpty(get(refund, 'splitTransferIds'))
  const splitRefundLabel = <div className='flex items-center'>{REFUND} <div className='split-transaction-badge'>{SPLIT_TRANSACTION}</div></div>
  const splitRefunds = getSplitTransfersByParentIdSelector(state, refundId)
  const showFundingStatus = (!isFlex || (isFlex && isRolePlatform({ credentials }))) && !isSplitParentRefund

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const showError = transferState === FAILED

  const isPayout = isPayoutFeature({ credentials })
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const [
    settlementId,
    settlementStatus,
    settlementException,
  ] = getMany(settlement, [
    'id',
    'status',
    'exception',
  ])

  const settlementEntryId = get(settlementEntry, 'id')
  const enableRemoveFromSettlement = settlementEntryId && settlementStatus && settlementStatus !== APPROVED && !settlementException
  const showAction = !isEmpty(paymentInstrument) && includes(NON_MERCHANT_ROLES, role) && enableRemoveFromSettlement
  const parentTransferSettlementAutoCloseTime = get(parentTransferSettlement, 'displayAutoCloseTime')

  const [
    originalPaymentId,
    displayParentTransactionAmount,
    primaryMerchantId,
  ] = getMany(parentTransfer, [
    'id',
    'displayAmountWithCurrencyCode',
    'merchantId',
  ])

  const parentTransactionPath = PAYMENT_PATH({ credentialId, transferId: originalPaymentId })
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const primaryMerchantAssociatedSplitRefund = find(splitRefunds, { merchantId: primaryMerchantId })
  const otherSplitRefunds = filter(splitRefunds, (element) => !isEqual(element, primaryMerchantAssociatedSplitRefund))
  const splitDetailsTableData = !isEmpty(splitRefunds) ? concat([refund], primaryMerchantAssociatedSplitRefund ? [primaryMerchantAssociatedSplitRefund] : [], otherSplitRefunds) : []
  const isFetching = (isFetchingRefund || isFetchingSplitRefunds) && isEmpty(splitDetailsTableData)

  const settlementStatusValue = (
    <div className='flex items-center'>
      <SettlementStatus value={settlementStatus} isSplitParentPayment={isSplitParentRefund} />
      {isSplitParentRefund && <TooltipLabelC message={SPLIT_REFUND_SETTLEMENT_STATE_TOOLTIP_MSG} position='right' />}
    </div>
  )

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
        condition: !isStandaloneMerchant && !isSplitParentRefund,
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: SETTLEMENT_PATH({ credentialId, settlementId }),
        condition: !isSplitParentRefund,
      },
    ],
  }

  const headerData = {
    resource: {
      label: isSplitParentRefund ? splitRefundLabel : REFUND_RESOURCE_TITLE,
      id: refundId,
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={transferState} rawMessage={raw} />,
      },
      {
        label: FAILURE_CODE,
        value: displayFailureCode,
        condition: showError,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: ORIGINAL_PAYMENT,
        value: displayParentTransactionAmount,
        path: parentTransactionPath,
      },
      {
        label: PARENT_SETTLEMENT_CLOSE,
        value: parentTransferSettlementAutoCloseTime,
      },
    ],
  }

  const fundingStatusValue = displayFundingDetailsValue({ shouldFund, externallyFunded })

  const detailsData = [
    {
      label: SETTLEMENT_STATE,
      value: settlementStatusValue,
    },
    {
      label: READY_TO_SETTLE,
      value: readyToSettleAt ? formatDate({ date: readyToSettleAt }) : '-',
      condition: !isFlex,
    },
    {
      label: CREATED_VIA,
      value: subtype,
    },
    {
      label: FUNDING_STATUS,
      value: fundingStatusValue,
      condition: showFundingStatus,
    },
  ]

  const detailsDataSection = convertPageSectionDataToV2(detailsData)

  return {
    isFetching,
    refundId,
    credentials,
    refund,
    detailsDataSection,
    paymentInstrument,
    tagsSectionData,
    messages,
    raw,
    paymentPath,
    showError,
    isPayout,
    displayAmount,
    displayAmountWithCurrencyCode,
    showAction,
    settlementId,
    settlementEntryId,
    headerData,
    contextBarData,
    device,
    buyerIdentity,
    isSplitParentRefund,
    splitRefundIds,
    originalPaymentId,
    splitDetailsTableData,
    splitDetailsColumnDescriptors: splitRefundsColumnDescriptors({ isParent: true, primaryMerchantId }),
    feesPath,
    fees,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRefund: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
    getSplitRefunds: ({ credentials, queries }) => { dispatch(getSplitTransfersRequest({ credentials, queries })) },
    showRemoveFromSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: REMOVE_SETTLEMENT_ENTRIES, modalProps })),
  }
}

class RefundC extends Component {
  componentDidMount() {
    const { splitRefundIds } = this.props

    this.fetchRefund()

    if (!isEmpty(splitRefundIds)) {
      this.fetchSplitRefunds()
    }
  }

  componentDidUpdate(prevProps) {
    const { refundId, splitRefundIds } = this.props
    const { refundId: prevRefundId, splitRefundIds: prevSplitRefundIds } = prevProps

    if (!isEqual(refundId, prevRefundId)) {
      this.fetchRefund()
    }

    if (!isEqual(prevSplitRefundIds, splitRefundIds) && !isEmpty(splitRefundIds)) {
      this.fetchSplitRefunds()
    }
  }

  fetchRefund = () => {
    const {
      refundId,
      credentials,
      getRefund,
    } = this.props

    getRefund({ credentials, transferId: refundId })
  }

  fetchSplitRefunds = () => {
    const {
      refundId,
      credentials,
      getSplitRefunds,
    } = this.props

    const queries = { parent_transfer_id: refundId }
    getSplitRefunds({ credentials, queries })
  }


  render() {
    const {
      transferId,
      showAction,
      showRemoveFromSettlementModal,
      settlementId,
      settlementEntryId,
      displayAmountWithCurrencyCode,
    } = this.props

    const actions = showAction ? [
      {
        label: REMOVE_FROM_SETTLEMENT,
        action: () => {
          showRemoveFromSettlementModal({
            settlementId,
            settlementEntryIds: [settlementEntryId],
            entriesData: [{ id: transferId, amount: displayAmountWithCurrencyCode }],
          })
        },
        className: 'remove-from-settlement-button',
      },
    ] : []


    return (
      <Refund
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundC)
