import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import PaymentLinkStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PaymentLinkStatus'
import column from 'utilities/table/column'
import { DEACTIVATED } from 'constants/paymentLinkConstants'

import {
  createdAtRange,
  updatedAtRange,
  linkExpiresAtRange,
} from 'utilities/table/sort'

import {
  AMOUNT,
  CREATED_ON,
  ID,
  LINK_EXPIRY,
  PAYOUT_LINK_NAME,
  RECIPIENT,
  STATUS,
  UPDATED_ON,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(RECIPIENT, 'recipientFullName', {
    className: 'name',
    formatter: ({ recipientFullName, recipientBusinessName }) => recipientBusinessName || recipientFullName,
  }),
  column(PAYOUT_LINK_NAME, 'nickname'),
  column(AMOUNT, 'displayTotalAmount', { className: 'amount divider' }),
  column(STATUS, 'small', {
    formatter: ({ state }) => <PaymentLinkStatus value={state} />,
  }),
  column(LINK_EXPIRY, 'displayLinkExpiresAt', {
    className: 'date tal',
    sort: linkExpiresAtRange,
    headerFormatter: () => <DateTimestampHeader title={LINK_EXPIRY} />,
    formatter: ({ linkExpiresAt, state }) => {
      if (state === DEACTIVATED) {
        return '-'
      }
      return <DateTimestampColumn timestamp={linkExpiresAt} />
    },
  }),
  column(UPDATED_ON, 'displayUpdatedAt', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
]

export default columnDescriptors
