import './AddEditBuyerInformationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import Button from 'components/Shared/Button/Button'
import validateBuyerInformationForm from 'utilities/validate/validateBuyerInformationForm'
import submitIdentityForm from 'utilities/submit/submitIdentityForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { ADD_EDIT_BUYER_INFORMATION_FORM } from 'constants/formConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  PHONE,
  ADD_BUYER,
  SAVE_CHANGES,
  CANCEL,
  REQUIRED,
} from 'constants/language/languageConstants'

const AddEditBuyerInformationForm = ({
  handleSubmit = () => {},
  submitting = false,
  closeModal = () => {},
  invalid = false,
  identityId,
  pristine = false,
}) => {
  return (
    <form className='AddEditBuyerInformationForm' onSubmit={handleSubmit(submitIdentityForm)}>
      <div className='modal-content'>
        <Field
          name='firstName'
          label={FIRST_NAME}
          component={InputField}
        />

        <Field
          name='lastName'
          label={LAST_NAME}
          component={InputField}
        />

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          component={InputField}
          required={false}
        />

        <Field
          name='phone'
          label={PHONE}
          component={InputField}
          format={formatPhone}
          parse={parsePhone}
          required={false}
        />

        <AddressFieldset
          name='personalAddress'
          noPlaceholders
          showLabels
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={identityId ? SAVE_CHANGES : ADD_BUYER} disabled={invalid || pristine} submitting={submitting} />
      </div>
    </form>
  )
}

AddEditBuyerInformationForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  identityId: PropTypes.string,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: ADD_EDIT_BUYER_INFORMATION_FORM,
  validate: validateBuyerInformationForm,
})(AddEditBuyerInformationForm)
