import co from 'co'
import postFeeProfileAPI from 'api/finix/post/postFeeProfileAPI'
import patchApplicationProfileAPI from 'api/finix/patch/patchApplicationProfileAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import removeUndefined from 'utilities/remove/removeUndefined'
import backendFeeProfileModel from 'utilities/create/models/backendFeeProfileModel'
import normalizeResponse from 'utilities/api/normalizeResponse'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import get from 'lodash/get'

function * postApplicationFeeProfileO ({
  values,
  credentials,
}) {
  const {
    cardNotPresentFeeProfile,
    cardPresentFeeProfile,
    application,
    applicationProfileId,
    applicationProfileTags,
    noExistingCardPresentFeeProfile,
  } = values

  const backendFeeProfileValues = backendFeeProfileModel({ values: cardNotPresentFeeProfile })
  const normalizedValues = normalizeResponse({ content: backendFeeProfileValues, normalizationMap: backendNormalizationMap })

  const { data: feeProfileResponse, error } = yield postFeeProfileAPI({
    credentials,
    values: { application, ...normalizedValues },
  })

  if (error) {
    return createErrorResponse({ error })
  }

  const feeProfileId = get(feeProfileResponse, 'id')

  // if cardNotPresentFeeProfile is different than cardPresent, create a separate one
  // once a user has a CNP fee profile applied, they cannot remove it. If they want to remove it, we want to set both fee profiles to the same Fee profile ID
  const applyCardNotPresentProfile = get(cardPresentFeeProfile, 'applyCardNotPresentProfile', false)
  let cardPresentFeeProfileId = noExistingCardPresentFeeProfile ? null : feeProfileId
  if (!applyCardNotPresentProfile) {
    const cardPresentBackendFeeProfileValues = backendFeeProfileModel({ values: cardPresentFeeProfile })
    const cardPresentNormalizedValues = normalizeResponse({ content: cardPresentBackendFeeProfileValues, normalizationMap: backendNormalizationMap })

    const { data: cardPresentFeeProfileResponse, error: cardPresentError } = yield postFeeProfileAPI({
      credentials,
      values: { application, ...cardPresentNormalizedValues },
    })

    if (cardPresentError) {
      return createErrorResponse({ error: cardPresentError })
    }

    cardPresentFeeProfileId = get(cardPresentFeeProfileResponse, 'id')
  }

  const patchApplicationProfilePayload = removeUndefined({
    id: applicationProfileId,
    application,
    fee_profile: feeProfileId,
    card_present_fee_profile: cardPresentFeeProfileId,
    tags: applicationProfileTags,
  })

  const { data: applicationProfileResponse, error: applicationProfileError } = yield patchApplicationProfileAPI({
    credentials,
    values: patchApplicationProfilePayload,
  })

  if (applicationProfileError) {
    return createErrorResponse({ error: applicationProfileError })
  }

  return createSuccessResponse({
    data: { [application]: {} },
  })
}

export default co.wrap(postApplicationFeeProfileO)
