import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import { getSubscriptionPlansMerchant } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_SUBSCRIPTION_PLANS } from 'constants/flowConstants'
import { SUBSCRIPTION_PLANS_LINKS_KEY } from 'constants/linkConstants'

import {
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLANS_EMPTY_MSG,
  SUBSCRIPTION_PLANS_TOOLTIP,
} from 'constants/language/languageConstants'


const SubscriptionPlans = ({
  isFetching = false,
  initialQueries = {},
  actions = [],
  quickFilters = [],
  allowedFilters = [],
  subscriptionPlansPath = '',
}) => {
  return (
    <div className='SubscriptionPlans'>
      <HeaderV2C
        title={SUBSCRIPTION_PLANS}
        subTitle={SUBSCRIPTION_PLANS_TOOLTIP}
      />

      <TableC
        isFetching={isFetching}
        flow={GET_SUBSCRIPTION_PLANS}
        columnDescriptors={columnDescriptors}
        selector={getSubscriptionPlansMerchant}
        initialQueries={initialQueries}
        emptyMessage={SUBSCRIPTION_PLANS_EMPTY_MSG}
        actions={actions}
        quickFilters={quickFilters}
        allowedFilters={allowedFilters}
        path={subscriptionPlansPath}
        linksKey={SUBSCRIPTION_PLANS_LINKS_KEY}
      />
    </div>
  )
}

SubscriptionPlans.propTypes = {
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  allowedFilters: PropTypes.array,
  subscriptionPlansPath: PropTypes.string,
}

export default SubscriptionPlans
