import {
  DUMMY_V1,
  LITLE_V1,
  VANTIV_V1,
} from 'constants/processorConstants'

function registrationLink(route, text) {
  return { text, route }
}

export const DATA = {
  name: 'clubessential',
  id: 'DASHdq3danBFX98Bh4uo8doAEN',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/clubessential/2018-06-27-20_43_51-club-essential-logo.png',
  faviconImage: 'https://finix-payments.github.io/customer-logos/assets/finix-favicon.png',
  credentialsHost: 'https://clubessential.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  paymentsApiHosts: 'https://*.finix.io',
  auth0: {
    clientID: '1quqQLWoo1vYGt5NzUfBfeE_Qqunj09O',
    domain: 'clubessential-dashboard.auth0.com',
    allowSignUp: false,
    title: '',
    logo: '', // 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2020-02-19-02_51_16-club-essential-logo.png',
  },
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'VANTIV_V1'],
  registrationSteps: [
    registrationLink('account/registrations/edit/index', 'Identity Information'),
    registrationLink('account/registrations/edit/bank-accounts', 'Bank Account'),
    registrationLink('account/registrations/edit/associated-identities', 'Associated Identities'),
    registrationLink('account/registrations/edit/processors', 'Processors'),
    registrationLink('account/registrations/edit/api-keys', 'API Keys'),
    registrationLink('account/registrations/edit/resource-ids', 'Resource IDs'),
  ],
  dashboardServiceV2: true,
}
