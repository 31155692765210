import removeUndefined from 'utilities/remove/removeUndefined'

const backendBalanceEntryModel = ({ values }) => {
  const {
    balanceEntryAmount,
    balanceEntryType,
    wireReferenceNumber,
    balanceEntryCurrency,
    tags,
    description,
  } = values

  return removeUndefined({
    amount: balanceEntryAmount,
    type: balanceEntryType,
    wire_details: {
      external_reference_number: wireReferenceNumber,
    },
    currency: balanceEntryCurrency,
    tags,
    description,
  })
}

export default backendBalanceEntryModel
