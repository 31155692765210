import './SkeletonInsightsS.scss'
import React from 'react'
import Skeleton from 'components/Shared/Skeleton/Skeleton'

const SkeletonInsights = () => (
  <div className='SkeletonInsights flex column'>
    <div className='header-area'>
      <Skeleton width='101px' height='40px' />
      <Skeleton width='268px' height='24px' />
    </div>

    <div className='time-frame-container flex space-between items-center'>
      <Skeleton width='268px' height='32px' />
      <Skeleton width='268px' height='32px' />
    </div>

    <div className='charts-area content-padding'>
      <Skeleton width='24%' height='32px' />

      <div className='section-one flex'>
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
      </div>

      <div className='section-area'>
        <Skeleton width='48%' height='32px' />

        <div className='section-two flex'>
          <Skeleton height='324px' />
          <Skeleton height='324px' />
        </div>

        <div className='section-two flex'>
          <Skeleton height='324px' />
          <Skeleton height='324px' />
        </div>
      </div>

      <Skeleton width='268px' height='32px' />

      <div className='section-one flex'>
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
      </div>

      <Skeleton className='last-top-level-bar' width='268px' height='32px' />

      <div className='section-one flex'>
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
        <Skeleton height='132px' />
      </div>
    </div>
  </div>
)

export default SkeletonInsights
