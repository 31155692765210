import './DisableAPIKeyModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import DisableAPIKeyC from './DisableAPIKeyC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { DISABLE_API_KEY } from 'constants/language/languageConstants'

const DisableAPIKeyModal = ({
  keyId,
}) => {
  return (
    <GenericModal
      title={DISABLE_API_KEY}
      className='DisableAPIKeyModal'
      Component={DisableAPIKeyC}
      keyId={keyId}
    />
  )
}

DisableAPIKeyModal.propTypes = {
  keyId: PropTypes.string,
}

export default DisableAPIKeyModal
