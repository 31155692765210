import { GET_API_LOG_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAPILogRequest = ({
  apiLogId,
  credentials,
}) => {
  return createAction({
    type: GET_API_LOG_F_REQUEST,
    id: apiLogId,
    credentials,
  })
}

export default getAPILogRequest
