import './TableFiltersMenuS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { TABLE_FILTERS_FORM } from 'constants/formConstants'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import submitTableFiltersForm from 'utilities/submit/submitTableFiltersForm'
import classnames from 'classnames'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  ON,
  IS_BETWEEN,
  ALL_OPERANDS,
  OPERAND_LABEL_MAP,
  DATE_FILTERS,
} from 'constants/filterConstants'

import {
  CLEAR,
  DONE,
} from 'constants/language/languageConstants'

// TODO: see if we can refactor some of the repetitive code in here
const TableFiltersMenu = ({
  checkedFilters = {},
  allowedFilters = [],
  toggleCheckedFilter = () => {},
  clearFilters = () => {},
  handleSubmit = () => {},
  tableFiltersFormValues = {},
}) => {
  return (
    <form
      className='TableFiltersMenu'
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit(submitTableFiltersForm)(e)
      }}
      autoComplete='off'
    >
      <div className='filterButtons flex space-between'>
        <Button onClick={clearFilters} label={CLEAR} variant='secondary' />
        <span>Filters</span>
        <Button type='submit' label={DONE} />
      </div>

      <div className='filterItems'>
        { map(allowedFilters, (filterItem) => {
          const {
            field,
            label,
            placeholder,
            options,
            format,
            component = InputField,
            operands = ALL_OPERANDS,
            operandLabelMap = OPERAND_LABEL_MAP,
            leadingIconString,
          } = filterItem

          const operandOptions = map(operands, (operand) => ({ label: operandLabelMap[operand], value: operand }))
          const showOperandOptions = get(operandOptions, 'length', 0) > 1
          const isActive = get(checkedFilters, field, false)
          const filterItemClassName = classnames('filterItem', { isActive })
          const isDateFilter = get(DATE_FILTERS, field)
          const filterOperand = get(tableFiltersFormValues, `${field}.operand`)

          return (
            <div className={filterItemClassName} key={`filter-item-${field}`}>
              <CheckboxC
                name={`${field}_checkbox`}
                checked={isActive}
                objectId={field}
                handleChange={toggleCheckedFilter}
                label={label}
              />

              <div className='filterInput'>
                { showOperandOptions && (
                  <div className='operand'>
                    <Field
                      name={`${field}.operand`}
                      component={SelectField}
                      options={operandOptions}
                      classNames
                    />
                  </div>
                )}

                <div className='value'>
                  { isDateFilter && (
                    <div className={`createdAtInput ${filterOperand}`}>
                      { filterOperand !== IS_BETWEEN && (
                        <Field
                          name={`${field}.value`}
                          placeholder={placeholder || label}
                          component={component}
                          options={options}
                          normalize={value => (value ? value : '')}
                          format={format ? format : (val) => val}
                        />
                      )}

                      { filterOperand === IS_BETWEEN && (
                        <>
                          <Field
                            name={`${field}.gte.value`}
                            placeholder={placeholder || label}
                            component={component}
                            options={options}
                            normalize={value => (value ? value : '')}
                            format={format ? format : (val) => val}
                          />

                          <Field
                            name={`${field}.lte.value`}
                            placeholder={placeholder || label}
                            component={component}
                            options={options}
                            normalize={value => (value ? value : '')}
                            format={format ? format : (val) => val}
                          />
                        </>
                      )}
                    </div>
                  )}

                  { !isDateFilter && (
                    <Field
                      name={`${field}.value`}
                      placeholder={placeholder || label}
                      component={component}
                      options={options}
                      normalize={value => (value ? value : '')}
                      format={format ? format : (val) => val}
                      leadingIconString={leadingIconString}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </form>
  )
}

TableFiltersMenu.propTypes = {
  checkedFilters: PropTypes.object,
  allowedFilters: PropTypes.array,
  toggleCheckedFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  handleSubmit: PropTypes.func,
  tableFiltersFormValues: PropTypes.object,
}

export default reduxForm({
  form: TABLE_FILTERS_FORM,
})(TableFiltersMenu)
