import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
} from 'constants/bankConstants'

const validateSendPayoutRecipientForm = (values, props) => {
  const [
    identityId,
    paymentInstrumentId,
  ] = getMany(values, [
    'identityId.value',
    'paymentInstrumentId.value',
  ])

  // set errors if the recipient has reached their card or ach limit
  const [
    selectedPaymentInstrument,
    selectedRecipientCurrentUsages,
  ] = getMany(props, [
    'selectedPaymentInstrument',
    'selectedRecipientCurrentUsages',
  ])

  const [
    canSendACHPayout,
    canSendCardPayout,
  ] = getMany(selectedRecipientCurrentUsages, [
    'canSendACHPayout',
    'canSendCardPayout',
  ])

  let unavailablePaymentInstrumentError = false
  if (!canSendACHPayout && get(selectedPaymentInstrument, 'instrumentType') === BANK_ACCOUNT) {
    unavailablePaymentInstrumentError = 'Daily ACH payout limit for this recipient has been reached. Please select a card instead.'
  }
  if (!canSendCardPayout && get(selectedPaymentInstrument, 'instrumentType') === PAYMENT_CARD) {
    unavailablePaymentInstrumentError = 'Daily card payout limit for this recipient has been reached. Please select a bank account instead.'
  }

  return {
    identityId: identityId ? false : 'Recipient Name must not be empty.',
    paymentInstrumentId: (unavailablePaymentInstrumentError && unavailablePaymentInstrumentError) || (paymentInstrumentId ? false : 'Recipient Payment Instrument must not be empty.'),
  }
}

export default validateSendPayoutRecipientForm
