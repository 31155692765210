import dashboardAPI from 'utilities/api/dashboardAPI'
import { HOME_CHARTS } from 'constants/apiConstants'

const getHomeChartsAPI = ({ meta }) => {
  return dashboardAPI.get({
    path: HOME_CHARTS,
    meta,
  })
}

export default getHomeChartsAPI
