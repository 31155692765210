import patchReviewQueueItemAPI from 'api/finix/patch/patchReviewQueueItemAPI'
import getReviewQueueAPI from 'api/finix/get/getReviewQueueAPI'
import backendReviewQueueItemModel from 'utilities/create/models/backendReviewQueueItemModel'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getResponseLinks from 'utilities/get/getResponseLinks'
import normalizeResponse from 'utilities/api/normalizeResponse'
import map from 'lodash/map'
import get from 'lodash/get'
import co from 'co'

function * patchReviewQueueItemsO ({
  values = {},
  queries = {},
  credentials,
}) {
  const {
    items,
  } = values

  const {
    offset = 0,
    limit,
    sort,
    outcome,
    entity_type: entityType,
  } = queries

  let successCounts = 0
  const responses = yield map(items, (item) => patchReviewQueueItemAPI({ values: backendReviewQueueItemModel({ values: item }), credentials }))

  const reviewQueueItems = map(responses, ({ data, error }, index) => {
    if (error) {
      const errors = get(error, 'response.data._embedded.errors')
      const reviewQueueItemId = get(items, `[${index}].id`)

      return {
        id: reviewQueueItemId,
        errors,
      }
    }

    successCounts += 1

    return normalizeResponse({ content: data })
  })

  const newOffset = Number(offset) - successCounts

  const { data: response } = yield getReviewQueueAPI({
    queries: {
      offset: newOffset > 0 ? newOffset : 0,
      limit,
      sort,
      outcome,
      entity_type: entityType,
    },
    credentials,
  })

  const page = get(response, 'page')
  const links = getResponseLinks(response)

  return createSuccessResponse({
    data: {
      data: reviewQueueItems,
      page,
      links,
    },
  })
}

export default co.wrap(patchReviewQueueItemsO)
