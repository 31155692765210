import { GET_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPayoutLinkRequest = ({
  payoutLinkId,
  credentials,
}) => createAction({
  type: GET_PAYOUT_LINK_F_REQUEST,
  id: payoutLinkId,
  credentials,
})

export default getPayoutLinkRequest
