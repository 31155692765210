import ClickToCopy from './ClickToCopy'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { SUCCESSFULLY_COPIED_TO_CLIPBOARD } from 'constants/language/languageConstants'
import { SUCCESS } from 'constants/flashNotificationConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

class ClickToCopyC extends Component {
  copyToClipboard = (name = '') => {
    const {
      dispatch,
    } = this.props

    const input = document.createElement('input')

    input.setAttribute('value', name)
    document.body.appendChild(input)
    input.select()

    const result = document.execCommand('copy')

    document.body.removeChild(input)

    dispatch(flashNotificationAction({
      message: SUCCESSFULLY_COPIED_TO_CLIPBOARD,
      type: SUCCESS,
      time: 3000,
    }))

    return result
  }

  render() {
    return (
      <ClickToCopy
        {...this.props}
        copyToClipboard={this.copyToClipboard}
      />
    )
  }
}

export default connect(mapStateToProps)(ClickToCopyC)
