import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY,
} from 'constants/apiConstants'

const getIdentityAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: IDENTITY({ identityId: id }),
    credentials,
    service: IDENTITIES,
  })
}

export default getIdentityAPI
