import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUnderwritingReportSelector } from 'state-layer/selectors'
import IdentityMatch from './IdentityMatch'
import getUnderwritingReportRequest from 'utilities/actions/get/getUnderwritingReportRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { FETCHING } from 'constants/reducerConstants'
import { IDENTITY_MATCH } from 'constants/language/languageConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReportsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)

  const reportId = get(props, 'params.reportId') || get(props, 'reportId')
  const underwritingReport = getUnderwritingReportSelector(state, reportId)
  const personaRawData = get(underwritingReport, 'raw')
  const hitIndex = getUrlQuery('hitIndex', 0)

  const [
    entityName,
    hits,
  ] = getMany(underwritingReport, [
    'entity_name',
    'hits',
  ])

  const hit = get(hits, `[${hitIndex}]`)

  const [
    hitName,
    mediaHits,
  ] = getMany(hit, [
    'name',
    'media',
  ])

  const headerData = {
    resource: {
      label: IDENTITY_MATCH,
    },
    items: [
      {
        value: <h1>{hitName}</h1>,
      },
    ],
  }

  return {
    isFetching,
    credentials,
    reportId,
    underwritingReport,
    headerData,
    hit,
    entityName,
    personaRawData,
    mediaHits,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnderwritingReport: ({ credentials, reportId }) => dispatch(getUnderwritingReportRequest({ credentials, reportId })),
  }
}

class IdentityMatchC extends Component {
  componentDidMount() {
    const {
      reportId,
      credentials,
      getUnderwritingReport,
    } = this.props

    getUnderwritingReport({
      reportId,
      credentials,
    })
  }

  render() {
    return (
      <IdentityMatch
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityMatchC)
