import './HeaderItemS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'

const HeaderItem = ({
  label = '',
  value = '',
  condition = true,
  path,
}) => {
  if (value === undefined || value === '' || !condition) return null
  if (!value && !label) return null

  const valueClassNames = classnames({
    value: true,
    path: !!path,
    flex: true,
    'items-center': true,
    'link-1': !!path,
  })

  return (
    <div className='HeaderItem flex column'>
      { label && <div className='label p-3-caps'>{label}</div> }
      { value && (path ? <Link className={valueClassNames} to={path}>{value}</Link> : <div className={valueClassNames}>{value}</div>) }
    </div>
  )
}

HeaderItem.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  condition: PropTypes.bool,
}

export default HeaderItem
