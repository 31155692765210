import frontendOnboardingFormModel from 'utilities/create/models/frontendOnboardingFormModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendOnboardingFormsModel = ({ data: onboardingForms }) => {
  const onboardingFormModels = map(onboardingForms, (onboardingForm) => frontendOnboardingFormModel({ data: onboardingForm }))

  return keyBy(onboardingFormModels, 'id')
}

export default frontendOnboardingFormsModel
