import React, { Component } from 'react'
import { connect } from 'react-redux'
import SendPayout from './SendPayout'
import SendPayoutRecipientFormC from 'components/Customer/Forms/SendPayoutForm/SendPayoutRecipientForm/SendPayoutRecipientFormC'
import SendPayoutAmountFormC from 'components/Customer/Forms/SendPayoutForm/SendPayoutAmountForm/SendPayoutAmountFormC'
import SendPayoutReviewAndSubmitFormC from 'components/Customer/Forms/SendPayoutForm/SendPayoutReviewAndSubmitForm/SendPayoutReviewAndSubmitFormC'
import { getCurrentUsagesByLinkedTo } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import { PAYOUTS_LIMIT_REACHED_MODAL } from 'constants/modalConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'

import {
  SEND_PAYOUT_RECIPIENT_FORM,
  SEND_PAYOUT_AMOUNT_FORM,
  SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM,
} from 'constants/formConstants'

import {
  RECIPIENT,
  AMOUNT,
  REVIEW_AMPERSAND_SUBMIT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const currentUsages = getCurrentUsagesByLinkedTo(state, applicationId)
  const applicationPayoutsLimitReached = get(currentUsages, 'cannotSendMoney', false)
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)

  const steps = [
    {
      name: RECIPIENT,
      component: SendPayoutRecipientFormC,
      props: {
        applicationId,
        applicationCurrentUsages: currentUsages,
      },
      dataKey: 'recipient',
      formValues: () => reduxFormValues(SEND_PAYOUT_RECIPIENT_FORM),
    },
    {
      name: AMOUNT,
      component: SendPayoutAmountFormC,
      props: {
        applicationId,
        applicationCurrentUsages: currentUsages,
      },
      dataKey: 'amount',
      formValues: () => reduxFormValues(SEND_PAYOUT_AMOUNT_FORM),
    },
    {
      name: REVIEW_AMPERSAND_SUBMIT,
      component: SendPayoutReviewAndSubmitFormC,
      props: {
        credentials,
        applicationId,
      },
      dataKey: 'review',
      formValues: () => reduxFormValues(SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM),
    },
  ]

  return {
    credentials,
    credentialId,
    steps,
    applicationId,
    applicationPayoutsLimitReached,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showPayoutsLimitReachedModal: ({ credentialId }) => dispatch(showModalAction({ modalType: PAYOUTS_LIMIT_REACHED_MODAL, modalProps: { canCloseModal: false, credentialId }, className: 'modal-md no-pad' })),
    getCurrentUsages: ({ credentials, entityId, currency }) => dispatch(getCurrentUsagesRequest({ credentials, entityId, currency })),
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
  }
}

class SendPayoutC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getCurrentUsages,
    } = this.props

    if (applicationId) {
      getCurrentUsages({ credentials, entityId: applicationId, currency: 'USD' })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      credentials,
      applicationId,
      getCurrentUsages,
      applicationPayoutsLimitReached,
      showPayoutsLimitReachedModal,
      getApplication,
      credentialId,
    } = this.props

    const { applicationId: prevApplicationId, applicationPayoutsLimitReached: prevApplicationPayoutsLimitReached } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getCurrentUsages({ credentials, entityId: applicationId, currency: 'USD' })
      getApplication({ credentials, applicationId })
    }

    if (applicationPayoutsLimitReached !== prevApplicationPayoutsLimitReached && applicationPayoutsLimitReached) {
      showPayoutsLimitReachedModal({ credentialId })
    }
  }

  render() {
    return (
      <SendPayout
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPayoutC)
