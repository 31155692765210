export const DRAFT = 'DRAFT'
export const PUBLISHED = 'PUBLISHED'

export const defaultToolbarOptions = [
  // [{ size: ['small', false, 'large', 'huge', 'title'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  ['link', 'image',],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  ['clean'],
]

export const underwritingNoteTemplates = [
  {
    name: 'Start with a template',
    data: {
      ops: [{
        insert: '',
      }]
    }
  },
  {
    name: 'Decision Template',
    data: {
      ops: [
        {
          insert: 'Underwriting Decision: Case with Flags Template \n',
          attributes: { header: 2 },
        },
        {
          insert: '\nDocument and defend your underwriting decision when approving Merchants/Flex Customers & Self-service applications.\n',
          attributes: { size: 'normal' },
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal', color: '#C1CACF' },
        },
        {
          insert: 'Decision \n',
          attributes: { header: 3 },
        },
        {
          insert: 'Defend your decision with a short summary, whether approving (e.g., "No concerns, approving" or "OFAC hit cleared dur to geography") or declining. \n',
          attributes: { header: 5 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Red Flags\n',
          attributes: { header: 3, color: '#E91825' },
        },
        {
          insert: 'List any red flags (e.g., documents, website age, international IP address) \n',
          attributes: { header: 5 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'N/A\n',
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal', color: '#C1CACF' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Due Diligence\n',
          attributes: { header: 4 }
        },
        {
          insert: '\n',
        },
        {
          insert:'Adverse Media: \n',
          attributes: { header: 4, color: '#0052E5' },
        },
        {
          insert: 'Were any Persona Adverse Media reports run outside of Finix Underwriting System? If so, list on which entities and on what date \n',
          attributes: { size: 'normal' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'List any negative findings and how they were negated: \n',
          attributes: { size: 'normal', bold: true },
        } ,
        {
          insert: '\n',
        },
        {
          insert: 'Sanctions: \n',
          attributes: { header: 4, color: '#0052E5' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'If any OFAC hits - list how it was negated/ why we didn\'t request an ID (e.g., due to geography, birthdate) \n',
        },
        {
          insert: '\n',
        },
        {
          insert: 'Flex Client (if applicable): \n',
          attributes: { header: 4, color: '#0052E5' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'KYB Screens: \n',
          attributes: { size: 'normal', bold: true },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Website \n',
          attributes: { size: 'normal'},
        },
        {
          insert: 'Domain Name Record: \n',
          attributes: { size: 'normal', list: 'bullet' },
        },
        {
          insert: 'Terms of Service \n',
          attributes: { size: 'normal', list: 'bullet' },
        },
        {
          insert: 'Privacy Policy \n',
          attributes: { size: 'normal', list: 'bullet' },
        },
        {
          insert: 'Return Policy \n',
          attributes: { size: 'normal', list: 'bullet'},
        },
        {
          insert: '\n',
        },
        {
          insert: 'Website Screenshot\n',
          attributes: {size: 'normal', list: 'bullet'},
        },
        {
          insert: '\n',
        },
        {
          insert: 'KYC Screens: \n',
          attributes: { size: 'normal', bold: true },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Owner(s): \n',
        },
        {
          insert: '\n',
        },
        {
          insert: 'Name \n',
          attributes: { list: 'bullet' },
        },
        {
          insert: 'Search Tools (if applicable): \n',
          attributes: { list: 'bullet' },
        },
        {
          insert: 'https:/www.webmii.com/',
          attributes: { link: 'https:/www.webmii.com/' },
        },
        {
          insert: 'https:/www.ostframework.com/',
          attributes: { link: 'https:/www.ostframework.com/' },
        },
      ],
    },
  },
  {
    name: 'Re-Review Template',
    data: {
      ops: [
        {
          insert: 'Risk Re-Review Template \n',
          attributes: { header: 2 },
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: 'Systems Needed\n',
          attributes: { header: 3},
        },
        {
          insert: '\n',
        },
        {
          insert: 'Finix Dashboard\n',
          attributes: { size: 'normal', bullet: true },
        },
        {
          insert: 'Salesforce\n',
          attributes: { size: 'normal', bullet: true },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Context\n',
          attributes: { header: 3 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Flex Client: \n',
          attributes: { size: 'normal', bold: true },
        },
        {
          insert: 'Original Review Date: MM/DD/YYYY \n',
          attributes: { size: 'normal', bold: true },
        },
        {
          insert: 'Analyst Name: \n',
          attributes: { bullet: true },
        },
        {
          insert: 'Today\'s Review Date: MM/DD/YYYY \n',
          attributes: { size: 'normal', bold: true, bullet: true },
        },
        {
          insert: 'Next Review Date: MM/DD/YYYY \n',
          attributes: { size: 'normal', bold: true, bullet: true },
        },
        {
          insert: '\n',
        },
        {
          insert: '# of merchants reviewing \n',
          attributes: { header: 3 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'List MUxxx and Merchant name below. \n',
          attributes: { size: 'normal', bullet: true },
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Transactions \n',
          attributes: { header: 3 },
        },
        {
          insert : '# of transactions',
          attributes : { bold : true}
        },
        {
          insert : 'since last review, combined count for all merchants: \n',
        },
        {
          insert : '# of disputes \n',
          attributes : { bold : true}
        },
        {
          insert : 'combined count for all merchants: \n',
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Adverse Media Searches for Merchants\n',
          attributes: { header: 3 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Repeat on each merchant if applicable: \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '\n',
        },
        {
          insert: '"Merchant Name Lawsuit" \n',
          attributes: { header: 4 },
        },
        {
          insert: 'Description \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '\n',
        },
        {
          insert: '"Merchant Name Class" \n',
          attributes: { header: 4 },
        },
        {
          insert: 'Description \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '\n',
        },
        {
          insert: '"Merchant Name Fraud" \n',
          attributes: { header: 4 },
        },
        {
          insert: 'Description \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '\n',
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: 'Complaints Flex Client \n',
          attributes: { header: 4 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'BBB \n',
          attributes: { header: 4 },
        },
        {
          insert: 'No results for Flex Client or any of their merchants. \n',
          attributes: { size: 'normal'},
        },
        {
          insert: 'Ripoff report \n',
          attributes: { header: 4 },
        },
        {
          insert: 'No results for Flex Client or any of their merchants. \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Complaints Merchant(s) if applicable \n',
          attributes: { header: 4 },
        },
        {
          insert: '\n',
        },
        {
          insert: 'BBB \n',
          attributes: { header: 4 },
        },
        {
          insert: 'No results for Flex Client or any of their merchants. \n',
          attributes: { size: 'normal'},
        },
        {
          insert: 'Ripoff report \n',
          attributes: { header: 4 },
        },
        {
          insert: 'No results for Flex Client or any of their merchants. \n',
          attributes: { size: 'normal'},
        },
        {
          insert: '\n',
        },
        {
          insert: '-----------------------------------------------------------------------------------------------------------------------\n',
          attributes: { size: 'normal' },
        },
        {
          insert: '\n',
        },
        {
          insert: 'Results from reviewing of Flex/Merchant Accounts \n',
          attributes: { header: 4 },
        },
        {
          insert: 'Results \n',
          attributes: { size: 'normal'},
        },
        {
          insert: 'Risk Level Decision \n',
          attributes: { header: 4 },
        },
        {
          insert: 'Decision \n',
          attributes: { size: 'normal'},
        },
      ],
    },
  },
]
