import { invalidOrEmptyRegex } from 'utilities/validate'
import { ACTIVATION_CODE, SERIAL_NUMBER } from 'constants/language/languageConstants'
import { ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX } from 'constants/regexConstants'
import trim from 'lodash/trim'
import size from 'lodash/size'

const validateActivateDeviceForm = (values, props) => {
  const {
    activationCode,
  } = values

  const {
    showActivationCodeInput,
  } = props

  return {
    activationCode: showActivationCodeInput ? invalidOrEmptyRegex({ field: activationCode, name: ACTIVATION_CODE, regex: ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX, customErrorMessage: `${ACTIVATION_CODE} cannot contain special characters.` })
    || (size(trim(activationCode)) > 7 && `${ACTIVATION_CODE} must not exceed 7 characters.`) : false,
  }
}

export default validateActivateDeviceForm
