import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProvisionMerchantProcessorForm from './ProvisionMerchantProcessorForm'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import { FETCHING } from 'constants/reducerConstants'
import map from 'lodash/map'
import head from 'lodash/head'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import { VANTIV_V1 } from 'constants/processorConstants'
import { ADVANCED_PROCESSOR_ENABLED_PLATFORMS } from 'constants/platformConstants'

const formatOptionLabel = ({ value, label, accountType }, { context }) => {
  const { name, maskedAccountNumber } = value

  if (context === 'value') {
    return label
  }

  return (
    <div>
      <div>{label}</div>
      <div>{name}</div>
      <div>{accountType} - {maskedAccountNumber}</div>
    </div>
  )
}

const filterOption = (option, inputValue) => {
  const { value } = option
  const { id, name, maskedAccountNumber } = value

  return includes(id, inputValue) || includes(name, inputValue) || includes(maskedAccountNumber, inputValue)
}

const mapStateToProps = (state, props) => {
  const [
    processor,
    paymentInstruments,
    enabled,
    onSubmit,
  ] = getMany(props, [
    'processor',
    'paymentInstruments',
    'enabled',
    'onSubmit',
  ])

  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const hasPaymentInstrument = !isEmpty(paymentInstruments)

  const paymentInstrumentOptions = map(paymentInstruments, (paymentInstrument) => {
    const {
      id,
      name,
      accountType,
      maskedAccountNumber,
    } = paymentInstrument
    return {
      label: id,
      value: { id, name, maskedAccountNumber },
      accountType,
    }
  })

  const defaultPaymentInstrumentOption = head(paymentInstrumentOptions)

  const platformName = getCurrentPlatformName()
  const advancedProcessorEnabled = includes(ADVANCED_PROCESSOR_ENABLED_PLATFORMS, platformName)
  const vantivProcessor = processor === VANTIV_V1
  const optionalProcessorParamsAllowed = vantivProcessor && advancedProcessorEnabled

  const initialValues = !isFetching ? {
    defaultBankAccount: defaultPaymentInstrumentOption,
  } : undefined

  return removeUndefined({
    processor,
    paymentInstruments,
    hasPaymentInstrument,
    enabled,
    onSubmit,
    paymentInstrumentOptions,
    initialValues,
    optionalProcessorParamsAllowed,
  })
}

class ProvisionMerchantProcessorFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCodeInput: false,
      showBankAccounts: false,
    }
  }

  toggleShowCodeInput = () => {
    const { showCodeInput } = this.state

    this.setState({ showCodeInput: !showCodeInput })
  }

  toggleShowBankAccounts = () => {
    const { showBankAccounts } = this.state

    this.setState({ showBankAccounts: !showBankAccounts })
  }

  render() {
    const {
      showCodeInput,
      showBankAccounts,
    } = this.state

    return (
      <ProvisionMerchantProcessorForm
        {...this.props}
        showCodeInput={showCodeInput}
        showBankAccount={showBankAccounts}
        toggleShowCodeInput={this.toggleShowCodeInput}
        toggleShowBankAccounts={this.toggleShowBankAccounts}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
      />
    )
  }
}

export default connect(mapStateToProps)(ProvisionMerchantProcessorFormC)
