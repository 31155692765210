import './AmountCurrencyDisplayHeaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { USD } from 'constants/currencyConstants'

const AmountCurrencyDisplayHeader = ({
  displayAmount = '',
  currency = USD,
}) => {
  return (
    <div className='AmountCurrencyDisplayHeader flex'>
      <h1>{displayAmount}</h1>
      <div className='h-5-caps'>{currency}</div>
    </div>
  )
}

AmountCurrencyDisplayHeader.propTypes = {
  displayAmount: PropTypes.string,
  currency: PropTypes.string,
}

export default AmountCurrencyDisplayHeader
