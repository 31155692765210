import './UnderwritingProfileS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { APPROVED_MERCHANTS_LINK } from 'constants/urlConstants'
import { infoTableTheme } from 'constants/themeConstants'

import {
  ALLOWED_MERCHANT_CATEGORY_CODES_TITLE,
  CONTINUOUS_MONITORING_SETTINGS,
  CONTINUOUS_MONITORING_SETTINGS_SUBTITLE,
  MCC_EMPTY_MESSAGE,
  MERCHANT_ONBOARDING_FORM_SETTINGS,
  RECIPIENT_SETTINGS,
  RECIPIENT_SETTINGS_SUBTITLE,
  TRANSACTION_LIMITS,
  UNDERWRITING_AUTOMATION_SETTINGS,
  UNDERWRITING_PROFILE,
  UNDERWRITING_PROFILE_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'


const UnderwritingProfile = ({
  isFetching = false,
  underwritingProfileActions = [],
  merchantOnboardingFormSettingsActions = [],
  transactionLimitsSectionData = [],
  clickToCopies = [],
  enabledMCCsTableItems = [],
  totalMCCsString = '',
  isUnderwritingProfileEmpty = false,
  tooltipMessage = '',
  isMCCsEmpty = false,
  isRolePartnerCredential = false,
  showEditModal = () => {},
  allowAllMCCs = false,
  showUnderwritingAutomationSettingsSection = false,
  workflowsTableItems = {},
  actions = [],
  workflowsColumnDescriptors = [],
  extraProps = {},
  displayBiometricDataCollection = '',
  continuousMonitoringSettingsData = [],
  continuousMonitoringSettingsActions = [],
  recipientSettingsData = [],
  isDisbursementsApplication = false,
  recipientSettingsActions = [],
  isRolePlatformCredential = false,
}) => {
  return (
    <div className='UnderwritingProfile'>
      { !isDisbursementsApplication && (
        <>
          <PageSectionV2C
            title={UNDERWRITING_PROFILE}
            actions={underwritingProfileActions}
            className='allowed-merchant-category-codes'
            isFetching={isFetching}
            clickToCopies={clickToCopies}
            tooltip
            tooltipMessage={tooltipMessage}
            tooltipPosition='right'
          >
            {isUnderwritingProfileEmpty && (
              <div className='empty-message p-1'>
                {isRolePartnerCredential ? (
                  <span>{UNDERWRITING_PROFILE_EMPTY_MESSAGE}</span>
                ) : (
                  <span>No Underwriting Profile available - <span className='link-1' onClick={showEditModal}>create one</span></span>
                )}
              </div>
            )}

            {!isUnderwritingProfileEmpty && (
              <>
                <h5>{ALLOWED_MERCHANT_CATEGORY_CODES_TITLE}</h5>
                {isMCCsEmpty && (
                  <div className='empty-message p-1'>
                    {isRolePartnerCredential ? (
                      <span>{MCC_EMPTY_MESSAGE}</span>
                    ) : (
                      <span> No MCCs available - <span className='link-1' onClick={showEditModal}>edit</span> to set allowed MCCs.</span>
                    )}
                  </div>
                )}

                {!isMCCsEmpty && (
                  <>
                    {allowAllMCCs && (
                      <div className='all-mccs-allowed-message p-1'>
                        <span>All MCCs <a target='blank' href={APPROVED_MERCHANTS_LINK} className='text-link'>approved by Finix</a> are allowed.</span>
                      </div>
                    )}

                    {!allowAllMCCs && (
                      <>
                        <div className='description p-1'>{totalMCCsString}</div>
                        <TableC
                          columnDescriptors={columnDescriptors}
                          data={enabledMCCsTableItems}
                          isFetching={isFetching}
                          showTableFooter={false}
                          theme={infoTableTheme}
                        />
                      </>
                    )}
                  </>
                )}

                <PageSectionV2C
                  smallTitle={TRANSACTION_LIMITS}
                  columns={transactionLimitsSectionData}
                  className='transaction-limits'
                />
              </>
            )}
          </PageSectionV2C>

          <PageSectionV2C
            title={MERCHANT_ONBOARDING_FORM_SETTINGS}
            subTitle='Set requirements for merchants to verify personal identity with a government-issued ID and selfie in our Onboarding Forms'
            columns={convertPageSectionDataToV2([{ label: 'Verification upon submission', value: displayBiometricDataCollection }])}
            actions={merchantOnboardingFormSettingsActions}
          />

          <PageSectionV2C
            title={CONTINUOUS_MONITORING_SETTINGS}
            subTitle={CONTINUOUS_MONITORING_SETTINGS_SUBTITLE}
            columns={continuousMonitoringSettingsData}
            actions={continuousMonitoringSettingsActions}
          />
        </>
      )}

      {(isDisbursementsApplication || isRolePlatformCredential) && (
        <PageSectionV2C
          title={RECIPIENT_SETTINGS}
          subTitle={RECIPIENT_SETTINGS_SUBTITLE}
          columns={recipientSettingsData}
          actions={recipientSettingsActions}
        />
      )}

      {showUnderwritingAutomationSettingsSection && (
        <PageSectionV2C
          className='underwriting-automation-section'
          title={UNDERWRITING_AUTOMATION_SETTINGS}
          isFetching={isFetching}
        >
          <div>
            <TableC
              columnDescriptors={workflowsColumnDescriptors}
              extraProps={extraProps}
              data={workflowsTableItems}
              isFetching={isFetching}
              showTableFooter={false}
              theme={infoTableTheme}
              actions={actions}
            />
          </div>
        </PageSectionV2C>
      )}
    </div>
  )
}

UnderwritingProfile.propTypes = {
  isFetching: PropTypes.bool,
  underwritingProfileActions: PropTypes.array,
  merchantOnboardingFormSettingsActions: PropTypes.array,
  transactionLimitsSectionData: PropTypes.array,
  clickToCopies: PropTypes.array,
  enabledMCCsTableItems: PropTypes.array,
  totalMCCsString: PropTypes.string,
  isUnderwritingProfileEmpty: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  isMCCsEmpty: PropTypes.bool,
  isRolePartnerCredential: PropTypes.bool,
  showEditModal: PropTypes.func,
  allowAllMCCs: PropTypes.bool,
  showUnderwritingAutomationSettingsSection: PropTypes.bool,
  workflowsTableItems: PropTypes.object,
  actions: PropTypes.array,
  workflowsColumnDescriptors: PropTypes.array,
  extraProps: PropTypes.object,
  displayBiometricDataCollection: PropTypes.string,
  continuousMonitoringSettingsData: PropTypes.array,
  continuousMonitoringSettingsActions: PropTypes.array,
  recipientSettingsData: PropTypes.array,
  isDisbursementsApplication: PropTypes.bool,
  recipientSettingsActions: PropTypes.array,
  isRolePlatformCredential: PropTypes.bool,
}

export default UnderwritingProfile
