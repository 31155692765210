import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { MERCHANT_PATH } from 'constants/pathConstants'
import { UPLOAD_MERCHANT_FILE } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  POST_MERCHANT_FILE_F_REQUEST,
  POST_IDENTITY_FILE_F_REQUEST,
} from 'constants/flowConstants'

const submitFileUploadForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const credentialId = get(credentials, 'id')
  const identityId = get(props, 'identityId')
  const merchantId = get(props, 'merchantId')
  const uploadForIdentity = get(props, 'uploadForIdentity', false)
  const redirectToFilesTab = get(props, 'redirectToFilesTab', false)
  const fileType = get(values, 'file.type')

  const valuesToSubmit = merge({}, values, { merchantId })

  dispatch({
    type: uploadForIdentity ? POST_IDENTITY_FILE_F_REQUEST : POST_MERCHANT_FILE_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPLOAD_MERCHANT_FILE, {
        credentials,
        merchantId,
        fileType,
      }),
      successCallback: () => {
        if (redirectToFilesTab) {
          const merchantFilesPath = `${MERCHANT_PATH({ credentialId, identityId, merchantId })}?tab=merchant-files`

          dispatch(redirectRequest({ path: merchantFilesPath }))
        }
      },
    },
  })
}

export default submitFileUploadForm
