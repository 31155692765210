import isEqual from 'lodash/isEqual'

const formatBusinessNameString = ({ businessName, doingBusinessAs }) => {
  if (!businessName && !doingBusinessAs) { return '-' }
  if (!businessName || !doingBusinessAs || (isEqual(businessName, doingBusinessAs))) { return businessName || doingBusinessAs }

  return `${doingBusinessAs} (${businessName})`
}

export default formatBusinessNameString
