import { VERMONT_SYSTEMS } from 'constants/platformConstants'

import {
  DUMMY_V1,
  LITLE_V1,
  VANTIV_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'vermontsystems',
  id: 'DASHoqGYd2EFYHBtE3XuuRUYzF',
  applicationName: VERMONT_SYSTEMS,
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2021-01-26-20_52_17-vermont-systems-logo.png',
  faviconImage: '',
  credentialsHost: 'https://vermontsystems.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: '1IIkg1iku8gN2DaaWDZJOX8tBhucpt0m',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: 'Vermont Systems',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'VANTIV_V1'],
  dashboardServiceV2: true, // using this to control dashboard service version
}
