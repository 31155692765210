import './AuthorizationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getReceiptDeliveryAttemptByReceiptId } from 'state-layer/selectors'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TransferFailureDetailsC from 'components/Customer/Shared/Display/TransferFailureDetails/TransferFailureDetailsC'
import TableC from 'components/Shared/Table/TableC'
import { transferFeesColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import { receiptDeliveryAttemptsColumnDescriptors } from './columnDescriptors'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_FEE_PROFILE_DUES_ASSESSMENTS } from 'constants/featureFlagConstants'
import { infoTableTheme, resourceTableThemeV2 } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  DETAILS,
  BUYER_DETAILS,
  ADDITIONAL_DATA,
  LEVEL_3_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE,
  LEVEL_2_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE,
  ITEM_INFORMATION,
  RISK_ANALYSIS,
  RECEIPT_HISTORY,
  RECEIPTS_EMPTY_MESSAGE,
  NO_RECEIPT_DELIVERY_ATTEMPTS,
  ASSOCIATED_FEES,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

import {
  GET_RECEIPT_DELIVERY_ATTEMPTS,
  PATCH_AUTHORIZATION_F_REQUEST,
} from 'constants/flowConstants'

const receiptTitle = (receipt) => <div className='receipt-title flex items-center'><h5>Receipt</h5> <ClickToCopyC position='right' content={get(receipt, 'id')} /></div>
const receiptSubtitle = (receipt) => `Created on ${get(receipt, 'displayCreatedAt')}`

const Authorization = ({
  detailsDataSection = [],
  paymentInstrument = {},
  tagsSectionData = [],
  isFetching = false,
  authorization = {},
  showError = false,
  headerData = {},
  contextBarData = {},
  device = {},
  buyerIdentity = {},
  actions = [],
  additionalPurchaseData = {},
  additionalLevelTwoDataSectionData = [],
  additionalLevelThreeDataSectionData = [],
  itemInformationCardsData = [],
  isLevelThreeEnabled = false,
  siftFraudScoreData = {},
  siftFraudScoreBadge = '',
  showRiskAnalysis = false,
  isEnterpriseSiftFraudScoreTier = false,
  receiptsArray = [],
  receiptsActions = [],
  receiptActions = [],
  hasDeliveryAttemptsForReceiptId = () => {},
  hasReceipts = false,
  feesPath = () => {},
  fees = [],
  showResponseModal,
}) => {
  return (
    <div className='Authorization'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        isFetching={isFetching}
        actions={actions}
        {...headerData}
      />

      { showError && <TransferFailureDetailsC transfer={authorization} /> }

      {isEnterpriseSiftFraudScoreTier && showRiskAnalysis && (
        <PageSectionV2C
          title={RISK_ANALYSIS}
          asideTitle={siftFraudScoreBadge}
          columns={siftFraudScoreData}
          isFetching={isFetching}
        />
      )}

      <PageSectionV2C
        title={DETAILS}
        columns={detailsDataSection}
        isFetching={isFetching}
        asideTitle={(
          <div className='view-api-response-link' onClick={showResponseModal}>
            {VIEW_API_RESPONSE}
          </div>
        )}
      />

      <BuyerDetailsC
        title={BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      {(!showError && !isFetching) && (
      <PageSectionV2C
        title={RECEIPT_HISTORY}
        actions={receiptsActions}
        emptyMessage={!hasReceipts && RECEIPTS_EMPTY_MESSAGE}
      >
        {map(receiptsArray, (receipt) => {
          const receiptId = get(receipt, 'id')
          return (
            <TableC
              title={receiptTitle(receipt)}
              subtitle={receiptSubtitle(receipt)}
              actions={receiptActions({ receipt, authorization })}
              theme={infoTableTheme}
              emptyMessageString={!hasDeliveryAttemptsForReceiptId(receiptId) && NO_RECEIPT_DELIVERY_ATTEMPTS}
              flow={GET_RECEIPT_DELIVERY_ATTEMPTS}
              flowValues={{ id: receiptId }}
              columnDescriptors={receiptDeliveryAttemptsColumnDescriptors}
              selector={getReceiptDeliveryAttemptByReceiptId}
              selectorId={receiptId}
              classNames='receipt-delivery-attempts-table'
              isFetching={isFetching}
              concurrent
              showTableFooter={false}
            />
          )
        })}
      </PageSectionV2C>
      )}

      {!isEmpty(additionalPurchaseData) && (
        <PageSectionV2C
          title={ADDITIONAL_DATA}
          className='additional-level-2-and-3-data-section'
          subTitle={isLevelThreeEnabled ? LEVEL_3_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE : LEVEL_2_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE}
          columns={isLevelThreeEnabled ? additionalLevelThreeDataSectionData : additionalLevelTwoDataSectionData}
          isFetching={isFetching}
        />
      )}

      {isLevelThreeEnabled && (
        <PageSectionV2C
          title={<h5>{ITEM_INFORMATION}</h5>}
          className='level-three-item-info-section'
          columns={itemInformationCardsData}
          isFetching={isFetching}
        />
      )}

      {getFeatureFlag(SHOW_FEE_PROFILE_DUES_ASSESSMENTS) && !isEmpty(fees) && (
        <TableC
          title={ASSOCIATED_FEES}
          columnDescriptors={transferFeesColumnDescriptors}
          data={fees}
          isFetching={isFetching}
          path={feesPath}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          classNames='Associated-Fees-Table'
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_AUTHORIZATION_F_REQUEST}
        data={tagsSectionData}
        record={authorization}
        isFetching={isFetching}
      />
    </div>
  )
}

Authorization.propTypes = {
  detailsDataSection: PropTypes.array,
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  authorization: PropTypes.object,
  raw: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  messages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  showError: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  device: PropTypes.object,
  buyerIdentity: PropTypes.object,
  actions: PropTypes.array,
  additionalLevelTwoDataSectionData: PropTypes.array,
  additionalLevelThreeDataSectionData: PropTypes.array,
  itemInformationCardsData: PropTypes.array,
  isLevelThreeEnabled: PropTypes.bool,
  additionalPurchaseData: PropTypes.object,
  isPremiumOrEnterpriseSiftFraudScoreTier: PropTypes.bool,
  siftFraudScoreData: PropTypes.array,
  siftFraudScoreBadge: PropTypes.object,
  showRiskAnalysis: PropTypes.bool,
  isEnterpriseSiftFraudScoreTier: PropTypes.bool,
  receiptsArray: PropTypes.array,
  receiptsActions: PropTypes.array,
  receiptActions: PropTypes.func,
  hasDeliveryAttemptsForReceiptId: PropTypes.func,
  hasReceipts: PropTypes.bool,
  feesPath: PropTypes.func,
  fees: PropTypes.array,
  showResponseModal: PropTypes.func,
}

export default Authorization
