// use this formatter to ensure only digits are entering into a field when passing into a Redux form field
import replace from 'lodash/replace'

const fieldsToSliceMap = {
  'processingData.amexMid': 10,
  'processingData.discoverMid': 15,
  'businessData.businessTaxId': 9,
  'bankAccountData.transitNumber': 5,
  'bankAccountData.institutionNumber': 3,
  routingNumber: 9,
  transitNumber: 5,
  institutionNumber: 3,
  accountNumber: 17,
  confirmAccountNumber: 17,
  'bankAccountData.bankCode': 9,
  'paymentInstrument.accountNumber': 17,
  'paymentInstrument.routingNumber': 9,
  'paymentInstrument.transitNumber': 5,
  'paymentInstrument.institutionNumber': 3,
  'paymentInstrument.postalCode': 5,
  'paymentInstrument.cvv': 4,
  'time.hour': 2,
  'time.minute': 2,
  visaMerchantVerificationValue: 6,
  mastercardAssignedId: 6,
}

const formatNumberField = (value, name) => {
  if (!value) {
    return value
  }

  const onlyNums = replace(value, /[^\d*]/g, '')

  if (fieldsToSliceMap[name]) {
    return onlyNums.slice(0, fieldsToSliceMap[name])
  }

  return onlyNums
}

export default formatNumberField
