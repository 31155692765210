import get from 'lodash/get'

import {
  PAYMENTS_PATH,
  PAYOUTS_PATH,
  HOME_PATH,
} from 'constants/pathConstants'

export const isPayoutFeature = ({ credentials }) => {
  const isPushToCard = get(credentials, 'features.payouts')
  const pushToCardEnabled = get(credentials, 'pushToCardEnabled')

  return pushToCardEnabled || isPushToCard || false
}

export const notPayoutFeature = ({ credentials }) => {
  const isPayFac = !isPayoutFeature({ credentials })
  const paymentFacilitatorEnabled = get(credentials, 'paymentFacilitatorEnabled')

  return paymentFacilitatorEnabled || isPayFac || false
}

export const getPathByCredential = (credentials) => {
  const credentialId = get(credentials, 'id')

  // return isPayoutFeature({ credentials }) ? PAYOUTS_PATH({ credentialId }) : PAYMENTS_PATH({ credentialId })
  return HOME_PATH({ credentialId })
}
