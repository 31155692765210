import React from 'react'
import PropTypes from 'prop-types'
import ComplianceForms from 'components/Customer/Pages/Compliance/ComplianceForms/ComplianceForms'

const ApplicationComplianceForms = ({
  complianceFormsPath = '',
  initialQueries,
  initialFilters,
  columnDescriptors = [],
  isFetchingComplianceForms = false,
  quickFilters,
  allowedFilters,
}) => {
  return (
    <div className='ApplicationComplianceForms'>
      <ComplianceForms
        complianceFormsPath={complianceFormsPath}
        allowedFilters={allowedFilters}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        isFetching={isFetchingComplianceForms}
        columnDescriptors={columnDescriptors}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ApplicationComplianceForms.propTypes = {
  complianceFormsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetchingComplianceForms: PropTypes.bool,
  columnDescriptors: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default ApplicationComplianceForms
