import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_SETTINGS } from 'constants/apiConstants'

const getComplianceSettingsAPI = ({ credentials, queries, meta }) => {
  return dashboardAPI.get({
    credentials,
    queries,
    meta,
    path: COMPLIANCE_SETTINGS,
  })
}

export default getComplianceSettingsAPI
