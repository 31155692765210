import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getSubscriptionScheduleAmountsRequest from 'utilities/actions/get/getSubscriptionScheduleAmountsRequest'
import { DELETE_SUBSCRIPTION_AMOUNT } from 'constants/amplitudeConstants'
import { DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST } from 'constants/flowConstants'

const submitDeleteSubscriptionScheduleAmountForm = (values = {}, dispatch, props) => {
  const { credentials, subscriptionScheduleId } = props
  const { subscriptionAmountId } = values

  dispatch({
    type: DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
    payload: {
      id: subscriptionAmountId,
      credentials,
      values: {
        subscriptionScheduleId,
      },
    },
    meta: {
      actionId: sendAmplitudeActionEvent(DELETE_SUBSCRIPTION_AMOUNT, {
        credentials,
        subscriptionScheduleId,
        subscriptionAmountId,
      }),
      successCallback: () => {
        dispatch(getSubscriptionScheduleAmountsRequest({
          subscriptionScheduleId,
          credentials,
          meta: { selectorId: subscriptionScheduleId },
        }))
      },
    },
  })
}

export default submitDeleteSubscriptionScheduleAmountForm
