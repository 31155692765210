import './MerchantFeeRiskProfileS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import FeeProfileC from 'components/Customer/Pages/FeeProfile/FeeProfileC'
import { RISK_PROFILE } from 'constants/language/languageConstants'
import { MERCHANT } from 'constants/dashboardConfigurationsConstants'

const MerchantFeeRiskProfile = ({
  riskProfileSectionData = [],
  riskProfileActions = [],
  isFetching = false,
  merchantId = '',
  merchantApplicationId = '',
  riskProfileClickToCopies = [],
  currency = 'USD',
  merchantIdentityId = '',
}) => {
  return (
    <div className='MerchantFeeRiskProfile'>
      <FeeProfileC
        entityId={merchantId}
        entityType={MERCHANT}
        merchantApplicationId={merchantApplicationId}
        currency={currency}
        identityId={merchantIdentityId}
      />

      <PageSectionV2C
        title={RISK_PROFILE}
        className='RiskProfile'
        clickToCopies={riskProfileClickToCopies}
        columns={riskProfileSectionData}
        actions={riskProfileActions}
        isFetching={isFetching}
      />
    </div>
  )
}

MerchantFeeRiskProfile.propTypes = {
  riskProfileSectionData: PropTypes.array,
  riskProfileActions: PropTypes.array,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  merchantApplicationId: PropTypes.string,
  riskProfileClickToCopies: PropTypes.array,
  merchantIdentityId: PropTypes.string,
}

export default MerchantFeeRiskProfile
