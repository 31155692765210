import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditPayoutProfileFormC from 'components/Customer/Forms/EditPayoutProfileForm/EditPayoutProfileFormC'
import { EDIT_PAYOUT_PROFILE } from 'constants/language/languageConstants'

const EditPayoutProfileModal = ({
  credentialId,
  disbursementsAchPullEnabled,
  disbursementsAchPushEnabled,
  disbursementsCardPullEnabled,
  disbursementsCardPushEnabled,
  disbursementsSameDayAchPushEnabled,
  disbursementsSameDayAchPullEnabled,
  payoutProfileInfo,
  applicationId,
}) => {
  return (
    <GenericModal
      className='EditPayoutProfileModal'
      credentialId={credentialId}
      title={EDIT_PAYOUT_PROFILE}
      Component={EditPayoutProfileFormC}
      disbursementsAchPullEnabled={disbursementsAchPullEnabled}
      disbursementsAchPushEnabled={disbursementsAchPushEnabled}
      disbursementsCardPullEnabled={disbursementsCardPullEnabled}
      disbursementsCardPushEnabled={disbursementsCardPushEnabled}
      disbursementsSameDayAchPushEnabled={disbursementsSameDayAchPushEnabled}
      disbursementsSameDayAchPullEnabled={disbursementsSameDayAchPullEnabled}
      payoutProfileInfo={payoutProfileInfo}
      applicationId={applicationId}
    />
  )
}


EditPayoutProfileModal.propTypes = {
  credentialId: PropTypes.string,
  disbursementsAchPullEnabled: PropTypes.bool,
  disbursementsAchPushEnabled: PropTypes.bool,
  disbursementsCardPullEnabled: PropTypes.bool,
  disbursementsCardPushEnabled: PropTypes.bool,
  disbursementsSameDayAchPushEnabled: PropTypes.bool,
  disbursementsSameDayAchPullEnabled: PropTypes.bool,
  payoutProfileInfo: PropTypes.object,
  applicationId: PropTypes.string,
}

export default EditPayoutProfileModal
