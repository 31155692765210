import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_ENROLLMENT,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const deleteSubscriptionEnrollmentAPI = ({ id, queries, credentials, meta }) => {
  return paymentsAPI.delete({
    meta,
    credentials,
    path: SUBSCRIPTION_ENROLLMENT({ subscriptionEnrollmentId: id }),
    queries,
    service: SUBSCRIPTION,
  })
}

export default deleteSubscriptionEnrollmentAPI
