import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CUSTOMER_ONBOARDING_FORMS } from 'constants/apiConstants'

const postCustomerOnboardingFormAPI = ({ values }) => {
  return dashboardAPI.post({
    path: DASHBOARD_CUSTOMER_ONBOARDING_FORMS,
    values,
  })
}

export default postCustomerOnboardingFormAPI
