import React from 'react'
import { payoutColumnDescriptors } from '../columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import getMany from 'utilities/get/getMany'
import { infoTableTheme } from 'constants/themeConstants'
import get from 'lodash/get'

const DailyPayoutsTable = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displayPayoutCreatedAtDay,
    displayPayoutCreatedAtDayPrior,
    displayPayoutAmountDollarsSum,
    displayPayoutAmountDollarsSumPriorDay,
    displayPayoutAmountDollarsAverage,
    displayPayoutAmountDollarsAveragePriorDay,
    payoutCount,
    payoutCountPriorDay,
  ] = getMany(calculations, [
    'displayPayoutCreatedAtDay',
    'displayPayoutCreatedAtDayPrior',
    'displayPayoutAmountDollarsSum',
    'displayPayoutAmountDollarsSumPriorDay',
    'displayPayoutAmountDollarsAverage',
    'displayPayoutAmountDollarsAveragePriorDay',
    'payoutCount',
    'payoutCountPriorDay',
  ])

  const payoutRows = [
    {
      createdAt: displayPayoutCreatedAtDay,
      sum: displayPayoutAmountDollarsSum,
      average: displayPayoutAmountDollarsAverage,
      count: payoutCount,
    },
    {
      createdAt: displayPayoutCreatedAtDayPrior,
      sum: displayPayoutAmountDollarsSumPriorDay,
      average: displayPayoutAmountDollarsAveragePriorDay,
      count: payoutCountPriorDay,
    },
  ]

  return (
    <TableC
      classNames='alert-insights-table'
      showTableFooter={false}
      theme={infoTableTheme}
      columnDescriptors={payoutColumnDescriptors}
      data={payoutRows}
    />
  )
}

export default DailyPayoutsTable
