import { DASHBOARD } from 'constants/pathConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import includes from 'lodash/includes'

import {
  PATH_TO_PAGE_MAP,
  NON_LIST_VIEWS,
} from 'constants/pageConstants'

const getPageName = (path = '', queries = {}) => {
  const parts = split(path, '/')

  if (parts[0] === DASHBOARD) {
    const credentialId = parts[1]

    if (credentialId) {
      const firstPage = parts[2]

      if (firstPage) {
        // TODO: in future check if there is a deep route and/or tab
        const pageName = get(PATH_TO_PAGE_MAP, firstPage)

        const viewPage = parts[3] || includes(NON_LIST_VIEWS, pageName)

        if (pageName) {
          // check if we are on a tab!
          const tab = get(queries, 'tab')

          if (tab) {
            // TODO: make a mao for tab -> page name, or modify the tab, or send a tab query?
          }

          return viewPage ? `${pageName} View` : `${pageName} List`
        }
      }
    } else {
      // We are in the credentials page
      return get(PATH_TO_PAGE_MAP, DASHBOARD)
    }
  } else {
    return get(PATH_TO_PAGE_MAP, path)
  }

  return null
}

export default getPageName
