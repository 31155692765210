import paymentsAPI from 'utilities/api/paymentsAPI'
import { WEBHOOKS } from 'constants/apiConstants'

const postWebhooksAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: WEBHOOKS,
  })
}

export default postWebhooksAPI
