import moment from 'moment'
import { DATE_FORMAT } from 'constants/timeConstants'
import getMany from 'utilities/get/getMany'

const formatDateObject = ({ date, format = DATE_FORMAT }) => {
  const [
    month,
    day,
    year,
  ] = getMany(date, [
    'month',
    'day',
    'year',
  ])

  return (month && day && year) ? moment.utc(new Date(`${month}/${day}/${year}`)).format(format) : undefined
}

export default formatDateObject
