import './DisputeEvidencesSectionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import DisputeEvidencesSectionTable from './DisputeEvidencesSectionTable'
import MultiFileUploader from 'components/Shared/Inputs/MultiFileUploader/MultiFileUploader'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import Loader from 'components/Shared/Loader/Loader'
import ExpandableButtonC from 'components/Shared/ExpandableButton/ExpandableButtonC'
import SubmitEvidenceFormC from 'components/Customer/Forms/DisputeSubmitEvidenceForm/DisputeSubmitEvidenceFormC'
import { FILE_UPLOAD_ICON } from 'constants/iconConstants'
import { MAX_EVIDENCE_FILES_ALLOWED } from 'constants/disputeConstants'
import size from 'lodash/size'

import {
  DISPUTE_EVIDENCE_LEARN_MORE_LINK,
  ACCEPTING_LIABILITY_LEARN_MORE_LINK,
} from 'constants/urlConstants'

import {
  SUBMIT_EVIDENCE_FOR_THIS_DISPUTE,
  SUBMIT_EVIDENCE_FOR_DISPUTE_INSTRUCTIONS,
  SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_NOTE,
  SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_1,
  SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_2,
  SUBMIT_DISPUTE_EVIDENCE_LEARN_MORE_MSG,
  SUBMIT_DISPUTE_EVIDENCE_FILES_NOTE,
  SUBMIT_EVIDENCE,
  SUBMITTING_EVIDENCE,
  ACCEPT_LIABILITY,
  ACCEPT_LIABILITY_QUESTION,
  ACCEPTING_LIABILITY,
  ACCEPTING_LIABILITY_TOOLTIP_MSG,
  ACCEPTING_LIABILITY_LEARN_MORE_MSG,
  MAX_FILES_UPLOADED,
  ADD_DETAILS_AND_SUBMIT,
} from 'constants/language/languageConstants'

const DisputeEvidencesSection = ({
  disputeId = '',
  disputeAmount = '',
  showAcceptLiabilityModal = () => {},
  onEvidencesFilesDrop = () => {},
  disputeEvidenceFiles = {},
  evidenceFilesUploaded = false,
  isUploading = false,
  isFetching = false,
  uploadErrorMsg = '',
  customValidatorDisplay = () => {},
  hideHyperlinks = false,
}) => {
  const submittingEvidenceTooltipMsg = (
    <div>
      <div className='bold'>{SUBMITTING_EVIDENCE}</div>
      <div className='content'>{SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_1} <span className='bold'>{SUBMIT_EVIDENCE}.</span>
        <span> {SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_2}</span>
      </div>
      <div className='content note'>{SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_NOTE}</div>
      {!hideHyperlinks && (<a className='text-link' href={DISPUTE_EVIDENCE_LEARN_MORE_LINK} target='blank'>{SUBMIT_DISPUTE_EVIDENCE_LEARN_MORE_MSG}</a>)}
    </div>
  )

  const acceptingLiabilityTooltipMsg = (
    <div>
      <div className='tooltip-title'>{ACCEPTING_LIABILITY}</div>
      <div className='content'>{ACCEPTING_LIABILITY_TOOLTIP_MSG}</div>
      {!hideHyperlinks && (<a className='text-link' href={ACCEPTING_LIABILITY_LEARN_MORE_LINK} target='blank'>{ACCEPTING_LIABILITY_LEARN_MORE_MSG}</a>)}
    </div>
  )

  const uploadInstructions = (
    <span>Drag and drop files or <a className='text-link'>click here</a> to upload -  Max {MAX_EVIDENCE_FILES_ALLOWED} files: 1MB/file. Accepted formats: JPG, PDF</span>
  )

  const config = {
    multiple: true,
    maxSize: 1048576,
    accept: 'image/jpeg, application/pdf',
  }

  return (
    <div className='DisputeEvidencesSection'>
      {isFetching ? <Loader /> : (
        <>
          <div className='title-area flex items-center'>
            <h6>{SUBMIT_EVIDENCE_FOR_THIS_DISPUTE}</h6>
            <TooltipLabel message={submittingEvidenceTooltipMsg} />
          </div>

          <div>{SUBMIT_EVIDENCE_FOR_DISPUTE_INSTRUCTIONS}</div>

          <MultiFileUploader
            instructions={uploadInstructions}
            onDrop={onEvidencesFilesDrop}
            isUploading={isUploading}
            disabled={size(disputeEvidenceFiles) >= 8}
            disabledMessage={<div className='disabled-message'><i className={`fa fa-${FILE_UPLOAD_ICON}`} />{MAX_FILES_UPLOADED}</div>}
            errorMessage={uploadErrorMsg}
            onDropRejected={customValidatorDisplay}
            config={config}
          />

          <div className='table-area'>
            {evidenceFilesUploaded && <div>{SUBMIT_DISPUTE_EVIDENCE_FILES_NOTE}</div>}

            {evidenceFilesUploaded && (
            <DisputeEvidencesSectionTable
              isFetching={isFetching}
              disputeEvidenceFiles={disputeEvidenceFiles}
            />
            )}
          </div>

          {evidenceFilesUploaded && (
            <ExpandableButtonC
              label={ADD_DETAILS_AND_SUBMIT}
              Component={SubmitEvidenceFormC}
              buttonClassName='add-details-submit-btn'
              extraProps={{ disputeId, disputeAmount }}
            />
          )}

          <div className='accept-liability-section flex items-center'>
            <span className='question-text'>{ACCEPT_LIABILITY_QUESTION}</span>
            <span className='accept-liability-text'>You can <span className='accept-liability-link' onClick={() => showAcceptLiabilityModal({ disputeId })}>{ACCEPT_LIABILITY}</span></span>
            <TooltipLabel message={acceptingLiabilityTooltipMsg} />
          </div>
        </>
      )}
    </div>
  )
}

DisputeEvidencesSection.propTypes = {
  disputeId: PropTypes.string,
  showAcceptLiabilityModal: PropTypes.func,
  onEvidencesFilesDrop: PropTypes.func,
  evidenceFilesUploaded: PropTypes.bool,
  disputeEvidenceFiles: PropTypes.object,
  isUploading: PropTypes.bool,
  disputeAmount: PropTypes.string,
  isFetching: PropTypes.bool,
  customValidatorDisplay: PropTypes.func,
  hideHyperlinks: PropTypes.bool,
}

export default DisputeEvidencesSection
