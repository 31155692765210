import React from 'react'
import PropTypes from 'prop-types'
import IdentitySettlementFormC from 'components/Customer/Forms/IdentitySettlementForm/IdentitySettlementFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const IdentitySettlementModal = ({ identityId }) => {
  return (
    <GenericModal
      title='Create a settlement for identity'
      className='IdentitySettlementModal'
      Component={IdentitySettlementFormC}
      identityId={identityId}
    />
  )
}

IdentitySettlementModal.propTypes = {
  identityId: PropTypes.string,
}

export default IdentitySettlementModal
