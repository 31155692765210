import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_NOTIFICATIONS } from 'constants/apiConstants'

const getNotificationsAPI = ({ credentials, queries }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_NOTIFICATIONS,
    credentials,
    queries,
  })
}

export default getNotificationsAPI
