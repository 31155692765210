import './MonitoringAlertS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const MonitoringAlert = ({
  isFetching = false,
  actions = [],
  headerData = {},
  contextBarData = {},
  tabs = [],
  monitoringAlert = {},
}) => {
  return (
    <div className='MonitoringAlert'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <TabsC
        tabs={tabs}
        monitoringAlert={monitoringAlert}
      />
    </div>
  )
}

MonitoringAlert.propTypes = {
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  tabs: PropTypes.array,
  monitoringAlert: PropTypes.object,
}

export default MonitoringAlert
