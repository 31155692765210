import { GET_CURRENT_USER_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'
import get from 'lodash/get'

const getCurrentUserRequest = ({
  credentials,
  currentUserEmail,
  successCallback = () => {},
}) => createAction({
  id: get(credentials, 'username'),
  type: GET_CURRENT_USER_F_REQUEST,
  credentials,
  values: {
    currentUserEmail,
  },
  meta: {
    overwriteReducer: true,
    successCallback,
  },
})

export default getCurrentUserRequest
