import { PATCH_ONBOARDING_FORM_DOCUMENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const patchOnboardingFormDocumentsRequest = ({
  files,
  fileType,
  onboardingFormId,
  applicationId,
  associatedFiles,
}) => createAction({
  type: PATCH_ONBOARDING_FORM_DOCUMENTS_F_REQUEST,
  id: onboardingFormId,
  values: {
    files,
    fileType,
    applicationId,
    associatedFiles,
  },
})

export default patchOnboardingFormDocumentsRequest
