import React from 'react'
import { getDispatch } from 'state-layer/configureStore'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import column from 'utilities/table/column'
import showModalAction from 'utilities/actions/showModalAction'
import { lastActivityRange } from 'utilities/table/sort'
import { ENVIRONMENT_ICON_MAP } from 'constants/iconConstants'
import { roleTypeMap } from 'constants/roleConstants'
import { EDIT_DASHBOARD_ACCOUNT_NAME_MODAL } from 'constants/modalConstants'

import {
  ACCOUNT_NAME,
  EDIT_DASHBOARD_ACCOUNT_NAME,
  ENVIRONMENT,
  LAST_ACCESSED,
  VIEW,
  YOUR_ROLE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ACCOUNT_NAME, 'name', { className: 'x-large' }),
  column(ENVIRONMENT, 'environment', {
    className: 'small',
    formatter: ({ environment }) => (
      <span className='environment'>
        <i className={`fa fa-${ENVIRONMENT_ICON_MAP[environment]}`} />
        <span className='environment-label'>{environment}</span>
      </span>
    ),
  }),
  column(VIEW, 'role', {
    className: 'small',
    formatter: ({ role }) => roleTypeMap[role],
  }),
  column(YOUR_ROLE, 'dashboardUserRoleName', {
    className: 'medium',
  }),
  column(LAST_ACCESSED, 'displayLastActivity', {
    className: 'grow',
    sort: lastActivityRange,
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: ({ id, name }) => {
      const dispatch = getDispatch()

      const modalProps = {
        credentialId: id,
        dashboardAccountName: name,
      }

      const actions = [
        {
          label: EDIT_DASHBOARD_ACCOUNT_NAME,
          action: () => dispatch(showModalAction({ modalType: EDIT_DASHBOARD_ACCOUNT_NAME_MODAL, modalProps })),
        },
      ]

      return (
        <TableRowActionsC
          actions={actions}
        />
      )
    },
  }),
]

export default columnDescriptors
