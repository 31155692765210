import './CreatePayoutLinkFormS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ChromePicker } from 'react-color'
import { goBack } from 'state-layer/history'
import Loader from 'components/Shared/Loader/Loader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import Button from 'components/Shared/Button/Button'
import ButtonGroup from 'components/Shared/ButtonGroup/ButtonGroup'
import PayoutLinkItems from 'components/Customer/Forms/CreatePayoutLinkForm/PayoutLinkItems'
import PaymentLinkCustomFields from 'components/Customer/Forms/CreatePaymentLinkForm/PaymentLinkCustomFields/PaymentLinkCustomFields'
import GuestPayoutLinkFormC from 'components/Customer/Forms/GuestPayoutLinkForm/GuestPayoutLinkFormC'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import DatePicketInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import submitCreatePayoutLinkForm from 'utilities/submit/submitCreatePayoutLinkForm'
import validateCreatePayoutLinkForm from 'utilities/validate/validateCreatePayoutLinkForm'
import { CREATE_PAYOUT_LINK_FORM } from 'constants/formConstants'
import { DATE_PICKER_DATE_FORMAT } from 'constants/timeConstants'
import get from 'lodash/get'

import {
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form'

import {
  ADVANCED_SETTINGS,
  ALLOWED_PAYOUT_METHODS,
  ASK_FOR_MORE_INFORMATION,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  CANCEL,
  BANK_TRANSFER,
  CREATE_PAYOUT_LINK,
  CREDIT_AND_DEBIT_CARDS,
  CUSTOM,
  CUSTOM_BRANDING,
  CUSTOM_EXPIRED_SESSION_URL,
  CUSTOM_FAILURE_RETURN_URL,
  CUSTOM_FIELDS,
  CUSTOM_SUCCESS_RETURN_URL,
  DESKTOP,
  EMAIL,
  EXPIRE_ON,
  FORTY_EIGHT_HOURS,
  GENERAL_INFORMATION,
  HEADER_COLOR,
  LINK_NAME,
  LINK_NAME_PLACEHOLDER,
  LINK_VALIDITY,
  LOGO_URL,
  LOGO_URL_PLACEHOLDER,
  MOBILE,
  ONE_WEEK,
  ONLY_COLLECT_NAME_ADDRESS_AND_BUSINESS_DETAILS,
  PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP,
  PAYMENT_LINK_BRANDING_HEADER_TOOLTIP,
  PAYMENT_LINK_EXPIRATION_HELPER_TEXT,
  PAYOUT_LINK_BRANDING_LOGO_TOOLTIP,
  PAYOUT_LINK_NAME_HELPER_TEXT,
  PHONE_NUMBER, PREVIEW,
  RECIPIENT_DETAILS,
  RECIPIENT_INFORMATION,
  RECIPIENT_NAME,
  RECIPIENT_NAME_TOOLTIP_MSG,
  SIXTY_DAYS,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_SERVICE_URL_HELPER_TEXT,
  TERMS_OF_SERVICE_URL_TOOLTIP,
  TWENTY_FOUR_HOURS,
  USE_CUSTOM_BRANDING,
  USE_CUSTOM_BRANDING_PAYOUT_LINK_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const CreatePayoutLinkForm = ({
  handleSubmit = () => {},
  isFetching = false,
  isPosting = false,
  previewPayoutLink = {},
  guestPayoutLinkComponentKey = '',
  currentItems = [],
  showExpirationDatePicker = false,
  collectMoreRecipientDetails = false,
  collectCustomFields = false,
  useCustomBranding = false,
  currentPayoutLinkHeaderColor = '',
  headerColor = '',
  buttonTextColor = '',
  buttonBackgroundColor = '',
  handleClick = () => {},
  handleClose = () => {},
  updateColor = () => {},
  displayHeaderColorPicker = false,
  displayButtonBackgroundColorPicker = false,
  displayButtonTextColorPicker = false,
  invalid = false,
  recipientNameOptions = [],
  submitting = false,
}) => {
  const [activePreviewMode, setActivePreviewMode] = useState('mobile')

  return (
    <form className='CreatePayoutLinkForm' onSubmit={handleSubmit(submitCreatePayoutLinkForm)}>
      <div className='content-container flex'>
        <div className='form-content'>
          <div className='form-holder'>
            <div className='form-header'>
              <h3 className='title'>{CREATE_PAYOUT_LINK}</h3>
            </div>

            { isFetching && <Loader />}

            { !isFetching && (
              <div className='form-body'>
                <Accordion title={GENERAL_INFORMATION} className='borderless' open>
                  <Field
                    name='nickname'
                    label={LINK_NAME}
                    placeholder={LINK_NAME_PLACEHOLDER}
                    helperText={PAYOUT_LINK_NAME_HELPER_TEXT}
                    component={InputField}
                    required={false}
                  />

                  <div className='items'>
                    <FieldArray
                      name='items'
                      component={PayoutLinkItems}
                      currentItems={currentItems}
                    />
                  </div>

                  <Field
                    name='termsOfServiceUrl'
                    label={TERMS_OF_SERVICE_URL}
                    placeholder='https://www.example.com/terms-of-service'
                    component={InputField}
                    helperText={TERMS_OF_SERVICE_URL_HELPER_TEXT}
                    tooltip={TERMS_OF_SERVICE_URL_TOOLTIP}
                  />
                </Accordion>

                <Accordion title={RECIPIENT_INFORMATION} className='borderless'>
                  <Field
                    name='recipient'
                    label={RECIPIENT_NAME}
                    component={ReactSelect}
                    options={recipientNameOptions}
                    helperText={RECIPIENT_NAME_TOOLTIP_MSG}
                    filterOption={({ data, value }, input) => {
                      const name = get(data, 'data.name', '')
                      const normalizedInput = input?.toLowerCase()
                      return name?.toLowerCase().includes(normalizedInput) || value?.toLowerCase().includes(normalizedInput)
                    }}
                    formatOptionLabel={(option, { context }) => {
                      return context === 'menu' ? get(option, 'label') : get(option, 'data.name')
                    }}
                    required={false}
                  />
                </Accordion>

                <Accordion title={ALLOWED_PAYOUT_METHODS} className='borderless'>
                  <Field
                    component={CheckboxC}
                    name='allowPaymentCard'
                    label={CREDIT_AND_DEBIT_CARDS}
                    checked
                  />

                  <Field
                    component={CheckboxC}
                    name='allowBankTransfer'
                    label={BANK_TRANSFER}
                    checked
                  />
                </Accordion>

                <Accordion title={ADVANCED_SETTINGS} className='borderless'>
                  <Field
                    label={LINK_VALIDITY}
                    name='expiresIn'
                    component={RadioButton}
                    options={[
                      { label: TWENTY_FOUR_HOURS, value: '24' }, // 1440 minutes
                      { label: FORTY_EIGHT_HOURS, value: '48' }, // 2880 minutes
                      { label: ONE_WEEK, value: '168' }, // 10080 minutes
                      { label: SIXTY_DAYS, value: '1440' }, // 86400 minutes
                      { label: CUSTOM, value: 'custom' }, // set by calendar picker
                    ]}
                  />

                  { showExpirationDatePicker && (
                    <Field
                      name='expirationDate'
                      label={EXPIRE_ON}
                      component={DatePicketInput}
                      minDate={moment().add(1, 'days').toDate()}
                      maxDate={moment().add(1, 'years').toDate()}
                      dateFormat={DATE_PICKER_DATE_FORMAT}
                      placeholderText='MM/DD/YYYY'
                      helperText={PAYMENT_LINK_EXPIRATION_HELPER_TEXT}
                      autofill={false}
                    />
                  )}

                  <Field
                    label={RECIPIENT_DETAILS}
                    name='collectRecipientDetails'
                    component={RadioButton}
                    options={[
                      { label: ONLY_COLLECT_NAME_ADDRESS_AND_BUSINESS_DETAILS, value: 'name' },
                      { label: ASK_FOR_MORE_INFORMATION, value: 'more' },
                    ]}
                  />

                  { collectMoreRecipientDetails && (
                    <div className='more-recipient-details'>
                      <Field
                        component={CheckboxC}
                        name='collectEmail'
                        label={EMAIL}
                      />

                      <Field
                        component={CheckboxC}
                        name='collectPhoneNumber'
                        label={PHONE_NUMBER}
                      />

                      <Field
                        component={CheckboxC}
                        name='collectCustomFields'
                        label={CUSTOM_FIELDS}
                      />

                      { collectCustomFields && (
                        <div className='custom-fields'>
                          <FieldArray
                            name='customFields'
                            component={PaymentLinkCustomFields} // re-using payment links component
                            isPayoutLink
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <Field
                    name='successReturnUrl'
                    label={CUSTOM_SUCCESS_RETURN_URL}
                    placeholder='https://www.example.com/success.html'
                    component={InputField}
                    required={false}
                  />

                  <Field
                    name='unsuccessfulReturnUrl'
                    label={CUSTOM_FAILURE_RETURN_URL}
                    placeholder='https://www.example.com/failure.html'
                    component={InputField}
                    required={false}
                  />

                  <Field
                    name='expiredSessionUrl'
                    label={CUSTOM_EXPIRED_SESSION_URL}
                    placeholder='https://www.example.com/expired.html'
                    component={InputField}
                    required={false}
                  />
                </Accordion>

                { useCustomBranding && (
                  <Accordion title={CUSTOM_BRANDING} className='borderless' open>
                    <Field
                      name='payoutLinkLogoURL'
                      label={LOGO_URL}
                      placeholder={LOGO_URL_PLACEHOLDER}
                      component={InputField}
                      required={false}
                      tooltip={PAYOUT_LINK_BRANDING_LOGO_TOOLTIP}
                      tooltipPosition='right'
                    />

                    <div className='flex items-center'>
                      <Field
                        name='payoutLinkHeaderColor'
                        label={HEADER_COLOR}
                        placeholder={currentPayoutLinkHeaderColor}
                        component={InputField}
                        tooltip={PAYMENT_LINK_BRANDING_HEADER_TOOLTIP}
                        tooltipPosition='right'
                        required={false}
                      />

                      <div>
                        <div
                          className='color-block'
                          style={{ backgroundColor: headerColor }}
                          onClick={() => handleClick('displayHeaderColorPicker')}
                        />
                        { displayHeaderColorPicker ? (
                          <div className='color-picker-popup'>
                            <div className='color-picker-cover' onClick={() => handleClose('displayHeaderColorPicker')} />
                            <ChromePicker
                              color={headerColor}
                              onChange={(color) => { updateColor('payoutLinkHeaderColor', color) }}
                              disableAlpha
                            />
                          </div>
                        ) : null }
                      </div>
                    </div>

                    <div className='flex items-center'>
                      <Field
                        name='payoutLinkButtonBackgroundColor'
                        label={BUTTON_BACKGROUND_COLOR}
                        placeholder='#0B5DBC'
                        component={InputField}
                        tooltip={PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP}
                        tooltipPosition='right'
                        required={false}
                      />
                      <div>
                        <div
                          className='color-block'
                          style={{ backgroundColor: buttonBackgroundColor }}
                          onClick={() => handleClick('displayButtonBackgroundColorPicker')}
                        />
                        { displayButtonBackgroundColorPicker ? (
                          <div className='color-picker-popup'>
                            <div className='color-picker-cover' onClick={() => handleClose('displayButtonBackgroundColorPicker')} />
                            <ChromePicker
                              color={buttonBackgroundColor}
                              onChange={(color) => { updateColor('payoutLinkButtonBackgroundColor', color) }}
                              disableAlpha
                            />
                          </div>
                        ) : null }
                      </div>
                    </div>

                    <div className='flex items-center'>
                      <Field
                        name='payoutLinkButtonColor'
                        label={BUTTON_TEXT_COLOR}
                        placeholder='#FFFFFF'
                        component={InputField}
                        tooltip={PAYOUT_LINK_BRANDING_LOGO_TOOLTIP}
                        tooltipPosition='right'
                        required={false}
                      />

                      <div>
                        <div
                          className='color-block'
                          style={{ backgroundColor: buttonTextColor }}
                          onClick={() => handleClick('displayButtonTextColorPicker')}
                        />
                        { displayButtonTextColorPicker ? (
                          <div className='color-picker-popup'>
                            <div className='color-picker-cover' onClick={() => handleClose('displayButtonTextColorPicker')} />
                            <ChromePicker
                              color={buttonTextColor}
                              onChange={(color) => { updateColor('payoutLinkButtonTextColor', color) }}
                              disableAlpha
                            />
                          </div>
                        ) : null }
                      </div>
                    </div>
                  </Accordion>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='preview flex column'>
          { isFetching && <Loader />}

          { !isFetching && (
            <>
              <div className='preview-header flex space-between'>
                <div className='flex column'>
                  <h5>{PREVIEW}</h5>
                </div>

                <ButtonGroup
                  options={[
                    { label: MOBILE, onClick: () => setActivePreviewMode('mobile') },
                    { label: DESKTOP, onClick: () => setActivePreviewMode('desktop') },
                  ]}
                />
              </div>

              <Field
                component={CheckboxC}
                className='use-custom-branding-checkbox'
                name='useCustomBranding'
                label={USE_CUSTOM_BRANDING}
                tooltip={USE_CUSTOM_BRANDING_PAYOUT_LINK_TOOLTIP_MSG}
              />

              <div className='preview-container flex flex-grow items-center'>
                { activePreviewMode === 'mobile' && (
                  <div className='mobile-container'>
                    <div className='mobile-line line-1' />
                    <div className='mobile-line line-2' />
                    <div className='mobile-line line-3' />
                    <div className='mobile-line line-4' />

                    <div className='mobile-content-wrapper'>
                      <GuestPayoutLinkFormC
                        openOrderSummary
                        payoutLink={previewPayoutLink}
                        previewMode='mobile'
                        isPreviewMode
                        key={guestPayoutLinkComponentKey}
                      />
                    </div>
                  </div>
                )}

                { activePreviewMode === 'desktop' && (
                  <div className='desktop-size-container'>
                    <div className='desktop-container'>
                      <div className='container-1'>
                        <div className='desktop-content-wrapper'>
                          <GuestPayoutLinkFormC
                            openOrderSummary
                            payoutLink={previewPayoutLink}
                            previewMode='desktop'
                            isPreviewMode
                            key={guestPayoutLinkComponentKey}
                          />
                        </div>
                      </div>
                      <div className='container-2' />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className='form-footer'>
        <Button variant='ghost' label={CANCEL} onClick={goBack} />
        <Button type='submit' submitting={submitting} label={CREATE_PAYOUT_LINK} disabled={isPosting || invalid} />
      </div>
    </form>
  )
}

CreatePayoutLinkForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  isPosting: PropTypes.bool,
  previewPayoutLink: PropTypes.object,
  guestPayoutLinkComponentKey: PropTypes.string,
  currentItems: PropTypes.array,
  showExpirationDatePicker: PropTypes.bool,
  collectMoreRecipientDetails: PropTypes.bool,
  collectCustomFields: PropTypes.bool,
  useCustomBranding: PropTypes.bool,
  currentPayoutLinkHeaderColor: PropTypes.string,
  headerColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  updateColor: PropTypes.func,
  displayHeaderColorPicker: PropTypes.bool,
  displayButtonBackgroundColorPicker: PropTypes.bool,
  displayButtonTextColorPicker: PropTypes.bool,
  invalid: PropTypes.bool,
  recipientNameOptions: PropTypes.array,
  submitting: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_PAYOUT_LINK_FORM,
  validate: validateCreatePayoutLinkForm,
})(CreatePayoutLinkForm)
