import './WelcomeNameCaptureFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitWelcomeNameCaptureForm from 'utilities/submit/submitWelcomeNameCaptureForm'
import validateWelcomeNameCaptureForm from 'utilities/validate/validateWelcomeNameCaptureForm'
import { WELCOME_NAME_CAPTURE_FORM } from 'constants/formConstants'

import {
  WELCOME_MODAL_NAME_CHANGE_INSTRUCTIONS,
  FIRST_NAME,
  LAST_NAME,
  ADD,
} from 'constants/language/languageConstants'

const WelcomeNameCaptureForm = ({
  handleSubmit = () => {},
  invalid = false,
}) => {
  return (
    <form className='WelcomeNameCaptureForm' onSubmit={handleSubmit(submitWelcomeNameCaptureForm)}>
      <div className='instructions'>{WELCOME_MODAL_NAME_CHANGE_INSTRUCTIONS}</div>

      <Field
        name='firstName'
        label={FIRST_NAME}
        component={InputField}
      />

      <Field
        name='lastName'
        label={LAST_NAME}
        component={InputField}
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={invalid} label={ADD} />
      </div>
    </form>
  )
}

WelcomeNameCaptureForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: WELCOME_NAME_CAPTURE_FORM,
  validate: validateWelcomeNameCaptureForm,
})(WelcomeNameCaptureForm)
