import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFER,
  TRANSFERS,
} from 'constants/apiConstants'

const patchTransferAPI = ({ values, credentials, id, meta }) => {
  const path = TRANSFER({ transferId: id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: TRANSFERS,
  })
}

export default patchTransferAPI
