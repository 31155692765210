import './SetIdleScreenFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import submitSetIdleScreenForm from 'utilities/submit/submitSetIdleScreenForm'
import validateSetIdleScreenForm from 'utilities/validate/validateSetIdleScreenForm'
import { SET_IDLE_SCREEN_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'

import {
  IDLE_MESSAGE,
  CANCEL,
  IDLE_SCREEN_IMAGE,
  SAVE,
  RESET,
} from 'constants/language/languageConstants'

const SetIdleScreenForm = ({
  handleSubmit = () => {},
  invalid = false,
  pristine = false,
  merchantName = '',
  currentIdleMessageValue,
  isUpdating = false,
  closeModal = () => {},
  config = {},
  instructions = [],
  clearForm = () => {},
  showFileUploader = false,
}) => {
  return (
    <form className='SetIdleScreenForm' onSubmit={handleSubmit(submitSetIdleScreenForm)}>
      <div className='modal-content'>
        <Field
          name='idleMessage'
          className='idle-message-field'
          label={IDLE_MESSAGE}
          component={InputField}
          placeholder={`Welcome to ${merchantName}`}
          required={false}
        />

        { showFileUploader && (
          <Field
            name='file'
            className='idle-image-field'
            label={IDLE_SCREEN_IMAGE}
            component={FileUploader}
            config={config}
            instructions={instructions}
          />
        )}
      </div>

      <div className='buttons flex flex-end'>
        <div className='reset-button-container'>
          <Button onClick={clearForm} label={RESET} variant='tertiary' className='reset-button' />
        </div>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' submitting={isUpdating} disabled={invalid || (pristine && !isEmpty(currentIdleMessageValue))} label={SAVE} className='submit-button' />
      </div>
    </form>
  )
}

SetIdleScreenForm.propTypes = {
  handleSubmit: PropTypes.func,
  merchantName: PropTypes.string,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  currentIdleMessageValue: PropTypes.string,
  closeModal: PropTypes.func,
  isUpdating: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
  clearForm: PropTypes.func,
  showFileUploader: PropTypes.bool,
}

export default reduxForm({
  form: SET_IDLE_SCREEN_FORM,
  validate: validateSetIdleScreenForm,
})(SetIdleScreenForm)
