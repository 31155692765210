import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT,
  SETTLEMENTS,
} from 'constants/apiConstants'

const patchSettlementAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.patch({
    values,
    credentials,
    path: SETTLEMENT({ settlementId: id }),
    meta,
    service: SETTLEMENTS,
  })
}

export default patchSettlementAPI
