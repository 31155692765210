import './AssociatedIdentityEditS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import IdentityPrincipalsFormC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsFormC'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import Loader from 'components/Shared/Loader/Loader'

const AssociatedIdentityEdit = ({
  isFetching = false,
  ...props
}) => {
  return (
    <div className='AssociatedIdentityEdit'>
      <HeaderC
        breadcrumbs
      />

      { isFetching && <Loader /> }

      { !isFetching && (
        <div className='content-card'>
          <IdentityPrincipalsFormC
            singlePrincipal
            {...props}
          />
        </div>
      )}
    </div>
  )
}

AssociatedIdentityEdit.propTypes = {
  isFetching: PropTypes.bool,
}

export default AssociatedIdentityEdit
