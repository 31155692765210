import formatNumber from 'utilities/formatters/formatNumber'
import get from 'lodash/get'
import isString from 'lodash/isString'
import toString from 'lodash/toString'

const formatAmountField = (amount) => {
  if (amount) {
    if (!isString(amount)) {
      amount = toString(amount)
    }

    const periodFound = amount.includes('.')
    const parts = amount.split('.')
    const dollars = get(parts, '[0]', '').replace(/\D/g, '')
    const cents = get(parts, '[1]', '').replace(/\D/g, '').substring(0, 2)

    if (dollars && !periodFound) {
      return formatNumber(dollars)
    }

    if (dollars && periodFound && !cents) {
      return `${formatNumber(dollars)}.`
    }

    return `${formatNumber(dollars)}.${cents}`
  }

  return ''
}

export default formatAmountField
