import 'react-phone-number-input/style.css'
import './PhoneNumberInputS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import classnames from 'classnames'

import {
  USA,
  countryISO3toISO2,
} from 'constants/countryConstants'

const PhoneNumberInput = ({
  label = '',
  required = false,
  input,
  meta,
  defaultCountry = USA,
  classNames,
  ...rest
}) => {
  const {
    touched,
    error,
  } = meta

  return (
    <div className={classnames('PhoneNumberInput', classNames)}>
      { label && <label>{label} {required && '*'}</label> }

      <PhoneInput
        defaultCountry={countryISO3toISO2[defaultCountry]}
        {...input}
        {...rest}
      />

      { touched && error && <span className='error'>{error}</span> }
    </div>
  )
}

PhoneNumberInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classNames: PropTypes.string,
}

export default PhoneNumberInput
