import React, { Component } from 'react'
import { connect } from 'react-redux'
import VerifyMFASMSAuthenticationCodeForm from 'components/Customer/Forms/VerifyMFASMSAuthenticationCodeForm/VerifyMFASMSAuthenticationCodeForm'
import showModalAction from 'utilities/actions/showModalAction'
import isPatching from 'utilities/is/isPatching'

import {
  CONFIRM_PASSWORD_MFA_MODAL,
  SETUP_MFA_SMS_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state) => {
  return {
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatch for resend phone number text message
    showConfirmPasswordMFAModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_PASSWORD_MFA_MODAL, modalProps, className: 'modal-md no-pad' })),
    // dispatch action to go back to the phone number input form
    showSetupMFASMSModal: ({ type, token }) => dispatch(showModalAction({
      modalType: SETUP_MFA_SMS_MODAL,
      modalProps: {
        type,
        token,
      },
      className: 'modal-md no-pad',
    })),
  }
}

class VerifyMFASMSAuthenticationCodeFormC extends Component {
  render() {
    return <VerifyMFASMSAuthenticationCodeForm {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMFASMSAuthenticationCodeFormC)
