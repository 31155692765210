import './SendPayoutOptionsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import {
  SEND_MONEY_DIRECTLY,
  SEND_MONEY_DIRECTLY_SUBTITLE,
  SEND_PAYOUT,
  SEND_PAYOUT_OPTIONS_SUBTITLE,
  SHARE_A_PAYOUT_LINK,
  SHARE_A_PAYOUT_LINK_SUBTITLE,
} from 'constants/language/languageConstants'

const SendPayoutOptions = ({
  sendPayoutPath = '',
  sharePayoutLinkPath = '',
}) => {
  return (
    <div className='SendPayoutOptions'>
      <div className='content-page'>
        <div className='title flex column'>
          <h2>{SEND_PAYOUT}</h2>
          <div className='p-1 secondary subtitle'>{SEND_PAYOUT_OPTIONS_SUBTITLE}</div>
        </div>

        <div className='options'>
          <Link to={sendPayoutPath}>
            <div className='option send-manual-payout flex items-center'>
              <div className='icon'><i className='far fa-user-circle' /></div>
              <div className='text flex-grow'>
                <h6>{SEND_MONEY_DIRECTLY}</h6>
                <p className='p-1 secondary'>{SEND_MONEY_DIRECTLY_SUBTITLE}</p>
              </div>
              <i className='arrow far fa-chevron-right' />
            </div>
          </Link>

          <Link to={sharePayoutLinkPath}>
            <div className='option share-payout-link flex items-center'>
              <div className='icon'><i className='far fa-link' /></div>
              <div className='text flex-grow'>
                <h6>{SHARE_A_PAYOUT_LINK}</h6>
                <p className='p-1 secondary'>{SHARE_A_PAYOUT_LINK_SUBTITLE}</p>
              </div>
              <i className='arrow far fa-chevron-right' />
            </div>
          </Link>
        </div>

      </div>
    </div>
  )
}

SendPayoutOptions.propTypes = {
  sendPayoutPath: PropTypes.string,
  sharePayoutLinkPath: PropTypes.string,
}

export default SendPayoutOptions
