import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentAuthSession from 'utilities/get/getCurrentAuthSession'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import removeUndefined from 'utilities/remove/removeUndefined'
import getCurrentCredentialIdFromURL from 'utilities/get/getCurrentCredentialIdFromURL'
import getMany from 'utilities/get/getMany'
import merge from 'lodash/merge'

const getDashboardAxiosConfigs = ({ contentType = 'application/json', customHeaders = {} }) => {
  const authSession = getCurrentAuthSession()
  const dashboardId = getCurrentDashboardId()
  const APICredentialId = getCurrentCredentialIdFromURL()

  const [
    idToken,
    tokenType,
  ] = getMany(authSession, [
    'idToken',
    'tokenType',
  ])

  const Authorization = `${tokenType} ${idToken}`

  const headers = removeUndefined(merge({
    Authorization,
    'x-finix-dashboard-id': dashboardId,
    'x-finix-api-credential': APICredentialId,
    'Content-Type': contentType,
  }, customHeaders))

  return { headers }
}

export default getDashboardAxiosConfigs
