import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import { AUTHORIZATIONS_PATH } from 'constants/pathConstants'
import PaymentInstrumentAuthorizations from './PaymentInstrumentAuthorizations'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  ID,
  TRACE_ID,
  AMOUNT,
  DEVICE_ID,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  ID,
  DEVICE_ID,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
], 'label')

const quickFilters = [
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const isFetching = get(state, `paymentInstrumentAuthorizationsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const authorizationPath = AUTHORIZATIONS_PATH({ credentialId })

  const flowValues = {
    paymentInstrumentId,
  }

  const initialQueries = {
    instrument_id: paymentInstrumentId,
  }

  return {
    paymentInstrumentId,
    flowValues,
    isFetching,
    authorizationPath,
    initialQueries,
    allowedFilters,
    quickFilters,
  }
}

class PaymentInstrumentAuthorizationsC extends Component {
  render() {
    return (
      <PaymentInstrumentAuthorizations {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PaymentInstrumentAuthorizationsC)
