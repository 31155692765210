import getSettlementEntriesAPI from 'api/finix/get/getSettlementEntriesAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import size from 'lodash/size'
import co from 'co'

function * getSettlementEntriesO ({
  id = '',
  credentials,
}) {
  let offset = 0
  let totalSettlementEntries = []

  while (true) {
    const settlementEntriesResponse = yield getSettlementEntriesAPI({
      credentials,
      id,
      queries: { offset, limit: 100 },
    })

    const settlementEntries = get(settlementEntriesResponse, 'data._embedded.settlement_entries', [])
    totalSettlementEntries = totalSettlementEntries.concat(settlementEntries)

    const settlementEntriesSize = size(settlementEntries)
    if (settlementEntriesSize < 100) break
    offset += 100
  }

  return createSuccessResponse({
    data: totalSettlementEntries,
  })
}

export default co.wrap(getSettlementEntriesO)
