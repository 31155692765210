import numeral from 'numeral'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  PRIVATE_CORPORATION_VALUE,
  PUBLIC_CORPORATION_VALUE,
  CORPORATION_VALUE,
  GOVERNMENT_AGENCY_VALUE,
  TAX_EXEMPT_ORGANIZATION_VALUE,
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
} from 'constants/identityConstants'

const backendOnboardingFormDataModel = ({ values }) => {
  const {
    id,
    businessData,
    beneficialOwners,
    controlPerson,
    processingData,
    bankAccountData,
    country,
    associatedFiles,
    ownershipType: prefilledOwnershipType,
  } = values

  const [
    businessName,
    doingBusinessAs,
    businessDescription,
    businessType,
    dashboardSelectedOwnershipType,
    incorporationDate,
    businessTaxId,
    website,
    businessPhone,
    businessAddress = {},
  ] = getMany(businessData, [
    'businessName',
    'doingBusinessAs',
    'businessDescription',
    'businessType',
    'ownershipType',
    'incorporationDate',
    'businessTaxId',
    'website',
    'businessPhone',
    'businessAddress',
  ])

  const ownershipType = dashboardSelectedOwnershipType || prefilledOwnershipType

  const businessAddressValues = removeUndefined({
    line1: businessAddress.line1,
    line2: businessAddress.line2,
    region: businessAddress.region,
    postal_code: businessAddress.postalCode,
    city: businessAddress.city,
    country: businessAddress.country,
  })

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress = {},
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth = {},
    controlPersonTaxId,
    controlPersonPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'firstName',
    'lastName',
    'email',
    'personalAddress',
    'principalPercentageOwnership',
    'dateOfBirth',
    'taxId',
    'phone',
    'title',
  ])

  const personalAddressValues = removeUndefined({
    line1: controlPersonPersonalAddress.line1,
    line2: controlPersonPersonalAddress.line2,
    region: controlPersonPersonalAddress.region,
    postal_code: controlPersonPersonalAddress.postalCode,
    city: controlPersonPersonalAddress.city,
    country: controlPersonPersonalAddress.country,
  })

  const controlPersonDobValues = removeUndefined({
    month: controlPersonDateOfBirth.month,
    day: controlPersonDateOfBirth.day,
    year: controlPersonDateOfBirth.year,
  })

  const backendAssociatedEntityValues = (isEmpty(beneficialOwners) && !isEqual(beneficialOwners, [])) ? undefined : map(beneficialOwners, (beneficialOwner) => {
    const [
      beneficialOwnerFirstName,
      beneficialOwnerLastName,
      beneficialOwnerEmail,
      beneficialOwnerPersonalAddress = {},
      beneficialOwnerPrincipalPercentageOwnership,
      beneficialOwnerDateOfBirth = {},
      beneficialOwnerTaxId,
      beneficialOwnerPhone,
      beneficialOwnerTitle,
      beneficialOwnerIndex,
    ] = getMany(beneficialOwner, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'dateOfBirth',
      'taxId',
      'phone',
      'title',
      'index',
    ])

    return removeUndefined({
      index: beneficialOwnerIndex,
      first_name: beneficialOwnerFirstName,
      last_name: beneficialOwnerLastName,
      title: beneficialOwnerTitle,
      tax_id: beneficialOwnerTaxId === '' ? null : beneficialOwnerTaxId,
      personal_address: removeUndefined({
        line1: beneficialOwnerPersonalAddress.line1,
        line2: beneficialOwnerPersonalAddress.line2,
        region: beneficialOwnerPersonalAddress.region,
        postal_code: beneficialOwnerPersonalAddress.postalCode,
        city: beneficialOwnerPersonalAddress.city,
        country: beneficialOwnerPersonalAddress.country,
      }),
      dob: {
        month: beneficialOwnerDateOfBirth.month,
        day: beneficialOwnerDateOfBirth.day,
        year: beneficialOwnerDateOfBirth.year,
      },
      principal_percentage_ownership: beneficialOwnerPrincipalPercentageOwnership ? numeral(beneficialOwnerPrincipalPercentageOwnership).value() : undefined,
      phone: beneficialOwnerPhone,
      email: beneficialOwnerEmail,
    }, true)
  })

  const [
    mcc,
    taxAuthority,
    defaultStatementDescriptor,
    previouslyProcessedCreditCards,
    amexMid,
    discoverMid,
    refundPolicy,
    annualCardVolume,
    annualACHVolume,
    averageCardTransferAmount,
    averageACHTransferAmount,
    maxTransactionAmount,
    achMaxTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    mailOrderTelephoneOrderPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
  ] = getMany(processingData, [
    'mcc',
    'taxAuthority',
    'defaultStatementDescriptor',
    'previouslyProcessedCreditCards',
    'amexMid',
    'discoverMid',
    'refundPolicy',
    'annualCardVolume',
    'annualACHVolume',
    'averageCardTransferAmount',
    'averageACHTransferAmount',
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'ecommercePercentage',
    'cardPresentPercentage',
    'mailOrderTelephoneOrderPercentage',
    'businessToBusinessVolumePercentage',
    'businessToConsumerVolumePercentage',
    'otherVolumePercentage',
  ])

  const normalizedBusinessType = includes([PRIVATE_CORPORATION_VALUE, PUBLIC_CORPORATION_VALUE], businessType) ? CORPORATION_VALUE : businessType
  const normalizedOwnershipType = !ownershipType && includes([GOVERNMENT_AGENCY_VALUE, TAX_EXEMPT_ORGANIZATION_VALUE, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE], businessType) ? BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType] : ownershipType

  const backendEntityValues = removeUndefined({
    title: controlPersonTitle,
    first_name: controlPersonFirstName,
    last_name: controlPersonLastName,
    email: controlPersonEmail,
    business_name: businessName,
    business_type: normalizedBusinessType,
    doing_business_as: doingBusinessAs,
    phone: controlPersonPhone,
    business_phone: businessPhone,
    mcc,
    tax_authority: taxAuthority,
    url: website,
    tax_id: controlPersonTaxId === '' ? null : controlPersonTaxId,
    business_tax_id: businessTaxId === '' ? null : businessTaxId,
    ownership_type: normalizedOwnershipType,
    default_statement_descriptor: defaultStatementDescriptor,
    max_transaction_amount: maxTransactionAmount ? numeral(maxTransactionAmount).value() : undefined,
    ach_max_transaction_amount: achMaxTransactionAmount ? numeral(achMaxTransactionAmount).value() : undefined,
    annual_card_volume: annualCardVolume ? numeral(annualCardVolume).value() : undefined,
    principal_percentage_ownership: controlPersonPrincipalPercentageOwnership,
    has_accepted_credit_cards_previously: previouslyProcessedCreditCards || undefined,
    personal_address: !isEmpty(personalAddressValues) ? personalAddressValues : undefined,
    business_address: !isEmpty(businessAddressValues) ? businessAddressValues : undefined,
    dob: !isEmpty(controlPersonDobValues) ? controlPersonDobValues : undefined,
    incorporation_date: !isEmpty(incorporationDate) ? incorporationDate : undefined,
    amex_mid: amexMid,
    discover_mid: discoverMid,
  }, true)

  const volumeDistributionByBusinessType = removeUndefined({
    other_volume_percentage: otherVolumePercentage ? numeral(otherVolumePercentage).value() : undefined,
    business_to_business_volume_percentage: businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : undefined,
    business_to_consumer_volume_percentage: businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : undefined,
  })

  const cardVolumeDistribution = removeUndefined({
    ecommerce_percentage: ecommercePercentage ? numeral(ecommercePercentage).value() : undefined,
    card_present_percentage: cardPresentPercentage ? numeral(cardPresentPercentage).value() : undefined,
    mail_order_telephone_order_percentage: mailOrderTelephoneOrderPercentage ? numeral(mailOrderTelephoneOrderPercentage).value() : undefined,
  })

  const backendAdditionalUnderwritingData = removeUndefined({
    refund_policy: refundPolicy,
    average_ach_transfer_amount: averageACHTransferAmount ? numeral(averageACHTransferAmount).value() : undefined,
    average_card_transfer_amount: averageCardTransferAmount ? numeral(averageCardTransferAmount).value() : undefined,
    annual_ach_volume: annualACHVolume ? numeral(annualACHVolume).value() : undefined,
    business_description: businessDescription,
    volume_distribution_by_business_type: !isEmpty(volumeDistributionByBusinessType) ? volumeDistributionByBusinessType : undefined,
    card_volume_distribution: !isEmpty(cardVolumeDistribution) ? cardVolumeDistribution : undefined,
  })

  const [
    name,
    bankCode,
    accountNumber,
    transitNumber,
    institutionNumber,
    plaidProcessorToken,
    bankAccountCountry,
    bankAccountCurrency,
    paymentInstrumentType,
    accountType,
    thirdParty,
  ] = getMany(bankAccountData, [
    'name',
    'bankCode',
    'accountNumber',
    'transitNumber',
    'institutionNumber',
    'plaidProcessorToken',
    'country',
    'currency',
    'type',
    'accountType',
    'thirdParty',
  ])

  const backendPaymentInstrumentValues = {
    name,
    bank_code: bankCode,
    account_number: accountNumber,
    account_type: accountType,
    transit_number: transitNumber,
    institution_number: institutionNumber,
    type: paymentInstrumentType,
    country: bankAccountCountry,
    currency: bankAccountCurrency,
    third_party_token: plaidProcessorToken,
    third_party: thirdParty,
  }

  return {
    id,
    onboarding_data: removeUndefined({
      associated_entities: backendAssociatedEntityValues,
      payment_instruments: !isEmpty(backendPaymentInstrumentValues) ? backendPaymentInstrumentValues : undefined,
      entity: !isEmpty(backendEntityValues) ? backendEntityValues : undefined,
      additional_underwriting_data: !isEmpty(backendAdditionalUnderwritingData) ? backendAdditionalUnderwritingData : undefined,
      associated_files: associatedFiles ? associatedFiles : undefined,
    }, true),
  }
}

export default backendOnboardingFormDataModel
