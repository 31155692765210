import './OnboardingFormsInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import formatNumber from 'utilities/formatters/formatNumber'
import Loader from 'components/Shared/Loader/Loader'


import {
  ONBOARDING_FORMS_FORMS_GENERATED_BIG_NUMBER_TOTAL,
  ONBOARDING_FORMS_IN_PROGRESS_BIG_NUMBER_TOTAL,
  ONBOARDING_FORMS_EXPIRED_BIG_NUMBER_TOTAL,
  ONBOARDING_FORMS_FORMS_COMPLETED_BIG_NUMBER_TOTAL,
  ONBOARDING_FORMS_ONBOARDING_FORMS_COMPLETION_RATE_ECHART_TIMESERIES_BAR,
  ONBOARDING_FORMS_ONBOARDING_FORMS_GENERATED_ECHART_TIMESERIES_BAR,
  ONBOARDING_FORMS_INCOMPLETE_ONBOARDING_FORMS,
} from 'constants/chartConstants'

import {
  TIME_FRAME,
  SUMMARY,
  ONBOARDING_FORMS_GENERATED_TOOLTIP_MSG,
  FORMS_COMPLETED,
  FORMS_IN_PROGRESS,
  EXPIRED_FORMS,
  ONBOARDING_FORMS_COMPLETED_TOOLTIP_MSG,
  ONBOARDING_FORMS_IN_PROGRESS_TOOLTIP_MSG,
  ONBOARDING_FORMS_TREND,
  ONBOARDING_FORMS_TREND_TOOLTIP_MSG,
  INCOMPLETE_ONBOARDING_FORMS,
  FORMS_GENERATED,
  INCOMPLETE_ONBOARDING_FORMS_TOOLTIP_MSG,
  EXPIRED_FORMS_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const OnboardingFormsInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }
  return (
    <div className='OnboardingFormsInsights flex column'>
      <div className='time-frame-container flex space-between'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{TIME_FRAME}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>Summary of your onboarding forms within the filtered time frame.</p>
                <p><span className='bold'>{FORMS_GENERATED}</span>{ONBOARDING_FORMS_GENERATED_TOOLTIP_MSG}</p>
                <p><span className='bold'>{FORMS_COMPLETED} </span>{ONBOARDING_FORMS_COMPLETED_TOOLTIP_MSG}</p>
                <p><span className='bold'>{FORMS_IN_PROGRESS}</span>{ONBOARDING_FORMS_IN_PROGRESS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{EXPIRED_FORMS}</span>{EXPIRED_FORMS_TOOLTIP_MSG}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={FORMS_GENERATED}
            headerData={{
              name: ONBOARDING_FORMS_FORMS_GENERATED_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FORMS_COMPLETED}
            headerData={{
              name: ONBOARDING_FORMS_FORMS_COMPLETED_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FORMS_IN_PROGRESS}
            headerData={{
              name: ONBOARDING_FORMS_IN_PROGRESS_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={EXPIRED_FORMS}
            headerData={{
              name: ONBOARDING_FORMS_EXPIRED_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={ONBOARDING_FORMS_TREND}
          classNames='onboarding-trends'
          chartClassNames='onboarding-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          tooltipMessage={ONBOARDING_FORMS_TREND_TOOLTIP_MSG}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: ONBOARDING_FORMS_ONBOARDING_FORMS_GENERATED_ECHART_TIMESERIES_BAR, height: 260 },
            { name: ONBOARDING_FORMS_ONBOARDING_FORMS_COMPLETION_RATE_ECHART_TIMESERIES_BAR, height: 260 },
          ]}
        />

        <div className='mtl'>
          <ChartC
            title={INCOMPLETE_ONBOARDING_FORMS}
            name={ONBOARDING_FORMS_INCOMPLETE_ONBOARDING_FORMS}
            height={484}
            className='table'
            timeRange={timeRange}
            hideTitle
            tooltipMessage={INCOMPLETE_ONBOARDING_FORMS_TOOLTIP_MSG}
          />
        </div>
      </div>
    </div>
  )
}

OnboardingFormsInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
}

export default OnboardingFormsInsights
