import numeral from 'numeral'
import { fieldEmpty } from 'utilities/validate'
import { AMOUNT } from 'constants/language/languageConstants'
import isUndefined from 'lodash/isUndefined'

const validateCreateBalanceAdjustmentForm = (values, props) => {
  const {
    amount,
    instrumentId,
    description,
  } = values

  const { applicationBalanceAdjustmentMaxTransactionAmount } = props

  const overMaxTransactionAmount = numeral(amount).value() > numeral(applicationBalanceAdjustmentMaxTransactionAmount).value()
  const noDisbursementRules = isUndefined(applicationBalanceAdjustmentMaxTransactionAmount)

  return {
    amount: fieldEmpty(amount, AMOUNT) || (noDisbursementRules && 'No disbursement rules set up for this application. Please reach out to Finix Support.') || (overMaxTransactionAmount && `You can send up to ${applicationBalanceAdjustmentMaxTransactionAmount}.`),
    instrumentId: fieldEmpty(instrumentId, 'Payment Method'),
    description: fieldEmpty(description, 'Note'),
  }
}

export default validateCreateBalanceAdjustmentForm
