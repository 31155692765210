import './ExportDataFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { EXPORT_DATA_FORM } from 'constants/formConstants'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import validateExportDataForm from 'utilities/validate/validateExportDataForm'
import submitExportDataForm from 'utilities/submit/submitExportDataForm'
import { EXPORT_ALL_WARNING } from 'constants/language/languageConstants'
import map from 'lodash/map'
import get from 'lodash/get'

const ExportDataForm = ({
  handleSubmit = () => {},
  dataKeys = {},
  itemsCount = 0,
  initialValues = {},
  isFetching,
  newFetchAll,
  progressPercent,
  totalSettlementsCount,
  submitExportAllReviewQueueItems = () => {},
  showExportAll = false,
  enableExportAll = false,
}) => {
  return (
    <form className='ExportDataForm' onSubmit={handleSubmit(submitExportDataForm)}>
      <div className='section'>
        <h2>Fields to export:</h2>

        <ul className='flex wrap'>
          {
            map(dataKeys, (key) => {
              const initialTitle = get(initialValues, `${key}.title`)

              return (
                <li key={key} className='flex items-center'>
                  <Field
                    component={CheckboxC}
                    name={`${key}.checked`}
                    checked
                  />
                  <div className='label'>{initialTitle}</div>
                </li>
              )
            })
          }
        </ul>
      </div>

      {
        showExportAll && newFetchAll && (
          <div className='getAllReviewQueueItemsWidget flex space-between items-center'>
            <div className='progressBarText'>Progress: <strong>{progressPercent}</strong></div>

            <div className='progressBar'>
              <div className='bar' style={{ width: progressPercent }} />
            </div>
          </div>
        )
      }

      <div className='buttons flex flex-end'>
        {
          showExportAll && (
            <Button submitting={isFetching} disabled={!enableExportAll} label={`Export All (${totalSettlementsCount}) Items`} onClick={handleSubmit(submitExportAllReviewQueueItems)} />
          )
        }

        <Button type='submit' label={`Export ${itemsCount} Items`} />
      </div>

      { showExportAll && !enableExportAll && <WarningMessage warningMessage={EXPORT_ALL_WARNING} /> }
    </form>
  )
}

ExportDataForm.propTypes = {
  handleSubmit: PropTypes.func,
  dataKeys: PropTypes.object,
  itemsCount: PropTypes.number,
  initialValues: PropTypes.object,
  isFetching: PropTypes.bool,
  newFetchAll: PropTypes.bool,
  progressPercent: PropTypes.string,
  totalSettlementsCount: PropTypes.number,
  submitExportAllReviewQueueItems: PropTypes.func,
  showExportAll: PropTypes.bool,
  enableExportAll: PropTypes.bool,
}

export default reduxForm({
  form: EXPORT_DATA_FORM,
  validate: validateExportDataForm,
})(ExportDataForm)
