import './DictionaryListS.scss'
import React, { Fragment } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import map from 'lodash/map'
import isFunction from 'lodash/isFunction'
import camelCase from 'lodash/camelCase'

const DictionaryList = ({
  data: terms = [],
  className = '',
}) => {
  return (
    <dl className='DictionaryList'>
      { map(terms, ({ label, value = '-', path, formatter, action, condition = true, tooltip = '' }) => {
        const ValueComponent = formatter ? formatter({ value }) : (value || '-')
        const InvokeValueComponent = isFunction(ValueComponent) ? ValueComponent() : ValueComponent
        const displayComponent = path ? <Link className='text-link' to={path}>{InvokeValueComponent}</Link> : InvokeValueComponent
        const labelCamelCase = camelCase(label)
        const labelClassName = `flex ${labelCamelCase}Label`
        const labelValueClassName = `${labelCamelCase}Value`

        return condition ? (
          <Fragment key={label}>
            <div className={className}>
              <dt className={labelClassName}>
                <label className='label-2'>{label}</label>
                { tooltip && <TooltipLabel message={tooltip} /> }
              </dt>
              <dd className={labelValueClassName} onClick={action}>
                <span className={action ? 'action' : ''}>{displayComponent}</span>
              </dd>
            </div>
          </Fragment>
        ) : undefined
      })}
    </dl>
  )
}

DictionaryList.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
}

export default DictionaryList
