import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitActivateDeviceForm from 'utilities/submit/submitActivateDeviceForm'
import { DEACTIVATE_DEVICE_FORM } from 'constants/formConstants'

import {
  CANCEL,
  DEACTIVATE_DEVICE,
} from 'constants/language/languageConstants'

const DeactivateDeviceForm = ({
  handleSubmit = () => {},
  deviceName = '',
  closeModal,
  invalid,
  isUpdating = false,
}) => {
  return (
    <form className='DeactivateDeviceForm' onSubmit={handleSubmit(submitActivateDeviceForm)}>
      <div className='modal-content'>
        <div className='confirm-deactivation-msg'>If you deactivate {deviceName}, it will not be able to process transactions.</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} submitting={isUpdating} variant='destructive' label={DEACTIVATE_DEVICE} className='submit-button' />
      </div>
    </form>
  )
}

DeactivateDeviceForm.propTypes = {
  handleSubmit: PropTypes.func,
  deviceName: PropTypes.string,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default reduxForm({
  form: DEACTIVATE_DEVICE_FORM,
})(DeactivateDeviceForm)
