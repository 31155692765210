import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_TRANSACTION_COUNT,
  TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_ACH_RETURN_COUNT,
  TRANSACTION_MONITORING_TOTAL_ACH_RETURN_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_ACH_RETURN_AMOUNT,
  TRANSACTION_MONITORING_ACH_RETURN_RATE,
} from 'constants/language/languageConstants'

const ACHCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    transferCount,
    achReturnCount,
    displayTransferAmountDollarsSum,
    displayAchReturnAmountDollarsSum,
    displayTransferAmountDollarsAverage,
    displayAchReturnAmountDollarsAverage,
    displayAchReturnRate,
  ] = getMany(calculations, [
    'transferCount',
    'achReturnCount',
    'displayTransferAmountDollarsSum',
    'displayAchReturnAmountDollarsSum',
    'displayTransferAmountDollarsAverage',
    'displayAchReturnAmountDollarsAverage',
    'displayAchReturnRate',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_ACH_RETURN_COUNT}
        headerData={{
          value: achReturnCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_ACH_RETURN_AMOUNT}
        headerData={{
          value: displayAchReturnAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_ACH_RETURN_AMOUNT}
        headerData={{
          value: displayAchReturnAmountDollarsAverage,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_ACH_RETURN_RATE}
        headerData={{
          value: displayAchReturnRate,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TRANSACTION_COUNT}
        headerData={{
          value: transferCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsAverage,
        }}
      />
    </div>
  )
}

export default ACHCards
