import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccessFormView from './AccessFormView'
import { getAccessFormSelector } from 'state-layer/selectors'
import getAccessFormPrincipalTaxIdUnmaskAPI from 'api/dashboard/get/getAccessFormPrincipalTaxIdUnmaskAPI'
import getAccessFormBusinessTaxIdUnmaskAPI from 'api/dashboard/get/getAccessFormBusinessTaxIdUnmaskAPI'
import getAccessFormBankAccountNumberUnmaskAPI from 'api/dashboard/get/getAccessFormBankAccountNumberUnmaskAPI'
import getAccessFormBeneficialOwnerTaxIdUnmaskAPI from 'api/dashboard/get/getAccessFormBeneficialOwnerTaxIdUnmaskAPI'
import DisplayUnmaskC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getAccessFormRequest from 'utilities/actions/get/getAccessFormRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import showModalAction from 'utilities/actions/showModalAction'
import hasPermission from 'utilities/hasPermission'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE } from 'constants/statusConstants'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { FILE_VIEWER_MODAL } from 'constants/modalConstants'
import { ADMINISTRATOR } from 'constants/pathConstants'
import { CAN } from 'constants/countryConstants'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  ANNUAL_CARD_VOLUME_USD,
  ANNUAL_TRANSACTION_COUNT,
  BANK_DOCUMENT,
  BUSINESS_NAME,
  BUSINESS_TAX_ID,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE,
  CREATED_ON,
  DATE_OF_BIRTH,
  DATE_OF_INCORPORATION,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DOING_BUSINESS_AS,
  EIN_VERIFICATION_DOCUMENT,
  EMAIL_ADDRESS,
  IN_PERSON_PAYMENTS,
  MAX_CARD_TRANSACTION_AMOUNT_USD,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_COUNT,
  ONLINE_PAYMENTS,
  OTHER,
  OWNERSHIP_TYPE,
  PAYMENT_SOLUTIONS,
  PHONE_NUMBER,
  PRICING_TYPE,
  ROUTING_NUMBER,
  SHORT_BUSINESS_DESCRIPTION,
  SUBDOMAINS,
  SUBMITTED_BY,
  TAX_ID,
  TERMS_OF_SERVICE,
  VIEW_FILE,
  WEBSITE,
  ACCEPTED_BY,
  ACCEPTED_ON,
  BUSINESS_TAX_ID_PROVIDED,
  YES,
  ACCOUNT_NUMBER_PROVIDED,
  TAX_ID_PROVIDED,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  BANK_ACCOUNT,
  SUCCESSFULLY_LINKED,
} from 'constants/language/languageConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
} from 'constants/labelConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'accessFormId') || get(props, 'params.accessFormId')
  const accessForm = get(props, 'accessForm', getAccessFormSelector(state, accessFormId))
  const isCustomerOnboardingForm = get(props, 'isCustomerOnboardingForm', false)
  const userRoleName = get(credentials, 'displayDashboardUserRoleName')
  const isAdministratorRole = isEqual(userRoleName, ADMINISTRATOR)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials }) && isAdministratorRole

  const [
    bankAccountData,
    bankDocument,
    beneficialOwners,
    businessData,
    controlPerson,
    einVerificationFile,
    displayPricingType,
    processingData,
    displayCreatedAt,
    submittedBy,
    contractId,
    status,
    acceptedBy,
    displayAcceptedAt,
    additionalDocuments,
    customerType,
    country,
  ] = getMany(accessForm, [
    'bankAccountData',
    'bankDocument',
    'beneficialOwners',
    'businessData',
    'controlPerson',
    'einVerificationFile',
    'displayPricingType',
    'processingData',
    'displayCreatedAt',
    'submittedBy',
    'contractId',
    'status',
    'acceptedBy',
    'displayAcceptedAt',
    'additionalDocuments',
    'customerType',
    'country',
  ])

  const isStandaloneMerchant = customerType === STANDALONE_MERCHANT
  const additionalVerificationNeeded = isEqual(status, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE)
  const contractUrl = `${getDashboardServiceHost()}/contracts/${contractId}/data`

  const detailsDataSection = [
    {
      type: 'data',
      data: [
        { label: CREATED_ON, value: displayCreatedAt },
        { label: SUBMITTED_BY, value: submittedBy },
      ],
    },
    {
      type: 'data',
      data: [
        { label: TERMS_OF_SERVICE, value: <a className='text-link' href={contractUrl} target='blank'>{isStandaloneMerchant ? 'Merchant' : 'Platform'}</a>, condition: !!contractId },
        { label: ACCEPTED_ON, value: displayAcceptedAt },
      ],
    },
    {
      type: 'data',
      data: [
        { label: ACCEPTED_BY, value: acceptedBy },
      ],
    },
  ]

  const pricingInfoDataSection = [
    {
      type: 'data',
      data: [
        { label: PRICING_TYPE, value: displayPricingType },
      ],
    },
  ]

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress,
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth,
    controlPersonTaxId,
    controlPersonDisplayPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'firstName',
    'lastName',
    'email',
    'personalAddress',
    'principalPercentageOwnership',
    'displayDateOfBirth',
    'taxId',
    'displayPhone',
    'title',
  ])

  const controlPersonInfoDataSection = controlPersonFirstName ? [
    {
      largeTitle: `${controlPersonFirstName} ${controlPersonLastName}`,
      asideTitle: `(${controlPersonTitle}, ${controlPersonPrincipalPercentageOwnership}%)`,
      data: [
        { label: DATE_OF_BIRTH, value: controlPersonDateOfBirth },
        {
          label: TAX_ID,
          value: showUnmaskAction ? (
            <DisplayUnmaskC
              id={accessFormId}
              api={getAccessFormPrincipalTaxIdUnmaskAPI}
              feKey={controlPersonTaxId}
              beKey='principal_tax_id'
              defaultValue={controlPersonTaxId}
            />
          ) : controlPersonTaxId,
          condition: !isCustomerOnboardingForm,
        },
        {
          label: TAX_ID_PROVIDED,
          value: controlPersonTaxId ? YES : '-',
          condition: isCustomerOnboardingForm,
        },
        { label: EMAIL_ADDRESS, value: controlPersonEmail },
        { label: PHONE_NUMBER, value: controlPersonDisplayPhone },
        { label: ADDRESS, value: <Address address={controlPersonPersonalAddress} /> },
      ],
    },
  ] : []

  const beneficialOwnershipInfoDataSection = map(beneficialOwners, owner => {
    const [
      firstName,
      lastName,
      email,
      personalAddress,
      principalPercentageOwnership,
      displayDateOfBirth,
      taxId,
      displayPhone,
      title,
      index,
    ] = getMany(owner, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'displayDateOfBirth',
      'taxId',
      'displayPhone',
      'title',
      'index',
    ])

    return {
      largeTitle: `${firstName} ${lastName}`,
      asideTitle: `(${title}, ${principalPercentageOwnership}%)`,
      data: [
        { label: DATE_OF_BIRTH, value: displayDateOfBirth },
        {
          label: TAX_ID,
          value: showUnmaskAction ? (
            <DisplayUnmaskC
              id={accessFormId}
              api={getAccessFormBeneficialOwnerTaxIdUnmaskAPI}
              feKey={taxId}
              beKey='tax_id'
              defaultValue={taxId}
              values={{ index }}
            />
          ) : taxId,
          condition: !isCustomerOnboardingForm,
        },
        {
          label: TAX_ID_PROVIDED,
          value: taxId ? YES : '-',
          condition: isCustomerOnboardingForm,
        },
        { label: EMAIL_ADDRESS, value: email },
        { label: PHONE_NUMBER, value: displayPhone },
        { label: ADDRESS, value: <Address address={personalAddress} /> },
      ],
    }
  })

  const [
    displayMccCodes,
    defaultStatementDescriptor,
    displayAnnualTransactionCount,
    displayAnnualCardVolume,
    displayAnnualACHVolume,
    displayMaxCardTransactionAmount,
    displayMaxACHTransactionAmount,
    displayEcommercePercentage,
    displayCardPresentPercentage,
    displayBusinessToBusinessVolumePercentage,
    displayBusinessToConsumerVolumePercentage,
    displayOtherVolumePercentage,
    displayAcceptedPaymentTypes,
  ] = getMany(processingData, [
    'displayMccCodes',
    'defaultStatementDescriptor',
    'displayAnnualTransactionCount',
    'displayAnnualCardVolume',
    'displayAnnualACHVolume',
    'displayMaxCardTransactionAmount',
    'displayMaxACHTransactionAmount',
    'displayEcommercePercentage',
    'displayCardPresentPercentage',
    'displayBusinessToBusinessVolumePercentage',
    'displayBusinessToConsumerVolumePercentage',
    'displayOtherVolumePercentage',
    'displayAcceptedPaymentTypes',
  ])

  const processingInfoDataSection = convertPageSectionDataToV2([
    { label: PAYMENT_SOLUTIONS, value: displayAcceptedPaymentTypes, condition: !isCustomerOnboardingForm },
    { label: MERCHANT_CATEGORY_CODE, value: displayMccCodes },
    { label: DEFAULT_STATEMENT_DESCRIPTOR, value: defaultStatementDescriptor, condition: !isCustomerOnboardingForm },
    { label: ANNUAL_TRANSACTION_COUNT, value: displayAnnualTransactionCount },
    { label: ANNUAL_CARD_VOLUME_USD, value: displayAnnualCardVolume },
    { label: annualACHVolumeLabelMap(country), value: displayAnnualACHVolume },
    { label: MAX_CARD_TRANSACTION_AMOUNT_USD, value: displayMaxCardTransactionAmount },
    { label: achMaxTransactionAmountLabelMap(country), value: displayMaxACHTransactionAmount },
  ])

  const cardVolumeDataSection = convertPageSectionDataToV2([
    { label: ONLINE_PAYMENTS, value: displayEcommercePercentage },
    { label: IN_PERSON_PAYMENTS, value: displayCardPresentPercentage },
  ])

  const paymentVolumeDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_TO_BUSINESS, value: displayBusinessToBusinessVolumePercentage },
    { label: BUSINESS_TO_CONSUMER, value: displayBusinessToConsumerVolumePercentage },
    { label: OTHER, value: displayOtherVolumePercentage },
  ])

  return {
    credentials,
    accessForm,
    accessFormId,
    showUnmaskAction,
    businessData,
    bankAccountData,
    einVerificationFile,
    bankDocument,
    detailsDataSection,
    pricingInfoDataSection,
    controlPersonInfoDataSection,
    beneficialOwnershipInfoDataSection,
    processingInfoDataSection,
    cardVolumeDataSection,
    paymentVolumeDataSection,
    additionalVerificationNeeded,
    isStandaloneMerchant,
    isCustomerOnboardingForm,
    additionalDocuments,
    country,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessForm: ({ credentials, accessFormId }) => { dispatch(getAccessFormRequest({ credentials, accessFormId })) },
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
  }
}

class AccessFormViewC extends Component {
  componentDidMount() {
    const {
      credentials,
      accessFormId,
      getAccessForm,
      accessForm,
    } = this.props

    if (accessFormId && isEmpty(accessForm)) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accessFormId: prevAccessFormId } = prevProps

    const {
      accessFormId,
      getAccessForm,
      accessForm,
      credentials,
    } = this.props

    if (accessFormId && isEmpty(accessForm) && accessFormId !== prevAccessFormId) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  render() {
    const {
      showFileViewer,
      businessData,
      einVerificationFile,
      bankAccountData,
      bankDocument,
      accessFormId,
      showUnmaskAction,
      isStandaloneMerchant,
      isCustomerOnboardingForm,
      country,
    } = this.props

    const [
      businessName,
      doingBusinessAs,
      businessDescription,
      displayBusinessType,
      displayBusinessOwnershipType,
      displayBusinessIncorporationDate,
      businessTaxId,
      businessWebsite,
      businessWebsiteDomains,
      businessEmail,
      displayBusinessPhone,
      businessMerchantCount,
      businessAddress,
    ] = getMany(businessData, [
      'businessName',
      'doingBusinessAs',
      'businessDescription',
      'displayBusinessType',
      'displayBusinessOwnershipType',
      'displayBusinessIncorporationDate',
      'businessTaxId',
      'businessWebsite',
      'businessWebsiteDomains',
      'businessEmail',
      'displayBusinessPhone',
      'businessMerchantCount',
      'businessAddress',
    ])

    const businessInfoDataSection = convertPageSectionDataToV2([
      { label: BUSINESS_NAME, value: businessName },
      { label: DOING_BUSINESS_AS, value: doingBusinessAs },
      { label: SHORT_BUSINESS_DESCRIPTION, value: businessDescription },
      { label: BUSINESS_TYPE, value: displayBusinessType },
      { label: OWNERSHIP_TYPE, value: displayBusinessOwnershipType },
      { label: DATE_OF_INCORPORATION, value: displayBusinessIncorporationDate },
      {
        label: BUSINESS_TAX_ID,
        value: showUnmaskAction ? (
          <DisplayUnmaskC
            id={accessFormId}
            api={getAccessFormBusinessTaxIdUnmaskAPI}
            feKey={businessTaxId}
            beKey='business_tax_id'
            defaultValue={businessTaxId}
          />
        ) : businessTaxId,
        condition: !isCustomerOnboardingForm,
      },
      {
        label: BUSINESS_TAX_ID_PROVIDED,
        value: businessTaxId ? YES : '-',
        condition: isCustomerOnboardingForm,
      },
      {
        label: EIN_VERIFICATION_DOCUMENT,
        value: einVerificationFile ? VIEW_FILE : '-',
        action: einVerificationFile ? () => {
          showFileViewer({ id: get(einVerificationFile, 'file_id'), accessFormId })
        } : () => {},
      },
      { label: MERCHANT_COUNT, value: businessMerchantCount, condition: !isStandaloneMerchant },
      { label: WEBSITE, value: businessWebsite === 'https://' ? '-' : validateWebsiteLink({ website: businessWebsite }) ? <a className='text-link' href={businessWebsite} target='blank'>{businessWebsite}</a> : businessWebsite },
      { label: SUBDOMAINS, value: businessWebsiteDomains },
      { label: EMAIL_ADDRESS, value: businessEmail },
      { label: PHONE_NUMBER, value: displayBusinessPhone },
      { label: ADDRESS, value: get(businessAddress, 'line1') ? <Address address={businessAddress} /> : '-' },
    ])

    const [
      accountName,
      displayAccountType,
      routingNumber,
      accountNumber,
      transitNumber,
      institutionNumber,
      thirdParty,
    ] = getMany(bankAccountData, [
      'name',
      'displayAccountType',
      'bankCode',
      'accountNumber',
      'transitNumber',
      'institutionNumber',
      'thirdParty',
    ])

    const bankAccountInfoDataSection = thirdParty ? convertPageSectionDataToV2([
      {
        label: BANK_ACCOUNT,
        value: <div><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
      },
    ]) : convertPageSectionDataToV2([
      { label: ACCOUNT_NAME, value: accountName },
      { label: ACCOUNT_TYPE, value: displayAccountType },
      { label: ROUTING_NUMBER, value: routingNumber },
      {
        label: TRANSIT_NUMBER,
        value: transitNumber,
        condition: country === CAN,
      },
      {
        label: INSTITUTION_NUMBER,
        value: institutionNumber,
        condition: country === CAN,
      },
      {
        label: ACCOUNT_NUMBER,
        value: showUnmaskAction ? (
          <DisplayUnmaskC
            id={accessFormId}
            api={getAccessFormBankAccountNumberUnmaskAPI}
            feKey='accountNumber'
            beKey='bank_account_number'
            defaultValue={accountNumber}
          />
        ) : accountNumber,
        condition: !isCustomerOnboardingForm,
      },
      {
        label: ACCOUNT_NUMBER_PROVIDED,
        value: accountNumber ? YES : '-',
        condition: isCustomerOnboardingForm,
      },
      {
        label: BANK_DOCUMENT,
        value: bankDocument ? VIEW_FILE : '-',
        action: bankDocument ? () => {
          showFileViewer({ id: get(bankDocument, 'file_id'), accessFormId })
        } : () => {
        },
      },
    ])

    return (
      <AccessFormView
        {...this.props}
        businessInfoDataSection={businessInfoDataSection}
        bankAccountInfoDataSection={bankAccountInfoDataSection}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessFormViewC)
