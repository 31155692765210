import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import getMany from 'utilities/get/getMany'
import { POST_BALANCE_ADJUSTMENT_F_REQUEST } from 'constants/flowConstants'
import { CREATE_BALANCE_ADJUSTMENT } from 'constants/amplitudeConstants'
import { ADD_FUNDS_CONFIRMATION_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitCreateBalanceAdjustmentReviewForm = (values, dispatch, props) => {
  const {
    credentials,
    formValues,
    processor,
  } = props

  const createBalanceAdjustmentForm = get(formValues, 'createBalanceAdjustment')

  const [
    amount,
    instrumentId,
    description,
  ] = getMany(createBalanceAdjustmentForm, [
    'amount',
    'instrumentId.value',
    'description',
  ])

  dispatch({
    type: POST_BALANCE_ADJUSTMENT_F_REQUEST,
    payload: {
      values: {
        amount,
        instrumentId,
        description,
        processor,
      },
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const balanceAdjustmentId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const balanceAdjustmentConfirmationPath = ADD_FUNDS_CONFIRMATION_PATH({ credentialId, balanceAdjustmentId })

        dispatch(redirectRequest({ path: balanceAdjustmentConfirmationPath }))
      },
    },
    actionId: sendAmplitudeActionEvent(CREATE_BALANCE_ADJUSTMENT, {
      amount,
      instrumentId,
      description,
      credentials,
    }),
  })
}

export default submitCreateBalanceAdjustmentReviewForm
