import moment from 'moment'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import formatDateUTC from 'utilities/formatters/formatDateUTC'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import { USD } from 'constants/currencyConstants'
import { NEEDS_RESPONSE } from 'constants/statusConstants'
import capitalize from 'lodash/capitalize'
import concat from 'lodash/concat'
import join from 'lodash/join'
import { M_D_YYYY } from 'constants/timeConstants'

const frontendDisputeModel = ({ data: dispute }) => {
  const [
    id,
    identityId,
    merchant, // TODO: missing from OL
    merchantObj,
    buyer, // TODO: missing from OL
    state,
    createdAt,
    updatedAt,
    respondBy,
    reason,
    amount,
    currency = USD, // TODO: do not see currency come back, do we assume USD?
    tags,
    adjustmentTransfers,
    adjustmentTransfersCount,
    paymentInstrument,
    caseId,
    reasonCode,
    pinDebitAdjustmentNumber,
    message,
    transferId,
    action,
    applicationId,
    identity,
    buyerIdentity,
    responseState,
  ] = getMany(dispute, [
    'id',
    'identity',
    'merchant', // TODO: missing from OL
    'merchantObj',
    'buyer', // TODO: missing from OL
    'state',
    'created_at',
    'updated_at',
    'respond_by',
    'reason',
    'amount',
    'currency',
    'tags',
    'adjustmentTransfers',
    'adjustmentTransfersCount',
    'paymentInstrument',
    'dispute_details.case_id',
    'dispute_details.reason_code',
    'dispute_details.pin_debit_adjustment_number',
    'message',
    'transfer',
    'action',
    'application',
    'identityObj',
    'buyerIdentity',
    'response_state',
  ])

  const currentDate = moment.utc().startOf('day')
  const respondByUTC = moment.utc(respondBy).startOf('day')
  const respondWithin = respondByUTC.diff(currentDate, 'days')

  return removeUndefined({
    id,
    identityId,
    merchant,
    merchantObj,
    buyer,
    state,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    respondBy,
    displayRespondBy: formatDateUTC({ date: respondBy, format: M_D_YYYY }),
    reason,
    displayReason: capitalize(reason),
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    tags,
    adjustmentTransfers,
    adjustmentTransfersCount,
    paymentInstrument,
    caseId,
    reasonCode,
    pinDebitAdjustmentNumber,
    isPinDebit: pinDebitAdjustmentNumber ? 'Yes' : 'No',
    message,
    transferId,
    transferDetails: frontendTransferModel({ data: transferId }),
    action,
    applicationId,
    identity,
    buyerIdentity,
    responseState,
    formattedReasonString: reason ? `${reasonCode ? `${reasonCode} - ` : ''}${capitalize(reason)}` : '',
    respondWithin: responseState === NEEDS_RESPONSE ? respondWithin : undefined,
    displayRespondWithin: join(concat(respondWithin, 'days'), ' '),
  })
}

export default frontendDisputeModel
