import React from 'react'
import PropTypes from 'prop-types'
import EditComplianceFormTemplateC from 'components/Customer/Forms/EditComplianceFormTemplateForm/EditComplianceFormTemplateFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const EditComplianceFormTemplateModal = ({
  complianceFormTemplate = {},
}) => {
  return (
    <GenericModal
      title='Edit Template Name'
      className='EditComplianceFormTemplateModal'
      Component={EditComplianceFormTemplateC}
      complianceFormTemplate={complianceFormTemplate}
    />
  )
}

EditComplianceFormTemplateModal.propTypes = {
  complianceFormTemplate: PropTypes.object,
}

export default EditComplianceFormTemplateModal
