import Subscription from './Subscription'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import { Link } from 'react-router'
import { getSubscriptionDataSelector, getSubscriptionPlanSelector } from 'state-layer/selectors'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getSubscriptionRequest from 'utilities/actions/get/getSubscriptionRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getSubscriptionPlanRequest from 'utilities/actions/get/getSubscriptionPlanRequest'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { CANCEL_SUBSCRIPTION_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  CANCELED,
  subscriptionStatusMap,
} from 'constants/statusConstants'

import {
  APPLICATION_PATH,
  MERCHANT_PATH,
  IDENTITY_PATH,
  EDIT_SUBSCRIPTION_PATH,
  SUBSCRIPTION_PLAN_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  BILLING_FREQUENCY,
  BILLING_SETTING,
  CANCEL_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  CUSTOMER,
  CUSTOMER_NAME,
  DESCRIPTION,
  EDIT_SUBSCRIPTION,
  EMAIL,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  PHONE,
  PLAN_NAME,
  RECURRING_PRICE,
  STATUS,
  SUBSCRIPTION,
  SUBSCRIPTION_NAME,
  TRIAL_DURATION,
  FREE_TRIAL_PHASE,
  VIEW_CUSTOMER,
  VIEW_SUBSCRIPTION_PLAN,
} from 'constants/language/languageConstants'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const subscriptionId = get(props, 'params.subscriptionId')
  const subscription = getSubscriptionDataSelector(state, subscriptionId)

  const [
    amount,
    currency,
    nickname,
    status,
    displayBillingInterval,
    displayCollectionMethod,
    applicationId,
    applicationName,
    merchantId,
    merchantName,
    identityId,
    customerName,
    paymentInstrument,
    subscriptionPlanId,
  ] = getMany(subscription, [
    'amount',
    'currency',
    'nickname',
    'state',
    'displayBillingInterval',
    'displayCollectionMethod',
    'application.id',
    'application.businessName',
    'merchant.id',
    'merchant.name',
    'identity.id',
    'identity.fullName',
    'paymentInstrument',
    'subscriptionPlanId',
  ])

  const buyerIdentity = get(subscription, 'identity')

  const subscriptionPlan = getSubscriptionPlanSelector(state, subscriptionPlanId)

  const [
    planName,
    description,
    displayAmount,
    trialDefaults,
    displayTrialDuration,
  ] = getMany(subscriptionPlan, [
    'planName',
    'description',
    'displayAmount',
    'trialDefaults',
    'displayTrialDuration',
  ])

  const hasAddOns = !isEmpty(trialDefaults)

  const headerData = {
    resource: {
      label: SUBSCRIPTION,
      id: subscriptionId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={amount} currency={currency} />,
      },
      {
        label: PLAN_NAME,
        value: planName || '-',
      },
      {
        label: SUBSCRIPTION_NAME,
        value: nickname,
      },
      {
        label: STATUS,
        value: <ColorcodedStatus data={subscriptionStatusMap} value={status} />,
      },
    ],
  }


  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_ACCOUNT_RESOURCE_TITLE,
        value: merchantName,
        path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ credentialId, identityId, merchantId }) : null,
      },
    ],
  }

  const subscriptionDetails = convertPageSectionDataToV2([
    {
      label: SUBSCRIPTION_NAME,
      value: nickname,
    },
    {
      label: BILLING_FREQUENCY,
      value: displayBillingInterval,
    },
    {
      label: RECURRING_PRICE,
      value: amount,
    },
    {
      label: PLAN_NAME,
      value: planName,
    },
    {
      label: BILLING_SETTING,
      value: displayCollectionMethod,
    },
  ])

  const planDetailsClickToCopies = [
    {
      content: subscriptionPlanId,
    },
  ]

  const planDetails = convertPageSectionDataToV2([
    {
      label: PLAN_NAME,
      value: planName,
    },
    {
      label: DESCRIPTION,
      value: description,
    },
    {
      label: RECURRING_PRICE,
      value: displayAmount,
    },
    {
      label: BILLING_FREQUENCY,
      value: displayBillingInterval,
    },
    {
      label: BILLING_SETTING,
      value: displayCollectionMethod,
    },
  ])

  const optionalAddOnsDetails = hasAddOns ? convertPageSectionDataToV2([{
    label: FREE_TRIAL_PHASE,
    value: 'Yes',
  },
  {
    label: TRIAL_DURATION,
    value: displayTrialDuration,
  },
  ]) : []

  const subscriptionPlanDetailsPath = <Link className='text-link' to={SUBSCRIPTION_PLAN_PATH({ credentialId, subscriptionPlanId })}>{VIEW_SUBSCRIPTION_PLAN}</Link>

  return {
    isFetching,
    subscriptionId,
    credentials,
    credentialId,
    contextBarData,
    headerData,
    subscriptionDetails,
    planDetails,
    planDetailsClickToCopies,
    subscriptionPlanDetailsPath,
    customerName,
    amount,
    status,
    subscription,
    subscriptionPlanId,
    subscriptionHasPlan: subscriptionPlanId,
    hasAddOns,
    optionalAddOnsDetails,
    paymentInstrument,
    buyerIdentity,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscription: ({ credentials, subscriptionId }) => dispatch(getSubscriptionRequest({ credentials, subscriptionId })),
    showCancelSubscriptionModal: (modalProps) => dispatch(showModalAction({ modalType: CANCEL_SUBSCRIPTION_MODAL, modalProps, className: 'modal-sm no-pad' })),
    getSubscriptionPlan: ({ credentials, subscriptionPlanId }) => { dispatch(getSubscriptionPlanRequest({ credentials, subscriptionPlanId })) },
  }
}

class SubscriptionC extends Component {
  componentDidMount() {
    const {
      subscriptionId,
      credentials,
      getSubscription,
    } = this.props

    if (subscriptionId) {
      getSubscription({ credentials, subscriptionId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      subscriptionId: prevSubscriptionId,
      subscriptionPlanId: prevSubscriptionPlanId,
    } = prevProps

    const {
      subscriptionId,
      subscriptionPlanId,
      credentials,
      getSubscription,
      getSubscriptionPlan,
    } = this.props

    if (subscriptionId && prevSubscriptionId !== subscriptionId) {
      getSubscription({ credentials, subscriptionId })
    }

    if (subscriptionPlanId && prevSubscriptionPlanId !== subscriptionPlanId) {
      getSubscriptionPlan({ credentials, subscriptionPlanId })
    }
  }

  render() {
    const {
      showCancelSubscriptionModal,
      subscriptionId,
      credentialId,
      customerName,
      amount,
      status,
    } = this.props

    const actions = [
      {
        label: CANCEL_SUBSCRIPTION,
        className: 'cancel-subscription-button',
        action: () => showCancelSubscriptionModal({ subscriptionId, customerName, amount }),
        condition: status !== CANCELED,
      },
      {
        label: EDIT_SUBSCRIPTION,
        className: 'edit-subscription-button',
        action: () => goToPath({ pathname: EDIT_SUBSCRIPTION_PATH({ credentialId, subscriptionId }) }),
        condition: status !== CANCELED,
      },
    ]

    return (
      <Subscription
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionC)
