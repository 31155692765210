import paymentsAPI from 'utilities/api/paymentsAPI'
import { FEES } from 'constants/apiConstants'

const getFeesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    path: FEES,
    queries,
    service: FEES,
  })
}

export default getFeesAPI
