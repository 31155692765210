import numeral from 'numeral'
import { getState } from 'state-layer/configureStore'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import replace from 'lodash/replace'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import moment from 'moment'

import {
  PRIVATE_CORPORATION_VALUE,
  PUBLIC_CORPORATION_VALUE,
  CORPORATION_VALUE,
} from 'constants/identityConstants'

const backendIdentityModel = ({ values, associatedIdentity = false }) => {
  const {
    id,
    businessName,
    businessType,
    doingBusinessAs,
    title,
    firstName,
    lastName,
    middleName,
    email,
    phone,
    principalPercentageOwnership,
    businessPhone,
    businessAddress = {},
    personalAddress = {},
    dateOfBirth = {},
    incorporationDate = {},
    annualCardVolume,
    maxTransactionAmount,
    mcc,
    defaultStatementDescriptor,
    website,
    tags,
    taxId,
    businessTaxId,
    previouslyProcessedCreditCards,
    application,
    taxAuthority,
    amexMid,
    discoverMid,
    averageCardTransferAmount,
    averageACHTransferAmount,
    annualACHVolume,
    businessDescription,
    cardPresentPercentage,
    ecommercePercentage,
    mailOrderTelephoneOrderPercentage,
    creditCheckAllowed,
    merchantAgreementAccepted,
    refundPolicy,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    personToPersonVolumePercentage,
    consumerToConsumerVolumePercentage,
    otherVolumePercentage,
    creditCheckUserAgent,
    creditCheckTimestamp,
    merchantAgreementUserAgent,
    merchantAgreementTimestamp,
    merchantAgreementIPAddress,
    creditCheckIPAddress,
    selectedApplicationId,
    ownershipType,
    achMaxTransactionAmount,
  } = values

  const {
    city: personalAddressCity,
    country: personalAddressCountry,
    line1: personalAddressLine1,
    line2: personalAddressLine2,
    postalCode: personalAddressPostalCode,
    region: personalAddressRegion,
  } = personalAddress

  const formattedPersonalAddress = removeUndefined({
    city: personalAddressCity,
    country: personalAddressCountry,
    line1: personalAddressLine1,
    line2: personalAddressLine2,
    postal_code: personalAddressPostalCode,
    region: personalAddressRegion,
  })

  const {
    city: businessAddressCity,
    country: businessAddressCountry,
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    postalCode: businessAddressPostalCode,
    region: businessAddressRegion,
  } = businessAddress

  const formattedBusinessAddress = removeUndefined({
    city: businessAddressCity,
    country: businessAddressCountry,
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    postal_code: businessAddressPostalCode,
    region: businessAddressRegion,
  })

  const {
    day: dobDay,
    month: dobMonth,
    year: dobYear,
  } = dateOfBirth

  const formattedDob = removeUndefined({
    day: dobDay,
    month: dobMonth,
    year: dobYear,
  })

  const {
    day: incDay,
    month: incMonth,
    year: incYear,
  } = incorporationDate

  const formattedIncorporationDate = removeUndefined({
    day: incDay,
    month: incMonth,
    year: incYear,
  })

  const state = getState()
  const credentials = getCurrentCredentials(state)
  const isRolePartnerCredential = isRolePartner({ credentials })
  const normalizedBusinessType = includes([PRIVATE_CORPORATION_VALUE, PUBLIC_CORPORATION_VALUE], businessType) ? CORPORATION_VALUE : businessType

  // merchant agreement has to be accepted to send underwriting data
  const additionalUnderwritingData = merchantAgreementAccepted ? {
    refund_policy: refundPolicy,
    card_volume_distribution: {
      ecommerce_percentage: numeral(ecommercePercentage).value(),
      card_present_percentage: numeral(cardPresentPercentage).value(),
      mail_order_telephone_order_percentage: numeral(mailOrderTelephoneOrderPercentage).value(),
    },
    average_ach_transfer_amount: averageACHTransferAmount ? numeral(averageACHTransferAmount).value() : undefined,
    average_card_transfer_amount: averageCardTransferAmount ? numeral(averageCardTransferAmount).value() : undefined,
    merchant_agreement_accepted: merchantAgreementAccepted,
    merchant_agreement_ip_address: merchantAgreementIPAddress,
    merchant_agreement_timestamp: merchantAgreementTimestamp ? moment(merchantAgreementTimestamp).toISOString() : undefined,
    merchant_agreement_user_agent: merchantAgreementUserAgent,
    credit_check_allowed: creditCheckAllowed,
    credit_check_ip_address: creditCheckIPAddress,
    credit_check_timestamp: creditCheckTimestamp ? moment(creditCheckTimestamp).toISOString() : undefined,
    credit_check_user_agent: creditCheckUserAgent,
    annual_ach_volume: annualACHVolume ? numeral(annualACHVolume).value() : undefined,
    business_description: businessDescription,
    volume_distribution_by_business_type: {
      other_volume_percentage: numeral(otherVolumePercentage).value(),
      person_to_person_volume_percentage: numeral(personToPersonVolumePercentage).value(),
      business_to_business_volume_percentage: numeral(businessToBusinessVolumePercentage).value(),
      business_to_consumer_volume_percentage: numeral(businessToConsumerVolumePercentage).value(),
      consumer_to_consumer_volume_percentage: numeral(consumerToConsumerVolumePercentage).value(),
    },
  } : undefined

  const entity = removeUndefined({
    title,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    email,
    business_name: businessName,
    business_type: normalizedBusinessType,
    doing_business_as: doingBusinessAs,
    phone,
    business_phone: businessPhone,
    personal_address: !isEmpty(formattedPersonalAddress) ? formattedPersonalAddress : undefined,
    business_address: !isEmpty(formattedBusinessAddress) ? formattedBusinessAddress : undefined,
    dob: !isEmpty(formattedDob) ? formattedDob : undefined,
    incorporation_date: !isEmpty(formattedIncorporationDate) ? formattedIncorporationDate : undefined,
    mcc,
    url: website,
    ownership_type: ownershipType,
    default_statement_descriptor: defaultStatementDescriptor,
    max_transaction_amount: maxTransactionAmount ? numeral(maxTransactionAmount).value() : undefined,
    ach_max_transaction_amount: achMaxTransactionAmount ? numeral(achMaxTransactionAmount).value() : undefined,
    annual_card_volume: annualCardVolume ? numeral(annualCardVolume).value() : undefined,
    principal_percentage_ownership: principalPercentageOwnership,
    tax_id: replace(taxId, /-/g, '') || undefined,
    business_tax_id: replace(businessTaxId, /-/g, '') || undefined,
    has_accepted_credit_cards_previously: previouslyProcessedCreditCards,
    tax_authority: taxAuthority,
    amex_mid: amexMid,
    discover_mid: discoverMid,
  })

  return removeUndefined({
    id,
    entity: !isEmpty(entity) ? entity : undefined,
    additional_underwriting_data: additionalUnderwritingData,
    tags,
    application: isRolePartnerCredential ? undefined : application, // TODO: in future account for selectedApplication
  })
}

export default backendIdentityModel
