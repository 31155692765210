import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import AchAndAdditionalForm from './AchAndAdditionalForm'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)
  const isFlex = isFlexPlatform()
  const requiresGuardRails = isFetchingCurrentUser ? true : isFlex
  const isFetching = requiresGuardRails ? (get(state, `feeProfilesR.${FETCHING}`, true) || get(state, `feeProfileRulesR.${FETCHING}`, true)) : get(state, `feeProfilesR.${FETCHING}`, true)
  const feeProfile = get(props, 'feeProfile')
  const formValues = get(props, 'formValues.ach')

  const blendedFeeProfileRules = get(props, 'blendedFeeProfileRules')
  const interchangeFeeProfileRules = get(props, 'interchangeFeeProfileRules')
  const applyGuardRails = get(props, 'applyGuardRails')
  const guardRail = blendedFeeProfileRules || interchangeFeeProfileRules

  const initialValues = !isFetching ?
    {
      achBasisPoints: get(formValues, 'achBasisPoints') || get(feeProfile, 'achBasisPoints'),
      achFixedFee: get(formValues, 'achFixedFee') || get(feeProfile, 'achFixedFee'),
      achBasisPointsFeeLimit: get(formValues, 'achBasisPointsFeeLimit') || get(feeProfile, 'achBasisPointsFeeLimit'),
      achDebitReturnFixedFee: get(formValues, 'achDebitReturnFixedFee') || get(feeProfile, 'achDebitReturnFixedFee'),
      achCreditReturnFixedFee: get(formValues, 'achCreditReturnFixedFee') || get(feeProfile, 'achCreditReturnFixedFee'),
      ancillaryFixedFeePrimary: get(formValues, 'ancillaryFixedFeePrimary') || get(feeProfile, 'ancillaryFixedFeePrimary'),
      ancillaryFixedFeeSecondary: get(formValues, 'ancillaryFixedFeeSecondary') || get(feeProfile, 'ancillaryFixedFeeSecondary'),
    } : null

  return {
    initialValues,
    applyGuardRails,
    guardRail,
    isFetching,
  }
}

class AchAndAdditionalFormC extends Component {
  render() {
    return (
      <AchAndAdditionalForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AchAndAdditionalFormC)
