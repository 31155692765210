import './OnboardingFormsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getOnboardingFormsWithDataAndApplication } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { ONBOARDING_FORMS } from 'constants/linkConstants'
import { GET_ONBOARDING_FORMS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { ONBOARDING_FORMS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  ONBOARDING_FORMS_RESOURCE_TITLE,
  ONBOARDING_FORMS_EMPTY_TABLE_MESSAGE,
  ONBOARDING_FORMS_TABLE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const OnboardingForms = ({
  onboardingFormsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  actions = [],
  quickFilters,
}) => {
  return (
    <div className='OnboardingForms'>
      <HeaderV2C
        title={ONBOARDING_FORMS_RESOURCE_TITLE}
        subTitle={ONBOARDING_FORMS_TABLE_SUBTITLE_DESCRIPTION}
        learnMoreLink={ONBOARDING_FORMS_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_ONBOARDING_FORMS}
        columnDescriptors={columnDescriptors}
        selector={getOnboardingFormsWithDataAndApplication}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={ONBOARDING_FORMS}
        allowedFilters={allowedFilters}
        path={onboardingFormsPath}
        isFetching={isFetching}
        emptyMessage={ONBOARDING_FORMS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV='Onboarding-Forms'
        fileResourceTitleCSV={ONBOARDING_FORMS_RESOURCE_TITLE}
        actions={actions}
        quickFilters={quickFilters}
      />
    </div>
  )
}

OnboardingForms.propTypes = {
  onboardingFormsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default OnboardingForms
