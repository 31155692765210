import { change } from 'redux-form'
import get from 'lodash/get'

import {
  allowedKeyPressControls,
  MOUSE_CLICK_KEYCODE,
} from 'constants/focusControlConstants'

const toggleCheckedStatus = ({ dispatch, event, name, form }) => {
  if (allowedKeyPressControls(event)) {
    const checked = get(event, 'target.checked', false)

    const targetChecked = get(event, 'keyCode') === MOUSE_CLICK_KEYCODE ? checked : !checked

    dispatch(change(form, name, targetChecked))
  }
}

export default toggleCheckedStatus
