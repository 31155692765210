import React from 'react'
import column from 'utilities/table/column'

const columnDescriptors = [
  column('Domain', 'domain', { className: 'id' }),
  column('', 'remove-domain', {
    className: 'medium',
    actionIndex: 0,
    actionLabel: 'Remove Domain',
  }),
]

export default columnDescriptors
