import './CreateTransactionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const CreateTransaction = ({
  steps = [],
  edit = false,
}) => {
  return (
    <div className='CreateTransaction'>
      <FlowsC
        flowClassName='create-transaction'
        steps={steps}
        hideFlowProgress
        layout='vertical'
        flowVariant='form-page-borderless'
        edit={edit}
      />
    </div>
  )
}

CreateTransaction.propTypes = {
  steps: PropTypes.array,
  edit: PropTypes.bool,
}

export default CreateTransaction
