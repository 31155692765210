import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstruments from './PaymentInstruments'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { PAYMENT_INSTRUMENTS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

import {
  PAYMENT_INSTRUMENT_TYPE,
  APPLICATION_ID,
  OWNER_IDENTITY_ID,
  NAME,
  ACCOUNT_ROUTING_NUMBER,
  ACCOUNT_LAST4,
  EXPIRATION_YEAR,
  EXPIRATION_MONTH,
  BRAND,
  FINGERPRINT,
  PAYMENT_INSTRUMENT_ENABLED,
  BIN,
  LAST_4,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  ACCOUNT_LAST4,
  ACCOUNT_ROUTING_NUMBER,
  APPLICATION_ID,
  BIN,
  BRAND,
  EXPIRATION_MONTH,
  EXPIRATION_YEAR,
  FINGERPRINT,
  LAST_4,
  NAME,
  OWNER_IDENTITY_ID,
  convertFilterToResourceIdFilter('Payment Instrument'),
  PAYMENT_INSTRUMENT_TYPE,
  PAYMENT_INSTRUMENT_ENABLED,
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [DATE_QUICK_FILTER]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `paymentInstrumentsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentInstrumentsPath = PAYMENT_INSTRUMENTS_PATH({ credentialId })
  const hiddenFilters = get(props, 'hiddenFilters', [])
  const mergedFilters = filter(allowedFilters, ({ field }) => !hiddenFilters.includes(field))

  return {
    allowedFilters: mergedFilters,
    initialQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    paymentInstrumentsPath,
    quickFilters,
  }
}

class PaymentInstrumentsC extends Component {
  render() {
    return (
      <PaymentInstruments {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PaymentInstrumentsC)
