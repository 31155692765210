import './ColorcodedStatusS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import get from 'lodash/get'

import {
  DEFAULT,
  statusIconMap,
} from 'constants/statusConstants'

const ColorcodedStatus = ({
  value = '',
  data = {},
  icon,
  hideValue = false,
  statusDescription = '',
  titleCase = true,
  customValue,
  customIconMap = statusIconMap,
  iconStyles = {},
  falsyValue = false,
  tooltip = '',
  tooltipPosition,
}) => {
  // TODO: default to circle on icon props
  const faStyle = get(iconStyles, value, 'fa')
  const iconClasses = icon ? classnames(`custom-icon ${faStyle} fa-${icon}`, get(data, value, DEFAULT)) : classnames(faStyle, get(customIconMap, get(data, value), 'fa-circle'), get(data, value, DEFAULT))
  const valueToDisplay = customValue ? customValue : value
  const normalizedValue = titleCase ? snakeCaseToTitleCase({ key: valueToDisplay }) : valueToDisplay

  return (
    <div className='ColorcodedStatus flex items-center'>
      { (value || falsyValue) && <span><i className={iconClasses} /></span> }
      { (value || falsyValue) && !statusDescription && !hideValue && <span className='value' title={normalizedValue}>{normalizedValue}</span> }
      { (value || falsyValue) && statusDescription && !hideValue && <span>{normalizedValue}: {statusDescription}</span> }
      { (!value && !falsyValue) && <span>-</span> }
      { tooltip && <TooltipLabelC message={tooltip} position={tooltipPosition} /> }
    </div>
  )
}

ColorcodedStatus.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  hideValue: PropTypes.bool,
  statusDescription: PropTypes.string,
  titleCase: PropTypes.bool,
  customValue: PropTypes.string,
  falsyValue: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
}

export default ColorcodedStatus
