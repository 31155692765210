import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  IDENTITY,
  IDENTITIES,
} from 'constants/apiConstants'

const patchIdentityAPI = ({ values, credentials, meta, id }) => {
  const path = IDENTITY({ identityId: get(values, 'id', id) })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: IDENTITIES,
  })
}

export default patchIdentityAPI
