import {
  USA,
  NON_USA,
  BE_UNKNOWN,
} from 'constants/paymentInstrumentConstants'

const formatIssuerCountry = ({ issuerCountry }) => {
  switch (issuerCountry) {
    case USA: return 'United States'

    case NON_USA: return 'International'

    case BE_UNKNOWN: return 'Unknown'

    default: return null
  }
}

export default formatIssuerCountry
