import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION,
  SUBSCRIPTION_BILLING,
} from 'constants/apiConstants'

const deleteSubscriptionAPI = ({
  id,
  queries,
  credentials,
  meta,
}) => {
  return paymentsAPI.delete({
    meta,
    credentials,
    path: SUBSCRIPTION_BILLING({ subscriptionId: id }),
    queries,
    service: SUBSCRIPTION,
  })
}

export default deleteSubscriptionAPI
