import React from 'react'
import PropTypes from 'prop-types'
import AddIdentityBankAccountFormC from 'components/Customer/Forms/AddIdentityBankAccountForm/AddIdentityBankAccountFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const AddIdentityBankAccountModal = ({
  identityId,
  elavonEnabled,
  settlementAliasWarningObject,
  successFunction,
  merchantId,
  warningMessage,
}) => {
  return (
    <GenericModal
      className='AddIdentityBankAccountModal'
      Component={AddIdentityBankAccountFormC}
      identityId={identityId}
      elavonEnabled={elavonEnabled}
      settlementAliasWarningObject={settlementAliasWarningObject}
      successFunction={successFunction}
      merchantId={merchantId}
      warningMessage={warningMessage}
    />
  )
}

AddIdentityBankAccountModal.propTypes = {
  identityId: PropTypes.string,
  elavonEnabled: PropTypes.bool,
  settlementAliasWarningObject: PropTypes.object,
  successFunction: PropTypes.func,
  merchantId: PropTypes.string,
  warningMessage: PropTypes.string,
}

export default AddIdentityBankAccountModal
