import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingMerchants from 'components/Customer/Pages/UnderwritingMerchants/UnderwritingMerchants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import createUrl from 'utilities/create/createUrl'
import { FETCHING } from 'constants/reducerConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { MANUAL_REVIEW } from 'constants/underwritingReviewConstants'
import { UNDERWRITING_MERCHANT_PATH } from 'constants/pathConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  SORT,
  LIMIT,
  OFFSET,
  createdQueriesForLastMonths,
} from 'constants/queryConstants'

import {
  REVIEW_ID,
  APPLICATION_ID,
  IDENTITY_ID,
  MERCHANT_ID,
  RUN_ID,
  UNDERWRITING_BUSINESS_TYPE,
  BUSINESS_NAME_EXACT,
  DOING_BUSINESS_AS_EXACT,
  ASSIGNEE,
  RECOMMENDATION,
  COUNTRY,
} from 'constants/filterConstants'

const initialQueries = {
  sort: 'updated_at,desc',
}

const allowedFilters = sortBy([
  REVIEW_ID,
  APPLICATION_ID,
  IDENTITY_ID,
  MERCHANT_ID,
  RUN_ID,
  UNDERWRITING_BUSINESS_TYPE,
  BUSINESS_NAME_EXACT,
  DOING_BUSINESS_AS_EXACT,
  ASSIGNEE,
  COUNTRY,
  // RECOMMENDATION,
], 'label')

const initialFilters = { state: { eq: MANUAL_REVIEW } }

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const queries = get(props, 'queries', {})
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)
  const mergedQueries = merge({}, initialQueries, queries)

  const underwritingReviewPath = ({ id, subjectId, entityId, entityType }) => createUrl({
    url: UNDERWRITING_MERCHANT_PATH({ credentialId, reviewId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
      tab: 'underwriting',
    }),
  })

  return {
    initialQueries: mergedQueries,
    isFetching,
    underwritingReviewPath,
    limit,
    offset,
    sort,
    credentials,
    currentUserEmail,
    allowedFilters,
    initialFilters,
  }
}

class UnderwritingMerchantsC extends Component {
  render() {
    return (
      <UnderwritingMerchants
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UnderwritingMerchantsC)
