import {
  DUMMY_V1,
  LITLE_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'lacore',
  id: 'DASH37hruuKYcJ7JrfbNpSJPjJ',
  applicationLogoImage: 'https://finix-payments.github.io/customer-logos/assets/lacore-logo.png',
  faviconImage: 'https://finix-payments.github.io/customer-logos/assets/lacore-favicon.png',
  credentialsHost: 'https://lacore-dashboard.herokuapp.com',
  paymentsApiHosts: 'https://*.finixpayments.com',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: 'kF1Aj9h9glbdRKGjl4Td3IzG52qbgvh1',
    domain: 'lacore.auth0.com',
    allowSignUp: false,
    title: '',
    logo: '', // 'https://finix-payments.github.io/customer-logos/assets/lacore-logo.png',
  },
  availableProcessors: ['DUMMY_V1', 'LITLE_V1'],
  customStylesheet: 'https://finix-payments.github.io/customer-logos/assets/lacore/application.css',
  dashboardServiceV2: true, // using this to control dashboard service version
}
