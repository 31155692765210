import {
  DUMMY_V1,
  LITLE_V1,
  VANTIV_V1,
  MICROBILT_V1,
} from 'constants/processorConstants'

function registrationLink(route, text) {
  return { text, route }
}

function field(type, label, value) {
  return { type, label, value }
}

function pair(label, value) {
  if (value === undefined) {
    return { label, disabled: true }
  }
  return { label, value }
}

const BULK_ACTIONS = [
  pair('Bulk Actions'),
  pair('Assign to me', 'ASSIGN-SELF'),
  pair('Reject (MATCH_LIST)', 'REJECTED-MATCH_LIST'),
  pair('Reject (HIGH_RISK)', 'REJECTED-HIGH_RISK'),
  pair('Reject (INSUFFICIENT_HISTORY)', 'REJECTED-INSUFFICIENT_HISTORY'),
  pair('Reject (FRAUD)', 'REJECTED-FRAUD'),
  pair('Reject (PROHIBITED)', 'REJECTED-PROHIBITED'),
  pair('Reject (MULTIPLE_FACTORS)', 'REJECTED-MULTIPLE_FACTORS'),
  pair('Reject (LOW_FICO_SCORE)', 'REJECTED-LOW_FICO_SCORE'),
  pair('Reject (POOR_PROCESSING_HISTORY)', 'REJECTED-POOR_PROCESSING_HISTORY'),
  pair('Reject (VIOLATES_CHARGEBACK_THRESHOLD)', 'REJECTED-VIOLATES_CHARGEBACK_THRESHOLD'),
  pair('Reject (VIOLATES_RETURNS_THRESHOLD)', 'REJECTED-VIOLATES_RETURNS_THRESHOLD'),
  pair('Reject (VIOLATES_UNDERWRITING_REQUIREMENTS)', 'REJECTED-VIOLATES_UNDERWRITING_REQUIREMENTS'),
  pair('Approve', 'ACCEPTED'),
]

const ADDITIONAL_FORM_SECTIONS = [{
  title: 'Additional Information',
  fields: [
    field('text', 'Merchandise/services sold', 'tags.Merchandise/services sold'),
    field('currency', 'Average Transaction', 'tags.Average Transaction'),
    field('currency', 'High Transaction', 'tags.High Transaction'),
    field('text', 'Return Policy', 'tags.Return Policy'),
    field('text', 'Warranties', 'tags.Warranties'),
    field('percentage', 'Instore (CP) %', 'tags.Instore (CP) %'),
    field('percentage', 'MOTO (key entered - CNP) %', 'tags.MOTO (key entered - CNP) %'),
    field('percentage', 'Internet %', 'tags.Internet %'),
    field('percentage', 'Visa/MC/Discovery volume relative to prepaid sales', 'tags.Visa/MC/Discovery volume relative to prepaid sales'),
    field('percentage', '% Percent Required for Pre-Payment or Down Payment', 'tags.% Percent Required for Pre-Payment or Down Payment'),
    field('percentage', 'American Express volume related to pre-paid Sales', 'tags.American Express volume related to pre-paid Sales'),
  ],
}]

export const DATA = {
  name: 'lightspeed',
  id: 'DASH4RZfKActjqLzJppVcs86EP',
  applicationLogoImage: 'https://finix-payments.github.io/customer-logos/assets/vantiv-now-worldpay.png',
  credentialsHost: 'https://vantiv.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  paymentsApiHosts: 'https://*.finix.io',
  auth0: {
    clientID: 'fE4C8oNCLVduuRJ1ZSXaq2ieKZTkD9Y3',
    domain: 'lightspeed-dashboard.auth0.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'VANTIV_V1', 'MICROBILT_V1'],
  customStylesheet: 'https://finix-payments.github.io/customer-logos/assets/vantiv/application.css',
  registrationSteps: [
    registrationLink('account/registrations/edit/index', 'Identity Information'),
    registrationLink('account/registrations/edit/bank-accounts', 'Bank Account'),
    registrationLink('account/registrations/edit/associated-identities', 'Beneficial Owners'),
    registrationLink('account/registrations/edit/processors', 'Processors'),
    registrationLink('account/registrations/edit/verifications', 'Credit Report'),
  ],
  identityFormTagFields: [
    'Merchant Processing Agreement Signer Title',
    'Merchant Processing Agreement Signer Initials',
    'Merchant Processing Agreement Signer Full name',

    'Merchant Compliance Contact - Name',
    'Merchant Compliance Contact - Phone',
    'Merchant Compliance Contact - Email Address (if preferred form of communication)',

    'Years At Business Address',
    'Years At Corporate Physical Address',
    'Years At Personal Address',

    'Number Of Locations',
    'Has this business ever filed for bankruptcy',
    'Any present intent to file bankruptcy',

    'Business Email Address',

    'Corporate Physical Address',
    'Merchandise/services sold',
    'Average Transaction Amount in cents',
    'Max Transaction Amount in cents',
    'Warranties provided by the Merchant (excluding manufacturer warranties)',
    'Return Policy',
    'Card Present %',
    'Card Not Present / MOTO / Internet Transaction %',
    'Business To Business',
    'Internet Transaction',
    'Visa/MC/Discover volume related to pre-paid sales',
    'Estimated Total Visa/MC/Discover Monthly volume',
    'American Express volume related to pre-paid sales',
    'Estimated Total American Express Monthly volume',
    '% Required for Pre-Payment or Down Payment',
    'By checking this box, I am electing to opt out of receiving American Express Marketing materials',
    'Has this Business, or any of its predecessors or affiliates, ever been fined by Visa or MasterCard Discover or American Express?',
    'Have you suffered a system intrusion that resulted in the compromise of account data?',
    'Payment Gateway',
    'Other third party service providers with access to cardholder data',
  ],
  identityQueueRejectionReasons: [
    'MATCH_LIST',
    'HIGH_RISK',
    'INSUFFICIENT_HISTORY',
    'FRAUD',
    'PROHIBITED',
    'MULTIPLE_FACTORS',
    'LOW_FICO_SCORE',
    'POOR_PROCESSING_HISTORY',
    'VIOLATES_CHARGEBACK_THRESHOLD',
    'VIOLATES_RETURNS_THRESHOLD',
    'VIOLATES_UNDERWRITING_REQUIREMENTS',
  ],
  'applications/index/newApplicationLinkName': 'registrations/new',
  'applications/new/additionalFormSections': ADDITIONAL_FORM_SECTIONS,
  'applications/show/transfers/isDisplayTraceId': true,
  'identities/index/showIdColumn': true,
  'identities/new/additionalFormSections': ADDITIONAL_FORM_SECTIONS,
  'identities/show/transfers/isDisplayTraceId': true,
  'settings/isShowProcessorInformation': true,
  'transfers/index/isDisplayTraceId': true,
  'merchants/index/isShowExpanded': true,
  'onboarding/identities/bulkActions': BULK_ACTIONS,
  'onboarding/merchants/bulkActions': BULK_ACTIONS,
  'settlements/show/funding-transfers/isDisplayTraceId': true,
  strings: {
    'label for business name': 'Legal Business Name',
    'Trace ID': 'Order ID',
    'Associated Identities': 'Beneficial Owners',
    'Associated Identity': 'Beneficial Owner',
    'New Associated Identity': 'New Beneficial Owner',
    'No associated identities available': 'No beneficial owners available',
    'Associated identity created': 'Beneficial owner created',
  },
  dashboardServiceV2: true, // using this to control dashboard service version
}
