import { PATCH_COMPLIANCE_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { TOGGLE_AUTO_GENERATE_COMPLIANCE_TEMPLATE_FORMS } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const patchComplianceSettingsRequest = ({
  queries,
  credentials,
  values,
}) => createAction({
  type: PATCH_COMPLIANCE_SETTINGS_F_REQUEST,
  queries,
  credentials,
  values,
  meta: {
    actionId: sendAmplitudeActionEvent(TOGGLE_AUTO_GENERATE_COMPLIANCE_TEMPLATE_FORMS, removeUndefined({
      credentials,
      autoGenerateComplianceForms: get(values, 'allow_automatic_form_generation'),
      autoRenewComplianceForms: get(values, 'allow_annual_form_generation'),
      linkedTo: get(queries, 'linked_to'),
      queries,
    })),
  },
})

export default patchComplianceSettingsRequest
