import { fieldEmpty } from 'utilities/validate'
import getMany from 'utilities/get/getMany'

const validateCustomerOnboardingFormWelcomeForm = (values) => {
  const [
    businessType,
    country,
  ] = getMany(values, [
    'businessData.businessType',
    'country',
  ])

  return {
    businessData: {
      businessType: fieldEmpty(businessType, 'Business Type'),
    },
    country: fieldEmpty(country, 'Country'),
  }
}

export default validateCustomerOnboardingFormWelcomeForm
