import './LiveAccountApplicationPricingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import Loader from 'components/Shared/Loader/Loader'
import submitLiveAccountApplicationPricingForm from 'utilities/submit/submitLiveAccountApplicationPricingForm'
import validateLiveAccountApplicationPricingForm from 'utilities/validate/validateLiveAccountApplicationPricingForm'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { LIVE_ACCOUNT_APPLICATION_PRICING_FORM } from 'constants/formConstants'
import { STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK } from 'constants/urlConstants'
import { DASHBOARD_TYPE_BE_TO_LABEL_MAP } from 'constants/dashboardConfigurationsConstants'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  PRICING_INFO_DESCRIPTION,
  SAVE_AND_CONTINUE,
  PRICING_NOTICE,
  CANCEL,
  EXIT_APPLICATION_FORM,
  PLEASE_COMPLETE_STEP_MESSAGE,
  STANDALONE_MERCHANT_PRICING_INFO_TITLE,
  STANDALONE_MERCHANT_PRICING_INFO_DESCRIPTION,
  STANDALONE_MERCHANT_PRICING_NOTICE,
  STARTER,
  STARTER_PRICING_SUBTITLE,
  VIEW_DETAILS,
} from 'constants/language/languageConstants'

const LiveAccountApplicationPricingForm = ({
  dashboardType,
  pricingTypeOptions = [],
  handleSubmit = () => {},
  accessFormId = '',
  showExitApplicationFormModal = () => {},
  showCancelApplicationFormModal = () => {},
  isFormInvalid = false,
  isStandaloneMerchant = false,
  isFetchingCurrentUser = true,
}) => {
  return (
    <form className='LiveAccountApplicationPricingForm' onSubmit={handleSubmit(submitLiveAccountApplicationPricingForm)}>
      { isFetchingCurrentUser && <Loader /> }
      { !isFetchingCurrentUser && (
        <>
          <div className='form-header'>
            <h3>{isStandaloneMerchant ? STANDALONE_MERCHANT_PRICING_INFO_TITLE : `Select a pricing that fits your needs as a ${get(DASHBOARD_TYPE_BE_TO_LABEL_MAP, dashboardType, 'business')}`}</h3>
            <div className='p-1'>{isStandaloneMerchant ? STANDALONE_MERCHANT_PRICING_INFO_DESCRIPTION : PRICING_INFO_DESCRIPTION}</div>
          </div>
          <div className='form-content'>
            {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} />}

            { isStandaloneMerchant ? (
              <div className='pricing-type'>
                <div className='starter-title p-2-bold'>{STARTER}</div>
                <div>{STARTER_PRICING_SUBTITLE}<a onClick={sendAmplitudeActionEvent(STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK)} className='view-details-link text-link' href={STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK} target='blank'>{VIEW_DETAILS} <i className={`fa fa-${EXTERNAL_LINK}`} /></a> </div>
              </div>
            ) : (
              <Field
                name='pricingType'
                component={RadioButton}
                options={pricingTypeOptions}
              />
            )}

            <div className='pricing-notice'>{isStandaloneMerchant ? STANDALONE_MERCHANT_PRICING_NOTICE : PRICING_NOTICE}</div>
          </div>

          <div className='form-footer'>
            <div className='footer-button-container'>
              <div className='exit-and-save-container'>
                {accessFormId && <Button onClick={showExitApplicationFormModal} label={EXIT_APPLICATION_FORM} variant='ghost' size='large' />}
                {!accessFormId && <Button onClick={showCancelApplicationFormModal} label={CANCEL} variant='ghost' size='large' />}
                <Button type='submit' label={SAVE_AND_CONTINUE} size='large' />
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

LiveAccountApplicationPricingForm.propTypes = {
  handleSubmit: PropTypes.func,
  dashboardType: PropTypes.string,
  pricingTypeOptions: PropTypes.array,
  accessFormId: PropTypes.string,
  showExitApplicationFormModal: PropTypes.func,
  showCancelApplicationFormModal: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  isFetchingCurrentUser: PropTypes.bool,
}

export default reduxForm({
  form: LIVE_ACCOUNT_APPLICATION_PRICING_FORM,
  validate: validateLiveAccountApplicationPricingForm,
  enableReinitialize: true,
})(LiveAccountApplicationPricingForm)
