import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENTS,
} from 'constants/apiConstants'

const getSettlementsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: SETTLEMENTS,
    queries,
    credentials,
    service: SETTLEMENTS,
  })
}

export default getSettlementsAPI
