import removeUndefined from 'utilities/remove/removeUndefined'

const backendAuthorizationModel = ({ values }) => {
  const {
    amount,
    currency,
    merchantId,
    paymentInstrumentId,
    additionalPurchaseData,
    tags,
    voidMe,
    captureAmount,
    operationKey,
  } = values

  return removeUndefined({
    amount,
    currency,
    merchant: merchantId,
    source: paymentInstrumentId,
    additional_purchase_data: additionalPurchaseData,
    tags,
    void_me: voidMe,
    capture_amount: captureAmount,
    operation_key: operationKey,
  })
}

export default backendAuthorizationModel
