import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import Button from 'components/Shared/Button/Button'
import submitContactSalesForm from 'utilities/submit/submitContactSalesForm'
import validateContactSalesForm from 'utilities/validate/validateContactSalesForm'
import { CONTACT_SALES_FORM } from 'constants/formConstants'

import {
  CANCEL,
  CONTACT_SALES_MESSAGE,
  NO,
  PROCESS_PAYMENTS_QUESTION,
  SEND,
  COMPANY_TITLE,
  YES,
} from 'constants/language/languageConstants'

const CONTACT_SALES_OPTIONS = [
  { label: YES, value: 'true' },
  { label: NO, value: 'false' },
]

const ContactSalesForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
}) => {
  return (
    <form className='ContactSalesForm' onSubmit={handleSubmit(submitContactSalesForm)}>
      <div>{CONTACT_SALES_MESSAGE}</div>

      <Field
        name='title'
        label={COMPANY_TITLE}
        component={InputField}
      />

      <Field
        name='processPayments'
        component={RadioButton}
        options={CONTACT_SALES_OPTIONS}
        label={PROCESS_PAYMENTS_QUESTION}
      />

      <Field
        name='message'
        label={<div className='flex'><div>Message</div><div className='optional p-2'>Optional</div></div>}
        component={TextAreaField}
      />

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={SEND} />
      </div>
    </form>
  )
}

ContactSalesForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: CONTACT_SALES_FORM,
  validate: validateContactSalesForm,
})(ContactSalesForm)
