import './ToggleDataSectionsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DictionaryListV2 from 'components/Customer/Shared/Page/DictionaryList/DictionaryListV2'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_ICON,
  ANGLE_UP_ICON,
  ANGLE_DOWN_ICON,
} from 'constants/iconConstants'

const ToggleDataSections = ({
  toggleSection,
  showSections,
  sectionData,
  sectionTitle,
  sectionName,
  sectionFields,
  title,
  emptyMessage,
  id,
}) => {
  const formattedEmptyMessage = emptyMessage || `No ${title} Provided`

  return (
    <div className='ToggleDataSections'>
      {
        !isEmpty(sectionData) ? map(sectionData, (section) => {
          const sectionTitleText = get(section, sectionTitle)
          const name = get(section, sectionName)
          const showSection = get(showSections, name)
          const headerClasses = classnames('header', 'flex', 'space-between', { show: showSection })

          const iconClasses = classnames('fa', {
            [`fa-${ANGLE_UP_ICON}`]: showSection,
            [`fa-${ANGLE_DOWN_ICON}`]: !showSection,
          })

          const sectionDataValues = map(sectionFields, ({ label, value, formatter }) => {
            const fieldValue = get(section, value)

            return {
              label,
              value: fieldValue,
              formatter,
            }
          })

          return (
            <div className='ToggleDataSection section'>
              <div className={headerClasses} onClick={() => toggleSection(name)}>
                <h6>{sectionTitleText}</h6>
                <i className={iconClasses} />
              </div>

              {
                showSection && (
                  <div className='contents flex row space-between'>
                    <DictionaryListV2
                      data={sectionDataValues}
                    />
                  </div>
                )
              }
            </div>
          )
        }) : (
          <span className='emptyMessage'><i className={`fa fa-${ALERT_ICON}`} />{formattedEmptyMessage}</span>
        )
      }
    </div>
  )
}

ToggleDataSections.propTypes = {
  toggleSection: PropTypes.func,
  showSections: PropTypes.object,
  sectionData: PropTypes.object,
  sectionTitle: PropTypes.string,
  sectionName: PropTypes.string,
  sectionFields: PropTypes.array,
  emptyMessage: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.string,
}

export default ToggleDataSections
