import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SetupMFASMSFormC from 'components/Customer/Forms/SetupMFASMSForm/SetupMFASMSFormC'

import {
  SETUP_MULTI_FACTOR_AUTHENTICATION,
  PLEASE_ENTER_MOBILE_NUMBER_TO_RECEIVE_TEXT,
} from 'constants/language/languageConstants'

const SetupMFASMSModal = ({
  type,
  token,
}) => {
  return (
    <GenericModal
      className='SetupMFASMSModal'
      title={SETUP_MULTI_FACTOR_AUTHENTICATION}
      subTitle={PLEASE_ENTER_MOBILE_NUMBER_TO_RECEIVE_TEXT}
    >
      <SetupMFASMSFormC
        type={type}
        token={token}
      />
    </GenericModal>
  )
}

SetupMFASMSModal.propTypes = {
  type: PropTypes.string,
  token: PropTypes.string,
}

export default SetupMFASMSModal
