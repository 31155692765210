import Application from './Application'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import ApplicationInfoC from 'components/Customer/Pages/Application/ApplicationInfo/ApplicationInfoC'
import DisbursementsC from 'components/Customer/Pages/Disbursements/DisbursementsC'
import RecipientsC from 'components/Customer/Pages/Recipients/RecipientsC'
import ApplicationFeeProfileTabs from 'components/Customer/Pages/Application/ApplicationFeeProfile/ApplicationFeeProfileTabs'
import ApplicationPayoutsC from 'components/Customer/Pages/Application/ApplicationPayouts/ApplicationPayoutsC'
import ApplicationAccountBalanceC from 'components/Customer/Pages/Application/ApplicationAccountBalance/ApplicationAccountBalanceC'
import ApplicationPaymentInstrumentsC from 'components/Customer/Pages/Application/ApplicationPaymentInstruments/ApplicationPaymentInstrumentsC'
import ApplicationPaymentDevicesC from 'components/Customer/Pages/Application/ApplicationPaymentDevices/ApplicationPaymentDevicesC'
import ApplicationPaymentLinksC from 'components/Customer/Pages/Application/ApplicationPaymentLinks/ApplicationPaymentLinksC'
import ApplicationPayoutConfigurationC from 'components/Customer/Pages/Application/ApplicationPayoutConfiguration/ApplicationPayoutConfigurationC'
import ApplicationTransactionsTabs from 'components/Shared/Tabs/ApplicationTransactionsTabs'
import ApplicationMerchantsTabs from 'components/Shared/Tabs/ApplicationMerchantsTabs'
import ApplicationVelocityInfoTabs from 'components/Shared/Tabs/ApplicationVelocityInfoTabs'
import ApplicationDeveloperTabs from 'components/Shared/Tabs/ApplicationDeveloperTabs'
import BrandingTabs from 'components/Shared/Tabs/BrandingTabs'
import ApplicationComplianceTabs from 'components/Customer/Pages/Application/ApplicationCompliance/ApplicationComplianceTabs'
import ApplicationDataResourcesTabs from 'components/Shared/Tabs/ApplicationDataResourcesTabs'
import TeamTabs from 'components/Shared/Tabs/TeamTabs'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getDashboardCustomizationRequest from 'utilities/actions/get/getDashboardCustomizationRequest'
import getApplicationBalancesRequest from 'utilities/actions/get/getApplicationBalancesRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import isAdminOrClientAdmin from 'utilities/is/isAdminOrClientAdmin'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { USD } from 'constants/currencyConstants'
import { SHOW_DEVICE_MANAGEMENT } from 'constants/featureFlagConstants'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

import {
  DASHBOARD_TYPE_TO_DISPLAY_LABEL_MAP,
  APPLICATION as APPLICATION_ENTITY_FIELD,
  STANDALONE_MERCHANT,
  DISBURSEMENT,
  DISBURSEMENTS_AND_PAYMENTS,
} from 'constants/dashboardConfigurationsConstants'

import {
  VIEW_FEE_PROFILE_PERMISSION,
  VIEW_TRANSACTIONS_LIST_PERMISSION,
} from 'constants/permissionConstants'

import {
  getApplicationSelector,
  getBalancesByApplicationId,
  getDashboardConfigByEntityTypeAndId,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

import {
  APPLICATION_ASSOCIATED_IDENTITIES,
  APPLICATION_IDENTITIES,
  PATCH_APPLICATION_F_REQUEST,
} from 'constants/flowConstants'

import {
  IDENTITY_PATH,
  APPLICATIONS_PATH,
  NEW_IDENTITY_PATH,
  EDIT_IDENTITY_PATH,
  NEW_APPLICATION_IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION,
  BUSINESS_INFO,
  PAYOUTS,
  MERCHANTS,
  BRANDING,
  APPLICATIONS,
  CREATED_ON,
  RESEND_EMAIL,
  COMPLIANCE,
  DEVELOPER,
  UPDATE_APPLICATION_SETTINGS,
  ADD_PROCESSOR,
  EDIT_BUSINESS_INFO,
  CREATE_IDENTITY,
  TRANSACTIONS,
  CREATE_ASSOCIATED_IDENTITY,
  TEAM,
  CUSTOMER_PROFILE_TYPE,
  FINIX_FEES,
  FEE_PROFILE,
  SWITCH_CUSTOMER_PROFILE_TYPE,
  SET_CUSTOMER_PROFILE_TYPE,
  DOING_BUSINESS_AS,
  VELOCITY_INFO,
  UPDATE_ACCOUNT_BALANCE,
  ACCOUNT_BALANCE,
  OWNER_IDENTITY,
  PAYOUT_CONFIGURATIONS,
  PAYMENT_INSTRUMENTS,
  RECIPIENTS,
  PAYMENT_DEVICES,
  PAYMENT_LINKS,
  DATA_RESOURCES,
} from 'constants/language/languageConstants'

import {
  ADD_APPLICATION_PROCESSORS,
  EDIT_APPLICATION_SETTING,
  INVITE_USER,
  SEARCH_USER,
  SWITCH_DASHBOARD_TYPE_MODAL,
  UPDATE_ACCOUNT_BALANCE_MODAL,
} from 'constants/modalConstants'

import {
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  BANK_ICON,
  ENVELOPE_LIGHT,
} from 'constants/iconConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    getApplicationDashboardConfig: ({ credentials, entityId, entityType }) => dispatch(getDashboardCustomizationRequest({ credentials, entityId, entityType })),
    getApplicationBalances: ({ credentials, applicationId }) => dispatch(getApplicationBalancesRequest({ credentials, queries: { linked_to: applicationId } })),
    showInviteUserModal: (modalProps) => dispatch(showModalAction({ modalType: INVITE_USER, modalProps })),
    showSearchUserModal: (modalProps) => dispatch(showModalAction({ modalType: SEARCH_USER, modalProps })),
    showApplicationSettingModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_APPLICATION_SETTING, modalProps })),
    showProcessorsModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_APPLICATION_PROCESSORS, modalProps })),
    showSwitchDashboardTypeModal: (modalProps) => dispatch(showModalAction({ modalType: SWITCH_DASHBOARD_TYPE_MODAL, modalProps })),
    showUpdateAccountBalanceModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_ACCOUNT_BALANCE_MODAL, modalProps })),
    getDisbursementRules: ({ entityId, credentials }) => dispatch(getDisbursementRulesRequest({ entityId, credentials })),
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `applicationsR.${FETCHING}`)
  const isFetchingDisbursementRules = get(state, `disbursementRulesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'params.applicationId')
  const application = getApplicationSelector(state, applicationId)
  const isPayout = isPayoutFeature({ credentials })
  const entityType = APPLICATION_ENTITY_FIELD
  const applicationDashboardConfig = getDashboardConfigByEntityTypeAndId(state, entityType, applicationId)
  const applicationDashboardType = get(applicationDashboardConfig, 'dashboardType')
  const noApplicationDashboardTypeSet = isEmpty(applicationDashboardType)
  const dashboardConfigurationId = get(applicationDashboardConfig, 'id')
  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const isAdminRole = isAdministratorRole(credentials)
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()
  const isFlex = isFlexPlatform()
  const applicationBalances = getBalancesByApplicationId(state, applicationId)
  // TODO: Cata - we're currently fetching for USD, but we will need to change this to be dynamic and support Canada
  const applicationBalanceUSD = find([applicationBalances], { currency: USD })
  const isDisbursementsApplication = applicationDashboardType === DISBURSEMENT
  const isDisbursementsAndPaymentsApplication = applicationDashboardType === DISBURSEMENTS_AND_PAYMENTS
  const disbursementRules = getDisbursementRulesSelector(state)

  const [
    balanceId,
    balanceCurrency,
    availableAmount,
    displayAvailableAmount,
  ] = getMany(applicationBalanceUSD, [
    'id',
    'currency',
    'availableAmount',
    'displayAvailableAmount',
  ])

  const [
    applicationName,
    displayCreatedAt = '',
    displayBusinessName,
    identityId,
    displayDoingBusinessAs,
  ] = getMany(application, [
    'businessName',
    'displayCreatedAt',
    'ownerIdentity.displayBusinessName',
    'identityId',
    'ownerIdentity.doingBusinessAs',
  ])

  const [
    applicationACHRules,
    applicationCardRules,
    recipientACHRules,
    recipientCardRules,
  ] = getMany(disbursementRules, [
    'applicationACHRules',
    'applicationCardRules',
    'recipientACHRules',
    'recipientCardRules',
  ])

  const noDisbursementRulesSet = isEmpty(applicationACHRules) && isEmpty(applicationCardRules) && isEmpty(recipientACHRules) && isEmpty(recipientCardRules)
  const feeTabName = applicationDashboardType === STANDALONE_MERCHANT ? FINIX_FEES : FEE_PROFILE

  const tabs = [
    {
      id: 'business-info',
      name: BUSINESS_INFO,
      component: ApplicationInfoC,
      props: {
        isPayout,
        isDisbursementsApplication,
      },
    },
    {
      id: 'payouts',
      name: PAYOUTS,
      component: DisbursementsC,
      condition: !isStandaloneUnderwriting && (isDisbursementsApplication || isDisbursementsAndPaymentsApplication),
      props: {
        isDisbursementsAndPaymentsApplication,
        applicationId,
        queryValue: applicationId,
        queryKey: 'application_id',
        hiddenFilters: ['application_id'],
        hideHeader: true,
      },
    },
    {
      id: 'transactions',
      name: TRANSACTIONS,
      component: !isPayout ? ApplicationTransactionsTabs : ApplicationPayoutsC,
      condition: !isStandaloneUnderwriting && !isDisbursementsApplication,
      props: {
        applicationId,
        queryValue: applicationId,
        queryKey: 'application_id',
        hiddenFilters: ['application_id'],
        hideHeader: true,
      },
    },
    {
      id: 'merchants',
      name: MERCHANTS,
      component: ApplicationMerchantsTabs,
      condition: !isPayout && !isDisbursementsApplication,
      props: {
        applicationId,
        isStandaloneUnderwriting,
        isDisbursementsApplication,
      },
    },
    {
      id: 'application-payment-devices',
      name: PAYMENT_DEVICES,
      component: ApplicationPaymentDevicesC,
      condition: getFeatureFlag(SHOW_DEVICE_MANAGEMENT),
      props: {
        applicationId,
        applicationName,
        queries: { application_id: applicationId },
      },
    },
    {
      id: 'payment-links',
      name: PAYMENT_LINKS,
      component: ApplicationPaymentLinksC,
      condition: isFlex && hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]) && (isAdministratorRole() || isPaymentOperationsManagerRole()),
      props: {
        applicationId,
      },
    },
    {
      id: 'data-resources',
      name: DATA_RESOURCES,
      component: ApplicationDataResourcesTabs,
    },
    {
      id: 'payout-configurations',
      name: PAYOUT_CONFIGURATIONS,
      component: ApplicationPayoutConfigurationC,
      condition: (isDisbursementsApplication || isDisbursementsAndPaymentsApplication) && isRolePlatform({ credentials }) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
    },
    {
      id: 'application-payment-instruments',
      name: PAYMENT_INSTRUMENTS,
      component: ApplicationPaymentInstrumentsC,
      condition: isDisbursementsApplication,
    },
    {
      id: 'account-balance',
      name: ACCOUNT_BALANCE,
      component: ApplicationAccountBalanceC,
      condition: isFlex && isRolePlatform({ credentials }) && (isDisbursementsApplication || isDisbursementsAndPaymentsApplication),
      props: {
        applicationId,
        applicationName,
        balanceId,
        balanceCurrency,
        availableAmount,
        displayAvailableAmount,
      },
    },
    {
      id: 'application-recipients',
      name: RECIPIENTS,
      component: RecipientsC,
      condition: isDisbursementsApplication,
      props: {
        applicationId,
        hideHeader: true,
      },
    },
    {
      id: 'fee-profile',
      name: feeTabName,
      component: ApplicationFeeProfileTabs,
      condition: !isPayout && !isStandaloneUnderwriting && !isDisbursementsApplication && hasPermissions([VIEW_FEE_PROFILE_PERMISSION]),
      props: {
        applicationId,
        isFlex,
      },
    },
    {
      id: 'compliance',
      name: COMPLIANCE,
      component: ApplicationComplianceTabs,
      condition: () => { return isRolePlatform({ credentials }) && isFlex && !isDisbursementsApplication },
      props: {
        applicationId,
      },
    },
    {
      id: 'velocity-info',
      name: VELOCITY_INFO,
      component: ApplicationVelocityInfoTabs,
      condition: isFlex && isRolePlatform({ credentials }) && (isDisbursementsApplication || isDisbursementsAndPaymentsApplication),
      props: {
        applicationId,
        noDisbursementRulesSet,
        isFetchingDisbursementRules,
      },
    },
    {
      id: 'developer',
      name: DEVELOPER,
      component: ApplicationDeveloperTabs,
      props: {
        applicationId,
        identityId,
      },
    },
    {
      id: 'branding',
      name: BRANDING,
      component: BrandingTabs,
      condition: () => { return isRolePlatform({ credentials }) && isFlex },
      props: {
        entityId: applicationId,
        entityType: APPLICATION_ENTITY_FIELD,
      },
    },
    {
      id: 'team',
      name: TEAM,
      component: TeamTabs,
      props: {
        entityId: applicationId,
        entityType: APPLICATION_ENTITY_FIELD,
      },
    },
  ]

  const contextBarData = {
    back: {
      label: APPLICATIONS,
      path: APPLICATIONS_PATH({ credentialId }),
    },
    items: [
      {
        label: OWNER_IDENTITY,
        value: displayBusinessName,
        path: IDENTITY_PATH({ credentialId, identityId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: APPLICATION,
      id: applicationId,
    },
    items: [
      {
        value: <h1>{applicationName}</h1>,
      },
      {
        label: DOING_BUSINESS_AS,
        value: displayDoingBusinessAs,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: CUSTOMER_PROFILE_TYPE,
        value: DASHBOARD_TYPE_TO_DISPLAY_LABEL_MAP[applicationDashboardType],
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    isFetchingDisbursementRules,
    isProductionEnv,
    credentials,
    credentialId,
    identityId,
    applicationId,
    balanceId,
    application,
    tabs,
    headerData,
    contextBarData,
    entityType,
    applicationDashboardType,
    dashboardConfigurationId,
    displayBusinessName,
    isAdminRole,
    noApplicationDashboardTypeSet,
    isStandaloneUnderwriting,
    isDisbursementsApplication,
  }
}

class ApplicationC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplication,
      entityType,
      getApplicationDashboardConfig,
      getApplicationBalances,
      getDisbursementRules,
    } = this.props

    getApplication({ credentials, applicationId })
    getApplicationDashboardConfig({ credentials, entityId: applicationId, entityType })
    getApplicationBalances({ credentials, applicationId })
    getDisbursementRules({ credentials, entityId: applicationId })
  }

  componentDidUpdate(prevProps) {
    const { applicationId: prevApplicationId } = prevProps

    const {
      credentials,
      applicationId,
      entityType,
      getApplicationDashboardConfig,
      getApplicationBalances,
      getDisbursementRules,
    } = this.props

    if (applicationId && (applicationId !== prevApplicationId)) {
      getApplicationDashboardConfig({ credentials, entityId: applicationId, entityType })
      getApplicationBalances({ credentials, applicationId })
      getDisbursementRules({ credentials, entityId: applicationId })
    }
  }

  render() {
    const {
      identityId,
      credentials,
      credentialId,
      application,
      applicationId,
      applicationName,
      balanceId,
      isProductionEnv,
      isAdminRole,
      dashboardConfigurationId,
      displayBusinessName,
      showInviteUserModal,
      showSearchUserModal,
      showProcessorsModal,
      showApplicationSettingModal,
      showUpdateAccountBalanceModal,
      applicationDashboardType,
      showSwitchDashboardTypeModal,
      entityType,
      noApplicationDashboardTypeSet,
      isStandaloneUnderwriting,
      isDisbursementsApplication,
    } = this.props

    const isFlex = isFlexPlatform()

    const actions = [
      // deprecated in favor of User Management Feature
      // {
      //   label: INVITE_USER_LABEL,
      //   buttonClassName: 'invite-user-button',
      //   action: () => {
      //     showInviteUserModal({
      //       applicationId,
      //     })
      //   },
      //   condition: isAdminOrClientAdmin(),
      //   icon: USER_LIGHT,
      // },
      {
        label: RESEND_EMAIL,
        buttonClassName: 'resend-email-button',
        action: () => showSearchUserModal({ applicationId }),
        condition: isAdminOrClientAdmin() && !isStandaloneUnderwriting,
        icon: ENVELOPE_LIGHT,
      },
      {
        label: UPDATE_APPLICATION_SETTINGS,
        buttonClassName: 'edit-application-settings-button',
        condition: !isStandaloneUnderwriting,
        action: () => {
          showApplicationSettingModal({
            record: application,
            actionType: PATCH_APPLICATION_F_REQUEST,
            credentials,
          })
        },
      },
      {
        label: UPDATE_ACCOUNT_BALANCE,
        buttonClassName: 'update-account-balance-button',
        action: () => showUpdateAccountBalanceModal({ applicationId, applicationName, balanceId, credentials }),
        condition: isFlex && isRolePlatform({ credentials }) && applicationDashboardType === DISBURSEMENT,
      },
      {
        label: ADD_PROCESSOR,
        icon: `fa fa-${BANK_ICON}`,
        action: () => {
          showProcessorsModal({
            applicationId,
          })
        },
        className: 'add-processor-button',
      },
      {
        label: EDIT_BUSINESS_INFO,
        link: EDIT_IDENTITY_PATH({ credentialId, identityId }),
        condition: !!identityId,
      },
      {
        label: CREATE_IDENTITY,
        action: () => goToPath({
          pathname: NEW_IDENTITY_PATH({ credentialId, applicationId }),
          queries: {
            recordId: applicationId,
            type: APPLICATION_IDENTITIES,
          },
        }),
        className: 'create-new-identity-button',
        condition: !isFlex,
      },
      {
        label: CREATE_IDENTITY,
        action: () => goToPath({
          pathname: NEW_APPLICATION_IDENTITY_PATH({ credentialId, applicationId }),
        }),
        className: 'create-new-identity-button',
        condition: isFlex && !isDisbursementsApplication,
      },
      {
        label: CREATE_ASSOCIATED_IDENTITY,
        action: () => goToPath({
          pathname: NEW_IDENTITY_PATH({ credentialId }),
          queries: {
            recordId: identityId,
            type: APPLICATION_ASSOCIATED_IDENTITIES,
          },
        }),
        className: 'create-associated-identity-button',
      },
      {
        label: noApplicationDashboardTypeSet ? SET_CUSTOMER_PROFILE_TYPE : SWITCH_CUSTOMER_PROFILE_TYPE,
        buttonClassName: 'switch-application-dashboard-type',
        action: () => showSwitchDashboardTypeModal({ credentials, dashboardConfigurationId, displayBusinessName, entityType, entityId: applicationId, applicationDashboardType }),
        condition: (isFlex && !isProductionEnv && !noApplicationDashboardTypeSet && !isStandaloneUnderwriting) || isAdminRole,
      },
    ]
    return (
      <Application
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationC)
