import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantInfo from './MerchantInfo'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { isFlexPlatform } from 'constants/flexConstants'
import { FAILED } from 'constants/statusConstants'
import { FETCHING } from 'constants/reducerConstants'
import { LITLE_V1 } from 'constants/processorConstants'
import { settlementFundingIdentifierOptionsMapping } from 'constants/platformConstants'
import { PROCESSOR_DETAILS_MODAL } from 'constants/modalConstants'
import orderBy from 'lodash/orderBy'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  getMerchantSelector,
  getMerchantVerificationsSelector,
} from 'state-layer/selectors'

import {
  ENABLED,
  DISABLED,
  CARD_CVV_REQUIRED_TOOLTIP,
  PROCESSING_ENABLED_TOOLTIP,
  SETTLEMENT_ENABLED_TOOLTIP,
  DEPOSIT_STATEMENT_DESCRIPTOR,
  RENT_SURCHARGES_ENABLED_TOOLTIP,
  CONVENIENCE_CHARGES_ENABLED_TOOLTIP,
  LEVEL_2_LEVEL_3_DATA_ENABLED_TOOLTIP,
  CARD_EXPIRATION_DATE_REQUIRED_TOOLTIP,
  VERIFICATION_STATE,
  PAYMENT_PROCESSING,
  SETTLEMENTS_RESOURCE_TITLE,
  CARD_EXPIRATION_DATE,
  CARD_CVV,
  CONVENIENCE_CHARGES,
  RENT_SURCHARGES,
  LEVEL_2_LEVEL_3_DATA,
  PROCESSOR,
  ACCEPTED_PAYMENT_METHODS,
  PROCESSOR_MID,
  MERCHANT_CATEGORY_CODE,
  VISA_MERCHANT_VERIFICATION_VALUE,
  MASTERCARD_ASSIGNED_ID,
  SURCHARGES,
} from 'constants/language/languageConstants'

import {
  NON_LITLE_ACCEPTED_PAYMENT_METHODS,
  LITLE_ACCEPTED_PAYMENT_METHODS,
  PAYMENT_METHOD_LABEL_TO_ICON_MAP,
} from 'constants/paymentMethodConstants'

// TODO: move to utility?
const enabledDisabledValue = (value) => (value ? ENABLED : DISABLED)

const mapStateToProps = (state, props) => {
  const isFlex = isFlexPlatform()
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `merchantsR.${FETCHING}`)
  const isFetchingVerifications = get(state, `merchantVerificationsR.${FETCHING}`)
  const merchantId = get(props, 'merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantVerifications = getMerchantVerificationsSelector(state, merchantId)
  const latestVerification = head(orderBy(merchantVerifications, 'createdAt', 'desc')) || {}
  const latestVerificationState = get(latestVerification, 'state')
  const latestVerificationFailed = latestVerificationState === FAILED
  const hasOutcomeCode = get(latestVerification, 'result[0].outcome_code', false)
  const showRejectInformationSection = isFlex && latestVerificationFailed && hasOutcomeCode
  const showProcessorDetailsSection = isRolePlatform({ credentials })
  const currentUserEmail = get(getCurrentUser(), 'userEmail')
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)

  const [
    processor,
    processingEnabled,
    settlementEnabled,
    processorDetails,
    settlementFundingIdentifier,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    rentSurchargesEnabled,
    surchargesEnabled,
    mid,
    mcc,
    visaMerchantVerificationValue,
    mastercardAssignedId,
  ] = getMany(merchant, [
    'processor',
    'processingEnabled',
    'settlementEnabled',
    'processorDetails',
    'settlementFundingIdentifier',
    'cardCvvRequired',
    'convenienceChargesEnabled',
    'cardExpirationDateRequired',
    'levelTwoLevelThreeDataEnabled',
    'rentSurchargesEnabled',
    'surchargesEnabled',
    'mid',
    'mcc',
    'visaMerchantVerificationValue',
    'mastercardAssignedId',
  ])

  const processorInfoSectionData = convertPageSectionDataToV2([
    {
      label: VERIFICATION_STATE,
      value: <TransferStatus value={latestVerificationState} />,
    },
    {
      label: PAYMENT_PROCESSING,
      value: enabledDisabledValue(processingEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: PROCESSING_ENABLED_TOOLTIP,
    },
    {
      label: SETTLEMENTS_RESOURCE_TITLE,
      value: enabledDisabledValue(settlementEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: SETTLEMENT_ENABLED_TOOLTIP,
    },
    {
      label: LEVEL_2_LEVEL_3_DATA,
      value: enabledDisabledValue(levelTwoLevelThreeDataEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: LEVEL_2_LEVEL_3_DATA_ENABLED_TOOLTIP,
    },
    {
      label: CARD_CVV,
      value: enabledDisabledValue(cardCvvRequired),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: CARD_CVV_REQUIRED_TOOLTIP,
    },
    {
      label: CARD_EXPIRATION_DATE,
      value: enabledDisabledValue(cardExpirationDateRequired),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: CARD_EXPIRATION_DATE_REQUIRED_TOOLTIP,
    },
    {
      label: CONVENIENCE_CHARGES,
      value: enabledDisabledValue(convenienceChargesEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: CONVENIENCE_CHARGES_ENABLED_TOOLTIP,
    },
    {
      label: RENT_SURCHARGES,
      value: enabledDisabledValue(rentSurchargesEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
      tooltip: RENT_SURCHARGES_ENABLED_TOOLTIP,
    },
    {
      label: SURCHARGES,
      value: enabledDisabledValue(surchargesEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: DEPOSIT_STATEMENT_DESCRIPTOR,
      value: settlementFundingIdentifierOptionsMapping[settlementFundingIdentifier],
    },
  ])

  const processorDetailsSectionData = convertPageSectionDataToV2([
    {
      label: PROCESSOR,
      value: snakeCaseToTitleCase({ key: processor }),
    },
    {
      label: MERCHANT_CATEGORY_CODE,
      value: mcc,
      condition: !!mcc,
    },
    {
      label: ACCEPTED_PAYMENT_METHODS,
      value: processor,
      formatter: ({ value }) => {
        const acceptedPaymentMethods = value === LITLE_V1 ? LITLE_ACCEPTED_PAYMENT_METHODS : NON_LITLE_ACCEPTED_PAYMENT_METHODS

        return map(acceptedPaymentMethods, (method) => (
          <div className='accepted-payment-method-container'>
            <i className={PAYMENT_METHOD_LABEL_TO_ICON_MAP[method]} />
            <div>{method}</div>
          </div>
        ))
      },
    },
    {
      label: PROCESSOR_MID,
      value: mid,
    },
    {
      label: VISA_MERCHANT_VERIFICATION_VALUE,
      value: visaMerchantVerificationValue,
      condition: !!visaMerchantVerificationValue,
    },
    {
      label: MASTERCARD_ASSIGNED_ID,
      value: mastercardAssignedId,
      condition: !!mastercardAssignedId,
    },
  ])

  const isStandaloneUnderwritingDetailsSection = [
    {
      type: 'data',
      data: [
        {
          label: PROCESSOR,
          value: snakeCaseToTitleCase({ key: processor }),
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: PROCESSOR_MID,
          value: mid,
        },
      ],
    },
    {
      type: 'data',
      data: [],
    },
  ]

  return {
    isFetching,
    merchantId,
    credentials,
    processorInfoSectionData,
    processorDetails,
    processorDetailsSectionData,
    showRejectInformationSection,
    showProcessorDetailsSection,
    isFetchingVerifications,
    verification: latestVerification,
    currentUserEmail,
    isStandaloneUnderwriting,
    isStandaloneUnderwritingDetailsSection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showProcessorDetailsModal: (modalProps) => dispatch(showModalAction({ modalType: PROCESSOR_DETAILS_MODAL, modalProps })),
  }
}

class MerchantInfoC extends Component {
  render() {
    const {
      showProcessorDetailsModal,
    } = this.props

    return (
      <MerchantInfo
        {...this.props}
        showProcessorDetailsModal={showProcessorDetailsModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantInfoC)
