import genericReducerConfigs from 'state-layer/reducers/genericReducers.config'
import genericReducer from 'utilities/reducers/genericReducer'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const generateGenericReducers = reduce(genericReducerConfigs, (total, reducerData) => {
  const {
    name,
    flows,
    request,
    success,
    failure,
    debug,
  } = reducerData

  return merge({}, total, {
    [name]: (state = {}, action = {}) => genericReducer({
      flows,
      request,
      success,
      failure,
      debug,
      name,
    })(state, action),
  })
}, {})

export default generateGenericReducers
