import { Field } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import formatAmountField from 'utilities/formatters/fields/formatAmountField'
import get from 'lodash/get'

import {
  USD,
  CURRENCY_NAME_TO_SYMBOL_MAP,
} from 'constants/currencyConstants'

const AmountField = ({
  name = 'amount',
  label = '',
  placeholder = '0.00',
  component = InputField,
  format = formatAmountField,
  normalize = (val) => val,
  currency = USD,
  disabled = false,
  tooltip,
  tooltipPosition,
  helperText,
  className,
  required,
  contextLabel,
}) => {
  // TODO: use a map to Font Awesome icons instead ?
  const currencySymbol = get(CURRENCY_NAME_TO_SYMBOL_MAP, currency, '$')

  return (
    <Field
      name={name}
      label={label}
      placeholder={placeholder}
      component={component}
      format={format}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      className={className}
      leadingIconString={currencySymbol}
      required={required}
      helperText={helperText}
      normalize={normalize}
      contextLabel={contextLabel}
      inputMode='decimal'
    />
  )
}

AmountField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  component: PropTypes.func,
  format: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  contextLabel: PropTypes.string,
  helperText: PropTypes.string,
  normalize: PropTypes.func,
}

export default AmountField
