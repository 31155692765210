import React from 'react'
import AmountsDisplaySection from 'components/Customer/Shared/Page/PageSection/AmountsDisplaySection'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import get from 'lodash/get'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import { STARTED_AT_LABEL, ENDED_AT_LABEL } from 'constants/language/languageConstants'

const columnDescriptors = [
  column('Merchant', 'identity.displayBusinessName', { className: 'small' }),
  column('Enrollment Nickname', 'enrollmentNickname', { className: 'medium' }),
  column('Schedule Nickname', 'schedule.scheduleNickname', { className: 'medium' }),
  column('Schedule Type', 'schedule.subscriptionType', {
    className: 'x-small',
    formatter: ({ schedule }) => subscriptionScheduleTypeMapping[get(schedule, 'subscriptionType')],
  }),
  column('Schedule', '', {
    className: 'medium',
    formatter: ({ schedule }) => {
      const subscriptionType = get(schedule, 'subscriptionType')
      const periodOffset = get(schedule, 'periodOffset')
      const fixedTimeIntervalOffset = get(schedule, 'fixedTimeIntervalOffset')

      return convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset })
    },
  }),
  column('Schedule Amounts', 'schedule.amounts', {
    className: 'medium',
    formatter: ({ schedule }) => AmountsDisplaySection({ data: get(schedule, 'amounts') }),
  }),
  column(STARTED_AT_LABEL, 'displayStartedAt', { className: 'date', sort: createdAtRange }),
  column(ENDED_AT_LABEL, 'displayEndedAt', { className: 'date', sort: createdAtRange }),
]

export default columnDescriptors
