import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import selectOption from 'utilities/forms/selectOption'
import { ALL } from 'constants/transferConstants'
import { ENABLE_PROCESSORS_FORM } from 'constants/formConstants'
import { isFlexPlatformLive } from 'constants/flexConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import without from 'lodash/without'
import capitalize from 'lodash/capitalize'

import {
  NOT_PROVIDED,
  LEVEL_2_CREDIT_CARD_PROCESSING,
  LEVEL_3_CREDIT_CARD_PROCESSING,
} from 'constants/language/languageConstants'

export const DUMMY_V1 = 'DUMMY_V1'
export const LITLE_V1 = 'LITLE_V1'
export const VISA_V1 = 'VISA_V1'
export const VANTIV_V1 = 'VANTIV_V1'
export const MICROBILT_V1 = 'MICROBILT_V1'
export const ELAVON_V1 = 'ELAVON_V1'
export const GIACT_V1 = 'GIACT_V1'
export const UNDERWRITING_ONLY_V1 = 'UNDERWRITING_ONLY_V1'
export const FINIX_V1 = 'FINIX_V1'

// gateways
export const TRIPOS_MOBILE_V1 = 'TRIPOS_MOBILE_V1'
export const TRIPOS_CLOUD_V1 = 'TRIPOS_CLOUD_V1'
export const EXPRESS_V1 = 'EXPRESS_V1'
export const DATACAP_V1 = 'DATACAP_V1'

export const L2_ENABLED = 'L2_ENABLED'
export const L3_ENABLED = 'L3_ENABLED'

export const ADDITIONAL_PROCESSOR_DATA_OPTIONS = [
  { label: capitalize(NOT_PROVIDED), value: 'N/A' },
  { label: capitalize(LEVEL_2_CREDIT_CARD_PROCESSING), value: L2_ENABLED },
  { label: capitalize(LEVEL_3_CREDIT_CARD_PROCESSING), value: L3_ENABLED },
]

export const ALL_PROCESSORS = [
  DUMMY_V1,
  LITLE_V1,
  VISA_V1,
  VANTIV_V1,
  MICROBILT_V1,
  ELAVON_V1,
  UNDERWRITING_ONLY_V1,
  FINIX_V1,
]

export const PROCESSOR_ASSOCIATED_GATEWAYS_MAP = {
  [VANTIV_V1]: [VANTIV_V1, TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1, DATACAP_V1]
}

export const displayFlaggedGateways = ({ processor }) => {
  return (isFlexPlatformLive() && processor === VANTIV_V1) ? [TRIPOS_MOBILE_V1, DATACAP_V1] :  PROCESSOR_ASSOCIATED_GATEWAYS_MAP[processor]
}

export const UNDERWRITING_SUMMARY_PROCESSORS = [GIACT_V1]

export const PROCESSOR_LABEL_MAP = {
  [DUMMY_V1]: 'Dummy',
  [LITLE_V1]: 'Litle',
  [VISA_V1]: 'Visa',
  [VANTIV_V1]: 'Vantiv Core',
  [MICROBILT_V1]: 'Microbilt',
  [ELAVON_V1]: 'Elavon',
  [GIACT_V1]: 'Giact',
  [UNDERWRITING_ONLY_V1]: 'Underwriting Only',
  [FINIX_V1]: 'Finix',
}

export const GATEWAY_LABEL_MAP = {
  [TRIPOS_MOBILE_V1]: 'Tripos Mobile',
  [TRIPOS_CLOUD_V1]: 'Tripos Cloud',
  [DATACAP_V1]: 'Datacap',
  [EXPRESS_V1]: 'Express',
}

export const PROCESSOR_DESCRIPTIONS = {
  VANTIV_V1: 'Used to process via alternative gateways',
  TRIPOS_MOBILE_V1: 'Used to process In-Person Payments via Finix Mobile SDK',
  TRIPOS_CLOUD_V1: 'Used to process In-Person Payments via Tripos Cloud API',
  DATACAP_V1: 'Used to process In-Person Payments via Finix API',
  LITLE_V1: 'Used to process online transactions',
  MICROBILT_V1: 'Used to process credit reports on identities',
  DUMMY_V1: 'Used to process online test transactions',
  UNDERWRITING_ONLY_V1: 'Used to verify and underwrite merchants',
  FINIX_V1: 'Used to process Online and In-Person Payments on Finix\'s Processor',
}

export const VERIFICATION_PROCESSORS = [
  MICROBILT_V1,
]

export const getAvailableProcessors = () => {
  const platform = getCurrentPlatform()

  return get(platform, 'availableProcessors', [])
}

export const getAvailableProcessorInputsSection = ({ isStandaloneUnderwriting = false }) => {
  const availableProcessors = isStandaloneUnderwriting ? [DUMMY_V1, UNDERWRITING_ONLY_V1] : getAvailableProcessors()

  return [{
    title: 'Processors',
    inputs: map(availableProcessors, (processor) => {
      return {
        props: {
          name: processor,
          label: get(PROCESSOR_LABEL_MAP, processor),
          component: ToggleSwitchC,
          form: ENABLE_PROCESSORS_FORM,
        },
      }
    })
  }]
}

export const getAvailableProcessorsOptions = () => {
  const platform = getCurrentPlatform()
  const availableProcessors = without(get(platform, 'availableProcessors', []), DUMMY_V1)
  const availableProcessorOptions = map(availableProcessors, (processor) => selectOption(processor, processor))

  return [selectOption('All', ALL), ... availableProcessorOptions]
}

export const L2_ENABLED_FORM_FIELDS  = ['salesTaxAmount', 'customerReferenceNumber']

export const L3_ENABLED_FORM_FIELDS = [
  'customerReferenceNumber',
  'destinationPostalCode',
  'shipFromPostalCode',
  'shippingAmount',
  'totalDiscountAmount',
  'totalCustomsDutyAmount',
  'levelThreeItems',
]
