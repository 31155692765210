import './IdentityPrincipalsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import classnames from 'classnames'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldsetOneCol'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import Button from 'components/Shared/Button/Button'
import pluralizeWord from 'utilities/pluralizeWord'
import validateIdentityPrincipalsForm from 'utilities/validate/validateIdentityPrincipalsForm'
import { MAX_OWNERS } from 'constants/identityConstants'
import { IDENTITY_PRINCIPALS_FORM } from 'constants/formConstants'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import isPlatform from 'utilities/is/isPlatform'

import {
  EMAIL_ADDRESS,
  NEXT_PRINCIPAL,
  PREVIOUS_PRINCIPAL,
  REMOVE_PRINCIPAL,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

// we should consider moving these to their own files soon
const PrincipalInfo = ({
  principal,
  componentClassName,
}) => {
  const isElavon = isPlatform('elavonpartnerconnect')

  return (
    <div className={componentClassName}>
      <div className='flex space-between'>
        <div className='col'>
          <Field
            name={`${principal}.firstName`}
            label='First Name'
            placeholder='John'
            component={InputField}
          />

          { isElavon && (
            <Field
              name={`${principal}.middleName`}
              label='Middle Name'
              placeholder='Jacob'
              component={InputField}
            />
          )}

          <Field
            name={`${principal}.lastName`}
            label='Last Name'
            placeholder='Smith'
            component={InputField}
          />

          <Field
            name={`${principal}.title`}
            label='Title'
            placeholder='e.g CEO, Manager'
            component={InputField}
          />

          <DateFieldset
            name={`${principal}.dateOfBirth`}
            label='Date of Birth'
          />

          <PercentageField
            name={`${principal}.principalPercentageOwnership`}
            label='Principal Percentage Ownership'
            tooltip='Percentage of company owned by the principal.'
          />

          <Field
            name={`${principal}.phone`}
            label='Personal Phone'
            component={InputField}
            format={formatPhone}
            parse={parsePhone}
          />
        </div>

        <div className='col'>
          <AddressFieldset
            name={`${principal}.personalAddress`}
          />

          <Field
            name={`${principal}.taxId`}
            label={SOCIAL_SECURITY_NUMBER}
            placeholder='000-00-0000'
            component={InputField}
            autofill={false}
          />

          <Field
            name={`${principal}.email`}
            label={EMAIL_ADDRESS}
            component={InputField}
          />
        </div>
      </div>
    </div>
  )
}

const Principals = ({
  fields,
  existingPrincipal = 0,
  displayPrincipal,
  updateDisplayPrincipal,
  removePrincipal,
  singlePrincipal = false,
  addPrincipal = true,
  totalPrincipalPercentageOwnership = 0,
}) => {
  const principalCount = fields.length + existingPrincipal
  const disableAdded = principalCount >= MAX_OWNERS || totalPrincipalPercentageOwnership >= 100
  const disablePrev = displayPrincipal === 0
  const disableNext = displayPrincipal === fields.length - 1 || fields.length === 0
  const remainingCount = disableAdded ? 0 : MAX_OWNERS - principalCount
  const addMessage = `${remainingCount} more ${pluralizeWord({ count: remainingCount, word: 'principal' })} may be added`
  const progressPercent = `${totalPrincipalPercentageOwnership}%`

  const addFieldAction = () => {
    fields.push({ personalAddress: { country: 'USA' } })

    updateDisplayPrincipal(displayPrincipal + 1)
  }

  return (
    <div>
      { !singlePrincipal && (
        <div className='header'>
          <h5>Principal Info</h5>

          <p>Total Principals: {principalCount}</p>
          <p>Total Ownership: {totalPrincipalPercentageOwnership}%</p>

          <div className='ownership-bar'>
            <div className='ownership-progress' style={{ width: progressPercent }} />
          </div>

          { addPrincipal && (<Button onClick={() => addFieldAction(false)} disabled={disableAdded} className='add-principal' label='Add more Principals' variant='tertiary' leftIcon='plus' />)}
          { addPrincipal && (<div>{addMessage}</div>) }

          { principalCount > 1 && (
          <div className='flex space-between mts principal-actions'>
            <div className='left-buttons flex'>
              <Button onClick={() => updateDisplayPrincipal(displayPrincipal - 1)} disabled={disablePrev} label={PREVIOUS_PRINCIPAL} variant='tertiary' leftIcon='fast-backward' />
            </div>

            <div className='right-buttons'>
              <Button onClick={() => updateDisplayPrincipal(displayPrincipal + 1)} disabled={disableNext} label={NEXT_PRINCIPAL} variant='tertiary' leftIcon='fast-forward' />
            </div>
          </div>
          )}
        </div>
      )}

      <div className='flex space-between mts'>
        <h6 className='mts'>Principal {displayPrincipal + 1}</h6>

        { addPrincipal && (<Button onClick={() => removePrincipal(displayPrincipal)} disabled={displayPrincipal === 0} label={REMOVE_PRINCIPAL} variant='destructive' className='cancel-button' />)}
      </div>

      { fields.map((principal, index) => {
        const componentClassName = classnames(`principal principal_${index}`, {
          hide: index !== displayPrincipal,
        })

        return (
          <PrincipalInfo
            principal={principal}
            principalIndex={index}
            componentClassName={componentClassName}
            singlePrincipal={singlePrincipal}
          />
        )
      })}
    </div>
  )
}

const IdentityPrincipalsForm = ({
  addPrincipal = true,
  singlePrincipal = false,
  disabled = false,
  displayPrincipal = 0,
  handleSubmit = () => {},
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  submitting = false,
  submitLabel = 'Submit',
  updateDisplayPrincipal = () => {},
  invalid = false,
  removePrincipal = () => {},
  totalPrincipalPercentageOwnership = 0,
}) => {
  return (
    <form className='IdentityPrincipalsForm' onSubmit={handleSubmit}>
      <div className='section'>
        <FieldArray
          name='principals'
          displayPrincipal={displayPrincipal}
          updateDisplayPrincipal={updateDisplayPrincipal}
          removePrincipal={removePrincipal}
          component={Principals}
          singlePrincipal={singlePrincipal}
          addPrincipal={addPrincipal}
          totalPrincipalPercentageOwnership={totalPrincipalPercentageOwnership}
        />
      </div>

      { invalid && (<span className='error'>Please enter valid information for all principals</span>)}
      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
        </div>

        <div className='right flex flex-end'>
          <Button type='submit' submitting={submitting} disabled={disabled} label={submitLabel} variant='legacy-black' />
        </div>
      </div>
    </form>
  )
}

IdentityPrincipalsForm.propTypes = {
  addPrincipal: PropTypes.bool,
  singlePrincipal: PropTypes.bool,
  disabled: PropTypes.bool,
  displayPrincipal: PropTypes.number,
  handleSubmit: PropTypes.func,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  updateDisplayPrincipal: PropTypes.func,
  invalid: PropTypes.bool,
  removePrincipal: PropTypes.func,
  totalPrincipalPercentageOwnership: PropTypes.number,
}

export default reduxForm({
  form: IDENTITY_PRINCIPALS_FORM,
  validate: validateIdentityPrincipalsForm,
})(IdentityPrincipalsForm)
