import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateFindUserForm from './CreateFindUserForm'
import submitUserForm from 'utilities/submit/submitUserForm'
import get from 'lodash/get'


const mapStateToProps = (state, props) => {
  const onSubmit = get(props, 'onSubmit', submitUserForm)
  return {
    onSubmit,
  }
}

class CreateFindUserFormC extends Component {
  render() {
    return (
      <CreateFindUserForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateFindUserFormC)
