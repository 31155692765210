import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateCustomerOnboardingFormFlow from './CreateCustomerOnboardingFormFlow'
import CustomerOnboardingFormDealInfoFormC from 'components/Customer/Forms/CustomerOnboardingFormDealInfoForm/CustomerOnboardingFormDealInfoFormC'
import CustomerOnboardingFormCustomerInfoFormC from 'components/Customer/Forms/CustomerOnboardingFormCustomerInfoForm/CustomerOnboardingFormCustomerInfoFormC'
import CustomerOnboardingFormReviewFormC from 'components/Customer/Forms/CustomerOnboardingFormReview/CustomerOnboardingFormReviewFormC'
import get from 'lodash/get'

import {
  DEAL_INFO,
  CUSTOMER_INFO,
  REVIEW,
} from 'constants/language/languageConstants'

import {
  CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM,
  CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM,
} from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)

  const steps = [
    {
      name: DEAL_INFO,
      component: CustomerOnboardingFormDealInfoFormC,
      dataKey: 'dealInfo',
      formValues: () => reduxFormValues(CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM),
    },
    {
      name: CUSTOMER_INFO,
      component: CustomerOnboardingFormCustomerInfoFormC,
      dataKey: 'customerInfo',
      formValues: () => reduxFormValues(CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM),
    },
    {
      name: REVIEW,
      component: CustomerOnboardingFormReviewFormC,
    },
  ]

  return {
    steps,
  }
}

class CreateCustomerOnboardingFormFlowC extends Component {
  render() {
    const { steps } = this.props

    return (
      <CreateCustomerOnboardingFormFlow
        {...this.props}
        steps={steps}
      />
    )
  }
}

export default connect(mapStateToProps)(CreateCustomerOnboardingFormFlowC)
