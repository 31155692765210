import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoleDetails from './RoleDetails'
import { goToPath } from 'state-layer/history'
import { getRoleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { EDIT } from 'constants/language/languageConstants'
import { ADD_ROLE_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const roleId = get(props, 'roleId')
  const role = getRoleSelector(state, roleId)

  const {
    authRole,
    entityId,
    entityType,
  } = role

  const actions = [
    {
      label: EDIT,
      action: () => goToPath({
        pathname: ADD_ROLE_PATH({ credentialId }),
        queries: {
          roleId,
          entityId,
          entityType,
        },
      }),
    },
  ]

  return {
    authRole,
    credentials,
    actions,
  }
}

class RoleDetailsC extends Component {
  render() {
    return (
      <RoleDetails
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(RoleDetailsC)
