import { REDIRECT_F_REQUEST } from 'constants/flowConstants'
import { createAction } from 'redux-actions'

const redirectRequest = ({
  path,
}) => createAction(
  REDIRECT_F_REQUEST,
  () => ({ path }),
)()

export default redirectRequest
