import './CustomerOnboardingFormCustomerInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import MultiSelectField from 'components/Shared/Inputs/MultiSelectField/MultiSelectField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import validateCustomerOnboardingFormCustomerInfoForm from 'utilities/validate/validateCustomerOnboardingFormCustomerInfoForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import prevFlowStep from 'utilities/prevFlowStep'
import { selfServiceApplicationMccCodesSelectOptions } from 'constants/mccConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { APPLICATIONS_PATH } from 'constants/pathConstants'
import { CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'

import {
  annualACHVolumeLabelMap,
  achMaxTransactionAmountLabelMap,
} from 'constants/labelConstants'

import {
  CANCEL,
  CUSTOMER_INFORMATION,
  CUSTOMER_INFORMATION_SUBTITLE,
  BUSINESS_INFORMATION,
  BUSINESS_NAME,
  BUSINESS_NAME_LOWER_LABEL,
  DOING_BUSINESS_AS,
  MERCHANT_COUNT,
  MERCHANT_COUNT_TOOLTIP,
  WEBSITE,
  WEBSITE_LOWER_LABEL,
  SUBDOMAINS,
  SUBDOMAINS_LOWER_LABEL,
  EMAIL_ADDRESS,
  EMAIL_ADDRESS_TOOLTIP,
  PHONE_NUMBER,
  BUSINESS_PHONE_NUMBER_TOOLTIP,
  PROCESSING_INFORMATION,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_CATEGORY_CODE_TOOLTIP,
  ANNUAL_TRANSACTION_COUNT,
  ANNUAL_TRANSACTION_COUNT_LOWER_LABEL,
  ANNUAL_CARD_VOLUME_TOOLTIP,
  ANNUAL_ACH_VOLUME_TOOLTIP,
  MAX_CARD_TRANSACTION_AMOUNT_TOOLTIP,
  MAX_ACH_TRANSACTION_AMOUNT_TOOLTIP,
  CARD_VOLUME,
  ONLINE_PAYMENTS,
  CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP,
  IN_PERSON_PAYMENTS,
  CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP,
  CUSTOMER_ONBOARDING_TRANSACTION_DISTRIBUTION_SUBTITLE,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  BUSINESS_TO_BUSINESS,
  CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP,
  BUSINESS_TO_CONSUMER,
  CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP,
  OTHER,
  CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP,
  NEXT,
  PLEASE_RESOLVE_ALL_ERRORS_MESSAGE,
  PREVIOUS_STEP,
  BUSINESS_ADDRESS,
  BUSINESS_ADDRESS_LINE1_LOWER_LABEL,
  BUSINESS_DBA_LOWER_LABEL,
  ANNUAL_CARD_VOLUME,
  MAX_CARD_TRANSACTION_AMOUNT_LABEL,
} from 'constants/language/languageConstants'

const CustomerOnboardingFormCustomerInfoForm = ({
  handleSubmit = () => {},
  credentialId = '',
  isStandaloneMerchant = false,
  paymentVolumeByCustomerTypeLowerLabel,
  isFormInvalid = false,
  onboardingFormCountry = USA,
}) => {
  return (
    <form className='CustomerOnboardingFormCustomerInfoForm' onSubmit={handleSubmit}>
      <div className='form-header'>
        <h3>{CUSTOMER_INFORMATION}</h3>
        <div className='p-1'>{CUSTOMER_INFORMATION_SUBTITLE}</div>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_RESOLVE_ALL_ERRORS_MESSAGE} />}
        <div className='business-info-section'>
          <h5>{BUSINESS_INFORMATION}</h5>
          <Field
            name='businessName'
            label={BUSINESS_NAME}
            component={InputField}
            helperText={BUSINESS_NAME_LOWER_LABEL}
            autoComplete='organization'
            required={false}
          />

          <Field
            name='doingBusinessAs'
            label={DOING_BUSINESS_AS}
            helperText={BUSINESS_DBA_LOWER_LABEL}
            component={InputField}
            required={false}
          />

          {!isStandaloneMerchant && (
          <Field
            name='businessMerchantCount'
            label={MERCHANT_COUNT}
            component={InputField}
            tooltip={MERCHANT_COUNT_TOOLTIP}
            tooltipPosition='right'
            required={false}
          />
          )}

          <Field
            name='businessWebsite'
            label={WEBSITE}
            component={InputField}
            helperText={WEBSITE_LOWER_LABEL}
            autoComplete='url'
            required={false}
            placeholder='https://'
          />

          <Field
            name='businessWebsiteDomains'
            label={SUBDOMAINS}
            component={InputField}
            helperText={SUBDOMAINS_LOWER_LABEL}
            placeholder='app.company.com, store.company.com'
            required={false}
          />

          <div className='flex space-between'>
            <Field
              name='businessEmail'
              label={EMAIL_ADDRESS}
              component={InputField}
              placeholder='contact@company.com'
              tooltip={EMAIL_ADDRESS_TOOLTIP}
              tooltipPosition='right'
              autoComplete='email'
              required={false}
            />

            <Field
              name='businessPhone'
              label={PHONE_NUMBER}
              component={InputField}
              autofill={false}
              placeholder='000-000-0000'
              format={formatPhone}
              parse={parsePhone}
              tooltip={BUSINESS_PHONE_NUMBER_TOOLTIP}
              tooltipPosition='right'
              autoComplete='tel-national'
              required={false}
            />
          </div>

          <AddressC
            name='businessAddress'
            label={BUSINESS_ADDRESS}
            helperText={{ addressLine1: BUSINESS_ADDRESS_LINE1_LOWER_LABEL }}
            form={CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM}
            presetCountry={onboardingFormCountry}
            required={false}
          />
        </div>

        <div className='processing-info-section'>
          <h5>{PROCESSING_INFORMATION}</h5>
          <MultiSelectField
            name='mccCode'
            label={MERCHANT_CATEGORY_CODE}
            options={selfServiceApplicationMccCodesSelectOptions}
            hideSelectedOptions={false}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? option?.label : option?.value
            }}
            tooltip={MERCHANT_CATEGORY_CODE_TOOLTIP}
            tooltipPosition='right'
            required={false}
          />

          <Field
            name='annualTransactionCount'
            label={ANNUAL_TRANSACTION_COUNT}
            component={InputField}
            helperText={ANNUAL_TRANSACTION_COUNT_LOWER_LABEL}
            required={false}
          />

          <div className='flex space-between'>
            <AmountField
              name='annualCardVolume'
              label={ANNUAL_CARD_VOLUME}
              tooltip={ANNUAL_CARD_VOLUME_TOOLTIP}
              tooltipPosition='right'
              required={false}
              placeholder=' '
              contextLabel={COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry]}
            />

            <AmountField
              name='annualACHVolume'
              label={annualACHVolumeLabelMap(onboardingFormCountry)}
              tooltip={ANNUAL_ACH_VOLUME_TOOLTIP}
              tooltipPosition='right'
              required={false}
              placeholder=' '
              contextLabel={COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry]}
            />
          </div>

          <div className='flex space-between'>
            <AmountField
              name='maxCardTransactionAmount'
              label={MAX_CARD_TRANSACTION_AMOUNT_LABEL}
              tooltip={MAX_CARD_TRANSACTION_AMOUNT_TOOLTIP}
              tooltipPosition='right'
              required={false}
              placeholder=' '
              contextLabel={COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry]}
            />

            <AmountField
              name='maxACHTransactionAmount'
              label={achMaxTransactionAmountLabelMap(onboardingFormCountry)}
              tooltip={MAX_ACH_TRANSACTION_AMOUNT_TOOLTIP}
              tooltipPosition='right'
              required={false}
              placeholder=' '
              contextLabel={COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry]}
            />
          </div>
        </div>

        <div className='card-volume-section'>
          <h5>{CARD_VOLUME}</h5>
          <div className='sub-title'>{CUSTOMER_ONBOARDING_TRANSACTION_DISTRIBUTION_SUBTITLE}</div>
          <div className='flex space-between'>
            <PercentageField
              name='ecommercePercentage'
              label={ONLINE_PAYMENTS}
              tooltip={CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP}
              tooltipPosition='right'
              placeholder={' '}
              required={false}
            />

            <PercentageField
              name='cardPresentPercentage'
              label={IN_PERSON_PAYMENTS}
              tooltip={CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP}
              tooltipPosition='right'
              placeholder={' '}
              required={false}
            />
          </div>
        </div>

        <div className='payment-volume-section'>
          <h5>{PAYMENT_VOLUME_BY_CUSTOMER_TYPE}</h5>
          <div className='sub-title'>{paymentVolumeByCustomerTypeLowerLabel}</div>
          <div className='flex space-between'>
            <PercentageField
              name='businessToBusinessVolumePercentage'
              label={BUSINESS_TO_BUSINESS}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP}
              tooltipPosition='right'
              placeholder={' '}
              required={false}
            />

            <PercentageField
              name='businessToConsumerVolumePercentage'
              label={BUSINESS_TO_CONSUMER}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP}
              tooltipPosition='right'
              placeholder={' '}
              required={false}
            />

            <PercentageField
              name='otherVolumePercentage'
              label={OTHER}
              tooltip={CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP}
              tooltipPosition='right'
              placeholder={' '}
              required={false}
            />
          </div>
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => prevFlowStep()} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          <div className='exit-and-save-container'>
            <Button
              label={CANCEL}
              variant='ghost'
              size='large'
              onClick={() => goToPath({
                pathname: APPLICATIONS_PATH({ credentialId }),
                queries: { tab: 'onboarding-forms' },
              })}
            />
            <Button type='submit' label={NEXT} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

CustomerOnboardingFormCustomerInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  credentialId: PropTypes.string,
  isStandaloneMerchant: PropTypes.bool,
  paymentVolumeByCustomerTypeLowerLabel: PropTypes.string,
  isFormInvalid: PropTypes.bool,
  onboardingFormCountry: PropTypes.string,
}

export default reduxForm({
  form: CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM,
  validate: validateCustomerOnboardingFormCustomerInfoForm,
})(CustomerOnboardingFormCustomerInfoForm)
