import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import AssignUnderwritingEntityToMeC from 'components/Customer/Pages/UnderwritingV2/AssignUnderwritingEntityToMe/AssignUnderwritingEntityToMeC'
import { ASSIGN_TO_SELF } from 'constants/language/languageConstants'

const AssignUnderwritingEntityToMeModal = ({
  reviewId = '',
  subjectId = '',
}) => {
  return (
    <GenericModal
      title={ASSIGN_TO_SELF}
      className='AssignUnderwritingEntityToMeModal'
      Component={AssignUnderwritingEntityToMeC}
      reviewId={reviewId}
      subjectId={subjectId}
    />
  )
}

AssignUnderwritingEntityToMeModal.propTypes = {
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
}

export default AssignUnderwritingEntityToMeModal
