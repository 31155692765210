import './UploadEvidenceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import { FILE_ICON } from 'constants/iconConstants'
import { UPLOAD_EVIDENCE_FORM } from 'constants/formConstants'
import Button from 'components/Shared/Button/Button'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import submitUploadEvidenceForm from 'utilities/submit/submitUploadEvidenceForm'
import validateUploadEvidenceForm from 'utilities/validate/validateUploadEvidenceForm'
import isEmpty from 'lodash/isEmpty'

const successMessage = 'This is confirmation we have received your file. Your file will be sent to the processor.'
const defaultErrorMessage = 'An error occurred when trying to save the file.'

const primaryButtonClassname = 'button primary'
const secondaryButtonClassname = 'button secondary'

const UploadEvidenceForm = ({
  handleSubmit = () => {},
  submitting = false,
  invalid = false,
  id = '',
  type = '',
  config = {},
  instructions = [],
  updateUploadStatus,
  uploadSuccess,
  uploaded,
  supportUpload = true,
  uploadErrors = [],
  closeModal,
}) => {
  const submitLabel = !uploaded ? 'Upload' : 'Try Again'
  const closeLabel = uploadSuccess ? 'Close' : 'Cancel'
  const closeButtonClassname = uploadSuccess ? primaryButtonClassname : secondaryButtonClassname

  const errorMessage = !isEmpty(uploadErrors) ? uploadErrors.join(' ') : defaultErrorMessage
  const message = uploadSuccess ? successMessage : errorMessage
  const statusClassname = classnames('uploadStatus', {
    success: uploadSuccess,
    error: !uploadSuccess,
  })

  const iconClassname = classnames('fa', {
    'fa-check-circle': uploadSuccess,
    'fa-exclamation-circle': !uploadSuccess,
  })

  return (
    <form className='UploadEvidenceForm' onSubmit={handleSubmit(submitUploadEvidenceForm)}>
      <div className='section'>
        <div>
          <div className='label'>{type} ID</div>
          <div className='sub-label'>{id}</div>
        </div>

        <Field
          name='file'
          label='File'
          config={config}
          instructions={instructions}
          component={FileUploader}
          fileIcon={`fal fa-${FILE_ICON}`}
        />

        { uploaded && (
          <div className={statusClassname}>
            <span className={iconClassname} />
            <span className='message'>{message}</span>
          </div>
        )}
      </div>

      <div className='buttons flex flex-end'>
        { uploaded && (<Button onClick={closeModal} label={closeLabel} className={closeButtonClassname} variant='ghost' />) }
        { supportUpload && !uploadSuccess && (<Button type='submit' disabled={invalid} label={submitLabel} className={primaryButtonClassname} />) }
      </div>
    </form>
  )
}

UploadEvidenceForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  config: PropTypes.object,
  instructions: PropTypes.array,
  supportUpload: PropTypes.bool,
  uploadErrors: PropTypes.array,
}

export default reduxForm({
  form: UPLOAD_EVIDENCE_FORM,
  validate: validateUploadEvidenceForm,
})(UploadEvidenceForm)
