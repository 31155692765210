import './CreateComplianceFormTemplateFlowS.scss'
import React from 'react'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import FlowsC from 'components/Shared/Flows/FlowsC'
import PropTypes from 'prop-types'

const CreateComplianceFormTemplateFlow = ({
  steps,
  isFetching = false,
  onConfirmationPage = false,
}) => {
  return (
    <div className='CreateComplianceFormTemplateFlow'>
      <HeaderC
        breadcrumbs
      />

      { (onConfirmationPage || !isFetching) && (
        <FlowsC
          flowClassName='create-compliance-form-template-flow'
          layout='vertical'
          steps={steps}
        />
      )}
    </div>
  )
}

CreateComplianceFormTemplateFlow.propTypes = {
  steps: PropTypes.array,
  isFetching: PropTypes.bool,
  onConfirmationPage: PropTypes.bool,
}


export default CreateComplianceFormTemplateFlow
