import frontendBalanceModel from 'utilities/create/models/frontendBalanceModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendBalancesModel = ({ data: balances }) => {
  const balancesModel = map(balances, (balance) => frontendBalanceModel({ data: balance }))

  return keyBy(balancesModel, 'id')
}

export default frontendBalancesModel
