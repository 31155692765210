import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { INVALIDATE_COMPLIANCE_FORM } from 'constants/amplitudeConstants'
import { PATCH_COMPLIANCE_FORM_F_REQUEST } from 'constants/flowConstants'

const submitInvalidateComplianceForm = (values, dispatch, props) => {
  const { complianceFormId } = props

  const valuesToSubmit = {
    state: 'INVALID',
  }

  dispatch({
    type: PATCH_COMPLIANCE_FORM_F_REQUEST,
    payload: {
      id: complianceFormId,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(INVALIDATE_COMPLIANCE_FORM, {
        complianceFormId,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
      successMessage: 'Compliance form invalidated successfully',
      errorMessage: 'Failed to invalidate compliance form',
    },
  })
}

export default submitInvalidateComplianceForm
