import './AccessFormReviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import LiveAccountApplicationAdditionalDocumentsC from 'components/Customer/Pages/LiveAccountApplicationAdditionalDocuments/LiveAccountApplicationAdditionalDocumentsC'
import { INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE } from 'constants/identityConstants'
import size from 'lodash/size'

import {
  ADDITIONAL_DOCUMENTS,
  BANK_ACCOUNT_INFORMATION,
  BENEFICIAL_OWNERS_EMPTY_MESSAGE,
  BENEFICIAL_OWNERSHIP,
  BUSINESS_INFORMATION,
  CARD_VOLUME,
  CONTROL_PERSON,
  CONTROL_PERSON_EMPTY_MESSAGE,
  EDIT,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  PRICING_INFORMATION,
  PROCESSING_INFORMATION,
} from 'constants/language/languageConstants'

// this component is used for the review and submit step on access forms, both hosted and in the dashboard
const AccessFormReview = ({
  pricingInfoDataSection = [],
  businessInfoDataSection = [],
  controlPersonInfoDataSection = [],
  beneficialOwnershipInfoDataSection = [],
  processingInfoDataSection = [],
  cardVolumeDataSection = [],
  paymentVolumeDataSection = [],
  bankAccountInfoDataSection = [],
  goToStep = () => {},
  accessFormId = '',
  additionalDocuments = [],
  additionalDocumentActions = [],
  additionalVerificationNeeded = false,
  isHostedAccessForm = false,
  businessType = '',
  isInProgress = false,
}) => {
  return (
    <div className='AccessFormReview'>
      { additionalVerificationNeeded && (
        <PageSectionV2C
          className='additional-documents-page-section'
          smallTitle={ADDITIONAL_DOCUMENTS}
          actions={additionalDocumentActions}
        >
          <LiveAccountApplicationAdditionalDocumentsC
            isHostedAccessForm={isHostedAccessForm}
            accessFormId={accessFormId}
          />
        </PageSectionV2C>
      )}

      {!isHostedAccessForm && (
        <PageSectionV2C
          smallTitle={PRICING_INFORMATION}
          columns={pricingInfoDataSection}
          actions={[{ label: EDIT, action: () => goToStep(0) }]}
        />
      )}

      <PageSectionV2C
        smallTitle={BUSINESS_INFORMATION}
        columns={businessInfoDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 0 : 1) }]}
      />

      <PageSectionV2C
        smallTitle={CONTROL_PERSON}
        columns={controlPersonInfoDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 1 : 2) }]}
        emptyMessage={CONTROL_PERSON_EMPTY_MESSAGE}
      />

      {businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE && (
        <PageSectionV2C
          smallTitle={BENEFICIAL_OWNERSHIP}
          columns={beneficialOwnershipInfoDataSection}
          actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 1 : 2) }]}
          className='beneficial-owners-section'
          emptyMessage={BENEFICIAL_OWNERS_EMPTY_MESSAGE}
        />
      )}

      <PageSectionV2C
        smallTitle={PROCESSING_INFORMATION}
        columns={processingInfoDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 2 : 3) }]}
      />

      <PageSectionV2C
        smallTitle={CARD_VOLUME}
        columns={cardVolumeDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 2 : 3) }]}
      />

      <PageSectionV2C
        smallTitle={PAYMENT_VOLUME_BY_CUSTOMER_TYPE}
        columns={paymentVolumeDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 2 : 3) }]}
      />

      <PageSectionV2C
        smallTitle={BANK_ACCOUNT_INFORMATION}
        columns={bankAccountInfoDataSection}
        actions={[{ label: EDIT, action: () => goToStep(isHostedAccessForm ? 3 : 4) }]}
      />

      { isInProgress && (
        <PageSectionV2C
          className='additional-documents-page-section'
          smallTitle={ADDITIONAL_DOCUMENTS}
          actions={additionalDocumentActions}
        >
          <LiveAccountApplicationAdditionalDocumentsC
            isHostedAccessForm={isHostedAccessForm}
            accessFormId={accessFormId}
          />
        </PageSectionV2C>
      )}
    </div>
  )
}

AccessFormReview.propTypes = {
  pricingInfoDataSection: PropTypes.array,
  businessInfoDataSection: PropTypes.array,
  controlPersonInfoDataSection: PropTypes.array,
  beneficialOwnershipInfoDataSection: PropTypes.array,
  processingInfoDataSection: PropTypes.array,
  cardVolumeDataSection: PropTypes.array,
  paymentVolumeDataSection: PropTypes.array,
  bankAccountInfoDataSection: PropTypes.array,
  goToStep: PropTypes.func,
  accessFormId: PropTypes.string,
  additionalDocuments: PropTypes.array,
  additionalDocumentActions: PropTypes.array,
  additionalVerificationNeeded: PropTypes.bool,
  isHostedAccessForm: PropTypes.bool,
  businessType: PropTypes.string,
  isInProgress: PropTypes.bool,
}

export default AccessFormReview
