import React from 'react'
import PropTypes from 'prop-types'
import { getPurchasesWithTransferSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { PURCHASES } from 'constants/linkConstants'
import { GET_PURCHASES } from 'constants/flowConstants'
import { PURCHASES_EMPTY_TABLE_MESSAGE } from 'constants/language/languageConstants'

const PaymentLinkPayments = ({
  purchasesPath = '',
  initialQueries,
  initialFilters,
  quickFilters,
  isFetching = false,
}) => {
  return (
    <div className='PaymentLinkPayments'>
      <TableC
        flow={GET_PURCHASES}
        columnDescriptors={columnDescriptors}
        selector={getPurchasesWithTransferSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        quickFilters={quickFilters}
        linksKey={PURCHASES}
        path={purchasesPath}
        isFetching={isFetching}
        emptyMessage={PURCHASES_EMPTY_TABLE_MESSAGE}
        fileNameCSV='Payment_Link_Purchases'
      />
    </div>
  )
}

PaymentLinkPayments.propTypes = {
  purchasesPath: PropTypes.string,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default PaymentLinkPayments
