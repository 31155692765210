import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import PaymentDevices from 'components/Customer/Pages/PaymentDevices/PaymentDevices'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { ADD_NEW_DEVICE } from 'constants/language/languageConstants'
import { FETCHING } from 'constants/reducerConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  ADD_NEW_DEVICE_PATH,
  PAYMENT_DEVICES_PATH,
} from 'constants/pathConstants'

import {
  DEVICE_DATES_QUICK_FILTER,
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  DEVICE_MODEL,
  DEVICE_NAME,
  SERIAL_NUMBER,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  DEVICE_MODEL,
  DEVICE_NAME,
  SERIAL_NUMBER,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
  DEVICE_DATES_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `devicesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const paymentDevicePath = PAYMENT_DEVICES_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const merchantId = get(props, 'merchantId')
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    isFetching,
    paymentDevicePath,
    credentials,
    credentialId,
    merchantId,
    showHeader: false,
  }
}

class MerchantPaymentDevicesC extends Component {
  render() {
    const {
      credentialId,
      merchantId,
    } = this.props

    const actions = [
      {
        label: ADD_NEW_DEVICE,
        className: 'add-new-device',
        action: () => goToPath({
          pathname: ADD_NEW_DEVICE_PATH({ credentialId }),
          queries: { merchantId },
        }),
      },
    ]

    return (
      <PaymentDevices
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps)(MerchantPaymentDevicesC)
