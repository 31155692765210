import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

import {
  SYSTEM,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

const isPaymentOperationsManagerRole = (credentials = getCurrentCredentials()) => {
  const isSystemRole = get(credentials, 'dashboardUserRoleType') === SYSTEM
  const isRolePaymentOperationsManager = get(credentials, 'dashboardUserRoleName') === SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE

  return isSystemRole && isRolePaymentOperationsManager
}

export default isPaymentOperationsManagerRole
