import co from 'co'
import postIdentityAPI from 'api/finix/post/postIdentityAPI'
import postIdentityPaymentInstrumentAPI from 'api/finix/post/postIdentityPaymentInstrumentAPI'
import postPaymentInstrumentVerificationAPI from 'api/finix/post/postPaymentInstrumentVerificationAPI'
import postIdentityMerchantAPI from 'api/finix/post/postIdentityMerchantAPI'
import backendIdentityModel from 'utilities/create/models/backendIdentityModel'
import backendPaymentInstrumentModel from 'utilities/create/models/backendPaymentInstrumentModel'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import get from 'lodash/get'
import omit from 'lodash/omit'

/* ============ Post Recipient Flow ==========
1) create identity in Finix
2) create a payment instrument (linked to identity)
3) verify the payment instrument (for cards)
4) onboard the identity aka create a merchant account
*/
function * postRecipientIdentityO ({
  values,
  credentials,
}) {
  const paymentInstrumentValues = get(values, 'paymentInstrument', {})
  const identityValues = omit(values, ['paymentInstrument'])
  const isCard = get(paymentInstrumentValues, 'type') === 'PAYMENT_CARD'
  const processor = get(values, 'processor')

  // create the identity
  const backendMerchantIdentityModel = backendIdentityModel({ values: identityValues })
  const { data: identityResponse, error: identityError } = yield postIdentityAPI({ values: { identity_roles: ['RECIPIENT'], ...backendMerchantIdentityModel }, credentials })
  const identityId = get(identityResponse, 'id')

  if (identityError) {
    return createErrorResponse({
      error: {
        response: {
          data: {
            message: 'There was an issue creating the recipient. Please try again.',
          },
        },
      },
    })
  }

  // create the payment instrument
  const backendPaymentInstrumentModelValues = backendPaymentInstrumentModel({ values: { identity: identityId, ...paymentInstrumentValues } })
  const { data: paymentInstrumentResponse, error: paymentInstrumentError } = yield postIdentityPaymentInstrumentAPI({
    values: backendPaymentInstrumentModelValues,
    credentials,
  })
  const paymentInstrumentId = get(paymentInstrumentResponse, 'id')

  if (paymentInstrumentError) {
    return createErrorResponse({
      error: {
        response: {
          data: {
            message: 'There was an issue creating the payment instrument. Please try again.',

          },
        },
      },
    })
  }

  // verify the payment instrument for cards
  if (isCard) {
    const { data: paymentInstrumentVerificationResponse, error: paymentInstrumentVerificationError } = yield postPaymentInstrumentVerificationAPI({
      id: paymentInstrumentId,
      values: {
        processor,
      },
      credentials,
    })

    if (paymentInstrumentVerificationError) {
      return createErrorResponse({
        error: {
          response: {
            data: {
              message: 'There was an issue verifying the payment instrument. Please try again with a valid payment instrument.',
            },
          },
        },
      })
    }
  }

  // onboard the identity aka create a merchant
  const { data: merchantResponse } = yield postIdentityMerchantAPI({
    values: { identity: identityId, processor },
    credentials,
  })

  return createSuccessResponse({
    data: {
      id: identityId,
      identity: identityResponse,
      paymentInstrument: paymentInstrumentResponse,
      merchant: merchantResponse,
    },
  })
}

export default co.wrap(postRecipientIdentityO)
