import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'

const frontendFeeModel = ({ data: fee }) => {
  const [
    id,
    amount,
    currency,
    feeType,
    feeSubtype,
    label,
    createdAt,
    updatedAt,
    merchantId,
    merchant,
    linkedType,
    linkedId,
    settlement,
    settlementId,
    settlementEntry,
    tags,
    transfer,
    application,
    identity,
    category,
  ] = getMany(fee, [
    'id',
    'amount',
    'currency',
    'fee_type',
    'fee_subtype',
    'label',
    'created_at',
    'updated_at',
    'merchant',
    'related_merchant',
    'linked_type',
    'linked_id',
    'settlement',
    'settlementId',
    'settlementEntry',
    'tags',
    'transfer',
    'application',
    'identity',
    'category',
  ])

  return removeUndefined({
    id,
    amount,
    displayAmount: formatMoney({ amount, currency }),
    displayAmountWithCurrencyCode: formatMoney({ amount, currency, showCurrencyCode: true }),
    currency,
    feeType,
    displayFeeType: convertSnakeToSentenceCase(feeType),
    feeSubtype,
    displayFeeSubtype: convertSnakeToSentenceCase(feeSubtype),
    label,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    merchantId,
    merchant,
    linkedType,
    linkedId,
    displayLinkedType: convertSnakeToSentenceCase(linkedType),
    settlement,
    settlementId,
    settlementEntry,
    tags,
    transfer: frontendTransferModel({ data: transfer }),
    application: frontendApplicationModel({ data: application }),
    identity: frontendIdentityModel({ data: identity }),
    category,
    displayCategory: convertSnakeToSentenceCase(category),
  })
}

export default frontendFeeModel
