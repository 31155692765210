import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import PaymentLink from './PaymentLink'
import PaymentLinkOverviewC from './PaymentLinkOverview/PaymentLinkOverviewC'
import PaymentLinkPaymentsC from './PaymentLinkPayments/PaymentLinkPaymentsC'
import { getPaymentLinkDataSelector } from 'state-layer/selectors'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import PaymentLinkStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PaymentLinkStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPaymentLinkRequest from 'utilities/actions/get/getPaymentLinkRequest'
import copyToClipboard from 'utilities/copyToClipboard'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { COPY } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  ACTIVE,
  DEACTIVATED,
} from 'constants/paymentLinkConstants'

import {
  ENABLE_PAYMENT_LINK_MODAL,
  DISABLE_PAYMENT_LINK_MODAL,
} from 'constants/modalConstants'


import {
  t,
  NAME,
  STATUS,
  CREATED_ON,
  EXPIRES_ON,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_ACCOUNT,
  PAYMENT_LINK,
  DEACTIVATE_PAYMENT_LINK,
  OVERVIEW,
  COPY_LINK,
  ACTIVATE_PAYMENT_LINK,
  PAYMENT_ATTEMPTS,
} from 'constants/language/languageConstants'

import {
  APPLICATION_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const paymentLinkId = get(props, 'params.paymentLinkId')
  const paymentLink = getPaymentLinkDataSelector(state, paymentLinkId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    applicationId,
    applicationName,
    merchantId,
    merchantIdentityId,
    merchantName,
    merchantBusinessName,
    displayTotalAmount,
    currency,
    nickname,
    status,
    displayCreatedAt,
    displayLinkExpiresAt,
    linkUrl,
    linkType,
    amountType,
  ] = getMany(paymentLink, [
    'applicationId',
    'application.businessName',
    'merchant.id',
    'merchant.identityId',
    'merchant.name',
    'merchant.displayBusinessName',
    'displayTotalAmount',
    'currency',
    'nickname',
    'state',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'linkUrl',
    'linkType',
    'amountType',
  ])

  const isFixedAmount = amountType === 'FIXED'

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: hasPlatformAccess({ credentials }),
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantName || merchantBusinessName,
        path: MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId }),
        condition: hasPartnerAccess({ credentials }),
      },
    ],
  }

  const headerItems = [
    {
      label: NAME,
      value: nickname,
    },
    {
      label: STATUS,
      value: <PaymentLinkStatus value={status} />,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: EXPIRES_ON,
      value: displayLinkExpiresAt,
    },
    {
      label: PAYMENT_LINK,
      value: <span className='link-2' onClick={() => copyToClipboard(linkUrl)}><i className={`fas fa-${COPY}`} /> {t(COPY_LINK)}</span>,
    },
  ]

  if (isFixedAmount) {
    headerItems.unshift({ value: <AmountCurrencyDisplayHeader displayAmount={displayTotalAmount} currency={currency} /> })
  }

  const headerData = {
    resource: {
      label: PAYMENT_LINK,
      id: paymentLinkId,
      badge: linkType,
    },
    items: headerItems,
  }

  const tabs = [
    {
      id: 'overview',
      name: OVERVIEW,
      component: PaymentLinkOverviewC,
      props: {
        paymentLinkId,
      },
    },
    {
      id: 'payment-attempts',
      name: PAYMENT_ATTEMPTS,
      component: PaymentLinkPaymentsC,
      props: {
        paymentLinkId,
      },
    },
  ]

  return {
    credentials,
    paymentLink,
    paymentLinkId,
    isFetching,
    headerData,
    contextBarData,
    tabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentLink: ({ credentials, paymentLinkId }) => { dispatch(getPaymentLinkRequest({ credentials, paymentLinkId })) },
    showEnablePaymentLinkModal: ({ paymentLinkId }) => dispatch(showModalAction({ modalType: ENABLE_PAYMENT_LINK_MODAL, modalProps: { paymentLinkId }, className: 'modal-md no-pad' })),
    showDisablePaymentLinkModal: ({ paymentLinkId }) => dispatch(showModalAction({ modalType: DISABLE_PAYMENT_LINK_MODAL, modalProps: { paymentLinkId }, className: 'modal-md no-pad' })),
  }
}

class PaymentLinkC extends Component {
  componentDidMount() {
    const {
      credentials,
      paymentLinkId,
      getPaymentLink,
    } = this.props

    getPaymentLink({ credentials, paymentLinkId })
  }

  render() {
    const {
      paymentLink,
      paymentLinkId,
      showEnablePaymentLinkModal,
      showDisablePaymentLinkModal,
    } = this.props

    const status = get(paymentLink, 'state')

    const actions = []

    if (status === ACTIVE) {
      actions.push({
        label: DEACTIVATE_PAYMENT_LINK,
        action: () => showDisablePaymentLinkModal({ paymentLinkId }),
        className: 'deactivate-payment-link-button',
      })
    }

    if (status === DEACTIVATED) {
      actions.push({
        label: ACTIVATE_PAYMENT_LINK,
        action: () => showEnablePaymentLinkModal({ paymentLinkId }),
        className: 'activate-payment-link-button',
      })
    }

    return (
      <PaymentLink
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkC)
