import get from 'lodash/get'
import map from 'lodash/map'
import formatRadioDisplayData from 'utilities/formatters/formatRadioDisplayData'
import formatCheckboxGroupDisplayData from 'utilities/formatters/formatCheckboxGroupDisplayData'
import formatDateDisplayData from 'utilities/formatters/formatDateDisplayData'
import formatCustomFieldArrayDisplayData from 'utilities/formatters/formatCustomFieldArrayDisplayData'
import { DATE_FORMAT } from 'constants/timeConstants'
import filter from 'lodash/filter'

import {
  PCI_DSS_VALIDATION_OPTIONS,
  YES_NO_YES_NO_RADIO_OPTIONS,
} from 'constants/complianceConstants'

const ComplianceValidationAndAttestationDetailsFields = [
  {
    name: 'pciDssValidation',
    label: 'PCI DSS Validation',
    formatter: ({ pciDssValidation }) => formatRadioDisplayData({ value: pciDssValidation, options: PCI_DSS_VALIDATION_OPTIONS }),
  },
  {
    name: 'compliantWithLegalExceptionDetails',
    label: 'PCI DSS Validation - Details',
    formatter: ({ compliantWithLegalExceptionDetails }) => formatCustomFieldArrayDisplayData({
      data: compliantWithLegalExceptionDetails,
      itemName: 'Details',
      optionals: {
        affected_requirement: { label: 'Requirement' },
        details: { label: 'Details' },
      },
    }),
    condition: ({ pciDssValidation }) => pciDssValidation === 'compliant_with_legal_exception',
  },
  {
    name: 'acknowledgementOfStatus',
    label: 'Acknowledgement Of Status',
    formatter: ({ acknowledgementOfStatus }) => formatCheckboxGroupDisplayData({ data: acknowledgementOfStatus }),
  },
  {
    name: 'qsaInvolvedDescribeRolePerformed',
    label: 'QSA - Involved Describe Role Performed',
  },
  {
    name: 'qsaSignature',
    label: 'QSA - Signature',
  },
  {
    name: 'qsaDate',
    label: 'QSA - Date',
    formatter: ({ qsaDate }) => formatDateDisplayData({ date: qsaDate, format: DATE_FORMAT }),
  },
  {
    name: 'qsaDulyAuthorizedOfficerName',
    label: 'QSA - Duly Authorized Officer Name',
  },
  {
    name: 'qsaCompany',
    label: 'QSA - Company Name',
  },
  {
    name: 'isaInvolvedDescribeRolePerformed',
    label: 'QSA - Role Performed',
  },
  {
    name: 'doNotUserVendorSuppliedDefaults',
    label: 'PCI DSS Req 2',
    formatter: ({ doNotUserVendorSuppliedDefaults }) => formatRadioDisplayData({ value: doNotUserVendorSuppliedDefaults, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'doNotUserVendorSuppliedDefaultsDate',
    label: 'PCI DSS Req 2 - Remediation Details',
    formatter: ({ doNotUserVendorSuppliedDefaultsDate, doNotUserVendorSuppliedDefaultsDescription }) => {
      const formattedDate = formatDateDisplayData({ date: doNotUserVendorSuppliedDefaultsDate, format: DATE_FORMAT })
      return `${formattedDate} - ${doNotUserVendorSuppliedDefaultsDescription}`
    },
    condition: ({ doNotUserVendorSuppliedDefaults }) => doNotUserVendorSuppliedDefaults === 'NO',
  },
  {
    name: 'developAndMaintainSecureSystems',
    label: 'PCI DSS Req 6',
    formatter: ({ developAndMaintainSecureSystems }) => formatRadioDisplayData({ value: developAndMaintainSecureSystems, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'developAndMaintainSecureSystemsDate',
    label: 'PCI DSS Req 6 - Remediation Details',
    formatter: ({ developAndMaintainSecureSystemsDate, developAndMaintainSecureSystemsDescription }) => {
      const formattedDate = formatDateDisplayData({ date: developAndMaintainSecureSystemsDate, format: DATE_FORMAT })
      return `${formattedDate} - ${developAndMaintainSecureSystemsDescription}`
    },
    condition: ({ developAndMaintainSecureSystems }) => developAndMaintainSecureSystems === 'NO',
  },
  {
    name: 'identifyAndAuthenticateAccessToSystemComponents',
    label: 'PCI DSS Req 8',
    formatter: ({ identifyAndAuthenticateAccessToSystemComponents }) => formatRadioDisplayData({ value: identifyAndAuthenticateAccessToSystemComponents, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'identifyAndAuthenticateAccessToSystemComponentsDate',
    label: 'PCI DSS Req 8 - Remediation Details',
    formatter: ({ identifyAndAuthenticateAccessToSystemComponentsDate, identifyAndAuthenticateAccessToSystemComponentsDescription }) => {
      const formattedDate = formatDateDisplayData({ date: identifyAndAuthenticateAccessToSystemComponentsDate, format: DATE_FORMAT })
      return `${formattedDate} - ${identifyAndAuthenticateAccessToSystemComponentsDescription}`
    },
    condition: ({ identifyAndAuthenticateAccessToSystemComponents }) => identifyAndAuthenticateAccessToSystemComponents === 'NO',
  },
  {
    name: 'restrictPhysicalAccessToCardholder',
    label: 'PCI DSS Req 9',
    formatter: ({ restrictPhysicalAccessToCardholder }) => formatRadioDisplayData({ value: restrictPhysicalAccessToCardholder, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'restrictPhysicalAccessToCardholderDate',
    label: 'PCI DSS Req 9 - Remediation Details',
    formatter: ({ restrictPhysicalAccessToCardholderDate, restrictPhysicalAccessToCardholderDescription }) => {
      const formattedDate = formatDateDisplayData({ date: restrictPhysicalAccessToCardholderDate, format: DATE_FORMAT })
      return `${formattedDate} - ${restrictPhysicalAccessToCardholderDescription}`
    },
    condition: ({ restrictPhysicalAccessToCardholder }) => restrictPhysicalAccessToCardholder === 'NO',
  },
  {
    name: 'maintainAPolicyAddressesInformationSecurity',
    label: 'PCI DSS Req 12',
    formatter: ({ maintainAPolicyAddressesInformationSecurity }) => formatRadioDisplayData({ value: maintainAPolicyAddressesInformationSecurity, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'maintainAPolicyAddressesInformationSecurityDate',
    label: 'PCI DSS Req 12 - Remediation Details',
    formatter: ({ maintainAPolicyAddressesInformationSecurityDate, maintainAPolicyAddressesInformationSecurityDescription }) => {
      const formattedDate = formatDateDisplayData({ date: maintainAPolicyAddressesInformationSecurityDate, format: DATE_FORMAT })
      return `${formattedDate} - ${maintainAPolicyAddressesInformationSecurityDescription}`
    },
    condition: ({ maintainAPolicyAddressesInformationSecurity }) => maintainAPolicyAddressesInformationSecurity === 'NO',
  },
]

const ComplianceValidationAndAttestationDetailsDisplayData = (values) => {
  const filteredDetails = filter(ComplianceValidationAndAttestationDetailsFields, ({ condition = () => true }) => condition(values))

  return map(filteredDetails, ({ name, label, formatter }) => ({
    label,
    value: formatter ? formatter(values) : get(values, name),
  }))
}

export default ComplianceValidationAndAttestationDetailsDisplayData
