import get from 'lodash/get'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import concat from 'lodash/concat'
import { FETCHING } from 'constants/reducerConstants'

const mergeOverwriteKeys = (keys) => {
  return (state, action) => {
    const newValues = get(action, 'payload.newValues', {})

    const omitKeys = reduce(newValues, (total, { id }) => {
      return concat(total, map(keys, (key) => { return `items.${id}.${key}` }))
    }, [])

    return merge({}, omit(state, omitKeys), {
      items: keyBy(newValues, 'id'),
      [FETCHING]: false,
    })
  }
}

export default mergeOverwriteKeys
