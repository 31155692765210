import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { EDIT_DEVICE_DETAILS } from 'constants/amplitudeConstants'
import { PATCH_DEVICE_F_REQUEST } from 'constants/flowConstants'

const submitEditDeviceDetailsForm = (values, dispatch, props) => {
  const [
    credentials,
    deviceId,
    canEditSerialNumber,
  ] = getMany(props, [
    'credentials',
    'deviceId',
    'canEditSerialNumber',
  ])

  const [
    deviceName,
    description,
    serialNumber,
  ] = getMany(values, [
    'deviceName',
    'deviceDescription',
    'serialNumber',
  ])

  const valuesToSubmit = removeUndefined({
    deviceName,
    description,
    serialNumber: canEditSerialNumber ? serialNumber : undefined,
  })

  dispatch({
    type: PATCH_DEVICE_F_REQUEST,
    payload: {
      id: deviceId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_DEVICE_DETAILS, {
        credentials,
        valuesToSubmit,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
      successMessage: `${deviceName} has been successfully updated.`,
    },
  })
}

export default submitEditDeviceDetailsForm
