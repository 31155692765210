import './SettlementsConfirmationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import formatMoney from 'utilities/formatters/formatMoney'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import { CREATED_AT_LABEL } from 'constants/language/languageConstants'

const SettlementsConfirmationModal = ({
  settlements = [],
}) => {
  return (
    <div className='SettlementsConfirmationModal'>
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>{CREATED_AT_LABEL}</th>
            <th>Processor</th>
            <th>Amount</th>
          </tr>

          {
            map(settlements, (settlement) => {
              const [
                id,
                settlementId,
                displayCreatedAt,
                processor,
                settlementProcessor,
                totalAmount,
                settlementTotalAmount,
              ] = getMany(settlement, [
                'entity.id',
                'id',
                'displayCreatedAt',
                'entity.processor',
                'processor',
                'entity.totalAmount',
                'totalAmount',
              ])

              return (
                <tr key={id}>
                  <td>{id ? id : settlementId}</td>
                  <td>{displayCreatedAt}</td>
                  <td>{processor ? processor : settlementProcessor}</td>
                  <td>{totalAmount ? formatMoney({ amount: totalAmount }) : formatMoney({ amount: settlementTotalAmount })}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

SettlementsConfirmationModal.propTypes = {
  settlements: PropTypes.array,
}

export default SettlementsConfirmationModal
