import React from 'react'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import { createdAtRange } from 'utilities/table/sort'
import column from 'utilities/table/column'

import {
  ID,
  CREATED_ON,
  BUSINESS_NAME,
  PERSONAL_NAME,
  COUNTRY,
  ROLE,
  EMAIL,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(BUSINESS_NAME, 'displayBusinessName'),
  column(PERSONAL_NAME, 'fullName'),
  column(EMAIL, 'email'),
  column(ROLE, 'displayIdentityRole'),
  column(COUNTRY, 'displayCountry', {
    formatter: ({ country }) => {
      return CountryFlagIcon({ country })
    },
  }),
]

export default columnDescriptors
