import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewQueueSettlements from './ReviewQueueSettlements'
import ApproveAllConfirmationFormC from 'components/Customer/Forms/ApproveAllConfirmationForm/ApproveAllConfirmationFormC'
import SettlementsConfirmationModal from 'components/Customer/Modals/SettlementsConfirmationModal/SettlementsConfirmationModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchReviewQueueItemsRequest from 'utilities/actions/patch/patchReviewQueueItemsRequest'
import showModalAction from 'utilities/actions/showModalAction'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import calculateReviewQueueSettlementsAmount from 'utilities/calculate/calculateReviewQueueSettlementsAmount'
import formatMoney from 'utilities/formatters/formatMoney'
import pluralizeWord from 'utilities/pluralizeWord'
import getMany from 'utilities/get/getMany'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CONFIRM_ACTION } from 'constants/modalConstants'
import { REVIEW_QUEUE_SETTLEMENTS } from 'constants/selectedItemsConstants'
import { SETTLEMENT } from 'constants/reviewQueueConstants'
import { POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST } from 'constants/flowConstants'
import { REVIEW_QUEUE } from 'constants/linkConstants'
import { FETCHING } from 'constants/reducerConstants'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { REVIEW_SETTLEMENT_PATH } from 'constants/pathConstants'
import { APPROVE_ALL_SETTLEMENTS } from 'constants/amplitudeConstants'
import numeral from 'numeral'
import map from 'lodash/map'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import get from 'lodash/get'
import size from 'lodash/size'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'

import {
  getReviewQueueSelector,
  getReviewQueueSettlement,
  getSelectedItemsByKeySelector,
  getReviewQueueSettlementsByOutcomeSelector,
} from 'state-layer/selectors'

import {
  PENDING,
  REJECTED,
  ACCEPTED,
} from 'constants/settlementConstants'

import {
  SORT,
  LIMIT,
  OFFSET,
} from 'constants/queryConstants'

import {
  SETTLEMENT_ID,
  APPLICATION_REVIEW_QUEUE,
  MERCHANT_REVIEW_QUEUE,
  getAvailableProcessorsFilter,
} from 'constants/filterConstants'

import {
  DATE_QUICK_FILTER,
  REVIEW_QUEUE_SETTLEMENTS_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const entityType = SETTLEMENT

const selectedItemsKey = REVIEW_QUEUE_SETTLEMENTS

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  entity_type: entityType,
}

const initialFilters = {
  outcome: {
    eq: PENDING,
  },
}

const quickFilters = [
  REVIEW_QUEUE_SETTLEMENTS_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `reviewQueueR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const reviewSettlementPath = ({ entityId }) => REVIEW_SETTLEMENT_PATH({ settlementId: entityId, credentialId })
  const reviewQueueSettlements = getReviewQueueSelector(state)
  const selectedReviewQueueSettlements = getSelectedItemsByKeySelector(state, selectedItemsKey)
  const selectedSettlements = map(selectedReviewQueueSettlements, (value, id) => getReviewQueueSettlement(state, id))
  const selectedSettlementsTotalAmount = numeral(calculateReviewQueueSettlementsAmount({ state, selectedItems: selectedReviewQueueSettlements })).value()
  const selectedSettlementsNetAmount = formatMoney({ amount: sumBy(selectedSettlements, (settlement) => get(settlement, 'entity.netAmount', 0)).toFixed(2) })
  const totalSettlementsCount = get(state, 'linksR.REVIEW_QUEUE.page.count')
  const queries = get(props, 'queries', {})
  const outcome = getUrlQuery('outcome')
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)

  const mergedQueries = merge({}, initialQueries, queries)
  const PROCESSOR = getAvailableProcessorsFilter()
  const approveAllInitialQueries = merge({}, parseUrlQueries(), { offset: 0, limit: 50 })

  // TODO: Comment MERCHANT_REVIEW_QUEUE back in once BE is deployed next week 05/08/2024
  const allowedFilters = sortBy([
    PROCESSOR,
    SETTLEMENT_ID,
    APPLICATION_REVIEW_QUEUE,
    MERCHANT_REVIEW_QUEUE,
  ], 'label')

  const [
    isApprovingAll,
    totalApproved = 0,
    totalFailed = 0,
  ] = getMany(state, [
    `massApprovalR.${FETCHING}`,
    'massApprovalR.totalApproved',
    'massApprovalR.totalFailed',
  ])

  return {
    credentials,
    initialQueries: mergedQueries,
    initialFilters,
    approveAllInitialQueries,
    isFetching,
    reviewQueueSettlements,
    reviewSettlementPath,
    outcome,
    limit,
    offset,
    sort,
    selectedItemsKey,
    selectedSettlements,
    selectedReviewQueueSettlements,
    allowedFilters,
    selectedSettlementsTotalAmount,
    selectedSettlementsNetAmount,
    totalSettlementsCount,
    isApprovingAll,
    totalApproved,
    totalFailed,
    quickFilters,
    selector: (currentState) => getReviewQueueSettlementsByOutcomeSelector(currentState, outcome),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSettlementsConfirmationModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_ACTION, modalProps })),
    patchItemsRequest: ({ items, queries, credentials }) => dispatch(patchReviewQueueItemsRequest({ items, queries, selectedItemsKey, credentials, dispatch })),
    clearReducer: () => dispatch(clearReducerRequest(['reviewQueueR'])),
    postMassSettlementApproval: ({ credentials, queries }) => dispatch({
      type: POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST,
      payload: {
        credentials,
        queries,
        linksKey: REVIEW_QUEUE,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(APPROVE_ALL_SETTLEMENTS, {
          credentials,
          queries,
        }),
      },
    }),
  }
}

class ReviewQueueSettlementsC extends Component {
  componentDidMount () {
    const {
      clearReducer,
    } = this.props

    clearReducer()
  }

  showRejectionConfirmationModal = () => {
    const {
      showSettlementsConfirmationModal,
      selectedSettlements,
      selectedSettlementsTotalAmount,
    } = this.props

    const settlementsTotal = selectedSettlements.length
    const pluralizedSettlement = pluralizeWord({ count: settlementsTotal, word: 'settlement' })
    const message = <>Are you sure you want to <strong>reject {settlementsTotal}</strong> {pluralizedSettlement} totaling <strong>{formatMoney({ amount: selectedSettlementsTotalAmount })}</strong>?</>
    const description = <SettlementsConfirmationModal settlements={selectedSettlements} />

    const confirmationAction = () => this.updateSelectedSettlementsStatus({ status: REJECTED })

    showSettlementsConfirmationModal({ message, description, confirmationAction })
  }

  showApprovalConfirmationModal = () => {
    const {
      showSettlementsConfirmationModal,
      selectedSettlements,
      selectedSettlementsTotalAmount,
    } = this.props

    const settlementsTotal = selectedSettlements.length
    const pluralizedSettlement = pluralizeWord({ count: settlementsTotal, word: 'settlement' })
    const message = <>Are you sure you want to <strong>approve {settlementsTotal}</strong> {pluralizedSettlement} totaling <strong>{formatMoney({ amount: selectedSettlementsTotalAmount })}</strong>?</>
    const description = <SettlementsConfirmationModal settlements={selectedSettlements} />

    const confirmationAction = () => this.updateSelectedSettlementsStatus({ status: ACCEPTED })

    showSettlementsConfirmationModal({ message, description, confirmationAction })
  }

  showApproveAllConfirmationModal = () => {
    const {
      showSettlementsConfirmationModal,
      credentials,
      approveAllInitialQueries,
      postMassSettlementApproval,
      totalSettlementsCount,
    } = this.props

    const pluralizedSettlement = pluralizeWord({ count: totalSettlementsCount, word: 'settlement' })
    const message = <>Are you sure you want to <strong>approve {totalSettlementsCount}</strong> {pluralizedSettlement}?</>

    const confirmationAction = () => {
      postMassSettlementApproval({
        credentials,
        queries: approveAllInitialQueries,
      })
    }

    showSettlementsConfirmationModal({ message, confirmationAction, Component: ApproveAllConfirmationFormC })
  }

  updateSelectedSettlementsStatus = ({ status }) => {
    const {
      limit,
      offset,
      sort,
      outcome,
      credentials,
      reviewQueueSettlements,
      selectedReviewQueueSettlements,
      patchItemsRequest,
    } = this.props

    const selectedIds = Object.keys(selectedReviewQueueSettlements)
    const selectedReviewQueueItems = pick(reviewQueueSettlements, selectedIds)
    const items = map(selectedReviewQueueItems, (item) => merge({}, item, { outcome: status }))

    const queries = {
      limit,
      offset,
      sort,
      outcome,
      entity_type: entityType,
    }

    patchItemsRequest({ items, queries, credentials })
  }

  selectedItemsActionsByOutcome = (outcome) => {
    return get({
      [PENDING]: [
        {
          label: 'Reject',
          action: this.showRejectionConfirmationModal,
        },
        {
          label: 'Approve',
          action: this.showApprovalConfirmationModal,
        },
      ],
      [REJECTED]: [
        {
          label: 'Move to Pending',
          action: () => {
            this.updateSelectedSettlementsStatus({ status: PENDING })
          },
        },
      ],
    }, outcome, [])
  }

  tableActionsByOutcome = (outcome) => {
    const { credentials } = this.props

    return get({
      [PENDING]: [
        {
          label: 'Approve All',
          action: this.showApproveAllConfirmationModal,
          condition: !isRolePartner({ credentials }),
        },
      ],
    }, outcome, [])
  }

  // TODO: add logic to have different selectedItemsAction depending on outcome
  render() {
    const {
      outcome,
      selectedSettlements,
      selectedSettlementsNetAmount,
      selectedSettlementsTotalAmount,
    } = this.props

    const selectedItemsData = {
      actions: this.selectedItemsActionsByOutcome(outcome),
      // TODO: consider making this it's own component with it's own style sheet
      info: (
        <div className='review-settlements-info flex'>
          <div><span>Selected:</span> {size(selectedSettlements)}</div>
          <div><span>Amount:</span> {formatMoney({ amount: selectedSettlementsTotalAmount })}</div>
          <div><span>Net Amount:</span> {selectedSettlementsNetAmount}</div>
        </div>
      ),
    }

    return (
      <ReviewQueueSettlements
        {...this.props}
        tableActions={this.tableActionsByOutcome(outcome)}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewQueueSettlementsC)
