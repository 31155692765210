import React from 'react'
import PropTypes from 'prop-types'
import Notes from 'components/Customer/Pages/Notes/Notes'

const MonitoringAlertNotes = ({
  monitoringAlertId,
  emptyMessage = 'No notes found',
  showAddNewNote = true,
}) => {
  return (
    <div className='MonitoringAlertNotes'>
      {monitoringAlertId && (
      <Notes
        linkedTo={monitoringAlertId}
        emptyMessage={emptyMessage}
        showAddNewNote={showAddNewNote}
      />
      )}
    </div>
  )
}

MonitoringAlertNotes.propTypes = {
  monitoringAlertId: PropTypes.string,
  emptyMessage: PropTypes.string,
  showAddNewNote: PropTypes.bool,
}

export default MonitoringAlertNotes
