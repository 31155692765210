import './RecipientsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { getIdentitiesSelector } from 'state-layer/selectors'
import { IDENTITIES } from 'constants/linkConstants'
import { MERCHANTS_ICON } from 'constants/iconConstants'
import { GET_RECIPIENT_IDENTITIES } from 'constants/flowConstants'
import { RECIPIENTS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  RECIPIENTS,
  RECIPIENT_SUBTITLE_DESCRIPTION,
  RECIPIENTS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const Recipients = ({
  identitiesPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  hiddenExportFields = [],
  quickFilters,
  hideHeader = false,
  actions = [],
}) => {
  return (
    <div className='Recipients'>
      {!hideHeader && (
        <HeaderV2C
          title={RECIPIENTS}
          subTitle={RECIPIENT_SUBTITLE_DESCRIPTION}
          learnMoreLink={RECIPIENTS_LEARN_MORE_LINK}
        />
      )}

      <TableC
        flow={GET_RECIPIENT_IDENTITIES}
        columnDescriptors={columnDescriptors}
        selector={getIdentitiesSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        allowedFilters={allowedFilters}
        linksKey={IDENTITIES}
        path={identitiesPath}
        isFetching={isFetching}
        fileNameCSV={RECIPIENTS}
        entityType={IDENTITIES}
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${MERCHANTS_ICON}`}
        emptyMessage={RECIPIENTS_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        actions={actions}
      />
    </div>
  )
}

Recipients.propTypes = {
  identitiesPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  hiddenExportFields: PropTypes.array,
  quickFilters: PropTypes.array,
  hideHeader: PropTypes.bool,
  actions: PropTypes.array,
}

export default Recipients
