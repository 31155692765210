import Note from './Note'
import React, { Component, createRef, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const note = get(props, 'note', {})
  const jsonContent = get(note, 'jsonContent', {})

  return {
    jsonContent,
  }
}

class NoteC extends Component {
  state = {
    quillRef: createRef(),
    overflowY: false,
  }

  componentDidMount() {
    const { quillRef } = this.state
    const editingArea = get(quillRef, 'current.editingArea')
    if (editingArea) {
      this.setState({
        overflowY: editingArea.scrollHeight > editingArea.clientHeight,
      })
    }
  }

  render() {
    const {
      quillRef,
      overflowY,
    } = this.state

    return (
      <Note
        {...this.props}
        quillRef={quillRef}
        overflowY={overflowY}
      />
    )
  }
}

export default connect(mapStateToProps)(NoteC)
