import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'

import {
  t,
  CANCEL,
  REACTIVATE,
  REACTIVATE_PAYMENT_LINK_MODAL_TEXT,
  REACTIVATE_PAYMENT_LINK,
} from 'constants/language/languageConstants'

const EnablePaymentLinkModal = ({
  isPatching = false,
  closeModal = () => {},
  enablePaymentLink = () => {},
}) => {
  return (
    <GenericModal title={REACTIVATE_PAYMENT_LINK}>
      <div className='modal-content'>
        <p className='p-1'>{t(REACTIVATE_PAYMENT_LINK_MODAL_TEXT)}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} onClick={closeModal} />
        <Button type='submit' label={REACTIVATE} onClick={enablePaymentLink} disabled={isPatching} />
      </div>
    </GenericModal>
  )
}

EnablePaymentLinkModal.propTypes = {
  isPatching: PropTypes.bool,
  closeModal: PropTypes.func,
  enablePaymentLink: PropTypes.func,
}

export default EnablePaymentLinkModal
