import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

const frontendNotificationModel = ({ data: notification }) => {
  const [
    applicationId,
    applicationName,
    eventType,
    merchantId,
    merchantName,
    notificationType,
    platformId,
    platformName,
    resourceId,
    resourceType,
    seenAt,
    subscriptionId,
    id,
    createdAt,
    message,
    metaData,
  ] = getMany(notification, [
    'application_id',
    'application_name',
    'event_type',
    'merchant_id',
    'merchant_name',
    'notification_type',
    'platform_id',
    'platform_name',
    'resource_id',
    'resource_type',
    'seen_at',
    'subscription_id',
    'id',
    'created_at',
    'metadata.message',
    'metadata',
  ])

  const displayCreatedAt = formatDate({ date: createdAt })
  const displayResourceType = startCase(lowerCase(resourceType))
  const displayEventType = startCase(lowerCase(eventType))
  const status = seenAt ? 'Read' : 'Unread'

  const [
    webhookNickname,
    webhookUrl,
  ] = getMany(metaData, [
    'dashboard_webhook_nickname',
    'webhook_url',
  ])

  const webhookDisplay = webhookNickname ? webhookNickname : webhookUrl

  return removeUndefined({
    applicationId,
    applicationName,
    eventType,
    displayEventType,
    merchantId,
    merchantName,
    notificationType,
    platformId,
    platformName,
    resourceId,
    resourceType,
    displayResourceType,
    seenAt: !!seenAt,
    subscriptionId,
    id,
    displayCreatedAt,
    createdAt,
    message,
    status,
    metaData,
    webhookNickname,
    webhookUrl,
    webhookDisplay,
  })
}

export default frontendNotificationModel
