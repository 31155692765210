import {
  fieldEmpty,
  validatePhoneNumber,
} from 'utilities/validate'

const validateSetupMFASMSForm = ({ phoneNumber }) => ({
  phoneNumber: fieldEmpty(phoneNumber, 'Phone Number') || validatePhoneNumber({ phoneNumber }),
})

export default validateSetupMFASMSForm
