import getIdentityMerchantsAPI from 'api/finix/get/getIdentityMerchantsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import get from 'lodash/get'
import co from 'co'

function * getUnderwritingIdentityMerchantsO ({
  id,
  credentials,
}) {
  const merchantsResponse = yield getIdentityMerchantsAPI({ values: { identityId: id }, credentials })
  const merchants = get(merchantsResponse, 'data._embedded.merchants')

  const identity = {
    id,
    merchants: frontendMerchantsModel({ data: merchants }),
  }

  return createSuccessResponse({
    data: [identity],
  })
}

export default co.wrap(getUnderwritingIdentityMerchantsO)
