import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateRecipientSettingsForm from './UpdateRecipientSettingsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const underwritingProfile = get(props, 'underwritingProfile')

  const [
    underwritingProfileId,
    personalRecipientsEnabled,
    businessRecipientsEnabled,
  ] = getMany(underwritingProfile, [
    'id',
    'personalRecipientsEnabled',
    'businessRecipientsEnabled',
  ])

  const initialValues = {
    personalRecipientsEnabled,
    businessRecipientsEnabled,
  }

  return {
    credentials,
    underwritingProfileId,
    initialValues,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class UpdateRecipientSettingsFormC extends Component {
  render() {
    return (
      <UpdateRecipientSettingsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRecipientSettingsFormC)
