import './ComplianceInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import formatNumber from 'utilities/formatters/formatNumber'
import Loader from 'components/Shared/Loader/Loader'


import {
  COMPLIANCE_OVERDUE_FORMS_BIG_NUMBER_TOTAL,
  COMPLIANCE_FORMS_DUE_IN_30_DAYS,
  COMPLIANCE_FORMS_DUE_IN_60_DAYS,
  COMPLIANCE_AVG_TIME_TO_COMPLETION,
  COMPLIANCE_COMPLIANCE_FORMS_CREATED,
  COMPLIANCE_COMPLIANCE_FORM_STATUS_PIE_CHART,
  COMPLIANCE_OVERDUE_PCI_COMPLIANCE_FORM,
  COMPLIANCE_INCOMPLETE_PCI_COMPLIANCE_FORM,
} from 'constants/chartConstants'

import {
  TIME_FRAME,
  CREATED_AT_LABEL,
  SUMMARY,
  OVERDUE_FORMS,
  FORMS_DUE_IN_30_DAYS,
  FORMS_DUE_IN_60_DAYS,
  AVERAGE_TIME_TO_COMPLETION,
  COMPLIANCE_TRENDS,
  OVERDUE_COMPLIANCE_FORMS,
  INCOMPLETE_PCI_COMPLIANCE_FORMS,
  OVERDUE_FORMS_TOOLTIP_MSG,
  FORMS_DUE_30DAYS_TOOLTIP_MSG,
  FORMS_DUE_60DAYS_TOOLTIP_MSG,
  AVG_TIME_TO_COMPLETION_TOOLTIP_MSG,
  COMPLIANCE_TRENDS_TOOLTIP_MSG,
  OVERDUE_COMPLIANCE_FORMS_TOOLTIP_MSG,
} from 'constants/language/languageConstants'


const ComplianceInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }
  return (
    <div className='ComplianceInsights flex column'>
      <div className='time-frame-container flex space-between'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{CREATED_AT_LABEL}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>Summary of your merchant compliance forms within the filtered time frame.</p>
                <p><span className='bold'>{OVERDUE_FORMS}</span>{OVERDUE_FORMS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{FORMS_DUE_IN_30_DAYS} </span>{FORMS_DUE_30DAYS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{FORMS_DUE_IN_60_DAYS}</span>{FORMS_DUE_60DAYS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{AVERAGE_TIME_TO_COMPLETION}</span>{AVG_TIME_TO_COMPLETION_TOOLTIP_MSG}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={OVERDUE_FORMS}
            headerData={{
              name: COMPLIANCE_OVERDUE_FORMS_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FORMS_DUE_IN_30_DAYS}
            headerData={{
              name: COMPLIANCE_FORMS_DUE_IN_30_DAYS,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FORMS_DUE_IN_60_DAYS}
            headerData={{
              name: COMPLIANCE_FORMS_DUE_IN_60_DAYS,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={AVERAGE_TIME_TO_COMPLETION}
            headerData={{
              name: COMPLIANCE_AVG_TIME_TO_COMPLETION,
              formatter: (value) => `${parseFloat(value).toFixed(2)} Days`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={COMPLIANCE_TRENDS}
          classNames='compliance-trends'
          chartClassNames='compliance-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          tooltipMessage={COMPLIANCE_TRENDS_TOOLTIP_MSG}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: COMPLIANCE_COMPLIANCE_FORMS_CREATED, height: 260 },
            { name: COMPLIANCE_COMPLIANCE_FORM_STATUS_PIE_CHART, height: 260 },
          ]}
        />

        <div className='mtl'>
          <ChartC
            title={OVERDUE_COMPLIANCE_FORMS}
            name={COMPLIANCE_OVERDUE_PCI_COMPLIANCE_FORM}
            height={484}
            className='table'
            timeRange={timeRange}
            hideTitle
            showGroupBy
            showPivots
            tooltipMessage={<p>{OVERDUE_COMPLIANCE_FORMS_TOOLTIP_MSG}.</p>}
          />
        </div>

        <div className='mtl'>
          <ChartC
            title={INCOMPLETE_PCI_COMPLIANCE_FORMS}
            name={COMPLIANCE_INCOMPLETE_PCI_COMPLIANCE_FORM}
            height={280}
            className='table'
            timeRange={timeRange}
            hideTitle
            tooltipMessage={<p>Table of all merchants with compliance forms that are incomplete.</p>}
          />
        </div>
      </div>
    </div>
  )
}

ComplianceInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
}

export default ComplianceInsights
