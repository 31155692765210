import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditDashboardAccountNameForm from 'components/Customer/Forms/EditDashboardAccountNameForm/EditDashboardAccountNameForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)
  const dashboardAccountName = get(props, 'dashboardAccountName')

  const initialValues = {
    dashboardName: dashboardAccountName,
  }

  return {
    credentials,
    initialValues,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditDashboardAccountNameFormC extends Component {
  render() {
    return (
      <EditDashboardAccountNameForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDashboardAccountNameFormC)
