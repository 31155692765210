import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_LINK,
  PAYOUT_LINKS,
} from 'constants/apiConstants'

const patchPayoutLinkAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    path: PAYOUT_LINK({ payoutLinkId: id }),
    values,
    meta,
    credentials,
    service: PAYOUT_LINKS,
  })
}

export default patchPayoutLinkAPI
