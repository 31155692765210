import frontendApplePayCertModel from 'utilities/create/models/frontendApplePayCertModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendApplePayCertsModel = ({ data: certs }) => {
  const certModels = map(certs, (applicationCert) => frontendApplePayCertModel({ data: applicationCert }))

  return keyBy(certModels, 'id')
}

export default frontendApplePayCertsModel
