import './SettlementCalculatedFundingDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import formatMoney from 'utilities/formatters/formatMoney'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  LEVEL,
  AMOUNT,
} from 'constants/language/languageConstants'

const SettlementCalculatedFundingDetails = ({
  title = '',
  smallTitle = '',
  isFetching = false,
  data = [],
}) => {
  if (isFetching) {
    return <Loader />
  }

  if (!isFetching && isEmpty(data)) {
    return null
  }

  return (
    <div className='SettlementCalculatedFundingDetails'>
      { title && <h4>{title}</h4> }
      { smallTitle && <h5>{smallTitle}</h5> }

      <div className='flex header'>
        <div className='bold'>{LEVEL}</div>
        <div className='bold'>{AMOUNT}</div>
      </div>

      { map(data, ({ amount, currency, level }) => {
        return (
          <div className='flex item'>
            <div>{startCase(toLower(level))}</div>
            <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount, currency })} currency={currency} />
          </div>
        )
      })}
    </div>
  )
}

SettlementCalculatedFundingDetails.propTypes = {
  title: PropTypes.string,
  smallTitle: PropTypes.string,
  isFetching: PropTypes.bool,
  data: PropTypes.array,
}

export default SettlementCalculatedFundingDetails
