import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProfileInfo from './ProfileInfo'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUserRequest from 'utilities/actions/get/getCurrentUserRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getMany from 'utilities/get/getMany'
import { POST_DASHBOARD_USER_MFA_RESET_F_REQUEST } from 'constants/flowConstants'
import { FETCHING } from 'constants/reducerConstants'
import { SHOW_MFA } from 'constants/featureFlagConstants'
import get from 'lodash/get'

import {
  CHANGE_PASSWORD_MODAL,
  EDIT_USER_INFO_MODAL,
  SETUP_MFA_MODAL,
} from 'constants/modalConstants'

import {
  ID,
  NAME,
  EMAIL,
  SECURITY,
  LAST_ACCESSED,
  PASSWORD,
  CHANGE_PASSWORD,
  PERSONAL_INFO,
  EDIT,
  MULTI_FACTOR_AUTHENTICATION_MFA,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const [
    currentUserEmail,
    currentUserID,
    fullName,
  ] = getMany(props, [
    'currentUserEmail',
    'currentUserID',
    'fullName',
  ])

  const isFetching = get(state, `credentialsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const lastActivity = get(credentials, 'displayLastActivity')
  const dashboardUserId = get(credentials, 'userId')
  const currentUser = getCurrentUser(state)
  const mfaEnabled = get(currentUser, 'mfaEnabled')

  return {
    currentUserEmail,
    currentUserID,
    fullName,
    lastActivity,
    isFetching,
    credentials,
    dashboardUserId,
    mfaEnabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showChangePasswordModal: (modalProps) => dispatch(showModalAction({ modalType: CHANGE_PASSWORD_MODAL, modalProps })),
    showEditUserInfoModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_USER_INFO_MODAL, modalProps })),
    showSetupMFAModal: () => dispatch(showModalAction({ modalType: SETUP_MFA_MODAL, className: 'modal-md no-pad' })),
    resetMFA: () => {
      dispatch({
        type: POST_DASHBOARD_USER_MFA_RESET_F_REQUEST,
        meta: {
          successCallback: () => {
            dispatch(getCurrentUserRequest({
              credentials: getCurrentCredentials(),
              currentUserEmail: get(getCurrentUser(), 'email'),
            }))

            dispatch(showModalAction({ modalType: SETUP_MFA_MODAL, className: 'modal-md no-pad' }))
          },
        },
      })
    },
  }
}

class ProfileInfoC extends Component {
  render() {
    const {
      currentUserID,
      fullName,
      currentUserEmail,
      lastActivity,
      showChangePasswordModal,
      showEditUserInfoModal,
      showSetupMFAModal,
      credentials,
      dashboardUserId,
      mfaEnabled,
      resetMFA,
    } = this.props

    const mfaJSX = mfaEnabled
      ? (<div className='mfa'>Enabled <a className='text-link' onClick={resetMFA}>Reset MFA</a></div>)
      : (<div className='mfa'>Not Enabled <a className='text-link' onClick={showSetupMFAModal}>Set Up MFA</a></div>)

    const profileInfoCardsData = [
      {
        largeTitle: PERSONAL_INFO,
        idKey: ID,
        idValue: currentUserID,
        headerActions: [
          {
            label: EDIT,
            buttonClassName: 'edit-dashboard-user-button',
            // TODO: onCLick seems anti-pattern, I would expect this to be called action so we might need to change it in DataSectionV2 view component
            onClick: () => showEditUserInfoModal({ credentials, dashboardUserId }),
          },
        ],
        data: [
          {
            label: NAME,
            value: fullName,
          },
          {
            label: EMAIL,
            value: currentUserEmail,
          },
        ],
      },
      {
        largeTitle: SECURITY,
        data: [
          {
            label: LAST_ACCESSED,
            value: lastActivity,
          },
          {
            label: PASSWORD,
            value: <div className='change-password-action' onClick={showChangePasswordModal}>{CHANGE_PASSWORD}</div>,
          },
          {
            label: MULTI_FACTOR_AUTHENTICATION_MFA,
            value: mfaJSX,
            condition: getFeatureFlag(SHOW_MFA),
          },
        ],
      },
    ]
    return (
      <ProfileInfo
        {...this.props}
        profileInfoCardsData={profileInfoCardsData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoC)
