import deleteSettlementEntryRequest from 'utilities/actions/delete/deleteSettlementEntryRequest'

const submitRemoveSettlementEntriesForm = (values, dispatch, props) => {
  const {
    settlementEntryIds,
    settlementId,
    selectedItemsKey,
    credentials,
    entriesData,
  } = props

  dispatch(
    deleteSettlementEntryRequest({
      settlementId,
      settlementEntryIds,
      credentials,
      selectedItemsKey,
      dispatch,
      entriesData,
    }),
  )
}

export default submitRemoveSettlementEntriesForm
