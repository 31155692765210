import isString from 'lodash/isString'

const isArrayString = (str) => {
  if (isString(str)) {
    const cleanString = str.trim()

    if (cleanString.charAt(0) === '[' && cleanString.charAt(cleanString.length - 1) === ']') {
      return true
    }
  }
  return false
}

export default isArrayString
