import './SwitchDashboardTypeFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import Button from 'components/Shared/Button/Button'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import { SWITCH_CUSTOMER_PROFILE_TYPE_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'

import {
  dashboardTypeOptions,
  DASHBOARD_TYPE_TO_DISPLAY_LABEL_MAP,
  CUSTOMER_PROFILE_TYPE_FIELD_NAME,
} from 'constants/dashboardConfigurationsConstants'

import {
  CANCEL,
  CUSTOMER_PROFILE_TYPE_LABEL,
  SWITCH_CUSTOMER_PROFILE_TYPE,
  SET_CUSTOMER_PROFILE_TYPE,
} from 'constants/language/languageConstants'

const SwitchDashboardTypeForm = ({
  handleSubmit = () => {},
  pristine = false,
  closeModal = () => {},
  displayBusinessName = '',
  dashboardType = '',
}) => {
  const noDashboardType = isEmpty(dashboardType)

  return (
    <form className='SwitchDashboardTypeForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <br />

      <div className='switch-dashboard-type-confirmation'>{!isEmpty(dashboardType) ?
        `Would you like to switch ${displayBusinessName} from ${DASHBOARD_TYPE_TO_DISPLAY_LABEL_MAP[dashboardType]} to a different customer profile type?` :
        `Would you like to set a customer profile type for ${displayBusinessName}?`}
      </div>

      <Field
        name={CUSTOMER_PROFILE_TYPE_FIELD_NAME}
        label={CUSTOMER_PROFILE_TYPE_LABEL}
        component={RadioButton}
        options={dashboardTypeOptions(dashboardType)}
      />
      <br />
      <div className='buttons flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={pristine} label={noDashboardType ? SET_CUSTOMER_PROFILE_TYPE : SWITCH_CUSTOMER_PROFILE_TYPE} />
      </div>
    </form>
  )
}

SwitchDashboardTypeForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: SWITCH_CUSTOMER_PROFILE_TYPE_FORM,
})(SwitchDashboardTypeForm)
