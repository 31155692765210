import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { getApplePayRegisteredMerchantDomainsSelector } from 'state-layer/selectors'
import { GET_PAYMENT_METHOD_CONFIGURATION } from 'constants/flowConstants'
import { LAPTOP_ICON } from 'constants/iconConstants'

import {
  APPLE_PAY_DOMAIN_TABLE_EMPTY_MESSAGE,
  APPLE_PAY_DOMAIN_REGISTRATIONS_TITLE,
} from 'constants/language/languageConstants'

const ApplePayDomainRegistrations = ({
  flowValues = {},
  initialQueries = {},
  isFetching = false,
  actions = [],
  selectedItemsKey,
  selectedItemActions = [],
}) => {
  return (
    <div className='ApplePayDomainRegistrations'>
      <TableC
        title='Web Domains'
        flow={GET_PAYMENT_METHOD_CONFIGURATION}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        initialQueries={initialQueries}
        selector={getApplePayRegisteredMerchantDomainsSelector}
        isFetching={isFetching}
        actions={actions}
        fileNameCSV="Apple_Pay_Merchant_Registered_Domains"
        fileResourceTitleCSV={APPLE_PAY_DOMAIN_REGISTRATIONS_TITLE}
        emptyMessage={APPLE_PAY_DOMAIN_TABLE_EMPTY_MESSAGE}
        icon={`fa fa-${LAPTOP_ICON}`}
        selectedItemsKey={selectedItemsKey}
        selectedItemActions={selectedItemActions}
      />
    </div>
  )
}

ApplePayDomainRegistrations.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  selectedItemActions: PropTypes.array,
  selectedItemsKey: PropTypes.string,
}

export default ApplePayDomainRegistrations
