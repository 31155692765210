import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DisbursementsAccountColumn from 'components/Customer/Shared/Display/Columns/DisbursementsAccountColumn/DisbursementsAccountColumn'
import { createdAtRange } from 'utilities/table/sort'
import column from 'utilities/table/column'

import {
  AMOUNT,
  CREATED_ON,
  PAYMENT_INSTRUMENT,
  PAYMENT_INSTRUMENT_NAME,
  REASON_CODE,
  RECIPIENT,
  STATE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column('ID', 'id', { className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} />,
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column(PAYMENT_INSTRUMENT_NAME, 'paymentInstrument.name', { className: 'name' }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle='Brand/Type' />,
    className: 'normal',
    formatter: ({ paymentInstrument }) => <DisbursementsAccountColumn paymentInstrument={paymentInstrument} />,
  }),
]

export default columnDescriptors
