import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { UPDATE_UNDERWRITING_REVIEW_ITEM } from 'constants/amplitudeConstants'
import { CHANGE_REQUESTED, REJECTED } from 'constants/underwritingReviewConstants'
import { PATCH_UNDERWRITING_REVIEW_F_REQUEST } from 'constants/flowConstants'
import keys from 'lodash/keys'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'

const submitRequestAdditionInfoOrRejectUnderwritingEntityForm = (values, dispatch, props) => {
  const {
    reviewId,
    credentials,
    subjectId,
    dashboardUserEmail,
    isRejectUnderwritingEntityModal,
  } = props

  const selectedEvents = get(values, 'selectedEvents', [])
  const reasonsToSubmit = []
  map(selectedEvents, (events) => {
    const truthyEvents = pickBy(events, (event) => event)
    reasonsToSubmit.push(...keys(truthyEvents))
  })

  dispatch({
    type: PATCH_UNDERWRITING_REVIEW_F_REQUEST,
    payload: {
      values: {
        id: reviewId,
        subjectId,
        state: isRejectUnderwritingEntityModal ? REJECTED : CHANGE_REQUESTED,
        reasons: reasonsToSubmit,
      },
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPDATE_UNDERWRITING_REVIEW_ITEM, {
        credentials,
        id: reviewId,
        subjectId,
        state: isRejectUnderwritingEntityModal ? REJECTED : CHANGE_REQUESTED,
        decisionBy: dashboardUserEmail,
        reasons: reasonsToSubmit,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default submitRequestAdditionInfoOrRejectUnderwritingEntityForm
