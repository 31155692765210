import paymentsAPI from 'utilities/api/paymentsAPI'
import { PLATFORMS } from 'constants/apiConstants'

const getPlatformsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: PLATFORMS,
    queries,
    credentials,
    service: PLATFORMS,
  })
}

export default getPlatformsAPI
