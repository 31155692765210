import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import MerchantACHReturnsC from 'components/Customer/Pages/Merchant/MerchantACHReturns/MerchantACHReturnsC'
import { ACH_RETURNS_RESOURCE_TITLE } from 'constants/language/languageConstants'

class MerchantExceptionsTabs extends Component {
  render() {
    const {
      merchantId,
    } = this.props

    const tabs = [
      {
        id: 'merchant-deposits',
        name: ACH_RETURNS_RESOURCE_TITLE,
        component: MerchantACHReturnsC,
        props: {
          merchantId,
        },
      },
    ]

    return (
      <div className='MerchantExceptionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default MerchantExceptionsTabs
