import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import EditDeviceConfigurationDetailsForm from './EditDeviceConfigurationDetailsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import isPatching from 'utilities/is/isPatching'
import removeUndefined from 'utilities/remove/removeUndefined'
import { EDIT_DEVICE_CONFIGURATION_DETAILS_FORM } from 'constants/formConstants'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import set from 'lodash/set'

import {
  DUMMY_V1,
  FINIX_V1,
  TRIPOS_CLOUD_V1,
} from 'constants/processorConstants'

import {
  AMOUNT_VALUE,
  PROMPT_SIGNATURE_OPTIONS,
  DEVICE_CONFIGURATIONS_SELECT_OPTIONS,
  ON_THRESHOLD_AMOUNT_VALUE,
  PROMPT_FOR_SIGNATURE_OPTIONS,
} from 'constants/deviceConstants'

import {
  EMAIL,
  PRINT,
  SMS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(EDIT_DEVICE_CONFIGURATION_DETAILS_FORM)
  const promptSignatureField = formSelector(state, 'promptSignature.value')
  const promptForSignatureField = formSelector(state, 'promptForSignature.value')
  const promptTipOnScreenFormValue = formSelector(state, 'promptTipOnScreen')
  const promptReceiptConfirmationFormValue = formSelector(state, 'promptReceiptConfirmation')
  const showReceiptSettingsError = get(state, `form.${EDIT_DEVICE_CONFIGURATION_DETAILS_FORM}.syncErrors.receiptSettingsError`)

  const [
    processor,
    gateway,
    device,
  ] = getMany(props, [
    'processor',
    'gateway',
    'device',
  ])

  const [
    deviceId,
    currentPromptSignatureValue,
    currentPercentTippingThresholdValue,
    currentPromptTipOnScreenValue,
    currentPercentOptionsValue = [],
    currentFixedOptionsValue = [],
    currentPromptReceiptConfirmationValue,
    currentDisplayAutomaticReceiptDeliveryMethodsValue,
    currentDisplayAvailableReceiptMethodsValue,
    currentSignatureThresholdAmountValue,
    currentPromptForSignatureValue,
  ] = getMany(device, [
    'id',
    'promptSignature',
    'normalizedPercentTippingThreshold',
    'promptTipOnScreen',
    'percentOptions',
    'normalizedFixedOptions',
    'promptReceiptConfirmation',
    'displayAutomaticReceiptDeliveryMethods',
    'displayAvailableReceiptMethods',
    'signatureThresholdAmount',
    'promptForSignature',
  ])

  const currentPromptSignatureLabel = get(find(PROMPT_SIGNATURE_OPTIONS, { value: currentPromptSignatureValue }), 'label')
  const currentPromptForSignatureLabel = get(find(PROMPT_FOR_SIGNATURE_OPTIONS, { value: currentPromptForSignatureValue }), 'label')
  const merchantGateway = gateway || processor
  const showSignatureAmountField = (promptSignatureField === AMOUNT_VALUE && gateway === TRIPOS_CLOUD_V1) || (promptForSignatureField === ON_THRESHOLD_AMOUNT_VALUE && includes([DUMMY_V1, FINIX_V1], merchantGateway))
  const filteredInitialValues = map(filter(DEVICE_CONFIGURATIONS_SELECT_OPTIONS, ({ visibleToCustomers, disabledForGateways }) => visibleToCustomers && !includes(disabledForGateways, merchantGateway)), ({ name }) => name)
  const [percentageOne, percentageTwo, percentageThree] = currentPercentOptionsValue
  const [amountOne, amountTwo, amountThree] = currentFixedOptionsValue
  const showReceiptAndTipConfigOptions = includes([DUMMY_V1, FINIX_V1], merchantGateway)

  const initialValues = reduce(filteredInitialValues, (acc, name) => {
    acc[name] = get(device, name)
    return acc
  }, {})

  // appending values that are not true/false
  set(initialValues, 'promptSignature', { label: currentPromptSignatureLabel, value: currentPromptSignatureValue })
  set(initialValues, 'signatureThresholdAmount', currentSignatureThresholdAmountValue || 0)
  set(initialValues, 'promptForSignature', { label: currentPromptForSignatureLabel, value: currentPromptForSignatureValue })

  return {
    credentials,
    deviceId,
    merchantGateway,
    deviceConfigurationSelectOptions: sortBy(DEVICE_CONFIGURATIONS_SELECT_OPTIONS, 'label'),
    initialValues: removeUndefined({
      ...initialValues,
      percentTippingThreshold: currentPercentTippingThresholdValue,
      promptTipOnScreen: currentPromptTipOnScreenValue,
      promptReceiptConfirmation: currentPromptReceiptConfirmationValue,
      percentageOne,
      percentageTwo,
      percentageThree,
      amountOne,
      amountTwo,
      amountThree,
      receiptDeliveryPrint: includes(currentDisplayAvailableReceiptMethodsValue, PRINT),
      receiptDeliveryEmail: includes(currentDisplayAvailableReceiptMethodsValue, EMAIL),
      receiptDeliverySms: includes(currentDisplayAvailableReceiptMethodsValue, SMS),
      automaticDeliveryPrint: includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, PRINT),
      automaticDeliveryEmail: includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, EMAIL),
      automaticDeliverySms: includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, SMS),
    }),
    isUpdating: isPatching(state),
    showPromptSignatureFields: gateway === TRIPOS_CLOUD_V1,
    showSignatureAmountField,
    showTippingSettings: promptTipOnScreenFormValue,
    showReceiptAndTipConfigOptions,
    showPromptReceiptConfirmationSettings: promptReceiptConfirmationFormValue,
    currentDisplayAvailableReceiptMethodsValue,
    currentDisplayAutomaticReceiptDeliveryMethodsValue,
    showReceiptSettingsError,
    showPromptForSignatureField: showReceiptAndTipConfigOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditDeviceConfigurationDetailsFormC extends Component {
  render() {
    return (
      <EditDeviceConfigurationDetailsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDeviceConfigurationDetailsFormC)
