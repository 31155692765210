import getMany from 'utilities/get/getMany'
import { CAN } from 'constants/countryConstants'
import map from 'lodash/map'
import size from 'lodash/size'
import get from 'lodash/get'

import {
  EMAIL_REGEX,
  HAVE_LESS_THAN_20_CHARACTERS_REGEX,
  HAVE_LESS_THAN_60_CHARACTERS_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  EMAIL_ADDRESS,
  EMAIL_LENGTH_VALIDATION_ERROR,
  FIRST_NAME_LENGTH_VALIDATION_ERROR,
  LAST_NAME_LENGTH_VALIDATION_ERROR,
  PHONE_NUMBER_LENGTH_VALIDATION_ERROR,
  TITLE_LENGTH_VALIDATION_ERROR,
} from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidRegex,
  validateDate,
  validateAddress,
  validatePercentage,
  validateMaskedTaxId,
  invalidOrEmptyRegex,
} from 'utilities/validate'

const validateLiveAccountOwnershipInfoForm = (values, props) => {
  const accessFormCountry = get(props, 'accessFormCountry')
  const [
    beneficialOwnersArray = [],
    beneficialOwnersQuestion,
    controlPerson,
  ] = getMany(values, [
    'beneficialOwners',
    'beneficialOwnersQuestion',
    'controlPerson',
  ])

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress,
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth,
    controlPersonTaxId,
    controlPersonPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'firstName',
    'lastName',
    'email',
    'personalAddress',
    'principalPercentageOwnership',
    'dateOfBirth',
    'taxId',
    'phone',
    'title',
  ])
  const controlPersonCurrentCountry = get(controlPersonPersonalAddress, 'country')

  const beneficialOwnersErrors = map(beneficialOwnersArray, (owner = {}, index) => {
    const [
      firstName,
      lastName,
      email,
      personalAddress,
      principalPercentageOwnership,
      dateOfBirth,
      taxId,
      phone,
      title,
    ] = getMany(owner, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'dateOfBirth',
      'taxId',
      'phone',
      'title',
    ])
    const ownerCurrentCountry = get(personalAddress, 'country')

    return {
      firstName: invalidOrEmptyRegex({ field: firstName, name: 'First Name', regex: HAVE_LESS_THAN_20_CHARACTERS_REGEX, customErrorMessage: FIRST_NAME_LENGTH_VALIDATION_ERROR }),
      lastName: invalidOrEmptyRegex({ field: lastName, name: 'Last Name', regex: HAVE_LESS_THAN_20_CHARACTERS_REGEX, customErrorMessage: LAST_NAME_LENGTH_VALIDATION_ERROR }),
      email: fieldEmpty(email, 'Email Address') || invalidRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }) || (size(email) > 100 && EMAIL_LENGTH_VALIDATION_ERROR),
      personalAddress: validateAddress({ address: personalAddress,
        names: {
          line1: 'Home Address Line 1',
          region: 'State',
        } }),
      dateOfBirth: validateDate({ date: dateOfBirth, noFutureDate: true, checkAdult: true }),
      principalPercentageOwnership: validatePercentage({ field: principalPercentageOwnership, name: 'Ownership Percentage', percentMin: 25 }),
      taxId: validateMaskedTaxId({ taxId, name: ownerCurrentCountry === CAN ? 'Social Insurance Number' : 'Personal Tax ID', country: ownerCurrentCountry, canBeEmpty: accessFormCountry === CAN }),
      phone: fieldEmpty(phone, 'Phone Number') || invalidRegex({ field: phone, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
      title: invalidOrEmptyRegex({ field: title, name: 'Title', regex: HAVE_LESS_THAN_60_CHARACTERS_REGEX, customErrorMessage: TITLE_LENGTH_VALIDATION_ERROR }),

    }
  })

  return {
    controlPerson: {
      firstName: invalidOrEmptyRegex({ field: controlPersonFirstName, name: 'First Name', regex: HAVE_LESS_THAN_20_CHARACTERS_REGEX, customErrorMessage: FIRST_NAME_LENGTH_VALIDATION_ERROR }),
      lastName: invalidOrEmptyRegex({ field: controlPersonLastName, name: 'Last Name', regex: HAVE_LESS_THAN_20_CHARACTERS_REGEX, customErrorMessage: LAST_NAME_LENGTH_VALIDATION_ERROR }),
      email: fieldEmpty(controlPersonEmail, 'Email Address') || invalidRegex({ field: controlPersonEmail, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }) || (size(controlPersonEmail) > 100 && EMAIL_LENGTH_VALIDATION_ERROR),
      personalAddress: validateAddress({ address: controlPersonPersonalAddress,
        names: {
          line1: 'Home Address Line 1',
          region: 'State',
        } }),
      dateOfBirth: validateDate({ date: controlPersonDateOfBirth, noFutureDate: true, checkAdult: true }),
      principalPercentageOwnership: validatePercentage({ field: controlPersonPrincipalPercentageOwnership, name: 'Ownership Percentage' }),
      taxId: validateMaskedTaxId({ taxId: controlPersonTaxId, name: controlPersonCurrentCountry === CAN ? 'Social Insurance Number' : 'Personal Tax ID', country: controlPersonCurrentCountry, canBeEmpty: accessFormCountry === CAN }),
      phone: fieldEmpty(controlPersonPhone, 'Phone Number') || invalidRegex({ field: controlPersonPhone, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
      title: invalidOrEmptyRegex({ field: controlPersonTitle, name: 'Title', regex: HAVE_LESS_THAN_60_CHARACTERS_REGEX, customErrorMessage: TITLE_LENGTH_VALIDATION_ERROR }),
    },
    beneficialOwnersQuestion: fieldEmpty(beneficialOwnersQuestion),
    beneficialOwners: beneficialOwnersErrors,
  }
}

export default validateLiveAccountOwnershipInfoForm
