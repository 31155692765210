import './PayoutPlansS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getPayoutPlansSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { PAYOUT_PLANS } from 'constants/linkConstants'
import { GET_PAYOUT_PLANS } from 'constants/flowConstants'
import { PAYOUT_PLANS_RESOURCE_TITLE } from 'constants/language/languageConstants'

const PayoutPlans = ({
  isFetching = false,
  actions = [],
  payoutPlanPath = '',
  allowedFilters = '',
  quickFilters = [],
}) => {
  return (
    <div className='PayoutPlans'>
      <HeaderV2C
        title={PAYOUT_PLANS_RESOURCE_TITLE}
      />

      <TableC
        flow={GET_PAYOUT_PLANS}
        columnDescriptors={columnDescriptors}
        selector={getPayoutPlansSelector}
        linksKey={PAYOUT_PLANS}
        isFetching={isFetching}
        path={payoutPlanPath}
        actions={actions}
        allowedFilters={allowedFilters}
        fileNameCSV='Payout_Plans'
        fileResourceTitleCSV={PAYOUT_PLANS_RESOURCE_TITLE}
        emptyMessage='No Payout Plans Available'
        quickFilters={quickFilters}
      />
    </div>
  )
}

PayoutPlans.propTypes = {
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  payoutPlanPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default PayoutPlans
