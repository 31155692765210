import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { SHOW_ONBOARDING_FORM_LINK_MODAL } from 'constants/modalConstants'
import { CREATE_ONBOARDING_FORM } from 'constants/amplitudeConstants'
import { POST_ONBOARDING_FORM_F_REQUEST } from 'constants/flowConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import reduce from 'lodash/reduce'

const submitCreateOnboardingFormDetailsForm = (values = {}, dispatch, props) => {
  const [
    credentials,
    onboardingFormBasicsValues,
  ] = getMany(props, [
    'credentials',
    'formValues.createOnboardingFormBasicsForm',
  ])

  const [
    maxTransactionAmount,
    maxACHTransactionAmount,
    paymentProcessors,
  ] = getMany(values, [
    'maxTransactionAmount',
    'maxACHTransactionAmount',
    'paymentProcessors',
  ])

  const [
    applicationId,
    country,
    merchantName,
    expirationTime,
    expiredSessionUrl,
    feeUrl,
    returnUrl,
    termsOfServiceUrl,
  ] = getMany(onboardingFormBasicsValues, [
    'applicationId.value',
    'country',
    'merchantName',
    'expirationTime',
    'expiredSessionUrl',
    'feeUrl',
    'returnUrl',
    'termsOfServiceUrl',
  ])

  // format the processors to be in the BE format
  const enabledFields = pickBy(paymentProcessors, (paymentProcessor) => paymentProcessor)
  const groupedProcessors = reduce(Object.keys(enabledFields), (total, value) => {
    const processor = value.split('-')[0]
    const gateway = value.split('-')[1]
    const currentGateways = get(total, `${processor}.gateways`, [])

    return removeUndefined(merge({}, total, {
      [processor]: {
        processor,
        gateways: gateway ? currentGateways.concat(gateway) : undefined,
        level_two_level_three_data_enabled: true,
      },
    }))
  }, {})

  const expirationInMinutes = expirationTime * 1440

  const valuesToSubmit = removeUndefined({
    application_id: applicationId,
    merchant_processors: Object.values(groupedProcessors),
    onboarding_data: {
      max_transaction_amount: maxTransactionAmount,
      ach_max_transaction_amount: maxACHTransactionAmount,
      country,
      entity: {
        business_name: merchantName,
      },
    },
    onboarding_link_details: {
      expiration_in_minutes: expirationInMinutes,
      expired_session_url: expiredSessionUrl,
      fee_details_url: feeUrl,
      return_url: returnUrl,
      terms_of_service_url: termsOfServiceUrl,
    },
  })

  dispatch({
    type: POST_ONBOARDING_FORM_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(CREATE_ONBOARDING_FORM, {
        credentials,
      }),
      successCallback: ({ newValues: onboardingFormNewValues }) => {
        const [
          onboardingFormLink,
        ] = getMany(onboardingFormNewValues, [
          'onboardingFormLink',
        ])
        updateUrlQueries({ fetchData: true })
        dispatch(showModalAction({ modalType: SHOW_ONBOARDING_FORM_LINK_MODAL, modalProps: { onboardingFormLink, isCreateNewFormFlow: true, expirationTime }, className: 'modal-md no-pad' }))
      },
    },
  })
}

export default submitCreateOnboardingFormDetailsForm
