// TODO: add unit test here
import React from 'react'
import PaymentLinkStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PaymentLinkStatus'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  FIXED_AMOUNT,
  VARIABLE_AMOUNT,
  MULTI_USE_LINK,
  ONE_TIME_LINK,
  SEND_TO_BUYER,
  SEND_TO_USER,
} from 'constants/language/languageConstants'

const stateToStatusMap = {
  ACTIVE: 'Active',
}

const frontendPaymentLinkModel = ({ data: paymentLink }) => {
  const [
    id,
    merchantId,
    applicationId,
    isMultipleUse,
    allowedPaymentMethods,
    nickname,
    items,
    paymentFrequency,
    amountType,
    currency,
    minAmount,
    maxAmount,
    totalAmount,
    subtotalAmount,
    shippingAmount,
    estimatedTaxAmount,
    discountAmount,
    tipAmount,
    collectEmail,
    collectName,
    collectPhoneNumber,
    collectShippingAddress,
    collectBillingAddress,
    expirationInMinutes,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    cartReturnUrl,
    sendReceipt,
    receiptDeliveryMethods,
    brandColor,
    accentColor,
    buttonFontColor,
    logo,
    icon,
    linkUrl,
    linkExpiresAt,
    createdAt,
    state,
    tags,
    updatedAt,
  ] = getMany(paymentLink, [
    'id',
    'merchant_id',
    'application_id',
    'is_multiple_use',
    'allowed_payment_methods',
    'nickname',
    'items',
    'payment_frequency',
    'amount_details.amount_type',
    'amount_details.currency',
    'amount_details.min_amount',
    'amount_details.max_amount',
    'amount_details.total_amount',
    'amount_details.amount_breakdown.subtotal_amount',
    'amount_details.amount_breakdown.shipping_amount',
    'amount_details.amount_breakdown.estimated_tax_amount',
    'amount_details.amount_breakdown.discount_amount',
    'amount_details.amount_breakdown.tip_amount',
    'additional_details.collect_email',
    'additional_details.collect_name',
    'additional_details.collect_phone',
    'additional_details.collect_shipping_address',
    'additional_details.collect_billing_address',
    'additional_details.expiration_in_minutes',
    'additional_details.terms_of_service_url',
    'additional_details.success_return_url',
    'additional_details.unsuccessful_return_url',
    'additional_details.expired_session_url',
    'additional_details.cart_return_url',
    'additional_details.send_receipt',
    'additional_details.receipt_requested_delivery_methods',
    'branding.brand_color',
    'branding.accent_color',
    'branding.button_font_color',
    'branding.logo',
    'branding.icon',
    'link_url',
    'link_expires_at',
    'created_at',
    'state',
    'tags',
    'updated_at',
  ])

  const normalizedItems = map(items, (item) => {
    const [
      name,
      description,
      quantity,
      primaryImageUrl,
      saleAmount,
      itemCurrency,
    ] = getMany(item, [
      'name',
      'description',
      'quantity',
      'image_details.primary_image_url',
      'price_details.sale_amount',
      'price_details.currency',
    ])

    return {
      name,
      description,
      quantity,
      primaryImageUrl,
      saleAmount,
      totalAmount: formatMoney({ amount: amountToDisplayAmount(quantity * displayAmountToAmount(saleAmount, itemCurrency), itemCurrency), currency: itemCurrency }),
      displaySaleAmount: formatMoney({ amount: saleAmount, currency: itemCurrency }),
      currency: itemCurrency,
    }
  })

  const sendReceiptToUser = !isEmpty(receiptDeliveryMethods)
  const sendToBuyer = sendReceipt ? SEND_TO_BUYER : undefined
  const sendToUser = sendReceiptToUser ? SEND_TO_USER : undefined
  const receiptRecipientsLabel = map(removeUndefined({ sendToBuyer, sendToUser }), (value) => value).join(', ')

  return removeUndefined({
    id,
    merchantId,
    applicationId,
    isMultipleUse,
    linkType: isMultipleUse ? MULTI_USE_LINK : ONE_TIME_LINK,
    allowedPaymentMethods,
    nickname,
    items: normalizedItems,
    paymentFrequency,
    amountType,
    displayAmountType: amountType === 'FIXED' ? FIXED_AMOUNT : VARIABLE_AMOUNT,
    currency,
    minAmount,
    displayMinAmount: formatMoney({ amount: minAmount, currency }),
    maxAmount,
    displayMaxAmount: formatMoney({ amount: maxAmount, currency }),
    totalAmount,
    displayTotalAmount: formatMoney({ amount: totalAmount, currency }),
    subtotalAmount,
    displaySubtotalAmount: formatMoney({ amount: subtotalAmount, currency }),
    shippingAmount,
    displayShippingAmount: formatMoney({ amount: shippingAmount, currency }),
    estimatedTaxAmount,
    displayEstimatedTaxAmount: formatMoney({ amount: estimatedTaxAmount, currency }),
    discountAmount,
    displayDiscountAmount: formatMoney({ amount: discountAmount, currency }),
    tipAmount,
    displayTipAmount: formatMoney({ amount: tipAmount, currency }),
    collectEmail,
    collectName,
    collectPhoneNumber,
    collectShippingAddress,
    collectBillingAddress,
    expirationInMinutes,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    cartReturnUrl,
    brandColor,
    accentColor,
    buttonFontColor,
    logo,
    icon,
    linkUrl,
    linkExpiresAt,
    displayLinkExpiresAt: formatDate({ date: linkExpiresAt }),
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    state,
    displayState: () => <PaymentLinkStatus value={state} />,
    tags,
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    sendReceipt,
    sendReceiptToUser,
    displayReceiptRecipients: receiptRecipientsLabel,
  })
}

export default frontendPaymentLinkModel
