import {
  fieldEmpty,
} from 'utilities/validate'

const validateDashboardFeedbackForm = (values) => {
  const { feedback } = values

  return {
    feedback: fieldEmpty(feedback, 'Feedback'),
  }
}

export default validateDashboardFeedbackForm
