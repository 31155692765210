import Payout from './Payout'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import showModalAction from 'utilities/actions/showModalAction'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import hasPermissions from 'utilities/hasPermissions'
import getMany from 'utilities/get/getMany'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { getCardBrand } from 'constants/bankConstants'
import { FETCHING } from 'constants/reducerConstants'
import { BANK_ICON } from 'constants/iconConstants'
import { APPROVED } from 'constants/settlementConstants'
import { NON_MERCHANT_ROLES } from 'constants/roleConstants'
import { REMOVE_SETTLEMENT_ENTRIES } from 'constants/modalConstants'
import { CARD_PRESENT_DEVICE_IMAGE_MAP } from 'constants/deviceConstants'
import { REFUND_TRANSACTION_PERMISSION } from 'constants/permissionConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import isEqual from 'lodash/isEqual'

import {
  getReversalsSelector,
  getTransferWithRelatedSelector,
} from 'state-layer/selectors'

import {
  PAYMENT_CARD,
  PAYMENT_CARD_PRESENT,
} from 'constants/paymentInstrumentConstants'

import {
  shouldFundMap,
  externallyFundedMap,
} from 'constants/statusConstants'

import {
  DEBIT,
  FAILED,
  REVERSAL,
  TRANSFERS_SUBTYPE_LABEL_MAP,
} from 'constants/transferConstants'

import {
  PAYOUTS_RESOURCE_TITLE,
  TRANSACTION_RESOURCE_TITLE,
  PAYOUT_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  STATE,
  EXTERNALLY_FUNDED,
  TRACE_ID,
  SHOULD_FUND,
  READY_TO_SETTLE,
  SUBTYPE,
  FEE,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  CREATE_REFUND,
  FAILURE_CODE,
  FAILURE_DESCRIPTION,
  CREATED_AT_LABEL,
  REMOVE_FROM_SETTLEMENT,
  CURRENCY,
  SETTLEMENT_RESOURCE_TITLE,
  PARENT_PAYMENT,
  TYPE,
  DEVICE_ID,
  DEVICE_NAME,
  DEVICE_MODEL,
  IDLE_MESSAGE,
  DEVICE_SERIAL_NUMBER,
  DESCRIPTION,
  TAGS,
} from 'constants/language/languageConstants'

import {
  PAYMENTS_PATH,
  PAYMENT_PATH,
  PAYOUTS_PATH,
  APPLICATION_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
  REFUNDS_PATH,
  CREATE_REFUND_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const transferId = get(props, 'params.transferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const paymentPath = PAYMENTS_PATH({ credentialId })
  const refundsPath = REFUNDS_PATH({ credentialId })
  const transferReversals = getReversalsSelector(state)

  const [
    fee = '',
    type = '',
    traceId = '',
    currency = '',
    transferState = '',
    readyToSettleAt = '',
    statementDescriptor = '',
    displayAmount = '',
    displayAmountWithCurrencyCode = '',
    displayCreatedAt = '',
    paymentInstrument = {},
    tags,
    messages,
    raw,
    displaySubtype,
    settlement,
    settlementEntry,
    shouldFund,
    externallyFunded,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    failureCode,
    failureMessage,
    displayFailureCode,
    subtype,
    parentTransfer,
    device,
  ] = getMany(transfer, [
    'fee',
    'type',
    'traceId',
    'currency',
    'state',
    'readyToSettleAt',
    'statementDescriptor',
    'displayAmount',
    'displayAmountWithCurrencyCode',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
    'messages',
    'raw',
    'displaySubtype',
    'settlement',
    'settlementEntry',
    'settlementEntry.shouldFund',
    'externallyFunded',
    'application.id',
    'application.businessName',
    'merchantIdentityId',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'failureCode',
    'failureMessage',
    'displayFailureCode',
    'subtype',
    'parentTransfer',
    'device',
  ])

  // TODO: merchantIdentity has not been properly converted to an FE model in the orchestration
  // However, I noticed a lot of places are using it as such, so it will require a full PR with testing to fix that
  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const showError = transferState === FAILED

  const [
    parentId,
    displayParentTransactionAmount,
  ] = getMany(parentTransfer, [
    'id',
    'displayAmountWithCurrencyCode',
  ])

  const parentTransactionPath = PAYMENT_PATH({ credentialId, transferId: parentId })

  const detailsSectionData = [
    {
      label: STATE,
      value: transferState,
      formatter: ({ value }) => <TransferStatus value={value} rawMessage={raw} />,
    },
    {
      label: CURRENCY,
      value: currency,
    },
    {
      label: FEE,
      value: formatMoney({ amount: fee, currency }),
    },
    {
      label: SUBTYPE,
      value: displaySubtype,
    },
    {
      label: READY_TO_SETTLE,
      value: readyToSettleAt ? formatDate({ date: readyToSettleAt }) : 'No',
    },
    {
      label: SHOULD_FUND,
      value: <ColorcodedStatus value={capitalize(String(shouldFund))} data={shouldFundMap} />,
      condition: shouldFund !== undefined,
    },
    {
      label: EXTERNALLY_FUNDED,
      value: () => <ColorcodedStatus value={capitalize(String(externallyFunded))} data={externallyFundedMap} />,
    },
    {
      label: TRACE_ID,
      value: traceId,
    },
    {
      label: FAILURE_CODE,
      value: displayFailureCode,
      condition: showError && !!displayFailureCode,
    },
    {
      label: FAILURE_DESCRIPTION,
      value: failureMessage,
      condition: showError && !!failureMessage,
    },
  ]

  const transactionType = get(paymentInstrument, 'type')
  const isCardPresent = isEqual(transactionType, PAYMENT_CARD_PRESENT)
  const showReversals = get(transfer, 'type') === DEBIT

  const [
    deviceId,
    deviceModel,
    deviceModelLabel,
    deviceName,
    idleMessage,
    serialNumber,
    deviceDescription,
    deviceTags,
  ] = getMany(device, [
    'id',
    'model',
    'displayDeviceModel',
    'name',
    'idleMessage',
    'serialNumber',
    'description',
    'tags',
  ])

  const deviceModelUrl = CARD_PRESENT_DEVICE_IMAGE_MAP[deviceModel]

  const deviceModelDisplay = (
    <div className='flex column'>
      {deviceModelLabel}
      {deviceModelUrl && <img src={deviceModelUrl} alt={deviceModelLabel} width='30%' />}
    </div>
  )

  const deviceSectionData = [
    {
      label: DEVICE_ID,
      value: deviceId,
    },
    {
      label: DEVICE_MODEL,
      value: deviceModelDisplay,
    },
    {
      label: DEVICE_NAME,
      value: deviceName,
    },
    {
      label: DESCRIPTION,
      value: deviceDescription,
    },
    {
      label: IDLE_MESSAGE,
      value: idleMessage,
    },
    {
      label: DEVICE_SERIAL_NUMBER,
      value: serialNumber,
    },
    {
      label: TAGS,
      formatter: () => TagsDisplaySection({
        data: map(deviceTags, (name, value) => ({ value, label: name })),
        emptyMessage: '-',
      }),
    },
  ]

  const isPayout = isPayoutFeature({ credentials })
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const [
    settlementId,
    settlementStatus,
    settlementException,
  ] = getMany(settlement, [
    'id',
    'status',
    'exception',
  ])

  const settlementEntryId = get(settlementEntry, 'id')
  const enableRemoveFromSettlement = settlementEntryId && settlementStatus && settlementStatus !== APPROVED && !settlementException
  const showAction = !isEmpty(paymentInstrument) && includes(NON_MERCHANT_ROLES, role) && enableRemoveFromSettlement
  const defaultLabel = isPayout ? PAYOUT_RESOURCE_TITLE : TRANSACTION_RESOURCE_TITLE
  const transactionLabel = get(TRANSFERS_SUBTYPE_LABEL_MAP, subtype, defaultLabel)

  const [
    maskedAccountNumber,
    maskedFullCardNumber,
  ] = getMany(paymentInstrument, [
    'maskedAccountNumber',
    'maskedFullCardNumber',
  ])

  const contextBarData = {
    back: {
      label: PAYOUTS_RESOURCE_TITLE,
      path: PAYOUTS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantBusinessName,
        path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: SETTLEMENT_PATH({ credentialId, settlementId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: transactionLabel,
      id: transferId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={transferState} rawMessage={raw} />,
      },
      {
        label: FAILURE_CODE,
        value: displayFailureCode,
        condition: showError,
      },
      {
        label: CREATED_AT_LABEL,
        value: displayCreatedAt,
      },
      {
        label: TYPE,
        value: transactionType,
      },
      {
        label: BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: transactionType,
          credentialId,
          paymentInstrument,
        }),
        condition: !isEmpty(paymentInstrument) && (!!maskedAccountNumber || !!maskedFullCardNumber),
      },
      {
        label: PARENT_PAYMENT,
        value: displayParentTransactionAmount,
        path: parentTransactionPath,
        condition: type === REVERSAL && !!parentId,
      },
    ],
  }

  return {
    isFetching,
    transferId,
    credentials,
    credentialId,
    type,
    transfer,
    detailsSectionData,
    paymentInstrument,
    tagsSectionData,
    messages,
    raw,
    paymentPath,
    showError,
    isPayout,
    displayAmount,
    displayAmountWithCurrencyCode,
    showAction,
    settlementId,
    settlementEntryId,
    headerData,
    contextBarData,
    isCardPresent,
    deviceSectionData,
    device,
    refundsPath,
    showReversals,
    transferReversals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
    showRemoveFromSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: REMOVE_SETTLEMENT_ENTRIES, modalProps })),
  }
}

class PayoutC extends Component {
  componentDidMount() {
    this.fetchTransfer()
  }

  componentDidUpdate(prevProps) {
    const { transferId } = this.props
    const { transferId: prevTransferId } = prevProps

    if (transferId !== prevTransferId) {
      this.fetchTransfer()
    }
  }

  fetchTransfer = () => {
    const {
      transferId,
      credentials,
      getTransfer,
    } = this.props

    getTransfer({ credentials, transferId })
  }

  render() {
    const {
      credentialId,
      type,
      transferId,
      transferReversals,
    } = this.props

    const refundActions = [
      {
        label: CREATE_REFUND,
        buttonVariant: 'secondary',
        action: () => goToPath({ pathname: CREATE_REFUND_PATH({ credentialId, transferId }) }),
        condition: type === DEBIT && hasPermissions([REFUND_TRANSACTION_PERMISSION]),
        className: 'create-refund-button',
        disabledButton: !isEmpty(transferReversals),
      },
    ]

    return (
      <Payout
        refundActions={refundActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutC)
