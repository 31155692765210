import { put, take, fork, all } from 'redux-saga/effects'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import getMany from 'utilities/get/getMany'
import redirectRequest from 'utilities/actions/redirectRequest'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'

import {
  GET_SEARCH_F_REQUEST,
  GET_SEARCH_F_SUCCESS,
  GET_SEARCH_F_FAILURE,
  GET_TRANSFERS_SEARCH,
  GET_AUTHORIZATIONS_SEARCH,
  requestF,
  successF,
  failureF,
} from 'constants/flowConstants'

const putSearchResult = function * ({
  payload = {},
  credentialId = '',
  meta = {},
}) {
  try {
    const newValues = get(payload, 'newValues')

    if (keys(newValues).length > 1) {
      yield put({
        type: GET_SEARCH_F_SUCCESS,
        payload,
        meta: {
          overwriteReducer: true,
        },
      })

      return
    }

    const id = head(keys(newValues))
    const resource = get(newValues, id)
    const identityId = get(resource, 'identityId')
    const settlementId = get(resource, 'settlementId')
    const parentTransferId = get(resource, 'parentTransfer.id')

    const { prefix, idName, path } = getIDPrefixMapping(id, resource)
    const failureCallback = get(meta, 'failureCallback')

    if (id && id !== 'undefined') { // TODO: investigate why this is happening - Justin speculates createFrontendModel
      yield put({
        type: GET_SEARCH_F_SUCCESS,
        payload,
        meta: {
          overwriteReducer: true,
        },
      })

      yield put(redirectRequest({
        path: path({
          credentialId,
          [idName]: id,
          ...(prefix === 'MU' && { identityId }), // needed for MERCHANT_PATH
          ...(prefix === 'FE' && { settlementId }), // needed for FEE_PATH
          ...(prefix === 'sp') && { transferId: parentTransferId }, // needed for SPLIT_TRANSFER_PATH
          ...(prefix === 'spr') && { transferId: parentTransferId }, // needed for SPLIT_REFUND_PATH
        }),
      }))
    } else {
      yield put({ type: GET_SEARCH_F_FAILURE, payload })

      if (failureCallback) failureCallback({ newValues })
    }
  } catch (error) {
    // TODO: catch any errors
  }
}

const searchF = function * () {
  while (true) {
    const { payload, meta } = yield take([GET_SEARCH_F_REQUEST])

    const [
      input = '',
      credentials,
      credentialId,
    ] = getMany(payload, [
      'input',
      'credentials',
      'credentials.id',
    ])

    const { type } = getIDPrefixMapping(input)

    if (type) { // block for normal resource search
      yield put({
        type: requestF(type),
        payload: {
          credentials,
          id: input,
        },
      })

      yield fork(putSearchResult, {
        payload: get(yield take([successF(type), failureF(type)]), 'payload'),
        credentialId,
        meta,
      })
    } else { // block for trace ID search (transfer or authorization)
      const actionPayload = {
        credentials,
        queries: { trace_id: input },
      }

      const transferRequest = { type: requestF(GET_TRANSFERS_SEARCH), payload: actionPayload }
      const authorizationRequest = { type: requestF(GET_AUTHORIZATIONS_SEARCH), payload: actionPayload }

      yield all([put(transferRequest), put(authorizationRequest)])

      const searchResults = yield all([
        take([successF(GET_TRANSFERS_SEARCH), failureF(GET_TRANSFERS_SEARCH)]),
        take([successF(GET_AUTHORIZATIONS_SEARCH), failureF(GET_AUTHORIZATIONS_SEARCH)]),
      ])

      const combinedAction = reduce(searchResults, (total, result) => {
        return merge({}, total, result)
      }, {})

      yield fork(putSearchResult, {
        payload: get(combinedAction, 'payload'),
        credentialId,
        meta,
      })
    }
  }
}

export default searchF
