import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { HOSTED_PAYMENTS_PAYOUT_LINK } from 'constants/apiConstants'

const getGuestCheckoutPayoutLinkAPI = ({ id }) => {
  return guestCheckoutAPI.get({
    path: HOSTED_PAYMENTS_PAYOUT_LINK({ payoutLinkId: id }),
  })
}

export default getGuestCheckoutPayoutLinkAPI
