import './IdentityBusinessFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import Button from 'components/Shared/Button/Button'
import validateIdentityBusinessForm from 'utilities/validate/validateIdentityBusinessForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { IDENTITY_BUSINESS_FORM } from 'constants/formConstants'

import {
  TELL_US_ABOUT_YOUR_BUSINESS_AND_LOCATION_TO_VERIFY,
  CREATE_IDENTITY_ADDRESS_LINE1_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_NAME_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP,
  CREATE_IDENTITY_DOING_BUSINESS_AS_TOOLTIP,
  CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP,
  CREATE_IDENTITY_TAXID_OR_SSN_TOOLTIP,
  HOSTED_ONBOARDING_SIGNUP_TEXT,
  HOSTED_ONBOARDING_SIGNUP_TITLE,
  BUSINESS_ADDRESS,
} from 'constants/language/languageConstants'

import {
  BUSINESS_TYPE_OPTIONS,
  OWNERSHIP_TYPE_OPTIONS,
} from 'constants/identityConstants'

const IdentityBusinessForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  submitting = false,
  disabled = false,
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  hideOwnershipTypeDropdown = false,
  edit = false,
}) => {
  return (
    <>
      <div className='IdentityBusinessFormHeader'>
        <h5>{HOSTED_ONBOARDING_SIGNUP_TITLE}</h5>
        <span>{HOSTED_ONBOARDING_SIGNUP_TEXT}</span>
      </div>

      <form className='IdentityBusinessForm' onSubmit={handleSubmit} onKeyPress={(event) => { return event.key === 'Enter' && event.preventDefault() }}>
        <div className='section'>
          <h5>Business Information</h5>
          <div className='section-description'>{TELL_US_ABOUT_YOUR_BUSINESS_AND_LOCATION_TO_VERIFY}</div>

          <div className='flex space-between wrap'>
            <div className='section-col'>
              <Field
                name='businessName'
                label='Legal Business Name'
                tooltip={CREATE_IDENTITY_BUSINESS_NAME_TOOLTIP}
                component={InputField}
                autofill={false}
              />

              <Field
                name='doingBusinessAs'
                label='Doing Business As'
                tooltip={CREATE_IDENTITY_DOING_BUSINESS_AS_TOOLTIP}
                component={InputField}
                autofill={false}
              />

              <Field
                name='website'
                label='Website'
                placeholder='https://example.com'
                component={InputField}
              />

              <Field
                name='businessPhone'
                label='Business Phone'
                placeholder='(123) 456-7890'
                component={InputField}
                autofill={false}
                format={formatPhone}
                parse={parsePhone}
              />

              <Field
                name='businessType'
                label='Business Type'
                tooltip={CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP}
                component={SelectField}
                options={BUSINESS_TYPE_OPTIONS}
                autofill={false}
              />

              {!hideOwnershipTypeDropdown && (
                <Field
                  name='ownershipType'
                  label='Ownership Type'
                  tooltip={CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP}
                  component={SelectField}
                  options={OWNERSHIP_TYPE_OPTIONS}
                />
              )}

              <DateFieldset
                name='incorporationDate'
                label='Date of Incorporation'
              />

              <Field
                name='businessTaxId'
                label='Tax ID (EIN) or Social Security Number (SSN)'
                placeholder='00-0000000 / 000-00-0000'
                tooltip={CREATE_IDENTITY_TAXID_OR_SSN_TOOLTIP}
                component={InputField}
                autofill={false}
                required={!edit}
              />
            </div>

            <div className='section-col'>
              <AddressC
                name='businessAddress'
                label={BUSINESS_ADDRESS}
                tooltips={{ addressLine1: CREATE_IDENTITY_ADDRESS_LINE1_TOOLTIP }}
                form={IDENTITY_BUSINESS_FORM}
                useColumn
              />
            </div>
          </div>
        </div>

        <div className='buttons flex space-between'>
          <div className='left flex flex-start'>
            { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
          </div>

          <div className='right flex flex-end'>
            <Button type='submit' label={submitLabel} submitting={submitting} disabled={disabled} variant='legacy-black' />
          </div>
        </div>
      </form>
    </>
  )
}

IdentityBusinessForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  hideOwnershipTypeDropdown: PropTypes.bool,
  edit: PropTypes.bool,
}

export default reduxForm({
  form: IDENTITY_BUSINESS_FORM,
  validate: validateIdentityBusinessForm,
  enableReinitialize: true,
})(IdentityBusinessForm)
