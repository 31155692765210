import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  MERCHANT_PROFILE,
  MERCHANT_PROFILES,
} from 'constants/apiConstants'

const patchMerchantProfileAPI = ({ values, credentials, meta }) => {
  // TODO: refactor this flow to send ID through id
  const id = get(values, 'id')
  const path = MERCHANT_PROFILE({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: MERCHANT_PROFILES,
  })
}

export default patchMerchantProfileAPI
