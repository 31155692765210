import get from 'lodash/get'
import merge from 'lodash/merge'
import head from 'lodash/head'
import keys from 'lodash/keys'
import map from 'lodash/map'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'

import {
  POST_APPLICATION_F_SUCCESS,
  POST_PAYOUT_PLAN_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS,
  POST_MERCHANT_IDENTITY_F_SUCCESS,
  PATCH_MERCHANT_IDENTITY_F_SUCCESS,
  PREV_FLOW_STEP,
  NEXT_FLOW_STEP,
} from 'constants/flowConstants'

import {
  TEMPLATES_FORM_DATA_KEY,
  SCHEDULES_FORM_DATA_KEY,
  COMBINATION_PLANS_FORM_DATA_KEY,
} from 'constants/flowsConstants'

import {
  UPDATE_FLOW_STATUS,
  HIDE_MODAL,
  RESET_FLOW_STATUS,
} from 'constants/actionConstants'

const initialState = {
  activeStep: 0,
  progress: 0,
  values: {},
}

const clearSchedulesTemplateNames = (state) => {
  const schedules = get(state, `formValues.${SCHEDULES_FORM_DATA_KEY}.schedules`)

  return {
    values: {
      schedulesForm: {
        schedules: map(schedules, schedule => omit(schedule, 'templateNames')),
      },
    },
  }
}

const clearCombinationPlansEquations = (state) => {
  const combinationPlans = get(state, `formValues.${COMBINATION_PLANS_FORM_DATA_KEY}.combinationPlans`)

  return {
    values: {
      combinationPlansForm: {
        combinationPlans: map(combinationPlans, combinationPlan => omit(combinationPlan, 'equation')),
      },
    },
  }
}

const flowsR = (state = initialState, action) => {
  const payload = get(action, 'payload', {})

  switch (action.type) {
    case UPDATE_FLOW_STATUS: {
      const formDataKey = head(keys(get(payload, 'formValues')))
      const prevFormValues = get(state, `formValues.${formDataKey}`)
      const currFormValues = get(payload, `formValues.${formDataKey}`)
      const sameFormValues = isEqual(prevFormValues, currFormValues)

      if (sameFormValues) return merge({}, omit(state, `formValues.${formDataKey}`), payload)

      switch (formDataKey) {
        case TEMPLATES_FORM_DATA_KEY: {
          const formattedSchedules = clearSchedulesTemplateNames(state)

          return merge({}, omit(state, [`formValues.${formDataKey}`, `formValues.${SCHEDULES_FORM_DATA_KEY}`]), formattedSchedules, payload)
        }

        case SCHEDULES_FORM_DATA_KEY: {
          const formattedCombinationPlans = clearCombinationPlansEquations(state)

          return merge({}, omit(state, [`formValues.${formDataKey}`, `formValues.${COMBINATION_PLANS_FORM_DATA_KEY}`]), formattedCombinationPlans, payload)
        }

        default:
          return merge({}, omit(state, `formValues.${formDataKey}`), payload)
      }
    }

    case NEXT_FLOW_STEP: {
      const {
        progress,
        activeStep,
      } = state

      const formValues = get(payload, 'formValues')
      const currentFormValues = get(state, 'formValues')
      const nextFormValues = formValues ? merge({}, currentFormValues, formValues) : currentFormValues

      return merge({}, state, {
        progress: progress + 1,
        activeStep: activeStep + 1,
        formValues: nextFormValues,
      })
    }

    case PREV_FLOW_STEP: {
      const {
        progress,
        activeStep,
      } = state

      if (activeStep === 0) return state

      return merge({}, state, {
        progress: progress - 1,
        activeStep: activeStep - 1,
      })
    }

    case POST_APPLICATION_F_SUCCESS:
    case POST_PAYOUT_PLAN_F_SUCCESS:
    case POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
    case POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS:
    case POST_MERCHANT_IDENTITY_F_SUCCESS:
    case PATCH_MERCHANT_IDENTITY_F_SUCCESS:
    case RESET_FLOW_STATUS:
      return initialState

    default:
      return state
  }
}

export default flowsR
