import co from 'co'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getAccessFormFileO from 'state-layer/orchestration/get/getAccessFormFileO'
import getAccessFormAPI from 'api/dashboard/get/getAccessFormAPI'
import getGuestAccessFormAPI from 'api/guestDashboard/get/getGuestAccessFormAPI'
import frontendAccessFormModel from 'utilities/create/models/frontendAccessFormModel'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import map from 'lodash/map'
import get from 'lodash/get'
import normalizeResponse from 'utilities/api/normalizeResponse'

function * getAccessFormAdditionalDocumentsO ({
  id,
  meta,
  credentials,
}) {
  const { isHostedAccessForm } = meta
  const { data: accessFormData } = isHostedAccessForm ? yield getGuestAccessFormAPI({ id }) : yield getAccessFormAPI({ credentials, id, meta })
  const additionalDocuments = get(accessFormData, 'business_data.business_additional_documents', [])

  const filesResponses = yield map(additionalDocuments, ({ file_id: fileId }) => {
    return getAccessFormFileO({
      id: fileId,
      meta: { accessFormId: id, isHostedAccessForm },
    })
  })

  const additionalDocumentsFiles = reduce(filesResponses, (total, { data }) => {
    const file = get(data, '[0]')
    const fileId = get(file, 'id')

    return merge({}, total, { additionalDocumentsFiles: { [fileId]: file } })
  }, {})

  const accessForm = merge({}, accessFormData, additionalDocumentsFiles)

  const normalizedValues = normalizeResponse({ content: accessForm })

  const frontendModelValuesToSubmit = frontendAccessFormModel({ data: normalizedValues })

  return createSuccessResponse({
    data: frontendModelValuesToSubmit,
  })
}

export default co.wrap(getAccessFormAdditionalDocumentsO)
