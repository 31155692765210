import { take, put, fork, cancel, cancelled } from 'redux-saga/effects'
import getReviewQueueAPI from 'api/finix/get/getReviewQueueAPI'
import patchReviewQueueItemsO from 'state-layer/orchestration/patch/patchReviewQueueItemsO'
import normalizeQueries from 'utilities/api/normalizeQueries'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import sleep from 'utilities/sleep'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import { ACCEPTED } from 'constants/settlementConstants'

import {
  POST_MASS_SETTLEMENT_APPROVAL_F_CANCEL,
  POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST,
  POST_MASS_SETTLEMENT_APPROVAL_F_SUCCESS,
  POST_MASS_SETTLEMENT_APPROVAL_F_CANCELLED,
  POST_MASS_SETTLEMENT_APPROVAL_BATCH_F_SUCCESS,
} from 'constants/flowConstants'

const approveLoop = function * ({
  payload,
}) {
  try {
    const credentials = get(payload, 'credentials')
    const queries = get(payload, 'queries')
    let offset = 0
    let hasMore = true
    let totalAttempted = 0
    let totalApproved = 0
    let totalFailed = 0

    while (hasMore) {
      const currentQueries = merge({}, omit(queries, ['offset']), { offset })
      const normalizedCurrentQueries = normalizeQueries({ queries: currentQueries, normalizationMap: backendNormalizationMap })
      const response = yield getReviewQueueAPI({ queries: normalizedCurrentQueries, credentials })
      const reviewQueueItems = get(response, 'data._embedded.review_queue_items')

      const items = map(reviewQueueItems, ({ id }) => {
        return {
          id,
          outcome: ACCEPTED,
        }
      })

      const { data } = yield patchReviewQueueItemsO({
        values: {
          items,
        },
        queries,
        credentials,
      })

      const total = get(data, 'page.count')
      const itemsBack = get(data, 'data', [])
      // TODO: refactor even failed attempts to return data then we can split them out by failed and succeeded
      const cleanItems = itemsBack.filter((el) => isEmpty(get(el, 'errors')))

      const attempted = itemsBack.length
      const approved = cleanItems.length
      const failed = attempted - approved

      // offset increases with failed because approved settlements wouldn't be in the PENDING state anymore
      // if offset increased with attempted, each batch would skip a number of settlements because it would account for settlements that are no longer PENDING
      offset += failed
      totalAttempted += attempted
      totalApproved += approved
      totalFailed += failed

      yield put({
        type: POST_MASS_SETTLEMENT_APPROVAL_BATCH_F_SUCCESS,
        payload: {
          newValues: {
            totalAttempted,
            totalApproved,
            totalFailed,
          },
        },
      })

      if (isEmpty(itemsBack) || total <= 0 || offset >= total) {
        hasMore = false
      }

      yield sleep(30000)
    }

    yield put({
      type: POST_MASS_SETTLEMENT_APPROVAL_F_SUCCESS,
      payload: {}, // TODO: look into whether we should pass more data back on success, like who failed maybe?
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: POST_MASS_SETTLEMENT_APPROVAL_F_CANCELLED })
    }
  }
}

const postMassSettlementApprovalsF = function * () { // TODO: standardize 'mass settlement' name
  let lastTask = null

  while (true) {
    const {
      type,
      payload,
    } = yield take([
      POST_MASS_SETTLEMENT_APPROVAL_F_CANCEL,
      POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST,
    ])

    if (lastTask) {
      yield cancel(lastTask)
    }

    if (type === POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST) {
      lastTask = yield fork(approveLoop, { payload })
    }
  }
}

export default postMassSettlementApprovalsF
