import './IdentityNewS.scss'
import React from 'react'
import IdentityFormC from 'components/Customer/Forms/IdentityForm/IdentityFormC'
import submitIdentityForm from 'utilities/submit/submitIdentityForm'

const IdentityNew = () => {
  return (
    <div className='IdentityNew'>
      <div className='content-header flex space-between'>
        <h1>Create New Identity</h1>
      </div>

      <div className='content-card'>
        <IdentityFormC onSubmit={submitIdentityForm} />
      </div>
    </div>
  )
}

export default IdentityNew
