import './DeviceInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const DeviceInfo = ({
  deviceDataSection,
  deviceImageUrl,
  model,
}) => {
  return (
    <div className='DeviceInfo flex column items-center'>
      <PageSectionV2C
        className='device-info-page-section'
        columns={deviceDataSection}
      />

      <img src={deviceImageUrl} alt={model} width='50%' />
    </div>
  )
}

DeviceInfo.propTypes = {
  deviceDataSection: PropTypes.array,
  deviceImageUrl: PropTypes.string,
  model: PropTypes.string,
}

export default DeviceInfo
