import { fieldEmpty } from 'utilities/validate'
import { getState } from 'state-layer/configureStore'
import { APPLICATION } from 'constants/payoutPlanConstants'
import { APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE } from 'constants/selectedItemsConstants'
import get from 'lodash/get'
import find from 'lodash/find'

const validateApplyTemplateForm = ({
  typeApplied,
}) => {
  const typeAppliedEmpty = fieldEmpty(typeApplied, 'Type')
  const state = getState()
  const selectedApplications = get(state, `selectedItemsR.${APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE}`, {})
  const selectedApplication = find(Object.keys(selectedApplications), (application) => selectedApplications[application])

  return {
    typeApplied: typeAppliedEmpty || (typeApplied === APPLICATION && !selectedApplication && 'An application must be selected.'),
  }
}

export default validateApplyTemplateForm
