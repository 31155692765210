import React from 'react'
import { templatesConfigOptionsMap } from 'constants/payoutPlanConstants'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import map from 'lodash/map'
import filter from 'lodash/filter'

const convertTemplateConfigToSectionData = (config = {}) => {
  return mapValues(config, (resourceValues, resourceKey) => {
    const resourceConfigIncludes = get(resourceValues, 'includes', [])
    const resourceConfigExcludes = keyBy(get(resourceValues, 'excludes'), (value) => value)

    let translatedResourceValues

    if (resourceConfigIncludes[0] === '*') {
      // translate '*' + account for excluded options
      const resourceConfigOptions = map(get(templatesConfigOptionsMap, `${resourceKey}.1.templateConfigOptions`), ({ value }) => value)

      translatedResourceValues = filter(resourceConfigOptions, (value) => !resourceConfigExcludes[value])
    } else {
      translatedResourceValues = resourceConfigIncludes
    }

    return {
      label: resourceKey.split('_').map(word => capitalize(word)).join(' '), // 'dispute_transfer' -> 'Dispute Transfer'
      value: translatedResourceValues,
    }
  })
}

export default convertTemplateConfigToSectionData
