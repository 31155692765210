import './ContextBarS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { goBack } from 'state-layer/history'
import map from 'lodash/map'
import { LONG_ARROW_ALT_LEFT } from 'constants/iconConstants'

import {
  t,
  BACK,
} from 'constants/language/languageConstants'

const ContextBar = ({
  items = [],
}) => {
  return (
    <div className='ContextBar flex'>
      <div className='back'>
        <Link onClick={goBack} className='link-3'><i className={`fa ${LONG_ARROW_ALT_LEFT}`} />{t(BACK)}</Link>
      </div>

      <div className='items flex'>
        {
          map(items, ({ label, value, path, condition = true }, index) => {
            if (!value || value === '-') return null

            return condition ? (
              <div className='item flex' key={`context-item-${index}`}>
                <div className='label'>{t(label)}:</div>
                { path && <Link to={path} className='link-3'>{t(value)}</Link> }
                { !path && t(value) }
              </div>
            ) : undefined
          })
        }
      </div>
    </div>
  )
}

ContextBar.propTypes = {
  items: PropTypes.array,
}

export default ContextBar
