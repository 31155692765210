import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import MerchantPaymentsC from 'components/Customer/Pages/Merchant/MerchantPayments/MerchantPaymentsC'
import MerchantAuthorizationsC from 'components/Customer/Pages/Merchant/MerchantAuthorizations/MerchantAuthorizationsC'
import MerchantRefundsC from 'components/Customer/Pages/Merchant/MerchantRefunds/MerchantRefundsC'

import {
  PAYMENTS,
  AUTHORIZATIONS,
  REFUNDS,
} from 'constants/language/languageConstants'

class MerchantTransactionsTabs extends Component {
  render() {
    const {
      merchantId,
    } = this.props

    const tabs = [
      {
        id: 'payments',
        name: PAYMENTS,
        component: MerchantPaymentsC,
        props: {
          merchantId,
        },
      },
      {
        id: 'authorizations',
        name: AUTHORIZATIONS,
        component: MerchantAuthorizationsC,
        props: {
          merchantId,
        },
      },
      {
        id: 'refunds',
        name: REFUNDS,
        component: MerchantRefundsC,
        props: {
          merchantId,
        },
      },
    ]

    return (
      <div className='MerchantTransactionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default MerchantTransactionsTabs
