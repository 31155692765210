import { GET_TRANSFER_ATTEMPTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'
import { SUCCEEDED } from 'constants/stateConstants'

const getPayoutLinkSucceededTransferAttemptRequest = ({
  payoutLinkId,
  credentials,
  meta,
}) => createAction({
  type: GET_TRANSFER_ATTEMPTS_F_REQUEST,
  credentials,
  queries: {
    entity_id: payoutLinkId,
    state: SUCCEEDED,
  },
  meta,
})

export default getPayoutLinkSucceededTransferAttemptRequest
