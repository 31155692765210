import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIdentitySelector } from 'state-layer/selectors'
import MerchantStatements from './MerchantStatements'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import filter from 'lodash/filter'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `statementsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const isRoleMerchantView = isRoleMerchant({ credentials })
  const identityId = get(credentials, 'identityId')
  const canadaMerchant = filter(get(getIdentitySelector(state, identityId), 'merchants'), { country: 'CAN' })[0]
  const merchantId = get(props, 'merchantId') || get(canadaMerchant, 'id')

  const flowValues = {
    merchantId,
  }

  const initialQueries = {
    merchant_id: merchantId,
  }

  return {
    merchantId,
    flowValues,
    isFetching,
    initialQueries,
    credentials,
    isRoleMerchantView,
  }
}

class MerchantStatementsC extends Component {
  render() {
    return (
      <MerchantStatements {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(MerchantStatementsC)
