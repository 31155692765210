import React, { Component } from 'react'
import { connect } from 'react-redux'
import redirectRequest from 'utilities/actions/redirectRequest'
import ConfirmationForm from 'components/Customer/Forms/ConfirmationForm/ConfirmationForm'
import hideModalAction from 'utilities/actions/hideModalAction'

const mapDispatchToProps = dispatch => {
  return {
    redirectPathAction: redirectPath => dispatch(redirectRequest({ path: redirectPath })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ConfirmationFormC extends Component {
  handleConfirmationClick = () => {
    const { confirmationAction, redirectPath, redirectPathAction, closeModal } = this.props

    if (confirmationAction) {
      confirmationAction()
    }

    if (redirectPath) {
      redirectPathAction(redirectPath)
    }

    closeModal()
  }

  render() {
    return (
      <ConfirmationForm {...this.props} handleConfirmationClick={this.handleConfirmationClick} />
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(ConfirmationFormC)
