import './PayoutPlanNewS.scss'
import React from 'react'
import FlowsC from 'components/Shared/Flows/FlowsC'
import PayoutPlanFormC from 'components/Admin/Forms/PayoutPlanForm/PayoutPlanFormC'
import TemplatesFormC from 'components/Admin/Forms/TemplatesForm/TemplatesFormC'
import SchedulesFormC from 'components/Admin/Forms/SchedulesForm/SchedulesFormC'
import CombinationPlansFormC from 'components/Admin/Forms/CombinationPlansForm/CombinationPlansFormC'
import PayoutPlanNewConfirmation from 'components/Admin/Pages/PayoutPlanNew/PayoutPlanNewConfirmation'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'

import {
  PAYOUT_PLAN_FORM_DATA_KEY,
  TEMPLATES_FORM_DATA_KEY,
  SCHEDULES_FORM_DATA_KEY,
  COMBINATION_PLANS_FORM_DATA_KEY,
} from 'constants/flowsConstants'

const steps = [
  {
    name: 'Payout Plan',
    component: PayoutPlanFormC,
    props: {
      submitLabel: 'Next',
    },
    dataKey: PAYOUT_PLAN_FORM_DATA_KEY,
  },
  {
    name: 'Templates',
    component: TemplatesFormC,
    props: {
      submitLabel: 'Next',
      leftButton: true,
    },
    dataKey: TEMPLATES_FORM_DATA_KEY,
  },
  {
    name: 'Schedules',
    component: SchedulesFormC,
    props: {
      submitLabel: 'Next',
      leftButton: true,
    },
    dataKey: SCHEDULES_FORM_DATA_KEY,
  },
  {
    name: 'Combination Plans',
    component: CombinationPlansFormC,
    props: {
      submitLabel: 'Next',
      leftButton: true,
    },
    dataKey: COMBINATION_PLANS_FORM_DATA_KEY,
  },
  {
    name: 'Confirmation',
    component: PayoutPlanNewConfirmation,
    props: {
      submitLabel: 'Submit',
      leftButton: true,
    },
  },
]

const PayoutPlanNew = (props) => {
  return (
    <div className='PayoutPlanNew'>
      <HeaderC
        title='Create New Payout Plan'
        breadcrumbs
      />

      <FlowsC
        flowClassName='new-payout-plan-flows'
        steps={steps}
        {...props}
      />
    </div>
  )
}

export default PayoutPlanNew
