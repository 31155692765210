import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import CreateRoleForm from 'components/Customer/Forms/CreateRoleForm/CreateRoleForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { entityTypeToRoleMap } from 'constants/memberConstants'
import { CREATE_ROLE_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser()
  const initialValues = get(props, 'initialRoleValues')

  const formSelector = formValueSelector(CREATE_ROLE_FORM)
  const currentSelectedEvents = formSelector(state, 'selectedEvents')

  const permissionsCheckboxError = get(state, ['form', CREATE_ROLE_FORM, 'syncErrors', 'selectedEvents'])
  const formSubmitFailed = get(state, ['form', CREATE_ROLE_FORM, 'submitFailed'])
  const permissionsError = permissionsCheckboxError && formSubmitFailed && 'Role Permissions must not be empty'

  const currentQueries = parseUrlQueries()
  const entityType = get(currentQueries, 'entityType')
  const authRole = get(entityTypeToRoleMap, entityType)

  return {
    credentials,
    currentUser,
    currentSelectedEvents,
    initialValues,
    permissionsError,
    authRole,
  }
}

class CreateRoleFormC extends Component {
  render() {
    return (
      <CreateRoleForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateRoleFormC)
