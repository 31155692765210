import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SelectAddDeviceMerchantFormC from 'components/Customer/Forms/SelectAddDeviceMerchantForm/SelectAddDeviceMerchantFormC'
import submitSelectAddDeviceMerchantForm from 'utilities/submit/submitSelectAddDeviceMerchantForm'

import {
  SELECT_A_MERCHANT_TITLE,
  SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR,
} from 'constants/language/languageConstants'

const SelectAddDeviceMerchantModal = ({
  applicationId,
  applicationName,
  title = SELECT_A_MERCHANT_TITLE,
  subtitle = SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR,
  isApplicationView = false,
  canCloseModal = false,
}) => {
  return (
    <GenericModal
      className='SelectAddDeviceMerchantModal'
      title={title}
      subTitle={subtitle}
      applicationId={applicationId}
      applicationName={applicationName}
      isApplicationView={isApplicationView}
      Component={SelectAddDeviceMerchantFormC}
      onSubmit={submitSelectAddDeviceMerchantForm}
      canCloseModal={canCloseModal}
    />
  )
}

SelectAddDeviceMerchantModal.propTypes = {
  applicationId: PropTypes.string,
  applicationName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isApplicationView: PropTypes.bool,
  canCloseModal: PropTypes.bool,
}

export default SelectAddDeviceMerchantModal
