import frontendMonitoringAlertModel from 'utilities/create/models/frontendMonitoringAlertModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendMonitoringAlertsModel = ({ data: monitoringAlerts }) => {
  const monitoringAlertsModel = map(monitoringAlerts, (monitoringAlert) => frontendMonitoringAlertModel({ data: monitoringAlert }))
  return keyBy(monitoringAlertsModel, 'id')
}

export default frontendMonitoringAlertsModel
