import './SelfieChecksS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const SelfieChecks = ({
  selfieChecksData = [],
}) => {
  return (
    <div className='SelfieChecks'>
      <PageSectionV2C
        className='selfie-checks-data-section no-pad'
        columns={selfieChecksData}
      />
    </div>
  )
}

SelfieChecks.propTypes = {
  selfieChecksData: PropTypes.array,
}

export default SelfieChecks
