import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import CreatePaymentInstrumentVerificationForm from 'components/Customer/Forms/CreatePaymentInstrumentVerificationForm/CreatePaymentInstrumentVerificationForm'
import submitCreateVerificationForm from 'utilities/submit/submitCreateVerificationForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  getApplicationSelector,
  getPaymentInstrumentSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const formSelector = formValueSelector(CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM)
  const processorFieldValue = formSelector(state, 'processor')
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const applicationId = get(paymentInstrument, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const applicationProcessors = get(application, 'processors')
  const relatedProcessor = find(applicationProcessors, ({ processor }) => processor === processorFieldValue)
  const allowedCountryCodes = Object.keys(get(relatedProcessor, 'application_config.configuration_templates.country', {}))
  const shouldShowCountrySelect = !isEmpty(allowedCountryCodes)

  const allowedCountryCodeOptions = map(allowedCountryCodes, (countryCode) => ({
    label: countryCode,
    value: countryCode,
  }))

  return {
    credentials,
    processorFieldValue,
    allowedCountryCodes,
    allowedCountryCodeOptions,
    shouldShowCountrySelect,
  }
}

class CreatePaymentInstrumentVerificationFormC extends Component {
  render() {
    const { processorFieldValue, availableProcessors } = this.props
    const availableProcessorTypes = map(availableProcessors, ({ processor }) => processor)

    return (
      <CreatePaymentInstrumentVerificationForm
        {...this.props}
        message='Would you like to create a verification?'
        processorFieldValue={processorFieldValue}
        availableProcessorTypes={availableProcessorTypes}
        onSubmit={submitCreateVerificationForm}
      />
    )
  }
}

export default connect(mapStateToProps)(CreatePaymentInstrumentVerificationFormC)
