import './WebhookFailureDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  WEBHOOK_ISSUE_DETAILS,
  WEBHOOK_STOPPED_ACCEPTING_EVENTS,
  WEBHOOK_ISSUE_DESCRIPTION,
} from 'constants/language/languageConstants'

const WebhookFailureDetails = ({
  title = WEBHOOK_ISSUE_DETAILS,
  webhookLogsLink,
}) => {
  return (
    <PageSectionV2C
      className='WebhookFailureDetails failure-highlight'
      title={<div className='failure-details-title'>{title}</div>}
      asideTitle={webhookLogsLink}
    >
      <div className='failure-details-highlight'>
        <div className='subheader'>{WEBHOOK_STOPPED_ACCEPTING_EVENTS}</div>
        <div className='description'>{WEBHOOK_ISSUE_DESCRIPTION}</div>

        <div className='next-steps-msg'>
          <span className='next-steps-label'>Next Steps:</span> <span className='description'>Resolve the webhook issue and resend the unaccepted events from the Actions dropdown above. </span>
        </div>
      </div>
    </PageSectionV2C>
  )
}

WebhookFailureDetails.propTypes = {
  title: PropTypes.string,
  webhookLogsLink: PropTypes.object,
}

export default WebhookFailureDetails
