import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingRecipients from 'components/Customer/Pages/UnderwritingRecipients/UnderwritingRecipients'
import createUrl from 'utilities/create/createUrl'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import { FETCHING } from 'constants/reducerConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  UNDERWRITING,
  UNDERWRITING_RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  MANUAL_REVIEW,
  RECIPIENTS,
} from 'constants/underwritingReviewConstants'

import {
  SORT,
  LIMIT,
  OFFSET,
  createdAtNoDefaultQueries,
} from 'constants/queryConstants'

import {
  REVIEW_ID,
  APPLICATION_ID,
  IDENTITY_ID,
  RUN_ID,
  ASSIGNEE,
} from 'constants/filterConstants'

const initialQueries = {
  sort: 'updated_at,desc',
  workflow_name: RECIPIENTS,
}

const quickFilters = [
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
]

const allowedFilters = [
  APPLICATION_ID,
  ASSIGNEE,
  IDENTITY_ID,
  REVIEW_ID,
  RUN_ID,
]

const initialFilters = { state: { eq: MANUAL_REVIEW } }

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const queries = get(props, 'queries', {})
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)
  const mergedQueries = merge({}, initialQueries, queries)

  const underwritingReviewPath = ({ id, subjectId, entityId, entityType }) => createUrl({
    url: UNDERWRITING_RECIPIENT_PATH({ credentialId, reviewId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
      tab: UNDERWRITING,
    }),
  })

  return {
    initialQueries: mergedQueries,
    isFetching,
    underwritingReviewPath,
    limit,
    offset,
    sort,
    credentials,
    currentUserEmail,
    quickFilters,
    allowedFilters,
    initialFilters,
  }
}


class UnderwritingRecipientsC extends Component {
  render() {
    return (
      <div>
        <UnderwritingRecipients
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(UnderwritingRecipientsC)
