import './StartHostedMerchantOnboardingScreenS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import MerchantOnboardingWelcomeFormC from 'components/Customer/Forms/MerchantOnboardingForms/MerchantOnboardingWelcomeForm/MerchantOnboardingWelcomeFormC'

import {
  ONBOARDING_FORM_RESOURCE_TITLE,
  ONBOARDING_FORM_WELCOME_PAGE_TEXT_1,
  ONBOARDING_FORM_WELCOME_PAGE_TEXT_2,
} from 'constants/language/languageConstants'

const StartHostedMerchantOnboardingScreen = ({
  onboardingFormId = '',
  onboardingFormCountry = '',
}) => {
  return (
    <div className='StartHostedMerchantOnboardingScreen'>
      <div className='hosted-merchant-onboarding-welcome'>
        <h5>{ONBOARDING_FORM_RESOURCE_TITLE}</h5>

        <div className='text-container'>
          <div className='text-section-1 p-2'>
            {ONBOARDING_FORM_WELCOME_PAGE_TEXT_1}
          </div>

          <div className='text-section-2 p-2'>
            {ONBOARDING_FORM_WELCOME_PAGE_TEXT_2}
          </div>
        </div>

        <MerchantOnboardingWelcomeFormC
          onboardingFormId={onboardingFormId}
          onboardingFormCountry={onboardingFormCountry}
        />
      </div>
    </div>
  )
}

StartHostedMerchantOnboardingScreen.propTypes = {
  onboardingFormId: PropTypes.string,
  onboardingFormCountry: PropTypes.string,
}

export default StartHostedMerchantOnboardingScreen
