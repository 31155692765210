import { POST_DISPUTE_EVIDENCES_F_REQUEST } from 'constants/flowConstants'
import { UPLOAD_DISPUTE_EVIDENCE } from 'constants/amplitudeConstants'
import { UNPROCESSABLE_ENTITY } from 'constants/apiCodeConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'
import some from 'lodash/some'
import map from 'lodash/map'

const submitUploadEvidenceForm = (values, dispatch, props) => {
  const { file } = values
  const { id, credentials, updateUploadStatus, clearForm } = props

  const formData = new FormData()
  formData.append('file', file)

  dispatch({
    type: POST_DISPUTE_EVIDENCES_F_REQUEST,
    payload: {
      values: formData,
      credentials,
      id,
    },
    meta: {
      selectorId: id,
      actionId: sendAmplitudeActionEvent(UPLOAD_DISPUTE_EVIDENCE, {
        disputeId: id,
        fileName: get(file, 'name'),
      }),
      showErrors: false,
      successCallback: () => {
        updateUploadStatus({
          uploaded: true,
          uploadSuccess: true,
        })
      },
      errorCallback: (errors = []) => {
        clearForm()
        updateUploadStatus({
          uploaded: true,
          uploadSuccess: false,
          uploadErrors: map(errors, ({ message }) => message),
          supportUpload: !some(errors, ({ code }) => code === UNPROCESSABLE_ENTITY),
        })
      },
    },
  })
}

export default submitUploadEvidenceForm
