import frontendChartModel from 'utilities/create/models/frontendChartModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendChartsModel = ({ data: charts }) => {
  const chartsModel = map(charts, (chart) => frontendChartModel({ data: chart }))

  return keyBy(chartsModel, 'id')
}

export default frontendChartsModel
