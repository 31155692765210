import get from 'lodash/get'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import _values from 'lodash/values'
import head from 'lodash/head'
import { FETCHING } from 'constants/reducerConstants'

const mergeOverwriteTags = (state, action) => {
  const newValues = get(action, 'payload.newValues')
  const newRecord = newValues ? head(_values(newValues)) : get(action, 'payload.values')
  const id = get(newRecord, 'id')

  const omittedState = omit(state, `items.${id}.tags`)

  return merge({}, omittedState, {
    items: {
      [id]: newRecord,
    },
    [FETCHING]: false,
  })
}

export default mergeOverwriteTags
