import './DisableCustomRoleErrorS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { ROLE_PATH } from 'constants/pathConstants'

import {
  ELLIPSIS_ICON,
  TIMES_OCTAGON,
} from 'constants/iconConstants'

import {
  DISABLE_ROLE_FAILED_WARNING,
  DISABLE_ROLE_REASSIGN_MEMBERS,
  GO_TO_ROLE_MEMBERS_TAB,
  CANCEL,
} from 'constants/language/languageConstants'

const DisableCustomRoleError = ({
  closeModal = () => {},
  roleId = '',
  credentialId = '',
}) => {
  return (
    <div className='DisableCustomRoleError'>
      <br />
      <div className='role-has-members-warning'><i className={`fa fa-${TIMES_OCTAGON}`} />{DISABLE_ROLE_FAILED_WARNING}</div>
      <br />
      <div className='reassign-role-members-message'>
        To reassign current members:
        <ul>
          <li className='tab'>&#x2022; Click into the &#39;Role Members&#39; tab</li>
          <li className='tab'>&#x2022; Click on the <i className={ELLIPSIS_ICON} /> and select &#39;Edit Team Member&#39;</li>
          <li className='tab'>&#x2022; Reassign the team member to a different role</li>
          <li className='tab'>&#x2022; Repeat for all team members</li>
        </ul>
      </div>
      <br />
      <div className='disable-role-confirmation'>{DISABLE_ROLE_REASSIGN_MEMBERS}</div>

      <div className='btn-container flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Link to={`${ROLE_PATH({ credentialId, roleId })}?tab=role-members`}>
          <Button onClick={closeModal} label={GO_TO_ROLE_MEMBERS_TAB} />
        </Link>
      </div>
    </div>

  )
}

DisableCustomRoleError.propTypes = {
  closeModal: PropTypes.func,
  roleId: PropTypes.string,
  credentialId: PropTypes.string,
}

export default DisableCustomRoleError
