import './PayoutPlanContentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { ALERT_ICON } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'

import {
  PAYOUT_PLAN_EMPTY_MESSAGE,
  PAYOUT_PLAN,
} from 'constants/language/languageConstants'

const PayoutPlanContents = ({
  title = PAYOUT_PLAN,
  isFetching = false,
  payoutPlanCards = [],
  payoutPlanId,
}) => {
  return (
    <div className='PayoutPlan'>
      { !isFetching && isEmpty(payoutPlanCards) && (
        <span className='empty-message'><i className={`fa fa-${ALERT_ICON}`} />
          {PAYOUT_PLAN_EMPTY_MESSAGE}
        </span>
      )}

      { !isFetching && (
        <PageSectionV2C
          isFetching={isFetching}
          title={title}
          clickToCopies={payoutPlanId ? [{ content: payoutPlanId }] : null}
          columns={payoutPlanCards}
        />
      )}
    </div>
  )
}

PayoutPlanContents.propTypes = {
  title: PropTypes.string,
  isFetching: PropTypes.bool,
  payoutPlanCards: PropTypes.array,
  payoutPlanId: PropTypes.string,
}

export default PayoutPlanContents
