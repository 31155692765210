import React, { Component } from 'react'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'

class TooltipLabelC extends Component {
  render() {
    return (
      <TooltipLabel {...this.props} />
    )
  }
}

export default TooltipLabelC
