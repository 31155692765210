import React, { Component } from 'react'
import { connect } from 'react-redux'
import EnablePaymentLinkModal from './EnablePaymentLinkModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import { PATCH_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import { ACTIVE } from 'constants/paymentLinkConstants'

const mapStateToProps = (state, props) => {
  return {
    credentials: getCurrentCredentials(state),
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activatePaymentLink: ({ credentials, paymentLinkId }) => dispatch({
      type: PATCH_PAYMENT_LINK_F_REQUEST,
      payload: {
        id: paymentLinkId,
        values: {
          state: ACTIVE,
        },
        credentials,
      },
    }),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EnablePaymentLinkModalC extends Component {
  enablePaymentLink = () => {
    const {
      credentials,
      paymentLinkId,
      activatePaymentLink,
    } = this.props

    activatePaymentLink({ credentials, paymentLinkId })
  }

  render() {
    return (
      <EnablePaymentLinkModal
        {...this.props}
        enablePaymentLink={this.enablePaymentLink}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablePaymentLinkModalC)
