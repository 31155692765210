import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendApplicationsModel = ({ data: applications }) => {
  const applicationModels = map(applications, (application) => frontendApplicationModel({ data: application }))

  return keyBy(applicationModels, 'id')
}

export default frontendApplicationsModel
