import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_GUEST_ACCESS_FORMS_PRESIGNED_URL } from 'constants/apiConstants'

const postGuestAccessFormFilePresignedUrlAPI = ({ id, values, credentials }) => {
  return guestDashboardAPI.post({
    path: DASHBOARD_GUEST_ACCESS_FORMS_PRESIGNED_URL({ id }),
    values,
    credentials,
  })
}

export default postGuestAccessFormFilePresignedUrlAPI
