import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PhoneNumberInput from 'components/Shared/Inputs/PhoneNumberInput/PhoneNumberInput'
import { USA } from 'constants/countryConstants'

const PhoneField = ({
  name = 'phone',
  label,
  required = false,
  defaultCountry = USA,
  placeholder = '',
  classNames,
}) => {
  return (
    <Field
      name={name}
      label={label}
      component={PhoneNumberInput}
      onBlur={(e) => { e.preventDefault() }}
      defaultCountry={defaultCountry}
      required={required}
      placeholder={placeholder}
      classNames={classNames}
    />
  )
}

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultCountry: PropTypes.string,
  classNames: PropTypes.string,
}

export default PhoneField
