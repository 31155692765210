import './IdentityBankAccountInfoWithPlaidLinkFormS.scss'
import PlaidLinkC from './PlaidLinkC'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import BankFieldset from 'components/Shared/Fieldsets/BankFieldset/BankFieldset'
import Loader from 'components/Shared/Loader/Loader'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import prevFlowStep from 'utilities/prevFlowStep'
import submitOnboardingFormWithPlaidLinkedAccount from 'utilities/submit/submitOnboardingFormWithPlaidLinkedAccount'
import validateBankAccountInfoForm from 'utilities/validate/validateBankAccountInfoForm'
import { IDENTITY_BANK_ACCOUNT_INFO_FORM } from 'constants/formConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'

import {
  ADD_BANK_ACCOUNT_MANUALLY,
  BANK_ACCOUNT_INFORMATION,
  BANK_ACCOUNT_SUCCESSFULLY_LINKED_MSG,
  BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT,
  INSTANTLY_LINK_BANK_ACCOUNT,
  PREVIOUS_STEP,
  SAVE_AND_CONTINUE,
} from 'constants/language/languageConstants'

import {
  businessCheckingAccountLabelMap,
  checkingAccountLabelMap,
} from 'constants/labelConstants'

const IdentityBankAccountInfoWithPlaidLinkForm = ({
  onboardingFormCountry = '',
  handleSubmit = () => {},
  handleClick = () => {},
  showBankFieldset,
  showUploadFileModal = () => {},
  removeFile = () => {},
  fileUploadHandler = () => {},
  bankDocumentFileName = '',
  bankDocumentFileErrorMsg = '',
  isFormInvalid = false,
  onboardingFormId = '',
  applicationId = '',
  isStandaloneUnderwriting = false,
  isFetchingOnboardingFormData = false,
  isFetchingPlaidToken = false,
  plaidProcessorToken,
  plaidLinkAccountDataSection = [],
  isSubmitButtonDisabled = false,
}) => {
  const isFetching = isFetchingPlaidToken || isFetchingOnboardingFormData
  return (
    <form className='IdentityBankAccountInfoWithPlaidLinkForm' onSubmit={handleSubmit(submitOnboardingFormWithPlaidLinkedAccount)}>
      <div className='form-header'>
        <h3>{BANK_ACCOUNT_INFORMATION}</h3>
      </div>
      <div className='form-content'>
        {isFetching && <Loader />}
        { !isFetching && !showBankFieldset && (
        <>
          { plaidProcessorToken && (
          <div className='flex flex-start success-highlight-panel'>
            <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />
            <div className='icon icon-checkmark' />
            <div className='label-2'>{BANK_ACCOUNT_SUCCESSFULLY_LINKED_MSG}
              If you wish to change your selection, you can&nbsp;
              <PlaidLinkC
                id={onboardingFormId}
                country={onboardingFormCountry}
                entityType='ONBOARDING_FORM'
                className='plaid-link'
                style={{ border: 'none', padding: '0px', background: 'inherit' }}
              >link a different bank account
              </PlaidLinkC>&nbsp;
              or <a className='text-link' onClick={() => handleClick()}>add a different bank account manually</a>.
            </div>
          </div>
          )}
          <h5>{businessCheckingAccountLabelMap(onboardingFormCountry)}</h5>
          <div className='plaid'>
            <div className='label-2 secondary'>
              Instantly link and verify your business {checkingAccountLabelMap(onboardingFormCountry)} account to let us know where your business should receive payouts.
              {BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT}
            </div>

            {/* if we don't have a public token, it means we haven't linked an account, so we show the buttons to add an account */}
            { !plaidProcessorToken && (
            <div className='buttons flex space-between items-center'>
              <a className='text-link' onClick={() => handleClick()}>{ADD_BANK_ACCOUNT_MANUALLY}</a>
              <PlaidLinkC
                id={onboardingFormId}
                country={onboardingFormCountry}
                entityType='ONBOARDING_FORM'
                style={{ border: 'none', padding: '0px', background: 'inherit' }}
              >
                <Button label={INSTANTLY_LINK_BANK_ACCOUNT} />
              </PlaidLinkC>
            </div>
            )}
            {/* if we have a public token, we show the data from the linked account instead of the buttons */}
            { plaidProcessorToken && (
            <PageSectionV2C
              className='plaid-linked-account-details'
              columns={plaidLinkAccountDataSection}
            />
            )}
          </div>
        </>
        )}

        {/* we show the bank fieldset if user clicks on "add account manually" link or if we already have bank data added manually */}
        { !isFetching && showBankFieldset && (
          <>
            <h5>{businessCheckingAccountLabelMap(onboardingFormCountry)}</h5>
            <div className='label-2 secondary'>
              Provide your business {checkingAccountLabelMap(onboardingFormCountry)} account details to let us know where your business should receive payouts.
              {BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT}
              Alternatively, you can&nbsp;
              <PlaidLinkC
                id={onboardingFormId}
                country={onboardingFormCountry}
                entityType='ONBOARDING_FORM'
                className='plaid-link'
                style={{ border: 'none', padding: '0px', background: 'inherit' }}
              >link your bank account instantly
              </PlaidLinkC>.
            </div>

            <div className='bank-fieldset-container'>
              <BankFieldset
                country={onboardingFormCountry}
                showUploadFileModal={showUploadFileModal}
                removeFile={removeFile}
                bankDocumentFileName={bankDocumentFileName}
                bankDocumentFileErrorMsg={bankDocumentFileErrorMsg}
                isFormInvalid={isFormInvalid}
                directUploadFunc={fileUploadHandler}
                isDirectUpload
                onboardingFormId={onboardingFormId}
                applicationId={applicationId}
                isStandaloneUnderwriting={isStandaloneUnderwriting}
                isFetchingBankDocument={isFetchingOnboardingFormData}
              />
            </div>
          </>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button variant='tertiary' className='left-button' onClick={prevFlowStep} label={PREVIOUS_STEP} />
          </div>
          <div className='exit-and-save-container'>
            <Button type='submit' label={SAVE_AND_CONTINUE} disabled={isSubmitButtonDisabled} />
          </div>
        </div>
      </div>
    </form>
  )
}

IdentityBankAccountInfoWithPlaidLinkForm.propTypes = {
  onboardingFormCountry: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClick: PropTypes.func,
  showBankFieldset: PropTypes.bool,
  showUploadFileModal: PropTypes.func,
  removeFile: PropTypes.func,
  fileUploadHandler: PropTypes.func,
  bankDocumentFileName: PropTypes.string,
  bankDocumentFileErrorMsg: PropTypes.string,
  isFormInvalid: PropTypes.bool,
  onboardingFormId: PropTypes.string,
  applicationId: PropTypes.string,
  isStandaloneUnderwriting: PropTypes.bool,
  isFetchingOnboardingFormData: PropTypes.bool,
  isFetchingPlaidToken: PropTypes.bool,
  plaidProcessorToken: PropTypes.string,
  plaidLinkAccountDataSection: PropTypes.array,
  isSubmitButtonDisabled: PropTypes.bool,
}

export default reduxForm({
  form: IDENTITY_BANK_ACCOUNT_INFO_FORM,
  validate: validateBankAccountInfoForm,
})(IdentityBankAccountInfoWithPlaidLinkForm)
