import Developer from './Developer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import APIKeysC from 'components/Customer/Shared/Page/APIKeys/APIKeysC'
import WebhooksC from 'components/Customer/Pages/Webhooks/WebhooksC'
import WebhookLogsC from 'components/Customer/Pages/WebhookLogs/WebhookLogsC'
import AltPaymentMethodsC from 'components/Customer/Pages/AltPaymentMethods/AltPaymentMethodsC'
import PlatformAPIKeysC from 'components/Customer/Shared/Page/APIKeys/PlatformAPIKeysC'
import APILogsC from 'components/Customer/Pages/APILogs/APILogsC'
import FinixIntegrationC from 'components/Customer/Shared/Page/FinixIntegration/FinixIntegrationC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import hasPermissions from 'utilities/hasPermissions'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getIdentityMerchantsRequest from 'utilities/actions/get/getIdentityMerchantsRequest'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import size from 'lodash/size'
import minBy from 'lodash/minBy'

import {
  isStandaloneMerchantDashboard,
  isDisbursementsPartnerDashboard,
} from 'utilities/is/isDashboardType'

import {
  getApplicationSelector,
  getIdentityMerchantsSelector,
} from 'state-layer/selectors'

import {
  SHOW_API_LOGS,
  SHOW_WEBHOOK_LOGS,
} from 'constants/featureFlagConstants'

import {
  FINIX_V1,
  LITLE_V1,
  DUMMY_V1,
} from 'constants/processorConstants'

import {
  API_LOGS,
  API_KEYS,
  WEBHOOKS,
  WEBHOOK_LOGS,
  ALT_PAYMENT_METHODS,
  FINIX_INTEGRATION,
} from 'constants/language/languageConstants'

import {
  isRolePartner,
  isRolePlatform,
  hasPartnerPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  VIEW_ALT_PAYMENT_METHODS_PERMISSION,
  VIEW_API_LOGS_LIST_PERMISSION,
  VIEW_WEBHOOKS_LIST_PERMISSION,
} from 'constants/permissionConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const platformId = get(currentUser, 'platformId')
  const isPayout = isPayoutFeature({ credentials })
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isFetchingData = get(state, `applicationsR.${FETCHING}`) || get(state, `merchantsR.${FETCHING}`)
  const application = getApplicationSelector(state, applicationId)
  const ownerIdentityId = get(application, 'ownerIdentity.id')
  const isLiveEnvironment = isEqual(get(credentials, 'environment'), LIVE_ENVIRONMENT)
  const merchantProcessors = isLiveEnvironment ? [FINIX_V1, LITLE_V1] : DUMMY_V1
  const merchants = filter(getIdentityMerchantsSelector(state, ownerIdentityId), ({ processor, mid }) => mid && includes(merchantProcessors, processor))
  // in case there are multiple valid merchants with MIDs, we want to pick the oldest one
  const merchant = size(merchants) === 1 ? get(merchants, '[0]') : minBy(merchants, (merchantObj) => get(merchantObj, 'createdAt'))
  const merchantId = get(merchant, 'id')

  const firstTab = isStandaloneMerchant ? {
    id: 'finix-integration',
    name: FINIX_INTEGRATION,
    component: FinixIntegrationC,
    props: {
      applicationId,
      merchantId,
      isFetchingData,
    },
  } : {
    id: 'api-keys',
    name: API_KEYS,
    component: APIKeysC,
    condition: () => isRolePartner({ credentials }) && hasPermissions([VIEW_API_LOGS_LIST_PERMISSION]),
  }

  const tabs = [
    firstTab,
    {
      id: 'platform-api-keys',
      name: API_KEYS,
      component: PlatformAPIKeysC,
      condition: () => isRolePlatform({ credentials }) && hasPermissions([VIEW_API_LOGS_LIST_PERMISSION]),
      props: {
        platformId,
      },
    },
    {
      id: 'webhooks',
      name: WEBHOOKS,
      component: WebhooksC,
      condition: () => hasPartnerPlatformAccess({ credentials }) && hasPermissions([VIEW_WEBHOOKS_LIST_PERMISSION]),
    },
    {
      id: 'webhook-logs',
      name: WEBHOOK_LOGS,
      component: WebhookLogsC,
      condition: () => hasPartnerPlatformAccess({ credentials }) && getFeatureFlag(SHOW_WEBHOOK_LOGS) && hasPermissions([VIEW_WEBHOOKS_LIST_PERMISSION]),
    },
    {
      id: 'api-logs',
      name: API_LOGS,
      component: APILogsC,
      condition: () => getFeatureFlag(SHOW_API_LOGS) && hasPermissions([VIEW_API_LOGS_LIST_PERMISSION]),
    },
    {
      id: 'alt-payment-methods',
      name: ALT_PAYMENT_METHODS,
      component: AltPaymentMethodsC,
      condition: () => isRolePartner({ credentials }) && !isPayout && hasPermissions([VIEW_ALT_PAYMENT_METHODS_PERMISSION]) && !isDisbursementsPartnerDashboard(state),
      props: {
        identityId: ownerIdentityId,
      },
    },
  ]

  return {
    credentials,
    credentialId,
    applicationId,
    ownerIdentityId,
    tabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    getIdentityMerchants: ({ credentials, identityId }) => dispatch(getIdentityMerchantsRequest({ credentials, identityId })),
  }
}

class DeveloperC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplication,
      ownerIdentityId,
      getIdentityMerchants,
    } = this.props

    if (!isEmpty(applicationId)) {
      getApplication({
        credentials,
        applicationId,
      })
    }

    if (!isEmpty(ownerIdentityId)) {
      getIdentityMerchants({ credentials, identityId: ownerIdentityId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId: prevApplicationId,
      ownerIdentityId: prevOwnerIdentityId,
    } = prevProps

    const {
      credentials,
      applicationId,
      getApplication,
      getIdentityMerchants,
      ownerIdentityId,
    } = this.props

    if (!isEqual(applicationId, prevApplicationId) && !isEmpty(applicationId)) {
      getApplication({ credentials, applicationId })
    }

    if (!isEqual(ownerIdentityId, prevOwnerIdentityId) && !isEmpty(ownerIdentityId)) {
      getIdentityMerchants({ credentials, identityId: ownerIdentityId })
    }
  }


  render() {
    return (
      <Developer
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperC)
