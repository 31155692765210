import { GET_PLATFORM_PROCESSOR_CONFIG_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPlatformProcessorConfigRequest = ({
  platformId,
  processorConfigId,
  credentials,
}) => createAction({
  type: GET_PLATFORM_PROCESSOR_CONFIG_F_REQUEST,
  id: platformId,
  values: {
    processorConfigId,
  },
  credentials,
})

export default getPlatformProcessorConfigRequest
