import { getState } from 'state-layer/configureStore'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  STANDALONE_MERCHANT,
  MARKETPLACE,
  SOFTWARE_COMPANY,
  DISBURSEMENT, DISBURSEMENTS_AND_PAYMENTS,
} from 'constants/dashboardConfigurationsConstants'

export const isStandaloneMerchantDashboard = (state = getState()) => {
  const dashboardConfig = getCurrentDashboardConfig(state)

  return isEqual(get(dashboardConfig, 'dashboardType'), STANDALONE_MERCHANT)
}

export const isSoftwareCompanyDashboard = (state = getState()) => {
  const dashboardConfig = getCurrentDashboardConfig(state)

  return isEqual(get(dashboardConfig, 'dashboardType'), SOFTWARE_COMPANY)
}

export const isMarketplaceDashboard = (state = getState()) => {
  const dashboardConfig = getCurrentDashboardConfig(state)

  return isEqual(get(dashboardConfig, 'dashboardType'), MARKETPLACE)
}

export const isDisbursementsPartnerDashboard = (state = getState()) => {
  const dashboardConfig = getCurrentDashboardConfig(state)

  return isEqual(get(dashboardConfig, 'dashboardType'), DISBURSEMENT)
}

export const isDisbursementsAndPaymentsDashboard = (state = getState()) => {
  const dashboardConfig = getCurrentDashboardConfig(state)

  return isEqual(get(dashboardConfig, 'dashboardType'), DISBURSEMENTS_AND_PAYMENTS)
}
