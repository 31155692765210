import './ReactSelectCreatableS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/lib/Creatable'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import { ALERT_ICON } from 'constants/iconConstants'
import merge from 'lodash/merge'
import map from 'lodash/map'

const colors = {
  borderInputDefault: '#7D90A5',
  actionActive: '#0B5DBC',
  textPrimary: '#020F1C',
  actionHoverLight: '#E6F3FD',
  bgLight: '#F6F7F8',
}

const reactSelectCreatableDefaultStyles = {
  control: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    boxShadow: state.isFocused ? '0px 0px 1px 1px rgba(11, 93, 188, 0.50)' : 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    backgroundColor: state.isSelected ? colors?.actionHoverLight : 'none',
    color: colors?.textPrimary,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors?.bgLight,
    },
  }),
  menu: (styles = {}) => ({
    ...styles,
    border: '1px solid #7D90A5',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
}

const createOption = (label, formatValue) => ({
  label: formatValue ? formatValue(label) : label,
  value: label,
})

const ReactSelectCreatable = (props) => {
  const { input, disableDropdown, formatValue } = props

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case ' ':
      case 'Enter':
      case 'Tab':
        input.onChange([...input.value, createOption(inputValue.trim(), formatValue)])
        setInputValue('')
        event.preventDefault()
        break
      default:
        break
    }
  }

  const handleChange = (newValue) => {
    if (formatValue) {
      newValue = map(newValue, item => ({
        ...item,
        label: formatValue(item.label),
      }))
    }
    input.onChange(newValue)
  }

  const handleInputChange = (newValue) => {
    setInputValue(newValue)
  }

  const handleBlur = () => {
    if (inputValue.trim()) {
      input.onBlur([...input.value, createOption(inputValue.trim(), formatValue)])
      setInputValue('')
    } else {
      input.onBlur(input.value)
    }
  }

  const {
    className,
    label,
    tooltip = '',
    leadingIcon,
    trailingIcon,
    helperText = '',
    customStyles = {},
    meta: { active, touched, error, warning },
    maxMenuHeight,
    options,
    components,
    placeholder,
  } = props

  const { name } = input
  const errorJSX = error && (
    <span className='error'>
      <i className={`fal fa-${ALERT_ICON} error-icon`} />
      {error}
    </span>
  )
  const warningJSX = warning && (
    <div className='warning flex'>
      <i className={`fa fa-${ALERT_ICON}`} />
      <span className='p-1'>{warning}</span>
    </div>
  )
  const inputClassName = name.replace('.', '_')
  const hasIcon = leadingIcon || trailingIcon ? 'hasIcon' : ''
  const hasLeadingIcon = leadingIcon ? 'hasLeadingIcon' : ''
  const isActive = active ? 'focus' : ''
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''
  const hasWarning = warningJSX ? 'warning' : ''

  const classNames = classnames({
    ReactSelectCreatable: true,
    [inputClassName]: true,
    [className]: !!className,
    [isActive]: !!isActive,
    [hasError]: !!hasError,
    [hasWarning]: !!hasWarning,
  })

  const inputAreaClassNames = classnames({
    'input-area': true,
    [hasIcon]: !!hasIcon,
    [hasLeadingIcon]: !!hasLeadingIcon,
    [isActive]: !!isActive,
    [hasError]: !!hasError,
  })

  const styles = merge({}, reactSelectCreatableDefaultStyles, customStyles)

  return (
    <div className={classNames}>
      <div className='flex label-area'>
        {label && <label className='label-2'>{label}</label>}
        {tooltip && <TooltipLabel message={tooltip} />}
      </div>
      <div className={inputAreaClassNames}>
        {leadingIcon && <i className={`leadingIcon ${leadingIcon}`} />}
        <CreatableSelect
          name={name}
          options={options}
          value={input.value}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          isClearable
          isMulti
          menuIsOpen={!disableDropdown}
          styles={styles}
          maxMenuHeight={maxMenuHeight}
          className='CreatableSelect'
          components={
            disableDropdown ?
              {
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ...components,
              }
              : components}
          placeholder={placeholder}
        />
        {trailingIcon && <i className={`trailingIcon ${trailingIcon}`} />}
      </div>
      {(touched && errorJSX) || <div className='helperText p-2'> {helperText} </div>}
      {warningJSX && warningJSX}
    </div>
  )
}

ReactSelectCreatable.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  leadingIcon: PropTypes.string,
  trailingIcon: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.string,
  customStyles: PropTypes.object,
  options: PropTypes.array,
  components: PropTypes.object,
  maxMenuHeight: PropTypes.number,
  disableDropdown: PropTypes.bool,
  formatValue: PropTypes.func,
}

export default ReactSelectCreatable
