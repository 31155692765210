import './ApplicationIdentitiesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Identities/columnDescriptors'
import { APPLICATION_IDENTITIES } from 'constants/linkConstants'
import { GET_APPLICATION_IDENTITIES } from 'constants/flowConstants'
import { MERCHANTS_ICON } from 'constants/iconConstants'
import { getApplicationIdentitiesSelector } from 'state-layer/selectors'

const ApplicationIdentities = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  identityPath = '',
  isFetching = false,
  applicationId = '',
  actions = [],
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='ApplicationIdentities'>
      <TableC
        flow={GET_APPLICATION_IDENTITIES}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationIdentitiesSelector}
        selectorId={applicationId}
        initialQueries={initialQueries}
        linksKey={APPLICATION_IDENTITIES}
        allowedFilters={allowedFilters}
        path={identityPath}
        isFetching={isFetching}
        actions={actions}
        icon={`fa fa-${MERCHANTS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Identities`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

ApplicationIdentities.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  identityPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default ApplicationIdentities
