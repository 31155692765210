import './ApproveAllConfirmationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  APPROVE_SETTLEMENTS,
  CANCEL, DONE,
} from 'constants/language/languageConstants'

const ApproveAllConfirmationForm = ({
  message,
  closeModal,
  isApprovingAll,
  totalApproved = 0,
  totalFailed = 0,
  totalSettlementsCount = 0,
  confirmationAction,
  newApproveAll = true,
  handleFinishApproveAll,
}) => {
  const widthPercent = ((totalApproved + totalFailed) / totalSettlementsCount) * 100
  const width = `${widthPercent}%`

  return (
    <div className='ApproveAllConfirmationForm'>
      <div className='message'>{message}</div>

      <div className='massApprovalWidget flex space-between items-center'>
        <div className='massApprovalWidgetInfo flex column'>
          <span>Succeeded: <strong>{totalApproved}</strong></span>
          <span>Failed: <strong>{totalFailed}</strong></span>
        </div>

        <div className='progressBar'>
          <div className='bar' style={{ width }} />
        </div>
      </div>

      <div className='buttons flex flex-end'>
        {
          newApproveAll ? (
            <>
              <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
              <Button submitting={isApprovingAll} onClick={confirmationAction} label={APPROVE_SETTLEMENTS} />
            </>
          ) : <Button onClick={handleFinishApproveAll} label={DONE} />
        }
      </div>
    </div>
  )
}

ApproveAllConfirmationForm.propTypes = {
  message: PropTypes.object,
  closeModal: PropTypes.func,
  isApprovingAll: PropTypes.bool,
  totalApproved: PropTypes.number,
  totalFailed: PropTypes.number,
  totalSettlementsCount: PropTypes.number,
  confirmationAction: PropTypes.func,
}

export default ApproveAllConfirmationForm
