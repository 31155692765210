import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationOwnerBankAccounts from './ApplicationOwnerBankAccounts'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { PAYMENT_INSTRUMENTS_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { INSTANT_BANK_VERIFICATION_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'

import {
  ADD_BANK_ACCOUNT,
  BANK_ACCOUNT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `paymentInstrumentsR.${FETCHING}`)
  const identityId = get(props, 'identityId')
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const paymentInstrumentsPath = PAYMENT_INSTRUMENTS_PATH({ credentialId })

  const initialQueries = {
    limit: 50,
    offset: 0,
    owner_identity_id: identityId,
    instrument_type: 'BANK_ACCOUNT',
  }

  return {
    isFetching,
    credentials,
    credentialId,
    identityId,
    paymentInstrumentsPath,
    initialQueries,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showBankVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: INSTANT_BANK_VERIFICATION_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class ApplicationOwnerBankAccountsC extends Component {
  render() {
    const {
      showBankVerificationModal,
      isFetching,
      identityId,
    } = this.props

    const actions = [
      {
        label: ADD_BANK_ACCOUNT,
        action: () => showBankVerificationModal({ identityId }),
        className: 'addBankAccount',
        condition: !isFetching,
      },
    ]
    return (
      <ApplicationOwnerBankAccounts
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationOwnerBankAccountsC)
