import numeral from 'numeral'
import { fieldEmpty } from 'utilities/validate'
import { ADJUSTMENT_DECREASE } from 'constants/transferConstants'

import {
  AMOUNT,
  TYPE,
  WIRE_REFERENCE_NUMBER_VALIDATION_MSG,
} from 'constants/language/languageConstants'

const validateUpdateAccountBalanceForm = (values, props) => {
  const {
    availableAmount,
    displayAvailableAmount,
  } = props

  const {
    balanceEntryAmount,
    balanceEntryType,
    wireReferenceNumber = '',
  } = values

  const invalidAmount = numeral(balanceEntryAmount).value() > numeral(availableAmount).value()

  return {
    balanceEntryType: fieldEmpty(balanceEntryType, TYPE),
    balanceEntryAmount: fieldEmpty(balanceEntryAmount, AMOUNT) || ((invalidAmount && balanceEntryType === ADJUSTMENT_DECREASE) && `The Amount cannot exceed the current account balance of ${displayAvailableAmount}.`),
    wireReferenceNumber: wireReferenceNumber.length > 30 && WIRE_REFERENCE_NUMBER_VALIDATION_MSG,
  }
}

export default validateUpdateAccountBalanceForm
