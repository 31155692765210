import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { USD } from 'constants/currencyConstants'
import { CREATE_VIRTUAL_TERMINAL_PAYOUT } from 'constants/amplitudeConstants'
import { SEND_PAYOUT_CONFIRMATION_PATH } from 'constants/pathConstants'
import { POST_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'

import {
  FINIX_V1,
  DUMMY_V1,
} from 'constants/processorConstants'

import {
  PUSH_TO_ACH,
  PUSH_TO_CARD,
  PUSH_TO_SAME_DAY_ACH,
} from 'constants/transferConstants'

const submitSendPayoutReviewForm = (values, dispatch, props) => {
  const {
    credentials,
    formValues,
    currency = USD,
    isBankAccount,
  } = props

  const [
    amount,
    note,
    paymentInstrumentId,
    transferSpeed,
  ] = getMany(formValues, [
    'amount.amount',
    'amount.note',
    'recipient.paymentInstrumentId.value',
    'amount.transferSpeed',
  ])

  const isSameDayACHPayout = transferSpeed === PUSH_TO_SAME_DAY_ACH
  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const processor = isProductionEnv ? FINIX_V1 : DUMMY_V1

  const valuesToSubmit = removeUndefined({
    amount,
    currency,
    operationKey: isBankAccount ? PUSH_TO_ACH : PUSH_TO_CARD,
    processor: isBankAccount ? processor : undefined,
    paymentInstrumentId,
    tags: note ? {
      description: note,
    } : undefined,
  })

  const valuesToSubmitSameDayACH = removeUndefined({
    amount,
    currency,
    operationKey: PUSH_TO_SAME_DAY_ACH,
    processor: isBankAccount ? processor : undefined,
    paymentInstrumentId,
    tags: note ? {
      description: note,
    } : undefined,
  })

  dispatch({
    type: POST_TRANSFER_F_REQUEST,
    payload: {
      credentials,
      values: isSameDayACHPayout ? valuesToSubmitSameDayACH : valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const entityId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const paymentConfirmationPath = `${SEND_PAYOUT_CONFIRMATION_PATH({ credentialId, entityId })}`

        dispatch(redirectRequest({ path: paymentConfirmationPath }))
      },
      hideFlashNotification: true,
    },
    actionId: sendAmplitudeActionEvent(CREATE_VIRTUAL_TERMINAL_PAYOUT, {
      credentials,
      amount,
      currency,
      paymentInstrumentId,
    }),
  })
}

export default submitSendPayoutReviewForm
