import './PrettyJSONS.scss'
import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import JSONPretty from 'react-json-pretty'
import Button from 'components/Shared/Button/Button'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import { COPY } from 'constants/iconConstants'
import { finixApiJsonTheme } from 'constants/themeConstants'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

const PrettyJSON = ({
  data,
  classNames,
  theme = finixApiJsonTheme,
  emptyMessage,
  title = '',
  isFetching = false,
  showCopyButton = false,
}) => {
  const noData = isEmpty(data)
  const formattedData = isNil(data) ? JSON.stringify(data) : data
  const componentClassNames = classnames('PrettyJSON', { 'no-data': noData }, classNames)
  const formattedEmptyMessage = emptyMessage || `No ${title} Available`

  return (
    <div className={componentClassNames}>
      {!noData && (
      <>
        <JSONPretty data={formattedData} theme={theme} />
        {showCopyButton && <ClickToCopyC customView={<Button label={<i className={`far fa-${COPY}`} />} />} content={formattedData} />}
      </>
      )}
      {noData && !isFetching && <span className='empty-message'>{formattedEmptyMessage}</span>}
    </div>
  )
}

PrettyJSON.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  classNames: PropTypes.string,
  theme: PropTypes.object,
  emptyMessage: PropTypes.string,
  title: PropTypes.string,
  isFetching: PropTypes.bool,
  showCopyButton: PropTypes.bool,
}

export default PrettyJSON
