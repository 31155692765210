import './ComplianceFormTemplateInfoS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  TEMPLATE_INFO,
  COMPLIANCE_FORM_TEMPLATE_DESCRIPTION,
  DUPLICATE,
} from 'constants/language/languageConstants'

const ComplianceFormTemplateInfo = ({
  templateInfoSectionData = [],
  isFetching = false,
  actions = [],
  viewPDFAsideTitle = {},
  duplicateComplianceTemplatePath = '',
}) => {
  return (
    <PageSectionV2C
      className='ComplianceFormTemplateInfo compliance-form-template-info'
      isFetching={isFetching}
      title={TEMPLATE_INFO}
      asideTitle={viewPDFAsideTitle}
      subTitle={(
        <span className='subheader'>
          { COMPLIANCE_FORM_TEMPLATE_DESCRIPTION }
          <Link className='compliance-form-template-duplicate content' to={duplicateComplianceTemplatePath}>{DUPLICATE}</Link>
        </span>
)}
      columns={templateInfoSectionData}
      actions={actions}
    />
  )
}

ComplianceFormTemplateInfo.propTypes = {
  templateInfoSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  duplicateComplianceTemplatePath: PropTypes.string,
  viewPDFAsideTitle: PropTypes.object,
}

export default ComplianceFormTemplateInfo
