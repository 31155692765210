import './CaptureAuthorizationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import CaptureAuthorizationFormC from 'components/Customer/Forms/CaptureAuthorizationForm/CaptureAuthorizationFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { CAPTURE_AUTHORIZATION } from 'constants/language/languageConstants'

const CaptureAuthorizationModal = ({
  credentials = {},
  authorizationId = '',
  allowCardPresentCapture = false,
}) => {
  return (
    <GenericModal
      title={CAPTURE_AUTHORIZATION}
      className='CaptureAuthorizationModal'
      Component={CaptureAuthorizationFormC}
      authorizationId={authorizationId}
      credentials={credentials}
      allowCardPresentCapture={allowCardPresentCapture}
    />
  )
}

CaptureAuthorizationModal.propTypes = {
  credentials: PropTypes.object,
  authorizationId: PropTypes.string,
  allowCardPresentCapture: PropTypes.bool,
}
export default CaptureAuthorizationModal
