import { GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestCheckoutMerchantPaymentInstrumentsRequest = ({
  merchantId,
  meta,
}) => createAction({
  type: GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS_F_REQUEST,
  id: merchantId,
  meta,
})

export default getGuestCheckoutMerchantPaymentInstrumentsRequest
