import { GET_BALANCE_ENTRY_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getBalanceEntryRequest = ({
  balanceEntryId,
  credentials,
}) => createAction({
  type: GET_BALANCE_ENTRY_F_REQUEST,
  id: balanceEntryId,
  credentials,
})

export default getBalanceEntryRequest
