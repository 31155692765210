import './LiveAccountApplicationProcessingInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import MultiSelectField from 'components/Shared/Inputs/MultiSelectField/MultiSelectField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import validateLiveAccountApplicationProcessingInfoForm from 'utilities/validate/validateLiveAccountApplicationProcessingInfoForm'
import submitLiveAccountApplicationProcessingInfoForm from 'utilities/submit/submitLiveAccountApplicationProcessingInfoForm'
import parseNumber from 'utilities/forms/parse/parseNumber'
import formatAmountField from 'utilities/formatters/fields/formatAmountField'
import { LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM } from 'constants/formConstants'
import { selfServiceApplicationMccCodesSelectOptions } from 'constants/mccConstants'

import {
  ANNUAL_CARD_VOLUME_TOOLTIP,
  ANNUAL_TRANSACTION_COUNT,
  ANNUAL_TRANSACTION_COUNT_LOWER_LABEL,
  CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP,
  DEFAULT_STATEMENT_DESCRIPTOR,
  IN_PERSON_PAYMENTS,
  MAX_CARD_TRANSACTION_AMOUNT_TOOLTIP,
  MERCHANT_CATEGORY_CODE,
  ONLINE_PAYMENTS,
  PAYMENT_SOLUTION_NOTICE,
  PAYMENT_SOLUTION_TITLE,
  PROCESSING_INFO_SUBTITLE,
  TRANSACTION_DISTRIBUTION_SUBTITLE,
  PREVIOUS_STEP,
  SAVE_AND_CONTINUE,
  ACCEPTING_ONLINE_PAYMENTS_LOWER_LABEL,
  ACCEPTING_IN_PERSON_PAYMENTS_LOWER_LABEL,
  CARD_VOLUME,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP,
  CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP,
  CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  OTHER,
  EXIT_APPLICATION_FORM,
  PLEASE_COMPLETE_STEP_MESSAGE,
  MERCHANT_CATEGORY_CODE_TOOLTIP,
  PROCESSING_INFORMATION,
  GENERAL_OVERVIEW,
  ANNUAL_CARD_VOLUME,
  MAX_CARD_TRANSACTION_AMOUNT,
  CREATE_IDENTITY_GENERIC_ANNUAL_ACH_VOLUME_TOOLTIP,
  CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  annualACHVolumeLabelMap,
  achMaxTransactionAmountLabelMap,
} from 'constants/labelConstants'

const LiveAccountApplicationProcessingInfoForm = ({
  handleSubmit = () => {},
  mccCodeLowerLabel = '',
  paymentVolumeByCustomerTypeLowerLabel = '',
  showExitApplicationFormModal,
  isFormInvalid = false,
  goToPreviousStep = () => {},
  dirty = false,
  isHostedAccessForm = false,
  accessFormCountry = '',
}) => {
  return (
    <form className='LiveAccountApplicationProcessingInfoForm' onSubmit={handleSubmit(submitLiveAccountApplicationProcessingInfoForm)}>
      <div className='form-header'>
        <h3>{PROCESSING_INFORMATION}</h3>
        <div className='p-1'>{PROCESSING_INFO_SUBTITLE}</div>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} />}
        {!isHostedAccessForm && (
        <div className='payment-solution-content'>
          <h5>{PAYMENT_SOLUTION_TITLE}</h5>
          <Field
            name='processingData.acceptedPaymentTypes.onlinePayments'
            checkboxLabel={ONLINE_PAYMENTS}
            checkboxHelperText={ACCEPTING_ONLINE_PAYMENTS_LOWER_LABEL}
            component={CheckboxInput}
          />
          <Field
            name='processingData.acceptedPaymentTypes.inPersonPayments'
            checkboxLabel={IN_PERSON_PAYMENTS}
            checkboxHelperText={ACCEPTING_IN_PERSON_PAYMENTS_LOWER_LABEL}
            component={CheckboxInput}
          />
          <div className='payment-solution-notice'>{PAYMENT_SOLUTION_NOTICE}</div>
        </div>
        )}

        <div className='processing-info-content'>
          <h5>{GENERAL_OVERVIEW}</h5>

          <MultiSelectField
            name='processingData.mccCode'
            label={MERCHANT_CATEGORY_CODE}
            options={selfServiceApplicationMccCodesSelectOptions}
            helperText={mccCodeLowerLabel}
            hideSelectedOptions={false}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? option.label : option.value
            }}
            tooltip={MERCHANT_CATEGORY_CODE_TOOLTIP}
            tooltipPosition='right'
          />

          <Field
            name='processingData.defaultStatementDescriptor'
            label={DEFAULT_STATEMENT_DESCRIPTOR}
            component={InputField}
            tooltip={CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP}
            tooltipPosition='right'
          />

          <Field
            name='processingData.annualTransactionCount'
            label={ANNUAL_TRANSACTION_COUNT}
            component={InputField}
            helperText={ANNUAL_TRANSACTION_COUNT_LOWER_LABEL}
            format={formatAmountField}
            parse={parseNumber}
          />

          <div className='flex spce-between'>
            <AmountField
              name='processingData.annualCardVolume'
              label={ANNUAL_CARD_VOLUME}
              tooltip={ANNUAL_CARD_VOLUME_TOOLTIP}
              tooltipPosition='right'
            />

            <AmountField
              name='processingData.annualACHVolume'
              label={annualACHVolumeLabelMap(accessFormCountry)}
              tooltip={CREATE_IDENTITY_GENERIC_ANNUAL_ACH_VOLUME_TOOLTIP}
              tooltipPosition='right'
            />
          </div>

          <div className='flex spce-between'>
            <AmountField
              name='processingData.maxCardTransactionAmount'
              label={MAX_CARD_TRANSACTION_AMOUNT}
              tooltip={MAX_CARD_TRANSACTION_AMOUNT_TOOLTIP}
              tooltipPosition='right'
            />

            <AmountField
              name='processingData.maxACHTransactionAmount'
              label={achMaxTransactionAmountLabelMap(accessFormCountry)}
              tooltip={CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP}
              tooltipPosition='right'
            />
          </div>
        </div>

        <div className='card-volume-content'>
          <h5>{CARD_VOLUME}</h5>
          <div className='sub-title'>{TRANSACTION_DISTRIBUTION_SUBTITLE}</div>
          <div className='flex spce-between'>
            <PercentageField
              name='processingData.ecommercePercentage'
              label={ONLINE_PAYMENTS}
              tooltip={CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP}
              onlyWholeNumbers
              placeholder={' '}
            />

            <PercentageField
              name='processingData.cardPresentPercentage'
              label={IN_PERSON_PAYMENTS}
              tooltip={CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP}
              onlyWholeNumbers
              placeholder={' '}
            />
          </div>
        </div>

        <div className='payment-volume-by-customer-type-content'>
          <h5>{PAYMENT_VOLUME_BY_CUSTOMER_TYPE}</h5>
          <div className='sub-title'>{paymentVolumeByCustomerTypeLowerLabel}</div>
          <div className='flex spce-between'>
            <PercentageField
              name='processingData.businessToBusinessVolumePercentage'
              label={BUSINESS_TO_BUSINESS}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP}
              onlyWholeNumbers
              placeholder={' '}
            />

            <PercentageField
              name='processingData.businessToConsumerVolumePercentage'
              label={BUSINESS_TO_CONSUMER}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP}
              onlyWholeNumbers
              placeholder={' '}
            />

            <PercentageField
              name='processingData.otherVolumePercentage'
              label={OTHER}
              tooltip={CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP}
              placeholder={' '}
              onlyWholeNumbers
            />
          </div>
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => goToPreviousStep({ isFormDirty: dirty })} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          <div className='exit-and-save-container'>
            {showExitApplicationFormModal && <Button onClick={showExitApplicationFormModal} label={EXIT_APPLICATION_FORM} variant='ghost' size='large' />}
            <Button type='submit' label={SAVE_AND_CONTINUE} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

LiveAccountApplicationProcessingInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  mccCodeLowerLabel: PropTypes.string,
  paymentVolumeByCustomerTypeLowerLabel: PropTypes.string,
  showExitApplicationFormModal: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  goToPreviousStep: PropTypes.func,
  dirty: PropTypes.bool,
  isHostedAccessForm: PropTypes.bool,
}

export default reduxForm({
  form: LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM,
  validate: validateLiveAccountApplicationProcessingInfoForm,
})(LiveAccountApplicationProcessingInfoForm)
