import './RoleS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const Role = ({
  headerData = {},
  contextBarData = {},
  actions = [],
  tabs = [],
}) => {
  return (
    <div className='Role'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        actions={actions}
      />

      <TabsC
        tabs={tabs}
      />
    </div>

  )
}

Role.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  actions: PropTypes.array,
  tabs: PropTypes.array,
}

export default Role
