import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORMS } from 'constants/apiConstants'

const postAccessFormAPI = ({ values }) => {
  return dashboardAPI.post({
    path: DASHBOARD_ACCESS_FORMS,
    values,
  })
}

export default postAccessFormAPI
