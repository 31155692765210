import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionScheduleEnrollments from './SubscriptionScheduleEnrollments'
import DeleteEnrollmentsConfirmationModal from 'components/Customer/Modals/DeleteEnrollmentsConfirmationModal/DeleteEnrollmentsConfirmationModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import deleteSubscriptionEnrollmentsRequest from 'utilities/actions/delete/deleteSubscriptionEnrollmentsRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { MERCHANT_PATH } from 'constants/pathConstants'
import { MERCHANT_ID } from 'constants/filterConstants'
import { SUBSCRIPTION_SCHEDULE_ENROLLMENTS } from 'constants/selectedItemsConstants'
import { CONFIRM_ACTION } from 'constants/modalConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  getSelectedItemsByKeySelector,
  getSubscriptionScheduleEnrollmentsScheduleSelector,
} from 'state-layer/selectors'

const allowedFilters = [MERCHANT_ID]
const selectedItemsKey = SUBSCRIPTION_SCHEDULE_ENROLLMENTS

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `subscriptionEnrollmentsR.${FETCHING}`)
  const subscriptionScheduleId = get(props, 'subscriptionScheduleId')
  const selectedEnrollmentIds = Object.keys(getSelectedItemsByKeySelector(state, selectedItemsKey))
  const selectedEnrollments = map(selectedEnrollmentIds, (enrollmentId) => get(getSubscriptionScheduleEnrollmentsScheduleSelector(state, subscriptionScheduleId), enrollmentId))
  const initialQueries = { subscription_schedule_id: subscriptionScheduleId }

  const merchantPath = ({ merchant }) => {
    const id = get(merchant, 'id')
    const identityId = get(merchant, 'identityId')

    return MERCHANT_PATH({ credentialId, identityId, merchantId: id })
  }

  return {
    credentials,
    isFetching,
    subscriptionScheduleId,
    initialQueries,
    allowedFilters,
    merchantPath,
    selectedEnrollmentIds,
    selectedEnrollments,
    selectedItemsKey,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showDeleteEnrollmentsModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_ACTION, modalProps })),
    deleteEnrollments: ({ enrollmentIds, credentials, subscriptionScheduleId }) => {
      dispatch(deleteSubscriptionEnrollmentsRequest({ enrollmentIds, credentials, dispatch, selectedItemsKey, subscriptionScheduleId }))
    },
  }
}

class SubscriptionScheduleEnrollmentsC extends Component {
  // TODO: rewrite ConfirmationModal
  showDeleteEnrollmentsConfirmationModal = () => {
    const {
      showDeleteEnrollmentsModal,
      selectedEnrollmentIds,
      selectedEnrollments,
      deleteEnrollments,
      credentials,
      subscriptionScheduleId,
    } = this.props

    const message = (
      <div style={{ width: '1250px' }}>
        <p>This action deletes the following subscription enrollments. The following merchants will no longer be charged subscription fees based on these enrollments.</p>
        <p>Do you want to proceed?</p>
      </div>
    )

    const description = <DeleteEnrollmentsConfirmationModal enrollments={selectedEnrollments} />
    const confirmationAction = () => deleteEnrollments({ enrollmentIds: selectedEnrollmentIds, credentials, subscriptionScheduleId })

    showDeleteEnrollmentsModal({ message, description, confirmationAction })
  }

  render() {
    const selectedItemsAction = [
      {
        label: 'Delete Enrollments',
        action: this.showDeleteEnrollmentsConfirmationModal,
      },
    ]

    const selectedItemsData = {
      actions: selectedItemsAction,
    }

    return (
      <SubscriptionScheduleEnrollments
        selectedItemsData={selectedItemsData}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionScheduleEnrollmentsC)
