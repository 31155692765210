import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATION,
  APPLICATIONS,
} from 'constants/apiConstants'

const patchApplicationAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: APPLICATION({ applicationId: id }),
    service: APPLICATIONS,
  })
}

export default patchApplicationAPI
