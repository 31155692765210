import get from 'lodash/get'
import getMany from 'utilities/get/getMany'

import {
  SHOW_MODAL,
  HIDE_MODAL,
} from 'constants/actionConstants'

import {
  POST_IDENTITY_SETTLEMENTS_F_SUCCESS,
  POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
  POST_IDENTITY_MERCHANTS_F_SUCCESS,
  POST_IDENTITY_VERIFICATIONS_F_SUCCESS,
  POST_DASHBOARD_USER_F_SUCCESS,
  POST_APPLICATION_PROCESSORS_F_SUCCESS,
  POST_USERS_EMAIL_F_SUCCESS,
  PATCH_TRANSFER_F_SUCCESS,
  PATCH_IDENTITY_F_SUCCESS,
  PATCH_APPLICATION_F_SUCCESS,
  POST_FEE_PROFILE_F_SUCCESS,
  POST_TRANSFER_REVERSAL_F_SUCCESS,
  POST_DISPUTE_EVIDENCES_F_SUCCESS,
  PATCH_MERCHANT_F_REQUEST,
  PATCH_AUTHORIZATION_F_REQUEST,
  POST_MERCHANT_VERIFICATIONS_F_REQUEST,
  POST_MERCHANT_FEE_PROFILE_F_REQUEST,
  PATCH_PAYMENT_INSTRUMENT_F_SUCCESS,
  POST_TRANSFER_F_SUCCESS,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE,
  DELETE_SETTLEMENT_ENTRIES_F_SUCCESS,
  PATCH_SETTLEMENT_F_SUCCESS,
  PATCH_USER_PERMISSIONS_F_SUCCESS,
  PATCH_USER_F_SUCCESS,
  POST_APPLICATION_F_SUCCESS,
  POST_BALANCE_TRANSFER_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS,
  DELETE_SUBSCRIPTION_ENROLLMENT_F_SUCCESS,
  POST_FEE_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  PATCH_FEE_F_SUCCESS,
  PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_SUCCESS,
  POST_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST,
  POST_MERCHANT_FILE_F_SUCCESS,
  POST_IDENTITY_FILE_F_SUCCESS,
  POST_DASHBOARD_FEEDBACK_EMAIL_F_SUCCESS,
  POST_CONTACT_SUPPORT_EMAIL_F_SUCCESS,
  POST_CONTACT_SALES_EMAIL_F_SUCCESS,
  PATCH_COMPLIANCE_FORM_F_SUCCESS,
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS,
  PATCH_PAYOUT_SETTINGS_F_SUCCESS,
  PATCH_DISPUTE_F_SUCCESS,
  POST_DASHBOARD_USER_MFA_VERIFY_F_SUCCESS,
  PATCH_ENTITY_CONFIGURATION_F_SUCCESS,
  POST_ENTITY_CONFIGURATION_F_SUCCESS,
  PATCH_PAYMENT_LINK_F_SUCCESS,
  PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS,
  PATCH_MONITORING_ALERTS_F_SUCCESS,
  PATCH_MONITORING_ALERT_F_SUCCESS,
} from 'constants/flowConstants'

const initialState = {
  modalType: null,
  modalProps: {},
  isOpen: false,
  className: '',
  overlayClassName: '',
}

const modalR = (state = initialState, action) => {
  const payload = get(action, 'payload', {})

  const [
    modalType = '',
    modalProps = {},
    Component,
    className,
    overlayClassName,
  ] = getMany(payload, [
    'modalType',
    'modalProps',
    'Component',
    'className',
    'overlayClassName',
  ])

  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalProps,
        modalType,
        Component,
        isOpen: true,
        className,
        overlayClassName,
      }

    case HIDE_MODAL:
    case POST_IDENTITY_SETTLEMENTS_F_SUCCESS:
    case POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS:
    case POST_IDENTITY_MERCHANTS_F_SUCCESS:
    case POST_IDENTITY_VERIFICATIONS_F_SUCCESS:
    case POST_DASHBOARD_USER_F_SUCCESS:
    case POST_APPLICATION_PROCESSORS_F_SUCCESS:
    case POST_USERS_EMAIL_F_SUCCESS:
    case PATCH_TRANSFER_F_SUCCESS:
    case PATCH_IDENTITY_F_SUCCESS:
    case PATCH_APPLICATION_F_SUCCESS:
    case PATCH_PAYMENT_INSTRUMENT_F_SUCCESS:
    case POST_FEE_PROFILE_F_SUCCESS:
    case POST_TRANSFER_REVERSAL_F_SUCCESS:
    case DELETE_SETTLEMENT_ENTRIES_F_SUCCESS:
    case PATCH_MERCHANT_F_REQUEST:
    case PATCH_AUTHORIZATION_F_REQUEST:
    case POST_MERCHANT_VERIFICATIONS_F_REQUEST:
    case POST_MERCHANT_FEE_PROFILE_F_REQUEST:
    case POST_TRANSFER_F_SUCCESS:
    case POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS:
    case POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE:
    case PATCH_SETTLEMENT_F_SUCCESS:
    case PATCH_USER_PERMISSIONS_F_SUCCESS:
    case PATCH_USER_F_SUCCESS:
    case POST_BALANCE_TRANSFER_F_SUCCESS:
    case POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS:
    case DELETE_SUBSCRIPTION_ENROLLMENT_F_SUCCESS:
    case POST_FEE_F_SUCCESS:
    case POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
    case PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
    case DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
    case PATCH_FEE_F_SUCCESS:
    case POST_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST:
    case POST_IDENTITY_FILE_F_SUCCESS:
    case POST_MERCHANT_FILE_F_SUCCESS:
    case POST_DASHBOARD_FEEDBACK_EMAIL_F_SUCCESS:
    case POST_CONTACT_SUPPORT_EMAIL_F_SUCCESS:
    case POST_CONTACT_SALES_EMAIL_F_SUCCESS:
    case PATCH_COMPLIANCE_FORM_F_SUCCESS:
    case PATCH_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS:
    case PATCH_PAYOUT_SETTINGS_F_SUCCESS:
    case PATCH_DISPUTE_F_SUCCESS:
    case POST_DASHBOARD_USER_MFA_VERIFY_F_SUCCESS:
    case PATCH_ENTITY_CONFIGURATION_F_SUCCESS:
    case POST_ENTITY_CONFIGURATION_F_SUCCESS:
    case PATCH_PAYMENT_LINK_F_SUCCESS:
    case PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS:
    case PATCH_MONITORING_ALERTS_F_SUCCESS:
    case PATCH_MONITORING_ALERT_F_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default modalR
