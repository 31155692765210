import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendMerchantsModel = ({ data: merchants }) => {
  const merchantModels = map(merchants, (merchant) => frontendMerchantModel({ data: merchant }))

  return keyBy(merchantModels, 'id')
}

export default frontendMerchantsModel
