import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getPageName from 'utilities/get/getPageName'
import { ALL } from 'constants/transferConstants'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import omitBy from 'lodash/omitBy'
import trim from 'lodash/trim'
import some from 'lodash/some'
import includes from 'lodash/includes'
import map from 'lodash/map'
import keys from 'lodash/keys'
import flatten from 'lodash/flatten'
import find from 'lodash/find'
import isString from 'lodash/isString'
import join from 'lodash/join'

import {
  ADD_TABLE_FILTER,
  REMOVE_TABLE_FILTER,
} from 'constants/flowConstants'

import {
  EQUALS,
  LESS_THAN_EQUALS,
  GREATER_THAN_EQUALS,
  FILTER_FORMATTERS,
  IS_BETWEEN,
  ON,
  DATE_FILTERS,
} from 'constants/filterConstants'

import {
  ADD_TABLE_FILTER as ADD_TABLE_FILTER_EVENT,
  REMOVE_TABLE_FILTER as REMOVE_TABLE_FILTER_EVENT,
} from 'constants/amplitudeConstants'

const submitTableFiltersForm = (values = {}, dispatch, props = {}) => {
  const {
    linksKey,
    initialValues,
    checkedFilters,
    toggleFiltersMenu = () => {},
    quickFilters,
    filterSets,
    allowedFilters,
  } = props

  const pathname = get(initialValues, 'pathname')
  const pathName = window.location.pathname.slice(1)
  const queries = parseUrlQueries()
  const pageName = getPageName(pathName, queries)
  const filterSetFilterNames = flatten(map(filterSets, ({ filters }) => keys(filters)))

  // we want to remove any values from quick filters + filter sets system so that we do not disable them on menu submit
  const valuesWithoutQuickFiltersAndSets = omitBy(values, (value, filterName) => {
    return some(quickFilters, ({ name, names = [] }) => (name === filterName) || (includes(names, filterName))) ||
      some(filterSetFilterNames, (filterSetFilterName) => (filterSetFilterName === filterName))
  })

  forEach(valuesWithoutQuickFiltersAndSets, (fieldData, fieldName) => {
    const {
      value,
      operand = EQUALS,
    } = fieldData

    const formatter = FILTER_FORMATTERS[fieldName]
    const fieldValue = formatter ? formatter(value) : value
    const cleanedValue = value && isString(fieldValue) ? trim(fieldValue) : fieldValue
    const checked = get(checkedFilters, fieldName)
    const isDateFilter = get(DATE_FILTERS, fieldName)
    const filterData = find(allowedFilters, { field: fieldName })
    const isMultiSelectFilter = get(filterData, 'isMultiSelectFilter')

    if (checked && (value || isDateFilter)) {
      if (fieldValue === ALL) {
        // value ALL in a dropdown is same as removing the filter
        dispatch({
          type: REMOVE_TABLE_FILTER,
          payload: {
            field: fieldName,
            operand,
            linksKey,
          },
        })
      } else if (isDateFilter) {
        const gteValue = operand === IS_BETWEEN ? get(fieldData, 'gte.value') : value
        const lteValue = operand === IS_BETWEEN ? get(fieldData, 'lte.value') : value

        if (!gteValue || !lteValue) return

        const gteFormatted = formatter(gteValue)
        const lteFormatted = formatter(lteValue)

        // if searching for exact date need to manually add gte and lte values so properly set start/end of day minutes/seconds
        if (operand === IS_BETWEEN || operand === ON || operand === EQUALS) {
          dispatch({
            type: ADD_TABLE_FILTER,
            payload: {
              field: fieldName,
              operand: LESS_THAN_EQUALS,
              value: lteFormatted,
              linksKey,
            },
            meta: {
              // TODO: need to update getPageName to also support tabs
              actionId: sendAmplitudeActionEvent(ADD_TABLE_FILTER_EVENT, {
                fieldName,
                operand,
                pathname: pathName,
                page: pageName,
                gteValue,
                lteValue,
              }),
            },
          })

          dispatch({
            type: ADD_TABLE_FILTER,
            payload: {
              field: fieldName,
              operand: GREATER_THAN_EQUALS,
              value: gteFormatted,
              linksKey,
            },
          })
        }

        if (operand === GREATER_THAN_EQUALS || operand === LESS_THAN_EQUALS) {
          const gteLteFormattedValue = gteFormatted ? gteFormatted : lteFormatted

          dispatch({
            type: ADD_TABLE_FILTER,
            payload: {
              field: fieldName,
              operand,
              value: gteLteFormattedValue,
              linksKey,
            },
            meta: {
              // TODO: need to update getPageName to also support tabs
              actionId: sendAmplitudeActionEvent(ADD_TABLE_FILTER_EVENT, {
                fieldName,
                operand,
                pathname: pathName,
                page: pageName,
                value: gteLteFormattedValue,
              }),
            },
          })

          dispatch({
            type: REMOVE_TABLE_FILTER,
            payload: {
              field: fieldName,
              operand: operand === GREATER_THAN_EQUALS ? LESS_THAN_EQUALS : GREATER_THAN_EQUALS,
              linksKey,
            },
          })
        }
      } else {
        // if multi select filter, need to pass in all values as a single string
        let valueToPass = cleanedValue

        if (isMultiSelectFilter) {
          const allValues = map(cleanedValue, (val) => {
            return get(val, 'value', '')
          })
          valueToPass = join(allValues)
        }

        dispatch({
          type: ADD_TABLE_FILTER,
          payload: {
            field: fieldName,
            operand,
            value: valueToPass,
            linksKey,
          },
          meta: {
            actionId: sendAmplitudeActionEvent(ADD_TABLE_FILTER_EVENT, {
              fieldName,
              operand,
              pathname,
              page: pageName,
              value: cleanedValue,
            }),
          },
        })
      }
    } else if (!checked) {
      if (isDateFilter) {
        dispatch({
          type: REMOVE_TABLE_FILTER,
          payload: {
            field: fieldName,
            operand: GREATER_THAN_EQUALS,
            linksKey,
          },
          meta: {
            actionId: sendAmplitudeActionEvent(REMOVE_TABLE_FILTER_EVENT, {
              fieldName,
              operand,
              pathname,
              page: pageName,
            }),
          },
        })

        dispatch({
          type: REMOVE_TABLE_FILTER,
          payload: {
            field: fieldName,
            operand: LESS_THAN_EQUALS,
            linksKey,
          },
        })
      } else {
        dispatch({
          type: REMOVE_TABLE_FILTER,
          payload: {
            field: fieldName,
            operand,
            linksKey,
          },
          meta: {
            actionId: sendAmplitudeActionEvent(REMOVE_TABLE_FILTER_EVENT, {
              fieldName,
              operand,
              pathname,
              page: pageName,
            }),
          },
        })
      }
    }
  })

  toggleFiltersMenu()
}

export default submitTableFiltersForm
