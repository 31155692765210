import getPlatformsAPI from 'api/finix/get/getPlatformsAPI'
import patchUserPermissionsAPI from 'api/finix/patch/patchUserPermissionsAPI'
import postCredentialsAPI from 'api/dashboard/post/postCredentialsAPI'
import postUserEmailAPI from 'api/dashboard/post/postUserEmailAPI'
import postUsersAPI from 'api/finix/post/postUsersAPI'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { getPermissionsForAccessLevel } from 'constants/userPermissions/rolePlatformPermissions'
import { WELCOME_EMAIL_PASSWORD } from 'constants/apiConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getDashboardUser from 'utilities/get/getDashboardUser'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import get from 'lodash/get'
import co from 'co'

import {
  getPlatform,
  createAPIUser,
  setAPIPermissions,
  createDashboardCredentials,
  createDashbordUser,
  updateDashboardUserCredentials,
  sendEmail,
} from 'constants/orchestrationConstants'

/* ============ Post User Flow ==========
1) getPlatformAPI => platformID
2) postUserAPI [1. platformID] => username, password
3) patchUserPermissionsAPI [2. username]
4) postCredentialsAPI [2. username, password] => credentialId
5) postV1UserAPI => userId
6) postMembership [4. credentialId; 5. userId ]
7) postUserEmnail [5. userId]
*/

function * postUserO ({
  id,
  values,
  credentials,
}) {
  const {
    name,
    email = '',
    platform,
    access,
    template,
  } = values

  const {
    paymentFacilitatorEnabled,
    pushToCardEnabled,
  } = credentials

  const dashboardId = getCurrentDashboardId()
  const { host } = credentials
  const creatorEmail = get(getDashboardUser(), CLIENT_EMAIL)

  const platformsResponse = yield getPlatformsAPI({ credentials })
  const platformId = get(platformsResponse, 'data._embedded.platforms[0].id')

  const userPayload = {
    role: 'ROLE_PLATFORM',
    platform_id: platformId,
    tags: {
      name,
      created_by: creatorEmail,
      email,
      access,
      platform,
    },
  }

  const { data: userResponse } = platformId ? yield postUsersAPI({
    values: userPayload,
    credentials,
  }) : { data: {} }

  const { id: username, password } = userResponse
  const permissions = getPermissionsForAccessLevel(access)

  const permissionResponse = username ? yield patchUserPermissionsAPI({
    credentials,
    id: username,
    values: permissions,
  }) : false

  const credentialsResponseRaw = username && password ? yield postCredentialsAPI({
    values: {
      username,
      password,
      email_address: email,
      dashboard_host: host,
      credential_name: platform,
      dashboard_id: dashboardId,
      payment_facilitator_enabled: paymentFacilitatorEnabled,
      push_to_card_enabled: pushToCardEnabled,
    },
  }) : {}

  const credentialsResponse = get(credentialsResponseRaw, 'data', {})
  const {
    id: credentialId,
    dashboard_user_id: userId,
    membership_id: membershipId,
  } = credentialsResponse

  const emailValues = {
    recipient_email: email.toLowerCase(),
    email_template_name: `${getCurrentPlatformName()}-${WELCOME_EMAIL_PASSWORD}`,
    email_template_data: {},
  }

  const { data: emailResponse } = userId ? yield postUserEmailAPI({
    id: userId,
    values: emailValues,
  }) : {}

  const orchestrationStatus = {
    [getPlatform]: !!platformId,
    [createAPIUser]: !!(username && password),
    [setAPIPermissions]: !!userResponse,
    [createDashboardCredentials]: !!credentialId,
    [createDashbordUser]: !!userId,
    [updateDashboardUserCredentials]: membershipId !== undefined,
    [sendEmail]: emailResponse !== undefined,
  }

  return createSuccessResponse({
    data: {
      users: [userResponse],
      orchestrationStatus,
    },
  })
}

export default co.wrap(postUserO)
