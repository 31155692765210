import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSortedAccessFormsSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import LiveAccountApplications from './LiveAccountApplications'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { WITHDRAW_LIVE_APPLICATION_FORM_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH,
  LIVE_ACCOUNT_APPLICATION_FORM_PATH,
  LIVE_ACCOUNT_APPLICATION_PATH,
} from 'constants/pathConstants'

import {
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
  IN_PROGRESS_BE_VALUE,
  WITHDRAWN_BE_VALUE,
} from 'constants/statusConstants'

import {
  CONTINUE_APPLYING,
  START_NEW_APPLICATION,
  SUBMIT_ADDITIONAL_INFO,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const accessForms = get(props, 'accessForms')
  const latestAccessForm = first(getSortedAccessFormsSelector(state))
  const latestAccessFormId = get(latestAccessForm, 'id')
  const latestAccessFormActiveStep = get(latestAccessForm, 'activeStepIndex')
  const editLiveAccountApplicationPath = EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId, accessFormId: latestAccessFormId })
  const viewLiveAccountApplicationPath = ({ id }) => LIVE_ACCOUNT_APPLICATION_PATH({ credentialId, accessFormId: id })
  const liveAccountUpgradePath = LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId })

  return {
    credentials,
    credentialId,
    accessForms,
    latestAccessForm,
    editLiveAccountApplicationPath,
    viewLiveAccountApplicationPath,
    latestAccessFormActiveStep,
    liveAccountUpgradePath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showWithdrawLiveAccountApplicationFormModal: (modalProps) => dispatch(showModalAction({ modalType: WITHDRAW_LIVE_APPLICATION_FORM_MODAL, modalProps })),
  }
}

class LiveAccountApplicationsC extends Component {
  redirectToEditApplication = ({ activeStep }) => {
    const {
      editLiveAccountApplicationPath,
    } = this.props

    if (activeStep) {
      goToPath({ pathname: editLiveAccountApplicationPath, queries: { activeStep } })
    } else {
      goToPath({ pathname: editLiveAccountApplicationPath })
    }
  }

  redirectToViewApplication = ({ id }) => {
    const {
      viewLiveAccountApplicationPath,
    } = this.props

    goToPath({ pathname: viewLiveAccountApplicationPath({ id }) })
  }

  redirectToStartApplication = () => {
    const {
      liveAccountUpgradePath,
    } = this.props

    goToPath({ pathname: liveAccountUpgradePath })
  }

  render() {
    const {
      showWithdrawLiveAccountApplicationFormModal,
      latestAccessForm,
      latestAccessFormActiveStep,
    } = this.props

    const latestAccessFormStatus = get(latestAccessForm, 'status')

    const actions = [
      {
        label: CONTINUE_APPLYING,
        className: 'continue-applying-btn',
        action: () => this.redirectToEditApplication({ activeStep: latestAccessFormActiveStep }),
        condition: isEqual(latestAccessFormStatus, IN_PROGRESS_BE_VALUE),
      },
      {
        label: START_NEW_APPLICATION,
        className: 'start-new-application-btn',
        action: this.redirectToStartApplication,
        condition: isEqual(latestAccessFormStatus, WITHDRAWN_BE_VALUE),
      },
      {
        label: SUBMIT_ADDITIONAL_INFO,
        className: 'submit-additional-info-btn',
        action: () => this.redirectToEditApplication({ activeStep: latestAccessFormActiveStep }),
        condition: isEqual(latestAccessFormStatus, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE),
      },
    ]

    const clickAction = (accessForm) => {
      const accessFormId = get(accessForm, 'id')
      const accessFormStatus = get(accessForm, 'status')
      const accessFormActiveStep = get(accessForm, 'activeStepIndex')

      if (includes([IN_PROGRESS_BE_VALUE, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE], accessFormStatus)) {
        this.redirectToEditApplication({ activeStep: accessFormActiveStep })
      } else {
        this.redirectToViewApplication({ id: accessFormId })
      }
    }

    return (
      <LiveAccountApplications
        {...this.props}
        actions={actions}
        clickAction={clickAction}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationsC)
