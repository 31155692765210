import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApplicationSelector } from 'state-layer/selectors'
import ApplicationPayoutPlan from 'components/Customer/Pages/Application/ApplicationPayoutPlan/ApplicationPayoutPlan'
import { FETCHING } from 'constants/reducerConstants'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

// TODO: this page is now a subset of PayoutSettings and we need to refactor payout settings to also work for applications and reduce repeated code
const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `applicationsR.${FETCHING}`)
  const applicationId = get(props, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const payoutPlan = get(application, 'payoutPlan')
  const payoutPlanId = get(payoutPlan, 'id')

  const [
    entityId,
    level,
    processor,
  ] = getMany(payoutPlan, [
    'entityId',
    'level',
    'processor',
  ])

  const detailsSectionData = [
    {
      label: 'Entity ID',
      value: entityId,
    },
    {
      label: 'Level',
      value: capitalize(level),
    },
    {
      label: 'Processor',
      value: processor,
    },
  ]

  return {
    isFetching,
    payoutPlan,
    payoutPlanId,
    detailsSectionData,
  }
}

class ApplicationPayoutPlanC extends Component {
  render() {
    return (
      <ApplicationPayoutPlan {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationPayoutPlanC)
