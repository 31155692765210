import './CountryFlagIconS.scss'
import Flags from 'country-flag-icons/react/3x2'
import React from 'react'
import PropTypes from 'prop-types'

import {
  USA,
  convertCountryISO3toISO2,
  countryNameByCountryCode,
} from 'constants/countryConstants'

/**
 * Renders a country flag icon with the country name next to it.
 *
 * @component
 * @param {Object} props - The props for the CountryFlagIcon component.
 * @param {string} props.country - The ISO-3 country code of the country whose flag will be displayed. Default is USA.
 * @param {boolean} [props.showCountryName=true] - A boolean indicating whether to display the country name alongside the flag. Default is true.
 * @returns {JSX.Element} Returns a JSX div containing the country flag icon.
 *
 * @example
 * <CountryFlagIcon country='USA' />
 */
const CountryFlagIcon = ({
  country = USA,
  showCountryName = true,
}) => {
  const countryString = countryNameByCountryCode[country]
  const ISO2Country = convertCountryISO3toISO2(country)
  const Flag = Flags[ISO2Country]

  return (
    <div className={`CountryFlagIcon ${country} flex items-center`}>
      { Flag && <Flag /> }
      { showCountryName && <div className='country-name'>{countryString}</div> }
    </div>
  )
}

CountryFlagIcon.propTypes = {
  country: PropTypes.string,
  showCountryName: PropTypes.bool,
}

export default CountryFlagIcon
