import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentPanel from './PaymentPanel'
import { getTransferSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import formatMoney from 'utilities/formatters/formatMoney'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  IDENTITY_PATH,
  APPLICATION_PATH,
} from 'constants/pathConstants'

import {
  MERCHANT_IDENTITY,
  APPLICATION_RESOURCE_TITLE,
  MASKED_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const transferId = get(props, 'resourceId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  // TODO: use updated selector that also fetches related information after FWS orchestration change
  const transfer = getTransferSelector(state, transferId)

  // TODO: merchant name should be DBA after running through frontend model in orchestration correctly
  const [
    merchantIdentityId,
    merchantName,
    applicationId,
    applicationName,
    maxTransactionAmount,
    currency,
  ] = getMany(transfer, [
    'merchantIdentity.id',
    'merchantIdentity.entity.business_name',
    'applicationId',
    'application.name',
    'merchantIdentity.entity.max_transaction_amount',
    'currency',
  ])

  // TODO: in getTransferO, run all orchestrated responses through our frontend models so we can pull from displayAmount directluy
  const displayMaxTransactionAmount = formatMoney({ amount: maxTransactionAmount, currency })

  const panelContextBarData = [
    // TODO: remove this first transfer ID when officially worked on, this is just to easily tell you are sliding out correct transfer
    {
      label: 'Transfer ID',
      value: transferId,
    },
    {
      label: MERCHANT_IDENTITY,
      value: merchantName,
      path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
    },
    {
      label: APPLICATION_RESOURCE_TITLE,
      value: applicationName,
      path: APPLICATION_PATH({ credentialId, applicationId }),
    },
    {
      label: MASKED_TRANSACTION_AMOUNT,
      value: displayMaxTransactionAmount,
    },
  ]

  return {
    isFetching,
    transferId,
    credentials,
    panelContextBarData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
  }
}

class PaymentPanelC extends Component {
  componentDidMount() {
    this.fetchTransfer()
  }

  componentDidUpdate(prevProps) {
    const { transferId } = this.props
    const { transferId: prevTransferId } = prevProps

    if (transferId !== prevTransferId) {
      this.fetchTransfer()
    }
  }

  fetchTransfer = () => {
    const {
      transferId,
      credentials,
      getTransfer,
    } = this.props

    getTransfer({ credentials, transferId })
  }

  render() {
    return (
      <PaymentPanel {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPanelC)
