import './CreateOnboardingFormBasicsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import validateCreateOnboardingFormBasicsForm from 'utilities/validate/validateCreateOnboardingFormBasicsForm'
import formatNumberFormatField from 'utilities/forms/format/formatNumberFormatField'
import parseNumber from 'utilities/forms/parse/parseNumber'
import { CREATE_ONBOARDING_FORM_BASICS_FORM } from 'constants/formConstants'
import { countryOptionsForFlex } from 'constants/countryConstants'

import {
  APPLICATION,
  CANCEL, CONTINUE,
  COUNTRY,
  COUNTRY_HELPER_TEXT,
  EXPIRATION_TIME_DAYS,
  EXPIRATION_TIME_SUBTITLE,
  EXPIRED_SESSION_URL,
  EXPIRED_URL_HELPER_TEXT,
  FEE_URL,
  FEE_URL_HELPER_TEXT,
  MERCHANT_NAME,
  MERCHANT_NAME_HELPER_TEXT,
  MERCHANTS_APPLICATION_SUBTITLE,
  RETURN_URL,
  RETURN_URL_HELPER_TEXT,
  SEARCH_BY_APPLICATION_NAME_OR_ID,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_SERVICE_URL_HELPER_TEXT,
} from 'constants/language/languageConstants'

const CreateOnboardingFormBasicsForm = ({
  handleSubmit = () => {},
  applicationsForInput = [],
  isRolePlatformCredential,
  closeModal = () => {},
}) => {
  return (
    <form className='CreateOnboardingFormBasicsForm' onSubmit={handleSubmit}>
      <div className='section'>
        {isRolePlatformCredential && (
        <Field
          name='applicationId'
          label={APPLICATION}
          helperText={MERCHANTS_APPLICATION_SUBTITLE}
          placeholder={SEARCH_BY_APPLICATION_NAME_OR_ID}
          component={ReactSelect}
          options={applicationsForInput}
        />
        )}

        <Field
          name='country'
          label={COUNTRY}
          helperText={COUNTRY_HELPER_TEXT}
          component={SelectField}
          options={countryOptionsForFlex}
        />

        <Field
          name='merchantName'
          label={MERCHANT_NAME}
          helperText={MERCHANT_NAME_HELPER_TEXT}
          component={InputField}
        />

        <Field
          name='expirationTime'
          label={EXPIRATION_TIME_DAYS}
          helperText={EXPIRATION_TIME_SUBTITLE}
          component={InputField}
          format={formatNumberFormatField}
          parse={parseNumber}
        />

        <Field
          name='feeUrl'
          label={FEE_URL}
          helperText={FEE_URL_HELPER_TEXT}
          component={InputField}
        />

        <Field
          name='returnUrl'
          label={RETURN_URL}
          helperText={RETURN_URL_HELPER_TEXT}
          component={InputField}
        />

        <Field
          name='termsOfServiceUrl'
          label={TERMS_OF_SERVICE_URL}
          helperText={TERMS_OF_SERVICE_URL_HELPER_TEXT}
          component={InputField}
        />

        <Field
          name='expiredSessionUrl'
          label={EXPIRED_SESSION_URL}
          helperText={EXPIRED_URL_HELPER_TEXT}
          component={InputField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={CONTINUE} />
      </div>
    </form>
  )
}

CreateOnboardingFormBasicsForm.propTypes = {
  handleSubmit: PropTypes.func,
  applicationsForInput: PropTypes.array,
  isRolePlatformCredential: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: CREATE_ONBOARDING_FORM_BASICS_FORM,
  validate: validateCreateOnboardingFormBasicsForm,
})(CreateOnboardingFormBasicsForm)
