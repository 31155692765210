import React from 'react'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import column from 'utilities/table/column'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { createdAtRange } from 'utilities/table/sort'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { REJECTED } from 'constants/settlementConstants'
import { get, includes } from 'lodash'

import {
  ALL,
  APPLICATION,
  ID,
  CREATED_ON,
  MERCHANT_ACCOUNT,
  ONBOARDING_STATE_TITLE,
  REASON_CODE,
  COUNTRY,
  PROCESSOR,
  MID,
  MCC,
  ROLE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    condition: notRoleMerchant,
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT} subtitle={APPLICATION} />,
    formatter: (merchant) => <TransferMerchantApplicationColumn transfer={merchant} />,
  }),
  column(ROLE, 'merchantIdentity.displayIdentityRoles', { className: 'small' }),
  column(PROCESSOR, 'displayGateway', { className: 'small' }),
  column(COUNTRY, 'displayCountry', {
    formatter: ({ country }) => {
      return CountryFlagIcon({ country })
    },
  }),
  column(ONBOARDING_STATE_TITLE, 'onboardingState', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={ONBOARDING_STATE_TITLE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, REJECTED], getUrlQuery('onboarding_state', ALL))} />,
    formatter: ({ onboardingState, verification }) => (
      <TableItemSubtitle
        titleComponent={() => <OnboardingState value={onboardingState} />}
        subtitle={get(verification, 'displayOutcomeCodes')}
        subtitleIndent
        emptySignifierCheck={onboardingState === REJECTED}
      />
    ),
  }),
  column(MID, 'mid', {
    className: 'small',
    formatter: (merchant) => {
      return get(merchant, 'mid') || get(merchant, 'processorDetails.mid') || get(merchant, 'processorDetails.merchant_ident_string')
    },
  }),
  column(MCC, 'merchantIdentity.mcc', { className: 'small' }),
]

export default columnDescriptors
