import {
  DISPUTE,
  WEBHOOK,
  MERCHANT,
} from 'constants/flowConstants'

import {
  PAYMENT_PATH,
  DISPUTE_PATH,
  WEBHOOK_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

const getResourceLink = ({ resourceId, resourceType, credentialId, merchantId }) => {
  switch (resourceType) {
    case 'PAYMENT':
      return PAYMENT_PATH({ credentialId, transferId: resourceId })

    case DISPUTE:
      return DISPUTE_PATH({ credentialId, disputeId: resourceId })

    case WEBHOOK:
      return WEBHOOK_PATH({ credentialId, webhookId: resourceId })

    case MERCHANT:
      return MERCHANT_PATH({ credentialId, merchantId: resourceId, identityId: merchantId })

    default:
      return null
  }
}

export default getResourceLink
