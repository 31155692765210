import ReviewQueueItem from './ReviewQueueItem'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewQueueOnboardingStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ReviewQueueOnboardingStatus'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import { FETCHING } from 'constants/reducerConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getReviewQueueItemRequest from 'utilities/actions/get/getReviewQueueItemRequest'
import formatDate from 'utilities/formatters/formatDate'
import { getReviewQueueItemSelector } from 'state-layer/selectors'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  SETTLEMENT,
  SETTLEMENT_V2,
  MERCHANT,
  getEntityName,
  getEntityPath,
} from 'constants/reviewQueueConstants'


const getSettlementSectionData = (settlement) => {
  const [
    settlementId,
    currency,
    status,
    settlementProcessor,
  ] = getMany(settlement, [
    'id',
    'currency',
    'status',
    'processor',
  ])

  const settlememntSectionData = [
    {
      label: 'ID',
      value: settlementId,
    },
    {
      label: 'Currency',
      value: currency,
    },
    {
      label: 'Status',
      value: status,
      formatter: ({ value }) => <SettlementStatus value={value} />,
    },
    {
      label: 'Processor',
      value: settlementProcessor,
    },
  ]

  return settlememntSectionData
}

const getMerchantSectionData = (merchant) => {
  const [
    merchantId,
    merchantProcessor,
    onboardingState,
    processingEnabled,
    settlementEnabled,
  ] = getMany(merchant, [
    'id',
    'processor',
    'onboardingState',
    'processingEnabled',
    'settlementEnabled',
  ])

  const merchantSectionData = [
    {
      label: 'ID',
      value: merchantId,
    },
    {
      label: 'Processor',
      value: merchantProcessor,
    },
    {
      label: 'State',
      value: onboardingState,
      formatter: ({ value }) => <OnboardingState value={value} />,
    },
    {
      label: 'Processing Enabled',
      value: processingEnabled ? 'Enabled' : 'Disabled',
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: 'Settlements Enabled',
      value: settlementEnabled ? 'Enabled' : 'Disabled',
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
  ]

  return merchantSectionData
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `reviewQueueR.${FETCHING}`)
  const reviewQueueId = get(props, 'params.reviewQueueId')
  const credentials = getCurrentCredentials(state)
  const reviewQueueItem = getReviewQueueItemSelector(state, reviewQueueId)

  const [
    id = '',
    entityType = '',
    outcome,
    createdAt,
    entityId,
    applicationId,
    merchant = {},
    settlement = {},
    merchantName = '',
    settlementAmount = '',
  ] = getMany(reviewQueueItem, [
    'id',
    'entityType',
    'outcome',
    'createdAt',
    'entityId',
    'applicationId',
    'merchant',
    'settlement',
    'merchant.name',
    'settlement.displayTotalAmount',
  ])

  const entityName = getEntityName({ entityType })
  const isSettlement = entityType === SETTLEMENT || entityType === SETTLEMENT_V2

  const entityTypeHeaderMap = {
    [SETTLEMENT]: `${settlementAmount} Settlement`,
    [SETTLEMENT_V2]: `${settlementAmount} Settlement`,
    [MERCHANT]: merchantName,
  }

  const headerTitle = get(entityTypeHeaderMap, entityType, '')
  const headerSubTitle = formatDate({ date: createdAt })

  const entitySectionTitle = entityName
  const entitySectionData = isSettlement ? getSettlementSectionData(settlement) : getMerchantSectionData(merchant)

  const detailsSectionData = [
    {
      label: 'ID',
      value: id,
    },
    {
      label: 'Type',
      value: entityName,
    },
    {
      label: 'Application ID',
      value: applicationId,
    },
    {
      label: 'Outcome',
      value: outcome,
      formatter: ({ value: onboardingOutcome }) => <ReviewQueueOnboardingStatus value={onboardingOutcome} />,
    },
  ]

  return {
    isFetching,
    reviewQueueId,
    credentials,
    reviewQueueItem,
    headerTitle,
    headerSubTitle,
    detailsSectionData,
    entitySectionData,
    entitySectionTitle,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewQueueItem: ({ credentials, reviewQueueId }) => { dispatch(getReviewQueueItemRequest({ credentials, reviewQueueId })) },
  }
}

class ReviewQueueItemC extends Component {
  componentDidMount() {
    this.fetchReviewQueueItem()
  }

  fetchReviewQueueItem = () => {
    const {
      reviewQueueId,
      credentials,
      getReviewQueueItem,
    } = this.props

    getReviewQueueItem({ credentials, reviewQueueId })
  }

  render() {
    return (
      <ReviewQueueItem
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewQueueItemC)
