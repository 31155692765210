import React from 'react'
import PropTypes from 'prop-types'
import PayoutPlanContentsC from 'components/Customer/Pages/PayoutPlan/PayoutPlanContentsC'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'

import {
  DETAILS,
} from 'constants/language/languageConstants'

const ApplicationPayoutPlan = ({
  isFetching = false,
  payoutPlanId = '',
  detailsSectionData = [],
}) => {
  return (
    <div className='ApplicationPayoutPlan'>
      <PageSection
        title={DETAILS}
        data={detailsSectionData}
        isFetching={isFetching}
      />

      <PayoutPlanContentsC
        isFetching={isFetching}
        payoutPlanId={payoutPlanId}
      />
    </div>
  )
}

ApplicationPayoutPlan.propTypes = {
  isFetching: PropTypes.bool,
  payoutPlanId: PropTypes.string,
  detailsSectionData: PropTypes.array,
}

export default ApplicationPayoutPlan
