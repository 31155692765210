import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditContinuousMonitoringSettingsFormC from 'components/Customer/Forms/EditContinuousMonitoringSettingsForm/EditContinuousMonitoringSettingsFormC'

import {
  CONTINUOUS_MONITORING_SETTINGS_SUBTITLE,
  UPDATE_CONTINUOUS_MONITORING_SETTINGS,
} from 'constants/language/languageConstants'

const ContinuousMonitoringSettingsModal = ({
  underwritingProfile = {},
}) => {
  return (
    <GenericModal
      title={UPDATE_CONTINUOUS_MONITORING_SETTINGS}
      subTitle={CONTINUOUS_MONITORING_SETTINGS_SUBTITLE}
      className='ContinuousMonitoringSettingsModal'
      Component={EditContinuousMonitoringSettingsFormC}
      underwritingProfile={underwritingProfile}
    />
  )
}

ContinuousMonitoringSettingsModal.propTypes = {
  underwritingProfile: PropTypes.object,
}

export default ContinuousMonitoringSettingsModal
