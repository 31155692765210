import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import { getPayoutLinksSelector } from 'state-layer/selectors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TableC from 'components/Shared/Table/TableC'
import { GET_PAYOUT_LINKS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  PAYOUT_LINKS,
  PAYOUT_LINKS_EMPTY_TABLE_MESSAGE,
  PAYOUT_LINKS_TABLE_SUBTITLE,
} from 'constants/language/languageConstants'

const PayoutLinks = ({
  payoutLinksPath = '',
  initialQueries = {},
  initialFilters = {},
  quickFilters = {},
  actions = [],
  allowedFilters = [],
}) => {
  return (
    <div className='PayoutLinks'>
      <HeaderV2C
        title={PAYOUT_LINKS}
        subTitle={PAYOUT_LINKS_TABLE_SUBTITLE}
      />

      <TableC
        flow={GET_PAYOUT_LINKS}
        selector={getPayoutLinksSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        quickFilters={quickFilters}
        allowedFilters={allowedFilters}
        path={payoutLinksPath}
        actions={actions}
        columnDescriptors={columnDescriptors}
        emptyMessage={PAYOUT_LINKS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV='Payout_Links'
      />
    </div>
  )
}

PayoutLinks.propTypes = {
  payoutLinksPath: PropTypes.string,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.object,
  actions: PropTypes.array,
  allowedFilters: PropTypes.array,
}

export default PayoutLinks
