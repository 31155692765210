import paymentsAPI from 'utilities/api/paymentsAPI'
import { RECEIPTS } from 'constants/apiConstants'

const postReceiptAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: RECEIPTS,
    service: RECEIPTS,
  })
}

export default postReceiptAPI
