import React from 'react'
import column from 'utilities/table/column'
import { CREATED_AT_LABEL } from 'constants/language/languageConstants'

const columnDescriptors = [
  column('ID', 'id', { className: 'id' }),
  column('Name', 'name', { className: 'name' }),
  column(CREATED_AT_LABEL, 'createdAt', { className: 'date' }),
]

export default columnDescriptors
