import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, isInvalid, hasSubmitFailed, formValueSelector } from 'redux-form'
import IdentityPrincipalInfoForm from './IdentityPrincipalInfoForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { IDENTITY_PRINCIPAL_INFO_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import { getOnboardingFormDataItemSelector } from 'state-layer/selectors'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import map from 'lodash/map'
import size from 'lodash/size'
import head from 'lodash/head'

const mapStateToProps = (state, props) => {
  const isFetchingOnboardingFormData = get(state, `onboardingFormDataR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'params.identityId')
  const onboardingFormId = get(props, 'onboardingFormId')
  const onboardingForm = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const businessType = get(onboardingForm, 'businessData.businessType')
  const controlPerson = get(onboardingForm, 'controlPerson')
  const beneficialOwners = get(onboardingForm, 'beneficialOwners')

  // get the total percentage ownership for the form
  const formSelector = formValueSelector(IDENTITY_PRINCIPAL_INFO_FORM)
  const controlPersonFormValues = formSelector(state, 'controlPerson')
  const controlPersonCurrentCountry = get(controlPersonFormValues, 'personalAddress.country')
  const beneficialOwnersFormValues = formSelector(state, 'beneficialOwners')
  const beneficialOwnersCurrentCountries = map(beneficialOwnersFormValues, 'personalAddress.country')
  const totalPrincipalPercentageOwnership = reduce(concat(controlPersonFormValues, beneficialOwnersFormValues), (total, principal) => {
    const principalPercentageOwnership = get(principal, 'principalPercentageOwnership', '0')

    return total + parseFloat(principalPercentageOwnership)
  }, 0)

  const ownershipPercentageError = totalPrincipalPercentageOwnership > 100 ? 'The total Ownership Percentage for all individuals must not exceed 100%' : undefined
  const hasBeneficialOwners = !isEmpty(beneficialOwners)
  const processors = get(onboardingForm, 'processorGatewayDetails.processors')
  const isBoardingToFinixV1Only = size(processors) === 1 && head(processors) === FINIX_V1
  const keysToIgnore = ['controlPerson.personalAddress.country', 'controlPerson.principalPercentageOwnership']

  const isFormInvalid = isInvalid(IDENTITY_PRINCIPAL_INFO_FORM)(state) && hasSubmitFailed(IDENTITY_PRINCIPAL_INFO_FORM)(state)

  const initialValues = isFetchingOnboardingFormData ? undefined : {
    controlPerson,
    beneficialOwners,
    beneficialOwnersQuestion: hasBeneficialOwners ? 'true' : 'false',
  }

  return {
    credentials,
    initialValues,
    identityId,
    ownershipPercentageError,
    isFormInvalid,
    businessType,
    controlPersonCurrentCountry,
    beneficialOwnersCurrentCountries,
    keysToIgnore,
    isBoardingToFinixV1Only,
  }
}

class IdentityPrincipalInfoFormC extends Component {
  handleBeneficialOwnersRadioClick = (event, value) => {
    const { dispatch, onboardingFormCountry } = this.props
    if (value === 'true') {
      dispatch(change(IDENTITY_PRINCIPAL_INFO_FORM, 'beneficialOwners', [{ personalAddress: { country: onboardingFormCountry } }]))
    } else {
      dispatch(change(IDENTITY_PRINCIPAL_INFO_FORM, 'beneficialOwners', []))
    }
  }

  render () {
    return (
      <IdentityPrincipalInfoForm
        {...this.props}
        handleBeneficialOwnersRadioClick={this.handleBeneficialOwnersRadioClick}
      />
    )
  }
}

export default connect(mapStateToProps)(IdentityPrincipalInfoFormC)
