import './DashboardAccountsMenuItemS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import {
  ACCOUNT,
  VIEW,
} from 'constants/language/languageConstants'

const DashboardAccountsMenuItem = ({
  name,
  environment = '',
  role = '',
  path = '',
}) => (
  <div className='DashboardAccountsMenuItem'>
    <Link to={path}>
      {name && (
      <div className='credential-name' title={name}>
        {name}
      </div>
      )}

      <div className='environment-view-data flex items-center'>
        <div className='environment'>{`${environment} ${ACCOUNT}`}</div>
        <span className='circle' />
        <div className='role'>{`${role} ${VIEW}`}</div>
      </div>
    </Link>
  </div>
)

DashboardAccountsMenuItem.propTypes = {
  name: PropTypes.string,
  environment: PropTypes.string,
  role: PropTypes.string,
  path: PropTypes.string,
}

export default DashboardAccountsMenuItem
