import './DisputeRespondWithinStatusS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import { HOURGLASS_ICON } from 'constants/iconConstants'

import {
  EXPIRED,
  statusIconMap,
} from 'constants/statusConstants'

import {
  EXPIRED as EXPIRED_LABEL,
  EXPIRED_RESPONSE_DEADLINE,
} from 'constants/language/languageConstants'

const DisputeRespondWithinStatus = ({
  value,
  displayValue = '',
  hoverOverText = '',
  icon = HOURGLASS_ICON,
  inTable = false,
}) => {
  const validValue = value || (value === 0)
  const showIcon = validValue && (value <= 5 && value >= 0)

  return (
    <div className='DisputeRespondWithinStatus flex items-center'>
      { showIcon && <span title={hoverOverText}><i className={`far fa-${icon}`} /></span> }
      { !showIcon && !(value < 0) && <span className='hidden-icon'><i className={`far fa-${icon}`} /></span> }

      { validValue && (value < 0 ?
        <span title={hoverOverText} className='value'>{inTable ? <ColorcodedStatus value={EXPIRED} data={statusIconMap} customValue={EXPIRED_LABEL} /> : EXPIRED_RESPONSE_DEADLINE}</span> :
        <span title={hoverOverText} className='value'>{displayValue}</span>)
      }

      { !validValue && <span>-</span> }
    </div>
  )
}

DisputeRespondWithinStatus.propTypes = {
  value: PropTypes.number,
  displayValue: PropTypes.string,
  hoverOverText: PropTypes.string,
  icon: PropTypes.string,
  inTable: PropTypes.bool,
}

export default DisputeRespondWithinStatus
