import {
  VISA_ICON,
  MASTERCARD_ICON,
  DISCOVER_ICON,
  AMERICAN_EXPRESS_ICON,
  APPLE_PAY_ICON,
  GOOGLE_PAY_ICON,
  ACH_RETURNS_ICON,
} from 'constants/iconConstants'

// display labels
export const VISA_LABEL = 'Visa'
export const MASTERCARD_LABEL = 'Mastercard'
export const DISCOVER_LABEL = 'Discover'
export const AMERICAN_EXPRESS_LABEL = 'American Express'
export const BANK_ACCOUNT_ACH_LABEL = 'Bank Account (ACH)'
export const APPLE_PAY_LABEL = 'Apple Pay'
export const GOOGLE_PAY_LABEL = 'Google Pay'

export const PAYMENT_METHOD_LABEL_TO_ICON_MAP = {
  [VISA_LABEL]: `fab fa-cc-${VISA_ICON}`,
  [MASTERCARD_LABEL]: `fab fa-cc-${MASTERCARD_ICON}`,
  [DISCOVER_LABEL]: `fab fa-cc-${DISCOVER_ICON}`,
  [AMERICAN_EXPRESS_LABEL]: `fab fa-cc-${AMERICAN_EXPRESS_ICON}`,
  [BANK_ACCOUNT_ACH_LABEL]: `fa fa-${ACH_RETURNS_ICON}`,
  [APPLE_PAY_LABEL]: `fab fa-cc-${APPLE_PAY_ICON}`,
  [GOOGLE_PAY_LABEL]: `fab fa-${GOOGLE_PAY_ICON}`,
}

export const NON_LITLE_ACCEPTED_PAYMENT_METHODS = [VISA_LABEL, MASTERCARD_LABEL, DISCOVER_LABEL, AMERICAN_EXPRESS_LABEL, APPLE_PAY_LABEL, GOOGLE_PAY_LABEL]

export const LITLE_ACCEPTED_PAYMENT_METHODS = [VISA_LABEL, MASTERCARD_LABEL, DISCOVER_LABEL, AMERICAN_EXPRESS_LABEL, BANK_ACCOUNT_ACH_LABEL, APPLE_PAY_LABEL, GOOGLE_PAY_LABEL]

// Apple Pay constants
export const APPLE_PAY = 'APPLE_PAY'
export const APPLE_PAY_PROCESSING = 'APPLE_PAY_PROCESSING'
export const UNREGISTER = 'UNREGISTER'
