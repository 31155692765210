import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'

const frontendExportModel = ({ data: exportFile }) => {
  const [
    id,
    userId,
    entityType,
    filters,
    createdAt,
    updatedAt,
    status,
    fields,
    fileName,
    exportDownloadData,
    downloadUrl,
    // expirationDate,
  ] = getMany(exportFile, [
    'id',
    'dashboard_user_id',
    'entity_type',
    'filters',
    'created_at',
    'updated_at',
    'state',
    'fields',
    'file_name',
    'exportDownloadData',
    'exportDownloadData.url',
    // 'expires_at',
  ])

  return removeUndefined({
    id,
    userId,
    entityType,
    filters,
    createdAt,
    updatedAt,
    status,
    fields,
    fileName,
    exportDownloadData,
    downloadUrl,
    // expirationDate,
    // displayExpirationDate: formatDate({ date: expirationDate }),
  })
}

export default frontendExportModel
