import dispatch from 'utilities/dispatch'
import { NEXT_FLOW_STEP } from 'constants/flowConstants'

const nextFlowStep = ({ dataKey, formValues } = {}) => {
  dispatch({
    type: NEXT_FLOW_STEP,
    payload: dataKey
      ? {
        formValues: {
          [dataKey]: formValues,
        },
      }
      : {},
  })
}

export default nextFlowStep
