import React, { Component } from 'react'
import { connect } from 'react-redux'
import Refunds from './Refunds'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { FETCHING } from 'constants/reducerConstants'
import { REFUNDS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { REFUND } from 'constants/language/languageConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import concat from 'lodash/concat'
import filter from 'lodash/filter'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  REVERSAL,
  FAILED,
  SUCCEEDED,
} from 'constants/transferConstants'

import {
  TRACE_ID,
  AMOUNT,
  CREATED_AT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  DEVICE_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter(REFUND),
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  type: REVERSAL,
}

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const refundsPath = REFUNDS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const stateQuery = getUrlQuery('state')
  const isFailedQuickFilter = stateQuery === FAILED
  const isSucceededQuickFilter = stateQuery === SUCCEEDED
  const hiddenFailedFields = isFailedQuickFilter ? ['readyToSettleAt'] : []
  const hiddenSucceededFields = isSucceededQuickFilter ? ['failureCode'] : []
  const conditionalHiddenExportFields = concat(hiddenFailedFields, hiddenSucceededFields)
  const hiddenFilters = get(props, 'hiddenFilters', [])
  const mergedFilters = filter(allowedFilters, ({ field }) => !hiddenFilters.includes(field))

  return {
    quickFilters,
    allowedFilters: mergedFilters,
    initialQueries: mergedQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    hiddenExportFields: conditionalHiddenExportFields,
    refundsPath,
  }
}

class RefundsC extends Component {
  render() {
    return (
      <Refunds {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(RefundsC)
