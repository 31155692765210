import frontendApplicationProcessorConfigModel from 'utilities/create/models/frontendApplicationProcessorConfigModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendApplicationProcessorsConfigModel = ({ data: processorsConfigs }) => {
  const processorsConfigsModel = map(processorsConfigs, (processorConfig) => frontendApplicationProcessorConfigModel({ data: processorConfig }))

  return keyBy(processorsConfigsModel, 'id')
}

export default frontendApplicationProcessorsConfigModel
