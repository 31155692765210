import React, { Component } from 'react'
import { connect } from 'react-redux'
import AcceptLiabilityConfirmationForm from './AcceptLiabilityConfirmationForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const disputeId = get(props, 'disputeId')

  return {
    credentials,
    disputeId,
  }
}

class AcceptLiabilityConfirmationFormC extends Component {
  render() {
    const { closeModal } = this.props

    return (
      <AcceptLiabilityConfirmationForm
        {...this.props}
        closeModal={closeModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptLiabilityConfirmationFormC)
