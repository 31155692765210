import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'

const frontendAPILogModel = ({ data: apiLog }) => {
  const [
    id,
    createdAt,
    apiPath,
    applicationId,
    httpMethod,
    ipAddress,
    requestContent,
    requestReceivedAt,
    requestURL,
    responseContent,
    httpStatusCode,
    requestId,
    requestingUser,
  ] = getMany(apiLog, [
    'id',
    'created_at',
    'api_path',
    'application_id',
    'http_method',
    'ip_address',
    'request_content',
    'request_received_at',
    'request_url',
    'response_content',
    'http_status_code',
    'request_id',
    'requesting_user',
  ])

  const [
    requestContentBody,
    requestContentHeaders,
  ] = getMany(requestContent, [
    'body',
    'headers',
  ])

  const [
    responseContentBody,
    responseContentHeaders,
  ] = getMany(responseContent, [
    'body',
    'headers',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    apiPath,
    applicationId,
    httpMethod,
    ipAddress,
    requestContent,
    requestReceivedAt,
    requestURL,
    responseContent,
    httpStatusCode,
    requestId,
    requestContentBody,
    requestContentHeaders,
    responseContentBody,
    responseContentHeaders,
    requestingUser,
  })
}

export default frontendAPILogModel
