import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import dashboardAPI from 'utilities/api/dashboardAPI'
import get from 'lodash/get'

import {
  DASHBOARD_SERVICE_CREDENTIAL,
  DASHBOARD_SERVICE_CREDENTIALS,
  DASHBOARD_SERVICE_CREDENTIAL_V2,
  DASHBOARD_SERVICE_CREDENTIALS_V2,
} from 'constants/apiConstants'

const getCredentialsAPI = ({ id, values, queries, meta }) => {
  const dashboardId = get(values, 'dashboardId')
  const credentialsPath = isDashboardServiceV2() ? DASHBOARD_SERVICE_CREDENTIALS_V2({ dashboardId }) : DASHBOARD_SERVICE_CREDENTIALS
  const credentialPath = isDashboardServiceV2() ? DASHBOARD_SERVICE_CREDENTIAL_V2({ dashboardId, id }) : DASHBOARD_SERVICE_CREDENTIAL(id)
  const apiPath = id ? credentialPath : credentialsPath

  return dashboardAPI.get({
    meta,
    path: apiPath,
    queries,
  })
}

export default getCredentialsAPI
