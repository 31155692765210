import co from 'co'
import patchIdentityAPI from 'api/finix/patch/patchIdentityAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import backendIdentityModel from 'utilities/create/models/backendIdentityModel'
import { isFlexPlatform } from 'constants/flexConstants'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import every from 'lodash/every'
import getMany from 'utilities/get/getMany'

import {
  editMerchantIdentity,
  editAssociatedIdentity,
} from 'constants/orchestrationConstants'

function * patchMerchantIdentityO ({
  values,
  credentials,
  id,
  meta,
}) {
  const { records } = meta
  const [
    merchantIdentity,
    associatedIdentities,
  ] = getMany(values, [
    'merchantIdentity',
    'associatedIdentities',
  ])

  const isFlex = isFlexPlatform()
  const backendMerchantIdentityModel = backendIdentityModel({ values: merchantIdentity })
  const backendMerchantIdentityValues = normalizeResponse({ content: backendMerchantIdentityModel, normalizationMap: backendNormalizationMap })
  const { data: merchantIdentityResponse } = yield patchIdentityAPI({ values: backendMerchantIdentityValues, credentials, id })

  const identityId = get(merchantIdentityResponse, 'id')

  const associatedIdentityResponse = !isEmpty(associatedIdentities) ?
    yield map(associatedIdentities, (associatedIdentity, idx) => {
      const associatedIdentityId = get(records, idx + 1)
      const associatedIdentityModel = backendIdentityModel({ values: associatedIdentity })
      const associatedIdentityValues = normalizeResponse({ content: associatedIdentityModel, normalizationMap: backendNormalizationMap })

      return patchIdentityAPI({
        values: associatedIdentityValues,
        credentials,
        id: associatedIdentityId,
      })
    }) : []

  const orchestrationStatus = {
    [editMerchantIdentity]: !!identityId,
    [editAssociatedIdentity]: isEmpty(associatedIdentities) || every(associatedIdentityResponse, ({ data }) => !isEmpty(data)),
  }

  return createSuccessResponse({
    data: {
      identities: merchantIdentityResponse,
      orchestrationStatus,
    },
  })
}

export default co.wrap(patchMerchantIdentityO)
