import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import CreateRefundForm from 'components/Customer/Forms/CreateRefundForm/CreateRefundForm'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import formatMoney from 'utilities/formatters/formatMoney'
import formatAmountField from 'utilities/formatters/fields/formatAmountField'
import getMany from 'utilities/get/getMany'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { CREATE_REFUND_FORM } from 'constants/formConstants'
import { CREATE_REFUND_CONFIRMATION_MODAL } from 'constants/modalConstants'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import filter from 'lodash/filter'
import concat from 'lodash/concat'

import {
  SPLIT_REFUND_RADIO_BUTTON_NO_OPTION,
  SPLIT_REFUND_RADIO_BUTTON_YES_OPTION,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const [
    transferId,
    transferAmount,
    isSplitParentPayment,
    splitTransfers,
    displayTransferAmount,
    merchantBusinessName,
    noPreviousRefunds,
    primaryMerchantId,
  ] = getMany(props, [
    'transferId',
    'transferAmount',
    'isSplitParentPayment',
    'splitTransfers',
    'displayTransferAmount',
    'merchantBusinessName',
    'noPreviousRefunds',
    'primaryMerchantId',
  ])

  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(CREATE_REFUND_FORM)
  const currentRefundAmount = formSelector(state, 'refundAmount')
  const selectedSplitRefundExperience = formSelector(state, 'splitRefund') === 'split'
  const isPayout = isPayoutFeature({ credentials })
  const offerSplitRefundExperience = isSplitParentPayment && !isPayout
  const normalizedTransferAmount = convertAmountStringToNumber({ amount: transferAmount })

  // use this form errors map to handle row expansion styling to properly show validation error msg
  const formErrorsMap = reduce(splitTransfers, (total, splitTransfer) => {
    const splitTransferId = get(splitTransfer, 'id')
    const splitRefundAmountName = `splitRefundAmount-${splitTransferId}`
    const formErrorExists = !!get(state, `form.${CREATE_REFUND_FORM}.syncErrors.${splitRefundAmountName}`)
    && get(state, `form.${CREATE_REFUND_FORM}.fields.${splitRefundAmountName}.touched`)

    return merge({}, total, {
      [splitTransferId]: formErrorExists,
    })
  }, {})

  const splitRefundAmountInitialValues = noPreviousRefunds ? reduce(splitTransfers, (total, splitTransfer) => {
    const splitTransferId = get(splitTransfer, 'id')
    const splitTransferAmount = get(splitTransfer, 'amount', 0.00)
    const splitRefundAmountName = `splitRefundAmount-${splitTransferId}`

    return merge({}, total, {
      [splitRefundAmountName]: splitTransferAmount,
    })
  }, {}) : {}

  const splitRefundAmountSum = reduce(splitTransfers, (sum, splitTransfer) => {
    const splitTransferId = get(splitTransfer, 'id')
    const normalizedInputtedAmount = formatAmountField(formSelector(state, `splitRefundAmount-${splitTransferId}`))
    const formInput = convertAmountStringToNumber({ amount: normalizedInputtedAmount })
    const splitRefundAmount = formInput ? formInput : 0

    return sum + splitRefundAmount
  }, 0)

  // creating data structure of split refunds for the value to submit to API
  const splitRefundsValuesToSubmit = reduce(splitTransfers, (splitArr, splitTransfer) => {
    const splitTransferId = get(splitTransfer, 'id')
    const splitTransferMerchant = get(splitTransfer, 'merchantId', '')
    const formInput = convertAmountStringToNumber({ amount: formSelector(state, `splitRefundAmount-${splitTransferId}`) })
    const splitRefundAmount = formInput ? formInput : 0

    if (splitRefundAmount > 0) {
      splitArr.push({
        amount: splitRefundAmount,
        merchant: splitTransferMerchant,
      })
    }

    return splitArr
  }, [])

  const normalizedSplitRefundAmountSum = splitRefundAmountSum.toFixed(2)
  const showTotalAmountError = (normalizedSplitRefundAmountSum > normalizedTransferAmount) && selectedSplitRefundExperience
  const splitRefundTotalAmountErrorMsg = `Refund up to ${displayTransferAmount} in total`

  const disabledRefundButton = showTotalAmountError || (selectedSplitRefundExperience && isEqual(splitRefundAmountSum, 0))

  const REFUND_SPLIT_OPTIONS = [
    { label: SPLIT_REFUND_RADIO_BUTTON_YES_OPTION, value: 'split' },
    { label: `${SPLIT_REFUND_RADIO_BUTTON_NO_OPTION} ${merchantBusinessName}.`, value: 'noSplit' },
  ]

  const initialValues = merge({}, {
    refundAmount: noPreviousRefunds ? transferAmount : '',
    splitRefund: offerSplitRefundExperience ? 'split' : 'noSplit',
  }, splitRefundAmountInitialValues)


  const primaryMerchantAssociatedSplitTransfer = find(splitTransfers, { merchantId: primaryMerchantId })
  const otherSplitTransfers = filter(splitTransfers, (element) => !isEqual(element, primaryMerchantAssociatedSplitTransfer))
  const orderedSplitTransfers = concat([primaryMerchantAssociatedSplitTransfer], otherSplitTransfers)

  return {
    id: transferId,
    transferAmount: normalizedTransferAmount,
    credentials,
    currentRefundAmount,
    offerSplitRefundExperience,
    selectedSplitRefundExperience,
    splitRefundRadioButtonOptions: REFUND_SPLIT_OPTIONS,
    splitTransfers: orderedSplitTransfers,
    splitRefundAmountSum,
    displaySplitRefundAmountSum: formatMoney({ amount: splitRefundAmountSum.toFixed(2) }),
    displayTransferAmount,
    splitRefundTotalAmountErrorMsg,
    showTotalAmountError,
    disabledRefundButton,
    splitRefundsValuesToSubmit,
    disabledAmountInput: isPayout,
    formErrorsMap,
    initialValues,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateRefundConfirmationModal: (modalProps => dispatch(showModalAction({ modalType: CREATE_REFUND_CONFIRMATION_MODAL, modalProps }))),
  }
}

class CreateRefundFormC extends Component {
  showCreateRefundConfirmation = () => {
    const {
      transferId,
      currentRefundAmount,
      showCreateRefundConfirmationModal,
      selectedSplitRefundExperience,
      splitRefundAmountSum,
      splitRefundsValuesToSubmit,
      displaySplitRefundAmountSum,
    } = this.props

    showCreateRefundConfirmationModal({
      transferId,
      refundAmount: currentRefundAmount,
      selectedSplitRefundExperience,
      splitRefundAmountSum,
      splitRefundsValuesToSubmit,
      displaySplitRefundAmountSum,
      displayRefundAmount: formatMoney({ amount: currentRefundAmount }),
    })
  }

  render() {
    return (
      <CreateRefundForm
        {...this.props}
        showCreateRefundConfirmation={this.showCreateRefundConfirmation}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRefundFormC)
