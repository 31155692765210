import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccessFormSelector } from 'state-layer/selectors'
import { change, formValueSelector } from 'redux-form'
import LiveAccountApplicationReviewAndSubmitForm from 'components/Customer/Forms/LiveAccountApplicationReviewAndSubmitForm/LiveAccountApplicationReviewAndSubmitForm'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getAccessFormContractRequest from 'utilities/actions/get/getAccessFormContractRequest'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import { LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM } from 'constants/formConstants'
import { CAN } from 'constants/countryConstants'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  FINIX_PRIVACY_POLICY_URL,
  LIVE_ACCOUNT_APPLICATION_PRICING_LINK,
} from 'constants/urlConstants'

import {
  FINIX_MERCHANT_TERMS_OF_SERVICE,
  FINIX_PLATFORM_TERMS_OF_SERVICE,
  HOSTED_ACCESS_FORM_CONFIRMATION_MESSAGE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const accessFormId = get(props, 'accessFormId')
  const isHostedAccessForm = get(props, 'isHostedAccessForm', false)
  const accessFormCountry = get(props, 'accessFormCountry')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const formSelector = formValueSelector(LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM)
  const inquiryId = formSelector(state, 'inquiryId') || get(accessForm, 'personaInquiryId')
  const showPersonaInquirySection = accessFormCountry !== CAN

  const [
    contractId,
    status,
    displayUpdatedAt,
    additionalDocuments,
  ] = getMany(accessForm, [
    'contractId',
    'status',
    'displayUpdatedAt',
    'additionalDocuments',
  ])

  // generate URL for the contract PDF
  const contractUrl = `${getDashboardServiceHost()}/contracts/${contractId}/data`
  let confirmationCheckboxString = isStandaloneMerchant ?
    <div>You agree to the <a className='text-link' onClick={sendAmplitudeActionEvent(contractUrl)} href={contractUrl} target='blank'>Finix Merchant Terms of Service</a>, <a className='text-link' onClick={sendAmplitudeActionEvent(FINIX_PRIVACY_POLICY_URL)} href={FINIX_PRIVACY_POLICY_URL} target='blank'>Finix Privacy Policy</a>, and pricing. You certify that all the information you provided is complete and correct and that you are authorized to sign on behalf of your business. You acknowledge that pricing is subject to eligibility. Finix will contact you to confirm your annual processing volume to determine your final pricing.</div> :
    <div>You agree to the <a className='text-link' onClick={sendAmplitudeActionEvent(contractUrl)} href={contractUrl} target='blank'>{isStandaloneMerchant ? FINIX_MERCHANT_TERMS_OF_SERVICE : FINIX_PLATFORM_TERMS_OF_SERVICE}</a>, <a className='text-link' onClick={sendAmplitudeActionEvent(FINIX_PRIVACY_POLICY_URL)} href={FINIX_PRIVACY_POLICY_URL} target='blank'>Finix Privacy Policy</a>, and <a onClick={sendAmplitudeActionEvent(LIVE_ACCOUNT_APPLICATION_PRICING_LINK)} className='text-link' href={LIVE_ACCOUNT_APPLICATION_PRICING_LINK} target='blank'>pricing</a> you selected. You certify that all the information you provided is complete and correct and that you are authorized to sign on behalf of your business. Finix will notify you if you are ineligible for the pricing you selected.</div>

  if (isHostedAccessForm) {
    confirmationCheckboxString = HOSTED_ACCESS_FORM_CONFIRMATION_MESSAGE
  }

  return {
    accessFormId,
    credentials,
    confirmationCheckboxString,
    contractId,
    status,
    displayUpdatedAt,
    additionalDocuments,
    inquiryId,
    showPersonaInquirySection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessFormContract: ({ credentials, accessFormId }) => dispatch(getAccessFormContractRequest({ credentials, accessFormId })),
  }
}

class LiveAccountApplicationReviewAndSubmitFormC extends Component {
  componentDidMount() {
    const {
      getAccessFormContract,
      credentials,
      accessFormId,
      isHostedAccessForm = false,
    } = this.props

    // fetch the contract url for the access form (do not fetch for hosted form)
    if (!isHostedAccessForm) {
      getAccessFormContract({ credentials, accessFormId })
    }
  }

  personaInquiryOnComplete = ({ inquiryId }) => {
    const { dispatch } = this.props
    dispatch(change(LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM, 'inquiryId', inquiryId))
  }

  render() {
    return (
      <LiveAccountApplicationReviewAndSubmitForm
        {...this.props}
        personaInquiryOnComplete={this.personaInquiryOnComplete}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationReviewAndSubmitFormC)
