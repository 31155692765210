import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import AttestComplianceForm from './AttestComplianceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import toggleCheckedStatus from 'utilities/toggleCheckedStatus'
import { ATTEST_COMPLIANCE_FORM } from 'constants/formConstants'

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(ATTEST_COMPLIANCE_FORM)
  const attestComplianceAgreementAccepted = formSelector(state, 'attestComplianceAgreementAccepted') || false
  const credentials = getCurrentCredentials(state)

  return removeUndefined({
    credentials,
    attestComplianceAgreementAccepted,
  })
}

class AttestComplianceFormC extends Component {
  toggleAcceptAttestComplianceFormAgreement = ({ event }) => {
    const { dispatch } = this.props

    toggleCheckedStatus({ dispatch, event, name: 'attestComplianceAgreementAccepted', form: ATTEST_COMPLIANCE_FORM })
  }

  render() {
    return (
      <AttestComplianceForm
        {...this.props}
        toggleAcceptAttestComplianceFormAgreement={this.toggleAcceptAttestComplianceFormAgreement}
      />
    )
  }
}

export default connect(mapStateToProps)(AttestComplianceFormC)
