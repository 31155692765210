import './DeveloperS.scss'
import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

import {
  DEVELOPER_RESOURCE_TITLE,
  DEVELOPER_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Developer = ({
  applicationId = '',
  actions = [],
  tabs = [],
}) => {
  const subTitle = <span className='subtitle-description'>{DEVELOPER_SUBTITLE_DESCRIPTION}</span>

  const headerData = {
    title: DEVELOPER_RESOURCE_TITLE,
    subTitle,
  }

  return (
    <div className='Developer'>
      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <TabsC
        tabs={tabs}
        applicationId={applicationId}
      />
    </div>
  )
}

Developer.propTypes = {
  identityId: PropTypes.string,
  applicationId: PropTypes.string,
  actions: PropTypes.array,
  tabs: PropTypes.array,
}

export default Developer
