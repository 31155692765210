import { LOG_ERROR_MESSAGE } from 'constants/flowConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'

const errorsR = (state = {}, action = {}) => {
  const actionType = get(action, 'type', '')
  const errors = get(action, 'errors', [])

  switch (actionType) {
    case LOG_ERROR_MESSAGE:
      return merge({}, state, { errors })

    default:
      return state
  }
}

export default errorsR
