import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerOnboardingForm from './CustomerOnboardingForm'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getUnderwritingReviewsRequest from 'utilities/actions/get/getUnderwritingReviewsRequest'
import getCustomerOnboardingFormRequest from 'utilities/actions/get/getCustomerOnboardingFormRequest'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { APPLICATION_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  getAccessFormSelector,
  getCustomerOnboardingFormSelector,
  getUnderwritingReviewSelector,
} from 'state-layer/selectors'

import {
  GENERATE_NEW_URL_LINK_MODAL,
  ADDITIONAL_VERIFICATION_MODAL,
  SEND_TO_UNDERWRITING_MODAL,
} from 'constants/modalConstants'

import {
  ACCOUNT_EXECUTIVE,
  CREATED_ON,
  CUSTOMER_ONBOARDING_FORM,
  STATUS,
  UPDATED_ON,
  SUBMITTED_ON,
  SALESFORCE_OPPORTUNITY_RECORD_ID,
  CUSTOMER_PROFILE,
  GENERATE_NEW_URL_LINK,
  APPLICATION_RESOURCE_TITLE,
  REQUEST_ADDITIONAL_VERIFICATION,
  SUBMIT_TO_UNDERWRITING,
  COUNTRY,
  HUBSPOT_DEAL_ID,
} from 'constants/language/languageConstants'

import {
  accessFormIconMap,
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
  IN_PROGRESS_BE_VALUE,
  SALES_REVIEW_BE_VALUE,
  REJECTED_BE_VALUE,
} from 'constants/statusConstants'

import {
  ACCESS_FORM_STATE_TO_DISPLAY_MAP,
  ACCESS_FORM_STATUS_MAP,
} from 'constants/underwritingConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `customerOnboardingFormsR.${FETCHING}`)
  const isFetchingUnderwritingReview = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const customerOnboardingFormId = get(props, 'params.customerOnboardingFormId')
  const customerOnboardingForm = getCustomerOnboardingFormSelector(state, customerOnboardingFormId)

  const [
    dealName,
    status,
    requestedBy,
    displayCreatedAt,
    displayUpdatedAt,
    hubspotDealId,
    salesforceOpportunityId,
    displayDealType,
    accessFormId,
    displaySubmittedAt,
  ] = getMany(customerOnboardingForm, [
    'dealName',
    'status',
    'requestedBy',
    'displayCreatedAt',
    'displayUpdatedAt',
    'hubspotDealId',
    'salesforceOpportunityId',
    'displayDealType',
    'accessFormId',
    'displaySubmittedAt',
  ])

  const accessForm = getAccessFormSelector(state, accessFormId)

  const [
    applicationId,
    applicationName,
    country,
    reviewId,
  ] = getMany(accessForm, [
    'applicationId',
    'applicationName',
    'country',
    'reviewId',
  ])

  const underwritingReview = getUnderwritingReviewSelector(state, reviewId)
  const rejectedReviewReasons = get(underwritingReview, 'reasons', [])
  const showRejectInformationSection = !isEmpty(rejectedReviewReasons) && status === REJECTED_BE_VALUE
  const isActiveCustomerOnboardingForm = includes([IN_PROGRESS_BE_VALUE, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE], status)
  const isAdditionalVerificationNeeded = status === ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE
  const inSalesReview = includes([SALES_REVIEW_BE_VALUE, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE], status)

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: CUSTOMER_ONBOARDING_FORM,
      id: customerOnboardingFormId,
    },
    items: [
      {
        value: <h1>{dealName}</h1>,
      },
      {
        label: STATUS,
        value: <ColorcodedStatus data={ACCESS_FORM_STATUS_MAP} value={ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] ? ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] : status} customIconMap={accessFormIconMap} titleCase={false} />,
      },
      {
        label: ACCOUNT_EXECUTIVE,
        value: requestedBy,
      },
      {
        label: COUNTRY,
        value: CountryFlagIcon({ country }) || '-',
      },
    ],
  }

  const detailsDataSection = convertPageSectionDataToV2([
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
    {
      label: SUBMITTED_ON,
      value: displaySubmittedAt,
    },
    {
      label: SALESFORCE_OPPORTUNITY_RECORD_ID,
      value: salesforceOpportunityId,
      condition: !!salesforceOpportunityId,
    },
    {
      label: HUBSPOT_DEAL_ID,
      value: hubspotDealId,
      condition: !!hubspotDealId,
    },
    {
      label: CUSTOMER_PROFILE,
      value: displayDealType,
    },
  ])

  return {
    customerOnboardingFormId,
    credentials,
    headerData,
    detailsDataSection,
    accessFormId,
    isActiveCustomerOnboardingForm,
    isAdditionalVerificationNeeded,
    contextBarData,
    inSalesReview,
    reviewId,
    isFetching,
    isFetchingUnderwritingReview,
    showRejectInformationSection,
    rejectedReviewReasons,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerOnboardingForm: ({ customerOnboardingFormId, credentials }) => dispatch(getCustomerOnboardingFormRequest({ customerOnboardingFormId, credentials })),
    showGenerateNewUrlLinkModal: (modalProps) => dispatch(showModalAction({ modalType: GENERATE_NEW_URL_LINK_MODAL, modalProps })),
    showRequestAdditionalVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: ADDITIONAL_VERIFICATION_MODAL, modalProps, className: 'modal-sm no-pad' })),
    showSendToUnderwritingModal: (modalProps) => dispatch(showModalAction({ modalType: SEND_TO_UNDERWRITING_MODAL, modalProps, className: 'modal-sm no-pad' })),
    getUnderwritingReview: ({ queries, credentials }) => dispatch(getUnderwritingReviewsRequest({ queries, credentials })),
  }
}

class CustomerOnboardingFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      customerOnboardingFormId,
      getCustomerOnboardingForm,
    } = this.props

    getCustomerOnboardingForm({ customerOnboardingFormId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { reviewId, getUnderwritingReview, credentials } = this.props
    const { reviewId: prevReviewId } = prevProps

    if (reviewId && reviewId !== prevReviewId) {
      getUnderwritingReview({ queries: { review_id: reviewId }, credentials })
    }
  }

  render() {
    const {
      showGenerateNewUrlLinkModal,
      customerOnboardingFormId,
      isActiveCustomerOnboardingForm,
      isAdditionalVerificationNeeded,
      inSalesReview,
      showRequestAdditionalVerificationModal,
      showSendToUnderwritingModal,
    } = this.props

    const actions = [
      {
        label: GENERATE_NEW_URL_LINK,
        action: () => {
          showGenerateNewUrlLinkModal({
            customerOnboardingFormId,
            isAdditionalVerificationNeeded,
          })
        },
        condition: isActiveCustomerOnboardingForm || isAdditionalVerificationNeeded,
      },
      {
        label: SUBMIT_TO_UNDERWRITING,
        action: () => {
          showSendToUnderwritingModal({
            customerOnboardingFormId,
          })
        },
        condition: inSalesReview && !isAdditionalVerificationNeeded,
      },
      {
        label: REQUEST_ADDITIONAL_VERIFICATION,
        action: () => {
          showRequestAdditionalVerificationModal({
            customerOnboardingFormId,
          })
        },
        condition: inSalesReview && !isAdditionalVerificationNeeded,
      },
    ]

    return (
      <CustomerOnboardingForm
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOnboardingFormC)
