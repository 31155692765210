import React from 'react'
import { goToPath } from 'state-layer/history'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { lastActivityRange } from 'utilities/table/sort'
import { EDIT_MEMBER_PATH } from 'constants/pathConstants'
import { SYSTEM } from 'constants/roleConstants'
import get from 'lodash/get'

import {
  DEACTIVATE_TEAM_MEMBER,
  EMAIL,
  ID,
  LAST_ACCESSED,
  NAME,
  RESEND_DASHBOARD_INVITE_EMAIL,
  STATE,
  EDIT_TEAM_MEMBER,
  REINSTATE_TEAM_MEMBER,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ columnActions = {} }) => [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(NAME, 'fullName', {
    headerFormatter: () => <TableItemSubtitle title={NAME} subtitle={EMAIL} />,
    formatter: ({ fullName, email }) => <TableItemSubtitle title={fullName} subtitle={email} />,
  }),
  column(STATE, 'enabled', {
    formatter: ({ enabled = false }) => <EnabledStatus value={enabled ? 'Active' : 'Inactive'} />,
  }),
  column(LAST_ACCESSED, 'displayLastActivity', {
    className: 'grow',
    sort: lastActivityRange,
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: (member, extraProps) => {
      const [
        showResendDashboardInviteModal,
        showDeactivateTeamMemberModal,
        showReinstateTeamMemberModal,
      ] = getMany(columnActions, [
        'showResendDashboardInviteModal',
        'showDeactivateTeamMemberModal',
        'showReinstateTeamMemberModal',
      ])

      const {
        entityId,
        entityType,
      } = extraProps

      const [
        memberId,
        email,
        enabled,
        dashboardName,
        roleName,
      ] = getMany(member, [
        'id',
        'email',
        'enabled',
        'credentials.name',
        'roleName',
      ])

      const memberName = get(member, 'fullName', email)

      const credentials = getCurrentCredentials()
      const credentialId = get(credentials, 'id')
      const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')

      const actions = [
        {
          label: RESEND_DASHBOARD_INVITE_EMAIL,
          action: () => showResendDashboardInviteModal({ email, dashboardName, entityId }),
          condition: enabled,
        },
        {
          label: EDIT_TEAM_MEMBER,
          action: () => {
            goToPath({
              pathname: EDIT_MEMBER_PATH({ credentialId, memberId }),
              queries: {
                entityType,
                entityId,
              },
            })
          },
          condition: dashboardUserRoleType === SYSTEM,
        },
        {
          label: DEACTIVATE_TEAM_MEMBER,
          action: () => showDeactivateTeamMemberModal({ memberId, memberName, dashboardName }),
          condition: enabled && dashboardUserRoleType === SYSTEM,
        },
        {
          label: REINSTATE_TEAM_MEMBER,
          action: () => showReinstateTeamMemberModal({ memberId, memberName, dashboardName, roleName }),
          condition: !enabled && dashboardUserRoleType === SYSTEM,
        },
      ]

      return (
        <TableRowActionsC
          actions={actions}
        />
      )
    },
  }),
]

export default columnDescriptors
