import paymentsAPI from 'utilities/api/paymentsAPI'
import { LATEST_REPORTS } from 'constants/apiConstants'
import { REPORTS as REPORTS_SERVICE } from 'constants/pathConstants'

const getCategorizedReportsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: LATEST_REPORTS,
    queries,
    credentials,
    service: REPORTS_SERVICE,
  })
}

export default getCategorizedReportsAPI
