import './WithdrawOrApproveUnderwritingEntityModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import WithdrawOrApproveUnderwritingEntityC from 'components/Customer/Pages/UnderwritingV2/WithdrawOrApproveUnderwritingEntity/WithdrawOrApproveUnderwritingEntityC'

import {
  APPROVE_APPLICANT,
  APPROVE_MERCHANT,
  WITHDRAW_APPLICANT,
  WITHDRAW_MERCHANT,
} from 'constants/language/languageConstants'

const WithdrawOrApproveUnderwritingEntityModal = ({
  reviewId = '',
  subjectId = '',
  isApproveMerchantModal,
  isMerchantUnderwriting,
  reviewType = '',
  runId = '',
}) => {
  return (
    <GenericModal
      title={isMerchantUnderwriting ? (isApproveMerchantModal ? APPROVE_MERCHANT : WITHDRAW_MERCHANT) : (isApproveMerchantModal ? APPROVE_APPLICANT : WITHDRAW_APPLICANT)}
      className='WithdrawOrApproveUnderwritingEntityModal'
      Component={WithdrawOrApproveUnderwritingEntityC}
      isApproveMerchantModal={isApproveMerchantModal}
      reviewId={reviewId}
      subjectId={subjectId}
      isMerchantUnderwriting={isMerchantUnderwriting}
      reviewType={reviewType}
      runId={runId}
    />
  )
}

WithdrawOrApproveUnderwritingEntityModal.propTypes = {
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  isApproveMerchantModal: PropTypes.bool,
  isMerchantUnderwriting: PropTypes.bool,
  reviewType: PropTypes.string,
  runId: PropTypes.string,
}

export default WithdrawOrApproveUnderwritingEntityModal
