import React from 'react'

// Chart Types
export const BIG_NUMBER = 'big_number'
export const BIG_NUMBER_TOTAL = 'big_number_total'
export const TIMESERIES_LINE = 'echarts_timeseries_line'
export const MIXED_TIMESERIES = 'mixed_timeseries'
export const TABLE = 'table'
export const ECHART_TIMESERIES_BAR = 'echarts_timeseries_bar'
export const DIST_BAR = 'dist_bar'
export const PIE = 'pie'

export const ORDERED_CHART_CATEGORY_NAMES = ['today', 'this_week', 'this_year']

// Chart Names
export const LAST_UPDATED_AT_BIG_NUMBER_TOTAL = 'last_updated_at_big_number_total'

// Home Charts
export const HOME_PAGE_TOTAL_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL = 'total_transaction_volume_big_number_total'
export const HOME_PAGE_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL = 'total_transaction_volume_count_big_number_total'
export const HOME_PAGE_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL = 'authorization_rate_big_number_total'
export const HOME_PAGE_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL = 'authorization_rate_count_big_number_total'
export const HOME_PAGE_MERCHANT_FEES_COLLECTED_BIG_NUMBER_TOTAL = 'merchant_fees_collected_big_number_total'
export const HOME_PAGE_MERCHANT_FEES_COUNT_BIG_NUMBER_TOTAL = 'merchant_fees_count_big_number_total'
export const HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL = 'avg_transaction_amount_big_number_total'
export const HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL = 'avg_transaction_amount_count_big_number_total'
export const HOME_PAGE_TOTAL_TRANSACTION_VOLUME_AND_COUNT_MIXED_TIMESERIES = 'total_transaction_volume_and_count_mixed_timeseries'
export const HOME_PAGE_AUTHORIZATION_RATE_MIXED_TIMESERIES = 'authorization_rate_mixed_timeseries'
export const HOME_PAGE_MERCHANT_FEES_COLLECTED_ECHARTS_TIMESERIES_BAR = 'merchant_fees_collected_echarts_timeseries_bar'
export const HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_ECHARTS_TIMESERIES_BAR = 'average_transaction_amount_echarts_timeseries_bar'
export const HOME_PAGE_MONTHLY_NET_PROFIT_TABLE = 'monthly_net_profit_table'
export const HOME_PAGE_TOTAL_RESIDUAL_AMOUNT_BIG_NUMBER_TOTAL = 'total_residual_amount_big_number_total'
export const HOME_PAGE_AVERAGE_INTERCHANGE_PERCENT_BIG_NUMBER_TOTAL = 'avg._interchange_%_big_number_total'
export const HOME_PAGE_AVERAGE_DUES_AND_ASSESSMENTS_PERCENT_BIG_NUMBER_TOTAL = 'avg._dues_&_assessments_%_big_number_total'
export const HOME_PAGE_CARD_PAYMENTS_BIG_NUMBER_TOTAL = 'card_payments_big_number_total'
export const HOME_PAGE_ACTIVE_MERCHANTS_BIG_NUMBER_TOTAL = 'active_merchants_big_number_total'
export const HOME_PAGE_DISPUTES_BIG_NUMBER_TOTAL = 'disputes_big_number_total'
export const HOME_PAGE_ACH_RETURNS_BIG_NUMBER_TOTAL = 'ach_returns_big_number_total'
export const HOME_PAGE_CHARGEBACK_RATE_BIG_NUMBER_TOTAL = 'chargeback_rate_big_number_total'
export const HOME_PAGE_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL = "last_month\'s_total_transaction_vol._big_number_total"
export const HOME_PAGE_MERCHANT_TOTAL_DEPOSITS_BIG_NUMBER_TOTAL = 'total_deposits_big_number_total'
export const HOME_PAGE_MERCHANT_SETTLEMENT_VOLUME_ECHARTS_TIMESERIES_BAR = 'settlement_volume_echarts_timeseries_bar'
export const HOME_PAGE_MERCHANT_DEPOSITS_ECHARTS_TIMESERIES_BAR = 'merchant_deposits_echarts_timeseries_bar'
export const HOME_PAGE_MERCHANT_ACH_PAYMENTS_BIG_NUMBER_TOTAL = 'ach_payments_big_number_total'
export const HOME_PAGE_MERCHANT_REFUNDS_BIG_NUMBER_TOTAL = 'refunds_big_number_total'
export const HOME_PAGE_MERCHANT_TOTAL_TRANSACTIONS_BIG_NUMBER_TOTAL = 'total_transactions_big_number_total'

// Transaction Insights - Payments Tab
export const PAYMENTS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL = 'total_transaction_vol_big_number_total'
export const PAYMENTS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL = 'total_transaction_volume_count_big_number_total'
export const PAYMENTS_SUCCESSFUL_SALES_BIG_NUMBER_TOTAL = 'successful_sales_big_number_total'
export const PAYMENTS_SUCCESSFUL_SALES_COUNT_BIG_NUMBER_TOTAL = 'count_of_succeeded_big_number_total'
export const PAYMENTS_FAILED_SALES_BIG_NUMBER_TOTAL = 'failed_sales_big_number_total'
export const PAYMENTS_FAILED_SALES_COUNT_BIG_NUMBER_TOTAL = 'count_of_failed_big_number_total'
export const PAYMENTS_AVG_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL = 'avg._transaction_amount_big_number_total'
export const PAYMENTS_AVG_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL = 'avg_transaction_amount_count_big_number_total'
export const PAYMENTS_PAYMENT_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR = 'payment_volume_by_payment_method_echarts_timeseries_bar'
export const PAYMENTS_PAYMENT_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR = 'payment_count_by_payment_method_echarts_timeseries_bar'
export const PAYMENTS_PAYMENT_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR = 'payment_volume_by_card_brand_echarts_timeseries_bar'
export const PAYMENTS_PAYMENT_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR = 'payment_count_by_card_brand_echarts_timeseries_bar'
export const PAYMENTS_PAYMENTS_PER_MERCHANT_TABLE = 'payments_per_merchant_table'
export const PAYMENTS_CARD_PAYMENT_DATA_TABLE = 'card_payment_data_table'
export const PAYMENTS_FAILED_TRANSACTIONS_BY_FAILURE_CODE_TABLE = 'failed_transactions_by_failure_code_table'

// Transaction Insights - Authorizations Tab
export const AUTHORIZATIONS_REQUESTS_RECEIVED_BIG_NUMBER_TOTAL = 'requests_received_big_number_total'
export const AUTHORIZATIONS_REQUESTS_RECEIVED_COUNT_BIG_NUMBER_TOTAL = 'requests_received_count_big_number_total'
export const AUTHORIZATIONS_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL = 'authorization_rate_big_number_total'
export const AUTHORIZATIONS_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL = 'authorization_rate_count_big_number_total'
export const AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_BIG_NUMBER_TOTAL = 'voided_authorizations_big_number_total'
export const AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL = 'voided_authorizations_count_big_number_total'
export const AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_BIG_NUMBER_TOTAL = 'captured_authorizations_big_number_total'
export const AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL = 'captured_authorizations_count_big_number_total'
export const AUTHORIZATIONS_AUTHORIZATION_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE = 'authorization_rate_by_card_brand_echarts_timeseries_line'
export const AUTHORIZATIONS_AUTHORIZATION_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR = 'authorization_count_by_card_brand_echarts_timeseries_bar'
export const AUTHORIZATIONS_AUTHORIZATIONS_PER_MERCHANT_TABLE = 'authorizations_per_merchant_table'
export const AUTHORIZATIONS_CARD_AUTHORIZATION_DATA_TABLE = 'card_authorization_data_table'
export const AUTHORIZATIONS_FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TABLE = 'failed_authorizations_by_failure_code_table'

// Transaction Insights - Refunds Tab
export const REFUNDS_GROSS_PROCESSING_VOLUME_BIG_NUMBER_TOTAL = 'gross_processing_volume_big_number_total'
export const REFUNDS_GROSS_PROCESSING_VOLUME_COUNT_BIG_NUMBER_TOTAL = 'count_gross_processing_volume_big_number_total'
export const REFUNDS_SUCCESSFUL_REFUNDS_BIG_NUMBER_TOTAL = 'successful_refunds_big_number_total'
export const REFUNDS_SUCCESSFUL_REFUNDS_COUNT_BIG_NUMBER_TOTAL = 'count_successful_refunds_big_number_total'
export const REFUNDS_FAILED_REFUNDS_BIG_NUMBER_TOTAL = 'failed_refunds_big_number_total'
export const REFUNDS_FAILED_REFUNDS_COUNT_BIG_NUMBER_TOTAL = 'count_failed_refunds_big_number_total'
export const REFUNDS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL = 'total_transaction_vol._big_number_total'
export const REFUNDS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL = 'total_transaction_volume_count_big_number_total'
export const REFUNDS_REFUND_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR = 'refund_volume_by_payment_method_echarts_timeseries_bar'
export const REFUNDS_REFUND_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR = 'refund_count_by_payment_method_echarts_timeseries_bar'
export const REFUNDS_REFUND_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR = 'refund_volume_by_card_brand_echarts_timeseries_bar'
export const REFUNDS_REFUND_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR = 'refund_count_by_card_brand_echarts_timeseries_bar'
export const REFUNDS_REFUNDS_PER_MERCHANT_TABLE = 'refunds_per_merchant_table'
export const REFUNDS_CARD_REFUND_DATA_TABLE = 'card_refund_data_table'


// Exceptions Insights - Disputes Tab
export const DISPUTE_VOLUME_AND_COUNT_MIXED_TIMESERIES_BAR = 'dispute_volume_and_count_mixed_timeseries'
export const DISPUTE_STATES_PIE = 'dispute_states_pie'
export const DISPUTE_RESPONSE_STATES_PIE = 'dispute_response_states_pie'
export const DISPUTE_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE = 'dispute_rate_by_card_brand_echarts_timeseries_line'
export const TOTAL_DISPUTE_VOLUME_BIG_NUMBER_TOTAL = 'total_dispute_volume_big_number_total'
export const WIN_RATE_BIG_NUMBER_TOTAL = 'win_rate_big_number_total'
export const COUNT_OF_WON_DISPUTES_BIG_NUMBER_TOTAL = 'count_of_won_disputes_big_number_total'
export const COUNT_OF_ACTIVE_DISPUTES_BIG_NUMBER_TOTAL = 'count_of_active_disputes_big_number_total'
export const ACTIVE_DISPUTE_VOLUME_BIG_NUMBER_TOTAL = 'active_dispute_volume_big_number_total'
export const COUNT_OF_DISPUTES_BIG_NUMBER_TOTAL = 'count_of_disputes_big_number_total'
export const COUNT_OF_DISPUTES_DISPUTE_RATE_BIG_NUMBER_TOTAL = 'count_of_disputes_dispute_rate_big_number_total'
export const DISPUTE_RATE_BIG_NUMBER_TOTAL = 'dispute_rate_big_number_total'
export const DISPUTES_PER_MERCHANT_CARD_BRAND_DISPUTE_RATE_TABLE = 'disputes_per_merchant_card_brand_dispute_rate_table'
export const CARD_DISPUTE_DATE_TABLE = 'card_dispute_data_table'
export const CARD_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL = 'card_transaction_volume_big_number_total'
export const COUNT_OF_CARD_TRANSACTIONS_BIG_NUMBER_TOTAL = 'count_of_card_transactions_big_number_total'
export const DISPUTE_REASONS_TABLE = 'dispute_reasons_table'

// Exceptions Insights - ACH Returns Tab
export const ACH_RETURNS_VOLUME_BIG_NUMBER_TOTAL = 'ach_return_volume_big_number_total'
export const ACH_RETURN_COUNT_BIG_NUMBER_TOTAL = 'ach_return_count_big_number_total'
export const ACH_RETURN_RATE_BIG_NUMBER_TOTAL = 'ach_return_rate_big_number_total'
export const TOTAL_ACH_VOLUME_BIG_NUMBER_TOTAL = 'total_ach_volume_big_number_total'
export const ACH_RETURN_VOLUME_AND_COUNT_TIMESERIES_BAR = 'ach_return_volume_and_count_mixed_timeseries'
export const ACH_RETURN_RATE_ECHARTS_TIMESERIES_LINE = 'ach_return_rate_echarts_timeseries_line'
export const ACH_RETURNS_BY_MERCHANT_TABLE = 'ach_returns_by_merchant_table'
export const ACH_RETURNS_BY_REASON_CODE_TABLE = 'ach_returns_by_reason_code_table'

// Merchant Insights - Merchant Accounts Tab
export const MERCHANT_ACCOUNTS_ONBOARDED_MERCHANTS = 'onboarded_merchants_big_number_total'
export const MERCHANT_ACCOUNTS_REJECTED_MERCHANTS = 'rejected_merchants_big_number_total'
export const MERCHANT_ACCOUNTS_ACTIVE_MERCHANTS = 'active_merchants_big_number_total'
export const MERCHANT_ACCOUNTS_PROFITABLE_MERCHANTS = 'profitable_merchants_big_number_total'
export const MERCHANT_ACCOUNTS_MERCHANTS_ONBOARDING_STATE = 'onboarding_state_echarts_timeseries_bar'
export const MERCHANT_ACCOUNTS_MERCHANTS_ACTIVE_MERCHANTS = 'active_merchants_echarts_timeseries_bar'
export const MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACTION_VOLUME_BY_MERCHANT = 'total_transaction_volume_by_merchant_table'
export const MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACATION_BY_MCC = 'total_transaction_volume_by_mcc_table'
export const MERCHANT_ACCOUNTS_MERCHANTS_BY_LOCATION = 'merchants_by_location_table'
export const MERCHANT_ACCOUNTS_TOTAL_TRANSACTION_VOLUME = 'count_of_total_transaction_volume_big_number_total'

// Merchant Insights - Compliance Tab
export const COMPLIANCE_OVERDUE_FORMS_BIG_NUMBER_TOTAL = 'overdue_forms_big_number_total'
export const COMPLIANCE_FORMS_DUE_IN_30_DAYS = 'forms_due_within_30_days_big_number_total'
export const COMPLIANCE_FORMS_DUE_IN_60_DAYS = 'forms_due_within_60_days_big_number_total'
export const COMPLIANCE_AVG_TIME_TO_COMPLETION = 'avg._time_to_completion_big_number_total'
export const COMPLIANCE_COMPLIANCE_FORMS_CREATED = 'compliance_forms_created_echarts_timeseries_bar'
export const COMPLIANCE_COMPLIANCE_FORMS_STATUS = 'compliance_form_status_dist_bar'
export const COMPLIANCE_OVERDUE_PCI_COMPLIANCE_FORM= 'overdue_compliance_forms_table'
export const COMPLIANCE_INCOMPLETE_PCI_COMPLIANCE_FORM = 'incomplete_compliance_forms_table'
export const COMPLIANCE_COMPLIANCE_FORM_STATUS_PIE_CHART = 'compliance_form_status_pie'


// Merchant Insights - Onboarding Forms
export const ONBOARDING_FORMS_FORMS_GENERATED_BIG_NUMBER_TOTAL = 'forms_generated_big_number_total'
export const ONBOARDING_FORMS_FORMS_COMPLETED_BIG_NUMBER_TOTAL = 'forms_completed_big_number_total'
export const ONBOARDING_FORMS_IN_PROGRESS_BIG_NUMBER_TOTAL = 'forms_in_progress_big_number_total'
export const ONBOARDING_FORMS_EXPIRED_BIG_NUMBER_TOTAL = 'forms_in_progress_big_number_total'
export const ONBOARDING_FORMS_ONBOARDING_FORMS_GENERATED_ECHART_TIMESERIES_BAR = 'onboarding_forms_generated_echarts_timeseries_bar'
export const ONBOARDING_FORMS_ONBOARDING_FORMS_COMPLETION_RATE_ECHART_TIMESERIES_BAR = 'onboarding_form_completion_rate_echarts_timeseries_line'
export const ONBOARDING_FORMS_INCOMPLETE_ONBOARDING_FORMS = 'incomplete_compliance_forms_table'

// Underwriting Insights - Merchant Underwriting
export const TOTAL_MERCHANTS_BIG_NUMBER_TOTAL = 'total_merchants_big_number_total'
export const AUTOMATIC_APPROVAL_RATE_BIG_NUMBER_TOTAL = 'automatic_approval_rate_big_number_total'
export const TOTAL_APPROVAL_RATE_BIG_NUMBER_TOTAL = 'total_approval_rate_big_number_total'
export const IDENTITY_SCORE_RESULTS_ECHARTS_TIMESERIES_BAR = 'identity_score_results_echarts_timeseries_bar'
export const MERCHANT_COUNT_BY_NUMBER_OF_BUSINESS_OWNERS_PIE = 'merchant_count_by_number_of_business_owners_pie'
export const ALL_APPLICATIONS_TABLE =  'all_applications_table'
export const COMPLIANCE_SCORES_RESULTS_ECHARTS_TIMESERIES_BAR = 'compliance_score_results_echarts_timeseries_bar'
export const KYB_RESULTS_ECHARTS_TIMESERIES_BAR = 'kyb_results_echarts_timeseries_bar'
export const KYC_RESULTS_ECHARTS_TIMESERIES_BAR = 'kyc_results_echarts_timeseries_bar'
export const BUSINESS_OFAC_RESULTS_ECARTS_TIMESERIES_BAR = 'business_ofac_results_echarts_timeseries_bar'
export const OWNER_OFAC_RESULTS_ECHARTS_TIMESERIES_BAR = 'owner_ofac_results_echarts_timeseries_bar'
export const ALL_MERCHANTS_TABLE = 'all_merchants_table'
export const AVG_TIME_IN_MANUAL_REVIEW_BIG_NUMBER_TOTAL = 'avg._time_in_manual_review_big_number_total'
export const TOTAL_MERCHANT_COUNT_BY_UNDERWRITING_STATUS_ECHARTS_TIMESERIES_BAR = 'total_merchant_count_by_underwriting_status_echarts_timeseries_bar'
export const APPROVAL_RATES_ECHARTS_TIMESERIES_LINE = 'approval_rates_echarts_timeseries_line'
export const BUSINESS_TYPES_TABLE = 'business_types_table'
export const MERCHANT_COUNT_BY_BUSINESS_OWNERSHIP_TYPE_PIE = 'merchant_count_by_business_ownership_type_pie'
