import { fieldEmpty } from 'utilities/validate'
import { getState } from 'state-layer/configureStore'
import get from 'lodash/get'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'

const validateComplianceExplanationOfNonApplicabilityForm = ({
  explanationOfNonApplicability = [],
}) => {
  const state = getState()
  const complianceSelfAssessmentQuestionnaireAFormValues = get(state, 'flowsR.formValues.complianceSelfAssessmentQuestionnaireAForm')
  const saqAFormHasNA = some(complianceSelfAssessmentQuestionnaireAFormValues, (value) => value === 'N/A')

  if (saqAFormHasNA && isEmpty(explanationOfNonApplicability)) {
    return { explanationOfNonApplicability: { _error: 'At least one requirement must be added.' } }
  }

  const explanationOfNonApplicabilityTotalErrors = []

  explanationOfNonApplicability.forEach((explanation, index) => {
    const { requirement, reason_requirement_is_not_applicable: reason } = explanation

    explanationOfNonApplicabilityTotalErrors[index] = {
      requirement: fieldEmpty(requirement, 'Requirement'),
      reason_requirement_is_not_applicable: fieldEmpty(reason, 'Reason'),
    }
  })

  return {
    explanationOfNonApplicability: explanationOfNonApplicabilityTotalErrors,
  }
}

export default validateComplianceExplanationOfNonApplicabilityForm
