import dashboardAPI from 'utilities/api/dashboardAPI'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'

import {
  DASHBOARD_SERVICE_APP_CONFIGURATION,
  DASHBOARD_SERVICE_APP_CONFIGURATION_V2,
} from 'constants/apiConstants'

const getAppConfigurationAPI = ({ meta, id }) => {
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_APP_CONFIGURATION_V2({ dashboardId: id }) : DASHBOARD_SERVICE_APP_CONFIGURATION

  return dashboardAPI.get({ path, meta })
}

export default getAppConfigurationAPI
