import removeUndefined from 'utilities/remove/removeUndefined'

const backendDisputeModel = ({ values = {} }) => {
  const {
    amount,
    caseId,
    reason,
    reasonCode,
    respondBy,
    state,
    responseState,
    message,
    action,
    transferId,
  } = values

  return removeUndefined({
    amount,
    dispute_details: {
      case_id: caseId,
      reason_code: reasonCode,
    },
    reason,
    respond_by: respondBy,
    state,
    response_state: responseState,
    message,
    action,
    transfer: transferId,
  })
}

export default backendDisputeModel
