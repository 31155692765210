import moment from 'moment'
import { getState } from 'state-layer/configureStore'
import removeUndefined from 'utilities/remove/removeUndefined'
import getCurrentPlatformId from 'utilities/get/getCurrentPlatformId'
import { YYYY_MM_DD } from 'constants/timeConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'
import some from 'lodash/some'

import {
  PLATFORM,
  APPLICATION,
} from 'constants/payoutPlanConstants'

const backendComplianceFormTemplateModel = ({ values }) => {
  const {
    name,
    linkedTo = '', // post
    linkTo, // patch
    type,
    qsaBusinessName,
    qsaContactName,
    qsaTitle,
    qsaTelephone,
    qsaEmail,
    qsaUrl,
    qsaBusinessAddress,
    typeMerchantBusiness,
    typeMerchantBusinessOther,
    typePaymentChannelsServe,
    paymentChannelsCovered,
    howAndInWhatCapacityDoesYourBusinessHandleCardholderData,
    facilityLocations,
    oneOrMorePaymentApplications,
    paymentApplicationsUsed,
    provideHighLevelDescriptionEnvironment,
    networkSegmentation,
    usedQir,
    nameOfQirCompany,
    qirIndividualName,
    descriptionOfServicesProvidedByQir,
    companyShareCardholderDataWithThirdPartyServiceProviders,
    thirdPartyServiceProviders,
    eligibilityToCompleteSaqA,
    vendorSuppliedDefaultsChangedBeforeInstallOnNetwork,
    unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork,
    systemAndSoftwareProtectedFromKnownVulnerabilities,
    criticalSecurityPatchesInstalledWithinOneMonthOfRelease,
    usersAssignedUniqueIdBeforeAccessToSystem,
    accessForTerminatedUsersRemoved,
    authenticateUsersUsingPasswordOrTokenOrBiometric,
    userPasswordParametersConfiguredToMeetReqs,
    groupSharedAccountsPasswordsAuthenticationMethodsProhibited,
    allMediaPhysicallySecured,
    strictControlOverDistributionOfMedia,
    mediaClassifiedToDetermineSensitivityOfData,
    mediaSentCanBeAccuratelyTracked,
    managementApprovalObtainedToMoveMedia,
    strictControlMaintainedOverStorageAndAccessibilityOfMedia,
    mediaDestroyedWhenNoLongerNeeded,
    hardCopyMaterialsCrosscutShreddedIncineratedOrPulped,
    storageContainersForMaterialsDestroyedToPreventAccessToContents,
    listOfServiceProvidersMaintained,
    writtenAgreementMaintained,
    establishedProcessForEngagingServicesProviders,
    programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually,
    informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity,
    incidentResponsePlanBeenCreatedInCaseOfSystemBreach,
    constraints,
    objective,
    identifiedRisk,
    definitionOfCompensatingControls,
    validationOfCompensatingControls,
    maintenance,
    explanationOfNonApplicability,
    pciDssValidation,
    compliantWithLegalExceptionDetails,
    acknowledgementOfStatus,
    qsaSignature,
    qsaInvolvedDescribeRolePerformed,
    qsaDate,
    qsaDulyAuthorizedOfficerName,
    qsaCompany,
    isaInvolvedDescribeRolePerformed,
    doNotUserVendorSuppliedDefaults,
    doNotUserVendorSuppliedDefaultsDate,
    doNotUserVendorSuppliedDefaultsDescription,
    developAndMaintainSecureSystems,
    developAndMaintainSecureSystemsDate,
    developAndMaintainSecureSystemsDescription,
    identifyAndAuthenticateAccessToSystemComponents,
    identifyAndAuthenticateAccessToSystemComponentsDate,
    identifyAndAuthenticateAccessToSystemComponentsDescription,
    restrictPhysicalAccessToCardholder,
    restrictPhysicalAccessToCardholderDate,
    restrictPhysicalAccessToCardholderDescription,
    maintainAPolicyAddressesInformationSecurity,
    maintainAPolicyAddressesInformationSecurityDate,
    maintainAPolicyAddressesInformationSecurityDescription,
  } = values

  const platformId = getCurrentPlatformId()
  const linkedType = linkedTo.slice(0, 2) === 'PL' ? PLATFORM : APPLICATION // TODO: create util to check if ID is a certain resource

  const formattedPaymentApplicationsUsed = map(paymentApplicationsUsed, (paymentApplication) => {
    const expiryDate = get(paymentApplication, 'pa_dss_listing_expiry_date')

    return merge({}, paymentApplication, {
      pa_dss_listing_expiry_date: expiryDate ? moment(expiryDate).format(YYYY_MM_DD) : undefined,
    })
  })

  const state = getState()
  const complianceSelfAssessmentQuestionnaireAFormValues = get(state, 'flowsR.formValues.complianceSelfAssessmentQuestionnaireAForm')
  const saqAFormHasYesWithCCW = some(complianceSelfAssessmentQuestionnaireAFormValues, (value) => value === 'YES_WITH_CCW')
  const saqAFormHasNA = some(complianceSelfAssessmentQuestionnaireAFormValues, (value) => value === 'N/A')

  return removeUndefined({
    display_name: name,
    link_to: linkTo,
    platform_id: type ? platformId : undefined, // only pass certain properties if creating a template
    linked_to: type ? linkedTo : undefined,
    linked_type: type ? linkedType : undefined,
    version: type ? '2018.15' : undefined,
    type,
    compliance_form_template_data: type ? {
      // page 2
      qualified_security_assesor: {
        company_name: qsaBusinessName,
        lead_qsa_contact_name: qsaContactName,
        title: qsaTitle,
        telephone: qsaTelephone,
        email: qsaEmail,
        url: qsaUrl,
        business_address: get(qsaBusinessAddress, 'line1'),
        city: get(qsaBusinessAddress, 'city'),
        state_province: get(qsaBusinessAddress, 'region'),
        zip: get(qsaBusinessAddress, 'postalCode'),
        country: get(qsaBusinessAddress, 'country'),
      },
      type_merchant_business: {
        others: typeMerchantBusinessOther ? true : undefined,
        others_description: typeMerchantBusinessOther,
        ...typeMerchantBusiness,
      },
      type_payment_channels_busniess_serve: typePaymentChannelsServe,
      payment_channels_covered: paymentChannelsCovered,
      how_and_in_what_capacity_does_your_business_handle_cardholder_data: howAndInWhatCapacityDoesYourBusinessHandleCardholderData,
      facility_locations: facilityLocations,
      one_or_more_payment_applications: oneOrMorePaymentApplications,
      payment_applications_used: oneOrMorePaymentApplications === 'YES' ? formattedPaymentApplicationsUsed : undefined,
      provide_high_level_description_environnment: provideHighLevelDescriptionEnvironment,
      network_segmentation: networkSegmentation,
      qualified_integrator_reseller: {
        used_qir: usedQir,
        name_of_qir_company: usedQir === 'YES' ? nameOfQirCompany : undefined,
        quailified_integrator_and_reseller_individual_name: usedQir === 'YES' ? qirIndividualName : undefined,
        description_of_services_provided_by_qir: usedQir === 'YES' ? descriptionOfServicesProvidedByQir : undefined,
      },
      company_share_cardholder_data_with_third_party_service_providers: companyShareCardholderDataWithThirdPartyServiceProviders,
      third_party_service_providers: companyShareCardholderDataWithThirdPartyServiceProviders === 'YES' ? thirdPartyServiceProviders : undefined,
      eligibility_to_complete_SAQ_A: eligibilityToCompleteSaqA,

      // page 3
      self_assesment_questionnaire: {
        vendor_supplied_defaults_changed_before_install_on_network: vendorSuppliedDefaultsChangedBeforeInstallOnNetwork,
        unnecessary_default_accounts_removed_or_disabled_before_install_on_network: unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork,
        system_and_software_protected_from_known_vulnerabilities: systemAndSoftwareProtectedFromKnownVulnerabilities,
        critical_security_patches_installed_within_one_month_of_release: criticalSecurityPatchesInstalledWithinOneMonthOfRelease,
        users_assigned_unique_id_before_access_to_system: usersAssignedUniqueIdBeforeAccessToSystem,
        access_for_terminated_users_removed: accessForTerminatedUsersRemoved,
        authenticate_users_using_passord_or_token_or_biometric: authenticateUsersUsingPasswordOrTokenOrBiometric,
        user_password_parameters_configured_to_meet_reqs: userPasswordParametersConfiguredToMeetReqs,
        group_shared_accounts_passwords_authentication_methods_prohibited: groupSharedAccountsPasswordsAuthenticationMethodsProhibited,
        all_media_physically_secured: allMediaPhysicallySecured,
        strict_control_over_distribution_of_media: strictControlOverDistributionOfMedia,
        media_classified_to_determine_sensitivity_of_data: mediaClassifiedToDetermineSensitivityOfData,
        media_sent_can_be_accurately_tracked: mediaSentCanBeAccuratelyTracked,
        management_approval_obtained_to_move_media: managementApprovalObtainedToMoveMedia,
        strict_control_maintained_over_storage_and_accessibility_of_media: strictControlMaintainedOverStorageAndAccessibilityOfMedia,
        media_detroyed_when_no_longer_needed: mediaDestroyedWhenNoLongerNeeded,
        hardcopy_materials_crosscut_shredded_incinerated_or_pulped: hardCopyMaterialsCrosscutShreddedIncineratedOrPulped,
        storage_containers_for_materials_destroyed_to_prevent_access_to_contents: storageContainersForMaterialsDestroyedToPreventAccessToContents,
        list_of_service_providers_maintained: listOfServiceProvidersMaintained,
        written_agreement_maintained: writtenAgreementMaintained,
        established_process_for_enagaing_services_providers: establishedProcessForEngagingServicesProviders,
        program_maintained_to_monitor_service_providers_pci_dss_status_at_least_annually: programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually,
        information_maintained_about_which_pci_dss_reqs_per_service_provider_and_managed_by_entity: informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity,
        incident_response_plan_been_created_in_case_of_system_breach: incidentResponsePlanBeenCreatedInCaseOfSystemBreach,
      },

      // // page 4
      pci_dss_compensating_controls: {
        constraints: saqAFormHasYesWithCCW ? constraints : undefined,
        objective: saqAFormHasYesWithCCW ? objective : undefined,
        identified_risk: saqAFormHasYesWithCCW ? identifiedRisk : undefined,
        definition_of_compensating_controls: saqAFormHasYesWithCCW ? definitionOfCompensatingControls : undefined,
        validation_of_compensating_controls: saqAFormHasYesWithCCW ? validationOfCompensatingControls : undefined,
        maintenance: saqAFormHasYesWithCCW ? maintenance : undefined,
      },

      // page 5
      explanation_of_non_applicability: saqAFormHasNA ? explanationOfNonApplicability : undefined,

      // page 6
      pci_dss_validation: {
        [pciDssValidation]: pciDssValidation ? true : undefined,
        compliance_with_legal_exception_affected_requirement_1: pciDssValidation === 'compliant_with_legal_exception' ? get(compliantWithLegalExceptionDetails, '0.affected_requirement') : undefined,
        compliance_with_legal_exception_details_legal_constraint_1: pciDssValidation === 'compliant_with_legal_exception' ? get(compliantWithLegalExceptionDetails, '0.details') : undefined,
        compliance_with_legal_exception_affected_requirement_2: pciDssValidation === 'compliant_with_legal_exception' ? get(compliantWithLegalExceptionDetails, '1.details') : undefined,
        compliance_with_legal_exception_details_legal_constraint_2: pciDssValidation === 'compliant_with_legal_exception' ? get(compliantWithLegalExceptionDetails, '1.details') : undefined,
        ...acknowledgementOfStatus,
      },
      qualified_security_assessor_attestation: {
        signature: qsaSignature,
        qsa_involved_describe_role_performed: qsaInvolvedDescribeRolePerformed,
        date: qsaDate ? moment(qsaDate).format(YYYY_MM_DD) : undefined,
        duty_authorized_officer_name: qsaDulyAuthorizedOfficerName,
        qsa_company: qsaCompany,
      },
      isa_involved_describe_role_performed: isaInvolvedDescribeRolePerformed,
      action_plan_non_compliant_requirements: {
        do_not_user_vendor_supplied_defaults: doNotUserVendorSuppliedDefaults,
        do_not_user_vendor_supplied_defaults_description: doNotUserVendorSuppliedDefaults === 'NO' ? `${moment(doNotUserVendorSuppliedDefaultsDate).format(YYYY_MM_DD)} - ${doNotUserVendorSuppliedDefaultsDescription}` : undefined,
        develop_and_maintain_secure_systems: developAndMaintainSecureSystems,
        develop_and_maintain_secure_systems_description: developAndMaintainSecureSystems === 'NO' ? `${moment(developAndMaintainSecureSystemsDate).format(YYYY_MM_DD)} - ${developAndMaintainSecureSystemsDescription}` : undefined,
        identify_and_authenticate_access_to_system_components: identifyAndAuthenticateAccessToSystemComponents,
        identify_and_authenticate_access_to_system_components_description: identifyAndAuthenticateAccessToSystemComponents === 'NO' ? `${moment(identifyAndAuthenticateAccessToSystemComponentsDate).format(YYYY_MM_DD)} - ${identifyAndAuthenticateAccessToSystemComponentsDescription}` : undefined,
        restrict_physical_access_to_cardholder: restrictPhysicalAccessToCardholder,
        restrict_physical_access_to_cardholder_description: restrictPhysicalAccessToCardholder === 'NO' ? `${moment(restrictPhysicalAccessToCardholderDate).format(YYYY_MM_DD)} - ${restrictPhysicalAccessToCardholderDescription}` : undefined,
        maintain_a_policy_adresses_information_security: maintainAPolicyAddressesInformationSecurity,
        maintain_a_policy_adresses_information_security_description: maintainAPolicyAddressesInformationSecurity === 'NO' ? `${moment(maintainAPolicyAddressesInformationSecurityDate).format(YYYY_MM_DD)} - ${maintainAPolicyAddressesInformationSecurityDescription}` : undefined,
      },
    } : undefined,
  })
}

export default backendComplianceFormTemplateModel
