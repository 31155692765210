import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReinstateTeamMember from './ReinstateTeamMember'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchCredentialRequest from 'utilities/actions/patch/patchCredentialRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { REINSTATE_TEAM_MEMBER } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const memberId = get(props, 'memberId')
  const memberName = get(props, 'memberName')

  return {
    credentials,
    credentialId,
    memberId,
    memberName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reinstateTeamMemberAction: ({ credentials, memberName, memberId, dashboardName }) => dispatch(patchCredentialRequest({
      credentials,
      memberName,
      memberId,
      dashboardName,
      amplitudeActionType: REINSTATE_TEAM_MEMBER,
      values: {
        enabled: true,
      },
      successMessage: `${memberName} has been successfully reinstated to this dashboard`,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ReinstateTeamMemberC extends Component {
    reinstateTeamMember = () => {
      const {
        credentials,
        memberId,
        memberName,
        reinstateTeamMemberAction,
        closeModal,
        dashboardName,
      } = this.props

      reinstateTeamMemberAction({ credentials, memberId, memberName, dashboardName })
      closeModal()
    }

    render() {
      return (
        <ReinstateTeamMember
          {...this.props}
          reinstateTeamMember={this.reinstateTeamMember}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReinstateTeamMemberC)
