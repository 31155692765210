import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import StartLiveAccountApplicationForm from 'components/Customer/Forms/StartLiveAccountApplicationForm/StartLiveAccountApplicationForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import redirectRequest from 'utilities/actions/redirectRequest'
import { START_LIVE_ACCOUNT_APPLICATION_FORM } from 'constants/formConstants'
import { CAN } from 'constants/countryConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  START_APPLICATION,
  TALK_TO_SALES,
} from 'constants/language/languageConstants'

import {
  ADMINISTRATOR,
  LIVE_ACCOUNT_APPLICATION_FORM_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const userRoleName = get(credentials, 'displayDashboardUserRoleName')
  const isAdministratorRole = isEqual(userRoleName, ADMINISTRATOR)
  const formSelector = formValueSelector(START_LIVE_ACCOUNT_APPLICATION_FORM)
  const country = formSelector(state, 'country')
  const disableStartApplication = !isAdministratorRole || !country
  const actionLabel = country === CAN ? TALK_TO_SALES : START_APPLICATION

  return {
    credentialId,
    disableStartApplication,
    actionLabel,
    country,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLiveApplicationRedirect: (credentialId) => dispatch(redirectRequest({ path: LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId }) })),
  }
}

class StartLiveAccountApplicationFormC extends Component {
  render() {
    const { startLiveApplicationRedirect, credentialId } = this.props

    const startLiveApplication = () => {
      startLiveApplicationRedirect(credentialId)
    }

    return (
      <StartLiveAccountApplicationForm
        {...this.props}
        startLiveApplication={startLiveApplication}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartLiveAccountApplicationFormC)
