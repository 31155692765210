import WelcomeEmail from './WelcomeEmail'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  WELCOME_EMAIL_TEMPLATE,
  GET_WELCOME_EMAIL_TEMPLATE_F_REQUEST,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `emailTemplatesR.${FETCHING}`)
  const welcomeEmail = get(state, `emailTemplatesR.items.${WELCOME_EMAIL_TEMPLATE}`)

  return {
    isFetching,
    welcomeEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWelcomeEmail: () => dispatch({ type: GET_WELCOME_EMAIL_TEMPLATE_F_REQUEST }),
  }
}

class WelcomeEmailC extends Component {
  componentDidMount() {
    const { getWelcomeEmail } = this.props

    getWelcomeEmail()
  }

  render() {
    return (
      <WelcomeEmail {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeEmailC)
