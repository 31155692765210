import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  USERS,
  USER,
} from 'constants/apiConstants'

const deleteAPIKeyAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.delete({
    meta,
    credentials,
    path: USER({ id }),
    service: USERS,
  })
}

export default deleteAPIKeyAPI
