import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SelectSenderForCreatePaymentFormC from 'components/Customer/Forms/SelectSenderForCreatePaymentForm/SelectSenderForCreatePaymentFormC'
import submitSelectPaymentLinkMerchantForm from 'utilities/submit/submitSelectPaymentLinkMerchantForm'

import {
  SELECT_A_MERCHANT_TITLE,
  SELECT_THE_MERCHANT_ACCOUNT_TO_PROCESS_PAYMENT_FOR,
} from 'constants/language/languageConstants'

const SelectPaymentLinkMerchantModal = () => {
  return (
    <GenericModal
      title={SELECT_A_MERCHANT_TITLE}
      subTitle={SELECT_THE_MERCHANT_ACCOUNT_TO_PROCESS_PAYMENT_FOR}
      className='SelectPaymentLinkMerchantModal'
      Component={SelectSenderForCreatePaymentFormC}
      onSubmit={submitSelectPaymentLinkMerchantForm}
    />
  )
}

export default SelectPaymentLinkMerchantModal
