import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditFeeProfileFlow from './EditFeeProfileFlow'
import CardNotPresentFormC from 'components/Customer/Forms/EditFeeProfileForm/CardNotPresentForm/CardNotPresentFormC'
import CardPresentFormC from 'components/Customer/Forms/EditFeeProfileForm/CardPresentForm/CardPresentFormC'
import AchAndAdditionalFormC from 'components/Customer/Forms/EditFeeProfileForm/AchAndAdditionalForm/AchAndAdditionaFormC'
import ReviewAndSubmitFormC from 'components/Customer/Forms/EditFeeProfileForm/ReviewAndSubmitForm/ReviewAndSubmitFormC'
import getMany from 'utilities/get/getMany'
import getFeeProfileRequest from 'utilities/actions/get/getFeeProfileRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getFeeProfileRulesRequest from 'utilities/actions/get/getFeeProfileRulesRequest'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import getFeeProfileSettingsRequest from 'utilities/actions/get/getFeeProfileSettingsRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_FEE_PROFILE_DUES_ASSESSMENTS } from 'constants/featureFlagConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { USA } from 'constants/countryConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'

import {
  APPLICATION,
  MERCHANT,
} from 'constants/dashboardConfigurationsConstants'

import {
  getFeeProfileSelector,
  getMerchantSelector,
  getApplicationSelector,
  getFeeProfileRuleSelector,
  getFeeProfileSettingSelector,
} from 'state-layer/selectors'

import {
  ACH_AMPERSAND_ADDITIONAL,
  CARD_DASH_PRESENT,
  CARD_NOT_PRESENT,
  REVIEW_AMPERSAND_SUBMIT,
} from 'constants/language/languageConstants'

import {
  EDIT_FEE_PROFILE_CP_FORM,
  EDIT_FEE_PROFILE_CNP_FORM,
  EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM,
} from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFlex = isFlexPlatform()

  const [
    entityId,
    entityType,
  ] = getMany(parseUrlQueries(), [
    'entityId',
    'entityType',
  ])

  const feeProfiles = getFeeProfileSelector(state, entityId)
  const feeProfile = get(feeProfiles, 'feeProfile')
  const cardPresentFeeProfile = get(feeProfiles, 'cardPresentFeeProfile')
  const noExistingCardPresentFeeProfile = !cardPresentFeeProfile
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)

  const merchant = getMerchantSelector(state, entityId)
  const application = getApplicationSelector(state, entityId)

  const [
    applicationProfileId,
    applicationProfileTags,
  ] = getMany(application, [
    'applicationProfile.id',
    'applicationProfile.tags',
  ])

  const [
    merchantProfileId,
    merchantApplicationId,
    merchantProfileTags,
    merchantIdentityId,
    merchantCountry = USA,
  ] = getMany(merchant, [
    'merchantProfileId',
    'applicationId',
    'merchantProfile.tags',
    'identityId',
    'country',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[merchantCountry]
  const showCurrency = entityType === MERCHANT
  const feeProfileApplicationId = !isFlex ? null : entityType === MERCHANT ? merchantApplicationId : entityId

  const feeProfileRules = getFeeProfileRuleSelector(state, feeProfileApplicationId)
  const feeProfileSettings = getFeeProfileSettingSelector(state, feeProfileApplicationId)
  const canApplyGuardRails = get(feeProfileSettings, `${currency}.enforceLimitsForFeeProfiles`)
  const blendedFeeProfileRules = get(feeProfileRules, `BLENDED.${currency}`)
  const interchangeFeeProfileRules = get(feeProfileRules, `INTERCHANGE_PLUS.${currency}`)
  const applyGuardRails = entityType === MERCHANT && isRolePartner({ credentials }) && canApplyGuardRails
  const showDuesAssessments = getFeatureFlag(SHOW_FEE_PROFILE_DUES_ASSESSMENTS)

  let title = ''
  if (entityType === MERCHANT) {
    title = `Edit Default Fee Profile for ${get(merchant, 'displayBusinessName') || get(merchant, 'displayFullName')}`
  }
  if (entityType === APPLICATION) {
    title = `Edit Default Merchant Fee Profile for ${get(application, 'businessName')}`
  }

  const steps = [
    {
      name: CARD_NOT_PRESENT,
      component: CardNotPresentFormC,
      dataKey: 'cnp',
      formValues: () => reduxFormValues(EDIT_FEE_PROFILE_CNP_FORM),
      props: {
        entityId,
        entityType,
        feeProfile,
        currency,
        showCurrency,
        blendedFeeProfileRules,
        interchangeFeeProfileRules,
        applyGuardRails,
        showDuesAssessments,
      },
    },
    {
      name: CARD_DASH_PRESENT,
      component: CardPresentFormC,
      dataKey: 'cp',
      formValues: () => reduxFormValues(EDIT_FEE_PROFILE_CP_FORM),
      props: {
        entityId,
        entityType,
        feeProfile: cardPresentFeeProfile,
        currency,
        showCurrency,
        blendedFeeProfileRules,
        interchangeFeeProfileRules,
        applyGuardRails,
        showDuesAssessments,
      },
    },
    {
      name: ACH_AMPERSAND_ADDITIONAL,
      component: AchAndAdditionalFormC,
      dataKey: 'ach',
      formValues: () => reduxFormValues(EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM),
      props: {
        entityId,
        entityType,
        feeProfile,
        currency,
        showCurrency,
        blendedFeeProfileRules,
        interchangeFeeProfileRules,
        applyGuardRails,
      },
    },
    {
      name: REVIEW_AMPERSAND_SUBMIT,
      component: ReviewAndSubmitFormC,
      props: {
        entityId,
        entityType,
        merchantProfileId,
        merchantApplicationId,
        merchantProfileTags,
        applicationProfileId,
        applicationProfileTags,
        credentialId,
        merchantIdentityId,
        noExistingCardPresentFeeProfile,
      },
    },
  ]

  return {
    steps,
    entityId,
    entityType,
    title,
    credentials,
    feeProfileApplicationId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeProfile: ({ credentials, values }) => dispatch(getFeeProfileRequest({ credentials, values })),
    getFeeProfileRules: ({ credentials, values }) => dispatch(getFeeProfileRulesRequest({ credentials, values })),
    getFeeProfileSettings: ({ credentials, values }) => dispatch(getFeeProfileSettingsRequest({ credentials, values })),
    getApplication: ({ applicationId, credentials }) => dispatch(getApplicationRequest({ applicationId, credentials })),
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
  }
}

class EditFeeProfileFlowC extends Component {
  componentDidMount() {
    const {
      getFeeProfile,
      entityId,
      entityType,
      credentials,
      getApplication,
      getMerchant,
      feeProfileApplicationId,
      getFeeProfileRules,
      getFeeProfileSettings,
    } = this.props

    const values = {
      entityType,
      entityId,
    }

    if (entityId && entityType) {
      getFeeProfile({ credentials, values })

      if (entityType === APPLICATION) {
        getApplication({ applicationId: entityId, credentials })
      }

      if (entityType === MERCHANT) {
        getMerchant({ merchantId: entityId, credentials })
      }
    }

    if (feeProfileApplicationId) {
      getFeeProfileRules({ credentials, values: { applicationId: feeProfileApplicationId } })
      getFeeProfileSettings({ credentials, values: { applicationId: feeProfileApplicationId } })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      entityId,
      entityType,
      credentials,
      getFeeProfile,
      getApplication,
      getMerchant,
      feeProfileApplicationId,
      getFeeProfileRules,
      getFeeProfileSettings,
    } = this.props

    const { entityId: prevEntityId, feeProfileApplicationId: prevFeeProfileApplicationId } = prevProps

    const values = {
      entityType,
      entityId,
    }

    if (entityId && entityId !== prevEntityId) {
      getFeeProfile({ credentials, values })

      if (entityType === APPLICATION) {
        getApplication({ applicationId: entityId, credentials })
      }

      if (entityType === MERCHANT) {
        getMerchant({ merchantId: entityId, credentials })
      }
    }

    // make the call to get fee profile rules after the merchant call has completed
    if (feeProfileApplicationId && feeProfileApplicationId !== prevFeeProfileApplicationId) {
      getFeeProfileRules({ credentials, values: { applicationId: feeProfileApplicationId } })
      getFeeProfileSettings({ credentials, values: { applicationId: feeProfileApplicationId } })
    }
  }

  render () {
    return (
      <EditFeeProfileFlow {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeeProfileFlowC)
