import React from 'react'
import PropTypes from 'prop-types'
import { getAuthorizationMerchantsBuyersSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { AUTHORIZATIONS_ICON } from 'constants/iconConstants'

import {
  AUTHORIZATIONS,
  AUTHORIZATIONS_RESOURCE_TITLE,
  AUTHORIZATIONS_SUB_TITLE,
  AUTHORIZATIONS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

import {
  AUTHORIZATION,
  GET_AUTHORIZATIONS,
} from 'constants/flowConstants'

const Authorizations = ({
  authorizationPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters,
  actions = [],
}) => {
  return (
    <div className='Authorizations'>
      <HeaderV2C
        title={AUTHORIZATIONS}
        subTitle={AUTHORIZATIONS_SUB_TITLE}
      />

      <TableC
        flow={GET_AUTHORIZATIONS}
        columnDescriptors={columnDescriptors}
        selector={getAuthorizationMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={AUTHORIZATIONS}
        allowedFilters={allowedFilters}
        path={authorizationPath}
        isFetching={isFetching}
        fileNameCSV={AUTHORIZATIONS_RESOURCE_TITLE}
        icon={`fa fa-${AUTHORIZATIONS_ICON}`}
        emptyMessage={AUTHORIZATIONS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={AUTHORIZATION}
        actions={actions}
      />
    </div>
  )
}

Authorizations.propTypes = {
  authorizationPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  actions: PropTypes.array,
}

export default Authorizations
