import './PreviousStepModalS.scss'
import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import PreviousStepC from './PreviousStepC'
import { PREVIOUS_STEP } from 'constants/language/languageConstants'

const PreviousStepModal = () => {
  return (
    <GenericModal
      title={PREVIOUS_STEP}
      className='PreviousStepModal'
      Component={PreviousStepC}
    />
  )
}

export default PreviousStepModal
