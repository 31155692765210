import './CreateQuickTransactionFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import LevelThreeItems from 'components/Customer/Forms/CreateTransactionForm/LevelThreeItems'
import validateCreateTransactionForm from 'utilities/validate/validateCreateTransactionForm'
import submitCreateQuickTransactionForm from 'utilities/submit/submitCreateQuickTransactionForm'
import { USD } from 'constants/currencyConstants'
import { CREATE_TRANSACTION_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'
import isEqual from 'lodash/isEqual'

import {
  VIRTUAL_TERMINAL_DOCS_LINK,
  VIRTUAL_TERMINAL_L2_L3_LEAR_MORE_LINK,
} from 'constants/urlConstants'

import {
  L2_ENABLED,
  L3_ENABLED,
  ADDITIONAL_PROCESSOR_DATA_OPTIONS,
} from 'constants/processorConstants'

import {
  APPLICATION,
  CANCEL,
  CREATE_A_PAYMENT_SUBTITLE,
  CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP,
  DESCRIPTION,
  LEARN_MORE_LABEL,
  MERCHANT_ACCOUNT,
  SELECT_MERCHANT_ACCOUNT_PLACEHOLDER,
  MERCHANT_ACCOUNT_TOOLTIP,
  PAYMENT_DESCRIPTION_PLACEHOLDER,
  PAYMENT_DESCRIPTION_TOOLTIP,
  TOTAL_AMOUNT_SUBTITLE,
  TOTAL_PURCHASE_AMOUNT,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  SALES_TAX_AMOUNT_TOOLTIP_MSG,
  CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG,
  ADDITIONAL_DATA,
  VIRTUAL_TERMINAL_ADDITIONAL_DATA_SUBTITLE,
  CUSTOMER_INFORMATION,
  SHIPPING_INFORMATION,
  DESTINATION_POSTAL_CODE,
  SHIP_FROM_POSTAL_CODE,
  SHIPPING_AMOUNT,
  SHIPPING_AMOUNT_TOOLTIP_MSG,
  OTHER_INFORMATION,
  TOTAL_DISCOUNT_AMOUNT,
  TOTAL_CUSTOMS_DUTY_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT_TOOLTIP_MSG,
  TOTAL_CUSTOMS_DUTY_AMOUNT_TOOLTIP_MSG,
  CREATE_A_TRANSACTION,
  SALE,
  AUTHORIZATION,
  REVIEW_TRANSACTION,
} from 'constants/language/languageConstants'

const CreateQuickTransactionForm = ({
  handleSubmit = () => {},
  isStandaloneMerchant = false,
  isRolePlatformCredential = false,
  merchantAccounts = [],
  applicationNames = [],
  isFetchingDashboardConfig = false,
  invalid = false,
  merchantLevelTwoLevelThreeDataEnabled = false,
  additionalDataSelection = '',
  isCardMethod = false,
  levelThreeItemsValue = [],
  setTransferType = () => {},
  isSale = true,
  currency = USD,
  formHasErrors = true,
}) => {
  return (
    <form className='CreateQuickTransactionForm' onSubmit={handleSubmit(submitCreateQuickTransactionForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{CREATE_A_TRANSACTION}</h3>
        <div className='p-1'>{CREATE_A_PAYMENT_SUBTITLE} <a className='text-link' href={VIRTUAL_TERMINAL_DOCS_LINK} target='blank'>{LEARN_MORE_LABEL}</a></div>
      </div>

      <div className='form-content'>
        <div className='link-type-buttons flex'>
          <div className={`one-time-link ${isSale ? 'active' : 'inactive'}`} onClick={() => setTransferType('sale')}>
            <span className='flex items-center'>
              <RadioButton
                options={[{ value: 'sale', label: SALE }]}
                input={{ value: isSale ? 'sale' : 'authorization', name: 'radio-button' }}
              />
            </span>
          </div>
          <div className={`multi-use-link ${isSale ? 'inactive' : 'active'}`} onClick={() => setTransferType('authorization')}>
            <span className='flex items-center'>
              <RadioButton
                options={[{ value: 'authorization', label: AUTHORIZATION }]}
                input={{ value: isSale ? 'sale' : 'authorization', name: 'radio-button' }}
              />
            </span>
          </div>
        </div>

        <AmountField
          name='amount'
          label={TOTAL_PURCHASE_AMOUNT}
          placeholder='0.00'
          helperText={TOTAL_AMOUNT_SUBTITLE}
          contextLabel={currency}
          currency={currency}
        />

        <Field
          name='description'
          label={DESCRIPTION}
          component={InputField}
          tooltip={PAYMENT_DESCRIPTION_TOOLTIP}
          tooltipPosition='right'
          placeholder={PAYMENT_DESCRIPTION_PLACEHOLDER}
          required={false}
        />

        { isRolePlatformCredential && (
          <Field
            name='applicationId'
            label={APPLICATION}
            component={ReactSelect}
            options={applicationNames}
            tooltip={CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP}
          />
        )}

        { (isFetchingDashboardConfig && !isStandaloneMerchant) && (
          <Field
            name='merchantAccount'
            label={MERCHANT_ACCOUNT}
            component={ReactSelect}
            options={merchantAccounts}
            tooltip={MERCHANT_ACCOUNT_TOOLTIP}
            tooltipPosition='right'
            placeholder={SELECT_MERCHANT_ACCOUNT_PLACEHOLDER}
          />
        )}

        <div className='payment-details'>
          <h5>Payment Details</h5>
          <div id='payment-instrument-form' className='form-elements' />
        </div>

        { merchantLevelTwoLevelThreeDataEnabled && isCardMethod && (
          <div className='additional-data-section'>
            <h5>{ADDITIONAL_DATA}</h5>
            <div className='additional-data-subtitle'>
              {VIRTUAL_TERMINAL_ADDITIONAL_DATA_SUBTITLE}
              <a className='text-link learn-more-link' href={VIRTUAL_TERMINAL_L2_L3_LEAR_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
            </div>

            <Field
              name='additionalDataSelection'
              className='additional-data-select'
              label={ADDITIONAL_DATA}
              component={ReactSelect}
              options={ADDITIONAL_PROCESSOR_DATA_OPTIONS}
              required={false}
            />

            { isEqual(additionalDataSelection, L2_ENABLED) && (
              <div className='level-two-enabled-fields-section'>
                {/* TODO: instead of just hardcoding USD, look into supporting different country currencies when possible */}
                <AmountField
                  name='salesTaxAmount'
                  className='sales-tax-field'
                  label={SALES_TAX_AMOUNT}
                  component={InputField}
                  contextLabel={USD}
                  tooltip={SALES_TAX_AMOUNT_TOOLTIP_MSG}
                  tooltipPosition='right'
                />

                <Field
                  name='customerReferenceNumber'
                  className='customer-ref-number-field'
                  label={CUSTOMER_REFERENCE_NUMBER}
                  component={InputField}
                  tooltip={CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG}
                  tooltipPosition='right'
                  placeholder='123456'
                />
              </div>
            )}

            {isEqual(additionalDataSelection, L3_ENABLED) && (
              <div className='level-three-enabled-fields-section'>
                <h6 className='customer-info-heading'>{CUSTOMER_INFORMATION}</h6>

                <Field
                  name='customerReferenceNumber'
                  className='customer-ref-number-field'
                  label={CUSTOMER_REFERENCE_NUMBER}
                  component={InputField}
                  tooltip={CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG}
                  tooltipPosition='right'
                  placeholder='123456'
                />

                <h6 className='shipping-info-heading'>{SHIPPING_INFORMATION}</h6>

                <Field
                  name='destinationPostalCode'
                  className='destination-postal-code-field'
                  label={DESTINATION_POSTAL_CODE}
                  component={InputField}
                  placeholder='12345'
                  required={false}
                />

                <Field
                  name='shipFromPostalCode'
                  className='ship-from-postal-code-field'
                  label={SHIP_FROM_POSTAL_CODE}
                  component={InputField}
                  placeholder='12345'
                  required={false}
                />

                <AmountField
                  name='shippingAmount'
                  className='shipping-amount-field'
                  label={SHIPPING_AMOUNT}
                  component={InputField}
                  contextLabel={USD}
                  tooltip={SHIPPING_AMOUNT_TOOLTIP_MSG}
                  tooltipPosition='right'
                />

                <div className='items'>
                  <FieldArray
                    name='levelThreeItems'
                    component={LevelThreeItems}
                    levelThreeItems={levelThreeItemsValue}
                  />
                </div>

                <h6 className='other-info-heading'>{OTHER_INFORMATION}</h6>

                <AmountField
                  name='totalDiscountAmount'
                  className='total-discount-amount-field'
                  label={TOTAL_DISCOUNT_AMOUNT}
                  component={InputField}
                  contextLabel={USD}
                  tooltip={TOTAL_DISCOUNT_AMOUNT_TOOLTIP_MSG}
                  tooltipPosition='right'
                />

                <AmountField
                  name='totalCustomsDutyAmount'
                  className='total-customs-duty-amount-field'
                  label={TOTAL_CUSTOMS_DUTY_AMOUNT}
                  component={InputField}
                  contextLabel={USD}
                  tooltip={TOTAL_CUSTOMS_DUTY_AMOUNT_TOOLTIP_MSG}
                  tooltipPosition='right'
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' disabled={invalid || formHasErrors} label={REVIEW_TRANSACTION} className='submit-button' />
          </div>
        </div>
      </div>
    </form>
  )
}

CreateQuickTransactionForm.propTypes = {
  handleSubmit: PropTypes.func,
  isStandaloneMerchant: PropTypes.bool,
  isRolePlatformCredential: PropTypes.bool,
  merchantAccounts: PropTypes.array,
  applicationNames: PropTypes.array,
  isFetchingDashboardConfig: PropTypes.bool,
  invalid: PropTypes.bool,
  merchantLevelTwoLevelThreeDataEnabled: PropTypes.bool,
  additionalDataSelection: PropTypes.string,
  isCardMethod: PropTypes.bool,
  levelThreeItemsValue: PropTypes.array,
  setTransferType: PropTypes.func,
  isSale: PropTypes.bool,
  currency: PropTypes.string,
  formHasErrors: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_TRANSACTION_FORM,
  validate: validateCreateTransactionForm,
})(CreateQuickTransactionForm)
