import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ComplianceForm from './ComplianceForm'
import Loader from 'components/Shared/Loader/Loader'
import Address from 'components/Customer/Shared/Display/Address/Address'
import ComplianceFormStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ComplianceFormStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getComplianceFormRequest from 'utilities/actions/get/getComplianceFormRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import hasPermissions from 'utilities/hasPermissions'
import { FETCHING } from 'constants/reducerConstants'
import { DATE_SHORT_FORMAT } from 'constants/timeConstants'
import { UPLOADED } from 'constants/fileConstants'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING } from 'constants/complianceConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { UPDATE_COMPLIANCE_FORMS_PERMISSION } from 'constants/permissionConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import concat from 'lodash/concat'

import {
  isRolePlatform,
  hasPartnerAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  FILE_VIEWER_MODAL,
  ATTEST_COMPLIANCE_FORM_MODAL,
  INVALIDATE_COMPLIANCE_FORM_MODAL,
} from 'constants/modalConstants'

import {
  APPLICATION,
  BUSINESS_ADDRESS,
  COMPLIANCE_FORM,
  COMPLIANCE_FORMS_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  DUE_ON_LABEL,
  TYPE,
  STATUS,
  MERCHANT,
  INCORPORATION_DATE,
  BUSINESS_PHONE,
  WEBSITE,
  ATTESTOR_NAME,
  SIGNED_ON,
  IP_ADDRESS,
  USER_AGENT,
  COMPLIANCE_FORM_TEMPLATE,
  UNSIGNED_PDF,
  SIGNED_PDF,
  CREATED_ON,
  EXPIRES_ON,
  THIS_FORM_WILL_EXPIRE_ONE_YEAR_AFTER_SIGNING,
  ATTEST_TO_FORM,
  INVALIDATE_FORM,
} from 'constants/language/languageConstants'

import {
  COMPLETE,
  EXPIRED,
  INCOMPLETE,
  INVALID,
  OVERDUE,
} from 'constants/statusConstants'

import {
  getApplicationSelector,
  getComplianceFormSelector,
  getComplianceFormTemplateSelector,
} from 'state-layer/selectors'

import {
  MERCHANT_PATH,
  COMPLIANCE_FORM_TEMPLATE_PATH,
  IDENTITY_PATH,
  APPLICATION_PATH,
  COMPLIANCE_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `complianceFormsR.${FETCHING}`)
  const complianceFormId = get(props, 'params.complianceFormId')
  const complianceForm = getComplianceFormSelector(state, complianceFormId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    id,
    type,
    status,
    displayCreatedAt,
    displayDueAt,
    merchantIdentity = {},
    tags,
    linkedTo,
    applicationId,
    complianceFormTemplateId,
    displaySignedAt,
    signedAt,
  ] = getMany(complianceForm, [
    'id',
    'type',
    'state',
    'displayCreatedAt',
    'displayDueAt',
    'merchantIdentity',
    'tags',
    'linkedTo',
    'applicationId',
    'complianceFormTemplateId',
    'displaySignedAt',
    'signedAt',
  ])

  const {
    id: merchantIdentityId,
    businessAddress,
    website,
    displayIncorporationDate,
    displayBusinessPhone,
    displayBusinessName,
  } = merchantIdentity

  const application = getApplicationSelector(state, applicationId)
  const applicationBusinessName = get(application, 'businessName')
  const complianceFormTemplate = getComplianceFormTemplateSelector(state, complianceFormTemplateId)
  const complianceFormTemplateName = get(complianceFormTemplate, 'name')

  const isSignedForm = status === COMPLETE || status === EXPIRED
  const isUnsignedForm = status === INCOMPLETE || status === OVERDUE
  const isInvalidForm = status === INVALID

  const contextBarData = {
    back: {
      label: COMPLIANCE_FORMS_RESOURCE_TITLE,
      path: `${COMPLIANCE_PATH({ credentialId })}?tab=forms`,
    },
    items: [
      {
        label: APPLICATION,
        value: applicationBusinessName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: hasPartnerAccess({ credentials }),
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: displayBusinessName,
        path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
        condition: hasPartnerAccess({ credentials }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: COMPLIANCE_FORM,
      id,
    },
    items: [
      {
        value: <h1>PCI {COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type]}</h1>,
      },
      {
        label: STATUS,
        value: ComplianceFormStatus({ state: status }),
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: SIGNED_ON,
        value: displaySignedAt,
        condition: isSignedForm,
      },
      {
        label: DUE_ON_LABEL,
        value: displayDueAt,
        condition: isUnsignedForm,
      },
    ],
    isFetching,
  }

  const complianceFormInfoSectionData = convertPageSectionDataToV2([
    {
      label: COMPLIANCE_FORM_TEMPLATE,
      value: complianceFormTemplateName,
      path: COMPLIANCE_FORM_TEMPLATE_PATH({
        complianceFormTemplateId,
        credentialId,
      }),
      condition: isRolePlatform({ credentials }),
    },
    {
      label: TYPE,
      value: COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type],
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: SIGNED_ON,
      value: displaySignedAt,
      condition: isSignedForm,
    },
    {
      label: DUE_ON_LABEL,
      value: displayDueAt,
      condition: isUnsignedForm || isInvalidForm,
    },
    {
      label: EXPIRES_ON,
      value: signedAt ? moment(signedAt).add(1, 'year').format(DATE_SHORT_FORMAT) : '-',
      tooltip: THIS_FORM_WILL_EXPIRE_ONE_YEAR_AFTER_SIGNING,
    },
  ])

  const businessInfoSectionData = convertPageSectionDataToV2([
    {
      label: MERCHANT,
      value: displayBusinessName,
      path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ merchantId: linkedTo, identityId: merchantIdentityId, credentialId }) : undefined,
    },
    {
      label: INCORPORATION_DATE,
      value: displayIncorporationDate,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
    },
    {
      label: WEBSITE,
      value: validateWebsiteLink({ website }) ? <a className='text-link' href={website} target='blank'>{website}</a> : website,
    },
  ])

  const tagsSectionData = map(tags, (value, tagLabel) => ({ value, label: tagLabel }))

  return {
    isFetching,
    credentials,
    credentialId,
    complianceFormId,
    complianceForm,
    complianceFormInfoSectionData,
    businessInfoSectionData,
    tagsSectionData,
    contextBarData,
    headerData,
    complianceFormTemplateName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComplianceForm: ({ credentials, complianceFormId }) => dispatch(getComplianceFormRequest({ credentials, complianceFormId })),
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
    showAttestComplianceModal: (modalProps) => dispatch(showModalAction({ modalType: ATTEST_COMPLIANCE_FORM_MODAL, modalProps })),
    showInvalidateComplianceFormModal: (modalProps) => dispatch(showModalAction({ modalType: INVALIDATE_COMPLIANCE_FORM_MODAL, modalProps, className: 'modal-sm no-pad' })),
  }
}

class ComplianceFormC extends Component {
  componentDidMount() {
    const {
      complianceFormId,
      credentials,
      getComplianceForm,
    } = this.props

    getComplianceForm({ credentials, complianceFormId })
  }

  render() {
    const {
      complianceForm,
      showFileViewer,
      showAttestComplianceModal,
      showInvalidateComplianceFormModal,
      credentials,
    } = this.props

    const [
      id,
      attestorName,
      displaySignedAt,
      ipAddress,
      userAgent,
      signedFile,
      unsignedFile,
      state,
    ] = getMany(complianceForm, [
      'id',
      'attestorName',
      'displaySignedAt',
      'ipAddress',
      'userAgent',
      'signedFile',
      'unsignedFile',
      'state',
    ])

    const unsignedFileName = get(unsignedFile, 'displayName')
    const signedFileName = get(signedFile, 'displayName')
    const signedFilePdfReady = get(signedFile, 'status') === UPLOADED
    const notReadySignedFileValue = signedFileName && !signedFilePdfReady ? <div>PDF (Pending) <Loader /></div> : '-'
    const signedFilePdfValue = signedFilePdfReady ? concat(signedFileName, '.pdf') : notReadySignedFileValue
    const isPOPS = isRolePlatform({ credentials }) && isAdministratorRole(credentials) && isFlexPlatform()

    const attestationSectionData = convertPageSectionDataToV2([
      {
        label: ATTESTOR_NAME,
        value: attestorName,
      },
      {
        label: SIGNED_ON,
        value: displaySignedAt,
      },
      {
        label: UNSIGNED_PDF,
        value: unsignedFileName ? <a className='text-link' onClick={() => showFileViewer(unsignedFile)}>{concat(unsignedFileName, '.pdf')}</a> : '-',
      },
      {
        label: SIGNED_PDF,
        value: (signedFile && signedFilePdfReady) ? <a className='text-link' onClick={() => showFileViewer(signedFile)}>{signedFilePdfValue}</a> : '-',
      },
      {
        label: IP_ADDRESS,
        value: ipAddress,
      },
      {
        label: USER_AGENT,
        value: userAgent,
      },
    ])

    const attestationActions = [
      {
        label: ATTEST_TO_FORM,
        className: 'attest-compliance-form-btn',
        action: () => showAttestComplianceModal({ complianceFormId: id }),
        condition: (state === INCOMPLETE || state === OVERDUE) && hasPermissions([UPDATE_COMPLIANCE_FORMS_PERMISSION]),
      },
    ]

    const actions = [
      {
        label: INVALIDATE_FORM,
        className: 'invalidate-form-btn',
        action: () => showInvalidateComplianceFormModal({ complianceFormId: id }),
        condition: state !== 'INVALID' && isPOPS,
      },
    ]

    return (
      <ComplianceForm
        {...this.props}
        attestationSectionData={attestationSectionData}
        attestationActions={attestationActions}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceFormC)
