import './DisableCustomRoleS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  ALERT_ICON,
  ELLIPSIS_ICON,
} from 'constants/iconConstants'

import {
  DISABLE_CUSTOM_ROLE_CONFIRMATION_LABEL,
  DISABLE_ROLE_CONFIRMATION_MESSAGE,
  DISABLE_ROLE_WARNING,
  KEEP_ACTIVE_CONFIRMATION_LABEL,
  REASSIGNED_ROLE_MEMBERS_MSG,
} from 'constants/language/languageConstants'

const DisableCustomRole = ({
  roleName = '',
  closeModal = () => {},
  disableCustomRole = () => {},
  credentialName = '',
}) => {
  return (
    <div className='DisableCustomRole'>
      <br />
      <div className='disable-description'>This will disable {roleName} from {credentialName} dashboard and it will no longer be available to assign to team members.</div>
      <br />
      <div className='reassign-role-members-message'>
        {REASSIGNED_ROLE_MEMBERS_MSG}
        <ul>
          <li className='tab'>&#x2022; Click into the &#39;Role Members&#39; tab</li>
          <li className='tab'>&#x2022; Click on the <i className={ELLIPSIS_ICON} /> and select &#39;Edit Team Member&#39;</li>
          <li className='tab'>&#x2022; Reassign the team member to a different role</li>
          <li className='tab'>&#x2022; Repeat for all team members</li>
        </ul>
      </div>
      <br />
      <div className='disable-role-confirmation'>{DISABLE_ROLE_CONFIRMATION_MESSAGE}</div>
      <br />
      <div className='role-disable-warning'><i className={`fa fa-${ALERT_ICON}`} />{DISABLE_ROLE_WARNING}</div>


      <div className='btn-container flex flex-end items-center'>
        <Button variant='tertiary' className='cancel-button' onClick={closeModal} label={KEEP_ACTIVE_CONFIRMATION_LABEL} />
        <Button variant='destructive' className='disable-btn' label={DISABLE_CUSTOM_ROLE_CONFIRMATION_LABEL} onClick={disableCustomRole} />
      </div>
    </div>
  )
}

DisableCustomRole.propTypes = {
  roleName: PropTypes.string,
  closeModal: PropTypes.func,
  disableCustomRole: PropTypes.func,
  credentialName: PropTypes.string,
}

export default DisableCustomRole
