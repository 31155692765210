import { IDENTITY_CSV_COLUMNS } from 'constants/identityConstants'
import getMany from 'utilities/get/getMany'
import lowerCase from 'lodash/lowerCase'
import backendIdentityModel from 'utilities/create/models/backendIdentityModel'

export const convertIdentityCsvRowToModel = row => {
  const [
    businessName,
    doingBusinessAs,
    businessType,
    businessTaxId,
    website,
    businessPhone,
    ownershipType,
    amexMid,
    discoverMid,
    firstName,
    lastName,
    title,
    principalPercentageOwnership,
    taxId,
    phone,
    email,
    defaultStatementDescriptor,
    annualCardVolume,
    maxTransactionAmount,
    mcc,
    previouslyProcessedCreditCards,
    dobMonth,
    dobDay,
    dobYear,
    incorporationDateMonth,
    incorporationDateDay,
    incorporationDateYear,
    businessAddressLine1,
    businessAddressLine2,
    businessAddressCity,
    businessAddressRegion,
    businessAddressPostalCode,
    businessAddressCountry,
    personalAddressLine1,
    personalAddressLine2,
    personalAddressCity,
    personalAddressRegion,
    personalAddressPostalCode,
    personalAddressCountry,
    tags,
  ] = getMany(row, IDENTITY_CSV_COLUMNS)

  const parsedTags = tags && JSON.parse(tags.replace(/\s/g, ''))

  const dateOfBirth = {
    month: dobMonth,
    day: dobDay,
    year: dobYear,
  }

  const incorporationDate = {
    month: incorporationDateMonth,
    day: incorporationDateDay,
    year: incorporationDateYear,
  }

  const businessAddress = {
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    city: businessAddressCity,
    region: businessAddressRegion,
    postalCode: businessAddressPostalCode,
    country: businessAddressCountry,
  }

  const personalAddress = {
    line1: personalAddressLine1,
    line2: personalAddressLine2,
    city: personalAddressCity,
    region: personalAddressRegion,
    postalCode: personalAddressPostalCode,
    country: personalAddressCountry,
  }

  const values = {
    businessName,
    doingBusinessAs,
    businessType,
    businessTaxId,
    website,
    businessPhone,
    ownershipType,
    firstName,
    lastName,
    title,
    principalPercentageOwnership,
    taxId,
    phone,
    email,
    defaultStatementDescriptor,
    annualCardVolume,
    maxTransactionAmount,
    mcc,
    previouslyProcessedCreditCards: lowerCase(previouslyProcessedCreditCards) === 'true' ? true : false,
    amexMid,
    discoverMid,
    ...tags && { tags: parsedTags },
    dateOfBirth,
    incorporationDate,
    businessAddress,
    personalAddress,
  }

  return backendIdentityModel({ values })
}
