import './AddFundsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  INSTRUCTION_FOR_ADDING_FUNDS_SUBTITLE,
  INSTRUCTIONS_FOR_ADDING_FUNDS,
} from 'constants/language/languageConstants'

const AddFunds = ({
  contextBarData = [],
  addingFundsData = [],
}) => {
  return (
    <div className='AddFunds'>
      <ContextBar {...contextBarData} />
      {/* TODO: Cata - commenting out PDF link for MVP release, might add it back after Dec 14th */}
      <PageSectionV2C
        title={INSTRUCTIONS_FOR_ADDING_FUNDS}
        subTitle={INSTRUCTION_FOR_ADDING_FUNDS_SUBTITLE}
        // subTitle={<span className='flex items-center'>{INSTRUCTION_FOR_ADDING_FUNDS_SUBTITLE}&nbsp;<Link>{DOWNLOAD_WIRE_INSTRUCTIONS}</Link>&nbsp;<i className={`fal fa-${DOWNLOAD_ICON}`} /></span>}
        columns={addingFundsData}
      />
    </div>
  )
}

AddFunds.propTypes = {
  contextBarData: PropTypes.array,
  addingFundsData: PropTypes.array,
}

export default AddFunds
