import React, { Component } from 'react'
import { connect } from 'react-redux'
import ConfirmPasswordMFAForm from 'components/Customer/Forms/ConfirmPasswordMFAForm/ConfirmPasswordMFAForm'
import isPatching from 'utilities/is/isPatching'

const mapStateToProps = (state) => {
  return {
    isPatching: isPatching(state),
  }
}

class ConfirmPasswordMFAFormC extends Component {
  render() {
    return <ConfirmPasswordMFAForm {...this.props} />
  }
}

export default connect(mapStateToProps)(ConfirmPasswordMFAFormC)
