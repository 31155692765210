import { GET_INSTANT_PAYOUT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getInstantPayoutRequest = ({
  settlementId,
  fundingTransferAttemptId,
  credentials,
}) => createAction({
  type: GET_INSTANT_PAYOUT_F_REQUEST,
  values: {
    settlementId,
    fundingTransferAttemptId,
  },
  credentials,
})

export default getInstantPayoutRequest
