import NotificationItemC from 'components/Customer/Shared/Notifications/NotificationItemC'
import React from 'react'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import filter from 'lodash/filter'

import {
  GET_LATEST_NOTIFICATIONS_F_SUCCESS,
  GET_NOTIFICATIONS_F_SUCCESS,
} from 'constants/flowConstants'


const toastMessage = ({ notification }) => <NotificationItemC notification={notification} />

const showToastNotifications = ({ notifications }) => {
  forEach(notifications, (notification) => {
    toast(toastMessage({ notification }),
      {
        className: 'global-toast-icon',
      })
  })
}


const toastNotificationsR = (state = {}, action = {}) => {
  const existingNotifications = get(state, 'notifications')
  const newValues = get(action, 'payload.newValues')

  switch (action.type) {
    case GET_LATEST_NOTIFICATIONS_F_SUCCESS: {
      const newNotifications = filter(newValues, ({ id }) => !existingNotifications[id])
      showToastNotifications({ notifications: newNotifications })
      return merge({}, state, {
        notifications: newValues,
      })
    }
    case GET_NOTIFICATIONS_F_SUCCESS:
      return merge({}, state, {
        notifications: newValues,
      })
    default:
      return state
  }
}

export default toastNotificationsR
