import Authorization from './Authorization'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { goToPath } from 'state-layer/history'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getAuthorizationRequest from 'utilities/actions/get/getAuthorizationRequest'
import getAuthorizationAPI from 'api/finix/get/getAuthorizationAPI'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getFraudScoresRequest from 'utilities/actions/get/getFraudScoresRequest'
import hasPermissions from 'utilities/hasPermissions'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getMany from 'utilities/get/getMany'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import { FAILED } from 'constants/transferConstants'
import { SHIELD_ICON } from 'constants/iconConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { AUTHORIZATION } from 'constants/apiConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'

import {
  VOID_AUTHORIZATION_MODAL,
  CAPTURE_AUTHORIZATION_MODAL,
  SEND_RECEIPT_MODAL,
  VIEW_API_RESPONSE_MODAL,
} from 'constants/modalConstants'

import {
  AUTHORIZATION_API_DOC_LINK,
  SIFT_CONSOLE_URL,
} from 'constants/urlConstants'

import {
  ruleDecisionStatusMap,
  PENDING,
  SUCCEEDED,
  UNATTEMPTED,
} from 'constants/statusConstants'

import {
  UPDATE_AUTHORIZATION_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_PERMISSION,
} from 'constants/permissionConstants'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRoleMerchant,
  isRolePartnerOrMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  getAuthorizationSelector,
  getFraudScoreByEntityId,
} from 'state-layer/selectors'

import {
  STATE,
  CREATED_ON,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  AUTHORIZATION_VOIDED,
  EXPIRATION_DATE,
  TRACE_ID,
  CAPTURED_TRANSACTION,
  VOID_AUTHORIZATION,
  CAPTURE_AUTHORIZATION,
  NO,
  APPLICATION,
  MERCHANT_IDENTITY,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  DESTINATION_POSTAL_CODE,
  SHIP_FROM_POSTAL_CODE,
  TOTAL_SHIPPING_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT,
  CUSTOMS_DUTY_AMOUNT,
  ITEM,
  MERCHANT_PRODUCT_CODE,
  PRODUCT_DESCRIPTION,
  COST_PER_UNIT,
  DISCOUNT_PER_UNIT,
  QUANTITY,
  UNIT_OF_MEASURE,
  TOTAL_ITEM_COST,
  EXCLUDING_SALES_TAX,
  INCLUDING_SALES_TAX,
  COMMODITY_CODE,
  RISK_ANALYSIS,
  DECISION_RULE,
  VIEW_IN_SIFT,
  DECISION_DESCRIPTION,
  RULE_DECISION,
  RULE_OUTCOME,
  TIP_AMOUNT,
  SURCHARGE_AMOUNT,
  HSA_FSA_CLINIC,
  HSA_FSA_DENTAL,
  HSA_FSA_RX,
  HSA_FSA_VISION,
  CONVENIENCE_AMOUNT,
  RENT_SURCHARGE_AMOUNT,
  VIEW_RECEIPT,
  SEND_RECEIPT,
  CREATE_RECEIPT,
  AUTHORIZATION_API_REFERENCE,
} from 'constants/language/languageConstants'

import {
  IDENTITY_PATH,
  APPLICATION_PATH,
  PAYMENT_PATH,
  CREATE_AUTHORIZATION_RECEIPT_PATH,
  FEES_PATH,
} from 'constants/pathConstants'

import {
  SHOW_ENTERPRISE_SIFT_FRAUD_SCORES,
  SHOW_PREMIUM_SIFT_FRAUD_SCORES,
  SHOW_SIFT_FRAUD_SCORES,
} from 'constants/featureFlagConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `authorizationsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFlex = isFlexPlatform()
  const authorizationId = get(props, 'params.authorizationId')
  const authorization = getAuthorizationSelector(state, authorizationId)
  const fraudScoreData = getFraudScoreByEntityId(state, authorizationId)
  const isSiftFraudEnabled = getFeatureFlag(SHOW_SIFT_FRAUD_SCORES)
  const isPremiumOrEnterpriseSiftFraudScoreTier = getFeatureFlag(SHOW_PREMIUM_SIFT_FRAUD_SCORES) || getFeatureFlag(SHOW_ENTERPRISE_SIFT_FRAUD_SCORES) // only paid applications have access to fraud score, everyone else just sees the category
  const isEnterpriseSiftFraudScoreTier = getFeatureFlag(SHOW_ENTERPRISE_SIFT_FRAUD_SCORES) // only enterprise tier sees risk analysis page section
  const hasFraudPermissions = hasPermissions([VIEW_SIFT_FRAUD_SCORES_PERMISSION, VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION])
  const isRoleMerchantCred = isRoleMerchant({ credentials })
  const feesPath = ({ id = '' }) => `${FEES_PATH({ credentialId })}/${id}`

  const [
    displayScore,
    displayScoreCategory,
    scoreCategory,
    thirdPartyRule,
    thirdPartyOutcome,
    thirdPartyRuleDescription,
    decision,
  ] = getMany(fraudScoreData, [
    'displayScore',
    'displayScoreCategory',
    'scoreCategory',
    'thirdPartyRule',
    'thirdPartyOutcome',
    'thirdPartyRuleDescription',
    'decision',
  ])

  const [
    traceId = '',
    isVoid = NO,
    authorizationState = '',
    displayCaptured = '',
    displayExpirationDate = '',
    displayAmount = '',
    amount,
    displayCreatedAt = '',
    paymentInstrument = {},
    paymentInstrumentId = '',
    tags,
    raw,
    messages,
    applicationId,
    merchantIdentityId,
    applicationName,
    merchantIdentityName,
    transferId,
    transferAmount,
    currency,
    device,
    buyerIdentity,
    voidState,
    isExpired,
    displayAmountWithCurrencyCode,
    additionalPurchaseData,
    isLevelThreeEnabled,
    displayTipAmount,
    displaySurchargeAmount,
    displayClinicAmount,
    displayDentalAmount,
    displayPrescriptionAmount,
    displayVisionAmount,
    displayConvenienceAmount,
    displayRentSurchargeAmount,
    receipts,
    fees,
  ] = getMany(authorization, [
    'traceId',
    'isVoid',
    'state',
    'displayCaptured',
    'displayExpirationDate',
    'displayAmount',
    'amount',
    'displayCreatedAt',
    'paymentInstrument',
    'paymentInstrumentId',
    'tags',
    'raw',
    'messages',
    'applicationId',
    'merchantIdentityId',
    'application.businessName',
    'merchantIdentity.displayBusinessName',
    'transferId',
    'transfer.displayAmount',
    'currency',
    'device',
    'buyerIdentity',
    'voidState',
    'isExpired',
    'displayAmountWithCurrencyCode',
    'additionalPurchaseData',
    'isLevelThreeEnabled',
    'displayTipAmount',
    'displaySurchargeAmount',
    'displayClinicAmount',
    'displayDentalAmount',
    'displayPrescriptionAmount',
    'displayVisionAmount',
    'displayConvenienceAmount',
    'displayRentSurchargeAmount',
    'receipts',
    'fees',
  ])

  const receiptsArray = orderBy(receipts, ['createdAt'], ['desc'])

  const [
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentFingerprint,
    instrumentType,
  ] = getMany(paymentInstrument, [
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'fingerprint',
    'instrumentType',
  ])

  const isCardPresentAuth = instrumentType === 'PAYMENT_CARD_PRESENT' ? true : false
  const merchantMcc = get(authorization, 'merchantIdentity.mcc')
  const inMccAllowedToCaptureList = includes(['5812', '5813', '5814'], merchantMcc)
  const allowCardPresentCapture = inMccAllowedToCaptureList && isCardPresentAuth
  const showError = authorizationState === FAILED
  const transactionType = get(paymentInstrument, 'type')
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const maxTransactionAmount = get(authorization, 'merchantIdentity.maxTransactionAmount')

  // TODO: remove only partner or merchant check once BE adds permission to role platform template as well (refer to ticket BE-15236)
  const canVoidOrCaptureAuthorization = hasPermissions([UPDATE_AUTHORIZATION_PERMISSION]) && isRolePartnerOrMerchant({ credentials })
    && includes([SUCCEEDED, PENDING], authorizationState) && isEqual(isVoid, NO) && isEqual(voidState, UNATTEMPTED)
    && !transferId && !isExpired

  const [
    displaySalesTax,
    customerReferenceNumber,
    destinationPostalCode,
    shipFromPostalCode,
    displayShippingAmount,
    displayDiscountAmount,
    displayCustomsDutyAmount,
    itemData,
  ] = getMany(additionalPurchaseData, [
    'displaySalesTax',
    'customerReferenceNumber',
    'destinationPostalCode',
    'shipFromPostalCode',
    'displayShippingAmount',
    'displayDiscountAmount',
    'displayCustomsDutyAmount',
    'itemData',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY,
        value: merchantIdentityName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
        condition: !isStandaloneMerchant,
      },
    ],
  }

  const showRiskAnalysis = (!!displayScore || !!displayScoreCategory) && isSiftFraudEnabled
  const siftFraudScoreBadge = <div className={`risk-badge ${scoreCategory}`}><i className={`fas fa-${SHIELD_ICON}`} />{isPremiumOrEnterpriseSiftFraudScoreTier ? displayScore : displayScoreCategory}</div>

  const headerData = {
    resource: {
      label: `Authorization — ${displayCaptured}`,
      id: authorizationId,
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={authorizationState} raw={raw} />,
      },
      {
        label: RISK_ANALYSIS,
        value: siftFraudScoreBadge,
        condition: showRiskAnalysis && isFlex,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: transactionType,
          credentialId,
          paymentInstrument,
        }),
        condition: !isEmpty(paymentInstrument) && (!!maskedAccountNumber || !!maskedFullCardNumber),
      },
      {
        label: CAPTURED_TRANSACTION,
        value: <Link className='text-link' to={PAYMENT_PATH({ credentialId, transferId })}>{transferAmount}</Link>,
        condition: !!transferAmount,
      },
    ],
  }

  const detailsDataSection = convertPageSectionDataToV2([
    {
      label: AUTHORIZATION_VOIDED,
      value: isVoid,
    },

    {
      label: EXPIRATION_DATE,
      value: displayExpirationDate,
    },
    {
      label: CONVENIENCE_AMOUNT,
      value: displayConvenienceAmount,
      condition: !!displayConvenienceAmount,
    },
    {
      label: RENT_SURCHARGE_AMOUNT,
      value: displayRentSurchargeAmount,
      condition: !!displayRentSurchargeAmount,
    },
    {
      label: TIP_AMOUNT,
      value: displayTipAmount,
      condition: !!displayTipAmount,
    },
    {
      label: SURCHARGE_AMOUNT,
      value: displaySurchargeAmount,
      condition: !!displaySurchargeAmount,
    },
    {
      label: HSA_FSA_CLINIC,
      value: displayClinicAmount,
      condition: !!displayClinicAmount,
    },
    {
      label: HSA_FSA_DENTAL,
      value: displayDentalAmount,
      condition: !!displayDentalAmount,
    },
    {
      label: HSA_FSA_RX,
      value: displayPrescriptionAmount,
      condition: !!displayPrescriptionAmount,
    },
    {
      label: HSA_FSA_VISION,
      value: displayVisionAmount,
      condition: !!displayVisionAmount,
    },
  ])

  const additionalLevelTwoDataSectionData = convertPageSectionDataToV2([
    {
      label: SALES_TAX_AMOUNT,
      value: displaySalesTax,
    },
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
  ])

  const additionalLevelThreeDataSectionData = convertPageSectionDataToV2([
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
    {
      label: DESTINATION_POSTAL_CODE,
      value: destinationPostalCode,
    },
    {
      label: SHIP_FROM_POSTAL_CODE,
      value: shipFromPostalCode,
    },
    {
      label: TOTAL_SHIPPING_AMOUNT,
      value: displayShippingAmount,
    },
    {
      label: TOTAL_DISCOUNT_AMOUNT,
      value: displayDiscountAmount,
    },
    {
      label: CUSTOMS_DUTY_AMOUNT,
      value: displayCustomsDutyAmount,
    },
  ])

  const itemInformationCardsData = map(itemData, (item, index) => {
    const [
      merchantProductCode,
      itemDescription,
      displayCostPerUnit,
      displayItemDiscountAmount,
      quantity,
      displayAmountExcludingSalesTax,
      displayAmountIncludingSalesTax,
      unitOfMeasure,
      commodityCode,
    ] = getMany(item, [
      'merchantProductCode',
      'itemDescription',
      'displayCostPerUnit',
      'displayItemDiscountAmount',
      'quantity',
      'displayAmountExcludingSalesTax',
      'displayAmountIncludingSalesTax',
      'unitOfMeasure',
      'commodityCode',
    ])

    return {
      largeTitle: `${ITEM} #${index + 1}`,
      data: [
        {
          label: MERCHANT_PRODUCT_CODE,
          value: merchantProductCode,
        },
        {
          label: PRODUCT_DESCRIPTION,
          value: itemDescription,
        },
        {
          label: COST_PER_UNIT,
          value: displayCostPerUnit,
        },
        {
          label: DISCOUNT_PER_UNIT,
          value: displayItemDiscountAmount,
        },
        {
          label: QUANTITY,
          value: quantity,
        },
        {
          label: UNIT_OF_MEASURE,
          value: unitOfMeasure,
        },
        {
          label: <><div>{TOTAL_ITEM_COST}</div><div>({EXCLUDING_SALES_TAX})</div></>,
          value: displayAmountExcludingSalesTax,
        },
        {
          label: <><div>{TOTAL_ITEM_COST}</div><div>({INCLUDING_SALES_TAX})</div></>,
          value: displayAmountIncludingSalesTax,
        },
        {
          label: COMMODITY_CODE,
          value: commodityCode,
        },
      ],
    }
  })

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const siftFraudScoreData = convertPageSectionDataToV2([
    {
      label: DECISION_RULE,
      value: thirdPartyRule,
    },
    {
      label: RISK_ANALYSIS,
      value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${paymentInstrumentFingerprint}?orderId=${traceId}`} target='blank'>{VIEW_IN_SIFT}</a>,
    },
    {
      label: DECISION_DESCRIPTION,
      value: thirdPartyRuleDescription,
    },
    {
      label: RULE_OUTCOME,
      value: thirdPartyOutcome,
    },
    {
      label: RULE_DECISION,
      value: <ColorcodedStatus data={ruleDecisionStatusMap} value={decision} />,
    },
  ], 3)

  return {
    isFetching,
    credentials,
    credentialId,
    authorizationId,
    authorization,
    contextBarData,
    headerData,
    detailsDataSection,
    paymentInstrument,
    tagsSectionData,
    raw,
    messages,
    showError,
    device,
    buyerIdentity,
    displayAmount,
    canVoidOrCaptureAuthorization,
    displayAmountWithCurrencyCode,
    amount,
    additionalPurchaseData,
    isLevelThreeEnabled,
    additionalLevelTwoDataSectionData,
    additionalLevelThreeDataSectionData,
    itemInformationCardsData,
    isSiftFraudEnabled,
    siftFraudScoreData,
    siftFraudScoreBadge,
    showRiskAnalysis,
    isEnterpriseSiftFraudScoreTier,
    hasFraudPermissions,
    isRoleMerchantCred,
    instrumentType,
    isCardPresentAuth,
    allowCardPresentCapture,
    maxTransactionAmount,
    receiptsArray,
    feesPath,
    fees,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthorization: ({ credentials, authorizationId }) => { dispatch(getAuthorizationRequest({ credentials, authorizationId })) },
    showVoidAuthorizationModal: (modalProps) => dispatch(showModalAction({ modalType: VOID_AUTHORIZATION_MODAL, modalProps, className: 'modal-sm no-pad' })),
    showCaptureAuthorizationModal: (modalProps) => dispatch(showModalAction({ modalType: CAPTURE_AUTHORIZATION_MODAL, modalProps, className: 'modal-sm no-pad' })),
    getFraudScores: ({ credentials, authorizationId }) => dispatch(getFraudScoresRequest({ credentials, queries: { entity_id: authorizationId } })),
    showSendReceiptModal: (modalProps) => dispatch(showModalAction({
      modalType: SEND_RECEIPT_MODAL,
      modalProps,
      className: 'modal-md no-pad',
    })),
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({
      modalType: VIEW_API_RESPONSE_MODAL,
      modalProps,
    })),
  }
}

class AuthorizationC extends Component {
  componentDidMount() {
    const {
      credentials,
      authorizationId,
      getAuthorization,
      getFraudScores,
      isSiftFraudEnabled,
      hasFraudPermissions,
      isRoleMerchantCred,
    } = this.props

    getAuthorization({ credentials, authorizationId })

    if (isSiftFraudEnabled && hasFraudPermissions && !isRoleMerchantCred) {
      getFraudScores({ credentials, authorizationId, meta: { overwriteReducer: true } })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      authorizationId,
      getFraudScores,
      credentials,
      isSiftFraudEnabled,
      getAuthorization,
      hasFraudPermissions,
      isRoleMerchantCred,
    } = this.props

    const {
      authorizationId: prevAuthorizationId,
      isSiftFraudEnabled: prevIsSiftFraudEnabled,
    } = prevProps

    if (authorizationId !== prevAuthorizationId) {
      getAuthorization({ credentials, authorizationId })
    }

    if (isSiftFraudEnabled && !prevIsSiftFraudEnabled && hasFraudPermissions && !isRoleMerchantCred) {
      getFraudScores({ credentials, authorizationId, meta: { overwriteReducer: true } })
    }
  }

  render() {
    const {
      credentials,
      authorizationId,
      displayAmountWithCurrencyCode,
      showVoidAuthorizationModal,
      canVoidOrCaptureAuthorization,
      transferId,
      isExpired,
      showAPIResponseModal,
      showCaptureAuthorizationModal,
      amount,
      allowCardPresentCapture,
      maxTransactionAmount,
      showSendReceiptModal,
      credentialId,
      hasReceipts,
    } = this.props

    const host = get(credentials, 'host')

    const actions = [
      {
        label: VOID_AUTHORIZATION,
        action: () => showVoidAuthorizationModal({ credentials, authorizationId, displayAuthorizationAmount: displayAmountWithCurrencyCode }),
        className: 'void-authorization-action',
        condition: canVoidOrCaptureAuthorization,
      },
      {
        label: CAPTURE_AUTHORIZATION,
        action: () => showCaptureAuthorizationModal({ credentials, authorizationId, allowCardPresentCapture, maxTransactionAmount }),
        className: 'capture-authorization-action',
        condition: canVoidOrCaptureAuthorization && !transferId && !isExpired,
      },
      {
        label: CREATE_RECEIPT,
        action: () => goToPath({ pathname: CREATE_AUTHORIZATION_RECEIPT_PATH({ credentialId, authorizationId }) }),
      },
    ]

    const receiptsActions = [
      {
        label: CREATE_RECEIPT,
        variant: 'secondary',
        action: () => goToPath({ pathname: CREATE_AUTHORIZATION_RECEIPT_PATH({ credentialId, authorizationId }) }),
        condition: !hasReceipts,
        className: 'create-receipt-button',
      },
    ]

    const receiptActions = ({ receipt, authorization }) => [
      {
        label: VIEW_RECEIPT,
        buttonVariant: 'secondary',
        action: () => {
          window.open(get(receipt, 'receiptUrl'), '_blank')
        },
        className: 'view-receipt-button',
      },
      {
        label: SEND_RECEIPT,
        buttonVariant: 'primary',
        action: () => {
          showSendReceiptModal({ receipt, authorization })
        },
        className: 'send-receipt-button',
      },
    ]

    const authorizationAPIDocs = [
      {
        label: AUTHORIZATION_API_REFERENCE,
        link: AUTHORIZATION_API_DOC_LINK,
      },
    ]

    const responseModalProps = () => showAPIResponseModal({
      api: getAuthorizationAPI,
      apiProps: {
        id: authorizationId,
        credentials,
      },
      apiRoute: `${host}/${AUTHORIZATION({ authorizationId })}`,
      apiMethod: 'GET',
      apiDocuments: authorizationAPIDocs,
    })

    return (
      <Authorization
        actions={actions}
        receiptActions={receiptActions}
        receiptsActions={receiptsActions}
        showResponseModal={responseModalProps}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationC)
