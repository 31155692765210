import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import IdentityBusinessForm from './IdentityBusinessForm'
import { IDENTITY_BUSINESS_FORM } from 'constants/formConstants'
import includes from 'lodash/includes'

import {
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
} from 'constants/identityConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(IDENTITY_BUSINESS_FORM)
  const businessType = formSelector(state, 'businessType')
  const hideOwnershipTypeDropdown = includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)

  return {
    businessType,
    hideOwnershipTypeDropdown,
  }
}

class IdentityBusinessFormC extends Component {
  componentDidUpdate(prevProps) {
    const { dispatch, businessType } = this.props
    const { businessType: prevBusinessType } = prevProps

    if (businessType !== prevBusinessType && (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType))) {
      dispatch(change(IDENTITY_BUSINESS_FORM, 'ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType]))
    }
  }

  render() {
    return (
      <IdentityBusinessForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(IdentityBusinessFormC)
