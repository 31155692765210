import './IdentityVerificationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import IdentityVerificationFormC from 'components/Customer/Forms/IdentityVerificationForm/IdentityVerificationFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { CREATE_VERIFICATION } from 'constants/language/languageConstants'

const IdentityVerificationModal = ({
  identityId,
  redirectToVerificationsTab,
}) => {
  return (
    <GenericModal
      title={CREATE_VERIFICATION}
      className='IdentityVerificationModal'
      Component={IdentityVerificationFormC}
      identityId={identityId}
      redirectToVerificationsTab={redirectToVerificationsTab}
    />
  )
}

IdentityVerificationModal.propTypes = {
  identityId: PropTypes.string,
  redirectToVerificationsTab: PropTypes.bool,
}

export default IdentityVerificationModal
