import {
  fieldEmpty,
  validateWebsite,
} from 'utilities/validate'

const validateCreateOnboardingFormBasicsForm = (values) => {
  const {
    applicationId,
    country,
    merchantName,
    expirationTime,
    feeUrl,
    returnUrl,
    termsOfServiceUrl,
    expiredSessionUrl,
  } = values

  return {
    applicationId: fieldEmpty(applicationId),
    country: fieldEmpty(country, 'Country'),
    merchantName: fieldEmpty(merchantName, 'Merchant Name'),
    expirationTime: fieldEmpty(expirationTime, 'Link Expiration Time (Days)') || (expirationTime > 365 && 'Link Expiration Time (Days) must be less than 365 days') || (expirationTime === '0' && 'Link Expiration Time (Days) must be greater than 0 days'),
    feeUrl: validateWebsite({ field: feeUrl, name: 'Fee URL' }),
    returnUrl: validateWebsite({ field: returnUrl, name: 'Return URL' }),
    termsOfServiceUrl: validateWebsite({ field: termsOfServiceUrl, name: 'Terms of Service URL' }),
    expiredSessionUrl: validateWebsite({ field: expiredSessionUrl, name: 'Expired Session URL' }),
  }
}

export default validateCreateOnboardingFormBasicsForm
