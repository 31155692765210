import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import { COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM } from 'constants/formConstants'

const ComplianceCompensatingControlsWorksheetForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  saqAFormHasYesWithCCW = false,
}) => {
  return (
    <form className='ComplianceCompensatingControlsWorksheetForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Appendix B: Compensating Controls Worksheet</h1>
        <div className='description flex column'>
          <span>Use this worksheet to define compensating controls for any requirement where &#34;YES with CCW&#34; was checked.</span>
          <i>Note: Only companies that have undertaken a risk analysis and have legitimate technological or documented business constraints can consider the use of compensating controls to achieve compliance.</i>
          <i>Refer to Appendices B, C, and D of PCI DSS for information about compensating controls and guidance on how to complete this worksheet.</i>
        </div>
      </div>

      <div className='section'>
        <h3 className='headerBorder'>Requirement Number and Definition</h3>

        <Field
          name='constraints'
          label='1. Constraints: List constraints precluding compliance with the original requirement.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />

        <Field
          name='objective'
          label='2. Objective: Define the objective of the original control; identify the objective met by the compensating control.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />

        <Field
          name='identifiedRisk'
          label='3. Identified risk: Identify any additional risk posed by the lack of the original control.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />

        <Field
          name='definitionOfCompensatingControls'
          label='4. Definition of Compensating Controls: Define the compensating controls and explain how they address the objectives of the original control and the increased risk, if any.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />

        <Field
          name='validationOfCompensatingControls'
          label='5. Validation of Compensating Controls: Define how the compensating controls were validated and tested.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />

        <Field
          name='maintenance'
          label='6. Maintenance: Define process and controls in place to maintain compensating controls.'
          component={TextAreaField}
          disabled={!saqAFormHasYesWithCCW}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

ComplianceCompensatingControlsWorksheetForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  saqAFormHasYesWithCCW: PropTypes.bool,
}

export default reduxForm({
  form: COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM,
})(ComplianceCompensatingControlsWorksheetForm)
