import { SHOW_MODAL } from 'constants/actionConstants'
import { createAction } from 'redux-actions'

const showModalAction = ({
  modalType,
  modalProps,
  className,
  overlayClassName,
}) => createAction(
  SHOW_MODAL,
  () => ({
    modalType,
    modalProps,
    className,
    overlayClassName,
  }),
)()

export default showModalAction
