import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import values from 'lodash/values'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

import {
  ROLE_PLATFORM,
  ROLE_PARTNER,
  ROLE_MERCHANT,
} from 'constants/roleConstants'

import {
  APPLICATIONS,
  CREATE_FEE_PROFILES,
  CREATE_PLATFORM_FEE_PROFILE,
  CREATE_MANAGE_2FA_FOR_YOURSELF,
  CREATE_NEW_PAYMENT_INSTRUMENTS_PAYOUTS,
  CREATE_PAYMENT_INSTRUMENT,
  DEVELOPER,
  ENFORCE_2FA_FOR_TEAM,
  DISPUTES,
  EXPORT_TRANSACTIONS,
  FEE_PROFILES,
  IDENTITIES_AND_MERCHANTS,
  INVITE_TEAM_MEMBERS,
  VIEW_TEAM_MEMBERS,
  MANAGE_API_KEYS,
  MANAGE_DISPUTES,
  MANAGE_MERCHANT_ACCOUNTS,
  MANAGE_MERCHANT_IDENTITY_INFORMATION,
  MANAGE_PAYMENT_INSTRUMENT,
  MANAGE_PAYOUT_SETTINGS,
  MANAGE_SETTLEMENTS,
  MANAGE_WEBHOOKS,
  MODIFY_APPLICATION_INFORMATION,
  MODIFY_MERCHANT_ACCOUNTS,
  PAYOUTS,
  REFUND_AND_MODIFY_TRANSACTIONS,
  RESET_2FA_FOR_INDIVIDUAL,
  RESET_2FA_FOR_USERS,
  RESET_PASSWORD_IN_DASHBOARD,
  SECURITY,
  TEAM_MANAGEMENT,
  TRANSACTIONS,
  VIEW_ALL_TRANSACTIONS,
  VIEW_APPLICATION_INFORMATION,
  VIEW_DISPUTES,
  VIEW_FEE_PROFILES,
  VIEW_MERCHANT_ACCOUNTS,
  VIEW_MERCHANT_IDENTITY_INFORMATION,
  VIEW_PAYMENT_INSTRUMENTS_FOR_TRANSACTIONS,
  VIEW_PAYOUT_SETTINGS,
  VIEW_SETTLEMENTS,
  VIEW_WEBHOOKS,
  MANAGE_RISK_PROFILE,
  RISK_PROFILE,
  CREATE_AND_ASSIGN_ROLES,
  VIEW_RISK_PROFILE,
  VIEW_APPLICATION_RISK_SETTINGS,
  EDIT_APPLICATION_RISK_SETTINGS,
  VIEW_APPLICATION_ACCOUNT_BALANCES,
  VOID_AUTHORIZATIONS,
  TRANSFERS,
} from 'constants/language/languageConstants'

export const REVIEW_QUEUE_UPDATE_PERMISSION = 'processing:review_queue:update'
export const BALANCE_TRANSFER_LIST_PERMISSION = 'processing:balance_transfer:list'
export const BALANCE_TRANSFER_READ_PERMISSION = 'processing:balance_transfer:read'
export const BALANCE_TRANSFER_CREATE_PERMISSION = 'processing:balance_transfer:create'
export const FEE_CREATE_PERMISSION = 'processing:fee:create'
export const ADJUSTMENT_CREATE_PERMISSION = 'processing:transfer:adjustment_create'
export const PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION = 'underwriting:personal_identifiable_information:read'
export const VIEW_TRANSACTIONS_LIST_PERMISSION = 'processing:transfer:list' // use for reports
export const VIEW_TRANSACTION_READ_PERMISSION = 'processing:transfer:read'
export const VIEW_AUTHORIZATIONS_LIST_PERMISSION = 'processing:authorization:list'
export const VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION = 'processing:instrument:list'
export const VIEW_SETTLEMENTS_LIST_PERMISSION = 'processing:settlement:list'
export const VIEW_REVIEW_QUEUE_LIST_PERMISSION = 'processing:review_queue:list' // not role_merchant
export const VIEW_DISPUTES_LIST_PERMISSION = 'processing:dispute:list'
export const VIEW_IDENTITIES_LIST_PERMISSION = 'processing:identity:list'
export const VIEW_MERCHANTS_LIST_PERMISSION = 'processing:merchant:list'
export const VIEW_APPLICATIONS_LIST_PERMISSION = 'processing:application:list' // not role_merchant
export const VIEW_PAYOUT_PLANS_LIST_PERMISSION = 'settlement:payout_plans:list' // only role_platform
export const VIEW_COMPLIANCE_FORMS_LIST_PERMISSION = 'compliance:compliance_forms:read'
export const UPDATE_COMPLIANCE_FORMS_PERMISSION = 'compliance:compliance_forms:update'
export const EDIT_IDENTITY_PERMISSION = 'processing:identity:update' // not role_merchant
export const VIEW_SUBSCRIPTION_BILLING_LIST_PERMISSION = 'subscription:subscription_plan:list' // only role_platform
export const VIEW_API_LOGS_LIST_PERMISSION = 'http_log:api_log:read' // not role_merchant
export const VIEW_WEBHOOKS_LIST_PERMISSION = 'processing:webhook:list' // not role_merchant
export const VIEW_ALT_PAYMENT_METHODS_PERMISSION = 'apple_pay:apple_pay_session:create' // not role_merchant
export const CREATE_TEAM_MEMBER_PERMISSION = 'authentication:user:create'
export const REFUND_TRANSACTION_PERMISSION = 'processing:transfer:refund'
export const CREATE_FEE_PROFILE_PERMISSION = 'processing:fee_profile:create'
export const VIEW_USERS_PERMISSION = 'authentication:user:list'
export const VIEW_UNDERWRITING_PROFILE_PERMISSION = 'underwriting:underwriting_profile:read'
export const CREATE_UNDERWRITING_PROFILE_PERMISSION = 'underwriting:underwriting_profile:create'
export const UPDATE_UNDERWRITING_PROFILE_PERMISSION = 'underwriting:underwriting_profile:update'
export const VIEW_BALANCES_LIST_PERMISSION = 'risk:balance:list' // not role_merchant
export const VIEW_BALANCE_PERMISSION = 'risk:balance:read' // not role_merchant
export const VIEW_BALANCE_ENTRIES_PERMISSION = 'risk:balance_entry:list' // not role_merchant
export const VIEW_BALANCE_ENTRY_PERMISSION = 'risk:balance_entry:read' // not role_merchant
export const UPDATE_AUTHORIZATION_PERMISSION = 'processing:authorization:update'
export const UPDATE_DISPUTE_PERMISSION = 'processing:dispute:update'
export const VIEW_SIFT_FRAUD_SCORES_PERMISSION = 'fraud:fraud_check:read'
export const VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION = 'fraud:fraud_check:list'
export const UPDATE_SETTLEMENT_PERMISSION = 'processing:settlement:update'
export const VIEW_FEE_PROFILE_PERMISSION = 'processing:fee_profile:read'

const ROLE_PARTNER_PERMISSIONS = [
  'settlement:settlement_group:read',
  'settlement:settlement_group_settings:create',
  'settlement:settlement_group_settings:delete',
  'settlement:settlement_group_settings:list',
  'processing:view:identity_list_all',
  'processing:platform:read',
  'processing:application:read',
  VIEW_APPLICATIONS_LIST_PERMISSION,
  'processing:application:processors_list',
  'processing:application:users_list',
  'processing:authorization:read',
  VIEW_AUTHORIZATIONS_LIST_PERMISSION,
  'processing:authorization:create',
  UPDATE_AUTHORIZATION_PERMISSION,
  'processing:fee_profile:read',
  'processing:fee_profile:list',
  'processing:fee_profile:create',
  'processing:identity:read',
  VIEW_IDENTITIES_LIST_PERMISSION,
  'processing:identity:create',
  EDIT_IDENTITY_PERMISSION,
  'processing:instrument:read',
  VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION,
  'processing:instrument:create',
  'processing:instrument:update',
  'processing:instrument_update:read',
  'processing:instrument_update:list',
  'processing:instrument_update:create',
  'processing:instrument_update:update',
  'processing:merchant_profile:read',
  'processing:merchant_profile:list',
  'processing:merchant_profile:update',
  'processing:merchant:read',
  VIEW_MERCHANTS_LIST_PERMISSION,
  'processing:merchant:create',
  'processing:merchant:update',
  'processing:processor:read',
  'processing:processor:list',
  'processing:reserve_profile:read',
  'processing:reserve_profile:list',
  'processing:risk_profile:read',
  'processing:risk_profile:list',
  'processing:risk_profile_rule:read',
  'processing:risk_profile_rule:list',
  VIEW_BALANCES_LIST_PERMISSION,
  VIEW_BALANCE_PERMISSION,
  VIEW_BALANCE_ENTRIES_PERMISSION,
  VIEW_BALANCE_ENTRY_PERMISSION,
  'processing:fee_strategy:read',
  'processing:fee_strategy:list',
  'processing:settlement:transfers_list',
  'processing:settlement:read',
  VIEW_SETTLEMENTS_LIST_PERMISSION,
  'processing:settlement:create',
  'processing:transaction:read',
  'processing:transaction:list',
  'processing:transfer:fee_return',
  'processing:transfer:platform_fee_return',
  'processing:transfer:funds_release',
  'processing:transfer:refund',
  'processing:transfer:refunds_list',
  'processing:transfer:fees_list',
  'processing:transfer:read',
  VIEW_TRANSACTIONS_LIST_PERMISSION,
  'processing:transfer:create',
  'processing:transfer:update',
  'processing:dispute:evidence_submit',
  'processing:dispute:files_list',
  'processing:dispute:read',
  VIEW_DISPUTES_LIST_PERMISSION,
  'processing:review_queue:read',
  VIEW_REVIEW_QUEUE_LIST_PERMISSION,
  'processing:webhook:read',
  VIEW_WEBHOOKS_LIST_PERMISSION,
  'processing:webhook:create',
  'processing:webhook:update',
  'authentication:user:read',
  'authentication:user:list',
  CREATE_TEAM_MEMBER_PERMISSION,
  'authentication:user:update',
  'authentication:user:partner_create',
  'authentication:user:merchant_create',
  'authentication:user:permission_list',
  'authentication:user:permission_update',
  'processing:verification:read',
  'processing:verification:list',
  'processing:verification:create',
  'processing:verification:update',
  'processing:black_listed_instrument:read',
  'processing:black_listed_instrument:create',
  'processing:black_listed_instrument:delete',
  'processing:application_profile:read',
  'processing:application_profile:list',
  'settlement:settlement_group_settings:read',
  'processing:fee:read',
  'processing:fee:list',
  'search:search:read',
  'search:search:list',
  'reporting:report:read',
  'reporting:report:list',
  'processing:dispute:simulate_state',
  'settlement:payout_plan_instrument_mapping:update',
  'settlement:payout_plan_instrument_mapping:list',
  'file:files:read',
  'file:files:list',
  'file:files:create',
  'file:files:download',
  'file:external_links:upload',
  'file:external_links:download',
  'file:external_links:read',
  'file:external_links:list',
  'account-updater-http:batch:create',
  'account-updater-http:batch:read',
  'account-updater-http:batch:download',
  'payment_method_configurations:payment_method_configuration:read',
  'payment_method_configurations:payment_method_configuration:list',
  'payment_method_configurations:payment_method_configuration:create',
  'payment_method_configurations:payment_method_configuration:update',
  VIEW_ALT_PAYMENT_METHODS_PERMISSION,
  'compliance:questionnaire_template:create',
  'compliance:questionnaire_template:read',
  'compliance:questionnaire:read',
  'compliance:questionnaire:update',
  'compliance:compliance_form_templates:create',
  'compliance:compliance_form_templates:read',
  VIEW_COMPLIANCE_FORMS_LIST_PERMISSION,
  UPDATE_COMPLIANCE_FORMS_PERMISSION,
  'cert_management:certificate_signing_request:create',
  'cert_management:certificate_signing_request:list',
  'cert_management:signed_certificate:create',
  'settlement:merchant_payout_profile:read',
  'settlement:merchant_payout_profile:update',
  'settlement:merchant_payout_profile:list',
  VIEW_API_LOGS_LIST_PERMISSION,
  'http_log:api_log:list',
  'processing.fee_profile_rule_set:read',
  'processing.fee_profile_settings:read',
  VIEW_UNDERWRITING_PROFILE_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION,
]

const ROLE_MERCHANT_PERMISSIONS = [
  CREATE_TEAM_MEMBER_PERMISSION,
  'authentication:user:list',
  'authentication:user:merchant_create',
  'authentication:user:read',
  'authentication:user:update',
  'authentication:user:permission_list',
  VIEW_COMPLIANCE_FORMS_LIST_PERMISSION,
  UPDATE_COMPLIANCE_FORMS_PERMISSION,
  'compliance:questionnaire:read',
  'compliance:questionnaire:update',
  'file:external_links:download',
  'file:external_links:list',
  'file:external_links:read',
  'file:external_links:upload',
  'file:files:create',
  'file:files:download',
  'file:files:list',
  'file:files:read',
  'processing:application:users_list',
  'processing:authorization:create',
  VIEW_AUTHORIZATIONS_LIST_PERMISSION,
  'processing:authorization:read',
  'processing:authorization:update',
  'processing:dispute:evidence_submit',
  'processing:dispute:files_list',
  VIEW_DISPUTES_LIST_PERMISSION,
  'processing:dispute:read',
  VIEW_IDENTITIES_LIST_PERMISSION,
  'processing:identity:read',
  'processing:instrument:create',
  VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION,
  'processing:instrument:read',
  'processing:instrument_update:create',
  'processing:instrument_update:list',
  'processing:instrument_update:read',
  VIEW_MERCHANTS_LIST_PERMISSION,
  'processing:merchant:read',
  'processing:merchant_profile:read',
  VIEW_SETTLEMENTS_LIST_PERMISSION,
  'processing:settlement:read',
  'processing:settlement:transfers_list',
  'processing:transfer:create',
  'processing:transfer:fees_list',
  VIEW_TRANSACTIONS_LIST_PERMISSION,
  'processing:transfer:read',
  'processing:transfer:refund',
  'processing:transfer:refunds_list',
  'processing:transfer:update',
  'processing:verification:list',
  'processing:verification:read',
  'reporting:report:list',
  'reporting:report:read',
  'settlement:merchant_payout_profile:read',
  UPDATE_AUTHORIZATION_PERMISSION,
]

export const PERMISSIONS_FOR_ALL = [
  'authentication:user:permission_list',
  'processing:view:application_list_all',
  'processing:view:identity_list_all',
  'processing:view:settlement_system',
  'processing:view:entity_system',
  'search:search:read',
  'search:search:list',
]

const AUTH_ROLE_TO_ALLOWED_PERMISSIONS_MAP = {
  // don't have full list for ROLE_PLATFORM yet, but this is only used for partner and merchants
  [ROLE_PARTNER]: ROLE_PARTNER_PERMISSIONS,
  [ROLE_MERCHANT]: ROLE_MERCHANT_PERMISSIONS,
}

export const permissionsForAllFilteredByRole = ({ authRole }) => {
  if (authRole === ROLE_PLATFORM) {
    return PERMISSIONS_FOR_ALL
  }

  const authRoleAllowedPermissions = get(AUTH_ROLE_TO_ALLOWED_PERMISSIONS_MAP, authRole, [])

  return filter(PERMISSIONS_FOR_ALL, (permission) => includes(authRoleAllowedPermissions, permission))
}

// TODO: Update name in permissions list and value in checkbox options to BE values
export const ALL_PERMISSIONS_LIST = {
  [VIEW_TEAM_MEMBERS]: {
    label: VIEW_TEAM_MEMBERS,
    value: VIEW_TEAM_MEMBERS,
    category: TEAM_MANAGEMENT,
    permissions: [
      'authentication:user:list',
      'authentication:user:read',
    ],
  },
  [INVITE_TEAM_MEMBERS]: {
    label: INVITE_TEAM_MEMBERS,
    value: INVITE_TEAM_MEMBERS,
    category: TEAM_MANAGEMENT,
    hideForCustom: true,
    permissions: [
      'authentication:user:merchant_create',
      CREATE_TEAM_MEMBER_PERMISSION,
      'authentication:user:partner_create',
    ],
  },
  [CREATE_AND_ASSIGN_ROLES]: {
    label: CREATE_AND_ASSIGN_ROLES,
    value: CREATE_AND_ASSIGN_ROLES,
    category: TEAM_MANAGEMENT,
    hideForCustom: true,
    permissions: [
      'authentication:user:permission_update',
      'authentication:user:update',
    ],
  },
  // TODO: uncomment once 2FA feature is built
  // [CREATE_MANAGE_2FA_FOR_YOURSELF]: {
  //     label: CREATE_MANAGE_2FA_FOR_YOURSELF,
  //     value: CREATE_MANAGE_2FA_FOR_YOURSELF,
  //     category: SECURITY,
  //     permissions: [],
  // },
  // [RESET_2FA_FOR_INDIVIDUAL]: {
  //     label: RESET_2FA_FOR_INDIVIDUAL,
  //     value: RESET_2FA_FOR_INDIVIDUAL,
  //     category: SECURITY,
  //     permissions: [],
  // },
  // [RESET_2FA_FOR_USERS]: {
  //     label: RESET_2FA_FOR_USERS,
  //     value: RESET_2FA_FOR_USERS,
  //     category: SECURITY,
  //     permissions: [],
  // },
  // [ENFORCE_2FA_FOR_TEAM]: {
  //     label: ENFORCE_2FA_FOR_TEAM,
  //     value: ENFORCE_2FA_FOR_TEAM,
  //     category: SECURITY,
  //     permissions: [],
  // },
  [MANAGE_API_KEYS]: {
    label: MANAGE_API_KEYS,
    value: MANAGE_API_KEYS,
    category: DEVELOPER,
    permissions: [
      VIEW_API_LOGS_LIST_PERMISSION,
      'http_log:api_log:list',
    ],
  },
  [VIEW_WEBHOOKS]: {
    label: VIEW_WEBHOOKS,
    value: VIEW_WEBHOOKS,
    category: DEVELOPER,
    permissions: [
      VIEW_WEBHOOKS_LIST_PERMISSION,
      'processing:webhook:read',
    ],
  },
  [MANAGE_WEBHOOKS]: {
    label: MANAGE_WEBHOOKS,
    value: MANAGE_WEBHOOKS,
    category: DEVELOPER,
    permissions: [
      'processing:platform_webhook:create',
      'processing:webhook:create',
      'processing:webhook:update',
    ],
  },
  [VIEW_ALL_TRANSACTIONS]: {
    label: VIEW_ALL_TRANSACTIONS,
    value: VIEW_ALL_TRANSACTIONS,
    category: TRANSACTIONS,
    permissions: [
      'processing:fee:read',
      'processing:fee:list',
      'processing:transfer:read',
      'processing:transfer:fees_list',
      'processing:transfer:refunds_list',
      VIEW_TRANSACTIONS_LIST_PERMISSION,
      'subscription:subscription_amount:read',
      VIEW_SUBSCRIPTION_BILLING_LIST_PERMISSION,
      'subscription:subscription_item:read',
      'subscription:subscription_enrollment:read',
      'subscription:subscription_schedule:read',
      'subscription:subscription_schedule:list',
      'subscription:subscription_enrollment:list',
      'subscription:subscription_group:read',
      'subscription:subscription_item:list',
      'subscription:subscription_plan:read',
      'subscription:subscription_group:list',
      'subscription:subscription_amount:list',
      'processing:balance_transfer:read',
      'processing:balance_transfer:list',
      'processing:transaction:read',
      'processing:transaction:list',
      'processing:authorization:read',
      VIEW_AUTHORIZATIONS_LIST_PERMISSION,
      'settlement:funding_instruction_preview:list',
      'processing:black_listed_instrument:read',
    ],
  },
  [VIEW_PAYMENT_INSTRUMENTS_FOR_TRANSACTIONS]: {
    label: VIEW_PAYMENT_INSTRUMENTS_FOR_TRANSACTIONS,
    value: VIEW_PAYMENT_INSTRUMENTS_FOR_TRANSACTIONS,
    category: TRANSACTIONS,
    permissions: [
      'payment_method_configurations:payment_method_configuration:list',
      'payment_method_configurations:payment_method_configuration:read',
      'processing:instrument_update:list',
      'processing:instrument_update:read',
      'processing:instrument:read',
      VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION,
    ],
  },
  [CREATE_PAYMENT_INSTRUMENT]: {
    label: CREATE_PAYMENT_INSTRUMENT,
    value: CREATE_PAYMENT_INSTRUMENT,
    category: TRANSACTIONS,
    permissions: [
      'processing:instrument:create',
    ],
  },
  [MANAGE_PAYMENT_INSTRUMENT]: {
    label: MANAGE_PAYMENT_INSTRUMENT,
    value: MANAGE_PAYMENT_INSTRUMENT,
    category: TRANSACTIONS,
    permissions: [
      'payment_method_configurations:payment_method_configuration:update',
      'payment_method_configurations:payment_method_configuration:create',
      'cert_management:certificate_signing_request:list',
      'cert_management:signed_certificate:create',
      'cert_management:certificate_signing_request:create',
      'account-updater-http:batch:download',
      'account-updater-http:batch:create',
      'account-updater-http:batch:read',
      'processing:instrument_update:create',
      'processing:instrument_update:update',
      'processing:instrument:update',
    ],
  },
  [REFUND_AND_MODIFY_TRANSACTIONS]: {
    label: REFUND_AND_MODIFY_TRANSACTIONS,
    value: REFUND_AND_MODIFY_TRANSACTIONS,
    category: TRANSACTIONS,
    permissions: [
      'processing:fee:create',
      'processing:fee:update',
      'processing:transfer:refund',
      'processing:transfer:payout',
      'processing:transfer:accounting_create',
      'processing:transfer:funds_release',
      'processing:transfer:fee_return',
      'processing:transfer:platform_fee_return',
      'processing:transfer:adjustment_create',
      'processing:transfer:create',
      'processing:transfer:update',
      'subscription:subscription_plan:update',
      'subscription:subscription_enrollment:update',
      'subscription:subscription_group:create',
      'subscription:subscription_item:create',
      'subscription:subscription_amount:create',
      'subscription:subscription_enrollment:create',
      'subscription:subscription_group:update',
      'subscription:subscription_item:update',
      'subscription:subscription_plan:create',
      'subscription:subscription_schedule:create',
      'subscription:subscription_schedule:update',
      'subscription:subscription_amount:update',
      'processing:fee_strategy:create',
      'processing:fee_strategy:read',
      'processing:fee_strategy:list',
      'processing:fee_strategy:update',
      'processing:balance_transfer:create',
      'processing:transaction:update',
      'processing:transaction:create',
      'processing:black_listed_instrument:create',
      'processing:black_listed_instrument:delete',
      VIEW_ALT_PAYMENT_METHODS_PERMISSION,
    ],
  },
  [EXPORT_TRANSACTIONS]: {
    label: EXPORT_TRANSACTIONS,
    value: EXPORT_TRANSACTIONS,
    category: TRANSACTIONS,
    permissions: [
      'reporting:report:read',
      'reporting:report:list',
    ],
  },
  [VOID_AUTHORIZATIONS]: {
    label: VOID_AUTHORIZATIONS,
    value: VOID_AUTHORIZATIONS,
    category: TRANSACTIONS,
    permissions: [
      'processing:authorization:update',
    ],
  },
  [VIEW_PAYOUT_SETTINGS]: {
    label: VIEW_PAYOUT_SETTINGS,
    value: VIEW_PAYOUT_SETTINGS,
    category: PAYOUTS,
    permissions: [
      VIEW_PAYOUT_PLANS_LIST_PERMISSION,
      'settlement:payout_plans:read',
      'settlement:merchant_payout_profile:list',
      'settlement:merchant_payout_profile:read',
      'settlement:payout_plan_instrument_mapping:list',
    ],
  },
  [MANAGE_PAYOUT_SETTINGS]: {
    label: MANAGE_PAYOUT_SETTINGS,
    value: MANAGE_PAYOUT_SETTINGS,
    category: PAYOUTS,
    permissions: [
      'settlement:payout_plans:update',
      'settlement:merchant_payout_profile:update',
      'settlement:payout_plan_instrument_mapping:update',
    ],
  },
  [VIEW_SETTLEMENTS]: {
    label: VIEW_SETTLEMENTS,
    value: VIEW_SETTLEMENTS,
    category: PAYOUTS,
    permissions: [
      'settlement:settlement_group_settings:list',
      'settlement:settlement_group:read',
      VIEW_SETTLEMENTS_LIST_PERMISSION,
      'processing:settlement:transfers_list',
      'processing:settlement:read',
      'settlement:settlement_group_settings:read',
      'settlement:funding_instruction:list',
    ],
  },
  [MANAGE_SETTLEMENTS]: {
    label: MANAGE_SETTLEMENTS,
    value: MANAGE_SETTLEMENTS,
    category: PAYOUTS,
    permissions: [
      'settlement:settlement_group_settings:create',
      'processing:settlement:create',
      'processing:settlement:settling_transfer_create',
      'settlement:settlement_group_settings:delete',
      'processing:settlement:update',
      'settlement:funding_instruction:create',
      'settlement:funding_instruction:update',
      'settlement:funding_instruction:resubmit',
      'processing:review_queue:create',
      VIEW_REVIEW_QUEUE_LIST_PERMISSION,
      'processing:review_queue:update',
      'processing:review_queue:read',
      'processing:reserve_profile:list',
      'processing:reserve_profile:read',
      'processing:reserve_profile:create',
      'processing:reserve_profile:update',
    ],
  },
  [VIEW_DISPUTES]: {
    label: VIEW_DISPUTES,
    value: VIEW_DISPUTES,
    category: DISPUTES,
    permissions: [
      'processing:dispute:files_list',
      VIEW_DISPUTES_LIST_PERMISSION,
      'processing:dispute:read',
    ],
  },
  [MANAGE_DISPUTES]: {
    label: MANAGE_DISPUTES,
    value: MANAGE_DISPUTES,
    category: DISPUTES,
    permissions: [
      'processing:dispute:update',
      'processing:dispute:create',
      'processing:dispute:evidence_submit',
      'processing:dispute:simulate_state',
    ],
  },
  [VIEW_FEE_PROFILES]: {
    label: VIEW_FEE_PROFILES,
    value: VIEW_FEE_PROFILES,
    category: FEE_PROFILES,
    permissions: [
      'processing:fee_profile:list',
      'processing:fee_profile:read',
      'processing.fee_profile_rule_set:read',
      'processing.fee_profile_settings:read',
    ],
  },
  [CREATE_FEE_PROFILES]: {
    label: CREATE_FEE_PROFILES,
    value: CREATE_FEE_PROFILES,
    category: FEE_PROFILES,
    permissions: [
      'processing:fee_profile:create',
      'processing:fee_profile:update',
    ],
  },
  [CREATE_PLATFORM_FEE_PROFILE]: {
    label: CREATE_PLATFORM_FEE_PROFILE,
    value: CREATE_PLATFORM_FEE_PROFILE,
    category: FEE_PROFILES,
    hideForCustom: true,
    permissions: [
      'processing:platform_fee_profile:update',
    ],
  },
  // TODO: Uncomment Assign Fee Profile when finished being built
  // [ASSIGN_FEE_PROFILES]: {
  //     label: ASSIGN_FEE_PROFILES,
  //     value: ASSIGN_FEE_PROFILES,
  //     category: FEE_PROFILES,
  //     permissions: [],
  // },
  [VIEW_RISK_PROFILE]: {
    label: VIEW_RISK_PROFILE,
    value: VIEW_RISK_PROFILE,
    category: RISK_PROFILE,
    permissions: [
      'processing:risk_profile_rule:read',
      'processing:risk_profile_rule:list',
      'processing:risk_profile:list',
      'processing:risk_profile:read',
      'risk:rule_instance:list',
      VIEW_BALANCES_LIST_PERMISSION,
    ],
  },
  // [MANAGE_RISK_PROFILE]: {
  //   label: MANAGE_RISK_PROFILE,
  //   value: MANAGE_RISK_PROFILE,
  //   category: RISK_PROFILE,
  //   permissions: [
  //     'processing:risk_profile_rule:create',
  //     'processing:risk_profile_rule:update',
  //     'processing:risk_profile:update',
  //     'processing:risk_profile:create',
  //     'risk:rule_instance:create',
  //     'risk:rule_instance:update',
  //   ],
  // },
  [VIEW_APPLICATION_ACCOUNT_BALANCES]: {
    label: VIEW_APPLICATION_ACCOUNT_BALANCES,
    value: VIEW_APPLICATION_ACCOUNT_BALANCES,
    category: RISK_PROFILE,
    permissions: [
      VIEW_BALANCES_LIST_PERMISSION,
    ],
  },
  [VIEW_APPLICATION_ACCOUNT_BALANCES]: {
    label: VIEW_APPLICATION_ACCOUNT_BALANCES,
    value: VIEW_APPLICATION_ACCOUNT_BALANCES,
    category: RISK_PROFILE,
    permissions: [
      VIEW_BALANCES_LIST_PERMISSION,
    ],
  },
  [VIEW_MERCHANT_IDENTITY_INFORMATION]: {
    label: VIEW_MERCHANT_IDENTITY_INFORMATION,
    value: VIEW_MERCHANT_IDENTITY_INFORMATION,
    category: IDENTITIES_AND_MERCHANTS,
    permissions: [
      'file:files:list',
      'file:files:download',
      'file:files:read',
      'processing:identity:read',
      VIEW_IDENTITIES_LIST_PERMISSION,
      'file:external_links:read',
      'file:external_links:list',
      'file:external_links:download',
      'processing:platform:read',
      'processing:platform:list',
    ],
  },
  [MANAGE_MERCHANT_IDENTITY_INFORMATION]: {
    label: MANAGE_MERCHANT_IDENTITY_INFORMATION,
    value: MANAGE_MERCHANT_IDENTITY_INFORMATION,
    category: IDENTITIES_AND_MERCHANTS,
    permissions: [
      EDIT_IDENTITY_PERMISSION,
      'processing:identity:create',
      'file:external_links:upload',
      'file:files:create',
      'processing:platform:update',
    ],
  },
  [VIEW_MERCHANT_ACCOUNTS]: {
    label: VIEW_MERCHANT_ACCOUNTS,
    value: VIEW_MERCHANT_ACCOUNTS,
    category: IDENTITIES_AND_MERCHANTS,
    permissions: [
      VIEW_COMPLIANCE_FORMS_LIST_PERMISSION,
      'processing:merchant:read',
      VIEW_MERCHANTS_LIST_PERMISSION,
      'compliance:compliance_form_templates:read',
      'processing:merchant_profile:list',
      'processing:merchant_profile:read',
      'compliance:questionnaire_template:read',
      'compliance:questionnaire:read',
      'processing:verification:list',
      'processing:verification:read',
    ],
  },
  [MODIFY_MERCHANT_ACCOUNTS]: {
    label: MODIFY_MERCHANT_ACCOUNTS,
    value: MODIFY_MERCHANT_ACCOUNTS,
    category: IDENTITIES_AND_MERCHANTS,
    permissions: [
      'processing:verification:create',
      'processing:verification:update',
      'processing:merchant_profile:create',
      'processing:merchant_profile:update',
      UPDATE_COMPLIANCE_FORMS_PERMISSION,
      'processing:merchant:create',
      'processing:merchant:update',
      'compliance:compliance_form_templates:create',
      'compliance:questionnaire_template:create',
      'compliance:questionnaire:update',
    ],
  },
  // [MANAGE_MERCHANT_ACCOUNTS]: {
  //     label: MANAGE_MERCHANT_ACCOUNTS,
  //     value: MANAGE_MERCHANT_ACCOUNTS,
  //     category: IDENTITIES_AND_MERCHANTS,
  //     permissions: [],
  // },
  [VIEW_APPLICATION_INFORMATION]: {
    label: VIEW_APPLICATION_INFORMATION,
    value: VIEW_APPLICATION_INFORMATION,
    category: APPLICATIONS,
    permissions: [
      'processing:application:read',
      'processing:application:processors_list',
      'processing:application:users_list',
      VIEW_APPLICATIONS_LIST_PERMISSION,
      'processing:application_profile:list',
      'processing:processor:read',
      'processing:processor:list',
      'processing:application_profile:read',
    ],
  },
  [MODIFY_APPLICATION_INFORMATION]: {
    label: MODIFY_APPLICATION_INFORMATION,
    value: MODIFY_APPLICATION_INFORMATION,
    category: APPLICATIONS,
    permissions: [
      'processing:application:processor_disable',
      'processing:application:update',
      'processing:application:processor_enable',
      'processing:application:merchant_profile_update',
      'processing:application:create',
      'processing:application_profile:update',
      'processing:application_profile:create',
      'processing:processor:update',
      'processing:processor:create',
    ],
  },
  [VIEW_APPLICATION_RISK_SETTINGS]: {
    label: VIEW_APPLICATION_RISK_SETTINGS,
    value: VIEW_APPLICATION_RISK_SETTINGS,
    category: APPLICATIONS,
    permissions: [
      VIEW_UNDERWRITING_PROFILE_PERMISSION,
    ],
  },
  [EDIT_APPLICATION_RISK_SETTINGS]: {
    label: EDIT_APPLICATION_RISK_SETTINGS,
    value: EDIT_APPLICATION_RISK_SETTINGS,
    category: APPLICATIONS,
    hideForCustom: true,
    permissions: [
      CREATE_UNDERWRITING_PROFILE_PERMISSION,
      UPDATE_UNDERWRITING_PROFILE_PERMISSION,
    ],
  },
}

export const CUSTOM_ROLE_PERMISSIONS_LIST = omitBy(ALL_PERMISSIONS_LIST, 'hideForCustom')

export const getPermissionCheckboxOptions = ({ permissionList = CUSTOM_ROLE_PERMISSIONS_LIST, authRole }) => {
  const filteredPermissionList = filteredAllPermissionsByRole({ permissionList, authRole })

  return values(reduce(filteredPermissionList, (total, value) => {
    const category = get(value, 'category')
    const roleValue = get(value, 'value')
    const roleLabel = get(value, 'label')

    if (!total[category]) {
      total[category] = {
        label: category,
        value: category,
        children: [{
          label: roleLabel,
          value: roleValue,
        }]
      }
    } else {
      const existingChildren = get(total[category], 'children')
      total[category]['children'] = [...existingChildren, {
        label: roleLabel,
        value: roleValue,
      }]
    }

    return total
  }, {}))
}

export const constructPermissionFormValues = ({ enabledEvents }) => {
  return reduce(enabledEvents, (total, {entity, types}) => {
    const typesObj = {}
    forEach(types, (typeName) => typesObj[typeName] = true)

    return merge({}, total, {[entity]: typesObj})
  }, {})
}

export const filteredAllPermissionsByRole = ({ permissionList = ALL_PERMISSIONS_LIST, authRole }) => {
  if (!authRole || authRole === ROLE_PLATFORM) {
    return permissionList
  }

  const allowedPermissions = authRole === ROLE_PARTNER ? ROLE_PARTNER_PERMISSIONS : ROLE_MERCHANT_PERMISSIONS

  return reduce(permissionList, (total, permissionData, permissionKey) => {
    const currentPermissions = get(permissionData, 'permissions')
    const filteredPermissions = filter(currentPermissions, (permission) => includes(allowedPermissions, permission))

    if (isEmpty(filteredPermissions)) {
      return total
    }

    return merge({}, total, {
      [permissionKey]: merge({}, omit(permissionData, 'permissions'), { permissions: filteredPermissions }),
    })
  }, {})
}
