import showModalAction from 'utilities/actions/showModalAction'
import postDashboardUserMFAAssociateRequest from 'utilities/actions/post/postDashboardUserMFAAssociateRequest'
import { POST_DASHBOARD_USER_MFA_TOKEN_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'

import {
  SETUP_MFA_SMS_MODAL,
  SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL,
} from 'constants/modalConstants'

const submitConfirmPasswordMFAForm = (values, dispatch, props) => {
  const { type } = props

  dispatch({
    type: POST_DASHBOARD_USER_MFA_TOKEN_F_REQUEST,
    payload: {
      values,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const token = get(newValues, 'mfa_token', '')

        if (type === 'sms') {
          dispatch(showModalAction({
            modalType: SETUP_MFA_SMS_MODAL,
            modalProps: {
              type,
              token,
            },
            className: 'modal-md no-pad',
          }))
        }

        if (type === 'totp') {
          dispatch(postDashboardUserMFAAssociateRequest({
            token,
            values: {
              mfa_type: 'TOTP',
            },
            successCallback: ({ newValues: associateNewValues }) => {
              const {
                barcode_uri: barcodeUri,
                mfa_id: mfaId,
                oob_code: oobCode,
                recovery_codes: recoveryCodes,
              } = associateNewValues

              dispatch(showModalAction({
                modalType: SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL,
                modalProps: {
                  type,
                  token,
                  barcodeUri,
                  mfaId,
                  oobCode,
                  recoveryCodes,
                },
                className: 'modal-md no-pad',
              }))
            },
          }))
        }
      },
    },
  })
}

export default submitConfirmPasswordMFAForm
