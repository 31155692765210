import getCurrentUserRequest from 'utilities/actions/get/getCurrentUserRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getDashboardCustomizationRequest from 'utilities/actions/get/getDashboardCustomizationRequest'
import { UPDATE_DASHBOARD_CONFIGURATIONS } from 'constants/amplitudeConstants'
import omit from 'lodash/omit'
import get from 'lodash/get'

import {
  POST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
  PATCH_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
} from 'constants/flowConstants'

const submitDashboardConfigurationsForm = (values, dispatch, props) => {
  const {
    id,
    entityId,
    entityType,
    initialValues = {},
    credentials,
    customSuccessFunc = () => {},
    customSuccessMsg,
    hideFlashNotification = false,
    eventType = UPDATE_DASHBOARD_CONFIGURATIONS,
  } = props

  const formValuesToSubmit = {
    entityId,
    entityType,
    ...values,
  }

  const {
    subDomainName,
  } = initialValues

  const nextSubDomainName = get(values, 'subDomainName')
  const sameSubDomain = subDomainName === nextSubDomainName
  const actionType = id ? PATCH_DASHBOARD_CUSTOMIZATIONS_F_REQUEST : POST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST
  const dashboardType = get(values, 'dashboardType') || get(props, 'dashboardType')

  const amplitudeValues = removeUndefined({
    entityId,
    entityType,
    dashboardType,
    credentials,
    id,
  })

  dispatch(removeUndefined({
    type: actionType,
    payload: {
      values: sameSubDomain ? omit(formValuesToSubmit, 'subDomainName') : formValuesToSubmit,
      id,
      credentials,
    },
    meta: {
      successCallback: () => {
        if (actionType === POST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST) {
          dispatch(getCurrentUserRequest({ credentials }))
        }

        dispatch(getDashboardCustomizationRequest({ credentials, entityId, entityType }))
        customSuccessFunc()
      },
      actionId: sendAmplitudeActionEvent(eventType, amplitudeValues),
      hideFlashNotification,
      successMessage: customSuccessMsg,
    },
  }))
}

export default submitDashboardConfigurationsForm
