const convertToAMPMTime = ({ hour }) => {
  if (hour >= 0 && hour <= 24) {
    if (hour === 0) {
      return '12:00 AM'
    } if (hour < 12) {
      return `${hour}:00 AM`
    } if (hour === 12) {
      return '12:00 PM'
    }
    return `${hour - 12}:00 PM`
  }

  return hour
}

export default convertToAMPMTime
