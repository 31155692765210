import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CREDENTIALS_MEMBERSHIPS } from 'constants/apiConstants'

const getCredentialUsersAPI = ({ credentials, meta }) => {
  const { id } = credentials
  const path = DASHBOARD_SERVICE_CREDENTIALS_MEMBERSHIPS(id)
  return dashboardAPI.get({ path, meta })
}

export default getCredentialUsersAPI
