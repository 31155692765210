import './ChartS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { SuperChart } from '@superset-ui/core'
import SelectInput from 'components/Shared/Inputs/SelectInput/SelectInput'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import Loader from 'components/Shared/Loader/Loader'
import Skeleton from 'components/Shared/Skeleton/Skeleton'
import removeUndefined from 'utilities/remove/removeUndefined'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import map from 'lodash/map'

const Chart = ({
  id = '',
  title = '',
  name = '',
  type = '',
  data = [],
  formData = {},
  width,
  height,
  styles = {},
  isFetching = true,
  hideTitle = false,
  className = '',
  groupBy = [],
  showGroupBy = false,
  selectGroupByOption = () => {},
  pivots = [],
  showPivots = false,
  selectPivot = () => {},
  tooltipMessage = '',
  tooltipPosition = 'right',
  skeletonLoadingHeight = '132px',
  showSkeletonLoading = false,
}) => {
  const classNames = classnames('card', {
    [className]: !!className,
    loaded: !isFetching,
    [`chart-${id}`]: !isFetching,
  })

  const containerStyles = removeUndefined({
    minHeight: parseInt(height, 10) + 32, // 32px is the added height of the padding (16px top and bottom)
    minWidth: width,
  })

  const showChart = !isFetching && type && !isEmpty(data) && !isEmpty(formData)
  const showChartHeader = !!title || showGroupBy || showPivots

  return (
    <div className='Chart'>
      { isFetching && showSkeletonLoading && <Skeleton height={skeletonLoadingHeight} />}

      {(!isFetching || !showSkeletonLoading) && (
        <>
          { showChartHeader && (
          <div className='chart-header flex space-between'>
            <div className='flex'>
              { title && <h4>{title}</h4> }

              { tooltipMessage && (
              <TooltipLabelC
                position={tooltipPosition}
                message={tooltipMessage}
              />
              )}
            </div>

            <div className='grouped-filters flex'>
              { showPivots && size(pivots) > 0 && (
              <div className='grouped-charts-pivots flex'>
                <SelectInput
                  name='chart-pivot-by'
                  options={map(pivots, ({ chart_name: pivotChartName, labels }) => ({ label: labels, value: pivotChartName }))}
                  onChange={selectPivot}
                  className='chart-pivot-by'
                />
              </div>
              )}

              { showGroupBy && size(groupBy) > 0 && (
              <div className='grouped-charts-groups flex'>
                <SelectInput
                  name='chart-group-by'
                  options={map(groupBy, ({ column, label }) => ({ label, value: column }))}
                  onChange={selectGroupByOption}
                  className='chart-group-by'
                />
              </div>
              )}
            </div>
          </div>
          )}

          <div className={classNames}>
            {isFetching && !showSkeletonLoading && <Loader />}

            <div className='container' style={containerStyles}>
              { !hideTitle && name && <div className='p-1'>{name}</div> }

              { showChart && (
              <SuperChart
                chartType={type}
                width={width}
                height={height}
                styles={styles}
                formData={formData}
                queriesData={data}
              />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

Chart.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  formData: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  styles: PropTypes.object,
  isFetching: PropTypes.bool,
  hideTitle: PropTypes.bool,
  className: PropTypes.string,
  groupBy: PropTypes.array,
  showGroupBy: PropTypes.bool,
  selectGroupByOption: PropTypes.func,
  pivots: PropTypes.array,
  showPivots: PropTypes.bool,
  selectPivot: PropTypes.func,
  tooltipPosition: PropTypes.string,
  skeletonLoadingHeight: PropTypes.string,
  showSkeletonLoading: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

export default Chart
