import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityUploaderForm from 'components/Admin/Forms/IdentityUploaderForm/IdentityUploaderForm'
import get from 'lodash/get'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import clearIdentityUploaderReducerRequest from 'utilities/actions/clearIdentityUploaderReducerRequest'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const totalCount = get(state, 'identityUploaderR.totalCount')

  return {
    credentials,
    totalCount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearIdentityUploaderReducer: () => dispatch(clearIdentityUploaderReducerRequest()),
  }
}

class IdentityUploaderFormC extends Component {
  render() {
    return (
      <IdentityUploaderForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityUploaderFormC)
