import './HostedMerchantOnboardingS.scss'
import React from 'react'
import Loader from 'components/Shared/Loader/Loader'
import PropTypes from 'prop-types'
import HostedMerchantOnboardingFlowC from 'components/Customer/Pages/HostedMerchantOnboardingFlow/HostedMerchantOnboardingFlowC'
import StartHostedMerchantOnboardingScreen from './StartHostedMerchantOnboardingScreen/StartHostedMerchantOnboardingScreen'
import Skeleton from 'components/Shared/Skeleton/Skeleton'
import HostedMerchantOnboardingExpiredOrCompleted
from './HostedMerchantOnboardingExpiredOrCompleted/HostedMerchantOnboardingExpiredOrCompleted'

const HostedMerchantOnboarding = ({
  isFetching = false,
  boardingStyles,
  boardingLogoImage,
  token,
  onboardingFormId,
  isTokenValid = false,
  expiredSessionUrl = '',
  termsOfServiceUrl = '',
  returnUrl = '',
  applicationName = '',
  isFormCompleted = false,
  maxTransactionAmount,
  achMaxTransactionAmount,
  feeProfileUrl = '',
  underwritingProfile = {},
  hideWelcomePage,
  onboardingFormCountry = '',
  applicationId = '',
}) => {
  return (
    <div className='HostedMerchantOnboarding'>
      <div className='Header flex center' style={boardingStyles}>
        <div className='logo-container flex items-center space-between'>
          { isFetching && <Skeleton height='48px' width='96px' /> }
          { !isFetching && boardingLogoImage && <img className='logo' src={boardingLogoImage} alt='merchantOnboardingLogo' /> }
        </div>
      </div>

      { !isFetching && !isTokenValid && (
      <HostedMerchantOnboardingExpiredOrCompleted
        isExpired
        expiredSessionUrl={expiredSessionUrl}
      />
      )}
      { !isFetching && isFormCompleted && (
      <HostedMerchantOnboardingExpiredOrCompleted
        isCompleted
        returnUrl={returnUrl}
      />
      )}
      { !isFetching && isTokenValid && !isFormCompleted && (hideWelcomePage ? (
        <HostedMerchantOnboardingFlowC
          token={token}
          onboardingFormId={onboardingFormId}
          termsOfServiceUrl={termsOfServiceUrl}
          applicationName={applicationName}
          returnUrl={returnUrl}
          maxTransactionAmount={maxTransactionAmount}
          achMaxTransactionAmount={achMaxTransactionAmount}
          feeProfileUrl={feeProfileUrl}
          underwritingProfile={underwritingProfile}
          onboardingFormCountry={onboardingFormCountry}
          applicationId={applicationId}
        />
      ) : (
        <StartHostedMerchantOnboardingScreen
          onboardingFormId={onboardingFormId}
          onboardingFormCountry={onboardingFormCountry}
        />
      )
      )}
    </div>
  )
}

HostedMerchantOnboarding.propTypes = {
  isFetching: PropTypes.bool,
  boardingStyles: PropTypes.object,
  boardingLogoImage: PropTypes.string,
  token: PropTypes.string,
  onboardingFormId: PropTypes.string,
  isTokenValid: PropTypes.bool,
  expiredSessionUrl: PropTypes.string,
  termsOfServiceUrl: PropTypes.string,
  returnUrl: PropTypes.string,
  applicationName: PropTypes.string,
  isFormCompleted: PropTypes.bool,
  maxTransactionAmount: PropTypes.string,
  achMaxTransactionAmount: PropTypes.string,
  feeProfileUrl: PropTypes.string,
  underwritingProfile: PropTypes.object,
  hideWelcomePage: PropTypes.bool,
  onboardingFormCountry: PropTypes.string,
  applicationId: PropTypes.string,
}

export default HostedMerchantOnboarding
