import './IdentityPaymentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getIdentityTransfers } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Payments/columnDescriptors'
import { IDENTITY_TRANSFERS } from 'constants/linkConstants'
import { GET_IDENTITY_TRANSFERS, TRANSFER } from 'constants/flowConstants'
import { IDENTITY_TRANSFERS_RESOURCE_TITLE } from 'constants/language/languageConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

const IdentityPayments = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  paymentPath = '',
  isFetching = false,
  identityId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='IdentityPayments'>
      <TableC
        flow={GET_IDENTITY_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getIdentityTransfers}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={`Identity_${identityId}_Transfers`}
        fileResourceTitleCSV={IDENTITY_TRANSFERS_RESOURCE_TITLE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={TRANSFER}
      />
    </div>
  )
}

IdentityPayments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default IdentityPayments
