import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_MERCHANTS,
} from 'constants/apiConstants'

const postIdentityMerchantAPI = ({ values, credentials, meta }) => {
  const {
    identity,
  } = values

  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: IDENTITY_MERCHANTS({ identityId: identity }),
    service: IDENTITIES,
  })
}

export default postIdentityMerchantAPI
