import React, { Component } from 'react'
import { connect } from 'react-redux'
import getDashboardUser from 'utilities/get/getDashboardUser'
import ReviewQueueMerchants from './ReviewQueueMerchants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchReviewQueueItemsRequest from 'utilities/actions/patch/patchReviewQueueItemsRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { FETCHING } from 'constants/reducerConstants'
import { REVIEW_QUEUE_MERCHANTS } from 'constants/selectedItemsConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { MERCHANT, PENDING, ACCEPTED, REJECTED, REJECT_REASONS } from 'constants/reviewQueueConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import map from 'lodash/map'
import omit from 'lodash/omit'

import {
  REVIEW_QUEUE_MERCHANTS_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

import {
  getReviewQueueSelector,
  getSelectedItemsByKeySelector,
} from 'state-layer/selectors'

import {
  SORT,
  LIMIT,
  OFFSET,
} from 'constants/queryConstants'

const entityType = MERCHANT
const selectedItemsKey = REVIEW_QUEUE_MERCHANTS

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  entity_type: MERCHANT,
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `reviewQueueR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const merchantPath = ({ merchantIdentity, entityId }) => MERCHANT_PATH({ credentialId, identityId: get(merchantIdentity, 'id'), merchantId: entityId })
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const reviewQueueMerchantsPath = REVIEW_QUEUE_MERCHANTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const outcome = get(queries, 'outcome')
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)
  const mergedQueries = merge({}, initialQueries, queries)
  const reviewQueueMerchants = getReviewQueueSelector(state)
  const selectedReviewQueueMerchants = getSelectedItemsByKeySelector(state, selectedItemsKey)

  return {
    initialQueries: mergedQueries,
    isFetching,
    merchantPath,
    reviewQueueMerchantsPath,
    outcome,
    limit,
    offset,
    sort,
    credentials,
    selectedItemsKey,
    reviewQueueMerchants,
    selectedReviewQueueMerchants,
    currentUserEmail,
  }
}

class ReviewQueueMerchantsC extends Component {
  updateSelectedMerchantsStatus = ({ status, assignee, reason }) => {
    const {
      limit,
      offset,
      sort,
      outcome,
      dispatch,
      credentials,
      reviewQueueMerchants,
      selectedReviewQueueMerchants,
    } = this.props

    const selectedIds = Object.keys(selectedReviewQueueMerchants)
    const selectedReviewQueueItems = pick(reviewQueueMerchants, selectedIds)

    const items = map(selectedReviewQueueItems, item => {
      const tags = get(items, 'tags', {})
      const newTags = removeUndefined(merge({}, tags, { assigned_to: assignee, decision_reason: reason }))
      return merge({}, omit(item, 'outcome'), { outcome: status, tags: newTags })
    })

    const queries = {
      limit,
      offset,
      sort,
      outcome,
      entity_type: entityType,
    }

    dispatch(
      patchReviewQueueItemsRequest({
        items,
        queries,
        selectedItemsKey,
        credentials,
        dispatch,
      }),
    )
  }

  updateSelectedMerchantsAssignment = () => {
    const { currentUserEmail } = this.props
    this.updateSelectedMerchantsStatus({ assignee: currentUserEmail })
  }

  createPendingActionsMap = () => {
    const { updateSelectedMerchantsAssignment, updateSelectedMerchantsStatus } = this
    const pendingActionsMap = []

    pendingActionsMap.push({
      label: 'Assign to me',
      action: updateSelectedMerchantsAssignment,
    })

    REJECT_REASONS.forEach(reason => {
      pendingActionsMap.push({
        label: `Reject (${reason})`,
        action: () => updateSelectedMerchantsStatus({ status: REJECTED, reason }),
      })
    })

    pendingActionsMap.push({
      label: 'Approve',
      action: () => updateSelectedMerchantsStatus({ status: ACCEPTED }),
    })

    return pendingActionsMap
  }

  outcomeActionsMap = () => ({
    [PENDING]: this.createPendingActionsMap(),
  })

  render() {
    const { outcome } = this.props
    const outcomeActionsMap = get(this, 'outcomeActionsMap', () => {})
    const actionsMap = outcomeActionsMap()
    const selectedItemsAction = get(actionsMap, outcome, [])

    const selectedItemsData = {
      actions: selectedItemsAction,
    }

    return (
      <ReviewQueueMerchants
        {...this.props}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps)(ReviewQueueMerchantsC)
