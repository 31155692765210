import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIdentitySelector } from 'state-layer/selectors'
import AddEditBuyerInformationForm from './AddEditBuyerInformationForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import formatIdentityInitialValues from 'utilities/formatters/formatIdentityInitialValues'
import { getIdentityActions } from 'constants/identityConstants'
import get from 'lodash/get'

import {
  IDENTITY,
  PATCH_IDENTITY,
} from 'constants/flowConstants'

import {
  ADD_BUYER_SUCCESS_MSG,
  EDIT_BUYER_SUCCESS_MSG,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const initialValues = formatIdentityInitialValues(identity)

  return {
    credentials,
    actionType: identityId ? getIdentityActions(PATCH_IDENTITY) : getIdentityActions(IDENTITY),
    customSuccessMsg: identityId ? EDIT_BUYER_SUCCESS_MSG : ADD_BUYER_SUCCESS_MSG,
    initialValues,
    identityId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class AddEditBuyerInformationFormC extends Component {
  render() {
    const { closeModal } = this.props

    const customSuccessFunc = () => closeModal()

    return (
      <AddEditBuyerInformationForm
        {...this.props}
        customSuccessFunc={customSuccessFunc}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBuyerInformationFormC)
