import './CertificatePendingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { FINIX_SUPPORT_HELP_URL } from 'constants/urlConstants'
import { OK } from 'constants/language/languageConstants'

const CertificatePendingForm = ({
  closeModal = () => {},
  showResumeModal = () => {},
}) => {
  return (
    <form className='CertificatePendingForm'>
      <div className='message'>
        Please <a className='text-link' href={FINIX_SUPPORT_HELP_URL} target='blank'> contact support</a> if you would like to revoke the pending certificate in order to create a new one, or
        <span className='resume-btn' onClick={showResumeModal}> resume</span> setting up Apple Pay for this app.
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={OK} />
      </div>
    </form>
  )
}

CertificatePendingForm.propTypes = {
  closeModal: PropTypes.func,
  showResumeModal: PropTypes.func,
}

export default CertificatePendingForm
