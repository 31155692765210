import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import get from 'lodash/get'

const frontendUnderwritingWorkflowModel = ({ data: underwritingWorkflow }) => {
  const [
    id,
    name,
    workflowProfile,
    schema,
    linkedTo,
    actOnDecision,
    createdAt,
  ] = getMany(underwritingWorkflow, [
    'id',
    'name',
    'profile',
    'schema',
    'linked_to',
    'definition.act_on_decision',
    'created_at',
  ])

  return removeUndefined({
    id,
    name,
    workflowProfile,
    schema,
    linkedTo,
    actOnDecision,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
  })
}

export default frontendUnderwritingWorkflowModel
