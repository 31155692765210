import createAction from 'utilities/actions/createAction'
import { GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE_F_REQUEST } from 'constants/flowConstants'

const getActiveComplianceFormTemplateRequest = ({
  queries,
  credentials,
  meta,
}) => createAction({
  type: GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
  queries,
  credentials,
  meta,
})

export default getActiveComplianceFormTemplateRequest
