import './PayoutS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getTransferReversalsMerchantsApplicationSelector } from 'state-layer/selectors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import { refundsColumnDescriptors } from 'components/Customer/Pages/Payment/columnDescriptors'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TableC from 'components/Shared/Table/TableC'
import { resourceTableThemeV2 } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  GET_TRANSFER_REVERSALS,
  PATCH_TRANSFER_F_REQUEST,
} from 'constants/flowConstants'

import {
  DETAILS,
  CARD_PRESENT_INFORMATION,
  RESPONSE_MESSAGES,
  RAW_RESPONSE,
  REFUNDS,
  REFUNDS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const Payout = ({
  detailsSectionData = [],
  paymentInstrument = {},
  tagsSectionData = [],
  transfer = {},
  transferId = '',
  isFetching = false,
  messages,
  raw,
  actions = [],
  refundActions = [],
  refundsPath = '',
  showError = false,
  isPayout = false,
  headerData = {},
  contextBarData = {},
  isCardPresent = false,
  deviceSectionData = [],
  device = {},
  showReversals = false,
}) => {
  return (
    <div className='Payout'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={convertPageSectionDataToV2(detailsSectionData)}
        isFetching={isFetching}
      />

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      {isCardPresent && (
      <PageSectionV2C
        title={CARD_PRESENT_INFORMATION}
        columns={convertPageSectionDataToV2(deviceSectionData)}
        isFetching={isFetching}
      />
      )}

      { showError && (
      <PageSectionV2C title={RESPONSE_MESSAGES}>
        <PrettyJSON data={messages} />
      </PageSectionV2C>
      )}

      { (isPayout || showError) && (
      <PageSectionV2C title={RAW_RESPONSE}>
        <PrettyJSON data={raw} />
      </PageSectionV2C>
      )}

      <TagsPageSectionV2
        actionType={PATCH_TRANSFER_F_REQUEST}
        data={tagsSectionData}
        record={transfer}
        isFetching={isFetching}
      />

      { showReversals && (
        <TableC
          title={REFUNDS}
          actions={refundActions}
          flow={GET_TRANSFER_REVERSALS}
          flowValues={{ transferId }}
          columnDescriptors={refundsColumnDescriptors}
          path={refundsPath}
          theme={resourceTableThemeV2}
          selector={getTransferReversalsMerchantsApplicationSelector}
          noEmptyMessageIcon
          showTableFooter={false}
          emptyMessage={REFUNDS_EMPTY_TABLE_MESSAGE}
        />
      )}
    </div>
  )
}

Payout.propTypes = {
  detailsSectionData: PropTypes.array,
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.array,
  reversals: PropTypes.object,
  transfer: PropTypes.object,
  isFetching: PropTypes.bool,
  transferId: PropTypes.string,
  messages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  raw: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  actions: PropTypes.array,
  refundActions: PropTypes.array,
  paymentPath: PropTypes.string,
  showError: PropTypes.bool,
  isPayout: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  isCardPresent: PropTypes.bool,
  deviceSectionData: PropTypes.array,
  device: PropTypes.object,
  showReversals: PropTypes.bool,
}

export default Payout
