import React from 'react'
import AmountsDisplaySection from 'components/Customer/Shared/Page/PageSection/AmountsDisplaySection'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import get from 'lodash/get'

import {
  SCHEDULE,
  ENROLLMENT_NICKNAME,
  SCHEDULE_NICKNAME,
  SCHEDULE_TYPE,
  SCHEDULE_AMOUNTS,
  STARTED_AT_LABEL,
  ENDED_AT_LABEL,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ENROLLMENT_NICKNAME, 'enrollmentNickname', { className: 'medium' }),
  column(SCHEDULE_NICKNAME, 'schedule.scheduleNickname', { className: 'medium' }),
  column(SCHEDULE, 'schedule.subscriptionType', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={SCHEDULE} subtitle={SCHEDULE_TYPE} />,
    formatter: ({ schedule }) => {
      const periodOffset = get(schedule, 'periodOffset')
      const fixedTimeIntervalOffset = get(schedule, 'fixedTimeIntervalOffset')
      const subscriptionType = get(schedule, 'subscriptionType')
      const scheduleContent = convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset })
      const scheduleType = subscriptionScheduleTypeMapping[subscriptionType]

      return <TableItemSubtitle title={scheduleContent} subtitle={scheduleType} />
    },
  }),
  column(SCHEDULE_AMOUNTS, 'schedule.amounts', {
    className: 'medium divider',
    formatter: ({ schedule }) => AmountsDisplaySection({ data: get(schedule, 'amounts') }),
  }),
  column(STARTED_AT_LABEL, 'displayStartedAt', { className: 'date', sort: createdAtRange }),
  column(ENDED_AT_LABEL, 'displayEndedAt', { className: 'date', sort: createdAtRange }),
]

export default columnDescriptors
