import DashboardFeedbackEmail from './DashboardFeedbackEmail'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import { GET_EMAIL_TEMPLATE_F_REQUEST } from 'constants/flowConstants'
import { DASHBOARD_FEEDBACK_TEMPLATE } from 'constants/emailTemplateConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `emailTemplatesR.${FETCHING}`)
  const dashboardFeedbackEmail = get(state, `emailTemplatesR.items.${DASHBOARD_FEEDBACK_TEMPLATE}`)

  return {
    isFetching,
    dashboardFeedbackEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardFeedbackEmail: (templateName) => dispatch({
      type: GET_EMAIL_TEMPLATE_F_REQUEST,
      payload: { values: { templateName } },
    }),
  }
}

class DashboardFeedbackEmailC extends Component {
  componentDidMount() {
    const { getDashboardFeedbackEmail } = this.props

    getDashboardFeedbackEmail(DASHBOARD_FEEDBACK_TEMPLATE)
  }

  render() {
    return (
      <DashboardFeedbackEmail {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFeedbackEmailC)
