import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  DISABLE_API_KEY,
  DISABLE_API_KEY_MESSAGE,
} from 'constants/language/languageConstants'

const DisableAPIKey = ({
  closeModal = () => {},
  disableAPIKey = () => {},
}) => {
  return (
    <div className='DisableAPIKey'>
      <div className='message'>
        <div>{DISABLE_API_KEY_MESSAGE}</div>
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' className='cancel-button' onClick={closeModal} label={CANCEL} />
        <Button onClick={disableAPIKey} label={DISABLE_API_KEY} />
      </div>
    </div>
  )
}

DisableAPIKey.propTypes = {
  closeModal: PropTypes.func,
  disableAPIKey: PropTypes.func,
}

export default DisableAPIKey
