import { PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const patchAccessFormAdditionalDocumentsRequest = ({
  credentials,
  fileType,
  fileName,
  file,
  id,
  existingAdditionalDocuments,
  isHostedAccessForm,
}) => createAction({
  type: PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST,
  credentials,
  id,
  values: {
    fileType,
    fileName,
    file,
    existingAdditionalDocuments,
    isHostedAccessForm,
  },
})

export default patchAccessFormAdditionalDocumentsRequest
