import capitalize from 'lodash/capitalize'
import isString from 'lodash/isString'

const convertSnakeToSentenceCase = (string, exceptionMapping = {
  CVV: 'CVV',
  TRANSFER: 'Transaction',
  II: 'II',
  FBO: 'FBO',
  API: 'API',
  PCI: 'PCI',
  AND: 'and',
  QA: 'QA',
}) => {
  if (!string || string === '') return undefined

  const formattedString = isString(string) ? string : ''

  return formattedString.split('_').map((word) => {
    const exceptionWord = exceptionMapping[word]

    return exceptionWord ? exceptionWord : capitalize(word)
  }).join(' ')
}

export default convertSnakeToSentenceCase
