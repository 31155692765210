import fileDownload from 'js-file-download'
import getPaymentsFileO from 'state-layer/orchestration/get/getPaymentsFileO'
import get from 'lodash/get'

async function downloadPaymentsFile({
  url,
  fileName,
  credentials,
}) {
  const { data: response } = await getPaymentsFileO({
    url,
    credentials,
  })

  const fileData = get(response, 'data')

  fileDownload(fileData, fileName)
}

export default downloadPaymentsFile
