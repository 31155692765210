import removeUndefined from 'utilities/remove/removeUndefined'
import {
  BILL_AUTOMATICALLY,
  MERCHANT,
} from 'constants/subscriptionsConstants'

const backendSubscriptionPlanModel = ({ values }) => {
  const {
    state,
    amount,
    currency,
    planName,
    description,
    linkedTo,
    linkedType,
    billingInterval,
    collectionMethod,
    sendInvoice,
    sendReceipt,
    trialIntervalCount,
    trialIntervalType,
    enableTrialPhase,
  } = values

  return removeUndefined({
    state,
    amount,
    currency: 'USD', // TODO: only support USD for now
    plan_name: planName,
    description,
    linked_to: linkedTo,
    linked_type: MERCHANT, // TODO: remove this when we support other types of linked types
    billing_interval: billingInterval,
    billing_defaults: {
      collection_method: BILL_AUTOMATICALLY, // TODO: remove this when we support other types of collection methods
      send_invoice: sendInvoice,
      send_receipt: sendReceipt,
    },
    trial_defaults: enableTrialPhase ? { interval_type: trialIntervalType, interval_count: trialIntervalCount } : undefined,
  })
}

export default backendSubscriptionPlanModel
