import { GET_NOTIFICATIONS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getNotificationsRequest = ({ credentials, queries, meta }) => createAction({
  type: GET_NOTIFICATIONS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getNotificationsRequest
