import React, { Component } from 'react'
import { connect } from 'react-redux'
import EnableDisableWebhook from './EnableDisableWebhook'
import { getWebhookSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchWebhookRequest from 'utilities/actions/patch/patchWebhookRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'

import {
  ENABLE_WEBHOOK,
  DISABLE_WEBHOOK,
} from 'constants/amplitudeConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    webhookId,
    enabled,
    isAcceptingEvents,
  ] = getMany(props, [
    'webhookId',
    'enabled',
    'isAcceptingEvents',
  ])

  const webhook = getWebhookSelector(state, webhookId)
  const amplitudeEventName = enabled ? DISABLE_WEBHOOK : ENABLE_WEBHOOK

  return {
    credentials,
    webhookId,
    webhook,
    enabled,
    amplitudeEventName,
    isAcceptingEvents,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateWebhookAction: ({ credentials, webhookId, enabled, amplitudeEventName }) => dispatch(patchWebhookRequest({
      credentials,
      webhookId,
      values: {
        enabled,
      },
      amplitudeEventName,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EnableDisableWebhookC extends Component {
  toggleWebhookEnabled = () => {
    const {
      credentials,
      updateWebhookAction,
      webhookId,
      enabled,
      amplitudeEventName,
      closeModal,
    } = this.props

    updateWebhookAction({ credentials, webhookId, enabled: !enabled, amplitudeEventName })
    closeModal()
  }

  render() {
    return (
      <EnableDisableWebhook
        {...this.props}
        toggleWebhookEnabled={this.toggleWebhookEnabled}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnableDisableWebhookC)
