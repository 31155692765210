import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  SUCCEEDED,
  FAILED,
  PENDING,
} from 'constants/statusConstants'

const PurchaseColorData = {
  SUCCEEDED,
  FAILED,
  PENDING,
}

const PurchaseStatus = ({
  value = '',
}) => {
  return (
    <ColorcodedStatus data={PurchaseColorData} value={value} />
  )
}

PurchaseStatus.propTypes = {
  value: PropTypes.string,
}

export default PurchaseStatus
