import frontendCustomerOnboardingFormModel from 'utilities/create/models/frontendCustomerOnboardingFormModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendCustomerOnboardingFormsModel = ({ data: customerOnboardingForms }) => {
  const customerOnboardingFormsModels = map(customerOnboardingForms, (customerOnboardingForm) => frontendCustomerOnboardingFormModel({ data: customerOnboardingForm }))

  return keyBy(customerOnboardingFormsModels, 'id')
}

export default frontendCustomerOnboardingFormsModel
