import formatRiskProfileS from './formatRiskProfileS.scss'
import React from 'react'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import isEmpty from 'lodash/isEmpty'
import { CHECK_CIRCLE_ICON, DO_NOT_ENTER } from 'constants/iconConstants'

import {
  ALLOW_AVS_FAILURE,
  ALLOW_AVS_FAILURE_TOOLTIP,
  ALLOW_CVV_FAILURE,
  ALLOW_CVV_FAILURE_TOOLTIP,
  DISABLED,
  ENABLED,
} from 'constants/language/languageConstants'

const formatRiskProfile = (riskProfile) => {
  if (isEmpty(riskProfile)) { return [] }

  const [
    cscFailureAllowed,
    avsFailureAllowed,
  ] = getMany(riskProfile, [
    'csc_failure_allowed',
    'avs_failure_allowed',
  ])

  return convertPageSectionDataToV2(
    [
      {
        label: ALLOW_CVV_FAILURE,
        value: <div className='flex items-center'><div>{ cscFailureAllowed ? <div><i className={`enabled fa fa-${CHECK_CIRCLE_ICON}`} />{ENABLED}</div> : <div><i className={`disabled fa fa-${DO_NOT_ENTER}`} />{DISABLED}</div>}</div> <TooltipLabelC message={ALLOW_CVV_FAILURE_TOOLTIP} /></div>,
      },
      {
        label: ALLOW_AVS_FAILURE,
        value: <div className='flex items-center'><div>{avsFailureAllowed ? <div><i className={`enabled fa fa-${CHECK_CIRCLE_ICON}`} />{ENABLED}</div> : <div><i className={`disabled fa fa-${DO_NOT_ENTER}`} />{DISABLED}</div>}</div> <TooltipLabelC message={ALLOW_AVS_FAILURE_TOOLTIP} /></div>,
      },
    ],
  )

  // const riskProfileData = removeUndefined({
  //   Created: formatDate(createdAt),
  //   'CVV Failure Allowed': () => TruthyStatus({ value: cscFailureAllowed }),
  //   'AVS Failure Allowed': () => TruthyStatus({ value: avsFailureAllowed }),
  // })
  //
  // return map(riskProfileData, (value, label) => ({ label, value }))
}

export default formatRiskProfile
