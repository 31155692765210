import React, { Component } from 'react'
import { isInvalid } from 'redux-form'
import Flows from './Flows'
import VerticalFlows from './VerticalFlows'
import { connect } from 'react-redux'
import get from 'lodash/get'
import merge from 'lodash/merge'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import toNumber from 'lodash/toNumber'

import {
  UPDATE_FLOW_STATUS,
  RESET_FLOW_STATUS,
} from 'constants/actionConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    setFlowStatus: (payload) => dispatch({ type: UPDATE_FLOW_STATUS, payload }),
    dispatcher: (action) => dispatch(action),
    cancelProgress: () => dispatch(hideModalAction()),
    resetFlowStatus: (payload) => dispatch({ type: RESET_FLOW_STATUS }),
  }
}

const mapStateToProps = (state, props) => {
  const {
    steps,
    modalPlacement = false,
  } = props

  const filteredSteps = filter(steps, ({ hidden = false }) => { return !hidden })
  const credentials = getCurrentCredentials(state)
  const orchestrationStatus = get(state, 'orchestrationStatusR')
  const flow = get(state, 'flowsR')

  const {
    progress,
    activeStep,
    formValues,
    skipStatus,
  } = flow

  const form = get(steps, `${activeStep}.props.form`, '')
  const isInvalidForm = isInvalid(form)(state)

  return {
    activeStep,
    credentials,
    progress,
    formValues,
    skipStatus,
    orchestrationStatus,
    steps: filteredSteps,
    modalPlacement,
    isInvalidForm,
  }
}

class FlowsC extends Component {
  componentDidMount() {
    const urlQueries = parseUrlQueries()
    const activeStep = get(urlQueries, 'activeStep', null)
    const activeStepIdx = toNumber(activeStep)

    const {
      setFlowStatus,
    } = this.props

    if (activeStep) {
      setFlowStatus({ activeStep: activeStepIdx })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeStep: prevActiveStep } = prevProps

    const {
      steps,
      activeStep,
      orchestrationStatus,
      setFlowStatus,
    } = this.props

    if (!isEmpty(orchestrationStatus)) {
      // loop through orchestration flows for orchestration status, find if it has failed, if so go to page
      // by default if multiple errors will go to whichever step failed first

      // eslint-disable-next-line consistent-return
      forEach(steps, (step, errorStepIndex) => {
        const { orchestrationStatus: name } = step

        const isStepError = !get(orchestrationStatus, name, true)
        const onLastStep = activeStep === steps.length - 1

        if (isStepError && onLastStep) {
          setFlowStatus({ activeStep: errorStepIndex })
          return false
        }
      })
    }

    if (activeStep && prevActiveStep !== activeStep) {
      const flowContainerElement = get(document.getElementsByClassName('flow-container'), '0')
      if (flowContainerElement) flowContainerElement.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    const { resetFlowStatus } = this.props

    resetFlowStatus()
  }

  clickForNextStep = (formValue = {}) => {
    const {
      activeStep,
      progress,
      steps,
      setFlowStatus,
      dispatcher,
    } = this.props

    // format the form data
    const formDataKey = get(steps, `${activeStep}.dataKey`)

    const formValues = { [formDataKey]: formValue }

    const nextStep = progress > activeStep ? progress : activeStep + 1

    // If the current step requires an action, trigger that action before moving to the next step in the flow
    const currentStep = steps[activeStep]
    const currentStepProps = get(currentStep, 'props')
    const currentStepSubmitAction = get(currentStepProps, 'stepSubmitAction')

    if (currentStepSubmitAction) {
      currentStepSubmitAction(formValues, dispatcher, currentStepProps, () => {
        setFlowStatus({
          activeStep: nextStep,
          progress: activeStep === progress ? progress + 1 : progress,
          formValues,
        })
      })
    } else {
      setFlowStatus({
        activeStep: nextStep,
        progress: activeStep === progress ? progress + 1 : progress,
        formValues,
      })
    }
  }

  clickForPreviousStep = () => {
    const {
      activeStep,
      setFlowStatus,
    } = this.props

    setFlowStatus({ activeStep: activeStep - 1, progress: activeStep - 1 })
  }

  clickForStep = (idx, progress, skipProgressCheck) => {
    const {
      setFlowStatus,
      steps,
      activeStep,
    } = this.props

    if ((progress >= idx || idx !== activeStep) || skipProgressCheck) {
      const formValues = get(steps, `${activeStep}.formValues`)
      const values = formValues ? formValues() : undefined

      if (values) {
        const dataKey = get(steps, `${activeStep}.dataKey`)

        setFlowStatus({
          activeStep: idx,
          formValues: { [dataKey]: values },
        })
      } else {
        setFlowStatus({ activeStep: idx })
      }
    }
  }

  skipStep = (idx, stepStatus) => {
    const {
      activeStep,
      progress,
      setFlowStatus,
      skipStatus,
    } = this.props

    const newSkipStatus = merge({}, skipStatus, { [idx]: stepStatus })

    if (stepStatus) {
      setFlowStatus({
        skipStatus: newSkipStatus,
        activeStep: activeStep + 1,
        progress: activeStep === progress ? progress + 1 : progress,
      })
    } else {
      setFlowStatus({ skipStatus: newSkipStatus })
    }
  }

  // TODO: in the future, refactor Flows to take other components that do not have to be forms
  render() {
    const {
      clickForNextStep,
      clickForPreviousStep,
      clickForStep,
      skipStep,
    } = this

    const { layout } = this.props

    if (layout === 'vertical') {
      return (
        <VerticalFlows
          clickForNextStep={clickForNextStep}
          clickForPreviousStep={clickForPreviousStep}
          clickForStep={clickForStep}
          skipStep={skipStep}
          {...this.props}
        />
      )
    }


    return (
      <Flows
        clickForNextStep={clickForNextStep}
        clickForPreviousStep={clickForPreviousStep}
        clickForStep={clickForStep}
        skipStep={skipStep}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlowsC)
