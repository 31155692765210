import './UpdateBalanceAdjustmentsVelocityLimitsReviewFormS.scss'
import React from 'react'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import prevFlowStep from 'utilities/prevFlowStep'
import submitBalanceAdjustmentsVelocityLimitsForm from 'utilities/submit/submitBalanceAdjustmentsVelocityLimitsForm'
import { UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_REVIEW_FORM } from 'constants/formConstants'

import {
  CANCEL,
  SUBMIT,
  EDIT,
  WINDOW_START_TIME,
  TRANSACTION_DETAILS,
  DAILY_LIMITS,
  MONTHLY_LIMITS,
  APPLICATION_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS,
  REVIEW_AMPERSAND_SUBMIT,
} from 'constants/language/languageConstants'

const UpdateBalanceAdjustmentsVelocityLimitsReviewForm = ({
  subtitle,
  handleSubmit = () => {},
  transactionDetails = [],
  windowStartTimeDetails = [],
  dailyLimitsDetails = [],
  monthlyLimitsDetails = [],
}) => {
  return (
    <form className='UpdateBalanceAdjustmentsVelocityLimitsReviewForm' onSubmit={handleSubmit(submitBalanceAdjustmentsVelocityLimitsForm)}>
      <>
        <div className='form-header'>
          <h3>{REVIEW_AMPERSAND_SUBMIT}</h3>

          {subtitle && <div className='p-1 subtitle'>{subtitle}</div>}
        </div>

        <div className='form-content'>
          <PageSectionV2C
            smallTitle={APPLICATION_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS}
            actions={[{ label: EDIT, action: prevFlowStep }]}
          >
            <h6>{TRANSACTION_DETAILS}</h6>
            <PageSectionV2C
              columns={transactionDetails}
            />
            <h6>{WINDOW_START_TIME}</h6>
            <PageSectionV2C
              columns={windowStartTimeDetails}
            />
            <h6>{DAILY_LIMITS}</h6>
            <PageSectionV2C
              columns={dailyLimitsDetails}
            />
            <h6>{MONTHLY_LIMITS}</h6>
            <PageSectionV2C
              columns={monthlyLimitsDetails}
            />
          </PageSectionV2C>
        </div>

        <div className='form-footer'>
          <div className='footer-button-container'>
            <div className='exit-and-save-container'>
              <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' />
              <Button className='submit-button' type='submit' label={SUBMIT} />
            </div>
          </div>
        </div>
      </>
    </form>
  )
}

UpdateBalanceAdjustmentsVelocityLimitsReviewForm.propTypes = {
  subtitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  transactionDetails: PropTypes.array,
  windowStartTimeDetails: PropTypes.array,
  dailyLimitsDetails: PropTypes.array,
  monthlyLimitsDetails: PropTypes.array,
}

export default reduxForm({
  form: UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_REVIEW_FORM,
})(UpdateBalanceAdjustmentsVelocityLimitsReviewForm)
