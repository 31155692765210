import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getMany from 'utilities/get/getMany'

const frontendConfigurationModel = ({ data: configuration }) => {
  const dashboardServiceV2 = isDashboardServiceV2()

  const [
    id,
    createdAt,
    updatedAt,
    state,
    customCssUrl,
    sidebarLogoImageUrl,
    pages,
    name,
    processors,
    sidebar,
  ] = getMany(configuration, [
    'id',
    dashboardServiceV2 ? 'created_at' : 'attributes.created-at',
    dashboardServiceV2 ? 'updated_at' : 'attributes.updated-at',
    dashboardServiceV2 ? 'state' : 'attributes.state',
    dashboardServiceV2 ? 'custom_css_url' : 'attributes.custom-css-url',
    dashboardServiceV2 ? 'sidebar_logo_image_url' : 'attributes.sidebar-logo-image-url',
    dashboardServiceV2 ? 'pages' : 'attributes.pages',
    dashboardServiceV2 ? 'name' : 'attributes.name',
    dashboardServiceV2 ? 'processors' : 'attributes.processors',
    dashboardServiceV2 ? 'sidebar' : 'attributes.sidebar',
  ])

  return {
    id,
    createdAt,
    updatedAt,
    state,
    customCssUrl,
    sidebarLogoImageUrl,
    pages,
    name,
    processors,
    sidebar,
  }
}

export default frontendConfigurationModel
