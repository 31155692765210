import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantMerchantDeposits from './MerchantMerchantDeposits'
import get from 'lodash/get'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { MERCHANT_DEPOSITS_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import sortBy from 'lodash/sortBy'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  APPLICATION_ID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transaction'),
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const merchantId = get(props, 'merchantId')
  const credentialId = getCurrentCredentialsId(state)
  const merchantDepositsPath = MERCHANT_DEPOSITS_PATH({ credentialId })

  const initialQueries = {
    custom_transfer_type: 'settlement_transfers',
    merchant_id: merchantId,
  }

  const flowValues = {
    merchantId,
  }

  return {
    merchantId,
    flowValues,
    quickFilters,
    allowedFilters,
    initialQueries,
    isFetching,
    merchantDepositsPath,
    initialFilters: createdLastMonthQueries(),
  }
}

class MerchantMerchantDepositsC extends Component {
  render() {
    return (
      <MerchantMerchantDeposits {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(MerchantMerchantDepositsC)
