import Auth0Lock from 'auth0-lock'
import Cookies from 'react-cookies'
import { getHistory } from 'state-layer/history'
import getMany from 'utilities/get/getMany'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import getCurrentAuthSession from 'utilities/get/getCurrentAuthSession'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import { AUTH_SESSION } from 'constants/cookieConstants'

import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from 'utilities/amplitude'

import {
  LOGIN_PATH,
  DASHBOARD_PATH,
} from 'constants/pathConstants'

const cookieConfig = {
  secure: true,
  SameSite: 'None',
}

class Auth {
  accessToken

  idToken

  lock

  fetchingSession

  constructor (domain, clientID, options = {}) {
    this.domain = domain
    this.clientID = clientID
    this.options = options
    this.fetchingSession = false

    this.initAuth0Lock = this.initAuth0Lock.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
  }

  initAuth0Lock () {
    this.lock = new Auth0Lock(
      this.clientID,
      this.domain,
      this.options,
    )

    this.createAuthListeners()
  }

  getAccessToken() {
    return this.accessToken
  }

  getIdToken() {
    return this.idToken
  }

  checkSession() {
    const {
      lock,
      logout,
      fetchingSession,
    } = this

    if (lock && !fetchingSession) {
      this.fetchingSession = true

      lock.checkSession({}, (err, authResult) => {
        if (err) {
          logout()
        } else {
          lock.getUserInfo(authResult.accessToken, (error, profile) => {
            if (error) {
              logout()
            }

            this.setSession(authResult, profile)
            this.fetchingSession = false
          })
        }
      })
    }
  }

  setSession(authResult, profile, redirect = false) {
    const [
      idToken,
      accessToken,
    ] = getMany(authResult, [
      'idToken',
      'accessToken',
    ])

    const [
      email,
      userId,
      roles,
      sub,
    ] = getMany(profile, [
      'email',
      'user_id',
      'roles',
      'sub',
    ])

    setAmplitudeUserId(userId || sub)

    setAmplitudeUserProperties({
      email,
      roles,
      platform: getCurrentPlatformName(),
    })

    // this logic will make it so we fetch a new session every 30 minutes (Auth0 instructs no less than 15 minutes)
    // TODO: write logic that will check if the user was idle for 15 minutes to then force logout
    const minutesToExpire = 30
    const hardExpiration = minutesToExpire * 60 * 1000
    const expiresAt = hardExpiration + new Date().getTime()

    const authSession = merge({}, omit(authResult, 'accessToken'), {
      expiresAt,
      profile,
    })

    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem(AUTH_SESSION, JSON.stringify(authSession))

    this.accessToken = accessToken
    this.idToken = idToken

    if (redirect) {
      const history = getHistory()
      history.replace(DASHBOARD_PATH)
    }
  }

  logout() {
    this.accessToken = null
    this.idToken = null

    localStorage.clear()
    Cookies.remove(AUTH_SESSION)

    const history = getHistory()
    history.replace(LOGIN_PATH)
  }

  login() {
    this.initAuth0Lock()
    this.lock.show()
  }

  isAuthenticated() {
    const { expiresAt } = getCurrentAuthSession()

    const hasAuth = expiresAt && new Date().getTime() < expiresAt

    if (!hasAuth) {
      this.checkSession()
    }

    return hasAuth
  }

  createAuthListeners () {
    const self = this

    this.lock.on('authenticated', (authResult) => {
      self.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          return
        }

        self.lock.hide()
        self.setSession(authResult, profile, true)
      })
    })
  }

  updateAuth0Options (options) {
    this.options = options
  }
}

export default Auth
