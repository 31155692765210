import './NotificationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getNotificationsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_NOTIFICATIONS } from 'constants/flowConstants'
import { NOTIFICATIONS_ICON } from 'constants/iconConstants'
import { NOTIFICATIONS } from 'constants/linkConstants'
import {
  NOTIFICATIONS_TITLE,
  NOTIFICATIONS_SUB_TITLE,
} from 'constants/language/languageConstants'

const Notifications = ({
  initialQueries = {},
  isFetching = false,
  clickNotificationAction = () => {},
}) => {
  return (
    <div className='Notifications'>
      <HeaderV2C
        title={NOTIFICATIONS_TITLE}
        subTitle={NOTIFICATIONS_SUB_TITLE}
      />

      <div className='header-page-content'>
        <TableC
          flow={GET_NOTIFICATIONS}
          columnDescriptors={columnDescriptors}
          selector={getNotificationsSelector}
          initialQueries={initialQueries}
          isFetching={isFetching}
          icon={`fa fa-${NOTIFICATIONS_ICON}`}
          emptyMessage='No Notifications'
          clickAction={clickNotificationAction}
          linksKey={NOTIFICATIONS}
          showTogglePagination={false}
        />
      </div>
    </div>
  )
}

Notifications.propTypes = {
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  clickNotificationAction: PropTypes.func,
}

export default Notifications
