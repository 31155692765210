import getPlatformMembersAPI from 'api/dashboard/get/getPlatformMembersAPI'
import getApplicationMembersAPI from 'api/dashboard/get/getApplicationMembersAPI'
import getIdentityMembersAPI from 'api/dashboard/get/getIdentityMembersAPI'
import getRoleMembersAPI from 'api/dashboard/get/getRoleMembersAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import get from 'lodash/get'
import co from 'co'

const entityTypeToApiMap = {
  PLATFORM: getPlatformMembersAPI,
  APPLICATION: getApplicationMembersAPI,
  IDENTITY: getIdentityMembersAPI,
  ROLE: getRoleMembersAPI,
}

function * getMembersO ({
  values = {},
  queries,
  credentials,
}) {
  const {
    entityId,
    entityType,
  } = values

  if (!entityId || !entityType) {
    return createErrorResponse({ error: 'entityId or entityType not supplied' })
  }

  const api = get(entityTypeToApiMap, entityType)

  if (!api) {
    // TODO: What error do we want to return?
    return createSuccessResponse({
      data: [],
    })
  }

  const response = yield api({
    credentials,
    queries,
    id: entityId,
  })

  return response
}

export default co.wrap(getMembersO)
