import './PaymentLinkTemplatesFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Field, FieldArray, reduxForm } from 'redux-form'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import PaymentLinkCustomFields from 'components/Customer/Forms/CreatePaymentLinkForm/PaymentLinkCustomFields/PaymentLinkCustomFields'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import { PAYMENT_LINK_TEMPLATE_FORM } from 'constants/formConstants'
import { DATE_PICKER_DATE_FORMAT } from 'constants/timeConstants'
import map from 'lodash/map'

import {
  CUSTOM_SUCCESS_RETURN_URL,
  GENERAL_INFORMATION,
  PAYMENT_LINK_TEMPLATE,
  PAYMENT_LINK_TEMPLATE_SUBTITLE,
  PAYOUT_LINK_TEMPLATE,
  RESET_ALL,
  TERMS_OF_SERVICE_URL,
  SAVE,
  ADVANCED_SETTINGS,
  TWENTY_FOUR_HOURS,
  FORTY_EIGHT_HOURS,
  SEVENTY_TWO_HOURS,
  ONE_WEEK,
  SIX_MONTHS,
  THREE_YEARS,
  CUSTOM,
  CREDIT_AND_DEBIT_CARDS,
  BANK_TRANSFER,
  ALLOWED_PAYMENT_METHODS,
  SELECT_WHICH_PAYMENT_METHODS_STRING,
  BUYER_DETAILS,
  ONLY_COLLECT_EMAIL,
  ASK_FOR_MORE_INFORMATION,
  DEFAULT_AMOUNT_TYPE_SELECTION,
  FIXED_AMOUNT_FOR_PRODUCTS_OR_SERVICES,
  VARIABLE_AMOUNT_BUYER_CHOOSES,
  DEFAULT_LINK_VALIDITY,
  NAME,
  PHONE_NUMBER,
  SHIPPING_ADDRESS,
  BILLING_ADDRESS,
  CUSTOM_FIELDS,
  CUSTOM_FAILURE_RETURN_URL,
  ALLOWED_PAYOUT_METHODS,
  CUSTOM_EXPIRED_SESSION_URL,
  TERMS_OF_SERVICE_URL_HELPER_TEXT_2,
  EXPIRE_ON,
  PAYMENT_LINK_EXPIRATION_HELPER_TEXT,
  SEND_RECEIPT_TO_BUYER_ON_SUCCESS,
  SEND_A_COPY_OF_RECEIPT_TO_SELF,
} from 'constants/language/languageConstants'

const PaymentLinkTemplatesForm = ({
  handleSubmit = () => {},
  resetForm = () => {},
  isPayoutLink = false,
  pristine,
  isFetchingConfiguration = false,
  settingsSections = [],
}) => {
  return (
    <form className='PaymentLinkTemplatesForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <div className='form-content'>
        <h3>{isPayoutLink ? PAYOUT_LINK_TEMPLATE : PAYMENT_LINK_TEMPLATE}</h3>
        <div className='p-1 secondary'>{PAYMENT_LINK_TEMPLATE_SUBTITLE}</div>

        <div className='general-information-section'>
          <h6>{GENERAL_INFORMATION}</h6>
          <Field
            name='paymentLinkTemplate.termsOfServiceUrl'
            label={TERMS_OF_SERVICE_URL}
            helperText={TERMS_OF_SERVICE_URL_HELPER_TEXT_2}
            placeholder='https://www.example.com/terms-of-service'
            component={InputField}
            required={false}
          />

          <Field
            name='paymentLinkTemplate.successReturnUrl'
            label={CUSTOM_SUCCESS_RETURN_URL}
            placeholder='https://www.cannon.com/success/123rw21w.html'
            component={InputField}
            required={false}
          />

          <Field
            name='paymentLinkTemplate.unsuccessfulReturnUrl'
            label={CUSTOM_FAILURE_RETURN_URL}
            placeholder='https://www.cannon.com/failure/123rw21w.html'
            component={InputField}
            required={false}
          />

          <Field
            name='paymentLinkTemplate.expiredSessionUrl'
            label={CUSTOM_EXPIRED_SESSION_URL}
            placeholder='https://www.cannon.com/expired/123rw21w.html'
            component={InputField}
            required={false}
          />
        </div>

        <div className='receipt-options mt-xl'>
          <Field
            component={CheckboxC}
            name='paymentLinkTemplate.sendReceipt'
            label={SEND_RECEIPT_TO_BUYER_ON_SUCCESS}
            checked
          />

          <Field
            component={CheckboxC}
            name='paymentLinkTemplate.sendReceiptToSelf'
            label={SEND_A_COPY_OF_RECEIPT_TO_SELF}
          />
        </div>

        { map(settingsSections, (sectionData = {}, index) => {
          const {
            title,
            fieldKey,
            collectMoreBuyerDetails,
            collectCustomFields,
            showExpirationDatePicker,
          } = sectionData

          const fieldName = (field) => `paymentLinkTemplate.${fieldKey}.${field}`

          return (
            <Accordion key={index} title={title} className='borderless'>
              <div className='extra-settings-section'>
                {!isPayoutLink && (
                <>
                  <h6>{GENERAL_INFORMATION}</h6>

                  <Field
                    label={DEFAULT_AMOUNT_TYPE_SELECTION}
                    name={fieldName('amountType')}
                    required={false}
                    component={RadioButton}
                    options={[
                      { label: FIXED_AMOUNT_FOR_PRODUCTS_OR_SERVICES, value: 'FIXED' },
                      { label: VARIABLE_AMOUNT_BUYER_CHOOSES, value: 'VARIABLE' },
                    ]}
                  />
                </>
                )}

                <h6>{isPayoutLink ? ALLOWED_PAYOUT_METHODS : ALLOWED_PAYMENT_METHODS}</h6>
                <div className='label-2 checkbox-helper-text'>{SELECT_WHICH_PAYMENT_METHODS_STRING}</div>

                <Field
                  component={CheckboxC}
                  name={fieldName('allowPaymentCard')}
                  label={CREDIT_AND_DEBIT_CARDS}
                />

                <Field
                  component={CheckboxC}
                  name={fieldName('allowBankTransfer')}
                  label={BANK_TRANSFER}
                />

                <h6>{ADVANCED_SETTINGS}</h6>

                <Field
                  label={DEFAULT_LINK_VALIDITY}
                  name={fieldName('expiresIn')}
                  required={false}
                  component={RadioButton}
                  options={[
                    { label: TWENTY_FOUR_HOURS, value: '24' }, // 1440 minutes (should we just set this to minutes right here?
                    { label: FORTY_EIGHT_HOURS, value: '48' }, // 2880 minutes
                    { label: SEVENTY_TWO_HOURS, value: '72' }, // 4320 minutes
                    { label: ONE_WEEK, value: '168' }, // 10080 minutes
                    { label: SIX_MONTHS, value: '4380' }, // 262800 minutes
                    { label: THREE_YEARS, value: '26280' }, // 1576800 minutes
                    { label: CUSTOM, value: 'custom' }, // set by calendar picker
                  ]}
                />

                { showExpirationDatePicker && (
                <Field
                  name={fieldName('expirationDate')}
                  label={EXPIRE_ON}
                  component={DatePickerInput}
                  minDate={moment().add(1, 'days').toDate()}
                  maxDate={moment().add(3, 'years').toDate()}
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  placeholderText='MM/DD/YYYY'
                  helperText={PAYMENT_LINK_EXPIRATION_HELPER_TEXT}
                  autofill={false}
                />
                )}

                <Field
                  label={BUYER_DETAILS}
                  name={fieldName('collectBuyerDetails')}
                  required={false}
                  component={RadioButton}
                  options={[
                    { label: ONLY_COLLECT_EMAIL, value: 'email' },
                    { label: ASK_FOR_MORE_INFORMATION, value: 'more' },
                  ]}
                />

                { collectMoreBuyerDetails && (
                <div className='more-buyer-details'>
                  <Field
                    component={CheckboxC}
                    name={fieldName('collectName')}
                    label={NAME}
                    type='checkbox'
                  />

                  <Field
                    component={CheckboxC}
                    name={fieldName('collectPhoneNumber')}
                    label={PHONE_NUMBER}
                  />

                  <Field
                    component={CheckboxC}
                    name={fieldName('collectShippingAddress')}
                    label={SHIPPING_ADDRESS}
                  />

                  <Field
                    component={CheckboxC}
                    name={fieldName('collectBillingAddress')}
                    label={BILLING_ADDRESS}
                  />

                  <Field
                    component={CheckboxC}
                    name={fieldName('collectCustomFields')}
                    label={CUSTOM_FIELDS}
                  />

                  { collectCustomFields && (
                  <div className='custom-fields'>
                    <FieldArray
                      name={fieldName('customFields')}
                      component={PaymentLinkCustomFields}
                    />
                  </div>
                  )}
                </div>
                )}
              </div>
            </Accordion>
          )
        })}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button onClick={resetForm} label={RESET_ALL} variant='secondary' className='reset-all-button' />
          <Button type='submit' label={SAVE} disabled={pristine} submitting={isFetchingConfiguration} />
        </div>
      </div>
    </form>
  )
}

PaymentLinkTemplatesForm.propTypes = {
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  isPayoutLink: PropTypes.bool,
  pristine: PropTypes.bool,
  isFetchingConfiguration: PropTypes.bool,
  settingsSections: PropTypes.array,
}

export default reduxForm({
  form: PAYMENT_LINK_TEMPLATE_FORM,
})(PaymentLinkTemplatesForm)
