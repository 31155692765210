import './EditTagsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import TagsFormC from 'components/Customer/Forms/TagsForm/TagsFormC'

const EditTagsModal = ({
  actionType,
  credentials,
  record,
}) => {
  return (
    <GenericModal
      title='Edit Tags'
      className='EditTagsModal'
      Component={TagsFormC}
      actionType={actionType}
      credentials={credentials}
      record={record}
    />
  )
}

EditTagsModal.propTypes = {
  actionType: PropTypes.string,
  credentials: PropTypes.object,
  record: PropTypes.object,
}

export default EditTagsModal
