import React, { Component } from 'react'
import Loader from 'components/Shared/Loader/Loader'
import TabsC from 'components/Shared/Tabs/TabsC'
import VelocityLimitsC from 'components/Customer/Pages/Velocity/VelocityLimits/VelocityLimitsC'
import ApplicationLimitsC from 'components/Customer/Pages/Application/ApplicationLimits/ApplicationLimitsC'
import RecipientLimitsC from 'components/Customer/Pages/Application/RecipientLimits/RecipientLimitsC'
import BalanceAdjustmentsVelocityLimitsC from 'components/Customer/Pages/Application/BalanceAdjustmentsVelocityLimits/BalanceAdjustmentsVelocityLimitsC'

import {
  VELOCITY_LIMITS,
  BALANCE_ADJUSTMENTS,
  APPLICATION_PAYOUT_LIMITS,
  RECIPIENT_PAYOUT_LIMITS,
} from 'constants/language/languageConstants'

class ApplicationVelocityInfoTabs extends Component {
  render() {
    const {
      applicationId,
      isFetchingDisbursementRules,
      noDisbursementRulesSet,
    } = this.props

    const tabs = [
      {
        id: 'velocity-limits',
        name: VELOCITY_LIMITS,
        component: VelocityLimitsC,
        condition: noDisbursementRulesSet,
        props: {
          applicationId,
        },
      },
      {
        id: 'application-limits',
        name: APPLICATION_PAYOUT_LIMITS,
        component: ApplicationLimitsC,
        condition: !noDisbursementRulesSet,
        props: {
          applicationId,
        },
      },
      {
        id: 'recipient-limits',
        name: RECIPIENT_PAYOUT_LIMITS,
        condition: !noDisbursementRulesSet,
        component: RecipientLimitsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'balance-adjustments-limits',
        name: BALANCE_ADJUSTMENTS,
        component: BalanceAdjustmentsVelocityLimitsC,
        condition: !noDisbursementRulesSet,
        props: {
          applicationId,
        },
      },
    ]

    return (
      <div className='ApplicationVelocityInfoTabs'>
        {isFetchingDisbursementRules ? <Loader /> : (
          <TabsC
            tabs={tabs}
            isSecondaryTab
          />
        )}
      </div>
    )
  }
}

export default ApplicationVelocityInfoTabs
