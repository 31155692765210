import React, { Component } from 'react'
import SettlementCalculatedFundingDetails from './SettlementCalculatedFundingDetails'
import { getSettlementSelector } from 'state-layer/selectors/index'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const settlementId = get(props, 'settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const settlementCalculatedFundingDetails = get(settlement, 'calculatedFundingDetails')
  const isFetching = get(state, `settlementsR.${FETCHING}`)

  return {
    data: settlementCalculatedFundingDetails,
    isFetching,
  }
}

class SettlementCalculatedFundingDetailsC extends Component {
  render() {
    return (
      <SettlementCalculatedFundingDetails
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SettlementCalculatedFundingDetailsC)
