import getMany from 'utilities/get/getMany'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_BALANCE_ENTRY_F_REQUEST } from 'constants/flowConstants'
import { CREATE_BALANCE_ENTRY } from 'constants/amplitudeConstants'

const submitUpdateAccountBalanceForm = (values, dispatch, props) => {
  const {
    credentials,
    balanceId,
    balanceEntryCurrency,
    closeModal,
  } = props

  const [
    balanceEntryType,
    balanceEntryAmount,
    wireReferenceNumber,
    description,
  ] = getMany(values, [
    'balanceEntryType',
    'balanceEntryAmount',
    'wireReferenceNumber',
    'description',
  ])

  dispatch({
    type: POST_BALANCE_ENTRY_F_REQUEST,
    payload: {
      id: balanceId,
      values: {
        balanceEntryType,
        balanceEntryAmount,
        wireReferenceNumber,
        balanceEntryCurrency,
        description,
      },
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(CREATE_BALANCE_ENTRY, {
        credentials,
        balanceEntryType,
        balanceEntryAmount,
        wireReferenceNumber,
        balanceId,
        description,
      }),
      successCallback: () => {
        closeModal()
      },
    },
  })
}

export default submitUpdateAccountBalanceForm
