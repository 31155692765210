import './PaymentsInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatNumber from 'utilities/formatters/formatNumber'
import formatChartAmount from 'utilities/formatters/formatChartAmount'

import {
  AVERAGE_TRANSACTION_AMOUNT,
  AVG_TRANSACTION_AMOUNT,
  CARD_PAYMENT_DATA,
  CARD_PAYMENT_DATA_TOOLTIP,
  FAILED_SALES,
  FAILED_TRANSACTIONS_BY_FAILURE_CODE,
  FAILED_TRANSACTIONS_BY_FAILURE_CODE_TOOLTIP,
  PAYMENT_INSIGHTS_TOOLTIP_TITLE,
  PAYMENT_TRENDS,
  PAYMENT_TRENDS_TOOLTIP,
  PAYMENTS_PER_MERCHANT,
  PAYMENTS_PER_MERCHANT_TOOLTIP,
  SUCCESSFUL_SALES,
  SUMMARY,
  TIME_FRAME,
  TOTAL_TRANSACTION_VOLUME,
  PAYMENTS_PER_MERCHANT_OR_APPLICATION,
  PAYMENTS_PER_MERCHANT_OR_APPLICATION_TOOLTIP,
  TOTAL_TRANSACTION_VOLUME_TOOLTIP,
  SUCCESSFUL_SALES_TOOLTIP,
  FAILED_SALES_TOOLTIP,
  AVERAGE_TRANSACTION_AMOUNT_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  PAYMENTS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL,
  PAYMENTS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
  PAYMENTS_SUCCESSFUL_SALES_BIG_NUMBER_TOTAL,
  PAYMENTS_SUCCESSFUL_SALES_COUNT_BIG_NUMBER_TOTAL,
  PAYMENTS_FAILED_SALES_BIG_NUMBER_TOTAL,
  PAYMENTS_FAILED_SALES_COUNT_BIG_NUMBER_TOTAL,
  PAYMENTS_AVG_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL,
  PAYMENTS_AVG_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL,
  PAYMENTS_PAYMENT_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR,
  PAYMENTS_PAYMENT_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR,
  PAYMENTS_PAYMENT_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR,
  PAYMENTS_PAYMENT_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR,
  PAYMENTS_PAYMENTS_PER_MERCHANT_TABLE,
  PAYMENTS_CARD_PAYMENT_DATA_TABLE,
  PAYMENTS_FAILED_TRANSACTIONS_BY_FAILURE_CODE_TABLE,
} from 'constants/chartConstants'

const PaymentsInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRolePlatformCredential = false,
  showPaymentsPerMerchant = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='PaymentsInsights flex column'>
      <div className='time-frame-container flex space-between items-center'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{TIME_FRAME}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated p-2'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>{PAYMENT_INSIGHTS_TOOLTIP_TITLE}</p>
                <p><span className='bold'>{TOTAL_TRANSACTION_VOLUME}</span>{TOTAL_TRANSACTION_VOLUME_TOOLTIP}</p>
                <p><span className='bold'>{SUCCESSFUL_SALES}</span>{SUCCESSFUL_SALES_TOOLTIP}</p>
                <p><span className='bold'>{FAILED_SALES}</span>{FAILED_SALES_TOOLTIP}</p>
                <p><span className='bold'>{AVERAGE_TRANSACTION_AMOUNT}</span>{AVERAGE_TRANSACTION_AMOUNT_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={TOTAL_TRANSACTION_VOLUME}
            headerData={{
              name: PAYMENTS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: PAYMENTS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={SUCCESSFUL_SALES}
            headerData={{
              name: PAYMENTS_SUCCESSFUL_SALES_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: PAYMENTS_SUCCESSFUL_SALES_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FAILED_SALES}
            headerData={{
              name: PAYMENTS_FAILED_SALES_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: PAYMENTS_FAILED_SALES_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={AVG_TRANSACTION_AMOUNT}
            headerData={{
              name: PAYMENTS_AVG_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: PAYMENTS_AVG_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={PAYMENT_TRENDS}
          classNames='transaction-trends'
          chartClassNames='transaction-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: PAYMENTS_PAYMENT_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: PAYMENTS_PAYMENT_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: PAYMENTS_PAYMENT_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: PAYMENTS_PAYMENT_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR, height: 260 },
          ]}
          tooltipMessage={<p>{PAYMENT_TRENDS_TOOLTIP}</p>}
        />

        { showPaymentsPerMerchant && (
          <div className='mtl'>
            <ChartC
              title={isRolePlatformCredential ? PAYMENTS_PER_MERCHANT_OR_APPLICATION : PAYMENTS_PER_MERCHANT}
              name={PAYMENTS_PAYMENTS_PER_MERCHANT_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              hideTitle
              showGroupBy
              showPivots
              tooltipMessage={<p>{isRolePlatformCredential ? PAYMENTS_PER_MERCHANT_OR_APPLICATION_TOOLTIP : PAYMENTS_PER_MERCHANT_TOOLTIP}</p>}
            />
          </div>
        )}

        <div className='mtl'>
          <ChartC
            title={CARD_PAYMENT_DATA}
            name={PAYMENTS_CARD_PAYMENT_DATA_TABLE}
            height={280}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{CARD_PAYMENT_DATA_TOOLTIP}</p>}
          />
        </div>

        <div className='mtl'>
          <ChartC
            title={FAILED_TRANSACTIONS_BY_FAILURE_CODE}
            name={PAYMENTS_FAILED_TRANSACTIONS_BY_FAILURE_CODE_TABLE}
            height={484}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{FAILED_TRANSACTIONS_BY_FAILURE_CODE_TOOLTIP}</p>}
          />
        </div>
      </div>
    </div>
  )
}

PaymentsInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  isRolePlatformCredential: PropTypes.bool,
  showPaymentsPerMerchant: PropTypes.bool,
}

export default PaymentsInsights
