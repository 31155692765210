import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDisputeEvidencesSelector } from 'state-layer/selectors'
import DisputeCaseDetails from './DisputeCaseDetails'
import DisputeRespondWithinStatus from 'components/Customer/Shared/Display/ColorcodedStatus/DisputeRespondWithinStatus'
import getDisputeEvidencesRequest from 'utilities/actions/get/getDisputeEvidencesRequest'
import getDisputeRequest from 'utilities/actions/get/getDisputeRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import capitalize from 'lodash/capitalize'

import {
  NEEDS_RESPONSE,
  RESPONDED,
  NO_RESPONSE_ALLOWED,
  ACCEPTED,
  UNKNOWN_RESPONSE_STATE,
  WON,
  LOST,
} from 'constants/statusConstants'

import {
  EVIDENCE_SUBMITTED,
  LIABILITY_ACCEPTED,
  NEEDS_RESPONSE as NEEDS_RESPONSE_LANG,
  PENDING_STRING,
  WON as WON_STRING,
  LOST as LOST_STRING,
} from 'constants/language/languageConstants'

const needsResponseData = { title: NEEDS_RESPONSE_LANG, className: 'needs-response' }
const respondedData = { title: EVIDENCE_SUBMITTED, className: 'evidence-submitted' }
const acceptedData = { title: LIABILITY_ACCEPTED, className: 'liability-accepted' }

const mapDispatchToProps = (dispatch) => {
  return {
    getDisputeEvidenceFiles: ({ credentials, disputeId }) => dispatch(getDisputeEvidencesRequest({ credentials, disputeId })),
    getDispute: ({ disputeId, credentials }) => dispatch(getDisputeRequest({ disputeId, credentials })),
  }
}

const mapStateToProps = (state, props) => {
  const [
    dispute,
    isVantivDispute,
  ] = getMany(props, [
    'dispute',
    'isVantivDispute',
  ])

  const credentials = getCurrentCredentials(state)
  const isFetchingEvidences = get(state, `disputeEvidencesR.${FETCHING}`)

  const [
    disputeId,
    reason,
    message,
    respondWithin,
    disputeState,
    disputeResponseState,
    displayRespondWithin,
    disputeAmount,
    action,
  ] = getMany(dispute, [
    'id',
    'formattedReasonString',
    'message',
    'respondWithin',
    'state',
    'responseState',
    'displayRespondWithin',
    'displayAmount',
    'action',
  ])

  const disputeStateMap = {
    INQUIRY: {
      [NEEDS_RESPONSE]: needsResponseData,
      [RESPONDED]: respondedData,
      [ACCEPTED]: acceptedData,
    },
    PENDING: {
      title: PENDING_STRING,
      className: 'pending',
      [NEEDS_RESPONSE]: needsResponseData,
      [RESPONDED]: respondedData,
      [ACCEPTED]: acceptedData,
    },
    WON: {
      title: WON_STRING,
      className: 'won',
      [NO_RESPONSE_ALLOWED]: { title: WON_STRING, className: 'won' },
    },
    LOST: {
      title: LOST_STRING,
      className: 'lost',
      [NO_RESPONSE_ALLOWED]: { title: LOST_STRING, className: 'lost' },
      [ACCEPTED]: { title: `${LOST_STRING} - ${LIABILITY_ACCEPTED}`, className: 'lost' },
    },
  }

  const {
    title = get(disputeStateMap, `${disputeState}.title`, capitalize(disputeState)),
    className = get(disputeStateMap, `${disputeState}.className`, 'evidence-submitted'),
  } = get(disputeStateMap, [disputeState, disputeResponseState], {})

  const isNeedsResponseDispute = isEqual(disputeResponseState, NEEDS_RESPONSE) || isEqual(disputeResponseState, UNKNOWN_RESPONSE_STATE)
  const isSubmittedDispute = isEqual(disputeResponseState, RESPONDED) || includes([WON, LOST], disputeState)
  const respondWithinString = <DisputeRespondWithinStatus value={respondWithin} displayValue={`Respond within ${displayRespondWithin}`} />
  const disputeEvidenceFiles = getDisputeEvidencesSelector(state, disputeId)

  return {
    disputeId,
    disputeAmount,
    isNeedsResponseDispute,
    isSubmittedDispute,
    title,
    className,
    respondWithinString,
    reason,
    message,
    disputeEvidenceFiles,
    credentials,
    isFetchingEvidences,
    isVantivDispute,
    action,
  }
}

class DisputeCaseDetailsC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOverflowing: false,
      isShowingMore: false,
      actionMessageIsOverflowing: false,
      actionMessageIsShowingMore: false,
    }
  }

  componentDidMount() {
    const {
      credentials,
      disputeId,
      getDisputeEvidenceFiles,
      getDispute,
    } = this.props

    if (disputeId) {
      getDisputeEvidenceFiles({
        credentials,
        disputeId,
      })

      getDispute({
        disputeId,
        credentials,
      })

      this.measureMessage()
      window.addEventListener('resize', this.measureMessage)

      this.measureActionMessage()
      window.addEventListener('resize', this.measureActionMessage)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      disputeId: prevDisputeId,
      message: prevMessage,
    } = prevProps

    const {
      disputeId,
      getDisputeEvidenceFiles,
      credentials,
      message,
    } = this.props

    if (!isEqual(prevDisputeId, disputeId) && disputeId) {
      getDisputeEvidenceFiles({
        credentials,
        disputeId,
      })

      if (!isEqual(prevMessage, message) && message) {
        this.measureMessage()
        window.addEventListener('resize', this.measureMessage)

        this.measureActionMessage()
        window.addEventListener('resize', this.measureActionMessage)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measureMessage)
    window.removeEventListener('resize', this.measureActionMessage)
  }

  measureMessage = () => {
    const messageElement = document.querySelector('.dispute-reason-message-line-clamp')
    const containerWidth = messageElement ? messageElement.clientWidth : 0
    const scrollWidth = messageElement ? messageElement.scrollWidth : 0
    const isOverflowing = scrollWidth > containerWidth

    this.setState({ isOverflowing })
  }

  toggleShowMore = () => {
    const { isShowingMore } = this.state
    document.querySelector('.dispute-message').classList.toggle('dispute-reason-message-line-clamp')

    this.setState({ isShowingMore: !isShowingMore })
  }

  measureActionMessage = () => {
    const actionMessageElement = document.querySelector('.action-message')
    const containerWidth = actionMessageElement ? actionMessageElement.clientWidth : 0
    const scrollWidth = actionMessageElement ? actionMessageElement.scrollWidth : 0
    const actionMessageIsOverflowing = scrollWidth > containerWidth

    this.setState({ actionMessageIsOverflowing })
  }

  toggleShowMoreAction = () => {
    const { actionMessageIsShowingMore } = this.state
    document.querySelector('.action-message').classList.toggle('line-clamp')

    this.setState({ actionMessageIsShowingMore: !actionMessageIsShowingMore })
  }

  render() {
    const {
      isOverflowing,
      isShowingMore,
      actionMessageIsOverflowing,
      actionMessageIsShowingMore,
    } = this.state

    return (
      <DisputeCaseDetails
        {...this.props}
        isOverflowing={isOverflowing}
        isShowingMore={isShowingMore}
        toggleShowMore={this.toggleShowMore}
        actionMessageIsShowingMore={actionMessageIsShowingMore}
        actionMessageIsOverflowing={actionMessageIsOverflowing}
        toggleShowMoreAction={this.toggleShowMoreAction}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeCaseDetailsC)
