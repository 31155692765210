import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import get from 'lodash/get'

const getCurrentAuth0Configs = () => {
  const platform = getCurrentPlatform()

  return get(platform, 'auth0', {})
}

export default getCurrentAuth0Configs
