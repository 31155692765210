import './InviteUserModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import InviteUserFormC from 'components/Customer/Forms/InviteUserForm/InviteUserFormC'

const InviteUserModal = ({
  applicationId = '',
  identityId = '',
  hasIdentityMerchants = false,
}) => {
  return (
    <GenericModal
      title='Invite User'
      className='InviteUserModal'
      Component={InviteUserFormC}
      applicationId={applicationId}
      identityId={identityId}
      hasIdentityMerchants={hasIdentityMerchants}
    />
  )
}

InviteUserModal.propTypes = {
  applicationId: PropTypes.string,
  identityId: PropTypes.string,
  hasIdentityMerchants: PropTypes.bool,
}

export default InviteUserModal
