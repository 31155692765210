export const REVIEW_QUEUE_SETTLEMENTS = 'REVIEW_QUEUE_SETTLEMENTS'
export const REVIEW_QUEUE_MERCHANTS = 'REVIEW_QUEUE_MERCHANTS'
export const REVIEW_QUEUE_IDENTITIES = 'REVIEW_QUEUE_IDENTITIES'
export const SETTLEMENT_ENTRIES = 'SETTLEMENT_ENTRIES'
export const MERCHANT_SETTLEMENTS = 'MERCHANT_SETTLEMENTS'
export const SUBSCRIPTION_ENROLLMENTS = 'SUBSCRIPTION_ENROLLMENTS'
export const SUBSCRIPTION_SCHEDULE_ENROLLMENTS = 'SUBSCRIPTION_SCHEDULE_ENROLLMENTS'
export const APPLE_PAY_MERCHANT = 'APPLE_PAY_MERCHANT'
export const REPORTS = 'REPORTS'
export const COMPLIANCE_FORM_TEMPLATES = 'COMPLIANCE_FORM_TEMPLATES'
export const APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE = 'APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE'
