import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendIdentitiesModel from 'utilities/create/models/frontendIdentitiesModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendVerificationsModel from 'utilities/create/models/frontendVerificationsModel'
import frontendPaymentInstrumentsModel from 'utilities/create/models/frontendPaymentInstrumentsModel'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendSettlementModel from 'utilities/create/models/frontendSettlementModel'
import frontendUnderwritingDecisionModel from 'utilities/create/models/frontendUnderwritingDecisionModel'
import normalizeResponse from 'utilities/api/normalizeResponse'
import getReviewQueueItemAPI from 'api/finix/get/getReviewQueueItemAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import getDecisionsAPI from 'api/finix/get/getDecisionsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import head from 'lodash/head'
import merge from 'lodash/merge'
import find from 'lodash/find'
import get from 'lodash/get'
import co from 'co'

function * getReviewQueueItemO ({
  id,
  credentials,
  reviewQueueItemObject,
}) {
  const { data: response } = yield reviewQueueItemObject ? { data: reviewQueueItemObject } : getReviewQueueItemAPI({ id, credentials })
  const links = getResponseLinks(response)

  const { entity_type: entityType } = response

  const [
    applicationLink,
    identityLink,
    settlementLink,
  ] = getMany(links, [
    'application',
    'identity',
    'settlement',
  ])

  const [
    { data: applicationResponse },
    { data: identityResponse },
    { data: settlementResponse },
    { data: decisionsResponse },
  ] = yield [
    applicationLink ? getPaymentsAPI({ absolutePath: applicationLink, credentials }) : {},
    identityLink ? getPaymentsAPI({ absolutePath: identityLink, credentials }) : {},
    settlementLink ? getPaymentsAPI({ absolutePath: settlementLink, credentials }) : {},
    entityType === 'IDENTITY' ? getDecisionsAPI({ credentials, queries: { link_type: entityType, link_to: id } }) : {},
  ]

  const decisions = get(decisionsResponse, '_embedded.decisions', [])
  const decision = head(decisions)
  const decisionModel = frontendUnderwritingDecisionModel({ data: decision })
  const identityLinks = getResponseLinks(identityResponse)

  const [
    paymentInstrumentsLink,
    verificationsLink,
    associatedIdentitiesLink,
    merchantsLink,
  ] = getMany(identityLinks, [
    'payment_instruments',
    'verifications',
    'associated_identities',
    'merchants',
  ])

  const [
    { data: paymentInstrumentsResponse },
    { data: verificationsResponse },
    { data: associatedIdentitiesResponse },
    { data: merchantsResponse },
  ] = yield [
    getPaymentsAPI({ absolutePath: paymentInstrumentsLink, credentials }),
    getPaymentsAPI({ absolutePath: verificationsLink, credentials }),
    getPaymentsAPI({ absolutePath: associatedIdentitiesLink, credentials }),
    getPaymentsAPI({ absolutePath: merchantsLink, credentials }),
  ]

  const reviewQueueItem = normalizeResponse({ content: response })
  const application = normalizeResponse({ content: applicationResponse })
  const identity = normalizeResponse({ content: identityResponse })
  const settlement = normalizeResponse({ content: settlementResponse })
  const paymentInstruments = normalizeResponse({ content: get(paymentInstrumentsResponse, '_embedded.payment_instruments') })
  const verifications = normalizeResponse({ content: get(verificationsResponse, '_embedded.verifications') })
  const associatedIdentities = normalizeResponse({ content: get(associatedIdentitiesResponse, '_embedded.identities') })
  const identityMerchants = normalizeResponse({ content: get(merchantsResponse, '_embedded.merchants') })

  const processorType = get(response, 'processor_type')
  const identityMerchant = find(identityMerchants, ({ processor }) => { return processor === processorType })

  const reviewQueueItemModel = removeUndefined(merge({}, reviewQueueItem, {
    applicationObject: frontendApplicationModel({ data: application }),
    identity: frontendIdentityModel({ data: identity }),
    paymentInstruments: frontendPaymentInstrumentsModel({ data: paymentInstruments }),
    verifications: frontendVerificationsModel({ data: verifications }),
    associatedIdentities: frontendIdentitiesModel({ data: associatedIdentities }),
    merchant: frontendMerchantModel({ data: identityMerchant }),
    settlement: frontendSettlementModel({ data: settlement }),
    merchantId: get(identityMerchant, 'id'),
    underwritingDecision: decisionModel,
  }))

  return createSuccessResponse({
    data: [reviewQueueItemModel],
  })
}

export default co.wrap(getReviewQueueItemO)
