import './IdentitiesTabsS.scss'
import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import ReviewQueueIdentitiesC from 'components/Customer/Pages/ReviewQueue/ReviewQueueIdentities/ReviewQueueIdentitiesC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { REVIEW_QUEUE_IDENTITIES } from 'constants/language/languageConstants'

import {
  getPendingReviewQueueIdentitiesSelector,
  getRejectedReviewQueueIdentitiesSelector,
  getAcceptedReviewQueueIdentitiesSelector,
} from 'state-layer/selectors'

import {
  PENDING,
  REJECTED,
  ACCEPTED,
} from 'constants/reviewQueueConstants'

const tabs = [
  {
    id: 'pending',
    name: 'Pending',
    component: ReviewQueueIdentitiesC,
    props: {
      selector: getPendingReviewQueueIdentitiesSelector,
      outcome: PENDING,
    },
    queries: {
      outcome: PENDING,
    },
  },
  {
    id: 'rejected',
    name: 'Rejected',
    component: ReviewQueueIdentitiesC,
    props: {
      selector: getRejectedReviewQueueIdentitiesSelector,
      outcome: REJECTED,
    },
    queries: {
      outcome: REJECTED,
    },
  },
  {
    id: 'approved',
    name: 'Approved',
    component: ReviewQueueIdentitiesC,
    props: {
      selector: getAcceptedReviewQueueIdentitiesSelector,
      outcome: ACCEPTED,
    },
    queries: {
      outcome: ACCEPTED,
    },
  },
]

const IdentitiesTabs = () => {
  return (
    <div className='IdentitiesTabs'>
      <HeaderV2C
        title={REVIEW_QUEUE_IDENTITIES}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

export default IdentitiesTabs
