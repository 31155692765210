import './RecipientUnderwritingReviewS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import UnderwritingInformationC from 'components/Customer/Pages/UnderwritingV2/UnderwritingInformation/UnderwritingInformationC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import BankAccountVerificationReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BankAccountVerificationReport/BankAccountVerificationReportTabs'
import IdentityFiles from 'components/Customer/Pages/IdentityFiles/IdentityFiles'
import Loader from 'components/Shared/Loader/Loader'
import SanctionsScreeningReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReport/SanctionsScreeningReportTabs'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import formatUnderwritingReportTitle from 'utilities/formatters/formatUnderwritingReportTitle'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import get from 'lodash/get'

import {
  ERROR as ERROR_LABEL,
  NO_HIT as NO_HIT_LABEL,
  UNKNOWN as UNKNOWN_LABEL,
  RELATED_FILES,
  SANCTIONS_SCREENING,
  EXACT_HIT,
  HIGH_MATCH_HIT,
  MEDIUM_MATCH_HIT,
  NO_REPORT_DETAILS_AVAILABLE,
  REPORT_FAILED_TO_RUN,
  BUSINESS_INFORMATION,
  OWNER_INFORMATION,
  RECIPIENT_INFORMATION,
  BANK_ACCOUNT_INFORMATION,
  BANK_ACCOUNT_VERIFICATION,
} from 'constants/language/languageConstants'

const sanctionsScreeningNormalizationMap = {
  ERROR: ERROR_LABEL,
  NO_HIT: NO_HIT_LABEL,
  UNKNOWN: UNKNOWN_LABEL,
  HIT_EXACT: EXACT_HIT,
  HIT_HIGH_MATCH: HIGH_MATCH_HIT,
  HIT_MEDIUM_MATCH: MEDIUM_MATCH_HIT,
}

const RecipientUnderwritingReview = ({
  isFetching,
  reviewId = '',
  subjectId = '',
  entityId = '',
  identityFilesSelector,
  identityMerchants,
  isRecipientUnderwriting = true,
  recipientInformationDataSection = [],
  businessInformationDataSection = [],
  recipientReports = {},
  recipientBusinessReports = {},
  showBusinessInformation = false,
  businessDescriptionString = '',
  showBankAccountInformation = false,
  bankAccountInformationDataSection = [],
  bankAccountReport = {},
}) => {
  return (
    <div className='RecipientUnderwritingReview'>
      <UnderwritingInformationC
        reviewId={reviewId}
        subjectId={subjectId}
        isRecipientUnderwriting={isRecipientUnderwriting}
      />

      <IdentityFiles
        identityId={entityId}
        showUploadButton
        title={RELATED_FILES}
        urlQueriesToIgnore={['subjectId', 'entityType', 'entityId']}
        selector={identityFilesSelector}
        identityMerchants={identityMerchants}
      />

      {showBusinessInformation && (
      <PageSectionV2C
        title={BUSINESS_INFORMATION}
        isFetching={isFetching}
        columns={businessInformationDataSection}
        className='recipient-information-section'
        subTitle={businessDescriptionString}
      >
        <Accordion
          title={formatUnderwritingReportTitle(recipientBusinessReports, SANCTIONS_SCREENING)}
          description={get(recipientBusinessReports, 'normalized_response') ? `${sanctionsScreeningNormalizationMap[get(recipientBusinessReports, 'normalized_response')]}: ${get(recipientBusinessReports, 'normalized_message')}` : ''}
          isEmpty={isEmpty(get(recipientBusinessReports, 'raw.PotentialMatchers')) || get(recipientBusinessReports, 'normalized_response') === 'NO_HIT'}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(recipientBusinessReports)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <SanctionsScreeningReportTabs report={recipientBusinessReports} />
        </Accordion>
      </PageSectionV2C>
      )}

      <PageSectionV2C
        title={showBusinessInformation ? OWNER_INFORMATION : RECIPIENT_INFORMATION}
        isFetching={isFetching}
        columns={recipientInformationDataSection}
        className='recipient-information-section'
      >
        <Accordion
          title={formatUnderwritingReportTitle(recipientReports, SANCTIONS_SCREENING)}
          description={get(recipientReports, 'normalized_response') ? `${sanctionsScreeningNormalizationMap[get(recipientReports, 'normalized_response')]}: ${get(recipientReports, 'normalized_message')}` : ''}
          isEmpty={isEmpty(get(recipientReports, 'raw.PotentialMatchers')) || get(recipientReports, 'normalized_response') === 'NO_HIT'}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(recipientReports)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <SanctionsScreeningReportTabs report={recipientReports} />
        </Accordion>
      </PageSectionV2C>

      {showBankAccountInformation && (
        <PageSectionV2C
          title={BANK_ACCOUNT_INFORMATION}
          columns={bankAccountInformationDataSection}
          isFetching={isFetching}
          className='bank-account-information-section'
        >
          {isFetching && <Loader />}
          {!isFetching && (
          <Accordion
            title={formatUnderwritingReportTitle(bankAccountReport, BANK_ACCOUNT_VERIFICATION)}
            description={`${get(bankAccountReport, 'normalized_response')}: ${lowerCase(get(bankAccountReport, 'normalized_message'))}`}
            isDisabled={isEmpty(bankAccountReport)}
            isDisabledMessage={REPORT_FAILED_TO_RUN}
          >
            <BankAccountVerificationReportTabs report={bankAccountReport} />
          </Accordion>
          )}
        </PageSectionV2C>
      )}
    </div>
  )
}

RecipientUnderwritingReview.propTypes = {
  isFetching: PropTypes.bool,
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  entityId: PropTypes.string,
  recipientInformationDataSection: PropTypes.array,
  businessInformationDataSection: PropTypes.array,
  recipientReports: PropTypes.object,
  recipientBusinessReports: PropTypes.object,
  identityMerchants: PropTypes.array,
  showBusinessInformation: PropTypes.bool,
  businessDescriptionString: PropTypes.string,
  showBankAccountInformation: PropTypes.bool,
  bankAccountInformationDataSection: PropTypes.array,
  bankAccountReport: PropTypes.object,
}

export default RecipientUnderwritingReview
