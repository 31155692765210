import './MonitoringAlertsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_MONITORING_ALERTS } from 'constants/flowConstants'
import { MONITORING_ALERTS } from 'constants/linkConstants'

import {
  TRANSACTION_MONITORING_SUBTITLE_DESCRIPTION,
  TRANSACTION_MONITORING_TITLE,
  TRANSACTION_MONITORING_RESOURCE_TITLE,
  TRANSACTION_MONITORING_TABLE_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const MonitoringAlerts = ({
  allowedFilters = [],
  initialQueries = {},
  isFetching = false,
  selector = () => {},
  quickFilters,
  initialFilters,
  selectedItemsData,
  selectedItemsKey,
  monitoringAlertPath = '',
}) => {
  return (
    <div className='MonitoringAlerts'>
      <HeaderV2C
        title={TRANSACTION_MONITORING_TITLE}
        subTitle={TRANSACTION_MONITORING_SUBTITLE_DESCRIPTION}
      />

      <TableC
        flow={GET_MONITORING_ALERTS}
        columnDescriptors={columnDescriptors}
        selector={selector}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        fileNameCSV='Monitoring_Alerts'
        fileResourceTitleCSV={TRANSACTION_MONITORING_RESOURCE_TITLE}
        emptyMessage={TRANSACTION_MONITORING_TABLE_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        selectedItemsData={selectedItemsData}
        selectedItemsKey={selectedItemsKey}
        linksKey={MONITORING_ALERTS}
        path={monitoringAlertPath}
      />
    </div>
  )
}

MonitoringAlerts.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  selector: PropTypes.func,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
  selectedItemsData: PropTypes.object,
  selectedItemsKey: PropTypes.string,
  monitoringAlertPath: PropTypes.string,
}

export default MonitoringAlerts
