import React, { Component } from 'react'
import { connect } from 'react-redux'
import BankAccountVerificationReport from './BankAccountVerificationReport'
import formatDate from 'utilities/formatters/formatDate'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'

import {
  ACCOUNT_FIRST_SEEN_DATE,
  ACCOUNT_LAST_UPDATED_DATE,
  BANK_ACCOUNT_TYPE,
  BANK_NAME,
  DESCRIPTION,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const report = get(props, 'report', {})

  const [
    normalizedResponse,
    normalizedMessage,
    bankName,
    bankAccountType,
    accountAddedDate,
    accountLastUpdatedDate,
  ] = getMany(report, [
    'normalized_response',
    'normalized_message',
    'details.account_details.bank_name',
    'details.account_details.account_type',
    'details.account_details.account_added_date',
    'details.account_details.account_last_updated_date',
  ])

  const verificationDetailsData = convertPageSectionDataToV2([
    { label: DESCRIPTION, value: `${normalizedResponse}: ${lowerCase(normalizedMessage)}` },
  ], 1)

  const accountDetailsData = convertPageSectionDataToV2([
    { label: BANK_NAME, value: bankName },
    { label: BANK_ACCOUNT_TYPE, value: bankAccountType },
    { label: ACCOUNT_FIRST_SEEN_DATE, value: formatDate({ date: accountAddedDate }) },
    { label: ACCOUNT_LAST_UPDATED_DATE, value: formatDate({ date: accountLastUpdatedDate }) },
  ], 1)

  return {
    verificationDetailsData,
    accountDetailsData,
  }
}

class BankAccountVerificationReportC extends Component {
  render() {
    return (
      <BankAccountVerificationReport {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(BankAccountVerificationReportC)
