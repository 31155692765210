import React, { Component } from 'react'
import { connect } from 'react-redux'
import Role from './Role'
import RoleMembersC from 'components/Customer/Pages/Role/RoleMembers/RoleMembersC'
import RoleDetailsC from 'components/Customer/Pages/Role/RoleDetails/RoleDetailsC'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import { goToPath } from 'state-layer/history'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getRoleRequest from 'utilities/actions/get/getRoleRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { FETCHING } from 'constants/reducerConstants'
import { DISABLE_CUSTOM_ROLE_MODAL } from 'constants/modalConstants'
import getMany from 'utilities/get/getMany'
import { CUSTOM } from 'constants/roleConstants'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

import {
  getRoleSelector,
  getApplicationSelector,
} from 'state-layer/selectors'

import {
  ROLES_PATH,
  APPLICATION_PATH,
  ADD_ROLE_PATH,
  DUPLICATE_ROLE_PATH,
} from 'constants/pathConstants'

import {
  ROLE_DETAILS,
  ROLE_MEMBERS,
  ROLE,
  ROLES,
  STATE,
  ENABLED,
  DISABLED,
  CREATED,
  APPLICATION_RESOURCE_TITLE,
  TYPE,
  DUPLICATE_AND_MODIFIY,
  EDIT_ROLE,
  DISABLE_ROLE,
} from 'constants/language/languageConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    getRole: ({ roleId, credentials }) => dispatch(getRoleRequest({ roleId, credentials })),
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    showDisableCustomRoleModal: (modalProps) => dispatch(showModalAction({ modalType: DISABLE_CUSTOM_ROLE_MODAL, modalProps })),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isFetching = get(state, `rolesR.${FETCHING}`)
  const roleId = get(props, 'params.roleId')
  const role = getRoleSelector(state, roleId)

  const [
    displayCreatedAt,
    roleEntityId,
    roleEntityType,
    roleName,
    roleType,
    roleEnabled,
    applicationId,
    permissions,
  ] = getMany(role, [
    'displayCreatedAt',
    'entityId',
    'entityType',
    'roleName',
    'type',
    'roleEnabled',
    'applicationId',
    'permissions',
  ])

  const application = getApplicationSelector(state, applicationId)
  const applicationName = get(application, 'businessName')

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: ROLE,
      id: roleId,
      clickToCopyPosition: 'right',
    },
    items: [
      {
        value: <h1>{roleName}</h1>,
      },
      {
        label: STATE,
        value: <EnabledStatus value={roleEnabled ? ENABLED : DISABLED} />,
      },
      {
        label: CREATED,
        value: displayCreatedAt,
      },
      {
        label: TYPE,
        value: capitalize(roleType),
      },
    ],
    isFetching,
  }

  return {
    credentials,
    credentialId,
    isFetching,
    roleEntityId,
    roleEntityType,
    roleId,
    role,
    headerData,
    contextBarData,
    applicationId,
    applicationName,
    roleEnabled,
    roleName,
    roleType,
    permissions,
  }
}

class RoleC extends Component {
  constructor(props) {
    super(props)

    const [
      urlEntityId,
      urlEntityType,
    ] = getMany(parseUrlQueries(), [
      'entityId',
      'entityType',
    ])

    this.state = { urlEntityId, urlEntityType }
  }

  componentDidMount() {
    const {
      roleId,
      getRole,
      credentials,
    } = this.props

    getRole({ roleId, credentials })
  }

  render() {
    const {
      credentialId,
      showDisableCustomRoleModal,
      roleId,
      roleName,
      roleEnabled,
      roleEntityId,
      roleEntityType,
      roleType,
      permissions,
      isFetching,
    } = this.props

    const {
      urlEntityId,
      urlEntityType,
    } = this.state

    const tabs = [
      {
        id: 'role-details',
        name: ROLE_DETAILS,
        component: RoleDetailsC,
        props: {
          type: roleType,
          permissions,
          isFetching,
          roleId,
        },
      },
      {
        id: 'role-members',
        name: ROLE_MEMBERS,
        component: RoleMembersC,
        props: {
          roleId,
          entityId: roleEntityId || urlEntityId,
          entityType: roleEntityType || urlEntityType,
        },
      },
    ]

    const actions = [
      {
        label: DUPLICATE_AND_MODIFIY,
        buttonClassName: 'duplicate-and-modify-role-button',
        action: () => goToPath({
          pathname: DUPLICATE_ROLE_PATH({ credentialId }),
          queries: {
            roleId,
            entityId: roleEntityId || urlEntityId,
            entityType: roleEntityType || urlEntityType,
          },
        }),
      },
      {
        label: EDIT_ROLE,
        buttonClassName: 'edit-role-button',
        condition: roleType === CUSTOM,
        action: () => goToPath({
          pathname: ADD_ROLE_PATH({ credentialId }),
          queries: {
            roleId,
            entityId: roleEntityId || urlEntityId,
            entityType: roleEntityType || urlEntityType,
          },
        }),
      },
      {
        label: DISABLE_ROLE,
        buttonClassName: 'disable-role-button',
        condition: roleType === CUSTOM && roleEnabled,
        action: () => showDisableCustomRoleModal({ roleId, roleName }),
      },
    ]

    return (
      <Role
        actions={actions}
        tabs={tabs}
        entityId={roleEntityId || urlEntityId}
        entityType={roleEntityType || urlEntityType}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleC)
