import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import CombinationPlansForm from './CombinationPlansForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'
import concat from 'lodash/concat'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import { COMBINATION_PLANS_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const templates = get(state, 'flowsR.formValues.templatesForm.templates')
  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(COMBINATION_PLANS_FORM)
  const combinationPlansValues = formSelector(state, 'combinationPlans')
  const templateNames = map(templates, ({ name }) => name)
  const templateOptions = map(templates, ({ name }) => selectOption(name, name))
  const schedulesValues = get(state, 'flowsR.formValues.schedulesForm.schedules')

  const schedulesTemplateNames = map(schedulesValues, ({ templateNames: scheduleTemplateNames }) => {
    // slice to remove 'name-' to prevent bug when redux field name starts with number
    return reduce(scheduleTemplateNames, (result, value, key = '') => merge({}, result, { [key.slice(5)]: value }), {})
  })

  return {
    credentials,
    templateNames,
    templateOptions: concat(selectOption('Select Template', ''), templateOptions),
    schedulesValues,
    schedulesTemplateNames,
    combinationPlansValues,
  }
}

class CombinationPlansFormC extends Component {
  render() {
    return (
      <CombinationPlansForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CombinationPlansFormC)
