import './PaymentLinkOverviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import { PATCH_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'

import {
  DETAILS,
} from 'constants/language/languageConstants'

const PaymentLinkOverview = ({
  isFetching = false,
  detailsData = [],
  itemDetailsData = [],
  allowedPaymentMethodsData = [],
  advancedDetailsData = [],
  paymentLink = {},
  tagsSectionData = [],
}) => {
  return (
    <div className='PaymentLinkOverview'>
      <PageSectionV2C
        title={DETAILS}
        columns={detailsData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title='Item Details'
        columns={itemDetailsData}
      />

      <PageSectionV2C
        title='Payment Methods'
        columns={allowedPaymentMethodsData}
      />

      <PageSectionV2C
        title='Advanced Settings'
        columns={advancedDetailsData}
        className='advanced-settings'
      />

      <TagsPageSectionV2
        actionType={PATCH_PAYMENT_LINK_F_REQUEST}
        data={tagsSectionData}
        record={paymentLink}
        isFetching={isFetching}
      />
    </div>
  )
}

PaymentLinkOverview.propTypes = {
  isFetching: PropTypes.bool,
  detailsData: PropTypes.func,
  itemDetailsData: PropTypes.array,
  allowedPaymentMethodsData: PropTypes.array,
  advancedDetailsData: PropTypes.array,
  paymentLink: PropTypes.object,
  tagsSectionData: PropTypes.array,
}

export default PaymentLinkOverview
