import get from 'lodash/get'

import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateEditSubscriptionForm = (values) => {
  const {
    nickname,
    amount,
    buyerInstrumentId,
  } = values

  return {
    nickname: fieldEmpty(nickname, 'Subscription Name'),
    amount: nonEmptyNumber({ field: amount, name: 'Recurring Price' }),
  }
}

export default validateEditSubscriptionForm
