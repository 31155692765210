import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFERS,
  TRANSFER_REVERSALS,
} from 'constants/apiConstants'

const postTransferReversalAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: TRANSFER_REVERSALS({ transferId: id }),
    service: TRANSFERS,
  })
}

export default postTransferReversalAPI
