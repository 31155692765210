import './MerchantSubscriptionEnrollmentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { SUBSCRIPTION_BILLING_ICON } from 'constants/iconConstants'
import { GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS } from 'constants/flowConstants'
import { MERCHANT_SUBSCRIPTION_ENROLLMENTS } from 'constants/linkConstants'
import { getEnrollmentsScheduleSelector } from 'state-layer/selectors'

const MerchantSubscriptionEnrollments = ({
  isFetching = false,
  initialQueries,
  actions,
  merchantId,
  selectedItemsKey,
  selectedItemsData = {},
  schedulePath = () => {},
}) => {
  return (
    <div className='MerchantSubscriptionEnrollments'>
      <TableC
        columnDescriptors={columnDescriptors}
        flow={GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS}
        linksKey={MERCHANT_SUBSCRIPTION_ENROLLMENTS}
        selector={getEnrollmentsScheduleSelector}
        selectorId={merchantId}
        actions={actions}
        isFetching={isFetching}
        initialQueries={initialQueries}
        icon={`fa fa-${SUBSCRIPTION_BILLING_ICON}`}
        fileNameCSV={`Merchant_${merchantId}_Subscription_Enrollments`}
        emptyMessage='No Subscription Enrollments Available'
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        path={schedulePath}
      />
    </div>
  )
}

MerchantSubscriptionEnrollments.propTypes = {
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  actions: PropTypes.array,
  merchantId: PropTypes.string,
  selectedItemsKey: PropTypes.string,
  selectedItemsData: PropTypes.object,
  schedulePath: PropTypes.func,
}

export default MerchantSubscriptionEnrollments
