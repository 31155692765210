import './WebhookS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import WebhookFailureDetailsC from 'components/Customer/Shared/Display/WebhookFailureDetails/WebhookFailureDetailsC'

import {
  DETAILS,
  ENABLED_EVENTS,
  ENABLED_EVENTS_EMPTY_MSG,
  ENABLED_EVENTS_SUBHEADER,
} from 'constants/language/languageConstants'

const Webhook = ({
  headerData = {},
  contextBarData = {},
  detailsSectionData = [],
  enabledEventsDataSection = [],
  isFetching = false,
  actions = [],
  pageSectionActions = [],
  editPath = '',
  showWebhookFailureBanner = false,
  webhookId = '',
  applicationId,
}) => {
  const emptyMessage = (
    <span>
      {ENABLED_EVENTS_EMPTY_MSG}
      <Link to={editPath} className='edit-path text-link'>Edit</Link> this section to subscribe to specific entities and types.
    </span>
  )

  return (
    <div className='Webhook'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      {showWebhookFailureBanner && <WebhookFailureDetailsC webhookId={webhookId} applicationId={applicationId} /> }

      <PageSectionV2C
        title={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={ENABLED_EVENTS}
        subHeader={ENABLED_EVENTS_SUBHEADER}
        emptyMessage={emptyMessage}
        columns={enabledEventsDataSection}
        isFetching={isFetching}
        actions={pageSectionActions}
      />
    </div>
  )
}

Webhook.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsSectionData: PropTypes.array,
  enabledEventsDataSection: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  editPath: PropTypes.string,
  isAcceptingEvents: PropTypes.bool,
  enabled: PropTypes.bool,
  showWebhookFailureBanner: PropTypes.bool,
  webhookId: PropTypes.string,
  applicationId: PropTypes.string,
}

export default Webhook
