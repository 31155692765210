import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import APIKeys from './APIKeys'
import get from 'lodash/get'
import showModalAction from 'utilities/actions/showModalAction'
import { GET_APPLICATION_USERS } from 'constants/flowConstants'
import { CREATE_API_KEY_LABEL } from 'constants/language/languageConstants'
import columnDescriptors from './columnDescriptors'

import {
  CREATE_API_KEY,
  DISABLE_API_KEY,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `applicationUsersR.${FETCHING}`)

  const flow = GET_APPLICATION_USERS

  const flowValues = {
    applicationId,
  }

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
  }

  return {
    flow,
    flowValues,
    isFetching,
    applicationId,
    initialQueries,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateAPIKey: (modalProps) => dispatch(showModalAction({ modalType: CREATE_API_KEY, modalProps })),
    showDisableAPIKeyModal: (modalProps) => dispatch(showModalAction({ modalType: DISABLE_API_KEY, modalProps })),
  }
}

class APIKeysC extends Component {
  render() {
    const {
      showCreateAPIKey,
      applicationId,
      showDisableAPIKeyModal,
    } = this.props

    const actions = [
      {
        label: CREATE_API_KEY_LABEL,
        action: () => {
          showCreateAPIKey({
            applicationId,
          })
        },
        className: 'create-api-key-button',
      },
    ]


    const columnActions = {
      showDisableAPIKeyModal,
    }

    const normalizedColumnDescriptors = columnDescriptors({ columnActions })

    return (
      <APIKeys
        actions={actions}
        columnDescriptors={normalizedColumnDescriptors}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIKeysC)
