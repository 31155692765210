import TableRowActions from './TableRowActions'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import filter from 'lodash/filter'

class TableRowActionsC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showActionsMenu: false,
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideActionsMenu)
  }

  handleClickOutsideActionsMenu = (event) => {
    const clickedInside = event?.target.closest('.actions-menu')

    if (!clickedInside) {
      this.setState({ showActionsMenu: false })
      document.removeEventListener('click', this.handleClickOutsideActionsMenu)
    }
  }

  toggleActionsMenu = (e) => {
    e.preventDefault()
    const { showActionsMenu } = this.state

    if (allowedKeyPressControls(e)) {
      this.setState({ showActionsMenu: !showActionsMenu })

      if (!showActionsMenu) {
        document.addEventListener('click', this.handleClickOutsideActionsMenu)
        const { currentTarget } = e

        if (currentTarget) {
          const offset = currentTarget.getBoundingClientRect().top
          const menuElement = currentTarget.querySelector('.actions-menu')

          if (menuElement) {
            menuElement.style.top = `${offset}px`
          }
        }
      } else {
        document.removeEventListener('click', this.handleClickOutsideActionsMenu)
      }
    }
  }

  render() {
    const { showActionsMenu } = this.state
    const { actions } = this.props
    const filteredActions = filter(actions, ({ condition = true }) => condition)

    return (
      <TableRowActions
        {...this.props}
        toggleActionsMenu={this.toggleActionsMenu}
        showActionsMenu={showActionsMenu}
        actions={filteredActions}
      />
    )
  }
}

export default connect()(TableRowActionsC)
