import frontendUnderwritingProfileModel from 'utilities/create/models/frontendUnderwritingProfileModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendUnderwritingProfilesModel = ({ data: underwritingProfiles }) => {
  const underwritingProfileModels = map(underwritingProfiles, (underwritingProfile) => frontendUnderwritingProfileModel({ data: underwritingProfile }))

  return keyBy(underwritingProfileModels, 'id')
}

export default frontendUnderwritingProfilesModel
