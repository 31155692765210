import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatDateObject from 'utilities/formatters/formatDateObject'
import removeUndefined from 'utilities/remove/removeUndefined'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatWebsite from 'utilities/formatters/formatWebsite'
import formatMoney from 'utilities/formatters/formatMoney'
import formatAddress from 'utilities/formatters/formatAddress'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import { normalizeAddress } from 'constants/normalizationConstants'
import { MM_DD_YYYY } from 'constants/timeConstants'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import get from 'lodash/get'
import split from 'lodash/split'
import last from 'lodash/last'
import includes from 'lodash/includes'
import merge from 'lodash/merge'


import {
  AMERICAN_EXPRESS_MID, DATE_OF_BIRTH,
  DISCOVER_MID,
  MERCHANT_CATEGORY_CODE,
  NO, WEBSITE,
  YES,
} from 'constants/language/languageConstants'

import {
  INSTRUMENT_CREATED_LABEL,
  PAYMENT_INSTRUMENT_CREATED_LABEL,
  MERCHANT_IDENTITY_LABEL,
  PAYMENT_INSTRUMENT_LABEL,
  PAYMENT_INSTRUMENT_ENABLED,
  PAYMENT_INSTRUMENT_DISABLED,
} from 'constants/AuditLogEventConstants'

const frontendAuditLogEventModel = ({ data: event }) => {
  const [
    id,
    createdAt,
    name,
    type,
    userEmail,
    agentId,
    references,
    operationType,
    identityId,
    resource,
    linkedType,
    updates,
    difference,
    applicationId,
    userId,
    platformId,
    linkedTo,
    items,
  ] = getMany(event, [
    'id',
    'created_at',
    'name',
    'type',
    'user_email',
    'agent_id',
    'references',
    'operation_type',
    'identity_id',
    'resource',
    'linked_type',
    'updates',
    'updates.difference',
    'application_id',
    'user_id',
    'platform_id',
    'linked_to',
    'updates.items',
  ])

  // resource content when event is instrument created
  const [
    resourceUpdatedAt,
    identityRoles,
    resourceCreatedAt,
    entity,
    paymentInstrumentType,
    paymentInstrumentFingerprint,
    paymentInstrumentCurrency,
    paymentInstrumentId,
  ] = getMany(resource, [
    'updated_at',
    'identity_roles',
    'created_at',
    'entity',
    'type',
    'fingerprint',
    'currency',
    'id',
  ])

  const [
    differenceEnabledTo,
    differenceEnabledFrom,
  ] = getMany(difference, [
    'enabled.to',
    'enabled.from',
  ])

  // resource content when event is identity created or updated
  const [
    identityTaxProvided,
    identityBusinessName,
    identityBusinessAddress,
    identityDefaultStatementDescriptor,
    identityLastName,
    identityTitle,
    identityMcc,
    identityMaxTransactionAmount,
    identityUrl,
    identityBusinessPhone,
    identityPersonalAddress,
    identityPrincipalPercentageOwnership,
    identityPhone,
    identityBusinessTaxIdProvided,
    identityDateOfBirth,
    identityBusinessType,
    identityDoingBusinessAs,
    identityHasAcceptedCreditCardsPreviously,
    identityFirstName,
    identityIncorporationDate,
    identityEmail,
  ] = getMany(entity, [
    'tax_id_provided',
    'business_name',
    'business_address',
    'default_statement_descriptor',
    'last_name',
    'title',
    'mcc',
    'max_transaction_amount',
    'url',
    'business_phone',
    'personal_address',
    'principal_percentage_ownership',
    'phone',
    'business_tax_id_provided',
    'dob',
    'business_type',
    'doing_business_as',
    'has_accepted_credit_cards_previously',
    'first_name',
    'incorporation_date',
    'email',
  ])

  const normalizedIdentity = entity ? {
    identityTaxProvided,
    displayIdentityTaxProvided: identityTaxProvided ? YES : NO,
    identityBusinessName,
    displayIdentityBusinessName: formatBusinessNameString({ identityBusinessName, identityDoingBusinessAs }),
    identityBusinessAddress: normalizeAddress(identityBusinessAddress),
    identityDefaultStatementDescriptor,
    identityLastName,
    identityTitle,
    identityMcc,
    identityMaxTransactionAmount,
    identityUrl,
    displayIdentityUrl: formatWebsite(identityUrl),
    identityBusinessPhone,
    identityPersonalAddress: normalizeAddress(identityPersonalAddress),
    identityPrincipalPercentageOwnership,
    displayIdentityPrincipalPercentageOwnership: identityPrincipalPercentageOwnership ? `${identityPrincipalPercentageOwnership}%` : undefined,
    identityPhone,
    displayIdentityPhone: formatPhone(identityPhone),
    identityBusinessTaxIdProvided,
    identityDateOfBirth,
    displayIdentityDateOfBirth: formatDateObject({ date: identityDateOfBirth, format: MM_DD_YYYY }),
    identityBusinessType,
    identityDoingBusinessAs,
    identityHasAcceptedCreditCardsPreviously,
    identityFirstName,
    identityIncorporationDate,
    identityEmail,
    fullName: identityFirstName && identityLastName ? `${startCase(identityFirstName)} ${startCase(identityLastName)}` : undefined,
  } : undefined

  const normalizedResource = resource ? {
    resourceUpdatedAt,
    displayUpdatedAt: formatDate({ date: resourceUpdatedAt }),
    identityRoles,
    displayIdentityRoles: map(identityRoles, role => capitalize(role)).join(', '),
    resourceCreatedAt,
    displayResourceCreatedAt: formatDate({ date: resourceCreatedAt }),
    entity: normalizedIdentity,
    paymentInstrumentType,
    displayPaymentInstrumentType: snakeCaseToTitleCase({ key: paymentInstrumentType }),
    paymentInstrumentFingerprint,
    paymentInstrumentCurrency,
    paymentInstrumentId,
  } : undefined

  // TODO: Cata - revisit the object manipulation we're doing below to make it more readable and better (maybe use reduce?)
  const pathToLabelMap = {
    mcc: MERCHANT_CATEGORY_CODE,
    url: WEBSITE,
    discover_mid: DISCOVER_MID,
    amex_mid: AMERICAN_EXPRESS_MID,
    dob: DATE_OF_BIRTH,
  }

  const labelToFormatMap = {
    max_transaction_amount: (amount) => formatMoney({ amount }),
    updated_at: (date) => formatDate({ date }),
    'entity.business_address': (address) => formatAddress({ address }),
    phone: (phone) => formatPhone(phone),
    business_phone: (phone) => formatPhone(phone),
    principal_percentage_ownership: (percentage) => `${percentage}%`,
    website: (website) => formatWebsite(website),
    'entity.incorporation_date': (date) => formatDateObject({ date, format: MM_DD_YYYY }),
    business_type: (businessType) => convertSnakeToSentenceCase(businessType),
    has_accepted_credit_cards_previously: (hasAccepted) => (hasAccepted ? YES : NO),
    'entity.personal_address': (address) => formatAddress({ address }),
    'entity.dob': (date) => formatDateObject({ date, format: MM_DD_YYYY }),
  }

  const mergeKeys = [
    'entity.business_address',
    'entity.personal_address',
    'entity.incorporation_date',
    'entity.dob',
  ]

  const updatedItems = {}

  map(items, (item) => {
    const {
      path,
      from,
      to,
    } = item

    // extracting last key regardless of finding merge key
    const lastKey = last(split(path, '.'))
    let foundMergeKey = null

    map(mergeKeys, (mergeKey) => {
      if (includes(path, mergeKey)) {
        foundMergeKey = mergeKey
      }
    })

    // if there is a merge key, we need to create a new base object, or merge into the object
    if (foundMergeKey) {
      const foundData = updatedItems[foundMergeKey]

      if (foundData) {
        // we already have the base object here, so just merge in the changes
        updatedItems[foundMergeKey] = merge({}, foundData, {
          from: {
            [lastKey]: from,
          },
        })
      } else {
        // we don't have the base object yet, so take the existing object and set it before merging the changes
        const initialData = get(resource, foundMergeKey)

        // now merge the change for the previous value
        updatedItems[foundMergeKey] = {
          path,
          to: { ...initialData },
          from: {
            ...initialData,
            [lastKey]: from,
          },
        }
      }
    } else {
      updatedItems[lastKey] = {
        path,
        from,
        to,
      }
    }
  })

  const normalizedUpdatedItems = map(updatedItems, (item, key) => {
    const {
      from,
      to,
    } = item

    const formatFunction = get(labelToFormatMap, key)
    const formatKey = last(split(key, '.'))

    return {
      label: pathToLabelMap[formatKey] || snakeCaseToTitleCase({ key: formatKey }),
      from: formatFunction ? formatFunction(from) : from,
      to: formatFunction ? formatFunction(to) : to,
    }
  })

  return removeUndefined({
    id,
    name,
    displayName: name === INSTRUMENT_CREATED_LABEL ? PAYMENT_INSTRUMENT_CREATED_LABEL : name,
    type,
    displayType: snakeCaseToTitleCase({ key: type }),
    displayInstrumentCreatedType: type === 'INSTRUMENT_CREATED' ? PAYMENT_INSTRUMENT_CREATED_LABEL : type,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    userEmail,
    agentId,
    references,
    operationType,
    identityId,
    resource: normalizedResource,
    linkedType,
    displayLinkedType: linkedType === 'IDENTITY' ? MERCHANT_IDENTITY_LABEL : PAYMENT_INSTRUMENT_LABEL,
    updates,
    difference,
    differenceEnabledFrom,
    differenceEnabledTo,
    paymentInstrumentEnabledToValue: differenceEnabledTo === 'true' ? PAYMENT_INSTRUMENT_ENABLED : PAYMENT_INSTRUMENT_DISABLED,
    applicationId,
    userId,
    platformId,
    linkedTo,
    updatedItems: normalizedUpdatedItems,
  })
}

export default frontendAuditLogEventModel
