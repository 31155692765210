import frontendFraudScoreModel from 'utilities/create/models/frontendFraudScoreModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendFraudScoresModel = ({ data: fraudScores }) => {
  const fraudScoresModel = map(fraudScores, (fraudScore) => frontendFraudScoreModel({ data: fraudScore }))

  return keyBy(fraudScoresModel, 'id')
}

export default frontendFraudScoresModel
