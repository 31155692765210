import React from 'react'
import Papa from 'papaparse'
import size from 'lodash/size'
import get from 'lodash/get'
import showModalAction from 'utilities/actions/showModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import IdentityUploaderConfirmationFormC from 'components/Admin/Forms/IdentityUploaderConfirmationForm/IdentityUploaderConfirmationFormC'
import { CONFIRM_ACTION } from 'constants/modalConstants'
import { POST_IDENTITY_UPLOAD_F_REQUEST } from 'constants/flowConstants'
import { IDENTITY_UPLOADER } from 'constants/amplitudeConstants'

const submitIdentityUploaderForm = (values, dispatch, props) => {
  const { file } = values
  const { credentials } = props
  const fileReader = new FileReader()

  fileReader.onloadend = (e) => {
    const csv = Papa.parse(e.target.result, { header: true })
    const csvSize = size(get(csv, 'data'))

    dispatch(showModalAction({
      modalType: CONFIRM_ACTION,
      modalProps: {
        Component: IdentityUploaderConfirmationFormC,
        message: <>This upload will create <strong>{csvSize} new identities and payment instruments</strong>. Are you sure you want to upload?</>,
        confirmationAction: () => {
          dispatch({
            type: POST_IDENTITY_UPLOAD_F_REQUEST,
            payload: {
              values: { csv },
              credentials,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(IDENTITY_UPLOADER, {
                credentials,
                csv,
              }),
            },
          })
        },
      },
    }))
  }

  fileReader.readAsText(file)
}

export default submitIdentityUploaderForm
