import paymentsAPI from 'utilities/api/paymentsAPI'
import { SPLIT_TRANSFERS } from 'constants/apiConstants'

const getSplitTransfersAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: SPLIT_TRANSFERS,
    meta,
    queries,
    service: SPLIT_TRANSFERS,
  })
}

export default getSplitTransfersAPI
