import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_VERIFICATION,
} from 'constants/apiConstants'

const getIdentityVerificationAPI = ({ values, credentials, meta }) => {
  const {
    identityId,
    verificationId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: IDENTITY_VERIFICATION({ identityId, verificationId }),
    service: IDENTITIES,
  })
}

export default getIdentityVerificationAPI
