import './IdentitiesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getIdentitiesSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { IDENTITIES } from 'constants/linkConstants'
import { GET_IDENTITIES } from 'constants/flowConstants'
import { MERCHANTS_ICON } from 'constants/iconConstants'
import { MERCHANT_IDENTITIES_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  IDENTITIES_RESOURCE_TITLE,
  MERCHANT_IDENTITIES_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Identities = ({
  identitiesPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  actions = [],
  quickFilters = [],
}) => {
  return (
    <div className='Identities'>
      <HeaderV2C
        title={IDENTITIES_RESOURCE_TITLE}
        subTitle={MERCHANT_IDENTITIES_SUBTITLE_DESCRIPTION}
        learnMoreLink={MERCHANT_IDENTITIES_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_IDENTITIES}
        columnDescriptors={columnDescriptors}
        selector={getIdentitiesSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={IDENTITIES}
        allowedFilters={allowedFilters}
        path={identitiesPath}
        isFetching={isFetching}
        actions={actions}
        emptyMessage='No Identities Available'
        icon={`fa fa-${MERCHANTS_ICON}`}
        fileNameCSV={IDENTITIES_RESOURCE_TITLE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

Identities.propTypes = {
  identitiesPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default Identities
