import React from 'react'
import PropTypes from 'prop-types'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import map from 'lodash/map'

const RawResponse = ({
  customerResponseCode = '',
  ofacResponseCode = '',
  accountResponseCode = '',
  rawResponse = {},
}) => {
  return (
    <div className='RawResponse'>
      <div className='info'>
        { customerResponseCode && <div className='customer-response-code'>Customer Response Code: {customerResponseCode}</div> }
        { ofacResponseCode && <div className='ofac-response-code'>OFAC Response Code: {ofacResponseCode}</div> }
        { accountResponseCode && <div className='account-response-code'>Account Response Code: {accountResponseCode}</div> }
      </div>

      <div className='raw-summary'>
        <PrettyJSON data={rawResponse} />
      </div>
    </div>
  )
}

RawResponse.propTypes = {
  customerResponseCode: PropTypes.string,
  ofacResponseCode: PropTypes.string,
  accountResponseCode: PropTypes.string,
  rawResponse: PropTypes.object,
}

export default RawResponse
