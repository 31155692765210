import frontendComplianceFormModel from 'utilities/create/models/frontendComplianceFormModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendComplianceFormsModel = ({ data: complianceForms }) => {
  const complianceFormsModels = map(complianceForms, (complianceForm) => frontendComplianceFormModel({ data: complianceForm }))

  return keyBy(complianceFormsModels, 'id')
}

export default frontendComplianceFormsModel
