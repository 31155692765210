import './ApplyNowHomepageBannerS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Button from 'components/Shared/Button/Button'
import { ReactComponent as ProcessLivePaymentsImg } from 'img/svg/application-header-image.svg'
import { COMPANY_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  PROCESS_LIVE_PAYMENTS_ONLINE_DESCRIPTION,
  PROCESS_LIVE_PAYMENTS_ONLINE,
  APPLY_NOW,
} from 'constants/language/languageConstants'

const ApplyNowHomepageBanner = ({
  credentials = {},
}) => {
  return (
    <div className='ApplyNowHomepageBanner'>
      <div className='homepage-banner-text'>
        <h2>{PROCESS_LIVE_PAYMENTS_ONLINE}</h2>
        <div className='payments-description p-1'>{PROCESS_LIVE_PAYMENTS_ONLINE_DESCRIPTION}</div>

        <Link to={COMPANY_PATH({ credentialId: get(credentials, 'id') })}>
          <Button className='start-application-button' label={APPLY_NOW} variant='legacy-black' />
        </Link>
      </div>

      <div className='img-container'>
        <ProcessLivePaymentsImg />
      </div>
    </div>
  )
}

ApplyNowHomepageBanner.propTypes = {
  credentials: PropTypes.func,
}

export default ApplyNowHomepageBanner
