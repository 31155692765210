import genericConnectorConfigs from 'state-layer/reducers/genericConnectors.config'
import connector from 'utilities/reducers/connector'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const generateGenericConnectors = reduce(genericConnectorConfigs, (total, connectorData) => {
  const {
    id,
    name,
    flows,
    debug,
  } = connectorData

  const connectedReducer = connector({ id, flows, debug })

  return merge({}, total, {
    [name]: (state = {}, action = {}) => connectedReducer(state, action),
  })
}, {})

export default generateGenericConnectors
