import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import formatCheckboxGroupDisplayData from 'utilities/formatters/formatCheckboxGroupDisplayData'
import formatRadioDisplayData from 'utilities/formatters/formatRadioDisplayData'
import formatCustomFieldArrayDisplayData from 'utilities/formatters/formatCustomFieldArrayDisplayData'
import formatAddress from 'utilities/formatters/formatAddress'
import formatDateDisplayData from 'utilities/formatters/formatDateDisplayData'
import { DATE_FORMAT } from 'constants/timeConstants'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import {
  COMPLIANCE_MERCHANT_BUSINESS_MAPPING,
  COMPLIANCE_PAYMENT_CHANNELS_MAPPING,
  YES_NO_YES_NO_RADIO_OPTIONS,
} from 'constants/complianceConstants'

const ComplianceAssessmentInformationFields = [
  {
    name: 'qsaBusinessName',
    label: 'QSA Business Name',
  },
  {
    name: 'qsaContactName',
    label: 'QSA Contact Name',
  },
  {
    name: 'qsaTitle',
    label: 'QSA Title',
  },
  {
    name: 'qsaTelephone',
    label: 'QSA Telephone',
  },
  {
    name: 'qsaEmail',
    label: 'QSA Email',
  },
  {
    name: 'qsaUrl',
    label: 'QSA Url',
  },
  {
    name: 'qsaBusinessAddress',
    label: 'QSA Business Address',
    formatter: ({ qsaBusinessAddress }) => formatAddress({ address: qsaBusinessAddress }),
  },
  {
    name: 'typeMerchantBusiness',
    label: 'Type of Merchant Business',
    formatter: ({ typeMerchantBusiness }) => formatCheckboxGroupDisplayData({ data: typeMerchantBusiness, mapping: COMPLIANCE_MERCHANT_BUSINESS_MAPPING }),
  },
  {
    name: 'typeMerchantBusinessOther',
    label: 'Type of Merchant Business - Other',
  },
  {
    name: 'typePaymentChannelsServe',
    label: 'Type of Payment Channels Served',
    formatter: ({ typePaymentChannelsServe }) => formatCheckboxGroupDisplayData({ data: typePaymentChannelsServe, mapping: COMPLIANCE_PAYMENT_CHANNELS_MAPPING }),
  },
  {
    name: 'paymentChannelsCovered',
    label: 'Payment Channels Covered',
    formatter: ({ paymentChannelsCovered }) => formatCheckboxGroupDisplayData({ data: paymentChannelsCovered, mapping: COMPLIANCE_PAYMENT_CHANNELS_MAPPING }),
  },
  {
    name: 'howAndInWhatCapacityDoesYourBusinessHandleCardholderData',
    label: 'Handle Cardholder Data',
  },
  {
    name: 'facilityLocations',
    label: 'Facility Locations',
    formatter: ({ facilityLocations }) => formatCustomFieldArrayDisplayData({
      data: facilityLocations,
      itemName: 'Facility Location',
      optionals: {
        type_of_facility: { label: 'Type' },
        number_facilities: { label: 'Number' },
        locations_of_facility: { label: 'Locations' },
      },
    }),
    condition: ({ facilityLocations }) => !isEmpty(facilityLocations),
  },
  {
    name: 'oneOrMorePaymentApplications',
    label: 'One or More Payment Applications',
    formatter: ({ oneOrMorePaymentApplications }) => formatRadioDisplayData({ value: oneOrMorePaymentApplications, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'paymentApplicationsUsed',
    label: 'Payment Applications Used',
    formatter: ({ paymentApplicationsUsed }) => {
      return formatCustomFieldArrayDisplayData({
        data: paymentApplicationsUsed,
        itemName: 'Payment Application',
        optionals: {
          is_application_pa_dss_listed: {
            label: 'Is application PA-DSS Listed?',
            formatter: (value) => formatRadioDisplayData({ value, options: YES_NO_YES_NO_RADIO_OPTIONS }),
          },
          pa_dss_listing_expiry_date: {
            formatter: (date) => formatDateDisplayData({ date, format: DATE_FORMAT }),
          },
        },
      })
    },
    condition: ({ oneOrMorePaymentApplications }) => oneOrMorePaymentApplications === 'YES',
  },
  {
    name: 'provideHighLevelDescriptionEnvironment',
    label: 'Description of Environment',
  },
  {
    name: 'networkSegmentation',
    label: 'Network Segmentation Usage',
    formatter: ({ networkSegmentation }) => formatRadioDisplayData({ value: networkSegmentation, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'usedQir',
    label: 'QIR Usage',
    formatter: ({ usedQir }) => formatRadioDisplayData({ value: usedQir, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'nameOfQirCompany',
    label: 'QIR Company Name',
    condition: ({ usedQir }) => usedQir === 'YES',
  },
  {
    name: 'qirIndividualName',
    label: 'QIR Individual Name',
    condition: ({ usedQir }) => usedQir === 'YES',
  },
  {
    name: 'descriptionOfServicesProvidedByQir',
    label: 'QIR Services Provided',
    condition: ({ usedQir }) => usedQir === 'YES',
  },
  {
    name: 'companyShareCardholderDataWithThirdPartyServiceProviders',
    label: 'Share Cardholder Data with 3rd Party SPs',
    formatter: ({ companyShareCardholderDataWithThirdPartyServiceProviders }) => formatRadioDisplayData({ value: companyShareCardholderDataWithThirdPartyServiceProviders, options: YES_NO_YES_NO_RADIO_OPTIONS }),
  },
  {
    name: 'thirdPartyServiceProviders',
    label: '3rd Party Service Providers',
    formatter: ({ thirdPartyServiceProviders }) => formatCustomFieldArrayDisplayData({
      data: thirdPartyServiceProviders,
      itemName: '3rd Party Service Providers',
      optionals: {
        name_of_service_provider: { label: 'Name' },
        description_of_services_provided: { label: 'Description' },
      },
    }),
    condition: ({ companyShareCardholderDataWithThirdPartyServiceProviders }) => companyShareCardholderDataWithThirdPartyServiceProviders === 'YES',
  },
  {
    name: 'eligibilityToCompleteSaqA',
    label: 'Eligibility To Complete SAQ A',
    formatter: ({ eligibilityToCompleteSaqA }) => formatCheckboxGroupDisplayData({ data: eligibilityToCompleteSaqA }),
  },
]

const ComplianceAssessmentInformationDisplayData = (values) => {
  const filteredDetails = filter(ComplianceAssessmentInformationFields, ({ condition = () => true }) => condition(values))

  return map(filteredDetails, ({ name, label, formatter }) => ({
    label,
    value: formatter ? formatter(values) : get(values, name),
  }))
}

export default ComplianceAssessmentInformationDisplayData
