import './CreateWebhookS.scss'
import React from 'react'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import WebhookFormC from 'components/Customer/Forms/WebhookForm/WebhookFormC'
import { CREATE_WEBHOOK } from 'constants/language/languageConstants'
import get from 'lodash/get'

const CreateWebhook = (props) => {
  const applicationId = get(props, 'params.applicationId')

  return (
    <div className='CreateWebhook'>
      <ContextBar />

      <HeaderV2C
        title={CREATE_WEBHOOK}
      />

      <WebhookFormC
        applicationId={applicationId}
      />
    </div>
  )
}
export default CreateWebhook
