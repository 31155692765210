import React, { Component } from 'react'
import { connect } from 'react-redux'
import SendPayoutAmountForm from './SendPayoutAmountForm'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import getApplicationBalancesRequest from 'utilities/actions/get/getApplicationBalancesRequest'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { USD } from 'constants/currencyConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import find from 'lodash/find'
import get from 'lodash/get'

import {
  BE_BANK_ACCOUNT,
  BE_PAYMENT_CARD,
} from 'constants/paymentInstrumentConstants'

import {
  getBalancesSelector,
  getDisbursementRulesSelector,
  getPaymentInstrumentSelector,
  getMerchantSelector,
  getIdentitySelector,
} from 'state-layer/selectors'

import {
  FINIX_V1,
  DUMMY_V1,
} from 'constants/processorConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const credentialId = get(credentials, 'id')
  const applicationBalances = getBalancesSelector(state)
  const applicationBalance = find(applicationBalances, { currency: USD })

  const [
    availableAmount,
    displayAvailableAmount,
  ] = getMany(applicationBalance, [
    'availableAmount',
    'displayAvailableAmount',
  ])

  const disbursementRules = getDisbursementRulesSelector(state)

  const [
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  ] = getMany(disbursementRules, [
    'applicationACHRulesMaxTransactionAmount',
    'applicationCardRulesMaxTransactionAmount',
    'recipientACHRulesMaxTransactionAmount',
    'recipientCardRulesMaxTransactionAmount',
  ])

  const selectedPaymentInstrumentId = get(state, 'flowsR.formValues.recipient.paymentInstrumentId.value')
  const selectedPaymentInstrument = getPaymentInstrumentSelector(state, selectedPaymentInstrumentId)
  const currentProcessor = isProductionEnv ? FINIX_V1 : DUMMY_V1
  const isBankAccount = get(selectedPaymentInstrument, 'instrumentType') === BE_BANK_ACCOUNT
  const isPaymentCard = get(selectedPaymentInstrument, 'instrumentType') === BE_PAYMENT_CARD

  const selectedRecipientId = get(state, 'flowsR.formValues.recipient.identityId.value')
  const selectedRecipient = getIdentitySelector(state, selectedRecipientId)
  const selectedRecipientMerchantId = get(find(get(selectedRecipient, 'merchants'), ({ processor }) => processor === currentProcessor), 'id')
  const merchantInformation = getMerchantSelector(state, selectedRecipientMerchantId)
  const isFetchingMerchantData = get(state, `merchantsR.${FETCHING}`)
  const isSameDayAchEnabled = get(merchantInformation, 'disbursementsSameDayAchPushEnabled', false)
  const applicationSameDayAchEnabled = get(merchantInformation, 'application.disbursements_same_day_ach_push_enabled', false)

  return {
    credentials,
    credentialId,
    displayAvailableAmount,
    availableAmount,
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
    isBankAccount,
    isPaymentCard,
    isSameDayAchEnabled,
    selectedRecipientMerchantId,
    merchantInformation,
    isFetchingMerchantData,
    applicationSameDayAchEnabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ credentials, merchantId }) => dispatch(getMerchantRequest({ credentials, merchantId })),
    getDisbursementRules: ({ credentials, entityId }) => dispatch(getDisbursementRulesRequest({ credentials, entityId })),
    getApplicationBalances: ({ credentials, applicationId }) => dispatch(getApplicationBalancesRequest({ credentials, queries: { linked_to: applicationId } })),
  }
}

class SendPayoutAmountFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getDisbursementRules,
      getApplicationBalances,
      getMerchant,
      selectedRecipientMerchantId,
    } = this.props

    if (applicationId) {
      getDisbursementRules({ credentials, entityId: applicationId })
      getApplicationBalances({ credentials, applicationId })
    }

    if (selectedRecipientMerchantId) {
      getMerchant({ credentials, merchantId: selectedRecipientMerchantId })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      credentials,
      applicationId,
      getDisbursementRules,
      getApplicationBalances,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getDisbursementRules({ credentials, entityId: applicationId })
      getApplicationBalances({ credentials, applicationId })
    }
  }

  render() {
    return (
      <SendPayoutAmountForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPayoutAmountFormC)
