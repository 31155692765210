import './IdentityInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import TableC from 'components/Shared/Table/TableC'

import {
  PATCH_IDENTITY_F_REQUEST,
  UNDERWRITING_REVIEW,
} from 'constants/flowConstants'

import {
  DETAILS,
  BUSINESS_PRINCIPALS,
  PROCESSING_INFORMATION,
  VOLUME_PERCENTAGE_DISTRIBUTION,
  IDENTITY_UNDERWRITING_REVIEW,
  UNDERWRITING_REVIEWS_TABLE_EMPTY_MESSAGE,
  UNDERWRITING_REVIEWS,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

const IdentityInfo = ({
  processingDetailsSectionData = [],
  detailsSectionData = [],
  detailsSectionSubtitle,
  businessPrincipalsDataCards = [],
  tagsSectionData = [],
  identity = {},
  isFetching = false,
  isPayout = false,
  hideProcessingPrincipalSections = false,
  volumeDistributionData = [],
  allDataEmpty = false,
  processingSectionActions = [],
  identityId = '',
  underwritingReviews = {},
  underwritingReviewPath = {},
  hasUnderwritingPermissions = false,
  showResponseModal,
}) => {
  return (
    <div className='IdentityInfo'>
      <PageSectionV2C
        title={DETAILS}
        subTitle={detailsSectionSubtitle}
        columns={detailsSectionData}
        isFetching={isFetching}
        className='details-section'
        asideTitle={(
          <div className='view-api-response-link' onClick={showResponseModal}>
            {VIEW_API_RESPONSE}
          </div>
        )}
      />

      {!isPayout && !hideProcessingPrincipalSections && (
        <PageSectionV2C
          title={PROCESSING_INFORMATION}
          columns={processingDetailsSectionData}
          isFetching={isFetching}
          actions={processingSectionActions}
          className='processing-info-section'
        />
      )}

      {!hideProcessingPrincipalSections && (
        <PageSectionV2C
          title={BUSINESS_PRINCIPALS}
          columns={businessPrincipalsDataCards}
          isFetching={isFetching}
          className='business-principals-section'
        />
      )}

      {!allDataEmpty && (
        <PageSectionV2C
          title={VOLUME_PERCENTAGE_DISTRIBUTION}
          columns={convertPageSectionDataToV2(volumeDistributionData)}
          isFetching={isFetching}
          className='volume-distribution-section'
        />
      )}

      {hasUnderwritingPermissions && !isFetching && (
        <TableC
          title={UNDERWRITING_REVIEWS}
          columnDescriptors={columnDescriptors}
          data={underwritingReviews}
          entityType={UNDERWRITING_REVIEW}
          emptyMessage={UNDERWRITING_REVIEWS_TABLE_EMPTY_MESSAGE}
          fileNameCSV={`${IDENTITY_UNDERWRITING_REVIEW} ${identityId}`}
          path={underwritingReviewPath}
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_IDENTITY_F_REQUEST}
        data={tagsSectionData}
        record={identity}
        isFetching={isFetching}
      />
    </div>
  )
}

IdentityInfo.propTypes = {
  processingDetailsSectionData: PropTypes.array,
  detailsSectionData: PropTypes.array,
  detailsSectionSubtitle: PropTypes.string,
  businessPrincipalsDataCards: PropTypes.array,
  tagsSectionData: PropTypes.array,
  identity: PropTypes.object,
  isFetching: PropTypes.bool,
  isPayout: PropTypes.bool,
  hideProcessingPrincipalSections: PropTypes.bool,
  volumeDistributionData: PropTypes.array,
  processingSectionActions: PropTypes.array,
  identityId: PropTypes.string,
  underwritingReviews: PropTypes.object,
  underwritingReviewPath: PropTypes.object,
  hasUnderwritingPermissions: PropTypes.bool,
}

export default IdentityInfo
