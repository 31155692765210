// TODO: can we deprecate this file?
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Platform from './Platform'
import getPlatformRequest from 'utilities/actions/get/getPlatformRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import PlatformInfoC from 'components/Customer/Pages/Platform/PlatformInfo/PlatformInfoC'
import PlatformProcessorConfigsC from 'components/Customer/Pages/Platform/PlatformProcessorConfigs/PlatformProcessorConfigsC'
import { getPlatformSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `platformsR.${FETCHING}`)
  const platformId = get(props, 'params.platformId')
  const platform = getPlatformSelector(state, platformId)

  const tabs = [
    {
      id: 'general-info',
      name: 'General Info',
      component: PlatformInfoC,
      props: {
        platformId,
      },
    },
    {
      id: 'processor-configs',
      name: 'Processor Configs',
      component: PlatformProcessorConfigsC,
      props: {
        platformId,
      },
    },
  ]

  const headerTitle = platformId
  const headerSubTitle = get(platform, 'displayCreatedAt')

  return {
    isFetching,
    platformId,
    platform,
    credentials,
    tabs,
    headerTitle,
    headerSubTitle,
  }
}

const mapDispatchToProps = {
  getPlatform: getPlatformRequest,
}

class PlatformC extends Component {
  componentDidMount() {
    const {
      credentials,
      platformId,
      getPlatform,
    } = this.props

    getPlatform({ platformId, credentials })
  }

  render() {
    return (
      <Platform {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformC)
