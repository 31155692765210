import { GET_CUSTOMER_ONBOARDING_FORM_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getCustomerOnboardingFormRequest = ({
  customerOnboardingFormId,
  credentials,
}) => createAction({
  type: GET_CUSTOMER_ONBOARDING_FORM_F_REQUEST,
  id: customerOnboardingFormId,
  credentials,
})

export default getCustomerOnboardingFormRequest
