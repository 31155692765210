import selectOption from 'utilities/forms/selectOption'

export const PROD = 'PROD'
export const SANDBOX = 'SANDBOX'
export const ALL = 'ALL'

export const ADMINISTRATOR = 'administrator'
export const CLIENT_ADMINISTRATOR = 'client_administrator'
export const DEV_ADMINISTRATOR = 'dev_administrator'
export const GATEWAY_ADMINISTRATOR = 'gateway_administrator'

export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_PLATFORM = 'ROLE_PLATFORM'
export const ROLE_PARTNER = 'ROLE_PARTNER'
export const ROLE_MERCHANT = 'ROLE_MERCHANT'
export const ROLE_PROXY = 'ROLE_PROXY'
export const ROLE_SERVICE = 'ROLE_SERVICE'

export const ALL_ROLES = [ROLE_ADMIN, ROLE_PLATFORM, ROLE_PARTNER, ROLE_MERCHANT]
export const NON_MERCHANT_ROLES = [ROLE_ADMIN, ROLE_PLATFORM, ROLE_PARTNER]
export const ADMIN_PLATFORM_ROLES = [ROLE_ADMIN, ROLE_PLATFORM]
export const PARTNER_AND_MERCHANT_ROLES = [ROLE_PARTNER, ROLE_MERCHANT]
export const READ_ONLY_ROLES = [ROLE_SERVICE, ROLE_PROXY]
export const PLATFORM_AND_PARTNER_ROLES = [ROLE_PLATFORM, ROLE_PARTNER]

export const roleTypeMap = {
  [ROLE_ADMIN]: 'Admin',
  [ROLE_PLATFORM]: 'Platform',
  [ROLE_PARTNER]: 'Application',
  [ROLE_MERCHANT]: 'Merchant',
}

export const DASHBOARDS_ENVIRONMENT_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Live', PROD),
  selectOption('Sandbox', SANDBOX),
]

export const CUSTOM = 'CUSTOM'
export const SYSTEM = 'SYSTEM'

// System role names
export const SYSTEM_ADMINISTRATOR_ROLE = 'Administrator'
export const SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE = 'Payment Operations Manager'
export const SYSTEM_SALES_ROLE = 'Sales'
