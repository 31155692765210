import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  SETTLEMENT_AMOUNT,
  TRANSFER_COUNT,
} from 'constants/language/languageConstants'

const FirstSettlementCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displaySettlementAmountDollarsSum,
    settlementEntryCount,
  ] = getMany(calculations, [
    'displaySettlementAmountDollarsSum',
    'settlementEntryCount',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={SETTLEMENT_AMOUNT}
        headerData={{
          value: displaySettlementAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSFER_COUNT}
        headerData={{
          value: settlementEntryCount,
        }}
      />
    </div>
  )
}

export default FirstSettlementCards
