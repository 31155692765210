import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { hasEditPermission } from 'constants/tagConstants'
import { EDIT_TAGS } from 'constants/modalConstants'
import { EDIT_ICON } from 'constants/iconConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const editPermission = hasEditPermission(credentials, get(props, 'actionType'))

  return {
    credentials,
    editPermission,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showEditTagsModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_TAGS, modalProps })),
  }
}

class TagsPageSection extends Component {
  render() {
    const {
      actionType,
      credentials,
      data,
      record,
      showEditTagsModal,
      title = 'Tags',
      isFetching,
      editPermission,
    } = this.props

    // To Do: refactor the editPermission into action, so we don't need to do this in the future
    const actions = editPermission ? [
      {
        label: 'Edit',
        action: () => { showEditTagsModal({ record, actionType, credentials }) },
        icon: `fal fa-${EDIT_ICON}`,
        buttonClassName: 'edit-tags-button',
      },
    ] : []

    return (
      <PageSection
        actions={actions}
        data={data}
        title={title}
        isFetching={isFetching}
        component={TagsDisplaySection}
      />
    )
  }
}

TagsPageSection.propTypes = {
  actionType: PropTypes.string,
  credentials: PropTypes.object,
  data: PropTypes.array,
  record: PropTypes.object,
  showEditTagsModal: PropTypes.func,
  title: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsPageSection)
