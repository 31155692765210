import './TableRowActionsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import snakeCase from 'lodash/snakeCase'
import isEmpty from 'lodash/isEmpty'

const TableRowActions = ({
  toggleActionsMenu = () => {},
  showActionsMenu = false,
  actions = [],
}) => {
  const actionsMenuClass = showActionsMenu ? 'show' : 'hide'

  return !isEmpty(actions) && (
    <div className='TableRowActions' onClick={toggleActionsMenu}>
      {!showActionsMenu && <i className='fa fa-ellipsis-v' />}
      <div className='parent'>
        { showActionsMenu && <i className='fa fa-times' /> }
        <div className={`actions-menu ${actionsMenuClass}`}>
          {map(actions, ({ label, action, condition = true }) => {
            return condition && (
              <div className='actions-menu-item' key={snakeCase(label)}>
                <div className='title' onClick={action}>{label}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

TableRowActions.propTypes = {
  toggleActionsMenu: PropTypes.func,
  showActionsMenu: PropTypes.bool,
  actions: PropTypes.array,
}

export default TableRowActions
