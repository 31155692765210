import paymentsAPI from 'utilities/api/paymentsAPI'
import { MONITORING_ALERTS } from 'constants/apiConstants'

const getMonitoringAlertsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: MONITORING_ALERTS,
    queries,
    credentials,
  })
}

export default getMonitoringAlertsAPI
