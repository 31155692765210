import './LiveAccountApplicationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { LIVE_ACCOUNT_APPLICATION_FORM } from 'constants/language/languageConstants'

const LiveAccountApplications = ({
  actions = [],
  accessForms = {},
  clickAction = () => {},
}) => {
  return (
    <div className='LiveAccountApplications'>
      <TableC
        title={LIVE_ACCOUNT_APPLICATION_FORM}
        columnDescriptors={columnDescriptors}
        actions={actions}
        data={accessForms}
        clickAction={clickAction}
      />
    </div>
  )
}

LiveAccountApplications.propTypes = {
  accessForms: PropTypes.object,
  actions: PropTypes.array,
  clickAction: PropTypes.func,
}

export default LiveAccountApplications
