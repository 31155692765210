import './MonitoringAlertDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import MonitoringAlertInfoPanel from './components/MonitoringAlertInfoPanel'
import MonitoringAlertDetailsPanel from './components/MonitoringAlertDetailsPanel'
import MonitoringAlertInsights from './components/MonitoringAlertInsights'
import MonitoringAlertTable from './components/MonitoringAlertTable'

const MonitoringAlertDetails = ({
  monitoringAlert = {},
  monitoringAlertInfoCardData = [],
  monitoringAlertDetailsCardData = [],
  alertType,
}) => {
  return (
    <div className='MonitoringAlertDetails'>
      <MonitoringAlertInfoPanel
        monitoringAlert={monitoringAlert}
        infoCardData={monitoringAlertInfoCardData}
      />

      <MonitoringAlertDetailsPanel
        monitoringAlert={monitoringAlert}
        detailsCardData={monitoringAlertDetailsCardData}
      />

      <MonitoringAlertInsights
        alertType={alertType}
        monitoringAlert={monitoringAlert}
      />

      <MonitoringAlertTable
        alertType={alertType}
        monitoringAlert={monitoringAlert}
      />
    </div>
  )
}

MonitoringAlertDetails.propTypes = {
  monitoringAlert: PropTypes.object,
  monitoringAlertInfoCardData: PropTypes.array,
  monitoringAlertDetailsCardData: PropTypes.array,
  alertType: PropTypes.string,
}

export default MonitoringAlertDetails
