import './UpdateVelocityLimitsFormS.scss'
import React from 'react'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Loader from 'components/Shared/Loader/Loader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import submitUpdateVelocityLimitsForm from 'utilities/submit/submitUpdateVelocityLimitsForm'
import validateUpdateLimitsForm from 'utilities/validate/validateUpdateLimitsForm'
import formatNumberFormatField from 'utilities/forms/format/formatNumberFormatField'
import { UPDATE_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import isEqual from 'lodash/isEqual'

import {
  timeZoneOptions,
  startTimeOptions,
  monthStartDayOptions,
  meridianOptions,
} from 'constants/timeConstants'

import {
  DAILY_ACH_LIMITS,
  CANCEL,
  TIME_ZONE,
  CARD,
  DAILY_CARD_LIMITS,
  START_TIME,
  DAILY_CARD_COUNT,
  DAILY_ACH_COUNT,
  DAILY_CARD_VOLUME,
  DAILY_ACH_VOLUME,
  MONTHLY_ACH_LIMITS,
  MONTHLY_CARD_LIMITS,
  MONTHLY_CARD_COUNT,
  MONTHLY_ACH_COUNT,
  MONTHLY_CARD_VOLUME,
  MONTHLY_ACH_VOLUME,
  MONTHLY_START_DATE,
  PLEASE_COMPLETE_ALL_FIELDS,
  TRANSACTION_DETAILS,
  SAVE,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'


const UpdateVelocityLimitsForm = ({
  title = '',
  subtitle,
  handleSubmit = () => {},
  isFetchingApplication = false,
  limitType = CARD,
  isFormInvalid = false,
  invalid = false,
}) => {
  const cardLimitType = isEqual(limitType, CARD)

  return (
    <form className='UpdateVelocityLimitsForm' onSubmit={handleSubmit(submitUpdateVelocityLimitsForm)}>
      <div className='form-header'>
        <h3>{title}</h3>
        {subtitle && !isFetchingApplication && <div className='p-1 subtitle'>{subtitle}</div>}
        {isFetchingApplication && <Loader />}
      </div>

      <div className='form-content'>
        <RequiredFieldNotice />
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_ALL_FIELDS} />}

        <h5>{TRANSACTION_DETAILS}</h5>

        <AmountField
          name='maxTransactionAmount'
          className='max-transaction-amount-field'
          label={MAX_TRANSACTION_AMOUNT}
          contextLabel={USD}
        />

        <h5>{cardLimitType ? DAILY_CARD_LIMITS : DAILY_ACH_LIMITS}</h5>

        <Field
          name='dailyTimeZone'
          label={TIME_ZONE}
          component={ReactSelect}
          options={timeZoneOptions}
          isDisabled
        />

        <div className='start-time-section flex'>
          <Field
            name='dailyStartTime'
            label={START_TIME}
            component={ReactSelect}
            options={startTimeOptions}
          />

          <Field
            name='dailyTimeMeridian'
            className='daily-time-meridian'
            label='hidden label'
            hiddenLabel
            component={ReactSelect}
            options={meridianOptions}
            required={false}
          />
        </div>

        {/* TODO: not going to be supported until weekly limits are supported (FE-3662) */}
        {/* <Field */}
        {/*   name='applySettingsToOtherSections' */}
        {/*   label={APPLY_LIMIT_SETTINGS_CHECKBOX_MESSAGE} */}
        {/*   component={CheckboxC} */}
        {/*   handleChange={toggleUpdateSettings} */}
        {/* /> */}

        <div className='flex space-between'>
          <Field
            name='dailyCount'
            label={cardLimitType ? DAILY_CARD_COUNT : DAILY_ACH_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name='dailyVolume'
            label={cardLimitType ? DAILY_CARD_VOLUME : DAILY_ACH_VOLUME}
            component={InputField}
          />
        </div>

        <h5>{limitType === CARD ? MONTHLY_CARD_LIMITS : MONTHLY_ACH_LIMITS}</h5>

        <Field
          name='monthlyTimeZone'
          label={TIME_ZONE}
          component={ReactSelect}
          options={timeZoneOptions}
          isDisabled
        />

        <div className='monthly-time-section flex'>
          <Field
            name='monthlyDate'
            label={MONTHLY_START_DATE}
            component={ReactSelect}
            options={monthStartDayOptions}
          />

          <Field
            name='monthlyStartTime'
            className='monthly-start-time'
            label={START_TIME}
            component={ReactSelect}
            options={startTimeOptions}
          />

          <Field
            name='monthlyTimeMeridian'
            label='hidden label'
            hiddenLabel
            className='monthly-time-meridian'
            component={ReactSelect}
            options={meridianOptions}
            required={false}
          />
        </div>

        <div className='flex space-between'>
          <Field
            name='monthlyCount'
            label={cardLimitType ? MONTHLY_CARD_COUNT : MONTHLY_ACH_COUNT}
            component={InputField}
            format={formatNumberFormatField}
            placeholder='0'
          />

          <AmountField
            name='monthlyVolume'
            label={cardLimitType ? MONTHLY_CARD_VOLUME : MONTHLY_ACH_VOLUME}
            component={InputField}
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' />
          <Button className='submit-button' type='submit' label={SAVE} disabled={invalid} />
        </div>
      </div>
    </form>
  )
}

UpdateVelocityLimitsForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  isFetchingApplication: PropTypes.bool,
  limitType: PropTypes.string,
  isFormInvalid: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: UPDATE_VELOCITY_LIMITS_FORM,
  validate: validateUpdateLimitsForm,
  enableReinitialize: true,
})(UpdateVelocityLimitsForm)
