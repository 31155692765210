import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import get from 'lodash/get'
import frontendVerificationModel from 'utilities/create/models/frontendVerificationModel'

const frontendVerificationsModel = ({ data: verifications }) => {
  const verificationsModels = map(verifications, (verification) => frontendVerificationModel({ data: verification }))

  return keyBy(verificationsModels, 'id')
}

export default frontendVerificationsModel
