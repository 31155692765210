import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasSubmitFailed, isInvalid } from 'redux-form'
import { getAccessFormSelector } from 'state-layer/selectors'
import Loader from 'components/Shared/Loader/Loader'
import LiveAccountApplicationProcessingInfoForm from 'components/Customer/Forms/LiveAccountApplicationProcessingInfoForm/LiveAccountApplicationProcessingInfoForm'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import removeUndefined from 'utilities/remove/removeUndefined'
import { LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  MCC_CODE_LOWER_LABEL,
  MCC_CODE_LOWER_LABEL_STANDALONE_MERCHANT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const accessFormId = get(props, 'accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const processingData = get(accessForm, 'processingData')
  const dataLoaded = !isEmpty(accessForm)

  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const mccCodeLowerLabel = isStandaloneMerchant ? MCC_CODE_LOWER_LABEL_STANDALONE_MERCHANT : MCC_CODE_LOWER_LABEL
  const paymentVolumeByCustomerTypeLowerLabel = `Help us understand the distribution of your transactions based on who your ${isStandaloneMerchant ? 'customers' : 'users\' customers'} are. Total percentage must add up to 100%.`

  const isFormInvalid = isInvalid(LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM)(state) && hasSubmitFailed(LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM)(state)
  const initialValues = dataLoaded ? { processingData } : undefined

  return removeUndefined({
    mccCodeLowerLabel,
    paymentVolumeByCustomerTypeLowerLabel,
    initialValues,
    isFormInvalid,
    dataLoaded,
  })
}

class LiveAccountApplicationProcessingInfoFormC extends Component {
  render() {
    const { dataLoaded } = this.props
    if (!dataLoaded) return <Loader />

    return (
      <LiveAccountApplicationProcessingInfoForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(LiveAccountApplicationProcessingInfoFormC)
