import './AmountCurrencyDisplayTableS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { USD } from 'constants/currencyConstants'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

const AmountCurrencyDisplayTable = ({
  displayAmount = '',
  currency = USD,
}) => {
  const invalidDisplayAmount = isEqual(displayAmount, '-') || isEmpty(displayAmount)

  return (
    <div className='AmountCurrencyDisplayTable flex flex-end'>
      <div className='amount-string'>{displayAmount}</div>
      <div className={`currency-string ${invalidDisplayAmount && 'invalid-display-amount'}`}>{currency}</div>
    </div>
  )
}

AmountCurrencyDisplayTable.propTypes = {
  displayAmount: PropTypes.string,
  currency: PropTypes.string,
}

export default AmountCurrencyDisplayTable
