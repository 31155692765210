import React from 'react'
import memoize from 'utilities/memoize'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import some from 'lodash/some'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  ROLE_PARTNER,
  ROLE_MERCHANT,
} from 'constants/roleConstants'

import {
  DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_NAME,
  DIGITAL_WALLET_OPERATING_LICENSE_FILE_NAME,
  HEMP_CBD_OPERATING_LICENSE_FILE_NAME,
  THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_NAME,
  THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_NAME,
  LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_NAME,
  HEMP_CBD_TERMS_SERVICE_FILE_NAME,
  HEMP_CBD_TERMS_SHIPPING_HANDLING_FILE_NAME,
  HEMP_CBD_TERMS_REFUNDS_RETURNS_FILE_NAME,
  POLICY_EMPLOYEE_SCREENING_FILE_NAME,
  POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_NAME,
  POLICY_PHYSICAL_SECURITY_FILE_NAME,
  POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_NAME,
  HEMP_CBD_AWARENESS_LETTER_BANK_FILE_NAME,
  HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_NAME,
  FORM_8300_FILE_NAME,
  DATA_FLOW_DIAGRAM_FILE_NAME,
  GAMING_OPERATING_LICENSE_FILE_NAME,
  GAMBLING_CONTROLS_CERTIFICATION_FILE_NAME,
  FAIRNESS_OF_PLAY_FILE_NAME,
  TERMS_USE_ELIGIBILITY_OFFERS_FILE_NAME,
  PAYMENTS_TERMS_LIMITATIONS_FILE_NAME,
  RACETRACK_LIST_FILE_NAME,
  CONSENT_RACETRACK_FILE_NAME,
  CONSENT_HOST_RACING_ASSOCIATION_FILE_NAME,
  CONSENT_HOST_RACING_COMMISSION_FILE_NAME,
  CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_NAME,
  OPERATING_LICENSE_MARKETPLACE_FILE_NAME,
  DIAGRAM_FUNDS_FLOW_FILE_NAME,
  SELLER_AGREEMENT_FILE_NAME,
  VALUE_PROPOSITION_FILE_NAME,
  PROVIDED_SERVICES_FILE_NAME,
  LIST_SELLERS_VOLUMES_FILE_NAME,
  NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_NAME,
  NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_NAME,
  OPERATING_LICENSE_LENDING_FILE_NAME,
  TEMPLATE_LOAN_APPLICATION_FILE_NAME,
  TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_NAME,
  REQUIRED_DISCLOSURES_FEDERAL_FILE_NAME,
  REQUIRED_DISCLOSURES_STATE_FILE_NAME,
  TEMPLATE_BORROWER_COMMUNICATIONS_FILE_NAME,
  POLICY_LENDING_UNDERWRITING_SERVICING_FILE_NAME,
  POLICY_BORROWER_CREDIT_FILE_NAME,
  POLICY_FAIR_LENDING_FILE_NAME,
  LIST_LEAD_GENERATORS_FILE_NAME,
  LIST_BRANCH_LOCATIONS_FILE_NAME,
  CREDIT_REPAIR_MERCHANT_QUESTIONNAIRE_FILE_NAME,
  CREDIT_REPAIR_OPERATING_LICENSE_FILE_NAME,
  CREDIT_REPAIR_AGREEMENT_FILE_NAME,
  CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_NAME,
  CREDIT_REPAIR_TERM_USAGE_FILE_NAME,
  CREDIT_REPAIR_SIGN_UP_FLOW_FILE_NAME,
  CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_NAME,
  CREDIT_REPAIR_LAWS_FILE_NAME,
  TELEMARKETING_LAWS_FILE_NAME,
  THIRD_PARTY_SERVICE_PROVIDERS_FILE_NAME,
  DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_DESCRIPTION,
  DIGITAL_WALLET_OPERATING_LICENSE_FILE_DESCRIPTION,
  LICENSE_HEMP_CBD_FILE_DESCRIPTION,
  THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_DESCRIPTION,
  THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_DESCRIPTION,
  LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_DESCRIPTION,
  TERMS_SERVICE_FILE_DESCRIPTION,
  TERMS_SHIPPING_HANDLING_FILE_DESCRIPTION,
  TERMS_REFUNDS_RETURNS_FILE_DESCRIPTION,
  POLICY_EMPLOYEE_SCREENING_FILE_DESCRIPTION,
  POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_DESCRIPTION,
  POLICY_PHYSICAL_SECURITY_FILE_DESCRIPTION,
  POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_DESCRIPTION,
  HEMP_CBD_AWARENESS_LETTER_BANK_FILE_DESCRIPTION,
  HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_DESCRIPTION,
  FORM_8300_FILE_DESCRIPTION,
  DIAGRAM_DATA_FLOW_FILE_DESCRIPTION,
  OPERATING_LICENSE_GAMING_FILE_DESCRIPTION,
  CERTIFICATION_GAMBLING_CONTROLS_FILE_DESCRIPTION,
  POLICY_FAIRNESS_PLAY_FILE_DESCRIPTION,
  TERMS_USE_ELIGIBILITY_OFFERS_FILE_DESCRIPTION,
  TERMS_PAYMENTS_FILE_DESCRIPTION,
  LIST_RACETRACKS_FILE_DESCRIPTION,
  CONSENT_RACETRACK_FILE_DESCRIPTION,
  CONSENT_HOST_RACING_ASSOCIATION_FILE_DESCRIPTION,
  CONSENT_HOST_RACING_COMMISSION_FILE_DESCRIPTION,
  CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_DESCRIPTION,
  OPERATING_LICENSE_MARKETPLACE_FILE_DESCRIPTION,
  DIAGRAM_FUNDS_FLOW_FILE_DESCRIPTION,
  SELLER_AGREEMENT_FILE_DESCRIPTION,
  VALUE_PROPOSITION_FILE_DESCRIPTION,
  PROVIDED_SERVICES_FILE_DESCRIPTION,
  LIST_SELLERS_VOLUMES_FILE_DESCRIPTION,
  NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_DESCRIPTION,
  NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_DESCRIPTION,
  OPERATING_LICENSE_LENDING_FILE_DESCRIPTION,
  TEMPLATE_LOAN_APPLICATION_FILE_DESCRIPTION,
  TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DESCRIPTION,
  REQUIRED_DISCLOSURES_FEDERAL_FILE_DESCRIPTION,
  REQUIRED_DISCLOSURES_STATE_FILE_DESCRIPTION,
  TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DESCRIPTION,
  POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DESCRIPTION,
  POLICY_BORROWER_CREDIT_FILE_DESCRIPTION,
  POLICY_FAIR_LENDING_FILE_DESCRIPTION,
  LIST_LEAD_GENERATORS_FILE_DESCRIPTION,
  LIST_BRANCH_LOCATIONS_FILE_DESCRIPTION,
  MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR_FILE_DESCRIPTION,
  OPERATING_LICENSE_CREDIT_REPAIR_FILE_DESCRIPTION,
  TEMPLATE_CREDIT_REPAIR_AGREEMENT_FILE_DESCRIPTION,
  TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_DESCRIPTION,
  CREDIT_REPAIR_TERM_USAGE_FILE_DESCRIPTION,
  CREDIT_REPAIR_SIGN_UP_FLOW_FILE_DESCRIPTION,
  CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_DESCRIPTION,
  POLICY_CREDIT_REPAIR_LAWS_FILE_DESCRIPTION,
  POLICY_TELEMARKETING_LAWS_FILE_DESCRIPTION,
  POLICY_THIRD_PARTY_SERVICE_PROVIDERS_FILE_DESCRIPTION,
  FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_NAME,
  FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_DESCRIPTION,
  PROCESSING_STATEMENTS_6_MONTHS_FILE_NAME,
  PROCESSING_STATEMENTS_6_MONTHS_FILE_DESCRIPTION,
  POLICY_CUSTOMER_SUPPORT_FILE_NAME,
  POLICY_CUSTOMER_SUPPORT_FILE_DESCRIPTION,
  POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_NAME,
  POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_DESCRIPTION,
  POLICY_PRIVACY_FILE_NAME,
  POLICY_PRIVACY_FILE_DESCRIPTION,
  POLICY_ACCEPTABLE_USE_FILE_NAME,
  POLICY_ACCEPTABLE_USE_FILE_DESCRIPTION,
  POLICY_AML_BSA_OFAC_FILE_NAME,
  POLICY_AML_BSA_OFAC_FILE_DESCRIPTION,
  POLICY_GEOLOCATION_CONTROLS_FILE_NAME,
  POLICY_GEOLOCATION_CONTROLS_FILE_DESCRIPTION,
  POLICY_INFORMATION_SECURITY_FILE_NAME,
  POLICY_INFORMATION_SECURITY_FILE_DESCRIPTION,
  POLICY_BUSINESS_CONTINUITY_FILE_NAME,
  POLICY_BUSINESS_CONTINUITY_FILE_DESCRIPTION,
  ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_NAME,
  ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_DESCRIPTION,
  ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_NAME,
  ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_DESCRIPTION,
  MARKETING_MATERIALS_FILE_NAME,
  MARKETING_MATERIALS_FILE_DESCRIPTION,
  LEGAL_COMPLIANCE_OPINION_FILE_NAME,
  LEGAL_COMPLIANCE_OPINION_FILE_DESCRIPTION,
  PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_NAME,
  PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_DESCRIPTION,
} from 'constants/language/languageConstants'

/** @typedef {String} EDDIndustryName */

export const ALL = 'ALL'
export const DIGITAL_WALLETS = 'DIGITAL_WALLETS'
export const HEMP_CBD = 'HEMP_CBD'
export const CASINO_GAMING_FANTASY = 'CASINO_GAMING_FANTASY'
export const MARKETPLACES = 'MARKETPLACES'
export const NUTRACEUTICALS = 'NUTRACEUTICALS'
export const LENDING_UNSECURED = 'LENDING_UNSECURED'
export const LENDING_AUTO = 'LENDING_AUTO'
export const LENDING_MORTGAGE = 'LENDING_MORTGAGE'
export const CREDIT_REPAIR = 'CREDIT_REPAIR'

/**
 * @typedef {Object} EDDIndustryData
 * @property {string} name - Name of the Industry.
 * @property {string} label - Label for the Industry.
 * @property {string} description - Description of the Industry.
 *
 * @example
 * export const INDUSTRY = {
 *   name: 'industry,
 *   label: 'Industry',
 *   description: 'This is an industry.',
 * }
 */

/** @type {EDDIndustryData} */
export const DIGITAL_WALLETS_INDUSTRY_INFORMATION = {
  name: DIGITAL_WALLETS,
  label: 'Digital Wallets',
  description: 'Digital Wallets',
}

/** @type {EDDIndustryData} */
export const HEMP_CBD_INDUSTRY_INFORMATION = {
  name: HEMP_CBD,
  label: 'Hemp / CBD',
  description: 'Hemp / CBD',
}

/** @type {EDDIndustryData} */
export const CASINO_GAMING_FANTASY_INDUSTRY_INFORMATION = {
  name: CASINO_GAMING_FANTASY,
  label: 'Casino / Gaming / Fantasy',
  description: 'Casino, gaming, or fantasy industry',
}

/** @type {EDDIndustryData} */
export const MARKETPLACES_INDUSTRY_INFORMATION = {
  name: MARKETPLACES,
  label: 'Marketplaces',
  description: 'Marketplaces',
}

/** @type {EDDIndustryData} */
export const NUTRACEUTICALS_INDUSTRY_INFORMATION = {
  name: NUTRACEUTICALS,
  label: 'Nutraceuticals',
  description: 'Nutraceuticals',
}

/** @type {EDDIndustryData} */
export const LENDING_UNSECURED_INDUSTRY_INFORMATION = {
  name: LENDING_UNSECURED,
  label: 'Lending (Unsecured)',
  description: 'Lending (Unsecured)',
}

/** @type {EDDIndustryData} */
export const LENDING_AUTO_INDUSTRY_INFORMATION = {
  name: LENDING_AUTO,
  label: 'Lending (Auto)',
  description: 'Lending (Auto)',
}

/** @type {EDDIndustryData} */
export const LENDING_MORTGAGE_INDUSTRY_INFORMATION = {
  name: LENDING_MORTGAGE,
  label: 'Lending (Mortgage)',
  description: 'Lending (Mortgage)',
}

/** @type {EDDIndustryData} */
export const CREDIT_REPAIR_INDUSTRY_INFORMATION = {
  name: CREDIT_REPAIR,
  label: 'Credit Repair',
  description: 'Credit Repair',
}

export const INDUSTRIES = {
  [DIGITAL_WALLETS]: DIGITAL_WALLETS_INDUSTRY_INFORMATION,
  [HEMP_CBD]: HEMP_CBD_INDUSTRY_INFORMATION,
  [CASINO_GAMING_FANTASY]: CASINO_GAMING_FANTASY_INDUSTRY_INFORMATION,
  [MARKETPLACES]: MARKETPLACES_INDUSTRY_INFORMATION,
  [NUTRACEUTICALS]: NUTRACEUTICALS_INDUSTRY_INFORMATION,
  [LENDING_UNSECURED]: LENDING_UNSECURED_INDUSTRY_INFORMATION,
  [LENDING_AUTO]: LENDING_AUTO_INDUSTRY_INFORMATION,
  [LENDING_MORTGAGE]: LENDING_MORTGAGE_INDUSTRY_INFORMATION,
  [CREDIT_REPAIR]: CREDIT_REPAIR_INDUSTRY_INFORMATION,
}

/**
 * @typedef {Object} EDDFileData
 * @property {string} type - The type of the EDD file.
 * @property {string} name - The name of the EDD file.
 * @property {React.ReactNode} description - The description of the EDD file.
 * @property {boolean} [applicationRequired] - Whether the file is required for application.
 * @property {boolean} [merchantRequired] - Whether the file is required for merchant.
 */


// All Verticals Required Files
export const FINANCIAL_STATEMENT_2_YEARS_UNAUDITED = 'FINANCIAL_STATEMENT'
/** @type {EDDFileData} */
export const FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_DATA = {
  type: FINANCIAL_STATEMENT_2_YEARS_UNAUDITED,
  name: FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_NAME,
  description: FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_DESCRIPTION,
}

export const PROCESSING_STATEMENTS_6_MONTHS = 'PROCESSING_STATEMENT'
/** @type {EDDFileData} */
export const PROCESSING_STATEMENTS_6_MONTHS_FILE_DATA = {
  type: PROCESSING_STATEMENTS_6_MONTHS,
  name: PROCESSING_STATEMENTS_6_MONTHS_FILE_NAME,
  description: PROCESSING_STATEMENTS_6_MONTHS_FILE_DESCRIPTION,
}

export const POLICY_CUSTOMER_SUPPORT = 'POLICY_CUSTOMER_SUPPORT'
/** @type {EDDFileData} */
export const POLICY_CUSTOMER_SUPPORT_FILE_DATA = {
  type: POLICY_CUSTOMER_SUPPORT,
  name: POLICY_CUSTOMER_SUPPORT_FILE_NAME,
  description: POLICY_CUSTOMER_SUPPORT_FILE_DESCRIPTION,
}

export const POLICY_COMPLAINT_DISPUTE_RESOLUTION = 'POLICY_COMPLAINT_DISPUTE_RESOLUTION'
/** @type {EDDFileData} */
export const POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_DATA = {
  type: POLICY_COMPLAINT_DISPUTE_RESOLUTION,
  name: POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_NAME,
  description: POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_DESCRIPTION,
}

export const POLICY_PRIVACY = 'POLICY_PRIVACY'
/** @type {EDDFileData} */
export const POLICY_PRIVACY_FILE_DATA = {
  type: POLICY_PRIVACY,
  name: POLICY_PRIVACY_FILE_NAME,
  description: POLICY_PRIVACY_FILE_DESCRIPTION,
}

export const POLICY_ACCEPTABLE_USE = 'POLICY_ACCEPTABLE_USE'
/** @type {EDDFileData} */
export const POLICY_ACCEPTABLE_USE_FILE_DATA = {
  type: POLICY_ACCEPTABLE_USE,
  name: POLICY_ACCEPTABLE_USE_FILE_NAME,
  description: POLICY_ACCEPTABLE_USE_FILE_DESCRIPTION,
}

export const POLICY_AML_BSA_OFAC = 'POLICY_AML_BSA_OFAC'
/** @type {EDDFileData} */
export const POLICY_AML_BSA_OFAC_FILE_DATA = {
  type: POLICY_AML_BSA_OFAC,
  name: POLICY_AML_BSA_OFAC_FILE_NAME,
  description: POLICY_AML_BSA_OFAC_FILE_DESCRIPTION,
}

export const POLICY_GEOLOCATION_CONTROLS = 'POLICY_GEOLOCATION_CONTROLS'
/** @type {EDDFileData} */
export const POLICY_GEOLOCATION_CONTROLS_FILE_DATA = {
  type: POLICY_GEOLOCATION_CONTROLS,
  name: POLICY_GEOLOCATION_CONTROLS_FILE_NAME,
  description: POLICY_GEOLOCATION_CONTROLS_FILE_DESCRIPTION,
}

export const POLICY_INFORMATION_SECURITY = 'POLICY_INFORMATION_SECURITY'
/** @type {EDDFileData} */
export const POLICY_INFORMATION_SECURITY_FILE_DATA = {
  type: POLICY_INFORMATION_SECURITY,
  name: POLICY_INFORMATION_SECURITY_FILE_NAME,
  description: POLICY_INFORMATION_SECURITY_FILE_DESCRIPTION,
}

export const POLICY_BUSINESS_CONTINUITY = 'POLICY_BUSINESS_CONTINUITY'
/** @type {EDDFileData} */
export const POLICY_BUSINESS_CONTINUITY_FILE_DATA = {
  type: POLICY_BUSINESS_CONTINUITY,
  name: POLICY_BUSINESS_CONTINUITY_FILE_NAME,
  description: POLICY_BUSINESS_CONTINUITY_FILE_DESCRIPTION,
}

export const ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT = 'ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT'
/** @type {EDDFileData} */
export const ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_DATA = {
  type: ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT,
  name: ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_NAME,
  description: ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_DESCRIPTION,
}

export const ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT = 'ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT'
/** @type {EDDFileData} */
export const ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_DATA = {
  type: ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT,
  name: ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_NAME,
  description: ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_DESCRIPTION,
}

export const MARKETING_MATERIALS = 'MARKETING_MATERIALS'
/** @type {EDDFileData} */
export const MARKETING_MATERIALS_FILE_DATA = {
  type: MARKETING_MATERIALS,
  name: MARKETING_MATERIALS_FILE_NAME,
  description: MARKETING_MATERIALS_FILE_DESCRIPTION,
}

export const LEGAL_COMPLIANCE_OPINION = 'LEGAL_COMPLIANCE_OPINION'
/** @type {EDDFileData} */
export const LEGAL_COMPLIANCE_OPINION_FILE_DATA = {
  type: LEGAL_COMPLIANCE_OPINION,
  name: LEGAL_COMPLIANCE_OPINION_FILE_NAME,
  description: LEGAL_COMPLIANCE_OPINION_FILE_DESCRIPTION,
}

export const PARTNERSHIPS_THIRD_PARTY_AGREEMENTS = 'PARTNERSHIPS_THIRD_PARTY_AGREEMENTS'
/** @type {EDDFileData} */
export const PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_DATA = {
  type: PARTNERSHIPS_THIRD_PARTY_AGREEMENTS,
  name: PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_NAME,
  description: PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_DESCRIPTION,
}

// Digital Wallets Files
export const MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS = 'MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS'
/** @type {EDDFileData} */
export const MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS_FILE_DATA = {
  type: MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS,
  name: DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_NAME,
  description: DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: false,
}

export const OPERATING_LICENSE_DIGITAL_WALLET = 'OPERATING_LICENSE_DIGITAL_WALLET'
/** @type {EDDFileData} */
export const OPERATING_LICENSE_DIGITAL_WALLET_FILE_DATA = {
  type: OPERATING_LICENSE_DIGITAL_WALLET,
  name: DIGITAL_WALLET_OPERATING_LICENSE_FILE_NAME,
  description: DIGITAL_WALLET_OPERATING_LICENSE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

// Hemp / CBD Files
export const LICENSE_HEMP_CBD = 'LICENSE_HEMP_CBD'
/** @type {EDDFileData} */
export const LICENSE_HEMP_CBD_FILE_DATA = {
  type: LICENSE_HEMP_CBD,
  name: HEMP_CBD_OPERATING_LICENSE_FILE_NAME,
  description: LICENSE_HEMP_CBD_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const THC_CONCENTRATION_TESTING_LAB_RESULTS = 'THC_CONCENTRATION_TESTING_LAB_RESULTS'
/** @type {EDDFileData} */
export const THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_DATA = {
  type: THC_CONCENTRATION_TESTING_LAB_RESULTS,
  name: THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_NAME,
  description: THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const THC_CONCENTRATION_TESTING_LAB_INVOICE = 'THC_CONCENTRATION_TESTING_LAB_INVOICE'
/** @type {EDDFileData} */
export const THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_DATA = {
  type: THC_CONCENTRATION_TESTING_LAB_INVOICE,
  name: THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_NAME,
  description: THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const LIST_HEMP_CBD_PRODUCTS_SERVICES = 'LIST_HEMP_CBD_PRODUCTS_SERVICES'
/** @type {EDDFileData} */
export const LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_DATA = {
  type: LIST_HEMP_CBD_PRODUCTS_SERVICES,
  name: LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_NAME,
  description: LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TERMS_SERVICE = 'TERMS_SERVICE'
/** @type {EDDFileData} */
export const TERMS_SERVICE_FILE_DATA = {
  type: TERMS_SERVICE,
  name: HEMP_CBD_TERMS_SERVICE_FILE_NAME,
  description: TERMS_SERVICE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TERMS_SHIPPING_HANDLING = 'TERMS_SHIPPING_HANDLING'
/** @type {EDDFileData} */
export const TERMS_SHIPPING_HANDLING_FILE_DATA = {
  type: TERMS_SHIPPING_HANDLING,
  name: HEMP_CBD_TERMS_SHIPPING_HANDLING_FILE_NAME,
  description: TERMS_SHIPPING_HANDLING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TERMS_REFUNDS_RETURNS = 'TERMS_REFUNDS_RETURNS'
/** @type {EDDFileData} */
export const TERMS_REFUNDS_RETURNS_FILE_DATA = {
  type: TERMS_REFUNDS_RETURNS,
  name: HEMP_CBD_TERMS_REFUNDS_RETURNS_FILE_NAME,
  description: TERMS_REFUNDS_RETURNS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_EMPLOYEE_SCREENING = 'POLICY_EMPLOYEE_SCREENING'
/** @type {EDDFileData} */
export const POLICY_EMPLOYEE_SCREENING_FILE_DATA = {
  type: POLICY_EMPLOYEE_SCREENING,
  name: POLICY_EMPLOYEE_SCREENING_FILE_NAME,
  description: POLICY_EMPLOYEE_SCREENING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_VENDOR_TRAINING_COMPLIANCE = 'POLICY_VENDOR_TRAINING_COMPLIANCE'
/** @type {EDDFileData} */
export const POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_DATA = {
  type: POLICY_VENDOR_TRAINING_COMPLIANCE,
  name: POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_NAME,
  description: POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_PHYSICAL_SECURITY = 'POLICY_PHYSICAL_SECURITY'
/** @type {EDDFileData} */
export const POLICY_PHYSICAL_SECURITY_FILE_DATA = {
  type: POLICY_PHYSICAL_SECURITY,
  name: POLICY_PHYSICAL_SECURITY_FILE_NAME,
  description: POLICY_PHYSICAL_SECURITY_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_CANNABIS_PRODUCT_LOGISTICS = 'POLICY_CANNABIS_PRODUCT_LOGISTICS'
/** @type {EDDFileData} */
export const POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_DATA = {
  type: POLICY_CANNABIS_PRODUCT_LOGISTICS,
  name: POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_NAME,
  description: POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const HEMP_CBD_AWARENESS_LETTER_BANK = 'HEMP_CBD_AWARENESS_LETTER_BANK'
/** @type {EDDFileData} */
export const HEMP_CBD_AWARENESS_LETTER_BANK_FILE_DATA = {
  type: HEMP_CBD_AWARENESS_LETTER_BANK,
  name: HEMP_CBD_AWARENESS_LETTER_BANK_FILE_NAME,
  description: HEMP_CBD_AWARENESS_LETTER_BANK_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const HEMP_CBD_AWARENESS_LETTER_LESSOR = 'HEMP_CBD_AWARENESS_LETTER_LESSOR'
/** @type {EDDFileData} */
export const HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_DATA = {
  type: HEMP_CBD_AWARENESS_LETTER_LESSOR,
  name: HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_NAME,
  description: HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const FORM_8300 = 'FORM_8300'
/** @type {EDDFileData} */
export const FORM_8300_FILE_DATA = {
  type: FORM_8300,
  name: FORM_8300_FILE_NAME,
  description: FORM_8300_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

// Casino / Gaming / Fantasy Files
export const DIAGRAM_DATA_FLOW = 'DIAGRAM_DATA_FLOW'
/** @type {EDDFileData} */
export const DIAGRAM_DATA_FLOW_FILE_DATA = {
  type: DIAGRAM_DATA_FLOW,
  name: DATA_FLOW_DIAGRAM_FILE_NAME,
  description: DIAGRAM_DATA_FLOW_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const OPERATING_LICENSE_GAMING = 'OPERATING_LICENSE_GAMING'
/** @type {EDDFileData} */
export const OPERATING_LICENSE_GAMING_FILE_DATA = {
  type: OPERATING_LICENSE_GAMING,
  name: GAMING_OPERATING_LICENSE_FILE_NAME,
  description: OPERATING_LICENSE_GAMING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CERTIFICATION_GAMBLING_CONTROLS = 'CERTIFICATION_GAMBLING_CONTROLS'
/** @type {EDDFileData} */
export const CERTIFICATION_GAMBLING_CONTROLS_FILE_DATA = {
  type: CERTIFICATION_GAMBLING_CONTROLS,
  name: GAMBLING_CONTROLS_CERTIFICATION_FILE_NAME,
  description: CERTIFICATION_GAMBLING_CONTROLS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_FAIRNESS_PLAY = 'POLICY_FAIRNESS_PLAY'
/** @type {EDDFileData} */
export const POLICY_FAIRNESS_PLAY_FILE_DATA = {
  type: POLICY_FAIRNESS_PLAY,
  name: FAIRNESS_OF_PLAY_FILE_NAME,
  description: POLICY_FAIRNESS_PLAY_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TERMS_USE_ELIGIBILITY_OFFERS = 'TERMS_USE_ELIGIBILITY_OFFERS'
/** @type {EDDFileData} */
export const TERMS_USE_ELIGIBILITY_OFFERS_FILE_DATA = {
  type: TERMS_USE_ELIGIBILITY_OFFERS,
  name: TERMS_USE_ELIGIBILITY_OFFERS_FILE_NAME,
  description: TERMS_USE_ELIGIBILITY_OFFERS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TERMS_PAYMENTS = 'TERMS_PAYMENTS'
/** @type {EDDFileData} */
export const TERMS_PAYMENTS_FILE_DATA = {
  type: TERMS_PAYMENTS,
  name: PAYMENTS_TERMS_LIMITATIONS_FILE_NAME,
  description: TERMS_PAYMENTS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const LIST_RACETRACKS = 'LIST_RACETRACKS'
/** @type {EDDFileData} */
export const LIST_RACETRACKS_FILE_DATA = {
  type: LIST_RACETRACKS,
  name: RACETRACK_LIST_FILE_NAME,
  description: LIST_RACETRACKS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CONSENT_RACETRACK = 'CONSENT_RACETRACK'
/** @type {EDDFileData} */
export const CONSENT_RACETRACK_FILE_DATA = {
  type: CONSENT_RACETRACK,
  name: CONSENT_RACETRACK_FILE_NAME,
  description: CONSENT_RACETRACK_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CONSENT_HOST_RACING_ASSOCIATION = 'CONSENT_HOST_RACING_ASSOCIATION'
/** @type {EDDFileData} */
export const CONSENT_HOST_RACING_ASSOCIATION_FILE_DATA = {
  type: CONSENT_HOST_RACING_ASSOCIATION,
  name: CONSENT_HOST_RACING_ASSOCIATION_FILE_NAME,
  description: CONSENT_HOST_RACING_ASSOCIATION_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CONSENT_HOST_RACING_COMMISSION = 'CONSENT_HOST_RACING_COMMISSION'
/** @type {EDDFileData} */
export const CONSENT_HOST_RACING_COMMISSION_FILE_DATA = {
  type: CONSENT_HOST_RACING_COMMISSION,
  name: CONSENT_HOST_RACING_COMMISSION_FILE_NAME,
  description: CONSENT_HOST_RACING_COMMISSION_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CONSENT_OFF_TRACK_RACING_COMMISSION = 'CONSENT_OFF_TRACK_RACING_COMMISSION'
/** @type {EDDFileData} */
export const CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_DATA = {
  type: CONSENT_OFF_TRACK_RACING_COMMISSION,
  name: CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_NAME,
  description: CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

// Marketplace Files
export const OPERATING_LICENSE_MARKETPLACE = 'OPERATING_LICENSE_MARKETPLACE'
/** @type {EDDFileData} */
export const OPERATING_LICENSE_MARKETPLACE_FILE_DATA = {
  type: OPERATING_LICENSE_MARKETPLACE,
  name: OPERATING_LICENSE_MARKETPLACE_FILE_NAME,
  description: OPERATING_LICENSE_MARKETPLACE_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const DIAGRAM_FUNDS_FLOW = 'DIAGRAM_FUNDS_FLOW'
/** @type {EDDFileData} */
export const DIAGRAM_FUNDS_FLOW_FILE_DATA = {
  type: DIAGRAM_FUNDS_FLOW,
  name: DIAGRAM_FUNDS_FLOW_FILE_NAME,
  description: DIAGRAM_FUNDS_FLOW_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const SELLER_AGREEMENT = 'SELLER_AGREEMENT'
/** @type {EDDFileData} */
export const SELLER_AGREEMENT_FILE_DATA = {
  type: SELLER_AGREEMENT,
  name: SELLER_AGREEMENT_FILE_NAME,
  description: SELLER_AGREEMENT_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const VALUE_PROPOSITION = 'VALUE_PROPOSITION'
/** @type {EDDFileData} */
export const VALUE_PROPOSITION_FILE_DATA = {
  type: VALUE_PROPOSITION,
  name: VALUE_PROPOSITION_FILE_NAME,
  description: VALUE_PROPOSITION_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const PROVIDED_SERVICES = 'PROVIDED_SERVICES'
/** @type {EDDFileData} */
export const PROVIDED_SERVICES_FILE_DATA = {
  type: PROVIDED_SERVICES,
  name: PROVIDED_SERVICES_FILE_NAME,
  description: PROVIDED_SERVICES_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: true,
}

export const LIST_SELLERS_VOLUMES = 'LIST_SELLERS_VOLUMES'
/** @type {EDDFileData} */
export const LIST_SELLERS_VOLUMES_FILE_DATA = {
  type: LIST_SELLERS_VOLUMES,
  name: LIST_SELLERS_VOLUMES_FILE_NAME,
  description: LIST_SELLERS_VOLUMES_FILE_DESCRIPTION,
  applicationRequired: true,
  merchantRequired: false,
}

// Neuroceuticals Files
export const ONLINE_CHECKOUT = 'ONLINE_CHECKOUT'
/** @type {EDDFileData} */
export const ONLINE_CHECKOUT_FILE_DATA = {
  type: ONLINE_CHECKOUT,
  name: NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_NAME,
  description: NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const ONLINE_PRODUCT_CONTENT = 'ONLINE_PRODUCT_CONTENT'
/** @type {EDDFileData} */
export const ONLINE_PRODUCT_CONTENT_FILE_DATA = {
  type: ONLINE_PRODUCT_CONTENT,
  name: NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_NAME,
  description: NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

// Lending (Unsecured, Auto, Mortgage) Files
export const OPERATING_LICENSE_LENDING = 'OPERATING_LICENSE_LENDING'
/** @type {EDDFileData} */
export const OPERATING_LICENSE_LENDING_FILE_DATA = {
  type: OPERATING_LICENSE_LENDING,
  name: OPERATING_LICENSE_LENDING_FILE_NAME,
  description: OPERATING_LICENSE_LENDING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TEMPLATE_LOAN_APPLICATION = 'TEMPLATE_LOAN_APPLICATION'
/** @type {EDDFileData} */
export const TEMPLATE_LOAN_APPLICATION_FILE_DATA = {
  type: TEMPLATE_LOAN_APPLICATION,
  name: TEMPLATE_LOAN_APPLICATION_FILE_NAME,
  description: TEMPLATE_LOAN_APPLICATION_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TEMPLATE_LENDING_ORIGINATION_AGREEMENT = 'TEMPLATE_LENDING_ORIGINATION_AGREEMENT'
/** @type {EDDFileData} */
export const TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DATA = {
  type: TEMPLATE_LENDING_ORIGINATION_AGREEMENT,
  name: TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_NAME,
  description: TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const REQUIRED_DISCLOSURES_FEDERAL = 'REQUIRED_DISCLOSURES_FEDERAL'
/** @type {EDDFileData} */
export const REQUIRED_DISCLOSURES_FEDERAL_FILE_DATA = {
  type: REQUIRED_DISCLOSURES_FEDERAL,
  name: REQUIRED_DISCLOSURES_FEDERAL_FILE_NAME,
  description: REQUIRED_DISCLOSURES_FEDERAL_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const REQUIRED_DISCLOSURES_STATE = 'REQUIRED_DISCLOSURES_STATE'
/** @type {EDDFileData} */
export const REQUIRED_DISCLOSURES_STATE_FILE_DATA = {
  type: REQUIRED_DISCLOSURES_STATE,
  name: REQUIRED_DISCLOSURES_STATE_FILE_NAME,
  description: REQUIRED_DISCLOSURES_STATE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TEMPLATE_BORROWER_COMMUNICATIONS = 'TEMPLATE_BORROWER_COMMUNICATIONS'
/** @type {EDDFileData} */
export const TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DATA = {
  type: TEMPLATE_BORROWER_COMMUNICATIONS,
  name: TEMPLATE_BORROWER_COMMUNICATIONS_FILE_NAME,
  description: TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_LENDING_UNDERWRITING_SERVICING = 'POLICY_LENDING_UNDERWRITING_SERVICING'
/** @type {EDDFileData} */
export const POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DATA = {
  type: POLICY_LENDING_UNDERWRITING_SERVICING,
  name: POLICY_LENDING_UNDERWRITING_SERVICING_FILE_NAME,
  description: POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_BORROWER_CREDIT = 'POLICY_BORROWER_CREDIT'
/** @type {EDDFileData} */
export const POLICY_BORROWER_CREDIT_FILE_DATA = {
  type: POLICY_BORROWER_CREDIT,
  name: POLICY_BORROWER_CREDIT_FILE_NAME,
  description: POLICY_BORROWER_CREDIT_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_FAIR_LENDING = 'POLICY_FAIR_LENDING'
/** @type {EDDFileData} */
export const POLICY_FAIR_LENDING_FILE_DATA = {
  type: POLICY_FAIR_LENDING,
  name: POLICY_FAIR_LENDING_FILE_NAME,
  description: POLICY_FAIR_LENDING_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const LIST_LEAD_GENERATORS = 'LIST_LEAD_GENERATORS'
/** @type {EDDFileData} */
export const LIST_LEAD_GENERATORS_FILE_DATA = {
  type: LIST_LEAD_GENERATORS,
  name: LIST_LEAD_GENERATORS_FILE_NAME,
  description: LIST_LEAD_GENERATORS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const LIST_BRANCH_LOCATIONS = 'LIST_BRANCH_LOCATIONS'
/** @type {EDDFileData} */
export const LIST_BRANCH_LOCATIONS_FILE_DATA = {
  type: LIST_BRANCH_LOCATIONS,
  name: LIST_BRANCH_LOCATIONS_FILE_NAME,
  description: LIST_BRANCH_LOCATIONS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

// Credit Repair Files
export const MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR = 'MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR'
/** @type {EDDFileData} */
export const MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR_FILE_DATA = {
  type: MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR,
  name: CREDIT_REPAIR_MERCHANT_QUESTIONNAIRE_FILE_NAME,
  description: MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const OPERATING_LICENSE_CREDIT_REPAIR = 'OPERATING_LICENSE_CREDIT_REPAIR'
/** @type {EDDFileData} */
export const OPERATING_LICENSE_CREDIT_REPAIR_FILE_DATA = {
  type: OPERATING_LICENSE_CREDIT_REPAIR,
  name: CREDIT_REPAIR_OPERATING_LICENSE_FILE_NAME,
  description: OPERATING_LICENSE_CREDIT_REPAIR_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TEMPLATE_CREDIT_REPAIR_AGREEMENT = 'TEMPLATE_CREDIT_REPAIR_AGREEMENT'
/** @type {EDDFileData} */
export const TEMPLATE_CREDIT_REPAIR_AGREEMENT_FILE_DATA = {
  type: TEMPLATE_CREDIT_REPAIR_AGREEMENT,
  name: CREDIT_REPAIR_AGREEMENT_FILE_NAME,
  description: TEMPLATE_CREDIT_REPAIR_AGREEMENT_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS = 'TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS'
/** @type {EDDFileData} */
export const TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_DATA = {
  type: TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS,
  name: CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_NAME,
  description: TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CREDIT_REPAIR_TERM_USAGE = 'CREDIT_REPAIR_TERM_USAGE'
/** @type {EDDFileData} */
export const CREDIT_REPAIR_TERM_USAGE_FILE_DATA = {
  type: CREDIT_REPAIR_TERM_USAGE,
  name: CREDIT_REPAIR_TERM_USAGE_FILE_NAME,
  description: CREDIT_REPAIR_TERM_USAGE_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CREDIT_REPAIR_SIGN_UP_FLOW = 'CREDIT_REPAIR_SIGN_UP_FLOW'
/** @type {EDDFileData} */
export const CREDIT_REPAIR_SIGN_UP_FLOW_FILE_DATA = {
  type: CREDIT_REPAIR_SIGN_UP_FLOW,
  name: CREDIT_REPAIR_SIGN_UP_FLOW_FILE_NAME,
  description: CREDIT_REPAIR_SIGN_UP_FLOW_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const CREDIT_REPAIR_PRODUCTS_SERVICES = 'CREDIT_REPAIR_PRODUCTS_SERVICES'
/** @type {EDDFileData} */
export const CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_DATA = {
  type: CREDIT_REPAIR_PRODUCTS_SERVICES,
  name: CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_NAME,
  description: CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_CREDIT_REPAIR_LAWS = 'POLICY_CREDIT_REPAIR_LAWS'
/** @type {EDDFileData} */
export const POLICY_CREDIT_REPAIR_LAWS_FILE_DATA = {
  type: POLICY_CREDIT_REPAIR_LAWS,
  name: CREDIT_REPAIR_LAWS_FILE_NAME,
  description: POLICY_CREDIT_REPAIR_LAWS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_TELEMARKETING_LAWS = 'POLICY_TELEMARKETING_LAWS'
/** @type {EDDFileData} */
export const POLICY_TELEMARKETING_LAWS_FILE_DATA = {
  type: POLICY_TELEMARKETING_LAWS,
  name: TELEMARKETING_LAWS_FILE_NAME,
  description: POLICY_TELEMARKETING_LAWS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const POLICY_THIRD_PARTY_SERVICE_PROVIDERS = 'POLICY_THIRD_PARTY_SERVICE_PROVIDERS'
/** @type {EDDFileData} */
export const POLICY_THIRD_PARTY_SERVICE_PROVIDERS_FILE_DATA = {
  type: POLICY_THIRD_PARTY_SERVICE_PROVIDERS,
  name: THIRD_PARTY_SERVICE_PROVIDERS_FILE_NAME,
  description: POLICY_THIRD_PARTY_SERVICE_PROVIDERS_FILE_DESCRIPTION,
  applicationRequired: false,
  merchantRequired: true,
}

export const ALL_VERTICAL_FILES = {
  [FINANCIAL_STATEMENT_2_YEARS_UNAUDITED]: FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_DATA,
  [PROCESSING_STATEMENTS_6_MONTHS]: PROCESSING_STATEMENTS_6_MONTHS_FILE_DATA,
  [POLICY_CUSTOMER_SUPPORT]: POLICY_CUSTOMER_SUPPORT_FILE_DATA,
  [POLICY_COMPLAINT_DISPUTE_RESOLUTION]: POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_DATA,
  [POLICY_PRIVACY]: POLICY_PRIVACY_FILE_DATA,
  [POLICY_ACCEPTABLE_USE]: POLICY_ACCEPTABLE_USE_FILE_DATA,
  [POLICY_AML_BSA_OFAC]: POLICY_AML_BSA_OFAC_FILE_DATA,
  [POLICY_GEOLOCATION_CONTROLS]: POLICY_GEOLOCATION_CONTROLS_FILE_DATA,
  [POLICY_INFORMATION_SECURITY]: POLICY_INFORMATION_SECURITY_FILE_DATA,
  [POLICY_BUSINESS_CONTINUITY]: POLICY_BUSINESS_CONTINUITY_FILE_DATA,
  [ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT]: ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_DATA,
  [ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT]: ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_DATA,
  [MARKETING_MATERIALS]: MARKETING_MATERIALS_FILE_DATA,
  [LEGAL_COMPLIANCE_OPINION]: LEGAL_COMPLIANCE_OPINION_FILE_DATA,
  [PARTNERSHIPS_THIRD_PARTY_AGREEMENTS]: PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_DATA,
}

const ALL_VERTICALS_REQUIRED_FILES_MAP = {
  [FINANCIAL_STATEMENT_2_YEARS_UNAUDITED]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [PROCESSING_STATEMENTS_6_MONTHS]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_CUSTOMER_SUPPORT]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_COMPLAINT_DISPUTE_RESOLUTION]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_PRIVACY]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_ACCEPTABLE_USE]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_AML_BSA_OFAC]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
  },
  [POLICY_GEOLOCATION_CONTROLS]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_INFORMATION_SECURITY]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [POLICY_BUSINESS_CONTINUITY]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
  [MARKETING_MATERIALS]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
  },
  [LEGAL_COMPLIANCE_OPINION]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: false,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: false,
      [ROLE_MERCHANT]: true,
    },
  },
  [PARTNERSHIPS_THIRD_PARTY_AGREEMENTS]: {
    [DIGITAL_WALLETS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [HEMP_CBD]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CASINO_GAMING_FANTASY]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [MARKETPLACES]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [NUTRACEUTICALS]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_UNSECURED]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_AUTO]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [LENDING_MORTGAGE]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
    [CREDIT_REPAIR]: {
      [ROLE_PARTNER]: true,
      [ROLE_MERCHANT]: true,
    },
  },
}

export const INDUSTRY_DOCUMENTS = {
  [DIGITAL_WALLETS]: {
    [MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS]: MERCHANT_QUESTIONNAIRE_DIGITAL_WALLETS_FILE_DATA,
    [OPERATING_LICENSE_DIGITAL_WALLET]: OPERATING_LICENSE_DIGITAL_WALLET_FILE_DATA,
  },
  [HEMP_CBD]: {
    [LICENSE_HEMP_CBD]: LICENSE_HEMP_CBD_FILE_DATA,
    [THC_CONCENTRATION_TESTING_LAB_RESULTS]: THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_DATA,
    [THC_CONCENTRATION_TESTING_LAB_INVOICE]: THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_DATA,
    [LIST_HEMP_CBD_PRODUCTS_SERVICES]: LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_DATA,
    [TERMS_SERVICE]: TERMS_SERVICE_FILE_DATA,
    [TERMS_SHIPPING_HANDLING]: TERMS_SHIPPING_HANDLING_FILE_DATA,
    [TERMS_REFUNDS_RETURNS]: TERMS_REFUNDS_RETURNS_FILE_DATA,
    [POLICY_EMPLOYEE_SCREENING]: POLICY_EMPLOYEE_SCREENING_FILE_DATA,
    [POLICY_VENDOR_TRAINING_COMPLIANCE]: POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_DATA,
    [POLICY_PHYSICAL_SECURITY]: POLICY_PHYSICAL_SECURITY_FILE_DATA,
    [POLICY_CANNABIS_PRODUCT_LOGISTICS]: POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_DATA,
    [HEMP_CBD_AWARENESS_LETTER_BANK]: HEMP_CBD_AWARENESS_LETTER_BANK_FILE_DATA,
    [HEMP_CBD_AWARENESS_LETTER_LESSOR]: HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_DATA,
    [FORM_8300]: FORM_8300_FILE_DATA,
  },
  [CASINO_GAMING_FANTASY]: {
    [DIAGRAM_DATA_FLOW]: DIAGRAM_DATA_FLOW_FILE_DATA,
    [OPERATING_LICENSE_GAMING]: OPERATING_LICENSE_GAMING_FILE_DATA,
    [CERTIFICATION_GAMBLING_CONTROLS]: CERTIFICATION_GAMBLING_CONTROLS_FILE_DATA,
    [POLICY_FAIRNESS_PLAY]: POLICY_FAIRNESS_PLAY_FILE_DATA,
    [TERMS_USE_ELIGIBILITY_OFFERS]: TERMS_USE_ELIGIBILITY_OFFERS_FILE_DATA,
    [TERMS_PAYMENTS]: TERMS_PAYMENTS_FILE_DATA,
    [LIST_RACETRACKS]: LIST_RACETRACKS_FILE_DATA,
    [CONSENT_RACETRACK]: CONSENT_RACETRACK_FILE_DATA,
    [CONSENT_HOST_RACING_ASSOCIATION]: CONSENT_HOST_RACING_ASSOCIATION_FILE_DATA,
    [CONSENT_HOST_RACING_COMMISSION]: CONSENT_HOST_RACING_COMMISSION_FILE_DATA,
    [CONSENT_OFF_TRACK_RACING_COMMISSION]: CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_DATA,
  },
  [MARKETPLACES]: {
    [OPERATING_LICENSE_MARKETPLACE]: OPERATING_LICENSE_MARKETPLACE_FILE_DATA,
    [DIAGRAM_FUNDS_FLOW]: DIAGRAM_FUNDS_FLOW_FILE_DATA,
    [SELLER_AGREEMENT]: SELLER_AGREEMENT_FILE_DATA,
    [VALUE_PROPOSITION]: VALUE_PROPOSITION_FILE_DATA,
    [PROVIDED_SERVICES]: PROVIDED_SERVICES_FILE_DATA,
    [LIST_SELLERS_VOLUMES]: LIST_SELLERS_VOLUMES_FILE_DATA,
  },
  [NUTRACEUTICALS]: {
    [ONLINE_CHECKOUT]: ONLINE_CHECKOUT_FILE_DATA,
    [ONLINE_PRODUCT_CONTENT]: ONLINE_PRODUCT_CONTENT_FILE_DATA,
  },
  [LENDING_UNSECURED]: {
    [OPERATING_LICENSE_LENDING]: OPERATING_LICENSE_LENDING_FILE_DATA,
    [TEMPLATE_LOAN_APPLICATION]: TEMPLATE_LOAN_APPLICATION_FILE_DATA,
    [TEMPLATE_LENDING_ORIGINATION_AGREEMENT]: TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DATA,
    [REQUIRED_DISCLOSURES_FEDERAL]: REQUIRED_DISCLOSURES_FEDERAL_FILE_DATA,
    [REQUIRED_DISCLOSURES_STATE]: REQUIRED_DISCLOSURES_STATE_FILE_DATA,
    [TEMPLATE_BORROWER_COMMUNICATIONS]: TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DATA,
  },
  [LENDING_AUTO]: {
    [OPERATING_LICENSE_LENDING]: OPERATING_LICENSE_LENDING_FILE_DATA,
    [TEMPLATE_LOAN_APPLICATION]: TEMPLATE_LOAN_APPLICATION_FILE_DATA,
    [TEMPLATE_LENDING_ORIGINATION_AGREEMENT]: TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DATA,
    [REQUIRED_DISCLOSURES_FEDERAL]: REQUIRED_DISCLOSURES_FEDERAL_FILE_DATA,
    [REQUIRED_DISCLOSURES_STATE]: REQUIRED_DISCLOSURES_STATE_FILE_DATA,
    [TEMPLATE_BORROWER_COMMUNICATIONS]: TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DATA,
    [POLICY_LENDING_UNDERWRITING_SERVICING]: POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DATA,
    [POLICY_BORROWER_CREDIT]: POLICY_BORROWER_CREDIT_FILE_DATA,
    [POLICY_FAIR_LENDING]: POLICY_FAIR_LENDING_FILE_DATA,
    [LIST_LEAD_GENERATORS]: LIST_LEAD_GENERATORS_FILE_DATA,
    [LIST_BRANCH_LOCATIONS]: LIST_BRANCH_LOCATIONS_FILE_DATA,
  },
  [LENDING_MORTGAGE]: {
    [OPERATING_LICENSE_LENDING]: OPERATING_LICENSE_LENDING_FILE_DATA,
    [TEMPLATE_LOAN_APPLICATION]: TEMPLATE_LOAN_APPLICATION_FILE_DATA,
    [TEMPLATE_LENDING_ORIGINATION_AGREEMENT]: TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DATA,
    [REQUIRED_DISCLOSURES_FEDERAL]: REQUIRED_DISCLOSURES_FEDERAL_FILE_DATA,
    [REQUIRED_DISCLOSURES_STATE]: REQUIRED_DISCLOSURES_STATE_FILE_DATA,
    [TEMPLATE_BORROWER_COMMUNICATIONS]: TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DATA,
    [POLICY_LENDING_UNDERWRITING_SERVICING]: POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DATA,
    [POLICY_BORROWER_CREDIT]: POLICY_BORROWER_CREDIT_FILE_DATA,
    [POLICY_FAIR_LENDING]: POLICY_FAIR_LENDING_FILE_DATA,
    [LIST_LEAD_GENERATORS]: LIST_LEAD_GENERATORS_FILE_DATA,
    [LIST_BRANCH_LOCATIONS]: LIST_BRANCH_LOCATIONS_FILE_DATA,
  },
  [CREDIT_REPAIR]: {
    [MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR]: MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR_FILE_DATA,
    [OPERATING_LICENSE_CREDIT_REPAIR]: OPERATING_LICENSE_CREDIT_REPAIR_FILE_DATA,
    [TEMPLATE_CREDIT_REPAIR_AGREEMENT]: TEMPLATE_CREDIT_REPAIR_AGREEMENT_FILE_DATA,
    [TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS]: TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_DATA,
    [CREDIT_REPAIR_TERM_USAGE]: CREDIT_REPAIR_TERM_USAGE_FILE_DATA,
    [CREDIT_REPAIR_SIGN_UP_FLOW]: CREDIT_REPAIR_SIGN_UP_FLOW_FILE_DATA,
    [CREDIT_REPAIR_PRODUCTS_SERVICES]: CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_DATA,
    [POLICY_CREDIT_REPAIR_LAWS]: POLICY_CREDIT_REPAIR_LAWS_FILE_DATA,
    [POLICY_TELEMARKETING_LAWS]: POLICY_TELEMARKETING_LAWS_FILE_DATA,
    [POLICY_THIRD_PARTY_SERVICE_PROVIDERS]: POLICY_THIRD_PARTY_SERVICE_PROVIDERS_FILE_DATA,
  },
}

export const EDD_FILES = {
  ...ALL_VERTICAL_FILES,
  ...reduce(INDUSTRY_DOCUMENTS, (acc, industryDocuments) => ({ ...acc, ...industryDocuments }), {}),
}

export const EDD_FILE_TYPES = Object.keys(EDD_FILES)

export const MCC_INDUSTRIES = {
  5262: [
    MARKETPLACES_INDUSTRY_INFORMATION,
  ],
  5499: [
    NUTRACEUTICALS_INDUSTRY_INFORMATION,
  ],
  5816: [
    CASINO_GAMING_FANTASY_INDUSTRY_INFORMATION,
  ],
  5993: [
    NUTRACEUTICALS_INDUSTRY_INFORMATION,
    HEMP_CBD_INDUSTRY_INFORMATION,
  ],
  6012: [
    LENDING_UNSECURED_INDUSTRY_INFORMATION,
    LENDING_AUTO_INDUSTRY_INFORMATION,
    LENDING_MORTGAGE_INDUSTRY_INFORMATION,
  ],
  6051: [
    LENDING_UNSECURED_INDUSTRY_INFORMATION,
    LENDING_AUTO_INDUSTRY_INFORMATION,
    LENDING_MORTGAGE_INDUSTRY_INFORMATION,
    DIGITAL_WALLETS_INDUSTRY_INFORMATION,
  ],
  7276: [
    CREDIT_REPAIR_INDUSTRY_INFORMATION,
  ],
  7995: [
    CASINO_GAMING_FANTASY_INDUSTRY_INFORMATION,
  ],
}

const DOCUMENT_FILTER_BY_ROLE = {
  [ROLE_PARTNER]: 'applicationRequired',
  [ROLE_MERCHANT]: 'merchantRequired',
}

/**
 * Returns an array of objects that represent high-risk industries based on business MCC code and role of user representing the business.
 *
 * @param {String} mcc - The MCC code of the business.
 * @param {('ROLE_PARTNER'|'ROLE_MERCHANT')} role - The role of the user representing the business, either 'ROLE_PARTNER' or 'ROLE_MERCHANT'.
 * @returns {Array<EDDIndustryData>} - An array of objects, each containing the name, label, and description of high-risk industries.
 *
 * @example
 * const highRiskIndustries = getHighRiskIndustriesByMCC('6051', 'ROLE_PARTNER')
 * // [
 * //   { name: 'Industry1', label: 'Label1', description: 'Description1' },
 * //   { name: 'Industry2', label: 'Label2', description: 'Description2' },
 * //   ...
 * // ]
 */
export const getHighRiskIndustriesByMCC = (mcc, role) => {
  const industries = get(MCC_INDUSTRIES, mcc, [])

  return filter(industries, (industry) => {
    const industryName = get(industry, 'name')
    const industryDocuments = get(INDUSTRY_DOCUMENTS, industryName, {})
    const requiredKey = DOCUMENT_FILTER_BY_ROLE[role]

    return some(industryDocuments, (document) => get(document, requiredKey, true))
  })
}

/**
 * Returns an array of unique objects that represent EDD documents required for all high-risk industries, dependent upon role of the user representing the business.
 * We only return unique objects to avoid requesting duplicate documents that may be required across multiple high-risk industries.
 *
 * @param {Array<EDDIndustryName>} industries - An array of strings representing the names of the high-risk industries.
 * @param {('ROLE_PARTNER'|'ROLE_MERCHANT')} role - The role of the user representing the business, either 'ROLE_PARTNER' or 'ROLE_MERCHANT'.
 * @returns {Array<EDDFileData>} - An array of objects, each containing the type, description, applicationRequired, and merchantRequired fields for required EDD documents.
 *
 * @example
 * const requiredDocuments = getGeneralRequiredFiles(['Industry1', 'Industry2'], 'ROLE_PARTNER')
 * // [
 * //   { type: 'DocumentType1', description: 'Description1', applicationRequired: true, merchantRequired: false },
 * //   { type: 'DocumentType2', description: 'Description2', applicationRequired: true, merchantRequired: true },
 * //   ...
 * // ]
 */
export const getGeneralRequiredFiles = memoize((industries, role) => {
  const requiredFiles = new Map()
  const allFilesArr = Object.values(ALL_VERTICAL_FILES)

  for (const industry of industries) {
    for (const file of allFilesArr) {
      const fileType = get(file, 'type')

      if (!requiredFiles.has(fileType) && get(ALL_VERTICALS_REQUIRED_FILES_MAP, [fileType, industry, role], false)) {
        requiredFiles.set(fileType, file)
      }
    }
  }

  return Array.from(requiredFiles.values())
})

/**
 * Returns an array of unique objects that represent EDD documents required for the specified high-risk industries and role of the user representing the business.
 * We only return unique objects to avoid requesting duplicate documents that may be required across multiple high-risk industries.
 *
 * @param {Array<EDDIndustryName>} industries - An array of strings representing the names of the high-risk industries.
 * @param {('ROLE_PARTNER'|'ROLE_MERCHANT')} role - The role of the user representing the business, either 'ROLE_PARTNER' or 'ROLE_MERCHANT'.
 * @returns {Array<EDDFileData>} - An array of objects, each containing the type, description, applicationRequired, and merchantRequired fields for required EDD documents.
 *
 * @example
 * const requiredDocuments = getRequiredEDDDocuments(['Industry1', 'Industry2'], 'ROLE_PARTNER')
 * // [
 * //   { type: 'DocumentType1', description: 'Description1', applicationRequired: true, merchantRequired: false },
 * //   { type: 'DocumentType2', description: 'Description2', applicationRequired: true, merchantRequired: true },
 * //   ...
 * // ]
 */
export const getRequiredEDDDocuments = (industries, role) => {
  const generalRequiredFiles = getGeneralRequiredFiles(industries, role)

  const industrySpecificRequiredFiles = uniq(flatten(map(industries, (industry) => {
    const industryDocuments = get(INDUSTRY_DOCUMENTS, industry, {})
    const requiredKey = DOCUMENT_FILTER_BY_ROLE[role]

    return filter(industryDocuments, (document) => get(document, requiredKey, true))
  })))

  return [...generalRequiredFiles, ...industrySpecificRequiredFiles]
}

/**
 * Function that takes an array of high-risk industries and a role and returns an object where each key represents a high-risk industry and the value is an array of objects, each containing the type, description, applicationRequired, and merchantRequired fields for required EDD documents.
 *
 * @param {Array<EDDIndustryName>} industries - An array of strings representing the names of the high-risk industries.
 * @param {('ROLE_PARTNER'|'ROLE_MERCHANT')} role - The role of the user representing the business, either 'ROLE_PARTNER' or 'ROLE_MERCHANT'.
 * @returns {Object.<EDDIndustryName, Array<EDDFileData>>} - An object where each key represents a high-risk industry and the value is an array of objects, each containing the type, description, applicationRequired, and merchantRequired fields for required EDD documents.
 *
 * @example
 * const requiredDocuments = getRequiredEDDDocumentsByIndustries(['Digital Wallets', 'Nutraceuticals'], 'ROLE_PARTNER')
 * // {
 * //   'Digital Wallets': [
 * //     { type: 'digital_wallet_tos', description: 'Your TOS agreement.', applicationRequired: true, merchantRequired: false },
 * //   ],
 * //   'Nutraceuticals': [
 * //     { type: 'online_checkout', description: 'Screenshots of your website\'s checkout / purchase flow.', applicationRequired: false, merchantRequired: true },
 * //   ]
 * // }
 */
export const getRequiredEDDDocumentsByIndustries = (industries = [], role) => {
  return reduce(industries, (total, industry) => {
    const industryDocuments = get(INDUSTRY_DOCUMENTS, industry, {})

    if (isEmpty(industryDocuments)) {
      return total
    }

    const requiredKey = DOCUMENT_FILTER_BY_ROLE[role]
    const filteredDocuments = filter(industryDocuments, (document) => get(document, requiredKey, true))

    if (isEmpty(filteredDocuments)) {
      return total
    }

    return {
      ...total,
      [industry]: filteredDocuments,
    }
  }, {})
}
