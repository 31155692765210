import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import showModalAction from 'utilities/actions/showModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import { POST_DEVICE_F_REQUEST } from 'constants/flowConstants'
import { ADD_DEVICE } from 'constants/amplitudeConstants'
import { ACTIVATE_DEVICE_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import map from 'lodash/map'
import includes from 'lodash/includes'
import numeral from 'numeral'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  ACTIVATE_VALUE,
  AMOUNT_VALUE,
  EMAIL_VALUE,
  ON_THRESHOLD_AMOUNT_VALUE,
  PRINT_VALUE,
  SMS_VALUE,
} from 'constants/deviceConstants'

import {
  ACTIVATE_DEVICE,
  ADD_AND_ACTIVATE_DEVICE_MODAL_SUBTITLE,
} from 'constants/language/languageConstants'

const submitAddDeviceWithActivationForm = (values, dispatch, props) => {
  const {
    credentials,
    merchantId,
    merchantGateway,
  } = props

  const credentialId = get(credentials, 'id')

  const [
    percentageOne,
    percentageTwo,
    percentageThree,
    amountOne,
    amountTwo,
    amountThree,
    promptTipOnScreen,
    promptReceiptConfirmation,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
    serialNumber,
    promptForSignature,
    promptSignature,
    signatureThresholdAmount,
  ] = getMany(values, [
    'percentageOne',
    'percentageTwo',
    'percentageThree',
    'amountOne',
    'amountTwo',
    'amountThree',
    'promptTipOnScreen',
    'promptReceiptConfirmation',
    'receiptDeliveryPrint',
    'receiptDeliveryEmail',
    'receiptDeliverySms',
    'automaticDeliveryPrint',
    'automaticDeliveryEmail',
    'automaticDeliverySms',
    'serialNumber',
    'promptForSignature.value',
    'promptSignature.value',
    'signatureThresholdAmount',
  ])

  const percentOptions = [percentageOne, percentageTwo, percentageThree]
  const fixedOptions = map([amountOne, amountTwo, amountThree], (amount) => convertAmountStringToNumber({ amount }))
  const promptSignatureValueToSubmit = includes([FINIX_V1, DUMMY_V1], merchantGateway) ? undefined : promptSignature
  const promptForSignatureValueToSubmit = includes([FINIX_V1, DUMMY_V1], merchantGateway) ? promptForSignature : undefined
  const signatureThresholdAmountToSubmit = (promptSignature === AMOUNT_VALUE || promptForSignature === ON_THRESHOLD_AMOUNT_VALUE) ? numeral(signatureThresholdAmount).value() : 0

  const availableReceiptMethods = removeUndefined([
    receiptDeliveryPrint ? PRINT_VALUE : undefined,
    receiptDeliveryEmail ? EMAIL_VALUE : undefined,
    receiptDeliverySms ? SMS_VALUE : undefined,
  ])

  const automaticReceiptDeliveryMethods = removeUndefined([
    automaticDeliveryPrint ? PRINT_VALUE : undefined,
    automaticDeliveryEmail ? EMAIL_VALUE : undefined,
    automaticDeliverySms ? SMS_VALUE : undefined,
  ])

  const valuesToSubmit = promptTipOnScreen ? {
    ...values,
    percentOptions,
    fixedOptions: amountOne ? fixedOptions : undefined,
    availableReceiptMethods,
    automaticReceiptDeliveryMethods,
  } : {
    ...values,
    promptForSignature: promptForSignatureValueToSubmit,
    promptSignature: promptSignatureValueToSubmit,
    signatureThresholdAmount: signatureThresholdAmountToSubmit,
  }

  dispatch({
    type: POST_DEVICE_F_REQUEST,
    payload: {
      id: merchantId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const deviceId = head(keys(newValues))
        const deviceName = get(newValues, `${deviceId}.name`)

        dispatch(showModalAction({
          modalType: ACTIVATE_DEVICE_MODAL,
          className: 'modal-sm no-pad',
          modalProps: {
            deviceId,
            deviceName,
            credentialId,
            title: ACTIVATE_DEVICE,
            subTitle: ADD_AND_ACTIVATE_DEVICE_MODAL_SUBTITLE,
            gateway: merchantGateway,
            isAddAndActivateDeviceFlow: true,
            actionType: ACTIVATE_VALUE,
            canCloseModal: false,
            serialNumber,
          },
        }))
      },
      actionId: sendAmplitudeActionEvent(ADD_DEVICE, {
        credentials,
        values,
      }),
      hideFlashNotification: true,
    },
  })
}

export default submitAddDeviceWithActivationForm
