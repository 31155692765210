import reduce from 'lodash/reduce'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import split from 'lodash/split'

const allowFullKeyNameTypes = [
  'created_at',
  'due_at',
  'start_date',
  'end_date',
  'updated_at',
  'respond_by',
]

const normalizeQueries = ({
  queries,
  normalizationMap = backendNormalizationMap,
}) => {
  if (!isPlainObject(queries)) {
    return queries
  }

  return reduce(queries, (total, value, key) => {
    const splitKey = split(key, '.')[0] // needed to handle amount.lt, amount.gt, etc
    const normalizeKey = allowFullKeyNameTypes.includes(splitKey) ? key : splitKey
    const normalizeFn = normalizationMap[normalizeKey]
    const normalizedValue = normalizeFn ? normalizeFn(value) : value

    return merge({}, total, {
      [key]: normalizedValue,
    })
  }, {})
}

export default normalizeQueries
