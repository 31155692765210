import './SubmitCaseNoteModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ConfirmSubmitNoteFormC from 'components/Customer/Forms/ConfirmSubmitNoteForm/ConfirmSubmitNoteFormC'

import {
  SUBMIT_NOTE_CONFIRMATION_MSG,
} from 'constants/language/languageConstants'

const SubmitCaseNoteModal = ({
  linkedTo = '',
  email = '',
  firstName = '',
  lastName = '',
  jsonContent = {},
  noteId = '',
  successCallback = () => {},
}) => {
  return (
    <GenericModal
      className='SubmitCaseNoteModal'
      title={SUBMIT_NOTE_CONFIRMATION_MSG}
      Component={ConfirmSubmitNoteFormC}
      linkedTo={linkedTo}
      email={email}
      firstName={firstName}
      lastName={lastName}
      jsonContent={jsonContent}
      noteId={noteId}
      successCallback={successCallback}
    />
  )
}

SubmitCaseNoteModal.propTypes = {
  linkedTo: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jsonContent: PropTypes.object,
  successCallback: PropTypes.func,
}

export default SubmitCaseNoteModal
