import './MerchantStatementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { MERCHANT_STATEMENTS } from 'constants/linkConstants'
import { AUTHORIZATIONS_ICON } from 'constants/iconConstants'
import { getStatementsSelector } from 'state-layer/selectors'

import {
  MERCHANT_STATEMENTS as MERCHANT_STATEMENTS_FLOW,
  GET_MERCHANT_STATEMENTS,
} from 'constants/flowConstants'

import {
  STATEMENTS,
  STATEMENTS_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const MerchantStatements = ({
  flowValues = {},
  initialQueries = {},
  isFetching = false,
  merchantId = '',
  isRoleMerchantView = false,
}) => {
  return (
    <div className='MerchantStatements'>
      {isRoleMerchantView && (
        <HeaderV2C
          title={STATEMENTS}
          subTitle={STATEMENTS_SUBTITLE_DESCRIPTION}
        />
      )}

      <TableC
        flow={GET_MERCHANT_STATEMENTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getStatementsSelector}
        selectorId={merchantId}
        initialQueries={initialQueries}
        linksKey={MERCHANT_STATEMENTS}
        isFetching={isFetching}
        icon={`fa fa-${AUTHORIZATIONS_ICON}`}
        fileNameCSV={`Merchant_${merchantId}_Statements`}
        entityType={MERCHANT_STATEMENTS_FLOW}
      />
    </div>
  )
}

MerchantStatements.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  isRoleMerchantView: PropTypes.bool,
}

export default MerchantStatements
