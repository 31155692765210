import { PATCH_ONBOARDING_FORM_DOCUMENT_F_REQUEST } from 'constants/flowConstants'

const submitUpdateOnboardingFormFileForm = (values, dispatch, props) => {
  const {
    fileId,
    associatedFiles,
    onboardingFormId,
    removeFile,
  } = props

  const {
    fileName,
  } = values

  dispatch({
    type: PATCH_ONBOARDING_FORM_DOCUMENT_F_REQUEST,
    payload: {
      id: onboardingFormId,
      values: {
        fileId,
        fileName,
        associatedFiles,
        removeFile,
      },
    },
    meta: {
      closeModal: true,
      overwriteReducer: true,
    },
  })
}

export default submitUpdateOnboardingFormFileForm
