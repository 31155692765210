import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import isEmpty from 'lodash/isEmpty'

import {
  ID,
  BALANCE_ENTRY_DETAILS,
  DETAILS,
} from 'constants/language/languageConstants'

const BalanceAdjustment = ({
  headerData = {},
  actions = {},
  isFetching = false,
  balanceAdjustmentDetailsSectionData = [],
  balanceEntryDetailsData = [],
  paymentInstrument = {},
  balanceEntryId = '',
  balanceEntryLink = '',
}) => {
  return (
    <div className='BalanceAdjustment'>
      <ContextBar />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        className='balance-adjustment-details-section'
        isFetching={isFetching}
        columns={balanceAdjustmentDetailsSectionData}
      />

      { balanceEntryId && (
        <PageSectionV2C
          title={BALANCE_ENTRY_DETAILS}
          className='balance-entry-details-section'
          isFetching={isFetching}
          columns={balanceEntryDetailsData}
          clickToCopies={[{
            label: ID,
            content: balanceEntryId,
          }]}
          asideTitle={balanceEntryLink}
        />
      )}

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          paymentInstrument={paymentInstrument}
        />
      )}
    </div>
  )
}

BalanceAdjustment.propTypes = {
  headerData: PropTypes.object,
  actions: PropTypes.object,
  isFetching: PropTypes.bool,
  balanceAdjustmentDetailsSectionData: PropTypes.array,
  balanceEntryDetailsData: PropTypes.array,
  paymentInstrument: PropTypes.object,
  balanceEntryId: PropTypes.string,
  balanceEntryLink: PropTypes.element,
}

export default BalanceAdjustment
