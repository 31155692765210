import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionFees from './SubscriptionFees'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { FEE_TABLE_ITEM_PATH } from 'constants/pathConstants'
import { FEE_LINKED_TYPE_SUBSCRIPTION } from 'constants/feeConstants'
import { MERCHANT_ID } from 'constants/filterConstants'
import get from 'lodash/get'

const allowedFilters = [MERCHANT_ID]
const initialQueries = { linked_type: FEE_LINKED_TYPE_SUBSCRIPTION }

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const feePath = ({ settlementId, id }) => FEE_TABLE_ITEM_PATH({ credentialId, settlementId, id })

  return {
    isFetching,
    initialQueries,
    feePath,
    allowedFilters,
  }
}

class SubscriptionFeesC extends Component {
  render() {
    return (
      <SubscriptionFees
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SubscriptionFeesC)
