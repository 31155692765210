import { LOCATION_CHANGE } from 'react-router-redux'
import get from 'lodash/get'

const mobileNavR = (state = {}, action = {}) => {
  switch (get(action, 'type')) {
    case 'SET_MOBILE_NAV_OPEN': {
      return {
        open: get(action, 'payload.open', false),
      }
    }

    case LOCATION_CHANGE:
      return {
        open: false,
      }

    default:
      return state
  }
}

export default mobileNavR
