import React, { Component } from 'react'
import ApplePayCertManagement from './ApplePayCertManagement'
import UnregisterApplePayDomainModal from 'components/Customer/Modals/UnregisterApplePayDomainModal/UnregisterApplePayDomainModal'
import RevokeApplePayCertModal from 'components/Customer/Modals/RevokeApplePayCertModal/RevokeApplePayCertModal'
import Button from 'components/Shared/Button/Button'
import unregisterApplePayDomainRequest from 'utilities/actions/patch/unregisterApplePayDomainRequest'
import getCertificateSigningRequestsRequest from 'utilities/actions/get/getCertificateSigningRequestsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import { ADD_ICON } from 'constants/iconConstants'
import { PENDING } from 'constants/statusConstants'
import { UPDATE_FLOW_STATUS } from 'constants/actionConstants'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

import {
  ADD_NEW_IOS_APPLICATION_MODAL,
  REVOKE_APPLE_PAY_CERT_MODAL,
} from 'constants/modalConstants'


const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `applePayCertsR.${FETCHING}`)
  const applePayCerts = get(state, 'applePayCertsR.items')
  let hasPendingCert = false

  if (applePayCerts) {
    // TODO: find more efficient way to handle this
    forEach(Object.values(applePayCerts), (cert) => {
      if (get(cert, 'status') === PENDING) {
        hasPendingCert = true
      }
    })
  }

  const paymentMethodConfigId = get(state, 'applePayRegisteredMerchantR.items.id')

  const initialQueries = {
    linked_to: identityId,
  }

  return {
    credentials,
    initialQueries,
    identityId,
    isFetching,
    paymentMethodConfigId,
    hasPendingCert,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlowStatus: (payload) => dispatch({ type: UPDATE_FLOW_STATUS, payload }),
    showAddIOSApplicationModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_NEW_IOS_APPLICATION_MODAL, modalProps })),
    showRevokeApplePayCertModal: (modalProps) => dispatch(showModalAction({ modalType: REVOKE_APPLE_PAY_CERT_MODAL, modalProps })),
  }
}


class ApplePayCertManagementC extends Component {
  // TODO: revoke apple pay cert code commented out until the BE API changes are ready
  // showRevokeApplePayCert = (rowData) => {
  //   const {
  //     unregisterApplePayDomains,
  //     credentials,
  //     paymentMethodConfigId,
  //     identityId,
  //     revokeApplePayCert,
  //     showRevokeApplePayCertModal,
  //   } = this.props
  //
  //   const description = <RevokeApplePayCertModal appName={appName} />
  //   const message = 'Revoke iOS app certificate?'
  //   const confirmationAction = () => revokeApplePayCert({ appName, credentials })
  //   const customButtonGroup = <Button onClick={confirmationAction} label='Revoke Certificate' variant='destructive' />
  //   showRevokeApplePayCertModal({ message, description, customButtonGroup })
  // }

  showResumeModal = (rowData) => {
    const {
      showAddIOSApplicationModal,
      setFlowStatus,
      identityId,
      hasPendingCert,
    } = this.props

    const rowApplicationName = get(rowData, 'name')

    setFlowStatus({ activeStep: 1 })
    showAddIOSApplicationModal({ identityId, hasPendingCert, resumeProcess: true, rowApplicationName })
  }

  render() {
    const {
      identityId,
      showAddIOSApplicationModal,
      hasPendingCert,
      showRevokeApplePayCertModal,
    } = this.props

    const actions = [
      {
        label: 'Add iOS Application',
        action: () => {
          showAddIOSApplicationModal({ identityId, hasPendingCert, showResumeModal: this.showResumeModal })
        },
        className: 'add-ios-application-btn',
      },
    ]

    const selectedItemActions = [this.showResumeModal, showRevokeApplePayCertModal]

    return (
      <ApplePayCertManagement
        {...this.props}
        selectedItemActions={selectedItemActions}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayCertManagementC)
