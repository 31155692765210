import { POST_SUBSCRIPTION_PLAN_F_REQUEST } from 'constants/flowConstants'
import { SUBSCRIPTION_PLAN_PATH } from 'constants/pathConstants'
import redirectRequest from 'utilities/actions/redirectRequest'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitCreateSubscriptionPlanForm = (values, dispatch, props) => {
  const {
    credentials,
    linkedTo,
  } = props

  const {
    planName,
    description,
    amount,
    billingInterval,
    enableTrialPhase,
    trialIntervalType,
    trialIntervalCount,
  } = values

  const credentialId = get(credentials, 'id')
  const billingIntervalValue = get(billingInterval, 'value')
  const trialIntervalTypeValue = get(trialIntervalType, 'value')

  const valuesToSubmit = {
    planName,
    description,
    linkedTo,
    amount,
    billingInterval: billingIntervalValue,
    enableTrialPhase,
    trialIntervalType: trialIntervalTypeValue,
    trialIntervalCount,
  }

  dispatch({
    type: POST_SUBSCRIPTION_PLAN_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const subscriptionPlanId = head(keys(newValues))
        dispatch(redirectRequest({ path: SUBSCRIPTION_PLAN_PATH({ credentialId, subscriptionPlanId }) }))
      },
    },
  })
}

export default submitCreateSubscriptionPlanForm
