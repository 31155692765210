import './HostedMerchantOnboardingConfirmationS.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { ReactComponent as PeoplesBankLogo } from 'img/svg/peoples-bank-logo.svg'
import Button from 'components/Shared/Button/Button'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import submitOnboardingForm from 'utilities/submit/submitOnboardingForm'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import validateMerchantIdentityConfirmationForm from 'utilities/validate/validateMerchantIdentityConfirmationForm'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import { MERCHANT_IDENTITY_CONFIRMATION_FORM } from 'constants/formConstants'
import { INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE } from 'constants/identityConstants'
import { ALERT_ICON, CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { EDD_FILES } from 'constants/eddDocumentConstants'
import { LIVE_HOST } from 'constants/dashboardServiceConstants'
import { CAN } from 'constants/countryConstants'
import toUpper from 'lodash/toUpper'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  fileStatusToIcon,
  fileStatusToLabel,
} from 'constants/fileConstants'

import {
  SANDBOX_ENV,
  PRODUCTION_ENV,
  initializePersonaClient,
} from 'constants/personaConstants'

import {
  t,
  BUSINESS_INFORMATION,
  EDIT,
  CONTROL_PERSON,
  CONTROL_PERSON_EMPTY_MESSAGE,
  BENEFICIAL_OWNERSHIP,
  BENEFICIAL_OWNERS_EMPTY_MESSAGE,
  PROCESSING_INFORMATION,
  CARD_VOLUME,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  BANK_ACCOUNT_INFORMATION,
  SUBMIT,
  REVIEW_AND_SUBMIT,
  PREVIOUS_STEP,
  REVIEW_AND_SUBMIT_CONFIRMATION_MESSAGE,
  TERMS_AND_CONDITIONS,
  GENERAL_INFORMATION,
  PEOPLES_TRUST_COMPANY,
  PEOPLES_TRUST_COMPANY_ADDRESS,
  AMEX_MARKETING_CONSENT_CAN_CHECKBOX_LABEL,
  AMEX_MARKETING_CONSENT_USA_CHECKBOX_LABEL,
  VERIFY,
  PERSONAL_IDENTITY_VERIFICATION,
  REQUIRED,
  VERIFICATION_STATUS,
  SUBMITTED,
  CONTROL_PERSON_PREFERRED,
  PROVIDE_GOVERNMENT_ISSUED_ID_AND_SELFIE,
  COLLECTING_GOVERNMENT_ID_AND_SELFIE,
  USE_A_LAPTOP_OR_MOBILE_DEVICE,
  FILE_NAME,
  STATUS,
  TYPE,
  FAILED,
} from 'constants/language/languageConstants'

const HostedMerchantOnboardingConfirmation = ({
  goToStep = () => {},
  leftButtonAction = () => {},
  handleSubmit = () => {},
  invalid = false,
  businessInfoData = [],
  controlPersonData = [],
  beneficialOwnersData = [],
  processingDetailsData = [],
  cardVolumeData = [],
  paymentVolumeData = [],
  bankAccountData = [],
  merchantOnboardingConfirmationCheckboxString = '',
  businessType = '',
  isFetchingOnboardingFormData = false,
  country = '',
  isBoardingToFinixV1Only = false,
  personaInquiryOnComplete = () => {},
  inquiryId: formInquiryId,
  biometricDataCollectionRequired = false,
  biometricDataNotCollected = false,
  groupedEDDFiles = {},
}) => {
  const currentPlatform = getCurrentPlatform() || {}
  const { credentialsHostV2 } = currentPlatform
  const isProduction = credentialsHostV2 === LIVE_HOST
  const [client, setClient] = useState(null)
  const [clientLoaded, setClientLoaded] = useState(false)

  useEffect(() => {
    if (!client) {
      setClient(initializePersonaClient({
        onReady: () => setClientLoaded(true),
        onComplete: personaInquiryOnComplete,
        environmentId: isProduction ? PRODUCTION_ENV : SANDBOX_ENV,
      }))
    }
  }, [])

  const openClient = () => {
    if (clientLoaded) {
      client.open()
    }
  }

  const identityVerificationActions = !formInquiryId ? [{
    label: VERIFY,
    variant: 'primary',
    action: () => openClient(),
  }] : []

  return (
    <form className='HostedMerchantOnboardingConfirmation' onSubmit={handleSubmit(submitOnboardingForm)}>
      <div className='form-header'>
        <h3>{t(REVIEW_AND_SUBMIT)}</h3>
      </div>

      <div className={`form-content ${country === CAN ? 'canada-form' : ''}`}>
        <div className='form-content-body'>
          <div className='confirmation-message flex'>
            <i className={`fa fa-${ALERT_ICON}`} />
            <div className='p-1'>{t(REVIEW_AND_SUBMIT_CONFIRMATION_MESSAGE)}</div>
          </div>

          { !biometricDataNotCollected && (
            <PageSectionV2C
              smallTitle={PERSONAL_IDENTITY_VERIFICATION}
              asideTitle={biometricDataCollectionRequired ? <div className='identity-verification-badge'><i className='fas fa-star' /><span className='p-2-bold'>{REQUIRED}</span></div> : null}
              actions={identityVerificationActions}
            >
              { formInquiryId ? (
                <div className='mt-xl flex gap-32'>
                  <span>{t(VERIFICATION_STATUS)}</span>
                  <span>{t(SUBMITTED)}</span>
                </div>
              ) : (
                <>
                  <div className='label-2 secondary'>{t(COLLECTING_GOVERNMENT_ID_AND_SELFIE)}</div>

                  <div className='identity-verification-details'>
                    <div>
                      <i className='far fa-id-card' />
                      <span className='p-2'>{t(PROVIDE_GOVERNMENT_ISSUED_ID_AND_SELFIE)}</span>
                    </div>

                    <div>
                      <i className='far fa-user-shield' />
                      <span className='p-2'>{t(CONTROL_PERSON_PREFERRED)}</span>
                    </div>

                    <div>
                      <i className='far fa-camera' />
                      <span className='p-2'>{t(USE_A_LAPTOP_OR_MOBILE_DEVICE)}</span>
                    </div>

                    <Field name='inquiryId' component='input' type='hidden' />
                  </div>
                </>
              )}
            </PageSectionV2C>
          )}

          <PageSectionV2C
            smallTitle={BUSINESS_INFORMATION}
            columns={convertPageSectionDataToV2(businessInfoData, 1)}
            actions={[{ label: EDIT, action: () => goToStep(0) }]}
          />

          <PageSectionV2C
            smallTitle={CONTROL_PERSON}
            columns={controlPersonData}
            actions={[{ label: EDIT, action: () => goToStep(1) }]}
            emptyMessage={CONTROL_PERSON_EMPTY_MESSAGE}
          />

          {businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE && (
            <PageSectionV2C
              smallTitle={BENEFICIAL_OWNERSHIP}
              columns={beneficialOwnersData}
              actions={[{ label: EDIT, action: () => goToStep(1) }]}
              className='beneficial-owners-section'
              emptyMessage={BENEFICIAL_OWNERS_EMPTY_MESSAGE}
            />
          )}

          <PageSectionV2C
            smallTitle={PROCESSING_INFORMATION}
            actions={[{ label: EDIT, action: () => goToStep(2) }]}
          >
            <>
              <h6>{GENERAL_INFORMATION}</h6>
              <PageSectionV2C
                columns={convertPageSectionDataToV2(processingDetailsData, 1)}
                className='general-information-section'
              />

              <h6>{CARD_VOLUME}</h6>
              <PageSectionV2C
                columns={convertPageSectionDataToV2(cardVolumeData, 1)}
                className='card-volume-section'
              />

              <h6>{PAYMENT_VOLUME_BY_CUSTOMER_TYPE}</h6>
              <PageSectionV2C
                columns={convertPageSectionDataToV2(paymentVolumeData, 1)}
                className='payment-volume-section'
              />

              { !isEmpty(groupedEDDFiles) && (
                <Accordion title='Additional EDD Documents'>
                  <div className='edd-table'>
                    <div className='header'>{t(FILE_NAME)}</div>
                    <div className='header'>{t(STATUS)}</div>
                    <div className='header'>{t(TYPE)}</div>

                    { map(groupedEDDFiles, (files, fileType) => (
                      <>
                        <div className='sub-header'>{get(EDD_FILES, [fileType, 'name'])}</div>
                        { map(files, ({ id, display_name: fileName, type, fileData }) => {
                          const fileStatus = get(fileData, 'status', '')
                          const statusIcon = fileStatusToIcon(fileStatus) || 'fa-times-circle'
                          const displayStatus = fileStatusToLabel(fileStatus) || FAILED
                          const extension = toUpper(get(fileData, 'extension', ''))

                          return (
                            <>
                              <div>{fileName}</div>
                              <div><i className={`fa ${statusIcon}`} />{t(displayStatus)}</div>
                              <div>{extension}</div>
                            </>
                          )
                        })}
                      </>
                    ))}
                  </div>
                </Accordion>
              ) }
            </>
          </PageSectionV2C>

          <PageSectionV2C
            smallTitle={BANK_ACCOUNT_INFORMATION}
            columns={convertPageSectionDataToV2(bankAccountData, 1)}
            actions={[{ label: EDIT, action: () => goToStep(3) }]}
          />

          <div className='PageSectionV2 terms-and-conditions'>
            <h5>{TERMS_AND_CONDITIONS}</h5>

            <Field
              name='merchantAgreementAccepted'
              checkboxLabel={merchantOnboardingConfirmationCheckboxString}
              component={CheckboxInput}
            />

            { isBoardingToFinixV1Only && (
              <Field
                name='amexMarketingConsentAccepted'
                checkboxLabel={country === CAN ? AMEX_MARKETING_CONSENT_CAN_CHECKBOX_LABEL : AMEX_MARKETING_CONSENT_USA_CHECKBOX_LABEL}
                component={CheckboxInput}
              />
            )}
          </div>
        </div>

        { country === CAN && (
          <div className='branding-footer flex row space-between items-center'>
            <div className='company-address-container'>
              <p className='p-3-bold'>{PEOPLES_TRUST_COMPANY}</p>
              <p className='p-3'>{PEOPLES_TRUST_COMPANY_ADDRESS}</p>
            </div>

            <div className='image-container'>
              <PeoplesBankLogo />
            </div>
          </div>
        ) }
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button variant='tertiary' onClick={leftButtonAction} label={PREVIOUS_STEP} />
          </div>
          <div className='exit-and-save-container'>
            <Button type='submit' label={SUBMIT} disabled={invalid} submitting={isFetchingOnboardingFormData} />
          </div>
        </div>
      </div>
    </form>
  )
}

HostedMerchantOnboardingConfirmation.propTypes = {
  goToStep: PropTypes.func,
  leftButtonAction: PropTypes.func,
  invalid: PropTypes.bool,
  businessInfoData: PropTypes.array,
  controlPersonData: PropTypes.array,
  beneficialOwnersData: PropTypes.array,
  processingDetailsData: PropTypes.array,
  cardVolumeData: PropTypes.array,
  paymentVolumeData: PropTypes.array,
  bankAccountData: PropTypes.array,
  merchantOnboardingConfirmationCheckboxString: PropTypes.string,
  businessType: PropTypes.string,
  isFetchingOnboardingFormData: PropTypes.bool,
  country: PropTypes.string,
  isBoardingToFinixV1Only: PropTypes.bool,
  personaInquiryOnComplete: PropTypes.func,
  inquiryId: PropTypes.string,
  biometricDataCollectionRequired: PropTypes.bool,
  biometricDataNotCollected: PropTypes.bool,
  groupedEDDFiles: PropTypes.object,
}

export default reduxForm({
  form: MERCHANT_IDENTITY_CONFIRMATION_FORM,
  validate: validateMerchantIdentityConfirmationForm,
})(HostedMerchantOnboardingConfirmation)
