import './CreateBalanceAdjustmentFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import validateCreateBalanceAdjustmentForm from 'utilities/validate/validateCreateBalanceAdjustmentForm'
import { CREATE_BALANCE_ADJUSTMENT_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'

import {
  ADD_FUNDS_FROM_LABEL,
  ADD_NOTE_TO_BALANCE_ADJUSTMENT_PLACEHOLDER,
  AMOUNT,
  CANCEL,
  CREATE_A_BALANCE_ADJUSTMENT,
  CREATE_A_BALANCE_ADJUSTMENT_SUBTITLE,
  NOTE,
  REVIEW,
} from 'constants/language/languageConstants'

const CreateBalanceAdjustmentForm = ({
  handleSubmit = () => {},
  invalid = false,
  ownerIdentityPaymentInstrumentOptions = [],
  isFetching = false,
  isUpdating = false,
  reactSelectCustomStyles = {},
  applicationBalanceAdjustmentMaxTransactionAmount = '',
}) => {
  const customOptionStyles = get(reactSelectCustomStyles, 'customOption', {})

  return (
    <form className='CreateBalanceAdjustmentForm' onSubmit={handleSubmit}>
      <div className='form-header p-1'>
        <h3 className='title'>{CREATE_A_BALANCE_ADJUSTMENT}</h3>
        <div className='p-1'>{CREATE_A_BALANCE_ADJUSTMENT_SUBTITLE}</div>
      </div>

      { isFetching && <Loader />}

      {!isFetching && (
        <>
          <RequiredFieldNotice />

          <div className='form-content'>
            <AmountField
              name='amount'
              label={AMOUNT}
              placeholder='0.00'
              contextLabel={USD}
              required
            />

            <Field
              name='instrumentId'
              label={ADD_FUNDS_FROM_LABEL}
              component={ReactSelect}
              options={ownerIdentityPaymentInstrumentOptions}
              customStyles={{ option: (styles, state) => (state.data.value === 'newBankAccount' ? customOptionStyles : {}) }}
            />

            <Field
              name='description'
              label={NOTE}
              component={TextAreaField}
              placeholder={ADD_NOTE_TO_BALANCE_ADJUSTMENT_PLACEHOLDER}
              characterLimit={400}
            />
          </div>
        </>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' submitting={isUpdating} disabled={invalid} label={REVIEW} className='submit-button' />
          </div>
        </div>
      </div>
    </form>
  )
}

CreateBalanceAdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  ownerIdentityPaymentInstrumentOptions: PropTypes.array,
  isFetching: PropTypes.bool,
  isUpdating: PropTypes.bool,
  reactSelectCustomStyles: PropTypes.object,
  applicationBalanceAdjustmentMaxTransactionAmount: PropTypes.string,
}

export default reduxForm({
  form: CREATE_BALANCE_ADJUSTMENT_FORM,
  validate: validateCreateBalanceAdjustmentForm,
})(CreateBalanceAdjustmentForm)
