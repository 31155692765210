import React, { Component } from 'react'
import { connect } from 'react-redux'
import Roles from './Roles'
import { getActiveOrderedRolesSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import columnDescriptors from './columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import createUrl from 'utilities/create/createUrl'
import { FETCHING } from 'constants/reducerConstants'
import { ROLE_STATE } from 'constants/filterConstants'
import { ROLE_TYPE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { DISABLE_CUSTOM_ROLE_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import size from 'lodash/size'

import {
  ADD_ROLE_PATH,
  ROLE_PATH,
} from 'constants/pathConstants'

const allowedFilters = [
  ROLE_STATE,
]

const quickFilters = [
  ROLE_TYPE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `rolesR.${FETCHING}`)
  const entityId = get(props, 'entityId')
  const entityType = get(props, 'entityType')
  const authRole = get(props, 'authRole')
  const queries = get(props, 'queries', {})
  const activeRoles = getActiveOrderedRolesSelector(state)
  const rolesCount = size(activeRoles)

  const rolePath = (data) => {
    const roleId = get(data, 'id')

    return createUrl({
      url: ROLE_PATH({ credentialId, roleId }),
      queries: {
        entityId,
        entityType,
      },
    })
  }

  const initialQueries = {
    entity_id: entityId,
    auth_role: authRole,
  }

  return {
    credentials,
    credentialId,
    isFetching,
    initialQueries: merge({}, initialQueries, queries),
    quickFilters,
    allowedFilters,
    entityDataFound: !!entityId,
    rolePath,
    rolesCount,
    entityId,
    entityType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showDisableCustomRoleModal: (modalProps) => dispatch(showModalAction({ modalType: DISABLE_CUSTOM_ROLE_MODAL, modalProps })),
  }
}

class RolesC extends Component {
  render() {
    const {
      credentialId,
      entityId,
      entityType,
      showDisableCustomRoleModal,
      rolesCount,
    } = this.props

    const actions = rolesCount < 60 ? [
      {
        label: 'Create Custom Role',
        action: () => goToPath({
          pathname: ADD_ROLE_PATH({ credentialId }),
          queries: {
            entityType,
            entityId,
          },
        }),
        className: 'create-custom-role-button',
      },
    ] : []

    const columnActions = {
      showDisableCustomRoleModal,
    }

    const normalizedColumnDescriptors = columnDescriptors({ columnActions })

    return (
      <Roles
        {...this.props}
        actions={actions}
        columnDescriptors={normalizedColumnDescriptors}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesC)
