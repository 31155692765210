import trim from 'lodash/trim'

import {
  fieldEmpty,
  validateBasicAuthPassword,
  validateWebsite,
} from 'utilities/validate'

import {
  URL,
  USERNAME,
  BEARER_TOKEN,
  ENDPOINT,
  WEBHOOK_EMPTY_EVENTS_VALIDATION,
  WEBHOOK_NICKNAME_VALIDATION_MSG,
} from 'constants/language/languageConstants'

const validateWebhookForm = ({
  endpointUrl,
  basicAuthenticationUsername,
  basicAuthenticationPassword,
  bearerToken,
  nickname = '',
}, props) => {
  const {
    editMode,
    disableBasicAuthorizationInput,
    disableBearerTokenInput,
    noItemsSelected,
  } = props

  const invalidWebsite = validateWebsite({ field: trim(endpointUrl), name: ENDPOINT })
  const shouldValidateBasicAuth = (!editMode || (editMode && !disableBasicAuthorizationInput))

  return {
    endpointUrl: fieldEmpty(endpointUrl, URL) || invalidWebsite,
    basicAuthenticationUsername: shouldValidateBasicAuth ? fieldEmpty(basicAuthenticationUsername, USERNAME) : false,
    basicAuthenticationPassword: shouldValidateBasicAuth ? validateBasicAuthPassword({ password: basicAuthenticationPassword }) : false,
    bearerToken: (!editMode || (editMode && !disableBearerTokenInput)) ? fieldEmpty(bearerToken, BEARER_TOKEN) : false,
    selectedEvents: noItemsSelected && WEBHOOK_EMPTY_EVENTS_VALIDATION,
    nickname: nickname.length > 75 && WEBHOOK_NICKNAME_VALIDATION_MSG,
  }
}

export default validateWebhookForm
