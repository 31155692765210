import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import lowerCase from 'lodash/lowerCase'

const createCategorizedReportsObject = (reports) => {
  return reduce(reports, (result, value) => {
    const { displayCategory: category, displaySubcategory: subcategory, fileName, period } = value

    return merge({}, result, {
      [category]: {
        [subcategory]: {
          [fileName]: {
            [lowerCase(period)]: value,
          },
        },
      },
    })
  }, {})
}

export default createCategorizedReportsObject
