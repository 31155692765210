import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import head from 'lodash/head'
import size from 'lodash/size'

import {
  SUCCESS as FLASH_SUCCESS,
  FAILURE as FLASH_FAILURE,
  WARNING as FLASH_WARNING,
  MESSAGE as FLASH_MESSAGE,
  DEFAULT_TIME,
} from 'constants/flashNotificationConstants'

import {
  CHANGE_PASSWORD_SUCCESS_MESSAGE,
  EDIT_DASHBOARD_USER_NAME_SUCCESS_MESSAGE,
  UNDERWRITING_AUTOMATION_UPDATES_ERROR_MESSAGE,
  UNDERWRITING_AUTOMATION_UPDATES_SUCCESS_MESSAGE,
  WITHDRAW_APPLICATION_FORM_SUCCESS_MSG,
} from 'constants/language/languageConstants'

import {
  DEACTIVATE_VALUE,
  ACTIVATE_VALUE,
} from 'constants/deviceConstants'

import {
  FLASH_NOTIFICATION,
  HIDE_FLASH_NOTIFICATION,
  POST_IDENTITY_F_SUCCESS,
  POST_IDENTITY_F_FAILURE,
  POST_IDENTITY_SETTLEMENTS_F_SUCCESS,
  POST_IDENTITY_SETTLEMENTS_F_FAILURE,
  POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
  POST_IDENTITY_PAYMENT_INSTRUMENTS_F_FAILURE,
  POST_IDENTITY_MERCHANTS_F_SUCCESS,
  POST_IDENTITY_MERCHANTS_F_FAILURE,
  POST_IDENTITY_VERIFICATIONS_F_SUCCESS,
  POST_IDENTITY_VERIFICATIONS_F_FAILURE,
  POST_APPLICATION_PROCESSORS_F_SUCCESS,
  POST_APPLICATION_PROCESSORS_F_FAILURE,
  PATCH_TRANSFER_F_SUCCESS,
  PATCH_TRANSFER_F_FAILURE,
  PATCH_IDENTITY_F_SUCCESS,
  PATCH_IDENTITY_F_FAILURE,
  PATCH_APPLICATION_F_SUCCESS,
  PATCH_APPLICATION_F_FAILURE,
  PATCH_REVIEW_QUEUE_ITEMS_F_SUCCESS,
  PATCH_REVIEW_QUEUE_ITEMS_F_FAILURE,
  POST_FEE_PROFILE_F_SUCCESS,
  POST_FEE_PROFILE_F_FAILURE,
  GET_DASHBOARD_USER_F_SUCCESS_NOTIFICATION,
  GET_DASHBOARD_USER_F_FAILURE,
  GET_DASHBOARD_USER_F_FAILURE_NOTIFICATION,
  POST_DASHBOARD_USER_F_SUCCESS,
  POST_DASHBOARD_USER_F_FAILURE,
  POST_ADMIN_USER_F_SUCCESS,
  POST_ADMIN_USER_F_FAILURE,
  POST_ADMIN_USER_MEMBERSHIPS_F_SUCCESS,
  POST_ADMIN_USER_MEMBERSHIPS_F_FAILURE,
  POST_USERS_EMAIL_F_SUCCESS,
  POST_USERS_EMAIL_F_FAILURE,
  POST_DASHBOARD_V2_EMAILS_F_SUCCESS,
  POST_DASHBOARD_V2_EMAILS_F_FAILURE,
  POST_TRANSFER_REVERSAL_F_SUCCESS,
  POST_TRANSFER_REVERSAL_F_FAILURE,
  DELETE_SETTLEMENT_ENTRIES_F_SUCCESS,
  DELETE_SETTLEMENT_ENTRIES_F_FAILURE,
  POST_MERCHANT_VERIFICATIONS_F_SUCCESS,
  POST_MERCHANT_VERIFICATIONS_F_FAILURE,
  POST_MERCHANT_FEE_PROFILE_F_SUCCESS,
  POST_MERCHANT_FEE_PROFILE_F_FAILURE,
  PATCH_MERCHANT_F_SUCCESS,
  PATCH_MERCHANT_F_FAILURE,
  POST_APPLICATION_ASSOCIATED_IDENTITIES_F_SUCCESS,
  POST_APPLICATION_ASSOCIATED_IDENTITIES_F_FAILURE,
  PATCH_AUTHORIZATION_F_SUCCESS,
  PATCH_AUTHORIZATION_F_FAILURE,
  PATCH_PAYMENT_INSTRUMENT_F_SUCCESS,
  PATCH_PAYMENT_INSTRUMENT_F_FAILURE,
  PATCH_RISK_PROFILE_F_FAILURE,
  POST_TRANSFER_F_SUCCESS,
  POST_TRANSFER_F_FAILURE,
  PATCH_WELCOME_EMAIL_TEMPLATE_F_SUCCESS,
  PATCH_WELCOME_EMAIL_TEMPLATE_F_FAILURE,
  POST_DASHBOARD_FILE_F_SUCCESS,
  POST_DASHBOARD_FILE_F_FAILURE,
  PATCH_SETTLEMENT_V1_F_SUCCESS,
  PATCH_SETTLEMENT_V1_F_FAILURE,
  PATCH_PLATFORM_PROCESSOR_CONFIG_F_SUCCESS,
  PATCH_PLATFORM_PROCESSOR_CONFIG_F_FAILURE,
  GET_SEARCH_F_FAILURE,
  PATCH_USER_PERMISSIONS_F_SUCCESS,
  PATCH_USER_PERMISSIONS_F_FAILURE,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE,
  POST_IDENTITY_UPLOAD_F_FAILURE,
  POST_IDENTITY_UPLOAD_F_SUCCESS,
  PATCH_SETTLEMENT_F_SUCCESS,
  GET_WELCOME_EMAIL_TEMPLATE_F_FAILURE,
  POST_PAYOUT_PLAN_F_SUCCESS,
  POST_PAYOUT_PLAN_F_FAILURE,
  CLOSE_SETTLEMENTS_F_FAILURE,
  POST_USER_F_SUCCESS,
  POST_USER_F_FAILURE,
  PATCH_USER_F_SUCCESS,
  PATCH_USER_F_FAILURE,
  POST_BALANCE_TRANSFER_F_SUCCESS,
  POST_BALANCE_TRANSFER_F_FAILURE,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_FAILURE,
  DELETE_SUBSCRIPTION_ENROLLMENT_F_SUCCESS,
  DELETE_SUBSCRIPTION_ENROLLMENT_F_FAILURE,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE,
  POST_FEE_F_SUCCESS,
  POST_FEE_F_FAILURE,
  PATCH_FEE_F_SUCCESS,
  PATCH_FEE_F_FAILURE,
  POST_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS,
  PATCH_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS,
  PATCH_WEBHOOK_F_SUCCESS,
  PATCH_WEBHOOK_F_FAILURE,
  POST_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS,
  POST_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE,
  PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS,
  PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE,
  POST_MERCHANT_FILE_F_FAILURE,
  POST_MERCHANT_FILE_F_SUCCESS,
  POST_IDENTITY_FILE_F_FAILURE,
  POST_IDENTITY_FILE_F_SUCCESS,
  POST_DASHBOARD_FEEDBACK_EMAIL_F_SUCCESS,
  POST_DASHBOARD_FEEDBACK_EMAIL_F_FAILURE,
  POST_CONTACT_SUPPORT_EMAIL_F_SUCCESS,
  POST_CONTACT_SUPPORT_EMAIL_F_FAILURE,
  POST_CONTACT_SALES_EMAIL_F_SUCCESS,
  POST_CONTACT_SALES_EMAIL_F_FAILURE,
  PATCH_EMAIL_TEMPLATE_F_SUCCESS,
  PATCH_EMAIL_TEMPLATE_F_FAILURE,
  POST_ONBOARDING_FORM_DATA_F_SUCCESS,
  PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_SUCCESS,
  PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_FAILURE,
  PATCH_COMPLIANCE_FORM_F_FAILURE,
  PATCH_COMPLIANCE_FORM_F_SUCCESS,
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS,
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_FAILURE,
  PATCH_COMPLIANCE_SETTINGS_F_SUCCESS,
  PATCH_COMPLIANCE_SETTINGS_F_FAILURE,
  POST_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS,
  POST_COMPLIANCE_FORM_TEMPLATE_F_FAILURE,
  PATCH_PAYOUT_SETTINGS_F_SUCCESS,
  PATCH_PAYOUT_SETTINGS_F_FAILURE,
  POST_NOTIFICATION_SETTINGS_F_SUCCESS,
  POST_NOTIFICATION_SETTINGS_F_FAILURE,
  POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_SUCCESS,
  POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_FAILURE,
  POST_SELF_SERVICE_RESEND_EMAIL_F_SUCCESS,
  POST_SELF_SERVICE_RESEND_EMAIL_F_FAILURE,
  PATCH_CREDENTIAL_F_SUCCESS,
  PATCH_CREDENTIAL_F_FAILURE,
  POST_MEMBER_F_SUCCESS,
  POST_MEMBER_F_FAILURE,
  POST_ROLE_F_SUCCESS,
  POST_ROLE_F_FAILURE,
  PATCH_ROLE_F_SUCCESS,
  PATCH_ROLE_F_FAILURE,
  POST_CHANGE_PASSWORD_F_SUCCESS,
  PATCH_DASHBOARD_USER_F_SUCCESS,
  POST_SUBMIT_ACCESS_FORM_F_SUCCESS,
  DELETE_ACCESS_FORM_F_SUCCESS,
  DELETE_API_KEY_F_SUCCESS,
  PATCH_UNDERWRITING_PROFILE_F_SUCCESS,
  PATCH_UNDERWRITING_PROFILE_F_FAILURE,
  POST_UNDERWRITING_PROFILE_F_SUCCESS,
  PATCH_DISPUTE_F_SUCCESS,
  PATCH_DISPUTE_F_FAILURE,
  PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_FAILURE,
  POST_DASHBOARD_USER_MFA_VERIFY_F_SUCCESS,
  POST_ENTITY_CONFIGURATION_F_SUCCESS,
  PATCH_ENTITY_CONFIGURATION_F_SUCCESS,
  POST_DASHBOARD_USER_MFA_RESET_F_SUCCESS,
  POST_BALANCE_ENTRY_F_SUCCESS,
  POST_BALANCE_ENTRY_F_FAILURE,
  POST_DISBURSEMENT_RULES_F_SUCCESS,
  SHOW_SUCCESS_FLASH_NOTIFICATION,
  SHOW_ERROR_FLASH_NOTIFICATION,
  SHOW_API_ERROR_FLASH_NOTIFICATION,
  PATCH_MERCHANT_RISK_PROFILE_F_FAILURE,
  PATCH_MERCHANT_RISK_PROFILE_F_SUCCESS,
  POST_UNDERWRITING_REVIEW_RUNS_F_SUCCESS,
  POST_DEVICE_F_SUCCESS,
  PATCH_DEVICE_F_SUCCESS,
  PATCH_DEVICE_F_FAILURE,
  GET_DEVICE_F_SUCCESS,
  GET_DEVICE_F_FAILURE,
  PATCH_UNDERWRITING_WORKFLOW_F_SUCCESS,
  PATCH_UNDERWRITING_WORKFLOW_F_FAILURE,
  POST_COMPLIANCE_FORM_F_SUCCESS,
  POST_COMPLIANCE_FORM_F_FAILURE,
  PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS,
  POST_RECIPIENT_IDENTITY_F_SUCCESS,
  POST_RECIPIENT_IDENTITY_F_FAILURE,
  POST_SUBMIT_TO_UNDERWRITING_F_SUCCESS,
  POST_REQUEST_CHANGES_F_SUCCESS,
  PATCH_PAYOUT_LINK_F_SUCCESS,
  POST_DISPUTE_F_SUCCESS,
  POST_DISPUTE_F_FAILURE,
  PATCH_UPDATE_DISPUTE_F_SUCCESS,
  PATCH_UPDATE_DISPUTE_F_FAILURE,
  POST_RECEIPT_DELIVERY_ATTEMPTS_F_SUCCESS,
  POST_RECEIPT_DELIVERY_ATTEMPTS_F_FAILURE,
  POST_SUBSCRIPTION_PLAN_F_SUCCESS,
  POST_SUBSCRIPTION_F_SUCCESS,
  PATCH_SUBSCRIPTION_PLAN_F_SUCCESS,
  DELETE_SUBSCRIPTION_F_SUCCESS,
  PATCH_SUBSCRIPTION_F_SUCCESS,
  POST_RECEIPT_F_FAILURE,
  POST_RECEIPT_F_SUCCESS,
  PATCH_MONITORING_ALERTS_F_SUCCESS,
  PATCH_MONITORING_ALERT_F_SUCCESS,
  POST_INSTANT_PAYOUT_F_SUCCESS,
  POST_INSTANT_PAYOUT_F_FAILURE,
} from 'constants/flowConstants'

const showMessage = ({
  type,
  message,
  time,
  hide = false,
  title,
  icon,
}) => {
  return {
    type,
    message,
    time,
    hide,
    title,
    icon,
  }
}

const successMessage = (message, time = DEFAULT_TIME, icon) => showMessage({
  type: FLASH_SUCCESS,
  time,
  title: message,
  icon,
})

const failMessage = (message, time = DEFAULT_TIME, icon) => showMessage({
  type: FLASH_FAILURE,
  time,
  title: message,
  icon,
})

const flashNotificationsR = (state = {}, action = {}) => {
  // TODO: do not require values, just get from payload
  const payload = get(action, 'payload')
  const values = get(action, 'payload.values')
  const errors = get(action, 'errors', [])
  const newValues = get(action, 'payload.newValues')
  const errorMessage = get(head(errors), 'message')
  const hideFlashNotification = get(action, 'meta.hideFlashNotification', false)

  if (hideFlashNotification) {
    return state
  }

  const [
    type = '',
    message = '',
    time = DEFAULT_TIME,
    title = '',
    icon,
  ] = getMany(values, [
    'type',
    'message',
    'time',
    'title',
    'icon',
  ])

  switch (action.type) {
    case FLASH_NOTIFICATION:
      return showMessage({
        type,
        message,
        time,
        title,
        icon,
      })

    case HIDE_FLASH_NOTIFICATION:
      return showMessage({
        ...state,
        hide: true,
      })

    case SHOW_SUCCESS_FLASH_NOTIFICATION:
      return successMessage(message, time, icon)

    case SHOW_ERROR_FLASH_NOTIFICATION:
      return failMessage(message, time, icon)

    case SHOW_API_ERROR_FLASH_NOTIFICATION:
      return failMessage(message, time, icon)

    // TODO: look into moving all these errors to errorCodesR where we show the actual message returned from the back-end
    case POST_IDENTITY_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Identity successfully created.'))

    case POST_IDENTITY_F_FAILURE:
      return failMessage('There were errors creating the identity.')

    case POST_IDENTITY_SETTLEMENTS_F_SUCCESS:
      return successMessage('Identity Settlement successfully created.')

    case POST_IDENTITY_SETTLEMENTS_F_FAILURE:
      return failMessage('There were errors creating the Identity Settlement.')

    case POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS:
      return successMessage('Payment Instrument successfully created.')

    case POST_IDENTITY_PAYMENT_INSTRUMENTS_F_FAILURE:
      return failMessage('There was an error creating the Bank Account.')

    case POST_IDENTITY_MERCHANTS_F_SUCCESS:
      return successMessage('Merchant successfully provisioned.')

    case POST_IDENTITY_MERCHANTS_F_FAILURE:
      return failMessage('There was an error provisioning this merchant.')

    case POST_IDENTITY_VERIFICATIONS_F_SUCCESS:
      return successMessage('Identity Verification successfully created.')

    case POST_IDENTITY_VERIFICATIONS_F_FAILURE:
      return failMessage(errorMessage)

    case PATCH_TRANSFER_F_SUCCESS:
      return successMessage('Transfer successfully updated.')

    case PATCH_TRANSFER_F_FAILURE:
      return failMessage('There was an error updating this transfer.')

    case PATCH_APPLICATION_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Application successfully updated.'))

    case PATCH_APPLICATION_F_FAILURE:
      return failMessage('There was an error updating this application.')

    case PATCH_PAYMENT_INSTRUMENT_F_SUCCESS:
      return successMessage('Payment instrument successfully updated.')

    case PATCH_PAYMENT_INSTRUMENT_F_FAILURE:
      return failMessage('There was an error updating this payment instrument.')

    case PATCH_IDENTITY_F_SUCCESS: {
      return successMessage(get(action, 'meta.successMessage', 'Identity successfully updated.'))
    }
    case PATCH_IDENTITY_F_FAILURE:
      return failMessage('There was an error updating this identity.')

    case PATCH_REVIEW_QUEUE_ITEMS_F_SUCCESS:
      return successMessage('Items have been successfully updated.')

    case PATCH_REVIEW_QUEUE_ITEMS_F_FAILURE:
      return failMessage('There was an error updating the selected items.')

    case POST_FEE_PROFILE_F_SUCCESS:
      return successMessage('Fee profile successfully created.')

    case POST_FEE_PROFILE_F_FAILURE:
      return failMessage('There was an error creating the Fee Profile.')

    case GET_DASHBOARD_USER_F_SUCCESS_NOTIFICATION:
      return successMessage('User has been found.')

    case GET_DASHBOARD_USER_F_FAILURE:
    case GET_DASHBOARD_USER_F_FAILURE_NOTIFICATION:
      return failMessage('There was an error finding the user.')

    case POST_DASHBOARD_USER_F_SUCCESS:
      return successMessage('User has been successfully created.')

    case POST_DASHBOARD_USER_F_FAILURE:
      return failMessage('There was an error creating the user.')

    case POST_ADMIN_USER_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'User has been successfully created or found.'))

    case POST_ADMIN_USER_F_FAILURE:
      return failMessage(get(action, 'meta.failMessage', 'There was an error creating or finding the user.'))

    case POST_ADMIN_USER_MEMBERSHIPS_F_SUCCESS:
      // TODO: refactor flash notifications to pull from meta in the case two flows are called but want to display different messages
      return successMessage(get(action, 'meta.successMessage'))

    case POST_ADMIN_USER_MEMBERSHIPS_F_FAILURE:
      return failMessage(get(action, 'meta.failMessage'))

    case POST_APPLICATION_PROCESSORS_F_SUCCESS:
      return successMessage('Processors have been successfully added.')

    case POST_APPLICATION_PROCESSORS_F_FAILURE:
      return failMessage('There was an error on adding processors.')

    case POST_USERS_EMAIL_F_SUCCESS:
    case POST_DASHBOARD_V2_EMAILS_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Email has been successfully sent to the user.'))

    case POST_USERS_EMAIL_F_FAILURE:
    case POST_DASHBOARD_V2_EMAILS_F_FAILURE:
      return failMessage('There was an error sending email to the user.')

    case POST_TRANSFER_REVERSAL_F_SUCCESS:
      return successMessage('Refund has been successfully created.')

    case POST_TRANSFER_REVERSAL_F_FAILURE:
      return failMessage('There was an error creating refund.')

    case DELETE_SETTLEMENT_ENTRIES_F_SUCCESS: {
      const entryIds = get(values, 'entry_ids')
      const entryWord = size(entryIds) === 1 ? 'Entry has' : 'Entries have'

      return successMessage(`${entryWord} been successfully removed from its settlement.`)
    }

    case DELETE_SETTLEMENT_ENTRIES_F_FAILURE:
      return failMessage(errorMessage || 'There was an error while removing the selected entries.')

    case POST_MERCHANT_VERIFICATIONS_F_SUCCESS:
      return successMessage('Verification has been successfully created.')

    case POST_MERCHANT_VERIFICATIONS_F_FAILURE:
      return failMessage('There was an error creating verification.')

    case POST_MERCHANT_FEE_PROFILE_F_SUCCESS:
      return successMessage('Fee Profile has been successfully created.')

    case POST_MERCHANT_FEE_PROFILE_F_FAILURE:
      return failMessage('There was an error creating fee profile.')

    case PATCH_MERCHANT_F_SUCCESS:
      return successMessage('Merchant has been successfully saved.')

    case PATCH_MERCHANT_F_FAILURE:
      return failMessage('There was an error saving merchant.')

    case POST_APPLICATION_ASSOCIATED_IDENTITIES_F_SUCCESS:
      return successMessage('Associated Identity has been successfully created.')

    case POST_APPLICATION_ASSOCIATED_IDENTITIES_F_FAILURE:
      return failMessage('There was an error saving associated identity.')

    case PATCH_AUTHORIZATION_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Authorization has been successfully saved.'))

    case PATCH_AUTHORIZATION_F_FAILURE:
      return failMessage('There was an error saving authorization.')

    case PATCH_RISK_PROFILE_F_FAILURE:
      return failMessage('There was an error saving risk profile.')

    case PATCH_MERCHANT_RISK_PROFILE_F_SUCCESS:
      return successMessage('Merchant\'s risk profile has been successfully saved.')

    case PATCH_MERCHANT_RISK_PROFILE_F_FAILURE:
      return failMessage('There was an error saving merchant\'s risk profile.')

    case POST_TRANSFER_F_SUCCESS: {
      const transferAction = get(values, 'action')

      if (transferAction === 'virtualTerminalPayment') {
        return successMessage('Payment has been successfully created.')
      }
      return successMessage('Adjustment successfully created.')
    }

    case POST_TRANSFER_F_FAILURE: {
      const transferAction = get(values, 'action')

      if (transferAction === 'virtualTerminalPayment') {
        return failMessage('There was an error creating the payment.')
      }
      return failMessage('There was an error when creating the adjustment.')
    }

    case PATCH_WELCOME_EMAIL_TEMPLATE_F_SUCCESS:
      return successMessage('Welcome email template successfully saved.')

    case PATCH_WELCOME_EMAIL_TEMPLATE_F_FAILURE:
      return failMessage('There was an error when saving the welcome email template.')

    case POST_DASHBOARD_FILE_F_SUCCESS:
      return successMessage('File successfully uploaded.')

    case POST_DASHBOARD_FILE_F_FAILURE:
      return failMessage('There was an error when uploading the file.')

    case PATCH_SETTLEMENT_V1_F_SUCCESS:
      return successMessage('Transfer successfully re-sent.')

    case PATCH_SETTLEMENT_V1_F_FAILURE:
      return failMessage('There was an error resending this transfer.')

    case PATCH_PLATFORM_PROCESSOR_CONFIG_F_SUCCESS:
      return successMessage('Processor config successfully updated.')

    case PATCH_PLATFORM_PROCESSOR_CONFIG_F_FAILURE:
      return failMessage('There was an error updating this processor config.')

    case GET_SEARCH_F_FAILURE:
      return failMessage('There was no match for your search.')

    case PATCH_USER_PERMISSIONS_F_SUCCESS:
      return successMessage('User Permissions have been updated.')

    case PATCH_USER_PERMISSIONS_F_FAILURE:
      return failMessage('There was an error updating user permissions.')

    case POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS:
      return successMessage('Verification successfully created.')

    case POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE:
      return failMessage('There was an error creating the verification.')

    case POST_IDENTITY_UPLOAD_F_SUCCESS:
      return successMessage('Identity upload was successful.')

    case POST_IDENTITY_UPLOAD_F_FAILURE:
      return failMessage('Identity upload contains error(s).')

    case PATCH_SETTLEMENT_F_SUCCESS:
      return successMessage('Settlement was successfully closed.')

    case GET_WELCOME_EMAIL_TEMPLATE_F_FAILURE:
      return failMessage('There was an error loading welcome email template.')

    case POST_PAYOUT_PLAN_F_SUCCESS:
      return successMessage('Payout plan was successfully created.')

    case POST_PAYOUT_PLAN_F_FAILURE:
      return failMessage('There was an error creating payout plan.')

    case CLOSE_SETTLEMENTS_F_FAILURE:
      return failMessage('There was an error closing settlements.')

    case POST_USER_F_SUCCESS:
      return successMessage('User was successfully created.')

    case POST_USER_F_FAILURE:
      return failMessage('There was an error creating user.')

    case PATCH_USER_F_SUCCESS:
      return successMessage('User was successfully updated.')

    case PATCH_USER_F_FAILURE:
      return failMessage('There was an error updating user.')

    case POST_BALANCE_TRANSFER_F_SUCCESS:
      return successMessage('Balance transfer was successfully created.')

    case POST_BALANCE_TRANSFER_F_FAILURE:
      return failMessage('There was an error creating balance transfer.')

    case POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS:
      return successMessage('Subscription enrollment was successfully created.')

    case POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_FAILURE:
      return failMessage('There was an error creating subscription enrollment.')

    case DELETE_SUBSCRIPTION_ENROLLMENT_F_SUCCESS:
      return successMessage('Subscription enrollment was successfully deleted.')

    case DELETE_SUBSCRIPTION_ENROLLMENT_F_FAILURE:
      return failMessage('There was an error deleting subscription enrollment.')

    case POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
      return successMessage('Subscription amount was successfully created.')

    case POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE:
      return failMessage('There was an error creating subscription amount.')

    case PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
      return successMessage('Subscription amount was successfully edited.')

    case PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE:
      return failMessage('There was an error editing subscription amount.')

    case DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS:
      return successMessage('Subscription amount was successfully deleted.')

    case DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE:
      return failMessage('There was an error deleting subscription amount.')

    case POST_FEE_F_SUCCESS:
      return successMessage('Custom fee was successfully created.')

    case POST_FEE_F_FAILURE:
      return failMessage('There was an error creating custom fee.')

    case PATCH_FEE_F_SUCCESS:
      return successMessage('Custom fee was successfully edited.')

    case PATCH_FEE_F_FAILURE:
      return failMessage('There was an error editing custom fee.')

    case PATCH_WEBHOOK_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Webhook was successfully edited.'))

    case PATCH_WEBHOOK_F_FAILURE:
      return failMessage(get(action, 'meta.failureMessage', 'There was an error editing webhook.'))

    case POST_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Domain successfully added.'))

    case POST_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE:
      return failMessage(get(action, 'meta.failMessage', 'Failed to add web domain.'))

    case PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Merchant domain(s) has successfully been unregistered with Apple Pay.'))

    case PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE:
      return successMessage(get(action, 'meta.failsMessage', 'There has been an error unregistering merchant domain(s) with Apple Pay.'))

    case POST_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS:
    case PATCH_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Dashboard has been successfully updated.'))

    case POST_MERCHANT_FILE_F_FAILURE:
    case POST_IDENTITY_FILE_F_FAILURE:
      return failMessage(get(action, 'meta.failMessage', 'Failed to upload file.'))

    case POST_MERCHANT_FILE_F_SUCCESS:
    case POST_IDENTITY_FILE_F_SUCCESS:
      return successMessage('File uploaded successfully. It may take up to a minute to scan the file before it\'s able to be viewed.', 15000)

    case POST_CONTACT_SUPPORT_EMAIL_F_SUCCESS:
      return successMessage('Your support ticket has been created. Please check your email for confirmation.')

    case POST_CONTACT_SUPPORT_EMAIL_F_FAILURE:
      return failMessage('Something went wrong. Please try again.')

    case POST_CONTACT_SALES_EMAIL_F_SUCCESS:
      return successMessage('Your message has been sent.')

    case POST_CONTACT_SALES_EMAIL_F_FAILURE:
      return failMessage('Something went wrong. Please try again.')

    case POST_DASHBOARD_FEEDBACK_EMAIL_F_SUCCESS:
      return successMessage('Thank you for your feedback.')

    case POST_DASHBOARD_FEEDBACK_EMAIL_F_FAILURE:
      return failMessage('Something went wrong. Please try again.')

    case PATCH_EMAIL_TEMPLATE_F_SUCCESS:
      return successMessage('Template Updated Successfully.')

    case PATCH_EMAIL_TEMPLATE_F_FAILURE:
      return failMessage('Something went wrong. Please try again.')

    case POST_ONBOARDING_FORM_DATA_F_SUCCESS:
      return successMessage('You have successfully submitted your application. We will now redirect you back to your software provider.', 7000)

    case PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_SUCCESS:
      return successMessage('Application underwriting settings updated successfully.')

    case PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_FAILURE:
      return failMessage('An error occurred while updating application underwriting settings.')

    case PATCH_COMPLIANCE_SETTINGS_F_SUCCESS:
      return successMessage('Compliance form settings were successfully updated.')

    case PATCH_COMPLIANCE_SETTINGS_F_FAILURE:
      return failMessage('An error occurred while updating compliance settings')

    case PATCH_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS: {
      const linkTo = get(values, 'linkTo', '')
      const linkTypeApplication = linkTo.slice(0, 2) === 'AP'
      const editActiveTemplateSuccessMessage = `${linkTypeApplication ? 'Application' : 'Platform'}'s active compliance form template was successfully updated. Please wait 10 to 60 seconds for it to appear on refresh.`
      const editTemplateNameSuccessMessage = 'Compliance form template name was successfully updated.'

      return successMessage(linkTo ? editActiveTemplateSuccessMessage : editTemplateNameSuccessMessage)
    }

    case PATCH_COMPLIANCE_FORM_TEMPLATE_F_FAILURE:
      return failMessage('An error occurred while updating compliance form template.')

    case POST_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS:
      return successMessage('Compliance form template created successfully.')

    case POST_COMPLIANCE_FORM_TEMPLATE_F_FAILURE:
      return failMessage('An error occurred while creating compliance form template.')

    case PATCH_PAYOUT_SETTINGS_F_SUCCESS:
      return successMessage('Payout settings were successfully edited.')

    case PATCH_PAYOUT_SETTINGS_F_FAILURE:
      return failMessage('An error occurred while editing payout settings.')

    case POST_NOTIFICATION_SETTINGS_F_SUCCESS:
      return successMessage('Notification settings updated successfully.')

    case POST_NOTIFICATION_SETTINGS_F_FAILURE:
      return failMessage('An error occurred. Please try again.')

    case PATCH_COMPLIANCE_FORM_F_FAILURE:
      return failMessage(get(action, 'meta.errorMessage', 'An error occurred while attesting this compliance form.'))

    case PATCH_COMPLIANCE_FORM_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Compliance form has been attested successfully.'))

    case POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_FAILURE:
      return failMessage('There was an issue resending the verification email. Please try again.')

    case POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_SUCCESS:
      return successMessage('Verification email was successfully resent.')

    case POST_SELF_SERVICE_RESEND_EMAIL_F_FAILURE:
      return failMessage('There was an issue resending the verification email. Please try again.')

    case POST_SELF_SERVICE_RESEND_EMAIL_F_SUCCESS:
      return successMessage('Verification email was successfully resent.')

    case PATCH_CREDENTIAL_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'The team member was successfully updated.'))

    case PATCH_CREDENTIAL_F_FAILURE:
      return failMessage('An error occurred. Please try again.')

    case POST_MEMBER_F_SUCCESS:
      return successMessage('Team Member was successfully added.')

    case POST_MEMBER_F_FAILURE:
      return failMessage('An error occurred. Please try again.')

    case POST_ROLE_F_SUCCESS:
      return successMessage('The Custom Role was successfully created.')

    case POST_ROLE_F_FAILURE:
      return failMessage('An error occurred. Please try again.')

    case PATCH_ROLE_F_SUCCESS:
      return successMessage('The Custom Role was successfully updated.')

    case PATCH_ROLE_F_FAILURE:
      return failMessage('An error occurred. Please try again.')

    case POST_CHANGE_PASSWORD_F_SUCCESS:
      return successMessage(CHANGE_PASSWORD_SUCCESS_MESSAGE)

    case PATCH_DASHBOARD_USER_F_SUCCESS:
      return successMessage(EDIT_DASHBOARD_USER_NAME_SUCCESS_MESSAGE)

    case POST_SUBMIT_ACCESS_FORM_F_SUCCESS:
      return successMessage('Your application form for a live account was submitted.')

    case DELETE_ACCESS_FORM_F_SUCCESS:
      return successMessage(WITHDRAW_APPLICATION_FORM_SUCCESS_MSG)

    case DELETE_API_KEY_F_SUCCESS:
      return successMessage('Your API Key was successfully disabled.')

    case PATCH_UNDERWRITING_PROFILE_F_SUCCESS: {
      const recipientSettings = get(payload, 'values.recipient_settings')
      const customSuccessMessage = recipientSettings ? 'Recipient Settings were successfully updated' : 'Underwriting Profile was successfully updated.'

      return successMessage(customSuccessMessage)
    }

    case PATCH_UNDERWRITING_PROFILE_F_FAILURE: {
      const recipientSettings = get(payload, 'values.recipient_settings')
      const customFailureMessage = recipientSettings ? 'Failed to update Recipient Settings' : 'There was an error updating the Underwriting Profile.'

      return failMessage(customFailureMessage)
    }

    case POST_UNDERWRITING_PROFILE_F_SUCCESS:
      return successMessage('Underwriting Profile was successfully created.')

    case PATCH_DISPUTE_F_SUCCESS:
      return successMessage('Dispute successfully updated.')

    case PATCH_DISPUTE_F_FAILURE:
      return failMessage('There was an error updating this dispute.')

    case PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_FAILURE:
      return failMessage('File upload failed. Please try again.')

    case POST_DASHBOARD_USER_MFA_VERIFY_F_SUCCESS:
      return successMessage('Multi-factor authentication was successfully enabled.')

    case POST_ENTITY_CONFIGURATION_F_SUCCESS:
    case PATCH_ENTITY_CONFIGURATION_F_SUCCESS:
      return successMessage('Multi-factor authentication settings have been updated for all members of your company.')

    case POST_DASHBOARD_USER_MFA_RESET_F_SUCCESS:
      return successMessage('Multi-factor authentication was successfully reset.')

    case POST_BALANCE_ENTRY_F_SUCCESS:
      return successMessage('Account Balance successfully updated.')

    case POST_BALANCE_ENTRY_F_FAILURE:
      return failMessage('There was an error updating the account balance.')

    case POST_DISBURSEMENT_RULES_F_SUCCESS:
      return successMessage('Velocity limits successfully created.')

    case POST_UNDERWRITING_REVIEW_RUNS_F_SUCCESS:
      return successMessage('New run successfully created.')

    case PATCH_UNDERWRITING_WORKFLOW_F_SUCCESS:
      return successMessage(UNDERWRITING_AUTOMATION_UPDATES_SUCCESS_MESSAGE)

    case PATCH_UNDERWRITING_WORKFLOW_F_FAILURE:
      return failMessage(UNDERWRITING_AUTOMATION_UPDATES_ERROR_MESSAGE)

    case POST_DEVICE_F_SUCCESS:
      return successMessage('A new device has been successfully created.')

    case PATCH_DEVICE_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'Device has been successfully updated.'))

    case GET_DEVICE_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage'))

    case GET_DEVICE_F_FAILURE:
      return failMessage(get(action, 'meta.errorMessage'))

    case POST_COMPLIANCE_FORM_F_SUCCESS:
      return successMessage(get(action, 'meta.successMessage', 'New compliance form created!'))

    case POST_COMPLIANCE_FORM_F_FAILURE:
      return failMessage(get(action, 'meta.errorMessage', 'Failed to create a new compliance form.'))

    case PATCH_DEVICE_F_FAILURE: {
      const deviceId = get(payload, 'id')
      const device = get(newValues, deviceId)
      const deviceName = get(device, 'name')
      const actionType = get(values, 'action')

      return failMessage(actionType === ACTIVATE_VALUE ? `${deviceName} could not be activated. Please confirm the device is online and the serial number is correct and try again.` : `${deviceName} could not be deactivated. Please try again.`)
    }

    case PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS:
      return successMessage('Application processor configurations have been successfully updated.')

    case POST_RECIPIENT_IDENTITY_F_SUCCESS:
      return successMessage('A new recipient identity was created successfully. Please note that each recipient will need to be underwritten. This may take up to 24 hours.', 15000)

    case POST_SUBMIT_TO_UNDERWRITING_F_SUCCESS:
      return successMessage('This application has been submitted for underwriting review')

    case POST_REQUEST_CHANGES_F_SUCCESS:
      return successMessage('This application has been updated to additional verification needed')

    case PATCH_PAYOUT_LINK_F_SUCCESS:
      return successMessage('Payout link was successfully updated.')

    case POST_DISPUTE_F_SUCCESS:
      return successMessage('Dispute successfully created.')

    case POST_DISPUTE_F_FAILURE:
      return failMessage('There was an error creating this dispute.')

    case PATCH_UPDATE_DISPUTE_F_SUCCESS:
      return successMessage('Dispute successfully updated.')

    case PATCH_UPDATE_DISPUTE_F_FAILURE:
      return failMessage('There was an error updating this dispute.')

    case POST_RECEIPT_DELIVERY_ATTEMPTS_F_SUCCESS:
      return successMessage('Receipt successfully sent.')

    case POST_RECEIPT_DELIVERY_ATTEMPTS_F_FAILURE:
      return failMessage('There was an error sending the receipt.')

    case POST_RECEIPT_F_SUCCESS:
      return successMessage('Receipt successfully created.')

    case POST_RECEIPT_F_FAILURE:
      return failMessage('There was an error creating the receipt.')

    case POST_SUBSCRIPTION_PLAN_F_SUCCESS:
      return successMessage('Subscription plan successfully created.')

    case POST_SUBSCRIPTION_F_SUCCESS:
      return successMessage('Subscription successfully created.')

    case DELETE_SUBSCRIPTION_F_SUCCESS:
      return successMessage('Subscription successfully canceled.')

    case PATCH_SUBSCRIPTION_PLAN_F_SUCCESS:
      return successMessage('Subscription plan was successfully updated.')

    case PATCH_SUBSCRIPTION_F_SUCCESS:
      return successMessage('Subscription successfully updated.')

    case PATCH_MONITORING_ALERTS_F_SUCCESS:
      return successMessage('Selected alerts resolved!')

    case PATCH_MONITORING_ALERT_F_SUCCESS:
      return successMessage('Selected alert resolved!')

    case POST_INSTANT_PAYOUT_F_SUCCESS:
      return successMessage('Instant Payout successfully created.')

    case POST_INSTANT_PAYOUT_F_FAILURE:
      return failMessage('There was an error creating the Instant Payout.')

    default:
      return state
  }
}

export default flashNotificationsR
