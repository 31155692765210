import './APILogsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getAPILogsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { API_LOGS } from 'constants/linkConstants'
import { GET_API_LOGS } from 'constants/flowConstants'
import { API_LOGS_ICON } from 'constants/iconConstants'

import {
  API_LOGS_BANNER_MESSAGE,
  API_LOGS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const APILogs = ({
  apiLogsPath = '',
  allowedFilters = [],
  initialQueries = {},
  isFetching = false,
  initialFilters,
  quickFilters,
}) => {
  return (
    <div className='APILogs'>
      <div className='banner'>{API_LOGS_BANNER_MESSAGE}</div>
      <TableC
        flow={GET_API_LOGS}
        initialFilters={initialFilters}
        columnDescriptors={columnDescriptors}
        selector={getAPILogsSelector}
        initialQueries={initialQueries}
        linksKey={API_LOGS}
        allowedFilters={allowedFilters}
        path={apiLogsPath}
        isFetching={isFetching}
        icon={`fa fa-${API_LOGS_ICON}`}
        emptyMessage={API_LOGS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

APILogs.propTypes = {
  apiLogsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default APILogs
