import valid from 'card-validator'
import getMany from 'utilities/get/getMany'
import formatNumber from 'utilities/formatters/formatNumber'
import { DEFAULT_CONFIG } from 'constants/fileUploaderConstants'
import { ancillaryFixedFeePrimary } from 'constants/ancillaryFixedFeeConstants'
import { invalidEmailsMap } from 'constants/invalidSelfServiceEmailConstants'
import { ASSOCIATION_ESTATE_TRUST_VALUE } from 'constants/identityConstants'
import numeral from 'numeral'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import some from 'lodash/some'
import every from 'lodash/every'
import startsWith from 'lodash/startsWith'
import replace from 'lodash/replace'
import size from 'lodash/size'
import split from 'lodash/split'

import {
  USA,
  CAN,
  countryToRegionLabelMap,
  defaultRegionLabel,
} from 'constants/countryConstants'

import {
  TAX_ID_REGEX,
  MASKED_TAX_ID_REGEX,
  BANK_CODE_REGEX,
  IPV4_ADDRESS_REGEX,
  IPV6_ADDRESS_REGEX,
  DEFAULT_STATEMENT_DESCRIPTOR_REGEX,
  MASKED_NUMBER_REGEX,
  MASKED_BANK_ACCOUNT_NUMBER_REGEX,
  ACCOUNT_NUMBER_REGEX,
  REGEX_CANADIAN_POSTAL_CODE,
  CANADA_ASSOCIATE_ESTATE_TRUST_TAX_ID_REGEX,
  USA_POSTAL_CODE_REGEX,
  PHONE_NUMBER_REGEX,
  EMAIL_REGEX,
} from 'constants/regexConstants'

import {
  t,
  ROUTING_NUMBER_VALIDATION_ERROR,
  STATEMENT_DESCRIPTOR_LENGTH_VALIDATION_ERROR,
  STATEMENT_DESCRIPTOR_ASTERISK_VALIDATION_ERROR,
  STATEMENT_DESCRIPTOR_SPECIAL_CHARACTER_VALIDATION_ERROR,
  BANK_ACCOUNT_NUMBER_VALIDATION_ERROR,
  MASKED_BANK_ACCOUNT_NUMBER_VALIDATION_ERROR,
  PASSWORD,
  INVALID_BASIC_AUTH_PASSWORD_LENGTH_ERROR,
  ENDPOINT,
  FUTURE_DATE_VALIDATION_ERROR,
  TAG_NAME_LENGTH_VALIDATION_ERROR,
  TAG_VALUE_LENGTH_VALIDATION_ERROR,
  ADULT_VALIDATION_ERROR,
  PLEASE_ENTER_A_VALID_ADDRESS,
  PLEASE_ENTER_PROPER_DATE_FORMAT,
  MUST_NOT_BE_EMPTY,
} from 'constants/language/languageConstants'

export const fieldEmptyMessage = (name = 'Field') => `${name} ${t(MUST_NOT_BE_EMPTY)}.`

export const fieldEmpty = (field, name, customErrorMessage) => {
  return isEmpty(field) && (t(customErrorMessage) || fieldEmptyMessage(name))
}

export const selectFieldEmpty = (field, name) => isEmpty(field) && `${name} must have an option selected.`
export const isNumber = value => !Number.isNaN(parseFloat(value))

export const nonEmptyNumber = ({
  field = '',
  name,
}) => {
  const num = replace(field, /,/gi, '')
  const empty = fieldEmpty(num, name)
  const normalizedField = numeral(field).value()
  const notNumber = !isNumber(normalizedField) && `${name} must be a number.`

  return empty || notNumber
}

export const nonEmptyPositiveNumber = ({
  field = '',
  name,
}) => {
  const num = replace(field, /,/gi, '')
  const empty = fieldEmpty(num, name)
  const notNumber = !isNumber(field) && `${name} must be a number.`
  const notPositiveNumber = field < 0 && `${name} must be a positive number.`

  return empty || notNumber || notPositiveNumber
}

export const nonEmptyPositiveAmount = ({
  field = '',
  name,
}) => {
  const empty = fieldEmpty(field, name)
  const notPositiveAmount = numeral(field).value() <= 0 && `${name} must be greater than $0.00.`

  return empty || notPositiveAmount
}

export const nonEmptyInteger = ({
  field = '',
  name,
}) => {
  const empty = fieldEmpty(field, name)
  const notInteger = (!isNumber(field) || field % 1 !== 0) && `${name} must be an integer.`
  const hasDecimal = field.toString().includes('.') && `${name} must be an integer.`

  return empty || notInteger || hasDecimal
}

export const invalidRegex = ({
  field,
  name,
  regex,
  customErrorMessage,
}) => {
  const empty = fieldEmpty(field, name)
  const fieldRegex = new RegExp(regex)
  const message = customErrorMessage ? customErrorMessage : `${name} is not in a correct format.`
  const fieldInvalid = !empty && (!field.match(fieldRegex)) && message

  return fieldInvalid
}

export const invalidOrEmptyRegex = ({
  field,
  name,
  regex,
  customErrorMessage,
}) => {
  const empty = fieldEmpty(field, name)
  const fieldRegex = new RegExp(regex)
  const message = customErrorMessage ? customErrorMessage : `${name} is not in a correct format.`
  const fieldInvalid = (empty || !field.match(fieldRegex)) && message

  return empty || fieldInvalid
}

export const invalidOrEmptyEmail = (email, label = 'Email Address') => invalidOrEmptyRegex({ field: email, name: 'Email Address', regex: EMAIL_REGEX })

export const isAddressUnsupported = (address = '') => {
  const normalizedAddress = address.toLowerCase().replace(/\s/g, '')

  const unsupportedAddressPatterns = [
    /^p\.?\s*o\.?\s*box/i,
    /^p\.?\s*box/i,
    /^postofficebox/i,
    /^postoffice\b/i,
    /^generaldelivery\b/i,
    /^mailforwarding\b/i,
    /^registeredagent\b/i,
  ]

  return unsupportedAddressPatterns.some(pattern => pattern.test(normalizedAddress))
}

// TODO: add better a clearer error message depending on what is wrong with the address
export const validateUnsupportedAddressTypes = (address) => {
  return isAddressUnsupported(address) && PLEASE_ENTER_A_VALID_ADDRESS
}

export const validateAddress = ({ address = {}, names = {}, canBeEmpty = false, canBePOBox = false }) => {
  const [
    line1,
    line2,
    city,
    countryValue,
    postalCode,
    region,
  ] = getMany(address, [
    'line1',
    'line2',
    'city',
    'country',
    'postalCode',
    'region',
  ])

  const [
    cityName,
    countryName,
    line1Name,
    postalCodeName,
    regionName,
  ] = getMany(names, [
    'city',
    'country',
    'line1',
    'postalCode',
    'region',
  ])

  const shouldValidateUnsupportedAddressTypes = (value) => {
    return canBePOBox ? false : validateUnsupportedAddressTypes(value)
  }

  if (canBeEmpty) {
    return {
      line1: shouldValidateUnsupportedAddressTypes(line1),
      line2: shouldValidateUnsupportedAddressTypes(line2),
      postalCode: size(postalCode) >= 8 && 'Postal Code must not exceed 7 digits',
    }
  }

  if (countryValue === CAN) {
    return {
      line1: fieldEmpty(line1, line1Name || 'Line 1') || shouldValidateUnsupportedAddressTypes(line1),
      line2: shouldValidateUnsupportedAddressTypes(line2),
      city: fieldEmpty(city, cityName || 'City'),
      region: fieldEmpty(region, get(countryToRegionLabelMap, countryValue, defaultRegionLabel)),
      postalCode: invalidOrEmptyRegex({ field: postalCode, name: postalCodeName || 'Postal Code', regex: REGEX_CANADIAN_POSTAL_CODE }),
      country: fieldEmpty(countryValue, countryName || 'Country'),
    }
  }

  if (countryValue === USA) {
    return {
      line1: fieldEmpty(line1, line1Name || 'Line 1') || shouldValidateUnsupportedAddressTypes(line1),
      line2: shouldValidateUnsupportedAddressTypes(line2),
      city: fieldEmpty(city, cityName || 'City'),
      region: fieldEmpty(region, get(countryToRegionLabelMap, countryValue, defaultRegionLabel)),
      postalCode: invalidOrEmptyRegex({ field: postalCode, name: postalCodeName || 'Postal Code', regex: USA_POSTAL_CODE_REGEX, customErrorMessage: 'Postal Code must be exactly 5 digits' }),
      country: fieldEmpty(countryValue, countryName || 'Country'),
    }
  }

  return {
    line1: fieldEmpty(line1, line1Name || 'Line 1') || shouldValidateUnsupportedAddressTypes(line1),
    line2: shouldValidateUnsupportedAddressTypes(line2),
    city: fieldEmpty(city, cityName || 'City'),
    region: fieldEmpty(region, get(countryToRegionLabelMap, countryValue, defaultRegionLabel)),
    postalCode: fieldEmpty(postalCode, postalCodeName || 'Postal Code') || (size(postalCode) >= 8 && 'Postal Code must not exceed 7 digits'),
    country: fieldEmpty(countryValue, countryName || 'Country'),
  }
}

export const validateDate = ({ date = {}, noFutureDate = false, checkAdult = false }) => {
  const {
    day,
    month,
    year,
  } = date

  if (noFutureDate) {
    const timestamp = new Date(year, month - 1, day).getTime()
    const currentDate = new Date()
    const currentTimestamp = currentDate.getTime()

    if (timestamp > currentTimestamp) {
      return {
        day: FUTURE_DATE_VALIDATION_ERROR,
        month: FUTURE_DATE_VALIDATION_ERROR,
        year: FUTURE_DATE_VALIDATION_ERROR,
      }
    }
  }

  if (checkAdult) {
    const birthdateTimestamp = new Date(year, month - 1, day).getTime()
    const currentDate = new Date()
    const currentTimestamp = currentDate.getTime()
    const adultTimestamp = new Date(currentTimestamp - 18 * 365 * 24 * 60 * 60 * 1000).getTime()

    if (birthdateTimestamp > adultTimestamp) {
      return {
        day: ADULT_VALIDATION_ERROR,
        month: ADULT_VALIDATION_ERROR,
        year: ADULT_VALIDATION_ERROR,
      }
    }
  }

  return {
    day: fieldEmpty(day, 'Day'),
    month: fieldEmpty(month, 'Month'),
    year: fieldEmpty(year, 'Year'),
  }
}

export const validateTaxId = ({
  taxId = '',
  name = '',
}) => {
  const trimmedTaxID = replace(taxId, /-/gi, '').trim()

  return invalidOrEmptyRegex({ field: trimmedTaxID, name, regex: TAX_ID_REGEX, customErrorMessage: `${name} must be exactly 9 digits.` })
}

export const validateMaskedTaxId = ({
  taxId = '',
  name = '',
  country = USA,
  canBeEmpty = false,
  businessType = '',
}) => {
  const trimmedTaxID = replace(taxId, /-/gi, '').trim()

  // this function is used to validate the tax id against its own regex, and regex for repeated characters or sequential digits
  const validate = (value, regex, customErrorMessage) => {
    const validateRegex = canBeEmpty ? invalidRegex({ field: value, name, regex, customErrorMessage }) : invalidOrEmptyRegex({ field: value, name, regex, customErrorMessage })
    const validateRepeatedDigits = value && every(value, (char) => char === value[0]) && `${name} cannot be all repeating digits.`
    const validateSequentialDigits = value && value === ('123456789' || '987654321') && `${name} cannot be all sequential digits.`

    return validateRegex || validateRepeatedDigits || validateSequentialDigits
  }

  if (country === CAN) {
    if (businessType === ASSOCIATION_ESTATE_TRUST_VALUE) {
      return validate(trimmedTaxID, CANADA_ASSOCIATE_ESTATE_TRUST_TAX_ID_REGEX, `${name} must have the correct format.`)
    }

    return validate(trimmedTaxID, MASKED_TAX_ID_REGEX, `${name} must be exactly 9 digits.`)
  }

  return validate(trimmedTaxID, MASKED_TAX_ID_REGEX, `${name} must be exactly 9 digits.`)
}

export const validatePhoneNumber = ({
  phoneNumber = '',
  name = 'Phone Number',
  canBeEmpty = false,
}) => {
  const trimmedPhoneNumber = replace(phoneNumber, /[+-]/gi, '').trim()

  const validateRegex = canBeEmpty ? invalidRegex({ field: trimmedPhoneNumber, name, regex: PHONE_NUMBER_REGEX, customErrorMessage: `${name} must be a max of 11 digits.` }) : invalidOrEmptyRegex({ field: trimmedPhoneNumber, name, regex: PHONE_NUMBER_REGEX, customErrorMessage: `${name} must be a max of 11 digits.` })
  const validateRepeatedDigits = every(trimmedPhoneNumber, (char) => char === trimmedPhoneNumber[0]) && `${name} cannot be all repeating digits.`
  const validateSequentialDigits = trimmedPhoneNumber === ('0123456789' || '9876543210' || '1234567890' || '0987654321') && `${name} cannot be all sequential digits.`

  return validateRegex || validateRepeatedDigits || validateSequentialDigits
}

export const validateBankCode = ({ bankCode = '', name = '' }) => {
  return invalidOrEmptyRegex({ field: bankCode, name, regex: BANK_CODE_REGEX, customErrorMessage: ROUTING_NUMBER_VALIDATION_ERROR })
}

export const validateBankAccountNumber = ({ accountNumber = '', name = '' }) => {
  return invalidOrEmptyRegex({
    field: accountNumber,
    name,
    regex: ACCOUNT_NUMBER_REGEX,
    customErrorMessage: BANK_ACCOUNT_NUMBER_VALIDATION_ERROR,
  })
}

export const validateMaskedBankAccountNumber = ({ accountNumber = '', name = '' }) => {
  return invalidOrEmptyRegex({
    field: accountNumber,
    name,
    regex: MASKED_BANK_ACCOUNT_NUMBER_REGEX,
    customErrorMessage: MASKED_BANK_ACCOUNT_NUMBER_VALIDATION_ERROR,
  })
}

export const validatePercentage = ({ field = '', name, percentMin = 0, percentMinEqual = false }) => {
  const parsedPercent = replace(field, '%', '')
  const percentInvalid = nonEmptyNumber({ field: parsedPercent, name })
  const percentMinCondition = percentMinEqual ? parseFloat(parsedPercent) <= percentMin : parseFloat(parsedPercent) < percentMin
  const percentLessThanMin = percentMinCondition && (percentMinEqual ? `${name} must be greater than ${percentMin}%` : `${name} must be ${percentMin}% or greater`)
  const percentMoreThan100 = parseFloat(parsedPercent) > 100 && `${name} must be less than 100%`
  const percentErrors = percentInvalid || percentLessThanMin || percentMoreThan100

  return percentErrors
}

export const validateAmount = ({ field = '', name = '' }) => {
  const emptyNumber = nonEmptyNumber({ field, name })
  const amountLessThan0 = field < 0 && `${name} must be greater than 0.00` // TODO: this check doesn't work, field is a string when using AmountField and will always be false

  return emptyNumber || amountLessThan0
}

export const validateMaxAmount = ({ field = '', name = '', maxAmount, canBeEmpty = false, formatter, showCurrencySymbol = false, currencySymbol = '$' }) => {
  const normalizedField = numeral(field).value()
  const formattedMaxAmount = formatNumber(maxAmount, formatter)
  const emptyNumber = nonEmptyNumber({ field, name })
  const amountLessThan0 = normalizedField < 0 && `${name} must be greater than 0.00`
  const amountGreaterThanMaxAmount = normalizedField > maxAmount && `${name} cannot exceed the max of ${showCurrencySymbol ? currencySymbol : ''}${formattedMaxAmount}`

  if (canBeEmpty) {
    return amountLessThan0 || amountGreaterThanMaxAmount
  }

  return emptyNumber || amountLessThan0 || amountGreaterThanMaxAmount
}

export const validateInteger = ({ field = '', name = '', value = 0 }) => {
  const emptyInteger = nonEmptyInteger({ field, name })
  const integerLessThanValue = field < value && `${name} must be greater than or equal to ${value}`

  return emptyInteger || integerLessThanValue
}

export const validateHour = ({ field = '', name = '' }) => {
  const emptyInteger = nonEmptyInteger({ field, name })
  const integerValidHour = (field < 0 || field > 23) && `${name} must be between 0 and 23`

  return emptyInteger || integerValidHour
}

export const validateMinute = ({ field = '', name = '' }) => {
  const emptyInteger = nonEmptyInteger({ field, name })
  const integerValidMinute = (field < 0 || field > 59) && `${name} must be between 0 and 59`

  return emptyInteger || integerValidMinute
}

export const validateFile = (file, config = DEFAULT_CONFIG) => {
  if (isEmpty(file)) {
    return 'Please attach a file'
  }

  const {
    accept,
    maxSize,
  } = config

  const {
    name,
    size: fileSize,
    type,
  } = file

  const maxSizeText = numeral(maxSize).format('0.00 b')
  const sizeText = numeral(fileSize).format('0.00 b')

  const invalidSize = fileSize > maxSize
  const invalidType = !!accept && !includes(accept.split(', '), type)

  if (invalidSize && invalidType) {
    return `${name}: file type '${type}' is not allowed, and file size ${sizeText} exceed max size (${maxSizeText})`
  }

  if (invalidSize) {
    return `${name}: file size ${sizeText} exceed max size (${maxSizeText})`
  }

  if (invalidType) {
    return `${name}: file type '${type}' is not allowed`
  }

  return false
}

export const validateAncillaryFixedFeeSecondary = ({ field, name }, values) => {
  const ancillaryFixedFeePrimaryValue = get(values, ancillaryFixedFeePrimary)
  const emptyAncillaryFixedFeePrimary = !ancillaryFixedFeePrimaryValue && 'Ancillary Fee #1 must be greater than 0.00.'

  return emptyAncillaryFixedFeePrimary || validateAmount({ field, name })
}

export const validateIPAddress = ({ field, name }) => {
  return invalidOrEmptyRegex({ field, name, regex: IPV4_ADDRESS_REGEX }) && invalidOrEmptyRegex({ field, name, regex: IPV6_ADDRESS_REGEX })
}

export const validateTotalPercentage = ({ name, amounts = [], lowerBound, upperBound, exactAmount, canBeEmpty = false }) => {
  let total = 0
  forEach(amounts, (amount) => { total += amount })

  if (canBeEmpty && total === 0 && every(amounts, (amount) => !isNumber(amount))) {
    return false
  }

  if (exactAmount) {
    return (total !== exactAmount) && `${name} must add up to ${exactAmount}%.`
  }

  return !(total >= lowerBound && total <= upperBound) && `${name} must add up to between ${lowerBound}& and ${upperBound}%. `
}

export const validateTruthyValue = ({ name, field, customErrorMessage = '' }) => {
  return !field && customErrorMessage
}

export const validateDefaultStatementDescriptor = ({ descriptor = '', name = '' }) => {
  const descLength = get(descriptor, 'length', 0)

  const lengthError = (descLength <= 0 || descLength > 20) && STATEMENT_DESCRIPTOR_LENGTH_VALIDATION_ERROR
  const asteriskError = descriptor.includes('*') && STATEMENT_DESCRIPTOR_ASTERISK_VALIDATION_ERROR
  const regexError = invalidOrEmptyRegex({ field: descriptor, name, regex: DEFAULT_STATEMENT_DESCRIPTOR_REGEX, customErrorMessage: STATEMENT_DESCRIPTOR_SPECIAL_CHARACTER_VALIDATION_ERROR })

  return lengthError || asteriskError || regexError
}

export const validateBasicAuthPassword = ({ password = '' }) => {
  const invalidPasswordLength = (password.length < 16 || password.length > 256)

  return fieldEmpty(password, PASSWORD) || (invalidPasswordLength && INVALID_BASIC_AUTH_PASSWORD_LENGTH_ERROR)
}

export const nonEmptyMaskedNumber = ({
  field = '',
  name,
}) => {
  const num = replace(field, /,/gi, '')
  const empty = fieldEmpty(num, name)
  const invalid = invalidRegex({ field, name, regex: MASKED_NUMBER_REGEX, customErrorMessage: `${name} must be a number.` })

  return empty || invalid
}

export const checkboxGroupFieldEmpty = ({ field, name = 'Field' }) => {
  const hasChecked = some(field, (value) => value)

  return !hasChecked && `${name} must have at least one option checked.`
}

export const checkboxGroupFieldAllChecked = ({ field, name = 'Field', options }) => {
  const hasAllChecked = every(options, ({ value }) => get(field, value))

  return (!hasAllChecked || isEmpty(field)) && `${name} must have all options checked.`
}

export const validateWebsite = ({ field = '', name = ENDPOINT, canBeEmpty = false }) => {
  if ((canBeEmpty && isEmpty(field)) || startsWith(field, 'https://')) {
    return false
  }

  return `${name} requires secure protocol (https).`
}

export const validateSelfServiceEmail = ({ field = '', name = 'Email' }) => {
  const empty = isEmpty(field)
  const isEmptyEmail = empty && 'Please enter your company email'

  const emailValue = field.split('@')[1]

  const isInvalidEmail = emailValue === undefined || !!invalidEmailsMap[emailValue]

  return isEmptyEmail || (isInvalidEmail && 'Please enter a valid company email')
}

export const maxSignupPasswordCharacters = 20

// TODO: change name to something more generic in future if these rules apply to all sorts of passwords
export const validateSelfServicePassword = ({ field = '', name = 'Password', regex, noMessages = false }) => {
  const empty = noMessages ? isEmpty(field) : fieldEmpty(field, name) && 'Please create a password'
  const fieldRegex = new RegExp(regex)
  const fieldInvalid = noMessages ? !field.match(fieldRegex) : !field.match(fieldRegex) && 'Please create a stronger password'
  const invalidCharactersRegex = new RegExp(/[ +=[\]{};':"\\|,<>/]/g)
  const invalidSpecialCharacter = noMessages ? invalidCharactersRegex.test(field) : invalidCharactersRegex.test(field) && 'Your password must only use one of these allowed special characters: @$!%^*#?&()._-'

  const passwordMaxLength = noMessages ? field.length > maxSignupPasswordCharacters : field.length > maxSignupPasswordCharacters && 'Max of 20 characters allowed'

  return empty || passwordMaxLength || invalidSpecialCharacter || fieldInvalid
}

export const validateBusinessName = ({ field = '', name = 'Business Name', canBeEmpty = false }) => {
  if (canBeEmpty) {
    return size(field) >= 120 && `${name} must not exceed 120 characters.`
  }
  return fieldEmpty(field, name) || (size(field) >= 120 && `${name} must not exceed 120 characters.`)
}

export const validateDBA = ({ field = '', name = 'Doing Business As', canBeEmpty = false }) => {
  if (canBeEmpty) {
    return size(field) >= 60 && `${name} must not exceed 60 characters`
  }
  return fieldEmpty(field, name) || (size(field) >= 60 && `${name} must not exceed 60 characters`)
}

export const validateBusinessDescription = ({ field = '', name = 'Business Description', length = 200 }) => {
  return fieldEmpty(field, name) || (size(field) > length && `${name} must not exceed ${length} characters`)
}

export const validateMerchantCount = ({ field = '', name = 'Merchant Count', canBeEmpty = false }) => {
  if (canBeEmpty) {
    return (!isEmpty(field) && !isNumber(field) && `${name} must be a number.`) || ((parseInt(field, 10) > 100000) && `${name} must not exceed 100,000`)
  }
  return nonEmptyNumber({ field, name }) || ((parseInt(field, 10) > 100000) && `${name} must not exceed 100,000`)
}

export const validateTagNameLength = ({ field = '', name = 'Name' }) => {
  return fieldEmpty(field, name) || (size(field) >= 40 && TAG_NAME_LENGTH_VALIDATION_ERROR)
}

export const validateTagValueLength = ({ field = '', value = 'Value' }) => {
  return fieldEmpty(field, value) || (size(field) >= 500 && TAG_VALUE_LENGTH_VALIDATION_ERROR)
}

export const validateCardExpirationDate = ({ field = '', name = 'Expiration Date' }) => {
  const yearValue = split(field, '/')[1]
  const isValidDate = !get(valid.expirationDate(field), 'isValid', false) && `Please enter a valid ${name}.`
  const isIncorrectFormat = size(yearValue) === 2 && PLEASE_ENTER_PROPER_DATE_FORMAT
  return isIncorrectFormat || isValidDate
}

export const validateCardNumber = ({ field = '', name = 'Card Number' }) => {
  const isValid = get(valid.number(field), 'isValid', false)
  return isValid ? false : `Please enter a valid ${name}.`
}
