import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import createQueryString from 'utilities/create/createQueryString'
import removeUndefined from 'utilities/remove/removeUndefined'
import { COMPLETE_MERCHANT_BOARDING } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import concat from 'lodash/concat'


const submitOnboardingForm = (values, dispatch, props) => {
  const {
    action,
    recordId,
    returnUrl,
  } = props

  const {
    inquiryId,
    merchantAgreementAccepted,
    amexMarketingConsentAccepted,
  } = values

  const valuesToSubmit = removeUndefined({
    onboarding_form_id: recordId,
    merchant_agreement_accepted: merchantAgreementAccepted,
    amex_marketing_consent_accepted: amexMarketingConsentAccepted !== undefined ? amexMarketingConsentAccepted : false,
    persona_inquiry_id: inquiryId,
  })

  dispatch({
    type: action,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const identityId = get(newValues, 'entityId')

        // add the identity id to the queries for the return url
        const urlBase = split(returnUrl, '?')[0]
        let urlQueries = parseUrlQueries(returnUrl)
        urlQueries = { ...urlQueries, identityId }
        const queryString = createQueryString({ queries: urlQueries })
        const returnUrlString = concat(urlBase, queryString).join('')

        // kick the user back to their home page after 5 seconds
        setTimeout(() => {
          window.location.replace(returnUrlString)
        }, 3000)
      },
      actionId: sendAmplitudeActionEvent(COMPLETE_MERCHANT_BOARDING, {
        id: recordId,
      }),
    },
  })
}

export default submitOnboardingForm
