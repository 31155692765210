import removeUndefined from 'utilities/remove/removeUndefined'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_IDENTITY_MERCHANTS_F_REQUEST } from 'constants/flowConstants'
import { ENABLE_PROVISION_MERCHANT } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const submitProvisionMerchantProcessorsForm = (values, dispatch, props) => {
  const {
    identity,
    credentials,
    processor,
    gateway,
  } = props

  const {
    chainCode,
    divisionCode,
    defaultBankAccount,
    creditDeposits,
    creditChargebacks,
    creditProcessingFees,
    creditConvenienceFees,
    debitDeposits,
    debitChargebacks,
    debitConvenienceFees,
  } = values

  const optionalProcessorSpecificParameters = {
    chain_code: chainCode,
    division_code: divisionCode,
    settlement_accounts: {
      default_bank_account: get(defaultBankAccount, 'value.id'),
      credit_deposits: get(creditDeposits, 'value.id'),
      debit_deposits: get(debitDeposits, 'value.id'),
      credit_chargebacks: get(creditChargebacks, 'value.id'),
      debit_chargebacks: get(debitChargebacks, 'value.id'),
      credit_processing_fees: get(creditProcessingFees, 'value.id'),
      credit_convenience_fees: get(creditConvenienceFees, 'value.id'),
      debit_convenience_fees: get(debitConvenienceFees, 'value.id'),
    },
  }

  const identityId = get(identity, 'id')
  const normalizedGateway = gateway && (gateway !== processor) ? gateway : undefined

  dispatch({
    type: POST_IDENTITY_MERCHANTS_F_REQUEST,
    payload: {
      credentials,
      values: removeUndefined({
        identity: identityId,
        processor,
        gateway: normalizedGateway,
        processor_specific_parameters: optionalProcessorSpecificParameters,
      }),
    },
    meta: {
      selectorId: identityId,
      actionId: sendAmplitudeActionEvent(ENABLE_PROVISION_MERCHANT, {
        credentials,
        identityId,
        processor,
      }),
    },
  })
}

export default submitProvisionMerchantProcessorsForm
