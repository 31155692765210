import './ResendEmailFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import { H_CAPTCHA_SITE_KEY_INVISIBLE } from 'constants/hCaptchaConstants'
import { RESEND_EMAIL_FORM } from 'constants/formConstants'
import validateResendEmailForm from 'utilities/validate/validateResendEmailForm'
import { RESEND } from 'constants/language/languageConstants'

const ResendEmailForm = ({
  handleSubmit = () => {},
  resendEmail = () => {},
  onVerifyCaptcha = () => {},
  hCaptchaFormError,
}) => {
  return (
    <form className='ResendEmailForm' onSubmit={handleSubmit(resendEmail)}>
      <div className='resend-email-warning'>To protect against fraud and spam, please verify you are human.</div>
      <HCaptcha
        id='verify-email-invisible-h-captcha'
        sitekey={H_CAPTCHA_SITE_KEY_INVISIBLE}
        onVerify={(token) => onVerifyCaptcha(token)}
      />
      {hCaptchaFormError && <div className='hcaptcha-error'>Please verify you are human.</div>}
      <Field
        name='hCaptchaToken'
        component={InputField}
        className='hcpatcha-hidden-input'
      />
      <div className='buttons flex flex-end'>
        <Button type='submit' label={RESEND} />
      </div>
    </form>
  )
}

ResendEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  resendEmail: PropTypes.func,
  onVerifyCaptcha: PropTypes.func,
  hCaptchaFormError: PropTypes.bool,
}

export default reduxForm({
  form: RESEND_EMAIL_FORM,
  validate: validateResendEmailForm,
})(ResendEmailForm)
