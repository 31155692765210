import {
  fieldEmpty,
  invalidRegex,
} from 'utilities/validate'

import {
  USA_POSTAL_CODE_REGEX,
} from 'constants/regexConstants'

import size from 'lodash/size'

import {
  CARD_ACCEPTOR_ID_CODE,
  COUNTRY_ERROR_MSG,
  DEFAULT_CURRENCIES,
  DEFAULT_PAYMENT_TYPE,
  DEFAULT_SENDER_ADDRESS_LINE_1,
  DEFAULT_SENDER_CITY,
  DEFAULT_SENDER_COUNTRY,
  DEFAULT_SENDER_COUNTRY_CODE,
  DEFAULT_SENDER_FIRST_NAME,
  DEFAULT_SENDER_FULL_NAME,
  DEFAULT_SENDER_LAST_NAME,
  DEFAULT_SENDER_POSTAL_CODE,
  DEFAULT_SENDER_SUBDIVISION,
  MC_ACCEPTOR_ID_CODE_ERROR_MSG,
  MC_DEFAULT_SENDER_COUNTRY_CODE_ERROR_MSG,
  MC_DEFAULT_STATEMENT_DESCRIPTOR,
  VISA_ACCEPTOR_ID_CODE_ERROR_MSG,
  VISA_ALLOWED_BUSINESS_APPLICATION_IDS,
  VISA_CARD_ACCEPTOR_ID_CODE,
  VISA_CARD_ACCEPTOR_TERMINAL_ID,
  VISA_CARD_ACCEPTOR_TERMINAL_ID_ERROR_MSG,
  VISA_DEFAULT_SENDER_ADDRESS,
  VISA_DEFAULT_SENDER_CITY,
  VISA_DEFAULT_SENDER_STATE_CODE,
  VISA_DEFAULT_SENDER_ZIP_CODE,
  VISA_DEFAULT_SENDER_NAME,
} from 'constants/language/languageConstants'

const validateSubmitEditPayoutConfigsForm = (values) => {
  const {
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    visaDefaultSenderName,
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    mcSubdivision,
    mcStatementDescriptor,
  } = values
  return {
    visaAllowedBusinessApplicationIds: fieldEmpty(visaAllowedBusinessApplicationIds, VISA_ALLOWED_BUSINESS_APPLICATION_IDS),
    visaCardAcceptorIdCode: fieldEmpty(visaCardAcceptorIdCode, VISA_CARD_ACCEPTOR_ID_CODE) || (size(visaCardAcceptorIdCode) !== 15 && VISA_ACCEPTOR_ID_CODE_ERROR_MSG),
    visaAcceptorTerminalId: fieldEmpty(visaAcceptorTerminalId, VISA_CARD_ACCEPTOR_TERMINAL_ID) || (size(visaAcceptorTerminalId) !== 8 && VISA_CARD_ACCEPTOR_TERMINAL_ID_ERROR_MSG),
    visaDefaultCurrencies: fieldEmpty(visaDefaultCurrencies, DEFAULT_CURRENCIES),
    visaDefaultSenderAddress: fieldEmpty(visaDefaultSenderAddress, VISA_DEFAULT_SENDER_ADDRESS),
    visaDefaultSenderCity: fieldEmpty(visaDefaultSenderCity, VISA_DEFAULT_SENDER_CITY),
    visaDefaultSenderStateCode: fieldEmpty(visaDefaultSenderStateCode, VISA_DEFAULT_SENDER_STATE_CODE),
    visaDefaultSenderZipCode: fieldEmpty(visaDefaultSenderZipCode, VISA_DEFAULT_SENDER_ZIP_CODE) || invalidRegex({ field: visaDefaultSenderZipCode, name: VISA_DEFAULT_SENDER_ZIP_CODE, regex: USA_POSTAL_CODE_REGEX, customErrorMessage: `${VISA_DEFAULT_SENDER_ZIP_CODE} is not in correct format.` }),
    visaDefaultSenderCountryCode: fieldEmpty(visaDefaultSenderCountryCode, DEFAULT_SENDER_COUNTRY_CODE) || (size(visaDefaultSenderCountryCode) > 3 && COUNTRY_ERROR_MSG) || (size(visaDefaultSenderCountryCode) < 3 && COUNTRY_ERROR_MSG),
    visaDefaultSenderCountry: fieldEmpty(visaDefaultSenderCountry, DEFAULT_SENDER_COUNTRY) || (size(visaDefaultSenderCountry) > 3 && COUNTRY_ERROR_MSG),
    visaDefaultSenderName: fieldEmpty(visaDefaultSenderName, VISA_DEFAULT_SENDER_NAME),
    mcCardAcceptorId: fieldEmpty(mcCardAcceptorId, CARD_ACCEPTOR_ID_CODE) || (size(mcCardAcceptorId) !== 15 && MC_ACCEPTOR_ID_CODE_ERROR_MSG),
    mcDefaultPaymentType: fieldEmpty(mcDefaultPaymentType, DEFAULT_PAYMENT_TYPE),
    mcDefaultCurrencies: fieldEmpty(mcDefaultCurrencies, DEFAULT_CURRENCIES),
    mcDefaultSenderFirstName: fieldEmpty(mcDefaultSenderFirstName, DEFAULT_SENDER_FIRST_NAME),
    mcDefaultSenderLastName: fieldEmpty(mcDefaultSenderLastName, DEFAULT_SENDER_LAST_NAME),
    mcDefaultSenderFullName: fieldEmpty(mcDefaultSenderFullName, DEFAULT_SENDER_FULL_NAME),
    mcDefaultSenderAddressLine1: fieldEmpty(mcDefaultSenderAddressLine1, DEFAULT_SENDER_ADDRESS_LINE_1),
    mcDefaultSenderCity: fieldEmpty(mcDefaultSenderCity, DEFAULT_SENDER_CITY),
    mcDefaultSenderPostalCode: fieldEmpty(mcDefaultSenderPostalCode, DEFAULT_SENDER_POSTAL_CODE) || invalidRegex({ field: mcDefaultSenderPostalCode, name: DEFAULT_SENDER_POSTAL_CODE, regex: USA_POSTAL_CODE_REGEX, customErrorMessage: `${DEFAULT_SENDER_POSTAL_CODE} is not in correct format.` }),
    mcDefaultSenderCountry: fieldEmpty(mcDefaultSenderCountry, DEFAULT_SENDER_COUNTRY) || (size(mcDefaultSenderCountry) > 3 && COUNTRY_ERROR_MSG) || (size(mcDefaultSenderCountry) < 3 && COUNTRY_ERROR_MSG),
    mcDefaultSenderCountryCode: fieldEmpty(mcDefaultSenderCountryCode, DEFAULT_SENDER_COUNTRY_CODE) || (size(mcDefaultSenderCountryCode) > 3 && MC_DEFAULT_SENDER_COUNTRY_CODE_ERROR_MSG) || (size(mcDefaultSenderCountryCode) < 2 && MC_DEFAULT_SENDER_COUNTRY_CODE_ERROR_MSG),
    mcSubdivision: fieldEmpty(mcSubdivision, DEFAULT_SENDER_SUBDIVISION),
    mcStatementDescriptor: fieldEmpty(mcStatementDescriptor, MC_DEFAULT_STATEMENT_DESCRIPTOR),
  }
}

export default validateSubmitEditPayoutConfigsForm
