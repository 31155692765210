import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change, isInvalid, hasSubmitFailed } from 'redux-form'
import { FETCHING } from 'constants/reducerConstants'
import { getAccessFormSelector } from 'state-layer/selectors'
import LiveAccountApplicationBusinessForm from 'components/Customer/Forms/LiveAccountApplicationBusinessForm/LiveAccountApplicationBusinessForm'
import Loader from 'components/Shared/Loader/Loader'
import removeUndefined from 'utilities/remove/removeUndefined'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM } from 'constants/formConstants'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'
import { CAN } from 'constants/countryConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

import {
  PUBLIC,
  PRIVATE,
  GOVERNMENT_AGENCY_VALUE,
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
  TAX_EXEMPT_ORGANIZATION_VALUE,
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
} from 'constants/identityConstants'

import {
  PUBLIC as PUBLIC_LABEL,
  PRIVATE as PRIVATE_LABEL,
} from 'constants/language/languageConstants'

const OWNERSHIP_TYPE_OPTIONS = [
  { label: PUBLIC_LABEL, value: PUBLIC },
  { label: PRIVATE_LABEL, value: PRIVATE },
]

const mapStateToProps = (state, props) => {
  const accessFormId = get(props, 'accessFormId')
  const accessFormCountry = get(props, 'accessFormCountry')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const credentials = getCurrentCredentials(state)
  const currentCompanyName = get(credentials, 'name')
  const dataLoaded = !isEmpty(accessForm)

  const [
    businessData,
    einVerificationFile,
    customerType,
  ] = getMany(accessForm, [
    'businessData',
    'einVerificationFile',
    'customerType',
  ])

  const formSelector = formValueSelector(LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM)
  const einVerificationFileName = get(formSelector(state, 'einVerificationFile.file'), 'name') || get(formSelector(state, 'einVerificationFile'), 'name')
  const businessType = formSelector(state, 'businessData.businessType')
  const isCanadianSoleProp = businessType === INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE && accessFormCountry === CAN
  const hideOwnershipTypeDropdown = includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)
  const einVerificationDocumentErrorMsg = get(state, 'form.LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM.syncErrors.einVerificationFile', '')
  const isFormInvalid = isInvalid(LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM)(state) && hasSubmitFailed(LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM)(state)
  const initialValues = dataLoaded ? merge({}, { businessData: { businessName: currentCompanyName } }, { businessData, einVerificationFile }) : undefined

  return removeUndefined({
    ownershipTypeOptions: OWNERSHIP_TYPE_OPTIONS,
    isStandaloneMerchant: customerType === STANDALONE_MERCHANT,
    einVerificationFileName,
    businessType,
    isCanadianSoleProp,
    hideOwnershipTypeDropdown,
    einVerificationDocumentErrorMsg,
    initialValues,
    isFormInvalid,
    dataLoaded,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    removeVerificationFile: () => dispatch(change(LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM, 'einVerificationFile', null)),
  }
}

class LiveAccountApplicationBusinessFormC extends Component {
  componentDidUpdate(prevProps) {
    const { dispatch, businessType } = this.props
    const { businessType: prevBusinessType } = prevProps

    if (businessType !== prevBusinessType && (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType))) {
      dispatch(change(LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM, 'ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType]))
    }
  }

  render() {
    const { dataLoaded } = this.props
    if (!dataLoaded) return <Loader />

    return (
      <LiveAccountApplicationBusinessForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationBusinessFormC)
