
import removeUndefined from 'utilities/remove/removeUndefined'
import { CUSTOM } from 'constants/settlementConstants'
import { USD } from 'constants/currencyConstants'

const backendFeeModel = ({ values }) => {
  const {
    amount,
    merchant,
    label,
    tags,
  } = values

  return removeUndefined({
    amount,
    merchant,
    label,
    currency: USD,
    fee_type: CUSTOM,
    fee_subtype: CUSTOM,
    tags,
  })
}

export default backendFeeModel
