import './IdentityUploaderFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import submitIdentityUploaderForm from 'utilities/submit/submitIdentityUploaderForm'
import { IDENTITY_UPLOADER_FORM } from 'constants/formConstants'
import { CSV_FILE_ICON } from 'constants/iconConstants'
import { validateFile } from 'utilities/validate'

import {
  RESET,
  SUBMIT,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 200000,
  accept: 'text/csv',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag & Drop or click to select a csv',
  },
  {
    name: 'sub',
    message: 'Max Size: 200 KB',
  },
]

const required = (value) => validateFile(value, config)

const IdentityUploaderForm = ({
  handleSubmit = () => {},
  clearIdentityUploaderReducer = () => {},
  totalCount = 0,
  invalid = false,
}) => {
  return (
    <form className='IdentityUploaderForm' onSubmit={handleSubmit(submitIdentityUploaderForm)}>
      <h2><a className='text-link' href='https://docs.google.com/spreadsheets/d/1181O1LBRS22_zgoF3mucqc38OeSy5ixZtBCF3HEFvj4/edit?usp=sharing' target='blank'>CSV Template - See Comments</a></h2>

      <div className='section'>
        <Field
          name='file'
          config={config}
          instructions={instructions}
          component={FileUploader}
          validate={required}
          fileIcon={`fal fa-${CSV_FILE_ICON}`}
        />
      </div>

      <div className='buttons flex flex-end'>
        {totalCount > 0 && <Button onClick={clearIdentityUploaderReducer} label={RESET} variant='secondary' className='reset-count-button' />}
        <Button type='submit' disabled={invalid} label={SUBMIT} />
      </div>
    </form>
  )
}

IdentityUploaderForm.propTypes = {
  handleSubmit: PropTypes.func,
  clearIdentityUploaderReducer: PropTypes.func,
  totalCount: PropTypes.number,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: IDENTITY_UPLOADER_FORM,
})(IdentityUploaderForm)
