import dashboardAPI from 'utilities/api/dashboardAPI'
import { TRANSACTION_CHARTS } from 'constants/apiConstants'

const getTransactionChartsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: TRANSACTION_CHARTS,
    meta,
    queries,
  })
}

export default getTransactionChartsAPI
