import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getPlatformUserAPI from 'api/finix/get/getPlatformUserAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import merge from 'lodash/merge'
import co from 'co'

function * getSettingsO ({
  id,
  credentials,
}) {
  const { username } = credentials

  const { data: userResponse } = yield getPlatformUserAPI({ id: username, credentials })
  const { identity } = userResponse

  const { data: response } = yield getIdentityAPI({ id: identity || get(credentials, 'identityId'), credentials })
  const { application } = response

  const settings = merge({}, response, {
    application: {
      id: application,
    },
  })

  return createSuccessResponse({
    data: [settings],
  })
}

export default co.wrap(getSettingsO)
