import './DownloadVerificationFileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { DOWNLOAD_ICON } from 'constants/iconConstants'
import { APPLE_PAY_DOMAIN_VERIFICATION_FILE_INSTRUCTION } from 'constants/language/languageConstants'

const DownloadVerificationFileForm = ({
  buttonGroup = {},
  onSubmit = () => {},
  verificationFile = '',
}) => {
  return (
    <form className='DownloadVerificationFileForm' onSubmit={onSubmit}>
      <div className='primary-msg'>Download verification file</div>
      <div className='msg'>{APPLE_PAY_DOMAIN_VERIFICATION_FILE_INSTRUCTION}</div>

      <a className='large download-verification-btn' href={verificationFile} download>
        <div className='flex container items-center'>
          <i className={`fa fa-${DOWNLOAD_ICON}`} />
          <div>apple-developer-merchantid-domain-association</div>
        </div>
      </a>

      { buttonGroup }
    </form>
  )
}

DownloadVerificationFileForm.propTypes = {
  buttonGroup: PropTypes.object,
  onSubmit: PropTypes.func,
  verificationFile: PropTypes.string,
}

export default DownloadVerificationFileForm
