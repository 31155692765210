import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableItems from './TableItems'
import { getSelectedItemsByKeySelector } from 'state-layer/selectors'
import selectItemsHandler from 'utilities/actions/selectItemsHandler'
import showPreviewPanelAction from 'utilities/actions/showPreviewPanelAction'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const selectedItemsKey = get(props, 'selectedItemsKey')
  const selectedItems = selectedItemsKey ? getSelectedItemsByKeySelector(state, selectedItemsKey) : {}
  const activePanel = get(state, 'panelR.show')
  const activeResourceId = get(state, 'panelR.panelProps.resourceId')

  return {
    selectedItems,
    selectedItemsKey,
    activePanel,
    activeResourceId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectItemsHandlerAction: (selectProps) => dispatch(selectItemsHandler(selectProps)),
    slideoutPreviewPanel: (panelProps) => dispatch(showPreviewPanelAction({ panelProps })),
  }
}

class TableItemsC extends Component {
  selectItemHandler = ({ event, objectId: itemId, checked, singleSelection = false }) => {
    const {
      selectedItemsKey,
      selectedRowAction,
      selectItemsHandlerAction,
    } = this.props

    // TODO: REFACTOR
    if (singleSelection) {
      selectedRowAction({ itemId })
    }

    if (allowedKeyPressControls(event)) {
      selectItemsHandlerAction({ checked, selectedItemsKey, itemId })
    }
  }

  showPreviewPanel = ({
    resourceId,
    viewPageLink,
    Component: PanelComponent,
    prevId,
    nextId,
    onClickHandler,
  }) => {
    const { slideoutPreviewPanel } = this.props

    slideoutPreviewPanel({
      resourceId,
      viewPageLink,
      PanelComponent,
      prevId,
      nextId,
      onClickHandler,
    })
  }

  render() {
    return (
      <TableItems
        {...this.props}
        showPreviewPanel={this.showPreviewPanel}
        selectItemHandler={this.selectItemHandler}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableItemsC)
