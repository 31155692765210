import includes from 'lodash/includes'
import get from 'lodash/get'

export const MOUSE_CLICK_KEYCODE = undefined
export const ENTER_KEYCODE = 13
export const SPACE_BAR_KEYCODE = 32

export const KEY_CONTROLS = [ENTER_KEYCODE, SPACE_BAR_KEYCODE]

// only trigger action upon mouse click (undefined), <enter> key, or <space> key
const ALLOWED_KEY_PRESS_CONTROLS = [MOUSE_CLICK_KEYCODE, ENTER_KEYCODE, SPACE_BAR_KEYCODE]

export const allowedKeyPressControls = (e) => {
  return includes(ALLOWED_KEY_PRESS_CONTROLS, get(e, 'keyCode'))
}

export const preventDefaultEvent = (e) => {
  if (includes(KEY_CONTROLS, get(e, 'keyCode'))) {
    e.preventDefault()
  }
}

