import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionSchedule from './SubscriptionSchedule'
import SubscriptionScheduleInfoC from 'components/Admin/Pages/SubscriptionBilling/SubscriptionSchedule/SubscriptionScheduleInfo/SubscriptionScheduleInfoC'
import SubscriptionScheduleEnrollmentsC from 'components/Admin/Pages/SubscriptionBilling/SubscriptionSchedule/SubscriptionScheduleEnrollments/SubscriptionScheduleEnrollmentsC'
import { getSubscriptionScheduleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getSubscriptionScheduleRequest from 'utilities/actions/get/getSubscriptionScheduleRequest'
import getSubscriptionScheduleAmountsRequest from 'utilities/actions/get/getSubscriptionScheduleAmountsRequest'
import getSubscriptionScheduleEnrollmentsRequest from 'utilities/actions/get/getSubscriptionScheduleEnrollmentsRequest'
import getMany from 'utilities/get/getMany'
import { SUBSCRIPTION_BILLING_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionSchedulesR.${FETCHING}`)
  const isFetchingAmounts = get(state, `subscriptionScheduleAmountsR.${FETCHING}`)
  const isFetchingEnrollments = get(state, `subscriptionScheduleEnrollmentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const subscriptionScheduleId = get(props, 'params.subscriptionScheduleId')
  const subscriptionSchedule = getSubscriptionScheduleSelector(state, subscriptionScheduleId)
  const scheduleNickname = get(subscriptionSchedule, 'scheduleNickname')
  const numSubscriptionScheduleFees = get(state, 'subscriptionScheduleAmountsR.page.count')
  const numSubscriptionScheduleEnrollments = get(state, 'subscriptionScheduleEnrollmentsR.page.count')

  const [
    displayCreatedAt,
    subscriptionType,
  ] = getMany(subscriptionSchedule, [
    'displayCreatedAt',
    'subscriptionType',
  ])

  const contextBarData = {
    back: {
      label: 'Subscription Billing',
      path: SUBSCRIPTION_BILLING_PATH({ credentialId }),
    },
  }

  const headerData = {
    resource: {
      label: 'Subscription Schedule',
      id: subscriptionScheduleId,
    },
    items: [
      {
        value: <h1>{scheduleNickname}</h1>,
      },
      {
        label: 'Subscription Created',
        value: displayCreatedAt,
      },
      {
        label: 'Type',
        value: subscriptionScheduleTypeMapping[subscriptionType],
      },
      {
        label: '# of Schedule Amounts',
        value: isFetchingAmounts ? '-' : numSubscriptionScheduleFees,
      },
      {
        label: '# of Enrollments',
        value: isFetchingEnrollments ? '-' : numSubscriptionScheduleEnrollments,
      },
    ],
    isFetching,
  }

  const tabs = [
    {
      id: 'general-info',
      name: 'General Info',
      component: SubscriptionScheduleInfoC,
    },
    {
      id: 'subscription-enrollments',
      name: 'Subscription Enrollments',
      component: SubscriptionScheduleEnrollmentsC,
    },
  ]

  return {
    credentials,
    subscriptionSchedule,
    subscriptionScheduleId,
    contextBarData,
    headerData,
    tabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionSchedule: ({ credentials, subscriptionScheduleId }) => dispatch(getSubscriptionScheduleRequest({ credentials, subscriptionScheduleId })),
    getSubscriptionScheduleAmounts: ({ credentials, subscriptionScheduleId }) => dispatch(getSubscriptionScheduleAmountsRequest({
      subscriptionScheduleId,
      credentials,
      meta: { selectorId: subscriptionScheduleId },
    })),
    getSubscriptionScheduleEnrollments: ({ credentials, subscriptionScheduleId }) => dispatch(getSubscriptionScheduleEnrollmentsRequest({
      credentials,
      queries: { subscription_schedule_id: subscriptionScheduleId },
      meta: { selectorId: subscriptionScheduleId },
    })),
  }
}

class SubscriptionScheduleC extends Component {
  componentDidMount() {
    const {
      credentials,
      subscriptionScheduleId,
      getSubscriptionSchedule,
      getSubscriptionScheduleAmounts,
      getSubscriptionScheduleEnrollments,
    } = this.props

    getSubscriptionSchedule({ subscriptionScheduleId, credentials })
    getSubscriptionScheduleAmounts({ subscriptionScheduleId, credentials })
    getSubscriptionScheduleEnrollments({ subscriptionScheduleId, credentials })
  }

  render() {
    return (
      <SubscriptionSchedule
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionScheduleC)
