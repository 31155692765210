import './PayoutPlanNewConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import Button from 'components/Shared/Button/Button'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import submitPayoutPlanForms from 'utilities/submit/submitPayoutPlanForms'
import backendPayoutPlanModel from 'utilities/create/models/backendPayoutPlanModel'
import normalizeObject from 'utilities/api/normalizeObject'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import map from 'lodash/map'
import slice from 'lodash/slice'
import merge from 'lodash/merge'
import { SUBMIT } from 'constants/language/languageConstants'

const PayoutPlanNewConfirmation = ({
  credentials,
  dispatch,
  goToStep = () => {},
  steps = [],
  formValues = {},
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
}) => {
  const inputSteps = slice(steps, 0, -1)
  const { payoutPlanForm, templatesForm, schedulesForm, combinationPlansForm } = formValues
  const formattedValues = merge({}, payoutPlanForm, templatesForm, schedulesForm, combinationPlansForm)
  const normalizedPayoutPlanModel = normalizeObject(backendPayoutPlanModel({ values: formattedValues }), backendNormalizationMap)
  const { name: payoutPlanName, platform_id: platformId, processor, templates, schedules, combination_plans: combinationPlans } = normalizedPayoutPlanModel
  const data = [{ name: payoutPlanName, platform_id: platformId, processor }, { templates }, { schedules }, { combination_plans: combinationPlans }]
  const createPayoutPlan = () => submitPayoutPlanForms(formattedValues, dispatch, credentials)

  return (
    <div className='PayoutPlanNewConfirmation'>
      <h2>Confirmation</h2>
      { map(inputSteps, (step, idx) => {
        const { name } = step

        const actions = [
          {
            label: 'Edit',
            action: () => { goToStep(idx, null, true) },
          },
        ]

        return (
          <PageSection
            title={name}
            actions={actions}
            component={PrettyJSON}
            data={data[idx]}
          />
        )
      })}

      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button variant='secondary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
        </div>

        <div className='right flex flex-end'>
          <Button onClick={createPayoutPlan} label={SUBMIT} />
        </div>
      </div>
    </div>
  )
}

PayoutPlanNewConfirmation.propTypes = {
  credentials: PropTypes.object,
  dispatch: PropTypes.func,
  goToStep: PropTypes.func,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
}

export default PayoutPlanNewConfirmation
