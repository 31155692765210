import frontendUnderwritingRunModel from 'utilities/create/models/frontendUnderwritingRunModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendUnderwritingRunsModel = ({ data: underwritingRuns }) => {
  const underwritingRunsModel = map(underwritingRuns, (underwritingRun) => frontendUnderwritingRunModel({ data: underwritingRun }))

  return keyBy(underwritingRunsModel, 'id')
}

export default frontendUnderwritingRunsModel
