import { GET_LATEST_NOTIFICATIONS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getLatestNotificationsRequest = ({ credentials, queries, meta }) => createAction({
  type: GET_LATEST_NOTIFICATIONS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getLatestNotificationsRequest
