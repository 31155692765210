import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  SUBSCRIPTION_SCHEDULE_AMOUNT,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const deleteSubscriptionScheduleAmountAPI = ({ id, values, queries, credentials, meta }) => {
  const subscriptionScheduleId = get(values, 'subscriptionScheduleId')

  return paymentsAPI.delete({
    meta,
    credentials,
    path: SUBSCRIPTION_SCHEDULE_AMOUNT({ subscriptionScheduleId, subscriptionAmountId: id }),
    queries,
    service: SUBSCRIPTION,
  })
}

export default deleteSubscriptionScheduleAmountAPI
