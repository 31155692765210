import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstrument from './PaymentInstrument'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import PaymentInstrumentInfoC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentInfo/PaymentInstrumentInfoC'
import PaymentInstrumentVerificationsC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentVerifications/PaymentInstrumentVerificationsC'
import PaymentInstrumentPaymentsC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentPayments/PaymentInstrumentPaymentsC'
import PaymentInstrumentTransactionsTabs from 'components/Shared/Tabs/PaymentInstrumentTransactionsTabs'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getApplicationProcessorsRequest from 'utilities/actions/get/getApplicationProcessorsRequest'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import { BANK_ACCOUNT } from 'constants/paymentInstrumentConstants'
import { getCardBrand } from 'constants/bankConstants'
import { enabledMap } from 'constants/statusConstants'
import { PATCH_PAYMENT_INSTRUMENT_F_REQUEST } from 'constants/flowConstants'
import { EDIT_LIGHT } from 'constants/iconConstants'
import { EDIT_PAYMENT_INSTRUMENT } from 'constants/modalConstants'
import { SHOW_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { goToPath } from 'state-layer/history'
import get from 'lodash/get'
import upperCase from 'lodash/upperCase'

import {
  isDisbursementsPartnerDashboard,
  isStandaloneMerchantDashboard,
} from 'utilities/is/isDashboardType'

import {
  getPaymentInstrumentWithApplication,
  getApplicationProcessorsSelector,
} from 'state-layer/selectors'

import {
  isPayoutFeature,
  notPayoutFeature,
} from 'utilities/validate/checkCredentialFeatures'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  PAYMENT_INSTRUMENTS_PATH,
  IDENTITY_PATH,
  CREATE_PAYMENT_PATH,
  RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  PAYOUTS,
  CARD_BRAND,
  GENERAL_INFO,
  TRANSACTIONS,
  MASKED_NUMBER,
  CREATED_ON,
  PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  IDENTITY_RESOURCE_TITLE,
  FINGERPRINT_ID,
  STATE,
  TYPE,
  VERIFICATIONS,
  CREATE_A_PAYMENT,
  DISABLE_PAYMENT_INSTRUMENT,
  ENABLE_PAYMENT_INSTRUMENT,
  CARD,
  COUNTRY,
  RECIPIENT_IDENTITY,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const credentialId = get(credentials, 'id')
  const isFetchingDashboardConfig = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const isFetchingPaymentInstruments = get(state, `paymentInstrumentsR.${FETCHING}`)
  const isFetching = isFetchingPaymentInstruments || isFetchingDashboardConfig
  const paymentInstrumentId = get(props, 'params.paymentInstrumentId')
  const paymentInstrument = getPaymentInstrumentWithApplication(state, paymentInstrumentId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isFlex = isFlexPlatform()
  const isDisbursements = isDisbursementsPartnerDashboard(state)

  const [
    paymentInstrumentName,
    displayPaymentInstrumentBrand,
    paymentInstrumentBrand,
    paymentInstrumentCreatedAt,
    maskedFullCardNumber,
    maskedAccountNumber,
    paymentInstrumentType,
    applicationId,
    applicationName,
    identityId,
    resourceTitle,
    displayEnabled,
    fingerprint,
    enabled,
    displayCountry,
    displayIssuerCountry,
  ] = getMany(paymentInstrument, [
    'name',
    'displayBrand',
    'brand',
    'displayCreatedAt',
    'maskedFullCardNumber',
    'maskedAccountNumber',
    'type',
    'applicationId',
    'application.businessName',
    'identity.id',
    'identity.resourceTitle',
    'displayEnabled',
    'fingerprint',
    'enabled',
    'displayCountry',
    'displayIssuerCountry',
  ])

  const availableProcessors = getApplicationProcessorsSelector(state, applicationId)
  const maskedPaymentInstrumentNumber = paymentInstrumentType === BANK_ACCOUNT ? maskedAccountNumber : maskedFullCardNumber

  const cardBrandIcon = getCardBrand(paymentInstrumentBrand)

  const tabs = [
    {
      id: 'general-info',
      name: GENERAL_INFO,
      component: PaymentInstrumentInfoC,
      props: {
        paymentInstrumentId,
        identityId,
      },
    },
    {
      id: 'transactions',
      name: !isFetching && isDisbursements ? PAYOUTS : TRANSACTIONS,
      component: !isFetching && isDisbursements ? PaymentInstrumentPaymentsC : PaymentInstrumentTransactionsTabs,
      props: {
        paymentInstrumentId,
        availableProcessors,
      },
      condition: notPayoutFeature({ credentials }) && !isFetching,
    },
    {
      id: 'payouts',
      name: PAYOUTS,
      component: PaymentInstrumentPaymentsC,
      props: {
        paymentInstrumentId,
      },
      condition: isPayoutFeature,
    },
    {
      id: 'verifications',
      name: VERIFICATIONS,
      component: PaymentInstrumentVerificationsC,
      props: {
        paymentInstrumentId,
        availableProcessors,
      },
      condition: isPayoutFeature,
    },
  ]

  const contextBarData = {
    back: {
      label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
      path: PAYMENT_INSTRUMENTS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant && !isDisbursements,
      },
      {
        label: isDisbursements ? RECIPIENT_IDENTITY : IDENTITY_RESOURCE_TITLE,
        value: resourceTitle,
        path: isDisbursements && hasPartnerAccess({ credentials }) ? RECIPIENT_PATH({ credentialId, recipientId: identityId }) : IDENTITY_PATH({ credentialId, identityId }),
        condition: !isStandaloneMerchant,
      },
    ],
  }

  const headerData = {
    resource: {
      label: PAYMENT_INSTRUMENT_RESOURCE_TITLE,
      id: paymentInstrumentId,
      additionalIds: [
        {
          label: FINGERPRINT_ID,
          id: fingerprint,
        },
      ],
    },
    items: [
      {
        value: <h1>{paymentInstrumentName ? paymentInstrumentName : `PI-${paymentInstrumentId && paymentInstrumentId.slice(-4)}`}</h1>,
      },
      {
        label: STATE,
        value: <ColorcodedStatus data={enabledMap} value={upperCase(displayEnabled)} />,
      },
      {
        label: CREATED_ON,
        value: paymentInstrumentCreatedAt,
      },
      {
        label: TYPE,
        value: paymentInstrumentType,
      },
      {
        label: CARD_BRAND,
        value: <div className='flex items-center'><span className={cardBrandIcon} /><div>&nbsp;{displayPaymentInstrumentBrand}</div></div>,
        condition: !!displayPaymentInstrumentBrand,
      },
      {
        label: MASKED_NUMBER,
        value: maskedPaymentInstrumentNumber,
      },
      {
        label: COUNTRY,
        value: paymentInstrumentType === 'Bank Account' ? displayCountry : displayIssuerCountry,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    paymentInstrumentId,
    paymentInstrument,
    credentials,
    tabs,
    contextBarData,
    headerData,
    applicationId,
    credentialId,
    identityId,
    enabled,
    isStandaloneMerchant,
    paymentInstrumentType,
    environment,
    isFlex,
    isDisbursements,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })),
    showEditPaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PAYMENT_INSTRUMENT, modalProps })),
    getApplicationProcessors: ({ applicationId, credentials }) => dispatch(getApplicationProcessorsRequest({
      applicationId,
      credentials,
      meta: {
        selectorId: applicationId,
      },
    })),
  }
}

class PaymentInstrumentC extends Component {
  componentDidMount() {
    const {
      credentials,
      paymentInstrumentId,
      getPaymentInstrument,
    } = this.props

    getPaymentInstrument({ paymentInstrumentId, credentials })
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId: prevApplicationId,
    } = prevProps

    const {
      getApplicationProcessors,
      credentials,
      applicationId,
      paymentInstrumentId,
    } = this.props

    if (!prevApplicationId && applicationId && isPayoutFeature({ credentials })) getApplicationProcessors({ credentials, applicationId, paymentInstrumentId })
  }

  render() {
    const {
      credentials,
      paymentInstrument,
      showEditPaymentInstrumentModal,
      headerData,
      credentialId,
      paymentInstrumentId,
      identityId,
      enabled,
      paymentInstrumentType,
      isFlex,
      environment,
      isDisbursements,
    } = this.props

    const actions = [
      {
        label: CREATE_A_PAYMENT,
        buttonClassName: 'create-a-payment-button',
        action: () => goToPath({
          pathname: CREATE_PAYMENT_PATH({ credentialId }),
          queries: {
            paymentInstrumentId,
            buyerIdentityId: identityId,
          },
        }),
        condition: isFlex && environment !== 'QA' && paymentInstrumentType === CARD && !isDisbursements && getFeatureFlag(SHOW_VIRTUAL_TERMINAL) && isAdministratorRole(),
      },
      {
        label: enabled ? DISABLE_PAYMENT_INSTRUMENT : ENABLE_PAYMENT_INSTRUMENT,
        buttonClassName: 'enable-disable-payment-instrument-button',
        action: () => {
          showEditPaymentInstrumentModal({
            paymentInstrument,
            actionType: PATCH_PAYMENT_INSTRUMENT_F_REQUEST,
            credentials,
          })
        },
        icon: EDIT_LIGHT,
      },
    ]

    const mergedHeaderData = { ...headerData, actions }

    return (
      <PaymentInstrument
        {...this.props}
        headerData={mergedHeaderData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInstrumentC)
