import React from 'react'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { TRANSACTION_MONITORING_ALERT_DETAILS } from 'constants/language/languageConstants'
import get from 'lodash/get'

const MonitoringAlertDetailsPanel = ({ monitoringAlert, detailsCardData }) => (
  <PageSectionV2C
    className='monitoring-alert-details'
    title={TRANSACTION_MONITORING_ALERT_DETAILS}
    subtitle={get(monitoringAlert, 'monitoringRuleDetails.description')}
    columns={detailsCardData}
  />
)

export default MonitoringAlertDetailsPanel
