import './SendEmailModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SendEmailFormC from 'components/Customer/Forms/SendEmailForm/SendEmailFormC'

const SendEmailModal = ({
  user = {},
  applicationId,
}) => {
  return (
    <GenericModal
      title='Send Email'
      className='SendEmailModal'
      Component={SendEmailFormC}
      user={user}
      applicationId={applicationId}
    />
  )
}

SendEmailModal.propTypes = {
  user: PropTypes.object,
  applicationId: PropTypes.string,
}

export default SendEmailModal
