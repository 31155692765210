import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISPUTE_UPDATE } from 'constants/apiConstants'

const patchUpdateDisputeAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: DISPUTE_UPDATE({ disputeId: id }),
  })
}

export default patchUpdateDisputeAPI
