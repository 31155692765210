import getTransferFeesAPI from 'api/finix/get/getTransferFeesAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'
import co from 'co'

function * getTransferFeesO ({
  id,
  credentials,
}) {
  const { data: response } = yield getTransferFeesAPI({ id, credentials })
  const transfers = get(response, '_embedded.transfers', [])

  return createSuccessResponse({
    data: map(transfers, (transfer) => merge({}, transfer, { parentId: id })),
  })
}

export default co.wrap(getTransferFeesO)
