import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ENTITY_CONFIGURATIONS } from 'constants/apiConstants'

const postEntityConfigurationAPI = ({
  values,
  meta,
}) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_ENTITY_CONFIGURATIONS,
    values,
    meta,
  })
}

export default postEntityConfigurationAPI
