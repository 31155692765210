import './CustomerOnboardingFormDealInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import validateCustomerOnboardingFormDealInfoForm from 'utilities/validate/validateCustomerOnboardingFormDealInfoForm'
import { goToPath } from 'state-layer/history'
import { APPLICATIONS_PATH } from 'constants/pathConstants'
import { CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM } from 'constants/formConstants'
import { countryOptionsForFlex } from 'constants/countryConstants'

import {
  CANCEL,
  DEAL_INFORMATION,
  ACCOUNT_EXECUTIVE,
  ACCOUNT_EXECUTIVE_SUBTITLE,
  CUSTOMER_PROFILE,
  OPPORTUNITY_NAME,
  ACCOUNT_EXECUTIVE_TOOLTIP,
  NEXT,
  COUNTRY,
  APPLICATION_COUNTRY_HELPER_TEXT,
  HUBSPOT_DEAL_ID,
} from 'constants/language/languageConstants'

const customerProfileOptions = [
  { value: 'STANDALONE_MERCHANT', label: 'Individual Business' },
  { value: 'DISBURSEMENT', label: 'Disbursements' },
  { value: 'MARKETPLACE', label: 'Marketplace' },
  { value: 'SOFTWARE_COMPANY', label: 'Software Platform' },
]

const CustomerOnboardingFormDealInfoForm = ({
  handleSubmit = () => {},
  credentialId = '',
  accountExecutiveDropdownOptions = [],
}) => {
  return (
    <form className='CustomerOnboardingDealInfoForm' onSubmit={handleSubmit}>
      <div className='form-header'>
        <h3>{DEAL_INFORMATION}</h3>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        <Field
          name='accountExecutive'
          label={ACCOUNT_EXECUTIVE}
          helperText={ACCOUNT_EXECUTIVE_SUBTITLE}
          tooltip={ACCOUNT_EXECUTIVE_TOOLTIP}
          component={ReactSelect}
          options={accountExecutiveDropdownOptions}
        />

        <Field
          name='hubspotDealId'
          label={HUBSPOT_DEAL_ID}
          component={InputField}
        />

        <Field
          name='opportunityName'
          label={OPPORTUNITY_NAME}
          component={InputField}
        />

        <Field
          name='country'
          label={COUNTRY}
          helperText={APPLICATION_COUNTRY_HELPER_TEXT}
          component={SelectField}
          options={countryOptionsForFlex}
        />

        <Field
          name='customerProfile'
          label={CUSTOMER_PROFILE}
          component={ReactSelect}
          options={customerProfileOptions}
        />
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button
              label={CANCEL}
              variant='ghost'
              size='large'
              onClick={() => goToPath({
                pathname: APPLICATIONS_PATH({ credentialId }),
                queries: { tab: 'onboarding-forms' },
              })}
            />
            <Button type='submit' label={NEXT} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

CustomerOnboardingFormDealInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  credentialId: PropTypes.string,
  accountExecutiveDropdownOptions: PropTypes.array,
}

export default reduxForm({
  form: CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM,
  validate: validateCustomerOnboardingFormDealInfoForm,
})(CustomerOnboardingFormDealInfoForm)
