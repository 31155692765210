import 'components/Customer/Forms/TagsForm/TagsFormS.scss'
import { FieldArray } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import Tags from 'components/Customer/Forms/TagsForm/Tags'

const TagsField = ({
  name = 'tags',
  label = 'Tags',
}) => {
  return (
    <div className='TagsField'>
      <label className='label-2'>{label}</label>
      <FieldArray name={name} component={Tags} />
    </div>
  )
}

TagsField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
}

export default TagsField
