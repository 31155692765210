import frontendCurrentUsageModel from './frontendCurrentUsageModel'
import uuidv4 from 'uuid/v4'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendCurrentUsagesModel = ({ data: currentUsages }) => {
  const currentUsagesModel = map(currentUsages, (currentUsage) => ({
    id: uuidv4(),
    ...frontendCurrentUsageModel({ data: currentUsage }),
  }))

  return keyBy(currentUsagesModel, 'id')
}

export default frontendCurrentUsagesModel
