import './IdentityPayoutsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Payouts/columnDescriptors'
import { IDENTITY_PAYOUTS } from 'constants/linkConstants'
import { GET_IDENTITY_TRANSFERS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getIdentityTransfers } from 'state-layer/selectors'

const IdentityPayouts = ({
  flowValues = {},
  initialQueries = {},
  paymentPath = '',
  isFetching = false,
  identityId = '',
  allowedFilters = [],
  quickFilters = [],
  initialFilters,
}) => {
  return (
    <div className='IdentityPayouts'>
      <TableC
        flow={GET_IDENTITY_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getIdentityTransfers}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_PAYOUTS}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={`Identity_${identityId}_Transfers`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        allowedFilters={allowedFilters}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

IdentityPayouts.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  allowedFilters: PropTypes.array,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default IdentityPayouts
