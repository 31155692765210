import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdditionalVerification from './AdditionalVerification'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import postCustomerOnboardingFormRequestChangesRequest from 'utilities/actions/post/postCustomerOnboardingFormRequestChangesRequest'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const customerOnboardingFormId = get(props, 'customerOnboardingFormId')

  return {
    credentials,
    customerOnboardingFormId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    requestAdditionalVerificationAction: ({
      credentials,
      customerOnboardingFormId,
    }) => dispatch(postCustomerOnboardingFormRequestChangesRequest({
      credentials,
      customerOnboardingFormId,
    })),
  }
}


class AdditionalVerificationC extends Component {
  requestAdditionalVerification = () => {
    const {
      credentials,
      requestAdditionalVerificationAction,
      customerOnboardingFormId,
    } = this.props

    requestAdditionalVerificationAction({
      credentials,
      customerOnboardingFormId,
    })
  }

  render() {
    return (
      <AdditionalVerification
        {...this.props}
        requestAdditionalVerification={this.requestAdditionalVerification}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalVerificationC)
