import './OrchestrationStatusS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'
import { CLOSE } from 'constants/language/languageConstants'
import every from 'lodash/every'
import get from 'lodash/get'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

const fieldNameNormalizeMap = {
  mcc: 'MCC',
  amex_m_i_d: 'Amex MID',
  discover_m_i_d: 'Discover MID',
  dob: 'Date of Birth',
  tax_id: 'Tax ID',

}
// TODO: move this to util and make actual mapping
const normalizeFieldName = (fieldName = '') => {
  const strippedName = fieldName.replace('entity.', '')
  const normalizedName = fieldNameNormalizeMap[strippedName] ? fieldNameNormalizeMap[strippedName] : startCase(toLower(strippedName.split('_').join(' ')))

  return normalizedName
}

const OrchestrationStatus = ({
  closeModal = () => {},
  orchestrationOrder = [],
  orchestrationData = {},
  orchestrationStatus = {},
  errors,
}) => {
  return (
    <div className='OrchestrationStatus'>
      <div className='section'>
        {map(orchestrationOrder, (stepName) => {
          const {
            name = '',
            dependency = [],
          } = get(orchestrationData, stepName, {})

          const completed = get(orchestrationStatus, stepName)
          const dependencySuccess = every(dependency, (d) => get(orchestrationStatus, d))
          const showError = completed === false && dependencySuccess // || (completed !== undefined && !dependencySuccess)

          const iconClass = classnames('fa', {
            'fa-check-circle': !!completed,
            'fa-exclamation-triangle': showError,
            'fa-question-circle': completed === undefined || !dependencySuccess,
          })

          return (
            <div key={stepName} className='step'>
              <div className='flex step-info'>
                <i className={iconClass} />
                <span className='step-name'>{name}</span>
              </div>
            </div>
          )
        })}
      </div>

      { errors && (
        <div className='errors'>
          {map(errors, ({ message, field }) => {
            const normalizedField = normalizeFieldName(field)

            return <p key={field}><span>{normalizedField}</span>: {message}</p>
          })}
        </div>
      )}

      <div className='section flex flex-end'>
        <Button onClick={closeModal} label={CLOSE} />
      </div>
    </div>
  )
}

OrchestrationStatus.propTypes = {
  closeModal: PropTypes.func,
  orchestrationOrder: PropTypes.array,
  orchestrationData: PropTypes.object,
  orchestrationStatus: PropTypes.object,
  errors: PropTypes.array,
}

export default OrchestrationStatus
