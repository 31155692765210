import moment from 'moment'
import { DATE_FORMAT } from 'constants/timeConstants'
import { PAYMENTS } from 'constants/language/languageConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import capitalize from 'lodash/capitalize'

import {
  AMOUNT_FILTERS,
  GREATER_THAN_EQUALS,
  LESS_THAN_EQUALS,
  IS_BETWEEN_LABEL,
  GREATER_THAN_LABEL,
  LESS_THAN_LABEL,
  GREATER_THAN_EQUALS_LABEL,
  LESS_THAN_EQUALS_LABEL,
  ON,
  TRANSFER_TYPE_VALUE_TO_DISPLAY_MAP,
  DATE_OPERAND_LABEL_MAP,
  CREATED_AT_FIELD,
  GREATER_THAN,
  LESS_THAN,
} from 'constants/filterConstants'

const displayCurrentFilterValue = ({
  filterKey,
  filterValueObj,
  resource = '',
}) => {
  if (!filterKey || !filterValueObj) {
    return ''
  }

  const operands = Object.keys(filterValueObj)

  if (operands.length === 1) {
    const filterVal = get(Object.values(filterValueObj), '[0]')
    const value = filterKey === CREATED_AT_FIELD ? moment(new Date(filterVal)).format(DATE_FORMAT) : `${includes(AMOUNT_FILTERS, filterKey) ? '$' : ''}${filterVal}`

    if (includes(operands, GREATER_THAN_EQUALS)) {
      const label = capitalize(filterKey === CREATED_AT_FIELD ? DATE_OPERAND_LABEL_MAP[GREATER_THAN_EQUALS] : GREATER_THAN_EQUALS_LABEL)

      return `${label} ${value}`
    }

    if (includes(operands, LESS_THAN_EQUALS)) {
      const label = capitalize(filterKey === CREATED_AT_FIELD ? DATE_OPERAND_LABEL_MAP[LESS_THAN_EQUALS] : LESS_THAN_EQUALS_LABEL)

      return `${label} ${value}`
    }

    if (includes(operands, GREATER_THAN)) {
      const label = capitalize(filterKey === CREATED_AT_FIELD ? DATE_OPERAND_LABEL_MAP[GREATER_THAN] : GREATER_THAN_LABEL)

      return `${label} ${value}`
    }

    if (includes(operands, LESS_THAN)) {
      const label = capitalize(filterKey === CREATED_AT_FIELD ? DATE_OPERAND_LABEL_MAP[LESS_THAN] : LESS_THAN_LABEL)

      return `${label} ${value}`
    }
  }

  if (operands.length === 2 && includes(operands, GREATER_THAN_EQUALS) && includes(operands, LESS_THAN_EQUALS)) {
    const greaterThanFilterValueObj = moment(new Date(get(filterValueObj, GREATER_THAN_EQUALS))).format(DATE_FORMAT)
    const lessThanFilterValueObj = moment(new Date(get(filterValueObj, LESS_THAN_EQUALS))).format(DATE_FORMAT)
    const on = isEqual(greaterThanFilterValueObj, lessThanFilterValueObj)
    return on ? `${capitalize(ON)} ${greaterThanFilterValueObj}` : `${capitalize(IS_BETWEEN_LABEL)} ${greaterThanFilterValueObj} and ${lessThanFilterValueObj}`
  }

  let filterValue = get(Object.values(filterValueObj), '[0]')

  if (resource === PAYMENTS && filterKey === 'type') {
    filterValue = TRANSFER_TYPE_VALUE_TO_DISPLAY_MAP[filterValue]
  }

  return `${includes(AMOUNT_FILTERS, filterKey) ? '$' : ''}${filterValue}`
}

export default displayCurrentFilterValue
