import './UpdateAdminToolsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import validateUpdateAdminToolsForm from 'utilities/validate/validateUpdateAdminToolsForm'
import submitUpdateAdminToolsForm from 'utilities/submit/submitUpdateAdminToolsForm'
import Button from 'components/Shared/Button/Button'

const UpdateAdminToolsForm = ({
  title = '',
  handleSubmit = () => {},
  submitLabel = 'Submit',
  stateOptions = [],
  codeOptions = [],
  taskTypeOptions = [],
  codeDisabled = false,
  hideType = false,
  formInvalid = true,
}) => {
  return (
    <form className='UpdateAdminToolsForm' onSubmit={handleSubmit(submitUpdateAdminToolsForm)}>
      <div className='section'>
        <h2>{title} Information</h2>

        <Field
          name='id'
          label={`${title} ID`}
          placeholder={`${title} ID`}
          component={TextAreaField}
        />

        <Field
          name='state'
          label={`${title} State`}
          component={SelectField}
          options={stateOptions}
        />

        <Field
          name='code'
          label={`${title} Code`}
          component={SelectField}
          disabled={codeDisabled}
          options={codeOptions}
        />

        {!hideType && (
          <Field
            name='type'
            label='Type'
            component={SelectField}
            options={taskTypeOptions}
          />
        )}
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={formInvalid} label={submitLabel} />
      </div>
    </form>
  )
}

UpdateAdminToolsForm.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  stateOptions: PropTypes.array,
  codeOptions: PropTypes.array,
  taskTypeOptions: PropTypes.array,
  codeDisabled: PropTypes.bool,
  hideType: PropTypes.bool,
  formInvalid: PropTypes.bool,
}

export default reduxForm({
  enableReinitialize: true,
  validate: validateUpdateAdminToolsForm,
})(UpdateAdminToolsForm)
