import selectOption from 'utilities/forms/selectOption'
import { ALL } from 'constants/transferConstants'
import { ALL as ALL_LABEL } from 'constants/language/languageConstants'

export const PENDING = 'PENDING'
export const AWAITING_APPROVAL = 'AWAITING_APPROVAL'
export const REJECTED = 'REJECTED'
export const ACCEPTED = 'ACCEPTED'
export const STAGED = 'STAGED'
export const APPROVED = 'APPROVED'
export const ACCRUING = 'ACCRUING'
export const PROVISIONING = 'PROVISIONING'

export const ACCRUING_STATUS = 'Accruing'
export const AWAITING_APPROVAL_STATUS = 'Awaiting Approval'
export const STAGED_STATUS = 'Staged Funding Instructions'
export const APPROVED_STATUS = 'Approved'
export const STOP_ACCRUING = 'Stop Accruing'

export const FEE = 'FEE'

export const CLOSE = 'CLOSE'

export const CUSTOM = 'CUSTOM'

export const SETTLEMENT_STATUS_OPTIONS = [
  selectOption(ALL_LABEL, ALL),
  selectOption(AWAITING_APPROVAL_STATUS, AWAITING_APPROVAL),
  selectOption(APPROVED_STATUS, APPROVED),
  selectOption(ACCRUING_STATUS, PENDING),
  // selectOption(STAGED_STATUS, STAGED), TODO: removed by Oscar, keep around in case we ever need it again
]

// TODO: name this map to be something less vague
export const settlementStatusMapping = {
  PENDING: ACCRUING,
  AWAITING_APPROVAL: AWAITING_APPROVAL,
  STAGED: STAGED,
  APPROVED: APPROVED,
}

export const ENTRY_NOT_IN_SETTLEMENT = 'Entry is not in settlement.'
export const ENTRY_EXTERNALLY_FUNDED = 'Entry is externally funded.'
