import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReversePhoneLookupReport from './ReversePhoneLookupReport'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  phoneOwnershipReportStatusMap,
  phoneAndEmailAddressReportStatusMap,
} from 'constants/statusConstants'

import {
  PHONE_CALLER_NAME,
  PHONE_CALLER_TYPE,
  PHONE_CARRIER,
  PHONE_LINE_TYPE,
  RESULT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    phoneOwnershipCheckMatchLevel,
    phoneOwnershipCheckDescription,
    phoneOwnershipCheckPhoneOwnerName,
    phoneLineTypeCheckResult,
    phoneLineTypeCheckDescription,
    phoneLineTypeCheckPhoneLineType,
    phoneLineTypeCheckPhoneLineDescription,
    phoneCallerType,
    phoneCarrier,
  ] = getMany(report, [
    'details.phone_ownership_check.match_level',
    'details.phone_ownership_check.description',
    'details.phone_ownership_check.phone_owner_name',
    'details.phone_line_type_check.result',
    'details.phone_line_type_check.description',
    'details.phone_line_type_check.phone_line_type_details.phone_line_type',
    'details.phone_line_type_check.phone_line_type_details.description',
    'details.phone_caller_type',
    'details.phone_carrier',
  ])

  const phoneOwnershipCheckData = convertPageSectionDataToV2([
    {
      label: RESULT,
      value: <ColorcodedStatus value={phoneOwnershipCheckMatchLevel} data={phoneOwnershipReportStatusMap} />,
      tooltip: phoneOwnershipCheckDescription,
    },
    {
      label: PHONE_CALLER_NAME,
      value: phoneOwnershipCheckPhoneOwnerName,
    },
  ])

  const phoneLineTypeCheckData = convertPageSectionDataToV2([
    {
      label: RESULT,
      value: <ColorcodedStatus value={phoneLineTypeCheckResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: phoneLineTypeCheckDescription,
    },
    {
      label: PHONE_LINE_TYPE,
      value: phoneLineTypeCheckPhoneLineType,
      tooltip: phoneLineTypeCheckPhoneLineDescription,
    },
  ])

  const additionalDetailsData = convertPageSectionDataToV2([
    {
      label: PHONE_CALLER_TYPE,
      value: phoneCallerType,
    },
    {
      label: PHONE_CARRIER,
      value: phoneCarrier,
    },
  ])

  return {
    credentials,
    phoneOwnershipCheckData,
    phoneLineTypeCheckData,
    additionalDetailsData,
  }
}

class ReversePhoneLookupReportC extends Component {
  render() {
    return (
      <ReversePhoneLookupReport {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ReversePhoneLookupReportC)
