import './HomeInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import SkeletonInsights from 'components/Shared/SkeletonInsights/SkeletonInsights'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import formatNumber from 'utilities/formatters/formatNumber'
import formatChartAmount from 'utilities/formatters/formatChartAmount'
import formatPercentage from 'utilities/formatters/formatPercentage'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isFlexPlatform } from 'constants/flexConstants'
import { SHOW_NET_PROFIT_TABLE } from 'constants/featureFlagConstants'
import { BUSINESS_ANALYTICS_LINK } from 'constants/urlConstants'

import {
  AUTHORIZATION_RATE,
  AUTHORIZATION_RATE_HOMEPAGE_TOOLTIP,
  AVG_TRANSACTION_AMOUNT,
  AVG_TRANSACTION_AMOUNT_HOMEPAGE_TOOLTIP,
  FEES_COLLECTED,
  HOME_INSIGHTS_SUBTITLE,
  LAST_MONTH_INSIGHTS,
  LAST_MONTH_INSIGHTS_TOOLTIP,
  MERCHANT_FEES_COLLECTED,
  MERCHANT_FEES_COLLECTED_HOMEPAGE_TOOLTIP,
  NET_PROFIT_ESTIMATE,
  NET_PROFIT_ESTIMATE_TOOLTIP_PT1,
  NET_PROFIT_ESTIMATE_TOOLTIP_PT2, SUMMARY,
  TOTAL_TRANSACTION_VOLUME,
  TOTAL_TRANSACTION_VOLUME_HOMEPAGE_TOOLTIP,
  TRANSACTION_TRENDS,
  YEAR_TO_DATE_INSIGHTS,
  YEAR_TO_DATE_INSIGHTS_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  HOME_PAGE_TOTAL_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL,
  HOME_PAGE_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL,
  HOME_PAGE_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_FEES_COLLECTED_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_FEES_COUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_TOTAL_TRANSACTION_VOLUME_AND_COUNT_MIXED_TIMESERIES,
  HOME_PAGE_AUTHORIZATION_RATE_MIXED_TIMESERIES,
  HOME_PAGE_MERCHANT_FEES_COLLECTED_ECHARTS_TIMESERIES_BAR,
  HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_ECHARTS_TIMESERIES_BAR,
  HOME_PAGE_MONTHLY_NET_PROFIT_TABLE,
  HOME_PAGE_TOTAL_RESIDUAL_AMOUNT_BIG_NUMBER_TOTAL,
  HOME_PAGE_AVERAGE_INTERCHANGE_PERCENT_BIG_NUMBER_TOTAL,
  HOME_PAGE_AVERAGE_DUES_AND_ASSESSMENTS_PERCENT_BIG_NUMBER_TOTAL,
  HOME_PAGE_CARD_PAYMENTS_BIG_NUMBER_TOTAL,
  HOME_PAGE_ACTIVE_MERCHANTS_BIG_NUMBER_TOTAL,
  HOME_PAGE_DISPUTES_BIG_NUMBER_TOTAL,
  HOME_PAGE_ACH_RETURNS_BIG_NUMBER_TOTAL,
  HOME_PAGE_CHARGEBACK_RATE_BIG_NUMBER_TOTAL,
  HOME_PAGE_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_TOTAL_DEPOSITS_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_SETTLEMENT_VOLUME_ECHARTS_TIMESERIES_BAR,
  HOME_PAGE_MERCHANT_DEPOSITS_ECHARTS_TIMESERIES_BAR,
  HOME_PAGE_MERCHANT_ACH_PAYMENTS_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_REFUNDS_BIG_NUMBER_TOTAL,
  HOME_PAGE_MERCHANT_TOTAL_TRANSACTIONS_BIG_NUMBER_TOTAL,
} from 'constants/chartConstants'

const HomeInsights = ({
  firstName = '',
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRoleMerchant = false,
}) => {
  const isStandaloneMerchant = isStandaloneMerchantDashboard()
  const welcomeTitle = firstName ? `Welcome, ${firstName}` : 'Welcome'
  const showNetProfitTable = getFeatureFlag(SHOW_NET_PROFIT_TABLE)

  return (
    <div className='HomeInsights flex column'>
      {isFetching && <SkeletonInsights /> }

      {!isFetching && (
        <HeaderV2C
          title={welcomeTitle}
          subTitle={HOME_INSIGHTS_SUBTITLE}
          learnMoreLink={BUSINESS_ANALYTICS_LINK}
        />
      )}

      {!isFetching && (
        <div className='time-frame-container flex space-between items-center'>
          <div className='time-frame flex'>
            <div className='time-frame-title'>Time Frame</div>
            <div className='time-frame-content'>
              <DatePickerNew
                name='created_at'
                initialFilters={createdAtFilters}
              />
            </div>
          </div>

          <div className='last-updated p-2'><LastUpdatedAtC /></div>
        </div>
      )}

      {!isFetching && (
      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>Summary of your Payment Insights for the time frame selected above.</p>
                <p><span className='bold'>{TOTAL_TRANSACTION_VOLUME}</span>{TOTAL_TRANSACTION_VOLUME_HOMEPAGE_TOOLTIP}</p>
                <p><span className='bold'>{AUTHORIZATION_RATE}</span>{AUTHORIZATION_RATE_HOMEPAGE_TOOLTIP}</p>
                <p><span className='bold'>{MERCHANT_FEES_COLLECTED}</span>{MERCHANT_FEES_COLLECTED_HOMEPAGE_TOOLTIP}</p>
                <p><span className='bold'>{AVG_TRANSACTION_AMOUNT}</span>{AVG_TRANSACTION_AMOUNT_HOMEPAGE_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={TOTAL_TRANSACTION_VOLUME}
            headerData={{
              name: HOME_PAGE_TOTAL_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: HOME_PAGE_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
            showSkeletonLoading
          />

          <CustomBigNumberC
            title={AUTHORIZATION_RATE}
            headerData={{
              name: HOME_PAGE_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL,
              formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
            }}
            subHeaderData={{
              name: HOME_PAGE_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} authorizations`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
            showSkeletonLoading
          />

          { isRoleMerchant ? (
            <CustomBigNumberC
              title='Total Deposits'
              headerData={{
                name: HOME_PAGE_MERCHANT_TOTAL_DEPOSITS_BIG_NUMBER_TOTAL,
                formatter: formatChartAmount,
              }}
              // subHeaderData={{
              //   name: HOME_PAGE_MERCHANT_FEES_COUNT_BIG_NUMBER_TOTAL,
              //   formatter: (count) => `${formatNumber(count)} fees`,
              // }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
              showSkeletonLoading
            />
          ) : (
            <CustomBigNumberC
              title={isStandaloneMerchant ? FEES_COLLECTED : MERCHANT_FEES_COLLECTED}
              headerData={{
                name: HOME_PAGE_MERCHANT_FEES_COLLECTED_BIG_NUMBER_TOTAL,
                formatter: formatChartAmount,
              }}
              subHeaderData={{
                name: HOME_PAGE_MERCHANT_FEES_COUNT_BIG_NUMBER_TOTAL,
                formatter: (count) => `${formatNumber(count)} fees`,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
              showSkeletonLoading
            />
          )}

          <CustomBigNumberC
            title={AVG_TRANSACTION_AMOUNT}
            headerData={{
              name: HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
            showSkeletonLoading
          />
        </div>

        <GroupedCharts
          title={TRANSACTION_TRENDS}
          classNames='transaction-trends'
          chartClassNames='transaction-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          skeletonLoadingHeight='324px'
          showSkeletonLoading
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: HOME_PAGE_TOTAL_TRANSACTION_VOLUME_AND_COUNT_MIXED_TIMESERIES, height: 260 },
            { name: HOME_PAGE_AUTHORIZATION_RATE_MIXED_TIMESERIES, height: 260 },
            { name: isRoleMerchant ? HOME_PAGE_MERCHANT_SETTLEMENT_VOLUME_ECHARTS_TIMESERIES_BAR : HOME_PAGE_MERCHANT_FEES_COLLECTED_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: isRoleMerchant ? HOME_PAGE_MERCHANT_DEPOSITS_ECHARTS_TIMESERIES_BAR : HOME_PAGE_AVERAGE_TRANSACTION_AMOUNT_ECHARTS_TIMESERIES_BAR, height: 260 },
          ]}
        />
      </div>
      )}

      {!isFetching && (
        <div className='content-padding'>
          { isFlexPlatform() && !isStandaloneMerchant && showNetProfitTable && (
          <ChartC
            title={NET_PROFIT_ESTIMATE}
            name={HOME_PAGE_MONTHLY_NET_PROFIT_TABLE}
            height={484}
            className='table'
            hideTitle
            showGroupBy
            timeRange={timeRange}
            showSkeletonLoading
            tooltipMessage={(
              <>
                <p>{NET_PROFIT_ESTIMATE_TOOLTIP_PT1}</p>
                <p> {NET_PROFIT_ESTIMATE_TOOLTIP_PT2}</p>
              </>
            )}
          />
          )}

          { !isStandaloneMerchant && (
          <GroupedCharts
            title={LAST_MONTH_INSIGHTS}
            classNames='summary'
            chartClassNames='transaction-trends-charts grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'
            showSkeletonLoading
            charts={[
              { name: HOME_PAGE_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: isRoleMerchant ? HOME_PAGE_CARD_PAYMENTS_BIG_NUMBER_TOTAL : HOME_PAGE_AVERAGE_INTERCHANGE_PERCENT_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: isRoleMerchant ? HOME_PAGE_MERCHANT_ACH_PAYMENTS_BIG_NUMBER_TOTAL : HOME_PAGE_AVERAGE_DUES_AND_ASSESSMENTS_PERCENT_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: isRoleMerchant ? HOME_PAGE_MERCHANT_REFUNDS_BIG_NUMBER_TOTAL : HOME_PAGE_CARD_PAYMENTS_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
            ]}
            tooltipMessage={(
              <>
                <p>{LAST_MONTH_INSIGHTS_TOOLTIP}</p>
              </>
            )}
          />
          )}

          <GroupedCharts
            title={YEAR_TO_DATE_INSIGHTS}
            classNames='summary'
            chartClassNames='transaction-trends-charts grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'
            charts={[
              { name: isRoleMerchant ? HOME_PAGE_MERCHANT_TOTAL_TRANSACTIONS_BIG_NUMBER_TOTAL : HOME_PAGE_ACTIVE_MERCHANTS_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: HOME_PAGE_DISPUTES_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: HOME_PAGE_ACH_RETURNS_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
              { name: HOME_PAGE_CHARGEBACK_RATE_BIG_NUMBER_TOTAL, height: 80, className: 'custom-big-number' },
            ]}
            showSkeletonLoading
            tooltipMessage={<p>{YEAR_TO_DATE_INSIGHTS_TOOLTIP}</p>}
          />
        </div>
      )}
    </div>
  )
}

HomeInsights.propTypes = {
  firstName: PropTypes.string,
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  isRoleMerchant: PropTypes.bool,
}

export default HomeInsights
