import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityMerchants from './IdentityMerchants'
import { FETCHING } from 'constants/reducerConstants'
import { IDENTITY_PROVISION_MERCHANT } from 'constants/modalConstants'
import { IDENTITY_MERCHANTS_PATH } from 'constants/pathConstants'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import showModalAction from 'utilities/actions/showModalAction'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `identityMerchantsR.${FETCHING}`)
  const isRolePartnerOrPlatform = hasPartnerAccess({ credentials })
  const merchantPath = isRolePartnerOrPlatform ? IDENTITY_MERCHANTS_PATH({ identityId, credentialId }) : undefined
  const isFetchingIdentity = !identityId

  const flowValues = {
    identityId,
  }

  return {
    credentials,
    identityId,
    flowValues,
    isFetching,
    merchantPath,
    isFetchingIdentity,
    isRolePartnerOrPlatform,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showProvisionMerchantModal: (modalProps) => dispatch(showModalAction({ modalType: IDENTITY_PROVISION_MERCHANT, modalProps })),
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}

class IdentityMerchantsC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    if (identityId) getIdentity({ identityId, credentials })
  }

  showIdentityProvisionMerchantModal = () => {
    const {
      identityId,
      showProvisionMerchantModal,
      settlementAliasWarningObject,
    } = this.props

    showProvisionMerchantModal({ identityId, settlementAliasWarningObject })
  }

  render() {
    const { showIdentityProvisionMerchantModal } = this
    const { isRolePartnerOrPlatform } = this.props

    const actions = [
      {
        label: 'Provision Merchant',
        action: showIdentityProvisionMerchantModal,
        className: 'provision-merchant-button',
        condition: isRolePartnerOrPlatform,
      },
    ]

    return (
      <IdentityMerchants
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityMerchantsC)
