import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tabs from './Tabs'
import { getState } from 'state-layer/configureStore'
import { hasSecondaryTabSelector } from 'state-layer/selectors'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import updateUrlQueries from 'utilities/updateUrlQueries'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPageName from 'utilities/get/getPageName'
import { sendAmplitudePageViewEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import getCurrentUser from 'utilities/get/getCurrentUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import getUrlQuery from 'utilities/get/getUrlQuery'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

import {
  UNSELECT_ALL_ITEMS,
  RENDER_TAB,
  CLEAR_TABLE_FILTERS,
} from 'constants/flowConstants'

import {
  PATHNAME,
  SECONDARY_TAB,
  TAB,
} from 'constants/queryConstants'

const mapStateToProps = (state) => {
  const tabQuery = get(state, TAB)
  const secondaryTabQuery = get(state, SECONDARY_TAB)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser()
  const platformId = get(currentUser, 'platformId')
  const hasSecondaryTabs = hasSecondaryTabSelector(state)

  return {
    tabQuery,
    secondaryTabQuery,
    credentials,
    platformId,
    hasSecondaryTabs,
  }
}

class TabsC extends Component {
  constructor(props) {
    super(props)

    this.tabsRef = React.createRef()

    const {
      tabs,
      tabQuery,
      isSecondaryTab,
      secondaryTabQuery,
      credentials,
      dispatch,
    } = this.props

    const firstAvailableTabIndex = findIndex(tabs, ({ condition = () => true }) => (isFunction(condition) ? condition({ credentials }) : condition))
    const activeTabQuery = isSecondaryTab ? secondaryTabQuery : tabQuery
    const tabQueryIndex = findIndex(tabs, ({ id }) => id === activeTabQuery)
    const activeTabIndex = tabQueryIndex > 0 ? tabQueryIndex : firstAvailableTabIndex

    // Update TabsR with active tabs on page load
    const activeTab = tabs[activeTabIndex]
    const activeTabId = get(activeTab, 'id')

    dispatch({
      type: RENDER_TAB,
      payload: {
        values: {
          tabQuery: activeTabId,
          isSecondaryTab,
        },
      },
    })

    this.state = removeUndefined({
      activeTab: activeTabIndex,
      isScrollableLeft: false,
      isScrollableRight: false,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.handleScroll(0))
    this.handleScroll()
  }

  // TODO: find less hacky fix to address Tabs crashing app when all tabs have conditions
  // that require credentials or currentUser to be fetched
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { platformId: prevPlatformId, credentials: prevCredentials } = prevProps
    const { tabs, platformId, credentials } = this.props
    const { activeTab } = this.state

    if (((!prevPlatformId && platformId) || (isEmpty(prevCredentials) && !isEmpty(credentials))) && activeTab === -1) {
      const firstAvailableTabIndex = findIndex(tabs, ({ condition = () => true }) => condition({ credentials }))

      this.setState({ activeTab: firstAvailableTabIndex })
    }

    if (isEmpty(prevCredentials) && !isEmpty(credentials)) {
      this.handleScroll()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleScroll(0))
  }

  tabClickHandler = (index = 0) => {
    const {
      tabs,
      dispatch,
      credentials,
      isSecondaryTab,
      persistQueries = [],
    } = this.props

    const nextTab = tabs[index]
    const tabId = get(nextTab, 'id')
    const tabName = get(nextTab, 'name')
    const tabQuery = get(nextTab, 'id')

    this.setState({ activeTab: index })

    dispatch({
      type: UNSELECT_ALL_ITEMS,
    })

    dispatch({
      type: RENDER_TAB,
      payload: {
        values: {
          tabQuery,
          isSecondaryTab,
        },
      },
    })

    const state = getState()
    const pathName = get(state, PATHNAME)
    const pageName = getPageName(pathName)
    const currentQueries = parseUrlQueries()

    const persistedQueries = removeUndefined(reduce(persistQueries, (total, query) => {
      return merge({}, total, {
        [query]: get(currentQueries, query),
      })
    }, {}))

    sendAmplitudePageViewEvent({
      page: `${pageName} ${tabName} Tab`,
      credentials,
      fullUrl: pathName,
    })

    if (isSecondaryTab) {
      updateUrlQueries({ ...persistedQueries, secondaryTab: tabId, tab: getUrlQuery('tab') }, true)
    } else {
      updateUrlQueries({ ...persistedQueries, tab: tabId }, true)
    }

    dispatch({ type: CLEAR_TABLE_FILTERS })
  }

  handleScroll = (scrollOffset = 0) => {
    const tabs = get(this, 'tabsRef.current', {})

    const [
      scrollLeft,
      scrollWidth,
      clientWidth,
    ] = getMany(tabs, [
      'scrollLeft',
      'scrollWidth',
      'clientWidth',
    ])

    if (scrollLeft === null || scrollLeft === undefined) {
      return
    }

    if (scrollOffset !== 0) set(tabs, 'scrollLeft', scrollLeft + scrollOffset)

    const isScrollableLeft = scrollLeft + scrollOffset > 0
    const isScrollableRight = scrollLeft + scrollOffset < scrollWidth - clientWidth - 0.5

    this.setState({
      isScrollableLeft,
      isScrollableRight,
    })
  }

  render() {
    const {
      tabs,
      tabQuery,
      isSecondaryTab,
      secondaryTabQuery,
    } = this.props

    const {
      activeTab,
      isScrollableLeft,
      isScrollableRight,
    } = this.state

    const activeTabQuery = isSecondaryTab ? secondaryTabQuery : tabQuery
    const tabQueryIndex = findIndex(tabs, ({ id }) => id === activeTabQuery)
    const activeTabIndex = tabQueryIndex >= 0 ? tabQueryIndex : activeTab

    return (
      <Tabs
        {...this.props}
        activeTab={activeTabIndex}
        tabClickHandler={this.tabClickHandler}
        handleScroll={this.handleScroll}
        tabsRef={this.tabsRef}
        isScrollableLeft={isScrollableLeft}
        isScrollableRight={isScrollableRight}
      />
    )
  }
}

export default connect(mapStateToProps)(TabsC)
