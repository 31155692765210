import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import getMany from 'utilities/get/getMany'
import { USD } from 'constants/currencyConstants'

const frontendLevelThreeItemDataModel = ({ data: item, currency = USD }) => {
  const [
    costPerUnit,
    itemDescription,
    itemDiscountAmount,
    merchantProductCode,
    quantity,
    amountExcludingSalesTax,
    amountIncludingSalesTax,
    unitOfMeasure,
    commodityCode,
    salesTaxAmount,
    salesTaxRate,
  ] = getMany(item, [
    'cost_per_unit',
    'item_description',
    'item_discount_amount',
    'merchant_product_code',
    'quantity',
    'amount_excluding_sales_tax',
    'amount_including_sales_tax',
    'unit_of_measure',
    'commodity_code',
    'sales_tax_amount',
    'sales_tax_rate',
  ])

  return removeUndefined({
    currency,
    costPerUnit,
    displayCostPerUnit: formatMoney({ amount: costPerUnit, currency, showCurrencyCode: true }),
    itemDescription,
    itemDiscountAmount,
    displayItemDiscountAmount: formatMoney({ amount: itemDiscountAmount, currency, showCurrencyCode: true }),
    merchantProductCode,
    quantity,
    amountExcludingSalesTax,
    displayAmountExcludingSalesTax: formatMoney({ amount: amountExcludingSalesTax, currency, showCurrencyCode: true }),
    amountIncludingSalesTax,
    displayAmountIncludingSalesTax: formatMoney({ amount: amountIncludingSalesTax, currency, showCurrencyCode: true }),
    unitOfMeasure,
    commodityCode,
    salesTaxAmount,
    displaySalesTaxAmount: formatMoney({ amount: salesTaxAmount, currency, showCurrencyCode: true }),
    salesTaxRate,
  })
}

export default frontendLevelThreeItemDataModel
