import React from 'react'

const formatAmountHeaderTitle = ({
  icon,
  displayAmount = '',
  title = '',
}) => {
  return (
    <span>
      {icon && <i className={`icon ${icon}`} />}
      <span className='displayAmount'>{displayAmount}</span>
      {title}
    </span>
  )
}

export default formatAmountHeaderTitle
