import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import uuidv4 from 'uuid/v4'
import { countryNameByCountryCode } from 'constants/countryConstants'
import { APPROVED } from 'constants/underwritingConstants'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import set from 'lodash/set'
import keys from 'lodash/keys'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import find from 'lodash/find'

import {
  ADVERSE_MEDIA,
  AUTO_APPROVED,
  BUSINESS,
  ONBOARDING,
  PERSONAL,
  SANCTIONS_SCREENING,
  UNASSIGNED,
} from 'constants/language/languageConstants'

import {
  ADVERSE_MEDIA_REVIEW,
  ONBOARDING_REVIEW,
  SANCTIONS_REVIEW,
} from 'constants/reviewQueueConstants'

const accessFormTypeMap = {
  SELF_SERVICE: 'Self-Service',
  PROSPECT: 'Sales Prospect',
}

const frontendUnderwritingReviewModel = ({ data: underwritingReview }) => {
  const [
    id,
    reasons,
    state,
    subjectId,
    workflow,
    assignee,
    businessName,
    customerType,
    submittedBy,
    submittedOn,
    updatedOn,
    entityId,
    entityType,
    report,
    entity,
    run,
    accessFormType,
    customerOnboardingForm,
    createdOn,
    country,
    lastRun,
    nextRun,
    cadence,
  ] = getMany(underwritingReview, [
    'id',
    'reasons',
    'state',
    'subject_id',
    'workflow',
    'assignee',
    'business_name',
    'customer_model',
    'submitted_by',
    'submitted_at',
    'updated_at',
    'entity_id',
    'entity_type',
    'report',
    'entity',
    'run',
    'access_form_type',
    'customerOnboardingForm',
    'created_at',
    'country',
    'last_run',
    'next_run',
    'cadence',
  ])

  // remove old GIACT owners report if the new one exists
  if (find(get(report, 'owners'), { type: 'PERSON_IDENTITY_REPORT_V2' })) {
    set(report, 'owners', filter(get(report, 'owners'), { type: 'PERSON_IDENTITY_REPORT_V2' }))
  }

  // TODO: remove this hack once the API returns the entity_id for businesses
  const bankAccountReports = get(report, 'bank_accounts', [])
  forEach(bankAccountReports, (bankAccountReport, bankIndex) => {
    // this is a hack to get around the fact that the API doesn't return the entity_id for bank accounts
    // TODO: remove this hack once the API returns the entity_id for bank accounts
    const randomBankAccountEntityId = uuidv4()
    set(report, `bank_accounts[${bankIndex}].entity_id`, randomBankAccountEntityId)
    set(run, `subject.bank_accounts[${bankIndex}].id`, randomBankAccountEntityId)
  })

  // group reports by their entity_id
  const groupedReports = reduce(report, (result, reports, reportType) => {
    forEach(reports, (entityReport) => set(result, `${get(entityReport, 'entity_id')}.${reportType}`, entityReport))

    return result
  }, {})

  // this code is to map the reports to the subject object
  set(run, 'subject.business.reports', get(groupedReports, get(run, 'subject.business.id'), {}))
  const subjectBankAccounts = get(run, 'subject.bank_accounts', [])
  forEach(subjectBankAccounts, ({ id: bankAccountEntityId }, bankIndex) => set(run, `subject.bank_accounts[${bankIndex}].reports`, get(groupedReports, bankAccountEntityId, {})))
  const subjectOwners = get(run, 'subject.owners', [])
  forEach(subjectOwners, ({ id: ownerEntityId }, ownerIndex) => {
    set(run, `subject.owners[${ownerIndex}].reports`, get(groupedReports, ownerEntityId, {}))
    set(run, `subject.owners[${ownerIndex}].subjectIndex`, ownerIndex)
  })

  const recipientType = get(entity, 'businessName') ? BUSINESS : PERSONAL
  const recipientName = get(entity, 'businessName') || get(entity, 'fullName')

  let reviewType = ONBOARDING_REVIEW
  let displayReviewType = ONBOARDING
  const reportsReturned = keys(report)
  if (cadence && includes(reportsReturned, 'sanctions')) {
    reviewType = SANCTIONS_REVIEW
    displayReviewType = SANCTIONS_SCREENING
  }
  if (cadence && includes(reportsReturned, 'adverse_media')) {
    reviewType = ADVERSE_MEDIA_REVIEW
    displayReviewType = ADVERSE_MEDIA
  }

  return removeUndefined({
    id,
    reasons,
    state,
    subjectId,
    workflow,
    assignee: state === APPROVED && !assignee ? AUTO_APPROVED : assignee || UNASSIGNED,
    businessName,
    customerType,
    submittedBy,
    submittedOn,
    displaySubmittedOn: formatDate({ date: submittedOn }),
    updatedOn,
    displayUpdatedOn: formatDate({ date: updatedOn }),
    entityId,
    entityType,
    report,
    entity,
    run,
    accessFormType,
    displayAccessFormType: accessFormTypeMap[accessFormType],
    customerOnboardingForm,
    createdOn,
    displayCreatedOn: formatDate({ date: createdOn }),
    country,
    displayCountry: countryNameByCountryCode[country],
    recipientType,
    recipientName,
    lastRun,
    displayLastRun: formatDate({ date: lastRun }),
    nextRun,
    displayNextRun: formatDate({ date: nextRun }),
    cadence,
    reviewType,
    displayReviewType,
  })
}

export default frontendUnderwritingReviewModel
