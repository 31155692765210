export const WELCOME_EMAIL_PASSWORD = 'welcome-email-password'

// Dashboard Service API
export const DASHBOARD_SERVICE_APP_CONFIGURATION = 'v1/configuration'
export const DASHBOARD_SERVICE_CONFIGURATIONS = 'v1/configurations'
export const DASHBOARD_SERVICE_CONFIGURATION = (id) => `${DASHBOARD_SERVICE_CONFIGURATIONS}/${id}`
export const DASHBOARD_SERVICE_CREDENTIALS = 'v1/credentials'
export const DASHBOARD_SERVICE_CREDENTIAL = (id) => `${DASHBOARD_SERVICE_CREDENTIALS}/${id}`
export const DASHBOARD_SERVICE_CREDENTIALS_MEMBERSHIPS = (id) => `${DASHBOARD_SERVICE_CREDENTIAL(id)}/memberships`
export const DASHBOARD_SERVICE_FILE = 'v1/files'
export const DASHBOARD_SERVICE_USER = 'v1/user'
export const DASHBOARD_SERVICE_USERS = 'v1/users'
export const DASHBOARD_SERVICE_USERS_MEMBERSHIPS = ({ id }) => `v1/users/${id}/memberships`
export const DASHBOARD_SERVICE_USERS_EMAILS = (id) => `v1/users/${id}/emails`
export const DASHBOARD_SERVICE_EMAIL_TEMPLATES = `v1/email-templates`
export const DASHBOARD_SERVICE_WELCOME_EMAIL = `${DASHBOARD_SERVICE_EMAIL_TEMPLATES}/${WELCOME_EMAIL_PASSWORD}`

// Dashboard Service API - V2
export const DASHBOARD_SERVICE_APP_CONFIGURATION_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/dashboard_configuration`
export const DASHBOARD_SERVICE_CONFIGURATIONS_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/dashboard_configuration`
// export const DASHBOARD_SERVICE_CONFIGURATION = (id) => `${DASHBOARD_SERVICE_CONFIGURATIONS}/${id}`
export const DASHBOARD_SERVICE_CREDENTIALS_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/api_credential`
export const DASHBOARD_SERVICE_CREDENTIAL_V2 = ({ id, dashboardId }) => `dashboard/${dashboardId}/api_credential/${id}`
export const DASHBOARD_SERVICE_PATCH_CREDENTIAL = ({ id }) => `dashboard/api_credential/${id}`
// export const DASHBOARD_SERVICE_FILE = 'v1/files' <=== BE-5291
export const DASHBOARD_SERVICE_USER_V2 = ({ dashboardId, userId }) => `dashboard/${dashboardId}/dashboard_user/${userId}`
export const DASHBOARD_SERVICE_USERS_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/dashboard_user`
export const DASHBOARD_SERVICE_USERS_MEMBERSHIPS_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/membership`
export const DASHBOARD_SERVICE_USER_MEMBERSHIP_V2 = ({ id, dashboardId }) => `${DASHBOARD_SERVICE_USERS_MEMBERSHIPS_V2({ dashboardId})}/${id}`
export const DASHBOARD_SERVICE_USERS_CREDENTIALS_V2 = ({ dashboardId, userId }) => `${DASHBOARD_SERVICE_USER_V2({ dashboardId, userId })}/api_credential`
export const DASHBOARD_SERVICE_USERS_EMAILS_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/dashboard_email/send_email`
export const DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2 = ({ dashboardId }) => `dashboard/${dashboardId}/dashboard_email`
export const DASHBOARD_SERVICE_EMAIL_TEMPLATE_V2 = ({ id, dashboardId }) => `dashboard/${dashboardId}/dashboard_email/${id}`
export const OLD_DASHBOARD_SERVICE_CHARTS = 'dashboard/charts'
export const OLD_DASHBOARD_SERVICE_CHART = ({ id }) => `${OLD_DASHBOARD_SERVICE_CHARTS}/${id}`
export const DASHBOARD_SERVICE_CHARTS = 'dashboard/v1/charts'
export const DASHBOARD_SERVICE_CHART = ({ id }) => `${DASHBOARD_SERVICE_CHARTS}/${id}`
export const DASHBOARD_SERVICE_CHART_DELTA = ({ id }) => `${DASHBOARD_SERVICE_CHARTS}/${id}/delta`
export const DASHBOARD_SERVICE_EXPORTS = 'dashboard/exports'
export const DASHBOARD_SERVICE_EXPORTS_DOWNLOAD = ({ id }) => `${DASHBOARD_SERVICE_EXPORTS}/${id}/download`
export const DASHBOARD_SERVICE_NOTIFICATION_SETTINGS = 'dashboard/notifications/subscriptions'
export const DASHBOARD_SERVICE_NOTIFICATIONS = 'dashboard/notifications'
export const DASHBOARD_SERVICE_NOTIFICATION_ACKNOWLEDGMENT = 'dashboard/notifications/acknowledge'
export const API_LOGS = 'api_logs'
export const DASHBOARD_SERVICE_API_LOGS = `dashboard/${API_LOGS}`
export const DASHBOARD_SERVICE_API_LOG = ({id}) => `dashboard/${API_LOGS}/${id}`
export const DASHBOARD_UPLOAD_PRESIGNED_URL = 'dashboard/upload_customer_asset/presigned_url'
export const DASHBOARD_ACCESS_FORMS_PRESIGNED_URL = 'dashboard/access_forms/files'
export const DASHBOARD_SERVICE_V2_EMAILS = 'dashboard/v2/emails'
export const DASHBOARD_SERVICE_SELF_SERVICE_SIGNUP = 'dashboard/sandboxes'
export const DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA = 'dashboard/sandboxes/emails'
export const DASHBOARD_SERVICE_SELF_SERVICE_EMAIL_VERIFICATION = 'onboarding/internal/sandboxes'
export const DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL = 'onboarding/internal/sandboxes/emails'
export const DASHBOARD_SERVICE_PLATFORM_MEMBERS = ({ platformId }) => `dashboard/platform/${platformId}/dashboard_users`
export const DASHBOARD_SERVICE_APPLICATION_MEMBERS = ({ applicationId }) => `dashboard/application/${applicationId}/dashboard_users`
export const DASHBOARD_SERVICE_IDENTITY_MEMBERS = ({ identityId }) => `dashboard/identity/${identityId}/dashboard_users`
export const DASHBOARD_SERVICE_ROLE_MEMBERS = ({ roleId }) => `dashboard/dashboard_user_roles/${roleId}/dashboard_users`
export const DASHBOARD_SERVICE_ROLES = 'dashboard/dashboard_user_roles'
export const DASHBOARD_SERVICE_ROLE = ({ roleId }) => `${DASHBOARD_SERVICE_ROLES}/${roleId}`
export const DASHBOARD_SERVICE_MEMBER = ({ memberId }) => `dashboard/dashboard_user/${memberId}`
export const DASHBOARD_SERVICE_CREDENTIAL_V3 = 'dashboard/v2/api_credential'
export const DASHBOARD_CHANGE_PASSWORD = 'dashboard/dashboard_user/change_password'
export const DASHBOARD_CONTRACTS = 'dashboard/contracts'
export const DASHBOARD_SERVICE_APPLICATION_FEE_PROFILE = ({ applicationId }) => `dashboard/fee_profiles/${applicationId}/application`
export const DASHBOARD_SERVICE_MERCHANT_FEE_PROFILE = ({ merchantId }) => `dashboard/fee_profiles/${merchantId}/merchant`
export const DASHBOARD_CUSTOMER_ONBOARDING_FORMS = 'dashboard/customer_onboarding_forms'
export const DASHBOARD_CUSTOMER_ONBOARDING_FORM = ({ id }) => `${DASHBOARD_CUSTOMER_ONBOARDING_FORMS}/${id}`
export const DASHBOARD_CUSTOMER_ONBOARDING_FORM_LINKS = ({ id }) => `${DASHBOARD_CUSTOMER_ONBOARDING_FORMS}/${id}/links`
export const DASHBOARD_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM = ({ customerOnboardingFormId, accessFormId }) => `${DASHBOARD_CUSTOMER_ONBOARDING_FORMS}/${customerOnboardingFormId}/access_forms/${accessFormId}`
export const DASHBOARD_SERVICE_MFA_TOKEN = ({ userId }) => `dashboard/dashboard_user/${userId}/mfa/token`
export const DASHBOARD_SERVICE_MFA_ASSOCIATE = ({ userId }) => `dashboard/dashboard_user/${userId}/mfa/associate`
export const DASHBOARD_SERVICE_MFA_VERIFY = ({ userId }) => `dashboard/dashboard_user/${userId}/mfa/verify`
export const DASHBOARD_SERVICE_MFA_RESET = ({ userId }) =>  `dashboard/dashboard_user/${userId}/mfa/reset`
export const DASHBOARD_SERVICE_MFA_AUTHENTICATORS = ({ userId }) => `dashboard/dashboard_user/${userId}/mfa/authenticators`
export const DASHBOARD_SERVICE_ENTITY_CONFIGURATIONS = 'dashboard/entity_configurations'
export const DASHBOARD_SERVICE_ENTITY_CONFIGURATION = ({ id }) => `${DASHBOARD_SERVICE_ENTITY_CONFIGURATIONS}/${id}`
export const DASHBOARD_SERVICE_ONBOARDING_FORM_REQUEST_CHANGES = ({ id }) => `${DASHBOARD_CUSTOMER_ONBOARDING_FORMS}/${id}/request_changes`
export const DASHBOARD_SERVICE_ONBOARDING_FORM_SUBMIT_TO_UNDERWRITING = ({ id }) => `${DASHBOARD_CUSTOMER_ONBOARDING_FORMS}/${id}/reviews`

// Dashboard Service (V2) - New Endpoints
export const DASHBOARD_SERVICE_CUSTOMIZATIONS = 'dashboard/dashboard_customization'
export const DASHBOARD_SERVICE_CUSTOMIZATION = ({ id }) => `dashboard/dashboard_customization/${id}`
export const DASHBOARD_SERVICE_GUEST_CUSTOMIZATIONS = 'dashboard_customization'
export const DASHBOARD_SERVICE_ONBOARDING_FORM = ({ id }) => `onboarding/internal/onboarding_forms/${id}`
export const ONBOARDING_FORMS_FILES = ({ onboardingFormId }) => `${DASHBOARD_SERVICE_ONBOARDING_FORM({ id: onboardingFormId })}/files`
export const ONBOARDING_FORMS_FILE = ({ id: fileId, onboardingFormId }) => `${DASHBOARD_SERVICE_ONBOARDING_FORM({ id: onboardingFormId })}/files/${fileId}`
export const DASHBOARD_SERVICE_ONBOARDING_FORM_UNDERWRITING_PROFILES = ({ id }) => `onboarding/internal/onboarding_forms/${id}/underwriting_profiles`
export const DASHBOARD_SERVICE_POST_ONBOARDING_FORM_DATA = 'onboarding/internal/onboard_entities'
export const DASHBOARD_SERVICE_ONBOARDING_FORM_TOKEN_VERIFICATION = 'onboarding/internal/verify_token'
export const DASHBOARD_SERVICE_TRANSFERS = 'dashboard/transfers'
export const DASHBOARD_SERVICE_TRANSFER = ({ id }) => `${DASHBOARD_SERVICE_TRANSFERS}/${id}`
export const DASHBOARD_ACCESS_FORMS = 'dashboard/access_forms'
export const DASHBOARD_ACCESS_FORM = ({ id }) => `${DASHBOARD_ACCESS_FORMS}/${id}`
export const DASHBOARD_GUEST_ACCESS_FORMS = 'onboarding/internal/access_forms'
export const DASHBOARD_GUEST_ACCESS_FORM = ({ id }) => `${DASHBOARD_GUEST_ACCESS_FORMS}/${id}`
export const DASHBOARD_GUEST_ACCESS_FORMS_PRESIGNED_URL = ({ id }) => `${DASHBOARD_GUEST_ACCESS_FORMS}/${id}/files`
export const DASHBOARD_GUEST_ACCESS_FORM_FILE = ({ accessFormId, fileId }) => `${DASHBOARD_GUEST_ACCESS_FORM({ id: accessFormId })}/files/${fileId}`
export const DASHBOARD_GUEST_ACCESS_FORM_FILE_LINKS = ({ accessFormId, fileId }) => `${DASHBOARD_GUEST_ACCESS_FORM_FILE({ accessFormId, fileId })}/external_links`
export const SUBMIT_GUEST_DASHBOARD_ACCESS_FORM = ({ id }) => `${DASHBOARD_GUEST_ACCESS_FORMS}/${id}/submit`
export const DASHBOARD_UNDERWRITING = 'dashboard/underwriting'
export const DASHBOARD_UNDERWRITING_REVIEWS = `${DASHBOARD_UNDERWRITING}/reviews`
export const DASHBOARD_ACCESS_FORM_CONTRACT = ({ id }) => `${DASHBOARD_ACCESS_FORM({ id })}/contracts`
export const SUBMIT_DASHBOARD_ACCESS_FORM = ({ id }) => `${DASHBOARD_ACCESS_FORMS}/${id}/submit`
export const SUBMIT_SALES_REVIEW_ACCESS_FORM = 'onboarding/internal/sales_review'


// compliance
export const COMPLIANCE_FORMS = 'dashboard/compliance_forms'
export const COMPLIANCE_FORM = ({ complianceFormId }) => `${COMPLIANCE_FORMS}/${complianceFormId}`
export const COMPLIANCE_FORM_TEMPLATES = 'dashboard/compliance_form_templates'
export const COMPLIANCE_FORM_TEMPLATE = ({ complianceFormTemplateId }) => `${COMPLIANCE_FORM_TEMPLATES}/${complianceFormTemplateId}`
export const COMPLIANCE_FORM_TEMPLATE_FORMS = ({ complianceFormTemplateId }) => `${COMPLIANCE_FORM_TEMPLATE({ complianceFormTemplateId })}/compliance_forms`
export const COMPLIANCE_SETTINGS = 'dashboard/compliance_settings'
export const ACTIVE_COMPLIANCE_FORM_TEMPLATE = `${COMPLIANCE_FORM_TEMPLATES}/active`
export const FINIX_COMPLIANCE_FORMS = 'compliance_forms'

// V2 API
export const V2_AUTHENTICATION = `v2/authentication`
export const USER_PERMISSIONS = ({ id }) => `${V2_AUTHENTICATION}/users/${id}/permissions`

// Finix API
export const ADMIN = 'support'

export const APPLICATIONS = 'applications'
export const APPLICATION = ({ applicationId }) => `${APPLICATIONS}/${applicationId}`
export const APPLICATION_DISPUTES = ({ applicationId }) => `${APPLICATION({ applicationId })}/disputes`
export const APPLICATION_IDENTITIES = ({ applicationId }) => `${APPLICATION({ applicationId })}/identities`
export const APPLICATION_MERCHANTS = ({ applicationId }) => `${APPLICATION({ applicationId })}/merchants`
export const APPLICATION_PROCESSORS = ({ applicationId }) => `${APPLICATION({ applicationId })}/processors`
export const APPLICATION_PROCESSOR = ({ applicationId, processor }) => `${APPLICATION_PROCESSORS({ applicationId })}/${processor}`
export const APPLICATION_PROCESSOR_CONFIGS = ({ applicationId, processor }) => `${APPLICATION({ applicationId })}/processors/${processor}`
export const APPLICATION_SETTLEMENTS = ({ applicationId }) => `${SETTLEMENT_ENGINE}/${APPLICATION({ applicationId })}/settlements`
export const APPLICATION_TRANSFERS = ({ applicationId }) => `${APPLICATION({ applicationId })}/transfers`
export const APPLICATION_USERS = ({ applicationId }) => `${APPLICATION({ applicationId })}/users`
export const APPLICATION_UNDERWRITING_SETTINGS = ({ applicationId }) => `merchantunderwriting/underwriting/decision_settings/applications/${applicationId}`
export const APPLICATION_FEE_PROFILE_RULES_V2 = ({ applicationId }) => `internal/v2/${APPLICATION({ applicationId })}/fee_profile_rules`
export const APPLICATION_FEE_PROFILE_RULES = ({ applicationId }) => `internal/${APPLICATION({ applicationId })}/fee_profile_rules`
export const APPLICATION_FEE_PROFILE_SETTINGS_V2 = ({ applicationId }) => `internal/v2/${APPLICATION({ applicationId })}/fee_profile_settings`
export const APPLICATION_FEE_PROFILE_SETTINGS = ({ applicationId }) => `internal/${APPLICATION({ applicationId })}/fee_profile_settings`

export const APPLICATION_PROFILES = 'application_profiles'
export const APPLICATION_PROFILE = ({ id }) => `${APPLICATION_PROFILES}/${id}`

export const AUTHORIZATIONS = 'authorizations'
export const AUTHORIZATION = ({ authorizationId }) => `${AUTHORIZATIONS}/${authorizationId}`

export const DISPUTES = 'disputes'
export const DISPUTE = ({ disputeId }) => `${DISPUTES}/${disputeId}`
export const DISPUTE_CREATE = `${DISPUTES}/_create` // internal only endpoint for creating disputes
export const DISPUTE_UPDATE = ({ disputeId }) => `${DISPUTE({ disputeId })}/_update` // internal only endpoint for updating disputes
export const DISPUTE_EVIDENCES = ({ disputeId }) => `${DISPUTE({ disputeId })}/evidence`
export const DISPUTE_ADJUSTMENT_TRANSFERS = ({ disputeId }) => `${DISPUTE({ disputeId })}/adjustment_transfers`
export const DISPUTE_ACCEPT = ({ disputeId }) => `${DISPUTE({ disputeId })}/accept`
export const DISPUTE_EVIDENCE_SUBMIT = ({ disputeId }) => `${DISPUTE({ disputeId })}/submit`
export const DISPUTE_EVIDENCE_DOWNLOAD = ({ disputeId, evidenceId }) => `${DISPUTE_EVIDENCES({ disputeId })}/${evidenceId}/download`

export const FEE_PROFILES = 'v2/fee_profiles'
export const FEE_PROFILE = ({ id }) => `${FEE_PROFILES}/${id}`

export const RISK_PROFILES = 'risk_profiles'
export const RISK_PROFILE = ({ id }) => `${RISK_PROFILES}/${id}`

export const IDENTITIES = 'identities'
export const IDENTITY = ({ identityId }) => `${IDENTITIES}/${identityId}`
export const IDENTITY_UNMASK = ({ identityId }) => `dashboard/${IDENTITIES}/${identityId}/unmask`
export const IDENTITY_AUTHORIZATIONS = ({ identityId }) => `${IDENTITY({ identityId })}/authorizations`
export const IDENTITY_TRANSFERS = ({ identityId }) => `${IDENTITY({ identityId })}/transfers`
export const IDENTITY_DISPUTES = ({ identityId }) => `${IDENTITY({ identityId })}/disputes`
export const V1_IDENTITY_SETTLEMENTS = ({ identityId }) => `${IDENTITY({ identityId })}/settlements`
export const IDENTITY_SETTLEMENTS = ({ identityId }) => `${SETTLEMENT_ENGINE}/${IDENTITY({ identityId })}/settlements`
export const IDENTITY_PAYMENT_INSTRUMENTS = ({ identityId }) => `${IDENTITY({ identityId })}/payment_instruments`
export const IDENTITY_MERCHANTS = ({ identityId }) => `${IDENTITY({ identityId })}/merchants`
export const IDENTITY_VERIFICATIONS = ({ identityId }) => `${IDENTITY({ identityId })}/verifications`
export const IDENTITY_VERIFICATION = ({identityId, verificationId}) => `${IDENTITY({ identityId })}/verifications/${verificationId}`
export const IDENTITY_USERS = ({ identityId }) => `${IDENTITY({ identityId })}/users`
export const IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING = ({ identityId }) => `${SETTLEMENT_ENGINE}/${IDENTITY({ identityId })}/payout_plan_instrument_mapping`

export const PAYOUT_PROFILES = 'payout_profiles'
export const PAYOUT_PROFILE_STRING = 'payout_profile'
export const PAYOUT_PROFILE = ({ payoutProfileId }) => `${PAYOUT_PROFILES}/${payoutProfileId}`

export const MERCHANTS = 'merchants'
export const MERCHANT = ({ merchantId }) => `${MERCHANTS}/${merchantId}`
export const MERCHANT_VERIFICATIONS = ({ merchantId }) => `${MERCHANT({ merchantId })}/verifications`
export const MERCHANT_SETTLEMENTS = ({ merchantId }) => `${SETTLEMENT_ENGINE}/${MERCHANT({ merchantId })}/settlements`
export const MERCHANT_PAYOUT_PROFILE = ({ merchantId }) => `${MERCHANT({ merchantId })}/${PAYOUT_PROFILE_STRING}`
export const MERCHANT_DEVICES = ({ merchantId }) => `${MERCHANT({ merchantId })}/${DEVICES}`

export const MERCHANT_PROFILES = 'merchant_profiles'
export const MERCHANT_PROFILE = ({ id }) => `${MERCHANT_PROFILES}/${id}`
// TODO: refactor all API constants to just accept "id", this will simplify a lot of logic and remove some unnecessary code
export const PAYMENT_INSTRUMENTS = 'payment_instruments'
export const PAYMENT_INSTRUMENT = ({ id }) => `${PAYMENT_INSTRUMENTS}/${id}`
export const PAYMENT_INSTRUMENT_UNMASK = ({ paymentInstrumentId }) => `dashboard/${PAYMENT_INSTRUMENTS}/${paymentInstrumentId}/unmask`
export const PAYMENT_INSTRUMENT_TRANSFERS = ({ paymentInstrumentId }) => `${PAYMENT_INSTRUMENT({ id: paymentInstrumentId })}/transfers`
export const PAYMENT_INSTRUMENT_AUTHORIZATIONS = ({ paymentInstrumentId }) => `${PAYMENT_INSTRUMENT({ id: paymentInstrumentId })}/authorizations`
export const PAYMENT_INSTRUMENT_VERIFICATIONS = ({ paymentInstrumentId }) => `${PAYMENT_INSTRUMENT({id: paymentInstrumentId})}/verifications`

export const REPORTS = 'reporting/reports/list'
export const REPORT_DOWNLOAD = ({ id }) => `reporting/reports/${id}/download`
export const LATEST_REPORTS = 'reporting/reports/latest'

export const REVIEW_QUEUE = 'review_queue'
export const REVIEW_QUEUE_ITEM = ({ id }) => `${REVIEW_QUEUE}/${id}`

export const STATEMENTS = 'statements'

export const SETTLEMENTS_BASE = 'settlements'
export const SETTLEMENT_ENGINE = 'settlement_engine'
export const SETTLEMENTS = `${SETTLEMENT_ENGINE}/${SETTLEMENTS_BASE}`
export const V1_SETTLEMENT = ({ settlementId }) => `${SETTLEMENTS_BASE}/${settlementId}`
export const SETTLEMENT = ({ settlementId }) => `${SETTLEMENTS}/${settlementId}`
export const SETTLEMENT_CALCULATED_FUNDING_DETAILS = ({ settlementId }) => `${SETTLEMENT({ settlementId })}/calculated_funding_details`
export const SETTLEMENT_GROUP = ({ settlementGroupId }) => `${SETTLEMENT_ENGINE}/settlement_group/${settlementGroupId}`

export const SETTLEMENT_TRANSFERS = ({ settlementId }) => `${SETTLEMENT({ settlementId })}/transfers`
export const SETTLEMENT_FUNDING_TRANSFERS = ({ settlementId }) => `${V1_SETTLEMENT({ settlementId })}/funding_transfers`
export const SETTLEMENT_FUNDING_TRANSFER_ATTEMPTS = ({ settlementId }) => `${V1_SETTLEMENT({ settlementId })}/funding_transfer_attempts`
export const SETTLEMENT_FUNDING_TRANSFER = ({ settlementId, fundingTransferAttemptId }) => `${V1_SETTLEMENT({ settlementId })}/funding_transfer_attempts/${fundingTransferAttemptId}`

export const SUBSCRIPTION = 'subscription'
export const SUBSCRIPTION_SCHEDULES = `${SUBSCRIPTION}/subscription_schedules`
export const SUBSCRIPTION_SCHEDULE = ({ subscriptionScheduleId }) => `${SUBSCRIPTION_SCHEDULES}/${subscriptionScheduleId}`
export const SUBSCRIPTION_SCHEDULE_AMOUNTS = ({ subscriptionScheduleId }) => `${SUBSCRIPTION_SCHEDULE({ subscriptionScheduleId })}/subscription_amounts`
export const SUBSCRIPTION_SCHEDULE_AMOUNT = ({ subscriptionScheduleId, subscriptionAmountId }) => `${SUBSCRIPTION_SCHEDULE_AMOUNTS({ subscriptionScheduleId })}/${subscriptionAmountId}`
export const SUBSCRIPTION_ENROLLMENTS = `${SUBSCRIPTION}/subscription_enrollments`
export const SUBSCRIPTION_ENROLLMENT = ({ subscriptionEnrollmentId }) => `${SUBSCRIPTION_ENROLLMENTS}/${subscriptionEnrollmentId}`
export const SUBSCRIPTION_SCHEDULE_ENROLLMENTS = ({ subscriptionScheduleId }) => `${SUBSCRIPTION_SCHEDULE({ subscriptionScheduleId })}/subscription_enrollments`
export const SUBSCRIPTION_SCHEDULE_ENROLLMENT = ({ subscriptionScheduleId, subscriptionScheduleEnrollmentId }) => `${SUBSCRIPTION_SCHEDULE_ENROLLMENTS({ subscriptionScheduleId })}/${subscriptionScheduleEnrollmentId}`

export const FEES = 'fees'
export const SETTLEMENT_ENTRIES = ({ settlementId }) => `${SETTLEMENT({ settlementId })}/entries`
export const FEE = ({ feeId }) => `${FEES}/${feeId}`

export const PAYOUT_PLANS = `${SETTLEMENT_ENGINE}/payout_plans`
export const PAYOUT_PLAN = ({ payoutPlanId }) => `${SETTLEMENT_ENGINE}/payout_plans/${payoutPlanId}`
export const PAYOUT_PLAN_TEMPLATES = ({ payoutPlanId }) => `${SETTLEMENT_ENGINE}/payout_plans/${payoutPlanId}/templates`
export const PAYOUT_PLAN_COMBINATION_PLANS = ({ payoutPlanId }) => `${SETTLEMENT_ENGINE}/payout_plans/${payoutPlanId}/combination_plans`

export const TRANSFERS = 'transfers'
export const TRANSFER = ({ transferId }) => `${TRANSFERS}/${transferId}`
export const TRANSFER_REVERSALS = ({ transferId }) => `${TRANSFER({ transferId })}/reversals`
export const TRANSFER_FEES = ({ transferId }) => `${TRANSFER({ transferId })}/fees`
export const TRANSFER_DISPUTES = ({ transferId }) => `${TRANSFER({ transferId })}/disputes`

export const BALANCE_TRANSFERS = 'balance_transfers'
export const BALANCE_TRANSFER = ({ balanceTransferId }) => `${BALANCE_TRANSFERS}/${balanceTransferId}`

export const USERS = 'users'
export const USER = ({ id }) => `${USERS}/${id}`
export const USER_FWS = ({ id }) => `dashboard/${USERS}/${id}`

export const ADMIN_USER =  ({ userId }) => `${DASHBOARD_SERVICE_USERS}/${userId}`

export const ASSOCIATED_IDENTITIES = ({ identityId }) => IDENTITY({ identityId }) + '/associated_identities'
export const APPLICATION_ASSOCIATED_IDENTITIES = ({ identityId }) => IDENTITY({ identityId }) + '/associated_identities'

export const PLATFORMS = 'platforms'
export const PLATFORM = ({ platformId }) => `${PLATFORMS}/${platformId}`

export const PROCESSOR_CONFIGS = 'processor_config'

export const PLATFORM_PROCESSOR_CONFIG = ({ platformId, processorConfigId }) => `${PLATFORM_PROCESSOR_CONFIGS({platformId})}/${processorConfigId}`
export const PLATFORM_PROCESSOR_CONFIGS = ({ platformId }) => `${PLATFORM({platformId})}/${PROCESSOR_CONFIGS}`

export const FILES = 'dashboard/files'
export const FILE = ({ fileId }) => `${FILES}/${fileId}`
export const FILE_EXTERNAL_LINKS = ({ fileId }) => `${FILE({ fileId })}/external_links`
export const ACCESS_FORM_FILE = ({ accessFormId, fileId }) => `${DASHBOARD_ACCESS_FORM({ id: accessFormId })}/files/${fileId}`
export const ACCESS_FORM_FILE_EXTERNAL_LINKS = ({ accessFormId, fileId }) => `${ACCESS_FORM_FILE({ accessFormId, fileId })}/external_links`
export const ACCESS_FORM_PRINCIPAL_TAX_ID_UNMASK = ({ accessFormId }) => `dashboard/internal/access_forms/${accessFormId}/principal_tax_id/unmask`
export const ACCESS_FORM_BUSINESS_TAX_ID_UNMASK = ({ accessFormId }) => `dashboard/internal/access_forms/${accessFormId}/business_tax_id/unmask`
export const ACCESS_FORM_BANK_ACCOUNT_NUMBER_UNMASK = ({ accessFormId }) => `dashboard/internal/access_forms/${accessFormId}/bank_account_number/unmask`
export const ACCESS_FORM_BENEFICIAL_OWNER_TAX_ID_UNMASK = ({ accessFormId, index }) => `dashboard/internal/access_forms/${accessFormId}/beneficial_owner/${index}/unmask`

export const WEBHOOKS = 'webhooks'
export const WEBHOOK = ({ webhookId }) => `${WEBHOOKS}/${webhookId}`

export const WEBHOOK_LOGS = 'webhook_logs'
export const WEBHOOK_LOG = ({ webhookLogId }) => `${WEBHOOK_LOGS}/${webhookLogId}`

export const PAYMENT_METHOD_CONFIGURATIONS = 'payment_method_configurations'
export const PAYMENT_METHOD_CONFIGURATION = ({ id }) => `${PAYMENT_METHOD_CONFIGURATIONS}/${id}`

export const CERTIFICATE_SIGNING_REQUESTS = 'certificate_signing_requests'
export const CERTIFICATE_SIGNING_REQUEST =  ({ id } ) => `${CERTIFICATE_SIGNING_REQUESTS}/${id}/signed_certificate`

export const DECISIONS = 'merchantunderwriting/underwriting/decisions'

export const DEVICES = 'devices'
export const DEVICE = ({ deviceId }) => `${DEVICES}/${deviceId}`

export const VERIFICATIONS = 'verifications'
export const VERIFICATION = ({ verificationId }) => `${VERIFICATIONS}/${verificationId}`

export const ONBOARDING_FORMS = 'onboarding_forms'
export const ONBOARDING_FORM = ({ onboardingFormId }) => `${ONBOARDING_FORMS}/${onboardingFormId}`
export const ONBOARDING_FORM_LINK = ({ onboardingFormId }) => `${ONBOARDING_FORMS}/${onboardingFormId}/links`

export const SPLIT_TRANSFERS = 'split_transfers'
export const SPLIT_TRANSFER = ({ splitTransferId }) => `${SPLIT_TRANSFERS}/${splitTransferId}`
export const SPLIT_TRANSFER_FEES = ({ splitTransferId }) => `${SPLIT_TRANSFER({ splitTransferId })}/fees`

export const PAYMENT_LINKS = 'payment_links'
export const PAYMENT_LINK = ({ paymentLinkId }) => `${PAYMENT_LINKS}/${paymentLinkId}`

export const PAYOUT_LINKS = 'payout_links'
export const PAYOUT_LINK = ({ payoutLinkId }) => `${PAYOUT_LINKS}/${payoutLinkId}`

export const TRANSFER_ATTEMPTS = 'transfer_attempts'
export const TRANSFER_ATTEMPT = ({ transferAttemptId }) => `${TRANSFER_ATTEMPTS}/${transferAttemptId}`

export const HOSTED_PAYMENTS_TOKEN_VERIFICATION = 'hosted_payments/internal/verify_token'
export const HOSTED_PAYMENTS_PAYMENT_LINKS = 'hosted_payments/payment_links'
export const HOSTED_PAYMENTS = 'hosted_payments'
export const HOSTED_PAYMENTS_INTERNAL = `${HOSTED_PAYMENTS}/internal`
export const HOSTED_PAYMENTS_PAYMENT_LINK = ({ paymentLinkId }) => `${HOSTED_PAYMENTS_INTERNAL}/payment_links/${paymentLinkId}`
export const HOSTED_PAYMENTS_CHECKOUT_FORM = ({ checkoutFormId }) => `${HOSTED_PAYMENTS_INTERNAL}/checkout_forms/${checkoutFormId}`
export const HOSTED_PAYMENTS_MERCHANT_PAYMENT_INSTRUMENTS = ({ merchantId }) => `${HOSTED_PAYMENTS_INTERNAL}/merchant/${merchantId}/payment_instruments`
export const HOSTED_PAYMENTS_PAYOUT_LINKS = `${HOSTED_PAYMENTS}/payout_links`
export const HOSTED_PAYMENTS_PAYOUT_LINK = ({ payoutLinkId }) => `${HOSTED_PAYMENTS_INTERNAL}/payout_links/${payoutLinkId}`
export const HOSTED_PAYMENT_TRANSFER_ATTEMPTS = `${HOSTED_PAYMENTS_INTERNAL}/transfer_attempts`

export const PURCHASES = 'purchases'
export const PURCHASE = ({ purchaseId }) => `${PURCHASES}/${purchaseId}`

export const RECEIPTS = 'receipts'
export const RECEIPT = ({ receiptId }) => `${RECEIPTS}/${receiptId}`
export const RECEIPT_DELIVERY_ATTEMPTS = ({ receiptId }) => `${RECEIPTS}/${receiptId}/delivery_attempts`

export const MONITORING_ALERTS = 'merchantunderwriting/alerts'
export const MONITORING_ALERT = ({ monitoringAlertId }) => `${MONITORING_ALERTS}/${monitoringAlertId}`

// chart APIs
export const HOME_CHARTS = 'dashboard/v1/home/charts'
export const TRANSACTION_CHARTS = 'dashboard/v1/transaction-insights/charts'
export const EXCEPTION_CHARTS = 'dashboard/v1/exception-insights/charts'
export const MERCHANT_CHARTS = 'dashboard/v1/merchant-insights/charts'
export const UNDERWRITING_CHARTS = 'dashboard/v1/underwriting-insights/charts'

// Underwriting V2 APIs
export const MERCHANT_UNDERWRITING = 'merchantunderwriting'
export const SUBJECTS = 'subjects'
export const EVENTS = 'events'
export const UNDERWRITING_SUBJECTS = `${MERCHANT_UNDERWRITING}/${SUBJECTS}`
export const UNDERWRITING_SUBJECT = ({ subjectId }) => `${MERCHANT_UNDERWRITING}/${SUBJECTS}/${subjectId}`
export const UNDERWRITING_SUBJECT_UNMASK = ({ subjectId }) => `${MERCHANT_UNDERWRITING}/${SUBJECTS}/${subjectId}/unmask`
export const UNDERWRITING_SUBJECT_REVIEWS = ({ subjectId }) => `${MERCHANT_UNDERWRITING}/${SUBJECTS}/${subjectId}/reviews`
export const UNDERWRITING_SUBJECT_REVIEW = ({ subjectId, reviewId }) => `${MERCHANT_UNDERWRITING}/${SUBJECTS}/${subjectId}/reviews/${reviewId}`
export const UNDERWRITING_REVIEW_RUNS = ({ reviewId }) => `${MERCHANT_UNDERWRITING}/reviews/${reviewId}/runs`
export const UNDERWRITING_REVIEW_RUN = ({ reviewId, runId }) => `${UNDERWRITING_REVIEW_RUNS({ reviewId })}/${runId}`
export const UNDERWRITING_RUN_REPORTS = ({ runId }) => `${MERCHANT_UNDERWRITING}/runs/${runId}/reports`
export const UNDERWRITING_RUN_SILENCE = ({ runId }) => `${MERCHANT_UNDERWRITING}/runs/${runId}/silence`
export const MERCHANT_UNDERWRITING_SUBJECTS = `${MERCHANT_UNDERWRITING}/${SUBJECTS}`
export const MERCHANT_UNDERWRITING_SUBJECT_EVENTS = ({ subjectId }) => `${MERCHANT_UNDERWRITING_SUBJECTS}/${subjectId}/${EVENTS}`
export const MERCHANT_UNDERWRITING_SUBJECT_EVENT = ({ subjectId, eventId }) => `${MERCHANT_UNDERWRITING_SUBJECT_EVENTS({ subjectId })}/${eventId}`

// new endpoint for merchant underwriting events
export const AUDIT_LOG_EVENTS = `${MERCHANT_UNDERWRITING}/${EVENTS}`
export const AUDIT_LOG_EVENT = ({ eventId }) => `${AUDIT_LOG_EVENTS}/${eventId}`

// Underwriting profile APIs
export const UNDERWRITING_PROFILES = 'underwriting_profiles'
export const UNDERWRITING_PROFILE = ({ underwritingProfileId }) => `${UNDERWRITING_PROFILES}/${underwritingProfileId}`
export const UNDERWRITING_REPORT = ({ reportId }) => `${MERCHANT_UNDERWRITING}/reports/${reportId}`

// Automated Underwriting Workflows APIs
export const WORKFLOWS = 'workflows'

export const UNDERWRITING_WORKFLOWS = `${MERCHANT_UNDERWRITING}/${WORKFLOWS}`
export const UNDERWRITING_WORKFLOW = ({ workflowId }) => `${UNDERWRITING_WORKFLOWS}/${workflowId}`

// Disbursements Dashboard APIs
export const BALANCES = 'balances'
export const BALANCE_ENTRIES_API = 'balance_entries'
export const DISBURSEMENT_RULES = 'disbursement_rules'
export const DISBURSEMENT_RULE = ({ ruleId }) => `${DISBURSEMENT_RULES}/${ruleId}`
export const CURRENT_USAGES = `${DISBURSEMENT_RULES}/current_usages`
export const DISBURSEMENTS = 'disbursements'
export const ENTRIES = 'balance_entries'
export const BALANCE_ADJUSTMENTS = 'balance_adjustments'

export const BALANCE_ENTRIES = ({ balanceId }) => `${BALANCES}/${balanceId}/${ENTRIES}`
export const BALANCE_ENTRY = ({ balanceEntryId }) => `${BALANCE_ENTRIES_API}/${balanceEntryId}`

export const BALANCE_ADJUSTMENT = ({ balanceAdjustmentId }) => `${BALANCE_ADJUSTMENTS}/${balanceAdjustmentId}`

export const NOTES = 'dashboard/notes'
export const NOTE = ({ noteId }) => `${NOTES}/${noteId}`
export const DRAFT_NOTES = 'dashboard/draft_notes'

// API headers constants
export const FINIX_VERSION = 'Finix-Version'
export const FINIX_VERSION_2022_02_01 = '2022-02-01'

// internal plaid access token API for onboarding forms
export const THIRD_PARTY_TOKENS = 'third_party_tokens'
export const ONBOARDING_FORM_PLAID_TOKEN = ({ id }) => `${DASHBOARD_SERVICE_ONBOARDING_FORM({ id })}/${THIRD_PARTY_TOKENS}`
export const ACCESS_FORM_PLAID_TOKEN  = ({ id }) => `${DASHBOARD_GUEST_ACCESS_FORM({ id })}/${THIRD_PARTY_TOKENS}`

// fraud monitoring constant
export const FRAUD_SCORES = 'fraud_scores'

// recurring billing constants
export const SUBSCRIPTIONS = 'subscriptions'
export const SUBSCRIPTION_BILLING = ({ subscriptionId }) => `${SUBSCRIPTIONS}/${subscriptionId}`
export const SUBSCRIPTION_PLANS = 'subscription_plans'
export const SUBSCRIPTION_PLAN = ({ subscriptionPlanId }) => `${SUBSCRIPTION_PLANS}/${subscriptionPlanId}`
