import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import GenericForm from 'components/Customer/Forms/GenericForm/GenericForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import validateBankAccountForm from 'utilities/validate/validateBankAccountForm'
import { accountTypeOptions } from 'constants/bankConstants'
import { BANK_ACCOUNT_FORM } from 'constants/formConstants'

const sections = [
  {
    title: 'Bank Information',
    inputs: [
      {
        props: {
          name: 'name',
          label: 'Name On Account',
          placeholder: 'Joe Banker',
          component: InputField,
        },
      },
      {
        props: {
          name: 'bankCode',
          label: 'Routing Number',
          placeholder: '123456789',
          component: InputField,
        },
      },
      {
        props: {
          name: 'accountNumber',
          label: 'Account Number',
          placeholder: '123456789',
          component: InputField,
        },
      },
      {
        props: {
          name: 'accountType',
          label: 'Account Type',
          component: SelectField,
          options: accountTypeOptions,
        },
      },
    ],
  },
]

const BankAccountForm = ({
  submitting = false,
  submitLabel = 'Create',
  handleSubmit = () => {},
  leftButton = false,
  leftButtonLabel = 'Back',
  leftButtonAction = () => {},
}) => {
  return (
    <GenericForm
      handleSubmit={handleSubmit}
      sections={sections}
      submitLabel={submitLabel}
      submitting={submitting}
      leftButton={leftButton}
      leftButtonLabel={leftButtonLabel}
      leftButtonAction={leftButtonAction}
      submitVariant='legacy-black'
    />
  )
}

BankAccountForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
}

export default reduxForm({
  form: BANK_ACCOUNT_FORM,
  validate: validateBankAccountForm,
})(BankAccountForm)
