import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT_FUNDING_TRANSFERS,
  SETTLEMENTS_BASE,
} from 'constants/apiConstants'

const getSettlementFundingTransfersAPI = ({ queries, values, credentials, meta }) => {
  // TODO: look into whether we should always just call these id
  const { settlementId } = values

  return paymentsAPI.get({
    meta,
    path: SETTLEMENT_FUNDING_TRANSFERS({ settlementId }),
    credentials,
    queries,
    service: SETTLEMENTS_BASE,
  })
}

export default getSettlementFundingTransfersAPI
