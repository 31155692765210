import './GenericModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'
import classnames from 'classnames'
import { t } from 'constants/language/languageConstants'

const GenericModal = (props) => {
  const {
    title,
    subTitle,
    className,
    Component,
    steps = [],
    multiSteps = false,
    children,
    style = {},
  } = props

  const classNames = classnames({
    GenericModal: true,
    [className]: !!className,
  })

  return (
    <div className={classNames} style={style}>
      <div className='modal-header'>
        { title && <h4>{t(title)}</h4> }
        { subTitle && <div className='sub-title p-2 secondary'>{t(subTitle)}</div> }
      </div>

      {!multiSteps && !!Component && <Component {...props} />}

      {multiSteps && (
        <FlowsC modalPlacement steps={steps} layout='vertical' />
      )}

      {children}
    </div>
  )
}

GenericModal.propTypes = {
  props: PropTypes.object,
  className: PropTypes.string,
  Component: PropTypes.func,
  steps: PropTypes.array,
  multiSteps: PropTypes.bool,
  title: PropTypes.string,
}

export default GenericModal
