import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlatformProcessorConfigs from './PlatformProcessorConfigs'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { PLATFORM_PROCESSOR_CONFIGS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `processorConfigsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const platformId = get(props, 'platformId', get(props, 'params.platformId'))
  const platformProcessorConfigsPath = PLATFORM_PROCESSOR_CONFIGS_PATH({ credentialId, platformId })

  const flowValues = {
    platformId,
  }

  return {
    initialQueries,
    isFetching,
    platformProcessorConfigsPath,
    flowValues,
  }
}

class PlatformProcessorConfigsC extends Component {
  render() {
    return (
      <PlatformProcessorConfigs {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PlatformProcessorConfigsC)
