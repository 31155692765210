import paymentsAPI from 'utilities/api/paymentsAPI'
import { ONBOARDING_FORMS } from 'constants/apiConstants'

const postOnboardingFormAPI = ({ values, meta, credentials }) => {
  return paymentsAPI.post({
    path: ONBOARDING_FORMS,
    values,
    meta,
    service: ONBOARDING_FORMS,
    credentials,
  })
}

export default postOnboardingFormAPI
