import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import capitalize from 'lodash/capitalize'

const frontendPayoutPlanModel = ({ data: payoutPlan }) => {
  const [
    id,
    name,
    combinationPlans,
    createdAt,
    entityId,
    level,
    processor,
    schedules,
    templates,
    updatedAt,
    merchant,
    application,
  ] = getMany(payoutPlan, [
    'id',
    'name',
    'combination_plans',
    'created_at',
    'entity_id',
    'level',
    'processor',
    'schedules',
    'templates',
    'updated_at',
    'merchant',
    'application',
  ])

  return removeUndefined({
    id,
    name,
    entityId,
    level,
    displayLevel: capitalize(level),
    processor,
    templates,
    schedules,
    combinationPlans,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    merchant: frontendMerchantModel({ data: merchant }),
    application: frontendApplicationModel({ data: application }),
  })
}

export default frontendPayoutPlanModel
