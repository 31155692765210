import { GET_WEBHOOK_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getWebhookRequest = ({
  webhookId,
  credentials,
  meta = { overwriteReducer: true },
}) => createAction({
  type: GET_WEBHOOK_F_REQUEST,
  id: webhookId,
  credentials,
  meta,
})

export default getWebhookRequest
