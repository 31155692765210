import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DisbursementsAccountColumn from 'components/Customer/Shared/Display/Columns/DisbursementsAccountColumn/DisbursementsAccountColumn'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import { createdAtRange } from 'utilities/table/sort'
import column from 'utilities/table/column'
import { balanceEntryStatusMap } from 'constants/statusConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  ADJUSTMENT_DECREASE,
  ADJUSTMENT_INCREASE,
  PULL_FROM_ACH,
  PULL_FROM_CARD,
} from 'constants/transferConstants'

import {
  AMOUNT,
  CREATED_ON,
  FINIX,
  FROM,
  ID,
  METHOD,
  PAYMENT_INSTRUMENT,
  POSTED_ON,
  STATE,
  TO,
  TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TO, 'recipientIdentity.fullName', {
    headerFormatter: () => <TableItemSubtitle title={TO} subtitle={FROM} />,
    formatter: ({ application, type, recipientIdentity }) => {
      const applicationName = get(application, 'businessName')
      const fullName = get(recipientIdentity, 'fullName')
      let title = ''
      let subtitle = ''

      if (type === PULL_FROM_CARD || type === PULL_FROM_ACH) {
        title = applicationName
        subtitle = fullName
      } else if (type === ADJUSTMENT_INCREASE || type === ADJUSTMENT_DECREASE) {
        title = applicationName
        subtitle = FINIX
      } else {
        title = fullName
        subtitle = applicationName
      }

      return <TableItemSubtitle title={title} subtitle={subtitle} />
    },
  }),
  column(METHOD, 'displayType', {
    className: 'small',
  }),
  column(AMOUNT, ['displayAmount', 'currency', 'moneyReceived'], {
    className: 'amount divider',
    formatter: ({ type, displayAmount, currency }) => (includes([PULL_FROM_CARD, PULL_FROM_ACH, ADJUSTMENT_INCREASE], type) ?
      <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} /> : <AmountCurrencyDisplayTable displayAmount={`(${displayAmount})`} currency={currency} />),
  }),
  column(STATE, 'state', {
    className: 'state',
    formatter: ({ state }) => <ColorcodedStatus value={state} data={balanceEntryStatusMap} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.maskedFullCardNumber', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={TYPE} />,
    className: 'medium',
    formatter: ({ paymentInstrument }) => <DisbursementsAccountColumn paymentInstrument={paymentInstrument} />,
  }),
  column(POSTED_ON, 'postedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={POSTED_ON} />,
    formatter: ({ postedAt }) => <DateTimestampColumn timestamp={postedAt} />,
  }),
]

export default columnDescriptors
