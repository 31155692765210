import './MerchantDepositsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import MerchantDepositPreviewPanelC from 'components/Customer/Pages/MerchantDeposits/MerchantDepositPreviewPanel/MerchantDepositPreviewPanelC'
import columnDescriptors from './columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { GET_TRANSFERS, TRANSFER } from 'constants/flowConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'
import { MERCHANT_DEPOSITS_LEARN_MORE_LINK } from 'constants/urlConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE,
  MERCHANT_DEPOSITS_RESOURCE_TITLE,
  MERCHANT_DEPOSITS_SUB_TITLE,
} from 'constants/language/languageConstants'

const MerchantDeposits = ({
  merchantDepositsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  hiddenExportFields = [],
  quickFilters = [],
  title = MERCHANT_DEPOSITS_RESOURCE_TITLE,
  showHeader = true,
}) => {
  return (
    <div className='MerchantDeposits'>
      {showHeader && (
        <HeaderV2C
          title={title}
          subTitle={MERCHANT_DEPOSITS_SUB_TITLE}
          learnMoreLink={MERCHANT_DEPOSITS_LEARN_MORE_LINK}
        />
      )}

      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={merchantDepositsPath}
        isFetching={isFetching}
        fileNameCSV={MERCHANT_DEPOSITS_RESOURCE_TITLE}
        entityType={TRANSFER}
        showAdvancedExport
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${PAYMENTS_ICON}`}
        emptyMessage={MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
        previewPanel={MerchantDepositPreviewPanelC}
      />
    </div>
  )
}

MerchantDeposits.propTypes = {
  merchantDepositsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  hiddenExportFields: PropTypes.array,
  quickFilters: PropTypes.array,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
}

export default MerchantDeposits
