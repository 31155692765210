import React, { Component } from 'react'
import { connect } from 'react-redux'
import BusinessIdentityVerificationReport from './BusinessIdentityVerificationReport'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatDateUTC from 'utilities/formatters/formatDateUTC'
import formatPhone from 'utilities/forms/format/formatPhone'
import getMany from 'utilities/get/getMany'
import { MM_DD_YYYY } from 'constants/timeConstants'
import map from 'lodash/map'
import join from 'lodash/join'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  BANKRUPTCY_CREDITOR_RECORD_COUNT,
  BANKRUPTCY_SUBJECT_RECORD_COUNT,
  CORPORATION_TYPE,
  DOMAINS,
  DUNS_NUMBER,
  FEIN,
  INCORPORATION_DATE,
  INCORPORATION_STATE,
  MATCHING_BUSINESS_CONTACT,
  PHONE_NUMBERS,
  REGISTRATION_TYPE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    names,
    addresses,
    bankruptcyCreditorRecordCount,
    bankruptcySubjectRecordCount,
    fein,
    dunsNumber,
    incorporationDate,
    incorporationState,
    registrationType,
    corporationType,
    matchingBusinessContactFound,
    domains,
    businessContacts,
    phoneNumbers,
  ] = getMany(report, [
    'names',
    'addresses',
    'bankruptcy_creditor_record_count',
    'bankruptcy_subject_record_count',
    'raw.BusinessData[0].FEIN',
    'raw.BusinessData[0].DunsNumber',
    'raw.BusinessData[0].IncorporationDate',
    'raw.BusinessData[0].IncorporationState',
    'raw.BusinessData[0].RegistrationType',
    'raw.BusinessData[0].CorporationType',
    'raw.BusinessData[0].MatchingBusinessContactFound',
    'raw.BusinessData[0].Domains',
    'raw.BusinessData[0].BusinessContacts',
    'raw.BusinessData[0].PhoneNumbers',
  ])

  const nameRecordsData = map(names, (name, index) => {
    return { label: `Record #${index + 1}`, value: name }
  })

  const phoneNumbersData = (
    <div className='phone-numbers-data'>
      {map(phoneNumbers, ({ PhoneNumber: phoneNumber }) => {
        return <div>{formatPhone(phoneNumber)}</div>
      })}
    </div>
  )

  const businessInformationData = [
    { label: MATCHING_BUSINESS_CONTACT, value: matchingBusinessContactFound },
    { label: FEIN, value: fein },
    { label: DUNS_NUMBER, value: dunsNumber },
    { label: CORPORATION_TYPE, value: corporationType },
    { label: REGISTRATION_TYPE, value: registrationType },
    { label: INCORPORATION_STATE, value: incorporationState },
    { label: INCORPORATION_DATE, value: formatDateUTC({ date: incorporationDate, format: MM_DD_YYYY }) },
    { label: DOMAINS, value: join(domains, ', ') },
    { label: BANKRUPTCY_SUBJECT_RECORD_COUNT, value: bankruptcySubjectRecordCount ? bankruptcySubjectRecordCount.toString() : undefined },
    { label: BANKRUPTCY_CREDITOR_RECORD_COUNT, value: bankruptcyCreditorRecordCount ? bankruptcyCreditorRecordCount.toString() : undefined },
    { label: PHONE_NUMBERS, value: isEmpty(phoneNumbersData) ? '-' : phoneNumbersData },
  ]

  const addressRecordsData = map(addresses, (address, index) => {
    const [
      line1,
      line2,
      city,
      region,
      country,
      postalCode,
    ] = getMany(address, [
      'address_lines[0]',
      'address_lines[1]',
      'locality',
      'administrative_area',
      'region_code',
      'postal_code',
    ])
    return {
      label: `Address #${index + 1}`,
      value: <Address address={{ line1, line2, city, region, country, postalCode }} />,
    }
  })

  const businessContactsData = map(businessContacts, (contact, index) => {
    const [
      firstName,
      lastName,
      title,
    ] = getMany(contact, [
      'FirstName',
      'LastName',
      'Title',
    ])
    return {
      label: `Contact #${index + 1}`,
      value: `${firstName} ${lastName} (${title})`,
    }
  })

  return {
    credentials,
    nameRecordsData,
    businessInformationData,
    addressRecordsData,
    businessContactsData,
  }
}

class BusinessIdentityVerificationReportC extends Component {
  render() {
    return (
      <BusinessIdentityVerificationReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(BusinessIdentityVerificationReportC)
