import {
  FINIX_LOGO_BLACK,
  FINIX_NAV_BAR_LOGO,
} from 'constants/logoConstants'

import {
  DUMMY_V1,
  LITLE_V1,
  VANTIV_V1,
  VISA_V1,
  MICROBILT_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'localservice',
  id: 'DASH8m9zZ8UhPzkHHmWjosVKwY', // 'DASH81ZU99SKsUdoETVcp73J4M', // finix dashboard id
  credentialsHostV2: 'https://qa-new.paymentsdashboard.io', // TODO: update to localhost:port of locally running service
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'VANTIV_V1', 'VISA_V1', 'MICROBILT_V1'],
  tokenizationLibraryUrl: 'https://forms.finixpymnts.com/finix.js',
  applicationLogoImage: FINIX_NAV_BAR_LOGO,
  // local dashboard service will use main Finix auth0 account
  auth0: {
    clientID: '2jOpN7fwuf6QsnXx2qqyOtbvIbUZUdA6',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: 'Local Service',
    logo: FINIX_LOGO_BLACK,
  },
  dashboardServiceV2: true, // using this to control dashboard service version
}
