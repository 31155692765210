import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PLATFORM,
  PLATFORMS,
} from 'constants/apiConstants'

const getPlatformAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: PLATFORM({ platformId: id }),
    credentials,
    service: PLATFORMS,
  })
}

export default getPlatformAPI
