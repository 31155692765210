import getWebhookAPI from 'api/finix/get/getWebhookAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import normalizeResponse from 'utilities/api/normalizeResponse'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * getWebhookO ({
  id,
  credentials,
}) {
  const { data: response } = yield getWebhookAPI({ id, credentials })

  const applicationId = get(response, 'application')

  let application = {}

  if (applicationId) {
    const { data: applicationResponse } = yield getApplicationAPI({
      id: applicationId,
      credentials,
    })

    application = frontendApplicationModel({ data: normalizeResponse({ content: applicationResponse }) })
  }

  const webhook = merge({}, response, { application })

  return createSuccessResponse({
    data: [webhook],
  })
}

export default co.wrap(getWebhookO)
