import './HostFileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'

const HostFileForm = ({
  submitValues = {},
  buttonGroup = {},
  onSubmit = () => {},
  domainInput = '',
  dispatch = () => {},
}) => {
  return (
    <form className='HostFileForm' onSubmit={() => onSubmit(submitValues, dispatch)}>
      <div className='primary-msg'>Please host the verification file at this location:</div>
      <div className='url-msg'>{`${domainInput}/.well-known/apple-developer-merchantid-domain-verification`}</div>

      {buttonGroup}
    </form>
  )
}

HostFileForm.propTypes = {
  buttonGroup: PropTypes.object,
}

export default HostFileForm
