import './BusinessMatchReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import map from 'lodash/map'

import {
  MERCHANT_INFORMATION,
  VERIFICATION_SUMMARY,
} from 'constants/language/languageConstants'

const BusinessMatchReport = ({
  hitsContent = [],
}) => {
  return (
    <div className='BusinessMatchReport'>
      {map(hitsContent, ({ title, merchantInformationColumns, verificationSummaryColumns, personsData }, index) => {
        return (
          <div className='hit-content' key={`hit-content-${index}`}>
            <h6 className='hit-title'>{title}</h6>
            <div className='p-2-bold'>{VERIFICATION_SUMMARY}</div>
            <PageSectionV2C
              columns={convertPageSectionDataToV2(verificationSummaryColumns)}
            />
            <div className='p-2-bold'>{MERCHANT_INFORMATION}</div>
            <PageSectionV2C
              columns={convertPageSectionDataToV2(merchantInformationColumns)}
            />
            {map(personsData, ({ title: personTitle, columns: personColumns }, personIndex) => {
              return (
                <div key={`person-${personIndex}`}>
                  <div className='p-2-bold'>{personTitle}</div>
                  <PageSectionV2C
                    columns={personColumns}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

BusinessMatchReport.propTypes = {
  hitsContent: PropTypes.array,
}

export default BusinessMatchReport
