import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'

const frontendSubscriptionEnrollmentModel = ({ data: subscriptionEnrollment }) => {
  const [
    id,
    merchantId,
    startedAt,
    endedAt,
    enrollmentNickname,
    subscriptionScheduleId,
    tags,
    userId,
    createdAt,
    updatedAt,
  ] = getMany(subscriptionEnrollment, [
    'id',
    'merchant',
    'started_at',
    'ended_at',
    'nickname',
    'subscription_schedule',
    'tags',
    'created_by',
    'created_at',
    'updated_at',
  ])

  return removeUndefined({
    id,
    merchantId,
    startedAt,
    endedAt,
    enrollmentNickname,
    subscriptionScheduleId,
    tags,
    userId,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayStartedAt: formatDate({ date: startedAt }),
    displayEndedAt: formatDate({ date: endedAt }),
  })
}

export default frontendSubscriptionEnrollmentModel
