import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccessFormReview from './AccessFormReview'
import FormFileUploaderButton from 'components/Shared/Inputs/FormFileUploaderButton/FormFileUploaderButton'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getAccessFormRequest from 'utilities/actions/get/getAccessFormRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import patchAccessFormAdditionalDocumentsRequest from 'utilities/actions/patch/patchAccessFormAdditionalDocumentsRequest'
import showModalAction from 'utilities/actions/showModalAction'
import hasPermission from 'utilities/hasPermission'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM } from 'constants/formConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { FILE_VIEWER_MODAL } from 'constants/modalConstants'
import { ADMINISTRATOR } from 'constants/pathConstants'
import capitalize from 'lodash/capitalize'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  getAccessFormSelector,
  getPlaidTokenMetadataSelector,
} from 'state-layer/selectors'

import {
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
  IN_PROGRESS_BE_VALUE,
} from 'constants/statusConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

import {
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  ANNUAL_CARD_VOLUME,
  ANNUAL_TRANSACTION_COUNT,
  BANK_ACCOUNT,
  BANK_DOCUMENT,
  BANK_NAME,
  BUSINESS_NAME,
  BUSINESS_TAX_ID,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE,
  DATE_OF_BIRTH,
  DATE_OF_INCORPORATION,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DOING_BUSINESS_AS,
  EIN_VERIFICATION_DOCUMENT,
  EMAIL_ADDRESS,
  IN_PERSON_PAYMENTS,
  INSTITUTION_NUMBER,
  MAX_CARD_TRANSACTION_AMOUNT,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_COUNT,
  ONLINE_PAYMENTS,
  OTHER,
  OWNERSHIP_TYPE,
  PAYMENT_SOLUTIONS,
  PHONE_NUMBER,
  PRICING_TYPE,
  ROUTING_NUMBER,
  SHORT_BUSINESS_DESCRIPTION,
  SUBDOMAINS,
  SUCCESSFULLY_LINKED,
  TAX_ID,
  TRANSIT_NUMBER,
  UPLOAD,
  VIEW_FILE,
  WEBSITE,
} from 'constants/language/languageConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
} from 'constants/labelConstants'

// set file upload configs for onboarding forms (customer and merchant)
const config = {
  multiple: false,
  maxSize: 20000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag and drop, or click to select and upload a file',
  },
  {
    name: 'sub',
    message: 'Accept: jpeg, png, tiff, pdf; Max Size: 20 MB',
  },
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'accessFormId') || get(props, 'params.accessFormId')
  const accessForm = get(props, 'accessForm', getAccessFormSelector(state, accessFormId))
  const isHostedAccessForm = get(props, 'isHostedAccessForm', false)
  const userRoleName = get(credentials, 'displayDashboardUserRoleName')
  const isAdministratorRole = isEqual(userRoleName, ADMINISTRATOR)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials }) && isAdministratorRole
  const plaidTokenMetadata = getPlaidTokenMetadataSelector(state)

  const [
    bankAccountData,
    bankDocument,
    beneficialOwners,
    businessData,
    controlPerson,
    einVerificationFile,
    displayPricingType,
    processingData,
    additionalDocuments,
    status,
    country,
    businessType,
  ] = getMany(accessForm, [
    'bankAccountData',
    'bankDocument',
    'beneficialOwners',
    'businessData',
    'controlPerson',
    'einVerificationFile',
    'displayPricingType',
    'processingData',
    'additionalDocuments',
    'status',
    'country',
    'businessData.businessType',
  ])

  // for the POPs view, check the customer type of the form, not the current user.
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const additionalVerificationNeeded = isEqual(status, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE)
  const isInProgress = isEqual(status, IN_PROGRESS_BE_VALUE)

  const pricingInfoDataSection = [
    {
      type: 'data',
      data: [
        { label: PRICING_TYPE, value: displayPricingType },
      ],
    },
  ]

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress,
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth,
    controlPersonTaxId,
    controlPersonDisplayPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'firstName',
    'lastName',
    'email',
    'personalAddress',
    'principalPercentageOwnership',
    'displayDateOfBirth',
    'taxId',
    'displayPhone',
    'title',
  ])

  const controlPersonInfoDataSection = !isEmpty(controlPerson) ? [
    {
      largeTitle: `${controlPersonFirstName} ${controlPersonLastName}`,
      asideTitle: `(${controlPersonTitle}, ${controlPersonPrincipalPercentageOwnership}%)`,
      data: [
        { label: DATE_OF_BIRTH, value: controlPersonDateOfBirth },
        { label: TAX_ID, value: controlPersonTaxId },
        { label: EMAIL_ADDRESS, value: controlPersonEmail },
        { label: PHONE_NUMBER, value: controlPersonDisplayPhone },
        { label: ADDRESS, value: <Address address={controlPersonPersonalAddress} /> },
      ],
    },
  ] : []

  const beneficialOwnershipInfoDataSection = map(beneficialOwners, owner => {
    const [
      firstName,
      lastName,
      email,
      personalAddress,
      principalPercentageOwnership,
      displayDateOfBirth,
      taxId,
      displayPhone,
      title,
    ] = getMany(owner, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'displayDateOfBirth',
      'taxId',
      'displayPhone',
      'title',
    ])

    return {
      largeTitle: `${firstName} ${lastName}`,
      asideTitle: `(${title}, ${principalPercentageOwnership}%)`,
      data: [
        { label: DATE_OF_BIRTH, value: displayDateOfBirth },
        { label: TAX_ID, value: taxId },
        { label: EMAIL_ADDRESS, value: email },
        { label: PHONE_NUMBER, value: displayPhone },
        { label: ADDRESS, value: <Address address={personalAddress} /> },
      ],
    }
  })

  const [
    displayMccCodes,
    defaultStatementDescriptor,
    displayAnnualTransactionCount,
    displayAnnualCardVolume,
    displayAnnualACHVolume,
    displayMaxCardTransactionAmount,
    displayMaxACHTransactionAmount,
    displayEcommercePercentage,
    displayCardPresentPercentage,
    displayBusinessToBusinessVolumePercentage,
    displayBusinessToConsumerVolumePercentage,
    displayOtherVolumePercentage,
    displayAcceptedPaymentTypes,
  ] = getMany(processingData, [
    'displayMccCodes',
    'defaultStatementDescriptor',
    'displayAnnualTransactionCount',
    'displayAnnualCardVolume',
    'displayAnnualACHVolume',
    'displayMaxCardTransactionAmount',
    'displayMaxACHTransactionAmount',
    'displayEcommercePercentage',
    'displayCardPresentPercentage',
    'displayBusinessToBusinessVolumePercentage',
    'displayBusinessToConsumerVolumePercentage',
    'displayOtherVolumePercentage',
    'displayAcceptedPaymentTypes',
  ])

  const processingInfoDataSection = convertPageSectionDataToV2([
    { label: PAYMENT_SOLUTIONS, value: displayAcceptedPaymentTypes, condition: !isHostedAccessForm },
    { label: MERCHANT_CATEGORY_CODE, value: displayMccCodes },
    { label: DEFAULT_STATEMENT_DESCRIPTOR, value: defaultStatementDescriptor, condition: !isHostedAccessForm },
    { label: ANNUAL_TRANSACTION_COUNT, value: displayAnnualTransactionCount },
    { label: ANNUAL_CARD_VOLUME, value: displayAnnualCardVolume },
    { label: annualACHVolumeLabelMap(country), value: displayAnnualACHVolume },
    { label: MAX_CARD_TRANSACTION_AMOUNT, value: displayMaxCardTransactionAmount },
    { label: achMaxTransactionAmountLabelMap(country), value: displayMaxACHTransactionAmount },
  ], 1)

  const cardVolumeDataSection = convertPageSectionDataToV2([
    { label: ONLINE_PAYMENTS, value: displayEcommercePercentage },
    { label: IN_PERSON_PAYMENTS, value: displayCardPresentPercentage },
  ], 1)

  const paymentVolumeDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_TO_BUSINESS, value: displayBusinessToBusinessVolumePercentage },
    { label: BUSINESS_TO_CONSUMER, value: displayBusinessToConsumerVolumePercentage },
    { label: OTHER, value: displayOtherVolumePercentage },
  ], 1)

  return {
    credentials,
    accessForm,
    accessFormId,
    showUnmaskAction,
    businessData,
    bankAccountData,
    einVerificationFile,
    bankDocument,
    pricingInfoDataSection,
    controlPersonInfoDataSection,
    beneficialOwnershipInfoDataSection,
    processingInfoDataSection,
    cardVolumeDataSection,
    paymentVolumeDataSection,
    additionalDocuments,
    additionalVerificationNeeded,
    isStandaloneMerchant,
    isHostedAccessForm,
    country,
    businessType,
    isInProgress,
    plaidTokenMetadata,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessForm: ({ credentials, accessFormId }) => { dispatch(getAccessFormRequest({ credentials, accessFormId })) },
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
    patchAccessFormAdditionalDocuments: ({ credentials, fileType, file, fileName, id, existingAdditionalDocuments, isHostedAccessForm }) => dispatch(patchAccessFormAdditionalDocumentsRequest({ credentials, fileType, file, fileName, id, existingAdditionalDocuments, isHostedAccessForm })),
    patchGuestAccessFormAdditionalDocuments: ({ fileType, file, fileName, id, existingAdditionalDocuments, isHostedAccessForm }) => dispatch(patchAccessFormAdditionalDocumentsRequest({ fileType, file, fileName, id, existingAdditionalDocuments, isHostedAccessForm })),
  }
}

class AccessFormReviewC extends Component {
  componentDidMount() {
    const {
      credentials,
      accessFormId,
      getAccessForm,
      accessForm,
    } = this.props

    if (accessFormId && isEmpty(accessForm)) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accessFormId: prevAccessFormId } = prevProps

    const {
      accessFormId,
      getAccessForm,
      accessForm,
      credentials,
    } = this.props

    if (accessFormId && isEmpty(accessForm) && accessFormId !== prevAccessFormId) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  render() {
    const {
      showFileViewer,
      businessData,
      einVerificationFile,
      bankAccountData,
      bankDocument,
      accessFormId,
      additionalDocuments: existingAdditionalDocuments,
      showUploadFileModal,
      patchAccessFormAdditionalDocuments,
      additionalVerificationNeeded,
      isInProgress,
      isStandaloneMerchant,
      isHostedAccessForm,
      patchGuestAccessFormAdditionalDocuments,
      country,
      plaidTokenMetadata,
    } = this.props

    const [
      businessName,
      doingBusinessAs,
      businessDescription,
      displayBusinessType,
      displayBusinessOwnershipType,
      displayBusinessIncorporationDate,
      businessTaxId,
      businessWebsite,
      businessWebsiteDomains,
      businessEmail,
      displayBusinessPhone,
      businessMerchantCount,
      businessAddress,
    ] = getMany(businessData, [
      'businessName',
      'doingBusinessAs',
      'businessDescription',
      'displayBusinessType',
      'displayBusinessOwnershipType',
      'displayBusinessIncorporationDate',
      'businessTaxId',
      'businessWebsite',
      'businessWebsiteDomains',
      'businessEmail',
      'displayBusinessPhone',
      'businessMerchantCount',
      'businessAddress',
    ])

    const businessInfoDataSection = convertPageSectionDataToV2([
      { label: BUSINESS_NAME, value: businessName },
      { label: DOING_BUSINESS_AS, value: doingBusinessAs },
      { label: SHORT_BUSINESS_DESCRIPTION, value: businessDescription },
      { label: BUSINESS_TYPE, value: displayBusinessType },
      { label: OWNERSHIP_TYPE, value: displayBusinessOwnershipType },
      { label: DATE_OF_INCORPORATION, value: displayBusinessIncorporationDate },
      {
        label: BUSINESS_TAX_ID,
        value: businessTaxId,
      },
      {
        label: EIN_VERIFICATION_DOCUMENT,
        value: einVerificationFile ? VIEW_FILE : '-',
        action: einVerificationFile ? () => {
          showFileViewer({ id: get(einVerificationFile, 'file_id'), accessFormId, isHostedAccessForm })
        } : () => {},
      },
      { label: MERCHANT_COUNT, value: businessMerchantCount, condition: !isStandaloneMerchant },
      { label: WEBSITE, value: validateWebsiteLink({ website: businessWebsite }) ? <a className='text-link' href={businessWebsite} target='blank'>{businessWebsite}</a> : businessWebsite },
      { label: SUBDOMAINS, value: businessWebsiteDomains },
      { label: EMAIL_ADDRESS, value: businessEmail },
      { label: PHONE_NUMBER, value: displayBusinessPhone },
      { label: ADDRESS, value: <Address address={businessAddress} /> },
    ], 1)

    const [
      accountName,
      displayAccountType,
      routingNumber,
      accountNumber,
      transitNumber,
      institutionNumber,
      thirdPartyToken,
    ] = getMany(bankAccountData, [
      'name',
      'displayAccountType',
      'bankCode',
      'accountNumber',
      'transitNumber',
      'institutionNumber',
      'thirdPartyToken',
    ])

    const [
      plaidBankName,
      plaidAccountType,
      plaidMaskedAccountNumber,
    ] = getMany(plaidTokenMetadata, [
      'institution.name',
      'account.subtype',
      'account.mask',
    ])

    const bankAccountColumns = thirdPartyToken ? [
      {
        label: BANK_NAME,
        value: plaidBankName,
        condition: !!plaidMaskedAccountNumber,
      },
      {
        label: ACCOUNT_TYPE,
        value: capitalize(plaidAccountType),
        condition: !!plaidMaskedAccountNumber,
      },
      {
        label: ACCOUNT_NUMBER,
        value: `**${plaidMaskedAccountNumber}`,
        condition: !!plaidMaskedAccountNumber,
      },
      {
        label: BANK_ACCOUNT,
        value: <div><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
        condition: isEmpty(plaidMaskedAccountNumber),
      },
    ] : [
      { label: ACCOUNT_NAME, value: accountName },
      { label: ACCOUNT_TYPE, value: displayAccountType },
      {
        label: ROUTING_NUMBER,
        value: routingNumber,
        condition: country === USA,
      },
      { label: TRANSIT_NUMBER,
        value: transitNumber,
        condition: country === CAN,
      },
      {
        label: INSTITUTION_NUMBER,
        value: institutionNumber,
        condition: country === CAN,
      },
      { label: ACCOUNT_NUMBER, value: accountNumber },
      {
        label: BANK_DOCUMENT,
        value: bankDocument ? VIEW_FILE : '-',
        action: bankDocument ? () => {
          showFileViewer({ id: get(bankDocument, 'file_id'), accessFormId, isHostedAccessForm })
        } : () => {},
      },
    ]

    const bankAccountInfoDataSection = convertPageSectionDataToV2(bankAccountColumns, 1)

    const additionalDocumentActions = [
      {
        label: UPLOAD,
        condition: additionalVerificationNeeded || isInProgress,
        component: FormFileUploaderButton,
        extraProps: {
          formName: LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM,
          fieldName: 'businessData.additionalDocuments',
          showUploadFileModal,
          directUpload: true,
          accessFormId,
          existingAdditionalDocuments,
          isHostedAccessForm,
          fileNameRequired: true,
          directUploadFunc: isHostedAccessForm ? patchGuestAccessFormAdditionalDocuments : patchAccessFormAdditionalDocuments,
          config,
          instructions,
        },
      },
    ]
    return (
      <AccessFormReview
        {...this.props}
        businessInfoDataSection={businessInfoDataSection}
        bankAccountInfoDataSection={bankAccountInfoDataSection}
        additionalDocumentActions={additionalDocumentActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessFormReviewC)
