import React from 'react'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import { createdAtRange } from 'utilities/table/sort'
import getMany from 'utilities/get/getMany'
import column from 'utilities/table/column'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'

import {
  CREATED_ON,
  DISABLE,
  ID,
  NAME,
  STATE,
  USERNAME,
  ENABLED,
  DISABLED,
  LAST_USED,
  CREATED_BY,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ columnActions = {} }) => [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(USERNAME, 'username', {
    className: 'medium',
    formatter: ({ id }) => id,
  }),
  column(NAME, 'name', { className: 'medium' }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(LAST_USED, 'displayLastUsedDate', {
    className: 'date',
  }),
  column(STATE, 'state', {
    className: 'small',
    formatter: ({ state }) => <EnabledStatus value={state ? ENABLED : DISABLED} />,
  }),
  column(CREATED_BY, 'createdBy', {
    className: 'grow',
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: ({ id, state }) => {
      const [
        showDisableAPIKeyModal,
      ] = getMany(columnActions, [
        'showDisableAPIKeyModal',
      ])

      const actions = [
        {
          label: DISABLE,
          action: () => showDisableAPIKeyModal({ keyId: id }),
          condition: state,
        },
      ]

      return (
        <TableRowActionsC
          actions={actions}
        />
      )
    },
  }),
]

export default columnDescriptors
