import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getWebhooksWithStoppedEventsSelector } from 'state-layer/selectors'
import Webhooks from './Webhooks'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getWebhooksWithStoppedEventsRequest from 'utilities/actions/get/getWebhooksWithStoppedEventsRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { CREATE_WEBHOOK } from 'constants/language/languageConstants'
import { FETCHING } from 'constants/reducerConstants'
import { ADD_TABLE_FILTER } from 'constants/flowConstants'
import { SHOW_WEBHOOK_LOGS } from 'constants/featureFlagConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'

import {
  APPLICATION_ID,
  ACCEPTING_EVENTS,
  EQUALS,
} from 'constants/filterConstants'

import {
  WEBHOOK_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  WEBHOOKS_PATH,
  APPLICATION_WEBHOOKS_PATH,
  CREATE_WEBHOOK_PATH,
  CREATE_APPLICATION_WEBHOOK_PATH,
} from 'constants/pathConstants'

const allowedFilters = [
  APPLICATION_ID,
  ACCEPTING_EVENTS,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  WEBHOOK_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `webhooksR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  // if applicationId exists, this indicates we are viewing application webhooks
  const applicationId = isRolePlatform({ credentials }) ? get(props, 'applicationId') : undefined
  const normalizedQueries = applicationId ? merge({}, initialQueries, { application_id: applicationId }) : initialQueries
  // TODO: separate application level webhooks out into a separate component? ApplicationWebhooks instead of handling via ternary checks?
  const webhookPath = applicationId ? APPLICATION_WEBHOOKS_PATH({ credentialId, applicationId }) : WEBHOOKS_PATH({ credentialId })
  const showWebhookLogs = getFeatureFlag(SHOW_WEBHOOK_LOGS)
  let normalizedFilters = showWebhookLogs ? allowedFilters : [APPLICATION_ID]

  if (applicationId) {
    normalizedFilters = showWebhookLogs ? [ACCEPTING_EVENTS] : []
  }

  const webhooksWithStoppedEventsExist = (size(getWebhooksWithStoppedEventsSelector(state)) > 0) && showWebhookLogs


  return {
    webhookPath,
    initialQueries: normalizedQueries,
    isFetching,
    credentials,
    allowedFilters: normalizedFilters,
    credentialId,
    applicationId,
    flowValues: { applicationId },
    quickFilters,
    webhooksWithStoppedEventsExist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWebhooksWithStoppedEvents: ({ credentials, queries }) => dispatch(getWebhooksWithStoppedEventsRequest({ credentials, queries })),
    addWebhooksWithStoppedEventsFilter: () => dispatch({ type: ADD_TABLE_FILTER, payload: { field: 'is_accepting_events', operand: EQUALS, value: false } }),
    addEnabledWebhooksFilter: () => dispatch({ type: ADD_TABLE_FILTER, payload: { field: 'enabled', operand: EQUALS, value: true } }),
  }
}

class WebhooksC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getWebhooksWithStoppedEvents,
    } = this.props

    const normalizedApplicationId = isEmpty(applicationId) ? undefined : applicationId

    const queries = removeUndefined({
      enabled: true,
      is_accepting_events: false,
      application_id: normalizedApplicationId,
    })

    getWebhooksWithStoppedEvents({ credentials, queries })
  }

  filterByWebhooksWithStoppedEvents = () => {
    const {
      addWebhooksWithStoppedEventsFilter,
      addEnabledWebhooksFilter,
    } = this.props

    addWebhooksWithStoppedEventsFilter()
    addEnabledWebhooksFilter()
  }

  render() {
    const { credentialId, applicationId } = this.props

    const actions = [
      {
        className: 'primary-btn create-webhook-action',
        label: CREATE_WEBHOOK,
        link: applicationId ? CREATE_APPLICATION_WEBHOOK_PATH({ credentialId, applicationId }) : CREATE_WEBHOOK_PATH({ credentialId }),
      },
    ]

    return (
      <Webhooks
        {...this.props}
        actions={actions}
        filterByWebhooksWithStoppedEvents={this.filterByWebhooksWithStoppedEvents}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksC)
