import React from 'react'
import { getCardBrand } from 'constants/bankConstants'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

const formatCardBrandWithIcon = ({ brand }) => {
  if (!brand) return null
  const brandIcon = getCardBrand(brand)
  const formattedBrand = snakeCaseToTitleCase({ key: brand })

  // TODO: make a folder for utility components like this that have scss styling
  // updated TODO: new CardBrandIcon component made - replace all instances of this utility with that and then deprecate
  return (
    <div className='flex items-center'>
      <i className={`icon ${brandIcon}`} />
      &nbsp;<div>{formattedBrand}</div>
    </div>
  )
}

export default formatCardBrandWithIcon
