import './PayoutSettingsSchedulePreviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { DAY_MAPPING } from 'constants/timeConstants'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import unzip from 'lodash/unzip'

import {
  STANDARD,
  BEFORE_7_AM,
  BEFORE_4_PM,
  ESTIMATE,
  convertDayToBusinessDay,
} from 'constants/payoutSettingsConstants'

import {
  PAYOUT_DEPOSITED_TO_BANK_ACCOUNT,
  SETTLEMENT_APPROVED_ON,
} from 'constants/language/languageConstants'

const SchedulePreview = ({
  headerData = [],
  scheduleData = [],
  classNames,
}) => {
  return (
    <table className='schedule'>
      <thead className='schedule-headers flex'>
        {map(headerData, ({ label, subLabel }, index) => {
          return (
            <th className='schedule-header flex column' key={`schedule-header-${index}`}>
              <div className='label p-2-bold'>{label}</div>
              <div className='supporting-text p-3'>{subLabel}</div>
            </th>
          )
        })}
      </thead>

      <tbody>
        <tr>
          {map(scheduleData, (row, index) => {
            return (
              <div className='flex schedule-days' key={`schedule-days-${index}`}>
                {map(row, (day, dayIndex) => <td className='schedule-day p-2' key={`schedule-day-${dayIndex}`}>{day}</td>)}
              </div>
            )
          })}
        </tr>
      </tbody>
    </table>
  )
}

const PayoutSettingsSchedulePreview = ({
  data = {},
  isFeePreferenceSection = false,
}) => {
  const {
    rail,
    payoutSubmissionDelayDays = 0,
    feeSubmissionDelayDays = 0,
  } = data

  const isRailStandard = rail === STANDARD
  // const payoutFundingScheduleData = map(Object.keys(DAY_MAPPING), (day) => convertDayToBusinessDay(day, payoutSubmissionDelayDays))
  // const feeFundingScheduleData = map(Object.keys(DAY_MAPPING), (day) => convertDayToBusinessDay(day, feeSubmissionDelayDays))
  const feeDepositScheduleData = map(Object.keys(DAY_MAPPING), (day) => convertDayToBusinessDay(day, toNumber(feeSubmissionDelayDays) + (isRailStandard ? 1 : 0)))
  const payoutDepositScheduleData = map(Object.keys(DAY_MAPPING), (day) => convertDayToBusinessDay(day, toNumber(payoutSubmissionDelayDays) + (isRailStandard ? 1 : 0)))

  const payoutScheduleHeaderData = [
    {
      label: SETTLEMENT_APPROVED_ON,
      subLabel: isRailStandard ? BEFORE_4_PM : BEFORE_7_AM,
    },
    {
      label: PAYOUT_DEPOSITED_TO_BANK_ACCOUNT,
      subLabel: ESTIMATE,
    },
  ]

  const feeScheduleHeaderData = [
    {
      label: SETTLEMENT_APPROVED_ON,
      subLabel: isRailStandard ? BEFORE_4_PM : BEFORE_7_AM,
    },
    {
      label: PAYOUT_DEPOSITED_TO_BANK_ACCOUNT,
      subLabel: ESTIMATE,
    },
  ]

  const feeScheduleData = unzip([
    Object.values(DAY_MAPPING),
    feeDepositScheduleData,
  ])

  const payoutScheduleData = unzip([
    Object.values(DAY_MAPPING),
    payoutDepositScheduleData,
  ])

  const PayoutSchedulePreviewSection = SchedulePreview({
    headerData: payoutScheduleHeaderData,
    scheduleData: payoutScheduleData,
    classNames: 'payout-schedule',
  })

  const FeeSchedulePreviewSection = SchedulePreview({
    headerData: feeScheduleHeaderData,
    scheduleData: feeScheduleData,
    classNames: 'fee-schedule',
  })

  return (
    isFeePreferenceSection ? (
      <div className='FeeSettingsSchedulePreview'>
        <PageSectionV2C>
          {FeeSchedulePreviewSection}
        </PageSectionV2C>
      </div>
    ) : (
      <div className='PayoutSettingsSchedulePreview'>
        <PageSectionV2C>
          {PayoutSchedulePreviewSection}
        </PageSectionV2C>
      </div>
    )
  )
}

PayoutSettingsSchedulePreview.propTypes = {
  data: PropTypes.object,
}

export default PayoutSettingsSchedulePreview
