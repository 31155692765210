import React, { Component } from 'react'
import ComplianceExplanationOfNonApplicabilityForm from './ComplianceExplanationOfNonApplicabilityForm'
import { getComplianceSelfAssessmentQuestionnaireASelector } from 'state-layer/selectors'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import some from 'lodash/some'
import { COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const complianceSelfAssessmentQuestionnaireAValues = getComplianceSelfAssessmentQuestionnaireASelector(state)
  const saqAFormHasNA = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'N/A')

  return {
    saqAFormHasNA,
  }
}

class ComplianceExplanationOfNonApplicabilityFormC extends Component {
  componentDidMount() {
    const {
      saqAFormHasNA,
      dispatch,
    } = this.props

    if (!saqAFormHasNA) {
      dispatch(change(COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM, 'explanationOfNonApplicability', []))
    }
  }

  render() {
    return (
      <ComplianceExplanationOfNonApplicabilityForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceExplanationOfNonApplicabilityFormC)
