import { GET_ACCESS_FORMS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAccessFormsRequest = ({
  credentials,
  applicationId,
}) => createAction({
  type: GET_ACCESS_FORMS_F_REQUEST,
  queries: {
    sandbox_application_id: applicationId,
  },
  credentials,
})

export default getAccessFormsRequest
