import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import CreateInstantPayoutConfirmation from './CreateInstantPayoutConfirmation'
import { countryNameByCountryCode } from 'constants/countryConstants'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getInstantPayoutRequest from 'utilities/actions/get/getInstantPayoutRequest'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { getCardBrand } from 'constants/bankConstants'
import { FETCHING } from 'constants/reducerConstants'
import { SETTLEMENT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  getInstantPayoutSelector,
  getPaymentInstrumentSelector,
} from 'state-layer/selectors'

import {
  COUNTRY,
  EXPIRATION_DATE,
  SETTLEMENT_AMOUNT,
  STATE,
  SETTLEMENT_ID,
  INSTANT_PAYOUT_ID,
  NAME,
  MASKED_NUMBER,
  TYPE,
  PENDING,
  SUCCEEDED,
  FAILED,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingInstantPayout = get(state, `fundingTransferAttemptsR.${FETCHING}`)
  const isFetchingPaymentInstrument = get(state, `paymentInstrumentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const instantPayoutId = get(props, 'params.instantPayoutId')
  const settlementId = get(props, 'params.settlementId')
  const instantPayout = getInstantPayoutSelector(state, instantPayoutId)
  const paymentInstrumentId = getUrlQuery('paymentInstrumentId')
  const paymentInstrumentInfo = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const settlementPath = SETTLEMENT_PATH({ credentialId, settlementId })
  const instantPayoutPath = `${SETTLEMENT_PATH({ credentialId, settlementId })}?tab=instant-payouts`

  const [
    status,
    statusValue,
    settlementResourceId,
    instantPayoutResourceId,
    failureCode,
    failureMessage,
    displayAmount,
  ] = getMany(instantPayout, [
    'displayState',
    'state',
    'settlementId',
    'id',
    'failureCode',
    'failureMessage',
    'displayAmount',
  ])

  const isPending = status === PENDING
  const isFailed = status === FAILED
  const isSucceeded = status === SUCCEEDED

  const [
    name,
    cardBrand,
    maskedFullCardNumber,
    displayInstrumentType,
    expirationDate,
    addressCountry,
    currency,
  ] = getMany(paymentInstrumentInfo, [
    'name',
    'brand',
    'maskedFullCardNumber',
    'displayInstrumentType',
    'expirationDate',
    'addressCountry',
    'currency',
  ])

  const cardIcon = getCardBrand(cardBrand)

  const instantPayoutDetails = convertPageSectionDataToV2([
    {
      label: SETTLEMENT_AMOUNT,
      value: <div className='flex items-center'>{displayAmount}<span>&nbsp;{currency}</span></div>,
    },
    {
      label: TYPE,
      value: displayInstrumentType,
    },
    {
      label: NAME,
      value: name,
    },
    {
      label: MASKED_NUMBER,
      value: <div className='flex items-center'><i className={`card-brand-icon ${cardIcon}`} /><span>&nbsp;{maskedFullCardNumber}</span></div>,
    },
    {
      label: EXPIRATION_DATE,
      value: expirationDate,
    },
    {
      label: COUNTRY,
      value: countryNameByCountryCode[addressCountry],
    },
    {
      label: STATE,
      value: <TransferStatus value={statusValue} />,
    },
    {
      label: SETTLEMENT_ID,
      value: <Link className='text-link' to={settlementPath}>{settlementResourceId}</Link>,
    },
    {
      label: INSTANT_PAYOUT_ID,
      value: <Link className='text-link' to={instantPayoutPath}>{instantPayoutResourceId}</Link>,
    },
  ], 1)

  return {
    isFetchingInstantPayout,
    isFetchingPaymentInstrument,
    credentials,
    instantPayoutId,
    settlementId,
    instantPayoutDetails,
    paymentInstrumentId,
    failureCode,
    failureMessage,
    isPending,
    isFailed,
    isSucceeded,
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    printPage: () => { window.print() },
    getInstantPayout: ({ instantPayoutId, settlementId, credentials }) => { dispatch(getInstantPayoutRequest({ fundingTransferAttemptId: instantPayoutId, settlementId, credentials })) },
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => { dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })) },
    getSettlement: ({ settlementId, credentials }) => { dispatch(getSettlementRequest({ settlementId, credentials })) },
  }
}

class CreateInstantPayoutConfirmationC extends Component {
  componentDidMount() {
    const {
      credentials,
      getInstantPayout,
      instantPayoutId,
      settlementId,
      getPaymentInstrument,
      paymentInstrumentId,
      getSettlement,
    } = this.props

    if (instantPayoutId) {
      getInstantPayout({ instantPayoutId, settlementId, credentials })
    }
    if (paymentInstrumentId) {
      getPaymentInstrument({ paymentInstrumentId, credentials })
    }
    if (settlementId) {
      getSettlement({ settlementId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      instantPayoutId: prevInstantPayoutId,
      paymentInstrumentId: prevPaymentInstrumentId,
    } = prevProps

    const {
      credentials,
      getInstantPayout,
      instantPayoutId,
      settlementId,
      getPaymentInstrument,
      paymentInstrumentId,
    } = this.props

    if (instantPayoutId && instantPayoutId !== prevInstantPayoutId) {
      getInstantPayout({ instantPayoutId, settlementId, credentials })
    }

    if (instantPayoutId && paymentInstrumentId !== prevPaymentInstrumentId) {
      getPaymentInstrument({ paymentInstrumentId, credentials })
    }
  }

  render() {
    return (
      <CreateInstantPayoutConfirmation {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInstantPayoutConfirmationC)
