import getWelcomeEmailTemplateAPI from 'api/dashboard/get/getWelcomeEmailTemplateAPI'
import patchWelcomeEmailTemplateAPI from 'api/dashboard/patch/patchWelcomeEmailTemplateAPI'
import postDashboardFileAPI from 'api/dashboard/post/postDashboardFileAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import get from 'lodash/get'
import co from 'co'

const insertUrlToHtml = (url, html) => {
  const start = html.indexOf('<img')
  const end = html.indexOf('>', start)
  const imageElement = html.substring(start, end + 1)

  const srcStart = imageElement.indexOf('src')
  const srcEndSpace = imageElement.indexOf(' ', srcStart)
  const originalSrc = imageElement.substring(srcStart, srcEndSpace)

  return html.replace(originalSrc, `src="${url}"`)
}

function * patchWelcomeEmailTemplateO ({
  values: {
    html,
    text,
    subject,
    logo,
  },
}) {
  let fileResponse

  if (logo) {
    const formData = new FormData()
    formData.append('file', logo)

    fileResponse = yield postDashboardFileAPI({ values: formData })
  }

  const url = get(fileResponse, 'data.url', '')
  const welcomeEmailTemplates = {
    html_part: url ? insertUrlToHtml(url, html) : html,
    text_part: text,
    subject_part: subject,
  }

  const { error } = yield patchWelcomeEmailTemplateAPI({ values: welcomeEmailTemplates })

  if (error) {
    return createErrorResponse({ error })
  }

  const { data: templateObject } = yield getWelcomeEmailTemplateAPI({})

  return createSuccessResponse({ data: templateObject })
}

export default co.wrap(patchWelcomeEmailTemplateO)
