import selectOption from 'utilities/forms/selectOption'
import { ALL } from 'constants/transferConstants'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import invert from 'lodash/invert'
import get from 'lodash/get'

import {
  LIVE_ENVIRONMENT,
  QA_ENVIRONMENT,
  SANDBOX_ENVIRONMENT,
} from 'constants/environmentConstants'

import {
  CHECK_CIRCLE_ICON,
  ALERT_CIRCLE_ICON,
} from 'constants/iconConstants'

import {
  ALL as ALL_LABEL,
  MANUAL_REVIEW as MANUAL_REVIEW_LABEL,
  APPROVED as APPROVED_LABEL,
} from 'constants/language/languageConstants'

import {
  DANGER,
  DEFAULT,
  SUCCESS,
  GOLD_WARNING,
  WARNING,
  IN_PROGRESS,
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
  SALES_REVIEW,
} from 'constants/statusConstants'

export const MANUAL_REVIEW = 'MANUAL_REVIEW'
export const ACCEPTED = 'ACCEPTED'
export const REJECTED = 'REJECTED'
export const WITHDRAWN = 'WITHDRAWN'
export const ADDITIONAL_VERIFICATION_NEEDED = 'ADDITIONAL_VERIFICATION_NEEDED'
export const ACCEPT = 'ACCEPT'
export const MANUAL_REVIEW_STRING = 'MANUAL REVIEW'
export const REJECT = 'REJECT'
export const APPROVE = 'APPROVE'
export const CHANGE_REQUESTED = 'CHANGE_REQUESTED'
export const CLOSED = 'CLOSED'
export const PENDING = 'PENDING'
export const APPROVED = 'APPROVED'
export const IN_REVIEW = 'IN_REVIEW'
export const ADDITIONAL_VERIFICATION_REQUIRED = 'ADDITIONAL_VERIFICATION_REQUIRED'

export const MATCH = 'MATCH'
export const EXACT_MATCH = 'EXACT_MATCH'
export const NO_MATCH = 'NO_MATCH'
export const PHONETIC_MATCH = 'PHONETIC_MATCH'
export const UNKNOWN = 'UNKNOWN'
export const FAILED = 'FAILED'
export const PASSED = 'PASSED'

export const NO_REFUNDS = 'NO_REFUNDS'
export const MERCHANDISE_EXCHANGE_ONLY = 'MERCHANDISE_EXCHANGE_ONLY'
export const WITHIN_30_DAYS = 'WITHIN_30_DAYS'
export const OTHER = 'OTHER'

export const NO_REFUNDS_DISPLAY = 'No Refunds'
export const MERCHANDISE_EXCHANGE_ONLY_DISPLAY = 'Merchandise Exchange Only'
export const WITHIN_30_DAYS_DISPLAY = 'Within 30 Days'
export const OTHER_DISPLAY = 'Other'

export const VALUE_TO_DISPLAY_STRING_MAP = {
  [MANUAL_REVIEW]: MANUAL_REVIEW_STRING,
}

export const ACCESS_FORM_STATE_TO_DISPLAY_MAP = {
  [IN_PROGRESS]: 'In Progress',
  [APPROVED]: 'Approved',
  [REJECTED]: 'Rejected',
  [WITHDRAWN]: 'Withdrawn',
  [IN_REVIEW]: 'Under Review',
  [ADDITIONAL_VERIFICATION_REQUIRED]: 'Additional Verification Required',
  [SALES_REVIEW]: 'Sales Review',
}

export const ACCESS_FORM_STATUS_MAP = {
  ['In Progress']: IN_PROGRESS,
  ['Approved']: APPROVED,
  ['Rejected']: REJECTED,
  ['Under Review']: IN_REVIEW,
  ['Withdrawn']: WITHDRAWN,
  ['Additional Verification Required']: ADDITIONAL_VERIFICATION_REQUIRED,
  ['Sales Review']: SALES_REVIEW,
}

export const UNDERWRITING_STATE_TO_DISPLAY_MAP = {
  [MANUAL_REVIEW]: 'Manual Review',
  [ACCEPTED]: 'Accepted',
  [APPROVED]: 'Approved',
  [REJECTED]: 'Rejected',
  [WITHDRAWN]: 'Withdrawn',
  [ADDITIONAL_VERIFICATION_NEEDED]: 'Additional Verification Needed',
  [ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE]: 'Additional Verification Needed',
  [CHANGE_REQUESTED]: 'Additional Verification Needed',
  [CLOSED]: 'Withdrawn',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In Progress',
}

export const UNDERWRITING_SUGGESTED_ACTION_TO_DISPLAY_MAP = {
  [MANUAL_REVIEW]: 'Manual Review',
  [ACCEPTED]: 'Accept',
  [APPROVE]: 'Accept',
  [APPROVED]: 'Approve',
  [REJECTED]: 'Reject',
  [WITHDRAWN]: 'Withdraw',
  [ADDITIONAL_VERIFICATION_NEEDED]: 'Require Additional Information',
  [CHANGE_REQUESTED]: 'Require Additional Information',
  [CLOSED]: 'Withdraw',
  [PENDING]: 'Pending',
}

export const UNDERWRITING_REVIEW_ACTION_MAP = {
  'Approve': ACCEPTED,
  'Reject': REJECTED,
  'Withdraw': WITHDRAWN,
  'Additional Verification Needed': ADDITIONAL_VERIFICATION_NEEDED,
}

export const UNDERWRITING_STATUS_MAP = {
  'Accepted': SUCCESS,
  'Approved': SUCCESS,
  'Rejected': DANGER,
  'Manual Review' : WARNING,
  'Withdrawn': DEFAULT,
  'Additional Verification Needed': GOLD_WARNING,
  'Pending': IN_PROGRESS,
  'In Progress': IN_PROGRESS,
  'Change Requested': WARNING,
}

export const UNDERWRITING_ACTION_STATUS_MAP = reduce(UNDERWRITING_STATUS_MAP, (total, color, outcomeLabel) => {
  const outcome = get(invert(UNDERWRITING_STATE_TO_DISPLAY_MAP), outcomeLabel)
  const actionName = get(UNDERWRITING_SUGGESTED_ACTION_TO_DISPLAY_MAP, outcome)

  return merge({}, total, { [actionName]: color })
}, {})

export const UNDERWRITING_ICON_MAP = {
  [ACCEPTED]: CHECK_CIRCLE_ICON,
  [APPROVED]: CHECK_CIRCLE_ICON,
  [REJECTED]:  ALERT_CIRCLE_ICON,
  [MANUAL_REVIEW] : ALERT_CIRCLE_ICON,
  [WITHDRAWN]: ALERT_CIRCLE_ICON,
}

export const REFUND_POLICY_OPTIONS = [
  selectOption('Select one', ''),
  selectOption(NO_REFUNDS_DISPLAY, NO_REFUNDS),
  selectOption(MERCHANDISE_EXCHANGE_ONLY_DISPLAY, MERCHANDISE_EXCHANGE_ONLY),
  selectOption(WITHIN_30_DAYS_DISPLAY, WITHIN_30_DAYS),
  selectOption(OTHER_DISPLAY, OTHER)
]

export const REFUND_POLICY_DISPLAY_OPTIONS_MAP = {
  [NO_REFUNDS]: NO_REFUNDS_DISPLAY,
  [MERCHANDISE_EXCHANGE_ONLY]: MERCHANDISE_EXCHANGE_ONLY_DISPLAY,
  [WITHIN_30_DAYS]: WITHIN_30_DAYS_DISPLAY,
  [OTHER]: OTHER_DISPLAY,
}

export const UNDERWRITING_STATUS_OPTIONS = [
  selectOption(ALL_LABEL, ALL),
  selectOption('Approved', ACCEPTED),
  selectOption('Pending', PENDING),
  selectOption('Manual Review', MANUAL_REVIEW),
  selectOption('Rejected', REJECTED),
  selectOption('Withdrawn', WITHDRAWN),
]

export const RECOMMENDATION_ACTION_OPTIONS = [
  selectOption(ALL_LABEL, ALL),
  selectOption(MANUAL_REVIEW_LABEL, MANUAL_REVIEW),
  selectOption(APPROVED_LABEL, APPROVED),
]

export const summaryColumnHeaders = ['Section', 'Entity', 'Outcome']

export const nestedSummaryColumnHeaders  = ['Entity', 'Outcome', 'Messages', 'Provider/Report', 'Link to Report']

export const UNDERWRITING_ENABLED_FINIX_EMPLOYEES = [
  'richie@finixpayments.com',
  'sharath@finixpayments.com',
  'oscar@finixpayments.com',
  'nan.siler@finixpayments.com',
  'michael@finixpayments.com',
  'selina.guo@finixpayments.com',
  'mary.naanep@finixpayments.com',
  'jerry.lin@finixpayments.com',
  'justin@finixpayments.com',
  'justin+test@finixpayments.com',
  'prakhar.khandelwal@finixpayments.com',
  'josh.brown@finixpayments.com',
  'keahi.thompson@finixpayments.com',
  'james.fisher@finixpayments.com',
  'caiti.stobie@finixpayments.com',
  'justin+admin@finixpayments.com',
  'sid.masso@finix.com',
  'jeremy.kyte@finix.com',
]

// underwriting subject event types
export const SUBJECT_CREATED = 'SUBJECT_CREATED'
export const SUBJECT_UPDATED = 'SUBJECT_UPDATED'
export const REVIEW_CREATED = 'REVIEW_CREATED'
export const REVIEW_UPDATED = 'REVIEW_UPDATED'
export const RUN_CREATED = 'RUN_CREATED'

// underwriting GIACT report types
export const GIACT_SANCTIONS_REPORT_TYPE = 'SANCTIONS'
export const GIACT_BANK_ACCOUNT_REPORT_TYPE = 'BANK_ACCOUNT'
export const GIACT_BUSINESS_IDENTITY_REPORT_TYPE = 'BUSINESS_IDENTITY'
export const GIACT_PERSONAL_IDENTITY_REPORT_TYPE = 'PERSON_IDENTITY'
export const GIACT_EMAIL_REPORT_TYPE = 'EMAIL'

export const GIACT_REPORT_TYPES = [
  GIACT_SANCTIONS_REPORT_TYPE,
  GIACT_BANK_ACCOUNT_REPORT_TYPE,
  GIACT_BUSINESS_IDENTITY_REPORT_TYPE,
  GIACT_PERSONAL_IDENTITY_REPORT_TYPE,
  GIACT_EMAIL_REPORT_TYPE,
]

// underwriting Persona report types
export const PERSONA_BUSINESS_IDENTITY_REPORT_TYPE = 'BUSINESS_IDENTITY'
export const PERSONA_PERSONAL_IDENTITY_REPORT_TYPE = 'PERSON_IDENTITY'
export const PERSONA_ADVERSE_MEDIA_REPORT_TYPE = 'ADVERSE_MEDIA'

export const PERSONA_REPORT_TYPES = [
  // commenting out until our service supports these types
  // PERSONA_BUSINESS_IDENTITY_REPORT_TYPE,
  // PERSONA_PERSONAL_IDENTITY_REPORT_TYPE,
  PERSONA_ADVERSE_MEDIA_REPORT_TYPE,
]

// Automated underwriting workflow IDs per processor
export const LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID = 'uw_workflow_7YyeAmjHnMTTkHMSEcNXxR'
export const FINIX_V1_USA_PROCESSOR_WORKFLOW_ID = 'uw_workflow_ouyxvKuxqPURboiprtk2pu'
export const FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID = 'uw_workflow_38bCPiv2XMUps8XY2UMJa2'

export const FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_QA = 'uw_workflow_fzKc3qNBXjSW9A8YoiEoJY'
export const FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_SANDBOX = 'uw_workflow_rfEFvcrxS16T4nwgkrxJ98'
export const FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_PROD = 'uw_workflow_nrPjQNpAdUyLkm7YzcawxZ'

export const recipientWorkflowByEnvironmentMap = {
  [QA_ENVIRONMENT]: FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_QA,
  [SANDBOX_ENVIRONMENT]: FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_SANDBOX,
  [LIVE_ENVIRONMENT]: FINIX_V1_RECIPIENT_PROCESSOR_WORKFLOW_ID_PROD,
}

export const merchantUnderwritingWorkflowFormOptionToIdMap = {
  ['litleVantivUSWorkflow']: LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID,
  ['finixUSWorkflow']: FINIX_V1_USA_PROCESSOR_WORKFLOW_ID,
  ['finixCANWorkflow']: FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID,
}
