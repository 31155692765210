import './AssociatedIdentityNewS.scss'
import React from 'react'
import IdentityPrincipalsFormC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsFormC'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'

const AssociatedIdentityNew = (props) => {
  return (
    <div className='AssociatedIdentityNew'>
      <HeaderC
        breadcrumbs
      />

      <div className='content-card'>
        <IdentityPrincipalsFormC
          singlePrincipal
          {...props}
        />
      </div>
    </div>
  )
}

export default AssociatedIdentityNew
