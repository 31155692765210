import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantDeposits from './MerchantDeposits'
import get from 'lodash/get'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { MERCHANT_DEPOSITS_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import concat from 'lodash/concat'

import {
  DEPOSITS,
  MERCHANT_DEPOSITS_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  SUCCEEDED,
} from 'constants/transferConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transaction'),
  ...TAG_FILTERS,
], 'label')

// hide all payment card fields as Merchant Deposits are all using bank accounts
const hiddenExportFields = [
  'cardType',
  'cardBrand',
  'cardBin',
  'cardLastFour',
  'cardExpirationMonth',
  'cardExpirationYear',
  'cardAddressLineOne',
  'cardAddressLineTwo',
  'cardAddressCity',
  'cardAddressPostalCode',
  'cardAddressCountry',
  'cardAddressVerification',
  'cardSecurityCodeVerification',
  'readyToSettleAt',
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  custom_transfer_type: 'settlement_transfers',
}

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const customPath = get(props, 'customPath')
  const merchantDepositsPath = customPath ? customPath({ credentialId }) : MERCHANT_DEPOSITS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const isSucceededQuickFilter = getUrlQuery('state') === SUCCEEDED
  const hiddenSucceededFields = isSucceededQuickFilter ? ['failureCode'] : []
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const title = isStandaloneMerchant ? DEPOSITS : MERCHANT_DEPOSITS_RESOURCE_TITLE
  const showHeader = get(props, 'showHeader', true)

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    hiddenExportFields: concat(hiddenExportFields, hiddenSucceededFields),
    merchantDepositsPath,
    title,
    showHeader,
  }
}

class MerchantDepositsC extends Component {
  render() {
    return (
      <MerchantDeposits {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(MerchantDepositsC)
