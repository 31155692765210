import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import RiskProfileFormC from 'components/Customer/Forms/RiskProfileForm/RiskProfileFormC'

const RiskProfileModal = ({
  applicationId,
  merchantId,
  riskProfile,
  credentials,
}) => {
  return (
    <GenericModal
      title='Update Risk Profile'
      className='RiskProfileModal'
      Component={RiskProfileFormC}
      applicationId={applicationId}
      merchantId={merchantId}
      riskProfile={riskProfile}
      credentials={credentials}
    />
  )
}

RiskProfileModal.propTypes = {
  applicationId: PropTypes.string,
  riskProfile: PropTypes.object,
  credentials: PropTypes.object,
}

export default RiskProfileModal
