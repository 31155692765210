import formatMoney from 'utilities/formatters/formatMoney'
import get from 'lodash/get'
import map from 'lodash/map'
import { MERCHANT_CATEGORY_CODE } from 'constants/language/languageConstants'

const IdentityProcessingFields = [
  {
    name: 'previouslyProcessedCreditCards',
    label: 'Processed cards before?',
    formatter: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    name: 'annualCardVolume',
    label: 'Annual Card Volume',
    highlight: true,
    formatter: (value) => formatMoney({ amount: value }),
  },
  {
    name: 'defaultStatementDescriptor',
    label: 'Default Statement Descriptor',
  },
  {
    name: 'maxTransactionAmount',
    label: 'Max Card Transaction Amount',
    highlight: true,
    formatter: (value) => formatMoney({ amount: value }),
  },
  {
    name: 'achMaxTransactionAmount',
    label: 'Max ACH Transaction Amount',
    highlight: true,
    formatter: (value) => formatMoney({ amount: value }),
  },
  {
    name: 'amexMid',
    label: 'American Express MID',
  },
  {
    name: 'discoverMid',
    label: 'Discover MID',
  },
  {
    name: 'mcc',
    label: MERCHANT_CATEGORY_CODE,
    formatter: ({ value }) => value,
    highlight: true,
  },
  {
    name: 'taxAuthority',
    label: 'Tax Authority',
  },
]

const IdentityProcessingDisplayData = (values) => map(IdentityProcessingFields, ({ name, label, formatter, highlight = false }) => ({
  label,
  value: formatter ? formatter(get(values, name, {})) : get(values, name),
  highlight,
}))

export default IdentityProcessingDisplayData
