import React from 'react'
import { highTicketColumnDescriptors } from '../columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import getMany from 'utilities/get/getMany'
import { infoTableTheme } from 'constants/themeConstants'
import get from 'lodash/get'

const HighTicketTable = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displayTransferCreatedAtDay,
    displayTransferCreatedAtDayPrior,
    displayTransferAmountDollars,
    displayTransferAmountDollarsPrior,
    displayTransferAmountDifference,
    transferId,
    transferIdPrior,
  ] = getMany(calculations, [
    'displayTransferCreatedAtDay',
    'displayTransferCreatedAtDayPrior',
    'displayTransferAmountDollars',
    'displayTransferAmountDollarsPrior',
    'displayTransferAmountDifference',
    'transferId',
    'transferIdPrior',
  ])

  const transferRows = [
    {
      id: transferId,
      createdAt: displayTransferCreatedAtDay,
      sum: displayTransferAmountDollars,
      percent: displayTransferAmountDifference,
    },
    {
      id: transferIdPrior,
      createdAt: displayTransferCreatedAtDayPrior,
      sum: displayTransferAmountDollarsPrior,
      percent: null,
    },
  ]

  return (
    <TableC
      classNames='alert-insights-table'
      showTableFooter={false}
      theme={infoTableTheme}
      columnDescriptors={highTicketColumnDescriptors}
      data={transferRows}
    />
  )
}

export default HighTicketTable
