import './UploadPaymentProcessingCertFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import submitUploadPaymentProcessingCertForm from 'utilities/submit/submitUploadPaymentProcessingCertForm'
import { FILE_CERT_ICON } from 'constants/iconConstants'
import { UPLOAD_PAYMENT_PROCESSING_CERT_FORM } from 'constants/formConstants'

import {
  PREVIOUS,
  UPLOAD,
} from 'constants/language/languageConstants'

const UploadPaymentProcessingCertForm = ({
  handleSubmit = () => {},
  config = {},
  instructions = [],
  uploadedFile,
  leftButtonAction = () => {},
}) => {
  // TODO: find a way to check if uploaded file is also of correct format, right now Upload only disabled when no file is uploaded
  const invalid = (uploadedFile === undefined)

  return (
    <form className='UploadPaymentProcessingCertForm' onSubmit={handleSubmit(submitUploadPaymentProcessingCertForm)}>
      <div className='primary-msg'>Upload Payment Processing Certificate</div>

      <Field
        name='file'
        config={config}
        instructions={instructions}
        component={FileUploader}
        fileIcon={`fa fa-${FILE_CERT_ICON}`}
        className='file-cert-uploader'
      />

      <div className='buttons flex items-center flex-end space-between'>
        <Button onClick={leftButtonAction} label={PREVIOUS} variant='tertiary' />
        <Button type='submit' disabled={invalid} label={UPLOAD} />
      </div>
    </form>
  )
}

UploadPaymentProcessingCertForm.propTypes = {
  handleSubmit: PropTypes.func,
  config: PropTypes.object,
  instructions: PropTypes.array,
  uploadedFile: PropTypes.object,
  leftButtonAction: PropTypes.func,
}

export default reduxForm({
  form: UPLOAD_PAYMENT_PROCESSING_CERT_FORM,
})(UploadPaymentProcessingCertForm)
