import React, { Component } from 'react'
import { connect } from 'react-redux'
import Applications from './Applications'
import columnDescriptors from './columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATIONS_PATH, NEW_APPLICATION_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const allowedFilters = [
  convertFilterToResourceIdFilter('Application'),
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `applicationsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const applicationsPath = APPLICATIONS_PATH({ credentialId })
  const isPayout = isPayoutFeature({ credentials })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)

  const actions = [
    {
      label: 'Create Application',
      link: NEW_APPLICATION_PATH({ credentialId }),
      className: 'createApplication',
    },
  ]

  return {
    allowedFilters,
    initialQueries,
    isFetching,
    applicationsPath,
    actions,
    isPayout,
    columnDescriptors: columnDescriptors({ isStandaloneUnderwriting }),
  }
}

class ApplicationsC extends Component {
  render() {
    return (
      <Applications {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationsC)
