import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditSubscriptionForm from './EditSubscriptionForm'
import getSubscriptionRequest from 'utilities/actions/get/getSubscriptionRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityPaymentInstrumentsRequest from 'utilities/actions/get/getIdentityPaymentInstrumentsRequest'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import getMany from 'utilities/get/getMany'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import getSubscriptionPlanRequest from 'utilities/actions/get/getSubscriptionPlanRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { CREATE_PAYMENT_INSTRUMENT_MODAL } from 'constants/modalConstants'
import { EDIT_SUBSCRIPTION_FORM } from 'constants/formConstants'
import { SHOW_ACH_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { FETCHING } from 'constants/reducerConstants'
import { getCardBrand } from 'constants/bankConstants'
import map from 'lodash/map'
import concat from 'lodash/concat'
import get from 'lodash/get'

import {
  ADD_NEW_PAYMENT_INSTRUMENT,
  BILLING_FREQUENCY,
  BILLING_SETTING,
  FIRST_PAYMENT,
  RECURRING_PRICE,
  SUBSCRIPTION_STARTS,
} from 'constants/language/languageConstants'

import {
  change,
  formValueSelector,
} from 'redux-form'

import {
  ADD_ICON,
  BANK_ICON,
} from 'constants/iconConstants'

import {
  getIdentitySelector,
  getPaymentInstrumentSelector,
  getPaymentInstrumentsSelector,
  getSubscriptionPlanSelector,
  getSubscriptionSelector,
} from 'state-layer/selectors'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
  option: {},
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionsR.${FETCHING}`, true)
  const isFetchingPaymentInstrumentsAndIdentities = isFetching || get(state, `paymentInstrumentsR.${FETCHING}`, true) || get(state, `identitiesR.${FETCHING}`, true)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const environment = get(credentials, 'environment')
  const subscriptionId = get(props, 'params.subscriptionId')
  const subscription = getSubscriptionSelector(state, subscriptionId)
  const canUseACHPayment = getFeatureFlag(SHOW_ACH_VIRTUAL_TERMINAL)
  const formSelector = formValueSelector(EDIT_SUBSCRIPTION_FORM)
  const selectedPaymentInstrumentId = formSelector(state, 'buyerInstrumentId.value')

  const [
    nickname,
    billingInterval,
    displayBillingInterval,
    amount,
    collectionMethod,
    displayCollectionMethod,
    buyerIdentityId,
    buyerInstrumentId,
    subscriptionPlanId,
    displayFirstChargeAt,
    displayCreatedAt,
  ] = getMany(subscription, [
    'nickname',
    'billingInterval',
    'displayBillingInterval',
    'amount',
    'collectionMethod',
    'displayCollectionMethod',
    'buyerIdentityId',
    'buyerInstrumentId',
    'subscriptionPlanId',
    'displayFirstChargeAt',
    'displayCreatedAt',
  ])

  const buyerIdentity = getIdentitySelector(state, buyerIdentityId)
  const buyerFullName = get(buyerIdentity, 'fullName')
  const identityPaymentInstruments = getPaymentInstrumentsSelector(state)
  const preSelectedIdentityInstrument = getPaymentInstrumentSelector(state, buyerInstrumentId)
  const subscriptionPlan = getSubscriptionPlanSelector(state, subscriptionPlanId)

  const [
    planDisplayBillingInterval,
    planAmount,
    planDisplayCollectionMethod,
  ] = getMany(subscriptionPlan, [
    'displayBillingInterval',
    'amount',
    'displayCollectionMethod',
  ])

  const planDetailsSectionData = convertPageSectionDataToV2([
    {
      label: BILLING_FREQUENCY,
      value: planDisplayBillingInterval,
    },
    {
      label: RECURRING_PRICE,
      value: planAmount,
    },
    {
      label: BILLING_SETTING,
      value: planDisplayCollectionMethod,
    },
    {
      label: SUBSCRIPTION_STARTS,
      value: displayCreatedAt,
    },
    {
      label: FIRST_PAYMENT,
      value: displayFirstChargeAt,
    },
  ], 1)

  const paymentInstrumentOption = (paymentInstrument) => {
    const [
      id,
      brand,
      expirationDate,
      maskedFullCardNumber,
      maskedAccountNumber,
      type,
    ] = getMany(paymentInstrument, [
      'id',
      'brand',
      'expirationDate',
      'maskedFullCardNumber',
      'maskedAccountNumber',
      'type',
    ])

    const cardIcon = getCardBrand(brand)
    const bankAccountOptionLabel = <div className='flex items-center'><i className={`bank-icon fa fa-${BANK_ICON}`} />{maskedAccountNumber}</div>
    const cardOptionLabel = <div className='flex items-center'><i className={`card-brand-icon ${cardIcon}`} />{maskedFullCardNumber}<span className='card-expiration-date secondary'>({expirationDate})</span></div>

    return {
      label: type === 'Bank Account' ? bankAccountOptionLabel : cardOptionLabel,
      value: id,
    }
  }

  const paymentInstrumentOptions = map(identityPaymentInstruments, paymentInstrumentOption)
  const preSelectedInstrumentOption = paymentInstrumentOption(preSelectedIdentityInstrument)

  const identityPaymentInstrumentOptions = concat(
    {
      label: (
        <div className='add-new-payment-instrument flex items-center'>
          <span className={`add-icon fa fa-${ADD_ICON}`} />
          {ADD_NEW_PAYMENT_INSTRUMENT}
        </div>),
      value: 'newPaymentInstrument',
    }, paymentInstrumentOptions,
  )

  const initialValues = !isFetchingPaymentInstrumentsAndIdentities ? {
    nickname,
    billingInterval: { value: billingInterval, label: displayBillingInterval },
    amount,
    collectionMethod: { value: collectionMethod, label: displayCollectionMethod },
    buyerIdentityId: { value: buyerIdentityId, label: buyerFullName },
    buyerInstrumentId: preSelectedInstrumentOption,
  } : undefined

  return {
    credentials,
    subscriptionId,
    initialValues,
    buyerIdentityId,
    buyerInstrumentId,
    identityPaymentInstrumentOptions,
    reactSelectCustomStyles,
    isFetching,
    environment,
    applicationId,
    canUseACHPayment,
    selectedPaymentInstrumentId,
    subscriptionPlanId,
    hasSubscriptionPlan: subscriptionPlanId,
    planDetailsSectionData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscription: ({ credentials, subscriptionId }) => dispatch(getSubscriptionRequest({ credentials, subscriptionId })),
    getIdentityPaymentInstruments: ({ identityId, credentials }) => dispatch(getIdentityPaymentInstrumentsRequest({ identityId, credentials })),
    showCreatePaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_MODAL, className: 'modal-md no-pad', modalProps })),
    updateEditSubscriptionFormField: ({ value, field }) => dispatch(change(EDIT_SUBSCRIPTION_FORM, field, value)),
    clearReducer: (reducerNames) => dispatch(clearReducerRequest(reducerNames)),
    getSubscriptionPlan: ({ credentials, subscriptionPlanId }) => { dispatch(getSubscriptionPlanRequest({ credentials, subscriptionPlanId })) },
  }
}

class EditSubscriptionFormC extends Component {
  componentDidMount() {
    const {
      getSubscription,
      credentials,
      subscriptionId,
    } = this.props

    getSubscription({ credentials, subscriptionId })
  }

  componentDidUpdate(prevProps) {
    const {
      getSubscription,
      credentials,
      subscriptionId,
      subscriptionPlanId,
      getIdentityPaymentInstruments,
      getSubscriptionPlan,
      buyerIdentityId,
      clearReducer,
      showCreatePaymentInstrumentModal,
      updateEditSubscriptionFormField,
      applicationId,
      environment,
      canUseACHPayment,
      selectedPaymentInstrumentId,
    } = this.props

    const {
      subscriptionId: prevSubscriptionId,
      selectedPaymentInstrumentId: prevSelectedPaymentInstrumentId,
      subscriptionPlanId: prevSubscriptionPlanId,
    } = prevProps

    const showNewPaymentMethodModal = prevSelectedPaymentInstrumentId !== selectedPaymentInstrumentId && selectedPaymentInstrumentId === 'newPaymentInstrument'

    if (subscriptionId && subscriptionId !== prevSubscriptionId) {
      getSubscription({ credentials, subscriptionId })
    }

    if (buyerIdentityId && buyerIdentityId !== prevProps.buyerIdentityId) {
      clearReducer(['paymentInstrumentsR'])
      getIdentityPaymentInstruments({ identityId: buyerIdentityId, credentials })
    }

    if (subscriptionPlanId && subscriptionPlanId !== prevSubscriptionPlanId) {
      getSubscriptionPlan({ credentials, subscriptionPlanId })
    }

    if (showNewPaymentMethodModal) {
      updateEditSubscriptionFormField({ field: 'buyerInstrumentId', value: null })
      showCreatePaymentInstrumentModal({
        modalProps: credentials,
        applicationId,
        environment,
        showTokenForm: canUseACHPayment,
        formName: EDIT_SUBSCRIPTION_FORM,
        fieldName: 'buyerInstrumentId',
        buyerIdentityId,
      })
    }
  }

  render() {
    return (
      <EditSubscriptionForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscriptionFormC)
