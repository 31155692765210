import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  PLAN_DETAILS,
  OPTIONAL_ADD_ONS,
  SUBSCRIPTION_PLANS_NO_ADD_ONS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const SubscriptionPlan = ({
  isFetching = false,
  planDetails = [],
  optionalAddOnsDetails = [],
}) => {
  return (
    <div className='SubscriptionPlan'>
      <PageSectionV2C
        title={PLAN_DETAILS}
        className='subscription-plan-details-section'
        columns={planDetails}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={OPTIONAL_ADD_ONS}
        columns={optionalAddOnsDetails}
        emptyMessage={SUBSCRIPTION_PLANS_NO_ADD_ONS_EMPTY_MESSAGE}
        isFetching={isFetching}
      />
    </div>
  )
}

SubscriptionPlan.propTypes = {
  isFetching: PropTypes.bool,
  planDetails: PropTypes.array,
  optionalAddOnsDetails: PropTypes.array,
}

export default SubscriptionPlan
