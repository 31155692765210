import postUsersAPI from 'api/finix/post/postUsersAPI'
import postApplicationAPI from 'api/finix/post/postApplicationAPI'
import postApplicationAssociatedIdentityAPI from 'api/finix/post/postApplicationAssociatedIdentityAPI'
import postApplicationProcessorAPI from 'api/finix/post/postApplicationProcessorAPI'
import postPaymentInstrumentAPI from 'api/finix/post/postPaymentInstrumentAPI'
import { BANK_ACCOUNT } from 'constants/bankConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import getMany from 'utilities/get/getMany'
import merge from 'lodash/merge'
import map from 'lodash/map'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import co from 'co'

import {
  createAPIUser,
  createApplication,
  createBankAccount,
  createAssociatedIdentity,
  updateApplicationProcessor,
} from 'constants/orchestrationConstants'

/* ============ Post Application Flow ==========
1) potUsersAPI => user
2) postApplicationAPI [1. user] => applicatonId, identityId
3) postPaymentInstrumentAPI [2. identityId]
4) postApplicatioAssociatedAPI [2. identityId]
5) postApplicationProcessorAPI [2. applicatioId]
*/

function * postApplicationO ({
  values,
  credentials,
}) {
  const defaultUsersValue = {
    role: 'ROLE_PARTNER',
    tags: {
      name: 'Default user',
    },
  }

  const {
    entity,
    bankAccountInfo,
    associatedIdentityPayload,
    processors,
  } = values

  const userResponse = yield postUsersAPI({ values: defaultUsersValue, credentials })

  const [
    userResponseData,
    userResponseErrors = [],
  ] = getMany(userResponse, [
    'data',
    'error.response.data._embedded.errors',
  ])

  const user = get(userResponseData, 'id')

  const applicationResponse = user ? yield postApplicationAPI({
    values: { entity, user },
    credentials,
  }) : {}

  const [
    applicationResponseData,
    applicationResponseErrors = [],
  ] = getMany(applicationResponse, [
    'data',
    'error.response.data._embedded.errors',
  ])

  const [
    applicationId,
    identityId,
  ] = getMany(applicationResponseData, [
    'id',
    'owner',
  ])

  const bankAccountPayload = merge({}, bankAccountInfo, {
    identity: identityId,
    type: BANK_ACCOUNT,
  })

  const bankAccountResponse = !isEmpty(bankAccountInfo) && identityId ? yield postPaymentInstrumentAPI({
    values: bankAccountPayload,
    credentials,
  }) : {}

  const [
    bankAccountResponseData,
    bankAccountResponseErrors = [],
  ] = getMany(bankAccountResponse, [
    'data',
    'error.response.data._embedded.errors',
  ])

  const associatedIdentityResponse = !isEmpty(associatedIdentityPayload) && identityId ? yield postApplicationAssociatedIdentityAPI({
    values: associatedIdentityPayload,
    credentials,
    id: identityId,
  }) : {}

  const [
    associatedIdentityResponseData,
    associatedIdentityResponseErrors = [],
  ] = getMany(associatedIdentityResponse, [
    'data',
    'error.response.data._embedded.errors',
  ])

  const processorsArr = map(processors, (processor) => processor)
  const processorsResponse = []

  if (applicationId) {
    for (let i = 0; i < processorsArr.length; i += 1) {
      const response = yield postApplicationProcessorAPI({
        id: applicationId,
        credentials,
        values: {
          application: applicationId,
          processor: processorsArr[i],
          type: processorsArr[i],
        },
      })

      processorsResponse.push(response)
    }
  }

  const processorsResponseData = []
  const processorsResponseErrors = []

  processorsResponse.forEach(({ data, error }) => {
    if (data) {
      processorsResponseData.push(data)
    } else {
      processorsResponseErrors.push(error)
    }
  })

  const application = merge({}, applicationResponseData, {
    payment_instrument: bankAccountResponseData,
    associated_identities: associatedIdentityResponseData,
    processors: processorsResponseData,
  })

  const normalizedApplication = normalizeResponse({ content: application })

  const errors = [].concat(userResponseErrors, applicationResponseErrors, bankAccountResponseErrors, associatedIdentityResponseErrors, processorsResponseErrors)

  const orchestrationStatus = {
    [createAPIUser]: isEmpty(userResponseErrors),
    [createApplication]: isEmpty(applicationResponseErrors),
    [createBankAccount]: isEmpty(bankAccountResponseErrors),
    [createAssociatedIdentity]: isEmpty(associatedIdentityResponseErrors),
    [updateApplicationProcessor]: isEmpty(processorsResponseErrors),
  }

  return createSuccessResponse({
    data: {
      applications: [normalizedApplication],
      orchestrationStatus,
    },
  })
}

export default co.wrap(postApplicationO)
