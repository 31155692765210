import './SetupMFAModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

import {
  t,
  SMS,
  MOBILE_AUTHENTICATOR,
  SETUP_MULTI_FACTOR_AUTHENTICATION,
  USE_AN_AUTHENTICATOR_APP,
  RECEIVE_A_CODE_VIA_TEXT_MESSAGE,
  TO_SETUP_MFA_DESCRIPTION,
} from 'constants/language/languageConstants'

import {
  ANGLE_RIGHT_ICON,
  MOBILE_ICON,
  SMS_ICON,
} from 'constants/iconConstants'

const SetupMFAModal = ({
  description = TO_SETUP_MFA_DESCRIPTION,
  showConfirmPasswordModalSMS = () => {},
  showConfirmPasswordModalAuthenticator = () => {},
}) => {
  return (
    <GenericModal
      title={SETUP_MULTI_FACTOR_AUTHENTICATION}
      className='SetupMFAModal'
    >
      <div className='modal-content'>
        <p className='p-1'>{t(description)}</p>

        <div className='mfa-option sms flex gap-xl' onClick={showConfirmPasswordModalSMS}>
          <i className={`icon far fa-${SMS_ICON}`} />
          <div className='flex column flex-grow'>
            <h6>{SMS}</h6>
            <p className='p-1 secondary'>{t(RECEIVE_A_CODE_VIA_TEXT_MESSAGE)}</p>
          </div>
          <i className={`far fa-${ANGLE_RIGHT_ICON}`} />
        </div>

        <div className='mfa-option authenticator flex gap-xl' onClick={showConfirmPasswordModalAuthenticator}>
          <i className={`icon far fa-${MOBILE_ICON}`} />
          <div className='flex column flex-grow'>
            <h6>{MOBILE_AUTHENTICATOR}</h6>
            <p className='p-1 secondary'>{t(USE_AN_AUTHENTICATOR_APP)}</p>
          </div>
          <i className={`far fa-${ANGLE_RIGHT_ICON}`} />
        </div>
      </div>
    </GenericModal>
  )
}

SetupMFAModal.propTypes = {
  description: PropTypes.string,
  showConfirmPasswordModalSMS: PropTypes.func,
  showConfirmPasswordModalAuthenticator: PropTypes.func,
}

export default SetupMFAModal
