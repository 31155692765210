import { CAD, USD } from './currencyConstants'

// this is the list of currencies that we want to collect for fee profiles and fee profile rules.
// if we ever add more currencies we should update this list.
// TODO: add CAD back as part of FE-4225
export const CURRENCIES_TO_COLLECT = [USD]

export const BLENDED_FEE_PROFILE_RULES_FIELDS = [
    'basisPointsMin',
    'basisPointsMax',
    'fixedFeeMin',
    'fixedFeeMax',
    'cardCrossBorderBasisPointsMin',
    'cardCrossBorderBasisPointsMax',
    'cardCrossBorderFixedFeeMin',
    'cardCrossBorderFixedFeeMax',
    'allowCardBrandOverrides',
    'visaBasisPointsMin',
    'visaBasisPointsMax',
    'visaFixedFeeMin',
    'visaFixedFeeMax',
    'mastercardBasisPointsMin',
    'mastercardBasisPointsMax',
    'mastercardFixedFeeMin',
    'mastercardFixedFeeMax',
    'americanExpressBasisPointsMin',
    'americanExpressBasisPointsMax',
    'americanExpressFixedFeeMin',
    'americanExpressFixedFeeMax',
    'discoverBasisPointsMin',
    'discoverBasisPointsMax',
    'discoverFixedFeeMin',
    'discoverFixedFeeMax',
    'jcbBasisPointsMin',
    'jcbBasisPointsMax',
    'jcbFixedFeeMin',
    'jcbFixedFeeMax',
    'dinersClubBasisPointsMin',
    'dinersClubBasisPointsMax',
    'dinersClubFixedFeeMin',
    'dinersClubFixedFeeMax',
    'allowChargebackFees',
    'disputeFixedFeeMin',
    'disputeFixedFeeMax',
    'disputeInquiryFixedFeeMin',
    'disputeInquiryFixedFeeMax',
]

export const INTERCHANGE_FEE_PROFILE_RULES_FIELDS = [
    ...BLENDED_FEE_PROFILE_RULES_FIELDS,
    'visaAssessmentsBasisPointsMin',
    'visaAssessmentsBasisPointsMax',
    'visaAcquirerProcessingFixedFeeMin',
    'visaAcquirerProcessingFixedFeeMax',
    'visaCreditVoucherFixedFeeMin',
    'visaCreditVoucherFixedFeeMax',
    'visaBaseIISystemFileTransmissionFixedFeeMin',
    'visaBaseIISystemFileTransmissionFixedFeeMax',
    'visaKilobyteAccessFixedFeeMin',
    'visaKilobyteAccessFixedFeeMax',
    'mastercardAssessmentsUnder1kBasisPointsMin',
    'mastercardAssessmentsUnder1kBasisPointsMax',
    'mastercardAssessmentsOver1kBasisPointsMin',
    'mastercardAssessmentsOver1kBasisPointsMax',
    'mastercardAcquirerFeesBasisPointsMin',
    'mastercardAcquirerFeesBasisPointsMax',
    'americanExpressAssessmentBasisPointsMin',
    'americanExpressAssessmentBasisPointsMax',
    'discoverAssessmentsBasisPointsMin',
    'discoverAssessmentsBasisPointsMax',
    'discoverDataUsageFixedFeeMin',
    'discoverDataUsageFixedFeeMax',
    'discoverNetworkAuthorizationFixedFeeMin',
    'discoverNetworkAuthorizationFixedFeeMax',
]

export const ACH_FEE_PROFILE_RULES_FIELDS = [
    'achBasisPointsMin',
    'achBasisPointsMax',
    'achFixedFeeMin',
    'achFixedFeeMax',
    'achDebitReturnFixedFeeMin',
    'achDebitReturnFixedFeeMax',
    'achCreditReturnFixedFeeMin',
    'achCreditReturnFixedFeeMax',
    'ancillaryFixedFeePrimaryMin',
    'ancillaryFixedFeePrimaryMax',
    'ancillaryFixedFeeSecondaryMin',
    'ancillaryFixedFeeSecondaryMax',
]

export const CHARGEBACK_FEE_PROFILE_RULES_FIELDS = [
    'disputeFixedFeeMin',
    'disputeFixedFeeMax',
    'disputeInquiryFixedFeeMin',
    'disputeInquiryFixedFeeMax',
]

export const CARD_BRAND_FEE_PROFILE_RULES_FIELDS = [
    'visaBasisPointsMin',
    'visaBasisPointsMax',
    'visaFixedFeeMin',
    'visaFixedFeeMax',
    'mastercardBasisPointsMin',
    'mastercardBasisPointsMax',
    'mastercardFixedFeeMin',
    'mastercardFixedFeeMax',
    'americanExpressBasisPointsMin',
    'americanExpressBasisPointsMax',
    'americanExpressFixedFeeMin',
    'americanExpressFixedFeeMax',
    'discoverBasisPointsMin',
    'discoverBasisPointsMax',
    'discoverFixedFeeMin',
    'discoverFixedFeeMax',
    'jcbBasisPointsMin',
    'jcbBasisPointsMax',
    'jcbFixedFeeMin',
    'jcbFixedFeeMax',
    'dinersClubBasisPointsMin',
    'dinersClubBasisPointsMax',
    'dinersClubFixedFeeMin',
    'dinersClubFixedFeeMax',
]
