import AddNote from './AddNote'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import postNoteRequest from 'utilities/actions/post/postNoteRequest'
import patchNoteRequest from 'utilities/actions/patch/patchNoteRequest'
import getDraftNotesRequest from 'utilities/actions/get/getDraftNotesRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import mergeConcatArrays from 'utilities/mergeConcatArrays'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'
import { getDraftNotesForResource } from 'state-layer/selectors/index'
import { SUBMIT_NOTE_MODAL } from 'constants/modalConstants'
import { DRAFT } from 'constants/noteConstants'
import get from 'lodash/get'
import find from 'lodash/find'
import trim from 'lodash/trim'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, props) => {
  const { linkedTo } = props
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser()
  const draftNote = getDraftNotesForResource(state, linkedTo)
  const draftContent = get(draftNote, 'jsonContent')
  const updatedAt = get(draftNote, 'displayUpdatedAt')
  const reviewId = get(props, 'linkedTo')

  const [
    email,
    firstName,
    lastName,
  ] = getMany(currentUser, [
    'email',
    'firstName',
    'lastName',
  ])

  return {
    credentials,
    credentialId,
    currentUser,
    email,
    firstName,
    lastName,
    isPatchingNote: isPatching(state),
    draftNote,
    draftContent,
    updatedAt,
    reviewId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchShowSubmitNoteModal: (modalProps) => dispatch(showModalAction({ modalType: SUBMIT_NOTE_MODAL, modalProps })),
    getDraftNotes: (props) => dispatch(getDraftNotesRequest(props)),
    postNote: (props) => dispatch(postNoteRequest(props)),
    patchNote: (props) => dispatch(patchNoteRequest(props)),
  }
}

class AddNoteC extends Component {
  state = {
    debounceTimeout: null,
    quillRef: React.createRef(),
    submittingDraft: false,
    isEditorEmpty: false,
  }

  componentDidMount() {
    const { draftContent } = this.props
    const {
      getDraftNotes,
      credentials,
      linkedTo,
    } = this.props

    if (draftContent) {
      this.setQuillContent(draftContent)
    }
    getDraftNotes({
      credentials,
      queries: {
        linked_to: linkedTo,
      },
    })
  }

  // everytime they type, clear old interval and set new interval
  textUpdated = (text) => {
    const { isPatchingNote } = this.props
    const { debounceTimeout } = this.state

    // text is ('<p><br></p>' when we reset the quill content post submit we do not want to create a new draft with nothing in it)
    if (isPatchingNote || text === '<p><br></p>') {
      return
    }

    this.setState({ submittingDraft: true })

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newDebounceTimeout = setTimeout(() => {
      this.setState({ debounceTimeout: null })
      this.saveDraft(text)
    }, 1000)

    this.setState({ debounceTimeout: newDebounceTimeout })
  }

  saveDraft = () => {
    const {
      credentials,
      draftNote,
      linkedTo,
      email,
      firstName,
      lastName,
      postNote,
      patchNote,
    } = this.props

    const jsonContent = this.getEditorContentAsJSON()

    if (draftNote) {
      patchNote({
        id: get(draftNote, 'id'),
        jsonContent,
        credentials,
        successCallback: () => this.setState({ submittingDraft: false }),
      })
    } else {
      postNote({
        linkedTo,
        jsonContent,
        firstName,
        lastName,
        email,
        status: DRAFT,
        credentials,
        successCallback: () => this.setState({ submittingDraft: false }),
      })
    }
  }

  getEditorContentAsJSON = () => {
    const { quillRef } = this.state
    if (!quillRef.current) return {}
    const editor = get(quillRef, 'current.editor')
    return editor ? editor.getContents() : undefined
  }

  isEditorEmpty = () => {
    const { quillRef, isEditorEmpty } = this.state
    const editor = get(quillRef, 'current.editor')
    const textContent = (editor && trim(editor.getText())) || ''
    const isEmptyText = isEmpty(textContent)
    if (isEmptyText !== isEditorEmpty) {
      this.setState({ isEditorEmpty: isEmptyText })
    }
    return isEmptyText
  }

  showConfirmSubmitNoteModal = () => {
    const {
      dispatchShowSubmitNoteModal,
      credentials,
      linkedTo,
      email,
      firstName,
      lastName,
      draftNote,
    } = this.props

    dispatchShowSubmitNoteModal({
      credentials,
      linkedTo,
      email,
      firstName,
      lastName,
      jsonContent: this.getEditorContentAsJSON(),
      noteId: get(draftNote, 'id'),
      successCallback: this.submitNoteSuccessCallback,
    })
  }

  changeTemplate = (templateName) => {
    const { quillRef } = this.state
    const { templates } = this.props

    const template = find(templates, ({ name }) => templateName === name)
    const templateData = get(template, 'data')
    const current = get(quillRef, 'current')

    if (templateData && current) {
      // clear the editor on change of template
      quillRef.current.editor.setContents([])
      const jsonContent = this.getEditorContentAsJSON()
      const nextContent = mergeConcatArrays(jsonContent, templateData)

      this.setQuillContent(nextContent)
    }
  }

  submitNoteSuccessCallback = () => {
    this.setQuillContent('')
  }

  setQuillContent = (content) => {
    const { quillRef } = this.state
    const editor = get(quillRef, 'current.editor')

    if (editor) {
      editor.setContents(content)
    }
  }

  render() {
    const {
      quillRef,
      isPatchingNote,
    } = this.state

    const {
      submittingDraft,
    } = this.state

    const isSaving = isPatchingNote || submittingDraft

    return (
      <AddNote
        {...this.props}
        quillRef={quillRef}
        textUpdated={this.textUpdated}
        checkEditorEmpty={this.checkEditorEmpty}
        getEditorContentAsJSON={this.getEditorContentAsJSON}
        changeTemplate={this.changeTemplate}
        submitCaseNote={this.submitNoteFunction}
        dispatchShowSubmitNoteModal={this.showConfirmSubmitNoteModal}
        isSaving={isSaving}
        isEditorEmpty={this.isEditorEmpty}
      />
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNoteC)
