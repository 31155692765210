import './SubscriptionScheduleInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getSubscriptionScheduleAmountsSelector } from 'state-layer/selectors'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { SUBSCRIPTION_SCHEDULE_AMOUNTS } from 'constants/linkConstants'
import { SUBSCRIPTION_BILLING_ICON } from 'constants/iconConstants'
import { GET_SUBSCRIPTION_SCHEDULE_AMOUNTS } from 'constants/flowConstants'
import { SUBSCRIPTION_SCHEDULE_AMOUNTS_RESOURCE_TITLE } from 'constants/language/languageConstants'

const SubscriptionScheduleInfo = ({
  detailsSectionData = [],
  isFetchingSchedule = false,
  isFetchingAmounts = false,
  tagsSectionData = [],
  subscriptionScheduleId = '',
  actions = [],
  flowValues = {},
}) => {
  return (
    <div className='SubscriptionScheduleInfo'>
      <PageSectionV2C
        title='Details'
        isFetching={isFetchingSchedule}
        columns={convertPageSectionDataToV2(detailsSectionData, 2)}
      />

      <div className='SubscriptionScheduleAmounts'>
        <TableC
          title={SUBSCRIPTION_SCHEDULE_AMOUNTS_RESOURCE_TITLE}
          actions={actions}
          columnDescriptors={columnDescriptors}
          flow={GET_SUBSCRIPTION_SCHEDULE_AMOUNTS}
          flowValues={flowValues}
          selector={getSubscriptionScheduleAmountsSelector}
          selectorId={subscriptionScheduleId}
          linksKey={SUBSCRIPTION_SCHEDULE_AMOUNTS}
          isFetching={isFetchingAmounts}
          fileNameCSV={`Subscription_Schedule_${subscriptionScheduleId}_Amounts`}
          fileResourceTitleCSV={SUBSCRIPTION_SCHEDULE_AMOUNTS_RESOURCE_TITLE}
          icon={`fa fa-${SUBSCRIPTION_BILLING_ICON}`}
          emptyMessage='No Subscription Amounts Available'
        />
      </div>

      <TagsPageSectionV2
        title='Tags'
        data={tagsSectionData}
        isFetchingSchedule={isFetchingSchedule}
      />
    </div>
  )
}

SubscriptionScheduleInfo.propTypes = {
  detailsSectionData: PropTypes.array,
  isFetchingSchedule: PropTypes.bool,
  isFetchingAmounts: PropTypes.bool,
  tagsSectionData: PropTypes.array,
  subscriptionScheduleId: PropTypes.string,
  actions: PropTypes.array,
  flowValues: PropTypes.object,
}

export default SubscriptionScheduleInfo
