import './ReviewIdentityV1S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import IdentityReviewInfo from 'components/Customer/Shared/Display/IdentityReviewInfo/IdentityReviewInfo'
import DictionaryList from 'components/Customer/Shared/Page/DictionaryList/DictionaryList'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import formatPaymentInstrumentReviewData from 'utilities/formatters/formatPaymentInstrumentReviewData'
import ReviewStatus from 'components/Customer/Pages/ReviewIdentity/ReviewStatus'
import { PENDING } from 'constants/reviewQueueConstants'
import { GROUP_ICON } from 'constants/iconConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const ReviewIdentityV1 = ({
  identity = {},
  headerTitle = '',
  subTitle = '',
  processingInfo = [],
  identityTagsSectionData = [],
  paymentInstruments = {},
  verifications = {},
  associatedIdentities = {},
  creatorEmail = '',
  reviewQueueItem = {},
  updateStatusToPending = () => {},
  updateStatus = () => {},
  isFetching = false,
}) => {
  const tags = get(reviewQueueItem, 'tags')
  const assignee = get(tags, 'assigned_to')
  const status = get(reviewQueueItem, 'outcome')
  const decisionBy = get(tags, 'decision_by')

  const actions = assignee === undefined ?
    [
      { label: 'Assign to me',
        buttonIcon: `fa fa-${GROUP_ICON}`,
        action: () => updateStatusToPending({ creatorEmail }),
        className: 'assignToMe',
      },
    ] :
    [
      { label: `Assigned to ${assignee}`,
        action: () => {},
        className: 'assignedTo',
      },
    ]

  return (
    <div className='ReviewIdentity'>
      <HeaderC
        title={headerTitle}
        subTitle={subTitle}
        actions={!isFetching && status === PENDING ? actions : []}
        breadcrumbs
      />

      <div className='header-page-content flex'>
        <div className='pageInfo w-75'>
          {!isEmpty(identity) && (
            <PageSection
              title='Bank Information'
              component={IdentityReviewInfo}
              data={identity}
              className='onboardingIdentityInfo'
            />
          )}

          {!isEmpty(processingInfo) && (
            <PageSection
              title='Processing Information'
              component={DictionaryList}
              data={processingInfo}
              className='onboardingIdentityInfo'
            />
          )}

          {!isEmpty(identityTagsSectionData) && (
            <PageSection
              title='Custom Information'
              component={DictionaryList}
              data={identityTagsSectionData}
              className='onboardingIdentityInfo'
            />
          )}

          {
            map(paymentInstruments, (paymentInstrument) => {
              const type = get(paymentInstrument, 'type')

              return (
                <PageSection
                  title={type}
                  component={DictionaryList}
                  data={formatPaymentInstrumentReviewData({ paymentInstrument })}
                  className='onboardingIdentityInfo'
                />
              )
            })
          }

          {
            map(verifications, (verification) => {
              const creditReportLink = get(verification, 'result.credit_report.html_preview')
              const watchlistReportLink = get(verification, 'result.watchlist.html_preview')

              const sectionData = [
                {
                  label: 'Credit Report',
                  value: <a className='text-link' href={creditReportLink} target='blank'>View credit report</a>,
                },
                {
                  label: 'Watchlist Report',
                  value: <a className='text-link' href={watchlistReportLink} target='blank'>View watchlist report</a>,
                },
              ]
              return (
                <PageSection
                  title='Verification'
                  component={DictionaryList}
                  data={sectionData}
                  className='onboardingIdentityInfo'
                />
              )
            })
          }

          {map(associatedIdentities, (associatedIdentity) => {
            return (
              <PageSection
                title='Beneficial Owner'
                component={IdentityReviewInfo}
                data={associatedIdentity}
                className='onboardingIdentityInfo'
              />
            )
          })
          }
        </div>

        <div className='status w-25'>
          <PageSection
            title='Review Actions'
            component={ReviewStatus}
            data={{ isFetching, status, updateStatus, decisionBy }}
            extraProps={{ hideOtherActions: true, underwritingEnabled: false }}
          />
        </div>

      </div>
    </div>
  )
}

ReviewIdentityV1.propTypes = {
  identity: PropTypes.object,
  headerTitle: PropTypes.string,
  subTitle: PropTypes.string,
  reviewQueueItem: PropTypes.object,
  processingInfo: PropTypes.array,
  identityTagsSectionData: PropTypes.array,
  paymentInstruments: PropTypes.object,
  verifications: PropTypes.object,
  associatedIdentities: PropTypes.object,
  updateStatusToPending: PropTypes.func,
  updateStatus: PropTypes.func,
  creatorEmail: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default ReviewIdentityV1
