import './SelfServiceSignupS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import SelfServiceSignupForm from 'components/Customer/Forms/SelfServiceSignupForm/SelfServiceSignupForm'
import Button from 'components/Shared/Button/Button'
import { ReactComponent as FinixAccentIcon } from 'img/svg/finix-accent.svg'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { FINIX_LOGO_BLACK } from 'constants/logoConstants'

import {
  FINIX_PRIVACY_POLICY_URL,
  SANDBOX_TERMS_OF_SERVICE_URL,
} from 'constants/urlConstants'

import {
  CONTACT_SALES,
  MOVE_MONEY_DESCRIPTION_1,
  MOVE_MONEY_DESCRIPTION_2,
  MOVE_MONEY_DESCRIPTION_3,
  MOVE_MONEY_TITLE,
} from 'constants/language/languageConstants'

import {
  CLICKED_ON_PRIVACY_POLICY_URL,
  CLICKED_ON_SANDBOX_TERMS_OF_SERVICES_URL,
} from 'constants/amplitudeConstants'

const SelfServiceSignup = ({
  passwordRequirements = [],
  onVerifyCaptcha = () => {},
  captchaRef = '',
  hCaptchaFormError,
  checkboxLabel = '',
}) => {
  return (
    <div className='SelfServiceSignup'>
      <div className='container'>
        <div className='header flex space-between items-center'>
          <img src={FINIX_LOGO_BLACK} alt='finix-logo' />
          <div className='login flex'>
            <div className='login-text'>Have an account?&nbsp;</div><Link to='/Login' className='login-link text-link'>Log in</Link>
          </div>
        </div>

        <div className='body flex items-center'>
          <div className='finix-info'>
            <div className='large-text'>
              {MOVE_MONEY_TITLE}
            </div>
            <div className='small-text'>
              {MOVE_MONEY_DESCRIPTION_1}
            </div>
            <div className='small-text'>
              {MOVE_MONEY_DESCRIPTION_2}
            </div>
            <div className='small-text'>
              {MOVE_MONEY_DESCRIPTION_3}
            </div>
            <a href='https://finix.com/contact-us' className='text-link'>
              <Button className='contact-sales-button' label={CONTACT_SALES} variant='tertiary' />
            </a>
          </div>

          <div className='finix-signup'>
            <FinixAccentIcon />
            <div className='signup-section'>
              <SelfServiceSignupForm
                passwordRequirements={passwordRequirements}
                onVerifyCaptcha={onVerifyCaptcha}
                captchaRef={captchaRef}
                initialValues={{ country: 'USA', marketingCheckbox: true }}
                hCaptchaFormError={hCaptchaFormError}
                checkboxLabel={checkboxLabel}
              />
              <div className='terms-of-service'>By signing up, you agree to Finix&apos;s <a onClick={sendAmplitudeActionEvent(CLICKED_ON_SANDBOX_TERMS_OF_SERVICES_URL)} href={SANDBOX_TERMS_OF_SERVICE_URL} className='text-link' target='blank'>Sandbox Terms of Service</a> and <a onClick={sendAmplitudeActionEvent(CLICKED_ON_PRIVACY_POLICY_URL)} className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>Privacy Policy</a>.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SelfServiceSignup.propTypes = {
  passwordRequirements: PropTypes.array,
  onVerifyCaptcha: PropTypes.func,
  captchaRef: PropTypes.string,
  hCaptchaFormError: PropTypes.bool,
  checkboxLabel: PropTypes.string,
}

export default SelfServiceSignup
