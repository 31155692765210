import getMany from 'utilities/get/getMany'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import postTeamMemberRequest from 'utilities/actions/post/postTeamMemberRequest'
import patchTeamMemberRequest from 'utilities/actions/patch/patchTeamMemberRequest'
import { POST_ROLE_F_REQUEST } from 'constants/flowConstants'
import { CREATE_CUSTOM_ROLE } from 'constants/amplitudeConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import omitBy from 'lodash/omitBy'
import keys from 'lodash/keys'

import {
  ALL_PERMISSIONS_LIST,
  filteredAllPermissionsByRole,
  permissionsForAllFilteredByRole,
} from 'constants/permissionConstants'

import {
  entityTypeToEntityTypeBEKeyMap,
  entityTypeToRoleMap,
} from 'constants/memberConstants'

const submitTeamMemberForm = (values, dispatch, props) => {
  const {
    credentials,
    entityId,
    entityType,
    memberId,
  } = props

  const dashboardId = getCurrentDashboardId()
  const isFlex = isFlexPlatform()

  const [
    host,
    paymentFacilitatorEnabled,
    pushToCardEnabled,
  ] = getMany(credentials, [
    'host',
    'paymentFacilitatorEnabled',
    'pushToCardEnabled',
  ])

  const [
    dashboardAccountName,
    firstName,
    lastName,
    email,
    rolesChoice,
    roleDescription,
    roleName,
    selectedEvents,
  ] = getMany(values, [
    'dashboardAccountName',
    'firstName',
    'lastName',
    'email',
    'rolesChoice',
    'roleDescription',
    'roleName',
    'selectedEvents',
  ])

  const entityTypeKey = get(entityTypeToEntityTypeBEKeyMap, entityType)

  const postPatchTeamMemberRequest = memberId ? patchTeamMemberRequest : postTeamMemberRequest

  // create custom role before creating team member
  if (rolesChoice === 'CUSTOM_ROLE') {
    const authRole = get(entityTypeToRoleMap, entityType)

    let permissions = permissionsForAllFilteredByRole({ authRole })
    const allPermissions = filteredAllPermissionsByRole({ permissionList: ALL_PERMISSIONS_LIST, authRole })

    forEach(selectedEvents, (event) => {
      const truthySelectedEvents = omitBy(event, (permission) => !permission)
      const selectedEventsPerGroup = keys(truthySelectedEvents)
      forEach(selectedEventsPerGroup, (eventLabel) => {
        const permissionsPerEvent = get(allPermissions, [eventLabel, 'permissions'])
        permissions = [...permissions, ...permissionsPerEvent]
      })
    })

    dispatch({
      type: POST_ROLE_F_REQUEST,
      payload: {
        values: {
          permissions,
          name: roleName,
          description: roleDescription,
          entity_id: entityId,
          auth_role: authRole,
        },
        credentials,
      },
      meta: {
        successCallback: ({ newValues }) => {
          const roleId = get(newValues, 'id')

          dispatch(postPatchTeamMemberRequest({
            memberId,
            dashboardAccountName,
            firstName,
            lastName,
            email,
            rolesChoice: roleId,
            host,
            dashboardId,
            pushToCardEnabled,
            paymentFacilitatorEnabled,
            credentials,
            dispatch,
            isFlex,
            entityId,
            entityTypeKey,
          }))
        },
        actionId: sendAmplitudeActionEvent(CREATE_CUSTOM_ROLE, {
          credentials,
          roleName,
          roleDescription,
          entityId,
          permissions,
        }),
        hideFlashNotification: true,
      },
    })
  } else {
    dispatch(postPatchTeamMemberRequest({
      memberId,
      dashboardAccountName,
      firstName,
      lastName,
      email,
      rolesChoice,
      host,
      dashboardId,
      pushToCardEnabled,
      paymentFacilitatorEnabled,
      credentials,
      dispatch,
      isFlex,
      entityId,
      entityTypeKey,
    }))
  }
}

export default submitTeamMemberForm
