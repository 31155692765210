import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateRefund from './CreateRefund'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getTransferReversalsRequest from 'utilities/actions/get/getTransferReversalsRequest'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import getSplitTransfersRequest from 'utilities/actions/get/getSplitTransfersRequest'
import formatMoney from 'utilities/formatters/formatMoney'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import isEqual from 'lodash/isEqual'
import values from 'lodash/values'
import map from 'lodash/map'

import {
  getTransferWithRelatedSelector,
  getReversalsSelector,
  getSplitTransfersByParentIdSelector,
} from 'state-layer/selectors'

import {
  REFUND_TO,
  PREVIOUS_REFUND,
  PREVIOUS_REFUND_AMOUNT,
  REFUND_RECIPIENT,
  ORIGINAL_PAYMENT_AMOUNT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const transferId = get(props, 'params.transferId')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const refunds = getReversalsSelector(state)
  const noPreviousRefunds = isEmpty(refunds)
  const splitTransfers = getSplitTransfersByParentIdSelector(state, transferId)

  const [
    amount,
    displayAmount,
    paymentInstrument,
    splitTransferIds,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    merchantId,
    currency,
  ] = getMany(transfer, [
    'amount',
    'displayAmountWithCurrencyCode',
    'paymentInstrument',
    'splitTransferIds',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'merchantId',
    'currency',
  ])

  const [
    displayBrand,
    lastFour,
    refundRecipient,
  ] = getMany(paymentInstrument, [
    'displayBrand',
    'lastFour',
    'name',
  ])

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const isSplitParentPayment = !isEmpty(splitTransferIds)

  const previousRefundAmounts = isEqual(size(refunds), 1) || noPreviousRefunds ?
    [{
      label: PREVIOUS_REFUND_AMOUNT,
      value: formatMoney({ amount: get(values(refunds), '[0].amount', 0), showCurrencyCode: true }),
    }] : map(sortBy(refunds, 'createdAt'), (refund, index) => {
      return {
        label: `${PREVIOUS_REFUND} ${index + 1}`,
        value: formatMoney({ amount: get(refund, 'amount', 0), showCurrencyCode: true }),
      }
    })

  const dataSection = [
    {
      label: REFUND_TO,
      value: lastFour && `${displayBrand} ••••${lastFour}`,
    },
    {
      label: REFUND_RECIPIENT,
      value: refundRecipient,
    },
    {
      label: ORIGINAL_PAYMENT_AMOUNT,
      value: displayAmount,
    },
    ...previousRefundAmounts,
  ]

  const detailsDataSection = convertPageSectionDataToV2(dataSection)

  return {
    isFetching,
    credentials,
    transferId,
    transfer,
    transferAmount: amount,
    detailsDataSection,
    isSplitParentPayment,
    splitTransfers,
    merchantBusinessName,
    noPreviousRefunds,
    displayTransferAmount: displayAmount,
    merchantId,
    currency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
    getSplitTransfers: ({ credentials, queries }) => { dispatch(getSplitTransfersRequest({ credentials, queries })) },
    getTransferReversals: ({ credentials, transferId }) => { dispatch(getTransferReversalsRequest({ credentials, transferId })) },
  }
}

class CreateRefundC extends Component {
  componentDidMount() {
    const {
      credentials,
      transferId,
      getTransfer,
      getTransferReversals,
      getSplitTransfers,
    } = this.props

    getTransfer({ credentials, transferId })
    getTransferReversals({ credentials, transferId })
    const queries = { parent_transfer_id: transferId }
    getSplitTransfers({ credentials, queries })
  }

  render() {
    const { showAPIResponseModal } = this.props

    return (
      <CreateRefund
        {...this.props}
        showAPIResponseModal={showAPIResponseModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRefundC)
