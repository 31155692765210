import Settings from './Settings'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPlatformSelector } from 'state-layer/selectors/index'
import ProfileInfoC from 'components/Customer/Pages/Settings/ProfileInfo/ProfileInfoC'
import NotificationSettingsC from 'components/Customer/Pages/Settings/Notifications/NotificationSettingsC'
import PermissionsSettingsC from 'components/Customer/Pages/Settings/Permissions/PermissionsSettingsC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getPlatformRequest from 'utilities/actions/get/getPlatformRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getMany from 'utilities/get/getMany'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { SHOW_NOTIFICATIONS } from 'constants/featureFlagConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  PROFILE,
  NOTIFICATION_SETTINGS,
  PERMISSIONS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `settingsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const roleId = get(credentials, 'dashboardUserRoleId')
  const currentUser = getCurrentUser(state)

  const [
    platformId,
    currentUserEmail,
    currentUserID,
    nonPlatformIdentityId,
    applicationId,
    fullName,
  ] = getMany(currentUser, [
    'platformId',
    'userEmail',
    'userName',
    'identityId',
    'applicationId',
    'fullName',
  ])

  const platform = getPlatformSelector(state, platformId)
  const platformIdentityId = get(platform, 'identityId')
  const identityId = platformIdentityId ? platformIdentityId : nonPlatformIdentityId

  const tabs = [
    {
      id: 'profile',
      name: PROFILE,
      component: ProfileInfoC,
      props: {
        identityId,
        currentUserEmail,
        currentUserID,
        fullName,
      },
    },
    {
      id: 'notification-settings',
      name: NOTIFICATION_SETTINGS,
      condition: () => (getFeatureFlag(SHOW_NOTIFICATIONS)),
      component: NotificationSettingsC,
    },
    {
      id: 'permissions',
      name: PERMISSIONS,
      component: PermissionsSettingsC,
      props: {
        roleId,
      },
      condition: !!roleId,
    },
  ]

  return {
    isFetching,
    credentials,
    credentialId,
    identityId,
    tabs,
    platformId,
    applicationId,
  }
}

const mapDispatchToProps = {
  getPlatform: getPlatformRequest,
  getIdentity: getIdentityRequest,
}

class SettingsC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      platformId,
      getIdentity,
      getPlatform,
    } = this.props

    if (identityId) getIdentity({ credentials, identityId })
    if (platformId && isRolePlatform({ credentials })) getPlatform({ credentials, platformId })
  }

  componentDidUpdate(prevProps) {
    const {
      platformId: prevPlatformId,
      identityId: prevIdentityId,
    } = prevProps

    const {
      platformId,
      identityId,
      getPlatform,
      getIdentity,
      credentials,
    } = this.props

    if (!prevPlatformId && platformId && isRolePlatform({ credentials })) { getPlatform({ credentials, platformId }) }
    if (!prevIdentityId && identityId) { getIdentity({ credentials, identityId }) }
  }

  render() {
    return (
      <Settings
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsC)
