import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstrumentRefunds from './PaymentInstrumentRefunds'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { REFUNDS_PATH } from 'constants/pathConstants'
import { REVERSAL } from 'constants/transferConstants'
import { REFUND } from 'constants/language/languageConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_BRAND_TYPE,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(REFUND),
  AMOUNT(),
  DEVICE_ID,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  INSTRUMENT_BRAND_TYPE,
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const isFetching = get(state, `paymentInstrumentTransfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const refundsPath = REFUNDS_PATH({ credentialId })

  const initialQueries = {
    offset: 0,
    type: REVERSAL,
    instrument_id: paymentInstrumentId,
  }

  const flowValues = {
    paymentInstrumentId,
  }

  return {
    paymentInstrumentId,
    flowValues,
    isFetching,
    refundsPath,
    initialQueries,
    allowedFilters,
    quickFilters,
  }
}

class PaymentInstrumentRefundsC extends Component {
  render() {
    return <PaymentInstrumentRefunds {...this.props} />
  }
}

export default connect(mapStateToProps)(PaymentInstrumentRefundsC)
