import frontendLevelThreeItemDataModel from './frontendLevelThreeItemDataModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import get from 'lodash/get'
import map from 'lodash/map'
import split from 'lodash/split'
import isEmpty from 'lodash/isEmpty'

const frontendTransferModel = ({ data: transfer }) => {
  const [
    id,
    type,
    subtype,
    state,
    amount,
    fee,
    feeType,
    currency,
    traceId,
    tags,
    statementDescriptor,
    createdAt,
    updatedAt,
    readyToSettleAt,
    application,
    paymentInstrument,
    merchantIdentity,
    reversals,
    applicationId,
    merchantIdentityId,
    raw,
    messages,
    sourceId,
    destinationId,
    deviceId,
    device,
    entryType,
    parentId,
    fees,
    settlement,
    settlementId,
    settlementEntry,
    disputes,
    reversalsDisputes,
    externallyFunded,
    failureCode,
    failureMessage,
    parentTransfer,
    parentTransferSettlement,
    feeIds,
    convenienceAmount,
    rentSurchargeAmount,
    surchargeAmount,
    buyerIdentity,
    splitTransferIds,
    merchantId,
    additionalPurchaseData,
    tipAmount,
    clinicAmount,
    dentalAmount,
    prescriptionAmount,
    visionAmount,
    receipts,
    paymentLink,
    cardPresentDetails,
    entryMode,
    operationKey,
    buyerIdentityId,
  ] = getMany(transfer, [
    'id',
    'type',
    'subtype',
    'state',
    'amount',
    'fee',
    'fee_type',
    'currency',
    'trace_id',
    'tags',
    'statement_descriptor',
    'created_at',
    'updated_at',
    'ready_to_settle_at',
    'application',
    'paymentInstrument',
    'merchantIdentity',
    'reversals',
    'application.id',
    'merchant_identity',
    'raw',
    'messages',
    'source',
    'destination',
    'deviceId',
    'device',
    'entry_type',
    'parentId',
    'fees',
    'settlement',
    'settlementId',
    'settlementEntry',
    'disputes',
    'reversalsDisputes',
    'externally_funded',
    'failure_code',
    'failure_message',
    'parentTransfer',
    'parentTransferSettlement',
    'feeIds',
    'additional_buyer_charges.convenience_amount',
    'additional_buyer_charges.rent_surcharge_amount',
    'additional_buyer_charges.surcharge_amount',
    'buyerIdentity',
    'split_transfers',
    'merchant',
    'additional_purchase_data',
    'tip_amount',
    'additional_healthcare_data.clinic_amount',
    'additional_healthcare_data.dental_amount',
    'additional_healthcare_data.prescription_amount',
    'additional_healthcare_data.vision_amount',
    'receipts',
    'paymentLink',
    'card_present_details',
    'card_present_details.entry_mode',
    'operation_key',
    'related.payment_instrument_details.identity',
  ])

  const transferType = type || entryType

  // certain transfer types pass error codes back from the API in the messages array
  const messagesFailureCode = get(split(get(messages, '0'), ': '), '1')
  const messagesFailureMessage = get(split(get(messages, '1'), ': '), '1')

  const [
    customerReferenceNumber,
    salesTax,
    customsDutyAmount,
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    discountAmount,
    itemData,
  ] = getMany(additionalPurchaseData, [
    'customer_reference_number',
    'sales_tax',
    'customs_duty_amount',
    'destination_postal_code',
    'ship_from_postal_code',
    'shipping_amount',
    'discount_amount',
    'item_data',
  ])

  const normalizedItemData = map(itemData, (item) => frontendLevelThreeItemDataModel({ data: item, currency }))

  const normalizedAdditionalPurchaseData = additionalPurchaseData ? removeUndefined({
    customerReferenceNumber,
    salesTax,
    displaySalesTax: formatMoney({ amount: salesTax }),
    customsDutyAmount,
    displayCustomsDutyAmount: formatMoney({ amount: customsDutyAmount, currency, showCurrencyCode: true }),
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    displayShippingAmount: formatMoney({ amount: shippingAmount, currency, showCurrencyCode: true }),
    discountAmount,
    displayDiscountAmount: formatMoney({ amount: discountAmount, currency, showCurrencyCode: true }),
    itemData: !isEmpty(normalizedItemData) ? normalizedItemData : undefined,
  }) : undefined

  return removeUndefined({
    id,
    type: transferType,
    displayType: convertSnakeToSentenceCase(transferType),
    subtype,
    displaySubtype: convertSnakeToSentenceCase(subtype),
    state,
    amount,
    currency,
    fee,
    displayFee: formatMoney({ amount: fee, currency }),
    displayFeeWithCurrencyCode: formatMoney({ amount: fee, currency, showCurrencyCode: true }),
    feeType,
    traceId,
    tags,
    statementDescriptor,
    updatedAt,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    readyToSettleAt,
    displayReadyToSettleAt: formatDate({ date: readyToSettleAt }),
    application,
    paymentInstrument,
    merchantIdentity,
    reversals,
    displayAmount: formatMoney({ amount, currency }),
    displayAmountWithCurrencyCode: formatMoney({ amount, currency, showCurrencyCode: true }),
    applicationId,
    merchantIdentityId,
    raw,
    messages,
    paymentInstrumentId: sourceId || destinationId || deviceId,
    parentId,
    fees,
    settlement,
    settlementId,
    settlementEntry,
    disputes,
    reversalsDisputes,
    externallyFunded,
    failureCode,
    failureMessage,
    displayFailureCode: convertSnakeToSentenceCase(failureCode),
    parentTransfer,
    parentTransferSettlement,
    deviceId,
    device,
    messagesFailureCode,
    messagesFailureMessage,
    feeIds,
    convenienceAmount,
    rentSurchargeAmount,
    displayConvenienceAmount: formatMoney({ amount: convenienceAmount }),
    displayRentSurchargeAmount: formatMoney({ amount: rentSurchargeAmount }),
    buyerIdentity,
    splitTransferIds,
    merchantId,
    additionalPurchaseData: normalizedAdditionalPurchaseData,
    isLevelTwoEnabled: !!(customerReferenceNumber && salesTax),
    isLevelThreeEnabled: !isEmpty(itemData),
    tipAmount,
    displayTipAmount: formatMoney({ amount: tipAmount, currency }),
    surchargeAmount,
    displaySurchargeAmount: formatMoney({ amount: surchargeAmount, currency }),
    clinicAmount,
    displayClinicAmount: formatMoney({ amount: clinicAmount, currency }),
    dentalAmount,
    displayDentalAmount: formatMoney({ amount: dentalAmount, currency }),
    prescriptionAmount,
    displayPrescriptionAmount: formatMoney({ amount: prescriptionAmount, currency }),
    visionAmount,
    displayVisionAmount: formatMoney({ amount: visionAmount, currency }),
    receipts,
    paymentLink,
    cardPresentDetails,
    entryMode,
    displayEntryMode: convertSnakeToSentenceCase(entryMode),
    operationKey,
    displayOperationKey: convertSnakeToSentenceCase(operationKey),
    buyerIdentityId,
  })
}

export default frontendTransferModel
