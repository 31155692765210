import './HeaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import BreadcrumbsC from 'components/Customer/Shared/Page/Breadcrumbs/BreadcrumbsC'
import ActionButtonsDropdown from 'components/Shared/ActionButtonsDropdown/ActionButtonsDropdown'
import Button from 'components/Shared/Button/Button'
import { CHEVRON_DOWN_ICON } from 'constants/iconConstants'
import map from 'lodash/map'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'

const Header = ({
  title = '',
  subTitle = '',
  titleDecorator,
  icon = '',
  actions = [],
  breadcrumbs = false,
  relatedObjects = {},
  showRelatedObjects = false,
  toggleShowActionsDropdown = () => {},
  actionDropdownMap = {},
  handleCloseDropdowns = () => {},
}) => {
  return (
    <header className='Header'>
      { breadcrumbs && <BreadcrumbsC /> }

      <div className='header-content flex space-between'>
        <div className='title'>
          <div className='flex flex-start items-center'>
            { title && (<h1>{icon && icon} {title}</h1>) }
            {titleDecorator && titleDecorator}
          </div>

          { subTitle && (isString(subTitle) ? <p className='header-subtitle'>{subTitle}</p> : subTitle) }
        </div>

        <div className='flex flex-start'>
          { !isEmpty(actions) && (
          <ul className='flex actions'>
            {
              !isEmpty(actions) && map(actions, ({ label, action, actionList, link, className, buttonIcon, condition = true }) => {
                const buttonClassName = className ? className : `${camelCase(label)}HeaderButton`
                const showActionsDropdown = actionDropdownMap[label]

                const buttonAction = isFunction(action) ? (
                  <Button
                    className={buttonClassName}
                    onClick={() => {
                      action()
                      handleCloseDropdowns()
                    }}
                    key={label}
                    label={label}
                    rightIcon={buttonIcon}
                  />
                ) : ActionButtonsDropdown({
                  handleCloseDropdowns,
                  toggleShowActionsDropdown,
                  showActionsDropdown,
                  label,
                  actionList,
                })

                return condition ? (
                  <li key={label}>
                    { link ? (
                      <Link to={link}>
                        <Button className={buttonClassName} label={label} rightIcon={buttonIcon} />
                      </Link>
                    ) : buttonAction }
                  </li>
                ) : null
              })
            }
          </ul>
          )}
          {/* TODO: refactor related objects to use ActionButtonsDropdown component */}
          { !isEmpty(relatedObjects) && (
          <div className='related-objects'>
            <div
              role='button'
              tabIndex='0'
              aria-label='related objects'
              aria-expanded={showRelatedObjects}
              className='button-container flex flex-start'
              onClick={(e) => toggleShowActionsDropdown(e, 'Related Objects')}
              onKeyDown={(e) => toggleShowActionsDropdown(e, 'Related Objects')}
            >
              <div className='related-objects-label'>Related Objects</div>
              <span className={`related-objects-button fa fa-${CHEVRON_DOWN_ICON}`} />
            </div>

            { showRelatedObjects && (
              <ul className='related-links'>
                {
                  map(relatedObjects, ({ id, name, link }) => {
                    return (
                      <li key={id}>
                        <Link to={link}>
                          <div className='item flex column'>
                            <span>{name}</span>
                            <span>{id}</span>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            )}
          </div>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  titleDecorator: PropTypes.object,
  icon: PropTypes.string,
  actions: PropTypes.array,
  breadcrumbs: PropTypes.bool,
  relatedObjects: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  showRelatedObjects: PropTypes.bool,
  toggleShowActionsDropdown: PropTypes.func,
  actionDropdownMap: PropTypes.object,
  handleCloseDropdowns: PropTypes.func,
}

export default Header
