import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MFA_ASSOCIATE } from 'constants/apiConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const postDashboardUserMFAAssociateAPI = ({ values, meta }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_MFA_ASSOCIATE({
      userId: get(getCurrentCredentials(), 'userId'),
    }),
    values,
    meta,
  })
}

export default postDashboardUserMFAAssociateAPI
