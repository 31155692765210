import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ONBOARDING_FORM_REQUEST_CHANGES } from 'constants/apiConstants'

const postCustomerOnboardingFormRequestChangesAPI = ({ id, values, meta }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_ONBOARDING_FORM_REQUEST_CHANGES({ id }),
    values,
    meta,
  })
}

export default postCustomerOnboardingFormRequestChangesAPI
