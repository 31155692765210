import createAction from 'utilities/actions/createAction'
import { GET_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST } from 'constants/flowConstants'

const getIdentityPaymentInstrumentsRequest = ({
  identityId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST,
  credentials,
  queries: {
    owner_identity_id: identityId,
    ...queries,
  },
  meta,
})

export default getIdentityPaymentInstrumentsRequest
