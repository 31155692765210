import './PlatformsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { PLATFORMS } from 'constants/linkConstants'
import { GET_PLATFORMS } from 'constants/flowConstants'
import { PLATFORMS_ICON } from 'constants/iconConstants'
import { getPlatformsSelector } from 'state-layer/selectors'

const Platforms = ({
  platformsPath = '',
  initialQueries,
  isFetching = false,
}) => {
  return (
    <div className='Platforms'>
      <div className='content-header flex space-between'>
        <h1>Platforms</h1>
      </div>

      <TableC
        flow={GET_PLATFORMS}
        columnDescriptors={columnDescriptors}
        selector={getPlatformsSelector}
        initialQueries={initialQueries}
        linksKey={PLATFORMS}
        path={platformsPath}
        isFetching={isFetching}
        emptyMessage='No Platforms Available'
        icon={`fa fa-${PLATFORMS_ICON}`}
      />

    </div>
  )
}

Platforms.propTypes = {
  platformsPath: PropTypes.string,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default Platforms
