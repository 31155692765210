import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT_FUNDING_TRANSFER_ATTEMPTS,
  FINIX_VERSION,
  FINIX_VERSION_2022_02_01,
} from 'constants/apiConstants'

const postInstantPayoutAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    path: SETTLEMENT_FUNDING_TRANSFER_ATTEMPTS({ settlementId: id }),
    values,
    credentials,
    meta,
    headers: {
      [FINIX_VERSION]: FINIX_VERSION_2022_02_01,
    },
  })
}

export default postInstantPayoutAPI
