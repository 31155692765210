import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'

import {
  ADD_VALUE,
  TAGS_LIMIT_REACHED,
} from 'constants/language/languageConstants'

import {
  TRASH_ICON,
  ADD_ICON,
  ALERT_ICON,
} from 'constants/iconConstants'

const Tags = ({ fields = {} }) => (
  <ul className='Tags'>
    {fields.map((tag, index) => {
      const key = `tag_${index}`

      return (
        <li key={key} className='flex tag'>
          <Field
            id='tag-name-input'
            name={`${tag}.name`}
            placeholder='Name'
            component={InputField}
            className='tag-item name'
          />
          <Field
            id='tag-value-input'
            name={`${tag}.value`}
            placeholder='Value'
            component={InputField}
            className='tag-item value'
          />

          <div className='tag-item'>
            <Button icon={TRASH_ICON} variant='destructive' size='large' onClick={() => fields.remove(index)} />
          </div>
        </li>
      )
    })}

    <li className='add-tag'>
      {
        fields.length >= 50 ? (
          <div className='tags-limit-reached-highlight'>
            <span className='tags-limit-reached-info'><span className={`fa fa-${ALERT_ICON}`} />{TAGS_LIMIT_REACHED}</span>
          </div>
        ) : (
          <Button variant='secondary' onClick={() => fields.push({})} leftIcon={ADD_ICON} label={ADD_VALUE} />
        )
      }
    </li>
  </ul>
)

Tags.propTypes = {
  fields: PropTypes.object,
}

export default Tags
