import './ContactSupportModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ContactSupportForm from 'components/Customer/Forms/ContactSupportForm/ContactSupportForm'

const ContactSupportModal = ({
  credentials,
  currentUserEmail = '',
}) => {
  return (
    <GenericModal
      title='Contact Support'
      className='ContactSupportModal'
      Component={ContactSupportForm}
      credentials={credentials}
      currentUserEmail={currentUserEmail}
    />
  )
}

ContactSupportModal.propTypes = {
  credentials: PropTypes.object,
  currentUserEmail: PropTypes.string,
}

export default ContactSupportModal
