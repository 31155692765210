import './CreateBalanceAdjustmentReviewFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import submitCreateBalanceAdjustmentReviewForm from 'utilities/submit/submitCreateBalanceAdjustmentReviewForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM } from 'constants/formConstants'

import {
  CANCEL,
  CREATE_A_BALANCE_ADJUSTMENT,
  CREATE_A_BALANCE_ADJUSTMENT_SUBTITLE,
  PREVIOUS_STEP,
  SUBMIT,
  TRANSACTION_DETAILS,
} from 'constants/language/languageConstants'

const CreateBalanceAdjustmentReviewForm = ({
  handleSubmit = () => {},
  transactionDetailsSectionData = [],
  isFetching = false,
}) => {
  return (
    <form className='CreateBalanceAdjustmentReviewForm' onSubmit={handleSubmit(submitCreateBalanceAdjustmentReviewForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{CREATE_A_BALANCE_ADJUSTMENT}</h3>
        <div className='p-1'>{CREATE_A_BALANCE_ADJUSTMENT_SUBTITLE}</div>
      </div>

      <div className='form-content'>
        <PageSectionV2
          smallTitle={TRANSACTION_DETAILS}
          className='transaction-details'
          columns={transactionDetailsSectionData}
        />
      </div>

      <div className='form-footer flex'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} className='prev-step-button' label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} className='cancel-button' label={CANCEL} variant='ghost' />
            <Button type='submit' className='submit-button' disabled={isFetching} submitting={isFetching} label={SUBMIT} />
          </div>
        </div>
      </div>
    </form>
  )
}

CreateBalanceAdjustmentReviewForm.propTypes = {
  handleSubmit: PropTypes.func,
  transactionDetailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM,
})(CreateBalanceAdjustmentReviewForm)
