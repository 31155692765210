import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFERS,
  TRANSFER_FEES,
} from 'constants/apiConstants'

const getTransferFeesAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: TRANSFER_FEES({ transferId: id }),
    credentials,
    service: TRANSFERS,
  })
}

export default getTransferFeesAPI
