import './SelectSubscriptionScheduleFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ToggleDataSectionsC from 'components/Customer/Shared/Page/PageSection/ToggleDataSectionsC'
import Button from 'components/Shared/Button/Button'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import validateCreateSubscriptionEnrollmentForm from 'utilities/validate/validateCreateSubscriptionEnrollmentForm'
import { SELECT_SUBSCRIPTION_SCHEDULE } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'

import {
  CANCEL,
  MERCHANT_ENROLLED_IN_SCHEDULE,
  NEXT,
} from 'constants/language/languageConstants'

const SelectSubscriptionScheduleForm = ({
  handleSubmit = () => {},
  subscriptionSchedule = {},
  subscriptionScheduleSectionData = {},
  alreadyEnrolled = false,
  activeStep,
  steps = [],
  closeModal,
}) => {
  return (
    <form className='SelectSubscriptionScheduleForm' onSubmit={handleSubmit}>
      <div className='section'>
        <Field
          name='subscriptionScheduleId'
          label='Enter a subscription schedule ID'
          placeholder='Enter a subscription schedule ID'
          component={InputField}
        />

        {
          !isEmpty(subscriptionSchedule) && (
            <ToggleDataSectionsC
              data={subscriptionScheduleSectionData}
            />
          )
        }

        { alreadyEnrolled && (<WarningMessage warningMessage={MERCHANT_ENROLLED_IN_SCHEDULE} />) }
      </div>

      <div className='buttons flex space-between items-center'>
        <div className='left flex flex-start'>
          <Button variant='secondary' onClick={closeModal} label={CANCEL} />
        </div>

        <div className='progress-string'>{`${activeStep + 1} of ${steps.length}`}</div>

        <div className='right flex flex-end'>
          <Button type='submit' label={NEXT} disabled={isEmpty(subscriptionSchedule)} />
        </div>
      </div>
    </form>
  )
}

SelectSubscriptionScheduleForm.propTypes = {
  handleSubmit: PropTypes.func,
  subscriptionSchedule: PropTypes.object,
  subscriptionScheduleSectionData: PropTypes.object,
  alreadyEnrolled: PropTypes.bool,
}

export default reduxForm({
  form: SELECT_SUBSCRIPTION_SCHEDULE,
  validate: validateCreateSubscriptionEnrollmentForm,
})(SelectSubscriptionScheduleForm)
