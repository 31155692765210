import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import AdditionalVerificationC from 'components/Customer/Pages/AdditionalVerification/AdditionalVerificationC'
import { ADDITIONAL_VERIFICATION_NEEDED } from 'constants/language/languageConstants'

const AdditionalVerificationModal = ({
  customerOnboardingFormId = '',
}) => {
  return (
    <GenericModal
      title={ADDITIONAL_VERIFICATION_NEEDED}
      customerOnboardingFormId={customerOnboardingFormId}
      Component={AdditionalVerificationC}
    />
  )
}

AdditionalVerificationModal.propTypes = {
  customerOnboardingFormId: PropTypes.string,
}

export default AdditionalVerificationModal
