import getSettlementFundingTransfersRequest from 'utilities/actions/get/getSettlementFundingTransfersRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { RESEND_TRANSFER } from 'constants/amplitudeConstants'
import { PATCH_SETTLEMENT_V1_F_REQUEST } from 'constants/flowConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'

const submitResendTransferForm = (values, dispatch, props) => {
  const paymentInstrumentId = get(values, 'paymentInstrumentId.value')

  const { settlementId, credentials } = props

  const valuesToSubmit = {
    destination: paymentInstrumentId,
  }

  dispatch({
    type: PATCH_SETTLEMENT_V1_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
      id: settlementId,
    },
    meta: {
      closeModal: true,
      actionId: sendAmplitudeActionEvent(RESEND_TRANSFER, {
        settlementId,
        destination: paymentInstrumentId,
        credentials,
      }),
      successCallback: ({ newValues }) => {
        const settlementValues = head(keys(newValues))
        const firstValue = newValues[settlementValues]
        const settlement = get(firstValue, 'id')

        dispatch(getSettlementFundingTransfersRequest({ credentials, settlementId: settlement }))
      },
    },
  })
}

export default submitResendTransferForm
