import createAction from 'utilities/actions/createAction'
import { GET_DISPUTE_EVIDENCES_F_REQUEST } from 'constants/flowConstants'

const getDisputeEvidencesRequest = ({
  disputeId,
  credentials,
}) => {
  return createAction({
    type: GET_DISPUTE_EVIDENCES_F_REQUEST,
    id: disputeId,
    credentials,
    meta: {
      selectorId: disputeId,
    },
  })
}

export default getDisputeEvidencesRequest
