import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACCESS_FORM_BANK_ACCOUNT_NUMBER_UNMASK } from 'constants/apiConstants'

const getAccessFormBankAccountNumberUnmaskAPI = ({ id }) => {
  return dashboardAPI.get({
    path: ACCESS_FORM_BANK_ACCOUNT_NUMBER_UNMASK({ accessFormId: id }),
  })
}

export default getAccessFormBankAccountNumberUnmaskAPI
