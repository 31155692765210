import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const getFormSyncErrors = ({ errors, fieldMap }) => reduce(errors, (total, error) => {
  const { field, message } = error
  const fieldName = fieldMap[field]

  return merge({}, total, {
    [fieldName]: message,
  })
}, {})

export default getFormSyncErrors
