import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_PROFILE,
  PAYOUT_PROFILES,
} from 'constants/apiConstants'

const getPayoutSettingsAPI = ({ id, credentials, queries, meta }) => {
  return paymentsAPI.get({
    path: PAYOUT_PROFILE({ payoutProfileId: id }),
    credentials,
    queries,
    meta,
    service: PAYOUT_PROFILES,
  })
}

export default getPayoutSettingsAPI
