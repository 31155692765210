import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import RequestAdditionalInformationForUnderwritingEntityForm from './RequestAdditionalInformationForUnderwritingEntityForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import { FETCHING } from 'constants/reducerConstants'
import { REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM } from 'constants/formConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const reviewId = get(props, 'reviewId')
  const credentials = getCurrentCredentials(state)
  const dashboardUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const formSelector = formValueSelector(REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM)
  const currentSelectedEvents = formSelector(state, 'selectedEvents')

  return {
    isFetching,
    reviewId,
    credentials,
    dashboardUserEmail,
    currentSelectedEvents,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class RequestAdditionalInformationForUnderwritingEntityFormC extends Component {
  render() {
    return (
      <RequestAdditionalInformationForUnderwritingEntityForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAdditionalInformationForUnderwritingEntityFormC)
