import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from './Table'
import { getState } from 'state-layer/configureStore'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import createTableItems from 'utilities/create/createTableItems'
import createTableHeaders from 'utilities/create/createTableHeaders'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import removeValues from 'utilities/remove/removeValues'
import updateUrlQueries from 'utilities/updateUrlQueries'
import showModalAction from 'utilities/actions/showModalAction'
import getPageName from 'utilities/get/getPageName'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import { clearTableIds } from 'utilities/actions/clearTableIds'
import { CREDENTIALS } from 'constants/linkConstants'
import { TOGGLE_PAGINATION } from 'constants/actionConstants'
import { REVIEW_QUEUE_QUERIES } from 'constants/reviewQueueConstants'
import { EQUALS } from 'constants/filterConstants'
import { frontendNormalizationMap } from 'constants/normalizationConstants'
import { HIDE_TABLE_ERROR_FLOW_CONSTANTS } from 'constants/errorConstants'
import isObject from 'lodash/isObject'
import isFunction from 'lodash/isFunction'
import printCSV from 'utilities/printCSV'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import merge from 'lodash/merge'
import split from 'lodash/split'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import concat from 'lodash/concat'
import omit from 'lodash/omit'
import get from 'lodash/get'
import filter from 'lodash/filter'
import pick from 'lodash/pick'
import isUndefined from 'lodash/isUndefined'

import {
  EXPORT_DATA,
  EXPORT_DATA_V2,
} from 'constants/modalConstants'

import {
  EXPORT_CSV,
  TABLE_LOAD_MORE,
  UPDATE_TABLE_LIMIT,
  NAVIGATE_TABLE_PAGE,
} from 'constants/amplitudeConstants'

import {
  requestF,
  ADD_TABLE_FILTER,
  NON_CREDENTIAL_FLOWS,
  CLEAR_TABLE_LINKS,
} from 'constants/flowConstants'

import {
  getActiveIds,
  getLinksSelector,
  getTableFiltersSelector,
  getIsFetchingByLinksKey,
} from 'state-layer/selectors'

import {
  TAB,
  SORT,
  PATHNAME,
  SEARCH,
  IGNORE_QUERIES,
  queryNameToModelName,
} from 'constants/queryConstants'

import {
  FIRST,
  LAST,
  PREV,
  NEXT,
  PAGINATION_QUERIES,
  limitOptions,
} from 'constants/paginationConstants'

const generateExportObjectFromItemAttributes = (attributes) => reduce(attributes, (total, attribute) => {
  const {
    displayValue,
    value,
    title,
  } = attribute

  const normalizedValue = (isObject(displayValue) || isFunction(displayValue)) ? value : displayValue

  return merge({}, total, { [title]: normalizedValue })
}, {})

const defaultPaginationLimit = 50

// this file has many comments since it is extra complex. useful for helping new hires understand how this function works
const mapStateToProps = (state, props) => {
  const {
    data: propsData,
    columnDescriptors = [],
    extraProps,
    idKey,
    selector,
    selectorId = '',
    linksKey,
    initialQueries = {},
    relatedItemsKey = '',
    overwriteReducer = true,
    classNames,
    selectedItemActions = [],
    doNotSortData = false,
    concurrent = false,
    shouldUseV2DataFlow = false,
    isFetching: isFetchingProp,
  } = props

  // useful utility data from reducers
  const credentials = getCurrentCredentials(state, props)
  const currentFilters = getTableFiltersSelector(state)
  const links = getLinksSelector(state, linksKey)
  const shouldRefresh = get(state, 'tableR.shouldRefresh', false)
  const pagination = get(state, 'tableR.pagination', false)
  const currentQueries = parseUrlQueries()
  const currentOffset = parseInt(get(currentQueries, 'offset'), 10)
  const limitCount = parseInt(get(state, 'form.TABLE_HEADERS_FORM.values.limitCount', defaultPaginationLimit), 10)
  const credentialsPage = linksKey === CREDENTIALS
  const dashboardServiceV2 = isDashboardServiceV2()
  const currentDashboardConfig = getCurrentDashboardConfig(state)
  const isFetching = isUndefined(isFetchingProp) ? getIsFetchingByLinksKey(state, linksKey) : isFetchingProp

  // next will exist if we fetched data properly and have more data to fetch
  const next = get(links, `${NEXT}.href`) || get(links, NEXT)
  const prev = get(links, `${PREV}.href`) || get(links, PREV)
  const first = get(links, `${FIRST}.href`) || get(links, FIRST)
  const last = get(links, `${LAST}.href`) || get(links, LAST)
  const page = get(links, 'page')
  const nextCursor = get(page, 'next_cursor')

  const lastOffset = parseInt(get(parseUrlQueries(last, frontendNormalizationMap), 'offset'), 10)
  // TODO: in the future, we can get rid of credentialsPage check when dashboard service is finished
  const disabledPrev = (!credentialsPage && currentOffset === 0) || (credentialsPage && first === undefined) ? 'disabled' : ''
  const disabledNext = (!credentialsPage && currentOffset === lastOffset) || (credentialsPage && last === undefined) ? 'disabled' : ''

  // this is the flag we use for showing the loading spinner
  const hasMore = !!next || !!nextCursor

  // Get the active IDs for this table
  const activeIds = shouldUseV2DataFlow ? getActiveIds(state, linksKey) : []

  // get selector data, if provided
  const selectorData = selector ? selector(state, selectorId) : {}

  const filteredData = shouldUseV2DataFlow ? pick(selectorData, activeIds) : selectorData

  // default to propsData if data is passed directly to the tableComponent (cannot pass data AND get benefits of reducer data)
  const data = propsData || filteredData

  // get sort data, but what if this changes? we should re-think this soon
  const querySort = get(state, SORT)
  const sortParts = split(querySort, ',')
  const queryColumn = get(sortParts, '0')
  const queryOrder = get(sortParts, '1')
  const queryTab = get(state, TAB)

  // this handles changing BE -> FE names, not ideal!
  const column = get(queryNameToModelName, queryColumn, queryColumn)
  const orderedData = doNotSortData ? data : orderBy(data, [column], [queryOrder])

  // generate the table headers
  const headers = createTableHeaders({
    columnDescriptors,
    credentials,
  })

  // generate the table items
  const items = createTableItems({
    data: orderedData,
    columnDescriptors,
    extraProps,
    idKey,
    credentials,
    relatedItemsKey,
    selectedItemActions,
  })

  return {
    items,
    orderedData,
    first,
    prev,
    next,
    last,
    page,
    headers,
    credentials,
    hasMore,
    currentFilters,
    queryTab,
    initialQueries: merge({}, { offset: 0, sort: 'created_at,desc', pagination, limit: limitCount }, initialQueries),
    overwriteReducer,
    shouldRefresh,
    pagination,
    limitCount,
    currentQueries,
    currentOffset,
    disabledPrev,
    disabledNext,
    credentialsPage,
    dashboardServiceV2,
    classNames,
    nextCursor,
    isFetching,
  }
}

class TableC extends Component {
  constructor (props) {
    super(props)

    const { initialQueries } = props
    const sort = get(initialQueries, 'sort')

    // set initial sort and flags
    this.state = {
      sort,
      showFilters: false,
      loadMoreThrottle: false,
      initialLoaded: false,
      height: 200,
      timeout: null,
    }
  }

  componentDidMount() {
    const {
      dispatch,
      initialFilters = {},
    } = this.props

    forEach(initialFilters, (operands, field) => {
      forEach(operands, (value, operand) => {
        if (field && operand && value) {
          dispatch({
            type: ADD_TABLE_FILTER,
            payload: {
              field,
              operand,
              value,
            },
          })
        }
      })
    })

    this.fetchInitialData()
    this.resizeTable()
    window.addEventListener('resize', this.resizeTable)
  }

  componentDidUpdate(prevProps) {
    const {
      initialQueries: prevQueries,
      currentFilters: prevFilters,
      selectorId: prevSelector,
      flowValues: prevFlowValues,
      credentials: prevCredentials,
      pagination: prevPagination,
      limitCount: prevLimitCount,
      currentQueries: prevCurrentQueries,
      last: prevLast,
    } = prevProps

    const {
      shouldRefresh,
      pagination,
      overwriteReducer,
      limitCount,
      currentQueries,
      last,
      initialQueries: nextQueries,
      currentFilters: nextFilters,
      selectorId: nextSelector,
      flowValues: nextFlowValues,
      credentials: nextCredentials,
    } = this.props

    const {
      initialLoaded = false,
    } = this.state

    const filtersChanged = !isEqual(prevFilters, nextFilters) && initialLoaded
    const selectorChanged = !isEqual(prevSelector, nextSelector)
    const flowValuesChanged = !isEqual(prevFlowValues, nextFlowValues)
    const prevCredentialsId = get(prevCredentials, 'id')
    const nextCredentialsId = get(nextCredentials, 'id')
    const credentialsChanged = !isEqual(prevCredentialsId, nextCredentialsId)
    const prevTab = get(prevCurrentQueries, 'tab', '')
    const nextTab = get(currentQueries, 'tab', '')
    const tabQueriesChanged = prevTab !== nextTab
    const prevLastOffset = get(prevLast, 'offset')
    const lastOffset = get(last, 'offset')

    // we do not want to re-fetch data when a pagination related query is updated, otherwise it will get into an infinite loop of re-fetching next data
    const omittedPrevQueries = omit(prevQueries, PAGINATION_QUERIES)
    const omittedNextQueries = omit(nextQueries, PAGINATION_QUERIES)
    const queriesChanged = !isEqual(omittedPrevQueries, omittedNextQueries)

    const urlPaginationValue = get(currentQueries, 'pagination') === 'true'
    const urlLimitValue = parseInt(get(currentQueries, 'limit', 20), 10)

    const fetchData = get(currentQueries, 'fetchData')

    const prevFilterKeys = reduce(prevFilters, (total, filterValues, filterKey) => {
      const keyNames = map(filterValues, (filterValue, operand) => {
        return operand === EQUALS ? filterKey : `${filterKey}.${operand}`
      })

      return total.concat(keyNames)
    }, [])

    // we don't want to include previous filters or any not updated review queue queries
    const omittedQueries = omit(currentQueries, concat(prevFilterKeys, REVIEW_QUEUE_QUERIES))
    let normalizedQueries = merge({}, nextQueries, omittedQueries)

    if (!includes([20, 50, 100], urlLimitValue)) {
      normalizedQueries = merge({}, normalizedQueries, { limit: 20 })
    }

    const firstPageQueries = omit(merge({}, normalizedQueries, { offset: 0, pagination }), 'after_cursor')

    // TODO: right now when view is switched between infinite scroll and pagination, should reset back to initial offset of 0 (may possibly change in the future based on feedback)
    if (prevPagination !== pagination || (urlPaginationValue !== pagination)) {
      // change pagination value in url when table navigational view is toggled
      updateUrlQueries(firstPageQueries)

      if (pagination) {
        this.fetchPaginationData({ queries: firstPageQueries })
      } else {
        this.fetchData({
          queries: firstPageQueries,
          meta: { overwriteReducer },
        })
      }
    }

    if (filtersChanged || queriesChanged || selectorChanged || flowValuesChanged || fetchData) {
      if (tabQueriesChanged || filtersChanged || (credentialsChanged && !urlPaginationValue)) {
        normalizedQueries = firstPageQueries
      }

      if (urlPaginationValue) {
        this.fetchPaginationData({ queries: normalizedQueries })
      } else {
        this.fetchData({
          queries: normalizedQueries || nextQueries,
          meta: {
            overwriteReducer: true,
          },
        })
      }
    }

    if (prevLimitCount !== limitCount) {
      this.updateLimitCount()
    }

    if (prevLastOffset !== lastOffset) {
      this.fetchPaginationData({ flow: LAST })
    }

    if (shouldRefresh || credentialsChanged) {
      this.fetchInitialData()
    }
  }

  componentWillUnmount() {
    const {
      timeout,
    } = this.state

    const {
      linksKey,
      dispatch,
    } = this.props

    if (timeout) clearTimeout(timeout)

    if (linksKey) {
      dispatch(clearTableIds(linksKey))

      dispatch({
        type: CLEAR_TABLE_LINKS,
        payload: {
          linksKey,
        },
      })
    }

    window.removeEventListener('resize', this.resizeTable)
  }

  resizeTable = () => {
    const tableItems = document.querySelector('.table-content .items')
    const offsetTop = tableItems ? tableItems.getBoundingClientRect().top : 0
    const height = window.innerHeight - offsetTop - 85

    this.setState({ height: height > 300 ? height : 300 })
  }

  fetchData = ({ initialFetch = false, queries = {}, meta = {} }) => {
    const {
      flow,
      flowValues = {},
      linksKey,
      credentials,
      dispatch,
      initialFilters = {},
      currentFilters,
      queryTab,
      selectorId,
      urlQueriesToIgnore = [],
      concurrent = false,
      shouldUseV2DataFlow = false,
    } = this.props

    // if we flow and proper credentials are provided dispatch a fetch data request
    if (flow && (!isEmpty(credentials) || includes(NON_CREDENTIAL_FLOWS, flow))) {
      const filters = initialFetch ? initialFilters : currentFilters

      const filterQueries = reduce(filters, (total, operands, fieldName) => {
        forEach(operands, (value, operand) => {
          // if operand is EQUALS send {fieldName} directly, otherwise use {fieldName}.{operand} -> ex: (amount.gte)
          // ex: ?merchant=Test,created_at.gte=12-1-2019,amount.lte=500 -> where merchant is 'Test', created_at >= 12-1-2019 and amount <= 600
          const paramName = operand && operand !== EQUALS ? `${fieldName}.${operand}` : fieldName

          total[paramName] = value
        })

        return total
      }, {})

      const urlQueries = removeUndefined(merge({ tab: queryTab }, filterQueries, queries))
      // TODO: we should make a constant of any other URL queries we always want to omit from table requests
      const queriesToIgnore = concat(IGNORE_QUERIES, urlQueriesToIgnore)
      const omittedQueries = omit(queries, queriesToIgnore)
      const updatedQueries = removeValues(merge({}, filterQueries, omittedQueries), ['', undefined, null])
      const afterCursor = get(updatedQueries, 'after_cursor')
      const normalizedQueries = afterCursor ? omit(updatedQueries, 'offset') : updatedQueries
      const hideTableErrors = HIDE_TABLE_ERROR_FLOW_CONSTANTS[flow]

      if (hideTableErrors) {
        meta = {
          ...meta,
          showErrors: false,
        }
      }

      const overwriteReducer = get(meta, 'overwriteReducer', false)

      const mergedMeta = merge({}, meta, {
        selectorId,
        concurrent,
        successCallback: () => {
          this.setState({ initialLoaded: true })
          this.resizeTable()
        },
        errorCallback: () => {
          this.setState({ initialLoaded: true })
        },
        overwriteReducer: concurrent || shouldUseV2DataFlow ? false : overwriteReducer,
      })

      updateUrlQueries(urlQueries, true)

      // make sure page is still on the current view when refreshed
      dispatch({ type: TOGGLE_PAGINATION, payload: { status: get(urlQueries, 'pagination') === true } })

      // we cannot use dots in field names due to Redux Form auto converting them into objects
      // a quick solution around this is to use a common delimiter to convert into dots on request
      // this solves a myriad of issues we were running into when trying to have filters with dots
      // created_at is a special exception that had a lot of code written specifically for it
      // This could ba e good project to refactor our filter system as a whole to better
      // support object like field names with dot notation
      const queryKeyDelimitersConverted = reduce(normalizedQueries, (total, value, key = '') => {
        return merge({}, total, { [key.replaceAll('*', '.')]: value })
      }, {})

      if (shouldUseV2DataFlow && overwriteReducer && linksKey) {
        dispatch(clearTableIds(linksKey))
      }

      // dispatch fetch request
      dispatch(removeUndefined({
        type: requestF(flow),
        payload: {
          queries: queryKeyDelimitersConverted,
          linksKey,
          credentials: !isEmpty(credentials) ? credentials : undefined,
          values: {
            ...flowValues,
          },
        },
        meta: mergedMeta,
      }))
    }
  }

  setThrottle = (time = 1000) => {
    const timeout = setTimeout(() => {
      this.setState({ loadMoreThrottle: false })
    }, time)

    this.setState({
      loadMoreThrottle: true,
      timeout,
    })
  }

  fetchInitialData = () => {
    const {
      dispatch,
      initialQueries,
    } = this.props

    const currentQueries = omit(parseUrlQueries(), 'after_cursor')
    const pagination = get(currentQueries, 'pagination') === 'true'

    // when on infinite scroll view reset offset back to 0
    // note that when hitting back button from resource view containing a table back to table view, the offset will be whatever the resource view table offset was
    const updatedQueries = merge({}, initialQueries, currentQueries, {
      offset: pagination ? get(currentQueries, 'offset') : 0,
      pagination,
    })

    this.setThrottle()

    this.fetchData({
      initialFetch: true,
      queries: !isEmpty(currentQueries) ? updatedQueries : initialQueries,
      meta: {
        overwriteReducer: true,
      },
    })

    dispatch({ type: TOGGLE_PAGINATION, payload: { status: pagination } })
  }

  fetchPaginationData = ({ flow, queries }) => {
    const {
      dispatch,
      credentials,
      first,
      prev,
      next,
      last,
      currentQueries,
      credentialsPage,
      dashboardServiceV2,
    } = this.props

    const currentOffset = parseInt(get(currentQueries, 'offset'), 10)
    const currentLimit = parseInt(get(currentQueries, 'limit'), 10)

    if (flow) {
      const state = getState()
      const pathName = get(state, PATHNAME)
      const pageName = getPageName(pathName, currentQueries)

      sendAmplitudeActionEvent(NAVIGATE_TABLE_PAGE, {
        credentials,
        page: pageName,
        button: flow,
      })
    }

    // TODO: in the future, we can get rid of credentialsPage check when dashboard service is finished
    if (flow === FIRST) {
      queries = !credentialsPage || dashboardServiceV2 ? merge(currentQueries, { offset: 0 }) : parseUrlQueries(first, frontendNormalizationMap)
    }

    if (flow === PREV) {
      queries = !credentialsPage || dashboardServiceV2 ? merge(currentQueries, { offset: currentOffset - currentLimit }) : parseUrlQueries(prev, frontendNormalizationMap)
    }

    if (flow === NEXT) {
      queries = !credentialsPage || dashboardServiceV2 ? merge(currentQueries, { offset: currentOffset + currentLimit }) : parseUrlQueries(next, frontendNormalizationMap)
    }

    if (flow === LAST) {
      const lastOffset = get(parseUrlQueries(last, frontendNormalizationMap), 'offset')
      queries = !credentialsPage || dashboardServiceV2 ? merge(currentQueries, { offset: lastOffset }) : parseUrlQueries(last, frontendNormalizationMap)
    }

    this.fetchData({
      queries,
      meta: {
        overwriteReducer: true,
      },
    })

    // reducers get cleared after fetch data, so must reset pagination to true in order to stay on pagination view
    dispatch({
      type: TOGGLE_PAGINATION,
      payload: { status: true },
    })
  }

  fetchNextData = () => {
    const {
      next,
      initialQueries,
      isFetching,
      credentials,
      currentFilters,
      nextCursor,
    } = this.props

    const queries = next ? parseUrlQueries(next, frontendNormalizationMap) : omit({ ...parseUrlQueries(), after_cursor: nextCursor }, ['tab', 'offset'])
    const { loadMoreThrottle } = this.state
    const mergedQueries = merge({}, initialQueries, queries)

    // seems as if infiniteScroll's built in throttle is NOT working, so I put my own in...
    // without this it will send off multiple request (1 to 2 extra)
    // perhaps we can solve this at some point
    if (!loadMoreThrottle && !isFetching && typeof window.Cypress === 'undefined') {
      this.setThrottle()
      this.fetchData({ queries: mergedQueries })

      const state = getState()
      const pathName = get(state, PATHNAME)
      const pageName = getPageName(pathName, queries)
      const filterNames = map(currentFilters, (currentFilter, name) => name)

      sendAmplitudeActionEvent(TABLE_LOAD_MORE, {
        credentials,
        page: pageName,
        queries,
        filters: filterNames,
        filter: currentFilters,
      })
    }
  }

  toggleFilters = () => {
    const { state } = this
    const { showFilters } = state

    this.setState({
      showFilters: !showFilters,
    })
  }

  toggleSort = (itemSorts, sortOrder) => {
    const { initialQueries } = this.props
    const inverseSortOrder = sortOrder === 'desc' ? 'asc' : 'desc'
    const inverseSort = get(itemSorts, inverseSortOrder)
    const queries = merge({}, initialQueries, { sort: inverseSort })

    this.setState({
      sort: inverseSort,
    })

    this.fetchData({
      queries,
      meta: {
        overwriteReducer: true,
      },
    })
  }

  handleDownloadCSV = () => {
    const {
      items,
      fileNameCSV,
    } = this.props

    const data = flatten(
      map(items, ({ attributes }) => {
        const normalizedAttributes = filter(attributes, ({ key }) => !isEmpty(key))
        return [generateExportObjectFromItemAttributes(normalizedAttributes)]
      }),
    )

    const state = getState()
    const pathName = get(state, PATHNAME)
    const search = get(state, SEARCH)
    const queries = parseUrlQueries(search)
    const pageName = getPageName(pathName, queries)

    sendAmplitudeActionEvent(EXPORT_CSV, {
      pageName,
      fileNameCSV,
    })

    printCSV(data, fileNameCSV)
  }

  showAdvancedExportModal = () => {
    const {
      items,
      dispatch,
      fileNameCSV,
      orderedData,
    } = this.props

    dispatch(
      showModalAction({
        modalType: EXPORT_DATA,
        modalProps: {
          data: orderedData,
          fileNameCSV,
          items,
        },
      }),
    )
  }

  showExportV2Modal = () => {
    const {
      items,
      dispatch,
      fileNameCSV,
      fileResourceTitleCSV,
      orderedData,
      currentFilters,
      entityType,
      hiddenExportFields,
      additionalExportValues,
    } = this.props

    dispatch(
      showModalAction({
        modalType: EXPORT_DATA_V2,
        modalProps: {
          data: orderedData,
          fileNameCSV,
          items,
          fileResourceTitleCSV,
          title: `Export ${fileResourceTitleCSV ? fileResourceTitleCSV : fileNameCSV}`,
          currentFilters,
          entityType,
          hiddenExportFields,
          additionalExportValues,
        },
      }),
    )
  }

  updateLimitCount = () => {
    const {
      credentials,
      limitCount,
      pagination,
      currentQueries,
    } = this.props

    const state = getState()
    const pathName = get(state, PATHNAME)
    const pageName = getPageName(pathName, currentQueries)
    const updatedQueries = merge({}, currentQueries, { limit: limitCount, offset: '0' })

    sendAmplitudeActionEvent(UPDATE_TABLE_LIMIT, {
      credentials,
      page: pageName,
      value: limitCount,
    })

    if (pagination) {
      this.fetchPaginationData({ queries: updatedQueries })
    } else {
      this.fetchData({
        queries: updatedQueries,
        meta: { overwriteReducer: true },
      })
    }
  }

  render() {
    const {
      sort,
      showFilters,
      height,
    } = this.state

    return (
      <Table
        {...this.props}
        sort={sort}
        showFilters={showFilters}
        limitOptions={limitOptions}
        toggleSort={this.toggleSort}
        toggleFilters={this.toggleFilters}
        fetchNextData={this.fetchNextData}
        handleDownloadCSV={this.handleDownloadCSV}
        showAdvancedExportModal={this.showAdvancedExportModal}
        showExportV2Modal={this.showExportV2Modal}
        fetchInitialData={this.fetchInitialData}
        fetchPaginationData={this.fetchPaginationData}
        tableContentHeight={`${height}px`}
      />
    )
  }
}

export default connect(mapStateToProps)(TableC)
