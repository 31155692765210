import API from 'utilities/api/API'
import createUrl from 'utilities/create/createUrl'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import getGuestDashboardAxiosConfigs from './getGuestDashboardAxiosConfigs'

const guestDashboardAPI = {
  // TODO: add ability to mock guesDashboardAPI requests as well
  get: ({ path, absolutePath, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return API.get(url, getGuestDashboardAxiosConfigs())
  },

  post: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })
    return API.post(url, values, getGuestDashboardAxiosConfigs())
  },

  patch: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return API.patch(url, values, getGuestDashboardAxiosConfigs())
  },

  put: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return API.put(url, values, getGuestDashboardAxiosConfigs())
  },

  delete: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return API.delete(url, values, getGuestDashboardAxiosConfigs())
  },
}

export default guestDashboardAPI
