import amplitude from 'amplitude-js/amplitude'
import moment from 'moment'
import { DEV_MODE } from 'config'
import removeUndefined from 'utilities/remove/removeUndefined'
import isAdministratorView from 'utilities/is/isAdministratorView'
import { API } from 'constants/amplitudeConstants'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import uuidv4 from 'uuid/v4'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

// this is a list of all things we DEFINITELY do not want to send, usually for security purposes
const propsToOmit = [
  'credentials', // contains username / password info
]

const STAGING_API_KEY = '9428bb671d57a7862529b78a75bd91d2'
const PROD_API_KEY = 'f35b544c01c606a847887dd33e1be421' // old enterprise key: 498cd6c37be74a1aa1d1c854c0458cdb
const amplitudeKey = DEV_MODE ? STAGING_API_KEY : PROD_API_KEY

const currentInstance = () => {
  // const instance = get(getCurrentPlatform(), 'amplitudeInstance')
  //
  // return instance ? amplitude.getInstance(instance) : amplitude.getInstance()
  return amplitude
}

export const setAmplitudeUserId = (userId) => currentInstance().setUserId(userId)

export const setAmplitudeUserDevice = (installationToken) => currentInstance().setDeviceId(installationToken)

export const setAmplitudeUserProperties = (properties = {}) => currentInstance().setUserProperties(properties)

export const createActionID = () => uuidv4()

export const initAmplitude = (user, platform) => {
  const instance = currentInstance()

  if (!isEmpty(instance)) {
    instance.init(amplitudeKey)
    instance.setGroup('platform', platform)

    if (!isEmpty(user)) {
      const roles = get(user, 'roles')
      const userID = get(user, 'user_id')
      const sub = get(user, 'sub')
      const auth0ID = userID || sub

      setAmplitudeUserId(auth0ID)

      setAmplitudeUserProperties({
        roles,
        platform,
      })
    }
  }
}

export const sendAmplitudeEvent = (eventType, eventProperties = {}) => {
  const credentials = get(eventProperties, 'credentials')
  const credentialId = get(credentials, 'id')
  const isRunningJestTest = typeof jest !== 'undefined'
  const isRunningCypress = typeof window.Cypress !== 'undefined'
  const shouldSendAmplitudeEvents = !isRunningJestTest && !isRunningCypress
  const timestamp = moment.utc().format()

  const mergedEventProps = merge({}, eventProperties, {
    credentialId,
    timestamp,
  })

  const cleanedEventProperties = removeUndefined(omit(mergedEventProps, propsToOmit))

  // TODO: unlock when ready for production testing
  if (shouldSendAmplitudeEvents) {
    const instance = currentInstance()

    if (!isEmpty(instance)) {
      instance.logEvent(eventType, cleanedEventProperties)
    }
  }

  // send amplitude events to appcues for tracking
  if (window.Appcues) window.Appcues.track(eventType)
}

export const sendAmplitudePageViewEvent = (eventProperties = {}, pathname = '') => {
  const { page } = eventProperties

  if (isAdministratorView(pathname)) {
    return
  }

  const eventName = page ? `${page} Viewed` : 'Unknown Page Viewed'

  // eslint-disable-next-line no-underscore-dangle
  const _hsq = window._hsq || []

  if (_hsq) {
    _hsq.push(['setPath', window.location.pathname + window.location.search])
    _hsq.push(['trackPageView'])
  }

  sendAmplitudeEvent(eventName, eventProperties)
}

export const sendAmplitudeActionEvent = (eventType, eventProperties = {}) => {
  const actionId = createActionID()
  const actionProperties = { actionId, ...eventProperties }
  const isRunningJestTest = typeof jest !== 'undefined'

  if (isRunningJestTest) { return 'test' }

  sendAmplitudeEvent(eventType, actionProperties)

  return actionId
}

// actionId can either be sent as extra param or pre-set into the eventProperties
export const sendAmplitudeApiEvent = (eventType, eventProperties = {}, actionId) => {
  const actionProperties = { actionId, ...eventProperties }

  sendAmplitudeEvent(`${API} ${eventType}`, actionProperties)

  return actionId
}
