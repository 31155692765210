import { getState } from 'state-layer/configureStore'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SEARCH_USER as SEARCH_USER_ACTION } from 'constants/amplitudeConstants'
import _values from 'lodash/values'
import first from 'lodash/first'
import get from 'lodash/get'

import {
  GET_DASHBOARD_USER_F_REQUEST,
  GET_DASHBOARD_USER_F_SUCCESS_NOTIFICATION,
  GET_DASHBOARD_USER_F_FAILURE_NOTIFICATION,
} from 'constants/flowConstants'

import {
  SEARCH_USER,
  SEND_EMAIL,
} from 'constants/modalConstants'

const submitSearchUserForm = (values, dispatch, props) => {
  const { email = '' } = values
  const state = getState()
  const credentials = getCurrentCredentials(state)
  const applicationId = get(props, 'applicationId')

  dispatch({
    type: GET_DASHBOARD_USER_F_REQUEST,
    payload: {
      queries: {
        email_address: email.toLowerCase(),
      },
    },
    meta: {
      successCallback: ({ newValues }) => {
        const user = first(_values(newValues))
        const userEmail = get(user, 'email', undefined)

        if (userEmail === undefined) {
          dispatch(showModalAction({
            modalType: SEARCH_USER,
            modalProps: {
              retryMessage: true,
            },
          }))

          dispatch({
            type: GET_DASHBOARD_USER_F_FAILURE_NOTIFICATION,
          })
        } else {
          dispatch(showModalAction({
            modalType: SEND_EMAIL,
            modalProps: { user, applicationId },
          }))

          dispatch({
            type: GET_DASHBOARD_USER_F_SUCCESS_NOTIFICATION,
          })
        }
      },
      actionId: sendAmplitudeActionEvent(SEARCH_USER_ACTION, {
        email,
        credentials,
      }),
    },
  })
}

export default submitSearchUserForm
