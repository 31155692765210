import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { createdAtRange } from 'utilities/table/sort'
import { enabledTruthyMap } from 'constants/statusConstants'
import toString from 'lodash/toString'

import {
  ID,
  CREATED_ON,
  NAME,
  MASKED_NUMBER,
  EXP_DATE_OR_ROUTING_NUM,
  TYPE,
  CARD_BRAND,
  STATE,
  ENABLED,
  DISABLED,
  COUNTRY,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NAME, 'name', { className: 'name' }),
  column(TYPE, 'type', {
    headerFormatter: () => <TableItemSubtitle title={TYPE} subtitle={`${CARD_BRAND}/${TYPE}`} />,
    formatter: ({ type, brand = '', cardType = '' }) => {
      return <TableItemSubtitle title={type} subtitle={`${snakeCaseToTitleCase({ key: brand })} ${cardType}`} />
    },
  }),
  column(MASKED_NUMBER, ['maskedFullCardNumber', 'maskedAccountNumber'], {
    headerFormatter: () => <TableItemSubtitle title={MASKED_NUMBER} subtitle={EXP_DATE_OR_ROUTING_NUM} />,
    formatter: (paymentInstrument) => {
      const {
        maskedFullCardNumber,
        maskedAccountNumber,
        expirationDate,
        bankCode,
        canadianRoutingNumber,
      } = paymentInstrument

      return <TableItemSubtitle title={maskedFullCardNumber || maskedAccountNumber} subtitle={expirationDate || bankCode || canadianRoutingNumber} />
    },
  }),
  column(COUNTRY, 'displayIssuerCountry', {
    formatter: (paymentInstrument) => {
      const {
        displayIssuerCountry,
        displayCountry,
        type,
      } = paymentInstrument

      return type === 'Bank Account' ? displayCountry : displayIssuerCountry
    },
  }),
  column(STATE, 'enabled', {
    className: 'state',
    formatter: ({ enabled }) => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
  }),
]

export default columnDescriptors
