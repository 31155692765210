import React, { Component } from 'react'
import { connect } from 'react-redux'
import Recipients from './Recipients'
import { FETCHING } from 'constants/reducerConstants'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import isMerchantManagerRole from 'utilities/is/isMerchantManagerRole'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'

import {
  ADD_RECIPIENT_PATH,
  RECIPIENT_IDENTITIES_PATH,
} from 'constants/pathConstants'

import {
  ADD_RECIPIENT,
  IDENTITY,
} from 'constants/language/languageConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(IDENTITY),
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  DATE_QUICK_FILTER,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const identitiesPath = RECIPIENT_IDENTITIES_PATH({ credentialId })
  const applicationId = get(props, 'applicationId')

  const actions = [
    {
      label: ADD_RECIPIENT,
      link: ADD_RECIPIENT_PATH({ credentialId }),
      className: 'addRecipient',
      condition: isMerchantManagerRole() || isAdministratorRole(),
    },
  ]

  return {
    quickFilters,
    allowedFilters,
    initialQueries: applicationId ? merge({}, initialQueries, { application_id: applicationId }) : initialQueries,
    isFetching,
    identitiesPath,
    actions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}


class RecipientsC extends Component {
  render() {
    return (
      <Recipients
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsC)
