import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'

import {
  t,
  CANCEL,
  DEACTIVATE,
  DEACTIVATE_PAYMENT_LINK_MODAL_TEXT,
  DEACTIVATE_PAYMENT_LINK,
} from 'constants/language/languageConstants'

const DisablePaymentLinkModal = ({
  isPatching = false,
  closeModal = () => {},
  disablePaymentLink = () => {},
}) => {
  return (
    <GenericModal title={DEACTIVATE_PAYMENT_LINK}>
      <div className='modal-content'>
        <p className='p-1'>{t(DEACTIVATE_PAYMENT_LINK_MODAL_TEXT)}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} onClick={closeModal} />
        <Button type='submit' variant='destructive' label={DEACTIVATE} onClick={disablePaymentLink} disabled={isPatching} />
      </div>
    </GenericModal>
  )
}

DisablePaymentLinkModal.propTypes = {
  isPatching: PropTypes.bool,
  closeModal: PropTypes.func,
  disablePaymentLink: PropTypes.func,
}

export default DisablePaymentLinkModal
