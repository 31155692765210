import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'

const frontendSettlementGroupModel = ({ data: settlementGroup }) => {
  const [
    id,
    createdAt,
    settlements,
  ] = getMany(settlementGroup, [
    'id',
    'created_at',
    'settlements',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    settlements,
  })
}

export default frontendSettlementGroupModel
