import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import AddEditBuyerInformationFormC from 'components/Customer/Forms/AddEditBuyerInformationForm/AddEditBuyerInformationFormC'

import {
  ADD_BUYER_INFORMATION,
  EDIT_BUYER_INFORMATION,
} from 'constants/language/languageConstants'

const AddEditBuyerInformationModal = ({
  identityId,
}) => {
  return (
    <GenericModal
      className='AddEditBuyerInformationModal'
      title={identityId ? EDIT_BUYER_INFORMATION : ADD_BUYER_INFORMATION}
      Component={AddEditBuyerInformationFormC}
      identityId={identityId}
    />
  )
}

AddEditBuyerInformationModal.propTypes = {
  identityId: PropTypes.string,
}

export default AddEditBuyerInformationModal
