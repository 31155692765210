import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  SHOW_HIGHLIGHT,
  HIDE_HIGHLIGHT,
  PREV_HIGHLIGHT,
  NEXT_HIGHLIGHT,
} from 'constants/flowConstants'

const highlightR = (state, action) => {
  switch (action.type) {
    case SHOW_HIGHLIGHT: {
      const highlights = get(action, 'payload.values')
      const index = get(action, 'payload.index', 0)

      return merge({}, state, {
        index,
        highlights,
      })
    }

    case PREV_HIGHLIGHT: {
      const index = get(state, 'index', 0) - 1

      return merge({}, state, { index })
    }

    case NEXT_HIGHLIGHT: {
      const index = get(state, 'index', 0) + 1

      return merge({}, state, { index })
    }

    case HIDE_HIGHLIGHT:
      return {}

    default:
      return merge({}, state || {})
  }
}

export default highlightR
