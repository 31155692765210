import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORM } from 'constants/apiConstants'

const getAccessFormAPI = ({ id, credentials, meta }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_ACCESS_FORM({ id }),
    meta,
  })
}

export default getAccessFormAPI
