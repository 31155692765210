import { GET_AUDIT_LOG_EVENT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAuditLogEventRequest = ({
  eventId,
  credentials,
  meta,
}) => createAction({
  type: GET_AUDIT_LOG_EVENT_F_REQUEST,
  id: eventId,
  credentials,
  meta,
})

export default getAuditLogEventRequest
