import selectOption from 'utilities/forms/selectOption'

import {
  UPLOADED as UPLOADED_LABEL,
  UPLOADING,
} from './language/languageConstants'

export const TYPE_TIFF = 'image/tiff'
export const TYPE_JPEG = 'image/jpeg'
export const TYPE_JPG = 'image/jpg'
export const TYPE_PNG = 'image/png'
export const TYPE_PDF = 'application/pdf'
export const TYPE_CSV = 'csv'

export const SUPPORTED_FILE_VIEWER_TYPES = [
  TYPE_TIFF,
  TYPE_JPEG,
  TYPE_JPG,
  TYPE_PNG,
  TYPE_PDF,
]

export const FILE_EXTENSION_TO_FILE_TYPE_MAP = {
  tiff: TYPE_TIFF,
  jpeg: TYPE_JPEG,
  jpg: TYPE_JPG,
  png: TYPE_PNG,
  pdf: TYPE_PDF,
  csv: TYPE_CSV,
}

export const SUPPORTED_FILE_UPLOAD_DOCUMENT_TYPES = [
    selectOption('Select Document Type', ''),
    selectOption('Drivers License Front', 'DRIVERS_LICENSE_FRONT'),
    selectOption('Drivers License Back', 'DRIVERS_LICENSE_BACK'),
    selectOption('Identification Card Front', 'IDENTIFICATION_CARD_FRONT'),
    selectOption('Identification Card Back', 'IDENTIFICATION_CARD_BACK'),
    selectOption('Passport', 'PASSPORT'),
    selectOption('Bank Statement', 'BANK_STATEMENT'),
    selectOption('Tax Document', 'TAX_DOCUMENT'),
    selectOption('Business Registration', 'BUSINESS_REGISTRATION'),
    selectOption('Business Address Verification', 'BUSINESS_ADDRESS_VERIFICATION'),
    selectOption('Other', 'OTHER'),
    selectOption('PCI Document', 'PCI_DOCUMENT'),
]


export const UPLOADED = 'UPLOADED'
export const REQUIRES_UPLOAD = 'REQUIRES_UPLOAD'

export const fileStatusToLabel = (status) => {
  const labels = {
    [UPLOADED]: UPLOADED_LABEL,
    [REQUIRES_UPLOAD]: UPLOADING,
  }

  return labels[status]
}

export const fileStatusToIcon = (status) => {
  const icons = {
    [UPLOADED]: 'fa-check-circle',
    [REQUIRES_UPLOAD]: 'fa-spinner fa-spin',
  }

  return icons[status]
}

// file type enums
export const DRIVERS_LICENSE_FRONT = 'DRIVERS_LICENSE_FRONT'
export const IDENTIFICATION_CARD_FRONT = 'IDENTIFICATION_CARD_FRONT'
export const IDENTIFICATION_CARD_BACK = 'IDENTIFICATION_CARD_BACK'
export const PASSPORT = 'PASSPORT'
export const TAX_DOCUMENT = 'TAX_DOCUMENT'
export const BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION'
export const BUSINESS_ADDRESS_VERIFICATION = 'BUSINESS_ADDRESS_VERIFICATION'
export const BANK_STATEMENT = 'BANK_STATEMENT'
export const OTHER = 'OTHER'
export const PCI_DOCUMENT = 'PCI_DOCUMENT'
export const DEVICE_IDLE_IMAGE = 'DEVICE_IDLE_IMAGE'
