import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import VoidAuthorizationC from 'components/Customer/Pages/VoidAuthorization/VoidAuthorizationC'
import { VOID_AUTHORIZATION } from 'constants/language/languageConstants'

const VoidAuthorizationModal = ({
  authorizationId = '',
  displayAuthorizationAmount = '',
}) => {
  return (
    <GenericModal
      title={VOID_AUTHORIZATION}
      className='VoidAuthorizationModal'
      Component={VoidAuthorizationC}
      authorizationId={authorizationId}
      displayAuthorizationAmount={displayAuthorizationAmount}
    />
  )
}

VoidAuthorizationModal.propTypes = {
  authorizationId: PropTypes.string,
  displayAuthorizationAmount: PropTypes.string,
}

export default VoidAuthorizationModal
