import './AddDeviceReviewFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import submitAddDeviceForm from 'utilities/submit/submitAddDeviceForm'
import submitAddDeviceWithActivationForm from 'utilities/submit/submitAddDeviceWithActivationForm'
import { ADD_NEW_DEVICE_REVIEW_FORM } from 'constants/formConstants'
import { TRIPOS_CLOUD_V1 } from 'constants/processorConstants'

import {
  CANCEL,
  REVIEW_DEVICE_DETAILS,
  GENERAL_INFO,
  DEVICE_CONFIGURATION,
  ADD_AND_ACTIVATE_DEVICE,
  ADD_DEVICE_WITHOUT_ACTIVATING,
  EDIT,
  ADD_DEVICE_REVIEW_FORM_SUBTITLE,
  ADD_DEVICE,
} from 'constants/language/languageConstants'

const AddDeviceReviewForm = ({
  handleSubmit = () => {},
  isFetching = false,
  generalInfoSectionData = [],
  deviceConfigurationsSectionData = [],
  merchantGateway = '',
  goToStep = () => {},
  showDeviceConfigurationsSection = true,
  showOnlyAddDeviceButtons = false,
}) => {
  return (
  // TODO: Cata - remove the check for TRIPOS_CLOUD_V1 below when bug fix BE-15443 is ready
    <form className='AddDeviceReviewForm' onSubmit={handleSubmit(submitAddDeviceForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{REVIEW_DEVICE_DETAILS}</h3>
        <div className='subtitle p-1 secondary'>{ADD_DEVICE_REVIEW_FORM_SUBTITLE}</div>
      </div>

      <div className='form-content'>
        <PageSectionV2
          smallTitle={GENERAL_INFO}
          className='general-info'
          columns={generalInfoSectionData}
          actions={[{ label: EDIT, action: () => goToStep(0) }]}
        />

        { showDeviceConfigurationsSection && (
          <PageSectionV2
            smallTitle={DEVICE_CONFIGURATION}
            className='device-configuration'
            columns={deviceConfigurationsSectionData}
            actions={[{ label: EDIT, action: () => goToStep(0) }]}
          />
        )}
      </div>

      <div className='form-footer flex'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            { showOnlyAddDeviceButtons ? undefined : (<Button onClick={goBack} className='cancel-button' label={CANCEL} variant='tertiary' />) }
          </div>
          { showOnlyAddDeviceButtons ? (
            <div className='exit-and-save-container'>
              <Button onClick={goBack} className='cancel-button' label={CANCEL} variant='ghost' />
              <Button type='submit' className='submit-button' disabled={isFetching} submitting={isFetching} label={ADD_DEVICE} />
            </div>
          ) : (
            <div className='exit-and-save-container'>
              <Button type='submit' className='submit-without-activation-button' submitting={isFetching} label={ADD_DEVICE_WITHOUT_ACTIVATING} variant='ghost' />
              <Button type='button' className='add-and-activate-button' disabled={isFetching} submitting={isFetching} label={ADD_AND_ACTIVATE_DEVICE} onClick={handleSubmit(submitAddDeviceWithActivationForm)} />
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

AddDeviceReviewForm.propTypes = {
  isFetching: PropTypes.bool,
  generalInfoSectionData: PropTypes.array,
  deviceConfigurationsSectionData: PropTypes.array,
  handleSubmit: PropTypes.func,
  merchantGateway: PropTypes.string,
  goToStep: PropTypes.func,
  showDeviceConfigurationsSection: PropTypes.bool,
  showOnlyAddDeviceButtons: PropTypes.bool,
}

export default reduxForm({
  form: ADD_NEW_DEVICE_REVIEW_FORM,
})(AddDeviceReviewForm)
