import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/ACHReturns/columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { ACH_RETURNS_ICON } from 'constants/iconConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

import {
  ACH_RETURNS_EMPTY_TABLE_MESSAGE,
  ACH_RETURNS_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

const IdentityACHReturns = ({
  paymentPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters,
}) => {
  return (
    <div className='IdentityACHReturns'>
      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={ACH_RETURNS_RESOURCE_TITLE}
        entityType={TRANSFER}
        icon={`fa fa-${ACH_RETURNS_ICON}`}
        emptyMessage={ACH_RETURNS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

IdentityACHReturns.propTypes = {
  paymentPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
}

export default IdentityACHReturns
