import { fieldEmpty, invalidOrEmptyRegex } from 'utilities/validate'
import { EMAIL_REGEX } from 'constants/regexConstants'
import { EMAIL_ADDRESS } from 'constants/language/languageConstants'

const validateTeamMemberForm = (values, props) => {
  const {
    dashboardAccountName,
    email,
    rolesChoice,
    roleName,
    roleDescription,
    selectedEvents,
  } = values

  const { memberId } = props

  return {
    dashboardAccountName: fieldEmpty(dashboardAccountName, 'Dashboard Account Name'),
    email: !memberId && invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
    rolesChoice: fieldEmpty(rolesChoice, 'Role'),
    roleName: rolesChoice === 'CUSTOM_ROLE' ? fieldEmpty(roleName, 'Role Name') : false,
    roleDescription: rolesChoice === 'CUSTOM_ROLE' ? fieldEmpty(roleDescription, 'Role Description') : false,
    selectedEvents: rolesChoice === 'CUSTOM_ROLE' ? fieldEmpty(selectedEvents, 'Selected Events') : false,
  }
}

export default validateTeamMemberForm
