import './AddDeviceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import validateAddDeviceForm from 'utilities/validate/validateAddDeviceForm'
import parseSerialNumber from 'utilities/forms/parse/parseSerialNumber'
import { PAYMENT_DEVICES_LEARN_MORE_LINK } from 'constants/urlConstants'
import { USD } from 'constants/currencyConstants'
import { ADD_NEW_DEVICE_FORM } from 'constants/formConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import filter from 'lodash/filter'

import {
  AMOUNT_VALUE,
  ON_THRESHOLD_AMOUNT_VALUE,
  PROMPT_FOR_SIGNATURE_OPTIONS,
  PROMPT_SIGNATURE_OPTIONS,
} from 'constants/deviceConstants'

import {
  CANCEL,
  LEARN_MORE_LABEL,
  DEVICE_NAME,
  DESCRIPTION,
  SERIAL_NUMBER,
  MODEL,
  REVIEW_DEVICE_DETAILS,
  DEVICE_CONFIGURATION,
  DEVICE_CONFIGURATION_SUBTITLE,
  DEVICE_NAME_PLACEHOLDER,
  DESCRIPTION_FOR_DEVICE_PLACEHOLDER,
  DEVICE_SERIAL_NUMBER_PLACEHOLDER,
  SELECT,
  PROMPT_SIGNATURE,
  SIGNATURE_THRESHOLD_AMOUNT,
  SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG,
  PROMPT_SIGNATURE_TOOLTIP_MESSAGE,
  ADD_A_DEVICE,
  ADD_A_DEVICE_SUBTITLE,
  TIPPING_SETTINGS,
  PERCENTAGE_TIP_OPTIONS,
  OPTIONAL_SETTINGS,
  PERCENTAGE_TIPPING_THRESHOLD_LABEL,
  THRESHOLD_FIXED_AMOUNT_OPTIONS_LABEL,
  PROMPT_RECEIPT_CONFIRMATION,
  PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
  TIP_COLLECTION_ON_TERMINAL,
  RECEIPT_SETTINGS,
  RECEIPT_DELIVERY_METHODS_LABEL,
  EMAIL,
  PRINT,
  TEXT,
  SUPPORTED_AUTOMATIC_DELIVERY_METHODS_LABEL,
  RECEIPT_DELIVERY_METHODS_TOOLTIP,
  SUPPORTED_AUTOMATIC_DELIVERY_METHODS_TOOLTIP,
  TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const AddDeviceForm = ({
  handleSubmit = () => {},
  invalid = false,
  modelValue,
  deviceModelSelectOptions = [],
  deviceConfigurationSelectOptions = [],
  promptSignatureValue,
  isFetchingMerchantData = false,
  showDeviceConfigurations = false,
  isUpdating = false,
  hideSerialNumber = false,
  merchantGateway = '',
  showTippingSettings = false,
  showPromptReceiptConfirmationSettings = false,
  showReceiptAndTipConfigOptions = false,
  showReceiptSettingsError = false,
  currentReceiptDeliveryPrint = true,
  currentReceiptDeliveryEmail = true,
  currentReceiptDeliverySms = true,
  currentAutomaticDeliveryPrint = true,
  currentAutomaticDeliveryEmail = true,
  currentAutomaticDeliverySms = true,
  showPromptForSignatureField = false,
  showPromptSignatureField = false,
  promptForSignatureValue = false,
}) => {
  return (
    <form className='AddDeviceForm' onSubmit={handleSubmit}>
      <div className='form-header p-1'>
        <h3 className='title'>{ADD_A_DEVICE}</h3>
        <div className='p-1'>{ADD_A_DEVICE_SUBTITLE} <a className='text-link' href={PAYMENT_DEVICES_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a></div>
      </div>

      {isFetchingMerchantData && <Loader />}

      {!isFetchingMerchantData && (
        <div className='form-content'>
          <Field
            name='deviceName'
            label={DEVICE_NAME}
            component={InputField}
            placeholder={DEVICE_NAME_PLACEHOLDER}
          />

          <Field
            name='description'
            label={DESCRIPTION}
            component={InputField}
            placeholder={DESCRIPTION_FOR_DEVICE_PLACEHOLDER}
            required={false}
          />

          {!hideSerialNumber && (
            <Field
              name='serialNumber'
              label={SERIAL_NUMBER}
              component={InputField}
              parse={parseSerialNumber}
              placeholder={DEVICE_SERIAL_NUMBER_PLACEHOLDER}
            />
          )}

          <Field
            name='model'
            label={MODEL}
            component={ReactSelect}
            options={deviceModelSelectOptions}
            placeholder={SELECT}
          />

          {showDeviceConfigurations && modelValue && !isEmpty(deviceConfigurationSelectOptions) && (
            <div className='device-configuration-section'>
              <h5>{DEVICE_CONFIGURATION}</h5>
              <div className='device-configuration-subtitle'>{DEVICE_CONFIGURATION_SUBTITLE}</div>

              {map(filter(deviceConfigurationSelectOptions, ({ visibleToCustomers, disabledForGateways }) => visibleToCustomers && !includes(disabledForGateways, merchantGateway)), ({ name, label, tooltip }) => {
                return (
                  <Field
                    className='device-config-toggle-field'
                    name={name}
                    label={label}
                    component={ToggleSwitchC}
                    tooltip={tooltip}
                    tooltipPosition='right'
                    formName={ADD_NEW_DEVICE_FORM}
                  />
                )
              })}

              {(!isFetchingMerchantData && showReceiptAndTipConfigOptions) && (
                <>
                  <Field
                    name='promptReceiptConfirmation'
                    className='prompt-receipt-confirmation-toggle'
                    label={PROMPT_RECEIPT_CONFIRMATION}
                    component={ToggleSwitchC}
                    tooltip={PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG}
                    tooltipPosition='right'
                    formName={ADD_NEW_DEVICE_FORM}
                  />

                  {showPromptReceiptConfirmationSettings && (
                  <div className='receipt-confirmation-settings'>
                    <h6>{RECEIPT_SETTINGS}</h6>
                    {showReceiptSettingsError && <ErrorMessage errorMessage='Please select one receipt confirmation method.' />}

                    <span className='flex row'><p>{RECEIPT_DELIVERY_METHODS_LABEL}</p><TooltipLabelC message={RECEIPT_DELIVERY_METHODS_TOOLTIP} position='top' /></span>
                    <div className='delivery-methods'>
                      <Field
                        name='receiptDeliveryPrint'
                        label={PRINT}
                        component={CheckboxC}
                        checked={currentReceiptDeliveryPrint}
                      />

                      <Field
                        name='receiptDeliveryEmail'
                        label={EMAIL}
                        component={CheckboxC}
                        checked={currentReceiptDeliveryEmail}
                      />

                      <Field
                        name='receiptDeliverySms'
                        label={TEXT}
                        component={CheckboxC}
                        checked={currentReceiptDeliverySms}
                      />
                    </div>

                    <span className='flex row'><p>{SUPPORTED_AUTOMATIC_DELIVERY_METHODS_LABEL}</p><TooltipLabelC message={SUPPORTED_AUTOMATIC_DELIVERY_METHODS_TOOLTIP} position='top' /></span>
                    <div className='automatic-delivery-options'>
                      <Field
                        name='automaticDeliveryPrint'
                        label={PRINT}
                        component={CheckboxC}
                        checked={currentAutomaticDeliveryPrint}
                      />

                      <Field
                        name='automaticDeliveryEmail'
                        label={EMAIL}
                        component={CheckboxC}
                        checked={currentAutomaticDeliveryEmail}
                      />

                      <Field
                        name='automaticDeliverySms'
                        label={TEXT}
                        component={CheckboxC}
                        checked={currentAutomaticDeliverySms}
                      />
                    </div>
                  </div>
                  )}

                  <Field
                    name='promptTipOnScreen'
                    className='prompt-tip-on-screen-toggle'
                    label={TIP_COLLECTION_ON_TERMINAL}
                    component={ToggleSwitchC}
                    tooltip={TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG}
                    tooltipPosition='right'
                    formName={ADD_NEW_DEVICE_FORM}
                  />

                  {showTippingSettings && (
                    <div className='tipping-settings'>
                      <h6>{TIPPING_SETTINGS}</h6>
                      <div className='tipping-percentages'>
                        <p>{PERCENTAGE_TIP_OPTIONS}</p>
                        <div className='flex row'>
                          <PercentageField
                            name='percentageOne'
                          />
                          <PercentageField
                            name='percentageTwo'
                          />
                          <PercentageField
                            name='percentageThree'
                          />
                        </div>
                      </div>

                      <div className='tipping-optional-settings'>
                        <h6>{OPTIONAL_SETTINGS}</h6>
                        <AmountField
                          name='percentTippingThreshold'
                          label={PERCENTAGE_TIPPING_THRESHOLD_LABEL}
                          placeholder='5.00'
                          required={false}
                        />

                        <p>{THRESHOLD_FIXED_AMOUNT_OPTIONS_LABEL}</p>
                        <div className='flex row'>
                          <AmountField
                            name='amountOne'
                            placeholder='1.00'
                          />
                          <AmountField
                            name='amountTwo'
                            placeholder='1.50'
                          />
                          <AmountField
                            name='amountThree'
                            placeholder='2.00'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {showPromptSignatureField && (
                <>
                  <Field
                    name='promptSignature'
                    label={PROMPT_SIGNATURE}
                    component={ReactSelect}
                    options={PROMPT_SIGNATURE_OPTIONS}
                    tooltip={PROMPT_SIGNATURE_TOOLTIP_MESSAGE}
                    tooltipPosition='right'
                  />

                  {isEqual(promptSignatureValue, AMOUNT_VALUE) && (
                    <AmountField
                      name='signatureThresholdAmount'
                      className='signature-threshold-amount-field'
                      label={SIGNATURE_THRESHOLD_AMOUNT}
                      component={InputField}
                      contextLabel={USD}
                      tooltip={SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG}
                      tooltipPosition='right'
                    />
                  )}
                </>
              )}

              {showPromptForSignatureField && (
                <>
                  <Field
                    name='promptForSignature'
                    label={PROMPT_SIGNATURE}
                    component={ReactSelect}
                    options={PROMPT_FOR_SIGNATURE_OPTIONS}
                    tooltip={PROMPT_SIGNATURE_TOOLTIP_MESSAGE}
                    tooltipPosition='right'
                  />

                  {isEqual(promptForSignatureValue, ON_THRESHOLD_AMOUNT_VALUE) && (
                    <AmountField
                      name='signatureThresholdAmount'
                      className='signature-threshold-amount-field'
                      label={SIGNATURE_THRESHOLD_AMOUNT}
                      component={InputField}
                      contextLabel={USD}
                      tooltip={SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG}
                      tooltipPosition='right'
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' submitting={isUpdating} disabled={invalid} label={REVIEW_DEVICE_DETAILS} className='submit-button' />
          </div>
        </div>
      </div>
    </form>
  )
}

AddDeviceForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  modelValue: PropTypes.string,
  promptSignatureValue: PropTypes.string,
  deviceModelSelectOptions: PropTypes.array,
  deviceConfigurationSelectOptions: PropTypes.array,
  isFetchingMerchantData: PropTypes.bool,
  showDeviceConfigurations: PropTypes.bool,
  isUpdating: PropTypes.bool,
  hideSerialNumber: PropTypes.bool,
  merchantGateway: PropTypes.string,
  showTippingSettings: PropTypes.bool,
  showPromptReceiptConfirmationSettings: PropTypes.bool,
  showReceiptAndTipConfigOptions: PropTypes.bool,
  showReceiptSettingsError: PropTypes.bool,
  currentReceiptDeliveryPrint: PropTypes.bool,
  currentReceiptDeliveryEmail: PropTypes.bool,
  currentReceiptDeliverySms: PropTypes.bool,
  currentAutomaticDeliveryPrint: PropTypes.bool,
  currentAutomaticDeliveryEmail: PropTypes.bool,
  currentAutomaticDeliverySms: PropTypes.bool,
  showPromptForSignatureField: PropTypes.bool,
  showPromptSignatureField: PropTypes.bool,
  promptForSignatureValue: PropTypes.bool,
}

export default reduxForm({
  form: ADD_NEW_DEVICE_FORM,
  validate: validateAddDeviceForm,
  enableReinitialize: true,
})(AddDeviceForm)
