import frontendUnderwritingWorkflowModel from 'utilities/create/models/frontendUnderwritingWorkflowModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendUnderwritingWorkflowsModel = ({ data: underwritingWorkflows }) => {
  const underwritingWorkflowsModel = map(underwritingWorkflows, (underwritingWorkflow) => frontendUnderwritingWorkflowModel({ data: underwritingWorkflow }))

  return keyBy(underwritingWorkflowsModel, 'id')
}

export default frontendUnderwritingWorkflowsModel
