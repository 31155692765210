import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  WEBHOOKS,
  WEBHOOK,
} from 'constants/apiConstants'

const getWebhookAPI = ({ id, values, credentials, meta }) => {
  const path = WEBHOOK({ webhookId: id })

  return paymentsAPI.get({
    meta,
    values,
    credentials,
    path,
    service: WEBHOOKS,
  })
}

export default getWebhookAPI
