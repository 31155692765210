import './CreateTransactionConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import LevelThreeItemsSectionData from 'components/Customer/Shared/Display/LevelThreeItemsSectionData'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  TIMES_OCTAGON,
} from 'constants/iconConstants'

import {
  AUTHORIZATIONS_PATH,
  CREATE_PAYMENT_PATH,
  PAYMENTS_PATH,
} from 'constants/pathConstants'

import {
  BILLING_DETAILS,
  PAYMENT_CONFIRMATION,
  PAYMENT_RESULT,
  PAYMENT_SUCCESSFUL,
  VIEW_RECEIPT,
  PURCHASE_DETAILS,
  UPDATE_PAYMENT_DETAILS,
  VIEW_ALL_PAYMENTS,
  ADDITIONAL_DATA,
  AUTHORIZATION_SUCCESSFUL,
  AUTHORIZATION_CONFIRMATION,
  CREATE_NEW_TRANSACTION,
  VIEW_ALL_AUTHORIZATIONS,
  PAYMENT_TERMINAL_DETAILS,
  TRANSACTION_CONFIRMATION_NO_RECEIPT,
} from 'constants/language/languageConstants'

const CreateTransactionConfirmation = ({
  credentialId = '',
  purchaseDetailsSectionData = [],
  paymentInstrumentSectionData = [],
  additionalDataSectionData = [],
  deviceDataSectionData = [],
  displayFailureCode = '',
  failureMessage = '',
  isFetchingTransferData = false,
  isFetchingMerchantData = false,
  isFetchingDeviceData = false,
  isFetchingReceiptData = false,
  paymentInstrumentId = '',
  identityId = '',
  amount = '',
  transferDescription = '',
  itemData = [],
  isLevelTwoEnabled = false,
  isLevelThreeEnabled = false,
  showDeviceDataSection = false,
  isSale = false,
  receiptUrl = '',
}) => {
  return (
    <div className='CreateTransactionConfirmation'>

      { (isFetchingTransferData || isFetchingReceiptData) && <Loader /> }

      { (!isFetchingTransferData && !isFetchingReceiptData) && (
        <>
          { !isEmpty(failureMessage) ? (
            <>
              <div className='header-container'>
                <h2 className='title'>{PAYMENT_RESULT}</h2>
              </div>
              <div className='failure-message-container row'>
                <h6 className='title'><i className={`fa fa-${TIMES_OCTAGON} failure-icon`} /> {displayFailureCode}</h6>
                <div className='failure-message'>{failureMessage} <a className='text-link' onClick={() => goToPath({ pathname: CREATE_PAYMENT_PATH({ credentialId }), queries: { paymentInstrumentId, buyerIdentityId: identityId, amount, transferDescription } })}>{UPDATE_PAYMENT_DETAILS}</a></div>
              </div>
            </>
          ) : (
            <>
              <div className='header-container'>
                <h2 className='title'>{isSale ? PAYMENT_CONFIRMATION : AUTHORIZATION_CONFIRMATION}</h2>
              </div>

              {receiptUrl ? (
                <div className='success-message-container'>
                  <span className='success-message'>
                    <i className={`fa fa-${CHECK_CIRCLE_ICON} success-icon`} />
                    {isSale ? PAYMENT_SUCCESSFUL : AUTHORIZATION_SUCCESSFUL} <a className='text-link' href={receiptUrl} target='blank'>{VIEW_RECEIPT}</a>
                  </span>
                </div>
              ) : (
                <div className='warning-message-container'>
                  <span className='warning-message'>
                    <i className={`icon fa fa-${ALERT_ICON} warning-icon`} />
                    {isSale ? PAYMENT_SUCCESSFUL : AUTHORIZATION_SUCCESSFUL} {TRANSACTION_CONFIRMATION_NO_RECEIPT}
                  </span>
                </div>
              )}

              <div className='payment-details-container'>
                <PageSectionV2
                  smallTitle={PURCHASE_DETAILS}
                  className='purchase-details'
                  columns={purchaseDetailsSectionData}
                  isFetching={isFetchingMerchantData}
                />

                <PageSectionV2
                  smallTitle={BILLING_DETAILS}
                  className='payment-details'
                  columns={paymentInstrumentSectionData}
                  isFetching={isFetchingMerchantData}
                />

                {showDeviceDataSection && (
                <PageSectionV2
                  smallTitle={PAYMENT_TERMINAL_DETAILS}
                  className='payment-terminal-details'
                  columns={deviceDataSectionData}
                  isFetching={isFetchingDeviceData}
                />
                )}

                {(isLevelTwoEnabled || isLevelThreeEnabled) && (
                <>
                  <PageSectionV2
                    smallTitle={ADDITIONAL_DATA}
                    className='additional-data-section'
                    columns={additionalDataSectionData}
                    isFetching={isFetchingMerchantData}
                  />

                  {isLevelThreeEnabled && <LevelThreeItemsSectionData levelThreeItems={itemData} />}
                </>
                )}
              </div>
            </>
          )}

          <div className='buttons-container flex flex-start'>
            <Button className='create-new-payment-button' onClick={() => goToPath({ pathname: CREATE_PAYMENT_PATH({ credentialId }) })} label={CREATE_NEW_TRANSACTION} />
            <Button className='view-all-payments-button' onClick={() => goToPath({ pathname: isSale ? PAYMENTS_PATH({ credentialId }) : AUTHORIZATIONS_PATH({ credentialId }) })} label={isSale ? VIEW_ALL_PAYMENTS : VIEW_ALL_AUTHORIZATIONS} variant='ghost' />
          </div>
        </>
      )}
    </div>
  )
}

CreateTransactionConfirmation.propTypes = {
  credentialId: PropTypes.string,
  purchaseDetailsSectionData: PropTypes.array,
  paymentInstrumentSectionData: PropTypes.array,
  additionalDataSectionData: PropTypes.array,
  deviceDataSectionData: PropTypes.array,
  displayFailureCode: PropTypes.string,
  failureMessage: PropTypes.string,
  isFetchingTransferData: PropTypes.bool,
  isFetchingMerchantData: PropTypes.bool,
  isFetchingDeviceData: PropTypes.bool,
  isFetchingReceiptData: PropTypes.bool,
  paymentInstrumentId: PropTypes.string,
  identityId: PropTypes.string,
  itemData: PropTypes.array,
  isLevelTwoEnabled: PropTypes.bool,
  isLevelThreeEnabled: PropTypes.bool,
  showDeviceDataSection: PropTypes.bool,
  isSale: PropTypes.bool,
  receiptUrl: PropTypes.string,
}

export default CreateTransactionConfirmation
