import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MONITORING_ALERT,
  MONITORING_ALERTS,
} from 'constants/apiConstants'

const patchMonitoringAlertAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    credentials,
    path: MONITORING_ALERT({ monitoringAlertId: id }),
    values,
    service: MONITORING_ALERTS,
  })
}

export default patchMonitoringAlertAPI
