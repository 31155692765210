import React from 'react'

const Terminal = ({
  width = '40',
  height = '56',
  fill = '#4C5B6A',
}) => {
  return (
    <svg
      className='Terminal'
      width={width}
      height={height}
      viewBox='0 0 40 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.7895 7.85965C9.61909 7.85965 7.85965 9.61909 7.85965 11.7895V27.5088C7.85965 29.6792 9.61909 31.4386 11.7895 31.4386H27.5088C29.6792 31.4386 31.4386 29.6792 31.4386 27.5088V11.7895C31.4386 9.61909 29.6792 7.85965 27.5088 7.85965H11.7895Z' fill={fill} />
      <path d='M7.85965 37.3333C7.85965 36.2481 8.73937 35.3684 9.82456 35.3684H29.4737C30.5589 35.3684 31.4386 36.2481 31.4386 37.3333C31.4386 38.4185 30.5589 39.2982 29.4737 39.2982H9.82456C8.73937 39.2982 7.85965 38.4185 7.85965 37.3333Z' fill={fill} />
      <path d='M9.82456 43.2281C8.73937 43.2281 7.85965 44.1078 7.85965 45.193C7.85965 46.2782 8.73937 47.1579 9.82456 47.1579H15.7193C16.8045 47.1579 17.6842 46.2782 17.6842 45.193C17.6842 44.1078 16.8045 43.2281 15.7193 43.2281H9.82456Z' fill={fill} />
      <path d='M21.614 45.193C21.614 44.1078 22.4938 43.2281 23.5789 43.2281H29.4737C30.5589 43.2281 31.4386 44.1078 31.4386 45.193C31.4386 46.2782 30.5589 47.1579 29.4737 47.1579H23.5789C22.4938 47.1579 21.614 46.2782 21.614 45.193Z' fill={fill} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 5.89474C0 2.63916 2.63916 0 5.89474 0H33.4035C36.6591 0 39.2982 2.63916 39.2982 5.89474V49.1228C39.2982 52.3784 36.6591 55.0175 33.4035 55.0175H5.89474C2.63916 55.0175 0 52.3784 0 49.1228V5.89474ZM5.89474 3.92982H33.4035C34.4887 3.92982 35.3684 4.80954 35.3684 5.89474V49.1228C35.3684 50.208 34.4887 51.0877 33.4035 51.0877H5.89474C4.80955 51.0877 3.92982 50.208 3.92982 49.1228V5.89474C3.92982 4.80955 4.80955 3.92982 5.89474 3.92982Z'
        fill={fill}
      />
    </svg>

  )
}

export default Terminal
