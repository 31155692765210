import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationProcessor from './ApplicationProcessor'
import { FETCHING } from 'constants/reducerConstants'
import getApplicationProcessorRequest from 'utilities/actions/get/getApplicationProcessorRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getApplicationProcessorByName } from 'state-layer/selectors'

import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `applicationProcessorR.${FETCHING}`)
  const applicationId = get(props, 'params.applicationId')
  const processorName = get(props, 'params.processor')
  const appProcessor = getApplicationProcessorByName(state, applicationId, processorName)
  const headerTitle = '' // TODO title = {business name and id string}
  const headerSubTitle = get(appProcessor, 'displayCreatedAt')

  const processorDetailsSectionData = [
    {
      label: 'Processor',
      value: processorName,
    },
  ]

  return {
    isFetching,
    applicationId,
    processorName,
    appProcessor,
    credentials,
    headerTitle,
    headerSubTitle,
    processorDetailsSectionData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationProcessor: (actionData) => dispatch(getApplicationProcessorRequest(actionData)),
  }
}

class ApplicationProcessorC extends Component {
  componentDidMount() {
    const {
      processorName,
      credentials,
      applicationId,
      getApplicationProcessor,
    } = this.props

    getApplicationProcessor({ applicationId, credentials, processor: processorName })
  }

  render() {
    return (
      <ApplicationProcessor
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationProcessorC)
