import API from 'utilities/api/API'
import createUrl from 'utilities/create/createUrl'
import getCredentialAxiosConfigs from 'utilities/get/getCredentialAxiosConfigs'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import isAdministratorView from 'utilities/is/isAdministratorView'
import { sendAmplitudeApiEvent } from 'utilities/amplitude'

import get from 'lodash/get'
import split from 'lodash/split'
import head from 'lodash/head'

const paymentsAPI = {
  handleApiEvent ({
    response,
    credentialId,
    host,
    path,
    queries,
    actionId,
    type,
    service,
  }) {
    const { error } = response

    if (isAdministratorView(path)) {
      return
    }

    const requestInfo = {
      host,
      path,
      queries,
      credentialId,
      service: service || head(split(path, '/')), // TODO: do we want to be passing in service from our api files purely for analytics? we can just have the service in the scope here and use the split
      requestType: type,
    }

    // TODO: pass credentials object to send event rather than just credentialId
    if (error) {
      sendAmplitudeApiEvent('Response Error', {
        actionId,
        status: get(error, 'response.status'),
        errors: get(error, 'response.data._embedded.errors'),
        ...requestInfo,
      })
    } else {
      // sendAmplitudeApiEvent('Response Success', {
      //   actionId,
      //   ...requestInfo,
      // })
    }
  },

  async sendRequest ({
    meta = {},
    path,
    absolutePath,
    values,
    queries,
    credentials = {},
    type,
    api,
    service,
    mock,
    headers,
  }) {
    const { actionId } = meta

    const {
      id: credentialId,
      host,
    } = credentials

    const {
      contentType,
      customApiConfigs,
    } = meta

    const url = absolutePath || createUrl({
      url: `${host}/${path}`,
      queries,
    })

    // TODO: removing request tracking analytics due to downgraded amplitude account. Look into we ever want to turn this back on.
    // const requestInfo = {
    //   host,
    //   path: path || absolutePath,
    //   queries,
    //   credentialId,
    //   service: service || get(split(path, '/'), '[0]'),
    //   requestType: type,
    // }

    // TODO: look into whether we should capture ALL api requests... that could be a lot with our orchestration calls
    // sendAmplitudeApiEvent('Request Sent', {
    //   actionId,
    //   ...requestInfo,
    // })

    const sendValues = type !== 'GET'
    const configs = getCredentialAxiosConfigs({ credentials, contentType, headers, customApiConfigs })
    const apiArgs = sendValues ? [url, values, configs] : [url, configs]

    // if mock exists and is enabled on the platform, send back the mock
    const platform = getCurrentPlatform()
    const useMocks = get(platform, 'useMocks', false)
    const response = mock && useMocks ? mock : await api(...apiArgs)

    this.handleApiEvent({
      response,
      credentialId,
      host,
      path,
      values,
      queries,
      type,
      actionId,
      service,
    })

    // TODO: run through createSuccessResponse/createErrorResponse utilities
    return response
  },

  get ({ path, absolutePath, queries, credentials, service, meta, mock, headers }) {
    return this.sendRequest({
      meta,
      path,
      absolutePath,
      queries,
      credentials,
      type: 'GET',
      api: API.get,
      service,
      mock,
      headers,
    })
  },

  post ({ path, absolutePath, values, queries, credentials, service, meta, mock, headers }) {
    return this.sendRequest({
      meta,
      path,
      absolutePath,
      values,
      queries,
      credentials,
      type: 'POST',
      api: API.post,
      service,
      mock,
      headers,
    })
  },

  patch ({ path, absolutePath, values, queries, credentials, service, meta, mock, headers }) {
    return this.sendRequest({
      meta,
      path,
      absolutePath,
      values,
      queries,
      credentials,
      type: 'PATCH',
      api: API.patch,
      service,
      mock,
      headers,
    })
  },

  put ({ path, absolutePath, values, queries, credentials, service, meta, mock, headers }) {
    return this.sendRequest({
      meta,
      path,
      absolutePath,
      values,
      queries,
      credentials,
      type: 'PUT',
      api: API.put,
      service,
      mock,
      headers,
    })
  },

  delete ({ path, absolutePath, values, queries, credentials, service, meta, mock, headers }) {
    return this.sendRequest({
      meta,
      path,
      absolutePath,
      values,
      queries,
      credentials,
      type: 'DELETE',
      api: API.delete,
      service,
      mock,
      headers,
    })
  },
}

export default paymentsAPI
