import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationLimits from './ApplicationLimits'
import { goToPath } from 'state-layer/history'
import { getDisbursementRulesSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  UPDATE_APPLICATION_CARD_LIMITS_PATH,
  UPDATE_APPLICATION_ACH_LIMITS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const disbursementRules = getDisbursementRulesSelector(state)
  const isRolePlatformCred = isRolePlatform({ credentials })

  const [
    applicationACHRules,
    applicationCardRules,
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
  ] = getMany(disbursementRules, [
    'applicationACHRules',
    'applicationCardRules',
    'applicationACHRulesMaxTransactionAmount',
    'applicationCardRulesMaxTransactionAmount',
  ])

  const setCardVelocityLimitActions = [
    {
      label: EDIT,
      action: () => goToPath({
        pathname: UPDATE_APPLICATION_CARD_LIMITS_PATH({ credentialId, applicationId }),
      }),
      condition: isRolePlatformCred,
    },
  ]

  const setACHVelocityLimitActions = [
    {
      label: EDIT,
      action: () => goToPath({
        pathname: UPDATE_APPLICATION_ACH_LIMITS_PATH({ credentialId, applicationId }),
      }),
      condition: isRolePlatformCred,
    },
  ]

  return {
    credentials,
    applicationId,
    setCardVelocityLimitActions,
    setACHVelocityLimitActions,
    applicationACHRules,
    applicationCardRules,
    isFetching: get(state, `disbursementRulesR.${FETCHING}`),
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
  }
}

class ApplicationLimitsC extends Component {
  render() {
    return (
      <ApplicationLimits
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(ApplicationLimitsC)
