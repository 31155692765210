import {
    TRANSFER,
    REVIEW_QUEUE,
    AUTHORIZATION,
    MERCHANTS,
    SETTLEMENT,
    DISPUTE,
    SETTLEMENT_ENTRIES,
} from 'constants/flowConstants'

// these are all possible export options for a transaction resource from the BE
export const transactionExportOptions = [
    'id',
    'type',
    'subtype',
    'state',
    'amount',
    'currency',
    'fee',
    'traceId',
    'statementDescriptor',
    'updatedAt',
    'createdAt',
    'merchantIdentityId',
    'paymentInstrumentId',
    'externallyFunded',
    'merchant',
    'paymentInstrumentName',
    'paymentMethod',
    'failureCode',
    'feeType',
    'readyToSettleAt',
    'issuerCountry',
    'cardType',
    'cardBrand',
    'cardBin',
    'cardLastFour',
    'cardExpirationMonth',
    'cardExpirationYear',
    'cardAddressLineOne',
    'cardAddressLineTwo',
    'cardAddressCity',
    'cardAddressPostalCode',
    'cardAddressCountry',
    'cardAddressVerification',
    'cardSecurityCodeVerification',
    'fingerprint',
    'tags',
]

// these are all possible export options for a settlement resource from the BE
export const reviewQueueSettlementExportOptions = [
    'id',
    'merchant',
    'status',
    'transfers',
    'fees',
    'createdAt',
    'processor',
    'application',
    'outcome',
    'amount',
    'totalFees',
    'netAmount',
    'entityType',
    'entityId',
    'applicationId',
    'updatedAt',
    'merchantId',
]

export const authorizationExportOptions = [
    'id',
    'state',
    'amount',
    'amountRequested',
    'currency',
    'expiresAt',
    'captured',
    'traceId',
    'idempotencyId',
    'transfer',
    'statementDescriptor',
    'updatedAt',
    'createdAt',
    'merchantIdentityId',
    'paymentInstrumentId',
    'merchant',
    'buyer',
    'paymentMethod',
    'failureCode',
    'feeType',
    'readyToSettleAt',
    'cardType',
    'cardBrand',
    'cardBin',
    'cardLastFour',
    'cardExpirationMonth',
    'cardExpirationYear',
    'cardAddressLineOne',
    'cardAddressLineTwo',
    'cardAddressCity',
    'cardAddressPostalCode',
    'cardAddressCountry',
    'cardAddressVerification',
    'cardSecurityCodeVerification',
    'fingerprint',
    'merchantName',
    'businessName',
    'applicationName',
    'doingBusinessAs',
    'tags',
]

export const merchantExportOptions = [
    'id',
    'businessName',
    'doingBusinessAs',
    'identityId',
    'applicationId',
    'applicationName',
    'merchantProfileId',
    'onboardingState',
    'createdAt',
    'processor',
    'mid',
    'processingEnabled',
    'settlementEnabled',
    'settlementFundingIdentifier',
    'mcc',
    'cardCvvRequired',
    'convenienceChangesEnabled',
    'cardExpiredDateRequired',
    'levelTwoLevelThreeDataEnabled',
    'rentSurchargesEnabled',
    'latestVerificationId',
    'merchantName',
    'tags',
]

export const settlementExportOptions = [
    'id',
    'createdAt',
    'merchantName',
    'applicationName',
    'totalTransactionCount',
    'totalFeesCount',
    'totalAmount',
    'totalFeesAmount',
    'netAmount',
    'processor',
    'updatedAt',
    'applicationId',
    'currency',
    'adjustmentCreditAmount',
    'adjustmentCreditCount',
    'adjustmentDebitAmount',
    'adjustmentDebitCount',
    'disputeCreditAmount',
    'disputeCreditCount',
    'disputeDebitAmount',
    'disputeDebitCount',
    'transferDebitAmount',
    'transferDebitCount',
    'transferCreditAmount',
    'transferCreditCount',
    'externallyFundedAmount',
    'externallyFundedCount',
    'autoCloseTime',
    'windowStart',
    'windowEnd',
    'scheduleType',
    'exception',
    'merchant',
    'payoutPlan',
    'settlementGroup',
    'businessName',
    'doingBusinessAs',
    'tags',
]

export const disputeExportOptions = [
    'id',
    'createdAt',
    'updatedAt',
    'application',
    'amount',
    'currency',
    'state',
    'buyer',
    'occurredAt',
    'respondBy',
    'caseId',
    'transfer',
    'reason',
    'message',
    'action',
    'merchant',
    'identity',
    'responseState',
    'merchantName',
    'businessName',
    'applicationName',
    'tags',
]

export const settlementEntriesExportOptions = [
    'id',
    'createdAt',
    'updatedAt',
    'traceId',
    'feeType',
    'buyer',
    'settlementId',
    'entryId',
    'entryType',
    'entrySubType',
    'amount',
    'currency',
    'transferId',
    'shouldFund',
    'tags',
]


export const RESOURCE_TO_EXPORT_FIELDS_MAP = {
    [TRANSFER] : transactionExportOptions,
    [REVIEW_QUEUE]: reviewQueueSettlementExportOptions,
    [AUTHORIZATION]: authorizationExportOptions,
    [MERCHANTS]: merchantExportOptions,
    [SETTLEMENT]: settlementExportOptions,
    [DISPUTE]: disputeExportOptions,
    [SETTLEMENT_ENTRIES]: settlementEntriesExportOptions,
}
