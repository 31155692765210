import genericReducer from 'utilities/reducers/genericReducer'
import updateState from 'utilities/reducers/updateState'
import map from 'lodash/map'
import get from 'lodash/get'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import includes from 'lodash/includes'
import uniq from 'lodash/uniq'
import omit from 'lodash/omit'
import getMany from 'utilities/get/getMany'

import {
  VALUES_PATH,
  NEW_VALUES_PATH,
} from 'constants/actionConstants'

import {
  GET,
  POST,
  PATCH,
  DELETE,
  getActionType,
} from 'constants/connectorConstants'


const connector = ({
  id: parentResourceID,
  flows,
  debug,
}) => {
  return (state, action) => genericReducer({
    flows,
    success: (successState, successAction) => {
      const [
        newValues,
        selectorId,
        resourceId,
        type,
      ] = getMany(successAction, [
        NEW_VALUES_PATH,
        'meta.selectorId',
        parentResourceID,
        'type',
      ])

      const itemId = selectorId || resourceId

      const oldIds = get(successState, `items.${itemId}`, [])
      const newIds = map(newValues, ({ id }) => id)

      const actionType = getActionType(type)
      const omittedState = omit(successState, `items.${itemId}`)

      if (actionType === POST || actionType === GET) {
        const postIds = uniq(concat(oldIds, newIds))

        return updateState(omittedState, successAction, false, { [itemId]: postIds })
      }

      if (actionType === DELETE) {
        const remainingIds = remove(oldIds, (id) => includes(newIds, id))

        return updateState(omittedState, successAction, false, { [itemId]: remainingIds })
      }

      return updateState(successState, successAction, false)
    },
    debug,
  })(state, action)
}

export default connector
