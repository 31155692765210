import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

const frontendStatementModel = ({ data: statement }) => {
  const [
    id,
    createdAt,
    updatedAt,
    applicationId,
    displayName,
    fileId,
    linkedTo,
    linkedType,
    rangeType,
    rangeStart,
    rangeEnd,
    type,
  ] = getMany(statement, [
    'id',
    'created_at',
    'updated_at',
    'application_id',
    'display_name',
    'file_id',
    'linked_to',
    'linked_type',
    'time_range.range_type',
    'time_range.range_start',
    'time_range.range_end',
    'type',
  ])

  return {
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    applicationId,
    displayName,
    fileId,
    linkedTo,
    linkedType,
    rangeType,
    rangeStart,
    displayRangeStart: formatDate({ date: rangeStart }),
    rangeEnd,
    displayRangeEnd: formatDate({ date: rangeEnd }),
    type,
    displayType: snakeCaseToTitleCase({ key: type }),
  }
}

export default frontendStatementModel
