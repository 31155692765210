import './EditDeviceConfigurationDetailsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditDeviceConfigurationDetailsFormC from 'components/Customer/Forms/EditDeviceConfigurationDetailsForm/EditDeviceConfigurationDetailsFormC'
import { EDIT_DEVICE_CONFIGURATION_DETAILS } from 'constants/language/languageConstants'

const EditDeviceConfigurationDetailsModal = ({
  deviceName = '',
  processor = '',
  gateway = '',
  device = {},
}) => {
  return (
    <GenericModal
      className='EditDeviceConfigurationsModal'
      title={EDIT_DEVICE_CONFIGURATION_DETAILS}
      subTitle={`Editing details for ${deviceName}`}
      Component={EditDeviceConfigurationDetailsFormC}
      deviceName={deviceName}
      processor={processor}
      gateway={gateway}
      device={device}
    />
  )
}

EditDeviceConfigurationDetailsModal.propTypes = {
  deviceName: PropTypes.string,
  processor: PropTypes.string,
  gateway: PropTypes.string,
  device: PropTypes.object,
}

export default EditDeviceConfigurationDetailsModal
