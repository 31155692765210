import './ContactSalesEmailS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericEmailFormC from 'components/Admin/Forms/GenericEmailForm/GenericEmailFormC'
import { CONTACT_SALES } from 'constants/language/languageConstants'

const ContactSalesEmail = ({
  isFetching = false,
  contactSalesEmail = {},
}) => {
  return (
    <div className='ContactSalesEmail'>
      <GenericEmailFormC
        isFetching={isFetching}
        email={contactSalesEmail}
        emailName={CONTACT_SALES}
      />
    </div>
  )
}

ContactSalesEmail.propTypes = {
  isFetching: PropTypes.bool,
  contactSalesEmail: PropTypes.object,
}

export default ContactSalesEmail
