import './ReviewAndSubmitFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import FeeProfileC from 'components/Customer/Pages/FeeProfile/FeeProfileC'
import Button from 'components/Shared/Button/Button'
import submitEditFeeProfileForm from 'utilities/submit/submitEditFeeProfileForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { REVIEW_AND_SUBMIT_FEE_PROFILE_FORM } from 'constants/formConstants'
import { ALERT_CIRCLE_ICON } from 'constants/iconConstants'

import {
  CANCEL,
  PREVIOUS_STEP,
  SUBMIT,
} from 'constants/language/languageConstants'

const ReviewAndSubmitForm = ({
  handleSubmit = () => {},
  currency = 'USD',
  feeProfileFormValues = {},
  infoContainerHeader = '',
  infoContainerText = '',
  title = '',
}) => {
  return (
    <form className='ReviewAndSubmitForm' onSubmit={handleSubmit(submitEditFeeProfileForm)}>
      <div className='form-content'>
        <h4>{title}</h4>
        <div className='info-container'>
          <div className='flex items-center'>
            <i className={`fa fa-${ALERT_CIRCLE_ICON}`} />
            <h6>{infoContainerHeader}</h6>
          </div>
          <div className='text'>{infoContainerText}</div>
        </div>
        <FeeProfileC
          feeProfilesForReviewPage={feeProfileFormValues}
          isReviewPage
        />
      </div>


      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => prevFlowStep()} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SUBMIT} />
          </div>
        </div>
      </div>
    </form>
  )
}

ReviewAndSubmitForm.propTypes = {
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
  feeProfileFormValues: PropTypes.object,
  infoContainerHeader: PropTypes.string,
  infoContainerText: PropTypes.string,
  title: PropTypes.string,
}

export default reduxForm({
  form: REVIEW_AND_SUBMIT_FEE_PROFILE_FORM,
})(ReviewAndSubmitForm)
