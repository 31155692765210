import './RoleFieldsetS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import NestedCheckboxesTable from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import { getPermissionCheckboxOptions } from 'constants/permissionConstants'

import {
  CUSTOM_ROLE_DESCRIPTION_TOOLTIP,
  ROLE_DESCRIPTION,
  ROLE_NAME,
  ROLE_PERMISSIONS,
  ROLE_PERMISSIONS_SUBLABEL,
} from 'constants/language/languageConstants'

const RoleFieldset = ({
  currentSelectedEvents = {},
  formName = '',
  permissionsError = '',
  authRole,
}) => {
  return (
    <fieldset className='RoleFieldset'>
      <Field
        name='roleName'
        label={ROLE_NAME}
        component={InputField}
      />

      <Field
        name='roleDescription'
        label={ROLE_DESCRIPTION}
        component={TextAreaField}
        tooltip={CUSTOM_ROLE_DESCRIPTION_TOOLTIP}
      />

      <Field
        name='selectedEvents'
        title={ROLE_PERMISSIONS}
        component={NestedCheckboxesTable}
        options={getPermissionCheckboxOptions({ authRole })}
        currentSelectedEvents={currentSelectedEvents}
        subLabel={ROLE_PERMISSIONS_SUBLABEL}
        formName={formName}
        showActions={false}
      />
      {permissionsError && <div className='error'>{permissionsError}</div>}
    </fieldset>
  )
}

RoleFieldset.propTypes = {
  currentSelectedEvents: PropTypes.object,
  formName: PropTypes.string,
  permissionsError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  authRole: PropTypes.string,
}

export default RoleFieldset
