import { GET_NOTIFICATION_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getNotificationSettingsRequest = ({
  credentials,
}) => createAction({
  type: GET_NOTIFICATION_SETTINGS_F_REQUEST,
  credentials,
})

export default getNotificationSettingsRequest
