import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CONTRACTS } from 'constants/apiConstants'

const getDashboardContractsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_CONTRACTS,
    queries,
    meta,
  })
}

export default getDashboardContractsAPI
