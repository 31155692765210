import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANTS,
  MERCHANT_DEVICES,
} from 'constants/apiConstants'

const postDeviceAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    path: MERCHANT_DEVICES({ merchantId: id }),
    credentials,
    service: MERCHANTS,
  })
}

export default postDeviceAPI
