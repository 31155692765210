import frontendBalanceAdjustmentModel from 'utilities/create/models/frontendBalanceAdjustmentModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendBalanceAdjustmentsModel = ({ data: balanceAdjustments }) => {
  const balanceAdjustmentsModels = map(balanceAdjustments, (balanceAdjustment) => frontendBalanceAdjustmentModel({ data: balanceAdjustment }))

  return keyBy(balanceAdjustmentsModels, 'id')
}

export default frontendBalanceAdjustmentsModel
