import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationPayoutConfiguration from './ApplicationPayoutConfiguration'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getApplicationProcessorConfigsRequest from 'utilities/actions/get/getApplicationProcessorConfigsRequest'
import getApplicationBalancesRequest from 'utilities/actions/get/getApplicationBalancesRequest'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { FINIX_V1 } from 'constants/processorConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'

import {
  EDIT_VISA_PAYOUT_CONFIG_MODAL,
  EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL,
  EDIT_PAYOUT_PROFILE_MODAL,
} from 'constants/modalConstants'

import {
  getAllApplicationProcessorsConfigsSelector,
  getApplicationProcessorConfigSelector,
  getApplicationSelector,
  getBalancesByApplicationId,
} from 'state-layer/selectors'

import {
  AVAILABLE_BALANCE,
  BALANCE_ACCOUNT_ID,
  DEFAULT_PAYMENT_TYPE,
  DEFAULT_CURRENCIES,
  DEFAULT_MCC,
  DEFAULT_SENDER_ACCOUNT_NUMBER,
  DEFAULT_SENDER_FIRST_NAME,
  DEFAULT_SENDER_LAST_NAME,
  DEFAULT_SENDER_FULL_NAME,
  DEFAULT_SENDER_ADDRESS_LINE_1,
  DEFAULT_SENDER_ADDRESS_LINE_2,
  DEFAULT_SENDER_CITY,
  DEFAULT_SENDER_COUNTRY_SUBDIVISION,
  DEFAULT_SENDER_POSTAL_CODE,
  DEFAULT_SENDER_COUNTRY,
  DEFAULT_SENDER_COUNTRY_CODE,
  EDIT,
  VISA_ALLOWED_BUSINESS_APPLICATION_IDS,
  VISA_CARD_ACCEPTOR_ID_CODE,
  VISA_CARD_ACCEPTOR_TERMINAL_ID,
  VISA_DEFAULT_SENDER_NAME,
  VISA_DEFAULT_SENDER_ADDRESS,
  VISA_DEFAULT_SENDER_CITY,
  VISA_DEFAULT_SENDER_COUNTRY,
  VISA_DEFAULT_SENDER_COUNTY,
  VISA_DEFAULT_SENDER_STATE_CODE,
  VISA_DEFAULT_SENDER_ZIP_CODE,
  CARD_ACCEPTOR_ID_CODE,
  MC_DEFAULT_STATEMENT_DESCRIPTOR,
  VISA_CARD_ACCEPTOR_TERMINAL_ID_TOOLTIP_MSG,
  VISA_ALLOWED_APPLICATION_ID_TOOLTIP_MSG,
  ENABLED_FOR_PAYOUT,
  ENABLED,
  DISABLED,
  PUSH_TO_CARD,
  PULL_FROM_CARD,
  PUSH_TO_ACH,
  PULL_FROM_ACH,
  PUSH_TO_SAME_DAY_ACH,
  PULL_FROM_SAME_DAY_ACH,
} from 'constants/language/languageConstants'

const enabledDisabledValue = (value) => (value ? ENABLED : DISABLED)

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `processorConfigsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const payoutProfileInfo = getApplicationSelector(state, applicationId)
  const appConfigs = getAllApplicationProcessorsConfigsSelector(state)
  const appConfigId = head(Object.keys(appConfigs))
  const processorConfig = getApplicationProcessorConfigSelector(state, appConfigId)
  const visaConfig = get(processorConfig, 'visa_application_config')
  const masterCardConfig = get(processorConfig, 'mastercard_application_config')
  const masterCardConfigTemplate = get(masterCardConfig, 'configuration_templates', {})
  const masterCardConfigTemplateObj = get(masterCardConfigTemplate, 'country', {})
  const masterCardConfigTemplateAddressSubdivision = get(masterCardConfigTemplateObj, 'USA', {})
  const mcSubdivision = get(masterCardConfigTemplateAddressSubdivision, 'default_sender_address_subdivision')
  const applicationBalances = getBalancesByApplicationId(state, applicationId)
  const isEnabledForPayout = !isEmpty(applicationBalances)
  const accountBalance = get(applicationBalances, 'displayAvailableAmount')
  const balanceID = get(applicationBalances, 'id')

  const [
    disbursementsAchPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsCardPushEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
  ] = getMany(payoutProfileInfo, [
    'disbursementsAchPullEnabled',
    'disbursementsAchPushEnabled',
    'disbursementsCardPullEnabled',
    'disbursementsCardPushEnabled',
    'disbursementsSameDayAchPushEnabled',
    'disbursementsSameDayAchPullEnabled',
  ])

  const [
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultMcc,
    mcStatementDescriptor,
    mcDefaultSenderAccountNumber,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
  ] = getMany(masterCardConfig, [
    'card_acceptor_id',
    'default_payment_type',
    'default_currencies',
    'default_mcc',
    'statement_descriptor',
    'default_sender_account_number',
    'default_sender_first_name',
    'default_sender_last_name',
    'default_sender_full_name',
    'default_sender_address_line1',
    'default_sender_address_line2',
    'default_sender_city',
    'default_sender_postal_code',
    'default_sender_country',
    'default_sender_country_code',
  ])

  const [
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultMcc,
    visaDefaultSenderName,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderCounty,
    visaDefaultSenderStateCode,
    visaDefaultSenderCountry,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderAccountNumber,
  ] = getMany(visaConfig, [
    'allowed_business_application_ids',
    'card_acceptor_id_code',
    'card_acceptor_terminal_id',
    'default_currencies',
    'default_mcc',
    'default_sender_name',
    'default_sender_address',
    'default_sender_city',
    'default_sender_county_code',
    'default_sender_state_code',
    'default_sender_country',
    'default_sender_zip_code',
    'default_sender_country_code',
    'default_sender_account_number',
  ])

  const displayMastercardDefaultCurrencies = join(mcDefaultCurrencies, ', ')

  const masterCardConfigDetails = convertPageSectionDataToV2([
    {
      label: CARD_ACCEPTOR_ID_CODE,
      value: mcCardAcceptorId,
    },
    {
      label: DEFAULT_PAYMENT_TYPE,
      value: mcDefaultPaymentType,
    },
    {
      label: DEFAULT_CURRENCIES,
      value: displayMastercardDefaultCurrencies,
    },
    {
      label: DEFAULT_MCC,
      value: mcDefaultMcc,
    },
    {
      label: DEFAULT_SENDER_ACCOUNT_NUMBER,
      value: mcDefaultSenderAccountNumber,
    },
    {
      label: MC_DEFAULT_STATEMENT_DESCRIPTOR,
      value: mcStatementDescriptor,
    },
    {
      label: DEFAULT_SENDER_FIRST_NAME,
      value: mcDefaultSenderFirstName,
    },
    {
      label: DEFAULT_SENDER_LAST_NAME,
      value: mcDefaultSenderLastName,
    },
    {
      label: DEFAULT_SENDER_FULL_NAME,
      value: mcDefaultSenderFullName,
    },
    {
      label: DEFAULT_SENDER_ADDRESS_LINE_1,
      value: mcDefaultSenderAddressLine1,
    },
    {
      label: DEFAULT_SENDER_ADDRESS_LINE_2,
      value: mcDefaultSenderAddressLine2,
    },
    {
      label: DEFAULT_SENDER_CITY,
      value: mcDefaultSenderCity,
    },
    {
      label: DEFAULT_SENDER_COUNTRY_SUBDIVISION,
      value: mcSubdivision,
    },
    {
      label: DEFAULT_SENDER_POSTAL_CODE,
      value: mcDefaultSenderPostalCode,
    },
    {
      label: DEFAULT_SENDER_COUNTRY,
      value: mcDefaultSenderCountry,
    },
    {
      label: DEFAULT_SENDER_COUNTRY_CODE,
      value: mcDefaultSenderCountryCode,
    },
  ])

  const displayVisaDefaultCurrencies = join(visaDefaultCurrencies, ', ')
  const displayVisaApplicationIds = join(visaAllowedBusinessApplicationIds, ', ')

  const visaConfigDetails = convertPageSectionDataToV2([
    {
      label: VISA_ALLOWED_BUSINESS_APPLICATION_IDS,
      value: <div className='flex space-between'><div>{displayVisaApplicationIds}</div><TooltipLabelC position='right' message={VISA_ALLOWED_APPLICATION_ID_TOOLTIP_MSG} /></div>,
    },
    {
      label: VISA_CARD_ACCEPTOR_ID_CODE,
      value: visaCardAcceptorIdCode,
    },
    {
      label: VISA_CARD_ACCEPTOR_TERMINAL_ID,
      value: <div className='flex space-between'><div>{visaAcceptorTerminalId}</div><TooltipLabelC position='right' message={VISA_CARD_ACCEPTOR_TERMINAL_ID_TOOLTIP_MSG} /></div>,

    },
    {
      label: DEFAULT_CURRENCIES,
      value: displayVisaDefaultCurrencies,
    },
    {
      label: DEFAULT_MCC,
      value: visaDefaultMcc,
    },
    {
      label: VISA_DEFAULT_SENDER_NAME,
      value: visaDefaultSenderName,
    },
    {
      label: VISA_DEFAULT_SENDER_ADDRESS,
      value: visaDefaultSenderAddress,
    },
    {
      label: VISA_DEFAULT_SENDER_CITY,
      value: visaDefaultSenderCity,
    },
    {
      label: VISA_DEFAULT_SENDER_STATE_CODE,
      value: visaDefaultSenderStateCode,
    },
    {
      label: VISA_DEFAULT_SENDER_COUNTY,
      value: visaDefaultSenderCounty,
    },
    {
      label: VISA_DEFAULT_SENDER_ZIP_CODE,
      value: visaDefaultSenderZipCode,
    },
    {
      label: VISA_DEFAULT_SENDER_COUNTRY,
      value: visaDefaultSenderCountry,
    },
    {
      label: DEFAULT_SENDER_COUNTRY_CODE,
      value: visaDefaultSenderCountryCode,
    },
  ])

  const balanceIdClickToCopies = [
    {
      label: BALANCE_ACCOUNT_ID,
      content: balanceID,
    },
  ]

  const payoutProfileDetails = convertPageSectionDataToV2([
    {
      label: PUSH_TO_CARD,
      value: enabledDisabledValue(disbursementsCardPushEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: PULL_FROM_CARD,
      value: enabledDisabledValue(disbursementsCardPullEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: PUSH_TO_ACH,
      value: enabledDisabledValue(disbursementsAchPushEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: PULL_FROM_ACH,
      value: enabledDisabledValue(disbursementsAchPullEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: PUSH_TO_SAME_DAY_ACH,
      value: enabledDisabledValue(disbursementsSameDayAchPushEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: PULL_FROM_SAME_DAY_ACH,
      value: enabledDisabledValue(disbursementsSameDayAchPullEnabled),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
  ], 3)

  const accountBalanceDetails = convertPageSectionDataToV2([
    {
      label: ENABLED_FOR_PAYOUT,
      value: enabledDisabledValue(isEnabledForPayout),
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: AVAILABLE_BALANCE,
      value: accountBalance,
    },
  ], 2)

  return {
    isFetching,
    credentials,
    credentialId,
    applicationId,
    processorFinix: FINIX_V1,
    mcCardAcceptorId,
    mcDefaultSenderAccountNumber,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    masterCardConfigDetails,
    mcSubdivision,
    mcStatementDescriptor,
    visaConfigDetails,
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultMcc,
    visaDefaultSenderName,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderCounty,
    visaDefaultSenderStateCode,
    visaDefaultSenderCountry,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderAccountNumber,
    mcDefaultPaymentType,
    mcDefaultMcc,
    mcDefaultCurrencies,
    displayVisaApplicationIds,
    displayVisaDefaultCurrencies,
    displayMastercardDefaultCurrencies,
    disbursementsAchPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsCardPushEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
    payoutProfileDetails,
    payoutProfileInfo,
    accountBalanceDetails,
    clickToCopies: balanceIdClickToCopies,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationProcessorConfigs: ({ applicationId, processor, credentials }) => dispatch(getApplicationProcessorConfigsRequest({ applicationId, processor, credentials })),
    showEditVisaConfigsModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_VISA_PAYOUT_CONFIG_MODAL, modalProps, className: 'modal-md no-pad' })),
    showMasterCardConfigsModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL, modalProps, className: 'modal-md no-pad' })),
    getPayoutProfile: ({ applicationId, credentials }) => dispatch(getApplicationRequest({ applicationId, credentials })),
    showEditPayoutProfileModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PAYOUT_PROFILE_MODAL, modalProps, className: 'modal-sm no-pad' })),
    getApplicationBalances: ({ credentials, applicationId }) => dispatch(getApplicationBalancesRequest({ credentials, queries: { linked_to: applicationId } })),
  }
}

class ApplicationPayoutConfigurationC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      processorFinix,
      getApplicationProcessorConfigs,
      getPayoutProfile,
      getApplicationBalances,
    } = this.props

    getApplicationProcessorConfigs({ applicationId, processor: processorFinix, credentials })
    getPayoutProfile({ applicationId, credentials })
    getApplicationBalances({ credentials, applicationId })
  }

  render() {
    const {
      credentialId,
      showEditVisaConfigsModal,
      visaAllowedBusinessApplicationIds,
      visaCardAcceptorIdCode,
      visaAcceptorTerminalId,
      visaDefaultCurrencies,
      visaDefaultMcc,
      visaDefaultSenderName,
      visaDefaultSenderAddress,
      visaDefaultSenderCity,
      visaDefaultSenderCounty,
      visaDefaultSenderStateCode,
      visaDefaultSenderCountry,
      visaDefaultSenderZipCode,
      visaDefaultSenderCountryCode,
      applicationId,
      visaDefaultSenderAccountNumber,
      showMasterCardConfigsModal,
      mcCardAcceptorId,
      mcDefaultPaymentType,
      mcDefaultCurrencies,
      mcDefaultMcc,
      mcStatementDescriptor,
      mcDefaultSenderAccountNumber,
      mcDefaultSenderFirstName,
      mcDefaultSenderLastName,
      mcDefaultSenderFullName,
      mcDefaultSenderAddressLine1,
      mcDefaultSenderAddressLine2,
      mcDefaultSenderCity,
      mcDefaultSenderPostalCode,
      mcDefaultSenderCountry,
      mcDefaultSenderCountryCode,
      mcSubdivision,
      showEditPayoutProfileModal,
      disbursementsAchPullEnabled,
      disbursementsAchPushEnabled,
      disbursementsCardPullEnabled,
      disbursementsCardPushEnabled,
      disbursementsSameDayAchPushEnabled,
      disbursementsSameDayAchPullEnabled,
      payoutProfileInfo,
      accountBalanceDetails,
    } = this.props

    const visaEditAction = [
      {
        label: EDIT,
        action: () => showEditVisaConfigsModal({
          credentialId,
          applicationId,
          visaAllowedBusinessApplicationIds,
          visaCardAcceptorIdCode,
          visaAcceptorTerminalId,
          visaDefaultCurrencies,
          visaDefaultMcc,
          visaDefaultSenderName,
          visaDefaultSenderAddress,
          visaDefaultSenderCity,
          visaDefaultSenderCounty,
          visaDefaultSenderStateCode,
          visaDefaultSenderCountry,
          visaDefaultSenderZipCode,
          visaDefaultSenderCountryCode,
          visaDefaultSenderAccountNumber,
        }),
      },
    ]

    const masterCardEditAction = [
      {
        label: EDIT,
        action: () => showMasterCardConfigsModal({
          credentialId,
          applicationId,
          mcCardAcceptorId,
          mcDefaultPaymentType,
          mcDefaultCurrencies,
          mcDefaultMcc,
          mcStatementDescriptor,
          mcDefaultSenderAccountNumber,
          mcDefaultSenderFirstName,
          mcDefaultSenderLastName,
          mcDefaultSenderFullName,
          mcDefaultSenderAddressLine1,
          mcDefaultSenderAddressLine2,
          mcDefaultSenderCity,
          mcDefaultSenderPostalCode,
          mcDefaultSenderCountry,
          mcDefaultSenderCountryCode,
          mcSubdivision,
        }),
      },
    ]

    const payoutProfileEditAction = [
      {
        label: EDIT,
        action: () => showEditPayoutProfileModal({
          credentialId,
          applicationId,
          disbursementsAchPullEnabled,
          disbursementsAchPushEnabled,
          disbursementsCardPullEnabled,
          disbursementsCardPushEnabled,
          disbursementsSameDayAchPushEnabled,
          disbursementsSameDayAchPullEnabled,
          payoutProfileInfo,
        }),
      },
    ]

    return (
      <ApplicationPayoutConfiguration
        {...this.props}
        visaEditAction={visaEditAction}
        masterCardEditAction={masterCardEditAction}
        payoutProfileEditAction={payoutProfileEditAction}
        accountBalanceDetails={accountBalanceDetails}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPayoutConfigurationC)
