import { CAN } from 'constants/countryConstants'
import { FULL_WEBSITE_REGEX } from 'constants/regexConstants'
import { WEBSITE_LENGTH_VALIDATION_ERROR } from 'constants/language/languageConstants'
import size from 'lodash/size'
import trim from 'lodash/trim'

import {
  fieldEmpty,
  invalidRegex,
  validateDate,
  validateAddress,
  validateMaskedTaxId,
  validateBusinessDescription,
  validatePhoneNumber,
} from 'utilities/validate'

const validateIdentityBusinessInfoForm = (values, props) => {
  const {
    onboardingFormCountry,
    isCanadianSoleProp,
  } = props

  const {
    businessData = {},
  } = values

  const {
    businessName,
    doingBusinessAs,
    businessDescription,
    businessPhone = '',
    website,
    businessType,
    ownershipType,
    incorporationDate,
    businessTaxId,
    businessAddress,
  } = businessData

  return {
    businessData: {
      businessName: fieldEmpty(businessName, 'Business Name'),
      doingBusinessAs: fieldEmpty(doingBusinessAs, 'Doing Business As'),
      businessDescription: validateBusinessDescription({ field: businessDescription, length: 250 }),
      businessPhone: validatePhoneNumber({ phoneNumber: businessPhone, name: 'Business Phone', canBeEmpty: isCanadianSoleProp }),
      website: fieldEmpty(website, 'Website') || invalidRegex({ field: website, name: 'Website', regex: FULL_WEBSITE_REGEX, customErrorMessage: 'Website must include https://' }) || (size(trim(website)) > 120 && WEBSITE_LENGTH_VALIDATION_ERROR),
      businessType: fieldEmpty(businessType, 'Business Type'),
      ownershipType: fieldEmpty(ownershipType, 'Ownership Type'),
      incorporationDate: validateDate({ date: incorporationDate, noFutureDate: true }),
      businessTaxId: validateMaskedTaxId({ taxId: businessTaxId, name: onboardingFormCountry === CAN ? 'Business Number' : 'Employer Identification Number', country: onboardingFormCountry, canBeEmpty: isCanadianSoleProp, businessType }),
      businessAddress: validateAddress({ address: businessAddress, canBeEmpty: isCanadianSoleProp }),
    },
  }
}

export default validateIdentityBusinessInfoForm
