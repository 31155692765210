import hideModalAction from 'utilities/actions/hideModalAction'
import getCertificateSigningRequestsRequest from 'utilities/actions/get/getCertificateSigningRequestsRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_CERTIFICATE_SIGNING_REQUEST_F_REQUEST } from 'constants/flowConstants'
import { UPLOAD_PAYMENT_PROCESSING_CERT } from 'constants/amplitudeConstants'

const submitUploadPaymentProcessingCertForm = (values, dispatch, props) => {
  const { file } = values
  const { csrId, credentials, identityId, updateUploadStatus } = props

  const fileReader = new FileReader()

  fileReader.onloadend = (e) => {
    const contents = e.target.result
    // TODO:  create a util for encoding a byte array into a base 64 encoded string
    const encodedFile = btoa(String.fromCharCode(...new Uint8Array(contents)))

    const valuesToSubmit = {
      data: encodedFile,
    }

    const queries = {
      linked_to: identityId,
    }

    dispatch({
      type: POST_CERTIFICATE_SIGNING_REQUEST_F_REQUEST,
      payload: {
        values: valuesToSubmit,
        id: csrId,
        credentials,
      },
      meta: {
        successCallback: () => {
          dispatch(hideModalAction())
          dispatch(getCertificateSigningRequestsRequest({ id: csrId, credentials, queries }))
        },
        actionId: sendAmplitudeActionEvent(UPLOAD_PAYMENT_PROCESSING_CERT, {
          credentials,
          valuesToSubmit,
        }),
      },
    })
  }

  fileReader.readAsArrayBuffer(file)
}

export default submitUploadPaymentProcessingCertForm
