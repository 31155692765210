import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CLOSE_SETTLEMENT } from 'constants/amplitudeConstants'
import { CLOSE } from 'constants/settlementConstants'

import {
  PATCH_SETTLEMENT_F_REQUEST,
  GET_SETTLEMENT_F_REQUEST,
} from 'constants/flowConstants'

const submitCloseSettlementForm = (values, dispatch, props) => {
  const { settlementId, credentials } = props

  dispatch({
    type: PATCH_SETTLEMENT_F_REQUEST,
    payload: {
      values: {
        action: CLOSE,
      },
      id: settlementId,
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch({
          type: GET_SETTLEMENT_F_REQUEST,
          payload: {
            id: settlementId,
            credentials,
          },
        })
      },
      actionId: sendAmplitudeActionEvent(CLOSE_SETTLEMENT, {
        settlementId,
      }),
    },
  })
}

export default submitCloseSettlementForm
