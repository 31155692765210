import moment from 'moment'
import showModalAction from 'utilities/actions/showModalAction'
import hideModalAction from 'utilities/actions/hideModalAction'
import redirectRequest from 'utilities/actions/redirectRequest'
import createQueryString from 'utilities/create/createQueryString'
import normalizeQueries from 'utilities/api/normalizeQueries'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_EXPORT } from 'constants/amplitudeConstants'
import { EXPORT_CONFIRMATION } from 'constants/modalConstants'
import { POST_EXPORTS_F_REQUEST } from 'constants/flowConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import { EXPORTS_PATH } from 'constants/pathConstants'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import omitBy from 'lodash/omitBy'
import map from 'lodash/map'
import snakeCase from 'lodash/snakeCase'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

const submitExportDataV2Form = (values, dispatch, props) => {
  const {
    fileNameCSV,
    fileResourceTitleCSV,
    credentials,
    queries = {},
    entityType,
    currentUserEmail,
    additionalExportValues = {},
  } = props

  const featureScreen = fileResourceTitleCSV ? fileResourceTitleCSV : fileNameCSV
  const date = moment().format(YYYY_MM_DD)
  const fileName = `export_${kebabCase(featureScreen)}-${date}`
  const credentialId = get(credentials, 'id')
  const querySearchString = createQueryString({ queries: omit(normalizeQueries({ queries, normalizationMap: backendNormalizationMap }), ['pagination', 'limit', 'offset', 'sort', 'tab']), encodeURI: false })
  const closeModal = () => dispatch(hideModalAction())

  const redirectToExportsLanding = () => {
    dispatch(redirectRequest({ path: EXPORTS_PATH({ credentialId }) }))
    closeModal()
  }

  const filteredFields = omitBy(values, (field) => !field)
  const formattedFields = map(filteredFields, (val, key) => snakeCase(key))

  const valuesToSubmit = {
    file_name: fileName,
    entity_type: entityType,
    fields: formattedFields,
    filters: isEmpty(querySearchString) ? 'null' : querySearchString,
  }

  dispatch({
    type: POST_EXPORTS_F_REQUEST,
    payload: {
      values: { ...valuesToSubmit, ...additionalExportValues },
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch(showModalAction({
          modalType: EXPORT_CONFIRMATION,
          modalProps: {
            currentUserEmail,
            closeModal,
            redirectToExportsLanding,
          },
        }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_EXPORT, {
        credentials,
        entityType,
        fields: formattedFields,
        filters: querySearchString,
      }),
    },
  })
}

export default submitExportDataV2Form
