import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import OrchestrationStatusC from 'components/Customer/Modals/OrchestrationStatusModal/OrchestrationStatusC'

const OrchestrationStatusModal = ({
  title = 'Status',
  orchestrationData = {},
  orchestrationOrder = {},
}) => {
  return (
    <GenericModal
      className='OrchestrationStatusModal'
      Component={OrchestrationStatusC}
      title={title}
      orchestrationData={orchestrationData}
      orchestrationOrder={orchestrationOrder}
    />
  )
}

OrchestrationStatusModal.propTypes = {
  title: PropTypes.string,
  orchestrationData: PropTypes.object,
  orchestrationOrder: PropTypes.array,
}

export default OrchestrationStatusModal
