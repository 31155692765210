import {
  DUMMY_V1,
  VISA_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'credibanco',
  id: 'DASHhcWKQqYzC21N1k6HjBrEJh',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2020-03-30-21_29_44-Credibanco-Logo-1.png',
  credentialsHost: 'https://credibanco.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: 'nEooVSych52a6Hq1m6Ry081N3PlCf9fw',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'VISA_V1'],
  dashboardServiceV2: true, // using this to control dashboard service version
}
