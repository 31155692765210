import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_DOMESTIC_TRANSACTION_COUNT,
  TRANSACTION_MONITORING_INTERNATIONAL_CARD_RATIO,
  INTERNATIONAL_TRANSACTION_COUNT,
} from 'constants/language/languageConstants'

const InternationalTransactionCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    internationalCardCount,
    domesticCardCount,
    displayInternationalCardRatio,
  ] = getMany(calculations, [
    'internationalCardCount',
    'domesticCardCount',
    'displayInternationalCardRatio',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={INTERNATIONAL_TRANSACTION_COUNT}
        headerData={{
          value: internationalCardCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_DOMESTIC_TRANSACTION_COUNT}
        headerData={{
          value: domesticCardCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_INTERNATIONAL_CARD_RATIO}
        headerData={{
          value: displayInternationalCardRatio,
        }}
      />
    </div>
  )
}

export default InternationalTransactionCards
