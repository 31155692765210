import React from 'react'
import DisplayUnmaskedItemC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import getPaymentInstrumentUnmaskAPI from 'api/dashboard/get/getPaymentInstrumentUnmaskAPI'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
  VANTIV_OMNI_TOKEN,
} from 'constants/paymentInstrumentConstants'

const formatPaymentInstrumentReviewData = ({ paymentInstrument = {} }) => {
  const {
    id,
    type,
    name,
    brand,
    lastFour,
    displayAccountType,
    bankCode,
    expirationDate,
    maskedCardNumber,
    maskedAccountNumber,
  } = paymentInstrument

  switch (type) {
    case PAYMENT_CARD:
      return [
        {
          label: 'Name on Account',
          value: name,
        },
        {
          label: 'Brand',
          value: brand,
        },
        {
          label: 'Card Number',
          value: maskedCardNumber,
        },
        {
          label: 'Expiration Date',
          value: expirationDate,
        },
      ]

    case BANK_ACCOUNT:
      return [
        {
          label: 'Name on Account',
          value: name,
        },
        {
          label: 'Account Type',
          value: displayAccountType,
        },
        {
          label: 'Account Number',
          value: (
            <DisplayUnmaskedItemC
              label='Account Number'
              id={id}
              api={getPaymentInstrumentUnmaskAPI}
              feKey='accountNumber'
              beKey='account_number'
              defaultValue={maskedAccountNumber}
            />
          ),
        },
        {
          label: 'Routing Number',
          value: bankCode,
        },
      ]

    case VANTIV_OMNI_TOKEN:
      return [
        {
          label: 'Name on Account',
          value: name,
        },
        {
          label: 'Brand',
          value: brand,
        },
        {
          label: 'Last Four',
          value: lastFour,
        },
        {
          label: 'Expiration Date',
          value: expirationDate,
        },
      ]

    default:
      return ''
  }
}

export default formatPaymentInstrumentReviewData
