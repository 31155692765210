import { HUBSPOT_DEAL_ID_REGEX } from 'constants/regexConstants'
import { HUBSPOT_DEAL_ID } from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
} from 'utilities/validate'

const validateCustomerOnboardingFormDealInfoForm = (values, props) => {
  const {
    accountExecutive,
    hubspotDealId,
    opportunityName,
    customerProfile,
    country,
  } = values

  return {
    accountExecutive: fieldEmpty(accountExecutive, 'Account Executive'),
    hubspotDealId: invalidOrEmptyRegex({
      field: hubspotDealId,
      name: HUBSPOT_DEAL_ID,
      regex: HUBSPOT_DEAL_ID_REGEX,
      customErrorMessage: `${HUBSPOT_DEAL_ID} must be exactly 11 characters long.`,
    }),
    opportunityName: fieldEmpty(opportunityName, 'Opportunity Name'),
    country: fieldEmpty(country, 'Country'),
    customerProfile: fieldEmpty(customerProfile, 'Customer Profile'),
  }
}

export default validateCustomerOnboardingFormDealInfoForm
