import './IdentityProcessingInfoFormS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import AccordionGroup from 'components/Customer/Shared/Page/PageSection/AccordionGroup'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import MultiFileUploader from 'components/Shared/Inputs/MultiFileUploader/MultiFileUploader'
import validateIdentityProcessingInfoForm from 'utilities/validate/validateIdentityProcessingInfoForm'
import warnIdentityProcessingInfoForm from 'utilities/warn/warnIdentityProcessingInfoForm'
import prevFlowStep from 'utilities/prevFlowStep'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import submitOnboardingStepForm from 'utilities/submit/submitOnboardingStepForm'
import { IDENTITY_PROCESSING_INFO_FORM } from 'constants/formConstants'
import { REFUND_POLICY_OPTIONS } from 'constants/underwritingConstants'
import { USA } from 'constants/countryConstants'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  ALERT_ICON,
  DOT_CIRCLE,
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

import {
  annualACHVolumeLabelMap,
  averageACHTransferAmountLabelMap,
  achMaxTransactionAmountLabelMap,
} from 'constants/labelConstants'

import {
  t,
  AMERICAN_EXPRESS_MID,
  ANNUAL_CARD_VOLUME,
  CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_AMEX_MID_TOOLTIP,
  CREATE_IDENTITY_MCC_CODE_TOOLTIP,
  CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP,
  CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP,
  DEFAULT_STATEMENT_DESCRIPTOR,
  MAX_CARD_TRANSACTION_AMOUNT_LABEL,
  MERCHANT_CATEGORY_CODE,
  TAX_AUTHORITY,
  PROCESSING_INFORMATION,
  CREATE_IDENTITY_AVERAGE_CARD_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_AVERAGE_ECHECK_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_MAIL_ORDER_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP,
  CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP,
  PREVIOUS_STEP,
  SAVE_AND_CONTINUE,
  YES,
  NO,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  CARD_VOLUME,
  ONLINE_PAYMENTS,
  IN_PERSON_PAYMENTS,
  MAIL_TELEPHONE_ORDER,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  OTHER,
  AVERAGE_CARD_TRANSACTION_AMOUNT,
  REFUND_POLICY,
  PLEASE_COMPLETE_STEP_MESSAGE,
  MERCHANT_CATEGORY_CODE_HELPER_TEXT,
  ANNUAL_CARD_VOLUME_TOOLTIP,
  CREATE_IDENTITY_GENERIC_ANNUAL_ACH_VOLUME_TOOLTIP,
  TRANSACTION_DISTRIBUTION_SUBTITLE,
  PAYMENT_VOLUME_DISTRIBUTION_SUBTITLE,
  GENERAL_INFORMATION,
  AMERICAN_EXPRESS_MERCHANT_ID_LABEL,
  FINIX_COLLECTS_THIS_INFORMATION_DESCRIPTION,
  DOES_BUSINESS_OPERATE_IN_FOLLOWING_INDUSTRIES,
  MCC_SELECTED_EDD_DOCUMENTATION_WARNING,
  FINIX_COLLECTS_EDD_DOCUMENTATION_DESCRIPTION,
  ADDITIONAL_EDD_DOCUMENTATION_REQUIRED,
  UPDATE_FILE_NAME,
  REMOVE_FILE,
  UPLOADED,
  CHECK_HERE_IF_YOU_DO_NOT_HAVE_THE_REQUESTED_DOCUMENTS,
  FILE_NAME,
  FILE_STATUS,
} from 'constants/language/languageConstants'

const config = {
  maxSize: 20000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const previouslyProcessedCardsButtonOptions = [
  { label: YES, value: 'true' },
  { label: NO, value: 'false' },
]

const IdentityProcessingInfoForm = ({
  disabled = false,
  handleSubmit = () => {},
  previouslyProcessedCreditCards,
  submitting = false,
  isFetchingOnboardingFormData = false,
  onboardingFormId = '',
  showTaxAuthority = false,
  mccList = [],
  achMaxTransactionAmount,
  isFormInvalid = false,
  onboardingFormCountry = USA,
  isBoardingToFinixV1Only = false,
  highRiskIndustries = [],
  requiredDocuments = [],
  associatedFiles = [],
  showHighRiskDocumentWarning = false,
  onEDDFileDrop = () => {},
  showUpdateFileNameModal = () => {},
  showRemoveFileModal = () => {},
  isMissingDocuments = false,
  optOut = {},
}) => {
  const className = classnames('IdentityProcessingInfoForm', { 'show-edd': !isEmpty(requiredDocuments) })

  return (
    <form className={className} onSubmit={handleSubmit(submitOnboardingStepForm)}>
      <div className='form-header'>
        <h3>{t(PROCESSING_INFORMATION)}</h3>
        <p className='p-1'>{t(FINIX_COLLECTS_THIS_INFORMATION_DESCRIPTION)}</p>
      </div>

      <RequiredFieldNotice />
      <div className='form-content processing-info'>
        {isFormInvalid && <ErrorMessage errorMessage={t(PLEASE_COMPLETE_STEP_MESSAGE)} />}

        <div className='general-information-section'>
          <h5>{t(GENERAL_INFORMATION)}</h5>

          <Field
            name='processingData.mcc'
            label={MERCHANT_CATEGORY_CODE}
            helperText={MERCHANT_CATEGORY_CODE_HELPER_TEXT}
            tooltip={CREATE_IDENTITY_MCC_CODE_TOOLTIP}
            component={SelectField}
            options={mccList}
          />

          { !isEmpty(highRiskIndustries) && (
            <div className='high-risk-industries'>
              <span className='label-2'>{t(DOES_BUSINESS_OPERATE_IN_FOLLOWING_INDUSTRIES)}</span>

              <div className='mts'>
                { map(highRiskIndustries, ({ name, label }) => (
                  <Field
                    component={CheckboxC}
                    name={`processingData.industries.${name}`}
                    label={label}
                  />
                ))}

                <div className='checkbox-divider' />

                <Field
                  component={CheckboxC}
                  name='processingData.noHighRiskIndustries'
                  label='None of the above'
                />
              </div>
            </div>
          )}

          { showHighRiskDocumentWarning && (
            <div className='high-risk-document-warning'>
              <i className={`fa fa-${ALERT_ICON}`} />
              <span className='p-1'>{t(MCC_SELECTED_EDD_DOCUMENTATION_WARNING)}<sup>**</sup></span>
            </div>
          )}

          {showTaxAuthority && (
          <Field
            name='processingData.taxAuthority'
            label={TAX_AUTHORITY}
            component={InputField}
          />
          )}

          <Field
            name='processingData.defaultStatementDescriptor'
            label={DEFAULT_STATEMENT_DESCRIPTOR}
            tooltip={CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP}
            placeholder='ACME Bakery'
            component={InputField}
          />

          <Field
            name='processingData.refundPolicy'
            label={REFUND_POLICY}
            component={SelectField}
            options={REFUND_POLICY_OPTIONS}
            className='refund-policy'
          />

          { isBoardingToFinixV1Only && (
            <>
              <Field
                name='processingData.previouslyProcessedCreditCards'
                component={RadioButton}
                label={AMERICAN_EXPRESS_MERCHANT_ID_LABEL}
                options={previouslyProcessedCardsButtonOptions}
              />

              { previouslyProcessedCreditCards === 'true' && (
                <div className='flex space-between'>
                  <Field
                    name='processingData.amexMid'
                    label={AMERICAN_EXPRESS_MID}
                    tooltip={CREATE_IDENTITY_AMEX_MID_TOOLTIP}
                    placeholder='0000000000'
                    component={InputField}
                    format={formatNumberField}
                    autofill={false}
                    required
                  />

                  {/* commenting this field out as requested by Product */}
                  {/* <Field */}
                  {/*  name='processingData.discoverMid' */}
                  {/*  label={DISCOVER_MID} */}
                  {/*  tooltip={CREATE_IDENTITY_DISCOVER_MID_TOOLTIP} */}
                  {/*  placeholder='000000000000000' */}
                  {/*  component={InputField} */}
                  {/*  format={formatNumberField} */}
                  {/*  autofill={false} */}
                  {/*  required={false} */}
                  {/* /> */}
                </div>
              )}
            </>
          )}
        </div>

        <div className='card-info-section'>
          <div className='flex space-between volume-amount-fields'>
            <AmountField
              name='processingData.annualCardVolume'
              placeholder='0.00'
              label={ANNUAL_CARD_VOLUME}
              tooltip={ANNUAL_CARD_VOLUME_TOOLTIP}
            />

            <AmountField
              name='processingData.annualACHVolume'
              placeholder='0.00'
              label={annualACHVolumeLabelMap(onboardingFormCountry)}
              tooltip={CREATE_IDENTITY_GENERIC_ANNUAL_ACH_VOLUME_TOOLTIP}
            />
          </div>

          <div className='flex space-between transfer-amount-fields'>
            <AmountField
              name='processingData.averageCardTransferAmount'
              placeholder='0.00'
              label={AVERAGE_CARD_TRANSACTION_AMOUNT}
              tooltip={CREATE_IDENTITY_AVERAGE_CARD_TRANSACTION_AMOUNT_TOOLTIP}
            />

            <AmountField
              name='processingData.averageACHTransferAmount'
              placeholder='0.00'
              label={averageACHTransferAmountLabelMap(onboardingFormCountry)}
              tooltip={CREATE_IDENTITY_AVERAGE_ECHECK_TRANSACTION_AMOUNT_TOOLTIP}
            />
          </div>

          <div className='flex space-between max-transaction-amount-fields'>
            <AmountField
              name='processingData.maxTransactionAmount'
              placeholder='0.00'
              label={MAX_CARD_TRANSACTION_AMOUNT_LABEL}
              tooltip={CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP}
              disabled={!!onboardingFormId}
            />

            {((!!achMaxTransactionAmount && onboardingFormId) || (!onboardingFormId)) && (
            <AmountField
              name='processingData.achMaxTransactionAmount'
              placeholder='0.00'
              label={achMaxTransactionAmountLabelMap(onboardingFormCountry)}
              tooltip={CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP}
              disabled={!!onboardingFormId}
            />
            )}
          </div>
        </div>

        <div className='card-volume-section'>
          <h5>{CARD_VOLUME}</h5>
          <div className='label-2 secondary'>{TRANSACTION_DISTRIBUTION_SUBTITLE}</div>
          <div className='flex space-between card-volume-fields'>
            <PercentageField
              name='processingData.ecommercePercentage'
              label={ONLINE_PAYMENTS}
              tooltip={CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />

            <PercentageField
              name='processingData.cardPresentPercentage'
              label={IN_PERSON_PAYMENTS}
              tooltip={CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />

            <PercentageField
              name='processingData.mailOrderTelephoneOrderPercentage'
              label={MAIL_TELEPHONE_ORDER}
              tooltip={CREATE_IDENTITY_MAIL_ORDER_PERCENTAGE_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />
          </div>
        </div>

        <div className='payment-volume-section'>
          <h5>{PAYMENT_VOLUME_BY_CUSTOMER_TYPE}</h5>
          <div className='label-2 secondary'>{PAYMENT_VOLUME_DISTRIBUTION_SUBTITLE}</div>
          <div className='flex space-between payment-volume-fields'>
            <PercentageField
              name='processingData.businessToBusinessVolumePercentage'
              label={BUSINESS_TO_BUSINESS}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />

            <PercentageField
              name='processingData.businessToConsumerVolumePercentage'
              label={BUSINESS_TO_CONSUMER}
              tooltip={CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />

            <PercentageField
              name='processingData.otherVolumePercentage'
              label={OTHER}
              tooltip={CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP}
              onlyWholeNumbers
              placeholder='0'
            />
          </div>
        </div>
      </div>

      { !isEmpty(requiredDocuments) && (
        <>
          <div className='form-header'>
            <h3>** {t(ADDITIONAL_EDD_DOCUMENTATION_REQUIRED)}</h3>
            <p className='p-1'>{t(FINIX_COLLECTS_EDD_DOCUMENTATION_DESCRIPTION)}</p>
          </div>

          <div className='form-content edd-documents'>
            <AccordionGroup>
              { map(requiredDocuments, ({ type, name, description }) => {
                const uploadedAssociatedFiles = filter(associatedFiles, ({ type: fileType }) => fileType === type)
                const hasUploadedFiles = !isEmpty(uploadedAssociatedFiles)
                const hasOptedOut = get(optOut, type)
                const iconClassName = (hasUploadedFiles || hasOptedOut) ? `fa fa-${CHECK_CIRCLE_ICON}` : `fas ${DOT_CIRCLE}`
                const title = <div className='title'><i className={iconClassName} /> {name}</div>
                const instructions = <span>Drag and drop files or <span className='link-1' style={{ fontSize: '0.75rem' }}>click here</span> to upload - Accepted formats: jpg, png, pdf, tiff </span>

                const fileRows = map(uploadedAssociatedFiles, ({ id, display_name: displayName }) => {
                  const fileActions = [
                    {
                      label: UPDATE_FILE_NAME,
                      action: () => showUpdateFileNameModal({ fileId: id, fileName: displayName, associatedFiles, onboardingFormId }),
                    },
                    {
                      label: REMOVE_FILE,
                      action: () => showRemoveFileModal({ fileId: id, fileName: displayName, associatedFiles, onboardingFormId, removeFile: true }),
                    },
                  ]

                  return [
                    <div>{displayName}</div>,
                    <div><i className={`fa fa-${CHECK_CIRCLE_ICON}`} />{t(UPLOADED)}</div>,
                    <div className='p-0'><TableRowActionsC actions={fileActions} /></div>,
                  ]
                })

                return (
                  <Accordion title={title}>
                    <h5>{name}</h5>
                    <p className='label-2 secondary'>{description}</p>

                    {!hasOptedOut && (
                      <div className='file-uploader'>
                        <MultiFileUploader
                          config={config}
                          instructions={instructions}
                          onDrop={(files) => onEDDFileDrop({ files, fileType: type })}
                        />

                        {isFetchingOnboardingFormData && (
                          <div className='file-uploader-mask'>
                            <Loader />
                          </div>
                        )}
                      </div>
                    )}

                    {!hasUploadedFiles && (
                      <Field
                        component={CheckboxC}
                        name={`processingData.optOut.${type}`}
                        label={CHECK_HERE_IF_YOU_DO_NOT_HAVE_THE_REQUESTED_DOCUMENTS}
                      />
                    )}

                    {hasUploadedFiles && (
                      <div className='uploaded-files'>
                        <div className='header tal'>{t(FILE_NAME)}</div>
                        <div className='header'>{t(FILE_STATUS)}</div>
                        <div className='header' />

                        {map(fileRows, (row, rowIndex) => (
                          <Fragment key={`overview-row-${rowIndex}`}>
                            {map(row, (column, columnIndex) => (
                              <Fragment key={`overview-row-${rowIndex}-column-${columnIndex}`}>{column}</Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </div>
                    )}
                  </Accordion>
                )
              })}
            </AccordionGroup>
          </div>
        </>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button variant='tertiary' className='left-button' onClick={prevFlowStep} label={PREVIOUS_STEP} />
          </div>
          <div className='exit-and-save-container'>
            <Button type='submit' label={SAVE_AND_CONTINUE} submitting={submitting} disabled={disabled || isMissingDocuments} />
          </div>
        </div>
      </div>
    </form>
  )
}

IdentityProcessingInfoForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  previouslyProcessedCreditCards: PropTypes.bool,
  submitting: PropTypes.bool,
  onboardingFormId: PropTypes.string,
  showTaxAuthority: PropTypes.bool,
  mccList: PropTypes.array,
  achMaxTransactionAmount: PropTypes.string,
  isFormInvalid: PropTypes.bool,
  onboardingFormCountry: PropTypes.string,
  isBoardingToFinixV1Only: PropTypes.bool,
  highRiskIndustries: PropTypes.array,
  requiredDocuments: PropTypes.array,
  associatedFiles: PropTypes.array,
  showHighRiskDocumentWarning: PropTypes.bool,
  onEDDFileDrop: PropTypes.func,
  showUpdateFileNameModal: PropTypes.func,
  showRemoveFileModal: PropTypes.func,
  isMissingDocuments: PropTypes.bool,
  optOut: PropTypes.object,
}

export default reduxForm({
  form: IDENTITY_PROCESSING_INFO_FORM,
  validate: validateIdentityProcessingInfoForm,
  warn: warnIdentityProcessingInfoForm,
})(IdentityProcessingInfoForm)
