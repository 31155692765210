import frontendRoleModel from './frontendRoleModel'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'

const frontendRolesModel = ({ data: roles }) => {
  const roleModels = map(roles, (role) => frontendRoleModel({ data: role }))

  return keyBy(roleModels, 'id')
}

export default frontendRolesModel
