import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import downloadPaymentsFile from 'utilities/downloadPaymentsFile'
import { REPORT_DOWNLOAD } from 'constants/apiConstants'
import { DOWNLOAD_REPORT } from 'constants/amplitudeConstants'

async function downloadReport({ id, name, credentials = {} }) {
  await downloadPaymentsFile({
    url: REPORT_DOWNLOAD({ id }),
    fileName: name,
    credentials,
  })

  sendAmplitudeActionEvent(DOWNLOAD_REPORT, {
    credentials,
    fileName: name,
  })
}

export default downloadReport
