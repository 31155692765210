import paymentsAPI from 'utilities/api/paymentsAPI'
import { ONBOARDING_FORM, ONBOARDING_FORMS } from 'constants/apiConstants'

const getOnboardingFormDataAPI = ({ id, credentials }) => {
  return paymentsAPI.get({
    path: ONBOARDING_FORM({ onboardingFormId: id }),
    credentials,
    service: ONBOARDING_FORMS,
  })
}

export default getOnboardingFormDataAPI
