import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import Loader from 'components/Shared/Loader/Loader'
import { GET_MEMBERS } from 'constants/flowConstants'
import { MEMBERS } from 'constants/linkConstants'
import { getMembersSelector } from 'state-layer/selectors'

const RoleMembers = ({
  flowValues = {},
  memberPath = '',
  isFetching = false,
  entityDataFound = false,
  columnDescriptors = [],
  extraProps = {},
}) => {
  return (
    <div className='RoleMembers'>
      { !entityDataFound && <Loader />}

      { entityDataFound && (
        <TableC
          flow={GET_MEMBERS}
          flowValues={flowValues}
          extraProps={extraProps}
          columnDescriptors={columnDescriptors}
          selector={getMembersSelector}
          linksKey={MEMBERS}
          path={memberPath}
          isFetching={isFetching}
          fileNameCSV='Role_Members'
          emptyMessage='There are currently no team members assigned to this role'
        />
      )}
    </div>
  )
}

RoleMembers.propTypes = {
  flowValues: PropTypes.object,
  memberPath: PropTypes.string,
  isFetching: PropTypes.bool,
  entityDataFound: PropTypes.bool,
  columnDescriptors: PropTypes.array,
  extraProps: PropTypes.object,
}

export default RoleMembers
