import co from 'co'
import getTransferAPI from 'api/dashboard/get/getTransferAPI'
import getReceiptsAPI from 'api/finix/get/getReceiptsAPI'
import getPurchaseAPI from 'api/finix/get/getPurchaseAPI'
import getPaymentLinkAPI from 'api/finix/get/getPaymentLinkAPI'
import normalizeResponse from 'utilities/api/normalizeResponse'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendPurchaseModel from 'utilities/create/models/frontendPurchaseModel'
import frontendPaymentLinkModel from 'utilities/create/models/frontendPaymentLinkModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import frontendReceiptsModel from 'utilities/create/models/frontendReceiptsModel'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import { PAYMENT_LINK } from 'constants/flowConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'

function * getPurchaseO ({
  id,
  credentials,
}) {
  const { data: response } = yield getPurchaseAPI({ id, credentials })
  const normalizedResponse = frontendPurchaseModel({ data: normalizeResponse({ content: response }) })
  const entityId = get(normalizedResponse, 'entityId')
  const entityType = get(normalizedResponse, 'entityType')
  const transferId = get(normalizedResponse, 'transferId')

  let normalizedPaymentLink

  if (isEqual(entityType, PAYMENT_LINK)) {
    const { data: paymentLink } = yield getPaymentLinkAPI({
      id: entityId,
      credentials,
    })

    normalizedPaymentLink = frontendPaymentLinkModel({ data: normalizeResponse({ content: paymentLink }) })
  }

  const { data: transferResponse } = yield getTransferAPI({ id: transferId, credentials })
  const transfer = frontendTransferModel({ data: normalizeResponse({ content: transferResponse }) })

  const { data: receiptsResponse } = yield getReceiptsAPI({ credentials, queries: { entity_id: transferId } })
  const receipts = get(receiptsResponse, '_embedded.receipts', [])
  const receiptModels = frontendReceiptsModel({ data: normalizeResponse({ content: receipts }) })

  const purchase = merge({}, normalizedResponse, removeUndefined({
    transfer,
    paymentLink: normalizedPaymentLink,
    receipts: orderBy(receiptModels, ['createdAt'], ['desc']),
  }))

  return createSuccessResponse({
    data: [purchase],
  })
}

export default co.wrap(getPurchaseO)
