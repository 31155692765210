import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACCESS_FORM_PRINCIPAL_TAX_ID_UNMASK } from 'constants/apiConstants'

const getAccessFormPrincipalTaxIdUnmaskAPI = ({ id }) => {
  return dashboardAPI.get({
    path: ACCESS_FORM_PRINCIPAL_TAX_ID_UNMASK({ accessFormId: id }),
  })
}

export default getAccessFormPrincipalTaxIdUnmaskAPI
