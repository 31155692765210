import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'

const getResponseLinks = (response) => {
  const links = get(response, '_links') || get(response, 'links')

  if (!links) {
    return {}
  }

  return reduce(links, (total, link, name) => {
    const href = get(link, 'href')

    if (href) {
      return merge({}, total, {
        [name]: href,
      })
    }

    return total
  }, {})
}

export default getResponseLinks
