import './ApplicationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getApplicationOwnerIdentitySelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { APPLICATIONS } from 'constants/linkConstants'
import { GET_APPLICATIONS } from 'constants/flowConstants'
import { APPLICATIONS_ICON } from 'constants/iconConstants'
import { APPLICATIONS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  APPLICATIONS_RESOURCE_TITLE,
  APPLICATION_SUB_TITLE,
  APPLICATIONS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const Applications = ({
  applicationsPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  actions = [],
  showTwoTabs = false,
  columnDescriptors = [],
}) => {
  return (
    <div className='Applications'>
      {!showTwoTabs && (
      <HeaderV2C
        title={APPLICATIONS_RESOURCE_TITLE}
        subTitle={APPLICATION_SUB_TITLE}
        learnMoreLink={APPLICATIONS_LEARN_MORE_LINK}
      />
      )}

      <TableC
        flow={GET_APPLICATIONS}
        columnDescriptors={columnDescriptors}
        selector={getApplicationOwnerIdentitySelector}
        initialQueries={initialQueries}
        linksKey={APPLICATIONS}
        allowedFilters={allowedFilters}
        path={applicationsPath}
        isFetching={isFetching}
        actions={actions}
        emptyMessage={APPLICATIONS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${APPLICATIONS_ICON}`}
        fileNameCSV={APPLICATIONS_RESOURCE_TITLE}
      />
    </div>
  )
}

Applications.propTypes = {
  applicationsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  showTwoTabs: PropTypes.bool,
  columnDescriptors: PropTypes.array,
}

export default Applications
