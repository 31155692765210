import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { PATCH_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import { COMPLETE_MERCHANT_BOARDING_STEP } from 'constants/amplitudeConstants'
import { BUSINESS_TO_OWNERSHIP_TYPE_MAP, HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS } from 'constants/identityConstants'
import get from 'lodash/get'
import set from 'lodash/set'
import includes from 'lodash/includes'

const submitOnboardingWelcomeStepForm = (values, dispatch, props) => {
  const action = PATCH_ONBOARDING_FORM_DATA_F_REQUEST
  const onboardingFormId = get(props, 'onboardingFormId')
  const businessType = get(values, 'businessData.businessType')

  const valuesToSubmit = { id: onboardingFormId, ...values }

  // patch ownership type if business type field is one of our hidden ownership type options
  if (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)) {
    set(valuesToSubmit, 'businessData.ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType])
  }

  dispatch({
    type: action,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: () => {
        updateUrlQueries({ activeStep: 0 })
      },
      actionId: sendAmplitudeActionEvent(COMPLETE_MERCHANT_BOARDING_STEP, {
        id: onboardingFormId,
      }),
    },
  })
}

export default submitOnboardingWelcomeStepForm
