import './IdentityProvisionMerchantModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import IdentityProvisionMerchantC from 'components/Customer/Pages/IdentityProvisionMerchant/IdentityProvisionMerchantC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { ENABLE_IDENTITY_MERCHANTS } from 'constants/language/languageConstants'

const IdentityProvisionMerchantModal = ({ identityId, settlementAliasWarningObject }) => {
  return (
    <GenericModal
      title={ENABLE_IDENTITY_MERCHANTS}
      className='IdentityProvisionMerchantModal'
      Component={IdentityProvisionMerchantC}
      identityId={identityId}
      settlementAliasWarningObject={settlementAliasWarningObject}
    />
  )
}

IdentityProvisionMerchantModal.propTypes = {
  identityId: PropTypes.string,
  settlementAliasWarningObject: PropTypes.object,
}

export default IdentityProvisionMerchantModal
