import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM } from 'constants/apiConstants'

const patchComplianceFormAPI = ({ values, id, meta }) => {
  return dashboardAPI.put({
    values,
    path: COMPLIANCE_FORM({ complianceFormId: id }),
    meta,
  })
}

export default patchComplianceFormAPI
