import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  AMOUNT,
  APPLICATION,
  CREATED_ON,
  ID,
  LABEL,
  MERCHANT_ACCOUNT,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'identity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
  }),
  column(LABEL, 'label', { className: 'medium' }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    sort: amountRange,
  }),
  column('', '', {
    className: 'empty',
    formatter: () => <span />,
  }),
]

export default columnDescriptors
