import * as moment from 'moment'
import formatDateDisplayData from 'utilities/formatters/formatDateDisplayData'
import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import { DATE_TIME_FORMAT } from 'constants/timeConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import replace from 'lodash/replace'

const localTimezone = replace(moment.tz.guess(), '_', ' ')

const SubscriptionEnrollmentFields = [
  {
    name: 'enrollmentNickname',
    label: 'Enrollment Nickname',
  },
  {
    name: 'startedAt',
    label: 'Start Date and Time',
    formatter: (date) => formatDateDisplayData({ date, localTimezone }),
  },
  {
    name: 'endedAt',
    label: 'End Date and Time',
    formatter: (date) => formatDateDisplayData({ date, localTimezone }),
  },
  {
    name: 'tags',
    label: 'Tags',
    formatter: (tags) => TagsDisplaySection({
      data: map(tags, ({ name, value }) => ({ value, label: name })),
      emptyMessage: '-',
    }),
  },
]

const SubscriptionEnrollmentDisplayData = (values) => map(SubscriptionEnrollmentFields, ({ name, label, formatter }) => {
  return {
    label,
    value: formatter ? formatter(get(values, name, {})) : get(values, name),
  }
})

export default SubscriptionEnrollmentDisplayData
