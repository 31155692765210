import { GET_TRANSFER_REVERSALS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getTransferReversalsRequest = ({
  transferId,
  credentials,
}) => createAction({
  type: GET_TRANSFER_REVERSALS_F_REQUEST,
  id: transferId,
  credentials,
})

export default getTransferReversalsRequest
