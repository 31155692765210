import { goToPath } from 'state-layer/history'
import numeral from 'numeral'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import { NEW_CUSTOMER_ONBOARDING_FORM_SUCCESS_PATH } from 'constants/pathConstants'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import head from 'lodash/head'
import _values from 'lodash/values'

import {
  PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM_F_REQUEST,
  POST_CUSTOMER_ONBOARDING_FORM_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_CUSTOMER_ONBOARDING_FORM,
  UPDATE_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM,
} from 'constants/amplitudeConstants'

const submitCustomerOnboardingForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const credentialId = get(credentials, 'id')
  const formValues = get(props, 'formValues')
  const dealInfoValues = get(formValues, 'dealInfo')
  const customerInfoValues = get(formValues, 'customerInfo')

  const {
    accountExecutive,
    hubspotDealId,
    opportunityName,
    customerProfile,
    country,
  } = dealInfoValues

  const valuesToSubmit = {
    requested_by: get(accountExecutive, 'value'),
    deal_name: opportunityName,
    hubspot_deal_id: hubspotDealId,
    deal_type: get(customerProfile, 'value'),
    country,
  }

  dispatch({
    type: POST_CUSTOMER_ONBOARDING_FORM_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        // on success, update the access form
        const accessFormId = get(head(_values(newValues)), 'accessFormId')
        const customerOnboardingFormId = get(head(_values(newValues)), 'id')

        const {
          businessName = null,
          doingBusinessAs = null,
          businessMerchantCount = null,
          businessWebsite = null,
          businessWebsiteDomains = null,
          businessEmail = null,
          businessPhone = null,
          businessAddress,
          mccCode,
          annualTransactionCount = null,
          annualCardVolume = null,
          annualACHVolume = null,
          maxCardTransactionAmount = null,
          maxACHTransactionAmount = null,
          ecommercePercentage = null,
          cardPresentPercentage = null,
          businessToBusinessVolumePercentage = null,
          businessToConsumerVolumePercentage = null,
          otherVolumePercentage = null,
        } = customerInfoValues

        const [
          businessAddressLine1,
          businessAddressLine2,
          businessAddressCity,
          businessAddressRegion,
          businessAddressPostalCode,
          businessAddressCountry,
        ] = getMany(businessAddress, [
          'line1',
          'line2',
          'city',
          'region',
          'postalCode',
          'country',
        ])

        const businessAddressValues = removeUndefined({
          line1: businessAddressLine1,
          line2: businessAddressLine2,
          city: businessAddressCity,
          region: businessAddressRegion,
          postal_code: businessAddressPostalCode,
          country: businessAddressCountry,
        })

        const customerInfoValuesToSubmit = {
          access_form_id: accessFormId,
          business_data: {
            business_name: businessName,
            doing_business_as: doingBusinessAs,
            business_merchant_count: businessMerchantCount,
            business_website: businessWebsite,
            business_website_domains: businessWebsiteDomains,
            business_email: businessEmail,
            business_phone: businessPhone,
            business_address: isEmpty(businessAddressValues) ? null : businessAddressValues,
            mcc: isEmpty(mccCode) ? null : map(mccCode, (code) => { return code.value }),
            annual_transaction_count: annualTransactionCount,
            annual_card_volume: annualCardVolume ? normalizeBackendAmount(annualCardVolume) : null,
            annual_ach_volume: annualACHVolume ? normalizeBackendAmount(annualACHVolume) : null,
            max_transaction_amount: maxCardTransactionAmount ? normalizeBackendAmount(maxCardTransactionAmount) : null,
            ach_max_transaction_amount: maxACHTransactionAmount ? normalizeBackendAmount(maxACHTransactionAmount) : null,
            card_volume_distribution: {
              ecommerce_percentage: ecommercePercentage ? numeral(ecommercePercentage).value() : null,
              card_present_percentage: cardPresentPercentage ? numeral(cardPresentPercentage).value() : null,
            },
            volume_distribution_by_business_type: {
              other_volume_percentage: otherVolumePercentage ? numeral(otherVolumePercentage).value() : null,
              business_to_business_volume_percentage: businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : null,
              business_to_consumer_volume_percentage: businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : null,
            },
          },
        }

        dispatch({
          type: PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM_F_REQUEST,
          payload: {
            values: customerInfoValuesToSubmit,
            id: customerOnboardingFormId,
            credentials,
          },
          meta: {
            successCallback: () => {
              goToPath({
                pathname: NEW_CUSTOMER_ONBOARDING_FORM_SUCCESS_PATH({ credentialId, customerOnboardingFormId }),
              })
            },
            overwriteReducer: true,
            actionId: sendAmplitudeActionEvent(UPDATE_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM, {
              accessFormId,
              customerOnboardingFormId,
            }),
          },
        })
      },
      actionId: sendAmplitudeActionEvent(CREATE_CUSTOMER_ONBOARDING_FORM, {
        requestedBy: accountExecutive,
        dealName: opportunityName,
        dealType: customerProfile,
        hubspotDealId,
      }),
    },
  })
}

export default submitCustomerOnboardingForm
