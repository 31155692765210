import getWelcomeEmailTemplateAPI from 'api/dashboard/get/getWelcomeEmailTemplateAPI'
import patchEmailTemplateAPI from 'api/dashboard/patch/patchEmailTemplateAPI'
import postDashboardFileAPI from 'api/dashboard/post/postDashboardFileAPI'
import postEmailTemplateAPI from 'api/dashboard/post/postEmailTemplateAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import get from 'lodash/get'
import co from 'co'

import {
  WELCOME_EMAIL_PASSWORD,
} from 'constants/apiConstants'

// TODO: refactor as separate util with unit test
const insertUrlToHtml = (url, html) => {
  const start = html.indexOf('<img')
  const end = html.indexOf('>', start)
  const imageElement = html.substring(start, end + 1)

  const srcStart = imageElement.indexOf('src')
  const srcEndSpace = imageElement.indexOf(' ', srcStart)
  const originalSrc = imageElement.substring(srcStart, srcEndSpace)

  return html.replace(originalSrc, `src="${url}"`)
}

function * patchWelcomeEmailTemplateO ({
  values: {
    html,
    text,
    subject,
    logo,
    templateExist,
    welcomeEmail,
  },
}) {
  let fileResponse

  if (logo) {
    const formData = new FormData()
    formData.append('file', logo)

    fileResponse = yield postDashboardFileAPI({ values: formData })
  }

  const url = get(fileResponse, 'data.url', '')
  const welcomeEmailTemplates = {
    email_template_name: `${getCurrentPlatformName()}-${WELCOME_EMAIL_PASSWORD}`,
    email_html_part: url ? insertUrlToHtml(url, html) : html,
    email_text_part: text,
    email_subject_part: subject,
  }

  const { id } = welcomeEmail
  const emailAPI = templateExist ? patchEmailTemplateAPI : postEmailTemplateAPI
  const { error } = yield emailAPI({ values: welcomeEmailTemplates, id })

  if (error) {
    return createErrorResponse({ error })
  }

  const { data: templateObject } = yield getWelcomeEmailTemplateAPI({})

  return createSuccessResponse({ data: templateObject })
}

export default co.wrap(patchWelcomeEmailTemplateO)
