import React, { Component } from 'react'
import { connect } from 'react-redux'
import InstantPayouts from './InstantPayouts'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `fundingTransferAttemptsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const settlementId = get(props, 'settlementId')

  return {
    isFetching,
    credentials,
    settlementId,
  }
}

class InstantPayoutsC extends Component {
  render() {
    return (
      <InstantPayouts
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(InstantPayoutsC)
