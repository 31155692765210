import getDashboardUser from 'utilities/get/getDashboardUser'
import { getState } from 'state-layer/configureStore'
import get from 'lodash/get'

const getCurrentUser = (state = getState()) => {
  const currentUser = get(state, 'currentUserR.items', {})
  const dashboardUser = getDashboardUser() || {}

  return {
    ...currentUser,
    ...dashboardUser,
  }
}

export default getCurrentUser
