import map from 'lodash/map'
import get from 'lodash/get'
import formatAddress from 'utilities/formatters/formatAddress'
import formatDateObject from 'utilities/formatters/formatDateObject'

import {
  EMAIL_ADDRESS,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

const AssociatedIdentityFields = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    formatter: (date) => formatDateObject({ date }),
  },
  {
    name: 'personalAddress',
    label: 'Address',
    formatter: (address) => formatAddress({ address }),
  },
  {
    name: 'ssn',
    label: SOCIAL_SECURITY_NUMBER,
  },
  {
    name: 'principalPercentageOwnership',
    label: 'Principal Percentage Ownership (%)',
  },
  {
    name: 'ownershipType',
    label: 'Ownership Type',
  },
  {
    name: 'phone',
    label: 'Personal Phone',
  },
  {
    name: 'email',
    label: EMAIL_ADDRESS,
  },
]

const AssociatedIdentityDisplayData = (values) => map(AssociatedIdentityFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name, {})) : get(values, name),
}))

export default AssociatedIdentityDisplayData
