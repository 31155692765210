import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { countryNameByCountryCode } from 'constants/countryConstants'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

const frontendCustomerOnboardingFormModel = ({ data: customerOnboardingForm }) => {
  const [
    id,
    accessFormId,
    createdAt,
    dealName,
    dealType,
    provisionSandbox,
    requestedBy,
    hubspotDealId,
    salesforceOpportunityId,
    sandboxApplicationId,
    status,
    updatedAt,
    userEmailAddress,
    submittedAt,
    country,
  ] = getMany(customerOnboardingForm, [
    'id',
    'access_form_id',
    'created_at',
    'deal_name',
    'deal_type',
    'provision_sandbox',
    'requested_by',
    'hubspot_deal_id',
    'salesforce_opportunity_id',
    'sandbox_application_id',
    'status',
    'updated_at',
    'user_email_address',
    'submitted_at',
    'country',
  ])

  return removeUndefined({
    id,
    accessFormId,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    dealName,
    dealType,
    displayDealType: startCase(lowerCase(dealType)),
    provisionSandbox,
    requestedBy,
    hubspotDealId,
    salesforceOpportunityId,
    sandboxApplicationId,
    status,
    displayStatus: snakeCaseToTitleCase({ key: status }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    userEmailAddress,
    submittedAt,
    displaySubmittedAt: formatDate({ date: submittedAt }),
    country,
    displayCountry: countryNameByCountryCode[country],
  })
}

export default frontendCustomerOnboardingFormModel
