import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationDisputes from './ApplicationDisputes'
import { DISPUTES_PATH } from 'constants/pathConstants'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `applicationDisputesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const disputePath = DISPUTES_PATH({ credentialId })

  const flowValues = {
    applicationId,
  }

  return {
    applicationId,
    flowValues,
    isFetching,
    disputePath,
  }
}

class ApplicationDisputesC extends Component {
  render() {
    return (
      <ApplicationDisputes {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationDisputesC)
