import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import CheckboxGroupField from 'components/Shared/Inputs/CheckboxGroupField/CheckboxGroupField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import CustomFieldArray from 'components/Shared/Inputs/CustomFieldArray/CustomFieldArray'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import validateComplianceAssessmentInformationForm from 'utilities/validate/validateComplianceAssessmentInformationForm'
import { COMPLIANCE_ASSESSMENT_INFORMATION_SUBMISSION_INSTRUCTIONS } from 'constants/language/languageConstants'
import { COMPLIANCE_ASSESSMENT_INFORMATION_FORM } from 'constants/formConstants'

import {
  COMPLIANCE_MERCHANT_BUSINESS_OPTIONS,
  COMPLIANCE_PAYMENT_CHANNELS_OPTIONS,
  COMPLIANCE_FACILITY_LOCATION_OPTIONS,
  COMPLIANCE_PAYMENT_APPLICATION_OPTIONS,
  COMPLIANCE_THIRD_PARTY_SERVICE_PROVIDER_OPTIONS,
  COMPLIANCE_ELIGIBILITY_TO_COMPLETE_SAQ_A_OPTIONS,
  YES_NO_YES_NO_RADIO_OPTIONS,
} from 'constants/complianceConstants'

const ComplianceAssessmentInformationForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  oneOrMorePaymentApplications = '',
  usedQir = '',
  companyShareCardholderDataWithThirdPartyServiceProviders = '',
}) => {
  return (
    <form className='ComplianceAssessmentInformationForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Section 1: Assessment Information</h1>
        <h3>Instructions for submission:</h3>
        <div>{COMPLIANCE_ASSESSMENT_INFORMATION_SUBMISSION_INSTRUCTIONS}</div>
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Part 1: Merchant and Qualified Assessor Information</h2>

        <div className='subsection'>
          <h3>Part 1a. Merchant Organization Information</h3>
          <span><strong>Note: </strong><i>Part 1 will be filled out automatically once this template is generated. Please skip to Part 2.</i></span>

          <Field
            label='Company Name'
            name='businessName'
            component={InputField}
            disabled
          />

          <Field
            label='DBA (Doing business as)'
            name='doingBusinessAs'
            component={InputField}
            disabled
          />

          <Field
            label='Contact Name'
            name='contactName'
            component={InputField}
            disabled
          />

          <Field
            label='Title'
            name='title'
            component={InputField}
            disabled
          />

          <Field
            label='Telephone'
            name='telephone'
            component={InputField}
            disabled
          />

          <Field
            label='Email'
            name='email'
            component={InputField}
            disabled
          />

          <Field
            label='URL'
            name='url'
            component={InputField}
            disabled
          />

          <Field
            label='Business Address'
            name='businessAddress'
            component={InputField}
            disabled
          />

          <Field
            label='City'
            name='city'
            component={InputField}
            disabled
          />

          <div className='flex'>
            <Field
              label='State'
              name='state'
              component={InputField}
              disabled
            />

            <Field
              label='Zip Code'
              name='zipCode'
              component={InputField}
              disabled
            />
          </div>

          <Field
            label='Country'
            name='country'
            component={InputField}
            disabled
          />
        </div>

        <div className='subsection'>
          <h3>Part 1b. Qualified Security Assessor Company Information (if applicable)</h3>

          <Field
            label='Company Name'
            name='qsaBusinessName'
            component={InputField}
          />

          <Field
            label='Lead QSA Contact Name'
            name='qsaContactName'
            component={InputField}
          />

          <Field
            label='Title'
            name='qsaTitle'
            component={InputField}
          />

          <Field
            label='Telephone'
            name='qsaTelephone'
            component={InputField}
          />

          <Field
            label='Email'
            name='qsaEmail'
            component={InputField}
          />

          <Field
            label='URL'
            name='qsaUrl'
            component={InputField}
          />

          <AddressFieldset
            label='Business Address'
            name='qsaBusinessAddress'
            hideLine2
          />
        </div>
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Part 2: Executive Summary</h2>

        <div className='subsection'>
          <Field
            label='Part 2a. Type of Merchant Business (check all that apply)'
            name='typeMerchantBusiness'
            component={CheckboxGroupField}
            options={COMPLIANCE_MERCHANT_BUSINESS_OPTIONS}
          />

          <Field
            label='Others (Please specify. Max of 6 lines.)'
            name='typeMerchantBusinessOther'
            component={TextAreaField}
          />

          <Field
            label='What types of payment channels does your business serve?'
            name='typePaymentChannelsServe'
            component={CheckboxGroupField}
            options={COMPLIANCE_PAYMENT_CHANNELS_OPTIONS}
          />

          <Field
            label='What payment channels are covered by this SAQ?'
            name='paymentChannelsCovered'
            component={CheckboxGroupField}
            options={COMPLIANCE_PAYMENT_CHANNELS_OPTIONS}
          />
        </div>

        <div className='subsection'>
          <h3>Part 2b. Description of Payment Card Business</h3>

          <Field
            label='How and in what capacity does your business store, process and/or transmit cardholder data?  (Max of 6 lines)'
            name='howAndInWhatCapacityDoesYourBusinessHandleCardholderData'
            component={TextAreaField}
          />
        </div>

        <div className='subsection'>
          <h3>Part 2c. Locations</h3>

          <Field
            label='List types of facilities (for example, retail outlets, corporate offices, data centers, call centers, etc.) and a summary of locations included in the PCI DSS review.'
            name='facilityLocations'
            component={CustomFieldArray}
            addButtonLabel='Facility'
            fieldOptions={COMPLIANCE_FACILITY_LOCATION_OPTIONS}
            maxFields={6}
          />
        </div>

        <div className='subsection'>
          <h3>Part 2d. Payment Application</h3>

          <Field
            label='Does the organization use one or more Payment Applications?'
            name='oneOrMorePaymentApplications'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { oneOrMorePaymentApplications === 'YES' && (
            <Field
              label='Provide the following information regarding the Payment Applications your organization uses:'
              name='paymentApplicationsUsed'
              component={CustomFieldArray}
              addButtonLabel='Payment Application'
              fieldOptions={COMPLIANCE_PAYMENT_APPLICATION_OPTIONS}
              maxFields={5}
            />
          )}
        </div>

        <div className='subsection'>
          <h3>Part 2e. Description of Environment</h3>

          <div className='description'>
            <span>Provide a high-level description of the environment covered by this assessment.</span>

            <div className='description flex column'>
              <span><i>For example:</i></span>

              <ul>
                <li><i>Connections into and out of the cardholder data environment (CDE).</i></li>
                <li><i>Critical system components within the CDE, such as POS devices, databases, web servers, etc., and any other necessary payment components, as applicable.</i></li>
              </ul>
            </div>
          </div>

          <Field
            name='provideHighLevelDescriptionEnvironment'
            component={TextAreaField}
          />

          <Field
            label={(
              <div className='flex column'>
                <span>Does your business use network segmentation to affect the scope of your PCI DSS environment?</span>
                <i>(Refer to &#34;Network Segmentation&#34; section of PCI DSS for guidance on network segmentation.)</i>
              </div>
            )}
            name='networkSegmentation'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />
        </div>

        <div className='subsection'>
          <h3>Part 2f. Third Party Service Providers</h3>

          <Field
            label='Does your company use a Qualified Integrator & Reseller (QIR)?'
            name='usedQir'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { usedQir === 'YES' && (
            <>
              <Field
                label='Name of QIR Company'
                name='nameOfQirCompany'
                component={InputField}
              />

              <Field
                label='QIR Individual Name'
                name='qirIndividualName'
                component={InputField}
              />

              <Field
                label='Description of services provided by QIR'
                name='descriptionOfServicesProvidedByQir'
                component={InputField}
              />
            </>
          )}

          <Field
            label={(
              <div className='flex column'>
                <span>Does your company share cardholder data with any third-party service providers.</span>
                <i>For example, Qualified Integrator & Resellers (QIR), gateways, payment processors, payment service providers (PSP), web-hosting companies, airline booking agents, loyalty program agents, etc.</i>
              </div>
            )}
            name='companyShareCardholderDataWithThirdPartyServiceProviders'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { companyShareCardholderDataWithThirdPartyServiceProviders === 'YES' && (
            <Field
              name='thirdPartyServiceProviders'
              component={CustomFieldArray}
              addButtonLabel='Third-Party Service Provider'
              fieldOptions={COMPLIANCE_THIRD_PARTY_SERVICE_PROVIDER_OPTIONS}
              maxFields={6}
            />
          )}
        </div>

        <div className='subsection'>
          <h3>Part 2g. Eligibility to complete SAQ A</h3>

          <Field
            label='Merchant certifies eligibility to complete this shortened version of the Self-Assessment Questionnaire because, for this payment channel:'
            name='eligibilityToCompleteSaqA'
            component={CheckboxGroupField}
            options={COMPLIANCE_ELIGIBILITY_TO_COMPLETE_SAQ_A_OPTIONS}
          />
        </div>
      </div>

      {buttonGroup}
    </form>
  )
}

ComplianceAssessmentInformationForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  oneOrMorePaymentApplications: PropTypes.string,
  usedQir: PropTypes.string,
  companyShareCardholderDataWithThirdPartyServiceProviders: PropTypes.string,
}

export default reduxForm({
  form: COMPLIANCE_ASSESSMENT_INFORMATION_FORM,
  validate: validateComplianceAssessmentInformationForm,
})(ComplianceAssessmentInformationForm)
