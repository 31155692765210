import { GET_MERCHANT_COMPLIANCE_FORMS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantComplianceFormsRequest = ({
  merchantId,
  meta,
  credentials,
}) => createAction({
  type: GET_MERCHANT_COMPLIANCE_FORMS_F_REQUEST,
  queries: {
    linked_to: merchantId,
  },
  meta,
  credentials,
})

export default getMerchantComplianceFormsRequest
