import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisputeEvidencesSection from './DisputeEvidencesSection'
import showModalAction from 'utilities/actions/showModalAction'
import postUploadDisputeEvidenceRequest from 'utilities/actions/post/postUploadDisputeEvidenceRequest'
import getDisputeEvidencesRequest from 'utilities/actions/get/getDisputeEvidencesRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import pluralizeWord from 'utilities/pluralizeWord'
import isPatching from 'utilities/is/isPatching'
import { ACCEPT_LIABILITY_MODAL } from 'constants/modalConstants'
import { MAX_EVIDENCE_FILES_ALLOWED } from 'constants/disputeConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import join from 'lodash/join'

import {
  ONE_MEGABYTE,
  DISPUTE_FILE_ACCEPT_TYPES,
} from 'constants/fileUploaderConstants'

import {
  isRoleMerchant,
  isRolePartner,
} from 'utilities/validate/checkRoleCredentials'

const mapDispatchToProps = (dispatch) => {
  return {
    showAcceptLiabilityModal: (modalProps) => dispatch(showModalAction({ modalType: ACCEPT_LIABILITY_MODAL, modalProps, className: 'modal-md no-pad' })),
    uploadDisputeEvidenceFiles: ({ disputeId, credentials, formData }) => dispatch(postUploadDisputeEvidenceRequest({ disputeId, credentials, formData, dispatch })),
    getDisputeEvidenceFiles: ({ credentials, disputeId }) => dispatch(getDisputeEvidencesRequest({ credentials, disputeId })),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const disputeId = get(props, 'disputeId')
  const disputeEvidenceFiles = get(props, 'disputeEvidenceFiles')
  const evidenceFilesUploaded = !isEmpty(disputeEvidenceFiles)
  const hideHyperlinks = isRoleMerchant({ credentials }) || (!isFlexPlatform() && isRolePartner({ credentials }))

  const flowValues = {
    disputeId,
  }

  return {
    credentials,
    disputeId,
    flowValues,
    disputeEvidenceFiles,
    evidenceFilesUploaded,
    isUploading: isPatching(state),
    hideHyperlinks,
  }
}

class DisputeEvidencesSectionC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadErrorMsg: '',
    }
  }

  customValidatorDisplay = (rejectedFiles) => {
    const invalidSizeFiles = []
    const invalidTypeFiles = []

    forEach(rejectedFiles, (({ name: fileName, size: fileSize, type: fileType }) => {
      if (fileSize > ONE_MEGABYTE) {
        invalidSizeFiles.push(fileName)
      }

      if (!includes(DISPUTE_FILE_ACCEPT_TYPES, fileType)) {
        invalidTypeFiles.push(fileName)
      }
    }))

    const invalidSizeMsg = !isEmpty(invalidSizeFiles) ? `${join(invalidSizeFiles, ', ')} ${size(invalidSizeFiles) === 1 ? 'exceeds' : 'exceed'} the maximum size limit of 1MB per file. Please compress or resize the ${pluralizeWord({ count: size(invalidSizeFiles), word: 'file' })} and try uploading again.` : null
    const invalidTypeMsg = !isEmpty(invalidTypeFiles) ? `${join(invalidTypeFiles, ', ')} ${pluralizeWord({ count: size(invalidTypeFiles), word: 'is' })} not an accepted file type. Please upload files of type JPG or PDF and try again.` : null

    return (
      <>
        {invalidSizeMsg && <div>{invalidSizeMsg}</div>}
        {invalidTypeMsg && <div>{invalidTypeMsg}</div>}
      </>
    )
  }

  onEvidencesFilesDrop = (acceptedFiles = []) => {
    const {
      credentials,
      disputeId,
      disputeEvidenceFiles,
      uploadDisputeEvidenceFiles,
    } = this.props

    this.setState({ uploadErrorMsg: '' })
    const remainingFilesAllowed = MAX_EVIDENCE_FILES_ALLOWED - size(disputeEvidenceFiles)
    const uploadFilesLimitErrorMsg = `You can only upload up to ${MAX_EVIDENCE_FILES_ALLOWED} files at once. Please remove some files and try again.`

    // confirm user's bulk upload will not surpass the max files limit
    if (size(acceptedFiles) <= remainingFilesAllowed) {
      forEach(acceptedFiles, (file) => {
        const formData = new FormData()
        formData.append('file', file)

        uploadDisputeEvidenceFiles({
          credentials,
          disputeId,
          formData,
        })
      })
    } else {
      this.setState({ uploadErrorMsg: uploadFilesLimitErrorMsg })
    }
  }

  render() {
    const { showAcceptLiabilityModal } = this.props
    const { uploadErrorMsg } = this.state

    return (
      <DisputeEvidencesSection
        {...this.props}
        showAcceptLiabilityModal={showAcceptLiabilityModal}
        onEvidencesFilesDrop={this.onEvidencesFilesDrop}
        customValidatorDisplay={this.customValidatorDisplay}
        uploadErrorMsg={uploadErrorMsg}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeEvidencesSectionC)
