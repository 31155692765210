import ContactSalesEmail from './ContactSalesEmail'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import { GET_EMAIL_TEMPLATE_F_REQUEST } from 'constants/flowConstants'
import { CONTACT_SALES_TEMPLATE } from 'constants/emailTemplateConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `emailTemplatesR.${FETCHING}`)
  const contactSalesEmail = get(state, `emailTemplatesR.items.${CONTACT_SALES_TEMPLATE}`)

  return {
    isFetching,
    contactSalesEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getContactSalesEmail: (templateName) => dispatch({
      type: GET_EMAIL_TEMPLATE_F_REQUEST,
      payload: { values: { templateName } },
    }),
  }
}

class ContactSalesEmailC extends Component {
  componentDidMount() {
    const { getContactSalesEmail } = this.props

    getContactSalesEmail(CONTACT_SALES_TEMPLATE)
  }

  render() {
    return (
      <ContactSalesEmail {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSalesEmailC)
