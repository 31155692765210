import getPlatformProperty from 'utilities/get/getPlatformProperty'
import selectOption from 'utilities/forms/selectOption'

import { DATA as DEV_DATA } from 'configs/platforms/dev'
import { DATA as QA_DATA } from 'configs/platforms/qa'
import { DATA as SANDBOX_DATA } from 'configs/platforms/sandbox'
import { DATA as FINIX_DATA } from 'configs/platforms/finix'
import { DATA as LACORE_DATA } from 'configs/platforms/lacore'
import { DATA as REALGREEN_DATA } from 'configs/platforms/realgreen'
import { DATA as LIGHTSPEED_DATA } from 'configs/platforms/lightspeed'
import { DATA as CLUB_ESSENTIAL_DATA } from 'configs/platforms/clubessential'
import { DATA as CROSSRIVER_BANK_DATA } from 'configs/platforms/crossriverbank'
import { DATA as PEOPLES_PAYMENTS_DATA } from 'configs/platforms/peoplespayments'
import { DATA as BILLTRUST_DATA } from 'configs/platforms/billtrust'
import { DATA as N41_DATA } from 'configs/platforms/n41'
import { DATA as PASSPORT_DATA } from 'configs/platforms/passport'
import { DATA as PAYMENT_BRANDS_DATA } from 'configs/platforms/paymentbrands'
import { DATA as WORKWAVE_DATA } from 'configs/platforms/workwave'
import { DATA as FATT_MERCHANT_DATA } from 'configs/platforms/fattmerchant'
import { DATA as SCOOP_CONNECT_DATA } from 'configs/platforms/scoopconnect'
import { DATA as CREDIBANCO_DATA } from 'configs/platforms/credibanco'
import { DATA as ACI_DATA } from 'configs/platforms/aci'
import { DATA as DCBANK_DATA } from 'configs/platforms/dcbank'
import { DATA as CORE_BT_DATA } from 'configs/platforms/corebt'
import { DATA as FLEX_DATA } from 'configs/platforms/flex'
import { DATA as ELAVON_PARTNER_CONNECT_DATA } from 'configs/platforms/elavonpartnerconnect'
import { DATA as PAY_IT_DATA } from 'configs/platforms/payit'
import { DATA as VERMONT_SYSTEMS_DATA } from 'configs/platforms/vermontsystems'
import { DATA as T2_SYSTEMS_DATA } from 'configs/platforms/t2systems'
import { DATA as LOCAL_SERVICE_DATA } from 'configs/platforms/localservice'
import { DATA as FINIX_MOCK_DATA } from 'configs/platforms/finixmock'

export const LOCALHOST = 'localhost'
export const DEV = 'dev'
export const QA = 'qa'
export const SANDBOX = 'sandbox'
export const FINIX = 'finix'
export const FINIX_MOCK = 'finixmock'
export const LACORE = 'lacore'
export const REALGREEN = 'realgreen'
export const LIGHTSPEED = 'lightspeed'
export const WORLDPAY = 'worldpay'
export const CLUB_ESSENTIAL = 'clubessential'
export const CROSSRIVER_BANK = 'crossriver'
export const PEOPLES_PAYMENTS = 'peoplespayments'
export const BILLTRUST = 'billtrust'
export const N41 = 'n41'
export const PASSPORT = 'passport'
export const PAYMENT_BRANDS = 'paymentbrands'
export const WORKWAVE = 'workwave'
export const FATT_MERCHANT = 'fattmerchant'
export const SCOOP_CONNECT = 'scoopconnect'
export const CREDIBANCO = 'credibanco'
export const ACI = 'aci'
export const DCBANK = 'dcbank'
export const CORE_BT = 'corebt'
export const FLEX = 'flex'
export const ELAVON_PARTNER_CONNECT = 'elavonpartnerconnect'
export const PAY_IT = 'payit'
export const T2_SYSTEMS = 't2systems'
export const VERMONT_SYSTEMS = 'vermontsystems'
export const LOCAL_SERVICE = 'localservice'

export const PLATFORM_DATA = {
  [LOCALHOST]: FINIX_DATA,
  [DEV]: DEV_DATA,
  [QA]: QA_DATA,
  [SANDBOX]: SANDBOX_DATA,
  [FINIX]: FINIX_DATA,
  [FINIX_MOCK]: FINIX_MOCK_DATA,
  [CLUB_ESSENTIAL]: CLUB_ESSENTIAL_DATA,
  [CROSSRIVER_BANK]: CROSSRIVER_BANK_DATA,
  [FINIX]: FINIX_DATA,
  [LACORE]: LACORE_DATA,
  [LIGHTSPEED]: LIGHTSPEED_DATA,
  [WORLDPAY]: LIGHTSPEED_DATA,
  [PEOPLES_PAYMENTS]: PEOPLES_PAYMENTS_DATA,
  // [REALGREEN]: REALGREEN_DATA, - removed customer (2/19/2020)
  [BILLTRUST]: BILLTRUST_DATA,
  [N41]: N41_DATA,
  [PASSPORT]: PASSPORT_DATA,
  [PAYMENT_BRANDS]: PAYMENT_BRANDS_DATA,
  [WORKWAVE]: WORKWAVE_DATA,
  [FATT_MERCHANT]: FATT_MERCHANT_DATA,
  [SCOOP_CONNECT]: SCOOP_CONNECT_DATA,
  [CREDIBANCO]: CREDIBANCO_DATA,
  [ACI]: ACI_DATA,
  [DCBANK]: DCBANK_DATA,
  [CORE_BT]: CORE_BT_DATA,
  [FLEX]: FLEX_DATA,
  [ELAVON_PARTNER_CONNECT]: ELAVON_PARTNER_CONNECT_DATA,
  [PAY_IT]: PAY_IT_DATA,
  [T2_SYSTEMS]: T2_SYSTEMS_DATA,
  [LOCAL_SERVICE]: LOCAL_SERVICE_DATA,
  [VERMONT_SYSTEMS]: VERMONT_SYSTEMS_DATA,
}

export const UNSET = 'UNSET'
export const MID_AND_MERCHANT_NAME = 'MID_AND_MERCHANT_NAME'
export const MID_AND_DATE = 'MID_AND_DATE'
export const DEFAULT = 'Default'
export const MID_AND_MERCHANT_NAME_DISPLAY = 'MID and Merchant Name'
export const MID_AND_DATE_DISPLAY = 'MID and Date'

export const SETTLEMENT_FUNDING_IDENTIFIER_OPTIONS = [
  selectOption(DEFAULT, UNSET),
  selectOption(MID_AND_MERCHANT_NAME_DISPLAY, MID_AND_MERCHANT_NAME),
  selectOption(MID_AND_DATE_DISPLAY, MID_AND_DATE),
]

export const settlementFundingIdentifierOptionsMapping = {
  [UNSET]: DEFAULT,
  [MID_AND_MERCHANT_NAME]: MID_AND_MERCHANT_NAME_DISPLAY,
  [MID_AND_DATE]: MID_AND_DATE_DISPLAY,
}

export const ADVANCED_PROCESSOR_ENABLED_PLATFORMS = [ACI, CORE_BT]

export const isDisplayTraceId = ({ platform }) => getPlatformProperty(platform, 'isDisplayTraceId')

export const STAX_ENABLED_APPLICATIONS = ['APjoQQbPyBRqx8vuKtQP9gfK']
