import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getWebhookSelector } from 'state-layer/selectors'
import EditWebhook from './EditWebhook'
import { allPossibleEventsSelected } from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import getWebhookRequest from 'utilities/actions/get/getWebhookRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { constructWebhookEventFormValues, WEBHOOK_EVENT_CHECKBOX_OPTIONS } from 'constants/webhookConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const webhookId = get(props, 'params.webhookId')
  const applicationId = get(props, 'params.applicationId')
  const webhook = getWebhookSelector(state, webhookId)

  const [
    endpointUrl,
    authenticationType,
    enabledEvents,
    nickname,
  ] = getMany(webhook, [
    'url',
    'authenticationType',
    'enabledEvents',
    'nickname',
  ])

  const selectedEvents = constructWebhookEventFormValues({ enabledEvents })

  const initialWebhookValues = {
    endpointUrl,
    authenticationType,
    selectedEvents,
    nickname,
    eventsChoice: allPossibleEventsSelected({ currentSelectedEvents: selectedEvents, allEvents: WEBHOOK_EVENT_CHECKBOX_OPTIONS }) ? 'all-events' : 'selected-events',
  }

  return {
    credentials,
    credentialId,
    webhookId,
    applicationId,
    webhook,
    initialWebhookValues,
  }
}

class EditWebhookC extends Component {
  componentDidMount() {
    const {
      credentials,
      webhookId,
      getWebhook,
    } = this.props

    getWebhook({ credentials, webhookId })
  }

  render() {
    return (
      <EditWebhook {...this.props} />
    )
  }
}

export default connect(mapStateToProps, {
  getWebhook: getWebhookRequest,
})(EditWebhookC)
