import './ExportDataModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ExportDataFormC from 'components/Customer/Forms/ExportDataForm/ExportDataFormC'

const ExportDataModal = ({
  title = 'Export',
  data = [],
  items = [],
  fileNameCSV = '',
}) => {
  return (
    <GenericModal
      title={title}
      className='ExportDataModal'
      Component={ExportDataFormC}
      data={data}
      items={items}
      fileNameCSV={fileNameCSV}
    />
  )
}

ExportDataModal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  items: PropTypes.array,
  fileNameCSV: PropTypes.string,
}

export default ExportDataModal
