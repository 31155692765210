import paymentsAPI from 'utilities/api/paymentsAPI'
import { FINIX_COMPLIANCE_FORMS } from 'constants/apiConstants'

const postComplianceFormAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: FINIX_COMPLIANCE_FORMS,
    service: FINIX_COMPLIANCE_FORMS,
  })
}

export default postComplianceFormAPI
