import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'

const getCurrentPlatformId = (state) => {
  const currentUser = getCurrentUser(state)

  return get(currentUser, 'platformId')
}

export default getCurrentPlatformId
