import co from 'co'
import getGuestOnboardingFormFileAPI from 'api/guestDashboard/get/getGuestOnboardingFormFileAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import map from 'lodash/map'

function * getGuestOnboardingFormFilesO ({
  values,
}) {
  const {
    fileIds,
    onboardingFormId,
  } = values

  const fileResponses = yield map(fileIds, (fileId) => {
    return getGuestOnboardingFormFileAPI({
      id: fileId,
      onboardingFormId,
    })
  })

  const files = reduce(fileResponses, (total, { data: fileData }) => {
    return !isEmpty(fileData) ? total.concat(fileData) : total
  }, [])

  return createSuccessResponse({
    data: files,
  })
}

export default co.wrap(getGuestOnboardingFormFilesO)
