import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CUSTOMIZATIONS } from 'constants/apiConstants'

const postDashboardCustomizationAPI = ({ values, meta }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_CUSTOMIZATIONS,
    values,
    meta,
  })
}

export default postDashboardCustomizationAPI
