import './TransferFailureDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  FAILURE_DETAILS,
  VIEW_API_RESPONSE,
  FAILURE_DETAILS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const TransferFailureDetails = ({
  title = FAILURE_DETAILS,
  asideTitle = VIEW_API_RESPONSE,
  transfer = {},
  showTransferApiResponseModal = () => {},
  hasAPIAccess,
}) => {
  const {
    messagesFailureCode,
    messagesFailureMessage,
    displayFailureCode,
    failureMessage,
    raw,
    messages,
  } = transfer

  const noDataAvailable = !messagesFailureCode && !messagesFailureMessage && !displayFailureCode && !failureMessage

  return (
    <PageSectionV2C
      className='failure-highlight'
      title={<div className='failure-details-title'>{title}</div>}
      asideTitle={(raw || messages) && hasAPIAccess && (
        <div className='view-api-response-link' onClick={showTransferApiResponseModal}>
          {asideTitle}
        </div>
      )}
    >
      { noDataAvailable ? <span className='empty-message'>{FAILURE_DETAILS_EMPTY_MESSAGE}</span> : (
        <div className='failure-details-highlight'>
          <div className='failure-code-display'>{messagesFailureCode && `${messagesFailureCode}: `}{messagesFailureMessage || displayFailureCode}</div>
          <div className='failure-message'>{failureMessage}</div>
        </div>
      )}

    </PageSectionV2C>
  )
}

TransferFailureDetails.propTypes = {
  title: PropTypes.string,
  asideTitle: PropTypes.string,
  transfer: PropTypes.object,
  showTransferApiResponseModal: PropTypes.func,
  hasAPIAccess: PropTypes.bool,
}

export default TransferFailureDetails
