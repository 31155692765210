import './DisplayUnmaskedItemS.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisplayUnmaskedItem from './DisplayUnmaskedItem'
import { getUnmaskedItemSelector } from 'state-layer/selectors'
import hasPermission from 'utilities/hasPermission'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import get from 'lodash/get'

import {
  DELETE_UNMASKED_ITEM,
  DELETE_UNMASKED_ITEMS,
  GET_UNMASKED_ITEM_F_REQUEST,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const canReadPersonalIdentifiableInformation = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION)
  const id = get(props, 'id')
  const feKey = get(props, 'feKey')
  const unmaskedItem = getUnmaskedItemSelector(state, `${id}_${feKey}`)
  const unmaskedItemValue = get(unmaskedItem, 'value')
  const unmaskedItemIsFetching = get(unmaskedItem, 'isFetching')

  return {
    canReadPersonalIdentifiableInformation,
    unmaskedItemValue,
    unmaskedItemIsFetching,
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnmaskedItem: ({ payload }) => dispatch({ type: GET_UNMASKED_ITEM_F_REQUEST, payload }),
    deleteUnmaskedItem: ({ payload }) => dispatch({ type: DELETE_UNMASKED_ITEM, payload }),
    deleteUnmaskedItems: () => dispatch({ type: DELETE_UNMASKED_ITEMS }),
  }
}

class DisplayUnmaskedItemC extends Component {
  constructor(props) {
    super(props)

    this.state = { unmaskedState: false }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { unmaskedItemIsFetching: prevUnmaskedItemIsFetching } = prevProps
    const { unmaskedItemIsFetching, unmaskedItemValue } = this.props

    if (prevUnmaskedItemIsFetching && !unmaskedItemIsFetching && !unmaskedItemValue) {
      this.toggleUnmaskedItem()
    }
  }

  componentWillUnmount() {
    const { deleteUnmaskedItems } = this.props

    deleteUnmaskedItems()
  }

  toggleUnmaskedItem = () => {
    const { unmaskedState } = this.state

    const {
      deleteUnmaskedItem,
      getUnmaskedItem,
      id,
      api,
      feKey,
      beKey,
      unmaskedItemValue,
      values,
      credentials,
    } = this.props

    if (unmaskedState) {
      if (unmaskedItemValue) deleteUnmaskedItem({ payload: { id, feKey } })
    } else {
      getUnmaskedItem({
        payload: {
          id,
          api,
          feKey,
          beKey,
          values,
          credentials,
        },
      })
    }

    this.setState({ unmaskedState: !unmaskedState })
  }

  render() {
    const { unmaskedState } = this.state

    const {
      unmaskedItemValue,
      defaultValue,
    } = this.props

    const displayMasked = (
      <div className='flex items-center'>
        <div className='masked-value'>{defaultValue}</div>
        <a onClick={() => this.toggleUnmaskedItem()}><i className='icon fa fa-eye-slash' /></a>
      </div>
    )

    const displayUnmasked = (
      <div className='flex items-center'>
        <div className='unmasked-value'>{unmaskedItemValue}</div>
        <a onClick={() => this.toggleUnmaskedItem()}><i className='icon fa fa-eye' /></a>
      </div>
    )

    const formattedValue = !unmaskedState ? displayMasked : displayUnmasked

    return (
      <DisplayUnmaskedItem
        formattedValue={formattedValue}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayUnmaskedItemC)
