import React from 'react'
import PropTypes from 'prop-types'
import CreateBalanceTransferForm from 'components/Customer/Forms/CreateBalanceTransferForm/CreateBalanceTransferForm'
import GenericModalC from 'components/Customer/Modals/GenericModal/GenericModalC'
import CreateBalanceTransferDisplayData from 'components/Customer/Forms/CreateBalanceTransferForm/CreateBalanceTransferDisplayData'
import CreateBalanceTransferConfirmation from 'components/Customer/Pages/CreateBalanceTransferConfirmation/CreateBalanceTransferConfirmation'
import { CREATE_BALANCE_TRANSFER_DATA_KEY } from 'constants/flowsConstants'

const CreateBalanceTransferModal = ({
  closeModal = () => {},
  credentials,
}) => {
  const steps = [
    {
      title: 'Create Balance Transfer',
      stepTitle: 'Balance Transfer',
      component: CreateBalanceTransferForm,
      props: {
        submitLabel: 'Next',
        leftButton: true,
        closeModal,
      },
      dataKey: CREATE_BALANCE_TRANSFER_DATA_KEY,
      getDisplayData: CreateBalanceTransferDisplayData,
    },
    {
      stepTitle: 'Confirmation of Balance Transfer',
      component: CreateBalanceTransferConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
      },
    },
  ]

  return (
    <GenericModalC
      title='Create Balance Transfer'
      className='CreateBalanceTransferModal'
      steps={steps}
      credentials={credentials}
      multiSteps
    />
  )
}

CreateBalanceTransferModal.propTypes = {
  closeModal: PropTypes.func,
  credentials: PropTypes.object,
}

export default CreateBalanceTransferModal
