import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import SelectSubscriptionScheduleForm from 'components/Customer/Forms/SelectSubscriptionScheduleForm/SelectSubscriptionScheduleForm'
import getSubscriptionScheduleRequest from 'utilities/actions/get/getSubscriptionScheduleRequest'
import getSubscriptionScheduleAmountsRequest from 'utilities/actions/get/getSubscriptionScheduleAmountsRequest'
import createSubscriptionScheduleSectionData from 'utilities/create/createSubscriptionScheduleSectionData'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import { SELECT_SUBSCRIPTION_SCHEDULE } from 'constants/formConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

import {
  getSubscriptionScheduleSelector,
  getMerchantSubscriptionEnrollmentsSelector,
  getSubscriptionScheduleAmountsSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const merchantId = get(props, 'merchantId')
  const formSelector = formValueSelector(SELECT_SUBSCRIPTION_SCHEDULE)
  const subscriptionScheduleId = formSelector(state, 'subscriptionScheduleId')
  const subscriptionSchedule = getSubscriptionScheduleSelector(state, subscriptionScheduleId)
  const subscriptionAmounts = getSubscriptionScheduleAmountsSelector(state, subscriptionScheduleId)
  const subscriptionScheduleNickname = get(subscriptionSchedule, 'scheduleNickname')
  const merchantSubscriptionEnrollments = Object.values(getMerchantSubscriptionEnrollmentsSelector(state, merchantId))
  const subscriptionScheduleSectionData = createSubscriptionScheduleSectionData({
    subscriptionSchedule: { titleText: `${subscriptionScheduleNickname} (${subscriptionScheduleId})`, ...subscriptionSchedule },
    subscriptionAmounts,
  })

  const alreadyEnrolled = isEmpty(subscriptionSchedule) ? false :
    some(merchantSubscriptionEnrollments, ({ merchantId: enrollmentMerchantId, subscriptionScheduleId: enrollmentSubscriptionScheduleId }) => {
      return merchantId === enrollmentMerchantId && subscriptionScheduleId === enrollmentSubscriptionScheduleId
    })

  return {
    subscriptionScheduleId,
    subscriptionSchedule,
    subscriptionScheduleSectionData,
    alreadyEnrolled,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSubscriptionSchedule: ({ credentials, subscriptionScheduleId }) => { dispatch(getSubscriptionScheduleRequest({ credentials, subscriptionScheduleId })) },
    getSubscriptionScheduleAmounts: ({ credentials, subscriptionScheduleId }) => { dispatch(getSubscriptionScheduleAmountsRequest({ credentials, subscriptionScheduleId, meta: { selectorId: subscriptionScheduleId } })) },
  }
}

class SelectSubscriptionScheduleFormC extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { subscriptionScheduleId: prevSubscriptionScheduleId } = prevProps
    const { subscriptionScheduleId, getSubscriptionSchedule, credentials, getSubscriptionScheduleAmounts } = this.props
    const isSubscriptionSchedule = get(getIDPrefixMapping(subscriptionScheduleId), 'recordName') === 'Subscription Schedule'

    if (prevSubscriptionScheduleId !== subscriptionScheduleId && isSubscriptionSchedule) {
      getSubscriptionSchedule({ credentials, subscriptionScheduleId })
      getSubscriptionScheduleAmounts({ credentials, subscriptionScheduleId })
    }
  }

  render() {
    return (
      <SelectSubscriptionScheduleForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSubscriptionScheduleFormC)
