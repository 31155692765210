import './SettlementFundingTransfersS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { SETTLEMENT_FUNDING_TRANSFERS } from 'constants/linkConstants'
import { GET_SETTLEMENT_FUNDING_TRANSFERS } from 'constants/flowConstants'
import { getSettlementFundingTransfersSelector } from 'state-layer/selectors'

const SettlementFundingTransfers = ({
  flowValues = {},
  initialQueries = {},
  isFetching = false,
  settlementId = '',
  actions = [],
  title = '',
  showExportButton = true,
  tableItemOnClick = () => {},
}) => {
  return (
    <div className='SettlementFundingTransfers'>
      <TableC
        flow={GET_SETTLEMENT_FUNDING_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getSettlementFundingTransfersSelector}
        selectorId={settlementId}
        initialQueries={initialQueries}
        linksKey={SETTLEMENT_FUNDING_TRANSFERS}
        clickAction={tableItemOnClick}
        isFetching={isFetching}
        actions={actions}
        fileNameCSV={showExportButton ? 'Settlement_Funding_Transfers' : null}
        title={title}
      />
    </div>
  )
}

SettlementFundingTransfers.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  settlementId: PropTypes.string,
  actions: PropTypes.array,
  title: PropTypes.string,
  showExportButton: PropTypes.bool,
  tableItemOnClick: PropTypes.func,
}

export default SettlementFundingTransfers
