import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplyNowHomepageBanner from './ApplyNowHomepageBanner'
import AccessFormFlowHomepageBanner from './AccessFormFlowHomepageBanner'
import { getApplicationOldestAccessForm } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import getAccessFormsRequest from 'utilities/actions/get/getAccessFormsRequest'
import redirectRequest from 'utilities/actions/redirectRequest'
import { FETCHING } from 'constants/reducerConstants'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH,
  LIVE_ACCOUNT_APPLICATION_FORM_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `accessFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const applicationOldestAccessForm = getApplicationOldestAccessForm(state)
  const accessFormId = get(applicationOldestAccessForm, 'id')
  const accessFormActiveStepIndex = get(applicationOldestAccessForm, 'activeStepIndex')
  const accessFormFlowSteps = ['Pricing Info', 'Business Info', 'Owner Info', 'Processing Info', 'Bank Account Info', 'Review and Submit']
  const continueLiveAccountApplicationPath = `${EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId, accessFormId })}?activeStep=${accessFormActiveStepIndex}`
  const liveAccountUpgradePath = LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId })

  return removeUndefined({
    credentials,
    applicationId,
    isFetching,
    applicationOldestAccessForm,
    accessFormFlowSteps,
    accessFormActiveStepIndex,
    continueLiveAccountApplicationPath,
    liveAccountUpgradePath,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationAccessForms: ({ credentials, applicationId }) => dispatch(getAccessFormsRequest({ credentials, applicationId })),
    startApplication: (liveAccountUpgradePath) => dispatch(redirectRequest({ path: liveAccountUpgradePath })),
  }
}

class HomepageBannerC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getApplicationAccessForms,
    } = this.props

    getApplicationAccessForms({ credentials, applicationId })
  }

  redirectToStartApplication = () => {
    const {
      startApplication,
      liveAccountUpgradePath,
    } = this.props

    startApplication(liveAccountUpgradePath)
  }

  render() {
    const {
      credentials,
      accessFormActiveStepIndex,
      isFetching,
      continueLiveAccountApplicationPath,
      applicationOldestAccessForm,
    } = this.props

    if (isFetching) return null
    return (
      applicationOldestAccessForm
        ? <AccessFormFlowHomepageBanner continueLiveAccountApplicationPath={continueLiveAccountApplicationPath} accessFormActiveStepIndex={accessFormActiveStepIndex} />
        : <ApplyNowHomepageBanner credentials={credentials} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageBannerC)
