import getUserPermissionsAPI from 'api/finix/get/getUserPermissionsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getMany from 'utilities/get/getMany'
import co from 'co'

function * getUserPermissionsO ({
  id,
  credentials,
}) {
  let data = []
  let nextLink = ''
  let initialFetch = true

  while (initialFetch || nextLink) {
    const { data: userPermissionsResponse } = !initialFetch ?
      yield getPaymentsAPI({ absolutePath: nextLink, credentials }) :
      yield getUserPermissionsAPI({
        id,
        credentials,
        queries: {
          limit: 100,
          offset: 0,
        },
      })

    const [
      permissions = [],
      next,
    ] = getMany(userPermissionsResponse, [
      '_embedded.permissions',
      '_links.next.href',
    ])

    data = data.concat(permissions)
    initialFetch = false
    nextLink = next
  }

  return createSuccessResponse({
    data: [{ id, permissions: data }],
  })
}

export default co.wrap(getUserPermissionsO)
