import { HIDE_MODAL } from 'constants/actionConstants'
import get from 'lodash/get'

import {
  POST_APPLICATION_F_SUCCESS,
  POST_USER_F_SUCCESS,
  POST_MERCHANT_IDENTITY_F_SUCCESS,
  POST_MERCHANT_IDENTITY_F_FAILURE,
  PATCH_MERCHANT_IDENTITY_F_SUCCESS,
} from 'constants/flowConstants'


const orchestrationStatusR = (state = {}, action) => {
  const payload = get(action, 'payload', {})
  const orchestrationStatus = get(payload, 'orchestrationStatus', {})

  switch (action.type) {
    case POST_APPLICATION_F_SUCCESS:
    case POST_USER_F_SUCCESS:
    case POST_MERCHANT_IDENTITY_F_SUCCESS:
    case POST_MERCHANT_IDENTITY_F_FAILURE:
    case PATCH_MERCHANT_IDENTITY_F_SUCCESS:
      return orchestrationStatus

    case HIDE_MODAL:
      return {}

    default:
      return state
  }
}

export default orchestrationStatusR
