import getMany from 'utilities/get/getMany'
import numeral from 'numeral'

const warnIdentityProcessingInfoForm = (values, props) => {
  const { processingData = {} } = values

  const {
    maxTransactionAmount,
    achMaxTransactionAmount,
  } = processingData

  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    isRolePlatformWithEditIdentityPermissions,
  ] = getMany(props, [
    'underwritingProfileMaxTransactionAmount',
    'underwritingProfileACHMaxTransactionAmount',
    'underwritingProfileDisplayMaxTransactionAmount',
    'underwritingProfileDisplayACHMaxTransactionAmount',
    'isRolePlatformWithEditIdentityPermissions',
  ])

  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(underwritingProfileMaxTransactionAmount).value()
  const invalidACHMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(underwritingProfileACHMaxTransactionAmount).value()
  const maxTransactionAmountWarning = (isRolePlatformWithEditIdentityPermissions && underwritingProfileMaxTransactionAmount) ? `Value exceeds the Application's max amount of ${underwritingProfileDisplayMaxTransactionAmount}` : ''
  const achMaxTransactionAmountWarning = (isRolePlatformWithEditIdentityPermissions && underwritingProfileACHMaxTransactionAmount) ? `Value exceeds the Application's ACH max amount of ${underwritingProfileDisplayACHMaxTransactionAmount}` : ''

  return {
    processingData: {
      maxTransactionAmount: invalidMaxTransactionAmount && maxTransactionAmountWarning,
      achMaxTransactionAmount: invalidACHMaxTransactionAmount && achMaxTransactionAmountWarning,
    },
  }
}

export default warnIdentityProcessingInfoForm
