import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fee from './Fee'
import { getFeeSelector } from 'state-layer/selectors'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getFeeRequest from 'utilities/actions/get/getFeeRequest'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import displayFundingDetailsValue from 'utilities/display/displayFundingDetailsValue'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isFlexPlatform } from 'constants/flexConstants'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import { FETCHING } from 'constants/reducerConstants'
import { REMOVE_SETTLEMENT_ENTRIES } from 'constants/modalConstants'
import { NON_MERCHANT_ROLES } from 'constants/roleConstants'
import { APPROVED } from 'constants/settlementConstants'
import { shouldFundMap } from 'constants/statusConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  SETTLEMENT_PATH,
  MERCHANT_PATH,
  PAYMENT_PATH,
  IDENTITY_PATH,
  APPLICATION_PATH,
  SUBSCRIPTION_BILLING_PATH,
} from 'constants/pathConstants'

import {
  FEE_LINKED_TYPE_SUBSCRIPTION,
  SUBSCRIPTION,
} from 'constants/feeConstants'

import {
  CREATED_ON,
  LINKED_TRANSACTION_AMOUNT,
  LINKED_TYPE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  REMOVE_FROM_SETTLEMENT,
  MERCHANT,
  LABEL,
  FEE_TYPE,
  SUBTYPE,
  FUNDING_STATUS,
  SUBSCRIPTION_BILLING_RESOURCE_TITLE,
  SETTLEMENT_ENTRIES,
  CATEGORY,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feesR.${FETCHING}`)
  const feeId = get(props, 'params.feeId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const fee = getFeeSelector(state, feeId)
  const settlementStatus = get(fee, 'settlement.status')
  const settlementId = get(fee, 'settlement.id')
  const settlementEntryId = get(fee, 'settlementEntry.id')
  const settlementException = get(fee, 'settlement.exception')
  const enableRemoveFromSettlement = settlementEntryId && settlementStatus && settlementStatus !== APPROVED && !settlementException
  const showAction = includes(NON_MERCHANT_ROLES, role) && enableRemoveFromSettlement
  const isFlex = isFlexPlatform()
  const showFundingStatus = !isFlex || (isFlex && isRolePlatform({ credentials }))
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  const [
    id,
    displayAmount,
    displayAmountWithCurrencyCode,
    currency,
    displayFeeType,
    displayFeeSubtype,
    label,
    displayCreatedAt,
    merchantId,
    linkedId,
    linkedType,
    displayLinkedType,
    tags,
    shouldFund,
    transfer,
    application,
    identity,
    merchant,
    displayCategory,
  ] = getMany(fee, [
    'id',
    'displayAmount',
    'displayAmountWithCurrencyCode',
    'currency',
    'displayFeeType',
    'displayFeeSubtype',
    'label',
    'displayCreatedAt',
    'merchantId',
    'linkedId',
    'linkedType',
    'displayLinkedType',
    'tags',
    'settlementEntry.shouldFund',
    'transfer',
    'application',
    'identity',
    'merchant',
    'displayCategory',
  ])

  const linkedIdMapping = getIDPrefixMapping(linkedId)
  const linkedIdName = get(linkedIdMapping, 'idName')
  const linkedIdPath = get(linkedIdMapping, 'path')
  const paymentId = get(transfer, 'id')
  const externallyFunded = get(transfer, 'externallyFunded')
  const transactionAmount = get(transfer, 'displayAmountWithCurrencyCode')
  const applicationName = get(application, 'businessName')
  const applicationId = get(application, 'id')
  const identityId = get(identity, 'id')
  const identityName = get(identity, 'displayBusinessName')
  const merchantName = get(merchant, 'name')
  const contextBarLabel = linkedType === SUBSCRIPTION ? SUBSCRIPTION_BILLING_RESOURCE_TITLE : SETTLEMENT_ENTRIES
  const contextBarPath = linkedType === SUBSCRIPTION ? SUBSCRIPTION_BILLING_PATH({ credentialId, settlementId }) : `${SETTLEMENT_PATH({ credentialId, settlementId })}?tab=entries`

  const contextBarData = {
    back: {
      label: contextBarLabel,
      path: contextBarPath,
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: identityName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
        condition: !isStandaloneMerchant,
      },
    ],
  }

  const headerData = {
    resource: {
      label: `${displayLinkedType ? displayLinkedType : ''} Fee`,
      id,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: LINKED_TRANSACTION_AMOUNT,
        value: transactionAmount,
        condition: !!transactionAmount,
        path: PAYMENT_PATH({ credentialId, transferId: paymentId }),
      },
      {
        label: LINKED_TYPE,
        value: displayLinkedType,
        condition: !!displayLinkedType,
      },
    ],
  }

  const fundingStatusValue = displayFundingDetailsValue({ shouldFund, externallyFunded })

  const detailsSectionData = [
    {
      label: MERCHANT,
      value: <div>{merchantName} <ClickToCopyC content={merchantId} /></div>,
      path: MERCHANT_PATH({ merchantId, identityId, credentialId }),
      condition: !isStandaloneMerchant,
    },
    {
      label: LABEL,
      value: label,
    },
    {
      label: FEE_TYPE,
      value: displayFeeType,
    },
    {
      label: SUBTYPE,
      value: displayFeeSubtype,
    },
    {
      label: FUNDING_STATUS,
      value: fundingStatusValue,
      condition: showFundingStatus,
    },
    {
      label: CATEGORY,
      value: displayCategory,
    },
  ]

  const tagsSectionData = map(tags, (value, tagLabel) => ({ value, label: tagLabel }))

  return {
    isFetching,
    credentials,
    settlementId,
    settlementEntryId,
    feeId,
    detailsSectionData,
    showAction,
    tagsSectionData,
    fee,
    contextBarData,
    headerData,
    displayAmount,
    displayAmountWithCurrencyCode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFee: ({ credentials, feeId }) => { dispatch(getFeeRequest({ credentials, feeId })) },
    showRemoveFromSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: REMOVE_SETTLEMENT_ENTRIES, modalProps })),
  }
}

class FeeC extends Component {
  componentDidMount() {
    const {
      feeId,
      credentials,
      getFee,
    } = this.props

    getFee({ credentials, feeId })
  }

  render() {
    const {
      showRemoveFromSettlementModal,
      settlementEntryId,
      settlementId,
      feeId,
      showAction,
      displayAmountWithCurrencyCode,
    } = this.props

    const actions = showAction ? [{
      label: REMOVE_FROM_SETTLEMENT,
      action: () => {
        showRemoveFromSettlementModal({
          settlementEntryIds: [settlementEntryId],
          entriesData: [{ id: feeId, amount: displayAmountWithCurrencyCode }],
          settlementId,
        })
      },
      className: 'remove-from-settlement-button',
    }] : []

    return (
      <Fee
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeeC)
