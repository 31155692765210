import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import CreatePayoutLinkForm from 'components/Customer/Forms/CreatePayoutLinkForm/CreatePayoutLinkForm'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import formatAddress from 'utilities/formatters/formatAddress'
import formatMoney from 'utilities/formatters/formatMoney'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import { FETCHING } from 'constants/reducerConstants'
import { FINIX_NAV_BAR_LOGO } from 'constants/logoConstants'
import { APPLICATION } from 'constants/dashboardConfigurationsConstants'
import { CREATE_PAYOUT_LINK_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'

import {
  getApplicationSelector,
  getFormValues,
  getIdentitiesSelector,
} from 'state-layer/selectors'

import {
  GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
  GET_RECIPIENT_IDENTITIES_F_REQUEST,
} from 'constants/flowConstants'

import {
  PUSH_TO_ACH,
  PUSH_TO_CARD,
} from 'constants/transferConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const isPosting = isPatching(state)
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const isFetching = isFetchingConfiguration || isFetchingCurrentUser
  const dashboardConfig = getCurrentDashboardConfig(state)
  // TODO: Currently Payouts is only for US, but in future we will need to somehow get the merchant country / currency of the application
  const currency = 'USD'

  const [
    maxACHTransactionAmount,
    maxTransactionAmount,
  ] = getMany(application, [
    'ownerIdentity.maxACHTransactionAmount',
    'ownerIdentity.maxTransactionAmount',
  ])

  const [
    currentPayoutLinkLogo,
    currentPayoutLinkHeaderColor,
    currentPayoutLinkButtonBackgroundColor = '#0B5DBC',
    currentPayoutLinkButtonTextColor = '#FFFFFF',
    currentSidebarLogoImageUrl = FINIX_NAV_BAR_LOGO,
    currentSidebarBackgroundColor = '#FFFFFF',
    paymentLinkTemplate,
  ] = getMany(dashboardConfig, [
    'paymentLinkLogo',
    'paymentLinkHeaderColor',
    'paymentLinkButtonBackgroundColor',
    'paymentLinkButtonTextColor',
    'sidebarLogoImageUrl',
    'sidebarBackgroundColor',
    'paymentLinkTemplate',
  ])

  const [
    currentItems = [],
    collectRecipientDetails,
    expiresIn,
    collectEmail,
    collectPhoneNumber,
    termsOfServiceUrl,
    allowPaymentCard,
    allowBankTransfer,
    collectCustomFields = false,
    customFields = [],
    useCustomBranding,
    formLogoBranding,
    formHeaderColorBranding,
    formButtonBackgroundColorBranding,
    formButtonTextColorBranding,
    recipient,
  ] = getFormValues(state, CREATE_PAYOUT_LINK_FORM, [
    'items',
    'collectRecipientDetails',
    'expiresIn',
    'collectEmail',
    'collectPhoneNumber',
    'termsOfServiceUrl',
    'allowPaymentCard',
    'allowBankTransfer',
    'collectCustomFields',
    'customFields',
    'useCustomBranding',
    'payoutLinkLogoURL',
    'payoutLinkHeaderColor',
    'payoutLinkButtonBackgroundColor',
    'payoutLinkButtonTextColor',
    'recipient',
  ])

  const payoutLinkLogo = formLogoBranding || currentPayoutLinkLogo || currentSidebarLogoImageUrl
  const headerColor = formHeaderColorBranding || currentPayoutLinkHeaderColor || currentSidebarBackgroundColor
  const buttonBackgroundColor = formButtonBackgroundColorBranding || currentPayoutLinkButtonBackgroundColor
  const buttonTextColor = formButtonTextColorBranding || currentPayoutLinkButtonTextColor

  const initialValues = !isFetching ? {
    allowPaymentCard: true,
    allowBankTransfer: true,
    expiresIn: '168',
    collectRecipientDetails: 'name',
    quantity: 1,
    payoutLinkLogoURL: payoutLinkLogo,
    payoutLinkHeaderColor: headerColor,
    payoutLinkButtonBackgroundColor: buttonBackgroundColor,
    payoutLinkButtonTextColor: buttonTextColor,
    termsOfServiceUrl: get(paymentLinkTemplate, 'termsOfServiceUrl'),
    successReturnUrl: get(paymentLinkTemplate, 'successReturnUrl'),
    unsuccessfulReturnUrl: get(paymentLinkTemplate, 'unsuccessfulReturnUrl'),
    expiredSessionUrl: get(paymentLinkTemplate, 'expiredSessionUrl'),
    ...get(paymentLinkTemplate, 'oneTime', {}),
  } : undefined

  let totalAmount = 0

  map(currentItems, (item) => {
    const {
      quantity = '1',
      amount,
    } = item

    // convert to display amount to actual amount for calculation
    const itemAmount = displayAmountToAmount(amount, currency)
    totalAmount += (itemAmount * parseInt(quantity, 10))
  })

  const allowedPayoutOperations = []
  if (allowPaymentCard) {
    allowedPayoutOperations.push(PUSH_TO_CARD)
  }
  if (allowBankTransfer) {
    allowedPayoutOperations.push(PUSH_TO_ACH)
  }
  const allowedPayoutOperationsKey = allowedPayoutOperations.join('_') || 'no_methods'

  const customFieldTags = reduce(customFields, (result, { name }, index) => {
    if (name) {
      result[`custom_field_${index + 1}`] = name
    }

    return result
  }, {})

  // this is "fake data" to pass to the guest payout form as preview
  const recipientFullName = recipient ? `${get(recipient, 'data.firstName')} ${get(recipient, 'data.lastName')}` : undefined
  const recipientBusinessName = recipient ? get(recipient, 'data.businessName') : undefined
  const recipientDoingBusinessAs = recipient ? get(recipient, 'data.doingBusinessAs') : undefined

  const previewPayoutLink = {
    collectEmail,
    collectPhoneNumber,
    termsOfServiceUrl,
    brandColor: headerColor,
    accentColor: buttonBackgroundColor,
    buttonFontColor: buttonTextColor,
    logo: payoutLinkLogo || currentSidebarLogoImageUrl,
    items: currentItems,
    allowedPayoutOperations,
    displayTotalAmount: formatMoney({ amount: amountToDisplayAmount(totalAmount, USD), currency: USD }),
    tags: collectCustomFields ? customFieldTags : undefined,
    useCustomBranding,
    recipientFullName,
    recipientBusinessName,
    recipientDoingBusinessAs,
    isPreviewMode: true,
  }

  // get list of recipients for dropdown
  const recipients = getIdentitiesSelector(state)
  const underwrittenRecipients = filter(recipients, (underwrittenRecipient) => {
    return get(underwrittenRecipient, 'identityRole') === 'RECIPIENT' && get(underwrittenRecipient, 'merchantProcessorOnboardingStatesString') === 'APPROVED'
  })

  const recipientNameOptions = map(underwrittenRecipients, (underwrittenRecipient) => {
    const {
      id,
      businessName,
      doingBusinessAs,
      firstName,
      lastName,
      merchants,
      fullName,
      businessAddress,
      personalAddress,
      email,
    } = underwrittenRecipient

    const isBusinessRecipient = !!businessName
    const name = isBusinessRecipient ? businessName : fullName
    const address = isBusinessRecipient ? businessAddress : personalAddress
    const recipientMerchantId = Object.keys(merchants)[0]

    return {
      label: (
        <>
          <div className='primary'>{name}</div>
          <div className='secondary'>{formatAddress({ address })}</div>
        </>
      ),
      value: id,
      data: {
        id,
        name,
        firstName,
        lastName,
        email,
        recipientMerchantId,
        businessName,
        doingBusinessAs,
        businessAddress,
        address,
        isBusinessRecipient,
        personalAddress,
      },
    }
  })

  return {
    applicationId,
    initialValues,
    collectMoreRecipientDetails: collectRecipientDetails === 'more',
    showExpirationDatePicker: expiresIn === 'custom',
    credentials,
    currentItems,
    isPosting,
    previewPayoutLink,
    useCustomBranding,
    payoutLinkLogo,
    headerColor,
    buttonBackgroundColor,
    buttonTextColor,
    isFetching,
    currentPayoutLinkLogo,
    currentPayoutLinkHeaderColor: currentPayoutLinkHeaderColor || currentSidebarBackgroundColor,
    currentPayoutLinkButtonBackgroundColor,
    currentPayoutLinkButtonTextColor,
    guestPayoutLinkComponentKey: `${allowedPayoutOperationsKey}_${collectRecipientDetails}`,
    collectCustomFields,
    recipientNameOptions,
    maxTransactionAmount,
    maxACHTransactionAmount,
    paymentLinkTemplate,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ applicationId, credentials }) => dispatch(getApplicationRequest({ applicationId, credentials })),
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => dispatch(change(CREATE_PAYOUT_LINK_FORM, fieldName, color.hex)),
    updateField: (fieldName, value) => dispatch(change(CREATE_PAYOUT_LINK_FORM, fieldName, value)),
    getApplicationIdentities: ({ applicationId, credentials }) => dispatch({
      type: GET_RECIPIENT_IDENTITIES_F_REQUEST,
      payload: {
        values: {
          applicationId,
        },
        credentials,
        queries: {
          limit: 200,
        },
      },
    }),
  }
}

class CreatePayoutLinkFormC extends Component {
  state = {
    displayHeaderColorPicker: false,
    displayButtonBackgroundColorPicker: false,
    displayButtonTextColorPicker: false,
  }

  componentDidMount() {
    const {
      applicationId,
      credentials,
      getApplicationIdentities,
      getApplication,
      merchantId,
      getMerchant,
    } = this.props

    getApplicationIdentities({ applicationId, credentials })

    if (merchantId) {
      getMerchant({ merchantId, credentials })
    }

    if (applicationId) {
      getApplication({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingCurrentUser: prevIsFetchingCurrentUser,
      useCustomBranding: prevUseCustomBranding,
      applicationId: prevApplicationId,
    } = prevProps

    const {
      credentials,
      getDashboardCustomization,
      getApplication,
      isFetchingCurrentUser,
      applicationId,
      updateField,
      useCustomBranding,
      currentPayoutLinkLogo,
      currentPayoutLinkHeaderColor,
      currentPayoutLinkButtonTextColor,
      currentPayoutLinkButtonBackgroundColor,
    } = this.props

    if (applicationId && !isEqual(prevIsFetchingCurrentUser, isFetchingCurrentUser) && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId: applicationId, entityType: APPLICATION })
    }

    if (!isEqual(prevUseCustomBranding, useCustomBranding) && !useCustomBranding) {
      updateField('payoutLinkLogoURL', currentPayoutLinkLogo)
      updateField('payoutLinkHeaderColor', currentPayoutLinkHeaderColor)
      updateField('payoutLinkButtonBackgroundColor', currentPayoutLinkButtonBackgroundColor)
      updateField('payoutLinkButtonTextColor', currentPayoutLinkButtonTextColor)
    }

    if (applicationId && !isEqual(prevApplicationId, applicationId)) {
      getApplication({ applicationId, credentials })
    }
  }

  handleClick = (fieldName) => {
    const {
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    if (fieldName === 'displayHeaderColorPicker') {
      this.setState({ displayHeaderColorPicker: !displayHeaderColorPicker })
    }

    if (fieldName === 'displayButtonBackgroundColorPicker') {
      this.setState({ displayButtonBackgroundColorPicker: !displayButtonBackgroundColorPicker })
    }

    if (fieldName === 'displayButtonTextColorPicker') {
      this.setState({ displayButtonTextColorPicker: !displayButtonTextColorPicker })
    }
  }

  handleClose = (fieldName) => {
    this.setState({ [fieldName]: false })
  }

  render() {
    const {
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    return (
      <CreatePayoutLinkForm
        setIsMultipleUse={this.setIsMultipleUse}
        displayHeaderColorPicker={displayHeaderColorPicker}
        displayButtonBackgroundColorPicker={displayButtonBackgroundColorPicker}
        displayButtonTextColorPicker={displayButtonTextColorPicker}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayoutLinkFormC)
