import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import removeUndefined from 'utilities/remove/removeUndefined'

const frontendPurchaseModel = ({ data: purchase }) => {
  const [
    id,
    state,
    merchantId,
    applicationId,
    amount,
    currency,
    paymentFrequency,
    token,
    transferId,
    entityId,
    entityType,
    firstName,
    lastName,
    email,
    phone,
    billingLine1,
    billingLine2,
    billingCity,
    billingRegion,
    billingPostalCode,
    billingCountry,
    shippingLine1,
    shippingLine2,
    shippingCity,
    shippingRegion,
    shippingPostalCode,
    shippingCountry,
    createdAt,
    failureCode,
    failureMessage,
    paymentLink,
    transfer,
    receipts,
    paymentInstrument,
  ] = getMany(purchase, [
    'id',
    'state',
    'merchant_id',
    'application_id',
    'amount',
    'currency',
    'payment_frequency',
    'token',
    'transfer_id',
    'entity_id',
    'entity_type',
    'buyer_details.first_name',
    'buyer_details.last_name',
    'buyer_details.email',
    'buyer_details.phone',
    'buyer_details.billing_address.line1',
    'buyer_details.billing_address.line2',
    'buyer_details.billing_address.city',
    'buyer_details.billing_address.region',
    'buyer_details.billing_address.postal_code',
    'buyer_details.billing_address.country',
    'buyer_details.shipping_address.line1',
    'buyer_details.shipping_address.line2',
    'buyer_details.shipping_address.city',
    'buyer_details.shipping_address.region',
    'buyer_details.shipping_address.postal_code',
    'buyer_details.shipping_address.country',
    'created_at',
    'failure_code',
    'failure_message',
    'paymentLink',
    'transfer',
    'receipts',
    'paymentInstrument',
  ])

  return removeUndefined({
    id,
    state,
    transferId,
    merchantId,
    applicationId,
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    paymentFrequency,
    token,
    entityId,
    entityType,
    firstName,
    lastName,
    fullName: (firstName || lastName) ? `${firstName} ${lastName}` : '-',
    email,
    phone,
    createdAt,
    failureCode,
    failureMessage,
    displayCreatedAt: formatDate({ date: createdAt }),
    billingAddress: {
      line1: billingLine1,
      line2: billingLine2,
      city: billingCity,
      region: billingRegion,
      country: billingCountry,
      postalCode: billingPostalCode,
    },
    shippingAddress: {
      line1: shippingLine1,
      line2: shippingLine2,
      city: shippingCity,
      region: shippingRegion,
      country: shippingCountry,
      postalCode: shippingPostalCode,
    },
    paymentLink,
    transfer,
    receipts,
    paymentInstrument,
  })
}

export default frontendPurchaseModel
