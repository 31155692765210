import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { ONBOARDING_FORMS_FILE } from 'constants/apiConstants'

const getOnboardingFormFileAPI = ({ id, onboardingFormId, meta }) => {
  return guestDashboardAPI.get({
    path: ONBOARDING_FORMS_FILE({ id, onboardingFormId }),
    meta,
  })
}

export default getOnboardingFormFileAPI
