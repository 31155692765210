import React from 'react'
import PropTypes from 'prop-types'
import FilesC from 'components/Customer/Pages/Files/FilesC'
import { GET_IDENTITY_FILES } from 'constants/flowConstants'
import { IDENTITY_FILES } from 'constants/linkConstants'
import defaultColumnDescriptors from './columnDescriptors'

const IdentityFiles = ({
  identityId = '',
  showUploadButton = false,
  fileNameCSV = '',
  title = '',
  urlQueriesToIgnore = [],
  selector,
  flow = GET_IDENTITY_FILES,
  data,
  columnDescriptors = defaultColumnDescriptors,
  identityMerchants,
}) => {
  return (
    <div className='IdentityFiles'>
      <FilesC
        identityId={identityId}
        flow={flow}
        linksKey={IDENTITY_FILES}
        showUploadButton={showUploadButton}
        title={title}
        uploadForIdentity
        urlQueriesToIgnore={urlQueriesToIgnore}
        fileNameCSV={fileNameCSV}
        selector={selector}
        data={data}
        columnDescriptors={columnDescriptors}
        identityMerchants={identityMerchants}
        initialQueries={{ identity_id: identityId }}
      />
    </div>
  )
}

IdentityFiles.propTypes = {
  identityId: PropTypes.string,
  showUploadButton: PropTypes.bool,
  title: PropTypes.string,
  urlQueriesToIgnore: PropTypes.array,
  selector: PropTypes.func,
  flow: PropTypes.string,
  data: PropTypes.object,
  columnDescriptors: PropTypes.array,
  identityMerchants: PropTypes.array,
}

export default IdentityFiles
