import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'

const DisputeTabs = ({
  tabs = [],
}) => {
  return (
    <div className='DisputeTabs'>
      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

DisputeTabs.propTypes = {
  tabs: PropTypes.array,
}

export default DisputeTabs
