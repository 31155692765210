import './ColorcodedStatusS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { COMPLETED } from 'constants/language/languageConstants'
import capitalize from 'lodash/capitalize'

import {
  COMPLETE,
  INCOMPLETE,
  EXPIRED,
  OVERDUE,
  INVALID,
} from 'constants/statusConstants'

import {
  CIRCLE_MINUS_ICON,
  CLOCK_ICON,
  HOURGLASS_END_ICON,
} from 'constants/iconConstants'

const ComplianceFormStatus = ({
  state = '',
}) => {
  let icon
  let value

  if (state === COMPLETE) {
    icon = 'fa-clipboard-check'
    value = COMPLETED
  } else if (state === INCOMPLETE) {
    icon = CLOCK_ICON
    value = capitalize(INCOMPLETE)
  } else if (state === EXPIRED) {
    icon = CIRCLE_MINUS_ICON
    value = capitalize(EXPIRED)
  } else if (state === OVERDUE) {
    icon = HOURGLASS_END_ICON
    value = capitalize(OVERDUE)
  } else {
    icon = 'fa-times-circle'
    value = capitalize(INVALID)
  }

  return (
    <div className='ComplianceFormDueAtStatus ColorcodedStatus'>
      { state && <span><i className={`fa ${icon}`} /></span> }
      { state && <span>{value}</span> }
    </div>
  )
}

ComplianceFormStatus.propTypes = {
  state: PropTypes.string,
}

export default ComplianceFormStatus
