import frontendAuthorizationModel from 'utilities/create/models/frontendAuthorizationModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import get from 'lodash/get'

const frontendAuthorizationsModel = ({ data: authorizations }) => {
  const authorizationsModels = map(authorizations, (authorization) => frontendAuthorizationModel({ data: authorization }))

  return keyBy(authorizationsModels, 'id')
}

export default frontendAuthorizationsModel
