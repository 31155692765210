import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import get from 'lodash/get'

import {
  POST_CREDENTIALS_F_REQUEST,
  POST_ADMIN_USER_F_REQUEST,
  POST_ADMIN_USER_MEMBERSHIPS_F_REQUEST,
  POST_ADMIN_USER_MEMBERSHIPS_F_SUCCESS,
} from 'constants/flowConstants'

const submitCredentialForm = (values, dispatch) => {
  const {
    credentialName,
    credentialType,
    apiHost,
    userName,
    password,
    credentialEmail,
  } = values

  const dashboardServiceV2 = isDashboardServiceV2()
  const payouts = credentialType === 'push-to-card' ? true : false

  const credentialPayload = dashboardServiceV2 ? {
    username: userName,
    password,
    email_address: credentialEmail,
    dashboard_host: apiHost,
    credential_name: credentialName,
    payment_facilitator_enabled: !payouts,
    push_to_card_enabled: payouts,
    dashboard_id: getCurrentDashboardId(),
  } : {
    host: apiHost,
    name: credentialName,
    username: userName,
    password,
    payouts,
    transfers: !payouts,
  }

  const emailPayload = {
    type: 'email-address',
    properties: {
      email_address: credentialEmail.trim(),
    },
  }

  dispatch({
    type: POST_CREDENTIALS_F_REQUEST,
    payload: {
      values: credentialPayload,
    },
    meta: {
      // TODO: whe dashboard service V1 is deprecated, delete this callback and use "post_credential_success" on notificationR
      successCallback: (credentials) => {
        if (dashboardServiceV2) {
          dispatch({
            type: POST_ADMIN_USER_MEMBERSHIPS_F_SUCCESS,
            payload: {},
            meta: {
              successMessage: `Credential ${credentialName} has been successfully created.`,
            },
          })

          return
        }

        const credential = get(credentials, 'newValues')
        const credentialId = get(Object.values(credential), '[0].data.id')

        dispatch({
          type: POST_ADMIN_USER_F_REQUEST,
          payload: {
            values: emailPayload,
          },
          meta: {
            successMessage: '',
            failMessage: '',
            successCallback: ({ newValues }) => {
              const userIds = Object.keys(newValues)

              userIds.forEach(userId => {
                dispatch({
                  type: POST_ADMIN_USER_MEMBERSHIPS_F_REQUEST,
                  payload: {
                    values: {
                      credential: credentialId,
                    },
                    id: userId,
                  },
                  meta: {
                    successMessage: `Credential ${credentialName} has been successfully created.`,
                    failMessage: 'There was an error creating the credential.',
                  },
                })
              })
            },
          },
        })
      },
    },
  })
}

export default submitCredentialForm
