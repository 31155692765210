import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationUnderwritingReview from './ApplicationUnderwritingReview'
import Address from 'components/Customer/Shared/Display/Address/Address'
import DisplayUnmaskC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import getUnderwritingSubjectUnmaskAPI from 'api/finix/get/getUnderwritingSubjectUnmaskAPI'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import hasPermission from 'utilities/hasPermission'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatDateObject from 'utilities/formatters/formatDateObject'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import showModalAction from 'utilities/actions/showModalAction'
import formatPercentage from 'utilities/formatters/formatPercentage'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatMoney from 'utilities/formatters/formatMoney'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'
import { FETCHING } from 'constants/reducerConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import get from 'lodash/get'
import join from 'lodash/join'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'

import {
  FILE_VIEWER_MODAL,
  GOOGLE_MAP_DETAILS_MODAL,
} from 'constants/modalConstants'

import {
  ACCEPTED_BY,
  ACCEPTED_ON,
  ACCEPTED_TERMS_OF_SERVICE,
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  ANNUAL_CARD_VOLUME,
  ANNUAL_TRANSACTION_COUNT,
  BANK_DOCUMENT,
  BUSINESS_NAME,
  BUSINESS_TAX_ID,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE, CONNECTION_TYPE, CURRENCY,
  CUSTOMER_PROFILE,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DOING_BUSINESS_AS,
  EIN_VERIFICATION_DOCUMENT,
  EMAIL_ADDRESS,
  HUBSPOT_DEAL_ID,
  IN_PERSON_PAYMENTS,
  INCORPORATION_DATE,
  INSTITUTION_NUMBER,
  IP_ADDRESS, MANUAL_OPTION,
  MAP_DETAILS,
  MAX_TRANSACTION_AMOUNT,
  MERCHANT,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_COUNT,
  ONLINE_PAYMENTS,
  OTHER,
  OWNERSHIP_TYPE,
  PAYMENT_SOLUTIONS,
  PHONE_NUMBER, PLAID,
  PLATFORM,
  PRICING_TYPE,
  ROUTING_NUMBER,
  SFDC_OPPORTUNITY_RECORD_ID,
  SUBDOMAINS,
  SUBMITTED_BY,
  SUBMITTED_ON,
  TRANSIT_NUMBER,
  TYPE,
  VIEW_FILE,
  WEBSITE,
} from 'constants/language/languageConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
} from 'constants/labelConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials })

  const [
    reviewId,
    subjectId,
    entityId,
  ] = getMany(props, [
    'reviewId',
    'subjectId',
    'entityId',
  ])

  // use the run's subject to populate data on the underwriting review page
  const review = getUnderwritingReviewSelector(state, reviewId)

  const [
    entity = {},
    runSubject = {},
    customerOnboardingForm = {},
    reviewCountry,
    selfieReport,
    governmentIdReport,
    reviewType,
  ] = getMany(review, [
    'entity',
    'run.subject',
    'customerOnboardingForm',
    'country',
    'report.selfie_files[0]',
    'report.government_id_files[0]',
    'reviewType',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[reviewCountry]

  const [
    businessReports = {},
    businessMcc,
    bankAccountReport = {},
    bankAccountReportVersion,
    owners = [],
  ] = getMany(runSubject, [
    'business.reports',
    'business.mcc',
    'bank_accounts[0].reports.bank_accounts',
    'bank_accounts[0].reports.bank_accounts.version',
    'owners',
  ])

  const primaryOwner = filter(owners, { primary_owner: true })
  const beneficialOwners = filter(owners, { primary_owner: false })

  const [
    displayPricingType,
    submittedBy,
    contractId,
    customerType,
    displayCustomerType,
    accessFormType,
    displayAccessFormType,
    acceptedBy,
    displayAcceptedAt,
    ipAddress,
    displaySubmittedAt,
    displayAcceptedPaymentTypes,
    displayAnnualTransactionCount,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    applicationOnlinePaymentsPercentage,
    applicationInPersonPaymentsPercentage,
    applicationBusinessToBusinessPercentage,
    applicationBusinessToConsumerPercentage,
    applicationOtherPercentage,
    applicationDefaultStatementDescriptor,
    applicationAnnualCardVolume,
    applicationAnnualACHVolume,
    applicationBusinessDescription,
  ] = getMany(entity, [
    'displayPricingType',
    'submittedBy',
    'contractId',
    'customerType',
    'displayCustomerType',
    'type',
    'displayType',
    'acceptedBy',
    'displayAcceptedAt',
    'ipAddress',
    'displaySubmittedAt',
    'processingData.displayAcceptedPaymentTypes',
    'processingData.displayAnnualTransactionCount',
    'processingData.maxCardTransactionAmount',
    'processingData.maxACHTransactionAmount',
    'processingData.ecommercePercentage',
    'processingData.cardPresentPercentage',
    'processingData.businessToBusinessVolumePercentage',
    'processingData.businessToConsumerVolumePercentage',
    'processingData.otherVolumePercentage',
    'processingData.defaultStatementDescriptor',
    'processingData.annualCardVolume',
    'processingData.annualACHVolume',
    'businessData.businessDescription',
  ])

  const isSalesProspectAccessForm = accessFormType === 'PROSPECT'
  const contractUrl = `${getDashboardServiceHost()}/contracts/${contractId}/data`

  const [
    hubspotDealId,
    salesforceOpportunityId,
    requestedBy,
  ] = getMany(customerOnboardingForm, [
    'hubspotDealId',
    'salesforceOpportunityId',
    'requestedBy',
  ])

  const applicationFormDetailsDataSection = convertPageSectionDataToV2([
    { label: CUSTOMER_PROFILE, value: displayCustomerType },
    { label: SUBMITTED_ON, value: displaySubmittedAt },
    { label: SUBMITTED_BY, value: submittedBy, condition: !isSalesProspectAccessForm },
    { label: HUBSPOT_DEAL_ID, value: hubspotDealId },
    { label: SFDC_OPPORTUNITY_RECORD_ID, value: salesforceOpportunityId },
    { label: ACCEPTED_TERMS_OF_SERVICE, value: <a className='text-link' href={contractUrl} target='blank'>{customerType === STANDALONE_MERCHANT ? MERCHANT : PLATFORM }</a>, condition: !!contractId },
    { label: ACCEPTED_ON, value: displayAcceptedAt },
    { label: ACCEPTED_BY, value: acceptedBy },
    { label: PRICING_TYPE, value: displayPricingType, condition: !isSalesProspectAccessForm },
    { label: IP_ADDRESS, value: ipAddress },
    { label: TYPE, value: displayAccessFormType },
  ])

  const processingInformationDataSection = convertPageSectionDataToV2([
    { label: PAYMENT_SOLUTIONS, value: displayAcceptedPaymentTypes, condition: !isSalesProspectAccessForm },
    { label: MERCHANT_CATEGORY_CODE, value: join(businessMcc, ', ') },
    { label: DEFAULT_STATEMENT_DESCRIPTOR, value: applicationDefaultStatementDescriptor },
    { label: ANNUAL_TRANSACTION_COUNT, value: displayAnnualTransactionCount },
    { label: ANNUAL_CARD_VOLUME, value: formatMoney({ amount: applicationAnnualCardVolume, currency, showCurrencyCode: true }) },
    { label: annualACHVolumeLabelMap(reviewCountry), value: formatMoney({ amount: applicationAnnualACHVolume, currency, showCurrencyCode: true }) },
    { label: MAX_TRANSACTION_AMOUNT, value: formatMoney({ amount: maxCardTransactionAmount, currency, showCurrencyCode: true }) },
    { label: achMaxTransactionAmountLabelMap(reviewCountry), value: formatMoney({ amount: maxACHTransactionAmount, currency, showCurrencyCode: true }) },
  ])

  const paymentVolumeDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_TO_BUSINESS, value: formatPercentage({ percentage: applicationBusinessToBusinessPercentage }) },
    { label: BUSINESS_TO_CONSUMER, value: formatPercentage({ percentage: applicationBusinessToConsumerPercentage }) },
    { label: OTHER, value: formatPercentage({ percentage: applicationOtherPercentage }) },
  ])

  const cardVolumeDataSection = convertPageSectionDataToV2([
    { label: ONLINE_PAYMENTS, value: formatPercentage({ percentage: applicationOnlinePaymentsPercentage }) },
    { label: IN_PERSON_PAYMENTS, value: formatPercentage({ percentage: applicationInPersonPaymentsPercentage }) },
  ])

  return {
    isFetching,
    reviewId,
    subjectId,
    entityId,
    runSubject,
    entity,
    applicationFormDetailsDataSection,
    businessReports,
    processingInformationDataSection,
    paymentVolumeDataSection,
    cardVolumeDataSection,
    bankAccountReport,
    primaryOwner,
    beneficialOwners,
    showUnmaskAction,
    businessInformationSubTitle: applicationBusinessDescription ? `Description: ${applicationBusinessDescription}` : 'Description: N/A',
    reviewCountry,
    bankAccountCurrency: currency,
    bankAccountReportVersion,
    reviewType,
    selfieReport,
    governmentIdReport,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
    showGoogleMapDetailsModal: (modalProps) => dispatch(showModalAction({ modalType: GOOGLE_MAP_DETAILS_MODAL, modalProps })),
  }
}

class ApplicationUnderwritingReviewC extends Component {
  render() {
    const {
      runSubject,
      entity,
      entityId,
      showFileViewer,
      showUnmaskAction,
      subjectId,
      showGoogleMapDetailsModal,
      reviewCountry,
      bankAccountCurrency,
      bankAccountReportVersion,
    } = this.props

    const [
      businessName,
      doingBusinessAs,
      businessType,
      businessOwnershipType,
      businessIncorporationDate,
      businessWebsite,
      businessPhone,
      businessAddressLine1,
      businessAddressLine2,
      businessAddressCity,
      businessAddressRegion,
      businessAddressCountry,
      businessAddressPostalCode,
      businessTaxId,
      accountNumber,
      routingNumber,
      transitNumber,
      institutionNumber,
      bankAccountName,
      bankAccountType,
    ] = getMany(runSubject, [
      'business.name',
      'business.doing_business_as',
      'business.type',
      'business.ownership_type',
      'business.incorporation_date',
      'business.website_url',
      'business.phone',
      'business.address.line1',
      'business.address.line2',
      'business.address.city',
      'business.address.region',
      'business.address.country',
      'business.address.postal_code',
      'business.tax_id',
      'bank_accounts[0].account_number',
      'bank_accounts[0].bank_code',
      'bank_accounts[0].transit_number',
      'bank_accounts[0].institution_number',
      'bank_accounts[0].name',
      'bank_accounts[0].account_type',
    ])

    const formattedBusinessAddress = {
      line1: businessAddressLine1,
      line2: businessAddressLine2,
      city: businessAddressCity,
      region: businessAddressRegion,
      country: businessAddressCountry,
      postalCode: businessAddressPostalCode,
    }

    const [
      einVerificationFile,
      customerType,
      businessMerchantCount,
      businessWebsiteDomains,
      businessEmail,
      bankDocument,
    ] = getMany(entity, [
      'einVerificationFile',
      'customerType',
      'businessData.businessMerchantCount',
      'businessData.businessWebsiteDomains',
      'businessData.businessEmail',
      'bankDocument',
    ])

    const businessInformationDataSection = convertPageSectionDataToV2([
      { label: BUSINESS_NAME, value: businessName },
      { label: DOING_BUSINESS_AS, value: doingBusinessAs },
      { label: BUSINESS_TYPE, value: snakeCaseToTitleCase({ key: businessType }) },
      { label: OWNERSHIP_TYPE, value: snakeCaseToTitleCase({ key: businessOwnershipType }) },
      { label: INCORPORATION_DATE, value: formatDateObject({ date: businessIncorporationDate }) },
      {
        label: BUSINESS_TAX_ID,
        value: (showUnmaskAction && businessTaxId) ? (
          <DisplayUnmaskC
            id={subjectId}
            api={getUnderwritingSubjectUnmaskAPI}
            feKey='business_tax_id'
            beKey='business.tax_id'
            defaultValue={businessTaxId}
          />
        ) : businessTaxId,
      },
      {
        label: reviewCountry === CAN ? 'BN Verification Document' : EIN_VERIFICATION_DOCUMENT,
        value: einVerificationFile ? VIEW_FILE : '-',
        action: einVerificationFile ? () => {
          showFileViewer({ id: get(einVerificationFile, 'file_id'), accessFormId: entityId })
        } : () => {},
      },
      { label: MERCHANT_COUNT, value: businessMerchantCount, condition: customerType !== STANDALONE_MERCHANT },
      { label: WEBSITE, value: validateWebsiteLink({ website: businessWebsite }) ? <a className='text-link' href={businessWebsite} target='blank'>{businessWebsite}</a> : businessWebsite },
      { label: SUBDOMAINS, value: businessWebsiteDomains },
      { label: EMAIL_ADDRESS, value: businessEmail },
      { label: PHONE_NUMBER, value: formatPhone(businessPhone) },
      { label: ADDRESS, value: <div><Address address={formattedBusinessAddress} /><a className='text-link' onClick={() => showGoogleMapDetailsModal({ businessAddress: formattedBusinessAddress, doingBusinessAs, businessName })}>{MAP_DETAILS}</a></div> },
    ])

    const bankAccountInformationDataSection = convertPageSectionDataToV2([
      { label: ACCOUNT_HOLDER_NAME, value: bankAccountName },
      { label: ACCOUNT_TYPE, value: snakeCaseToTitleCase({ key: bankAccountType }) },
      {
        label: ROUTING_NUMBER,
        value: routingNumber,
        condition: reviewCountry === USA,
      },
      {
        label: TRANSIT_NUMBER,
        value: transitNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: INSTITUTION_NUMBER,
        value: institutionNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: ACCOUNT_NUMBER,
        value: (showUnmaskAction && accountNumber) ? (
          <DisplayUnmaskC
            id={subjectId}
            api={getUnderwritingSubjectUnmaskAPI}
            feKey='bank_account_number'
            beKey='bank_accounts[0].account_number'
            defaultValue={accountNumber}
          />
        ) : accountNumber,
      },
      {
        label: BANK_DOCUMENT,
        value: bankDocument ? VIEW_FILE : '-',
        action: bankDocument ? () => {
          showFileViewer({ id: get(bankDocument, 'file_id'), accessFormId: entityId })
        } : () => {},
      },
      {
        label: CURRENCY,
        value: bankAccountCurrency,
      },
      {
        label: CONNECTION_TYPE,
        value: isEqual(bankAccountReportVersion, 'V2') ? PLAID : MANUAL_OPTION,
      },
    ])

    return (
      <ApplicationUnderwritingReview
        {...this.props}
        businessInformationDataSection={businessInformationDataSection}
        bankAccountInformationDataSection={bankAccountInformationDataSection}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUnderwritingReviewC)
