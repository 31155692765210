import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import Button from 'components/Shared/Button/Button'
import PersonalTaxIdField from 'components/Shared/Inputs/PersonalTaxIdField/PersonalTaxIdField'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { USA } from 'constants/countryConstants'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'

import {
  ADD_ANOTHER,
  BENEFICIAL_OWNER,
  PERSONAL_ADDRESS_LINE1_LOWER_LABEL,
  DATE_OF_BIRTH,
  EMAIL_ADDRESS,
  FIRST_NAME,
  HOME_ADDRESS,
  JOB_TITLE,
  LAST_NAME,
  OWNERSHIP_PERCENTAGE,
  PHONE_NUMBER,
  REMOVE,
} from 'constants/language/languageConstants'

const BeneficialOwners = ({
  fields,
  ownershipPercentageError,
  form,
  onboardingFormCountry = USA,
  beneficialOwnersCurrentCountries = [],
  isBoardingToFinixV1Only = false,
}) => (
  <ul className='BeneficialOwners'>
    {fields.map((beneficialOwner = {}, index) => {
      const beneficialOwnerCurrentCountry = beneficialOwnersCurrentCountries[index] || USA
      const beneficialOwnerTitle = concat(BENEFICIAL_OWNER, ' #', index + 1)
      const key = `beneficial_owner_${index}`

      return (
        <li className='beneficial-owner-content' key={key}>
          <div className='header flex space-between items-center'>
            <div>
              <h5 className='title'>{beneficialOwnerTitle}</h5>
            </div>
            {index > 0 && (
              <Button onClick={() => fields.remove(index)} leftIcon='user-minus' label={REMOVE} variant='secondary' />
            )}
          </div>

          <div className='flex space-between'>
            <Field
              name={`${beneficialOwner}.firstName`}
              label={FIRST_NAME}
              component={InputField}
              autoComplete='given-name'
            />

            <Field
              name={`${beneficialOwner}.lastName`}
              label={LAST_NAME}
              component={InputField}
              autoComplete='family-name'
            />
          </div>

          <Field
            name={`${beneficialOwner}.title`}
            label={JOB_TITLE}
            component={InputField}
            autoComplete='organization-title'
          />

          <DateFieldset
            name={`${beneficialOwner}.dateOfBirth`}
            label={DATE_OF_BIRTH}
            autoComplete={{
              day: 'bday-day',
              month: 'bday-month',
              year: 'bday-year',
            }}
          />

          <PersonalTaxIdField
            name={`${beneficialOwner}.taxId`}
            country={beneficialOwnerCurrentCountry}
            onboardingFormCountry={onboardingFormCountry}
          />

          <div className='flex space-between'>
            <Field
              name={`${beneficialOwner}.email`}
              label={EMAIL_ADDRESS}
              component={InputField}
              autoComplete='email'
            />

            <Field
              name={`${beneficialOwner}.phone`}
              label={PHONE_NUMBER}
              component={InputField}
              format={formatPhone}
              parse={parsePhone}
              placeholder='000-000-0000'
              autoComplete='tel-national'
            />
          </div>

          <AddressC
            name={`${beneficialOwner}.personalAddress`}
            label={HOME_ADDRESS}
            showLabels
            form={form}
            helperText={isBoardingToFinixV1Only ? { addressLine1HelperText: '' } : { addressLine1HelperText: PERSONAL_ADDRESS_LINE1_LOWER_LABEL }}
          />

          <PercentageField
            name={`${beneficialOwner}.principalPercentageOwnership`}
            label={OWNERSHIP_PERCENTAGE}
            placeholder='0'
            onlyWholeNumbers
          />
        </li>
      )
    })}


    { !isEmpty(fields) && fields.length < 4 && !ownershipPercentageError && (
    <li className='add-another-beneficial-owner-content'>
      <Button onClick={() => fields.push({ personalAddress: { country: onboardingFormCountry } })} label={ADD_ANOTHER} leftIcon='user-plus' variant='secondary' />
    </li>
    )}
  </ul>
)

BeneficialOwners.propTypes = {
  fields: PropTypes.object,
  ownershipPercentageError: PropTypes.string,
  form: PropTypes.string,
  onboardingFormCountry: PropTypes.string,
  beneficialOwnersCurrentCountries: PropTypes.array,
  isBoardingToFinixV1Only: PropTypes.bool,
}

export default BeneficialOwners
