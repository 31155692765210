import './SkeletonS.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Skeleton = ({
  width = '100%',
  height = '26px',
  className = '',
}) => {
  return (
    <div className={`Skeleton ${className}`}>
      <div className='skeleton-bar' style={{ width, height }} />
    </div>
  )
}

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
}

export default Skeleton
