import React from 'react'
import PropTypes from 'prop-types'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TransferFailureDetailsC from 'components/Customer/Shared/Display/TransferFailureDetails/TransferFailureDetailsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { PATCH_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import isEmpty from 'lodash/isEmpty'

const PlatformFundingTransfer = ({
  tagsSectionData = [],
  transfer = {},
  paymentInstrument = {},
  isFetching = false,
  headerData = {},
  contextBarData = {},
  showError = false,
  device = {},
}) => {
  return (
    <div className='PlatformFundingTransfer'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      { showError && <TransferFailureDetailsC transfer={transfer} /> }

      {!isEmpty(paymentInstrument) && (
      <PaymentInstrumentDetailsC
        isFetching={isFetching}
        device={device}
        paymentInstrument={paymentInstrument}
      />
      )}

      <TagsPageSectionV2
        actionType={PATCH_TRANSFER_F_REQUEST}
        data={tagsSectionData}
        record={transfer}
        isFetching={isFetching}
      />
    </div>
  )
}

PlatformFundingTransfer.propTypes = {
  tagsSectionData: PropTypes.array,
  transfer: PropTypes.object,
  paymentInstrument: PropTypes.object,
  isFetching: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  showError: PropTypes.bool,
  device: PropTypes.object,
}

export default PlatformFundingTransfer
