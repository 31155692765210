import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import column from 'utilities/table/column'
import { MONITORING_ALERT_STATUS_ICON_MAP, MONITORING_ALERT_STATUS_MAP, MONITORING_ALERT_STATUS_TO_DISPLAY_MAP } from 'constants/monitoringAlertConstants'
import get from 'lodash/get'

import {
  createdAtRange,
} from 'utilities/table/sort'

import {
  ID,
  CREATED_ON,
  TRANSACTION_MONITORING_ALERT_NAME,
  TRANSACTION_MONITORING_TARGET,
  TRANSACTION_MONITORING_APPLICATION_MERCHANT,
  TRANSACTION_MONITORING_THRESHOLD,
  TRANSACTION_MONITORING_ACTUAL_VALUE,
  STATUS,
  TRANSACTION_MONITORING_LOOKBACK,
  TRANSACTION_MONITORING_CADENCE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TRANSACTION_MONITORING_ALERT_NAME, 'monitoringRuleDetails.name', {
    className: 'large',
    formatter: ({ monitoringRuleDetails }) => get(monitoringRuleDetails, 'name'),
  }),
  column(TRANSACTION_MONITORING_TARGET, ['linkedName', 'groupedBy'], {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={TRANSACTION_MONITORING_TARGET} subtitle={TRANSACTION_MONITORING_APPLICATION_MERCHANT} />,
    formatter: (alert => {
      return (
        <TableItemSubtitle
          title={get(alert, 'groupedBy')}
          subtitle={get(alert, 'linkedName')}
        />
      )
    }),
  }),
  column(TRANSACTION_MONITORING_THRESHOLD, ['displayResult', 'monitoringRuleDetails.displayThresholdValue'], {
    className: 'small',
    headerFormatter: () => <TableItemSubtitle title={TRANSACTION_MONITORING_ACTUAL_VALUE} subtitle={TRANSACTION_MONITORING_THRESHOLD} />,
    formatter: (alert => {
      return (
        <TableItemSubtitle
          title={get(alert, 'displayResult')}
          subtitle={`>${get(alert, 'monitoringRuleDetails.displayThresholdValue')}`}
        />
      )
    }),
  }),
  column(STATUS, 'displayState', {
    className: 'medium',
    formatter: ({ state }) => <ColorcodedStatus data={MONITORING_ALERT_STATUS_MAP} value={MONITORING_ALERT_STATUS_TO_DISPLAY_MAP[state]} customIconMap={MONITORING_ALERT_STATUS_ICON_MAP} titleCase={false} />,
  }),
  column(TRANSACTION_MONITORING_LOOKBACK, 'monitoringRuleDetails.displayIntervalValue', {
    className: 'small',
  }),
  column(TRANSACTION_MONITORING_CADENCE, 'monitoringRuleDetails.displayCadenceValue', {
    className: 'small',
  }),
]

export default columnDescriptors
