import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PhoneField from 'components/Shared/Inputs/PhoneField/PhoneField'
import submitMFASMSForm from 'utilities/submit/submitMFASMSForm'
import validateSetupMFASMSForm from 'utilities/validate/validateSetupMFASMSForm'
import { SETUP_MFA_SMS_FORM } from 'constants/formConstants'
import { FINIX_PRIVACY_POLICY_URL } from 'constants/urlConstants'

import {
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

const SetupMFASMSForm = ({
  type = '',
  handleSubmit = () => {},
  showConfirmPasswordMFAModal = () => {},
  isPatching = false,
  invalid = false,
}) => {
  return (
    <form className='SetupMFASMSForm' onSubmit={handleSubmit(submitMFASMSForm)}>
      <div className='modal-content'>
        <PhoneField
          name='phoneNumber'
          label='Mobile Number'
          classNames='mt-0 mb-xs'
          disabled={isPatching}
        />

        <span className='label-2 secondary'>
          By submitting, you agree to receive texts from Finix and to <a href={FINIX_PRIVACY_POLICY_URL} className='link-2' target='blank'>Finix&apos;s privacy policy.</a>
        </span>
      </div>

      <div className='buttons flex space-between'>
        <Button onClick={() => showConfirmPasswordMFAModal({ type })} label={BACK} variant='tertiary' className='cancel-button' disabled={isPatching} />
        <Button type='submit' label={NEXT} disabled={invalid || isPatching} />
      </div>
    </form>
  )
}

SetupMFASMSForm.propTypes = {
  type: PropTypes.string,
  handleSubmit: PropTypes.func,
  showConfirmPasswordMFAModal: PropTypes.func,
  isPatching: PropTypes,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: SETUP_MFA_SMS_FORM,
  validate: validateSetupMFASMSForm,
})(SetupMFASMSForm)
