import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import validateCreateSubscriptionEnrollmentForm from 'utilities/validate/validateCreateSubscriptionEnrollmentForm'
import { CREATE_SUBSCRIPTION_ENROLLMENT } from 'constants/formConstants'
import { DATE_PICKER_TIME_FORMAT } from 'constants/timeConstants'

const CreateSubscriptionEnrollmentForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  enrollmentStaredAt,
  localTimezone,
}) => {
  return (
    <form className='CreateSubscriptionEnrollmentForm' onSubmit={handleSubmit}>
      <div className='section'>
        <Field
          name='enrollmentNickname'
          label='Enrollment Nickname'
          component={InputField}
        />

        <Field
          name='startedAt'
          label={`Start Date and Time (${localTimezone})`}
          component={DatePickerInput}
          minDate={moment().add(1, 'days').toDate()}
          dateFormat={DATE_PICKER_TIME_FORMAT}
          placeholderText='Select start date and time'
          autofill={false}
          showTimeSelect
        />

        <Field
          name='endedAt'
          label={`End Date and Time (${localTimezone})`}
          component={DatePickerInput}
          minDate={moment(enrollmentStaredAt).add(1, 'days').toDate()}
          dateFormat={DATE_PICKER_TIME_FORMAT}
          placeholderText='Select end date and time (optional)'
          autofill={false}
          showTimeSelect
          disabled={!enrollmentStaredAt}
        />

        <TagsField
          name='tags'
          label='Tags'
        />
      </div>

      {buttonGroup}
    </form>
  )
}

CreateSubscriptionEnrollmentForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  enrollmentStaredAt: PropTypes.object,
  localTimezone: PropTypes.string,
}

export default reduxForm({
  form: CREATE_SUBSCRIPTION_ENROLLMENT,
  validate: validateCreateSubscriptionEnrollmentForm,
})(CreateSubscriptionEnrollmentForm)
