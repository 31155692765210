import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import EditSubscriptionAmountForm from 'components/Customer/Forms/EditSubscriptionAmountForm/EditSubscriptionAmountForm'
import selectOption from 'utilities/forms/selectOption'
import { EDIT_SUBSCRIPTION_AMOUNT } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const subscriptionAmounts = get(props, 'subscriptionAmounts')
  const formSelector = formValueSelector(EDIT_SUBSCRIPTION_AMOUNT)
  const subscriptionAmountId = formSelector(state, 'subscriptionAmountId')
  const subscriptionAmount = get(subscriptionAmounts, subscriptionAmountId)

  const subscriptionAmountsOptions = [selectOption('Select Subscription Amount', '')]
    .concat(map(subscriptionAmounts, ({ id, amountNickname, amount }) => selectOption(`${amountNickname} - $${amount}`, id)))

  return {
    subscriptionAmountId,
    subscriptionAmountsOptions,
    subscriptionAmount,
  }
}

class EditSubscriptionAmountFormC extends Component {
  componentDidUpdate(prevProps) {
    const { subscriptionAmount: prevSubscriptionAmount } = prevProps
    const { subscriptionAmount, dispatch } = this.props
    const prevSubscriptionAmountId = get(prevSubscriptionAmount, 'id')
    const subscriptionAmountId = get(subscriptionAmount, 'id')

    if (subscriptionAmountId && subscriptionAmountId !== prevSubscriptionAmountId) {
      map(['amountNickname', 'amount', 'label', 'tags'], (key) => {
        if (key === 'tags') {
          const tagValues = map(get(subscriptionAmount, key), (tagValue, tagKey) => ({ name: tagKey, value: tagValue }))

          dispatch(change(EDIT_SUBSCRIPTION_AMOUNT, key, tagValues))
        } else {
          const value = get(subscriptionAmount, key)

          dispatch(change(EDIT_SUBSCRIPTION_AMOUNT, key, value))
        }
      })
    }
  }

  render() {
    return (
      <EditSubscriptionAmountForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EditSubscriptionAmountFormC)
