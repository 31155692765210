import './MerchantInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import MerchantVerificationsC from 'components/Customer/Pages/Merchant/MerchantVerifications/MerchantVerificationsC'
import VerificationRejectionDetailsC from 'components/Customer/Shared/Display/VerificationRejectionDetails/VerificationRejectionDetailsC'
import isEmpty from 'lodash/isEmpty'

import {
  DETAILS,
  PROCESSOR_DETAILS,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

const MerchantInfo = ({
  merchantId = '',
  processorInfoSectionData = [],
  processorDetailsSectionData = [],
  showRejectInformationSection = false,
  showProcessorDetailsSection = false,
  isFetching = false,
  isFetchingVerifications = false,
  verification = {},
  processorDetails = {},
  showProcessorDetailsModal = () => {},
  isStandaloneUnderwriting = false,
  isStandaloneUnderwritingDetailsSection = [],
}) => {
  const viewProcessorDetailsLink = !isEmpty(processorDetails) ? (
    <div
      className='view-processor-details'
      onClick={() => showProcessorDetailsModal({ processorDetails })}
    >
      {VIEW_API_RESPONSE}
    </div>
  ) : null

  return (
    <div className='MerchantInfo'>
      { showRejectInformationSection && (
        <VerificationRejectionDetailsC
          isFetching={isFetchingVerifications}
          verification={verification}
        />
      )}

      {isStandaloneUnderwriting && (
        <PageSectionV2C
          title={DETAILS}
          isFetching={isFetching}
          columns={isStandaloneUnderwritingDetailsSection}
        />
      )}

      {!isStandaloneUnderwriting && (
      <>
        <PageSectionV2C
          title={DETAILS}
          isFetching={isFetching}
          columns={processorInfoSectionData}
        />

        { showProcessorDetailsSection && (
        <PageSectionV2C
          title={PROCESSOR_DETAILS}
          isFetching={isFetching}
          asideTitle={viewProcessorDetailsLink}
          columns={processorDetailsSectionData}
        />
        )}
      </>
      )}

      <MerchantVerificationsC merchantId={merchantId} />
    </div>
  )
}

MerchantInfo.propTypes = {
  merchantId: PropTypes.string,
  processorInfoSectionData: PropTypes.array,
  processorDetailsSectionData: PropTypes.array,
  showRejectInformationSection: PropTypes.bool,
  showProcessorDetailsSection: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingVerifications: PropTypes.bool,
  verification: PropTypes.object,
  processorDetails: PropTypes.object,
  showProcessorDetailsModal: PropTypes.func,
  isStandaloneUnderwriting: PropTypes.bool,
  isStandaloneUnderwritingDetailsSection: PropTypes.array,
}

export default MerchantInfo
