const formatBN = (value) => {
  if (!value) {
    return ''
  }

  const onlyNumsOrLetters = value.replace(/[^A-Za-z0-9*]/g, '')

  return onlyNumsOrLetters.slice(0, 9)
}

export default formatBN
