import frontendNoteModel from 'utilities/create/models/frontendNoteModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendNotesModel = ({ data: notes }) => {
  const notesModel = map(notes, (note) => frontendNoteModel({ data: note }))

  return keyBy(notesModel, 'id')
}

export default frontendNotesModel
