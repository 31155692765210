import React, { Component } from 'react'
import { connect } from 'react-redux'
import TransferReceiptHistory from './TransferReceiptHistory'
import { goToPath } from 'state-layer/history'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { CREATE_TRANSFER_RECEIPT_PATH } from 'constants/pathConstants'
import { SEND_RECEIPT_MODAL } from 'constants/modalConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  SEND_RECEIPT,
  VIEW_RECEIPT,
  CREATE_RECEIPT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {
    credentials,
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  showSendReceiptModal: (modalProps) => dispatch(showModalAction({
    modalType: SEND_RECEIPT_MODAL,
    modalProps,
    className: 'modal-md no-pad',
  })),
})

class TransferReceiptHistoryC extends Component {
  render() {
    const {
      receipts,
      transferId,
      credentialId,
      showSendReceiptModal,
    } = this.props

    const receiptsActions = [
      {
        label: CREATE_RECEIPT,
        variant: 'secondary',
        action: () => goToPath({ pathname: CREATE_TRANSFER_RECEIPT_PATH({ credentialId, transferId }) }),
        condition: isEmpty(receipts),
        className: 'create-receipt-button',
      },
    ]

    const receiptActions = ({ receipt, transfer }) => [
      {
        label: VIEW_RECEIPT,
        buttonVariant: 'secondary',
        action: () => {
          window.open(get(receipt, 'receiptUrl'), '_blank')
        },
        className: 'view-receipt-button',
      },
      {
        label: SEND_RECEIPT,
        buttonVariant: 'primary',
        action: () => {
          showSendReceiptModal({ receipt, transfer })
        },
        className: 'send-receipt-button',
      },
    ]

    return (
      <TransferReceiptHistory
        {...this.props}
        receiptActions={receiptActions}
        receiptsActions={receiptsActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferReceiptHistoryC)
