import './ResendDashboardInviteFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { RESEND_DASHBOARD_INVITE_FORM } from 'constants/formConstants'
import submitSendWelcomeEmailForm from 'utilities/submit/submitSendWelcomeEmailForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'

import {
  RESEND_DASHBOARD_INVITE_EMAIL_INSTRUCTIONS,
  TEAM_MEMBER_EMAIL,
  RESEND_DASHBOARD_INVITE_EMAIL,
  CANCEL,
} from 'constants/language/languageConstants'

const ResendDashboardInviteForm = ({
  handleSubmit = () => {},
  disableAction = false,
  closeModal = () => {},
  email,
  dashboardName = '',
}) => {
  return (
    <form className='ResendDashboardInviteForm' onSubmit={handleSubmit(submitSendWelcomeEmailForm)}>
      {email && <div className='msg'>{`This will resend the dashboard invite email for ${dashboardName} dashboard to ${email}`}</div>}

      {!email && (
        <>
          <div className='instructions'>{RESEND_DASHBOARD_INVITE_EMAIL_INSTRUCTIONS}</div>

          <Field
            name='email'
            label={TEAM_MEMBER_EMAIL}
            component={InputField}
          />
        </>
      )}

      <div className='buttons flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={disableAction} label={RESEND_DASHBOARD_INVITE_EMAIL} />
      </div>
    </form>
  )
}

ResendDashboardInviteForm.propTypes = {
  handleSubmit: PropTypes.func,
  disableAction: PropTypes.bool,
  closeModal: PropTypes.func,
  email: PropTypes.string,
  dashboardName: PropTypes.string,
}

export default reduxForm({
  form: RESEND_DASHBOARD_INVITE_FORM,
})(ResendDashboardInviteForm)
