import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import DisputeRespondWithinStatus from 'components/Customer/Shared/Display/ColorcodedStatus/DisputeRespondWithinStatus'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { disputeStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'

import {
  createdAtRange,
  respondByRange,
  updatedAtRange,
  amountRange,
} from 'utilities/table/sort'

import {
  AMOUNT,
  APPLICATION,
  BRAND_SLASH_TYPE,
  BUYER,
  CREATED_ON,
  ID,
  MASKED_NUMBER,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  PAYMENT_INSTRUMENT,
  REASON,
  RESPOND_WITHIN,
  STATE,
  UPDATED_ON,
  INSTRUMENT_TYPE,
  EMAIL,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ isWonOrLostQuickFilter }) => {
  return [
    column(ID, 'id', {
      className: 'copy-click-id',
      formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
      condition: notRoleMerchant,
    }),
    column(CREATED_ON, 'createdAt', {
      className: 'date',
      sort: createdAtRange,
      headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
      formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
    }),
    column(UPDATED_ON, 'displayUpdatedAt', {
      className: 'date',
      sort: updatedAtRange,
      headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
      formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
    }),
    column(RESPOND_WITHIN, 'displayRespondWithin', {
      sort: respondByRange,
      formatter: ({ respondWithin, displayRespondWithin, displayRespondBy }) => <DisputeRespondWithinStatus inTable value={respondWithin} displayValue={displayRespondWithin} hoverOverText={displayRespondBy} />,
      condition: !isWonOrLostQuickFilter,
    }),
    column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
      className: 'medium',
      headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
      formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
      condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
    }),
    column(BUYER, 'buyerIdentity.buyerName', {
      className: 'name',
      headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={EMAIL} />,
      formatter: ({ buyerIdentity }) => <TableItemSubtitle title={get(buyerIdentity, 'buyerName')} subtitle={get(buyerIdentity, 'email')} />,
      condition: notRoleMerchant,
    }),
    column(AMOUNT, ['displayAmount', 'currency'], {
      className: 'amount divider',
      sort: amountRange,
      formatter: ({ displayAmount, transfer }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={get(transfer, 'currency')} />,
    }),
    column(STATE, 'state', {
      headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON} />,
      className: 'state',
      formatter: ({ state, displayReason }) => (
        <TableItemSubtitle
          titleComponent={() => <ColorcodedStatus value={state} data={disputeStatusMap} />}
          subtitle={displayReason}
          subtitleIndent
        />
      ),
    }),
    column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
      headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
      formatter: ({ paymentInstrument }) => {
        const [
          name,
          maskedAccountNumber,
          maskedFullCardNumber,
        ] = getMany(paymentInstrument, [
          'name',
          'maskedAccountNumber',
          'maskedFullCardNumber',
        ])

        return (
          <TableItemSubtitle
            title={name}
            subtitle={maskedAccountNumber || maskedFullCardNumber}
          />
        )
      },
      className: 'normal',
    }),
    column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
      headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
      formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
      className: 'normal',
    }),
  ]
}

export default columnDescriptors
