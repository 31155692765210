import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityInfo from './IdentityInfo'
import Address from 'components/Customer/Shared/Display/Address/Address'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import { FETCHING } from 'constants/reducerConstants'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getAssociatedIdentitiesRequest from 'utilities/actions/get/getAssociatedIdentitiesRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import redirectRequest from 'utilities/actions/redirectRequest'
import isPlatform from 'utilities/is/isPlatform'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import hasPermissions from 'utilities/hasPermissions'
import createUrl from 'utilities/create/createUrl'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isFlexPlatform } from 'constants/flexConstants'
import { EDIT_ICON } from 'constants/iconConstants'
import { IDENTITY } from 'constants/apiConstants'
import { IDENTITIES_API_DOCS_LINK } from 'constants/urlConstants'

import {
  EDIT_IDENTITY_PROCESSING_INFO_MODAL,
  VIEW_API_RESPONSE_MODAL,
} from 'constants/modalConstants'

import {
  EDIT_ASSOCIATED_IDENTITY_PATH,
  UNDERWRITING_MERCHANT_PATH,
} from 'constants/pathConstants'

import {
  EDIT_IDENTITY_PERMISSION,
} from 'constants/permissionConstants'

import {
  getIdentitySelector,
  getIdentityAssociatedIdentitiesSelector,
  getUnderwritingProfileByEntityId,
  getUnderwritingProfileByPlatform,
} from 'state-layer/selectors'

import {
  SYSTEM,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  ADDRESS,
  AMERICAN_EXPRESS_MID,
  ANNUAL_ACH_VOLUME,
  ANNUAL_CARD_VOLUME,
  AVERAGE_ACH_TRANSACTION_AMOUNT,
  BUSINESS_ADDRESS,
  BUSINESS_PHONE,
  BUSINESS_TAX_ID_PROVIDED,
  BUSINESS_NAME,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE,
  CARD_PRESENT,
  CREDIT_CHECK,
  CREDIT_CHECK_IP_ADDRESS,
  CREDIT_CHECK_TIMESTAMP,
  CONSUMER_TO_CONSUMER,
  DATE_OF_BIRTH,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DISCOVERY_MID,
  DOING_BUSINESS_AS,
  ECOMMERCE,
  EDIT,
  EMAIL,
  INCORPORATION_DATE,
  INDIVIDUAL_TAX_ID_PROVIDED,
  MAIL_TELEPHONE_ORDER,
  MAX_CARD_TRANSACTION_AMOUNT,
  MAX_ACH_TRANSACTION_AMOUNT,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_AGREEMENT_ACCEPTED,
  MERCHANT_AGREEMENT_IP_ADDRESS,
  MERCHANT_AGREEMENT_TIMESTAMP,
  OTHER,
  OWNERSHIP_TYPE,
  PERSON_TO_PERSON,
  PREVIOUSLY_ACCEPTED_CREDIT_CARDS,
  PHONE,
  REFUND_POLICY,
  TAX_AUTHORITY,
  WEBSITE,
  APPLICATION_MAX_CARD_TRANSACTION_AMOUNT,
  APPLICATION_MAX_ACH_TRANSACTION_AMOUNT,
  AVERAGE_CARD_TRANSACTION_AMOUNT,
  IDENTITIES_API_REFERENCE,
} from 'constants/language/languageConstants'

import get from 'lodash/get'
import map from 'lodash/map'
import values from 'lodash/values'
import every from 'lodash/every'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const associatedIdentities = values(getIdentityAssociatedIdentitiesSelector(state, identityId))
  const owners = [identity, ...associatedIdentities]
  const underwritingReviews = get(props, 'underwritingReviews')
  const isFlex = isFlexPlatform()

  const [
    dashboardUserRoleName,
    dashboardUserRoleType,
  ] = getMany(credentials, [
    'dashboardUserRoleName',
    'dashboardUserRoleType',
  ])

  const {
    additionalUnderwritingData,
    displayAnnualCardVolume,
    amexMid,
    businessAddress,
    businessDescription,
    businessName,
    businessTaxIdProvided,
    displayBusinessPhone,
    creditCheckIpAddress,
    creditCheckTimestamp,
    displayBusinessType,
    displayCreditCheckAllowed,
    defaultStatementDescriptor,
    discoverMid,
    formattedAnnualACHVolume,
    formattedAverageCardTransferAmount,
    formattedAverageACHTransferAmount,
    displayBusinessToBusinessVolumePercentage,
    displayBusinessToConsumerVolumePercentage,
    displayCardPresentPercentage,
    displayConsumerToConsumerVolumePercentage,
    displayEcommercePercentage,
    displayIncorporationDate,
    displayMailOrderTelephoneOrderPercentage,
    displayMerchantAgreementAccepted,
    displayOtherVolumePercentage,
    displayOwnershipType,
    displayPersonToPersonVolumePercentage,
    displayRefundPolicy,
    doingBusinessAs,
    hasAcceptedCreditCardsPreviously,
    displayMaxTransactionAmount,
    displayMaxACHTransactionAmount,
    merchantAgreementIpAddress,
    merchantAgreementTimestamp,
    mcc,
    tags,
    taxAuthority,
    website,
    applicationId,
  } = identity

  // if the application has an underwriting profile set up, grab it from reducer with application ID, if not grab the platform underwriting profile
  const applicationUnderwritingProfile = getUnderwritingProfileByEntityId(state, applicationId) || getUnderwritingProfileByPlatform(state)

  const [
    displayApplicationMaxTransactionAmount,
    displayApplicationMaxACHTransactionAmount,
  ] = getMany(applicationUnderwritingProfile, [
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
  ])

  const detailsSectionSubtitle = businessDescription ? `Description: ${businessDescription}` : ''
  const showWebsiteAsLink = validateWebsiteLink({ website }) ? <a className='text-link' href={website} target='blank'>{website}</a> : website

  const hasUnderwritingPermissions = isFlex && isRolePlatform({ credentials }) && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName)

  const detailsData = [
    {
      label: BUSINESS_NAME,
      value: businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
    },
    {
      label: WEBSITE,
      value: showWebsiteAsLink,
    },
    {
      label: OWNERSHIP_TYPE,
      value: displayOwnershipType,
    },
    {
      label: BUSINESS_TYPE,
      value: displayBusinessType,
    },
    {
      label: BUSINESS_TAX_ID_PROVIDED,
      value: businessTaxIdProvided,
    },
    {
      label: INCORPORATION_DATE,
      value: displayIncorporationDate,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
    {
      label: CREDIT_CHECK,
      value: displayCreditCheckAllowed,
    },
    {
      label: CREDIT_CHECK_IP_ADDRESS,
      value: creditCheckIpAddress,
    },
    {
      label: CREDIT_CHECK_TIMESTAMP,
      value: creditCheckTimestamp,
    },
    {
      label: MERCHANT_AGREEMENT_ACCEPTED,
      value: displayMerchantAgreementAccepted,
    },
    {
      label: MERCHANT_AGREEMENT_IP_ADDRESS,
      value: merchantAgreementIpAddress,
    },
    {
      label: MERCHANT_AGREEMENT_TIMESTAMP,
      value: merchantAgreementTimestamp,
    },
    {
      label: REFUND_POLICY,
      value: displayRefundPolicy,
    },
  ]

  const detailsSectionData = convertPageSectionDataToV2(detailsData)

  const maxCardTransactionTooltipMsg = `${APPLICATION_MAX_CARD_TRANSACTION_AMOUNT}: ${displayApplicationMaxTransactionAmount}`
  const maxACHTransactionTooltipMsg = `${APPLICATION_MAX_ACH_TRANSACTION_AMOUNT}: ${displayApplicationMaxACHTransactionAmount}`

  const processingDetailsData = [
    {
      label: MERCHANT_CATEGORY_CODE,
      value: mcc,
    },
    {
      label: DEFAULT_STATEMENT_DESCRIPTOR,
      value: defaultStatementDescriptor,
    },
    {
      label: MAX_CARD_TRANSACTION_AMOUNT,
      value: <div className='flex space-between'><div>{displayMaxTransactionAmount}</div><TooltipLabelC position='right' message={maxCardTransactionTooltipMsg} /></div>,
    },
    {
      label: MAX_ACH_TRANSACTION_AMOUNT,
      value: <div className='flex space-between'><div>{displayMaxACHTransactionAmount}</div><TooltipLabelC position='right' message={maxACHTransactionTooltipMsg} /></div>,
    },
    {
      label: ANNUAL_CARD_VOLUME,
      value: displayAnnualCardVolume,
    },
    {
      label: ANNUAL_ACH_VOLUME,
      value: formattedAnnualACHVolume,
    },
    {
      label: AVERAGE_CARD_TRANSACTION_AMOUNT,
      value: formattedAverageCardTransferAmount,
    },
    {
      label: AVERAGE_ACH_TRANSACTION_AMOUNT,
      value: formattedAverageACHTransferAmount,
    }, {
      label: TAX_AUTHORITY,
      value: taxAuthority,
    },
    {
      label: AMERICAN_EXPRESS_MID,
      value: amexMid,
    },
    {
      label: DISCOVERY_MID,
      value: discoverMid,
    },
    {
      label: PREVIOUSLY_ACCEPTED_CREDIT_CARDS,
      value: hasAcceptedCreditCardsPreviously,
    },
  ]

  const processingDetailsSectionData = convertPageSectionDataToV2(processingDetailsData)

  const isElavon = isPlatform('elavonpartnerconnect')

  const businessPrincipalsDataCards = map(owners, (owner) => {
    const [
      title,
      firstName,
      lastName,
      middleName,
      displayPrincipalPercentageOwnership,
      taxIdProvided,
      displayDateOfBirth,
      displayPhone,
      email,
      personalAddress,
    ] = getMany(owner, [
      'title',
      'firstName',
      'lastName',
      'middleName',
      'principalPercentageOwnership',
      'taxIdProvided',
      'displayDateOfBirth',
      'displayPhone',
      'email',
      'personalAddress',
    ])

    const nameData = isElavon ? `${firstName} ${middleName} ${lastName} ` : `${firstName} ${lastName} `
    const titleJSX = title ? `${title}: ` : ''
    const principalOwnershipTitle = `(${titleJSX}${displayPrincipalPercentageOwnership}% Ownership)`

    return {
      largeTitle: <div>{nameData} <span className='large-title-aside'>{principalOwnershipTitle}</span></div>,
      headerActions: [
        {
          label: EDIT,
          link: EDIT_ASSOCIATED_IDENTITY_PATH({ credentialId, identityId }),
        },
      ],
      data: [
        {
          label: EMAIL,
          value: email,
        },
        {
          label: PHONE,
          value: displayPhone,
        },
        {
          label: ADDRESS,
          value: <Address address={personalAddress} />,
        },
        {
          label: DATE_OF_BIRTH,
          value: displayDateOfBirth,
        },
        {
          label: INDIVIDUAL_TAX_ID_PROVIDED,
          value: taxIdProvided,
        },
      ],
    }
  })

  const volumeDistributionData = [
    {
      label: CARD_PRESENT,
      value: displayCardPresentPercentage,
    },
    {
      label: ECOMMERCE,
      value: displayEcommercePercentage,
    },
    {
      label: MAIL_TELEPHONE_ORDER,
      value: displayMailOrderTelephoneOrderPercentage,
    },
    {
      label: BUSINESS_TO_BUSINESS,
      value: displayBusinessToBusinessVolumePercentage,
    },
    {
      label: BUSINESS_TO_CONSUMER,
      value: displayBusinessToConsumerVolumePercentage,
    },
    {
      label: PERSON_TO_PERSON,
      value: displayPersonToPersonVolumePercentage,
    },
    {
      label: CONSUMER_TO_CONSUMER,
      value: displayConsumerToConsumerVolumePercentage,
    },
    {
      label: OTHER,
      value: displayOtherVolumePercentage,
    },
  ]

  const allDataEmpty = every(volumeDistributionData, ({ value }) => !value)

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const underwritingReviewPath = ({ id, subjectId, entityId, entityType }) => createUrl({
    url: UNDERWRITING_MERCHANT_PATH({ credentialId, reviewId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
    }),
  })

  return {
    isFetching,
    identityId,
    identity,
    credentials,
    credentialId,
    processingDetailsSectionData,
    detailsSectionData,
    detailsSectionSubtitle,
    businessPrincipalsDataCards,
    tagsSectionData,
    associatedIdentities,
    additionalUnderwritingData,
    isRolePlatformCredential,
    volumeDistributionData,
    allDataEmpty,
    applicationId,
    applicationUnderwritingProfile,
    isFlex: isFlexPlatform(),
    underwritingReviews,
    underwritingReviewPath,
    hasUnderwritingPermissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAssociatedIdentities: ({ identityId, credentials }) => dispatch(getAssociatedIdentitiesRequest({ identityId, credentials })),
    editAssociatedIdentities: (path) => dispatch(redirectRequest({ path })),
    getApplicationUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
    showEditIdentityProcessingInfoModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_IDENTITY_PROCESSING_INFO_MODAL, modalProps })),
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({
      modalType: VIEW_API_RESPONSE_MODAL,
      modalProps,
    })),
  }
}


class IdentityInfoC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationUnderwritingProfile,
    } = this.props

    if (applicationId) {
      getApplicationUnderwritingProfile({
        credentials,
        queries: {
          linked_to: applicationId,
        },
      })
    }

    this.fetchAssociatedIdentities()
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      identityId,
      applicationId,
      getApplicationUnderwritingProfile,
    } = this.props

    const {
      identityId: prevIdentityId,
      applicationId: prevApplicationId,
    } = prevProps

    if (identityId && !isEqual(identityId, prevIdentityId)) {
      this.fetchAssociatedIdentities()
    }

    if (applicationId && !isEqual(applicationId, prevApplicationId)) {
      getApplicationUnderwritingProfile({
        credentials,
        queries: {
          linked_to: applicationId,
        },
      })
    }
  }

  fetchAssociatedIdentities = () => {
    const {
      credentials,
      identityId,
      getAssociatedIdentities,
    } = this.props

    if (identityId) getAssociatedIdentities({ identityId, credentials })
  }

  render() {
    const {
      credentials,
      associatedIdentities,
      credentialId,
      editAssociatedIdentities,
      showEditIdentityProcessingInfoModal,
      applicationUnderwritingProfile,
      isFlex,
      identity,
      isRolePlatformCredential,
      showAPIResponseModal,
    } = this.props

    const ownersActions = map(associatedIdentities, (associatedIdentity) => {
      const identityId = get(associatedIdentity, 'id')
      const path = EDIT_ASSOCIATED_IDENTITY_PATH({ credentialId, identityId })

      return [{
        label: 'Edit',
        action: () => editAssociatedIdentities(path),
        icon: `fal fa-${EDIT_ICON}`,
        className: 'large edit-owner-button',
      }]
    })

    const processingSectionActions = [
      {
        label: EDIT,
        buttonClassName: 'edit-identity-processing-info-button',
        action: () => showEditIdentityProcessingInfoModal({ credentials, identity, applicationUnderwritingProfile }),
        condition: isRolePlatformCredential && hasPermissions([EDIT_IDENTITY_PERMISSION]) && isFlex,
      },
    ]

    const responseModalProps = () => {
      const host = get(credentials, 'host')
      const identityId = get(identity, 'id')
      const identityAPIDocs = [
        {
          label: IDENTITIES_API_REFERENCE,
          link: IDENTITIES_API_DOCS_LINK,
        },
      ]

      showAPIResponseModal({
        api: getIdentityAPI,
        apiProps: {
          id: identityId,
          credentials,
        },
        apiMethod: 'GET',
        apiRoute: `${host}/${IDENTITY({ identityId })}`,
        apiDocuments: identityAPIDocs,
      })
    }

    return (
      <IdentityInfo
        {...this.props}
        ownersActions={ownersActions}
        showResponseModal={responseModalProps}
        processingSectionActions={processingSectionActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityInfoC)
