import getMany from 'utilities/get/getMany'
import { parseAmount } from 'constants/normalizationConstants'

import {
  checkboxGroupFieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

import {
  MAX_CARD_TRANSACTION_AMOUNT,
  MAX_ACH_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const validateCreateOnboardingFormDetailsForm = (values, props) => {
  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileMaxACHTransactionAmount,
    displayUnderwritingProfileMaxTransactionAmount,
    displayUnderwritingProfileMaxACHTransactionAmount,
    isCanadianOnboardingForm,
  ] = getMany(props, [
    'underwritingProfileMaxTransactionAmount',
    'underwritingProfileMaxACHTransactionAmount',
    'displayUnderwritingProfileMaxTransactionAmount',
    'displayUnderwritingProfileMaxACHTransactionAmount',
    'isCanadianOnboardingForm',
  ])

  const {
    maxTransactionAmount,
    maxACHTransactionAmount,
    paymentProcessors,
  } = values


  return {
    maxTransactionAmount: nonEmptyNumber({ field: maxTransactionAmount, name: MAX_CARD_TRANSACTION_AMOUNT })
      || (underwritingProfileMaxTransactionAmount && (parseAmount(maxTransactionAmount) > parseAmount(underwritingProfileMaxTransactionAmount)) && `Value exceeds the Application's max amount of ${displayUnderwritingProfileMaxTransactionAmount}.`),
    maxACHTransactionAmount: isCanadianOnboardingForm ? false : nonEmptyNumber({ field: maxACHTransactionAmount, name: MAX_ACH_TRANSACTION_AMOUNT })
      || ((underwritingProfileMaxACHTransactionAmount && parseAmount(maxACHTransactionAmount) > parseAmount(underwritingProfileMaxACHTransactionAmount)) && `Value exceeds the Application's max amount of ${displayUnderwritingProfileMaxACHTransactionAmount}.`),
    paymentProcessors: checkboxGroupFieldEmpty({ field: paymentProcessors }),
  }
}

export default validateCreateOnboardingFormDetailsForm
