import frontendSubscriptionPlanModel from 'utilities/create/models/frontendSubscriptionPlanModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSubscriptionPlansModel = ({ data: subscriptionPlans }) => {
  const subscriptionsModel = map(subscriptionPlans, (subscriptionPlan) => frontendSubscriptionPlanModel({ data: subscriptionPlan }))

  return keyBy(subscriptionsModel, 'id')
}

export default frontendSubscriptionPlansModel
