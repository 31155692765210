import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { MERCHANT_FEES } from 'constants/linkConstants'
import { FEES_ICON } from 'constants/iconConstants'
import { GET_MERCHANT_FEES } from 'constants/flowConstants'
import { getMerchantFeesSelector } from 'state-layer/selectors'

const MerchantFees = ({
  feePath = '',
  isFetching = false,
  initialQueries,
  merchantId,
  allowedFilters,
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='MerchantFees'>
      <TableC
        allowedFilters={allowedFilters}
        columnDescriptors={columnDescriptors}
        flow={GET_MERCHANT_FEES}
        linksKey={MERCHANT_FEES}
        selector={getMerchantFeesSelector}
        selectorId={merchantId}
        path={feePath}
        isFetching={isFetching}
        initialQueries={initialQueries}
        icon={`fa fa-${FEES_ICON}`}
        fileNameCSV='Merchant_Fees'
        emptyMessage='No Fees Available'
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

MerchantFees.propTypes = {
  feePath: PropTypes.string,
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  merchantId: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default MerchantFees
