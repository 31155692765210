export const BX = 'BX'
export const CS = 'CS'
export const CTN = 'CTN'
export const DZ = 'DZ'
export const EA = 'EA'
export const GL = 'GL'
export const LB = 'LB'
export const LT = 'LT'
export const M = 'M'
export const M3 = 'M3'
export const KG = 'KG'
export const HR = 'HR'
export const PK = 'PK'
export const PR = 'PR'
export const RL = 'RL'
export const SET = 'SET'
export const TN = 'TN'
export const SF = 'SF'
export const SHT = 'SHT'

export const UNIT_OF_MEASURE_OPTIONS = [
  { label: 'Box (BX)', value: BX },
  { label: 'Case (CS)', value: CS },
  { label: 'Count (CTN)', value: CTN },
  { label: 'Dozen (DZ)', value: DZ },
  { label: 'Each (EA)', value: EA },
  { label: 'Gallon (GL)', value: GL },
  { label: 'Pound (LB)', value: LB },
  { label: 'Liter (LT)', value: LT },
  { label: 'Meter (M)', value: M },
  { label: 'Cubic Meter (M3)', value: M3 },
  { label: 'Kilogram (KG)', value: KG },
  { label: 'Hour (HR)', value: HR },
  { label: 'Packet (PK)', value: PK },
  { label: 'Pair (PR)', value: PR },
  { label: 'Roll (RL)', value: RL },
  { label: 'Set (SET)', value: SET },
  { label: 'Ton (TN)', value: TN },
  { label: 'Square Foot (SF)', value: SF },
  { label: 'Sheet (SHT)', value: SHT },
]
