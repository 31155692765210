import nextFlowStep from 'utilities/nextFlowStep'
import getMany from 'utilities/get/getMany'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_3 } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  PATCH_ACCESS_FORM_F_REQUEST,
  PATCH_GUEST_ACCESS_FORM_F_REQUEST,
} from 'constants/flowConstants'

const submitLiveAccountApplicationOwnershipInfoForm = (values, dispatch, props) => {
  const [
    controlPerson,
    beneficialOwners,
  ] = getMany(values, [
    'controlPerson',
    'beneficialOwners',
  ])

  const accessFormId = get(props, 'accessFormId')
  const dashboardType = get(props, 'dashboardType')
  const isHostedAccessForm = get(props, 'isHostedAccessForm', false)

  dispatch({
    type: isHostedAccessForm ? PATCH_GUEST_ACCESS_FORM_F_REQUEST : PATCH_ACCESS_FORM_F_REQUEST,
    payload: {
      values: {
        controlPerson,
        beneficialOwners: isEmpty(beneficialOwners) ? [] : beneficialOwners,
      },
      id: accessFormId,
    },
    meta: {
      successCallback: nextFlowStep,
      actionId: sendAmplitudeActionEvent(SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_3, {
        accessFormId,
        dashboardType,
      }),
    },
  })
}

export default submitLiveAccountApplicationOwnershipInfoForm
