import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  DISPUTE,
  DISPUTES,
} from 'constants/apiConstants'

const patchDisputeAPI = ({ values, credentials, meta, id }) => {
  const path = DISPUTE({ disputeId: get(values, 'id', id) })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: DISPUTES,
  })
}

export default patchDisputeAPI
