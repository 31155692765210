import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddRecipientForm from './AddRecipientForm'
import { getUnderwritingProfilesSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getMany from 'utilities/get/getMany'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import { ADD_NEW_RECIPIENT_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'
import get from 'lodash/get'
import values from 'lodash/values'

import {
  BUSINESS_RECIPIENT,
  PERSONAL_RECIPIENT,
} from 'constants/language/languageConstants'

import {
  change,
  formValueSelector,
} from 'redux-form'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const applicationUnderwritingProfile = get(values(getUnderwritingProfilesSelector(state)), '[0]', {})
  const credentialId = get(credentials, 'id')
  const formSelector = formValueSelector(ADD_NEW_RECIPIENT_FORM)
  const recipientType = formSelector(state, 'type')

  const [
    businessRecipientsEnabled,
    personalRecipientsEnabled,
  ] = getMany(applicationUnderwritingProfile, [
    'businessRecipientsEnabled',
    'personalRecipientsEnabled',
  ])

  const disableTypeField = (businessRecipientsEnabled || personalRecipientsEnabled) ? !(businessRecipientsEnabled && personalRecipientsEnabled) : false

  // payouts are only available in the US currently
  const initialValues = {
    paymentInstrument: {
      country: USA,
    },
    personalAddress: {
      country: USA,
    },
    businessAddress: {
      country: USA,
    },
  }

  return {
    credentials,
    credentialId,
    showBusinessInformation: get(recipientType, 'value') === 'business',
    initialValues,
    applicationId,
    businessRecipientsEnabled,
    personalRecipientsEnabled,
    disableTypeField,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
    setRecipientDetailValue: ({ businessRecipientsEnabled }) => dispatch(change(ADD_NEW_RECIPIENT_FORM, 'type', businessRecipientsEnabled ? { value: 'business', label: BUSINESS_RECIPIENT } : { value: 'personal', label: PERSONAL_RECIPIENT })),
  }
}

class AddRecipientFormC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getApplicationUnderwritingProfile,
      disableTypeField,
      setRecipientDetailValue,
      businessRecipientsEnabled,
    } = this.props

    if (applicationId) {
      getApplicationUnderwritingProfile({
        credentials,
        queries: {
          linked_to: applicationId,
        },
      })

      if (disableTypeField) {
        setRecipientDetailValue({ businessRecipientsEnabled })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId,
      credentials,
      getApplicationUnderwritingProfile,
      businessRecipientsEnabled,
      personalRecipientsEnabled,
      disableTypeField,
      setRecipientDetailValue,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationUnderwritingProfile({
        credentials,
        queries: {
          linked_to: applicationId,
        },
      })
    }

    if (disableTypeField && disableTypeField !== prevProps.disableTypeField) {
      setRecipientDetailValue({ businessRecipientsEnabled })
    }
  }

  render() {
    return (
      <AddRecipientForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRecipientFormC)
