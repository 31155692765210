import './ActionButtonsDropdownS.scss'
import React from 'react'
import { CHEVRON_DOWN_ICON } from 'constants/iconConstants'
import map from 'lodash/map'

const ActionButtonsDropdown = ({
  className = '',
  handleCloseDropdowns,
  toggleShowActionsDropdown,
  showActionsDropdown,
  label,
  actionList,
  disabledActions = false,
}) => {
  return (
    <div className={`${className}  header-action-button-dropdown`}>
      <div className='button-container' onClick={!disabledActions ? (e) => toggleShowActionsDropdown(e, label) : () => {}}>
        {label} <i className={`fa fa-${CHEVRON_DOWN_ICON}`} />

        { showActionsDropdown && (
        <ul className='action-buttons'>
          {
            map(actionList, ({ label: actionLabel, actionFunc, condition: actionCondition = true }) => {
              return actionCondition ? (
                <li className='action-buttons-list'>
                  <div
                    className='item flex column'
                    onClick={!disabledActions ? () => {
                      actionFunc()
                      handleCloseDropdowns()
                    } : () => {}}
                  >
                    <span>{actionLabel}</span>
                  </div>
                </li>
              ) : null
            })
          }
        </ul>
        )}
      </div>
    </div>
  )
}

export default ActionButtonsDropdown
