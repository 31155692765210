import removeUndefined from 'utilities/remove/removeUndefined'
import { USD } from 'constants/currencyConstants'
import { ACH } from 'constants/language/languageConstants'

const backendBalanceAdjustmentModel = ({ values }) => {
  const {
    amount,
    instrumentId,
    description,
    processor,
  } = values

  return removeUndefined({
    amount,
    currency: USD,
    instrument_id: instrumentId,
    rail: ACH,
    description,
    processor,
  })
}

export default backendBalanceAdjustmentModel
