import React, { Component } from 'react'
import { connect } from 'react-redux'
import APILogs from './APILogs'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { API_LOGS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  API_LOGS_DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  REQUEST_ID,
  REQUESTING_USER,
  HTTP_STATUS_CODES,
  HTTP_METHODS,
  HTTP_LOG_ENTITY_ID,
} from 'constants/filterConstants'

const allowedFilters = [
  REQUEST_ID,
  REQUESTING_USER,
  HTTP_STATUS_CODES,
  HTTP_METHODS,
  HTTP_LOG_ENTITY_ID,
]

const quickFilters = [
  API_LOGS_DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `apiLogsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const apiLogsPath = API_LOGS_PATH({ credentialId })

  return {
    allowedFilters,
    isFetching,
    apiLogsPath,
    quickFilters,
  }
}

class APILogsC extends Component {
  render() {
    return (
      <APILogs {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(APILogsC)
