import paymentsAPI from 'utilities/api/paymentsAPI'
import { WEBHOOK_LOGS } from 'constants/apiConstants'

const getWebhookLogsAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    queries,
    path: WEBHOOK_LOGS,
    service: WEBHOOK_LOGS,
  })
}

export default getWebhookLogsAPI
