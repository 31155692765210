/* eslint-disable no-underscore-dangle */
import MainApp from './MainApp'
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import getFormattedPath from 'utilities/get/getFormattedPath'
import isAdministrator from 'utilities/is/isAdministrator'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import getCurrentUserRequest from 'utilities/actions/get/getCurrentUserRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import getGuestDashboardCustomizationsRequest from 'utilities/actions/get/getGuestDashboardCustomizationsRequest'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import showModalAction from 'utilities/actions/showModalAction'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors'
import { ANON_KEY } from 'constants/launchDarklyConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import { PATHNAME } from 'constants/queryConstants'
import { CREDENTIALS } from 'constants/linkConstants'
import { YOUR_ORGANIZATION_REQUIRES_MFA_FOR_YOUR_ACCOUNT } from 'constants/language/languageConstants'
import head from 'lodash/head'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  FETCHING,
  LOADED,
} from 'constants/reducerConstants'

import {
  WELCOME_NAME_CAPTURE_MODAL,
  DASHBOARD_TYPE_MODAL,
  SETUP_MFA_MODAL,
} from 'constants/modalConstants'

import {
  roleToEntityIdMap,
  roleToEntityTypeMap,
} from 'constants/memberConstants'

import {
  DASHBOARD,
  ADMINISTRATOR,
  UNPROTECTED_PATHS,
  UNPROTECTED_PUBLIC_PATHS,
} from 'constants/pathConstants'

import {
  requestF,
  GET_CREDENTIAL,
  GET_CREDENTIALS,
  GET_APP_CONFIGURATION,
  UPDATE_FEATURE_FLAGS,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const auth = get(props, 'auth')
  const credentialId = get(props, 'params.credentialId')
  const pathname = get(state, PATHNAME)
  const topLevelPath = head(pathname.split('/', 1))
  const isLoggedIn = localStorage.getItem('isLoggedIn') || 'false'
  const formattedPath = getFormattedPath(state, props)
  const isAdmin = isAdministrator(state)
  const inDashboard = topLevelPath === DASHBOARD || !topLevelPath
  const showNav = (inDashboard && credentialId) || (topLevelPath === ADMINISTRATOR && isAdmin)
  const hideNav = !showNav
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `credentialsR.${FETCHING}`)
  const showPreviewPanel = get(state, 'panelR.show')
  const currentUser = getCurrentUser(state)
  const currentUserEmail = get(currentUser, 'email')
  const fullName = get(currentUser, 'fullName')
  const dashboardUserId = get(credentials, 'userId')
  const authRole = get(credentials, 'role')
  const currentPlatform = getCurrentPlatform(state)
  const isProtectedPath = !UNPROTECTED_PATHS.includes(pathname)
  const isUnprotectedPath = UNPROTECTED_PUBLIC_PATHS.includes(pathname)
  const isRootPath = pathname === '/'
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const entityType = roleToEntityTypeMap[authRole]
  const entityIdKey = roleToEntityIdMap[authRole]
  const entityId = get(currentUser, entityIdKey)
  const dashboardConfiguration = getDashboardConfigByEntityTypeAndId(state, entityType, entityId)
  const dashboardType = get(dashboardConfiguration, 'dashboardType')
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)
  const featureFlagsLoaded = get(state, `featureFlagsR.${LOADED}`, false)
  const isFetchingDashboardUser = get(state, `usersR.${FETCHING}`, false)

  return {
    auth,
    credentialId,
    hideNav,
    isLoggedIn: JSON.parse(isLoggedIn),
    formattedPath,
    isAdmin,
    showNav,
    credentials,
    isFetching,
    currentUser,
    currentUserEmail,
    isProtectedPath,
    isUnprotectedPath,
    currentPlatform,
    isRootPath,
    showPreviewPanel,
    fullName,
    dashboardUserId,
    isFetchingConfiguration,
    dashboardType,
    isFetchingCurrentUser,
    dashboardConfiguration,
    isLoading: isFetching || isFetchingCurrentUser || isFetchingDashboardUser || !featureFlagsLoaded,
  }
}

class MainAppC extends Component {
  componentDidMount() {
    const {
      auth,
      isLoggedIn,
      credentialId,
      dispatch,
    } = this.props

    if (isLoggedIn) {
      auth.isAuthenticated()

      const dashboardId = getCurrentDashboardId()
      const credentialRequest = isDashboardServiceV2() ? GET_CREDENTIAL : GET_CREDENTIALS

      dispatch({
        type: requestF(GET_APP_CONFIGURATION),
        payload: {
          id: dashboardId,
        },
        meta: {
          showErrors: false,
        },
      })

      if (credentialId) {
        dispatch({
          type: requestF(credentialRequest),
          payload: {
            id: credentialId,
            linksKey: CREDENTIALS,
            values: {
              dashboardId,
            },
          },
          meta: {
            showErrors: false,
          },
        })
      }

      dispatch(getGuestDashboardCustomizationsRequest({
        queries: {
          sub_domain_name: getCurrentPlatformName(),
        },
      }))
    }
  }

  componentDidUpdate(prevProps) {
    const {
      flags: prevFlags,
      credentials: prevCredentials,
      ldClient: prevLDClient,
      currentUser: prevCurrentUser,
      isFetchingConfiguration: prevIsFetchingConfiguration,
      dashboardConfiguration: prevDashboardConfiguration,
    } = prevProps

    const {
      auth,
      dispatch,
      isFetching,
      credentials,
      credentialId,
      currentUserEmail,
      flags: nextFlags,
      ldClient,
      currentUser,
      currentPlatform,
      dashboardUserId,
      isFetchingConfiguration,
      dashboardType,
      isFetchingCurrentUser,
      dashboardConfiguration,
      isLoggedIn,
    } = this.props

    const platformName = get(currentPlatform, 'name')

    const [
      prevCredentialId,
      prevEnvironment,
      prevRole,
    ] = getMany(prevCredentials, [
      'id',
      'environment',
      'role',
    ])

    const [
      nextCredentialId,
      nextEnvironment,
      nextRole,
    ] = getMany(credentials, [
      'id',
      'environment',
      'role',
    ])

    const [
      prevPlatformId,
      prevApplicationId,
      prevIdentityId,
      prevEmail,
      prevFullName,
    ] = getMany(prevCurrentUser, [
      'platformId',
      'applicationId',
      'identityId',
      'userEmail',
      'fullName',
    ])

    const [
      nextPlatformId,
      nextApplicationId,
      nextIdentityId,
      nextEmail,
      nextFullName,
    ] = getMany(currentUser, [
      'platformId',
      'applicationId',
      'identityId',
      'userEmail',
      'fullName',
    ])

    const prevConfigurationId = get(prevDashboardConfiguration, 'id')
    const configurationId = get(dashboardConfiguration, 'id')

    const platformChanged = prevPlatformId !== nextPlatformId
    const applicationChanged = prevApplicationId !== nextApplicationId
    const identityChanged = prevIdentityId !== nextIdentityId
    const environmentChanged = prevEnvironment !== nextEnvironment
    const roleChanged = prevRole !== nextRole
    const configurationChanged = prevConfigurationId !== configurationId

    const userDataChanged = platformChanged || applicationChanged || identityChanged || environmentChanged || roleChanged || configurationChanged

    if (!isEqual(prevFlags, nextFlags)) {
      dispatch({
        type: UPDATE_FEATURE_FLAGS,
        payload: nextFlags,
      })
    }

    const sub = get(currentUser, 'sub')
    const userId = get(currentUser, 'user_id')
    const email = get(currentUser, 'email')
    const prevIsSelfServiceSandboxUser = get(prevCurrentUser, 'isSelfServiceSandboxUser', false)
    const isSelfServiceSandboxUser = get(currentUser, 'isSelfServiceSandboxUser', false)
    const auth0ID = sub || userId || ANON_KEY
    const username = get(credentials, 'username')
    const userRole = get(credentials, 'role')
    const environment = get(credentials, 'environment')
    const userCreatedAt = get(currentUser, 'created_at')
    const formattedUserCreatedAt = formatDate({ date: userCreatedAt, format: YYYY_MM_DD })

    if (ldClient && userDataChanged) {
      const newUser = removeUndefined({
        key: auth0ID,
        email,
        custom: {
          platform: platformName,
          platformId: nextPlatformId,
          applicationId: nextApplicationId,
          identityId: nextIdentityId,
          environment: nextEnvironment,
          role: nextRole,
          dashboardType,
        },
      })

      ldClient.identify(newUser)
    }

    if (userDataChanged && window.Appcues) {
      window.Appcues.identify(
        auth0ID,
        {
          finixuserid: username,
          finixcredentialid: nextCredentialId,
          role: userRole,
          application: nextApplicationId,
          platform: nextPlatformId,
          identity: nextIdentityId,
          env: environment,
          createdAt: formattedUserCreatedAt,
          isSelfServiceSandboxUser,
        },
      )
    }

    if (window._hsq && userDataChanged) {
      window._hsq.push(['identify', {
        email,
        id: auth0ID,
      }])
    }

    if (localStorage.getItem('isLoggedIn') === 'true') {
      auth.isAuthenticated()
    }

    if ((prevEmail !== nextEmail) && !prevFullName && !nextFullName && dashboardUserId) {
      dispatch(showModalAction({
        modalType: WELCOME_NAME_CAPTURE_MODAL,
        modalProps: { dashboardUserId, canCloseModal: false },
      }))
    }

    if ((prevIsSelfServiceSandboxUser === false && isSelfServiceSandboxUser === true) && !dashboardType && isLoggedIn) {
      dispatch(showModalAction({
        modalType: DASHBOARD_TYPE_MODAL,
        modalProps: { canCloseModal: false },
        className: 'yellow-border',
        overlayClassName: 'light-blue-opaque',
      }))
    }

    if (nextCredentialId && (prevCredentialId !== nextCredentialId)) {
      dispatch(getCurrentUserRequest({
        credentials,
        currentUserEmail,
        successCallback: ({ newValues = {} }) => {
          const {
            mfaEnabled,
            mfaRequired,
          } = newValues

          if (!mfaEnabled && mfaRequired) {
            dispatch(showModalAction({
              modalType: SETUP_MFA_MODAL,
              modalProps: {
                canCloseModal: false,
                description: YOUR_ORGANIZATION_REQUIRES_MFA_FOR_YOUR_ACCOUNT,
              },
              className: 'modal-md no-pad',
            }))
          }
        },
      }))
      dispatch(clearReducerRequest(['identitiesR', 'merchantsR', 'applicationsR', 'paymentInstrumentsR']))
    }
  }

  render () {
    return (
      <MainApp
        {...this.props}
      />
    )
  }
}

export default withLDConsumer()(connect(mapStateToProps)(MainAppC))
