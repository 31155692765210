import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import assign from 'lodash/assign'
import gt from 'lodash/gt'
import isNumber from 'lodash/isNumber'
import { USD } from 'constants/currencyConstants'

const validateAchAndAdditionalRulesForm = (values, props) => {
  const validations = map(values, (currentValues, key) => {
    const [
      achBasisPointsMin,
      achBasisPointsMax,
      achFixedFeeMin,
      achFixedFeeMax,
      achDebitReturnFixedFeeMin,
      achDebitReturnFixedFeeMax,
      achCreditReturnFixedFeeMin,
      achCreditReturnFixedFeeMax,
      ancillaryFixedFeePrimaryMin,
      ancillaryFixedFeePrimaryMax,
      ancillaryFixedFeeSecondaryMin,
      ancillaryFixedFeeSecondaryMax,
    ] = getMany(currentValues, [
      'achBasisPointsMin',
      'achBasisPointsMax',
      'achFixedFeeMin',
      'achFixedFeeMax',
      'achDebitReturnFixedFeeMin',
      'achDebitReturnFixedFeeMax',
      'achCreditReturnFixedFeeMin',
      'achCreditReturnFixedFeeMax',
      'ancillaryFixedFeePrimaryMin',
      'ancillaryFixedFeePrimaryMax',
      'ancillaryFixedFeeSecondaryMin',
      'ancillaryFixedFeeSecondaryMax',
    ])

    return {
      [key]: {
        achBasisPointsMin: key === 'USD' ? isNumber(achBasisPointsMin) || !!achBasisPointsMin ? false : 'Cannot be empty' : false,
        achBasisPointsMax: (gt(parseFloat(achBasisPointsMin), parseFloat(achBasisPointsMax)) ? 'Max should be larger than Min' : false) || (key === 'USD' ? isNumber(achBasisPointsMax) || !!achBasisPointsMax ? false : 'Cannot be empty' : false),
        achFixedFeeMin: key === 'USD' ? isNumber(achFixedFeeMin) || !!achFixedFeeMin ? false : 'Cannot be empty' : false,
        achFixedFeeMax: (gt(parseFloat(achFixedFeeMin), parseFloat(achFixedFeeMax)) ? 'Max should be larger than Min' : false) || (key === 'USD' ? isNumber(achFixedFeeMax) || !!achFixedFeeMax ? false : 'Cannot be empty' : false),
        achDebitReturnFixedFeeMin: false,
        achDebitReturnFixedFeeMax: gt(parseFloat(achDebitReturnFixedFeeMin), parseFloat(achDebitReturnFixedFeeMax)) ? 'Max should be larger than Min' : false,
        achCreditReturnFixedFeeMin: false,
        achCreditReturnFixedFeeMax: gt(parseFloat(achCreditReturnFixedFeeMin), parseFloat(achCreditReturnFixedFeeMax)) ? 'Max should be larger than Min' : false,
        ancillaryFixedFeePrimaryMin: false,
        ancillaryFixedFeePrimaryMax: gt(parseFloat(ancillaryFixedFeePrimaryMin), parseFloat(ancillaryFixedFeePrimaryMax)) ? 'Max should be larger than Min' : false,
        ancillaryFixedFeeSecondaryMin: false,
        ancillaryFixedFeeSecondaryMax: gt(parseFloat(ancillaryFixedFeeSecondaryMin), parseFloat(ancillaryFixedFeeSecondaryMax)) ? 'Max should be larger than Min' : false,
      },
    }
  })

  return assign({}, ...validations)
}

export default validateAchAndAdditionalRulesForm
