import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardOverview from './DashboardOverview'
import { FETCHING } from 'constants/reducerConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'

import {
  getApplicationOldestAccessForm,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const isFetching = get(state, `chartsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const isLiveEnvironment = get(credentials, 'environment') === LIVE_ENVIRONMENT
  const isSelfServiceSandboxUser = get(currentUser, 'isSelfServiceSandboxUser')
  const applicationOldestAccessForm = getApplicationOldestAccessForm(state)
  const accessFormSubmissionDate = get(applicationOldestAccessForm, 'submittedAt')
  const isAccessFormCompleted = !!accessFormSubmissionDate
  // TODO: make a utility for this
  const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')
  const isRoleAdministrator = dashboardUserRoleName === 'Administrator'

  return {
    isFetching,
    credentials,
    isLiveEnvironment,
    isSelfServiceSandboxUser,
    isAccessFormCompleted,
    isRoleAdministrator,
  }
}

class DashboardOverviewC extends Component {
  render() {
    return (
      <DashboardOverview
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DashboardOverviewC)
