import './DisputeSubmitEvidenceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import submitDisputeEvidenceForm from 'utilities/submit/submitDisputeEvidenceForm'
import { ALERT_ICON } from 'constants/iconConstants'
import { SUBMIT_EVIDENCE_FORM } from 'constants/formConstants'
import lowerCase from 'lodash/lowerCase'

import {
  REPRESENTMENT_AMOUNT,
  SUBMIT_EVIDENCE_FORM as SUBMIT_EVIDENCE_FORM_TITLE,
  SUMMARY_OF_EVIDENCE_FOR_ISSUER,
  SUBMIT_EVIDENCE_FOR_DISPUTE_WARNING,
  RECOMMENDED,
  SUBMIT_EVIDENCE,
  RESPONSE_TO_ISSUER,
  DISPUTE_REPRESENTMENT_AMOUNT_TOOLTIP_MSG,
  SUMMARY_OF_EVIDENCE_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const DisputeSubmitEvidenceForm = ({
  handleSubmit = () => {},
}) => {
  const responseToIssuerTooltipMsg = (
    <div className='response-to-issuer-tooltip-msg'>
      <div className='tooltip-title'>{RESPONSE_TO_ISSUER}</div>
      <div className='tooltip-msg'>{SUMMARY_OF_EVIDENCE_TOOLTIP_MSG}</div>
    </div>
  )

  return (
    <form className='DisputeSubmitEvidenceForm' onSubmit={handleSubmit(submitDisputeEvidenceForm)}>
      <h6 className='title'>{SUBMIT_EVIDENCE_FORM_TITLE}</h6>

      {/* TODO: comment out until BE supports */}
      {/* <AmountField */}
      {/*   name='representmentAmount' */}
      {/*   label={REPRESENTMENT_AMOUNT} */}
      {/*   tooltip={DISPUTE_REPRESENTMENT_AMOUNT_TOOLTIP_MSG} */}
      {/* /> */}

      <Field
        name='summary'
        label={<div className='flex items-center'>{SUMMARY_OF_EVIDENCE_FOR_ISSUER} <span className='recommended-string'>({lowerCase(RECOMMENDED)})</span></div>}
        component={TextAreaField}
        tooltip={responseToIssuerTooltipMsg}
      />

      <div className='submit-evidence-warning flex items-center'>
        <i className={`fa fa-${ALERT_ICON}`} />
        <div>{SUBMIT_EVIDENCE_FOR_DISPUTE_WARNING}</div>
      </div>

      <Button type='submit' label={SUBMIT_EVIDENCE} />
    </form>
  )
}

DisputeSubmitEvidenceForm.propTypes = {
  handleSubmit: PropTypes.func,
}

export default reduxForm({ form: SUBMIT_EVIDENCE_FORM })(DisputeSubmitEvidenceForm)
