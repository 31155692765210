import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import PropTypes from 'prop-types'
import SwitchDashboardTypeFormC from 'components/Customer/Forms/SwitchDashboardTypeForm/SwitchDashboardTypeFormC'
import isEmpty from 'lodash/isEmpty'

import {
  SET_CUSTOMER_PROFILE_TYPE,
  SWITCH_CUSTOMER_PROFILE_TYPE,
} from 'constants/language/languageConstants'

const SwitchDashboardTypeModal = ({
  credentials,
  dashboardConfigurationId = '',
  displayBusinessName = '',
  entityType = '',
  entityId = '',
  applicationDashboardType = '',
}) => {
  return (
    <GenericModal
      title={isEmpty(applicationDashboardType) ? SET_CUSTOMER_PROFILE_TYPE : SWITCH_CUSTOMER_PROFILE_TYPE}
      className='SwitchDashboardTypeModal'
      Component={SwitchDashboardTypeFormC}
      credentials={credentials}
      dashboardConfigurationId={dashboardConfigurationId}
      displayBusinessName={displayBusinessName}
      entityType={entityType}
      entityId={entityId}
      applicationDashboardType={applicationDashboardType}
    />
  )
}

SwitchDashboardTypeModal.propTypes = {
  credentials: PropTypes.object,
  dashboardConfigurationId: PropTypes.string,
  displayBusinessName: PropTypes.string,
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  applicationDashboardType: PropTypes.string,
}

export default SwitchDashboardTypeModal
