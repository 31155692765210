import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM_TEMPLATES } from 'constants/apiConstants'

const getComplianceFormTemplatesAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: COMPLIANCE_FORM_TEMPLATES,
    queries,
    meta,
  })
}

export default getComplianceFormTemplatesAPI
