import selectOption from 'utilities/forms/selectOption'
import upperCase from 'lodash/upperCase'
import { SELECT } from 'constants/language/languageConstants'

export const DATE_FORMAT = 'MMMM D, YYYY'
export const DATE_SHORT_FORMAT = 'MMM D, YYYY'
export const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A'
export const DATE_TIME_FULL_FORMAT = 'MMMM Do, YYYY h:mm A'
export const TIME_FORMAT = 'h:mm A'
export const MM_DD_YYYY = 'MM/DD/YYYY'
export const M_D_YYYY = 'M/D/YYYY'
export const M_DD_YYYY = 'M/DD/YYYY'
export const CREATED_AT_DEFAULT_FORMAT = 'M/DD/YYYY h:mm A'
export const DATE_PICKER_DATE_FORMAT = 'MM/dd/yyyy'
export const DATE_PICKER_TIME_FORMAT = 'MM/dd/yyyy hh:mm aa'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const MONTH_YEAR_FORMAT = 'MMM YYYY'
export const DAY_FORMAT = 'MMM D'
export const MM_DD_YYYY_FILE_NAME = 'MM-DD-YYYY-hh_mm_ss'
export const ISO_8601_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss'

// display constants - used site-wide for display purposes
export const DISPLAY_DATE = DATE_TIME_FORMAT
export const DISPLAY_TIME = DATE_TIME_FULL_FORMAT

export const DAILY = 'DAILY'
export const MONTHLY = 'MONTHLY'

export const MONDAY = 'Monday'
export const TUESDAY = 'Tuesday'
export const WEDNESDAY = 'Wednesday'
export const THURSDAY = 'Thursday'
export const FRIDAY = 'Friday'
export const SATURDAY = 'Saturday'
export const SUNDAY = 'Sunday'


export const AM = 'AM'
export const PM = 'PM'

export const DAY_MAPPING = {
  1: MONDAY,
  2: TUESDAY,
  3: WEDNESDAY,
  4: THURSDAY,
  5: FRIDAY,
  6: SATURDAY,
  7: SUNDAY,
}

// time zone labels
export const AMERICA_NY_TIMEZONE = 'America / New York'
export const AMERICA_AT_TIMEZONE = 'Alaska Time (AT) - Anchorage'
export const AMERICA_CT_TIMEZONE = 'Central Time (CT) - Chicago'
export const AMERICA_ET_TIMEZONE = 'Eastern Time (ET) - New York'
export const AMERICA_HT_TIMEZONE = 'Hawaii Time (AT) - Honolulu'
export const AMERICA_MT_TIMEZONE = 'Mountain Time (MT) - Denver'
export const AMERICA_PT_TIMEZONE = 'Pacific Time (PT) - Los Angeles'

// BE time zone enums
export const AMERICA_NY_TIMEZONE_BE_FIELD = 'America/New_York'
export const AMERICA_AT_TIMEZONE_BE_FIELD = 'America/Anchorage'
export const AMERICA_CT_TIMEZONE_BE_FIELD = 'America/Chicago'
export const AMERICA_ET_TIMEZONE_BE_FIELD = 'America/New_York'
export const AMERICA_HT_TIMEZONE_BE_FIELD = 'Pacific/Honolulu'
export const AMERICA_MT_TIMEZONE_BE_FIELD = 'America/Denver'
export const AMERICA_PT_TIMEZONE_BE_FIELD = 'America/Los_Angeles'

export const TIME_ZONE_TO_DISPLAY_MAP = {
  [AMERICA_AT_TIMEZONE_BE_FIELD]: AMERICA_AT_TIMEZONE,
  [AMERICA_CT_TIMEZONE_BE_FIELD]: AMERICA_CT_TIMEZONE,
  [AMERICA_ET_TIMEZONE_BE_FIELD]: AMERICA_ET_TIMEZONE,
  [AMERICA_HT_TIMEZONE_BE_FIELD]: AMERICA_HT_TIMEZONE,
  [AMERICA_MT_TIMEZONE_BE_FIELD]: AMERICA_MT_TIMEZONE,
  [AMERICA_PT_TIMEZONE_BE_FIELD]: AMERICA_PT_TIMEZONE,
}

export const TIME_ZONE_TO_SHORT_DISPLAY_MAP = {
  [AMERICA_AT_TIMEZONE_BE_FIELD]: 'AT',
  [AMERICA_CT_TIMEZONE_BE_FIELD]: 'CT',
  [AMERICA_ET_TIMEZONE_BE_FIELD]: 'ET',
  [AMERICA_HT_TIMEZONE_BE_FIELD]: 'HT',
  [AMERICA_MT_TIMEZONE_BE_FIELD]: 'MT',
  [AMERICA_PT_TIMEZONE_BE_FIELD]: 'PT',
}

export const timeZoneOptions = [
  selectOption(AMERICA_NY_TIMEZONE, AMERICA_NY_TIMEZONE_BE_FIELD)
]

export const settlementsTimeZoneOptions = [
  selectOption(AMERICA_AT_TIMEZONE, AMERICA_AT_TIMEZONE_BE_FIELD),
  selectOption(AMERICA_CT_TIMEZONE, AMERICA_CT_TIMEZONE_BE_FIELD),
  selectOption(AMERICA_ET_TIMEZONE, AMERICA_ET_TIMEZONE_BE_FIELD),
  selectOption(AMERICA_HT_TIMEZONE, AMERICA_HT_TIMEZONE_BE_FIELD),
  selectOption(AMERICA_MT_TIMEZONE, AMERICA_MT_TIMEZONE_BE_FIELD),
  selectOption(AMERICA_PT_TIMEZONE, AMERICA_PT_TIMEZONE_BE_FIELD),
]

export const meridianOptions  = [
  selectOption(AM, AM),
  selectOption(PM, PM),
]

export const weekStartDayOptions = [
  selectOption('Select...', ''),
  selectOption(MONDAY, upperCase(MONDAY)),
  selectOption(TUESDAY, upperCase(TUESDAY)),
  selectOption(WEDNESDAY, upperCase(WEDNESDAY)),
  selectOption(THURSDAY, upperCase(THURSDAY)),
  selectOption(FRIDAY, upperCase(FRIDAY)),
  selectOption(SATURDAY, upperCase(SATURDAY)),
]

export const monthStartDayOptions = [
  selectOption(`${SELECT}...`, ''),
  selectOption('1', 1),
  selectOption('2', 2),
  selectOption('3', 3),
  selectOption('4', 4),
  selectOption('5', 5),
  selectOption('6', 6),
  selectOption('7', 7),
  selectOption('8', 8),
  selectOption('9', 9),
  selectOption('10', 10),
  selectOption('11', 11),
  selectOption('12', 12),
  selectOption('13', 13),
  selectOption('14', 14),
  selectOption('15', 15),
  selectOption('16', 16),
  selectOption('17', 17),
  selectOption('18', 18),
  selectOption('19', 19),
  selectOption('20', 20),
  selectOption('21', 21),
  selectOption('22', 22),
  selectOption('23', 23),
  selectOption('24', 24),
  selectOption('25', 25),
  selectOption('26', 26),
  selectOption('27', 27),
  selectOption('28', 28),
  selectOption('29', 29),
  selectOption('30', 30),
  selectOption('31', 31),
]

export const startTimeOptions =  [
  selectOption(`${SELECT}...`, ''),
  selectOption('1', 1),
  selectOption('2', 2),
  selectOption('3', 3),
  selectOption('4', 4),
  selectOption('5', 5),
  selectOption('6', 6),
  selectOption('7', 7),
  selectOption('8', 8),
  selectOption('9', 9),
  selectOption('10', 10),
  selectOption('11', 11),
  selectOption('12', 12),
]
