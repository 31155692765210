// use this formatter to ensure only digits are entering into a field when passing into a Redux form field
// these digits are formatted with commas when the number becomes applicable
import numeral from 'numeral'
import { NUMBER_FORMAT } from 'constants/formatAmountConstants'

const formatNumberFormatField = (number) => {
  if (!number) {
    return ''
  }

  return numeral(number).format(NUMBER_FORMAT)
}

export default formatNumberFormatField
