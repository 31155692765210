import moment from 'moment'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import pluralizeWord from 'utilities/pluralizeWord'

import {
  collectionMethodMap,
  intervalTypeMap,
} from 'constants/subscriptionsConstants'

const frontendSubscriptionPlanModel = ({ data: subscriptionPlan }) => {
  const [
    id,
    createdAt,
    updatedAt,
    linkedTo,
    linkedType,
    billingInterval,
    nickname,
    planName,
    description,
    amount,
    currency,
    billingDefaults,
    trialDefaults,
    state,
  ] = getMany(subscriptionPlan, [
    'id',
    'created_at',
    'updated_at',
    'linked_to',
    'linked_type',
    'billing_interval',
    'nickname',
    'plan_name',
    'description',
    'amount',
    'currency',
    'billing_defaults',
    'trial_defaults',
    'state',
  ])

  const [
    collectionMethod,
    sendInvoice,
    sendReceipt,
  ] = getMany(billingDefaults, [
    'collection_method',
    'send_invoice',
    'send_receipt',
  ])

  const [
    intervalType,
    intervalCount,
  ] = getMany(trialDefaults, [
    'interval_type',
    'interval_count',
  ])

  const trialStartDate = moment().format('MM/DD/YYYY')
  const displayIntervalType = pluralizeWord({ count: intervalCount, word: intervalTypeMap[intervalType] })


  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    linkedTo,
    linkedType,
    billingInterval,
    displayBillingInterval: snakeCaseToTitleCase({ key: billingInterval }),
    nickname,
    planName,
    description,
    amount,
    displayAmount: formatMoney({ amount }),
    currency,
    billingDefaults,
    trialDefaults,
    state,
    displayStatus: snakeCaseToTitleCase({ key: state }),
    status: state,
    collectionMethod,
    displayCollectionMethod: collectionMethodMap[collectionMethod],
    sendInvoice,
    sendReceipt,
    intervalType,
    displayIntervalType,
    intervalCount,
    displayTrialStartDate: trialStartDate,
    displayTrialDuration: `${intervalCount} ${displayIntervalType}`,
  })
}

export default frontendSubscriptionPlanModel
