import validateTagsForm from 'utilities/validate/validateTagsForm'

import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreateSubscriptionAmountForm = (values) => {
  const {
    amountNickname,
    amount,
    tags,
  } = values

  const tagErrors = validateTagsForm({ tags })

  return {
    amountNickname: fieldEmpty(amountNickname, 'Amount nickname'),
    amount: nonEmptyNumber({ field: amount, name: 'Amount' }),
    ...tagErrors,
  }
}

export default validateCreateSubscriptionAmountForm
