import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ApplicationSettingFormC from 'components/Customer/Forms/ApplicationSettingForm/ApplicationSettingFormC'

const ApplicationSettingModal = ({
  actionType,
  credentials,
  record,
}) => {
  return (
    <GenericModal
      title='Update Application Settings'
      className='ApplicationSettingModal'
      Component={ApplicationSettingFormC}
      actionType={actionType}
      credentials={credentials}
      record={record}
    />
  )
}

ApplicationSettingModal.propTypes = {
  actionType: PropTypes.string,
  credentials: PropTypes.object,
  record: PropTypes.object,
}

export default ApplicationSettingModal
