import React, { Component } from 'react'
import { connect } from 'react-redux'
import FeatureNotAvailableModal from './FeatureNotAvailableModal'

const mapStateToProps = (state, props) => {
  return {}
}

class FeatureNotAvailableModalC extends Component {
  render() {
    return (
      <FeatureNotAvailableModal {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(FeatureNotAvailableModalC)
