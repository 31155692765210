import './IdentityMerchantsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getMerchantsIdentitiesSelector } from 'state-layer/selectors'
import columnDescriptors from './columnDescriptors'
import Loader from 'components/Shared/Loader/Loader'
import TableC from 'components/Shared/Table/TableC'
import { IDENTITY_MERCHANTS } from 'constants/linkConstants'
import { GET_IDENTITY_MERCHANTS } from 'constants/flowConstants'
import { IDENTITY_MERCHANTS_RESOURCE_TITLE } from 'constants/language/languageConstants'

const IdentityMerchants = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  merchantPath = '',
  isFetching = false,
  isFetchingIdentity = false,
  identityId = '',
  actions = [],
}) => {
  return (
    <div className='IdentityMerchants'>
      { isFetchingIdentity && <Loader />}

      {!isFetchingIdentity && (
      <TableC
        flow={GET_IDENTITY_MERCHANTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getMerchantsIdentitiesSelector}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_MERCHANTS}
        allowedFilters={allowedFilters}
        path={merchantPath}
        isFetching={isFetching}
        actions={actions}
        fileNameCSV={`Identity_${identityId}_Merchants`}
        fileResourceTitleCSV={IDENTITY_MERCHANTS_RESOURCE_TITLE}
      />
      )}
    </div>
  )
}

IdentityMerchants.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  merchantPath: PropTypes.string,
  isFetching: PropTypes.bool,
  isFetchingIdentity: PropTypes.bool,
  identityId: PropTypes.string,
  actions: PropTypes.array,
}

export default IdentityMerchants
