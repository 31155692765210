import './MainAppS.scss'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import MainNavC from 'components/System/MainNav/MainNavC'
import HighlightC from 'components/Shared/Highlight/HighlightC'
import MainHeaderC from 'components/System/MainHeader/MainHeaderC'
import MobileHeaderC from 'components/System/MobileHeader/MobileHeaderC'
import ModalC from 'components/Shared/Modal/ModalC'
import PreviewPanelC from 'components/Shared/PreviewPanel/PreviewPanelC'
import FlashNotificationC from 'components/Customer/Shared/FlashNotification/FlashNotificationC'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import classNames from 'classnames'

const MainApp = ({
  children,
  auth,
  credentialId,
  hideNav = false,
  formattedPath = '',
  isAdmin = false,
  isLoggedIn = false,
  isProtectedPath = false,
  isUnprotectedPath = false,
  isRootPath = false,
  showPreviewPanel = false,
  isLoading = false,
}) => {
  const platformName = getCurrentPlatformName()

  const mainAppClassName = classNames({
    MainApp: true,
    hideNav,
    [platformName]: true,
    isUnprotectedPath,
    showPreviewPanel,
  })

  const mainContentClassName = classNames('main-content', { isAdmin }, { isLoggedOut: !isLoggedIn }, { isUnprotectedPath })
  const showNavigation = isLoggedIn && (isProtectedPath || isRootPath)

  return (
    <div className={mainAppClassName}>
      <FlashNotificationC />

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop
        pauseOnHover
        closeButton={false}
      />

      <ModalC />

      <PreviewPanelC />

      <HighlightC formattedPath={formattedPath} />

      { showNavigation && <MainNavC auth={auth} credentialId={credentialId} isLoading={isLoading} /> }

      <main className={mainContentClassName}>
        <div className='main-app-fixed-content'>
          { showNavigation && (
            <>
              <MainHeaderC auth={auth} formattedPath={formattedPath} />

              <MobileHeaderC
                auth={auth}
                formattedPath={formattedPath}
                credentialId={credentialId}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
        <div className='main-app-content'>
          {children}
        </div>
      </main>
    </div>
  )
}

MainApp.propTypes = {
  children: PropTypes.object,
  auth: PropTypes.object,
  credentialId: PropTypes.string,
  hideNav: PropTypes.bool,
  formattedPath: PropTypes.string,
  isAdmin: PropTypes.bool,
  isProtectedPath: PropTypes.bool,
  isUnprotectedPath: PropTypes.bool,
  isRootPath: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default MainApp
