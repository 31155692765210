import { Field } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import formatPercentageField from 'utilities/formatters/fields/formatPercentageField'
import { PERCENTAGE_ICON } from 'constants/iconConstants'

const normalizeHundredPercent = (value) => {
  if (!value) {
    return value
  }

  if (parseInt(value, 10) > 100) {
    return value.substring(0, value.length - 1)
  }

  return value
}

const PercentageField = ({
  name = 'amount',
  label = '',
  placeholder = '0.00',
  component = InputField,
  greaterPercentageOption = false,
  disabled = false,
  tooltip,
  tooltipPosition,
  required,
  onlyWholeNumbers = false,
  contextLabel,
  helperText,
}) => {
  const format = (percentage) => formatPercentageField({ percentage, greaterPercentageOption, onlyWholeNumbers })
  const normalize = greaterPercentageOption ? (val) => val : normalizeHundredPercent

  return (
    <Field
      name={name}
      label={label}
      placeholder={placeholder}
      component={component}
      format={format}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      normalize={normalize}
      trailingIcon={`fa fa-${PERCENTAGE_ICON}`}
      required={required}
      contextLabel={contextLabel}
      helperText={helperText}
    />
  )
}

PercentageField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  component: PropTypes.func,
  greaterPercentageOption: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  required: PropTypes.bool,
  onlyWholeNumbers: PropTypes.bool,
  contextLabel: PropTypes.string,
  helperText: PropTypes.string,
}

export default PercentageField
