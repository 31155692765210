import './HostedMerchantOnboardingExpiredOrCompletedS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'components/Shared/Button/Button'
import { ReactComponent as VerifyEmailErrorIcon } from 'img/svg/verify-email-error-icon.svg'
import { ReactComponent as VerifyEmailSuccessIcon } from 'img/svg/verify-email-success-icon.svg'

import {
  RETURN_HOME,
  THIS_LINK_HAS_EXPIRED,
  THIS_MERCHANT_ONBOARDING_LINK_HAS_EXPIRED_DESCRIPTION,
  YOUR_APPLICATION_HAS_ALREADY_BEEN_SUBMITTED,
} from 'constants/language/languageConstants'

const HostedMerchantOnboardingExpiredOrCompleted = ({
  isExpired = false,
  isCompleted = false,
  expiredSessionUrl = '',
  returnUrl = '',
}) => {
  return (
    <div className='HostedMerchantOnboardingExpiredOrCompleted'>
      <div className='flex center'>
        <div className='message flex column center'>
          {isExpired && (
            <>
              <div className='img-container'>
                <VerifyEmailErrorIcon />
              </div>
              <h5>{THIS_LINK_HAS_EXPIRED}</h5>
              <div className='p-2'>{THIS_MERCHANT_ONBOARDING_LINK_HAS_EXPIRED_DESCRIPTION}</div>
              {expiredSessionUrl && <Button label={RETURN_HOME} onClick={() => window.location.replace(expiredSessionUrl)} />}
            </>
          )}
          {isCompleted && (
            <>
              <div className='img-container'>
                <VerifyEmailSuccessIcon />
              </div>
              <h5>{YOUR_APPLICATION_HAS_ALREADY_BEEN_SUBMITTED}</h5>
              {returnUrl && <Button label={RETURN_HOME} onClick={() => window.location.replace(returnUrl)} />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

HostedMerchantOnboardingExpiredOrCompleted.propTypes = {
  isExpired: PropTypes.bool,
  isCompleted: PropTypes.bool,
  expiredSessionUrl: PropTypes.string,
  returnUrl: PropTypes.string,
}

export default HostedMerchantOnboardingExpiredOrCompleted
