import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import RemoveSettlementEntriesFormC from 'components/Customer/Forms/RemoveSettlementEntriesForm/RemoveSettlementEntriesFormC'
import { REMOVE_ONE_ENTRY } from 'constants/language/languageConstants'
import size from 'lodash/size'

const RemoveSettlementEntriesModal = ({
  settlementEntryIds = [],
  settlementId = '',
  selectedItemsKey = '',
  entriesData = [],
}) => {
  const entriesCount = size(settlementEntryIds)
  const title = entriesCount > 1 ? `Remove ${entriesCount} Entries` : REMOVE_ONE_ENTRY
  return (
    <GenericModal
      title={title}
      className='RemoveSettlementEntriesModal'
      Component={RemoveSettlementEntriesFormC}
      settlementEntryIds={settlementEntryIds}
      settlementId={settlementId}
      selectedItemsKey={selectedItemsKey}
      entriesData={entriesData}
    />
  )
}

RemoveSettlementEntriesModal.propTypes = {
  settlementId: PropTypes.string,
  settlementEntryIds: PropTypes.array,
  selectedItemsKey: PropTypes.string,
  entriesData: PropTypes.array,
}

export default RemoveSettlementEntriesModal
