import {
  CONTACT,
  NAME,
  EMAIL,
  PHONE,
  SHIPPING,
  SHIPPING_AMOUNT,
  SHIPPING_DETAILS,
  LINE_1,
  LINE_2,
  ADDRESS_LINE_ONE,
  ADDRESS_LINE_TWO,
  CITY,
  STATE,
  REGION,
  COUNTRY,
  POSTAL_CODE,
  SELECT_COUNTRY,
  BILLING_DETAILS,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  PAY,
  ORDER_SUMMARY,
  TOTAL_DUE,
  SUBTOTAL,
  DISCOUNT,
  ESTIMATED_TAX,
  TIP_AMOUNT,
  PAYMENT_AMOUNT,
  PAYMENT_AMOUNT_HELPER_TEXT,
  PROCESSING,
  CARD_NUMBER,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P3,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6,
  EXPIRATION,
  CVC,
  ZIP,
  ACCOUNT_NUMBER,
  BANK_CODE,
  ACCOUNT_TYPE,
  TRANSIT_NUMBER,
  TOTAL_PAID,
  INSTITUTION_NUMBER,
  ADDITIONAL_INFORMATION,
  SELECT_STATE,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
  RECIPIENT_PHONE_NUMBER_PLACEHOLDER,
  A_RECEIPT_WILL_BE_EMAILED_TO_YOU,
  HIDE_OTHER_RECEIPT_OPTIONS,
  VIEW_OTHER_RECEIPT_OPTIONS,
  VALID_US_CAN_NUMBERS_ONLY_RATES_APPLY,
  ONE_OR_MORE_EMAILS_ARE_INCORRECT,
  ONE_OR_MORE_PHONE_NUMBERS_ARE_INCORRECT,
  PAYMENT_AMOUNT_CANNOT_BE_GREATER_THAN_AMOUNT_OF,
  PAYMENT_AMOUNT_CANNOT_BE_LESS_THAN_MINIMUM_AMOUNT_OF,
} from 'constants/language/languageConstants'

export const FR_CA_TRANSLATION = {
  [CONTACT]: 'Contact',
  [NAME]: 'Nom et prénom',
  [EMAIL]: 'Courriel',
  [PHONE]: 'Téléphone',
  [SHIPPING]: 'Livraison',
  [SHIPPING_AMOUNT]: 'Montant de livraison',
  [SHIPPING_DETAILS]: 'Adresse de livraison',
  [LINE_1]: 'Ligne 1',
  [LINE_2]: 'Ligne 2',
  [ADDRESS_LINE_ONE]: 'Ligne d\'adresse 1',
  [ADDRESS_LINE_TWO]: 'Ligne d\'adresse 2',
  [CITY]: 'Ville',
  [STATE]: 'État / Province',
  [REGION]: 'Région',
  [POSTAL_CODE]: 'Code postal',
  [COUNTRY]: 'Pays',
  [SELECT_COUNTRY]: 'Sélectionner le pays',
  [SELECT_STATE]: 'Sélectionner l\'état',
  [BILLING_DETAILS]: 'Informations de facturation',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US]: 'En cliquant sur Payer, vous nous autorisez à initier un débit unique de la chambre de compensation automatisée (ACH) en votre nom sur votre compte bancaire indiqué ci-dessus. Le montant de cette transaction tel que mentionné ci-dessus sera présenté à votre institution financière le jour ouvrable suivant. Vous acceptez en outre qu\'une fois que vous avez cliqué sur Payer, vous ne pouvez pas révoquer cette autorisation ni annuler ce paiement.',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1]: "En cliquant sur soumettre, vous acceptez cet accord unique de DPA (Débit Pré-autorisé) et vous autorisez Finix Canada, Inc. à débiter le compte bancaire spécifié, de tout montant dû pour les frais découlant de l'utilisation des services et/ou de l'achat de produits.",
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2]: 'Les paiements seront débités du compte spécifié dans les 3 jours suivant votre accord sur ce DPA.',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P3]: 'Si la date de débit prévue n\'est pas un jour ouvrable, Finix Canada, Inc. débitera le compte indiqué le jour ouvrable suivant.',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4]: 'Vous acceptez que tout paiement dû soit débité de votre compte immédiatement après l\'acceptation de ce DPA et que la confirmation de ce DPA soit envoyée dans les cinq (5) jours suivant l\'acceptation de ce DPA.',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD]: 'Vous acceptez en outre que votre autorisation donnée au titre du présent Accord de DPA reste en vigueur jusqu\'à ce que nous ayons effectué le paiement unique, date à laquelle l\'Accord de DPA prendra automatiquement fin.',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5]: "Vous disposez de certains droits de recours si un débit n'est pas conforme au présent accord de DPA. Par exemple, vous avez le droit de recevoir un remboursement pour tout débit qui n'est pas autorisé ou qui n'est pas conforme au présent accord de DPA. Pour obtenir plus d'informations sur vos droits de recours, veuillez contacter votre institution financière ou visitez le site www.payments.ca.",
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6]: "Vous pouvez modifier ou annuler ce DPA à tout moment en donnant à Customer First Commerce un préavis de trente (30) jours à l\'adresse suivante: support@finix.com. Pour obtenir un modèle de formulaire d'annulation ou de plus amples informations sur l'annulation de ce DPA, veuillez contacter votre institution financière ou visiter le site www.payments.ca.",
  [TERMS_OF_SERVICE]: 'Termes et Conditions du Service',
  [PRIVACY_POLICY]: 'Politique de confidentialité',
  [PAY]: 'Payer',
  [ORDER_SUMMARY]: 'Résumé de la commande',
  [TOTAL_DUE]: 'Total dû',
  [TOTAL_PAID]: 'Total payé',
  [SUBTOTAL]: 'Sous-total',
  [DISCOUNT]: 'Réduction ',
  [ESTIMATED_TAX]: 'Taxe estimée',
  [TIP_AMOUNT]: 'Montant du pourboire',
  [PAYMENT_AMOUNT]: 'Montant du paiement',
  [PAYMENT_AMOUNT_HELPER_TEXT]: 'Inclueant les taxes, pourboires, frais, etc.',
  [PROCESSING]: 'Traitement',
  [CARD_NUMBER]: 'Numéro de carte',
  [EXPIRATION]: 'Expiration',
  [CVC]: 'CVV',
  [ZIP]: 'Code postal',
  [ACCOUNT_NUMBER]: 'Numéro de compte',
  [BANK_CODE]: 'Code bancaire',
  [ACCOUNT_TYPE]: 'Type de compte',
  [TRANSIT_NUMBER]: 'Numéro de transit',
  [INSTITUTION_NUMBER]: 'Numéro d\'établissement',
  [ADDITIONAL_INFORMATION]: 'Informations Complémentaires',
  [RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER]: 'Adresse courriel du destinataire',
  [RECIPIENT_PHONE_NUMBER_PLACEHOLDER]: 'Numéro de téléphone du destinataire',
  [A_RECEIPT_WILL_BE_EMAILED_TO_YOU]: 'Un reçu vous sera envoyé par email',
  [HIDE_OTHER_RECEIPT_OPTIONS]: 'Masquer les autres options de reçu',
  [VIEW_OTHER_RECEIPT_OPTIONS]: 'Afficher d\'autres options de reçus',
  [VALID_US_CAN_NUMBERS_ONLY_RATES_APPLY]: 'Numéros américains ou canadiens valides uniquement. La carte standard des tarifs de messages et de données s’applique.',
  [ONE_OR_MORE_EMAILS_ARE_INCORRECT]: 'Un ou plusieurs e-mails sont incorrects',
  [ONE_OR_MORE_PHONE_NUMBERS_ARE_INCORRECT]: 'Un ou plusieurs numéros de téléphone sont incorrects',
  [PAYMENT_AMOUNT_CANNOT_BE_GREATER_THAN_AMOUNT_OF]: 'Le montant du paiement ne peut être supérieur au montant maximum de',
  [PAYMENT_AMOUNT_CANNOT_BE_LESS_THAN_MINIMUM_AMOUNT_OF]: 'Le montant du paiement ne peut être inférieur au montant minimum de',
}
