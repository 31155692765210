import React, { Component } from 'react'
import { connect } from 'react-redux'
import SendReceiptModal from 'components/Customer/Modals/SendReceiptModal/SendReceiptModal'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMerchantDevicesRequest from 'utilities/actions/get/getMerchantDevicesRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import hideModalAction from 'utilities/actions/hideModalAction'
import { SEND_RECEIPT_FORM } from 'constants/formConstants'
import { PAYMENT_CARD_PRESENT } from 'constants/paymentInstrumentConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'

import {
  getEnabledDevicesSelector,
  getFormValues,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const transfer = get(props, 'transfer')
  const authorization = get(props, 'authorization')

  let paymentInstrument = {}
  let merchantId

  if (transfer) {
    [paymentInstrument, merchantId] = getMany(transfer, [
      'paymentInstrument',
      'merchantId',
    ])
  } else if (authorization) {
    [paymentInstrument, merchantId] = getMany(authorization, [
      'paymentInstrument',
      'merchant',
    ])
  }

  const [
    sendMethod = '',
  ] = getFormValues(state, SEND_RECEIPT_FORM, [
    'sendMethod',
  ])

  const transactionType = get(paymentInstrument, 'type')
  const isCardPresent = isEqual(transactionType, PAYMENT_CARD_PRESENT)
  const devices = isCardPresent ? getEnabledDevicesSelector(state) : []
  const deviceOptions = map(devices, ({ id, name }) => ({ label: name, value: id }))

  return removeUndefined({
    credentials,
    isCardPresent,
    deviceOptions,
    sendMethod,
    merchantId,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    getMerchantDevices: ({ credentials, merchantId }) => dispatch(getMerchantDevicesRequest({ credentials, merchantId })),
  }
}

class SendReceiptModalC extends Component {
  componentDidMount() {
    const {
      merchantId,
      getMerchantDevices,
      credentials,
    } = this.props

    if (merchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      merchantId,
      getMerchantDevices,
    } = this.props

    const {
      merchantId: prevMerchantId,
    } = prevProps

    if (merchantId && merchantId !== prevMerchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  render() {
    return <SendReceiptModal {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendReceiptModalC)
