import dashboardAPI from 'utilities/api/dashboardAPI'
import { MERCHANT_CHARTS } from 'constants/apiConstants'

const getMerchantChartsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: MERCHANT_CHARTS,
    meta,
    queries,
  })
}

export default getMerchantChartsAPI
