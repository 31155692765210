import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateBalanceAdjustmentReviewForm from './CreateBalanceAdjustmentReviewForm'
import { getPaymentInstrumentSelector, getIdentitySelector } from 'state-layer/selectors'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatMoney from 'utilities/formatters/formatMoney'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  AMOUNT,
  NOTE,
  ROUTING_NUMBER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingBalanceAdjustments = get(state, `balanceAdjustmentsR.${FETCHING}`)
  const isFetchingPaymentInstruments = get(state, `paymentInstrumentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const createBalanceAdjustmentFormValues = get(props, 'formValues.createBalanceAdjustment')
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)

  const merchantProcessors = get(identity, 'merchantProcessors')
  const processor = includes(merchantProcessors, FINIX_V1) ? FINIX_V1 : DUMMY_V1

  const [
    amount,
    paymentInstrumentId,
    description,
  ] = getMany(createBalanceAdjustmentFormValues, [
    'amount',
    'instrumentId.value',
    'description',
  ])

  const displayAmount = formatMoney({ amount })
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)

  const [
    name,
    maskedAccountNumber,
    bankCode,
  ] = getMany(paymentInstrument, [
    'name',
    'maskedAccountNumber',
    'bankCode',
  ])

  const transactionDetailsSectionData = convertPageSectionDataToV2([
    {
      label: AMOUNT,
      value: displayAmount,
    },
    {
      label: ACCOUNT_NAME,
      value: name,
    },
    {
      label: ROUTING_NUMBER,
      value: bankCode,
    },
    {
      label: ACCOUNT_NUMBER,
      value: maskedAccountNumber,
    },
    {
      label: NOTE,
      value: description,
    },
  ], 1)

  return {
    transactionDetailsSectionData,
    paymentInstrumentId,
    credentials,
    isFetching: isFetchingBalanceAdjustments || isFetchingPaymentInstruments,
    processor,
    identityId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentInstrument: ({ credentials, paymentInstrumentId }) => dispatch(getPaymentInstrumentRequest({ credentials, paymentInstrumentId })),
    getIdentity: ({ credentials, identityId }) => dispatch(getIdentityRequest({ credentials, identityId })),
  }
}

class CreateBalanceAdjustmentReviewFormC extends Component {
  componentDidMount() {
    const {
      paymentInstrumentId,
      getPaymentInstrument,
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getPaymentInstrument({ credentials, paymentInstrumentId })

    if (identityId) {
      getIdentity({ credentials, identityId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      paymentInstrumentId,
      identityId,
      getIdentity,
      getPaymentInstrument,
      credentials,
    } = this.props

    if (paymentInstrumentId && paymentInstrumentId !== prevProps.paymentInstrumentId) {
      getPaymentInstrument({ credentials, paymentInstrumentId })
    }

    if (identityId && identityId !== prevProps.identityId) {
      getIdentity({ credentials, identityId })
    }
  }

  render() {
    return (
      <CreateBalanceAdjustmentReviewForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBalanceAdjustmentReviewFormC)
