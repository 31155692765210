import selectOption from 'utilities/forms/selectOption'
import map from 'lodash/map'
import get from 'lodash/get'
import split from 'lodash/split'

// TODO: Remove old lists, finixMCCCodes is the new list

const selfServiceApplicationMccCodes = [
    '0742 - Veterinary Services',
    '0763 - Agricultural Cooperatives',
    '0780 - Horticultural and Landscaping Services',
    '1520 - General Contractors for Residential and Commercial',
    '1711 - Air Conditioning, Heating, and Plumbing Contractors',
    '1731 - Electrical Contractors',
    '1740 - Insulation, Masonry, Plastering, Stonework, and Tile Setting Contractors',
    '1750 - Carpentry Contractors',
    '1761 - Roofing, Siding, and Sheet Metal Work Contractors',
    '1771 - Concrete Work Contractors',
    '1799 - Special Trade Contractors (Not Elsewhere Classified)',
    '2741 - Miscellaneous Publishing and Printing',
    '2791 - Typesetting, Plate Making and Related Services',
    '2842 - Specialty Cleaning, Polishing, and Sanitation Preparations',
    '4111 - Local and Suburban Commuter Passenger Transportation Including Railroads, Ferries, and Local Water Transportation',
    '4119 - Ambulance Services',
    '4121 - Taxicabs and Limosines',
    '4214 - Motor Freight Carriers and Trucking (Local and Long Distance), Moving and Storage Companies, and Local Delivery Services',
    '4225 - Public Storage and Warehousing',
    '4457 - Boat Rentals and Leases',
    '4468 - Marinas, Marine Service, and Supplies',
    '4582 - Airports, Airport Terminals, Flying Fields, and Airport Lounge Services',
    '4722 - Travel Agencies and Tour Operators',
    '4784 - Tolls and Bridge Fees',
    '4789 - Transportation Services (Not Elsewhere Classified)',
    '4812 - Telecommunication Equipment Including Telephone Sales',
    '4816 - Computer Network/Information Services',
    '4899 - Cable and Other Pay Television',
    '4900 - Electric, Gas, Sanitary and Water Utilities',
    '5013 - Motor Vehicle Supplies and New Parts',
    '5021 - Office and Commercial Furniture',
    '5039 - Construction Materials (Not Elsewhere Classified)',
    '5044 - Office, Photographic, Photocopy, and Microfilm Equipment',
    '5045 - Computers, Computer Peripheral Equipment, and Software',
    '5046 - Commercial Equipment (Not Elsewhere Classified)',
    '5047 - Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
    '5051 - Metal Service Centers and Offices',
    '5065 - Electrical Parts and Equipment',
    '5072 - Hardware, Equipment, and Supplies',
    '5074 - Plumbing and Heating Equipment and Supplies',
    '5085 - Industrial Supplies (Not Elsewhere Classified)',
    '5094 - Precious Stones, Metals, Watches, and Jewelry',
    '5099 - Durable Goods (Not Elsewhere Classified)',
    '5111 - Stationery, Office Supplies, Printing, and Writing Paper',
    '5122 - Drugs, Drug Proprietors, and Druggist Sundries',
    '5131 - Piece Goods, Notions, and Other Dry Goods',
    '5137 - Men\'s, Women\'s and Children\'s Uniforms and Commercial Clothing',
    '5139 - Commercial Footwear',
    '5169 - Chemicals and Allied Products (Not Elsewhere Classified)',
    '5172 - Petroleum and Petroleum Products',
    '5192 - Books, Periodicals, and Newspapers',
    '5193 - Florist Supplies, Nursery Stock, and Flowers',
    '5198 - Paints, Varnishes, and Supplies',
    '5199 - Nondurable Goods (Not Elsewhere Classified)',
    '5200 - Home Supply Warehouse Stores',
    '5211 - Lumber and Building Materials Stores',
    '5231 - Glass, Paint, and Wallpaper Stores',
    '5251 - Hardware Stores',
    '5261 - Nurseries, Lawn and Garden Supply Stores',
    '5262 - Marketplaces',
    '5300 - Wholesale Clubs',
    '5309 - Duty Free Stores',
    '5310 - Discount Stores',
    '5311 - Department Stores',
    '5331 - Variety Stores',
    '5399 - Miscellaneous General Merchandise',
    '5411 - Grocery Stores, Supermarkets',
    '5422 - Freezer and Locker Meat Provisioners',
    '5441 - Candy, Nut, and Confectionery Stores',
    '5451 - Dairy Products Stores',
    '5462 - Bakeries',
    '5499 - Miscellaneous Food Stores Including Convenience Stores and Specialty Stores (Excludes Personal Enhancement Products and/or Nutraceuticals)',
    '5511 - Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
    '5521 - Automobile and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing',
    '5531 - Auto and Home Supply Stores',
    '5532 - Automotive Tire Stores',
    '5533 - Automotive Parts and Accessories Stores',
    '5541 - Service Stations (With or Without Ancillary Services)',
    '5551 - Boat Dealers',
    '5561 - Recreational and Utility Trailers, Camp Dealers',
    '5571 - Motorcycle Shops and Dealers',
    '5598 - Snowmobile Dealers',
    '5599 - Miscellaneous Auto Dealers',
    '5611 - Men\'s and Boys\' Clothing and Accessories Stores',
    '5621 - Women\'s Ready to Wear Stores',
    '5631 - Women’s Accessory and Specialty Shops',
    '5641 - Children\'s and Infants\' Wear Stores',
    '5651 - Family Clothing Stores',
    '5655 - Sports and Riding Apparel Stores',
    '5661 - Shoe Stores',
    '5681 - Furriers and Fur Shops',
    '5691 - Men’s and Women’s Clothing Stores',
    '5697 - Tailors, Seamstresses, Mending, and Alterations',
    '5698 - Wig and Toupee Stores',
    '5699 - Miscellaneous Apparel and Accessory Shops',
    '5712 - Furniture, Home Furnishings, and Equipment Stores Excluding Appliances',
    '5713 - Floor Covering Stores',
    '5714 - Drapery, Window Covering, and Upholstery Stores',
    '5718 - Fireplace, Fireplace Screens, and Accessories Stores',
    '5719 - Miscellaneous Home Furnishing Specialty Stores',
    '5722 - Household Appliance Stores',
    '5732 - Electronics Stores',
    '5733 - Music Stores-Musical Instruments, Pianos, and Sheet Music',
    '5734 - Computer Software Stores',
    '5735 - Record Shops',
    '5811 - Caterers',
    '5812 - Eating Places and Restaurants Including Food Delivery',
    '5813 - Drinking Places (Alcoholic Beverages) Including Bars, Cocktail lounges, Discotheques, Nightclubs and Taverns',
    '5814 - Fast Food Restaurants Including Food Delivery',
    '5815 - Digital Goods Media - Books, Movies, Digital Artwork/Images, Music',
    '5816 - Digital Goods - Games',
    '5817 - Digital Goods - Applications (Excludes Games)',
    '5818 - Digital Goods - Large Digital Goods Merchant',
    '5832 - Antique Shops - Sales, Repairs, and Restoration Services',
    '5912 - Drug Stores and Pharmacies',
    '5921 - Package Stores (Beer, Wine, and Liquor)',
    '5931 - Used Merchandise and Secondhand Stores',
    '5932 - Antique Shops - Sales, Repairs, and Restoration Services',
    '5933 - Pawn Shops',
    '5935 - Wrecking and Salvage Yards',
    '5940 - Bicycle Shops-Sales and Service',
    '5941 - Sporting Goods Stores',
    '5942 - Book Stores',
    '5943 - Stationery Stores, Office and School Supply Stores',
    '5944 - Watch, Clock, Jewelry, and Silverware Stores',
    '5945 - Hobby, Toy, and Game Shops',
    '5946 - Camera and Photographic Supply Stores',
    '5947 - Card Shops, Gift, Novelty, and Souvenir Shops',
    '5948 - Luggage and Leather Goods Stores',
    '5949 - Sewing, Needlework, Fabric, and Piece Goods Stores',
    '5950 - Glassware, Crystal Stores',
    '5960 - Direct Marketing for Insurance Services',
    '5964 - Direct Marketing (Catalog Merchant)',
    '5970 - Artist Supply and Craft Shops',
    '5971 - Art Dealers and Galleries',
    '5972 - Stamp and Coin Stores',
    '5973 - Religious Goods Stores',
    '5975 - Hearing Aids Sales, Service, and Supply Stores',
    '5976 - Orthopedic Goods-Prosthetic Devices',
    '5977 - Cosmetic Stores',
    '5978 - Typewriter Stores-Sales, Rentals, and Service',
    '5983 - Fuel Dealers - Fuel Oil, Wood, Coal, Liquefied Petroleum',
    '5992 - Florists',
    '5993 - Cigar Stores and Stands, Tobacco, Vape, CBD',
    '5994 - News Dealers and Newsstands',
    '5995 - Pet Shops, Pet Foods, and Supplies Stores (In-Person and Online)',
    '5996 - Swimming Pool Sales, Service, and Supplies',
    '5997 - Electric Razor Stores - Sales and Service',
    '5998 - Tent and Awning Shops',
    '5999 - Miscellaneous and Specialty Retail Stores',
    '6012 - Financial Institutions – Services',
    '6051 - Quasi Cash / Account Funding',
    '6211 - Security Brokers/Dealers',
    '6300 - Insurance Sales, Underwriting, and Premiums',
    '6513 - Real Estate Agents and Managers',
    '6540 - Funding Transactions',
    '7011 - Lodging Including Hotels, Motels, Resorts, and Central Reservation Services (Not Elsewhere Classified)',
    '7032 - Sporting and Recreational Camps (Excludes Nudist Camps)',
    '7033 - Trailer Parks and Campgrounds',
    '7210 - Laundry, Cleaning, and Garment Services',
    '7211 - Laundry Services - Family and Commercial',
    '7216 - Dry Cleaners',
    '7217 - Carpet and Upholstery Cleaning',
    '7221 - Photographic Studios',
    '7230 - Barber and Beauty Shops',
    '7251 - Hat Cleaning and Shoe Repair Shops',
    '7261 - Funeral Service and Crematories',
    '7273 - Dating Services',
    '7276 - Tax Preparation Service',
    '7277 - Debt, Marriage, and Personal Counseling Services',
    '7278 - Buying and Shopping Services and Clubs',
    '7296 - Clothing Rental - Costumes, Uniforms, and Formal Wear',
    '7297 - Massage Parlors',
    '7298 - Health and Beauty Spas',
    '7299 - Miscellaneous Personal Services (Not Elsewhere Classified)',
    '7311 - Advertising Services',
    '7321 - Consumer Credit Reporting Agencies',
    '7322 - Collection Agencies',
    '7323 - Credit Reporting Services',
    '7332 - Blueprinting and Photocopying Services',
    '7333 - Commercial Photography, Art, and Graphics',
    '7338 - Quick Copy, Reproduction, and Blueprinting Services',
    '7339 - Stenographic and Secretarial Support',
    '7342 - Exterminating and Disinfecting Services',
    '7349 - Cleaning and Maintenance, Janitorial Services',
    '7361 - Employment Agencies, Temporary Help Services',
    '7372 - Computer Programming, Integrated Systems Design and Data Processing Services',
    '7375 - Information Retrieval Services',
    '7379 - Computer Maintenance and Repair Services (Not Elsewhere Classified)',
    '7392 - Management, Consulting, and Public Relations Services',
    '7393 - Detective Agencies, Protective Agencies, and Security Services Including Armored Cars and Guard Dogs',
    '7394 - Equipment, Tool, Furniture, and Appliance Rental and Leasing Services',
    '7395 - Photofinishing Laboratories and Photo Developing',
    '7399 - Business Services Including Event Planning and Management (Not Elsewhere Classified)',
    '7519 - Recreational Vehicle Rentals',
    '7523 - Parking Lots, Parking Meters, and Garages',
    '7531 - Automotive Body Repair Shops',
    '7533 - Exhaust Repair',
    '7534 - Tire Re-treading and Repair Shops',
    '7535 - Auto Paint Shops',
    '7536 - Auto Glass',
    '7537 - Transmission Repair',
    '7538 - Automotive Service Shops (Excludes Dealers)',
    '7539 - Auto Repair Shops (Other)',
    '7542 - Car Washes',
    '7549 - Other Automotive Services',
    '7622 - Electronics Repair Shops',
    '7623 - Air Conditioning and Refrigeration Repair Shops',
    '7629 - Electrical and Small Appliance Repair Shops',
    '7631 - Watch and Jewelry Repair Shops',
    '7641 - Furniture, Furniture Repair, and Furniture Refinishing',
    '7692 - Welding Services',
    '7699 - Miscellaneous Repair Shops and Related Services',
    '7829 - Motion Picture and Video Tape Production and Distribution',
    '7832 - Motion Picture Theaters',
    '7841 - Video Tape Rental Stores',
    '7911 - Dance Halls, Studios, and Schools',
    '7922 - Theatrical Producers (Except Motion Pictures) and Ticket Agencies',
    '7929 - Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
    '7932 - Billiard and Pool Establishments',
    '7933 - Bowling Alleys',
    '7941 - Commercial Sports, Athletic Fields, Professional Sport Clubs, Sport Promoters, Sports Arena, and Stadium Management',
    '7991 - Tourist Attractions and Exhibits',
    '7992 - Public Golf Courses',
    '7993 - Video Amusement Game Supplies',
    '7994 - Video Game Arcades and Establishments',
    '7995 - Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, Wagers at Race Tracks and games of chance to win prizes of monetary value',
    '7996 - Amusement Parks, Circuses, Carnivals, Fortune Tellers',
    '7997 - Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
    '7998 - Aquariums, Seaquariums, Dolphinariums, and Zoos',
    '7999 - Recreation Services (Not Elsewhere Classified; Excludes Shooting Ranges)',
    '8011 - Doctors and Physicians (Not Elsewhere Classified)',
    '8021 - Dentists and Orthodontists',
    '8031 - Osteopaths',
    '8041 - Chiropractors',
    '8042 - Optometrists and Ophthalmologists',
    '8043 - Opticians, Optical Goods, and Eyeglasses',
    '8049 - Podiatrists and Chiropodists',
    '8050 - Nursing, Personal Care Facilities, and Home Healthcare',
    '8062 - Hospitals',
    '8071 - Medical and Dental Laboratories',
    '8099 - Medical Services and Health Practitioners (Not Elsewhere Classified)',
    '8111 - Legal Services and Attorneys',
    '8211 - Elementary and Secondary Schools',
    '8220 - Colleges, Junior Colleges, Universities, and Professional Schools',
    '8241 - Correspondence Schools',
    '8244 - Business and Secretarial Schools',
    '8249 - Vocational and Trade Schools',
    '8299 - Schools and Educational Services (Not Elsewhere Classified)',
    '8351 - Child Care Services',
    '8398 - Charitable and Social Service Organizations',
    '8641 - Civic, Fraternal, and Social Associations',
    '8651 - Political Organizations',
    '8661 - Religious Organizations',
    '8675 - Automobile Associations',
    '8699 - Membership Organizations (Not Elsewhere Classified)',
    '8734 - Testing Laboratories',
    '8748 - Business Consulting Services, Not Elsewhere Classified',
    '8911 - Architectural, Engineering, and Surveying Services',
    '8931 - Accounting, Auditing, and Bookkeeping Services',
    '8999 - Professional Services (Not Elsewhere Classified)',
    '9211 - Court Costs, Including Alimony and Child Support',
    '9222 - Fines',
    '9223 - Bail and Bond Payments',
    '9311 - Tax Payments',
    '9399 - Government Services (Not Elsewhere Classified)',
]

export const finixMCCsList = {
    '0742': 'Veterinary Services',
    '0763': 'Agricultural Cooperatives',
    '0780': 'Horticultural and Landscaping Services',
    '1520': 'General Contractors for Residential and Commercial',
    '1711': 'Air Conditioning, Heating, and Plumbing Contractors',
    '1731': 'Electrical Contractors',
    '1740': 'Insulation, Masonry, Plastering, Stonework, and Tile Setting Contractors',
    '1750': 'Carpentry Contractors',
    '1761': 'Roofing, Siding, and Sheet Metal Work Contractors',
    '1771': 'Concrete Work Contractors',
    '1799': 'Special Trade Contractors (Not Elsewhere Classified)',
    '2741': 'Miscellaneous Publishing and Printing',
    '2791': 'Typesetting, Plate Making and Related Services',
    '2842': 'Specialty Cleaning, Polishing, and Sanitation Preparations',
    '4111': 'Local and Suburban Commuter Passenger Transportation Including Railroads, Ferries, and Local Water Transportation',
    '4119': 'Ambulance Services',
    '4121': 'Taxicabs and Limousines',
    '4214': 'Motor Freight Carriers and Trucking (Local and Long Distance), Moving and Storage Companies, and Local Delivery Services',
    '4225': 'Public Storage and Warehousing',
    '4457': 'Boat Rentals and Leases',
    '4468': 'Marinas, Marine Service, and Supplies',
    '4582': 'Airports, Airport Terminals, Flying Fields, and Airport Lounge Services',
    '4722': 'Travel Agencies and Tour Operators',
    '4789': 'Transportation Services (Not Elsewhere Classified)',
    '4784': 'Tolls and Bridge Fees',
    '4812': 'Telecommunication Equipment Including Telephone Sales',
    '4816': 'Computer Network/Information Services',
    '4899': 'Cable and Other Pay Television',
    '4900': 'Electric, Gas, Sanitary, and Water Utilities',
    '5013': 'Motor Vehicle Supplies and New Parts',
    '5021': 'Office and Commercial Furniture',
    '5039': 'Construction Materials (Not Elsewhere Classified)',
    '5044': 'Office, Photographic, Photocopy, and Microfilm Equipment',
    '5045': 'Computers, Computer Peripheral Equipment, and Software',
    '5046': 'Commercial Equipment (Not Elsewhere Classified)',
    '5047': 'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
    '5051': 'Metal Service Centers and Offices',
    '5065': 'Electrical Parts and Equipment',
    '5072': 'Hardware, Equipment, and Supplies',
    '5074': 'Plumbing and Heating Equipment and Supplies',
    '5085': 'Industrial Supplies (Not Elsewhere Classified)',
    '5094': 'Precious Stones, Metals, Watches, and Jewelry',
    '5099': 'Durable Goods (Not Elsewhere Classified)',
    '5111': 'Stationery, Office Supplies, Printing, and Writing Paper',
    '5122': 'Drugs, Drug Proprietors, and Druggist Sundries',
    '5131': 'Piece Goods, Notions, and Other Dry Goods',
    '5137': 'Men’s, Women’s, and Children’s Uniforms and Commercial Clothing',
    '5139': 'Commercial Footwear',
    '5169': 'Chemicals and Allied Products (Not Elsewhere Classified)',
    '5172': 'Petroleum and Petroleum Products',
    '5192': 'Books, Periodicals, and Newspapers',
    '5193': 'Florist Supplies, Nursery Stock, and Flowers',
    '5198': 'Paints, Varnishes, and Supplies',
    '5199': 'Nondurable Goods (Not Elsewhere Classified)',
    '5200': 'Home Supply Warehouse Stores',
    '5211': 'Lumber and Building Materials Stores',
    '5231': 'Glass, Paint, and Wallpaper Stores',
    '5251': 'Hardware Stores',
    '5261': 'Nurseries, Lawn and Garden Supply Stores',
    '5262': 'Marketplaces',
    '5300': 'Wholesale Clubs',
    '5309': 'Duty Free Stores',
    '5310': 'Discount Stores',
    '5311': 'Department Stores',
    '5331': 'Variety Stores',
    '5399': 'Miscellaneous General Merchandise',
    '5411': 'Grocery Stores, Supermarkets',
    '5422': 'Freezer and Locker Meat Provisioners',
    '5441': 'Candy, Nut, and Confectionery Stores',
    '5451': 'Dairy Products Stores',
    '5462': 'Bakeries',
    '5499': 'Miscellaneous Food Stores Including Convenience Stores and Specialty Stores (Excludes Personal Enhancement Products and/or Nutraceuticals)',
    '5511': 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
    '5521': 'Automobile and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing',
    '5531': 'Auto and Home Supply Stores',
    '5532': 'Automotive Tire Stores',
    '5533': 'Automotive Parts, Accessories Stores',
    '5541': 'Service Stations (With or Without Ancillary Services)',
    '5551': 'Boat Dealers',
    '5561': 'Recreational and Utility Trailers, Camp Dealers',
    '5571': 'Motorcycle Shops and Dealers',
    '5598': 'Snowmobile Dealers',
    '5599': 'Miscellaneous Auto Dealers',
    '5611': 'Men’s and Boys’ Clothing and Accessories Stores',
    '5621': 'Women’s Ready-To-Wear Stores',
    '5631': 'Women’s Accessory and Specialty Shops',
    '5641': 'Children’s and Infant’s Wear Stores',
    '5651': 'Family Clothing Stores',
    '5655': 'Sports and Riding Apparel Stores',
    '5661': 'Shoe Stores',
    '5681': 'Furriers and Fur Shops',
    '5691': 'Men’s and Women’s Clothing Stores',
    '5697': 'Tailors, Seamstresses, Mending, and Alterations',
    '5698': 'Wig and Toupee Stores',
    '5699': 'Miscellaneous Apparel and Accessory Shops',
    '5712': 'Furniture, Home Furnishings, and Equipment Stores Excluding Appliances',
    '5713': 'Floor Covering Stores',
    '5714': 'Drapery, Window Covering, and Upholstery Stores',
    '5718': 'Fireplace, Fireplace Screens, and Accessories Stores',
    '5719': 'Miscellaneous Home Furnishing Specialty Stores',
    '5722': 'Household Appliance Stores',
    '5732': 'Electronics Stores',
    '5733': 'Music Stores-Musical Instruments, Pianos, and Sheet Music',
    '5734': 'Computer Software Stores',
    '5735': 'Record Shops',
    '5811': 'Caterers',
    '5812': 'Eating Places and Restaurants Including Food Delivery',
    '5813': 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail Lounges, Nightclubs, and Taverns',
    '5814': 'Fast Food Restaurants Including Food Delivery',
    '5815': 'Digital Goods Media - Books, Movies, Digital Artwork/Images, Music',
    '5816': 'Digital Goods - Games',
    '5817': 'Digital Goods - Applications (Excludes Games)',
    '5818': 'Digital Goods - Large Digital Goods Merchant',
    '5832': 'Antique Shops - Sales, Repairs, and Restoration Services',
    '5912': 'Drug Stores and Pharmacies',
    '5921': 'Package Stores (Beer, Wine, and Liquor)',
    '5931': 'Used Merchandise and Secondhand Stores',
    '5932': 'Antique Shops - Sales, Repairs, and Restoration Services',
    '5933': 'Pawn Shops',
    '5935': 'Wrecking and Salvage Yards',
    '5940': 'Bicycle Shops-Sales and Service',
    '5941': 'Sporting Goods Stores',
    '5942': 'Book Stores',
    '5943': 'Stationery Stores, Office and School Supply Stores',
    '5944': 'Watch, Clock, Jewelry, and Silverware Stores',
    '5945': 'Hobby, Toy, and Game Shops',
    '5946': 'Camera and Photographic Supply Stores',
    '5947': 'Card Shops, Gift, Novelty, and Souvenir Shops',
    '5948': 'Luggage and Leather Goods Stores',
    '5949': 'Sewing, Needlework, Fabric, and Piece Goods Stores',
    '5950': 'Glassware, Crystal Stores',
    '5960': 'Direct Marketing for Insurance Services',
    '5964': 'Direct Marketing (Catalog Merchant)',
    '5970': 'Artist Supply and Craft Shops',
    '5971': 'Art Dealers and Galleries',
    '5972': 'Stamp and Coin Stores',
    '5973': 'Religious Goods Stores',
    '5975': 'Hearing Aids Sales, Service, and Supply Stores',
    '5976': 'Orthopedic Goods-Prosthetic Devices',
    '5977': 'Cosmetic Stores',
    '5978': 'Typewriter Stores-Sales, Rentals, and Service',
    '5983': 'Fuel Dealers - Fuel Oil, Wood, Coal, Liquefied Petroleum',
    '5992': 'Florists',
    '5993': 'Cigar Stores and Stands, Tobacco, Vape, CBD',
    '5994': 'News Dealers and Newsstands',
    '5995': 'Pet Shops, Pet Foods, and Supplies Stores (In-Person and Online)',
    '5996': 'Swimming Pools Sales, Service, and Supplies',
    '5997': 'Electric Razor Stores - Sales and Service',
    '5998': 'Tent and Awning Shops',
    '5999': 'Miscellaneous and Specialty Retail Stores',
    '6012': 'Financial Institutions – Services',
    '6051': 'Quasi Cash / Account Funding',
    '6211': 'Security Brokers/Dealers',
    '6300': 'Insurance Sales, Underwriting, and Premiums',
    '6513': 'Real Estate Agents and Managers',
    '6540': 'Funding Transactions',
    '7011': 'Lodging Including Hotels, Motels, Resorts, Central Reservation Services (Not Elsewhere Classified)',
    '7032': 'Sporting and Recreational Camps (Excludes Nudist Camps)',
    '7033': 'Trailer Parks and Campgrounds',
    '7210': 'Laundry, Cleaning, and Garment Services',
    '7211': 'Laundry Services - Family and Commercial',
    '7216': 'Dry Cleaners',
    '7217': 'Carpet and Upholstery Cleaning',
    '7221': 'Photographic Studios',
    '7230': 'Barber and Beauty Shops',
    '7251': 'Hat Cleaning and Shoe Repair Shops',
    '7261': 'Funeral Service and Crematories',
    '7273': 'Dating Services',
    '7276': 'Tax Preparation Service',
    '7277': 'Debt, Marriage, and Personal Counseling Services',
    '7278': 'Buying and Shopping Services and Clubs',
    '7296': 'Clothing Rental - Costumes, Uniforms, and Formal Wear',
    '7297': 'Massage Parlors',
    '7298': 'Health and Beauty Spas',
    '7299': 'Miscellaneous Personal Services (Not Elsewhere Classified)',
    '7311': 'Advertising Services',
    '7321': 'Consumer Credit Reporting Agencies',
    '7322': 'Collection Agencies',
    '7323': 'Credit Reporting Services',
    '7332': 'Blueprinting and Photocopying Services',
    '7333': 'Commercial Photography, Art, and Graphics',
    '7338': 'Quick Copy, Reproduction, and Blueprinting Services',
    '7339': 'Stenographic and Secretarial Support',
    '7342': 'Exterminating and Disinfecting Services',
    '7349': 'Cleaning and Maintenance, Janitorial Services',
    '7361': 'Employment Agencies, Temporary Help Services',
    '7372': 'Computer Programming, Integrated Systems Design, and Data Processing Services',
    '7375': 'Information Retrieval Services',
    '7379': 'Computer Maintenance and Repair Services (Not Elsewhere Classified)',
    '7392': 'Management, Consulting, and Public Relations Services',
    '7393': 'Detective Agencies, Protective Agencies, and Security Services Including Armored Cars and Guard Dogs',
    '7394': 'Equipment, Tool, Furniture, and Appliance Rental and Leasing Services',
    '7395': 'Photofinishing Laboratories and Photo Developing',
    '7399': 'Business Services Including Event Planning and Management (Not Elsewhere Classified)',
    '7519': 'Recreational Vehicle Rentals',
    '7523': 'Parking Lots, Parking Meters, and Garages',
    '7531': 'Automotive Body Repair Shops',
    '7533': 'Exhaust Repair',
    '7534': 'Tire Re-treading and Repair Shops',
    '7535': 'Auto Paint Shops',
    '7536': 'Auto Glass',
    '7537': 'Transmission Repair',
    '7538': 'Automotive Service Shops (Excludes Dealers)',
    '7539': 'Auto Repair Shops (Other)',
    '7542': 'Car Washes',
    '7549': 'Other Automotive Services',
    '7622': 'Electronics Repair Shops',
    '7623': 'Air Conditioning and Refrigeration Repair Shops',
    '7629': 'Electrical and Small Appliance Repair Shops',
    '7631': 'Watch and Jewelry Repair Shops',
    '7641': 'Furniture, Furniture Repair, and Furniture Refinishing',
    '7692': 'Welding Services',
    '7699': 'Miscellaneous Repair Shops and Related Services',
    '7829': 'Motion Picture and Video Tape Production and Distribution',
    '7832': 'Motion Picture Theaters',
    '7841': 'Video Tape Rental Stores',
    '7911': 'Dance Halls, Studios, and Schools',
    '7922': 'Theatrical Producers (Except Motion Pictures) and Ticket Agencies',
    '7929': 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
    '7932': 'Billiard and Pool Establishments',
    '7933': 'Bowling Alleys',
    '7941': 'Commercial Sports, Athletic Fields, Professional Sport Clubs, Sport Promoters, Sports Arena, and Stadium Management',
    '7991': 'Tourist Attractions and Exhibits',
    '7992': 'Public Golf Courses',
    '7993': 'Video Amusement Game Supplies',
    '7994': 'Video Game Arcades and Establishments',
    '7995': 'Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, Wagers at Race Tracks and games of chance to win prizes of monetary value',
    '7996': 'Amusement Parks, Circuses, Carnivals, Fortune Tellers',
    '7997': 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
    '7998': 'Aquariums, Seaquariums, Dolphinariums, and Zoos',
    '7999': 'Recreation Services (Not Elsewhere Classified; Excludes Shooting Ranges)',
    '8011': 'Doctors and Physicians (Not Elsewhere Classified)',
    '8021': 'Dentists and Orthodontists',
    '8031': 'Osteopaths',
    '8041': 'Chiropractors',
    '8042': 'Optometrists and Ophthalmologists',
    '8043': 'Opticians, Optical Goods, and Eyeglasses',
    '8049': 'Podiatrists and Chiropodists',
    '8050': 'Nursing, Personal Care Facilities, and Home Healthcare',
    '8062': 'Hospitals',
    '8071': 'Medical and Dental Laboratories',
    '8099': 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
    '8111': 'Legal Services and Attorneys',
    '8211': 'Elementary and Secondary Schools',
    '8220': 'Colleges, Junior Colleges, Universities, and Professional Schools',
    '8241': 'Correspondence Schools',
    '8244': 'Business and Secretarial Schools',
    '8249': 'Vocational Schools and Trade Schools',
    '8299': 'Schools and Educational Services (Not Elsewhere Classified)',
    '8351': 'Child Care Services',
    '8398': 'Charitable and Social Service Organizations',
    '8641': 'Civic, Fraternal, and Social Associations',
    '8651': 'Political Organizations',
    '8661': 'Religious Organizations',
    '8675': 'Automobile Associations',
    '8699': 'Membership Organizations (Not Elsewhere Classified)',
    '8734': 'Testing Laboratories',
    '8748': 'Business Consulting Services, Not Elsewhere Classified',
    '8911': 'Architectural, Engineering, and Surveying Services',
    '8931': 'Accounting, Auditing, and Bookkeeping Services',
    '8999': 'Professional Services (Not Elsewhere Classified)',
    '9211': 'Court Costs, Including Alimony and Child Support',
    '9222': 'Fines',
    '9223': 'Bail and Bond Payments',
    '9311': 'Tax Payments',
    '9399': 'Government Services (Not Elsewhere Classified)',
}

export const selfServiceApplicationMccCodesSelectOptions = map(selfServiceApplicationMccCodes, (codeLabel) => selectOption(codeLabel, get(split(codeLabel, '-'), '[0]').trim()))

export const finixMCCsSelectOptions = map(finixMCCsList, (value, key) => selectOption(`${key} - ${value}`, key))
