import './ComplianceSettingsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { ALERT_ICON } from 'constants/iconConstants'
import { EDIT_COMPLIANCE_TEMPLATE_FORM } from 'constants/formConstants'

import {
  CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_INSTRUCTIONS,
  CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_TITLE,
  COMPLIANCE_FORM_SETTINGS,
} from 'constants/language/languageConstants'

const ComplianceSettings = ({
  complianceTemplateCards = [],
  isFetching = false,
  toggleAutoGenerateComplianceForms = () => {},
  toggleAutoRenewComplianceForms = () => {},
  disableToggle = false,
  applicationInheritedPlatformTemplate = false,
  complianceSettingsActions,
}) => {
  return (
    <div className='ComplianceSettings'>
      <PageSectionV2C
        title={COMPLIANCE_FORM_SETTINGS}
        isFetching={isFetching}
        actions={complianceSettingsActions}
      >
        { !isFetching && applicationInheritedPlatformTemplate && (
          <div className='warning-message'>
            <div className='flex items-center'>
              <i className={`fa fa-${ALERT_ICON}`} />
              <h6>{CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_TITLE}</h6>
            </div>
            <div className='description'>{CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_INSTRUCTIONS}</div>
          </div>
        )}

        { !isFetching && !applicationInheritedPlatformTemplate && (
        <>
          <ToggleSwitchC
            name='autoGenerateComplianceForms'
            label='Automatically generate compliance forms'
            form={EDIT_COMPLIANCE_TEMPLATE_FORM}
            customHandleChange={toggleAutoGenerateComplianceForms}
            disabled={disableToggle}
            className='generate-compliance-forms-toggle'
          />
          <ToggleSwitchC
            name='autoRenewComplianceForms'
            label='Automatically generate renewal compliance forms'
            form={EDIT_COMPLIANCE_TEMPLATE_FORM}
            customHandleChange={toggleAutoRenewComplianceForms}
            disabled={disableToggle}
            className='renew-compliance-forms-toggle'
          />
          <PageSectionV2C
            columns={complianceTemplateCards}
            className='template-section'
          />
        </>
        )}
      </PageSectionV2C>
    </div>
  )
}

ComplianceSettings.propTypes = {
  complianceTemplateCards: PropTypes.array,
  isFetching: PropTypes.bool,
  toggleAutoGenerateComplianceForms: PropTypes.func,
  toggleAutoRenewComplianceForms: PropTypes.func,
  disableToggle: PropTypes.bool,
  applicationInheritedPlatformTemplate: PropTypes.bool,
  complianceSettingsActions: PropTypes.array,
}

export default ComplianceSettings
