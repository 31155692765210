import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import validateComplianceSelfAssessmentQuestionnaireAForm from 'utilities/validate/validateComplianceSelfAssessmentQuestionnaireAForm'
import { COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_FORM } from 'constants/formConstants'

import {
  vendorSuppliedDefaultsChangedBeforeInstallOnNetworkLabel,
  unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetworkLabel,
  systemAndSoftwareProtectedFromKnownVulnerabilitiesLabel,
  criticalSecurityPatchesInstalledWithinOneMonthOfReleaseLabel,
  usersAssignedUniqueIdBeforeAccessToSystemLabel,
  accessForTerminatedUsersRemovedLabel,
  authenticateUsersUsingPasswordOrTokenOrBiometricLabel,
  userPasswordParametersConfiguredToMeetReqsLabel,
  groupSharedAccountsPasswordsAuthenticationMethodsProhibitedLabel,
  allMediaPhysicallySecuredLabel,
  strictControlOverDistributionOfMediaLabel,
  mediaClassifiedToDetermineSensitivityOfDataLabel,
  mediaSentCanBeAccuratelyTrackedLabel,
  managementApprovalObtainedToMoveMediaLabel,
  strictControlMaintainedOverStorageAndAccessibilityOfMediaLabel,
  mediaDestroyedWhenNoLongerNeededLabel,
  hardCopyMaterialsCrosscutShreddedIncineratedOrPulpedLabel,
  storageContainersForMaterialsDestroyedToPreventAccessToContentsLabel,
  listOfServiceProvidersMaintainedLabel,
  writtenAgreementMaintainedLabel,
  establishedProcessForEngagingServicesProvidersLabel,
  programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnuallyLabel,
  informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntityLabel,
  incidentResponsePlanBeenCreatedInCaseOfSystemBreachLabel,
  YES_NO_YESCCW_NA_RADIO_OPTIONS,
} from 'constants/complianceConstants'

const ComplianceSelfAssessmentQuestionnaireAForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
}) => {
  return (
    <form className='ComplianceSelfAssessmentQuestionnaireAForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Section 2: Self-Assessment Questionnaire A</h1>
        <i><b>Note: </b>The following questions are numbered according to PCI DSS requirements and testing procedures, as defined in the PCI DSS Requirements and Security Assessment Procedures document.</i>
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Build and Maintain a Secure Network and Systems</h2>
        <h3>Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters</h3>

        <Field
          label={vendorSuppliedDefaultsChangedBeforeInstallOnNetworkLabel}
          name='vendorSuppliedDefaultsChangedBeforeInstallOnNetwork'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetworkLabel}
          name='unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Maintain a Vulnerability Management Program</h2>
        <h3>Requirement 6: Develop and maintain secure systems and applications</h3>

        <Field
          label={systemAndSoftwareProtectedFromKnownVulnerabilitiesLabel}
          name='systemAndSoftwareProtectedFromKnownVulnerabilities'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={criticalSecurityPatchesInstalledWithinOneMonthOfReleaseLabel}
          name='criticalSecurityPatchesInstalledWithinOneMonthOfRelease'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Implement Strong Access Control Measures</h2>
        <h3>Requirement 8: Identify and authenticate access to system components</h3>

        <Field
          label={usersAssignedUniqueIdBeforeAccessToSystemLabel}
          name='usersAssignedUniqueIdBeforeAccessToSystem'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={accessForTerminatedUsersRemovedLabel}
          name='accessForTerminatedUsersRemoved'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={authenticateUsersUsingPasswordOrTokenOrBiometricLabel}
          name='authenticateUsersUsingPasswordOrTokenOrBiometric'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={userPasswordParametersConfiguredToMeetReqsLabel}
          name='userPasswordParametersConfiguredToMeetReqs'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={groupSharedAccountsPasswordsAuthenticationMethodsProhibitedLabel}
          name='groupSharedAccountsPasswordsAuthenticationMethodsProhibited'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <h3>Requirement 9: Restrict physical access to cardholder data</h3>

        <Field
          label={allMediaPhysicallySecuredLabel}
          name='allMediaPhysicallySecured'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={strictControlOverDistributionOfMediaLabel}
          name='strictControlOverDistributionOfMedia'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={mediaClassifiedToDetermineSensitivityOfDataLabel}
          name='mediaClassifiedToDetermineSensitivityOfData'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={mediaSentCanBeAccuratelyTrackedLabel}
          name='mediaSentCanBeAccuratelyTracked'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={managementApprovalObtainedToMoveMediaLabel}
          name='managementApprovalObtainedToMoveMedia'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={strictControlMaintainedOverStorageAndAccessibilityOfMediaLabel}
          name='strictControlMaintainedOverStorageAndAccessibilityOfMedia'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={mediaDestroyedWhenNoLongerNeededLabel}
          name='mediaDestroyedWhenNoLongerNeeded'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={hardCopyMaterialsCrosscutShreddedIncineratedOrPulpedLabel}
          name='hardCopyMaterialsCrosscutShreddedIncineratedOrPulped'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={storageContainersForMaterialsDestroyedToPreventAccessToContentsLabel}
          name='storageContainersForMaterialsDestroyedToPreventAccessToContents'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />
      </div>

      <div className='section'>
        <h2 className='headerBorder'>Maintain an Information Security Policy</h2>
        <h3>Requirement 12: Maintain a policy that addresses information security for all personnel</h3>


        <Field
          label={listOfServiceProvidersMaintainedLabel}
          name='listOfServiceProvidersMaintained'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={writtenAgreementMaintainedLabel}
          name='writtenAgreementMaintained'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={establishedProcessForEngagingServicesProvidersLabel}
          name='establishedProcessForEngagingServicesProviders'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnuallyLabel}
          name='programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntityLabel}
          name='informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />

        <Field
          label={incidentResponsePlanBeenCreatedInCaseOfSystemBreachLabel}
          name='incidentResponsePlanBeenCreatedInCaseOfSystemBreach'
          component={RadioButton}
          options={YES_NO_YESCCW_NA_RADIO_OPTIONS}
          row
          buttonsRow
        />
      </div>

      {buttonGroup}
    </form>
  )
}

ComplianceSelfAssessmentQuestionnaireAForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_FORM,
  validate: validateComplianceSelfAssessmentQuestionnaireAForm,
})(ComplianceSelfAssessmentQuestionnaireAForm)
