/* eslint no-debugger: 0 */

import { take, put, fork } from 'redux-saga/effects'
import getRelatedResourcesO from 'state-layer/orchestration/get/getRelatedResourcesO'
import isEmpty from 'lodash/isEmpty'

const flowLoop = function * ({
  api,
  idKey,
  items,
  credentials,
  orchestration,
  frontendModel,
  reducer,
  relatedResourceSuccessF,
  updateSelf,
  condition,
}) {
  try {
    const { data } = yield orchestration({ credentials, items, idKey, api, frontendModel, reducer, condition })

    yield put({
      type: relatedResourceSuccessF,
      payload: {
        newValues: data,
        credentials,
        updateSelf,
      },
    })
  } catch (error) {
    // TODO: catch any errors
  }
}

const getRelatedResourcesF = ({
  api,
  idKey = '',
  frontendModel,
  reducer,
  resourceSuccessF,
  relatedResourceSuccessF,
  orchestration = getRelatedResourcesO,
  updateSelf,
  condition,
}) => {
  return function * () {
    while (true) {
      const {
        payload = {},
        meta = {},
      } = yield take(resourceSuccessF)

      const {
        newValues = {},
        credentials = {},
      } = payload

      const {
        skipRelated = false,
      } = meta

      if (!isEmpty(newValues) && !skipRelated) {
        yield fork(flowLoop, {
          api,
          idKey,
          credentials,
          orchestration,
          frontendModel,
          reducer,
          items: newValues,
          relatedResourceSuccessF,
          updateSelf,
          condition,
        })
      }
    }
  }
}

export default getRelatedResourcesF
