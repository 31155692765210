import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import validateCreateComplianceFormTemplateForm from 'utilities/validate/validateCreateComplianceFormTemplateForm'
import { CREATE_COMPLIANCE_FORM_TEMPLATE_FORM } from 'constants/formConstants'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_OPTIONS } from 'constants/complianceConstants'

const CreateComplianceFormTemplateForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  complianceFormTemplateId = '',
}) => {
  return (
    <form className='CreateComplianceFormTemplateForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h2>{complianceFormTemplateId ? 'Duplicate' : 'Create New'} Template</h2>

        <Field
          label='Name'
          name='name'
          component={InputField}
        />

        <Field
          label='Type'
          name='type'
          component={RadioButton}
          options={COMPLIANCE_FORM_TEMPLATE_TYPE_OPTIONS}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

CreateComplianceFormTemplateForm.propTypes = {
  complianceFormTemplateId: PropTypes.string,
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: CREATE_COMPLIANCE_FORM_TEMPLATE_FORM,
  validate: validateCreateComplianceFormTemplateForm,
})(CreateComplianceFormTemplateForm)
