import './AccessFormViewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import LiveAccountApplicationAdditionalDocumentsC from 'components/Customer/Pages/LiveAccountApplicationAdditionalDocuments/LiveAccountApplicationAdditionalDocumentsC'
import size from 'lodash/size'

import {
  ADDITIONAL_DOCUMENTS,
  ADDITIONAL_DOCUMENTS_EMPTY_MESSAGE,
  BANK_ACCOUNT_INFORMATION,
  BENEFICIAL_OWNERSHIP,
  BUSINESS_INFORMATION,
  CARD_VOLUME,
  CONTROL_PERSON,
  DETAILS, GENERAL_INFORMATION, PAYMENT_VOLUME_BY_BUSINESS_TYPE,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  PRICING_INFORMATION,
  PROCESSING_INFORMATION,
} from 'constants/language/languageConstants'

// this component is used for view access form data in the dashboard - for customer onboarding forms and access forms
const AccessFormView = ({
  detailsDataSection = [],
  pricingInfoDataSection = [],
  businessInfoDataSection = [],
  controlPersonInfoDataSection = [],
  beneficialOwnershipInfoDataSection = [],
  processingInfoDataSection = [],
  cardVolumeDataSection = [],
  paymentVolumeDataSection = [],
  bankAccountInfoDataSection = [],
  isCustomerOnboardingForm = false,
  additionalVerificationNeeded = false,
  accessFormId,
  additionalDocuments = [],
}) => {
  return (
    <div className='AccessFormView'>
      { !isCustomerOnboardingForm && (
        <PageSectionV2C
          smallTitle={DETAILS}
          columns={detailsDataSection}
        />
      )}

      { (additionalVerificationNeeded || size(additionalDocuments) > 0) && (
        <PageSectionV2C
          className='additional-documents-page-section'
          smallTitle={ADDITIONAL_DOCUMENTS}
        >
            {size(additionalDocuments) > 0 ? (
              <LiveAccountApplicationAdditionalDocumentsC accessFormId={accessFormId} />
            ) : <div className='empty-message'>{ADDITIONAL_DOCUMENTS_EMPTY_MESSAGE}</div>}
        </PageSectionV2C>
      )}

      {!isCustomerOnboardingForm && (
      <PageSectionV2C
        smallTitle={PRICING_INFORMATION}
        columns={pricingInfoDataSection}
      />
      )}

      <PageSectionV2C
        smallTitle={BUSINESS_INFORMATION}
        columns={businessInfoDataSection}
      />

      <PageSectionV2C
        smallTitle={CONTROL_PERSON}
        columns={controlPersonInfoDataSection}
        emptyMessage='No control person added.'
      />

      <PageSectionV2C
        smallTitle={BENEFICIAL_OWNERSHIP}
        columns={beneficialOwnershipInfoDataSection}
        className='beneficial-owners-section'
        emptyMessage='No beneficial owners added.'
      />

      <PageSectionV2C
        smallTitle={PROCESSING_INFORMATION}
      >
        <PageSectionV2C
          columns={processingInfoDataSection}
          smallTitle={GENERAL_INFORMATION}
          className='general-information-section'
        />
        <PageSectionV2C
          columns={cardVolumeDataSection}
          smallTitle={CARD_VOLUME}
          className='card-volume-section'
        />
        <PageSectionV2C
          columns={paymentVolumeDataSection}
          smallTitle={PAYMENT_VOLUME_BY_CUSTOMER_TYPE}
          className='payment-volume-section'
        />
      </PageSectionV2C>

      <PageSectionV2C
        smallTitle={BANK_ACCOUNT_INFORMATION}
        columns={bankAccountInfoDataSection}
      />
    </div>
  )
}

AccessFormView.propTypes = {
  detailsDataSection: PropTypes.array,
  pricingInfoDataSection: PropTypes.array,
  businessInfoDataSection: PropTypes.array,
  controlPersonInfoDataSection: PropTypes.array,
  beneficialOwnershipInfoDataSection: PropTypes.array,
  processingInfoDataSection: PropTypes.array,
  cardVolumeDataSection: PropTypes.array,
  paymentVolumeDataSection: PropTypes.array,
  bankAccountInfoDataSection: PropTypes.array,
  isCustomerOnboardingForm: PropTypes.bool,
  additionalVerificationNeeded: PropTypes.bool,
  accessFormId: PropTypes.string,
  additionalDocuments: PropTypes.string,
}

export default AccessFormView
