import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewIdentityV1 from './ReviewIdentityV1'
import getReviewQueueItemRequest from 'utilities/actions/get/getReviewQueueItemRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import patchReviewQueueItemRequest from 'utilities/actions/patch/patchReviewQueueItemRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  getReviewQueueSelector,
  getReviewQueueItemSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const creatorEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const reviewQueueIdentities = getReviewQueueSelector(state)
  const reviewQueueId = get(props, 'reviewQueueId') || get(props, 'params.reviewQueueId')
  const isFetching = get(state, `reviewQueueR.${FETCHING}`)
  const reviewQueueItem = getReviewQueueItemSelector(state, reviewQueueId)

  // TODO: use connector for this
  const [
    identity,
    defaultStatementDescriptor,
    amexMid,
    mcc,
    displayMaxTransactionAmount,
    displayAnnualCardVolume,
    hasAcceptedCreditCardsPreviously,
    identityTags,
    paymentInstruments,
    verifications,
    associatedIdentities,
    additionalUnderwritingData,
  ] = getMany(reviewQueueItem, [
    'identity',
    'identity.defaultStatementDescriptor',
    'identity.amexMid',
    'identity.mcc',
    'identity.displayMaxTransactionAmount',
    'identity.displayAnnualCardVolume',
    'identity.hasAcceptedCreditCardsPreviously',
    'identity.tags',
    'paymentInstruments',
    'verifications',
    'associatedIdentities',
    'identity.additionalUnderwritingData',
  ])

  const processingInfo = [
    {
      label: 'Default statement descriptor',
      value: defaultStatementDescriptor,
    },
    {
      label: 'American Express MID',
      value: amexMid,
    },
    {
      label: 'Max transaction amount',
      value: displayMaxTransactionAmount,
    },
    {
      label: 'Merchant category code',
      value: mcc,
    },
    {
      label: 'Annual card volume',
      value: displayAnnualCardVolume,
    },
    {
      label: 'Principal percentage ownership',
      value: hasAcceptedCreditCardsPreviously,
    },
  ]

  const headerTitle = get(identity, 'businessName')
  const subTitle = get(identity, 'displayCreatedAt')
  const identityTagsSectionData = map(identityTags, (value, label) => ({ value, label }))

  return {
    credentials,
    headerTitle,
    subTitle,
    creatorEmail,
    reviewQueueId,
    isFetching,
    reviewQueueItem,
    identity,
    processingInfo,
    identityTagsSectionData,
    paymentInstruments,
    verifications,
    credentialId,
    associatedIdentities,
    reviewQueueIdentities,
    additionalUnderwritingData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewQueueItem: ({ reviewQueueId, credentials }) => dispatch(getReviewQueueItemRequest({
      reviewQueueId, credentials,
    })),
    patchReviewQueueItem: ({ items, credentials }) => dispatch(patchReviewQueueItemRequest({
      items, credentials,
    })),
  }
}

class ReviewIdentityV1C extends Component {
  componentDidMount() {
    const {
      credentials,
      reviewQueueId,
      getReviewQueueItem,
    } = this.props
    getReviewQueueItem({ reviewQueueId, credentials })
  }

  updateStatusToPending = ({ creatorEmail }) => {
    this.updateSelectedIdentityStatus({ status: 'PENDING', assignee: creatorEmail })
  }

  updateSelectedIdentityStatus = ({ status, assignee }) => {
    const { credentials, reviewQueueItem, creatorEmail, patchReviewQueueItem } = this.props
    const id = get(reviewQueueItem, 'id')

    const tags = assignee ? { assigned_to: assignee } : { decision_reason: null, decision_by: creatorEmail }
    const application = get(reviewQueueItem, 'applicationId')

    const items = removeUndefined({
      id,
      tags,
      application,
      outcome: status,
    })

    patchReviewQueueItem({ items, credentials })
  }

  updateStatusToPending = () => {
    const { creatorEmail } = this.props
    this.updateSelectedIdentityStatus({ assignee: creatorEmail })
  }

  render() {
    return (
      <ReviewIdentityV1
        {...this.props}
        updateStatusToPending={this.updateStatusToPending}
        updateStatus={this.updateSelectedIdentityStatus}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewIdentityV1C)
