import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SHOW_NOTIFICATIONS } from 'constants/actionConstants'
import { OPEN_NOTIFICATIONS } from 'constants/amplitudeConstants'

const showNotificationsRequest = ({
  credentials,
}) => {
  return createAction({
    type: SHOW_NOTIFICATIONS,
    meta: {
      actionId: sendAmplitudeActionEvent(OPEN_NOTIFICATIONS, {
        credentials,
      }),
    },
  })
}

export default showNotificationsRequest
