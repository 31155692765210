import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { MERCHANT_COMPLIANCE_FORMS } from 'constants/linkConstants'
import { GET_MERCHANT_COMPLIANCE_FORMS } from 'constants/flowConstants'
import { COMPLIANCE_FORMS_ICON } from 'constants/iconConstants'
import { COMPLIANCE_FORMS_EMPTY_MESSAGE } from 'constants/language/languageConstants'

const MerchantComplianceForms = ({
  complianceFormsPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  merchantId = '',
  quickFilters,
  initialFilters,
  columnDescriptors = [],
  actions = [],
  complianceForms = [],
}) => {
  return (
    <div className='MerchantComplianceForms'>
      <TableC
        flow={GET_MERCHANT_COMPLIANCE_FORMS}
        columnDescriptors={columnDescriptors}
        data={complianceForms}
        initialQueries={initialQueries}
        linksKey={MERCHANT_COMPLIANCE_FORMS}
        allowedFilters={allowedFilters}
        path={complianceFormsPath}
        isFetching={isFetching}
        emptyMessage={COMPLIANCE_FORMS_EMPTY_MESSAGE}
        icon={`fa fa-${COMPLIANCE_FORMS_ICON}`}
        fileNameCSV={`Merchant_${merchantId}_Compliance_Forms`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        actions={actions}
      />
    </div>
  )
}

MerchantComplianceForms.propTypes = {
  complianceFormsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
  columnDescriptors: PropTypes.array,
  actions: PropTypes.array,
  complianceForms: PropTypes.array,
}

export default MerchantComplianceForms
