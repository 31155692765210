import frontendAPILogModel from './frontendAPILogModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendAPILogsModel = ({ data: apiLogs }) => {
  const apiLogModels = map(apiLogs, (apiLog) => frontendAPILogModel({ data: apiLog }))

  return keyBy(apiLogModels, 'id')
}

export default frontendAPILogsModel
