import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditFeeProfileSettingsForm from './EditFeeProfileSettingsForm'
import { getFeeProfileRuleSelector, getFeeProfileSettingSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { EDIT_FEE_PROFILE_SETTINGS_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import get from 'lodash/get'

const editModalTitleMap = {
  enforceLimitsForFeeProfiles: {
    true: 'Stop enforcing fee profile limits?',
    false: 'Enforce fee profile limits?',
  },
  canAssignFeeProfiles: {
    true: 'Disable application from assigning fee profiles?',
    false: 'Enable application to assign fee profiles?',
  },
  canCreateFeeProfiles: {
    true: 'Disable application from creating fee profiles?',
    false: 'Enable application to create fee profiles?',
  },
}

const editModalTextMap = {
  enforceLimitsForFeeProfiles: {
    true: 'If you disable enforcing fee profile limits, no restrictions will be applied when creating or assigning fee profiles.',
    false: 'If you enforce fee profile limits, restrictions will be applied when creating or assigning fee profiles.',
  },
  canAssignFeeProfiles: {
    true: 'Application will not be able to assign fee profile for merchants. Changes made to the fee profiles of pre-existing applications will be unaffected.',
    false: 'Application will be able to assign fee profile for merchants. Changes made to the fee profiles of pre-existing applications will be unaffected.',
  },
  canCreateFeeProfiles: {
    true: 'Application will not be able to create fee profile for merchants. Changes made to the fee profiles of pre-existing applications will be unaffected.',
    false: 'Application will be able to create fee profile for merchants. Changes made to the fee profiles of pre-existing applications will be unaffected.',
  },
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetchingSettings = get(state, `feeProfileSettingsR.${FETCHING}`, true)
  const entityId = get(props, 'entityId')
  const feeProfileSettings = getFeeProfileSettingSelector(state, entityId)
  const hasNoFeeProfileSettings = isEmpty(feeProfileSettings)
  const feeProfileRules = getFeeProfileRuleSelector(state, entityId)
  const disableEnforceLimitsToggleUsd = !get(feeProfileRules, 'BLENDED.USD', false) && !get(feeProfileRules, 'INTERCHANGE_PLUS.USD', false)
  const disableEnforceLimitsToggleCad = !get(feeProfileRules, 'BLENDED.CAD', false) && !get(feeProfileRules, 'INTERCHANGE_PLUS.CAD', false)

  const initialValues = isFetchingSettings ? undefined : {
    // TODO: add back as part of FE-4225
    // CAD: {
    //   enforceLimitsForFeeProfiles: get(feeProfileSettings, 'CAD.enforceLimitsForFeeProfiles', false),
    //   canAssignFeeProfiles: get(feeProfileSettings, 'CAD.canAssignFeeProfiles', false),
    //   canCreateFeeProfiles: get(feeProfileSettings, 'CAD.canCreateFeeProfiles', false),
    // },
    USD: {
      enforceLimitsForFeeProfiles: get(feeProfileSettings, 'USD.enforceLimitsForFeeProfiles', false),
      canAssignFeeProfiles: get(feeProfileSettings, 'USD.canAssignFeeProfiles', false),
      canCreateFeeProfiles: get(feeProfileSettings, 'USD.canCreateFeeProfiles', false),
    },
  }

  const currentFeeProfileSettings = get(state, 'form.EDIT_FEE_PROFILE_SETTINGS_FORM.values')

  return {
    credentials,
    initialValues,
    currentFeeProfileSettings,
    hasNoFeeProfileSettings,
    disableEnforceLimitsToggleUsd,
    disableEnforceLimitsToggleCad,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showEditFeeProfileSettingsModal: (modalProps) => dispatch(showModalAction({
      modalType: EDIT_FEE_PROFILE_SETTINGS_MODAL,
      modalProps: { canCloseModal: false, ...modalProps },
      className: 'modal-md no-pad',
    })),
  }
}

class EditFeeProfileSettingsFormC extends Component {
  render() {
    const toggleSwitchAction = (name) => {
      const {
        showEditFeeProfileSettingsModal,
        currentFeeProfileSettings,
        hasNoFeeProfileSettings,
        entityId,
      } = this.props

      const currency = split(name, '.')[0]
      const fieldName = split(name, '.')[1]
      const currentFieldValue = currentFeeProfileSettings[currency][fieldName]
      const modalTitle = editModalTitleMap[fieldName][currentFieldValue]
      const modalText = editModalTextMap[fieldName][currentFieldValue]
      const buttonText = currentFieldValue ? 'Disable' : 'Enable'
      const buttonVariant = currentFieldValue ? 'destructive' : 'primary'

      showEditFeeProfileSettingsModal({
        modalTitle,
        modalText,
        buttonText,
        buttonVariant,
        currency,
        fieldName,
        currentFeeProfileSettings,
        hasNoFeeProfileSettings,
        applicationId: entityId,
      })
    }

    return (
      <EditFeeProfileSettingsForm
        {...this.props}
        toggleSwitchAction={toggleSwitchAction}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeeProfileSettingsFormC)
