import { goToPath } from 'state-layer/history'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import { GUEST_PAYMENT_LINK_CONFIRMATION } from 'constants/pathConstants'
import { CREATE_PAYMENT_LINK_PURCHASE } from 'constants/amplitudeConstants'
import isEmpty from 'lodash/isEmpty'
import sample from 'lodash/sample'
import split from 'lodash/split'
import get from 'lodash/get'

import {
  SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
  YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
} from 'constants/language/languageConstants'

import {
  POST_PURCHASE_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
  SHOW_SUCCESS_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

const submitGuestPaymentLinkForm = (values, dispatch, props = {}) => {
  const { paymentLink } = props

  const [
    id,
    merchantId,
    applicationId,
    amountType,
    totalAmount,
    currency,
    paymentFrequency,
    successReturnUrl,
    unsuccessfulReturnUrl,
    sendReceipt,
  ] = getMany(paymentLink, [
    'id',
    'merchantId',
    'applicationId',
    'amountType',
    'totalAmount',
    'currency',
    'paymentFrequency',
    'successReturnUrl',
    'unsuccessfulReturnUrl',
    'sendReceipt',
  ])

  const {
    name,
    email,
    phone,
    token,
    saleAmount,
    shippingAddress,
    tags,
    receiptEmails,
    receiptPhoneNumbers,
  } = values

  const isFixedAmount = amountType === 'FIXED'
  const firstName = split(name, ' ')[0] || ''
  const lastName = split(name, ' ')[1] || ''

  const valuesToSubmit = {
    merchantId,
    applicationId,
    amount: isFixedAmount ? totalAmount : saleAmount,
    currency,
    paymentFrequency,
    token,
    entityId: id,
    entityType: 'PAYMENT_LINK',
    firstName,
    lastName,
    email,
    phone,
    shippingAddress,
    tags,
    receiptEmails: sendReceipt && isEmpty(receiptEmails) ? [{ label: email, value: email }] : receiptEmails,
    receiptPhoneNumbers,
  }

  dispatch({
    type: POST_PURCHASE_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues: purchases }) => {
        const purchase = sample(purchases) || {}
        const state = get(purchase, 'state')

        if (state === 'FAILED') {
          const failureCode = get(purchase, 'failureCode')

          dispatch({
            type: SHOW_ERROR_FLASH_NOTIFICATION,
            payload: {
              values: {
                message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
              },
            },
            meta: {
              actionId: sendAmplitudeActionEvent(CREATE_PAYMENT_LINK_PURCHASE, {
                paymentLinkId: id,
                merchantId,
                applicationId,
                amountType,
                currency,
                paymentFrequency,
                state: 'FAILED',
              }),
            },
          })

          if (unsuccessfulReturnUrl) {
            setTimeout(() => {
              window.location.href = unsuccessfulReturnUrl
            }, 1000)
          } else {
            goToPath({
              pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
              queries: {
                status: 'failure',
                failureCode,
              },
            })
          }
        } else {
          dispatch({
            type: SHOW_SUCCESS_FLASH_NOTIFICATION,
            payload: {
              values: {
                message: YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
              },
            },
            meta: {
              actionId: sendAmplitudeActionEvent(CREATE_PAYMENT_LINK_PURCHASE, {
                paymentLinkId: id,
                merchantId,
                applicationId,
                amountType,
                currency,
                paymentFrequency,
                state: 'SUCCESS',
              }),
            },
          })

          if (successReturnUrl) {
            setTimeout(() => {
              window.location.href = successReturnUrl
            }, 1000)
          } else {
            setTimeout(() => {
              goToPath({
                pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
                queries: {
                  status: 'success',
                },
              })
            }, 1000)
          }
        }
      },
      errorCallback: () => {
        dispatch({
          type: SHOW_ERROR_FLASH_NOTIFICATION,
          payload: {
            values: {
              message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
            },
          },
          meta: {
            actionId: sendAmplitudeActionEvent(CREATE_PAYMENT_LINK_PURCHASE, {
              paymentLinkId: id,
              merchantId,
              applicationId,
              amountType,
              currency,
              paymentFrequency,
              state: 'ERROR',
            }),
          },
        })

        if (unsuccessfulReturnUrl) {
          setTimeout(() => {
            window.location.href = unsuccessfulReturnUrl
          }, 1000)
        } else {
          goToPath({
            pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
            queries: {
              status: 'failure',
            },
          })
        }
      },
    },
  })
}

export default submitGuestPaymentLinkForm
