import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import GenericForm from 'components/Customer/Forms/GenericForm/GenericForm'
import { getAvailableProcessorInputsSection } from 'constants/processorConstants'
import { ENABLE_PROCESSORS_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'

const EnableProcessorsForm = ({
  submitting = false,
  submitLabel = 'Create',
  handleSubmit = () => {},
  sections = [],
  renderHeader,
  formName = '',
  disabled = false,
  leftButton = false,
  leftButtonLabel = 'Back',
  leftButtonAction = () => {},
  isStandaloneUnderwriting = false,
}) => {
  const displaySections = !isEmpty(sections) ? sections : getAvailableProcessorInputsSection({ isStandaloneUnderwriting })

  return (
    <GenericForm
      handleSubmit={handleSubmit}
      sections={displaySections}
      submitLabel={submitLabel}
      submitting={submitting}
      renderHeader={renderHeader}
      formName={formName}
      disabled={disabled}
      leftButton={leftButton}
      leftButtonLabel={leftButtonLabel}
      leftButtonAction={leftButtonAction}
    />
  )
}

EnableProcessorsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  sections: PropTypes.array,
  renderHeader: PropTypes.func,
  formName: PropTypes.string,
  disabled: PropTypes.bool,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  isStandaloneUnderwriting: PropTypes.bool,
}

export default reduxForm({
  form: ENABLE_PROCESSORS_FORM,
  enableReinitialize: true,
})(EnableProcessorsForm)
