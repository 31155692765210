import getMerchantUnderwritingSubjectEventAPI from 'api/finix/get/getMerchantUnderwritingSubjectEventAPI'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import get from 'lodash/get'
import merge from 'lodash/merge'
import co from 'co'

function * getUnderwritingEventO ({
  id,
  credentials,
  meta,
}) {
  const identityId = get(meta, 'identityId')
  const { data: response } = yield getMerchantUnderwritingSubjectEventAPI({ id, credentials, meta })

  const { data: merchantIdentity } = yield getIdentityAPI({ id: identityId, credentials })
  const merchantIdentityModel = frontendIdentityModel({ data: merchantIdentity })

  const applicationId = get(merchantIdentityModel, 'application')
  const { data: applicationResponse } = yield getApplicationAPI({ id: applicationId, credentials })
  const applicationModel = frontendApplicationModel({ data: applicationResponse })

  const underwritingEvent = merge({}, response, {
    identity: merchantIdentityModel,
    application: applicationModel,
  })

  return createSuccessResponse({
    data: [underwritingEvent],
  })
}

export default co.wrap(getUnderwritingEventO)
