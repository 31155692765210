import './ReviewStatusS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import ActionButtonsDropdown from 'components/Shared/ActionButtonsDropdown/ActionButtonsDropdown'
import getMany from 'utilities/get/getMany'
import includes from 'lodash/includes'
import { isFlexPlatform } from 'constants/flexConstants'

import {
  ACCEPTED,
  REJECTED,
  WITHDRAWN,
  ADDITIONAL_VERIFICATION_NEEDED,
} from 'constants/reviewQueueConstants'

import {
  CHECK_ICON,
  REMOVE_ICON,
  ADDITIONAL_VERIFICATION_NEEDED_ICON,
  WITHDRAW_ICON,
} from 'constants/iconConstants'

const ReviewStatus = ({
  data = {},
  extraProps = {},
}) => {
  const [
    isFetching,
    status,
    decisionBy,
    updateStatus,
  ] = getMany(data, [
    'isFetching',
    'status',
    'decisionBy',
    'updateStatus',
  ])

  const [
    showReviewActionsModal,
    toggleShowOtherActionButtonsDropdown,
    showOtherActionButtonsDropdown,
    actionList,
    handleCloseActionButtonsDropdown,
    hideOtherActions,
    underwritingEnabled = true,
    disableReviewActions = false,
  ] = getMany(extraProps, [
    'showReviewActionsModal',
    'toggleShowOtherActionButtonsDropdown',
    'showOtherActionButtonsDropdown',
    'actionList',
    'handleCloseActionButtonsDropdown',
    'hideOtherActions',
    'underwritingEnabled',
    'disableReviewActions',
  ])

  const isDisabled = includes([ACCEPTED, REJECTED, WITHDRAWN], status) || disableReviewActions
  const disabledClassname = isDisabled ? 'disabled' : ''
  const isFlex = isFlexPlatform()
  const approvedMessage = decisionBy ? <>Approved by <div className='bold'>{decisionBy}</div></> : 'Approved'
  const rejectedMessage = decisionBy ? <>Rejected by <div className='bold'>{decisionBy}</div></> : 'Rejected'
  const showMoreInfoRequiredByMessage = status === ADDITIONAL_VERIFICATION_NEEDED && decisionBy && (!hideOtherActions || isFlex)

  const classNames = classnames('ReviewStatus flex column', {
    'disable-review-actions': !!disableReviewActions,
  })

  return isFetching ? (
    <Loader />
  ) : (
    <div className={classNames}>
      {status !== ACCEPTED && (
        <Button
          label='Approve'
          rightIcon={CHECK_ICON}
          disabled={isDisabled}
          className={`${disabledClassname} approve-button`}
          onClick={underwritingEnabled ? () => showReviewActionsModal({ action: 'Approve' }) : () => updateStatus({ status: ACCEPTED })}
        />
      )}

      {status === ACCEPTED && <div className='approved'>{approvedMessage}</div> }

      {status !== REJECTED && (
        <Button
          label='Reject'
          rightIcon={REMOVE_ICON}
          disabled={isDisabled}
          className={`${disabledClassname} reject-button`}
          onClick={underwritingEnabled ? () => showReviewActionsModal({ action: 'Reject' }) : () => updateStatus({ status: REJECTED })}
          variant='destructive'
        />
      )}

      {status === REJECTED && <div className='rejected'>{rejectedMessage}</div>}

      {isFlex && (
        <Button
          label='Additional Verification Needed'
          disabled={isDisabled}
          className={`${disabledClassname} additional-verification-needed-button`}
          onClick={underwritingEnabled ? () => showReviewActionsModal({ action: 'Additional Verification Needed' }) : () => updateStatus({ status: ADDITIONAL_VERIFICATION_NEEDED })}
        />
      )}

      {showMoreInfoRequiredByMessage && (<div className='withdrawn'>More information required by <div className='bold'>{decisionBy}</div></div>)}

      {isFlex && (
        <Button
          label='Withdraw'
          disabled={isDisabled}
          className={`${disabledClassname} withdraw-button`}
          variant='secondary'
          onClick={underwritingEnabled ? () => showReviewActionsModal({ action: 'Withdraw' }) : () => updateStatus({ status: WITHDRAWN })}
        />
      )}

      {status === WITHDRAWN && (!hideOtherActions || isFlex) && (<div className='withdrawn'>Withdrawn by <div className='bold'>{decisionBy}</div></div>)}

      {!hideOtherActions && !isFlex && (
        <ActionButtonsDropdown
          className='other-actions-button'
          label='Other Actions'
          toggleShowActionsDropdown={toggleShowOtherActionButtonsDropdown}
          showActionsDropdown={showOtherActionButtonsDropdown}
          handleCloseDropdowns={handleCloseActionButtonsDropdown}
          actionList={actionList}
          disabledActions={isDisabled}
        />
      )}
    </div>
  )
}

ReviewStatus.propTypes = {
  data: PropTypes.object,
  extraProps: PropTypes.object,
}

export default ReviewStatus
