import React, { Component } from 'react'
import { connect } from 'react-redux'
import CloseSettlementForm from 'components/Customer/Forms/CloseSettlementForm/CloseSettlementForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)

  return {
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CloseSettlementFormC extends Component {
  render() {
    return (
      <CloseSettlementForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseSettlementFormC)
