import paymentsAPI from 'utilities/api/paymentsAPI'
import { IDENTITIES } from 'constants/apiConstants'

const getIdentitiesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: IDENTITIES,
    queries,
    credentials,
    service: IDENTITIES,
  })
}

export default getIdentitiesAPI
