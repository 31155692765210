import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_ASSOCIATED_IDENTITIES,
} from 'constants/apiConstants'

const getApplicationAssociatedIdentitiesAPI = ({ queries, values, credentials, meta }) => {
  const {
    identityId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: APPLICATION_ASSOCIATED_IDENTITIES({ identityId }),
    queries,
    service: APPLICATIONS,
  })
}

export default getApplicationAssociatedIdentitiesAPI
