import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFER_ATTEMPT,
  TRANSFER_ATTEMPTS,
} from 'constants/apiConstants'

const patchTransferAttemptAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    path: TRANSFER_ATTEMPT({ transferAttemptId: id }),
    values,
    meta,
    credentials,
    service: TRANSFER_ATTEMPTS,
  })
}

export default patchTransferAttemptAPI
