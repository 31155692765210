import * as moment from 'moment'
import 'moment-timezone'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import CreateSubscriptionEnrollmentForm from 'components/Customer/Forms/CreateSubscriptionEnrollmentForm/CreateSubscriptionEnrollmentForm'
import { CREATE_SUBSCRIPTION_ENROLLMENT } from 'constants/formConstants'
import replace from 'lodash/replace'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(CREATE_SUBSCRIPTION_ENROLLMENT)
  const enrollmentStaredAt = formSelector(state, 'startedAt')
  const localTimezone = replace(moment.tz.guess(), '_', ' ')

  return {
    enrollmentStaredAt,
    localTimezone,
  }
}

class CreateSubscriptionEnrollmentFormC extends Component {
  componentDidUpdate(prevProps) {
    const { enrollmentStaredAt: prevEnrollmentStaredAt } = prevProps
    const { enrollmentStaredAt, dispatch } = this.props

    if (prevEnrollmentStaredAt && prevEnrollmentStaredAt !== enrollmentStaredAt) {
      dispatch(change(CREATE_SUBSCRIPTION_ENROLLMENT, 'endedAt', null))
    }
  }

  render() {
    return (
      <CreateSubscriptionEnrollmentForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateSubscriptionEnrollmentFormC)
