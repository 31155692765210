import {
  FINIX_LOGO_BLACK,
  FINIX_NAV_BAR_LOGO,
} from 'constants/logoConstants'

import {
  DUMMY_V1,
  LITLE_V1,
  VANTIV_V1,
  VISA_V1,
  MICROBILT_V1,
} from 'constants/processorConstants'

function registrationLink(route, text) {
  return { text, route }
}

function pair(label, value) {
  if (value === undefined) {
    return { label, disabled: true }
  }

  return { label, value }
}

const BULK_ACTIONS = [
  pair('Bulk Actions'),
  pair('Assign to me', 'ASSIGN-SELF'),
  pair('Reject (MATCH_LIST)', 'REJECTED-MATCH_LIST'),
  pair('Reject (HIGH_RISK)', 'REJECTED-HIGH_RISK'),
  pair('Reject (INSUFFICIENT_HISTORY)', 'REJECTED-INSUFFICIENT_HISTORY'),
  pair('Reject (FRAUD)', 'REJECTED-FRAUD'),
  pair('Reject (PROHIBITED)', 'REJECTED-PROHIBITED'),
  pair('Reject (MULTIPLE_FACTORS)', 'REJECTED-MULTIPLE_FACTORS'),
  pair('Reject (LOW_FICO_SCORE)', 'REJECTED-LOW_FICO_SCORE'),
  pair('Reject (POOR_PROCESSING_HISTORY)', 'REJECTED-POOR_PROCESSING_HISTORY'),
  pair('Reject (VIOLATES_CHARGEBACK_THRESHOLD)', 'REJECTED-VIOLATES_CHARGEBACK_THRESHOLD'),
  pair('Reject (VIOLATES_RETURNS_THRESHOLD)', 'REJECTED-VIOLATES_RETURNS_THRESHOLD'),
  pair('Reject (VIOLATES_UNDERWRITING_REQUIREMENTS)', 'REJECTED-VIOLATES_UNDERWRITING_REQUIREMENTS'),
  pair('Approve', 'ACCEPTED'),
]

export const DATA = {
  id: 'DASH8m9zZ8UhPzkHHmWjosVKwY',
  credentialsHost: 'https://finix-qa-dashboard.herokuapp.com',
  credentialsHostV2: 'https://qa-new.paymentsdashboard.io',
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'VANTIV_V1', 'FINIX_V1'],
  tokenizationLibraryUrl: 'https://forms.finixpymnts.com/finix.js',
  applicationLogoImage: FINIX_NAV_BAR_LOGO,
  auth0: {
    clientID: '2jOpN7fwuf6QsnXx2qqyOtbvIbUZUdA6',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    logo: FINIX_LOGO_BLACK,
  },
  registrationSteps: [
    registrationLink('account/registrations/edit/index', 'Identity Information'),
    registrationLink('account/registrations/edit/bank-accounts', 'Bank Account'),
    registrationLink('account/registrations/edit/associated-identities', 'Associated Identities'),
    registrationLink('account/registrations/edit/processors', 'Processors'),
    registrationLink('account/registrations/edit/api-keys', 'API Keys'),
  ],
  'applications/index/newApplicationLinkName': 'registrations/new',
  'onboarding/identities/bulkActions': BULK_ACTIONS,
  'onboarding/merchants/bulkActions': BULK_ACTIONS,
  dashboardServiceV2: true,
}
