import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  SETTLEMENT_ENTRIES,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getSettlementEntriesAPI = ({ queries, values, credentials, id, meta }) => {
  const settlementId = id || get(values, 'settlementId')

  return paymentsAPI.get({
    meta,
    credentials,
    path: SETTLEMENT_ENTRIES({ settlementId }),
    queries,
    service: SETTLEMENTS,
  })
}

export default getSettlementEntriesAPI
