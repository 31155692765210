import getRelatedResourcesF from 'state-layer/flows/get/getRelatedResourcesF'
import relatedResourceFlows from 'state-layer/flows/relatedResourceFlows'
import map from 'lodash/map'

const generateRelatedResourcesFlows = map(relatedResourceFlows, (properties) => {
  const {
    api,
    idKey,
    frontendModel,
    reducer,
    orchestration,
    resourceSuccessF,
    relatedResourceSuccessF,
    updateSelf = false,
    condition = () => true,
  } = properties

  return getRelatedResourcesF({
    api,
    idKey,
    frontendModel,
    reducer,
    orchestration,
    resourceSuccessF,
    relatedResourceSuccessF,
    updateSelf,
    condition,
  })
})

export default generateRelatedResourcesFlows
