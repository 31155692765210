import { getDispatch } from 'state-layer/configureStore'
import createAction from 'utilities/actions/createAction'
import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_TRANSFER_REVERSAL_F_REQUEST } from 'constants/flowConstants'
import { CREATE_REFUND } from 'constants/amplitudeConstants'
import { PAYMENT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const postCreateRefundRequest = ({
  credentials,
  transferId,
  refundAmount,
  selectedSplitRefundExperience,
  splitRefundAmountSum,
  closeModal = () => {},
  valuesToSubmit = {},
}) => createAction({
  credentials,
  type: POST_TRANSFER_REVERSAL_F_REQUEST,
  id: transferId,
  values: valuesToSubmit,
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_REFUND, {
      credentials,
      transferId,
      refundAmount: selectedSplitRefundExperience ? splitRefundAmountSum : refundAmount,
    }),
    successCallback: () => {
      const dispatch = getDispatch()
      const paymentPath = PAYMENT_PATH({ transferId, credentialId: get(credentials, 'id') })
      dispatch(redirectRequest({ path: paymentPath }))
      closeModal()
    },
  },
})

export default postCreateRefundRequest
