import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import convertToMilitaryTime from 'utilities/convert/convertToMilitaryTime'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import redirectRequest from 'utilities/actions/redirectRequest'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import { USD } from 'constants/currencyConstants'
import { ALL } from 'constants/transferConstants'
import { GLOBAL } from 'constants/disbursementsConstants'
import { PATCH_DISBURSEMENT_RULES_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_VELOCITY_LIMITS } from 'constants/amplitudeConstants'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  APPLICATION_PATH,
  COMPANY_PATH,
} from 'constants/pathConstants'

import {
  DAILY,
  MONTHLY,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

import {
  CARD,
  APPLICATION,
} from 'constants/language/languageConstants'

const submitUpdateVelocityLimitsForm = (values, dispatch, props) => {
  const {
    entityType,
    limitType,
    credentials,
    disbursementRuleId,
    applicationId,
    platformId,
  } = props

  const credentialId = get(credentials, 'id')
  const linkedTo = applicationId || platformId
  const applicationEntityType = isEqual(entityType, APPLICATION)
  let redirectPath = COMPANY_PATH({ credentialId })

  if (applicationId) {
    redirectPath = applicationEntityType ? `${APPLICATION_PATH({ credentialId, applicationId })}?tab=velocity-info` :
      `${APPLICATION_PATH({ credentialId, applicationId })}?tab=velocity-info&secondaryTab=recipient-limits`
  }

  const detailsPath = isEqual(entityType, APPLICATION) ? 'application_details' : 'recipient_details'
  const rulesPath = isEqual(limitType, CARD) ? 'card_rules' : 'ach_rules'

  const [
    dailyStartTimeValue,
    dailyTimeMeridianValue,
    dailyCountValue,
    dailyVolumeValue,
    monthlyDateValue,
    monthlyStartTimeValue,
    monthlyTimeMeridianValue,
    monthlyCountValue,
    monthlyVolumeValue,
    maxTransactionAmountValue,
  ] = getMany(values, [
    'dailyStartTime.value',
    'dailyTimeMeridian.value',
    'dailyCount',
    'dailyVolume',
    'monthlyDate.value',
    'monthlyStartTime.value',
    'monthlyTimeMeridian.value',
    'monthlyCount',
    'monthlyVolume',
    'maxTransactionAmount',
  ])

  const valuesObject = {
    [detailsPath]: {
      [rulesPath]: [
        {
          currency: USD,
          type: GLOBAL,
          max_transaction_amount: normalizeBackendAmount(maxTransactionAmountValue),
        },
        {
          currency: USD,
          time_frame: DAILY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: dailyStartTimeValue, meridian: dailyTimeMeridianValue }),
          rules: [
            {
              day: ALL,
              count_limit: convertAmountStringToNumber({ amount: dailyCountValue }),
              volume_limit: normalizeBackendAmount(dailyVolumeValue),
              enabled: true,
            },
          ],
        },
        {
          currency: USD,
          time_frame: MONTHLY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: monthlyStartTimeValue, meridian: monthlyTimeMeridianValue }),
          start_date: monthlyDateValue,
          rules: [
            {
              count_limit: convertAmountStringToNumber({ amount: monthlyCountValue }),
              volume_limit: normalizeBackendAmount(monthlyVolumeValue),
              enabled: true,
            },
          ],
        },
      ],
    },
  }

  const valuesToSubmit = {
    linked_to: linkedTo,
    ...valuesObject,
  }

  dispatch({
    type: PATCH_DISBURSEMENT_RULES_F_REQUEST,
    payload: {
      id: disbursementRuleId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch(redirectRequest({ path: redirectPath }))
      },
      actionId: sendAmplitudeActionEvent(UPDATE_VELOCITY_LIMITS, {
        credentials,
        applicationId,
      }),
    },
  })
}

export default submitUpdateVelocityLimitsForm
