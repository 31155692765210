import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import { PATCH_TRANSFER_ATTEMPT_F_REQUEST } from 'constants/flowConstants'

import {
  RECIPIENT_DETAILS,
  PAYOUT_LINK_DETAILS,
} from 'constants/language/languageConstants'

const TransferAttempt = ({
  isFetching = false,
  contextBarData = {},
  headerData = {},
  recipientIdentity = {},
  paymentInstrument = {},
  tagsSectionData = {},
  payoutLinkId = '',
  payoutLinkPath,
  payoutLinkDetailsData = [],
}) => {
  return (
    <div className='TransferAttempt'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      <BuyerDetailsC
        title={RECIPIENT_DETAILS}
        buyerIdentity={recipientIdentity}
        isFetching={isFetching}
      />

      {payoutLinkId && (
        <PageSectionV2C
          title={PAYOUT_LINK_DETAILS}
          columns={payoutLinkDetailsData}
          isFetching={isFetching}
          clickToCopies={[{ content: payoutLinkId }]}
          asideTitle={payoutLinkPath}
          className='payment-link-details-section'
        />
      )}

      <PaymentInstrumentDetailsC
        isFetching={isFetching}
        paymentInstrument={paymentInstrument}
      />

      <TagsPageSectionV2
        isFetching={isFetching}
        actionType={PATCH_TRANSFER_ATTEMPT_F_REQUEST}
        data={tagsSectionData}
      />
    </div>
  )
}

TransferAttempt.propTypes = {
  isFetching: PropTypes.bool,
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  recipientIdentity: PropTypes.object,
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.object,
  payoutLinkId: PropTypes.string,
  payoutLinkPath: PropTypes.string,
  payoutLinkDetailsData: PropTypes.array,
}

export default TransferAttempt
