import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'

import {
  GET_RELATED_APPLICATION_F_SUCCESS,
  GET_RELATED_IDENTITY_F_SUCCESS,
  GET_RELATED_PAYMENT_INSTRUMENT_F_SUCCESS,
} from 'constants/flowConstants'

// related resource type backend key name (defined by API)
export const applicationDetails = 'application_details'
export const identityDetails = 'identity_details'
export const paymentInstrumentDetails = 'payment_instrument_details'

export const frontendModelByResourceType = {
  [applicationDetails]: frontendApplicationModel,
  [identityDetails]: frontendIdentityModel,
  [paymentInstrumentDetails]: frontendPaymentInstrumentModel,
}

export const successActionByResourceType = {
  [applicationDetails]: GET_RELATED_APPLICATION_F_SUCCESS,
  [identityDetails]: GET_RELATED_IDENTITY_F_SUCCESS,
  [paymentInstrumentDetails]: GET_RELATED_PAYMENT_INSTRUMENT_F_SUCCESS,
}
