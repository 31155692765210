import './TeamMemberFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButtonWithDropdown from 'components/Shared/Inputs/RadioFieldWithDropdown/RadioButtonWithDropdown'
import Button from 'components/Shared/Button/Button'
import submitTeamMemberForm from 'utilities/submit/submitTeamMemberForm'
import validateTeamMemberForm from 'utilities/validate/validateTeamMemberForm'
import { CREATE_TEAM_MEMBER_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'

import {
  CANCEL,
  DASHBOARD_ACCOUNT_NAME,
  EMAIL,
  ROLE,
  ROLE_SUBLABEL,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
} from 'constants/language/languageConstants'

const TeamMemberForm = ({
  roles = [],
  handleSubmit = () => {},
  memberId = '',
  invalid,
}) => {
  return (
    <form className='TeamMemberForm' onSubmit={handleSubmit(submitTeamMemberForm)}>
      <div className='section'>
        { !memberId && (
          <>
            <Field
              name='dashboardAccountName'
              label={DASHBOARD_ACCOUNT_NAME}
              component={InputField}
            />

            <Field
              name='email'
              label={EMAIL}
              component={InputField}
            />
          </>
        )}

        <Field
          name='rolesChoice'
          component={RadioButtonWithDropdown}
          options={roles}
          label={ROLE}
          helperText={ROLE_SUBLABEL}
        />
      </div>

      <div className='sticky-footer'>
        <div className='btn-container flex flex-end'>
          <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button type='submit' label={memberId ? EDIT_TEAM_MEMBER : ADD_TEAM_MEMBER} />
        </div>
      </div>
    </form>
  )
}

TeamMemberForm.propTypes = {
  roles: PropTypes.array,
  handleSubmit: PropTypes.func,
  memberId: PropTypes.string,
}

export default reduxForm({
  form: CREATE_TEAM_MEMBER_FORM,
  validate: validateTeamMemberForm,
  enableReinitialize: true,
})(TeamMemberForm)
