import React from 'react'
import column from 'utilities/table/column'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import VerificationStatusIndicator from 'components/Customer/Shared/Display/ColorcodedStatus/VerificationStatusIndicator'
import { cardTypeMap } from 'constants/cardConstants'
import isNil from 'lodash/isNil'

import {
  ADDRESS,
  CVV2,
  FAST_FUNDS,
  PUSH_FUNDS_BLOCK,
} from 'constants/verificationConstants'

const columnDescriptors = [
  column('Created', 'createdAt', { className: 'date' }),
  column('State', 'state', {
    className: 'state',
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column('Issuer Name', 'institutionName', { className: 'large' }),
  column('Address Verification', 'addressVerification', {
    className: 'large',
    formatter: ({ addressVerification }) => ((isNil(addressVerification) || addressVerification.length <= 1) ?
      <VerificationStatusIndicator verificationKey={ADDRESS} value={addressVerification} /> : addressVerification),
  }),
  column('CVV & Expiration Date', 'cvcStatus', {
    className: 'status',
    formatter: ({ cvcStatus }) => <VerificationStatusIndicator verificationKey={CVV2} value={cvcStatus} />,
  }),
  column('Card Type', 'cardTypeCode', {
    className: 'small',
    formatter: ({ cardTypeCode }) => cardTypeMap[cardTypeCode],
  }),
  column('Push to Card', 'receivingEligibility', {
    className: 'state',
    formatter: ({ receivingEligibility }) => <VerificationStatusIndicator verificationKey={PUSH_FUNDS_BLOCK} value={receivingEligibility} />,
  }),
  column('Fast Funds', 'fastFundsIndicator', {
    className: 'state',
    formatter: ({ fastFundsIndicator }) => <VerificationStatusIndicator verificationKey={FAST_FUNDS} value={fastFundsIndicator} />,
  }),
]

export default columnDescriptors
