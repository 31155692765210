import removeUndefined from 'utilities/remove/removeUndefined'

const backendNoteModel = ({ values }) => {
  const {
    linkedTo,
    content,
    jsonContent,
    firstName,
    lastName,
    email,
    tags,
    status,
  } = values

  const userDetails = {
    first_name: firstName,
    last_name: lastName,
    email,
  }

  return removeUndefined({
    linked_to: linkedTo,
    content,
    user_details: userDetails,
    tags,
    status,
    json_content: jsonContent,
  })
}

export default backendNoteModel
