import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'

const frontendMembershipModel = ({ data: membership }) => {
  const [
    id,
    dashboardUserId,
    apiCredentialId,
    dashboardId,
    token,
    validFrom,
    validTo,
    createdAt,
    updatedAt,
  ] = getMany(membership, [
    'id',
    'dashboard_user_id',
    'api_credential_id',
    'dashboard_id',
    'token',
    'valid_from',
    'valid_to',
    'created_at',
    'updated_at',
  ])

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    dashboardUserId,
    apiCredentialId,
    dashboardId,
    token,
    validFrom,
    validTo,
    displayValidFrom: formatDate({ date: validFrom }),
    displayValidTo: formatDate({ date: validTo }),
  })
}

export default frontendMembershipModel
