import { UPDATE_ACTION_STATUS } from 'constants/actionConstants'
import filter from 'lodash/filter'

import {
  UPDATE_TRANSFERS,
  UPDATE_VERIFICATIONS,
} from 'constants/amplitudeConstants'

const submitUpdateAdminToolsForm = (values, dispatch, props) => {
  const {
    id = '',
    state,
    code,
    type,
  } = values

  const {
    title,
    actionType,
    adminOperationKey,
    confirmationModalAction,
  } = props

  const updatedValues = {
    admin_operation_key: adminOperationKey,
    entity: {
      state,
      code,
      task_type: type,
    },
  }

  const eventName = title === 'Transfer' ? UPDATE_TRANSFERS : UPDATE_VERIFICATIONS

  const ids = filter(id.split(/\W/))

  if (confirmationModalAction) {
    dispatch(confirmationModalAction)
  }

  dispatch({
    type: UPDATE_ACTION_STATUS,
    payload: {
      actionType,
      recordIds: ids,
      recordType: title,
      values: updatedValues,
      statusKey: 'is_updated',
      eventName,
    },
  })
}

export default submitUpdateAdminToolsForm
