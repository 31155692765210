import getUnderwritingIdentityO from 'state-layer/orchestration/get/getUnderwritingIdentityO'
import getAccessFormAPI from 'api/dashboard/get/getAccessFormAPI'
import getUnderwritingReviewAPI from 'api/finix/get/getUnderwritingReviewAPI'
import getUnderwritingReviewRunAPI from 'api/finix/get/getUnderwritingReviewRunAPI'
import getUnderwritingReviewRunsAPI from 'api/finix/get/getUnderwritingReviewRunsAPI'
import getCustomerOnboardingFormsAPI from 'api/dashboard/get/getCustomerOnboardingFormsAPI'
import getUnderwritingRunReportsAPI from 'api/finix/get/getUnderwritingRunReportsAPI'
import frontendUnderwritingReviewModel from 'utilities/create/models/frontendUnderwritingReviewModel'
import frontendUnderwritingRunModel from 'utilities/create/models/frontendUnderwritingRunModel'
import frontendAccessFormModel from 'utilities/create/models/frontendAccessFormModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import removeUndefined from 'utilities/remove/removeUndefined'
import frontendCustomerOnboardingFormModel from 'utilities/create/models/frontendCustomerOnboardingFormModel'
import merge from 'lodash/merge'
import head from 'lodash/head'
import get from 'lodash/get'
import co from 'co'

const entityTypeToAPI = {
  access_form: getAccessFormAPI,
  IDENTITY: getUnderwritingIdentityO,
}

const entityTypeToFEModel = {
  access_form: frontendAccessFormModel,
  IDENTITY: ({ data }) => frontendIdentityModel({ data: head(data) }),
}

function * getUnderwritingReviewO ({
  values,
  credentials,
  queries,
  meta,
}) {
  const {
    reviewId,
    runId,
  } = values

  const [
    { data: reviewData },
    { data: runsResponse },
  ] = yield [
    getUnderwritingReviewAPI({ values, credentials, queries, meta }),
    getUnderwritingReviewRunsAPI({ id: reviewId, credentials, meta }),
  ]

  const normalizedReviewData = normalizeResponse({ content: reviewData })
  const runs = get(runsResponse, '_embedded.runs', [])
  const latestRunId = runId || get(head(runs), 'id')

  const { data: latestRun } = yield latestRunId ? getUnderwritingReviewRunAPI({ reviewId, runId: latestRunId, credentials, queries: { show_context: true } }) : {}
  const latestRunModel = frontendUnderwritingRunModel({ data: normalizeResponse({ content: latestRun }) })

  const { data: report } = yield latestRunId ? getUnderwritingRunReportsAPI({ id: latestRunId, credentials }) : {}

  const {
    entityType,
    entityId,
  } = values

  let entity
  const entityAPI = entityTypeToAPI[entityType]
  const entityFEModel = entityTypeToFEModel[entityType]

  if (entityAPI) {
    const { data: entityData } = yield entityAPI({ id: entityId, credentials })
    const normalizedEntity = normalizeResponse({ content: entityData })
    entity = entityFEModel ? entityFEModel({ data: normalizedEntity }) : normalizedEntity
  }

  // fetch the customer onboarding form model for application underwriting items
  let customerOnboardingFormModel
  if (entityType === 'access_form') {
    const { data: customerOnboardingFormResponse } = yield getCustomerOnboardingFormsAPI({ queries: { access_form_id: entityId }, credentials })
    const customerOnboardingForm = get(customerOnboardingFormResponse, '_embedded.customer_onboarding_forms[0]', {})
    customerOnboardingFormModel = frontendCustomerOnboardingFormModel({ data: customerOnboardingForm })
  }

  const review = frontendUnderwritingReviewModel({
    data: removeUndefined(
      merge({}, normalizedReviewData, {
        run: latestRunModel,
        report,
        entity,
        customerOnboardingForm: customerOnboardingFormModel,
      }),
    ),
  })

  return createSuccessResponse({
    data: [review],
  })
}

export default co.wrap(getUnderwritingReviewO)
