import './MobileHeaderS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import SearchFormC from 'components/Customer/Shared/SearchForm/SearchFormC'
import UserActionsC from 'components/System/UserActions/UserActionsC'
import MiniNavC from 'components/System/MiniNav/MiniNavC'
import MainNavC from 'components/System/MainNav/MainNavC'
import X from 'components/SVG/X'
import CTABanner from 'components/Shared/CTABanner/CTABanner'
import HamburgerMenu from 'components/SVG/HamburgerMenu'
import { COMPANY_PATH } from 'constants/pathConstants'
import { SEARCH_ICON } from 'constants/iconConstants'

import {
  ATTEST_TO_FORM,
  LOGOUT,
  PLEASE_COMPLETE_PCI_ATTESTATION,
} from 'constants/language/languageConstants'

const MobileHeader = ({
  switchLink,
  handleLogout,
  inDashboard,
  formattedPath = '',
  showEnvironmentHeaderTag = false,
  environment = '',
  inCredentialsPage = false,
  userEmail = '',
  hasIncompleteComplianceForm = false,
  hasOverdueComplianceForm = false,
  credentialId = '',
  auth,
  isLoading,
  mobileNavOpen = false,
  setMobileNavOpen = () => {},
}) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const toggleNav = () => setMobileNavOpen(!mobileNavOpen)
  const showCTABanner = hasIncompleteComplianceForm || hasOverdueComplianceForm

  const mainHeaderClassNames = classnames(`MobileHeader ${environment}`, {
    dashboard: inDashboard,
    admin: !inDashboard,
  })

  if (inCredentialsPage) {
    return (
      <div className='MobileHeader flex flex-end'>
        <div className='header-right flex items-center'>
          <div className='email'>{userEmail}</div>

          <Button onClick={handleLogout} label={LOGOUT} rightIcon='sign-out' />
        </div>
      </div>
    )
  }

  return (
    <div className={mainHeaderClassNames}>
      { showEnvironmentHeaderTag && (
        <div className={`environment-tag-container ${environment}`}>
          <div className={`environment-tag bold ${environment}`}>{environment}</div>
        </div>
      )}

      <div className='header-content flex items-center space-between'>
        <div className='nav-hamburger-menu' onClick={toggleNav}>
          { mobileNavOpen ? <X /> : <HamburgerMenu /> }
        </div>

        <div className='flex'>
          <i className={`search fal fa-${SEARCH_ICON}`} onClick={() => setSearchOpen(!searchOpen)} />

          <MiniNavC formattedPath={formattedPath} />

          <UserActionsC
            handleLogout={handleLogout}
            switchLink={switchLink}
          />
        </div>
      </div>

      { showCTABanner && (
        <CTABanner
          status={hasIncompleteComplianceForm ? 'warning' : 'error'}
          title={`Compliance Forms ${hasIncompleteComplianceForm ? 'Due' : 'Overdue'}`}
          subTitle={PLEASE_COMPLETE_PCI_ATTESTATION}
          action={() => goToPath({ pathname: COMPANY_PATH({ credentialId }), queries: { tab: 'compliance', state: hasIncompleteComplianceForm ? 'INCOMPLETE' : 'OVERDUE' } })}
          actionLabel={ATTEST_TO_FORM}
        />
      )}

      <div className={`mobile-search ${searchOpen ? 'open' : 'closed'}`}>
        <SearchFormC />
      </div>

      <div className={`mobile-nav ${mobileNavOpen ? 'show' : ''}`}>
        <MainNavC auth={auth} credentialId={credentialId} isLoading={isLoading} />
      </div>
    </div>
  )
}

MobileHeader.propTypes = {
  switchLink: PropTypes.string,
  handleLogout: PropTypes.func,
  inDashboard: PropTypes.bool,
  formattedPath: PropTypes.string,
  showEnvironmentHeaderTag: PropTypes.bool,
  environment: PropTypes.string,
  inCredentialsPage: PropTypes.bool,
  userEmail: PropTypes.string,
  hasOverdueComplianceForm: PropTypes.bool,
  hasIncompleteComplianceForm: PropTypes.bool,
  credentialId: PropTypes.string,
  auth: PropTypes.object,
  isLoading: PropTypes.bool,
  mobileNavOpen: PropTypes.bool,
  setMobileNavOpen: PropTypes.func,
}

export default MobileHeader
