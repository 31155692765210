import { parseUrlQueries } from 'utilities/parseUrlQueries'
import removeUndefined from 'utilities/remove/removeUndefined'
import { YYYY_MM_DD } from 'constants/timeConstants'
import get from 'lodash/get'
import moment from 'moment'

export const ROUTING = 'routing.locationBeforeTransitions'
export const QUERY = `${ROUTING}.query`
export const PATHNAME = `${ROUTING}.pathname`
export const SEARCH = `${ROUTING}.search`

export const PLATFORM_NAME = `${QUERY}.platformName`
export const SORT = `${QUERY}.sort`
export const TAB = `${QUERY}.tab`
export const SECONDARY_TAB = `${QUERY}.secondaryTab`
export const LIMIT = `${QUERY}.limit`
export const OFFSET = `${QUERY}.offset`

export const IGNORE_QUERIES = ['tab', 'secondaryTab', 'pagination']

// TODO: ultimately combine all these queries below into one and pass in props to determine what the default value is
export const createdLastMonthQueries = () => {
  const urlQueries = parseUrlQueries()
  const createdAtGTE = get(urlQueries, 'created_at.gte')
  const createdAtLTE = get(urlQueries, 'created_at.lte')

  const createdAt = (createdAtGTE || createdAtLTE) ? removeUndefined({
    gte: createdAtGTE,
    lte: createdAtLTE,
  }) : {
    gte: moment().subtract(30, 'days').format(YYYY_MM_DD),
    lte:  moment().format(YYYY_MM_DD),
  }

  const notRunningCypress = typeof window.Cypress === 'undefined'

  // having the 30 day filter default causes a lot of problems for our tests due, so we are disabling it during Cypress runs
  return notRunningCypress ? { created_at: createdAt } : {}
}

export const createdLast3MonthQueries = () => {
  const urlQueries = parseUrlQueries()
  const createdAtGTE = get(urlQueries, 'created_at.gte')
  const createdAtLTE = get(urlQueries, 'created_at.lte')

  const createdAt = (createdAtGTE || createdAtLTE) ? removeUndefined({
    gte: createdAtGTE,
    lte: createdAtLTE,
  }) : {
    gte: moment().subtract(3, 'months').format(YYYY_MM_DD),
    lte:  moment().format(YYYY_MM_DD),
  }

  const notRunningCypress = typeof window.Cypress === 'undefined'

  // having the 30 day filter default causes a lot of problems for our tests due, so we are disabling it during Cypress runs
  return notRunningCypress ? { created_at: createdAt } : {}
}

export const createdQueriesForLastMonths = (numberOfMonths = 1) => {
  const urlQueries = parseUrlQueries()
  const createdAtGTE = get(urlQueries, 'created_at.gte')
  const createdAtLTE = get(urlQueries, 'created_at.lte')
  const updatedAtGTE = get(urlQueries, 'updated_at.gte')
  const updatedAtLTE = get(urlQueries, 'updated_at.lte')
  const respondByGTE = get(urlQueries, 'respond_by.gte')
  const respondByLTE = get(urlQueries, 'respond_by.lte')

  const createdAt = (createdAtGTE || createdAtLTE) ? removeUndefined({
    gte: createdAtGTE,
    lte: createdAtLTE,
  }) :  {
    gte: moment().subtract(numberOfMonths, 'months').format(YYYY_MM_DD),
    lte:  moment().format(YYYY_MM_DD),
  }

  let dateQueryValue = { created_at: createdAt }

  if(updatedAtLTE || updatedAtGTE) {
    const updatedAt = removeUndefined({
      gte: updatedAtGTE,
      lte: updatedAtLTE,
    })

    dateQueryValue = { updated_at: updatedAt}
  }

  if(respondByGTE || respondByLTE) {
    const respondBy = removeUndefined({
      gte: respondByGTE,
      lte: respondByLTE,
    })

    dateQueryValue = { respond_by: respondBy}
  }


  const notRunningCypress = typeof window.Cypress === 'undefined'

  return notRunningCypress ? dateQueryValue : {}
}

export const createdAtNoDefaultQueries = () => {
  const urlQueries = parseUrlQueries()
  const createdAtGTE = get(urlQueries, 'created_at.gte')
  const createdAtLTE = get(urlQueries, 'created_at.lte')
  const updatedAtGTE = get(urlQueries, 'updated_at.gte')
  const updatedAtLTE = get(urlQueries, 'updated_at.lte')
  const respondByGTE = get(urlQueries, 'respond_by.gte')
  const respondByLTE = get(urlQueries, 'respond_by.lte')

  const existingCreatedAt = (createdAtGTE || createdAtLTE) ? removeUndefined({
    gte: createdAtGTE,
    lte: createdAtLTE,
  }) :  undefined

  let dateQueryValue = { created_at: existingCreatedAt }

  if(updatedAtLTE || updatedAtGTE) {
    const existingUpdatedAt = removeUndefined({
      gte: updatedAtGTE,
      lte: updatedAtLTE,
    })

    dateQueryValue = { updated_at: existingUpdatedAt}
  }

  if(respondByGTE || respondByLTE) {
    const existingRespondBy = removeUndefined({
      gte: respondByGTE,
      lte: respondByLTE,
    })

    dateQueryValue = { respond_by: existingRespondBy}
  }

  const notRunningCypress = typeof window.Cypress === 'undefined'

  return notRunningCypress ? dateQueryValue : {}
}

// TODO: move this to somewhere more useful, or update the logic to be createdAt and changed created_at at the createBEModel level
export const queryNameToModelName = {
  ['created_at']: 'createdAt',
  ['updated_at']: 'updatedAt',
  ['respond_by']: 'respondBy',
}
