import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_ADJUSTMENTS } from 'constants/apiConstants'

const postBalanceAdjustmentAPI = ({ credentials, values, meta }) => {
  return paymentsAPI.post({
    path: BALANCE_ADJUSTMENTS,
    credentials,
    values,
    meta,
  })
}

export default postBalanceAdjustmentAPI
