import './HeaderV2S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderItem from './HeaderItem'
import Loader from 'components/Shared/Loader/Loader'
import DropdownButton from 'components/Customer/Shared/DropdownButton/DropdownButton'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'
import every from 'lodash/every'

import {
  t,
  ACTIONS,
} from 'constants/language/languageConstants'

const HeaderV2 = ({
  title = '',
  formattedSubTitleDisplay = '',
  resource = {},
  items = [],
  actions = [],
  isFetching = false,
}) => {
  const resourceLabel = get(resource, 'label')
  const resourceID = get(resource, 'id')
  const additionalIds = get(resource, 'additionalIds', [])
  const badge = get(resource, 'badge')
  const badges = get(resource, 'badges')
  const clickToCopyPosition = get(resource, 'clickToCopyPosition', 'right')

  return (
    <header className='HeaderV2 flex space-between'>
      <div className='header-content'>
        { (!isEmpty(resource) && !isFetching) && (
          <div className='resource flex items-center'>
            <h5>{t(resourceLabel)}</h5>
            <ClickToCopyC content={resourceID} position={clickToCopyPosition} />

            {map(additionalIds, ({ label, id }) => (
              <ClickToCopyC
                label={t(label)}
                content={id}
                key={id}
                position={clickToCopyPosition}
              />
            ))}
            {badge && <div className='badge'>{badge}</div>}
            {badges && map(badges, (singleBadge) => {
              return (
                <div className='badge'>{singleBadge}</div>
              )
            })}
          </div>
        )}

        { title && <h2 className='title'>{t(title)}</h2> }

        { formattedSubTitleDisplay }

        { isFetching && <Loader /> }

        { !isEmpty(items) && !isFetching && (
          <div className='items flex wrap items-center'>
            { map(items, (item, index) => <HeaderItem {...item} key={`HeaderItem-${index}`} />) }
          </div>
        )}
      </div>

      { !isEmpty(actions) && !every(actions, { condition: false }) && (
        <DropdownButton
          label={ACTIONS}
          variant='tertiary'
          alignment='right'
          className='header-actions-button'
          actions={actions}
        />
      )}
    </header>
  )
}

HeaderV2.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  formattedSubTitleDisplay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  resource: PropTypes.object,
  items: PropTypes.array,
  actions: PropTypes.array,
  isFetching: PropTypes.bool,
  showSkeletonLoading: PropTypes.bool,
}

export default HeaderV2
