import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_GUEST_ACCESS_FORM } from 'constants/apiConstants'

const getGuestAccessFormAPI = ({ id }) => {
  return guestDashboardAPI.get({
    path: DASHBOARD_GUEST_ACCESS_FORM({ id }),
  })
}

export default getGuestAccessFormAPI
