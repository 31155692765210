import React, { Component } from 'react'
import ApplePayDomainRegistrations from './ApplePayDomainRegistrations'
import UnregisterApplePayDomainModal from 'components/Customer/Modals/UnregisterApplePayDomainModal/UnregisterApplePayDomainModal'
import Button from 'components/Shared/Button/Button'
import unregisterApplePayDomainRequest from 'utilities/actions/patch/unregisterApplePayDomainRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import { ADD_ICON } from 'constants/iconConstants'
import { APPLE_PAY_MERCHANT } from 'constants/selectedItemsConstants'
import get from 'lodash/get'

import {
  REGISTER_NEW_DOMAIN_MODAL,
  CONFIRM_ACTION,
} from 'constants/modalConstants'

const selectedItemsKey = APPLE_PAY_MERCHANT

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const merchant = get(props, 'merchant')
  const identityId = get(props, 'identityId')
  const merchantName = get(merchant, 'name')
  const isFetching = get(state, `applePayRegisteredMerchantR.${FETCHING}`)
  const paymentMethodConfigId = get(state, 'applePayRegisteredMerchantR.items.id')

  const initialQueries = {
    merchant_identity: identityId,
  }

  return {
    credentials,
    initialQueries,
    identityId,
    merchantName,
    isFetching,
    paymentMethodConfigId,
    selectedItemsKey,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showRegisterNewDomainModal: (modalProps) => dispatch(showModalAction({ modalType: REGISTER_NEW_DOMAIN_MODAL, modalProps })),
    showUnregisterApplePayDomainModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_ACTION, modalProps })),
    unregisterApplePayDomains: ({ domainToUnregister, credentials, paymentMethodConfigId, identityId }) => dispatch(unregisterApplePayDomainRequest({ domainToUnregister, credentials, selectedItemsKey, dispatch, paymentMethodConfigId, identityId })),
  }
}


class ApplePayDomainRegistrationsC extends Component {
  showUnregisterApplePayDomainsConfirmationModal = ({ domain: domainToUnregister }) => {
    const {
      showUnregisterApplePayDomainModal,
      unregisterApplePayDomains,
      credentials,
      paymentMethodConfigId,
      identityId,
    } = this.props

    const description = <UnregisterApplePayDomainModal domainToUnregister={domainToUnregister} />
    const message = 'Remove web domain?'
    const confirmationAction = () => unregisterApplePayDomains({ domainToUnregister, credentials, paymentMethodConfigId, identityId })
    const customButtonGroup = <Button onClick={confirmationAction} label='Remove Web Domain' variant='destructive' />

    showUnregisterApplePayDomainModal({ message, description, customButtonGroup })
  }

  render() {
    const {
      identityId,
      merchantName,
      showRegisterNewDomainModal,
    } = this.props

    const actions = [
      {
        label: 'Add Web Domain',
        action: () => {
          showRegisterNewDomainModal({ identityId, merchantName })
        },
        className: 'add-new-domain-btn',
      },
    ]

    const selectedItemActions = [this.showUnregisterApplePayDomainsConfirmationModal]

    return (
      <ApplePayDomainRegistrations
        {...this.props}
        selectedItemActions={selectedItemActions}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayDomainRegistrationsC)
