import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import PropTypes from 'prop-types'
import FormFileUploaderC from 'components/Customer/Pages/FormFileUploader/FormFileUploaderC'
import { UPLOAD_FILE } from 'constants/language/languageConstants'

const FormFileUploaderModal = ({
  formName,
  fieldName,
  fileIcon,
  fileType,
  displayFieldName,
  directUpload,
  accessFormId,
  onboardingFormId,
  applicationId,
  existingAdditionalDocuments,
  directUploadFunc,
  isHostedAccessForm = false,
  fileNameRequired = false,
  config = {},
  instructions = [],
}) => {
  return (
    <GenericModal
      title={UPLOAD_FILE}
      className='FormFileUploaderModal'
      Component={FormFileUploaderC}
      formName={formName}
      fieldName={fieldName}
      fileIcon={fileIcon}
      fileType={fileType}
      displayFieldName={displayFieldName}
      directUpload={directUpload}
      accessFormId={accessFormId}
      onboardingFormId={onboardingFormId}
      applicationId={applicationId}
      existingAdditionalDocuments={existingAdditionalDocuments}
      directUploadFunc={directUploadFunc}
      isHostedAccessForm={isHostedAccessForm}
      fileNameRequired={fileNameRequired}
      config={config}
      instructions={instructions}
    />
  )
}

FormFileUploaderModal.propTypes = {
  formName: PropTypes.string,
  fieldName: PropTypes.string,
  fileIcon: PropTypes.string,
  fileType: PropTypes.string,
  displayFieldName: PropTypes.string,
  directUpload: PropTypes.bool,
  accessFormId: PropTypes.string,
  onboardingFormId: PropTypes.string,
  applicationId: PropTypes.string,
  existingAdditionalDocuments: PropTypes.array,
  directUploadFunc: PropTypes.func,
  isHostedAccessForm: PropTypes.bool,
  fileNameRequired: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
}

export default FormFileUploaderModal
