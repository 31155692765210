import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import dashboardAPI from 'utilities/api/dashboardAPI'
import get from 'lodash/get'

const environmentToProxyEnvMap = {
  QA: 'qa',
  Sandbox: 'sb',
  Live: 'prod',
}

const fwsFinixProxy = {
  get: ({ path, absolutePath, queries, mock, meta }) => {
    const credentials = getCurrentCredentials()
    const currentEnv = get(credentials, 'environment')
    const proxyEnv = environmentToProxyEnvMap[currentEnv]
    const proxyPath = `${proxyEnv}/${path}`

    return dashboardAPI.get({
      path: proxyPath,
      absolutePath,
      queries,
      mock,
      meta,
    })
  },

  post: ({ path, absolutePath, values, queries, mock, meta }) => {
    const credentials = getCurrentCredentials()
    const currentEnv = get(credentials, 'environment')
    const proxyEnv = environmentToProxyEnvMap[currentEnv]
    const proxyPath = `${proxyEnv}/${path}`

    return dashboardAPI.post({
      path: proxyPath,
      absolutePath,
      values,
      queries,
      mock,
      meta,
    })
  },

  patch: ({ path, absolutePath, values, queries, mock, meta }) => {
    const credentials = getCurrentCredentials()
    const currentEnv = get(credentials, 'environment')
    const proxyEnv = environmentToProxyEnvMap[currentEnv]
    const proxyPath = `${proxyEnv}/${path}`

    return dashboardAPI.patch({
      path: proxyPath,
      absolutePath,
      values,
      queries,
      mock,
      meta,
    })
  },

  put: ({ path, absolutePath, values, queries, mock, meta }) => {
    const credentials = getCurrentCredentials()
    const currentEnv = get(credentials, 'environment')
    const proxyEnv = environmentToProxyEnvMap[currentEnv]
    const proxyPath = `${proxyEnv}/${path}`

    return dashboardAPI.put({
      path: proxyPath,
      absolutePath,
      values,
      queries,
      mock,
      meta,
    })
  },

  delete: ({ path, absolutePath, values, queries, mock, meta }) => {
    const credentials = getCurrentCredentials()
    const currentEnv = get(credentials, 'environment')
    const proxyEnv = environmentToProxyEnvMap[currentEnv]
    const proxyPath = `${proxyEnv}/${path}`

    return dashboardAPI.delete({
      path: proxyPath,
      absolutePath,
      values,
      queries,
      mock,
      meta,
    })
  },
}

export default fwsFinixProxy
