import frontendDashboardCustomizationModel from 'utilities/create/models/frontendDashboardCustomizationModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendDashboardCustomizationsModel = ({ data: dashboardCustomizations }) => {
  const dashboardCustomizationModels = map(dashboardCustomizations, (dashboardCustomization) => frontendDashboardCustomizationModel({ data: dashboardCustomization }))

  return keyBy(dashboardCustomizationModels, 'id')
}

export default frontendDashboardCustomizationsModel
