import './EditIdentityProcessingInformationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditIdentityProcessingInformationFormC from 'components/Customer/Forms/EditIdentityProcessingInformationForm/EditIdentityProcessingInformationFormC'
import { EDIT_PROCESSING_INFORMATION } from 'constants/language/languageConstants'

const EditIdentityProcessingInformationModal = ({
  credentials = {},
  applicationUnderwritingProfile,
  identity = {},
}) => {
  return (
    <GenericModal
      title={EDIT_PROCESSING_INFORMATION}
      className='EditIdentityProcessingInformationModal'
      Component={EditIdentityProcessingInformationFormC}
      credentials={credentials}
      applicationUnderwritingProfile={applicationUnderwritingProfile}
      identity={identity}
    />
  )
}

EditIdentityProcessingInformationModal.propTypes = {
  credentials: PropTypes.object,
  applicationUnderwritingProfile: PropTypes.object,
  identity: PropTypes.object,
}

export default EditIdentityProcessingInformationModal
