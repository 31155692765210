import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { getPurchaseDataSelector } from 'state-layer/selectors/index'
import Purchase from './Purchase'
import Address from 'components/Customer/Shared/Display/Address/Address'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import PurchaseStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PurchaseStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPurchaseRequest from 'utilities/actions/get/getPurchaseReqest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatPhone from 'utilities/forms/format/formatPhone'
import getMany from 'utilities/get/getMany'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'

import {
  STATE,
  AMOUNT,
  CREATED_ON,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  PAYMENT_ATTEMPT,
  PAYMENT,
  NAME,
  EMAIL,
  PHONE,
  SHIPPING_ADDRESS,
  TRANSFER_ID,
  MERCHANT_ACCOUNT,
  LINK_TYPE,
  PAYMENT_LINK_NAME,
  AMOUNT_TYPE,
  EXPIRES_ON,
  STATUS,
  TOTAL_AMOUNT,
  BILLING_ADDRESS,
  VIEW_BUYER_DETAILS,
  VIEW_PAYMENT_LINK,
} from 'constants/language/languageConstants'

import {
  APPLICATION_PATH,
  PAYMENT_PATH,
  IDENTITY_PATH,
  MERCHANT_PATH,
  BUYER_PATH,
  PAYMENT_LINK_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const purchaseId = get(props, 'params.purchaseId')
  const purchase = getPurchaseDataSelector(state, purchaseId)

  const {
    id,
    displayAmount,
    currency,
    transferId,
    applicationId,
    shippingAddress,
    billingAddress,
    state: purchaseState,
    displayCreatedAt,
    paymentInstrument,
    application,
    identity,
    paymentLink,
    merchant,
    fullName,
    email,
    phone,
    transfer,
    receipts,
  } = purchase

  const [
    paymentLinkId,
    merchantId,
    nickname,
    displayCreatedAtPaymentLink,
    displayLinkExpiresAt,
    linkType,
    displayAmountType,
    paymentLinkStatus,
    paymentLinkTotalAmount,
  ] = getMany(paymentLink, [
    'id',
    'merchantId',
    'nickname',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'linkType',
    'displayAmountType',
    'displayState',
    'displayTotalAmount',
  ])

  const applicationName = get(application, 'businessName')
  const merchantName = get(merchant, 'name')

  const [
    merchantIdentityId,
    merchantIdentityName,
  ] = getMany(identity, [
    'id',
    'displayBusinessName',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: hasPartnerAccess({ credentials }),
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantIdentityName,
        path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
      },
      {
        label: PAYMENT,
        value: transferId,
        path: hasPartnerAccess({ credentials }) ? PAYMENT_PATH({ credentialId, transferId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: PAYMENT_ATTEMPT,
      id,
      additionalIds: [
        {
          label: TRANSFER_ID,
          id: transferId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <PurchaseStatus value={purchaseState} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
    ],
  }

  const purchaseData = convertPageSectionDataToV2([
    {
      label: AMOUNT,
      value: displayAmount,
    },
    {
      label: STATE,
      value: <PurchaseStatus value={purchaseState} />,
    },
    {
      label: SHIPPING_ADDRESS,
      value: <Address address={shippingAddress} />,
    },
    {
      label: BILLING_ADDRESS,
      value: <Address address={billingAddress} />,
    },
  ])

  const buyerIdentityId = get(paymentInstrument, 'identityId')
  const buyerData = convertPageSectionDataToV2([
    {
      label: NAME,
      value: fullName,
    },
    {
      label: EMAIL,
      value: email,
    },
    {
      label: PHONE,
      value: formatPhone(phone),
    },
  ])

  const paymentLinkDetailsData = convertPageSectionDataToV2([
    {
      label: MERCHANT_ACCOUNT,
      value: <Link className='text-link' to={MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId })}>{merchantName}</Link>,
    },
    {
      label: LINK_TYPE,
      value: linkType,
    },
    {
      label: PAYMENT_LINK_NAME,
      value: nickname,
    },
    {
      label: STATUS,
      value: paymentLinkStatus,
    },
    {
      label: AMOUNT_TYPE,
      value: displayAmountType,
    },
    {
      label: TOTAL_AMOUNT,
      value: paymentLinkTotalAmount,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAtPaymentLink,
    },
    {
      label: EXPIRES_ON,
      value: displayLinkExpiresAt,
    },
  ])

  const buyerDetailsPath = <Link className='text-link' to={BUYER_PATH({ credentialId, identityId: buyerIdentityId })}>{VIEW_BUYER_DETAILS}</Link>
  const paymentLinkPath = <Link className='text-link' to={PAYMENT_LINK_PATH({ credentialId, paymentLinkId })}>{VIEW_PAYMENT_LINK}</Link>

  return {
    purchaseId,
    credentials,
    contextBarData,
    headerData,
    purchaseData,
    buyerData,
    paymentLinkDetailsData,
    paymentInstrument,
    buyerIdentityId,
    buyerDetailsPath,
    paymentLinkId,
    paymentLinkPath,
    transfer,
    receipts,
    transferId,
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPurchase: ({ purchaseId, credentials }) => dispatch(getPurchaseRequest({ purchaseId, credentials })),
})

class PurchaseC extends Component {
  componentDidMount() {
    const {
      getPurchase,
      credentials,
      purchaseId,
    } = this.props

    if (purchaseId) {
      getPurchase({ purchaseId, credentials })
    }
  }

  render() {
    return (
      <Purchase {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseC)
