import paymentsAPI from 'utilities/api/paymentsAPI'
import { RECEIPT_DELIVERY_ATTEMPTS } from 'constants/apiConstants'
import get from 'lodash/get'

const getReceiptDeliveryAttemptsAPI = ({ values, queries, credentials, meta }) => {
  const receiptId = get(values, 'id')
  return paymentsAPI.get({
    meta,
    path: RECEIPT_DELIVERY_ATTEMPTS({ receiptId }),
    queries,
    credentials,
  })
}

export default getReceiptDeliveryAttemptsAPI
