import './SanctionsScreeningReportV2S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'
import { PROFILE } from 'constants/language/languageConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

const SanctionsScreeningReportV2 = ({
  sanctionsData = [],
}) => {
  return (
    <div className='SanctionsScreeningReportV2'>
      { map(sanctionsData, (sanction, index) => {
        const [
          title,
          subTitle,
          profileColumns,
          watchLists,
        ] = getMany(sanction, [
          'title',
          'subTitle',
          'profileColumns',
          'watchLists',
        ])

        return (
          <div className={`sanction-item sanction-${index}`}>
            <h6>{title}</h6>
            {subTitle && <div className='sub-title p-2'>{subTitle}</div>}
            {!isEmpty(profileColumns) && (
            <>
              <div className='profile-string p-2-bold'>{PROFILE}</div>
              <PageSectionV2C
                columns={profileColumns}
                className='profile-sections'
              />
            </>
            )}
            { map(watchLists, ({ name, columns }, watchListIndex) => {
              return (
                <>
                  <div className='p-2-bold'>{name}</div>
                  <PageSectionV2C
                    columns={columns}
                    className={`watch-list-${watchListIndex}`}
                  />
                </>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

SanctionsScreeningReportV2.propTypes = {
  sanctionsData: PropTypes.array,
}

export default SanctionsScreeningReportV2
