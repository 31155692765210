import { fieldEmpty } from 'utilities/validate'

const validateUpdateDisputeStateForm = ({ disputeState, disputeNote }) => {
  return {
    disputeState: fieldEmpty(disputeState, 'State'),
    disputeNote: fieldEmpty(disputeNote, 'Dispute Update Note'),
  }
}

export default validateUpdateDisputeStateForm
