import './AccessFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import AccessFormViewC from 'components/Customer/Pages/AccessForm/AccessFormViewC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import LiveAccountApplicationApprovedDetails from 'components/Customer/Shared/Display/LiveAccountApplicationApprovedDetails/LiveAccountApplicationApprovedDetails'
import LiveAccountApplicationRejectedDetails from 'components/Customer/Shared/Display/LiveAccountApplicationRejectedDetails/LiveAccountApplicationRejectedDetails'
import LiveAccountApplicationAdditionalVerificationNeededDetails from 'components/Customer/Shared/Display/LiveAccountApplicationAdditionalVerificationNeededDetails/LiveAccountApplicationAdditionalVerificationNeededDetails'
import LiveAccountApplicationWithdrawnDetails from 'components/Customer/Shared/Display/LiveAccountApplicationWithdrawnDetails/LiveAccountApplicationWithdrawnDetails'
import isEqual from 'lodash/isEqual'

import {
  LIVE_ACCOUNT_APPLICATION_VIEW_PAGE_MESSAGE,
  UPDATED,
} from 'constants/language/languageConstants'

import {
  IN_REVIEW_BE_VALUE,
  REJECTED_BE_VALUE,
  APPROVED_BE_VALUE,
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
  WITHDRAWN_BE_VALUE,
} from 'constants/statusConstants'

const AccessForm = ({
  accessForm = {},
  accessFormId = '',
  status,
  displayUpdatedAt,
  headerData = {},
}) => {
  return (
    <div className='AccessForm'>
      <ContextBar />

      <HeaderV2C
        {...headerData}
      />

      <div className='status-banner-container'>
        {isEqual(status, IN_REVIEW_BE_VALUE) && <div className='review-message'>{LIVE_ACCOUNT_APPLICATION_VIEW_PAGE_MESSAGE}</div>}
        {isEqual(status, APPROVED_BE_VALUE) && <LiveAccountApplicationApprovedDetails rightTitle={`${UPDATED} ${displayUpdatedAt}`} />}
        {isEqual(status, REJECTED_BE_VALUE) && <LiveAccountApplicationRejectedDetails rightTitle={`${UPDATED} ${displayUpdatedAt}`} />}
        {isEqual(status, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE) && <LiveAccountApplicationAdditionalVerificationNeededDetails rightTitle={`${UPDATED} ${displayUpdatedAt}`} />}
        {isEqual(status, WITHDRAWN_BE_VALUE) && <LiveAccountApplicationWithdrawnDetails rightTitle={`${UPDATED} ${displayUpdatedAt}`} />}
      </div>

      <AccessFormViewC
        accessFormId={accessFormId}
        accessForm={accessForm}
      />
    </div>
  )
}

AccessForm.propTypes = {
  accessForm: PropTypes.object,
  accessFormId: PropTypes.string,
  status: PropTypes.string,
  displayUpdatedAt: PropTypes.string,
}

export default AccessForm
