import './CardNotPresentFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import Loader from 'components/Shared/Loader/Loader'
import validateCardNotPresentForm from 'utilities/validate/validateCardNotPresentForm'
import { EDIT_FEE_PROFILE_CNP_FORM } from 'constants/formConstants'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  CARD_NOT_PRESENT_PAYMENTS,
  EDIT_CARD_NOT_PRESENT_PAYMENTS_SUBTITLE,
  PRICING_STRATEGY,
  SAVE_AND_CONTINUE,
  PERCENTAGE_FEE,
  FIXED_FEE,
  CARD_BRAND_OVERRIDES,
  CHARGEBACKS,
  CHARGEBACK_NOTIFICATION,
  CHARGEBACK_INQUIRY,
  CARD_CROSS_BORDER,
  FEE_TYPE,
  PASS_THROUGH_CARD_ASSOCIATION_FEES,
  VISA_ASSESSMENTS,
  VISA_ACQUIRER_PROCESSING,
  VISA_CREDIT_VOUCHER,
  VISA_BASE_SYSTEM_FILE_TRANSMISSION,
  VISA_KILOBYTE_ACCESS,
  MASTERCARD_ASSESSMENTS_UNDER_1000,
  MASTERCARD_ASSESSMENTS_OVER_1000,
  MASTERCARD_ACQUIRER_FEES,
  AMERICAN_EXPRESS_ASSESSMENTS,
  DISCOVER_ASSESSMENTS,
  DISCOVER_DATA_USAGE,
  DISCOVER_NETWORK_AUTHORIZATION,
  CANCEL,
  ALL_CARD_BRANDS_REQUIRED,
  PASS_THROUGH_DUES_ASSESSMENTS,
  PASS_THROUGH_DUES_ASSESSMENTS_HELPER_TEXT,
} from 'constants/language/languageConstants'

import {
  AMEX_LABEL,
  DISCOVER_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

const CardNotPresentForm = ({
  isFetching = false,
  handleSubmit = () => {},
  currency = 'USD',
  showCurrency = false,
  cardBrandRows = {},
  toggleCardBrandFields = () => {},
  toggleChargeDuesAssessmentsField = () => {},
  chargeInterchange = 'blended',
  pricingStrategyOptions = [],
  guardRail = {},
  showDuesAssessments = false,
}) => {
  return (
    <form className='CardNotPresentForm' onSubmit={handleSubmit}>
      {isFetching && <Loader />}
      {!isFetching && (
        <>
          <div className='form-content'>
            <h4>{CARD_NOT_PRESENT_PAYMENTS}</h4>
            <div className='flex space-between'>
              <div className='p-1 secondary'>{EDIT_CARD_NOT_PRESENT_PAYMENTS_SUBTITLE}</div>
              <RequiredFieldNotice />
            </div>
            <Field
              label={PRICING_STRATEGY}
              name='chargeInterchange'
              component={RadioButton}
              options={pricingStrategyOptions}
            />
            <div className='container-3'>
              <div className='item header p-2-bold'>{FEE_TYPE}</div>
              <div className='item header'>
                <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
                {showCurrency && <div className='currency'>{currency}</div>}
              </div>
              <div className='item header'>
                <div className='p-2-bold'>{FIXED_FEE}</div>
                {showCurrency && <div className='currency'>{currency}</div>}
              </div>
            </div>
            <div className='container-3'>
              <div className='item'>{ALL_CARD_BRANDS_REQUIRED}</div>
              <div className='item'>
                <PercentageField
                  name='basisPoints'
                  helperText={get(guardRail, 'basisPointsString')}
                />
              </div>
              <div className='item'>
                <AmountField
                  name='fixedFee'
                  helperText={get(guardRail, 'fixedFeeString')}
                />
              </div>
            </div>
            <div className='container-3'>
              <div className='item'>{CARD_CROSS_BORDER}</div>
              <div className='item'>
                <PercentageField
                  name='cardCrossBorderBasisPoints'
                  helperText={get(guardRail, 'cardCrossBorderBasisPointsString')}
                />
              </div>
              <div className='item'>
                <AmountField
                  name='cardCrossBorderFixedFee'
                  helperText={get(guardRail, 'cardCrossBorderFixedFeeString')}
                />
              </div>
            </div>
            <div className='container-1'>
              <div className='item sub-header'>{CARD_BRAND_OVERRIDES}</div>
            </div>
            {map(cardBrandRows, ({ basisPointsField, fixedFeeField, overrideField, enabled }, key) => {
              return (
                <div className={enabled ? 'container-4 enabled' : 'container-4 disabled'}>
                  <div className='item'>
                    <Field
                      name={overrideField}
                      component={ToggleSwitchC}
                      customHandleChange={() => toggleCardBrandFields([basisPointsField, fixedFeeField])}
                    />
                  </div>
                  <div className='item'>{key}</div>
                  <div className='item'>
                    <PercentageField
                      name={basisPointsField}
                      disabled={!enabled}
                      helperText={get(guardRail, `${basisPointsField}String`)}
                    />
                  </div>
                  <div className='item'>
                    <AmountField
                      name={fixedFeeField}
                      disabled={!enabled}
                      helperText={get(guardRail, `${fixedFeeField}String`)}
                    />
                  </div>
                </div>
              )
            })}
            <div className='container-1'>
              <div className='item sub-header'>{CHARGEBACKS}</div>
            </div>
            <div className='container-2'>
              <div className='item'>{CHARGEBACK_NOTIFICATION}</div>
              <div className='item'>
                <AmountField
                  name='disputeFixedFee'
                  helperText={get(guardRail, 'disputeFixedFeeString')}
                />
              </div>
            </div>
            <div className='container-2'>
              <div className='item'>{CHARGEBACK_INQUIRY}</div>
              <div className='item'>
                <AmountField
                  name='disputeInquiryFixedFee'
                  helperText={get(guardRail, 'disputeInquiryFixedFeeString')}
                />
              </div>
            </div>
            {chargeInterchange === 'interchange-plus' && !showDuesAssessments && (
            <>
              <div className='container-3'>
                <div className='item header p-2-bold'>{PASS_THROUGH_CARD_ASSOCIATION_FEES}</div>
                <div className='item header'>
                  <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
                  {showCurrency && <div className='currency'>{currency}</div>}
                </div>
                <div className='item header'>
                  <div className='p-2-bold'>{FIXED_FEE}</div>
                  {showCurrency && <div className='currency'>{currency}</div>}
                </div>
              </div>
              <div className='container-1'>
                <div className='item sub-header'>{VISA_LABEL}</div>
              </div>
              <div className='container-3'>
                <div className='item'>{VISA_ASSESSMENTS}</div>
                <div className='item'>
                  <PercentageField
                    name='visaAssessmentsBasisPoints'
                    helperText={get(guardRail, 'visaAssessmentsBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-2'>
                <div className='item'>{VISA_ACQUIRER_PROCESSING}</div>
                <div className='item'>
                  <AmountField
                    name='visaAcquirerProcessingFixedFee'
                    helperText={get(guardRail, 'visaAcquirerProcessingFixedFeeString')}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{VISA_CREDIT_VOUCHER}</div>
                <div className='item'>
                  <AmountField
                    name='visaCreditVoucherFixedFee'
                    helperText={get(guardRail, 'visaCreditVoucherFixedFeeString')}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{VISA_BASE_SYSTEM_FILE_TRANSMISSION}</div>
                <div className='item'>
                  <AmountField
                    name='visaBaseIISystemFileTransmissionFixedFee'
                    helperText={get(guardRail, 'visaBaseIISystemFileTransmissionFixedFeeString')}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{VISA_KILOBYTE_ACCESS}</div>
                <div className='item'>
                  <AmountField
                    name='visaKilobyteAccessFixedFee'
                    helperText={get(guardRail, 'visaKilobyteAccessFixedFeeString')}
                  />
                </div>
              </div>
              <div className='container-1'>
                <div className='item sub-header'>{MASTERCARD_LABEL}</div>
              </div>
              <div className='container-3'>
                <div className='item'>{MASTERCARD_ASSESSMENTS_UNDER_1000}</div>
                <div className='item'>
                  <PercentageField
                    name='mastercardAssessmentsUnder1kBasisPoints'
                    helperText={get(guardRail, 'mastercardAssessmentsUnder1kBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-3'>
                <div className='item'>{MASTERCARD_ASSESSMENTS_OVER_1000}</div>
                <div className='item'>
                  <PercentageField
                    name='mastercardAssessmentsOver1kBasisPoints'
                    helperText={get(guardRail, 'mastercardAssessmentsOver1kBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-3'>
                <div className='item'>{MASTERCARD_ACQUIRER_FEES}</div>
                <div className='item'>
                  <PercentageField
                    name='mastercardAcquirerFeesBasisPoints'
                    helperText={get(guardRail, 'mastercardAcquirerFeesBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-1'>
                <div className='item sub-header'>{AMEX_LABEL}</div>
              </div>
              <div className='container-3'>
                <div className='item'>{AMERICAN_EXPRESS_ASSESSMENTS}</div>
                <div className='item'>
                  <PercentageField
                    name='americanExpressAssessmentBasisPoints'
                    helperText={get(guardRail, 'americanExpressAssessmentBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-1'>
                <div className='item sub-header'>{DISCOVER_LABEL}</div>
              </div>
              <div className='container-3'>
                <div className='item'>{DISCOVER_ASSESSMENTS}</div>
                <div className='item'>
                  <PercentageField
                    name='discoverAssessmentsBasisPoints'
                    helperText={get(guardRail, 'discoverAssessmentsBasisPointsString')}
                  />
                </div>
                <div className='item' />
              </div>
              <div className='container-2'>
                <div className='item'>{DISCOVER_DATA_USAGE}</div>
                <div className='item'>
                  <AmountField
                    name='discoverDataUsageFixedFee'
                    helperText={get(guardRail, 'discoverDataUsageFixedFeeString')}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{DISCOVER_NETWORK_AUTHORIZATION}</div>
                <div className='item'>
                  <AmountField
                    name='discoverNetworkAuthorizationFixedFee'
                    helperText={get(guardRail, 'discoverNetworkAuthorizationFixedFeeString')}
                  />
                </div>
              </div>
            </>
            )}
            {chargeInterchange === 'interchange-plus' && showDuesAssessments && (
            <>
              <div className='container-2-inverse'>
                <div className='item header'>
                  <Field
                    name='chargeDuesAssessments'
                    component={ToggleSwitchC}
                    customHandleChange={toggleChargeDuesAssessmentsField}
                  />
                </div>
                <div className='item header p-2-bold flex'>
                  {PASS_THROUGH_DUES_ASSESSMENTS}
                  <div className='label-2 secondary'>{PASS_THROUGH_DUES_ASSESSMENTS_HELPER_TEXT}</div>
                </div>
              </div>
              <div className='container-2-inverse'>
                <div className='item'>
                  <Field
                    name='visaChargeDuesAssessments'
                    component={ToggleSwitchC}
                  />
                </div>
                <div className='item'>{VISA_LABEL}</div>
              </div>
              <div className='container-2-inverse'>
                <div className='item'>
                  <Field
                    name='mastercardChargeDuesAssessments'
                    component={ToggleSwitchC}
                  />
                </div>
                <div className='item'>{MASTERCARD_LABEL}</div>
              </div>
              <div className='container-2-inverse'>
                <div className='item'>
                  <Field
                    name='americanExpressChargeDuesAssessments'
                    component={ToggleSwitchC}
                  />
                </div>
                <div className='item'>{AMEX_LABEL}</div>
              </div>
              <div className='container-2-inverse'>
                <div className='item'>
                  <Field
                    name='discoverChargeDuesAssessments'
                    component={ToggleSwitchC}
                  />
                </div>
                <div className='item'>{DISCOVER_LABEL}</div>
              </div>
            </>
            )}
          </div>
        </>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SAVE_AND_CONTINUE} />
          </div>
        </div>
      </div>
    </form>
  )
}

CardNotPresentForm.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
  showCurrency: PropTypes.bool,
  cardBrandRows: PropTypes.string,
  toggleCardBrandFields: PropTypes.func,
  toggleChargeDuesAssessmentsField: PropTypes.func,
  chargeInterchange: PropTypes.string,
  pricingStrategyOptions: PropTypes.array,
  guardRail: PropTypes.object,
  showDuesAssessments: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_CNP_FORM,
  validate: validateCardNotPresentForm,
})(CardNotPresentForm)
