import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import createAction from 'utilities/actions/createAction'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import sleep from 'utilities/sleep'
import { REMOVE_SETTLEMENT_ENTRY } from 'constants/amplitudeConstants'
import size from 'lodash/size'
import get from 'lodash/get'

import {
  REFRESH_TABLE,
  REMOVE_ENTRIES,
} from 'constants/actionConstants'

import {
  UNSELECT_ALL_ITEMS,
  DELETE_SETTLEMENT_ENTRIES_F_REQUEST, GET_SEARCH_F_REQUEST,
} from 'constants/flowConstants'

const deleteSettlementEntryRequest = ({
  settlementId = '',
  settlementEntryIds = [],
  queries = {},
  credentials,
  selectedItemsKey,
  dispatch,
  entriesData = [],
}) => createAction({
  type: DELETE_SETTLEMENT_ENTRIES_F_REQUEST,
  id: settlementId,
  values: {
    action: REMOVE_ENTRIES,
    entry_ids: settlementEntryIds,
  },
  queries,
  meta: {
    actionId: sendAmplitudeActionEvent(REMOVE_SETTLEMENT_ENTRY, {
      credentials,
      settlementId,
      settlementEntryIds,
    }),
    successCallback: () => {
      // TODO: look into whether this can automatically be handled by selectedItemsR by listening to DELETE_SETTLEMENT_TRANSFERS_F_SUCCESS
      // Depending on where this event was fired, trigger specific callback
      const isSingleEntry = size(entriesData) === 1
      if (dispatch && !isSingleEntry) {
        dispatch(getSettlementRequest({ settlementId, credentials }))

        dispatch({
          type: UNSELECT_ALL_ITEMS,
          payload: {
            key: selectedItemsKey,
          },
        })

        dispatch({
          type: REFRESH_TABLE,
        })
      } else if (isSingleEntry) {
        // add time for fee to be added to exception bucket
        sleep(1500).then(() => {
          dispatch({
            type: GET_SEARCH_F_REQUEST,
            payload: {
              input: get(entriesData[0], 'id'),
              credentials,
            },
          })
        })
      }
    },
  },
  credentials,
})

export default deleteSettlementEntryRequest
