import './SendReceiptModalS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import ReactSelectCreatable from 'components/Shared/Inputs/ReactSelectCreatable/ReactSelectCreatable'
import submitSendReceiptForm from 'utilities/submit/submitSendReceiptForm'
import validateSendReceiptForm from 'utilities/validate/validateSendReceiptForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import { SEND_RECEIPT_FORM } from 'constants/formConstants'

import {
  CANCEL,
  SEND_RECEIPT,
  SEND_RECEIPT_MODAL_SUBTITLE,
  EMAIL_MULTI_INPUT_HELPER_TEXT,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
  PHONE_MULTI_INPUT_HELPER_TEXT,
  RECIPIENT_PHONE_NUMBER_PLACEHOLDER,
  PRINT as PRINT_LABEL,
  TEXT as TEXT_LABEL,
  EMAIL as EMAIL_LABEL,
} from 'constants/language/languageConstants'

const SendReceiptModal = ({
  closeModal = () => {},
  handleSubmit = () => {},
  isCardPresent = false,
  deviceOptions = [],
  invalid = false,
}) => {
  const [showFields, setShowFields] = useState({
    sendMethodEmail: false,
    sendMethodSMS: false,
    sendMethodPrint: false,
  })

  const handleCheckboxChange = (field) => {
    setShowFields((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  const { sendMethodEmail, sendMethodSMS, sendMethodPrint } = showFields

  return (
    <GenericModal
      title={SEND_RECEIPT}
      subTitle={SEND_RECEIPT_MODAL_SUBTITLE}
      className='SendReceiptModal'
    >
      <form className='SendReceiptForm' onSubmit={handleSubmit(submitSendReceiptForm)}>
        <div className='modal-content'>
          <div className='send-method-option'>
            <Field
              name='sendMethodEmail'
              component={CheckboxC}
              label={EMAIL_LABEL}
              onChange={() => handleCheckboxChange('sendMethodEmail')}
            />
            {sendMethodEmail && (
            <Field
              name='emails'
              component={ReactSelectCreatable}
              helperText={EMAIL_MULTI_INPUT_HELPER_TEXT}
              disableDropdown
              placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
              className='send-method-input'
            />
            )}
          </div>

          <div className='send-method-option'>
            <Field
              name='sendMethodSMS'
              component={CheckboxC}
              label={TEXT_LABEL}
              onChange={() => handleCheckboxChange('sendMethodSMS')}
            />
            {sendMethodSMS && (
            <Field
              name='phoneNumbers'
              component={ReactSelectCreatable}
              helperText={PHONE_MULTI_INPUT_HELPER_TEXT}
              disableDropdown
              placeholder={RECIPIENT_PHONE_NUMBER_PLACEHOLDER}
              className='send-method-input'
              formatValue={formatPhone}
            />
            )}
          </div>

          {isCardPresent && (
          <div className='send-method-option'>
            <Field
              name='sendMethodPrint'
              component={CheckboxC}
              label={PRINT_LABEL}
              onChange={() => handleCheckboxChange('sendMethodPrint')}
            />
            {sendMethodPrint && (
            <Field
              name='device'
              label='Device'
              component={ReactSelect}
              options={deviceOptions}
              required={false}
              customStyles={{
                option: {
                  textAlign: 'left',
                },
                menu: {
                  position: 'relative',
                },
              }}
            />
            )}
          </div>
          )}
        </div>

        <div className='buttons flex flex-end'>
          <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />

          <Button
            type='submit'
            label='Send'
            className='submit-button'
            disabled={invalid}
          />
        </div>
      </form>
    </GenericModal>
  )
}

SendReceiptModal.propTypes = {
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  isCardPresent: PropTypes.bool,
  deviceOptions: PropTypes.array,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: SEND_RECEIPT_FORM,
  validate: validateSendReceiptForm,
})(SendReceiptModal)
