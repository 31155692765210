import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_ENROLLMENTS,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const getSubscriptionEnrollmentsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    path: SUBSCRIPTION_ENROLLMENTS,
    queries,
    service: SUBSCRIPTION,
  })
}

export default getSubscriptionEnrollmentsAPI
