import { ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX } from 'constants/regexConstants'
import size from 'lodash/size'
import trim from 'lodash/trim'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
} from 'utilities/validate'

import {
  DEVICE_NAME,
  SERIAL_NUMBER,
} from 'constants/language/languageConstants'

const validateEditDeviceDetailsForm = (values, props) => {
  const {
    deviceName,
    serialNumber,
  } = values

  return {
    deviceName: fieldEmpty(deviceName, DEVICE_NAME),
    serialNumber: invalidOrEmptyRegex({ field: serialNumber, name: SERIAL_NUMBER, regex: ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX, customErrorMessage: `${SERIAL_NUMBER} cannot contain special characters.` })
      || (size(trim(serialNumber)) > 30 && `${SERIAL_NUMBER} must be 30 digits long.`),
  }
}

export default validateEditDeviceDetailsForm
