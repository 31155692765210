import React from 'react'
import PropTypes from 'prop-types'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'

import {
  PAYMENT_CARD,
  PAYMENT_CARD_PRESENT,
  BANK_ACCOUNT,
  APPLE_PAY,
  GOOGLE_PAY,
} from 'constants/paymentInstrumentConstants'

import {
  TYPE,
  CREATED_VIA,
  CARD_BRAND,
  CARD_TYPE,
  MASKED_NUMBER,
  EXPIRATION_DATE,
  CVV_VERIFICATION,
  ISSUER_COUNTRY,
  ADDRESS_VERIFICATION,
  ROUTING_NUMBER,
  BILLING_ADDRESS,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  CONNECTION_TYPE,
} from 'constants/language/languageConstants'

const formatPaymentInstrumentPageDetailSection = ({
  paymentInstrument = {},
}) => {
  const [
    type,
    cardBrand,
    cardType,
    expirationDate,
    maskedCardNumber,
    maskedAccountNumber,
    bankCode,
    displayCVVVerification,
    issuerCountry,
    displayAddressVerification,
    displayAddress,
    createdVia,
    transitNumber,
    institutionNumber,
    connectionType,
  ] = getMany(paymentInstrument, [
    'type',
    'displayBrand',
    'cardType',
    'expirationDate',
    'maskedFullCardNumber',
    'maskedAccountNumber',
    'bankCode',
    'displaySecurityCodeVerification',
    'displayIssuerCountry',
    'displayAddressVerification',
    'displayAddress',
    'displayCreatedVia',
    'transitNumber',
    'institutionNumber',
    'connectionType',
  ])

  switch (type) {
    case PAYMENT_CARD:
      return [
        {
          type: 'data',
          data: [
            {
              label: TYPE,
              value: type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
            {
              label: CARD_TYPE,
              value: cardType,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: CVV_VERIFICATION,
              value: displayCVVVerification,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: BILLING_ADDRESS,
              value: displayAddress,
            },
            {
              label: ADDRESS_VERIFICATION,
              value: displayAddressVerification,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
          ],
        },
      ]

    case PAYMENT_CARD_PRESENT:
      return [
        {
          type: 'data',
          data: [
            {
              label: TYPE,
              value: type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
            {
              label: CARD_TYPE,
              value: cardType,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
          ],
        },
      ]

    case BANK_ACCOUNT:
      return convertPageSectionDataToV2([
        {
          label: TYPE,
          value: type,
        },
        {
          label: CONNECTION_TYPE,
          value: connectionType,
        },
        {
          label: MASKED_NUMBER,
          value: maskedAccountNumber,
        },
        {
          label: ROUTING_NUMBER,
          value: bankCode,
          condition: !!bankCode,
        },
        {
          label: TRANSIT_NUMBER,
          value: transitNumber,
          condition: !!transitNumber,
        },
        {
          label: INSTITUTION_NUMBER,
          value: institutionNumber,
          condition: !!institutionNumber,
        },
        {
          label: CREATED_VIA,
          value: createdVia,
        },
      ])

    case APPLE_PAY:
    case GOOGLE_PAY:
      return [
        {
          type: 'data',
          data: [
            {
              label: TYPE,
              value: type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
          ],
        },
      ]

    default:
      return []
  }
}

formatPaymentInstrumentPageDetailSection.propTypes = {
  paymentInstrument: PropTypes.object,
}

export default formatPaymentInstrumentPageDetailSection
