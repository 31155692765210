import selectOption from 'utilities/forms/selectOption'

import {
  LITLE_V1,
  VANTIV_V1,
} from 'constants/processorConstants'

export const FOR_BENEFIT_OF_ACCOUNT = 'FOR_BENEFIT_OF_ACCOUNT'
export const OPERATING_ACCOUNT = 'OPERATING_ACCOUNT'

export const sourceDestinationOptions = [
  selectOption('Select Type' , ''),
  selectOption('FBO' , FOR_BENEFIT_OF_ACCOUNT),
  selectOption('Operating' , OPERATING_ACCOUNT),
]

export const sourceDestinationMapping = {
  FOR_BENEFIT_OF_ACCOUNT: 'FBO',
  OPERATING_ACCOUNT: 'Operating',
}

export const balanceTransferProcessors = {
  LITLE_V1,
  VANTIV_V1,
}
