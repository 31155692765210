import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewQueueIdentities from './ReviewQueueIdentities'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { REVIEW_QUEUE_IDENTITY_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import patchReviewQueueItemsRequest from 'utilities/actions/patch/patchReviewQueueItemsRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { IDENTITY, REJECT_REASONS, PENDING, ACCEPTED, REJECTED } from 'constants/reviewQueueConstants'
import pick from 'lodash/pick'
import map from 'lodash/map'

import { REVIEW_QUEUE_IDENTITIES } from 'constants/selectedItemsConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import getDashboardUser from 'utilities/get/getDashboardUser'

import {
  getReviewQueueSelector,
  getSelectedItemsByKeySelector,
} from 'state-layer/selectors'

import {
  SORT,
  LIMIT,
  OFFSET,
} from 'constants/queryConstants'

const entityType = IDENTITY
const selectedItemsKey = REVIEW_QUEUE_IDENTITIES

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  entity_type: IDENTITY,
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `reviewQueueR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const identitiesPath = ({ id }) => REVIEW_QUEUE_IDENTITY_PATH({ reviewQueueId: id, credentialId })
  const queries = get(props, 'queries', {})
  const outcome = get(queries, 'outcome')
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)
  const mergedQueries = merge({}, initialQueries, queries)
  const reviewQueueIdentities = getReviewQueueSelector(state)
  const selectedReviewQueueIdentities = getSelectedItemsByKeySelector(state, selectedItemsKey)

  return {
    initialQueries: mergedQueries,
    isFetching,
    identitiesPath,
    outcome,
    limit,
    offset,
    sort,
    credentials,
    selectedItemsKey,
    reviewQueueIdentities,
    selectedReviewQueueIdentities,
    currentUserEmail,
  }
}

class ReviewQueueIdentitiesC extends Component {
  updateSelectedIdentitiesStatus = ({ status, assignee, reason }) => {
    const {
      limit,
      offset,
      sort,
      outcome,
      dispatch,
      credentials,
      reviewQueueIdentities,
      selectedReviewQueueIdentities,
    } = this.props

    const selectedIds = Object.keys(selectedReviewQueueIdentities)
    const selectedReviewQueueItems = pick(reviewQueueIdentities, selectedIds)

    const items = map(selectedReviewQueueItems, item => {
      const tags = get(items, 'tags', {})
      const itemId = get(item, 'id')
      const newTags = merge({}, tags, { assigned_to: assignee, decision_reason: reason })

      return removeUndefined({
        id: itemId,
        tags: newTags,
        outcome: status,
      })
    })

    const queries = {
      limit,
      offset,
      sort,
      outcome,
      entity_type: entityType,
    }

    dispatch(
      patchReviewQueueItemsRequest({
        items,
        queries,
        selectedItemsKey,
        credentials,
        dispatch,
      }),
    )
  }

  updateSelectedIdentitiesAssignment = () => {
    const { currentUserEmail } = this.props
    this.updateSelectedIdentitiesStatus({ assignee: currentUserEmail })
  }


  createPendingActionsMap = () => {
    const { updateSelectedIdentitiesAssignment, updateSelectedIdentitiesStatus } = this
    const pendingActionsMap = []

    pendingActionsMap.push({
      label: 'Assign to me',
      action: updateSelectedIdentitiesAssignment,
    })

    REJECT_REASONS.forEach(reason => {
      pendingActionsMap.push({
        label: `Reject (${reason})`,
        action: () => updateSelectedIdentitiesStatus({ status: REJECTED, reason }),
      })
    })

    pendingActionsMap.push({
      label: 'Approve',
      action: () => updateSelectedIdentitiesStatus({ status: ACCEPTED }),
    })

    return pendingActionsMap
  }

  outcomeActionsMap = () => ({
    [PENDING]: this.createPendingActionsMap(),
  });

  render() {
    const { outcome } = this.props
    const outcomeActionsMap = get(this, 'outcomeActionsMap', () => {})
    const actionsMap = outcomeActionsMap()
    const selectedItemsAction = get(actionsMap, outcome, [])

    const selectedItemsData = {
      actions: selectedItemsAction,
    }

    return (
      <ReviewQueueIdentities
        {...this.props}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps)(ReviewQueueIdentitiesC)
