import axios from 'axios'
import postFileAPI from 'api/dashboard/post/postFileAPI'
import postFileExternalLinksAPI from 'api/dashboard/post/postFileExternalLinksAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import get from 'lodash/get'
import co from 'co'

function * postFileO ({
  values,
  credentials,
}) {
  const {
    file,
    documentType,
    nameOfFile,
    merchantId,
  } = values

  const valuesToPost = {
    display_name: nameOfFile,
    linked_to: merchantId,
    file_type: documentType,
    action_type: 'UPLOAD',
  }

  const { data: postFileResponse } = yield postFileAPI({
    credentials,
    values: valuesToPost,
  })

  const externalLinkURL = get(postFileResponse, 'external_link.url')

  try {
    yield axios.put(externalLinkURL, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    // set the status to pending while the BE waits for response from S3
    const pendingFile = { ...postFileResponse, status: 'PENDING' }

    return createSuccessResponse({
      data: [pendingFile],
    })
  } catch (error) {
    return createErrorResponse({ error })
  }
}

export default co.wrap(postFileO)
