import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import removeUndefined from 'utilities/remove/removeUndefined'

const frontendRoleModel = ({ data: role }) => {
  const [
    id,
    createdAt,
    entityType,
    entityId,
    updatedAt,
    roleName,
    authRole,
    levelId,
    type,
    env,
    createUser,
    createCustomRole,
    roleEnabled,
    permissions,
    applicationId,
    platformId,
    description,
  ] = getMany(role, [
    'id',
    'created_at',
    'entity_type',
    'entity_id',
    'updated_at',
    'name',
    'auth_role',
    'level_id',
    'type',
    'env',
    'create_user',
    'create_custom_role',
    'role_enabled',
    'permissions',
    'application_id',
    'platform_id',
    'description',
  ])

  return removeUndefined({
    id,
    displayCreatedAt: formatDate({ date: createdAt }),
    entityType,
    entityId,
    createdAt,
    updatedAt,
    roleName,
    authRole,
    levelId,
    type,
    env,
    createUser,
    createCustomRole,
    roleEnabled,
    permissions,
    applicationId,
    platformId,
    description,
  })
}

export default frontendRoleModel
