import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_TOTAL_SPLIT_PAYOUT_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_SPLIT_PAYOUT_AMOUNT,
  TRANSACTION_MONITORING_SPLIT_PAYOUT_COUNT,
  TRANSACTION_MONITORING_SETTLEMENT_COUNT,
} from 'constants/language/languageConstants'

const SplitPayoutCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    totalPayoutAmount,
    averagePayoutAmount,
    payoutCount,
    settlementCount,
  ] = getMany(calculations, [
    'displaySplitPayoutAmountDollarsSum',
    'displaySplitPayoutAmountDollarsAverage',
    'splitPayoutCount',
    'settlementCount',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_SPLIT_PAYOUT_AMOUNT}
        headerData={{
          value: totalPayoutAmount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_SPLIT_PAYOUT_AMOUNT}
        headerData={{
          value: averagePayoutAmount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SPLIT_PAYOUT_COUNT}
        headerData={{
          value: payoutCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SETTLEMENT_COUNT}
        headerData={{
          value: settlementCount,
        }}
      />
    </div>
  )
}

export default SplitPayoutCards
