import hideModalAction from 'utilities/actions/hideModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'

import {
  MERCHANT_RISK_PROFILE,
  RISK_PROFILE,
} from 'constants/amplitudeConstants'

import {
  PATCH_RISK_PROFILE_F_REQUEST,
  PATCH_MERCHANT_RISK_PROFILE_F_REQUEST,
} from 'constants/flowConstants'

const submitRiskProfileForm = (values, dispatch, props) => {
  const {
    riskProfile,
    credentials,
    merchantId,
    applicationId,
  } = props

  const { id } = riskProfile
  const updatedValues = reduce(values, (total, value, key) => {
    return value !== get(riskProfile, key) ? merge({}, total, { [key]: value }) : total
  }, {})

  if (isEmpty(updatedValues)) { return }

  if (applicationId) {
    dispatch({
      type: PATCH_RISK_PROFILE_F_REQUEST,
      payload: {
        id,
        credentials,
        values: updatedValues,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(RISK_PROFILE, {
          credentials,
          riskProfile: updatedValues,
          applicationId,
        }),
        successCallback: ({ newValues = {} }) => {
          dispatch(hideModalAction())
        },
      },
    })
  } else {
    dispatch({
      type: PATCH_MERCHANT_RISK_PROFILE_F_REQUEST,
      payload: {
        id,
        credentials,
        values: updatedValues,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(MERCHANT_RISK_PROFILE, {
          credentials,
          riskProfile: updatedValues,
          merchantId,
        }),
        merchantId,
        successCallback: ({ newValues = {} }) => {
          dispatch(hideModalAction())
        },
      },
    })
  }
}

export default submitRiskProfileForm
