import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { fileStatusMap } from 'constants/statusConstants'

import {
  ID,
  CREATED_ON,
  NAME,
  TYPE,
  STATUS,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'x-small',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NAME, 'displayName', { className: 'name' }),
  column(TYPE, 'displayType'),
  column(STATUS, 'displayStatus', {
    className: 'small',
    formatter: ({ status, displayStatus }) => <ColorcodedStatus value={status} customValue={displayStatus} data={fileStatusMap} />,
  }),
]

export default columnDescriptors
