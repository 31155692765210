import { validateFile } from 'utilities/validate'

const validateEmailConfigurationForm = (values, { config }) => {
  const { emailLogo } = values

  const validatedFile = emailLogo ? validateFile(emailLogo, config) : false

  return {
    emailLogo: validatedFile,
  }
}

export default validateEmailConfigurationForm
