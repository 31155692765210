import paymentsAPI from 'utilities/api/paymentsAPI'
import { RECEIPTS } from 'constants/apiConstants'

const getReceiptsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: RECEIPTS,
    queries,
    credentials,
    service: RECEIPTS,
  })
}

export default getReceiptsAPI
