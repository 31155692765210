import './AddNoteS.scss'
import 'react-quill/dist/quill.snow.css'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import SelectInput from 'components/Shared/Inputs/SelectInput/SelectInput'
import ReactQuill from 'react-quill'
import { defaultToolbarOptions } from 'constants/noteConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  t,
  SUBMIT,
  LAST_SAVED,
  SUBMIT_NOTE,
  ADD_NEW_NOTE,
  SAVING_IN_PROGRESS,
} from 'constants/language/languageConstants'

const AddNote = ({
  quillRef = {},
  textUpdated = () => {},
  changeTemplate = () => {},
  templates = [],
  dispatchShowSubmitNoteModal = () => {},
  height = '300px',
  isSaving = false,
  updatedAt = '',
  isEditorEmpty = () => {},
}) => {
  return (
    <div className='AddNote'>
      <div className='header'>
        <h4>{t(ADD_NEW_NOTE)}</h4>
      </div>

      { !isEmpty(templates) && (
        <div className='templates'>
          <SelectInput
            name='templates'
            options={map(templates, ({ name }) => ({ label: name, value: name }))}
            onChange={changeTemplate}
          />
        </div>
      )}

      <div className='quill-container'>
        <ReactQuill
          theme='snow'
          modules={{ toolbar: defaultToolbarOptions }}
          onChange={textUpdated}
          ref={quillRef}
          style={{ height }}
        />
      </div>

      <div className='buttons flex flex-end'>
        <div className='saving flex'>
          { isSaving && <span className='flex p-2'><Loader /> {t(SAVING_IN_PROGRESS)}</span> }
          { !isSaving && updatedAt && <span className='flex p-2'><i className={`fa fa-${CHECK_CIRCLE_ICON}`} /> {t(LAST_SAVED)}: {updatedAt}</span> }
          <Button className='submit-button' label={SUBMIT_NOTE} disabled={isSaving || isEditorEmpty()} onClick={dispatchShowSubmitNoteModal} type='submit'>{t(SUBMIT)}</Button>
        </div>
      </div>
    </div>
  )
}

AddNote.propTypes = {
  quillRef: PropTypes.object,
  textUpdated: PropTypes.func,
  changeTemplate: PropTypes.func,
  templates: PropTypes.array,
  dispatchShowSubmitNoteModal: PropTypes.func,
  height: PropTypes.string,
  isSaving: PropTypes.bool,
  updatedAt: PropTypes.string,
  isEditorEmpty: PropTypes.func,
}

export default AddNote
