import './AddNewDomainFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import validateAddNewDomainForm from 'utilities/validate/validateAddNewDomainForm'
import { ADD_NEW_DOMAIN_FORM } from 'constants/formConstants'
import { APPLE_PAY_REGISTER_DOMAIN_INSTRUCTION } from 'constants/language/languageConstants'
import trim from 'lodash/trim'

const AddNewDomainForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
}) => {
  return (
    <form className='AddNewDomainForm' onSubmit={handleSubmit}>
      <div className='primary-msg'>Enter domain name</div>
      <div>{APPLE_PAY_REGISTER_DOMAIN_INSTRUCTION}</div>

      <Field
        name='websiteDomain'
        placeholder='company.com'
        component={InputField}
        normalize={trim}
      />

      { buttonGroup }
    </form>
  )
}

AddNewDomainForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: ADD_NEW_DOMAIN_FORM,
  validate: validateAddNewDomainForm,
})(AddNewDomainForm)
