import { GET_ROLES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getRolesRequest = ({
  queries,
  credentials,
  meta,
}) => createAction({
  type: GET_ROLES_F_REQUEST,
  queries,
  credentials,
  meta,
})

export default getRolesRequest
