import { SHOW_PREVIEW_PANEL } from 'constants/actionConstants'
import { createAction } from 'redux-actions'

const showPreviewPanelAction = ({
  panelProps,
}) => createAction(
  SHOW_PREVIEW_PANEL,
  () => ({
    panelProps,
  }),
)()

export default showPreviewPanelAction
