import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import { goBack } from 'state-layer/history'
import { CREATE_CUSTOM_ROLE, UPDATE_CUSTOM_ROLE } from 'constants/amplitudeConstants'
import { entityTypeToRoleMap } from 'constants/memberConstants'
import get from 'lodash/get'
import keys from 'lodash/keys'
import omitBy from 'lodash/omitBy'
import forEach from 'lodash/forEach'
import concat from 'lodash/concat'

import {
  PATCH_ROLE_F_REQUEST,
  POST_ROLE_F_REQUEST,
} from 'constants/flowConstants'

import {
  ALL_PERMISSIONS_LIST,
  filteredAllPermissionsByRole,
  permissionsForAllFilteredByRole,
} from 'constants/permissionConstants'

const submitRoleForm = (values, dispatch, props) => {
  const [
    credentials,
    entityId,
    entityType,
    roleId,
  ] = getMany(props, [
    'credentials',
    'entityId',
    'entityType',
    'roleId',
  ])

  const authRole = get(entityTypeToRoleMap, entityType)

  const [
    roleDescription,
    roleName,
    selectedEvents,
  ] = getMany(values, [
    'roleDescription',
    'roleName',
    'selectedEvents',
  ])

  let permissions = permissionsForAllFilteredByRole({ authRole })
  const allPermissions = filteredAllPermissionsByRole({ permissionList: ALL_PERMISSIONS_LIST, authRole })
  const selectedPermissionTitles = []

  forEach(selectedEvents, (event) => {
    const truthySelectedEvents = omitBy(event, (permission) => !permission)
    const selectedEventsPerGroup = keys(truthySelectedEvents)
    forEach(selectedEventsPerGroup, (eventLabel) => {
      selectedPermissionTitles.push(eventLabel)
      const permissionsPerEvent = get(allPermissions, [eventLabel, 'permissions'])
      permissions = concat(permissions, permissionsPerEvent)
    })
  })

  if (roleId) {
    dispatch({
      type: PATCH_ROLE_F_REQUEST,
      payload: {
        id: roleId,
        values: {
          permissions,
          name: roleName,
          description: roleDescription,
        },
        credentials,
      },
      meta: {
        successCallback: () => {
          // redirect user back to team members page
          dispatch(goBack)
        },
        actionId: sendAmplitudeActionEvent(UPDATE_CUSTOM_ROLE, {
          credentials,
          roleName,
          roleDescription,
          entityId,
          permissions: selectedPermissionTitles,
        }),
      },
    })
  } else {
    dispatch({
      type: POST_ROLE_F_REQUEST,
      payload: {
        values: {
          permissions,
          name: roleName,
          description: roleDescription,
          entity_id: entityId,
          auth_role: authRole,
        },
        credentials,
      },
      meta: {
        successCallback: () => {
          // redirect user back to team members page
          dispatch(goBack)
        },
        actionId: sendAmplitudeActionEvent(CREATE_CUSTOM_ROLE, {
          credentials,
          roleName,
          roleDescription,
          entityId,
          permissions: selectedPermissionTitles,
        }),
      },
    })
  }
}

export default submitRoleForm
