import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateAPIKeyForm from 'components/Customer/Forms/CreateAPIKeyForm/CreateAPIKeyForm'
import get from 'lodash/get'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const platformId = get(props, 'platformId')
  const credentials = getCurrentCredentials(state)

  return {
    applicationId,
    platformId,
    credentials,
  }
}

class CreateAPIKeyFormC extends Component {
  render() {
    return (
      <CreateAPIKeyForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateAPIKeyFormC)
