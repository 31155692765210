import React from 'react'
import PropTypes from 'prop-types'
import CreatePaymentInstrumentVerificationFormC from 'components/Customer/Forms/CreatePaymentInstrumentVerificationForm/CreatePaymentInstrumentVerificationFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const CreatePaymentInstrumentVerificationModal = ({
  paymentInstrumentId = '',
  availableProcessors = [],
}) => {
  return (
    <GenericModal
      title='Create a Verification'
      className='CreatePaymentInstrumentVerificationModal'
      Component={CreatePaymentInstrumentVerificationFormC}
      paymentInstrumentId={paymentInstrumentId}
      availableProcessors={availableProcessors}
    />
  )
}

CreatePaymentInstrumentVerificationModal.propTypes = {
  paymentInstrumentId: PropTypes.string,
  availableProcessors: PropTypes.array,
}

export default CreatePaymentInstrumentVerificationModal
