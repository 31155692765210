import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import GenericEmailForm from 'components/Admin/Forms/GenericEmailForm/GenericEmailForm'
import { GENERIC_EMAIL_FORM } from 'constants/formConstants'
import validateGenericEmailForm from 'utilities/validate/validateGenericEmailForm'
import get from 'lodash/get'
import some from 'lodash/some'
import isEqual from 'lodash/isEqual'

const mapStateToProps = (state, props) => {
  const email = get(props, 'email')
  const templateExist = some(['html', 'subject', 'text'], (property) => get(email, property) !== undefined)

  return {
    templateExist,
  }
}


class GenericEmailFormC extends Component {
  componentDidUpdate(prevProps) {
    const { email: prevEmail } = prevProps

    const {
      initialize,
      initialized,
      email,
    } = this.props

    if (!isEqual(email, prevEmail) || !initialized) {
      initialize(email)
    }
  }

  render() {
    return (
      <GenericEmailForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: GENERIC_EMAIL_FORM,
  validate: validateGenericEmailForm,
})(GenericEmailFormC))
