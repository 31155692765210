import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Warning from 'components/Shared/Warning/Warning'
import submitEditIdentityBankAccountsForm from 'utilities/submit/submitEditIdentityBankAccountsForm'
import { EDIT_IDENTITY_BANK_ACCOUNTS_FORM } from 'constants/formConstants'
import { SUBMIT } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  MERCHANT_PRIMARY_BANK_ACCOUNT_FE,
  MERCHANT_SECONDARY_BANK_ACCOUNT_FE,
  MERCHANT_TERTIARY_BANK_ACCOUNT_FE,
} from 'constants/paymentInstrumentConstants'

const EditIdentityBankAccountsForm = ({
  handleSubmit = () => {},
  submitting = false,
  paymentInstrumentsOptionsObject,
  settlementAliasWarningObject,
}) => {
  return (
    <form className='EditIdentityBankAccountsForm' onSubmit={handleSubmit(submitEditIdentityBankAccountsForm)}>

      <Field
        name={MERCHANT_PRIMARY_BANK_ACCOUNT_FE}
        label='Merchant Primary Bank Account'
        component={SelectField}
        options={get(paymentInstrumentsOptionsObject, MERCHANT_PRIMARY_BANK_ACCOUNT_FE)}
      />

      <Field
        name={MERCHANT_SECONDARY_BANK_ACCOUNT_FE}
        label='Merchant Secondary Bank Account'
        component={SelectField}
        options={get(paymentInstrumentsOptionsObject, MERCHANT_SECONDARY_BANK_ACCOUNT_FE)}
      />

      <Field
        name={MERCHANT_TERTIARY_BANK_ACCOUNT_FE}
        label='Merchant Tertiary Bank Account'
        component={SelectField}
        options={get(paymentInstrumentsOptionsObject, MERCHANT_TERTIARY_BANK_ACCOUNT_FE)}
      />

      <Warning {...settlementAliasWarningObject} />

      <div className='buttons flex flex-end'>
        <Button type='submit' label={SUBMIT} submitting={submitting} />
      </div>
    </form>
  )
}

EditIdentityBankAccountsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  paymentInstrumentsOptionsObject: PropTypes.object,
  settlementAliasWarningObject: PropTypes.object,
}

export default reduxForm({
  form: EDIT_IDENTITY_BANK_ACCOUNTS_FORM,
})(EditIdentityBankAccountsForm)
