import PropTypes from 'prop-types'
import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const Buyer = ({
  tabs = [],
  contextBarData = {},
  headerData = {},
  isFetching = false,
  actions = [],
}) => {
  return (
    <div className='Buyer'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

Buyer.propTypes = {
  tabs: PropTypes.array,
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
}

export default Buyer
