import paymentsAPI from 'utilities/api/paymentsAPI'
import { TRANSFER_DISPUTES } from 'constants/apiConstants'
import get from 'lodash/get'

const getTransferDisputesAPI = ({ values, queries, credentials, meta }) => {
  const transferId = get(values, 'transferId')

  return paymentsAPI.get({
    meta,
    path: TRANSFER_DISPUTES({ transferId }),
    queries,
    credentials,
  })
}

export default getTransferDisputesAPI
