import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import PasswordInputFieldC from 'components/Shared/Inputs/PasswordInputField/PasswordInputFieldC'
import Button from 'components/Shared/Button/Button'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import validateSelfServiceSignupForm from 'utilities/validate/validateSelfServiceSignupForm'
import submitSelfServiceSignupForm from 'utilities/submit/submitSelfServiceSignupForm'
import { SELF_SERVICE_SIGNUP_FORM } from 'constants/formConstants'
import { countryNamesOptions } from 'constants/countryConstants'
import { H_CAPTCHA_SITE_KEY } from 'constants/hCaptchaConstants'

import {
  COMPANY_EMAIL,
  COMPANY_NAME,
  COUNTRY,
  CREATE_ACCOUNT,
  CREATE_PASSWORD_TITLE_CASE,
  FIRST_NAME,
  LAST_NAME,
  MINIMUM_8_CHARACTERS,
} from 'constants/language/languageConstants'

const SelfServiceSignupForm = ({
  handleSubmit = () => {},
  passwordRequirements = [],
  onVerifyCaptcha = () => {},
  captchaRef,
  hCaptchaFormError,
  checkboxLabel = '',
}) => {
  return (
    <form className='SelfServiceSignupForm' onSubmit={handleSubmit(submitSelfServiceSignupForm)}>
      <div className='fullName flex'>
        <Field
          name='firstName'
          component={InputField}
          label={FIRST_NAME}
          className='firstName self-service-input'
        />

        <Field
          name='lastName'
          component={InputField}
          label={LAST_NAME}
          className='lastName self-service-input'
        />
      </div>
      <Field
        name='companyEmail'
        component={InputField}
        label={COMPANY_EMAIL}
        className='self-service-input'
      />

      <Field
        name='password'
        component={PasswordInputFieldC}
        label={CREATE_PASSWORD_TITLE_CASE}
        className='self-service-input'
        passwordRequirements={passwordRequirements}
        placeholder={MINIMUM_8_CHARACTERS}
        formName={SELF_SERVICE_SIGNUP_FORM}
      />

      <Field
        name='companyName'
        component={InputField}
        label={COMPANY_NAME}
        className='self-service-input'
      />

      <Field
        name='country'
        label={COUNTRY}
        component={SelectField}
        options={countryNamesOptions}
        className='self-service-input'
      />

      <Field
        name='marketingCheckbox'
        checkboxLabel={checkboxLabel}
        component={CheckboxInput}
      />

      <HCaptcha
        id='self-service-h-captcha'
        sitekey={H_CAPTCHA_SITE_KEY}
        onVerify={(token) => onVerifyCaptcha(token)}
        ref={captchaRef}
      />

      {hCaptchaFormError && <div className='hcaptcha-error'>Please verify you are human.</div>}

      <Field
        name='hCaptchaToken'
        component={InputField}
        className='hcpatcha-hidden-input'
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' className='submit-button' label={CREATE_ACCOUNT} />
      </div>
    </form>
  )
}

SelfServiceSignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  passwordRequirements: PropTypes.array,
  onVerifyCaptcha: PropTypes.func,
  captchaRef: PropTypes.string,
  hCaptchaFormError: PropTypes.bool,
  checkboxLabel: PropTypes.string,
}

export default reduxForm({
  form: SELF_SERVICE_SIGNUP_FORM,
  validate: validateSelfServiceSignupForm,
})(SelfServiceSignupForm)
