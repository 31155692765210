import React from 'react'

const X = ({
  width = '12',
  height = '12',
  fill = '#020F1C',
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 12 12' fill='none'>
      <path d='M7.78127 5.98127L11.8313 10.0313C12.0562 10.2563 12.0562 10.6688 11.8313 10.8938L10.8938 11.8313C10.6688 12.0563 10.2563 12.0563 10.0313 11.8313L6.01877 7.78127L1.96876 11.8313C1.74375 12.0563 1.33125 12.0563 1.10625 11.8313L0.16875 10.8938C-0.0562502 10.6688 -0.0562502 10.2563 0.16875 10.0313L4.21876 5.98127L0.16875 1.96876C-0.0562502 1.74375 -0.0562502 1.33125 0.16875 1.10625L1.10625 0.16875C1.33125 -0.0562501 1.74375 -0.0562501 1.96876 0.16875L6.01877 4.21876L10.0313 0.16875C10.2563 -0.0562501 10.6688 -0.0562501 10.8938 0.16875L11.8313 1.10625C12.0562 1.33125 12.0562 1.74375 11.8313 1.96876L7.78127 5.98127Z' fill={fill} />
    </svg>
  )
}

export default X
