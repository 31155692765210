import createAction from 'utilities/actions/createAction'
import { GET_WEBHOOK_LOG_F_REQUEST } from 'constants/flowConstants'

const getWebhookRequest = ({
  webhookLogId,
  credentials,
}) => createAction({
  type: GET_WEBHOOK_LOG_F_REQUEST,
  id: webhookLogId,
  credentials,
})

export default getWebhookRequest
