import {
  fieldEmpty,
  validateFile,
} from 'utilities/validate'

const getImageTags = (html = '') => {
  const tags = []
  let currentIndex = 0

  while (html.indexOf('<img', currentIndex) >= 0) {
    const start = html.indexOf('<img', currentIndex)
    const end = html.indexOf('>', start)
    tags.push([start, end])
    currentIndex = end + 1
  }

  return tags
}

const imageHasSrc = (imageTag) => {
  const start = imageTag.indexOf('src=')
  const end = imageTag.indexOf(' ', start)

  return start && end !== -1 && end !== imageTag.length - 1
}

const validateWelcomeEmailForm = ({ subject = '', html = '', text = '', logo } = {}, { config } = {}) => {
  let htmlError

  if (logo) {
    const tags = getImageTags(html)
    const tagsCount = tags.length

    if (tagsCount <= 0) {
      htmlError = 'Please provide an image tag with "src={{src}}" placeholder for the logo'
    } else if (tagsCount > 1) {
      htmlError = `Customized logo only apply to one image tag, html template has ${tags.length}`
    } else {
      const [[start, end]] = tags
      const imageTag = html.substring(start, end + 1)

      if (!imageHasSrc(imageTag)) {
        htmlError = 'Please provide "src={{src}}" placeholder to image tag with spaces separeting other code'
      }
    }
  }

  return {
    subject: fieldEmpty(subject, 'Email Subject'),
    html: htmlError || fieldEmpty(html, 'Html Body'),
    text: fieldEmpty(text, 'Text Body'),
    logo: logo && validateFile(logo, config),
  }
}

export default validateWelcomeEmailForm
