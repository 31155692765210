export const IDENTITY_SETTLEMENT = 'IDENTITY_SETTLEMENT'
export const ADD_IDENTITY_BANK_ACCOUNT = 'ADD_IDENTITY_BANK_ACCOUNT'
export const EDIT_IDENTITY_BANK_ACCOUNTS = 'EDIT_IDENTITY_BANK_ACCOUNTS'
export const IDENTITY_PROVISION_MERCHANT = 'IDENTITY_PROVISION_MERCHANT'
export const IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION'
export const EDIT_TAGS = 'EDIT_TAGS'
export const EDIT_APPLICATION_SETTING = 'EDIT_APPLICATION_SETTING'
export const EDIT_MERCHANT_SETTING = 'EDIT_MERCHANT_SETTING'
export const INVITE_USER = 'INVITE_USER'
export const SEARCH_USER = 'SEARCH_USER'
export const SEND_EMAIL = 'SEND_EMAIL'
export const ADD_APPLICATION_PROCESSORS = 'ADD_APPLICATION_PROCESSORS'
export const CREATE_ADJUSTMENT = 'CREATE_ADJUSTMENT'
export const REMOVE_SETTLEMENT_ENTRIES = 'REMOVE_SETTLEMENT_ENTRIES'
export const UPLOAD_EVIDENCE = 'UPLOAD_EVIDENCE'
export const CREATE_API_KEY = 'CREATE_API_KEY'
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS'
export const CREATE_MERCHANT_VERIFICATION = 'CREATE_MERCHANT_VERIFICATION'
export const CONFIRM_ACTION = 'CONFIRM_ACTION'
export const EDIT_RISK_PROFILE = 'EDIT_RISK_PROFILE'
export const APPROVE_ALL_CONFIRM_ACTION = 'APPROVE_ALL_CONFIRM_ACTION'
export const EXPORT_DATA = 'EXPORT_DATA'
export const EXPORT_DATA_V2 = 'EXPORT_DATA_V2'
export const EXPORT_CONFIRMATION = 'EXPORT_CONFIRMATION'
export const RESEND_TRANSFER = 'RESEND_TRANSFER'
export const CREATE_PAYMENT_INSTRUMENT_VERIFICATION = 'CREATE_PAYMENT_INSTRUMENT_VERIFICATION'
export const EDIT_PROCESSOR_CONFIG = 'EDIT_PROCESSOR_CONFIG'
export const CLOSE_SETTLEMENT = 'CLOSE_SETTLEMENT'
export const UPDATE_ALL_PERMISSIONS = 'UPDATE_ALL_PERMISSIONS'
export const UPDATE_USER = 'UPDATE_USER'
export const ORCHESTRATION_STATUS = 'ORCHESTRATION_STATUS'
export const CREATE_BALANCE_TRANSFER = 'CREATE_BALANCE_TRANSFER'
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT = 'CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT'
export const CREATE_NEW_ONBOARDING_FORM = 'CREATE_NEW_ONBOARDING_FORM'
export const SHOW_ONBOARDING_FORM_LINK_MODAL = 'SHOW_ONBOARDING_FORM_LINK_MODAL'
export const CREATE_SUBSCRIPTION_AMOUNT = 'CREATE_SUBSCRIPTION_AMOUNT'
export const CREATE_SUBSCRIPTION_ENROLLMENT = 'CREATE_SUBSCRIPTION_ENROLLMENT'
export const EDIT_SUBSCRIPTION_AMOUNT = 'EDIT_SUBSCRIPTION_AMOUNT'
export const DELETE_SUBSCRIPTION_AMOUNT = 'DELETE_SUBSCRIPTION_AMOUNT'
export const CREATE_FEE = 'CREATE_FEE'
export const FILE_VIEWER_MODAL = 'FILE_VIEWER_MODAL'
export const CREATE_WEBHOOK_MODAL = 'CREATE_WEBHOOK_MODAL'
export const EDIT_WEBHOOK_MODAL = 'EDIT_WEBHOOK_MODAL'
export const ADD_NEW_DOMAIN_MODAL = 'ADD_NEW_DOMAIN_MODAL'
export const REGISTER_NEW_DOMAIN_MODAL = 'REGISTER_NEW_DOMAIN_MODAL'
export const UNREGISTER_APPLE_PAY_DOMAINS_MODAL = 'UNREGISTER_APPLE_PAY_DOMAINS_MODAL'
export const ADD_NEW_IOS_APPLICATION_MODAL = 'ADD_NEW_IOS_APPLICATION_MODAL'
export const UPLOAD_PAYMENT_PROCESSING_CERT_MODAL = 'UPLOAD_PAYMENT_PROCESSING_CERT_MODAL'
export const REVOKE_APPLE_PAY_CERT_MODAL = 'REVOKE_APPLE_PAY_CERT_MODAL'
export const FILE_UPLOAD_MODAL = 'FILE_UPLOAD_MODAL'
export const RESEND_EMAIL_MODAL = 'RESEND_EMAIL_MODAL'
export const EDIT_COMPLIANCE_FORM_TEMPLATE = 'EDIT_COMPLIANCE_FORM_TEMPLATE'
export const CONTACT_SUPPORT_MODAL = 'CONTACT_SUPPORT_MODAL'
export const DASHBOARD_FEEDBACK_MODAL = 'DASHBOARD_FEEDBACK_MODAL'
export const EDIT_ACTIVE_COMPLIANCE_TEMPLATE = 'EDIT_ACTIVE_COMPLIANCE_TEMPLATE'
export const EDIT_PAYOUT_SETTINGS_WARNING = 'EDIT_PAYOUT_SETTINGS_WARNING'
export const EDIT_PAYOUT_SETTINGS_CONFIRMATION = 'EDIT_PAYOUT_SETTINGS_CONFIRMATION'
export const ATTEST_COMPLIANCE_FORM_MODAL = 'ATTEST_COMPLIANCE_FORM_MODAL'
export const EDIT_PAYMENT_INSTRUMENT = 'EDIT_PAYMENT_INSTRUMENT'
export const ENABLE_DISABLE_WEBHOOK_MODAL = 'ENABLE_DISABLE_WEBHOOK_MODAL'
export const VIEW_API_RESPONSE_MODAL = 'VIEW_API_RESPONSE_MODAL'
export const VIEW_DEVICE_INFO_MODAL = 'VIEW_DEVICE_INFO_MODAL'
export const VERIFICATION_RESULT_MODAL = 'VERIFICATION_RESULT_MODAL'
export const PROCESSOR_DETAILS_MODAL = 'PROCESSOR_DETAILS_MODAL'
export const CONTACT_SALES_MODAL = 'CONTACT_SALES_MODAL'
export const RESEND_UNACCEPTED_EVENTS_MODAL = 'RESEND_UNACCEPTED_EVENTS_MODAL'
export const EDIT_DASHBOARD_ACCOUNT_NAME_MODAL = 'EDIT_DASHBOARD_ACCOUNT_NAME_MODAL'
export const RESEND_DASHBOARD_INVITE_MODAL = 'RESEND_DASHBOARD_INVITE_MODAL'
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL'
export const DEACTIVATE_TEAM_MEMBER_MODAL = 'DEACTIVATE_TEAM_MEMBER_MODAL'
export const REINSTATE_TEAM_MEMBER_MODAL = 'REINSTATE_TEAM_MEMBER_MODAL'
export const WELCOME_NAME_CAPTURE_MODAL = 'WELCOME_NAME_CAPTURE_MODAL'
export const DISABLE_CUSTOM_ROLE_MODAL = 'DISABLE_CUSTOM_ROLE_MODAL'
export const EDIT_USER_INFO_MODAL = 'EDIT_USER_INFO_MODAL'
export const ADD_EDIT_BUYER_INFORMATION_MODAL = 'ADD_EDIT_BUYER_INFORMATION_MODAL'
export const SWITCH_DASHBOARD_TYPE_MODAL = 'SWITCH_DASHBOARD_TYPE_MODAL'
export const DASHBOARD_TYPE_MODAL = 'DASHBOARD_TYPE_MODAL'
export const FORM_FILE_UPLOADER_MODAL = 'FORM_FILE_UPLOADER_MODAL'
export const EXIT_APPLICATION_FORM_MODAL = 'EXIT_APPLICATION_FORM_MODAL'
export const CANCEL_APPLICATION_FORM_MODAL = 'CANCEL_APPLICATION_FORM_MODAL'
export const GO_TO_PREVIOUS_STEP_MODAL = 'GO_TO_PREVIOUS_STEP_MODAL'
export const WITHDRAW_LIVE_APPLICATION_FORM_MODAL = 'WITHDRAW_LIVE_APPLICATION_FORM_MODAL'
export const DISABLE_API_KEY = 'DISABLE_API_KEY'
export const ACCEPT_LIABILITY_MODAL = 'ACCEPT_LIABILITY_MODAL'
export const UPDATE_DISPUTE_STATE_MODAL = 'UPDATE_DISPUTE_STATE_MODAL'
export const GOOGLE_PLACE_DETAILS_MODAL = 'GOOGLE_PLACE_DETAILS_MODAL'
export const EDIT_UNDERWRITING_PROFILE_MODAL = 'EDIT_UNDERWRITING_PROFILE_MODAL'
export const EDIT_IDENTITY_PROCESSING_INFO_MODAL = 'EDIT_IDENTITY_PROCESSING_INFO_MODAL'
export const WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL = 'WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL'
export const CREATE_NEW_RUN_MODAL = 'CREATE_NEW_RUN_MODAL'
export const REJECT_UNDERWRITING_ENTITY_MODAL = 'REJECT_UNDERWRITING_ENTITY_MODAL'
export const REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL = 'REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL'
export const ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL = 'ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL'
export const GOOGLE_MAP_DETAILS_MODAL = 'GOOGLE_MAP_DETAILS_MODAL'
export const GENERATE_NEW_URL_LINK_MODAL = 'GENERATE_NEW_URL_LINK_MODAL'
export const SETUP_MFA_MODAL = 'SETUP_MFA_MODAL'
export const CONFIRM_PASSWORD_MFA_MODAL = 'CONFIRM_PASSWORD_MFA_MODAL'
export const SETUP_MFA_SMS_MODAL = 'SETUP_MFA_SMS_MODAL'
export const SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL = 'SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL'
export const REQUIRE_TEAM_MFA_MODAL = 'REQUIRE_TEAM_MFA_MODAL'
export const RESET_MEMBER_MFA_MODAL = 'RESET_MEMBER_MFA_MODAL'
export const CREATE_REFUND_CONFIRMATION_MODAL = 'CREATE_REFUND_CONFIRMATION_MODAL'
export const NEW_BUYER_IDENTITY_MODAL = 'NEW_BUYER_IDENTITY_MODAL'
export const CREATE_PAYMENT_INSTRUMENT_MODAL = 'CREATE_PAYMENT_INSTRUMENT_MODAL'
export const SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL = 'SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL'
export const VIRTUAL_TERMINAL_NOT_AVAILABLE_MODAL = 'VIRTUAL_TERMINAL_NOT_AVAILABLE_MODAL'
export const PAYMENT_LINKS_NOT_AVAILABLE_MODAL = 'PAYMENT_LINKS_NOT_AVAILABLE_MODAL'
export const UPDATE_ACCOUNT_BALANCE_MODAL = 'UPDATE_ACCOUNT_BALANCE_MODAL'
export const SELECT_PAYMENT_LINK_MERCHANT_MODAL = 'SELECT_PAYMENT_LINK_MERCHANT_MODAL'
export const DISABLE_PAYMENT_LINK_MODAL = 'DISABLE_PAYMENT_LINK_MODAL'
export const ENABLE_PAYMENT_LINK_MODAL = 'ENABLE_PAYMENT_LINK_MODAL'
export const VOID_AUTHORIZATION_MODAL = 'VOID_AUTHORIZATION_MODAL'
export const CAPTURE_AUTHORIZATION_MODAL = 'CAPTURE_AUTHORIZATION_MODAL'
export const SUBMIT_NOTE_MODAL = 'SUBMIT_NOTE_MODAL'
export const SELECT_ADD_DEVICE_MERCHANT_MODAL = 'SELECT_ADD_DEVICE_MERCHANT_MODAL'
export const EDIT_DEVICE_DETAILS_MODAL = 'EDIT_DEVICE_DETAILS_MODAL'
export const ACTIVATE_DEVICE_MODAL = 'ACTIVATE_DEVICE'
export const DEACTIVATE_DEVICE_MODAL = 'DEACTIVATE_DEVICE'
export const SET_IDLE_MESSAGE_MODAL = 'SET_IDLE_MESSAGE_MODAL'
export const SET_IDLE_SCREEN_MODAL = 'SET_IDLE_SCREEN_MODAL'
export const REBOOT_DEVICE_MODAL = 'REBOOT_DEVICE_MODAL'
export const EDIT_DEVICE_CONFIGURATIONS_MODAL = 'EDIT_DEVICE_CONFIGURATIONS_MODAL'
export const EDIT_UNDERWRITING_WORKFLOW_MODAL = 'EDIT_UNDERWRITING_WORKFLOW_MODAL'
export const INVALIDATE_COMPLIANCE_FORM_MODAL = 'INVALIDATE_COMPLIANCE_FORM_MODAL'
export const INSTANT_BANK_VERIFICATION_MODAL = 'INSTANT_BANK_VERIFICATION_MODAL'
export const MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL = 'MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL'
export const EDIT_VISA_PAYOUT_CONFIG_MODAL = 'EDIT_VISA_PAYOUT_CONFIG_MODAL'
export const EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL = 'EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL'
export const EDIT_PAYOUT_PROFILE_MODAL = 'EDIT_PAYOUT_PROFILE_MODAL'
export const PAYOUTS_LIMIT_REACHED_MODAL = 'PAYOUTS_LIMIT_REACHED_MODAL'
export const EXIT_THIS_FLOW_MODAL = 'EXIT_THIS_FLOW_MODAL'
export const CONTINUOUS_MONITORING_SETTINGS_MODAL = 'CONTINUOUS_MONITORING_SETTINGS_MODAL'
export const ADDITIONAL_VERIFICATION_MODAL = 'ADDITIONAL_VERIFICATION_MODAL'
export const SEND_TO_UNDERWRITING_MODAL = 'SEND_TO_UNDERWRITING_MODAL'
export const DISABLE_PAYOUT_LINK_MODAL = 'DISABLE_PAYOUT_LINK_MODAL'
export const ENABLE_PAYOUT_LINK_MODAL = 'ENABLE_PAYOUT_LINK_MODAL'
export const CONNECT_DEVICE_FOR_PAYMENT_MODAL = 'CONNECT_DEVICE_FOR_PAYMENT_MODAL'
export const UPDATE_RECIPIENT_SETTINGS_MODAL = 'UPDATE_RECIPIENT_SETTINGS_MODAL'
export const UPDATE_ONBOARDING_FORM_FILE_MODAL = 'UPDATE_ONBOARDING_FORM_FILE_MODAL'
export const EDIT_FEE_PROFILE_SETTINGS_MODAL = 'EDIT_FEE_PROFILE_SETTINGS_MODAL'
export const SEND_RECEIPT_MODAL = 'SEND_RECEIPT_MODAL'
export const CANCEL_SUBSCRIPTION_MODAL = 'CANCEL_SUBSCRIPTION_MODAL'
export const VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL = 'VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL'
export const ARCHIVE_A_PLAN_MODAL = 'ARCHIVE_A_PLAN_MODAL'
export const RESOLVE_MONITORING_ALERTS_MODAL = 'RESOLVE_MONITORING_ALERTS_MODAL'
export const FEATURE_NOT_AVAILABLE_MODAL = 'FEATURE_NOT_AVAILABLE_MODAL'
