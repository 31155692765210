import './HostedCustomerOnboardingExpiredS.scss'
import React from 'react'
import { ReactComponent as VerifyEmailErrorIcon } from 'img/svg/verify-email-error-icon.svg'

import {
  THIS_LINK_HAS_EXPIRED,
  THIS_LINK_HAS_EXPIRED_DESCRIPTION,
} from 'constants/language/languageConstants'

const HostedCustomerOnboardingExpired = () => {
  return (
    <div className='HostedCustomerOnboardingExpired'>
      <div className='flex center'>
        <div className='hosted-customer-onboarding-expired flex column center'>
          <div className='img-container'>
            <VerifyEmailErrorIcon />
          </div>
          <h5>{THIS_LINK_HAS_EXPIRED}</h5>
          <div className='p-2'>{THIS_LINK_HAS_EXPIRED_DESCRIPTION}</div>
        </div>
      </div>
    </div>
  )
}

export default HostedCustomerOnboardingExpired
