import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  SUCCEEDED,
  DEFAULT,
  FAILED,
  PENDING,
  WARNING,
} from 'constants/statusConstants'

import {
  VALUE_TO_DISPLAY_STRING_MAP,
  MANUAL_REVIEW_STRING,
  ACCEPTED,
  REJECTED,
} from 'constants/underwritingConstants'

const OnboardingStatusData = {
  [ACCEPTED]: SUCCEEDED,
  [PENDING]: DEFAULT,
  [REJECTED]: FAILED,
  [MANUAL_REVIEW_STRING]: WARNING,
}

const ReviewQueueOnboardingStatus = ({
  value = '',
}) => <ColorcodedStatus data={OnboardingStatusData} value={VALUE_TO_DISPLAY_STRING_MAP[value] ? VALUE_TO_DISPLAY_STRING_MAP[value] : value} />

ReviewQueueOnboardingStatus.propTypes = {
  value: PropTypes.string,
}

export default ReviewQueueOnboardingStatus
