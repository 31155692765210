import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFER,
  TRANSFERS,
} from 'constants/apiConstants'

const getTransferAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: TRANSFER({ transferId: id }),
    credentials,
    service: TRANSFERS,
  })
}

export default getTransferAPI
