import React from 'react'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import isEqual from 'lodash/isEqual'

import {
  COMPLETED,
  exportStatusMap,
} from 'constants/statusConstants'

import {
  DOWNLOAD,
  REQUEST_DATE,
  STATUS,
  EXPORTED_FROM,
  FILE_LINK,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(REQUEST_DATE, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={REQUEST_DATE} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(STATUS, 'status', {
    className: 'state',
    formatter: ({ status = '' }) => {
      return <ColorcodedStatus value={status} data={exportStatusMap} />
    },
  }),
  column(EXPORTED_FROM, 'entityType', {
    className: 'exported-from large',
    formatter: ({ entityType }) => startCase(lowerCase(startCase(entityType))),
  }),
  // TODO: not in phase one of this release
  // column('Expiration Date', 'displayExpirationDate', { className: 'date' }),
  column(FILE_LINK, 'fileLink', {
    className: 'small',
    formatter: (data) => {
      const { status = '', downloadUrl } = data

      // TODO: look into best way of triggering amplitude event here on download with an anchor tag
      return isEqual(status, COMPLETED) ? <a className='file-link' href={downloadUrl}>{DOWNLOAD}</a> : <span />
    },
  }),
]

export default columnDescriptors
