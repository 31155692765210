import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import { getDispatch } from 'state-layer/configureStore'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import column from 'utilities/table/column'
import { DOWNLOAD_ICON } from 'constants/iconConstants'
import { GET_FILE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'

import {
  ID,
  CREATED_ON,
  REPORT_NAME,
  TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(REPORT_NAME, 'displayName', { className: 'large' }),
  column(TYPE, 'displayType', { className: 'grow' }),
  column('', '', {
    className: 'row-actions',
    formatter: ({ fileId }) => {
      const dispatch = getDispatch()
      const credentials = getCurrentCredentials()

      const downloadFile = () => {
        dispatch({
          type: GET_FILE_F_REQUEST,
          payload: {
            id: fileId,
            credentials,
          },
          meta: {
            successCallback: ({ newValues }) => {
              const externalLink = get(newValues, `${fileId}.externalLink.url`)
              window.open(externalLink, '_blank')
            },
          },
        })
      }

      return (
        <i className={`fa fa-${DOWNLOAD_ICON}`} onClick={downloadFile} />
      )
    },
  }),
]

export default columnDescriptors
