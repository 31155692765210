import paymentsAPI from 'utilities/api/paymentsAPI'
import { CERTIFICATE_SIGNING_REQUEST } from 'constants/apiConstants'

const postCertificateSigningRequestAPI = ({ id, values, credentials }) => {
  const path = CERTIFICATE_SIGNING_REQUEST({ id })

  return paymentsAPI.post({
    values,
    credentials,
    path,
  })
}

export default postCertificateSigningRequestAPI
