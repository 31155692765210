import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_SUBMIT_TO_UNDERWRITING_F_REQUEST } from 'constants/flowConstants'
import { SUBMIT_TO_UNDERWRITING } from 'constants/amplitudeConstants'
import dispatch from 'utilities/dispatch'
import getCustomerOnboardingFormRequest from 'utilities/actions/get/getCustomerOnboardingFormRequest'

const postCustomerOnboardingFormSubmitToUnderwritingRequest = ({
  credentials,
  values,
  customerOnboardingFormId,
}) => createAction({
  type: POST_SUBMIT_TO_UNDERWRITING_F_REQUEST,
  values,
  credentials,
  id: customerOnboardingFormId,
  meta: {
    closeModal: true,
    successCallback: () => {
      dispatch(getCustomerOnboardingFormRequest({ credentials, customerOnboardingFormId }))
    },
    actionId: sendAmplitudeActionEvent(SUBMIT_TO_UNDERWRITING, {
      values,
    }),
  },
})

export default postCustomerOnboardingFormSubmitToUnderwritingRequest
