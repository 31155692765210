import './IdentityProcessingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import validateIdentityProcessingForm from 'utilities/validate/validateIdentityProcessingForm'
import warnIdentityProcessingForm from 'utilities/warn/warnIdentityProcessingForm'
import { IDENTITY_PROCESSING_FORM } from 'constants/formConstants'

import {
  AMERICAN_EXPRESS_MID,
  ANNUAL_CARD_VOLUME,
  CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_AMEX_MID_TOOLTIP,
  CREATE_IDENTITY_CARD_VOLUME_TOOLTIP,
  CREATE_IDENTITY_DISCOVER_MID_TOOLTIP,
  CREATE_IDENTITY_MCC_CODE_TOOLTIP,
  CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP,
  CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DISCOVER_MID,
  MAX_ACH_TRANSACTION_AMOUNT_LABEL,
  MAX_CARD_TRANSACTION_AMOUNT_LABEL,
  MERCHANT_CATEGORY_CODE,
  PREVIOUSLY_PROCESSED_CREDIT_CARDS_LABEL,
  TAX_AUTHORITY,
  PROCESSING_INFORMATION,
} from 'constants/language/languageConstants'

const IdentityProcessingForm = ({
  disabled = false,
  handleSubmit = () => {},
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  previouslyProcessedCreditCards,
  submitting = false,
  submitLabel = 'Submit',
  onboardingFormId = '',
  showTaxAuthority = false,
  mccList = [],
  achMaxTransactionAmount,
}) => {
  return (
    <form className='IdentityProcessingForm' onSubmit={handleSubmit} onKeyPress={(event) => { return event.key === 'Enter' && event.preventDefault() }}>
      <div className='section'>
        <h5>{PROCESSING_INFORMATION}</h5>

        <div className='flex space-between'>
          <div className='col'>
            <AmountField
              name='annualCardVolume'
              placeholder='100,000.00'
              label={ANNUAL_CARD_VOLUME}
              tooltip={CREATE_IDENTITY_CARD_VOLUME_TOOLTIP}
            />

            <ToggleSwitchC
              name='previouslyProcessedCreditCards'
              label={PREVIOUSLY_PROCESSED_CREDIT_CARDS_LABEL}
              checked={previouslyProcessedCreditCards}
              form={IDENTITY_PROCESSING_FORM}
            />

            {previouslyProcessedCreditCards && (
            <>
              <Field
                name='amexMid'
                label={AMERICAN_EXPRESS_MID}
                tooltip={CREATE_IDENTITY_AMEX_MID_TOOLTIP}
                placeholder='0000000000'
                component={InputField}
                autofill={false}
                required={false}
              />

              <Field
                name='discoverMid'
                label={DISCOVER_MID}
                tooltip={CREATE_IDENTITY_DISCOVER_MID_TOOLTIP}
                placeholder='000000000000000'
                component={InputField}
                autofill={false}
                required={false}
              />
            </>
            )}

            <Field
              name='mcc'
              label={MERCHANT_CATEGORY_CODE}
              tooltip={CREATE_IDENTITY_MCC_CODE_TOOLTIP}
              placeholder='Please select an MCC'
              component={ReactSelect}
              options={mccList}
              maxMenuHeight={200}
            />

            { showTaxAuthority && (
            <Field
              name='taxAuthority'
              label={TAX_AUTHORITY}
              placeholder='Required...'
              component={InputField}
            />
            )}

            <Field
              name='defaultStatementDescriptor'
              label={DEFAULT_STATEMENT_DESCRIPTOR}
              tooltip={CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP}
              placeholder='ACME Bakery'
              component={InputField}
            />
          </div>

          <div className='col'>
            <AmountField
              name='maxTransactionAmount'
              placeholder='10,000.00'
              label={MAX_CARD_TRANSACTION_AMOUNT_LABEL}
              tooltip={CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP}
              disabled={!!onboardingFormId}
            />

            {((!!achMaxTransactionAmount && onboardingFormId) || (!onboardingFormId)) && (
            <AmountField
              name='achMaxTransactionAmount'
              placeholder='10,000.00'
              label={MAX_ACH_TRANSACTION_AMOUNT_LABEL}
              tooltip={CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP}
              disabled={!!onboardingFormId}
            />
            )}
          </div>
        </div>
      </div>

      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
        </div>

        <div className='right flex flex-end'>
          <Button type='submit' label={submitLabel} submitting={submitting} disabled={disabled} variant='legacy-black' />
        </div>
      </div>
    </form>
  )
}

IdentityProcessingForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  previouslyProcessedCreditCards: PropTypes.bool,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  onboardingFormId: PropTypes.string,
  showTaxAuthority: PropTypes.bool,
  mccList: PropTypes.array,
  achMaxTransactionAmount: PropTypes.string,
}

export default reduxForm({
  form: IDENTITY_PROCESSING_FORM,
  validate: validateIdentityProcessingForm,
  warn: warnIdentityProcessingForm,
})(IdentityProcessingForm)
