import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_PLAN,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getPayoutPlanAPI = ({ id, credentials }) => {
  return paymentsAPI.get({
    path: PAYOUT_PLAN({ payoutPlanId: id }),
    credentials,
    service: SETTLEMENTS,
  })
}

export default getPayoutPlanAPI
