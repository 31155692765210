import React from 'react'
import PropTypes from 'prop-types'
import AddNoteC from 'components/Customer/Pages/AddNote/AddNoteC'
import ShowNotesC from 'components/Customer/Pages/ShowNotes/ShowNotesC'

const Notes = ({
  linkedTo = '',
  templates = [],
  emptyMessage = 'There are no notes for this resource',
  showAddNewNote = false,
}) => {
  return (
    <div className='Notes'>
      {showAddNewNote && (
        <AddNoteC
          linkedTo={linkedTo}
          templates={templates}
        />
      )}

      <ShowNotesC
        linkedTo={linkedTo}
        emptyMessage={emptyMessage}
      />
    </div>
  )
}

Notes.propTypes = {
  linkedTo: PropTypes.string.isRequired,
  templates: PropTypes.array,
  emptyMessage: PropTypes.string,
  showAddNewNote: PropTypes.bool,
}

export default Notes
