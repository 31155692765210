import React from 'react'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { createdAtRange, lastActivityRange } from 'utilities/table/sort'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { EDIT_MEMBER_PATH } from 'constants/pathConstants'
import { SHOW_MFA } from 'constants/featureFlagConstants'
import { goToPath } from 'state-layer/history'
import get from 'lodash/get'

import {
  CUSTOM,
  SYSTEM,
} from 'constants/roleConstants'

import {
  ID,
  NAME,
  EMAIL,
  STATE,
  DASHBOARD_ROLE,
  EDIT_TEAM_MEMBER,
  DEACTIVATE_TEAM_MEMBER,
  RESEND_DASHBOARD_INVITE_EMAIL,
  REINSTATE_TEAM_MEMBER,
  CUSTOM_ROLE,
  LAST_ACCESSED,
  ADDED_BY,
  ADDED, CREATED_ON,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ columnActions = {} }) => [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(NAME, 'email', {
    headerFormatter: () => <TableItemSubtitle title={NAME} subtitle={EMAIL} />,
    formatter: ({ fullName, email }) => <TableItemSubtitle title={fullName} subtitle={email} />,
  }),
  column(DASHBOARD_ROLE, 'roleName', {
    className: 'medium',
    formatter: ({ dashboardUserRoleType, roleName }) => <TableItemSubtitle title={roleName} subtitle={dashboardUserRoleType === CUSTOM ? CUSTOM_ROLE : ' '} />,
  }),
  column(STATE, 'enabled', {
    className: 'small',
    formatter: ({ enabled = false }) => <EnabledStatus value={enabled ? 'Active' : 'Inactive'} />,
  }),
  column(ADDED, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={ADDED} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(ADDED_BY, 'createdBy', {
    className: 'medium',
  }),
  column(LAST_ACCESSED, 'displayLastActivity', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={LAST_ACCESSED} />,
    formatter: ({ lastActivity }) => <DateTimestampColumn timestamp={lastActivity} />,
    // TODO: Add back when BE adds this sort in FWS-433
    // sort: lastActivityRange,
  }),
  column('Auth Type', 'authenticationType', {
    className: 'small',
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: (member, extraProps) => {
      const [
        showResendDashboardInviteModal,
        showDeactivateTeamMemberModal,
        showReinstateTeamMemberModal,
        showResetMemberMFAModal,
      ] = getMany(columnActions, [
        'showResendDashboardInviteModal',
        'showDeactivateTeamMemberModal',
        'showReinstateTeamMemberModal',
        'showResetMemberMFAModal',
      ])

      const {
        entityId,
        entityType,
      } = extraProps

      const [
        memberId,
        email,
        enabled,
        dashboardName,
        roleName,
        memberDashboardUserId,
      ] = getMany(member, [
        'id',
        'email',
        'enabled',
        'credentials.name',
        'roleName',
        'dashboardUserId',
      ])

      const memberName = get(member, 'fullName', email)
      const credentials = getCurrentCredentials()
      const credentialId = get(credentials, 'id')
      const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')

      const actions = [
        {
          label: RESEND_DASHBOARD_INVITE_EMAIL,
          action: () => showResendDashboardInviteModal({ email, dashboardName, entityId }),
          condition: enabled,
        },
        {
          label: EDIT_TEAM_MEMBER,
          action: () => {
            goToPath({
              pathname: EDIT_MEMBER_PATH({ credentialId, memberId }),
              queries: {
                entityId,
                entityType,
              },
            })
          },
          condition: dashboardUserRoleType === SYSTEM,
        },
        {
          label: DEACTIVATE_TEAM_MEMBER,
          action: () => showDeactivateTeamMemberModal({ memberId, memberName, dashboardName }),
          condition: enabled && dashboardUserRoleType === SYSTEM,
        },
        {
          label: REINSTATE_TEAM_MEMBER,
          action: () => showReinstateTeamMemberModal({ memberId, memberName, dashboardName, roleName }),
          condition: !enabled && dashboardUserRoleType === SYSTEM,
        },
        {
          label: 'Reset Multi-Factor Authentication',
          action: () => showResetMemberMFAModal({ name: memberName, dashboardUserId: memberDashboardUserId }),
          condition: getFeatureFlag(SHOW_MFA) && isAdministratorRole(),
        },
      ]

      return (
        <TableRowActionsC
          actions={actions}
        />
      )
    },
  }),
]

export default columnDescriptors
