import React from 'react'
import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import column from 'utilities/table/column'
import formatMoney from 'utilities/formatters/formatMoney'
import map from 'lodash/map'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

const columnDescriptors = [
  column('Nickname', 'amountNickname'),
  column('Label', 'label', {
    className: 'small',
  }),
  column('Created', 'createdAt', {
    className: 'date',
    sort: createdAtRange,
  }),
  column('Amount', ['amount', 'currency'], {
    className: 'amount',
    formatter: formatMoney,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column('Tags', 'tags', {
    formatter: ({ tags }) => TagsDisplaySection({
      data: map(tags, (tagValue, label) => ({ value: tagValue, label })),
      emptyMessage: '-',
    }),
    className: 'large',
  }),
]

export default columnDescriptors
