import { getCredentialSelector } from 'state-layer/selectors'
import { getState } from 'state-layer/configureStore'
import { PATHNAME } from 'constants/queryConstants'
import split from 'lodash/split'
import get from 'lodash/get'

const getCurrentCredentials = (state = getState(), props) => {
  const pathname = get(state, PATHNAME) ? get(state, PATHNAME) : window.location.pathname.substr(1)
  const pathCredentialId = get(split(pathname, '/'), '[1]')
  const paramsCredentialId = get(props, 'params.credentialId')
  const credentialId = paramsCredentialId || pathCredentialId

  return getCredentialSelector(state, credentialId)
}

export default getCurrentCredentials
