import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import downloadBlobFromAPI from 'utilities/actions/downloadBlobFromAPI'
import column from 'utilities/table/column'

import {
  SUCCEEDED,
  disputeEvidenceFileIconMap,
  disputeEvidenceStatusMap,
} from 'constants/statusConstants'

import {
  ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_STATUS,
  UPLOADED_ON,
  UPLOADED,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(FILE_NAME, 'fileName', {
    className: 'large',
    formatter: ({ fileName, id, dispute, state }) => {
      const fileUploaded = state === SUCCEEDED

      return (
        <span
          className={fileUploaded ? 'downloadable-file-name' : ''}
          onClick={fileUploaded ? () => {
            const apiEndpoint = `disputes/${dispute}/evidence/${id}/download`
            downloadBlobFromAPI({ api: apiEndpoint, fileName })
          } : () => {}}
        >
          {fileName}
        </span>
      )
    },
  }),
  column(FILE_STATUS, 'state', {
    formatter: ({ state }) => (
      <ColorcodedStatus
        value={state}
        customValue={state === SUCCEEDED ? UPLOADED : state}
        data={disputeEvidenceStatusMap}
        customIconMap={disputeEvidenceFileIconMap}
      />
    ),
  }),
  column(FILE_TYPE, 'displayFileExtension', { className: 'x-small' }),
  column(UPLOADED_ON, 'createdAt', { className: 'grow' }),
]

export default columnDescriptors
