import './DeviceDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  DEVICE_STATUS,
  SERIAL_NUMBER,
} from 'constants/language/languageConstants'

const DeviceDetails = ({
  className = '',
  displayDeviceModel = '',
  serialNumber = '',
  deviceImageUrl = '',
  model = '',
  deviceStatusMessage = '',
  showSerialNumber = false,
}) => {
  return (
    <>
      <div className='DeviceDetails'>
        <PageSectionV2C
          className={`device-details-highlight ${className}`}
        >
          <div className='highlight-information flex row'>
            <img src={deviceImageUrl} alt={model} />
            <span className='device-details flex column'>
              <h4 className='device-model'>{displayDeviceModel}</h4>
              {(serialNumber && showSerialNumber) && (
                <div className='flex row'>
                  <h6>{`${SERIAL_NUMBER}:`}</h6>
                  <span className='serial-number p-1'>{serialNumber}</span>
                </div>
              )}
              <div className='flex row'>
                <h6>{`${DEVICE_STATUS}:`}</h6>
                <span className='device-status p-1'>{deviceStatusMessage}</span>
              </div>
            </span>
          </div>
        </PageSectionV2C>
      </div>
    </>
  )
}

DeviceDetails.propTypes = {
  className: PropTypes.string,
  displayDeviceModel: PropTypes.string,
  serialNumber: PropTypes.string,
  deviceImageUrl: PropTypes.string,
  model: PropTypes.string,
  deviceStatusMessage: PropTypes.string,
  showSerialNumber: PropTypes.bool,
}

export default DeviceDetails
