import './ResponseFilesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ReuploadFileForm from 'components/GatewayAdmin/Forms/ReuploadFileForm/ReuploadFileForm'
import ResponseFilesFormC from './ResponseFilesFormC'

const ResponseFiles = ({
  instructions = [],
}) => {
  return (
    <div className='ResponseFiles'>
      <ResponseFilesFormC />
      <ReuploadFileForm instructions={instructions} />
    </div>
  )
}

ResponseFiles.propTypes = {
}

export default ResponseFiles
