import selectOption from 'utilities/forms/selectOption'

import {
  ACH,
  ADJUSTMENT,
  CARD,
} from 'constants/language/languageConstants'

export const ALL = 'ALL'
export const TRANSFER = 'TRANSFER'
export const REVERSAL = 'REVERSAL'
export const REVERSE = 'REVERSE'
export const SETTLEMENT = 'SETTLEMENT'
export const SPLIT_TRANSFER = 'SPLIT_TRANSFER'

export const SUCCEEDED = 'SUCCEEDED'
export const FAILED = 'FAILED'
export const PENDING = 'PENDING'
export const CANCELED = 'CANCELED'
export const UNKNOWN = 'UNKNOWN'

export const DEBIT = 'DEBIT'
export const CREDIT = 'CREDIT'
export const SALE = 'SALE'
export const AUTHORIZATION = 'AUTHORIZATION'

export const TRANSFER_TYPE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Debits', TRANSFER),
  selectOption('Refunds', REVERSAL),
  selectOption('Credits', SETTLEMENT),
]

export const TRANSFER_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Succeeded', SUCCEEDED),
  selectOption('Failed', FAILED),
  selectOption('Pending', PENDING),
  selectOption('Canceled', CANCELED),
]

export const AUTHORIZATION_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Succeeded', SUCCEEDED),
  selectOption('Failed', FAILED),
  selectOption('Pending', PENDING),
  selectOption('Canceled', CANCELED),
]

// Transfer Subtype
export const MERCHANT_DEBIT = 'MERCHANT_DEBIT'
export const MERCHANT_CREDIT = 'MERCHANT_CREDIT'
export const SETTLEMENT_MERCHANT = 'SETTLEMENT_MERCHANT'
export const SETTLEMENT_PARTNER = 'SETTLEMENT_PARTNER'
export const SETTLEMENT_PLATFORM = 'SETTLEMENT_PLATFORM'

export const TRANSFERS_SUBTYPE_LABEL_MAP = {
  [SETTLEMENT_MERCHANT]: 'Merchant Deposit',
  [SETTLEMENT_PARTNER]: 'Platform Funding Transfer',
  [SETTLEMENT_PLATFORM]: 'Platform Funding Transfer',
}

// balance entries constants
export const PUSH_TO_CARD = 'PUSH_TO_CARD'
export const PULL_FROM_CARD = 'PULL_FROM_CARD'
export const PUSH_TO_ACH = 'PUSH_TO_ACH'
export const PULL_FROM_ACH = 'PULL_FROM_ACH'
export const ADJUSTMENT_INCREASE = 'ADJUSTMENT_INCREASE'
export const ADJUSTMENT_DECREASE = 'ADJUSTMENT_DECREASE'

// send payout constants
export const NEXT_DAY_ACH = 'NEXT_DAY_ACH'
export const PUSH_TO_SAME_DAY_ACH = 'PUSH_TO_SAME_DAY_ACH'

export const BALANCE_ENTRY_METHOD_LABEL_MAP = {
  [PUSH_TO_CARD]: CARD,
  [PULL_FROM_CARD]: CARD,
  [PUSH_TO_ACH]: ACH,
  [PULL_FROM_ACH]: ACH,
  [ADJUSTMENT_INCREASE]: ADJUSTMENT,
  [ADJUSTMENT_DECREASE]: ADJUSTMENT,
  [ACH]: ADJUSTMENT,
}

export const ACH_PAYOUT_SPEED_MAP = {
  [PUSH_TO_SAME_DAY_ACH]: 'Same-day ACH',
  [NEXT_DAY_ACH]: 'Next-day ACH',
}
