import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitCancelSubscriptionForm from 'utilities/submit/submitCancelSubscriptionForm'
import { CANCEL_SUBSCRIPTION_FORM } from 'constants/formConstants'
import { endsWith } from 'lodash'

import {
  CANCEL,
  CANCEL_SUBSCRIPTION,
} from 'constants/language/languageConstants'

const CancelSubscriptionForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  invalid,
  isUpdating = false,
  customerName = '',
  amount = '',
}) => {
  const endsWithS = endsWith(customerName, 's')

  return (
    <form className='CancelSubscriptionForm' onSubmit={handleSubmit(submitCancelSubscriptionForm)}>
      <div className='modal-content'>
        <div className='confirm-cancellation-msg'>Please confirm that you would like to cancel {customerName}{endsWithS ? '\'' : '\'s'} subscription of {amount}. Please note that this change is not reversible.</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} submitting={isUpdating} variant='destructive' label={CANCEL_SUBSCRIPTION} className='submit-button' />
      </div>
    </form>
  )
}

CancelSubscriptionForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  isUpdating: PropTypes.bool,
  customerName: PropTypes.string,
  amount: PropTypes.string,
}

export default reduxForm({
  form: CANCEL_SUBSCRIPTION_FORM,
})(CancelSubscriptionForm)
