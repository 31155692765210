import './ShowOnboardingFormLinkModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import OnboardingFormLinkC from 'components/Customer/Pages/OnboardingForms/OnboardingFormLinkC'

import {
  NEW_URL_LINK_GENERATED,
  ONBOARDING_FORM_SUCCESS_MODAL_TITLE,
} from 'constants/language/languageConstants'

const ShowOnboardingFormLinkModal = ({
  onboardingFormLink = '',
  isCreateNewFormFlow,
  expirationTime = '',
}) => {
  return (
    <GenericModal
      title={isCreateNewFormFlow ? ONBOARDING_FORM_SUCCESS_MODAL_TITLE : NEW_URL_LINK_GENERATED}
      className='ShowOnboardingFormLinkModal'
      Component={OnboardingFormLinkC}
      onboardingFormLink={onboardingFormLink}
      expirationTime={expirationTime}
    />
  )
}

ShowOnboardingFormLinkModal.propTypes = {
  onboardingFormLink: PropTypes.string,
  isCreateNewFormFlow: PropTypes.bool,
  expirationTime: PropTypes.string,
}

export default ShowOnboardingFormLinkModal
