import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'

const frontendSubscriptionAmountModel = ({ data: subscriptionAmount }) => {
  const [
    id,
    amountType,
    feeAmountData,
    amountNickname,
    subscriptionScheduleId,
    tags,
    userId,
    createdAt,
    updatedAt,
  ] = getMany(subscriptionAmount, [
    'id',
    'amount_type',
    'fee_amount_data',
    'nickname',
    'subscription_schedule',
    'tags',
    'created_by',
    'created_at',
    'updated_at',
  ])

  const [
    amount,
    label,
  ] = getMany(feeAmountData, [
    'amount',
    'label',
  ])

  return removeUndefined({
    id,
    amountType,
    amount,
    label,
    feeAmountData,
    amountNickname,
    subscriptionScheduleId,
    tags,
    userId,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
  })
}

export default frontendSubscriptionAmountModel
