import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import IdentityProcessingForm from './IdentityProcessingForm'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import getApplicationEnabledMCCs from 'utilities/get/getApplicationEnabledMCCs'
import hasPermissions from 'utilities/hasPermissions'
import getMany from 'utilities/get/getMany'
import { IDENTITY_PROCESSING_FORM } from 'constants/formConstants'
import get from 'lodash/get'

import {
  getUnderwritingProfileByEntityId,
  getUnderwritingProfileByPlatform,
} from 'state-layer/selectors'

import {
  finixMCCsList,
  finixMCCsSelectOptions,
} from 'constants/mccConstants'

import {
  EDIT_IDENTITY_PERMISSION,
  VIEW_UNDERWRITING_PROFILE_PERMISSION,
} from 'constants/permissionConstants'

const mapStateToProps = (state, props) => {
  const credentials = get(props, 'credentials')
  const isRolePartnerCredential = isRolePartner({ credentials })
  const canEditIdentity = hasPermissions([EDIT_IDENTITY_PERMISSION, VIEW_UNDERWRITING_PROFILE_PERMISSION])
  const isRolePartnerWithEditIdentityPermissions = isRolePartnerCredential && canEditIdentity
  const isRolePlatformWithEditIdentityPermissions = !isRolePartnerCredential && canEditIdentity
  const formSelector = formValueSelector(IDENTITY_PROCESSING_FORM)
  const mcc = formSelector(state, 'mcc')
  const showTaxAuthority = mcc === '9311'
  const previouslyProcessedCreditCards = formSelector(state, 'previouslyProcessedCreditCards')
  // if no application underwriting profile is set, fetch underwriting profile by platform
  const underwritingProfile = getUnderwritingProfileByEntityId(state, props.applicationId) || getUnderwritingProfileByPlatform(state)

  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    underwritingProfileEnabledMCCs,
    underwritingProfileAllowAllMCCs,
  ] = getMany(underwritingProfile, [
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
    'mccDetails.enabledMCCs',
    'mccDetails.allowAllMCCs',
  ])

  const mccList = (underwritingProfileAllowAllMCCs === false && isRolePartnerCredential) ? getApplicationEnabledMCCs(underwritingProfileEnabledMCCs, finixMCCsList) : finixMCCsSelectOptions

  return {
    previouslyProcessedCreditCards,
    showTaxAuthority,
    underwritingProfile,
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    mccList,
    isRolePartnerWithEditIdentityPermissions,
    isRolePlatformWithEditIdentityPermissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
  }
}

class IdentityProcessingFormC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getUnderwritingProfile,
      isRolePartnerWithEditIdentityPermissions,
      isRolePlatformWithEditIdentityPermissions,
    } = this.props

    // we're only fetching the underwriting profile if the user is ROLE_PARTNER or ROLE_PLATFORM AND has permissions to edit the identity
    if (isRolePartnerWithEditIdentityPermissions || isRolePlatformWithEditIdentityPermissions) {
      getUnderwritingProfile({ credentials, queries: { linked_to: applicationId } })
    }
  }

  render() {
    return (
      <IdentityProcessingForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityProcessingFormC)
