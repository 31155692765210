import co from 'co'
import getComplianceSettingsAPI from 'api/dashboard/get/getComplianceSettingsAPI'
import getActiveComplianceFormTemplateAPI from 'api/dashboard/get/getActiveComplianceFormTemplateAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendComplianceFormTemplateModel from 'utilities/create/models/frontendComplianceFormTemplateModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import { APPLICATION } from 'constants/apiConstants'
import get from 'lodash/get'

function * getComplianceSettingsO ({
  id,
  credentials,
}) {
  const activeComplianceFormTemplateResponse = yield getActiveComplianceFormTemplateAPI({
    credentials,
    queries: { linked_to: id },
  })

  const activeSAQAComplianceFormTemplate = get(activeComplianceFormTemplateResponse, 'data.active_compliance_form_templates.pci_saq_a')
  const activeSAQAComplianceFormTemplateModel = frontendComplianceFormTemplateModel({ data: activeSAQAComplianceFormTemplate })
  const activeSAQAComplianceFormTemplateModelLinkedType = get(activeSAQAComplianceFormTemplateModel, 'linkedType')

  let complianceSettingsResponse

  if (activeSAQAComplianceFormTemplateModelLinkedType === 'APPLICATION') {
    complianceSettingsResponse = yield getComplianceSettingsAPI({
      credentials,
      queries: { linked_to: id },
    })
  }

  const complianceSettings = removeUndefined({
    id,
    activeComplianceTemplate: activeSAQAComplianceFormTemplateModel,
    autoGenerateComplianceForms: get(complianceSettingsResponse, 'data.allow_automatic_form_generation'),
    autoRenewComplianceForms: get(complianceSettingsResponse, 'data.allow_annual_form_generation'),
  })

  return createSuccessResponse({
    data: complianceSettings,
  })
}

export default co.wrap(getComplianceSettingsO)
