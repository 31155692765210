import columnDescriptors from './columnDescriptors'
import React, { Component } from 'react'
import SettlementEntries from './SettlementEntries'
import { connect } from 'react-redux'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { UPDATE_SETTLEMENT_PERMISSION } from 'constants/permissionConstants'
import hasPermissions from 'utilities/hasPermissions'
import { ADMIN_PLATFORM_ROLES } from 'constants/roleConstants'
import { SETTLEMENT_ENTRIES } from 'constants/selectedItemsConstants'
import { FETCHING } from 'constants/reducerConstants'
import { REMOVE_SETTLEMENT_ENTRIES } from 'constants/modalConstants'
import { APPROVED } from 'constants/settlementConstants'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'
import slice from 'lodash/slice'
import replace from 'lodash/replace'
import size from 'lodash/size'

import {
  REVERSE,
  SPLIT_TRANSFER,
} from 'constants/transferConstants'

import {
  ENTRY_TYPE,
  ENTRY_ID,
  SETTLEMENT_TRANSFER_TYPE,
} from 'constants/filterConstants'

import {
  getSelectedItemsByKeySelector,
  getEntrySelector,
  getSettlementSelector,
  getSplitTransferSelector,
} from 'state-layer/selectors'

const allowedFilters = [
  ENTRY_ID,
  ENTRY_TYPE,
  SETTLEMENT_TRANSFER_TYPE,
]

const selectedItemsKey = SETTLEMENT_ENTRIES

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const role = get(credentials, 'role')
  const selectedSettlementEntries = getSelectedItemsByKeySelector(state, SETTLEMENT_ENTRIES)
  const settlementEntryIds = map(selectedSettlementEntries, (selected, id) => id)
  const settlementId = get(props, 'settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const status = get(settlement, 'status')
  const exceptionSettlement = get(settlement, 'exception')
  const enableRemoveFromSettlement = status && (status !== APPROVED) && !exceptionSettlement
  const isRolePlatform = includes(ADMIN_PLATFORM_ROLES, role)
  const isPaymentOpsRole = isAdministratorRole() || isPaymentOperationsManagerRole()
  const hasSettlementUpdatePermission = hasPermissions([UPDATE_SETTLEMENT_PERMISSION])
  const canRemoveSettlementEntries = isRolePlatform || (isRolePartner({ credentials }) && (isPaymentOpsRole || hasSettlementUpdatePermission))
  const showBulkActions = enableRemoveFromSettlement && canRemoveSettlementEntries
  const isFetching = get(state, `entriesR.${FETCHING}`)
  const flowValues = { settlementId }
  const tableFilterType = get(state, 'tableFiltersR.type.eq')
  const tableFilterTypeNotFee = tableFilterType && tableFilterType !== 'fee'
  const relatedItemsKey = tableFilterTypeNotFee ? 'fees' : ''

  const entriesData = map(selectedSettlementEntries, (selected, id) => {
    const entry = getEntrySelector(state, id)
    return { id: get(entry, 'entryId'), amount: get(entry, 'displayAmountWithCurrencyCode') }
  })

  const entryPath = (entry) => {
    const resourceId = get(entry, 'resourceId', '')
    const entryType = get(entry, 'entryType')
    const entryId = get(entry, 'entryId')
    let formattedResourceId = resourceId
    let transferId = resourceId

    if (entryType === REVERSE) {
      formattedResourceId = replace(resourceId, 'TR', 'RV')
    }

    if (entryType === SPLIT_TRANSFER) {
      formattedResourceId = entryId
      const splitTransfer = getSplitTransferSelector(state, entryId)
      transferId = get(splitTransfer, 'parentTransferId')
    }

    const { path, idName } = getIDPrefixMapping(formattedResourceId)
    const normalizedResourceId = entryType === SPLIT_TRANSFER ? entryId : resourceId

    return path && path({ credentialId, [idName]: normalizedResourceId, settlementId, transferId })
  }

  return {
    selectedItemsKey,
    settlementEntryIds,
    selectedSettlementEntries,
    showBulkActions,
    entriesData,
    settlementId,
    flowValues,
    isFetching,
    entryPath,
    allowedFilters,
    relatedItemsKey,
    columnDescriptors: tableFilterTypeNotFee ? columnDescriptors : slice(columnDescriptors, 1),
  }
}

class SettlementEntriesC extends Component {
  removeEntryFromSettlement = () => {
    const {
      dispatch,
      settlementId,
      settlementEntryIds,
      entriesData,
    } = this.props

    dispatch(
      showModalAction({
        modalType: REMOVE_SETTLEMENT_ENTRIES,
        modalProps: {
          settlementId,
          settlementEntryIds,
          selectedItemsKey,
          entriesData,
        },
      }),
    )
  }

  render() {
    const { showBulkActions, selectedSettlementEntries } = this.props

    const actions = showBulkActions ? [
      {
        label: 'Remove from Settlement',
        action: this.removeEntryFromSettlement,
      },
    ] : []

    const selectedItemsData = {
      actions,
      info: (
        <div className='settlement-entries-info flex'>
          <div><span>Selected:</span> {size(selectedSettlementEntries)}</div>
        </div>
      ),
    }


    return (
      <SettlementEntries
        {...this.props}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps)(SettlementEntriesC)
