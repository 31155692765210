import getCurrentUser from 'utilities/get/getCurrentUser'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'

export const FLEX_PROD_PLATFORM_ID = 'PLcAUeNuHyRmyRCMZc9UvcAk'
export const FLEX_SANDBOX_PLATFORM_ID = 'PLpYsNorjuJN6MqZJ85nEsGi'
export const FLEX_QA_PLATFORM_ID = 'PL3vjukQZXosjcrS8A3iAyYj'
export const FLEX_QA_DISBURSEMENTS_PLATFORM_ID = 'PL9LZULxHPEE2pBTRm7Fx55o'
export const FLEX_EXT_SANDBOX_PLATFORM_ID = 'PLoMxXWbqwEMT5N1upSaNUbJ'
export const FLEX_INT_SANDBOX_PLATFORM_ID = 'PLob67M7fcMo5h8UccoPbN1t'
export const FLEX_SALES_PLATFORM_ID = 'PL8a1CNQ8SSV4yT6YjWX1LJx'
export const FLEX_AE_SANDBOX_PLATFORM_ID = 'PL8a1CNQ8SSV4yT6YjWX1LJx'

export const FLEX_PLATFORM_IDS = [
  FLEX_PROD_PLATFORM_ID,
  FLEX_SANDBOX_PLATFORM_ID,
  FLEX_QA_PLATFORM_ID,
  FLEX_EXT_SANDBOX_PLATFORM_ID,
  FLEX_INT_SANDBOX_PLATFORM_ID,
  FLEX_SALES_PLATFORM_ID,
  FLEX_AE_SANDBOX_PLATFORM_ID,
]

export const isFlexPlatform = () => {
  const currentUser = getCurrentUser()
  const { platformId } = currentUser

  return includes(FLEX_PLATFORM_IDS, platformId)
}

export const isFlexPlatformLive = () => {
  const currentUser = getCurrentUser()
  const { platformId } = currentUser

  return isEqual(platformId, FLEX_PROD_PLATFORM_ID)
}
