import React, { Component } from 'react'
import { connect } from 'react-redux'
import HomeInsights from './HomeInsights'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { GET_HOME_CHARTS_F_REQUEST } from 'constants/flowConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const firstName = get(currentUser, 'firstName', '')
  const isFetching = get(state, `chartsR.${FETCHING}`, true)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})

  return {
    firstName,
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
    isRoleMerchant: isRoleMerchant({ credentials }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeCharts: () => dispatch({ type: GET_HOME_CHARTS_F_REQUEST }),
  }
}

class HomeInsightsC extends Component {
  componentDidMount() {
    const {
      getHomeCharts,
    } = this.props

    getHomeCharts()
  }

  render() {
    return (
      <HomeInsights
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeInsightsC)
