import './PaymentS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TransferFailureDetailsC from 'components/Customer/Shared/Display/TransferFailureDetails/TransferFailureDetailsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import TableC from 'components/Shared/Table/TableC'
import TransferReceiptHistoryC from 'components/Customer/Pages/TransferReceiptHistory/TransferReceiptHistoryC'
import { disputesColumnDescriptors } from './columnDescriptors'
import { resourceTableThemeV2 } from 'constants/themeConstants'
import { DEBIT } from 'constants/transferConstants'
import isEmpty from 'lodash/isEmpty'

import {
  getTransferReversalsMerchantsApplicationSelector,
  getTransferDisputesMerchantsApplicationSelector,
} from 'state-layer/selectors'

import {
  PATCH_TRANSFER_F_REQUEST,
  GET_TRANSFER_REVERSALS,
  GET_TRANSFER_DISPUTES,
} from 'constants/flowConstants'

import {
  PAYMENT_DETAILS,
  ASSOCIATED_FEES,
  BUYER_DETAILS,
  SPLIT_DETAILS,
  REFUNDS,
  DISPUTES,
  PARENT_PAYMENT_SPLIT_DETAILS_DESCRIPTION,
  PAYMENT_DISPUTES_EMPTY_MESSAGE,
  PAYMENT_REFUNDS_EMPTY_MESSAGE,
  PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE,
  PARENT_PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE,
  RECIPIENT_DETAILS,
  ADDITIONAL_DATA,
  LEVEL_2_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE,
  LEVEL_3_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE,
  ITEM_INFORMATION,
  RISK_ANALYSIS,
  PAYMENT_LINK_DETAILS,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

const Payment = ({
  tagsSectionData = [],
  transferFeesColumnDescriptors = [],
  transfer = {},
  transferId = '',
  paymentInstrument = {},
  isFetching = false,
  actions = [],
  refundActions = [],
  showError = false,
  headerData = {},
  contextBarData = {},
  detailsDataSection = [],
  fees = [],
  feesPath = '',
  refundsPath = '',
  disputesPath = '',
  device = {},
  buyerIdentity = {},
  isSplitParentPayment = false,
  splitDetailsTableData = [],
  splitTransfersColumnDescriptors = [],
  noRefunds = true,
  noDisputes = true,
  refundsColumnDescriptors,
  isDisbursements = false,
  additionalPurchaseData = {},
  additionalLevelTwoDataSectionData = [],
  additionalLevelThreeDataSectionData = [],
  itemInformationCardsData = [],
  isLevelThreeEnabled = false,
  siftFraudScoreData = [],
  siftFraudScoreBadge = '',
  showRiskAnalysis = false,
  isEnterpriseSiftFraudScoreTier = false,
  paymentDetailsSectionTitle = PAYMENT_DETAILS,
  receiptsArray = [],
  type,
  paymentLinkId,
  paymentLinkDetailsData,
  paymentLinkPath,
  showResponseModal,
}) => {
  return (
    <div className='Payment'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      { showError && <TransferFailureDetailsC transfer={transfer} /> }

      {isEnterpriseSiftFraudScoreTier && showRiskAnalysis && (
        <PageSectionV2C
          title={RISK_ANALYSIS}
          asideTitle={siftFraudScoreBadge}
          columns={siftFraudScoreData}
          isFetching={isFetching}
        />
      )}

      {((!showError && !isFetching) && !isDisbursements) && (
      <PageSectionV2C
        title={paymentDetailsSectionTitle}
        columns={detailsDataSection}
        isFetching={isFetching}
        asideTitle={(
          <div className='view-api-response-link' onClick={showResponseModal}>
            {VIEW_API_RESPONSE}
          </div>
        )}
      />
      )}

      {isSplitParentPayment && (
        <TableC
          title={SPLIT_DETAILS}
          subtitle={PARENT_PAYMENT_SPLIT_DETAILS_DESCRIPTION}
          classNames='SplitDetails Table'
          data={splitDetailsTableData}
          columnDescriptors={splitTransfersColumnDescriptors}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          doNotSortData
          isFetching={isFetching}
        />
      )}

      <BuyerDetailsC
        title={!isDisbursements ? BUYER_DETAILS : RECIPIENT_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      { paymentLinkId && (
      <PageSectionV2C
        title={PAYMENT_LINK_DETAILS}
        columns={paymentLinkDetailsData}
        isFetching={isFetching}
        clickToCopies={[{ content: paymentLinkId }]}
        asideTitle={paymentLinkPath}
        className='payment-link-details-section'
      />
      )}

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      {!isEmpty(additionalPurchaseData) && (
        <PageSectionV2C
          title={ADDITIONAL_DATA}
          className='additional-level-2-and-3-data-section'
          subTitle={isLevelThreeEnabled ? LEVEL_3_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE : LEVEL_2_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE}
          columns={isLevelThreeEnabled ? additionalLevelThreeDataSectionData : additionalLevelTwoDataSectionData}
          isFetching={isFetching}
        />
      )}

      {isLevelThreeEnabled && (
        <PageSectionV2C
          title={<h5>{ITEM_INFORMATION}</h5>}
          className='level-three-item-info-section'
          columns={itemInformationCardsData}
          isFetching={isFetching}
        />
      )}

      {(isEmpty(fees) && !isFetching && !isDisbursements) && (
        <PageSectionV2C
          title={ASSOCIATED_FEES}
          emptyMessage={isSplitParentPayment ? PARENT_PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE : PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE}
        />
      )}

      {!showError && !isSplitParentPayment && !isEmpty(fees) && (
        <TableC
          title={ASSOCIATED_FEES}
          columnDescriptors={transferFeesColumnDescriptors}
          data={fees}
          isFetching={isFetching}
          path={feesPath}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          classNames='Associated-Fees-Table'
        />
      )}

      {(!showError && !isFetching && !isDisbursements) && (
        <TableC
          title={REFUNDS}
          classNames='refunds-table'
          actions={refundActions}
          flow={GET_TRANSFER_REVERSALS}
          flowValues={{ transferId }}
          columnDescriptors={refundsColumnDescriptors}
          path={isSplitParentPayment ? '' : refundsPath}
          theme={resourceTableThemeV2}
          selector={getTransferReversalsMerchantsApplicationSelector}
          showTableFooter={false}
          emptyMessageString={noRefunds && PAYMENT_REFUNDS_EMPTY_MESSAGE}
        />
      )}

      {(!showError && !isFetching && !isDisbursements) && (
        <TableC
          title={DISPUTES}
          flow={GET_TRANSFER_DISPUTES}
          flowValues={{ transferId }}
          columnDescriptors={disputesColumnDescriptors}
          selector={getTransferDisputesMerchantsApplicationSelector}
          path={disputesPath}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          emptyMessageString={noDisputes && PAYMENT_DISPUTES_EMPTY_MESSAGE}
        />
      )}

      {(type === DEBIT && !showError && !isFetching) && (
        <TransferReceiptHistoryC
          receipts={receiptsArray}
          transfer={transfer}
          isFetching={isFetching}
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_TRANSFER_F_REQUEST}
        data={tagsSectionData}
        record={transfer}
        isFetching={isFetching}
      />
    </div>
  )
}

Payment.propTypes = {
  tagsSectionData: PropTypes.array,
  transfer: PropTypes.object,
  transferId: PropTypes.string,
  paymentInstrument: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  paymentPath: PropTypes.string,
  showError: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsDataSection: PropTypes.array,
  device: PropTypes.object,
  buyerIdentity: PropTypes.object,
  isSplitParentPayment: PropTypes.bool,
  refundActions: PropTypes.array,
  splitDetailsTableData: PropTypes.array,
  refundsPath: PropTypes.string,
  disputesPath: PropTypes.string,
  splitTransfersColumnDescriptors: PropTypes.array,
  noRefunds: PropTypes.bool,
  additionalPurchaseData: PropTypes.object,
  refundsColumnDescriptors: PropTypes.array,
  additionalLevelTwoDataSectionData: PropTypes.array,
  additionalLevelThreeDataSectionData: PropTypes.array,
  itemInformationCardsData: PropTypes.array,
  isLevelThreeEnabled: PropTypes.bool,
  feesPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  fees: PropTypes.array,
  isDisbursements: PropTypes.bool,
  siftFraudScoreData: PropTypes.array,
  siftFraudScoreBadge: PropTypes.string,
  showRiskAnalysis: PropTypes.bool,
  isEnterpriseSiftFraudScoreTier: PropTypes.bool,
  paymentDetailsSectionTitle: PropTypes.string,
  receiptsArray: PropTypes.array,
  type: PropTypes.string,
  paymentLinkId: PropTypes.string,
  paymentLinkDetailsData: PropTypes.array,
  paymentLinkPath: PropTypes.object,
  showAPIResponseModal: PropTypes.func,
}

export default Payment
