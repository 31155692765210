import numeral from 'numeral'
import { MONEY_FORMAT } from 'constants/formatAmountConstants'
import toString from 'lodash/toString'

const formatAmount = (number) => {
  if (number === undefined || number === null) {
    return number
  }

  // Construct the format string dynamically
  const numberString = toString(number)
  const decimalPlaces = numberString.includes('.') ? numberString.split('.')[1].length : 0
  const decimalPlacesCount = decimalPlaces > 5 ? 5 : decimalPlaces
  const format = `0,0.${'0'.repeat(decimalPlacesCount)}`

  return decimalPlacesCount > 2 ? numeral(number).format(format) : numeral(number).format(MONEY_FORMAT)
}

export default formatAmount
