import './CreateBalanceAdjustmentConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import isEmpty from 'lodash/isEmpty'

import {
  CHECK_CIRCLE_ICON,
  TIMES_OCTAGON,
} from 'constants/iconConstants'

import {
  ADD_FUNDS_PATH,
  BALANCE_ADJUSTMENTS_PATH,
} from 'constants/pathConstants'

import {
  ADD_MORE_FUNDS,
  BALANCE_ADJUSTMENT_CONFIRMATION,
  CREATE_BALANCE_ADJUSTMENT_SUCCESS_MSG,
  TRANSACTION_DETAILS,
  VIEW_ALL_BALANCE_ADJUSTMENTS,
} from 'constants/language/languageConstants'

const CreateBalanceAdjustmentConfirmation = ({
  credentialId = '',
  failureMessage = '',
  displayFailureCode = '',
  transactionDetailsSectionData = [],
}) => {
  return (
    <div className='CreateBalanceAdjustmentConfirmation'>
      <div className='header-container'>
        <h2 className='title'>{BALANCE_ADJUSTMENT_CONFIRMATION}</h2>
      </div>

      { !isEmpty(failureMessage) ? (
        <div className='failure-message-container row'>
          <h6 className='title'><i className={`fa fa-${TIMES_OCTAGON} failure-icon`} /> {displayFailureCode}</h6>
          <div className='failure-message p-1'>{failureMessage}</div>
        </div>
      ) : (
        <div className='success-message-container'>
          <span className='success-message p-1'><i className={`fa fa-${CHECK_CIRCLE_ICON} success-icon`} />{CREATE_BALANCE_ADJUSTMENT_SUCCESS_MSG}</span>
        </div>
      )}

      <div className='payment-details-container'>
        <PageSectionV2
          smallTitle={TRANSACTION_DETAILS}
          className='transaction-details'
          columns={transactionDetailsSectionData}
        />
      </div>

      <div className='buttons-container flex flex-start'>
        <Button className='create-new-payment-button' onClick={() => goToPath({ pathname: ADD_FUNDS_PATH({ credentialId }) })} label={ADD_MORE_FUNDS} />
        <Button className='view-all-payments-button' onClick={() => goToPath({ pathname: BALANCE_ADJUSTMENTS_PATH({ credentialId }) })} label={VIEW_ALL_BALANCE_ADJUSTMENTS} variant='ghost' />
      </div>
    </div>
  )
}

CreateBalanceAdjustmentConfirmation.propTypes = {
  credentialId: PropTypes.string,
  failureMessage: PropTypes.string,
  displayFailureCode: PropTypes.string,
  transactionDetailsSectionData: PropTypes.array,
}

export default CreateBalanceAdjustmentConfirmation
