import './IdentityFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import validateIdentityForm from 'utilities/validate/validateIdentityForm'
import Button from 'components/Shared/Button/Button'
import { IDENTITY_FORM } from 'constants/formConstants'

import {
  OWNERSHIP_TYPE_OPTIONS,
  BUSINESS_TYPE_OPTIONS,
} from 'constants/identityConstants'

import {
  EMAIL_ADDRESS,
  BUSINESS_ADDRESS,
  MERCHANT_CATEGORY_CODE,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

const IdentityForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  showTaxAuthority = false,
  skipIdentity = false,
  showSkipIdentity = false,
  previouslyProcessedCreditCards = false,
  toggleSkipIdentity = () => {},
  hideOwnershipTypeDropdown = false,
  showMiddleName = false,
  businessTypeOptions = BUSINESS_TYPE_OPTIONS,
}) => {
  return (
    <form className='IdentityForm' onSubmit={handleSubmit}>
      <div className='section'>
        <div className='flex space-between'>
          <h2>Business Information</h2>

          { showSkipIdentity && (
            <Field
              name='skipIdentity'
              label='Skip Identity'
              component={CheckboxC}
              checked={skipIdentity}
              handleChange={toggleSkipIdentity}
            />
          )}
        </div>

        <Field
          id='business-name-input'
          name='businessName'
          label='Business Name'
          component={InputField}
          autofill={false}
        />

        { !skipIdentity && (
        <>
          <Field
            id='doing-business-as-input'
            name='doingBusinessAs'
            label='Doing Business As'
            component={InputField}
            autofill={false}
          />

          <Field
            id='website-input'
            name='website'
            label='Website'
            component={InputField}
          />

          <Field
            id='business-phone-input'
            name='businessPhone'
            label='Business Phone'
            component={InputField}
            autofill={false}
          />

          <AddressFieldset
            name='businessAddress'
            label={BUSINESS_ADDRESS}
          />

          <Field
            name='businessType'
            label='Business Type'
            component={SelectField}
            options={businessTypeOptions}
            autofill={false}
          />

          {!hideOwnershipTypeDropdown && (
          <Field
            name='ownershipType'
            label='Ownership Type'
            component={SelectField}
            options={OWNERSHIP_TYPE_OPTIONS}
          />
          )}

          <DateFieldset
            name='incorporationDate'
            label='Date of Incorporation'
          />

          <Field
            name='businessTaxId'
            label='Tax ID (EIN) / Social Security Number (SSN)'
            placeholder='00-0000000'
            component={InputField}
            autofill={false}
          />

          <Field
            name='mcc'
            label={MERCHANT_CATEGORY_CODE}
            placeholder='4225'
            component={InputField}
            autofill={false}
          />

          <Field
            name='amexMid'
            label='American Express MID'
            placeholder='0000000000'
            component={InputField}
            autofill={false}
          />

          <Field
            name='discoverMid'
            label='Discover MID'
            placeholder='000000000000000'
            component={InputField}
            autofill={false}
          />

          { showTaxAuthority && (
            <Field
              name='taxAuthority'
              label='Tax Authority'
              placeholder='Required...'
              component={InputField}
            />
          )}
        </>
        )}
      </div>

      { !skipIdentity && (
      <div className='section'>
        <h2>Principal Information</h2>

        <Field
          name='firstName'
          label='First Name'
          placeholder='John'
          component={InputField}
        />

        { showMiddleName && (
          <Field
            name='middleName'
            label='Middle Name'
            placeholder='Jacob'
            component={InputField}
          />
        )}

        <Field
          name='lastName'
          label='Last Name'
          placeholder='Smith'
          component={InputField}
        />

        <Field
          name='title'
          label='Title'
          placeholder='e.g CEO, Manager'
          component={InputField}
        />

        <DateFieldset
          name='dateOfBirth'
          label='Date of Birth'
        />

        <AddressFieldset
          name='personalAddress'
          label='Address'
        />

        <Field
          name='taxId'
          label={SOCIAL_SECURITY_NUMBER}
          placeholder='000-00-0000'
          component={InputField}
          autofill={false}
        />

        <PercentageField
          name='principalPercentageOwnership'
          label='Principal Percentage Ownership'
        />

        <Field
          name='phone'
          label='Personal Phone'
          component={InputField}
        />

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          component={InputField}
        />
      </div>
      )}

      { !skipIdentity && (
      <div className='section'>
        <h2>Processing Information</h2>

        <ToggleSwitchC
          name='previouslyProcessedCreditCards'
          label='Have you processed credit cards before?'
          checked={previouslyProcessedCreditCards}
          form={IDENTITY_FORM}
        />

        <AmountField
          name='annualCardVolume'
          placeholder='Ex: 100,000.00'
          label='Annual Card Volume'
        />

        <Field
          name='defaultStatementDescriptor'
          label='Default Statement Descriptor'
          placeholder='MERCHANTNAME'
          component={InputField}
        />

        <AmountField
          name='maxTransactionAmount'
          placeholder='Ex: 10,000.00'
          label='Max Transaction Amount'
        />
      </div>
      )}

      <div className='buttons flex flex-end'>
        <Button type='submit' label={submitLabel} />
      </div>
    </form>
  )
}

IdentityForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  showTaxAuthority: PropTypes.bool,
  skipIdentity: PropTypes.bool,
  showSkipIdentity: PropTypes.bool,
  previouslyProcessedCreditCards: PropTypes.bool,
  toggleSkipIdentity: PropTypes.func,
  hideOwnershipTypeDropdown: PropTypes.bool,
  showMiddleName: PropTypes.bool,
  businessTypeOptions: PropTypes.array,
}

export default reduxForm({
  form: IDENTITY_FORM,
  validate: validateIdentityForm,
})(IdentityForm)
