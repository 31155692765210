import './ExceptionsInsightsTabsS.scss'
import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import DisputesInsightsC from 'components/Customer/Pages/Insights/ExceptionsInsights/DisputesInsights/DisputesInsightsC'
import ACHReturnsInsightsC from 'components/Customer/Pages/Insights/ExceptionsInsights/ACHReturnsInsights/ACHReturnsInsightsC'
import { BUSINESS_ANALYTICS_LINK } from 'constants/urlConstants'

import {
  ACH_RETURNS,
  DISPUTES_RESOURCE_TITLE,
  EXCEPTIONS_INSIGHTS,
  EXCEPTIONS_INSIGHTS_SUBTITLE,
} from 'constants/language/languageConstants'

class ExceptionsInsightsTabs extends Component {
  render() {
    const tabs = [
      {
        id: 'disputes-insights',
        name: DISPUTES_RESOURCE_TITLE,
        component: DisputesInsightsC,
      },
      {
        id: 'ach-returns-insights',
        name: ACH_RETURNS,
        component: ACHReturnsInsightsC,
      },
    ]

    return (
      <div className='ExceptionsInsightsTabs'>
        <HeaderV2C
          title={EXCEPTIONS_INSIGHTS}
          subTitle={EXCEPTIONS_INSIGHTS_SUBTITLE}
          learnMoreLink={BUSINESS_ANALYTICS_LINK}
        />

        <TabsC tabs={tabs} />
      </div>
    )
  }
}

export default ExceptionsInsightsTabs
