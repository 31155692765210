import frontendStatementModel from 'utilities/create/models/frontendStatementModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendStatementsModel = ({ data: statements }) => {
  const statementsModels = map(statements, (statement) => frontendStatementModel({ data: statement }))

  return keyBy(statementsModels, 'id')
}

export default frontendStatementsModel
