import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import nextFlowStep from 'utilities/nextFlowStep'
import removePristineValues from 'utilities/remove/removePristineValues'
import { PATCH_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import { COMPLETE_MERCHANT_BOARDING_STEP } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import set from 'lodash/set'
import trim from 'lodash/trim'

const submitOnboardingStepForm = (values, dispatch, props) => {
  const action = PATCH_ONBOARDING_FORM_DATA_F_REQUEST
  const onboardingFormId = get(props, 'onboardingFormId')
  const onboardingFormCountry = get(props, 'onboardingFormCountry')
  const keysToIgnore = get(props, 'keysToIgnore')
  const initialValues = get(props, 'initialValues')
  const newValues = removePristineValues({ values, initialValues, keysToIgnore })

  // if the business description has been updated, sanitize the input to remove line breaks)
  const newBusinessDescriptionValue = get(newValues, 'businessData.businessDescription')

  if (newBusinessDescriptionValue) {
    set(newValues, 'businessData.businessDescription', trim(newBusinessDescriptionValue.replace(/(\r\n|\n|\r)/gm, ' ')))
  }
  const valuesToSubmit = { id: onboardingFormId, country: onboardingFormCountry, ...newValues }

  dispatch({
    type: action,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: nextFlowStep,
      actionId: sendAmplitudeActionEvent(COMPLETE_MERCHANT_BOARDING_STEP, {
        id: onboardingFormId,
      }),
      overwriteReducer: true,
    },
  })
}

export default submitOnboardingStepForm
