import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComplianceInsights from './ComplianceInsights'
import getMerchantChartsRequest from 'utilities/actions/get/getMerchantChartsRequest'
import { FETCHING } from 'constants/reducerConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { COMPLIANCE_DATES_QUICK_FILTER } from 'constants/quickFilterConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'


const mapStateToProps = (state) => {
  const isFetching = get(state, `chartsR.${FETCHING}`, false)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})

  return {
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
  }
}

const quickFilters = [
  COMPLIANCE_DATES_QUICK_FILTER,
]

const mapDispatchToProps = (dispatch) => {
  return {
    getComplianceCharts: ({ queries }) => dispatch(getMerchantChartsRequest({ queries })),
  }
}

class ComplianceInsightsC extends Component {
  componentDidMount() {
    const {
      getComplianceCharts,
    } = this.props

    getComplianceCharts({
      queries: {
        tab: 'compliance',
      },
    })
  }

  render() {
    return (
      <ComplianceInsights
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceInsightsC)
