import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateDisputeStateForm from './UpdateDisputeStateForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const disputeId = get(props, 'disputeId')

  return { credentials }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class UpdateDisputeStateFormC extends Component {
  render() {
    return (
      <UpdateDisputeStateForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDisputeStateFormC)
