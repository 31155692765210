import numeral from 'numeral'
import { isFlexPlatform } from 'constants/flexConstants'

import {
  validateIPAddress,
  validateTotalPercentage,
  validateTruthyValue,
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateAdditionalUnderwritingDataForm = (values) => {
  const {
    businessDescription,
    averageCardTransferAmount,
    averageACHTransferAmount,
    annualACHVolume,
    cardPresentPercentage,
    ecommercePercentage,
    mailOrderTelephoneOrderPercentage,
    merchantAgreementAccepted,
    creditCheckIPAddress,
    merchantAgreementIPAddress,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
  } = values

  const normalizedCardPresentPercentage = numeral(cardPresentPercentage).value()
  const normalizedEcommercePercentage = numeral(ecommercePercentage).value()
  const normalizedMailOrderTelephoneOrderPercentage = numeral(mailOrderTelephoneOrderPercentage).value()
  const normalizedB2BVolumePercentage = numeral(businessToBusinessVolumePercentage).value()
  const normalizedB2CVolumePercentage = numeral(businessToConsumerVolumePercentage).value()
  const normalizedOtherVolumePercentage = numeral(otherVolumePercentage).value()

  const agreementAcceptedLabel = isFlexPlatform() ? 'Finix Terms of Service' : 'Merchant Agreement'

  return {
    businessDescription: fieldEmpty(businessDescription),
    averageCardTransferAmount: nonEmptyNumber({ field: averageCardTransferAmount, name: 'Average Card Transaction Amount' }),
    averageACHTransferAmount: nonEmptyNumber({ field: averageACHTransferAmount, name: 'Average eCheck Transaction Amount' }),
    annualACHVolume: nonEmptyNumber({ field: annualACHVolume, name: 'Annual ACH Volume' }),
    creditCheckIPAddress: validateIPAddress({ field: creditCheckIPAddress, name: 'Credit Check IP Address' }),
    merchantAgreementAccepted: validateTruthyValue({ field: merchantAgreementAccepted, name: agreementAcceptedLabel, customErrorMessage: `${agreementAcceptedLabel} must be accepted.` }),
    merchantAgreementIPAddress: validateIPAddress({ field: merchantAgreementIPAddress, name: 'Merchant Agreement IP Address' }),
    cardPresentPercentage: validateTotalPercentage({ name: 'Total Card Volume Distribution', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
    ecommercePercentage: validateTotalPercentage({ name: 'Total Card Volume Distribution', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
    mailOrderTelephoneOrderPercentage: validateTotalPercentage({ name: 'Total Card Volume Distribution', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
    businessToBusinessVolumePercentage: validateTotalPercentage({ name: 'Total Business Type Volume Distribution', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
    businessToConsumerVolumePercentage: validateTotalPercentage({ name: 'Total Business Type Volume Distribution', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
    otherVolumePercentage: validateTotalPercentage({ name: 'Total Business Type Volume Distribution', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
  }
}

export default validateAdditionalUnderwritingDataForm
