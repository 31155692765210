import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import merge from 'lodash/merge'
import size from 'lodash/size'

import {
  WEBHOOK_PATH,
  APPLICATION_WEBHOOK_PATH,
} from 'constants/pathConstants'

import {
  constructWebhookEventValues,
  BASIC_VALUE,
  BEARER_TOKEN_VALUE,
  NONE_VALUE,
} from 'constants/webhookConstants'

import {
  PATCH_WEBHOOK_F_REQUEST,
  POST_WEBHOOKS_F_REQUEST,
  GET_WEBHOOK_F_REQUEST,
} from 'constants/flowConstants'

import {
  UPDATE_WEBHOOK,
  CREATE_WEBHOOK,
} from 'constants/amplitudeConstants'

const submitWebhookForm = (values, dispatch, props) => {
  const { credentials, webhook = {}, applicationId } = props

  const {
    endpointUrl,
    nickname,
    authenticationType,
    selectedEvents,
    basicAuthenticationUsername,
    basicAuthenticationPassword,
    bearerToken,
  } = values

  const editMode = !isEmpty(webhook)
  const enabledEvents = constructWebhookEventValues({ selectedEvents })

  let authenticationObj = {
    type: authenticationType,
  }

  if (authenticationType === BASIC_VALUE && basicAuthenticationUsername && basicAuthenticationPassword) {
    authenticationObj = merge({}, authenticationObj, {
      basic: {
        username: basicAuthenticationUsername,
        password: basicAuthenticationPassword,
      },
    })
  }

  if (authenticationType === BEARER_TOKEN_VALUE && bearerToken) {
    authenticationObj = merge({}, authenticationObj, {
      bearer: {
        token: bearerToken,
      },
    })
  }

  // don't send in authentication field if basic/bearer token is not edited
  const webhookValues = (size(keys(authenticationObj)) === 1 && (authenticationType !== NONE_VALUE)) ?
    {
      nickname,
      url: trim(endpointUrl),
      enabled_events: enabledEvents,
    } :
    {
      nickname,
      url: trim(endpointUrl),
      enabled_events: enabledEvents,
      authentication: authenticationObj,
    }

  const normalizedWebhookValues = (applicationId && !isRolePartner({ credentials })) ? merge({}, webhookValues, { application_id: applicationId }) : webhookValues

  if (editMode) {
    const webhookId = get(webhook, 'id')

    dispatch({
      type: PATCH_WEBHOOK_F_REQUEST,
      payload: {
        id: webhookId,
        values: normalizedWebhookValues,
        credentials,
      },
      meta: {
        successCallback: () => {
          const credentialId = get(credentials, 'id')

          dispatch({
            type: GET_WEBHOOK_F_REQUEST,
            payload: {
              id: webhookId,
              credentials,
            },
            meta: {
              overwriteReducer: true,
            },
          })

          const webhookPath = applicationId ? APPLICATION_WEBHOOK_PATH({ credentialId, applicationId, webhookId }) : WEBHOOK_PATH({ credentialId, webhookId })

          dispatch(redirectRequest({ path: webhookPath }))
        },
        // fetch again on failure or else webhook reducer is blown out and initial values don't persist
        errorCallback: () => {
          dispatch({
            type: GET_WEBHOOK_F_REQUEST,
            payload: {
              id: webhookId,
              credentials,
            },
            meta: {
              overwriteReducer: true,
            },
          })
        },
        actionId: sendAmplitudeActionEvent(UPDATE_WEBHOOK, {
          credentials,
          webhookId,
          applicationId,
          ...webhookValues,
        }),
      },
    })
  } else {
    // creation of webhook
    dispatch({
      type: POST_WEBHOOKS_F_REQUEST,
      payload: {
        values: normalizedWebhookValues,
        credentials,
      },
      meta: {
        successCallback: ({ newValues }) => {
          const credentialId = get(credentials, 'id')
          const webhookId = head(keys(newValues))
          const webhookPath = applicationId ? APPLICATION_WEBHOOK_PATH({ credentialId, applicationId, webhookId }) : WEBHOOK_PATH({ credentialId, webhookId })

          dispatch(redirectRequest({ path: webhookPath }))
        },
        actionId: sendAmplitudeActionEvent(CREATE_WEBHOOK, {
          credentials,
          applicationId,
          ...webhookValues,
        }),
      },
    })
  }
}

export default submitWebhookForm
