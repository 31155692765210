import React, { Component } from 'react'
import { connect } from 'react-redux'
import AssignUnderwritingEntityToMe from './AssignUnderwritingEntityToMe'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import patchUnderwritingReviewRequest from 'utilities/actions/patch/patchUnderwritingReviewRequest'
import getDashboardUser from 'utilities/get/getDashboardUser'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)

  return {
    credentials,
    currentUserEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    assignUnderwritingEntityToSelf: (actionProps) => dispatch(patchUnderwritingReviewRequest(actionProps)),
  }
}

class AssignUnderwritingEntityToMeC extends Component {
  assignToSelf = () => {
    const {
      reviewId,
      subjectId,
      currentUserEmail,
      credentials,
      assignUnderwritingEntityToSelf,
      closeModal,
    } = this.props

    assignUnderwritingEntityToSelf({
      id: reviewId,
      subjectId,
      assignee: currentUserEmail,
      credentials,
    })

    closeModal()
  }

  render() {
    return (
      <AssignUnderwritingEntityToMe
        {...this.props}
        assignToSelf={this.assignToSelf}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignUnderwritingEntityToMeC)
