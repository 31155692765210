import './ReinstateTeamMemberModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ReinstateTeamMemberC from 'components/Customer/Pages/ReinstateTeamMember/ReinstateTeamMemberC'
import { REINSTATE_TEAM_MEMBER } from 'constants/language/languageConstants'

const ReinstateTeamMemberModal = ({
  memberId,
  memberName,
  dashboardName,
  roleName,
}) => {
  return (
    <GenericModal
      title={REINSTATE_TEAM_MEMBER}
      className='ReinstateTeamMemberModal'
      Component={ReinstateTeamMemberC}
      memberId={memberId}
      memberName={memberName}
      dashboardName={dashboardName}
      roleName={roleName}
    />
  )
}

ReinstateTeamMemberModal.propTypes = {
  memberId: PropTypes.string,
  memberName: PropTypes.string,
  dashboardName: PropTypes.string,
  roleName: PropTypes.string,
}

export default ReinstateTeamMemberModal
