import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_UPLOAD_PRESIGNED_URL } from 'constants/apiConstants'

const postDashboardPresignedUrlAPI = ({ values, credentials }) => {
  return dashboardAPI.post({
    path: DASHBOARD_UPLOAD_PRESIGNED_URL,
    values,
    credentials,
  })
}

export default postDashboardPresignedUrlAPI
