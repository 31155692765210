import includes from 'lodash/includes'

import {
  SHOULD_FUND,
  PENDING_STRING,
  SHOULD_NOT_FUND,
  SETTLED_EXTERNALLY,
  UNKNOWN,
  FALSE_STRING,
} from 'constants/language/languageConstants'

const displayFundingDetailsValue = ({ shouldFund, externallyFunded }) => {
  const notExternallyFunded = includes([UNKNOWN, FALSE_STRING], externallyFunded)

  if (shouldFund) {
    return SHOULD_FUND
  }

  if (shouldFund === undefined) {
    return PENDING_STRING
  }

  if (!shouldFund && notExternallyFunded) {
    return SHOULD_NOT_FUND
  }

  return SETTLED_EXTERNALLY
}

export default displayFundingDetailsValue
