import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import APIKeys from './APIKeys'
import columnDescriptors from './columnDescriptors'
import get from 'lodash/get'
import showModalAction from 'utilities/actions/showModalAction'
import { GET_PLATFORM_USERS } from 'constants/flowConstants'
import { CREATE_API_KEY_LABEL } from 'constants/language/languageConstants'

import {
  CREATE_API_KEY,
  DISABLE_API_KEY,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const platformId = get(props, 'platformId')
  const isFetching = get(state, `applicationUsersR.${FETCHING}`)
  const flow = GET_PLATFORM_USERS

  const flowValues = {
    platformId,
  }

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
  }

  return {
    flow,
    flowValues,
    isFetching,
    platformId,
    initialQueries,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateAPIKey: (modalProps) => dispatch(showModalAction({ modalType: CREATE_API_KEY, modalProps })),
    showDisableAPIKeyModal: (modalProps) => dispatch(showModalAction({ modalType: DISABLE_API_KEY, modalProps })),
  }
}

class PlatformAPIKeysC extends Component {
  render() {
    const {
      showCreateAPIKey,
      platformId,
      showDisableAPIKeyModal,
    } = this.props

    const actions = [
      {
        label: CREATE_API_KEY_LABEL,
        action: () => {
          showCreateAPIKey({
            platformId,
          })
        },
        className: 'create-api-key-button',
      },
    ]

    const columnActions = {
      showDisableAPIKeyModal,
    }

    const normalizedColumnDescriptors = columnDescriptors({ columnActions })

    return (
      <APIKeys
        actions={actions}
        columnDescriptors={normalizedColumnDescriptors}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAPIKeysC)
