import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATCH_WEBHOOK_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'

const patchWebhookRequest = ({
  webhookId,
  credentials,
  values,
  amplitudeEventName,
  customSuccessMessage,
  customErrorMessage,
}) => createAction({
  type: PATCH_WEBHOOK_F_REQUEST,
  id: webhookId,
  credentials,
  values,
  meta: {
    actionId: sendAmplitudeActionEvent(amplitudeEventName, removeUndefined({
      credentials,
      values,
      webhookId,
    })),
    successMessage: customSuccessMessage ? customSuccessMessage : `Webhook has been ${get(values, 'enabled') ? 'enabled.' : 'disabled.'}`,
    errorMessage: customErrorMessage ? customErrorMessage : `There was an error ${get(values, 'enabled') ? 'enabling' : 'disabling'} this webhook.`,
  },
})

export default patchWebhookRequest
