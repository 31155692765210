import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'

const frontendReportModel = ({ data: report }) => {
  const [
    id,
    createdAt,
    fileName,
    periodType,
    reportTitle,
    reportType,
    category,
    subcategory,
    version,
    associatedEntity,
    associatedEntityType,
    startDateTime,
    endDateTime,
  ] = getMany(report, [
    'id',
    'created_at',
    'file_name',
    'period_type',
    'report_title',
    'report_type',
    'category',
    'subcategory',
    'version',
    'associated_entity',
    'associated_entity_type',
    'start_date_time',
    'end_date_time',
  ])

  return removeUndefined({
    id,
    createdAt,
    fileName,
    periodType,
    reportTitle,
    reportType,
    category,
    subcategory,
    version,
    associatedEntity,
    associatedEntityType,
    startDateTime,
    endDateTime,
  })
}

export default frontendReportModel
