import './CustomerOnboardingFormReviewFormS.scss'
import React from 'react'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import submitCustomerOnboardingForm from 'utilities/submit/submitCustomerOnboardingForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { CUSTOMER_ONBOARDING_FORM_REVIEW_FORM } from 'constants/formConstants'
import { APPLICATIONS_PATH } from 'constants/pathConstants'

import {
  CANCEL,
  DEAL_INFORMATION,
  REVIEW,
  CUSTOMER_ONBOARDING_FORM_REVIEW_SUBTITLE,
  PREVIOUS_STEP,
  PROCESSING_INFORMATION,
  EDIT,
  CARD_VOLUME,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  BUSINESS_INFORMATION,
  CREATE_FORM,
} from 'constants/language/languageConstants'

const CustomerOnboardingFormReviewForm = ({
  goToStep = () => {},
  handleSubmit = () => {},
  dealInformationDataSection = [],
  businessInfoDataSection = [],
  processingInfoDataSection = [],
  cardVolumeDataSection = [],
  paymentVolumeDataSection = [],
  credentialId,
}) => {
  return (
    <form className='CustomerOnboardingFormReviewForm' onSubmit={handleSubmit(submitCustomerOnboardingForm)}>
      <div className='form-header'>
        <h3>{REVIEW}</h3>
        <div className='p-1'>{CUSTOMER_ONBOARDING_FORM_REVIEW_SUBTITLE}</div>
      </div>

      <div className='form-content'>
        <PageSectionV2C
          smallTitle={DEAL_INFORMATION}
          columns={dealInformationDataSection}
          actions={[{ label: EDIT, action: () => goToStep(0) }]}
        />

        <PageSectionV2C
          smallTitle={BUSINESS_INFORMATION}
          columns={businessInfoDataSection}
          actions={[{ label: EDIT, action: () => goToStep(1) }]}
        />

        <PageSectionV2C
          smallTitle={PROCESSING_INFORMATION}
          columns={processingInfoDataSection}
          actions={[{ label: EDIT, action: () => goToStep(1) }]}
        />

        <PageSectionV2C
          smallTitle={CARD_VOLUME}
          columns={cardVolumeDataSection}
          actions={[{ label: EDIT, action: () => goToStep(1) }]}
        />

        <PageSectionV2C
          smallTitle={PAYMENT_VOLUME_BY_CUSTOMER_TYPE}
          columns={paymentVolumeDataSection}
          actions={[{ label: EDIT, action: () => goToStep(1) }]}
        />
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => prevFlowStep()} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          <div className='exit-and-save-container'>
            <Button
              label={CANCEL}
              variant='ghost'
              size='large'
              onClick={() => goToPath({
                pathname: APPLICATIONS_PATH({ credentialId }),
                queries: { tab: 'onboarding-forms' },
              })}
            />
            <Button type='submit' label={CREATE_FORM} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

CustomerOnboardingFormReviewForm.propTypes = {
  goToStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  dealInformationDataSection: PropTypes.array,
  businessInfoDataSection: PropTypes.array,
  processingInfoDataSection: PropTypes.array,
  cardVolumeDataSection: PropTypes.array,
  paymentVolumeDataSection: PropTypes.array,
  credentialId: PropTypes.string,
}

export default reduxForm({
  form: CUSTOMER_ONBOARDING_FORM_REVIEW_FORM,
})(CustomerOnboardingFormReviewForm)
