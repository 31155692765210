import GatewayAdminTools from './GatewayAdminTools'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DASHBOARD_PATH } from 'constants/pathConstants'
import isAdministrator from 'utilities/is/isAdministrator'

const mapStateToProps = (state) => {
  const isAdmin = isAdministrator(state)

  return {
    isAdmin,
    redirectPath: DASHBOARD_PATH,
  }
}

class GatewayAdminToolsC extends Component {
  render () {
    return (
      <GatewayAdminTools {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(GatewayAdminToolsC)
