import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { PROCESSOR_LABEL_MAP } from 'constants/processorConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import join from 'lodash/join'

// TODO: write unit test for this function
const frontendVerificationModel = ({ data: verification }) => {
  const [
    id,
    createdAt,
    state,
    processor,
    result,
    validationDetails,
    inquiryDetails,
    generalInquiryDetails,
    accountInfo,
    accountVerification,
    messages,
  ] = getMany(verification, [
    'id',
    'created_at',
    'state',
    'processor',
    'raw',
    'raw.validation_details',
    'raw.inquiry_details',
    'raw.general_inquiry_details',
    'raw.account_info_response.account_info',
    'raw.account_verification_response.account_verification',
    'messages',
  ])

  const addressVerification = validationDetails ? get(validationDetails, 'address_verification_results') : get(accountVerification, 'avs_response_desc')
  const cvcStatus = validationDetails ? get(validationDetails, 'cvv2_result_code') : get(accountVerification, 'cvc_status')
  const cardTypeCode = generalInquiryDetails ? get(generalInquiryDetails, 'card_type_code') : get(accountInfo, 'type')
  const fastFundsIndicator = inquiryDetails ? get(inquiryDetails, 'visa_network_info[0].fast_funds_indicator') : get(accountInfo, 'funds_availability')
  const institutionName = generalInquiryDetails ? get(generalInquiryDetails, 'issuer_name') : get(accountInfo, 'institution_name')
  const receivingEligibility = inquiryDetails ? inquiryDetails : get(accountInfo, 'receiving_eligibility.eligible')
  const normalizedReportData = get(result, 'normalized_report_data')
  const identityBusinessInformation = get(normalizedReportData, 'IDENTITY_BUSINESS')
  const identityOwner1 = get(normalizedReportData, 'IDENTITY_OWNER_1')
  const hasOutcomeCode = !!get(result, '[0].outcome_code')
  const displayOutcomeCodes = hasOutcomeCode ? join(map(result, ({ outcome_code: outcomeCode }) => snakeCaseToTitleCase({ key: outcomeCode })), ', ') : ''
  const displayOutcomeReasons = hasOutcomeCode ? join(map(result, ({ description }) => description), ' ') : ''
  const displayOutcomeRemediations = hasOutcomeCode ? join(map(result, ({ remediation }) => remediation), ' ') : ''

  return {
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    state,
    processor,
    displayProcessor: get(PROCESSOR_LABEL_MAP, processor, processor),
    result,
    validationDetails,
    inquiryDetails,
    generalInquiryDetails,
    accountInfo,
    accountVerification,
    addressVerification,
    cardTypeCode,
    fastFundsIndicator,
    institutionName,
    cvcStatus,
    receivingEligibility,
    normalizedReportData,
    identityBusinessInformation,
    identityOwner1,
    messages,
    displayOutcomeCodes,
    displayOutcomeReasons,
    displayOutcomeRemediations,
  }
}

export default frontendVerificationModel
