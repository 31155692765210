import './MainHeaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import SearchFormC from 'components/Customer/Shared/SearchForm/SearchFormC'
import UserActionsC from 'components/System/UserActions/UserActionsC'
import MiniNavC from 'components/System/MiniNav/MiniNavC'
import CTABanner from 'components/Shared/CTABanner/CTABanner'
import { COMPANY_PATH } from 'constants/pathConstants'

import {
  ATTEST_TO_FORM,
  LOGOUT,
  PLEASE_COMPLETE_PCI_ATTESTATION,
} from 'constants/language/languageConstants'

const MainHeader = ({
  switchLink,
  handleLogout,
  inDashboard,
  formattedPath = '',
  showEnvironmentHeaderTag = false,
  environment = '',
  inCredentialsPage = false,
  userEmail = '',
  showOverdueComplianceFormBanner = false,
  hasIncompleteComplianceForm = false,
  credentialId = '',
}) => {
  const mainHeaderClassNames = classnames(`MainHeader ${environment}`, {
    dashboard: inDashboard,
    admin: !inDashboard,
  })

  return (
    <>
      {!inCredentialsPage && (
      <div className={mainHeaderClassNames}>
        { showEnvironmentHeaderTag && (
          <div className={`environment-tag-container ${environment}`}>
            <div className={`environment-tag bold ${environment}`}>{environment}</div>
          </div>
        )}

        <div className='header-content flex items-center space-between'>
          <SearchFormC />

          <div className='flex flex-end items-center'>
            <MiniNavC formattedPath={formattedPath} />

            <UserActionsC
              handleLogout={handleLogout}
              switchLink={switchLink}
            />
          </div>
        </div>
        {showOverdueComplianceFormBanner && (
          <CTABanner
            status={hasIncompleteComplianceForm ? 'warning' : 'error'}
            title={`Compliance Forms ${hasIncompleteComplianceForm ? 'Due' : 'Overdue'}`}
            subTitle={PLEASE_COMPLETE_PCI_ATTESTATION}
            action={() => goToPath({ pathname: COMPANY_PATH({ credentialId }), queries: { tab: 'compliance', state: hasIncompleteComplianceForm ? 'INCOMPLETE' : 'OVERDUE' } })}
            actionLabel={ATTEST_TO_FORM}
          />
        )}
      </div>
      )}

      {inCredentialsPage && (
      <div className='MainHeader flex flex-end'>
        <div className='header-right flex items-center'>
          <div className='email'>{userEmail}</div>

          <Button onClick={handleLogout} label={LOGOUT} rightIcon='sign-out' />
        </div>
      </div>
      )}
    </>
  )
}

MainHeader.propTypes = {
  switchLink: PropTypes.string,
  handleLogout: PropTypes.func,
  inDashboard: PropTypes.bool,
  formattedPath: PropTypes.string,
  showEnvironmentHeaderTag: PropTypes.bool,
  environment: PropTypes.string,
  inCredentialsPage: PropTypes.bool,
  userEmail: PropTypes.string,
  showOverdueComplianceFormBanner: PropTypes.bool,
  hasIncompleteComplianceForm: PropTypes.bool,
  credentialId: PropTypes.string,
}

export default MainHeader
