import validateTagsForm from 'utilities/validate/validateTagsForm'

import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreateBalanceTransferForm = (values) => {
  const {
    source,
    destination,
    amount,
    description,
    tags,
  } = values

  const sameSourceDestination = source === destination
  const tagErrors = validateTagsForm({ tags })

  return {
    source: fieldEmpty(source, 'Source') || (sameSourceDestination && 'Source and Destination cannot have the same value.'),
    destination: fieldEmpty(destination, 'Destination') || (sameSourceDestination && 'Source and Destination cannot have the same value.'),
    amount: nonEmptyNumber({ field: amount, name: 'Amount' }),
    description: fieldEmpty(description, 'Description'),
    ...tagErrors,
  }
}

export default validateCreateBalanceTransferForm
