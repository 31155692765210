import { CANNOT_BE_EMPTY } from 'constants/language/languageConstants'
import get from 'lodash/get'
import lt from 'lodash/lt'
import gt from 'lodash/gt'
import isNumber from 'lodash/isNumber'

const validateFeeProfileValues = ({ value, min, displayMin, max, displayMax, applyGuardRails = true, canBeEmpty = true }) => {
  if (!canBeEmpty && (!value && !isNumber(value))) {
    return CANNOT_BE_EMPTY
  }

  if (applyGuardRails && !!min && (!value && !isNumber(value))) {
    return CANNOT_BE_EMPTY
  }
  if (applyGuardRails && lt(parseFloat(value), parseFloat(min))) {
    return `Minimum is ${displayMin}`
  }

  if (applyGuardRails && gt(parseFloat(value), parseFloat(max))) {
    return `Maximum is ${displayMax}`
  }

  return false
}

const validateCardNotPresentForm = (values, props) => {
  const { guardRail, applyGuardRails } = props

  const {
    basisPoints,
    fixedFee,
    cardCrossBorderBasisPoints,
    cardCrossBorderFixedFee,
    visaBasisPoints,
    visaFixedFee,
    mastercardBasisPoints,
    mastercardFixedFee,
    americanExpressBasisPoints,
    americanExpressFixedFee,
    discoverBasisPoints,
    discoverFixedFee,
    jcbBasisPoints,
    jcbFixedFee,
    dinersClubBasisPoints,
    dinersClubFixedFee,
    disputeFixedFee,
    disputeInquiryFixedFee,
    visaAssessmentsBasisPoints,
    visaAcquirerProcessingFixedFee,
    visaCreditVoucherFixedFee,
    visaBaseIISystemFileTransmissionFixedFee,
    visaKilobyteAccessFixedFee,
    mastercardAssessmentsUnder1kBasisPoints,
    mastercardAssessmentsOver1kBasisPoints,
    mastercardAcquirerFeesBasisPoints,
    americanExpressAssessmentBasisPoints,
    discoverAssessmentsBasisPoints,
    discoverDataUsageFixedFee,
    discoverNetworkAuthorizationFixedFee,
  } = values

  return {
    basisPoints: validateFeeProfileValues({
      value: basisPoints,
      min: get(guardRail, 'basisPointsMin'),
      displayMin: get(guardRail, 'displayBasisPointsMin'),
      max: get(guardRail, 'basisPointsMax'),
      displayMax: get(guardRail, 'displayBasisPointsMax'),
      applyGuardRails,
      canBeEmpty: false,
    }),
    fixedFee: validateFeeProfileValues({
      value: fixedFee,
      min: get(guardRail, 'fixedFeeMin'),
      displayMin: get(guardRail, 'displayFixedFeeMin'),
      max: get(guardRail, 'fixedFeeMax'),
      displayMax: get(guardRail, 'displayFixedFeeMax'),
      applyGuardRails,
      canBeEmpty: false,
    }),
    cardCrossBorderBasisPoints: validateFeeProfileValues({
      value: cardCrossBorderBasisPoints,
      min: get(guardRail, 'cardCrossBorderBasisPointsMin'),
      displayMin: get(guardRail, 'displayCardCrossBorderBasisPointsMin'),
      max: get(guardRail, 'cardCrossBorderBasisPointsMax'),
      displayMax: get(guardRail, 'displayCardCrossBorderBasisPointsMax'),
      applyGuardRails,
    }),
    cardCrossBorderFixedFee: validateFeeProfileValues({
      value: cardCrossBorderFixedFee,
      min: get(guardRail, 'cardCrossBorderFixedFeeMin'),
      displayMin: get(guardRail, 'displayCardCrossBorderFixedFeeMin'),
      max: get(guardRail, 'cardCrossBorderFixedFeeMax'),
      displayMax: get(guardRail, 'displayCardCrossBorderFixedFeeMax'),
      applyGuardRails,
    }),
    visaBasisPoints: validateFeeProfileValues({
      value: visaBasisPoints,
      min: get(guardRail, 'visaBasisPointsMin'),
      displayMin: get(guardRail, 'displayVisaBasisPointsMin'),
      max: get(guardRail, 'visaBasisPointsMax'),
      displayMax: get(guardRail, 'displayVisaBasisPointsMax'),
      applyGuardRails,
    }),
    visaFixedFee: validateFeeProfileValues({
      value: visaFixedFee,
      min: get(guardRail, 'visaFixedFeeMin'),
      displayMin: get(guardRail, 'displayVisaFixedFeeMin'),
      max: get(guardRail, 'visaFixedFeeMax'),
      displayMax: get(guardRail, 'displayVisaFixedFeeMax'),
      applyGuardRails,
    }),
    mastercardBasisPoints: validateFeeProfileValues({
      value: mastercardBasisPoints,
      min: get(guardRail, 'mastercardBasisPointsMin'),
      displayMin: get(guardRail, 'displayMastercardBasisPointsMin'),
      max: get(guardRail, 'mastercardBasisPointsMax'),
      displayMax: get(guardRail, 'displayMastercardBasisPointsMax'),
      applyGuardRails,
    }),
    mastercardFixedFee: validateFeeProfileValues({
      value: mastercardFixedFee,
      min: get(guardRail, 'mastercardFixedFeeMin'),
      displayMin: get(guardRail, 'displayMastercardFixedFeeMin'),
      max: get(guardRail, 'mastercardFixedFeeMax'),
      displayMax: get(guardRail, 'displayMastercardFixedFeeMax'),
      applyGuardRails,
    }),
    americanExpressBasisPoints: validateFeeProfileValues({
      value: americanExpressBasisPoints,
      min: get(guardRail, 'americanExpressBasisPointsMin'),
      displayMin: get(guardRail, 'displayAmericanExpressBasisPointsMin'),
      max: get(guardRail, 'americanExpressBasisPointsMax'),
      displayMax: get(guardRail, 'displayAmericanExpressBasisPointsMax'),
      applyGuardRails,
    }),
    americanExpressFixedFee: validateFeeProfileValues({
      value: americanExpressFixedFee,
      min: get(guardRail, 'americanExpressFixedFeeMin'),
      displayMin: get(guardRail, 'displayAmericanExpressFixedFeeMin'),
      max: get(guardRail, 'americanExpressFixedFeeMax'),
      displayMax: get(guardRail, 'displayAmericanExpressFixedFeeMax'),
      applyGuardRails,
    }),
    discoverBasisPoints: validateFeeProfileValues({
      value: discoverBasisPoints,
      min: get(guardRail, 'discoverBasisPointsMin'),
      displayMin: get(guardRail, 'displayDiscoverBasisPointsMin'),
      max: get(guardRail, 'discoverBasisPointsMax'),
      displayMax: get(guardRail, 'displayDiscoverBasisPointsMax'),
      applyGuardRails,
    }),
    discoverFixedFee: validateFeeProfileValues({
      value: discoverFixedFee,
      min: get(guardRail, 'discoverFixedFeeMin'),
      displayMin: get(guardRail, 'displayDiscoverFixedFeeMin'),
      max: get(guardRail, 'discoverFixedFeeMax'),
      displayMax: get(guardRail, 'displayDiscoverFixedFeeMax'),
      applyGuardRails,
    }),
    jcbBasisPoints: validateFeeProfileValues({
      value: jcbBasisPoints,
      min: get(guardRail, 'jcbBasisPointsMin'),
      displayMin: get(guardRail, 'displayJcbBasisPointsMin'),
      max: get(guardRail, 'jcbBasisPointsMax'),
      displayMax: get(guardRail, 'displayJcbBasisPointsMax'),
      applyGuardRails,
    }),
    jcbFixedFee: validateFeeProfileValues({
      value: jcbFixedFee,
      min: get(guardRail, 'jcbFixedFeeMin'),
      displayMin: get(guardRail, 'displayJcbFixedFeeMin'),
      max: get(guardRail, 'jcbFixedFeeMax'),
      displayMax: get(guardRail, 'displayJcbFixedFeeMax'),
      applyGuardRails,
    }),
    dinersClubBasisPoints: validateFeeProfileValues({
      value: dinersClubBasisPoints,
      min: get(guardRail, 'dinersClubBasisPointsMin'),
      displayMin: get(guardRail, 'displayDinersClubBasisPointsMin'),
      max: get(guardRail, 'dinersClubBasisPointsMax'),
      displayMax: get(guardRail, 'displayDinersClubBasisPointsMax'),
      applyGuardRails,
    }),
    dinersClubFixedFee: validateFeeProfileValues({
      value: dinersClubFixedFee,
      min: get(guardRail, 'dinersClubFixedFeeMin'),
      displayMin: get(guardRail, 'displayDinersClubFixedFeeMin'),
      max: get(guardRail, 'dinersClubFixedFeeMax'),
      displayMax: get(guardRail, 'displayDinersClubFixedFeeMax'),
      applyGuardRails,
    }),
    disputeFixedFee: validateFeeProfileValues({
      value: disputeFixedFee,
      min: get(guardRail, 'disputeFixedFeeMin'),
      displayMin: get(guardRail, 'displayDisputeFixedFeeMin'),
      max: get(guardRail, 'disputeFixedFeeMax'),
      displayMax: get(guardRail, 'displayDisputeFixedFeeMax'),
      applyGuardRails,
    }),
    disputeInquiryFixedFee: validateFeeProfileValues({
      value: disputeInquiryFixedFee,
      min: get(guardRail, 'disputeInquiryFixedFeeMin'),
      displayMin: get(guardRail, 'displayDisputeInquiryFixedFeeMin'),
      max: get(guardRail, 'disputeInquiryFixedFeeMax'),
      displayMax: get(guardRail, 'displayDisputeInquiryFixedFeeMax'),
      applyGuardRails,
    }),
    visaAssessmentsBasisPoints: validateFeeProfileValues({
      value: visaAssessmentsBasisPoints,
      min: get(guardRail, 'visaAssessmentsBasisPointsMin'),
      displayMin: get(guardRail, 'displayVisaAssessmentsBasisPointsMin'),
      max: get(guardRail, 'visaAssessmentsBasisPointsMax'),
      displayMax: get(guardRail, 'displayVisaAssessmentsBasisPointsMax'),
      applyGuardRails,
    }),
    visaAcquirerProcessingFixedFee: validateFeeProfileValues({
      value: visaAcquirerProcessingFixedFee,
      min: get(guardRail, 'visaAcquirerProcessingFixedFeeMin'),
      displayMin: get(guardRail, 'displayVisaAcquirerProcessingFixedFeeMin'),
      max: get(guardRail, 'visaAcquirerProcessingFixedFeeMax'),
      displayMax: get(guardRail, 'displayVisaAcquirerProcessingFixedFeeMax'),
      applyGuardRails,
    }),
    visaCreditVoucherFixedFee: validateFeeProfileValues({
      value: visaCreditVoucherFixedFee,
      min: get(guardRail, 'visaCreditVoucherFixedFeeMin'),
      displayMin: get(guardRail, 'displayVisaCreditVoucherFixedFeeMin'),
      max: get(guardRail, 'visaCreditVoucherFixedFeeMax'),
      displayMax: get(guardRail, 'displayVisaCreditVoucherFixedFeeMax'),
      applyGuardRails,
    }),
    visaBaseIISystemFileTransmissionFixedFee: validateFeeProfileValues({
      value: visaBaseIISystemFileTransmissionFixedFee,
      min: get(guardRail, 'visaBaseIISystemFileTransmissionFixedFeeMin'),
      displayMin: get(guardRail, 'displayVisaBaseIISystemFileTransmissionFixedFeeMin'),
      max: get(guardRail, 'visaBaseIISystemFileTransmissionFixedFeeMax'),
      displayMax: get(guardRail, 'displayVisaBaseIISystemFileTransmissionFixedFeeMax'),
      applyGuardRails,
    }),
    visaKilobyteAccessFixedFee: validateFeeProfileValues({
      value: visaKilobyteAccessFixedFee,
      min: get(guardRail, 'visaKilobyteAccessFixedFeeMin'),
      displayMin: get(guardRail, 'displayVisaKilobyteAccessFixedFeeMin'),
      max: get(guardRail, 'visaKilobyteAccessFixedFeeMax'),
      displayMax: get(guardRail, 'displayVisaKilobyteAccessFixedFeeMax'),
      applyGuardRails,
    }),
    mastercardAssessmentsUnder1kBasisPoints: validateFeeProfileValues({
      value: mastercardAssessmentsUnder1kBasisPoints,
      min: get(guardRail, 'mastercardAssessmentsUnder1kBasisPointsMin'),
      displayMin: get(guardRail, 'displayMastercardAssessmentsUnder1kBasisPointsMin'),
      max: get(guardRail, 'mastercardAssessmentsUnder1kBasisPointsMax'),
      displayMax: get(guardRail, 'displayMastercardAssessmentsUnder1kBasisPointsMax'),
      applyGuardRails,
    }),
    mastercardAssessmentsOver1kBasisPoints: validateFeeProfileValues({
      value: mastercardAssessmentsOver1kBasisPoints,
      min: get(guardRail, 'mastercardAssessmentsOver1kBasisPointsMin'),
      displayMin: get(guardRail, 'displayMastercardAssessmentsOver1kBasisPointsMin'),
      max: get(guardRail, 'mastercardAssessmentsOver1kBasisPointsMax'),
      displayMax: get(guardRail, 'displayMastercardAssessmentsOver1kBasisPointsMax'),
      applyGuardRails,
    }),
    mastercardAcquirerFeesBasisPoints: validateFeeProfileValues({
      value: mastercardAcquirerFeesBasisPoints,
      min: get(guardRail, 'mastercardAcquirerFeesBasisPointsMin'),
      displayMin: get(guardRail, 'displayMastercardAcquirerFeesBasisPointsMin'),
      max: get(guardRail, 'mastercardAcquirerFeesBasisPointsMax'),
      displayMax: get(guardRail, 'displayMastercardAcquirerFeesBasisPointsMax'),
      applyGuardRails,
    }),
    americanExpressAssessmentBasisPoints: validateFeeProfileValues({
      value: americanExpressAssessmentBasisPoints,
      min: get(guardRail, 'americanExpressAssessmentBasisPointsMin'),
      displayMin: get(guardRail, 'displayAmericanExpressAssessmentBasisPointsMin'),
      max: get(guardRail, 'americanExpressAssessmentBasisPointsMax'),
      displayMax: get(guardRail, 'displayAmericanExpressAssessmentBasisPointsMax'),
      applyGuardRails,
    }),
    discoverAssessmentsBasisPoints: validateFeeProfileValues({
      value: discoverAssessmentsBasisPoints,
      min: get(guardRail, 'discoverAssessmentsBasisPointsMin'),
      displayMin: get(guardRail, 'displayDiscoverAssessmentsBasisPointsMin'),
      max: get(guardRail, 'discoverAssessmentsBasisPointsMax'),
      displayMax: get(guardRail, 'displayDiscoverAssessmentsBasisPointsMax'),
      applyGuardRails,
    }),
    discoverDataUsageFixedFee: validateFeeProfileValues({
      value: discoverDataUsageFixedFee,
      min: get(guardRail, 'discoverDataUsageFixedFeeMin'),
      displayMin: get(guardRail, 'displayDiscoverDataUsageFixedFeeMin'),
      max: get(guardRail, 'discoverDataUsageFixedFeeMax'),
      displayMax: get(guardRail, 'displayDiscoverDataUsageFixedFeeMax'),
      applyGuardRails,
    }),
    discoverNetworkAuthorizationFixedFee: validateFeeProfileValues({
      value: discoverNetworkAuthorizationFixedFee,
      min: get(guardRail, 'discoverNetworkAuthorizationFixedFeeMin'),
      displayMin: get(guardRail, 'displayDiscoverNetworkAuthorizationFixedFeeMin'),
      max: get(guardRail, 'discoverNetworkAuthorizationFixedFeeMax'),
      displayMax: get(guardRail, 'displayDiscoverNetworkAuthorizationFixedFeeMax'),
      applyGuardRails,
    }),
  }
}

export default validateCardNotPresentForm
