import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomBigNumber from './CustomBigNumber'
import removeUndefined from 'utilities/remove/removeUndefined'
import { getChartByChartName } from 'state-layer/selectors/index'
import { FETCHING } from 'constants/reducerConstants'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  normalizeBackendStartDate,
  normalizeBackendEndDate,
} from 'constants/normalizationConstants'

import {
  GET_CHART_F_REQUEST,
  GET_CHART_DELTA_F_REQUEST,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const {
    headerData = {},
    subHeaderData,
    shouldFetch = false,
  } = props

  const headerFormatter = get(headerData, 'formatter')
  const headerChart = getChartByChartName(state, get(headerData, 'name'))
  const headerChartId = get(headerChart, 'id')
  const headerChartGetKey = get(headerChart, 'data.0.colnames.0')
  const headerChartData = get(headerChart, 'data.0.data.0', {})
  const headerChartValue = headerChart ? headerChartData[headerChartGetKey] : get(headerData, 'value')
  const headerChartDeltaPercent = get(headerChart, 'deltaPercent')
  const subHeaderChartName = get(subHeaderData, 'name')
  const subHeaderFormatter = get(subHeaderData, 'formatter')
  const subHeaderChart = getChartByChartName(state, subHeaderChartName)
  const subHeaderChartId = get(subHeaderChart, 'id')
  const subHeaderChartGetKey = get(subHeaderChart, 'data.0.colnames.0')
  const subHeaderChartValue = subHeaderData ? get(subHeaderChart, `data.0.data.0.${subHeaderChartGetKey}`) : get(subHeaderData, 'value')
  const headerFetching = get(headerChart, FETCHING, shouldFetch)
  const subHeaderFetching = get(subHeaderChart, FETCHING, shouldFetch)
  const isFetching = headerFetching || subHeaderFetching

  return {
    headerChartId,
    headerChartValue: headerFormatter ? headerFormatter(headerChartValue) : headerChartValue,
    headerChartDeltaPercent,
    subHeaderChartId,
    subHeaderChartValue: subHeaderFormatter ? subHeaderFormatter(subHeaderChartValue) : subHeaderChartValue,
    isFetching,
    headerFetching,
    subHeaderFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChart: ({ id, timeRange, fetchDelta = false }) => {
      const timeRangeLte = get(timeRange, 'lte')
      const timeRangeGte = get(timeRange, 'gte')

      const queries = removeUndefined({
        'time_range.gte': timeRangeGte ? normalizeBackendStartDate(timeRangeGte) : undefined,
        'time_range.lte': timeRangeLte ? normalizeBackendEndDate(timeRangeLte) : undefined,
      })

      dispatch({
        type: GET_CHART_F_REQUEST,
        payload: {
          id,
          queries,
        },
      })

      if (fetchDelta) {
        dispatch({
          type: GET_CHART_DELTA_F_REQUEST,
          payload: {
            id,
            queries,
          },
        })
      }
    },
  }
}

class CustomBigNumberC extends Component {
  componentDidMount() {
    const {
      headerChartId,
      subHeaderChartId,
      getChart,
      timeRange,
    } = this.props

    if (headerChartId) {
      getChart({ id: headerChartId, timeRange, fetchDelta: true })
    }

    if (subHeaderChartId) {
      getChart({ id: subHeaderChartId, timeRange })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      headerChartId: prevHeaderChartId,
      subHeaderChartId: prevSubHeaderChartId,
      timeRange: prevTimeRange = {},
    } = prevProps

    const {
      getChart,
      headerChartId,
      subHeaderChartId,
      timeRange = {},
    } = this.props

    const headerChartIdChanged = headerChartId !== prevHeaderChartId
    const subHeaderChartIdChanged = subHeaderChartId !== prevSubHeaderChartId
    const timeRangeChanged = !isEqual(prevTimeRange, timeRange)

    if (headerChartIdChanged || subHeaderChartIdChanged || timeRangeChanged) {
      if (headerChartId) {
        getChart({ id: headerChartId, timeRange, fetchDelta: true })
      }

      if (subHeaderChartId) {
        getChart({ id: subHeaderChartId, timeRange })
      }
    }
  }

  render() {
    return (
      <CustomBigNumber {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomBigNumberC)
