import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import EditComplianceFormTemplateForm from 'components/Customer/Forms/EditComplianceFormTemplateForm/EditComplianceFormTemplateForm'
import validateEditComplianceFormTemplateForm from 'utilities/validate/validateEditComplianceFormTemplateForm'
import { EDIT_COMPLIANCE_FORM_TEMPLATE_FORM } from 'constants/formConstants'
import get from 'lodash/get'

class EditComplianceFormTemplateFormC extends Component {
  componentDidMount() {
    const { initialize, complianceFormTemplate } = this.props

    initialize({ name: get(complianceFormTemplate, 'name') })
  }

  render() {
    return (
      <EditComplianceFormTemplateForm {...this.props} />
    )
  }
}

export default connect()(reduxForm({
  form: EDIT_COMPLIANCE_FORM_TEMPLATE_FORM,
  validate: validateEditComplianceFormTemplateForm,
})(EditComplianceFormTemplateFormC))
