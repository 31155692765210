import { FEE } from 'constants/subscriptionConstants'
import { USD } from 'constants/currencyConstants'

const backendSubscriptionAmountModel = ({ values }) => {
  const {
    amount,
    label,
    amountNickname,
    tags,
    subscriptionScheduleId,
  } = values

  return {
    amount_type: FEE,
    fee_amount_data: {
      amount,
      label,
      currency: USD,
    },
    nickname: amountNickname,
    tags,
    subscription_schedule_id: subscriptionScheduleId,
  }
}

export default backendSubscriptionAmountModel
