import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  ACTIVE,
  INACTIVE,
  SUCCEEDED,
  CANCELED,
} from 'constants/statusConstants'

const SubscriptionPlanStatusColorData = {
  Active: SUCCEEDED,
  Archived: CANCELED,
}

const subscriptionPlanStateToDisplayMap = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Archived',
}

const SubscriptionPlanStatus = ({
  value = '',
}) => {
  return (
    <ColorcodedStatus data={SubscriptionPlanStatusColorData} value={subscriptionPlanStateToDisplayMap[value]} />
  )
}

SubscriptionPlanStatus.propTypes = {
  value: PropTypes.string,
}

export default SubscriptionPlanStatus
