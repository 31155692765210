import React from 'react'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import { goToPath } from 'state-layer/history'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { CUSTOM } from 'constants/roleConstants'
import isEmpty from 'lodash/isEmpty'

import {
  ADD_ROLE_PATH,
  DUPLICATE_ROLE_PATH,
} from 'constants/pathConstants'

import {
  DESCRIPTION,
  ID,
  NAME,
  STATE,
  DUPLICATE_AND_MODIFIY,
  EDIT_ROLE,
  DISABLE_ROLE,
  CUSTOM_ROLE,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ columnActions = {} }) => [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(NAME, 'roleName', {
    className: 'medium',
    formatter: ({ type, roleName }) => <TableItemSubtitle title={roleName} subtitle={type === CUSTOM ? CUSTOM_ROLE : ' '} />,
  }),
  column(DESCRIPTION, 'description', {
    className: 'large',
  }),
  column(STATE, 'roleEnabled', {
    className: 'grow',
    formatter: ({ roleEnabled }) => <EnabledStatus value={roleEnabled ? 'Enabled' : 'Disabled'} />,
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: (values) => {
      const [
        roleType,
        roleId,
        credentials,
        credentialId,
        roleName,
        roleEnabled,
        entityId,
        entityType,
      ] = getMany(values, [
        'type',
        'id',
        'credentials',
        'credentials.id',
        'roleName',
        'roleEnabled',
        'entityId',
        'entityType',
      ])

      const [
        showDisableCustomRoleModal,
      ] = getMany(columnActions, [
        'showDisableCustomRoleModal',
      ])

      const actions = [
        {
          label: DUPLICATE_AND_MODIFIY,
          action: () => goToPath({
            pathname: DUPLICATE_ROLE_PATH({ credentialId }),
            queries: {
              roleId,
              entityId,
              entityType,
            },
          }),
        },
        {
          label: EDIT_ROLE,
          action: () => goToPath({
            pathname: ADD_ROLE_PATH({ credentialId }),
            queries: {
              roleId,
              entityId,
              entityType,
            },
          }),
          condition: roleType === CUSTOM,
        },
        {
          label: DISABLE_ROLE,
          action: () => showDisableCustomRoleModal({ roleId, roleName, credentials }),
          condition: roleEnabled && roleType === CUSTOM,
        },
      ]

      return (
        <TableRowActionsC
          actions={actions}
        />
      )
    },
  }),
]

export default columnDescriptors
