import './IdentitySettlementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getIdentitySettlements } from 'state-layer/selectors'
import columnDescriptors from 'components/Customer/Pages/Settlements/columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { IDENTITY_SETTLEMENTS } from 'constants/linkConstants'
import { SETTLEMENTS_ICON } from 'constants/iconConstants'
import { IDENTITY_SETTLEMENTS_RESOURCE_TITLE } from 'constants/language/languageConstants'

import {
  GET_IDENTITY_SETTLEMENTS,
  SETTLEMENT,
} from 'constants/flowConstants'

const IdentitySettlements = ({
  flowValues = {},
  initialQueries = {},
  initialFilters = {},
  allowedFilters = [],
  settlementPath = '',
  isFetching = false,
  identityId = '',
  identitySettlementActions,
  quickFilters,
}) => {
  return (
    <div className='IdentitySettlements'>
      <TableC
        flow={GET_IDENTITY_SETTLEMENTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getIdentitySettlements}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_SETTLEMENTS}
        initialFilters={initialFilters}
        allowedFilters={allowedFilters}
        path={settlementPath}
        isFetching={isFetching}
        actions={identitySettlementActions}
        icon={`fa fa-${SETTLEMENTS_ICON}`}
        fileNameCSV={`Identity_${identityId}_Settlements`}
        fileResourceTitleCSV={IDENTITY_SETTLEMENTS_RESOURCE_TITLE}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={SETTLEMENT}
      />
    </div>
  )
}

IdentitySettlements.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  allowedFilters: PropTypes.array,
  settlementPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  identitySettlementActions: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default IdentitySettlements
