import './TooltipWarningS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import isFunction from 'lodash/isFunction'
import { ALERT_CIRCLE_ICON } from 'constants/iconConstants'

const TooltipWarning = ({
  message,
  condition = false,
}) => {
  const tooltipClasses = classnames(`fa fa-${ALERT_CIRCLE_ICON}`, 'tooltip')

  return condition && (
    <div className='TooltipWarning flex'>
      <div className={tooltipClasses}>
        <div className='message'>
          {isFunction(message) ? message() : message}
          <span className="arrow" />
        </div>
      </div>
    </div>
  )
}

TooltipWarning.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  condition: PropTypes.bool,
}

export default TooltipWarning
