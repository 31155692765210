import './RadioComponentsS.scss'
import React from 'react'
import map from 'lodash/map'

// Custom component where you can pass in any component and it will be treated like a radio button
const RadioComponents = ({ input, options }) => (
  <div className='RadioComponents'>
    {map(options, (option) => (
      <label key={option.value} className='radio-component label-1'>
        <input
          type='radio'
          {...input}
          value={option.value}
          checked={option.value === input.value}
        />

        <div className={`radio-component-inner ${option.value === input.value ? 'checked' : ''}`}>{option.label}</div>
      </label>
    ))}
  </div>
)

export default RadioComponents
