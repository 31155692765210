import fwsFinixProxy from 'utilities/proxy/fwsFinixProxy'
import { SUBSCRIPTION_SCHEDULE } from 'constants/apiConstants'

const getSubscriptionScheduleAPI = ({ id, meta }) => {
  return fwsFinixProxy.get({
    meta,
    path: SUBSCRIPTION_SCHEDULE({ subscriptionScheduleId: id }),
  })
}

export default getSubscriptionScheduleAPI
