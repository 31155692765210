import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import get from 'lodash/get'

import {
  F_SUCCESS,
  F_FAILURE,
  ADD_TABLE_FILTER,
  REMOVE_TABLE_FILTER,
  CLEAR_TABLE_LINKS,
} from 'constants/flowConstants'

const linksR = (state = {}, action = {}) => {
  const actionType = get(action, 'type', '')
  const linksKey = get(action, 'payload.linksKey')

  if (!linksKey) return state

  if (actionType.includes(F_FAILURE)) {
    return omit(state, linksKey)
  }

  if (actionType.includes(F_SUCCESS)) {
    const page = get(action, 'payload.page')
    const links = get(action, 'payload.links', {})

    if (!isEmpty(links) || !isEmpty(page)) {
      return merge({}, omit(state, linksKey), {
        [linksKey]: {
          page,
          ...links,
        },
      })
    }
  }

  switch (actionType) {
    case ADD_TABLE_FILTER:
    case REMOVE_TABLE_FILTER:
    case CLEAR_TABLE_LINKS:
      return omit(state, linksKey)

    default:
      return state
  }
}

export default linksR
