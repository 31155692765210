import genericFlow from 'utilities/genericFlow'
import genericSaga from 'utilities/genericSaga'
import genericFlows from 'state-layer/flows/genericFlows'
import map from 'lodash/map'

const generateGenericFlows = map(genericFlows, (flowProperties) => {
  const {
    api,
    orchestration,
    requestF,
    successF,
    failureF,
    successS,
    failureS,
    frontendModel,
    backendModel,
    dataKey,
    debug,
    // TODO: remove the V2 logic after all customers migrate to DS_V2
    orchestrationV2,
    frontendModelV2,
    dataKeyV2,
    normalizeQueries,
    shouldNormalizeFrontend,
    shouldNormalizeBackend,
  } = flowProperties

  return genericFlow({
    requestF,
    successF,
    failureF,
    successS,
    failureS,
    debug,
    saga: genericSaga({
      successS,
      failureS,
      frontendModel,
      backendModel,
      api,
      orchestration,
      dataKey,
      debug,
      orchestrationV2,
      frontendModelV2,
      dataKeyV2,
      normalizeQueries,
      shouldNormalizeFrontend,
      shouldNormalizeBackend,
    }),
  })
})

export default generateGenericFlows
