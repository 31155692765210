import React, { Component } from 'react'
import { connect } from 'react-redux'
import BusinessMatchReport from './BusinessMatchReport'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatPhone from 'utilities/forms/format/formatPhone'
import Address from 'components/Customer/Shared/Display/Address/Address'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  ADDED_BY_ACQUIRER_ID,
  ADDED_ON,
  ADDRESS,
  BUSINESS_ADDRESS,
  BUSINESS_DBA,
  BUSINESS_NAME,
  BUSINESS_PHONE,
  BUSINESS_TAX_ID,
  EXACT_MATCH as EXACT_MATCH_LABEL,
  NO_MATCH as NO_MATCH_LABEL,
  PHONETIC_MATCH as PHONETIC_MATCH_LABEL,
  PERSON_ADDRESS,
  PERSON_NAME,
  PERSON_PHONE,
  PERSON_TAX_ID,
  PHONE_NUMBER,
  TERMINATION_REASON,
  UNKNOWN,
} from 'constants/language/languageConstants'

import {
  NO_MATCH,
  PHONETIC_MATCH,
  EXACT_MATCH,
} from 'constants/underwritingConstants'

import {
  ALERT_CIRCLE_ICON,
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  DOT_CIRCLE,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})
  const hits = get(report, 'details.hits', [])

  const hitsContent = map(hits, (hit) => {
    const [
      businessName,
      businessNameResult,
      businessDba,
      businessDbaResult,
      addedOn,
      addedByAcquirerId,
      terminationReason,
      businessPhoneAreaCode,
      businessPhoneCountryCode,
      businessPhoneNumber,
      businessPhoneNumberResult,
      businessAddressLine1,
      businessAddressLine2,
      businessAddressCity,
      businessAddressRegion,
      businessAddressPostalCode,
      businessAddressCountry,
      businessAddressResult,
      businessTaxIdResult,
      persons,
      personTaxIdResult,
      personAddressResult,
      personPhoneNumberResult,
      personNameResult,
    ] = getMany(hit, [
      'business.name_details.name',
      'business.name_details.result',
      'business.doing_business_as_details.doing_business_as',
      'business.doing_business_as_details.result',
      'details.added_on',
      'details.added_by_acquirer_id',
      'details.termination_reason',
      'business.phone_number_details.phone_number.area_code',
      'business.phone_number_details.phone_number.country_code',
      'business.phone_number_details.phone_number.phone_number',
      'business.phone_number_details.result',
      'business.address_details.address.address_lines.0',
      'business.address_details.address.address_lines.1',
      'business.address_details.address.locality',
      'business.address_details.address.administrative_area',
      'business.address_details.address.postal_code',
      'business.address_details.address.region_code',
      'business.address_details.result',
      'business.tax_id_details.result',
      'persons',
      'persons[0].tax_id_details.result',
      'persons[0].address_details.result',
      'persons[0].phone_number_details.result',
      'persons[0].name_details.result',
    ])

    const title = businessName
    const merchantInformationColumns = [
      { label: ADDED_ON, value: formatDate({ date: addedOn }) },
      { label: ADDED_BY_ACQUIRER_ID, value: addedByAcquirerId },
      { label: TERMINATION_REASON, value: terminationReason },
      { label: BUSINESS_PHONE, value: formatPhone(businessPhoneCountryCode + businessPhoneAreaCode + businessPhoneNumber) },
      { label: BUSINESS_NAME, value: businessName },
      { label: BUSINESS_DBA, value: businessDba },
      { label: BUSINESS_ADDRESS,
        value: <Address address={{
          line1: businessAddressLine1,
          line2: businessAddressLine2,
          city: businessAddressCity,
          region: businessAddressRegion,
          postalCode: businessAddressPostalCode,
          country: businessAddressCountry,
        }}
        /> },
    ]

    const formatVerificationSummaryValue = (value) => {
      switch (value) {
        case NO_MATCH:
          return (
            <div className='flex items-center verification-summary-string'><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{NO_MATCH_LABEL}</div>
          )

        case EXACT_MATCH:
          return (
            <div className='flex items-center verification-summary-string'><i className={`icon fa fa-${ALERT_CIRCLE_ICON}`} />{EXACT_MATCH_LABEL}</div>
          )

        case PHONETIC_MATCH:
          return (
            <div className='flex items-center verification-summary-string'><i className={`icon fa fa-${ALERT_ICON}`} />{PHONETIC_MATCH_LABEL}</div>
          )

        default:
          return (
            <div className='flex items-center verification-summary-string'><i className={`icon fa ${DOT_CIRCLE}`} />{UNKNOWN}</div>
          )
      }
    }

    const verificationSummaryColumns = [
      { label: BUSINESS_NAME, value: formatVerificationSummaryValue(businessNameResult) },
      { label: BUSINESS_TAX_ID, value: formatVerificationSummaryValue(businessTaxIdResult) },
      { label: BUSINESS_ADDRESS, value: formatVerificationSummaryValue(businessAddressResult) },
      { label: BUSINESS_PHONE, value: formatVerificationSummaryValue(businessPhoneNumberResult) },
      { label: BUSINESS_DBA, value: formatVerificationSummaryValue(businessPhoneNumberResult) },
      { label: PERSON_TAX_ID, value: formatVerificationSummaryValue(personTaxIdResult) },
      { label: PERSON_NAME, value: formatVerificationSummaryValue(personNameResult) },
      { label: PERSON_PHONE, value: formatVerificationSummaryValue(personPhoneNumberResult) },
      { label: PERSON_ADDRESS, value: formatVerificationSummaryValue(personAddressResult) },
    ]

    const personsData = map(persons, (person, index) => {
      const [
        firstName,
        lastName,
        phoneNumber,
        addressLine1,
        addressLine2,
        addressCity,
        addressRegion,
        addressPostalCode,
        addressCountry,
      ] = getMany(person, [
        'name_details.first_name',
        'name_details.last_name',
        'phone_number_details.phone_number',
        'address_details.address.address_lines.0',
        'address_details.address.address_lines.1',
        'address_details.address.locality',
        'address_details.address.administrative_area',
        'address_details.address.postal_code',
        'address_details.address.region_code',
      ])

      return {
        title: `Principal Owner #${index + 1}: ${firstName} ${lastName}`,
        columns: [
          {
            type: 'data',
            data: [
              { label: PHONE_NUMBER, value: formatPhone(phoneNumber) },
            ],
          },
          {
            type: 'data',
            data: [
              { label: ADDRESS,
                value: <Address address={{
                  line1: addressLine1,
                  line2: addressLine2,
                  city: addressCity,
                  region: addressRegion,
                  postalCode: addressPostalCode,
                  country: addressCountry,
                }}
                /> },
            ],
          },
          {
            type: 'data',
            data: [],
          },
        ],
      }
    })

    return {
      title,
      merchantInformationColumns,
      verificationSummaryColumns,
      personsData,
    }
  })

  return {
    credentials,
    hitsContent,
  }
}

class BusinessMatchReportC extends Component {
  render() {
    return (
      <BusinessMatchReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(BusinessMatchReportC)
