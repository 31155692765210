import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewAndSubmitForm from './ReviewAndSubmitForm'
import get from 'lodash/get'
import frontendFeeProfileModel from 'utilities/create/models/frontendFeeProfileModel'
import backendFeeProfileModel from 'utilities/create/models/backendFeeProfileModel'
import { APPLICATION } from 'constants/dashboardConfigurationsConstants'

import {
  NEW_FEE_PROFILE_APPLY_TO_MERCHANTS,
  NEW_FEE_PROFILE_DIFFERENT_FEES_SAME_DAY,
  REVIEW_DEFAULT_FEE_PROFILE,
  REVIEW_DEFAULT_MERCHANT_FEE_PROFILE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const entityType = get(props, 'entityType')
  const formValues = get(state, 'flowsR.formValues')
  const cnpValues = get(formValues, 'cnp')
  const cpValues = get(formValues, 'cp')
  const achValues = get(formValues, 'ach')

  const infoContainerHeader = entityType === APPLICATION ? NEW_FEE_PROFILE_APPLY_TO_MERCHANTS : NEW_FEE_PROFILE_DIFFERENT_FEES_SAME_DAY
  const infoContainerText = entityType === APPLICATION ? 'For pre-existing merchants, update their individual fee profile under Merchant Account > Fee & Risk Profile tab.' : 'Changes to fee profiles take place immediately upon submission. New fees will only apply to transactions happening after changes.'
  const title = entityType === APPLICATION ? REVIEW_DEFAULT_MERCHANT_FEE_PROFILE : REVIEW_DEFAULT_FEE_PROFILE

  const backendValues = backendFeeProfileModel({
    values: {
      ...cnpValues,
      ...achValues,
    },
  })

  const cardPresentBackendValues = backendFeeProfileModel({
    values: cpValues,
  })

  const feeProfileFormValues = {
    feeProfile: frontendFeeProfileModel({ data: backendValues }),
    cardPresentFeeProfile: get(cpValues, 'applyCardNotPresentProfile') ? undefined : frontendFeeProfileModel({ data: cardPresentBackendValues }),
  }

  return {
    feeProfileFormValues,
    infoContainerHeader,
    infoContainerText,
    title,
  }
}

class ReviewAndSubmitFormC extends Component {
  render() {
    return (
      <ReviewAndSubmitForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ReviewAndSubmitFormC)
