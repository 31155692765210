import React from 'react'
import PropTypes from 'prop-types'
import { getComplianceFormsApplicationSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import { COMPLIANCE_FORMS } from 'constants/linkConstants'
import { GET_COMPLIANCE_FORMS } from 'constants/flowConstants'
import { COMPLIANCE_FORMS_ICON } from 'constants/iconConstants'

import {
  COMPLIANCE_FORMS_RESOURCE_TITLE,
  COMPLIANCE_FORMS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const ComplianceForms = ({
  complianceFormsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  columnDescriptors = [],
  showLimitCount = false,
  quickFilters,
}) => {
  return (
    <div className='ComplianceForms'>
      <TableC
        flow={GET_COMPLIANCE_FORMS}
        columnDescriptors={columnDescriptors}
        selector={getComplianceFormsApplicationSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={COMPLIANCE_FORMS}
        allowedFilters={allowedFilters}
        path={complianceFormsPath}
        isFetching={isFetching}
        emptyMessage={COMPLIANCE_FORMS_EMPTY_MESSAGE}
        icon={`fa fa-${COMPLIANCE_FORMS_ICON}`}
        fileNameCSV='Compliance_Forms'
        fileResourceTitleCSV={COMPLIANCE_FORMS_RESOURCE_TITLE}
        showLimitCount={showLimitCount}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ComplianceForms.propTypes = {
  complianceFormsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  columnDescriptors: PropTypes.array,
  showLimitCount: PropTypes.bool,
  quickFilters: PropTypes.array,
}

export default ComplianceForms
