import isEqual from 'lodash/isEqual'

import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  INSTITUTION_NUMBER,
  ROUTING_NUMBER,
  TRANSIT_NUMBER,
} from 'constants/language/languageConstants'

import {
  INSTITUTION_NUMBER_REGEX,
  TRANSIT_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  validateBankCode,
  validateBankAccountNumber,
  invalidOrEmptyRegex,
} from 'utilities/validate'

const validateAddIdentityBankAccountForm = ({
  name,
  routingNumber,
  transitNumber,
  institutionNumber,
  accountNumber,
  confirmAccountNumber,
  settlementAlias,
  accountType,
}) => {
  return {
    name: fieldEmpty(name, ACCOUNT_HOLDER_NAME),
    routingNumber: validateBankCode({ bankCode: routingNumber, name: ROUTING_NUMBER }),
    accountNumber: validateBankAccountNumber({ accountNumber, name: ACCOUNT_NUMBER }),
    confirmAccountNumber: isEqual(accountNumber, confirmAccountNumber) ? fieldEmpty(confirmAccountNumber, 'Confirm Account Number') : 'The account numbers must match.',
    transitNumber: invalidOrEmptyRegex({ field: transitNumber, name: TRANSIT_NUMBER, regex: TRANSIT_NUMBER_REGEX }),
    institutionNumber: invalidOrEmptyRegex({ field: institutionNumber, name: INSTITUTION_NUMBER, regex: INSTITUTION_NUMBER_REGEX }),
    settlementAlias: fieldEmpty(settlementAlias, 'Settlement Alias'),
    accountType: fieldEmpty(accountType, 'Account Type'),
  }
}

export default validateAddIdentityBankAccountForm
