import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { APPLICATION_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  PATCH_FEE_PROFILE_RULES_F_REQUEST,
  POST_FEE_PROFILE_RULES_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_FEE_PROFILE_RULES,
  UPDATE_FEE_PROFILE_RULES,
} from 'constants/amplitudeConstants'

const submitEditFeeProfileRulesForm = (values, dispatch, props) => {
  const {
    credentials,
    formValues,
    entityType,
    entityId,
    credentialId,
    noExistingFeeProfileRules,
  } = props

  const cardValues = get(formValues, 'card')
  const achValues = get(formValues, 'ach')

  const valuesToSubmit = {
    blended: {
      USD: {
        ...get(cardValues, 'blended.USD', {}),
        ...get(achValues, 'USD', {}),
      },
      CAD: {
        ...get(cardValues, 'blended.CAD', {}),
        ...get(achValues, 'CAD', {}),
      },
    },
    'interchange-plus': {
      USD: {
        ...get(cardValues, 'interchange-plus.USD', {}),
        ...get(achValues, 'USD', {}),
      },
      CAD: {
        ...get(cardValues, 'interchange-plus.CAD', {}),
        ...get(achValues, 'CAD', {}),
      },
    },
  }

  const action = noExistingFeeProfileRules ? POST_FEE_PROFILE_RULES_F_REQUEST : PATCH_FEE_PROFILE_RULES_F_REQUEST
  const amplitudeEventName = noExistingFeeProfileRules ? CREATE_FEE_PROFILE_RULES : UPDATE_FEE_PROFILE_RULES

  dispatch({
    type: action,
    payload: {
      id: entityId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(amplitudeEventName, {
        credentials,
        entityType,
        entityId,
      }),
      successCallback: () => {
        const path = `${APPLICATION_PATH({ credentialId, applicationId: entityId })}?tab=fee-profile&secondaryTab=fee-profile-limits`
        dispatch(redirectRequest({ path }))
      },
    },
  })
}

export default submitEditFeeProfileRulesForm
