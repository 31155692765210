import './MainNavS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import MainNavItem from './MainNavItem'
import Skeleton from 'components/Shared/Skeleton/Skeleton'
import removeUndefined from 'utilities/remove/removeUndefined'
import map from 'lodash/map'
import some from 'lodash/some'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'

import {
  isAllowedCheck,
  normalizeRoute,
  isActiveLinkStatus,
} from 'constants/sidebarConstants'

const MainNav = ({
  credentialId,
  sidebarData = [],
  bottomNavBar = [],
  applicationLogoImage = '',
  pathname = '',
  currentRole = '',
  inDashboard = true,
  mainLink,
  isFetchingUser = true,
  sidebarBackgroundColor = '',
  sidebarTextColor = '',
  sidebarLogoImageUrl = '',
  sidebarHighlightColor = '',
  toggleSidebarSections = () => {},
  inCredentialsPage = false,
  isLoading = false,
}) => {
  const sidebarStyles = removeUndefined({
    color: sidebarTextColor,
    backgroundColor: sidebarBackgroundColor,
  })

  const sidebarItemStyles = removeUndefined({
    color: sidebarTextColor,
  })

  const logoImage = sidebarLogoImageUrl || applicationLogoImage

  // TODO: look into best way of normalizing all logos
  const sidebarLogoStyles = {
    maxHeight: sidebarLogoImageUrl ? '100%' : '40px',
  }

  const sidebarHighlightStyles = removeUndefined({
    color: sidebarHighlightColor,
  })

  const activeDotHighlightStyles = removeUndefined({
    backgroundColor: sidebarHighlightColor,
  })

  const mainNavClassName = classnames('MainNav', {
    dashboard: inDashboard,
    admin: !inDashboard,
    open: !isFetchingUser,
    loading: isLoading && !inCredentialsPage,
  })

  const logoLinkClassNames = classnames('logo-link', {
    'credentials-page': inCredentialsPage,
  })

  return (
    <div className={mainNavClassName}>
      <div className='background flex column' style={sidebarStyles}>
        { (isLoading && !inCredentialsPage) ? <Skeleton height='48px' /> : !!logoImage && (
          <Link className={logoLinkClassNames} to={!inCredentialsPage ? mainLink : ''}>
            <div className='logo'>
              <img src={logoImage} alt='applicationLogoImage home button' style={sidebarLogoStyles} />
            </div>
          </Link>
        )}

        <ul className='nav-bar-container'>
          {map(sidebarData, (sidebarItem, index) => {
            const {
              icon,
              label: headerLabel,
              allowedRoles,
              isHidden = false,
              adminCheck = () => true,
              items = [],
              key: headerKey,
              route: headerRoute = () => {},
              isDivider = false,
            } = sidebarItem

            // if there are no items, skip this mainNav header
            if (!isEmpty(items) && every(items, { isHidden: true })) return null

            const isAllowed = isAllowedCheck({ allowedRoles, adminCheck, currentRole })
            const headerRouteWithCredentials = normalizeRoute({ route: headerRoute, credentialId })
            const currentHeaderItem = document.querySelector(`.nav-bar-content.${headerKey}`)
            const isCurrentlyExpanded = currentHeaderItem ? currentHeaderItem.classList.contains('expanded') : false

            const hasActiveChild = some(items, ({ route: childRoute, key }) => {
              const normalizedChildRoute = normalizeRoute({ route: childRoute, credentialId })
              return isActiveLinkStatus({ route: normalizedChildRoute, pathname, key })
            })

            const isActiveHeader = isActiveLinkStatus({ route: headerRouteWithCredentials, pathname, key: headerKey }) || hasActiveChild
            const iconWeight = (isActiveHeader && !isCurrentlyExpanded) ? 'fas' : 'fal'

            if (isLoading && !inCredentialsPage) return <Skeleton key={`nav-skeleton-${index}`} height='26px' />

            if (!isAllowed || isHidden) return null

            return (
              <MainNavItem
                key={headerKey}
                credentialId={credentialId}
                pathname={pathname}
                headerKey={headerKey}
                headerRouteWithCredentials={headerRouteWithCredentials}
                items={items}
                icon={icon}
                iconWeight={iconWeight}
                sidebarItemStyles={sidebarItemStyles}
                toggleSidebarSections={toggleSidebarSections}
                headerLabel={headerLabel}
                isActiveHeader={isActiveHeader}
                isCurrentlyExpanded={isCurrentlyExpanded}
                sidebarHighlightStyles={sidebarHighlightStyles}
                activeDotHighlightStyles={activeDotHighlightStyles}
                currentRole={currentRole}
                isDivider={isDivider}
              />
            )
          })}
        </ul>

        <div className='flex-grow' />

        <ul className='bottom-nav-area'>
          {map(bottomNavBar, (sidebarItem, index) => {
            const {
              label,
              icon,
              key = '',
              route = () => {},
              allowedRoles,
              isHidden = false,
              adminCheck = () => true,
              items = [],
            } = sidebarItem

            const isAllowed = isAllowedCheck({ allowedRoles, adminCheck, currentRole })
            const routeWithCredentials = normalizeRoute({ route, credentialId })
            const isActive = isActiveLinkStatus({ route: routeWithCredentials, pathname, key })
            const currentHeaderItem = document.querySelector(`.nav-bar-content.${key}`)
            const isCurrentlyExpanded = currentHeaderItem ? currentHeaderItem.classList.contains('expanded') : false

            const iconWeight = isActive ? 'fas' : 'fal'

            const bottomNavClassNames = classnames({
              'bottom-nav-item': true,
              active: isActive,
            })

            if (isLoading && !inCredentialsPage) return <Skeleton key={`bottom-nav-skeleton-${index}`} height='26px' />

            if (!isAllowed || isHidden) return null

            return (
              <MainNavItem
                credentialId={credentialId}
                pathname={pathname}
                key={key}
                headerKey={key}
                headerItemClassNames={bottomNavClassNames}
                headerRouteWithCredentials={routeWithCredentials}
                icon={icon}
                iconWeight={iconWeight}
                sidebarItemStyles={sidebarItemStyles}
                headerLabel={label}
                currentRole={currentRole}
                toggleSidebarSections={toggleSidebarSections}
                isActiveHeader={isActive}
                isCurrentlyExpanded={isCurrentlyExpanded}
                sidebarHighlightStyles={sidebarHighlightStyles}
                activeDotHighlightStyles={activeDotHighlightStyles}
                items={items}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

MainNav.propTypes = {
  credentialId: PropTypes.string,
  sidebarData: PropTypes.array,
  bottomNavBar: PropTypes.array,
  applicationLogoImage: PropTypes.string,
  pathname: PropTypes.string,
  currentRole: PropTypes.string,
  inDashboard: PropTypes.bool,
  mainLink: PropTypes.string,
  isFetchingUser: PropTypes.bool,
  sidebarBackgroundColor: PropTypes.string,
  sidebarTextColor: PropTypes.string,
  sidebarHighlightColor: PropTypes.string,
  toggleSidebarSections: PropTypes.func,
  inCredentialsPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  sidebarLogoImageUrl: PropTypes.string,
}

export default MainNav
