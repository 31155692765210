import React, { Component } from 'react'
import { connect } from 'react-redux'
import Payouts from './Payouts'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { PAYOUTS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import concat from 'lodash/concat'

import {
  FAILED,
  SUCCEEDED,
} from 'constants/transferConstants'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  TRANSFER_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transfer'),
  TRANSFER_TYPE,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const payoutPath = PAYOUTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const stateQuery = getUrlQuery('state')
  const isFailedQuickFilter = stateQuery === FAILED
  const isSucceededQuickFilter = stateQuery === SUCCEEDED
  const hiddenFailedFields = isFailedQuickFilter ? ['readyToSettleAt'] : []
  const hiddenSucceededFields = isSucceededQuickFilter ? ['failureCode'] : []
  const conditionalHiddenExportFields = concat(hiddenFailedFields, hiddenSucceededFields)

  return {
    allowedFilters,
    quickFilters,
    initialQueries: mergedQueries,
    initialFilters: createdLastMonthQueries(),
    hiddenExportFields: conditionalHiddenExportFields,
    isFetching,
    payoutPath,
  }
}

class PayoutsC extends Component {
  render() {
    return (
      <Payouts {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PayoutsC)
