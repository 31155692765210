import './ApplicationAccountBalanceS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getBalanceEntriesTransfersPaymentInstrumentsAndIdentities } from 'state-layer/selectors'
import columnDescriptors from 'components/Customer/Pages/AccountBalance/columnDescriptors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TableC from 'components/Shared/Table/TableC'
import { USD } from 'constants/currencyConstants'
import { BALANCE_ENTRIES } from 'constants/linkConstants'

import {
  ACCOUNT_BALANCE,
  BALANCE_ENTRIES_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

import {
  BALANCE_ENTRY,
  GET_BALANCE_ENTRIES,
} from 'constants/flowConstants'

const ApplicationAccountBalance = ({
  isFetching = false,
  balanceId = '',
  flowValues = {},
  actions = [],
  balanceEntryPath = '',
  balanceCurrency = USD,
  displayAvailableAmount = '',
}) => {
  return (
    <div className='ApplicationAccountBalance'>
      <HeaderV2C
        title={<div className='account-balance-header flex'><h5>{`${ACCOUNT_BALANCE}: ${balanceId ? displayAvailableAmount : '$0'} ${balanceCurrency}`}</h5></div>}
      />

      <TableC
        flow={balanceId ? GET_BALANCE_ENTRIES : null}
        flowValues={flowValues}
        selector={balanceId ? getBalanceEntriesTransfersPaymentInstrumentsAndIdentities : () => {}}
        columnDescriptors={columnDescriptors}
        linksKey={BALANCE_ENTRIES}
        isFetching={isFetching}
        actions={actions}
        entityType={BALANCE_ENTRY}
        emptyMessage={BALANCE_ENTRIES_EMPTY_MESSAGE}
        path={balanceEntryPath}
      />
    </div>
  )
}

ApplicationAccountBalance.propTypes = {
  isFetching: PropTypes.bool,
  balanceId: PropTypes.string,
  flowValues: PropTypes.object,
  actions: PropTypes.array,
  balanceEntryPath: PropTypes.string,
  displayAvailableAmount: PropTypes.string,
  balanceCurrency: PropTypes.string,
}

export default ApplicationAccountBalance
