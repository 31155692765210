import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const UnderwritingOwnerPageSection = ({
  ownerDataSectionTitle = '',
  ownerDataSectionAsideTitle = '',
  ownerDataSection = [],
  reports = {},
  isFetching,
}) => {
  return (
    <PageSectionV2C
      smallTitle={ownerDataSectionTitle}
      asideTitle={ownerDataSectionAsideTitle}
      columns={ownerDataSection}
      isFetching={isFetching}
      className='owner-section'
    />
  )
}

UnderwritingOwnerPageSection.propTypes = {
  ownerDataSectionTitle: PropTypes.string,
  ownerDataSectionAsideTitle: PropTypes.string,
  ownerDataSection: PropTypes.array,
  reports: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default UnderwritingOwnerPageSection
