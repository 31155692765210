import { fieldEmpty } from 'utilities/validate'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'
import uniqBy from 'lodash/uniqBy'

const validateTemplatesForm = ({
  templates = [],
}) => {
  if (isEmpty(templates)) {
    return { templates: { _error: ['At least one template must be created.'] } }
  }

  // errors for individual templates
  const templatesErrors = []

  // errors for all templates
  const templatesTotalErrors = []

  templates.forEach((template, index) => {
    const {
      name,
      config,
    } = template

    templatesErrors[index] = {
      name: fieldEmpty(name, 'Name'),
    }

    // template must include a sub-type
    const configIncludesCheck = some(config, ({ includes }) => !isEmpty(pickBy(includes)))

    if (!configIncludesCheck) {
      templatesTotalErrors.push(`${name ? name : `Template #${index + 1}`} must include at least one selection.`)
    }
  })

  if (templates.length !== uniqBy(templates, 'name').length) {
    templatesTotalErrors.push('Each template must have its own unique name.')
  }

  if (!isEmpty(templatesTotalErrors)) {
    set(templatesErrors, '_error', templatesTotalErrors)
  }

  return {
    templates: templatesErrors,
  }
}

export default validateTemplatesForm
