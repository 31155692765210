import './OwnerIdentityVerificationReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import map from 'lodash/map'

import {
  ADDRESS_RECORDS,
  PERSONAL_INFORMATION,
} from 'constants/language/languageConstants'

const OwnerIdentityVerificationReport = ({
  ownerIdentitiesData = [],
}) => {
  return (
    <div className='OwnerIdentityVerificationReport'>
      {map(ownerIdentitiesData, (identityData, index) => {
        const [
          title,
          personalInformationData,
          addressData,
        ] = getMany(identityData, [
          'title',
          'personalInformationData',
          'addressData',
        ])
        return (
          <PageSectionV2C className='owner-identity-section' smallTitle={title} key={`owner-identity-section-${index}`}>
            <h6>{PERSONAL_INFORMATION}</h6>
            <PageSectionV2C className='personal-information-section' columns={personalInformationData} />
            <h6 className='address-records-section-title'>{ADDRESS_RECORDS}</h6>
            <PageSectionV2C className='address-records-section' columns={convertPageSectionDataToV2(addressData)} />
          </PageSectionV2C>
        )
      })}
    </div>
  )
}

OwnerIdentityVerificationReport.propTypes = {
  ownerIdentitiesData: PropTypes.array,
}

export default OwnerIdentityVerificationReport
