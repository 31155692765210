import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditIdentityBankAccountsForm from './EditIdentityBankAccountsForm'
import { getIdentitySelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import mapValues from 'lodash/mapValues'
import slice from 'lodash/slice'

import {
  SETTLEMENT_ALIAS_BE_FE_MAPPING,
  SETTLEMENT_ALIAS_FE_BE_MAPPING,
} from 'constants/paymentInstrumentConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const paymentInstruments = get(identity, 'paymentInstruments')
  const payoutPlanInstrumentMapping = get(identity, 'payoutPlanInstrumentMapping', {})

  const formattedPayoutPlanInstrumentMapping = reduce(payoutPlanInstrumentMapping, (total, { paymentInstrumentId, settlementAlias }) => {
    return merge({}, total, { [get(SETTLEMENT_ALIAS_BE_FE_MAPPING, settlementAlias)]: paymentInstrumentId })
  }, {})

  const paymentInstrumentsOptions = [selectOption('Select Bank Account', '')].concat(map(paymentInstruments, ({ id, name, maskedAccountNumber }) => {
    return selectOption(`${name} - ${maskedAccountNumber} - ${id}`, id)
  }))

  const paymentInstrumentsOptionsObject = mapValues(SETTLEMENT_ALIAS_FE_BE_MAPPING, (value, key) => {
    return get(formattedPayoutPlanInstrumentMapping, key) ? slice(paymentInstrumentsOptions, 1) : paymentInstrumentsOptions
  })

  return removeUndefined({
    credentials,
    paymentInstrumentsOptionsObject,
    initialValues: formattedPayoutPlanInstrumentMapping,
  })
}

class EditIdentityBankAccountsFormC extends Component {
  render() {
    return (
      <EditIdentityBankAccountsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EditIdentityBankAccountsFormC)
