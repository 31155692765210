import includes from 'lodash/includes'
import toString from 'lodash/toString'

import {
  USD,
  currenciesWithCents,
  CURRENCY_NAME_TO_SYMBOL_MAP,
} from 'constants/currencyConstants'

const amountToDisplayAmount = (amount, currency = USD, showCurrencySymbol = false) => {
  // if currency does not support cents, just return integer representation of displayAmount
  if (!includes(currenciesWithCents, currency)) {
    return toString(amount)
  }

  // otherwise, convert cents to dollars and cents
  const dollars = Math.floor(amount / 100)
  const centsLeft = amount % 100
  const centsString = centsLeft < 10 ? `0${centsLeft}` : `${centsLeft}`

  return showCurrencySymbol ? `${CURRENCY_NAME_TO_SYMBOL_MAP[currency]}${dollars}.${centsString}` : `${dollars}.${centsString}`
}

export default amountToDisplayAmount
