import './MerchantIdentityConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import Button from 'components/Shared/Button/Button'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import normalizeObject from 'utilities/api/normalizeObject'
import submitMerchantIdentityForms from 'utilities/submit/submitMerchantIdentityForms'
import submitOnboardingForm from 'utilities/submit/submitOnboardingForm'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import validateMerchantIdentityConfirmationForm from 'utilities/validate/validateMerchantIdentityConfirmationForm'
import { MERCHANT_IDENTITY_CONFIRMATION_FORM } from 'constants/formConstants'
import { PATCH_MERCHANT_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import slice from 'lodash/slice'
import split from 'lodash/split'

import {
  CREATE,
  UPDATE,
  HOSTED_ONBOARDING_CONFIRMATION_MESSAGE, RETURN_TO_EDITING,
} from 'constants/language/languageConstants'

import {
  FINIX_TERMS_OF_SERVICE_URL,
  FINIX_PRIVACY_POLICY_URL,
} from 'constants/urlConstants'

const MerchantIdentityConfirmation = ({
  goToStep = () => {},
  steps = [],
  formValues = {},
  skipStatus,
  leftButton = false,
  leftButtonAction = () => {},
  action,
  handleSubmit = () => {},
  isFlex = false,
  isMerchantOnboarding = false,
  invalid = false,
  termsOfServiceUrl = '',
  applicationName = '',
  feeProfileUrl = '',
  backgroundColor = '',
}) => {
  const editMode = action === PATCH_MERCHANT_IDENTITY_F_REQUEST
  const submitLabel = editMode ? UPDATE : CREATE
  const customButtonStyles = { backgroundColor, border: 'none', color: '#FFF' }
  const inputSteps = slice(steps, 0, -1)
  // Update confirmation details if in merchant boarding flow
  const submitFunction = isMerchantOnboarding ? submitOnboardingForm : submitMerchantIdentityForms

  const merchantOnboardingConfirmationCheckboxString = termsOfServiceUrl ? (
    <div>By continuing, you agree to <a href={termsOfServiceUrl} target='blank'>{applicationName ? `the ${applicationName}` : 'our'} Terms of Service</a>, the <a href={FINIX_TERMS_OF_SERVICE_URL} target='blank'>Finix Terms of Service</a>, the <a className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>Finix Privacy Policy</a>, and the fees available <a href={feeProfileUrl} target='blank'>here.</a></div>
  ) : (
    <div>By continuing, you agree to the <a href={FINIX_TERMS_OF_SERVICE_URL} target='blank'>Finix Terms of Service</a>, the <a className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>Finix Privacy Policy</a>, and the fees available <a href={feeProfileUrl} target='blank'>here.</a></div>
  )

  return (
    <>
      <div className='row'><h5 className='ConfirmationHeader'>Confirmation</h5></div>
      <span className='ConfirmationAndFeesMessage'>{HOSTED_ONBOARDING_CONFIRMATION_MESSAGE}</span>
      <form className='MerchantIdentityConfirmation confirmation' onSubmit={handleSubmit(submitFunction)}>
        { map(inputSteps, (step, idx) => {
          const skipStatusForStep = get(skipStatus, idx, false)
          const { name, dataKey, getDisplayData, displayComponent } = step
          const data = isMerchantOnboarding ? get(step, 'props.confirmationData', {}) : get(formValues, dataKey, {})
          const normalizeData = normalizeObject(data)
          const displayData = getDisplayData ? getDisplayData(normalizeData, isFlex) : map(normalizeData, (value, label) => ({ value, label }))
          const sectionData = !skipStatusForStep ? displayData : []
          const Component = displayComponent || PageSectionV2

          const actions = [
            {
              label: 'Edit',
              action: () => { goToStep(idx, null, true) },
              customButtonStyles,
            },
          ]

          return (
            <Component
              actions={actions}
              title={name}
              data={sectionData}
              columns={convertPageSectionDataToV2(sectionData)}
              key={split(name, ' ').join('-')}
            />
          )
        })}
        {!isMerchantOnboarding && (
          <div className='PageSectionV2'>
            <header className='headerBorder'>
              <h2>Confirmation</h2>
            </header>

            <p>Please confirm whether the information above is correct.</p>

            <div className='buttons flex space-between'>
              <div className='left flex flex-start'>
                { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={RETURN_TO_EDITING} leftIcon='arrow-left' /> }
              </div>

              <div className='right flex flex-end'>
                <Button type='submit' label={submitLabel} rightIcon='paper-plane' variant='legacy-black' />
              </div>
            </div>
          </div>
        )}
        {isMerchantOnboarding && (
          <div className='PageSectionV2'>
            <header className='headerBorder'>
              <h4>Terms and Conditions</h4>
            </header>

            <div className='termsOfServiceCheckbox'>
              <Field
                name='merchantAgreementAccepted'
                checkboxLabel={merchantOnboardingConfirmationCheckboxString}
                component={CheckboxInput}
              />
            </div>

            <div className='buttons flex space-between'>
              <div className='left flex flex-start'>
                { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={RETURN_TO_EDITING} leftIcon='arrow-left' /> }
              </div>

              <div className='right flex flex-end'>
                <Button type='submit' label={submitLabel} disabled={invalid} variant='legacy-black' rightIcon='paper-plane' />
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  )
}

MerchantIdentityConfirmation.propTypes = {
  goToStep: PropTypes.func,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  leftButton: PropTypes.bool,
  leftButtonAction: PropTypes.func,
  action: PropTypes.string,
  isFlex: PropTypes.bool,
  isMerchantOnboarding: PropTypes.bool,
  invalid: PropTypes.bool,
  termsOfServiceUrl: PropTypes.string,
  applicationName: PropTypes.string,
  feeProfileUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default reduxForm({
  form: MERCHANT_IDENTITY_CONFIRMATION_FORM,
  validate: validateMerchantIdentityConfirmationForm,
})(MerchantIdentityConfirmation)
