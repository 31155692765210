import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import AddDeviceForm from './AddDeviceForm'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import isPatching from 'utilities/is/isPatching'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import showModalAction from 'utilities/actions/showModalAction'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { ADD_NEW_DEVICE_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import assign from 'lodash/assign'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import head from 'lodash/head'

import {
  getMerchantsByApplicationIdSelector,
  getMerchantSelector,
} from 'state-layer/selectors'

import {
  FEATURE_NOT_AVAILABLE_MODAL,
  SELECT_ADD_DEVICE_MERCHANT_MODAL,
} from 'constants/modalConstants'

import {
  ADDING_DEVICES_NOT_AVAILABLE,
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_ADD_DEVICE,
  NEVER,
  SELECT_A_MERCHANT_TITLE,
  SELECT_AN_APPLICATION_AND_MERCHANT_TITLE,
  SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR,
  SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR,
} from 'constants/language/languageConstants'

import {
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
  TRIPOS_CLOUD_V1,
  TRIPOS_MOBILE_V1,
} from 'constants/processorConstants'

import {
  AMOUNT_VALUE,
  DEVICE_CONFIGURATIONS_SELECT_OPTIONS,
  GATEWAY_PROCESSOR_TO_DEVICE_MODELS_MAP,
  generateDeviceModelOptions,
  NEVER_VALUE,
  PAX_D135,
  PROMPT_FOR_SIGNATURE_VALUE_TO_LABEL_MAP,
  PROMPT_SIGNATURE_VALUE_TO_LABEL_MAP,
} from 'constants/deviceConstants'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
  option: {},
}

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const currentUser = getCurrentUser(state)
  const applicationId = getUrlQuery('applicationId') || get(currentUser, 'applicationId')
  const isApplicationView = !!getUrlQuery('applicationId')
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const formSelector = formValueSelector(ADD_NEW_DEVICE_FORM)
  const existingFormValues = get(state, 'flowsR.formValues.addDevice')
  const modelValue = formSelector(state, 'model.value')
  const promptSignatureValue = formSelector(state, 'promptSignature.value')
  const promptForSignatureValue = formSelector(state, 'promptForSignature.value')
  const isFetchingMerchantData = get(state, `merchantsR.${FETCHING}`, true)
  const promptTipOnScreenValue = formSelector(state, 'promptTipOnScreen')
  const showReceiptSettingsError = get(state, `form.${ADD_NEW_DEVICE_FORM}.syncErrors.receiptSettingsError`)
  const promptReceiptConfirmationValue = formSelector(state, 'promptReceiptConfirmation')
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const createDeviceEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const selectAddDeviceMerchantModalTitle = (isRolePlatformCredential && !applicationId) ? SELECT_AN_APPLICATION_AND_MERCHANT_TITLE : SELECT_A_MERCHANT_TITLE
  const selectAddDeviceMerchantModalSubtitle = (isRolePlatformCredential && !applicationId) ? SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR : SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR

  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantGateway = get(merchant, 'gateway', get(merchant, 'processor'))
  const deviceModelSelectOptions = generateDeviceModelOptions({ allowedModels: GATEWAY_PROCESSOR_TO_DEVICE_MODELS_MAP[merchantGateway] })
  const configSelectOptions = DEVICE_CONFIGURATIONS_SELECT_OPTIONS
  const deviceConfigurationSelectNames = map(configSelectOptions, ({ name }) => name)
  const showDeviceConfigurations = includes([TRIPOS_CLOUD_V1, DATACAP_V1, DUMMY_V1, FINIX_V1], merchantGateway) && modelValue !== PAX_D135
  const disablePromptSignatureOption = includes([TRIPOS_MOBILE_V1, FINIX_V1, DATACAP_V1, DUMMY_V1], merchantGateway)
  const hideSerialNumber = isEqual(merchantGateway, TRIPOS_CLOUD_V1)
  const showReceiptAndTipConfigOptions = includes([DUMMY_V1, FINIX_V1], merchantGateway)
  const showPromptForSignatureField = includes([DUMMY_V1, FINIX_V1], merchantGateway)
  const showPromptSignatureField = isEqual(merchantGateway, TRIPOS_CLOUD_V1)

  const [
    currentDeviceNameValue,
    currentDescriptionValue,
    currentSerialNumberValue,
    currentModel,
    currentCollectSignatureValue,
    currentSignatureThresholdAmountValue,
    currentPercentageOne = 10,
    currentPercentageTwo = 15,
    currentPercentageThree = 20,
    currentPercentTippingThreshold,
    currentAmountOne,
    currentAmountTwo,
    currentAmountThree,
    currentPromptTipOnScreenValue = false,
    currentPromptReceiptConfirmationValue = false,
    currentReceiptDeliveryPrint = true,
    currentReceiptDeliveryEmail = true,
    currentReceiptDeliverySms = true,
    currentAutomaticDeliveryPrint = true,
    currentAutomaticDeliveryEmail = true,
    currentAutomaticDeliverySms = true,
    currentAllowDebitValue = true,
    currentBypassDeviceOnCaptureValue = true,
    currentCheckForDuplicateTransactionsValue = true,
    currentPromptAmountConfirmationValue = true,
    currentPromptManualEntryOfCardInformationValue = false,
    currentAllowStandaloneAuthorizationsValue = false,
    currentAllowStandaloneRefundsValue = false,
    currentAllowStandaloneSalesValue = false,
    currentDisplayTipOnReceiptValue = false,
    currentPromptForSignatureValue,
  ] = getMany(existingFormValues, [
    'deviceName',
    'description',
    'serialNumber',
    'model',
    'promptSignature.value',
    'signatureThresholdAmount',
    'percentageOne',
    'percentageTwo',
    'percentageThree',
    'percentTippingThreshold',
    'amountOne',
    'amountTwo',
    'amountThree',
    'promptTipOnScreen',
    'promptReceiptConfirmation',
    'receiptDeliveryPrint',
    'receiptDeliveryEmail',
    'receiptDeliverySms',
    'automaticDeliveryPrint',
    'automaticDeliveryEmail',
    'automaticDeliverySms',
    'allowDebit',
    'bypassDeviceOnCapture',
    'checkForDuplicateTransactions',
    'promptAmountConfirmation',
    'promptManualEntryOfCardInformation',
    'allowStandaloneAuthorizations',
    'allowStandaloneRefunds',
    'allowStandaloneSales',
    'displayTipOnReceipt',
    'promptForSignature.value',
  ])

  const currentModelSelectedValue = get(currentModel, 'value')

  const configurationToggleValues = map(deviceConfigurationSelectNames, (configuration) => {
    const configurationValue = get(existingFormValues, configuration, false)

    return { [configuration]: configurationValue }
  })

  const configurationToggleValuesObject = assign({}, ...configurationToggleValues)
  const promptSignatureInitialValue = currentCollectSignatureValue ? { label: PROMPT_SIGNATURE_VALUE_TO_LABEL_MAP[currentCollectSignatureValue], value: currentCollectSignatureValue } : { label: NEVER, value: NEVER_VALUE }
  const promptForSignatureInitialValue = currentPromptForSignatureValue ? { label: PROMPT_FOR_SIGNATURE_VALUE_TO_LABEL_MAP[currentPromptForSignatureValue], value: currentPromptForSignatureValue } : { label: NEVER, value: NEVER_VALUE }

  const initialValues = {
    deviceName: currentDeviceNameValue,
    description: currentDescriptionValue,
    serialNumber: currentSerialNumberValue,
    model: currentModel ? { label: snakeCaseToTitleCase({ key: currentModelSelectedValue }), value: currentModelSelectedValue } : undefined,
    promptSignature: promptSignatureInitialValue,
    signatureThresholdAmount: currentSignatureThresholdAmountValue,
    ...configurationToggleValuesObject,
    promptTipOnScreen: currentPromptTipOnScreenValue,
    promptReceiptConfirmation: currentPromptReceiptConfirmationValue,
    percentageOne: currentPercentageOne,
    percentageTwo: currentPercentageTwo,
    percentageThree: currentPercentageThree,
    percentTippingThreshold: currentPercentTippingThreshold,
    amountOne: currentAmountOne,
    amountTwo: currentAmountTwo,
    amountThree: currentAmountThree,
    receiptDeliveryPrint: currentReceiptDeliveryPrint,
    receiptDeliveryEmail: currentReceiptDeliveryEmail,
    receiptDeliverySms: currentReceiptDeliverySms,
    automaticDeliveryPrint: currentAutomaticDeliveryPrint,
    automaticDeliveryEmail: currentAutomaticDeliveryEmail,
    automaticDeliverySms: currentAutomaticDeliverySms,
    allowDebit: currentAllowDebitValue,
    bypassDeviceOnCapture: currentBypassDeviceOnCaptureValue,
    checkForDuplicateTransactions: currentCheckForDuplicateTransactionsValue,
    promptAmountConfirmation: currentPromptAmountConfirmationValue,
    promptManualEntryOfCardInformation: currentPromptManualEntryOfCardInformationValue,
    allowStandaloneAuthorizations: currentAllowStandaloneAuthorizationsValue,
    allowStandaloneRefunds: currentAllowStandaloneRefundsValue,
    allowStandaloneSales: currentAllowStandaloneSalesValue,
    displayTipOnReceipt: currentDisplayTipOnReceiptValue,
    promptForSignature: promptForSignatureInitialValue,
  }

  return removeUndefined({
    credentials,
    isStandaloneMerchant,
    isRolePlatformCredential,
    environment,
    initialValues,
    promptSignatureValue,
    promptForSignatureValue,
    modelValue,
    merchantId,
    deviceModelSelectOptions,
    deviceConfigurationSelectOptions: sortBy(configSelectOptions, 'name'),
    isFetchingMerchantData,
    showDeviceConfigurations,
    disablePromptSignatureOption,
    hideSerialNumber,
    merchantGateway,
    isUpdating: isPatching(state),
    showTippingSettings: promptTipOnScreenValue,
    showPromptReceiptConfirmationSettings: promptReceiptConfirmationValue,
    showReceiptAndTipConfigOptions,
    currentReceiptDeliveryPrint,
    currentReceiptDeliveryEmail,
    currentReceiptDeliverySms,
    currentAutomaticDeliveryPrint,
    currentAutomaticDeliveryEmail,
    currentAutomaticDeliverySms,
    showReceiptSettingsError,
    showPromptForSignatureField,
    showPromptSignatureField,
    applicationId,
    selectAddDeviceMerchantModalTitle,
    selectAddDeviceMerchantModalSubtitle,
    createDeviceEnabledApplicationMerchants,
    applicationMerchants,
    isApplicationView,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    showFeatureNotAvailableModal: () => dispatch(showModalAction({
      modalType: FEATURE_NOT_AVAILABLE_MODAL,
      modalProps: {
        canCloseModal: false,
        title: ADDING_DEVICES_NOT_AVAILABLE,
        className: 'AddDeviceNotAvailableModal',
        description: MERCHANT_ACCOUNT_IS_REQUIRED_TO_ADD_DEVICE,
      },
      className: 'modal-md no-pad',
    })),
    showSelectAddDeviceMerchantModal: (modalProps) => dispatch(showModalAction({
      modalType: SELECT_ADD_DEVICE_MERCHANT_MODAL,
      className: 'modal-md no-pad overflow-visible',
      modalProps,
    })),
    updateAddDeviceFormField: ({ value, field }) => dispatch(change(ADD_NEW_DEVICE_FORM, field, value)),
  }
}

class AddDeviceFormC extends Component {
  componentDidMount() {
    const {
      merchantId,
      credentials,
      getApplicationMerchants,
      applicationId,
      isRolePlatformCredential,
      showSelectAddDeviceMerchantModal,
      isApplicationView,
    } = this.props

    if (!merchantId) {
      if (applicationId) {
        getApplicationMerchants({ applicationId, credentials })
      }

      if (isRolePlatformCredential && !isApplicationView) {
        showSelectAddDeviceMerchantModal({
          title: SELECT_AN_APPLICATION_AND_MERCHANT_TITLE,
          subtitle: SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR,
          canCloseModal: false,
          applicationId,
          isApplicationView,
        })
      }
    }

    if (merchantId) {
      getMerchantRequest({ merchantId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      promptSignatureValue,
      merchantId,
      isFetchingMerchantData,
      applicationMerchants,
      createDeviceEnabledApplicationMerchants,
      showFeatureNotAvailableModal,
      showSelectAddDeviceMerchantModal,
      selectAddDeviceMerchantModalTitle,
      selectAddDeviceMerchantModalSubtitle,
      getApplicationMerchants,
      applicationId,
      credentials,
      isApplicationView,
      isRolePlatformCredential,
      updateAddDeviceFormField,
    } = this.props

    const {
      promptSignatureValue: prevPromptSignatureValue,
      isFetchingMerchantData: prevIsFetchingMerchantData,
      applicationId: prevApplicationId,
      isRolePlatformCredential: prevIsRolePlatformCredential,
    } = prevProps

    const filteredEnabledMerchantsSize = size(createDeviceEnabledApplicationMerchants)

    if (isRolePlatformCredential && isRolePlatformCredential !== prevIsRolePlatformCredential && !isApplicationView) {
      showSelectAddDeviceMerchantModal({
        title: selectAddDeviceMerchantModalTitle,
        subtitle: selectAddDeviceMerchantModalSubtitle,
        canCloseModal: false,
      })
    }

    if (!merchantId) {
      if (prevIsFetchingMerchantData === true && isFetchingMerchantData === false && !isEmpty(applicationMerchants)) {
        if (filteredEnabledMerchantsSize === 0) {
          showFeatureNotAvailableModal()
        }

        if (filteredEnabledMerchantsSize === 1) {
          const { id } = head(createDeviceEnabledApplicationMerchants)
          updateUrlQueries({ merchantId: id })
        }

        if (filteredEnabledMerchantsSize > 1) {
          showSelectAddDeviceMerchantModal({
            title: selectAddDeviceMerchantModalTitle,
            subtitle: selectAddDeviceMerchantModalSubtitle,
            canCloseModal: false,
            applicationId,
            isApplicationView,
          })
        }
      }
    }

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }

    // clear signature threshold amount form value if user change collect signature to always/never
    if (!isEqual(promptSignatureValue, prevPromptSignatureValue) && !isEqual(promptSignatureValue, AMOUNT_VALUE)) {
      updateAddDeviceFormField({ field: 'signatureThresholdAmount', value: undefined })
    }
  }

  render() {
    return (
      <AddDeviceForm
        {...this.props}
        reactSelectCustomStyles={reactSelectCustomStyles}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceFormC)
