import './ApplicationDisputesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { APPLICATION_DISPUTES } from 'constants/linkConstants'
import { GET_APPLICATION_DISPUTES } from 'constants/flowConstants'
import { EXCEPTIONS_ICON } from 'constants/iconConstants'
import { getApplicationDisputes } from 'state-layer/selectors'

const ApplicationDisputes = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  disputePath = '',
  isFetching = false,
  applicationId = '',
}) => {
  return (
    <div className='ApplicationDisputes'>
      <TableC
        flow={GET_APPLICATION_DISPUTES}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationDisputes}
        selectorId={applicationId}
        initialQueries={initialQueries}
        linksKey={APPLICATION_DISPUTES}
        allowedFilters={allowedFilters}
        path={disputePath}
        isFetching={isFetching}
        icon={`fa fa-${EXCEPTIONS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Disputes`}
      />
    </div>
  )
}

ApplicationDisputes.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  disputePath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
}

export default ApplicationDisputes
