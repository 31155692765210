import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import appendSuffix from 'utilities/appendSuffix'
import removeUndefined from 'utilities/remove/removeUndefined'
import getAMPM from 'utilities/get/getAMPM'
import convertToAMPMTime from 'utilities/convert/convertToAMPMTime'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  filterByGlobalType,
  filterOutGlobalType,
} from 'constants/disbursementsConstants'

import {
  ENABLED,
  DISABLED,
} from 'constants/language/languageConstants'

const individualRulesModel = (individualRules) => {
  const [
    currency,
    timeFrame,
    startTime,
    timeZone,
    rules,
    startDay,
    startDate,
    type,
    maxTransactionAmount,
  ] = getMany(individualRules, [
    'currency',
    'time_frame',
    'start_time',
    'time_zone',
    'rules',
    'start_day',
    'start_date',
    'type',
    'max_transaction_amount',
  ])

  const normalizedRules = map(rules, (rule) => {
    const [
      countLimit,
      volumeLimit,
      day,
      enabled,
    ] = getMany(rule, [
      'count_limit',
      'volume_limit',
      'day',
      'enabled',
    ])

    return {
      countLimit,
      displayCountLimit: countLimit,
      volumeLimit,
      displayVolumeLimit: formatMoney({ amount: volumeLimit }),
      day,
      enabled,
      enabledStatus: enabled ? ENABLED : DISABLED,
    }
  })

  return removeUndefined({
    currency,
    timeFrame,
    displayTimeFrame: snakeCaseToTitleCase({ key: timeFrame }),
    startTime,
    startTimeMeridian: getAMPM({ militaryTime: startTime }),
    displayStartTime: convertToAMPMTime({ hour: startTime }),
    startDay,
    displayStartDay: startDay ? snakeCaseToTitleCase({ key: startDay }) : undefined,
    startDate,
    displayStartDate: appendSuffix({ num: startDate }),
    timeZone,
    displayTimeZone: snakeCaseToTitleCase({ key: timeZone, noFinalCapitalize: true }),
    rules: normalizedRules,
    type,
    maxTransactionAmount,
    displayMaxTransactionAmount: formatMoney({ amount: maxTransactionAmount }),
  })
}

const frontendDisbursementRulesModel = ({ data: disbursementRules }) => {
  const [
    id,
    createdAt,
    updatedAt,
    linkedType,
    linkedTo,
    tags,
    applicationDetails,
    recipientDetails,
  ] = getMany(get(disbursementRules, '[0]'), [
    'id',
    'created_at',
    'updated_at',
    'linked_type',
    'linked_to',
    'tags',
    'application_details',
    'recipient_details',
  ])

  const applicationACHRules = get(applicationDetails, 'ach_rules')
  const normalizedApplicationACHRules = map(applicationACHRules, (rules) => individualRulesModel(rules))
  const applicationCardRules = get(applicationDetails, 'card_rules')
  const normalizedApplicationCardRules = map(applicationCardRules, (rules) => individualRulesModel(rules))
  const applicationBalanceAdjustmentRules = get(applicationDetails, 'balance_adjustment_rules')
  const normalizedApplicationBalanceAdjustmentRules = map(applicationBalanceAdjustmentRules, (rules) => individualRulesModel(rules))

  const recipientACHRules = get(recipientDetails, 'ach_rules')
  const normalizedRecipientACHRules = map(recipientACHRules, (rules) => individualRulesModel(rules))
  const recipientCardRules = get(recipientDetails, 'card_rules')
  const normalizedRecipientCardRules = map(recipientCardRules, (rules) => individualRulesModel(rules))
  const applicationACHRulesMaxTransactionAmount = get(filterByGlobalType(normalizedApplicationACHRules), '[0].displayMaxTransactionAmount')
  const applicationCardRulesMaxTransactionAmount = get(filterByGlobalType(normalizedApplicationCardRules), '[0].displayMaxTransactionAmount')
  const applicationBalanceAdjustmentsRulesMaxTransactionAmount = get(filterByGlobalType(normalizedApplicationBalanceAdjustmentRules), '[0].displayMaxTransactionAmount')
  const recipientACHRulesMaxTransactionAmount = get(filterByGlobalType(normalizedRecipientACHRules), '[0].displayMaxTransactionAmount')
  const recipientCardRulesMaxTransactionAmount = get(filterByGlobalType(normalizedRecipientCardRules), '[0].displayMaxTransactionAmount')

  return {
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ data: updatedAt }),
    linkedType,
    linkedTo,
    tags,
    applicationDetails,
    recipientDetails,
    applicationACHRules: filterOutGlobalType(normalizedApplicationACHRules),
    applicationCardRules: filterOutGlobalType(normalizedApplicationCardRules),
    applicationBalanceAdjustmentRules: filterOutGlobalType(normalizedApplicationBalanceAdjustmentRules),
    recipientACHRules: filterOutGlobalType(normalizedRecipientACHRules),
    recipientCardRules: filterOutGlobalType(normalizedRecipientCardRules),
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    applicationBalanceAdjustmentsRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  }
}

export default frontendDisbursementRulesModel
