import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import Button from 'components/Shared/Button/Button'
import submitDashboardFeedbackForm from 'utilities/submit/submitDashboardFeedbackForm'
import validateDashboardFeedbackForm from 'utilities/validate/validateDashboardFeedbackForm'
import { DASHBOARD_FEEDBACK_FORM } from 'constants/formConstants'

import {
  GIVE_FEEDBACK_MESSAGE,
  SEND_FEEDBACK,
} from 'constants/language/languageConstants'

const DashboardFeedbackForm = ({
  handleSubmit = () => {},
  invalid = false,
}) => {
  return (
    <form className='DashboardFeedbackForm' onSubmit={handleSubmit(submitDashboardFeedbackForm)}>
      <div>{GIVE_FEEDBACK_MESSAGE}</div>

      <Field
        name='feedback'
        label='Feedback'
        component={TextAreaField}
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' label={SEND_FEEDBACK} disabled={invalid} />
      </div>
    </form>
  )
}

DashboardFeedbackForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: DASHBOARD_FEEDBACK_FORM,
  validate: validateDashboardFeedbackForm,
})(DashboardFeedbackForm)
