import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import formatPaymentInstrumentDataSection from 'utilities/formatters/formatPaymentInstrumentDataSection'
import { PAYMENT_INSTRUMENT_PATH } from 'constants/pathConstants'
import isEmpty from 'lodash/isEmpty'

import {
  FINGERPRINT_ID,
  VIEW_PAYMENT_INSTRUMENT,
  PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

const PaymentInstrumentDetails = ({
  title = PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  isFetching = false,
  showAside = true,
  dataSectionFormatter = formatPaymentInstrumentDataSection,
  credentialId = '',
  paymentInstrument = {},
  device = {},
  deviceId = '',
  isRoleMerchantCred = false,
  isEnterpriseSiftFraudScoreTier = false,
  showResponseModal,
  isPreviewPanel = false,
}) => {
  const {
    id,
    fingerprint,
  } = paymentInstrument

  const noDevice = isEmpty(device)

  const paymentInstrumentClickToCopies = [
    {
      content: id,
    },
    {
      label: FINGERPRINT_ID,
      content: fingerprint,
    },
  ]

  const paymentInstrumentLink = !isRoleMerchantCred && <Link className='payment-instrument-link text-link' to={PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId: id })}>{VIEW_PAYMENT_INSTRUMENT}</Link>
  const paymentInstrumentDataSection = dataSectionFormatter({
    paymentInstrument,
    noDevice,
    deviceId,
    credentialId,
    isRoleMerchantCred,
    isEnterpriseSiftFraudScoreTier,
    isPreviewPanel,
  })

  return (
    <PageSectionV2C
      title={isPreviewPanel ? null : title}
      smallTitle={isPreviewPanel ? title : null}
      isFetching={isFetching}
      clickToCopies={showAside ? paymentInstrumentClickToCopies : []}
      columns={paymentInstrumentDataSection}
      className='payment-instrument-details'
      asideTitle={showAside ? paymentInstrumentLink : (
        <div className='view-api-response-link' onClick={showResponseModal}>
          {VIEW_API_RESPONSE}
        </div>
      )}
      isPreviewPanel
    />
  )
}

PaymentInstrumentDetails.propTypes = {
  title: PropTypes.string,
  isFetching: PropTypes.bool,
  dataSectionFormatter: PropTypes.func,
  credentialId: PropTypes.string,
  paymentInstrument: PropTypes.object,
  device: PropTypes.object,
  showAside: PropTypes.bool,
  isRoleMerchantCred: PropTypes.bool,
  isEnterpriseSiftFraudScoreTier: PropTypes.bool,
  deviceId: PropTypes.string,
  showResponseModal: PropTypes.func,
  isPreviewPanel: PropTypes.bool,
}

export default PaymentInstrumentDetails
