import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  FEE,
  FEES,
} from 'constants/apiConstants'

const patchFeeAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.put({
    values,
    credentials,
    path: FEE({ feeId: id }),
    meta,
    service: FEES,
  })
}

export default patchFeeAPI
