import './UpdateTransfersS.scss'
import React from 'react'
import UpdateAdminToolsFormC from 'components/Customer/Forms/UpdateAdminToolsForm/UpdateAdminToolsFormC'
import selectOption from 'utilities/forms/selectOption'
import { UPDATE_TRANSFERS_FORM } from 'constants/formConstants'
import { PATCH_TRANSFERS_UPDATE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import head from 'lodash/head'

import {
  FAILED,
  PENDING,
} from 'constants/transferConstants'

import {
  SUBMITTED,
  SUBMITTING,
  STAGED,
} from 'constants/stateConstants'

const transferStateOptions = [
  selectOption('Submitted', SUBMITTED),
  selectOption('Submitting', SUBMITTING),
  selectOption('Staged', STAGED),
]

const transferCodeOptions = [
  selectOption('Failed', FAILED),
  selectOption('Pending', PENDING),
]

const taskTypeOptions = [
  selectOption('Select Type', ''),
  selectOption('Capture Authorization', 'CAPTURE_AUTHORIZATION'),
  selectOption('Transfer', 'TRANSFER'),
]

const initialValues = {
  state: get(head(transferStateOptions), 'value'),
  code: get(head(transferCodeOptions), 'value'),
}

const UpdateTransfers = () => {
  return (
    <div className='UpdateTransfers'>
      <div className='content-header flex space-between'>
        <h1>Update Transfer(s)</h1>
      </div>

      <div className='content-card'>
        <UpdateAdminToolsFormC
          title='Transfer'
          adminOperationKey='TRANSFER_UPDATE'
          actionType={PATCH_TRANSFERS_UPDATE_F_REQUEST}
          form={UPDATE_TRANSFERS_FORM}
          initialValues={initialValues}
          stateOptions={transferStateOptions}
          codeOptions={transferCodeOptions}
          taskTypeOptions={taskTypeOptions}
        />
      </div>
    </div>
  )
}

export default UpdateTransfers
