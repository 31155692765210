import React, { Component } from 'react'
import { connect } from 'react-redux'
import OnboardingForm from './OnboardingForm'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import Address from 'components/Customer/Shared/Display/Address/Address'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import { getOnboardingFormWithDataAndApplication } from 'state-layer/selectors'
import getOnboardingFormDataRequest from 'utilities/actions/get/getOnboardingFormDataRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import postOnboardingFormLinkRequest from 'utilities/actions/post/postOnboardingFormLinkRequest'
import getMany from 'utilities/get/getMany'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { onboardingFormStatusMap } from 'constants/statusConstants'
import { APPLICATION_PATH, IDENTITY_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { CAN } from 'constants/countryConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'

import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  AMERICAN_EXPRESS_MID,
  ANNUAL_CARD_VOLUME,
  APPLICATION,
  AVERAGE_CARD_TRANSACTION_AMOUNT,
  BANK_DOCUMENT_PROVIDED,
  BUSINESS_ADDRESS,
  BUSINESS_PHONE,
  BUSINESS_TAX_ID,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE,
  COUNTRY,
  CREATED_AT_LABEL,
  CREATED_ON,
  DATE_OF_BIRTH,
  DATE_OF_INCORPORATION,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DOING_BUSINESS_AS,
  EMAIL_ADDRESS,
  EXPIRATION_TIME_DAYS,
  EXPIRATION_URL,
  FEE_URL,
  GENERATE_NEW_URL_LINK,
  IN_PERSON_PAYMENTS,
  INSTITUTION_NUMBER,
  LEGAL_BUSINESS_NAME,
  MAIL_TELEPHONE_ORDER,
  MAX_CARD_TRANSACTION_AMOUNT,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_IDENTITY,
  ONBOARDING_FORM_RESOURCE_TITLE,
  ONLINE_PAYMENTS,
  OTHER,
  OWNERSHIP_TYPE,
  PAYMENT_PROCESSORS,
  PHONE_NUMBER,
  PROCESSED_CARDS_BEFORE,
  REFUND_POLICY,
  RETURN_URL,
  ROUTING_NUMBER,
  STATUS,
  TAX_AUTHORITY,
  TERMS_OF_SERVICE_URL,
  TRANSIT_NUMBER,
  UPDATED_ON,
  WEBSITE,
  YES,
  NO,
  TAX_ID,
  BANK_ACCOUNT,
  SUCCESSFULLY_LINKED,
  CONNECTION_TYPE,
  PLAID,
} from 'constants/language/languageConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
  averageACHTransferAmountLabelMap,
} from 'constants/labelConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `onboardingFormDataR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const onboardingFormId = get(props, 'params.onboardingFormId')
  const onboardingForm = getOnboardingFormWithDataAndApplication(state, onboardingFormId)

  const [
    displayCreatedAt,
    displayUpdatedAt,
    status,
    identityId,
    businessData,
    controlPerson,
    beneficialOwners,
    processingData,
    bankAccountData,
    onboardingLinkDetails,
    processorGatewayDetails,
    applicationId,
    applicationName,
    country,
  ] = getMany(onboardingForm, [
    'displayCreatedAt',
    'displayUpdatedAt',
    'status',
    'identityId',
    'businessData',
    'controlPerson',
    'beneficialOwners',
    'processingData',
    'bankAccountData',
    'onboardingLinkDetails',
    'processorGatewayDetails',
    'applicationId',
    'application.businessName',
    'country',
  ])

  const [
    businessName,
    doingBusinessAs,
    website,
    ownershipType,
    displayBusinessPhone,
    businessAddress,
    businessType,
    displayBusinessType,
    displayIncorporationDate,
    businessTaxId,
    displayBusinessName,
    businessDescription,
  ] = getMany(businessData, [
    'businessName',
    'doingBusinessAs',
    'website',
    'ownershipType',
    'displayBusinessPhone',
    'businessAddress',
    'businessType',
    'displayBusinessType',
    'displayIncorporationDate',
    'businessTaxId',
    'displayBusinessName',
    'businessDescription',
  ])

  const [
    title,
    firstName,
    lastName,
    displayDateOfBirth,
    displayPhone,
    email,
    personalAddress,
    principalPercentageOwnership,
    taxId,
  ] = getMany(controlPerson, [
    'title',
    'firstName',
    'lastName',
    'displayDateOfBirth',
    'displayPhone',
    'email',
    'personalAddress',
    'displayPrincipalPercentageOwnership',
    'taxId',
  ])

  const [
    displayRefundPolicy,
    displayAverageACHTransferAmount,
    displayAverageCardTransferAmount,
    displayAnnualACHVolume,
    displayBusinessToBusinessVolumePercentage,
    displayBusinessToConsumerVolumePercentage,
    displayOtherVolumePercentage,
    displayEcommercePercentage,
    displayCardPresentPercentage,
    displayMailOrderTelephoneOrderPercentage,
    mcc,
    taxAuthority,
    displayPreviouslyProcessedCreditCards,
    amexMid,
    discoverMid,
    displayMaxTransactionAmount,
    displayAchMaxTransactionAmount,
    displayAnnualCardVolume,
    defaultStatementDescriptor,
  ] = getMany(processingData, [
    'displayRefundPolicy',
    'displayAverageACHTransferAmount',
    'displayAverageCardTransferAmount',
    'displayAnnualACHVolume',
    'displayBusinessToBusinessVolumePercentage',
    'displayBusinessToConsumerVolumePercentage',
    'displayOtherVolumePercentage',
    'displayEcommercePercentage',
    'displayCardPresentPercentage',
    'displayMailOrderTelephoneOrderPercentage',
    'mcc',
    'taxAuthority',
    'displayPreviouslyProcessedCreditCards',
    'amexMid',
    'discoverMid',
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
    'displayAnnualCardVolume',
    'defaultStatementDescriptor',
  ])

  const [
    name,
    bankCode,
    accountNumber,
    displayAccountType,
    transitNumber,
    institutionNumber,
    bankDocumentId,
    plaidProcessorToken,
  ] = getMany(bankAccountData, [
    'name',
    'bankCode',
    'accountNumber',
    'displayAccountType',
    'transitNumber',
    'institutionNumber',
    'bankDocument.id',
    'plaidProcessorToken',
  ])

  const [
    returnUrl,
    expiredSessionUrl,
    feeDetailsUrl,
    termsOfServiceUrl,
    expirationInMinutes,
    expirationInDays,
  ] = getMany(onboardingLinkDetails, [
    'returnUrl',
    'expiredSessionUrl',
    'feeDetailsUrl',
    'termsOfServiceUrl',
    'expirationInMinutes',
    'expirationInDays',
  ])

  const hasUrlDetails = !!(returnUrl && expiredSessionUrl && feeDetailsUrl && termsOfServiceUrl && expirationInMinutes)

  const [
    processors,
  ] = getMany(processorGatewayDetails, [
    'processors',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !!applicationId,
      },
      {
        label: MERCHANT_IDENTITY,
        value: displayBusinessName,
        path: IDENTITY_PATH({ credentialId, identityId }),
        condition: !!identityId,
      },
    ],
  }

  const headerData = {
    resource: {
      label: ONBOARDING_FORM_RESOURCE_TITLE,
      id: onboardingFormId,
    },
    items: [
      {
        value: <h1>{businessName}</h1>,
        condition: !!businessName,
      },
      {
        value: <h1>Created {displayCreatedAt}</h1>,
        condition: !businessName,
      },
      {
        label: STATUS,
        value: <ColorcodedStatus data={onboardingFormStatusMap} value={status} />,
      },
      {
        label: CREATED_AT_LABEL,
        value: displayCreatedAt,
        condition: !!businessName,
      },
      {
        label: COUNTRY,
        value: CountryFlagIcon({ country }) || '-',
      },
    ],
  }

  const formDetailsDataSection = [
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
    {
      label: EXPIRATION_TIME_DAYS,
      value: expirationInDays,
    },
    {
      label: FEE_URL,
      value: validateWebsiteLink({ website: feeDetailsUrl }) ? <a className='text-link' href={feeDetailsUrl} target='blank'>{feeDetailsUrl}</a> : feeDetailsUrl,
    },
    {
      label: TERMS_OF_SERVICE_URL,
      value: validateWebsiteLink({ website: termsOfServiceUrl }) ? <a className='text-link' href={termsOfServiceUrl} target='blank'>{termsOfServiceUrl}</a> : termsOfServiceUrl,
    },
    {
      label: RETURN_URL,
      value: validateWebsiteLink({ website: returnUrl }) ? <a className='text-link' href={returnUrl} target='blank'>{returnUrl}</a> : returnUrl,
    },
    {
      label: EXPIRATION_URL,
      value: validateWebsiteLink({ website: expiredSessionUrl }) ? <a className='text-link' href={expiredSessionUrl} target='blank'>{expiredSessionUrl}</a> : expiredSessionUrl,
    },
    {
      label: MAX_CARD_TRANSACTION_AMOUNT,
      value: displayMaxTransactionAmount,
    },
    {
      label: achMaxTransactionAmountLabelMap(country),
      value: displayAchMaxTransactionAmount,
    },
    {
      label: PAYMENT_PROCESSORS,
      value: map(processors, processor => {
        if (size(processors) === 1) return <div>{processor}</div>
        return <div>{processor},</div>
      }),
    },
  ]

  const businessDetailsDataSection = isEmpty(businessData) ? [] : convertPageSectionDataToV2([
    {
      label: LEGAL_BUSINESS_NAME,
      value: businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
    },
    {
      label: BUSINESS_TYPE,
      value: displayBusinessType,
    },
    {
      label: OWNERSHIP_TYPE,
      value: ownershipType,
    },
    {
      label: DATE_OF_INCORPORATION,
      value: displayIncorporationDate,
    },
    {
      label: BUSINESS_TAX_ID,
      value: businessTaxId,
    },
    {
      label: WEBSITE,
      value: validateWebsiteLink({ website }) ? <a className='text-link' href={website} target='blank'>{website}</a> : website,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
  ])

  const businessDataSubtitle = businessDescription ? `Description: ${businessDescription}` : ''

  const controlPersonDataSection = !firstName ? [] : [{
    largeTitle: `${firstName} ${lastName}`,
    asideTitle: `(${title}, ${principalPercentageOwnership})`,
    data: [
      { label: DATE_OF_BIRTH, value: displayDateOfBirth },
      { label: TAX_ID, value: taxId },
      { label: EMAIL_ADDRESS, value: email },
      { label: PHONE_NUMBER, value: displayPhone },
      { label: ADDRESS, value: <Address address={personalAddress} /> },
    ],
  }]

  const beneficialOwnersDataSection = map(beneficialOwners, beneficialOwner => {
    const [
      ownerTitle,
      ownerFirstName,
      ownerLastName,
      ownerDateOfBirth,
      ownerPhone,
      ownerEmail,
      ownerPersonalAddress,
      ownerPrincipalPercentageOwnership,
      ownerTaxId,
    ] = getMany(beneficialOwner, [
      'title',
      'firstName',
      'lastName',
      'displayDateOfBirth',
      'displayPhone',
      'email',
      'personalAddress',
      'displayPrincipalPercentageOwnership',
      'taxId',
    ])

    return {
      largeTitle: `${ownerFirstName} ${ownerLastName}`,
      asideTitle: `(${ownerTitle}, ${ownerPrincipalPercentageOwnership})`,
      data: [
        { label: DATE_OF_BIRTH, value: ownerDateOfBirth },
        { label: TAX_ID, value: ownerTaxId },
        { label: EMAIL_ADDRESS, value: ownerEmail },
        { label: PHONE_NUMBER, value: ownerPhone },
        { label: ADDRESS, value: <Address address={ownerPersonalAddress} /> },
      ],
    }
  })

  const processingDetailsDataSection = [
    {
      label: MERCHANT_CATEGORY_CODE,
      value: mcc,
    },
    {
      label: TAX_AUTHORITY,
      value: taxAuthority,
      condition: !!taxAuthority,
    },
    {
      label: DEFAULT_STATEMENT_DESCRIPTOR,
      value: defaultStatementDescriptor,
    },
    {
      label: PROCESSED_CARDS_BEFORE,
      value: displayPreviouslyProcessedCreditCards,
    },
    {
      label: AMERICAN_EXPRESS_MID,
      value: amexMid,
      condition: displayPreviouslyProcessedCreditCards === 'Yes',
    },
    // {
    //   label: DISCOVERY_MID,
    //   value: discoverMid,
    //   condition: displayPreviouslyProcessedCreditCards === 'Yes',
    // },
    {
      label: REFUND_POLICY,
      value: displayRefundPolicy,
    },
    {
      label: ANNUAL_CARD_VOLUME,
      value: displayAnnualCardVolume,
    },
    {
      label: annualACHVolumeLabelMap(country),
      value: displayAnnualACHVolume,
    },
    {
      label: AVERAGE_CARD_TRANSACTION_AMOUNT,
      value: displayAverageCardTransferAmount,
    },
    {
      label: averageACHTransferAmountLabelMap(country),
      value: displayAverageACHTransferAmount,
    },
    {
      label: MAX_CARD_TRANSACTION_AMOUNT,
      value: displayMaxTransactionAmount,
    },
    {
      label: achMaxTransactionAmountLabelMap(country),
      value: displayAchMaxTransactionAmount,
    },
  ]

  const cardVolumeDataSection = [
    {
      label: ONLINE_PAYMENTS,
      value: displayEcommercePercentage,
    },
    {
      label: IN_PERSON_PAYMENTS,
      value: displayCardPresentPercentage,
    },
    {
      label: MAIL_TELEPHONE_ORDER,
      value: displayMailOrderTelephoneOrderPercentage,
    },
  ]

  const paymentVolumeDataSection = [
    {
      label: BUSINESS_TO_BUSINESS,
      value: displayBusinessToBusinessVolumePercentage,
    },
    {
      label: BUSINESS_TO_CONSUMER,
      value: displayBusinessToConsumerVolumePercentage,
    },
    {
      label: OTHER,
      value: displayOtherVolumePercentage,
    },
  ]

  const bankAccountDataSection = isEmpty(bankAccountData) ? [] : convertPageSectionDataToV2(plaidProcessorToken ? [
    {
      label: BANK_ACCOUNT,
      value: <div className='bank-account-link'><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
    },
    {
      label: CONNECTION_TYPE,
      value: PLAID,
    },
  ] : [
    {
      label: ACCOUNT_HOLDER_NAME,
      value: name,
    },
    {
      label: ACCOUNT_TYPE,
      value: displayAccountType,
    },
    {
      label: ROUTING_NUMBER,
      value: bankCode,
      condition: country !== CAN,
    },
    {
      label: TRANSIT_NUMBER,
      value: transitNumber,
      condition: country === CAN,
    },
    {
      label: INSTITUTION_NUMBER,
      value: institutionNumber,
      condition: country === CAN,
    },
    {
      label: ACCOUNT_NUMBER,
      value: accountNumber,
    },
    {
      label: BANK_DOCUMENT_PROVIDED,
      value: bankDocumentId ? YES : NO,
      condition: country === CAN,
    },
  ])

  return {
    onboardingFormId,
    onboardingForm,
    credentials,
    contextBarData,
    headerData,
    businessDetailsDataSection,
    controlPersonDataSection,
    processingDetailsDataSection,
    cardVolumeDataSection,
    paymentVolumeDataSection,
    bankAccountDataSection,
    beneficialOwnersDataSection,
    formDetailsDataSection,
    hasUrlDetails,
    returnUrl,
    expiredSessionUrl,
    feeDetailsUrl,
    termsOfServiceUrl,
    expirationInMinutes,
    expirationInDays,
    status,
    businessDataSubtitle,
    isFetching,
    businessType,
    processingDataIsEmpty: isEmpty(processingData),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingForm: ({ onboardingFormId, credentials }) => dispatch(getOnboardingFormDataRequest({ onboardingFormId, credentials })),
    postOnboardingFormLink: ({
      onboardingFormId,
      expirationInMinutes,
      expirationInDays,
      expiredSessionUrl,
      feeDetailsUrl,
      returnUrl,
      termsOfServiceUrl,
      credentials,
    }) => dispatch(postOnboardingFormLinkRequest({
      onboardingFormId,
      expirationInMinutes,
      expirationInDays,
      expiredSessionUrl,
      feeDetailsUrl,
      returnUrl,
      termsOfServiceUrl,
      credentials,
      dispatch,
    })),
  }
}

class OnboardingFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      onboardingFormId,
      getOnboardingForm,
    } = this.props

    getOnboardingForm({ onboardingFormId, credentials })
  }

  render() {
    const {
      onboardingFormId,
      postOnboardingFormLink,
      returnUrl,
      expiredSessionUrl,
      feeDetailsUrl,
      termsOfServiceUrl,
      expirationInMinutes,
      expirationInDays,
      credentials,
      status,
    } = this.props

    const actionToTrigger = () => postOnboardingFormLink({
      onboardingFormId,
      expirationInMinutes,
      expirationInDays,
      expiredSessionUrl,
      feeDetailsUrl,
      returnUrl,
      termsOfServiceUrl,
      credentials,
    })

    const actions = status !== 'COMPLETED' ? [{
      label: GENERATE_NEW_URL_LINK,
      action: actionToTrigger,
      className: 'generate-new-form-url-button',
    }] : []

    return (
      <OnboardingForm
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFormC)
