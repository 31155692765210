import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  BALANCE_TRANSFER,
  BALANCE_TRANSFERS,
} from 'constants/apiConstants'

const getBalanceTransferAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: BALANCE_TRANSFER({ balanceTransferId: id }),
    credentials,
    service: BALANCE_TRANSFERS,
  })
}

export default getBalanceTransferAPI
