import './PermissionsS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import get from 'lodash/get'
import slice from 'lodash/slice'

const Permissions = ({
  formattedPermissions = {},
}) => {
  return (
    <div className='Permissions'>
      <div className='appendix flex items-center'>
        <div><i className='fas fa-check-circle' /> = role permits access</div>
        <div className='flex items-center'><i className='fas fa-times-circle' />&nbsp;= role&nbsp;<div className='italic'>does not</div>&nbsp;permit access</div>
      </div>

      <div className='content'>
        { map(formattedPermissions, (values, key) => {
          const median = Math.ceil(values.length / 2)
          const firstHalf = slice(values, 0, median)
          const secondHalf = slice(values, median)

          return (
            <Fragment key={key}>
              <div className='section-header p-2-bold'>{key}</div>
              <div className='body flex'>
                { map(firstHalf, (value) => {
                  const label = get(value, 'label')
                  const enabled = get(value, 'enabled')

                  return (
                    <div className='permission flex items-center' key={`${key}-${label}`}>
                      {enabled ? <i className='fas fa-check-circle' /> : <i className='fas fa-times-circle' />}
                      <div className='label'>{label}</div>
                    </div>
                  )
                })}

                { map(secondHalf, (value) => {
                  const label = get(value, 'label')
                  const enabled = get(value, 'enabled')

                  return (
                    <div className='permission flex items-center' key={`${key}-${label}`}>
                      {enabled ? <i className='fas fa-check-circle' /> : <i className='fas fa-times-circle' />}
                      <div className='label'>{label}</div>
                    </div>
                  )
                })}
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

Permissions.propTypes = {
  formattedPermissions: PropTypes.object,
}

export default Permissions
