import removeUndefined from 'utilities/remove/removeUndefined'
import isEmpty from 'lodash/isEmpty'

const formatAddress = ({
  address = {},
}) => {
  if (!address) {
    return ''
  }

  const normalizedAddress = removeUndefined(address)

  if (isEmpty(normalizedAddress)) {
    return ''
  }

  const {
    line1 = '',
    line2 = '',
    city = '',
    region = '',
    postal_code: postalCode = '',
    postalCode: postalCodeFE = '',
    country = '',
  } = normalizedAddress

  return `${line1 && `${line1}, `}${line2 && `${line2}, `}${city && `${city}, `}${region && `${region}, `}${country && `${country}, `}${(postalCode || postalCodeFE) ? (postalCode || postalCodeFE) : ''}`
}

export default formatAddress
