import removeUndefined from 'utilities/remove/removeUndefined'
import replace from 'lodash/replace'
import isEmpty from 'lodash/isEmpty'

const backendAssociatedIdentityModel = ({ values }) => {
  if (isEmpty(values)) {
    return values
  }

  // TODO: Remove ssn in favor of taxId
  const {
    firstName,
    lastName,
    middleName,
    title,
    phone,
    email,
    ssn,
    taxId,
    ownershipType,
    dateOfBirth = {},
    personalAddress = {},
    principalPercentageOwnership,
    tags = {},
  } = values

  const {
    city,
    country,
    line1,
    line2,
    postalCode,
    region,
  } = personalAddress

  const formattedPersonalAddress = removeUndefined({
    city,
    country: city ? country : undefined,
    line1,
    line2,
    postal_code: postalCode,
    region,
  })

  const entity = {
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    title,
    phone,
    email,
    tax_id: replace(ssn, /-/g, '') || replace(taxId, /-/g, '') || undefined,
    personal_address: !isEmpty(formattedPersonalAddress) ? formattedPersonalAddress : undefined,
    dob: !isEmpty(dateOfBirth) ? dateOfBirth : undefined,
    ownership_type: ownershipType,
    principal_percentage_ownership: principalPercentageOwnership,
  }

  return removeUndefined({
    entity,
    tags,
  })
}

export default backendAssociatedIdentityModel
