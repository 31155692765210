import { getState } from 'state-layer/configureStore'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'

import {
  getTransferSelector,
  getAuthorizationSelector,
  getSettlementSelector,
  getIdentitySelector,
  getMerchantSelector,
  getApplicationSelector,
  getApplicationUsersSelector,
  getPaymentInstrumentSelector,
  getPlatformSelector,
  getPlatformProcessorConfigSelector,
  getReviewQueueItemSelector,
  getPayoutPlanSelector,
  getFeeSelector,
  getWebhookSelector,
  getComplianceFormSelector,
} from 'state-layer/selectors'

export const NEW = 'new'
export const EDIT = 'edit'
export const DUPLICATE = 'duplicate'
export const OVERVIEW = 'overview'
export const TRANSFERS = 'transfers'
export const TRANSACTIONS = 'transactions'
export const PAYMENTS = 'payments'
export const REFUNDS = 'refunds'
export const BALANCE_TRANSFERS = 'balance-transfers'
export const BALANCES = 'balances'
export const BALANCE_ENTRIES = 'balance-entries'
export const BALANCE = 'balance'
export const PAYOUTS = 'payouts'
export const AUTHORIZATIONS = 'authorizations'
export const SETTLEMENTS = 'settlements'
export const DISPUTES = 'disputes'
export const ACH_RETURNS = 'ach-returns'
export const REPORTS = 'reports'
export const REPORTS_HOME = 'reports-home'
export const IDENTITIES = 'identities'
export const BUYERS = 'buyers'
export const MERCHANTS = 'merchants'
export const PAYOUT_SETTINGS = 'payout-settings'
export const COMPLIANCE = 'compliance'
export const COMPLIANCE_FORMS = 'compliance-forms'
export const COMPLIANCE_FORM_TEMPLATES = 'compliance-form-templates'
export const APPLICATIONS = 'applications'
export const PAYMENT_INSTRUMENTS = 'payment-instruments'
export const ONBOARDING_SETTLEMENTS = 'onboarding-settlements'
export const ONBOARDING_MERCHANTS = 'onboarding-merchants'
export const ONBOARDING_IDENTITIES = 'onboarding-identities'
export const ONBOARDING_APPLICATIONS = 'onboarding-applications'
export const ONBOARDING_RECIPIENTS = 'onboarding-recipients'
export const REVIEW_QUEUE_IDENTITIES = 'review-identities'
export const REVIEW_SETTLEMENTS = 'review-settlements'
export const APPROVED_ONBOARDING_IDENTITIES = 'approved-onboarding-identities'
export const USERS = 'users'
export const SETTINGS = 'settings'
export const DEVELOPER = 'developer'
export const ONBOARDING = 'onboarding'
export const UPDATE_TRANSFERS = 'update-transfers'
export const UPDATE_VERIFICATIONS = 'update-verifications'
export const REVIEW_QUEUE = 'reviewQueue'
export const RECIPIENTS = 'recipients'
// export const RECIPIENT = 'recipient'
export const FEES = 'fees'
export const ENTRIES = 'entries'
export const EMAIL_TEMPLATES = 'email-templates'
export const PLATFORMS = 'platforms'
export const PROCESSOR_CONFIGS = 'processor-configs'
export const FEE = 'fee'
export const IDENTITY_UPLOADER = 'identity-uploader'
export const SUBSCRIPTION_BILLING = 'subscription-billing'
export const SUBSCRIPTION_SCHEDULES = 'subscription-schedules'
export const PAYOUT_PLANS = 'payout-plans'
export const PROCESSORS = 'processors'
export const FILES_MANAGEMENT = 'files-management'
export const TRANSACTIONS_MANAGEMENT = 'transactions-management'
export const WEBHOOKS = 'webhooks'
export const WEBHOOK_LOGS = 'webhook-logs'
export const EXPORTS = 'exports'
export const EXCEPTIONS_INSIGHTS = 'exceptions-insights'
export const COMPANY = 'company'
export const NOTIFICATIONS = 'notifications'
export const MERCHANT_DEPOSITS = 'merchant-deposits'
export const PLATFORM_FUNDING_TRANSFER = 'platform-funding-transfer'
export const API_LOGS = 'api-logs'
export const CREATE_WEBHOOK = 'create-webhook'
export const ONBOARDING_FORMS = 'onboarding-forms'
export const MEMBERS = 'members'
export const ADD_TEAM_MEMBER = 'add-team-member'
export const DASHBOARD_USER_ROLES = 'dashboard_user_roles'
export const ROLES = 'roles'
export const ADD_ROLE = 'add-role'
export const DUPLICATE_ROLE = 'duplicate-role'
export const LIVE_ACCOUNT_APPLICATION_FORM = 'live-account-application-form'
export const UNDERWRITING = 'underwriting'
export const LIVE_ACCOUNT_APPLICATION = 'live-account-application'
export const CUSTOMER_ONBOARDING_FORM = 'customer-onboarding-form'
export const SPLIT_PAYMENTS = 'split-payments'
export const SPLIT_REFUNDS = 'split-refunds'
export const CREATE_REFUND = 'create-refund'
export const DISBURSEMENTS = 'disbursements'
export const SEND_PAYOUT = 'send-payout'
export const ADDING_FUNDS = 'adding-funds'
export const PURCHASES = 'purchases'
export const PAYMENT_DEVICES = 'payment-devices'
export const ADD_NEW_DEVICE = 'add-new-device'
export const DEVICES = 'devices'
export const EVENTS = 'events'
export const PAST_RUN = 'past-run'
export const TRANSFER_ATTEMPTS = 'transfer_attempts'
export const PAYOUT_ATTEMPTS = 'payout_attempts'
export const VIRTUAL_TERMINAL = 'virtual-terminal'
export const MERCHANT_PAYOUTS = 'merchant-payouts'
export const BALANCE_ADJUSTMENTS = 'balance-adjustments'
export const ADJUSTMENTS = 'adjustments'
export const ADD_FUNDS = 'add-funds'
export const ADD_FUNDS_CONFIRMATION = 'add-funds-confirmation'
export const CREATE_RECEIPT = 'create-receipt'
export const SUBSCRIPTIONS = 'subscriptions'
export const SUBSCRIPTION_PLANS = 'subscription-plans'

export const DASHBOARD = 'Dashboard' // TODO: move this to env config
export const ADMINISTRATOR = 'Administrator' // TODO: move this to env config
export const COMPONENTS = 'Components'
export const GATEWAY_ADMINISTRATOR_TOOLS = `Gateway-${ADMINISTRATOR}-Tools`
export const LOGIN_PATH = 'Login'
export const LOGOUT_PATH = 'Logout'
export const DASHBOARD_PATH = `/${DASHBOARD}`
export const CREDENTIAL_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}`
export const ADMINISTRATOR_PATH = `/${ADMINISTRATOR}`
export const GATEWAY_ADMIN_TOOLS_PATH = `/${GATEWAY_ADMINISTRATOR_TOOLS}`
export const MERCHANT_ONBOARDING_PATH = 'merchant-onboarding'
export const CUSTOMER_ONBOARDING_PATH = 'customer-onboarding'
export const CUSTOMER_ONBOARDING_SUCCESS_PATH = `${CUSTOMER_ONBOARDING_PATH}/success`
export const SELF_SERVICE_SIGNUP_PATH = 'signup'
export const VERIFY_EMAIL_PATH = 'verify-email'
export const VERIFY_EMAIL_PENDING_PATH = 'verify-email-pending'
export const HOME = 'home'
export const HOME_INSIGHTS = 'home-insights'
export const TRANSACTION_INSIGHTS = 'transaction-insights'
export const MERCHANT_INSIGHTS = 'merchant-insights'
export const UNDERWRITING_INSIGHTS = 'underwriting-insights'
export const ADVERSE_MEDIA = 'adverse-media'
export const CREATE_TRANSACTION = 'create-transaction'
export const CREATE_TRANSACTION_CONFIRMATION = 'create-transaction-confirmation'

export const SET_VELOCITY_LIMITS = 'set-velocity-limits'
export const UPDATE_APPLICATION_CARD_LIMITS = 'update-application-card-limits'
export const UPDATE_APPLICATION_ACH_LIMITS = 'update-application-ach-limits'
export const UPDATE_RECIPIENT_CARD_LIMITS = 'update-recipient-card-limits'
export const UPDATE_RECIPIENT_ACH_LIMITS = 'update-recipient-ach-limits'
export const UPDATE_BALANCE_ADJUSTMENT_LIMITS = 'update-balance-adjustment-limits'
export const EDIT_RECIPIENT_IDENTITY = 'edit-recipient-identity'

export const PAYMENT_LINKS = 'payment-links'
export const CREATE_CONFIRMATION = 'create-confirmation'
export const GUEST_PAYMENT_LINK = 'payment_link'
export const GUEST_CHECKOUT_FORM = 'checkout_form'
export const GUEST_PAYMENT_LINK_CONFIRMATION = 'payment_link_confirmation'

export const PAYOUT_LINKS = 'payout-links'
export const GUEST_PAYOUT_LINK = 'payout_link'
export const GUEST_PAYOUT_LINK_CONFIRMATION = 'payout_link_confirmation'

export const MONITORING_ALERTS = 'monitoring-alerts'

export const CREATE_INSTANT_PAYOUT = 'create-instant-payout'
export const CREATE_INSTANT_PAYOUT_CONFIRMATION = 'instant-payout-confirmation'
// route params name
export const CREDENTIAL_ID = 'credentialId'
export const TRANSFER_ID = 'transferId'
export const AUTHORIZATION_ID = 'authorizationId'
export const SETTLEMENT_ID = 'settlementId'
export const DISPUTE_ID = 'disputeId'
export const IDENTITY_ID = 'identityId'
export const MERCHANT_ID = 'merchantId'
export const APPLICATION_ID = 'applicationId'
export const PROCESSOR = 'processor'
export const PAYMENT_INSTRUMENT_ID = 'paymentInstrumentId'
export const REVIEW_QUEUE_ID = 'reviewQueueId'

// TODO: add _PATH to missing links
export const HOME_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${HOME}`
export const APPLICATIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${APPLICATIONS}`
export const APPLICATION_PATH = ({ credentialId, applicationId }) => `${APPLICATIONS_PATH({ credentialId })}/${applicationId}`
export const NEW_APPLICATION_PATH = ({ credentialId }) => `${APPLICATIONS_PATH({ credentialId })}/new`
export const APPLICATION_PROCESSOR_PATH = ({ credentialId, applicationId, processor }) => `${APPLICATION_PATH({ credentialId, applicationId })}/processors/${processor}`
export const NEW_APPLICATION_IDENTITY_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({ credentialId, applicationId })}/new_identity`
export const CUSTOMER_ONBOARDING_FORM_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${CUSTOMER_ONBOARDING_FORM}`
export const CUSTOMER_ONBOARDING_FORM_VIEW_PATH = ({ credentialId, customerOnboardingFormId }) => `${DASHBOARD_PATH}/${credentialId}/${CUSTOMER_ONBOARDING_FORM}/${customerOnboardingFormId}`
export const NEW_CUSTOMER_ONBOARDING_FORM_PATH = ({ credentialId }) => `${CUSTOMER_ONBOARDING_FORM_PATH({ credentialId })}/new`
export const NEW_CUSTOMER_ONBOARDING_FORM_SUCCESS_PATH = ({ credentialId, customerOnboardingFormId }) => `${CUSTOMER_ONBOARDING_FORM_PATH({ credentialId })}/${customerOnboardingFormId}/success`

export const AUTHORIZATIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${AUTHORIZATIONS}`
export const AUTHORIZATION_PATH = ({ credentialId, authorizationId }) => `${AUTHORIZATIONS_PATH({ credentialId })}/${authorizationId}`

export const DISPUTES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${DISPUTES}`
export const DISPUTE_PATH = ({ credentialId, disputeId }) => `${DISPUTES_PATH({ credentialId })}/${disputeId}`
export const DISPUTE_UPDATE_PATH = ({ credentialId, disputeId }) => `${DISPUTES_PATH({ credentialId })}/${disputeId}/update`

export const ACH_RETURNS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ACH_RETURNS}`
export const ACH_RETURN_PATH = ({ credentialId, transferId }) => `${DASHBOARD_PATH}/${credentialId}/${ACH_RETURNS}/${transferId}`

export const RECIPIENT_IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${RECIPIENTS}`
export const IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${IDENTITIES}`
export const IDENTITY_PATH = ({ credentialId, identityId }) => `${IDENTITIES_PATH({ credentialId })}/${identityId}`
export const NEW_IDENTITY_PATH = ({ credentialId }) => `${IDENTITIES_PATH({ credentialId })}/new`
export const NEW_IDENTITY_PATH_FLEX = ({ credentialId }) => `${IDENTITIES_PATH({ credentialId })}/new_identity`
export const EDIT_IDENTITY_PATH = ({ credentialId, identityId }) => `${IDENTITIES_PATH({ credentialId })}/${identityId}/edit`
export const NEW_ASSOCIATED_IDENTITY_PATH = ({ credentialId, identityId }) => `${IDENTITY_PATH({ credentialId, identityId })}/new_associated_identity`
export const EDIT_ASSOCIATED_IDENTITY_PATH = ({ credentialId, identityId }) => `${IDENTITY_PATH({ credentialId, identityId })}/edit_associated_identity`
export const NEW_MERCHANT_APPLICATION_PATH = ({credentialId })=> `${IDENTITIES_PATH({ credentialId })}/new-merchant-application`
export const EDIT_IDENTITY_PATH_NEW = ({ credentialId, identityId }) => `${IDENTITIES_PATH({ credentialId })}/${identityId}/edit_identity`
export const IDENTITY_AUDIT_LOG_PATH =  ({ credentialId, identityId, subjectId }) => `${IDENTITY_PATH({ credentialId, identityId })}/reviews/${subjectId}/audit-log-events`
export const RECIPIENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${RECIPIENTS}`
export const ADD_RECIPIENT_PATH = ({ credentialId }) => `${RECIPIENTS_PATH({ credentialId })}/add-recipient-identity`
export const RECIPIENT_PATH = ({ credentialId, recipientId }) => `${RECIPIENTS_PATH({ credentialId })}/${recipientId}`
export const EDIT_RECIPIENT_IDENTITY_PATH = ({ credentialId, recipientId }) => `${RECIPIENT_PATH({ credentialId, recipientId })}/edit-recipient-identity`
export const LIVE_ACCOUNT_APPLICATION_PATH = ({ credentialId, accessFormId }) => `${DASHBOARD_PATH}/${credentialId}/${LIVE_ACCOUNT_APPLICATION}/${accessFormId}`
export const LIVE_ACCOUNT_APPLICATION_FORM_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${LIVE_ACCOUNT_APPLICATION_FORM}`
export const EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH = ({ credentialId, accessFormId }) => `${LIVE_ACCOUNT_APPLICATION_FORM_PATH({ credentialId })}/${accessFormId}`

export const BUYERS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${BUYERS}`
export const BUYER_PATH = ({ credentialId, identityId }) => `${BUYERS_PATH({ credentialId })}/${identityId}`

export const MERCHANTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANTS}`
export const IDENTITY_MERCHANTS_PATH = ({ credentialId, identityId }) => `${DASHBOARD_PATH}/${credentialId}/${IDENTITIES}/${identityId}/${MERCHANTS}`
export const MERCHANT_PATH = ({ credentialId, identityId, merchantId }) => `${DASHBOARD_PATH}/${credentialId}/${IDENTITIES}/${identityId}/${MERCHANTS}/${merchantId}`

export const PAYOUT_SETTINGS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_SETTINGS}`
export const PAYOUT_SETTING_PATH = ({ credentialId, payoutSettingsId }) => `${PAYOUT_SETTINGS_PATH({ credentialId })}/${payoutSettingsId}`

export const COMPLIANCE_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPLIANCE}`
export const COMPLIANCE_FORMS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPLIANCE_FORMS}`
export const COMPLIANCE_FORM_PATH = ({ credentialId, complianceFormId }) => `${COMPLIANCE_FORMS_PATH({ credentialId })}/${complianceFormId}`
export const COMPLIANCE_FORM_TEMPLATES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPLIANCE_FORM_TEMPLATES}`
export const COMPLIANCE_FORM_TEMPLATE_PATH = ({ credentialId, complianceFormTemplateId }) => `${COMPLIANCE_FORM_TEMPLATES_PATH({ credentialId })}/${complianceFormTemplateId}`
export const CREATE_COMPLIANCE_FORM_TEMPLATE_PATH = ({ credentialId }) => `${COMPLIANCE_FORM_TEMPLATES_PATH({ credentialId })}/new`
export const DUPLICATE_COMPLIANCE_FORM_TEMPLATE_PATH = ({ credentialId, complianceFormTemplateId }) => `${COMPLIANCE_FORM_TEMPLATES_PATH({ credentialId })}/${complianceFormTemplateId}/duplicate`

// export const ONBOARDING_IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ONBOARDING}/${IDENTITIES}`
// export const ONBOARDING_IDENTITY_PATH = ({ credentialId, reviewQueueId }) => `${ONBOARDING_IDENTITIES_PATH({ credentialId })}/${reviewQueueId}`

export const PAYMENT_INSTRUMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYMENT_INSTRUMENTS}`
export const PAYMENT_INSTRUMENT_PATH = ({ credentialId, paymentInstrumentId }) => `${PAYMENT_INSTRUMENTS_PATH({ credentialId })}/${paymentInstrumentId}`

export const REPORTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${REPORTS}`
export const REPORTS_HOME_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${REPORTS_HOME}`
export const CATEGORIZED_REPORTS_PATH = ({ credentialId, reportCategory, reportType }) => `${REPORTS_PATH({ credentialId })}/${reportCategory}/${reportType}`

export const REVIEW_QUEUE_SETTLEMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ONBOARDING_SETTLEMENTS}`
export const REVIEW_QUEUE_SETTLEMENT_PATH = ({ credentialId, reviewQueueId }) => `${REVIEW_QUEUE_SETTLEMENTS_PATH({ credentialId })}/${reviewQueueId}`
export const REVIEW_QUEUE_MERCHANTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ONBOARDING_MERCHANTS}`
export const REVIEW_QUEUE_MERCHANT_PATH = ({ credentialId, reviewQueueId }) => `${REVIEW_QUEUE_MERCHANTS_PATH({ credentialId })}/${reviewQueueId}`
export const REVIEW_QUEUE_IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${REVIEW_QUEUE_IDENTITIES}`
export const ONBOARDING_IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_IDENTITIES}`
export const ONBOARDING_APPLICATIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_APPLICATIONS}`
export const ONBOARDING_APPLICATION_PATH = ({ credentialId, reviewId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_APPLICATIONS}/${reviewId}`
export const UNDERWRITING_MERCHANTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_MERCHANTS}`
export const UNDERWRITING_MERCHANT_PATH = ({ credentialId, reviewId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_MERCHANTS}/${reviewId}`
// Mary to do: confirm that you are calling the right end point once FWS QA is deployed.
export const UNDERWRITING_RECIPIENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_RECIPIENTS}`
export const UNDERWRITING_RECIPIENT_PATH = ({ credentialId, reviewId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING}/${ONBOARDING_RECIPIENTS}/${reviewId}`
export const APPROVED_REVIEW_QUEUE_IDENTITIES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${APPROVED_ONBOARDING_IDENTITIES}`
export const REVIEW_QUEUE_IDENTITY_PATH = ({ credentialId, reviewQueueId }) => `${REVIEW_QUEUE_IDENTITIES_PATH({ credentialId })}/${reviewQueueId}`
export const ONBOARDING_IDENTITY_PATH = ({ credentialId, reviewQueueId }) => `${ONBOARDING_IDENTITIES_PATH({ credentialId })}/${reviewQueueId}`
export const ONBOARDING_IDENTITY_AUDIT_PATH = ({ credentialId, reviewQueueId, verificationId  }) => `${ONBOARDING_IDENTITY_PATH({ credentialId, reviewQueueId })}/audit/${verificationId}`
export const UNDERWRITING_ADVERSE_MEDIA_PATH = ({ credentialId }) =>  `${DASHBOARD_PATH}/${credentialId}/${ADVERSE_MEDIA}`
export const UNDERWRITING_REPORT_PATH = ({ credentialId, reportId }) => `${UNDERWRITING_ADVERSE_MEDIA_PATH({ credentialId })}/${reportId}`
export const UNDERWRITING_PAST_RUN_PATH = ({ credentialId, reviewId, runId })=> `${UNDERWRITING_MERCHANT_PATH({ credentialId, reviewId })}/${PAST_RUN}/${runId}`

export const SETTLEMENTS_PATH = ({ credentialId, review = false }) => `${DASHBOARD_PATH}/${credentialId}/${review ? 'review-' : ''}${SETTLEMENTS}`
export const SETTLEMENT_PATH = ({ credentialId, settlementId, review = false }) => `${SETTLEMENTS_PATH({ credentialId, review })}/${settlementId}`
export const SETTLEMENT_INSTANT_PAYOUT_PATH = ({ credentialId, settlementId, review = false }) => `${SETTLEMENTS_PATH({ credentialId, review })}/${settlementId}/${CREATE_INSTANT_PAYOUT}`

export const SETTLEMENT_INSTANT_PAYOUT_CONFIRMATION_PATH = ({ credentialId, settlementId, instantPayoutId }) => `${SETTLEMENT_INSTANT_PAYOUT_PATH({ credentialId, settlementId })}/${instantPayoutId}/${CREATE_INSTANT_PAYOUT_CONFIRMATION}`
export const REVIEW_SETTLEMENT_PATH = ({ credentialId, settlementId }) => `${SETTLEMENT_PATH({ credentialId, settlementId, review: true })}`
export const SETTLEMENT_ENTRIES_PATH = ({ credentialId, settlementId }) => settlementId ? `${SETTLEMENT_PATH({ credentialId, settlementId })}/${ENTRIES}` : `${DASHBOARD_PATH}/${credentialId}/${ENTRIES}`
export const FEES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${FEES}`
export const FEE_PATH = ({ credentialId, settlementId, feeId }) => settlementId ? `${SETTLEMENT_ENTRIES_PATH({ credentialId, settlementId })}/${feeId}` : `${FEES_PATH({ credentialId })}/${feeId}`
export const FEE_TABLE_ITEM_PATH = ({ credentialId, settlementId, id }) => settlementId ? `${SETTLEMENT_ENTRIES_PATH({ credentialId, settlementId })}/${id}` : `${FEES_PATH({ credentialId })}/${id}`

export const PAYMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYMENTS}`
export const PAYMENT_PATH = ({ credentialId, transferId }) => `${PAYMENTS_PATH({ credentialId })}/${transferId}`
export const DISPUTE_PAYMENT_PATH = ({ credentialId, transferId }) => `${PAYMENTS_PATH({ credentialId })}/${transferId}/dispute`
export const CREATE_PAYMENT_PATH = ({ credentialId }) => `${PAYMENTS_PATH({ credentialId })}/${CREATE_TRANSACTION}`

export const SPLIT_PAYMENT_PATH = ({ credentialId, transferId, splitTransferId }) => `${PAYMENT_PATH({ credentialId, transferId })}/${SPLIT_PAYMENTS}/${splitTransferId}`

export const CREATE_REFUND_PATH = ({ credentialId, transferId }) => `${PAYMENT_PATH({ credentialId, transferId })}/${CREATE_REFUND}`

export const REFUNDS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${REFUNDS}`
export const REFUND_PATH = ({ credentialId, transferId }) => `${DASHBOARD_PATH}/${credentialId}/${REFUNDS}/${transferId}`
export const SPLIT_REFUND_PATH = ({ credentialId, transferId, splitRefundId }) => `${REFUND_PATH({ credentialId, transferId })}/${SPLIT_REFUNDS}/${splitRefundId}`

export const BALANCE_TRANSFERS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${BALANCE_TRANSFERS}`
export const BALANCE_TRANSFER_PATH = ({ credentialId, balanceTransferId }) => `${BALANCE_TRANSFERS_PATH({ credentialId })}/${balanceTransferId}`

export const PAYOUTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUTS}`

export const OVERVIEW_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${OVERVIEW}`

export const USERS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPANY}/${USERS}`
export const USER_PATH = ({ credentialId, userId }) => `${USERS_PATH({ credentialId })}/${userId}`
export const NEW_USER_PATH = ({ credentialId }) => `${USERS_PATH({ credentialId })}/new`

export const ADMIN_USERS_PATH = () =>  `${ADMINISTRATOR_PATH}/${USERS}`
export const ADMIN_USER_PATH = ({ userId }) => `${ADMIN_USERS_PATH()}/${userId}`
export const NEW_ADMIN_USER_PATH = `${ADMIN_USERS_PATH()}/new`

export const SETTINGS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SETTINGS}`

export const DEVELOPER_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${DEVELOPER}`

export const UPDATE_TRANSFERS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UPDATE_TRANSFERS}`

export const UPDATE_VERIFICATIONS_PATH = ({ credentialId }) =>  `${DASHBOARD_PATH}/${credentialId}/${UPDATE_VERIFICATIONS}`

export const PLATFORMS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PLATFORMS}`
export const PLATFORM_PATH = ({ credentialId, platformId }) => `${PLATFORMS_PATH({ credentialId })}/${platformId}`

export const ONBOARDING_FORMS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ONBOARDING_FORMS}`
export const ONBOARDING_FORM_PATH = ({ credentialId, onboardingFormId }) => `${ONBOARDING_FORMS_PATH({ credentialId })}/${onboardingFormId}`

export const PLATFORM_PROCESSOR_CONFIGS_PATH = ({ credentialId, platformId }) => `${PLATFORM_PATH({credentialId, platformId})}/${PROCESSOR_CONFIGS}`
export const PLATFORM_PROCESSOR_CONFIG_PATH = ({ credentialId, platformId, processorConfigId }) => `${PLATFORM_PROCESSOR_CONFIGS_PATH({credentialId, platformId })}/${processorConfigId}`

export const IDENTITY_UPLOADER_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${IDENTITY_UPLOADER}`

export const SUBSCRIPTION_BILLING_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SUBSCRIPTION_BILLING}`
export const SUBSCRIPTION_SCHEDULES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/subscription-billing/${SUBSCRIPTION_SCHEDULES}`
export const SUBSCRIPTION_SCHEDULE_PATH = ({ credentialId, subscriptionScheduleId }) => `${SUBSCRIPTION_SCHEDULES_PATH({ credentialId })}/${subscriptionScheduleId}`

export const PAYOUT_PLANS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_PLANS}`
export const PAYOUT_PLAN_PATH = ({ credentialId, payoutPlanId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_PLANS}/${payoutPlanId}`
export const NEW_PAYOUT_PLANS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_PLANS}/new`
export const EDIT_PAYOUT_PLANS_PATH = ({ credentialId, payoutPlanId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_PLANS}/${payoutPlanId}/edit`

export const WEBHOOKS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${DEVELOPER}/${WEBHOOKS}`
export const WEBHOOK_LOGS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${DEVELOPER}/${WEBHOOK_LOGS}`
export const APPLICATION_WEBHOOKS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${WEBHOOKS}`
export const APPLICATION_WEBHOOK_LOGS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${WEBHOOK_LOGS}`
export const WEBHOOK_PATH = ({ credentialId, webhookId }) => `${WEBHOOKS_PATH({ credentialId })}/${webhookId}`
export const APPLICATION_WEBHOOK_PATH =({ credentialId, applicationId, webhookId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${WEBHOOKS}/${webhookId}`
export const WEBHOOK_LOG_PATH = ({credentialId, webhookLogId}) => `${WEBHOOK_LOGS_PATH({ credentialId })}/${webhookLogId}`
export const APPLICATION_WEBHOOK_LOG_PATH = ({credentialId, applicationId, webhookLogId })=> `${APPLICATION_PATH({credentialId, applicationId})}/${WEBHOOK_LOGS}/${webhookLogId}`
export const CREATE_WEBHOOK_PATH = ({ credentialId }) => `${WEBHOOKS_PATH({ credentialId })}/${CREATE_WEBHOOK}`
export const CREATE_APPLICATION_WEBHOOK_PATH = ({credentialId, applicationId})=> `${APPLICATION_PATH({credentialId, applicationId})}/${CREATE_WEBHOOK}`
export const EDIT_WEBHOOK_PATH = ({ credentialId, webhookId }) => `${WEBHOOK_PATH({ credentialId, webhookId })}/edit`
export const EDIT_APPLICATION_WEBHOOK_PATH =  ({credentialId, applicationId, webhookId })=> `${APPLICATION_WEBHOOK_PATH({credentialId, applicationId, webhookId})}/edit`
export const EDIT_APPLICATION_FEE_PROFILE_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId })}/edit-fee-profile`
export const EDIT_APPLICATION_FEE_PROFILE_RULES_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId })}/edit-fee-profile-rules`
export const EDIT_MERCHANT_FEE_PROFILE_PATH = ({ credentialId, identityId, merchantId }) => `${MERCHANT_PATH({credentialId, identityId, merchantId })}/edit-fee-profile`
export const EXPORTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${EXPORTS}`

export const COMPANY_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPANY}`
export const ADD_TEAM_MEMBER_PATH = ({ credentialId }) => `${MEMBERS_PATH({credentialId})}/${ADD_TEAM_MEMBER}`
export const ADD_ROLE_PATH = ({ credentialId }) => `${ROLES_PATH({credentialId})}/${ADD_ROLE}`
export const DUPLICATE_ROLE_PATH = ({ credentialId }) => `${ROLES_PATH({credentialId})}/${DUPLICATE_ROLE}`

export const NOTIFICATIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${NOTIFICATIONS}`

export const MERCHANT_DEPOSITS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANT_DEPOSITS}`
export const MERCHANT_DEPOSIT_PATH = ({ credentialId, transactionId }) =>  `${MERCHANT_DEPOSITS_PATH({credentialId})}/${transactionId}`

export const PLATFORM_FUNDING_TRANSFER_PATH = ({ credentialId, transactionId }) => `${DASHBOARD_PATH}/${credentialId}/${PLATFORM_FUNDING_TRANSFER}/${transactionId}`

export const API_LOGS_PATH = ({ credentialId }) =>  `${DASHBOARD_PATH}/${credentialId}/${DEVELOPER}/${API_LOGS}`
export const API_LOG_PATH = ({ credentialId, apiLogId }) =>  `${API_LOGS_PATH({credentialId})}/${apiLogId}`

export const MEMBERS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPANY}/${MEMBERS}`
export const MEMBER_PATH = ({ credentialId, memberId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPANY}/${MEMBERS}/${memberId}`
export const EDIT_MEMBER_PATH = ({ credentialId, memberId }) => `${MEMBER_PATH({ credentialId, memberId })}/edit`
export const ROLES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${COMPANY}/${ROLES}`
export const ROLE_PATH = ({ credentialId, roleId }) => `${ROLES_PATH({credentialId})}/${roleId}`

export const CREATE_PAYMENT_LINK_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYMENT_LINKS}/create`

export const PAYMENT_LINKS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYMENT_LINKS}`
export const PAYMENT_LINK_PATH = ({ credentialId, paymentLinkId }) => `${PAYMENT_LINKS_PATH({credentialId})}/${paymentLinkId}`
export const PAYMENT_LINK_CREATE_CONFIRMATION_PATH = ({ credentialId, paymentLinkId }) => `${PAYMENT_LINK_PATH({ credentialId, paymentLinkId })}/${CREATE_CONFIRMATION}`

export const PURCHASES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PURCHASES}`
export const PURCHASE_PATH = ({ credentialId, purchaseId }) => `${PURCHASES_PATH({ credentialId })}/${purchaseId}`

export const PAYMENT_DEVICES_PATH = ({credentialId}) => `${DASHBOARD_PATH}/${credentialId}/${PAYMENT_DEVICES}`
export const PAYMENT_DEVICE_PATH = ({credentialId, deviceId }) => `${PAYMENT_DEVICES_PATH({credentialId})}/${deviceId}`
export const ADD_NEW_DEVICE_PATH = ({credentialId}) => `${DASHBOARD_PATH}/${credentialId}/${ADD_NEW_DEVICE}`

export const STATEMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/statements`

// insights path
export const HOME_INSIGHTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${HOME_INSIGHTS}`
export const TRANSACTION_INSIGHTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${TRANSACTION_INSIGHTS}`
export const EXCEPTIONS_INSIGHTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${EXCEPTIONS_INSIGHTS}`
export const MERCHANT_INSIGHTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANT_INSIGHTS}`
export const UNDERWRITING_INSIGHTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${UNDERWRITING_INSIGHTS}`

// disbursements dashboard paths
export const DISBURSEMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${DISBURSEMENTS}`
export const DISBURSEMENT_PATH = ({ credentialId, transferId }) => `${DASHBOARD_PATH}/${credentialId}/${DISBURSEMENTS}/${transferId}`
export const BALANCE_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${BALANCE}`
export const BALANCES_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${BALANCES}`
export const ADDING_FUNDS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${BALANCE}/${ADDING_FUNDS}`
export const SET_APPLICATION_LEVEL_VELOCITY_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${SET_VELOCITY_LIMITS}`
export const SET_PLATFORM_LEVEL_VELOCITY_LIMITS_PATH = ({ credentialId }) => `${COMPANY_PATH({credentialId})}/${SET_VELOCITY_LIMITS}`
export const UPDATE_PLATFORM_LEVEL_APPLICATION_CARD_LIMITS_PATH = ({ credentialId }) => `${COMPANY_PATH({credentialId})}/${UPDATE_APPLICATION_CARD_LIMITS}`
export const UPDATE_PLATFORM_LEVEL_APPLICATION_ACH_LIMITS_PATH = ({ credentialId }) => `${COMPANY_PATH({credentialId})}/${UPDATE_APPLICATION_ACH_LIMITS}`
export const UPDATE_PLATFORM_LEVEL_RECIPIENT_CARD_LIMITS_PATH = ({ credentialId }) => `${COMPANY_PATH({credentialId})}/${UPDATE_RECIPIENT_CARD_LIMITS}`
export const UPDATE_PLATFORM_LEVEL_RECIPIENT_ACH_LIMITS_PATH = ({ credentialId}) => `${COMPANY_PATH({credentialId})}/${UPDATE_RECIPIENT_ACH_LIMITS}`
export const UPDATE_APPLICATION_CARD_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${UPDATE_APPLICATION_CARD_LIMITS}`
export const UPDATE_APPLICATION_ACH_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${UPDATE_APPLICATION_ACH_LIMITS}`
export const UPDATE_RECIPIENT_CARD_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${UPDATE_RECIPIENT_CARD_LIMITS}`
export const UPDATE_RECIPIENT_ACH_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${UPDATE_RECIPIENT_ACH_LIMITS}`
export const UPDATE_BALANCE_ADJUSTMENTS_LIMITS_PATH = ({ credentialId, applicationId }) => `${APPLICATION_PATH({credentialId, applicationId})}/${UPDATE_BALANCE_ADJUSTMENT_LIMITS}`
export const BALANCE_ENTRY_PATH = ({ credentialId, balanceEntryId }) => `${BALANCE_PATH({ credentialId })}/${balanceEntryId}`

export const BALANCE_ADJUSTMENTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${ADJUSTMENTS}`
export const BALANCE_ADJUSTMENT_PATH = ({ credentialId, balanceAdjustmentId }) => `${BALANCE_ADJUSTMENTS_PATH({ credentialId })}/${balanceAdjustmentId}`
export const ADD_FUNDS_PATH = ({ credentialId }) => `${BALANCE_ADJUSTMENTS_PATH({ credentialId })}/${ADD_FUNDS}`
export const ADD_FUNDS_CONFIRMATION_PATH = ({ credentialId, balanceAdjustmentId }) => `${BALANCE_ADJUSTMENTS_PATH({ credentialId })}/${balanceAdjustmentId}/${ADD_FUNDS_CONFIRMATION}`

export const CREATE_TRANSFER_RECEIPT_PATH = ({ credentialId, transferId }) => `${PAYMENT_PATH({ credentialId, transferId })}/${CREATE_RECEIPT}`
export const CREATE_AUTHORIZATION_RECEIPT_PATH = ({ credentialId, authorizationId }) => `${AUTHORIZATION_PATH({ credentialId, authorizationId })}/${CREATE_RECEIPT}`

// new path for audit logs
export const AUDIT_LOG_EVENT_PATH = ({ credentialId, identityId }) => `${IDENTITY_PATH({ credentialId, identityId })}/audit-log-events`

export const SEND_PAYOUT_OPTIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SEND_PAYOUT}`
export const SEND_PAYOUT_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SEND_PAYOUT}/manual`
export const SEND_PAYOUT_CONFIRMATION_PATH = ({ credentialId, entityId }) => `${SEND_PAYOUT_PATH({ credentialId })}/${entityId}`

export const CREATE_PAYOUT_LINK_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_LINKS}/create`
export const PAYOUT_LINKS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_LINKS}`
export const PAYOUT_LINK_PATH = ({ credentialId, payoutLinkId }) => `${PAYOUT_LINKS_PATH({credentialId})}/${payoutLinkId}`
export const PAYOUT_LINK_CREATE_CONFIRMATION_PATH = ({ credentialId, payoutLinkId }) => `${PAYOUT_LINK_PATH({ credentialId, payoutLinkId })}/${CREATE_CONFIRMATION}`

export const PAYOUT_ATTEMPTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${PAYOUT_ATTEMPTS}`

// Transaction Monitoring Alerts
export const MONITORING_ALERTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MONITORING_ALERTS}`
export const MONITORING_ALERT_PATH = ({ credentialId, monitoringAlertId }) => `${MONITORING_ALERTS_PATH({ credentialId })}/${monitoringAlertId}`
export const MONITORING_ALERT_DETAILS_TAB_PATH = ({ credentialId, monitoringAlertId }) => `${MONITORING_ALERTS_PATH({ credentialId })}/${monitoringAlertId}/?tab=details`

// TODO: set home path in case we ever want to change it
// export const HOME_PATH = HOME_PATH

// Admin Path
export const EMAIL_TEMPLATES_PATH = `${ADMINISTRATOR_PATH}/${EMAIL_TEMPLATES}`

// Gateway Admin Tools Paths
export const FILES_MANAGEMENT_PATH =  `${GATEWAY_ADMIN_TOOLS_PATH}/${FILES_MANAGEMENT}`
export const TRANSACTIONS_MANAGEMENT_PATH = `${GATEWAY_ADMIN_TOOLS_PATH}/${TRANSACTIONS_MANAGEMENT}`

// new paths for nav bar updates
export const VIRTUAL_TERMINAL_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${VIRTUAL_TERMINAL}`
export const CREATE_TRANSACTION_CONFIRMATION_PATH = ({ credentialId, entityId }) => `${VIRTUAL_TERMINAL_PATH({ credentialId })}/${entityId}/${CREATE_TRANSACTION_CONFIRMATION}`
export const MERCHANT_PAYOUTS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANT_PAYOUTS}`
export const MERCHANT_PAYOUTS_PATH_SETTLEMENTS = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANT_PAYOUTS}/${SETTLEMENTS}`
export const MERCHANT_PAYOUTS_PATH_MERCHANT_DEPOSITS = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${MERCHANT_PAYOUTS}/${MERCHANT_DEPOSITS}`

// recurring billing paths
export const SUBSCRIPTIONS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SUBSCRIPTIONS}`
export const SUBSCRIPTION_PATH = ({ credentialId, subscriptionId }) => `${SUBSCRIPTIONS_PATH({ credentialId })}/${subscriptionId}`
export const CREATE_SUBSCRIPTION_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SUBSCRIPTIONS}/create`
export const EDIT_SUBSCRIPTION_PATH = ({ credentialId, subscriptionId }) => `${SUBSCRIPTION_PATH({ credentialId, subscriptionId })}/edit-subscription`

export const SUBSCRIPTION_PLANS_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SUBSCRIPTION_PLANS}`
export const SUBSCRIPTION_PLAN_PATH = ({ credentialId, subscriptionPlanId }) => `${SUBSCRIPTION_PLANS_PATH({ credentialId })}/${subscriptionPlanId}`
export const CREATE_SUBSCRIPTION_PLAN_PATH = ({ credentialId }) => `${DASHBOARD_PATH}/${credentialId}/${SUBSCRIPTION_PLANS}/create`
export const EDIT_SUBSCRIPTION_PLAN_PATH = ({ credentialId, subscriptionPlanId }) => `${SUBSCRIPTION_PLAN_PATH({ credentialId, subscriptionPlanId })}/edit-subscription-plan`

// TODO: look for another way to handle auto breadcrumb generation - we currently look at the url parts and try to find the link to the resource here
export const RESOURCE_LIST_PATH_MAP = {
  [TRANSFERS]: PAYMENTS_PATH,
  [PAYOUTS]: PAYOUTS_PATH,
  [AUTHORIZATIONS]: AUTHORIZATIONS_PATH,
  [SETTLEMENTS]: SETTLEMENTS_PATH,
  [DISPUTES]: DISPUTES_PATH,
  [REPORTS]: REPORTS_PATH,
  [IDENTITIES]: IDENTITIES_PATH,
  [MERCHANTS]: MERCHANTS_PATH,
  [APPLICATIONS]: APPLICATIONS_PATH,
  [PAYMENT_INSTRUMENTS]: PAYMENT_INSTRUMENTS_PATH,
  [ONBOARDING_SETTLEMENTS]: REVIEW_QUEUE_SETTLEMENTS_PATH,
  [ONBOARDING_MERCHANTS]: REVIEW_QUEUE_MERCHANTS_PATH,
  [ONBOARDING_IDENTITIES]: REVIEW_QUEUE_IDENTITIES_PATH,
  [USERS]: USERS_PATH,
  [SETTINGS]: SETTINGS_PATH,
  [PLATFORMS]: PLATFORMS_PATH,
  [PROCESSOR_CONFIGS]: PLATFORM_PROCESSOR_CONFIGS_PATH,
  [PAYOUT_PLANS]: PAYOUT_PLANS_PATH,
  [ENTRIES]: SETTLEMENT_ENTRIES_PATH,
  [REFUNDS]: REFUNDS_PATH,
  [ACH_RETURNS]: ACH_RETURNS_PATH,
}

// export const RESOURCE_VIEW_PATH_MAP = {
//   [TRANSFERS]: PAYMENT_PATH,
//   [PAYOUTS]: PAYOUT_PATH,
//   [AUTHORIZATIONS]: AUTHORIZATION_PATH,
//   [SETTLEMENTS]: SETTLEMENT_PATH,
//   [DISPUTES]: DISPUTE_PATH,
//   [REPORTS]: REPORT_PATH,
//   [IDENTITIES]: IDENTITY_PATH,
//   [MERCHANTS]: MERCHANT_PATH,
//   [APPLICATIONS]: APPLICATION_PATH,
//   [PAYMENT_INSTRUMENTS]: PAYMENT_INSTRUMENT_PATH,
//   [USERS]: USER_PATH,
//   [PLATFORMS]: PLATFORM_PATH,
//   [PROCESSOR_CONFIGS]: PLATFORM_PROCESSOR_CONFIG_PATH,
// }

export const RESOURCE_VIEW_SELECTOR_MAP = {
  [TRANSFERS]: getTransferSelector,
  [PAYOUTS]: getTransferSelector,
  [AUTHORIZATIONS]: getAuthorizationSelector,
  [SETTLEMENTS]: getSettlementSelector,
  [ENTRIES]: getFeeSelector, // TODO: create a function to pick correct selector based on entry type
  [IDENTITIES]: getIdentitySelector,
  [MERCHANTS]: getMerchantSelector,
  [APPLICATIONS]: getApplicationSelector,
  [PAYMENT_INSTRUMENTS]: getPaymentInstrumentSelector,
  [PLATFORMS]: getPlatformSelector,
  [PROCESSOR_CONFIGS]: getPlatformProcessorConfigSelector,
  [USERS]: getApplicationUsersSelector,
  [ONBOARDING_SETTLEMENTS]: getReviewQueueItemSelector,
  [ONBOARDING_MERCHANTS]: getReviewQueueItemSelector,
  [ONBOARDING_IDENTITIES]: getReviewQueueItemSelector,
  [PAYOUT_PLANS]: getPayoutPlanSelector,
  [WEBHOOKS]: getWebhookSelector,
  [COMPLIANCE_FORMS]: getComplianceFormSelector,
}

// export const RESOURCE_ID_MAP = {
//   [TRANSFERS]: 'transferId',
//   [AUTHORIZATIONS]: 'authorizationId',
//   [SETTLEMENTS]: 'settlementId',
//   [DISPUTES]: 'disputeId',
//   [REPORTS]: 'reportId',
//   [IDENTITIES]: 'identityId',
//   [MERCHANTS]: 'merchantId',
//   [APPLICATIONS]: 'applicationId',
//   [PAYMENT_INSTRUMENTS]: 'paymentInstrumentId',
//   [USERS]: 'userId',
//   [PLATFORMS]: 'platformId',
//   [PROCESSOR_CONFIGS]: 'processorConfigId',
// }

// export const RESOURCE_PATH_PAYLOAD_MAP = {
//   [PROCESSOR_CONFIGS]: getProcessorConfigsPathPayload,
// }

export const ROUTES = {
  [NEW]: 'New',
  [EDIT]: 'Edit',
  [DUPLICATE]: 'Duplicate',
  [DASHBOARD]: 'Dashboard',
  [TRANSFERS]: 'Transfers',
  [PAYOUTS]: 'Payouts',
  [AUTHORIZATIONS]: 'Authorizations',
  [SETTLEMENTS]: 'Settlements',
  [DISPUTES]: 'Disputes',
  [REPORTS]: 'Reports',
  [IDENTITIES]: 'Identities',
  [MERCHANTS]: 'Merchants',
  [APPLICATIONS]: 'Applications',
  [PAYMENT_INSTRUMENTS]: 'Payment Instruments',
  [ONBOARDING_SETTLEMENTS]: 'Review - Settlements',
  [ONBOARDING_MERCHANTS]: 'Review - Merchants',
  [ONBOARDING_IDENTITIES]: 'Underwriting',
  [USERS]: 'Users',
  [SETTINGS]: 'Settings',
  [ONBOARDING]: 'Onboarding',
  [UPDATE_TRANSFERS]: 'Update Transfers',
  [UPDATE_VERIFICATIONS]: 'Update Verifications',
  [ADMINISTRATOR]: 'Administrator',
  [EMAIL_TEMPLATES]: 'Email Templates',
  [PLATFORMS]: 'Platforms',
  [PROCESSOR_CONFIGS]: 'Processor Config',
  [IDENTITY_UPLOADER]: 'Identity Uploader',
  [PROCESSORS]: 'Processors',
  [ENTRIES]: 'Entries',
  [BALANCE_TRANSFERS]: 'Balance Transfers',
  [PAYOUT_PLANS]: 'Payout Plans',
  [SUBSCRIPTION_SCHEDULES]: 'Subscription Schedules',
  [WEBHOOKS]: 'Webhooks',
  [COMPLIANCE_FORMS]: 'Compliance Forms',
  [COMPLIANCE_FORM_TEMPLATES]: 'Compliance Form Templates',
}

export const SPECIAL_ROUTES = {
  [MERCHANTS]: (pathname) => [ ...pathname.slice(0, 2), MERCHANTS].join('/'),
  [PROCESSORS]: (pathname) => `${pathname.slice(0, 4).join('/')}?tab=processors`,
  [ENTRIES]: (pathname) => `${pathname.slice(0, 4).join('/')}?tab=entries`,
  [SUBSCRIPTION_SCHEDULES]: (pathname) => `${pathname.slice(0, 2).join('/')}/subscription-billing?tab=subscription-schedules`,
  [COMPLIANCE_FORMS]: (pathname) => `${pathname.slice(0, 2).join('/')}/compliance/?tab=forms`,
  [COMPLIANCE_FORM_TEMPLATES]: (pathname) => `${pathname.slice(0, 2).join('/')}/compliance/?tab=form-templates`,
  [WEBHOOKS]: (pathname) => {
    const state = getState()
    const credentials = getCurrentCredentials(state)
    const webhook = getWebhookSelector(state, get(pathname, '[3]'))
    const applicationId = get(webhook, 'applicationId')

    return isRolePartner({ credentials })
      ? `${pathname.slice(0, 2).join('/')}/settings?tab=setting-webhooks`
      : `${pathname.slice(0, 2).join('/')}/applications/${applicationId}?tab=webhooks`
  },
  [REPORTS]: () => {
    const state = getState()
    const credentialId = getCurrentCredentialsId(state)
    return REPORTS_HOME_PATH({ credentialId })
  },
}

// everything else is protected and requires a proper session
export const UNPROTECTED_PATHS = [
  '/', // TODO: switch with basename from env config
  LOGIN_PATH,
  LOGOUT_PATH,
  MERCHANT_ONBOARDING_PATH,
  SELF_SERVICE_SIGNUP_PATH,
  VERIFY_EMAIL_PATH,
  VERIFY_EMAIL_PENDING_PATH,
  `/${LOGIN_PATH}`,
  `/${MERCHANT_ONBOARDING_PATH}`,
  CUSTOMER_ONBOARDING_PATH,
  `/${CUSTOMER_ONBOARDING_PATH}`,
  CUSTOMER_ONBOARDING_SUCCESS_PATH,
  GUEST_PAYMENT_LINK,
  GUEST_PAYOUT_LINK,
  GUEST_CHECKOUT_FORM,
  GUEST_PAYMENT_LINK_CONFIRMATION,
  GUEST_PAYOUT_LINK_CONFIRMATION,
]

export const UNPROTECTED_PUBLIC_PATHS = [
  MERCHANT_ONBOARDING_PATH,
  SELF_SERVICE_SIGNUP_PATH,
  VERIFY_EMAIL_PATH,
  VERIFY_EMAIL_PENDING_PATH,
  `/${MERCHANT_ONBOARDING_PATH}`,
  CUSTOMER_ONBOARDING_PATH,
  `/${CUSTOMER_ONBOARDING_PATH}`,
  CUSTOMER_ONBOARDING_SUCCESS_PATH,
  GUEST_PAYMENT_LINK,
  GUEST_PAYOUT_LINK,
  GUEST_CHECKOUT_FORM,
  GUEST_PAYMENT_LINK_CONFIRMATION,
  GUEST_PAYOUT_LINK_CONFIRMATION,
]
