import ApplicationInfo from './ApplicationInfo'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import Address from 'components/Customer/Shared/Display/Address/Address'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isAdminOrClientAdmin from 'utilities/is/isAdminOrClientAdmin'
import showModalAction from 'utilities/actions/showModalAction'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import getMany from 'utilities/get/getMany'
import formatRiskProfile from 'utilities/formatters/formatRiskProfile'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import { PATCH_APPLICATION_F_REQUEST } from 'constants/flowConstants'
import { settlementFundingIdentifierOptionsMapping } from 'constants/platformConstants'
import { PROCESSOR_LABEL_MAP } from 'constants/processorConstants'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import isPlatform from 'utilities/is/isPlatform'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  EDIT_ASSOCIATED_IDENTITY_PATH,
  EDIT_IDENTITY_PATH_NEW,
} from 'constants/pathConstants'

import {
  getApplicationSelector,
  getIdentitySelector,
} from 'state-layer/selectors'

import {
  EDIT_APPLICATION_SETTING,
  EDIT_RISK_PROFILE,
  INVITE_USER,
  SEARCH_USER,
} from 'constants/modalConstants'

import {
  ENVELOPE_LIGHT,
} from 'constants/iconConstants'

import {
  EDIT,
  ENABLED,
  DISABLED,
  RESEND_EMAIL,
  CARD_EXPIRATION_DATE_REQUIRED,
  CARD_CVV_REQUIRED,
  DEPOSIT_STATEMENT_DESCRIPTOR,
  CREATING_TRANSFER_ENABLED,
  BUSINESS_ADDRESS,
  EMAIL,
  PHONE,
  ADDRESS,
  DATE_OF_BIRTH,
  INDIVIDUAL_TAX_ID_PROVIDED,
  NO_BUSINESS_PRINCIPALS,
  BUSINESS_PHONE,
  WEBSITE,
  BUSINESS_TAX_ID_PROVIDED,
  OWNERSHIP_TYPE,
  INCORPORATION_DATE,
  BUSINESS_TYPE,
  DOING_BUSINESS_AS,
  BUSINESS_NAME,
  ANNUAL_CARD_VOLUME,
  PREVIOUSLY_ACCEPTED_CREDIT_CARDS,
  DEFAULT_STATEMENT_DESCRIPTOR,
  SETTLEMENTS,
  PAYMENT_PROCESSING,
  AVERAGE_CARD_TRANSFER_AMOUNT,
} from 'constants/language/languageConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    showApplicationSettingModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_APPLICATION_SETTING, modalProps })),
    showInviteUserModal: (modalProps) => dispatch(showModalAction({ modalType: INVITE_USER, modalProps })),
    showSearchUserModal: (modalProps) => dispatch(showModalAction({ modalType: SEARCH_USER, modalProps })),
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    showRiskProfileModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_RISK_PROFILE, modalProps })),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const applicationId = get(props, 'applicationId')
  const isPayout = get(props, 'isPayout')
  const isDisbursementsApplication = get(props, 'isDisbursementsApplication')
  const application = getApplicationSelector(state, applicationId)
  const platformId = get(props, 'platformId')
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `applicationsR.${FETCHING}`)
  const isElavon = isPlatform('elavonpartnerconnect')
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()

  const [
    processing,
    settlementsEnabledLabel,
    creatingTransferEnabled,
    cardExpirationDateRequired,
    cardCvvRequired,
    tags,
    settlementFundingIdentifier,
    ownerIdentity = {},
    processors,
    associatedIdentities = [],
    actOnDecisions = false,
    riskProfile,
  ] = getMany(application, [
    'processing',
    'settlementsEnabledLabel',
    'creatingTransferEnabled',
    'cardExpirationDateRequired',
    'cardCvvRequired',
    'tags',
    'settlementFundingIdentifier',
    'ownerIdentity',
    'processors',
    'associatedIdentities',
    'underwritingDecisionSettings.actOnDecisions',
    'riskProfile',
  ])

  const { id: identityId } = ownerIdentity
  const identityRecord = getIdentitySelector(state, identityId)
  const identity = !isEmpty(identityRecord) ? identityRecord : ownerIdentity
  const owners = [identity, ...associatedIdentities]

  const {
    businessName,
    doingBusinessAs,
    website,
    displayBusinessPhone,
    businessTaxIdProvided = false,
    businessAddress,
    displayIncorporationDate,
    displayBusinessType,
    displayAnnualCardVolume,
    defaultStatementDescriptor,
    displayOwnershipType,
    hasAcceptedCreditCardsPreviously,
    businessDescription,
  } = identity

  const businessDescriptionString = businessDescription ? `Description: ${businessDescription}` : 'Description: N/A'

  const processorsEnabled = map(processors, ({ processor = '', enabled = false }) => {
    return {
      label: `${get(PROCESSOR_LABEL_MAP, processor, processor)} Processor`,
      value: enabled ? ENABLED : DISABLED,
      formatter: ({ value }) => <EnabledStatus value={value} />,
    }
  })

  const processingDetailsSectionData = [
    ...processorsEnabled,
    {
      label: PAYMENT_PROCESSING,
      value: processing,
      formatter: ({ value }) => <EnabledStatus value={value} />,
    },
    {
      label: SETTLEMENTS,
      value: settlementsEnabledLabel,
      formatter: ({ value }) => <EnabledStatus value={value} />,
      condition: !isPayout,
    },
    {
      label: CREATING_TRANSFER_ENABLED,
      value: creatingTransferEnabled ? ENABLED : DISABLED,
      formatter: ({ value }) => <EnabledStatus value={value} />,
      condition: !isPayout,
    },
    // TODO: commented out 7/21 - uncomment again once backend EP is properly migrated to new engine (Sharath is BE POC)
    // {
    //   label: AUTOMATED_UNDERWRITING,
    //   value: actOnDecisions ? ENABLED : DISABLED,
    //   formatter: ({ value }) => <EnabledStatus value={value} />,
    // },
    {
      label: DEPOSIT_STATEMENT_DESCRIPTOR,
      value: settlementFundingIdentifierOptionsMapping[settlementFundingIdentifier],
      condition: !isPayout,
    },
    {
      label: DEFAULT_STATEMENT_DESCRIPTOR,
      value: defaultStatementDescriptor,
    },
    {
      label: CARD_CVV_REQUIRED,
      value: cardCvvRequired ? 'Yes' : 'No',
      condition: !isPayout,
    },
    {
      label: CARD_EXPIRATION_DATE_REQUIRED,
      value: cardExpirationDateRequired ? 'Yes' : 'No',
      condition: !isPayout,
    },
    {
      label: PREVIOUSLY_ACCEPTED_CREDIT_CARDS,
      value: hasAcceptedCreditCardsPreviously,
    },
    {
      label: AVERAGE_CARD_TRANSFER_AMOUNT,
      value: 'Unknown',
    },
    {
      label: ANNUAL_CARD_VOLUME,
      value: displayAnnualCardVolume,
    },
  ]

  const businessInfoSectionData = [
    {
      label: BUSINESS_NAME,
      value: businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
    },
    {
      label: BUSINESS_TYPE,
      value: displayBusinessType,
    },
    {
      label: INCORPORATION_DATE,
      value: displayIncorporationDate,
    },
    {
      label: OWNERSHIP_TYPE,
      value: displayOwnershipType,
    },
    {
      label: BUSINESS_TAX_ID_PROVIDED,
      value: businessTaxIdProvided,
    },
    {
      label: WEBSITE,
      value: validateWebsiteLink({ website }) ? <a className='text-link' href={website} target='blank'>{website}</a> : website,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
  ]

  const businessPrincipalsDataCards = map(owners, (owner, index) => {
    const [
      id,
      title,
      firstName,
      lastName,
      middleName,
      displayPrincipalPercentageOwnership,
      taxIdProvided,
      displayDateOfBirth,
      displayPhone,
      email,
      personalAddress,
    ] = getMany(owner, [
      'id',
      'title',
      'firstName',
      'lastName',
      'middleName',
      'principalPercentageOwnership',
      'taxIdProvided',
      'displayDateOfBirth',
      'displayPhone',
      'email',
      'personalAddress',
    ])

    const nameData = (!firstName && !lastName) ? NO_BUSINESS_PRINCIPALS : isElavon ? `${firstName} ${middleName} ${lastName} ` : `${firstName} ${lastName} `
    const titleJSX = title ? `${title}: ` : ''
    const principalOwnershipTitle = `(${titleJSX}${displayPrincipalPercentageOwnership}% Ownership)`
    const editPath = index === 0 ? EDIT_IDENTITY_PATH_NEW({ credentialId, identityId: id }) : EDIT_ASSOCIATED_IDENTITY_PATH({ credentialId, identityId: id })

    return {
      largeTitle: <div>{nameData} <span className='large-title-aside'>{principalOwnershipTitle}</span></div>,
      headerActions: [
        {
          label: EDIT,
          onClick: () => goToPath({ pathname: editPath }),
        },
      ],
      data: [
        {
          label: EMAIL,
          value: email,
        },
        {
          label: PHONE,
          value: displayPhone,
        },
        {
          label: ADDRESS,
          value: <Address address={personalAddress} />,
        },
        {
          label: DATE_OF_BIRTH,
          value: displayDateOfBirth,
        },
        {
          label: INDIVIDUAL_TAX_ID_PROVIDED,
          value: taxIdProvided,
        },
      ],
    }
  })

  const businessInfoActions = [
    {
      label: 'Edit',
      link: EDIT_IDENTITY_PATH_NEW({ credentialId, identityId: get(identity, 'id') }),
      condition: get(identity, 'id') ? true : false,
    },
  ]

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const riskProfileSectionData = formatRiskProfile(riskProfile)

  return {
    applicationId,
    application,
    processingDetailsSectionData,
    businessInfoSectionData,
    businessInfoActions,
    credentials,
    isFetching,
    tagsSectionData,
    businessPrincipalsDataCards,
    riskProfile,
    riskProfileSectionData,
    platformId,
    businessDescriptionString,
    isFlex: isFlexPlatform(),
    isStandaloneUnderwriting,
    processors,
    isDisbursementsApplication,
  }
}

class ApplicationInfoC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplication,
    } = this.props

    getApplication({ credentials, applicationId })
  }

  render() {
    const {
      showApplicationSettingModal,
      showInviteUserModal,
      showSearchUserModal,
      showRiskProfileModal,
      application,
      credentials,
      applicationId,
      riskProfile,
    } = this.props

    const actions = [
      // deprecated in favor of User Management Feature
      // {
      //   label: INVITE_USER_LABEL,
      //   buttonClassName: 'invite-user-button',
      //   action: () => {
      //     showInviteUserModal({
      //       applicationId,
      //     })
      //   },
      //   condition: isAdminOrClientAdmin(),
      //   icon: USER_LIGHT,
      // },
      {
        label: RESEND_EMAIL,
        buttonClassName: 'resend-email-button',
        action: () => showSearchUserModal({ applicationId }),
        condition: isAdminOrClientAdmin(),
        icon: ENVELOPE_LIGHT,
      },
    ]

    const processingDetailsActions = [
      {
        label: EDIT,
        buttonClassName: 'edit-application-settings-button',
        action: () => {
          showApplicationSettingModal({
            record: application,
            actionType: PATCH_APPLICATION_F_REQUEST,
            credentials,
          })
        },
      },
    ]

    const riskProfileActions = [
      {
        label: 'Edit',
        action: () => showRiskProfileModal({
          applicationId,
          riskProfile,
          credentials,
        }),
        condition: isRolePlatform({ credentials }),
      },
    ]

    return (
      <ApplicationInfo
        actions={actions}
        processingDetailsActions={processingDetailsActions}
        riskProfileActions={riskProfileActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationInfoC)
