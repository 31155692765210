import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'
import omit from 'lodash/omit'

import {
  SELECT_ITEMS,
  UNSELECT_ITEMS,
  UNSELECT_ALL_ITEMS,
} from 'constants/flowConstants'

const updateItems = (ids, key, checked) => reduce(ids, (total, id) => {
  return merge({}, total, {
    [key]: {
      [id]: checked,
    },
  })
}, {})

// TODO: check for post/patch experiment to clear out selectedItems
const selectedItemsR = (state = {}, action = {}) => {
  const key = get(action, 'payload.key')
  const ids = get(action, 'payload.ids')

  switch (action.type) {
    case SELECT_ITEMS:
      return merge({}, state, updateItems(ids, key, true))

    case UNSELECT_ITEMS:
      return merge({}, state, updateItems(ids, key, false))

    case UNSELECT_ALL_ITEMS:
      return key ? omit(state, key) : {}

    default:
      return state
  }
}

export default selectedItemsR
