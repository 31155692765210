import getMany from 'utilities/get/getMany'
import formatNumber from 'utilities/formatters/formatNumber'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

const frontendUnderwritingDecisionModel = ({ data: decision }) => {
  const [
    id,
    createdAt,
    state,
    recommendation,
    linkTo,
    linkType,
    outcome,
    riskScoreId,
    decisionFormulaId,
    riskScoreFormulaId,
    identityScore,
    complianceScore,
  ] = getMany(decision, [
    'id',
    'created_at',
    'state',
    'recommendation',
    'link_to',
    'link_type',
    'output.outcome',
    'input.risk_score',
    'input.decision_formula',
    'input.risk_score_formula',
    'scores.identity_score',
    'scores.compliance_score',
  ])

  const formattedIdentityScore = formatNumber(identityScore)
  const formattedComplianceScore = formatNumber(complianceScore)

  return {
    id,
    createdAt,
    state,
    recommendation,
    displayRecommendation: snakeCaseToTitleCase({ key: recommendation }),
    linkTo,
    linkType,
    outcome,
    riskScoreId,
    decisionFormulaId,
    riskScoreFormulaId,
    identityScore: formattedIdentityScore,
    complianceScore: formattedComplianceScore,
  }
}

export default frontendUnderwritingDecisionModel
