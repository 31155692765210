import './PayoutPlanFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import validatePayoutPlanForm from 'utilities/validate/validatePayoutPlanForm'
import { PAYOUT_PLAN_FORM } from 'constants/formConstants'

const PayoutPlanForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  availableProcessorsOptions = [],
  buttonGroup = {},
}) => {
  return (
    <form className='PayoutPlanForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h2>Payout Plan</h2>

        <div className='contents'>
          <Field
            name='name'
            type='text'
            component={InputField}
            label='Name'
          />

          <Field
            name='platformId'
            type='text'
            component={InputField}
            label='Platform ID'
          />

          <Field
            name='processor'
            type='text'
            component={SelectField}
            options={availableProcessorsOptions}
            label='Processor'
          />
        </div>
      </div>

      {buttonGroup}
    </form>
  )
}

PayoutPlanForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  availableProcessorsOptions: PropTypes.array,
}

export default reduxForm({
  form: PAYOUT_PLAN_FORM,
  validate: validatePayoutPlanForm,
})(PayoutPlanForm)
