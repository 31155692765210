import './EditDeviceDetailsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitEditDeviceDetailsForm from 'utilities/submit/submitEditDeviceDetailsForm'
import validateEditDeviceDetailsForm from 'utilities/validate/validateEditDeviceDetailsForm'
import { EDIT_DEVICE_DETAILS_FORM } from 'constants/formConstants'

import {
  CANCEL,
  SAVE,
  DEVICE_NAME,
  DESCRIPTION,
  SERIAL_NUMBER,
} from 'constants/language/languageConstants'

const EditDeviceDetailsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  invalid = false,
  canEditSerialNumber = false,
  pristine = false,
  isUpdating = false,
}) => {
  return (
    <form className='EditDeviceDetailsForm' onSubmit={handleSubmit(submitEditDeviceDetailsForm)}>
      <div className='modal-content'>
        <Field
          name='deviceName'
          className='device-name-field'
          label={DEVICE_NAME}
          component={InputField}
        />

        <Field
          name='deviceDescription'
          className='device-description-field'
          label={DESCRIPTION}
          component={InputField}
          required={false}
        />

        <Field
          name='serialNumber'
          label={SERIAL_NUMBER}
          component={InputField}
          disabled={!canEditSerialNumber}
          className='serial-number-field'
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={SAVE} submitting={isUpdating} disabled={invalid || pristine} />
      </div>
    </form>
  )
}

EditDeviceDetailsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  canEditSerialNumber: PropTypes.bool,
  pristine: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_DEVICE_DETAILS_FORM,
  validate: validateEditDeviceDetailsForm,
})(EditDeviceDetailsForm)
