import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTION_PLANS } from 'constants/apiConstants'

const postSubscriptionPlanAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    path: SUBSCRIPTION_PLANS,
    values,
    credentials,
    meta,
  })
}

export default postSubscriptionPlanAPI
