import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  DISPUTES,
  DISPUTE_EVIDENCES,
} from 'constants/apiConstants'

const getDisputeEvidencesAPI = ({ queries, id, credentials, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: DISPUTE_EVIDENCES({ disputeId: id }),
    queries,
    meta,
    service: DISPUTES,
  })
}

export default getDisputeEvidencesAPI
