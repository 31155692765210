import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import TransferAttempt from './TransferAttempt'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getTransferAttemptRequest from 'utilities/actions/get/getTransferAttemptRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  getIdentitySelector,
  getMerchantSelector,
  getPaymentInstrumentSelector,
  getPayoutLinkSelector,
  getTransferAttemptSelector,
} from 'state-layer/selectors'

import {
  DISBURSEMENT_PATH,
  MERCHANT_PATH,
  PAYOUT_LINK_PATH,
  RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  CREATED_ON,
  EXPIRES_ON,
  MERCHANT_ACCOUNT,
  METHOD,
  PAYOUT,
  PAYOUT_ATTEMPT,
  PAYOUT_LINK_NAME,
  RECIPIENT,
  RECIPIENT_IDENTITY,
  RECIPIENT_NAME,
  RECIPIENT_PAYMENT_INSTRUMENT,
  STATE,
  STATUS,
  TOTAL_AMOUNT,
  VIEW_PAYOUT_LINK,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingTransferAttempts = get(state, `transferAttemptsR.${FETCHING}`)
  const isFetchingMerchants = get(state, `merchantsR.${FETCHING}`)
  const isFetchingIdentities = get(state, `identitiesR.${FETCHING}`)
  const isFetching = isFetchingTransferAttempts || isFetchingMerchants || isFetchingIdentities
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const transferAttemptId = get(props, 'params.transferAttemptId')
  const transferAttempt = getTransferAttemptSelector(state, transferAttemptId)

  const [
    displayAmount,
    status,
    displayCreatedAt,
    recipientFullName,
    recipientBusinessName,
    recipientMerchantId,
    displayType,
    paymentInstrumentId,
    currency,
    tags,
    payoutLinkId,
    transferId,
  ] = getMany(transferAttempt, [
    'displayAmount',
    'status',
    'displayCreatedAt',
    'recipientFullName',
    'recipientBusinessName',
    'recipientMerchantId',
    'displayType',
    'paymentInstrumentId',
    'currency',
    'tags',
    'entityId',
    'transferId',
  ])

  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const paymentInstrumentType = get(paymentInstrument, 'type')
  const recipientMerchant = getMerchantSelector(state, recipientMerchantId)
  const recipientMerchantBusinessName = get(recipientMerchant, 'displayBusinessName')
  const recipientNameValue = recipientBusinessName || recipientFullName
  const recipientIdentityId = get(recipientMerchant, 'identityId')
  const recipientIdentity = getIdentitySelector(state, recipientIdentityId)
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  const payoutLink = getPayoutLinkSelector(state, payoutLinkId)

  const contextBarData = {
    items: [
      {
        label: RECIPIENT_IDENTITY,
        value: recipientNameValue,
        path: RECIPIENT_PATH({ credentialId, recipientId: recipientIdentityId }),
      },
      {
        label: MERCHANT_ACCOUNT,
        value: recipientMerchantBusinessName,
        path: MERCHANT_PATH({ credentialId, identityId: recipientIdentityId, merchantId: recipientMerchantId }),
      },
      {
        label: PAYOUT,
        value: transferId,
        path: hasPartnerAccess({ credentials }) ? DISBURSEMENT_PATH({ credentialId, transferId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: PAYOUT_ATTEMPT,
      id: transferAttemptId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={status} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: RECIPIENT,
        value: recipientBusinessName || recipientFullName,
      },
      {
        label: METHOD,
        value: displayType,
      },
      {
        label: RECIPIENT_PAYMENT_INSTRUMENT,
        value: displayBuyerPaymentInstrumentLink({
          type: paymentInstrumentType,
          credentialId,
          paymentInstrument,
        }),
      },
    ],
  }

  const [
    payoutLinkRecipientFullName,
    nickname,
    displayCreatedAtPaymentLink,
    displayLinkExpiresAt,
    paymentLinkStatus,
    paymentLinkTotalAmount,
  ] = getMany(payoutLink, [
    'recipientFullName',
    'nickname',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'displayState',
    'displayTotalAmount',
  ])

  const payoutLinkPath = <Link className='text-link' to={PAYOUT_LINK_PATH({ credentialId, payoutLinkId })}>{VIEW_PAYOUT_LINK}</Link>
  const payoutLinkDetailsData = convertPageSectionDataToV2([
    {
      label: RECIPIENT_NAME,
      value: payoutLinkRecipientFullName,
    },
    {
      label: PAYOUT_LINK_NAME,
      value: nickname,
    },
    {
      label: STATUS,
      value: paymentLinkStatus,
    },
    {
      label: TOTAL_AMOUNT,
      value: paymentLinkTotalAmount,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAtPaymentLink,
    },
    {
      label: EXPIRES_ON,
      value: displayLinkExpiresAt,
    },
  ])

  return {
    isFetching,
    credentials,
    transferAttemptId,
    paymentInstrumentId,
    paymentInstrument,
    recipientMerchantId,
    recipientIdentityId,
    recipientIdentity,
    headerData,
    tagsSectionData,
    contextBarData,
    payoutLinkPath,
    payoutLinkDetailsData,
    payoutLinkId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransferAttempt: ({ credentials, transferAttemptId }) => dispatch(getTransferAttemptRequest({ credentials, transferAttemptId })),
    getPaymentInstrument: ({ credentials, paymentInstrumentId }) => dispatch(getPaymentInstrumentRequest({ credentials, paymentInstrumentId })),
    getMerchant: ({ credentials, merchantId }) => dispatch(getMerchantRequest({ credentials, merchantId })),
    getIdentity: ({ credentials, identityId }) => dispatch(getIdentityRequest({ credentials, identityId })),
  }
}

class TransferAttemptC extends Component {
  componentDidMount() {
    const {
      getTransferAttempt,
      getPaymentInstrument,
      credentials,
      transferAttemptId,
      paymentInstrumentId,
      recipientMerchantId,
      getMerchant,
    } = this.props

    getTransferAttempt({ credentials, transferAttemptId })

    if (paymentInstrumentId) {
      getPaymentInstrument({ credentials, paymentInstrumentId })
    }

    if (recipientMerchantId) {
      getMerchant({ credentials, merchantId: recipientMerchantId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      paymentInstrumentId,
      recipientMerchantId,
      recipientIdentityId,
      getPaymentInstrument,
      getIdentity,
      getMerchant,
      credentials,
    } = this.props

    if (paymentInstrumentId && paymentInstrumentId !== prevProps.paymentInstrumentId) {
      getPaymentInstrument({ credentials, paymentInstrumentId })
    }

    if (recipientMerchantId && recipientMerchantId !== prevProps.recipientMerchantId) {
      getMerchant({ credentials, merchantId: recipientMerchantId })
    }

    if (recipientIdentityId && recipientIdentityId !== prevProps.recipientIdentityId) {
      getIdentity({ credentials, identityId: recipientIdentityId })
    }
  }

  render() {
    return (
      <TransferAttempt {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferAttemptC)
