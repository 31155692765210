import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import SendPayoutConfirmation from './SendPayoutConfirmation'
import Address from 'components/Customer/Shared/Display/Address/Address'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import { getCardBrand } from 'constants/bankConstants'
import { ACH_PAYOUT_SPEED_MAP } from 'constants/transferConstants'
import { FETCHING } from 'constants/reducerConstants'
import { PAYMENT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  getPaymentInstrumentSelector,
  getTransferSelector,
} from 'state-layer/selectors'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  COUNTRY,
  EMAIL,
  PHONE,
  NAME,
  ZIP_CODE,
  CARD_NUMBER,
  EXPIRY,
  RECIPIENT_TYPE,
  RECIPIENT_NAME,
  ADDRESS,
  CREATED_VIA,
  DASHBOARD,
  AMOUNT,
  NOTE,
  STATE,
  BUSINESS_RECIPIENT,
  PERSONAL_RECIPIENT,
  TRANSFER_SPEED,
  PAYOUT_ID,
} from 'constants/language/languageConstants'

import {
  BE_BANK_ACCOUNT,
  BE_PAYMENT_CARD,
} from 'constants/paymentInstrumentConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetchingTransferData = get(state, `transfersR.${FETCHING}`)
  const isFetchingMerchantData = get(state, `merchantsR.${FETCHING}`)
  const transferId = get(props, 'params.transferId')
  const transfer = getTransferSelector(state, transferId)

  const [
    id,
    amount,
    note,
    buyerIdentity,
    paymentInstrumentId,
    displayFailureCode,
    failureMessage,
    transferState,
    operationKey,
  ] = getMany(transfer, [
    'id',
    'displayAmountWithCurrencyCode',
    'tags.description',
    'buyerIdentity',
    'paymentInstrumentId',
    'displayFailureCode',
    'failureMessage',
    'state',
    'operationKey',
  ])

  const [
    recipientBusinessName,
    recipientFullName,
    recipientEmail,
    recipientBusinessPhone,
    recipientBusinessAddress,
    recipientPhone,
    recipientPersonalAddress,
  ] = getMany(buyerIdentity, [
    'businessName',
    'fullName',
    'email',
    'displayBusinessPhone',
    'businessAddress',
    'displayPhone',
    'personalAddress',
  ])

  const mappedTransferSpeed = ACH_PAYOUT_SPEED_MAP[operationKey]
  const isBusinessRecipient = !!recipientBusinessName
  const recipientDetailsData = convertPageSectionDataToV2([
    {
      label: RECIPIENT_TYPE,
      value: isBusinessRecipient ? BUSINESS_RECIPIENT : PERSONAL_RECIPIENT,
    },
    {
      label: RECIPIENT_NAME,
      value: isBusinessRecipient ? recipientBusinessName : recipientFullName,
    },
    {
      label: ADDRESS,
      value: isBusinessRecipient ? <Address address={recipientBusinessAddress} /> : <Address address={recipientPersonalAddress} />,
    },
    {
      label: EMAIL,
      value: recipientEmail,
    },
    {
      label: PHONE,
      value: isBusinessRecipient ? recipientBusinessPhone : recipientPhone,
    },
  ], 1)

  const recipientPaymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)

  const [
    name,
    maskedFullCardNumber,
    expirationDate,
    address,
    bankAccountCountry,
    maskedAccountNumber,
    displayAccountType,
    brand,
  ] = getMany(recipientPaymentInstrument, [
    'name',
    'maskedFullCardNumber',
    'expirationDate',
    'address',
    'displayCountry',
    'maskedAccountNumber',
    'displayAccountType',
    'brand',
  ])

  const isBankAccount = get(recipientPaymentInstrument, 'instrumentType') === BE_BANK_ACCOUNT
  const isPaymentCard = get(recipientPaymentInstrument, 'instrumentType') === BE_PAYMENT_CARD
  const cardBrandIcon = getCardBrand(brand)

  const recipientPaymentInstrumentData = convertPageSectionDataToV2([
    {
      label: NAME,
      value: name,
      condition: isBankAccount,
    },
    {
      label: ACCOUNT_TYPE,
      value: displayAccountType,
      condition: isBankAccount,
    },
    {
      label: ACCOUNT_NUMBER,
      value: maskedAccountNumber,
      condition: isBankAccount,
    },
    {
      label: COUNTRY,
      value: bankAccountCountry,
      condition: isBankAccount,
    },
    {
      label: CARD_NUMBER,
      value: <div className='flex items-center'><i className={cardBrandIcon} /><div>&nbsp;{maskedFullCardNumber}</div></div>,
      condition: isPaymentCard,
    },
    {
      label: EXPIRY,
      value: expirationDate,
      condition: isPaymentCard,
    },
    {
      label: ZIP_CODE,
      value: get(address, 'postalCode'),
      condition: isPaymentCard,
    },
    {
      label: COUNTRY,
      value: get(address, 'country'),
      condition: isPaymentCard,
    },
  ], 1)

  const payoutDetailsData = convertPageSectionDataToV2([
    {
      label: CREATED_VIA,
      value: DASHBOARD,
    },
    {
      label: AMOUNT,
      value: amount,
    },
    {
      label: NOTE,
      value: note,
    },
    {
      label: STATE,
      value: <TransferStatus value={transferState} />,
    },
    {
      label: PAYOUT_ID,
      value: <Link className='text-link' to={PAYMENT_PATH({ credentialId, transferId })}>{id}</Link>,
    },
    {
      label: TRANSFER_SPEED,
      value: convertSnakeToSentenceCase(mappedTransferSpeed),
      condition: isBankAccount,
    },
  ], 1)

  return {
    credentials,
    credentialId,
    isFetchingTransferData,
    isFetchingMerchantData,
    transferId,
    paymentInstrumentId,
    displayFailureCode,
    failureMessage,
    recipientDetailsData,
    recipientPaymentInstrumentData,
    payoutDetailsData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    printPage: () => { window.print() },
    getTransfer: ({ transferId, credentials }) => { dispatch(getTransferRequest({ transferId, credentials })) },
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })),
  }
}

class SendPayoutConfirmationC extends Component {
  componentDidMount() {
    const {
      credentials,
      transferId,
      getTransfer,
    } = this.props

    if (transferId) {
      getTransfer({ transferId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      paymentInstrumentId: prevPaymentInstrumentId,
    } = prevProps

    const {
      credentials,
      paymentInstrumentId,
      getPaymentInstrument,
    } = this.props

    if (!prevPaymentInstrumentId && paymentInstrumentId) {
      getPaymentInstrument({ paymentInstrumentId, credentials })
    }
  }

  render() {
    return (
      <SendPayoutConfirmation
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPayoutConfirmationC)
