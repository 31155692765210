import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_ENTRY } from 'constants/apiConstants'
import get from 'lodash/get'

const getBalanceEntryAPI = ({ id, credentials, meta, values }) => {
  const balanceEntryId = id || get(values, 'balanceEntryId')

  return paymentsAPI.get({
    meta,
    path: BALANCE_ENTRY({ balanceEntryId }),
    credentials,
  })
}

export default getBalanceEntryAPI
