import { goToPath } from 'state-layer/history'
import removeUndefined from 'utilities/remove/removeUndefined'
import { DISPUTE_PATH } from 'constants/pathConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import isString from 'lodash/isString'
import get from 'lodash/get'

import {
  POST_DISPUTE_F_REQUEST,
  PATCH_UPDATE_DISPUTE_F_REQUEST,
} from 'constants/flowConstants'

const submitDisputeTransferForm = (values, dispatch, props) => {
  const {
    dispute,
    disputeId,
    transferId,
    credentials,
  } = props

  // TODO: remove reason being sent after BE fixes edge case bug in _update code
  const reason = get(dispute, 'reason')

  const {
    amount,
    caseId,
    reasonCode,
    respondBy,
    state,
    responseState,
    message,
    action,
  } = values

  const respondByDate = isString(respondBy) ? new Date(respondBy) : respondBy

  dispatch({
    type: disputeId ? PATCH_UPDATE_DISPUTE_F_REQUEST : POST_DISPUTE_F_REQUEST,
    payload: removeUndefined({
      id: disputeId,
      values: {
        amount,
        caseId,
        reason,
        reasonCode,
        respondBy: respondByDate ? respondByDate.toISOString() : undefined,
        state,
        responseState,
        message,
        action,
        transferId,
      },
      credentials,
    }),
    meta: {
      successCallback: ({ newValues }) => goToPath({
        pathname: DISPUTE_PATH({
          credentialId: get(credentials, 'id'),
          disputeId: head(keys(newValues)) || disputeId,
        }),
      }),
    },
  })
}

export default submitDisputeTransferForm
