import { CAN } from 'constants/countryConstants'

import {
    ANNUAL_ACH_VOLUME,
    ANNUAL_EFT_VOLUME,
    AVERAGE_ACH_TRANSACTION_AMOUNT,
    AVERAGE_EFT_TRANSACTION_AMOUNT,
    BUSINESS_CHECKING, BUSINESS_CHECKING_ACCOUNT,
    BUSINESS_CHEQUING, BUSINESS_CHEQUING_ACCOUNT,
    CHECKING,
    CHEQUING,
    MAX_ACH_TRANSACTION_AMOUNT_LABEL,
    MAX_EFT_TRANSACTION_AMOUNT_LABEL
} from 'constants/language/languageConstants'

export const annualACHVolumeLabelMap = (country) => {
    switch (country) {
        case CAN:
            return ANNUAL_EFT_VOLUME

        default:
            return ANNUAL_ACH_VOLUME
    }
}

export const averageACHTransferAmountLabelMap = (country) => {
    switch (country) {
        case CAN:
            return AVERAGE_EFT_TRANSACTION_AMOUNT

        default:
            return AVERAGE_ACH_TRANSACTION_AMOUNT
    }
}

export const achMaxTransactionAmountLabelMap = (country) => {
    switch (country) {
        case CAN:
            return MAX_EFT_TRANSACTION_AMOUNT_LABEL

        default:
            return MAX_ACH_TRANSACTION_AMOUNT_LABEL
    }
}

export const businessCheckingLabelMap = (country) => {
    switch (country) {
        case CAN:
            return BUSINESS_CHEQUING

        default:
            return BUSINESS_CHECKING
    }
}

export const businessCheckingAccountLabelMap = (country) => {
    switch (country) {
        case CAN:
            return BUSINESS_CHEQUING_ACCOUNT

        default:
            return BUSINESS_CHECKING_ACCOUNT
    }
}

export const checkingAccountLabelMap = (country) => {
    switch (country) {
        case CAN:
            return 'chequing'

        default:
            return 'checking'
    }
}
