import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues, reduxForm, isInvalid, isPristine } from 'redux-form'
import { getCheckoutFormsSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import GuestPaymentLinkForm from 'components/Customer/Forms/GuestPaymentLinkForm/GuestPaymentLinkForm'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import getGuestCheckoutTokenVerificationRequest from 'utilities/actions/get/getGuestCheckoutTokenVerificationRequest'
import submitGuestCheckoutForm from 'utilities/submit/submitGuestCheckoutForm'
import validateGuestPaymentLinkForm from 'utilities/validate/validateGuestPaymentLinkForm'
import getGuestCheckoutHost from 'utilities/api/getGuestCheckoutHost'
import formatMoney from 'utilities/formatters/formatMoney'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { GET_GUEST_CHECKOUT_FORM_F_REQUEST } from 'constants/flowConstants'
import { GUEST_PAYMENT_LINK_CONFIRMATION } from 'constants/pathConstants'
import { GUEST_PAYMENT_LINK_FORM } from 'constants/formConstants'
import { LOAD_CHECKOUT_PAGE } from 'constants/amplitudeConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import { LIVE_URL } from 'constants/credentialConstants'
import { FAILURE } from 'constants/flashNotificationConstants'
import { USD } from 'constants/currencyConstants'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
  COMPLETED,
  DEACTIVATED,
  EXPIRED,
} from 'constants/paymentLinkConstants'

const mapStateToProps = (state) => {
  const checkoutForms = getCheckoutFormsSelector(state)
  const checkoutFormId = head(Object.keys(checkoutForms))
  const checkoutForm = get(checkoutForms, checkoutFormId, {})
  const currency = get(checkoutForm, 'currency', USD)
  const formValues = getFormValues(GUEST_PAYMENT_LINK_FORM)(state)
  const displayVariableAmount = formatMoney({ amount: get(formValues, 'saleAmount', '0.00'), currency })
  const formIsInvalid = isInvalid(GUEST_PAYMENT_LINK_FORM)(state)
  const formIsPristine = isPristine(GUEST_PAYMENT_LINK_FORM)(state)
  const email = get(formValues, 'email', '')
  const receiptEmails = get(formValues, 'receiptEmails', [])

  return {
    checkoutForm,
    isFetching: isEmpty(checkoutForm),
    displayVariableAmount,
    formIsInvalid,
    formIsPristine,
    initialValues: {
      shippingAddress: {
        country: 'USA',
      },
    },
    email,
    receiptEmails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestCheckoutForm: ({ checkoutFormId, meta }) => {
      dispatch({
        type: GET_GUEST_CHECKOUT_FORM_F_REQUEST,
        payload: {
          id: checkoutFormId,
        },
        meta,
      })
    },
    getHostedFormTokenVerification: ({ meta }) => { dispatch(getGuestCheckoutTokenVerificationRequest({ meta })) },
    updateToken: (token) => { dispatch(change(GUEST_PAYMENT_LINK_FORM, 'token', token)) },
    flashErrorNotification: () => {
      dispatch(flashNotificationAction({
        message: 'There was an error processing your payment. Please check your information and try again.',
        type: FAILURE,
        time: 3000,
      }))
    },
    updateReceiptEmails: ({ email }) => dispatch(change(GUEST_PAYMENT_LINK_FORM, 'receiptEmails', [{ label: email, value: email }])),
  }
}

class GuestCheckoutFormC extends Component {
  state = {
    env: null,
    token: null,
    form: null,
    sessionKey: null,
    formHasErrors: false,
    isProcessingPayment: false,
    showAchWarning: false,
  }

  componentDidMount() {
    const {
      getGuestCheckoutForm,
      getHostedFormTokenVerification,
    } = this.props

    const tokenQuery = getUrlQuery('bearer_token')

    if (tokenQuery) {
      // store bearer token to state
      this.setState({ token: tokenQuery })

      // clear localStorage to ensure hosted payment links hits the correct env
      localStorage.clear()

      // set the temp bearer token for localStorage for verify call
      localStorage.setItem(AUTH_SESSION, JSON.stringify({
        idToken: tokenQuery,
        tokenType: 'Bearer',
      }))

      // verify bearer token from url
      getHostedFormTokenVerification({
        meta: {
          successCallback: ({ newValues: tokenResponse }) => {
            const isValid = get(tokenResponse, 'is_valid')
            const checkoutFormId = get(tokenResponse, 'entity_id')
            const additionalDetails = get(tokenResponse, 'additional_details', {})
            const bearerToken = get(tokenResponse, 'bearer_token')

            // set the returned bearer token to localStorage for further authentication uses
            localStorage.setItem(AUTH_SESSION, JSON.stringify({
              idToken: bearerToken,
              tokenType: 'Bearer',
            }))

            const {
              collect_billing_address: collectBillingAddress,
              collect_email: collectEmail,
              collect_name: collectName,
              collect_phone: collectPhone,
              collect_shipping_address: collectShippingAddress,
              expiration_in_minutes: expirationInMinutes,
              generate_receipt: generateReceipt,
            } = additionalDetails

            if (!isValid) {
              // redirect to the supplied invalid link
              goToPath({
                pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
                queries: {
                  status: 'expired',
                },
              })

              return
            }

            getGuestCheckoutForm({
              checkoutFormId,
              meta: {
                actionId: sendAmplitudeActionEvent(LOAD_CHECKOUT_PAGE, {
                  checkoutFormId,
                  collectEmail,
                  collectName,
                  collectPhone,
                  collectBillingAddress,
                  collectShippingAddress,
                  expirationInMinutes,
                  generateReceipt,
                }),
              },
            })
          },
          errorCallback: () => {
            // there was an error validating the token, take to some error page
            goToPath({
              pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
              queries: {
                status: 'expired',
              },
            })
          },
        },
      })
    } else {
      // no token, go to expired page for now? Check with Oscar on how this should work
      goToPath({
        pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
        queries: {
          status: 'expired',
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkoutForm } = this.props
    const { checkoutForm: prevCheckoutForm } = prevProps

    const { form } = this.state

    if (isEmpty(prevCheckoutForm) && !isEmpty(checkoutForm)) {
      const checkoutFormState = get(checkoutForm, 'state')

      if (includes([COMPLETED, EXPIRED, DEACTIVATED], checkoutFormState)) {
        window.location.href = get(checkoutForm, 'expiredSessionUrl')
      } else {
        const {
          collectBillingAddress = false,
          allowedPaymentMethods = [],
          merchantId,
        } = checkoutForm

        const env = getGuestCheckoutHost() === LIVE_URL ? 'live' : 'sandbox'
        this.setState({ env })

        window.Finix.Auth(env, merchantId, (sessionKey) => this.setState({ sessionKey }))

        const tokenFormOptions = {
          showAddress: true,
          hideFields: collectBillingAddress ? [] : ['address_line1', 'address_line2', 'address_city', 'address_state', 'address_region'],
          requiredFields: collectBillingAddress ? ['name', 'address_line1', 'address_city', 'address_region', 'address_postal_code', 'address_country'] : ['name', 'address_postal_code', 'address_country'],
          styles: {
            default: {
              color: '#000',
              border: '1px solid #7D90A5',
              borderRadius: '4px',
              padding: '8px 16px',
              fontFamily: 'Helvetica',
              fontSize: '16px',
            },
          },
          onUpdate: this.onUpdate,
        }

        document.querySelector('.pay-button').addEventListener('click', this.submitTokenForm)

        let formFunction = 'TokenForm'
        if (allowedPaymentMethods.length === 1 && allowedPaymentMethods.includes(PAYMENT_CARD)) formFunction = 'CardTokenForm'
        if (allowedPaymentMethods.length === 1 && allowedPaymentMethods.includes(BANK_ACCOUNT)) formFunction = 'BankTokenForm'

        if (!form && window.Finix && window.Finix.TokenForm) {
          this.setState({ form: window.Finix[formFunction]('payment-instrument-form', tokenFormOptions) })
        }
      }
    }
  }

  onUpdate = (state, bin, hasErrors) => {
    this.setState({ showAchWarning: !!document.querySelector('.field-holder.account_number') })
    this.setState({ formHasErrors: hasErrors })
  }

  initializeReceiptEmails = () => {
    const {
      email,
      receiptEmails,
      updateReceiptEmails,
    } = this.props

    if (isEmpty(receiptEmails) && email) {
      updateReceiptEmails({ email })
    }
  }

  submitTokenForm = () => {
    const {
      env,
      form,
    } = this.state

    const {
      checkoutForm,
      handleSubmit,
      updateToken,
      flashErrorNotification,
    } = this.props

    const applicationId = get(checkoutForm, 'applicationId')

    this.setState({ isProcessingPayment: true })

    form.submit(env, applicationId, (err, res) => {
      const tokenData = get(res, 'data', {})
      const { id } = tokenData

      if (id) {
        updateToken(id)
        handleSubmit(submitGuestCheckoutForm)()
      } else {
        this.setState({ isProcessingPayment: false })
        flashErrorNotification()
      }
    })
  }

  render() {
    const {
      token,
      sessionKey,
      formHasErrors,
      isProcessingPayment,
      showAchWarning,
    } = this.state

    return (
      <GuestPaymentLinkForm
        {...this.props}
        token={token}
        sessionKey={sessionKey}
        formHasErrors={formHasErrors}
        isProcessingPayment={isProcessingPayment}
        showAchWarning={showAchWarning}
        initializeReceiptEmails={this.initializeReceiptEmails}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: GUEST_PAYMENT_LINK_FORM,
    validate: validateGuestPaymentLinkForm,
  })(GuestCheckoutFormC),
)
