import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import Button from 'components/Shared/Button/Button'
import submitCreateSubscriptionForm from 'utilities/submit/submitCreateSubscriptionForm'
import get from 'lodash/get'
import map from 'lodash/map'
import slice from 'lodash/slice'
import split from 'lodash/split'
import { SUBMIT } from 'constants/language/languageConstants'

const CreateSubscriptionConfirmation = ({
  credentials,
  dispatch,
  goToStep = () => {},
  steps = [],
  formValues = {},
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  merchantId,
  activeStep,
}) => {
  const inputSteps = slice(steps, 0, -1)
  const createSubscriptionSchedule = () => { submitCreateSubscriptionForm(formValues, dispatch, { credentials, merchantId }) }

  return (
    <div className='CreateSubscriptionConfirmation confirmation'>
      { map(inputSteps, (step, idx) => {
        const { stepTitle, dataKey, getDisplayData } = step
        const data = get(formValues, dataKey, {})
        const displayData = getDisplayData ? getDisplayData(data) : map(data, (value, label) => ({ value, label }))

        const actions = [
          {
            label: 'Edit',
            action: () => { goToStep(idx, null, true) },
          },
        ]

        return (
          <PageSection
            title={stepTitle}
            actions={actions}
            data={displayData}
            key={split(stepTitle, ' ').join('-')}
          />
        )
      })}

      <div className='buttons flex space-between items-center'>
        <div className='left flex flex-start'>
          { leftButton && <Button variant='secondary' onClick={leftButtonAction} label={leftButtonLabel} /> }
        </div>

        <div className='progress-string'>{`${activeStep + 1} of ${steps.length}`}</div>

        <div className='right flex flex-end'>
          <Button type='submit' label={SUBMIT} onClick={createSubscriptionSchedule} />
        </div>
      </div>
    </div>
  )
}

CreateSubscriptionConfirmation.propTypes = {
  credentials: PropTypes.object,
  dispatch: PropTypes.func,
  goToStep: PropTypes.func,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  merchantId: PropTypes.string,
  activeStep: PropTypes.number,
}

export default CreateSubscriptionConfirmation
