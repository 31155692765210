import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSettingsModel = ({ data: settings }) => {
  const settingsModels = map(settings, (setting) => frontendIdentityModel({ data: setting }))

  return keyBy(settingsModels, 'id')
}

export default frontendSettingsModel
