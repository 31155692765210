import './ContactSupportEmailS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericEmailFormC from 'components/Admin/Forms/GenericEmailForm/GenericEmailFormC'
import { CONTACT_SUPPORT } from 'constants/language/languageConstants'

const ContactSupportEmail = ({
  isFetching = false,
  contactSupportEmail = {},
}) => {
  return (
    <div className='ContactSupportEmail'>
      <GenericEmailFormC
        isFetching={isFetching}
        email={contactSupportEmail}
        emailName={CONTACT_SUPPORT}
      />
    </div>
  )
}

ContactSupportEmail.propTypes = {
  isFetching: PropTypes.bool,
  contactSupportEmail: PropTypes.object,
}

export default ContactSupportEmail
