import React, { Component } from 'react'
import { connect } from 'react-redux'
import ExitApplicationForm from './ExitApplicationForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import redirectRequest from 'utilities/actions/redirectRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { COMPANY_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => { dispatch(hideModalAction()) },
    redirectUser: ({ credentialId }) => { dispatch(redirectRequest({ path: COMPANY_PATH({ credentialId }) })) },
  }
}

class ExitApplicationFormC extends Component {
  redirectUserToHome = () => {
    const { closeModal, redirectUser, credentialId } = this.props
    redirectUser({ credentialId })
    closeModal()
  }

  render() {
    return (
      <ExitApplicationForm
        redirectUserToHome={this.redirectUserToHome}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitApplicationFormC)
