import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_EXPORTS } from 'constants/apiConstants'

const getExportsAPI = ({ credentials }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_SERVICE_EXPORTS,
  })
}

export default getExportsAPI
