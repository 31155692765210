import { fieldEmpty } from 'utilities/validate'

const validateEditUnderwritingProfileForm = ({
  allowAllMCCs,
  merchantCategoryCodes,
  maxTransactionAmount,
  achMaxTransactionAmount,
}) => {
  return {
    merchantCategoryCodes: !allowAllMCCs ? fieldEmpty(merchantCategoryCodes, 'Merchant Category Codes') : '',
    maxTransactionAmount: fieldEmpty(maxTransactionAmount, 'Max Card transaction amount'),
    achMaxTransactionAmount: fieldEmpty(achMaxTransactionAmount, 'Max ACH transaction amount'),
  }
}
export default validateEditUnderwritingProfileForm
