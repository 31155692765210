import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/MerchantDeposits/columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { GET_TRANSFERS, TRANSFER } from 'constants/flowConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const ApplicationMerchantDeposits = ({
  merchantDepositsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters = [],
  applicationId,
}) => {
  return (
    <div className='ApplicationMerchantDeposits'>
      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={merchantDepositsPath}
        isFetching={isFetching}
        fileNameCSV={`Application_${applicationId}_Merchant_Deposits`}
        entityType={TRANSFER}
        icon={`fa fa-${PAYMENTS_ICON}`}
        emptyMessage={MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
        showAdvancedExport
      />
    </div>
  )
}

ApplicationMerchantDeposits.propTypes = {
  merchantDepositsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  applicationId: PropTypes.string,
}

export default ApplicationMerchantDeposits
