import './WebhookLogsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getWebhookLogsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { WEBHOOK_LOGS } from 'constants/linkConstants'
import { GET_WEBHOOK_LOGS } from 'constants/flowConstants'

import {
  WEBHOOK_LOGS_BANNER_MSG,
  WEBHOOK_LOGS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const WebhookLogs = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  webhookPath = '',
  isFetching = false,
  quickFilters,
}) => {
  return (
    <div className='WebhookLogs'>
      <div className='banner'>{WEBHOOK_LOGS_BANNER_MSG}</div>

      <TableC
        flow={GET_WEBHOOK_LOGS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getWebhookLogsSelector}
        initialQueries={initialQueries}
        linksKey={WEBHOOK_LOGS}
        path={webhookPath}
        isFetching={isFetching}
        quickFilters={quickFilters}
        allowedFilters={allowedFilters}
        emptyMessage={WEBHOOK_LOGS_EMPTY_TABLE_MESSAGE}
        fileNameCSV='Webhook_Logs'
      />
    </div>
  )
}

WebhookLogs.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  webhookPath: PropTypes.string,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
}

export default WebhookLogs
