import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'

// rule types
export const TIME_RANGE = 'TIME_RANGE'
export const GLOBAL = 'GLOBAL'

export const filterOutGlobalType = (rules) =>  filter(rules, ({ type }) => !isEqual(type, GLOBAL))
export const filterByGlobalType = (rules) =>  filter(rules, ({ type }) => isEqual(type, GLOBAL))

export const maxTransactionAmountKeyMapping = {
    application: {
        Card: 'applicationCardRulesMaxTransactionAmount',
        ACH: 'applicationACHRulesMaxTransactionAmount',
    },
    recipient: {
        Card: 'recipientCardRulesMaxTransactionAmount',
        ACH: 'recipientACHRulesMaxTransactionAmount',
    }
}
