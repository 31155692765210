import { EMPTY_DATA_SECTION } from 'constants/pageSectionContants'
import map from 'lodash/map'
import slice from 'lodash/slice'
import size from 'lodash/size'
import filter from 'lodash/filter'

// This function will take an array of data and set number of columns and will split the data as evenly as possible into those columns.
// Normally splitting data into N columns will leave the last column the most depleted.
// Ex: 17 / 5 -> [4][4][4][4][1] - when design actually wants [4][4][3][3][3]
// The main difference is filling out rows before columns, but that becomes not as easy when the data
// is stored as a list of columns, rather than rows (meaning top/down rather than left/right)
// Example of how it works now - 17 / 5 = 3 remainder 2 [3][3][3][3][3] + [1][1]
// Add the remainders to the first columns while we have remainders left to share
// [3+1][3+1][3][3][3] => [4][4][3][3][3]
const convertPageSectionDataToV2 = (dataArr = [], columns = 3) => {
  const filteredDataArr = filter(dataArr, ({ condition }) => condition !== false)

  const chunks = []
  const len = filteredDataArr.length
  const floor = Math.floor(len / columns)
  // we are going to rake the remainder and spread them across the first set of columns
  let remainder = len % columns
  let index = 0

  while (index < len) {
    // if we have remainders left to share, add them to the column
    const chunkSize = remainder > 0 ? floor + 1 : floor
    chunks.push(slice(filteredDataArr, index, index + chunkSize))
    index += chunkSize

    // reduce remainders by 1 until there are no more
    if (remainder > 0) {
      remainder -= 1
    }
  }

  while (size(chunks) < columns) {
    chunks.push(EMPTY_DATA_SECTION)
  }

  return map(chunks, (data) => {
    return {
      type: 'data',
      data,
    }
  })
}

export default convertPageSectionDataToV2
