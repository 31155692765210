import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import { APPLICATIONS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  APPLICATION_ONBOARDING_FORMS_SUB_TITLE,
  APPLICATIONS,
} from 'constants/language/languageConstants'

const ApplicationTabs = ({
  tabs = [],
}) => {
  return (
    <div className='ApplicationTabs'>
      <HeaderV2C
        title={APPLICATIONS}
        subTitle={APPLICATION_ONBOARDING_FORMS_SUB_TITLE}
        learnMoreLink={APPLICATIONS_LEARN_MORE_LINK}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

ApplicationTabs.propTypes = {
  tabs: PropTypes.array,
}

export default ApplicationTabs
