import frontendPlatformProcessorConfigModel from 'utilities/create/models/frontendPlatformProcessorConfigModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPlatformProcessorConfigsModel = ({ data: processorConfigs }) => {
  const platformsModels = map(processorConfigs, (processorConfig) => frontendPlatformProcessorConfigModel({ data: processorConfig }))

  return keyBy(platformsModels, 'id')
}

export default frontendPlatformProcessorConfigsModel
