import removeUndefined from 'utilities/remove/removeUndefined'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'

const frontendUserModel = ({ data: user }) => {
  const dashboardServiceV2 = isDashboardServiceV2()

  const [
    id,
    email,
    createdAt,
    firstName,
    lastName,
    mfaEnabled,
    mfaRequired,
    mfaConfigurations,
  ] = getMany(user, [
    'id',
    dashboardServiceV2 ? 'email_address' : 'attributes.email-address',
    dashboardServiceV2 ? 'created_at' : 'attributes.created-at',
    'first_name',
    'last_name',
    'mfa_enabled',
    'mfa_required',
    'mfa_configurations',
  ])

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : undefined

  return removeUndefined({
    id,
    email,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    firstName,
    lastName,
    fullName,
    mfaEnabled,
    mfaRequired,
    mfaConfigurations,
  })
}

export default frontendUserModel
