import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './Header'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getRouteLocationSelector } from 'state-layer/selectors'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import split from 'lodash/split'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'

import {
  RESOURCE_VIEW_SELECTOR_MAP,
  APPLICATION_PATH,
  IDENTITY_PATH,
  PAYMENT_PATH,
  SETTLEMENT_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

import {
  ADMIN_PLATFORM_ROLES,
  NON_MERCHANT_ROLES,
} from 'constants/roleConstants'

// TODO: look into another way instead of having to map
const STATE_ACTION_NAMES = [
  'showCreateAdjustmentDropdown',
  'showRelatedObjects',
]

const ACTION_NAME_TO_STATE_MAP = {
  'Create Adjustment': 'showCreateAdjustmentDropdown',
  'Manage Subscriptions': 'showManageSubscriptionsDropdown',
  'Related Objects': 'showRelatedObjects',
}

const getRelatedResourceData = ({ isPayout }) => {
  return {
    application: {
      name: 'Application',
      path: (applicationId, credentialId) => APPLICATION_PATH({ applicationId, credentialId }),
      getKey: 'applicationId',
      condition: ({ role }) => includes(ADMIN_PLATFORM_ROLES, role),
    },
    identity: {
      name: 'Identity',
      path: (identityId, credentialId) => IDENTITY_PATH({ identityId, credentialId }),
      getKey: 'identityId',
      condition: ({ role }) => includes(NON_MERCHANT_ROLES, role),
    },
    ownerIdentity: {
      name: 'Owner Identity',
      path: (identityId, credentialId) => IDENTITY_PATH({ identityId, credentialId }),
      getKey: 'ownerIdentityId',
      condition: ({ role }) => includes(NON_MERCHANT_ROLES, role),
    },
    merchantIdentity: {
      name: !isPayout ? 'Merchant Identity' : 'Recipient Identity',
      path: (identityId, credentialId) => IDENTITY_PATH({ identityId, credentialId }),
      getKey: 'merchantIdentityId',
      condition: ({ role }) => includes(NON_MERCHANT_ROLES, role),
    },
    capturedTransfer: {
      name: 'Capture Transfer',
      path: (transferId, credentialId) => PAYMENT_PATH({ transferId, credentialId }),
      getKey: 'transfer',
    },
    parentTransfer: {
      name: 'Parent Transfer',
      path: (transferId, credentialId) => PAYMENT_PATH({ transferId, credentialId }),
      getKey: 'parentId',
    },
    settlement: {
      name: 'Settlement',
      path: (settlementId, credentialId) => SETTLEMENT_PATH({ settlementId, credentialId }),
      getKey: 'settlementId',
    },
  }
}

const generateRelatedObjects = (resource, credentials) => {
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const isPayout = isPayoutFeature({ credentials })
  const relatedResourceData = getRelatedResourceData({ isPayout })

  return reduce(relatedResourceData, (total, resourceData, keyName) => {
    const {
      name,
      path,
      getKey,
      condition = () => { return true },
    } = resourceData

    const resourceId = get(resource, getKey)
    const shouldShow = isFunction(condition) ? condition({ role }) : condition === true

    if (resourceId && shouldShow) {
      return merge({}, total, {
        [keyName]: {
          name,
          id: resourceId,
          link: path(resourceId, credentialId),
        },
      })
    }

    return total
  }, {})
}

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const routingInfo = getRouteLocationSelector(state)
  const pathname = get(routingInfo, 'pathname', '')
  const pathnameParts = split(pathname, '/')
  const resourceParts = pathnameParts.slice(2)
  const resourceChunks = chunk(resourceParts, 2)

  const resourceLength = get(resourceChunks, 'length', 0)
  const lastChunk = get(resourceChunks, resourceLength - 1, [])
  const firstResourceName = lastChunk[0]
  const firstResourceId = lastChunk[1]
  const selector = RESOURCE_VIEW_SELECTOR_MAP[firstResourceName]
  const resource = selector ? selector(state, firstResourceId) : undefined
  const relatedObjects = resource ? generateRelatedObjects(resource, credentials) : []

  return {
    credentials,
    relatedObjects,
  }
}

// TODO: remove this component when we have fully migrated to the v2 header system
class HeaderC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentActionName: '',
      showCreateAdjustmentDropdown: false,
      showRelatedObjects: false,
    }
  }

  handleCloseDropdowns = () => {
    map(STATE_ACTION_NAMES, (action) => {
      this.setState({ [action]: false })
    })
  }

  handleCloseOtherDropdowns = (e, name) => {
    const currentActionName = ACTION_NAME_TO_STATE_MAP[name]

    map(STATE_ACTION_NAMES, (action) => {
      if (action !== currentActionName) {
        this.setState({ [action]: false })
      }
    })
  }

  toggleCreateAdjustmentDropdown = (e, name) => {
    const { showCreateAdjustmentDropdown } = this.state

    if (allowedKeyPressControls(e)) {
      this.handleCloseOtherDropdowns(e, name)

      this.setState({
        showCreateAdjustmentDropdown: !showCreateAdjustmentDropdown,
        currentActionName: 'Create Adjustment',
      })
    }
  }

  toggleShowRelatedObjects = (e, name) => {
    const { showRelatedObjects } = this.state

    if (allowedKeyPressControls(e)) {
      this.handleCloseOtherDropdowns(e, name)

      this.setState({
        showRelatedObjects: !showRelatedObjects,
      })
    }
  }

  toggleShowActionsDropdown = (e, name) => {
    switch (name) {
      case 'Create Adjustment':
        return this.toggleCreateAdjustmentDropdown(e, name)

      case 'Related Objects':
        return this.toggleShowRelatedObjects(e, name)

      default:
        return () => {}
    }
  }

  render() {
    const {
      currentActionName,
      showCreateAdjustmentDropdown,
      showRelatedObjects,
    } = this.state

    const actionDropdownMap = {
      'Create Adjustment': showCreateAdjustmentDropdown,
    }

    return (
      <Header
        {...this.props}
        toggleShowActionsDropdown={this.toggleShowActionsDropdown}
        currentActionName={currentActionName}
        showRelatedObjects={showRelatedObjects}
        actionDropdownMap={actionDropdownMap}
        handleCloseDropdowns={this.handleCloseDropdowns}
      />
    )
  }
}

export default connect(mapStateToProps)(HeaderC)
