import './ApplicationSettingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import submitApplicationSettingForm from 'utilities/submit/submitApplicationSettingForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import { APPLICATION_SETTING_FORM } from 'constants/formConstants'
import { SETTLEMENT_FUNDING_IDENTIFIER_OPTIONS } from 'constants/platformConstants'

import {
  APPLICATION,
  AUTOMATED_UNDERWRITING,
  DEPOSIT_STATEMENT_DESCRIPTOR,
  ENABLE_PROCESSING,
  ENABLE_SETTLEMENTS,
} from 'constants/language/languageConstants'

const ApplicationSettingForm = ({
  handleSubmit = () => {},
  submitting = false,
  processingEnabled = false,
  settlementsEnabled = false,
  actOnDecisions = false,
}) => {
  return (
    <form className='ApplicationSettingForm' onSubmit={handleSubmit(submitApplicationSettingForm)}>
      <div className='section'>
        <Field
          name='id'
          label={APPLICATION}
          component={InputField}
          disabled
        />

        <ToggleSwitchC
          name='processingEnabled'
          label={ENABLE_PROCESSING}
          checked={processingEnabled}
          form={APPLICATION_SETTING_FORM}
        />

        <ToggleSwitchC
          name='settlementsEnabled'
          label={ENABLE_SETTLEMENTS}
          checked={settlementsEnabled}
          form={APPLICATION_SETTING_FORM}
        />

        {/* TODO: commented out 7/21 - uncomment again once backend EP is properly migrated to new engine (Sharath is BE POC) */}
        {/* <ToggleSwitchC */}
        {/*   name='actOnDecisions' */}
        {/*   label={AUTOMATED_UNDERWRITING} */}
        {/*   checked={actOnDecisions} */}
        {/*   form={APPLICATION_SETTING_FORM} */}
        {/* /> */}

        <Field
          name='settlementFundingIdentifier'
          label={DEPOSIT_STATEMENT_DESCRIPTOR}
          component={SelectField}
          options={SETTLEMENT_FUNDING_IDENTIFIER_OPTIONS}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label='Submit' submitting={submitting} />
      </div>
    </form>
  )
}

ApplicationSettingForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  processingEnabled: PropTypes.bool,
  settlementsEnabled: PropTypes.bool,
  actOnDecisions: PropTypes.bool,
}

export default ApplicationSettingForm
