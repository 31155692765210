import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CUSTOMIZATION } from 'constants/apiConstants'

const patchDashboardCustomizationAPI = ({ values, id, meta }) => {
  return dashboardAPI.patch({
    path: DASHBOARD_SERVICE_CUSTOMIZATION({ id }),
    meta,
    values,
  })
}

export default patchDashboardCustomizationAPI
