import dashboardAPI from 'utilities/api/dashboardAPI'
import { FILE } from 'constants/apiConstants'

const getFileAPI = ({ id, credentials, meta }) => {
  return dashboardAPI.get({
    meta,
    credentials,
    path: FILE({ fileId: id }),
  })
}

export default getFileAPI
