import { GET_PLATFORM_USER_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPlatformUserRequest = ({
  userId,
  credentials,
}) => createAction({
  type: GET_PLATFORM_USER_F_REQUEST,
  credentials,
  id: userId,
})

export default getPlatformUserRequest
