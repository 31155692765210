import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlertRelatedAlertsPreviewPanel from './MonitoringAlertRelatedAlertsPreviewPanel'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMonitoringAlertRequest from 'utilities/actions/get/getMonitoringAlertRequest'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATION, MERCHANT } from 'constants/flowConstants'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  getMerchantSelector,
  getMonitoringAlertSelector,
} from 'state-layer/selectors'

import {
  IDENTITY_PATH,
  APPLICATION_PATH,
} from 'constants/pathConstants'

import {
  STATUS,
  APPLICATION_RESOURCE_TITLE,
  ALERT,
  LINKED_APPLICATION,
  UPDATED_ON,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  TRANSACTION_MONITORING_ACTUAL_VALUE,
  TRANSACTION_MONITORING_THRESHOLD,
  TRANSACTION_MONITORING_TARGET,
  TRANSACTION_MONITORING_LOOKBACK_START_DATE,
  TRANSACTION_MONITORING_LOOKBACK_END_DATE,
  TRANSACTION_MONITORING_LOOKBACK,
  CADENCE,
} from 'constants/language/languageConstants'

import {
  MONITORING_ALERT_STATUS_ICON_MAP,
  MONITORING_ALERT_STATUS_MAP,
  MONITORING_ALERT_STATUS_TO_DISPLAY_MAP,
} from 'constants/monitoringAlertConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `monitoringAlertsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const monitoringAlertId = get(props, 'resourceId')
  const monitoringAlert = getMonitoringAlertSelector(state, monitoringAlertId)

  const [
    groupedBy,
    linkedName,
    linkedTo,
    linkedType,
    status,
    displayUpdatedAt,
    name,
    description,
    displayCreatedAt,
    actualValue,
    displayThresholdValue,
    displayPeriodStart,
    displayPeriodEnd,
    displayIntervalValue,
    target,
    linkedApplication,
    cadence,
  ] = getMany(monitoringAlert, [
    'groupedBy',
    'linkedName',
    'linkedTo',
    'linkedType',
    'state',
    'displayUpdatedAt',
    'monitoringRuleDetails.name',
    'monitoringRuleDetails.description',
    'displayCreatedAt',
    'displayResult',
    'monitoringRuleDetails.displayThresholdValue',
    'displayPeriodStart',
    'displayPeriodEnd',
    'monitoringRuleDetails.displayIntervalValue',
    'groupedBy',
    'linkedName',
    'monitoringRuleDetails.displayCadenceValue',
  ])

  const monitoringAlertInfoCardData = convertPageSectionDataToV2([
    {
      label: TRANSACTION_MONITORING_ACTUAL_VALUE,
      value: actualValue,
    },
    {
      label: TRANSACTION_MONITORING_THRESHOLD,
      value: displayThresholdValue,
    },
  ], 1)

  const monitoringAlertDetailsCardData = convertPageSectionDataToV2([
    {
      label: TRANSACTION_MONITORING_TARGET,
      value: target,
    },
    {
      label: LINKED_APPLICATION,
      value: linkedApplication,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK_START_DATE,
      value: displayPeriodStart,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK_END_DATE,
      value: displayPeriodEnd,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK,
      value: displayIntervalValue,
    },
    {
      label: CADENCE,
      value: cadence,
    },
  ], 1)

  let merchant
  let merchantIdentity
  let merchantIdentityId

  if (linkedType === MERCHANT) {
    merchant = getMerchantSelector(state, linkedTo)
    merchantIdentity = get(merchant, 'merchantIdentity')
    merchantIdentityId = get(merchantIdentity, 'id')
  }

  const panelContextBarData = [
    {
      label: APPLICATION_RESOURCE_TITLE,
      value: linkedName,
      path: APPLICATION_PATH({ credentialId, applicationId: linkedTo }),
      condition: linkedType === APPLICATION,
    },
    {
      label: MERCHANT_IDENTITY_RESOURCE_TITLE,
      value: linkedName,
      path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
      condition: linkedType === MERCHANT,
    },
  ]

  const headerData = {
    resource: {
      label: ALERT,
      id: monitoringAlertId,
    },
    items: [
      {
        value: <h1>{groupedBy}</h1>,
      },
    ],
    isFetching,
  }

  const secondaryHeaderData = convertPageSectionDataToV2([
    {
      label: LINKED_APPLICATION,
      value: linkedName,
    },
    {
      label: STATUS,
      value: <ColorcodedStatus data={MONITORING_ALERT_STATUS_MAP} value={MONITORING_ALERT_STATUS_TO_DISPLAY_MAP[status]} customIconMap={MONITORING_ALERT_STATUS_ICON_MAP} titleCase={false} />,
    },
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
  ], 1)

  return {
    isFetching,
    credentials,
    panelContextBarData,
    headerData,
    name,
    description,
    displayCreatedAt,
    monitoringAlertInfoCardData,
    monitoringAlertDetailsCardData,
    secondaryHeaderData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMonitoringAlert: ({ monitoringAlertId, credentials }) => dispatch(getMonitoringAlertRequest({ monitoringAlertId, credentials })),
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
  }
}

class MonitoringAlertRelatedAlertsPreviewPanelC extends Component {
  componentDidMount() {
    const {
      credentials,
      monitoringAlertId,
      getMonitoringAlert,
      getMerchant,
      linkedType,
      linkedTo,
    } = this.props

    if (monitoringAlertId) {
      getMonitoringAlert({ monitoringAlertId, credentials })
    }

    if (linkedType === MERCHANT) {
      getMerchant({ merchantId: linkedTo, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      getMerchant,
      linkedType,
      linkedTo,
    } = this.props

    const {
      linkedTo: prevLinkedTo,
    } = prevProps

    if (linkedType === MERCHANT && linkedTo !== prevLinkedTo) {
      getMerchant({ merchantId: linkedTo, credentials })
    }
  }

  render() {
    return (
      <MonitoringAlertRelatedAlertsPreviewPanel {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringAlertRelatedAlertsPreviewPanelC)
