import React from 'react'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import { updatedAtRange, submittedAtRange } from 'utilities/table/sort'
import column from 'utilities/table/column'

import {
  ID,
  BUSINESS_NAME,
  SUBMITTED_ON,
  UPDATED_ON,
  ASSIGNEE,
  UNDERWRITING_STATUS,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(SUBMITTED_ON, 'displaySubmittedOn', {
    className: 'date',
    sort: submittedAtRange,
    headerFormatter: () => <DateTimestampHeader title={SUBMITTED_ON} />,
    formatter: ({ submittedOn }) => <DateTimestampColumn timestamp={submittedOn} />,
  }),
  column(UPDATED_ON, 'displayUpdatedOn', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedOn }) => <DateTimestampColumn timestamp={updatedOn} />,
  }),
  column(BUSINESS_NAME, 'businessName', { className: 'medium' }),
  column(ASSIGNEE, 'assignee', { className: 'medium divider' }),
  column(UNDERWRITING_STATUS, 'state', {
    className: 'medium',
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
]

export default columnDescriptors
