import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerOnboardingForms from './CustomerOnboardingForms'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  CUSTOMER_ONBOARDING_FORM_PATH,
  NEW_CUSTOMER_ONBOARDING_FORM_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `customerOnboardingFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const customerOnboardingFormPath = CUSTOMER_ONBOARDING_FORM_PATH({ credentialId })

  const actions = [
    {
      label: 'Create New Form',
      link: NEW_CUSTOMER_ONBOARDING_FORM_PATH({ credentialId }),
      className: 'createNewCustomerOnboardingForm',
    },
  ]

  return {
    isFetching,
    credentials,
    actions,
    customerOnboardingFormPath,
  }
}

class CustomerOnboardingFormsC extends Component {
  render() {
    return (
      <CustomerOnboardingForms {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CustomerOnboardingFormsC)
