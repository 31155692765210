import { isNumber } from 'utilities/validate/index'
import map from 'lodash/map'
import split from 'lodash/split'
import capitalize from 'lodash/capitalize'
import toString from 'lodash/toString'
import isString from 'lodash/isString'

const snakeCaseToTitleCase = ({ key = '', noFinalCapitalize = false }) => {
  if (!isString(key)) {
    return isNumber(key) ? toString(key) : ''
  }

  const normalizedString = key
    .replace(/^[-_]*(.)/, (_, c) => capitalize(c))
    .replace(/[-_]+(.)/g, (_, c) => ` ${capitalize(c)}`)

  return map(split(normalizedString, ' '), (word) => (noFinalCapitalize ? word : capitalize(word))).join(' ')
}

export default snakeCaseToTitleCase
