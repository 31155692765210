import './APIKeysS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { GET_APPLICATION_USERS } from 'constants/flowConstants'
import { APPLICATION_USERS } from 'constants/linkConstants'
import { getApplicationUsersSelector } from 'state-layer/selectors'

const APIKeys = ({
  flowValues = {},
  isFetching = false,
  applicationId = '',
  actions = [],
  initialQueries,
  flow = '',
  title = '',
  columnDescriptors = [],
}) => {
  return (
    <div className='APIKeys'>
      <TableC
        title={title}
        actions={actions}
        flow={flow}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationUsersSelector}
        selectorId={applicationId}
        linksKey={APPLICATION_USERS}
        initialQueries={initialQueries}
        isFetching={isFetching}
      />
    </div>
  )
}

APIKeys.propTypes = {
  flow: PropTypes.string,
  flowValues: PropTypes.object,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  actions: PropTypes.array,
  initialQueries: PropTypes.object,
  title: PropTypes.string,
  columnDescriptors: PropTypes.array,
}

export default APIKeys
