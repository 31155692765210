import './ReportCardS.scss'
import React from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import downloadReport from 'utilities/downloadReport'
import getMany from 'utilities/get/getMany'
import { TOP_LEVEL_REPORT_TYPE_URL } from 'constants/urlConstants'
import { MAX_REPORTS_DISPLAY } from 'constants/reportingConstants'
import map from 'lodash/map'
import slice from 'lodash/slice'
import isEmpty from 'lodash/isEmpty'
import upperCase from 'lodash/upperCase'
import forEach from 'lodash/forEach'

import {
  MONTHLY,
  DAILY,
  DOWNLOAD_ALL_LABEL,
  LEARN_MORE_LABEL,
} from 'constants/language/languageConstants'

import {
  CIRCLE_DOWNLOAD_ICON,
  CIRCLE_ICON,
  ARROW_DOWN_TO_LINE_ICON,
  ANGLE_RIGHT,
} from 'constants/iconConstants'

const ReportCard = ({
  credentials = {},
  title = '',
  description = '',
  learnMoreLink = TOP_LEVEL_REPORT_TYPE_URL,
  reports = {},
  redirectPath = '',
  goToCategorizedReportsTable = () => {},
}) => {
  // TODO: sort reports by created at to order by latest generated first
  const totalReports = Object.entries(reports).length
  const sortedReports = Object.fromEntries(slice(Object.entries(reports), 0, 5))
  const hasReports = !isEmpty(reports)

  const reportCardClassName = classnames('ReportCard', {
    'flex-grow': totalReports !== 1 && hasReports,
  })

  const downloadAllReports = () => {
    forEach(Object.values(sortedReports), (report) => {
      const reportObj = Object.values(report)[0]

      const [
        id,
        fileName,
      ] = getMany(reportObj, [
        'id',
        'fileName',
      ])

      downloadReport({ id, name: fileName, credentials })
    })
  }

  const openLearnMoreLink = () => {
    window.open(learnMoreLink, '_blank')
  }

  return (
    <Link className={reportCardClassName} to={redirectPath} key={title}>
      <div className='title-area-container flex space-between'>
        <h2 className='report-card-title'>{title}</h2>

        {hasReports && (
        <div className='individual-download-headers flex'>
          <div className='daily-header'>{DAILY}</div>
          <div className='monthly-header'>{MONTHLY}</div>
        </div>
        )}
      </div>

      <div className='report-card-description'>
        <span>{description}</span>

        <span
          className='learn-more-link'
          onClick={(e) => {
            e.preventDefault()
            openLearnMoreLink()
          }}
        >
          {upperCase(LEARN_MORE_LABEL)}
        </span>
      </div>

      {hasReports && <div className='border' />}

      {hasReports && (
      <div className='reports-area-container'>
        {map(sortedReports, (periods, name) => {
          const { monthly, daily } = periods

          const [
            dailyPeriod,
            dailyId,
            dailyName,
          ] = getMany(daily, [
            'displayDailyPeriod',
            'id',
            'fileName',
          ])

          const [
            monthlyPeriod,
            monthlyId,
            monthlyName,
          ] = getMany(monthly, [
            'displayMonthlyPeriod',
            'id',
            'fileName',
          ])


          return (
            <div className='report-row flex space-between items-center'>
              <div className='flex row items-center report-name'>
                <span className={`fa fa-${CIRCLE_ICON}`} />
                <div>{name}</div>
              </div>

              <div className='date-downloads flex space-between'>
                {daily && (
                <div onClick={(e) => {
                  e.preventDefault()
                  downloadReport({ id: dailyId, name: dailyName, credentials })
                }}
                >
                  <span className={`download-icon fal fa-${ARROW_DOWN_TO_LINE_ICON}`} />{upperCase(dailyPeriod)}
                </div>
                )}
                {monthly ? (
                  <div
                    className='monthly'
                    onClick={(e) => {
                      e.preventDefault()
                      downloadReport({ id: monthlyId, name: monthlyName, credentials })
                    }}
                  >
                    <span className={`download-icon fal fa-${ARROW_DOWN_TO_LINE_ICON}`} />{upperCase(monthlyPeriod)}
                  </div>
                ) : <div className='monthly' />}
              </div>
            </div>
          )
        })}

        {(totalReports > MAX_REPORTS_DISPLAY) && (
        <div className='more-reports-area flex items-center' onClick={() => goToCategorizedReportsTable({ path: redirectPath })}>
          <div className='more-reports-text-link'>More reports...</div>
          <span className={`fal fa-${ANGLE_RIGHT}`} />
        </div>
        )}
      </div>
      )}

      {hasReports && (totalReports !== 1) && (
      <div
        className='download-area-container flex items-center'
        onClick={(e) => {
          e.preventDefault()
          downloadAllReports()
        }}
      >
        <i className={`download-all-icon fal fa-${CIRCLE_DOWNLOAD_ICON}`} />
        <div className='download-all-txt'>{upperCase(DOWNLOAD_ALL_LABEL)}</div>
      </div>
      )}
    </Link>
  )
}

ReportCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  learnMoreLink: PropTypes.string,
  reports: PropTypes.object,
  redirectPath: PropTypes.string,
  credentials: PropTypes.object,
  goToCategorizedReportsTable: PropTypes.func,
}

export default ReportCard
