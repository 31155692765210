import './CloseSettlementFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import submitCloseSettlementForm from 'utilities/submit/submitCloseSettlementForm'
import Button from 'components/Shared/Button/Button'
import { reduxForm } from 'redux-form'
import { CLOSE_SETTLEMENT_FORM } from 'constants/formConstants'

const CloseSettlementForm = ({
  handleSubmit = () => {},
  settlementId = '',
  closeModal = () => {},
}) => {
  return (
    <form className='CloseSettlementForm' onSubmit={handleSubmit(submitCloseSettlementForm)}>
      <div>
        <p>This action stops settlement <strong>{settlementId}</strong> from accruing entries and changes its status to <strong>Awaiting Approval</strong>. This action is irreversible.</p>
        <p>Do you want to proceed?</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label='Cancel' variant='ghost' className='cancel-button' />
        <Button type='submit' label='Proceed' />
      </div>
    </form>
  )
}

CloseSettlementForm.propTypes = {
  handleSubmit: PropTypes.func,
  settlementId: PropTypes.string,
  closeModal: PropTypes.func,
}

export default reduxForm({ form: CLOSE_SETTLEMENT_FORM })(CloseSettlementForm)
