import co from 'co'
import patchOnboardingFormDataAPI from 'api/guestDashboard/patch/patchOnboardingFormDataAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import backendOnboardingFormDataModel from 'utilities/create/models/backendOnboardingFormDataModel'
import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import filter from 'lodash/filter'
import merge from 'lodash/merge'
import find from 'lodash/find'

function * patchOnboardingFormDocumentO ({
  id,
  values,
}) {
  const {
    fileId,
    fileName,
    fileType,
    associatedFiles = [],
    removeFile = false,
  } = values

  const otherFiles = filter(associatedFiles, ({ id: associatedFileId }) => associatedFileId !== fileId)
  const currentFile = find(associatedFiles, ({ id: associatedFileId }) => associatedFileId === fileId)

  const updatedFile = merge({}, currentFile, removeUndefined({
    id: fileId,
    display_name: fileName,
    type: fileType,
  }))

  const valuesToSubmit = {
    id,
    associatedFiles: [
      ...otherFiles,
      ...(removeFile ? [] : [updatedFile]),
    ],
  }

  // run through BE model
  const backendModelValuesToSubmit = backendOnboardingFormDataModel({ values: valuesToSubmit })

  const { data: onboardingFormResponse, error: onboardingFormPatchError } = yield patchOnboardingFormDataAPI({
    values: backendModelValuesToSubmit,
  })

  // run response through FE model before returning
  const frontendModelValues = frontendOnboardingFormDataModel({ data: onboardingFormResponse })

  if (onboardingFormPatchError) {
    return createErrorResponse({ error: onboardingFormPatchError })
  }

  return createSuccessResponse({
    data: frontendModelValues,
  })
}

export default co.wrap(patchOnboardingFormDocumentO)
