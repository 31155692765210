import numeral from 'numeral'
import { HAVE_LESS_THAN_23_INTEGERS_REGEX } from 'constants/regexConstants'

import {
  validateTotalPercentage,
  fieldEmpty,
  validateDefaultStatementDescriptor,
  validateMaxAmount,
  nonEmptyNumber,
  invalidRegex,
} from 'utilities/validate'

const validateLiveAccountApplicationProcessingInfoForm = (values) => {
  const {
    processingData = {},
  } = values

  const {
    acceptedPaymentTypes,
    mccCode,
    defaultStatementDescriptor,
    annualTransactionCount,
    annualCardVolume,
    annualACHVolume,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    cardPresentPercentage,
    ecommercePercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
  } = processingData

  const normalizedCardPresentPercentage = cardPresentPercentage ? numeral(cardPresentPercentage).value() : cardPresentPercentage
  const normalizedEcommercePercentage = ecommercePercentage ? numeral(ecommercePercentage).value() : ecommercePercentage
  const normalizedB2BVolumePercentage = businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : businessToBusinessVolumePercentage
  const normalizedB2CVolumePercentage = businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : businessToConsumerVolumePercentage
  const normalizedOtherVolumePercentage = otherVolumePercentage ? numeral(otherVolumePercentage).value() : otherVolumePercentage

  return {
    processingData: {
      acceptedPaymentTypes: fieldEmpty(acceptedPaymentTypes),
      mccCode: fieldEmpty(mccCode),
      defaultStatementDescriptor: validateDefaultStatementDescriptor({ descriptor: defaultStatementDescriptor, name: 'Default Statement Descriptor' }),
      annualTransactionCount: nonEmptyNumber({ field: annualTransactionCount, name: 'Annual Transaction Count' }) || invalidRegex({ field: annualTransactionCount, name: 'Annual Transaction Count', regex: HAVE_LESS_THAN_23_INTEGERS_REGEX }),
      annualCardVolume: nonEmptyNumber({ field: annualCardVolume, name: 'Annual Card Volume' }),
      annualACHVolume: nonEmptyNumber({ field: annualACHVolume, name: 'Annual ACH Volume' }),
      maxCardTransactionAmount: validateMaxAmount({ field: maxCardTransactionAmount, name: 'Max Card Transaction Amount', maxAmount: 500000 }),
      maxACHTransactionAmount: validateMaxAmount({ field: maxACHTransactionAmount, name: 'Max ACH Transaction Amount', maxAmount: 500000 }),
      cardPresentPercentage: validateTotalPercentage({ name: 'Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage], exactAmount: 100 }),
      ecommercePercentage: validateTotalPercentage({ name: 'Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage], exactAmount: 100 }),
      businessToBusinessVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
      businessToConsumerVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
      otherVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
    },
  }
}

export default validateLiveAccountApplicationProcessingInfoForm
