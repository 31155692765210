import co from 'co'
import postAccessFormFileO from 'state-layer/orchestration/post/postAccessFormFileO'
import getAccessFormAdditionalDocumentsO from 'state-layer/orchestration/get/getAccessFormAdditionalDocumentsO'
import patchAccessFormAPI from 'api/dashboard/patch/patchAccessFormAPI'
import patchGuestAccessFormAPI from 'api/guestDashboard/patch/patchGuestAccessFormAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import backendAccessFormModel from 'utilities/create/models/backendAccessFormModel'
import get from 'lodash/get'

function * patchAccessFormAdditionalDocumentsO ({
  credentials,
  id,
  values,
}) {
  const {
    fileType,
    fileName,
    file,
    existingAdditionalDocuments,
    isHostedAccessForm,
  } = values

  const { fileId, error: accessFormFileError } = yield postAccessFormFileO({
    credentials,
    file: get(file, 'file'),
    fileType,
    fileName,
    isGuestAccessForm: isHostedAccessForm,
    id,
  })

  if (accessFormFileError) {
    return createErrorResponse({ error: accessFormFileError })
  }

  const valuesToSubmit = {
    additionalDocuments: [
      ...existingAdditionalDocuments,
      {
        file_id: fileId,
        name: fileName,
      },
    ],
  }

  // run through BE model
  const backendModelValuesToSubmit = backendAccessFormModel({ values: valuesToSubmit })

  if (isHostedAccessForm) {
    yield patchGuestAccessFormAPI({
      id,
      values: backendModelValuesToSubmit,
    })
  } else {
    yield patchAccessFormAPI({
      id,
      credentials,
      values: backendModelValuesToSubmit,
    })
  }

  const { data: accessFormResponse, error: accessFormAdditionalDocumentsError } = yield getAccessFormAdditionalDocumentsO({
    id,
    credentials,
    meta: { isHostedAccessForm },
  })

  if (accessFormAdditionalDocumentsError) {
    return createErrorResponse({ error: accessFormAdditionalDocumentsError })
  }

  return createSuccessResponse({
    data: accessFormResponse,
  })
}

export default co.wrap(patchAccessFormAdditionalDocumentsO)
