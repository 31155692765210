import dashboardAPI from 'utilities/api/dashboardAPI'

import {
  NOTES,
  DRAFT_NOTES,
} from 'constants/apiConstants'

const getDraftNotesAPI = ({ credentials, queries, meta }) => {
  return dashboardAPI.get({
    credentials,
    queries,
    meta,
    path: DRAFT_NOTES,
    service: NOTES,
  })
}

export default getDraftNotesAPI
