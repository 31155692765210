import frontendSubscriptionScheduleModel from 'utilities/create/models/frontendSubscriptionScheduleModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSubscriptionSchedulesModel = ({ data: subscriptionSchedules }) => {
  const subscriptionSchedulesModels = map(subscriptionSchedules, (subscriptionSchedule) => frontendSubscriptionScheduleModel({ data: subscriptionSchedule }))

  return keyBy(subscriptionSchedulesModels, 'id')
}

export default frontendSubscriptionSchedulesModel
