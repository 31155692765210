import React, { Component } from 'react'
import { connect } from 'react-redux'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { ALL } from 'constants/transferConstants'
import { EQUALS } from 'constants/filterConstants'
import get from 'lodash/get'

import {
  ADD_TABLE_FILTER,
  REMOVE_TABLE_FILTER,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const filterName = get(props, 'name')
  const queryValue = getUrlQuery(filterName)
  const tableFilterValue = get(state, `tableFiltersR.${filterName}.eq`)
  const filterValue = tableFilterValue || queryValue

  return {
    filterValue,
    queryValue,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addTableFilter: ({ field, operand, value }) => dispatch({
      type: ADD_TABLE_FILTER,
      payload: {
        field,
        operand,
        value,
      },
    }),
    removeTableFilter: ({ field, operand }) => dispatch({
      type: REMOVE_TABLE_FILTER,
      payload: {
        field,
        operand,
      },
    }),
  }
}

class QuickFilterC extends Component {
  componentDidMount() {
    // TODO: filter persisted from refresh isn't in TableFiltersR
    const {
      name,
      queryValue,
      addTableFilter,
    } = this.props

    if (queryValue) {
      addTableFilter({
        field: name,
        operand: EQUALS,
        value: queryValue,
      })
    }
  }

  handleClick = (value) => {
    const {
      name,
      filterValue,
      addTableFilter,
      removeTableFilter,
    } = this.props

    if (value === filterValue) {
      return
    }

    if (value === ALL) {
      if (filterValue) { // remove filter not dispatched when clicking All when All is already clicked
        removeTableFilter({
          field: name,
          operand: EQUALS,
        })
      }
    } else if (value === filterValue) {
      removeTableFilter({
        field: name,
        operand: EQUALS,
      })
    } else {
      addTableFilter({
        field: name,
        operand: EQUALS,
        value,
      })
    }
  }

  render() {
    const { name, filterValue, QuickFilterComponent } = this.props

    return (
      <div className={`quick-filter ${name}`}>
        <QuickFilterComponent
          filterValue={filterValue}
          handleClick={this.handleClick}
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickFilterC)
