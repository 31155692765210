import numeral from 'numeral'
import { NUMBER_FORMAT } from 'constants/formatAmountConstants'
import isNil from 'lodash/isNil'
import toString from 'lodash/toString'
import split from 'lodash/split'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import {
  USD,
  CURRENCY_NAME_TO_SYMBOL_MAP,
} from 'constants/currencyConstants'

const formatMoney = ({
  amount,
  currency = USD,
  displayNegativeAmount = false,
  displayNegativeAsAbsolute = false,
  showCurrencyCode = false,
}) => {
  if (isNil(amount) || (isString(amount) && isEmpty(amount))) { return null }

  const currencySymbol = CURRENCY_NAME_TO_SYMBOL_MAP[currency]
  const amountString = toString(amount)

  // split into dollars and cents
  const splitAmountString = split(amountString, '.')
  const dollar = get(splitAmountString, '[0]', '')
  const decimal = get(splitAmountString, '[1]', '')
  const normalizedDecimalValue = decimal.length === 1 ? `${decimal}0` : decimal

  // detect and remove sign before formatting
  const isNegative = dollar.indexOf('-') === 0
  const sign = isNegative ? '-' : ''
  const absoluteDollarValue = isNegative ? dollar.substring(1) : dollar

  // format the money to include currency symbol and sign
  const formattedDollars = numeral(absoluteDollarValue).format(NUMBER_FORMAT)
  const formattedCents = normalizedDecimalValue ? normalizedDecimalValue : '00'
  const formattedMoney = displayNegativeAsAbsolute ? `${currencySymbol}${formattedDollars}.${formattedCents}` : `${sign}${currencySymbol}${formattedDollars}.${formattedCents}`
  const amountIsNotZero = formattedMoney !== `${currencySymbol}0.00`

  // displayNegativeAmount will simply surround a number with () even if it is negative
  // display negative as absolute will turn a negative into a positive and surround it with ()
  const returnValue = ((displayNegativeAsAbsolute && isNegative) || (displayNegativeAmount && amountIsNotZero)) ? `(${formattedMoney})` : formattedMoney

  return showCurrencyCode ? `${returnValue} ${currency}` : returnValue
}

export default formatMoney
