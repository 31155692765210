import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { CREATE_ADJUSTMENT_FORM } from 'constants/formConstants'
import validateCreateAdjustmentForm from 'utilities/validate/validateCreateAdjustmentForm'
import submitCreateAdjustmentForm from 'utilities/submit/submitCreateAdjustmentForm'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'

const CreateAdjustmentForm = ({
  handleSubmit = () => {},
  submitting = false,
  merchantsOptions = [],
  paymentInstrumentOptions = [],
  adjustmentTypeOptions = [],
}) => {
  return (
    <form className='CreateAdjustmentForm' onSubmit={handleSubmit(submitCreateAdjustmentForm)}>
      <div className='section'>
        <Field
          name='adjustmentType'
          label='Adjustment Type'
          component={SelectField}
          options={adjustmentTypeOptions}
        />

        <Field
          name='paymentInstrumentId'
          label='Payment Instrument'
          component={SelectField}
          options={paymentInstrumentOptions}
        />

        <Field
          name='merchantId'
          label='Merchant'
          component={SelectField}
          options={merchantsOptions}
        />

        <AmountField label='Amount' />

        <Field
          name='reason'
          label='Reason'
          component={InputField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label='Create' submitting={submitting} />
      </div>
    </form>
  )
}

CreateAdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  merchantsOptions: PropTypes.array,
  paymentInstrumentOptions: PropTypes.array,
  adjustmentTypeOptions: PropTypes.array,
}

export default reduxForm({
  form: CREATE_ADJUSTMENT_FORM,
  validate: validateCreateAdjustmentForm,
})(CreateAdjustmentForm)
