import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditMerchantOnboardingFormSettingsFormC from 'components/Customer/Forms/EditMerchantOnboardingFormSettingsForm/EditMerchantOnboardingFormSettingsFormC'

const MerchantOnboardingFormSettingsModal = ({
  underwritingProfile = {},
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title='Update Merchant Onboarding Form Settings'
      subTitle='Set requirements for merchants to verify personal identity with a government-issued ID and selfie in our Onboarding Forms'
      className='MerchantOnboardingFormSettingsModal'
      Component={EditMerchantOnboardingFormSettingsFormC}
      underwritingProfile={underwritingProfile}
      closeModal={closeModal}
    />
  )
}

MerchantOnboardingFormSettingsModal.propTypes = {
  underwritingProfile: PropTypes.object,
  closeModal: PropTypes.func,
}

export default MerchantOnboardingFormSettingsModal
