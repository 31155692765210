import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'


// card types
const CREDIT = 'Credit'
const DEBIT = 'Debit'
const CHARGE = 'Charge'
const PREPAID = 'Prepaid'
const DEFERRED_DEBIT = 'Deferred Debit'
const UNKNOWN = 'Unknown'

// card brand types
const AMERICAN_EXPRESS = 'AMERICAN_EXPRESS'
const CHINA_T_UNION = 'CHINA_T_UNION'
const CHINA_UNION_PAY = 'CHINA_UNION_PAY'
const DANKORT = 'DANKORT'
const DINERS_CLUB = 'DINERS_CLUB'
const DINERS_CLUB_INTERNATIONAL = 'DINERS_CLUB_INTERNATIONAL'
const DISCOVER = 'DISCOVER'
const INTERPAYMENT = 'INTERPAYMENT'
const INSTAPAYMENT = 'INSTAPAYMENT'
const JCB = 'JCB'
const LANKAPAY = 'LANKAPAY'
const MAESTRO = 'MAESTRO'
const MASTERCARD = 'MASTERCARD'
const MIR = 'MIR'
const RUPAY = 'RUPAY'
const TROY = 'TROY'
const UATP = 'UATP'
const VERVE = 'VERVE'
const VISA = 'VISA'

export const VISA_LABEL = 'Visa'
export const AMEX_LABEL = 'American Express'
export const MASTERCARD_LABEL = 'Mastercard'
export const DISCOVER_LABEL = 'Discover'
export const JCB_LABEL = 'JCB'
export const DINERS_CLUB_LABEL = 'Diners Club'

export const cardTypeMap = {
  C: CREDIT,
  D: DEBIT,
  H: CHARGE,
  P: PREPAID,
  R: DEFERRED_DEBIT,
  U: UNKNOWN,
  CREDIT: CREDIT,
  DEBIT: DEBIT,
  PREPAID: PREPAID,
  UNKNOWN: UNKNOWN,
}

export const CARD_BRANDS = [
  AMERICAN_EXPRESS,
  CHINA_T_UNION,
  CHINA_UNION_PAY,
  DANKORT,
  DINERS_CLUB,
  DINERS_CLUB_INTERNATIONAL,
  DISCOVER,
  INTERPAYMENT,
  INSTAPAYMENT,
  JCB,
  LANKAPAY,
  MAESTRO,
  MASTERCARD,
  MIR,
  RUPAY,
  TROY,
  UATP,
  VERVE,
  VISA,
]

export const CARD_BRAND_OPTIONS = map(sortBy(CARD_BRANDS), (brand) => ({ label: snakeCaseToTitleCase({key: brand}), value: brand }))
