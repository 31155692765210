/* eslint no-debugger: 0 */

import updateState from 'utilities/reducers/updateState'
import isFunction from 'lodash/isFunction'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { NEW_VALUES_PATH } from 'constants/actionConstants'
import { LOCATION_CHANGE } from 'react-router-redux'
import includes from 'lodash/includes'

import {
  requestF,
  successF,
  failureF,
  CLEAR_REDUCER,
  cancelF,
} from 'constants/flowConstants'

const flowRequest = (state, action) => updateState(state, action, true, undefined, get(action, 'meta.overwriteReducer', false))

const flowSuccess = (state, action) => updateState(state, action, false, get(action, NEW_VALUES_PATH), get(action, 'meta.overwriteReducer', false))

const flowFailure = (state, action) => updateState(state, action, false, undefined, get(action, 'meta.overwriteReducer', false))

// TODO: write tests for this
const genericReducer = ({
  flows = {},
  // request/success/failure options allow you to change the default actions across the entire reducer
  request = flowRequest,
  success = flowSuccess,
  failure = flowFailure,
  debug = false,
  name = '',
  cancel = flowFailure,
}) => {
  const flowActions = reduce(flows, (total, flowData, flowName) => {
    if (flowName === LOCATION_CHANGE) {
      const locationChangeHandler = isFunction(flowData) ? flowData : () => {}

      return merge({}, total, { [LOCATION_CHANGE]: locationChangeHandler })
    }

    return merge({}, total, {
      // each flow may optionally pass in a request/success/failure override for specific flows in the reducer
      [requestF(flowName)]: get(flowData, 'request', request),
      [successF(flowName)]: get(flowData, 'success', success),
      [failureF(flowName)]: get(flowData, 'failure', failure),
      [cancelF(flowName)]: get(flowData, 'failure', cancel),
    })
  }, {})

  return (state = {}, action = {}) => {
    const actionType = get(action, 'type')
    const flowAction = get(flowActions, actionType)

    if (flowAction) {
      const nextState = flowAction(state, action)

      if (debug) { debugger }

      return nextState
    }

    if (actionType === CLEAR_REDUCER && includes(get(action, 'payload.names'), name)) {
      return omit(state, 'items')
    }

    return state
  }
}

export default genericReducer
