import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import FinixIntegration from './FinixIntegration'
import Loader from 'components/Shared/Loader/Loader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import getMany from 'utilities/get/getMany'
import { GET_APPLICATION_USERS } from 'constants/flowConstants'
import { COPY } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  FINIX_SUPPORT_HELP_URL,
  INTEGRATION_OVERVIEW_LEARN_MORE_LINK,
} from 'constants/urlConstants'

import {
  APPLICATION_ID,
  MERCHANT_ID,
  DESCRIPTION,
  FINIX_PROCESSING_DETAILS_DESCRIPTION,
  LEARN_MORE_LABEL,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `applicationUsersR.${FETCHING}`)

  const [
    applicationId,
    merchantId,
    isFetchingData = false,
  ] = getMany(props, [
    'applicationId',
    'merchantId',
    'isFetchingData',
  ])

  const flow = GET_APPLICATION_USERS

  const flowValues = {
    applicationId,
  }

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
  }

  const descriptionValue = (
    <div>{FINIX_PROCESSING_DETAILS_DESCRIPTION}
      <a target='blank' href={INTEGRATION_OVERVIEW_LEARN_MORE_LINK} className='learn-more-link text-link'>{LEARN_MORE_LABEL}</a>
    </div>
  )

  const merchantValue = merchantId ? <div className='flex'>{merchantId}<ClickToCopyC customView={<i className={`far fa-${COPY}`} />} content={merchantId} /></div> :
  <span>Please <a className='text-link' href={FINIX_SUPPORT_HELP_URL} target='blank'>contact support</a> to get your Merchant ID created</span>

  const finixProcessingDetailsCardData = [
    {
      data: [
        {
          label: APPLICATION_ID,
          value: isFetchingData ? <Loader /> : <div className='flex'>{applicationId}<ClickToCopyC customView={<i className={`far fa-${COPY}`} />} content={applicationId} /></div>,
        },
        {
          label: MERCHANT_ID,
          value: isFetchingData ? <Loader /> : merchantValue,
        },
        {
          label: DESCRIPTION,
          value: descriptionValue,
        },
      ],
    },
  ]

  return {
    flow,
    flowValues,
    isFetching,
    applicationId,
    initialQueries,
    finixProcessingDetailsCardData,
  }
}

class FinixIntegrationC extends Component {
  render() {
    return (
      <FinixIntegration
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(FinixIntegrationC)
