import paymentsAPI from 'utilities/api/paymentsAPI'
import { TRANSFER_ATTEMPTS } from 'constants/apiConstants'

const getTransferAttemptsAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: TRANSFER_ATTEMPTS,
    queries,
    meta,
    service: TRANSFER_ATTEMPTS,
  })
}

export default getTransferAttemptsAPI
