import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ResendEmailFormC from 'components/Customer/Forms/ResendEmailForm/ResendEmailFormC'

const ResendEmailModal = ({
  dashboardSandboxId = '',
  emailAddress = '',
}) => {
  return (
    <GenericModal
      title='Resend Email'
      className='resendEmailModal'
      Component={ResendEmailFormC}
      dashboardSandboxId={dashboardSandboxId}
      emailAddress={emailAddress}
    />
  )
}

ResendEmailModal.propTypes = {
  dashboardSandboxId: PropTypes.string,
  emailAddress: PropTypes.string,
}

export default ResendEmailModal
