import './DisbursementsDashboardOverviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import TableC from 'components/Shared/Table/TableC'

import {
  DAILY_ACH_PAYOUT_COUNT,
  DAILY_ACH_PAYOUT_COUNT_TOOLTIP,
  DAILY_ACH_PAYOUT_VOLUME,
  DAILY_ACH_PAYOUT_VOLUME_TOOLTIP,
  DAILY_CARD_PAYOUT_COUNT,
  DAILY_CARD_PAYOUT_COUNT_TOOLTIP,
  DAILY_CARD_PAYOUT_VOLUME,
  DAILY_CARD_PAYOUT_VOLUME_TOOLTIP,
  LATEST_PAYOUTS,
  LATEST_PAYOUTS_TOOLTIP,
  MY_ACCOUNT_BALANCE,
  SUMMARY,
  VIEW_ALL_PAYOUTS,
} from 'constants/language/languageConstants'

const DisbursementsDashboardOverview = ({
  displayCardDailyVelocityCurrentVolume = '',
  displayCardDailyVelocityVolumeLimit = '',
  cardDailyVelocityCurrentCount = '',
  cardDailyVelocityCountLimit = '',
  displayAchDailyVelocityCurrentVolume = '',
  displayAchDailyVelocityVolumeLimit = '',
  achDailyVelocityCurrentCount = '',
  achDailyVelocityCountLimit = '',
  applicationBalanceAmount = '',
  currency = '',
  latestActivityData = [],
  transactionPath = '',
  disbursementsPath = '',
}) => {
  return (
    <div className='DisbursementsDashboardOverview'>
      <HeaderV2C
        title={MY_ACCOUNT_BALANCE}
        subTitle={<div className='flex'><h1>{applicationBalanceAmount ? `${applicationBalanceAmount}` : '$0'}</h1> <h5>{currency}</h5></div>}
      />

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p><span className='bold'>{DAILY_CARD_PAYOUT_VOLUME}</span>{DAILY_CARD_PAYOUT_VOLUME_TOOLTIP}</p>
                <p><span className='bold'>{DAILY_CARD_PAYOUT_COUNT}</span>{DAILY_CARD_PAYOUT_COUNT_TOOLTIP}</p>
                <p><span className='bold'>{DAILY_ACH_PAYOUT_VOLUME}</span>{DAILY_ACH_PAYOUT_VOLUME_TOOLTIP}</p>
                <p><span className='bold'>{DAILY_ACH_PAYOUT_COUNT}</span>{DAILY_ACH_PAYOUT_COUNT_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={DAILY_CARD_PAYOUT_VOLUME}
            headerData={{
              value: displayCardDailyVelocityCurrentVolume,
            }}
            subHeaderData={{
              value: displayCardDailyVelocityVolumeLimit,
              formatter: () => `of ${displayCardDailyVelocityVolumeLimit} limit`,
            }}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={DAILY_CARD_PAYOUT_COUNT}
            headerData={{
              value: cardDailyVelocityCurrentCount,
            }}
            subHeaderData={{
              value: cardDailyVelocityCountLimit,
              formatter: () => `of ${cardDailyVelocityCountLimit} transactions limit`,
            }}
          />

          <CustomBigNumberC
            title={DAILY_ACH_PAYOUT_VOLUME}
            headerData={{
              value: displayAchDailyVelocityCurrentVolume,
            }}
            subHeaderData={{
              value: displayAchDailyVelocityVolumeLimit,
              formatter: () => `of ${displayAchDailyVelocityVolumeLimit} limit`,
            }}
          />

          <CustomBigNumberC
            title={DAILY_ACH_PAYOUT_COUNT}
            headerData={{
              value: achDailyVelocityCurrentCount,
            }}
            subHeaderData={{
              value: achDailyVelocityCountLimit,
              formatter: () => `of ${achDailyVelocityCountLimit} transactions limit`,
            }}
          />
        </div>

        {/* TODO: Cata - uncomment this for next disbursements phase */}
        {/* <GroupedCharts */}
        {/*  title={INSIGHTS} */}
        {/*  classNames='disbursements-insights' */}
        {/*  chartClassNames='disbursements-insights-charts grid grid-cols-2 md:grid-cols-1' */}
        {/*  tooltipPosition='right' */}
        {/*  tooltipMessage={( */}
        {/*    <> */}
        {/*      <p>{DISBURSEMENTS_INSIGHTS_TOOLTIP}</p> */}
        {/*    </> */}
        {/*  )} */}
        {/*  timeRange={timeRange} */}
        {/*  timeGrainFilters={[ */}
        {/*    { name: DAILY, value: 'daily' }, */}
        {/*    { name: WEEKLY, value: 'weekly', active: true }, */}
        {/*    { name: MONTHLY, value: 'monthly' }, */}
        {/*  ]} */}
        {/*  charts={[ */}
        {/*    { name: 'name', height: 260 }, */}
        {/*    { name: 'name', height: 260 }, */}
        {/*  ]} */}
        {/* /> */}

        <div className='payouts-table-container'>
          <TableC
            title={LATEST_PAYOUTS}
            data={latestActivityData}
            columnDescriptors={columnDescriptors}
            tooltipPosition='right'
            tooltip={LATEST_PAYOUTS_TOOLTIP}
            tableLink={VIEW_ALL_PAYOUTS}
            linkPath={disbursementsPath}
            path={transactionPath}
          />
        </div>
      </div>
    </div>
  )
}

DisbursementsDashboardOverview.propTypes = {
  displayCardDailyVelocityCurrentVolume: PropTypes.string,
  displayCardDailyVelocityVolumeLimit: PropTypes.string,
  cardDailyVelocityCurrentCount: PropTypes.string,
  cardDailyVelocityCountLimit: PropTypes.string,
  displayAchDailyVelocityCurrentVolume: PropTypes.string,
  displayAchDailyVelocityVolumeLimit: PropTypes.string,
  achDailyVelocityCurrentCount: PropTypes.string,
  achDailyVelocityCountLimit: PropTypes.string,
  applicationBalanceAmount: PropTypes.string,
  currency: PropTypes.string,
  latestActivityData: PropTypes.array,
  transactionPath: PropTypes.string,
  disbursementsPath: PropTypes.string,
}

export default DisbursementsDashboardOverview
