import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import CaptureAuthorizationForm from 'components/Customer/Forms/CaptureAuthorizationForm/CaptureAuthorizationForm'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { getDispatch } from 'state-layer/configureStore'
import { getAuthorizationSelector } from 'state-layer/selectors'
import { CAPTURE_AUTHORIZATION_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import round from 'lodash/round'

import {
  USD,
  CAD,
} from 'constants/currencyConstants'

const mapStateToProps = (state, props) => {
  const dispatch = getDispatch()
  const isFetching = get(state, `authorizationsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const activeField = get(state, `form.${CAPTURE_AUTHORIZATION_FORM}.active`)
  const currentUser = getCurrentUser(state)

  const [
    authorizationId,
    allowCardPresentCapture,
  ] = getMany(props, [
    'authorizationId',
    'allowCardPresentCapture',
  ])

  const authorization = getAuthorizationSelector(state, authorizationId)
  const formValues = getFormValues(CAPTURE_AUTHORIZATION_FORM)(state)

  const [
    addedAmountInput,
    totalCaptureAmountInput,
    returnAmountInput,
    totalSubtractedCaptureAmountInput,
    captureAuthAmountInput,
    captureCardPresentOption = 'captureOriginalAmount',
    sendReceiptToBuyer = false,
  ] = getMany(formValues, [
    'addToAuthAmount',
    'totalCaptureAmount',
    'subtractFromAuthAmount',
    'totalSubtractedCaptureAmount',
    'captureAuthAmount',
    'captureCardPresentOption',
    'sendReceiptToBuyer',
  ])

  const originalAmount = get(authorization, 'amount', '0.00')
  const merchantCountry = get(authorization, 'merchantIdentity.country')
  const merchantCurrency = merchantCountry === 'USA' ? USD : CAD

  const initialValues = {
    ...formValues,
    originalAmount,
    captureAmount: originalAmount,
    captureCardPresentOption: 'captureOriginalAmount',
  }

  return {
    isFetching,
    credentials,
    credentialId,
    authorization,
    authorizationId,
    allowCardPresentCapture,
    addedAmountInput,
    merchantCurrency,
    dispatch,
    totalCaptureAmountInput,
    initialValues,
    returnAmountInput,
    totalSubtractedCaptureAmountInput,
    activeField,
    captureAuthAmountInput,
    captureCardPresentOption,
    originalAmount,
    sendReceiptToBuyer,
    currentUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CaptureAuthorizationFormC extends Component {
  componentDidUpdate(prevProps, prevState) {
    const {
      addedAmountInput: prevAddedAmountInput,
      returnAmountInput: prevReturnAmountInput,
      totalCaptureAmountInput: prevTotalCaptureAmountInput,
      totalSubtractedCaptureAmountInput: prevTotalSubtractedCaptureAmountInput,
    } = prevProps

    const {
      addedAmountInput,
      returnAmountInput,
      originalAmount,
      totalCaptureAmountInput,
      totalSubtractedCaptureAmountInput,
      merchantCurrency,
      dispatch,
      activeField,
    } = this.props

    // We need to use displayAmountToAmount so that we can do the addition & subtraction correctly.
    const authorizationAmount = displayAmountToAmount(originalAmount, merchantCurrency)
    const addedAmount = displayAmountToAmount(addedAmountInput, merchantCurrency)
    const returnAmount = displayAmountToAmount(returnAmountInput, merchantCurrency)
    const totalAmount = displayAmountToAmount(totalCaptureAmountInput, merchantCurrency)
    const totalReturnAmount = displayAmountToAmount(totalSubtractedCaptureAmountInput, merchantCurrency)

    const authorizationPlusAdded = round(authorizationAmount + addedAmount).toFixed(2)
    const totalMinusAuthorization = totalAmount - authorizationAmount
    const authorizationMinusReturn = authorizationAmount - returnAmount
    const totalMinusReturn = authorizationAmount - totalReturnAmount
    const captureAmountCorrect = authorizationAmount + addedAmount === totalAmount
    const isCaptureAmountValid = totalCaptureAmountInput <= originalAmount

    if (activeField === 'addToAuthAmount' && addedAmountInput !== prevAddedAmountInput && !captureAmountCorrect) {
      const updatedValue = addedAmountInput ? amountToDisplayAmount(authorizationPlusAdded, merchantCurrency, false) : ''
      dispatch(change(CAPTURE_AUTHORIZATION_FORM, 'totalCaptureAmount', updatedValue))
    }

    if (activeField === 'totalCaptureAmount' && totalCaptureAmountInput !== prevTotalCaptureAmountInput && !captureAmountCorrect && !isCaptureAmountValid) {
      const updatedValue = totalCaptureAmountInput ? amountToDisplayAmount(totalMinusAuthorization, merchantCurrency, false) : ''
      dispatch(change(CAPTURE_AUTHORIZATION_FORM, 'addToAuthAmount', updatedValue))
    }

    if (activeField === 'subtractFromAuthAmount' && returnAmountInput !== prevReturnAmountInput && !captureAmountCorrect) {
      const updatedValue = returnAmountInput ? amountToDisplayAmount(authorizationMinusReturn, merchantCurrency, false) : ''
      dispatch(change(CAPTURE_AUTHORIZATION_FORM, 'totalSubtractedCaptureAmount', updatedValue))
    }

    if (activeField === 'totalSubtractedCaptureAmount' && totalSubtractedCaptureAmountInput !== prevTotalSubtractedCaptureAmountInput) {
      const updateValue = totalSubtractedCaptureAmountInput ? amountToDisplayAmount(totalMinusReturn, merchantCurrency, false) : ''
      dispatch(change(CAPTURE_AUTHORIZATION_FORM, 'subtractFromAuthAmount', updateValue))
    }
  }

  render() {
    return (
      <div>
        <CaptureAuthorizationForm
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureAuthorizationFormC)
