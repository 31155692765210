import React, { Component } from 'react'
import { connect } from 'react-redux'
import ACHReturns from './ACHReturns'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { ACH_RETURNS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transaction'),
  ...TAG_FILTERS,
], 'label')

const quickFilters = [DATE_QUICK_FILTER]

// hide all payment card fields as ACH Returns are all using bank accounts
const hiddenExportFields = [
  'cardType',
  'cardBrand',
  'cardBin',
  'cardLastFour',
  'cardExpirationMonth',
  'cardExpirationYear',
  'cardAddressLineOne',
  'cardAddressLineTwo',
  'cardAddressCity',
  'cardAddressPostalCode',
  'cardAddressCountry',
  'cardAddressVerification',
  'cardSecurityCodeVerification',
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  custom_transfer_type: 'echeck_return',
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = ACH_RETURNS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    allowedFilters,
    initialQueries: mergedQueries,
    hiddenExportFields,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    paymentPath,
    quickFilters,
  }
}

class ACHReturnsC extends Component {
  render() {
    return (
      <ACHReturns {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ACHReturnsC)
