import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_VERIFICATIONS,
} from 'constants/apiConstants'

const getIdentityVerificationsAPI = ({ queries, values, credentials, meta }) => {
  const {
    identityId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: IDENTITY_VERIFICATIONS({ identityId }),
    queries,
    service: IDENTITIES,
  })
}

export default getIdentityVerificationsAPI
