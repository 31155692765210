import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { IDENTITY_PATH } from 'constants/pathConstants'
import { POST_APPLICATION_ASSOCIATED_IDENTITIES_F_REQUEST } from 'constants/flowConstants'
import { CREATE_ASSOCIATED_IDENTITY } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const submitAssociatedIdentityForm = (values, dispatch, props) => {
  const principal = get(values, 'principals.0', {})

  const {
    credentials,
    identityId,
  } = props

  dispatch({
    type: POST_APPLICATION_ASSOCIATED_IDENTITIES_F_REQUEST,
    payload: {
      values: principal,
      credentials,
      id: identityId,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const credentialId = get(credentials, 'id')
        const identityPath = IDENTITY_PATH({ identityId, credentialId })

        dispatch(redirectRequest({ path: identityPath }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_ASSOCIATED_IDENTITY, {
        id: identityId,
        credentials,
      }),
    },
  })
}

export default submitAssociatedIdentityForm
