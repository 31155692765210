import './IdentityVerificationFormS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { IDENTITY_VERIFICATION_FORM } from 'constants/formConstants'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import submitIdentityVerificationForm from 'utilities/submit/submitIdentityVerificationForm'
import { SUBMIT } from 'constants/language/languageConstants'

const IdentityVerificationForm = ({
  handleSubmit = () => {},
  submitting = false,
  processorOptions = [],
  hasEnabledProcessors,
  applicationPath,
  closeModal = () => {},
}) => {
  const warningMessage = (
    <>
      <span>This identity has not enabled any verification processors, please enable them on the </span>
      <Link to={applicationPath} onClick={closeModal}>Application - Processors</Link>
      <span> page</span>
    </>
  )

  return (
    <form className='IdentityVerificationForm' onSubmit={handleSubmit(submitIdentityVerificationForm)}>
      { !hasEnabledProcessors && (
        <WarningMessage warningMessage={warningMessage} />
      )}

      <Field
        name='identity'
        label='Identity ID'
        component={InputField}
        disabled
      />

      <Field
        name='processor'
        label='Processor'
        component={SelectField}
        options={processorOptions}
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={!hasEnabledProcessors} label={SUBMIT} />
      </div>
    </form>
  )
}

IdentityVerificationForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  processorOptions: PropTypes.array,
}

export default reduxForm({
  form: IDENTITY_VERIFICATION_FORM,
})(IdentityVerificationForm)
