import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_PAYMENT_INSTRUMENTS,
} from 'constants/apiConstants'

const getIdentityPaymentInstrumentsAPI = ({ queries, values, credentials, meta }) => {
  const {
    identityId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: IDENTITY_PAYMENT_INSTRUMENTS({ identityId }),
    queries,
    service: IDENTITIES,
  })
}

export default getIdentityPaymentInstrumentsAPI
