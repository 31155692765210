import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  V1_IDENTITY_SETTLEMENTS,
} from 'constants/apiConstants'

const postIdentitySettlementsAPI = ({ values, credentials, meta }) => {
  const {
    identity,
  } = values

  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: V1_IDENTITY_SETTLEMENTS({ identityId: identity }),
    service: IDENTITIES,
  })
}

export default postIdentitySettlementsAPI
