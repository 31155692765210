import { GET_DEVICE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getDeviceRequest = ({
  deviceId,
  credentials,
  queries,
  meta = { hideFlashNotification: true },
}) => createAction({
  type: GET_DEVICE_F_REQUEST,
  id: deviceId,
  credentials,
  queries,
  meta,
})

export default getDeviceRequest
