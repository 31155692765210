import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import getMany from 'utilities/get/getMany'

import {
  WELCOME_EMAIL_TEMPLATE,
} from 'constants/flowConstants'


const frontendEmailTemplatesModel = (templateObject) => {
  return keyBy([templateObject], 'templateName')
}

export const frontendWelcomeEmailTemplatesModel = ({ data: emailTemplate }) => {
  const dashboardServiceV2 = isDashboardServiceV2()

  const [
    id,
    subject,
    html,
    text,
  ] = getMany(emailTemplate, [
    'id',
    dashboardServiceV2 ? 'email_subject_part' : 'subject_part',
    dashboardServiceV2 ? 'email_html_part' : 'html_part',
    dashboardServiceV2 ? 'email_text_part' : 'text_part',
  ])

  return frontendEmailTemplatesModel({
    id,
    templateName: WELCOME_EMAIL_TEMPLATE,
    subject,
    html,
    text,
  })
}

export const frontendGenericEmailTemplatesModel = ({ data: emailTemplate }) => {
  const [
    id,
    subject,
    html,
    text,
    templateName,
  ] = getMany(emailTemplate, [
    'id',
    'email_subject_part',
    'email_html_part',
    'email_text_part',
    'email_template_name',
  ])

  return frontendEmailTemplatesModel({
    id,
    templateName,
    subject,
    html,
    text,
  })
}
