import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import find from 'lodash/find'
import get from 'lodash/get'

const frontendMemberModel = ({ data: member }) => {
  const [
    id,
    membershipId,
    createdAt,
    updatedAt,
    lastActivity,
    firstName,
    lastName,
    email,
    roleName,
    enabled,
    env,
    roleId,
    createdBy,
    dashboardUserRoleType,
    dashboardUserId,
    mfaConfigurations,
  ] = getMany(member, [
    'id',
    'membership_id',
    'created_at',
    'updated_at',
    'last_activity',
    'first_name',
    'last_name',
    'email_address',
    'dashboard_user_role_name',
    'enabled',
    'env',
    'dashboard_user_role',
    'created_by',
    'dashboard_user_role_type',
    'dashboard_user_id',
    'mfa_configurations',
  ])

  const enabledMFAConfiguration = find(mfaConfigurations, { enabled: true })
  const authenticationType = enabledMFAConfiguration ? 'MFA' : 'Password'

  const normalizedFirstName = firstName || ''
  const normalizedLastName = lastName || ''

  return removeUndefined({
    id,
    membershipId,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt: formatDate({ date: updatedAt }),
    lastActivity,
    displayLastActivity: formatDate({ date: lastActivity }),
    firstName,
    lastName,
    fullName: (firstName || lastName) ? `${normalizedFirstName} ${normalizedLastName}` : undefined,
    email,
    roleName,
    enabled,
    env,
    roleId,
    createdBy,
    dashboardUserRoleType,
    dashboardUserId,
    authenticationType,
  })
}

export default frontendMemberModel
