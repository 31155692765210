import './CustomerOnboardingFormSuccessS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import { APPLICATIONS_PATH } from 'constants/pathConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'

import {
  CUSTOMER_ONBOARDING_FORM,
  CUSTOMER_ONBOARDING_FORM_SUCCESS_MESSAGE,
  CUSTOMER_ONBOARDING_FORM_SUCCESS_HEADER,
  VIEW_ALL_ONBOARDING_FORMS,
  COPY_LINK,
  COPY_AND_SHARE_THE_URL_LINK,
} from 'constants/language/languageConstants'

const CustomerOnboardingFormSuccess = ({
  url = '',
  credentialId,
  copyToClipboard = () => {},
  isFetching = true,
}) => {
  return (
    <div className='CustomerOnboardingFormSuccess'>
      <h3>{CUSTOMER_ONBOARDING_FORM}</h3>
      <div className='success-header flex items-center'>
        <i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />
        {CUSTOMER_ONBOARDING_FORM_SUCCESS_HEADER}
      </div>

      <h5>{COPY_AND_SHARE_THE_URL_LINK}</h5>
      <div className='success-message p-2'>{CUSTOMER_ONBOARDING_FORM_SUCCESS_MESSAGE}</div>
      <div className='url-container'>
        {isFetching ? <Loader /> : url }
      </div>

      <div className='flex'>
        <Button onClick={() => copyToClipboard(url)} label={COPY_LINK} className='copy-link-button' />
        <Button
          label={VIEW_ALL_ONBOARDING_FORMS}
          variant='secondary'
          onClick={() => goToPath({
            pathname: APPLICATIONS_PATH({ credentialId }),
            queries: { tab: 'onboarding-forms' },
          })}
        />
      </div>
    </div>
  )
}

CustomerOnboardingFormSuccess.propTypes = {
  url: PropTypes.string,
  credentialId: PropTypes.string,
  copyToClipboard: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default CustomerOnboardingFormSuccess
