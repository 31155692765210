import { GET_TRANSACTION_CHARTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getTransactionChartsRequest = ({
  queries,
}) => createAction({
  type: GET_TRANSACTION_CHARTS_F_REQUEST,
  queries,
})

export default getTransactionChartsRequest
