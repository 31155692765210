import { IDLE_MESSAGE } from 'constants/language/languageConstants'
import size from 'lodash/size'
import trim from 'lodash/trim'

const validateSetIdleScreenForm = (values) => {
  const { idleMessage } = values
  const idleMessageExceedsMaxCharLength = size(trim(idleMessage)) > 255 && `${IDLE_MESSAGE} must not exceed 255 characters.`

  return {
    idleMessage: idleMessageExceedsMaxCharLength,
  }
}

export default validateSetIdleScreenForm
