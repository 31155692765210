import React from 'react'
import PropTypes from 'prop-types'
import CreateRefundConfirmationC from 'components/Customer/Pages/CreateRefund/CreateRefundConfirmation/CreateRefundConfirmationC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { CONFIRM_TOTAL_REFUND_AMOUNT } from 'constants/language/languageConstants'

const CreateRefundConfirmationModal = ({
  transferId,
  refundAmount = '',
  selectedSplitRefundExperience = false,
  splitRefundAmountSum,
  displaySplitRefundAmountSum = '',
  splitRefundsValuesToSubmit = [],
  displayRefundAmount = '',
}) => {
  const normalizedRefundAmount = selectedSplitRefundExperience ? displaySplitRefundAmountSum : displayRefundAmount

  return (
    <GenericModal
      title={`${CONFIRM_TOTAL_REFUND_AMOUNT} ${normalizedRefundAmount}`}
      className='CreateRefundConfirmationModal'
      Component={CreateRefundConfirmationC}
      transferId={transferId}
      refundAmount={refundAmount}
      selectedSplitRefundExperience={selectedSplitRefundExperience}
      splitRefundAmountSum={splitRefundAmountSum}
      splitRefundsValuesToSubmit={splitRefundsValuesToSubmit}
    />
  )
}

CreateRefundConfirmationModal.propTypes = {
  transferId: PropTypes.string,
  refundAmount: PropTypes.string,
  selectedSplitRefundExperience: PropTypes.bool,
  splitRefundAmountSum: PropTypes.number,
  displaySplitRefundAmountSum: PropTypes.string,
  splitRefundsValuesToSubmit: PropTypes.array,
  displayRefundAmount: PropTypes.string,
}

export default CreateRefundConfirmationModal
