import './RegisterNewDomainModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import AddNewDomainFormC from 'components/Customer/Forms/AddNewDomainForm/AddNewDomainFormC'
import DownloadVerificationFileFormC from 'components/Customer/Forms/DownloadVerificationFileForm/DownloadVerificationFileFormC'
import HostFileFormC from 'components/Customer/Forms/HostFileForm/HostFileFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import submitAddDomainForm from 'utilities/submit/submitAddDomainForm'
import { ADD_NEW_DOMAIN_DATA_KEY } from 'constants/flowsConstants'
import { ADD_NEW_DOMAIN_FORM } from 'constants/formConstants'

const RegisterNewDomainModal = ({
  identityId = '',
  merchantName = '',
}) => {
  const steps = [
    {
      component: AddNewDomainFormC,
      stepTitle: 'Domain Name',
      props: {
        submitLabel: 'Next',
        form: ADD_NEW_DOMAIN_FORM,
      },
      dataKey: ADD_NEW_DOMAIN_DATA_KEY,
    },
    {
      component: DownloadVerificationFileFormC,
      stepTitle: 'Download File',
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
    },
    {
      component: HostFileFormC,
      stepTitle: 'Host File',
      props: {
        identityId,
        submitLabel: 'Submit',
        leftButton: true,
        rightButtonAction: submitAddDomainForm,
      },
    },
  ]

  return (
    <GenericModal
      className='RegisterNewDomainModal'
      identityId={identityId}
      merchantName={merchantName}
      steps={steps}
      multiSteps
      title='Add Web Domain'
    />
  )
}

RegisterNewDomainModal.propTypes = {
  identityId: PropTypes.string,
  merchantName: PropTypes.string,
}

export default RegisterNewDomainModal
