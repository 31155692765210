import getUrlQuery from 'utilities/get/getUrlQuery'

import {
  QA_URL,
  LIVE_URL,
  SANDBOX_URL,
} from 'constants/credentialConstants'

const getGuestCheckoutHost = () => {
  if (window.location.origin && window.location.origin.includes('sandbox-payments-checkout')) {
    return SANDBOX_URL
  }

  if (window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://qa.payments-dashboard.com') {
    if (getUrlQuery('platform') === 'finix') {
      return LIVE_URL
    }

    if (getUrlQuery('platform') === 'dev') {
      return QA_URL
    }

    return SANDBOX_URL
  }

  return LIVE_URL
}

export default getGuestCheckoutHost
