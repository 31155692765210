import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { TRANSFER_ATTEMPTS } from 'constants/apiConstants'

const postGuestTransferAttemptAPI = ({ values }) => {
  return guestCheckoutAPI.post({
    values,
    path: TRANSFER_ATTEMPTS,
  })
}

export default postGuestTransferAttemptAPI
