import { T2_SYSTEMS } from 'constants/platformConstants'

import {
  DUMMY_V1,
  LITLE_V1,
  MICROBILT_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 't2systems',
  id: 'DASHha2QU4oDa6o6erdxRXTc82',
  applicationName: T2_SYSTEMS,
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2020-10-30-19_12_18-t2systems-logo.png',
  faviconImage: '',
  credentialsHost: 'https://t2systems.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: 'pz2FjcFHkSIXMMVCOHhvupWqAvzG9LBZ',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: 'T2 Systems',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'LITLE_V1', 'MICROBILT_V1'],
  dashboardServiceV2: true, // using this to control dashboard service version
}
