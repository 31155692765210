import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const PaymentLink = ({
  headerData = {},
  contextBarData = {},
  tabs = [],
  actions = [],
}) => {
  return (
    <div className='PaymentLink'>
      <ContextBar {...contextBarData} />

      <HeaderV2C actions={actions} {...headerData} />

      <TabsC tabs={tabs} />
    </div>
  )
}

PaymentLink.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  tabs: PropTypes.array,
  actions: PropTypes.array,
}

export default PaymentLink
