import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddNewDomainForm from './AddNewDomainForm'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const [
    identityId,
    merchantName,
  ] = getMany(props, [
    'identityId',
    'merchantName',
  ])

  return {
    identityId,
    merchantName,
  }
}

class AddNewDomainFormC extends Component {
  render() {
    return (
      <AddNewDomainForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AddNewDomainFormC)
