import invert from 'lodash/invert'
import map from 'lodash/map'
import get from 'lodash/get'
import selectOption from 'utilities/forms/selectOption'

import {
  t,
  COUNTRY,
  SELECT_STATE,
} from 'constants/language/languageConstants'

// ISO3 Country Codes
export const USA = 'USA'
export const AFG = 'AFG'
export const ALA = 'ALA'
export const ALB = 'ALB'
export const DZA = 'DZA'
export const ASM = 'ASM'
export const AND = 'AND'
export const AGO = 'AGO'
export const AIA = 'AIA'
export const ATA = 'ATA'
export const ATG = 'ATG'
export const ARG = 'ARG'
export const ARM = 'ARM'
export const ABW = 'ABW'
export const AUS = 'AUS'
export const AUT = 'AUT'
export const AZE = 'AZE'
export const BHS = 'BHS'
export const BHR = 'BHR'
export const BGD = 'BGD'
export const BRB = 'BRB'
export const BLR = 'BLR'
export const BEL = 'BEL'
export const BLZ = 'BLZ'
export const BEN = 'BEN'
export const BMU = 'BMU'
export const BTN = 'BTN'
export const BOL = 'BOL'
export const BES = 'BES'
export const BIH = 'BIH'
export const BWA = 'BWA'
export const BVT = 'BVT'
export const BRA = 'BRA'
export const IOT = 'IOT'
export const BRN = 'BRN'
export const BGR = 'BGR'
export const BFA = 'BFA'
export const BDI = 'BDI'
export const KHM = 'KHM'
export const CMR = 'CMR'
export const CAN = 'CAN'
export const CPV = 'CPV'
export const CYM = 'CYM'
export const CAF = 'CAF'
export const TCD = 'TCD'
export const CHL = 'CHL'
export const CHN = 'CHN'
export const CXR = 'CXR'
export const CCK = 'CCK'
export const COL = 'COL'
export const COM = 'COM'
export const COG = 'COG'
export const COD = 'COD'
export const COK = 'COK'
export const CRI = 'CRI'
export const CIV = 'CIV'
export const HRV = 'HRV'
export const CUB = 'CUB'
export const CUW = 'CUW'
export const CYP = 'CYP'
export const CZE = 'CZE'
export const DNK = 'DNK'
export const DJI = 'DJI'
export const DMA = 'DMA'
export const DOM = 'DOM'
export const ECU = 'ECU'
export const EGY = 'EGY'
export const SLV = 'SLV'
export const GNQ = 'GNQ'
export const ERI = 'ERI'
export const EST = 'EST'
export const ETH = 'ETH'
export const FLK = 'FLK'
export const FRO = 'FRO'
export const FJI = 'FJI'
export const FIN = 'FIN'
export const FRA = 'FRA'
export const GUF = 'GUF'
export const PYF = 'PYF'
export const ATF = 'ATF'
export const GAB = 'GAB'
export const GMB = 'GMB'
export const GEO = 'GEO'
export const DEU = 'DEU'
export const GHA = 'GHA'
export const GIB = 'GIB'
export const GRC = 'GRC'
export const GRL = 'GRL'
export const GRD = 'GRD'
export const GLP = 'GLP'
export const GUM = 'GUM'
export const GTM = 'GTM'
export const GGY = 'GGY'
export const GIN = 'GIN'
export const GNB = 'GNB'
export const GUY = 'GUY'
export const HTI = 'HTI'
export const HMD = 'HMD'
export const VAT = 'VAT'
export const HND = 'HND'
export const HKG = 'HKG'
export const HUN = 'HUN'
export const ISL = 'ISL'
export const IND = 'IND'
export const IDN = 'IDN'
export const IRN = 'IRN'
export const IRQ = 'IRQ'
export const IRL = 'IRL'
export const IMN = 'IMN'
export const ISR = 'ISR'
export const ITA = 'ITA'
export const JAM = 'JAM'
export const JPN = 'JPN'
export const JEY = 'JEY'
export const JOR = 'JOR'
export const KAZ = 'KAZ'
export const KEN = 'KEN'
export const KIR = 'KIR'
export const PRK = 'PRK'
export const KOR = 'KOR'
export const KWT = 'KWT'
export const KGZ = 'KGZ'
export const LAO = 'LAO'
export const LVA = 'LVA'
export const LBN = 'LBN'
export const LSO = 'LSO'
export const LBR = 'LBR'
export const LBY = 'LBY'
export const LIE = 'LIE'
export const LTU = 'LTU'
export const LUX = 'LUX'
export const MAC = 'MAC'
export const MKD = 'MKD'
export const MDG = 'MDG'
export const MWI = 'MWI'
export const MYS = 'MYS'
export const MDV = 'MDV'
export const MLI = 'MLI'
export const MLT = 'MLT'
export const MHL = 'MHL'
export const MTQ = 'MTQ'
export const MRT = 'MRT'
export const MUS = 'MUS'
export const MYT = 'MYT'
export const MEX = 'MEX'
export const FSM = 'FSM'
export const MDA = 'MDA'
export const MCO = 'MCO'
export const MNG = 'MNG'
export const MNE = 'MNE'
export const MSR = 'MSR'
export const MAR = 'MAR'
export const MOZ = 'MOZ'
export const MMR = 'MMR'
export const NAM = 'NAM'
export const NRU = 'NRU'
export const NPL = 'NPL'
export const NLD = 'NLD'
export const NCL = 'NCL'
export const NZL = 'NZL'
export const NIC = 'NIC'
export const NER = 'NER'
export const NGA = 'NGA'
export const NIU = 'NIU'
export const NFK = 'NFK'
export const MNP = 'NMP'
export const NOR = 'NOR'
export const OMN = 'OMN'
export const PAK = 'PAK'
export const PLW = 'PLW'
export const PSE = 'PSE'
export const PAN = 'PAN'
export const PNG = 'PNG'
export const PRY = 'PRY'
export const PER = 'PER'
export const PHL = 'PHL'
export const PCN = 'PCN'
export const POL = 'POL'
export const PRT = 'PRT'
export const PRI = 'PRI'
export const QAT = 'PRT'
export const REU = 'REU'
export const ROU = 'ROU'
export const RUS = 'RUS'
export const RWA = 'RWA'
export const BLM = 'BLM'
export const SHN = 'SHN'
export const KNA = 'KNA'
export const LCA = 'LCA'
export const MAF = 'MAF'
export const SPM = 'SPM'
export const VCT = 'VCT'
export const WSM = 'WSM'
export const SMR = 'SMR'
export const STP = 'STP'
export const SAU = 'SAU'
export const SEN = 'SEN'
export const SRB = 'SRB'
export const SYC = 'SYC'
export const SLE = 'SLE'
export const SGP = 'SGP'
export const SXM = 'SXM'
export const SVK = 'SVK'
export const SVN = 'SVN'
export const SLB = 'SLB'
export const SOM = 'SOM'
export const ZAF = 'ZAF'
export const SGS = 'SGS'
export const SSD = 'SSD'
export const ESP = 'ESP'
export const LKA = 'LKA'
export const SDN = 'SDN'
export const SUR = 'SUR'
export const SJM = 'SJM'
export const SWZ = 'SWZ'
export const SWE = 'SWE'
export const CHE = 'CHE'
export const SYR = 'SYR'
export const TWN = 'TWN'
export const TJK = 'TJK'
export const TZA = 'TZA'
export const THA = 'THA'
export const TLS = 'TLS'
export const TGO = 'TGO'
export const TKL = 'TKL'
export const TON = 'TON'
export const TTO = 'TTO'
export const TUN = 'TUN'
export const TUR = 'TUR'
export const TKM = 'TKM'
export const TCA = 'TCA'
export const TUV = 'TUV'
export const UGA = 'UGA'
export const UKR = 'UKR'
export const ARE = 'ARE'
export const GBR = 'GBR'
export const UMI = 'UMI'
export const URY = 'URY'
export const UZB = 'UZB'
export const VUT = 'VUT'
export const VEN = 'VEN'
export const VNM = 'VNM'
export const VGB = 'VGB'
export const VIR = 'VIR'
export const WLF = 'WLF'
export const ESH = 'ESH'
export const YEM = 'YEM'
export const ZMB = 'ZMB'
export const ZWE = 'ZWE'
export const XKX = 'XKX'
export const ANT = 'ANT'

export const countryOptionsForFlex = [
  selectOption('Select One', ''),
  selectOption('United States', USA),
  selectOption('Canada', CAN),
]

export const countryNameByCountryCode = {
  [AFG]: 'Afghanistan',
  [ALA]: 'Åland Islands',
  [ALB]: 'Albania',
  [DZA]: 'Algeria',
  [ASM]: 'American Samoa',
  [AND]: 'Andorra',
  [AGO]: 'Angola',
  [AIA]: 'Anguilla',
  [ATA]: 'Antarctica',
  [ATG]: 'Antigua and Barbuda',
  [ARG]: 'Argentina',
  [ARM]: 'Armenia',
  [ABW]: 'Aruba',
  [AUS]: 'Australia',
  [AUT]: 'Austria',
  [AZE]: 'Azerbaijan',
  [BHS]: 'Bahamas',
  [BHR]: 'Bahrain',
  [BGD]: 'Bangladesh',
  [BRB]: 'Barbados',
  [BLR]: 'Belarus',
  [BEL]: 'Belgium',
  [BLZ]: 'Belize',
  [BEN]: 'Benin',
  [BMU]: 'Bermuda',
  [BTN]: 'Bhutan',
  [BOL]: 'Bolivia',
  [BES]: 'Bonaire, Sint Eustatius and Saba',
  [BIH]: 'Bosnia and Herzegovina',
  [BWA]: 'Botswana',
  [BVT]: 'Bouvet Island',
  [BRA]: 'Brazil',
  [IOT]: 'British Indian Ocean Territory',
  [BRN]: 'Brunei Darussalam',
  [BGR]: 'Bulgaria',
  [BFA]: 'Burkina Faso',
  [BDI]: 'Burundi',
  [KHM]: 'Cambodia',
  [CMR]: 'Cameroon',
  [CAN]: 'Canada',
  [CPV]: 'Cape Verde',
  [CYM]: 'Cayman Islands',
  [CAF]: 'Central African Republic',
  [TCD]: 'Chad',
  [CHL]: 'Chile',
  [CHN]: 'China',
  [CXR]: 'Christmas Island',
  [CCK]: 'Cocos (Keeling) Islands',
  [COL]: 'Colombia',
  [COM]: 'Comoros',
  [COG]: 'Congo',
  [COD]: 'Congo, the Democratic Republic of the',
  [COK]: 'Cook Islands',
  [CRI]: 'Costa Rica',
  [CIV]: 'Cote D\'Ivoire',
  [HRV]: 'Croatia',
  [CUB]: 'Cuba',
  [CUW]: 'Curaçao',
  [CYP]: 'Cyprus',
  [CZE]: 'Czech Republic',
  [DNK]: 'Denmark',
  [DJI]: 'Djibouti',
  [DMA]: 'Dominica',
  [DOM]: 'Dominican Republic',
  [ECU]: 'Ecuador',
  [EGY]: 'Egypt',
  [SLV]: 'El Salvador',
  [GNQ]: 'Equatorial Guinea',
  [ERI]: 'Eritrea',
  [EST]: 'Estonia',
  [ETH]: 'Ethiopia',
  [FLK]: 'Falkland Islands (Malvinas)',
  [FRO]: 'Faroe Islands',
  [FJI]: 'Fiji',
  [FIN]: 'Finland',
  [FRA]: 'France',
  [GUF]: 'French Guiana',
  [PYF]: 'French Polynesia',
  [ATF]: 'French Southern Territories',
  [GAB]: 'Gabon',
  [GMB]: 'Gambia',
  [GEO]: 'Georgia',
  [DEU]: 'Germany',
  [GHA]: 'Ghana',
  [GIB]: 'Gibraltar',
  [GRC]: 'Greece',
  [GRL]: 'Greenland',
  [GRD]: 'Grenada',
  [GLP]: 'Guadeloupe',
  [GUM]: 'Guam',
  [GTM]: 'Guatemala',
  [GGY]: 'Guernsey',
  [GIN]: 'Guinea',
  [GNB]: 'Guinea-Bissau',
  [GUY]: 'Guyana',
  [HTI]: 'Haiti',
  [HMD]: 'Heard Island and Mcdonald Islands',
  [VAT]: 'Holy See (Vatican City State)',
  [HND]: 'Honduras',
  [HKG]: 'Hong Kong',
  [HUN]: 'Hungary',
  [ISL]: 'Iceland',
  [IND]: 'India',
  [IDN]: 'Indonesia',
  [IRN]: 'Iran, Islamic Republic of',
  [IRQ]: 'Iraq',
  [IRL]: 'Ireland',
  [IMN]: 'Isle of Man',
  [ISR]: 'Israel',
  [ITA]: 'Italy',
  [JAM]: 'Jamaica',
  [JPN]: 'Japan',
  [JEY]: 'Jersey',
  [JOR]: 'Jordan',
  [KAZ]: 'Kazakhstan',
  [KEN]: 'Kenya',
  [KIR]: 'Kiribati',
  [PRK]: 'Korea, Democratic People\'s Republic of',
  [KOR]: 'Korea, Republic of',
  [KWT]: 'Kuwait',
  [KGZ]: 'Kyrgyzstan',
  [LAO]: 'Lao People\'s Democratic Republic',
  [LVA]: 'Latvia',
  [LBN]: 'Lebanon',
  [LSO]: 'Lesotho',
  [LBR]: 'Liberia',
  [LBY]: 'Libyan Arab Jamahiriya',
  [LIE]: 'Liechtenstein',
  [LTU]: 'Lithuania',
  [LUX]: 'Luxembourg',
  [MAC]: 'Macao',
  [MKD]: 'Macedonia, the Former Yugoslav Republic of',
  [MDG]: 'Madagascar',
  [MWI]: 'Malawi',
  [MYS]: 'Malaysia',
  [MDV]: 'Maldives',
  [MLI]: 'Mali',
  [MLT]: 'Malta',
  [MHL]: 'Marshall Islands',
  [MTQ]: 'Martinique',
  [MRT]: 'Mauritania',
  [MUS]: 'Mauritius',
  [MYT]: 'Mayotte',
  [MEX]: 'Mexico',
  [FSM]: 'Micronesia, Federated States of',
  [MDA]: 'Moldova, Republic of',
  [MCO]: 'Monaco',
  [MNG]: 'Mongolia',
  [MNE]: 'Montenegro',
  [MSR]: 'Montserrat',
  [MAR]: 'Morocco',
  [MOZ]: 'Mozambique',
  [MMR]: 'Myanmar',
  [NAM]: 'Namibia',
  [NRU]: 'Nauru',
  [NPL]: 'Nepal',
  [NLD]: 'Netherlands',
  [NCL]: 'New Caledonia',
  [NZL]: 'New Zealand',
  [NIC]: 'Nicaragua',
  [NER]: 'Niger',
  [NGA]: 'Nigeria',
  [NIU]: 'Niue',
  [NFK]: 'Norfolk Island',
  [MNP]: 'Northern Mariana Islands',
  [NOR]: 'Norway',
  [OMN]: 'Oman',
  [PAK]: 'Pakistan',
  [PLW]: 'Palau',
  [PSE]: 'Palestinian Territory, Occupied',
  [PAN]: 'Panama',
  [PNG]: 'Papua New Guinea',
  [PRY]: 'Paraguay',
  [PER]: 'Peru',
  [PHL]: 'Philippines',
  [PCN]: 'Pitcairn',
  [POL]: 'Poland',
  [PRT]: 'Portugal',
  [PRI]: 'Puerto Rico',
  [QAT]: 'Qatar',
  [REU]: 'Reunion',
  [ROU]: 'Romania',
  [RUS]: 'Russian Federation',
  [RWA]: 'Rwanda',
  [BLM]: 'Saint Barthélemy',
  [SHN]: 'Saint Helena',
  [KNA]: 'Saint Kitts and Nevis',
  [LCA]: 'Saint Lucia',
  [MAF]: 'Saint Martin (French part)',
  [SPM]: 'Saint Pierre and Miquelon',
  [VCT]: 'Saint Vincent and the Grenadines',
  [WSM]: 'Samoa',
  [SMR]: 'San Marino',
  [STP]: 'Sao Tome and Principe',
  [SAU]: 'Saudi Arabia',
  [SEN]: 'Senegal',
  [SRB]: 'Serbia',
  [SYC]: 'Seychelles',
  [SLE]: 'Sierra Leone',
  [SGP]: 'Singapore',
  [SXM]: 'Sint Maarten (Dutch part)',
  [SVK]: 'Slovakia',
  [SVN]: 'Slovenia',
  [SLB]: 'Solomon Islands',
  [SOM]: 'Somalia',
  [ZAF]: 'South Africa',
  [SGS]: 'South Georgia and the South Sandwich Islands',
  [SSD]: 'South Sudan',
  [ESP]: 'Spain',
  [LKA]: 'Sri Lanka',
  [SDN]: 'Sudan',
  [SUR]: 'Suriname',
  [SJM]: 'Svalbard and Jan Mayen',
  [SWZ]: 'Swaziland',
  [SWE]: 'Sweden',
  [CHE]: 'Switzerland',
  [SYR]: 'Syrian Arab Republic',
  [TWN]: 'Taiwan, Province of China',
  [TJK]: 'Tajikistan',
  [TZA]: 'Tanzania, United Republic of',
  [THA]: 'Thailand',
  [TLS]: 'Timor-Leste',
  [TGO]: 'Togo',
  [TKL]: 'Tokelau',
  [TON]: 'Tonga',
  [TTO]: 'Trinidad and Tobago',
  [TUN]: 'Tunisia',
  [TUR]: 'Turkey',
  [TKM]: 'Turkmenistan',
  [TCA]: 'Turks and Caicos Islands',
  [TUV]: 'Tuvalu',
  [UGA]: 'Uganda',
  [UKR]: 'Ukraine',
  [ARE]: 'United Arab Emirates',
  [GBR]: 'United Kingdom',
  [USA]: 'United States',
  [UMI]: 'United States Minor Outlying Islands',
  [URY]: 'Uruguay',
  [UZB]: 'Uzbekistan',
  [VUT]: 'Vanuatu',
  [VEN]: 'Venezuela',
  [VNM]: 'Viet Nam',
  [VGB]: 'Virgin Islands, British',
  [VIR]: 'Virgin Islands, U.s.',
  [WLF]: 'Wallis and Futuna',
  [ESH]: 'Western Sahara',
  [YEM]: 'Yemen',
  [ZMB]: 'Zambia',
  [ZWE]: 'Zimbabwe',
  [XKX]: 'Kosovo',
  [ANT]: 'Netherlands Antilles',
}

export const countryISO2toISO3 = {
  AF: AFG,
  AX: ALA,
  AL: ALB,
  DZ: DZA,
  AS: ASM,
  AD: AND,
  AO: AGO,
  AI: AIA,
  AQ: ATA,
  AG: ATG,
  AR: ARG,
  AM: ARM,
  AW: ABW,
  AU: AUS,
  AT: AUT,
  AZ: AZE,
  BS: BHS,
  BH: BHR,
  BD: BGD,
  BB: BRB,
  BY: BLR,
  BE: BEL,
  BZ: BLZ,
  BJ: BEN,
  BM: BMU,
  BT: BTN,
  BO: BOL,
  BA: BIH,
  BW: BWA,
  BV: BVT,
  BR: BRA,
  VG: VGB,
  IO: IOT,
  BN: BRN,
  BG: BGR,
  BF: BFA,
  BI: BDI,
  KH: KHM,
  CM: CMR,
  CA: CAN,
  CV: CPV,
  KY: CYM,
  CF: CAF,
  TD: TCD,
  CL: CHL,
  CN: CHN,
  HK: HKG,
  MO: MAC,
  CX: CXR,
  CC: CCK,
  CO: COL,
  KM: COM,
  CG: COG,
  CD: COD,
  CK: COK,
  CR: CRI,
  CI: CIV,
  HR: HRV,
  CU: CUB,
  CY: CYP,
  CZ: CZE,
  DK: DNK,
  DJ: DJI,
  DM: DMA,
  DO: DOM,
  EC: ECU,
  EG: EGY,
  SV: SLV,
  GQ: GNQ,
  ER: ERI,
  EE: EST,
  ET: ETH,
  FK: FLK,
  FO: FRO,
  FJ: FJI,
  FI: FIN,
  FR: FRA,
  GF: GUF,
  PF: PYF,
  TF: ATF,
  GA: GAB,
  GM: GMB,
  GE: GEO,
  DE: DEU,
  GH: GHA,
  GI: GIB,
  GR: GRC,
  GL: GRL,
  GD: GRD,
  GP: GLP,
  GU: GUM,
  GT: GTM,
  GG: GGY,
  GN: GIN,
  GW: GNB,
  GY: GUY,
  HT: HTI,
  HM: HMD,
  VA: VAT,
  HN: HND,
  HU: HUN,
  IS: ISL,
  IN: IND,
  ID: IDN,
  IR: IRN,
  IQ: IRQ,
  IE: IRL,
  IM: IMN,
  IL: ISR,
  IT: ITA,
  JM: JAM,
  JP: JPN,
  JE: JEY,
  JO: JOR,
  KZ: KAZ,
  KE: KEN,
  KI: KIR,
  KP: PRK,
  KR: KOR,
  KW: KWT,
  KG: KGZ,
  LA: LAO,
  LV: LVA,
  LB: LBN,
  LS: LSO,
  LR: LBR,
  LY: LBY,
  LI: LIE,
  LT: LTU,
  LU: LUX,
  MK: MKD,
  MG: MDG,
  MW: MWI,
  MY: MYS,
  MV: MDV,
  ML: MLI,
  MT: MLT,
  MH: MHL,
  MQ: MTQ,
  MR: MRT,
  MU: MUS,
  YT: MYT,
  MX: MEX,
  FM: FSM,
  MD: MDA,
  MC: MCO,
  MN: MNG,
  ME: MNE,
  MS: MSR,
  MA: MAR,
  MZ: MOZ,
  MM: MMR,
  NA: NAM,
  NR: NRU,
  NP: NPL,
  NL: NLD,
  AN: ANT,
  NC: NCL,
  NZ: NZL,
  NI: NIC,
  NE: NER,
  NG: NGA,
  NU: NIU,
  NF: NFK,
  MP: MNP,
  NO: NOR,
  OM: OMN,
  PK: PAK,
  PW: PLW,
  PS: PSE,
  PA: PAN,
  PG: PNG,
  PY: PRY,
  PE: PER,
  PH: PHL,
  PN: PCN,
  PL: POL,
  PT: PRT,
  PR: PRI,
  QA: QAT,
  RE: REU,
  RO: ROU,
  RU: RUS,
  RW: RWA,
  BL: BLM,
  SH: SHN,
  KN: KNA,
  LC: LCA,
  MF: MAF,
  PM: SPM,
  VC: VCT,
  WS: WSM,
  SM: SMR,
  ST: STP,
  SA: SAU,
  SN: SEN,
  RS: SRB,
  SC: SYC,
  SL: SLE,
  SG: SGP,
  SK: SVK,
  SI: SVN,
  SB: SLB,
  SO: SOM,
  ZA: ZAF,
  GS: SGS,
  SS: SSD,
  ES: ESP,
  LK: LKA,
  SD: SDN,
  SR: SUR,
  SJ: SJM,
  SZ: SWZ,
  SE: SWE,
  CH: CHE,
  SY: SYR,
  TW: TWN,
  TJ: TJK,
  TZ: TZA,
  TH: THA,
  TL: TLS,
  TG: TGO,
  TK: TKL,
  TO: TON,
  TT: TTO,
  TN: TUN,
  TR: TUR,
  TM: TKM,
  TC: TCA,
  TV: TUV,
  UG: UGA,
  UA: UKR,
  AE: ARE,
  GB: GBR,
  US: USA,
  UM: UMI,
  UY: URY,
  UZ: UZB,
  VU: VUT,
  VE: VEN,
  VN: VNM,
  VI: VIR,
  WF: WLF,
  EH: ESH,
  YE: YEM,
  ZM: ZMB,
  ZW: ZWE,
  XK: XKX
}

export const countryISO3toISO2 = invert(countryISO2toISO3)

export const countryToRegionLabelMap = {
  [USA]: 'State',
  [CAN]: 'Province',
}

export const defaultRegionLabel = 'Region'

export const countryToPostalCodeLabelMap = {
  [USA]: 'Zip Code',
  [CAN]: 'Postal Code',
}

export const defaultPostalCodeLabel = 'Postal Code'

export const stateNamesByStateCode = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export const provincesByStateCode = {
  AB: 'Alberta',
  NL: 'Newfoundland and Labrador',
  SK: 'Saskatchewan',
  PE: 'Prince Edward Island',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NS: 'Nova Scotia',
  QC: 'Quebec',
  ON: 'Ontario',
}

export const countryCodeByCountryName = invert(countryNameByCountryCode)

export const countryNamesOptions = [selectOption(t(COUNTRY), ''), ...map(countryNameByCountryCode, (countryName, countryCode) => {
  return { label: countryName, value: countryCode }
})]

export const stateCodeByStateName = invert(stateNamesByStateCode)

export const stateNameOptions = [selectOption(t(SELECT_STATE), ''), ...map(stateNamesByStateCode, (stateName, stateCode) => {
  return { label: stateName, value: stateCode }
})]

const regionsByRegionCodeCountryMap = {
  [USA]: stateNamesByStateCode,
  [CAN]: provincesByStateCode,
}

export const regionNameOptionsByCountryCode = (countryCode) => {
  const regionNamesByCountryCode = regionsByRegionCodeCountryMap[countryCode]

  if (!regionNamesByCountryCode) return null

  const defaultRegionLabel = get(countryToRegionLabelMap, countryCode, defaultRegionLabel)

  return [
    selectOption(t(`Select ${defaultRegionLabel}`), ''),
    ...map(regionNamesByCountryCode, (regionName, regionCode) => ({ label: regionName, value: regionCode }))
  ]
}

export const convertCountryISO2toISO3 = (countryCode) => {
  return countryISO2toISO3[countryCode]
}

export const convertCountryISO3toISO2 = (countryCode) => {
  const ISO3toISO2Map = invert(countryISO2toISO3)

  return ISO3toISO2Map[countryCode]
}
