import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import ComplianceValidationAndAttestationDetailsForm from './ComplianceValidationAndAttestationDetailsForm'
import { COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM)
  const pciDssValidation = formSelector(state, 'pciDssValidation')
  const doNotUserVendorSuppliedDefaults = formSelector(state, 'doNotUserVendorSuppliedDefaults')
  const developAndMaintainSecureSystems = formSelector(state, 'developAndMaintainSecureSystems')
  const identifyAndAuthenticateAccessToSystemComponents = formSelector(state, 'identifyAndAuthenticateAccessToSystemComponents')
  const restrictPhysicalAccessToCardholder = formSelector(state, 'restrictPhysicalAccessToCardholder')
  const maintainAPolicyAddressesInformationSecurity = formSelector(state, 'maintainAPolicyAddressesInformationSecurity')

  return {
    pciDssValidation,
    doNotUserVendorSuppliedDefaults,
    developAndMaintainSecureSystems,
    identifyAndAuthenticateAccessToSystemComponents,
    restrictPhysicalAccessToCardholder,
    maintainAPolicyAddressesInformationSecurity,
  }
}

class ComplianceValidationAndAttestationDetailsFormC extends Component {
  render() {
    return (
      <ComplianceValidationAndAttestationDetailsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceValidationAndAttestationDetailsFormC)
