import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import GenericForm from 'components/Customer/Forms/GenericForm/GenericForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import validateAssociatedIdentityForm from 'utilities/validate/validateAssociatedIdentityForm'
import { OWNERSHIP_TYPE_OPTIONS } from 'constants/identityConstants'

import {
  EMAIL_ADDRESS,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

const sections = [
  {
    title: 'Personal Information',
    inputs: [
      {
        props: {
          name: 'firstName',
          label: 'First Name',
          placeholder: 'John',
          component: InputField,
        },
      },
      {
        props: {
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Smith',
          component: InputField,
        },
      },
      {
        props: {
          name: 'title',
          label: 'Title',
          placeholder: 'e.g CEO, Manager',
          component: InputField,
        },
      },
      {
        fieldComponent: DateFieldset,
        props: {
          name: 'dateOfBirth',
          label: 'Date of Birth',
        },
      },
      {
        fieldComponent: AddressFieldset,
        props: {
          name: 'personalAddress',
          label: 'Address',
        },
      },
      {
        props: {
          name: 'ssn',
          label: SOCIAL_SECURITY_NUMBER,
          placeholder: '000-00-0000',
          component: InputField,
        },
      },
      {
        props: {
          name: 'principalPercentageOwnership',
          label: 'Principal Percentage Ownership (%)',
          placeholder: '10',
          component: InputField,
        },
      },
      {
        props: {
          name: 'ownershipType',
          label: 'Ownership Type',
          component: SelectField,
          options: OWNERSHIP_TYPE_OPTIONS,
        },
      },
      {
        props: {
          name: 'phone',
          label: 'Personal Phone',
          component: InputField,
        },
      },
      {
        props: {
          name: 'email',
          label: EMAIL_ADDRESS,
          component: InputField,
        },
      },
    ],
  },
]

const AssociatedIdentitiesForm = ({
  submitting = false,
  submitLabel = 'Create',
  handleSubmit = () => {},
  leftButton = false,
  leftButtonLabel = 'Back',
  leftButtonAction = () => {},
}) => {
  return (
    <GenericForm
      handleSubmit={handleSubmit}
      sections={sections}
      submitLabel={submitLabel}
      submitting={submitting}
      leftButton={leftButton}
      leftButtonLabel={leftButtonLabel}
      leftButtonAction={leftButtonAction}
    />
  )
}

AssociatedIdentitiesForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
}

export default reduxForm({
  form: 'ASSOCIATED_IDENTITIES_FORM',
  validate: validateAssociatedIdentityForm,
})(AssociatedIdentitiesForm)
