import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentLinkPayments from './PaymentLinkPayments'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { PURCHASES_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  DATE_QUICK_FILTER,
  PAYMENT_LINK_PAYMENTS_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const quickFilters = [
  PAYMENT_LINK_PAYMENTS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const { paymentLinkId } = props
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const purchasesPath = PURCHASES_PATH({ credentialId })

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    entity_id: paymentLinkId,
  }

  return {
    credentials,
    initialQueries,
    initialFilters: createdLast3MonthQueries(),
    isFetching,
    quickFilters,
    purchasesPath,
  }
}

class PaymentLinkPaymentsC extends Component {
  render() {
    return (
      <PaymentLinkPayments {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PaymentLinkPaymentsC)
