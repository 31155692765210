import './ComplianceFormTemplatesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { COMPLIANCE_FORM_TEMPLATES } from 'constants/linkConstants'
import { GET_COMPLIANCE_FORM_TEMPLATES } from 'constants/flowConstants'
import { COMPLIANCE_FORMS_ICON } from 'constants/iconConstants'
import { getComplianceFormTemplatesLinkedToSelector } from 'state-layer/selectors'

const ComplianceFormTemplates = ({
  complianceFormTemplatesPath = '',
  allowedFilters,
  isFetching = false,
  showLimitCount = false,
  actions = [],
  selectedItemsKey = '',
  selectSingleItem = false,
  quickFilters = [],
}) => {
  return (
    <div className='ComplianceFormTemplates'>
      <TableC
        flow={GET_COMPLIANCE_FORM_TEMPLATES}
        columnDescriptors={columnDescriptors}
        selector={getComplianceFormTemplatesLinkedToSelector}
        linksKey={COMPLIANCE_FORM_TEMPLATES}
        allowedFilters={allowedFilters}
        path={selectSingleItem ? undefined : complianceFormTemplatesPath}
        isFetching={isFetching}
        emptyMessage='No Compliance Form Templates Available'
        icon={`fa fa-${COMPLIANCE_FORMS_ICON}`}
        fileNameCSV={selectSingleItem ? undefined : 'Compliance_Form_Templates'}
        showLimitCount={showLimitCount}
        actions={actions}
        selectedItemsKey={selectedItemsKey}
        selectSingleItem={selectSingleItem}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ComplianceFormTemplates.propTypes = {
  complianceFormTemplatesPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  isFetching: PropTypes.bool,
  showLimitCount: PropTypes.bool,
  actions: PropTypes.array,
  selectedItemsKey: PropTypes.string,
  selectSingleItem: PropTypes.bool,
  quickFilters: PropTypes.array,
}

export default ComplianceFormTemplates
