import './AdminUsersS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { getUsersSelector } from 'state-layer/selectors'
import columnDescriptors from './columnDescriptors'
import { ADMIN_USERS } from 'constants/linkConstants'
import { GET_DASHBOARD_USERS } from 'constants/flowConstants'
import { USERS_ICON } from 'constants/iconConstants'

import {
  ADMIN_USERS_SUBTITLE_DESCRIPTION,
  DASHBOARD_USER_MANAGEMENT,
} from 'constants/language/languageConstants'

const AdminUsers = ({
  usersPath = '',
  initialQueries,
  isFetching = false,
  actions = [],
  allowedFilters = [],
}) => {
  return (
    <div className='AdminUsers'>
      <HeaderV2C
        title={DASHBOARD_USER_MANAGEMENT}
        subTitle={ADMIN_USERS_SUBTITLE_DESCRIPTION}
      />

      <TableC
        flow={GET_DASHBOARD_USERS}
        columnDescriptors={columnDescriptors}
        selector={getUsersSelector}
        initialQueries={initialQueries}
        linksKey={ADMIN_USERS}
        path={usersPath}
        isFetching={isFetching}
        actions={actions}
        icon={`fa fa-${USERS_ICON}`}
        emptyMessage='No Users Available'
        allowedFilters={allowedFilters}
      />
    </div>
  )
}

AdminUsers.propTypes = {
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  usersPath: PropTypes.string,
  actions: PropTypes.array,
  allowedFilters: PropTypes.array,
}

export default AdminUsers
