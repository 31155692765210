import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportsTable from './ReportsTable'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import camelCaseToTitleCase from 'utilities/display/camelCaseToTitleCase'
import pluralizeWord from 'utilities/pluralizeWord'
import downloadReport from 'utilities/downloadReport'
import removeUndefined from 'utilities/remove/removeUndefined'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { FETCHING } from 'constants/reducerConstants'
import { REPORTS } from 'constants/selectedItemsConstants'
import { UNSELECT_ALL_ITEMS } from 'constants/flowConstants'
import { SHOW_REPORTS_LANDING } from 'constants/featureFlagConstants'
import { REPORTS_HOME_PATH } from 'constants/pathConstants'
import { REPORTS_RESOURCE_TITLE } from 'constants/language/languageConstants'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'

import {
  getSelectedItemsByKeySelector,
  getReportsSelector,
} from 'state-layer/selectors'

import {
  REPORT_TYPE_TO_DESCRIPTION_MAPPING,
  REPORT_TYPE_TO_LEARN_MORE_LINK_MAPPING,
  REPORT_CATEGORY_TO_API_FIELD_MAPPING,
  REPORT_TYPE_TO_API_FIELD_MAPPING,
} from 'constants/reportingConstants'

import {
  END_DATE,
  START_DATE,
  REPORT_FREQUENCY,
  REPORT_VERSION,
  REPORT_CATEGORY,
  REPORT_SUBCATEGORY,
} from 'constants/filterConstants'
import size from 'lodash/size'
import formatMoney from '../../../../../utilities/formatters/formatMoney'

const selectedItemsKey = REPORTS

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `reportsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const reports = getReportsSelector(state)
  const reportCategory = camelCaseToTitleCase(camelCase(get(props, 'params.reportCategory', '')))
  const reportType = camelCaseToTitleCase(camelCase(get(props, 'params.reportType', '')))

  const description = REPORT_TYPE_TO_DESCRIPTION_MAPPING[reportType]
  const learnMoreLink = REPORT_TYPE_TO_LEARN_MORE_LINK_MAPPING[reportType]
  const selectedReportIds = Object.keys(getSelectedItemsByKeySelector(state, selectedItemsKey))
  const totalSelectedReports = selectedReportIds.length
  const showCheckboxes = getFeatureFlag(SHOW_REPORTS_LANDING)

  const initialQueries = removeUndefined({
    category: !isEmpty(reportCategory) ? REPORT_CATEGORY_TO_API_FIELD_MAPPING[reportCategory] : undefined,
    subcategory: !isEmpty(reportCategory) ? REPORT_TYPE_TO_API_FIELD_MAPPING[reportType] : undefined,
  })

  const selectedReports = reduce(selectedReportIds, (reportsObjects, reportId) => {
    const reportObj = get(reports, reportId)
    return merge({}, reportsObjects, { [reportId]: reportObj })
  }, {})

  const reportV1Filters = [
    END_DATE,
    START_DATE,
  ]

  const categorizedReportFilters = [
    END_DATE,
    START_DATE,
    REPORT_FREQUENCY,
    REPORT_VERSION,
  ]

  const allReportsViewFilters = [
    END_DATE,
    START_DATE,
    REPORT_FREQUENCY,
    REPORT_VERSION,
    REPORT_CATEGORY,
    REPORT_SUBCATEGORY,
  ]

  let allowedFilters = reportV1Filters

  if (showCheckboxes) {
    allowedFilters = !isEmpty(reportCategory) ? categorizedReportFilters : allReportsViewFilters
  }

  const contextBarData = {
    back: {
      label: REPORTS_RESOURCE_TITLE,
      path: REPORTS_HOME_PATH({ credentialId }),
    },
  }

  return {
    credentials,
    credentialId,
    initialQueries,
    isFetching,
    allowedFilters,
    reportType,
    description,
    learnMoreLink,
    selectedReportIds,
    totalSelectedReports,
    selectedReports,
    showCheckboxes,
    contextBarData,
  }
}

class ReportsTableC extends Component {
  downloadSelectedReports = async () => {
    const { dispatch, credentials, selectedReports } = this.props

    forEach(selectedReports, ({ id, fileName }) => {
      downloadReport({ id, name: fileName, credentials })
    })

    dispatch({ type: UNSELECT_ALL_ITEMS })
  }

  render() {
    const { totalSelectedReports } = this.props

    const selectedItemsData = getFeatureFlag(SHOW_REPORTS_LANDING) ? {
      actions: [
        {
          label: `Download ${pluralizeWord({ count: totalSelectedReports, word: 'Report' })}`,
          action: this.downloadSelectedReports,
        },
      ],
      info: (
        <div className='reports-info flex'>
          <div><span>Selected:</span> {totalSelectedReports}</div>
        </div>
      ),
    } : undefined

    return (
      <ReportsTable
        {...this.props}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps)(ReportsTableC)
