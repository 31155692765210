import React, { Component } from 'react'
import { connect } from 'react-redux'
import Settlements from './Settlements'
import get from 'lodash/get'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { SETTLEMENTS_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  TOTAL_AMOUNT,
  TRANSFER_ID,
  ENTRY_ID,
  SPLIT_TRANSFER_ID,
  APPLICATION_ID,
  MERCHANT_ID,
} from 'constants/filterConstants'

import {
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const allowedFilters = sortBy([
  TOTAL_AMOUNT(),
  TRANSFER_ID,
  ENTRY_ID,
  SPLIT_TRANSFER_ID,
  APPLICATION_ID,
  MERCHANT_ID,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const initialFilters = {
  ...createdLastMonthQueries(),
}

const quickFilters = [
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `settlementsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const customPath = get(props, 'customPath')
  const settlementsPath = customPath ? customPath({ credentialId }) : SETTLEMENTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const showHeader = get(props, 'showHeader', true)

  return {
    initialQueries: mergedQueries,
    initialFilters,
    isFetching,
    settlementsPath,
    allowedFilters,
    quickFilters,
    showHeader,
  }
}

class SettlementsC extends Component {
  render() {
    return (
      <Settlements {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(SettlementsC)
