import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_NOTIFICATION_SETTINGS } from 'constants/apiConstants'

const getNotificationSettingsAPI = ({ credentials }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_NOTIFICATION_SETTINGS,
    credentials,
  })
}

export default getNotificationSettingsAPI
