import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import TableC from 'components/Shared/Table/TableC'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import Loader from 'components/Shared/Loader/Loader'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import formatPaymentInstrumentPageDetailSection from 'utilities/formatters/formatPaymentInstrumentPageDetailsSection'
import { UNDERWRITING_EVENTS } from 'constants/linkConstants'
import { getAuditLogEventsSelector } from 'state-layer/selectors'

import {
  BUYER_DETAILS,
  DETAILS,
  RECIPIENT_DETAILS,
  AUDIT_LOG_EMPTY_TABLE_MESSAGE,
  AUDIT_LOGS,
} from 'constants/language/languageConstants'

import {
  GET_AUDIT_LOG_EVENTS,
  AUDIT_LOG_EVENT,
  PATCH_PAYMENT_INSTRUMENT_F_REQUEST,
} from 'constants/flowConstants'

const PaymentInstrumentInfo = ({
  paymentInstrument = {},
  tagsSectionData = [],
  isFetching = false,
  buyerIdentity = {},
  isDisbursements = false,
  initialQueries = {},
  auditLogEventPath = '',
  hasUnderwritingPermissions = false,
}) => {
  return (
    <div className='PaymentInstrumentInfo'>
      <PaymentInstrumentDetailsC
        title={DETAILS}
        isFetching={isFetching}
        paymentInstrument={paymentInstrument}
        showAside={false}
        dataSectionFormatter={formatPaymentInstrumentPageDetailSection}
      />

      <BuyerDetailsC
        title={isDisbursements ? RECIPIENT_DETAILS : BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {isFetching && <Loader />}

      {hasUnderwritingPermissions && (
        <TableC
          title={AUDIT_LOGS}
          flow={GET_AUDIT_LOG_EVENTS}
          columnDescriptors={columnDescriptors}
          selector={getAuditLogEventsSelector}
          initialQueries={initialQueries}
          linksKey={UNDERWRITING_EVENTS}
          isFetching={isFetching}
          path={auditLogEventPath}
          entityType={AUDIT_LOG_EVENT}
          emptyMessage={AUDIT_LOG_EMPTY_TABLE_MESSAGE}
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_PAYMENT_INSTRUMENT_F_REQUEST}
        data={tagsSectionData}
        record={paymentInstrument}
        isFetching={isFetching}
      />
    </div>
  )
}

PaymentInstrumentInfo.propTypes = {
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  buyerIdentity: PropTypes.object,
  initialQueries: PropTypes.object,
  auditLogEventPath: PropTypes.string,
  hasUnderwritingPermissions: PropTypes.bool,
}

export default PaymentInstrumentInfo
