import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_FEE_PROFILE_SETTINGS,
} from 'constants/apiConstants'

const postFeeProfileSettingsAPI = ({ values, id, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    credentials,
    values,
    path: APPLICATION_FEE_PROFILE_SETTINGS({ applicationId: id }),
    service: APPLICATIONS,
  })
}

export default postFeeProfileSettingsAPI
