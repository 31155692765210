import './DownloadCSRFileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { DOWNLOAD_ICON } from 'constants/iconConstants'
import { APPLE_PAY_DOWNLOAD_CSR_FILE_INSTRUCTION } from 'constants/language/languageConstants'

const DownloadCSRFileForm = ({
  downloadCSRFile = () => {},
  buttonGroup = {},
  onSubmit = () => {},
}) => {
  return (
    <form className='DownloadCSRFileForm' onSubmit={onSubmit}>
      <div className='primary-msg'>Download your Certificate Signing Request (CSR) file</div>
      <div className='msg'>{APPLE_PAY_DOWNLOAD_CSR_FILE_INSTRUCTION}</div>

      <Button className='download-csr-btn link' variant='secondary' onClick={downloadCSRFile} label='Download File' leftIcon={DOWNLOAD_ICON} />

      { buttonGroup }
    </form>
  )
}

DownloadCSRFileForm.propTypes = {
  downloadCSRFile: PropTypes.func,
  buttonGroup: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default DownloadCSRFileForm
