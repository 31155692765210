import React from 'react'
import PropTypes from 'prop-types'
import CloseSettlementFormC from 'components/Customer/Forms/CloseSettlementForm/CloseSettlementFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { CONFIRM_STOP_ACCRUING_SETTLEMENT } from 'constants/language/languageConstants'

const CloseSettlementModal = ({
  settlementId,
}) => {
  return (
    <GenericModal
      title={CONFIRM_STOP_ACCRUING_SETTLEMENT}
      className='CloseSettlementModal'
      Component={CloseSettlementFormC}
      settlementId={settlementId}
    />
  )
}

CloseSettlementModal.propTypes = {
  settlementId: PropTypes.string,
}

export default CloseSettlementModal
