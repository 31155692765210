import './VoidAuthorizationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  VOID_AUTHORIZATION,
} from 'constants/language/languageConstants'

const VoidAuthorization = ({
  displayAuthorizationAmount = '',
  voidAuthorization = () => {},
  closeModal = () => {},
}) => {
  return (
    <div className='VoidAuthorization'>
      <div className='modal-content confirmation-msg'>
        {`Please confirm that you would like to void this authorization of ${displayAuthorizationAmount}.`}
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={VOID_AUTHORIZATION} onClick={voidAuthorization} />
      </div>
    </div>
  )
}

VoidAuthorization.propTypes = {
  displayAuthorizationAmount: PropTypes.string,
  voidAuthorization: PropTypes.func,
  closeModal: PropTypes.func,
}

export default VoidAuthorization
