import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendAccessFormContractModel = ({ data: accessForm }) => {
  const [
    id,
    createdAt,
    updatedAt,
    contractName,
    contractType,
    data,
    pricingType,
    validFrom,
    validTo,
  ] = getMany(accessForm, [
    'id',
    'created_at',
    'updated_at',
    'contract_name',
    'contract_type',
    'data',
    'pricing_type',
    'valid_from',
    'valid_to',
  ])

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    contractName,
    contractType,
    data,
    pricingType,
    validFrom,
    validTo,
  })
}

export default frontendAccessFormContractModel
