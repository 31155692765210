import './UnderwritingMerchantsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getUnderwritingReviewIdentitiesSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import { UNDERWRITING_REVIEWS } from 'constants/linkConstants'
import { GET_MERCHANT_UNDERWRITING_REVIEWS } from 'constants/flowConstants'

import {
  MERCHANT_UNDERWRITING_RESOURCE_TITLE,
  UNDERWRITING_MERCHANTS_TABLE_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const UnderwritingMerchants = ({
  underwritingReviewPath = '',
  allowedFilters = [],
  initialQueries = {},
  isFetching = false,
  selector = getUnderwritingReviewIdentitiesSelector,
  quickFilters,
  initialFilters,
  columnDescriptors = [],
}) => {
  return (
    <div className='UnderwritingMerchants'>
      <TableC
        flow={GET_MERCHANT_UNDERWRITING_REVIEWS}
        columnDescriptors={columnDescriptors}
        selector={selector}
        linksKey={UNDERWRITING_REVIEWS}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        path={underwritingReviewPath}
        fileNameCSV='Underwriting_Merchants'
        fileResourceTitleCSV={MERCHANT_UNDERWRITING_RESOURCE_TITLE}
        emptyMessage={UNDERWRITING_MERCHANTS_TABLE_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

UnderwritingMerchants.propTypes = {
  underwritingReviewPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  selector: PropTypes.func,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
  columnDescriptors: PropTypes.array,
}

export default UnderwritingMerchants
