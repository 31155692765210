import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UpdateAllPermissionsFormC from 'components/Customer/Forms/UpdateAllPermissionsForm/UpdateAllPermissionsFormC'

const UpdateAllPermissionModal = ({
  user = {},
  userId = '',
  permissions = [],
}) => {
  return (
    <GenericModal
      title='Update Permissions'
      className='UpdateAllPermissionModal'
      Component={UpdateAllPermissionsFormC}
      user={user}
      userId={userId}
      permissions={permissions}
    />
  )
}

UpdateAllPermissionModal.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  permissions: PropTypes.array,
}

export default UpdateAllPermissionModal
