import './CreateSubscriptionEnrollmentModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import SelectSubscriptionScheduleFormC from 'components/Customer/Forms/SelectSubscriptionScheduleForm/SelectSubscriptionScheduleFormC'
import CreateSubscriptionEnrollmentFormC from 'components/Customer/Forms/CreateSubscriptionEnrollmentForm/CreateSubscriptionEnrollmentFormC'
import GenericModalC from 'components/Customer/Modals/GenericModal/GenericModalC'
import CreateSubscriptionConfirmation from 'components/Customer/Pages/CreateSubscriptionConfirmation/CreateSubscriptionConfirmation'
import SubscriptionEnrollmentDisplayData from 'components/Customer/Forms/CreateSubscriptionEnrollmentForm/SubscriptionEnrollmentDisplayData'
import { ENROLL_IN_EXISTING_SUBSCRIPTION } from 'constants/language/languageConstants'

import {
  CREATE_SUBSCRIPTION_ENROLLMENT_DATA_KEY,
  SELECT_SUBSCRIPTION_SCHEDULE_DATA_KEY,
} from 'constants/flowsConstants'

const CreateSubscriptionEnrollmentModal = ({
  merchantId,
  closeModal,
  credentials,
}) => {
  const steps = [
    {
      stepTitle: 'Find a Subscription Schedule',
      component: SelectSubscriptionScheduleFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
        merchantId,
        closeModal,
      },
      dataKey: SELECT_SUBSCRIPTION_SCHEDULE_DATA_KEY,
      getDisplayData: ({ subscriptionScheduleId }) => [{ value: subscriptionScheduleId, label: 'Subscription Schedule' }],
    },
    {
      stepTitle: 'Create a Subscription Enrollment',
      component: CreateSubscriptionEnrollmentFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: CREATE_SUBSCRIPTION_ENROLLMENT_DATA_KEY,
      getDisplayData: SubscriptionEnrollmentDisplayData,
    },
    {
      stepTitle: 'Confirmation',
      component: CreateSubscriptionConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
        merchantId,
      },
    },
  ]

  return (
    <GenericModalC
      title={ENROLL_IN_EXISTING_SUBSCRIPTION}
      className='CreateSubscriptionEnrollmentModal'
      steps={steps}
      credentials={credentials}
      multiSteps
    />
  )
}

CreateSubscriptionEnrollmentModal.propTypes = {
  merchantId: PropTypes.string,
  closeModal: PropTypes.func,
  credentials: PropTypes.object,
}

export default CreateSubscriptionEnrollmentModal
