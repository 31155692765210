import './CustomerOnboardingFormWelcomeFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import submitCustomerOnboardingFormWelcomeStepForm from 'utilities/submit/submitCustomerOnboardingFormWelcomeStepForm'
import validateCustomerOnboardingFormWelcomeForm from 'utilities/validate/validateCustomerOnboardingFormWelcomeForm'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CUSTOMER_ONBOARDING_FORM_WELCOME_FORM } from 'constants/formConstants'
import { MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS } from 'constants/identityConstants'
import { countryOptionsForFlex } from 'constants/countryConstants'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'

import {
  BUSINESS_TYPE,
  START_APPLICATION,
  COUNTRY,
  ONBOARDING_FORM_WELCOME_FORM_TEXT,
} from 'constants/language/languageConstants'

import {
  APPLICATION_INFORMATION_URL,
  LIVE_ACCOUNT_APPLICATION_PRICING_LINK,
  PROHIBITED_OR_RESTRICTED_BUSINESS_AND_ACTIVITIES_URL,
  STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK,
} from 'constants/urlConstants'

const CustomerOnboardingFormWelcomeForm = ({
  handleSubmit = () => {},
  accessFormCountry,
  isSelfService = false,
  dashboardType = '',
}) => {
  return (
    <form className='CustomerOnboardingFormWelcomeForm' onSubmit={handleSubmit(submitCustomerOnboardingFormWelcomeStepForm)}>
      <h6>{ONBOARDING_FORM_WELCOME_FORM_TEXT}</h6>
      <div className='form-content'>
        <div className='form-fields'>
          <Field
            name='country'
            label={COUNTRY}
            component={SelectField}
            options={countryOptionsForFlex}
            disabled={accessFormCountry ? true : false}
            required
          />

          <Field
            name='businessData.businessType'
            label={BUSINESS_TYPE}
            component={SelectField}
            options={MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS}
            required
          />
        </div>

        <h6 className='section-divider'>Before continuing, please confirm:</h6>
        <div className='list p-2 flex column'>
          <div>1. You&#39;ve gathered the information you&#39;ll need for this application described <a onClick={sendAmplitudeActionEvent(APPLICATION_INFORMATION_URL)} className='text-link' href={APPLICATION_INFORMATION_URL} target='blank'>here</a>.</div>
          {isSelfService ? <div>2. Your business is based or has a subsidiary in the U.S.</div> : <div>2. Your business is based or has a subsidiary in the U.S. or Canada.</div> }
          <div>3. You will not use the services for <a className='text-link' onClick={sendAmplitudeActionEvent(PROHIBITED_OR_RESTRICTED_BUSINESS_AND_ACTIVITIES_URL)} href={PROHIBITED_OR_RESTRICTED_BUSINESS_AND_ACTIVITIES_URL} target='blank'>restricted or prohibited industries</a>.</div>
          <div>4. You are an authorized representative for your business.</div>
          {isSelfService && (<div>5. You&#39;ve reviewed the <a className='text-link' href={dashboardType === STANDALONE_MERCHANT ? STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK : LIVE_ACCOUNT_APPLICATION_PRICING_LINK} target='blank'>pricing</a>.</div>)}
        </div>
      </div>
      <Button type='submit' label={START_APPLICATION} />
    </form>
  )
}

CustomerOnboardingFormWelcomeForm.propTypes = {
  handleSubmit: PropTypes.func,
  accessFormCountry: PropTypes.string,
  isSelfService: PropTypes.bool,
  dashboardType: PropTypes.string,
}

export default reduxForm({
  form: CUSTOMER_ONBOARDING_FORM_WELCOME_FORM,
  validate: validateCustomerOnboardingFormWelcomeForm,
})(CustomerOnboardingFormWelcomeForm)
