import React, { Component } from 'react'
import SelfieResults from './SelfieResults'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  CENTER_SELFIE,
  CONFIDENCE_REASONS,
  CONFIDENCE_SCORE,
  DOCUMENT_SIMILARITY_SCORE,
  LEFT_SELFIE,
  LEFT_SELFIE_SIMILARITY_SCORE,
  NOT_PROVIDED,
  RIGHT_SELFIE,
  RIGHT_SELFIE_SIMILARITY_SCORE,
  SOURCE,
  VIEW_PHOTO,
  VIEW_SOURCE,
} from 'constants/language/languageConstants'


class SelfieResultsC extends Component {
  render() {
    const report = get(this.props, 'report', {})

    const [
      confidenceScore,
      confidenceReasons = [],
      leftSelfieSimilarity,
      rightSelfieSimilarity,
      documentSimilarityScore,
      leftPhotoUrl,
      rightPhotoUrl,
      centerPhotoUrl,
      vendorReportId,
    ] = getMany(report, [
      'details.result_details.confidence_score',
      'details.result_details.confidence_reasons',
      'details.result_details.selfie_similarity_score_left',
      'details.result_details.selfie_similarity_score_right',
      'details.result_details.document_similarity_score',
      'input.left_photo_url',
      'input.right_photo_url',
      'input.center_photo_url',
      'sources[0].vendor_report_id',
    ])

    const sourceUrl = `https://app.withpersona.com/dashboard/verifications/${vendorReportId}`

    const selfiePhotosData = convertPageSectionDataToV2(
      [
        {
          label: LEFT_SELFIE,
          value: leftPhotoUrl ? <a className='text-link' href={leftPhotoUrl} target='_blank' rel='noreferrer'>{VIEW_PHOTO}</a> : NOT_PROVIDED,
        },
        {
          label: SOURCE,
          value: vendorReportId ? <a className='text-link' href={sourceUrl} target='_blank' rel='noreferrer'>{VIEW_SOURCE} <i className={`fa fa-${EXTERNAL_LINK}`} /></a> : NOT_PROVIDED,
        },
        {
          label: RIGHT_SELFIE,
          value: rightPhotoUrl ? <a className='text-link' href={rightPhotoUrl} target='_blank' rel='noreferrer'>{VIEW_PHOTO}</a> : NOT_PROVIDED,
        },
        {
          label: CENTER_SELFIE,
          value: centerPhotoUrl ? <a className='text-link' href={centerPhotoUrl} target='_blank' rel='noreferrer'>{VIEW_PHOTO}</a> : NOT_PROVIDED,
        },
      ],
    )

    const resultDetailsData = convertPageSectionDataToV2(
      [
        {
          label: CONFIDENCE_SCORE,
          value: confidenceScore,
        },
        {
          label: CONFIDENCE_REASONS,
          value: confidenceReasons.join(', '),
        },
        {
          label: LEFT_SELFIE_SIMILARITY_SCORE,
          value: leftSelfieSimilarity,
        },
        {
          label: RIGHT_SELFIE_SIMILARITY_SCORE,
          value: rightSelfieSimilarity,
        },
        {
          label: DOCUMENT_SIMILARITY_SCORE,
          value: documentSimilarityScore,
        },
      ],
    )

    return (
      <SelfieResults
        {...this.props}
        selfiePhotosData={selfiePhotosData}
        resultDetailsData={resultDetailsData}
      />
    )
  }
}

export default SelfieResultsC
