import { fork, all } from 'redux-saga/effects'
import generateGenericFlows from 'utilities/generateGenericFlows'
import generateRelatedResourcesFlows from 'utilities/generateRelatedResourcesFlows'
import map from 'lodash/map'

import locationWatcherF from 'state-layer/flows/locationWatcherF'
import credentialsWatcherF from 'state-layer/flows/credentialsWatcherF'
import searchF from 'state-layer/flows/searchF'
import redirectF from 'state-layer/flows/redirectF'
import getReviewQueueF from 'state-layer/flows/get/getReviewQueueF'
import postMassSettlementApprovalsF from 'state-layer/flows/post/postMassSettlementApprovalsF'
import postIdentityUploadF from 'state-layer/flows/post/postIdentityUploadF'
import getAllReviewQueueItemsF from 'state-layer/flows/get/getAllReviewQueueItemsF'
import getChartF from 'state-layer/flows/get/getChartF'
import getChartDeltaF from 'state-layer/flows/get/getChartDeltaF'
import getUnmaskedItemF from 'state-layer/flows/get/getUnmaskedItemF'
import getUnderwritingWorkflowF from 'state-layer/flows/get/getUnderwritingWorkflowF'

const flows = [
  // system flows
  getUnmaskedItemF,
  getChartF,
  getChartDeltaF,
  redirectF,
  credentialsWatcherF,
  locationWatcherF,
  // custom flows
  searchF,
  getReviewQueueF,
  postMassSettlementApprovalsF,
  postIdentityUploadF,
  getAllReviewQueueItemsF,
  getUnderwritingWorkflowF,
  // generic flows
  ...generateGenericFlows,
  ...generateRelatedResourcesFlows,
]

export default function * rootFlows () {
  yield all(map(flows, (flow) => fork(flow)))
}
