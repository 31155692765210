import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import isString from 'lodash/isString'

const backendDashboardCustomizationModel = ({ values }) => {
  const {
    dashboardId,
    entityId,
    entityType,
    sidebarBackgroundColor,
    sidebarTextColor,
    sidebarHighlightColor,
    enabled,
    subDomainName,
    dashboardSidebarLogoImageUrl,
    onboardingLogoImageUrl,
    onboardingPrimaryColor,
    onboardingDisplayName,
    emailLogoImageUrl,
    companyName,
    dashboardType,
    onboardingLinkExpiration,
    onboardingFeeUrl,
    onboardingTermsOfServiceUrl,
    onboardingReturnUrl,
    onboardingExpiredUrl,
    paymentLinkLogoImageUrl,
    paymentLinkHeaderColor,
    paymentLinkButtonBackgroundColor,
    paymentLinkButtonTextColor,
    paymentLinkTemplate,
  } = values

  const [
    successReturnUrl,
    unsuccessfulReturnUrl,
    termsOfServiceUrl,
    expiredSessionUrl,
    oneTimeAmountType,
    oneTimeAllowPaymentCard,
    oneTimeAllowBankTransfer,
    oneTimeExpiresIn,
    oneTimeExpirationDate,
    oneTimeCollectBuyerDetails,
    oneTimeCollectName,
    oneTimeCollectPhoneNumber,
    oneTimeCollectShippingAddress,
    oneTimeCollectBillingAddress,
    oneTimeCollectCustomFields,
    oneTimeCustomFields,
    multiUseAmountType,
    multiUseAllowPaymentCard,
    multiUseAllowBankTransfer,
    multiUseExpiresIn,
    multiUseExpirationDate,
    multiUseCollectBuyerDetails,
    multiUseCollectName,
    multiUseCollectPhoneNumber,
    multiUseCollectShippingAddress,
    multiUseCollectBillingAddress,
    multiUseCollectCustomFields,
    multiUseCustomFields,
    sendReceipt,
    sendReceiptToSelf,
  ] = getMany(paymentLinkTemplate, [
    'successReturnUrl',
    'unsuccessfulReturnUrl',
    'termsOfServiceUrl',
    'expiredSessionUrl',
    'oneTime.amountType',
    'oneTime.allowPaymentCard',
    'oneTime.allowBankTransfer',
    'oneTime.expiresIn',
    'oneTime.expirationDate',
    'oneTime.collectBuyerDetails',
    'oneTime.collectName',
    'oneTime.collectPhoneNumber',
    'oneTime.collectShippingAddress',
    'oneTime.collectBillingAddress',
    'oneTime.collectCustomFields',
    'oneTime.customFields',
    'multiUse.amountType',
    'multiUse.allowPaymentCard',
    'multiUse.allowBankTransfer',
    'multiUse.expiresIn',
    'multiUse.expirationDate',
    'multiUse.collectBuyerDetails',
    'multiUse.collectName',
    'multiUse.collectPhoneNumber',
    'multiUse.collectShippingAddress',
    'multiUse.collectBillingAddress',
    'multiUse.collectCustomFields',
    'multiUse.customFields',
    'sendReceipt',
    'sendReceiptToSelf',
  ])

  const normalizedEnabled = isString(enabled) ? ((enabled === 'true') ? true : false) : enabled

  return removeUndefined({
    dashboard_id: dashboardId,
    entity_id: entityId,
    entity_type: entityType,
    sidebar_logo_image_url: dashboardSidebarLogoImageUrl,
    sidebar_background_color: sidebarBackgroundColor,
    sidebar_text_color: sidebarTextColor,
    sidebar_secondary_color: sidebarHighlightColor,
    sidebar_settings: {},
    filters: {},
    enabled: normalizedEnabled,
    sub_domain_name: subDomainName,
    email_logo_image_url: emailLogoImageUrl,
    company_name: companyName,
    dashboard_type: dashboardType,
    hosted_onboarding_form_settings: {
      display_name: onboardingDisplayName,
      primary_color: onboardingPrimaryColor,
      logo_image_url: onboardingLogoImageUrl,
      expiration_in_days: onboardingLinkExpiration,
      expired_session_url: onboardingExpiredUrl,
      fee_details_url: onboardingFeeUrl,
      return_url: onboardingReturnUrl,
      terms_of_service_url: onboardingTermsOfServiceUrl,
    },
    payment_link_settings: {
      logo: paymentLinkLogoImageUrl,
      brand_color: paymentLinkHeaderColor,
      accent_color: paymentLinkButtonBackgroundColor,
      button_font_color: paymentLinkButtonTextColor,
      payment_link_template: [{
        template_name: 'default',
        success_return_url: successReturnUrl,
        unsuccessful_return_url: unsuccessfulReturnUrl,
        terms_of_service_url: termsOfServiceUrl,
        expired_session_url: expiredSessionUrl,
        send_receipt: sendReceipt,
        send_receipt_to_self: sendReceiptToSelf,
        one_time: {
          amount_type: oneTimeAmountType,
          allow_payment_card: oneTimeAllowPaymentCard,
          allow_bank_transfer: oneTimeAllowBankTransfer,
          expires_in: oneTimeExpiresIn,
          expiration_date: oneTimeExpirationDate,
          collect_buyer_details: oneTimeCollectBuyerDetails,
          collect_name: oneTimeCollectName,
          collect_phone: oneTimeCollectPhoneNumber,
          collect_shipping_address: oneTimeCollectShippingAddress,
          collect_billing_address: oneTimeCollectBillingAddress,
          custom_fields: oneTimeCollectCustomFields ? oneTimeCustomFields : undefined,
        },
        multi_use: {
          amount_type: multiUseAmountType,
          allow_payment_card: multiUseAllowPaymentCard,
          allow_bank_transfer: multiUseAllowBankTransfer,
          expires_in: multiUseExpiresIn,
          expiration_date: multiUseExpirationDate,
          collect_buyer_details: multiUseCollectBuyerDetails,
          collect_name: multiUseCollectName,
          collect_phone: multiUseCollectPhoneNumber,
          collect_shipping_address: multiUseCollectShippingAddress,
          collect_billing_address: multiUseCollectBillingAddress,
          custom_fields: multiUseCollectCustomFields ? multiUseCustomFields : undefined,
        },
      }],
    },
  })
}

export default backendDashboardCustomizationModel
