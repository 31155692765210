import selectOption from 'utilities/forms/selectOption'

export const DAILY = 'DAILY'
export const WEEKLY = 'WEEKLY'
export const MONTHLY = 'MONTHLY'
export const QUARTERLY = 'QUARTERLY'
export const YEARLY = 'YEARLY'
export const DAY = 'DAY'
export const WEEK = 'WEEK'
export const MONTH = 'MONTH'
export const YEAR = 'YEAR'
export const ACTIVE = 'ACTIVE'
export const INACTIVE = 'INACTIVE'

export const BILL_AUTOMATICALLY = 'BILL_AUTOMATICALLY'
export const GENERATE_INVOICE = 'GENERATE_INVOICE'
export const GENERATE_INVOICE_WITH_PAYMENT_LINK = 'GENERATE_INVOICE_WITH_PAYMENT_LINK'
export const MERCHANT = 'MERCHANT'

export const intervalTypeOptions = [
  selectOption('Days', DAY),
  selectOption('Weeks', WEEK),
  selectOption('Months', MONTH),
  selectOption('Years', YEAR)
]

export const subscriptionFrequencyOptions = [
  selectOption('Daily', DAILY),
  selectOption('Weekly', WEEKLY),
  selectOption('Monthly', MONTHLY),
  selectOption('Quarterly', QUARTERLY),
  selectOption('Yearly', YEARLY)
]

export const subscriptionCollectionMethod = [
  selectOption('Automatically bill card on file', BILL_AUTOMATICALLY),
  // TODO: uncomment this once we support invoices
  // selectOption('Generate invoice', GENERATE_INVOICE),
  // selectOption('Generate invoice with payment link', GENERATE_INVOICE_WITH_PAYMENT_LINK)
]

export const collectionMethodMap = {
  [BILL_AUTOMATICALLY]: 'Automatically charge card on file',
}

export const intervalTypeMap = {
  [DAY]: 'Day',
  [WEEK]: 'Week',
  [MONTH]: 'Month',
  [YEAR]: 'Year',
}
