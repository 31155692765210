import React, { Component } from 'react'
import { connect } from 'react-redux'
import OwnerIdentityVerificationReport from './OwnerIdentityVerificationReport'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatDateUTC from 'utilities/formatters/formatDateUTC'
import getMany from 'utilities/get/getMany'
import formatPhone from 'utilities/forms/format/formatPhone'
import { MM_DD_YYYY } from 'constants/timeConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'

import {
  DATE_OF_BIRTH,
  PHONE_NUMBERS,
  SSN_ISSUE_START_END_YEAR,
  SSN_ISSUE_STATE,
  SSN_STATUS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})
  const ownerIdentities = get(report, 'raw.PersonData', {})

  const ownerIdentitiesData = map(ownerIdentities, (identity) => {
    const [
      addresses,
      dob,
      firstName,
      middleName,
      lastName,
      phoneNumbers,
      ssnIssueStartYear,
      ssnIssueEndYear,
      ssnIssueState,
      ssnStatus,
    ] = getMany(identity, [
      'AddressRecords',
      'DateOfBirth',
      'FirstName',
      'MiddleName',
      'LastName',
      'PhoneNumbers',
      'SsnIssueStartYear',
      'SsnIssueEndYear',
      'SsnIssueState',
      'SsnStatus',
    ])

    const title = join([firstName, middleName, lastName], ' ')

    const phoneNumbersData = (
      <div className='phone-numbers-data'>
        {map(phoneNumbers, ({ PhoneNumber: phoneNumber }) => {
          return <div>{formatPhone(phoneNumber)}</div>
        })}
      </div>
    )

    const personalInformationData = [
      {
        type: 'data',
        data: [
          { label: DATE_OF_BIRTH, value: formatDateUTC({ date: dob, format: MM_DD_YYYY }) },
          { label: SSN_ISSUE_STATE, value: ssnIssueState },
        ],
      },
      {
        type: 'data',
        data: [
          { label: SSN_STATUS, value: ssnStatus },
          { label: SSN_ISSUE_START_END_YEAR, value: ssnIssueStartYear && ssnIssueEndYear ? `${ssnIssueStartYear} / ${ssnIssueEndYear}` : null },
        ],
      },
      {
        type: 'data',
        data: [
          { label: PHONE_NUMBERS, value: isEmpty(phoneNumbersData) ? '-' : phoneNumbersData },
        ],
      },
    ]

    const addressData = map(addresses, (address, index) => {
      const [
        line1,
        line2,
        city,
        region,
        postalCode,
        country = 'USA',
      ] = getMany(address, [
        'AddressLine1',
        'AddressLine2',
        'City',
        'State',
        'ZipCode',
        'Country',
      ])
      return {
        label: `Address #${index + 1}`,
        value: <Address address={{ line1, line2, city, region, postalCode, country }} />,
      }
    })

    return {
      title,
      personalInformationData,
      addressData,
    }
  })

  return {
    credentials,
    ownerIdentitiesData,
  }
}

class OwnerIdentityVerificationReportC extends Component {
  render() {
    return (
      <OwnerIdentityVerificationReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(OwnerIdentityVerificationReportC)
