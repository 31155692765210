import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'

import {
  IDENTITY,
  PATCH_IDENTITY,
  APPLICATION_IDENTITIES,
  APPLICATION_ASSOCIATED_IDENTITIES,
  POST_IDENTITY_F_REQUEST,
  PATCH_IDENTITY_F_REQUEST,
  POST_APPLICATION_IDENTITIES_F_REQUEST,
  POST_APPLICATION_ASSOCIATED_IDENTITIES_F_REQUEST,
} from 'constants/flowConstants'

import {
  ASSOCIATION_ESTATE_TRUST,
  PRIVATE_CORPORATION,
  PUBLIC_CORPORATION,
  GOVERNMENT_AGENCY,
  INDIVIDUAL_SOLE_PROPRIETORSHIP,
  INTERNATIONAL_ORGANIZATION,
  LIMITED_LIABILITY_COMPANY,
  PARTNERSHIP,
  TAX_EXEMPT_ORGANIZATION,
  LLC_DISREGARDED,
  CORPORATION, LIMITED_LIABILITY_COMPANY_SINGLE_MEMBER,
} from 'constants/language/languageConstants'

export const PRIVATE = 'PRIVATE'
export const PUBLIC = 'PUBLIC'
export const ASSOCIATION_ESTATE_TRUST_VALUE = 'ASSOCIATION_ESTATE_TRUST'
export const CORPORATION_VALUE = 'CORPORATION'
export const PRIVATE_CORPORATION_VALUE = 'PRIVATE_CORPORATION'
export const PUBLIC_CORPORATION_VALUE = 'PUBLIC_CORPORATION'
export const GOVERNMENT_AGENCY_VALUE = 'GOVERNMENT_AGENCY'
export const INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE = 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
export const INTERNATIONAL_ORGANIZATION_VALUE = 'INTERNATIONAL_ORGANIZATION'
export const LIMITED_LIABILITY_COMPANY_VALUE = 'LIMITED_LIABILITY_COMPANY'
export const PARTNERSHIP_VALUE = 'PARTNERSHIP'
export const TAX_EXEMPT_ORGANIZATION_VALUE = 'TAX_EXEMPT_ORGANIZATION'

export const OWNERSHIP_TYPES = [PRIVATE, PUBLIC]
export const OWNERSHIP_TYPE_OPTIONS = [selectOption('Select Type', ''), ...map(OWNERSHIP_TYPES, (ownershipType) => selectOption(capitalize(ownershipType), ownershipType))]

export const BUSINESS_TO_OWNERSHIP_TYPE_MAP = {
  [GOVERNMENT_AGENCY_VALUE]: PUBLIC,
  [TAX_EXEMPT_ORGANIZATION_VALUE]: PUBLIC,
  [PRIVATE_CORPORATION_VALUE]: PRIVATE,
  [PUBLIC_CORPORATION_VALUE]: PUBLIC,
  [INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE]: PRIVATE,
}

export const HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS = Object.keys(BUSINESS_TO_OWNERSHIP_TYPE_MAP)

export const BUSINESS_TYPE_OPTIONS = [
  selectOption('Select Type', ''),
  selectOption(ASSOCIATION_ESTATE_TRUST, ASSOCIATION_ESTATE_TRUST_VALUE),
  selectOption(PRIVATE_CORPORATION, PRIVATE_CORPORATION_VALUE),
  selectOption(PUBLIC_CORPORATION, PUBLIC_CORPORATION_VALUE),
  selectOption(GOVERNMENT_AGENCY, GOVERNMENT_AGENCY_VALUE),
  selectOption(INDIVIDUAL_SOLE_PROPRIETORSHIP, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE),
  selectOption(INTERNATIONAL_ORGANIZATION, INTERNATIONAL_ORGANIZATION_VALUE),
  selectOption(LIMITED_LIABILITY_COMPANY, LIMITED_LIABILITY_COMPANY_VALUE),
  selectOption(PARTNERSHIP, PARTNERSHIP_VALUE),
  selectOption(TAX_EXEMPT_ORGANIZATION, TAX_EXEMPT_ORGANIZATION_VALUE),
]

export const MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS = [
  selectOption('Select One', ''),
  selectOption(ASSOCIATION_ESTATE_TRUST, ASSOCIATION_ESTATE_TRUST_VALUE),
  selectOption(PRIVATE_CORPORATION, PRIVATE_CORPORATION_VALUE),
  selectOption(PUBLIC_CORPORATION, PUBLIC_CORPORATION_VALUE),
  selectOption(GOVERNMENT_AGENCY, GOVERNMENT_AGENCY_VALUE),
  selectOption(INDIVIDUAL_SOLE_PROPRIETORSHIP, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE),
  selectOption(INTERNATIONAL_ORGANIZATION, INTERNATIONAL_ORGANIZATION_VALUE),
  selectOption(LIMITED_LIABILITY_COMPANY, LIMITED_LIABILITY_COMPANY_VALUE),
  selectOption(PARTNERSHIP, PARTNERSHIP_VALUE),
  selectOption(TAX_EXEMPT_ORGANIZATION, TAX_EXEMPT_ORGANIZATION_VALUE),
]

export const MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES = [
  ASSOCIATION_ESTATE_TRUST_VALUE,
  CORPORATION_VALUE,
  GOVERNMENT_AGENCY_VALUE,
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
  INTERNATIONAL_ORGANIZATION_VALUE,
  LIMITED_LIABILITY_COMPANY_VALUE,
  PARTNERSHIP_VALUE,
  TAX_EXEMPT_ORGANIZATION_VALUE,
]

export const UNDERWRITING_BUSINESS_TYPE_OPTIONS = [
  selectOption('Select Type', ''),
  selectOption(ASSOCIATION_ESTATE_TRUST, ASSOCIATION_ESTATE_TRUST_VALUE),
  selectOption(CORPORATION, CORPORATION_VALUE),
  selectOption(GOVERNMENT_AGENCY, GOVERNMENT_AGENCY_VALUE),
  selectOption(INDIVIDUAL_SOLE_PROPRIETORSHIP, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE),
  selectOption(INTERNATIONAL_ORGANIZATION, INTERNATIONAL_ORGANIZATION_VALUE),
  selectOption(LIMITED_LIABILITY_COMPANY, LIMITED_LIABILITY_COMPANY_VALUE),
  selectOption(PARTNERSHIP, PARTNERSHIP_VALUE),
  selectOption(TAX_EXEMPT_ORGANIZATION, TAX_EXEMPT_ORGANIZATION_VALUE),
]

export const LIVE_ACCOUNT_BUSINESS_TYPE_OPTIONS = [
  selectOption('Select Type', ''),
  selectOption(INDIVIDUAL_SOLE_PROPRIETORSHIP, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE),
  selectOption(CORPORATION, CORPORATION_VALUE),
  selectOption(LIMITED_LIABILITY_COMPANY, LIMITED_LIABILITY_COMPANY_VALUE),
  selectOption(PARTNERSHIP, PARTNERSHIP_VALUE),
  selectOption(ASSOCIATION_ESTATE_TRUST, ASSOCIATION_ESTATE_TRUST_VALUE),
  selectOption(TAX_EXEMPT_ORGANIZATION, TAX_EXEMPT_ORGANIZATION_VALUE),
  selectOption(INTERNATIONAL_ORGANIZATION, INTERNATIONAL_ORGANIZATION_VALUE),
  selectOption(GOVERNMENT_AGENCY, GOVERNMENT_AGENCY_VALUE),
]

export const BUSINESS_TYPE_DISPLAY_MAP = {
  [ASSOCIATION_ESTATE_TRUST_VALUE]: ASSOCIATION_ESTATE_TRUST,
  [GOVERNMENT_AGENCY_VALUE]: GOVERNMENT_AGENCY,
  [INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE]: INDIVIDUAL_SOLE_PROPRIETORSHIP,
  [INTERNATIONAL_ORGANIZATION_VALUE]: INTERNATIONAL_ORGANIZATION,
  [LIMITED_LIABILITY_COMPANY_VALUE]: LIMITED_LIABILITY_COMPANY,
  [PARTNERSHIP_VALUE]: PARTNERSHIP,
  [TAX_EXEMPT_ORGANIZATION_VALUE]: TAX_EXEMPT_ORGANIZATION,
}

export const LLC_DISREGARDED_OPTION = selectOption(LLC_DISREGARDED, 'LLC_DISREGARDED')

const IDENTITY_ACTIONS = {
  [IDENTITY]: POST_IDENTITY_F_REQUEST,
  [PATCH_IDENTITY]: PATCH_IDENTITY_F_REQUEST,
  [APPLICATION_IDENTITIES]: POST_APPLICATION_IDENTITIES_F_REQUEST,
  [APPLICATION_ASSOCIATED_IDENTITIES]: POST_APPLICATION_ASSOCIATED_IDENTITIES_F_REQUEST,
}

export const getIdentityActions = (type) => get(IDENTITY_ACTIONS, type)

export const IDENTITY_CSV_COLUMNS = [
  'business_name',
  'doing_business_as',
  'business_type',
  'business_tax_id',
  'url',
  'business_phone',
  'ownership_type',
  'amex_mid',
  'discover_mid',
  'first_name',
  'last_name',
  'title',
  'principal_percentage_ownership',
  'tax_id',
  'phone',
  'email',
  'default_statement_descriptor',
  'annual_card_volume',
  'max_transaction_amount',
  'mcc',
  'has_accepted_credit_cards_previously',
  'dob_month',
  'dob_day',
  'dob_year',
  'incorporation_date_month',
  'incorporation_date_day',
  'incorporation_date_year',
  'business_address_line1',
  'business_address_line2',
  'business_address_city',
  'business_address_region',
  'business_address_postal_code',
  'business_address_country',
  'personal_address_line1',
  'personal_address_line2',
  'personal_address_city',
  'personal_address_region',
  'personal_address_postal_code',
  'personal_address_country',
  'tags',
]

export const IDENTITY_BUSINESS_FIELDS = [
  'businessName',
  'doingBusinessAs',
  'website',
  'businessPhone',
  'businessType',
  'ownershipType',
  'incorporationDate',
  'businessTaxId',
  'businessAddress',
]

export const IDENTITY_PRINCIPAL_FIELDS = [
  'firstName',
  'lastName',
  'title',
  'dateOfBirth',
  'principalPercentageOwnership',
  'ownershipType',
  'phone',
  'personalAddress',
  'taxId',
  'email',
]

export const IDENTITY_PROCESSING_FIELDS = [
  'previouslyProcessedCreditCards',
  'annualCardVolume',
  'defaultStatementDescriptor',
  'maxTransactionAmount',
  'maxACHTransactionAmount',
  'amexMid',
  'discoverMid',
  'mcc',
  'taxAuthority',
]

export const ADDITIONAL_UNDERWRITING_DATA_FIELDS = [
  'businessDescription',
  'formattedAverageCardTransferAmount',
  'formattedAverageACHTransferAmount',
  'formattedAnnualACHVolume',
  'merchantAgreementAccepted',
  'refundPolicy',
  'cardPresentPercentage',
  'ecommercePercentage',
  'mailOrderTelephoneOrderPercentage',
  'businessToBusinessVolumePercentage',
  'businessToConsumerVolumePercentage',
  'personToPersonVolumePercentage',
  'consumerToConsumerVolumePercentage',
  'otherVolumePercentage',
]

export const ADDITIONAL_UNDERWRITING_DATA_FIELDS_TO_RENAME = ['formattedAverageCardTransferAmount', 'formattedAverageACHTransferAmount', 'formattedAnnualACHVolume']

export const ADDITIONAL_UNDERWRITING_DATA_FIELDS_KEY_VALUE_MAP = {
  formattedAverageCardTransferAmount: 'averageCardTransferAmount',
  formattedAverageACHTransferAmount: 'averageACHTransferAmount',
  formattedAnnualACHVolume: 'annualACHVolume',
}

export const MAX_OWNERS = 5 // maximum owner can be added to a merchant

// different possible identity roles
export const SELLER_IDENTITY_ROLE  = 'SELLER'
export const RECIPIENT_IDENTITY_ROLE = 'RECIPIENT'
export const SENDER_IDENTITY_ROLE = 'SENDER'
export const APPLICATION_OWNER_IDENTITY_ROLE = 'APPLICATION_OWNER'
export const PLATFORM_OWNER_IDENTITY_ROLE = 'PLATFORM_OWNER'
export const BENEFICIAL_OWNER_IDENTITY_ROLE = 'BENEFICIAL_OWNER'

export const IDENTITY_ROLES_LABEL_MAP = {
  [SELLER_IDENTITY_ROLE]: 'Seller',
  [RECIPIENT_IDENTITY_ROLE]: 'Recipient',
  [SENDER_IDENTITY_ROLE]: 'Sender',
  [APPLICATION_OWNER_IDENTITY_ROLE]: 'Application Owner',
  [PLATFORM_OWNER_IDENTITY_ROLE]: 'Platform Owner',
  [BENEFICIAL_OWNER_IDENTITY_ROLE]: 'Beneficial Owner',
}
