import React, { Component } from 'react'
import { connect } from 'react-redux'
import Webhook from './Webhook'
import { getWebhookSelector } from 'state-layer/selectors'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import getWebhookRequest from 'utilities/actions/get/getWebhookRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import formatEnabledEventString from 'utilities/formatters/formatEnabledEventString'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { FETCHING } from 'constants/reducerConstants'
import { SHOW_WEBHOOK_LOGS } from 'constants/featureFlagConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import {
  ENABLE_DISABLE_WEBHOOK_MODAL,
  RESEND_UNACCEPTED_EVENTS_MODAL,
} from 'constants/modalConstants'

import {
  EDIT_WEBHOOK_PATH,
  EDIT_APPLICATION_WEBHOOK_PATH,
  APPLICATION_PATH,
} from 'constants/pathConstants'

import {
  ENABLE,
  ENABLED,
  DISABLE,
  WEBHOOK,
  CREATED_ON,
  URL,
  STATE,
  AUTHORIZATION_TYPE,
  EDIT,
  UPDATED_ON,
  ACCEPTING_EVENTS,
  RESEND_UNACCEPTED_EVENTS,
  APPLICATION,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `webhooksR.${FETCHING}`)
  const webhookId = get(props, 'params.webhookId')
  const webhook = getWebhookSelector(state, webhookId)

  const [
    url = '',
    enabledStatus = '',
    displayCreatedAt = '',
    displayUpdatedAt = '',
    displayAuthenticationType = '',
    enabledEvents = [],
    isAcceptingEvents = false,
    displayIsAcceptingEvents,
    application,
    nickname,
  ] = getMany(webhook, [
    'url',
    'enabledStatus',
    'displayCreatedAt',
    'displayUpdatedAt',
    'displayAuthenticationType',
    'enabledEvents',
    'isAcceptingEvents',
    'displayIsAcceptingEvents',
    'application',
    'nickname',
  ])

  const applicationId = get(props, 'params.applicationId', get(application, 'id'))
  const businessName = get(application, 'businessName')
  const formattedEnabledEvents = (map(enabledEvents, (enabledEvent) => formatEnabledEventString(enabledEvent)))
  const enabledEventsDataSection = !isEmpty(enabledEvents) ? convertPageSectionDataToV2(sortBy(formattedEnabledEvents, 'label'), 3) : []
  const showWebhookLogs = getFeatureFlag(SHOW_WEBHOOK_LOGS)
  const enabled = enabledStatus === ENABLED
  const showWebhookFailureBanner = !isAcceptingEvents && enabled && showWebhookLogs

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: businessName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: isRolePlatform({ credentials }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: WEBHOOK,
      id: webhookId,
    },
    items: [
      {
        value: <h1>{nickname ? nickname : `WH-${webhookId && webhookId.slice(-4)}`}</h1>,
      },
      {
        label: STATE,
        value: <EnabledStatus value={enabledStatus} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: URL,
        value: url,
      },
    ],
    isFetching,
  }

  // TODO: FE-3055 use condition but need to refactor convertPageSectionDataToV2 to properly account for condition logic
  const detailsData = showWebhookLogs ? [
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
    {
      label: ACCEPTING_EVENTS,
      value: displayIsAcceptingEvents,
    },
    {
      label: AUTHORIZATION_TYPE,
      value: displayAuthenticationType,
    },
  ] : [
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
    {
      label: AUTHORIZATION_TYPE,
      value: displayAuthenticationType,
    },
  ]


  const detailsSectionData = convertPageSectionDataToV2(detailsData, 3)

  return {
    credentials,
    credentialId,
    isFetching,
    webhookId,
    webhook,
    contextBarData,
    headerData,
    detailsSectionData,
    enabledEventsDataSection,
    enabledStatus,
    enabledEvents,
    applicationId,
    isAcceptingEvents,
    showWebhookFailureBanner,
    enabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWebhook: ({ credentials, webhookId }) => dispatch(getWebhookRequest({ credentials, webhookId })),
    showEnableDisableWebhookModal: (modalProps) => dispatch(showModalAction({ modalType: ENABLE_DISABLE_WEBHOOK_MODAL, modalProps })),
    showResendUnacceptedEventsModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_UNACCEPTED_EVENTS_MODAL, modalProps })),
  }
}

class WebhookC extends Component {
  componentDidMount() {
    const {
      credentials,
      webhookId,
      getWebhook,
    } = this.props

    getWebhook({ webhookId, credentials })
  }

  render() {
    const {
      credentialId,
      webhookId,
      applicationId,
      isAcceptingEvents,
      showEnableDisableWebhookModal,
      showResendUnacceptedEventsModal,
      enabled,
    } = this.props

    const enableDisableLabel = enabled ? DISABLE : ENABLE
    const editPath = applicationId ? EDIT_APPLICATION_WEBHOOK_PATH({ credentialId, applicationId, webhookId }) : EDIT_WEBHOOK_PATH({ credentialId, webhookId })

    const actions = [
      {
        label: EDIT,
        link: editPath,
        className: 'edit-webhook',
      },
      {
        label: RESEND_UNACCEPTED_EVENTS,
        className: 'resend-unaccepted-events',
        condition: enabled && !isAcceptingEvents,
        action: () => showResendUnacceptedEventsModal({ webhookId, enabled }),
      },
      {
        label: enableDisableLabel,
        action: () => showEnableDisableWebhookModal({ webhookId, enabled, isAcceptingEvents }),
        className: 'enable-disable-webhook',
      },
    ]

    const pageSectionActions = [
      {
        label: EDIT,
        link: editPath,
        buttonClassName: 'edit-webhook',
      },
    ]

    return (
      <Webhook
        actions={actions}
        pageSectionActions={pageSectionActions}
        editPath={editPath}
        enabled={enabled}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhookC)
