import './GuestPayoutLinkFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import AddressC from 'components/Shared/Inputs/Address/Address'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import PhoneField from 'components/Shared/Inputs/PhoneField/PhoneField'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import Loader from 'components/Shared/Loader/Loader'
import formatMoney from 'utilities/formatters/formatMoney'
import { GUEST_PAYOUT_LINK_FORM } from 'constants/formConstants'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import every from 'lodash/every'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

import {
  t,
  EMAIL,
  PHONE,
  CONTACT,
  PROCESSING,
  PRIVACY_POLICY,
  ADDITIONAL_INFORMATION,
  BILLING_DETAILS,
  TERMS_OF_SERVICE,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6,
  BUSINESS,
  FIRST_NAME,
  LAST_NAME,
  NAME,
  PERSONAL_ADDRESS,
  CONTROL_OWNER,
  BUSINESS_INFORMATION,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_ADDRESS,
  TOTAL_PAYOUT,
  PAYOUT_DETAILS,
  CLAIM_PAYOUT,
  PAYOUT_METHOD,
  INDIVIDUAL,
} from 'constants/language/languageConstants'

const countriesWithCustomACHWarnings = [CAN]

const GuestPayoutLinkForm = ({
  payoutLink = {},
  checkoutForm = {},
  formIsInvalid = true,
  formHasErrors = true,
  isProcessingPayment = false,
  showAchWarning = false,
  previewMode = '',
  noValidation = false,
  openOrderSummary = false,
  country = USA,
  isPersonalRecipient = true,
  setIsPersonalRecipient = () => {},
  recipientPaymentInstrumentOptions = [],
  showBillingForm = false,
  isPaymentInstrumentBankAccount = false,
  handlePaymentInstrumentChange = () => {},
}) => {
  const checkoutResource = isEmpty(payoutLink) ? checkoutForm : payoutLink

  const {
    recipientFullName,
    recipientBusinessName,
    recipientDoingBusinessAs,
    termsOfServiceUrl,
    brandColor,
    accentColor,
    buttonFontColor,
    logo,
    items,
    displayTotalAmount,
    tags,
    useCustomBranding,
  } = checkoutResource

  const customFields = filter(tags, (tagValue, tagKey) => startsWith(tagKey, 'custom_field'))
  const collectEmail = get(tags, 'collectEmail') === 'true' || get(checkoutResource, 'collectEmail')
  const collectPhoneNumber = get(tags, 'collectPhoneNumber') === 'true' || get(checkoutResource, 'collectPhoneNumber')
  const showCustomFields = size(customFields) > 0
  const noRecipientPreselected = isEmpty(recipientFullName) && isEmpty(recipientBusinessName) // this controls the fields to show if the recipient was pre-selected when creating the payout link
  const finixFormErrors = showBillingForm ? formHasErrors : false
  const submitButtonDisabled = !noValidation && !useCustomBranding && (formIsInvalid || finixFormErrors || isProcessingPayment)
  const hideImagePreviews = every(items, ({ primaryImageUrl }) => !primaryImageUrl)
  const previewClassNames = previewMode ? `preview-mode ${previewMode}` : ''
  const emptyItems = isEqual(size(items), 1) && isEmpty(get(items, '[0]'))
  const showStandardAchWarningText = !includes(countriesWithCustomACHWarnings, country)
  const isBusinessRecipientPreselected = !!recipientBusinessName
  const classname = showBillingForm ? 'show' : 'hide'

  // these control the fields to show if type of recipient is selected in the payout link
  const setPersonalRecipient = () => {
    setIsPersonalRecipient(true)
  }

  const setBusinessRecipient = () => {
    setIsPersonalRecipient(false)
  }

  return (
    <form className={`GuestPayoutLinkForm ${previewClassNames}`}>
      { isEmpty(checkoutResource) && <div className='mask' /> }

      <div className='header' style={{ backgroundColor: brandColor }}>
        { logo && <img src={logo} alt='company-logo' /> }
      </div>

      { isEmpty(checkoutResource) && <Loader />}

      { !isEmpty(checkoutResource) && (
        <div className='container flex'>
          <div className='left-col'>
            <div className='form-container'>
              <div className='mobile-order-summary'>
                <Accordion title={PAYOUT_DETAILS} open={openOrderSummary}>
                  <div className='items'>
                    { !emptyItems && map(items, (item, index) => {
                      const {
                        name,
                        quantity,
                        description,
                        primaryImageUrl,
                        amount,
                        itemTotalAmount,
                      } = item

                      const itemKey = `mobile-item-preview-${index}`

                      return (
                        <div className='item-preview flex' key={itemKey}>
                          { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }
                          { !primaryImageUrl && !hideImagePreviews && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                          <div className='info flex column flex-grow space-between'>
                            <div>
                              <p className='name p-2'>{name}</p>
                              <p className='description p-3 secondary'>{description}</p>
                            </div>

                            { quantity && <p className='quantity p-3 secondary'>{formatMoney({ amount })} x {quantity}</p>}
                          </div>

                          <h6 className='total'>{itemTotalAmount}</h6>
                        </div>
                      )
                    })}
                  </div>

                  <div className='cart-total flex space-between'>
                    <h5>{t(TOTAL_PAYOUT)}</h5>
                    <h5>{displayTotalAmount}</h5>
                  </div>
                </Accordion>
              </div>

              <div className='contact-info'>
                <h5>{CONTACT}</h5>

                { noRecipientPreselected && (
                  <>
                    <div className='link-type-buttons flex'>
                      <div className={`individual-contact ${isPersonalRecipient ? 'active' : 'inactive'}`} onClick={setPersonalRecipient}>
                        <span className='flex center'>
                          <RadioButton options={[{ value: 'individual', label: INDIVIDUAL }]} input={{ value: isPersonalRecipient ? 'individual' : 'business', name: 'radio-button' }} />
                        </span>
                      </div>
                      <div className={`business-contact ${isPersonalRecipient ? 'inactive' : 'active'}`} onClick={setBusinessRecipient}>
                        <span className='flex center'>
                          <RadioButton options={[{ value: 'business', label: BUSINESS }]} input={{ value: isPersonalRecipient ? 'individual' : 'business', name: 'radio-button' }} />
                        </span>
                      </div>
                    </div>

                    { !isPersonalRecipient && (
                      <h5>{t(CONTROL_OWNER)}</h5>
                    )}

                    <div className='flex space-between'>
                      <Field
                        name='firstName'
                        type='text'
                        component={InputField}
                        label={t(FIRST_NAME)}
                        placeholder='John'
                        required={false}
                      />

                      <Field
                        name='lastName'
                        type='text'
                        component={InputField}
                        label={t(LAST_NAME)}
                        placeholder='Doe'
                        required={false}
                      />
                    </div>

                    <AddressC
                      name='personalAddress'
                      label={PERSONAL_ADDRESS}
                      helperText={{ addressLine1HelperText: '' }}
                      form={GUEST_PAYOUT_LINK_FORM}
                      presetCountry={USA}
                      required={false}
                    />

                    { collectEmail && (
                      <Field
                        name='email'
                        type='text'
                        component={InputField}
                        label={t(EMAIL)}
                        required={false}
                        placeholder='johndoe@gmail.com'
                      />
                    )}

                    { collectPhoneNumber && (
                      <PhoneField
                        name='phoneNumber'
                        label={t(PHONE)}
                        placeholder='(123) 456-7890'
                      />
                    )}
                  </>
                )}

                { !noRecipientPreselected && (
                  <>
                    { isBusinessRecipientPreselected && (
                      <h5>{t(CONTROL_OWNER)}</h5>
                    )}

                    <Field
                      name='recipientFullName'
                      type='text'
                      component={InputField}
                      label={t(NAME)}
                      disabled
                      required={false}
                    />

                    { collectEmail && (
                      <Field
                        name='email'
                        type='text'
                        component={InputField}
                        label={t(EMAIL)}
                        required={false}
                      />
                    )}

                    { collectPhoneNumber && (
                      <PhoneField
                        name='phoneNumber'
                        label={t(PHONE)}
                      />
                    )}
                  </>
                )}
              </div>

              { showCustomFields && (
                <div className='custom-info'>
                  <h5>{t(ADDITIONAL_INFORMATION)}</h5>

                  { map(customFields, (customFieldName) => {
                    return (
                      <Field
                        key={customFieldName}
                        name={`tags.${customFieldName}`}
                        label={customFieldName}
                        component={InputField}
                        required={false}
                      />
                    )
                  })}
                </div>
              )}

              { noRecipientPreselected && (
                <>
                  {!isPersonalRecipient && (
                    <div className='business-info'>
                      <h5>{t(BUSINESS_INFORMATION)}</h5>

                      <Field
                        name='businessName'
                        type='text'
                        component={InputField}
                        label={BUSINESS_NAME}
                        required={false}
                      />

                      <Field
                        name='doingBusinessAs'
                        type='text'
                        component={InputField}
                        label={DOING_BUSINESS_AS}
                        required={false}
                      />

                      <AddressC
                        name='businessAddress'
                        label={BUSINESS_ADDRESS}
                        showLabels
                        helperText={{ addressLine1HelperText: '' }}
                        form={GUEST_PAYOUT_LINK_FORM}
                        presetCountry={USA}
                        required={false}
                      />

                      { collectEmail && (
                        <Field
                          name='email'
                          type='text'
                          component={InputField}
                          label={t(EMAIL)}
                          required={false}
                        />
                      )}

                      { collectPhoneNumber && (
                        <PhoneField
                          name='phoneNumber'
                          label={t(PHONE)}
                        />
                      )}
                    </div>
                  )}
                </>
              )}

              { !noRecipientPreselected && (
                <>
                  { isBusinessRecipientPreselected && (
                    <div className='business-info'>
                      <h5>{t(BUSINESS_INFORMATION)}</h5>

                      <Field
                        name='businessName'
                        type='text'
                        component={InputField}
                        label={BUSINESS_NAME}
                        required={false}
                        disabled
                      />

                      { recipientDoingBusinessAs && (
                        <Field
                          name='doingBusinessAs'
                          type='text'
                          component={InputField}
                          label={DOING_BUSINESS_AS}
                          required={false}
                          disabled
                        />
                      )}
                    </div>
                  )}
                </>
              )}

              <div className='billing-info'>
                <h5>{t(BILLING_DETAILS)}</h5>

                { !noRecipientPreselected && (
                  <Field
                    name='paymentInstrumentId'
                    label={PAYOUT_METHOD}
                    component={ReactSelect}
                    options={recipientPaymentInstrumentOptions}
                    onChange={handlePaymentInstrumentChange}
                  />
                )}

                <div className={`${classname} payment-instrument-form`} id='payment-instrument-form' />

                { (showAchWarning || isPaymentInstrumentBankAccount) && (
                  <div className='ach-warning-text'>
                    { showStandardAchWarningText && (
                      <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US)}</p>
                    )}

                    { country === CAN && (
                      <>
                        <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1)}</p>
                        <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2)}</p>
                        <p className='label-3 secondary'>
                          {t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4)} <span className='bold underline'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD)}</span>
                        </p>
                        <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5)}</p>
                        <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6)}</p>
                      </>
                    )}
                  </div>
                ) }

                <Button
                  label={isProcessingPayment ? <span className='flex'>{t(PROCESSING)} <Loader /></span> : t(CLAIM_PAYOUT)}
                  type='button'
                  className='pay-button'
                  style={{ backgroundColor: accentColor, borderColor: accentColor, color: buttonFontColor }}
                  disabled={submitButtonDisabled}
                />
              </div>

              <div className='links flex center'>
                <a href={termsOfServiceUrl} className='link-3' target='_blank' rel='noopener noreferrer'>{t(TERMS_OF_SERVICE)}</a>
                <a href='https://finix.com/terms-and-policies/privacy-policy' className='link-3' target='_blank' rel='noopener noreferrer'>{t(PRIVACY_POLICY)}</a>
              </div>
            </div>
          </div>

          <div className='right-col'>
            <h5 className='order-summary'>{t(PAYOUT_DETAILS)}</h5>

            <div className='items'>
              { map(items, (item, index) => {
                const {
                  name,
                  quantity,
                  description,
                  primaryImageUrl,
                  amount,
                  itemTotalAmount,
                } = item

                const itemKey = `item-preview-${index}`

                return (
                  <div className='item-preview flex' key={itemKey}>
                    { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }
                    { !primaryImageUrl && !hideImagePreviews && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                    <div className='info flex column flex-grow space-between'>
                      <div>
                        <p className='name p-2'>{name}</p>
                        <p className='description p-3 secondary'>{description}</p>
                      </div>

                      { quantity && <p className='quantity p-3 secondary'>{formatMoney({ amount })} x {quantity}</p> }
                    </div>

                    <h6 className='total'>{itemTotalAmount}</h6>
                  </div>
                )
              })}
            </div>

            <div className='cart-total flex space-between'>
              <h5>{t(TOTAL_PAYOUT)}</h5>
              <h5>{displayTotalAmount}</h5>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}

GuestPayoutLinkForm.propTypes = {
  payoutLink: PropTypes.object,
  checkoutForm: PropTypes.object,
  formIsInvalid: PropTypes.bool,
  formHasErrors: PropTypes.bool,
  isProcessingPayment: PropTypes.bool,
  showAchWarning: PropTypes.bool,
  previewMode: PropTypes.string,
  noValidation: PropTypes.bool,
  openOrderSummary: PropTypes.bool,
  country: PropTypes.string,
  isPersonalRecipient: PropTypes.bool,
  setIsPersonalRecipient: PropTypes.func,
  recipientPaymentInstrumentOptions: PropTypes.array,
}

export default GuestPayoutLinkForm
