import React, { Component } from 'react'
import { connect } from 'react-redux'
import Merchant from './Merchant'
import MerchantInfoC from 'components/Customer/Pages/Merchant/MerchantInfo/MerchantInfoC'
import MerchantTransactionsTabs from 'components/Shared/Tabs/MerchantTransactionsTabs'
import MerchantFeeRiskProfileC from 'components/Customer/Pages/Merchant/MerchantFeeRiskProfile/MerchantFeeRiskProfileC'
import PayoutSettingsC from 'components/Customer/Pages/PayoutSettings/PayoutSettingsC'
import MerchantSubscriptionEnrollmentsC from 'components/Customer/Pages/Merchant/MerchantSubscriptionEnrollments/MerchantSubscriptionEnrollmentsC'
import MerchantComplianceFormsC from 'components/Customer/Pages/Merchant/MerchantComplianceForms/MerchantComplianceFormsC'
import MerchantPayoutsTabs from 'components/Customer/Pages/Merchant/MerchantPayoutsTabs/MerchantPayoutsTabs'
import MerchantExceptionsTabs from 'components/Customer/Pages/Merchant/MerchantExceptionsTabs/MerchantExceptionsTabs'
import MerchantFiles from 'components/Customer/Pages/MerchantFiles/MerchantFiles'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import MerchantPaymentDevicesC from 'components/Customer/Pages/Merchant/MerchantPaymentDevices/MerchantPaymentDevicesC'
import MerchantStatementsC from 'components/Customer/Pages/Merchant/MerchantStatements/MerchantStatementsC'
import getMany from 'utilities/get/getMany'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getMerchantPayoutSettingsRequest from 'utilities/actions/get/getMerchantPayoutSettingsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import isPlatform from 'utilities/is/isPlatform'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import getMerchantSettlementsRequest from 'utilities/actions/get/getMerchantSettlementsRequest'
import { notPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { PENDING } from 'constants/settlementConstants'
import { PATCH_MERCHANT_F_REQUEST } from 'constants/flowConstants'
import { CAN } from 'constants/countryConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import map from 'lodash/map'

import {
  SHOW_DEVICE_MANAGEMENT,
  SHOW_PAYOUT_SETTINGS,
} from 'constants/featureFlagConstants'

import {
  ELAVON_V1,
  TRIPOS_MOBILE_V1,
  TRIPOS_CLOUD_V1,
  DATACAP_V1,
  FINIX_V1,
  DUMMY_V1,
} from 'constants/processorConstants'

import {
  EDIT_MERCHANT_SETTING,
  CREATE_MERCHANT_VERIFICATION,
  FILE_UPLOAD_MODAL,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_ENROLLMENT,
  EDIT_PAYOUT_SETTINGS_WARNING,
} from 'constants/modalConstants'

import {
  getMerchantFilesByMerchantId,
  getMerchantPayoutSettingsConnector,
  getMerchantSelector,
} from 'state-layer/selectors'

import {
  hasPlatformAccess,
  hasPartnerAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  MERCHANTS_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  FILES,
  STATE,
  COMPLIANCE,
  TRANSACTIONS,
  ACCOUNT_INFO,
  BUSINESS_NAME,
  CREATED_ON,
  SUBSCRIPTIONS,
  FEE_AND_RISK_PROFILES,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  MERCHANT_ACCOUNTS_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  PAYOUT_SPEED,
  UPDATE_MERCHANT_SETTINGS,
  CREATE_VERIFICATION,
  PAYOUTS,
  EXCEPTIONS,
  UPLOAD_FILE,
  CREATE_SUBSCRIPTION as CREATE_SUBSCRIPTION_STRING,
  ENROLL_IN_EXISTING_SUBSCRIPTION,
  PAYOUT_SETTINGS,
  COUNTRY,
  ROLE,
  PAYMENT_DEVICES,
  STATEMENTS,
} from 'constants/language/languageConstants'

import {
  RECIPIENT_IDENTITY_ROLE,
  SENDER_IDENTITY_ROLE,
  IDENTITY_ROLES_LABEL_MAP,
} from 'constants/identityConstants'


const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `merchantsR.${FETCHING}`)
  const merchantId = get(props, 'params.merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const editMerchant = hasPartnerAccess({ credentials })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()

  const [
    identityId,
    applicationName,
    applicationId,
    merchantName,
    merchantDoingBusinessAs,
    onboardingState,
    displayMerchantIdentityCreatedAt,
    payoutPlanId,
    processor,
    payoutPlan,
    displayBusinessName,
    displayFullName,
    country,
    merchantIdentityRoles,
    displayMerchantIdentityRoles,
    gateway,
  ] = getMany(merchant, [
    'identityId',
    'application.name',
    'application.id',
    'merchantName',
    'merchantDoingBusinessAs',
    'onboardingState',
    'displayMerchantIdentityCreatedAt',
    'payoutPlan.id',
    'processor',
    'payoutPlan',
    'displayBusinessName',
    'displayFullName',
    'country',
    'merchantIdentityRoles',
    'displayMerchantIdentityRoles',
    'gateway',
  ])

  const merchantPayoutSettings = getMerchantPayoutSettingsConnector(state, merchantId)
  const payoutSpeed = get(merchantPayoutSettings, 'displayRailHeader')
  const payoutSettingsFeatureFlag = getFeatureFlag(SHOW_PAYOUT_SETTINGS, false)
  const isFlex = isFlexPlatform()
  const isElavon = processor === ELAVON_V1
  const isElavonPlatform = isPlatform('elavonpartnerconnect')

  const tabs = [
    {
      id: 'account-info',
      name: ACCOUNT_INFO,
      component: MerchantInfoC,
    },
    {
      id: 'merchant-transactions',
      name: TRANSACTIONS,
      component: MerchantTransactionsTabs,
      props: {
        merchantId,
      },
      condition: notPayoutFeature({ credentials }) && !isStandaloneUnderwriting,
    },
    {
      id: 'merchant-payouts',
      name: PAYOUTS,
      component: MerchantPayoutsTabs,
      condition: !isStandaloneUnderwriting,
    },
    {
      id: 'merchant-exceptions',
      name: EXCEPTIONS,
      component: MerchantExceptionsTabs,
      condition: !isStandaloneUnderwriting,
      props: {
        merchantId,
      },
    },
    {
      id: 'merchant-payout-settings',
      name: PAYOUT_SETTINGS,
      component: PayoutSettingsC,
      props: {
        merchantId,
        merchant,
        payoutPlan,
      },
      condition: payoutSettingsFeatureFlag && !!payoutPlanId && !isElavon && !isElavonPlatform && !isStandaloneUnderwriting,
    },
    {
      id: 'merchant-payment-devices',
      name: PAYMENT_DEVICES,
      component: MerchantPaymentDevicesC,
      condition: getFeatureFlag(SHOW_DEVICE_MANAGEMENT) && (includes([TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1], gateway) || includes([DUMMY_V1, FINIX_V1], processor)),
      props: {
        merchantId,
        queries: { merchant_id: merchantId },
      },
    },
    {
      id: 'merchant-fee-risk-profile',
      name: FEE_AND_RISK_PROFILES,
      component: MerchantFeeRiskProfileC,
      condition: !isStandaloneUnderwriting,
    },
    {
      id: 'merchant-subscription-enrollments',
      name: SUBSCRIPTIONS,
      component: MerchantSubscriptionEnrollmentsC,
      props: {
        merchant,
      },
      condition: hasPlatformAccess({ credentials }) && !isStandaloneUnderwriting,
    },
    {
      id: 'merchant-files',
      name: FILES,
      component: MerchantFiles,
      props: {
        selector: () => getMerchantFilesByMerchantId(state, merchantId),
        merchantId,
        merchant,
        showUploadButton: true,
        fileNameCSV: 'MerchantFiles',
      },
    },
    {
      id: 'merchant-compliance',
      name: COMPLIANCE,
      component: MerchantComplianceFormsC,
      props: {
        merchantId,
        applicationId,
      },
      condition: isFlex && hasPartnerAccess({ credentials }),
    },
    {
      id: 'merchant-statements',
      name: STATEMENTS,
      component: MerchantStatementsC,
      props: {
        merchantId,
      },
      condition: hasPartnerAccess({ credentials }) && country === CAN,
    },
  ]

  const contextBarData = {
    back: {
      label: MERCHANT_ACCOUNTS_RESOURCE_TITLE,
      path: MERCHANTS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantName ? displayBusinessName : displayFullName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: MERCHANT_ACCOUNT_RESOURCE_TITLE,
      badges: (hasPlatformAccess({ credentials }) && isFlex) ? map(merchantIdentityRoles, (role) => IDENTITY_ROLES_LABEL_MAP[role]) : null,
      id: merchantId,
    },
    items: [
      {
        value: merchantDoingBusinessAs ? <h1>{merchantDoingBusinessAs}</h1> : <h1>{displayFullName}</h1>,
      },
      {
        label: BUSINESS_NAME,
        value: merchantName,
        condition: !merchantName === merchantDoingBusinessAs,
      },
      {
        label: STATE,
        value: <OnboardingState value={onboardingState} />,
      },
      {
        label: CREATED_ON,
        value: displayMerchantIdentityCreatedAt,
      },
      {
        label: PAYOUT_SPEED,
        value: payoutSpeed,
      },
      {
        label: COUNTRY,
        value: CountryFlagIcon({ country }) || '-',
      },
      {
        label: ROLE,
        value: displayMerchantIdentityRoles,
      },
    ],
    isFetching,
  }


  return {
    isFetching,
    identityId,
    merchantId,
    merchant,
    credentials,
    credentialId,
    tabs,
    contextBarData,
    headerData,
    payoutSettingsFeatureFlag,
    payoutPlanId,
    editMerchant,
    isStandaloneUnderwriting,
    isFlex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    showMerchantSettingModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_MERCHANT_SETTING, modalProps })),
    getMerchantPayoutSettings: ({ merchantId, credentials }) => dispatch(getMerchantPayoutSettingsRequest({ merchantId, credentials })),
    showMerchantVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_MERCHANT_VERIFICATION, modalProps })),
    showUploadMerchantFileModal: (modalProps) => dispatch(showModalAction({ modalType: FILE_UPLOAD_MODAL, modalProps })),
    showCreateSubscriptionModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION, modalProps })),
    showCreateSubscriptionEnrollmentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION_ENROLLMENT, modalProps })),
    getMerchantSettlements: ({ merchantId, credentials, queries, meta }) => dispatch(getMerchantSettlementsRequest({ merchantId, credentials, queries, meta })),
  }
}

class MerchantC extends Component {
  constructor(props) {
    super(props)

    this.state = { showWarning: false }
  }

  componentDidMount() {
    const {
      credentials,
      merchantId,
      getMerchant,
      payoutPlanId,
      payoutSettingsFeatureFlag,
      getMerchantPayoutSettings,
    } = this.props

    getMerchant({ merchantId, credentials })

    if (payoutPlanId && payoutSettingsFeatureFlag) getMerchantPayoutSettings({ merchantId, credentials })

    this.getAccruingSettlements()
  }

  componentDidUpdate(prevProps) {
    const {
      payoutPlanId: prevPayoutPlanId,
      merchantId: prevMerchantId,
    } = prevProps

    const {
      credentials,
      merchantId,
      getMerchantPayoutSettings,
      payoutSettingsFeatureFlag,
      payoutPlanId,
    } = this.props

    if (payoutSettingsFeatureFlag && ((!prevPayoutPlanId && payoutPlanId) || (prevMerchantId !== merchantId))) {
      getMerchantPayoutSettings({ merchantId, credentials })
    }
  }

  getAccruingSettlements = () => {
    const {
      getMerchantSettlements,
      merchantId,
      credentials,
    } = this.props

    getMerchantSettlements({
      merchantId,
      credentials,
      queries: {
        status: PENDING,
      },
      meta: {
        selectorId: merchantId,
        skipRelated: true,
        successCallback: ({ newValues }) => {
          if (!isEmpty(newValues)) {
            this.setState({ showWarning: true })
          } else {
            this.setState({ showWarning: false })
          }
        },
      },
    })
  }

  render() {
    const { showWarning } = this.state

    const {
      credentials,
      credentialId,
      merchant,
      merchantId,
      identityId,
      editMerchant,
      showMerchantSettingModal,
      showMerchantVerificationModal,
      showUploadMerchantFileModal,
      showCreateSubscriptionModal,
      showCreateSubscriptionEnrollmentModal,
      isStandaloneUnderwriting,
      isFlex,
    } = this.props

    const actions = [
      {
        label: UPDATE_MERCHANT_SETTINGS,
        buttonClassName: 'edit-merchant-button',
        action: () => {
          showMerchantSettingModal({
            record: merchant,
            actionType: PATCH_MERCHANT_F_REQUEST,
            credentials,
          })
        },
        condition: editMerchant && !isStandaloneUnderwriting,
      },
      {
        label: CREATE_VERIFICATION,
        action: () => {
          showMerchantVerificationModal({
            merchantId,
          })
        },
        className: 'create-merchant-verification-button',
      },
      {
        label: UPLOAD_FILE,
        action: () => {
          showUploadMerchantFileModal({
            credentials,
            merchantId,
            merchant,
            identityId,
            redirectToFilesTab: true,
          })
        },
        className: 'upload-merchant-file-button',
      },
      {
        label: CREATE_SUBSCRIPTION_STRING,
        condition: !isStandaloneUnderwriting && !isFlex,
        action: () => {
          showCreateSubscriptionModal({
            credentials,
            merchantId,
          })
        },
      },
      {
        label: ENROLL_IN_EXISTING_SUBSCRIPTION,
        condition: !isStandaloneUnderwriting && !isFlex,
        action: () => {
          showCreateSubscriptionEnrollmentModal({
            credentials,
            merchantId,
          })
        },
      },
    ]

    return (
      <Merchant
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantC)
