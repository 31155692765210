import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import { DASHBOARD_SERVICE_EMAIL_TEMPLATE_V2 } from 'constants/apiConstants'


const patchEmailTemplateAPI = ({ id, values, meta }) => {
  const dashboardId = getCurrentDashboardId()

  return dashboardAPI.patch({
    meta,
    values,
    path: DASHBOARD_SERVICE_EMAIL_TEMPLATE_V2({ id, dashboardId }),
  })
}

export default patchEmailTemplateAPI
