import './TableS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TableFiltersV2C from 'components/Shared/Table/TableFiltersV2/TableFiltersV2C'
import TableHeadersC from 'components/Shared/Table/TableHeaders/TableHeadersC'
import TableItemsC from 'components/Shared/Table/TableItems/TableItemsC'
import TableFooterC from 'components/Shared/Table/TableFooter/TableFooterC'
import InfiniteScroll from 'react-infinite-scroller'
import { ALERT_ICON } from 'constants/iconConstants'
import { resourceTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

const Table = ({
  items = [],
  headers = [],
  actions = [],
  allowedFilters = [],
  selectedItemsAction = [],
  selectedItemsPath,
  path = '',
  title = '',
  subtitle = '',
  page = {},
  sort = '',
  icon = `fa fa-${ALERT_ICON}`,
  classNames = 'normal',
  fileNameCSV = '',
  fileResourceTitleCSV = '',
  emptyMessage = '',
  TableFooter = TableFooterC,
  relatedItemsKey = '',
  relatedItemsWarning = {},
  selectedItemsKey = '',
  toggleSort = () => {},
  fetchNextData = () => {},
  handleDownloadCSV = () => {},
  showAdvancedExportModal = () => {},
  showExportV2Modal = () => {},
  fetchPaginationData = () => {},
  isFetching = false,
  hasMore = false,
  linksKey = '',
  pagination = false,
  limitOptions = [],
  disabledPrev = '',
  disabledNext = '',
  currentQueries = {},
  initialQueries = {},
  openNewTab = false,
  clickAction = () => {},
  filterSets = [],
  selectSingleItem = false,
  showAdvancedExport = false,
  tableContentHeight = '45vh',
  quickFilters = [],
  previewPanel,
  selectedItemsData = {},
  showTableFooter = true,
  theme = resourceTableTheme,
  noEmptyMessageIcon = false,
  emptyMessageString,
  tooltip = '',
  tooltipPosition = '',
  tableLink = '',
  linkPath = '',
}) => {
  const tableClassNames = classnames(
    'Table',
    theme,
    classNames,
  )

  const cypressTestMode = !!window.Cypress

  const formattedEmptyMessage = emptyMessage ?
    <>{!noEmptyMessageIcon && <span className={`empty-icon ${icon}`} />} {emptyMessage}</>
    : <>{!noEmptyMessageIcon && <span className={`empty-icon ${icon}`} />} No {title || 'Data'} Available</>

  const disableCSV = items.length === 0

  return (
    <div className={tableClassNames}>
      <TableFiltersV2C
        title={title}
        subtitle={subtitle}
        actions={actions}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        fileNameCSV={fileNameCSV}
        fileResourceTitleCSV={fileResourceTitleCSV}
        handleDownloadCSV={handleDownloadCSV}
        showAdvancedExportModal={showAdvancedExportModal}
        showExportV2Modal={showExportV2Modal}
        disableCSV={disableCSV}
        linksKey={linksKey}
        initialQueries={initialQueries}
        filterSets={filterSets}
        showAdvancedExport={showAdvancedExport}
        quickFilters={quickFilters}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        tableLink={tableLink}
        linkPath={linkPath}
      />
      <>
        { isEmpty(items) && emptyMessageString ? <div className='empty-message-string p-2'>{emptyMessageString}</div> : (
          <div className='table-content'>
            <div>
              <TableHeadersC
                headers={headers}
                toggleSort={toggleSort}
                sort={sort}
                selectedItemsKey={selectedItemsKey}
                selectedItemsAction={selectedItemsAction}
                selectedItemsData={selectedItemsData}
                items={items}
                relatedItemsKey={relatedItemsKey}
                pagination={pagination}
                fetchPaginationData={fetchPaginationData}
                limitOptions={limitOptions}
                disabledPrev={disabledPrev}
                disabledNext={disabledNext}
                currentQueries={currentQueries}
                selectSingleItem={selectSingleItem}
              />

              <div className='items flex column' style={{ maxHeight: tableContentHeight }}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={fetchNextData}
                  hasMore={!isFetching && hasMore}
                  useWindow={false}
                  initialLoad
                >
                  <TableItemsC
                    items={items}
                    path={path}
                    selectedItemsKey={selectedItemsKey}
                    selectedItemsAction={selectedItemsAction}
                    selectedItemsPath={selectedItemsPath}
                    selectedItemsData={selectedItemsData}
                    isFetching={isFetching}
                    emptyMessage={formattedEmptyMessage}
                    relatedItemsKey={relatedItemsKey}
                    relatedItemsWarning={relatedItemsWarning}
                    openNewTab={openNewTab && !cypressTestMode}
                    clickAction={clickAction}
                    selectSingleItem={selectSingleItem}
                    previewPanel={previewPanel}
                  />
                </InfiniteScroll>
              </div>
            </div>

            {showTableFooter && (
              <TableFooter
                isFetching={isFetching}
                page={page}
                itemsCount={items.length}
              />
            )}
          </div>
        )}
      </>
    </div>
  )
}

Table.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headers: PropTypes.array,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  classNames: PropTypes.string,
  allowedFilters: PropTypes.array,
  fetchNextData: PropTypes.func,
  toggleSort: PropTypes.func,
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  sort: PropTypes.string,
  selectedItemsKey: PropTypes.string,
  actions: PropTypes.array,
  page: PropTypes.object,
  selectedItemsAction: PropTypes.array,
  selectedItemsPath: PropTypes.string,
  fileNameCSV: PropTypes.string,
  handleDownloadCSV: PropTypes.func,
  relatedItemsKey: PropTypes.string,
  relatedItemsWarning: PropTypes.object,
  showAdvancedExportModal: PropTypes.func,
  showExportV2Modal: PropTypes.func,
  TableFooter: PropTypes.func,
  linksKey: PropTypes.string,
  pagination: PropTypes.bool,
  fetchPaginationData: PropTypes.func,
  disabledPrev: PropTypes.string,
  disabledNext: PropTypes.string,
  currentQueries: PropTypes.object,
  limitOptions: PropTypes.array,
  initialQueries: PropTypes.object,
  openNewTab: PropTypes.bool,
  clickAction: PropTypes.func,
  filterSets: PropTypes.array,
  selectSingleItem: PropTypes.bool,
  showAdvancedExport: PropTypes.bool,
  fileResourceTitleCSV: PropTypes.string,
  tableContentHeight: PropTypes.string,
  quickFilters: PropTypes.array,
  previewPanel: PropTypes.elementType,
  selectedItemsData: PropTypes.object,
  showTableFooter: PropTypes.bool,
  noEmptyMessageIcon: PropTypes.bool,
  emptyMessageString: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tableLink: PropTypes.string,
  linkPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
}

export default Table
