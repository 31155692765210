import './FeeProfileS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import EditFeeProfileSettingsFormC from 'components/Customer/Forms/EditFeeProfileSettingsForm/EditFeeProfileSettingsFormC'
import Loader from 'components/Shared/Loader/Loader'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  ACH_TRANSFERS,
  ADDITIONAL_FEES,
  ADDITIONAL_FEES_SUBTITLE,
  CARD_BRAND_OVERRIDES,
  CARD_NOT_PRESENT_PAYMENTS,
  CARD_NOT_PRESENT_PAYMENTS_SUBTITLE,
  CARD_PRESENT_PAYMENTS,
  CARD_PRESENT_PAYMENTS_SUBTITLE,
  CHARGEBACKS,
  INTERCHANGE_PLUS,
  FIXED_FEE,
  PASS_THROUGH_CARD_ASSOCIATION_FEES,
  PERCENTAGE_FEE,
  RETURNS,
  TOTAL_FEE,
  DEFAULT_FEE_PROFILE,
  DUES_AND_ASSESSMENTS,
} from 'constants/language/languageConstants'

import {
  AMEX_LABEL,
  DISCOVER_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

const Header = ({ title = 'Fee Type', currency, showPercentageColumn = true, showCurrency = false }) => {
  return (
    <>
      <div className='item header p-2-bold'>{title}</div>
      {showPercentageColumn && (
      <div className='item header'>
        <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
          {showCurrency && <div className='currency'>{currency}</div>}
      </div>
      )}
      <div className='item header'>
        <div className='p-2-bold'>{FIXED_FEE}</div>
        {showCurrency && <div className='currency'>{currency}</div>}
      </div>
      <div className='item header'>
        <div className='p-2-bold'>{TOTAL_FEE}</div>
        {showCurrency && <div className='currency'>{currency}</div>}
      </div>
    </>
  )
}

const FourColumnRows = ({ rows }) => {
  return map(rows, ({ isVisible, columns = [] }) => {
    return isVisible ? (
      <>
        <div className='item'>{columns[0]}</div>
        <div className='item'>{columns[1]}</div>
        <div className='item'>{columns[2]}</div>
        <div className='item total'>
          <div>{get(columns, '[3].title')}</div>
          <div className='currency'>{get(columns, '[3].subTitle')}</div>
        </div>
      </>
    ) : null
  })
}

const ThreeColumnRows = ({ rows }) => {
  return map(rows, ({ isVisible, columns = [] }) => {
    return isVisible ? (
      <>
        <div className='item'>{columns[0]}</div>
        <div className='item'>{columns[1]}</div>
        <div className='item total'>
          <div>{get(columns, '[2].title')}</div>
          <div className='currency'>{get(columns, '[2].subTitle')}</div>
        </div>
      </>
    ) : null
  })
}

const TwoColumnRows = ({ rows }) => {
  return map(rows, ({ isVisible, columns = [] }) => {
    return isVisible ? (
      <>
        <div className='item'>{columns[0]}</div>
        <div className='item'>{columns[1]}</div>
      </>
    ) : null
  })
}

const FeeProfile = ({
  feeProfileId,
  cardPresentFeeProfileId,
  entityId = '',
  currency = 'USD',
  title = '',
  subHeader = '',
  feeProfileData,
  cardPresentFeeProfileData,
  achFeeTypesRows = [],
  hideAchReturnsRows,
  achReturnsRows = [],
  hideAdditionalFeesSection,
  additionalFeesRows = [],
  isFetching = true,
  editFeeProfileActions = () => {},
  noCardPresentFeeProfile = false,
  showCurrency = false,
  showFeeProfileSettingsSection = false,
  showDuesAssessments = false,
}) => {
  const [
    feeTypeRows,
    cardBrandRows,
    hasCardBrandOverrides,
    chargebackRows,
    hideChargebackRows,
    interchangeVisaRows,
    showInterchangeVisaRows,
    interchangeMastercardRows,
    showInterchangeMastercardRows,
    interchangeAmexRows,
    showInterchangeAmexRows,
    interchangeDiscoverRows,
    showInterchangeDiscoverRows,
    showInterchangeSection,
    feeProfileType,
    duesAssessmentsRows = [],
    hasDuesAssessments,
  ] = getMany(feeProfileData, [
    'feeTypeRows',
    'cardBrandRows',
    'hasCardBrandOverrides',
    'chargebackRows',
    'hideChargebackRows',
    'interchangeVisaRows',
    'showInterchangeVisaRows',
    'interchangeMastercardRows',
    'showInterchangeMastercardRows',
    'interchangeAmexRows',
    'showInterchangeAmexRows',
    'interchangeDiscoverRows',
    'showInterchangeDiscoverRows',
    'showInterchangeSection',
    'feeProfileType',
    'duesAssessmentsRows',
    'hasDuesAssessments',
  ])
  const [
    cpFeeTypeRows,
    cpCardBrandRows,
    cpHasCardBrandOverrides,
    cpChargebackRows,
    cpHideChargebackRows,
    cpInterchangeVisaRows,
    cpShowInterchangeVisaRows,
    cpInterchangeMastercardRows,
    cpShowInterchangeMastercardRows,
    cpInterchangeAmexRows,
    cpShowInterchangeAmexRows,
    cpInterchangeDiscoverRows,
    cpShowInterchangeDiscoverRows,
    cpShowInterchangeSection,
    cpFeeProfileType,
    cpDuesAssessmentsRows = [],
    cpHasDuesAssessments,
  ] = getMany(cardPresentFeeProfileData, [
    'feeTypeRows',
    'cardBrandRows',
    'hasCardBrandOverrides',
    'chargebackRows',
    'hideChargebackRows',
    'interchangeVisaRows',
    'showInterchangeVisaRows',
    'interchangeMastercardRows',
    'showInterchangeMastercardRows',
    'interchangeAmexRows',
    'showInterchangeAmexRows',
    'interchangeDiscoverRows',
    'showInterchangeDiscoverRows',
    'showInterchangeSection',
    'feeProfileType',
    'duesAssessmentsRows',
    'hasDuesAssessments',
  ])
  return (
    <div className='FeeProfile'>
      {showFeeProfileSettingsSection && (
      <div className='info-highlight-panel'>
        <PageSectionV2C
          title={DEFAULT_FEE_PROFILE}
          subTitle={subHeader}
          clickToCopies={[{ content: feeProfileId }]}
        >
          <EditFeeProfileSettingsFormC entityId={entityId} />
        </PageSectionV2C>
      </div>
      )}
      <PageSectionV2C
        title={title}
        subTitle={subHeader}
        actions={editFeeProfileActions(0)}
        className='parent-page-section'
      >
        {isFetching && <Loader />}
        {!isFetching && (
        <>
          <PageSectionV2C
            smallTitle={CARD_NOT_PRESENT_PAYMENTS}
            subTitle={CARD_NOT_PRESENT_PAYMENTS_SUBTITLE}
            clickToCopies={[{ content: feeProfileId }]}
            badge={feeProfileType}
            className='nested-page-section'
          >
            {feeProfileData && (
            <>
              <div className='container-4'>
                <Header currency={currency} showCurrency={showCurrency} />
                <FourColumnRows rows={feeTypeRows} />
              </div>
              {hasCardBrandOverrides && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{CARD_BRAND_OVERRIDES}</div>
                </div>
                <div className='container-4'>
                  <FourColumnRows rows={cardBrandRows} />
                </div>
              </>
              )}
              {!hideChargebackRows && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{CHARGEBACKS}</div>
                </div>
                <div className='container-3'>
                  <ThreeColumnRows rows={chargebackRows} />
                </div>
              </>
              )}
              {feeProfileType === INTERCHANGE_PLUS && showInterchangeSection && !showDuesAssessments && (
              <>
                <div className='container-4'>
                  <Header title={PASS_THROUGH_CARD_ASSOCIATION_FEES} currency={currency} showCurrency={showCurrency} />
                </div>
                {showInterchangeVisaRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{VISA_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={interchangeVisaRows} />
                  </div>
                </>
                )}
                {showInterchangeMastercardRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{MASTERCARD_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={interchangeMastercardRows} />
                  </div>
                </>
                )}
                  {showInterchangeAmexRows && (
                  <>
                    <div className='container-1'>
                      <div className='item sub-header'>{AMEX_LABEL}</div>
                    </div>
                    <div className='container-4'>
                      <FourColumnRows rows={interchangeAmexRows} />
                    </div>
                  </>
                  )}
                  {showInterchangeDiscoverRows && (
                  <>
                    <div className='container-1'>
                      <div className='item sub-header'>{DISCOVER_LABEL}</div>
                    </div>
                    <div className='container-4'>
                      <FourColumnRows rows={interchangeDiscoverRows} />
                    </div>
                  </>
                  )}
              </>
              )}
              {feeProfileType === INTERCHANGE_PLUS && showDuesAssessments && hasDuesAssessments && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{DUES_AND_ASSESSMENTS}</div>
                </div>
                <div className='container-2'>
                  <TwoColumnRows rows={duesAssessmentsRows} />
                </div>
              </>
              )}
            </>
            )}
          </PageSectionV2C>
          <PageSectionV2C
            smallTitle={CARD_PRESENT_PAYMENTS}
            clickToCopies={[{ content: cardPresentFeeProfileId }]}
            subTitle={noCardPresentFeeProfile ? CARD_PRESENT_PAYMENTS_SUBTITLE : ''}
            badge={cpFeeProfileType}
            className='nested-page-section'
          >
            {cardPresentFeeProfileData && (
            <>
              <div className='container-4'>
                <Header currency={currency} showCurrency={showCurrency} />
                <FourColumnRows rows={cpFeeTypeRows} />
              </div>
              {cpHasCardBrandOverrides && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{CARD_BRAND_OVERRIDES}</div>
                </div>
                <div className='container-4'>
                  <FourColumnRows rows={cpCardBrandRows} />
                </div>
              </>
              )}
              {!cpHideChargebackRows && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{CHARGEBACKS}</div>
                </div>
                <div className='container-3'>
                  <ThreeColumnRows rows={cpChargebackRows} />
                </div>
              </>
              )}
              {cpFeeProfileType === INTERCHANGE_PLUS && cpShowInterchangeSection && !showDuesAssessments && (
              <>
                <div className='container-4'>
                  <Header title={PASS_THROUGH_CARD_ASSOCIATION_FEES} currency={currency} showCurrency={showCurrency} />
                </div>
                {cpShowInterchangeVisaRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{VISA_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={cpInterchangeVisaRows} />
                  </div>
                </>
                )}
                {cpShowInterchangeMastercardRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{MASTERCARD_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={cpInterchangeMastercardRows} />
                  </div>
                </>
                )}
                {cpShowInterchangeAmexRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{AMEX_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={cpInterchangeAmexRows} />
                  </div>
                </>
                )}
                {cpShowInterchangeDiscoverRows && (
                <>
                  <div className='container-1'>
                    <div className='item sub-header'>{DISCOVER_LABEL}</div>
                  </div>
                  <div className='container-4'>
                    <FourColumnRows rows={cpInterchangeDiscoverRows} />
                  </div>
                </>
                )}
              </>
              )}
              {cpFeeProfileType === INTERCHANGE_PLUS && showDuesAssessments && cpHasDuesAssessments && (
              <>
                <div className='container-1'>
                  <div className='item sub-header'>{DUES_AND_ASSESSMENTS}</div>
                </div>
                <div className='container-2'>
                  <TwoColumnRows rows={cpDuesAssessmentsRows} />
                </div>
              </>
              )}
            </>
            )}
          </PageSectionV2C>
          <PageSectionV2C
            smallTitle={ACH_TRANSFERS}
            className='nested-page-section'
          >
            <div className='container-4'>
              <Header currency={currency} showCurrency={showCurrency} />
              <FourColumnRows rows={achFeeTypesRows} />
            </div>
            {!hideAchReturnsRows && (
            <>
              <div className='container-1'>
                <div className='item sub-header'>{RETURNS}</div>
              </div>
              <div className='container-3'>
                <ThreeColumnRows rows={achReturnsRows} />
              </div>
            </>
            )}
          </PageSectionV2C>
          {!hideAdditionalFeesSection && (
          <PageSectionV2C
            smallTitle={ADDITIONAL_FEES}
            subTitle={ADDITIONAL_FEES_SUBTITLE}
            className='nested-page-section'
          >
            <div className='container-3'>
              <Header currency={currency} showPercentageColumn={false} showCurrency={showCurrency} />
              <ThreeColumnRows rows={additionalFeesRows} />
            </div>
          </PageSectionV2C>
          )}
        </>
        )}
      </PageSectionV2C>
    </div>
  )
}

FeeProfile.propTypes = {
  feeProfileId: PropTypes.string,
  cardPresentFeeProfileId: PropTypes.string,
  entityId: PropTypes.string,
  currency: PropTypes.string,
  title: PropTypes.string,
  subHeader: PropTypes.string,
  feeProfileData: PropTypes.object,
  cardPresentFeeProfileData: PropTypes.object,
  achFeeTypesRows: PropTypes.array,
  achReturnsRows: PropTypes.array,
  additionalFeesRows: PropTypes.array,
  isFetching: PropTypes.bool,
  noCardPresentFeeProfile: PropTypes.bool,
  showCurrency: PropTypes.bool,
  editFeeProfileActions: PropTypes.func,
  showFeeProfileSettingsSection: PropTypes.bool,
  showDuesAssessments: PropTypes.bool,
}

export default FeeProfile
