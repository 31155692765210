import './EditWebhookS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import WebhookFormC from 'components/Customer/Forms/WebhookForm/WebhookFormC'
import { EDIT_WEBHOOK } from 'constants/language/languageConstants'

const EditWebhook = ({
  webhook,
  applicationId,
  initialWebhookValues = {},
}) => {
  return (
    <div className='EditWebhook'>
      <ContextBar />

      <HeaderV2C
        title={EDIT_WEBHOOK}
      />

      <WebhookFormC
        editMode
        webhook={webhook}
        applicationId={applicationId}
        initialWebhookValues={initialWebhookValues}
      />
    </div>
  )
}

EditWebhook.propTypes = {
  webhook: PropTypes.object,
  applicationId: PropTypes.string,
  initialWebhookValues: PropTypes.object,
}

export default EditWebhook
