import './WelcomeNameCaptureModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import WelcomeNameCaptureForm from 'components/Customer/Forms/WelcomeNameCaptureForm/WelcomeNameCaptureForm'
import { WELCOME_MODAL_TITLE } from 'constants/language/languageConstants'

const WelcomeNameCaptureModal = ({
  credentials,
  dashboardUserId = '',
}) => {
  return (
    <GenericModal
      title={WELCOME_MODAL_TITLE}
      className='WelcomeNameCaptureModal'
      Component={WelcomeNameCaptureForm}
      credentials={credentials}
      dashboardUserId={dashboardUserId}
    />
  )
}

WelcomeNameCaptureModal.propTypes = {
  credentials: PropTypes.object,
  dashboardUserId: PropTypes.string,
}

export default WelcomeNameCaptureModal
