import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from 'components/Customer/Pages/DisbursementsDashboardOverview/columnDescriptors'
import ApplicationPayments from 'components/Customer/Pages/Application/ApplicationPayments/ApplicationPayments'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

import {
  PAYOUTS,
  PAYOUTS_LIST_PAGE_SUBTITLE,
} from 'constants/language/languageConstants'

const Disbursements = ({
  initialFilters = [],
  allowedFilters = [],
  initialQueries = {},
  flowValues = {},
  applicationId = '',
  disbursementsPath = '',
  quickFilters = [],
  isFetching = false,
  hideHeader = false,
  disbursementsFileNameCSV = '',
  actions = [],
}) => {
  return (
    <div className='Disbursements'>
      {!hideHeader && (
        <HeaderV2C
          title={PAYOUTS}
          subTitle={PAYOUTS_LIST_PAGE_SUBTITLE}
          // learnMoreLink={} TODO: Cata - add link once it's available
        />
      )}

      <ApplicationPayments
        initialFilters={initialFilters}
        quickFilters={quickFilters}
        allowedFilters={allowedFilters}
        initialQueries={initialQueries}
        flowValues={flowValues}
        applicationId={applicationId}
        customPath={disbursementsPath}
        columnDescriptors={columnDescriptors}
        customFileNameCSV={disbursementsFileNameCSV}
        isFetching={isFetching}
        actions={actions}
      />
    </div>
  )
}

Disbursements.propTypes = {
  initialFilters: PropTypes.array,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  flowValues: PropTypes.object,
  applicationId: PropTypes.string,
  disbursementsPath: PropTypes.string,
  quickFilters: PropTypes.array,
  isFetching: PropTypes.bool,
  hideHeader: PropTypes.bool,
  disbursementsFileNameCSV: PropTypes.string,
  actions: PropTypes.array,
}

export default Disbursements
