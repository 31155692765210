import './AttestComplianceFormModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import AttestComplianceFormC from 'components/Customer/Forms/AttestComplianceForm/AttestComplianceFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { ATTEST_COMPLIANCE_FORM } from 'constants/language/languageConstants'

const AttestComplianceFormModal = ({ complianceFormId }) => {
  return (
    <GenericModal
      title={ATTEST_COMPLIANCE_FORM}
      className='AttestComplianceFormModal'
      Component={AttestComplianceFormC}
      complianceFormId={complianceFormId}
    />
  )
}

AttestComplianceFormModal.propTypes = {
  complianceFormId: PropTypes.string,
}

export default AttestComplianceFormModal
