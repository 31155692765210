import './DisputeS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import DisputeTabsC from 'components/Customer/Pages/Dispute/DisputeTabsC'
import DisputeReviewC from 'components/Customer/Pages/Dispute/DisputeReview/DisputeReviewC'

const Dispute = ({
  headerData = {},
  contextBarData = {},
  disputeId = '',
  showTwoTabs = false,
}) => {
  return (
    <div className='Dispute'>
      <ContextBar {...contextBarData} />

      <HeaderV2C {...headerData} />

      { showTwoTabs ? (
        <DisputeTabsC
          disputeId={disputeId}
        />
      ) : <DisputeReviewC disputeId={disputeId} />
      }

    </div>
  )
}

Dispute.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  disputeId: PropTypes.string,
  showTwoTabs: PropTypes.bool,
}

export default Dispute
