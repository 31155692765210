import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANTS,
  MERCHANT_VERIFICATIONS,
} from 'constants/apiConstants'

const getMerchantVerificationsAPI = ({ queries, values, credentials, meta }) => {
  const {
    merchantId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: MERCHANT_VERIFICATIONS({ merchantId }),
    queries,
    service: MERCHANTS,
  })
}

export default getMerchantVerificationsAPI
