import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  BALANCES,
} from 'constants/apiConstants'

const getBalancesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: BALANCES,
    queries,
    credentials,
    service: BALANCES,
  })
}

export default getBalancesAPI
