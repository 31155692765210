import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT,
  TRANSACTION_MONITORING_DUPLICATE_SETTLEMENT_COUNT,
  TRANSACTION_MONITORING_SETTLEMENT_AMOUNT,
  TRANSACTION_MONITORING_SETTLEMENT_TRANSFER_COUNT,
} from 'constants/language/languageConstants'

const DuplicateSettlementCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displaySettlementAmountDollarsSum,
    displaySettlementAmountDollarsAverage,
    settlementEntryCount,
    settlementCount,
  ] = getMany(calculations, [
    'displaySettlementAmountDollarsSum',
    'displaySettlementAmountDollarsAverage',
    'settlementEntryCount',
    'settlementCount',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_DUPLICATE_SETTLEMENT_COUNT}
        headerData={{
          value: settlementEntryCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SETTLEMENT_TRANSFER_COUNT}
        headerData={{
          value: settlementCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SETTLEMENT_AMOUNT}
        headerData={{
          value: displaySettlementAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT}
        headerData={{
          value: displaySettlementAmountDollarsAverage,
        }}
      />
    </div>
  )
}

export default DuplicateSettlementCards
