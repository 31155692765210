import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { CHANGE_PASSWORD } from 'constants/amplitudeConstants'
import { POST_CHANGE_PASSWORD_F_REQUEST } from 'constants/flowConstants'

const submitChangePaswordForm = (values, dispatch) => {
  const {
    oldPassword,
    password,
  } = values

  const valuesToSubmit = {
    old_password: oldPassword,
    new_password: password,
  }

  dispatch({
    type: POST_CHANGE_PASSWORD_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(CHANGE_PASSWORD),
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default submitChangePaswordForm
