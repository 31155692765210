import {
  fieldEmpty,
} from 'utilities/validate'

const validateUpdateAdminToolsForm = (values, props) => {
  const { title } = props

  const {
    id,
    state,
    code,
    type,
  } = values

  return {
    id: fieldEmpty(id, `${title} ID`),
    state: fieldEmpty(state, `${title} State`),
    code: fieldEmpty(code, `${title} Code`),
    type: fieldEmpty(type, 'Task Type'),
  }
}

export default validateUpdateAdminToolsForm
