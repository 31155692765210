import './CreateRefundS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import CreateRefundFormC from 'components/Customer/Forms/CreateRefundForm/CreateRefundFormC'
import { USD } from 'constants/currencyConstants'

import {
  REFUND,
  CREATE_REFUND_MSG,
} from 'constants/language/languageConstants'

const CreateRefund = ({
  transferId = '',
  transferAmount = '',
  detailsDataSection = [],
  isFetching = false,
  isSplitParentPayment = false,
  splitTransfers = {},
  displayTransferAmount = '',
  merchantBusinessName = '',
  noPreviousRefunds = true,
  merchantId = '',
  currency = USD,
}) => {
  return (
    <div className='CreateRefund'>
      <ContextBar />

      <HeaderV2C
        title={REFUND}
        subTitle={CREATE_REFUND_MSG}
      />

      <PageSectionV2C
        isFetching={isFetching}
        columns={detailsDataSection}
      />

      <PageSectionV2C
        className='create-refund-form-section'
        isFetching={isFetching}
      >
        <CreateRefundFormC
          transferId={transferId}
          transferAmount={transferAmount}
          isSplitParentPayment={isSplitParentPayment}
          splitTransfers={splitTransfers}
          displayTransferAmount={displayTransferAmount}
          merchantBusinessName={merchantBusinessName}
          noPreviousRefunds={noPreviousRefunds}
          primaryMerchantId={merchantId}
          currency={currency}
        />
      </PageSectionV2C>
    </div>
  )
}

CreateRefund.propTypes = {
  transferId: PropTypes.string,
  transferAmount: PropTypes.string,
  detailsDataSection: PropTypes.array,
  isFetching: PropTypes.bool,
  isSplitParentPayment: PropTypes.bool,
  splitTransfers: PropTypes.object,
  displayTransferAmount: PropTypes.string,
  merchantBusinessName: PropTypes.string,
  noPreviousRefunds: PropTypes.bool,
  merchantId: PropTypes.string,
  currency: PropTypes.string,
}

export default CreateRefund
