import ObjectsToCsv from 'objects-to-csv'
import moment from 'moment'

async function printCSV (data, fileName) {
  const csv = await new ObjectsToCsv(data).toString()
  const fileNameWithDate = `${fileName}_${moment().format()}`
  const hiddenElement = document.createElement('a')

  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
  hiddenElement.target = '_blank'
  hiddenElement.download = `${fileNameWithDate}.csv`
  hiddenElement.click()
}

export default printCSV
