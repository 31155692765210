import axios from 'axios'
import co from 'co'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import { DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA } from 'constants/apiConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'

function * postSelfServiceResendEmailWithCaptchaAPI({ values, meta }) {
  const path = DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA
  const { hCaptchaToken } = meta

  const headers = {
    'x-finix-hcaptcha-token': hCaptchaToken,
  }

  const url = `${getDashboardServiceHost()}/${path}`

  try {
    const response = yield axios.post(url, values, { headers })

    return createSuccessResponse(response)
  } catch (error) {
    return createErrorResponse({ error })
  }
}

export default co.wrap(postSelfServiceResendEmailWithCaptchaAPI)
