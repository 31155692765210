import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import RiskProfileForm from 'components/Customer/Forms/RiskProfileForm/RiskProfileForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import { RISK_PROFILE_FORM } from 'constants/formConstants'

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(RISK_PROFILE_FORM)
  const cvvFailureAllowed = formSelector(state, 'csc_failure_allowed')
  const avsFailureAllowed = formSelector(state, 'avs_failure_allowed')

  return {
    cvvFailureAllowed,
    avsFailureAllowed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class RiskProfileFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      riskProfile,
    } = this.props

    initialize(riskProfile)
  }

  render() {
    return (
      <RiskProfileForm {...this.props} />
    )
  }
}

RiskProfileFormC.propTypes = {
  initialize: PropTypes.func,
  riskProfile: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: RISK_PROFILE_FORM,
})(RiskProfileFormC))
