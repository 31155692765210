import postDashboardUserMFAAssociateRequest from 'utilities/actions/post/postDashboardUserMFAAssociateRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL } from 'constants/modalConstants'

const submitMFASMSForm = (values, dispatch, props) => {
  const { token } = props
  const { phoneNumber } = values

  const valuesToSubmit = {
    mfa_type: 'SMS',
    phone_number: phoneNumber,
  }

  dispatch(postDashboardUserMFAAssociateRequest({
    token,
    values: valuesToSubmit,
    successCallback: ({ newValues }) => {
      const {
        mfa_id: mfaId,
        oob_code: oobCode,
        recovery_codes: recoveryCodes,
      } = newValues

      dispatch(showModalAction({
        modalType: VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL,
        modalProps: {
          type: 'sms',
          token,
          mfaId,
          oobCode,
          recoveryCodes,
        },
        className: 'modal-md no-pad',
      }))
    },
  }))
}

export default submitMFASMSForm
