import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MFA_TOKEN } from 'constants/apiConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'

const postDashboardUserMFATokenAPI = ({ values, meta }) => {
  const { password } = values

  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_MFA_TOKEN({
      userId: get(getCurrentCredentials(), 'userId'),
    }),
    values: {
      username: get(getCurrentUser(), 'email'),
      password,
    },
    meta,
  })
}

export default postDashboardUserMFATokenAPI
