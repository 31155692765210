export const SUBMITTED = 'SUBMITTED'
export const SUBMITTING = 'SUBMITTING'
export const STAGED = 'STAGED'
export const STAGING = 'STAGING'
export const UNKNOWN = 'UNKNOWN'
export const REVERTED = 'REVERTED'
export const CANCELED = 'CANCELED'

export const SUCCEEDED = 'SUCCEEDED'
export const PENDING = 'PENDING'
export const FAILED = 'FAILED'
