import './PaymentLinkConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  t,
  CONFIRMATION_PAGE_SUCCESS_STATUS_TITLE,
  CONFIRMATION_PAGE_FAILURE_STATUS_TITLE,
  CONFIRMATION_PAGE_EXPIRED_LINK_STATUS_TITLE,
  CONFIRMATION_PAGE_DEACTIVATED_LINK_STATUS_TITLE,
  CONFIRMATION_PAGE_SUCCESS_STATUS_MSG,
  CONFIRMATION_PAGE_FAILURE_STATUS_MSG,
  CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
  TOTAL_DUE,
  TOTAL_PAID,
} from 'constants/language/languageConstants'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

const statusToIconMap = {
  success: `far fa-${CHECK_CIRCLE_ICON}`,
  failure: `far fa-${TIMES_CIRCLE_ICON}`,
  expired: `far fa-${ALERT_ICON}`,
  deactivated: `far fa-${ALERT_ICON}`,
}

const statusToTitleMap = {
  success: CONFIRMATION_PAGE_SUCCESS_STATUS_TITLE,
  failure: CONFIRMATION_PAGE_FAILURE_STATUS_TITLE,
  expired: CONFIRMATION_PAGE_EXPIRED_LINK_STATUS_TITLE,
  deactivated: CONFIRMATION_PAGE_DEACTIVATED_LINK_STATUS_TITLE,
}

const statusToMessageMap = {
  success: CONFIRMATION_PAGE_SUCCESS_STATUS_MSG,
  expired: CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
  deactivated: CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
}

const failureCodeToFailureMessageMap = {
  ADDRESS_VERIFICATION_FAILED_RISK_RULES: 'Please update the address associated with the card or use a different payment method.',
  CARD_ACCOUNT_CLOSED: 'It looks like this card account has been closed. Please go back and use an active card.',
  CARD_NETWORK_ERROR: 'The transaction was declined due to a problem with the card network. Please contact the card network for more information or try a different payment method.',
  CARD_NOT_SUPPORTED: 'It looks like the card does not support this type of purchase. Please contact the issuing bank to make sure this card can be used for this type of purchase or go back and try a different payment method.',
  COMMUNICATION_ERROR: 'There was a network communication error with the host. Please check your network connection and retry the transaction.',
  CVV_FAILED_RISK_RULES: 'The Risk Rules associated with the Merchant declined the transaction. The cardholder needs to create a card with the correct CVV.',
  FRAUD_DETECTED: 'The card was declined for fraud by the processor. Please contact your issuing bank for more information or go back and try a different payment method.',
  INACTIVE_CARD: 'It looks like the card you used is inactive. Please activate the card or go back and use an active card.',
  INSUFFICIENT_FUNDS: 'The account has insufficient funds for the transaction. Please go back and use another method of payment.',
  INVALID_ACCOUNT_NUMBER: 'The card number is not valid. Please contact your issuing bank for more information or use another payment method.',
  INVALID_AMOUNT: 'The amount exceeds the amount that is allowed on the card. Please check with your issuing bank or go back and try a different payment method.',
  INVALID_BANK_ACCOUNT_VALIDATION_CHECK: 'The provided bank account cannot be used because the routing number is no longer valid. Please check that the routing number and account are correct or attempt with another bank account.',
  INVALID_CARD: 'The card is not valid. Please contact your issuing bank for more information or use another payment method.',
  INVALID_CVV: 'The CVV number is invalid. Please go back and try again using the correct CVV or use a different payment method.',
  INVALID_ROUTING_NUMBER: 'The bank routing number provided is invalid. Please provide a valid routing number or go back and use a different payment method.',
  INVALID_TRANSACTION: 'The transaction is not permitted by the issuing bank. Please contact your issuing bank for more information or go back and try a different payment method.',
  LOST_OR_STOLEN_CARD: 'The transaction was declined for an unknown reason. Please contact the card issuer for more information or go back and try a different payment method.',
  NO_BANK_ACCOUNT_FOUND: 'It looks like the account number does not correspond to an open account. Please reenter your information with the correct details.',
  RESTRICTED_CARD: 'The card has a restriction preventing approval for this transaction. Please contact your issuing bank for more information or go back and try a different payment method.',
  RESUBMIT_TRANSACTION: 'The transaction was declined for an unknown reason. Please go back and try again. If the transaction is declined again, please contact your issuing bank or try a different payment method.',
  TRANSACTION_NOT_PERMITTED: 'The transaction was declined because the card or transaction type is not permitted. Please go back and use a different type of card or try a different payment method.',
  INVALID_ISSUER: 'It looks like the card number is not associated to a valid card issuing bank. Please contact the issuing bank or go back and try a different payment method.',
}

const PaymentLinkConfirmation = ({
  status = '',
  paymentLink = {},
  failureCode = '',
}) => {
  const {
    termsOfServiceUrl,
    brandColor = '#FFF',
    logo,
    items,
    amountType,
    displayTotalAmount,
  } = paymentLink

  const isFixedAmount = amountType === 'FIXED'
  const icon = statusToIconMap[status]
  const title = statusToTitleMap[status]
  const failed = status === 'failure'
  const message = failed ? get(failureCodeToFailureMessageMap, failureCode, CONFIRMATION_PAGE_FAILURE_STATUS_MSG) : statusToMessageMap[status]

  return (
    <form className='PaymentLinkConfirmation'>
      { isEmpty(paymentLink) && <div className='mask' /> }

      <div className='header' style={{ backgroundColor: brandColor }}>
        {logo && <img src={logo} alt='company-logo' height={54} />}
      </div>

      <div className='container flex column'>
        <div className='messages flex column'>
          <i className={`icon ${status} far fa-${icon}`} />
          <h5>{t(title)}</h5>
          <p className='p-2'>{t(message)}</p>
        </div>

        { !isEmpty(paymentLink) && (
          <div className='order-summary'>
            <div className='items'>
              { map(items, (item, index) => {
                const {
                  name,
                  quantity,
                  description,
                  primaryImageUrl,
                  totalAmount: itemTotalAmount,
                } = item

                const itemKey = `mobile-item-preview-${index}`

                return (
                  <div className='item-preview flex' key={itemKey}>
                    { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }

                    <div className='info flex column flex-grow space-between'>
                      <div>
                        <p className='name p-2'>{name}</p>
                        <p className='description p-3 secondary'>{description}</p>
                      </div>

                      { isFixedAmount && <p className='quantity p-3 secondary'>Qty: {quantity}</p> }
                    </div>

                    { isFixedAmount && <h6 className='total'>{itemTotalAmount}</h6> }
                  </div>
                )
              })}
            </div>

            { isFixedAmount && (
              <div className='cart-total flex space-between'>
                <h5>{failed ? t(TOTAL_DUE) : t(TOTAL_PAID)}</h5>
                <h5>{displayTotalAmount}</h5>
              </div>
            )}
          </div>
        )}

        <div className='links flex center'>
          <a href={termsOfServiceUrl} className='link-3' target='_blank' rel='noopener noreferrer'>Terms of Service</a>
          <a href='https://finix.com/terms-and-policies/privacy-policy' className='link-3' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
        </div>
      </div>
    </form>
  )
}

PaymentLinkConfirmation.propTypes = {
  status: PropTypes.string,
  paymentLink: PropTypes.object,
}

export default PaymentLinkConfirmation
