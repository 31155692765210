import './ClickToCopyS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import {
  t,
  ID,
  CLICK_TO_COPY,
} from 'constants/language/languageConstants'

const ClickToCopy = ({
  label = ID,
  position = 'default',
  content = '',
  copyToClipboard = () => {},
  className = '',
  customView,
}) => {
  const viewClassnames = classnames({
    [`view ${position}`]: true,
    'flex column': position === 'default',
  })

  return (
    !isEmpty(content) && (
      <div
        className={`ClickToCopy ${className} ${customView ? 'custom-view' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          copyToClipboard(content)
        }}
      >

        {customView ? customView : (
          <>
            <span className='copy'>{t(label)}</span>

            <div className={viewClassnames}>
              <span className='resource-id'>{content}</span>
              <span className='click-label'>{t(CLICK_TO_COPY)}</span>
            </div>
          </>
        )}
      </div>
    )
  )
}

ClickToCopy.propTypes = {
  position: PropTypes.string,
  content: PropTypes.string,
  copyToClipboard: PropTypes.func,
  className: PropTypes.string,
  customView: PropTypes.object,
}

export default ClickToCopy
