import './NotificationsDropdownS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { ROLE_PARTNER } from 'constants/roleConstants'

const NotificationItem = ({
  applicationName = '',
  merchantName = '',
  displayEventType = '',
  displayCreatedAt = '',
  isNotificationRead = false,
  onClickAction = () => {},
  notification = {},
  role = '',
  message = '',
  webhookDisplay = '',
}) => {
  return (
    <div className='notificationItem' onClick={() => { onClickAction(notification) }}>
      <div className='header flex'>
        {applicationName && role !== ROLE_PARTNER && (
        <div className='header-resource flex application'>
          <div className='header-title p-3'>APP:&nbsp;</div>
          <div className='header-info p-3'>{applicationName}</div>
        </div>
        )}

        {merchantName && (
        <div className='header-resource flex'>
          <div className='header-title p-3'>MER:&nbsp;</div>
          <div className='header-info p-3'>{merchantName}</div>
        </div>
        )}
      </div>

      <div className='body flex space-between p-2-bold'>
        <div className='info'>
          {displayEventType && <div className='event'>{displayEventType}</div>}
          {message && <div className='subtitle p-2'>{message}</div>}
          {webhookDisplay ? <div className='subtitle webhook-display p-2' title={webhookDisplay}>{webhookDisplay}</div> : ''}
        </div>

        <div className='read-indicator flex items-center'>
          {!isNotificationRead && <div>&#8226;</div>}
        </div>
      </div>

      <div className='footer flex p-3'>
        {displayCreatedAt && <div>{displayCreatedAt}</div>}
      </div>
    </div>
  )
}

NotificationItem.propTypes = {
  applicationName: PropTypes.string,
  merchantName: PropTypes.string,
  displayEventType: PropTypes.string,
  displayCreatedAt: PropTypes.string,
  isNotificationRead: PropTypes.bool,
  onClickAction: PropTypes.func,
  notification: PropTypes.object,
  role: PropTypes.string,
  message: PropTypes.string,
}

export default NotificationItem
