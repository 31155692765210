import get from 'lodash/get'
import map from 'lodash/map'
import find from 'lodash/find'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_OPTIONS } from 'constants/complianceConstants'

const ComplianceAssessmentInformationFields = [
  {
    name: 'name',
    label: 'Template Name',
  },
  {
    name: 'type',
    label: 'Template Type',
    formatter: (type) => get(find(COMPLIANCE_FORM_TEMPLATE_TYPE_OPTIONS, ({ value }) => value === type), 'label'),
  },
]

const ComplianceAssessmentInformationDisplayData = (values) => map(ComplianceAssessmentInformationFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name)) : get(values, name),
}))

export default ComplianceAssessmentInformationDisplayData
