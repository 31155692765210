import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_REVIEW_RUNS } from 'constants/apiConstants'
import get from 'lodash/get'

const getUnderwritingReviewRunsAPI = ({ id, credentials, queries, meta, values }) => {
  const reviewId = id || get(values, 'id')
  return paymentsAPI.get({
    path: UNDERWRITING_REVIEW_RUNS({ reviewId }),
    credentials,
    queries,
    meta,
  })
}

export default getUnderwritingReviewRunsAPI
