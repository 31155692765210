import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_PLATFORM_MEMBERS } from 'constants/apiConstants'

const getPlatformMembersAPI = ({ credentials, id, queries, meta }) => {
  return dashboardAPI.get({
    queries,
    credentials,
    path: DASHBOARD_SERVICE_PLATFORM_MEMBERS({ platformId: id }),
    meta,
  })
}

export default getPlatformMembersAPI
