import Cookies from 'react-cookies'
import { AUTH_SESSION } from 'constants/cookieConstants'

const getCurrentAuthSession = () => {
  // TODO: react cookies automatically parses by default, so calling JSON.parse causes an issue (one working fix: pass in { doNotParse: true } as second parameter)
  const sessionString = localStorage.getItem(AUTH_SESSION) || Cookies.load(AUTH_SESSION)

  return sessionString ? JSON.parse(sessionString) : {}
}

export default getCurrentAuthSession
