import './SelectAddDeviceMerchantFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import validateAddDeviceMerchantForm from 'utilities/validate/validateAddDeviceMerchantForm'
import { SELECT_ADD_DEVICE_MERCHANT_FORM } from 'constants/formConstants'

import {
  CANCEL,
  PROCEED,
  APPLICATION,
  MERCHANT_ACCOUNT,
  SELECT_MERCHANT_ACCOUNT_PLACEHOLDER,
  SELECT_APPLICATION_FIELD_PLACEHOLDER,
  NO_MERCHANT_ACCOUNTS_ELIGIBLE,
  MERCHANT_TO_ADD_DEVICE_FOR_PARTNER_TOOLTIP_MSG,
  MERCHANT_TO_ADD_DEVICE_FOR_PLATFORM_TOOLTIP_MSG,
  APPLICATION_TO_ADD_DEVICE_FOR_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const SelectAddDeviceMerchantForm = ({
  handleSubmit = () => {},
  applicationOptions = [],
  merchantOptions = [],
  isRolePlatformCred = false,
  invalid = false,
  isApplicationView = false,
  noMerchantAccountsEligible = false,
  exitModal = () => {},
}) => {
  return (
    <form className='SelectAddDeviceMerchantForm' onSubmit={handleSubmit}>
      <div className='modal-content'>
        {isRolePlatformCred && !isApplicationView && (
          <Field
            name='application'
            classNames='application-options'
            label={APPLICATION}
            component={ReactSelect}
            options={applicationOptions}
            tooltip={APPLICATION_TO_ADD_DEVICE_FOR_TOOLTIP_MSG}
            tooltipPosition='right'
            placeholder={SELECT_APPLICATION_FIELD_PLACEHOLDER}
          />
        )}

        <Field
          name='merchantAccount'
          classNames={`merchant-options ${(!isRolePlatformCred || isApplicationView) ? 'first-dropdown' : ''}`}
          label={MERCHANT_ACCOUNT}
          component={ReactSelect}
          options={merchantOptions}
          tooltip={isRolePlatformCred ? MERCHANT_TO_ADD_DEVICE_FOR_PLATFORM_TOOLTIP_MSG : MERCHANT_TO_ADD_DEVICE_FOR_PARTNER_TOOLTIP_MSG}
          tooltipPosition='right'
          placeholder={noMerchantAccountsEligible ? NO_MERCHANT_ACCOUNTS_ELIGIBLE : SELECT_MERCHANT_ACCOUNT_PLACEHOLDER}
          isDisabled={noMerchantAccountsEligible}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} onClick={exitModal} />
        <Button type='submit' label={PROCEED} disabled={invalid} />
      </div>
    </form>
  )
}

SelectAddDeviceMerchantForm.propTypes = {
  handleSubmit: PropTypes.func,
  applicationOptions: PropTypes.array,
  merchantOptions: PropTypes.array,
  isRolePlatformCred: PropTypes.bool,
  invalid: PropTypes.bool,
  isApplicationView: PropTypes.bool,
  noMerchantAccountsEligible: PropTypes.bool,
  exitModal: PropTypes.func,
}

export default reduxForm({
  form: SELECT_ADD_DEVICE_MERCHANT_FORM,
  validate: validateAddDeviceMerchantForm,
})(SelectAddDeviceMerchantForm)
