import removeUndefined from 'utilities/remove/removeUndefined'
import convertCombinationPlanEquationArrayToString from 'utilities/convert/convertCombinationPlanEquationArrayToString'
import numeral from 'numeral'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import set from 'lodash/set'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { PLATFORM, DEFERRED, EXCLUDES, CONTINUOUS } from 'constants/payoutPlanConstants'

const backendPayoutPlanModel = ({ values }) => {
  const {
    name,
    platformId,
    processor,
    templates,
    schedules,
    combinationPlans,
  } = values

  const formattedTemplates = map(templates, (template) => {
    const { name: templateName, config: templateConfig } = template
    const formattedTemplateConfig = {}

    forEach(templateConfig, (templateConfigResource, templateConfigResourceName) => {
      forEach(templateConfigResource, (templateConfigResourceSection, templateConfigResourceSectionName) => {
        // filter fees with false value
        const templateConfigResourceSectionKeys = keys(pickBy(templateConfigResourceSection))

        set(formattedTemplateConfig, `${templateConfigResourceName}.${templateConfigResourceSectionName}`, templateConfigResourceSectionKeys)
      })

      // if a template includes subtypes, excludes must pass an array even if no subtypes are excluded
      const excludes = get(templateConfigResource, EXCLUDES)
      const excludesEmptyCheck = isEmpty(pickBy(excludes))
      if (excludesEmptyCheck) set(formattedTemplateConfig, `${templateConfigResourceName}.${EXCLUDES}`, [])
    })

    const filteredTemplateConfig = pickBy(formattedTemplateConfig, ({ includes }) => !isEmpty(includes))

    return {
      name: templateName,
      config: filteredTemplateConfig,
      fee_default: true, // vestigial + set to true according to Zack
      split_percentage: 100, // deprecated but still required by API
      level: PLATFORM, // deprecated but still required by API
    }
  })

  const formattedSchedules = map(schedules, (schedule) => {
    const { type, autoCloseOffsetHours, autoCloseOffsetMinutes, autoCloseOffsetDays, templateNames } = schedule

    return {
      type,
      timezone: 'America/New_York',
      auto_close_offset_hours: type !== CONTINUOUS ? numeral(autoCloseOffsetHours).value() : undefined,
      auto_close_offset_minutes: type !== CONTINUOUS ? numeral(autoCloseOffsetMinutes).value() : undefined,
      auto_close_offset_days: type !== CONTINUOUS ? numeral(autoCloseOffsetDays).value() : undefined,
      template_names: map(keys(templateNames), (templateName) => templateName.slice(5)),
    }
  })

  const formattedCombinationPlans = map(combinationPlans, (combinationPlan) => {
    const { name: combinationPlanName, level, sourceInstrumentLabel, destinationInstrumentLabel, rail, submissionSettings = {}, equation } = combinationPlan
    const { type, submissionOffsetBusinessDays } = submissionSettings

    return {
      name: combinationPlanName,
      level,
      source_instrument_label: sourceInstrumentLabel,
      destination_instrument_label: destinationInstrumentLabel,
      rail,
      submission_settings: removeUndefined({
        type,
        submission_offset_business_days: type === DEFERRED ? numeral(submissionOffsetBusinessDays).value() : undefined,
      }),
      equation: convertCombinationPlanEquationArrayToString(equation),
    }
  })

  return {
    name,
    platform_id: platformId,
    processor,
    templates: formattedTemplates,
    schedules: formattedSchedules,
    combination_plans: formattedCombinationPlans,
  }
}

export default backendPayoutPlanModel
