import removeUndefined from 'utilities/remove/removeUndefined'

const backendApplicationModel = ({ values }) => {
  const {
    id,
    tags,
    processingEnabled,
    settlementsEnabled,
    settlementFundingIdentifier,
    disbursementsCardPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsAchPullEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
  } = values

  return removeUndefined({
    id,
    tags,
    processing_enabled: processingEnabled,
    settlement_enabled: settlementsEnabled,
    settlement_funding_identifier: settlementFundingIdentifier,
    disbursements_card_push_enabled: disbursementsCardPushEnabled,
    disbursements_card_pull_enabled: disbursementsCardPullEnabled,
    disbursements_ach_push_enabled: disbursementsAchPushEnabled,
    disbursements_ach_pull_enabled: disbursementsAchPullEnabled,
    disbursements_same_day_ach_push_enabled: disbursementsSameDayAchPushEnabled,
    disbursements_same_day_ach_pull_enabled: disbursementsSameDayAchPullEnabled,
  })
}

export default backendApplicationModel
