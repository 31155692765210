import dashboardAPI from 'utilities/api/dashboardAPI'
import { FILE_EXTERNAL_LINKS } from 'constants/apiConstants'

const postFileExternalLinks = ({ id, values, credentials, meta }) => {
  return dashboardAPI.post({
    meta,
    credentials,
    path: FILE_EXTERNAL_LINKS({ fileId: id }),
    values,
  })
}

export default postFileExternalLinks
