import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const UpdateBalanceAdjustmentsVelocityLimitsFlow = ({
  steps,
}) => {
  return (
    <div className='UpdateBalanceAdjustmentsVelocityLimitsFlow'>
      <FlowsC
        layout='vertical'
        steps={steps}
      />
    </div>
  )
}

UpdateBalanceAdjustmentsVelocityLimitsFlow.propTypes = {
  steps: PropTypes.array,
}

export default UpdateBalanceAdjustmentsVelocityLimitsFlow
