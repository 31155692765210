import './WithdrawOrApproveUnderwritingEntityS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import UnderwritingReviewReportsSummaryC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReviewReportsSummary/UnderwritingReviewReportsSummaryC'
import { ALERT_ICON } from 'constants/iconConstants'
import lowerCase from 'lodash/lowerCase'

import {
  APPROVE,
  CANCEL,
  UNDERWRITING_ACTIONS_WARNING_MESSAGE,
  WITHDRAW,
} from 'constants/language/languageConstants'

const WithdrawOrApproveUnderwritingEntity = ({
  closeModal = () => {},
  isApproveMerchantModal,
  reviewId = '',
  updateUnderwritingEntityStatus = () => {},
  isMerchantUnderwriting,
}) => {
  return (
    <div className='WithdrawOrApproveUnderwritingEntity'>
      <div className='modal-content'>
        <div className='p-2 flex header'>Are you sure you want to <div className='p-2-bold'>{ isApproveMerchantModal ? lowerCase(APPROVE) : lowerCase(WITHDRAW) }</div> this {isMerchantUnderwriting ? 'merchant' : 'applicant'}?</div>
        <UnderwritingReviewReportsSummaryC
          reviewId={reviewId}
        />
        <div className='warning-message flex items-center'><i className={`fa fa-${ALERT_ICON}`} />{UNDERWRITING_ACTIONS_WARNING_MESSAGE}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={updateUnderwritingEntityStatus} label={isApproveMerchantModal ? APPROVE : WITHDRAW} />
      </div>
    </div>
  )
}

WithdrawOrApproveUnderwritingEntity.propTypes = {
  closeModal: PropTypes.func,
  isApproveMerchantModal: PropTypes.bool,
  reviewId: PropTypes.string,
  updateUnderwritingEntityStatus: PropTypes.func,
  isMerchantUnderwriting: PropTypes.bool,
}

export default WithdrawOrApproveUnderwritingEntity
