import getMany from 'utilities/get/getMany'

const convertCreatedAtFilterToQuery = (createdAtFilter) => {
  const [gte, lte] = getMany(createdAtFilter, ['gte', 'lte'])

  return {
    ...(lte && { 'created_at.lte': lte }),
    ...(gte && { 'created_at.gte': gte }),
  }
}

export default convertCreatedAtFilterToQuery
