import NotificationItem from './NotificationItem'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getResourceLink from 'utilities/get/getResourceLink'
import getMany from 'utilities/get/getMany'
import { CLICK_ON_NOTIFICATION } from 'constants/amplitudeConstants'
import { POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const role = get(credentials, 'role')
  const notification = get(props, 'notification')

  const [
    applicationName,
    merchantName,
    displayEventType,
    displayCreatedAt,
    seenAt,
    message,
    webhookDisplay,
  ] = getMany(notification, [
    'applicationName',
    'merchantName',
    'displayEventType',
    'displayCreatedAt',
    'seenAt',
    'message',
    'webhookDisplay',
  ])

  return {
    credentials,
    role,
    notification,
    applicationName,
    merchantName,
    displayEventType,
    displayCreatedAt,
    isNotificationRead: seenAt,
    message,
    webhookDisplay,
  }
}

class NotificationItemC extends Component {
  clickOnNotification = (notification) => {
    const { dispatch, credentials } = this.props

    const [
      resourceType,
      resourceId,
      notificationId,
      merchantId,
    ] = getMany(notification, [
      'resourceType',
      'resourceId',
      'id',
      'merchantId',
    ])
    const credentialId = get(credentials, 'id')

    // reroute the user to the resource related to the notification
    const path = getResourceLink({ resourceId, resourceType, credentialId, merchantId })
    if (path) dispatch(push(path))

    // mark the notification as read
    dispatch({
      type: POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST,
      payload: {
        values: { ids: [notificationId] },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(CLICK_ON_NOTIFICATION, {
          credentials,
        }),
      },
    })
  }

  render() {
    return (
      <NotificationItem
        onClickAction={this.clickOnNotification}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(NotificationItemC)
