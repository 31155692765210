import { LOCATION_CHANGE } from 'react-router-redux'
import getMany from 'utilities/get/getMany'
import { DATE_FILTERS } from 'constants/filterConstants'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import get from 'lodash/get'

import {
  ADD_TABLE_FILTER,
  REMOVE_TABLE_FILTER,
  CLEAR_TABLE_FILTERS,
} from 'constants/flowConstants'

let currentPath = ''

const tableFiltersR = (state, action) => {
  switch (action.type) {
    case ADD_TABLE_FILTER: {
      const filter = get(action, 'payload')

      const [
        field,
        operand,
        value,
      ] = getMany(filter, [
        'field',
        'operand',
        'value',
      ])

      if (value === '') {
        return omit(state, `${field}.${operand}`)
      }

      // this prevents all filters except 'created_at' from having multiple filters/operands applied at the same time
      // preventing bugs like amount for example having both eq and lt applied at the same time
      // we can check for a list of filters that can have multiple filters/operands like 'created_at' if we have more in the future
      const formattedState = get(DATE_FILTERS, field) ? state : omit(state, field)

      return merge({}, formattedState, {
        [field]: {
          [operand]: value,
        },
      })
    }

    case REMOVE_TABLE_FILTER: {
      const filter = get(action, 'payload')

      const [
        field,
        operand,
      ] = getMany(filter, [
        'field',
        'operand',
      ])

      return omit(state, `${field}.${operand}`)
    }

    case CLEAR_TABLE_FILTERS:
      return {}

    case LOCATION_CHANGE: {
      const pathname = get(action, 'payload.pathname')
      const tab = get(action, 'payload.query.tab')
      const totalPathname = tab ? `${pathname}?tab=${tab}` : pathname

      if (totalPathname !== currentPath) {
        currentPath = totalPathname

        return {}
      }

      return state
    }

    default:
      return state || {}
  }
}

export default tableFiltersR
