import { getState } from 'state-layer/configureStore'
import getPageName from 'utilities/get/getPageName'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import { GET_SEARCH_F_REQUEST } from 'constants/flowConstants'
import { GLOBAL_SEARCH } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import trim from 'lodash/trim'

import {
  PATHNAME,
  SEARCH,
} from 'constants/queryConstants'

const submitSearchForm = (values, dispatch, props) => {
  const { credentials, handleFocus, setNewSearch } = props
  const { input = '' } = values

  setNewSearch(true)

  const state = getState()
  const pathName = get(state, PATHNAME)
  const search = get(state, SEARCH)
  const queries = parseUrlQueries(search)
  const pageName = getPageName(pathName, queries)

  dispatch({
    type: GET_SEARCH_F_REQUEST,
    payload: {
      credentials,
      input: trim(input),
    },
    meta: {
      failureCallback: () => handleFocus(),
      actionId: sendAmplitudeActionEvent(GLOBAL_SEARCH, {
        query: input,
        credentials,
        page: pageName,
        resourceType: get(getIDPrefixMapping(input), 'recordName', 'other'),
      }),
    },
  })
}

export default submitSearchForm
