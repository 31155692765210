import React from 'react'
import { getState } from 'state-layer/configureStore'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { createdAtRange } from 'utilities/table/sort'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { SHOW_WEBHOOK_LOGS } from 'constants/featureFlagConstants'
import split from 'lodash/split'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  ID,
  URL,
  STATE,
  CREATED_ON,
  AUTHENTICATION_TYPE,
  LEVEL,
  APPLICATION,
  PLATFORM,
  NICKNAME,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NICKNAME, 'nickname'),
  column('', 'isAcceptingEvents', {
    className: 'xx-small is-accepting-events-header',
    formatter: ({ isAcceptingEvents, enabled }) => ((!isAcceptingEvents && enabled) ? <span className='fa fa-times-octagon' /> : ' '),
    condition: () => getFeatureFlag(SHOW_WEBHOOK_LOGS),
  }),
  column(URL, 'url', { className: 'large url-header' }),
  column(AUTHENTICATION_TYPE, 'displayAuthenticationType'),
  column(LEVEL, 'applicationId', {
    className: 'small',
    condition: () => {
      const state = getState()
      const pathName = get(state, 'routing.locationBeforeTransitions.pathname')
      const topLevelPage = get(split(pathName, '/'), '[2]')

      return isRolePlatform && !isEqual(topLevelPage, 'applications')
    },
    formatter: ({ application }) => (application ? APPLICATION : PLATFORM),
  }),
  column(STATE, 'enabledStatus', {
    className: 'state',
    formatter: ({ enabledStatus }) => <EnabledStatus value={enabledStatus} />,
  }),
]

export default columnDescriptors
