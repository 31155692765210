import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'

const frontendReceiptModel = ({ data: receipt }) => {
  const [
    id,
    createdAt,
    updatedAt,
    amount,
    currency,
    amountBreakdown,
    sendReceiptToBuyer,
    deviceId,
    entityDetailsId,
    entityType,
    entityDetailsCreatedAt,
    type,
    items,
    merchantId,
    merchantBusinessName,
    merchantDBA,
    merchantCity,
    merchantCountry,
    merchantLine1,
    merchantLine2,
    merchantPostalCode,
    merchantRegion,
    paymentInstrumentId,
    paymentInstrumentType,
    paymentInstrumentBillingCity,
    paymentInstrumentBillingCountry,
    paymentInstrumentBillingLine1,
    paymentInstrumentBillingLine2,
    paymentInstrumentBillingPostalCode,
    paymentInstrumentBillingRegion,
    paymentInstrumentBin,
    paymentInstrumentBrand,
    paymentInstrumentCardType,
    paymentInstrumentLastFour,
    paymentInstrumentName,
    networkDetails,
    requestedDeliveryMethods,
    receiptUrl,
  ] = getMany(receipt, [
    'id',
    'created_at',
    'updated_at',
    'amount',
    'currency',
    'amount_breakdown',
    'send_receipt_to_buyer',
    'device_id',
    'entity_details.id',
    'entity_details.type',
    'entity_details.created_at',
    'type',
    'items',
    'merchant_details.id',
    'merchant_details.business_name',
    'merchant_details.doing_business_as',
    'merchant_details.business_address.city',
    'merchant_details.business_address.country',
    'merchant_details.business_address.line1',
    'merchant_details.business_address.line2',
    'merchant_details.business_address.postal_code',
    'merchant_details.business_address.region',
    'payment_instrument_details.id',
    'payment_instrument_details.type',
    'payment_instrument_details.billing_address.city',
    'payment_instrument_details.billing_address.country',
    'payment_instrument_details.billing_address.line1',
    'payment_instrument_details.billing_address.line2',
    'payment_instrument_details.billing_address.postal_code',
    'payment_instrument_details.billing_address.region',
    'payment_instrument_details.bin',
    'payment_instrument_details.brand',
    'payment_instrument_details.card_type',
    'payment_instrument_details.last_four',
    'payment_instrument_details.name',
    'network_details',
    'requested_delivery_methods',
    'receipt_url',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    amountBreakdown,
    sendReceiptToBuyer,
    deviceId,
    entityDetails: {
      id: entityDetailsId,
      type: entityType,
      createdAt: entityDetailsCreatedAt,
    },
    type,
    items,
    merchantDetails: {
      id: merchantId,
      businessName: merchantBusinessName,
      doingBusinessAs: merchantDBA,
      businessAddress: {
        line1: merchantLine1,
        line2: merchantLine2,
        city: merchantCity,
        region: merchantRegion,
        postalCode: merchantPostalCode,
        country: merchantCountry,
      },
    },
    paymentInstrumentDetails: {
      id: paymentInstrumentId,
      type: paymentInstrumentType,
      billingAddress: {
        line1: paymentInstrumentBillingLine1,
        line2: paymentInstrumentBillingLine2,
        city: paymentInstrumentBillingCity,
        region: paymentInstrumentBillingRegion,
        postalCode: paymentInstrumentBillingPostalCode,
        country: paymentInstrumentBillingCountry,
      },
      bin: paymentInstrumentBin,
      brand: paymentInstrumentBrand,
      cardType: paymentInstrumentCardType,
      lastFour: paymentInstrumentLastFour,
      name: paymentInstrumentName,
    },
    networkDetails,
    requestedDeliveryMethods,
    receiptUrl,
  })
}

export default frontendReceiptModel
