import './CustomerOnboardingFormNewUrlLinkS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'

import {
  COPY_LINK,
  CUSTOMER_ONBOARDING_FORM_SUCCESS_MESSAGE,
} from 'constants/language/languageConstants'

const CustomerOnboardingFormNewUrlLink = ({
  url = '',
  copyToClipboard = () => {},
  isFetching = true,
}) => {
  return (
    <div className='CustomerOnboardingFormNewUrlLink'>
      <div className='message'>{CUSTOMER_ONBOARDING_FORM_SUCCESS_MESSAGE}</div>
      <div className='url-container'>
        {isFetching ? <Loader /> : url }
      </div>
      <div className='flex flex-end'>
        <Button onClick={() => copyToClipboard(url)} label={COPY_LINK} className='copy-link-button' />
      </div>
    </div>
  )
}

CustomerOnboardingFormNewUrlLink.propTypes = {
  url: PropTypes.string,
  copyToClipboard: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default CustomerOnboardingFormNewUrlLink
