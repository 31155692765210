import './TabsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TooltipWarning from 'components/Shared/TooltipWarning/TooltipWarning'
import Loader from 'components/Shared/Loader/Loader'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'
import omit from 'lodash/omit'
import camelCase from 'lodash/camelCase'
import classnames from 'classnames'
import { t } from 'constants/language/languageConstants'

import {
  ANGLE_LEFT_ICON,
  ANGLE_RIGHT_ICON,
} from 'constants/iconConstants'

const Tabs = ({
  tabs = [],
  activeTab = 0,
  credentials,
  tabClickHandler = () => {},
  short = false,
  tabsRef,
  isScrollableLeft = false,
  isScrollableRight = false,
  handleScroll = () => {},
  isSecondaryTab = false,
  hasSecondaryTabs = false,
  ...rest
}) => {
  const extraProps = omit(rest, 'dispatch')
  const activeTabData = get(tabs, `${activeTab}`, {})

  const role = get(credentials, 'role')
  const platform = getCurrentPlatform()

  const {
    component: Component,
    queries = {},
    props = {},
    isFetching = false,
  } = activeTabData

  const tabClassNames = classnames('Tabs', { isSecondaryTab })
  const tabContentClassName = classnames('tabContent', { short })

  return (
    <div className={tabClassNames}>
      <div className='tabs-header' onScroll={() => handleScroll()}>
        <div className='tabs-outer flex'>
          { isScrollableLeft && (<span className={`fa fa-${ANGLE_LEFT_ICON} tabs-scroll tabs-scroll-left`} onClick={() => handleScroll(-50)} />) }

          <ul className='tabs flex' role='tabpanel' ref={tabsRef}>
            { !isEmpty(tabs) &&
                map(tabs, (tab, index) => {
                  const name = get(tab, 'name')
                  const tooltipWarning = get(tab, 'tooltipWarning')
                  const condition = get(tab, 'condition', true)
                  const isActive = index === activeTab
                  const tabClassName = `${camelCase(name)}Tab`
                  const classNames = classnames(tabClassName, { active: isActive, hasSecondaryTabs: isSecondaryTab ? false : hasSecondaryTabs }, 'flex')
                  const clickHandler = (e) => ((allowedKeyPressControls(e) && !isActive) ? tabClickHandler(index) : {})

                  const item = (
                    <li
                      key={name}
                      className={classNames}
                      role='tab'
                      aria-selected={isActive}
                      tabIndex='0'
                      onClick={clickHandler}
                      onKeyDown={clickHandler}
                    >
                      {t(name)}
                      <TooltipWarning {...tooltipWarning} />
                    </li>
                  )

                  const showTab = isFunction(condition) ? condition({ platform, credentials, role }) : condition

                  return showTab ? item : null
                })
              }
          </ul>

          { isScrollableRight && (<span className={`fa fa-${ANGLE_RIGHT_ICON} tabs-scroll tabs-scroll-right`} onClick={() => handleScroll(50)} />) }
        </div>
      </div>

      { Component && (
        <div className={tabContentClassName}>
          { isFetching ? <Loader /> : (
            <Component
              queries={queries}
              {...props}
              {...extraProps}
              key={`tab-${activeTab}`}
            />
          )}
        </div>
      )}
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  tabClickHandler: PropTypes.func,
  tooltipWarning: PropTypes.object,
  isScrollableLeft: PropTypes.bool,
  isScrollableRight: PropTypes.bool,
  handleScroll: PropTypes.func,
  isSecondaryTab: PropTypes.bool,
  hasSecondaryTabs: PropTypes.bool,
  rest: PropTypes.object,
}

export default Tabs
