import map from 'lodash/map'
import get from 'lodash/get'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'

const createQueryString = ({ queries, encodeURI = true }) => {
  const encode = encodeURI ? encodeURIComponent : (val) => val

  return !isEmpty(queries) && isPlainObject(queries)
    ? `?${map(keys(queries), (key) => `${encode(key)}=${encode(get(queries, key))}`).join('&')}`
    : ''
}

export default createQueryString
