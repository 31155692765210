import API from 'utilities/api/API'
import createUrl from 'utilities/create/createUrl'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import getDashboardAxiosConfigs from './getDashboardAxiosConfigs'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import get from 'lodash/get'

const dashboardAPI = {
  sendRequest: ({
    api,
    url,
    values,
    mock,
    meta = {},
  }) => {
    const {
      contentType,
      customHeaders,
    } = meta

    const configs = getDashboardAxiosConfigs({ contentType, customHeaders })
    const apiArgs = values ? [url, values, configs] : [url, configs]

    // if mock exists and is enabled on the platform, send back the mock
    const platform = getCurrentPlatform()
    const useMocks = get(platform, 'useMocks', false)

    return mock && useMocks ? mock : api(...apiArgs)
  },

  get: ({ path, absolutePath, queries, mock, meta }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return dashboardAPI.sendRequest({
      api: API.get,
      url,
      mock,
      meta,
    })
  },

  post: ({ path, absolutePath, values, queries, mock, meta }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return dashboardAPI.sendRequest({
      api: API.post,
      url,
      values,
      mock,
      meta,
    })
  },

  patch: ({ path, absolutePath, values, queries, mock, meta }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return dashboardAPI.sendRequest({
      api: API.patch,
      url,
      values,
      mock,
      meta,
    })
  },

  put: ({ path, absolutePath, values, queries, mock, meta }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return dashboardAPI.sendRequest({
      api: API.put,
      url,
      values,
      mock,
      meta,
    })
  },

  delete: ({ path, absolutePath, values, queries, mock, meta }) => {
    const url = absolutePath || createUrl({
      url: `${getDashboardServiceHost()}/${path}`,
      queries,
    })

    return dashboardAPI.sendRequest({
      api: API.delete,
      url,
      values,
      mock,
      meta,
    })
  },
}

export default dashboardAPI
