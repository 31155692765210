import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubmitEvidenceForm from './DisputeSubmitEvidenceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const disputeAmount = get(props, 'disputeAmount')

  const initialValues = {
    representmentAmount: disputeAmount,
  }

  return {
    credentials,
    initialValues,
  }
}

class DisputeSubmitEvidenceFormC extends Component {
  render() {
    return (
      <SubmitEvidenceForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DisputeSubmitEvidenceFormC)
