import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import BalanceEntry from './BalanceEntry'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getBalanceEntryRequest from 'utilities/actions/get/getBalanceEntryRequest'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { balanceEntryStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'

import {
  APPLICATION_PATH,
  BALANCE_ENTRY_PATH,
  DISBURSEMENT_PATH,
  RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  getApplicationSelector,
  getBalanceEntrySelector,
  getIdentitySelector,
  getPaymentInstrumentSelector,
  getTransferSelector,
} from 'state-layer/selectors'

import {
  ACCOUNT_BALANCE_ENTRY,
  ACH,
  ADJUSTMENT,
  AMOUNT,
  APPLICATION,
  CARD,
  CREATED_ON,
  CREATED_VIA,
  FINIX,
  FROM,
  METHOD,
  NOTE,
  RECEIVED,
  RECIPIENT_IDENTITY,
  SENT,
  STATE,
  TO,
  TRACE_ID,
  TYPE,
  VIEW_PAYOUT,
  WIRE_REFERENCE_NUMBER,
  POSTED_ON,
  VIEW_PARENT_BALANCE_ENTRY,
} from 'constants/language/languageConstants'

import {
  ADJUSTMENT_DECREASE,
  ADJUSTMENT_INCREASE,
  PULL_FROM_ACH,
  PULL_FROM_CARD,
} from 'constants/transferConstants'

import {
  BE_PAYMENT_CARD,
  PAYMENT_CARD,
} from 'constants/paymentInstrumentConstants'

const mapStateToProps = (state, props) => {
  const isFetchingBalanceEntry = get(state, `balanceEntriesR.${FETCHING}`)
  const isFetchingTransfer = get(state, `transfersR.${FETCHING}`)
  const isFetchingApplication = get(state, `applicationsR.${FETCHING}`)
  const isFetchingPaymentInstrument = get(state, `paymentInstrumentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const balanceEntryId = get(props, 'params.balanceEntryId')
  const balanceEntry = getBalanceEntrySelector(state, balanceEntryId)
  const isFetching = isFetchingBalanceEntry || isFetchingTransfer || isFetchingApplication || isFetchingPaymentInstrument
  const isRolePlatformCredential = isRolePlatform(credentials)

  const [
    displayAmount,
    displayCreatedAt,
    currency,
    transferId,
    type,
    displayCreatedBy,
    wireDetails,
    tags,
    description,
    displayPostedAt,
    parentBalanceEntryId,
    balanceEntryState,
  ] = getMany(balanceEntry, [
    'displayAmount',
    'displayCreatedAt',
    'currency',
    'transferId',
    'type',
    'displayCreatedBy',
    'wireDetails',
    'tags',
    'description',
    'displayPostedAt',
    'parentBalanceEntryId',
    'state',
  ])

  const transfer = getTransferSelector(state, transferId)
  const wireReferenceNumber = get(wireDetails, 'external_reference_number')

  const [
    transferState,
    raw,
    applicationId,
    paymentInstrumentId,
    transferTraceId,
    transferCreatedAt,
  ] = getMany(transfer, [
    'state',
    'raw',
    'application',
    'paymentInstrumentId',
    'traceId',
    'displayCreatedAt',
  ])

  const application = getApplicationSelector(state, applicationId)
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const applicationName = get(application, 'businessName')
  const paymentInstrumentType = get(paymentInstrument, 'type')
  const recipientIdentityId = get(paymentInstrument, 'identityId')
  const identity = getIdentitySelector(state, recipientIdentityId)
  const recipientIdentityName = get(identity, 'fullName')
  const isAdjustment = includes([ADJUSTMENT_INCREASE, ADJUSTMENT_DECREASE, ACH], type)
  const tagsSectionData = map(tags, (value, label) => ({ label, value }))

  const getToFromValue = () => {
    let toValue = ''
    let fromValue = ''

    if (includes([PULL_FROM_CARD, PULL_FROM_ACH], type)) {
      toValue = applicationName
      fromValue = recipientIdentityName
    } else if (includes([ADJUSTMENT_INCREASE, ADJUSTMENT_DECREASE], type)) {
      toValue = FINIX
      fromValue = applicationName
    } else {
      toValue = recipientIdentityName
      fromValue = applicationName
    }

    return {
      fromValue,
      toValue,
    }
  }

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: !isAdjustment && isRolePlatformCredential,
      },
      {
        label: RECIPIENT_IDENTITY,
        value: recipientIdentityName,
        path: RECIPIENT_PATH({ credentialId, recipientId: recipientIdentityId }),
        condition: !isAdjustment,
      },
    ],
  }

  const headerData = {
    resource: {
      label: ACCOUNT_BALANCE_ENTRY,
      id: balanceEntryId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: STATE,
        value: <TransferStatus value={balanceEntryState} data={balanceEntryStatusMap} />,
      },
      {
        label: FROM,
        value: get(getToFromValue(), 'fromValue') === recipientIdentityName ? <Link className='recipient-link text-link' to={RECIPIENT_PATH({ credentialId, recipientId: recipientIdentityId })}>{get(getToFromValue(), 'fromValue')}</Link> : get(getToFromValue(), 'fromValue'),
        condition: !isAdjustment,
      },
      {
        label: TO,
        value: get(getToFromValue(), 'toValue') === recipientIdentityName ? <Link className='recipient-link text-link' to={RECIPIENT_PATH({ credentialId, recipientId: recipientIdentityId })}>{get(getToFromValue(), 'toValue')}</Link> : get(getToFromValue(), 'toValue'),
        condition: !isAdjustment,
      },
      {
        label: METHOD,
        value: paymentInstrumentType ? (includes([BE_PAYMENT_CARD, PAYMENT_CARD], paymentInstrumentType) ? CARD : ACH) : ADJUSTMENT,
      },
    ],
  }

  const detailsDataSection = convertPageSectionDataToV2([
    {
      label: CREATED_VIA,
      value: displayCreatedBy,
    },
    {
      label: POSTED_ON,
      value: displayPostedAt,
    },
    {
      label: WIRE_REFERENCE_NUMBER,
      value: wireReferenceNumber,
      condition: isAdjustment,
    },
    {
      label: NOTE,
      value: description,
      condition: isAdjustment,
    },
  ], 3)

  const payoutClickToCopies = [
    {
      content: transferId,
    },
    {
      label: TRACE_ID,
      content: transferTraceId,
    },
  ]

  const payoutDataSection = convertPageSectionDataToV2([
    {
      label: AMOUNT,
      value: displayAmount,
    },
    {
      label: STATE,
      value: <TransferStatus value={transferState} rawMessage={raw} />,
    },
    {
      label: CREATED_ON,
      value: transferCreatedAt,
    },
    {
      label: TYPE,
      value: includes([PULL_FROM_CARD, PULL_FROM_ACH, ADJUSTMENT_DECREASE], type) ? RECEIVED : SENT,
    },
  ], 3)

  const payoutLink = <Link className='payout-link text-link' to={DISBURSEMENT_PATH({ credentialId, transferId })}>{VIEW_PAYOUT}</Link>

  const parentBalanceEntry = getBalanceEntrySelector(state, parentBalanceEntryId)
  const parentBalanceEntryLink = <Link className='balance-entry-link text-link' to={BALANCE_ENTRY_PATH({ credentialId, balanceEntryId: parentBalanceEntryId })}>{VIEW_PARENT_BALANCE_ENTRY}</Link>

  const [
    parentBalanceEntryAmount,
    parentBalanceEntryState,
    parentBalanceEntryType,
    parentBalanceEntryPostedOn,
  ] = getMany(parentBalanceEntry, [
    'displayAmount',
    'state',
    'displayType',
    'displayPostedAt',
  ])

  const parentBalanceEntryDetailsData = convertPageSectionDataToV2([
    {
      label: STATE,
      value: <TransferStatus value={parentBalanceEntryState} data={balanceEntryStatusMap} />,
    },
    {
      label: AMOUNT,
      value: parentBalanceEntryAmount,
    },
    {
      label: TYPE,
      value: parentBalanceEntryType,
    },
    {
      label: POSTED_ON,
      value: parentBalanceEntryPostedOn,
    },
  ], 2)

  return {
    credentials,
    balanceEntryId,
    applicationId,
    paymentInstrument,
    paymentInstrumentId,
    transferId,
    headerData,
    detailsDataSection,
    payoutDataSection,
    payoutClickToCopies,
    payoutLink,
    contextBarData,
    isAdjustment,
    tagsSectionData,
    balanceEntry,
    isFetching,
    parentBalanceEntryDetailsData,
    parentBalanceEntryId,
    parentBalanceEntryLink,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceEntry: ({ credentials, balanceEntryId }) => dispatch(getBalanceEntryRequest({ credentials, balanceEntryId })),
    getTransfer: ({ credentials, transferId }) => dispatch(getTransferRequest({ credentials, transferId })),
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    getPaymentInstrument: ({ credentials, paymentInstrumentId }) => dispatch(getPaymentInstrumentRequest({ credentials, paymentInstrumentId })),
  }
}

class BalanceEntryC extends Component {
  componentDidMount() {
    const {
      credentials,
      balanceEntryId,
      getBalanceEntry,
    } = this.props

    getBalanceEntry({ credentials, balanceEntryId })
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      transferId,
      applicationId,
      paymentInstrumentId,
      getTransfer,
      getApplication,
      getPaymentInstrument,
    } = this.props

    const {
      transferId: prevTransferId,
      applicationId: prevApplicationId,
      paymentInstrumentId: prevPaymentInstrumentId,
    } = prevProps

    if (transferId && transferId !== prevTransferId) {
      getTransfer({ credentials, transferId })
    }

    if (applicationId && applicationId !== prevApplicationId) {
      getApplication({ credentials, applicationId })
    }

    if (paymentInstrumentId && paymentInstrumentId !== prevPaymentInstrumentId) {
      getPaymentInstrument({ credentials, paymentInstrumentId })
    }
  }

  render() {
    return (
      <div className='BalanceEntryC'>
        <BalanceEntry
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceEntryC)
