import frontendInstantPayoutModel from 'utilities/create/models/frontendInstantPayoutModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendInstantPayoutsModel = ({ data: instantPayouts }) => {
  const instantPayoutsModel = map(instantPayouts, (instantPayout) => frontendInstantPayoutModel({ data: instantPayout }))

  return keyBy(instantPayoutsModel, 'id')
}

export default frontendInstantPayoutsModel
