import './TransferReturnDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  RETURN_DETAILS,
  RETURN_DETAILS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const TransferReturnDetails = ({
  title = RETURN_DETAILS,
  asideTitle = '',
  reasonCode,
  reasonDecision,
}) => {
  const noDataAvailable = !reasonCode && !reasonDecision

  return (
    <PageSectionV2C
      title={<div className='return-details-title'>{title}</div>}
      asideTitle={<div>{asideTitle}</div>}
      className='return-highlight'
    >
      {noDataAvailable ? <span className='empty-message'>{RETURN_DETAILS_EMPTY_MESSAGE}</span> : (
        <div className='return-details-highlight'>
          <div className='reason-code-display bold'>{reasonCode}</div>
          <div className='reason-decision-message'>{reasonDecision}</div>
        </div>
      )}
    </PageSectionV2C>
  )
}

TransferReturnDetails.propTypes = {
  title: PropTypes.string,
  asideTitle: PropTypes.string,
  reasonCode: PropTypes.string,
  reasonDecision: PropTypes.string,
}

export default TransferReturnDetails
