import React, { Component } from 'react'
import { connect } from 'react-redux'
import RecipientDetail from './RecipientDetail'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getMany from 'utilities/get/getMany'
import createUrl from 'utilities/create/createUrl'
import removeUndefined from 'utilities/remove/removeUndefined'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { UNDERWRITING_RECIPIENT_PATH } from 'constants/pathConstants'
import { FINIX_V1, DUMMY_V1 } from 'constants/processorConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'

import {
  isRolePlatform as isRolePlatformCheck,
  isRolePartner as isRolePartnerCheck,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  getIdentitySelector,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

import {
  SYSTEM,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  NAME,
  EMAIL,
  PHONE,
  ADDRESS,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
  BUSINESS_ADDRESS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')

  const [
    platformId,
    identityId,
    underwritingReviews,
  ] = getMany(props, [
    'platformId',
    'identityId',
    'underwritingReviews',
  ])

  const identity = getIdentitySelector(state, identityId)
  const identityRole = get(identity, 'identityRole')
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const isFlex = isFlexPlatform()
  const disbursementRules = getDisbursementRulesSelector(state)

  const [
    recipientACHRules,
    recipientCardRules,
  ] = getMany(disbursementRules, [
    'recipientACHRules',
    'recipientCardRules',
  ])

  const [
    fullName,
    email,
    phone,
    address,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
    businessDescription,
  ] = getMany(identity, [
    'fullName',
    'email',
    'displayPhone',
    'personalAddress',
    'businessName',
    'doingBusinessAs',
    'displayBusinessPhone',
    'businessAddress',
    'businessDescription',
  ])

  const { tags } = identity

  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const currentProcessor = isProductionEnv ? FINIX_V1 : DUMMY_V1
  const merchantId = get(find(get(identity, 'merchants'), ({ processor }) => processor === currentProcessor), 'id')

  const isBusinessRecipient = !!businessName
  const businessDescriptionString = businessDescription ? `Description: ${businessDescription}` : null

  const [
    dashboardUserRoleName,
    dashboardUserRoleType,
  ] = getMany(credentials, [
    'dashboardUserRoleName',
    'dashboardUserRoleType',
  ])

  const hasUnderwritingPermissions = isFlex && isRolePlatform({ credentials }) && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName)

  const ownerData = [
    {
      label: NAME,
      value: fullName,
    },
    {
      label: EMAIL,
      value: email,
    },
    {
      label: PHONE,
      value: phone,
    },
    {
      label: ADDRESS,
      value: <Address address={address} />,
    },
  ]

  const businessData = [
    {
      label: BUSINESS_NAME,
      value: businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
    },
    {
      label: BUSINESS_PHONE,
      value: businessPhone,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
  ]

  const underwritingReviewPath = ({ id, subjectId, entityId, entityType }) => createUrl({
    url: UNDERWRITING_RECIPIENT_PATH({ credentialId, reviewId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
    }),
  })

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  return {
    recipientACHRules,
    recipientCardRules,
    applicationId,
    platformId,
    isFetching,
    identityId,
    identity,
    identityRole,
    credentials,
    credentialId,
    ownerData,
    businessData,
    isDisbursementsDashboard: isDisbursementsPartnerDashboard(state) || isFlex,
    isRolePlatform: isRolePlatformCheck({ credentials }),
    isRolePartner: isRolePartnerCheck({ credentials }),
    isBusinessRecipient,
    businessDescriptionString,
    underwritingReviews,
    underwritingReviewPath,
    hasUnderwritingPermissions,
    merchantId,
    tagsSectionData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentityRequest: (identityId, credentials) => dispatch(getIdentityRequest(identityId, credentials)),
    getDisbursementRules: ({ entityId, credentials }) => dispatch(getDisbursementRulesRequest({ entityId, credentials })),
    getCurrentUsages: ({ credentials, entityId, currency }) => dispatch(getCurrentUsagesRequest({ credentials, entityId, currency })),
  }
}

class RecipientDetailC extends Component {
  componentDidMount() {
    const {
      merchantId,
      applicationId,
      credentials,
      getCurrentUsages,
      getDisbursementRules,
    } = this.props

    if (merchantId) {
      getCurrentUsages({
        credentials,
        entityId: merchantId,
        currency: 'USD',
      })
    }

    if (applicationId) {
      getDisbursementRules({
        entityId: applicationId,
        credentials,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      merchantId,
      credentials,
      getCurrentUsages,
      getDisbursementRules,
      applicationId,
    } = this.props

    const {
      applicationId: prevApplicationId,
      merchantId: prevMerchantId,
    } = prevProps

    if (applicationId !== prevApplicationId && applicationId) {
      getDisbursementRules({
        entityId: applicationId,
        credentials,
      })
    }

    if (merchantId !== prevMerchantId && merchantId) {
      getCurrentUsages({
        credentials,
        entityId: merchantId,
        currency: 'USD',
      })
    }
  }

  render() {
    return (
      <RecipientDetail
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecipientDetailC)
