import React from 'react'
import { POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS } from 'constants/flowConstants'
import nextFlowStep from 'utilities/nextFlowStep'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import postData from 'utilities/api/postData'
import getMany from 'utilities/get/getMany'
import { credentialNameToEnvironmentUrl } from 'constants/credentialConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { BANK_ICON } from 'constants/iconConstants'
import { CARD } from 'constants/language/languageConstants'
import get from 'lodash/get'

const submitCreateQuickTransactionForm = (values, dispatch, props) => {
  const {
    tokenForm,
    credentials,
    applicationId,
    environment,
  } = props

  const env = environment === LIVE_ENVIRONMENT ? 'live' : 'sandbox'

  if (tokenForm) {
    tokenForm.submit(env, applicationId, async (err, response) => {
      if (err) {
        const errors = get(response, 'data._embedded.errors', [])

        if (Array.isArray(errors) && errors.length > 0) {
          this.showErrors(errors[0]?.message)
          return
        }
      }

      const tokenData = response.data || {}
      const token = tokenData.id
      const authToken = get(credentials, 'authToken')
      const apiUrl = get(credentialNameToEnvironmentUrl, environment)

      const identityResponse = await postData(`${apiUrl}/identities`, authToken, {})
      const identityId = get(identityResponse, 'id')

      const paymentInstrumentResponse = await postData(`${apiUrl}/payment_instruments`, authToken, {
        token,
        identity: identityId,
        type: 'TOKEN',
      })

      const paymentInstrumentResponseModel = frontendPaymentInstrumentModel({ data: paymentInstrumentResponse })

      const [
        paymentInstrumentId,
        displayBrand,
        maskedFullCardNumber,
        maskedAccountNumber,
        type,
        name,
      ] = getMany(paymentInstrumentResponseModel, [
        'id',
        'displayBrand',
        'maskedFullCardNumber',
        'maskedAccountNumber',
        'type',
        'name',
      ])

      const paymentMethodLabel = type === 'Bank Account' ? (
        <div className='flex items-center'>
          <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
          {maskedAccountNumber}
        </div>
      ) : `${displayBrand === 'Unknown' ? CARD : displayBrand} ${maskedFullCardNumber}`

      dispatch({
        type: POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
        id: paymentInstrumentId,
        payload: {
          newValues: {
            [paymentInstrumentId]: paymentInstrumentResponseModel,
          },
        },
        meta: {
          hideFlashNotification: true,
        },
      })

      nextFlowStep({
        dataKey: 'createPayment',
        formValues: {
          ...values,
          // manually add paymentMethod and buyer name to formValues to show in the review step
          paymentMethod: {
            value: paymentInstrumentId,
            label: paymentMethodLabel,
          },
          buyerName: name,
        },
      })
    })
  }
}

export default submitCreateQuickTransactionForm
