import './OnboardingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE } from 'constants/identityConstants'
import isEqual from 'lodash/isEqual'

import {
  BANK_ACCOUNT,
  BENEFICIAL_OWNERSHIP,
  CONTROL_PERSON,
  BUSINESS_INFORMATION,
  PROCESSING_INFORMATION,
  GENERAL_INFORMATION,
  CARD_VOLUME,
  PAYMENT_VOLUME_BY_CUSTOMER_TYPE,
  GENERIC_EMPTY_MESSAGE,
  DETAILS,
} from 'constants/language/languageConstants'

const OnboardingForm = ({
  headerData = {},
  contextBarData = {},
  businessDetailsDataSection = [],
  controlPersonDataSection = [],
  processingDetailsDataSection = [],
  cardVolumeDataSection = [],
  paymentVolumeDataSection = [],
  bankAccountDataSection = [],
  beneficialOwnersDataSection = [],
  formDetailsDataSection = [],
  actions = [],
  businessDataSubtitle = '',
  isFetching,
  processingDataIsEmpty = false,
  businessType = '',
}) => {
  return (
    <div className='OnboardingForm'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        actions={actions}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={convertPageSectionDataToV2(formDetailsDataSection)}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={BUSINESS_INFORMATION}
        columns={businessDetailsDataSection}
        emptyMessage={GENERIC_EMPTY_MESSAGE}
        subTitle={businessDataSubtitle}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={CONTROL_PERSON}
        columns={controlPersonDataSection}
        emptyMessage={GENERIC_EMPTY_MESSAGE}
        isFetching={isFetching}
      />

      {!isEqual(businessType, INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE) && (
        <PageSectionV2C
          title={BENEFICIAL_OWNERSHIP}
          columns={beneficialOwnersDataSection}
          className='beneficial-owners-section'
          emptyMessage={GENERIC_EMPTY_MESSAGE}
          isFetching={isFetching}
        />
      )}

      <PageSectionV2C
        title={PROCESSING_INFORMATION}
        isFetching={isFetching}
      >
        {processingDataIsEmpty ?
          <div>{GENERIC_EMPTY_MESSAGE}</div> : (
            <>
              <PageSectionV2C
                columns={convertPageSectionDataToV2(processingDetailsDataSection)}
                smallTitle={GENERAL_INFORMATION}
                className='general-information-section'
              />
              <PageSectionV2C
                columns={convertPageSectionDataToV2(cardVolumeDataSection)}
                smallTitle={CARD_VOLUME}
                className='card-volume-section'
              />
              <PageSectionV2C
                columns={convertPageSectionDataToV2(paymentVolumeDataSection)}
                smallTitle={PAYMENT_VOLUME_BY_CUSTOMER_TYPE}
                className='payment-volume-section'
              />
            </>
          )}
      </PageSectionV2C>

      <PageSectionV2C
        title={BANK_ACCOUNT}
        columns={bankAccountDataSection}
        isFetching={isFetching}
        emptyMessage={GENERIC_EMPTY_MESSAGE}
      />
    </div>
  )
}

OnboardingForm.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  businessDetailsDataSection: PropTypes.array,
  controlPersonDataSection: PropTypes.array,
  processingDetailsDataSection: PropTypes.array,
  cardVolumeDataSection: PropTypes.array,
  paymentVolumeDataSection: PropTypes.array,
  bankAccountDataSection: PropTypes.array,
  formDetailsDataSection: PropTypes.array,
  actions: PropTypes.array,
  businessDataSubtitle: PropTypes.string,
  isFetching: PropTypes.bool,
  processingDataIsEmpty: PropTypes.bool,
  businessType: PropTypes.string,
}

export default OnboardingForm
