import './IOSApplicationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import { ADD_IOS_APPLICATION_NAME_FORM } from 'constants/formConstants'

const IOSApplicationForm = ({
  buttonGroup = {},
  handleSubmit = () => {},
}) => {
  return (
    <form className='IOSApplicationForm' onSubmit={handleSubmit}>
      <div className='primary-msg'>Enter application name</div>

      <Field
        name='iOSApplicationName'
        className='ios-application-name'
        placeholder='App Name'
        component={InputField}
      />

      { buttonGroup }
    </form>
  )
}

IOSApplicationForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: ADD_IOS_APPLICATION_NAME_FORM,
})(IOSApplicationForm)
