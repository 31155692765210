import './SendPayoutRecipientFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goToPath } from 'state-layer/history'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'
import validateSendPayoutRecipientForm from 'utilities/validate/validateSendPayoutRecipientForm'
import { SEND_PAYOUT_RECIPIENT_FORM } from 'constants/formConstants'
import { ALERT_ICON } from 'constants/iconConstants'
import { SEND_PAYOUT_OPTIONS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  CANCEL,
  NEXT,
  WHO_WOULD_YOU_LIKE_TO_SEND_MONEY_TO,
  RECIPIENT_NAME,
  RECIPIENT_PAYMENT_INSTRUMENT,
} from 'constants/language/languageConstants'

const SendPayoutRecipientForm = ({
  handleSubmit = () => {},
  credentialId = '',
  isFetching = true,
  showWarningMessage = false,
  warningMessageData = {},
  recipientNameOptions = [],
  recipientPaymentInstrumentOptions = [],
  noRecipientOptionsMessage = () => {},
  noPaymentInstrumentOptionsMessage = () => {},
  selectedRecipientData = [],
  selectedPaymentInstrumentData = [],
  selectedRecipientId = '',
  selectedPaymentInstrumentId = '',
  invalid = false,
}) => {
  return (
    <form className='SendPayoutRecipientForm' onSubmit={handleSubmit}>
      <div className='form-header'>
        <h5>{WHO_WOULD_YOU_LIKE_TO_SEND_MONEY_TO}</h5>
      </div>

      <div className='form-content'>
        { isFetching && <Loader /> }
        {!isFetching && (
        <>
          {showWarningMessage && (
          <div className='warning-message'>
            <div className='flex items-baseline'>
              <i className={`fa fa-${ALERT_ICON}`} />
              <div className='p-1'>Please note that you have already reached your {get(warningMessageData, 'limitReached')} {get(warningMessageData, 'type')} payout limit. You cannot send money until {get(warningMessageData, 'windowReset')} tomorrow. Bank payouts will proceed as normal.</div>
            </div>
          </div>
          )}
          <Field
            name='identityId'
            label={RECIPIENT_NAME}
            component={ReactSelect}
            options={recipientNameOptions}
            noOptionsMessage={noRecipientOptionsMessage}
            filterOption={({ data, value }, input) => {
              const name = get(data, 'data.name', '')
              const normalizedInput = input?.toLowerCase()
              return name?.toLowerCase().includes(normalizedInput) || value?.toLowerCase().includes(normalizedInput)
            }}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? get(option, 'label') : get(option, 'data.name')
            }}
          />
          {selectedRecipientId && (
          <PageSectionV2C
            className='recipient-data-section'
            columns={selectedRecipientData}
          />
          )}
          <Field
            name='paymentInstrumentId'
            label={RECIPIENT_PAYMENT_INSTRUMENT}
            component={ReactSelect}
            options={recipientPaymentInstrumentOptions}
            noOptionsMessage={noPaymentInstrumentOptionsMessage}
            isDisabled={!selectedRecipientId}
          />
          {selectedPaymentInstrumentId && (
          <PageSectionV2C
            className='payment-instrument-data-section'
            columns={selectedPaymentInstrumentData}
          />
          )}
        </>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={() => goToPath({ pathname: SEND_PAYOUT_OPTIONS_PATH({ credentialId }) })} label={CANCEL} variant='ghost' />
            <Button type='submit' label={NEXT} disabled={invalid} />
          </div>
        </div>
      </div>
    </form>
  )
}

SendPayoutRecipientForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  credentialId: PropTypes.string,
  showWarningMessage: PropTypes.bool,
  warningMessageData: PropTypes.object,
  recipientNameOptions: PropTypes.array,
  recipientPaymentInstrumentOptions: PropTypes.array,
  noRecipientOptionsMessage: PropTypes.func,
  noPaymentInstrumentOptionsMessage: PropTypes.func,
  selectedRecipientData: PropTypes.array,
  selectedPaymentInstrumentData: PropTypes.array,
  selectedRecipientId: PropTypes.string,
  selectedPaymentInstrumentId: PropTypes.string,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: SEND_PAYOUT_RECIPIENT_FORM,
  validate: validateSendPayoutRecipientForm,
})(SendPayoutRecipientForm)
