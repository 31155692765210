import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'

const frontendReceiptDeliveryAttemptModel = ({ data: receiptDeliveryAttempt }) => {
  const [
    id,
    createdAt,
    updatedAt,
    destination,
    receiptId,
    state,
    type,
  ] = getMany(receiptDeliveryAttempt, [
    'id',
    'created_at',
    'updated_at',
    'destination',
    'receipt_id',
    'state',
    'type',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    destination,
    receiptId,
    state,
    type,
  })
}

export default frontendReceiptDeliveryAttemptModel
