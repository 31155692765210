import './ProvisionMerchantProcessorFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitProvisionMerchantProcessorsForm from 'utilities/submit/submitProvisionMerchantProcessorsForm'
import { PROVISION_MERCHANT_PROCESSOR_FORM } from 'constants/formConstants'
import { PROCESSOR_DESCRIPTIONS } from 'constants/processorConstants'
import isEmpty from 'lodash/isEmpty'

import {
  CREATE,
  ENABLE,
} from 'constants/language/languageConstants'

import {
  ANGLE_UP_ICON,
  ANGLE_RIGHT,
} from 'constants/iconConstants'

const ProvisionMerchantProcessorForm = ({
  processor = '',
  processorLabel = '',
  enabled = false,
  showCodeInput = false,
  showBankAccount = false,
  toggleShowCodeInput = () => {},
  toggleShowBankAccounts = () => {},
  paymentInstrumentOptions = [],
  formatOptionLabel = () => {},
  filterOption = () => {},
  handleSubmit = () => {},
  optionalProcessorParamsAllowed = false,
  hasPaymentInstrument = true,
  gateway = '',
}) => {
  const icon = (show) => (show === true ? ANGLE_UP_ICON : ANGLE_RIGHT)
  const hasGateway = !isEmpty(gateway)

  return (
    <form className='ProvisionMerchantProcessorForm' onSubmit={handleSubmit(submitProvisionMerchantProcessorsForm)}>
      <div className='flex space-between items-center'>
        <div>
          <h2 className='processor-label'>{processorLabel}</h2>
          <p className='processor-description'>{PROCESSOR_DESCRIPTIONS[hasGateway ? gateway : processor]}</p>
        </div>

        {/* TODO: always allow users to create/enable processor when there are associated gateways until the BE returns needed fields in response */}
        {/* { enabled && !hasGateway && <span><EnabledStatus value='Enabled' /></span> } */}
        {/* { !hasGateway && !enabled && !optionalProcessorParamsAllowed && <Button type='submit' disabled={!hasPaymentInstrument} label={ENABLE} />} */}
        {!optionalProcessorParamsAllowed && <Button type='submit' disabled={!hasPaymentInstrument} label={CREATE} />}
      </div>

      { !enabled && optionalProcessorParamsAllowed && (
        <div className='processorOptions flex'>
          <div className='codeInputOptions flex' onClick={toggleShowCodeInput}>
            <span className={`icon fas fa-${icon(showCodeInput)}`} />

            Advanced Settlement Chain Code and Division Code
          </div>

          { showCodeInput === true && (
            <div>
              <Field
                id='chainCode'
                name='chainCode'
                className='chainCode'
                label='Chain Code'
                component={InputField}
              />

              <Field
                id='divisionCode'
                name='divisionCode'
                className='divisionCode'
                label='Division Code'
                component={InputField}
              />
            </div>
          )}

          <div className='bankAccountOptions flex' onClick={toggleShowBankAccounts}>
            <span className={`icon fas fa-${icon(showBankAccount)}`} onClick={toggleShowBankAccounts} />

            Advanced Settlement Bank Accounts
          </div>

          { showBankAccount === true && (
          <div>
            <div className='optionField defaultBankAccount flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Default Bank Account</span>
                <span className='subLabel'>Mandatory</span>
              </div>

              <Field
                name='defaultBankAccount'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditDeposits flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Credit Deposits</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='creditDeposits'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditChargebacks flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Credit Chargebacks</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='creditChargebacks'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditProcessingFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Credit Processing Fees</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='creditProcessingFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditConvenienceFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Credit Convenience Fees</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='creditConvenienceFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitDeposits flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Debit Deposits</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='debitDeposits'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitChargebacks flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Debit Chargebacks</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='debitChargebacks'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitConvenienceFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label'>Debit Convenience Fees</span>
                <span className='subLabel'>Optional</span>
              </div>

              <Field
                name='debitConvenienceFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitProcessingFees flex space-between'>
              <div className='disabled flex column'>
                <span className='label'>Debit Processing Fees</span>
                <span className='subLabel'>Disabled</span>
              </div>

              <Field
                name='debitProcessingFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                isDisabled
              />
            </div>
          </div>
          )}

          <div className='submit'>
            <Button type='submit' className='provision-merchant-button' label={ENABLE} />
          </div>
        </div>
      )}
    </form>
  )
}

ProvisionMerchantProcessorForm.propTypes = {
  processor: PropTypes.string,
  processorLabel: PropTypes.string,
  enabled: PropTypes.bool,
  showCodeInput: PropTypes.bool,
  showBankAccount: PropTypes.bool,
  toggleShowCodeInput: PropTypes.func,
  toggleShowBankAccounts: PropTypes.func,
  handleSubmit: PropTypes.func,
  optionalProcessorParamsAllowed: PropTypes.bool,
  gateway: PropTypes.string,
}

export default reduxForm({
  form: PROVISION_MERCHANT_PROCESSOR_FORM,
})(ProvisionMerchantProcessorForm)
