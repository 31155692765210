import { EMAIL_ADDRESS } from 'constants/language/languageConstants'
import { ELAVON_PARTNER_CONNECT } from 'constants/platformConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import isPlatform from 'utilities/is/isPlatform'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidRegex,
  validateDate,
  validateTaxId,
  validateAddress,
  validatePercentage,
} from 'utilities/validate'

const validateIdentityPrincipalsForm = (values, props) => {
  const editIdentity = get(props, 'edit', false)
  const principalsArray = get(values, 'principals')
  const principals = (principalsArray && !isEmpty(principalsArray)) ? principalsArray : get(props, 'initialValues.principals')

  const totalPrincipalPercentageOwnership = reduce(principals, (total, principal) => {
    const {
      principalPercentageOwnership = '0',
    } = principal

    return total + parseFloat(principalPercentageOwnership)
  }, 0)

  const isElavon = isPlatform(ELAVON_PARTNER_CONNECT)
  const totalOwnershipGreaterThanMax = totalPrincipalPercentageOwnership > 100 && `Total principal percentage ownership between all principals may not exceed 100%. Currently set at ${totalPrincipalPercentageOwnership}%.`

  const principalsErrors = map(principalsArray, (principal = {}) => {
    const [
      firstName,
      lastName,
      email,
      personalAddress,
      principalPercentageOwnership,
      dateOfBirth,
      taxId,
      phone,
      title,
    ] = getMany(principal, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'dateOfBirth',
      'taxId',
      'phone',
      'title',
    ])

    return {
      firstName: fieldEmpty(firstName, 'First Name'),
      lastName: fieldEmpty(lastName, 'Last Name'),
      email: fieldEmpty(email, 'Email') || invalidRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
      personalAddress: validateAddress({ address: personalAddress }),
      dateOfBirth: validateDate({ date: dateOfBirth }),
      principalPercentageOwnership: validatePercentage({ field: principalPercentageOwnership, name: 'Principal Percentage Ownership', percentMinEqual: true }) || totalOwnershipGreaterThanMax,
      taxId: (isElavon || editIdentity) ? (taxId && validateTaxId({ taxId, name: 'Tax ID' })) : (fieldEmpty(taxId, 'Tax ID') || validateTaxId({ taxId, name: 'Tax ID' })),
      phone: fieldEmpty(phone, 'Phone Number') || invalidRegex({ field: phone, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: 'Phone number must be a max of 11 digits.' }),
      title: fieldEmpty(title, 'Title'),
    }
  })

  return {
    principals: principalsErrors,
  }
}

export default validateIdentityPrincipalsForm
