import './PlaidReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  NO_REPORT_DETAILS_AVAILABLE,
  SUMMARY,
  OWNERS,
} from 'constants/language/languageConstants'

const PlaidReport = ({
  summaryResponseData = [],
  owners = [],
  details,
  constructOwnerData = () => {},
}) => {
  return (
    <div className='PlaidReport'>
      <PageSectionV2C
        smallTitle={SUMMARY}
        className='summary-data-section'
        columns={summaryResponseData}
      />

      <PageSectionV2C
        smallTitle={OWNERS}
        className='owners-data-section'
      >
        {map(owners, (owner, index) => {
          const data = constructOwnerData(owner)

          return (
            <PageSectionV2C
              key={`owner-${index}`}
              className='owner-data-section'
              smallTitle={get(owner, 'names[0]')}
              columns={data}
            />
          )
        })}
      </PageSectionV2C>
    </div>
  )
}

PlaidReport.propTypes = {
  summaryResponseData: PropTypes.array,
  owners: PropTypes.array,
  details: PropTypes.object,
  constructOwnerData: PropTypes.func,
}

export default PlaidReport
