import React, { Component } from 'react'
import { connect } from 'react-redux'
import BusinessIdentityVerificationReportV2 from './BusinessIdentityVerificationReportV2'
import Address from 'components/Customer/Shared/Display/Address/Address'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatPhone from 'utilities/forms/format/formatPhone'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import join from 'lodash/join'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import upperCase from 'lodash/upperCase'
import isEmpty from 'lodash/isEmpty'

import {
  BUSINESS_NAME,
  BUSINESS_TAX_ID,
  NO_MATCH as NO_MATCH_LABEL,
  MATCH as MATCH_LABEL,
  PASSED as PASSED_LABEL,
  FAILED as FAILED_LABEL,
  UNKNOWN,
  BUSINESS_TYPE,
  BUSINESS_ADDRESS,
  BUSINESS_PHONE,
  BUSINESS_WEBSITE,
  ASSOCIATED_PERSONS,
  BUSINESS_ALIASES,
  BUSINESS_TAX_ID_TYPE,
  BUSINESS_PHONE_NUMBER,
  BUSINESS_WEBSITES,
  STATUS,
  BUSINESS_TYPE_ALIAS,
  DATE,
  REGION,
  NUMBER,
  COUNTRY,
} from 'constants/language/languageConstants'

import {
  MATCH,
  NO_MATCH,
  PASSED,
  FAILED,
} from 'constants/underwritingConstants'

import {
  CHECK_CIRCLE_ICON,
  DOT_CIRCLE,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    addressDetailsResult,
    addressDetailsDescription,
    addresses,
    nameDetailsResult,
    nameDetailsDescription,
    phoneNumberDetailsResult,
    phoneNumberDetailsDescription,
    phoneNumbers,
    taxIdDetailsResult,
    taxIdDetailsDescription,
    websiteDetailsResult,
    websiteDetailsDescription,
    websites,
    personDetailsResult,
    personDetailsDescription,
    associatedPersons,
    businessName,
    businessAliases,
    taxIdType,
    businessType,
    registrations,
    industries,
  ] = getMany(report, [
    'details.address_details.result',
    'details.address_details.description',
    'details.address_details.addresses',
    'details.name_details.result',
    'details.name_details.description',
    'details.phone_number_details.result',
    'details.phone_number_details.description',
    'details.phone_number_details.phone_numbers',
    'details.tax_id_details.result',
    'details.tax_id_details.description',
    'details.website_details.result',
    'details.website_details.description',
    'details.website_details.websites',
    'details.associated_persons_details.result',
    'details.associated_persons_details.description',
    'details.associated_persons_details.associated_persons',
    'details.name_details.name',
    'details.additional_details.aliases',
    'details.tax_id_details.type',
    'details.additional_details.entity_type',
    'details.additional_details.filings',
    'details.additional_details.industries',
  ])

  const formatVerificationSummaryValue = (value, description) => {
    switch (value) {
      case NO_MATCH:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${TIMES_CIRCLE_ICON}`} />
            {NO_MATCH_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case FAILED:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${TIMES_CIRCLE_ICON}`} />
            {FAILED_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case MATCH:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />
            {MATCH_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case PASSED:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />
            {PASSED_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      default:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa ${DOT_CIRCLE}`} />
            {UNKNOWN}
            { description && <TooltipLabel message={description} /> }
          </div>
        )
    }
  }

  const verificationSummaryData = convertPageSectionDataToV2([
    { label: BUSINESS_TAX_ID, value: formatVerificationSummaryValue(taxIdDetailsResult, taxIdDetailsDescription) },
    { label: BUSINESS_NAME, value: formatVerificationSummaryValue(nameDetailsResult, nameDetailsDescription) },
    { label: ASSOCIATED_PERSONS, value: formatVerificationSummaryValue(personDetailsResult, personDetailsDescription) },
    { label: BUSINESS_ADDRESS, value: formatVerificationSummaryValue(addressDetailsResult, addressDetailsDescription) },
    { label: BUSINESS_PHONE, value: formatVerificationSummaryValue(phoneNumberDetailsResult, phoneNumberDetailsDescription) },
    { label: BUSINESS_WEBSITE, value: formatVerificationSummaryValue(websiteDetailsResult, websiteDetailsDescription) },
  ])

  const phoneNumbersData = (
    <div className='phone-numbers-data'>
      {map(phoneNumbers, number => {
        return <div>{formatPhone(number)}</div>
      })}
    </div>
  )

  const websitesData = (
    <div className='websites-data'>
      {map(websites, website => {
        return <div>{website}</div>
      })}
    </div>
  )

  const businessInformationData = convertPageSectionDataToV2([
    { label: BUSINESS_NAME, value: businessName },
    { label: BUSINESS_ALIASES, value: join(businessAliases, ', ') },
    { label: BUSINESS_TYPE, value: businessType },
    { label: BUSINESS_TAX_ID_TYPE, value: taxIdType },
    { label: BUSINESS_PHONE_NUMBER, value: phoneNumbersData },
    { label: BUSINESS_WEBSITES, value: websitesData },
  ])

  const addressRecordsData = isEmpty(addresses) ? [] : convertPageSectionDataToV2(map(addresses, (address, index) => {
    const [
      line1,
      line2,
      city,
      region,
      country,
      postalCode,
    ] = getMany(address, [
      'address_lines[0]',
      'address_lines[1]',
      'locality',
      'administrative_area',
      'region_code',
      'postal_code',
    ])
    return {
      label: `Address #${index + 1}`,
      value: <Address address={{ line1, line2, city, region, country, postalCode }} />,
    }
  }))

  const associatedPersonsInformation = isEmpty(associatedPersons) ? [] : convertPageSectionDataToV2(map(associatedPersons, ({ full_name: fullName, titles }, index) => {
    const titlesString = titles ? `(${join(titles, ', ')})` : ''
    const fullNameString = startCase(lowerCase(fullName))
    return { label: `Contact #${index + 1}`, value: `${fullNameString} ${titlesString}` }
  }))

  const industriesData = isEmpty(industries) ? [] : map(industries, (industry) => {
    const [
      code,
      title,
      type,
    ] = getMany(industry, [
      'code',
      'title',
      'type',
    ])
    return { code, title, type: upperCase(type) }
  })

  const businessRegistrationsData = isEmpty(registrations) ? [] : map(registrations, (registration, index) => {
    const [
      registrationAddresses,
      country,
      registrationRegion,
      date,
      number,
      type,
      status,
    ] = getMany(registration, [
      'addresses',
      'country',
      'region',
      'date',
      'number',
      'type',
      'status',
    ])

    const businessRegistrationAddressMap = map(registrationAddresses, (address, addressIdx) => {
      const [
        line1,
        line2,
        city,
        region,
        addressCountry,
        postalCode,
        addressType,
      ] = getMany(address, [
        'address.address_lines[0]',
        'address.address_lines[1]',
        'address.locality',
        'address.administrative_area',
        'address.region_code',
        'address.postal_code',
        'type',
      ])
      return {
        label: `Address #${addressIdx + 1}`,
        value: <div><Address address={{ line1, line2, city, region, country: addressCountry, postalCode }} />({addressType})</div>,
      }
    })

    return {
      title: `Registration #${index + 1}`,
      data: convertPageSectionDataToV2([
        { label: STATUS, value: status },
        { label: BUSINESS_TYPE_ALIAS, value: type },
        { label: DATE, value: date },
        { label: REGION, value: registrationRegion },
        { label: NUMBER, value: number },
        { label: COUNTRY, value: country },
        ...businessRegistrationAddressMap,
      ]),
    }
  })

  return {
    credentials,
    verificationSummaryData,
    businessInformationData,
    addressRecordsData,
    associatedPersonsInformation,
    industriesData,
    businessRegistrationsData,
  }
}

class BusinessIdentityVerificationReportV2C extends Component {
  render() {
    return (
      <BusinessIdentityVerificationReportV2
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(BusinessIdentityVerificationReportV2C)
