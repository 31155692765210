import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_SCHEDULES,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const getSubscriptionSchedulesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    path: SUBSCRIPTION_SCHEDULES,
    queries,
    service: SUBSCRIPTION,
  })
}

export default getSubscriptionSchedulesAPI
