import './SubscriptionScheduleEnrollmentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { getSubscriptionScheduleEnrollmentsScheduleSelector } from 'state-layer/selectors'
import { SUBSCRIPTION_SCHEDULE_ENROLLMENTS } from 'constants/linkConstants'
import { SUBSCRIPTION_BILLING_ICON } from 'constants/iconConstants'
import { GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS } from 'constants/flowConstants'

const SubscriptionScheduleEnrollments = ({
  isFetching = false,
  subscriptionScheduleId = '',
  initialQueries,
  allowedFilters,
  merchantPath,
  selectedItemsKey,
  selectedItemsData = {},
}) => {
  return (
    <div className='SubscriptionScheduleEnrollments'>
      <TableC
        columnDescriptors={columnDescriptors}
        flow={GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS}
        linksKey={SUBSCRIPTION_SCHEDULE_ENROLLMENTS}
        isFetching={isFetching}
        initialQueries={initialQueries}
        fileNameCSV={`Subscription_Schedule_${subscriptionScheduleId}_Enrollments`}
        fileResourceTitleCSV='Subscription Schedule Enrollments'
        icon={`fa fa-${SUBSCRIPTION_BILLING_ICON}`}
        emptyMessage='No Subscription Enrollments Available'
        allowedFilters={allowedFilters}
        selector={getSubscriptionScheduleEnrollmentsScheduleSelector}
        selectorId={subscriptionScheduleId}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        path={merchantPath}
      />
    </div>
  )
}

SubscriptionScheduleEnrollments.propTypes = {
  isFetching: PropTypes.bool,
  subscriptionScheduleId: PropTypes.string,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  merchantPath: PropTypes.func,
  selectedItemsKey: PropTypes.string,
  selectedItemsData: PropTypes.object,
}

export default SubscriptionScheduleEnrollments
