import numeral from 'numeral'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import getMany from 'utilities/get/getMany'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import { PAYMENT_DEVICE_PATH } from 'constants/pathConstants'
import { POST_DEVICE_F_REQUEST } from 'constants/flowConstants'
import { ADD_DEVICE } from 'constants/amplitudeConstants'
import filter from 'lodash/filter'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import map from 'lodash/map'
import includes from 'lodash/includes'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  AMOUNT_VALUE,
  EMAIL_VALUE,
  ON_THRESHOLD_AMOUNT_VALUE,
  PRINT_VALUE,
  SMS_VALUE,
} from 'constants/deviceConstants'

const submitAddDeviceForm = (values, dispatch, props) => {
  const {
    credentials,
    merchantId,
    merchantGateway,
  } = props

  const credentialId = get(credentials, 'id')

  const [
    percentageOne,
    percentageTwo,
    percentageThree,
    amountOne,
    amountTwo,
    amountThree,
    promptTipOnScreen,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
    promptSignature,
    signatureThresholdAmount,
    promptForSignature,
  ] = getMany(values, [
    'percentageOne',
    'percentageTwo',
    'percentageThree',
    'amountOne',
    'amountTwo',
    'amountThree',
    'promptTipOnScreen',
    'receiptDeliveryPrint',
    'receiptDeliveryEmail',
    'receiptDeliverySms',
    'automaticDeliveryPrint',
    'automaticDeliveryEmail',
    'automaticDeliverySms',
    'promptSignature',
    'signatureThresholdAmount',
    'promptForSignature',
  ])

  const percentOptions = [percentageOne, percentageTwo, percentageThree]
  const fixedOptions = map([amountOne, amountTwo, amountThree], (amount) => convertAmountStringToNumber({ amount }))

  const availableReceiptMethods = filter([
    receiptDeliveryPrint ? PRINT_VALUE : undefined,
    receiptDeliveryEmail ? EMAIL_VALUE : undefined,
    receiptDeliverySms ? SMS_VALUE : undefined,
  ], undefined)

  const automaticReceiptDeliveryMethods = filter([
    automaticDeliveryPrint ? PRINT_VALUE : undefined,
    automaticDeliveryEmail ? EMAIL_VALUE : undefined,
    automaticDeliverySms ? SMS_VALUE : undefined,
  ], undefined)

  const promptSignatureValueToSubmit = includes([FINIX_V1, DUMMY_V1], merchantGateway) ? undefined : get(promptSignature, 'value')
  const promptForSignatureValueToSubmit = includes([FINIX_V1, DUMMY_V1], merchantGateway) ? get(promptForSignature, 'value') : undefined
  const signatureThresholdAmountToSubmit = (get(promptSignature, 'value') === AMOUNT_VALUE || get(promptForSignature, 'value') === ON_THRESHOLD_AMOUNT_VALUE) ? numeral(signatureThresholdAmount).value() : 0

  const valuesToSubmit = promptTipOnScreen ? {
    ...values,
    promptSignature: promptSignatureValueToSubmit,
    promptForSignature: promptForSignatureValueToSubmit,
    signatureThresholdAmount: signatureThresholdAmountToSubmit,
    percentOptions,
    fixedOptions: amountOne ? fixedOptions : undefined,
    availableReceiptMethods,
    automaticReceiptDeliveryMethods,
  } : {
    ...values,
    promptSignature: promptSignatureValueToSubmit,
    promptForSignature: promptForSignatureValueToSubmit,
    signatureThresholdAmount: signatureThresholdAmountToSubmit,
  }

  dispatch({
    type: POST_DEVICE_F_REQUEST,
    payload: {
      id: merchantId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const deviceId = head(keys(newValues))
        dispatch(redirectRequest({ path: PAYMENT_DEVICE_PATH({ credentialId, deviceId }) }))
      },
      actionId: sendAmplitudeActionEvent(ADD_DEVICE, {
        credentials,
        values,
      }),
    },
  })
}

export default submitAddDeviceForm
