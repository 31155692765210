import './ACHReturnsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { ACH_RETURNS_ICON } from 'constants/iconConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

import {
  ACH_RETURNS_EMPTY_TABLE_MESSAGE,
  ACH_RETURNS_RESOURCE_TITLE,
  ACH_RETURNS_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const ACHReturns = ({
  paymentPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  hiddenExportFields = [],
  quickFilters,
}) => {
  return (
    <div className='ACHReturns'>
      <HeaderV2C
        title={ACH_RETURNS_RESOURCE_TITLE}
        subTitle={ACH_RETURNS_SUBTITLE_DESCRIPTION}
        // learnMoreLink={} TODO: add once we get a link
      />

      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={ACH_RETURNS_RESOURCE_TITLE}
        entityType={TRANSFER}
        showAdvancedExport
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${ACH_RETURNS_ICON}`}
        emptyMessage={ACH_RETURNS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ACHReturns.propTypes = {
  paymentPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  hiddenExportFields: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default ACHReturns
