import './CreateMerchantVerificationFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import submitCreateMerchantVerificationForm from 'utilities/submit/submitCreateMerchantVerificationForm'
import { CREATE_MERCHANT_VERIFICATION_FORM } from 'constants/formConstants'
import { CREATE } from 'constants/language/languageConstants'
import includes from 'lodash/includes'

const CREATE_VERIFICATION_MSG = 'Would you like to create a Merchant verification request?'

const formatOptionLabel = ({ value, label, accountType }, { context }) => {
  const { name, maskedAccountNumber } = value

  if (context === 'value') {
    return label
  }

  return (
    <div>
      <div>{label}</div>
      <div>{name}</div>
      <div>{accountType} - {maskedAccountNumber}</div>
    </div>
  )
}

const filterOption = (option, inputValue) => {
  const { value } = option
  const { id, name, maskedAccountNumber } = value

  return includes(id, inputValue) || includes(name, inputValue) || includes(maskedAccountNumber, inputValue)
}

const CreateMerchantVerificationForm = ({
  merchantId,
  handleSubmit = () => {},
  paymentInstrumentOptions = [],
  optionalProcessorParamsAllowed = false,
  showCodeInputOptions = false,
}) => {
  return (
    <form className='CreateMerchantVerificationForm' onSubmit={handleSubmit(submitCreateMerchantVerificationForm)}>
      <div className='section'>
        <div>
          <div className='p-2 create-verification-message'>{CREATE_VERIFICATION_MSG}</div>
        </div>

        <div>
          <label className='label-2'>Merchant:</label>

          <div className='p-2-bold merchant-id'>{merchantId}</div>
        </div>
      </div>

      {
        optionalProcessorParamsAllowed && (
        <div className='advancedOptions'>
          {showCodeInputOptions && (
          <div className='advancedCodeOptions'>
            <h4>Advanced Chain Code and Division Code</h4>

            <div className='codeInput'>
              <Field
                id='chainCode'
                name='chainCode'
                className='chainCode'
                label='Chain Code'
                component={InputField}
              />

              <Field
                id='divisionCode'
                name='divisionCode'
                className='divisionCode'
                label='Division Code'
                component={InputField}
              />
            </div>
          </div>
          )}
          <div className='advancedProcessorOptions'>
            <h4>Advanced Processor Options</h4>

            <div className='optionField defaultBankAccount flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Default Bank Account</span>
                <span className='subLabel label-2-italic'>Mandatory</span>
              </div>

              <Field
                name='defaultBankAccount'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditDeposits flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Credit Deposits</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='creditDeposits'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditChargebacks flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Credit Chargebacks</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='creditChargebacks'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditProcessingFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Credit Processing Fees</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='creditProcessingFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField creditConvenienceFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Credit Convenience Fees</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='creditConvenienceFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitDeposits flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Debit Deposits</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='debitDeposits'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitChargebacks flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Debit Chargebacks</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>

              <Field
                name='debitChargebacks'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitConvenienceFees flex space-between'>
              <div className='enabled flex column'>
                <span className='label label-2'>Debit Convenience Fees</span>
                <span className='subLabel label-2-italic'>Optional</span>
              </div>
              <Field
                name='debitConvenienceFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                menuPlacement='top'
                filterOption={filterOption}
              />
            </div>

            <div className='optionField debitProcessingFees flex space-between'>
              <div className='disabled flex column'>
                <span className='label label-2'>Debit Processing Fees</span>
                <span className='subLabel label-2-italic'>Disabled</span>
              </div>

              <Field
                name='debitProcessingFees'
                component={ReactSelect}
                options={paymentInstrumentOptions}
                formatOptionLabel={formatOptionLabel}
                isDisabled
              />
            </div>
          </div>
        </div>
        )
      }

      <div className='buttons flex flex-end'>
        <Button type='submit' label={CREATE} />
      </div>
    </form>
  )
}

CreateMerchantVerificationForm.propTypes = {
  merchantId: PropTypes.string,
  handleSubmit: PropTypes.func,
  paymentInstrumentOptions: PropTypes.array,
  optionalProcessorParamsAllowed: PropTypes.bool,
  showCodeInputOptions: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_MERCHANT_VERIFICATION_FORM,
})(CreateMerchantVerificationForm)
