export const PAYOUT_PLAN_FORM_DATA_KEY = 'payoutPlanForm'
export const TEMPLATES_FORM_DATA_KEY = 'templatesForm'
export const SCHEDULES_FORM_DATA_KEY = 'schedulesForm'
export const COMBINATION_PLANS_FORM_DATA_KEY = 'combinationPlansForm'

export const CREATE_SUBSCRIPTION_SCHEDULE_DATA_KEY = 'subscriptionScheduleForm'
export const CREATE_SUBSCRIPTION_AMOUNT_DATA_KEY = 'subscriptionAmountForm'
export const CREATE_SUBSCRIPTION_ENROLLMENT_DATA_KEY = 'subscriptionEnrollmentForm'
export const SELECT_SUBSCRIPTION_SCHEDULE_DATA_KEY = 'selectSubscriptionScheduleForm'

export const CREATE_BALANCE_TRANSFER_DATA_KEY = 'createBalanceTransferForm'

export const ADD_NEW_DOMAIN_DATA_KEY = 'addNewDomainKeyForm'

export const ADD_IOS_APPLICATION_NAME_DATA_KEY = 'addIOSApplicationNameForm'

// create compliance form template
export const CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY = 'createNewComplianceFormTemplateForm'
export const COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY = 'complianceAssessmentInformationForm'
export const COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY = 'complianceSelfAssessmentQuestionnaireAForm'
export const COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY = 'complianceCompensatingControlsWorksheetForm'
export const COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY = 'complianceExplanationOfNonApplicabilityForm'
export const COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY = 'complianceValidationAndAttestationDetailsForm'
export const COMPLIANCE_APPLY_TEMPLATE_DATA_KEY = 'complianceApplyTemplateForm'

export const CREATE_ONBOARDING_FORM_DETAILS_DATA_KEY = 'createOnboardingFormDetailsForm'
export const CREATE_ONBOARDING_FORM_BASICS_DATA_KEY = 'createOnboardingFormBasicsForm'
