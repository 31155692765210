import './QuickFilterPageS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FilterSearchBar from 'components/Shared/FilterSearch/FilterSearchBar'
import map from 'lodash/map'

const QuickFilterPage = ({
  records,
  filteredRecords = [],
  filterQuery = '',
  updateFilter = () => {},
  removeFilter = () => {},
  component,
  title,
  extraProps = {},
  searchPlaceholder = 'Search',
  actions = [],
}) => {
  const Component = component

  const filterAreaClassNames = classnames(
    'flex',
    'items-center',
    { 'space-between': !!title },
    { 'flex-start': !title },
  )

  return (
    <div className='QuickFilterPage'>
      <div className={filterAreaClassNames}>
        {title && <h4>{title}</h4>}

        <FilterSearchBar
          filterQuery={filterQuery}
          updateFilter={updateFilter}
          removeFilter={removeFilter}
          searchPlaceholder={searchPlaceholder}
        />

        {map(actions, ({ label = '', className = '', action = () => {}, link = false }) => (link ? <Link to={link}>{label}</Link> : <div className={`action ${className}`} onClick={action}>{label}</div>))}
      </div>

      {component && (
      <Component
        records={records}
        filteredRecords={filteredRecords}
        extraProps={extraProps}
      />
      )}
    </div>
  )
}

QuickFilterPage.propTypes = {
  records: PropTypes.array,
  filteredRecords: PropTypes.array,
  filterQuery: PropTypes.string,
  updateFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  component: PropTypes.func,
  title: PropTypes.string,
  extraProps: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  actions: PropTypes.array,
}

export default QuickFilterPage
