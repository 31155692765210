import './MerchantAuthorizationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Authorizations/columnDescriptors'
import { MERCHANT_AUTHORIZATIONS } from 'constants/linkConstants'
import { AUTHORIZATIONS_ICON } from 'constants/iconConstants'
import { getMerchantAuthorizations } from 'state-layer/selectors'

import {
  AUTHORIZATION,
  GET_MERCHANT_AUTHORIZATIONS,
} from 'constants/flowConstants'

const MerchantAuthorizations = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  authorizationPath = '',
  isFetching = false,
  merchantId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='MerchantAuthorizations'>
      <TableC
        flow={GET_MERCHANT_AUTHORIZATIONS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getMerchantAuthorizations}
        selectorId={merchantId}
        initialQueries={initialQueries}
        linksKey={MERCHANT_AUTHORIZATIONS}
        allowedFilters={allowedFilters}
        path={authorizationPath}
        isFetching={isFetching}
        icon={`fa fa-${AUTHORIZATIONS_ICON}`}
        fileNameCSV={`Merchant_${merchantId}_Authorizations`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={AUTHORIZATION}
      />
    </div>
  )
}

MerchantAuthorizations.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  authorizationPath: PropTypes.string,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default MerchantAuthorizations
