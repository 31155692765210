import getCurrentCredentials from './getCurrentCredentials'
import get from 'lodash/get'
import { getState } from 'state-layer/configureStore'

const getCurrentCredentialsId = (state) => {
  const currentState = state || getState()
  const credentials = getCurrentCredentials(currentState)

  return get(credentials, 'id')
}

export default getCurrentCredentialsId
