import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import removeUndefined from 'utilities/remove/removeUndefined'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import capitalize from 'lodash/capitalize'

import {
  ACH,
  CARD,
} from 'constants/language/languageConstants'

const frontendTransferAttemptModel = ({ data: transferAttempt }) => {
  const [
    id,
    createdAt,
    updatedAt,
    amount,
    applicationId,
    buyerDetails,
    recipientType,
    recipientBusinessName,
    recipientDoingBusinessAs,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientPhone,
    recipientPersonalAddress,
    recipientBusinessAddress,
    recipientPersonalTaxIdProvided,
    recipientBusinessTaxIdProvided,
    currency,
    entityId,
    entityType,
    type,
    operationKey,
    merchantId,
    recipientMerchantId,
    paymentFrequency,
    paymentInstrumentId,
    status,
    transferId,
    failureCode,
    failureReason,
    tags,
  ] = getMany(transferAttempt, [
    'id',
    'created_at',
    'updated_at',
    'amount',
    'application_id',
    'buyer_details',
    'recipient_details.type',
    'recipient_details.business_name',
    'recipient_details.doing_business_as',
    'recipient_details.first_name',
    'recipient_details.last_name',
    'recipient_details.email',
    'recipient_details.phone_number',
    'recipient_details.personal_address',
    'recipient_details.business_address',
    'recipient_details.personal_tax_id_provided',
    'recipient_details.business_tax_id_provided',
    'currency',
    'entity_id',
    'entity_type',
    'type',
    'operation_key',
    'merchant_id',
    'recipient_merchant_id',
    'payment_frequency',
    'payment_instrument_id',
    'state',
    'transfer_id',
    'failure_code',
    'failure_reason',
    'tags',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    amount,
    displayAmount: formatMoney({ amount }),
    applicationId,
    buyerDetails,
    recipientType: capitalize(recipientType),
    recipientBusinessName,
    recipientDoingBusinessAs,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientFullName: recipientFirstName ? `${recipientFirstName} ${recipientLastName}` : undefined,
    recipientPhone,
    recipientPersonalAddress,
    recipientBusinessAddress,
    recipientPersonalTaxIdProvided,
    recipientBusinessTaxIdProvided,
    currency,
    entityId,
    entityType,
    type,
    displayType: convertSnakeToSentenceCase(type),
    operationKey,
    displayOperationKey: operationKey === 'PUSH_TO_ACH' ? ACH : CARD,
    merchantId,
    recipientMerchantId,
    paymentFrequency,
    paymentInstrumentId,
    status,
    transferId,
    failureCode,
    displayFailureCode: convertSnakeToSentenceCase(failureCode),
    failureReason,
    tags,
  })
}

export default frontendTransferAttemptModel
