import getExportsAPI from 'api/dashboard/get/getExportsAPI'
import getExportDownloadAPI from 'api/dashboard/get/getExportDownloadAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getMany from 'utilities/get/getMany'
import { COMPLETED } from 'constants/statusConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import map from 'lodash/map'
import co from 'co'

function * getExportDownloadData({ exportObj, credentials }) {
  const [
    id,
    state,
  ] = getMany(exportObj, [
    'id',
    'state',
  ])
  if (state !== COMPLETED) return exportObj

  const { data: exportDownloadResponse } = yield getExportDownloadAPI({ id, credentials })
  return merge({}, exportObj, { exportDownloadData: exportDownloadResponse })
}

function * getExportsO ({ credentials }) {
  const { data: response } = yield getExportsAPI({ credentials })
  const exports = get(response, '_embedded.exports')

  const exportsResponseWithDownloadData = yield map(exports, (exportObj) => getExportDownloadData({ exportObj, credentials }))

  return createSuccessResponse({
    data: exportsResponseWithDownloadData,
  })
}

export default co.wrap(getExportsO)
