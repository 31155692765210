import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import { getSelectedItemsByKeySelector, getSettlementSelector } from 'state-layer/selectors'
import MerchantSettlements from './MerchantSettlements'
import SettlementsConfirmationModal from 'components/Customer/Modals/SettlementsConfirmationModal/SettlementsConfirmationModal'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import showModalAction from 'utilities/actions/showModalAction'
import pluralizeWord from 'utilities/pluralizeWord'
import closeAccruingSettlementsRequest from 'utilities/actions/patch/closeSettlementsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { SETTLEMENTS_PATH } from 'constants/pathConstants'
import { MERCHANT_SETTLEMENTS } from 'constants/selectedItemsConstants'
import { CONFIRM_ACTION } from 'constants/modalConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import {
  PENDING,
} from 'constants/settlementConstants'

import {
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TOTAL_AMOUNT,
  TRANSFER_ID,
  ENTRY_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  TOTAL_AMOUNT(),
  TRANSFER_ID,
  ENTRY_ID,
], 'label')


const quickFilters = [
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const selectedItemsKey = MERCHANT_SETTLEMENTS

const initialFilters = {
  ...createdLastMonthQueries(),
}

const mapStateToProps = (state, props) => {
  const merchantId = get(props, 'merchantId')
  const isFetching = get(state, `merchantSettlementsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const statusQuery = get(parseUrlQueries(), 'status')
  const showBulkActions = hasPlatformAccess({ credentials }) && statusQuery === PENDING
  const settlementPath = SETTLEMENTS_PATH({ credentialId })
  const settlementIds = Object.keys(getSelectedItemsByKeySelector(state, selectedItemsKey))
  const selectedMerchantSettlements = map(settlementIds, (settlementId) => getSettlementSelector(state, settlementId))
  const flowValues = { merchantId }

  const initialQueries = {
    merchant_id: merchantId,
  }

  return {
    merchantId,
    flowValues,
    isFetching,
    settlementPath,
    initialFilters,
    initialQueries,
    allowedFilters,
    selectedItemsKey,
    settlementIds,
    selectedMerchantSettlements,
    credentials,
    showBulkActions,
    statusQuery,
    quickFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSettlementsConfirmationModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_ACTION, modalProps })),
    closeAccruingSettlements: ({ settlementIds, credentials }) => dispatch(closeAccruingSettlementsRequest({ settlementIds, credentials, dispatch, selectedItemsKey })),
  }
}

class MerchantSettlementsC extends Component {
  showCloseMerchantSettlementsConfirmationModal = () => {
    const {
      settlementIds,
      showSettlementsConfirmationModal,
      selectedMerchantSettlements,
      credentials,
      closeAccruingSettlements,
    } = this.props

    const settlementsTotal = selectedMerchantSettlements.length
    const pluralizedSettlement = pluralizeWord({ count: settlementsTotal, word: 'settlement' })

    const message = (
      <>
        <p>This action stops <strong>{settlementsTotal} {pluralizedSettlement}</strong> from accruing entries and changes {settlementsTotal > 1 ? 'their' : 'its'} status to <strong>Awaiting Approval</strong>. This action is irreversible.</p>
        <p>Do you want to proceed?</p>
      </>
    )

    const description = <SettlementsConfirmationModal settlements={selectedMerchantSettlements} />
    const confirmationAction = () => closeAccruingSettlements({ settlementIds, credentials })

    showSettlementsConfirmationModal({ message, description, confirmationAction })
  }

  render() {
    const { showBulkActions } = this.props

    const selectedItemsAction = showBulkActions ? [
      {
        label: 'Stop Accruing',
        action: this.showCloseMerchantSettlementsConfirmationModal,
      },
    ] : []

    const selectedItemsData = {
      actions: selectedItemsAction,
    }


    return (
      <MerchantSettlements
        {...this.props}
        selectedItemsData={selectedItemsData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSettlementsC)
