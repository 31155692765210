import React from 'react'
import PropTypes from 'prop-types'
import ResolveMonitoringAlertsFormC from 'components/Customer/Forms/ResolveMonitoringAlertsForm/ResolveMonitoringAlertsFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { TRANSACTION_MONITORING_RESOLVE_ONE_TITLE, TRANSACTION_MONITORING_RESOLVE_MULTIPLE_TITLE } from 'constants/language/languageConstants'
import size from 'lodash/size'

const ResolveMonitoringAlertsModal = ({
  items,
  credentials,
  selectedItemsKey,
}) => {
  return (
    <GenericModal
      title={size(items) === 1 ? TRANSACTION_MONITORING_RESOLVE_ONE_TITLE : TRANSACTION_MONITORING_RESOLVE_MULTIPLE_TITLE}
      className='ResolveMonitoringAlertsModal'
      Component={ResolveMonitoringAlertsFormC}
      items={items}
      credentials={credentials}
      selectedItemsKey={selectedItemsKey}
    />
  )
}

ResolveMonitoringAlertsModal.propTypes = {
  items: PropTypes.array,
  credentials: PropTypes.object,
  selectedItemsKey: PropTypes.string,
}

export default ResolveMonitoringAlertsModal
