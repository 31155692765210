import './DashboardTypeFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import RadioComponents from 'components/Shared/Inputs/RadioComponents/RadioComponents'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import { CUSTOMER_PROFILE_TYPE_FIELD_NAME } from 'constants/dashboardConfigurationsConstants'
import { DASHBOARD_TYPE_FORM } from 'constants/formConstants'

import {
  TELL_US_MORE_ABOUT_YOUR_BUSINESS,
  FINIX_SANDBOX_CREATION_EXPERIENCE_LABEL,
  SELECT_BUSINESS_TYPE_LABEL,
  GO_TO_THE_FINIX_DASHBOARD,
} from 'constants/language/languageConstants'

const DashboardTypeForm = ({
  handleSubmit = () => {},
  options = [],
  pristine = false,
}) => {
  return (
    <form className='DashboardTypeForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <div className='container flex column'>
        <h4>{TELL_US_MORE_ABOUT_YOUR_BUSINESS}</h4>
        <div className='experience-label p-1'>{FINIX_SANDBOX_CREATION_EXPERIENCE_LABEL}</div>
        <div className='p-1'>{SELECT_BUSINESS_TYPE_LABEL}</div>

        <Field
          name={CUSTOMER_PROFILE_TYPE_FIELD_NAME}
          component={RadioComponents}
          options={options}
        />

        <Button type='submit' className='go-to-dashboard-button' label={GO_TO_THE_FINIX_DASHBOARD} disabled={pristine} variant='legacy-black' />
      </div>
    </form>
  )
}

DashboardTypeForm.propTypes = {
  handleSubmit: PropTypes.func,
  options: PropTypes.array,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: DASHBOARD_TYPE_FORM,
})(DashboardTypeForm)
