import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import EditPaymentInstrumentForm from 'components/Customer/Forms/EditPaymentInstrumentForm/EditPaymentInstrumentForm'
import { EDIT_PAYMENT_INSTRUMENT_FORM } from 'constants/formConstants'
import { BANK_ACCOUNT } from 'constants/paymentInstrumentConstants'

class EditPaymentInstrumentFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      paymentInstrument = {},
    } = this.props

    const {
      id,
      enabled,
      type,
      maskedAccountNumber,
      maskedFullCardNumber,
      name,
    } = paymentInstrument

    const maskedPaymentInstrumentNumber = type === BANK_ACCOUNT ? maskedAccountNumber : maskedFullCardNumber

    initialize({
      id,
      enabled,
      maskedPaymentInstrumentNumber,
      name,
    })
  }

  render() {
    return (
      <EditPaymentInstrumentForm {...this.props} />
    )
  }
}

EditPaymentInstrumentFormC.propTypes = {
  initialize: PropTypes.func,
  record: PropTypes.object,
}

export default connect()(reduxForm({
  form: EDIT_PAYMENT_INSTRUMENT_FORM,
})(EditPaymentInstrumentFormC))
