import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import redirectRequest from 'utilities/actions/redirectRequest'
import { PATCH_DEVICE_F_REQUEST } from 'constants/flowConstants'
import { PAYMENT_DEVICE_PATH } from 'constants/pathConstants'
import { ACTIVATE_VALUE } from 'constants/deviceConstants'
import { TRIPOS_CLOUD_V1 } from 'constants/processorConstants'
import isEqual from 'lodash/isEqual'

import {
  ACTIVATE_DEVICE,
  DEACTIVATE_DEVICE,
} from 'constants/amplitudeConstants'

const submitActivateDeviceForm = (values, dispatch, props) => {
  const { activationCode } = values

  const {
    deviceId,
    actionType,
    credentials,
    credentialId,
    deviceName,
    isAddAndActivateDeviceFlow,
    gateway,
  } = props

  const valuesToSubmit = isEqual(gateway, TRIPOS_CLOUD_V1) ? removeUndefined({ action: actionType, activationCode }) : removeUndefined({ action: actionType })

  const amplitudeActionEvent = actionType === ACTIVATE_VALUE ? ACTIVATE_DEVICE : DEACTIVATE_DEVICE
  const successMessage = actionType === ACTIVATE_VALUE ? `${deviceName} has been successfully activated.` : `${deviceName} has been successfully deactivated.`
  const addAndActivateDeviceSuccessMessage = `${deviceName} has been successfully added and activated.`

  dispatch({
    type: PATCH_DEVICE_F_REQUEST,
    payload: {
      id: deviceId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(amplitudeActionEvent, {
        credentials,
        deviceName,
      }),
      successCallback: () => {
        if (isAddAndActivateDeviceFlow) {
          dispatch(hideModalAction())
          dispatch(redirectRequest({ path: PAYMENT_DEVICE_PATH({ credentialId, deviceId }) }))
        } else {
          dispatch(hideModalAction())
        }
      },
      successMessage: isAddAndActivateDeviceFlow ? addAndActivateDeviceSuccessMessage : successMessage,
    },
  })
}

export default submitActivateDeviceForm
