import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'

import {
  createdAtRange,
} from 'utilities/table/sort'

import {
  ID,
  CREATED_ON,
  ENTITY_ID,
} from 'constants/language/languageConstants'


const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(ENTITY_ID, 'entityId', {
    className: 'x-small',
    formatter: ({ entityId }) => <ClickToCopyC label='Entity ID' position='right' content={entityId} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column('Name', 'name', { className: 'medium' }),
  column('Level', 'displayLevel', { className: 'small' }),
  column('Processor', 'processor', { className: 'small' }),
]

export default columnDescriptors
