import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import getAuthorizationRequest from 'utilities/actions/get/getAuthorizationRequest'
import { PATCH_AUTHORIZATION_F_REQUEST, POST_RECEIPT_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_AUTHORIZATION } from 'constants/amplitudeConstants'
import { CAPTURE_AUTHORIZATION_SUCCESS_MSG } from 'constants/language/languageConstants'
import { EMAIL } from 'constants/receiptConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const submitCaptureAuthorizationForm = (values, dispatch, props) => {
  const {
    credentials,
    authorizationId,
    credentialId,
    allowCardPresentCapture,
    currentUser,
  } = props

  const {
    totalCaptureAmount,
    totalSubtractedCaptureAmount,
    captureCardPresentOption,
    captureAmountCardNotPresent,
    originalAmount,
    sendReceiptToBuyer,
    sendReceiptToSelf,
    buyerEmails,
  } = values

  const postReceiptAndHandleSuccess = ({ entityId }) => {
    const emails = []
    if (sendReceiptToBuyer && buyerEmails) {
      emails.push(...map(buyerEmails, ({ value }) => value))
    }
    const currentUserEmail = get(currentUser, 'userEmail')
    if (sendReceiptToSelf && currentUserEmail) {
      emails.push(currentUserEmail)
    }

    const sendMethods = []
    if (sendReceiptToBuyer || sendReceiptToSelf) {
      sendMethods.push(EMAIL)
    }

    dispatch({
      type: POST_RECEIPT_F_REQUEST,
      payload: {
        credentials,
        values: {
          ...removeUndefined({
            sendReceiptToBuyer,
            sendReceiptToSelf,
            sendMethods,
            emails,
          }),
          entityId,
        },
      },
      meta: {
        successCallback: () => {
          dispatch(hideModalAction())
          dispatch(getAuthorizationRequest({ credentials, authorizationId }))
        },
        successMessage: CAPTURE_AUTHORIZATION_SUCCESS_MSG,
      },
    })
  }

  const handleAuthorizationSuccess = ({ newValues }) => {
    const authorization = get(newValues, authorizationId)
    const entityId = get(authorization, 'transferId')
    postReceiptAndHandleSuccess({ entityId })
  }

  const dispatchAuthorizationUpdate = ({ captureAmountParam }) => {
    dispatch({
      type: PATCH_AUTHORIZATION_F_REQUEST,
      payload: {
        id: authorizationId,
        values: { captureAmount: captureAmountParam },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(UPDATE_AUTHORIZATION, {
          credentialId,
          captureAmount: captureAmountParam,
        }),
        successCallback: ({ newValues }) => handleAuthorizationSuccess({ newValues }),
      },
    })
  }

  if (!allowCardPresentCapture && captureAmountCardNotPresent) {
    dispatchAuthorizationUpdate({ captureAmountParam: captureAmountCardNotPresent })
  } else if (allowCardPresentCapture) {
    switch (captureCardPresentOption) {
      case 'captureOriginalAmount':
        dispatchAuthorizationUpdate({ captureAmountParam: originalAmount })
        break
      case 'amountToAdd':
        dispatchAuthorizationUpdate({ captureAmountParam: totalCaptureAmount })
        break
      case 'lessThanCaptureAmount':
        dispatchAuthorizationUpdate({ captureAmountParam: totalSubtractedCaptureAmount })
        break
      default:
        break
    }
  }
}

export default submitCaptureAuthorizationForm
