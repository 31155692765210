import MerchantFeeRiskProfile from './MerchantFeeRiskProfile'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMerchantSelector } from 'state-layer/selectors'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import formatRiskProfile from 'utilities/formatters/formatRiskProfile'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { ADMIN_PLATFORM_ROLES } from 'constants/roleConstants'
import { EDIT_RISK_PROFILE } from 'constants/modalConstants'
import { EDIT } from 'constants/language/languageConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { USA } from 'constants/countryConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const merchantId = get(props, 'merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const editMerchant = includes(ADMIN_PLATFORM_ROLES, get(credentials, 'role'))
  const isAdminRole = isAdministratorRole(credentials)

  const [
    merchantProfileId,
    merchantApplicationId,
    merchantProfileTags,
    riskProfile,
    merchantIdentityId,
    country = USA,
  ] = getMany(merchant, [
    'merchantProfileId',
    'applicationId',
    'merchantProfile.tags',
    'riskProfile',
    'identityId',
    'country',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[country]

  const riskProfileSectionData = formatRiskProfile(riskProfile)
  const riskProfileId = get(riskProfile, 'id')

  const riskProfileClickToCopies = [
    {
      content: riskProfileId,
    },
  ]

  return {
    merchantId,
    merchant,
    riskProfileSectionData,
    riskProfile,
    credentials,
    editMerchant,
    merchantProfileId,
    merchantApplicationId,
    merchantProfileTags,
    riskProfileClickToCopies,
    isAdminRole,
    merchantIdentityId,
    currency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showRiskProfileModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_RISK_PROFILE, modalProps })),
  }
}

class MerchantFeeRiskProfileC extends Component {
  render() {
    const {
      showRiskProfileModal,
      riskProfile,
      merchantId,
      credentials,
      isAdminRole,
    } = this.props

    const riskProfileActions = [
      {
        label: EDIT,
        action: () => showRiskProfileModal({
          merchantId,
          riskProfile,
          credentials,
        }),
        condition: hasPlatformAccess({ credentials }) && isAdminRole,
      },
    ]

    return (
      <MerchantFeeRiskProfile
        {...this.props}
        riskProfileActions={riskProfileActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantFeeRiskProfileC)
