import {
  DUMMY_V1,
  VANTIV_V1,
  LITLE_V1,
} from 'constants/processorConstants'

function registrationLink(route, text) {
  return { text, route }
}

function field(type, label, value) {
  return { type, label, value }
}

function pair(label, value) {
  if (value === undefined) {
    return { label, disabled: true }
  }
  return { label, value }
}

const BULK_ACTIONS = [
  pair('Bulk Actions'),
  pair('Assign to me', 'ASSIGN-SELF'),
  pair('Reject (MATCH_LIST)', 'REJECTED-MATCH_LIST'),
  pair('Reject (HIGH_RISK)', 'REJECTED-HIGH_RISK'),
  pair('Reject (INSUFFICIENT_HISTORY)', 'REJECTED-INSUFFICIENT_HISTORY'),
  pair('Reject (FRAUD)', 'REJECTED-FRAUD'),
  pair('Reject (PROHIBITED)', 'REJECTED-PROHIBITED'),
  pair('Reject (MULTIPLE_FACTORS)', 'REJECTED-MULTIPLE_FACTORS'),
  pair('Reject (LOW_FICO_SCORE)', 'REJECTED-LOW_FICO_SCORE'),
  pair('Reject (POOR_PROCESSING_HISTORY)', 'REJECTED-POOR_PROCESSING_HISTORY'),
  pair('Reject (VIOLATES_CHARGEBACK_THRESHOLD)', 'REJECTED-VIOLATES_CHARGEBACK_THRESHOLD'),
  pair('Reject (VIOLATES_RETURNS_THRESHOLD)', 'REJECTED-VIOLATES_RETURNS_THRESHOLD'),
  pair('Reject (VIOLATES_UNDERWRITING_REQUIREMENTS)', 'REJECTED-VIOLATES_UNDERWRITING_REQUIREMENTS'),
  pair('Approve', 'ACCEPTED'),
]

const ADDITIONAL_FORM_SECTIONS = [{
  title: 'Additional Information',
  fields: [
    field('text', 'Merchandise/services sold', 'tags.Merchandise/services sold'),
    field('currency', 'Average Transaction', 'tags.Average Transaction'),
    field('currency', 'High Transaction', 'tags.High Transaction'),
    field('text', 'Return Policy', 'tags.Return Policy'),
    field('text', 'Warranties', 'tags.Warranties'),
    field('percentage', 'Instore (CP) %', 'tags.Instore (CP) %'),
    field('percentage', 'MOTO (key entered - CNP) %', 'tags.MOTO (key entered - CNP) %'),
    field('percentage', 'Internet %', 'tags.Internet %'),
    field('percentage', 'Visa/MC/Discovery volume relative to prepaid sales', 'tags.Visa/MC/Discovery volume relative to prepaid sales'),
    field('percentage', '% Percent Required for Pre-Payment or Down Payment', 'tags.% Percent Required for Pre-Payment or Down Payment'),
    field('percentage', 'American Express volume related to pre-paid Sales', 'tags.American Express volume related to pre-paid Sales'),
  ],
}]

export const DATA = {
  name: 'paymentbrands',
  id: 'DASHacfJdF1ZwPC2hcDzfXCkis',
  applicationName: 'paymentbrands',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/paymentbrands/2019-09-05-06_23_28-PB%20Edited.png',
  credentialsHost: 'https://paymentbrands.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  paymentsApiHosts: 'https://*.live-payments-api.com',
  auth0: {
    clientID: 'RsL7LbTl0ekaQLt6OKi4YQqU5Y9OIL8N',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'VANTIV_V1', 'LITLE_V1'],
  registrationSteps: [
    registrationLink('account/registrations/edit/index', 'Identity Information'),
    registrationLink('account/registrations/edit/bank-accounts', 'Bank Account'),
    registrationLink('account/registrations/edit/associated-identities', 'Beneficial Owners'),
    registrationLink('account/registrations/edit/processors', 'Processors'),
    registrationLink('account/registrations/edit/verifications', 'Credit Report'),
    registrationLink('account/registrations/edit/api-keys', 'API Keys'),
  ],
  identityFormTagFields: [],

  identityQueueRejectionReasons: [
    'MATCH_LIST',
    'HIGH_RISK',
    'INSUFFICIENT_HISTORY',
    'FRAUD',
    'PROHIBITED',
    'MULTIPLE_FACTORS',
    'LOW_FICO_SCORE',
    'POOR_PROCESSING_HISTORY',
    'VIOLATES_CHARGEBACK_THRESHOLD',
    'VIOLATES_RETURNS_THRESHOLD',
    'VIOLATES_UNDERWRITING_REQUIREMENTS',
  ],

  'applications/index/newApplicationLinkName': 'registrations/new',
  'applications/new/additionalFormSections': ADDITIONAL_FORM_SECTIONS,
  'applications/show/transfers/isDisplayTraceId': true,
  'identities/index/showIdColumn': true,
  'identities/new/additionalFormSections': ADDITIONAL_FORM_SECTIONS,
  'identities/show/transfers/isDisplayTraceId': true,
  'settings/isShowProcessorInformation': true,
  'transfers/index/isDisplayTraceId': true,
  'merchants/index/isShowExpanded': true,
  'onboarding/identities/bulkActions': BULK_ACTIONS,
  'onboarding/merchants/bulkActions': BULK_ACTIONS,
  'settlements/show/funding-transfers/isDisplayTraceId': true,

  strings: {
    'label for business name': 'Legal Business Name',
    'Trace ID': 'Order ID',
    'Associated Identities': 'Beneficial Owners',
    'Associated Identity': 'Beneficial Owner',
    'New Associated Identity': 'New Beneficial Owner',
    'No associated identities available': 'No beneficial owners available',
    'Associated identity created': 'Beneficial owner created',
    Transfers: 'Transactions',
  },

  dashboardServiceV2: true, // using this to control dashboard service version
}
