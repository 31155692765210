import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const downloadBlobFromAPI = ({ api, fileName, method = 'GET' }) => {
  const { host, authToken } = getCurrentCredentials()
  const headers = new Headers()
  headers.append('Authorization', `Basic ${authToken}`)
  const apiEndpoint = `${host}/${api}`

  fetch(apiEndpoint, {
    method,
    headers,
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    })
}

export default downloadBlobFromAPI
