import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import SubscriptionPlanStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SubscriptionPlanStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'

import {
  AMOUNT,
  ID,
  CREATED_ON,
  DESCRIPTION,
  MERCHANT_ACCOUNT,
  PLAN_NAME,
  STATUS,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', { className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'merchant.name', { className: 'medium' }),
  column(PLAN_NAME, 'planName', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={PLAN_NAME} subtitle={DESCRIPTION} />,
    formatter: ({ planName, description }) => (
      <TableItemSubtitle
        title={planName}
        subtitle={description}
      />
    ),
  }),
  // TO DO: Add Subscriptions count one BE adds it in
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATUS, 'state', {
    className: 'state',
    formatter: ({ state }) => <SubscriptionPlanStatus value={state} />,
  }),
]
export default columnDescriptors
