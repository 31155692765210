import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityProvisionMerchant from './IdentityProvisionMerchant'
import { FETCHING } from 'constants/reducerConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import flatten from 'lodash/flatten'

import {
  getIdentitySelector,
  getIdentityPaymentInstrumentsSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'params.identityId') || get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const isFetching = get(state, `identitiesR.${FETCHING}`)

  const [
    processors,
    merchants,
    paymentInstruments,
  ] = getMany(identity, [
    'application.processors',
    'merchants',
    'paymentInstruments',
  ])

  const enabledProcessors = map(merchants, ({ processor }) => processor)
  const identityPaymentInstruments = !isEmpty(paymentInstruments) ? paymentInstruments : getIdentityPaymentInstrumentsSelector(state, identityId)

  const processorsWithGateways = flatten(map(processors, ({ processor, gateways }) => {
    return gateways ? map(gateways, (gateway) => ({ processor, gateway })) : { processor }
  }))

  return removeUndefined({
    identity,
    identityId,
    credentials,
    processors: processorsWithGateways,
    enabledProcessors,
    paymentInstruments: identityPaymentInstruments,
    isFetching,
  })
}

const mapDispatchToProps = {
  getIdentity: getIdentityRequest,
}

class IdentityProvisionMerchantC extends Component {
  componentDidMount() {
    const {
      identity,
      credentials,
      identityId,
      getIdentity,
      processors,
    } = this.props

    if (!identity || !processors) {
      getIdentity({ identityId, credentials })
    }
  }

  render() {
    return (
      <IdentityProvisionMerchant
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityProvisionMerchantC)
