import './InviteUserFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { INVITE_USER_FORM } from 'constants/formConstants'
import validateUserEmailForm from 'utilities/validate/validateUserEmailForm'
import submitInviteUserForm from 'utilities/submit/submitInviteUserForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'

import {
  EMAIL_ADDRESS,
  IDENTITY_PROVISION,
  INVITE,
  CANCEL,
} from 'constants/language/languageConstants'

const InviteUserForm = ({
  id,
  handleSubmit = () => {},
  disableAction = false,
  closeModal = () => {},
}) => {
  return (
    <form className='InviteUserForm' onSubmit={handleSubmit(submitInviteUserForm)}>
      <div className='section'>
        { disableAction && <WarningMessage warningMessage={IDENTITY_PROVISION} /> }

        <div>
          <p>Grant Dashboard Access for</p>
          <h2>{id}</h2>
        </div>

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          component={InputField}
        />

        <Field
          name='name'
          label='Platform Name'
          component={InputField}
          helperText={'This name will appear on the invited user\'s list of platforms.'}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} className='close-modal-button' label={CANCEL} variant='ghost' />
        <Button type='submit' disabled={disableAction} label={INVITE} />
      </div>
    </form>
  )
}

InviteUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  disableAction: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: INVITE_USER_FORM,
  validate: validateUserEmailForm,
})(InviteUserForm)
