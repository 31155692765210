import React, { Component } from 'react'
import { connect } from 'react-redux'
import RebootDevice from './RebootDevice'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchDeviceRequest from 'utilities/actions/patch/patchDeviceRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import isPatching from 'utilities/is/isPatching'
import { REBOOT_DEVICE } from 'constants/amplitudeConstants'
import { REBOOT_VALUE } from 'constants/deviceConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    deviceId,
    deviceName,
  ] = getMany(props, [
    'deviceId',
    'deviceName',
  ])

  return {
    credentials,
    deviceId,
    deviceName,
    isRebooting: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    rebootDeviceAction: ({ credentials, deviceId, deviceName }) => dispatch(patchDeviceRequest({
      credentials,
      deviceId,
      values: {
        actionType: REBOOT_VALUE,
      },
      amplitudeActionType: REBOOT_DEVICE,
      successMessage: `${deviceName} has been successfully rebooted.`,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class RebootDeviceC extends Component {
  rebootDevice = () => {
    const {
      credentials,
      deviceId,
      deviceName,
      rebootDeviceAction,
      closeModal,
    } = this.props

    rebootDeviceAction({ credentials, deviceId, deviceName })
    closeModal()
  }

  render() {
    return (
      <RebootDevice
        {...this.props}
        rebootDevice={this.rebootDevice}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebootDeviceC)
