import './AmountsDisplaySectionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import formatMoney from 'utilities/formatters/formatMoney'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import classnames from 'classnames'

const AmountsDisplaySection = ({
  data = [],
}) => {
  const hasMultipleAmounts = size(data) > 1
  const amountItemClassNames = classnames('amountItemContainer', {
    hasMultipleAmounts,
  })
  return (
    <ul className='AmountsDisplaySection' id='AmountsDisplaySection'>
      { isEmpty(data) ? '-' : map(data, ({ id, amountNickname, amount, currency }) => {
        return (
          <li className={amountItemClassNames} title={amountNickname} id='amountItem' key={id}>
            <span className='amountItem'><strong className='amountItemName'>{amountNickname}:</strong><span className='amountItemValue'> {formatMoney({ amount, currency, showCurrencyCode: true })}</span></span>
          </li>
        )
      }) }
    </ul>
  )
}

AmountsDisplaySection.propTypes = {
  data: PropTypes.array,
}

export default AmountsDisplaySection
