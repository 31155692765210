import { createAction as reduxCreateAction } from 'redux-actions'
import removeUndefined from 'utilities/remove/removeUndefined'

const createAction = ({
  id,
  type = '',
  values,
  queries,
  credentials,
  meta,
  linksKey,
}) => removeUndefined(
  reduxCreateAction(
    type,
    () => ({
      id,
      values,
      queries,
      credentials,
      linksKey,
    }),
    () => meta,
  )(),
)

export default createAction
