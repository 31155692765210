import React from 'react'
import column from 'utilities/table/column'

import {
  MCC,
  DESCRIPTION,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(MCC, 'mcc', {
    className: 'medium',
  }),
  column(DESCRIPTION, 'description', {
    className: 'grow',
  }),
]

export default columnDescriptors
