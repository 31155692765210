import './CompanyBusinessInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import VelocityLimitsC from 'components/Customer/Pages/Velocity/VelocityLimits/VelocityLimitsC'
import RiskProfileToolTipMessage from 'components/Customer/Pages/RiskProfileToolTipMessage/RiskProfileToolTipMessage'
import UnderwritingProfileC from 'components/Customer/Pages/UnderwritingProfile/UnderwritingProfileC'
import { velocityLimitsColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import { infoTableTheme } from 'constants/themeConstants'

import {
  DETAILS,
  RISK_PROFILE,
  RISK_PROFILE_SECTION_EMPTY_MESSAGE,
  APPLICATION_CARD_VELOCITY_LIMITS,
  APPLICATION_ACH_VELOCITY_LIMITS,
  RECIPIENT_CARD_VELOCITY_LIMITS,
  RECIPIENT_ACH_VELOCITY_LIMITS,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const CompanyBusinessInfo = ({
  businessInfoSectionData = [],
  isFetching = false,
  riskProfileSectionData = [],
  isRolePartnerCredential,
  isPayoutCredential,
  platformId = '',
  applicationId = '',
  isFlex = false,
  isRoleMerchantCredential,
  isStandaloneUnderwriting = false,
  isDisbursementsDashboard = false,
  applicationACHRules = {},
  applicationCardRules = {},
  recipientACHRules = {},
  recipientCardRules = {},
  noDisbursementRulesSet = true,
  setApplicationCardVelocityLimitActions = [],
  setApplicationACHVelocityLimitActions = [],
  setRecipientCardVelocityLimitActions = [],
  setRecipientACHVelocityLimitActions = [],
  applicationACHRulesMaxTransactionAmount,
  applicationCardRulesMaxTransactionAmount,
  recipientACHRulesMaxTransactionAmount,
  recipientCardRulesMaxTransactionAmount,
}) => {
  const showRiskProfilePageSection = isRolePartnerCredential && !isPayoutCredential && !isDisbursementsDashboard
  const showVelocityLimits = isFlex && !isStandaloneUnderwriting && isDisbursementsDashboard
  return (
    <div className='CompanyBusinessInfo'>
      <PageSectionV2C
        className='CompanyBusinessInfoDetails'
        isFetching={isFetching}
        title={DETAILS}
        columns={businessInfoSectionData}
      />

      {showVelocityLimits && (
        <>

          {noDisbursementRulesSet && <VelocityLimitsC /> }

          {isFlex && !noDisbursementRulesSet && isDisbursementsDashboard && (
            <>
              <PageSectionV2C
                title={APPLICATION_CARD_VELOCITY_LIMITS}
                subTitle={applicationCardRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${applicationCardRulesMaxTransactionAmount}` : undefined}
                actions={setApplicationCardVelocityLimitActions}
              >
                <TableC
                  showTableFooter={false}
                  theme={infoTableTheme}
                  columnDescriptors={velocityLimitsColumnDescriptors}
                  data={applicationCardRules}
                />
              </PageSectionV2C>

              <PageSectionV2C
                title={APPLICATION_ACH_VELOCITY_LIMITS}
                subTitle={applicationACHRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${applicationACHRulesMaxTransactionAmount}` : undefined}
                actions={setApplicationACHVelocityLimitActions}
              >
                <TableC
                  showTableFooter={false}
                  theme={infoTableTheme}
                  columnDescriptors={velocityLimitsColumnDescriptors}
                  data={applicationACHRules}
                />
              </PageSectionV2C>

              <PageSectionV2C
                title={RECIPIENT_CARD_VELOCITY_LIMITS}
                subTitle={recipientCardRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${recipientCardRulesMaxTransactionAmount}` : undefined}
                actions={setRecipientCardVelocityLimitActions}
              >
                <TableC
                  showTableFooter={false}
                  theme={infoTableTheme}
                  columnDescriptors={velocityLimitsColumnDescriptors}
                  data={recipientCardRules}
                />
              </PageSectionV2C>

              <PageSectionV2C
                title={RECIPIENT_ACH_VELOCITY_LIMITS}
                subTitle={recipientACHRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${recipientACHRulesMaxTransactionAmount}` : undefined}
                actions={setRecipientACHVelocityLimitActions}
              >
                <TableC
                  showTableFooter={false}
                  theme={infoTableTheme}
                  columnDescriptors={velocityLimitsColumnDescriptors}
                  data={recipientACHRules}
                />
              </PageSectionV2C>
            </>
          )}
        </>
      )}

      {(isFlex && !isRoleMerchantCredential) && (
        <UnderwritingProfileC
          applicationId={applicationId}
          platformId={platformId}
          isDisbursementsApplication={isDisbursementsDashboard}
        />
      )}

      {showRiskProfilePageSection && (
        <PageSectionV2C
          className='CompanyRiskProfile'
          isFetching={isFetching}
          title={RISK_PROFILE}
          columns={riskProfileSectionData}
          emptyMessage={RISK_PROFILE_SECTION_EMPTY_MESSAGE}
          tooltip
          tooltipMessage={<RiskProfileToolTipMessage />}
          tooltipPosition='right'
        />
      )}
    </div>
  )
}

CompanyBusinessInfo.propTypes = {
  businessInfoSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  riskProfileSectionData: PropTypes.array,
  isRolePartnerCredential: PropTypes.bool,
  isPayoutCredential: PropTypes.bool,
  isStandaloneUnderwriting: PropTypes.bool,
  platformId: PropTypes.string,
  applicationId: PropTypes.string,
  isFlex: PropTypes.bool,
  isRoleMerchantCredential: PropTypes.bool,
  isDisbursementsDashboard: PropTypes.bool,
  applicationACHRules: PropTypes.object,
  applicationCardRules: PropTypes.object,
  recipientACHRules: PropTypes.object,
  recipientCardRules: PropTypes.object,
  noDisbursementRulesSet: PropTypes.bool,
  setApplicationCardVelocityLimitActions: PropTypes.array,
  setApplicationACHVelocityLimitActions: PropTypes.array,
  setRecipientCardVelocityLimitActions: PropTypes.array,
  setRecipientACHVelocityLimitActions: PropTypes.array,
  applicationACHRulesMaxTransactionAmount: PropTypes.string,
  applicationCardRulesMaxTransactionAmount: PropTypes.string,
  recipientACHRulesMaxTransactionAmount: PropTypes.string,
  recipientCardRulesMaxTransactionAmount: PropTypes.string,
}

export default CompanyBusinessInfo
