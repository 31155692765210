import paymentsAPI from 'utilities/api/paymentsAPI'
import omit from 'lodash/omit'

import {
  SUBSCRIPTION_SCHEDULE_AMOUNT,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const patchSubscriptionScheduleAmountAPI = ({ id, values, queries, credentials, meta }) => {
  const { subscription_schedule_id: subscriptionScheduleId } = values
  const formattedValues = omit(values, 'subscriptionScheduleId')

  return paymentsAPI.patch({
    meta,
    credentials,
    values: formattedValues,
    path: SUBSCRIPTION_SCHEDULE_AMOUNT({ subscriptionScheduleId, subscriptionAmountId: id }),
    queries,
    service: SUBSCRIPTION,
  })
}

export default patchSubscriptionScheduleAmountAPI
