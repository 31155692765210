import { ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX } from 'constants/regexConstants'
import get from 'lodash/get'
import size from 'lodash/size'
import trim from 'lodash/trim'
import isEqual from 'lodash/isEqual'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  nonEmptyNumber,
} from 'utilities/validate'

import {
  DEVICE_NAME,
  SERIAL_NUMBER,
  MODEL,
  SIGNATURE_THRESHOLD_AMOUNT,
} from 'constants/language/languageConstants'

import {
  AMOUNT_VALUE,
  ON_THRESHOLD_AMOUNT_VALUE,
} from 'constants/deviceConstants'

const validateAddDeviceForm = (values) => {
  const {
    deviceName,
    serialNumber,
    model,
    promptSignature,
    promptForSignature,
    signatureThresholdAmount,
    percentageOne,
    percentageTwo,
    percentageThree,
    amountOne,
    amountTwo,
    amountThree,
    promptReceiptConfirmation,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
  } = values

  const isThresholdAmountValue = isEqual(get(promptSignature, 'value'), AMOUNT_VALUE) || isEqual(get(promptForSignature, 'value'), ON_THRESHOLD_AMOUNT_VALUE)

  return {
    deviceName: fieldEmpty(deviceName, DEVICE_NAME),
    serialNumber: invalidOrEmptyRegex({ field: serialNumber, name: SERIAL_NUMBER, regex: ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX, customErrorMessage: `${SERIAL_NUMBER} cannot contain special characters.` })
      || (size(trim(serialNumber)) > 30 && `${SERIAL_NUMBER} must be 30 digits long.`),
    model: fieldEmpty(get(model, 'value'), MODEL),
    signatureThresholdAmount: isThresholdAmountValue ? fieldEmpty(signatureThresholdAmount, SIGNATURE_THRESHOLD_AMOUNT) : false,
    percentageOne: nonEmptyNumber({ field: percentageOne, name: 'Percentage' }),
    percentageTwo: nonEmptyNumber({ field: percentageTwo, name: 'Percentage' }),
    percentageThree: nonEmptyNumber({ field: percentageThree, name: 'Percentage' }),
    amountTwo: !fieldEmpty(amountOne) ? nonEmptyNumber({ field: amountTwo, name: 'Amount' }) : false,
    amountThree: !fieldEmpty(amountOne) ? nonEmptyNumber({ field: amountThree, name: 'Amount' }) : false,
    receiptSettingsError: promptReceiptConfirmation && !receiptDeliveryPrint && !receiptDeliveryEmail && !receiptDeliverySms && !automaticDeliveryPrint && !automaticDeliveryEmail && !automaticDeliverySms ? true : false,
  }
}

export default validateAddDeviceForm
