import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { DETAILS } from 'constants/language/languageConstants'

const BuyerInfo = ({
  detailsSectionData = [],
  isFetching = false,
  actions = [],
}) => {
  return (
    <div className='BuyerInfo'>
      <PageSectionV2C
        title={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetching}
        actions={actions}
      />
    </div>
  )
}

BuyerInfo.propTypes = {
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
}

export default BuyerInfo
