import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitArchivePlanForm from 'utilities/submit/submitArchivePlanForm'
import { ARCHIVE_SUBSCRIPTION_PLAN_FORM } from 'constants/formConstants'

import {
  CANCEL,
  ARCHIVE_PLAN,
  ARCHIVE_STANDARD_PLAN_CONFIRMATION_MSG,
} from 'constants/language/languageConstants'

const ArchiveSubscriptionPlanForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  invalid,
  isUpdating = false,
}) => {
  return (
    <form className='ArchiveSubscriptionPlanForm' onSubmit={handleSubmit(submitArchivePlanForm)}>
      <div className='modal-content'>
        <div className='confirmation-msg'>{ARCHIVE_STANDARD_PLAN_CONFIRMATION_MSG}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} submitting={isUpdating} variant='destructive' label={ARCHIVE_PLAN} className='submit-button' />
      </div>
    </form>
  )
}

ArchiveSubscriptionPlanForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default reduxForm({
  form: ARCHIVE_SUBSCRIPTION_PLAN_FORM,
})(ArchiveSubscriptionPlanForm)
