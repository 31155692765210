import co from 'co'
import postOnboardingFormPlaidTokenAPI from 'api/guestDashboard/post/postOnboardingFormPlaidTokenAPI'
import postGuestAccessFormPlaidTokenAPI from 'api/guestDashboard/post/postGuestAccessFormPlaidTokenAPI'
import postGenericPlaidTokenAPI from 'api/finix/post/postGenericPlaidTokenAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import { POST_PLAID_TOKEN_F_REQUEST } from 'constants/flowConstants'

function * postPlaidTokenO ({
  id,
  values,
  credentials,
  meta,
}) {
  const [
    type,
    countries,
    language,
    actionType,
    entityType,
    accountId,
    plaidPublicToken,
  ] = getMany(values, [
    'type',
    'countries',
    'language',
    'actionType',
    'entityType',
    'accountId',
    'plaidPublicToken',
  ])

  // send proper values depending on if we are getting the public token or the modal token
  const valuesToPost = actionType === POST_PLAID_TOKEN_F_REQUEST ? {
    type,
    countries,
    language,
  } : {
    plaid_account_id: accountId,
    plaid_public_token: plaidPublicToken,
    type,
  }

  let tokenResponse = {}

  if (entityType === 'ONBOARDING_FORM') {
    tokenResponse = yield postOnboardingFormPlaidTokenAPI({
      id,
      values: valuesToPost,
      meta,
    })
  } else if (entityType === 'ACCESS_FORM') {
    tokenResponse = yield postGuestAccessFormPlaidTokenAPI({
      id,
      values: valuesToPost,
      meta,
    })
  } else {
    tokenResponse = yield postGenericPlaidTokenAPI({
      credentials,
      values: valuesToPost,
      meta,
    })
  }

  return createSuccessResponse({
    data: get(tokenResponse, 'data', {}),
  })
}

export default co.wrap(postPlaidTokenO)
