import map from 'lodash/map'
import filter from 'lodash/filter'
import size from 'lodash/size'
import first from 'lodash/first'
import get from 'lodash/get'

import {
  PATCH_MONITORING_ALERTS_F_REQUEST,
  PATCH_MONITORING_ALERT_F_REQUEST,
  UNSELECT_ITEMS,
} from 'constants/flowConstants'

const submitResolveMonitoringAlertsForm = (values, dispatch, props) => {
  const {
    items,
    selectedItemsKey,
    credentials,
  } = props

  if (selectedItemsKey) {
    dispatch({
      type: PATCH_MONITORING_ALERTS_F_REQUEST,
      payload: {
        values: {
          items,
        },
        credentials,
      },
      meta: {
        successCallback: ({ newValues }) => {
          const successfulIds = filter(map(newValues, 'id'), (id) => id !== undefined)
          if (size(successfulIds) > 0) {
            dispatch({
              type: UNSELECT_ITEMS,
              payload: {
                key: selectedItemsKey,
                ids: successfulIds,
              },
            })
          }
        },
      },
    })
  } else if (!selectedItemsKey && size(items) === 1) {
    const item = first(items)

    dispatch({
      type: PATCH_MONITORING_ALERT_F_REQUEST,
      payload: {
        id: get(item, 'id'),
        values: {
          ...item,
        },
        credentials,
      },
    })
  }
}

export default submitResolveMonitoringAlertsForm
