import frontendMembershipModel from 'utilities/create/models/frontendMembershipModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendMembershipsModel = ({ data: memberships }) => {
  const membershipModels = map(memberships, (membership) => frontendMembershipModel({ data: membership }))

  return keyBy(membershipModels, 'id')
}

export default frontendMembershipsModel
