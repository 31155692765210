import { INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE } from 'constants/identityConstants'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import trim from 'lodash/trim'

import {
  EMAIL_REGEX,
  FULL_WEBSITE_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  EIN_VERIFICATION_DOCUMENT_UPLOAD_ERROR,
  PHONE_NUMBER_LENGTH_VALIDATION_ERROR,
  WEBSITE_LENGTH_VALIDATION_ERROR,
} from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidRegex,
  validateDate,
  validateAddress,
  validateBusinessName,
  validateDBA,
  validateBusinessDescription,
  validateMerchantCount,
  invalidOrEmptyRegex,
  validateMaskedTaxId,
} from 'utilities/validate'

const validateLiveAccountApplicationBusinessForm = (values, props) => {
  const {
    accessFormCountry,
    isCanadianSoleProp,
    einVerificationFileName,
  } = props

  const {
    businessData = {},
  } = values

  const {
    businessName,
    doingBusinessAs,
    businessDescription,
    businessType,
    businessOwnershipType,
    businessIncorporationDate,
    businessTaxId,
    businessMerchantCount,
    businessWebsite,
    businessWebsiteDomains,
    businessEmail,
    businessPhone,
    businessAddress,
  } = businessData

  return {
    businessData: {
      businessName: validateBusinessName({ field: businessName }),
      doingBusinessAs: validateDBA({ field: doingBusinessAs }),
      businessDescription: validateBusinessDescription({ field: businessDescription }),
      businessType: fieldEmpty(businessType, 'Business Type'),
      businessOwnershipType: fieldEmpty(businessOwnershipType, 'Ownership Type'),
      businessPhone: fieldEmpty(businessPhone, 'Phone Number') || invalidRegex({ field: businessPhone, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
      businessIncorporationDate: validateDate({ date: businessIncorporationDate, noFutureDate: true }),
      businessWebsite: fieldEmpty(businessWebsite, 'Website') || invalidRegex({ field: businessWebsite, name: 'Website', regex: FULL_WEBSITE_REGEX }) || (size(trim(businessWebsite)) > 120 && WEBSITE_LENGTH_VALIDATION_ERROR),
      businessTaxId: validateMaskedTaxId({ taxId: businessTaxId, name: 'Business Tax ID', country: accessFormCountry, canBeEmpty: isCanadianSoleProp, businessType }),
      businessMerchantCount: businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE ? validateMerchantCount({ field: businessMerchantCount }) : null,
      businessEmail: invalidOrEmptyRegex({ field: businessEmail, name: 'Email Address', regex: EMAIL_REGEX }),
      businessWebsiteDomains: fieldEmpty(businessWebsiteDomains, 'Subdomains'),
      businessAddress: validateAddress({ address: businessAddress }),
    },
    einVerificationFile: isEmpty(einVerificationFileName) && (businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE) && EIN_VERIFICATION_DOCUMENT_UPLOAD_ERROR,
  }
}

export default validateLiveAccountApplicationBusinessForm
