// audit log event types
export const IDENTITY_CREATED = 'IDENTITY_CREATED'
export const IDENTITY_UPDATED = 'IDENTITY_UPDATED'
export const INSTRUMENT_CREATED = 'INSTRUMENT_CREATED'

export const PAYMENT_INSTRUMENT_CREATED_LABEL = 'Payment Instrument Created'
export const INSTRUMENT_CREATED_LABEL = 'Instrument Created'
export const PAYMENT_INSTRUMENT_LABEL = 'Payment Instrument'
export const MERCHANT_IDENTITY_LABEL = 'Merchant Identity'
export const PAYMENT_INSTRUMENT_ENABLED = 'Payment Instrument Enabled'
export const PAYMENT_INSTRUMENT_DISABLED = 'Payment Instrument Disabled'
