import { fieldEmpty } from 'utilities/validate'

const validateComplianceSelfAssessmentQuestionnaireAForm = ({
  vendorSuppliedDefaultsChangedBeforeInstallOnNetwork,
  unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork,
  systemAndSoftwareProtectedFromKnownVulnerabilities,
  criticalSecurityPatchesInstalledWithinOneMonthOfRelease,
  usersAssignedUniqueIdBeforeAccessToSystem,
  accessForTerminatedUsersRemoved,
  authenticateUsersUsingPasswordOrTokenOrBiometric,
  userPasswordParametersConfiguredToMeetReqs,
  groupSharedAccountsPasswordsAuthenticationMethodsProhibited,
  allMediaPhysicallySecured,
  strictControlOverDistributionOfMedia,
  mediaClassifiedToDetermineSensitivityOfData,
  mediaSentCanBeAccuratelyTracked,
  managementApprovalObtainedToMoveMedia,
  strictControlMaintainedOverStorageAndAccessibilityOfMedia,
  mediaDestroyedWhenNoLongerNeeded,
  hardCopyMaterialsCrosscutShreddedIncineratedOrPulped,
  storageContainersForMaterialsDestroyedToPreventAccessToContents,
  listOfServiceProvidersMaintained,
  writtenAgreementMaintained,
  establishedProcessForEngagingServicesProviders,
  programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually,
  informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity,
  incidentResponsePlanBeenCreatedInCaseOfSystemBreach,
}) => {
  return {
    vendorSuppliedDefaultsChangedBeforeInstallOnNetwork: fieldEmpty(vendorSuppliedDefaultsChangedBeforeInstallOnNetwork, 'Question 2.1 (a)'),
    unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork: fieldEmpty(unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork, 'Question 2.1 (b)'),
    systemAndSoftwareProtectedFromKnownVulnerabilities: fieldEmpty(systemAndSoftwareProtectedFromKnownVulnerabilities, 'Question 6.2 (a)'),
    criticalSecurityPatchesInstalledWithinOneMonthOfRelease: fieldEmpty(criticalSecurityPatchesInstalledWithinOneMonthOfRelease, 'Question 6.2 (b)'),
    usersAssignedUniqueIdBeforeAccessToSystem: fieldEmpty(usersAssignedUniqueIdBeforeAccessToSystem, 'Question 8.1.1'),
    accessForTerminatedUsersRemoved: fieldEmpty(accessForTerminatedUsersRemoved, 'Question 8.1.3'),
    authenticateUsersUsingPasswordOrTokenOrBiometric: fieldEmpty(authenticateUsersUsingPasswordOrTokenOrBiometric, 'Question 8.2'),
    userPasswordParametersConfiguredToMeetReqs: fieldEmpty(userPasswordParametersConfiguredToMeetReqs, 'Question 8.2.3 (a)'),
    groupSharedAccountsPasswordsAuthenticationMethodsProhibited: fieldEmpty(groupSharedAccountsPasswordsAuthenticationMethodsProhibited, 'Question 8.5'),
    allMediaPhysicallySecured: fieldEmpty(allMediaPhysicallySecured, 'Question 9.5'),
    strictControlOverDistributionOfMedia: fieldEmpty(strictControlOverDistributionOfMedia, 'Question 9.6 (a)'),
    mediaClassifiedToDetermineSensitivityOfData: fieldEmpty(mediaClassifiedToDetermineSensitivityOfData, 'Question 9.6 (b)'),
    mediaSentCanBeAccuratelyTracked: fieldEmpty(mediaSentCanBeAccuratelyTracked, 'Question 9.6.2'),
    managementApprovalObtainedToMoveMedia: fieldEmpty(managementApprovalObtainedToMoveMedia, 'Question 9.6.3'),
    strictControlMaintainedOverStorageAndAccessibilityOfMedia: fieldEmpty(strictControlMaintainedOverStorageAndAccessibilityOfMedia, 'Question 9.7'),
    mediaDestroyedWhenNoLongerNeeded: fieldEmpty(mediaDestroyedWhenNoLongerNeeded, 'Question 9.8 (a)'),
    hardCopyMaterialsCrosscutShreddedIncineratedOrPulped: fieldEmpty(hardCopyMaterialsCrosscutShreddedIncineratedOrPulped, 'Question 9.8.1 (a)'),
    storageContainersForMaterialsDestroyedToPreventAccessToContents: fieldEmpty(storageContainersForMaterialsDestroyedToPreventAccessToContents, 'Question 9.8.1 (b)'),
    listOfServiceProvidersMaintained: fieldEmpty(listOfServiceProvidersMaintained, 'Question 12.8.1'),
    writtenAgreementMaintained: fieldEmpty(writtenAgreementMaintained, 'Question 12.8.2'),
    establishedProcessForEngagingServicesProviders: fieldEmpty(establishedProcessForEngagingServicesProviders, 'Question 12.8.3'),
    programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually: fieldEmpty(programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually, 'Question 12.8.4'),
    informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity: fieldEmpty(informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity, 'Question 12.8.5'),
    incidentResponsePlanBeenCreatedInCaseOfSystemBreach: fieldEmpty(incidentResponsePlanBeenCreatedInCaseOfSystemBreach, 'Question 12.8.10 (a)'),
  }
}

export default validateComplianceSelfAssessmentQuestionnaireAForm
