import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditUserInfoForm from 'components/Customer/Forms/EditUserInfoForm/EditUserInfoForm'
import get from 'lodash/get'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hideModalAction from 'utilities/actions/hideModalAction'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser()
  const dashboardUserId = get(credentials, 'userId')
  const initialValues = {
    firstName: get(currentUser, 'firstName'),
    lastName: get(currentUser, 'lastName'),
  }

  return {
    credentials,
    dashboardUserId,
    initialValues,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditUserInfoFormC extends Component {
  render() {
    return (
      <EditUserInfoForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfoFormC)
