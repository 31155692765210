import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import MerchantSettlementsC from 'components/Customer/Pages/Merchant/MerchantSettlements/MerchantSettlementsC'
import MerchantMerchantDepositsC from 'components/Customer/Pages/Merchant/MerchantMerchantDeposits/MerchantMerchantDepositsC'
import MerchantFeesC from 'components/Customer/Pages/Merchant/MerchantFees/MerchantFeesC'

import {
  SETTLEMENTS,
  MERCHANT_DEPOSITS_RESOURCE_TITLE,
  FEES,
} from 'constants/language/languageConstants'

class MerchantPayoutsTabs extends Component {
  render() {
    const {
      merchantId,
    } = this.props

    const tabs = [
      {
        id: 'settlements',
        name: SETTLEMENTS,
        component: MerchantSettlementsC,
        props: {
          merchantId,
        },
      },
      {
        id: 'merchant-deposits',
        name: MERCHANT_DEPOSITS_RESOURCE_TITLE,
        component: MerchantMerchantDepositsC,
        props: {
          merchantId,
        },
      },
      {
        id: 'fees',
        name: FEES,
        component: MerchantFeesC,
        props: {
          merchantId,
        },
      },
    ]

    return (
      <div className='MerchantPayoutsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default MerchantPayoutsTabs
