import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisableAPIKey from './DisableAPIKey'
import hideModalAction from 'utilities/actions/hideModalAction'
import deleteAPIKeyRequest from 'utilities/actions/delete/deleteAPIKeyRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => { dispatch(hideModalAction()) },
    disableAPIKeyRequest: ({ apiKeyId, credentials }) => dispatch(deleteAPIKeyRequest({ apiKeyId, credentials })),
  }
}

class DisableAPIKeyC extends Component {
    disableAPIKey = () => {
      const {
        keyId,
        disableAPIKeyRequest,
        credentials,
        closeModal,
      } = this.props

      if (keyId) {
        disableAPIKeyRequest({ apiKeyId: keyId, credentials })
        closeModal()
      }
    }

    render() {
      return (
        <DisableAPIKey
          disableAPIKey={this.disableAPIKey}
          {...this.props}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisableAPIKeyC)
