import React from 'react'
import PropTypes from 'prop-types'
import { getPaymentInstrumentsSelector } from 'state-layer/selectors'
import columnDescriptors from 'components/Customer/Pages/ApplicationOwnerBankAccounts/columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { GET_PAYMENT_INSTRUMENTS } from 'constants/flowConstants'
import { IDENTITY_PAYMENT_INSTRUMENTS } from 'constants/linkConstants'

import {
  OWNER_IDENTITY_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  APPLICATION_BANK_ACCOUNTS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const ApplicationOwnerBankAccounts = ({
  isFetching = false,
  initialQueries = {},
  paymentInstrumentsPath = '',
  actions = [],
  identityId = '',
}) => {
  return (
    <div className='ApplicationOwnerBankAccounts'>
      { identityId && (
        <TableC
          flow={GET_PAYMENT_INSTRUMENTS}
          columnDescriptors={columnDescriptors}
          selector={getPaymentInstrumentsSelector}
          initialQueries={initialQueries}
          linksKey={IDENTITY_PAYMENT_INSTRUMENTS}
          path={paymentInstrumentsPath}
          isFetching={isFetching}
          fileNameCSV={`Identity_${identityId}_PaymentInstruments`}
          fileResourceTitleCSV={OWNER_IDENTITY_PAYMENT_INSTRUMENT_RESOURCE_TITLE}
          emptyMessage={APPLICATION_BANK_ACCOUNTS_EMPTY_MESSAGE}
          actions={actions}
        />
      )}
    </div>
  )
}

ApplicationOwnerBankAccounts.propTypes = {
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  paymentInstrumentsPath: PropTypes.string,
  actions: PropTypes.array,
  identityId: PropTypes.string,
}

export default ApplicationOwnerBankAccounts
