import { SUBDOMAIN_REGEX } from 'constants/regexConstants'

import {
  validateFile,
  invalidRegex,
} from 'utilities/validate'

const validateDashboardConfigurationForm = (values, { config }) => {
  const {
    dashboardSidebarLogo,
    subDomainName,
  } = values

  const validatedFile = dashboardSidebarLogo ? validateFile(dashboardSidebarLogo, config) : false
  const validSubDomainName = subDomainName ? invalidRegex({ field: subDomainName, name: 'Sub Domain', regex: SUBDOMAIN_REGEX, customErrorMessage: 'Sub Domain must only include characters a-z or 0-9' }) : false

  return {
    dashboardSidebarLogo: validatedFile,
    subDomainName: validSubDomainName,
  }
}

export default validateDashboardConfigurationForm
