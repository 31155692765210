import './AddNewBuyerIdentityFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import validateAddNewBuyerIdentityForm from 'utilities/validate/validateAddNewBuyerIdentityForm'
import submitAddNewBuyerIdentityForm from 'utilities/submit/submitAddNewBuyerIdentityForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { ADD_NEW_BUYER_IDENTITY_FORM } from 'constants/formConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  CANCEL,
  PHONE_NUMBER,
  ADD,
} from 'constants/language/languageConstants'

const AddNewBuyerIdentityForm = ({
  handleSubmit = () => {},
  submitting = false,
  closeModal = () => {},
  invalid = false,
  pristine = false,
}) => {
  return (
    <form className='AddNewBuyerIdentityForm' onSubmit={handleSubmit(submitAddNewBuyerIdentityForm)}>
      <div className='name-fields-container flex'>
        <Field
          name='firstName'
          label={FIRST_NAME}
          component={InputField}
          required
        />

        <Field
          name='lastName'
          label={LAST_NAME}
          component={InputField}
          required
        />
      </div>

      <Field
        name='email'
        label={EMAIL_ADDRESS}
        component={InputField}
        required
      />

      <Field
        name='phone'
        label={PHONE_NUMBER}
        component={InputField}
        format={formatPhone}
        parse={parsePhone}
        required={false}
      />

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={ADD} disabled={invalid || pristine} submitting={submitting} />
      </div>
    </form>
  )
}

AddNewBuyerIdentityForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: ADD_NEW_BUYER_IDENTITY_FORM,
  validate: validateAddNewBuyerIdentityForm,
})(AddNewBuyerIdentityForm)
