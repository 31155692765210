import './PlatformS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const Platform = ({
  headerTitle = '',
  headerSubTitle = '',
  tabs = [],
}) => {
  return (
    <div className='Platform'>
      <HeaderV2C
        title={headerTitle}
        subTitle={headerSubTitle}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

Platform.propTypes = {
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  tabs: PropTypes.array,
}

export default Platform
