import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import DevicePaymentsC from 'components/Customer/Pages/Device/DevicePayments/DevicePaymentsC'
import DeviceAuthorizationsC from 'components/Customer/Pages/Device/DeviceAuthorizations/DeviceAuthorizationsC'
import DeviceRefundsC from 'components/Customer/Pages/Device/DeviceRefunds/DeviceRefundsC'

import {
  PAYMENTS,
  AUTHORIZATIONS,
  REFUNDS,
} from 'constants/language/languageConstants'

class DeviceTransactionsTabs extends Component {
  render() {
    const {
      deviceId,
    } = this.props

    const tabs = [
      {
        id: 'payments',
        name: PAYMENTS,
        component: DevicePaymentsC,
        props: {
          deviceId,
          queries: { device: deviceId },
        },
      },
      {
        id: 'authorizations',
        name: AUTHORIZATIONS,
        component: DeviceAuthorizationsC,
        props: {
          deviceId,
          queries: { device: deviceId },
        },
      },
      {
        id: 'refunds',
        name: REFUNDS,
        component: DeviceRefundsC,
        props: {
          deviceId,
          queries: { device: deviceId },
        },
      },
    ]

    return (
      <div className='DeviceTransactionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default DeviceTransactionsTabs
