import './BalanceEntryS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'

import {
  DETAILS,
  ID,
  PARENT_BALANCE_ENTRY_DETAILS,
  PAYOUT_DETAILS,
} from 'constants/language/languageConstants'

const BalanceEntry = ({
  contextBarData = {},
  headerData = {},
  detailsDataSection = [],
  payoutDataSection = [],
  payoutClickToCopies = [],
  payoutLink = '',
  paymentInstrument = {},
  actions = [],
  isFetching = false,
  isAdjustment = false,
  parentBalanceEntryDetailsData = [],
  parentBalanceEntryId = '',
  parentBalanceEntryLink = '',
}) => {
  return (
    <div className='BalanceEntry'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={detailsDataSection}
        isFetching={isFetching}
      />

      {!isAdjustment && (
        <PageSectionV2C
          title={PAYOUT_DETAILS}
          columns={payoutDataSection}
          clickToCopies={payoutClickToCopies}
          asideTitle={payoutLink}
          isFetching={isFetching}
        />
      )}

      {parentBalanceEntryId && (
        <PageSectionV2C
          title={PARENT_BALANCE_ENTRY_DETAILS}
          className='balance-entry-details-section'
          isFetching={isFetching}
          columns={parentBalanceEntryDetailsData}
          clickToCopies={[{
            label: ID,
            content: parentBalanceEntryId,
          }]}
          asideTitle={parentBalanceEntryLink}
        />
      )}

      {!isAdjustment && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          paymentInstrument={paymentInstrument}
        />
      )}
    </div>
  )
}

BalanceEntry.propTypes = {
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  detailsDataSection: PropTypes.array,
  payoutDataSection: PropTypes.array,
  payoutClickToCopies: PropTypes.array,
  payoutLink: PropTypes.object,
  paymentInstrument: PropTypes.object,
  actions: PropTypes.array,
  isFetching: PropTypes.bool,
  isAdjustment: PropTypes.bool,
  parentBalanceEntryDetailsData: PropTypes.array,
  parentBalanceEntryId: PropTypes.string,
  parentBalanceEntryLink: PropTypes.element,
}

export default BalanceEntry
