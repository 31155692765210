import './ActivateDeviceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitActivateDeviceForm from 'utilities/submit/submitActivateDeviceForm'
import validateActivateDeviceForm from 'utilities/validate/validateActivateDeviceForm'
import { ACTIVATE_DEVICE_FORM } from 'constants/formConstants'

import {
  ACTIVATE_DEVICE,
  ACTIVATION_CODE,
  CANCEL,
  ACTIVATION_CODE_HELPER_TEXT,
  ACTIVATE_LATER,
} from 'constants/language/languageConstants'

const ActivateDeviceForm = ({
  handleSubmit = () => {},
  invalid = false,
  showActivationCodeInput = false,
  deviceName = '',
  closeModal,
  isUpdating = false,
  isAddAndActivateDeviceFlow = false,
  redirectToDevicePage = () => {},
}) => {
  return (
    <form className='ActivateDeviceForm' onSubmit={handleSubmit(submitActivateDeviceForm)}>
      { !isAddAndActivateDeviceFlow && (
        <div className='modal-content'>
          { showActivationCodeInput && (
            <Field
              name='activationCode'
              label={ACTIVATION_CODE}
              component={InputField}
              placeholder='0000000'
              helperText={ACTIVATION_CODE_HELPER_TEXT}
            />
          )}

          {!showActivationCodeInput && <div className='confirm-activation-msg'>{`This will activate ${deviceName}.`}</div>}
        </div>
      )}

      { isAddAndActivateDeviceFlow ? (
        <div className='buttons flex flex-end'>
          <Button onClick={redirectToDevicePage} label={ACTIVATE_LATER} variant='ghost' className='cancel-button' />
          <Button type='submit' disabled={invalid} submitting={isUpdating} label={ACTIVATE_DEVICE} className='submit-button' />
        </div>
      ) : (
        <div className='buttons flex flex-end'>
          <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button type='submit' disabled={invalid} submitting={isUpdating} label={ACTIVATE_DEVICE} className='submit-button' />
        </div>
      )}
    </form>
  )
}

ActivateDeviceForm.propTypes = {
  handleSubmit: PropTypes.func,
  showActivationCodeInput: PropTypes.bool,
  deviceName: PropTypes.string,
  invalid: PropTypes.bool,
  closeModal: PropTypes.func,
  isUpdating: PropTypes.bool,
  isAddAndActivateDeviceFlow: PropTypes.bool,
  redirectToDevicePage: PropTypes.func,
}

export default reduxForm({
  form: ACTIVATE_DEVICE_FORM,
  validate: validateActivateDeviceForm,
})(ActivateDeviceForm)
