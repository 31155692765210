import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import AcceptLiabilityConfirmationFormC from 'components/Customer/Forms/AcceptLiabilityConfirmationForm/AcceptLiabilityConfirmationFormC'
import { ACCEPT_LIABILITY } from 'constants/language/languageConstants'

const AcceptLiabilityModal = ({
  disputeId = '',
}) => {
  return (
    <GenericModal
      className='AcceptLiabilityModal'
      title={ACCEPT_LIABILITY}
      Component={AcceptLiabilityConfirmationFormC}
      disputeId={disputeId}
    />
  )
}

AcceptLiabilityModal.propTypes = {
  disputeId: PropTypes.string,
}

export default AcceptLiabilityModal
