import { GET_PAYMENT_INSTRUMENT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPaymentInstrumentRequest = ({
  paymentInstrumentId,
  credentials,
}) => createAction({
  type: GET_PAYMENT_INSTRUMENT_F_REQUEST,
  id: paymentInstrumentId,
  credentials,
})

export default getPaymentInstrumentRequest
