import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { DELETE_SUBSCRIPTION_F_REQUEST } from 'constants/flowConstants'
import { DELETE_SUBSCRIPTION } from 'constants/amplitudeConstants'

const submitCancelSubscriptionForm = (values, dispatch, props) => {
  const {
    credentials,
    subscriptionId,
  } = props

  dispatch({
    type: DELETE_SUBSCRIPTION_F_REQUEST,
    payload: {
      id: subscriptionId,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(DELETE_SUBSCRIPTION, {
        subscriptionId,
        credentials,
      }),
      closeModal: true,
    },
  })
}

export default submitCancelSubscriptionForm
