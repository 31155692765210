import React, { Component } from 'react'
import { connect } from 'react-redux'
import PreviousStep from './PreviousStep'
import hideModalAction from 'utilities/actions/hideModalAction'
import prevFlowStep from 'utilities/prevFlowStep'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => { dispatch(hideModalAction()) },
  }
}

class PreviousStepC extends Component {
    goToPreviousStep = () => {
      const { closeModal } = this.props
      prevFlowStep()
      closeModal()
    }

    render() {
      return (
        <PreviousStep
          goToPreviousStep={this.goToPreviousStep}
          {...this.props}
        />
      )
    }
}

export default connect(null, mapDispatchToProps)(PreviousStepC)
