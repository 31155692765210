import { CREATE_PAYMENT_INSTRUMENT } from 'constants/amplitudeConstants'
import { PAYMENT_INSTRUMENT_PATH } from 'constants/pathConstants'
import { POST_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST } from 'constants/flowConstants'
import dispatch from 'utilities/dispatch'
import redirectRequest from 'utilities/actions/redirectRequest'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const postPaymentInstrumentRequest = ({
  values,
  credentials,
  isDisbursementsDashboard = false,
}) => createAction({
  type: POST_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST,
  credentials,
  values,
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_PAYMENT_INSTRUMENT, {
      values,
    }),
    selectorId: get(values, 'identity'),
    successCallback: ({ newValues }) => {
      const paymentInstrumentId = head(keys(newValues))
      if (isDisbursementsDashboard) {
        const credentialId = get(credentials, 'id')
        const path = PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId })
        dispatch(redirectRequest({ path }))
      }
    },
  },
})

export default postPaymentInstrumentRequest
