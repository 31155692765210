import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_TRANSFERS } from 'constants/apiConstants'

const getBalanceTransfersAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: BALANCE_TRANSFERS,
    queries,
    credentials,
    service: BALANCE_TRANSFERS,
  })
}

export default getBalanceTransfersAPI
