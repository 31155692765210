import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuditLogEvent from './AuditLogEvent'
import Address from 'components/Customer/Shared/Display/Address/Address'
import DisplayUpdatedIdentityDifferences from 'components/Customer/Shared/Display/DisplayUpdatedIdentityDifferences/DisplayUpdatedIdentityDifferences'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getAuditLogEventRequest from 'utilities/actions/get/getAuditLogEventRequest'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  getApplicationSelector,
  getAuditLogEventSelector,
} from 'state-layer/selectors'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  IDENTITY_CREATED,
  IDENTITY_UPDATED,
  INSTRUMENT_CREATED,
} from 'constants/AuditLogEventConstants'

import {
  CREATED_ON,
  AUDIT_LOG_EVENT,
  RESOURCE_TYPE,
  BUSINESS_NAME,
  BUSINESS_ADDRESS,
  MCC,
  APPLICATION,
  USER,
  BUSINESS_DBA,
  BUSINESS_PHONE,
  OWNER_NAME,
  OWNER_EMAIL,
  OWNER_PHONE,
  OWNER_TITLE,
  OWNER_BIRTHDATE,
  OWNER_ADDRESS,
  OWNER_TAX_ID_PROVIDED,
  OWNERSHIP_PERCENTAGE,
  IDENTITY_ROLES,
  ID,
  PAYMENT_TYPE,
  CURRENCY,
  FINGERPRINT_ID,
  MERCHANT_IDENTITY,
  BUSINESS_WEBSITE,
  UPDATED_ON,
  MERCHANT_CATEGORY_CODE,
  WEBSITE,
  DISCOVER_MID,
  AMERICAN_EXPRESS_MID,
  DATE_OF_BIRTH,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `auditLogEventsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const auditLogEventId = get(props, 'params.auditLogEventId')
  const identityId = get(props, 'params.identityId')
  const auditLogEvent = getAuditLogEventSelector(state, auditLogEventId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  let dataSection = []
  let detailSectionClickToCopies = []

  const [
    type,
    displayName,
    displayCreatedAt,
    displayLinkedType,
    userEmail,
    resource = {},
    applicationId,
    userId,
    updatedItems,
  ] = getMany(auditLogEvent, [
    'type',
    'displayName',
    'displayCreatedAt',
    'displayLinkedType', // 'linkedType' is a reserved word in JS, so we use 'displayLinkedType' instead
    'userEmail',
    'resource',
    'applicationId',
    'userId',
    'updatedItems',
  ])

  const application = getApplicationSelector(state, applicationId)
  const applicationName = get(application, 'businessName')

  const [
    identity,
    displayIdentityRoles,
    displayPaymentInstrumentType,
    paymentInstrumentCurrency,
    paymentInstrumentId,
    paymentInstrumentFingerprint,
    displayUpdatedAt,
  ] = getMany(resource, [
    'entity',
    'displayIdentityRoles',
    'displayPaymentInstrumentType',
    'paymentInstrumentCurrency',
    'paymentInstrumentId',
    'paymentInstrumentFingerprint',
    'displayUpdatedAt',
  ])

  const [
    identityBusinessName,
    identityDoingBusinessAs,
    displayIdentityPhone,
    identityBusinessAddress,
    identityMcc,
    fullName,
    identityEmail,
    identityTitle,
    displayIdentityDateOfBirth,
    identityPersonalAddress,
    displayIdentityTaxProvided,
    displayIdentityPrincipalPercentageOwnership,
    identityBusinessPhone,
    identityUrl,
    displayIdentityUrl,
  ] = getMany(identity, [
    'identityBusinessName',
    'identityDoingBusinessAs',
    'displayIdentityPhone',
    'identityBusinessAddress',
    'identityMcc',
    'fullName',
    'identityEmail',
    'identityTitle',
    'displayIdentityDateOfBirth',
    'identityPersonalAddress',
    'displayIdentityTaxProvided',
    'displayIdentityPrincipalPercentageOwnership',
    'identityBusinessPhone',
    'identityUrl',
    'displayIdentityUrl',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY,
        value: identityBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
        condition: !isStandaloneMerchant,
      },
    ],
  }

  if (type === IDENTITY_CREATED || type === IDENTITY_UPDATED) {
    dataSection = [
      {
        label: RESOURCE_TYPE,
        value: displayLinkedType,
      },
      {
        label: BUSINESS_NAME,
        value: identityBusinessName,
      },
      {
        label: BUSINESS_DBA,
        value: identityDoingBusinessAs,
      },
      {
        label: BUSINESS_PHONE,
        value: identityBusinessPhone,
      },
      {
        label: BUSINESS_ADDRESS,
        value: <Address address={identityBusinessAddress} />,
      },
      {
        label: MCC,
        value: identityMcc,
      },
      {
        label: BUSINESS_WEBSITE,
        value: <a className='text-link' href={identityUrl} target='blank'>{displayIdentityUrl}</a>,
      },
      {
        label: OWNER_NAME,
        value: fullName,
      },
      {
        label: OWNER_EMAIL,
        value: identityEmail,
      },
      {
        label: OWNER_PHONE,
        value: displayIdentityPhone,
      },
      {
        label: OWNER_TITLE,
        value: identityTitle,
      },
      {
        label: OWNER_BIRTHDATE,
        value: displayIdentityDateOfBirth,
      },
      {
        label: OWNER_ADDRESS,
        value: <Address address={identityPersonalAddress} />,
      },
      {
        label: OWNER_TAX_ID_PROVIDED,
        value: displayIdentityTaxProvided,
      },
      {
        label: OWNERSHIP_PERCENTAGE,
        value: displayIdentityPrincipalPercentageOwnership,
      },
      {
        label: IDENTITY_ROLES,
        value: displayIdentityRoles,
      },
    ]

    detailSectionClickToCopies = [
      {
        label: ID,
        content: identityId,
      },
    ]
  }

  if (type === INSTRUMENT_CREATED) {
    dataSection = [
      {
        label: RESOURCE_TYPE,
        value: displayLinkedType,
      },
      {
        label: PAYMENT_TYPE,
        value: displayPaymentInstrumentType,
      },
      {
        label: CURRENCY,
        value: paymentInstrumentCurrency,
      },
    ]

    detailSectionClickToCopies = [
      {
        label: ID,
        content: paymentInstrumentId,
      },
      {
        label: FINGERPRINT_ID,
        content: paymentInstrumentFingerprint,
      },
    ]
  }

  const headerData = {
    resource: {
      label: AUDIT_LOG_EVENT,
      id: auditLogEventId,
    },
    items: [
      {
        value: <h1>{displayName}</h1>,
      },
      {
        label: type === IDENTITY_UPDATED ? UPDATED_ON : CREATED_ON,
        value: type === IDENTITY_UPDATED ? displayUpdatedAt : displayCreatedAt,
      },
      {
        label: USER,
        value: userEmail ? (<div><span className='user-email'>{userEmail}</span><ClickToCopyC label={ID} content={userId} /></div>) : undefined,
      },
    ],
  }

  const updateActivityData = map(updatedItems, ({ from, to, label }) => ({
    label,
    value: <DisplayUpdatedIdentityDifferences fromValue={from} toValue={to} />,
  }))

  const detailsDataSection = convertPageSectionDataToV2(dataSection)
  const updateActivityDataSection = convertPageSectionDataToV2(updateActivityData, 1)

  return {
    isFetching,
    credentials,
    auditLogEventId,
    auditLogEvent,
    headerData,
    detailsDataSection,
    identityId,
    contextBarData,
    detailSectionClickToCopies,
    applicationId,
    type,
    updateActivityDataSection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAuditLogEvent: ({ auditLogEventId, credentials }) => dispatch(getAuditLogEventRequest({ eventId: auditLogEventId, credentials })),
    getApplication: ({ applicationId, credentials }) => dispatch(getApplicationRequest({ applicationId, credentials })),
  }
}

class AuditLogEventC extends Component {
  componentDidMount() {
    const {
      applicationId,
      auditLogEventId,
      getApplication,
      getAuditLogEvent,
      credentials,
    } = this.props

    if (applicationId) {
      getApplication({ applicationId, credentials })
    }

    if (auditLogEventId) {
      getAuditLogEvent({ auditLogEventId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      auditLogEventId,
      applicationId,
      getAuditLogEvent,
      getApplication,
      credentials,
    } = this.props

    const { auditLogEventId: prevAuditLogEventId, applicationId: prevApplicationId } = prevProps

    if (auditLogEventId !== prevAuditLogEventId && auditLogEventId) {
      getAuditLogEvent({ auditLogEventId, credentials })
    }

    if (applicationId !== prevApplicationId && applicationId) {
      getApplication({ applicationId, credentials })
    }
  }

  render() {
    return (
      <AuditLogEvent
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogEventC)
