import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  ADDITIONAL_VERIFICATION_NEEDED_CONFIRMATION_MSG,
  CONFIRM,
  SUBMIT,
} from 'constants/language/languageConstants'

const AdditionalVerification = ({
  closeModal = () => {},
  requestAdditionalVerification = () => {},
}) => {
  return (
    <div className='AdditionalVerification'>
      <div className='modal-content confirmation-msg'>
        {ADDITIONAL_VERIFICATION_NEEDED_CONFIRMATION_MSG}
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label='Cancel' variant='ghost' className='cancel-button' />
        <Button type={SUBMIT} label={CONFIRM} onClick={requestAdditionalVerification} />
      </div>
    </div>
  )
}

AdditionalVerification.propTypes = {
  closeModal: PropTypes.func,
  requestAdditionalVerification: PropTypes.func,
}

export default AdditionalVerification
