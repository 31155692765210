import frontendMemberModel from 'utilities/create/models/frontendMemberModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendMembersModel = ({ data: members }) => {
  const memberModels = map(members, (member) => frontendMemberModel({ data: member }))

  return keyBy(memberModels, 'id')
}

export default frontendMembersModel
