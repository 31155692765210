import { GET_TRANSFER_ATTEMPT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

// TODO: Cata - add unit tests
const getTransferAttemptRequest = ({
  transferAttemptId,
  credentials,
}) => createAction({
  type: GET_TRANSFER_ATTEMPT_F_REQUEST,
  id: transferAttemptId,
  credentials,
})

export default getTransferAttemptRequest
