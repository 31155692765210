import './AmountCurrencyIconDisplayTableS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { USD } from 'constants/currencyConstants'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

const AmountCurrencyIconDisplayTable = ({
  displayAmount = '',
  currency = USD,
  icon,
  iconStyle = {},
}) => {
  const invalidDisplayAmount = isEqual(displayAmount, '-') || isEmpty(displayAmount)

  return (
    <div className='AmountCurrencyIconDisplayTable flex flex-end'>
      {icon && <div className='icon-container'><i className={`fa fa-${icon}`} style={iconStyle} /></div>}
      <div className='amount-string'>{displayAmount}</div>
      <div className={`currency-string ${invalidDisplayAmount && 'invalid-display-amount'}`}>{currency}</div>
    </div>
  )
}

AmountCurrencyIconDisplayTable.propTypes = {
  icon: PropTypes.string,
  displayAmount: PropTypes.string,
  currency: PropTypes.string,
  iconStyle: PropTypes.object,
}

export default AmountCurrencyIconDisplayTable
