import { getState } from 'state-layer/configureStore'
import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  FLEX,
  PAYFAC,
  PAYFAC_WITH_UNDERWRITING,
  UNDERWRITING,
  PUSH_TO_CARD,
} from 'constants/dashboardConfigurationsConstants'

const isPlatformType = (state, type) => {
  const currentUser = getCurrentUser(state)
  const platformType = get(currentUser, 'customSettings.platformType')

  return isEqual(platformType, type)
}

// TODO: update all places using isFlexPlatform to this for standalone underwriting release
export const isFlexPlatformV2 = (state = getState()) => isPlatformType(state, FLEX)

export const isPayfacPlatform = (state = getState()) => isPlatformType(state, PAYFAC)

export const isPayfacWithUnderwritingPlatform = (state = getState()) => isPlatformType(state, PAYFAC_WITH_UNDERWRITING)

export const isPushToCardPlatform = (state = getState()) => isPlatformType(state, PUSH_TO_CARD)

export const isStandaloneUnderwritingPlatform = (state = getState()) => isPlatformType(state, UNDERWRITING)
