import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateBalanceAdjustment from './CreateBalanceAdjustment'
import { getIdentitySelector } from 'state-layer/selectors'
import CreateBalanceAdjustmentFormC from 'components/Customer/Forms/CreateBalanceAdjustmentForm/CreateBalanceAdjustmentFormC'
import CreateBalanceAdjustmentReviewFormC from 'components/Customer/Forms/CreateBalanceAdjustmentReviewForm/CreateBalanceAdjustmentReviewFormC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'

import {
  CREATE_A_BALANCE_ADJUSTMENT,
  REVIEW_TRANSACTION,
} from 'constants/language/languageConstants'

import {
  CREATE_BALANCE_ADJUSTMENT_FORM,
  CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM,
} from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const identityId = get(currentUser, 'identityId')
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)

  const steps = [
    {
      name: CREATE_A_BALANCE_ADJUSTMENT,
      component: CreateBalanceAdjustmentFormC,
      dataKey: 'createBalanceAdjustment',
      formValues: () => reduxFormValues(CREATE_BALANCE_ADJUSTMENT_FORM),
      props: { identityId },
    },
    {
      name: REVIEW_TRANSACTION,
      component: CreateBalanceAdjustmentReviewFormC,
      dateKey: 'createBalanceAdjustment',
      formValues: () => reduxFormValues(CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM),
      props: { identityId },
    },
  ]

  return {
    steps,
    credentials,
  }
}

class CreateBalanceAdjustmentC extends Component {
  render() {
    return (
      <CreateBalanceAdjustment {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateBalanceAdjustmentC)
