import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutLink from './PayoutLink'
import PayoutLinkOverviewC from './PayoutLinkOverview/PayoutLinkOverviewC'
import PayoutLinkTransferAttemptsC from './PayoutLinkTransferAttempts/PayoutLinkTransferAttemptsC'
import PaymentLinkStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PaymentLinkStatus'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import getPayoutLinkSucceededTransferAttemptRequest from 'utilities/actions/get/getPayoutLinkSucceededTransferAttemptRequest'
import getPayoutLinkRequest from 'utilities/actions/get/getPayoutLinkRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import copyToClipboard from 'utilities/copyToClipboard'
import showModalAction from 'utilities/actions/showModalAction'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import { FETCHING } from 'constants/reducerConstants'
import { COPY } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  getMerchantSelector,
  getPayoutLinkDataSelector,
  getSucceededTransferAttemptSelector,
} from 'state-layer/selectors'

import {
  MERCHANT_PATH,
  RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  DISABLE_PAYOUT_LINK_MODAL,
  ENABLE_PAYOUT_LINK_MODAL,
} from 'constants/modalConstants'

import {
  ACTIVE,
  DEACTIVATED,
} from 'constants/paymentLinkConstants'

import {
  COPY_LINK,
  CREATED_ON,
  DEACTIVATE_PAYOUT_LINK,
  EXPIRES_ON,
  MERCHANT_ACCOUNT,
  NAME,
  OVERVIEW,
  PAYOUT_ATTEMPTS,
  PAYOUT_LINK,
  REACTIVATE_PAYOUT_LINK,
  RECIPIENT_IDENTITY,
  STATUS,
  t,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingPayoutLinks = get(state, `payoutLinksR.${FETCHING}`)
  const isFetchingTransferAttempts = get(state, `transferAttemptsR.${FETCHING}`)
  const isFetching = isFetchingPayoutLinks || isFetchingTransferAttempts
  const payoutLinkId = get(props, 'params.payoutLinkId')
  const payoutLink = getPayoutLinkDataSelector(state, payoutLinkId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const succeededTransferAttempt = getSucceededTransferAttemptSelector(state)

  const [
    transferAttemptRecipientMerchantId,
    transferAttemptRecipientFullName,
    transferAttemptRecipientType,
    transferAttemptRecipientBusinessName,
  ] = getMany(succeededTransferAttempt, [
    'recipientMerchantId',
    'recipientFullName',
    'recipientType',
    'recipientBusinessName',
  ])

  const [
    nickname,
    status,
    displayCreatedAt,
    displayLinkExpiresAt,
    displayTotalAmount,
    currency,
    linkUrl,
    recipientMerchantId,
    recipientFullName,
    recipientBusinessName,
  ] = getMany(payoutLink, [
    'nickname',
    'state',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'displayTotalAmount',
    'currency',
    'linkUrl',
    'recipientMerchantId',
    'recipientFullName',
    'recipientBusinessName',
  ])

  // if there's a pre-selected recipient, we get the merchant from payout link. if not, we get it from the succeeded transfer attempt
  const recipientMerchant = getMerchantSelector(state, recipientMerchantId) || getMerchantSelector(state, transferAttemptRecipientMerchantId)
  const recipientIdentityId = get(recipientMerchant, 'identityId')
  const recipientMerchantBusinessName = get(recipientMerchant, 'displayBusinessName')
  const merchantId = get(recipientMerchant, 'id')
  const recipientNameValue = recipientBusinessName || recipientFullName || transferAttemptRecipientBusinessName || transferAttemptRecipientFullName

  const contextBarData = {
    items: [
      {
        label: RECIPIENT_IDENTITY,
        value: recipientNameValue,
        path: RECIPIENT_PATH({ credentialId, recipientId: recipientIdentityId }),
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantId,
        path: MERCHANT_PATH({ credentialId, merchantId, identityId: recipientIdentityId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: PAYOUT_LINK,
      id: payoutLinkId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayTotalAmount} currency={currency} />,
      },
      {
        label: NAME,
        value: nickname,
      },
      {
        label: STATUS,
        value: <PaymentLinkStatus value={status} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: EXPIRES_ON,
        value: displayLinkExpiresAt,
      },
      {
        label: PAYOUT_LINK,
        value: <span className='link-2' onClick={() => copyToClipboard(linkUrl)}><i className={`fas fa-${COPY}`} /> {t(COPY_LINK)}</span>,
      },
    ],
  }

  const tabs = [
    {
      id: 'overview',
      name: OVERVIEW,
      component: PayoutLinkOverviewC,
      props: {
        payoutLinkId,
        payoutLink,
        succeededTransferAttempt,
        recipientMerchant,
      },
    },
    {
      id: 'payout-attempts',
      name: PAYOUT_ATTEMPTS,
      component: PayoutLinkTransferAttemptsC,
      props: {
        payoutLinkId,
      },
    },
  ]


  return {
    isFetching,
    credentials,
    payoutLinkId,
    headerData,
    status,
    tabs,
    recipientMerchantId,
    contextBarData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPayoutLink: ({ credentials, payoutLinkId }) => dispatch(getPayoutLinkRequest({ credentials, payoutLinkId })),
    getMerchant: ({ credentials, merchantId }) => dispatch(getMerchantRequest({ credentials, merchantId })),
    getSucceededTransferAttempt: ({ credentials, payoutLinkId }) => dispatch(getPayoutLinkSucceededTransferAttemptRequest({ credentials, payoutLinkId })),
    showDisablePayoutLinkModal: ({ payoutLinkId }) => dispatch(showModalAction({ modalType: DISABLE_PAYOUT_LINK_MODAL, modalProps: { payoutLinkId }, className: 'modal-sm no-pad' })),
    showEnablePayoutLinkModal: ({ payoutLinkId }) => dispatch(showModalAction({ modalType: ENABLE_PAYOUT_LINK_MODAL, modalProps: { payoutLinkId }, className: 'modal-sm no-pad' })),
  }
}

class PayoutLinkC extends Component {
  componentDidMount() {
    const {
      credentials,
      payoutLinkId,
      getPayoutLink,
      getSucceededTransferAttempt,
      getMerchant,
      recipientMerchantId,
    } = this.props

    getPayoutLink({ credentials, payoutLinkId })
    getSucceededTransferAttempt({ credentials, payoutLinkId })
    getMerchant({ merchantId: recipientMerchantId, credentials })
  }

  componentDidUpdate(prevProps) {
    const {
      recipientMerchantId,
      getMerchant,
      credentials,
    } = this.props

    if (recipientMerchantId && recipientMerchantId !== prevProps.recipientMerchantId) {
      getMerchant({ merchantId: recipientMerchantId, credentials })
    }
  }

  render() {
    const {
      payoutLinkId,
      showDisablePayoutLinkModal,
      showEnablePayoutLinkModal,
      status,
    } = this.props

    const actions = [
      {
        label: DEACTIVATE_PAYOUT_LINK,
        action: () => showDisablePayoutLinkModal({ payoutLinkId }),
        className: 'deactivate-payout-link-button',
        condition: status === ACTIVE,
      },
      {
        label: REACTIVATE_PAYOUT_LINK,
        action: () => showEnablePayoutLinkModal({ payoutLinkId }),
        className: 'reactivate-payout-link-button',
        condition: status === DEACTIVATED,
      },
    ]

    return (
      <PayoutLink
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutLinkC)
