import './UpdateOnboardingFormFileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import submitUpdateOnboardingFormFileForm from 'utilities/submit/submitUpdateOnboardingFormFileForm'
import { UPDATE_ONBOARDING_FORM_FILE_NAME_FORM } from 'constants/formConstants'
import { CANCEL, FILE_NAME } from 'constants/language/languageConstants'

const UpdateOnboardingFormFileForm = ({
  handleSubmit = () => {},
  invalid = false,
  removeFile = false,
  closeModal = () => {},
}) => {
  const buttonLabel = removeFile ? 'Remove File' : 'Update File Name'
  const buttonVariant = removeFile ? 'destructive' : 'primary'

  return (
    <form className='UpdateOnboardingFormFileForm' onSubmit={handleSubmit(submitUpdateOnboardingFormFileForm)}>
      <div className='modal-content'>
        { removeFile ? (
          <p className='p-1'>Are you sure you&apos;d like to remove this file? This action is permanent and cannot be undone.</p>
        ) : (
          <Field
            name='fileName'
            label={FILE_NAME}
            component={InputField}
          />
        )}
      </div>

      <div className='buttons flex flex-end'>
        <Button className='cancel-button' onClick={closeModal} label={CANCEL} variant='ghost' />
        <Button type='submit' className='submit-button' label={buttonLabel} variant={buttonVariant} disabled={invalid} />
      </div>
    </form>
  )
}

UpdateOnboardingFormFileForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  removeFile: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: UPDATE_ONBOARDING_FORM_FILE_NAME_FORM,
  validate: ({ fileName }) => ({
    fileName: !fileName ? 'File Name must not be empty' : false,
  }),
})(UpdateOnboardingFormFileForm)
