import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors'
import BusinessTypeComponent from 'components/Customer/Forms/DashboardTypeForm/BusinessTypeComponent'
import DashboardTypeForm from 'components/Customer/Forms/DashboardTypeForm/DashboardTypeForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { CHOOSE_SANDBOX_DASHBOARD } from 'constants/amplitudeConstants'
import get from 'lodash/get'

import {
  roleToEntityIdMap,
  roleToEntityTypeMap,
} from 'constants/memberConstants'

import {
  MARKETPLACE,
  SOFTWARE_COMPANY,
  STANDALONE_MERCHANT,
} from 'constants/dashboardConfigurationsConstants'

import {
  STANDALONE_MERCHANT_INFO,
  STANDALONE_MERCHANT_EXAMPLE,
  STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL,
  SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL,
  SOFTWARE_COMPANY_INFO,
  SOFTWARE_COMPANY_EXAMPLE,
  MARKETPLACE_TYPE_DISPLAY_LABEL,
  MARKETPLACE_INFO,
  MARKETPLACE_EXAMPLE,
} from 'constants/language/languageConstants'

const options = [
  {
    label: (
      <BusinessTypeComponent
        icon='far fa-shopping-cart'
        name={STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL}
        info={STANDALONE_MERCHANT_INFO}
        example={STANDALONE_MERCHANT_EXAMPLE}
        className='individual-business'
      />
    ),
    value: STANDALONE_MERCHANT,
  },
  {
    label: (
      <BusinessTypeComponent
        icon='far fa-laptop-code'
        name={SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL}
        info={SOFTWARE_COMPANY_INFO}
        example={SOFTWARE_COMPANY_EXAMPLE}
        className='software-platform'
      />
    ),
    value: SOFTWARE_COMPANY,
  },
  {
    label: (
      <BusinessTypeComponent
        icon='far fa-store'
        name={MARKETPLACE_TYPE_DISPLAY_LABEL}
        info={MARKETPLACE_INFO}
        example={MARKETPLACE_EXAMPLE}
        className='marketplace'
      />
    ),
    value: MARKETPLACE,
  },
]

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const authRole = get(credentials, 'role')
  const entityType = roleToEntityTypeMap[authRole]
  const entityIdKey = roleToEntityIdMap[authRole]
  const entityId = get(currentUser, entityIdKey)
  const dashboardConfiguration = getDashboardConfigByEntityTypeAndId(state, entityType, entityId)
  const id = get(dashboardConfiguration, 'id')

  return removeUndefined({
    id,
    entityId,
    entityType,
    credentials,
    options,
    hideFlashNotification: true,
    eventType: CHOOSE_SANDBOX_DASHBOARD,
  })
}

class DashboardTypeFormC extends Component {
  render() {
    const { closeModal } = this.props

    return (
      <DashboardTypeForm
        {...this.props}
        customSuccessFunc={closeModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTypeFormC)
