import React from 'react'
import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  ROLE_PLATFORM,
  ROLE_PARTNER,
  ROLE_MERCHANT,
} from 'constants/roleConstants'

import {
  YES,
  NO,
  STANDALONE_MERCHANT_BADGE_LABEL,
  SOFTWARE_COMPANY_BADGE_LABEL,
  MARKETPLACE_BADGE_LABEL,
  STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL,
  SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL,
  MARKETPLACE_TYPE_DISPLAY_LABEL,
  SOFTWARE_PLATFORM,
  INDIVIDUAL_BUSINESS,
  MARKETPLACE as MARKETPLACE_LABEL,
  PLATFORM_TERMS_OF_SERVICE,
  MERCHANT_TERMS_OF_SERVICE,
  DISBURSEMENTS_TYPE_DISPLAY_LABEL,
  PAYOUTS,
  DISBURSEMENTS_AND_PAYMENTS_LABEL,
} from 'constants/language/languageConstants'

export const PLATFORM = 'PLATFORM'
const APPLICATIONS = 'APPLICATIONS'
export const APPLICATION = 'APPLICATION'
const MERCHANTS = 'MERCHANTS'
export const MERCHANT = 'MERCHANT'

// dashboard type enums
export const STANDALONE_MERCHANT = 'STANDALONE_MERCHANT'
export const MARKETPLACE = 'MARKETPLACE'
export const SOFTWARE_COMPANY = 'SOFTWARE_COMPANY'
export const DISBURSEMENTS = 'DISBURSEMENTS'
export const DISBURSEMENT = 'DISBURSEMENT'
export const DISBURSEMENTS_AND_PAYMENTS = 'DISBURSEMENTS_AND_PAYMENTS'

// dashboard type field name
export const CUSTOMER_PROFILE_TYPE_FIELD_NAME = 'dashboardType'

// dashboard type options for modal
export const  DASHBOARD_TYPE_OPTIONS = [
  { label: STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL, value: STANDALONE_MERCHANT },
  { label: MARKETPLACE_TYPE_DISPLAY_LABEL, value: MARKETPLACE },
  { label: SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL, value: SOFTWARE_COMPANY },
  { label: DISBURSEMENTS_TYPE_DISPLAY_LABEL, value: DISBURSEMENT },
  { label: DISBURSEMENTS_AND_PAYMENTS_LABEL, value: DISBURSEMENTS_AND_PAYMENTS },
]

export const dashboardTypeOptions = (currentType) => {
  return map(DASHBOARD_TYPE_OPTIONS, (option) => {
    const label = <div>{get(option, 'label')} {get(option, 'value') === currentType ? <span className='current-dashboard-type'>(current type)</span> : ''}</div>
    return selectOption(label, option.value)
  })
}

export const entityTypeMap = {
  [PLATFORM]: PLATFORM,
  [APPLICATIONS]: APPLICATION,
  [APPLICATION]: APPLICATION,
  [MERCHANTS]: MERCHANT,
  [MERCHANT]: MERCHANT,
}

export const CONFIGURATIONS_OPTIONS = {
  [ROLE_PLATFORM]: {
    type: PLATFORM,
    entityIdKey: 'platformId',
    typeOptions: [
      selectOption('Platform', PLATFORM),
      selectOption('All Applications', APPLICATIONS),
      selectOption('All Merchants', MERCHANTS),
    ],
  },
  [ROLE_PARTNER]: {
    type: APPLICATION,
    entityIdKey: 'applicationId',
    typeOptions: [
      selectOption('Application', APPLICATION),
      selectOption('All Merchants', MERCHANTS),
    ],
  },
  [ROLE_MERCHANT]: {
    type: MERCHANT,
    entityIdKey: 'identityId',
    typeOptions: [
      selectOption('Merchant', MERCHANT),
    ],
  },
}

export const BRANDING_ENABLED_OPTIONS = [
  { label: YES, value: 'true' },
  { label: NO, value: 'false' },
]

export const  toggleShowColorPicker = (id) => {
  const chromePicker = document.getElementById(id)?.firstChild

  if (chromePicker) {
    get(chromePicker, 'classList').toggle('show')
  }
}

export const DASHBOARD_TYPE_TO_BADGE_LABEL_MAP = {
  [STANDALONE_MERCHANT]: STANDALONE_MERCHANT_BADGE_LABEL,
  [SOFTWARE_COMPANY]: SOFTWARE_COMPANY_BADGE_LABEL,
  [MARKETPLACE]: MARKETPLACE_BADGE_LABEL,
}

export const DASHBOARD_TYPE_TO_DISPLAY_LABEL_MAP = {
  [STANDALONE_MERCHANT]: STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL,
  [SOFTWARE_COMPANY]: SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL,
  [MARKETPLACE]: MARKETPLACE_TYPE_DISPLAY_LABEL,
  [DISBURSEMENT]: PAYOUTS,
  [DISBURSEMENTS_AND_PAYMENTS]: DISBURSEMENTS_AND_PAYMENTS_LABEL,
}

export const DASHBOARD_TYPE_BE_TO_LABEL_MAP = {
  [STANDALONE_MERCHANT]: INDIVIDUAL_BUSINESS,
  [SOFTWARE_COMPANY]: SOFTWARE_PLATFORM,
  [MARKETPLACE]: MARKETPLACE_LABEL,
  [DISBURSEMENT]: DISBURSEMENT,
}

export const DASHBOARD_TYPE_TO_TERMS_OF_SERVICE_MAP = {
  [STANDALONE_MERCHANT]: MERCHANT_TERMS_OF_SERVICE,
  [SOFTWARE_COMPANY]: PLATFORM_TERMS_OF_SERVICE,
  [MARKETPLACE]: PLATFORM_TERMS_OF_SERVICE,
}

// platform constants -- available enums in the platform_type field in dashboard customization
export const FLEX = 'FLEX'
export const PUSH_TO_CARD = 'PUSH_TO_CARD'
export const PAYFAC = 'PAYFAC'
export const UNDERWRITING = 'UNDERWRITING'
export const PAYFAC_WITH_UNDERWRITING = 'PAYFAC_WITH_UNDERWRITING'
