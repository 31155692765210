import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationRefunds from './ApplicationRefunds'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { REVERSAL } from 'constants/transferConstants'
import { REFUND } from 'constants/language/languageConstants'
import { REFUNDS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  DEVICE_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter(REFUND),
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const refundsPath = REFUNDS_PATH({ credentialId })

  const initialQueries = {
    offset: 0,
    application_id: applicationId,
    type: REVERSAL,
  }

  return {
    applicationId,
    isFetching,
    refundsPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: createdLastMonthQueries(),
  }
}

class ApplicationRefundsC extends Component {
  render() {
    return (
      <ApplicationRefunds {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationRefundsC)
