import co from 'co'
import getPaymentMethodConfigurationAPI from 'api/finix/get/getPaymentMethodConfigurationAPI'
import patchPaymentMethodConfigurationsAPI from 'api/finix/patch/patchPaymentMethodConfigurationsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  APPLE_PAY,
  UNREGISTER,
} from 'constants/paymentMethodConstants'

function * patchPaymentMethodConfigurationsO ({
  values,
  credentials,
  meta,
}) {
  const [
    type,
    actionType,
  ] = getMany(meta, [
    'type',
    'actionType',
  ])

  // unregister merchant domain from apple pay
  if (type === APPLE_PAY && actionType === UNREGISTER) {
    const [
      domainToUnregister,
      paymentMethodConfigId,
      identityId,
    ] = getMany(values,
      [
        'domainToUnregister',
        'paymentMethodConfigId',
        'identityId',
      ])

    const domains = [
      {
        name: domainToUnregister,
        enabled: false,
      },
    ]

    yield patchPaymentMethodConfigurationsAPI({
      id: paymentMethodConfigId,
      values: {
        domains,
      },
      credentials,
      meta,
    })

    const response = yield getPaymentMethodConfigurationAPI({
      credentials,
      queries: { merchant_identity: identityId },
    })

    return createSuccessResponse({ data: get(response, 'data') })
  }

  return createErrorResponse({})
}

export default co.wrap(patchPaymentMethodConfigurationsO)
