import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import PurchaseStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PurchaseStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { createdAtRange } from 'utilities/table/sort'

import {
  ID,
  CREATED_ON,
  BUYER,
  AMOUNT,
  STATE,
  EMAIL,
  PAYMENT_INSTRUMENT,
  MASKED_NUMBER,
  BRAND_SLASH_TYPE,
  INSTRUMENT_TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(BUYER, 'fullName', {
    className: 'name',
  }),
  column(EMAIL, 'email', {
    className: 'name',
  }),
  column(AMOUNT, ['transfer.amount', 'transfer.currency'], {
    className: 'amount divider',
    formatter: ({ transfer = {}, currency }) => {
      const { displayAmount } = transfer

      return <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />
    },
  }),
  column(STATE, 'state', {
    className: 'state',
    formatter: ({ state }) => <PurchaseStatus value={state} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return (
        <TableItemSubtitle
          title={name}
          subtitle={maskedAccountNumber || maskedFullCardNumber}
        />
      )
    },
    className: 'normal',
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
]

export default columnDescriptors
