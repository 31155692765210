import React from 'react'
import PropTypes from 'prop-types'
import CancelSubscriptionFormC from 'components/Customer/Forms/CancelSubscriptionForm/CancelSubscriptionFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { endsWith } from 'lodash'

const CancelSubscriptionModal = ({
  subscriptionId = '',
  customerName = '',
  amount = '',
}) => {
  const endsWithS = endsWith(customerName, 's')

  return (
    <GenericModal
      title={`Cancel ${customerName}${endsWithS ? '\'' : '\'s'} subscription?`}
      className='CancelSubscriptionModal'
      Component={CancelSubscriptionFormC}
      customerName={customerName}
      subscriptionId={subscriptionId}
      amount={amount}
    />
  )
}

CancelSubscriptionModal.propTypes = {
  subscriptionId: PropTypes.string,
  customerName: PropTypes.string,
  amount: PropTypes.string,
}

export default CancelSubscriptionModal
