import toNumber from 'lodash/toNumber'

const appendSuffix = ({ num }) => {
  if (!num) {
    return num
  }

  const normalizedNum = toNumber(num)

  if (normalizedNum % 100 >= 10 && normalizedNum % 100 <= 20) {
    return `${num}th`
  }

  switch (normalizedNum % 10) {
    case 1:
      return `${num}st`
    case 2:
      return `${num}nd`
    case 3:
      return `${num}rd`
    default:
      return `${num}th`
  }
}

export default appendSuffix
