import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityMerchantDeposits from './IdentityMerchantDeposits'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getUrlQuery from 'utilities/get/getUrlQuery'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { MERCHANT_DEPOSITS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  SUCCEEDED,
} from 'constants/transferConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transaction'),
  ...TAG_FILTERS,
], 'label')

const hiddenExportFields = [
  'cardType',
  'cardBrand',
  'cardBin',
  'cardLastFour',
  'cardExpirationMonth',
  'cardExpirationYear',
  'cardAddressLineOne',
  'cardAddressLineTwo',
  'cardAddressCity',
  'cardAddressPostalCode',
  'cardAddressCountry',
  'cardAddressVerification',
  'cardSecurityCodeVerification',
  'readyToSettleAt',
]

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const merchantDepositsPath = MERCHANT_DEPOSITS_PATH({ credentialId })
  const isSucceededQuickFilter = getUrlQuery('state') === SUCCEEDED
  const hiddenSucceededFields = isSucceededQuickFilter ? ['failureCode'] : []

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    custom_transfer_type: 'settlement_transfers',
    merchant_identity_id: identityId,
  }

  return {
    quickFilters,
    allowedFilters,
    initialQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    merchantDepositsPath,
    identityId,
  }
}

class IdentityMerchantDepositsC extends Component {
  render() {
    return (
      <IdentityMerchantDeposits {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityMerchantDepositsC)
