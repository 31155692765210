import './AddressS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import toUpper from 'lodash/toUpper'

const Address = ({
  address = {},
  emptyLabel = '-',
}) => {
  const {
    line1 = '',
    line2 = '',
    city = '',
    region = '',
    postalCode = '',
    country = '',
    street = '',
  } = address

  if (!isEmpty(address)) {
    return (
      <div className='Address'>
        <p>{line1}</p>
        <p>{line2}</p>
        {street && <p>{street}</p>}
        <p>{city}, {toUpper(region)} {postalCode}</p>
        <p>{toUpper(country)}</p>
      </div>
    )
  }

  return emptyLabel
}

Address.propTypes = {
  address: PropTypes.object,
  emptyLabel: PropTypes.string,
}

export default Address
