import './ResendTransferFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import validateResendTransferForm from 'utilities/validate/validateResendTransferForm'
import { RESEND_FUNDING_TRANSFER_FORM } from 'constants/formConstants'

import {
  SEND,
  CANCEL,
  PAYMENT_INSTRUMENT,
} from 'constants/language/languageConstants'

const ResendTransferForm = ({
  handleSubmit = () => {},
  settlementPaymentInstrumentOptions = [],
  closeModal = () => {},
  submitting = false,
  pristine = false,
}) => {
  return (
    <form className='ResendTransferForm' onSubmit={handleSubmit}>
      <div className='modal-content'>
        <Field
          name='paymentInstrumentId'
          label={PAYMENT_INSTRUMENT}
          component={ReactSelect}
          options={settlementPaymentInstrumentOptions}
        />
      </div>
      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={SEND} className='submit-button' disabled={submitting || pristine} />
      </div>
    </form>
  )
}

ResendTransferForm.propTypes = {
  handleSubmit: PropTypes.func,
  settlementPaymentInstrumentOptions: PropTypes.array,
  closeModal: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: RESEND_FUNDING_TRANSFER_FORM,
  validate: validateResendTransferForm,
})(ResendTransferForm)
