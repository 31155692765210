import postFileToCustomerUploadedAssetsO from 'state-layer/orchestration/post/postFileToCustomerUploadedAssetsO'
import postDashboardCustomizationAPI from 'api/dashboard/post/postDashboardCustomizationAPI'
import backendDashboardCustomizationModel from 'utilities/create/models/backendDashboardCustomizationModel'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getMany from 'utilities/get/getMany'
import isString from 'lodash/isString'
import merge from 'lodash/merge'
import co from 'co'

function * postDashboardCustomizationO ({
  values,
  credentials,
}) {
  let dashboardSidebarLogoImageUrl
  let onboardingLogoImageUrl
  let emailLogoImageUrl

  const [
    dashboardSidebarLogoFile,
    onboardingLogoFile,
    emailLogoFile,
    paymentLinkLogoFile,
  ] = getMany(values, [
    'dashboardSidebarLogo',
    'onboardingFormLogo',
    'emailLogo',
    'paymentLinkLogo',
  ])

  let paymentLinkLogoImageUrl = paymentLinkLogoFile

  if (dashboardSidebarLogoFile) {
    const { data: dashboardSidebarLogoImageUrlResponse } = yield postFileToCustomerUploadedAssetsO({
      credentials,
      file: dashboardSidebarLogoFile,
    })

    dashboardSidebarLogoImageUrl = dashboardSidebarLogoImageUrlResponse
  }

  if (onboardingLogoFile) {
    const { data: onboardingLogoImageUrlResponse } = yield postFileToCustomerUploadedAssetsO({
      credentials,
      file: onboardingLogoFile,
    })

    onboardingLogoImageUrl = onboardingLogoImageUrlResponse
  }

  if (emailLogoFile) {
    const { data: emailLogoImageUrlResponse } = yield postFileToCustomerUploadedAssetsO({
      credentials,
      file: emailLogoFile,
    })

    emailLogoImageUrl = emailLogoImageUrlResponse
  }

  // if it's a string and not File, we don't expect the users to have uploaded new image
  if (paymentLinkLogoFile && !isString(paymentLinkLogoFile)) {
    const { data: paymentLinkLogoImageUrlResponse } = yield postFileToCustomerUploadedAssetsO({
      credentials,
      file: paymentLinkLogoFile,
    })

    paymentLinkLogoImageUrl = paymentLinkLogoImageUrlResponse
  }

  const valuesToSubmit = merge({}, values, { dashboardSidebarLogoImageUrl, onboardingLogoImageUrl, emailLogoImageUrl, paymentLinkLogoImageUrl })

  // run through BE model
  const normalizedValuesToSubmit = backendDashboardCustomizationModel({ values: valuesToSubmit })

  const { data: dashboardCustomizationResponse } = yield postDashboardCustomizationAPI({
    credentials,
    values: normalizedValuesToSubmit,
  })

  return createSuccessResponse({
    data: dashboardCustomizationResponse,
  })
}

export default co.wrap(postDashboardCustomizationO)
