import { GET_APPLICATION_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getApplicationRequest = ({
  applicationId,
  credentials,
}) => createAction({
  type: GET_APPLICATION_F_REQUEST,
  id: applicationId,
  credentials,
})

export default getApplicationRequest
