import { validateTruthyValue } from 'utilities/validate'
import { TERMS_OF_SERVICE_ACCEPTANCE_VALIDATION_ERROR } from 'constants/language/languageConstants'

const validateLiveAccountApplicationReviewAndSubmitForm = (values) => {
  const {
    agreementAccepted,
    inquiryId,
  } = values

  return {
    agreementAccepted: validateTruthyValue({ field: agreementAccepted, name: 'Terms of Service', customErrorMessage: TERMS_OF_SERVICE_ACCEPTANCE_VALIDATION_ERROR }),
    inquiryId: inquiryId ? false : 'Personal Identity Verification is required.',
  }
}

export default validateLiveAccountApplicationReviewAndSubmitForm
