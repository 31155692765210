import React from 'react'
import PropTypes from 'prop-types'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import getMany from 'utilities/get/getMany'
import { RECIPIENT_IDENTITY_ROLE } from 'constants/identityConstants'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

const TransferMerchantApplicationColumn = ({
  transfer = {},
  titleBadge = '',
}) => {
  const [
    identityBusinessName,
    displayIdentityBusinessName,
    merchantIdentityBusinessName,
    displayMerchantIdentityBusinessName,
    merchantIdentityName,
    applicationName,
  ] = getMany(transfer, [
    'identity.businessName',
    'identity.displayBusinessName',
    'merchantIdentity.displayBusinessName',
    'merchantIdentity.businessName',
    'merchantIdentity.fullName',
    'application.businessName',
  ])

  const titleText = (identityBusinessName && displayIdentityBusinessName) || (merchantIdentityBusinessName && displayMerchantIdentityBusinessName) || merchantIdentityName

  const title = !isEmpty(titleBadge) ?
    <div className='flex items-center'>{titleText}<div className='badge'>{titleBadge}</div></div>
    : titleText

  return (
    <div className='TransferMerchantApplicationColumn'>
      <TableItemSubtitle
        title={title}
        subtitle={applicationName}
      />
    </div>
  )
}

TransferMerchantApplicationColumn.propTypes = {
  transfer: PropTypes.object,
  titleBadge: PropTypes.string,
}

export default TransferMerchantApplicationColumn
