import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  GET_UNMASKED_ITEM_F_REQUEST,
  GET_UNMASKED_ITEM_F_SUCCESS,
  GET_UNMASKED_ITEM_F_FAILURE,
  DELETE_UNMASKED_ITEM,
  DELETE_UNMASKED_ITEMS,
} from 'constants/flowConstants'

const unmaskedItemsR = (state = {}, action = {}) => {
  const payload = get(action, 'payload', {})

  const [
    id,
    feKey,
    beKey,
  ] = getMany(payload, [
    'id',
    'feKey',
    'beKey',
  ])

  const newValueName = `${id}_${feKey}`
  const newValue = get(action, `payload.newValues.${beKey}`)

  switch (action.type) {
    case GET_UNMASKED_ITEM_F_REQUEST:
      return merge({}, state, {
        items: {
          [newValueName]: {
            isFetching: true,
          },
        },
      })

    case GET_UNMASKED_ITEM_F_SUCCESS:
      return merge({}, state, {
        items: {
          [newValueName]: {
            value: newValue,
            isFetching: false,
          },
        },
      })

    case GET_UNMASKED_ITEM_F_FAILURE:
      return merge({}, state, {
        items: {
          [newValueName]: {
            value: null,
            isFetching: false,
          },
        },
      })

    case DELETE_UNMASKED_ITEM:
      return merge({}, omit(state, `items.${newValueName}`))

    case DELETE_UNMASKED_ITEMS:
      return {}

    default:
      return state
  }
}

export default unmaskedItemsR
