import './VerificationResultsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import VerificationResults from 'components/Customer/Shared/Display/VerificationResults/VerificationResults'
import { VERIFICATION_RESULT } from 'constants/language/languageConstants'

const VerificationResultsModal = ({
  verification,
}) => {
  return (
    <GenericModal
      title={VERIFICATION_RESULT}
      className='VerificationResultsModal'
      Component={VerificationResults}
      verification={verification}
    />
  )
}

VerificationResultsModal.propTypes = {
  verification: PropTypes.object,
}

export default VerificationResultsModal
