import formatMoney from 'utilities/formatters/formatMoney'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import { USD } from 'constants/currencyConstants'
import map from 'lodash/map'
import numeral from 'numeral'

import {
  TAG_REGEX,
  WEBSITE_REGEX,
} from 'constants/regexConstants'

import {
  NAME,
  QUANTITY,
  UNIT_PRICE,
} from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  invalidRegex,
  nonEmptyInteger,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreatePayoutLinkForm = (values, props) => {
  const {
    maxACHTransactionAmount,
    maxTransactionAmount,
  } = props

  const {
    items,
    allowPaymentCard,
    allowBankTransfer,
    termsOfServiceUrl,
    customFields,
    currency = USD,
  } = values

  const itemsValidation = map(items, (item) => {
    const {
      name,
      amount,
      primaryImageUrl,
      quantity,
    } = item

    const maxTransactionValue = displayAmountToAmount(maxTransactionAmount, currency)
    const maxACHTransactionValue = displayAmountToAmount(maxACHTransactionAmount, currency)

    const normalizedMaxTransactionValue = (allowPaymentCard && allowBankTransfer)
      ? (maxTransactionValue > maxACHTransactionValue ? maxACHTransactionValue : maxTransactionValue)
      : (allowPaymentCard ? maxTransactionValue : maxACHTransactionValue)

    const normalizedMaxTransactionAmount = formatMoney({ amount: amountToDisplayAmount(normalizedMaxTransactionValue, currency), currency })
    const saleAmountGreaterThanMaxTransactionAmount = displayAmountToAmount(amount, currency) > normalizedMaxTransactionValue && `Unit price must be less than or equal to ${normalizedMaxTransactionAmount}`
    const amountLessThanZero = numeral(amount).value() <= 0 && 'Unit price must be greater than 0'


    return {
      name: fieldEmpty(name, NAME),
      amount: fieldEmpty(amount, UNIT_PRICE) || saleAmountGreaterThanMaxTransactionAmount || amountLessThanZero,
      primaryImageUrl: invalidRegex({ field: primaryImageUrl, name: 'URL', regex: WEBSITE_REGEX }),
      quantity: quantity ? (nonEmptyNumber({ field: quantity, name: QUANTITY }) || nonEmptyInteger({ field: quantity, name: QUANTITY })) : false,
    }
  })

  const customFieldErrors = map(customFields, ({ name: customFieldName = '' }) => {
    const customFieldEmpty = fieldEmpty(customFieldName, 'Custom Field Name')
    const customFieldNameTooLong = customFieldName.length > 40 ? 'Custom field names must be less than 40 characters long' : false
    const customFieldNameInvalid = invalidRegex({ field: customFieldName, name: 'Custom field name', regex: TAG_REGEX, customErrorMessage: 'Special characters , " \' \\ are not supported.' })

    return {
      name: customFieldEmpty || customFieldNameTooLong || customFieldNameInvalid,
    }
  })

  return {
    items: itemsValidation,
    allowPaymentCard: !(allowPaymentCard || allowBankTransfer) ? 'Please select at least one payment method' : undefined,
    allowBankTransfer: !(allowPaymentCard || allowBankTransfer) ? 'Please select at least one payment method' : undefined,
    termsOfServiceUrl: invalidOrEmptyRegex({ field: termsOfServiceUrl, name: 'Website', regex: WEBSITE_REGEX }),
    customFields: customFieldErrors,
  }
}

export default validateCreatePayoutLinkForm
