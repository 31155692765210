import co from 'co'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import paymentsAPI from 'utilities/api/paymentsAPI'

function * getPaymentsFileO ({
  url,
  credentials,
}) {
  const response = yield paymentsAPI.get({
    path: url,
    credentials,
  })

  return createSuccessResponse({
    data: response,
  })
}

export default co.wrap(getPaymentsFileO)
