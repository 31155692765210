import React, { Component } from 'react'
import { connect } from 'react-redux'
import Settlement from './Settlement'
import { getSettlementSelector } from 'state-layer/selectors'
import SettlementInfoC from 'components/Customer/Pages/Settlement/SettlementInfo/SettlementInfoC'
import SettlementEntriesC from 'components/Customer/Pages/Settlement/SettlementEntries/SettlementEntriesC'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import getSettlementGroupRequest from 'utilities/actions/get/getSettlementGroupRequest'
import getSettlementGroupSettlementsRequest from 'utilities/actions/get/getSettlementGroupSettlementsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import { ACCRUING } from 'constants/settlementConstants'
import { CLOSE_SETTLEMENT } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import { NON_MERCHANT_ROLES } from 'constants/roleConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
  REVIEW_QUEUE_SETTLEMENTS_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  CREATED_ON,
  EXCEPTION_SETTLEMENT,
  FEES_AMOUNT,
  MERCHANT_IDENTITY,
  NET_AMOUNT,
  PAYOUT_SETTLEMENT_SCHEDULE,
  PAYOUT_TYPE,
  REVIEW_SETTLEMENTS_RESOURCE_TITLE,
  SETTLEMENT,
  STATE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const isFetching = get(state, `settlementsR.${FETCHING}`)
  const settlementId = get(props, 'params.settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const hasPartnerAccess = includes(NON_MERCHANT_ROLES, role)

  const [
    settlementGroupId,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    status,
    displayCreatedAt,
    displayScheduleType,
    displayTotalAmount,
    currency,
    netAmount,
    payoutProfileDisplayType,
    exception,
    totalFees,
  ] = getMany(settlement, [
    'settlementGroupId',
    'applicationId',
    'applicationModel.businessName',
    'merchantIdentity.id',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'status',
    'displayCreatedAt',
    'displayScheduleType',
    'displayTotalAmount',
    'currency',
    'netAmount',
    'payoutProfile.displayType',
    'exception',
    'totalFees',
  ])

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })

  const showAction = hasPlatformAccess({ credentials }) && status === ACCRUING

  const tabs = [
    {
      id: 'general-info',
      name: 'General Info',
      component: SettlementInfoC,
      props: {
        settlementId,
      },
    },
    {
      id: 'entries',
      name: 'Entries',
      component: SettlementEntriesC,
      props: {
        settlementId,
      },
    },
  ]

  const headerResourceTitle = exception ? EXCEPTION_SETTLEMENT : SETTLEMENT
  const contextBarData = {
    back: {
      label: REVIEW_SETTLEMENTS_RESOURCE_TITLE,
      path: REVIEW_QUEUE_SETTLEMENTS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
      },
      {
        label: MERCHANT_IDENTITY,
        value: merchantBusinessName,
        path: IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: headerResourceTitle,
      id: settlementId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayTotalAmount} currency={currency} />,
      },
      {
        label: FEES_AMOUNT,
        value: formatMoney({ amount: totalFees, currency, displayNegativeAmount: true, showCurrencyCode: true }),
      },
      {
        label: NET_AMOUNT,
        value: formatMoney({ amount: netAmount, currency, showCurrencyCode: true }),
      },
      {
        label: STATE,
        value: <SettlementStatus value={status} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: PAYOUT_SETTLEMENT_SCHEDULE,
        value: displayScheduleType,
      },
      {
        label: PAYOUT_TYPE,
        value: payoutProfileDisplayType,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    settlementId,
    settlement,
    credentials,
    tabs,
    showAction,
    settlementGroupId,
    hasPartnerAccess,
    contextBarData,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSettlement: ({ settlementId, credentials }) => dispatch(getSettlementRequest({ settlementId, credentials })),
    showCloseSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: CLOSE_SETTLEMENT, modalProps })),
    getSettlementGroup: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupRequest({ credentials, settlementGroupId })) },
    getSettlementGroupSettlements: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupSettlementsRequest({ credentials, settlementGroupId })) },
  }
}

class SettlementC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fetchedSettlementGroup: false,
    }
  }

  componentDidMount() {
    const { settlementId, getSettlement, credentials } = this.props

    getSettlement({ settlementId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { settlementId: prevSettlementId } = prevProps
    const { settlementId, settlementGroupId, getSettlement, credentials, hasPartnerAccess } = this.props
    const { fetchedSettlementGroup } = this.state

    if (prevSettlementId !== settlementId) {
      getSettlement({ settlementId, credentials })
    }

    if (hasPartnerAccess && settlementGroupId && (!fetchedSettlementGroup || prevSettlementId !== settlementId)) {
      this.fetchSettlementGroup()

      this.setState({ fetchedSettlementGroup: true })
    }
  }

  componentWillUnmount() {
    this.setState({ fetchedSettlementGroup: false })
  }

    fetchSettlementGroup = () => {
      const {
        settlementGroupId,
        credentials,
        getSettlementGroup,
        getSettlementGroupSettlements,
      } = this.props

      getSettlementGroup({ credentials, settlementGroupId })
      getSettlementGroupSettlements({ credentials, settlementGroupId })
    }

    render() {
      const {
        settlementId,
        credentials,
        showCloseSettlementModal,
        showAction,
      } = this.props

      const actions = showAction ? [
        {
          label: 'Stop Accruing',
          action: () => {
            showCloseSettlementModal({ settlementId, credentials })
          },
        },
      ] : []

      return (
        <Settlement
          actions={actions}
          {...this.props}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementC)
