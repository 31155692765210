import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  VERIFICATION,
  VERIFICATIONS,
} from 'constants/apiConstants'

const getVerificationAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: VERIFICATION({ verificationId: id }),
    credentials,
    service: VERIFICATIONS,
  })
}

export default getVerificationAPI
