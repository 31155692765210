import { POST_PAYOUT_PLAN_F_REQUEST } from 'constants/flowConstants'
import { PAYOUT_PLAN_PATH } from 'constants/pathConstants'
import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import { CREATE_PAYOUT_PLAN } from 'constants/amplitudeConstants'

const submitPayoutPlanForms = (values, dispatch, credentials) => {
  const { name, platformId, processor } = values

  dispatch({
    type: POST_PAYOUT_PLAN_F_REQUEST,
    payload: {
      values,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const payoutPlanId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const payoutPlanPath = PAYOUT_PLAN_PATH({ payoutPlanId, credentialId })

        dispatch(redirectRequest({ path: payoutPlanPath }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_PAYOUT_PLAN, {
        credentials,
        name,
        platformId,
        processor,
      }),
    },
  })
}

export default submitPayoutPlanForms
