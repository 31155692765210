import './EditPayoutSettingsWarningModalS.scss'
import React from 'react'
import { Link } from 'react-router'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'
import { PAYOUT_SETTING_PATH } from 'constants/pathConstants'
import { CONTINUE } from 'constants/language/languageConstants'
import PropTypes from 'prop-types'

const EditPayoutSettingsWarningModal = ({
  credentialId = '',
  payoutSettingsId = '',
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title='This Merchant has active Settlements'
      className='EditPayoutSettingsWarningModal'
      Component={() => (
        <>
          <div>Any updates or changes made to this Merchant&apos;s payout settings will immediately go into effect after saving. Any active Settlements accruing payout funds or fees will automatically close. Are you sure you would like to continue?</div>

          <div className='buttons flex flex-end' onClick={closeModal}>
            <Link to={PAYOUT_SETTING_PATH({ credentialId, payoutSettingsId })}>
              <Button label={CONTINUE} />
            </Link>
          </div>
        </>
      )}
    />
  )
}

EditPayoutSettingsWarningModal.propTypes = {
  credentialId: PropTypes.string,
  payoutSettingsId: PropTypes.string,
  closeModal: PropTypes.func,
}

export default EditPayoutSettingsWarningModal
