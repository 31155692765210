import createAction from 'utilities/actions/createAction'
import { GET_CATEGORIZED_REPORTS_F_REQUEST } from 'constants/flowConstants'

const getCategorizedReportsRequest = ({
  credentials,
  queries,
}) => createAction({
  type: GET_CATEGORIZED_REPORTS_F_REQUEST,
  credentials,
  queries,
})

export default getCategorizedReportsRequest
