import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingRecipient from 'components/Customer/Pages/UnderwritingRecipient/UnderwritingRecipient'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import UnderwritingAssignee from 'components/Customer/Shared/Display/UnderwritingAssignee/UnderwritingAssignee'
import { underwritingNoteTemplates } from 'constants/noteConstants'
import Notes from 'components/Customer/Pages/Notes/Notes'
import RecipientUnderwritingReviewC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReview/RecipientUnderwritingReview/RecipientUnderwritingReviewC'
import IdentityMerchantsC from 'components/Customer/Pages/Identity/IdentityMerchants/IdentityMerchantsC'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import patchUnderwritingReviewRequest from 'utilities/actions/patch/patchUnderwritingReviewRequest'
import getUnderwritingReviewRequest from 'utilities/actions/get/getUnderwritingReviewRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import hasPermission from 'utilities/hasPermission'
import hasPermissions from 'utilities/hasPermissions'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import { REVIEW_QUEUE_UPDATE_PERMISSION } from 'constants/permissionConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { FETCHING } from 'constants/reducerConstants'
import { ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  ASSIGNEE,
  UPDATED_ON,
  STATUS,
  RECIPIENT_UNDERWRITING,
  APPLICATION_RESOURCE_TITLE,
  RECIPIENT_IDENTITY,
  CASE_NOTES,
  REVIEW,
  COUNTRY,
  MERCHANT_ACCOUNTS,
  UW_CASE_NOTE_EMPTY_MSG,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const reviewId = get(props, 'params.reviewId')
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const review = getUnderwritingReviewSelector(state, reviewId)
  const hasUpdatePermission = hasPermission(state, REVIEW_QUEUE_UPDATE_PERMISSION)
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)

  const [
    displayUpdatedOn,
    outcome,
    assignee,
    applicationName,
    applicationId,
    identityId,
    fullName,
    recipientName,
    recipientType,
    country,
  ] = getMany(review, [
    'displayUpdatedOn',
    'state',
    'assignee',
    'entity.application.businessName',
    'entity.application.id',
    'entity.id',
    'entity.fullName',
    'recipientName',
    'recipientType',
    'country',
  ])

  const {
    subjectId,
    entityType,
    entityId,
  } = parseUrlQueries()

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: RECIPIENT_IDENTITY,
        value: fullName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
      },
    ],
    isFetching,
  }

  const tabs = [
    {
      id: 'review',
      name: REVIEW,
      component: RecipientUnderwritingReviewC,
      props: {
        reviewId,
        subjectId,
        entityId,
      },
    },
    {
      id: 'merchant-accounts',
      name: MERCHANT_ACCOUNTS,
      component: IdentityMerchantsC,
      props: {
        identityId,
      },
    },
    {
      id: 'recipient-case-notes',
      name: CASE_NOTES,
      component: Notes,
      props: {
        linkedTo: reviewId,
        identityId,
        templates: underwritingNoteTemplates,
        emptyMessage: UW_CASE_NOTE_EMPTY_MSG,
        showAddNewNote: hasPermissions([REVIEW_QUEUE_UPDATE_PERMISSION]),
      },
    },
  ]

  return {
    isFetching,
    credentials,
    reviewId,
    subjectId,
    entityType,
    entityId,
    assignee,
    hasUpdatePermission,
    currentUserEmail,
    outcome,
    recipientName,
    recipientType,
    displayUpdatedOn,
    contextBarData,
    tabs,
    identityId,
    country,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnderwritingReview: ({ subjectId, reviewId, entityType, entityId, credentials }) => dispatch(getUnderwritingReviewRequest({ subjectId, reviewId, entityType, entityId, credentials })),
    patchUnderwritingReview: (actionProps) => dispatch(patchUnderwritingReviewRequest(actionProps)),
    showAssignToMeModal: (modalProps) => dispatch(showModalAction({ modalType: ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL, modalProps })),
  }
}

class UnderwritingRecipientC extends Component {
  componentDidMount() {
    const {
      reviewId,
      subjectId,
      entityType,
      entityId,
      credentials,
      getUnderwritingReview,
    } = this.props

    getUnderwritingReview({
      subjectId,
      reviewId,
      entityType,
      entityId,
      credentials,
    })
  }

  render() {
    const {
      reviewId,
      subjectId,
      hasUpdatePermission,
      outcome,
      showAssignToMeModal,
      assignee,
      isFetching,
      currentUserEmail,
      recipientName,
      recipientType,
      displayUpdatedOn,
      country,
    } = this.props

    const headerData = {
      resource: {
        label: RECIPIENT_UNDERWRITING,
        id: reviewId,
        badge: recipientType,
      },
      items: [
        {
          value: <h1>{recipientName}</h1>,
        },
        {
          label: STATUS,
          value: <UnderwritingReviewStatus value={outcome} />,
        },
        {
          label: UPDATED_ON,
          value: displayUpdatedOn,
        },
        {
          label: COUNTRY,
          value: CountryFlagIcon({ country }) || '-',
        },
        {
          label: ASSIGNEE,
          value: <UnderwritingAssignee outcome={outcome} assignee={assignee} hasUpdatePermission={hasUpdatePermission} showAssignToMeModal={showAssignToMeModal} reviewId={reviewId} subjectId={subjectId} currentUserEmail={currentUserEmail} />,
        },
      ],
      isFetching,
    }

    return (
      <UnderwritingRecipient
        {...this.props}
        headerData={headerData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingRecipientC)
