import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import getDisputeRequest from 'utilities/actions/get/getDisputeRequest'
import { SUBMIT_DISPUTE_EVIDENCE } from 'constants/amplitudeConstants'
import { POST_SUBMIT_DISPUTE_EVIDENCE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'


const submitDisputeEvidenceForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const disputeId = get(props, 'disputeId')

  const [
    representmentAmount,
    summary,
  ] = getMany(values, [
    'representmentAmount',
    'summary',
  ])

  const formValues = {
    note: summary,
  }

  dispatch({
    type: POST_SUBMIT_DISPUTE_EVIDENCE_F_REQUEST,
    payload: {
      id: disputeId,
      values: formValues,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_DISPUTE_EVIDENCE, {
        credentials,
        summary,
        disputeId,
      }),
      successCallback: () => {
        dispatch(getDisputeRequest({ disputeId, credentials }))
      },
    },
  })
}

export default submitDisputeEvidenceForm
