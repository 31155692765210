import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  DISPUTES,
  FINIX_VERSION,
} from 'constants/apiConstants'

const getDisputesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: DISPUTES,
    queries,
    credentials,
    service: DISPUTES,
    headers: {
      [FINIX_VERSION]: '2022-02-01',
    },
  })
}

export default getDisputesAPI
