import {
  fieldEmpty,
} from 'utilities/validate'

const validateContactSupportForm = (values) => {
  const {
    subject,
    message,
  } = values

  return {
    subject: fieldEmpty(subject, 'Subject'),
    message: fieldEmpty(message, 'Message'),
  }
}

export default validateContactSupportForm
