import selectOption from 'utilities/forms/selectOption'

import {
  CHECK_CIRCLE_ICON,
  TIMES_CIRCLE_ICON,
  CIRCLE_MINUS_ICON,
  CLIPBOARD_CHECK_ICON,
} from 'constants/iconConstants'

import {
  FAILED,
  SUCCEEDED,
  PENDING,
  CANCELED,
} from 'constants/transferConstants'

import {
  ALL,
  ACTIVE as ACTIVE_LABEL,
  COMPLETED as COMPLETED_LABEL,
  EXPIRED as EXPIRED_LABEL,
  DEACTIVATED as DEACTIVATED_LABEL,
  ALL as ALL_STRING,
  SUCCEEDED as SUCCEEDED_STRING,
  FAILED as FAILED_STRING,
  PENDING_STRING,
  CANCELED as CANCELED_STRING,
} from 'constants/language/languageConstants'

export const PAYMENT_CARD = 'PAYMENT_CARD'
export const BANK_ACCOUNT = 'BANK_ACCOUNT'

export const ALLOWED_PAYMENT_METHODS = [PAYMENT_CARD, BANK_ACCOUNT]

export const PAYMENT_METHOD_TYPE_TO_LABEL_MAP = {
  [PAYMENT_CARD]: 'Card',
  [BANK_ACCOUNT]: 'Bank Account',
}

export const ACTIVE = 'ACTIVE'
export const COMPLETED = 'COMPLETED'
export const EXPIRED = 'EXPIRED'
export const DEACTIVATED = 'DEACTIVATED'
export const AWAITING_PAYOUT = 'AWAITING_PAYOUT' // this is a status for payout links

export const PAYMENT_LINK_STATUS_LABEL_MAP = {
  [ACTIVE]: ACTIVE_LABEL,
  [EXPIRED]: EXPIRED_LABEL,
  [COMPLETED]: COMPLETED_LABEL,
  [DEACTIVATED]: DEACTIVATED_LABEL,
}

export const paymentLinkStatusIconMap = {
  [ACTIVE]: CHECK_CIRCLE_ICON,
  [EXPIRED]: TIMES_CIRCLE_ICON,
  [COMPLETED]: CLIPBOARD_CHECK_ICON,
  [DEACTIVATED]: CIRCLE_MINUS_ICON,
}

export const PAYMENT_LINK_STATUS_OPTIONS = [
  selectOption(ALL, ''),
  selectOption(ACTIVE_LABEL, ACTIVE),
  selectOption(EXPIRED_LABEL, EXPIRED),
  selectOption(COMPLETED_LABEL, COMPLETED),
  selectOption(DEACTIVATED_LABEL, DEACTIVATED),
]

export const PAYMENT_LINK_PAYMENTS_OPTIONS = [
  selectOption(ALL_STRING, ''),
  selectOption(SUCCEEDED_STRING, SUCCEEDED),
  selectOption(FAILED_STRING, FAILED),
  selectOption(PENDING_STRING, PENDING),
  selectOption(CANCELED_STRING, CANCELED),
]
