import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { PROCEED, CANCEL } from 'constants/language/languageConstants'

const ExitThisFlow = ({
  redirectUserToRecipientsPage = () => {},
  redirectUserToPaymentInstrumentsPage = () => {},
  closeModal = () => {},
  isRecipientFlow = true,
}) => {
  return (
    <div className='ExitThisFlow'>
      <div className='modal-content'>
        <div>You will be taken out of this flow and routed to a new page where you can add {isRecipientFlow ? 'recipients.' : 'payment instruments.'}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button label={CANCEL} onClick={closeModal} variant='ghost' />
        <Button label={PROCEED} onClick={isRecipientFlow ? redirectUserToRecipientsPage : redirectUserToPaymentInstrumentsPage} />
      </div>
    </div>
  )
}

ExitThisFlow.propTypes = {
  redirectUserToRecipientsPage: PropTypes.func,
  redirectUserToPaymentInstrumentsPage: PropTypes.func,
  closeModal: PropTypes.func,
  isRecipientFlow: PropTypes.bool,
}

export default ExitThisFlow
