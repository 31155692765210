import createAction from 'utilities/actions/createAction'
import { GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_REQUEST } from 'constants/flowConstants'

const getSubscriptionScheduleEnrollmentsRequest = ({
  credentials,
  queries,
  meta,
}) => {
  return createAction({
    type: GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_REQUEST,
    credentials,
    queries,
    meta,
  })
}

export default getSubscriptionScheduleEnrollmentsRequest
