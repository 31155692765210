import { getState } from 'state-layer/configureStore'
import { getApplicationSelector } from 'state-layer/selectors'
import getCurrentPlatformId from 'utilities/get/getCurrentPlatformId'
import { APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE } from 'constants/selectedItemsConstants'
import { PLATFORM } from 'constants/payoutPlanConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'

const ComplianceApplyTemplateDisplayFields = [
  {
    name: 'typeApplied',
    label: 'Type',
    formatter: (typeApplied) => capitalize(typeApplied),
  },
  {
    name: 'typeApplied',
    label: 'ID',
    formatter: (typeApplied) => {
      if (typeApplied === PLATFORM) return getCurrentPlatformId()

      const state = getState()
      const selectedApplications = get(state, `selectedItemsR.${APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE}`, {})
      const selectedApplication = find(Object.keys(selectedApplications), (application) => selectedApplications[application])
      const applicationName = get(getApplicationSelector(state, selectedApplication), 'businessName')

      return `${applicationName ? applicationName : ''} (${selectedApplication})`
    },
  },
]

const ComplianceApplyTemplateDisplayData = (values) => map(ComplianceApplyTemplateDisplayFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name)) : get(values, name),
}))

export default ComplianceApplyTemplateDisplayData
