import './AcceptLiabilityConfirmationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import submitAcceptLiabilityForm from 'utilities/submit/submitAcceptLiabilityForm'
import validateAcceptLiabilityConfirmationForm from 'utilities/validate/validateAcceptLiabilityConfirmationForm'
import { ALERT_ICON } from 'constants/iconConstants'
import { ACCEPT_LIABILITY_FORM } from 'constants/formConstants'

import {
  ACCEPT_LIABILITY_CONFIRMATION_MSG,
  ACCEPT_LIABILITY_WARNING,
  CANCEL,
  ACCEPT_LIABILITY,
  NOTE,
  ACCEPT_LIABILITY_CHECKBOX_STRING,
} from 'constants/language/languageConstants'

const AcceptLiabilityConfirmationForm = ({
  closeModal = () => {},
  handleSubmit = () => {},
  invalid,
}) => {
  return (
    <form className='AcceptLiabilityConfirmationForm' onSubmit={handleSubmit(submitAcceptLiabilityForm)}>
      <div className='modal-content'>
        <div>{ACCEPT_LIABILITY_CONFIRMATION_MSG}</div>

        <Field
          name='note'
          label={NOTE}
          component={TextAreaField}
          required={false}
        />

        <div className='accept-liability-warning flex items-center'>
          <i className={`fa fa-${ALERT_ICON}`} />
          <div>{ACCEPT_LIABILITY_WARNING}</div>
        </div>

        <Field
          checkboxLabel={ACCEPT_LIABILITY_CHECKBOX_STRING}
          component={CheckboxInput}
          name='acceptLiability'
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={ACCEPT_LIABILITY} disabled={invalid} />
      </div>
    </form>
  )
}

AcceptLiabilityConfirmationForm.propTypes = {
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: ACCEPT_LIABILITY_FORM,
  validate: validateAcceptLiabilityConfirmationForm,
})(AcceptLiabilityConfirmationForm)
