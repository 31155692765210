import frontendExportModel from 'utilities/create/models/frontendExportModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendExportsModel = ({ data: exports }) => {
  const exportModels = map(exports, (exportFile) => frontendExportModel({ data: exportFile }))

  return keyBy(exportModels, 'id')
}

export default frontendExportsModel
