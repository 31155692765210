import './ExportsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { GET_EXPORTS } from 'constants/flowConstants'
import { getExportsSelector } from 'state-layer/selectors'
import { EXPORT_ICON } from 'constants/iconConstants'

import {
  EXPORTS,
  EXPORTS_TABLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Exports = ({
  initialQueries = {},
  isFetching = false,
}) => {
  return (
    <div className='Exports'>
      <HeaderV2C
        title={EXPORTS}
        subTitle={EXPORTS_TABLE_DESCRIPTION}
      />
      <TableC
        flow={GET_EXPORTS}
        columnDescriptors={columnDescriptors}
        selector={getExportsSelector}
        initialQueries={initialQueries}
        isFetching={isFetching}
        icon={`fa fa-${EXPORT_ICON}`}
        emptyMessage='No Exports Available'
      />
    </div>
  )
}

Exports.propTypes = {
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default Exports
