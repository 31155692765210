import './ApplicationPayoutConfigurationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'

import {
  MASTERCARD_CONFIGURATION,
  VISA_CONFIGURATION,
  PAYOUT_PROFILE,
  BALANCE_ACCOUNT,
} from 'constants/language/languageConstants'

const ApplicationPayoutConfiguration = ({
  masterCardConfigDetails = [],
  visaConfigDetails = [],
  visaEditAction = [],
  masterCardEditAction = [],
  payoutProfileDetails = [],
  payoutProfileEditAction = [],
  accountBalanceDetails = [],
  clickToCopies = [],
  isFetching = true,
}) => {
  return (
    <div className='ApplicationPayoutConfiguration'>
      {isFetching && <Loader />}
      {!isFetching && (
        <div>
          <PageSectionV2C
            title={PAYOUT_PROFILE}
            columns={payoutProfileDetails}
            actions={payoutProfileEditAction}
          />
          <PageSectionV2C
            title={BALANCE_ACCOUNT}
            columns={accountBalanceDetails}
            clickToCopies={clickToCopies}
          />
          <PageSectionV2C
            title={VISA_CONFIGURATION}
            columns={visaConfigDetails}
            actions={visaEditAction}
          />
          <PageSectionV2C
            title={MASTERCARD_CONFIGURATION}
            columns={masterCardConfigDetails}
            actions={masterCardEditAction}
          />
        </div>
      )}
    </div>
  )
}

ApplicationPayoutConfiguration.propTypes = {
  masterCardConfigDetails: PropTypes.array,
  visaConfigDetails: PropTypes.array,
  visaEditAction: PropTypes.array,
  masterCardEditAction: PropTypes.array,
  payoutProfileDetails: PropTypes.array,
  payoutProfileEditAction: PropTypes.array,
  accountBalanceDetails: PropTypes.array,
  clickToCopies: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default ApplicationPayoutConfiguration
