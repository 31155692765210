import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { getIdentitySelector } from 'state-layer/selectors'
import IdentityForm from 'components/Customer/Forms/IdentityForm/IdentityForm'
import Loader from 'components/Shared/Loader/Loader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitIdentityForm from 'utilities/submit/submitIdentityForm'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import formatIdentityInitialValues from 'utilities/formatters/formatIdentityInitialValues'
import isPlatform from 'utilities/is/isPlatform'
import { FETCHING } from 'constants/reducerConstants'
import { PATCH_IDENTITY } from 'constants/flowConstants'
import { IDENTITY_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  getIdentityActions,
  BUSINESS_TYPE_OPTIONS,
  LLC_DISREGARDED_OPTION,
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
} from 'constants/identityConstants'

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'params.identityId')
  const credentials = getCurrentCredentials(state)
  const onSubmit = get(props, 'onSubmit', submitIdentityForm)
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const identity = getIdentitySelector(state, identityId)
  const applicationId = get(identity, 'applicationId')
  const formSelector = formValueSelector(IDENTITY_FORM)
  const mcc = formSelector(state, 'mcc')
  const showTaxAuthority = mcc === '9311'

  const isElavon = isPlatform('elavonpartnerconnect')
  const businessTypeOptions = isElavon ? [...BUSINESS_TYPE_OPTIONS, LLC_DISREGARDED_OPTION] : BUSINESS_TYPE_OPTIONS

  const businessType = formSelector(state, 'businessType')
  const hideOwnershipTypeDropdown = includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)

  return {
    actionType: getIdentityActions(PATCH_IDENTITY),
    identityId,
    credentials,
    onSubmit,
    isFetching,
    initialValues: formatIdentityInitialValues(identity),
    applicationId,
    showTaxAuthority,
    businessTypeOptions,
    showMiddleName: isElavon,
    hideOwnershipTypeDropdown,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}

class IdentityEditFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { dispatch, businessType } = this.props
    const { businessType: prevBusinessType } = prevProps

    if (businessType !== prevBusinessType && (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType))) {
      dispatch(change(IDENTITY_FORM, 'ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType]))
    }
  }

  render() {
    const { isFetching } = this.props

    return (
      <div>
        { isFetching && <Loader /> }

        { !isFetching && <IdentityForm {...this.props} /> }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityEditFormC)
