import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import IdentityPaymentInstruments from './IdentityPaymentInstruments'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import showModalAction from 'utilities/actions/showModalAction'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { PAYMENT_INSTRUMENTS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  ADD_BANK_ACCOUNT,
  ADD_PAYMENT_INSTRUMENT,
} from 'constants/language/languageConstants'

import {
  getIdentityPaymentInstrumentsSelector,
  getIdentitySelector,
} from 'state-layer/selectors'

import {
  EDIT_IDENTITY_BANK_ACCOUNTS,
  INSTANT_BANK_VERIFICATION_MODAL,
  ADD_IDENTITY_BANK_ACCOUNT,
  CREATE_PAYMENT_INSTRUMENT_MODAL,
} from 'constants/modalConstants'

import {
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  ACCOUNT_ROUTING_NUMBER,
  ACCOUNT_LAST4,
  EXPIRATION_YEAR,
  EXPIRATION_MONTH,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  ACCOUNT_LAST4,
  ACCOUNT_ROUTING_NUMBER,
  EXPIRATION_MONTH,
  EXPIRATION_YEAR,
  INSTRUMENT_BIN,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  convertFilterToResourceIdFilter('Payment Instrument'),
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `identityPaymentInstrumentsR.${FETCHING}`) || !identityId
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const identity = getIdentitySelector(state, identityId)
  const applicationId = get(identity, 'applicationId')
  const environment = get(credentials, 'environment')
  const paymentInstrumentPath = PAYMENT_INSTRUMENTS_PATH({ credentialId })
  const paymentInstruments = getIdentityPaymentInstrumentsSelector(state, identityId)
  const isPayout = isPayoutFeature({ credentials })
  const isRoleMerchantCred = isRoleMerchant({ credentials })

  const flowValues = {
    identityId,
  }

  const initialQueries = isRoleMerchantCred ? {} : {
    owner_identity_id: identityId,
  }

  return {
    credentials,
    identityId,
    applicationId,
    environment,
    flowValues,
    isFetching,
    paymentInstrumentPath,
    initialQueries,
    allowedFilters,
    isPayout,
    paymentInstruments,
    quickFilters,
    isRoleMerchantCred,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showBankVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: INSTANT_BANK_VERIFICATION_MODAL, modalProps, className: 'modal-md no-pad' })),
    showAddBankModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_IDENTITY_BANK_ACCOUNT, modalProps })),
    showEditBankModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_IDENTITY_BANK_ACCOUNTS, modalProps })),
    showCreatePaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_MODAL, className: 'modal-md no-pad', modalProps })),
  }
}

class IdentityPaymentInstrumentsC extends Component {
  render() {
    const {
      isPayout,
      identityId,
      applicationId,
      environment,
      showBankVerificationModal,
      showAddBankModal,
      elavonEnabled,
      hideAddBankAccountBtn = false,
      isRoleMerchantCred,
      showCreatePaymentInstrumentModal,
      showAddPaymentInstrumentAction = false,
      credentials,
    } = this.props

    const actions = showAddPaymentInstrumentAction ? [
      {
        label: ADD_PAYMENT_INSTRUMENT,
        action: () => showCreatePaymentInstrumentModal({ credentials, buyerIdentityId: identityId, showTokenForm: true, applicationId, environment }),
        className: 'addPaymentInstrument',
      },
    ] : [
      {
        label: ADD_BANK_ACCOUNT,
        action: () => showBankVerificationModal({ identityId }),
        condition: !isPayout && !elavonEnabled && !hideAddBankAccountBtn && isRoleMerchantCred,
        className: 'addBankAccount',
      },
      {
        label: ADD_BANK_ACCOUNT,
        action: () => showAddBankModal({ identityId }),
        condition: !isPayout && !elavonEnabled && !hideAddBankAccountBtn && !isRoleMerchantCred,
        className: 'addBankAccount',
      },
      // {
      //   label: 'Manage Bank Accounts',
      //   actionList: [
      //     {
      //       label: 'Add Bank Account',
      //       actionFunc: () => showBankVerificationModal({ identityId, elavonEnabled, settlementAliasWarningObject }),
      //     },
      //     {
      //       label: 'Edit Bank Accounts',
      //       actionFunc: () => showEditBankModal({ identityId, settlementAliasWarningObject }),
      //       condition: !isFetching,
      //     },
      //   ],
      //   className: 'large manage-bank-accounts-button',
      //   condition: !isPayout && elavonEnabled,
      // },
    ]

    return (
      <IdentityPaymentInstruments
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityPaymentInstrumentsC)
