import formatDate from 'utilities/formatters/formatDate'
import formatNumber from 'utilities/formatters/formatNumber'
import formatAmount from 'utilities/formatters/formatAmount'
import getMany from 'utilities/get/getMany'
import numeral from 'numeral'
import moment from 'moment'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'

export const parseAmount = (amount) => numeral(amount).value()
export const normalizeNumber = (amount) => formatNumber(amount)
export const normalizeInteger = (amount) => toString(amount)
export const normalizeAmount = (amount) => {
  if (amount === undefined || amount === null) {
    return amount
  }

  return formatAmount(amount / (10 ** 2))
}
export const normalizeFeeAmount = (amount) => parseAmount((amount / (10 ** 2)).toFixed(4))
export const normalizeTimestamp = (date) => formatDate({ date })
export const normalizeBackendAmount = (amount) => Math.round(parseAmount(amount) * (10 ** 4))/100
export const normalizeBackendNumber = (amount) => parseAmount(amount)
export const normalizeBackendPercentage = (percent) => parseFloat(percent)

// TODO: after sprint 529 release, remove last line that replaces Z
export const normalizeBackendStartDate = (dateString, useZ = false) => {
  const time = dateString.indexOf('T') > 0 ? dateString : `${dateString}T00:00:00`
  const offset = moment().local().utcOffset() * -1
  const localToUtc = moment.utc(time).add(offset, 'm').format()

  return useZ ? localToUtc : localToUtc.replace('Z', '')
}

// TODO: after sprint 529 release, remove last line that replaces Z
export const normalizeBackendEndDate = (dateString, useZ = false) => {
  const time = dateString.indexOf('T') > 0 ? dateString : `${dateString}T23:59:59`
  const offset = moment().local().utcOffset() * -1
  const localToUtc = moment.utc(time).add(offset, 'm').format()

  return useZ ? localToUtc : localToUtc.replace('Z', '')
}

export const normalizeStartEndDate = (dateString) => {
  const offset = moment().local().utcOffset()
  const time = moment.utc(dateString).add(offset, 'm').format()

  return time.indexOf('T') > 0 ? time.split('T')[0] : time
}

export const normalizePercentageToBasisPoints = (percent) => normalizeBackendPercentage(percent) * (10 ** 2)

// special normalization functions for fee profile values that can be null
export const normalizeNonNullFeeAmount = (amount) => amount === null ? null : amountToDisplayAmount(amount)
export const normalizeNonNullBasisPoints = (basisPoints) => basisPoints === null ? null : basisPoints / 100
export const normalizeNullableBackendAmount = (number) => number === null || isEmpty(number) ? null : normalizeBackendAmount(number)

export const normalizeAddress = (address) => {
  if (!address) return undefined

    const [
      line1,
      line2 = '', // needed for reducer to catch removal
      city,
      region,
      country,
      postalCode,
      street,
    ] = getMany(address, [
      'line1',
      'line2',
      'city',
      'region',
      'country',
      'postal_code',
      'street',
    ])

    return {
      line1,
      line2,
      city,
      region,
      country,
      postalCode,
      street,
    }
}

export const frontendNormalizationMap = {
  fee: normalizeAmount,
  amount: normalizeAmount,
  net_amount: normalizeAmount,
  total_amount: normalizeAmount,
  sale_amount: normalizeAmount,
  min_amount: normalizeAmount,
  max_amount: normalizeAmount,
  total_fee_amount: normalizeAmount,
  refund_amount: normalizeAmount,
  subtotal_amount: normalizeAmount,
  shipping_amount: normalizeAmount,
  estimated_tax_amount: normalizeAmount,
  discount_amount: normalizeAmount,
  tip_amount: normalizeAmount,
  convenience_amount: normalizeAmount,
  rent_surcharge_amount: normalizeAmount,
  surcharge_amount: normalizeAmount,
  transfer_debit_amount: normalizeAmount,
  transfer_credit_amount: normalizeAmount,
  total_fees: normalizeAmount,
  reverse_amount: normalizeAmount,
  dispute_debit_amount: normalizeAmount,
  dispute_credit_amount: normalizeAmount,
  adjustment_debit_amount: normalizeAmount,
  adjustment_credit_amount: normalizeAmount,
  annual_card_volume: normalizeAmount,
  payment_volume: normalizeAmount,
  max_transaction_amount: normalizeAmount,
  ach_max_transaction_amount: normalizeAmount,
  average_card_transfer_amount: normalizeAmount,
  average_ach_transfer_amount: normalizeAmount,
  annual_ach_volume: normalizeAmount,
  externally_funded_amount: normalizeAmount,
  available_amount: normalizeAmount,
  current_volume: normalizeAmount,
  volume_limit: normalizeAmount,
  day: normalizeInteger,
  month: normalizeInteger,
  year: normalizeInteger,
  principal_percentage_ownership: normalizeNumber,
  count_limit: normalizeNumber,
  current_count: normalizeNumber,
  sales_tax: normalizeAmount,
  customs_duty_amount: normalizeAmount,
  cost_per_unit: normalizeAmount,
  item_discount_amount: normalizeAmount,
  amount_excluding_sales_tax: normalizeAmount,
  amount_including_sales_tax: normalizeAmount,
  sales_tax_amount: normalizeAmount,
  capture_amount: normalizeAmount,
  signature_threshold_amount: normalizeAmount,
  clinic_amount: normalizeAmount,
  dental_amount: normalizeAmount,
  prescription_amount: normalizeAmount,
  vision_amount: normalizeAmount,
  'created_at.lte': normalizeStartEndDate,
  'created_at.gte': normalizeStartEndDate,
  'due_at.lte': normalizeStartEndDate,
  'due_at.gte': normalizeStartEndDate,
  'start_date.lte': normalizeStartEndDate,
  'start_date.gte': normalizeStartEndDate,
  'end_date.lte': normalizeStartEndDate,
  'end_date.gte': normalizeStartEndDate,
  'respond_by.lte': normalizeStartEndDate,
  'respond_by.gte':  normalizeStartEndDate,
  'updated_at.lte':  normalizeStartEndDate,
  'updated_at.gte':  normalizeStartEndDate,
  fixed_fee: normalizeNonNullFeeAmount,
  basis_points: normalizeNonNullBasisPoints,
  ancillary_fixed_fee_primary: normalizeNonNullFeeAmount,
  ancillary_fixed_fee_secondary: normalizeNonNullFeeAmount,
  externally_funded_fixed_fee: normalizeNonNullFeeAmount,
  externally_funded_basis_points: normalizeNonNullBasisPoints,
  ach_fixed_fee: normalizeNonNullFeeAmount,
  ach_basis_points: normalizeNonNullBasisPoints,
  ach_debit_return_fixed_fee: normalizeNonNullFeeAmount,
  ach_credit_return_fixed_fee: normalizeNonNullFeeAmount,
  dispute_fixed_fee: normalizeNonNullFeeAmount,
  dispute_inquiry_fixed_fee: normalizeNonNullFeeAmount,
  card_cross_border_fixed_fee: normalizeNonNullFeeAmount,
  card_cross_border_basis_points: normalizeNonNullBasisPoints,
  visa_fixed_fee: normalizeNonNullFeeAmount,
  visa_basis_points: normalizeNonNullBasisPoints,
  visa_assessments_basis_points: normalizeNonNullBasisPoints,
  visa_acquirer_processing_fixed_fee: normalizeNonNullFeeAmount,
  visa_credit_voucher_fixed_fee: normalizeNonNullFeeAmount,
  visa_kilobyte_access_fixed_fee: normalizeNonNullFeeAmount,
  visa_base_II_system_file_transmission_fixed_fee: normalizeNonNullFeeAmount,
  discover_fixed_fee: normalizeNonNullFeeAmount,
  discover_basis_points: normalizeNonNullBasisPoints,
  discover_assessments_basis_points: normalizeNonNullBasisPoints,
  discover_data_usage_fixed_fee: normalizeNonNullFeeAmount,
  discover_network_authorization_fixed_fee: normalizeNonNullFeeAmount,
  discover_externally_funded_fixed_fee: normalizeNonNullFeeAmount,
  discover_externally_funded_basis_points: normalizeNonNullBasisPoints,
  mastercard_fixed_fee: normalizeNonNullFeeAmount,
  mastercard_basis_points: normalizeNonNullBasisPoints,
  mastercard_assessments_under1k_basis_points: normalizeNonNullBasisPoints,
  mastercard_assessments_over1k_basis_points: normalizeNonNullBasisPoints,
  mastercard_acquirer_fees_basis_points: normalizeNonNullBasisPoints,
  diners_club_fixed_fee: normalizeNonNullFeeAmount,
  diners_club_basis_points: normalizeNonNullBasisPoints,
  jcb_fixed_fee: normalizeNonNullFeeAmount,
  jcb_basis_points: normalizeNonNullBasisPoints,
  american_express_fixed_fee: normalizeNonNullFeeAmount,
  american_express_basis_points: normalizeNonNullBasisPoints,
  american_express_assessment_basis_points: normalizeNonNullBasisPoints,
  american_express_externally_funded_basis_points: normalizeNonNullBasisPoints,
  american_express_externally_funded_fixed_fee: normalizeNonNullFeeAmount,
  ach_basis_points_fee_limit: normalizeNonNullFeeAmount,
  split_transfer_debit_amount: normalizeAmount,
  split_transfer_credit_amount: normalizeAmount,
}

export const backendNormalizationMap = {
  fee: normalizeBackendAmount,
  amount: normalizeBackendAmount,
  net_amount: normalizeBackendAmount,
  total_fees: normalizeBackendAmount,
  total_fee_amount: normalizeBackendAmount,
  total_amount: normalizeBackendAmount,
  sale_amount: normalizeBackendAmount,
  min_amount: normalizeBackendAmount,
  max_amount: normalizeBackendAmount,
  subtotal_amount: normalizeBackendAmount,
  shipping_amount: normalizeBackendAmount,
  estimated_tax_amount: normalizeBackendAmount,
  discount_amount: normalizeBackendAmount,
  tip_amount: normalizeBackendAmount,
  convenience_amount: normalizeBackendAmount,
  rent_surcharge_amount: normalizeBackendAmount,
  surcharge_amount: normalizeBackendAmount,
  sales_tax: normalizeBackendAmount,
  customs_duty_amount: normalizeBackendAmount,
  cost_per_unit: normalizeBackendAmount,
  item_discount_amount: normalizeBackendAmount,
  amount_excluding_sales_tax: normalizeBackendAmount,
  amount_including_sales_tax: normalizeBackendAmount,
  sales_tax_amount: normalizeBackendAmount,
  clinic_amount: normalizeBackendAmount,
  dental_amount: normalizeBackendAmount,
  prescription_amount: normalizeBackendAmount,
  vision_amount: normalizeBackendAmount,
  'created_at.lte': normalizeBackendEndDate,
  'created_at.gte': normalizeBackendStartDate,
  'due_at.lte': normalizeBackendEndDate,
  'due_at.gte': normalizeBackendStartDate,
  'start_date.lte': normalizeBackendEndDate,
  'start_date.gte': normalizeBackendStartDate,
  'end_date.lte': normalizeBackendEndDate,
  'end_date.gte': normalizeBackendStartDate,
  'respond_by.lte': normalizeBackendEndDate,
  'respond_by.gte': normalizeBackendStartDate,
  'updated_at.lte': normalizeBackendEndDate,
  'updated_at.gte': normalizeBackendStartDate,
  refund_amount: normalizeBackendAmount,
  annual_card_volume: normalizeBackendAmount,
  payment_volume: normalizeBackendAmount,
  max_transaction_amount: normalizeBackendAmount,
  ach_max_transaction_amount: normalizeBackendAmount,
  average_card_transfer_amount: normalizeBackendAmount,
  average_ach_transfer_amount: normalizeBackendAmount,
  annual_ach_volume: normalizeBackendAmount,
  day: normalizeBackendNumber,
  month: normalizeBackendNumber,
  year: normalizeBackendNumber,
  principal_percentage_ownership: normalizeBackendNumber,
  auto_close_offset_hours: normalizeBackendNumber,
  auto_close_offset_minutes: normalizeBackendNumber,
  auto_close_offset_days: normalizeBackendNumber,
  submission_offset_business_days: normalizeBackendNumber,
  capture_amount: normalizeBackendAmount,
  signature_threshold_amount: normalizeBackendAmount,
  fixed_fee: normalizeBackendAmount,
  ancillary_fixed_fee_primary: normalizeBackendAmount,
  ancillary_fixed_fee_secondary: normalizeBackendAmount,
  externally_funded_basis_points: normalizePercentageToBasisPoints,
  externally_funded_fixed_fee: normalizeBackendAmount,
  basis_points: normalizePercentageToBasisPoints,
  ach_fixed_fee: normalizeBackendAmount,
  ach_basis_fee_limit: normalizeBackendAmount,
  ach_basis_points: normalizePercentageToBasisPoints,
  ach_debit_return_fixed_fee: normalizeBackendAmount,
  ach_credit_return_fixed_fee: normalizeBackendAmount,
  dispute_fixed_fee: normalizeBackendAmount,
  dispute_inquiry_fixed_fee: normalizeBackendAmount,
  card_cross_border_fixed_fee: normalizeBackendAmount,
  card_cross_border_basis_points: normalizePercentageToBasisPoints,
  visa_fixed_fee: normalizeBackendAmount,
  visa_basis_points: normalizePercentageToBasisPoints,
  visa_assessments_basis_points: normalizePercentageToBasisPoints,
  visa_acquirer_processing_fixed_fee: normalizeBackendAmount,
  visa_credit_voucher_fixed_fee: normalizeBackendAmount,
  visa_kilobyte_access_fixed_fee: normalizeBackendAmount,
  visa_base_II_system_file_transmission_fixed_fee: normalizeBackendAmount,
  discover_fixed_fee: normalizeBackendAmount,
  discover_basis_points: normalizePercentageToBasisPoints,
  discover_assessments_basis_points: normalizePercentageToBasisPoints,
  discover_data_usage_fixed_fee: normalizeBackendAmount,
  discover_network_authorization_fixed_fee: normalizeBackendAmount,
  discover_externally_funded_basis_points: normalizePercentageToBasisPoints,
  discover_externally_funded_fixed_fee: normalizeBackendAmount,
  mastercard_fixed_fee: normalizeBackendAmount,
  mastercard_basis_points: normalizePercentageToBasisPoints,
  mastercard_assessments_under1k_basis_points: normalizePercentageToBasisPoints,
  mastercard_assessments_over1k_basis_points: normalizePercentageToBasisPoints,
  mastercard_acquirer_fees_basis_points: normalizePercentageToBasisPoints,
  diners_club_fixed_fee: normalizeBackendAmount,
  diners_club_basis_points: normalizePercentageToBasisPoints,
  jcb_fixed_fee: normalizeBackendAmount,
  jcb_basis_points: normalizePercentageToBasisPoints,
  american_express_fixed_fee: normalizeBackendAmount,
  american_express_basis_points: normalizePercentageToBasisPoints,
  american_express_assessment_basis_points: normalizePercentageToBasisPoints,
  american_express_externally_funded_basis_points: normalizePercentageToBasisPoints,
  american_express_externally_funded_fixed_fee: normalizeBackendAmount,
  ach_basis_points_fee_limit: normalizeBackendAmount,
}
