import frontendPayoutLinkModel from 'utilities/create/models/frontendPayoutLinkModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPayoutLinksModel = ({ data: payoutLinks }) => {
  const payoutLinksModel = map(payoutLinks, (payoutLink) => frontendPayoutLinkModel({ data: payoutLink }))

  return keyBy(payoutLinksModel, 'id')
}

export default frontendPayoutLinksModel
