export const AUTH_SESSION = 'auth-session'
export const CLIENT_ID = 'clientID'
export const CLIENT_EMAIL = 'email'
export const PLATFORM_ID = 'platformId'
export const ADMINISTRATOR_ID = 'administratorId'

export const COOKIE_KEY_MAP = {
  administratorId: ADMINISTRATOR_ID,
  platformId: PLATFORM_ID,
  clientId: CLIENT_ID,
  clientEmail: CLIENT_EMAIL,
}
