import React from 'react'
import column from 'utilities/table/column'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import { createdAtRange } from 'utilities/table/sort'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  ID,
  CREATED_ON,
  ONBOARDING_STATE_TITLE,
  NAME,
  EMAIL,
  COUNTRY,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NAME, 'fullName'),
  column(EMAIL, 'email'),
  column(COUNTRY, 'displayCountry', {
    formatter: ({ country }) => {
      return CountryFlagIcon({ country })
    },
  }),
  column(ONBOARDING_STATE_TITLE, 'merchantProcessorOnboardingStatesString', {
    formatter: ({ id, merchants = {} }) => {
      const filteredMerchants = filter(merchants, ({ processor }) => includes([DUMMY_V1, FINIX_V1], processor))

      return (
        <ul className='onboarding-states'>{ isEmpty(filteredMerchants) ? ' - '
          : map(filteredMerchants, ({ onboardingState, processor, displayProcessor }) => (
            <li key={`${id}-${processor}-${onboardingState}`}>
              <div className='flex items-center'><OnboardingState value={onboardingState} /> - {displayProcessor}</div>
            </li>
          )) }
        </ul>
      )
    },
  }),
]

export default columnDescriptors
