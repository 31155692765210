import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { IDENTITY_PATH } from 'constants/pathConstants'
import { PATCH_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import { EDIT_ASSOCIATED_IDENTITY } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const submitAssociatedIdentityEditForm = (values, dispatch, props = {}) => {
  const principal = get(values, 'principals.0', {})

  const {
    credentials,
    identityId,
    // parentIdentityId,
  } = props

  dispatch({
    type: PATCH_IDENTITY_F_REQUEST,
    payload: {
      values: principal,
      credentials,
      id: identityId,
    },
    meta: {
      successCallback: () => {
        const identityPath = IDENTITY_PATH({
          identityId,
          credentialId: get(credentials, 'id'),
        })

        dispatch(redirectRequest({ path: identityPath }))
      },
      actionId: sendAmplitudeActionEvent(EDIT_ASSOCIATED_IDENTITY, {
        id: identityId,
        credentials,
      }),
    },
  })
}

export default submitAssociatedIdentityEditForm
