import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYMENT_INSTRUMENT,
  PAYMENT_INSTRUMENTS,
} from 'constants/apiConstants'

const patchPaymentInstrumentAPI = ({ id, values, credentials, meta }) => {
  const path = PAYMENT_INSTRUMENT({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: PAYMENT_INSTRUMENTS,
  })
}

export default patchPaymentInstrumentAPI
