import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatMoney from 'utilities/formatters/formatMoney'
import formatPercentage from 'utilities/formatters/formatPercentage'
import { DEVICE_MODEL_TO_LABEL_MAP } from 'constants/deviceConstants'
import { normalizeAmount } from 'constants/normalizationConstants'
import map from 'lodash/map'
import join from 'lodash/join'

import {
  ENABLED,
  DISABLED,
} from 'constants/language/languageConstants'

const frontendDeviceModel = ({ data: device }) => {
  const [
    id,
    configurationDetails,
    createdAt,
    description,
    enabled,
    merchantId,
    deviceModel,
    name,
    serialNumber,
    tags,
    updatedAt,
    merchant,
    application,
    connectionStatus,
  ] = getMany(device, [
    'id',
    'configuration_details',
    'created_at',
    'description',
    'enabled',
    'merchant',
    'model',
    'name',
    'serial_number',
    'tags',
    'updated_at',
    'merchant',
    'application',
    'connection',
  ])

  const [
    allowDebit,
    bypassDeviceOnCapture,
    checkForDuplicateTransactions,
    promptAmountConfirmation,
    promptManualEntry,
    promptSignature,
    signatureThresholdAmount,
    promptReceiptConfirmation,
    displayTipOnReceipt,
    allowStandaloneAuthorizations,
    allowStandaloneRefunds,
    allowStandaloneSales,
    percentTippingThreshold,
    percentOptions,
    fixedOptions,
    allowCustomTip,
    promptTipOnScreen,
    idleMessage,
    idleImageFileId,
    automaticReceiptDeliveryMethods,
    availableReceiptMethods,
    promptForSignature,
  ] = getMany(configurationDetails, [
    'allow_debit',
    'bypass_device_on_capture',
    'check_for_duplicate_transactions',
    'prompt_amount_confirmation',
    'prompt_manual_entry',
    'prompt_signature',
    'signature_threshold_amount',
    'prompt_receipt_confirmation',
    'display_tip_on_receipt',
    'allow_standalone_authorizations',
    'allow_standalone_refunds',
    'allow_standalone_sales',
    'tipping_details.percent_tipping_threshold',
    'tipping_details.percent_options',
    'tipping_details.fixed_options',
    'tipping_details.allow_custom_tip',
    'prompt_tip_on_screen',
    'idle_message',
    'idle_image_file_id',
    'automatic_receipt_delivery_methods',
    'available_receipt_methods',
    'prompt_for_signature',
  ])

  const displayPercentOptions = map(percentOptions, option => formatPercentage({ percentage: option })).join(', ')
  const normalizedFixedOptions = map(fixedOptions, (amount) => normalizeAmount(amount))
  const normalizedPercentTippingThreshold = normalizeAmount(percentTippingThreshold)
  const displayFixedOptions = map(normalizedFixedOptions, option => formatMoney({ amount: option })).join(', ')
  const displayAutomaticReceiptDeliveryMethods = join(map(automaticReceiptDeliveryMethods, method => {
    if (method === 'SMS') return method
    return snakeCaseToTitleCase({ key: method })
  }), ', ')
  const displayAvailableReceiptMethods = join(map(availableReceiptMethods, method => {
    if (method === 'SMS') return method
    return snakeCaseToTitleCase({ key: method })
  }), ', ')

  return removeUndefined({
    id,
    configurationDetails,
    allowDebit,
    bypassDeviceOnCapture,
    checkForDuplicateTransactions,
    promptAmountConfirmation,
    promptManualEntry,
    promptSignature,
    displayPromptSignature: snakeCaseToTitleCase({ key: promptSignature }),
    signatureThresholdAmount,
    displaySignatureThresholdAmount: formatMoney({ amount: signatureThresholdAmount }),
    promptReceiptConfirmation,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    description,
    enabled,
    displayState: enabled ? ENABLED : DISABLED,
    idleMessage,
    idleImageFileId,
    merchantId,
    deviceModel,
    displayDeviceModel: DEVICE_MODEL_TO_LABEL_MAP[deviceModel] || deviceModel,
    name,
    serialNumber,
    tags,
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    merchant,
    application,
    connectionStatus,
    displayTipOnReceipt,
    allowStandaloneAuthorizations,
    allowStandaloneRefunds,
    allowStandaloneSales,
    percentTippingThreshold,
    normalizedPercentTippingThreshold,
    displayPercentTippingThreshold: formatMoney({ amount: normalizedPercentTippingThreshold }),
    percentOptions,
    fixedOptions,
    normalizedFixedOptions,
    allowCustomTip,
    promptTipOnScreen,
    displayPercentOptions,
    displayFixedOptions,
    displayAutomaticReceiptDeliveryMethods,
    displayAvailableReceiptMethods,
    promptForSignature,
    displayPromptForSignature: snakeCaseToTitleCase({ key: promptForSignature }),
    // TODO: Tami - in the future, add a field here called processorGateway which checks for gateway || processor
  })
}

export default frontendDeviceModel
