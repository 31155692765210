import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ExitThisFlowC from 'components/Customer/Pages/SendPayout/ExitThisFlow/ExitThisFlowC'
import { EXIT_THIS_FLOW } from 'constants/language/languageConstants'

const ExitThisFlowModal = ({
  credentialId,
  isRecipientFlow = true,
}) => {
  return (
    <GenericModal
      className='ExitThisFlowModal'
      title={EXIT_THIS_FLOW}
      Component={ExitThisFlowC}
      credentialId={credentialId}
      isRecipientFlow={isRecipientFlow}
    />
  )
}

ExitThisFlowModal.propTypes = {
  credentialId: PropTypes.string,
  isRecipientFlow: PropTypes.bool,
}

export default ExitThisFlowModal
