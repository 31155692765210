import './IdentityAuthorizationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getIdentityAuthorizations } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Authorizations/columnDescriptors'
import { IDENTITY_AUTHORIZATIONS } from 'constants/linkConstants'
import { AUTHORIZATIONS_ICON } from 'constants/iconConstants'
import { IDENTITY_AUTHORIZATIONS_RESOURCE_TITLE } from 'constants/language/languageConstants'

import {
  AUTHORIZATION,
  GET_IDENTITY_AUTHORIZATIONS,
} from 'constants/flowConstants'

const IdentityAuthorizations = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  authorizationPath = '',
  isFetching = false,
  identityId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='IdentityAuthorizations'>
      <TableC
        flow={GET_IDENTITY_AUTHORIZATIONS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getIdentityAuthorizations}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_AUTHORIZATIONS}
        allowedFilters={allowedFilters}
        path={authorizationPath}
        isFetching={isFetching}
        icon={`fa fa-${AUTHORIZATIONS_ICON}`}
        fileNameCSV={`Identity_${identityId}_Authorizations`}
        fileResourceTitleCSV={IDENTITY_AUTHORIZATIONS_RESOURCE_TITLE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={AUTHORIZATION}
      />
    </div>
  )
}

IdentityAuthorizations.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  authorizationPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default IdentityAuthorizations
