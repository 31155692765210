import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { ONBOARDING_FORMS_FILES } from 'constants/apiConstants'

const postOnboardingFormFileAPI = ({ id, values, meta }) => {
  return guestDashboardAPI.post({
    path: ONBOARDING_FORMS_FILES({ onboardingFormId: id }),
    values,
    meta,
  })
}

export default postOnboardingFormFileAPI
