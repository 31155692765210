import { getDispatch } from 'state-layer/configureStore'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getUnderwritingReviewRequest from 'utilities/actions/get/getUnderwritingReviewRequest'
import { POST_UNDERWRITING_REVIEW_RUNS_F_REQUEST } from 'constants/flowConstants'
import { CREATE_NEW_UNDERWRITING_REVIEW_RUN } from 'constants/amplitudeConstants'

const postUnderwritingReviewRunsRequest = ({
  credentials,
  id,
  closeModal = () => {},
  entityId,
  subjectId,
}) => createAction({
  credentials,
  type: POST_UNDERWRITING_REVIEW_RUNS_F_REQUEST,
  id,
  values: {},
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_NEW_UNDERWRITING_REVIEW_RUN, {
      credentials,
      reviewId: id,
    }),
    successCallback: () => {
      closeModal()
      // reload the application underwriting page to get the latest run
      const dispatch = getDispatch()
      dispatch(getUnderwritingReviewRequest({ reviewId: id, entityId, entityType: 'access_form', subjectId, credentials }))
    },
  },
})

export default postUnderwritingReviewRunsRequest
