import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CHART } from 'constants/apiConstants'

const getChartAPI = ({ id, queries, meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_CHART({ id }),
    meta,
    queries,
  })
}

export default getChartAPI
