import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import MerchantComplianceForms from './MerchantComplianceForms'
import columnDescriptors from 'components/Customer/Pages/Compliance/ComplianceForms/columnDescriptors'
import getActiveComplianceFormTemplateRequest from 'utilities/actions/get/getActiveComplianceFormTemplateRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import postComplianceFormRequest from 'utilities/actions/post/postComplianceFormRequest'
import { FETCHING } from 'constants/reducerConstants'
import { COMPLIANCE_FORMS_PATH } from 'constants/pathConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { COMPLIANCE_FORM_STATUS } from 'constants/filterConstants'
import { CREATE_COMPLIANCE_FORM } from 'constants/language/languageConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import get from 'lodash/get'

import {
  getComplianceFormsByMerchantId,
  getComplianceFormTemplateByApplicationId,
} from 'state-layer/selectors'

const allowedFilters = [
  COMPLIANCE_FORM_STATUS,
]

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `complianceFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const merchantId = get(props, 'merchantId')
  const applicationId = get(props, 'applicationId')
  const complianceFormTemplate = getComplianceFormTemplateByApplicationId(state, applicationId)
  const showCreateFormButton = !!complianceFormTemplate && (isAdministratorRole(credentials) || isPaymentOperationsManagerRole(credentials)) && isRolePlatform({ credentials })
  const complianceFormsPath = COMPLIANCE_FORMS_PATH({ credentialId })
  const formattedColumnDescriptors = columnDescriptors({ isRolePlatform: isRolePlatform({ credentials }) })
  const complianceForms = getComplianceFormsByMerchantId(state, merchantId)

  const initialQueries = {
    sort: 'created_at,desc',
    linked_to: merchantId,
  }

  const initialFilters = {
    created_at: {
      gte: '2022-01-01',
      lte: moment().format(YYYY_MM_DD),
    },
    state: {
      eq: 'INCOMPLETE,OVERDUE',
    },
  }

  return {
    initialQueries,
    isFetching,
    complianceFormsPath,
    columnDescriptors: formattedColumnDescriptors,
    merchantId,
    credentials,
    allowedFilters,
    quickFilters,
    initialFilters,
    showCreateFormButton,
    complianceForms,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveComplianceFormTemplate: ({ queries, credentials, meta }) => dispatch(getActiveComplianceFormTemplateRequest({ queries, credentials, meta })),
    postComplianceForm: ({ merchantId, credentials, closeModal }) => dispatch(postComplianceFormRequest({ merchantId, credentials, closeModal })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class MerchantComplianceFormsC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getActiveComplianceFormTemplate,
    } = this.props

    if (applicationId) {
      getActiveComplianceFormTemplate({ queries: { linked_to: applicationId }, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId: prevApplicationId,
    } = prevProps

    const {
      applicationId,
      credentials,
      getActiveComplianceFormTemplate,
    } = this.props

    if (applicationId && prevApplicationId !== applicationId) {
      getActiveComplianceFormTemplate({ queries: { linked_to: applicationId }, credentials })
    }
  }

  render() {
    const {
      postComplianceForm,
      credentials,
      closeModal,
      merchantId,
      showCreateFormButton,
    } = this.props

    const actions = [
      {
        label: CREATE_COMPLIANCE_FORM,
        action: () => {
          postComplianceForm({
            credentials,
            closeModal,
            merchantId,
          })
        },
        condition: showCreateFormButton,
      },
    ]

    return (
      <MerchantComplianceForms
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantComplianceFormsC)
