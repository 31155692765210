import createAction from 'utilities/actions/createAction'
import { POST_UNDERWRITING_RUN_SILENCE_F_REQUEST } from 'constants/flowConstants'

const postUnderwritingRunSilenceRequest = ({
  id,
  credentials,
  meta,
}) => createAction({
  type: POST_UNDERWRITING_RUN_SILENCE_F_REQUEST,
  credentials,
  id,
  meta,
})

export default postUnderwritingRunSilenceRequest
