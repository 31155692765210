import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'

const EditPlatformProcessorConfig = ({ fields }) => (
  <ul className='EditPlatformProcessorConfig'>
    {fields && fields.map((config, index) => {
      const key = `config_${index}`

      return (
        <li key={key} className='flex space-between config'>
          <Field
            name={`${config}.name`}
            placeholder=''
            disabled
            component={InputField}
            className='config-item name'
          />
          <Field
            name={`${config}.value`}
            placeholder=''
            component={InputField}
            className='config-item value'
          />
        </li>
      )
    })}
  </ul>
)

EditPlatformProcessorConfig.propTypes = {
  fields: PropTypes.object,
}

export default EditPlatformProcessorConfig
