import './PermissionsSettingsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PermissionsC from 'components/Customer/Pages/Permissions/PermissionsC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { PERMISSIONS } from 'constants/language/languageConstants'

const PermissionsSettings = ({
  permissions = [],
  type = '',
  isFetching = false,
  subTitle = '',
  authRole = '',
}) => {
  return (
    <div className='PermissionsSettings'>
      <PageSectionV2C
        isFetching={isFetching}
        title={PERMISSIONS}
        subTitle={subTitle}
      >
        <PermissionsC
          userPermissions={permissions}
          type={type}
          authRole={authRole}
        />
      </PageSectionV2C>
    </div>
  )
}

PermissionsSettings.propTypes = {
  permissions: PropTypes.array,
  isFetching: PropTypes.bool,
  type: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  authRole: PropTypes.string,
}

export default PermissionsSettings
