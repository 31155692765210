import { GET_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getTransferRequest = ({
  transferId,
  credentials,
}) => createAction({
  type: GET_TRANSFER_F_REQUEST,
  id: transferId,
  credentials,
})

export default getTransferRequest
