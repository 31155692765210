import './ProcessorDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import map from 'lodash/map'

const ProcessorDetails = ({
  processorDetails = {},
}) => {
  return (
    <div className='ProcessorDetails'>
      {map(processorDetails, (value, key) => {
        const normalizedKey = snakeCaseToTitleCase({ key })

        return (
          <>
            <div className='key'>{normalizedKey}</div>
            <div className='value'>{value}</div>
          </>
        )
      })}
    </div>
  )
}

ProcessorDetails.propTypes = {
  processorDetails: PropTypes.object,
}

export default ProcessorDetails
