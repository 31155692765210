import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import QRCode from 'react-qr-code'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import submitSetupMFAMobileAuthenticatorForm from 'utilities/submit/submitSetupMFAMobileAuthenticatorForm'
import { CONFIRM_PASSWORD_MFA_FORM } from 'constants/formConstants'

import {
  t,
  STEP_1_SCAN_BARCODE,
  STEP_2_ENTER_AUTHENTICATION_CODE,
  SET_UP_NEW_ACCOUNT_AUTHENTICATOR_APP_AND_SCAN_BARCODE,
  ENTER_CODE_FROM_AUTHENTICATOR_APP_TO_FINISH_SETUP,
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

const SetupMFAMobileAuthenticatorForm = ({
  type = '',
  handleSubmit = () => {},
  barcodeUri = '',
  showConfirmPasswordMFAModal = () => {},
}) => {
  return (
    <form className='SetupMFASMSForm' onSubmit={handleSubmit(submitSetupMFAMobileAuthenticatorForm)}>
      <div className='modal-content'>
        <p className='p-2-bold'>{t(STEP_1_SCAN_BARCODE)}</p>
        <p className='p-2'>{t(SET_UP_NEW_ACCOUNT_AUTHENTICATOR_APP_AND_SCAN_BARCODE)}</p>

        <div className='tac content-padding'>
          <QRCode value={barcodeUri} />
        </div>

        <p className='p-2-bold'>{t(STEP_2_ENTER_AUTHENTICATION_CODE)}</p>
        <p className='p-2'>{t(ENTER_CODE_FROM_AUTHENTICATOR_APP_TO_FINISH_SETUP)}</p>

        <Field
          name='code'
          component={InputField}
        />
      </div>

      <div className='buttons flex space-between'>
        <Button onClick={() => showConfirmPasswordMFAModal({ type })} label={BACK} variant='tertiary' className='cancel-button' />
        <Button type='submit' label={NEXT} />
      </div>
    </form>
  )
}

SetupMFAMobileAuthenticatorForm.propTypes = {
  type: PropTypes.string,
  handleSubmit: PropTypes.func,
  barcodeUri: PropTypes.string,
  showConfirmPasswordMFAModal: PropTypes.func,
}

export default reduxForm({
  form: CONFIRM_PASSWORD_MFA_FORM,
})(SetupMFAMobileAuthenticatorForm)
