import patchAdminUpdateAPI from 'api/finix/patch/patchAdminUpdateAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'
import co from 'co'

function * patchTransfersUpdateO ({
  credentials,
  values: {
    payloadValues = {},
    recordIds = [],
  },
}) {
  const responses = yield map(recordIds, (recordId) => patchAdminUpdateAPI({
    values: merge({}, payloadValues, {
      entity: { id: recordId },
    }),
    credentials,
  }))

  const data = map(recordIds, (recordId, idx) => ({
    id: recordId,
    response: get(responses, `${idx}.data`),
  }))

  return createSuccessResponse({ data })
}

export default co.wrap(patchTransfersUpdateO)
