import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import get from 'lodash/get'

const getCurrentDashboardId = () => {
  const platform = getCurrentPlatform()

  return get(platform, 'id')
}

export default getCurrentDashboardId
