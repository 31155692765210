import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CHANGE_PASSWORD } from 'constants/apiConstants'

const postUserChangePasswordAPI = ({ values, credentials, meta }) => {
  return dashboardAPI.post({
    meta,
    values,
    credentials,
    path: DASHBOARD_CHANGE_PASSWORD,
  })
}

export default postUserChangePasswordAPI
