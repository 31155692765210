import './SchedulesFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import validateSchedulesForm from 'utilities/validate/validateSchedulesForm'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { SCHEDULES_FORM } from 'constants/formConstants'
import { ADD_SCHEDULE } from 'constants/language/languageConstants'

import {
  scheduleTypeOptions,
  CONTINUOUS,
} from 'constants/payoutPlanConstants'

const renderSchedules = ({
  fields,
  templateNames,
  schedulesValues,
  meta: {
    error: errors = [],
    submitFailed,
  },
}) => {
  return (
    <ul className='schedules'>
      <li className='flex center'>
        <Button onClick={() => fields.push({})} label={ADD_SCHEDULE} />
      </li>

      { fields.map((schedule, index) => {
        const type = get(schedulesValues, `${index}.type`)

        return (
          <li className='schedule' key={schedule}>
            <div className='header flex space-between'>
              <h3>Schedule #{index + 1}</h3>

              <span
                className='removeSchedule fa fa-times'
                onClick={() => fields.remove(index)}
              />
            </div>

            <div className='contents'>
              <Field
                name={`${schedule}.type`}
                type='text'
                component={SelectField}
                options={scheduleTypeOptions}
                label='Type'
              />

              <Field
                name={`${schedule}.timezone`}
                type='text'
                component={InputField}
                label='Timezone'
                placeholder='America/New_York'
                disabled
              />

              {(!type || type !== CONTINUOUS) && (
                <fieldset className='DateFieldset'>
                  <label className='label-2'>Auto Close Offset</label>

                  <div className='flex space-between'>
                    <Field
                      name={`${schedule}.autoCloseOffsetHours`}
                      type='text'
                      component={InputField}
                      placeholder='Hours'
                    />

                    <Field
                      name={`${schedule}.autoCloseOffsetMinutes`}
                      type='text'
                      component={InputField}
                      placeholder='Minutes'
                    />

                    <Field
                      name={`${schedule}.autoCloseOffsetDays`}
                      type='text'
                      component={InputField}
                      placeholder='Days'
                    />
                  </div>
                </fieldset>
              )}

              <div className='templateNames'>
                <h3>Templates</h3>

                { map(templateNames, (templateName) => {
                  const name = `${schedule}.templateNames.name-${templateName}`

                  return (
                    <ToggleSwitchC
                      name={name}
                      label={templateName}
                      form={SCHEDULES_FORM}
                    />
                  )
                }) }
              </div>
            </div>
          </li>
        )
      })}

      {!isEmpty(errors) && submitFailed && map(errors, error => (<div className='payoutPlanError'>{error}</div>))}
    </ul>
  )
}

const SchedulesForm = ({
  handleSubmit = () => {},
  templateNames = [],
  schedulesValues = [],
  handleChange = () => {},
  buttonGroup = {},
}) => {
  return (
    <form className='SchedulesForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h2>Schedules</h2>

        <FieldArray
          name='schedules'
          component={renderSchedules}
          handleChange={handleChange}
          templateNames={templateNames}
          schedulesValues={schedulesValues}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

SchedulesForm.propTypes = {
  handleSubmit: PropTypes.func,
  templateNames: PropTypes.array,
  schedulesValues: PropTypes.array,
  handleChange: PropTypes.func,
}

export default reduxForm({
  form: SCHEDULES_FORM,
  validate: validateSchedulesForm,
})(SchedulesForm)
