import './FormFileUploaderButtonS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { UPLOAD_FILE } from 'constants/language/languageConstants'
import { ALERT_ICON } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'

const FormFileUploaderButton = ({
  label,
  helperText,
  showUploadFileModal = () => {},
  formName,
  fieldName,
  fileIcon,
  fileType,
  displayFieldName,
  errorMessage = '',
  buttonLabel = UPLOAD_FILE,
  buttonIcon,
  directUpload = false,
  accessFormId,
  onboardingFormId,
  applicationId,
  existingAdditionalDocuments = [],
  directUploadFunc = () => {},
  isFormInvalid = false,
  isHostedAccessForm,
  fileNameRequired = false,
  required = true,
  config = {},
  instructions = [],
}) => {
  return (
    <div className='FormFileUploaderButton'>
      <div className='flex'>
        {label && <div className='label'>{label}</div>}
        {required && label && <div className='required-field'>*</div>}
      </div>
      {helperText && <div className='lower-label'>{helperText}</div>}

      <Button
        className='upload-file-button'
        label={buttonLabel}
        leftIcon={buttonIcon}
        onClick={() => showUploadFileModal({
          formName,
          fieldName,
          fileIcon,
          fileType,
          displayFieldName,
          directUpload,
          accessFormId,
          onboardingFormId,
          applicationId,
          existingAdditionalDocuments,
          directUploadFunc,
          isHostedAccessForm,
          fileNameRequired,
          config,
          instructions,
        })}
        variant='secondary'
      />

      {isFormInvalid && !isEmpty(errorMessage) && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{errorMessage}</span>}
    </div>
  )
}

FormFileUploaderButton.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  showUploadFileModal: PropTypes.func,
  formName: PropTypes.string,
  fieldName: PropTypes.string,
  fileIcon: PropTypes.string,
  fileType: PropTypes.string,
  displayFieldName: PropTypes.string,
  errorMessage: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
  directUpload: PropTypes.bool,
  accessFormId: PropTypes.string,
  onboardingFormId: PropTypes.string,
  applicationId: PropTypes.string,
  existingAdditionalDocuments: PropTypes.array,
  directUploadFunc: PropTypes.func,
  isHostedAccessForm: PropTypes.bool,
  fileNameRequired: PropTypes.bool,
  required: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
}

export default FormFileUploaderButton
