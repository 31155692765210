import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ConfirmPasswordMFAFormC from 'components/Customer/Forms/ConfirmPasswordMFAForm/ConfirmPasswordMFAFormC'

import {
  CONFIRM_PASSWORD,
  PLEASE_CONFIRM_PASSWORD_BEFORE_PROCEEDING,
} from 'constants/language/languageConstants'

const ConfirmPasswordMFAModal = ({
  type = '',
  back = () => {},
}) => {
  return (
    <GenericModal
      title={CONFIRM_PASSWORD}
      subTitle={PLEASE_CONFIRM_PASSWORD_BEFORE_PROCEEDING}
      className='ConfirmPasswordMFAModal'
    >
      <ConfirmPasswordMFAFormC
        type={type}
        back={back}
      />
    </GenericModal>
  )
}

ConfirmPasswordMFAModal.propTypes = {
  type: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
}

export default ConfirmPasswordMFAModal
