import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_AUTHORIZATION_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_AUTHORIZATION } from 'constants/amplitudeConstants'

const patchAuthorizationRequest = ({
  authorizationId,
  credentials,
  values,
  closeModal = () => {},
  successMessage,
}) => createAction({
  type: PATCH_AUTHORIZATION_F_REQUEST,
  id: authorizationId,
  credentials,
  values,
  meta: {
    successCallback: closeModal,
    actionId: sendAmplitudeActionEvent(UPDATE_AUTHORIZATION, {
      credentials,
      authorizationId,
      values,
    }),
    successMessage,
  },
})

export default patchAuthorizationRequest
