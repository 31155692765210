import paymentsAPI from 'utilities/api/paymentsAPI'
import { TRANSFERS } from 'constants/apiConstants'

const postTransferAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: TRANSFERS,
    service: TRANSFERS,
  })
}

export default postTransferAPI
