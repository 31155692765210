import './ConfirmSubmitNoteFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  t,
  CANCEL,
  SUBMIT,
  SUBMIT_NOTE_CONFIRMATION_SUBTITLE,
} from 'constants/language/languageConstants'

const ConfirmSubmitNoteForm = ({
  closeModal = () => {},
  submitNote = () => {},
  subtitle = SUBMIT_NOTE_CONFIRMATION_SUBTITLE,
  submitting = false,
}) => {
  return (
    <form className='ConfirmSubmitNoteForm'>
      <div className='modal-content'>
        <div className='subtitle'>
          <p>{t(subtitle)}</p>
        </div>

        <div className='buttons flex flex-end'>
          <Button label={CANCEL} className='cancel-button' variant='ghost' onClick={closeModal} />
          <Button label={SUBMIT} onClick={submitNote} disabled={submitting} />
        </div>
      </div>
    </form>
  )
}

ConfirmSubmitNoteForm.propTypes = {
  closeModal: PropTypes.func,
  submitNote: PropTypes.func,
  subtitle: PropTypes.func,
  submitting: PropTypes.bool,
}

export default ConfirmSubmitNoteForm
