import './CancelApplicationFormModalS.scss'
import CancelApplicationFormC from './CancelApplicationFormC'
import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { CANCEL_APPLYING } from 'constants/language/languageConstants'

const CancelApplicationFormModal = () => {
  return (
    <GenericModal
      title={CANCEL_APPLYING}
      className='CancelApplicationFormModal'
      Component={CancelApplicationFormC}
    />
  )
}

export default CancelApplicationFormModal
