import React from 'react'
import AmountsDisplaySection from 'components/Customer/Shared/Page/PageSection/AmountsDisplaySection'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import { createdAtRange } from 'utilities/table/sort'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'

import {
  CREATED_ON,
  ID,
  SCHEDULE,
  SCHEDULE_AMOUNTS,
  SCHEDULE_NICKNAME,
  TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(SCHEDULE_NICKNAME, 'scheduleNickname', { className: 'medium' }),
  column(TYPE, 'subscriptionType', {
    className: 'x-small',
    formatter: ({ subscriptionType }) => subscriptionScheduleTypeMapping[subscriptionType],
  }),
  column(SCHEDULE, '', {
    formatter: ({ subscriptionType, periodOffset, fixedTimeIntervalOffset }) => convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset }),
  }),
  column(SCHEDULE_AMOUNTS, 'amounts', {
    className: 'large',
    formatter: ({ amounts }) => AmountsDisplaySection({ data: amounts }),
  }),
]

export default columnDescriptors
