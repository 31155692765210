import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  FAILED,
  SUCCEEDED,
  DEFAULT,
} from 'constants/statusConstants'

const BalanceTransferData = {
  CREATED: DEFAULT,
  SUBMITTING: DEFAULT,
  SUBMITTED: DEFAULT,
  [SUCCEEDED]: SUCCEEDED,
  [FAILED]: FAILED,
  RETURNED: FAILED,
  UNKNOWN: DEFAULT,
}

const BalanceTransferStatus = ({
  value = '',
}) => <ColorcodedStatus data={BalanceTransferData} value={value} />

BalanceTransferStatus.propTypes = {
  value: PropTypes.string,
}

export default BalanceTransferStatus
