import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors/index'
import DashboardConfigurationForm from './DashboardConfigurationForm'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isFlexPlatform } from 'constants/flexConstants'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { DASHBOARD_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  reset,
  change,
  getFormValues,
} from 'redux-form'

import {
  FILE_UPLOAD_INSTRUCTIONS,
  FILE_UPLOAD_SUB_INSTRUCTIONS,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 10000000,
  accept: 'image/jpeg, image/png, image/jpg',
}

const instructions = [
  {
    name: 'main',
    message: FILE_UPLOAD_INSTRUCTIONS,
  },
  {
    name: 'sub',
    message: FILE_UPLOAD_SUB_INSTRUCTIONS,
  },
]

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => {
      dispatch(change(DASHBOARD_CONFIGURATIONS_FORM, fieldName, color.hex))
    },
    resetForm: () => dispatch(reset(DASHBOARD_CONFIGURATIONS_FORM)),
  }
}

const mapStateToProps = (state, props) => {
  const {
    entityType,
    entityId,
  } = props

  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const isLiveEnvironment = get(credentials, 'environment') === LIVE_ENVIRONMENT

  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const customSettings = entityType && entityId ? getDashboardConfigByEntityTypeAndId(state, entityType, entityId) : get(currentUser, 'customSettings')
  const initialSidebarBackgroundColor = get(customSettings, 'sidebarBackgroundColor', '#FFFFFF')
  const initialSidebarTextColor = get(customSettings, 'sidebarTextColor', '#020F1C')
  const initialSidebarHighlightColor = get(customSettings, 'sidebarHighlightColor', '#0B5DBC')


  const isFetching = entityType && entityId ? isFetchingConfiguration : isFetchingCurrentUser
  const formValues = getFormValues(DASHBOARD_CONFIGURATIONS_FORM)(state)
  const sidebarBackgroundColor = get(formValues, 'sidebarBackgroundColor') || initialSidebarBackgroundColor
  const sidebarTextColor = get(formValues, 'sidebarTextColor') || initialSidebarTextColor
  const sidebarHighlightColor = get(formValues, 'sidebarHighlightColor') || initialSidebarHighlightColor

  const initialSidebarColorValues = {
    sidebarBackgroundColor,
    sidebarTextColor,
    sidebarHighlightColor,
  }

  const initialValues = merge({}, { enabled: 'true' }, initialSidebarColorValues, customSettings)

  return removeUndefined({
    id: get(customSettings, 'id'),
    isFetching,
    credentials,
    customSettings,
    initialValues,
    showEnabledInput: isRolePlatform({ credentials }) && isFlexPlatform(),
    sidebarBackgroundColor,
    sidebarTextColor,
    sidebarHighlightColor,
    isLiveEnvironment,
    isFetchingCurrentUser,
    config,
    instructions,
  })
}

class DashboardConfigurationFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      displaySidebarHighlightColorPicker: false,
      displaySidebarTextColorPicker: false,
      displaySidebarBackgroundColorPicker: false,
    }
  }

  componentDidMount() {
    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isFetchingCurrentUser: prevIsFetchingCurrentUser,
    } = prevProps

    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && prevIsFetchingCurrentUser && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  handleClick = (fieldName) => {
    const {
      displaySidebarHighlightColorPicker,
      displaySidebarTextColorPicker,
      displaySidebarBackgroundColorPicker,
    } = this.state

    if (fieldName === 'displaySidebarHighlightColorPicker') {
      this.setState({ displaySidebarHighlightColorPicker: !displaySidebarHighlightColorPicker })
    }
    if (fieldName === 'displaySidebarTextColorPicker') {
      this.setState({ displaySidebarTextColorPicker: !displaySidebarTextColorPicker })
    }
    if (fieldName === 'displaySidebarBackgroundColorPicker') {
      this.setState({ displaySidebarBackgroundColorPicker: !displaySidebarBackgroundColorPicker })
    }
  };

  handleClose = (fieldName) => {
    if (fieldName === 'displaySidebarHighlightColorPicker') {
      this.setState({ displaySidebarHighlightColorPicker: false })
    }
    if (fieldName === 'displaySidebarTextColorPicker') {
      this.setState({ displaySidebarTextColorPicker: false })
    }
    if (fieldName === 'displaySidebarBackgroundColorPicker') {
      this.setState({ displaySidebarBackgroundColorPicker: false })
    }
  };

  render() {
    const { resetForm } = this.props

    const {
      displaySidebarHighlightColorPicker,
      displaySidebarTextColorPicker,
      displaySidebarBackgroundColorPicker,
    } = this.state

    return (
      <DashboardConfigurationForm
        {...this.props}
        getDashboardCustomization={this.getDashboardCustomization}
        displaySidebarHighlightColorPicker={displaySidebarHighlightColorPicker}
        displaySidebarTextColorPicker={displaySidebarTextColorPicker}
        displaySidebarBackgroundColorPicker={displaySidebarBackgroundColorPicker}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        resetForm={resetForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardConfigurationFormC)
