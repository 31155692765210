import frontendDeviceModel from 'utilities/create/models/frontendDeviceModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendDevicesModel = ({ data: devices }) => {
  const devicesModels = map(devices, (device) => frontendDeviceModel({ data: device }))

  return keyBy(devicesModels, 'id')
}

export default frontendDevicesModel
