import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import CreateSubscriptionPlanForm from './CreateSubscriptionPlanForm'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_SUBSCRIPTION_PLAN_FORM } from 'constants/formConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getUrlQuery from 'utilities/get/getUrlQuery'
import showModalAction from 'utilities/actions/showModalAction'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { DAY } from 'constants/subscriptionsConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'
import head from 'lodash/head'

import {
  FEATURE_NOT_AVAILABLE_MODAL,
  SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
} from 'constants/modalConstants'

import {
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLANS_ARE_NOT_AVAILABLE,
} from 'constants/language/languageConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `subscriptionPlansR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const formSelector = formValueSelector(CREATE_SUBSCRIPTION_PLAN_FORM)
  const enableTrialPhase = formSelector(state, 'enableTrialPhase')
  const linkedTo = getUrlQuery('merchantId')
  const applicationId = get(currentUser, 'applicationId')
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, LIVE_ENVIRONMENT) ? [LITLE_V1, FINIX_V1] : [DUMMY_V1]
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const filteredApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const isFetchingMerchants = get(state, `merchantsR.${FETCHING}`, true)

  const initialValues = {
    trialIntervalCount: 30,
    trialIntervalType: { label: 'Days', value: DAY },
  }

  return {
    isFetching,
    credentials,
    currentUser,
    initialValues,
    showTrialPhaseDetails: enableTrialPhase,
    linkedTo,
    applicationId,
    isFetchingMerchants,
    filteredApplicationMerchants,
    applicationMerchants,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    showSelectSenderForCreatePaymentModal: () => dispatch(showModalAction({
      modalType: SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
      className: 'modal-md no-pad overflow-visible',
      modalProps: {
        canCloseModal: true,
      },
    })),
    showFeatureNotAvailableModal: () => dispatch(showModalAction({
      modalType: FEATURE_NOT_AVAILABLE_MODAL,
      modalProps: {
        canCloseModal: false,
        title: SUBSCRIPTION_PLANS_ARE_NOT_AVAILABLE,
        className: 'SubscriptionsNotAvailableModal',
        description: MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTION_PLAN,
      },
    })),
  }
}

class CreateSubscriptionPlanFormC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getApplicationMerchants,
      linkedTo,
    } = this.props

    if (!linkedTo && applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingMerchants,
      showFeatureNotAvailableModal,
      showSelectSenderForCreatePaymentModal,
      linkedTo,
      applicationMerchants,
      filteredApplicationMerchants,
      applicationId,
      getApplicationMerchants,
      credentials,
    } = this.props

    const {
      isFetchingMerchants: prevIsFetchingMerchants,
      applicationId: prevApplicationId,
    } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }

    const filteredApplicationMerchantsSize = size(filteredApplicationMerchants)

    if (!linkedTo) {
      if (prevIsFetchingMerchants === true && isFetchingMerchants === false && !isEmpty(applicationMerchants)) {
        if (filteredApplicationMerchantsSize === 0) {
          showFeatureNotAvailableModal()
        }

        if (filteredApplicationMerchantsSize === 1) {
          const { id } = head(filteredApplicationMerchants)
          updateUrlQueries({ merchantId: id })
        }

        if (filteredApplicationMerchantsSize > 1) {
          showSelectSenderForCreatePaymentModal()
        }
      }
    }
  }

  render() {
    return (
      <CreateSubscriptionPlanForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionPlanFormC)
