import toString from 'lodash/toString'
import getMany from 'utilities/get/getMany'
import { CUSTOM_BATCH_TIME_VALUE } from 'constants/payoutSettingsConstants'

import {
  HOUR_REGEX,
  MINUTES_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
} from 'utilities/validate'

// TODO: add unit test
const validateEditPayoutSettingsForm = ({
  type,
  payoutPaymentInstrumentId,
  feePaymentInstrumentId,
  payoutFrequency,
  payoutMonthlyDay,
  feeFrequency,
  feeMonthlyDay,
  payoutRail,
  feeRail,
  payoutSubmissionDelayDays,
  feeSubmissionDelayDays,
  readyToSettleUpon,
  feeReadyToSettleUpon,
  batchTime,
  time,
}) => {
  const [
    hour,
    minute,
    timeMeridian,
    timeZone,
  ] = getMany(time, [
    'hour',
    'minute',
    'timeMeridian',
    'timeZone',
  ])

  return {
    type: fieldEmpty(type, 'Payout type'), // technically shouldn't be needed but adding just in case
    payoutPaymentInstrumentId: fieldEmpty(payoutPaymentInstrumentId, 'Payout bank account'),
    feePaymentInstrumentId: fieldEmpty(feePaymentInstrumentId, 'Fee bank account'),
    payoutFrequency: fieldEmpty(payoutFrequency, 'Payout settlement schedule'), // technically shouldn't be needed but adding just in case
    payoutMonthlyDay: fieldEmpty(toString(payoutMonthlyDay), 'Payout settlement schedule day of month'),
    feeFrequency: fieldEmpty(feeFrequency, 'Fee settlement schedule'),
    feeMonthlyDay: fieldEmpty(toString(feeMonthlyDay), 'Fee settlement schedule day of month'),
    payoutRail: fieldEmpty(payoutRail, 'Funding transfer speed'), // technically shouldn't be needed but adding just in case
    feeRail: fieldEmpty(feeRail, 'Fee transfer speed'), // technically shouldn't be needed but adding just in case
    readyToSettleUpon: fieldEmpty(readyToSettleUpon, 'Payout ready to settle at'),
    feeReadyToSettleUpon: fieldEmpty(feeReadyToSettleUpon, 'Fee ready to settle at'),
    batchTime: fieldEmpty(batchTime, 'Settlement Batch Time'),
    time: batchTime === CUSTOM_BATCH_TIME_VALUE ? {
      hour: invalidOrEmptyRegex({ field: hour, name: 'Hour', regex: HOUR_REGEX, customErrorMessage: 'Hour must be between 1 and 12.' }),
      minute: invalidOrEmptyRegex({ field: minute, name: 'Minute', regex: MINUTES_REGEX, customErrorMessage: 'Minutes must be between 00 and 59.' }),
      timeMeridian: fieldEmpty(timeMeridian, 'AM/PM'),
      timeZone: fieldEmpty(timeZone, 'Time Zone'),
    } : {},
  }
}

export default validateEditPayoutSettingsForm
