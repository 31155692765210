import get from 'lodash/get'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'

import {
  APPLICATION_OWNER_IDENTITY_ROLE,
  BENEFICIAL_OWNER_IDENTITY_ROLE,
  PLATFORM_OWNER_IDENTITY_ROLE,
  RECIPIENT_IDENTITY_ROLE,
  SELLER_IDENTITY_ROLE,
  SENDER_IDENTITY_ROLE,
} from 'constants/identityConstants'

import {
  GET_TRANSFER,
  GET_IDENTITY,
  GET_AUTHORIZATION,
  GET_SETTLEMENT,
  GET_DISPUTE,
  GET_MERCHANT,
  GET_APPLICATION,
  GET_PAYMENT_INSTRUMENT,
  GET_PLATFORM_USER,
  GET_FEE,
  GET_PLATFORM,
  GET_PAYOUT_PLAN,
  GET_BALANCE_TRANSFER,
  GET_SUBSCRIPTION_SCHEDULE,
  GET_WEBHOOK,
  GET_COMPLIANCE_FORM,
  GET_COMPLIANCE_FORM_TEMPLATE,
  GET_REVIEW_QUEUE_ITEM,
  GET_SPLIT_TRANSFER,
  GET_ONBOARDING_FORM_DATA,
  GET_PAYMENT_LINK,
  GET_PAYOUT_LINK,
  GET_PURCHASE,
  GET_DEVICE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLAN,
  GET_API_LOG,
  GET_CUSTOMER_ONBOARDING_FORM,
  GET_MONITORING_ALERT,
} from 'constants/flowConstants'

import {
  PAYMENT_PATH,
  IDENTITY_PATH,
  AUTHORIZATION_PATH,
  SETTLEMENT_PATH,
  DISPUTE_PATH,
  MERCHANT_PATH,
  APPLICATION_PATH,
  PAYMENT_INSTRUMENT_PATH,
  USER_PATH,
  FEE_PATH,
  PLATFORM_PATH,
  PAYOUT_PLAN_PATH,
  BALANCE_TRANSFER_PATH,
  SUBSCRIPTION_SCHEDULE_PATH,
  WEBHOOK_PATH,
  COMPLIANCE_FORM_PATH,
  COMPLIANCE_FORM_TEMPLATE_PATH,
  ONBOARDING_IDENTITY_PATH,
  REFUND_PATH,
  SPLIT_PAYMENT_PATH,
  SPLIT_REFUND_PATH,
  ONBOARDING_FORM_PATH,
  RECIPIENT_PATH,
  PAYMENT_LINK_PATH,
  PURCHASE_PATH,
  PAYMENT_DEVICE_PATH,
  SUBSCRIPTION_PATH,
  SUBSCRIPTION_PLAN_PATH,
  API_LOG_PATH,
  CUSTOMER_ONBOARDING_FORM_VIEW_PATH,
  PAYOUT_LINK_PATH,
  BUYER_PATH,
  MONITORING_ALERT_PATH,
} from 'constants/pathConstants'

export const IDPrefixMapping = {
  TR: {
    type: GET_TRANSFER,
    idName: 'transferId',
    path: PAYMENT_PATH,
    recordType: 'Transfer',
    recordName: 'Transfer',
  },
  RV: {
    idName: 'transferId',
    path: REFUND_PATH,
    recordType: 'Refund',
    recordName: 'Refund',
  },
  MID: {
    type: GET_IDENTITY,
    idName: 'identityId',
    path: IDENTITY_PATH,
    recordName: 'Identity',
  },
  RID: {
    type: GET_IDENTITY,
    idName: 'recipientId',
    path: RECIPIENT_PATH,
    recordName: 'Recipient',
  },
  BID: {
    type: GET_IDENTITY,
    idName: 'identityId',
    path: BUYER_PATH,
    recordName: 'Buyer',
  },
  AU: {
    type: GET_AUTHORIZATION,
    idName: 'authorizationId',
    path: AUTHORIZATION_PATH,
    recordType: 'Authorization',
    recordName: 'Authorization',
  },
  ST: {
    type: GET_SETTLEMENT,
    idName: 'settlementId',
    path: SETTLEMENT_PATH,
    recordName: 'Settlement',
  },
  DI: {
    type: GET_DISPUTE,
    idName: 'disputeId',
    path: DISPUTE_PATH,
    recordName: 'Dispute',
  },
  MU: {
    type: GET_MERCHANT,
    idName: 'merchantId',
    path: MERCHANT_PATH,
    recordName: 'Merchant',
  },
  AP: {
    type: GET_APPLICATION,
    idName: 'applicationId',
    path: APPLICATION_PATH,
    recordName: 'Application',
  },
  PI: {
    type: GET_PAYMENT_INSTRUMENT,
    idName: 'paymentInstrumentId',
    path: PAYMENT_INSTRUMENT_PATH,
    recordName: 'Payment Instrument',
  },
  FE: {
    type: GET_FEE,
    idName: 'feeId',
    path: FEE_PATH,
    recordName: 'Fee',
  },
  PL: {
    type: GET_PLATFORM,
    idName: 'platformId',
    path: PLATFORM_PATH,
    recordName: 'Platform',
  },
  PO: {
    type: GET_PAYOUT_PLAN,
    idName: 'payoutPlanId',
    path: PAYOUT_PLAN_PATH,
    recordName: 'Platform',
  },
  BT: {
    type: GET_BALANCE_TRANSFER,
    idName: 'balanceTransferId',
    path: BALANCE_TRANSFER_PATH,
    recordName: 'Balance Transfer',
  },
  SU: {
    type: GET_SUBSCRIPTION_SCHEDULE,
    idName: 'subscriptionScheduleId',
    path: SUBSCRIPTION_SCHEDULE_PATH,
    recordName: 'Subscription Schedule',
  },
  WH: {
    type: GET_WEBHOOK,
    idName: 'webhookId',
    path: WEBHOOK_PATH,
    recordName: 'Webhook',
  },
  cf: {
    type: GET_COMPLIANCE_FORM,
    idName: 'complianceFormId',
    path: COMPLIANCE_FORM_PATH,
    recordName: 'Compliance Form',
  },
  cft: {
    type: GET_COMPLIANCE_FORM_TEMPLATE,
    idName: 'complianceFormTemplateId',
    path: COMPLIANCE_FORM_TEMPLATE_PATH,
    recordName: 'Compliance Form Template',
  },
  RK: {
    type: GET_REVIEW_QUEUE_ITEM,
    idName: 'reviewQueueId',
    path: ({ credentialId, reviewQueueId }) => `${ONBOARDING_IDENTITY_PATH({ credentialId, reviewQueueId })}?tab=underwriting`,
    recordName: 'Merchant Underwriting',
  },
  sp: {
    type: GET_SPLIT_TRANSFER,
    idName: 'splitTransferId',
    path: SPLIT_PAYMENT_PATH,
    recordName: 'Split Payment',
  },
  spr: {
    type: GET_SPLIT_TRANSFER,
    idName: 'splitRefundId',
    path: SPLIT_REFUND_PATH,
    recordName: 'Split Refund',
  },
  obf: {
    type: GET_ONBOARDING_FORM_DATA,
    idName: 'onboardingFormId',
    path: ONBOARDING_FORM_PATH,
    recordName: 'Onboarding Form',
  },
  PML: {
    type: GET_PAYMENT_LINK,
    idName: 'paymentLinkId',
    path: PAYMENT_LINK_PATH,
    recordName: 'Payment Link',
  },
  POL: {
    type: GET_PAYOUT_LINK,
    idName: 'payoutLinkId',
    path: PAYOUT_LINK_PATH,
    recordName: 'Payout Link',
  },
  PUR: {
    type: GET_PURCHASE,
    idName: 'purchaseId',
    path: PURCHASE_PATH,
    recordName: 'Purchase',
  },
  DV: {
    type: GET_DEVICE,
    idName: 'deviceId',
    path: PAYMENT_DEVICE_PATH,
    recordName: 'Device',
  },
  SBP: {
    type: GET_SUBSCRIPTION_PLAN,
    idName: 'subscriptionPlanId',
    path: SUBSCRIPTION_PLAN_PATH,
    recordName: 'Subscription Plan',
  },
  su: {
    type: GET_SUBSCRIPTION,
    idName: 'subscriptionId',
    path: SUBSCRIPTION_PATH,
    recordName: 'Subscription',
  },
  APL: {
    type: GET_API_LOG,
    idName: 'apiLogId',
    path: API_LOG_PATH,
    recordName: 'API Log',
  },
  COB: {
    type: GET_CUSTOMER_ONBOARDING_FORM,
    idName: 'customerOnboardingFormId',
    path: CUSTOMER_ONBOARDING_FORM_VIEW_PATH,
    recordName: 'Customer Onboarding Form',
  },
  MAL: {
    type: GET_MONITORING_ALERT,
    idName: 'monitoringAlertId',
    path: MONITORING_ALERT_PATH,
    recordName: 'Monitoring Alert',
  },
}

const transferTypeToPrefix = {
  REVERSAL: 'RV',
}

const splitTransferTypeToPrefix = {
  REVERSAL: 'spr',
}

export const getIDPrefixMapping = (input = '', resource) => {
  let prefixTwoLetters = input && input.slice(0, 2)
  let prefixThreeLetters = input && input.slice(0, 3)

  // handle redirecting to special transfer type pages
  if (prefixTwoLetters === 'TR') {
    const transferType = get(resource, 'type')
    prefixTwoLetters = get(transferTypeToPrefix, transferType, prefixTwoLetters)
  }

  // handle redirecting to either split payment or split refund
  if(prefixTwoLetters === 'sp') {
    const parentTransferType = get(resource, 'parentTransfer.type')
    prefixThreeLetters = get(splitTransferTypeToPrefix, parentTransferType, prefixTwoLetters)
  }

  // handle redirecting to special identity type pages
  if(prefixTwoLetters === 'ID') {
    prefixThreeLetters = 'BID'

    const merchantIdentity = !isEmpty(intersection(get(resource, 'identityRoles'), [SELLER_IDENTITY_ROLE, APPLICATION_OWNER_IDENTITY_ROLE, PLATFORM_OWNER_IDENTITY_ROLE, BENEFICIAL_OWNER_IDENTITY_ROLE, SENDER_IDENTITY_ROLE])) || !!get(resource, 'businessType')
    if (merchantIdentity) {
      prefixThreeLetters = 'MID'
    }

    const recipientIdentity = includes(get(resource, 'identityRoles'), RECIPIENT_IDENTITY_ROLE)
    if (recipientIdentity) {
      prefixThreeLetters = 'RID'
    }
  }

  if (input.includes('payment_link')) {
    prefixThreeLetters = 'PML'
  }

  if (input.includes('payout_link')) {
    prefixThreeLetters = 'POL'
  }

  if (input.includes('purchase')) {
    prefixThreeLetters = 'PUR'
  }

  if (input.includes('subscription_plan')) {
    prefixThreeLetters = 'SBP'
  }

  if (input.includes('apilog')) {
    prefixThreeLetters = 'APL'
  }

  if (input.includes('customer_obf')) {
    prefixThreeLetters = 'COB'
  }

  if (input.includes('monitoring_alert')) {
    prefixThreeLetters = 'MAL'
  }

  const prefix = get(IDPrefixMapping, prefixThreeLetters) ? prefixThreeLetters : prefixTwoLetters
  const IDMapping = get(IDPrefixMapping, prefix)

  return { prefix, ...IDMapping }
}
