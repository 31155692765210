import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_LINK,
  PAYOUT_LINKS,
} from 'constants/apiConstants'

const getPayoutLinkAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: PAYOUT_LINK({ payoutLinkId: id }),
    service: PAYOUT_LINKS,
    credentials,
    meta,
  })
}

export default getPayoutLinkAPI
