import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import submitDeleteSubscriptionScheduleAmountForm from 'utilities/submit/submitDeleteSubscriptionScheduleAmountForm'
import { fieldEmpty } from 'utilities/validate'
import { DELETE_SUBSCRIPTION_AMOUNT } from 'constants/formConstants'

import {
  DELETE,
  DELETE_AMOUNT_CHARGE_ALL_MERCHANTS,
  CANCEL,
} from 'constants/language/languageConstants'

const DeleteSubscriptionAmountForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  subscriptionAmountsOptions,
}) => {
  return (
    <form className='DeleteSubscriptionAmountForm' onSubmit={handleSubmit(submitDeleteSubscriptionScheduleAmountForm)}>
      <div className='section'>
        <Field
          name='subscriptionAmountId'
          label='Subscription Amount'
          component={SelectField}
          options={subscriptionAmountsOptions}
          autofill={false}
        />

        <WarningMessage warningMessage={DELETE_AMOUNT_CHARGE_ALL_MERCHANTS} />
      </div>

      <div className='buttons flex space-between'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={DELETE} />
      </div>
    </form>
  )
}

DeleteSubscriptionAmountForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  subscriptionAmountsOptions: PropTypes.array,
}

export default reduxForm({
  form: DELETE_SUBSCRIPTION_AMOUNT,
  validate: ({ subscriptionAmountId }) => ({ subscriptionAmountId: fieldEmpty(subscriptionAmountId, 'Subscription amount') }),
})(DeleteSubscriptionAmountForm)
