import { GET_APPLICATION_BALANCES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getApplicationBalancesRequest = ({
  credentials,
  queries,
}) => createAction({
  type: GET_APPLICATION_BALANCES_F_REQUEST,
  queries,
  credentials,
  meta: {
    showErrors: false,
  },
})

export default getApplicationBalancesRequest
