import { POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_REQUEST } from 'constants/flowConstants'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import head from 'lodash/head'

const submitCreateVerificationForm = (values, dispatch, props) => {
  const [
    paymentInstrumentId,
    credentials,
    allowedCountryCodes,
    shouldShowCountrySelect,
  ] = getMany(props, [
    'paymentInstrumentId',
    'credentials',
    'allowedCountryCodes',
    'shouldShowCountrySelect',
  ])

  const [
    country = head(allowedCountryCodes),
    processor,
  ] = getMany(values, [
    'country',
    'processor',
  ])

  const valuesToSubmit = removeUndefined({
    processor,
    country: shouldShowCountrySelect ? country : undefined,
  })

  dispatch({
    type: POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_REQUEST,
    payload: {
      id: paymentInstrumentId,
      credentials,
      values: valuesToSubmit,
    },
  })
}

export default submitCreateVerificationForm
