import get from 'lodash/get'
import head from 'lodash/head'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'

const createGenericFrontendModel = ({ data }) => {
  if (isArray(data) && !isEmpty(data)) {
    const firstID = get(head(data), 'id')

    if (firstID) {
      return keyBy(data, 'id')
    }
  }

  if (isPlainObject(data) && !isEmpty(data)) {
    return keyBy([data], 'id')
  }

  return data
}

export default createGenericFrontendModel
