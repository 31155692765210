import selectOption from 'utilities/forms/selectOption'
import map from 'lodash/map'

const getApplicationEnabledMCCs = (applicationMCCs, finixMCCs) => {
  const applicationMCCsArray = map(applicationMCCs, (mcc) => {
    const description = finixMCCs[mcc]

    return selectOption(`${mcc} - ${description}`, mcc)
  })

  return applicationMCCsArray
}

export default getApplicationEnabledMCCs
