import React, { Component } from 'react'
import ExpandableButton from 'components/Shared/ExpandableButton/ExpandableButton'

class ExpandableButtonC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  toggleExpansion = () => {
    const { expanded } = this.state

    this.setState({ expanded: !expanded })
  }

  render() {
    const { expanded } = this.state

    return (
      <ExpandableButton
        {...this.props}
        expanded={expanded}
        toggleExpansion={this.toggleExpansion}
      />
    )
  }
}

export default ExpandableButtonC
