import removeUndefined from 'utilities/remove/removeUndefined'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'
import findIndex from 'lodash/findIndex'
import keys from 'lodash/keys'
import map from 'lodash/map'
import { SAME_DAY_ACH, CONTINUOUS } from 'constants/payoutPlanConstants'

import {
  fieldEmpty,
  nonEmptyNumber,
  validateInteger,
} from 'utilities/validate'

const validateCombinationPlansForm = ({
  combinationPlans = [],
}, {
  templateNames = [],
  schedulesTemplateNames,
  schedulesValues,
}) => {
  if (isEmpty(combinationPlans)) {
    return { combinationPlans: { _error: ['At least one combination plan must be created.'] } }
  }

  // errors for individual combination plans
  const combinationPlansErrors = []

  // errors for all combination plans
  const combinationPlansTotalErrors = []

  const selectedTemplateNames = {}

  combinationPlans.forEach((combinationPlan, combinationPlanIndex) => {
    const {
      name,
      sourceInstrumentLabel,
      destinationInstrumentLabel,
      rail,
      level,
      submissionSettings = {},
      equation = [],
    } = combinationPlan

    const { type, submissionOffsetBusinessDays } = submissionSettings

    if (isEmpty(equation)) {
      combinationPlansTotalErrors.push(`${name ? name : `Combination plan #${combinationPlanIndex + 1}`} must have an equation.`)
    }

    const combinationPlanScheduleIndexes = {}

    equation.forEach(({ coefficient, template }, termIndex) => {
      set(combinationPlansErrors, `${combinationPlanIndex}.equation.${termIndex}`, removeUndefined({
        coefficient: coefficient ? nonEmptyNumber({ field: coefficient, name: 'Coefficient' }) : undefined,
        template: fieldEmpty(template, 'Template'),
      }))

      selectedTemplateNames[template] = true

      // combination plan can only have templates from the same schedule selected
      const schedulesTemplateNamesIndex = findIndex(schedulesTemplateNames, (scheduleTemplateNames) => get(scheduleTemplateNames, template))
      combinationPlanScheduleIndexes[schedulesTemplateNamesIndex] = true
    })

    if (keys(combinationPlanScheduleIndexes).length > 1) {
      combinationPlansTotalErrors.push(`${name ? name : `Combination Plan ${combinationPlanIndex + 1}`}'s equation must have templates from the same schedule.`)
    }

    // same day ACH requires a continuous schedule
    const validateRail = () => {
      const emptyField = fieldEmpty(rail, 'Rail')
      const scheduleTypes = map(keys(combinationPlanScheduleIndexes), (scheduleIndex) => get(schedulesValues, `${scheduleIndex}.type`))
      const scheduleTypeNotContinuous = scheduleTypes.length !== 1 || scheduleTypes[0] !== CONTINUOUS
      const validRail = rail !== SAME_DAY_ACH ? false : scheduleTypeNotContinuous

      return emptyField || (validRail && 'Same Day ACH requires a continuous schedule.')
    }

    combinationPlansErrors[combinationPlanIndex] = {
      name: fieldEmpty(name, 'Name'),
      sourceInstrumentLabel: fieldEmpty(sourceInstrumentLabel, 'Source Instrument Label'),
      destinationInstrumentLabel: fieldEmpty(destinationInstrumentLabel, 'Destination Instrument Label'),
      rail: validateRail(),
      level: fieldEmpty(level, 'Level'),
      submissionSettings: {
        type: fieldEmpty(type, 'Submission Settings'),
        submissionOffsetBusinessDays: validateInteger({ field: submissionOffsetBusinessDays, name: 'Submission Settings Offset' }),
      },
    }
  })

  if (combinationPlans.length !== uniqBy(combinationPlans, 'name').length) {
    combinationPlansTotalErrors.push('Each combination plan must have its own unique name.')
  }

  if (!isEqual(templateNames.length, keys(selectedTemplateNames).length)) {
    combinationPlansTotalErrors.push('Each template must be applied to an equation at least once.')
  }

  if (!isEmpty(combinationPlansTotalErrors)) {
    set(combinationPlansErrors, '_error', combinationPlansTotalErrors)
  }

  return {
    combinationPlans: combinationPlansErrors,
  }
}

export default validateCombinationPlansForm
