import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisablePayoutLinkModal from './DisablePayoutLinkModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import patchPayoutLinkRequest from 'utilities/actions/patch/patchPayoutLinkRequest'
import { DEACTIVATED } from 'constants/paymentLinkConstants'

const mapStateToProps = (state, props) => {
  return {
    credentials: getCurrentCredentials(state),
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deactivatePayoutLink: ({ credentials, payoutLinkId, closeModal }) => dispatch(patchPayoutLinkRequest({ payoutLinkId, credentials, values: { state: DEACTIVATED }, closeModal })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class DisablePayoutLinkModalC extends Component {
  disablePayoutLink = () => {
    const {
      credentials,
      payoutLinkId,
      deactivatePayoutLink,
      closeModal,
    } = this.props

    deactivatePayoutLink({ credentials, payoutLinkId, closeModal })
  }

  render() {
    return (
      <DisablePayoutLinkModal
        {...this.props}
        disablePayoutLink={this.disablePayoutLink}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisablePayoutLinkModalC)
