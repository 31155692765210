import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SHOW_SUPPORT_DROPDOWN } from 'constants/actionConstants'
import { OPEN_SUPPORT_DROPDOWN } from 'constants/amplitudeConstants'

const showSupportDropdownRequest = ({
  credentials,
}) => {
  return createAction({
    type: SHOW_SUPPORT_DROPDOWN,
    meta: {
      actionId: sendAmplitudeActionEvent(OPEN_SUPPORT_DROPDOWN, {
        credentials,
      }),
    },
  })
}

export default showSupportDropdownRequest
