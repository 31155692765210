import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationAccountBalance from './ApplicationAccountBalance'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { UPDATE_ACCOUNT_BALANCE_MODAL } from 'constants/modalConstants'
import { UPDATE_ACCOUNT_BALANCE } from 'constants/language/languageConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { FETCHING } from 'constants/reducerConstants'
import { BALANCE_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `balanceEntriesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    applicationId,
    balanceId,
    availableAmount,
    displayAvailableAmount,
    balanceCurrency,
  ] = getMany(props, [
    'applicationId',
    'balanceId',
    'availableAmount',
    'displayAvailableAmount',
    'balanceCurrency',
  ])

  const quickFilters = [DATE_QUICK_FILTER]
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const balanceEntryPath = BALANCE_PATH({ credentialId, applicationId })

  const flowValues = {
    balanceId,
  }

  return {
    isFetching,
    credentials,
    applicationId,
    balanceId,
    balanceCurrency,
    availableAmount,
    displayAvailableAmount,
    quickFilters,
    flowValues,
    isRolePlatformCredential,
    balanceEntryPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationVelocityLimits: ({ credentials, entityId, currency }) => dispatch(getCurrentUsagesRequest({ credentials, entityId, currency })),
    showUpdateAccountBalanceModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_ACCOUNT_BALANCE_MODAL, modalProps })),
  }
}

class ApplicationAccountBalanceC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      balanceCurrency,
      getApplicationVelocityLimits,
    } = this.props

    if (applicationId) {
      getApplicationVelocityLimits({ credentials, entityId: applicationId, currency: balanceCurrency })
    }
  }

  render() {
    const {
      showUpdateAccountBalanceModal,
      applicationId,
      applicationName,
      credentials,
      balanceId,
      availableAmount,
      displayAvailableAmount,
    } = this.props

    const actions = [
      {
        label: UPDATE_ACCOUNT_BALANCE,
        action: () => showUpdateAccountBalanceModal({ applicationId, applicationName, balanceId, availableAmount, displayAvailableAmount, credentials }),
      },
    ]

    return (
      <ApplicationAccountBalance
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationAccountBalanceC)
