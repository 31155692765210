import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change, isInvalid, hasSubmitFailed } from 'redux-form'
import LiveAccountApplicationBankAccountInfoForm from 'components/Customer/Forms/LiveAccountApplicationBankAccountInfoForm/LiveAccountApplicationBankAccountInfoForm'
import Loader from 'components/Shared/Loader/Loader'
import removeUndefined from 'utilities/remove/removeUndefined'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import { LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM } from 'constants/formConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  getAccessFormSelector,
  getPlaidPublicTokensSelector,
  getPlaidTokenMetadataSelector,
} from 'state-layer/selectors'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  BANK_ACCOUNT,
  BANK_NAME,
  SUCCESSFULLY_LINKED,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const accessFormId = get(props, 'accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const dataLoaded = !isEmpty(accessForm)

  const [
    bankAccountData,
    bankDocument,
  ] = getMany(accessForm, [
    'bankAccountData',
    'bankDocument',
  ])

  const formSelector = formValueSelector(LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM)
  const bankDocumentName = get(formSelector(state, 'bankDocument.file'), 'name') || get(formSelector(state, 'bankDocument'), 'name')
  const bankDocumentErrorMsg = get(state, 'form.LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM.syncErrors.bankAccountData.bankDocument', '')
  const isFormInvalid = isInvalid(LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM)(state) && hasSubmitFailed(LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM)(state)
  const plaidProcessorToken = get(getPlaidPublicTokensSelector(state), 'token') || get(accessForm, 'bankAccountData.thirdPartyToken')
  const formAccountType = get(formSelector(state, 'bankAccountData.accountType'), 'CHECKING')

  const initialValues = dataLoaded ? {
    bankAccountData,
    bankDocument,
  } : undefined

  // on success, display metadata returned from plaid
  const plaidTokenMetadata = getPlaidTokenMetadataSelector(state)

  const [
    bankName,
    accountType,
    maskedAccountNumber,
  ] = getMany(plaidTokenMetadata, [
    'institution.name',
    'account.subtype',
    'account.mask',
  ])

  const plaidLinkAccountDataSection = convertPageSectionDataToV2([
    {
      label: BANK_NAME,
      value: bankName,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_TYPE,
      value: capitalize(accountType),
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: `**${maskedAccountNumber}`,
      condition: !!maskedAccountNumber,
    },
    {
      label: BANK_ACCOUNT,
      value: <div><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
      condition: isEmpty(maskedAccountNumber),
    },
  ], 1)

  return removeUndefined({
    initialValues,
    bankDocumentName,
    bankDocumentErrorMsg,
    isFormInvalid,
    dataLoaded,
    bankAccountData,
    plaidProcessorToken,
    accessForm,
    accessFormId,
    plaidLinkAccountDataSection,
    formAccountType,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    removeFile: () => dispatch(change(LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM, 'bankDocument', null)),
  }
}

class LiveAccountApplicationBankAccountInfoFormC extends Component {
  state = {
    showBankFieldset: false,
  }

  componentDidMount() {
    const {
      bankAccountData,
      plaidProcessorToken,
      plaidEnabled,
    } = this.props

    // if we have a plaid public token on page load, don't show bank fieldset
    if (plaidProcessorToken) {
      this.setState({ showBankFieldset: false })
    }

    // if we have bank data or plaid is disabled, show bank fieldset
    const bankAccountNumber = get(bankAccountData, 'accountNumber')
    if (!plaidEnabled || bankAccountNumber) {
      this.setState({ showBankFieldset: true })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      bankAccountData,
      plaidProcessorToken,
      dispatch,
    } = this.props

    const {
      bankAccountData: prevBankAccountData,
      plaidProcessorToken: prevPlaidProcessorToken,
    } = prevProps

    const prevBankDocumentId = get(prevBankAccountData, 'bankDocument.id')
    const bankDocumentId = get(bankAccountData, 'bankDocument.id')
    const bankDocumentChanged = prevBankDocumentId !== bankDocumentId

    const prevBankAccountNumber = get(prevBankAccountData, 'accountNumber')
    const bankAccountNumber = get(bankAccountData, 'accountNumber')

    // if the bank document is updated via api, update the form to have the correct bank documents
    if (bankDocumentId && bankDocumentChanged) {
      dispatch(change(LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM, 'bankAccountData.bankDocument', bankAccountData.bankDocument))
    }

    // if we have a plaid public token on page load, don't show bank fieldset
    if (prevPlaidProcessorToken !== plaidProcessorToken && plaidProcessorToken) {
      this.setState({ showBankFieldset: false })
    }

    // if we have bank data on page load, show bank fieldset
    if (prevBankAccountNumber !== bankAccountNumber && bankAccountNumber) {
      this.setState({ showBankFieldset: true })
    }
  }

  showBankFields = () => {
    this.setState({ showBankFieldset: true })
  }

  render() {
    const { dataLoaded } = this.props
    const { showBankFieldset } = this.state

    if (!dataLoaded) return <Loader />

    return (
      <LiveAccountApplicationBankAccountInfoForm
        {...this.props}
        showBankFieldset={showBankFieldset}
        showBankFields={this.showBankFields}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationBankAccountInfoFormC)
