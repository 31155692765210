import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { CREATE_SUBSCRIPTION } from 'constants/amplitudeConstants'
import { POST_SUBSCRIPTION_F_REQUEST } from 'constants/flowConstants'
import { SUBSCRIPTION_PATH } from 'constants/pathConstants'
import { BILL_AUTOMATICALLY } from 'constants/subscriptionsConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitCreateSubscriptionBillingForm = (values, dispatch, props) => {
  const {
    credentials,
    linkedTo,
    merchantIdentity,
    amountFromSelectedPlan,
    identityPaymentInstruments,
  } = props

  const {
    nickname,
    billingInterval,
    amount,
    buyerIdentityId,
    buyerInstrumentId,
    trialIntervalType,
    trialIntervalCount,
    enableTrialPhase,
    plan,
    createSubscriptionWithoutPlanCheckbox,
  } = values

  const credentialId = get(credentials, 'id')
  const billingIntervalValue = get(billingInterval, 'value')
  const buyerIdentityIdValue = get(buyerIdentityId, 'value')
  const buyerInstrumentIdValue = get(buyerInstrumentId, 'value')
  const trialIntervalTypeValue = get(trialIntervalType, 'value')
  const linkedType = 'MERCHANT'
  const planId = get(plan, 'value')
  const currency = get(identityPaymentInstruments, ['buyerInstrumentId.value', 'currency'], 'USD')
  const collectionMethod = BILL_AUTOMATICALLY // TODO: remove this when we support other types of collection methods

  let valuesToSubmit = {}

  if (createSubscriptionWithoutPlanCheckbox) {
    valuesToSubmit = {
      currency,
      nickname,
      linkedTo,
      linkedType,
      billingInterval: billingIntervalValue,
      amount,
      buyerIdentityId: buyerIdentityIdValue,
      buyerInstrumentId: buyerInstrumentIdValue,
      trialIntervalType: trialIntervalTypeValue,
      trialIntervalCount,
      enableTrialPhase,
      collectionMethod,
    }
  } else {
    valuesToSubmit = {
      currency,
      subscriptionPlanId: planId,
      buyerIdentityId: buyerIdentityIdValue,
      buyerInstrumentId: buyerInstrumentIdValue,
      linkedType,
      linkedTo: merchantIdentity,
      collectionMethod,
    }
  }

  dispatch({
    type: POST_SUBSCRIPTION_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const subscriptionId = head(keys(newValues))
        dispatch(redirectRequest({ path: SUBSCRIPTION_PATH({ credentialId, subscriptionId }) }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION, {
        credentials,
        values: valuesToSubmit,
      }),
    },
  })
}

export default submitCreateSubscriptionBillingForm
