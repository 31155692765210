import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { convertTagValues } from 'utilities/convert/convertTagValues'
import { EDIT_SUBSCRIPTION_SCHEDULE_AMOUNT } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
  GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_REQUEST,
} from 'constants/flowConstants'

const submitEditSubscriptionScheduleAmountForm = (values = {}, dispatch, props) => {
  const { credentials, subscriptionScheduleId, subscriptionAmountId } = props
  const label = get(values, 'label')

  const amountValues = merge(omit(values, 'tags'), {
    subscriptionScheduleId,
    label: label ? label : '', // pass empty string to clear label value
    tags: convertTagValues(values),
  })

  dispatch({
    type: PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
    payload: {
      id: subscriptionAmountId,
      values: amountValues,
      credentials,
    },
    meta: {
      selectorId: subscriptionScheduleId,
      actionId: sendAmplitudeActionEvent(EDIT_SUBSCRIPTION_SCHEDULE_AMOUNT, {
        credentials,
        ...amountValues,
      }),
      successCallback: () => {
        dispatch({
          type: GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_REQUEST,
          payload: {
            id: subscriptionScheduleId,
            credentials,
          },
          meta: {
            selectorId: subscriptionScheduleId,
          },
        })
      },
    },
  })
}

export default submitEditSubscriptionScheduleAmountForm
