import dashboardAPI from 'utilities/api/dashboardAPI'
import { PAYMENT_INSTRUMENT_UNMASK } from 'constants/apiConstants'

const getPaymentInstrumentUnmaskAPI = ({ id }) => {
  return dashboardAPI.get({
    path: PAYMENT_INSTRUMENT_UNMASK({ paymentInstrumentId: id }),
  })
}

export default getPaymentInstrumentUnmaskAPI
