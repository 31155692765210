import './ResendDashboardInviteModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ResendDashboardInviteFormC from 'components/Customer/Forms/ResendDashboardInviteForm/ResendDashboardInviteFormC'
import { RESEND_DASHBOARD_INVITE_EMAIL } from 'constants/language/languageConstants'

const ResendDashboardInviteModal = ({
  credentials,
  entityId = '',
  email,
  dashboardName = '',
}) => {
  return (
    <GenericModal
      title={RESEND_DASHBOARD_INVITE_EMAIL}
      className='ResendDashboardInviteModal'
      Component={ResendDashboardInviteFormC}
      credentials={credentials}
      entityId={entityId}
      email={email}
      dashboardName={dashboardName}
    />
  )
}

ResendDashboardInviteModal.propTypes = {
  credentials: PropTypes.object,
  entityId: PropTypes.string,
  email: PropTypes.string,
  dashboardName: PropTypes.string,
}

export default ResendDashboardInviteModal
