import isEmpty from 'lodash/isEmpty'

import {
  fieldEmpty,
  fieldEmptyMessage,
  selectFieldEmpty,
} from 'utilities/validate'

const validateComplianceValidationAndAttestationDetailsForm = ({
  pciDssValidation,
  compliantWithLegalExceptionDetails,
  doNotUserVendorSuppliedDefaults,
  doNotUserVendorSuppliedDefaultsDate,
  doNotUserVendorSuppliedDefaultsDescription,
  developAndMaintainSecureSystems,
  developAndMaintainSecureSystemsDate,
  developAndMaintainSecureSystemsDescription,
  identifyAndAuthenticateAccessToSystemComponents,
  identifyAndAuthenticateAccessToSystemComponentsDate,
  identifyAndAuthenticateAccessToSystemComponentsDescription,
  restrictPhysicalAccessToCardholder,
  restrictPhysicalAccessToCardholderDate,
  restrictPhysicalAccessToCardholderDescription,
  maintainAPolicyAddressesInformationSecurity,
  maintainAPolicyAddressesInformationSecurityDate,
  maintainAPolicyAddressesInformationSecurityDescription,
}) => {
  let compliantWithLegalExceptionDetailsError

  if (isEmpty(compliantWithLegalExceptionDetails)) {
    compliantWithLegalExceptionDetailsError = { _error: 'At least one requirement must be provided.' }
  } else {
    const thirdPartyServiceProvidersErrors = []

    compliantWithLegalExceptionDetails.forEach((thirdPartyServiceProvider, index) => {
      const { affected_requirement: affectedRequirement, details } = thirdPartyServiceProvider

      thirdPartyServiceProvidersErrors[index] = {
        affected_requirement: fieldEmpty(affectedRequirement, 'Affected requirement'),
        details: fieldEmpty(details, 'Details'),
      }
    })

    compliantWithLegalExceptionDetailsError = thirdPartyServiceProvidersErrors
  }

  return {
    pciDssValidation: selectFieldEmpty(pciDssValidation, 'Field'),
    compliantWithLegalExceptionDetails: compliantWithLegalExceptionDetailsError,
    doNotUserVendorSuppliedDefaults: selectFieldEmpty(doNotUserVendorSuppliedDefaults, 'Requirement 2'),
    doNotUserVendorSuppliedDefaultsDate: !doNotUserVendorSuppliedDefaultsDate && fieldEmptyMessage(),
    doNotUserVendorSuppliedDefaultsDescription: fieldEmpty(doNotUserVendorSuppliedDefaultsDescription, 'Field'),
    developAndMaintainSecureSystems: selectFieldEmpty(developAndMaintainSecureSystems, 'Requirement 6'),
    developAndMaintainSecureSystemsDate: !developAndMaintainSecureSystemsDate && fieldEmptyMessage(),
    developAndMaintainSecureSystemsDescription: fieldEmpty(developAndMaintainSecureSystemsDescription, 'Field'),
    identifyAndAuthenticateAccessToSystemComponents: selectFieldEmpty(identifyAndAuthenticateAccessToSystemComponents, 'Requirement 8'),
    identifyAndAuthenticateAccessToSystemComponentsDate: !identifyAndAuthenticateAccessToSystemComponentsDate && fieldEmptyMessage(),
    identifyAndAuthenticateAccessToSystemComponentsDescription: fieldEmpty(identifyAndAuthenticateAccessToSystemComponentsDescription, 'Field'),
    restrictPhysicalAccessToCardholder: selectFieldEmpty(restrictPhysicalAccessToCardholder, 'Requirement 9'),
    restrictPhysicalAccessToCardholderDate: !restrictPhysicalAccessToCardholderDate && fieldEmptyMessage(),
    restrictPhysicalAccessToCardholderDescription: fieldEmpty(restrictPhysicalAccessToCardholderDescription, 'Field'),
    maintainAPolicyAddressesInformationSecurity: selectFieldEmpty(maintainAPolicyAddressesInformationSecurity, 'Requirement 12'),
    maintainAPolicyAddressesInformationSecurityDate: !maintainAPolicyAddressesInformationSecurityDate && fieldEmptyMessage(),
    maintainAPolicyAddressesInformationSecurityDescription: fieldEmpty(maintainAPolicyAddressesInformationSecurityDescription, 'Field'),
  }
}

export default validateComplianceValidationAndAttestationDetailsForm
