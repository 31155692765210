import './TagsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'
import Tags from 'components/Customer/Forms/TagsForm/Tags'
import Button from 'components/Shared/Button/Button'
import submitTagsForm from 'utilities/submit/submitTagsForm'
import { SAVE } from 'constants/language/languageConstants'

const TagsForm = ({
  handleSubmit = () => {},
  submitting = false,
  invalid = false,
}) => {
  return (
    <form className='TagsForm' onSubmit={handleSubmit(submitTagsForm)}>
      <FieldArray name='tags' component={Tags} />

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} disabled={invalid} label={SAVE} />
      </div>
    </form>
  )
}

TagsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default TagsForm
