import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  SUCCEEDED,
  WARNING,
  FAILED,
} from 'constants/statusConstants'

const OnboardingData = {
  APPROVED: SUCCEEDED,
  PROVISIONING: WARNING,
  REJECTED: FAILED,
}

const OnboardingState = ({
  value = '',
}) => <ColorcodedStatus data={OnboardingData} value={value} />

OnboardingState.propTypes = {
  value: PropTypes.string,
}

export default OnboardingState
