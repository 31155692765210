import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import CreateTeamMember from './CreateTeamMember'
import PermissionsC from 'components/Customer/Pages/Permissions/PermissionsC'
import RoleFieldset from 'components/Shared/Fieldsets/RoleFieldset/RoleFieldset'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getMany from 'utilities/get/getMany'
import getRolesRequest from 'utilities/actions/get/getRolesRequest'
import { CREATE_TEAM_MEMBER_FORM } from 'constants/formConstants'
import { PLATFORM } from 'constants/dashboardConfigurationsConstants'
import join from 'lodash/join'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  getActiveOrderedRolesSelector,
  getMemberSelector,
} from 'state-layer/selectors'

import {
  ADD_NEW_TEAM_MEMBER,
  ADD_NEW_TEAM_MEMBER_SUBTITLE,
  EDIT_TEAM_MEMBER_SUBTITLE,
  CREATE_CUSTOM_ROLE,
  CUSTOM_ROLE,
  CUSTOMIZE_PERMISSIONS_MESSAGE,
  EDIT_TEAM_MEMBER,
} from 'constants/language/languageConstants'

import {
  CUSTOM,
  roleTypeMap,
} from 'constants/roleConstants'

import {
  entityTypeToRoleMap,
  entityTypeToSelectorMap,
} from 'constants/memberConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const memberId = get(props, 'params.memberId')
  const member = getMemberSelector(state, memberId)
  const { email } = member

  const [
    credentialName,
    environment,
    role,
  ] = getMany(credentials, [
    'name',
    'environment',
    'role',
  ])

  const currentQueries = parseUrlQueries()
  const [
    entityType,
    entityId,
  ] = getMany(currentQueries, [
    'entityType',
    'entityId',
  ])

  const roleType = get(roleTypeMap, role)
  const authRole = get(entityTypeToRoleMap, entityType)
  const orderedRoles = getActiveOrderedRolesSelector(state)

  const currentEntitySelector = entityTypeToSelectorMap[entityType]
  const currentEntity = currentEntitySelector(state, entityId)
  const currentEntityName = entityType === PLATFORM ? get(currentEntity, 'name') : get(currentEntity, 'businessName')
  const currentBusinessName = currentEntityName ? `${currentEntityName} Dashboard` : undefined

  const roleDropdownOptions = map(orderedRoles, (orderedRole) => {
    const [
      id,
      roleName,
      description,
      permissions = [],
      type,
    ] = getMany(orderedRole, [
      'id',
      'roleName',
      'description',
      'permissions',
      'type',
    ])

    return {
      value: id,
      label: roleName,
      description,
      sublabel: type === CUSTOM ? CUSTOM_ROLE : '',
      dropdownComponent: PermissionsC,
      dropdownProps: {
        userPermissions: permissions,
        type,
        authRole,
      },
    }
  })

  const formSelector = formValueSelector(CREATE_TEAM_MEMBER_FORM)
  const currentSelectedEvents = formSelector(state, 'selectedEvents')

  const permissionsCheckboxError = get(state, ['form', CREATE_TEAM_MEMBER_FORM, 'syncErrors', 'selectedEvents'])
  const formSubmitFailed = get(state, ['form', CREATE_TEAM_MEMBER_FORM, 'submitFailed'])
  const permissionsError = permissionsCheckboxError && formSubmitFailed && 'Role Permissions must not be empty'

  // Add the Create Custom Role to the Dropdown Actions
  if (orderedRoles.length < 60) {
    roleDropdownOptions.push({
      value: 'CUSTOM_ROLE',
      label: CREATE_CUSTOM_ROLE,
      description: CUSTOMIZE_PERMISSIONS_MESSAGE,
      dropdownComponent: RoleFieldset,
      dropdownProps: {
        formName: CREATE_TEAM_MEMBER_FORM,
        currentSelectedEvents,
        permissionsError,
        authRole,
      },
    })
  }

  const subTitle = memberId ? EDIT_TEAM_MEMBER_SUBTITLE : ADD_NEW_TEAM_MEMBER_SUBTITLE
  const headerTitle = memberId ? EDIT_TEAM_MEMBER : ADD_NEW_TEAM_MEMBER
  const midSentence = memberId ? 'on the' : 'to the'
  const headerSubTitle = join([subTitle, email, midSentence, environment, roleType, 'dashboard.'], ' ')

  return {
    credentials,
    headerTitle,
    headerSubTitle,
    roles: roleDropdownOptions,
    authRole,
    entityType,
    entityId,
    memberId,
    currentBusinessName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: ({ credentials, queries, meta }) => dispatch(getRolesRequest({ credentials, queries, meta })),
  }
}


class CreateTeamMemberC extends Component {
  componentDidMount() {
    const {
      credentials,
      entityId,
      getRoles,
      authRole,
    } = this.props

    const queries = {
      entity_id: entityId,
      auth_role: authRole,
      role_enabled: true,
      limit: 60,
    }

    if (credentials && entityId) {
      getRoles({ credentials, queries, meta: { overwriteReducer: true } })
    }
  }

  componentDidUpdate(prevProps) {
    const [
      prevCredentials,
      prevEntityId,
    ] = getMany(prevProps, [
      'credentials',
      'entityId',
    ])
    const [
      credentials,
      entityId,
      getRoles,
      authRole,
    ] = getMany(this.props, [
      'credentials',
      'entityId',
      'getRoles',
      'authRole',
    ])

    if ((!isEmpty(credentials) && isEmpty(prevCredentials) && entityId) || (!isEmpty(credentials) && entityId !== prevEntityId)) {
      const queries = {
        entity_id: entityId,
        auth_role: authRole,
        role_enabled: true,
        limit: 60,
      }
      getRoles({ credentials, queries, meta: { overwriteReducer: true } })
    }
  }

  render() {
    return (
      <CreateTeamMember
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamMemberC)
