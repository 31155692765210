import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import ExportDataForm from './ExportDataForm'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatNumber from 'utilities/formatters/formatNumber'
import camelCaseToTitleCase from 'utilities/display/camelCaseToTitleCase'
import getPageName from 'utilities/get/getPageName'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { ONBOARDING_SETTLEMENTS_LIST_PAGE } from 'constants/pageConstants'
import isObject from 'lodash/isObject'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'
import size from 'lodash/size'
import keys from 'lodash/keys'

import {
  GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST,
  GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
} from 'constants/flowConstants'

import {
  PATHNAME,
  SEARCH,
} from 'constants/queryConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const { data } = props
  const firstItem = head(data)
  const dataKeys = keys(firstItem)
  const itemsCount = formatNumber(size(data))
  const pathName = get(state, PATHNAME)
  const search = get(state, SEARCH)
  const queries = parseUrlQueries(search)
  const pageName = getPageName(pathName, queries)
  const isFetching = get(state, `getAllReviewQueueItemsR.${FETCHING}`)

  const filteredKeys = removeUndefined(
    map(dataKeys, (key = '') => {
      const value = get(firstItem, key)

      if (!isObject(value) && key.search('display') < 0) return key

      return undefined
    }),
  )

  const initialValues = reduce(filteredKeys, (total, key) => merge({}, total, {
    [key]: {
      checked: true,
      title: key === 'id' ? 'ID' : camelCaseToTitleCase(key),
    },
  }), {})

  const [
    progressCount,
    progressTotal,
    isFetchingAllReviewQueueItems,
    totalSettlementsCount,
  ] = getMany(state, [
    'getAllReviewQueueItemsR.progressCount',
    'getAllReviewQueueItemsR.progressTotal',
    `getAllReviewQueueItemsR.${FETCHING}`,
    'linksR.REVIEW_QUEUE.page.count',
  ])

  const createdAtLte = get(queries, 'created_at.lte')
  const createdAtGte = get(queries, 'created_at.gte')
  const createdAtLteDate = moment(createdAtLte)
  const createdAtGteDate = moment(createdAtGte)
  const createdAtDiff = moment.duration(createdAtLteDate.diff(createdAtGteDate)).asDays()
  const showExportAll = pageName === ONBOARDING_SETTLEMENTS_LIST_PAGE // TODO: convert logic to page to function map
  const enableExportAll = createdAtGte && createdAtDiff <= 7

  const progress = progressCount ? formatNumber(progressCount / progressTotal * 100) : 0
  const progressPercent = `${progress}%`

  return {
    credentials,
    data,
    dataKeys: filteredKeys,
    initialValues,
    itemsCount,
    pageName,
    isFetching,
    isFetchingAllReviewQueueItems,
    progressPercent,
    totalSettlementsCount,
    showExportAll,
    enableExportAll,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancelExportAll: () => dispatch({ type: GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST }),
    exportAllReviewQueueItems: ({ credentials, fileNameCSV }) => dispatch({
      type: GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
      payload: {
        credentials,
        fileNameCSV,
        newValues: { // resets progress bar after clicking out of Advanced Export modal
          progressCount: 0,
          progressTotal: 0,
          [FETCHING]: true,
        },
      },
    }),
  }
}

class ExportDataFormC extends Component {
  constructor(props) {
    super(props)

    this.state = { newFetchAll: false }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFetchingAllReviewQueueItems: prevIsFetchingAllReviewQueueItems } = prevProps
    const { isFetchingAllReviewQueueItems } = this.props

    if (!prevIsFetchingAllReviewQueueItems && isFetchingAllReviewQueueItems) {
      this.setState({ newFetchAll: true })
    }
  }

  componentWillUnmount() {
    const { cancelExportAll } = this.props

    cancelExportAll()
  }

  submitExportAllReviewQueueItems = () => {
    const {
      credentials,
      fileNameCSV,
      exportAllReviewQueueItems,
    } = this.props

    exportAllReviewQueueItems({ credentials, fileNameCSV })
  }

  render() {
    const { newFetchAll } = this.state

    return (
      <ExportDataForm
        newFetchAll={newFetchAll}
        submitExportAllReviewQueueItems={this.submitExportAllReviewQueueItems}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDataFormC)
