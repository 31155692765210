import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import split from 'lodash/split'

const DateTimestampColumn = ({
  timestamp = '',
}) => {
  if (!timestamp) {
    return '-'
  }

  const formattedDateStrings = split(moment.utc(timestamp).local().format('M/DD/YYYY,h:mm A'), ',')
  const date = formattedDateStrings[0]
  const time = formattedDateStrings[1]

  return (
    <div className='DateTimestampColumn'>
      <TableItemSubtitle
        title={date}
        subtitle={time}
      />
    </div>
  )
}

DateTimestampColumn.propTypes = {
  timestamp: PropTypes.string,
}

export default DateTimestampColumn
