import paymentsAPI from 'utilities/api/paymentsAPI'
import { FEE_PROFILE } from 'constants/apiConstants'

const getPlatformFeeProfileAPI = ({ id, meta, credentials }) => {
  return paymentsAPI.get({
    credentials,
    path: FEE_PROFILE({ id }),
    meta,
  })
}

export default getPlatformFeeProfileAPI
