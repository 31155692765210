import 'components/Shared/Inputs/FileUploader/FileUploaderS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import formatAmount from 'utilities/formatters/formatAmount'
import { UPLOAD_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import merge from 'lodash/merge'
import map from 'lodash/map'

import {
  DEFAULT_CONFIG,
  DEFAULT_INSTRUCTIONS,
} from 'constants/fileUploaderConstants'

const ReactDropzone = ({
  onFileChange = () => {},
  config = {},
  instructions = {},
  fileIcon,
}) => {
  const [file, setFile] = useState({})
  const [error, setError] = useState('')
  const emptyFile = isEmpty(file)

  const onDrop = (acceptedFiles) => {
    const acceptedFile = head(acceptedFiles)

    setError('')
    setFile(acceptedFile)
    onFileChange({ file: acceptedFile })
  }

  const onDropRejected = (rejectedFiles) => {
    onFileChange({ file: head(rejectedFiles), rejected: true })

    // if the file is too big, set a custom error message
    const rejectedFileSize = get(head(rejectedFiles), 'size')
    const formattedRejectedFileSize = formatAmount(rejectedFileSize / 1000000)
    const allowedFileSize = get(config, 'maxSize')
    const formattedAllowedFileSize = formatAmount(allowedFileSize / 1000000)
    if (rejectedFileSize > get(config, 'maxSize')) {
      setError(`${get(head(rejectedFiles), 'name')}: file size ${formattedRejectedFileSize} MB exceeds max size (${formattedAllowedFileSize} MB)`)
    }
  }
  const uploaderConfig = merge({}, DEFAULT_CONFIG, config, { onDrop, onDropRejected })

  const { getRootProps, getInputProps, isDragActive } = useDropzone(uploaderConfig)

  const uploadInstructions = merge({}, DEFAULT_INSTRUCTIONS, instructions)

  const instructionsComp = map(uploadInstructions, ({ name, message }) => (
    <li key={name} className={classnames('item', { main: name === 'main' })}>
      {message}
    </li>
  ))

  return (
    <div className='ReactDropzone'>
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <ul className='instructions'>
          <li className='item'>

            <span className='icons fa-stack fa-2x'>
              <i className={`fal fa-${UPLOAD_ICON} fa-stack-1x uploadIcon`} />
              {fileIcon && <i className={`${fileIcon} fa-stack-2x fileIcon`} />}
            </span>
          </li>
          {instructionsComp}
        </ul>

        {!emptyFile && <div className='file-display'>{get(file, 'name')}</div>}

      </div>

      { emptyFile && <WarningMessage warningMessage='Please attach a file' /> }
      { error && <WarningMessage warningMessage={error} /> }
    </div>
  )
}

ReactDropzone.propTypes = {
  onFileChange: PropTypes.func,
  config: PropTypes.object,
  instructions: PropTypes.array,
  fileIcon: PropTypes.string,
}

export default ReactDropzone
