import './RequestAdditionalInformationForUnderwritingEntityModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import RequestAdditionalInformationForUnderwritingEntityFormC from 'components/Customer/Forms/RequestAdditionalInformationForUnderwritingEntityForm/RequestAdditionalInformationForUnderwritingEntityFormC'
import { REQUEST_INFORMATION_FOR_ADDITIONAL_VERIFICATION } from 'constants/language/languageConstants'

const RequestAdditionalInformationForUnderwritingEntityModal = ({
  reviewId = '',
  subjectId = '',
}) => {
  return (
    <GenericModal
      title={REQUEST_INFORMATION_FOR_ADDITIONAL_VERIFICATION}
      className='RequestAdditionalInformationForUnderwritingEntityModal'
      Component={RequestAdditionalInformationForUnderwritingEntityFormC}
      reviewId={reviewId}
      subjectId={subjectId}
    />
  )
}

RequestAdditionalInformationForUnderwritingEntityModal.propTypes = {
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
}

export default RequestAdditionalInformationForUnderwritingEntityModal
