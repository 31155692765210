import React, { Component } from 'react'
import { connect } from 'react-redux'
import VoidAuthorization from './VoidAuthorization'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchAuthorizationRequest from 'utilities/actions/patch/patchAuthorizationRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { VOID_AUTHORIZATION_SUCCESS_MSG } from 'constants/language/languageConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const authorizationId = get(props, 'authorizationId')

  return {
    credentials,
    authorizationId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    voidAuthorizationAction: ({
      credentials,
      authorizationId,
      values,
      closeModal,
      successMessage,
    }) => dispatch(patchAuthorizationRequest({
      credentials,
      authorizationId,
      values,
      closeModal,
      successMessage,
    })),
  }
}

class VoidAuthorizationC extends Component {
  voidAuthorization = () => {
    const {
      credentials,
      authorizationId,
      voidAuthorizationAction,
      closeModal,
    } = this.props

    const values = { voidMe: true }

    voidAuthorizationAction({
      credentials,
      authorizationId,
      closeModal,
      values,
      successMessage: VOID_AUTHORIZATION_SUCCESS_MSG,
    })
  }

  render() {
    return (
      <VoidAuthorization
        {...this.props}
        voidAuthorization={this.voidAuthorization}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoidAuthorizationC)
