import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetupMFASMSForm from 'components/Customer/Forms/SetupMFASMSForm/SetupMFASMSForm'
import showModalAction from 'utilities/actions/showModalAction'
import { CONFIRM_PASSWORD_MFA_MODAL } from 'constants/modalConstants'
import isPatching from 'utilities/is/isPatching'

const mapStateToProps = (state) => {
  return {
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmPasswordMFAModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_PASSWORD_MFA_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class SetupMFASMSFormC extends Component {
  render() {
    return <SetupMFASMSForm {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupMFASMSFormC)
