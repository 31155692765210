import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_USER } from 'constants/amplitudeConstants'
import { POST_USER_F_REQUEST } from 'constants/flowConstants'
import { USER_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitNewUserForm = (values, dispatch, props) => {
  const {
    name,
    email,
    platform,
    access,
    template,
  } = values

  const {
    credentials,
    orchestrationModalAction,
  } = props

  if (orchestrationModalAction) {
    dispatch(orchestrationModalAction)
  }

  dispatch({
    type: POST_USER_F_REQUEST,
    payload: {
      values: {
        name,
        email,
        platform,
        access,
        template,
      },
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const userId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const userPath = USER_PATH({ userId, credentialId })

        dispatch(redirectRequest({ path: userPath }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_USER, {
        access,
        credentials,
      }),
    },
  })
}

export default submitNewUserForm
