import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYMENT_LINK,
  PAYMENT_LINKS,
} from 'constants/apiConstants'

const getPaymentLinkAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: PAYMENT_LINK({ paymentLinkId: id }),
    service: PAYMENT_LINKS,
    credentials,
    meta,
  })
}

export default getPaymentLinkAPI
