import React, { Component } from 'react'
import { connect } from 'react-redux'
import Platforms from './Platforms'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { PLATFORMS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `platformsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const platformsPath = PLATFORMS_PATH({ credentialId })

  return {
    initialQueries,
    isFetching,
    platformsPath,
  }
}

class PlatformsC extends Component {
  render() {
    return (
      <Platforms {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PlatformsC)
