import co from 'co'
import patchMonitoringAlertAPI from 'api/finix/patch/patchMonitoringAlertAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import backendMonitoringAlertModel from 'utilities/create/models/backendMonitoringAlertModel'
import map from 'lodash/map'
import get from 'lodash/get'

function * patchMonitoringAlertsO ({
  values,
  credentials,
}) {
  try {
    const { items } = values
    const responses = yield map(items, (item) => {
      const model = backendMonitoringAlertModel({
        values: item,
      })
      return patchMonitoringAlertAPI({
        id: get(item, 'id'),
        values: model,
        credentials,
      })
    })

    const formattedResponse = map(responses, (response, idx) => {
      const id = get(items[idx], 'id')
      const { data, error } = response

      if (error) {
        return { id, errors: get(error, 'response.data._embedded.errors') }
      }

      return data
    })

    return createSuccessResponse({ data: formattedResponse })
  } catch (error) {
    return createErrorResponse({ error })
  }
}

export default co.wrap(patchMonitoringAlertsO)
