import { fieldEmpty } from 'utilities/validate'
import capitalize from 'lodash/capitalize'

import {
  FIRST_NAME,
  LAST_NAME,
} from 'constants/language/languageConstants'

const validateWelcomeNameCaptureForm = (values) => {
  const {
    firstName,
    lastName,
  } = values

  return {
    firstName: fieldEmpty(firstName, capitalize(FIRST_NAME)),
    lastName: fieldEmpty(lastName, capitalize(LAST_NAME)),
  }
}

export default validateWelcomeNameCaptureForm
