import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import GuestPayoutLinkForm from 'components/Customer/Forms/GuestPayoutLinkForm/GuestPayoutLinkForm'
import removeUndefined from 'utilities/remove/removeUndefined'
import getGuestCheckoutTokenVerificationRequest from 'utilities/actions/get/getGuestCheckoutTokenVerificationRequest'
import getGuestCheckoutMerchantPaymentInstrumentsRequest from 'utilities/actions/get/getGuestCheckoutMerchantPaymentInstrumentsRequest'
import validateGuestPayoutLinkForm from 'utilities/validate/validateGuestPayoutLinkForm'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import submitGuestPayoutLinkForm from 'utilities/submit/submitGuestPayoutLinkForm'
import getGuestCheckoutHost from 'utilities/api/getGuestCheckoutHost'
import getMany from 'utilities/get/getMany'
import { GUEST_PAYOUT_LINK_FORM } from 'constants/formConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import { GET_GUEST_CHECKOUT_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'
import { GUEST_PAYOUT_LINK_CONFIRMATION } from 'constants/pathConstants'
import { BE_BANK_ACCOUNT, BE_PAYMENT_CARD } from 'constants/paymentInstrumentConstants'
import { FAILURE } from 'constants/flashNotificationConstants'
import { USA } from 'constants/countryConstants'
import { LIVE_URL } from 'constants/credentialConstants'
import { getCardBrand } from 'constants/bankConstants'
import { ADD_ICON, BANK_ICON } from 'constants/iconConstants'
import map from 'lodash/map'
import head from 'lodash/head'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import concat from 'lodash/concat'

import {
  getPaymentInstrumentSelector,
  getPaymentInstrumentsSelector,
  getPayoutLinksSelector,
} from 'state-layer/selectors'

import {
  GET_RECIPIENT_PAYMENT_INSTRUMENTS,
  LOAD_PAYOUT_LINK,
} from 'constants/amplitudeConstants'

import {
  PUSH_TO_ACH,
  PUSH_TO_CARD,
} from 'constants/transferConstants'

import {
  t,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  BANK_CODE,
  CARD_NUMBER,
  COUNTRY,
  CVC,
  EXPIRATION,
  GUEST_CHECKOUT_ERROR_MESSAGE,
  INSTITUTION_NUMBER,
  NAME,
  TRANSIT_NUMBER,
  ZIP,
  ADD_NEW_PAYOUT_METHOD,
} from 'constants/language/languageConstants'

import {
  change,
  isInvalid,
  isPristine,
  reduxForm,
  getFormValues,
} from 'redux-form'

import {
  AWAITING_PAYOUT,
  COMPLETED,
  DEACTIVATED,
  EXPIRED,
} from 'constants/paymentLinkConstants'

const mapStateToProps = (state, props) => {
  const {
    previewMode,
    noValidation = false,
  } = props

  const payoutLinks = !previewMode ? getPayoutLinksSelector(state) : []
  const payoutLinkId = head(Object.keys(payoutLinks))
  const payoutLink = get(payoutLinks, payoutLinkId, get(props, 'payoutLink', {}))
  const formIsInvalid = isInvalid(GUEST_PAYOUT_LINK_FORM)(state)
  const formIsPristine = isPristine(GUEST_PAYOUT_LINK_FORM)(state)
  const formValues = getFormValues(GUEST_PAYOUT_LINK_FORM)(state)
  const paymentInstrumentIdValue = get(formValues, 'paymentInstrumentId.value')
  const paymentInstrumentSelected = getPaymentInstrumentSelector(state, paymentInstrumentIdValue)
  const paymentInstrumentSelectedType = get(paymentInstrumentSelected, 'instrumentType')
  const isPaymentInstrumentBankAccount = paymentInstrumentSelectedType === BE_BANK_ACCOUNT
  const lng = get(state, 'languageR.lng')

  const [
    recipientFullName,
    recipientBusinessName,
    recipientDoingBusinessAs,
    recipientMerchantId,
    allowedPayoutOperations,
  ] = getMany(payoutLink, [
    'recipientFullName',
    'recipientBusinessName',
    'recipientDoingBusinessAs',
    'recipientMerchantId',
    'allowedPayoutOperations',
  ])

  // we show the finix library form if there's no recipient pre-selected, we don't have a payment instrument selected or the user wants to add a new payment instrument
  const showBillingForm = (previewMode && !recipientFullName) || (!previewMode && (!recipientMerchantId || (recipientMerchantId && paymentInstrumentIdValue === 'newPayoutMethod')))

  const initialValues = (!payoutLinkId && !previewMode) ? undefined : {
    recipientFullName,
    businessName: recipientBusinessName,
    doingBusinessAs: recipientDoingBusinessAs,
    personalAddress: { country: USA },
    businessAddress: { country: USA },
  }

  const payoutOperationToPaymentInstrumentTypeMap = {
    [PUSH_TO_CARD]: BE_PAYMENT_CARD,
    [PUSH_TO_ACH]: BE_BANK_ACCOUNT,
  }

  // get list of payment instruments for dropdown
  const recipientPaymentInstruments = getPaymentInstrumentsSelector(state)
  const filteredRecipientPaymentInstruments = filter(recipientPaymentInstruments, (paymentInstrument) => {
    const { instrumentType } = paymentInstrument
    const allowedInstrumentTypes = map(allowedPayoutOperations, (operation) => payoutOperationToPaymentInstrumentTypeMap[operation])

    return includes(allowedInstrumentTypes, instrumentType)
  })

  const paymentInstruments = map(filteredRecipientPaymentInstruments, (paymentInstrument) => {
    const {
      id,
      instrumentType,
      brand,
      expirationDate,
      maskedFullCardNumber,
      maskedAccountNumber,
    } = paymentInstrument

    const cardIcon = getCardBrand(brand)

    return {
      label: instrumentType === BE_BANK_ACCOUNT ? (
        <div className='flex items-center'>
          <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
          {maskedAccountNumber}
        </div>
      ) : (
        <div className='flex items-center'>
          <i className={`card-brand-icon ${cardIcon}`} />
          {maskedFullCardNumber}
          <span className='secondary-expiry-date'>({expirationDate})</span>
        </div>
      ),
      value: id,
    }
  })

  // add option to create a new payment instrument
  const recipientPaymentInstrumentOptions = concat(
    {
      label: (
        <div className='add-new-payout-method flex items-center'>
          <span className={`add-icon fa fa-${ADD_ICON}`} />
          {ADD_NEW_PAYOUT_METHOD}
        </div>),
      value: 'newPayoutMethod',
    }, paymentInstruments,
  )

  return removeUndefined({
    lng,
    payoutLink,
    payoutLinkId,
    noValidation,
    isFetching: isEmpty(payoutLink),
    formIsInvalid,
    formIsPristine,
    initialValues,
    recipientMerchantId,
    recipientPaymentInstrumentOptions,
    showBillingForm,
    paymentInstrumentIdValue,
    isPaymentInstrumentBankAccount,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestCheckoutPayoutLink: ({ payoutLinkId, meta }) => {
      dispatch({
        type: GET_GUEST_CHECKOUT_PAYOUT_LINK_F_REQUEST,
        payload: {
          id: payoutLinkId,
        },
        meta,
      })
    },
    getHostedFormTokenVerification: ({ meta }) => { dispatch(getGuestCheckoutTokenVerificationRequest({ meta })) },
    updateToken: (token) => { dispatch(change(GUEST_PAYOUT_LINK_FORM, 'token', token)) },
    flashErrorNotification: () => {
      dispatch(flashNotificationAction({
        message: GUEST_CHECKOUT_ERROR_MESSAGE,
        type: FAILURE,
        time: 3000,
      }))
    },
    getGuestCheckoutMerchantPaymentInstruments: ({ merchantId, meta }) => dispatch(getGuestCheckoutMerchantPaymentInstrumentsRequest({ merchantId, meta })),
  }
}

class GuestPayoutLinkFormC extends Component {
  state = {
    env: null,
    token: null,
    form: null,
    sessionKey: null,
    formHasErrors: false,
    isProcessingPayout: false,
    showAchWarning: false,
    country: USA,
  }

  componentDidMount() {
    const {
      previewMode,
      getGuestCheckoutPayoutLink,
      getHostedFormTokenVerification,
    } = this.props

    if (previewMode) {
      // skip token checks for preview mode
      this.initializeTokenForm()
      return
    }

    const tokenQuery = getUrlQuery('bearer_token')

    if (tokenQuery) {
      // store bearer token to state
      this.setState({ token: tokenQuery })

      // clear localStorage to ensure hosted payment links hits the correct env
      localStorage.clear()

      // set the temp bearer token for localStorage for verify call
      localStorage.setItem(AUTH_SESSION, JSON.stringify({
        idToken: tokenQuery,
        tokenType: 'Bearer',
      }))

      // verify bearer token from url
      getHostedFormTokenVerification({
        meta: {
          successCallback: ({ newValues: tokenResponse }) => {
            const payoutLinkId = get(tokenResponse, 'entity_id')
            const isValid = get(tokenResponse, 'is_valid')
            const additionalDetails = get(tokenResponse, 'additional_details', {})
            const bearerToken = get(tokenResponse, 'bearer_token')

            // set the returned bearer token to localStorage for further authentication uses
            localStorage.setItem(AUTH_SESSION, JSON.stringify({
              idToken: bearerToken,
              tokenType: 'Bearer',
            }))

            const {
              expiration_in_minutes: expirationInMinutes,
              expired_session_url: expiredSessionUrl,
              success_return_url: successReturnUrl,
              terms_of_service_url: termsOfServiceUrl,
              unsuccessful_return_url: unsuccessfulReturnUrl,
            } = additionalDetails

            if (!isValid) {
              // redirect to invalid token page
              goToPath({
                pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
                queries: {
                  status: 'expired',
                },
              })
            } else {
              // make fetch for the payout link
              getGuestCheckoutPayoutLink({
                payoutLinkId,
                meta: {
                  actionId: sendAmplitudeActionEvent(LOAD_PAYOUT_LINK, {
                    payoutLinkId,
                    expirationInMinutes,
                    expiredSessionUrl,
                    successReturnUrl,
                    termsOfServiceUrl,
                    unsuccessfulReturnUrl,
                  }),
                },
              })
            }
          },
          errorCallback: () => {
            // there was an error validating the token, take to some error page
            goToPath({
              pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
              queries: {
                status: 'expired',
              },
            })
          },
        },
      })
    } else {
      goToPath({
        pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
        queries: {
          status: 'expired',
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      previewMode,
      payoutLink,
      recipientMerchantId,
      getGuestCheckoutMerchantPaymentInstruments,
    } = this.props

    const { recipientMerchantId: prevRecipientMerchantId } = prevProps

    if (previewMode) {
      return
    }

    const { form } = this.state

    if (form === null && !isEmpty(payoutLink)) {
      const payoutLinkState = get(payoutLink, 'state')

      // redirect if payout link is completed, expired, or deactivated
      if (includes([COMPLETED, EXPIRED, DEACTIVATED, AWAITING_PAYOUT], payoutLinkState)) {
        const payoutLinKStateToStatusMap = {
          [COMPLETED]: 'completed',
          [EXPIRED]: 'expired',
          [DEACTIVATED]: 'deactivated',
          [AWAITING_PAYOUT]: 'awaiting_payout',
        }

        goToPath({
          pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
          queries: {
            status: payoutLinKStateToStatusMap[payoutLinkState],
          },
        })
      } else {
        this.initializeTokenForm()
      }
    }

    if (recipientMerchantId && recipientMerchantId !== prevRecipientMerchantId) {
      getGuestCheckoutMerchantPaymentInstruments({
        merchantId: recipientMerchantId,
        meta: {
          actionId: sendAmplitudeActionEvent(GET_RECIPIENT_PAYMENT_INSTRUMENTS, {
            recipientMerchantId,
          }),
        },
      })
    }
  }

  componentWillUnmount() {
    this.setState({ form: null })
  }

  onUpdate = (state, bin, hasErrors) => {
    const addressCountry = get(state, 'address.country') || {}
    const countryCode = get(addressCountry, 'country', USA)
    // need to update the legal text depending on if USA or CAN
    this.setState({ country: countryCode })
    this.setState({ showAchWarning: !!document.querySelector('.field-holder.account_number') })
    this.setState({ formHasErrors: hasErrors })
  }

  setIsPersonalRecipient = (isPersonalRecipient) => this.setState({ isPersonalRecipient })

  initializeTokenForm = () => {
    const { payoutLink } = this.props
    const { form } = this.state

    const {
      allowedPayoutOperations = [],
      merchantId,
    } = payoutLink

    const env = getGuestCheckoutHost() === LIVE_URL ? 'live' : 'sandbox'
    this.setState({ env })

    window.Finix.Auth(env, merchantId, (sessionKey) => this.setState({ sessionKey }))

    const tokenFormOptions = {
      showAddress: true,
      hideFields: ['address_line1', 'address_line2', 'address_city', 'address_state', 'address_region'],
      labels: {
        name: t(NAME),
        number: t(CARD_NUMBER),
        account_number: t(ACCOUNT_NUMBER),
        bank_code: t(BANK_CODE),
        account_type: t(ACCOUNT_TYPE),
        transit_number: t(TRANSIT_NUMBER),
        institution_number: t(INSTITUTION_NUMBER),
        expiration_date: t(EXPIRATION),
        security_code: t(CVC),
        address_postal_code: t(ZIP),
        address_country: t(COUNTRY),
      },
      placeholders: {
        name: t(NAME),
        security_code: t(CVC),
        address_postal_code: t(ZIP),
      },
      styles: {
        default: {
          color: '#000',
          border: '1px solid #7D90A5',
          borderRadius: '4px',
          padding: '8px 16px',
          fontFamily: 'Helvetica',
          fontSize: '16px',
        },
      },
      onUpdate: this.onUpdate,
    }

    document.querySelector('.pay-button').addEventListener('click', this.submitTokenForm)

    let formFunction = 'TokenForm'
    if (allowedPayoutOperations.length === 1 && allowedPayoutOperations.includes(PUSH_TO_CARD)) formFunction = 'CardTokenForm'
    if (allowedPayoutOperations.length === 1 && allowedPayoutOperations.includes(PUSH_TO_ACH)) formFunction = 'BankTokenForm'

    if (!form && window.Finix && window.Finix.TokenForm) {
      this.setState({ form: window.Finix[formFunction]('payment-instrument-form', tokenFormOptions) })
    }
  }

  submitTokenForm = () => {
    const {
      env,
      form,
    } = this.state

    const {
      payoutLink,
      handleSubmit,
      updateToken,
      flashErrorNotification,
      previewMode,
      paymentInstrumentIdValue,
    } = this.props

    if (previewMode) {
      return
    }

    // if we have a payment instrument id, call the submit function directly
    if (paymentInstrumentIdValue && paymentInstrumentIdValue !== 'newPayoutMethod') {
      handleSubmit(submitGuestPayoutLinkForm)()
      return
    }

    const applicationId = get(payoutLink, 'applicationId')

    this.setState({ isProcessingPayout: true })

    form.submit(env, applicationId, (err, res) => {
      const tokenData = get(res, 'data', {})
      const { id } = tokenData

      if (id) {
        updateToken(id)
        handleSubmit(submitGuestPayoutLinkForm)()
      } else {
        this.setState({ isProcessingPayout: false })
        flashErrorNotification()
      }
    })
  }

  render() {
    const {
      token,
      sessionKey,
      formHasErrors,
      isProcessingPayout,
      showAchWarning,
      country,
      isPersonalRecipient,
    } = this.state

    return (
      <GuestPayoutLinkForm
        {...this.props}
        token={token}
        sessionKey={sessionKey}
        formHasErrors={formHasErrors}
        isProcessingPayout={isProcessingPayout}
        showAchWarning={showAchWarning}
        country={country}
        isPersonalRecipient={isPersonalRecipient}
        setIsPersonalRecipient={this.setIsPersonalRecipient}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: GUEST_PAYOUT_LINK_FORM,
    validate: validateGuestPayoutLinkForm,
  })(GuestPayoutLinkFormC),
)
