const formatCanadianPostalCode = (value) => {
  if (!value) {
    return ''
  }

  const onlyNumsOrLetters = value.replace(/[^A-Za-z0-9*]/g, '')

  if (onlyNumsOrLetters.length <= 3) return onlyNumsOrLetters
  return `${onlyNumsOrLetters.slice(0, 3)} ${onlyNumsOrLetters.slice(3, 6)}`
}

export default formatCanadianPostalCode
