import './RoleDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PermissionsC from 'components/Customer/Pages/Permissions/PermissionsC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  DETAILS,
  ROLE_DETAILS_SUBHEADER,
} from 'constants/language/languageConstants'

const RoleDetails = ({
  permissions = [],
  type = '',
  isFetching,
  actions = [],
  authRole = '',
}) => {
  return (
    <div className='RoleDetails'>
      <PageSectionV2C
        isFetching={isFetching}
        title={DETAILS}
        subTitle={ROLE_DETAILS_SUBHEADER}
        actions={actions}
      >
        <PermissionsC
          userPermissions={permissions}
          type={type}
          authRole={authRole}
        />
      </PageSectionV2C>
    </div>
  )
}

RoleDetails.propTypes = {
  permissions: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  type: PropTypes.string,
  authRole: PropTypes.string,
}

export default RoleDetails
