import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import BalanceTransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/BalanceTransferStatus'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import formatMoney from 'utilities/formatters/formatMoney'
import { sourceDestinationMapping } from 'constants/balanceTransferConstants'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  ID,
  DESCRIPTION,
  SOURCE,
  DESTINATION,
  STATE,
  CREATED_ON,
  AMOUNT,
  EXTERNAL_REFERENCE_ID,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(DESCRIPTION, 'description'),
  column(SOURCE, 'source', {
    formatter: ({ source }) => sourceDestinationMapping[source],
    className: 'small',
  }),
  column(DESTINATION, 'destination', {
    formatter: ({ destination }) => sourceDestinationMapping[destination],
    className: 'small',
  }),
  // TODO: ask design about click to copy for this in the future
  column(EXTERNAL_REFERENCE_ID, 'externalReferenceId'),
  column(STATE, 'state', { className: 'small',
    formatter: ({ state }) => <BalanceTransferStatus value={state} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(AMOUNT, ['amount', 'currency'], {
    className: 'amount',
    formatter: ({ amount, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount, currency })} currency={currency} />,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column('', '', {
    className: 'empty',
    formatter: () => <span />,
  }),
]

export default columnDescriptors
