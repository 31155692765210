import paymentsAPI from 'utilities/api/paymentsAPI'
import { MERCHANT_UNDERWRITING_SUBJECT_EVENTS } from 'constants/apiConstants'
import get from 'lodash/get'

const getMerchantUnderwritingSubjectEventsAPI = ({ id, credentials, meta, values }) => {
  const subjectId = id || get(values, 'underwritingReviewSubjectId')

  return paymentsAPI.get({
    meta,
    path: MERCHANT_UNDERWRITING_SUBJECT_EVENTS({ subjectId }),
    credentials,
  })
}

export default getMerchantUnderwritingSubjectEventsAPI
