import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import { CREDENTIAL_ENVIRONMENTS } from 'constants/credentialConstants'
import isArray from 'lodash/isArray'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import get from 'lodash/get'

const frontendCredentialsModel = ({ data: credentials }) => {
  const normalizedCredentials = isArray(credentials) || credentials === undefined ? credentials : [credentials]
  const dashboardServiceV2 = isDashboardServiceV2()

  const credentialModels = map(normalizedCredentials, (credential) => {
    const [
      id,
      host,
      role,
      name,
      username,
      password,
      identityId,
      features = { payouts: false, transfers: true },
      encryptedPassword,
      payouts,
      transfers,
      userId,
      createdAt,
      token,
      paymentFacilitatorEnabled = true,
      pushToCardEnabled = false,
      dashboardUserRoleId,
      dashboardUserRoleName,
      dashboardUserRoleType,
      lastActivity,
      env,
      enabled,
    ] = getMany(credential, [
      'id',
      dashboardServiceV2 ? 'dashboard_host' : 'attributes.host',
      dashboardServiceV2 ? 'role_name' : 'attributes.role',
      dashboardServiceV2 ? 'credential_name' : 'attributes.name',
      dashboardServiceV2 ? 'username' : 'attributes.username',
      dashboardServiceV2 ? 'password' : 'attributes.password',
      dashboardServiceV2 ? 'identity_id' : 'attributes.identity-id',
      dashboardServiceV2 ? 'features' : 'attributes.features',
      'encrypted_password',
      'push_to_card_enabled',
      'payment_facilitator_enabled',
      'dashboard_user_id',
      'created_at',
      'token',
      'payment_facilitator_enabled',
      'push_to_card_enabled',
      'dashboard_user_role_id',
      'dashboard_user_role_name',
      'dashboard_user_role_type',
      'last_activity',
      'env',
      'enabled',
    ])

    const authToken = token || encryptedPassword || window.btoa(`${username}:${password}`)

    return removeUndefined({
      id,
      host,
      role,
      name,
      authToken,
      environment: get(CREDENTIAL_ENVIRONMENTS, env),
      identityId,
      features: dashboardServiceV2 ? { payouts, transfers } : features,
      username: username || window.atob(authToken).split(':')[0],
      userId,
      createdAt,
      displayCreatedAt: formatDate({ date: createdAt }),
      paymentFacilitatorEnabled,
      pushToCardEnabled,
      dashboardUserRoleId,
      dashboardUserRoleName,
      displayDashboardUserRoleName: convertSnakeToSentenceCase(dashboardUserRoleName),
      dashboardUserRoleType,
      lastActivity,
      displayLastActivity: formatDate({ date: lastActivity }),
      enabled,
    })
  })

  return keyBy(credentialModels, 'id')
}

export default frontendCredentialsModel
