import merge from 'lodash/merge'
import get from 'lodash/get'
import getFormSyncErrors from 'utilities/get/getFormSyncErrors'

import {
  POST_IDENTITY_F_FAILURE,
} from 'constants/flowConstants'

const beToFeFieldMap = {
  'entity.mcc': 'mcc',
}

const identityFormR = (state, action) => {
  switch (action.type) {
    case POST_IDENTITY_F_FAILURE: {
      const errors = get(action, 'errors', {})

      return merge({}, state, {
        syncErrors: getFormSyncErrors({ errors, fieldMap: beToFeFieldMap }),
      })
    }

    default:
      return state || {}
  }
}

export default identityFormR
