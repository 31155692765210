import './ContactSalesModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ContactSalesForm from 'components/Customer/Forms/ContactSalesForm/ContactSalesForm'
import { CONTACT_SALES } from 'constants/language/languageConstants'

const ContactSalesModal = ({
  credentials,
  currentUserEmail,
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title={CONTACT_SALES}
      className='ContactSalesModal'
      Component={ContactSalesForm}
      credentials={credentials}
      currentUserEmail={currentUserEmail}
      closeModal={closeModal}
    />
  )
}

ContactSalesModal.propTypes = {
  credentials: PropTypes.object,
  currentUserEmail: PropTypes.string,
  closeModal: PropTypes.func,
}

export default ContactSalesModal
