import { GET_DISPUTE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getDisputeRequest = ({
  disputeId,
  credentials,
}) => createAction({
  type: GET_DISPUTE_F_REQUEST,
  id: disputeId,
  credentials,
})

export default getDisputeRequest
