import './ReviewQueueMerchantsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { GET_REVIEW_QUEUE } from 'constants/flowConstants'
import { REVIEW_QUEUE } from 'constants/linkConstants'
import { getPendingReviewQueueMerchantsSelector } from 'state-layer/selectors'

const ReviewQueueMerchants = ({
  // reviewQueueSettlementsPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  selectedItemsKey,
  selectedItemsData = {},
  selector = getPendingReviewQueueMerchantsSelector,
  merchantPath = '',
}) => {
  return (
    <div className='ReviewQueueMerchants'>
      <TableC
        flow={GET_REVIEW_QUEUE}
        columnDescriptors={columnDescriptors}
        selector={selector}
        linksKey={REVIEW_QUEUE}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        path={merchantPath}
        fileNameCSV='ReviewQueue_Merchants'
      />
    </div>
  )
}

ReviewQueueMerchants.propTypes = {
  // reviewQueueSettlementsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  selectedItemsKey: PropTypes.string,
  selectedItemsData: PropTypes.object,
  selector: PropTypes.func,
  merchantPath: PropTypes.string,
}

export default ReviewQueueMerchants
