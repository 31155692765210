import moment from 'moment'
import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import merge from 'lodash/merge'
import getMany from 'utilities/get/getMany'

import {
  IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  POST_MERCHANT_IDENTITY_F_REQUEST,
  PATCH_MERCHANT_IDENTITY_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_MERCHANT_IDENTITY,
  EDIT_MERCHANT_IDENTITY,
} from 'constants/amplitudeConstants'

import {
  createIdentityModalAction,
  editIdentityModalAction,
} from 'constants/orchestrationConstants'

const submitMerchantIdentityForms = (values, dispatch, props) => {
  const {
    credentials,
    recordId,
    records,
    action,
    formValues,
  } = props

  const [
    selectedApplication,
    businessInfo = {},
    principals = [],
    processingInfo = {},
    additionalUnderwritingInfo = {},
    bankAccount = {},
  ] = getMany(formValues, [
    'selectedApplication',
    'businessInfo',
    'principalsInfo.principals',
    'processingInfo',
    'additionalUnderwritingInfo',
    'bankAccount',
  ])

  const isFlex = isFlexPlatform()
  const userAgent = get(navigator, 'userAgent')
  const currentTime = moment().utc().toISOString()
  const updatedAdditionalUnderwritingInfo = additionalUnderwritingInfo ? merge({}, additionalUnderwritingInfo, { creditCheckUserAgent: userAgent, merchantAgreementUserAgent: userAgent, creditCheckTimestamp: currentTime, merchantAgreementTimestamp: currentTime }) : {}
  const merchantIdentityPrincipal = principals[0]
  const merchantIdentity = merge({}, merchantIdentityPrincipal, businessInfo, processingInfo, selectedApplication, updatedAdditionalUnderwritingInfo)
  const associatedIdentities = principals.slice(1)

  const valuesToSubmit = {
    merchantIdentity: merge({}, merchantIdentity, {
      mcc: get(merchantIdentity, 'mcc.value') || get(merchantIdentity, 'mcc'),
    }),
    associatedIdentities,
    bankAccount,
  }

  let eventType = ''

  if (action === POST_MERCHANT_IDENTITY_F_REQUEST) {
    eventType = CREATE_MERCHANT_IDENTITY

    dispatch(createIdentityModalAction)
  }

  if (action === PATCH_MERCHANT_IDENTITY_F_REQUEST) {
    eventType = EDIT_MERCHANT_IDENTITY

    dispatch(editIdentityModalAction(isFlex))
  }

  dispatch({
    type: action,
    payload: {
      values: valuesToSubmit,
      credentials,
      id: recordId,
    },
    meta: {
      records,
      successCallback: ({ newValues }) => {
        const identityId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const identityPath = IDENTITY_PATH({ identityId, credentialId })

        if (identityId && identityId !== 'undefined') {
          dispatch(redirectRequest({ path: identityPath }))
        }
      },
      actionId: sendAmplitudeActionEvent(eventType, {
        id: recordId,
        credentials,
      }),
    },
  })
}

export default submitMerchantIdentityForms
