import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import frontendFileModel from 'utilities/create/models/frontendFileModel'
import frontendApplicationModel from './frontendApplicationModel'
import frontendPlatformModel from './frontendPlatformModel'
import split from 'lodash/split'
import get from 'lodash/get'
import omit from 'lodash/omit'

const frontendComplianceFormTemplateModel = ({ data: complianceFormTemplate }) => {
  const [
    id,
    type,
    version,
    name,
    linkedTo,
    linkedType,
    createdAt,
    updatedAt,
    complianceFormTemplateData,
    formsCount,
    fileId,
    file,
    fileStatus,
    fileType,
    application,
    platform,
  ] = getMany(complianceFormTemplate, [
    'id',
    'type',
    'version',
    'display_name',
    'linked_to',
    'linked_type',
    'created_at',
    'updated_at',
    'compliance_form_template_data',
    'compliance_forms_count',
    'file_id',
    'file',
    'file_status',
    'file_type',
    'application',
    'platform',
  ])

  const [
    qsaBusinessName,
    qsaContactName,
    qsaTitle,
    qsaTelephone,
    qsaEmail,
    qsaUrl,
    qsaLine1,
    qsaCity,
    qsaRegion,
    qsaPostalCode,
    qsaCountry,
    typeMerchantBusiness,
    typeMerchantBusinessOther,
    typePaymentChannelsServe,
    paymentChannelsCovered,
    howAndInWhatCapacityDoesYourBusinessHandleCardholderData,
    facilityLocations,
    oneOrMorePaymentApplications,
    paymentApplicationsUsed,
    provideHighLevelDescriptionEnvironment,
    networkSegmentation,
    usedQir,
    nameOfQirCompany,
    qirIndividualName,
    descriptionOfServicesProvidedByQir,
    companyShareCardholderDataWithThirdPartyServiceProviders,
    thirdPartyServiceProviders,
    eligibilityToCompleteSaqA,
    vendorSuppliedDefaultsChangedBeforeInstallOnNetwork,
    unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork,
    systemAndSoftwareProtectedFromKnownVulnerabilities,
    criticalSecurityPatchesInstalledWithinOneMonthOfRelease,
    usersAssignedUniqueIdBeforeAccessToSystem,
    accessForTerminatedUsersRemoved,
    authenticateUsersUsingPasswordOrTokenOrBiometric,
    userPasswordParametersConfiguredToMeetReqs,
    groupSharedAccountsPasswordsAuthenticationMethodsProhibited,
    allMediaPhysicallySecured,
    strictControlOverDistributionOfMedia,
    mediaClassifiedToDetermineSensitivityOfData,
    mediaSentCanBeAccuratelyTracked,
    managementApprovalObtainedToMoveMedia,
    strictControlMaintainedOverStorageAndAccessibilityOfMedia,
    mediaDestroyedWhenNoLongerNeeded,
    hardCopyMaterialsCrosscutShreddedIncineratedOrPulped,
    storageContainersForMaterialsDestroyedToPreventAccessToContents,
    listOfServiceProvidersMaintained,
    writtenAgreementMaintained,
    establishedProcessForEngagingServicesProviders,
    programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually,
    informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity,
    incidentResponsePlanBeenCreatedInCaseOfSystemBreach,
    constraints,
    objective,
    identifiedRisk,
    definitionOfCompensatingControls,
    validationOfCompensatingControls,
    maintenance,
    explanationOfNonApplicability,
    pciDssValidationCompliant,
    pciDssValidationCompliantWithLegalException,
    compliantWithLegalExceptionDetailsAffectedRequirement1,
    compliantWithLegalExceptionDetailsAffectedRequirementDetails1,
    compliantWithLegalExceptionDetailsAffectedRequirement2,
    compliantWithLegalExceptionDetailsAffectedRequirementDetails2,
    pciDssSelfAssessmentQuestionnaireCompleted,
    informationWithinSaqRepresentsResultsOfMyAssessmentInAllMaterialRespects,
    confirmedThatPaymentSystemDoesntStoreSensitiveAuthenticationData,
    willMaintainPciDssCompliance,
    onEnvironmentChangeReassessEnvironmentAndImplementAdditionalPciDssReqs,
    noEvidenceOfFullTrackData,
    asvScansCompletedByPciSscAsv,
    qsaSignature,
    qsaInvolvedDescribeRolePerformed,
    qsaDate,
    qsaDulyAuthorizedOfficerName,
    qsaCompany,
    isaInvolvedDescribeRolePerformed,
    doNotUserVendorSuppliedDefaults,
    doNotUserVendorSuppliedDefaultsDetails,
    developAndMaintainSecureSystems,
    developAndMaintainSecureSystemsDetails,
    identifyAndAuthenticateAccessToSystemComponents,
    identifyAndAuthenticateAccessToSystemComponentsDetails,
    restrictPhysicalAccessToCardholder,
    restrictPhysicalAccessToCardholderDetails,
    maintainAPolicyAddressesInformationSecurity,
    maintainAPolicyAddressesInformationSecurityDetails,
  ] = getMany(complianceFormTemplateData, [
    'qualified_security_assesor.company_name',
    'qualified_security_assesor.lead_qsa_contact_name',
    'qualified_security_assesor.title',
    'qualified_security_assesor.telephone',
    'qualified_security_assesor.email',
    'qualified_security_assesor.url',
    'qualified_security_assesor.business_address',
    'qualified_security_assesor.city',
    'qualified_security_assesor.state_province',
    'qualified_security_assesor.zip',
    'qualified_security_assesor.country',
    'type_merchant_business',
    'type_merchant_business.others_description',
    'type_payment_channels_busniess_serve',
    'payment_channels_covered',
    'how_and_in_what_capacity_does_your_business_handle_cardholder_data',
    'facility_locations',
    'one_or_more_payment_applications',
    'payment_applications_used',
    'provide_high_level_description_environnment',
    'network_segmentation',
    'qualified_integrator_reseller.used_qir',
    'qualified_integrator_reseller.name_of_qir_company',
    'qualified_integrator_reseller.quailified_integrator_and_reseller_individual_name',
    'qualified_integrator_reseller.description_of_services_provided_by_qir',
    'company_share_cardholder_data_with_third_party_service_providers',
    'third_party_service_providers',
    'eligibility_to_complete_SAQ_A',
    'self_assesment_questionnaire.vendor_supplied_defaults_changed_before_install_on_network',
    'self_assesment_questionnaire.unnecessary_default_accounts_removed_or_disabled_before_install_on_network',
    'self_assesment_questionnaire.system_and_software_protected_from_known_vulnerabilities',
    'self_assesment_questionnaire.critical_security_patches_installed_within_one_month_of_release',
    'self_assesment_questionnaire.users_assigned_unique_id_before_access_to_system',
    'self_assesment_questionnaire.access_for_terminated_users_removed',
    'self_assesment_questionnaire.authenticate_users_using_passord_or_token_or_biometric',
    'self_assesment_questionnaire.user_password_parameters_configured_to_meet_reqs',
    'self_assesment_questionnaire.group_shared_accounts_passwords_authentication_methods_prohibited',
    'self_assesment_questionnaire.all_media_physically_secured',
    'self_assesment_questionnaire.strict_control_over_distribution_of_media',
    'self_assesment_questionnaire.media_classified_to_determine_sensitivity_of_data',
    'self_assesment_questionnaire.media_sent_can_be_accurately_tracked',
    'self_assesment_questionnaire.management_approval_obtained_to_move_media',
    'self_assesment_questionnaire.strict_control_maintained_over_storage_and_accessibility_of_media',
    'self_assesment_questionnaire.media_detroyed_when_no_longer_needed',
    'self_assesment_questionnaire.hardcopy_materials_crosscut_shredded_incinerated_or_pulped',
    'self_assesment_questionnaire.storage_containers_for_materials_destroyed_to_prevent_access_to_contents',
    'self_assesment_questionnaire.list_of_service_providers_maintained',
    'self_assesment_questionnaire.written_agreement_maintained',
    'self_assesment_questionnaire.established_process_for_enagaing_services_providers',
    'self_assesment_questionnaire.program_maintained_to_monitor_service_providers_pci_dss_status_at_least_annually',
    'self_assesment_questionnaire.information_maintained_about_which_pci_dss_reqs_per_service_provider_and_managed_by_entity',
    'self_assesment_questionnaire.incident_response_plan_been_created_in_case_of_system_breach',
    'pci_dss_compensating_controls.constraints',
    'pci_dss_compensating_controls.objective',
    'pci_dss_compensating_controls.identified_risk',
    'pci_dss_compensating_controls.definition_of_compensating_controls',
    'pci_dss_compensating_controls.validation_of_compensating_controls',
    'pci_dss_compensating_controls.maintenance',
    'explanation_of_non_applicability',
    'pci_dss_validation.compliant',
    'pci_dss_validation.compliant_with_legal_exception',
    'pci_dss_validation.compliance_with_legal_exception_affected_requirement_1',
    'pci_dss_validation.compliance_with_legal_exception_details_legal_constraint_1',
    'pci_dss_validation.compliance_with_legal_exception_affected_requirement_2',
    'pci_dss_validation.compliance_with_legal_exception_details_legal_constraint_2',
    'pci_dss_validation.pci_dss_self_assesment_questionnaire_completed',
    'pci_dss_validation.information_within_saq_represets_results_of_my_assessment_in_all_material_respects',
    'pci_dss_validation.confimed_that_payment_system_doesnt_store_sensitive_authentication_data',
    'pci_dss_validation.will_maintain_pci_dss_compaince',
    'pci_dss_validation.on_environment_change_reassess_environment_and_implement_additional_pci_dss_reqs',
    'pci_dss_validation.no_evidence_of_full_track_data',
    'pci_dss_validation.asv_scans_completed_by_pci_ssc_asv',
    'qualified_security_assessor_attestation.signature',
    'qualified_security_assessor_attestation.qsa_involved_describe_role_performed',
    'qualified_security_assessor_attestation.date',
    'qualified_security_assessor_attestation.duty_authorized_officer_name',
    'qualified_security_assessor_attestation.qsa_company',
    'isa_involved_describe_role_performed',
    'action_plan_non_compliant_requirements.do_not_user_vendor_supplied_defaults',
    'action_plan_non_compliant_requirements.do_not_user_vendor_supplied_defaults_description',
    'action_plan_non_compliant_requirements.develop_and_maintain_secure_systems',
    'action_plan_non_compliant_requirements.develop_and_maintain_secure_systems_description',
    'action_plan_non_compliant_requirements.identify_and_authenticate_access_to_system_components',
    'action_plan_non_compliant_requirements.identify_and_authenticate_access_to_system_components_description',
    'action_plan_non_compliant_requirements.restrict_physical_access_to_cardholder',
    'action_plan_non_compliant_requirements.restrict_physical_access_to_cardholder_description',
    'action_plan_non_compliant_requirements.maintain_a_policy_adresses_information_security',
    'action_plan_non_compliant_requirements.maintain_a_policy_adresses_information_security_description',
  ])

  const createComplianceFormTemplateForm = {
    name,
    type,
  }

  const complianceAssessmentInformationForm = {
    qsaBusinessName,
    qsaContactName,
    qsaTitle,
    qsaTelephone,
    qsaEmail,
    qsaUrl,
    qsaBusinessAddress: {
      line1: qsaLine1,
      region: qsaRegion,
      city: qsaCity,
      postalCode: qsaPostalCode,
      country: qsaCountry,
    },
    typeMerchantBusiness: omit(typeMerchantBusiness, ['others', 'others_description']),
    typeMerchantBusinessOther,
    typePaymentChannelsServe,
    paymentChannelsCovered,
    howAndInWhatCapacityDoesYourBusinessHandleCardholderData,
    facilityLocations,
    oneOrMorePaymentApplications,
    paymentApplicationsUsed,
    provideHighLevelDescriptionEnvironment,
    networkSegmentation,
    usedQir,
    nameOfQirCompany,
    qirIndividualName,
    descriptionOfServicesProvidedByQir,
    companyShareCardholderDataWithThirdPartyServiceProviders,
    thirdPartyServiceProviders,
    eligibilityToCompleteSaqA,
  }

  const complianceSelfAssessmentQuestionnaireAForm = {
    vendorSuppliedDefaultsChangedBeforeInstallOnNetwork,
    unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork,
    systemAndSoftwareProtectedFromKnownVulnerabilities,
    criticalSecurityPatchesInstalledWithinOneMonthOfRelease,
    usersAssignedUniqueIdBeforeAccessToSystem,
    accessForTerminatedUsersRemoved,
    authenticateUsersUsingPasswordOrTokenOrBiometric,
    userPasswordParametersConfiguredToMeetReqs,
    groupSharedAccountsPasswordsAuthenticationMethodsProhibited,
    allMediaPhysicallySecured,
    strictControlOverDistributionOfMedia,
    mediaClassifiedToDetermineSensitivityOfData,
    mediaSentCanBeAccuratelyTracked,
    managementApprovalObtainedToMoveMedia,
    strictControlMaintainedOverStorageAndAccessibilityOfMedia,
    mediaDestroyedWhenNoLongerNeeded,
    hardCopyMaterialsCrosscutShreddedIncineratedOrPulped,
    storageContainersForMaterialsDestroyedToPreventAccessToContents,
    listOfServiceProvidersMaintained,
    writtenAgreementMaintained,
    establishedProcessForEngagingServicesProviders,
    programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually,
    informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity,
    incidentResponsePlanBeenCreatedInCaseOfSystemBreach,
  }

  const complianceCompensatingControlsWorksheetForm = {
    constraints,
    objective,
    identifiedRisk,
    definitionOfCompensatingControls,
    validationOfCompensatingControls,
    maintenance,
  }

  const complianceExplanationOfNonApplicabilityForm = {
    explanationOfNonApplicability,
  }

  const formattedDoNotUserVendorSuppliedDefaultsDetail = split(doNotUserVendorSuppliedDefaultsDetails, ' - ', 2)
  const formattedDevelopAndMaintainSecureSystemsDetail = split(developAndMaintainSecureSystemsDetails, ' - ', 2)
  const formattedIdentifyAndAuthenticateAccessToSystemComponentsDetail = split(identifyAndAuthenticateAccessToSystemComponentsDetails, ' - ', 2)
  const formattedRestrictPhysicalAccessToCardholderDetail = split(restrictPhysicalAccessToCardholderDetails, ' - ', 2)
  const formattedMaintainAPolicyAddressesInformationSecurityDetail = split(maintainAPolicyAddressesInformationSecurityDetails, ' - ', 2)

  const complianceValidationAndAttestationDetailsForm = {
    pciDssValidation: pciDssValidationCompliant ? 'compliant' : 'compliant_with_legal_exception',
    compliantWithLegalExceptionDetails: [
      ...(compliantWithLegalExceptionDetailsAffectedRequirement1 ? [{
        affected_requirement: compliantWithLegalExceptionDetailsAffectedRequirement1,
        details: compliantWithLegalExceptionDetailsAffectedRequirementDetails1,
      }] : []),
      ...(compliantWithLegalExceptionDetailsAffectedRequirement2 ? [{
        affected_requirement: compliantWithLegalExceptionDetailsAffectedRequirement2,
        details: compliantWithLegalExceptionDetailsAffectedRequirementDetails2,
      }] : []),
    ],
    acknowledgementOfStatus: {
      pci_dss_self_assesment_questionnaire_completed: pciDssSelfAssessmentQuestionnaireCompleted,
      information_within_saq_represets_results_of_my_assessment_in_all_material_respects: informationWithinSaqRepresentsResultsOfMyAssessmentInAllMaterialRespects,
      confimed_that_payment_system_doesnt_store_sensitive_authentication_data: confirmedThatPaymentSystemDoesntStoreSensitiveAuthenticationData,
      will_maintain_pci_dss_compaince: willMaintainPciDssCompliance,
      on_environment_change_reassess_environment_and_implement_additional_pci_dss_reqs: onEnvironmentChangeReassessEnvironmentAndImplementAdditionalPciDssReqs,
      no_evidence_of_full_track_data: noEvidenceOfFullTrackData,
      asv_scans_completed_by_pci_ssc_asv: asvScansCompletedByPciSscAsv,
    },
    qsaSignature,
    qsaInvolvedDescribeRolePerformed,
    qsaDate,
    qsaDulyAuthorizedOfficerName,
    qsaCompany,
    isaInvolvedDescribeRolePerformed,
    doNotUserVendorSuppliedDefaults,
    developAndMaintainSecureSystems,
    identifyAndAuthenticateAccessToSystemComponents,
    restrictPhysicalAccessToCardholder,
    maintainAPolicyAddressesInformationSecurity,
    doNotUserVendorSuppliedDefaultsDate: get(formattedDoNotUserVendorSuppliedDefaultsDetail, '0'),
    developAndMaintainSecureSystemsDate: get(formattedDevelopAndMaintainSecureSystemsDetail, '0'),
    identifyAndAuthenticateAccessToSystemComponentsDate: get(formattedIdentifyAndAuthenticateAccessToSystemComponentsDetail, '0'),
    restrictPhysicalAccessToCardholderDate: get(formattedRestrictPhysicalAccessToCardholderDetail, '0'),
    maintainAPolicyAddressesInformationSecurityDate: get(formattedMaintainAPolicyAddressesInformationSecurityDetail, '0'),
    doNotUserVendorSuppliedDefaultsDescription: get(formattedDoNotUserVendorSuppliedDefaultsDetail, '1'),
    developAndMaintainSecureSystemsDescription: get(formattedDevelopAndMaintainSecureSystemsDetail, '1'),
    identifyAndAuthenticateAccessToSystemComponentsDescription: get(formattedIdentifyAndAuthenticateAccessToSystemComponentsDetail, '1'),
    restrictPhysicalAccessToCardholderDescription: get(formattedRestrictPhysicalAccessToCardholderDetail, '1'),
    maintainAPolicyAddressesInformationSecurityDescription: get(formattedMaintainAPolicyAddressesInformationSecurityDetail, '1'),
  }

  const applicationModel = frontendApplicationModel({ data: application })
  const platformModel = frontendPlatformModel({ data: platform })
  const applicationName = get(applicationModel, 'businessName')
  const platformName = get(platformModel, 'name')

  return removeUndefined({
    id,
    type,
    version,
    name,
    linkedTo,
    linkedType,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    formsCount,
    fileId,
    file: frontendFileModel({ data: file }),
    fileStatus,
    fileType,
    application: applicationModel,
    platform: platformModel,
    applicationName,
    platformName,
    complianceFormTemplateData: {
      createComplianceFormTemplateForm,
      complianceAssessmentInformationForm,
      complianceSelfAssessmentQuestionnaireAForm,
      complianceCompensatingControlsWorksheetForm,
      complianceExplanationOfNonApplicabilityForm,
      complianceValidationAndAttestationDetailsForm,
    },
  })
}

export default frontendComplianceFormTemplateModel
