import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreateFeeForm = (values) => {
  const {
    amount,
    merchant,
  } = values

  return {
    amount: nonEmptyNumber({ field: amount, name: 'Amount' }),
    merchant: fieldEmpty(merchant, 'Merchant'),
  }
}

export default validateCreateFeeForm
