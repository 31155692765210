import getDeviceAPI from 'api/finix/get/getDeviceAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import removeUndefined from 'utilities/remove/removeUndefined'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendDeviceModel from 'utilities/create/models/frontendDeviceModel'
import normalizeResponse from 'utilities/api/normalizeResponse'
import get from 'lodash/get'
import merge from 'lodash/merge'
import co from 'co'

function * getDeviceO ({
  id,
  credentials,
  queries,
}) {
  const { data: response } = yield getDeviceAPI({ id, credentials, queries })

  if (response) {
    const normalizedDeviceResponse = frontendDeviceModel({ data: normalizeResponse({ content: response }) })
    const merchantId = get(normalizedDeviceResponse, 'merchant')

    const { data: merchantData } = yield getMerchantAPI({
      id: merchantId,
      credentials,
    })
    const applicationId = get(merchantData, 'application')
    const { data: applicationData } = yield getApplicationAPI({
      id: applicationId,
      credentials,
    })

    const device = removeUndefined(merge({}, normalizedDeviceResponse, {
      merchantData: frontendMerchantModel({ data: merchantData }),
      applicationData,
    }))

    return createSuccessResponse({ data: [device] })
  }

  return createErrorResponse({ error: true })
}

export default co.wrap(getDeviceO)
