import {
  fieldEmpty,
} from 'utilities/validate'

const validateRequestAdditionalInformationForUnderwritingEntityForm = (values) => {
  const {
    selectedEvents,
  } = values

  return {
    selectedEvents: fieldEmpty(selectedEvents, 'Selected Events'),
  }
}

export default validateRequestAdditionalInformationForUnderwritingEntityForm
