const parseTaxId = (value) => {
  if (!value) {
    return ''
  }

  const onlyNums = value.replace(/-/g, '')

  return onlyNums.slice(0, 9)
}

export default parseTaxId
