import { GET_WEBHOOKS_WITH_STOPPED_EVENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getWebhooksWithStoppedEventsRequest = ({
  credentials,
  queries,
}) => createAction({
  type: GET_WEBHOOKS_WITH_STOPPED_EVENTS_F_REQUEST,
  queries,
  credentials,
})

export default getWebhooksWithStoppedEventsRequest
