import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationAuthorizations from './ApplicationAuthorizations'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { AUTHORIZATION } from 'constants/language/languageConstants'
import { AUTHORIZATIONS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  convertFilterToResourceIdFilter(AUTHORIZATION),
  DEVICE_ID,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `authorizationsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const authorizationPath = AUTHORIZATIONS_PATH({ credentialId })

  const initialQueries = {
    application_id: applicationId,
  }

  return {
    isFetching,
    authorizationPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: createdLastMonthQueries(),
  }
}

class ApplicationAuthorizationsC extends Component {
  render() {
    return (
      <ApplicationAuthorizations {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationAuthorizationsC)
