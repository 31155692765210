import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import ApplicationPaymentsC from 'components/Customer/Pages/Application/ApplicationPayments/ApplicationPaymentsC'
import ApplicationAuthorizationsC from 'components/Customer/Pages/Application/ApplicationAuthorizations/ApplicationAuthorizationsC'
import ApplicationRefundsC from 'components/Customer/Pages/Application/ApplicationRefunds/ApplicationRefundsC'
import ApplicationDisputesC from 'components/Customer/Pages/Application/ApplicationDisputes/ApplicationDisputesC'
import ApplicationACHReturnsC from 'components/Customer/Pages/Application/ApplicationACHReturns/ApplicationACHReturnsC'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import isPlatform from 'utilities/is/isPlatform'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

import {
  PAYMENTS,
  AUTHORIZATIONS,
  REFUNDS,
  DISPUTES,
  ACH_RETURNS,
} from 'constants/language/languageConstants'

class ApplicationTransactionsTabs extends Component {
  render() {
    const credentials = getCurrentCredentials()
    const isPayout = isPayoutFeature({ credentials })
    const isElavon = isPlatform('elavonpartnerconnect')

    const {
      applicationId,
    } = this.props

    const tabs = [
      {
        id: 'payments',
        name: PAYMENTS,
        component: ApplicationPaymentsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'authorizations',
        name: AUTHORIZATIONS,
        component: ApplicationAuthorizationsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'refunds',
        name: REFUNDS,
        component: ApplicationRefundsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'disputes',
        name: DISPUTES,
        component: ApplicationDisputesC,
        condition: !(isPayout || isElavon),
        props: {
          applicationId,
        },
      },
      {
        id: 'ach-returns',
        name: ACH_RETURNS,
        component: ApplicationACHReturnsC,
        props: {
          applicationId,
        },
      },
    ]

    return (
      <div className='ApplicationTransactionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationTransactionsTabs
