import './CreateBalanceTransferFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import validateCreateBalanceTransferForm from 'utilities/validate/validateCreateBalanceTransferForm'
import { CREATE_BALANCE_TRANSFER_FORM } from 'constants/formConstants'
import { sourceDestinationOptions } from 'constants/balanceTransferConstants'

const CreateBalanceTransferForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
}) => {
  return (
    <form className='CreateBalanceTransferForm' onSubmit={handleSubmit}>
      <div className='section'>
        <Field
          name='source'
          label='Source'
          component={SelectField}
          options={sourceDestinationOptions}
        />

        <Field
          name='destination'
          label='Destination'
          component={SelectField}
          options={sourceDestinationOptions}
        />

        <AmountField
          name='amount'
          label='Amount'
        />

        <Field
          name='description'
          label='Description'
          component={InputField}
        />

        <TagsField
          name='tags'
          label='Tags'
        />
      </div>

      { buttonGroup }
    </form>
  )
}

CreateBalanceTransferForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: CREATE_BALANCE_TRANSFER_FORM,
  validate: validateCreateBalanceTransferForm,
})(CreateBalanceTransferForm)
