import createAction from 'utilities/actions/createAction'
import { GET_APPLICATION_IDENTITIES_F_REQUEST } from 'constants/flowConstants'

// TODO: add unit tests
const getApplicationIdentitiesRequest = ({
  applicationId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_APPLICATION_IDENTITIES_F_REQUEST,
  id: applicationId,
  credentials,
  queries,
  meta,
})

export default getApplicationIdentitiesRequest
