import './FinixIntegrationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import APIKeysC from 'components/Customer/Shared/Page/APIKeys/APIKeysC'

import {
  FINIX_PROCESSING_DETAILS,
  API_KEYS,
} from 'constants/language/languageConstants'

const FinixIntegration = ({
  applicationId = '',
  finixProcessingDetailsCardData = {},
}) => {
  return (
    <div className='FinixIntegration'>
      <PageSectionV2C
        className='finix-processing-details-section'
        title={FINIX_PROCESSING_DETAILS}
        columns={finixProcessingDetailsCardData}
      />

      <PageSectionV2C className='api-keys-section'>
        <APIKeysC
          title={API_KEYS}
          applicationId={applicationId}
        />
      </PageSectionV2C>
    </div>
  )
}

FinixIntegration.propTypes = {
  applicationId: PropTypes.string,
}

export default FinixIntegration
