import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateAPIKeyFormC from 'components/Customer/Forms/CreateAPIKeyForm/CreateAPIKeyFormC'

const CreateAPIKeyModal = ({
  applicationId,
  platformId,
}) => {
  return (
    <GenericModal
      title='Create a new API Key'
      className='CreateAPIKeyModal'
      Component={CreateAPIKeyFormC}
      applicationId={applicationId}
      platformId={platformId}
    />
  )
}

CreateAPIKeyModal.propTypes = {
  applicationId: PropTypes.string,
  platformId: PropTypes.string,
}

export default CreateAPIKeyModal
