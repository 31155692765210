import './EditPaymentInstrumentFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import submitEditPaymentInstrumentForm from 'utilities/submit/submitEditPaymentInstrumentForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import { EDIT_PAYMENT_INSTRUMENT_FORM } from 'constants/formConstants'

import {
  EDIT_PAYMENT_INSTRUMENT_WARNING,
  SAVE,
} from 'constants/language/languageConstants'

const EditPaymentInstrumentForm = ({
  handleSubmit = () => {},
  submitting = false,
  pristine = false,
}) => {
  return (
    <form className='EditPaymentInstrumentForm' onSubmit={handleSubmit(submitEditPaymentInstrumentForm)}>
      <div className='section'>
        <Field
          name='id'
          label='Payment Instrument'
          component={InputField}
          disabled
        />
        <Field
          name='name'
          label='Name'
          component={InputField}
          disabled
        />
        <Field
          name='maskedPaymentInstrumentNumber'
          label='Masked Number'
          component={InputField}
          disabled
        />

        <ToggleSwitchC
          name='enabled'
          label='Enabled'
          form={EDIT_PAYMENT_INSTRUMENT_FORM}
        />

        <WarningMessage warningMessage={EDIT_PAYMENT_INSTRUMENT_WARNING} />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' className='save-payment-instrument-button' submitting={submitting} disabled={pristine} label={SAVE} />
      </div>
    </form>
  )
}

EditPaymentInstrumentForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default EditPaymentInstrumentForm
