import numeral from 'numeral'
import size from 'lodash/size'
import trim from 'lodash/trim'

import {
  EMAIL_REGEX,
  HAVE_LESS_THAN_23_INTEGERS_REGEX,
  PHONE_NUMBER_REGEX,
  FULL_WEBSITE_REGEX,
} from 'constants/regexConstants'

import {
  invalidRegex,
  validateAddress,
  validateBusinessName,
  validateDBA,
  validateMaxAmount,
  validateMerchantCount,
  validateTotalPercentage,
} from 'utilities/validate'

import {
  PHONE_NUMBER_LENGTH_VALIDATION_ERROR,
  WEBSITE_LENGTH_VALIDATION_ERROR,
} from 'constants/language/languageConstants'

const validateCustomerOnboardingFormCustomerInfoForm = (values, props) => {
  const {
    businessName,
    doingBusinessAs,
    businessMerchantCount,
    businessWebsite,
    businessEmail,
    businessPhone,
    businessAddress,
    annualTransactionCount,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
  } = values

  const normalizedCardPresentPercentage = cardPresentPercentage ? numeral(cardPresentPercentage).value() : null
  const normalizedEcommercePercentage = ecommercePercentage ? numeral(ecommercePercentage).value() : null
  const normalizedB2BVolumePercentage = businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : null
  const normalizedB2CVolumePercentage = businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : null
  const normalizedOtherVolumePercentage = otherVolumePercentage ? numeral(otherVolumePercentage).value() : null

  return {
    businessName: validateBusinessName({ field: businessName, canBeEmpty: true }),
    doingBusinessAs: validateDBA({ field: doingBusinessAs, canBeEmpty: true }),
    businessPhone: invalidRegex({ field: businessPhone, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
    businessWebsite: invalidRegex({ field: businessWebsite, name: 'Website', regex: FULL_WEBSITE_REGEX }) || (size(trim(businessWebsite)) > 120 && WEBSITE_LENGTH_VALIDATION_ERROR),
    businessMerchantCount: validateMerchantCount({ field: businessMerchantCount, canBeEmpty: true }),
    businessEmail: invalidRegex({ field: businessEmail, name: 'Email Address', regex: EMAIL_REGEX }),
    businessAddress: validateAddress({ address: businessAddress, names: { region: 'State' }, canBeEmpty: true }),
    annualTransactionCount: invalidRegex({ field: annualTransactionCount, name: 'Annual Transaction Count', regex: HAVE_LESS_THAN_23_INTEGERS_REGEX }),
    maxCardTransactionAmount: validateMaxAmount({ field: maxCardTransactionAmount, name: 'Max Card Transaction Amount', maxAmount: 500000, canBeEmpty: true }),
    maxACHTransactionAmount: validateMaxAmount({ field: maxACHTransactionAmount, name: 'Max ACH Transaction Amount', maxAmount: 500000, canBeEmpty: true }),
    cardPresentPercentage: validateTotalPercentage({ name: 'Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage], exactAmount: 100, canBeEmpty: true }),
    ecommercePercentage: validateTotalPercentage({ name: 'Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage], exactAmount: 100, canBeEmpty: true }),
    businessToBusinessVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100, canBeEmpty: true }),
    businessToConsumerVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100, canBeEmpty: true }),
    otherVolumePercentage: validateTotalPercentage({ name: 'Payment Volume by Customer Type', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100, canBeEmpty: true }),
  }
}

export default validateCustomerOnboardingFormCustomerInfoForm
