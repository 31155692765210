import React from 'react'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { SMS } from 'constants/receiptConstants'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

import {
  SENT_TO,
  DELIVERY_METHOD,
  DATE,
} from 'constants/language/languageConstants'

export const receiptDeliveryAttemptsColumnDescriptors = [
  column(DATE, 'createdAt', {
    className: 'col-33',
    headerFormatter: () => <DateTimestampHeader title={DATE} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(SENT_TO, 'destination', {
    className: 'col-33',
  }),
  column(DELIVERY_METHOD, 'type', {
    className: 'col-33',
    formatter: ({ type }) => {
      if (type === SMS) {
        return type
      }
      return startCase(toLower(type))
    },
  }),
]
