import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import { PATCH_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'

import {
  ADVANCED_SETTINGS,
  DETAILS, PAYOUT_METHODS,
  PRODUCT_OR_SERVICE_DETAILS,
} from 'constants/language/languageConstants'

const PayoutLinkOverview = ({
  isFetching = false,
  payoutLinkDetailsData = [],
  payoutLinkItemsData = [],
  allowedPayoutOperationsData = [],
  advancedSettingsData = [],
  tagsSectionData = [],
  payoutLink = {},
}) => {
  return (
    <div className='PayoutLinkOverview'>
      <PageSectionV2C
        title={DETAILS}
        className='payout-link-details'
        columns={payoutLinkDetailsData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={PRODUCT_OR_SERVICE_DETAILS}
        className='payout-link-items'
        columns={payoutLinkItemsData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={PAYOUT_METHODS}
        className='payout-link-payout-methods'
        columns={allowedPayoutOperationsData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={ADVANCED_SETTINGS}
        className='payout-link-advanced-settings'
        columns={advancedSettingsData}
        isFetching={isFetching}
      />

      <TagsPageSectionV2
        actionType={PATCH_PAYOUT_LINK_F_REQUEST}
        data={tagsSectionData}
        record={payoutLink}
        isFetching={isFetching}
      />
    </div>
  )
}

PayoutLinkOverview.propTypes = {
  isFetching: PropTypes.bool,
  payoutLinkDetailsData: PropTypes.array,
  payoutLinkItemsData: PropTypes.array,
  allowedPayoutOperationsData: PropTypes.array,
  advancedSettingsData: PropTypes.array,
  tagsSectionData: PropTypes.array,
  payoutLink: PropTypes.object,
}

export default PayoutLinkOverview
