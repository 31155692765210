import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import submitFileUploadForm from 'utilities/submit/submitFileUploadForm'
import validateFileUploadForm from 'utilities/validate/validateFileUploadForm'
import { FILE_UPLOAD_FORM } from 'constants/formConstants'
import { IMAGE_FILE_ICON } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  DOCUMENT_TYPE,
  MERCHANT_ACCOUNT,
  NAME_OF_FILE,
  UPLOAD_FILE,
} from 'constants/language/languageConstants'

const FileUploadForm = ({
  handleSubmit = () => {},
  fileTypeOptions = [],
  config = {},
  instructions = [],
  merchant = {},
  invalid = false,
  uploadForIdentity = false,
  merchantAccountOptions = [],
}) => {
  return (
    <form className='FileUploadForm' onSubmit={handleSubmit(submitFileUploadForm)}>
      { get(merchant, 'name') && <div>This document will be associated with {get(merchant, 'name')} for underwriting and compliance purposes.</div> }

      <Field
        name='nameOfFile'
        label={NAME_OF_FILE}
        component={InputField}
      />

      <Field
        name='documentType'
        label={DOCUMENT_TYPE}
        component={SelectField}
        options={fileTypeOptions}
      />

      {uploadForIdentity && (
      <Field
        name='merchantId'
        label={MERCHANT_ACCOUNT}
        component={SelectField}
        options={merchantAccountOptions}
      />
      )}

      <Field
        name='file'
        config={config}
        instructions={instructions}
        component={FileUploader}
        fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={invalid} label={UPLOAD_FILE} />
      </div>
    </form>
  )
}

FileUploadForm.propTypes = {
  handleSubmit: PropTypes.func,
  fileTypeOptions: PropTypes.array,
  config: PropTypes.object,
  instructions: PropTypes.array,
  merchant: PropTypes.object,
  invalid: PropTypes.bool,
  merchantAccountOptions: PropTypes.array,
}

export default reduxForm({
  form: FILE_UPLOAD_FORM,
  validate: validateFileUploadForm,
})(FileUploadForm)
