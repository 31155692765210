import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import removeUndefined from 'utilities/remove/removeUndefined'
import { ROUTING } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import omitBy from 'lodash/omitBy'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'
import find from 'lodash/find'
import map from 'lodash/map'
import omit from 'lodash/omit'
import concat from 'lodash/concat'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import compact from 'lodash/compact'
import values from 'lodash/values'
import head from 'lodash/head'
import isUndefined from 'lodash/isUndefined'
import slice from 'lodash/slice'

import { ORDERED_CHART_CATEGORY_NAMES } from 'constants/chartConstants'
import { SUCCEEDED } from 'constants/stateConstants'

import {
  DRAFT,
  PUBLISHED,
} from 'constants/noteConstants'

import {
  SETTLEMENT,
  MERCHANT,
  IDENTITY,
  APPROVED,
  MANUAL_REVIEW,
  WITHDRAWN,
  ADDITIONAL_VERIFICATION_NEEDED,
} from 'constants/reviewQueueConstants'

import {
  PENDING,
  REJECTED,
  ACCEPTED,
  settlementStatusMapping,
} from 'constants/settlementConstants'

// utility selectors
export const getAllLinksSelector = (state) => state.linksR
export const getLinksSelector = (state, linksKey) => get(getAllLinksSelector(state), linksKey)
export const getTableFiltersSelector = (state) => state.tableFiltersR
export const getSelectedItemsSelector = (state) => state.selectedItemsR
export const getSelectedItemsByKeySelector = (state, key) => omitBy(get(getSelectedItemsSelector(state), key), (selected) => selected === false)
export const getRouteLocationSelector = (state) => get(state, ROUTING)
export const getActiveIds = (state, linksKey) => get(state, `tableR.tableStates.${linksKey}.activeIds`, [])
export const getIsFetchingByLinksKey = (state, linksKey) => get(state, `tableR.tableStates.${linksKey}.${FETCHING}`, false)

export const getConnectedItemsSelector = (
  state,
  id,
  connector = () => {},
  getter = () => {},
) => {
  return removeUndefined(
    reduce(connector(state, id), (total, itemId) => {
      const item = getter(state, itemId)

      return !isEmpty(item) ? merge({}, total, { [itemId]: item }) : total
    }, {}),
  )
}

// standard items selectors
// export const getConfigurations = (state) => state.credentialsR.items
export const getCredentialsSelector = (state) => get(state, 'credentialsR.items', {})
export const getRecentlyActiveCredentialsSelector = (state) => get(state, 'recentlyActiveCredentialsR.items', {})
export const getTransfersSelector = (state) => get(state, 'transfersR.items', {})
export const getEntriesSelector = (state) => get(state, 'entriesR.items', {})
export const getBalanceTransfersSelector = (state) => get(state, 'balanceTransfersR.items', {})
export const getAuthorizationsSelector = (state) => get(state, 'authorizationsR.items', {})
export const getStatementsSelector = (state) => get(state, 'statementsR.items', {})
export const getSettlementsSelector = (state) => get(state, 'settlementsR.items', {})
export const getSettlementGroupsSelector = (state) => get(state, 'settlementGroupsR.items', {})
export const getFeesSelector = (state) => get(state, 'feesR.items', {})
export const getDisputesSelector = (state) => get(state, 'disputesR.items', {})
export const getReportsSelector = (state) => get(state, 'reportsR.items', {})
export const getCategorizedReportsSelector = (state) => get(state, 'categorizedReportsR.items', {})
export const getIdentitiesSelector = (state) => get(state, 'identitiesR.items', {})
export const getMerchantsSelector = (state) => get(state, 'merchantsR.items', {})
export const getComplianceFormsSelector = (state) => get(state, 'complianceFormsR.items', {})
export const getComplianceFormTemplatesSelector = (state) => get(state, 'complianceFormTemplatesR.items', {})
export const getApplicationsSelector = (state) => get(state, 'applicationsR.items', {})
export const getCustomerOnboardingFormsSelector = (state) => get(state, 'customerOnboardingFormsR.items', {})
export const getCustomerOnboardingFormLinksSelector = (state) => get(state, 'customerOnboardingFormLinksR.items', {})
export const getPaymentInstrumentsSelector = (state) => get(state, 'paymentInstrumentsR.items', {})
export const getOnboardingFormsSelector = (state) => get(state, 'onboardingFormsR.items', {})
export const getReviewQueueSelector = (state) => get(state, 'reviewQueueR.items', {})
export const getUsersSelector = (state) => get(state, 'usersR.items', {})
export const getAssociatedIdentitiesSelector = (state) => get(state, 'associatedIdentitiesR.items')
export const getVerificationsSelector = (state) => get(state, 'verificationsR.items')
export const getEvidencesSelector = (state) => get(state, 'evidencesR.items')
export const getProcessorsSelector = (state) => get(state, 'processorsR.items')
export const getWebhooksSelector = (state) => get(state, 'webhooksR.items')
export const getWebhookLogsSelector = (state) => get(state, 'webhookLogsR.items')
export const getWebhooksWithStoppedEventsSelector = (state) => get(state, 'webhooksWithStoppedEventsR.items')
export const getSettingsSelector = (state) => get(state, 'settingsR.items')
export const getApplicationUsersSelector = (state) => get(state, 'applicationUsersR.items')
export const getFormsSelector = (state) => get(state, 'form')
export const getAdminUserMembershipsSelector = (state) => get(state, 'membershipsR.items')
export const getConfigurationsSelector = (state) => get(state, 'configurationsR.items')
export const getPlatformsSelector = (state) => get(state, 'platformsR.items', {})
export const getPlatformProcessorConfigsSelector = (state) => get(state, 'processorConfigsR.items', {})
export const getUsersPermissionsSelector = (state) => get(state, 'userPermissionsR.items', []) // user permissions items are stored in an array
export const getSubscriptionSchedulesSelector = (state) => get(state, 'subscriptionSchedulesR.items', {})
export const getSubscriptionAmountsSelector = (state) => get(state, 'subscriptionAmountsR.items', {})
export const getSubscriptionEnrollmentsSelector = (state) => get(state, 'subscriptionEnrollmentsR.items', {})
export const getPayoutPlansSelector = (state) => get(state, 'payoutPlansR.items', {})
export const getApplePayRegisteredMerchantSelector = (state) => get(state, 'applePayRegisteredMerchantR.items', {})
export const getApplePayCertsSelector = (state) => get(state, 'applePayCertsR.items', {})
export const getMerchantFilesSelector = (state) => get(state, 'merchantFilesR.items', {})
export const getChartsSelector = (state) => get(state, 'chartsR.items', {})
export const getDashboardConfigurations = (state) => get(state, 'dashboardConfigurationsR.items', {})
export const getOnboardingFormDataSelector = (state) => get(state, 'onboardingFormDataR.items', {})
export const getHostedFormTokenVerificationSelector = (state) => get(state, 'hostedFormTokenDataR.items', {})
export const getAllComplianceSettingsSelector = (state) => get(state, 'complianceSettingsR.items', {})
export const getExportsSelector = (state) => get(state, 'exportsR.items', {})
export const getFlowsSelector = (state) => get(state, 'flowsR.formValues', {})
export const getComplianceSelfAssessmentQuestionnaireASelector = (state) => get(getFlowsSelector(state), 'complianceSelfAssessmentQuestionnaireAForm')
export const getPayoutsSettingsSelector = (state) => get(state, 'payoutSettingsR.items')
export const getNotificationSettingsSelector = (state) => get(state, 'notificationSettingsR.items', {})
export const getNotificationsSelector = (state) => get(state, 'notificationsR.items', {})
export const getToastNotificationsCursorSelector = (state) => get(state, 'toastNotificationsR.latestCursor', {})
export const getAPILogsSelector = (state) => get(state, 'apiLogsR.items', {})
export const getUnmaskedItemsSelector = (state) => get(state, 'unmaskedItemsR.items', {})
export const getTabsSelector = (state) => get(state, 'tabsR', {})
export const getSelfServiceSignupFormsSelector = (state) => get(state, 'selfServiceSignupFormR.items', {})
export const getMembersSelector = (state) => get(state, 'membersR.items', {})
export const getMembershipsSelector = (state) => get(state, 'membershipsR.items', {})
export const getRolesSelector = (state) => get(state, 'rolesR.items', {})
export const getAccessFormsSelector = (state) => get(state, 'accessFormsR.items', {})
export const getUnderwritingReviewsSelector = (state) => get(state, 'underwritingReviewsR.items', {})
export const getUnderwritingReportsSelector = (state) => get(state, 'underwritingReportsR.items', {})
export const getAccessFormsAdditionalDocumentsSelector = (state) => get(state, 'accessFormsAdditionalDocumentsR.items', {})
export const getFeeProfilesSelector = (state) => get(state, 'feeProfilesR.items', {})
export const getFeeProfileRulesSelector = (state) => get(state, 'feeProfileRulesR.items', {})
export const getFeeProfileSettingsSelector = (state) => get(state, 'feeProfileSettingsR.items', {})
export const getUnderwritingProfilesSelector = (state) => get(state, 'underwritingProfilesR.items', {})
export const getIdentityFilesSelector = (state) => get(state, 'identityFilesR.items', {})
export const getEntityConfigurationsSelector = (state) => get(state, 'entityConfigurationsR.items', {})
export const getSplitTransfersSelector = (state) => get(state, 'splitTransfersR.items', {})
export const getReversalsSelector = (state) => get(state, 'transferReversalsR.items', {})
export const getUnderwritingSubjectEventsSelector = (state) => get(state, 'underwritingSubjectEventsR.items', {})
export const getBalancesSelector = (state) => get(state, 'balancesR.items', {})
export const getCurrentUsagesSelector = (state) => get(state, 'currentUsagesR.items', {})
export const getDisbursementRulesSelector = (state) => get(state, 'disbursementRulesR.items', {})
export const getBalanceEntriesSelector = (state) => get(state, 'balanceEntriesR.items', {})
export const getPaymentLinksSelector = (state) => get(state, 'paymentLinksR.items', {})
export const getCheckoutFormsSelector = (state) => get(state, 'checkoutFormsR.items', {})
export const getPurchasesSelector = (state) => get(state, 'purchasesR.items', {})
export const getNotesSelector = (state) => get(state, 'notesR.items', {})
export const getDevicesSelector = (state) => get(state, 'devicesR.items', {})
export const getUnderwritingWorkflowsSelector = (state) => get(state, 'underwritingWorkflowsR.items', {})
export const getPlaidLinkModalTokensSelector = (state) => get(state, 'plaidLinkModalTokensR', {})
export const getPlaidPublicTokensSelector = (state) => get(state, 'plaidPublicTokensR', {})
export const getPlaidTokenMetadataSelector = (state) => get(state, 'plaidTokenMetadataR', {})
export const getAllApplicationProcessorsConfigsSelector = (state) => get(state, 'processorConfigsR.items', {})
export const getAuditLogEventsSelector = (state) => get(state, 'auditLogEventsR.items', {})
export const getFraudScoresSelector = (state) => get(state, 'fraudScoresR.items', {})
export const getRunsSelector = (state) => get(state, 'runsR.items', {})
export const getPayoutLinksSelector = (state) => get(state, 'payoutLinksR.items', {})
export const getTransferAttemptsSelector = (state) => get(state, 'transferAttemptsR.items', {})
export const getTransferAttempts = (state) => get(state, 'transferAttemptsR.items', {})
export const getFilesSelector = (state) => get(state, 'filesR.items', {})
export const getBalanceAdjustmentsSelector = (state) => get(state, 'balanceAdjustmentsR.items', {})
export const getReceiptsSelector = (state) => get(state, 'receiptsR.items', {})
export const getReceiptDeliveryAttemptsSelector = (state) => get(state, 'receiptDeliveryAttemptsR.items', {})
export const getSubscriptionsSelector = (state) => get(state, 'subscriptionsR.items', {})
export const getSubscriptionPlansSelector = (state) => get(state, 'subscriptionPlansR.items', {})
export const getMonitoringAlertsSelector = (state) => get(state, 'monitoringAlertsR.items', {})
export const getInstantPayoutsSelector = (state) => get(state, 'fundingTransferAttemptsR.items', {})

// item by id selector
export const getItem = (items, id, emptyValue = {}) => get(items, id, emptyValue)
export const getCredentialSelector = (state, id) => getItem(getCredentialsSelector(state), id)
export const getTransferSelector = (state, id) => getItem(getTransfersSelector(state), id)
export const getEntrySelector = (state, id) => getItem(getEntriesSelector(state), id)
export const getBalanceTransferSelector = (state, id) => getItem(getBalanceTransfersSelector(state), id)
export const getAuthorizationSelector = (state, id) => getItem(getAuthorizationsSelector(state), id)
export const getSettlementSelector = (state, id) => getItem(getSettlementsSelector(state), id)
export const getSettlementGroupSelector = (state, id) => getItem(getSettlementGroupsSelector(state), id)
export const getFeeSelector = (state, id) => getItem(getFeesSelector(state), id)
export const getComplianceFormSelector = (state, id) => getItem(getComplianceFormsSelector(state), id)
export const getComplianceFormTemplateSelector = (state, id) => getItem(getComplianceFormTemplatesSelector(state), id)
export const getDisputeSelector = (state, id) => getItem(getDisputesSelector(state), id)
export const getApplicationSelector = (state, id) => getItem(getApplicationsSelector(state), id)
export const getIdentitySelector = (state, id) => getItem(getIdentitiesSelector(state), id)
export const getEvidenceSelector = (state, id) => getItem(getEvidencesSelector(state), id)
export const getPaymentInstrumentSelector = (state, id) => getItem(getPaymentInstrumentsSelector(state), id)
export const getMerchantSelector = (state, id) => getItem(getMerchantsSelector(state), id)
export const getVerificationSelector = (state, id) => getItem(getVerificationsSelector(state), id)
export const getAssociatedIdentitySelector = (state, id) => getItem(getAssociatedIdentitiesSelector(state), id)
export const getProcessorSelector = (state, id) => getItem(getProcessorsSelector(state), id)
export const getWebhookSelector = (state, id) => getItem(getWebhooksSelector(state), id)
export const getWebhookLogSelector = (state, id) => getItem(getWebhookLogsSelector(state), id)
export const getSettingSelector = (state, id) => getItem(getSettingsSelector(state), id)
export const getApplicationUserSelector = (state, id) => getItem(getApplicationUsersSelector(state), id)
export const getReviewQueueItemSelector = (state, id) => getItem(getReviewQueueSelector(state), id)
export const getFormSelector = (state, key) => getItem(getFormsSelector(state), key)
export const getAdminUserSelector = (state, id) => getItem(getUsersSelector(state), id)
export const getConfigurationSelector = (state, id) => getItem(getConfigurationsSelector(state), id)
export const getPlatformSelector = (state, id) => getItem(getPlatformsSelector(state), id)
export const getPlatformProcessorConfigSelector = (state, id) => getItem(getPlatformProcessorConfigsSelector(state), id)
export const getPayoutPlanSelector = (state, id) => getItem(getPayoutPlansSelector(state), id)
export const getUserPermissionsSelector = (state, id) => getItem(getUsersPermissionsSelector(state), id)
export const getSubscriptionScheduleSelector = (state, id) => getItem(getSubscriptionSchedulesSelector(state), id)
export const getSubscriptionAmountSelector = (state, id) => getItem(getSubscriptionAmountsSelector(state), id)
export const getSubscriptionEnrollmentSelector = (state, id) => getItem(getSubscriptionEnrollmentsSelector(state), id)
export const getChartSelector = (state, id) => getItem(getChartsSelector(state), id)
export const getDashboardConfigurationSelector = (state, id) => getItem(getDashboardConfigurations(state), id)
export const getItemComplianceSettingsSelector = (state, id) => getItem(getAllComplianceSettingsSelector(state), id)
export const getPayoutSettingsSelector = (state, id) => getItem(getPayoutsSettingsSelector(state), id)
export const getAPILogSelector = (state, id) => getItem(getAPILogsSelector(state), id)
export const getUnmaskedItemSelector = (state, id) => getItem(getUnmaskedItemsSelector(state), id)
export const getOnboardingFormDataItemSelector = (state, id) => getItem(getOnboardingFormDataSelector(state), id)
export const getOnboardingFormsItemSelector = (state, id) => getItem(getOnboardingFormsSelector(state), id)
export const getMemberSelector = (state, id) => getItem(getMembersSelector(state), id)
export const getRoleSelector = (state, id) => getItem(getRolesSelector(state), id)
export const getAccessFormSelector = (state, id) => getItem(getAccessFormsSelector(state), id)
export const getUnderwritingReviewSelector = (state, id) => getItem(getUnderwritingReviewsSelector(state), id)
export const getUnderwritingReportSelector = (state, id) => getItem(getUnderwritingReportsSelector(state), id)
export const getFeeProfileSelector = (state, id) => getItem(getFeeProfilesSelector(state), id)
export const getFeeProfileRuleSelector = (state, id) => getItem(getFeeProfileRulesSelector(state), id)
export const getFeeProfileSettingSelector = (state, id) => getItem(getFeeProfileSettingsSelector(state), id)
export const getUnderwritingProfileSelector = (state, id) => getItem(getUnderwritingProfilesSelector(state), id)
export const getSplitTransferSelector = (state, id) => getItem(getSplitTransfersSelector(state), id)
export const getUnderwritingSubjectEventSelector = (state, id) => getItem(getUnderwritingSubjectEventsSelector(state), id)
export const getBalanceSelector = (state, id) => getItem(getBalancesSelector(state), id)
export const getBalanceEntrySelector = (state, id) => getItem(getBalanceEntriesSelector(state), id)
export const getPaymentLinkSelector = (state, id) => getItem(getPaymentLinksSelector(state), id)
export const getPurchaseSelector = (state, id) => getItem(getPurchasesSelector(state), id)
export const getDeviceSelector = (state, id) => getItem(getDevicesSelector(state), id)
export const getUnderwritingWorkflowSelector = (state, id) => getItem(getUnderwritingWorkflowsSelector(state), id)
export const getApplicationProcessorConfigSelector = (state, id) => getItem(getAllApplicationProcessorsConfigsSelector(state), id)
export const getAuditLogEventSelector = (state, id) => getItem(getAuditLogEventsSelector(state), id)
export const getPayoutLinkSelector = (state, id) => getItem(getPayoutLinksSelector(state), id)
export const getTransferAttemptSelector = (state, id) => getItem(getTransferAttemptsSelector(state), id)
export const getFileSelector = (state, id) => getItem(getFilesSelector(state), id)
export const getIdentityFileSelector = (state, id) => get(state, `identityFilesR.items.${id}`, {})
export const getBalanceAdjustmentSelector = (state, id) => getItem(getBalanceAdjustmentsSelector(state), id)
export const getReceiptSelector = (state, id) => getItem(getReceiptsSelector(state), id)
export const getSubscriptionSelector = (state, id) => getItem(getSubscriptionsSelector(state), id)
export const getSubscriptionPlanSelector = (state, id) => getItem(getSubscriptionPlansSelector(state), id)
export const getMonitoringAlertSelector = (state, id) => getItem(getMonitoringAlertsSelector(state), id)
export const getInstantPayoutSelector = (state, id) => getItem(getInstantPayoutsSelector(state), id)

// connector reducer items
export const getAllSettlementEntriesSelector = (state) => get(state, 'settlementEntriesR.items')
export const getAllSettlementFundingTransfersSelector = (state) => get(state, 'settlementFundingTransfersR.items')
export const getAllDisputeEvidencesSelector = (state) => get(state, 'disputeEvidencesR.items')
export const getAllDisputeAdjustmentTransfersSelector = (state) => get(state, 'disputeAdjustmentTransfersR.items')
export const getAllIdentityAssociatedIdentitiesSelector = (state) => get(state, 'identityAssociatedIdentitiesR.items')
export const getAllIdentityAuthorizationsSelector = (state) => get(state, 'identityAuthorizationsR.items')
export const getAllIdentityTransfersSelector = (state) => get(state, 'identityTransfersR.items')
export const getAllIdentityDisputesSelector = (state) => get(state, 'identityDisputesR.items')
export const getAllIdentitySettlementsSelector = (state) => get(state, 'identitySettlementsR.items')
export const getAllIdentityPaymentInstrumentsSelector = (state) => get(state, 'identityPaymentInstrumentsR.items')
export const getAllIdentityMerchantsSelector = (state) => get(state, 'identityMerchantsR.items')
export const getAllIdentityVerificationsSelector = (state) => get(state, 'identityVerificationsR.items')
export const getAllMerchantAuthorizationsSelector = (state) => get(state, 'merchantAuthorizationsR.items')
export const getAllMerchantTransfersSelector = (state) => get(state, 'merchantTransfersR.items')
export const getAllMerchantSettlementsSelector = (state) => get(state, 'merchantSettlementsR.items')
export const getAllMerchantFeesSelector = (state) => get(state, 'merchantFeesR.items')
export const getAllMerchantVerificationsSelector = (state) => get(state, 'merchantVerificationsR.items')
export const getAllMerchantSubscriptionEnrollmentsSelector = (state) => get(state, 'merchantSubscriptionEnrollmentsR.items')
export const getAllSubscriptionScheduleAmountsSelector = (state) => get(state, 'subscriptionScheduleAmountsR.items')
export const getAllPaymentInstrumentTransfersSelector = (state) => get(state, 'paymentInstrumentTransfersR.items')
export const getAllPaymentInstrumentAuthorizationsSelector = (state) => get(state, 'paymentInstrumentAuthorizationsR.items')
export const getAllPaymentInstrumentVerificationsSelector = (state) => get(state, 'paymentInstrumentVerificationsR.items')
export const getAllApplicationAssociatedIdentitiesSelector = (state) => get(state, 'applicationAssociatedIdentitiesR.items')
export const getAllApplicationDisputesSelector = (state) => get(state, 'applicationDisputesR.items')
export const getAllApplicationIdentitiesSelector = (state) => get(state, 'applicationIdentitiesR.items')
export const getAllApplicationMerchantsSelector = (state) => get(state, 'applicationMerchantsR.items')
export const getAllApplicationSettlementsSelector = (state) => get(state, 'applicationSettlementsR.items')
export const getAllApplicationTransfersSelector = (state) => get(state, 'applicationTransfersR.items')
export const getAllApplicationProcessorsSelector = (state) => get(state, 'applicationProcessorsR.items')
export const getAllPlatformProcessorConfigsSelector = (state) => get(state, 'processorConfigsR.items')
export const getAllSubscriptionScheduleEnrollmentsSelector = (state) => get(state, 'subscriptionScheduleEnrollmentsR.items')
export const getAllSubscriptionScheduleFeesSelector = (state) => get(state, 'subscriptionScheduleFeesR.items')
export const getAllComplianceFormTemplateFormsSelector = (state) => get(state, 'complianceFormTemplateFormsR.items')
export const getAllMerchantPayoutSettingsSelector = (state) => get(state, 'merchantPayoutSettingsR.items')
export const getTransferDisputesSelector = (state) => get(state, 'transferDisputesR.items')

// connector reducer items by connector id
const getSettlementEntryConnector = (state, id) => getItem(getAllSettlementEntriesSelector(state), id)
const getSettlementFundingTransferConnector = (state, id) => getItem(getAllSettlementFundingTransfersSelector(state), id)
const getDisputeEvidencesConnector = (state, id) => getItem(getAllDisputeEvidencesSelector(state), id)
const getDisputeAdjustmentTransfersConnector = (state, id) => getItem(getAllDisputeAdjustmentTransfersSelector(state), id)
const getIdentityAssociatedIdentitiesConnector = (state, id) => getItem(getAllIdentityAssociatedIdentitiesSelector(state), id)
const getIdentityAuthorizationsConnector = (state, id) => getItem(getAllIdentityAuthorizationsSelector(state), id)
const getIdentityTransfersConnector = (state, id) => getItem(getAllIdentityTransfersSelector(state), id)
const getIdentityDisputesConnector = (state, id) => getItem(getAllIdentityDisputesSelector(state), id)
const getIdentitySettlementsConnector = (state, id) => getItem(getAllIdentitySettlementsSelector(state), id)
const getIdentityPaymentInstrumentsConnector = (state, id) => getItem(getAllIdentityPaymentInstrumentsSelector(state), id)
const getIdentityMerchantsConnector = (state, id) => getItem(getAllIdentityMerchantsSelector(state), id)
const getIdentityVerificationsConnector = (state, id) => getItem(getAllIdentityVerificationsSelector(state), id)
const getMerchantAuthorizationsConnector = (state, id) => getItem(getAllMerchantAuthorizationsSelector(state), id)
const getMerchantTransfersConnector = (state, id) => getItem(getAllMerchantTransfersSelector(state), id)
const getMerchantSettlementsConnector = (state, id) => getItem(getAllMerchantSettlementsSelector(state), id)
const getMerchantFeesConnector = (state, id) => getItem(getAllMerchantFeesSelector(state), id)
const getMerchantVerificationsConnector = (state, id) => getItem(getAllMerchantVerificationsSelector(state), id)
const getMerchantSubscriptionEnrollmentsConnector = (state, id) => getItem(getAllMerchantSubscriptionEnrollmentsSelector(state), id)
const getSubscriptionScheduleAmountsConnector = (state, id) => getItem(getAllSubscriptionScheduleAmountsSelector(state), id)
const getPaymentInstrumentTransfersConnector = (state, id) => getItem(getAllPaymentInstrumentTransfersSelector(state), id)
const getPaymentInstrumentAuthorizationsConnector = (state, id) => getItem(getAllPaymentInstrumentAuthorizationsSelector(state), id)
const getPaymentInstrumentVerificationsConnector = (state, id) => getItem(getAllPaymentInstrumentVerificationsSelector(state), id)
const getApplicationAssociatedIdentitiesConnector = (state, id) => getItem(getAllApplicationAssociatedIdentitiesSelector(state), id)
const getApplicationDisputesConnector = (state, id) => getItem(getAllApplicationDisputesSelector(state), id)
const getApplicationIdentitiesConnector = (state, id) => getItem(getAllApplicationIdentitiesSelector(state), id)
const getApplicationMerchantsConnector = (state, id) => getItem(getAllApplicationMerchantsSelector(state), id)
const getApplicationSettlementsConnector = (state, id) => getItem(getAllApplicationSettlementsSelector(state), id)
const getApplicationTransfersConnector = (state, id) => getItem(getAllApplicationTransfersSelector(state), id)
const getApplicationProcessorsConnector = (state, id) => getItem(getAllApplicationProcessorsSelector(state), id)
export const getSubscriptionScheduleEnrollmentsConnector = (state, id) => getItem(getAllSubscriptionScheduleEnrollmentsSelector(state), id)
const getSubscriptionScheduleFeesConnector = (state, id) => getItem(getAllSubscriptionScheduleFeesSelector(state), id)
const getComplianceFormTemplateFormsConnector = (state, id) => getItem(getAllComplianceFormTemplateFormsSelector(state), id)
export const getMerchantPayoutSettingsConnector = (state, id) => getItem(getAllMerchantPayoutSettingsSelector(state), id)
export const getCustomerOnboardingFormSelector = (state, id) => getItem(getCustomerOnboardingFormsSelector(state), id)
export const getCustomerOnboardingFormLinkSelector = (state, id) => getItem(getCustomerOnboardingFormLinksSelector(state), id)

// connected items selectors - ex: get all transfers that belong to settlementId
export const getSettlementEntriesSelector = (state, id) => getConnectedItemsSelector(state, id, getSettlementEntryConnector, getEntrySelector)
export const getSettlementFundingTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getSettlementFundingTransferConnector, getTransferSelector)
export const getDisputeEvidencesSelector = (state, id) => getConnectedItemsSelector(state, id, getDisputeEvidencesConnector, getEvidenceSelector)
export const getDisputeAdjustmentTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getDisputeAdjustmentTransfersConnector, getTransferSelector)
export const getIdentityAssociatedIdentitiesSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityAssociatedIdentitiesConnector, getAssociatedIdentitySelector)
export const getIdentityAuthorizationsSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityAuthorizationsConnector, getAuthorizationSelector)
export const getIdentityTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityTransfersConnector, getTransferSelector)
export const getIdentityDisputesSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityDisputesConnector, getDisputeSelector)
export const getIdentitySettlementsSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentitySettlementsConnector, getSettlementSelector)
export const getIdentityPaymentInstrumentsSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityPaymentInstrumentsConnector, getPaymentInstrumentSelector)
export const getIdentityMerchantsSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityMerchantsConnector, getMerchantSelector)
export const getIdentityVerificationsSelector = (state, id) => getConnectedItemsSelector(state, id, getIdentityVerificationsConnector, getVerificationSelector)
export const getMerchantAuthorizationsSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantAuthorizationsConnector, getAuthorizationSelector)
export const getMerchantTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantTransfersConnector, getTransferSelector)
export const getMerchantSettlementsSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantSettlementsConnector, getSettlementSelector)
export const getMerchantFeesSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantFeesConnector, getFeeSelector)
export const getMerchantSubscriptionEnrollmentsSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantSubscriptionEnrollmentsConnector, getSubscriptionEnrollmentSelector)
export const getSubscriptionScheduleAmountsSelector = (state, id) => getConnectedItemsSelector(state, id, getSubscriptionScheduleAmountsConnector, getSubscriptionAmountSelector)
export const getMerchantVerificationsSelector = (state, id) => getConnectedItemsSelector(state, id, getMerchantVerificationsConnector, getVerificationSelector)
export const getPaymentInstrumentTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getPaymentInstrumentTransfersConnector, getTransferSelector)
export const getPaymentInstrumentAuthorizationsSelector = (state, id) => getConnectedItemsSelector(state, id, getPaymentInstrumentAuthorizationsConnector, getAuthorizationSelector)
export const getPaymentInstrumentVerificationsSelector = (state, id) => getConnectedItemsSelector(state, id, getPaymentInstrumentVerificationsConnector, getVerificationSelector)
export const getApplicationAssociatedIdentitiesSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationAssociatedIdentitiesConnector, getAssociatedIdentitySelector)
export const getApplicationDisputesSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationDisputesConnector, getDisputeSelector)
export const getApplicationIdentitiesSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationIdentitiesConnector, getIdentitySelector)
export const getApplicationMerchantsSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationMerchantsConnector, getMerchantSelector)
export const getApplicationSettlementsSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationSettlementsConnector, getSettlementSelector)
export const getApplicationTransfersSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationTransfersConnector, getTransferSelector)
export const getApplicationProcessorsSelector = (state, id) => getConnectedItemsSelector(state, id, getApplicationProcessorsConnector, getProcessorSelector)
export const getSubscriptionScheduleEnrollmentsSelector = (state, id) => getConnectedItemsSelector(state, id, getSubscriptionScheduleEnrollmentsConnector, getSubscriptionEnrollmentSelector)
export const getSubscriptionScheduleFeesSelector = (state, id) => getConnectedItemsSelector(state, id, getSubscriptionScheduleFeesConnector, getFeeSelector)
export const getComplianceFormTemplateFormsSelector = (state, id) => getConnectedItemsSelector(state, id, getComplianceFormTemplateFormsConnector, getComplianceFormSelector)


// redux form values selector utility
export const getFormValues = (state, form, fields) => map(fields, (field) => formValueSelector(form)(state, field))

// custom selectors
export const getApplicationProcessorByName = (state, applicationId, processorName) => {
  return find(getApplicationProcessorsSelector(state, applicationId), ({ processor }) => processor === processorName)
}

export const getBalancesByApplicationId = (state, associatedApplicationId) => {
  return find(getBalancesSelector(state), ({ applicationId }) => applicationId === associatedApplicationId)
}

export const getMerchantsIdentitiesSelector = createSelector(
  [getIdentityMerchantsSelector, getIdentitiesSelector],
  (merchants, identities) => {
    const items = reduce(merchants, (total, item, id) => {
      const identityId = get(item, 'identityId')
      const identity = get(identities, identityId)

      const mergedMerchantIdentityItem = merge({}, item, { identity })

      return merge({}, total, {
        [id]: mergedMerchantIdentityItem,
      })
    }, {})

    return items
  },
)

export const getComplianceFormsApplicationSelector = createSelector(
  [getComplianceFormsSelector, getApplicationsSelector],
  (complianceForms, applications) => {
    const items = reduce(complianceForms, (total, item, id) => {
      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId)

      const mergedComplianceFormItem = merge({}, item, { application })

      return merge({}, total, {
        [id]: mergedComplianceFormItem,
      })
    }, {})

    return items
  },
)

export const getMerchantComplianceFormsApplicationSelector = createSelector(
  [getComplianceFormsSelector, getApplicationsSelector],
  (complianceForms, applications) => {
    const items = reduce(complianceForms, (total, item, id) => {
      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId)

      const mergedComplianceFormItem = merge({}, item, { application })

      return merge({}, total, {
        [id]: mergedComplianceFormItem,
      })
    }, {})

    return items
  },
)

export const getComplianceFormTemplateFormsApplicationSelector = createSelector(
  [getComplianceFormTemplateFormsSelector, getApplicationsSelector],
  (templateComplianceForms, applications) => {
    const items = reduce(templateComplianceForms, (total, item, id) => {
      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId)

      const mergedComplianceFormItem = merge({}, item, { application })

      return merge({}, total, {
        [id]: mergedComplianceFormItem,
      })
    }, {})

    return items
  },
)

export const getReviewQueueSettlementsSelector = createSelector(
  [getReviewQueueSelector, getSettlementsSelector, getApplicationsSelector, getMerchantsSelector, getIdentitiesSelector],
  (reviewQueueItems, settlements, applications, merchants, identities) => {
    const items = reduce(reviewQueueItems, (total, item, id) => {
      const entityType = get(item, 'entityType')

      if (entityType === SETTLEMENT || entityType === 'SETTLEMENT_V2') {
        const applicationId = get(item, 'applicationId')
        const application = get(applications, applicationId)

        const settlementId = get(item, 'entityId')
        const settlement = get(settlements, settlementId)

        const merchantId = get(settlement, 'merchantId')
        const merchant = get(merchants, merchantId)

        const identityId = get(merchant, 'identityId')
        const identity = get(identities, identityId)

        const mergedReviewQueueItem = removeUndefined(merge({}, item, { entity: settlement, application, merchant, identity }))

        return merge({}, total, {
          [id]: mergedReviewQueueItem,
        })
      }

      return total
    }, {})

    return items
  },
)

export const getReviewQueueSettlementsByOutcomeSelector = (state, outcome) => {
  if (!outcome) {
    return getReviewQueueSettlementsSelector(state)
  }

  return filter(getReviewQueueSettlementsSelector(state), ({ outcome: reviewQueueOutcome }) => reviewQueueOutcome === outcome)
}

export const getReviewQueueSettlement = (state, reviewQueueId) => {
  const reviewQueueItem = getReviewQueueItemSelector(state, reviewQueueId)
  const settlementId = get(reviewQueueItem, 'entityId')
  const settlement = getSettlementSelector(state, settlementId)

  return merge({}, reviewQueueItem, { entity: settlement })
}

export const getMerchantFilesByMerchantId = (state, merchantId) => {
  const merchantFiles = getMerchantFilesSelector(state)

  return filter(merchantFiles, ({ linkedTo }) => linkedTo === merchantId)
}

export const getIdentityFilesWithMerchantData = (state, reviewId) => {
  const identityFiles = getIdentityFilesSelector(state)
  const review = getUnderwritingReviewSelector(state, reviewId)
  const merchants = get(review, 'entity.merchants')

  const items = reduce(identityFiles, (total, item, id) => {
    const merchantId = get(item, 'linkedTo')
    const merchant = get(merchants, merchantId, {})

    const mergedFileItem = merge({}, item, { merchant })

    return merge({}, total, {
      [id]: mergedFileItem,
    })
  }, {})

  return items
}

export const getIdentityFilesWithMerchantDataByIdentityId = (state, identityId) => {
  const identityFiles = getIdentityFilesSelector(state)
  const identity = getIdentitySelector(state, identityId)
  const merchants = get(identity, 'merchants')

  const items = reduce(identityFiles, (total, item, id) => {
    const merchantId = get(item, 'linkedTo')
    const merchant = get(merchants, merchantId, {})

    const mergedFileItem = merge({}, item, { merchant })

    return merge({}, total, {
      [id]: mergedFileItem,
    })
  }, {})

  return items
}

// TODO: look if we can make a util that also maintains memoization, is it worth it?
export const getPendingReviewQueueSettlementsSelector = createSelector(
  [getReviewQueueSettlementsSelector],
  (reviewQueueSettlements) => filter(reviewQueueSettlements, ({ outcome }) => outcome === PENDING),
)

export const getRejectedReviewQueueSettlementsSelector = createSelector(
  [getReviewQueueSettlementsSelector],
  (reviewQueueSettlements) => filter(reviewQueueSettlements, ({ outcome }) => outcome === REJECTED),
)

export const getAcceptedReviewQueueSettlementsSelector = createSelector(
  [getReviewQueueSettlementsSelector],
  (reviewQueueSettlements) => filter(reviewQueueSettlements, ({ outcome }) => outcome === ACCEPTED),
)

export const getReviewQueueMerchantsSelector = createSelector(
  [getReviewQueueSelector, getMerchantsSelector, getIdentitiesSelector],
  (reviewQueueItems, merchants, identities) => {
    const items = reduce(reviewQueueItems, (total, item, id) => {
      const entityType = get(item, 'entityType')

      if (entityType === MERCHANT) {
        const merchantId = get(item, 'entityId')
        const merchant = get(merchants, merchantId)
        const identityId = get(merchant, 'identityId')
        const merchantIdentity = get(identities, identityId)
        const mergedReviewQueueItem = merchant ? merge({}, item, { entity: merchant, merchantIdentity }) : item

        return merge({}, total, {
          [id]: mergedReviewQueueItem,
        })
      }

      return total
    }, {})

    return items
  },
)

// TODO: look if we can make a util that also maintains memoization, is it worth it?
export const getPendingReviewQueueMerchantsSelector = createSelector(
  [getReviewQueueMerchantsSelector],
  (reviewQueueMerchants) => filter(reviewQueueMerchants, ({ outcome }) => outcome === PENDING),
)

export const getRejectedReviewQueueMerchantsSelector = createSelector(
  [getReviewQueueMerchantsSelector],
  (reviewQueueMerchants) => filter(reviewQueueMerchants, ({ outcome }) => outcome === REJECTED),
)

export const getAcceptedReviewQueueMerchantsSelector = createSelector(
  [getReviewQueueMerchantsSelector],
  (reviewQueueMerchants) => filter(reviewQueueMerchants, ({ outcome }) => outcome === ACCEPTED),
)

export const getReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueSelector, getIdentitiesSelector],
  (reviewQueueItems, identities) => {
    const items = reduce(reviewQueueItems, (total, item, id) => {
      const entityType = get(item, 'entityType')

      if (entityType === IDENTITY) {
        const identityId = get(item, 'entityId')
        const identity = get(identities, identityId)
        const mergedReviewQueueItem = identity ? merge({}, item, { entity: identity }) : item

        return merge({}, total, {
          [id]: mergedReviewQueueItem,
        })
      }

      return total
    }, {})

    return items
  },
)

// TODO: look if we can make a util that also maintains memoization, is it worth it?
export const getPendingReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === PENDING),
)

export const getRejectedReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === REJECTED),
)

export const getAcceptedReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === ACCEPTED),
)

export const getApprovedReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === APPROVED),
)

export const getManualReviewReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === MANUAL_REVIEW),
)

export const getAdditionalVerificationNeededReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === ADDITIONAL_VERIFICATION_NEEDED),
)

export const getWithdrawnReviewQueueIdentitiesSelector = createSelector(
  [getReviewQueueIdentitiesSelector],
  (reviewQueueIdentities) => filter(reviewQueueIdentities, ({ outcome }) => outcome === WITHDRAWN),
)

export const getDisputeMerchantsBuyersSelector = createSelector(
  [getDisputesSelector, getTransfersSelector, getIdentitiesSelector, getPaymentInstrumentsSelector, getApplicationsSelector],
  (disputeItems, transfers, identities, paymentInstruments, applications) => {
    const items = reduce(disputeItems, (total, item, id) => {
      const transferId = get(item, 'transferId')
      const transfer = get(transfers, transferId)

      const merchantIdentityId = get(transfer, 'merchantIdentityId')
      const merchantIdentity = get(identities, merchantIdentityId, {})

      const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId, {})

      const buyerIdentityId = get(paymentInstrument, 'identityId')
      const buyerIdentity = get(identities, buyerIdentityId, {})

      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId, {})

      const mergedDisputeItem = merge({}, item, { merchantIdentity, paymentInstrument, application, buyerIdentity, transfer })

      return merge({}, total, {
        [id]: mergedDisputeItem,
      })
    }, {})

    return items
  },
)

export const getTransferWithRelatedSelector = (state, transferId) => {
  const transfer = getTransferSelector(state, transferId)

  if (isEmpty(transfer)) {
    return transfer
  }

  const merchantIdentityId = get(transfer, 'merchantIdentityId')
  const merchantIdentity = getIdentitySelector(state, merchantIdentityId)

  const merchantId = get(transfer, 'merchantId')
  const merchant = getMerchantSelector(state, merchantId)

  const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)

  const applicationId = get(transfer, 'application')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, transfer, removeUndefined({
    merchantIdentity,
    merchant,
    paymentInstrument,
    application,
  }))
}

export const getDeviceMerchantSelector = createSelector(
  [getDevicesSelector, getMerchantsSelector, getApplicationsSelector],
  (deviceItems, merchants, applications) => {
    const items = reduce(deviceItems, (total, item, id) => {
      const merchantId = get(item, 'merchantId')
      const merchant = get(merchants, merchantId)
      const applicationId = get(merchant, 'applicationId')
      const application = get(applications, applicationId)

      const mergedDeviceItem = merge({}, item, { merchant, application })

      return merge({}, total, {
        [id]: mergedDeviceItem,
      })
    }, {})

    return items
  },
)

export const getTransferMerchantsBuyersSelector = createSelector(
  [getTransfersSelector, getIdentitiesSelector, getPaymentInstrumentsSelector, getApplicationsSelector],
  (transferItems, identities, paymentInstruments, applications) => {
    const items = reduce(transferItems, (total, item, id) => {
      const merchantIdentityId = get(item, 'merchantIdentityId')
      const merchantIdentity = get(identities, merchantIdentityId, {})

      const paymentInstrumentId = get(item, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId, {})

      const buyerIdentityId = get(paymentInstrument, 'identityId')
      const buyerIdentity = get(identities, buyerIdentityId, {})

      const applicationId = get(item, 'application')
      const application = get(applications, applicationId, {})

      const mergedTransferItem = merge({}, item, { merchantIdentity, paymentInstrument, application, buyerIdentity })

      return merge({}, total, {
        [id]: mergedTransferItem,
      })
    }, {})

    return items
  },
)

export const getSplitTransferMerchantsApplicationsSelector = createSelector(
  [getSplitTransfersSelector, getIdentitiesSelector, getApplicationsSelector, getMerchantsSelector],
  (splitTransferItems, identities, applications, merchants) => {
    const items = reduce(splitTransferItems, (total, item, id) => {
      const identityId = get(item, 'identityId')
      const identity = get(identities, identityId, {})

      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId, {})

      const merchantId = get(item, 'merchantId')
      const merchant = get(merchants, merchantId, {})

      const mergedSplitTransferItem = merge({}, item, { identity, application, merchant })

      return merge({}, total, {
        [id]: mergedSplitTransferItem,
      })
    }, {})

    return items
  },
)

export const getSplitTransfersByParentIdSelector = (state, parentId) => {
  const splitTransfers = getSplitTransferMerchantsApplicationsSelector(state)

  return filter(splitTransfers, ({ parentTransferId }) => parentId === parentTransferId)
}

export const getTransferReversalsMerchantsApplicationSelector = createSelector(
  [getReversalsSelector, getIdentitiesSelector, getApplicationsSelector],
  (reversalItems, identities, applications) => {
    const items = reduce(reversalItems, (total, item, id) => {
      const merchantIdentityId = get(item, 'merchantIdentityId')
      const merchantIdentity = get(identities, merchantIdentityId, {})

      const applicationId = get(item, 'application')
      const application = get(applications, applicationId, {})

      const mergedReversalItem = merge({}, item, { merchantIdentity, application })

      return merge({}, total, {
        [id]: mergedReversalItem,
      })
    }, {})

    return items
  },
)

export const getTransferDisputesMerchantsApplicationSelector = createSelector(
  [getTransferDisputesSelector, getIdentitiesSelector, getApplicationsSelector],
  (disputeItems, identities, applications) => {
    const items = reduce(disputeItems, (total, item, id) => {
      const identityId = get(item, 'identityId')
      const merchantIdentity = get(identities, identityId, {})

      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId, {})

      const mergedDisputeItem = merge({}, item, { merchantIdentity, application })

      return merge({}, total, {
        [id]: mergedDisputeItem,
      })
    }, {})

    return items
  },
)

export const getAuthorizationMerchantsBuyersSelector = createSelector(
  [getAuthorizationsSelector, getIdentitiesSelector, getPaymentInstrumentsSelector, getApplicationsSelector],
  (authorizationItems, identities, paymentInstruments, applications) => {
    const items = reduce(authorizationItems, (total, item, id) => {
      const merchantIdentityId = get(item, 'merchantIdentityId')
      const merchantIdentity = get(identities, merchantIdentityId, {})

      const paymentInstrumentId = get(item, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId, {})

      const buyerIdentityId = get(paymentInstrument, 'identityId')
      const buyerIdentity = get(identities, buyerIdentityId, {})

      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId, {})

      const mergedAuthorizationItem = merge({}, item, { merchantIdentity, paymentInstrument, application, buyerIdentity })

      return merge({}, total, {
        [id]: mergedAuthorizationItem,
      })
    }, {})

    return items
  },
)

export const getFeesMerchantSelector = createSelector(
  [getFeesSelector, getMerchantsSelector, getIdentitiesSelector, getApplicationsSelector],
  (fees, merchants, identities, applications) => {
    const items = reduce(fees, (total, item, id) => {
      const merchantId = get(item, 'merchantId')
      const merchant = get(merchants, merchantId)

      const identityId = get(merchant, 'identityId')
      const identity = get(identities, identityId)

      const applicationId = get(merchant, 'applicationId')
      const application = get(applications, applicationId)

      const mergedFee = merge({}, item, { merchant, identity, application })

      return merge({}, total, {
        [id]: mergedFee,
      })
    }, {})

    return items
  },
)

export const getMerchantIdentitySelector = createSelector(
  [getMerchantsSelector, getIdentitiesSelector, getApplicationsSelector, getVerificationsSelector],
  (merchants, identities, applications, verifications) => {
    const items = reduce(merchants, (total, item, id) => {
      const merchantIdentityId = get(item, 'identityId')
      const merchantIdentity = get(identities, merchantIdentityId, {})

      const merchantApplicationId = get(item, 'applicationId')
      const application = get(applications, merchantApplicationId, {})

      const merchantVerificationId = get(item, 'verificationId')
      const verification = get(verifications, merchantVerificationId, {})

      const mergedMerchantItem = merge({}, item, { merchantIdentity, application, verification })

      return merge({}, total, {
        [id]: mergedMerchantItem,
      })
    }, {})

    return items
  },
)

export const getSubscriptionScheduleEnrollmentsScheduleSelector = createSelector(
  [getSubscriptionScheduleEnrollmentsSelector, getSubscriptionSchedulesSelector, getMerchantsSelector, getSubscriptionScheduleAmountsSelector, getIdentitiesSelector],
  (enrollments, schedules, merchants, amounts, identities) => {
    const items = reduce(enrollments, (total, item, id) => {
      const enrollmentScheduleId = get(item, 'subscriptionScheduleId')
      const merchantId = get(item, 'merchantId')
      const schedule = omit(get(schedules, enrollmentScheduleId, {}), 'amounts')
      const mergedSchedule = merge({}, schedule, { amounts })
      const merchant = get(merchants, merchantId, {})
      const identityId = get(merchant, 'identityId')
      const identity = get(identities, identityId, {})

      const mergedEnrollmentItem = merge({}, item, { schedule: mergedSchedule, merchant, identity })

      return merge({}, total, {
        [id]: mergedEnrollmentItem,
      })
    }, {})

    return items
  },
)

export const getEnrollmentsScheduleSelector = createSelector(
  [getSubscriptionEnrollmentsSelector, getSubscriptionSchedulesSelector],
  (enrollments, schedules) => {
    const items = reduce(enrollments, (total, item, id) => {
      const enrollmentScheduleId = get(item, 'subscriptionScheduleId')
      const schedule = get(schedules, enrollmentScheduleId, {})

      const mergedEnrollmentItem = merge({}, item, { schedule })

      return merge({}, total, {
        [id]: mergedEnrollmentItem,
      })
    }, {})

    return items
  },
)

export const getComplianceFormTemplatesLinkedToSelector = createSelector(
  [getComplianceFormTemplatesSelector, getApplicationsSelector, getPlatformsSelector],
  (complianceFormTemplates, applications, platforms) => {
    const items = reduce(complianceFormTemplates, (total, item, id) => {
      const linkedToId = get(item, 'linkedTo')
      const application = get(applications, linkedToId)
      const platform = get(platforms, linkedToId)

      const mergedItem = merge({}, item, removeUndefined({ application, platform }))

      return merge({}, total, {
        [id]: mergedItem,
      })
    }, {})

    return items
  },
)

export const getApplePayRegisteredMerchantDomainsSelector = (state) => {
  const applePayMerchants = getApplePayRegisteredMerchantSelector(state)

  // TODO: improve/refactor to possibly be cleaner in using map, flatMap, removeUndefined, etc...
  const domainNames = reduce([applePayMerchants], (total, { domains, id }) => {
    const domainMap = []

    map(domains, ({ name: domain, enabled }) => {
      return enabled ? domainMap.push({ domain, id }) : domainMap
    })

    return concat(total, domainMap)
  }, [])

  return domainNames
}

export const getApplicationMerchants = (state, id) => {
  const applicationMerchants = getApplicationMerchantsSelector(state, id)
  const identities = getIdentitiesSelector(state)

  const items = reduce(applicationMerchants, (total, merchant, merchantId) => {
    const identityId = get(merchant, 'identityId')
    const identity = get(identities, identityId)

    const merchantWithIdentity = merge({}, merchant, { identity })

    return merge({}, total, {
      [merchantId]: merchantWithIdentity,
    })
  }, {})

  return items
}

export const getMerchantsByApplicationIdSelector = (state, applicationId) => {
  const merchants = getMerchantsSelector(state)

  return reduce(merchants, (total, merchant, merchantId) => {
    if (get(merchant, 'applicationId') !== applicationId) {
      return total
    }

    const identityId = get(merchant, 'identityId')
    const identity = getIdentitySelector(state, identityId)
    const merchantWithIdentity = merge({}, merchant, { merchantIdentity: identity })

    return merge({}, total, {
      [merchantId]: merchantWithIdentity,
    })
  }, {})
}

export const getSettlementsDataSelector = createSelector(
  [getSettlementsSelector, getMerchantsSelector, getIdentitiesSelector, getApplicationsSelector],
  (settlements, merchants, identities, applications) => {
    const items = reduce(settlements, (total, item, id) => {
      const merchantId = get(item, 'merchantId')
      const merchant = get(merchants, merchantId, {})

      const identityId = get(merchant, 'identityId')
      const identity = get(identities, identityId, {})

      const applicationId = get(merchant, 'applicationId')
      const application = get(applications, applicationId, {})

      const mergedSettlement = merge({}, item, { merchant, identity, application })

      return merge({}, total, {
        [id]: mergedSettlement,
      })
    }, {})

    return items
  },
)

export const getSettlementGroupSettlementsSelector = (state, id) => {
  const settlementGroup = getSettlementGroupSelector(state, id)
  const settlementGroupSettlements = get(settlementGroup, 'settlements')
  const merchants = getMerchantsSelector(state)
  const identities = getIdentitiesSelector(state)

  const items = reduce(settlementGroupSettlements, (total, settlementGroupSettlement) => {
    const settlementId = get(settlementGroupSettlement, 'id')
    const settlement = getSettlementSelector(state, settlementId)

    const merchantId = get(settlement, 'merchantId')
    const merchant = get(merchants, merchantId)


    const identityId = get(merchant, 'identityId')
    const identity = get(identities, identityId, {})

    const settlementWithMerchant = merge({}, settlement, { merchant, identity })

    return merge({}, total, {
      [settlementId]: settlementWithMerchant,
    })
  }, {})

  return items
}

export const getApplicationSettlements = (state, id) => {
  const applicationSettlements = getApplicationSettlementsSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const merchants = getMerchantsSelector(state)

  const items = reduce(applicationSettlements, (total, settlement, settlementId) => {
    const merchantId = get(settlement, 'merchantId')
    const merchant = get(merchants, merchantId)

    const identityId = get(merchant, 'identityId')
    const identity = get(identities, identityId)

    const identitySettlement = merge({}, settlement, { identity, merchant })

    return merge({}, total, {
      [settlementId]: identitySettlement,
    })
  }, {})

  return items
}

// TODO: look into whether we can use re-select, reselect has issue when params are required (aka id)
export const getIdentityAuthorizations = (state, id) => {
  const identityAuthorizations = getIdentityAuthorizationsSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const identity = get(identities, id)
  const paymentInstruments = getPaymentInstrumentsSelector(state)

  const items = reduce(identityAuthorizations, (total, authorization, authorizationId) => {
    const paymentInstrumentId = get(authorization, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

    const authorizationWithIdentity = merge({}, authorization, { identity, paymentInstrument })

    return merge({}, total, {
      [authorizationId]: authorizationWithIdentity,
    })
  }, {})

  return items
}

export const getMerchantAuthorizations = (state, id) => {
  const merchantAuthorizations = getMerchantAuthorizationsSelector(state, id)
  const merchants = getMerchantsSelector(state)
  const merchant = get(merchants, id)
  const paymentInstruments = getPaymentInstrumentsSelector(state)
  const identities = getIdentitiesSelector(state)

  const items = reduce(merchantAuthorizations, (total, authorization, authorizationId) => {
    const paymentInstrumentId = get(authorization, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)
    const identityId = get(authorization, 'merchantIdentityId')
    const identity = get(identities, identityId)

    const authorizationWithIdentity = merge({}, authorization, { merchant, paymentInstrument, identity })

    return merge({}, total, {
      [authorizationId]: authorizationWithIdentity,
    })
  }, {})

  return items
}

export const getIdentityTransfers = (state, id) => {
  const identityTransfers = getIdentityTransfersSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const identity = get(identities, id)
  const paymentInstruments = getPaymentInstrumentsSelector(state)
  const applications = getApplicationsSelector(state)

  const items = reduce(identityTransfers, (total, transfer, transferId) => {
    const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

    // TODO: Application object is too big! we need to break out all of it's stuff into separate reducers
    const applicationId = get(transfer, 'application')
    const application = get(applications, applicationId)
    const applicationData = { businessName: get(application, 'businessName') }

    const transferWithIdentity = merge({}, transfer, { identity, paymentInstrument, application: applicationData })

    return merge({}, total, {
      [transferId]: transferWithIdentity,
    })
  }, {})

  return items
}

export const getMerchantTransfers = (state, id) => {
  const merchantTransfers = getMerchantTransfersSelector(state, id)
  const merchants = getMerchantsSelector(state)
  const merchant = get(merchants, id)
  const paymentInstruments = getPaymentInstrumentsSelector(state)
  const identities = getIdentitiesSelector(state)
  const applications = getApplicationsSelector(state)

  const items = reduce(merchantTransfers, (total, transfer, transferId) => {
    const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)
    const identityId = get(transfer, 'merchantIdentityId')
    const identity = get(identities, identityId)
    const applicationId = get(transfer, 'application')
    const application = get(applications, applicationId)

    const transferWithIdentity = merge({}, transfer, { merchant, paymentInstrument, identity, application })

    return merge({}, total, {
      [transferId]: transferWithIdentity,
    })
  }, {})

  return items
}

export const getIdentitySettlements = (state, id) => {
  const identitySettlements = getIdentitySettlementsSelector(state, id)
  const merchants = getMerchantsSelector(state)
  const identities = getIdentitiesSelector(state)
  const applications = getApplicationsSelector(state)

  const items = reduce(identitySettlements, (total, settlement, settlementId) => {
    const merchantId = get(settlement, 'merchantId')
    const merchant = get(merchants, merchantId)

    const identityId = get(merchant, 'identityId')
    const identity = get(identities, identityId)

    const applicationId = get(merchant, 'applicationId')
    const application = get(applications, applicationId, {})

    const identitySettlement = merge({}, settlement, { merchant, identity, application })

    return merge({}, total, {
      [settlementId]: identitySettlement,
    })
  }, {})

  return items
}

export const getMerchantSettlements = (state, id) => {
  const merchantSettlements = getMerchantSettlementsSelector(state, id)
  const merchants = getMerchantsSelector(state)
  const identities = getIdentitiesSelector(state)

  const items = reduce(merchantSettlements, (total, settlement, settlementId) => {
    const merchant = get(merchants, id)
    const identityId = get(merchant, 'identityId')
    const identity = get(identities, identityId)

    const merchantSettlement = merge({}, settlement, { merchant, identity })
    return merge({}, total, {
      [settlementId]: merchantSettlement,
    })
  }, {})

  return items
}

export const getMerchantSettlementsByStatus = (status) => {
  return (state, id) => {
    const merchantSettlements = getMerchantSettlements(state, id)
    const statusValue = get(settlementStatusMapping, status)

    return filter(merchantSettlements, ({ status: itemStatus }) => !statusValue || itemStatus === statusValue)
  }
}

export const getApplicationTransfers = (state, id) => {
  const applicationTransfers = getApplicationTransfersSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const paymentInstruments = getPaymentInstrumentsSelector(state)
  const applications = getApplicationsSelector(state)

  const items = reduce(applicationTransfers, (total, transfer, transferId) => {
    const identityId = get(transfer, 'merchantIdentityId')
    const merchantIdentity = get(identities, identityId)

    const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

    // TODO: we have way too much information in the application object (from getApplicationO)
    // when we attach it to so many payments, the object size becomes too large an JS cannot handle it
    // we should only pull and attach the bare minimum info we need (like application name) to reduce size
    const applicationId = get(transfer, 'application')
    const application = get(applications, applicationId)
    const applicationBusinessName = get(application, 'businessName')

    const combinedTransfer = merge({}, transfer, { merchantIdentity, paymentInstrument, application: { id: applicationId, businessName: applicationBusinessName } })

    return merge({}, total, {
      [transferId]: combinedTransfer,
    })
  }, {})

  return items
}

// TODO: look into whether we can use re-select, reselect has issue when params are required (aka id)
export const buildPaymentInstrumentTransfers = (state, id) => {
  const paymentInstrumentTransfers = getPaymentInstrumentTransfersSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const paymentInstrument = getPaymentInstrumentSelector(state, id)

  const items = reduce(paymentInstrumentTransfers, (total, transfer, transferId) => {
    const identityId = get(transfer, 'merchantIdentityId')
    const identity = get(identities, identityId)

    const transferWithIdentity = merge({}, transfer, { identity, paymentInstrument })

    return merge({}, total, {
      [transferId]: transferWithIdentity,
    })
  }, {})

  return items
}

export const getPaymentInstrumentAuthorizations = (state, id) => {
  const paymentIdentityAuthorizations = getPaymentInstrumentAuthorizationsSelector(state, id)
  const identities = getIdentitiesSelector(state)
  const paymentInstruments = getPaymentInstrumentsSelector(state)

  const items = reduce(paymentIdentityAuthorizations, (total, authorization, authorizationId) => {
    const identityId = get(authorization, 'merchantIdentityId')
    const identity = get(identities, identityId)

    const paymentInstrumentId = get(authorization, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

    const paymentIdentityAuthorization = merge({}, authorization, { identity, paymentInstrument })

    return merge({}, total, {
      [authorizationId]: paymentIdentityAuthorization,
    })
  }, {})

  return items
}

export const getPaymentInstrumentWithApplication = (state, id) => {
  const paymentInstrument = getPaymentInstrumentSelector(state, id)
  const applicationId = get(paymentInstrument, 'applicationId')
  const identityId = get(paymentInstrument, 'identityId')
  const application = getApplicationSelector(state, applicationId)
  const identity = getIdentitySelector(state, identityId)

  return merge({}, paymentInstrument, { application, identity })
}

const getRelatedDisputes = (relatedDisputesSelector, state, id) => {
  const relatedDisputes = relatedDisputesSelector(state, id)
  const transfers = getTransfersSelector(state)
  const identities = getIdentitiesSelector(state)
  const paymentInstruments = getPaymentInstrumentsSelector(state)
  const applications = getApplicationsSelector(state)

  const items = reduce(relatedDisputes, (total, dispute, disputeId) => {
    const transferId = get(dispute, 'transferId')
    const transfer = get(transfers, transferId)

    const merchantIdentityId = get(transfer, 'merchantIdentityId')
    const merchantIdentity = get(identities, merchantIdentityId, {})

    const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId, {})

    const applicationId = get(dispute, 'applicationId')
    const application = get(applications, applicationId, {})

    const mergedDisputeItem = merge({}, dispute, { merchantIdentity, paymentInstrument, application })

    return merge({}, total, {
      [disputeId]: mergedDisputeItem,
    })
  }, {})

  return items
}

export const getIdentityDisputes = (state, id) => getRelatedDisputes(getIdentityDisputesSelector, state, id)
export const getApplicationDisputes = (state, id) => getRelatedDisputes(getApplicationDisputesSelector, state, id)

export const getSettlementEntries = (state, id) => {
  const entries = getEntriesSelector(state)
  const settlementEntries = getSettlementEntriesSelector(state, id)
  const paymentInstruments = getPaymentInstrumentsSelector(state)

  // combine entries with transfer and fee
  const combinedItems = reduce(entries, (total, entry, entryId) => {
    const transferId = get(entry, 'transferId')
    const transfer = getTransferSelector(state, transferId)
    const transferSubtype = get(transfer, 'subtype')
    const feeId = get(entry, 'entryId')
    const fee = getFeeSelector(state, feeId)
    const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
    const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

    const mergedEntry = removeUndefined(merge({}, entry, {
      transfer,
      fee,
      paymentInstrument,
      entrySubType: transferSubtype,
    }))

    return merge({}, total, {
      [entryId]: mergedEntry,
    })
  }, {})

  // filter combinedEntries for current page's settlement entries
  const items = pickBy(combinedItems, ({ id: entryId }) => settlementEntries[entryId])

  // convert settlement entry related fees IDs to settlement entries
  const keyedItems = reduce(items, (total, settlementEntry, settlementEntryId) => {
    const transferId = get(settlementEntry, 'transferId')
    const transfer = getTransferSelector(state, transferId)
    const feeIds = get(transfer, 'feeIds')
    const feeEntries = keyBy(map(feeIds, (feeId) => find(combinedItems, ({ transferId: entryTransferId }) => entryTransferId === feeId)), 'id')

    const mergedSettlementEntry = merge({}, settlementEntry, {
      fees: feeEntries,
    })

    return merge({}, total, {
      [settlementEntryId]: mergedSettlementEntry,
    })
  }, {})

  return keyedItems
}

export const getAdminUserMembershipsByEmail = (state, email) => {
  const memberships = getAdminUserMembershipsSelector(state)

  return filter(memberships, (membership) => get(membership, 'email') === email)
}

export const getAdminUserCredentialsByUserId = (state, userId) => {
  const credentials = getCredentialsSelector(state)

  return filter(credentials, (credential) => get(credential, 'userId') === userId)
}

export const getIdentityPaymentInstrumentsSettlementAliases = (state, id) => {
  const identity = getIdentitySelector(state, id)
  const payoutPlanInstrumentMapping = get(identity, 'payoutPlanInstrumentMapping')

  const formattedPayoutPlanInstrumentMapping = reduce(payoutPlanInstrumentMapping, (total, { paymentInstrumentId, settlementAlias }) => {
    return merge({}, total, { [paymentInstrumentId]: { [settlementAlias]: true } })
  }, {})

  const identityPaymentInstruments = getIdentityPaymentInstrumentsSelector(state, id)
  const items = reduce(identityPaymentInstruments, (total, paymentInstrument, paymentInstrumentId) => {
    const settlementAliases = Object.keys(get(formattedPayoutPlanInstrumentMapping, paymentInstrumentId, {}))

    const paymentInstrumentWithSettlementAlias = removeUndefined(merge({}, paymentInstrument, { settlementAliases }))

    return merge({}, total, {
      [paymentInstrumentId]: paymentInstrumentWithSettlementAlias,
    })
  }, {})

  return items
}

export const getApplicationOwnerIdentitySelector = createSelector(
  [getApplicationsSelector, getIdentitiesSelector], (applicationItems, identities) => {
    const items = reduce(applicationItems, (total, item, id) => {
      const identityId = get(item, 'identityId')
      const identity = get(identities, identityId, {})

      const mergedApplicationItem = merge({}, item, { identity })

      return merge({}, total, {
        [id]: mergedApplicationItem,
      })
    }, {})

    return items
  },
)

export const getPendingCertId = (state) => {
  const certs = getApplePayCertsSelector(state)
  const pendingCert = find(certs, ({ status }) => status === PENDING)

  return get(pendingCert, 'id')
}

export const getDashboardConfigByEntityTypeAndId = (state, entityType, entityId) => {
  if (!entityType || !entityId) {
    return {}
  }

  const dashboardConfigurations = getDashboardConfigurations(state)

  return find(dashboardConfigurations, ({ entityType: type, entityId: id }) => {
    return type === entityType && id === entityId
  }) || {}
}

export const getDashboardConfigBySubDomain = (state, subDomain) => {
  if (!subDomain) {
    return {}
  }

  const dashboardConfigurations = getDashboardConfigurations(state)

  return find(dashboardConfigurations, ({ subDomainName, enabled = false }) => {
    return subDomain === subDomainName && enabled
  }) || {}
}

export const getDashboardConfigById = (state, dashboardConfigId) => {
  if (!dashboardConfigId) {
    return {}
  }

  const dashboardConfigurations = getDashboardConfigurations(state)

  return find(dashboardConfigurations, ({ id }) => {
    return dashboardConfigId === id
  }) || {}
}

export const get20LatestNotifications = (state) => {
  const allNotifications = getNotificationsSelector(state)

  const orderedNotifications = orderBy(allNotifications, ['createdAt'], ['desc'])

  return orderedNotifications.splice(0, 20)
}

export const getCategorizedCharts = createSelector(
  [getChartsSelector],
  (charts) => {
    if (isEmpty(charts)) return []

    const categorizedCharts = groupBy(charts, 'category')

    // loop through the category order array, find the category charts and order them, compact to remove any categories not found
    const orderedCategorizedCharts = compact(map(ORDERED_CHART_CATEGORY_NAMES, (category) => {
      const categoryCharts = get(categorizedCharts, category)

      return categoryCharts ? orderBy(categoryCharts, 'order') : undefined
    }))

    return orderedCategorizedCharts
  },
)

export const hasSecondaryTabSelector = (state) => {
  const tabs = getTabsSelector(state)
  const secondaryTab = get(tabs, 'secondaryTab')

  return !!secondaryTab
}

export const getOnboardingFormsWithDataAndApplication = createSelector(
  [getOnboardingFormsSelector, getApplicationsSelector, getOnboardingFormDataSelector],
  (onboardingForms, applications, onboardingFormsData) => {
    const items = reduce(onboardingForms, (result, item, key) => {
      const applicationId = get(item, 'applicationId')
      const application = get(applications, applicationId, {})

      const onboardingFormData = get(onboardingFormsData, key)

      const mergedOnboardingFormItem = merge({}, item, { application, onboardingFormData })

      return merge(result, {
        [key]: mergedOnboardingFormItem,
      })
    }, {})

    return items
  },
)

export const getOnboardingFormWithDataAndApplication = (state, id) => {
  const onboardingForm = getOnboardingFormDataItemSelector(state, id)
  const applicationId = get(onboardingForm, 'applicationId')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, onboardingForm, { application })
}

export const getActiveOrderedRolesSelector = createSelector(
  [getRolesSelector],
  (roles) => {
    const filteredRoles = omitBy(roles, ['roleEnabled', false])
    return orderBy(values(filteredRoles), ['type', 'createdAt'], ['desc', 'desc'])
  },
)

export const getSortedAccessFormsSelector = createSelector(
  [getAccessFormsSelector], (accessForms) => {
    return orderBy(values(accessForms), ['createdAt'], ['desc'])
  },
)

export const getApplicationOldestAccessForm = (state) => {
  const allApplicationAccessForms = getAccessFormsSelector(state)

  const orderedApplicationAccessForms = orderBy(allApplicationAccessForms, ['createdAt'])

  return head(orderedApplicationAccessForms)
}

export const getUnderwritingReviewIdentitiesSelector = createSelector(
  [getUnderwritingReviewsSelector, getIdentitiesSelector, getApplicationsSelector], (reviews, identities, applications) => {
    return reduce(reviews, (total, review, reviewId) => {
      const identityId = get(review, 'entityId')
      const identity = get(identities, identityId)
      const applicationId = get(identity, 'applicationId', get(identity, 'application'))
      const application = get(applications, applicationId)

      total[reviewId] = {
        ...review,
        entity: {
          ...identity,
          application,
        },
      }

      return total
    }, {})
  },
)

export const getChartByChartName = (state, name) => {
  return find(getChartsSelector(state), (chartData) => {
    return get(chartData, 'metadata.metadata.chart_name') === name
  })
}

export const getChartsByChartNames = (state, names) => {
  return keyBy(map(names, (name) => {
    return find(getChartsSelector(state), (chartData) => {
      return get(chartData, 'metadata.metadata.chart_name') === name
    })
  }), 'metadata.metadata.chart_name')
}

export const getUnderwritingProfileByEntityId = (state, entityId) => {
  const underwritingProfiles = getUnderwritingProfilesSelector(state)

  return find(underwritingProfiles, ({ linkedTo }) => linkedTo === entityId)
}

export const getUnderwritingProfileByPlatform = (state) => {
  const underwritingProfiles = getUnderwritingProfilesSelector(state)

  return find(underwritingProfiles, ({ linkedType }) => linkedType === 'PLATFORM')
}

export const getEntityConfigurationByLinkedToSelector = (state, linkedTo) => {
  const entityConfigurations = getEntityConfigurationsSelector(state)

  const foundConfiguration = find(entityConfigurations, ({ linkedTo: configLinkedTo }) => {
    return configLinkedTo === linkedTo
  })

  return foundConfiguration
}

export const getBalanceEntriesTransfersPaymentInstrumentsAndIdentities = createSelector(
  [getBalanceEntriesSelector, getTransfersSelector, getPaymentInstrumentsSelector, getIdentitiesSelector, getApplicationsSelector],
  (balanceEntries, transfers, paymentInstruments, identities, applications) => {
    const items = reduce(balanceEntries, (total, balanceEntry, balanceEntryId) => {
      const applicationId = get(balanceEntry, 'linkedTo')
      const application = get(applications, applicationId, {})
      const transferId = get(balanceEntry, 'transferId')
      const transfer = get(transfers, transferId, {})
      const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId, {})
      const recipientIdentityId = get(paymentInstrument, 'identityId')
      const recipientIdentity = get(identities, recipientIdentityId, {})

      const mergedBalanceEntry = merge({}, balanceEntry, { transfer, paymentInstrument, recipientIdentity, application })

      return merge({}, total, {
        [balanceEntryId]: mergedBalanceEntry,
      })
    }, {})

    return items
  },
)

export const getPaymentLinksWithMerchantSelector = createSelector(
  [getPaymentLinksSelector, getMerchantsSelector], (paymentLinks, merchants) => {
    return reduce(paymentLinks, (total, paymentLink, paymentLinkId) => {
      const merchantId = get(paymentLink, 'merchantId')
      const merchant = get(merchants, merchantId)

      return merge({}, total, {
        [paymentLinkId]: merge({}, paymentLink, { merchant }),
      })
    }, {})
  },
)

export const getPaymentLinkDataSelector = (state, id) => {
  const paymentLink = getPaymentLinkSelector(state, id)
  const merchantId = get(paymentLink, 'merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const applicationId = get(paymentLink, 'applicationId')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, paymentLink, { merchant, application })
}

export const getPurchasesWithTransferSelector = createSelector(
  [getPurchasesSelector, getTransfersSelector, getIdentitiesSelector, getPaymentInstrumentsSelector], (purchases, transfers, identities, paymentInstruments) => {
    return reduce(purchases, (total, purchase, purchaseId) => {
      const transferId = get(purchase, 'transferId')
      const transfer = get(transfers, transferId)

      const merchantIdentityId = get(transfer, 'merchantIdentityId')
      const merchantIdentity = get(identities, merchantIdentityId)

      const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

      return merge({}, total, {
        [purchaseId]: merge({}, purchase, { transfer, merchantIdentity, paymentInstrument }),
      })
    }, {})
  },
)

export const getPurchaseDataSelector = (state, id) => {
  const purchase = getPurchaseSelector(state, id)
  const transferId = get(purchase, 'transferId')
  const transfer = getTransferSelector(state, transferId)
  const merchantId = get(purchase, 'merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const applicationId = get(purchase, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const identityId = get(merchant, 'identityId')
  const identity = getIdentitySelector(state, identityId)

  return merge({}, purchase, { transfer, identity, merchant, application, paymentInstrument })
}

export const getProcessorsByApplicationId = (state, id) => {
  const processors = getProcessorsSelector(state)

  return filter(processors, ({ applicationId }) => applicationId === id)
}

export const getDraftNotesForResource = (state, id) => {
  return find(getNotesSelector(state), ({ linkedTo, status }) => linkedTo === id && status === DRAFT)
}

export const getPublishedNotes = (state, id) => {
  return orderBy(filter(getNotesSelector(state), ({
    linkedTo,
    status,
  }) => linkedTo === id && status === PUBLISHED), ['createdAt'], ['desc'])
}

export const getComplianceFormTemplateByApplicationId = (state, applicationId) => {
  const underwritingProfiles = getComplianceFormTemplatesSelector(state)

  return find(underwritingProfiles, ({ linkedTo }) => linkedTo === applicationId)
}

export const getComplianceFormsByMerchantId = (state, merchantId) => {
  const complianceForms = getMerchantComplianceFormsApplicationSelector(state)

  return filter(complianceForms, ({ merchantId: formMerchantId }) => formMerchantId === merchantId)
}

export const getFraudScoreByEntityId = (state, id) => {
  const fraudScores = getFraudScoresSelector(state)

  return find(fraudScores, ({ entityId }) => entityId === id)
}

export const getCurrentUsagesByLinkedTo = (state, linkedToId) => {
  const currentUsages = getCurrentUsagesSelector(state)

  return find(currentUsages, ({ linkedTo }) => linkedTo === linkedToId)
}

export const getRecipientCurrentUsagesByLinkedTo = (state, linkedToId) => {
  const currentUsages = getCurrentUsagesSelector(state)

  return find(currentUsages, ({ linkedTo, type }) => linkedTo === linkedToId && type === 'RECIPIENT')
}

export const getPastRunsSelector = createSelector([getRunsSelector], (runs) => {
  return slice(orderBy(runs, ['created_at'], ['desc']), 1)
})

export const getPayoutLinkDataSelector = (state, id) => {
  const payoutLink = getPayoutLinkSelector(state, id)
  const applicationId = get(payoutLink, 'applicationId')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, payoutLink, { application })
}

export const getSubscriptionDataSelector = (state, id) => {
  const subscription = getSubscriptionSelector(state, id)
  const identityId = get(subscription, 'buyerIdentityId')
  const identity = getIdentitySelector(state, identityId)
  const merchantId = get(subscription, 'linkedTo')
  const merchant = getMerchantSelector(state, merchantId)
  const paymentInstrumentId = get(subscription, 'buyerInstrumentId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const applicationId = get(merchant, 'applicationId')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, subscription, { identity, merchant, paymentInstrument, application })
}

export const getSubscriptionPlanDataSelector = (state, id) => {
  const subscriptionPlan = getSubscriptionPlanSelector(state, id)
  const merchantId = get(subscriptionPlan, 'linkedTo')
  const merchant = getMerchantSelector(state, merchantId)
  const identityId = get(merchant, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const applicationId = get(merchant, 'applicationId')
  const application = getApplicationSelector(state, applicationId)

  return merge({}, subscriptionPlan, { merchant, identity, application })
}

export const getSucceededTransferAttemptSelector = (state) => {
  const transferAttempts = getTransferAttemptsSelector(state)

  return find(transferAttempts, ({ status }) => status === SUCCEEDED)
}

export const getSplitTransferSettlementSelector = (state, id) => {
  const settlements = getSettlementsSelector(state)

  return find(settlements, ({ splitTransferId }) => splitTransferId === id)
}

export const getTransferDisputes = (state, transferId) => {
  return filter(getTransferDisputesSelector(state), ({ transferId: disputeTransferId }) => disputeTransferId === transferId)
}

export const getBalanceAdjustmentsPaymentInstruments = createSelector(
  [getBalanceAdjustmentsSelector, getPaymentInstrumentsSelector],
  (balanceAdjustments, paymentInstruments) => {
    const items = reduce(balanceAdjustments, (total, balanceAdjustment, balanceAdjustmentId) => {
      const paymentInstrumentId = get(balanceAdjustment, 'instrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)
      const mergedBalanceAdjustment = merge({}, balanceAdjustment, { paymentInstrument })
      return merge({}, total, {
        [balanceAdjustmentId]: mergedBalanceAdjustment,
      })
    }, {})
    return items
  },
)

export const getReceiptUrlById = (state, id) => {
  const receipt = getReceiptSelector(state, id)
  return get(receipt, 'receiptUrl', '')
}

export const getReceiptDeliveryAttemptByReceiptId = (state, receiptId) => createSelector(
  [getReceiptDeliveryAttemptsSelector],
  (receiptDeliveryAttempts) => {
    return orderBy(filter(receiptDeliveryAttempts, { receiptId }), ['createdAt'], ['desc'])
  },
)(state, receiptId)

export const getEnabledDevicesSelector = createSelector(
  [getDevicesSelector],
  (devices) => {
    return pickBy(devices, device => get(device, 'enabled', false))
  },
)

export const getSubscriptionPlansMerchant = createSelector(
  [getSubscriptionPlansSelector, getMerchantsSelector],
  (subscriptionPlans, merchants) => {
    const items = reduce(subscriptionPlans, (total, subscriptionPlan, subscriptionPlanId) => {
      const merchantId = get(subscriptionPlan, 'linkedTo')
      const merchant = get(merchants, merchantId)
      const mergedSubscriptionPlan = merge({}, subscriptionPlan, { merchant })

      return merge({}, total, {
        [subscriptionPlanId]: mergedSubscriptionPlan,
      })
    }, {})
    return items
  },
)

export const getSubscriptionsIdentityMerchantPaymentInstrumentAndPlanSelector = createSelector(
  [getSubscriptionsSelector, getIdentitiesSelector, getMerchantsSelector, getPaymentInstrumentsSelector, getSubscriptionPlansSelector],
  (subscriptions, identities, merchants, paymentInstruments, subscriptionPlans) => {
    return reduce(subscriptions, (total, subscription, subscriptionId) => {
      const identityId = get(subscription, 'buyerIdentityId')
      const identity = get(identities, identityId)
      const merchantId = get(subscription, 'linkedTo')
      const merchant = get(merchants, merchantId)
      const paymentInstrumentId = get(subscription, 'buyerInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)
      const subscriptionPlanId = get(subscription, 'subscriptionPlanId')
      const subscriptionPlan = get(subscriptionPlans, subscriptionPlanId)

      return merge({}, total, {
        [subscriptionId]: merge({}, subscription, { identity, merchant, paymentInstrument, subscriptionPlan }),
      })
    }, {})
  },
)

export const getMonitoringAlertsByStatusSelector = createSelector(
  [getMonitoringAlertsSelector, (_, status) => status],
  (alerts, status) => {
    if (isUndefined(status)) {
      return alerts
    }
    return pickBy(alerts, alert => get(alert, 'state') === status)
  },
)

export const getInstantPayoutsMerchantPaymentInstrumentSelector = createSelector(
  [getInstantPayoutsSelector, getMerchantsSelector, getPaymentInstrumentsSelector],
  (instantPayouts, merchants, paymentInstruments) => {
    return reduce(instantPayouts, (total, instantPayout, instantPayoutId) => {
      const merchantId = get(instantPayout, 'merchantId')
      const merchant = get(merchants, merchantId)
      const paymentInstrumentId = get(instantPayout, 'instrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

      return merge({}, total, {
        [instantPayoutId]: merge({}, instantPayout, { merchant, paymentInstrument }),
      })
    }, {})
  },
)

export const getTransferAttemptsListSelector = createSelector(
  [getTransferAttemptsSelector, getPaymentInstrumentsSelector],
  (transferAttempts, paymentInstruments) => {
    return reduce(transferAttempts, (total, transferAttempt, transferAttemptId) => {
      const paymentInstrumentId = get(transferAttempt, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)

      return merge({}, total, {
        [transferAttemptId]: merge({}, transferAttempt, { paymentInstrument }),
      })
    }, {})
  },
)

// computational selectors that should use reselect (cache + memoize)
// export const getTransfersCreatedDesc = createSelector(
//   [getTransfers],
//   (transfers) => {
//     return orderBy(transfers, ['created_at'], ['desc'])
//   },
// )
//
// export const getTransfersCreatedAsc = createSelector(
//   [getTransfers],
//   (transfers) => {
//     return orderBy(transfers, ['created_at'], ['asc'])
//   },
// )
