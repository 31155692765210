// TODO: unit test
import { fieldEmpty } from 'utilities/validate'

const validateLiveAccountApplicationPricingForm = (values, props) => {
  const {
    pricingType,
  } = values

  return {
    pricingType: fieldEmpty(pricingType, 'Pricing selection'),
  }
}

export default validateLiveAccountApplicationPricingForm
