import co from 'co'
import postOnboardingFormFileO from 'state-layer/orchestration/post/postOnboardingFormFileO'
import patchOnboardingFormDataAPI from 'api/guestDashboard/patch/patchOnboardingFormDataAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import backendOnboardingFormDataModel from 'utilities/create/models/backendOnboardingFormDataModel'
import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import filter from 'lodash/filter'
import get from 'lodash/get'

function * patchOnboardingFormBankDocumentO ({
  id,
  values,
}) {
  const {
    fileType,
    fileName,
    file,
    applicationId,
    associatedFiles = [],
  } = values

  const { fileId, error: onboardingFormFileError } = yield postOnboardingFormFileO({
    file: get(file, 'file'),
    fileType,
    fileName,
    id,
    applicationId,
  })

  if (onboardingFormFileError) {
    return createErrorResponse({ error: onboardingFormFileError })
  }

  const nonBankStatementFiles = filter(associatedFiles, ({ type }) => type !== 'BANK_STATEMENT')

  const valuesToSubmit = {
    id,
    associatedFiles: [
      ...nonBankStatementFiles,
      {
        id: fileId,
        display_name: fileName,
        type: fileType,
      },
    ],
  }

  // run through BE model
  const backendModelValuesToSubmit = backendOnboardingFormDataModel({ values: valuesToSubmit })

  const { data: onboardingFormResponse, error: onboardingFormPatchError } = yield patchOnboardingFormDataAPI({
    values: backendModelValuesToSubmit,
  })

  // run response through FE model before returning
  const frontendModelValues = frontendOnboardingFormDataModel({ data: onboardingFormResponse })

  if (onboardingFormPatchError) {
    return createErrorResponse({ error: onboardingFormPatchError })
  }

  return createSuccessResponse({
    data: frontendModelValues,
  })
}

export default co.wrap(patchOnboardingFormBankDocumentO)
