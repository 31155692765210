import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddDeviceReviewForm from 'components/Customer/Forms/AddDeviceReviewForm/AddDeviceReviewForm'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import camelCaseToTitleCase from 'utilities/display/camelCaseToTitleCase'
import formatMoney from 'utilities/formatters/formatMoney'
import formatPercentage from 'utilities/formatters/formatPercentage'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { truthyMap, configNotSupportedMap } from 'constants/statusConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'

import {
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
  TRIPOS_CLOUD_V1,
  TRIPOS_MOBILE_V1,
} from 'constants/processorConstants'

import {
  AMOUNT_VALUE,
  CARD_PRESENT_DEVICE_IMAGE_MAP,
  ON_THRESHOLD_AMOUNT_VALUE,
  PAX_D135,
  PROMPT_FOR_SIGNATURE_VALUE_TO_LABEL_MAP,
  PROMPT_SIGNATURE_VALUE_TO_LABEL_MAP,
} from 'constants/deviceConstants'

import {
  DEVICE_NAME,
  DESCRIPTION,
  MODEL,
  SERIAL_NUMBER,
  MERCHANT_ACCOUNT,
  TRUE,
  FALSE,
  PROMPT_SIGNATURE,
  SIGNATURE_THRESHOLD_AMOUNT,
  PERCENTAGE_TIP_OPTIONS,
  PERCENTAGE_TIP_THRESHOLD,
  FIXED_AMOUNT_TIP_OPTIONS,
  AVAILABLE_RECEIPT_DELIVERY_METHODS,
  AUTOMATIC_RECEIPT_DELIVERY_METHODS,
  PRINT,
  EMAIL,
  TEXT,
  PROMPT_RECEIPT_CONFIRMATION,
  TIP_COLLECTION_ON_TERMINAL,
  NOT_SUPPORTED,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)

  const [
    addDeviceFormValues,
    merchantName,
    deviceConfigurationSelectNames,
    merchantGateway,
  ] = getMany(props, [
    'formValues.addDevice',
    'merchantName',
    'deviceConfigurationSelectNames',
    'merchantGateway',
  ])

  const [
    deviceName,
    description,
    serialNumber,
    model,
    promptSignature,
    signatureThresholdAmount,
    percentTippingThreshold,
    percentageOne,
    percentageTwo,
    percentageThree,
    amountOne,
    amountTwo,
    amountThree,
    promptReceiptConfirmation,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
    promptTipOnScreen,
    promptForSignature,
  ] = getMany(addDeviceFormValues, [
    'deviceName',
    'description',
    'serialNumber',
    'model.value',
    'promptSignature.value',
    'signatureThresholdAmount',
    'percentTippingThreshold',
    'percentageOne',
    'percentageTwo',
    'percentageThree',
    'amountOne',
    'amountTwo',
    'amountThree',
    'promptReceiptConfirmation',
    'receiptDeliveryPrint',
    'receiptDeliveryEmail',
    'receiptDeliverySms',
    'automaticDeliveryPrint',
    'automaticDeliveryEmail',
    'automaticDeliverySms',
    'promptTipOnScreen',
    'promptForSignature.value',
  ])

  const deviceModelImageUrl = CARD_PRESENT_DEVICE_IMAGE_MAP[model]
  const showSerialNumber = !isEqual(merchantGateway, TRIPOS_CLOUD_V1)
  const disablePromptSignatureOption = includes([TRIPOS_MOBILE_V1, FINIX_V1, DATACAP_V1, DUMMY_V1], merchantGateway)
  const displayPercentOptions = map([percentageOne, percentageTwo, percentageThree], option => formatPercentage({ percentage: option })).join(', ')
  const displayFixedOptions = map([amountOne, amountTwo, amountThree], option => formatMoney({ amount: option })).join(', ')
  const displayPercentTippingThreshold = formatMoney({ amount: percentTippingThreshold })
  const showReceiptAndTipConfigOptions = includes([DUMMY_V1, FINIX_V1], merchantGateway)
  const showOnlyAddDeviceButtons = merchantGateway === TRIPOS_CLOUD_V1 || model === PAX_D135
  const showPromptForSignatureField = includes([FINIX_V1, DUMMY_V1], merchantGateway)

  const displayReceiptDeliveryMethods = map([
    { label: PRINT, value: receiptDeliveryPrint },
    { label: EMAIL, value: receiptDeliveryEmail },
    { label: TEXT, value: receiptDeliverySms },
  ], ({ label, value }) => (value ? label : null)).filter(Boolean).join(', ')

  const displayAutomaticReceiptDeliveryMethods = map([
    { label: PRINT, value: automaticDeliveryPrint },
    { label: EMAIL, value: automaticDeliveryEmail },
    { label: TEXT, value: automaticDeliverySms },
  ], ({ label, value }) => (value ? label : null)).filter(Boolean).join(', ')

  const generalInfoSectionData = convertPageSectionDataToV2([
    {
      label: <img src={deviceModelImageUrl} alt={model} width='50%' />,
      value: ' ',
    },
    {
      label: DEVICE_NAME,
      value: deviceName,
    },
    {
      label: DESCRIPTION,
      value: description,
    },
    {
      label: MODEL,
      value: snakeCaseToTitleCase({ key: model }),
    },
    {
      label: SERIAL_NUMBER,
      value: serialNumber,
      condition: showSerialNumber,
    },
    {
      label: MERCHANT_ACCOUNT,
      value: merchantName,
    },
  ], 1)

  const deviceConfigurationData = sortBy(map(deviceConfigurationSelectNames, ({ name, disabledForGateways }) => {
    const deviceConfigValue = get(addDeviceFormValues, name) || false

    return {
      label: capitalize(camelCaseToTitleCase(name)),
      value: <ColorcodedStatus value={deviceConfigValue} data={truthyMap} customValue={deviceConfigValue ? TRUE : FALSE} falsyValue />,
      condition: !includes(disabledForGateways, merchantGateway),
    }
  }), 'label')

  const tippingAndReceiptSettings = showReceiptAndTipConfigOptions ? [
    {
      label: PROMPT_RECEIPT_CONFIRMATION,
      value: <ColorcodedStatus value={promptReceiptConfirmation} data={truthyMap} customValue={promptReceiptConfirmation ? TRUE : FALSE} falsyValue />,
    },
    {
      label: AVAILABLE_RECEIPT_DELIVERY_METHODS,
      value: displayReceiptDeliveryMethods,
      condition: promptReceiptConfirmation,
    },
    {
      label: AUTOMATIC_RECEIPT_DELIVERY_METHODS,
      value: displayAutomaticReceiptDeliveryMethods,
      condition: promptReceiptConfirmation,
    },
    {
      label: TIP_COLLECTION_ON_TERMINAL,
      value: <ColorcodedStatus value={promptTipOnScreen} data={truthyMap} customValue={promptTipOnScreen ? TRUE : FALSE} falsyValue />,
    },
    {
      label: PERCENTAGE_TIP_OPTIONS,
      value: displayPercentOptions,
      condition: promptTipOnScreen,
    },
    {
      label: PERCENTAGE_TIP_THRESHOLD,
      value: displayPercentTippingThreshold,
      condition: !isEmpty(displayPercentTippingThreshold),
    },
    {
      label: FIXED_AMOUNT_TIP_OPTIONS,
      value: displayFixedOptions,
      condition: !isEmpty(amountOne),
    },
  ] : []

  const additionalDeviceConfigurationData = [
    {
      label: PROMPT_SIGNATURE,
      value: disablePromptSignatureOption ? <ColorcodedStatus value='false' data={configNotSupportedMap} customValue={NOT_SUPPORTED} /> : PROMPT_SIGNATURE_VALUE_TO_LABEL_MAP[promptSignature],
      condition: !showPromptForSignatureField,
    },
    {
      label: PROMPT_SIGNATURE,
      value: PROMPT_FOR_SIGNATURE_VALUE_TO_LABEL_MAP[promptForSignature],
      condition: showPromptForSignatureField,
    },
    {
      label: SIGNATURE_THRESHOLD_AMOUNT,
      value: disablePromptSignatureOption ? <ColorcodedStatus value='false' data={configNotSupportedMap} customValue={NOT_SUPPORTED} /> : formatMoney({ amount: signatureThresholdAmount }),
      condition: isEqual(promptSignature, AMOUNT_VALUE),
    },
    {
      label: SIGNATURE_THRESHOLD_AMOUNT,
      value: formatMoney({ amount: signatureThresholdAmount }),
      condition: isEqual(promptForSignature, ON_THRESHOLD_AMOUNT_VALUE) && showPromptForSignatureField,
    },
  ]

  const deviceConfigurationsSectionData = convertPageSectionDataToV2(concat(deviceConfigurationData, tippingAndReceiptSettings, additionalDeviceConfigurationData), 1)

  return {
    credentials,
    isFetching,
    generalInfoSectionData,
    deviceConfigurationsSectionData,
    merchantGateway,
    showReceiptAndTipConfigOptions,
    showDeviceConfigurationsSection: includes([TRIPOS_CLOUD_V1, DATACAP_V1, DUMMY_V1, FINIX_V1], merchantGateway) && model !== PAX_D135,
    showOnlyAddDeviceButtons,
  }
}

class AddDeviceReviewFormC extends Component {
  render() {
    return (
      <AddDeviceReviewForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(AddDeviceReviewFormC)
