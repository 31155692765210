import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  MERCHANT,
  MERCHANTS,
} from 'constants/apiConstants'

const patchMerchantAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: MERCHANT({ merchantId: id }),
    service: MERCHANTS,
  })
}

export default patchMerchantAPI
