import './PlatformProcessorConfigsFormS.scss'
import React from 'react'
import { FieldArray } from 'redux-form'
import PropTypes from 'prop-types'
import PlatformProcessorsConfigs from 'components/Customer/Forms/PlatformProcessorConfigsForm/PlatformProcessorConfigs'

const PlatformProcessorConfigsForm = ({
  name = 'processorConfigFieldsArray',
  sectionId = '',
  processorConfigId = '',
  updateConfigValue = () => {},
  emptyMessage = '',
}) => {
  return (
    <form className='PlatformProcessorConfigsForm'>
      <FieldArray
        name={name}
        component={PlatformProcessorsConfigs}
        sectionId={sectionId}
        processorConfigId={processorConfigId}
        updateConfigValue={updateConfigValue}
        emptyMessage={emptyMessage}
      />
    </form>
  )
}

PlatformProcessorConfigsForm.propTypes = {
  sectionId: PropTypes.string,
  processorConfigId: PropTypes.string,
  updateConfigValue: PropTypes.func,
}

export default PlatformProcessorConfigsForm
