import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_LINKS } from 'constants/apiConstants'

const postPaymentLinkAPI = ({ values, credentials }) => {
  return paymentsAPI.post({
    values,
    credentials,
    path: PAYMENT_LINKS,
  })
}

export default postPaymentLinkAPI
