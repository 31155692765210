import './MainAdminS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const MainAdmin = ({
  children,
  isAdmin,
  redirectPath,
}) => {
  if (!isAdmin) {
    return (
      <div className='MainAdmin NoAccess'>
        <h3>You are not an administrator</h3>
        <Link to={redirectPath}>
          Back to Dashboard
        </Link>
      </div>
    )
  }

  return (
    <div className='MainAdmin'>
      {children}
    </div>
  )
}

MainAdmin.propTypes = {
  children: PropTypes.object,
  isAdmin: PropTypes.bool,
  redirectPath: PropTypes.string,
}

export default MainAdmin
