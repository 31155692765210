import {
  DISPUTES,
  TRANSFERS,
  AUTHORIZATIONS,
  ONBOARDING_SETTLEMENTS,
  IDENTITIES,
  MERCHANTS,
  PAYMENT_INSTRUMENTS,
} from 'constants/pathConstants'

export const GENERIC_FORM = 'GENERIC_FORM'
export const LOGIN_FORM = 'LOGIN_FORM'
export const TABLE_FILTERS_FORM = 'TABLE_FILTERS_FORM'
export const TABLE_HEADERS_FORM = 'TABLE_HEADERS_FORM'
export const TABLE_FOOTER_FORM = 'TABLE_FOOTER_FORM'
export const TAGS_FORM = 'TAGS_FORM'
export const IDENTITY_FORM = 'IDENTITY_FORM'
export const IDENTITY_SETTLEMENT_FORM = 'IDENTITY_SETTLEMENT_FORM'
export const ADD_IDENTITY_BANK_ACCOUNT_FORM = 'ADD_IDENTITY_BANK_ACCOUNT_FORM'
export const EDIT_IDENTITY_BANK_ACCOUNTS_FORM = 'EDIT_IDENTITY_BANK_ACCOUNTS_FORM'
export const IDENTITY_VERIFICATION_FORM = 'IDENTITY_VERIFICATION_FORM'
export const NEW_USER_FORM = 'NEW_USER_FORM'
export const INVITE_USER_FORM = 'INVITE_USER_FORM'
export const SEARCH_USER_FORM = 'SEARCH_USER_FORM'
export const APPLICATION_SETTING_FORM = 'APPLICATION_SETTING_FORM'
export const APPLICATION_PROCESSORS_FORM = 'APPLICATION_PROCESSORS_FORM'
export const MERCHANT_SETTING_FORM = 'MERCHANT_SETTING_FORM'
export const CREATE_FEE_PROFILE_FORM = 'CREATE_FEE_PROFILE_FORM'
export const FEE_PROFILE_FORM = 'FEE_PROFILE_FORM'
export const SEND_EMAIL_FORM = 'SEND_EMAIL_FORM'
export const CREATE_REFUND_FORM = 'CREATE_REFUND_FORM'
export const CREATE_ADJUSTMENT_FORM = 'CREATE_ADJUSTMENT_FORM'
export const REMOVE_SETTLEMENT_ENTRIES_FORM = 'REMOVE_SETTLEMENT_ENTRIES_FORM'
export const UPLOAD_EVIDENCE_FORM = 'UPLOAD_EVIDENCE_FORM'
export const CREATE_API_KEY_FORM = 'CREATE_API_KEY_FORM'
export const RISK_PROFILE_FORM = 'RISK_PROFILE_FORM'
export const UPDATE_TRANSFERS_FORM = 'UPDATE_TRANSFERS_FORM'
export const UPDATE_VERIFICATIONS_FORM = 'UPDATE_VERIFICATIONS_FORM'
export const DISPUTES_FILTER_FORM = 'DISPUTES_FILTER_FORM'
export const TRANSFERS_FILTER_FORM = 'TRANSFERS_FILTER_FORM'
export const AUTHORIZATIONS_FILTER_FORM = 'AUTHORIZATIONS_FILTER_FORM'
export const SETTLEMENTS_FILTER_FORM = 'SETTLEMENTS_FILTER_FORM'
export const IDENTITIES_FILTER_FORM = 'IDENTITIES_FILTER_FORM'
export const MERCHANTS_FILTER_FORM = 'MERCHANTS_FILTER_FORM'
export const PAYMENT_INSTRUMENTS_FILTER_FORM = 'PAYMENT_INSTRUMENTS_FILTER_FORM'
export const CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM = 'CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM'
export const SYSTEM_CONFIG_FORM = 'SYSTEM_CONFIG_FORM'
export const APPLICATION_CONFIG_FORM = 'APPLICATION_CONFIG_FORM'
export const EDIT_PROCESSOR_CONFIG_FORM = 'EDIT_PROCESSOR_CONFIG_FORM'
export const PROVISION_MERCHANT_PROCESSOR_FORM = 'PROVISION_MERCHANT_PROCESSOR_FORM'
export const SEARCH_FORM = 'SEARCH_FORM'
export const CREATE_FIND_USER_FORM = 'CREATE_FIND_USER_FORM'
export const CREATE_CREDENTIAL_FORM = 'CREATE_CREDENTIAL_FORM'
export const WELCOME_EMAIL_FORM = 'WELCOME_EMAIL_FORM'
export const GENERIC_EMAIL_FORM = 'GENERIC_EMAIL_FORM'
export const UPLOAD_LOGO_FORM = 'UPLOAD_LOGO_FORM'
export const UPLOAD_STYLE_SHEET_FORM = 'UPLOAD_STYLE_SHEET_FORM'
export const EXPORT_DATA_FORM = 'EXPORT_DATA_FORM'
export const EXPORT_DATA_V2_FORM = 'EXPORT_DATA_V2_FORM'
export const RESEND_FUNDING_TRANSFER_FORM = 'RESEND_FUNDING_TRANSFER_FORM'
export const RESEND_EMAIL_FORM = 'RESEND_EMAIL_FORM'
export const CREATE_MERCHANT_VERIFICATION_FORM = 'CREATE_MERCHANT_VERIFICATION_FORM'
export const IDENTITY_UPLOADER_FORM = 'IDENTITY_UPLOADER_FORM'
export const CLOSE_SETTLEMENT_FORM = 'CLOSE_SETTLEMENT_FORM'
export const ENABLE_PROCESSORS_FORM = 'ENABLE_PROCESSORS_FORM'
export const PAYOUT_PLAN_FORM = 'PAYOUT_PLAN_FORM'
export const TEMPLATES_FORM = 'TEMPLATES_FORM'
export const SCHEDULES_FORM = 'SCHEDULES_FORM'
export const COMBINATION_PLANS_FORM = 'COMBINATION_PLANS_FORM'
export const UPDATE_ALL_PERMISSIONS_FORM = 'UPDATE_ALL_PERMISSIONS_FORM'
export const PERMISSION_FORM = 'PERMISSION_FORM'
export const CREATE_BALANCE_TRANSFER_FORM = 'CREATE_BALANCE_TRANSFER_FORM'
export const CREATE_SUBSCRIPTION_SCHEDULE = 'CREATE_SUBSCRIPTION_SCHEDULE'
export const CREATE_TEAM_MEMBER_FORM = 'CREATE_TEAM_MEMBER_FORM'
export const CREATE_SUBSCRIPTION_AMOUNT = 'CREATE_SUBSCRIPTION_AMOUNT'
export const CREATE_SUBSCRIPTION_ENROLLMENT = 'CREATE_SUBSCRIPTION_ENROLLMENT'
export const SELECT_SUBSCRIPTION_SCHEDULE = 'SELECT_SUBSCRIPTION_SCHEDULE'
export const EDIT_SUBSCRIPTION_AMOUNT = 'EDIT_SUBSCRIPTION_AMOUNT'
export const DELETE_SUBSCRIPTION_AMOUNT = 'DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT'
export const CREATE_FEE_FORM = 'CREATE_FEE_FORM'
export const IDENTITY_PRINCIPALS_FORM = 'IDENTITY_PRINCIPALS_FORM'
export const IDENTITY_PRINCIPAL_INFO_FORM = 'IDENTITY_PRINCIPAL_INFO_FORM'
export const IDENTITY_PROCESSING_FORM = 'IDENTITY_PROCESSING_FORM'
export const IDENTITY_PROCESSING_INFO_FORM = 'IDENTITY_PROCESSING_INFO_FORM'
export const IDENTITY_BUSINESS_FORM = 'IDENTITY_BUSINESS_FORM'
export const BANK_ACCOUNT_FORM = 'BANK_ACCOUNT_FORM'
export const IDENTITY_BANK_ACCOUNT_INFO_FORM = 'IDENTITY_BANK_ACCOUNT_INFO_FORM'
export const DASHBOARD_CONFIGURATIONS_FORM = 'DASHBOARD_CONFIGURATIONS_FORM'
export const DASHBOARD_CONFIGURATIONS_ROLE_FORM = 'DASHBOARD_CONFIGURATIONS_ROLE_FORM'
export const SELECT_APPLICATION_FORM = 'SELECT_APPLICATION_FORM'
export const ADDITIONAL_UNDERWRITING_DATA_FORM = 'ADDITIONAL_UNDERWRITING_DATA_FORM'
export const MERCHANT_IDENTITY_CONFIRMATION_FORM = 'MERCHANT_IDENTITY_CONFIRMATION_FORM'
export const MULTI_CHECKBOX_SELECTOR_FORM = 'MULTI_CHECKBOX_SELECTOR_FORM'
export const RESPONSE_FILES_FORM = 'RESPONSE_FILES_FORM'
export const REUPLOAD_FILE_FORM = 'REUPLOAD_FILE_FORM'
export const WEBHOOK_FORM = 'WEBHOOK_FORM'
export const ADD_NEW_DOMAIN_FORM = 'ADD_NEW_DOMAIN_FORM'
export const SELF_SERVICE_SIGNUP_FORM = 'SELF_SERVICE_SIGNUP_FORM'
export const ADD_IOS_APPLICATION_NAME_FORM = 'ADD_IOS_APPLICATION_NAME_FORM'
export const UPLOAD_PAYMENT_PROCESSING_CERT_FORM = 'UPLOAD_PAYMENT_PROCESSING_CERT_FORM'
export const FILE_UPLOAD_FORM = 'FILE_UPLOAD_FORM'
export const EDIT_COMPLIANCE_FORM_TEMPLATE_FORM = 'EDIT_COMPLIANCE_FORM_TEMPLATE_FORM'
export const CREATE_COMPLIANCE_FORM_TEMPLATE_FORM = 'CREATE_COMPLIANCE_FORM_TEMPLATE_FORM'
export const COMPLIANCE_ASSESSMENT_INFORMATION_FORM = 'COMPLIANCE_ASSESSMENT_INFORMATION_FORM'
export const COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_FORM = 'COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_FORM'
export const COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM = 'COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM'
export const COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM = 'COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM'
export const COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM = 'COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM'
export const COMPLIANCE_APPLY_TEMPLATE_FORM = 'COMPLIANCE_APPLY_TEMPLATE_FORM'
export const DASHBOARD_FEEDBACK_FORM = 'DASHBOARD_FEEDBACK_FORM'
export const CONTACT_SUPPORT_FORM = 'CONTACT_SUPPORT_FORM'
export const CONTACT_SALES_FORM = 'CONTACT_SALES_FORM'
export const REPORT_SEARCH_FORM = 'REPORT_SEARCH_FORM'
export const APPLICATION_UNDERWRITING_SETTINGS_FORM = 'APPLICATION_UNDERWRITING_SETTINGS_FORM'
export const EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM = 'EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM'
export const EDIT_COMPLIANCE_TEMPLATE_FORM = 'EDIT_COMPLIANCE_TEMPLATE_FORM'
export const EDIT_PAYOUT_SETTINGS_FORM = 'EDIT_PAYOUT_SETTINGS_FORM'
export const NOTIFICATION_SETTINGS_FORM = 'NOTIFICATION_SETTINGS_FORM'
export const ATTEST_COMPLIANCE_FORM = 'ATTEST_COMPLIANCE_FORM'
export const EDIT_PAYMENT_INSTRUMENT_FORM = 'EDIT_PAYMENT_INSTRUMENT_FORM'
export const CREATE_WEBHOOK_FORM = 'CREATE_WEBHOOK_FORM'
export const CREATE_ONBOARDING_FORM_DETAILS_FORM = 'CREATE_ONBOARDING_FORM_DETAILS_FORM'
export const CREATE_ONBOARDING_FORM_BASICS_FORM = 'CREATE_ONBOARDING_FORM_BASICS_FORM'
export const CREATE_NEW_ONBOARDING_FORM_LINK_FORM = 'CREATE_NEW_ONBOARDING_FORM_LINK_FORM'
export const ONBOARDING_CONFIGURATIONS_FORM = 'ONBOARDING_CONFIGURATIONS_FORM'
export const EMAIL_CONFIGURATIONS_FORM = 'EMAIL_CONFIGURATIONS_FORM'
export const EDIT_DASHBOARD_ACCOUNT_NAME_FORM = 'EDIT_DASHBOARD_ACCOUNT_NAME_FORM'
export const RESEND_DASHBOARD_INVITE_FORM = 'RESEND_DASHBOARD_INVITE_FORM'
export const CREATE_TRANSACTION_FORM = 'CREATE_TRANSACTION_FORM'
export const CREATE_TRANSACTION_REVIEW_FORM = 'CREATE_TRANSACTION_REVIEW_FORM'
export const CREATE_PAYMENT_CONFIRMATION_FORM = 'CREATE_PAYMENT_CONFIRMATION_FORM'
export const CREATE_ROLE_FORM = 'CREATE_ROLE_FORM'
export const CHANGE_PASSWORD_FORM = 'CHANGE_PASSWORD_FORM'
export const WELCOME_NAME_CAPTURE_FORM = 'WELCOME_NAME_CAPTURE_FORM'
export const EDIT_USER_INFO_FORM = 'EDIT_USER_INFO_FORM'
export const ADD_EDIT_BUYER_INFORMATION_FORM = 'ADD_EDIT_BUYER_INFORMATION_FORM'
export const SWITCH_CUSTOMER_PROFILE_TYPE_FORM = 'SWITCH_CUSTOMER_PROFILE_TYPE_FORM'
export const LIVE_ACCOUNT_APPLICATION_PRICING_FORM = 'LIVE_ACCOUNT_APPLICATION_PRICING_FORM'
export const LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM = 'LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM'
export const LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM = 'LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM'
export const LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM = 'LIVE_ACCOUNT_APPLICATION_PROCESSING_INFO_FORM'
export const LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM = 'LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM'
export const LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM = 'LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM'
export const DASHBOARD_TYPE_FORM = 'DASHBOARD_TYPE_FORM'
export const UPDATE_DISPUTE_STATE_FORM = 'UPDATE_DISPUTE_STATE_FORM'
export const ACCEPT_LIABILITY_FORM = 'ACCEPT_LIABILITY_FORM'
export const SUBMIT_EVIDENCE_FORM = 'SUBMIT_EVIDENCE_FORM'
export const EDIT_UNDERWRITING_PROFILE_FORM = 'EDIT_UNDERWRITING_PROFILE_FORM'
export const EDIT_RECIPIENT_IDENTITY_CONTACT_FORM = 'EDIT_RECIPIENT_IDENTITY_CONTACT_FORM'
export const REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM = 'REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM'
export const REJECT_UNDERWRITING_ENTITY_FORM = 'REJECT_UNDERWRITING_ENTITY_FORM'
export const CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM = 'CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM'
export const CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM = 'CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM'
export const CUSTOMER_ONBOARDING_FORM_REVIEW_FORM = 'CUSTOMER_ONBOARDING_FORM_REVIEW_FORM'
export const CONFIRM_PASSWORD_MFA_FORM = 'CONFIRM_PASSWORD_MFA_FORM'
export const ADD_NEW_BUYER_IDENTITY_FORM = 'ADD_NEW_BUYER_IDENTITY_FORM'
export const SELECT_SENDER_FOR_CREATE_PAYMENT_FORM = 'SELECT_SENDER_FOR_CREATE_PAYMENT_FORM'
export const IDENTITY_BUSINESS_INFO_FORM = 'IDENTITY_BUSINESS_INFO_FORM'
export const MERCHANT_ONBOARDING_WELCOME_FORM = 'MERCHANT_ONBOARDING_WELCOME_FORM'
export const UPDATE_LIMITS_FORM = 'UPDATE_LIMITS_FORM'
export const CREATE_VELOCITY_LIMITS_FORM = 'CREATE_VELOCITY_LIMITS_FORM'
export const UPDATE_VELOCITY_LIMITS_FORM = 'UPDATE_VELOCITY_LIMITS_FORM'
export const UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM = 'UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM'
export const UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_REVIEW_FORM = 'UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_REVIEW_FORM'
export const UPDATE_ACCOUNT_BALANCE_FORM = 'UPDATE_ACCOUNT_BALANCE_FORM'
export const CREATE_PAYMENT_LINK_FORM = 'CREATE_PAYMENT_LINK_FORM'
export const GUEST_PAYMENT_LINK_FORM = 'GUEST_PAYMENT_LINK_FORM'
export const CAPTURE_AUTHORIZATION_FORM = 'CAPTURE_AUTHORIZATION_FORM'
export const SUBMIT_NOTE_FORM = 'SUBMIT_NOTE_FORM'
export const SELECT_ADD_DEVICE_MERCHANT_FORM = 'SELECT_ADD_DEVICE_MERCHANT_FORM'
export const ADD_NEW_DEVICE_FORM = 'ADD_NEW_DEVICE_FORM'
export const ADD_NEW_DEVICE_REVIEW_FORM = 'ADD_NEW_DEVICE_REVIEW_FORM'
export const EDIT_DEVICE_DETAILS_FORM = 'EDIT_DEVICE_DETAILS_FORM'
export const ACTIVATE_DEVICE_FORM = 'ACTIVATE_DEVICE_FORM'
export const DEACTIVATE_DEVICE_FORM = 'DEACTIVATE_DEVICE_FORM'
export const SET_IDLE_MESSAGE_FORM = 'SET_IDLE_MESSAGE_FORM'
export const SET_IDLE_SCREEN_FORM = 'SET_IDLE_SCREEN_FORM'
export const EDIT_DEVICE_CONFIGURATION_DETAILS_FORM = 'EDIT_DEVICE_CONFIGURATION_DETAILS_FORM'
export const EDIT_UNDERWRITING_WORKFLOW_FORM = 'EDIT_UNDERWRITING_WORKFLOW_FORM'
export const PAYMENT_LINK_CONFIGURATION_FORM = 'PAYMENT_LINKS_CONFIGURATION_FORM'
export const PAYMENT_LINK_TEMPLATE_FORM = 'PAYMENT_LINK_TEMPLATE_FORM'
export const CUSTOMER_ONBOARDING_FORM_WELCOME_FORM = 'CUSTOMER_ONBOARDING_FORM_WELCOME_FORM'
export const START_LIVE_ACCOUNT_APPLICATION_FORM = 'START_LIVE_ACCOUNT_APPLICATION_FORM'
export const EDIT_MERCHANT_ONBOARDING_FORM_SETTINGS_FORM = 'EDIT_MERCHANT_ONBOARDING_FORM_SETTINGS_FORM'
export const EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM = 'EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM'
export const EDIT_PAYOUT_PROFILE_FORM = 'EDIT_PAYOUT_PROFILE_FORM'
export const SEND_PAYOUT_RECIPIENT_FORM = 'SEND_PAYOUT_RECIPIENT_FORM'
export const SEND_PAYOUT_AMOUNT_FORM = 'SEND_PAYOUT_AMOUNT_FORM'
export const SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM = 'SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM'
export const ADD_NEW_RECIPIENT_FORM = 'ADD_NEW_RECIPIENT_FORM'
export const EDIT_CONTINUOUS_MONITORING_SETTINGS_FORM = 'EDIT_CONTINUOUS_MONITORING_SETTINGS_FORM'
export const CREATE_PAYOUT_LINK_FORM = 'CREATE_PAYOUT_LINK_FORM'
export const GUEST_PAYOUT_LINK_FORM = 'GUEST_PAYOUT_LINK_FORM'
export const DISPUTE_TRANSFER_FORM = 'DISPUTE_TRANSFER_FORM'
export const EDIT_FEE_PROFILE_CNP_FORM = 'EDIT_FEE_PROFILE_CNP_FORM'
export const EDIT_FEE_PROFILE_CP_FORM = 'EDIT_FEE_PROFILE_CP_FORM'
export const EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM = 'EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM'
export const REVIEW_AND_SUBMIT_FEE_PROFILE_FORM = 'REVIEW_AND_SUBMIT_FEE_PROFILE_FORM'
export const UPDATE_RECIPIENT_SETTINGS_FORM = 'UPDATE_RECIPIENT_SETTINGS_FORM'
export const UPDATE_ONBOARDING_FORM_FILE_NAME_FORM = 'UPDATE_ONBOARDING_FORM_FILE_NAME_FORM'
export const EDIT_FEE_PROFILE_SETTINGS_FORM = 'EDIT_FEE_PROFILE_SETTINGS_FORM'
export const EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM = 'EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM'
export const EDIT_FEE_PROFILE_RULES_ACH_FORM = 'EDIT_FEE_PROFILE_RULES_ACH_FORM'
export const EDIT_FEE_PROFILE_RULES_REVIEW_AND_SUBMIT_FORM = 'EDIT_FEE_PROFILE_RULES_REVIEW_AND_SUBMIT_FORM'
export const CREATE_BALANCE_ADJUSTMENT_FORM = 'CREATE_BALANCE_ADJUSTMENT_FORM'
export const CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM = 'CREATE_BALANCE_ADJUSTMENT_REVIEW_FORM'
export const CREATE_RECEIPT_FORM = 'CREATE_RECEIPT_FORM'
export const SEND_RECEIPT_FORM = 'SEND_RECEIPT_FORM'
export const CREATE_SUBSCRIPTION_FORM = 'CREATE_SUBSCRIPTION_FORM'
export const CREATE_SUBSCRIPTION_PLAN_FORM = 'CREATE_SUBSCRIPTION_PLAN_FORM'
export const CANCEL_SUBSCRIPTION_FORM = 'CANCEL_SUBSCRIPTION_FORM'
export const SETUP_MFA_SMS_FORM = 'SETUP_MFA_SMS_FORM'
export const ARCHIVE_SUBSCRIPTION_PLAN_FORM = 'ARCHIVE_SUBSCRIPTION_PLAN_FORM'
export const EDIT_SUBSCRIPTION_FORM = 'EDIT_SUBSCRIPTION_FORM'
export const EDIT_SUBSCRIPTION_PLAN_FORM = 'EDIT_SUBSCRIPTION_PLAN_FORM'
export const CONFIRM_EDIT_SUBSCRIPTION_PLAN_FORM = 'CONFIRM_EDIT_SUBSCRIPTION_PLAN_FORM'
export const CREATE_INSTANT_PAYOUT_FORM = 'CREATE_INSTANT_PAYOUT_FORM'
export const RESOLVE_MONITORING_ALERTS_FORM = 'RESOLVE_MONITORING_ALERTS_FORM'

export const FILTER_FORM_CONSTANTS = {
  [DISPUTES]: DISPUTES_FILTER_FORM,
  [TRANSFERS]: TRANSFERS_FILTER_FORM,
  [AUTHORIZATIONS]: AUTHORIZATIONS_FILTER_FORM,
  [ONBOARDING_SETTLEMENTS]: SETTLEMENTS_FILTER_FORM,
  [IDENTITIES]: IDENTITIES_FILTER_FORM,
  [MERCHANTS]: MERCHANTS_FILTER_FORM,
  [PAYMENT_INSTRUMENTS]: PAYMENT_INSTRUMENTS_FILTER_FORM,
}
