import map from 'lodash/map'
import filter from 'lodash/filter'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

const formatCheckboxGroupDisplayData = ({ data: unfilteredFormValues = {}, mapping: valueToLabelMapping }) => {
  const checkedValues = filter(Object.keys(unfilteredFormValues), (value) => get(unfilteredFormValues, value))

  return map(checkedValues, (checkedValue) => {
    const defaultValueLabel = map(checkedValue.split('_'), (word) => capitalize(word)).join(' ')

    return valueToLabelMapping ? get(valueToLabelMapping, checkedValue) : defaultValueLabel
  }).join(', ')
}

export default formatCheckboxGroupDisplayData
