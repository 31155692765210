import get from 'lodash/get'
import map from 'lodash/map'

const selectedApplicationFields = [
  {
    name: 'selectedApplicationId',
    label: 'Selected Application',
  },
]

const SelectApplicationDisplayData = (values) => map(selectedApplicationFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, `${name}.value.id`)) : get(values, `${name}.value.id`),
}))

export default SelectApplicationDisplayData
