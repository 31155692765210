import React, { Component } from 'react'
import { connect } from 'react-redux'
import CancelApplicationForm from './CancelApplicationForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import redirectRequest from 'utilities/actions/redirectRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { HOME_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => { dispatch(hideModalAction()) },
    redirectUser: ({ credentialId }) => { dispatch(redirectRequest({ path: HOME_PATH({ credentialId }) })) },
  }
}

class CancelApplicationFormC extends Component {
    redirectUserToHome = () => {
      const { closeModal, redirectUser, credentialId } = this.props
      redirectUser({ credentialId })
      closeModal()
    }

    render() {
      return (
        <CancelApplicationForm
          redirectUserToHome={this.redirectUserToHome}
          {...this.props}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelApplicationFormC)
