import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_GUEST_ACCESS_FORM } from 'constants/apiConstants'

const patchGuestAccessFormAPI = ({ id, values }) => {
  return guestDashboardAPI.patch({
    path: DASHBOARD_GUEST_ACCESS_FORM({ id }),
    values,
  })
}

export default patchGuestAccessFormAPI
