import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ONBOARDING_FORM_SUBMIT_TO_UNDERWRITING } from 'constants/apiConstants'

const postCustomerOnboardingFormSubmitToUnderwritingAPI = ({ id, values, meta }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_ONBOARDING_FORM_SUBMIT_TO_UNDERWRITING({ id }),
    values,
    meta,
  })
}

export default postCustomerOnboardingFormSubmitToUnderwritingAPI
