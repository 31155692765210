import { GET_FRAUD_SCORES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getFraudScoresRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_FRAUD_SCORES_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getFraudScoresRequest
