import React, { Component } from 'react'
import { connect } from 'react-redux'
import Breadcrumbs from './Breadcrumbs'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import { getRouteLocationSelector } from 'state-layer/selectors'
import split from 'lodash/split'
import get from 'lodash/get'
import has from 'lodash/has'
import head from 'lodash/head'
import reduce from 'lodash/reduce'

import {
  DASHBOARD,
  ROUTES,
  SPECIAL_ROUTES,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const routingInfo = getRouteLocationSelector(state)
  const pathname = get(routingInfo, 'pathname', '')
  const pathnameParts = split(pathname, '/')
  const pathLinks = reduce(pathnameParts, (accumulate, pathName) => {
    const len = accumulate.length
    const total = len ? `${accumulate[len - 1].total}/${pathName}` : pathName
    const displayName = get(ROUTES, pathName, pathName)
    const path = has(SPECIAL_ROUTES, pathName) ? get(SPECIAL_ROUTES, pathName)(pathnameParts) : total
    const linkClass = has(ROUTES, pathName) ? 'resourceName' : 'resourceId'

    accumulate.push({
      id: pathName,
      name: displayName,
      path,
      linkClass,
      total,
    })

    return accumulate
  }, [])

  const topLevelPath = head(pathnameParts)
  const inDashboard = topLevelPath === DASHBOARD || !topLevelPath
  const homeIndex = inDashboard ? 1 : 0
  const resourceLinks = pathLinks.slice(homeIndex + 1)

  return {
    resourceLinks,
  }
}

class BreadcrumbsC extends Component {
  render() {
    return (
      <Breadcrumbs
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(BreadcrumbsC)
