import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Loader from 'components/Shared/Loader/Loader'
import FormFileUploaderButton from 'components/Shared/Inputs/FormFileUploaderButton/FormFileUploaderButton'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import { BANK_STATEMENT } from 'constants/fileConstants'
import { businessCheckingLabelMap } from 'constants/labelConstants'
import get from 'lodash/get'

import {
  FILE_CHECK,
  IMAGE_FILE_ICON,
} from 'constants/iconConstants'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

import {
  ACCOUNT_TYPE,
  CREATE_IDENTITY_ACCOUNT_TYPE_TOOLTIP,
  ROUTING_NUMBER,
  ACCOUNT_NUMBER,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  ACCOUNT_HOLDER_NAME,
  BUSINESS_CHECKING,
  BANK_DOCUMENT,
  BANK_DOCUMENT_LOWER_LABEL,
  REMOVE,
  CONFIRM_ACCOUNT_NUMBER,
  BANK_DOCUMENT_LOWER_LABEL_CAN,
  BUSINESS_SAVINGS,
} from 'constants/language/languageConstants'

const merchantOnboardingAccountTypeOptions = (country) => {
  return [
    { label: businessCheckingLabelMap(country), value: 'BUSINESS_CHECKING' },
  ]
}

const applicationOnboardingAccountTypeOptions = (country) => {
  return [
    { label: businessCheckingLabelMap(country), value: 'CHECKING' },
    { label: BUSINESS_SAVINGS, value: 'SAVINGS' },
  ]
}

const validateAccountNumber = (input, formValues) => {
  return input && input === get(formValues, 'bankAccountData.accountNumber') ? undefined : 'Bank account numbers must match'
}

// set file upload configs for onboarding forms (customer and merchant)
const config = {
  multiple: false,
  maxSize: 20000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag and drop, or click to select and upload a file',
  },
  {
    name: 'sub',
    message: 'Accept: jpeg, png, tiff, pdf; Max Size: 20 MB',
  },
]

// this component is used for bank account creation in hosted onboarding
const BankFieldset = ({
  name = 'bankAccountData',
  country = USA,
  showUploadFileModal = () => {},
  removeFile = () => {},
  bankDocumentFileName = '',
  bankDocumentFileErrorMsg = '',
  isFormInvalid,
  directUploadFunc = () => {},
  isDirectUpload = false,
  onboardingFormId,
  applicationId,
  isStandaloneUnderwriting = false,
  isFetchingBankDocument = false,
  formName = '',
  isAccessForm = false,
}) => {
  const fieldName = (field) => `${name}.${field}`
  const showRoutingNumber = country === USA
  const showTransitNumber = country === CAN
  const showInstitutionNumber = country === CAN
  const showBankDocumentUpload = country === CAN || isStandaloneUnderwriting || isAccessForm

  return (
    <fieldset className='BankFieldset'>
      <Field
        name={fieldName('name')}
        label={ACCOUNT_HOLDER_NAME}
        component={InputField}
      />

      <Field
        name={fieldName('accountType')}
        label={ACCOUNT_TYPE}
        options={isAccessForm ? applicationOnboardingAccountTypeOptions(country) : merchantOnboardingAccountTypeOptions(country)}
        tooltip={CREATE_IDENTITY_ACCOUNT_TYPE_TOOLTIP}
        component={SelectField}
        disabled={!!onboardingFormId}
      />

      {showRoutingNumber && (
        <Field
          name={fieldName('bankCode')}
          label={ROUTING_NUMBER}
          placeholder='123456789'
          component={InputField}
          format={formatNumberField}
        />
      )}

      {showTransitNumber && (
        <Field
          name={fieldName('transitNumber')}
          label={TRANSIT_NUMBER}
          placeholder='12345'
          component={InputField}
          format={formatNumberField}
        />
      )}

      {showInstitutionNumber && (
        <Field
          name={fieldName('institutionNumber')}
          label={INSTITUTION_NUMBER}
          placeholder='123'
          component={InputField}
          format={formatNumberField}
        />
      )}

      <Field
        name={fieldName('accountNumber')}
        label={ACCOUNT_NUMBER}
        component={InputField}
        format={formatNumberField}
      />

      <Field
        name={fieldName('accountNumberVerification')}
        label={CONFIRM_ACCOUNT_NUMBER}
        component={InputField}
        validate={validateAccountNumber}
      />

      {showBankDocumentUpload && (
      <>
        {!bankDocumentFileName && isFetchingBankDocument && (
        <div className='bank-document-file-uploaded'>
          <div className='label'><div className='flex'>{BANK_DOCUMENT} <span className='required-field-string'>*</span></div></div>
          <div className='lower-label'>{country === CAN ? BANK_DOCUMENT_LOWER_LABEL_CAN : BANK_DOCUMENT_LOWER_LABEL}</div>

          <div className='file-name-area flex space-between'>
            <Loader />
          </div>
        </div>
        )}
        {!bankDocumentFileName && !isFetchingBankDocument && (
        <Field
          component={FormFileUploaderButton}
          fieldName='bankDocument'
          name={fieldName('bankDocument')}
          label={BANK_DOCUMENT}
          helperText={country === CAN ? BANK_DOCUMENT_LOWER_LABEL_CAN : BANK_DOCUMENT_LOWER_LABEL}
          formName={formName}
          showUploadFileModal={showUploadFileModal}
          fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
          fileType={BANK_STATEMENT}
          displayFieldName={BANK_DOCUMENT}
          errorMessage={bankDocumentFileErrorMsg}
          isFormInvalid={isFormInvalid}
          directUploadFunc={directUploadFunc}
          directUpload={isDirectUpload}
          onboardingFormId={onboardingFormId}
          applicationId={applicationId}
          config={config}
          instructions={instructions}
        />
        )}
        {bankDocumentFileName && (
        <div className='bank-document-file-uploaded'>
          <div className='label'><div className='flex'>{BANK_DOCUMENT} <span className='required-field-string'>*</span></div></div>
          <div className='lower-label'>{country === CAN ? BANK_DOCUMENT_LOWER_LABEL_CAN : BANK_DOCUMENT_LOWER_LABEL}</div>

          <div className='file-name-area flex space-between'>
            <div><i className={`file-check-icon fa fa-${FILE_CHECK}`} />{bankDocumentFileName}</div>
            <div className='remove-action' onClick={removeFile}>{REMOVE}</div>
          </div>
        </div>
        )}
      </>
      )}
    </fieldset>
  )
}

BankFieldset.propTypes = {
  name: PropTypes.string,
  country: PropTypes.string,
  showUploadFileModal: PropTypes.func,
  removeFile: PropTypes.func,
  bankDocumentFileName: PropTypes.string,
  bankDocumentFileErrorMsg: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  directUploadFunc: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  onboardingFormId: PropTypes.string,
  applicationId: PropTypes.string,
  isStandaloneUnderwriting: PropTypes.bool,
  isFetchingBankDocument: PropTypes.bool,
  isDirectUpload: PropTypes.bool,
  formName: PropTypes.string,
  isAccessForm: PropTypes.bool,
}

export default BankFieldset
