import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import CardNotPresentForm from './CardNotPresentForm'
import { EDIT_FEE_PROFILE_CNP_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import size from 'lodash/size'

import {
  AMEX_LABEL,
  DINERS_CLUB_LABEL,
  DISCOVER_LABEL,
  JCB_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

import {
  BLENDED,
  BLENDED_HELPER_TEXT,
  INTERCHANGE_PLUS,
  INTERCHANGE_PLUS_HELPER_TEXT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)
  const isFlex = isFlexPlatform()
  const requiresGuardRails = isFetchingCurrentUser ? true : isFlex
  const isFetching = requiresGuardRails ? (get(state, `feeProfilesR.${FETCHING}`, true) || get(state, `feeProfileRulesR.${FETCHING}`, true)) : get(state, `feeProfilesR.${FETCHING}`, true)
  const formSelector = formValueSelector(EDIT_FEE_PROFILE_CNP_FORM)
  const feeProfile = get(props, 'feeProfile')
  const formValues = get(props, 'formValues.cnp')
  const chargeInterchange = formSelector(state, 'chargeInterchange')
  const chargeDuesAssessments = formSelector(state, 'chargeDuesAssessments')

  const blendedFeeProfileRules = get(props, 'blendedFeeProfileRules')
  const interchangeFeeProfileRules = get(props, 'interchangeFeeProfileRules')
  const applyGuardRails = get(props, 'applyGuardRails')
  const guardRail = chargeInterchange === 'interchange-plus' ? interchangeFeeProfileRules : blendedFeeProfileRules

  const pricingStrategyOptions = [
    { label: BLENDED, value: 'blended', helperText: BLENDED_HELPER_TEXT, disabled: applyGuardRails && !blendedFeeProfileRules, disabledTooltip: 'This pricing strategy is not available.' },
    { label: INTERCHANGE_PLUS, value: 'interchange-plus', helperText: INTERCHANGE_PLUS_HELPER_TEXT, disabled: applyGuardRails && !interchangeFeeProfileRules, disabledTooltip: 'This pricing strategy is not available.' },
  ]

  const visaOverrideEnabled = formSelector(state, 'visaOverrideEnabled')
  const mastercardOverrideEnabled = formSelector(state, 'mastercardOverrideEnabled')
  const amexOverrideEnabled = formSelector(state, 'amexOverrideEnabled')
  const discoverOverrideEnabled = formSelector(state, 'discoverOverrideEnabled')
  const jcbOverrideEnabled = formSelector(state, 'jcbOverrideEnabled')
  const dinersClubOverrideEnabled = formSelector(state, 'dinersClubOverrideEnabled')

  const cardBrandRows = {
    [VISA_LABEL]: {
      basisPointsField: 'visaBasisPoints',
      fixedFeeField: 'visaFixedFee',
      overrideField: 'visaOverrideEnabled',
      enabled: visaOverrideEnabled,
    },
    [MASTERCARD_LABEL]: {
      basisPointsField: 'mastercardBasisPoints',
      fixedFeeField: 'mastercardFixedFee',
      overrideField: 'mastercardOverrideEnabled',
      enabled: mastercardOverrideEnabled,
    },
    [AMEX_LABEL]: {
      basisPointsField: 'americanExpressBasisPoints',
      fixedFeeField: 'americanExpressFixedFee',
      overrideField: 'amexOverrideEnabled',
      enabled: amexOverrideEnabled,
    },
    [DISCOVER_LABEL]: {
      basisPointsField: 'discoverBasisPoints',
      fixedFeeField: 'discoverFixedFee',
      overrideField: 'discoverOverrideEnabled',
      enabled: discoverOverrideEnabled,
    },
    [JCB_LABEL]: {
      basisPointsField: 'jcbBasisPoints',
      fixedFeeField: 'jcbFixedFee',
      overrideField: 'jcbOverrideEnabled',
      enabled: jcbOverrideEnabled,
    },
    [DINERS_CLUB_LABEL]: {
      basisPointsField: 'dinersClubBasisPoints',
      fixedFeeField: 'dinersClubFixedFee',
      overrideField: 'dinersClubOverrideEnabled',
      enabled: dinersClubOverrideEnabled,
    },
  }

  const getChargeInterchangeInitialValue = () => {
    const enabledOptions = filter(pricingStrategyOptions, { disabled: false })

    if (size(enabledOptions) === 1) {
      return get(enabledOptions, '[0].value')
    }

    return get(formValues, 'chargeInterchange') || (get(feeProfile, 'chargeInterchange') === true ? 'interchange-plus' : 'blended')
  }

  const getDuesAssessmentsInitialValues = (formValue, feeProfileValue, feeProfileParentValue) => {
    if (formValue === true || formValue === false) {
      return formValue
    }

    if (feeProfileValue === true || feeProfileValue === false) {
      return feeProfileValue
    }

    if (feeProfileParentValue === true || feeProfileParentValue === false) {
      return feeProfileParentValue
    }

    return true
  }

  const initialValues = !isFetching ? {
    chargeInterchange: getChargeInterchangeInitialValue(),
    basisPoints: get(formValues, 'basisPoints') || get(feeProfile, 'basisPoints'),
    fixedFee: get(formValues, 'fixedFee') || get(feeProfile, 'fixedFee'),
    cardCrossBorderBasisPoints: get(formValues, 'cardCrossBorderBasisPoints') || get(feeProfile, 'cardCrossBorderBasisPoints'),
    cardCrossBorderFixedFee: get(formValues, 'cardCrossBorderFixedFee') || get(feeProfile, 'cardCrossBorderFixedFee'),
    visaOverrideEnabled: !!get(formValues, 'visaBasisPoints') || !!get(feeProfile, 'visaBasisPoints') || !!get(formValues, 'visaFixedFee') || !!get(feeProfile, 'visaFixedFee'),
    visaBasisPoints: get(formValues, 'visaBasisPoints') || get(feeProfile, 'visaBasisPoints'),
    visaFixedFee: get(formValues, 'visaFixedFee') || get(feeProfile, 'visaFixedFee'),
    mastercardOverrideEnabled: !!get(formValues, 'mastercardBasisPoints') || !!get(feeProfile, 'mastercardBasisPoints') || !!get(formValues, 'mastercardFixedFee') || !!get(feeProfile, 'mastercardFixedFee'),
    mastercardBasisPoints: get(formValues, 'mastercardBasisPoints') || get(feeProfile, 'mastercardBasisPoints'),
    mastercardFixedFee: get(formValues, 'mastercardFixedFee') || get(feeProfile, 'mastercardFixedFee'),
    amexOverrideEnabled: !!get(formValues, 'americanExpressBasisPoints') || !!get(feeProfile, 'americanExpressBasisPoints') || !!get(formValues, 'americanExpressFixedFee') || !!get(feeProfile, 'americanExpressFixedFee'),
    americanExpressBasisPoints: get(formValues, 'americanExpressBasisPoints') || get(feeProfile, 'americanExpressBasisPoints'),
    americanExpressFixedFee: get(formValues, 'americanExpressFixedFee') || get(feeProfile, 'americanExpressFixedFee'),
    discoverOverrideEnabled: !!get(formValues, 'discoverBasisPoints') || !!get(feeProfile, 'discoverBasisPoints') || !!get(formValues, 'discoverFixedFee') || !!get(feeProfile, 'discoverFixedFee'),
    discoverBasisPoints: get(formValues, 'discoverBasisPoints') || get(feeProfile, 'discoverBasisPoints'),
    discoverFixedFee: get(formValues, 'discoverFixedFee') || get(feeProfile, 'discoverFixedFee'),
    jcbOverrideEnabled: !!get(formValues, 'jcbBasisPoints') || !!get(feeProfile, 'jcbBasisPoints') || !!get(formValues, 'jcbFixedFee') || !!get(feeProfile, 'jcbFixedFee'),
    jcbBasisPoints: get(formValues, 'jcbBasisPoints') || get(feeProfile, 'jcbBasisPoints'),
    jcbFixedFee: get(formValues, 'jcbFixedFee') || get(feeProfile, 'jcbFixedFee'),
    dinersClubOverrideEnabled: !!get(formValues, 'dinersClubBasisPoints') || !!get(feeProfile, 'dinersClubBasisPoints') || !!get(formValues, 'dinersClubFixedFee') || !!get(feeProfile, 'dinersClubFixedFee'),
    dinersClubBasisPoints: get(formValues, 'dinersClubBasisPoints') || get(feeProfile, 'dinersClubBasisPoints'),
    dinersClubFixedFee: get(formValues, 'dinersClubFixedFee') || get(feeProfile, 'dinersClubFixedFee'),
    disputeFixedFee: get(formValues, 'disputeFixedFee') || get(feeProfile, 'disputeFixedFee'),
    disputeInquiryFixedFee: get(formValues, 'disputeInquiryFixedFee') || get(feeProfile, 'disputeInquiryFixedFee'),
    visaAssessmentsBasisPoints: get(formValues, 'visaAssessmentsBasisPoints') || get(feeProfile, 'visaAssessmentsBasisPoints'),
    visaAcquirerProcessingFixedFee: get(formValues, 'visaAcquirerProcessingFixedFee') || get(feeProfile, 'visaAcquirerProcessingFixedFee'),
    visaCreditVoucherFixedFee: get(formValues, 'visaCreditVoucherFixedFee') || get(feeProfile, 'visaCreditVoucherFixedFee'),
    visaBaseIISystemFileTransmissionFixedFee: get(formValues, 'visaBaseIISystemFileTransmissionFixedFee') || get(feeProfile, 'visaBaseIISystemFileTransmissionFixedFee'),
    visaKilobyteAccessFixedFee: get(formValues, 'visaKilobyteAccessFixedFee') || get(feeProfile, 'visaKilobyteAccessFixedFee'),
    mastercardAssessmentsUnder1kBasisPoints: get(formValues, 'mastercardAssessmentsUnder1kBasisPoints') || get(feeProfile, 'mastercardAssessmentsUnder1kBasisPoints'),
    mastercardAssessmentsOver1kBasisPoints: get(formValues, 'mastercardAssessmentsOver1kBasisPoints') || get(feeProfile, 'mastercardAssessmentsOver1kBasisPoints'),
    mastercardAcquirerFeesBasisPoints: get(formValues, 'mastercardAcquirerFeesBasisPoints') || get(feeProfile, 'mastercardAcquirerFeesBasisPoints'),
    americanExpressAssessmentBasisPoints: get(formValues, 'americanExpressAssessmentBasisPoints') || get(feeProfile, 'americanExpressAssessmentBasisPoints'),
    discoverAssessmentsBasisPoints: get(formValues, 'discoverAssessmentsBasisPoints') || get(feeProfile, 'discoverAssessmentsBasisPoints'),
    discoverDataUsageFixedFee: get(formValues, 'discoverDataUsageFixedFee') || get(feeProfile, 'discoverDataUsageFixedFee'),
    discoverNetworkAuthorizationFixedFee: get(formValues, 'discoverNetworkAuthorizationFixedFee') || get(feeProfile, 'discoverNetworkAuthorizationFixedFee'),
    chargeDuesAssessments: getDuesAssessmentsInitialValues(get(formValues, 'chargeDuesAssessments'), get(feeProfile, 'chargeDuesAssessments')),
    visaChargeDuesAssessments: getDuesAssessmentsInitialValues(get(formValues, 'visaChargeDuesAssessments'), get(feeProfile, 'visaChargeDuesAssessments'), get(feeProfile, 'chargeDuesAssessments')),
    mastercardChargeDuesAssessments: getDuesAssessmentsInitialValues(get(formValues, 'mastercardChargeDuesAssessments'), get(feeProfile, 'mastercardChargeDuesAssessments'), get(feeProfile, 'chargeDuesAssessments')),
    americanExpressChargeDuesAssessments: getDuesAssessmentsInitialValues(get(formValues, 'americanExpressChargeDuesAssessments'), get(feeProfile, 'americanExpressChargeDuesAssessments'), get(feeProfile, 'chargeDuesAssessments')),
    discoverChargeDuesAssessments: getDuesAssessmentsInitialValues(get(formValues, 'discoverChargeDuesAssessments'), get(feeProfile, 'discoverChargeDuesAssessments'), get(feeProfile, 'chargeDuesAssessments')),
  } : null

  return {
    isFetching,
    initialValues,
    cardBrandRows,
    chargeInterchange,
    pricingStrategyOptions,
    guardRail,
    applyGuardRails,
    chargeDuesAssessments,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateField: (fieldName, value) => dispatch(change(EDIT_FEE_PROFILE_CNP_FORM, fieldName, value)),
  }
}

class CardNotPresentFormC extends Component {
  toggleCardBrandFields = (fields) => {
    const { updateField } = this.props
    forEach(fields, field => {
      updateField(field, null)
    })
  }

  toggleChargeDuesAssessmentsField = () => {
    const { updateField, chargeDuesAssessments } = this.props
    const value = !chargeDuesAssessments
    updateField('chargeDuesAssessments', value)
    updateField('visaChargeDuesAssessments', value)
    updateField('mastercardChargeDuesAssessments', value)
    updateField('americanExpressChargeDuesAssessments', value)
    updateField('discoverChargeDuesAssessments', value)
  }

  render() {
    return (
      <CardNotPresentForm
        {...this.props}
        toggleCardBrandFields={this.toggleCardBrandFields}
        toggleChargeDuesAssessmentsField={this.toggleChargeDuesAssessmentsField}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardNotPresentFormC)
