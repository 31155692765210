import './AchAndAdditionalFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import Loader from 'components/Shared/Loader/Loader'
import prevFlowStep from 'utilities/prevFlowStep'
import validateAchAndAdditionalForm from 'utilities/validate/validateAchAndAdditionalForm'
import { EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM } from 'constants/formConstants'
import get from 'lodash/get'

import {
  ACH_CREDIT,
  ACH_DEBIT,
  ACH_TRANSFERS,
  ACH_TRANSFERS_SUBTITLE,
  ADDITIONAL_FEES,
  ADDITIONAL_FEES_SUBTITLE,
  CANCEL,
  FEE_TYPE,
  FIXED_FEE,
  MAXIMUM_ACH_PERCENTAGE_FEE_ALLOWED,
  PERCENTAGE_FEE,
  PREVIOUS_STEP,
  RETURNS,
  SAVE_AND_CONTINUE,
  SUPPLEMENTAL_FEE_1,
  SUPPLEMENTAL_FEE_2,
  REQUIRED_ACH,
} from 'constants/language/languageConstants'

const AchAndAdditionalForm = ({
  isFetching = false,
  handleSubmit = () => {},
  currency = 'USD',
  showCurrency = false,
  guardRail = {},
}) => {
  return (
    <form className='AchAndAdditionalFormFeeProfile' onSubmit={handleSubmit}>
      {isFetching && <Loader />}
      {!isFetching && (
      <>
        <div className='form-content'>
          <h4>{ACH_TRANSFERS}</h4>
          <div className='flex space-between'>
            <div className='p-1 secondary'>{ACH_TRANSFERS_SUBTITLE}</div>
            <RequiredFieldNotice />
          </div>
          <div className='container-3'>
            <div className='item header p-2-bold'>{FEE_TYPE}</div>
            <div className='item header'>
              <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
              {showCurrency && <div className='currency'>{currency}</div>}
            </div>
            <div className='item header'>
              <div className='p-2-bold'>{FIXED_FEE}</div>
              {showCurrency && <div className='currency'>{currency}</div>}
            </div>
          </div>
          <div className='container-3'>
            <div className='item'>{REQUIRED_ACH}</div>
            <div className='item'>
              <PercentageField
                name='achBasisPoints'
                helperText={get(guardRail, 'achBasisPointsString')}
              />
            </div>
            <div className='item'>
              <AmountField
                name='achFixedFee'
                helperText={get(guardRail, 'achFixedFeeString')}
              />
            </div>
          </div>
          <div className='container-3'>
            <div className='item'>{MAXIMUM_ACH_PERCENTAGE_FEE_ALLOWED}</div>
            <div className='item' />
            <div className='item'>
              <AmountField
                name='achBasisPointsFeeLimit'
              />
            </div>
          </div>
          <div className='container-1'>
            <div className='item sub-header'>{RETURNS}</div>
          </div>
          <div className='container-2'>
            <div className='item'>{ACH_DEBIT}</div>
            <div className='item'>
              <AmountField
                name='achDebitReturnFixedFee'
                helperText={get(guardRail, 'achDebitReturnFixedFeeString')}
              />
            </div>
          </div>
          <div className='container-2'>
            <div className='item'>{ACH_CREDIT}</div>
            <div className='item'>
              <AmountField
                name='achCreditReturnFixedFee'
                helperText={get(guardRail, 'achCreditReturnFixedFeeString')}
              />
            </div>
          </div>
          <div className='divider' />
          <h4>{ADDITIONAL_FEES}</h4>
          <div className='p-1 secondary'>{ADDITIONAL_FEES_SUBTITLE}</div>
          <div className='container-2'>
            <div className='item header p-2-bold'>{FEE_TYPE}</div>
            <div className='item header'>
              <div className='p-2-bold'>{FIXED_FEE}</div>
              {showCurrency && <div className='currency'>{currency}</div>}
            </div>
          </div>
          <div className='container-2'>
            <div className='item'>{SUPPLEMENTAL_FEE_1}</div>
            <div className='item'>
              <AmountField
                name='ancillaryFixedFeePrimary'
                helperText={get(guardRail, 'ancillaryFixedFeePrimaryString')}
              />
            </div>
          </div>
          <div className='container-2'>
            <div className='item'>{SUPPLEMENTAL_FEE_2}</div>
            <div className='item'>
              <AmountField
                name='ancillaryFixedFeeSecondary'
                helperText={get(guardRail, 'ancillaryFixedFeeSecondaryString')}
              />
            </div>
          </div>
        </div>
      </>
      )}


      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SAVE_AND_CONTINUE} />
          </div>
        </div>
      </div>
    </form>
  )
}

AchAndAdditionalForm.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
  showCurrency: PropTypes.bool,
  guardRail: PropTypes.object,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_ACH_ADDITIONAL_FORM,
  validate: validateAchAndAdditionalForm,
})(AchAndAdditionalForm)
