import './CustomerOnboardingFormsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getCustomerOnboardingFormsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { CUSTOMER_ONBOARDING_FORMS } from 'constants/linkConstants'
import { GET_CUSTOMER_ONBOARDING_FORMS } from 'constants/flowConstants'
import { APPLICATIONS_ICON } from 'constants/iconConstants'
import { ONBOARDING_FORMS_EMPTY_TABLE_MESSAGE } from 'constants/language/languageConstants'

const CustomerOnboardingForms = ({
  isFetching = false,
  actions = [],
  customerOnboardingFormPath = '',
}) => {
  return (
    <div className='CustomerOnboardingForms'>
      <TableC
        flow={GET_CUSTOMER_ONBOARDING_FORMS}
        columnDescriptors={columnDescriptors}
        selector={getCustomerOnboardingFormsSelector}
        linksKey={CUSTOMER_ONBOARDING_FORMS}
        isFetching={isFetching}
        actions={actions}
        emptyMessage={ONBOARDING_FORMS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${APPLICATIONS_ICON}`}
        path={customerOnboardingFormPath}
      />
    </div>
  )
}

CustomerOnboardingForms.propTypes = {
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  customerOnboardingFormPath: PropTypes.string,
}

export default CustomerOnboardingForms
