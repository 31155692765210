import MiniNav from './MiniNav'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getNotificationsRequest from 'utilities/actions/get/getNotificationsRequest'
import getLatestNotificationsRequest from 'utilities/actions/get/getLatestNotificationsRequest'
import showNotificationsRequest from 'utilities/actions/showNotificationsRequest'
import showSupportDropdownRequest from 'utilities/actions/showSupportDropdownRequest'
import isAdministratorView from 'utilities/is/isAdministratorView'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hideModalAction from 'utilities/actions/hideModalAction'
import hideSupportMenu from 'utilities/hide/hideSupportMenu'
import { get20LatestNotifications } from 'state-layer/selectors'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import { SHOW_NOTIFICATIONS } from 'constants/featureFlagConstants'
import { UNPROTECTED_PATHS } from 'constants/pathConstants'
import { CONTACT_SALES_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const showNotificationFlag = get(state, 'featureFlagsR.showNotifications')
  const formattedPath = get(props, 'formattedPath')
  const notifications = get20LatestNotifications(state)
  const currentUser = getCurrentUser()
  const currentUserEmail = get(currentUser, 'userEmail')
  const isSelfServiceSandboxUser = get(currentUser, 'isSelfServiceSandboxUser')

  const newNotificationsIndicator = some(notifications, ({ status }) => (status === 'Unread'))

  const notificationsQueries = {
    limit: 20,
  }

  return {
    credentials,
    newNotificationsIndicator,
    showNotificationsIcon: !isAdministratorView(formattedPath) && getFeatureFlag(SHOW_NOTIFICATIONS),
    showSupportToggle: !isAdministratorView(formattedPath) && !hideSupportMenu({ credentials }),
    showContactSalesButton: !isAdministratorView(formattedPath) && isSelfServiceSandboxUser,
    notificationsQueries,
    showNotificationFlag,
    currentUserEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (credentials, queries, meta) => dispatch(getNotificationsRequest({ credentials, queries, meta })),
    getLatestNotifications: (credentials, queries, meta) => dispatch(getLatestNotificationsRequest({ credentials, queries, meta })),
    showNotificationsAction: (credentials) => dispatch(showNotificationsRequest({ credentials })),
    showSupportAction: (credentials) => dispatch(showSupportDropdownRequest({ credentials })),
    showContactSalesModal: (modalProps) => dispatch(showModalAction({ modalType: CONTACT_SALES_MODAL, modalProps })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class MainNavC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showNotifications: false,
      showSupport: false,
      interval: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { credentials: prevCredentials, showNotificationFlag: prevShowNotificationFlag } = prevProps
    const { credentials, getNotifications, getLatestNotifications, notificationsQueries, showNotificationFlag } = this.props
    const { interval } = this.state

    // TODO: when removing getFeatureFlag(SHOW_NOTIFICATIONS), change to empty prevCredential to credential check
    if (!prevShowNotificationFlag && showNotificationFlag) {
      getNotifications(credentials, { ...notificationsQueries, include_seen: true }, { showErrors: false, overwriteReducer: true })

      if (!interval) {
        const notificationInterval = setInterval(() => {
          const pathName = window.location.pathname
          const isProtectedPath = !UNPROTECTED_PATHS.includes(pathName)
          if (!isEmpty(credentials) && isProtectedPath) {
            getLatestNotifications(credentials, { ...notificationsQueries, include_seen: true }, { showErrors: false })
          } else {
            clearInterval(notificationInterval)
          }
        }, 180000)

        this.setState({ interval: notificationInterval })
      }
    }

    // TODO: when removing getFeatureFlag(SHOW_NOTIFICATIONS), remove flag check here
    // remove the interval when changing credentials
    if ((!isEmpty(prevCredentials) && isEmpty(credentials)) || (prevShowNotificationFlag && !showNotificationFlag)) {
      clearInterval(interval)
      this.setState({ interval: null })
    }
  }

  componentWillUnmount() {
    const { interval } = this.state
    // remove the interval when user logs out
    clearInterval(interval)

    document.removeEventListener('click', this.handleClickOutsideSupportMenu)
    document.removeEventListener('click', this.handleClickOutsideNotificationsMenu)
  }

  handleLogout = () => {
    const { auth } = this.props

    if (auth) {
      auth.logout()
    }
  }

  handleClickOutsideNotificationsMenu = (event) => {
    const clickedInside = event?.target.closest('.NotificationsDropdown')

    if (!clickedInside) {
      this.setState({ showNotifications: false })
      document.removeEventListener('click', this.handleClickOutsideNotificationsMenu)
    }
  }

  handleClickOutsideSupportMenu = (event) => {
    const clickedInside = event?.target.closest('.Support')

    if (!clickedInside) {
      this.setState({ showSupport: false })
      document.removeEventListener('click', this.handleClickOutsideSupportMenu)
    }
  }

  toggleNotifications = (e) => {
    const { credentials, showNotificationsAction } = this.props
    const { showNotifications } = this.state

    if (!showNotifications) {
      showNotificationsAction(credentials)
    }

    if (allowedKeyPressControls(e)) {
      this.setState({ showNotifications: !showNotifications })

      if (!showNotifications) {
        document.addEventListener('click', this.handleClickOutsideNotificationsMenu)
      } else {
        document.removeEventListener('click', this.handleClickOutsideNotificationsMenu)
      }
    }
  }

  toggleSupport = (e) => {
    const { credentials, showSupportAction } = this.props
    const { showSupport } = this.state

    if (!showSupport) {
      showSupportAction(credentials)
    }

    if (allowedKeyPressControls(e)) {
      this.setState({ showSupport: !showSupport })

      if (!showSupport) {
        document.addEventListener('click', this.handleClickOutsideSupportMenu)
      } else {
        document.removeEventListener('click', this.handleClickOutsideSupportMenu)
      }
    }
  }

  closeModal = () => {
    const { closeModal } = this.props

    closeModal()
  }

  toggleContactSalesModal = () => {
    const { credentials, showContactSalesModal, currentUserEmail } = this.props

    showContactSalesModal({ credentials, currentUserEmail, closeModal: this.closeModal })
  }

  render() {
    const { showNotifications, showSupport } = this.state

    return (
      <MiniNav
        {...this.props}
        showNotifications={showNotifications}
        handleLogout={this.handleLogout}
        toggleNotificationsDialog={this.toggleNotifications}
        toggleSupportDropdown={this.toggleSupport}
        toggleContactSalesModal={this.toggleContactSalesModal}
        showSupport={showSupport}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavC)
