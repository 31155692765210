import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'

import {
  PASSED,
  FAILED,
  NOT_APPLICABLE,
} from 'constants/personaConstants'

import {
  SUCCEEDED,
  FAILED as FAILED_STATUS,
  CANCELED,
} from 'constants/statusConstants'

const PersonaCheckData = {
  [PASSED]: SUCCEEDED,
  [FAILED]: FAILED_STATUS,
  [NOT_APPLICABLE]: CANCELED,
}

const PlaidCheckColorcodedStatus = ({
  value = '',
  description = '',
}) => {
  return (
    <ColorcodedStatus
      data={PersonaCheckData}
      value={value}
      tooltip={description}
    />
  )
}

PlaidCheckColorcodedStatus.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
}

export default PlaidCheckColorcodedStatus
