import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  APPLICATIONS,
  APPLICATION_UNDERWRITING_SETTINGS,
} from 'constants/apiConstants'

const getApplicationUnderwritingSettings = ({ queries, values, credentials, meta }) => {
  const applicationId = get(values, 'application_id')

  return paymentsAPI.get({
    meta,
    credentials,
    path: APPLICATION_UNDERWRITING_SETTINGS({ applicationId }),
    queries,
    service: APPLICATIONS,
  })
}

export default getApplicationUnderwritingSettings
