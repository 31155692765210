import { PATCH_WELCOME_EMAIL_TEMPLATE_F_REQUEST } from 'constants/flowConstants'

const submitWelcomeEmailForm = (values, dispatch, props = {}) => {
  const { templateExist, welcomeEmail } = props

  dispatch({
    type: PATCH_WELCOME_EMAIL_TEMPLATE_F_REQUEST,
    payload: {
      values: {
        templateExist,
        welcomeEmail,
        ...values,
      },
    },
  })
}

export default submitWelcomeEmailForm
