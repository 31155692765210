import forOwn from 'lodash/forOwn'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import unset from 'lodash/unset'
import isArray from 'lodash/isArray'

function removeEmpty(obj, options = { removeEmptyObjects: true, removeEmptyArrays: true }) {
  const isRemovable = (value) => {
    if (isObject(value) && options.removeEmptyObjects && !isArray(value)) {
      return isEmpty(value)
    }
    if (isArray(value) && options.removeEmptyArrays) {
      return isEmpty(value)
    }
    return false
  }

  forOwn(obj, (value, key) => {
    if (isObject(value)) {
      removeEmpty(value, options)
      if (isRemovable(value)) {
        unset(obj, key)
      }
    }
  })
  return obj
}

export default removeEmpty
