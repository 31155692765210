export const finixApiJsonTheme = {
  main: 'line-height:1.3;color:#008681;background:#f6f8fa;overflow:auto;',
  key: 'color:#008681;',
  string: 'color:#183fb8;',
  boolean: 'color:#9c2e48;',
  value: 'color:#bcaf2c;',
}

export const resourceTableTheme = 'resource-table'
export const infoTableTheme = 'info-table'
// TODO: universally update all resoure table themes to be resourceTableThemeV2 during active recovery
export const resourceTableThemeV2 = 'resource-table-v2'
