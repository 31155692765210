import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import removeUndefined from 'utilities/remove/removeUndefined'
import { displayFlaggedGateways } from 'constants/processorConstants'

import {
  ENABLED,
  DISABLED,
} from 'constants/language/languageConstants'

const frontendProcessorModel = ({ data: processor }) => {
  const [
    id,
    createdAt,
    processorName,
    enabled,
    defaultMerchantProfile,
    applicationId,
  ] = getMany(processor, [
    'id',
    'created_at',
    'processor',
    'enabled',
    'default_merchant_profile',
    'application',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    processor: processorName,
    enabled,
    enabledStatus: enabled ? ENABLED : DISABLED,
    defaultMerchantProfile,
    gateways: displayFlaggedGateways({ processor: processorName }),
    applicationId,
  })
}

export default frontendProcessorModel
