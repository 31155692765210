import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_INSTRUMENTS } from 'constants/apiConstants'

const postIdentityPaymentInstrumentAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: PAYMENT_INSTRUMENTS,
    service: PAYMENT_INSTRUMENTS,
  })
}

export default postIdentityPaymentInstrumentAPI
