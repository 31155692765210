import getMany from 'utilities/get/getMany'
import { fieldEmpty } from 'utilities/validate'
import gt from 'lodash/gt'
import isNumber from 'lodash/isNumber'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'

const validateCardPaymentsForm = (values, props) => {
  const blendedUsdSelected = get(values, 'blended.USD.open')
  const interchangeUsdSelected = get(values, 'interchange-plus.USD.open')
  const noUsdRulesetSelected = !blendedUsdSelected && !interchangeUsdSelected

  const validations = reduce(values, (total, feeProfileValues, key) => {
    const normalizedValues = reduce(feeProfileValues, (countryTotal, countryValues, countryKey) => {
      const [
        basisPointsMin,
        basisPointsMax,
        fixedFeeMin,
        fixedFeeMax,
        cardCrossBorderBasisPointsMin,
        cardCrossBorderBasisPointsMax,
        cardCrossBorderFixedFeeMin,
        cardCrossBorderFixedFeeMax,
        visaBasisPointsMin,
        visaBasisPointsMax,
        visaFixedFeeMin,
        visaFixedFeeMax,
        mastercardBasisPointsMin,
        mastercardBasisPointsMax,
        mastercardFixedFeeMin,
        mastercardFixedFeeMax,
        americanExpressBasisPointsMin,
        americanExpressBasisPointsMax,
        americanExpressFixedFeeMin,
        americanExpressFixedFeeMax,
        discoverBasisPointsMin,
        discoverBasisPointsMax,
        discoverFixedFeeMin,
        discoverFixedFeeMax,
        jcbBasisPointsMin,
        jcbBasisPointsMax,
        jcbFixedFeeMin,
        jcbFixedFeeMax,
        dinersClubBasisPointsMin,
        dinersClubBasisPointsMax,
        dinersClubFixedFeeMin,
        dinersClubFixedFeeMax,
        disputeFixedFeeMin,
        disputeFixedFeeMax,
        disputeInquiryFixedFeeMin,
        disputeInquiryFixedFeeMax,
        visaAssessmentsBasisPointsMin,
        visaAssessmentsBasisPointsMax,
        visaAcquirerProcessingFixedFeeMin,
        visaAcquirerProcessingFixedFeeMax,
        visaCreditVoucherFixedFeeMin,
        visaCreditVoucherFixedFeeMax,
        visaBaseIISystemFileTransmissionFixedFeeMin,
        visaBaseIISystemFileTransmissionFixedFeeMax,
        visaKilobyteAccessFixedFeeMin,
        visaKilobyteAccessFixedFeeMax,
        mastercardAssessmentsUnder1kBasisPointsMin,
        mastercardAssessmentsUnder1kBasisPointsMax,
        mastercardAssessmentsOver1kBasisPointsMin,
        mastercardAssessmentsOver1kBasisPointsMax,
        mastercardAcquirerFeesBasisPointsMin,
        mastercardAcquirerFeesBasisPointsMax,
        americanExpressAssessmentBasisPointsMin,
        americanExpressAssessmentBasisPointsMax,
        discoverAssessmentsBasisPointsMin,
        discoverAssessmentsBasisPointsMax,
        discoverDataUsageFixedFeeMin,
        discoverDataUsageFixedFeeMax,
        discoverNetworkAuthorizationFixedFeeMin,
        discoverNetworkAuthorizationFixedFeeMax,
        open,
      ] = getMany(countryValues, [
        'basisPointsMin',
        'basisPointsMax',
        'fixedFeeMin',
        'fixedFeeMax',
        'cardCrossBorderBasisPointsMin',
        'cardCrossBorderBasisPointsMax',
        'cardCrossBorderFixedFeeMin',
        'cardCrossBorderFixedFeeMax',
        'visaBasisPointsMin',
        'visaBasisPointsMax',
        'visaFixedFeeMin',
        'visaFixedFeeMax',
        'mastercardBasisPointsMin',
        'mastercardBasisPointsMax',
        'mastercardFixedFeeMin',
        'mastercardFixedFeeMax',
        'americanExpressBasisPointsMin',
        'americanExpressBasisPointsMax',
        'americanExpressFixedFeeMin',
        'americanExpressFixedFeeMax',
        'discoverBasisPointsMin',
        'discoverBasisPointsMax',
        'discoverFixedFeeMin',
        'discoverFixedFeeMax',
        'jcbBasisPointsMin',
        'jcbBasisPointsMax',
        'jcbFixedFeeMin',
        'jcbFixedFeeMax',
        'dinersClubBasisPointsMin',
        'dinersClubBasisPointsMax',
        'dinersClubFixedFeeMin',
        'dinersClubFixedFeeMax',
        'disputeFixedFeeMin',
        'disputeFixedFeeMax',
        'disputeInquiryFixedFeeMin',
        'disputeInquiryFixedFeeMax',
        'visaAssessmentsBasisPointsMin',
        'visaAssessmentsBasisPointsMax',
        'visaAcquirerProcessingFixedFeeMin',
        'visaAcquirerProcessingFixedFeeMax',
        'visaCreditVoucherFixedFeeMin',
        'visaCreditVoucherFixedFeeMax',
        'visaBaseIISystemFileTransmissionFixedFeeMin',
        'visaBaseIISystemFileTransmissionFixedFeeMax',
        'visaKilobyteAccessFixedFeeMin',
        'visaKilobyteAccessFixedFeeMax',
        'mastercardAssessmentsUnder1kBasisPointsMin',
        'mastercardAssessmentsUnder1kBasisPointsMax',
        'mastercardAssessmentsOver1kBasisPointsMin',
        'mastercardAssessmentsOver1kBasisPointsMax',
        'mastercardAcquirerFeesBasisPointsMin',
        'mastercardAcquirerFeesBasisPointsMax',
        'americanExpressAssessmentBasisPointsMin',
        'americanExpressAssessmentBasisPointsMax',
        'discoverAssessmentsBasisPointsMin',
        'discoverAssessmentsBasisPointsMax',
        'discoverDataUsageFixedFeeMin',
        'discoverDataUsageFixedFeeMax',
        'discoverNetworkAuthorizationFixedFeeMin',
        'discoverNetworkAuthorizationFixedFeeMax',
        'open',
      ])

      return merge({}, countryTotal, { [countryKey]: open ? {
        basisPointsMin: isNumber(basisPointsMin) || !!basisPointsMin ? false : 'Cannot be empty',
        basisPointsMax: gt(parseFloat(basisPointsMin), parseFloat(basisPointsMax)) ? 'Max should be larger than Min' : false || (isNumber(basisPointsMax) || !!basisPointsMax ? false : 'Cannot be empty'),
        fixedFeeMin: fieldEmpty(fixedFeeMin, 'Fixed Fee Min', 'Cannot be empty'),
        fixedFeeMax: gt(parseFloat(fixedFeeMin), parseFloat(fixedFeeMax)) ? 'Max should be larger than Min' : false || (isNumber(fixedFeeMax) || !!fixedFeeMax ? false : 'Cannot be empty'),
        cardCrossBorderBasisPointsMin: false,
        cardCrossBorderBasisPointsMax: gt(parseFloat(cardCrossBorderBasisPointsMin), parseFloat(cardCrossBorderBasisPointsMax)) ? 'Max should be larger than Min' : false,
        cardCrossBorderFixedFeeMin: false,
        cardCrossBorderFixedFeeMax: gt(parseFloat(cardCrossBorderFixedFeeMin), parseFloat(cardCrossBorderFixedFeeMax)) ? 'Max should be larger than Min' : false,
        visaBasisPointsMin: false,
        visaBasisPointsMax: gt(parseFloat(visaBasisPointsMin), parseFloat(visaBasisPointsMax)) ? 'Max should be larger than Min' : false,
        visaFixedFeeMin: false,
        visaFixedFeeMax: gt(parseFloat(visaFixedFeeMin), parseFloat(visaFixedFeeMax)) ? 'Max should be larger than Min' : false,
        mastercardBasisPointsMin: false,
        mastercardBasisPointsMax: gt(parseFloat(mastercardBasisPointsMin), parseFloat(mastercardBasisPointsMax)) ? 'Max should be larger than Min' : false,
        mastercardFixedFeeMin: false,
        mastercardFixedFeeMax: gt(parseFloat(mastercardFixedFeeMin), parseFloat(mastercardFixedFeeMax)) ? 'Max should be larger than Min' : false,
        americanExpressBasisPointsMin: false,
        americanExpressBasisPointsMax: gt(parseFloat(americanExpressBasisPointsMin), parseFloat(americanExpressBasisPointsMax)) ? 'Max should be larger than Min' : false,
        americanExpressFixedFeeMin: false,
        americanExpressFixedFeeMax: gt(parseFloat(americanExpressFixedFeeMin), parseFloat(americanExpressFixedFeeMax)) ? 'Max should be larger than Min' : false,
        discoverBasisPointsMin: false,
        discoverBasisPointsMax: gt(parseFloat(discoverBasisPointsMin), parseFloat(discoverBasisPointsMax)) ? 'Max should be larger than Min' : false,
        discoverFixedFeeMin: false,
        discoverFixedFeeMax: gt(parseFloat(discoverFixedFeeMin), parseFloat(discoverFixedFeeMax)) ? 'Max should be larger than Min' : false,
        jcbBasisPointsMin: false,
        jcbBasisPointsMax: gt(parseFloat(jcbBasisPointsMin), parseFloat(jcbBasisPointsMax)) ? 'Max should be larger than Min' : false,
        jcbFixedFeeMin: false,
        jcbFixedFeeMax: gt(parseFloat(jcbFixedFeeMin), parseFloat(jcbFixedFeeMax)) ? 'Max should be larger than Min' : false,
        dinersClubBasisPointsMin: false,
        dinersClubBasisPointsMax: gt(parseFloat(dinersClubBasisPointsMin), parseFloat(dinersClubBasisPointsMax)) ? 'Max should be larger than Min' : false,
        dinersClubFixedFeeMin: false,
        dinersClubFixedFeeMax: gt(parseFloat(dinersClubFixedFeeMin), parseFloat(dinersClubFixedFeeMax)) ? 'Max should be larger than Min' : false,
        disputeFixedFeeMin: false,
        disputeFixedFeeMax: gt(parseFloat(disputeFixedFeeMin), parseFloat(disputeFixedFeeMax)) ? 'Max should be larger than Min' : false,
        disputeInquiryFixedFeeMin: false,
        disputeInquiryFixedFeeMax: gt(parseFloat(disputeInquiryFixedFeeMin), parseFloat(disputeInquiryFixedFeeMax)) ? 'Max should be larger than Min' : false,
        visaAssessmentsBasisPointsMin: false,
        visaAssessmentsBasisPointsMax: gt(parseFloat(visaAssessmentsBasisPointsMin), parseFloat(visaAssessmentsBasisPointsMax)) ? 'Max should be larger than Min' : false,
        visaAcquirerProcessingFixedFeeMin: false,
        visaAcquirerProcessingFixedFeeMax: gt(parseFloat(visaAcquirerProcessingFixedFeeMin), parseFloat(visaAcquirerProcessingFixedFeeMax)) ? 'Max should be larger than Min' : false,
        visaCreditVoucherFixedFeeMin: false,
        visaCreditVoucherFixedFeeMax: gt(parseFloat(visaCreditVoucherFixedFeeMin), parseFloat(visaCreditVoucherFixedFeeMax)) ? 'Max should be larger than Min' : false,
        visaBaseIISystemFileTransmissionFixedFeeMin: false,
        visaBaseIISystemFileTransmissionFixedFeeMax: gt(parseFloat(visaBaseIISystemFileTransmissionFixedFeeMin), parseFloat(visaBaseIISystemFileTransmissionFixedFeeMax)) ? 'Max should be larger than Min' : false,
        visaKilobyteAccessFixedFeeMin: false,
        visaKilobyteAccessFixedFeeMax: gt(parseFloat(visaKilobyteAccessFixedFeeMin), parseFloat(visaKilobyteAccessFixedFeeMax)) ? 'Max should be larger than Min' : false,
        mastercardAssessmentsUnder1kBasisPointsMin: false,
        mastercardAssessmentsUnder1kBasisPointsMax: gt(parseFloat(mastercardAssessmentsUnder1kBasisPointsMin), parseFloat(mastercardAssessmentsUnder1kBasisPointsMax)) ? 'Max should be larger than Min' : false,
        mastercardAssessmentsOver1kBasisPointsMin: false,
        mastercardAssessmentsOver1kBasisPointsMax: gt(parseFloat(mastercardAssessmentsOver1kBasisPointsMin), parseFloat(mastercardAssessmentsOver1kBasisPointsMax)) ? 'Max should be larger than Min' : false,
        mastercardAcquirerFeesBasisPointsMin: false,
        mastercardAcquirerFeesBasisPointsMax: gt(parseFloat(mastercardAcquirerFeesBasisPointsMin), parseFloat(mastercardAcquirerFeesBasisPointsMax)) ? 'Max should be larger than Min' : false,
        americanExpressAssessmentBasisPointsMin: false,
        americanExpressAssessmentBasisPointsMax: gt(parseFloat(americanExpressAssessmentBasisPointsMin), parseFloat(americanExpressAssessmentBasisPointsMax)) ? 'Max should be larger than Min' : false,
        discoverAssessmentsBasisPointsMin: false,
        discoverAssessmentsBasisPointsMax: gt(parseFloat(discoverAssessmentsBasisPointsMin), parseFloat(discoverAssessmentsBasisPointsMax)) ? 'Max should be larger than Min' : false,
        discoverDataUsageFixedFeeMin: false,
        discoverDataUsageFixedFeeMax: gt(parseFloat(discoverDataUsageFixedFeeMin), parseFloat(discoverDataUsageFixedFeeMax)) ? 'Max should be larger than Min' : false,
        discoverNetworkAuthorizationFixedFeeMin: false,
        discoverNetworkAuthorizationFixedFeeMax: gt(parseFloat(discoverNetworkAuthorizationFixedFeeMin), parseFloat(discoverNetworkAuthorizationFixedFeeMax)) ? 'Max should be larger than Min' : false,
      } : {},
      })
    }, {})

    return merge({}, total, { [key]: normalizedValues })
  }, {})

  return { ...validations, noUsdRulesetSelected }
}

export default validateCardPaymentsForm
