import React from 'react'
import PropTypes from 'prop-types'
import { getReceiptDeliveryAttemptByReceiptId } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { receiptDeliveryAttemptsColumnDescriptors } from 'components/Customer/Pages/Payment/columnDescriptors'
import { GET_RECEIPT_DELIVERY_ATTEMPTS } from 'constants/flowConstants'
import { infoTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  NO_RECEIPT_DELIVERY_ATTEMPTS,
  RECEIPT_HISTORY,
  RECEIPTS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const receiptTitle = (receipt) => <div className='receipt-title flex items-center'><h5>Receipt</h5> <ClickToCopyC position='right' content={get(receipt, 'id')} /></div>
const receiptSubtitle = (receipt) => `Created on ${get(receipt, 'displayCreatedAt')}`

const TransferReceiptHistory = ({
  receipts = [],
  transfer = {},
  receiptsActions = [],
  receiptActions = () => {},
  isFetching = false,
}) => {
  return (
    <PageSectionV2C
      className='TransferReceiptHistory'
      title={RECEIPT_HISTORY}
      actions={receiptsActions}
      emptyMessage={isEmpty(receipts) ? RECEIPTS_EMPTY_MESSAGE : undefined}
    >
      {map(receipts, (receipt) => {
        const receiptId = get(receipt, 'id')

        return (
          <TableC
            title={receiptTitle(receipt)}
            subtitle={receiptSubtitle(receipt)}
            actions={receiptActions({ receipt, transfer })}
            theme={infoTableTheme}
            emptyMessageString={NO_RECEIPT_DELIVERY_ATTEMPTS}
            flow={GET_RECEIPT_DELIVERY_ATTEMPTS}
            flowValues={{ id: receiptId }}
            columnDescriptors={receiptDeliveryAttemptsColumnDescriptors}
            selector={getReceiptDeliveryAttemptByReceiptId}
            selectorId={receiptId}
            classNames='receipt-delivery-attempts-table'
            isFetching={isFetching}
            concurrent
            showTableFooter={false}
          />
        )
      })}
    </PageSectionV2C>
  )
}

TransferReceiptHistory.propTypes = {
  receipts: PropTypes.array,
  transfer: PropTypes.object,
  receiptsActions: PropTypes.array,
  receiptActions: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default TransferReceiptHistory
