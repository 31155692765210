import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_V2_EMAILS } from 'constants/apiConstants'

const postDashboardV2EmailsAPI = ({ values, meta }) => {
  return dashboardAPI.post({
    meta,
    values,
    path: DASHBOARD_SERVICE_V2_EMAILS,
  })
}

export default postDashboardV2EmailsAPI
