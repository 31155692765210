import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_EXPORTS_DOWNLOAD } from 'constants/apiConstants'

const getExportDownloadAPI = ({ credentials, id }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_SERVICE_EXPORTS_DOWNLOAD({ id }),
  })
}

export default getExportDownloadAPI
