import camelCaseToTitleCase from 'utilities/display/camelCaseToTitleCase'
import removeUndefined from 'utilities/remove/removeUndefined'
import map from 'lodash/map'
import every from 'lodash/every'
import size from 'lodash/size'
import filter from 'lodash/filter'
import some from 'lodash/some'
import values from 'lodash/values'
import get from 'lodash/get'

import {
  WEBSITE_REGEX,
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidRegex,
  nonEmptyNumber,
  nonEmptyInteger,
} from 'utilities/validate'

import {
  INVALID_EMAIL,
  INVALID_EMAIL_LIST,
  INVALID_PHONE_NUMBERS_LIST,
  INVALID_PHONE_NUMBER,
} from 'constants/language/languageConstants'

const validateEmailList = (emails) => {
  if (!Array.isArray(emails) || size(emails) === 0) {
    return INVALID_EMAIL_LIST
  }

  const allValid = every(emails, ({ value: email }) => email && EMAIL_REGEX.test(email))
  if (!allValid) {
    return INVALID_EMAIL
  }

  return false
}

const validatePhoneNumbers = (phoneNumbers) => {
  if (!phoneNumbers || size(phoneNumbers) === 0) {
    return INVALID_PHONE_NUMBERS_LIST
  }
  const invalidPhones = filter(phoneNumbers, phone => !PHONE_NUMBER_REGEX.test(get(phone, 'value')))
  if (size(invalidPhones) > 0) {
    return INVALID_PHONE_NUMBER
  }

  return false
}

const validateCreateReceiptForm = (formValues, props) => {
  const {
    items,
    subtotal,
    discount,
    shipping,
    tax,
    tip,
    surcharge,
    convenienceFee,
    rentSurcharge,
    sendReceiptToBuyer,
    sendMethodEmail,
    sendMethodSMS,
    sendMethodPrint,
    buyerEmails,
    phoneNumbers,
    device,
    subtotalAmount,
    discountAmount,
    shippingAmount,
    taxAmount,
    tipAmount,
    surchargeAmount,
    convenienceFeeAmount,
    rentSurchargeAmount,
  } = formValues

  const {
    amount,
    displayAmount,
  } = props

  const itemsValidation = map(items, (item) => {
    const {
      name,
      saleAmount,
      primaryImageUrl,
      quantity,
    } = item

    return {
      name: fieldEmpty(name, 'Name'),
      quantity: quantity ? (nonEmptyNumber({ field: quantity, name: 'Quantity' }) || nonEmptyInteger({ field: quantity, name: 'Quantity' })) : false,
      saleAmount: nonEmptyNumber({ field: saleAmount, name: 'Price' }),
      primaryImageUrl: primaryImageUrl ? invalidRegex({ field: primaryImageUrl, name: 'URL', regex: WEBSITE_REGEX }) : false,
    }
  })

  const amountFields = [
    { name: 'subtotalAmount', checked: subtotal },
    { name: 'discountAmount', checked: discount },
    { name: 'shippingAmount', checked: shipping },
    { name: 'taxAmount', checked: tax },
    { name: 'tipAmount', checked: tip },
    { name: 'surchargeAmount', checked: surcharge },
    { name: 'convenienceFeeAmount', checked: convenienceFee },
    { name: 'rentSurchargeAmount', checked: rentSurcharge },
  ]

  const amountErrors = {}
  amountFields.forEach(({ name, checked }) => {
    if (checked) {
      const error = nonEmptyNumber({ field: formValues[name], name: camelCaseToTitleCase(name) })
      if (error) amountErrors[name] = error
    }
  })

  const anyAmountFieldChecked = subtotal || discount || shipping || tax || tip || surcharge || convenienceFee || rentSurcharge

  let amountMismatch = false
  if (anyAmountFieldChecked) {
    const calculatedTotal = (
      (subtotal ? parseFloat(subtotalAmount) || 0 : 0) +
      (tax ? parseFloat(taxAmount) || 0 : 0) +
      (shipping ? parseFloat(shippingAmount) || 0 : 0) +
      (tip ? parseFloat(tipAmount) || 0 : 0) +
      (surcharge ? parseFloat(surchargeAmount) || 0 : 0) +
      (convenienceFee ? parseFloat(convenienceFeeAmount) || 0 : 0) +
      (rentSurcharge ? parseFloat(rentSurchargeAmount) || 0 : 0) -
      (discount ? parseFloat(discountAmount) || 0 : 0)
    ).toFixed(2)

    amountMismatch = parseFloat(calculatedTotal) !== parseFloat(amount)
  }

  return removeUndefined({
    items: some(itemsValidation, item => some(values(item))) ? itemsValidation : undefined,
    ...amountErrors,
    _error: amountMismatch
      ? `Total amount breakdown should add up to ${displayAmount}.`
      : undefined,
    buyerEmails: sendMethodEmail ? validateEmailList(buyerEmails) : undefined,
    phoneNumbers: sendMethodSMS ? validatePhoneNumbers(phoneNumbers) : undefined,
    device: (sendMethodPrint && !device) ? 'Please select a device' : undefined,
  })
}

export default validateCreateReceiptForm
