import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  DISABLE,
  CANCEL,
} from 'constants/language/languageConstants'

const EditFeeProfileSettings = ({
  closeModalFunc = () => {},
  disableAction = () => {},
  modalText = '',
  buttonText = '',
  buttonVariant = '',
}) => {
  return (
    <div className='EditFeeProfileSettings'>
      <div className='modal-content confirmation-msg'>
        {modalText}
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModalFunc} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button label={buttonText} variant={buttonVariant} onClick={disableAction} />
      </div>
    </div>
  )
}

EditFeeProfileSettings.propTypes = {
  closeModalFunc: PropTypes.func,
  disableAction: PropTypes.func,
  modalText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
}

export default EditFeeProfileSettings
