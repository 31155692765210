import ShowNotes from './ShowNotes'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getNotesRequest from 'utilities/actions/get/getNotesRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getPublishedNotes } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const linkedTo = get(props, 'linkedTo')
  const isFetching = get(state, `notesR.${FETCHING}`, true)
  const credentials = getCurrentCredentials(state)
  const notes = getPublishedNotes(state, linkedTo)

  return {
    notes,
    isFetching,
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotes: ({ credentials, queries }) => dispatch(getNotesRequest({ credentials, queries })),
  }
}

class ShowNotesC extends Component {
  state = {
    initialFetch: true,
  }

  componentDidMount() {
    const {
      getNotes,
      linkedTo,
      credentials,
    } = this.props

    getNotes({
      credentials,
      queries: {
        linked_to: linkedTo,
      },
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialFetch } = this.state
    const { isFetching } = this.props
    const { isFetching: prevIsFetching } = prevProps

    if (initialFetch && prevIsFetching === true && isFetching === false) {
      this.setState({ initialFetch: false })
    }
  }

  render() {
    const { initialFetch } = this.state

    return (
      <ShowNotes
        {...this.props}
        initialFetch={initialFetch}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowNotesC)
