import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  SETTLEMENT_GROUP,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getSettlementGroupAPI = ({ id, queries, credentials, meta, values }) => {
  const settlementGroupId = id || get(values, 'settlementGroupId')

  return settlementGroupId && paymentsAPI.get({
    meta,
    path: SETTLEMENT_GROUP({ settlementGroupId }),
    queries,
    credentials,
    service: SETTLEMENTS,
  })
}

export default getSettlementGroupAPI
