import React from 'react'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DisbursementsAccountColumn from 'components/Customer/Shared/Display/Columns/DisbursementsAccountColumn/DisbursementsAccountColumn'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import CapturedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/CapturedStatus'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DisputeRespondWithinStatus from 'components/Customer/Shared/Display/ColorcodedStatus/DisputeRespondWithinStatus'
import column from 'utilities/table/column'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import { disputeStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'

import {
  ALL,
  CANCELED,
  FAILED,
  REVERSAL,
} from 'constants/transferConstants'

import {
  ACH,
  AMOUNT,
  APPLICATION,
  BANK_ACCOUNT,
  BRAND_SLASH_TYPE,
  BUYER,
  CAPTURED_STATUS,
  CARD,
  CARD_BRAND,
  CARD_BRAND_TYPE_SUBTITLE,
  CARD_TYPE,
  CREATED_ON,
  DESCRIPTION,
  EMAIL,
  FEES,
  FEES_AMOUNT,
  ID,
  INSTRUMENT_TYPE,
  ISSUER_COUNTRY,
  MASKED_ACCOUNT_NUMBER,
  MASKED_NUMBER,
  MERCHANT_ACCOUNT,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  METHOD,
  NET_AMOUNT,
  PAYMENT_INSTRUMENT,
  PAYMENT_TYPE,
  PROCESSOR,
  REASON,
  REASON_CODE,
  RECIPIENT_NAME,
  RESPOND_WITHIN,
  SPLIT_TRANSACTION,
  STATE,
  TRANSACTIONS,
  TRANSACTION_MONITORING_AVERAGE_PAYOUT_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT,
  TRANSACTION_MONITORING_DAYS_SINCE_PRIOR_SETTLEMENT,
  TRANSACTION_MONITORING_LOOKBACK_DATE,
  TRANSACTION_MONITORING_PAYOUT_COUNT,
  TRANSACTION_MONITORING_SETTLEMENT_AMOUNT,
  TRANSACTION_MONITORING_SIZE_DIFFERENCE,
  TRANSACTION_MONITORING_TOTAL_PAYOUT_AMOUNT,
  TRANSACTION_MONITORING_TRANSFER_COUNT,
  UPDATED_ON,
} from 'constants/language/languageConstants'

export const payoutColumnDescriptors = [
  column(TRANSACTION_MONITORING_LOOKBACK_DATE, 'createdAt', {
    className: 'col-25',
    headerFormatter: () => <DateTimestampHeader title={TRANSACTION_MONITORING_LOOKBACK_DATE} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TRANSACTION_MONITORING_TOTAL_PAYOUT_AMOUNT, 'sum', {
    className: 'col-25',
  }),
  column(TRANSACTION_MONITORING_AVERAGE_PAYOUT_AMOUNT, 'average', {
    className: 'col-25',
  }),
  column(TRANSACTION_MONITORING_PAYOUT_COUNT, 'count', {
    className: 'col-25',
  }),
]

export const dormantSettlementColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'col-16',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TRANSACTION_MONITORING_TRANSFER_COUNT, 'count', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_SETTLEMENT_AMOUNT, 'sum', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT, 'average', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_DAYS_SINCE_PRIOR_SETTLEMENT, 'days', {
    className: 'col-16',
  }),
]

export const settlementSizeDifferenceColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'col-16',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TRANSACTION_MONITORING_TRANSFER_COUNT, 'count', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_SETTLEMENT_AMOUNT, 'sum', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT, 'average', {
    className: 'col-16',
  }),
  column(TRANSACTION_MONITORING_SIZE_DIFFERENCE, 'percent', {
    className: 'col-16',
  }),
]

export const highTicketColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'col-25',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(AMOUNT, 'sum', {
    className: 'col-25',
  }),
  column(TRANSACTION_MONITORING_SIZE_DIFFERENCE, 'percent', {
    className: 'col-25',
  }),
]

export const relatedPayoutsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(RECIPIENT_NAME, 'merchantIdentity.fullName', {
    className: 'medium',
    formatter: (review) => {
      return get(review, 'merchantIdentity.businessName') || get(review, 'merchantIdentity.fullName')
    },
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === CANCELED}
        subtitleIndent
      />
    ),
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.maskedFullCardNumber', {
    headerFormatter: () => <TableItemSubtitle title={MASKED_NUMBER} subtitle={CARD_BRAND_TYPE_SUBTITLE} />,
    className: 'medium',
    formatter: ({ paymentInstrument }) => <DisbursementsAccountColumn paymentInstrument={paymentInstrument} />,
  }),
  column(METHOD, 'paymentInstrument.type', {
    className: 'medium',
    formatter: ({ paymentInstrument }) => <div>{isEqual(get(paymentInstrument, 'type'), BANK_ACCOUNT) ? ACH : CARD}</div>,
  }),
]

export const relatedPaymentsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
  }),
  column(BUYER, 'paymentInstrument.name', {
    className: 'normal',
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    headerFormatter: () => <TableItemSubtitle title={AMOUNT} subtitle={PAYMENT_TYPE} />,
    formatter: ({ displayAmount, currency, splitTransferIds = [], type }) => (
      <TableItemSubtitle
        titleComponent={() => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />}
        subtitle={(!isEmpty(splitTransferIds) && !isEqual(type, REVERSAL)) ? SPLIT_TRANSACTION : ' '}
      />
    ),
  }),
  column(STATE, 'state', {
    className: 'normal',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === CANCELED}
        subtitleIndent
      />
    ),
  }),
  column(CARD_BRAND, 'paymentInstrument.brand', {
    headerFormatter: () => <TableItemSubtitle title={CARD_BRAND} subtitle={CARD_TYPE} />,
    className: 'normal',
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.type', {
    className: 'normal',
  }),
]

export const relatedInternationalTransfersColumnDescriptors = concat(relatedPaymentsColumnDescriptors,
  column(ISSUER_COUNTRY, 'paymentInstrument.displayIssuerCountry', {
    className: 'normal',
  }))

export const relatedAchReturnsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
  }),
  column(BUYER, 'paymentInstrument.name', {
    className: 'large',
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(MASKED_ACCOUNT_NUMBER, 'paymentInstrument.maskedAccountNumber', {
    className: 'large',
  }),
  column('', '', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={REASON_CODE} subtitle={DESCRIPTION} />,
    formatter: (transfer) => {
      const reasonCode = get(transfer, 'tags.result_message_0', '').split(':')[1]
      const reasonDescription = get(transfer, 'tags.result_message_1', '').split(':')[1]

      return <TableItemSubtitle title={reasonCode} subtitle={reasonDescription} />
    },
  }),
]

export const relatedAuthorizationsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (authorization) => <TransferMerchantApplicationColumn transfer={authorization} />,
  }),
  column(BUYER, 'buyerIdentity.buyerName', {
    className: 'name',
    headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={EMAIL} />,
    formatter: ({ buyerIdentity }) => <TableItemSubtitle title={get(buyerIdentity, 'buyerName')} subtitle={get(buyerIdentity, 'email')} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleIndent
        emptySignifierCheck={state === FAILED || state === CANCELED}
      />
    ),
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return (
        <TableItemSubtitle
          title={name}
          subtitle={maskedAccountNumber || maskedFullCardNumber}
        />
      )
    },
    className: 'normal',
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
  column(CAPTURED_STATUS, 'displayCaptured', {
    className: 'state',
    formatter: ({ displayCaptured }) => <CapturedStatus value={displayCaptured} />,
  }),
]

export const relatedSettlementsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'identity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (settlement) => <TransferMerchantApplicationColumn transfer={settlement} />,
  }),
  column(TRANSACTIONS, 'transferCount', {
    className: 'small',
    formatter: ({ transferCount }) => (transferCount !== undefined ? formatNumber(transferCount) : '-'),
  }),
  column(FEES, 'feeCount', {
    className: 'small',
    formatter: ({ feeCount }) => (feeCount !== undefined ? formatNumber(feeCount) : '-'),
  }),
  column(AMOUNT, ['totalAmount', 'currency'], {
    className: 'amount',
    formatter: ({ totalAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />,
  }),
  column(FEES_AMOUNT, ['totalFees', 'currency'], {
    className: 'amount',
    formatter: ({ totalFees, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalFees, currency })} currency={currency} />,
  }),
  column(NET_AMOUNT, ['netAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ netAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: netAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />,
  }),
  column(PROCESSOR, 'processor', {
    className: 'small',
  }),
  column(STATE, 'status', {
    className: 'status',
    formatter: ({ status }) => <SettlementStatus value={status} />,
  }),
]


export const relatedDisputesColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(UPDATED_ON, 'displayUpdatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
  column(RESPOND_WITHIN, 'displayRespondWithin', {
    formatter: ({ respondWithin, displayRespondWithin, displayRespondBy }) => <DisputeRespondWithinStatus inTable value={respondWithin} displayValue={displayRespondWithin} hoverOverText={displayRespondBy} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
  }),
  column(BUYER, 'buyerIdentity.buyerName', {
    className: 'name',
    headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={EMAIL} />,
    formatter: ({ buyerIdentity }) => <TableItemSubtitle title={get(buyerIdentity, 'buyerName')} subtitle={get(buyerIdentity, 'email')} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, transfer }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={get(transfer, 'currency')} />,
  }),
  column(STATE, 'state', {
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON} />,
    className: 'state',
    formatter: ({ state, displayReason }) => (
      <TableItemSubtitle
        titleComponent={() => <ColorcodedStatus value={state} data={disputeStatusMap} />}
        subtitle={displayReason}
        subtitleIndent
      />
    ),
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return (
        <TableItemSubtitle
          title={name}
          subtitle={maskedAccountNumber || maskedFullCardNumber}
        />
      )
    },
    className: 'normal',
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
]
