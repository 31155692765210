import React from 'react'
import PropTypes from 'prop-types'
import { getRolesSelector } from 'state-layer/selectors'
import Loader from 'components/Shared/Loader/Loader'
import TableC from 'components/Shared/Table/TableC'
import { GET_ROLES } from 'constants/flowConstants'
import { ROLES } from 'constants/language/languageConstants'

const Roles = ({
  initialQueries = {},
  isFetching = false,
  actions = [],
  allowedFilters = [],
  entityDataFound = false,
  rolePath = '',
  quickFilters,
  columnDescriptors = [],
}) => {
  return (
    <div className='Roles'>
      { !entityDataFound && <Loader />}
      { entityDataFound && (
        <TableC
          flow={GET_ROLES}
          initialQueries={initialQueries}
          columnDescriptors={columnDescriptors}
          selector={getRolesSelector}
          linksKey={ROLES}
          allowedFilters={allowedFilters}
          quickFilters={quickFilters}
          path={rolePath}
          isFetching={isFetching}
          actions={actions}
          fileNameCSV='Team_Roles'
        />
      )}
    </div>
  )
}

Roles.propTypes = {
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  entityDataFound: PropTypes.bool,
  rolePath: PropTypes.string,
  columnDescriptors: PropTypes.array,
}

export default Roles
