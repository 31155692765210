import paymentsAPI from 'utilities/api/paymentsAPI'
import { RECEIPT_DELIVERY_ATTEMPTS } from 'constants/apiConstants'

const postReceiptDeliveryAttemptsAPI = ({ values, credentials, meta, id }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: RECEIPT_DELIVERY_ATTEMPTS({ receiptId: id }),
  })
}

export default postReceiptDeliveryAttemptsAPI
