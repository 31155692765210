import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATCH_DEVICE_F_REQUEST } from 'constants/flowConstants'

const patchDeviceRequest = ({
  credentials,
  deviceId,
  values,
  amplitudeActionType,
  successMessage,
}) => createAction({
  type: PATCH_DEVICE_F_REQUEST,
  id: deviceId,
  values,
  credentials,
  meta: removeUndefined({
    actionId: sendAmplitudeActionEvent(amplitudeActionType, removeUndefined({
      credentials,
      values,
      deviceId,
    })),
    successMessage,
  }),
})


export default patchDeviceRequest
