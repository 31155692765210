import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import { getUnderwritingReviewsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import { UNDERWRITING_REVIEWS } from 'constants/linkConstants'
import { GET_UNDERWRITING_REVIEWS } from 'constants/flowConstants'

import {
  UNDERWRITING_APPLICATIONS_RESOURCE_TITLE,
  UNDERWRITING_APPLICATIONS_TABLE_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const UnderwritingApplications = ({
  underwritingReviewPath = '',
  allowedFilters = [],
  initialQueries = {},
  isFetching = false,
  selector = getUnderwritingReviewsSelector,
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='UnderwritingApplications'>
      <TableC
        flow={GET_UNDERWRITING_REVIEWS}
        columnDescriptors={columnDescriptors}
        selector={selector}
        linksKey={UNDERWRITING_REVIEWS}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        path={underwritingReviewPath}
        fileNameCSV='Underwriting_Applications'
        fileResourceTitleCSV={UNDERWRITING_APPLICATIONS_RESOURCE_TITLE}
        emptyMessage={UNDERWRITING_APPLICATIONS_TABLE_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

UnderwritingApplications.propTypes = {
  underwritingReviewPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  selector: PropTypes.func,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default UnderwritingApplications
