import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { PAYMENT_INSTRUMENT_RESOURCE_TITLE } from 'constants/language/languageConstants'

const BuyerDetails = ({
  title = PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  isFetching = false,
  buyerDetailsDataSection = [],
  detailsLink,
  clickToCopies = [],
}) => {
  return (
    <PageSectionV2C
      title={title}
      isFetching={isFetching}
      columns={buyerDetailsDataSection}
      className='buyer-details'
      asideTitle={detailsLink}
      clickToCopies={clickToCopies}
    />
  )
}

BuyerDetails.propTypes = {
  title: PropTypes.string,
  isFetching: PropTypes.bool,
  buyerDetailsDataSection: PropTypes.array,
  detailsLink: PropTypes.object,
  clickToCopies: PropTypes.array,
}

export default BuyerDetails
