import './AccountBalanceS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import { getBalanceEntriesTransfersPaymentInstrumentsAndIdentities } from 'state-layer/selectors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import TableC from 'components/Shared/Table/TableC'
import { USD } from 'constants/currencyConstants'
import { BALANCE_ENTRIES } from 'constants/linkConstants'

import {
  BALANCE_ENTRY,
  GET_BALANCE_ENTRIES,
} from 'constants/flowConstants'

import {
  ACCOUNT_BALANCE,
  ACCOUNT_BALANCE_SUBTITLE,
  DAILY_ACH_PAYOUT_COUNT,
  DAILY_ACH_PAYOUT_VOLUME,
  DAILY_CARD_PAYOUT_COUNT,
  DAILY_CARD_PAYOUT_VOLUME,
} from 'constants/language/languageConstants'

const AccountBalance = ({
  applicationBalanceAmountUSD = '',
  displayCardDailyVelocityCurrentVolume = '',
  displayCardDailyVelocityVolumeLimit = '',
  cardDailyVelocityCurrentCount = '',
  cardDailyVelocityCountLimit = '',
  displayAchDailyVelocityCurrentVolume = '',
  displayAchDailyVelocityVolumeLimit = '',
  achDailyVelocityCurrentCount = '',
  achDailyVelocityCountLimit = '',
  balanceId,
  isFetching = false,
  flowValues = {},
  balanceEntryPath = '',
  balanceCurrency = USD,
}) => {
  return (
    <div className='AccountBalance'>
      <HeaderV2C
        title={<h2 className='flex items-end'>{`${ACCOUNT_BALANCE} : ${applicationBalanceAmountUSD ? applicationBalanceAmountUSD : '$0'}`}<h5>{balanceCurrency}</h5></h2>}
        subTitle={ACCOUNT_BALANCE_SUBTITLE}
      />

      <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
        <CustomBigNumberC
          title={DAILY_CARD_PAYOUT_VOLUME}
          headerData={{
            value: displayCardDailyVelocityCurrentVolume,
          }}
          subHeaderData={{
            value: displayCardDailyVelocityVolumeLimit,
            formatter: () => `of ${displayCardDailyVelocityVolumeLimit} limit`,
          }}
          style={{ minHeight: '100px' }}
        />

        <CustomBigNumberC
          title={DAILY_CARD_PAYOUT_COUNT}
          headerData={{
            value: cardDailyVelocityCurrentCount,
          }}
          subHeaderData={{
            value: cardDailyVelocityCountLimit,
            formatter: () => `of ${cardDailyVelocityCountLimit} transactions limit`,
          }}
        />

        <CustomBigNumberC
          title={DAILY_ACH_PAYOUT_VOLUME}
          headerData={{
            value: displayAchDailyVelocityCurrentVolume,
          }}
          subHeaderData={{
            value: displayAchDailyVelocityVolumeLimit,
            formatter: () => `of ${displayAchDailyVelocityVolumeLimit} limit`,
          }}
        />

        <CustomBigNumberC
          title={DAILY_ACH_PAYOUT_COUNT}
          headerData={{
            value: achDailyVelocityCurrentCount,
          }}
          subHeaderData={{
            value: achDailyVelocityCountLimit,
            formatter: () => `of ${achDailyVelocityCountLimit} transactions limit`,
          }}
        />
      </div>

      <TableC
        flow={balanceId ? GET_BALANCE_ENTRIES : null}
        flowValues={flowValues}
        selector={getBalanceEntriesTransfersPaymentInstrumentsAndIdentities}
        columnDescriptors={columnDescriptors}
        isFetching={isFetching}
        entityType={BALANCE_ENTRY}
        path={balanceEntryPath}
        linksKey={BALANCE_ENTRIES}
        fileNameCSV='Account_Balance_All_Activity'
      />
    </div>
  )
}

AccountBalance.propTypes = {
  applicationBalanceAmountUSD: PropTypes.string,
  displayCardDailyVelocityCurrentVolume: PropTypes.string,
  displayCardDailyVelocityVolumeLimit: PropTypes.string,
  cardDailyVelocityCurrentCount: PropTypes.string,
  cardDailyVelocityCountLimit: PropTypes.string,
  displayAchDailyVelocityCurrentVolume: PropTypes.string,
  displayAchDailyVelocityVolumeLimit: PropTypes.string,
  achDailyVelocityCurrentCount: PropTypes.string,
  achDailyVelocityCountLimit: PropTypes.string,
  balanceId: PropTypes.string,
  isFetching: PropTypes.bool,
  flowValues: PropTypes.object,
  balanceEntryPath: PropTypes.string,
  balanceCurrency: PropTypes.string,
}

export default AccountBalance
