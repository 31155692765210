import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getPageName from 'utilities/get/getPageName'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { SUBMIT_DASHBOARD_FEEDBACK } from 'constants/amplitudeConstants'
import { POST_DASHBOARD_FEEDBACK_EMAIL_F_REQUEST } from 'constants/flowConstants'
import { DASHBOARD_FEEDBACK_TEMPLATE } from 'constants/emailTemplateConstants'
import { getState } from 'state-layer/configureStore'
import get from 'lodash/get'

const submitDashboardFeedbackForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const currentUserEmail = get(props, 'currentUserEmail')

  // get page name for support use
  const state = getState()
  const pathName = get(state, 'routing.locationBeforeTransitions.pathname')
  const search = get(state, 'routing.locationBeforeTransitions.search')
  const queries = parseUrlQueries(search)
  const pageName = getPageName(pathName, queries)


  const formValues = {
    email_template_name: DASHBOARD_FEEDBACK_TEMPLATE,
    reply_to_email: currentUserEmail,
    email_template_data: {
      ...values,
      pageName,
      currentUserEmail,
    },
  }

  dispatch({
    type: POST_DASHBOARD_FEEDBACK_EMAIL_F_REQUEST,
    payload: {
      values: formValues,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_DASHBOARD_FEEDBACK, {
        credentials,
        pageName,
        userName: currentUserEmail,
      }),
    },
  })
}

export default submitDashboardFeedbackForm
