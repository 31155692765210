import dashboardAPI from 'utilities/api/dashboardAPI'
import { NOTE, NOTES } from 'constants/apiConstants'

const patchNoteAPI = ({ id, credentials, meta, values }) => {
  return dashboardAPI.patch({
    credentials,
    meta,
    values,
    path: NOTE({ noteId: id }),
    service: NOTES,
  })
}

export default patchNoteAPI
