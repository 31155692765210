import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import { BALANCE_ENTRY_METHOD_LABEL_MAP } from 'constants/transferConstants'
import { SYSTEM } from 'constants/roleConstants'
import capitalize from 'lodash/capitalize'

const frontendBalanceEntryModel = ({ data: balanceEntry }) => {
  const [
    id,
    createdAt,
    updatedAt,
    linkedTo,
    linkedType,
    amount,
    currency,
    type,
    wireDetails,
    tags,
    createdBy,
    transactionDate,
    description,
    transferId,
    parentBalanceEntryId,
    postedAt,
    state,
  ] = getMany(balanceEntry, [
    'id',
    'created_at',
    'updated_at',
    'linked_to',
    'linked_type',
    'amount',
    'currency',
    'type',
    'wire_details',
    'tags',
    'created_by',
    'transaction_date',
    'description',
    'transfer_id',
    'parent_balance_entry_id',
    'posted_at',
    'state',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    linkedTo,
    linkedType,
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    type,
    displayType: BALANCE_ENTRY_METHOD_LABEL_MAP[type],
    wireDetails,
    tags,
    createdBy,
    displayCreatedBy: createdBy === SYSTEM ? capitalize(createdBy) : createdBy,
    transactionDate,
    displayTransactionDate: formatDate({ date: transactionDate }),
    description,
    transferId,
    parentBalanceEntryId,
    postedAt,
    displayPostedAt: formatDate({ date: postedAt }),
    state,
  })
}

export default frontendBalanceEntryModel
