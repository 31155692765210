import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DisbursementsAccountColumn from 'components/Customer/Shared/Display/Columns/DisbursementsAccountColumn/DisbursementsAccountColumn'
import AmountCurrencyIconDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyIconDisplayTable/AmountCurrencyIconDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { BANK_ACCOUNT } from 'constants/paymentInstrumentConstants'
import { LIGHTNING_BOLT_ICON } from 'constants/iconConstants'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  ALL,
  CANCELED,
  FAILED,
  PUSH_TO_SAME_DAY_ACH,
} from 'constants/transferConstants'

import {
  AMOUNT,
  CREATED_ON,
  ID,
  METHOD,
  REASON_CODE,
  STATE,
  TYPE,
  ACH,
  CARD,
  RECIPIENT_NAME,
  PAYMENT_INSTRUMENT,
  PAYMENT_INSTRUMENT_NAME,
} from 'constants/language/languageConstants'


const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(RECIPIENT_NAME, 'merchantIdentity.fullName', {
    className: 'medium',
    formatter: (review) => {
      return get(review, 'merchantIdentity.businessName') || get(review, 'merchantIdentity.fullName')
    },
  }),

  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency, operationKey }) => {
      const icon = operationKey === PUSH_TO_SAME_DAY_ACH ? LIGHTNING_BOLT_ICON : null
      return (
        <AmountCurrencyIconDisplayTable icon={icon} iconStyle={{ color: '#0052E5' }} displayAmount={displayAmount} currency={currency} />
      )
    },
  }),

  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === CANCELED}
        subtitleIndent
      />
    ),
  }),
  // TODO: Uncomment when type returns sent/received is available instead of just sent
  // column(TYPE, 'transfer.displayType', {
  //   className: 'small',
  //   formatter: ({ type }) => <div>{isEqual(type, DEBIT) ? RECEIVED : SENT}</div>,
  // }),
  column(METHOD, 'paymentInstrument.type', {
    className: 'medium',
    formatter: ({ paymentInstrument }) => <div>{isEqual(get(paymentInstrument, 'type'), BANK_ACCOUNT) ? ACH : CARD}</div>,
  }),
  column(PAYMENT_INSTRUMENT_NAME, 'paymentInstrument.name', {
    className: 'medium',
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.maskedFullCardNumber', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={TYPE} />,
    className: 'medium',
    formatter: ({ paymentInstrument }) => <DisbursementsAccountColumn paymentInstrument={paymentInstrument} />,
  }),
]

export default columnDescriptors
