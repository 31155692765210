import {
  DUMMY_V1,
  VISA_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'crossriverbank',
  id: 'DASHiKyPtJHz9k8mSYGiHmoqcs',
  applicationLogoImage: 'https://finix-payments.github.io/customer-logos/assets/cross-river-bank-logo.png',
  faviconImage: 'https://finix-payments.github.io/customer-logos/assets/cross-river-bank-favicon.png',
  credentialsHost: 'https://crossriverbank-dashboard.herokuapp.com',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  paymentsApiHosts: 'https://*.crbpay.io',
  auth0: {
    clientID: 'WCg5kWe6SvR6flHu16CaWQ4EuZOBldZ7',
    domain: 'crossriverbank-dashboard.auth0.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'VISA_V1'],
  customStylesheet: 'https://finix-payments.github.io/customer-logos/assets/crossriverbank/application.css',
  dashboardServiceV2: true,
}
