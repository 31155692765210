import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccessFormSelector } from 'state-layer/selectors'
import AccessForm from './AccessForm'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getAccessFormRequest from 'utilities/actions/get/getAccessFormRequest'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { accessFormIconMap } from 'constants/statusConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  LIVE_ACCOUNT_APPLICATION_FORM,
  STATUS,
  SUBMITTED_ON,
  UPDATED_ON,
} from 'constants/language/languageConstants'

import {
  ACCESS_FORM_STATE_TO_DISPLAY_MAP,
  ACCESS_FORM_STATUS_MAP,
} from 'constants/underwritingConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `accessFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'params.accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)

  const [
    status,
    displayUpdatedAt,
    businessName,
    displaySubmittedAt,
  ] = getMany(accessForm, [
    'status',
    'displayUpdatedAt',
    'businessData.businessName',
    'displaySubmittedAt',
  ])

  const headerData = {
    resource: {
      label: LIVE_ACCOUNT_APPLICATION_FORM,
      id: accessFormId,
      clickToCopyPosition: 'right',
    },
    items: [
      {
        value: <h1>{businessName}</h1>,
      },
      {
        label: STATUS,
        value: <ColorcodedStatus data={ACCESS_FORM_STATUS_MAP} value={ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] ? ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] : status} customIconMap={accessFormIconMap} titleCase={false} />,
      },
      {
        label: SUBMITTED_ON,
        value: displaySubmittedAt,
      },
      {
        label: UPDATED_ON,
        value: displayUpdatedAt,
      },
    ],
    isFetching,
  }

  return {
    accessFormId,
    credentials,
    accessForm,
    status,
    displayUpdatedAt,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessForm: ({ credentials, accessFormId }) => { dispatch(getAccessFormRequest({ credentials, accessFormId })) },
  }
}

class AccessFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      accessFormId,
      getAccessForm,
      accessForm,
    } = this.props

    if (accessFormId && isEmpty(accessForm)) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  render() {
    return (
      <AccessForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessFormC)
