import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import PaymentInstrumentPaymentsC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentPayments/PaymentInstrumentPaymentsC'
import PaymentInstrumentRefundsC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentRefunds/PaymentInstrumentRefundsC'
import PaymentInstrumentAuthorizationsC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentAuthorizations/PaymentInstrumentAuthorizationsC'

import {
  notPayoutFeature,
} from 'utilities/validate/checkCredentialFeatures'

import {
  PAYMENTS,
  AUTHORIZATIONS,
  REFUNDS,
} from 'constants/language/languageConstants'

class PaymentInstrumentTransactionsTabs extends Component {
  render() {
    const {
      paymentInstrumentId,
    } = this.props

    const tabs = [
      {
        id: 'payments',
        name: PAYMENTS,
        component: PaymentInstrumentPaymentsC,
        props: {
          paymentInstrumentId,
        },
      },
      {
        id: 'authorizations',
        name: AUTHORIZATIONS,
        component: PaymentInstrumentAuthorizationsC,
        props: {
          paymentInstrumentId,
        },
        condition: notPayoutFeature,
      },
      {
        id: 'refunds',
        name: REFUNDS,
        component: PaymentInstrumentRefundsC,
        props: {
          paymentInstrumentId,
        },
      },
    ]

    return (
      <div className='PaymentInstrumentTransactionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default PaymentInstrumentTransactionsTabs
