import redirectRequest from 'utilities/actions/redirectRequest'
import isPlatform from 'utilities/is/isPlatform'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { APPLICATION_PATH } from 'constants/pathConstants'
import { POST_APPLICATION_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  CREATE_APPLICATION,
  APPLICATION_CREATED,
} from 'constants/amplitudeConstants'

const submitApplicationForms = (values, dispatch, { credentials, orchestrationModalAction }) => {
  const processorsValues = get(values, 'processors')
  const processors = pickBy(processorsValues, processorValue => processorValue)

  const isElavon = isPlatform('elavonpartnerconnect')
  const defaultSkipIdentity = isElavon ? true : false
  const skipIdentity = get(values, 'ownerIdentity.skipIdentity', defaultSkipIdentity)
  const application = merge({}, omit(values, ['processors', 'ownerIdentity.skipIdentity']), { processors })

  const minimumGenericIdentity = {
    businessName: get(application, 'ownerIdentity.businessName'),
    doingBusinessAs: get(application, 'ownerIdentity.businessName'),
    businessType: 'LIMITED_LIABILITY_COMPANY',
    businessPhone: '+1 (555) 555-5555',
    businessAddress: {
      city: 'San Francisco',
      country: 'USA',
      line1: '0 2nd Street',
      postalCode: '94107',
      region: 'CA',
    },
    maxTransactionAmount: '100,000',
    businessTaxId: '123456789',
  }

  const ownerIdentity = skipIdentity ? minimumGenericIdentity : get(application, 'ownerIdentity')
  const applicationToPost = skipIdentity ? merge({}, omit(application, 'ownerIdentity'), { ownerIdentity }) : application

  if (orchestrationModalAction) {
    dispatch(orchestrationModalAction)
  }

  dispatch({
    type: POST_APPLICATION_F_REQUEST,
    payload: {
      values: applicationToPost,
      credentials,
    },
    meta: {
      successCallback: ({ newValues, meta }) => {
        const applicationId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const applicationPath = APPLICATION_PATH({ applicationId, credentialId })
        const actionId = get(meta, 'actionId')

        if (applicationId && applicationId !== 'undefined') {
          dispatch(redirectRequest({ path: applicationPath }))

          sendAmplitudeActionEvent(APPLICATION_CREATED, {
            actionId,
            applicationId,
          })
        }
      },
      actionId: sendAmplitudeActionEvent(CREATE_APPLICATION, {
        credentials,
        businessName: get(application, 'ownerIdentity.businessName'),
      }),
    },
  })
}

export default submitApplicationForms
