import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentLinks from './PaymentLinks'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hasPermissions from 'utilities/hasPermissions'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_PAYMENT_LINK } from 'constants/language/languageConstants'
import { createdQueriesForLastMonths } from 'constants/queryConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

import {
  QA_ENVIRONMENT,
  SANDBOX_ENVIRONMENT,
} from 'constants/environmentConstants'

import {
  CREATE_PAYMENT_LINK_PATH,
  PAYMENT_LINKS_PATH,
} from 'constants/pathConstants'

import {
  PAYMENT_LINKS_QUICK_FILTERS,
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
  PAYMENT_LINKS_QUICK_FILTERS,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const isFetchingMerchants = get(state, `merchantsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const currentUser = getCurrentUser(state)
  const paymentLinksPath = PAYMENT_LINKS_PATH({ credentialId })
  const applicationId = get(currentUser, 'applicationId')
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const createPaymentEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const isRolePlatformCredential = isRolePlatform({ credentials })

  return {
    credentials,
    initialQueries,
    initialFilters: createdQueriesForLastMonths(6),
    isFetching,
    paymentLinksPath,
    quickFilters,
    applicationId,
    createPaymentEnabledApplicationMerchants,
    isFetchingMerchants,
    isRolePlatformCredential,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

class PaymentLinksC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && prevApplicationId !== applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      credentials,
      createPaymentEnabledApplicationMerchants,
      isFetchingMerchants,
      isRolePlatformCredential,
    } = this.props

    const credentialId = get(credentials, 'id')
    const environment = get(credentials, 'environment')
    const isFlex = isFlexPlatform()
    const hasOneMerchant = size(createPaymentEnabledApplicationMerchants) === 1

    const actions = [
      {
        label: CREATE_PAYMENT_LINK,
        className: 'create-payment-link',
        action: () => goToPath({
          pathname: CREATE_PAYMENT_LINK_PATH({ credentialId }),
          queries: hasOneMerchant ? { merchantId: get(createPaymentEnabledApplicationMerchants, '[0].id') } : {},
        }),
        condition: isFlex
            && environment !== QA_ENVIRONMENT
            && hasPermissions([
              'processing:transfer:create',
              'processing:identity:create',
              'processing:instrument:create',
              'processing:transfer:update',
            ])
            && isFetchingMerchants === false
            && !isRolePlatformCredential,
      },
    ]

    return (
      <PaymentLinks
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinksC)
