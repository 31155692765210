import { SELF_SERVICE_PASSWORD_REGEX } from 'constants/regexConstants'
import { COMPANY_EMAIL } from 'constants/language/languageConstants'
import trim from 'lodash/trim'

import {
  fieldEmpty,
  nonEmptyNumber,
  validateSelfServiceEmail,
  validateSelfServicePassword,
} from 'utilities/validate'

const validateSelfServiceSignupForm = (values) => {
  const {
    firstName,
    lastName,
    companyName,
    companyEmail,
    password,
    country,
    hCaptchaToken,
    annualPaymentVolume,
  } = values

  return {
    firstName: fieldEmpty(firstName, 'First Name', 'Please enter your first name'),
    lastName: fieldEmpty(lastName, 'Last Name', 'Please enter your last name'),
    companyName: fieldEmpty(companyName, 'Company Name', 'Please enter your company name'),
    password: validateSelfServicePassword({ field: password, name: 'Password', regex: SELF_SERVICE_PASSWORD_REGEX }),
    companyEmail: validateSelfServiceEmail({ field: trim(companyEmail), name: COMPANY_EMAIL }),
    country: fieldEmpty(country, 'Country', 'Please enter your country'),
    annualPaymentVolume: nonEmptyNumber({ field: annualPaymentVolume, name: 'Annual Gross Processing Volume' }),
    hCaptchaToken: fieldEmpty(hCaptchaToken),
  }
}

export default validateSelfServiceSignupForm
