import './UserActionsS.scss'
import React, { useState } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import Button from 'components/Shared/Button/Button'
import DashboardAccountsMenuItem from 'components/Customer/Pages/DashboardAccountsMenuItem/DashboardAccountsMenuItem'
import removeUndefined from 'utilities/remove/removeUndefined'
import { roleTypeMap } from 'constants/roleConstants'
import toUpper from 'lodash/toUpper'
import map from 'lodash/map'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  SETTINGS_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
} from 'constants/pathConstants'

import {
  LOGOUT_ICON,
  REMOVE_ICON,
  ANGLE_UP_ICON,
  ANGLE_DOWN_ICON,
} from 'constants/iconConstants'

import {
  LOGOUT,
  ROLE,
  VIEW_ALL_DASHBOARDS,
} from 'constants/language/languageConstants'

const UserActions = ({
  credentialId = '',
  userEmail = '',
  handleLogout = () => {},
  showUserMenu = false,
  showDashboardsMenu = false,
  toggleUserMenu = () => {},
  toggleDashboardsMenu = () => {},
  credentialName = '',
  userId = '',
  userRoleName = '',
  fullName,
  environment = '',
  role = '',
  recentlyActiveCredentials = [],
  totalDashboardsCount,
  sidebarTextColor,
  sidebarBackgroundColor,
  sidebarHighlightColor,
  isFetching = false,
  businessEntityId,
  businessName,
}) => {
  const initial = toUpper(userEmail[0])
  const angleIcon = showDashboardsMenu ? ANGLE_UP_ICON : ANGLE_DOWN_ICON
  const oneCred = totalDashboardsCount === 1
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const customAvatarTextStyle = ({ ignoreHover = false }) => removeUndefined({
    color: (isHovered && !ignoreHover) ? sidebarHighlightColor : sidebarTextColor,
  })

  const customAvatarBackgroundStyle = ({ ignoreHover = false }) => removeUndefined({
    backgroundColor: (isHovered && !ignoreHover) ? undefined : sidebarBackgroundColor,
  })

  return (
    <div className='UserActions flex items-center'>
      <div
        className={`dashboards-menu-container flex items-center ${oneCred ? 'one-cred' : ''}`}
        onClick={!oneCred ? toggleDashboardsMenu : () => {}}
        onKeyDown={!oneCred ? toggleDashboardsMenu : () => {}}
      >
        <DashboardAccountsMenuItem
          name={credentialName}
          environment={environment}
          role={role}
        />

        {!oneCred && <i className={`angle-icon fa fa-${angleIcon}`} />}
      </div>

      {showDashboardsMenu && (
        <div className='dashboards-menu'>
          {map(recentlyActiveCredentials, ({ id, name, environment: environmentData, role: roleData }) => {
            const roleType = get(roleTypeMap, roleData)

            return (
              <DashboardAccountsMenuItem
                name={name}
                environment={environmentData}
                role={roleType}
                path={HOME_PATH({ credentialId: id })}
              />
            )
          })}

          <div className='view-all-dashboards'>
            <Link to={DASHBOARD_PATH} className='text-link'>{`${VIEW_ALL_DASHBOARDS} (${totalDashboardsCount})`}</Link>
          </div>
        </div>
      )}

      {isFetching && <div className='user-initial-fetching' />}

      {!isFetching && (
        <div
          onClick={toggleUserMenu}
          className='user-initial h-5-caps'
          tabIndex='0'
          role='button'
          onKeyDown={toggleUserMenu}
          aria-label='user actions'
          aria-expanded={showUserMenu}
          style={customAvatarBackgroundStyle({})}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {!showUserMenu ?
            <div style={customAvatarTextStyle({})}>{initial}</div> :
            <i className={`fa fa-${REMOVE_ICON}`} style={customAvatarTextStyle({})} />
          }
        </div>
      )}

      { showUserMenu && (
        <div className='menu'>
          <div className='user-info-section flex-column center'>
            <div
              className='user-initial-in-dropdown h-5-caps'
              style={merge({}, customAvatarTextStyle({ ignoreHover: true }), customAvatarBackgroundStyle({ ignoreHover: true }))}
            >
              {initial}
            </div>

            {fullName && <Link className='full-name text-link' to={SETTINGS_PATH({ credentialId })}>{fullName}</Link>}

            <div className='email-and-id flex items-center'>
              <div className='email' title={userEmail}>{userEmail}</div>
              <ClickToCopyC position='left' content={userId} />
            </div>
          </div>

          <div className='app-section'>
            <div className='flex center items-center'>
              <div className='company-name p-2-bold' title={businessName}>{businessName}</div>
              {businessEntityId && <ClickToCopyC position='left' content={businessEntityId} />}
            </div>

            <div className='user-role-name'>{ROLE}: {userRoleName}</div>
          </div>

          <div className='logout'>
            <Button onClick={handleLogout} label={LOGOUT} variant='secondary' rightIcon={LOGOUT_ICON} />
          </div>
        </div>
      )}
    </div>
  )
}

UserActions.propTypes = {
  credentialId: PropTypes.string,
  userEmail: PropTypes.string,
  handleLogout: PropTypes.func,
  showUserMenu: PropTypes.bool,
  toggleUserMenu: PropTypes.func,
  showDashboardsMenu: PropTypes.bool,
  credentialName: PropTypes.string,
  userId: PropTypes.string,
  userRoleName: PropTypes.string,
  fullName: PropTypes.string,
  environment: PropTypes.string,
  role: PropTypes.string,
  totalDashboardsCount: PropTypes.number,
  sidebarTextColor: PropTypes.string,
  sidebarBackgroundColor: PropTypes.string,
  sidebarHighlightColor: PropTypes.string,
  isFetching: PropTypes.bool,
  businessEntityId: PropTypes.string,
  businessName: PropTypes.string,
}

export default UserActions
