import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionPlan from './SubscriptionPlan'
import { getSubscriptionPlanDataSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { collectionMethodMap } from 'constants/subscriptionsConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  BILLING_FREQUENCY,
  BILLING_SETTING,
  DESCRIPTION,
  TRIAL_DURATION,
  FREE_TRIAL_PHASE,
  NAME,
  RECURRING_PRICE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionPlansR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const subscriptionPlanId = get(props, 'subscriptionPlanId')
  const subscriptionPlan = getSubscriptionPlanDataSelector(state, subscriptionPlanId)

  const [
    displayAmount,
    planName,
    description,
    displayBillingInterval,
    collectionMethod,
    trialDefaults,
    displayTrialDuration,
  ] = getMany(subscriptionPlan, [
    'displayAmount',
    'planName',
    'description',
    'displayBillingInterval',
    'collectionMethod',
    'trialDefaults',
    'displayTrialDuration',
  ])

  const planDetails = convertPageSectionDataToV2([
    {
      label: NAME,
      value: planName,
    },
    {
      label: DESCRIPTION,
      value: description,
    },
    {
      label: BILLING_FREQUENCY,
      value: displayBillingInterval,
    },
    {
      label: RECURRING_PRICE,
      value: displayAmount,
    },
    {
      label: BILLING_SETTING,
      value: collectionMethodMap[collectionMethod],
    },
  ])

  const hasAddOns = !isEmpty(trialDefaults)

  const optionalAddOnsDetails = hasAddOns ? convertPageSectionDataToV2([{
    label: FREE_TRIAL_PHASE,
    value: 'Yes',
  },
  {
    label: TRIAL_DURATION,
    value: displayTrialDuration,
  },
  ]) : []

  return {
    isFetching,
    credentials,
    credentialId,
    subscriptionPlanId,
    subscriptionPlan,
    planDetails,
    optionalAddOnsDetails,
    hasAddOns,
    trialDefaults,
  }
}

class SubscriptionPlanC extends Component {
  render() {
    return (
      <SubscriptionPlan
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SubscriptionPlanC)
