import { parseUrlQueries } from 'utilities/parseUrlQueries'
import merge from 'lodash/merge'
import { getHistory } from 'state-layer/history'
import createQueryString from 'utilities/create/createQueryString'
import isEmpty from 'lodash/isEmpty'

const updateUrlQueries = (queries, forceOverride = false) => {
  const history = getHistory()

  if (isEmpty(queries)) {
    if (history) {
      history.replace({
        pathname: window.location.pathname,
        search: '',
      })
    }

    return {}
  }

  const updatedQueries = forceOverride ? queries : merge({}, parseUrlQueries(), queries)
  const updatedQueriesString = createQueryString({ queries: updatedQueries })

  // TODO: look into using redux-mock-store or some other utility to set history in test files
  if (history) {
    history.replace({
      pathname: window.location.pathname,
      search: updatedQueriesString,
    })
  }

  return updatedQueries
}

export default updateUrlQueries
