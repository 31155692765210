import React, { Component } from 'react'
import { connect } from 'react-redux'
import DevicePayments from './DevicePayments'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { PAYMENT } from 'constants/language/languageConstants'
import { PAYMENTS_PATH } from 'constants/pathConstants'
import { DEBIT } from 'constants/transferConstants'
import { FETCHING } from 'constants/reducerConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  AMOUNT,
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TAG_FILTERS,
  TRACE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  // TODO: add back in FE-3778 after BE's deployment on 1/11/24
  // INSTRUMENT_FINGERPRINT,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter(PAYMENT),
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const paymentPath = PAYMENTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const deviceId = get(props, 'deviceId')
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    initialFilters: { type: { eq: DEBIT } },
    isFetching,
    paymentPath,
    credentials,
    credentialId,
    deviceId,
  }
}

class DevicePaymentsC extends Component {
  render() {
    return (
      <DevicePayments
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DevicePaymentsC)
