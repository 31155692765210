import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateAccountBalanceForm from 'components/Customer/Forms/UpdateAccountBalanceForm/UpdateAccountBalanceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const applicationName = get(props, 'applicationName')
  const availableAmount = get(props, 'availableAmount')
  const updateAccountBalanceModalSubtitle = `Create an adjustment to manually update the account balance for ${applicationName}.`

  return {
    updateAccountBalanceModalSubtitle,
    credentials,
    availableAmount,
    balanceEntryCurrency: USD, // TODO: Cata - change this when we start supporting CAN for Disbursements
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class UpdateAccountBalanceFormC extends Component {
  render() {
    return (
      <UpdateAccountBalanceForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccountBalanceFormC)
