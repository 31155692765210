import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantSubscriptionEnrollments from './MerchantSubscriptionEnrollments'
import DeleteEnrollmentsConfirmationModal from 'components/Customer/Modals/DeleteEnrollmentsConfirmationModal/DeleteEnrollmentsConfirmationModal'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import deleteSubscriptionEnrollmentsRequest from 'utilities/actions/delete/deleteSubscriptionEnrollmentsRequest'
import { FETCHING } from 'constants/reducerConstants'
import { SUBSCRIPTION_ENROLLMENTS } from 'constants/selectedItemsConstants'
import { SUBSCRIPTION_SCHEDULE_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'

import {
  CREATE_SUBSCRIPTION as CREATE_SUBSCRIPTION_STRING,
  ENROLL_IN_EXISTING_SUBSCRIPTION,
  DELETE_ENROLLMENTS,
} from 'constants/language/languageConstants'

import {
  getSelectedItemsByKeySelector,
  getEnrollmentsScheduleSelector,
} from 'state-layer/selectors'

import {
  CONFIRM_ACTION,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_ENROLLMENT,
} from 'constants/modalConstants'

const selectedItemsKey = SUBSCRIPTION_ENROLLMENTS

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const merchantId = get(props, 'merchantId')
  const merchant = get(props, 'merchant')
  const merchantName = get(merchant, 'name')
  const isFetching = get(state, `merchantSubscriptionEnrollmentsR.${FETCHING}`)
  const selectedEnrollmentIds = Object.keys(getSelectedItemsByKeySelector(state, selectedItemsKey))

  const selectedEnrollments = map(selectedEnrollmentIds, (enrollmentId) => {
    const enrollment = get(getEnrollmentsScheduleSelector(state), enrollmentId)

    return merge({}, enrollment, { merchant })
  })

  const schedulePath = ({ schedule }) => SUBSCRIPTION_SCHEDULE_PATH({ credentialId, subscriptionScheduleId: get(schedule, 'id') })

  const initialQueries = {
    merchant_id: merchantId,
  }

  return {
    isFetching,
    initialQueries,
    credentials,
    merchantId,
    selectedItemsKey,
    selectedEnrollmentIds,
    selectedEnrollments,
    merchantName,
    schedulePath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateSubscriptionModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION, modalProps })),
    showCreateSubscriptionEnrollmentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION_ENROLLMENT, modalProps })),
    showDeleteEnrollmentsModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_ACTION, modalProps })),
    deleteEnrollments: ({ enrollmentIds, credentials }) => dispatch(deleteSubscriptionEnrollmentsRequest({ enrollmentIds, credentials, dispatch, selectedItemsKey })),
  }
}

class MerchantSubscriptionEnrollmentsC extends Component {
  showDeleteEnrollmentsConfirmationModal = () => {
    const {
      showDeleteEnrollmentsModal,
      selectedEnrollmentIds,
      selectedEnrollments,
      merchantName,
      deleteEnrollments,
      credentials,
    } = this.props

    const message = (
      <div style={{ width: '1250px' }}>
        <p>This action deletes the following subscription enrollments. {merchantName} will no longer be charged subscription fees based on these enrollments.</p>
        <p>Do you want to proceed?</p>
      </div>
    )

    const description = <DeleteEnrollmentsConfirmationModal enrollments={selectedEnrollments} />
    const confirmationAction = () => deleteEnrollments({ enrollmentIds: selectedEnrollmentIds, credentials })

    showDeleteEnrollmentsModal({ message, description, confirmationAction })
  }

  render() {
    const {
      credentials,
      merchantId,
      showCreateSubscriptionModal,
      showCreateSubscriptionEnrollmentModal,
    } = this.props

    const actions = [
      {
        label: CREATE_SUBSCRIPTION_STRING,
        action: () => {
          showCreateSubscriptionModal({
            credentials,
            merchantId,
          })
        },
      },
      {
        label: ENROLL_IN_EXISTING_SUBSCRIPTION,
        action: () => {
          showCreateSubscriptionEnrollmentModal({
            credentials,
            merchantId,
          })
        },
      },
    ]

    const selectedItemsAction = [
      {
        label: DELETE_ENROLLMENTS,
        action: this.showDeleteEnrollmentsConfirmationModal,
      },
    ]

    const selectedItemsData = {
      actions: selectedItemsAction,
    }

    return (
      <MerchantSubscriptionEnrollments
        actions={actions}
        selectedItemsData={selectedItemsData}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSubscriptionEnrollmentsC)
