import validateTagsForm from 'utilities/validate/validateTagsForm'

import {
  fieldEmpty,
  validateInteger,
} from 'utilities/validate'

import {
  FIXED_TIME_INTERVAL,
  PERIODIC_MONTHLY,
  PERIODIC_YEARLY,
} from 'constants/subscriptionConstants'

const validateCreateSubscriptionScheduleForm = (values) => {
  const {
    scheduleNickname,
    subscriptionType,
    periodOffset = {},
    fixedTimeIntervalOffset = {},
    tags,
  } = values

  const { month, day } = periodOffset
  const { hourlyInterval, intervalCount } = fixedTimeIntervalOffset
  const tagErrors = validateTagsForm({ tags })

  return {
    scheduleNickname: fieldEmpty(scheduleNickname, 'Schedule nickname'),
    subscriptionType: fieldEmpty(subscriptionType, 'Subscription type'),
    periodOffset: {
      day: (subscriptionType === PERIODIC_MONTHLY || subscriptionType === PERIODIC_YEARLY) && fieldEmpty(day, 'Day'),
      month: subscriptionType === PERIODIC_YEARLY && fieldEmpty(month, 'Month'),
    },
    fixedTimeIntervalOffset: {
      hourlyInterval: subscriptionType === FIXED_TIME_INTERVAL && validateInteger({ field: hourlyInterval, name: 'Interval (hours)', value: 1 }),
      intervalCount: subscriptionType === FIXED_TIME_INTERVAL && validateInteger({ field: intervalCount, name: 'Interval count', value: 1 }),
    },
    ...tagErrors,
  }
}

export default validateCreateSubscriptionScheduleForm
