import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'

const frontendEntityConfiguration = ({ data: entityConfiguration }) => {
  const [
    id,
    createdAt,
    updatedAt,
    linkedTo,
    linkedType,
    mfaEnabled,
  ] = getMany(entityConfiguration, [
    'id',
    'created_at',
    'updated_at',
    'linked_to',
    'linked_type',
    'mfa_enabled',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    linkedTo,
    linkedType,
    mfaEnabled,
  })
}

export default frontendEntityConfiguration
