import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { ENABLE_DISABLE_PAYMENT_INSTRUMENT } from 'constants/amplitudeConstants'

const submitEditPaymentInstrumentForm = (values, dispatch, props) => {
  const {
    id,
    enabled,
  } = values

  const newValues = {
    enabled,
  }

  const {
    actionType,
    credentials,
  } = props

  dispatch({
    type: actionType,
    payload: {
      id,
      credentials,
      values: newValues,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(ENABLE_DISABLE_PAYMENT_INSTRUMENT, {
        credentials,
        paymentInstrumentId: id,
        enabled,
      }),
    },
  })
}

export default submitEditPaymentInstrumentForm
