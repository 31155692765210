import { GET_GUEST_ACCESS_FORM_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestAccessFormRequest = ({
  id,
}) => createAction({
  type: GET_GUEST_ACCESS_FORM_F_REQUEST,
  id,
})

export default getGuestAccessFormRequest
