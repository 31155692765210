import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlertRelatedAlerts from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertRelatedAlerts/MonitoringAlertRelatedAlerts'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { MONITORING_ALERT_RELATED_ALERTS } from 'constants/linkConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  getMonitoringAlertsSelector,
} from 'state-layer/selectors'

import {
  MONITORING_ALERTS_QUICK_FILTER,
  MONITORING_ALERTS_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  createdAtNoDefaultQueries,
} from 'constants/queryConstants'

import {
  MONITORING_ALERT_NAME,
  MONITORING_ALERT_LINKED_NAME,
} from 'constants/filterConstants'

import {
  MONITORING_ALERT_DETAILS_TAB_PATH,
} from 'constants/pathConstants'

const quickFilters = [
  MONITORING_ALERTS_QUICK_FILTER,
  MONITORING_ALERTS_DATES_QUICK_FILTER,
]

const allowedFilters = [
  MONITORING_ALERT_NAME,
  MONITORING_ALERT_LINKED_NAME,
]

const initialFilters = createdAtNoDefaultQueries

const mapStateToProps = (state, props) => {
  const monitoringAlert = get(props, 'monitoringAlert')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const monitoringAlertPath = (data) => MONITORING_ALERT_DETAILS_TAB_PATH({ credentialId, monitoringAlertId: get(data, 'id') })
  const queries = get(props, 'queries', {})
  const linkedTo = get(monitoringAlert, 'linkedTo')
  const initialQueries = {
    sort: 'created_at,desc',
    linked_to: linkedTo,
  }
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    initialQueries: mergedQueries,
    credentials,
    quickFilters,
    allowedFilters,
    initialFilters,
    monitoringAlertPath,
    selector: getMonitoringAlertsSelector,
    linksKey: MONITORING_ALERT_RELATED_ALERTS,
  }
}

class MonitoringAlertRelatedAlertsC extends Component {
  render() {
    return (
      <div>
        <MonitoringAlertRelatedAlerts
          {...this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(MonitoringAlertRelatedAlertsC)
