import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import SettlementFundingTransfers from './SettlementFundingTransfers'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { getSettlementFundingTransfersSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import { FAILED } from 'constants/transferConstants'
import { RESEND_TRANSFER } from 'constants/modalConstants'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { RESEND_TRANSFER_LABEL } from 'constants/language/languageConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import some from 'lodash/some'

import {
  MERCHANT_DEPOSIT_PATH,
  PLATFORM_FUNDING_TRANSFER_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentialId = getCurrentCredentialsId(state)
  const settlementId = get(props, 'settlementId')
  const isFetching = get(state, `settlementFundingTransfersR.${FETCHING}`)
  const fundingTransfers = getSettlementFundingTransfersSelector(state, settlementId)
  const bankAccountPaymentInstruments = get(props, 'bankAccountPaymentInstruments')

  // TODO: should make this into a util if we ever use more than once
  const showRefundButton = some(groupBy(fundingTransfers, 'type'), (fundingTransfersGroupedByType) => {
    return some(groupBy(fundingTransfersGroupedByType, 'amount'), (fundingTransfersGroupedByAmount) => {
      const relatedFundingTransfersOrderedByCreatedAt = orderBy(fundingTransfersGroupedByAmount, ['createdAt'], ['desc'])
      const latestRelatedFundingTransfer = head(relatedFundingTransfersOrderedByCreatedAt)

      return get(latestRelatedFundingTransfer, 'state') === FAILED
    })
  })

  const flowValues = {
    settlementId,
  }

  return {
    settlementId,
    flowValues,
    isFetching,
    showRefundButton,
    credentialId,
    bankAccountPaymentInstruments,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showResendTransferModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_TRANSFER, modalProps, className: 'modal-md no-pad overflow-visible' })),
  }
}

class SettlementFundingTransfersC extends Component {
  render() {
    const {
      settlementId,
      showRefundButton,
      showResendTransferModal,
      bankAccountPaymentInstruments,
      credentialId,
    } = this.props

    const actions = showRefundButton ? [
      { label: RESEND_TRANSFER_LABEL,
        action: () => showResendTransferModal({ bankAccountPaymentInstruments, settlementId }),
      },
    ] : []

    const tableItemOnClick = (data) => {
      const [
        subtype,
        id,
      ] = getMany(data, [
        'subtype',
        'id',
      ])

      if (subtype === 'SETTLEMENT_MERCHANT') {
        goToPath({ pathname: MERCHANT_DEPOSIT_PATH({ credentialId, transactionId: id }) })
      } else if (subtype === 'SETTLEMENT_PLATFORM') {
        goToPath({ pathname: PLATFORM_FUNDING_TRANSFER_PATH({ credentialId, transactionId: id }) })
      }
    }

    return (
      <SettlementFundingTransfers
        {...this.props}
        actions={actions}
        tableItemOnClick={tableItemOnClick}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementFundingTransfersC)
