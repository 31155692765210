import removeUndefined from 'utilities/remove/removeUndefined'
import { REFRESH_TABLE } from 'constants/actionConstants'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/language/languageConstants'
import { EMAIL, SMS, PRINT } from 'constants/receiptConstants'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  POST_RECEIPT_DELIVERY_ATTEMPTS_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

const submitSendReceiptForm = (values, dispatch, props) => {
  const {
    credentials,
    receipt,
  } = props

  const {
    sendMethodEmail,
    sendMethodSMS,
    sendMethodPrint,
    phoneNumbers,
    emails,
    device,
  } = values

  const id = get(receipt, 'id')

  const sendMethods = []
  if (sendMethodEmail) sendMethods.push(EMAIL)
  if (sendMethodSMS) sendMethods.push(SMS)
  if (sendMethodPrint) sendMethods.push(PRINT)

  dispatch({
    type: POST_RECEIPT_DELIVERY_ATTEMPTS_F_REQUEST,
    payload: {
      credentials,
      values: removeUndefined({
        phoneNumbers: sendMethodSMS ? map(phoneNumbers, ({ value }) => value) : undefined,
        emails: sendMethodEmail ? map(emails, ({ value }) => value) : undefined,
        sendMethods,
        devices: sendMethodPrint ? [get(device, 'value')] : undefined,
      }),
      id,
    },
    meta: {
      successCallback: () => {
        dispatch({
          type: REFRESH_TABLE,
        })
      },
      errorCallback: () => {
        dispatch({
          type: SHOW_ERROR_FLASH_NOTIFICATION,
          payload: {
            message: GENERIC_API_ERROR_MESSAGE,
          },
        })
      },
      closeModal: true,
    },
  })
}

export default submitSendReceiptForm
