import React, { Component } from 'react'
import { connect } from 'react-redux'
import Permissions from './Permissions'
import get from 'lodash/get'
import { formatPermissionsForView } from 'utilities/formatters/formatPermissions'

const mapStateToProps = (state, props) => {
  const userPermissions = get(props, 'userPermissions')
  const type = get(props, 'type')
  const authRole = get(props, 'authRole')

  const formattedPermissions = formatPermissionsForView({ type, userPermissions, authRole })

  return {
    formattedPermissions,
  }
}


class PermissionsC extends Component {
  componentDidMount() {}

  render() {
    return (
      <Permissions
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, null)(PermissionsC)
