import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { getCategorizedReportsSelector } from 'state-layer/selectors'
import Reports from './Reports'
import getCategorizedReportsRequest from 'utilities/actions/get/getCategorizedReportsRequest'
import createCategorizedReportsObject from 'utilities/create/createCategorizedReportsObject'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import redirectRequest from 'utilities/actions/redirectRequest'
import { isFlexPlatform } from 'constants/flexConstants'
import { REPORTS_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { REPORT_SEARCH_FORM } from 'constants/formConstants'
import { VIEW_ALL_REPORTS_LABEL } from 'constants/language/languageConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import lowerCase from 'lodash/lowerCase'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state) => {
  const isFetching = get(state, `categorizedReportsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const reports = getCategorizedReportsSelector(state)
  const formSelector = formValueSelector(REPORT_SEARCH_FORM)
  const searchQuery = formSelector(state, 'reportFilter')
  const isFlex = isFlexPlatform()
  const role = get(credentials, 'role')

  // only filter reports if the search query has input
  const filteredReports = !isEmpty(searchQuery) ? filter(Object.values(reports), ({ fileName, displayCategory: category, displaySubcategory: subcategory }) => {
    const normalizedSearchQuery = lowerCase(searchQuery)

    return includes(lowerCase(fileName), normalizedSearchQuery) || includes(lowerCase(category), normalizedSearchQuery) || includes(lowerCase(subcategory), normalizedSearchQuery)
  }) : reports

  const categorizedReports = createCategorizedReportsObject(Object.values(filteredReports))

  return {
    credentials,
    credentialId,
    isFetching,
    reports,
    categorizedReports,
    searchQuery,
    isFlex,
    role,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategorizedReports: (props) => dispatch(getCategorizedReportsRequest(props)),
    removeAllSearchQuery: () => dispatch(change(REPORT_SEARCH_FORM, 'reportFilter', '')),
    goToCategorizedReportsTable: ({ path }) => dispatch(redirectRequest({ path })),
  }
}

class ReportsC extends Component {
  componentDidMount() {
    const {
      credentials,
      getCategorizedReports,
    } = this.props

    const queries = {
      'created_at.gte': moment().subtract(36, 'days').toISOString(),
    }

    getCategorizedReports({ credentials, queries })
  }

  render() {
    const {
      credentialId,
      removeAllSearchQuery,
      goToCategorizedReportsTable,
    } = this.props

    const actions = [
      {
        label: VIEW_ALL_REPORTS_LABEL,
        className: 'view-table',
        link: REPORTS_PATH({ credentialId }),
      },
    ]

    return (
      <Reports
        {...this.props}
        actions={actions}
        clearSearchQuery={() => removeAllSearchQuery()}
        goToCategorizedReportsTable={goToCategorizedReportsTable}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsC)
