import get from 'lodash/get'
import { take, put } from 'redux-saga/effects'

import { MERCHANT, IDENTITY } from 'constants/reviewQueueConstants'

import {
  GET_REVIEW_QUEUE_F_SUCCESS,
  GET_REVIEW_QUEUE_SETTLEMENTS,
  GET_REVIEW_QUEUE_MERCHANTS,
  GET_REVIEW_QUEUE_IDENTITIES,
} from 'constants/flowConstants'

const getReviewQueueEntityType = (entityType) => {
  switch (entityType) {
    case MERCHANT:
      return GET_REVIEW_QUEUE_MERCHANTS

    case IDENTITY:
      return GET_REVIEW_QUEUE_IDENTITIES

    default:
      return GET_REVIEW_QUEUE_SETTLEMENTS
  }
}

const getReviewQueueF = function * () {
  while (true) {
    const { meta, payload } = yield take([GET_REVIEW_QUEUE_F_SUCCESS])
    const entityType = get(payload, 'queries.entity_type')
    const type = getReviewQueueEntityType(entityType)

    yield put({
      type,
      payload,
      meta,
    })
  }
}

export default getReviewQueueF
