import { GET_APPLICATION_PROCESSOR_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getApplicationProcessorRequest = ({
  applicationId,
  credentials,
  processor,
}) => createAction({
  type: GET_APPLICATION_PROCESSOR_F_REQUEST,
  values: {
    applicationId,
    processor,
  },
  credentials,
})

export default getApplicationProcessorRequest
