import HostedMerchantOnboarding from './HostedMerchantOnboarding'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getMany from 'utilities/get/getMany'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import getGuestOnboardingFormDataRequest from 'utilities/actions/get/getGuestOnboardingFormDataRequest'
import getGuestOnboardingFormFilesRequest from 'utilities/actions/get/getGuestOnboardingFormFilesRequest'
import getHostedFormTokenVerificationRequest from 'utilities/actions/get/getHostedFormTokenVerificationRequest'
import getGuestDashboardCustomizationsRequest from 'utilities/actions/get/getGuestDashboardCustomizationsRequest'
import getGuestOnboardingFormUnderwritingProfileRequest from 'utilities/actions/get/getGuestOnboardingFormUnderwritingProfileRequest'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { FETCHING } from 'constants/reducerConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import { COMPLETED } from 'constants/onboardingFormConstants'
import { FINIX_NAV_BAR_LOGO } from 'constants/logoConstants'
import { MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES } from 'constants/identityConstants'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  getHostedFormTokenVerificationSelector,
  getDashboardConfigByEntityTypeAndId,
  getOnboardingFormDataItemSelector,
  getUnderwritingProfileByEntityId,
  getUnderwritingProfileByPlatform,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const isFetchingTokenData = get(state, `hostedFormTokenDataR.${FETCHING}`)
  const isFetchingDashboardConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const isFetching = isFetchingTokenData || isFetchingDashboardConfiguration
  const platform = getCurrentPlatform()
  const applicationLogoImage = get(platform, 'applicationLogoImage', FINIX_NAV_BAR_LOGO)

  // ensure token is valid and store relevant data
  const onboardingTokenVerification = getHostedFormTokenVerificationSelector(state)

  const [
    isValid,
    expiredSessionUrl,
    returnUrl,
    termsOfServiceUrl,
    feeProfileUrl,
    maxTransactionAmount,
    achMaxTransactionAmount,
  ] = getMany(onboardingTokenVerification, [
    'isValid',
    'expiredSessionUrl',
    'returnUrl',
    'termsOfServiceUrl',
    'feeDetailsUrl',
    'maxTransactionAmount',
    'achMaxTransactionAmount',
  ])

  // fetch values from url
  const queries = get(state, 'routing.locationBeforeTransitions.query')
  const token = get(queries, 'bearerToken')
  const onboardingFormId = get(queries, 'formId')
  const applicationId = get(queries, 'applicationId')

  // fetch any customization for this application
  const dashboardCustomizationQueries = {
    entity_type: 'APPLICATION',
    entity_id: applicationId,
  }

  const dashboardCustomizationMeta = {
    showErrors: false,
  }

  // fetch any customization for this application
  const dashboardCustomization = getDashboardConfigByEntityTypeAndId(state, 'APPLICATION', applicationId)

  const [
    sidebarLogoImageUrl,
    subDomainName,
    sidebarBackgroundColor,
    onboardingDisplayName,
    onboardingLogoImageUrl,
    onboardingPrimaryColor = '#FFFFFF',
  ] = getMany(dashboardCustomization, [
    'sidebarLogoImageUrl',
    'subDomainName',
    'sidebarBackgroundColor',
    'onboardingDisplayName',
    'onboardingLogoImageUrl',
    'onboardingPrimaryColor',
  ])

  // fetch underwriting profile to get list of MCCs
  const underwritingProfile = getUnderwritingProfileByEntityId(state, applicationId) || getUnderwritingProfileByPlatform(state)

  // fetch form and form status
  const onboardingFormData = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const onboardingFormCountry = get(onboardingFormData, 'country')
  const isFormCompleted = get(onboardingFormData, 'status') === COMPLETED
  const isTokenValid = isValid && !isEqual(get(state, 'errorsR.errors.0.status'), 401)

  // hide or show the welcome page based on url queries
  const urlQueries = parseUrlQueries()
  const hideWelcomePage = !!get(urlQueries, 'activeStep')

  return {
    isFetching,
    boardingStyles: !isFetching ? { backgroundColor: onboardingPrimaryColor || sidebarBackgroundColor || '#FFFFFF' } : {},
    boardingLogoImage: onboardingLogoImageUrl || sidebarLogoImageUrl || applicationLogoImage,
    token,
    onboardingFormId,
    applicationId,
    isTokenValid,
    expiredSessionUrl,
    returnUrl,
    termsOfServiceUrl,
    dashboardCustomizationQueries,
    dashboardCustomizationMeta,
    applicationName: onboardingDisplayName || subDomainName,
    isFormCompleted,
    maxTransactionAmount,
    achMaxTransactionAmount,
    feeProfileUrl,
    underwritingProfile,
    onboardingFormData,
    onboardingFormCountry,
    hideWelcomePage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingForm: ({ id, meta }) => { dispatch(getGuestOnboardingFormDataRequest({ id, meta })) },
    getOnboardingFormFiles: ({ onboardingFormId, fileIds }) => { dispatch(getGuestOnboardingFormFilesRequest({ onboardingFormId, fileIds })) },
    getHostedFormTokenVerification: () => { dispatch(getHostedFormTokenVerificationRequest()) },
    getGuestDashboardCustomization: ({ queries, meta }) => { dispatch(getGuestDashboardCustomizationsRequest({ queries, meta })) },
    getUnderwritingProfileForOnboardingForm: ({ id }) => { dispatch(getGuestOnboardingFormUnderwritingProfileRequest({ id })) },
  }
}

class HostedMerchantOnboardingC extends Component {
  componentDidMount() {
    const {
      getOnboardingForm,
      token,
      onboardingFormId,
      getOnboardingFormFiles,
      getHostedFormTokenVerification,
      getGuestDashboardCustomization,
      dashboardCustomizationQueries,
      dashboardCustomizationMeta,
      getUnderwritingProfileForOnboardingForm,
    } = this.props

    // clear localStorarge to ensure hosted onboarding hits the correct env
    localStorage.clear()

    // set the bearer token for localStorage for authentication uses
    localStorage.setItem(AUTH_SESSION, JSON.stringify({
      idToken: token,
      tokenType: 'Bearer',
    }))

    // verify bearer token from url
    getHostedFormTokenVerification()

    // Once bearer token has been verified, fetch dashboard configuration for white-label data
    getGuestDashboardCustomization({ queries: dashboardCustomizationQueries, meta: dashboardCustomizationMeta })

    // Once bearer token has been verified, fetch existing onboarding form data
    getOnboardingForm({
      id: onboardingFormId,
      meta: {
        successCallback: ({ newValues }) => {
          const onboardingForm = head(Object.values(newValues))
          const fileIds = map(get(onboardingForm, 'associatedFiles'), 'id')

          if (!isEmpty(fileIds)) {
            getOnboardingFormFiles({ onboardingFormId, fileIds })
          }
        },
      },
    })

    // Once bearer token has been verified, fetch underwriting profile for the form
    getUnderwritingProfileForOnboardingForm({ id: onboardingFormId })
  }

  componentDidUpdate(prevProps) {
    const { onboardingFormData } = this.props
    const { onboardingFormData: prevOnboardingFormData } = prevProps
    const urlQueries = parseUrlQueries()
    const hasActiveStepQuery = !!get(urlQueries, 'activeStep')

    if (isEmpty(prevOnboardingFormData) && !isEmpty(onboardingFormData)) {
      const businessType = get(onboardingFormData, 'businessData.businessType')
      const isValidBusinessType = includes(MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES, businessType)

      if (!hasActiveStepQuery && isValidBusinessType) {
        updateUrlQueries({ activeStep: 0 })
      }
    }
  }

  render () {
    return (
      <HostedMerchantOnboarding
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HostedMerchantOnboardingC)
