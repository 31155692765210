import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutPlans from './PayoutPlans'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { isRoleAdmin } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'
import { FETCHING } from 'constants/reducerConstants'
import { PAYOUT_PLANS_ICON } from 'constants/iconConstants'
import { LEVEL, ENTITY_ID } from 'constants/filterConstants'

import {
  NEW_PAYOUT_PLANS_PATH,
  PAYOUT_PLANS_PATH,
} from 'constants/pathConstants'

const quickFilters = []

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `payoutPlansR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const payoutPlanPath = PAYOUT_PLANS_PATH({ credentialId })

  const actions = [
    {
      label: 'Create Payout Plan',
      icon: `fa fa-${PAYOUT_PLANS_ICON}`,
      link: NEW_PAYOUT_PLANS_PATH({ credentialId }),
      className: 'createPayoutPlan',
      condition: isRoleAdmin,
    },
  ]

  const allowedFilters = [
    LEVEL,
    ENTITY_ID,
  ]

  return {
    isFetching,
    actions,
    payoutPlanPath,
    allowedFilters,
    quickFilters,
  }
}

class PayoutPlansC extends Component {
  render() {
    return (
      <PayoutPlans {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PayoutPlansC)
