import { GET_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getOnboardingFormDataRequest = ({
  onboardingFormId,
  credentials,
}) => createAction({
  type: GET_ONBOARDING_FORM_DATA_F_REQUEST,
  id: onboardingFormId,
  credentials,
})

export default getOnboardingFormDataRequest
