import './CreatePaymentInstrumentVerificationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import selectOption from 'utilities/forms/selectOption'
import Button from 'components/Shared/Button/Button'
import { CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM } from 'constants/formConstants'
import map from 'lodash/map'
import { CREATE } from 'constants/language/languageConstants'

const generateProcessorTypeOptions = (availableProcessorTypes) => {
  const defaultSelection = selectOption('Select Type', '')

  const processorTypeSelections = map(availableProcessorTypes, (type) => {
    return selectOption(type, type)
  })

  return [defaultSelection, ...processorTypeSelections]
}

const CreatePaymentInstrumentVerificationForm = ({
  message = ' ',
  submitting = false,
  paymentInstrumentId = '',
  processorFieldValue = '',
  availableProcessorTypes = [],
  allowedCountryCodeOptions = [],
  shouldShowCountrySelect = false,
  handleSubmit = () => {},
}) => {
  const availableProcessors = generateProcessorTypeOptions(availableProcessorTypes)

  return (
    <form className='CreatePaymentInstrumentVerificationForm' onSubmit={handleSubmit}>
      <div className='message'>{message}</div>
      <hr />

      <div className='items'>

        <div className='paymentInstrument'>
          <div>Payment Instrument</div>
          <div className='id'>{paymentInstrumentId}</div>
        </div>

        <Field
          name='processor'
          label='Processor'
          component={SelectField}
          options={availableProcessors}
        />

        { shouldShowCountrySelect && (
          <Field
            name='country'
            label='Country'
            component={SelectField}
            options={allowedCountryCodeOptions}
          />
        )}
      </div>

      <hr />

      <div className='buttons flex flex-end'>
        <Button type='submit' label={CREATE} submitting={submitting} disabled={processorFieldValue === ''} />
      </div>
    </form>
  )
}

CreatePaymentInstrumentVerificationForm.propTypes = {
  message: PropTypes.string,
  submitting: PropTypes.bool,
  paymentInstrumentId: PropTypes.string,
  processorFieldValue: PropTypes.string,
  availableProcessorTypes: PropTypes.array,
  allowedCountryCodeOptions: PropTypes.array,
  shouldShowCountrySelect: PropTypes.bool,
  handleSubmit: PropTypes.func,
}

export default reduxForm({
  form: CREATE_PAYMENT_INSTRUMENT_VERIFICATION_FORM,
})(CreatePaymentInstrumentVerificationForm)
