import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CREDENTIAL_V3 } from 'constants/apiConstants'

const postMemberAPI = ({ values, meta }) => {
  return dashboardAPI.post({
    meta,
    values,
    path: DASHBOARD_SERVICE_CREDENTIAL_V3,
  })
}

export default postMemberAPI
