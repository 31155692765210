import React, { Component } from 'react'
import { connect } from 'react-redux'
import RequireTeamMFAModal from './RequireTeamMFAModal'
import hideModalAction from 'utilities/actions/hideModalAction'
import postEntityConfigurationRequest from 'utilities/actions/post/postEntityConfigurationRequest'
import patchEntityConfigurationRequest from 'utilities/actions/patch/patchEntityConfigurationRequest'
import isPatching from 'utilities/is/isPatching'

const mapStateToProps = (state) => {
  const patching = isPatching(state)

  return {
    patching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postEntityConfiguration: ({ linkedTo, linkedType, mfaEnabled }) => dispatch(postEntityConfigurationRequest({ linkedTo, linkedType, mfaEnabled })),
    patchEntityConfiguration: ({ id, values }) => dispatch(patchEntityConfigurationRequest({ id, values })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class RequireTeamMFAModalC extends Component {
  submitMFAEnabled = () => {
    const {
      linkedTo,
      linkedType,
      entityConfigurationId,
      postEntityConfiguration,
      patchEntityConfiguration,
      mfaEnabled,
    } = this.props

    if (entityConfigurationId) {
      patchEntityConfiguration({
        id: entityConfigurationId,
        values: {
          mfaEnabled,
        },
      })
    } else {
      postEntityConfiguration({
        linkedTo,
        linkedType,
        mfaEnabled,
      })
    }
  }

  render() {
    return (
      <RequireTeamMFAModal
        {...this.props}
        submitMFAEnabled={this.submitMFAEnabled}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequireTeamMFAModalC)
