import './PlatformProcessorConfigS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Header from 'components/Customer/Shared/Page/Header/Header'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import PlatformProcessorConfigsFormC from 'components/Customer/Forms/PlatformProcessorConfigsForm/PlatformProcessorConfigsFormC'
import { SYSTEM_CONFIG_FORM, APPLICATION_CONFIG_FORM } from 'constants/formConstants'
import { SYSTEM_CONFIG, APPLICATION_CONFIG } from 'constants/configConstants'

const PlatformProcessorConfig = ({
  headerTitle = '',
  headerSubTitle = '',
  detailsSectionData = [],
  isFetching = false,
  processorConfigId = '',
  systemConfig = {},
  applicationConfig = {},
  updateConfigValue = () => {},
}) => {
  return (
    <div className='PlatformProcessorConfig'>
      <Header
        title={headerTitle}
        subTitle={headerSubTitle}
        breadcrumbs
      />

      <div className='header-page-content'>
        <PageSection
          title='Details'
          data={detailsSectionData}
          isFetching={isFetching}
        />

        <PageSection
          title='System Config'
          data={systemConfig}
          extraProps={{ sectionId: SYSTEM_CONFIG, updateConfigValue }}
          component={PlatformProcessorConfigsFormC}
          isFetching={isFetching}
          form={SYSTEM_CONFIG_FORM}
          emptyMessage='No system configurations available'
        />

        <PageSection
          title='Application Config'
          extraProps={{ processorConfigId, sectionId: APPLICATION_CONFIG, updateConfigValue }}
          data={applicationConfig}
          component={PlatformProcessorConfigsFormC}
          isFetching={isFetching}
          form={APPLICATION_CONFIG_FORM}
          emptyMessage='No application configurations available'
        />
      </div>
    </div>
  )
}

PlatformProcessorConfig.propTypes = {
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  processorConfigId: PropTypes.string,
  systemConfig: PropTypes.object,
  applicationConfig: PropTypes.object,
  updateConfigValue: PropTypes.func,
}

export default PlatformProcessorConfig
