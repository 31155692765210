import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdverseMediaReport from './AdverseMediaReport'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { UNDERWRITING_REPORT_PATH } from 'constants/pathConstants'
import { countryISO2toISO3, countryNameByCountryCode } from 'constants/countryConstants'
import size from 'lodash/size'
import join from 'lodash/join'
import map from 'lodash/map'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const report = get(props, 'report', {})
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)

  const [
    hits,
    raw,
    reportId,
  ] = getMany(report, [
    'hits',
    'raw',
    'id',
  ])

  const personaReportId = get(raw, 'data.id')
  const hitsCount = size(hits)
  const viewDetailsPath = UNDERWRITING_REPORT_PATH({ credentialId, reportId })

  const hitsContent = map(hits, (hit) => {
    const [
      name,
      birthdates = [],
      sources,
      media,
    ] = getMany(hit, [
      'name',
      'birthdates',
      'sources',
      'media',
    ])

    const countryCodes = get(sources, '[0].country_codes')
    const categories = get(sources, '[0].types')
    const countries = map(countryCodes, (countryCode) => get(countryNameByCountryCode, get(countryISO2toISO3, countryCode)))
    const countriesString = join(countries, ', ')
    const birthdatesCount = size(birthdates)
    const birthdateString = birthdatesCount > 1 ? `Birth Year: ${get(birthdates, '[0]')} (+${birthdatesCount - 1} more)` : `Birth Year: ${get(birthdates, '[0]', 'N/A')}`
    const mediaCount = size(media)

    return {
      name,
      mediaCount,
      birthdates,
      birthdateString,
      categories,
      countries,
      countriesString,
    }
  })

  return {
    credentials,
    personaReportId,
    hitsCount,
    hitsContent,
    viewDetailsPath,
    isStandaloneUnderwriting,
  }
}

class AdverseMediaReportC extends Component {
  render() {
    return (
      <AdverseMediaReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AdverseMediaReportC)
