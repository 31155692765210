import get from 'lodash/get'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_SELF_SERVICE_EMAIL_VERIFICATION_F_REQUEST } from 'constants/flowConstants'
import {
  SELF_SERVICE_EMAIL_VERIFICATION_ATTEMPTED,
  SELF_SERVICE_EMAIL_VERIFICATION_FAILED,
  SELF_SERVICE_EMAIL_VERIFICATION_SUCCEEDED,
} from 'constants/amplitudeConstants'

const postSelfServiceEmailVerificationRequest = ({
  dashboardSandboxId,
}) => createAction({
  type: POST_SELF_SERVICE_EMAIL_VERIFICATION_F_REQUEST,
  values: {
    id: dashboardSandboxId,
  },
  meta: {
    actionId: sendAmplitudeActionEvent(SELF_SERVICE_EMAIL_VERIFICATION_ATTEMPTED, {
      dashboardSandboxId,
    }),
    successCallback: ({ newValues }) => {
      const email = get(newValues, 'emailAddress')

      sendAmplitudeActionEvent(SELF_SERVICE_EMAIL_VERIFICATION_SUCCEEDED, {
        email,
        dashboardSandboxId,
      })
    },
    errorCallback: () => {
      sendAmplitudeActionEvent(SELF_SERVICE_EMAIL_VERIFICATION_FAILED, {
        dashboardSandboxId,
      })
    },
    showErrors: false,
  },
})

export default postSelfServiceEmailVerificationRequest
