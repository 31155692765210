import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANT,
  MERCHANTS,
} from 'constants/apiConstants'

const getMerchantAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: MERCHANT({ merchantId: id }),
    credentials,
    service: MERCHANTS,
  })
}

export default getMerchantAPI
