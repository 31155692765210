import { GET_PURCHASE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

// TODO: Add unit tests
const getPurchaseRequest = ({
  purchaseId,
  credentials,
}) => createAction({
  type: GET_PURCHASE_F_REQUEST,
  id: purchaseId,
  credentials,
})

export default getPurchaseRequest
