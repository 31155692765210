import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CUSTOMIZATIONS } from 'constants/apiConstants'

const getDashboardCustomizationsAPI = ({ meta, queries }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_CUSTOMIZATIONS,
    meta,
    queries,
  })
}

export default getDashboardCustomizationsAPI
