import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_REVIEW_RUN } from 'constants/apiConstants'

const getUnderwritingReviewRunAPI = ({
  reviewId,
  runId,
  credentials,
  queries,
  meta,
}) => {
  return paymentsAPI.get({
    path: UNDERWRITING_REVIEW_RUN({ reviewId, runId }),
    credentials,
    queries,
    meta,
  })
}

export default getUnderwritingReviewRunAPI
