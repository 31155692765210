import './EditApplicationPayoutConfigsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import MultiSelectField from 'components/Shared/Inputs/MultiSelectField/MultiSelectField'
import Button from 'components/Shared/Button/Button'
import submitEditPayoutConfigsForm from 'utilities/submit/submitEditPayoutConfigsForm'
import validateSubmitEditPayoutConfigsForm from 'utilities/validate/validateSubmitEditPayoutConfigsForm'
import { EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM } from 'constants/formConstants'

import {
  currenciesListOptions,
  visaAllowedBusinessApplicationIdsSelectOptions,
} from 'constants/applicationProcessorConfigsConstants'

import {
  CANCEL,
  CARD_ACCEPTOR_ID_CODE,
  CARD_ACCEPTOR_TERMINAL_ID,
  DEFAULT_CURRENCIES,
  DEFAULT_MCC,
  DEFAULT_PAYMENT_TYPE,
  DEFAULT_SENDER_ACCOUNT_NUMBER,
  DEFAULT_SENDER_ADDRESS_LINE_1,
  DEFAULT_SENDER_ADDRESS_LINE_2,
  DEFAULT_SENDER_CITY,
  DEFAULT_SENDER_COUNTRY,
  DEFAULT_SENDER_COUNTRY_CODE,
  DEFAULT_SENDER_FIRST_NAME,
  DEFAULT_SENDER_FULL_NAME,
  DEFAULT_SENDER_LAST_NAME,
  DEFAULT_SENDER_POSTAL_CODE,
  MC_DEFAULT_STATEMENT_DESCRIPTOR,
  SAVE,
  VISA_ALLOWED_BUSINESS_APPLICATION_IDS,
  VISA_CARD_ACCEPTOR_ID_CODE,
  VISA_DEFAULT_SENDER_ADDRESS,
  VISA_DEFAULT_SENDER_CITY,
  VISA_DEFAULT_SENDER_STATE_CODE,
  VISA_DEFAULT_SENDER_ZIP_CODE,
  VISA_DEFAULT_SENDER_NAME,
  DEFAULT_SENDER_SUBDIVISION,
} from 'constants/language/languageConstants'

const EditApplicationPayoutConfigsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  disabled = true,
  isVisa,
  isMastercard,
  isRequired = true,
  isPatching = false,
  invalid = false,
  pristine = false,
}) => {
  return (
    <form className='EditApplicationPayoutConfigsForm' onSubmit={handleSubmit(submitEditPayoutConfigsForm)}>
      { isVisa && (
        <div className='modal-content'>
          <MultiSelectField
            label={VISA_ALLOWED_BUSINESS_APPLICATION_IDS}
            name='visaAllowedBusinessApplicationIds'
            options={visaAllowedBusinessApplicationIdsSelectOptions}
            hideSelectedOptions={false}
            isRequired={isRequired}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? option.label : option.value
            }}
          />

          <div className='visa-acceptor-info flex space-between'>
            <Field
              label={VISA_CARD_ACCEPTOR_ID_CODE}
              name='visaCardAcceptorIdCode'
              component={InputField}
            />

            <Field
              label={CARD_ACCEPTOR_TERMINAL_ID}
              name='visaAcceptorTerminalId'
              component={InputField}
            />
          </div>

          <MultiSelectField
            label={DEFAULT_CURRENCIES}
            isRequired={isRequired}
            name='visaDefaultCurrencies'
            options={currenciesListOptions}
            hideSelectedOptions={false}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? option.label : option.value
            }}
          />

          <Field
            label={DEFAULT_MCC}
            name='visaDefaultMcc'
            disabled={disabled}
            component={InputField}
          />

          <Field
            label={VISA_DEFAULT_SENDER_NAME}
            name='visaDefaultSenderName'
            component={InputField}
          />

          <Field
            label={DEFAULT_SENDER_ACCOUNT_NUMBER}
            name='visaDefaultSenderAccountNumber'
            placeholder='123456789'
            component={InputField}
            disabled={disabled}
          />

          <Field
            label={VISA_DEFAULT_SENDER_ADDRESS}
            name='visaDefaultSenderAddress'
            component={InputField}
          />

          <Field
            label={VISA_DEFAULT_SENDER_CITY}
            name='visaDefaultSenderCity'
            component={InputField}
          />

          <div className='visa-state-zip flex space-between'>
            <Field
              label={VISA_DEFAULT_SENDER_STATE_CODE}
              name='visaDefaultSenderStateCode'
              component={InputField}
            />

            <Field
              label={VISA_DEFAULT_SENDER_ZIP_CODE}
              name='visaDefaultSenderZipCode'
              component={InputField}
            />
          </div>

          <div className='visa-country flex space-between'>
            <Field
              label={DEFAULT_SENDER_COUNTRY_CODE}
              name='visaDefaultSenderCountryCode'
              component={InputField}
            />

            <Field
              label={DEFAULT_SENDER_COUNTRY}
              name='visaDefaultSenderCountry'
              component={InputField}
            />
          </div>
        </div>
      )}

      { isMastercard && (
        <div className='modal-content'>
          <div className='mc-card-acceptor-terminal-info flex flex end'>
            <Field
              label={CARD_ACCEPTOR_ID_CODE}
              name='mcCardAcceptorId'
              component={InputField}
            />

            <Field
              label={DEFAULT_PAYMENT_TYPE}
              name='mcDefaultPaymentType'
              component={InputField}
            />
          </div>

          <MultiSelectField
            label={DEFAULT_CURRENCIES}
            isRequired={isRequired}
            name='mcDefaultCurrencies'
            options={currenciesListOptions}
            component={InputField}
            hideSelectedOptions={false}
            formatOptionLabel={(option, { context }) => {
              return context === 'menu' ? option.label : option.value
            }}
          />

          <Field
            label={DEFAULT_MCC}
            name='mcDefaultMcc'
            component={InputField}
            disabled={disabled}
          />

          <Field
            label={DEFAULT_SENDER_ACCOUNT_NUMBER}
            name='mcDefaultSenderAccountNumber'
            component={InputField}
            disabled={disabled}
          />

          <Field
            label={MC_DEFAULT_STATEMENT_DESCRIPTOR}
            name='mcStatementDescriptor'
            component={InputField}
          />

          <div className='mc-name-info flex flex end'>
            <Field
              label={DEFAULT_SENDER_FIRST_NAME}
              name='mcDefaultSenderFirstName'
              component={InputField}
            />

            <Field
              label={DEFAULT_SENDER_LAST_NAME}
              name='mcDefaultSenderLastName'
              component={InputField}
            />
          </div>

          <Field
            label={DEFAULT_SENDER_FULL_NAME}
            name='mcDefaultSenderFullName'
            component={InputField}
          />

          <Field
            label={DEFAULT_SENDER_ADDRESS_LINE_1}
            name='mcDefaultSenderAddressLine1'
            component={InputField}
          />

          <Field
            label={DEFAULT_SENDER_ADDRESS_LINE_2}
            name='mcDefaultSenderAddressLine2'
            component={InputField}
          />

          <div className='mc-city-postal flex flex-end'>
            <Field
              label={DEFAULT_SENDER_CITY}
              name='mcDefaultSenderCity'
              component={InputField}
            />

            <Field
              label={DEFAULT_SENDER_POSTAL_CODE}
              name='mcDefaultSenderPostalCode'
              component={InputField}
            />
          </div>

          <div className='mc-country-info flex flex-end'>
            <Field
              label={DEFAULT_SENDER_COUNTRY}
              name='mcDefaultSenderCountry'
              component={InputField}
            />

            <Field
              label={DEFAULT_SENDER_COUNTRY_CODE}
              name='mcDefaultSenderCountryCode'
              component={InputField}
            />
          </div>

          <Field
            label={DEFAULT_SENDER_SUBDIVISION}
            name='mcSubdivision'
            component={InputField}
          />
        </div>
      )}
      <div className='sticky-footer'>
        <div className='buttons flex flex-end'>
          <Button variant='ghost' className='cancel-button' onClick={closeModal} label={CANCEL} />
          <Button type='submit' label={SAVE} disabled={isPatching || invalid || pristine} />
        </div>
      </div>
    </form>
  )
}

EditApplicationPayoutConfigsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  isVisa: PropTypes.bool,
  isMastercard: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isPatching: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM,
  validate: validateSubmitEditPayoutConfigsForm,
})(EditApplicationPayoutConfigsForm)
