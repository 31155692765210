import {
  SELECT_ITEMS,
  UNSELECT_ITEMS,
} from 'constants/flowConstants'

const selectItemsHandler = ({ checked, selectedItemsKey, itemId }) => {
  return {
    type: !checked ? SELECT_ITEMS : UNSELECT_ITEMS,
    payload: {
      key: selectedItemsKey,
      ids: [itemId],
    },
  }
}

export default selectItemsHandler
