import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import CreateTransactionConfirmation from './CreateTransactionConfirmation'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getAuthorizationRequest from 'utilities/actions/get/getAuthorizationRequest'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getDeviceRequest from 'utilities/actions/get/getDeviceRequest'
import getReceiptRequest from 'utilities/actions/get/getReceiptRequest'
import getUrlQuery from 'utilities/get/getUrlQuery'
import formatMoney from 'utilities/formatters/formatMoney'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import copyToClipboard from 'utilities/copyToClipboard'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { COPY } from 'constants/iconConstants'
import { FETCHING } from 'constants/reducerConstants'
import { getCardBrand } from 'constants/bankConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  getAuthorizationSelector,
  getIdentitySelector,
  getPaymentInstrumentSelector,
  getTransferSelector,
  getDeviceSelector,
  getReceiptUrlById,
  getReceiptSelector,
} from 'state-layer/selectors'

import {
  AUTHORIZATION_PATH,
  PAYMENT_PATH,
} from 'constants/pathConstants'

import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  AUTHORIZATION_ID,
  BANK_CODE,
  BILLING_ADDRESS,
  BUSINESS_ADDRESS,
  BUYER_NAME,
  COUNTRY,
  CREATED_ON,
  CUSTOMER_REFERENCE_NUMBER,
  CUSTOMS_DUTY_AMOUNT,
  DESCRIPTION,
  DESTINATION_POSTAL_CODE,
  EMAIL,
  EXPIRATION_DATE,
  NAME_ON_CARD,
  PAYMENT_ID,
  PHONE,
  SALES_TAX_AMOUNT,
  SHIP_FROM_POSTAL_CODE,
  STATEMENT_DESCRIPTOR,
  TOTAL_DISCOUNT_AMOUNT,
  TOTAL_SHIPPING_AMOUNT,
  TOTAL_AMOUNT,
  ZIP_POSTAL_CODE,
  DEVICE_ID,
  DEVICE_NAME,
  SERIAL_NUMBER,
  CARD_NUMBER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingTransferData = get(state, `transfersR.${FETCHING}`)
  const isFetchingMerchantData = get(state, `merchantsR.${FETCHING}`)
  const isFetchingDeviceData = get(state, `devicesR.${FETCHING}`)
  const isFetchingReceiptData = get(state, `receiptsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isSale = getUrlQuery('type') === 'sale'
  const receiptId = getUrlQuery('receiptId')
  const receipt = getReceiptSelector(state, receiptId)
  const deviceId = getUrlQuery('deviceId')
  const entityId = get(props, 'params.transferId')
  const entity = isSale ? getTransferSelector(state, entityId) : getAuthorizationSelector(state, entityId)
  const receiptUrl = getReceiptUrlById(state, receiptId)
  const path = isSale ? PAYMENT_PATH({ credentialId, transferId: entityId }) : AUTHORIZATION_PATH({ credentialId, authorizationId: entityId })
  const device = getDeviceSelector(state, deviceId)

  const [
    amount,
    tags,
    displayCreatedAt,
    merchantIdentityId,
    buyerIdentity,
    paymentInstrumentId,
    displayFailureCode,
    failureMessage,
    additionalPurchaseData,
    isLevelTwoEnabled,
    isLevelThreeEnabled,
  ] = getMany(entity, [
    'amount',
    'tags',
    'displayCreatedAt',
    'merchantIdentityId',
    'buyerIdentity',
    'paymentInstrumentId',
    'displayFailureCode',
    'failureMessage',
    'additionalPurchaseData',
    'isLevelTwoEnabled',
    'isLevelThreeEnabled',
  ])

  const [
    displaySalesTax,
    customerReferenceNumber,
    destinationPostalCode,
    shipFromPostalCode,
    displayShippingAmount,
    displayDiscountAmount,
    displayCustomsDutyAmount,
    itemData,
  ] = getMany(additionalPurchaseData, [
    'displaySalesTax',
    'customerReferenceNumber',
    'destinationPostalCode',
    'shipFromPostalCode',
    'displayShippingAmount',
    'displayDiscountAmount',
    'displayCustomsDutyAmount',
    'itemData',
  ])

  const transferDescription = get(tags, 'description')
  const merchantIdentity = getIdentitySelector(state, merchantIdentityId)
  const merchantIdentityDoingBusinessAs = get(merchantIdentity, 'displayBusinessName')
  const merchantIdentityBusinessAddress = get(merchantIdentity, 'businessAddress')
  const buyerPaymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)

  const [
    identityId,
    buyerFullName,
    buyerEmail,
    buyerPhone,
  ] = getMany(buyerIdentity, [
    'id',
    'fullName',
    'email',
    'displayPhone',
  ])

  const [
    name,
    cardBrand,
    maskedFullCardNumber,
    expirationDate,
    address,
    cardType,
    bankAccountCountry,
    maskedAccountNumber,
    bankCode,
    displayAccountType,
  ] = getMany(buyerPaymentInstrument, [
    'name',
    'brand',
    'maskedFullCardNumber',
    'expirationDate',
    'address',
    'cardType',
    'displayCountry',
    'maskedAccountNumber',
    'bankCode',
    'displayAccountType',
  ])

  const cardBrandIcon = getCardBrand(cardBrand)

  const [
    line1,
    postalCode,
    country,
  ] = getMany(address, [
    'line1',
    'postalCode',
    'country',
  ])

  const purchaseDetailsSectionData = convertPageSectionDataToV2([
    {
      label: TOTAL_AMOUNT,
      value: formatMoney({ amount }),
    },
    {
      label: DESCRIPTION,
      value: transferDescription,
    },
    {
      label: STATEMENT_DESCRIPTOR,
      value: merchantIdentityDoingBusinessAs,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={merchantIdentityBusinessAddress} />,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: isSale ? PAYMENT_ID : AUTHORIZATION_ID,
      value: <span><Link className='text-link' to={path}>{entityId}</Link> <i className={`fas fa-${COPY}`} onClick={() => copyToClipboard(entityId)} /></span>,
    },
  ], 1)

  const paymentInstrumentSectionData = convertPageSectionDataToV2([
    {
      label: BUYER_NAME,
      value: buyerFullName,
    },
    {
      label: EMAIL,
      value: buyerEmail,
    },
    {
      label: PHONE,
      value: buyerPhone,
    },
    {
      label: NAME_ON_CARD,
      value: name,
      condition: !!maskedFullCardNumber,
    },
    {
      label: CARD_NUMBER,
      value: <div className='flex items-center'><i className={cardBrandIcon} /><div>&nbsp;{maskedFullCardNumber}</div></div>,
      condition: !!maskedFullCardNumber,
    },
    {
      label: EXPIRATION_DATE,
      value: expirationDate,
      condition: !!maskedFullCardNumber,
    },
    {
      label: ZIP_POSTAL_CODE,
      value: postalCode,
      condition: !!maskedFullCardNumber,
    },
    {
      label: COUNTRY,
      value: country,
      condition: !isEmpty(line1),
    },
    {
      label: BILLING_ADDRESS,
      value: <Address address={address} />,
      condition: !isEmpty(line1),
    },
    {
      label: ACCOUNT_HOLDER_NAME,
      value: name,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: maskedAccountNumber,
      condition: !!maskedAccountNumber,
    },
    {
      label: BANK_CODE,
      value: bankCode,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_TYPE,
      value: displayAccountType,
      condition: !!maskedAccountNumber,
    },
    {
      label: COUNTRY,
      value: bankAccountCountry,
      condition: !!maskedAccountNumber,
    },
  ], 1)

  const additionalDataSectionData = convertPageSectionDataToV2([
    {
      label: SALES_TAX_AMOUNT,
      value: displaySalesTax,
      condition: isLevelTwoEnabled,
    },
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
      condition: isLevelTwoEnabled || isLevelThreeEnabled,
    },
    {
      label: DESTINATION_POSTAL_CODE,
      value: destinationPostalCode,
      condition: isLevelThreeEnabled,
    },
    {
      label: SHIP_FROM_POSTAL_CODE,
      value: shipFromPostalCode,
      condition: isLevelThreeEnabled,
    },
    {
      label: TOTAL_SHIPPING_AMOUNT,
      value: displayShippingAmount,
      condition: isLevelThreeEnabled,
    },
    {
      label: TOTAL_DISCOUNT_AMOUNT,
      value: displayDiscountAmount,
      condition: isLevelThreeEnabled,
    },
    {
      label: CUSTOMS_DUTY_AMOUNT,
      value: displayCustomsDutyAmount,
      condition: isLevelThreeEnabled,
    },
  ], 1)

  const [
    deviceName,
    serialNumber,
  ] = getMany(device, [
    'name',
    'serialNumber',
  ])

  const deviceDataSectionData = convertPageSectionDataToV2([
    {
      label: DEVICE_ID,
      value: deviceId,
    },
    {
      label: DEVICE_NAME,
      value: deviceName,
    },
    {
      label: SERIAL_NUMBER,
      value: serialNumber,
    },
  ], 1)

  const showDeviceDataSection = !!deviceId

  return {
    credentials,
    credentialId,
    amount,
    transferDescription,
    entityId,
    identityId,
    merchantIdentityId,
    paymentInstrumentId,
    purchaseDetailsSectionData,
    paymentInstrumentSectionData,
    displayFailureCode,
    failureMessage,
    isFetchingTransferData,
    isFetchingMerchantData,
    additionalDataSectionData,
    isLevelTwoEnabled,
    isLevelThreeEnabled,
    itemData,
    isSale,
    deviceId,
    device,
    deviceDataSectionData,
    showDeviceDataSection,
    isFetchingDeviceData,
    isFetchingReceiptData,
    receiptUrl,
    receiptId,
    receipt,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ transferId, credentials }) => { dispatch(getTransferRequest({ transferId, credentials })) },
    getAuthorization: ({ authorizationId, credentials }) => { dispatch(getAuthorizationRequest({ authorizationId, credentials })) },
    getMerchantIdentity: ({ merchantIdentityId, credentials }) => dispatch(getIdentityRequest({ identityId: merchantIdentityId, credentials })),
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })),
    getDevice: ({ deviceId, credentials }) => dispatch(getDeviceRequest({ deviceId, credentials })),
    getReceipt: ({ receiptId, credentials }) => dispatch(getReceiptRequest({ receiptId, credentials })),
  }
}

class CreateTransactionConfirmationC extends Component {
  componentDidMount() {
    const {
      credentials,
      entityId,
      getTransfer,
      getAuthorization,
      isSale,
      deviceId,
      getDevice,
      receiptId,
      getReceipt,
      receipt,
    } = this.props

    if (isSale) {
      getTransfer({ transferId: entityId, credentials })
    } else {
      getAuthorization({ authorizationId: entityId, credentials })
    }

    if (deviceId) {
      getDevice({ deviceId, credentials })
    }

    if (receiptId && isEmpty(receipt)) {
      getReceipt({ receiptId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      merchantIdentityId: prevMerchantIdentityId,
      paymentInstrumentId: prevPaymentInstrumentId,
      receiptId: prevReceiptId,
    } = prevProps

    const {
      credentials,
      merchantIdentityId,
      paymentInstrumentId,
      getMerchantIdentity,
      getPaymentInstrument,
      receiptId,
      getReceipt,
      receipt,
    } = this.props

    if (!prevMerchantIdentityId && merchantIdentityId) {
      getMerchantIdentity({ merchantIdentityId, credentials })
    }

    if (notRoleMerchant({ credentials }) && !prevPaymentInstrumentId && paymentInstrumentId) {
      getPaymentInstrument({ paymentInstrumentId, credentials })
    }

    if (!prevReceiptId && receiptId) {
      getReceipt({ receiptId, credentials })
    }
  }

  render() {
    return (
      <CreateTransactionConfirmation
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionConfirmationC)
