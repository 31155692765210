import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditContinuousMonitoringSettingsForm from './EditContinuousMonitoringSettingsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import { CONTINUOUS_MONITORING_LABELS } from 'constants/underwritingProfileConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const adverseMediaCadence = get(props, 'underwritingProfile.adverseMediaCadence')
  const sanctionsScreeningCadence = get(props, 'underwritingProfile.sanctionsScreeningCadence')

  return {
    initialValues: {
      adverseMediaReviewCadence: {
        value: adverseMediaCadence,
        label: CONTINUOUS_MONITORING_LABELS[adverseMediaCadence],
      },
      sanctionsScreeningCadence: {
        value: sanctionsScreeningCadence,
        label: CONTINUOUS_MONITORING_LABELS[sanctionsScreeningCadence],
      },
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditContinuousMonitoringSettingsFormC extends Component {
  render() {
    return (
      <EditContinuousMonitoringSettingsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContinuousMonitoringSettingsFormC)
