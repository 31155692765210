import { GET_GUEST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestDashboardCustomizationsRequest = ({
  queries,
  meta,
}) => createAction({
  type: GET_GUEST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
  queries,
  meta,
})

export default getGuestDashboardCustomizationsRequest
