import { INVALID_EMAIL, INVALID_EMAIL_LIST } from 'constants/language/languageConstants'
import { EMAIL_REGEX } from 'constants/regexConstants'
import { every, size } from 'lodash'

const validateEmailList = (emails) => {
  if (!Array.isArray(emails) || size(emails) <= 0) {
    return INVALID_EMAIL_LIST
  }

  const allValid = every(emails, ({ value: email }) => email && EMAIL_REGEX.test(email))
  if (!allValid) {
    return INVALID_EMAIL
  }

  return false
}

const validateCreateTransactionReviewForm = (values) => {
  const { sendReceiptToBuyer, buyerEmails } = values

  return {
    buyerEmails: sendReceiptToBuyer && validateEmailList(buyerEmails),
  }
}

export default validateCreateTransactionReviewForm
