import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreateAdjustmentForm = (values) => {
  const {
    adjustmentType,
    paymentInstrumentId,
    merchantId,
    amount,
  } = values

  return {
    adjustmentType: fieldEmpty(adjustmentType, 'Adjustment Type'),
    paymentInstrumentId: fieldEmpty(paymentInstrumentId, 'Payment Instrument'),
    merchantId: fieldEmpty(merchantId, 'Merchant'),
    amount: nonEmptyNumber({ field: amount, name: 'Amount' }),
  }
}

export default validateCreateAdjustmentForm
