import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'

const RequireTeamMFAModal = ({
  closeModal = () => {},
  submitMFAEnabled = () => {},
  patching = false,
  mfaEnabled = false,
}) => {
  const title = mfaEnabled ? 'Require MFA for your team?' : 'Remove MFA requirement for all members?'
  const info = mfaEnabled ? 'All members of your team will have to use multi-factor authentication to sign in.' : 'This will allow all members of your team to sign in without multi-factor authentication.'

  return (
    <GenericModal
      title={title}
      className='RequireTeamMFAModal'
    >
      <div className='modal-content'>
        <p className='p-1'>{info}</p>
      </div>

      <div className='buttons flex flex-end mtl'>
        <Button onClick={closeModal} label='Cancel' variant='ghost' className='cancel-button' disabled={patching} />
        <Button type='submit' label='Confirm' onClick={submitMFAEnabled} disabled={patching} />
      </div>
    </GenericModal>
  )
}

RequireTeamMFAModal.propTypes = {
  closeModal: PropTypes.func,
  submitMFAEnabled: PropTypes.func,
  patching: PropTypes.bool,
  mfaEnabled: PropTypes.bool,
}

export default RequireTeamMFAModal
