import { GET_BALANCE_ADJUSTMENT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getBalanceAdjustmentRequest = ({
  balanceAdjustmentId,
  credentials,
}) => createAction({
  type: GET_BALANCE_ADJUSTMENT_F_REQUEST,
  id: balanceAdjustmentId,
  credentials,
})

export default getBalanceAdjustmentRequest
