import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ArchiveSubscriptionPlanFormC from 'components/Customer/Forms/ArchiveSubscriptionPlanForm/ArchiveSubscriptionPlanFormC'
import { ARCHIVE_STANDARD_PLAN } from 'constants/language/languageConstants'

const ArchiveSubscriptionPlanModal = ({
  subscriptionPlanId = '',
}) => {
  return (
    <GenericModal
      title={ARCHIVE_STANDARD_PLAN}
      className='ArchiveSubscriptionPlanModal'
      Component={ArchiveSubscriptionPlanFormC}
      subscriptionPlanId={subscriptionPlanId}
    />
  )
}

ArchiveSubscriptionPlanModal.propTypes = {
  subscriptionPlanId: PropTypes.string,
}

export default ArchiveSubscriptionPlanModal
