import './SubscriptionSchedulesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getSubscriptionSchedulesSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { GET_SUBSCRIPTION_SCHEDULES_AMOUNTS } from 'constants/flowConstants'
import { SUBSCRIPTION_SCHEDULES } from 'constants/linkConstants'
import { SUBSCRIPTION_BILLING_ICON } from 'constants/iconConstants'
import { SUBSCRIPTION_SCHEDULES_RESOURCE_TITLE } from 'constants/language/languageConstants'

const SubscriptionSchedules = ({
  allowedFilters,
  subscriptionSchedulePath = '',
  isFetching = false,
  actions = [],
}) => {
  return (
    <div className='SubscriptionSchedules'>
      <TableC
        actions={actions}
        flow={GET_SUBSCRIPTION_SCHEDULES_AMOUNTS}
        columnDescriptors={columnDescriptors}
        selector={getSubscriptionSchedulesSelector}
        linksKey={SUBSCRIPTION_SCHEDULES}
        allowedFilters={allowedFilters}
        path={subscriptionSchedulePath}
        isFetching={isFetching}
        icon={`fa fa-${SUBSCRIPTION_BILLING_ICON}`}
        fileNameCSV='Subscription_Schedules'
        fileResourceTitleCSV={SUBSCRIPTION_SCHEDULES_RESOURCE_TITLE}
        emptyMessage='No Subscription Schedules Available'
      />
    </div>
  )
}

SubscriptionSchedules.propTypes = {
  allowedFilters: PropTypes.array,
  subscriptionSchedulePath: PropTypes.string,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
}

export default SubscriptionSchedules
