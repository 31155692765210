import createAction from 'utilities/actions/createAction'
import { GET_SPLIT_TRANSFER_SETTLEMENT_F_REQUEST } from 'constants/flowConstants'

const getSplitTransferSettlementRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_SPLIT_TRANSFER_SETTLEMENT_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getSplitTransferSettlementRequest
