import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPaymentLinksSelector } from 'state-layer/selectors'
import PaymentLinkConfirmation from 'components/Customer/Pages/PaymentLinkConfirmation/PaymentLinkConfirmation'
import getGuestCheckoutTokenVerificationRequest from 'utilities/actions/get/getGuestCheckoutTokenVerificationRequest'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { GET_GUEST_CHECKOUT_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import { LOAD_PAYMENT_LINK_CONFIRMATION } from 'constants/amplitudeConstants'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const paymentLinks = getPaymentLinksSelector(state)
  const paymentLinkId = head(Object.keys(paymentLinks))
  const paymentLink = get(paymentLinks, paymentLinkId, {})
  const status = getUrlQuery('status')
  const failureCode = getUrlQuery('failureCode')

  return {
    status,
    failureCode,
    paymentLink,
    isFetching: isEmpty(paymentLink),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestCheckoutPaymentLink: ({ paymentLinkId }) => {
      dispatch({
        type: GET_GUEST_CHECKOUT_PAYMENT_LINK_F_REQUEST,
        payload: {
          id: paymentLinkId,
        },
        meta: {
          actionId: sendAmplitudeActionEvent(LOAD_PAYMENT_LINK_CONFIRMATION, {
            paymentLinkId,
          }),
        },
      })
    },
    getHostedFormTokenVerification: ({ meta }) => { dispatch(getGuestCheckoutTokenVerificationRequest({ meta })) },
  }
}

class PaymentLinkConfirmationC extends Component {
  componentDidMount() {
    const {
      getGuestCheckoutPaymentLink,
      getHostedFormTokenVerification,
    } = this.props

    // verify bearer token from url
    getHostedFormTokenVerification({
      meta: {
        successCallback: ({ newValues: tokenResponse }) => {
          const entityId = get(tokenResponse, 'entity_id')

          if (entityId) {
            getGuestCheckoutPaymentLink({ paymentLinkId: entityId })
          }
        },
        errorCallback: () => {
          // there was an error validating the token, take to some error page
        },
      },
    })
  }

  render() {
    return (
      <PaymentLinkConfirmation {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkConfirmationC)
