import React from 'react'
import './DateRadioButtonsS.scss'

// DEPRECATED: This component is deprecated and not being used anywhere. Can be deleted.
const DateRadioButtons = ({ input }) => {
  return (
    <div className='DateRadioButtons'>
      <label key='day' className='radio-input'>
        <input
          id='today'
          type='radio'
          {...input}
          value='today'
          checked={input.value === 'today'}
        />

        <div className='fas fa-calendar-day' aria-label='day' />
      </label>

      <label key='week' className='radio-input'>
        <input
          id='week'
          type='radio'
          {...input}
          value='week'
          checked={input.value === 'week'}
        />

        <div className='fas fa-calendar-week' />
      </label>

      <label key='month' className='radio-input'>
        <input
          id='month'
          type='radio'
          {...input}
          value='month'
          checked={input.value === 'month'}
        />

        <div className='fas fa-calendar-alt' />
      </label>
    </div>
  )
}

export default DateRadioButtons
