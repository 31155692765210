import {
  US,
  USA,
  CAN,
} from 'constants/countryConstants'

export const AED = 'AED'
export const AFN = 'AFN'
export const ALL = 'ALL'
export const AMD = 'AMD'
export const ANG = 'ANG'
export const AOA = 'AOA'
export const ARS = 'ARS'
export const AUD = 'AUD'
export const AWG = 'AWG'
export const AZN = 'AZN'
export const BAM = 'BAM'
export const BBD = 'BBD'
export const BDT = 'BDT'
export const BGN = 'BGN'
export const BHD = 'BHD'
export const BIF = 'BIF'
export const BMD = 'BMD'
export const BND = 'BND'
export const BOB = 'BOB'
export const BRL = 'BRL'
export const BSD = 'BSD'
export const BTC = 'BTC'
export const BTN = 'BTN'
export const BWP = 'BWP'
export const BYR = 'BYR'
export const BYN = 'BYN'
export const BZD = 'BZD'
export const CAD = 'CAD'
export const CDF = 'CDF'
export const CHF = 'CHF'
export const CLP = 'CLP'
export const CNY = 'CNY'
export const COP = 'COP'
export const CRC = 'CRC'
export const CUC = 'CUC'
export const CUP = 'CUP'
export const CVE = 'CVE'
export const CZK = 'CZK'
export const DJF = 'DJF'
export const DKK = 'DKK'
export const DOP = 'DOP'
export const DZD = 'DZD'
export const EEK = 'EEK'
export const EGP = 'EGP'
export const ERN = 'ERN'
export const ETB = 'ETB'
export const ETH = 'ETH'
export const EUR = 'EUR'
export const FJD = 'FJD'
export const FKP = 'FKP'
export const GBP = 'GBP'
export const GEL = 'GEL'
export const GGP = 'GGP'
export const GHC = 'GHC'
export const GHS = 'GHS'
export const GIP = 'GIP'
export const GMD = 'GMD'
export const GNF = 'GNF'
export const GTQ = 'GTQ'
export const GYD = 'GYD'
export const HKD = 'HKD'
export const HNL = 'HNL'
export const HRK = 'HRK'
export const HTG = 'HTG'
export const HUF = 'HUF'
export const IDR = 'IDR'
export const ILS = 'ILS'
export const IMP = 'IMP'
export const INR = 'INR'
export const IQD = 'IQD'
export const IRR = 'IRR'
export const ISK = 'ISK'
export const JEP = 'JEP'
export const JMD = 'JMD'
export const JOD = 'JOD'
export const JPY = 'JPY'
export const KES = 'KES'
export const KGS = 'KGS'
export const KHR = 'KHR'
export const KMF = 'KMF'
export const KPW = 'KPW'
export const KRW = 'KRW'
export const KWD = 'KWD'
export const KYD = 'KYD'
export const KZT = 'KZT'
export const LAK = 'LAK'
export const LBP = 'LBP'
export const LKR = 'LKR'
export const LRD = 'LRD'
export const LSL = 'LSL'
export const LTC = 'LTC'
export const LTL = 'LTL'
export const LVL = 'LVL'
export const LYD = 'LYD'
export const MAD = 'MAD'
export const MDL = 'MDL'
export const MGA = 'MGA'
export const MKD = 'MKD'
export const MMK = 'MMK'
export const MNT = 'MNT'
export const MOP = 'MOP'
export const MRO = 'MRO'
export const MRU = 'MRU'
export const MUR = 'MUR'
export const MVR = 'MVR'
export const MWK = 'MWK'
export const MXN = 'MXN'
export const MYR = 'MYR'
export const MZN = 'MZN'
export const NAD = 'NAD'
export const NGN = 'NGN'
export const NIO = 'NIO'
export const NOK = 'NOK'
export const NPR = 'NPR'
export const NZD = 'NZD'
export const OMR = 'OMR'
export const PAB = 'PAB'
export const PEN = 'PEN'
export const PGK = 'PGK'
export const PHP = 'PHP'
export const PKR = 'PKR'
export const PLN = 'PLN'
export const PYG = 'PYG'
export const QAR = 'QAR'
export const RMB = 'RMB'
export const RON = 'RON'
export const RSD = 'RSD'
export const RUB = 'RUB'
export const RWF = 'RWF'
export const SAR = 'SAR'
export const SBD = 'SBD'
export const SCR = 'SCR'
export const SDG = 'SDG'
export const SEK = 'SEK'
export const SGD = 'SGD'
export const SHP = 'SHP'
export const SLL = 'SLL'
export const SOS = 'SOS'
export const SRD = 'SRD'
export const SSP = 'SSP'
export const STD = 'STD'
export const STN = 'STN'
export const SVC = 'SVC'
export const SYP = 'SYP'
export const SZL = 'SZL'
export const THB = 'THB'
export const TJS = 'TJS'
export const TMT = 'TMT'
export const TND = 'TND'
export const TOP = 'TOP'
export const TRL = 'TRL'
export const TRY = 'TRY'
export const TTD = 'TTD'
export const TVD = 'TVD'
export const TWD = 'TWD'
export const TZS = 'TZS'
export const UAH = 'UAH'
export const UGX = 'UGX'
export const USD = 'USD'
export const UYU = 'UYU'
export const UZS = 'UZS'
export const VEF = 'VEF'
export const VND = 'VND'
export const VUV = 'VUV'
export const WST = 'WST'
export const XAF = 'XAF'
export const XBT = 'XBT'
export const XCD = 'XCD'
export const XOF = 'XOF'
export const XPF = 'XPF'
export const YER = 'YER'
export const ZAR = 'ZAR'
export const ZWD = 'ZWD'

export const COUNTRY_TO_CURRENCY_NAME_MAP = {
  [USA]: USD,
  [CAN]: CAD,
}

export const CURRENCY_TO_COUNTRY_NAME_MAP = {
  [USD]: USA,
  [CAD]: CAN,
}

export const CURRENCY_NAME_TO_SYMBOL_MAP = {
  [AED]: 'د.إ',
  [AFN]: '؋',
  [ALL]: 'L',
  [AMD]: '֏',
  [ANG]: 'ƒ',
  [AOA]: 'Kz',
  [ARS]: '$',
  [AUD]: '$',
  [AWG]: 'ƒ',
  [AZN]: '₼',
  [BAM]: 'KM',
  [BBD]: '$',
  [BDT]: '৳',
  [BGN]: 'лв',
  [BHD]: '.د.ب',
  [BIF]: 'FBu',
  [BMD]: '$',
  [BND]: '$',
  [BOB]: '$b',
  [BRL]: 'R$',
  [BSD]: '$',
  [BTC]: '฿',
  [BTN]: 'Nu.',
  [BWP]: 'P',
  [BYR]: 'Br',
  [BYN]: 'Br',
  [BZD]: 'BZ$',
  [CAD]: '$',
  [CDF]: 'FC',
  [CHF]: 'CHF',
  [CLP]: '$',
  [CNY]: '¥',
  [COP]: '$',
  [CRC]: '₡',
  [CUC]: '$',
  [CUP]: '₱',
  [CVE]: '$',
  [CZK]: 'Kč',
  [DJF]: 'Fdj',
  [DKK]: 'kr',
  [DOP]: 'RD$',
  [DZD]: 'دج',
  [EEK]: 'kr',
  [EGP]: '£',
  [ERN]: 'Nfk',
  [ETB]: 'Br',
  [ETH]: 'Ξ',
  [EUR]: '€',
  [FJD]: '$',
  [FKP]: '£',
  [GBP]: '£',
  [GEL]: '₾',
  [GGP]: '£',
  [GHC]: '₵',
  [GHS]: 'GH₵',
  [GIP]: '£',
  [GMD]: 'D',
  [GNF]: 'FG',
  [GTQ]: 'Q',
  [GYD]: '$',
  [HKD]: '$',
  [HNL]: 'L',
  [HRK]: 'kn',
  [HTG]: 'G',
  [HUF]: 'Ft',
  [IDR]: 'Rp',
  [ILS]: '₪',
  [IMP]: '£',
  [INR]: '₹',
  [IQD]: 'ع.د',
  [IRR]: '﷼',
  [ISK]: 'kr',
  [JEP]: '£',
  [JMD]: 'J$',
  [JOD]: 'JD',
  [JPY]: '¥',
  [KES]: 'KSh',
  [KGS]: 'лв',
  [KHR]: '៛',
  [KMF]: 'CF',
  [KPW]: '₩',
  [KRW]: '₩',
  [KWD]: 'KD',
  [KYD]: '$',
  [KZT]: 'лв',
  [LAK]: '₭',
  [LBP]: '£',
  [LKR]: '₨',
  [LRD]: '$',
  [LSL]: 'M',
  [LTC]: 'Ł',
  [LTL]: 'Lt',
  [LVL]: 'Ls',
  [LYD]: 'LD',
  [MAD]: 'MAD',
  [MDL]: 'lei',
  [MGA]: 'Ar',
  [MKD]: 'ден',
  [MMK]: 'K',
  [MNT]: '₮',
  [MOP]: 'MOP$',
  [MRO]: 'UM',
  [MRU]: 'UM',
  [MUR]: '₨',
  [MVR]: 'Rf',
  [MWK]: 'MK',
  [MXN]: '$',
  [MYR]: 'RM',
  [MZN]: 'MT',
  [NAD]: '$',
  [NGN]: '₦',
  [NIO]: 'C$',
  [NOK]: 'kr',
  [NPR]: '₨',
  [NZD]: '$',
  [OMR]: '﷼',
  [PAB]: 'B/.',
  [PEN]: 'S/.',
  [PGK]: 'K',
  [PHP]: '₱',
  [PKR]: '₨',
  [PLN]: 'zł',
  [PYG]: 'Gs',
  [QAR]: '﷼',
  [RMB]: '￥',
  [RON]: 'lei',
  [RSD]: 'Дин.',
  [RUB]: '₽',
  [RWF]: 'R₣',
  [SAR]: '﷼',
  [SBD]: '$',
  [SCR]: '₨',
  [SDG]: 'ج.س.',
  [SEK]: 'kr',
  [SGD]: '$',
  [SHP]: '£',
  [SLL]: 'Le',
  [SOS]: 'S',
  [SRD]: '$',
  [SSP]: '£',
  [STD]: 'Db',
  [STN]: 'Db',
  [SVC]: '$',
  [SYP]: '£',
  [SZL]: 'E',
  [THB]: '฿',
  [TJS]: 'SM',
  [TMT]: 'T',
  [TND]: 'د.ت',
  [TOP]: 'T$',
  [TRL]: '₤',
  [TRY]: '₺',
  [TTD]: 'TT$',
  [TVD]: '$',
  [TWD]: 'NT$',
  [TZS]: 'TSh',
  [UAH]: '₴',
  [UGX]: 'USh',
  [USD]: '$',
  [UYU]: '$U',
  [UZS]: 'лв',
  [VEF]: 'Bs',
  [VND]: '₫',
  [VUV]: 'VT',
  [WST]: 'WS$',
  [XAF]: 'FCFA',
  [XBT]: 'Ƀ',
  [XCD]: '$',
  [XOF]: 'CFA',
  [XPF]: '₣',
  [YER]: '﷼',
  [ZAR]: 'R',
  [ZWD]: 'Z$',
}

export const currenciesWithCents = [USD, AUD, CAD, NZD, SGD, HKD]
