import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  WEBHOOK,
  WEBHOOKS,
} from 'constants/apiConstants'

const patchWebhookAPI = ({ id, values, credentials, meta }) => {
  const path = WEBHOOK({ webhookId: id })

  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path,
    service: WEBHOOKS,
  })
}

export default patchWebhookAPI
