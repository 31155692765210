import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_GUEST_ACCESS_FORM_FILE } from 'constants/apiConstants'
import get from 'lodash/get'

const getGuestAccessFormFileAPI = ({ id, values, meta }) => {
  const accessFormId = get(values, 'accessFormId')

  return guestDashboardAPI.get({
    meta,
    path: DASHBOARD_GUEST_ACCESS_FORM_FILE({ fileId: id, accessFormId }),
  })
}

export default getGuestAccessFormFileAPI
