import { GET_ROLE_MEMBERS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getRoleMembersRequest = ({
  roleId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_ROLE_MEMBERS_F_REQUEST,
  id: roleId,
  credentials,
  queries,
  meta,
})

export default getRoleMembersRequest
