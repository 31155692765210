import paymentsAPI from 'utilities/api/paymentsAPI'
import { AUTHORIZATIONS } from 'constants/apiConstants'

const getAuthorizationsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: AUTHORIZATIONS,
    queries,
    credentials,
    service: AUTHORIZATIONS,
  })
}

export default getAuthorizationsAPI
