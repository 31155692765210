import selectOption from 'utilities/forms/selectOption'
import { ALL } from 'constants/transferConstants'
import { ALL as ALL_LABEL } from 'constants/language/languageConstants'

import { 
    APPROVED,
    APPROVED_STATUS,
    PROVISIONING,
    REJECTED,
} from 'constants/settlementConstants'

export const PROVISIONING_STATUS = 'Provisioning'
export const REJECTED_STATUS = 'Rejected'

export const MERCHANT_ACCOUNTS_ONBOARDING_STATE_OPTIONS = [
    selectOption(ALL_LABEL, ALL),
    selectOption(APPROVED_STATUS, APPROVED),
    selectOption(PROVISIONING_STATUS, PROVISIONING),
    selectOption(REJECTED_STATUS, REJECTED),
]

export const merchantAccountsMapping = {
    APPROVED: APPROVED_STATUS,
    PROVISIONING: PROVISIONING_STATUS,
    REJECTED: REJECTED_STATUS,
}