import React, { Component } from 'react'
import { connect } from 'react-redux'
import OnboardingFormLink from './OnboardingFormLink'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import { SUCCESS } from 'constants/flashNotificationConstants'

class OnboardingFormLinkC extends Component {
    copyToClipboard = (name = '') => {
      const {
        dispatch,
      } = this.props

      const input = document.createElement('input')

      input.setAttribute('value', name)
      document.body.appendChild(input)
      input.select()

      const result = document.execCommand('copy')

      document.body.removeChild(input)

      dispatch(flashNotificationAction({
        message: 'Successfully copied to clipboard.',
        type: SUCCESS,
        time: 3000,
      }))

      return result
    }

    render() {
      return (
        <OnboardingFormLink
          {...this.props}
          copyToClipboard={this.copyToClipboard}
        />
      )
    }
}

export default connect()(OnboardingFormLinkC)
