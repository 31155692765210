import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISPUTE_EVIDENCE_SUBMIT } from 'constants/apiConstants'

const postSubmitDisputeEvidenceAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: DISPUTE_EVIDENCE_SUBMIT({ disputeId: id }),
  })
}

export default postSubmitDisputeEvidenceAPI
