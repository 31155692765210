import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import IdentityForm from './IdentityForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isPlatform from 'utilities/is/isPlatform'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import toggleCheckedStatus from 'utilities/toggleCheckedStatus'
import submitIdentityForm from 'utilities/submit/submitIdentityForm'
import getMany from 'utilities/get/getMany'
import { IDENTITY } from 'constants/flowConstants'
import { IDENTITY_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  BUSINESS_TYPE_OPTIONS,
  LLC_DISREGARDED_OPTION,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
  getIdentityActions,
} from 'constants/identityConstants'

const mapStateToProps = (state, props) => {
  const { skipIdentity = false } = props
  const credentials = getCurrentCredentials(state)
  const onSubmit = get(props, 'onSubmit', submitIdentityForm)

  const [
    dispatch,
    identityId,
  ] = getMany(props, [
    'dispatch',
    'params.identityId',
  ])

  const formSelector = formValueSelector(IDENTITY_FORM)
  const mcc = formSelector(state, 'mcc')
  const previouslyProcessedCreditCards = formSelector(state, 'previouslyProcessedCreditCards')
  const showTaxAuthority = mcc === '9311'
  const formSkipIdentity = formSelector(state, 'skipIdentity')
  const businessType = formSelector(state, 'businessType')
  const hideOwnershipTypeDropdown = includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)

  const [
    recordId,
    type = IDENTITY,
  ] = getMany(parseUrlQueries(), [
    'recordId',
    'type',
  ])

  const isElavon = isPlatform('elavonpartnerconnect')
  const businessTypeOptions = isElavon ? [...BUSINESS_TYPE_OPTIONS, LLC_DISREGARDED_OPTION] : BUSINESS_TYPE_OPTIONS

  return {
    actionType: getIdentityActions(type),
    identityId,
    recordId,
    credentials,
    onSubmit,
    showTaxAuthority,
    skipIdentity: formSkipIdentity || skipIdentity,
    previouslyProcessedCreditCards,
    dispatch,
    hideOwnershipTypeDropdown,
    businessType,
    businessTypeOptions,
    showMiddleName: isElavon,
  }
}

class IdentityFormC extends Component {
  componentDidUpdate(prevProps) {
    const { dispatch, businessType } = this.props
    const { businessType: prevBusinessType } = prevProps

    if (businessType !== prevBusinessType && (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType))) {
      dispatch(change(IDENTITY_FORM, 'ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType]))
    }
  }

  toggleSkipIdentity = ({ event }) => {
    const { dispatch } = this.props

    toggleCheckedStatus({ dispatch, event, name: 'skipIdentity', form: IDENTITY_FORM })
  }

  render() {
    return (
      <IdentityForm
        {...this.props}
        toggleSkipIdentity={this.toggleSkipIdentity}
        togglePreviouslyProcessedCreditCards={this.togglePreviouslyProcessedCreditCards}
      />
    )
  }
}

export default connect(mapStateToProps)(IdentityFormC)
