import './OnboardingConfigurationFormS.scss'
import React from 'react'
import { ChromePicker } from 'react-color'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import validateOnboardingConfigurationForm from 'utilities/validate/validateOnboardingConfigurationForm'
import { IMAGE_FILE_ICON } from 'constants/iconConstants'
import { ONBOARDING_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { DASHBOARD_BRANDING_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  MERCHANT_ONBOARDING_FORMS_BRANDING,
  MERCHANT_ONBOARDING_FORMS_BRANDING_OVERVIEW,
  UPLOAD_LOGO,
  DISPLAY_NAME,
  SAVE,
  LEARN_MORE_LABEL,
  RESET_ALL,
  HEADER_COLOR,
  MERCHANT_ONBOARDING_FORMS_PRESET,
  MERCHANT_ONBOARDING_FORMS_PRESET_OVERVIEW,
  EXPIRATION_TIME_DAYS,
  LINK_EXPIRATION_HELPER_TEXT,
  FEE_URL,
  FEE_URL_HELPER_TEXT,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_SERVICE_URL_HELPER_TEXT,
  RETURN_URL,
  RETURN_URL_HELPER_TEXT,
  EXPIRED_URL_HELPER_TEXT,
  EXPIRED_URL,
  HEADER_COLOR_HELPER_TEXT,
  DISPLAY_NAME_HELPER_TEXT,
  UPLOAD_LOGO_HELPER_TEXT,
} from 'constants/language/languageConstants'

const OnboardingConfigurationForm = ({
  handleSubmit = () => {},
  isFetching = true,
  updateColor = () => {},
  handleClick = () => {},
  handleClose = () => {},
  displayColorPicker = false,
  onboardingPrimaryColor,
  config,
  instructions,
  resetForm = () => {},
}) => {
  return (
    <form className='OnboardingConfigurationForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <div className='branding-section'>
        <h3>{MERCHANT_ONBOARDING_FORMS_BRANDING}</h3>

        <div className='onboarding-forms-branding-overview flex'>
          <div className='p-1 secondary'>
            {MERCHANT_ONBOARDING_FORMS_BRANDING_OVERVIEW}
            <a target='blank' href={DASHBOARD_BRANDING_LEARN_MORE_LINK} className='text-link link-1'>{LEARN_MORE_LABEL}</a>
          </div>
        </div>

        { isFetching && <Loader /> }

        { !isFetching && (
        <>
          <Field
            name='onboardingDisplayName'
            className='display-name-input'
            label={DISPLAY_NAME}
            component={InputField}
            helperText={DISPLAY_NAME_HELPER_TEXT}
          />

          <Field
            name='onboardingFormLogo'
            label={UPLOAD_LOGO}
            component={FileUploader}
            fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
            config={config}
            instructions={instructions}
            helperText={UPLOAD_LOGO_HELPER_TEXT}
          />

          <div className='primary-color-container flex items-center'>
            <Field
              name='onboardingPrimaryColor'
              label={HEADER_COLOR}
              placeholder='#FFFFFF'
              component={InputField}
              helperText={HEADER_COLOR_HELPER_TEXT}
            />


            <div>
              <div
                className='color-block'
                style={{ backgroundColor: onboardingPrimaryColor }}
                onClick={handleClick}
              />
              { displayColorPicker ? (
                <div className='color-picker-popup'>
                  <div className='color-picker-cover' onClick={handleClose} />
                  <ChromePicker
                    color={onboardingPrimaryColor}
                    onChange={(color) => { updateColor('onboardingPrimaryColor', color) }}
                    disableAlpha
                  />
                </div>
              ) : null }
            </div>
          </div>
        </>
        )}
      </div>

      <div className='preset-section'>
        <h4>{MERCHANT_ONBOARDING_FORMS_PRESET}</h4>

        <div className='onboarding-forms-branding-overview flex p-1 secondary'>
          {MERCHANT_ONBOARDING_FORMS_PRESET_OVERVIEW}
        </div>

        { isFetching && <Loader /> }

        { !isFetching && (
        <>
          <Field
            name='onboardingLinkExpiration'
            label={EXPIRATION_TIME_DAYS}
            component={InputField}
            helperText={LINK_EXPIRATION_HELPER_TEXT}
            required={false}
          />

          <Field
            name='onboardingFeeUrl'
            label={FEE_URL}
            component={InputField}
            helperText={FEE_URL_HELPER_TEXT}
            required={false}
          />

          <Field
            name='onboardingTermsOfServiceUrl'
            label={TERMS_OF_SERVICE_URL}
            component={InputField}
            helperText={TERMS_OF_SERVICE_URL_HELPER_TEXT}
            required={false}
          />

          <Field
            name='onboardingReturnUrl'
            label={RETURN_URL}
            component={InputField}
            helperText={RETURN_URL_HELPER_TEXT}
            required={false}
          />

          <Field
            name='onboardingExpiredUrl'
            label={EXPIRED_URL}
            component={InputField}
            helperText={EXPIRED_URL_HELPER_TEXT}
            required={false}
          />
        </>
        )}
      </div>

      <div className='buttons flex'>
        <Button onClick={resetForm} label={RESET_ALL} variant='secondary' className='reset-all-button' />
        <Button type='submit' label={SAVE} />
      </div>
    </form>
  )
}

OnboardingConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  updateColor: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  displayColorPicker: PropTypes.bool,
  onboardingPrimaryColor: PropTypes.string,
  config: PropTypes.object,
  instructions: PropTypes.array,
  resetForm: PropTypes.func,
}

export default reduxForm({
  form: ONBOARDING_CONFIGURATIONS_FORM,
  validate: validateOnboardingConfigurationForm,
  enableReinitialize: true,
})(OnboardingConfigurationForm)
