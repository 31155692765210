import React, { Component } from 'react'
import { connect } from 'react-redux'
import WithdrawLiveApplication from './WithdrawLiveApplication'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import deleteAccessFormRequest from 'utilities/actions/delete/deleteAccessFormRequest'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'accessFormId')

  return {
    credentials,
    accessFormId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    withdrawAccessForm: ({ accessFormId }) => dispatch(deleteAccessFormRequest({ accessFormId })),
  }
}

class WithdrawLiveApplicationC extends Component {
  render() {
    return (
      <WithdrawLiveApplication
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawLiveApplicationC)
