import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  AUTHORIZATION,
  AUTHORIZATIONS,
} from 'constants/apiConstants'

const patchAuthorizationAPI = ({ values, credentials, id, meta }) => {
  const path = AUTHORIZATION({ authorizationId: id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: AUTHORIZATIONS,
  })
}

export default patchAuthorizationAPI
