import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_SUBJECT_UNMASK } from 'constants/apiConstants'

const getUnderwritingSubjectUnmaskAPI = ({ id, credentials }) => {
  return paymentsAPI.get({
    path: UNDERWRITING_SUBJECT_UNMASK({ subjectId: id }),
    credentials,
  })
}

export default getUnderwritingSubjectUnmaskAPI
