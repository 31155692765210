import './GenericFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import classnames from 'classnames'
import map from 'lodash/map'

const GenericForm = ({
  handleSubmit,
  sections = [],
  submitting = false,
  submitLabel = 'Submit',
  submitVariant = 'primary',
  renderHeader,
  formName = '',
  disabled = false,
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
}) => {
  const classNames = classnames('GenericForm', formName)

  return (
    <form className={classNames} onSubmit={handleSubmit}>
      { renderHeader && renderHeader()}

      { map(sections, (section, sectionIndex) => {
        const { inputs, title } = section
        const sectionKey = `section_${sectionIndex}`
        return (
          <div className='section' key={sectionKey}>
            { title && <h2>{title}</h2> }

            { inputs.map((input, inputIndex) => {
              const { fieldComponent, props } = input
              const FieldComponent = fieldComponent || Field
              const inputKey = `${sectionKey}_input_${inputIndex}`

              return (
                <FieldComponent key={inputKey} {...props} />
              )
            })}

          </div>
        )
      })}

      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button className='left-button' onClick={leftButtonAction} variant='secondary' label={leftButtonLabel} /> }
        </div>

        <div className='right flex flex-end'>
          <Button type='submit' label={submitLabel} disabled={disabled} submitting={submitting} variant={submitVariant} />
        </div>
      </div>
    </form>
  )
}

GenericForm.propTypes = {
  handleSubmit: PropTypes.func,
  sections: PropTypes.array,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool,
  renderHeader: PropTypes.func,
  formName: PropTypes.string,
  disabled: PropTypes.bool,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
}

export default GenericForm
