import frontendFeeModel from 'utilities/create/models/frontendFeeModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendFeesModel = ({ data: fees }) => {
  const feesModels = map(fees, (fee) => frontendFeeModel({ data: fee }))

  return keyBy(feesModels, 'id')
}

export default frontendFeesModel
