import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

const frontendOnboardingFormModel = ({ data: onboardingForm }) => {
  const [
    id,
    createdAt,
    updatedAt,
    applicationId,
    identityId,
    status,
  ] = getMany(onboardingForm, [
    'id',
    'created_at',
    'updated_at',
    'application_id',
    'identity_id',
    'status',
  ])

  const displayCreatedAt = formatDate({ date: createdAt })
  const displayStatus = startCase(lowerCase(status))

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    applicationId,
    identityId,
    status,
    displayCreatedAt,
    displayStatus,
  })
}

export default frontendOnboardingFormModel
