import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import CustomerOnboardingFormDealInfoForm from 'components/Customer/Forms/CustomerOnboardingFormDealInfoForm/CustomerOnboardingFormDealInfoForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getMembersSelector } from 'state-layer/selectors'
import getRoleMembersRequest from 'utilities/actions/get/getRoleMembersRequest'
import getMany from 'utilities/get/getMany'
import { USA } from 'constants/countryConstants'
import { CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const accountExecutiveRoleIdMap = {
  QA: 'dashuserrole_npjK63KyXSkDseMA8Sf3Uv',
  Sandbox: 'dashuserrole_sMPHwXQvJHK1dxvkMckMH8',
  Live: 'dashuserrole_kzB3vELCWTLK4iVC6UpgVf',
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentEnvironment = get(credentials, 'environment')
  const accountExecutiveRoleId = accountExecutiveRoleIdMap[currentEnvironment]
  const accountExecutives = getMembersSelector(state)
  const accountExecutiveDropdownOptions = map(accountExecutives, ({ email }) => { return { label: email, value: email } })
  const formValues = getFormValues(CUSTOMER_ONBOARDING_FORM_DEAL_INFO_FORM)(state) || get(state, 'flowsR.formValues.dealInfo')

  return {
    credentials,
    credentialId,
    accountExecutiveDropdownOptions,
    accountExecutiveRoleId,
    initialValues: { country: USA, ...formValues },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountExecutives: ({ credentials, roleId, meta, queries }) => dispatch(getRoleMembersRequest({ roleId, credentials, meta, queries })),
  }
}

class CustomerOnboardingFormDealInfoFormC extends Component {
  componentDidMount() {
    const [
      credentials,
      accountExecutiveRoleId,
      getAccountExecutives,
    ] = getMany(this.props, [
      'credentials',
      'accountExecutiveRoleId',
      'getAccountExecutives',
    ])

    if (accountExecutiveRoleId) {
      getAccountExecutives({
        credentials,
        roleId: accountExecutiveRoleId,
        queries: { enabled: true },
        meta: { overwriteReducer: true },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { accountExecutiveRoleId: prevAccountExecutiveRoleId } = prevProps
    const [
      credentials,
      accountExecutiveRoleId,
      getAccountExecutives,
    ] = getMany(this.props, [
      'credentials',
      'accountExecutiveRoleId',
      'getAccountExecutives',
    ])

    if (accountExecutiveRoleId && prevAccountExecutiveRoleId !== accountExecutiveRoleId) {
      getAccountExecutives({
        credentials,
        roleId: accountExecutiveRoleId,
        queries: { enabled: true },
        meta: { overwriteReducer: true },
      })
    }
  }

  render() {
    return (
      <CustomerOnboardingFormDealInfoForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOnboardingFormDealInfoFormC)
