import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { goToPath } from 'state-layer/history'
import FeeProfile from 'components/Customer/Pages/FeeProfile/FeeProfile'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getFeeProfileSettingsRequest from 'utilities/actions/get/getFeeProfileSettingsRequest'
import getFeeProfileRequest from 'utilities/actions/get/getFeeProfileRequest'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import getFeeProfileRulesRequest from 'utilities/actions/get/getFeeProfileRulesRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_FEE_PROFILE_DUES_ASSESSMENTS } from 'constants/featureFlagConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import some from 'lodash/some'
import get from 'lodash/get'

import {
  getFeeProfileRuleSelector,
  getFeeProfileSelector,
  getFeeProfileSettingSelector,
} from 'state-layer/selectors'

import {
  isRolePartner,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION,
  MERCHANT,
  PLATFORM,
} from 'constants/dashboardConfigurationsConstants'

import {
  APPLICATION_PATH,
  EDIT_APPLICATION_FEE_PROFILE_PATH,
  EDIT_MERCHANT_FEE_PROFILE_PATH,
} from 'constants/pathConstants'

import {
  AMEX_LABEL,
  DINERS_CLUB_LABEL,
  DISCOVER_LABEL,
  JCB_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

import {
  ACH,
  ACH_CREDIT,
  ACH_DEBIT,
  ALL_CARD_BRANDS,
  AMERICAN_EXPRESS_ASSESSMENTS,
  CARD_CROSS_BORDER,
  CHARGEBACK_INQUIRY,
  CHARGEBACK_NOTIFICATION,
  DISCOVER_ASSESSMENTS,
  DISCOVER_DATA_USAGE,
  DISCOVER_NETWORK_AUTHORIZATION,
  EDIT,
  MASTERCARD_ACQUIRER_FEES,
  MASTERCARD_ASSESSMENTS_OVER_1000,
  MASTERCARD_ASSESSMENTS_UNDER_1000,
  MAXIMUM_ACH_PERCENTAGE_FEE_ALLOWED,
  PER_CARD_PAYMENT_LOWERCASE,
  PER_INQUIRY_LOWERCASE,
  PER_NOTIFICATION_LOWERCASE,
  PER_TRANSACTION_LOWERCASE,
  SUPPLEMENTAL_FEE_1,
  SUPPLEMENTAL_FEE_2,
  VISA_ACQUIRER_PROCESSING,
  VISA_ASSESSMENTS,
  VISA_BASE_SYSTEM_FILE_TRANSMISSION,
  VISA_CREDIT_VOUCHER,
  VISA_KILOBYTE_ACCESS,
  DEFAULT_FEE_PROFILE,
  DEFAULT_MERCHANT_FEE_PROFILE,
} from 'constants/language/languageConstants'

const createFeeProfileSubheader = (feeProfileInheritanceId, feeProfileInheritanceName, feeProfileInheritanceType, displayCreatedAt, credentials, entityType) => {
  if (feeProfileInheritanceType === MERCHANT) {
    return `Custom profile created ${displayCreatedAt}`
  }
  if (entityType === APPLICATION && feeProfileInheritanceType === APPLICATION) {
    return `Assigned to new merchants created since ${displayCreatedAt}`
  }
  if (feeProfileInheritanceType === APPLICATION) {
    const applicationPath = `${APPLICATION_PATH({ credentialId: get(credentials, 'id'), applicationId: feeProfileInheritanceId })}?tab=fee-risk-profile`
    return isRolePlatform({ credentials }) ? (
      <p className='subheader p-1'>Inherited from <Link className='applicationLink text-link' href={applicationPath}>{feeProfileInheritanceName}</Link> {feeProfileInheritanceId}</p>
    ) : (`Inherited from ${feeProfileInheritanceName}`)
  }
  if (feeProfileInheritanceType === PLATFORM) {
    return `Inherited from ${feeProfileInheritanceName}`
  }
  return ''
}

const createTotalFeeDisplay = (displayFixedFee, displayPercentageFee, fixedFee, percentageFee) => {
  if (fixedFee && percentageFee) {
    return `${displayFixedFee} + ${displayPercentageFee}`
  }
  if (fixedFee) {
    return displayFixedFee
  }
  if (percentageFee) {
    return displayPercentageFee
  }
  return null
}

const createDataForFeeProfiles = ({ feeProfile }) => {
  const [
    displayBasisPoints,
    displayFixedFee,
    displayAmericanExpressBasisPoints,
    displayAmericanExpressFixedFee,
    displayVisaBasisPoints,
    displayVisaFixedFee,
    displayMastercardBasisPoints,
    displayMastercardFixedFee,
    displayDiscoverBasisPoints,
    displayDiscoverFixedFee,
    displayJcbBasisPoints,
    displayJcbFixedFee,
    displayDinersClubBasisPoints,
    displayDinersClubFixedFee,
    displayDisputeFixedFee,
    displayDisputeInquiryFixedFee,
    displayVisaAssessmentsBasisPoints,
    displayVisaAcquirerProcessingFixedFee,
    displayVisaCreditVoucherFixedFee,
    displayVisaBaseIISystemFileTransmissionFixedFee,
    displayVisaKilobyteAccessFixedFee,
    displayMastercardAcquirerFeesBasisPoints,
    displayMastercardAssessmentsOver1kBasisPoints,
    displayMastercardAssessmentsUnder1kBasisPoints,
    displayAmericanExpressAssessmentBasisPoints,
    displayDiscoverAssessmentsBasisPoints,
    displayDiscoverDataUsageFixedFee,
    displayDiscoverNetworkAuthorizationFixedFee,
    feeProfileType,
    displayCardCrossBorderBasisPoints,
    displayCardCrossBorderFixedFee,
    visaBasisPoints,
    mastercardBasisPoints,
    americanExpressBasisPoints,
    discoverBasisPoints,
    jcbBasisPoints,
    dinersClubBasisPoints,
    basisPoints,
    fixedFee,
    cardCrossBorderBasisPoints,
    cardCrossBorderFixedFee,
    disputeFixedFee,
    disputeInquiryFixedFee,
    visaFixedFee,
    mastercardFixedFee,
    americanExpressFixedFee,
    discoverFixedFee,
    jcbFixedFee,
    dinersClubFixedFee,
    displayChargeDuesAssessments,
    displayVisaChargeDuesAssessments,
    displayMastercardChargeDuesAssessments,
    displayAmericanExpressChargeDuesAssessments,
    displayDiscoverChargeDuesAssessments,
    chargeDuesAssessments,
    visaChargeDuesAssessments,
    mastercardChargeDuesAssessments,
    americanExpressChargeDuesAssessments,
    discoverChargeDuesAssessments,
  ] = getMany(feeProfile, [
    'displayBasisPoints',
    'displayFixedFee',
    'displayAmericanExpressBasisPoints',
    'displayAmericanExpressFixedFee',
    'displayVisaBasisPoints',
    'displayVisaFixedFee',
    'displayMastercardBasisPoints',
    'displayMastercardFixedFee',
    'displayDiscoverBasisPoints',
    'displayDiscoverFixedFee',
    'displayJcbBasisPoints',
    'displayJcbFixedFee',
    'displayDinersClubBasisPoints',
    'displayDinersClubFixedFee',
    'displayDisputeFixedFee',
    'displayDisputeInquiryFixedFee',
    'displayVisaAssessmentsBasisPoints',
    'displayVisaAcquirerProcessingFixedFee',
    'displayVisaCreditVoucherFixedFee',
    'displayVisaBaseIISystemFileTransmissionFixedFee',
    'displayVisaKilobyteAccessFixedFee',
    'displayMastercardAcquirerFeesBasisPoints',
    'displayMastercardAssessmentsOver1kBasisPoints',
    'displayMastercardAssessmentsUnder1kBasisPoints',
    'displayAmericanExpressAssessmentBasisPoints',
    'displayDiscoverAssessmentsBasisPoints',
    'displayDiscoverDataUsageFixedFee',
    'displayDiscoverNetworkAuthorizationFixedFee',
    'feeProfileType',
    'displayCardCrossBorderBasisPoints',
    'displayCardCrossBorderFixedFee',
    'visaBasisPoints',
    'mastercardBasisPoints',
    'americanExpressBasisPoints',
    'discoverBasisPoints',
    'jcbBasisPoints',
    'dinersClubBasisPoints',
    'basisPoints',
    'fixedFee',
    'cardCrossBorderBasisPoints',
    'cardCrossBorderFixedFee',
    'disputeFixedFee',
    'disputeInquiryFixedFee',
    'visaFixedFee',
    'mastercardFixedFee',
    'americanExpressFixedFee',
    'discoverFixedFee',
    'jcbFixedFee',
    'dinersClubFixedFee',
    'displayChargeDuesAssessments',
    'displayVisaChargeDuesAssessments',
    'displayMastercardChargeDuesAssessments',
    'displayAmericanExpressChargeDuesAssessments',
    'displayDiscoverChargeDuesAssessments',
    'chargeDuesAssessments',
    'visaChargeDuesAssessments',
    'mastercardChargeDuesAssessments',
    'americanExpressChargeDuesAssessments',
    'discoverChargeDuesAssessments',
  ])

  const feeTypeRows = [
    { isVisible: !!basisPoints || !!fixedFee,
      columns: [
        ALL_CARD_BRANDS,
        displayBasisPoints,
        displayFixedFee,
        {
          title: createTotalFeeDisplay(displayFixedFee, displayBasisPoints, fixedFee, basisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!cardCrossBorderBasisPoints || !!cardCrossBorderFixedFee,
      columns: [
        CARD_CROSS_BORDER,
        displayCardCrossBorderBasisPoints,
        displayCardCrossBorderFixedFee,
        {
          title: createTotalFeeDisplay(displayCardCrossBorderFixedFee, displayCardCrossBorderBasisPoints, cardCrossBorderFixedFee, cardCrossBorderBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]

  const cardBrandRows = [
    { isVisible: !!visaBasisPoints || !!visaFixedFee,
      columns: [
        VISA_LABEL,
        displayVisaBasisPoints,
        displayVisaFixedFee,
        {
          title: createTotalFeeDisplay(displayVisaFixedFee, displayVisaBasisPoints, visaFixedFee, visaBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!mastercardBasisPoints || !!mastercardFixedFee,
      columns: [
        MASTERCARD_LABEL,
        displayMastercardBasisPoints,
        displayMastercardFixedFee,
        {
          title: createTotalFeeDisplay(displayMastercardFixedFee, displayMastercardBasisPoints, mastercardFixedFee, mastercardBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!americanExpressBasisPoints || !!americanExpressFixedFee,
      columns: [
        AMEX_LABEL,
        displayAmericanExpressBasisPoints,
        displayAmericanExpressFixedFee,
        {
          title: createTotalFeeDisplay(displayAmericanExpressFixedFee, displayAmericanExpressBasisPoints, americanExpressFixedFee, americanExpressBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!discoverBasisPoints || !!discoverFixedFee,
      columns: [
        DISCOVER_LABEL,
        displayDiscoverBasisPoints,
        displayDiscoverFixedFee,
        {
          title: createTotalFeeDisplay(displayDiscoverFixedFee, displayDiscoverBasisPoints, discoverFixedFee, discoverBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!jcbBasisPoints || !!jcbFixedFee,
      columns: [
        JCB_LABEL,
        displayJcbBasisPoints,
        displayJcbFixedFee,
        {
          title: createTotalFeeDisplay(displayJcbFixedFee, displayJcbBasisPoints, jcbFixedFee, jcbBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!dinersClubBasisPoints || !!dinersClubFixedFee,
      columns: [
        DINERS_CLUB_LABEL,
        displayDinersClubBasisPoints,
        displayDinersClubFixedFee,
        {
          title: createTotalFeeDisplay(displayDinersClubFixedFee, displayDinersClubBasisPoints, dinersClubFixedFee, dinersClubBasisPoints),
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]

  const hasCardBrandOverrides = some(cardBrandRows, 'isVisible')

  const hideChargebackRows = !disputeFixedFee && !disputeInquiryFixedFee
  const chargebackRows = [
    { isVisible: !!disputeFixedFee,
      columns: [
        CHARGEBACK_NOTIFICATION,
        displayDisputeFixedFee,
        {
          title: displayDisputeFixedFee,
          subTitle: PER_NOTIFICATION_LOWERCASE,
        },
      ],
    },
    { isVisible: !!disputeInquiryFixedFee,
      columns: [
        CHARGEBACK_INQUIRY,
        displayDisputeInquiryFixedFee,
        {
          title: displayDisputeInquiryFixedFee,
          subTitle: PER_INQUIRY_LOWERCASE,
        },
      ],
    },
  ]

  const interchangeVisaRows = [
    { isVisible: !!displayVisaAssessmentsBasisPoints,
      columns: [
        VISA_ASSESSMENTS,
        displayVisaAssessmentsBasisPoints,
        '',
        {
          title: displayVisaAssessmentsBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayVisaAcquirerProcessingFixedFee,
      columns: [
        VISA_ACQUIRER_PROCESSING,
        '',
        displayVisaAcquirerProcessingFixedFee,
        {
          title: displayVisaAcquirerProcessingFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayVisaCreditVoucherFixedFee,
      columns: [
        VISA_CREDIT_VOUCHER,
        '',
        displayVisaCreditVoucherFixedFee,
        {
          title: displayVisaCreditVoucherFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayVisaBaseIISystemFileTransmissionFixedFee,
      columns: [
        VISA_BASE_SYSTEM_FILE_TRANSMISSION,
        '',
        displayVisaBaseIISystemFileTransmissionFixedFee,
        {
          title: displayVisaBaseIISystemFileTransmissionFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayVisaKilobyteAccessFixedFee,
      columns: [
        VISA_KILOBYTE_ACCESS,
        '',
        displayVisaKilobyteAccessFixedFee,
        {
          title: displayVisaKilobyteAccessFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]
  const showInterchangeVisaRows = some(interchangeVisaRows, 'isVisible')

  const interchangeMastercardRows = [
    { isVisible: !!displayMastercardAssessmentsUnder1kBasisPoints,
      columns: [
        MASTERCARD_ASSESSMENTS_UNDER_1000,
        displayMastercardAssessmentsUnder1kBasisPoints,
        '',
        {
          title: displayMastercardAssessmentsUnder1kBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayMastercardAssessmentsOver1kBasisPoints,
      columns: [
        MASTERCARD_ASSESSMENTS_OVER_1000,
        displayMastercardAssessmentsOver1kBasisPoints,
        '',
        {
          title: displayMastercardAssessmentsOver1kBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayMastercardAcquirerFeesBasisPoints,
      columns: [
        MASTERCARD_ACQUIRER_FEES,
        displayMastercardAcquirerFeesBasisPoints,
        '',
        {
          title: displayMastercardAcquirerFeesBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]
  const showInterchangeMastercardRows = some(interchangeMastercardRows, 'isVisible')

  const interchangeAmexRows = [
    { isVisible: !!displayAmericanExpressAssessmentBasisPoints,
      columns: [
        AMERICAN_EXPRESS_ASSESSMENTS,
        displayAmericanExpressAssessmentBasisPoints,
        '',
        {
          title: displayAmericanExpressAssessmentBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]
  const showInterchangeAmexRows = some(interchangeAmexRows, 'isVisible')

  const interchangeDiscoverRows = [
    { isVisible: !!displayDiscoverAssessmentsBasisPoints,
      columns: [
        DISCOVER_ASSESSMENTS,
        displayDiscoverAssessmentsBasisPoints,
        '',
        {
          title: displayDiscoverAssessmentsBasisPoints,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayDiscoverDataUsageFixedFee,
      columns: [
        DISCOVER_DATA_USAGE,
        '',
        displayDiscoverDataUsageFixedFee,
        {
          title: displayDiscoverDataUsageFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayDiscoverNetworkAuthorizationFixedFee,
      columns: [
        DISCOVER_NETWORK_AUTHORIZATION,
        '',
        displayDiscoverNetworkAuthorizationFixedFee,
        {
          title: displayDiscoverNetworkAuthorizationFixedFee,
          subTitle: PER_CARD_PAYMENT_LOWERCASE,
        },
      ],
    },
  ]
  const showInterchangeDiscoverRows = some(interchangeDiscoverRows, 'isVisible')
  const showInterchangeSection = showInterchangeVisaRows || showInterchangeMastercardRows || showInterchangeAmexRows || showInterchangeDiscoverRows

  const duesAssessmentsRows = [
    {
      isVisible: chargeDuesAssessments === true || chargeDuesAssessments === false,
      columns: [
        ALL_CARD_BRANDS,
        displayChargeDuesAssessments,
      ],
    },
    {
      isVisible: visaChargeDuesAssessments === true || visaChargeDuesAssessments === false,
      columns: [
        VISA_LABEL,
        displayVisaChargeDuesAssessments,
      ],
    },
    {
      isVisible: mastercardChargeDuesAssessments === true || mastercardChargeDuesAssessments === false,
      columns: [
        MASTERCARD_LABEL,
        displayMastercardChargeDuesAssessments,
      ],
    },
    {
      isVisible: americanExpressChargeDuesAssessments === true || americanExpressChargeDuesAssessments === false,
      columns: [
        AMEX_LABEL,
        displayAmericanExpressChargeDuesAssessments,
      ],
    },
    {
      isVisible: discoverChargeDuesAssessments === true || discoverChargeDuesAssessments === false,
      columns: [
        DISCOVER_LABEL,
        displayDiscoverChargeDuesAssessments,
      ],
    },
  ]
  const hasDuesAssessments = some(duesAssessmentsRows, 'isVisible')

  return {
    feeTypeRows,
    cardBrandRows,
    hasCardBrandOverrides,
    hideChargebackRows,
    chargebackRows,
    interchangeVisaRows,
    showInterchangeVisaRows,
    interchangeMastercardRows,
    showInterchangeMastercardRows,
    interchangeAmexRows,
    showInterchangeAmexRows,
    interchangeDiscoverRows,
    showInterchangeDiscoverRows,
    showInterchangeSection,
    feeProfileType,
    duesAssessmentsRows,
    hasDuesAssessments,
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feeProfilesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const identityId = get(props, 'identityId')
  const entityId = get(props, 'entityId')
  const entityType = get(props, 'entityType')
  const isReviewPage = get(props, 'isReviewPage', false)
  const feeProfilesForReviewPage = get(props, 'feeProfilesForReviewPage')
  const feeProfiles = isReviewPage ? feeProfilesForReviewPage : getFeeProfileSelector(state, entityId)
  const feeProfile = get(feeProfiles, 'feeProfile')
  const cardPresentFeeProfile = get(feeProfiles, 'cardPresentFeeProfile')
  const noCardPresentFeeProfile = !cardPresentFeeProfile
  const cardPresentFeeProfileId = get(cardPresentFeeProfile, 'id')
  const isFlex = isFlexPlatform()

  const [
    feeProfileId,
    displayCreatedAt,
    displayAchBasisPoints,
    displayAchFixedFee,
    displayAchCreditReturnFixedFee,
    displayAchDebitReturnFixedFee,
    displayAncillaryFixedFeePrimary,
    displayAncillaryFixedFeeSecondary,
    feeProfileInheritanceId,
    feeProfileInheritanceName,
    feeProfileInheritanceType,
    displayAchBasisPointsFeeLimit,
  ] = getMany(feeProfile, [
    'id',
    'displayCreatedAt',
    'displayAchBasisPoints',
    'displayAchFixedFee',
    'displayAchCreditReturnFixedFee',
    'displayAchDebitReturnFixedFee',
    'displayAncillaryFixedFeePrimary',
    'displayAncillaryFixedFeeSecondary',
    'feeProfileInheritanceId',
    'feeProfileInheritanceName',
    'feeProfileInheritanceType',
    'displayAchBasisPointsFeeLimit',
  ])

  const feeProfileData = feeProfile ? createDataForFeeProfiles({ feeProfile }) : undefined
  const cardPresentFeeProfileData = cardPresentFeeProfile ? createDataForFeeProfiles({ feeProfile: cardPresentFeeProfile }) : undefined

  const achFeeTypesRows = [
    { isVisible: !!displayAchBasisPoints,
      columns: [
        ACH,
        displayAchBasisPoints,
        displayAchFixedFee,
        {
          title: `${displayAchBasisPoints} + ${displayAchFixedFee}`,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayAchBasisPointsFeeLimit,
      columns: [
        MAXIMUM_ACH_PERCENTAGE_FEE_ALLOWED,
        '',
        `Up to ${displayAchBasisPointsFeeLimit}`,
        {
          title: `Up to ${displayAchBasisPointsFeeLimit}`,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
  ]

  const hideAchReturnsRows = !displayAchCreditReturnFixedFee && !displayAchDebitReturnFixedFee
  const achReturnsRows = [
    { isVisible: !!displayAchCreditReturnFixedFee,
      columns: [
        ACH_CREDIT,
        displayAchCreditReturnFixedFee,
        {
          title: displayAchCreditReturnFixedFee,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayAchDebitReturnFixedFee,
      columns: [
        ACH_DEBIT,
        displayAchDebitReturnFixedFee,
        {
          title: displayAchDebitReturnFixedFee,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
  ]

  const hideAdditionalFeesSection = !displayAncillaryFixedFeePrimary && !displayAncillaryFixedFeeSecondary
  const additionalFeesRows = [
    { isVisible: !!displayAncillaryFixedFeePrimary,
      columns: [
        SUPPLEMENTAL_FEE_1,
        displayAncillaryFixedFeePrimary,
        {
          title: displayAncillaryFixedFeePrimary,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
    { isVisible: !!displayAncillaryFixedFeeSecondary,
      columns: [
        SUPPLEMENTAL_FEE_2,
        displayAncillaryFixedFeeSecondary,
        {
          title: displayAncillaryFixedFeeSecondary,
          subTitle: PER_TRANSACTION_LOWERCASE,
        },
      ],
    },
  ]

  // check if the application can edit a merchant fee profile / apply guard rails
  const merchantApplicationId = get(props, 'merchantApplicationId')
  const feeProfileApplicationId = !isFlex ? null : entityType === MERCHANT ? merchantApplicationId : entityId
  const currency = get(props, 'currency')
  const feeProfileSettings = getFeeProfileSettingSelector(state, feeProfileApplicationId)
  const feeProfileRules = getFeeProfileRuleSelector(state, feeProfileApplicationId)
  const canEditFeeProfileForMerchant =
      !!get(feeProfileSettings, `${currency}.canAssignFeeProfiles`)
      && !!get(feeProfileSettings, `${currency}.canCreateFeeProfiles`)
      && !!get(feeProfileSettings, `${currency}.enforceLimitsForFeeProfiles`)
      && (!!get(feeProfileRules, `BLENDED.${currency}.isAllowed`) || !!get(feeProfileRules, `INTERCHANGE_PLUS.${currency}.isAllowed`))
      && hasPermissions(['processing:fee_profile:create', 'processing:merchant_profile:update'])

  const editFeeProfilePath = entityType === APPLICATION ? EDIT_APPLICATION_FEE_PROFILE_PATH({ credentialId, applicationId: entityId }) : EDIT_MERCHANT_FEE_PROFILE_PATH({ credentialId, merchantId: entityId, identityId })
  const editFeeProfileActions = (step) => {
    return [{
      label: EDIT,
      action: () => goToPath({
        pathname: editFeeProfilePath,
        queries: {
          entityId,
          entityType,
          activeStep: step,
        },
      }),
      condition:
        !isReviewPage
        && (
          (
            entityType !== PLATFORM
            && isRolePlatform({ credentials })
            && hasPermissions(['processing:fee_profile:create', 'processing:application_profile:update', 'processing:merchant_profile:update'])
          ) || (
            entityType === MERCHANT
              && isRolePartner({ credentials })
              && canEditFeeProfileForMerchant
          )
        ),
    }]
  }

  const title = isReviewPage ? '' : entityType === MERCHANT ? DEFAULT_FEE_PROFILE : DEFAULT_MERCHANT_FEE_PROFILE
  const subHeader = isReviewPage || entityType === PLATFORM ? '' : createFeeProfileSubheader(feeProfileInheritanceId, feeProfileInheritanceName, feeProfileInheritanceType, displayCreatedAt, credentials, entityType)
  const showCurrency = entityType === MERCHANT
  const showFeeProfileSettingsSection = entityType === APPLICATION && isRolePlatform({ credentials }) && isFlex
  const showDuesAssessments = getFeatureFlag(SHOW_FEE_PROFILE_DUES_ASSESSMENTS)

  return {
    credentials,
    credentialId,
    entityId,
    entityType,
    feeProfileId,
    cardPresentFeeProfileId,
    feeProfileData,
    cardPresentFeeProfileData,
    achFeeTypesRows,
    hideAchReturnsRows,
    achReturnsRows,
    additionalFeesRows,
    hideAdditionalFeesSection,
    isFetching,
    editFeeProfileActions,
    noCardPresentFeeProfile,
    title,
    subHeader,
    showCurrency,
    showFeeProfileSettingsSection,
    feeProfileApplicationId,
    isReviewPage,
    isFlex,
    showDuesAssessments,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeProfile: ({ credentials, values }) => dispatch(getFeeProfileRequest({ credentials, values })),
    getFeeProfileSettings: ({ credentials, values }) => dispatch(getFeeProfileSettingsRequest({ credentials, values })),
    getFeeProfileRules: ({ credentials, values }) => dispatch(getFeeProfileRulesRequest({ credentials, values })),
  }
}

class FeeProfileC extends Component {
  componentDidMount() {
    const {
      getFeeProfile,
      entityId,
      entityType,
      credentials,
      isReviewPage,
      getFeeProfileSettings,
      getFeeProfileRules,
      feeProfileApplicationId,
    } = this.props

    const values = {
      entityType,
      entityId,
    }

    if (!isReviewPage && entityId) {
      getFeeProfile({ credentials, values })

      if (feeProfileApplicationId && entityType !== PLATFORM) {
        getFeeProfileRules({ credentials, values: { applicationId: feeProfileApplicationId } })
        getFeeProfileSettings({ credentials, values: { applicationId: feeProfileApplicationId } })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      entityId: prevEntityId,
      feeProfileApplicationId: prevFeeProfileApplicationId,
    } = prevProps

    const {
      entityId,
      entityType,
      credentials,
      getFeeProfile,
      getFeeProfileSettings,
      isReviewPage,
      getFeeProfileRules,
      feeProfileApplicationId,
    } = this.props

    const values = {
      entityType,
      entityId,
    }

    if (!isReviewPage && entityId && entityId !== prevEntityId) {
      getFeeProfile({ credentials, values })
    }

    if (!isReviewPage && (entityType === MERCHANT || entityType === APPLICATION) && feeProfileApplicationId && prevFeeProfileApplicationId !== feeProfileApplicationId) {
      getFeeProfileRules({ credentials, values: { applicationId: feeProfileApplicationId } })
      getFeeProfileSettings({ credentials, values: { applicationId: feeProfileApplicationId } })
    }
  }

  render() {
    return (
      <FeeProfile
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeeProfileC)
