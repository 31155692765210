import './EditUserInfoModalS.scss'
import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import PropTypes from 'prop-types'
import EditUserInfoFormC from 'components/Customer/Forms/EditUserInfoForm/EditUserInfoFormC'
import { UPDATE_PERSONAL_INFO } from 'constants/language/languageConstants'

const EditUserInfoModal = ({
  credentials,
  dashboardUserId = '',
}) => {
  return (
    <GenericModal
      title={UPDATE_PERSONAL_INFO}
      className='EditUserInfoModal'
      Component={EditUserInfoFormC}
      credentials={credentials}
      dashboardUserId={dashboardUserId}
    />
  )
}

EditUserInfoModal.propTypes = {
  credentials: PropTypes.object,
  dashboardUserId: PropTypes.string,
}

export default EditUserInfoModal
