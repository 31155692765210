import React, { Component } from 'react'
import AltPaymentMethods from './AltPaymentMethods'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const isFetchingDomains = get(state, `applePayRegisteredMerchantR.${FETCHING}`)
  const isFetchingCerts = get(state, `applePayCertsR.${FETCHING}`)

  return {
    credentials,
    identityId,
    isFetchingDomains,
    isFetchingCerts,
  }
}

class AltPaymentMethodsC extends Component {
  render() {
    return (
      <AltPaymentMethods {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(AltPaymentMethodsC)
