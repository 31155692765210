import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { PATCH_SUBSCRIPTION_F_REQUEST } from 'constants/flowConstants'
import { EDIT_SUBSCRIPTION } from 'constants/amplitudeConstants'
import { SUBSCRIPTION_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitEditSubscriptionForm = (values, dispatch, props) => {
  const {
    subscriptionId,
    credentials,
    hasSubscriptionPlan,
  } = props

  const {
    nickname,
    amount,
    buyerInstrumentId,
  } = values

  const buyerInstrumentIdValue = get(buyerInstrumentId, 'value')
  const credentialId = get(credentials, 'id')

  const valuesToSubmit = !hasSubscriptionPlan ? {
    nickname,
    amount,
    buyerInstrumentId: buyerInstrumentIdValue,
  } : { buyerInstrumentId: buyerInstrumentIdValue }

  dispatch({
    type: PATCH_SUBSCRIPTION_F_REQUEST,
    payload: {
      id: subscriptionId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_SUBSCRIPTION, {
        subscriptionId,
        valuesToSubmit,
        credentials,
      }),
      successCallback: ({ newValues }) => {
        const id = head(keys(newValues))
        dispatch(redirectRequest({ path: SUBSCRIPTION_PATH({ credentialId, subscriptionId: id }) }))
      },
    },
  })
}

export default submitEditSubscriptionForm
