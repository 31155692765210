import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_APPLICATION_MEMBERS } from 'constants/apiConstants'

const getApplicationMembersAPI = ({ credentials, id, queries, meta }) => {
  return dashboardAPI.get({
    queries,
    credentials,
    path: DASHBOARD_SERVICE_APPLICATION_MEMBERS({ applicationId: id }),
    meta,
  })
}

export default getApplicationMembersAPI
