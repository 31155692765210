import './AccessFormFlowHomepageBannerS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Button from 'components/Shared/Button/Button'
import classnames from 'classnames'
import map from 'lodash/map'

import {
  CONTINUE_APPLICATION,
  FINISH_APPLYING_FOR_LIVE_ACCOUNT,
} from 'constants/language/languageConstants'

const accessFormFlowSteps = ['Pricing Info', 'Business Info', 'Owner Info', 'Processing Info', 'Bank Account Info', 'Review and Submit']
const AccessFormFlowHomepageBanner = ({
  accessFormActiveStepIndex,
  continueLiveAccountApplicationPath = '',
}) => {
  return (
    <div className='AccessFormFlowHomepageBanner'>
      <h2 className='banner-title'>{FINISH_APPLYING_FOR_LIVE_ACCOUNT}</h2>
      <div className='access-form-flow flex flex-start'>
        <div className='flex flow-progress'>
          { map(accessFormFlowSteps, (step, index) => {
            const { name } = step

            const progressClassnames = classnames('progress-session flex column', {
              'past-step': index < accessFormActiveStepIndex,
              'active-step': index === accessFormActiveStepIndex,
              'future-step': index > accessFormActiveStepIndex,
              'last-step': (index + 1) === accessFormFlowSteps.length,
            })
            return (
              <>
                <div className='flex column step-container'>
                  <div className='flex items-center'>
                    <div key={`${name}-${index}`} className={`step ${progressClassnames}`}>
                      <div className='step-number'>{index + 1}</div>
                    </div>
                    <div className={`horizontal-line flex-grow ${progressClassnames}`} />
                  </div>
                  <div className={`step-name flex ${progressClassnames}`}>{step}</div>
                </div>
              </>
            )
          }) }
        </div>
      </div>
      <Link to={continueLiveAccountApplicationPath}><Button label={CONTINUE_APPLICATION} variant='legacy-black' /></Link>
    </div>
  )
}

AccessFormFlowHomepageBanner.propTypes = {
  accessFormActiveStepIndex: PropTypes.string,
  continueLiveAccountApplicationPath: PropTypes.string,
}

export default AccessFormFlowHomepageBanner
