import React, { Component } from 'react'
import { connect } from 'react-redux'
import ActionStatusForm from 'components/Customer/Forms/ActionStatusForm/ActionStatusForm'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  const [
    recordIds = [],
    status = {},
    values,
    recordType,
    actionType,
    eventName,
    completed,
  ] = getMany(state, [
    'actionStatusR.recordIds',
    'actionStatusR.status',
    'actionStatusR.values',
    'actionStatusR.recordType',
    'actionStatusR.actionType',
    'actionStatusR.eventName',
    'actionStatusR.completed',
  ])

  return {
    credentials,
    recordIds,
    status,
    values,
    recordType,
    actionType,
    eventName,
    completed,
  }
}

class ActionStatusFormC extends Component {
  closeModal = () => {
    const { dispatch } = this.props

    dispatch(hideModalAction())
  }

  confirmationAction = () => {
    const { dispatch, actionType, recordIds, values, status, eventName, credentials } = this.props

    dispatch({
      type: actionType,
      payload: {
        credentials,
        values: {
          payloadValues: values,
          recordIds,
        },
      },
      meta: {
        actionId: sendAmplitudeActionEvent(eventName, {
          credentials,
          recordIds,
          status,
          values,
        }),
      },
    })
  }

  render() {
    const { confirmationAction, closeModal } = this

    return (
      <ActionStatusForm
        {...this.props}
        confirmationAction={confirmationAction}
        closeModal={closeModal}
      />
    )
  }
}

export default connect(mapStateToProps)(ActionStatusFormC)
