import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import CreatePaymentLinkForm from 'components/Customer/Forms/CreatePaymentLinkForm/CreatePaymentLinkForm'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getUrlQuery from 'utilities/get/getUrlQuery'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { CREATE_PAYMENT_LINK_FORM } from 'constants/formConstants'
import { FINIX_NAV_BAR_LOGO } from 'constants/logoConstants'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATION } from 'constants/dashboardConfigurationsConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import get from 'lodash/get'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import head from 'lodash/head'

import {
  BANK_ACCOUNT,
  PAYMENT_CARD,
} from 'constants/paymentLinkConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

import {
  USD,
  currenciesWithCents,
} from 'constants/currencyConstants'

import {
  FEATURE_NOT_AVAILABLE_MODAL,
  SELECT_PAYMENT_LINK_MERCHANT_MODAL,
} from 'constants/modalConstants'

import {
  getFormValues,
  getMerchantsByApplicationIdSelector,
  getMerchantSelector,
} from 'state-layer/selectors'

import {
  AMOUNT_TYPE_FIXED_OPTION_LABEL,
  AMOUNT_TYPE_VARIABLE_OPTION_LABEL,
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_PAYMENT_LINKS,
  PAYMENT_LINKS_ARE_NOT_AVAILABLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const currentUserEmail = get(currentUser, 'email')
  const isPosting = isPatching(state)
  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const maxTransactionAmount = get(merchant, 'merchantIdentity.entity.max_transaction_amount')
  const maxACHTransactionAmount = get(merchant, 'merchantIdentity.entity.ach_max_transaction_amount')
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, true)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const isFetchingMerchants = get(state, `merchantsR.${FETCHING}`, true)
  const isFetching = isFetchingConfiguration || isFetchingCurrentUser
  const currency = get(merchant, 'currency', USD)

  const dashboardConfig = getCurrentDashboardConfig(state)
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const filteredApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))

  const [
    currentPaymentLinkLogo,
    currentPaymentLinkHeaderColor,
    currentPaymentLinkButtonBackgroundColor = '#0B5DBC',
    currentPaymentLinkButtonTextColor = '#FFFFFF',
    currentSidebarLogoImageUrl = FINIX_NAV_BAR_LOGO,
    currentSidebarBackgroundColor = '#0D121A',
    paymentLinkTemplate,
  ] = getMany(dashboardConfig, [
    'paymentLinkLogo',
    'paymentLinkHeaderColor',
    'paymentLinkButtonBackgroundColor',
    'paymentLinkButtonTextColor',
    'sidebarLogoImageUrl',
    'sidebarBackgroundColor',
    'paymentLinkTemplate',
  ])

  const [
    amountType,
    currentItems = [],
    collectBuyerDetails,
    expiresIn,
    collectName,
    collectPhoneNumber,
    collectShippingAddress,
    collectBillingAddress,
    termsOfServiceUrl,
    allowPaymentCard,
    allowBankTransfer,
    collectCustomFields = false,
    customFields = [],
    useCustomBranding,
    formLogoBranding,
    formHeaderColorBranding,
    formButtonBackgroundColorBranding,
    formButtonTextColorBranding,
    sendReceipt,
  ] = getFormValues(state, CREATE_PAYMENT_LINK_FORM, [
    'amountType.value',
    'items',
    'collectBuyerDetails',
    'expiresIn',
    'collectName',
    'collectPhoneNumber',
    'collectShippingAddress',
    'collectBillingAddress',
    'termsOfServiceUrl',
    'allowPaymentCard',
    'allowBankTransfer',
    'collectCustomFields',
    'customFields',
    'useCustomBranding',
    'paymentLinkLogoURL',
    'paymentLinkHeaderColor',
    'paymentLinkButtonBackgroundColor',
    'paymentLinkButtonTextColor',
    'sendReceipt',
  ])

  const paymentLinkLogo = formLogoBranding || currentPaymentLinkLogo || currentSidebarLogoImageUrl
  const headerColor = formHeaderColorBranding || currentPaymentLinkHeaderColor || currentSidebarBackgroundColor
  const buttonBackgroundColor = formButtonBackgroundColorBranding || currentPaymentLinkButtonBackgroundColor
  const buttonTextColor = formButtonTextColorBranding || currentPaymentLinkButtonTextColor

  const amountTypeOptions = [
    { label: AMOUNT_TYPE_FIXED_OPTION_LABEL, value: 'FIXED' },
    { label: AMOUNT_TYPE_VARIABLE_OPTION_LABEL, value: 'VARIABLE' },
  ]

  const initialValues = !isFetching ? {
    allowPaymentCard: true,
    allowBankTransfer: false,
    expiresIn: '168',
    collectBuyerDetails: 'email',
    quantity: '1',
    paymentLinkLogoURL: paymentLinkLogo,
    paymentLinkHeaderColor: headerColor,
    paymentLinkButtonBackgroundColor: buttonBackgroundColor,
    paymentLinkButtonTextColor: buttonTextColor,
    termsOfServiceUrl: get(paymentLinkTemplate, 'termsOfServiceUrl'),
    successReturnUrl: get(paymentLinkTemplate, 'successReturnUrl'),
    unsuccessfulReturnUrl: get(paymentLinkTemplate, 'unsuccessfulReturnUrl'),
    expiredSessionUrl: get(paymentLinkTemplate, 'expiredSessionUrl'),
    ...get(paymentLinkTemplate, 'oneTime', {}),
    amountType: find(amountTypeOptions, { value: get(paymentLinkTemplate, 'oneTime.amountType') }),
    sendReceipt: get(paymentLinkTemplate, 'sendReceipt', true),
    sendReceiptToSelf: get(paymentLinkTemplate, 'sendReceiptToSelf', false),
  } : undefined

  const isFixedAmount = amountType === 'FIXED'
  let totalAmount = 0
  let minimumAmount
  let maximumAmount

  map(currentItems, (item) => {
    const {
      quantity = '1',
      saleAmount,
      minAmount = includes(currenciesWithCents, currency) ? '0.01' : '1',
      maxAmount = maxTransactionAmount,
    } = item

    if (isFixedAmount) {
      // convert to display amount to actual amount for calculation
      const amount = displayAmountToAmount(saleAmount, currency)
      const amountMultiQuantity = (amount * parseInt(quantity, 10))
      totalAmount += amountMultiQuantity

      if (amountMultiQuantity) {
        set(item, 'totalAmount', amountToDisplayAmount(amountMultiQuantity, currency, true))
      }
    } else {
      minimumAmount = minAmount
      maximumAmount = maxAmount
    }
  })

  const allowedPaymentMethods = []

  if (allowPaymentCard) {
    allowedPaymentMethods.push(PAYMENT_CARD)
  }
  if (allowBankTransfer) {
    allowedPaymentMethods.push(BANK_ACCOUNT)
  }

  const collectBillingAddressKey = collectBillingAddress ? 'with_billing_address' : 'without_billing_address'
  const allowedPaymentMethodsKey = allowedPaymentMethods.join('_') || 'no_methods'

  const collectMoreBuyerInfo = collectBuyerDetails === 'more'

  const customFieldTags = reduce(customFields, (result, { name }, index) => {
    if (name) {
      result[`custom_field_${index + 1}`] = name
    }

    return result
  }, {})

  const previewPaymentLink = {
    collectEmail: true,
    collectName: collectMoreBuyerInfo ? collectName : false,
    collectPhoneNumber: collectMoreBuyerInfo ? collectPhoneNumber : false,
    collectShippingAddress: collectMoreBuyerInfo ? collectShippingAddress : false,
    collectBillingAddress: collectMoreBuyerInfo ? collectBillingAddress : false,
    termsOfServiceUrl,
    brandColor: headerColor,
    accentColor: buttonBackgroundColor,
    buttonFontColor: buttonTextColor,
    logo: paymentLinkLogo || currentSidebarLogoImageUrl,
    items: currentItems,
    amountType,
    allowedPaymentMethods,
    displayTotalAmount: amountToDisplayAmount(totalAmount, currency, true),
    tags: collectCustomFields ? customFieldTags : undefined,
    useCustomBranding,
    sendReceipt,
  }

  return {
    amountType,
    amountTypeOptions,
    applicationId,
    initialValues,
    collectMoreBuyerDetails: collectBuyerDetails === 'more',
    showExpirationDatePicker: expiresIn === 'custom',
    credentials,
    currentItems,
    isPosting,
    merchantId,
    maxTransactionAmount,
    maxACHTransactionAmount,
    previewPaymentLink,
    useCustomBranding,
    paymentLinkLogo,
    headerColor,
    buttonBackgroundColor,
    buttonTextColor,
    isFetching,
    currentPaymentLinkLogo,
    currentPaymentLinkHeaderColor: currentPaymentLinkHeaderColor || currentSidebarBackgroundColor,
    currentPaymentLinkButtonBackgroundColor,
    currentPaymentLinkButtonTextColor,
    guestPaymentLinkComponentKey: `${allowedPaymentMethodsKey}_${collectBillingAddressKey}_${collectBuyerDetails}`,
    collectCustomFields,
    currency,
    paymentLinkTemplate,
    currentUserEmail,
    isFetchingMerchants,
    applicationMerchants,
    allowedProcessors,
    filteredApplicationMerchants,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => dispatch(change(CREATE_PAYMENT_LINK_FORM, fieldName, color.hex)),
    updateField: (fieldName, value) => dispatch(change(CREATE_PAYMENT_LINK_FORM, fieldName, value)),
    showModal: (props) => dispatch(showModalAction(props)),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    showFeatureNotAvailableModal: () => dispatch(showModalAction({
      modalType: FEATURE_NOT_AVAILABLE_MODAL,
      modalProps: {
        canCloseModal: false,
        title: PAYMENT_LINKS_ARE_NOT_AVAILABLE,
        className: 'PaymentLinksNotAvailableModal',
        description: MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_PAYMENT_LINKS,
      },
      className: 'modal-md no-pad',
    })),
  }
}

class CreatePaymentLinkFormC extends Component {
  state = {
    isMultipleUse: false,
    displayHeaderColorPicker: false,
    displayButtonBackgroundColorPicker: false,
    displayButtonTextColorPicker: false,
  }

  componentDidMount() {
    const {
      merchantId,
      credentials,
      getMerchant,
      getApplicationMerchants,
      applicationId,
    } = this.props

    if (!merchantId) {
      if (applicationId) {
        getApplicationMerchants({ applicationId, credentials })
      }
    }

    if (merchantId) {
      getMerchant({ merchantId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingCurrentUser: prevIsFetchingCurrentUser,
      useCustomBranding: prevUseCustomBranding,
      merchantId: prevMerchantId,
      isFetchingMerchants: prevIsFetchingMerchants,
    } = prevProps

    const {
      getDashboardCustomization,
      isFetchingCurrentUser,
      applicationId,
      useCustomBranding,
      updateField,
      currentPaymentLinkLogo,
      currentPaymentLinkHeaderColor,
      currentPaymentLinkButtonBackgroundColor,
      currentPaymentLinkButtonTextColor,
      merchantId,
      isFetchingMerchants,
      applicationMerchants,
      allowedProcessors,
      showModal,
      credentials,
      getMerchant,
      showFeatureNotAvailableModal,
      filteredApplicationMerchants,
    } = this.props

    const filteredApplicationMerchantsSize = size(filteredApplicationMerchants)

    if (!merchantId) {
      if (prevIsFetchingMerchants === true && isFetchingMerchants === false && !isEmpty(applicationMerchants)) {
        if (filteredApplicationMerchantsSize === 0) {
          showFeatureNotAvailableModal()
        }

        if (filteredApplicationMerchantsSize === 1) {
          const { id } = head(filteredApplicationMerchants)
          updateUrlQueries({ merchantId: id })
        }

        if (filteredApplicationMerchantsSize > 1) {
          showModal({
            modalType: SELECT_PAYMENT_LINK_MERCHANT_MODAL,
            className: 'modal-md no-pad overflow-visible',
            modalProps: { canCloseModal: false },
          })
        }
      }
    }

    if (merchantId && !isEqual(prevMerchantId, merchantId)) {
      getMerchant({ merchantId, credentials })
    }

    if (applicationId && !isEqual(prevIsFetchingCurrentUser, isFetchingCurrentUser) && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId: applicationId, entityType: APPLICATION })
    }

    if (!isEqual(prevUseCustomBranding, useCustomBranding) && !useCustomBranding) {
      updateField('paymentLinkLogoURL', currentPaymentLinkLogo)
      updateField('paymentLinkHeaderColor', currentPaymentLinkHeaderColor)
      updateField('paymentLinkButtonBackgroundColor', currentPaymentLinkButtonBackgroundColor)
      updateField('paymentLinkButtonTextColor', currentPaymentLinkButtonTextColor)
    }
  }

  setMultipleUse = () => {
    const { updateField, paymentLinkTemplate, amountTypeOptions } = this.props
    this.setState({ isMultipleUse: true })
    updateField('amountType', find(amountTypeOptions, { value: get(paymentLinkTemplate, 'multiUse.amountType', null) }))
    updateField('allowPaymentCard', get(paymentLinkTemplate, 'multiUse.allowPaymentCard') || null)
    updateField('allowBankTransfer', get(paymentLinkTemplate, 'multiUse.allowBankTransfer') || null)
    updateField('expiresIn', get(paymentLinkTemplate, 'multiUse.expiresIn') || '26280')
    updateField('expirationDate', get(paymentLinkTemplate, 'multiUse.expirationDate') || null)
    updateField('collectBuyerDetails', get(paymentLinkTemplate, 'multiUse.collectBuyerDetails') || null)
    updateField('collectName', get(paymentLinkTemplate, 'multiUse.collectName') || null)
    updateField('collectPhoneNumber', get(paymentLinkTemplate, 'multiUse.collectPhoneNumber') || null)
    updateField('collectShippingAddress', get(paymentLinkTemplate, 'multiUse.collectShippingAddress') || null)
    updateField('collectBillingAddress', get(paymentLinkTemplate, 'multiUse.collectBillingAddress') || null)
    updateField('collectCustomFields', get(paymentLinkTemplate, 'multiUse.collectCustomFields') || null)
    updateField('customFields', get(paymentLinkTemplate, 'multiUse.customFields') || null)
  }

  setSingleUse = () => {
    const { updateField, amountTypeOptions, paymentLinkTemplate } = this.props
    this.setState({ isMultipleUse: false })
    updateField('amountType', find(amountTypeOptions, { value: get(paymentLinkTemplate, 'multiUse.amountType', null) }))
    updateField('allowPaymentCard', get(paymentLinkTemplate, 'oneTime.allowPaymentCard') || null)
    updateField('allowBankTransfer', get(paymentLinkTemplate, 'oneTime.allowBankTransfer') || null)
    updateField('expiresIn', get(paymentLinkTemplate, 'oneTime.expiresIn') || '168')
    updateField('expirationDate', get(paymentLinkTemplate, 'oneTime.expirationDate') || null)
    updateField('collectBuyerDetails', get(paymentLinkTemplate, 'oneTime.collectBuyerDetails') || null)
    updateField('collectName', get(paymentLinkTemplate, 'oneTime.collectName') || null)
    updateField('collectPhoneNumber', get(paymentLinkTemplate, 'oneTime.collectPhoneNumber') || null)
    updateField('collectShippingAddress', get(paymentLinkTemplate, 'oneTime.collectShippingAddress') || null)
    updateField('collectBillingAddress', get(paymentLinkTemplate, 'oneTime.collectBillingAddress') || null)
    updateField('collectCustomFields', get(paymentLinkTemplate, 'oneTime.collectCustomFields') || null)
    updateField('customFields', get(paymentLinkTemplate, 'oneTime.customFields') || null)
  }

  handleClick = (fieldName) => {
    const {
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    if (fieldName === 'displayHeaderColorPicker') {
      this.setState({ displayHeaderColorPicker: !displayHeaderColorPicker })
    }
    if (fieldName === 'displayButtonBackgroundColorPicker') {
      this.setState({ displayButtonBackgroundColorPicker: !displayButtonBackgroundColorPicker })
    }
    if (fieldName === 'displayButtonTextColorPicker') {
      this.setState({ displayButtonTextColorPicker: !displayButtonTextColorPicker })
    }
  }

  handleClose = (fieldName) => {
    this.setState({ [fieldName]: false })
  }

  render() {
    const {
      isMultipleUse,
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    return (
      <CreatePaymentLinkForm
        {...this.props}
        isMultipleUse={isMultipleUse}
        setMultipleUse={this.setMultipleUse}
        setSingleUse={this.setSingleUse}
        displayHeaderColorPicker={displayHeaderColorPicker}
        displayButtonBackgroundColorPicker={displayButtonBackgroundColorPicker}
        displayButtonTextColorPicker={displayButtonTextColorPicker}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentLinkFormC)
