import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATCH_CREDENTIAL_F_REQUEST } from 'constants/flowConstants'

const patchCredentialRequest = ({
  credentials,
  memberId,
  values,
  memberName,
  amplitudeActionType,
  successMessage,
  dashboardName,
}) => createAction({
  type: PATCH_CREDENTIAL_F_REQUEST,
  id: memberId,
  credentials,
  values,
  meta: removeUndefined({
    memberName,
    actionId: sendAmplitudeActionEvent(amplitudeActionType, removeUndefined({
      credentials,
      values,
      dashboardName,
      memberId,
    })),
    successMessage,
  }),
})

export default patchCredentialRequest
