import { GET_SPLIT_TRANSFERS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSplitTransfersRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_SPLIT_TRANSFERS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getSplitTransfersRequest
