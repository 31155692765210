import React, { Component } from 'react'
import { connect } from 'react-redux'
import QuickFilterPage from './QuickFilterPage'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import trim from 'lodash/trim'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

class QuickFilterPageC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterQuery: '',
    }
  }

  updateFilter = (e) => {
    this.setState({ filterQuery: e.target.value })
  }

  removeFilter = () => {
    this.setState({ filterQuery: '' })
  }

  updateFilteredRecords = (
    records = [],
    query = '',
    func,
    field,
  ) => {
    if (query.length <= 0) {
      return records
    }

    if (func) {
      return filter(records, (record) => func(record, query))
    }

    return filter(records, (record) => {
      const filedName = field || 'name'
      const formattedFileValue = get(record, filedName, '').toLowerCase()
      const formattedQuery = trim(query.toLowerCase())

      return includes(formattedFileValue, formattedQuery)
    })
  }

  render() {
    const { updateFilteredRecords, updateFilter, removeFilter } = this
    const { filterQuery } = this.state
    const { records, filterFunc, filterField } = this.props

    const filteredRecords = updateFilteredRecords(records, filterQuery, filterFunc, filterField)

    return (
      <QuickFilterPage
        {...this.props}
        filterQuery={filterQuery}
        filteredRecords={filteredRecords}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
      />
    )
  }
}

export default connect(mapStateToProps)(QuickFilterPageC)
