import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISPUTE_EVIDENCES } from 'constants/apiConstants'

const postDisputeEvidencesAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: DISPUTE_EVIDENCES({ disputeId: id }),
  })
}

export default postDisputeEvidencesAPI
