import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import removeUndefined from 'utilities/remove/removeUndefined'

const frontendApplicationProcessorConfigModel = ({ data: processorConfig }) => {
  const [
    id,
    createdAt,
    updatedAt,
    applicationId,
    defaultMerchantProfile,
    enabled,
    processor,
    applicationConfig,
  ] = getMany(processorConfig, [
    'id',
    'created_at',
    'updated_at',
    'application',
    'default_merchant_profile',
    'enabled',
    'processor',
    'application_config',
  ])

  const [
    defaultCurrencies,
    achSettlementDelayDays,
    allowedBusinessApplicationIds,
    cardAcceptorIdCode,
    cardAcceptorTerminalId,
    visaConfigurationTemplates,
    visaUSADefaultSenderAddress,
    visaUSADefaultSenderCity,
    visaUSADefaultSenderCountyCode,
    visaUSADefaultSenderStateCode,
    visaUSADefaultSenderCountry,
    visaUSADefaultSenderCountryCode,
    visaUSADefaultSenderZipCode,
    visaCrossBorderConfigurationTemplates,
    visaDefaultMcc,
    visaDefaultSenderAccountNumber,
    visaDefaultSenderAddress,
    visaDefaultSenderCountry,
    visaDefaultSenderCity,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCounty,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderName,
    visaDefaultCurrencies,
    mcDefaultSenderAccountNumber,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    mcDefaultSenderPostalCode,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcSanctionScreeningOverride,
    mcCardAcceptorId,
    mcStatementDescriptor,
    mcCardPaymentType,
    mcDefaultMcc,
    mcSkipAccountVerification,
    mcDefaultCurrencies,
    mcConfigurationTemplates,
    mcConfigurationTemplatesCountryUSADefaultSenderCity,
    mcConfigurationTemplatesCountryUSADefaultSenderCountry,
    mcConfigurationTemplatesCountryUSADefaultSenderPostalCode,
    mcConfigurationTemplatesCountryUSADefaultSenderCountryCode,
    mcConfigurationTemplatesCountryUSADefaultSenderAddressLine1,
    mcConfigurationTemplatesCountryUSADefaultAddressSubdivision,
    mcConfigurationTemplatesCrossBorder,
  ] = getMany(applicationConfig, [
    'default_currencies',
    'ach_settlement_delay_days',
    'visa_application_config.allowed_business_application_ids',
    'visa_application_config.card_acceptor_id_code',
    'visa_application_config.card_acceptor_terminal_id',
    'visa_application_config.configuration_templates',
    'visa_application_config.country.USA.default_sender_address',
    'visa_application_config.country.USA.default_sender_city',
    'visa_application_config.country.USA.default_sender_county_code',
    'visa_application_config.country.USA.default_sender_state_code',
    'visa_application_config.country.USA.default_sender_country',
    'visa_application_config.country.USA.default_sender_country_code',
    'visa_application_config.country.USA.default_sender_zip_code',
    'visa_application_config.configuration_templates.cross_border',
    'visa_application_config.default_mcc',
    'visa_application_config.default_sender_account_number',
    'visa_application_config.default_sender_address',
    'visa_application_config.default_sender_country',
    'visa_application_config.default_sender_city',
    'visa_application_config.default_sender_country_code',
    'visa_application_config.default_sender_county',
    'visa_application_config.default_sender_state_code',
    'visa_application_config.default_sender_zip_code',
    'visa_application_config.default_sender_name',
    'visa_application_config.default_currencies',
    'mastercard_application_config.default_sender_account_number',
    'mastercard_application_config.default_sender_address_line1',
    'mastercard_application_config.default_sender_address_line2',
    'mastercard_application_config.default_sender_city',
    'mastercard_application_config.default_sender_country',
    'mastercard_application_config.default_sender_country_code',
    'mastercard_application_config.default_sender_postal_code',
    'mastercard_application_config.default_sender_first_name',
    'mastercard_application_config.default_sender_last_name',
    'mastercard_application_config.default_sender_full_name',
    'mastercard_application_config.sanction_screening_override',
    'mastercard_application_config.card_acceptor_id',
    'mastercard_application_config.statement_descriptor',
    'mastercard_application_config.default_payment_type',
    'mastercard_application_config.default_mcc',
    'mastercard_application_config.skip_account_verification',
    'mastercard_application_config.default_currencies',
    'mastercard_application_config.configuration_templates',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_city',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_country',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_postal_code',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_country_code',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_address_line1',
    'mastercard_application_config.configuration_templates.country.USA.default_sender_country_subdivision',
    'mastercard_application_config.configuration_templates.cross_border',
  ])

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    applicationId,
    defaultMerchantProfile,
    applicationConfig,
    enabled,
    processor,
    defaultCurrencies,
    achSettlementDelayDays,
    visa_application_config: {
      allowed_business_application_ids: allowedBusinessApplicationIds,
      card_acceptor_id_code: cardAcceptorIdCode,
      card_acceptor_terminal_id: cardAcceptorTerminalId,
      configuration_templates: {
        country: {
          USA: {
            default_sender_address: visaUSADefaultSenderAddress,
            default_sender_city: visaUSADefaultSenderCity,
            default_sender_county_code: visaUSADefaultSenderCountyCode,
            default_sender_state_code: visaUSADefaultSenderStateCode,
            default_sender_country: visaUSADefaultSenderCountry,
            default_sender_country_code: visaUSADefaultSenderCountryCode,
            default_sender_zip_code: visaUSADefaultSenderZipCode,
          },
        },
        cross_border: visaCrossBorderConfigurationTemplates,
      },
      default_mcc: visaDefaultMcc,
      default_sender_account_number: visaDefaultSenderAccountNumber,
      default_sender_address: visaDefaultSenderAddress,
      default_sender_city: visaDefaultSenderCity,
      default_sender_country: visaDefaultSenderCountry,
      default_sender_country_code: visaDefaultSenderCountryCode,
      default_sender_county_code: visaDefaultSenderCounty,
      default_sender_state_code: visaDefaultSenderStateCode,
      default_sender_zip_code: visaDefaultSenderZipCode,
      default_sender_name: visaDefaultSenderName,
      default_currencies: visaDefaultCurrencies,
    },
    mastercard_application_config: {
      default_sender_account_number: mcDefaultSenderAccountNumber,
      default_sender_address_line1: mcDefaultSenderAddressLine1,
      default_sender_address_line2: mcDefaultSenderAddressLine2,
      default_sender_city: mcDefaultSenderCity,
      default_sender_country: mcDefaultSenderCountry,
      default_sender_country_code: mcDefaultSenderCountryCode,
      default_sender_postal_code: mcDefaultSenderPostalCode,
      default_sender_first_name: mcDefaultSenderFirstName,
      default_sender_last_name: mcDefaultSenderLastName,
      default_sender_full_name: mcDefaultSenderFullName,
      sanction_screening_override: mcSanctionScreeningOverride,
      card_acceptor_id: mcCardAcceptorId,
      statement_descriptor: mcStatementDescriptor,
      default_payment_type: mcCardPaymentType,
      default_mcc: mcDefaultMcc,
      skip_account_verification: mcSkipAccountVerification,
      default_currencies: mcDefaultCurrencies,
      configuration_templates: {
        country: {
          USA: {
            default_sender_city: mcConfigurationTemplatesCountryUSADefaultSenderCity,
            default_sender_country: mcConfigurationTemplatesCountryUSADefaultSenderCountry,
            default_sender_postal_code: mcConfigurationTemplatesCountryUSADefaultSenderPostalCode,
            default_sender_country_code: mcConfigurationTemplatesCountryUSADefaultSenderCountryCode,
            default_sender_address_line1: mcConfigurationTemplatesCountryUSADefaultSenderAddressLine1,
            default_sender_address_subdivision: mcConfigurationTemplatesCountryUSADefaultAddressSubdivision,
          },
        },
        cross_border: mcConfigurationTemplatesCrossBorder,
      },
    },
  })
}

export default frontendApplicationProcessorConfigModel
