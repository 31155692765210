import './CombinationPlansFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import Button from 'components/Shared/Button/Button'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import getMany from 'utilities/get/getMany'
import convertCombinationPlanEquationArrayToString from 'utilities/convert/convertCombinationPlanEquationArrayToString'
import validateCombinationPlansForm from 'utilities/validate/validateCombinationPlansForm'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import filter from 'lodash/filter'
import get from 'lodash/get'
import { COMBINATION_PLANS_FORM } from 'constants/formConstants'

import {
  ADD_COMBINATION_PLAN,
  ADD_TERM,
} from 'constants/language/languageConstants'

import {
  levelOptions,
  instrumentLabelOptions,
  railOptions,
  submissionSettingsOptions,
  DEFERRED,
} from 'constants/payoutPlanConstants'

const renderEquation = ({
  fields,
  templateOptions,
}) => (
  <ul>
    <li>
      <Button onClick={() => fields.push({})} label={ADD_TERM} />
    </li>

    <li>
      <ul className='terms flex wrap'>
        {fields.map((variable, index) => (
          <li className='term'>
            <span
              className='removeTerm fa fa-times'
              onClick={() => fields.remove(index)}
            />

            <div className='flex'>
              <Field
                name={`${variable}.coefficient`}
                type='text'
                component={InputField}
                placeholder='Coefficient'
              />

              <Field
                name={`${variable}.template`}
                component={SelectField}
                options={templateOptions}
              />
            </div>
          </li>
        ))}
      </ul>
    </li>
  </ul>
)

const renderCombinationPlans = ({
  fields,
  combinationPlansValues,
  templateOptions,
  meta: {
    error: errors = [],
    submitFailed,
  },
}) => {
  return (
    <ul className='combinationPlans'>
      <li className='flex center'>
        <Button onClick={() => fields.push({})} label={ADD_COMBINATION_PLAN} />
      </li>

      {fields.map((combinationPlan, index) => {
        const [
          name,
          submissionSettingsType,
          sourceInstrumentLabel,
          destinationInstrumentLabel,
          equation,
        ] = getMany(combinationPlansValues, [
          `${index}.name`,
          `${index}.submissionSettings.type`,
          `${index}.sourceInstrumentLabel`,
          `${index}.destinationInstrumentLabel`,
          `${index}.equation`,
        ])

        const sourceInstrumentLabelOptions = filter(instrumentLabelOptions, (instrumentLabelOption) => get(instrumentLabelOption, 'value') !== destinationInstrumentLabel)
        const destinationInstrumentLabelOptions = filter(instrumentLabelOptions, (instrumentLabelOption) => get(instrumentLabelOption, 'value') !== sourceInstrumentLabel)
        const equationString = convertCombinationPlanEquationArrayToString(equation)

        return (
          <li className='combinationPlan'>
            <div className='header flex space-between'>
              <h4>Combination Plan #{index + 1}</h4>

              <span
                className='removeCombinationPlan fa fa-times'
                onClick={() => fields.remove(index)}
              />
            </div>

            <div className='contents'>
              <Field
                name={`${combinationPlan}.name`}
                type='text'
                component={InputField}
                label='Name'
              />

              <Field
                name={`${combinationPlan}.level`}
                component={SelectField}
                options={levelOptions}
                label='Level'
              />

              <Field
                name={`${combinationPlan}.sourceInstrumentLabel`}
                component={SelectField}
                options={sourceInstrumentLabelOptions}
                label='Source Instrument Label'
              />

              <Field
                name={`${combinationPlan}.destinationInstrumentLabel`}
                component={SelectField}
                options={destinationInstrumentLabelOptions}
                label='Destination Instrument Label'
              />

              <Field
                name={`${combinationPlan}.rail`}
                component={SelectField}
                options={railOptions}
                label='Rail'
              />

              <Field
                name={`${combinationPlan}.submissionSettings.type`}
                component={SelectField}
                options={submissionSettingsOptions}
                label='Submission Settings'
              />

              {submissionSettingsType === DEFERRED && (
                <Field
                  name={`${combinationPlan}.submissionSettings.submissionOffsetBusinessDays`}
                  component={InputField}
                  label='Submission Settings Offset Business Days'
                />
              )}

              <div className='equation'>
                <h3>Equation</h3>

                <FieldArray
                  name={`${combinationPlan}.equation`}
                  component={renderEquation}
                  templateOptions={templateOptions}
                />

                <PageSection
                  title={`${name ? name : `Combination Plan ${index + 1}`} Equation`}
                  component={PrettyJSON}
                  data={equationString}
                />
              </div>
            </div>
          </li>
        )
      })}

      {!isEmpty(errors) && submitFailed && map(errors, error => (<div className='payoutPlanError'>{error}</div>))}
    </ul>
  )
}

const CombinationPlansForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  combinationPlansValues = [],
  templateOptions = [],
  buttonGroup,
}) => {
  return (
    <form className='CombinationPlansForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h4>Combination Plans</h4>

        <FieldArray
          name='combinationPlans'
          component={renderCombinationPlans}
          combinationPlansValues={combinationPlansValues}
          templateOptions={templateOptions}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

CombinationPlansForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  combinationPlansValues: PropTypes.array,
  templateOptions: PropTypes.array,
}

export default reduxForm({
  form: COMBINATION_PLANS_FORM,
  validate: validateCombinationPlansForm,
})(CombinationPlansForm)
