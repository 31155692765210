import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORM_CONTRACT } from 'constants/apiConstants'

const getAccessFormContractAPI = ({ credentials, id, meta }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_ACCESS_FORM_CONTRACT({ id }),
    meta,
  })
}

export default getAccessFormContractAPI
