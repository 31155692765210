import './WithdrawLiveApplicationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  WITHDRAW,
  WITHDRAW_APPLICATION_CONFIRMATION_MSG,
  WITHDRAW_APPLICATION_WARNING_MSG,
  CANCEL,
} from 'constants/language/languageConstants'

const WithdrawLiveApplication = ({
  accessFormId = '',
  closeModal = () => {},
  withdrawAccessForm = () => {},
}) => {
  return (
    <div className='WithdrawLiveApplication'>
      <div className='text'>{WITHDRAW_APPLICATION_CONFIRMATION_MSG}</div>
      <div className='text'>{WITHDRAW_APPLICATION_WARNING_MSG}</div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button label={WITHDRAW} onClick={() => withdrawAccessForm({ accessFormId })} />
      </div>
    </div>
  )
}

WithdrawLiveApplication.propTypes = {
  accessFormId: PropTypes.string,
  closeModal: PropTypes.func,
  withdrawAccessForm: PropTypes.func,
}

export default WithdrawLiveApplication
