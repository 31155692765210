import './EditVisaPayoutConfigModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import EditApplicationPayoutConfigsFormC from 'components/Customer/Forms/EditApplicationPayoutConfigsForm/EditApplicationPayoutConfigsFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { EDIT_VISA_APPLICATION_CONFIGS } from 'constants/language/languageConstants'

const EditVisaPayoutConfigModal = ({
  credentialId,
  applicationId,
  visaAllowedBusinessApplicationIds,
  visaCardAcceptorIdCode,
  visaAcceptorTerminalId,
  visaDefaultCurrencies,
  visaDefaultMcc,
  visaDefaultSenderName,
  visaDefaultSenderAddress,
  visaDefaultSenderAccountNumber,
  visaDefaultSenderCity,
  visaDefaultSenderStateCode,
  visaDefaultSenderZipCode,
  visaDefaultSenderCountryCode,
  visaDefaultSenderCountry,
  isVisa = true,
}) => {
  return (
    <GenericModal
      className='EditVisaPayoutConfigModal'
      title={EDIT_VISA_APPLICATION_CONFIGS}
      Component={EditApplicationPayoutConfigsFormC}
      credentialId={credentialId}
      applicationId={applicationId}
      visaAllowedBusinessApplicationIds={visaAllowedBusinessApplicationIds}
      visaCardAcceptorIdCode={visaCardAcceptorIdCode}
      visaAcceptorTerminalId={visaAcceptorTerminalId}
      visaDefaultMcc={visaDefaultMcc}
      visaDefaultSenderName={visaDefaultSenderName}
      visaDefaultCurrencies={visaDefaultCurrencies}
      visaDefaultSenderAddress={visaDefaultSenderAddress}
      visaDefaultSenderAccountNumber={visaDefaultSenderAccountNumber}
      visaDefaultSenderCity={visaDefaultSenderCity}
      visaDefaultSenderStateCode={visaDefaultSenderStateCode}
      visaDefaultSenderZipCode={visaDefaultSenderZipCode}
      visaDefaultSenderCountryCode={visaDefaultSenderCountryCode}
      visaDefaultSenderCountry={visaDefaultSenderCountry}
      isVisa={isVisa}
    />
  )
}

EditVisaPayoutConfigModal.propTypes = {
  credentialId: PropTypes.string,
  applicationId: PropTypes.string,
  visaAllowedBusinessApplicationIds: PropTypes.array,
  visaCardAcceptorIdCode: PropTypes.string,
  visaAcceptorTerminalId: PropTypes.string,
  visaDefaultMcc: PropTypes.string,
  visaDefaultSenderName: PropTypes.string,
  visaDefaultCurrencies: PropTypes.array,
  visaDefaultSenderAddress: PropTypes.string,
  visaDefaultSenderAccountNumber: PropTypes.string,
  visaDefaultSenderCity: PropTypes.string,
  visaDefaultSenderStateCode: PropTypes.string,
  visaDefaultSenderZipCode: PropTypes.string,
  visaDefaultSenderCountryCode: PropTypes.string,
  visaDefaultSenderCountry: PropTypes.string,
  isVisa: PropTypes.bool,
}
export default EditVisaPayoutConfigModal
