import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { HOSTED_PAYMENTS_CHECKOUT_FORM } from 'constants/apiConstants'

const getGuestCheckoutFormAPI = ({ id }) => {
  return guestCheckoutAPI.get({
    path: HOSTED_PAYMENTS_CHECKOUT_FORM({ checkoutFormId: id }),
  })
}

export default getGuestCheckoutFormAPI
