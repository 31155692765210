import './VerticalFlowsS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

const VerticalFlows = ({
  activeStep,
  clickForStep,
  clickForNextStep,
  clickForPreviousStep,
  credentials,
  dispatcher,
  progress,
  steps,
  formValues,
  skipStep,
  skipStatus = {},
  flowClassName = '',
  hideFlowProgress = false,
  showProgressString = false,
  cancelProgress = () => {},
  disabledLeftButton = false,
  disabledRightButton = false,
  edit = false,
  modalPlacement = false,
  isInvalidForm = false,
  title = '',
  flowVariant = 'form-page',
}) => {
  const activeStepConfig = get(steps, activeStep, {})

  const {
    component: ActiveComponent = () => <div />,
    props = {},
    dataKey = '',
    optional = false,
    initialValues = {},
  } = activeStepConfig

  const componentValues = get(formValues, dataKey) || initialValues
  const skipStatusForStep = get(skipStatus, activeStep, false)
  const skipStepMsg = skipStatusForStep ? 'This step has been skipped' : 'This step is optional'
  const skipButtonTxt = skipStatusForStep ? 'Undo Skip' : 'Skip to Next'
  const totalSteps = steps.length
  const progressString = `${activeStep + 1} of ${totalSteps}`
  const leftButtonLabel = activeStep === 0 ? 'Cancel' : 'Previous'
  const leftButtonAction = activeStep === 0 ? cancelProgress : clickForPreviousStep
  const flowClassnames = classnames(flowClassName, flowVariant, { 'modal-placement': modalPlacement })
  const leftButton = get(steps, `${activeStep}.props.leftButton`)
  const leftButtonDisabled = get(steps, `${activeStep}.props.leftButtonDisabled`)
  const leftButtonClassnames = classnames('left-button', { danger: leftButtonLabel === 'Cancel', disabled: leftButtonDisabled })
  const rightButtonAction = get(steps, `${activeStep}.props.rightButtonAction`)
  const submitLabel = get(steps, `${activeStep}.props.submitLabel`)
  const lastStep = (activeStep + 1) === totalSteps

  const buttonGroup = (
    <div className='buttons flex space-between items-center'>
      <div className='left flex flex-start'>
        { leftButton && <Button variant='tertiary' className={leftButtonClassnames} disabled={leftButtonDisabled} onClick={leftButtonAction} label={leftButtonLabel} /> }
      </div>

      {showProgressString && <div className='progress-string'>{progressString}</div>}

      <div className='right flex flex-end'>
        <Button type='submit' label={submitLabel} disabled={disabledRightButton || isInvalidForm} />
      </div>
    </div>
  )

  return (
    <div className={`VerticalFlows column flex flex-start ${flowClassnames}`}>
      {title && <h6 className='header-title'>{title}</h6>}

      {!hideFlowProgress && (
        <div className='flex flow-progress'>
          { map(steps, (step, index) => {
            const {
              name,
              iconName = 'fa-dot-circle',
              stepTitle = '',
            } = step

            const separationNeeded = index > 0

            const progressClassnames = classnames('progress-session flex column', {
              'past-step': index < activeStep,
              'active-step': index === activeStep,
              'future-step': index > activeStep,
              'modal-placement': modalPlacement,
              'last-step': (index + 1) === steps.length,
            })

            const isOptional = get(step, 'optional', false)

            const stepName = modalPlacement ? `${stepTitle}` : `${name}`

            return (
              <Fragment key={kebabCase(`${name}-${index}`)}>
                <div className='flex column items-center center step-container'>
                  <div>
                    <div key={`${name}-${index}`} className={`step ${progressClassnames}`}>
                      <div className='step-number'>{index + 1}</div>
                    </div>
                  </div>
                  <div className={`step-name ${progressClassnames}`}>{stepName}</div>
                  {isOptional && <div className={`step-optional flex ${progressClassnames}`}>Optional</div>}
                </div>
              </Fragment>
            )
          }) }
        </div>
      )}

      <div className='flow-container content-card'>
        { optional && (
          <div className='flex flex-end skip-controller'>
            <span className='message'>{skipStepMsg}</span>
            <Button variant='tertiary' className='skip-button' onClick={() => skipStep(activeStep, !skipStatusForStep)} label={skipButtonTxt} />
          </div>
        )}

        { skipStatusForStep && (
          <div className='flex space-between flow-controller'>
            <Button variant='secondary' onClick={clickForPreviousStep} label={BACK} />
            <Button onClick={clickForNextStep} label={NEXT} />
          </div>
        )}

        { !skipStatusForStep && (
          <ActiveComponent
            flows
            onSubmit={rightButtonAction ? rightButtonAction : clickForNextStep}
            initialValues={componentValues}
            formValues={formValues}
            steps={steps}
            skipStatus={skipStatus}
            goToStep={clickForStep}
            credentials={credentials}
            dispatch={dispatcher}
            buttonGroup={buttonGroup}
            leftButtonLabel={leftButtonLabel}
            leftButtonAction={leftButtonAction}
            activeStep={activeStep}
            lastStep={lastStep}
            {...props}
          />
        )}
      </div>
    </div>
  )
}

VerticalFlows.propTypes = {
  activeStep: PropTypes.number,
  clickForStep: PropTypes.func,
  clickForNextStep: PropTypes.func,
  clickForPreviousStep: PropTypes.func,
  credentials: PropTypes.object,
  dispatcher: PropTypes.func,
  progress: PropTypes.number,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  skipStep: PropTypes.func,
  skipStatus: PropTypes.object,
  hideFlowProgress: PropTypes.bool,
  showProgressString: PropTypes.bool,
  cancelProgress: PropTypes.func,
  rightButtonAction: PropTypes.func,
  disabledLeftButton: PropTypes.bool,
  disabledRightButton: PropTypes.bool,
  edit: PropTypes.bool,
  isInvalidForm: PropTypes.bool,
  title: PropTypes.string,
}

export default VerticalFlows
