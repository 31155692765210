import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateOnboardingFormDetailsForm from 'components/Customer/Forms/CreateOnboardingFormDetailsForm/CreateOnboardingFormDetailsForm'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import getMany from 'utilities/get/getMany'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hideModalAction from 'utilities/actions/hideModalAction'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import getApplicationProcessorsRequest from 'utilities/actions/get/getApplicationProcessorsRequest'
import { onboardingProcessorOptions } from 'constants/onboardingFormConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import toString from 'lodash/toString'
import values from 'lodash/values'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'

import {
  getProcessorsByApplicationId,
  getUnderwritingProfilesSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = get(props, 'credentials')
  const isFetchingProcessors = get(state, `processorsR.${FETCHING}`)
  const env = get(credentials, 'environment')
  const formValues = get(state, 'flowsR.formValues.createOnboardingFormBasicsForm')
  const applicationId = get(formValues, 'applicationId.value') || get(getCurrentUser(), 'applicationId')
  const country = get(formValues, 'country')
  const isCanadianOnboardingForm = country === 'CAN'
  const applicationUnderwritingProfile = get(values(getUnderwritingProfilesSelector(state)), '[0]', {})
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)
  const applicationAvailableProcessorNames = map(getProcessorsByApplicationId(state, applicationId), ({ processor }) => processor)
  const allCheckboxOptions = onboardingProcessorOptions(env, country, isStandaloneUnderwriting)
  const processorCheckboxOptions = filter(allCheckboxOptions, (option) => {
    const checkboxProcessorName = get(option, 'value').split('-')[0]

    return includes(applicationAvailableProcessorNames, checkboxProcessorName)
  })

  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileMaxACHTransactionAmount,
    displayUnderwritingProfileMaxTransactionAmount,
    displayUnderwritingProfileMaxACHTransactionAmount,
  ] = getMany(applicationUnderwritingProfile, [
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
  ])

  const initialValues = (underwritingProfileMaxTransactionAmount && underwritingProfileMaxACHTransactionAmount) ? {
    maxTransactionAmount: toString(underwritingProfileMaxTransactionAmount),
    maxACHTransactionAmount: toString(underwritingProfileMaxACHTransactionAmount),
  } : undefined

  return {
    credentials,
    applicationId,
    processorCheckboxOptions,
    underwritingProfileMaxACHTransactionAmount,
    underwritingProfileMaxTransactionAmount,
    displayUnderwritingProfileMaxTransactionAmount,
    displayUnderwritingProfileMaxACHTransactionAmount,
    initialValues,
    isCanadianOnboardingForm,
    isFetchingProcessors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
    getApplicationProcessors: ({ credentials, applicationId, meta }) => dispatch(getApplicationProcessorsRequest({ credentials, applicationId, meta })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CreateOnboardingFormDetailsFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationUnderwritingProfile,
      getApplicationProcessors,
    } = this.props

    if (applicationId) {
      getApplicationUnderwritingProfile({
        credentials,
        queries: {
          linked_to: applicationId,
        },
      })

      getApplicationProcessors({
        credentials,
        applicationId,
        meta: {
          overwriteReducer: true,
        },
      })
    }
  }

  render() {
    return (
      <CreateOnboardingFormDetailsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOnboardingFormDetailsFormC)
