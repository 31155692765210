import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, hasSubmitFailed, isInvalid } from 'redux-form'
import IdentityBusinessInfoForm from './IdentityBusinessInfoForm'
import { getOnboardingFormDataItemSelector } from 'state-layer/selectors'
import { IDENTITY_BUSINESS_INFO_FORM } from 'constants/formConstants'
import { CAN } from 'constants/countryConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import includes from 'lodash/includes'
import size from 'lodash/size'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
} from 'constants/identityConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(IDENTITY_BUSINESS_INFO_FORM)
  const businessType = formSelector(state, 'businessData.businessType')
  const onboardingFormId = get(props, 'onboardingFormId')
  const onboardingFormCountry = get(props, 'onboardingFormCountry')
  const hideOwnershipTypeDropdown = includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)
  const onboardingForm = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const businessData = get(onboardingForm, 'businessData')
  const isCanadianSoleProp = businessType === INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE && onboardingFormCountry === CAN
  const isFormInvalid = isInvalid(IDENTITY_BUSINESS_INFO_FORM)(state) && hasSubmitFailed(IDENTITY_BUSINESS_INFO_FORM)(state)
  const processors = get(onboardingForm, 'processorGatewayDetails.processors')
  const isBoardingToFinixV1Only = size(processors) === 1 && head(processors) === FINIX_V1
  // these keys will always be sent through to the API in our submit function
  const keysToIgnore = ['businessData.businessAddress.country']

  return {
    businessType,
    hideOwnershipTypeDropdown,
    initialValues: { businessData },
    isFormInvalid,
    keysToIgnore,
    isCanadianSoleProp,
    isBoardingToFinixV1Only,
  }
}

class IdentityBusinessInfoFormC extends Component {
  componentDidUpdate(prevProps) {
    const { dispatch, businessType } = this.props
    const { businessType: prevBusinessType } = prevProps

    if (businessType !== prevBusinessType && (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType))) {
      dispatch(change(IDENTITY_BUSINESS_INFO_FORM, 'ownershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType]))
    }
  }

  render() {
    return (
      <IdentityBusinessInfoForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(IdentityBusinessInfoFormC)
