import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResendDashboardInviteForm from './ResendDashboardInviteForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ResendDashboardInviteUserFormC extends Component {
  render() {
    return (
      <ResendDashboardInviteForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendDashboardInviteUserFormC)
