import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getAuthorizationsAPI from 'api/finix/get/getAuthorizationsAPI'
import getPaymentInstrumentsAPI from 'api/finix/get/getPaymentInstrumentsAPI'
import getTransfersAPI from 'api/finix/get/getTransfersAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getPayoutPlansAPI from 'api/finix/get/getPayoutPlansAPI'
import getPlatformAPI from 'api/finix/get/getPlatformAPI'
import getApplicationUnderwritingSettings from 'api/finix/get/getApplicationUnderwritingSettingsAPI'
import getPayoutPlanO from 'state-layer/orchestration/get/getPayoutPlanO'
import getResponseLinks from 'utilities/get/getResponseLinks'
import getCurrentUser from 'utilities/get/getCurrentUser'
import normalizeResponse from 'utilities/api/normalizeResponse'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendDisputesModel from 'utilities/create/models/frontendDisputesModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendIdentitiesModel from 'utilities/create/models/frontendIdentitiesModel'
import frontendTransfersModel from 'utilities/create/models/frontendTransfersModel'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import frontendAuthorizationsModel from 'utilities/create/models/frontendAuthorizationsModel'
import frontendSettlementsModel from 'utilities/create/models/frontendSettlementsModel'
import frontendUsersModel from 'utilities/create/models/frontendUsersModel'
import frontendPaymentInstrumentsModel from 'utilities/create/models/frontendPaymentInstrumentsModel'
import frontendPayoutPlanModel from 'utilities/create/models/frontendPayoutPlanModel'
import frontendUnderwritingDecisionSettings from 'utilities/create/models/frontendUnderwritingDecisionSettings'
import getMany from 'utilities/get/getMany'
import { FEE_PROFILE } from 'constants/apiConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import head from 'lodash/head'
import co from 'co'

import {
  isRolePlatform,
  isRolePartner,
} from 'utilities/validate/checkRoleCredentials'

function * getApplicationO ({
  id,
  credentials,
}) {
  const { data: response } = yield getApplicationAPI({ id, credentials })
  const links = getResponseLinks(response)

  const [
    applicationProfileLink,
    disputesLink,
    identitiesLink,
    merchantsLink,
    ownerIdentityLink,
    processorsLink,
    settlementsLink,
    usersLink,
  ] = getMany(links, [
    'application_profile',
    'disputes',
    'identities',
    'merchants',
    'owner_identity',
    'processors',
    'settlements',
    'users',
  ])

  // TODO: move the generic paymentsApi calls to their own once they are made
  const [
    { data: applicationProfile },
    { data: authorizations },
    { data: disputes },
    { data: identities },
    { data: merchants },
    { data: ownerIdentity },
    { data: paymentInstruments },
    { data: processors },
    { data: settlements },
    { data: transfers },
    { data: users },
    { data: underwritingSettings },
  ] = yield [
    getPaymentsAPI({ absolutePath: applicationProfileLink, credentials }),
    getAuthorizationsAPI({ credentials, queries: { application_id: id } }),
    getPaymentsAPI({ absolutePath: disputesLink, credentials }),
    getPaymentsAPI({ absolutePath: identitiesLink, credentials }),
    getPaymentsAPI({ absolutePath: merchantsLink, credentials }),
    getPaymentsAPI({ absolutePath: ownerIdentityLink, credentials }),
    getPaymentInstrumentsAPI({ credentials, queries: { application_id: id } }),
    getPaymentsAPI({ absolutePath: processorsLink, credentials }),
    getPaymentsAPI({ absolutePath: settlementsLink, credentials }),
    getTransfersAPI({ credentials, queries: { application_id: id } }),
    getPaymentsAPI({ absolutePath: usersLink, credentials }),
    isRolePlatform({ credentials }) ? getApplicationUnderwritingSettings({
      credentials,
      values: { application_id: id },
    }) : {},
  ]

  const authorizationsData = get(authorizations, '_embedded.authorizations')
  const authorizationModels = frontendAuthorizationsModel({ data: authorizationsData })

  const disputesData = get(disputes, '_embedded.disputes')
  const disputeModels = frontendDisputesModel({ data: disputesData })

  const identitiesData = get(identities, '_embedded.identities')
  const identityModels = frontendIdentitiesModel({ data: normalizeResponse({ content: identitiesData }) })

  const merchantsData = get(merchants, '_embedded.merchants')
  const merchantModels = frontendMerchantsModel({ data: merchantsData })

  const paymentInstrumentsData = get(paymentInstruments, '_embedded.payment_instruments')
  const paymentInstrumentModels = frontendPaymentInstrumentsModel({ data: paymentInstrumentsData })

  const processorsData = get(processors, '_embedded.processors')
  const processorModels = processorsData // frontendTransfersModel({ data: processorsData })

  const settlementsData = get(settlements, '_embedded.settlements')
  const settlementModels = frontendSettlementsModel({ data: settlementsData })

  const transfersData = get(transfers, '_embedded.transfers')
  const transferModels = frontendTransfersModel({ data: transfersData })

  const usersData = get(users, '_embedded.users')
  const userModels = frontendUsersModel({ data: usersData })

  const { href: associatedIdentitiesLink } = get(ownerIdentity, '_links.associated_identities')
  const associatedIdentitiesResponse = yield associatedIdentitiesLink ? getPaymentsAPI({ absolutePath: associatedIdentitiesLink, credentials }) : {}
  const associatedIdentities = get(associatedIdentitiesResponse, 'data._embedded.identities')
  const associatedIdentitiesModels = Object.values(frontendIdentitiesModel({ data: normalizeResponse({ content: associatedIdentities }) }))

  const underwritingDecisionSettingsModel = frontendUnderwritingDecisionSettings({ data: underwritingSettings })

  const riskProfileId = get(applicationProfile, 'risk_profile')
  const { data: riskProfile } = riskProfileId ? yield getPaymentsAPI({
    path: `risk_profiles/${riskProfileId}`,
    credentials,
  }) : {}

  const { data: payoutPlansResponse } = isRolePlatform({ credentials }) ? yield getPayoutPlansAPI({ queries: { entity_id: id }, credentials }) : {}
  const payoutPlan = get(payoutPlansResponse, '_embedded.payout_plans.0')
  const payoutPlanId = get(payoutPlan, 'id')
  let payoutPlanCombined

  if (payoutPlanId) {
    const { data: payoutPlanResponse } = yield getPayoutPlanO({
      id: payoutPlanId,
      payoutPlan,
      credentials,
    })

    payoutPlanCombined = head(payoutPlanResponse)
  }

  const application = merge({}, response, {
    applicationProfile,
    authorizations: authorizationModels,
    disputes: disputeModels,
    identities: identityModels,
    merchants: merchantModels,
    ownerIdentity: frontendIdentityModel({ data: normalizeResponse({ content: ownerIdentity }) }),
    associatedIdentities: associatedIdentitiesModels,
    paymentInstruments: paymentInstrumentModels,
    processors: processorModels,
    settlements: settlementModels,
    transfers: transferModels,
    users: userModels,
    riskProfile,
    payoutPlan: frontendPayoutPlanModel({ data: payoutPlanCombined }),
    underwritingDecisionSettings: underwritingDecisionSettingsModel,
  })

  const normalizedApplication = normalizeResponse({ content: application })

  return createSuccessResponse({
    data: [normalizedApplication],
  })
}

export default co.wrap(getApplicationO)
