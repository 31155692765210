import './ApplePayObtainCertInstructionsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'

import {
  APPLE_PAY_DEVELOPER_PORTAL_URL,
  APPLE_PAY_FINIX_DASHBOARD_REGISTRATION_DEV_DOCS_URL,
} from 'constants/urlConstants'

const ApplePayObtainCertInstructionsForm = ({
  buttonGroup = {},
  onSubmit = () => {},
}) => {
  return (
    <form className='ApplePayObtainCertInstructions' onSubmit={onSubmit}>
      <div className='bold'><div>Go to <a className='text-link' href={APPLE_PAY_DEVELOPER_PORTAL_URL} target='blank'>this page</a> on the Apple&#39;s Developer Portal</div></div>
      {/* TODO: move step strings into language constants but find a way to handle the styling */}
      <div className='step-instructions flex column'>
        <span>1. Select the Merchant ID you&#39;d like to add this certificate to, then click <span className='bold'>&#39;Create Certificate&#39;</span> in the <span className='bold'>Apple Pay Payment Processing Certificate</span> section.</span>
        <span>2. When it prompts you to upload a Certificate Signing Request, choose the file you just downloaded.</span>
        <span>3. Click <span className='bold'>Activate</span> to enable the certificate, then click <span className='bold'>Download</span> to save it to your computer.</span>
      </div>
      <div>For a detailed walk through of these steps, please <a className='text-link' href={APPLE_PAY_FINIX_DASHBOARD_REGISTRATION_DEV_DOCS_URL} target='blank'>click here</a>.</div>

      {buttonGroup}
    </form>
  )
}

ApplePayObtainCertInstructionsForm.propTypes = {
  buttonGroup: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default ApplePayObtainCertInstructionsForm
