import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountBalance from './AccountBalance'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getApplicationBalancesRequest from 'utilities/actions/get/getApplicationBalancesRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import getMany from 'utilities/get/getMany'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { FETCHING } from 'constants/reducerConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'
import find from 'lodash/find'

import {
  ADDING_FUNDS_PATH,
  BALANCE_PATH,
} from 'constants/pathConstants'

import {
  getBalancesSelector,
  getCurrentUsagesByLinkedTo,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const isFetching = get(state, `balanceEntriesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const applicationBalances = getBalancesSelector(state)
  // TODO: Cata - we're currently fetching for USD, but we will need to change this to be dynamic and support Canada
  const applicationBalanceUSD = find(applicationBalances, { currency: USD })
  const applicationCurrentUsages = getCurrentUsagesByLinkedTo(state, applicationId)
  const addFundsPath = ADDING_FUNDS_PATH({ credentialId })
  const quickFilters = [DATE_QUICK_FILTER]
  const balanceEntryPath = BALANCE_PATH({ credentialId, applicationId })

  const [
    displayCardDailyVelocityCurrentVolume,
    displayCardDailyVelocityVolumeLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCountLimit,
    displayAchDailyVelocityCurrentVolume,
    displayAchDailyVelocityVolumeLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCountLimit,
  ] = getMany(applicationCurrentUsages, [
    'displayCardDailyVelocityCurrentVolume',
    'displayCardDailyVelocityVolumeLimit',
    'cardDailyVelocityCurrentCount',
    'cardDailyVelocityCountLimit',
    'displayAchDailyVelocityCurrentVolume',
    'displayAchDailyVelocityVolumeLimit',
    'achDailyVelocityCurrentCount',
    'achDailyVelocityCountLimit',
  ])

  const [
    balanceId,
    applicationBalanceAmountUSD,
    currency,
  ] = getMany(applicationBalanceUSD, [
    'id',
    'displayAvailableAmount',
    'currency',
  ])

  const flowValues = {
    balanceId,
  }

  return {
    credentials,
    credentialId,
    applicationId,
    applicationBalanceAmountUSD,
    displayCardDailyVelocityCurrentVolume,
    displayCardDailyVelocityVolumeLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCountLimit,
    displayAchDailyVelocityCurrentVolume,
    displayAchDailyVelocityVolumeLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCountLimit,
    currency,
    balanceId,
    quickFilters,
    addFundsPath,
    isFetching,
    flowValues,
    balanceEntryPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationBalances: ({ credentials, applicationId }) => dispatch(getApplicationBalancesRequest({ credentials, queries: { linked_to: applicationId } })),
    getApplicationVelocityLimits: ({ credentials, entityId, currency }) => dispatch(getCurrentUsagesRequest({ credentials, entityId, currency })),
  }
}

class AccountBalanceC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      currency,
      getApplicationBalances,
      getApplicationVelocityLimits,
    } = this.props

    if (applicationId) {
      getApplicationBalances({ credentials, applicationId })
      getApplicationVelocityLimits({ credentials, entityId: applicationId, currency })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      applicationId,
      currency,
      getApplicationBalances,
      getApplicationVelocityLimits,
    } = this.props

    const {
      applicationId: prevApplicationId,
    } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationBalances({ credentials, applicationId })
      getApplicationVelocityLimits({ credentials, entityId: applicationId, currency })
    }
  }

  render() {
    return (
      <AccountBalance
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBalanceC)
