import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  CURRENT_USAGES,
  DISBURSEMENT_RULES,
} from 'constants/apiConstants'

const getCurrentUsagesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: CURRENT_USAGES,
    queries,
    credentials,
    service: DISBURSEMENT_RULES,
  })
}

export default getCurrentUsagesAPI
