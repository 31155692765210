import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_PROFILES } from 'constants/apiConstants'

const postUnderwritingProfileAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: UNDERWRITING_PROFILES,
    service: UNDERWRITING_PROFILES,
  })
}

export default postUnderwritingProfileAPI
