import { GET_COMPLIANCE_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getComplianceSettingsRequest = ({
  id,
  credentials,
}) => createAction({
  type: GET_COMPLIANCE_SETTINGS_F_REQUEST,
  id,
  credentials,
})

export default getComplianceSettingsRequest
