import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendOnboardingFormsDataModel = ({ data: onboardingFormsData }) => {
  const onboardingFormDataModels = map(onboardingFormsData, (onboardingFormData) => frontendOnboardingFormDataModel({ data: onboardingFormData }))

  return keyBy(onboardingFormDataModels, 'id')
}

export default frontendOnboardingFormsDataModel
