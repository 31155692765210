import { PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_REQUEST } from 'constants/flowConstants'
import { EDIT_PAYOUT_APPLICATION_CONFIGS } from 'constants/amplitudeConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import { UPDATE_APP_CONFIGS_SUCCESS_MSG } from 'constants/language/languageConstants'
import hideModalAction from 'utilities/actions/hideModalAction'
import getApplicationProcessorConfigsRequest from 'utilities/actions/get/getApplicationProcessorConfigsRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import omit from 'lodash/omit'

const submitEditPayoutConfigsForm = (values, dispatch, props) => {
  const {
    applicationId,
    credentials,
    isVisa,
  } = props

  const {
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    visaDefaultSenderName,
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    mcSubdivision,
    mcStatementDescriptor,
  } = values

  const visaObject = {
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    visaDefaultSenderName,
  }

  const mastercardObject = {
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    mcSubdivision,
    mcStatementDescriptor,
  }

  const valuesToSubmit = {
    applicationConfig: {
      visaObject: { ...visaObject },
      mastercardObject: { ...mastercardObject },
    },
  }

  dispatch({
    type: PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_REQUEST,
    payload: {
      id: applicationId,
      processor: FINIX_V1,
      values: isVisa ? omit(valuesToSubmit, 'applicationConfig.mastercardObject') : omit(valuesToSubmit, 'applicationConfig.visaObject'),
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch(hideModalAction())
        dispatch(getApplicationProcessorConfigsRequest({ applicationId, processor: FINIX_V1, credentials }))
      },
      actionId: sendAmplitudeActionEvent(EDIT_PAYOUT_APPLICATION_CONFIGS, {
        applicationId,
        values,
      }),
      successMessage: UPDATE_APP_CONFIGS_SUCCESS_MSG,
    },
  })
}

export default submitEditPayoutConfigsForm
