import './ReportsS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import CategorizedReports from 'components/Customer/Pages/Reports/ReportsHome/CategorizedReports'
import Loader from 'components/Shared/Loader/Loader'
import ReportSearchForm from 'components/Customer/Forms/ReportSearchForm/ReportSearchForm'
import { REPORTS_ICON } from 'constants/iconConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  REPORTS_RESOURCE_TITLE,
  REPORTS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const Reports = ({
  credentials = {},
  credentialId = '',
  isFetching = false,
  categorizedReports = {},
  searchQuery = '',
  actions = [],
  clearSearchQuery = () => {},
  goToCategorizedReportsTable = () => {},
  isFlex = false,
  role = '',
}) => {
  const noReports = isEmpty(categorizedReports)

  return (
    <div className='Reports'>
      {isFetching && <Loader />}

      <div className='content-header flex space-between'>
        <h1>{REPORTS_RESOURCE_TITLE}</h1>
      </div>

      {!isFetching && (
      <>
        <div className='flex items-center'>
          <ReportSearchForm
            searchQuery={searchQuery}
            clearSearchQuery={clearSearchQuery}
          />

          <div className='actions'>
            {map(actions, ({ label, className, link }) => {
              return link ? <Link className={className} to={link}>{label}</Link> :
              <div className={className}>{label}</div>
            })}
          </div>
        </div>

        {noReports && (
          <div className='no-reports-msg p-2'>
            <i className={`fa fa-${REPORTS_ICON}`} />
            {REPORTS_EMPTY_TABLE_MESSAGE}
          </div>
        )}

        <CategorizedReports
          credentials={credentials}
          credentialId={credentialId}
          categorizedReports={categorizedReports}
          searchQuery={searchQuery}
          goToCategorizedReportsTable={goToCategorizedReportsTable}
          isFlex={isFlex}
          role={role}
        />
      </>
      )}
    </div>
  )
}

Reports.propTypes = {
  isFetching: PropTypes.bool,
  categorizedReports: PropTypes.object,
  searchQuery: PropTypes.string,
  actions: PropTypes.array,
  clearSearchQuery: PropTypes.func,
  credentials: PropTypes.object,
  credentialId: PropTypes.string,
  goToCategorizedReportsTable: PropTypes.func,
  isFlex: PropTypes.bool,
  role: PropTypes.string,
}

export default Reports
