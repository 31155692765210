import formatAmount from 'utilities/formatters/formatAmount'
import reduce from 'lodash/reduce'
import get from 'lodash/get'

const calculateReviewQueueSettlementsAmount = ({ selectedItems = {}, state = {} }) => {
  return formatAmount(reduce(selectedItems, (totalAmount, selected, reviewQueueItem) => {
    const entityId = get(state, `reviewQueueR.items.${reviewQueueItem}.entityId`)
    const reviewQueueItemTotalAmount = Number(get(state, `settlementsR.items.${entityId}.totalAmount`, '').replace(',', ''))

    return totalAmount + reviewQueueItemTotalAmount
  }, 0))
}

export default calculateReviewQueueSettlementsAmount
