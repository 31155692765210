import './SettlementPreviewPanelS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import PanelContextBar from 'components/Shared/PanelContextBar/PanelContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import SettlementFundingTransfersC from 'components/Customer/Pages/Settlement/SettlementFundingTransfers/SettlementFundingTransfersC'
import SettlementCalculatedFundingDetailsC from 'components/Customer/Pages/Settlement/SettlementCalculatedFundingDetails/SettlementCalculatedFundingDetailsC'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import map from 'lodash/map'
import { USD } from 'constants/currencyConstants'

import {
  t,
  TYPE,
  COUNT,
  AMOUNT,
  ADDITIONAL_INFORMATION,
  DETAILS,
  PAYOUT_PROFILE,
  MERCHANT_PAYOUT_PROFILE,
  FUNDING_TRANSFERS,
  FUNDING_INSTRUCTIONS,
  ENTRIES,
} from 'constants/language/languageConstants'

const SettlementPreviewPanel = ({
  isFetchingPayoutProfile = false,
  isFetchingDetails = false,
  panelContextBarData = [],
  headerData = {},
  currency = USD,
  settlementDetailsData = [],
  detailsSectionData = [],
  merchantPayoutProfileSectionData = [],
  actions = [],
  overviewTableRows = [],
  isApprovedSettlement = false,
  settlementId = '',
  tagsSectionData = [],
  entries = '',
}) => {
  return (
    <div className='SettlementPreviewPanel'>
      <PanelContextBar data={panelContextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <PageSectionV2C columns={settlementDetailsData} />

      <PageSectionV2C
        smallTitle={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetchingDetails}
      />

      <PageSectionV2C
        smallTitle={isStandaloneMerchantDashboard() ? PAYOUT_PROFILE : MERCHANT_PAYOUT_PROFILE}
        columns={merchantPayoutProfileSectionData}
        isFetching={isFetchingPayoutProfile}
      />

      <PageSectionV2C smallTitle='Overview'>
        <div className='table overview-table'>
          <div className='header tal'>{t(TYPE)}</div>
          <div className='header'>{t(COUNT)}</div>
          <div className='header'>{t(AMOUNT)} ({currency})</div>

          { map(overviewTableRows, (row, rowIndex) => (
            <Fragment key={`overview-row-${rowIndex}`}>
              { map(row, (column, columnIndex) => {
                return (
                  <div
                    key={`overview-row-${rowIndex}-column-${columnIndex}`}
                    className={columnIndex === 0 ? 'tal' : ''}
                  >
                    {t(column)}
                  </div>
                )
              })}
            </Fragment>
          ))}
        </div>
      </PageSectionV2C>

      { isApprovedSettlement ? (
        <PageSectionV2C
          className='funding-transfers-section'
          smallTitle={FUNDING_TRANSFERS}
        >
          <SettlementFundingTransfersC
            settlementId={settlementId}
            showExportButton={false}
          />
        </PageSectionV2C>
      ) : (
        <SettlementCalculatedFundingDetailsC
          settlementId={settlementId}
          smallTitle={FUNDING_INSTRUCTIONS}
          showExportButton={false}
        />
      )}

      <TagsPageSectionV2
        data={tagsSectionData}
      />

      <PageSectionV2C
        smallTitle={ADDITIONAL_INFORMATION}
        linkToView={entries}
        textForLink={t(ENTRIES)}
      />
    </div>
  )
}

SettlementPreviewPanel.propTypes = {
  isFetchingPayoutProfile: PropTypes.bool,
  isFetchingDetails: PropTypes.bool,
  panelContextBarData: PropTypes.array,
  headerData: PropTypes.object,
  settlementDetailsData: PropTypes.array,
  detailsSectionData: PropTypes.array,
  merchantPayoutProfileSectionData: PropTypes.array,
  actions: PropTypes.array,
  currency: PropTypes.string,
  isApprovedSettlement: PropTypes.bool,
  settlementId: PropTypes.string,
  tagsSectionData: PropTypes.array,
  entries: PropTypes.string,
  textForLink: PropTypes.string,
}

export default SettlementPreviewPanel
