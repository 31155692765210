import './PayoutLinkConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  t,
  TRY_AGAIN,
  TOTAL_PAYOUT, TERMS_OF_SERVICE, PRIVACY_POLICY,
} from 'constants/language/languageConstants'

const PayoutLinkConfirmation = ({
  status,
  payoutLink = {},
  payoutLinkUrl = '',
  statusToIconMap = {},
  statusToTitleMap = {},
  statusToMessageMap = {},
  showACHMessage = false,
  today = '',
}) => {
  const {
    termsOfServiceUrl,
    brandColor = '#FFF',
    logo,
    items,
    displayTotalAmount,
  } = payoutLink

  const icon = statusToIconMap[status]
  const title = t(statusToTitleMap[status])
  const message = t(statusToMessageMap[status])

  return (
    <form className='PayoutLinkConfirmation'>
      { isEmpty(payoutLink) && <div className='mask' /> }

      <div className='header' style={{ backgroundColor: brandColor }}>
        {logo && <img src={logo} alt='company-logo' height={54} />}
      </div>

      <div className='container flex column'>
        <div className='messages flex column'>
          <i className={`icon ${status} far fa-${icon}`} />
          <h5>{title}</h5>
          <p className='p-2'>{message}</p>
        </div>

        { !isEmpty(payoutLink) && (
          <div className='order-summary'>
            <div className='items'>
              { map(items, (item, index) => {
                const {
                  name,
                  quantity,
                  description,
                  primaryImageUrl,
                  itemTotalAmount,
                } = item

                const itemKey = `mobile-item-preview-${index}`

                return (
                  <div className='item-preview flex' key={itemKey}>
                    { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }

                    <div className='info flex column flex-grow space-between'>
                      <div>
                        <p className='name p-2'>{name}</p>
                        <p className='description p-3 secondary'>{description}</p>
                      </div>

                      <p className='quantity p-3 secondary'>Qty: {quantity}</p>
                    </div>

                    <h6 className='total'>{itemTotalAmount}</h6>
                  </div>
                )
              })}
            </div>

            <div className='cart-total flex space-between'>
              <h5>{TOTAL_PAYOUT}</h5>
              <h5>{displayTotalAmount}</h5>
            </div>
          </div>
        )}

        { status === 'failure' && (
          <div className='btn-container flex column items-center'>
            <a href={payoutLinkUrl}><Button label={TRY_AGAIN} className='try-again-button' /></a>
          </div>
        )}

        { showACHMessage && (
          <div className='ach-message'>
            <p className='p-3'>
              You have authorized us to initiate an automated clearing house (ACH) one-time credit in your name to your bank account on {today}.
              This transaction will be presented to your financial institution by the next business day.
              You further agree that you may not revoke this authorization or cancel this payment.
            </p>
          </div>
        )}

        { status !== 'expired' && (
          <div className='links flex center'>
            <a href={termsOfServiceUrl} className='link-3' target='_blank' rel='noopener noreferrer'>{TERMS_OF_SERVICE}</a>
            <a href='https://finix.com/terms-and-policies/privacy-policy' className='link-3' target='_blank' rel='noopener noreferrer'>{PRIVACY_POLICY}</a>
          </div>
        )}
      </div>
    </form>
  )
}

PayoutLinkConfirmation.propTypes = {
  status: PropTypes.string,
  payoutLink: PropTypes.object,
  payoutLinkUrl: PropTypes.string,
  statusToIconMap: PropTypes.object,
  statusToTitleMap: PropTypes.object,
  statusToMessageMap: PropTypes.object,
  showACHMessage: PropTypes.bool,
  today: PropTypes.string,
}

export default PayoutLinkConfirmation
