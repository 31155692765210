import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const PayoutLink = ({
  contextBarData = {},
  headerData = {},
  actions = {},
  tabs = [],
}) => {
  return (
    <div className='PayoutLink'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <TabsC tabs={tabs} />
    </div>
  )
}

PayoutLink.propTypes = {
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  actions: PropTypes.object,
  tabs: PropTypes.array,
}

export default PayoutLink
