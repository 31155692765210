import paymentsAPI from 'utilities/api/paymentsAPI'
import { MONITORING_ALERT } from 'constants/apiConstants'

const getMonitoringAlertAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: MONITORING_ALERT({ monitoringAlertId: id }),
    credentials,
  })
}

export default getMonitoringAlertAPI
