import { CUSTOM } from 'constants/roleConstants'
import includes from 'lodash/includes'
import every from 'lodash/every'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import concat from 'lodash/concat'
import memoize from 'lodash/memoize'

import {
  ALL_PERMISSIONS_LIST,
  CUSTOM_ROLE_PERMISSIONS_LIST,
  filteredAllPermissionsByRole,
} from 'constants/permissionConstants'

export const formatPermissionsForView = memoize(({
  type,
  userPermissions,
  authRole,
}) => {
  const allPermissions = type === CUSTOM ? CUSTOM_ROLE_PERMISSIONS_LIST : ALL_PERMISSIONS_LIST
  const filteredPermissions = filteredAllPermissionsByRole({ permissionList: allPermissions, authRole })

  return reduce(filteredPermissions, (total, { permissions, category, label }) => {
    const enabledPermission = every(permissions, (permission) => includes(userPermissions, permission))
    const existingArray = total[category] || []
    const concatenatedArray = concat(existingArray, [{ label, enabled: enabledPermission }])

    return merge({}, total, {
      [category]: concatenatedArray,
    })
  }, {})
})

export const formatPermissionsForForm = memoize(({
  type,
  userPermissions,
  authRole,
}) => {
  const allPermissions = type === CUSTOM ? CUSTOM_ROLE_PERMISSIONS_LIST : ALL_PERMISSIONS_LIST
  const filteredPermissions = filteredAllPermissionsByRole({ permissionList: allPermissions, authRole })

  return reduce(filteredPermissions, (total, { label, permissions, category }) => {
    const enabledPermission = every(permissions, (permission) => includes(userPermissions, permission))
    if (enabledPermission) {
      return merge({}, total, {
        [category]: { [label]: enabledPermission },
      })
    }
    return total
  }, {})
})
