import React, { Component } from 'react'
import { connect } from 'react-redux'
import InviteUserForm from 'components/Customer/Forms/InviteUserForm/InviteUserForm'
import { APPLICATION, IDENTITY } from 'constants/flowConstants'
import { getApplicationSelector, getIdentitySelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

const selectorByRecordType = {
  [IDENTITY]: getIdentitySelector,
  [APPLICATION]: getApplicationSelector,
}

const mapStateToProps = (state, props) => {
  const [
    applicationId = '',
    identityId = '',
    hasIdentityMerchants = false,
  ] = getMany(props, [
    'applicationId',
    'identityId',
    'hasIdentityMerchants',
  ])

  const credentials = getCurrentCredentials(state)
  // TODO: refactor if we need more than 2 types of resources to invite to, seems unlikely though
  const type = identityId ? IDENTITY : APPLICATION
  const id = identityId || applicationId
  const selector = selectorByRecordType[type]
  const record = selector(state, id)
  const nameQuery = identityId ? 'businessName' : 'ownerIdentity.businessName'
  const disableAction = type === IDENTITY && !hasIdentityMerchants
  const name = get(record, nameQuery)

  return {
    id,
    name,
    type,
    record,
    credentials,
    disableAction,
    initialValues: {
      name,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class InviteUserFormC extends Component {
  render() {
    return (
      <InviteUserForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserFormC)
