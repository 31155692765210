// TODO: revisit after April 13 release to potentially delete modal + form it renders
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import WithdrawLiveApplicationC from 'components/Customer/Pages/WithdrawLiveApplication/WithdrawLiveApplicationC'
import { WITHDRAW_APPLICATION_FORM } from 'constants/language/languageConstants'

const WithdrawLiveApplicationModal = ({
  accessFormId = '',
}) => {
  return (
    <GenericModal
      title={WITHDRAW_APPLICATION_FORM}
      className='WithdrawLiveApplicationModal'
      Component={WithdrawLiveApplicationC}
      accessFormId={accessFormId}
    />
  )
}

WithdrawLiveApplicationModal.propTypes = {
  accessFormId: PropTypes.string,
}

export default WithdrawLiveApplicationModal
