import './MerchantDepositPreviewPanelS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PanelContextBar from 'components/Shared/PanelContextBar/PanelContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import { PATCH_TRANSFER_F_REQUEST } from 'constants/flowConstants'

const MerchantDepositPreviewPanel = ({
  isFetching = false,
  panelContextBarData = [],
  headerData = {},
  actions = [],
  merchantDepositDetailsData = [],
  paymentInstrument = {},
  tagsSectionData = [],
  transfer = {},
}) => {
  return (
    <div className='MerchantDepositPreviewPanel'>
      {isFetching && <Loader />}

      {!isFetching && (
        <>
          <PanelContextBar
            isFetching={isFetching}
            data={panelContextBarData}
          />

          <HeaderV2C
            isFetching={isFetching}
            actions={actions}
            {...headerData}
          />

          <PageSectionV2C
            isFetching={isFetching}
            columns={merchantDepositDetailsData}
          />

          <PaymentInstrumentDetailsC
            isFetching={isFetching}
            paymentInstrument={paymentInstrument}
            isPreviewPanel
          />

          <TagsPageSectionV2
            isFetching={isFetching}
            data={tagsSectionData}
            actionType={PATCH_TRANSFER_F_REQUEST}
            record={transfer}
          />
        </>
      )}
    </div>
  )
}

MerchantDepositPreviewPanel.propTypes = {
  isFetching: PropTypes.bool,
  panelContextBarData: PropTypes.array,
  headerData: PropTypes.object,
  actions: PropTypes.array,
  merchantDepositDetailsData: PropTypes.array,
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.array,
  transfer: PropTypes.object,
}

export default MerchantDepositPreviewPanel
