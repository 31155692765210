import React, { Component } from 'react'
import { connect } from 'react-redux'
import SanctionsScreeningReportV2 from './SanctionsScreeningReportV2'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatDate from 'utilities/formatters/formatDate'
import { MM_DD_YYYY } from 'constants/timeConstants'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'
import startCase from 'lodash/startCase'

import {
  countryISO2toISO3,
  countryNameByCountryCode,
} from 'constants/countryConstants'

import {
  VISIT_WEBSITE,
  TYPE,
  WATCHLIST,
  COUNTRY,
  BIRTH_DATES,
  SEX,
  EMAILS,
  LOCATIONS,
  PHONE_NUMBERS,
  NAME_ALIASES,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})
  const hits = get(report, 'details.hits', [])
  const isBusinessSanctionsReport = get(report, 'name') === 'BusinessWatchlistReport'

  const sanctionsData = map(hits, (hit) => {
    const [
      name,
      nameAliases = [],
      birthDates = [],
      locations = [],
      sex,
      emails = [],
      phoneNumbers = [],
      watchLists,
    ] = getMany(hit, [
      'name',
      'name_aliases',
      'birthdates',
      'locations',
      'sex',
      'emails',
      'phone_numbers',
      'watchlists',
    ])

    const watchListsData = map(watchLists, ({ name: watchlistName, type, url, country }) => {
      return {
        name: watchlistName,
        columns: convertPageSectionDataToV2([
          { label: TYPE, value: type },
          { label: COUNTRY, value: countryNameByCountryCode[countryISO2toISO3[country]] || country },
          { label: WATCHLIST, value: url ? <a className='text-link' href={url} target='blank'>{VISIT_WEBSITE}<i className={`fa fa-${EXTERNAL_LINK}`} /></a> : 'N/A' },
        ]),
      }
    })

    const phoneNumbersData = (
      <div className='phone-numbers-data'>
        {map(phoneNumbers, number => {
          return <div>{formatPhone(toString(number))}</div>
        })}
      </div>
    )

    const birthDatesData = (
      <div className='birthdates-data'>
        {map(birthDates, birthDate => {
          return <div>{formatDate({ date: birthDate, format: MM_DD_YYYY })}</div>
        })}
      </div>
    )

    const emailsData = (
      <div className='emails-data'>
        {map(emails, email => {
          return <div>{email}</div>
        })}
      </div>
    )

    const locationsData = (
      <div className='locations-data'>
        {map(locations, location => {
          return <div>{countryNameByCountryCode[countryISO2toISO3[location]]}</div>
        })}
      </div>
    )

    const nameAliasesData = (
      <div className='nameAliases-data'>
        {map(nameAliases, nameAlias => {
          return <div>{nameAlias}</div>
        })}
      </div>
    )

    return isBusinessSanctionsReport ? {
      title: name,
      subTitle: isEmpty(nameAliases) ? null : <div className='alias-string'>Business Aliases: {join(nameAliases, ', ')}</div>,
      watchLists: watchListsData,
    } : {
      title: name,
      profileColumns: convertPageSectionDataToV2([
        { label: NAME_ALIASES, value: nameAliasesData },
        { label: SEX, value: startCase(sex) },
        { label: BIRTH_DATES, value: birthDatesData },
        { label: LOCATIONS, value: locationsData },
        { label: EMAILS, value: emailsData },
        { label: PHONE_NUMBERS, value: phoneNumbersData },
      ]),
      watchLists: watchListsData,
    }
  })

  return {
    credentials,
    sanctionsData,
  }
}

class SanctionsScreeningReportV2C extends Component {
  render() {
    return (
      <SanctionsScreeningReportV2
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SanctionsScreeningReportV2C)
