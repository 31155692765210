import React from 'react'
import PropTypes from 'prop-types'
import { getInstantPayoutsMerchantPaymentInstrumentSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Settlement/InstantPayouts/columnDescriptors'
import { GET_INSTANT_PAYOUTS } from 'constants/flowConstants'

import {
  INSTANT_PAYOUT_ATTEMPTS,
  INSTANT_PAYOUT_ATTEMPTS_EMPTY_MSG,
} from 'constants/language/languageConstants'

const InstantPayouts = ({
  isFetching = false,
  settlementId = '',
}) => {
  return (
    <div className='InstantPayouts'>
      <TableC
        title={INSTANT_PAYOUT_ATTEMPTS}
        isFetching={isFetching}
        flow={GET_INSTANT_PAYOUTS}
        emptyMessage={INSTANT_PAYOUT_ATTEMPTS_EMPTY_MSG}
        selector={getInstantPayoutsMerchantPaymentInstrumentSelector}
        selectorId={settlementId}
        columnDescriptors={columnDescriptors}
      />
    </div>
  )
}

InstantPayouts.propTypes = {
  isFetching: PropTypes.bool,
  settlementId: PropTypes.string,
}

export default InstantPayouts
