import React from 'react'
import PaymentLinkStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PaymentLinkStatus'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import map from 'lodash/map'

import {
  CUSTOM,
  FORTY_EIGHT_HOURS,
  ONE_WEEK,
  SIXTY_DAYS,
  TWENTY_FOUR_HOURS,
} from 'constants/language/languageConstants'

const frontendPayoutLinkModel = ({ data: payoutLink }) => {
  const [
    id,
    createdAt,
    updatedAt,
    applicationId,
    merchantId,
    allowedPayoutOperations,
    nickname,
    items,
    totalAmount,
    currency,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientBusinessName,
    recipientDoingBusinessAs,
    recipientMerchantId,
    brandColor,
    accentColor,
    logo,
    icon,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    expirationInMinutes,
    state,
    tags,
    linkUrl,
    linkExpiresAt,
  ] = getMany(payoutLink, [
    'id',
    'created_at',
    'updated_at',
    'application_id',
    'merchant_id',
    'allowed_payout_operations',
    'nickname',
    'items',
    'amount_details.total_amount',
    'amount_details.currency',
    'recipient_details.first_name',
    'recipient_details.last_name',
    'recipient_details.email',
    'recipient_details.business_name',
    'recipient_details.doing_business_as',
    'recipient_details.merchant_id',
    'branding.brand_color',
    'branding.accent_color',
    'branding.logo',
    'branding.icon',
    'additional_details.terms_of_service_url',
    'additional_details.success_return_url',
    'additional_details.unsuccessful_return_url',
    'additional_details.expired_session_url',
    'additional_details.expiration_in_minutes',
    'state',
    'tags',
    'link_url',
    'link_expires_at',
  ])

  const normalizedItems = map(items, (item) => {
    const [
      name,
      description,
      quantity,
      primaryImageUrl,
      amount,
      itemCurrency,
    ] = getMany(item, [
      'name',
      'description',
      'quantity',
      'image_details.primary_image_url',
      'price_details.amount',
      'price_details.currency',
    ])

    return {
      name,
      description,
      quantity,
      primaryImageUrl,
      amount,
      itemTotalAmount: formatMoney({ amount: amountToDisplayAmount(quantity * displayAmountToAmount(amount, itemCurrency), itemCurrency), currency: itemCurrency }),
      displayAmount: formatMoney({ amount, currency: itemCurrency }),
      currency: itemCurrency,
    }
  })

  const expirationMinutesToDaysMap = {
    1440: TWENTY_FOUR_HOURS,
    2880: FORTY_EIGHT_HOURS,
    10080: ONE_WEEK,
    86400: SIXTY_DAYS,
    custom: CUSTOM,
  }

  return removeUndefined({
    id,
    merchantId,
    applicationId,
    allowedPayoutOperations,
    nickname,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    items: normalizedItems,
    totalAmount,
    displayTotalAmount: formatMoney({ amount: totalAmount, currency }),
    currency,
    recipientFirstName,
    recipientLastName,
    recipientFullName: (recipientFirstName || recipientLastName) ? `${recipientFirstName} ${recipientLastName}` : '',
    recipientEmail,
    recipientBusinessName,
    recipientDoingBusinessAs,
    recipientMerchantId,
    brandColor,
    accentColor,
    logo,
    icon,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    expirationInMinutes,
    displayExpirationMinutesInDays: expirationMinutesToDaysMap[expirationInMinutes],
    state,
    displayState: () => <PaymentLinkStatus value={state} />,
    tags,
    linkUrl,
    linkExpiresAt,
    displayLinkExpiresAt: formatDate({ date: linkExpiresAt }),
  })
}

export default frontendPayoutLinkModel
