import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import TagsPageSection from 'components/Customer/Shared/Page/PageSection/TagsPageSection'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const BalanceTransfer = ({
  detailsSectionData = [],
  isFetching = false,
  actions = [],
  tagsSectionData = [],
  balanceTransfer = {},
  headerData = {},
  contextBarData = {},
}) => {
  return (
    <div className='BalanceTransfer'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <div className='header-page-content'>
        <PageSection
          title='Details'
          data={detailsSectionData}
          isFetching={isFetching}
        />

        <TagsPageSection
          data={tagsSectionData}
          record={balanceTransfer}
          isFetching={isFetching}
        />
      </div>
    </div>
  )
}

BalanceTransfer.propTypes = {
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  tagsSectionData: PropTypes.array,
  balanceTransfer: PropTypes.object,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default BalanceTransfer
