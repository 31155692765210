import { POST_INSTANT_PAYOUT_F_REQUEST } from 'constants/flowConstants'
import { SETTLEMENT_INSTANT_PAYOUT_CONFIRMATION_PATH } from 'constants/pathConstants'
import redirectRequest from 'utilities/actions/redirectRequest'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

const submitCreateInstantPayoutForm = (values, dispatch, props) => {
  const {
    credentials,
    settlementId,
    netAmount,
  } = props

  const paymentMethod = get(values, 'paymentMethod.value')

  const valuesToSubmit = {
    paymentInstrumentId: paymentMethod,
    amount: netAmount,
  }

  dispatch({
    type: POST_INSTANT_PAYOUT_F_REQUEST,
    payload: {
      id: settlementId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const credentialId = get(credentials, 'id')
        const instantPayoutId = head(keys(newValues))
        // eslint-disable-next-line no-shadow
        const settlementId = get(props, 'settlementId')
        const instantPayoutConfirmationPath = `${SETTLEMENT_INSTANT_PAYOUT_CONFIRMATION_PATH({ credentialId, settlementId, instantPayoutId })}?paymentInstrumentId=${paymentMethod}`
        dispatch(redirectRequest({ path: instantPayoutConfirmationPath }))
      },
    },
  })
}

export default submitCreateInstantPayoutForm
