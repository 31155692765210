import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstrumentDetails from './PaymentInstrumentDetails'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { PAYMENT_INSTRUMENT } from 'constants/apiConstants'
import { SHOW_ENTERPRISE_SIFT_FRAUD_SCORES } from 'constants/featureFlagConstants'
import { PAYMENT_INSTRUMENT_API_REFERENCE } from 'constants/language/languageConstants'
import { PAYMENT_INSTRUMENT_API_DOCS_LINK } from 'constants/urlConstants'
import { VIEW_API_RESPONSE_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)

  const [
    paymentInstrument,
    device,
  ] = getMany(props, [
    'paymentInstrument',
    'device',
  ])

  return {
    credentialId,
    credentials,
    device,
    deviceId: get(device, 'id'),
    paymentInstrument,
    isRoleMerchantCred: isRoleMerchant({ credentials }),
    isEnterpriseSiftFraudScoreTier: getFeatureFlag(SHOW_ENTERPRISE_SIFT_FRAUD_SCORES),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({ modalType: VIEW_API_RESPONSE_MODAL, modalProps })),
  }
}

class PaymentInstrumentDetailsC extends Component {
  showResponseModal = () => {
    const {
      credentials,
      paymentInstrument,
      showAPIResponseModal,
    } = this.props

    const host = get(credentials, 'host')
    const paymentInstrumentId = get(paymentInstrument, 'id')
    const paymentInstrumentDocs = [
      {
        label: PAYMENT_INSTRUMENT_API_REFERENCE,
        link: PAYMENT_INSTRUMENT_API_DOCS_LINK,
      },
    ]

    showAPIResponseModal({
      api: getPaymentInstrumentAPI,
      apiProps: {
        id: paymentInstrumentId,
        credentials,
      },
      apiRoute: host && `${host}/${PAYMENT_INSTRUMENT({ id: paymentInstrumentId })}`,
      apiMethod: 'GET',
      apiDocuments: paymentInstrumentDocs,
    })
  }

  render() {
    return (
      <PaymentInstrumentDetails
        {...this.props}
        showResponseModal={this.showResponseModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInstrumentDetailsC)
