import React, { Component } from 'react'
import { connect } from 'react-redux'
import SendPayoutOptions from 'components/Customer/Pages/SendPayoutOptions/SendPayoutOptions'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

import {
  CREATE_PAYOUT_LINK_PATH,
  SEND_PAYOUT_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const sharePayoutLinkPath = `${CREATE_PAYOUT_LINK_PATH({ credentialId })}`
  const sendPayoutPath = `${SEND_PAYOUT_PATH({ credentialId })}`

  return {
    credentialId,
    sharePayoutLinkPath,
    sendPayoutPath,
  }
}

class SendPayoutOptionsC extends Component {
  render() {
    return (
      <SendPayoutOptions {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(SendPayoutOptionsC)
