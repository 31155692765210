import React from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'

import {
  isActiveLinkStatus,
  normalizeRoute,
  isAllowedCheck,
} from 'constants/sidebarConstants'

import {
  ANGLE_DOWN_ICON,
  ANGLE_RIGHT_ICON,
} from 'constants/iconConstants'

const MainNavItem = ({
  credentialId,
  pathname,
  headerKey = '',
  headerRouteWithCredentials = '',
  items = [],
  icon,
  sidebarItemStyles,
  sidebarHighlightStyles,
  activeDotHighlightStyles,
  toggleSidebarSections = () => {},
  headerLabel = '',
  currentRole,
  iconWeight = 'fal',
  isActiveHeader = false,
  isCurrentlyExpanded = false,
  isDivider = false,
}) => {
  const toggleIcon = isCurrentlyExpanded ? ANGLE_DOWN_ICON : ANGLE_RIGHT_ICON
  const noChildren = isEmpty(items)

  const headerItemClassNames = classnames({
    'header-item': true,
    active: isActiveHeader,
    'no-children': noChildren,
  })

  const iconClassNames = classnames({
    [`${iconWeight} fa-${icon}`]: true,
    icon: true,
    'no-toggle': isEmpty(items),
  })

  // do not override active state with branding
  const normalizedSidebarStyles = (isActiveHeader && !isCurrentlyExpanded) ? sidebarHighlightStyles : sidebarItemStyles

  return (
    <li className='MainNavItem'>
      {isDivider && <div className='divider'>{headerLabel}</div>}
      {!isDivider && (
      <ul className={`nav-bar-content ${headerKey} ${isCurrentlyExpanded ? 'expanded' : ''}`}>
        <li key={headerKey} className={headerItemClassNames} onClick={(e) => toggleSidebarSections({ e, noChildren })}>
          <Link to={headerRouteWithCredentials}>
            <div style={normalizedSidebarStyles} className='flex header-item-container items-center'>
              {icon && <span className={iconClassNames} />}
              <div className='header-label'>{headerLabel}</div>
              {!isEmpty(items) ? <i className={`toggle-icon far fa-${toggleIcon}`} /> : <div />}
            </div>
          </Link>

          <div className='section-items'>
            <ul>
              {map(items, (item) => {
                const {
                  label,
                  route = () => {},
                  key = '',
                  allowedRoles,
                  adminCheck = () => true,
                  isHidden = false,
                } = item

                const routeWithCredentials = normalizeRoute({ route, credentialId })
                const isActiveLink = isActiveLinkStatus({ route: routeWithCredentials, pathname, key })
                // do not override active state with branding
                const activeStyles = !isActiveLink ? sidebarItemStyles : sidebarHighlightStyles
                const isAllowed = isAllowedCheck({ allowedRoles, adminCheck, currentRole })

                const linkClassNames = classnames({
                  'children-item': true,
                  active: isActiveLink,
                  [camelCase(label)]: true,
                })

                return isAllowed && !isHidden ? (
                  <li
                    className={linkClassNames}
                    key={key}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <Link to={routeWithCredentials}>
                      <div className='label-area flex items-center' style={activeStyles}>
                        {isActiveLink && <div style={activeDotHighlightStyles} className='active-dot' />}
                        <div className='label'>{label}</div>
                      </div>
                    </Link>
                  </li>
                ) : undefined
              })}
            </ul>
          </div>
        </li>
      </ul>
      )}
    </li>
  )
}

MainNavItem.propTypes = {
  credentialId: PropTypes.string,
  pathname: PropTypes.string,
  headerKey: PropTypes.string,
  headerRouteWithCredentials: PropTypes.string,
  isActiveHeader: PropTypes.bool,
  items: PropTypes.array,
  icon: PropTypes.string,
  sidebarItemStyles: PropTypes.object,
  sidebarHighlightStyles: PropTypes.object,
  activeDotHighlightStyles: PropTypes.object,
  toggleSidebarSections: PropTypes.func,
  headerLabel: PropTypes.string,
  currentRole: PropTypes.string,
  iconWeight: PropTypes.string,
  isCurrentlyExpanded: PropTypes.bool,
  isDivider: PropTypes.bool,
}

export default MainNavItem
