import './DisplayUpdatedIdentityDifferencesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { NEXT_ARROW_ICON } from 'constants/iconConstants'

const DisplayUpdatedIdentityDifferences = ({
  fromValue = '',
  toValue = '',
}) => {
  return (
    <div className='DisplayUpdatedIdentityDifferences'>
      <div className="difference-container flex space-between">
        <div className="old-value-container">
          <span className='old-value'>{fromValue}</span>
        </div>
        <i className={`fa fa-${NEXT_ARROW_ICON}`} />
        <div className="new-value-container">
          <span className='new-value'>{toValue}</span>
        </div>
      </div>
    </div>
  )
}

DisplayUpdatedIdentityDifferences.propTypes = {
  fromValue: PropTypes.string,
  toValue: PropTypes.string,
}

export default DisplayUpdatedIdentityDifferences
