import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues, hasSubmitFailed, isInvalid } from 'redux-form'
import CustomerOnboardingFormCustomerInfoForm from 'components/Customer/Forms/CustomerOnboardingFormCustomerInfoForm/CustomerOnboardingFormCustomerInfoForm'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'
import { CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentialId = getCurrentCredentialsId(state)
  const isFormInvalid = isInvalid(CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM)(state) && hasSubmitFailed(CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM)(state)
  const dealInfoValues = get(state, 'flowsR.formValues.dealInfo')
  const isStandaloneMerchant = get(dealInfoValues, 'customerProfile.value') === STANDALONE_MERCHANT
  const paymentVolumeByCustomerTypeLowerLabel = `Enter the distribution of transactions based on who the ${isStandaloneMerchant ? 'individual business’ customers' : 'customers of the platform’s users'} are. Total percentage must add up to 100%.`
  const onboardingFormCountry = get(dealInfoValues, 'country')
  const formValues = getFormValues(CUSTOMER_ONBOARDING_FORM_CUSTOMER_INFO_FORM)(state) || get(state, 'flowsR.formValues.customerInfo')
  const initialValues = {
    ...formValues,
    businessAddress: {
      country: onboardingFormCountry,
    },
  }

  return {
    credentialId,
    isFormInvalid,
    isStandaloneMerchant,
    paymentVolumeByCustomerTypeLowerLabel,
    onboardingFormCountry,
    initialValues,
  }
}

class CustomerOnboardingFormCustomerInfoFormC extends Component {
  render() {
    return (
      <CustomerOnboardingFormCustomerInfoForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(CustomerOnboardingFormCustomerInfoFormC)
