import React, { Component } from 'react'
import { connect } from 'react-redux'
import RawResponse from './RawResponse'
import get from 'lodash/get'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const rawResponse = get(props, 'rawResponse', {})

  const [
    customerResponseCode,
    ofacResponseCode,
    accountResponseCode,
  ] = getMany(rawResponse, [
    'CustomerResponseCode',
    'OfacResponseCode',
    'AccountResponseCode',
  ])

  return {
    customerResponseCode,
    ofacResponseCode,
    accountResponseCode,
    rawResponse,
  }
}

class RawResponseC extends Component {
  render() {
    return (
      <RawResponse
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(RawResponseC)
