import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResetMemberMFAModal from 'components/Customer/Modals/ResetMemberMFAModal/ResetMemberMFAModal'
import hideModalAction from 'utilities/actions/hideModalAction'
import { POST_DASHBOARD_USER_MFA_RESET_F_REQUEST } from 'constants/flowConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    resetMemberMFA: ({ id }) => dispatch({
      type: POST_DASHBOARD_USER_MFA_RESET_F_REQUEST,
      payload: { id },
      meta: {
        successCallback: () => dispatch(hideModalAction()),
      },
    }),
  }
}

class ResetMemberMFAModalC extends Component {
  render() {
    return <ResetMemberMFAModal {...this.props} />
  }
}

export default connect(null, mapDispatchToProps)(ResetMemberMFAModalC)
