import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetVelocityLimitsFlow from 'components/Customer/Pages/SetVelocityLimitsFlow/SetVelocityLimitsFlow'
import SetVelocityLimitsFormC from 'components/Customer/Forms/SetVelocityLimitsForm/SetVelocityLimitsFormC'
import UpdateBalanceAdjustmentsVelocityLimitsFormC from 'components/Customer/Forms/UpdateBalanceAdjustmentsVelocityLimitsForm/UpdateBalanceAdjustmentsVelocityLimitsFormC'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import submitSetVelocityLimitsForm from 'utilities/submit/submitSetVelocityLimitsForm'
import get from 'lodash/get'

import {
  CREATE_VELOCITY_LIMITS_FORM,
  UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM,
} from 'constants/formConstants'

import {
  APPLICATION,
  BALANCE_ADJUSTMENTS,
  RECIPIENT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const dashboardConfig = getCurrentDashboardConfig(state)
  const dashboardType = get(dashboardConfig, 'dashboardType')
  const applicationId = get(props, 'params.applicationId')
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const currentUser = getCurrentUser(state)
  const platformId = get(currentUser, 'platformId')

  const steps = [
    {
      name: APPLICATION,
      component: SetVelocityLimitsFormC,
      dataKey: 'applicationVelocityLimits',
      props: {
        applicationId,
        platformId,
        entityType: APPLICATION,
      },
      formValues: () => reduxFormValues(CREATE_VELOCITY_LIMITS_FORM),
    },
    {
      name: RECIPIENT,
      component: SetVelocityLimitsFormC,
      dataKey: 'recipientVelocityLimits',
      props: {
        applicationId,
        platformId,
        entityType: RECIPIENT,
      },
      formValues: () => reduxFormValues(CREATE_VELOCITY_LIMITS_FORM),
    },
    {
      name: BALANCE_ADJUSTMENTS,
      component: UpdateBalanceAdjustmentsVelocityLimitsFormC,
      dataKey: 'balanceAdjustmentsVelocityLimits',
      props: {
        applicationId,
        customSubmitFunc: submitSetVelocityLimitsForm,
        isSetLimitsFlow: true,
      },
      formValues: () => reduxFormValues(UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM),
    },
  ]

  return {
    dashboardType,
    credentials,
    applicationId,
    platformId,
    steps,
  }
}

class SetVelocityLimitsFlowC extends Component {
  render() {
    return (
      <SetVelocityLimitsFlow
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SetVelocityLimitsFlowC)
