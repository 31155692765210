import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

// TODO: Cata - add unit tests for this model
const backendTransferAttemptModel = ({ values }) => {
  const {
    totalAmount,
    currency,
    id,
    token,
    paymentInstrumentId,
    recipientMerchantId,
    recipientDetails,
    tags,
  } = values

  const [
    recipientType,
    recipientBusinessName,
    recipientDoingBusinessName,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientPhoneNumber,
    recipientPersonalAddress,
    recipientBusinessAddress,
  ] = getMany(recipientDetails, [
    'recipientType',
    'recipientBusinessName',
    'recipientDoingBusinessName',
    'recipientFirstName',
    'recipientLastName',
    'recipientEmail',
    'recipientPhoneNumber',
    'recipientPersonalAddress',
    'recipientBusinessAddress',
  ])

  const [
    recipientPersonalAddressLine1,
    recipientPersonalAddressLine2,
    recipientPersonalAddressCity,
    recipientPersonalAddressState,
    recipientPersonalAddressPostalCode,
    recipientPersonalAddressCountry,
  ] = getMany(recipientPersonalAddress, [
    'line1',
    'line2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  const [
    recipientBusinessAddressLine1,
    recipientBusinessAddressLine2,
    recipientBusinessAddressCity,
    recipientBusinessAddressState,
    recipientBusinessAddressPostalCode,
    recipientBusinessAddressCountry,
  ] = getMany(recipientBusinessAddress, [
    'line1',
    'line2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  return removeUndefined({
    amount: totalAmount,
    currency,
    entity_id: id,
    payment_instrument_id: paymentInstrumentId,
    token,
    recipient_merchant_id: recipientMerchantId,
    recipient_details: {
      type: recipientType,
      business_name: recipientBusinessName,
      doing_business_as: recipientDoingBusinessName,
      first_name: recipientFirstName,
      last_name: recipientLastName,
      email: recipientEmail,
      phone_number: recipientPhoneNumber,
      personal_address: recipientPersonalAddress ? {
        line1: recipientPersonalAddressLine1,
        line2: recipientPersonalAddressLine2,
        city: recipientPersonalAddressCity,
        region: recipientPersonalAddressState,
        postal_code: recipientPersonalAddressPostalCode,
        country: recipientPersonalAddressCountry,
      } : undefined,
      business_address: recipientBusinessAddress ? {
        line1: recipientBusinessAddressLine1,
        line2: recipientBusinessAddressLine2,
        city: recipientBusinessAddressCity,
        region: recipientBusinessAddressState,
        postal_code: recipientBusinessAddressPostalCode,
        country: recipientBusinessAddressCountry,
      } : undefined,
    },
    tags,
  })
}

export default backendTransferAttemptModel
