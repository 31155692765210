import AddIdentityBankAccountModal from 'components/Customer/Modals/AddIdentityBankAccountModal/AddIdentityBankAccountModal'
import EditIdentityBankAccountsModal from 'components/Customer/Modals/EditIdentityBankAccountsModal/EditIdentityBankAccountsModal'
import IdentitySettlementModal from 'components/Customer/Modals/IdentitySettlementModal/IdentitySettlementModal'
import IdentityVerificationModal from 'components/Customer/Modals/IdentityVerificationModal/IdentityVerificationModal'
import IdentityProvisionMerchantModal from 'components/Customer/Modals/IdentityProvisionMerchantModal/IdentityProvisionMerchantModal'
import EditTagsModal from 'components/Customer/Modals/EditTagsModal/EditTagsModal'
import ApplicationSettingModal from 'components/Customer/Modals/ApplicationSettingModal/ApplicationSettingModal'
import InviteUserModal from 'components/Customer/Modals/InviteUserModal/InviteUserModal'
import AddProcessorsModal from 'components/Customer/Modals/AddProcessorsModal/AddProcessorsModal'
import SearchUserModal from 'components/Customer/Modals/SearchUserModal/SearchUserModal'
import SendEmailModal from 'components/Customer/Modals/SendEmailModal/SendEmailModal'
import CreateAdjustmentModal from 'components/Customer/Modals/CreateAdjustmentModal/CreateAdjustmentModal'
import RemoveSettlementEntriesModal from 'components/Customer/Modals/RemoveSettlementEntriesModal/RemoveSettlementEntriesModal'
import UploadEvidenceModal from 'components/Customer/Modals/UploadEvidenceModal/UploadEvidenceModal'
import CreateAPIKeyModal from 'components/Customer/Modals/CreateAPIKeyModal/CreateAPIKeyModal'
import CreateAPIKeyConfirmationModal from 'components/Customer/Modals/CreateAPIKeyConfirmationModal/CreateAPIKeyConfirmationModal'
import MerchantSettingModal from 'components/Customer/Modals/MerchantSettingModal/MerchantSettingModal'
import MerchantVerificationModal from 'components/Customer/Modals/MerchantVerificationModal/MerchantVerificationModal'
import ConfirmationModal from 'components/Customer/Modals/ConfirmationModal/ConfirmationModal'
import RiskProfileModal from 'components/Customer/Modals/RiskProfileModal/RiskProfileModal'
import ExportDataModal from 'components/Customer/Modals/ExportDataModal/ExportDataModal'
import ExportDataV2Modal from 'components/Customer/Modals/ExportDataV2Modal/ExportDataV2Modal'
import ExportConfirmationModal from 'components/Customer/Modals/ExportConfirmationModal/ExportConfirmationModal'
import ResendTransferModal from 'components/Customer/Modals/ResendTransferModal/ResendTransferModal'
import CreatePaymentInstrumentVerificationModal from 'components/Customer/Modals/CreatePaymentInstrumentVerificationModal/CreatePaymentInstrumentVerificationModal'
import EditProcessorConfigModal from 'components/Customer/Modals/EditPlatformProcessorConfigModal/EditPlatformProcessorConfigModal'
import CloseSettlementModal from 'components/Customer/Modals/CloseSettlementModal/CloseSettlementModal'
import UpdateAllPermissionsModal from 'components/Customer/Modals/UpdateAllPermissionsModal/UpdateAllPermissionsModal'
import UpdateUserModal from 'components/Customer/Modals/UpdateUserModal/UpdateUserModal'
import OrchestrationStatusModal from 'components/Customer/Modals/OrchestrationStatusModal/OrchestrationStatusModal'
import CreateBalanceTransferModal from 'components/Customer/Modals/CreateBalanceTransferModal/CreateBalanceTransferModal'
import CreateSubscriptionModal from 'components/Customer/Modals/CreateSubscriptionModal/CreateSubscriptionModal'
import CreateSubscriptionScheduleAmountModal from 'components/Customer/Modals/CreateSubscriptionScheduleAmountModal/CreateSubscriptionScheduleAmountModal'
import CreateSubscriptionEnrollmentModal from 'components/Customer/Modals/CreateSubscriptionEnrollmentModal/CreateSubscriptionEnrollmentModal'
import CreateSubscriptionAmountModal from 'components/Customer/Modals/CreateSubscriptionAmountModal/CreateSubscriptionAmountModal'
import EditSubscriptionAmountModal from 'components/Customer/Modals/EditSubscriptionAmountModal/EditSubscriptionAmountModal'
import DeleteSubscriptionAmountModal from 'components/Customer/Modals/DeleteSubscriptionAmountModal/DeleteSubscriptionAmountModal'
import CreateFeeModal from 'components/Customer/Modals/CreateFeeModal/CreateFeeModal'
import FileViewerModal from 'components/Customer/Modals/FileViewerModal/FileViewerModal'
import RegisterNewDomainModal from 'components/Customer/Modals/RegisterNewDomainModal/RegisterNewDomainModal'
import UnregisterApplePayDomainModal from 'components/Customer/Modals/UnregisterApplePayDomainModal/UnregisterApplePayDomainModal'
import AddNewIOSApplicationModal from 'components/Customer/Modals/AddNewIOSApplicatonModal/AddNewIOSApplicationModal'
import RevokeApplePayCertModal from 'components/Customer/Modals/RevokeApplePayCertModal/RevokeApplePayCertModal'
import EditComplianceFormTemplateModal from 'components/Customer/Modals/EditComplianceFormTemplateModal/EditComplianceFormTemplateModal'
import ContactSupportModal from 'components/Customer/Modals/ContactSupportModal/ContactSupportModal'
import DashboardFeedbackModal from 'components/Customer/Modals/DashboardFeedbackModal/DashboardFeedbackModal'
import EditActiveComplianceTemplateModal from 'components/Customer/Modals/EditActiveComplianceTemplateModal/EditActiveComplianceTemplateModal'
import EditPayoutSettingsWarningModal from 'components/Customer/Modals/EditPayoutSettingsWarningModal/EditPayoutSettingsWarningModal'
import EditPayoutSettingsConfirmationModalC from 'components/Customer/Modals/EditPayoutSettingsConfirmationModal/EditPayoutSettingsConfirmationModalC'
import AttestComplianceFormModal from 'components/Customer/Modals/AttestComplianceFormModal/AttestComplianceFormModal'
import EditPaymentInstrumentModal from 'components/Customer/Modals/EditPaymentInstrumentModal/EditPaymentInstrumentModal'
import EnableDisableWebhookModal from 'components/Customer/Modals/EnableDisableWebhookModal/EnableDisableWebhookModal'
import ViewAPIResponseModal from 'components/Customer/Modals/ViewAPIResponseModal/ViewAPIResponseModal'
import ViewDeviceInfoModal from 'components/Customer/Modals/ViewDeviceInfoModal/ViewDeviceInfoModal'
import CreateNewOnboardingFormModal from 'components/Customer/Modals/CreateNewOnboardingFormModal/CreateNewOnboardingFormModal'
import ShowOnboardingFormLinkModal from 'components/Customer/Modals/ShowOnboardingFormLinkModal/ShowOnboardingFormLinkModal'
import VerificationResultsModal from 'components/Customer/Modals/VerificationResultsModal/VerificationResultsModal'
import ProcessorDetailsModal from 'components/Customer/Modals/ProcessorDetailsModal/ProcessorDetailsModal'
import ResendEmailModal from 'components/Customer/Modals/ResendEmailModal/ResendEmailModal'
import ContactSalesModal from 'components/Customer/Modals/ContactSalesModal/ContactSalesModal'
import ResendUnacceptedEventsModal from 'components/Customer/Modals/ResendUnacceptedEventsModal/ResendUnacceptedEventsModal'
import EditDashboardAccountNameModal from 'components/Customer/Modals/EditDashboardAccountNameModal/EditDashboardAccountNameModal'
import ResendDashboardInviteModal from 'components/Customer/Modals/ResendDashboardInviteModal/ResendDashboardInviteModal'
import ChangePasswordModal from 'components/Customer/Modals/ChangePasswordModal/ChangePasswordModal'
import DeactivateTeamMemberModal from 'components/Customer/Modals/DeactivateTeamMemberModal/DeactivateTeamMemberModal'
import ReinstateTeamMemberModal from 'components/Customer/Modals/ReinstateTeamMemberModal/ReinstateTeamMemberModal'
import WelcomeNameCaptureModal from 'components/Customer/Modals/WelcomeNameCaptureModal/WelcomeNameCaptureModal'
import DisableCustomRoleModal from 'components/Customer/Modals/DisableCustomRoleModal/DisableCustomRoleModal'
import EditUserInfoModal from 'components/Customer/Modals/EditUserInfoModal/EditUserInfoModal'
import AddEditBuyerInformationModal from 'components/Customer/Modals/AddEditBuyerInformationModal/AddEditBuyerInformationModal'
import SwitchDashboardTypeModal from 'components/Customer/Modals/SwitchDashboardTypeModal/SwitchDashboardTypeModal'
import DashboardTypeSelectModal from 'components/Customer/Modals/DashboardTypeSelectModal/DashboardTypeSelectModal'
import FormFileUploaderModal from 'components/Customer/Modals/FormFileUploaderModal/FormFileUploaderModal'
import CancelApplicationFormModal from 'components/Customer/Modals/CancelApplicationFormModal/CancelApplicationFormModal'
import ExitApplicationFormModal from 'components/Customer/Modals/ExitApplicationFormModal/ExitApplicationFormModal'
import PreviousStepModal from 'components/Customer/Modals/PreviousStepModal/PreviousStepModal'
import WithdrawLiveApplicationModal from 'components/Customer/Modals/WithdrawLiveApplicationModal/WithdrawLiveApplicationModal'
import DisableAPIKeyModal from 'components/Customer/Modals/DisableAPIKeyModal/DisableAPIKeyModal'
import AcceptLiabilityModal from 'components/Customer/Modals/AcceptLiabilityModal/AcceptLiabilityModal'
import UpdateDisputeStateModal from 'components/Customer/Modals/UpdateDisputeStateModal/UpdateDisputeStateModal'
import EditUnderwritingProfileModal from 'components/Customer/Modals/EditUnderwritingProfileModal/EditUnderwritingProfileModal'
import EditIdentityProcessingInformationModal from 'components/Customer/Modals/EditIdentityProcessingInformationModal/EditIdentityProcessingInformationModal'
import WithdrawOrApproveUnderwritingEntityModal from 'components/Customer/Modals/WithdrawOrApproveUnderwritingEntityModal/WithdrawOrApproveUnderwritingEntityModal'
import RejectUnderwritingEntityModal from 'components/Customer/Modals/RejectUnderwritingEntityModal/RejectUnderwritingEntityModal'
import RequestAdditionalInformationForUnderwritingEntityModal from 'components/Customer/Modals/RequestAdditionalInformationForUnderwritingEntityModal/RequestAdditionalInformationForUnderwritingEntityModal'
import AssignUnderwritingEntityToMeModal from 'components/Customer/Modals/AssignUnderwritingEntityToMeModal/AssignUnderwritingEntityToMeModal'
import FileUploadModal from 'components/Customer/Modals/FileUploadModal/FileUploadModal'
import GoogleMapDetailsModal from 'components/Customer/Modals/GoogleMapDetailsModal/GoogleMapDetailsModal'
import GenerateNewURLLinkModal from 'components/Customer/Modals/GenerateNewURLLinkModal/GenerateNewURLLinkModal'
import SetupMFAModalC from 'components/Customer/Modals/SetupMFAModal/SetupMFAModalC'
import ConfirmPasswordMFAModal from 'components/Customer/Modals/ConfirmPasswordMFAModal/ConfirmPasswordMFAModal'
import SetupMFASMSModal from 'components/Customer/Modals/SetupMFASMSModal/SetupMFASMSModal'
import SetupMFAMobileAuthenticatorModal from 'components/Customer/Modals/SetupMFAMobileAuthenticatorModal/SetupMFAMobileAuthenticatorModal'
import RequireTeamMFAModalC from 'components/Customer/Modals/RequireTeamMFAModal/RequireTeamMFAModalC'
import ResetMemberMFAModalC from 'components/Customer/Modals/ResetMemberMFAModal/ResetMemberMFAModalC'
import CreateRefundConfirmationModal from 'components/Customer/Modals/CreateRefundConfirmationModal/CreateRefundConfirmationModal'
import NewBuyerIdentityModal from 'components/Customer/Modals/NewBuyerIdentityModal/NewBuyerIdentityModal'
import CreatePaymentInstrumentModal from 'components/Customer/Modals/CreatePaymentInstrumentModal/CreatePaymentInstrumentModal'
import SelectSenderForCreatePaymentModal from 'components/Customer/Modals/SelectSenderForCreatePaymentModal/SelectSenderForCreatePaymentModal'
import UpdateAccountBalanceModal from 'components/Customer/Modals/UpdateAccountBalanceModal/UpdateAccountBalanceModal'
import SelectPaymentLinkMerchantModal from 'components/Customer/Modals/SelectPaymentLinkMerchantModal/SelectPaymentLinkMerchantModal'
import DisablePaymentLinkModalC from 'components/Customer/Modals/DisablePaymentLinkModal/DisablePaymentLinkModalC'
import EnablePaymentLinkModalC from 'components/Customer/Modals/EnablePaymentLinkModal/EnablePaymentLinkModalC'
import CreateNewRunModal from 'components/Customer/Modals/CreateNewRunModal/CreateNewRunModal'
import VoidAuthorizationModal from 'components/Customer/Modals/VoidAuthorizationModal/VoidAuthorizationModal'
import CaptureAuthorizationModal from 'components/Customer/Modals/CaptureAuthorizationModal/CaptureAuthorizationModal'
import SubmitCaseNoteModal from 'components/Customer/Modals/SubmitCaseNoteModal/SubmitCaseNoteModal'
import SelectAddDeviceMerchantModal from 'components/Customer/Modals/SelectAddDeviceMerchantModal/SelectAddDeviceMerchantModal'
import EditDeviceDetailsModal from 'components/Customer/Modals/EditDeviceDetailsModal/EditDeviceDetailsModal'
import ActivateDeviceModal from 'components/Customer/Modals/ActivateDeviceModal/ActivateDeviceModal'
import DeactivateDeviceModal from 'components/Customer/Modals/DeactivateDeviceModal/DeactivateDeviceModal'
import SetIdleScreenModal from 'components/Customer/Modals/SetIdleScreenModal/SetIdleScreenModal'
import RebootDeviceModal from 'components/Customer/Modals/RebootDeviceModal/RebootDeviceModal'
import EditDeviceConfigurationDetailsModal from 'components/Customer/Modals/EditDeviceConfigurationDetailsModal/EditDeviceConfigurationDetailsModal'
import EditUnderwritingWorkflowModalC from 'components/Customer/Modals/EditUnderwritingWorkflowModal/EditUnderwritingWorkflowModalC'
import InvalidateComplianceFormModal from 'components/Customer/Modals/InvalidateComplianceFormModal/InvalidateComplianceFormModal'
import InstantBankAccountVerificationModal from 'components/Customer/Modals/InstantBankAccountVerificationModal/InstantBankAccountVerificationModal'
import MerchantOnboardingFormSettingsModal from 'components/Customer/Modals/MerchantOnboardingFormSettingsModal/MerchantOnboardingFormSettingsModal'
import EditVisaPayoutConfigModal from 'components/Customer/Modals/EditVisaPayoutConfigModal/EditVisaPayoutConfigModal'
import EditMastercardPayoutConfigModal from 'components/Customer/Modals/EditMastercardPayoutConfigModal/EditMastercardPayoutConfigModal'
import EditPayoutProfileModal from 'components/Customer/Modals/EditPayoutProfileModal/EditPayoutProfileModal'
import PayoutsLimitReachedModal from 'components/Customer/Modals/PayoutsLimitReachedModal/PayoutsLimitReachedModal'
import ExitThisFlowModal from 'components/Customer/Modals/ExitThisFlowModal/ExitThisFlowModal'
import ContinuousMonitoringSettingsModal from 'components/Customer/Modals/ContinuousMonitoringSettingsModal/ContinuousMonitoringSettingsModal'
import AdditionalVerificationModal from 'components/Customer/Modals/AdditionalVerificationModal/AdditionalVerificationModal'
import SendToUnderwritingModal from 'components/Customer/Modals/SendToUnderwritingModal/SendToUnderwritingModal'
import DisablePayoutLinkModalC from 'components/Customer/Modals/DisablePayoutLinkModal/DisablePayoutLinkModalC'
import EnablePayoutLinkModalC from 'components/Customer/Modals/EnablePayoutLinkModal/EnablePayoutLinkModalC'
import ConnectDeviceForPaymentModal from 'components/Customer/Modals/ConnectDeviceForPaymentModal/ConnectDeviceForPaymentModal'
import UpdateRecipientSettingsModal from 'components/Customer/Modals/UpdateRecipientSettingsModal/UpdateRecipientSettingsModal'
import UpdateOnboardingFormFileModal from 'components/Customer/Modals/UpdateOnboardingFormFileModal/UpdateOnboardingFormFileModal'
import EditFeeProfileSettingsModal from 'components/Customer/Modals/EditFeeProfileSettingsModal/EditFeeProfileSettingsModal'
import SendReceiptModalC from 'components/Customer/Modals/SendReceiptModal/SendReceiptModalC'
import CancelSubscriptionModal from 'components/Customer/Modals/CancelSubscriptionModal/CancelSubscriptionModal'
import VerifyMFASMSAuthenticationCodeModal from 'components/Customer/Modals/VerifyMFASMSAuthenticationCodeModal/VerifyMFASMSAuthenticationCodeModal'
import ArchiveSubscriptionPlanModal from 'components/Customer/Modals/ArchiveSubscriptionPlanModal/ArchiveSubscriptionPlanModal'
import ResolveMonitoringAlertsModal from 'components/Customer/Modals/ResolveMonitoringAlertsModal/ResolveMonitoringAlertsModal'
import PaymentLinksNotAvailableModal from 'components/Customer/Modals/PaymentLinksNotAvailableModal/PaymentLinksNotAvailableModal'
import FeatureNotAvailableModalC from 'components/Customer/Modals/FeatureNotAvailableModal/FeatureNotAvailableModalC'

import {
  ACCEPT_LIABILITY_MODAL,
  ACTIVATE_DEVICE_MODAL,
  ADD_APPLICATION_PROCESSORS,
  ADD_EDIT_BUYER_INFORMATION_MODAL,
  ADD_IDENTITY_BANK_ACCOUNT,
  ADD_NEW_IOS_APPLICATION_MODAL,
  APPROVE_ALL_CONFIRM_ACTION,
  ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL,
  ATTEST_COMPLIANCE_FORM_MODAL,
  CANCEL_APPLICATION_FORM_MODAL,
  CAPTURE_AUTHORIZATION_MODAL,
  CHANGE_PASSWORD_MODAL,
  CLOSE_SETTLEMENT,
  CONFIRM_ACTION,
  CONFIRM_PASSWORD_MFA_MODAL,
  CONTACT_SALES_MODAL,
  CONTACT_SUPPORT_MODAL,
  CONTINUOUS_MONITORING_SETTINGS_MODAL,
  CREATE_ADJUSTMENT,
  CREATE_API_KEY,
  CREATE_API_KEY_SUCCESS,
  CREATE_BALANCE_TRANSFER,
  CREATE_FEE,
  CREATE_MERCHANT_VERIFICATION,
  CREATE_NEW_ONBOARDING_FORM,
  CREATE_NEW_RUN_MODAL,
  CREATE_PAYMENT_INSTRUMENT_MODAL,
  CREATE_PAYMENT_INSTRUMENT_VERIFICATION,
  CREATE_REFUND_CONFIRMATION_MODAL,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_AMOUNT,
  CREATE_SUBSCRIPTION_ENROLLMENT,
  CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT,
  DASHBOARD_FEEDBACK_MODAL,
  DASHBOARD_TYPE_MODAL,
  DEACTIVATE_DEVICE_MODAL,
  DEACTIVATE_TEAM_MEMBER_MODAL,
  DELETE_SUBSCRIPTION_AMOUNT,
  DISABLE_API_KEY,
  DISABLE_CUSTOM_ROLE_MODAL,
  DISABLE_PAYMENT_LINK_MODAL,
  DISABLE_PAYOUT_LINK_MODAL,
  EDIT_ACTIVE_COMPLIANCE_TEMPLATE,
  EDIT_APPLICATION_SETTING,
  EDIT_COMPLIANCE_FORM_TEMPLATE,
  EDIT_DASHBOARD_ACCOUNT_NAME_MODAL,
  EDIT_DEVICE_CONFIGURATIONS_MODAL,
  EDIT_DEVICE_DETAILS_MODAL,
  EDIT_IDENTITY_BANK_ACCOUNTS,
  EDIT_IDENTITY_PROCESSING_INFO_MODAL,
  EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL,
  EDIT_MERCHANT_SETTING,
  EDIT_PAYMENT_INSTRUMENT,
  EDIT_PAYOUT_PROFILE_MODAL,
  EDIT_PAYOUT_SETTINGS_CONFIRMATION,
  EDIT_PAYOUT_SETTINGS_WARNING,
  EDIT_PROCESSOR_CONFIG,
  EDIT_RISK_PROFILE,
  EDIT_SUBSCRIPTION_AMOUNT,
  EDIT_TAGS,
  EDIT_UNDERWRITING_PROFILE_MODAL,
  EDIT_UNDERWRITING_WORKFLOW_MODAL,
  EDIT_USER_INFO_MODAL,
  EDIT_VISA_PAYOUT_CONFIG_MODAL,
  ENABLE_DISABLE_WEBHOOK_MODAL,
  ENABLE_PAYMENT_LINK_MODAL,
  ENABLE_PAYOUT_LINK_MODAL,
  EXIT_APPLICATION_FORM_MODAL,
  EXIT_THIS_FLOW_MODAL,
  EXPORT_CONFIRMATION,
  EXPORT_DATA,
  EXPORT_DATA_V2,
  FILE_UPLOAD_MODAL,
  FILE_VIEWER_MODAL,
  FORM_FILE_UPLOADER_MODAL,
  GENERATE_NEW_URL_LINK_MODAL,
  GO_TO_PREVIOUS_STEP_MODAL,
  GOOGLE_MAP_DETAILS_MODAL,
  IDENTITY_PROVISION_MERCHANT,
  IDENTITY_SETTLEMENT,
  IDENTITY_VERIFICATION,
  INSTANT_BANK_VERIFICATION_MODAL,
  INVALIDATE_COMPLIANCE_FORM_MODAL,
  INVITE_USER,
  MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL,
  NEW_BUYER_IDENTITY_MODAL,
  ORCHESTRATION_STATUS,
  PAYOUTS_LIMIT_REACHED_MODAL,
  PROCESSOR_DETAILS_MODAL,
  REBOOT_DEVICE_MODAL,
  REGISTER_NEW_DOMAIN_MODAL,
  REINSTATE_TEAM_MEMBER_MODAL,
  REJECT_UNDERWRITING_ENTITY_MODAL,
  REMOVE_SETTLEMENT_ENTRIES,
  REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL,
  REQUIRE_TEAM_MFA_MODAL,
  RESEND_DASHBOARD_INVITE_MODAL,
  RESEND_EMAIL_MODAL,
  RESEND_TRANSFER,
  RESEND_UNACCEPTED_EVENTS_MODAL,
  RESET_MEMBER_MFA_MODAL,
  REVOKE_APPLE_PAY_CERT_MODAL,
  SEARCH_USER,
  SELECT_ADD_DEVICE_MERCHANT_MODAL,
  SELECT_PAYMENT_LINK_MERCHANT_MODAL,
  SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
  SEND_EMAIL,
  SET_IDLE_SCREEN_MODAL,
  SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL,
  SETUP_MFA_MODAL,
  SETUP_MFA_SMS_MODAL,
  SHOW_ONBOARDING_FORM_LINK_MODAL,
  SUBMIT_NOTE_MODAL,
  SWITCH_DASHBOARD_TYPE_MODAL,
  UNREGISTER_APPLE_PAY_DOMAINS_MODAL,
  UPDATE_ACCOUNT_BALANCE_MODAL,
  UPDATE_ALL_PERMISSIONS,
  UPDATE_DISPUTE_STATE_MODAL,
  UPDATE_USER,
  UPLOAD_EVIDENCE,
  VERIFICATION_RESULT_MODAL,
  VIEW_API_RESPONSE_MODAL,
  VIEW_DEVICE_INFO_MODAL,
  VIRTUAL_TERMINAL_NOT_AVAILABLE_MODAL,
  VOID_AUTHORIZATION_MODAL,
  WELCOME_NAME_CAPTURE_MODAL,
  WITHDRAW_LIVE_APPLICATION_FORM_MODAL,
  WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL,
  ADDITIONAL_VERIFICATION_MODAL,
  SEND_TO_UNDERWRITING_MODAL,
  CONNECT_DEVICE_FOR_PAYMENT_MODAL,
  UPDATE_RECIPIENT_SETTINGS_MODAL,
  UPDATE_ONBOARDING_FORM_FILE_MODAL,
  EDIT_FEE_PROFILE_SETTINGS_MODAL,
  SEND_RECEIPT_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
  VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL,
  ARCHIVE_A_PLAN_MODAL,
  RESOLVE_MONITORING_ALERTS_MODAL,
  PAYMENT_LINKS_NOT_AVAILABLE_MODAL,
  FEATURE_NOT_AVAILABLE_MODAL,
} from 'constants/modalConstants'

export const MODAL_TYPE_TO_COMPONENT_MAP = {
  [ADD_IDENTITY_BANK_ACCOUNT]: AddIdentityBankAccountModal,
  [EDIT_IDENTITY_BANK_ACCOUNTS]: EditIdentityBankAccountsModal,
  [IDENTITY_SETTLEMENT]: IdentitySettlementModal,
  [IDENTITY_VERIFICATION]: IdentityVerificationModal,
  [IDENTITY_PROVISION_MERCHANT]: IdentityProvisionMerchantModal,
  [EDIT_TAGS]: EditTagsModal,
  [EDIT_APPLICATION_SETTING]: ApplicationSettingModal,
  [EDIT_MERCHANT_SETTING]: MerchantSettingModal,
  [INVITE_USER]: InviteUserModal,
  [SEARCH_USER]: SearchUserModal,
  [SEND_EMAIL]: SendEmailModal,
  [ADD_APPLICATION_PROCESSORS]: AddProcessorsModal,
  [CREATE_ADJUSTMENT]: CreateAdjustmentModal,
  [REMOVE_SETTLEMENT_ENTRIES]: RemoveSettlementEntriesModal,
  [UPLOAD_EVIDENCE]: UploadEvidenceModal,
  [CREATE_API_KEY]: CreateAPIKeyModal,
  [CREATE_API_KEY_SUCCESS]: CreateAPIKeyConfirmationModal,
  [CREATE_MERCHANT_VERIFICATION]: MerchantVerificationModal,
  [CONFIRM_ACTION]: ConfirmationModal,
  [APPROVE_ALL_CONFIRM_ACTION]: ConfirmationModal,
  [EDIT_RISK_PROFILE]: RiskProfileModal,
  [EXPORT_DATA]: ExportDataModal,
  [EXPORT_DATA_V2]: ExportDataV2Modal,
  [EXPORT_CONFIRMATION]: ExportConfirmationModal,
  [RESEND_TRANSFER]: ResendTransferModal,
  [CREATE_PAYMENT_INSTRUMENT_VERIFICATION]: CreatePaymentInstrumentVerificationModal,
  [EDIT_PROCESSOR_CONFIG]: EditProcessorConfigModal,
  [CLOSE_SETTLEMENT]: CloseSettlementModal,
  [UPDATE_ALL_PERMISSIONS]: UpdateAllPermissionsModal,
  [UPDATE_USER]: UpdateUserModal,
  [ORCHESTRATION_STATUS]: OrchestrationStatusModal,
  [CREATE_BALANCE_TRANSFER]: CreateBalanceTransferModal,
  [CREATE_SUBSCRIPTION_ENROLLMENT]: CreateSubscriptionEnrollmentModal,
  [CREATE_SUBSCRIPTION]: CreateSubscriptionModal,
  [CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT]: CreateSubscriptionScheduleAmountModal,
  [CREATE_FEE]: CreateFeeModal,
  [CREATE_SUBSCRIPTION_AMOUNT]: CreateSubscriptionAmountModal,
  [EDIT_SUBSCRIPTION_AMOUNT]: EditSubscriptionAmountModal,
  [DELETE_SUBSCRIPTION_AMOUNT]: DeleteSubscriptionAmountModal,
  [FILE_VIEWER_MODAL]: FileViewerModal,
  [REGISTER_NEW_DOMAIN_MODAL]: RegisterNewDomainModal,
  [UNREGISTER_APPLE_PAY_DOMAINS_MODAL]: UnregisterApplePayDomainModal,
  [ADD_NEW_IOS_APPLICATION_MODAL]: AddNewIOSApplicationModal,
  [REVOKE_APPLE_PAY_CERT_MODAL]: RevokeApplePayCertModal,
  [FILE_UPLOAD_MODAL]: FileUploadModal,
  [EDIT_COMPLIANCE_FORM_TEMPLATE]: EditComplianceFormTemplateModal,
  [CONTACT_SUPPORT_MODAL]: ContactSupportModal,
  [DASHBOARD_FEEDBACK_MODAL]: DashboardFeedbackModal,
  [EDIT_ACTIVE_COMPLIANCE_TEMPLATE]: EditActiveComplianceTemplateModal,
  [EDIT_PAYOUT_SETTINGS_WARNING]: EditPayoutSettingsWarningModal,
  [EDIT_PAYOUT_SETTINGS_CONFIRMATION]: EditPayoutSettingsConfirmationModalC,
  [ATTEST_COMPLIANCE_FORM_MODAL]: AttestComplianceFormModal,
  [EDIT_PAYMENT_INSTRUMENT]: EditPaymentInstrumentModal,
  [ENABLE_DISABLE_WEBHOOK_MODAL]: EnableDisableWebhookModal,
  [VIEW_API_RESPONSE_MODAL]: ViewAPIResponseModal,
  [VIEW_DEVICE_INFO_MODAL]: ViewDeviceInfoModal,
  [CREATE_NEW_ONBOARDING_FORM]: CreateNewOnboardingFormModal,
  [SHOW_ONBOARDING_FORM_LINK_MODAL]: ShowOnboardingFormLinkModal,
  [VERIFICATION_RESULT_MODAL]: VerificationResultsModal,
  [PROCESSOR_DETAILS_MODAL]: ProcessorDetailsModal,
  [RESEND_EMAIL_MODAL]: ResendEmailModal,
  [CONTACT_SALES_MODAL]: ContactSalesModal,
  [RESEND_UNACCEPTED_EVENTS_MODAL]: ResendUnacceptedEventsModal,
  [EDIT_DASHBOARD_ACCOUNT_NAME_MODAL]: EditDashboardAccountNameModal,
  [RESEND_DASHBOARD_INVITE_MODAL]: ResendDashboardInviteModal,
  [CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
  [DEACTIVATE_TEAM_MEMBER_MODAL]: DeactivateTeamMemberModal,
  [REINSTATE_TEAM_MEMBER_MODAL]: ReinstateTeamMemberModal,
  [WELCOME_NAME_CAPTURE_MODAL]: WelcomeNameCaptureModal,
  [DISABLE_CUSTOM_ROLE_MODAL]: DisableCustomRoleModal,
  [EDIT_USER_INFO_MODAL]: EditUserInfoModal,
  [ADD_EDIT_BUYER_INFORMATION_MODAL]: AddEditBuyerInformationModal,
  [SWITCH_DASHBOARD_TYPE_MODAL]: SwitchDashboardTypeModal,
  [DASHBOARD_TYPE_MODAL]: DashboardTypeSelectModal,
  [FORM_FILE_UPLOADER_MODAL]: FormFileUploaderModal,
  [CANCEL_APPLICATION_FORM_MODAL]: CancelApplicationFormModal,
  [EXIT_APPLICATION_FORM_MODAL]: ExitApplicationFormModal,
  [GO_TO_PREVIOUS_STEP_MODAL]: PreviousStepModal,
  [WITHDRAW_LIVE_APPLICATION_FORM_MODAL]: WithdrawLiveApplicationModal,
  [DISABLE_API_KEY]: DisableAPIKeyModal,
  [ACCEPT_LIABILITY_MODAL]: AcceptLiabilityModal,
  [UPDATE_DISPUTE_STATE_MODAL]: UpdateDisputeStateModal,
  [EDIT_UNDERWRITING_PROFILE_MODAL]: EditUnderwritingProfileModal,
  [EDIT_IDENTITY_PROCESSING_INFO_MODAL]: EditIdentityProcessingInformationModal,
  [WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL]: WithdrawOrApproveUnderwritingEntityModal,
  [REJECT_UNDERWRITING_ENTITY_MODAL]: RejectUnderwritingEntityModal,
  [REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL]: RequestAdditionalInformationForUnderwritingEntityModal,
  [ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL]: AssignUnderwritingEntityToMeModal,
  [GOOGLE_MAP_DETAILS_MODAL]: GoogleMapDetailsModal,
  [GENERATE_NEW_URL_LINK_MODAL]: GenerateNewURLLinkModal,
  [SETUP_MFA_MODAL]: SetupMFAModalC,
  [CONFIRM_PASSWORD_MFA_MODAL]: ConfirmPasswordMFAModal,
  [SETUP_MFA_SMS_MODAL]: SetupMFASMSModal,
  [SETUP_MFA_MOBILE_AUTHENTICATOR_MODAL]: SetupMFAMobileAuthenticatorModal,
  [REQUIRE_TEAM_MFA_MODAL]: RequireTeamMFAModalC,
  [RESET_MEMBER_MFA_MODAL]: ResetMemberMFAModalC,
  [CREATE_REFUND_CONFIRMATION_MODAL]: CreateRefundConfirmationModal,
  [NEW_BUYER_IDENTITY_MODAL]: NewBuyerIdentityModal,
  [CREATE_PAYMENT_INSTRUMENT_MODAL]: CreatePaymentInstrumentModal,
  [SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL]: SelectSenderForCreatePaymentModal,
  [UPDATE_ACCOUNT_BALANCE_MODAL]: UpdateAccountBalanceModal,
  [SELECT_PAYMENT_LINK_MERCHANT_MODAL]: SelectPaymentLinkMerchantModal,
  [DISABLE_PAYMENT_LINK_MODAL]: DisablePaymentLinkModalC,
  [ENABLE_PAYMENT_LINK_MODAL]: EnablePaymentLinkModalC,
  [CREATE_NEW_RUN_MODAL]: CreateNewRunModal,
  [VOID_AUTHORIZATION_MODAL]: VoidAuthorizationModal,
  [CAPTURE_AUTHORIZATION_MODAL]: CaptureAuthorizationModal,
  [SUBMIT_NOTE_MODAL]: SubmitCaseNoteModal,
  [SELECT_ADD_DEVICE_MERCHANT_MODAL]: SelectAddDeviceMerchantModal,
  [EDIT_DEVICE_DETAILS_MODAL]: EditDeviceDetailsModal,
  [ACTIVATE_DEVICE_MODAL]: ActivateDeviceModal,
  [DEACTIVATE_DEVICE_MODAL]: DeactivateDeviceModal,
  [SET_IDLE_SCREEN_MODAL]: SetIdleScreenModal,
  [REBOOT_DEVICE_MODAL]: RebootDeviceModal,
  [EDIT_DEVICE_CONFIGURATIONS_MODAL]: EditDeviceConfigurationDetailsModal,
  [EDIT_UNDERWRITING_WORKFLOW_MODAL]: EditUnderwritingWorkflowModalC,
  [INVALIDATE_COMPLIANCE_FORM_MODAL]: InvalidateComplianceFormModal,
  [INSTANT_BANK_VERIFICATION_MODAL]: InstantBankAccountVerificationModal,
  [MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL]: MerchantOnboardingFormSettingsModal,
  [EDIT_VISA_PAYOUT_CONFIG_MODAL]: EditVisaPayoutConfigModal,
  [EDIT_MASTERCARD_PAYOUT_CONFIG_MODAL]: EditMastercardPayoutConfigModal,
  [EDIT_PAYOUT_PROFILE_MODAL]: EditPayoutProfileModal,
  [PAYOUTS_LIMIT_REACHED_MODAL]: PayoutsLimitReachedModal,
  [EXIT_THIS_FLOW_MODAL]: ExitThisFlowModal,
  [CONTINUOUS_MONITORING_SETTINGS_MODAL]: ContinuousMonitoringSettingsModal,
  [ADDITIONAL_VERIFICATION_MODAL]: AdditionalVerificationModal,
  [SEND_TO_UNDERWRITING_MODAL]: SendToUnderwritingModal,
  [DISABLE_PAYOUT_LINK_MODAL]: DisablePayoutLinkModalC,
  [ENABLE_PAYOUT_LINK_MODAL]: EnablePayoutLinkModalC,
  [CONNECT_DEVICE_FOR_PAYMENT_MODAL]: ConnectDeviceForPaymentModal,
  [UPDATE_RECIPIENT_SETTINGS_MODAL]: UpdateRecipientSettingsModal,
  [UPDATE_ONBOARDING_FORM_FILE_MODAL]: UpdateOnboardingFormFileModal,
  [EDIT_FEE_PROFILE_SETTINGS_MODAL]: EditFeeProfileSettingsModal,
  [SEND_RECEIPT_MODAL]: SendReceiptModalC,
  [CANCEL_SUBSCRIPTION_MODAL]: CancelSubscriptionModal,
  [VERIFY_MFA_SMS_AUTHENTICATION_CODE_MODAL]: VerifyMFASMSAuthenticationCodeModal,
  [ARCHIVE_A_PLAN_MODAL]: ArchiveSubscriptionPlanModal,
  [RESOLVE_MONITORING_ALERTS_MODAL]: ResolveMonitoringAlertsModal,
  [PAYMENT_LINKS_NOT_AVAILABLE_MODAL]: PaymentLinksNotAvailableModal,
  [FEATURE_NOT_AVAILABLE_MODAL]: FeatureNotAvailableModalC,
}
