import './ApplicationMerchantsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { APPLICATION_MERCHANTS } from 'constants/linkConstants'
import { GET_APPLICATION_MERCHANTS } from 'constants/flowConstants'
import { MERCHANTS_ICON } from 'constants/iconConstants'
import { getMerchantIdentitySelector } from 'state-layer/selectors'

const ApplicationMerchants = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  merchantPath = () => {},
  isFetching = false,
  applicationId = '',
}) => {
  return (
    <div className='ApplicationMerchants'>
      <TableC
        flow={GET_APPLICATION_MERCHANTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getMerchantIdentitySelector}
        selectorId={applicationId}
        initialQueries={initialQueries}
        linksKey={APPLICATION_MERCHANTS}
        allowedFilters={allowedFilters}
        path={merchantPath}
        isFetching={isFetching}
        icon={`fa fa-${MERCHANTS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Merchants`}
      />
    </div>
  )
}

ApplicationMerchants.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  merchantPath: PropTypes.func,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
}

export default ApplicationMerchants
