import toNumber from 'lodash/toNumber'

import {
  AM,
  PM,
} from 'constants/timeConstants'

const getAMPM = ({ militaryTime }) => {
  const normalizedMilitaryTime = toNumber(militaryTime)

  if (normalizedMilitaryTime >= 0 && normalizedMilitaryTime <= 24) {
    return (normalizedMilitaryTime < 12) ? AM : PM
  }

  return null
}

export default getAMPM
