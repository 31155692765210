import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  USER,
  USERS,
} from 'constants/apiConstants'

const getPlatformUserAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    path: USER({ id }),
    service: USERS,
  })
}

export default getPlatformUserAPI
