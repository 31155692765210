import React from 'react'
import PropTypes from 'prop-types'
import UpdateDisputeStateFormC from 'components/Customer/Forms/UpdateDisputeStateForm/UpdateDisputeStateFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { UPDATE_DISPUTE_STATE } from 'constants/language/languageConstants'

const UpdateDisputeStateModal = ({
  disputeId,
}) => {
  return (
    <GenericModal
      title={UPDATE_DISPUTE_STATE}
      className='UpdateDisputeStateModal'
      Component={UpdateDisputeStateFormC}
      disputeId={disputeId}
    />
  )
}

UpdateDisputeStateModal.propTypes = {
  disputeId: PropTypes.string,
}

export default UpdateDisputeStateModal
