import createAction from 'utilities/actions/createAction'
import { PATCH_ENTITY_CONFIGURATION_F_REQUEST } from 'constants/flowConstants'

const patchEntityConfigurationRequest = ({
  id,
  values,
}) => createAction({
  type: PATCH_ENTITY_CONFIGURATION_F_REQUEST,
  id,
  values,
})

export default patchEntityConfigurationRequest
