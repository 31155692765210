import './NotificationsDropdownS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import NotificationItemC from './NotificationItemC'
import { CONFIGURATION_ICON } from 'constants/iconConstants'
import size from 'lodash/size'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

import {
  NO_UNREAD_NOTIFICATIONS,
  NOTIFICATIONS_DROPDOWN_MARK_AS_READ_TITLE,
  NOTIFICATIONS_TITLE,
  VIEW_ALL,
} from 'constants/language/languageConstants'

const NotificationsDropdown = ({
  groupedNotifications = {},
  clickMarkAllAsRead = () => {},
  settingsPath = '',
  viewAllPath = '',
  noNotifications = false,
  unreadNotificationsCount,
}) => {
  return (
    <div className='NotificationsDropdown'>
      <div className='flex space-between notifications-header'>
        <h5 className='notifications-title'>{NOTIFICATIONS_TITLE}</h5>
        <Link className='notification-settings-link' to={settingsPath}><i className={`far fa-${CONFIGURATION_ICON}`} /></Link>
      </div>
      <div className='notification-items'>
        { noNotifications && <div className='p-2 empty-notifications'>{NO_UNREAD_NOTIFICATIONS}</div> }

        { !noNotifications && map(groupedNotifications, (value, key) => {
          const groupedNotificationsLength = size(value)
          if (groupedNotificationsLength === 0) { return false }

          return (
            <>
              { key === 'day' ? <div className='notification-header p-2-bold'>Today ({groupedNotificationsLength})</div> : <div className='notification-header bold'>Past Notifications ({groupedNotificationsLength})</div> }
              { map(value, (notification) => {
                return (
                  <NotificationItemC
                    notification={notification}
                  />
                )
              })}
            </>
          )
        })}
      </div>
      <div className='flex space-between items-center notifications-footer p-1'>
        <Link className='notification-view-all-link' to={viewAllPath}>{startCase(VIEW_ALL)}</Link>
        {unreadNotificationsCount && !noNotifications && <div className='notification-mark-as-read-button' onClick={clickMarkAllAsRead}>{NOTIFICATIONS_DROPDOWN_MARK_AS_READ_TITLE} ({unreadNotificationsCount})</div>}
      </div>
    </div>
  )
}

NotificationsDropdown.propTypes = {
  groupedNotifications: PropTypes.object,
  clickMarkAllAsRead: PropTypes.func,
  settingsPath: PropTypes.string,
  viewAllPath: PropTypes.string,
  noNotifications: PropTypes.bool,
}

export default NotificationsDropdown
