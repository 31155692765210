import createAction from 'utilities/actions/createAction'
import { GET_APPLICATIONS_F_REQUEST } from 'constants/flowConstants'

const getApplicationsRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_APPLICATIONS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getApplicationsRequest
