import { GET_SETTLEMENT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSettlementRequest = ({
  settlementId,
  credentials,
}) => createAction({
  type: GET_SETTLEMENT_F_REQUEST,
  id: settlementId,
  credentials,
})

export default getSettlementRequest
