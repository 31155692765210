import paymentsAPI from 'utilities/api/paymentsAPI'
import { DECISIONS } from 'constants/apiConstants'

const getDecisionsAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    path: DECISIONS,
    queries,
    credentials,
  })
}

export default getDecisionsAPI
