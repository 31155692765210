// UTILITIES
export const F_REQUEST = 'F_REQUEST'
export const F_SUCCESS = 'F_SUCCESS'
export const F_CANCEL = 'F_CANCEL'
export const F_FAILURE = 'F_FAILURE'
export const F_CANCELLED = 'F_CANCELLED'
export const CLEAR_REDUCER = 'CLEAR_REDUCER'
export const NOTIFICATION = 'NOTIFICATION'

export const GET = (type) => `GET_${type}`
export const POST = (type) => `POST_${type}`
export const PATCH = (type) => `PATCH_${type}`
export const DELETE = (type) => `DELETE_${type}`

export const requestF = (type) => `${type}_${F_REQUEST}`
export const successF = (type) => `${type}_${F_SUCCESS}`
export const failureF = (type) => `${type}_${F_FAILURE}`
export const cancelF = (type) => `${type}_${F_CANCEL}`
export const cancelledF = (type) => `${type}_${F_CANCELLED}`
export const notification = (type) => `${type}_${NOTIFICATION}`

// CUSTOM FLOWS
export const LOGOUT_F_REQUEST = 'LOGOUT_F_REQUEST'
export const READ_COOKIES = 'READ_COOKIES'
export const WRITE_COOKIES = 'WRITE_COOKIES'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

export const REDIRECT_F_REQUEST = 'REDIRECT_F_REQUEST'
export const REDIRECT_F_SUCCESS = 'REDIRECT_F_SUCCESS'
export const REDIRECT_F_FAILURE = 'REDIRECT_F_FAILURE'

export const SELECT_ITEMS = 'SELECT_ITEMS'
export const UNSELECT_ITEMS = 'UNSELECT_ITEMS'
export const UNSELECT_ALL_ITEMS = 'UNSELECT_ALL_ITEMS'

export const ADD_TABLE_FILTER = 'ADD_TABLE_FILTER'
export const ADD_TABLE_FILTERS = 'ADD_TABLE_FILTERS'
export const REMOVE_TABLE_FILTER = 'REMOVE_TABLE_FILTER'
export const CLEAR_TABLE_FILTERS = 'CLEAR_TABLE_FILTERS'

export const CLEAR_TABLE_LINKS = 'CLEAR_TABLE_LINKS'

export const RENDER_TAB = 'RENDER_TAB'

export const SHOW_HIGHLIGHT = 'SHOW_HIGHLIGHT'
export const HIDE_HIGHLIGHT = 'HIDE_HIGHLIGHT'
export const PREV_HIGHLIGHT = 'PREV_HIGHLIGHT'
export const NEXT_HIGHLIGHT = 'NEXT_HIGHLIGHT'

export const NEXT_FLOW_STEP = 'NEXT_FLOW_STEP'
export const PREV_FLOW_STEP = 'PREV_FLOW_STEP'


export const FLASH_NOTIFICATION = 'FLASH_NOTIFICATION'
export const HIDE_FLASH_NOTIFICATION = 'HIDE_FLASH_NOTIFICATION'

export const GET_FEATURE_FLAG = 'GET_FEATURE_FLAG'
export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS'

export const LOG_ERROR_MESSAGE = 'LOG_ERROR_MESSAGE'

// GENERIC FLOWS UTIL CONSTANTS
export const EXAMPLE = 'EXAMPLE'

// GENERIC FLOWS
export const SESSION = 'SESSION'
export const APP_CONFIGURATION = 'APP_CONFIGURATION'
export const CONFIGURATION = 'CONFIGURATION'
export const CONFIGURATIONS = 'CONFIGURATIONS'
export const CREDENTIAL = 'CREDENTIAL'
export const CREDENTIALS = 'CREDENTIALS'
export const RECENTLY_ACTIVE_CREDENTIALS = 'RECENTLY_ACTIVE_CREDENTIALS'
export const TRANSFERS_SEARCH = 'TRANSFERS_SEARCH'
export const TRANSFERS = 'TRANSFERS'
export const BALANCE_TRANSFER = 'BALANCE_TRANSFER'
export const BALANCE_TRANSFERS = 'BALANCE_TRANSFERS'
export const AUTHORIZATIONS = 'AUTHORIZATIONS'
export const AUTHORIZATIONS_SEARCH = 'AUTHORIZATIONS_SEARCH'
export const SETTLEMENTS = 'SETTLEMENTS'
export const DISPUTES = 'DISPUTES'
export const DISPUTE = 'DISPUTE'
export const REPORTS = 'REPORTS'
export const CATEGORIZED_REPORTS = 'CATEGORIZED_REPORTS'
export const IDENTITIES = 'IDENTITIES'
export const IDENTITY = 'IDENTITY'
export const RECIPIENT_IDENTITY = 'RECIPIENT_IDENTITY'
export const IDENTITY_UNMASK = 'IDENTITY_UNMASK'
export const MERCHANT = 'MERCHANT'
export const MERCHANTS = 'MERCHANTS'
export const MERCHANT_COMPLIANCE_FORMS = 'MERCHANT_COMPLIANCE_FORMS'
export const COMPLIANCE_FORMS = 'COMPLIANCE_FORMS'
export const COMPLIANCE_FORM = 'COMPLIANCE_FORM'
export const COMPLIANCE_FORM_APPLICATION = 'COMPLIANCE_FORM_APPLICATION'
export const COMPLIANCE_FORMS_APPLICATION = 'COMPLIANCE_FORMS_APPLICATION'
export const COMPLIANCE_FORM_TEMPLATE_APPLICATION = 'COMPLIANCE_FORM_TEMPLATE_APPLICATION'
export const COMPLIANCE_FORM_TEMPLATES = 'COMPLIANCE_FORM_TEMPLATES'
export const COMPLIANCE_FORM_TEMPLATE = 'COMPLIANCE_FORM_TEMPLATE'
export const ACTIVE_COMPLIANCE_FORM_TEMPLATE = 'ACTIVE_COMPLIANCE_FORM_TEMPLATE'
export const COMPLIANCE_FORM_TEMPLATE_FORMS = 'COMPLIANCE_FORM_TEMPLATE_FORMS'
export const COMPLIANCE_SETTINGS = 'COMPLIANCE_SETTINGS'
export const APPLICATION = 'APPLICATION'
export const APPLICATIONS = 'APPLICATIONS'
export const APPLICATIONS_IDENTITY = 'APPLICATIONS_IDENTITY'
export const PAYMENT_INSTRUMENT = 'PAYMENT_INSTRUMENT'
export const PAYMENT_INSTRUMENT_UNMASK = 'PAYMENT_INSTRUMENT_UNMASK'
export const PAYMENT_INSTRUMENTS = 'PAYMENT_INSTRUMENTS'
export const PAYMENT_INSTRUMENT_TRANSFERS = 'PAYMENT_INSTRUMENT_TRANSFERS'
export const PAYMENT_INSTRUMENT_AUTHORIZATIONS = 'PAYMENT_INSTRUMENT_AUTHORIZATIONS'
export const PAYMENT_INSTRUMENT_VERIFICATIONS = 'PAYMENT_INSTRUMENT_VERIFICATIONS'
export const PAYMENT_INSTRUMENT_APPLICATION = 'PAYMENT_INSTRUMENT_APPLICATION'
export const PAYMENT_INSTRUMENT_IDENTITY = 'PAYMENT_INSTRUMENT_IDENTITY'
export const TRANSFER_PAYMENT_INSTRUMENT_IDENTITY = 'TRANSFER_PAYMENT_INSTRUMENT_IDENTITY'
export const REVIEW_RUNS = 'REVIEW_RUNS'
export const REVIEW_QUEUE = 'REVIEW_QUEUE'
export const REVIEW_QUEUE_ITEM = 'REVIEW_QUEUE_ITEM'
export const REVIEW_QUEUE_ITEMS = 'REVIEW_QUEUE_ITEMS'
export const ALL_REVIEW_QUEUE_ITEMS = 'ALL_REVIEW_QUEUE_ITEMS'
export const ALL_REVIEW_QUEUE_ITEMS_BATCH = 'ALL_REVIEW_QUEUE_ITEMS_BATCH'
export const ALL_REVIEW_QUEUE_ITEMS_CANCEL = 'ALL_REVIEW_QUEUE_ITEMS_CANCEL'
export const BALANCE_ENTRY = 'BALANCE_ENTRY'
export const REVIEW_QUEUE_SETTLEMENTS = 'REVIEW_QUEUE_SETTLEMENTS'
export const REVIEW_QUEUE_APPLICATION = 'REVIEW_QUEUE_APPLICATION'
export const REVIEW_QUEUE_MERCHANTS = 'REVIEW_QUEUE_MERCHANTS'
export const REVIEW_QUEUE_SETTLEMENTS_MERCHANT = 'REVIEW_QUEUE_SETTLEMENTS_MERCHANT'
export const REVIEW_QUEUE_MERCHANTS_IDENTITY = 'REVIEW_QUEUE_MERCHANTS_IDENTITY'
export const REVIEW_QUEUE_IDENTITIES = 'REVIEW_QUEUE_IDENTITIES'
export const FEE_PROFILE = 'FEE_PROFILE'
export const FEE_PROFILE_RULES = 'FEE_PROFILE_RULES'
export const FEE_PROFILE_SETTINGS = 'FEE_PROFILE_SETTINGS'
export const TRANSFER = 'TRANSFER'
export const AUTHORIZATION = 'AUTHORIZATION'
export const SETTLEMENT = 'SETTLEMENT'
export const SETTLEMENT_TRANSFERS = 'SETTLEMENT_TRANSFERS'
export const SETTLEMENT_ENTRIES = 'SETTLEMENT_ENTRIES'
export const SETTLEMENT_FUNDING_TRANSFERS = 'SETTLEMENT_FUNDING_TRANSFERS'
export const SETTLEMENTS_MERCHANT = 'SETTLEMENTS_MERCHANT'
export const SETTLEMENT_V2 = 'SETTLEMENT_V2'
export const SETTLEMENT_GROUP = 'SETTLEMENT_GROUP'
export const SETTLEMENT_GROUP_SETTLEMENTS = 'SETTLEMENT_GROUP_SETTLEMENTS'
export const DISPUTE_EVIDENCES = 'DISPUTE_EVIDENCES'
export const SUBMIT_DISPUTE_EVIDENCE = 'SUBMIT_DISPUTE_EVIDENCE'
export const DISPUTE_ADJUSTMENT_TRANSFERS = 'DISPUTE_ADJUSTMENT_TRANSFERS'
export const USERS = 'USERS'
export const USER = 'USER'
export const ADMIN_USER = 'ADMIN_USER'
export const ROLE = 'ROLE'
export const ROLES = 'ROLES'
export const SELF_SERVICE_SIGNUP_FORM = 'SELF_SERVICE_SIGNUP_FORM'
export const SELF_SERVICE_EMAIL_VERIFICATION = 'SELF_SERVICE_EMAIL_VERIFICATION'
export const SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA = 'SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA'
export const SELF_SERVICE_RESEND_EMAIL = 'SELF_SERVICE_RESEND_EMAIL'
export const ADMIN_USER_MEMBERSHIPS = 'ADMIN_USER_MEMBERSHIPS'
export const ADMIN_USER_CREDENTIALS = 'ADMIN_USER_CREDENTIALS'
export const DASHBOARD_USER = 'DASHBOARD_USER'
export const DASHBOARD_USERS = 'DASHBOARD_USERS'
export const USERS_EMAIL = 'USERS_EMAIL'
export const ASSOCIATED_IDENTITIES = 'ASSOCIATED_IDENTITIES'
export const IDENTITY_AUTHORIZATIONS = 'IDENTITY_AUTHORIZATIONS'
export const IDENTITY_TRANSFERS = 'IDENTITY_TRANSFERS'
export const IDENTITY_DISPUTES = 'IDENTITY_DISPUTES'
export const IDENTITY_SETTLEMENTS = 'IDENTITY_SETTLEMENTS'
export const IDENTITY_PAYMENT_INSTRUMENTS = 'IDENTITY_PAYMENT_INSTRUMENTS'
export const IDENTITY_MERCHANTS = 'IDENTITY_MERCHANTS'
export const IDENTITY_VERIFICATIONS = 'IDENTITY_VERIFICATIONS'
export const IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION'
export const MERCHANT_VERIFICATIONS = 'MERCHANT_VERIFICATIONS'
export const MERCHANT_FEE_PROFILE = 'MERCHANT_FEE_PROFILE'
export const MERCHANT_PAYOUT_SETTINGS = 'MERCHANT_PAYOUT_SETTINGS'
export const APPLICATION_ASSOCIATED_IDENTITIES = 'APPLICATION_ASSOCIATED_IDENTITIES'
export const APPLICATION_DISPUTES = 'APPLICATION_DISPUTES'
export const APPLICATION_IDENTITIES = 'APPLICATION_IDENTITIES'
export const APPLICATION_MERCHANTS = 'APPLICATION_MERCHANTS'
export const APPLICATION_PROCESSORS = 'APPLICATION_PROCESSORS'
export const APPLICATION_PROCESSOR = 'APPLICATION_PROCESSOR'
export const APPLICATION_SETTLEMENTS = 'APPLICATION_SETTLEMENTS'
export const APPLICATION_TRANSFERS = 'APPLICATION_TRANSFERS'
export const APPLICATION_USERS = 'APPLICATION_USERS'
export const TRANSFER_REVERSALS = 'TRANSFER_REVERSALS'
export const TRANSFER_REVERSAL = 'TRANSFER_REVERSAL'
export const UNDERWRITING_REVIEW_RUNS = 'UNDERWRITING_REVIEW_RUNS'
export const TRANSFER_DISPUTES = 'TRANSFER_DISPUTES'
export const TRANSFERS_MERCHANT_IDENTITY = 'TRANSFERS_MERCHANT_IDENTITY'
export const TRANSFERS_PAYMENT_INSTRUMENT = 'TRANSFERS_PAYMENT_INSTRUMENT'
export const TRANSFER_REVERSALS_MERCHANT_IDENTITY = 'TRANSFER_REVERSALS_MERCHANT_IDENTITY'
export const TRANSFER_REVERSALS_APPLICATION = 'TRANSFER_REVERSALS_APPLICATION'
export const TRANSFER_DISPUTES_MERCHANT_IDENTITY = 'TRANSFER_DISPUTES_MERCHANT_IDENTITY'
export const TRANSFER_DISPUTES_APPLICATION = 'TRANSFER_DISPUTES_APPLICATION'
export const TRANSFER_APPLICATION = 'TRANSFER_APPLICATION'
export const SPLIT_TRANSFERS_MERCHANT_IDENTITY = 'SPLIT_TRANSFERS_MERCHANT_IDENTITY'
export const SPLIT_TRANSFERS_APPLICATION = 'SPLIT_TRANSFERS_APPLICATION'
export const SPLIT_TRANSFERS_MERCHANT = 'SPLIT_TRANSFERS_MERCHANT'
export const SETTINGS = 'SETTINGS'
export const SEARCH = 'SEARCH'
export const DISPUTE_TRANSFER = 'DISPUTE_TRANSFER'
export const DISPUTE_APPLICATION = 'DISPUTE_APPLICATION'
export const AUTHORIZATIONS_MERCHANT_IDENTITY = 'AUTHORIZATIONS_MERCHANT_IDENTITY'
export const AUTHORIZATIONS_PAYMENT_INSTRUMENT = 'AUTHORIZATIONS_PAYMENT_INSTRUMENT'
export const AUTHORIZATIONS_APPLICATION = 'AUTHORIZATIONS_APPLICATION'
export const IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT = 'IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT'
export const IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT = 'IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT'
export const IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING = 'IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING'
export const MERCHANT_IDENTITY = 'MERCHANT_IDENTITY'
export const MERCHANT_TRANSFERS = 'MERCHANT_TRANSFERS'
export const MERCHANT_TRANSFERS_APPLICATION = 'MERCHANT_TRANSFERS_APPLICATION'
export const MERCHANT_AUTHORIZATIONS = 'MERCHANT_AUTHORIZATIONS'
export const MERCHANT_STATEMENTS = 'MERCHANT_STATEMENTS'
export const MERCHANT_SETTLEMENTS = 'MERCHANT_SETTLEMENTS'
export const MERCHANT_FEES = 'MERCHANT_FEES'
export const MERCHANT_FILES = 'MERCHANT_FILES'
export const IDENTITY_FILES = 'IDENTITY_FILES'
export const MERCHANT_SUBSCRIPTION_ENROLLMENTS = 'MERCHANT_SUBSCRIPTION_ENROLLMENTS'
export const SETTLEMENTS_IDENTITY = 'SETTLEMENTS_IDENTITY'
export const APPLICATION_SETTLEMENTS_IDENTITY = 'APPLICATION_SETTLEMENTS_IDENTITY'
export const FUNDING_TRANSFERS_RELATED_TRANSFER = 'FUNDING_TRANSFERS_RELATED_TRANSFER'
export const SETTLEMENT_TRANSFERS_RELATED_TRANSFER = 'SETTLEMENT_TRANSFERS_RELATED_TRANSFER'
export const SETTLEMENT_ENTRIES_FEE = 'SETTLEMENT_ENTRIES_FEE'
export const SETTLEMENT_ENTRIES_TRANSFER = 'SETTLEMENT_ENTRIES_TRANSFER'
export const SETTLEMENT_ENTRIES_SPLIT_TRANSFERS = 'SETTLEMENT_ENTRIES_SPLIT_TRANSFERS'
export const ONBOARDING_FORMS_APPLICATION = 'ONBOARDING_FORMS_APPLICATION'
export const ONBOARDING_FORMS_ONBOARDING_FORM_DATA = 'ONBOARDING_FORMS_ONBOARDING_FORM_DATA'
export const SETTLEMENT_TRANSFER_FEES = 'SETTLEMENT_TRANSFER_FEES'
export const TRANSFER_FEES_SETTLEMENT_ENTRIES = 'TRANSFER_FEES_SETTLEMENT_ENTRIES'
export const SETTLEMENT_TRANSFER_FEE_COUNTS = 'SETTLEMENT_TRANSFER_FEE_COUNTS'
export const FEES = 'FEES'
export const FEE = 'FEE'
export const SUBSCRIPTION_SCHEDULES = 'SUBSCRIPTION_SCHEDULES'
export const SUBSCRIPTION_SCHEDULES_AMOUNTS = 'SUBSCRIPTION_SCHEDULES_AMOUNTS'
export const SUBSCRIPTION_SCHEDULE = 'SUBSCRIPTION_SCHEDULE'
export const SUBSCRIPTION_SCHEDULE_AMOUNTS = 'SUBSCRIPTION_SCHEDULE_AMOUNTS'
export const SUBSCRIPTION_SCHEDULE_FEES = 'SUBSCRIPTION_SCHEDULE_FEES'
export const SUBSCRIPTION_SCHEDULE_AMOUNT = 'SUBSCRIPTION_SCHEDULE_AMOUNT'
export const SUBSCRIPTION_ENROLLMENT = 'SUBSCRIPTION_ENROLLMENT'
export const SUBSCRIPTION_ENROLLMENTS = 'SUBSCRIPTION_ENROLLMENTS'
export const SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS = 'SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS'
export const SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT = 'SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT'
export const SUBSCRIPTION_SCHEDULE_ENROLLMENTS = 'SUBSCRIPTION_SCHEDULE_ENROLLMENTS'
export const SUBSCRIPTION_SCHEDULE_ENROLLMENT = 'SUBSCRIPTION_SCHEDULE_ENROLLMENT'
export const PAYOUT_PLAN = 'PAYOUT_PLAN'
export const PAYOUT_SETTINGS = 'PAYOUT_SETTINGS'
export const ONBOARDING_FORM = 'ONBOARDING_FORM'
export const ONBOARDING_FORM_LINK = 'ONBOARDING_FORM_LINK'
export const ONBOARDING_FORM_DATA = 'ONBOARDING_FORM_DATA'
export const GUEST_ONBOARDING_FORM_DATA = 'GUEST_ONBOARDING_FORM_DATA'
export const GUEST_ONBOARDING_FORM_FILES = 'GUEST_ONBOARDING_FORM_FILES'
export const GUEST_ACCESS_FORM = 'GUEST_ACCESS_FORM'
export const GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE = 'GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE'
export const ONBOARDING_FORMS = 'ONBOARDING_FORMS'
export const HOSTED_FORM_TOKEN_VERIFICATION = 'HOSTED_FORM_TOKEN_VERIFICATION'
export const PAYOUT_PLANS = 'PAYOUT_PLANS'
export const FEES_MERCHANT = 'FEES_MERCHANT'
export const RISK_PROFILE = 'RISK_PROFILE'
export const MERCHANT_RISK_PROFILE = 'MERCHANT_RISK_PROFILE'
export const TRANSFERS_UPDATE = 'TRANSFERS_UPDATE'
export const VERIFICATIONS_UPDATE = 'VERIFICATIONS_UPDATE'
export const DISPUTES_WIDGET = 'DISPUTES_WIDGET'
export const TRANSFERS_WIDGET = 'TRANSFERS_WIDGET'
export const MASS_SETTLEMENT_APPROVAL = 'MASS_SETTLEMENT_APPROVAL'
export const AUTHORIZATIONS_WIDGET = 'AUTHORIZATIONS_WIDGET'
export const REVIEW_QUEUE_WIDGET = 'REVIEW_QUEUE_WIDGET'
export const IDENTITIES_WIDGET = 'IDENTITIES_WIDGET'
export const MERCHANTS_WIDGET = 'MERCHANTS_WIDGET'
export const PAYMENT_INSTRUMENTS_WIDGET = 'PAYMENT_INSTRUMENTS_WIDGET'
export const WELCOME_EMAIL_TEMPLATE = 'WELCOME_EMAIL_TEMPLATE'
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE'
export const DASHBOARD_FILE = 'DASHBOARD_FILE'
export const PLATFORMS = 'PLATFORMS'
export const PLATFORM = 'PLATFORM'
export const PLATFORM_PROCESSOR_CONFIG = 'PLATFORM_PROCESSOR_CONFIG'
export const PLATFORM_PROCESSOR_CONFIGS = 'PLATFORM_PROCESSOR_CONFIGS'
export const PLATFORM_USERS = 'PLATFORM_USERS'
export const PLATFORM_USER = 'PLATFORM_USER'
export const USER_PERMISSIONS = 'USER_PERMISSIONS'
export const IDENTITY_UPLOAD = 'IDENTITY_UPLOAD'
export const CREDENTIAL_PERMISSIONS = 'CREDENTIAL_PERMISSIONS'
export const CLOSE_SETTLEMENTS = 'CLOSE_SETTLEMENTS'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const LATEST_NOTIFICATIONS = 'LATEST_NOTIFICATIONS'
export const CURRENT_USER = 'CURRENT_USER'
export const DASHBOARD_CUSTOMIZATION = 'DASHBOARD_CUSTOMIZATION'
export const DASHBOARD_CUSTOMIZATIONS = 'DASHBOARD_CUSTOMIZATIONS'
export const GUEST_DASHBOARD_CUSTOMIZATIONS = 'GUEST_DASHBOARD_CUSTOMIZATIONS'
export const APPLE_PAY_MERCHANT = 'APPLE_PAY_MERCHANT'
export const PAYMENT_METHOD_CONFIGURATIONS = 'PAYMENT_METHOD_CONFIGURATIONS'
export const PAYMENT_METHOD_CONFIGURATION = 'PAYMENT_METHOD_CONFIGURATION'
export const RESPONSE_FILES = 'RESPONSE_FILES'
export const FILE = 'FILE'
export const ACCESS_FORM_FILE = 'ACCESS_FORM_FILE'
export const FILES = 'FILES'
export const CERTIFICATE_SIGNING_REQUESTS = 'CERTIFICATE_SIGNING_REQUESTS'
export const CERTIFICATE_SIGNING_REQUEST = 'CERTIFICATE_SIGNING_REQUEST'
export const MERCHANT_FILE = 'MERCHANT_FILE'
export const IDENTITY_FILE = 'IDENTITY_FILE'
export const DASHBOARD_FEEDBACK_EMAIL = 'DASHBOARD_FEEDBACK_EMAIL'
export const CONTACT_SUPPORT_EMAIL = 'CONTACT_SUPPORT_EMAIL'
export const CONTACT_SALES_EMAIL = 'CONTACT_SALES_EMAIL'
export const CHART = 'CHART'
export const CHARTS = 'CHARTS'
export const CHART_DELTA = 'CHART_DELTA'
export const HOME_CHARTS = 'HOME_CHARTS'
export const TRANSACTION_CHARTS = 'TRANSACTION_CHARTS'
export const EXCEPTION_CHARTS = 'EXCEPTION_CHARTS'
export const UNDERWRITING_CHARTS = 'UNDERWRITING_CHARTS'
export const APPLICATION_UNDERWRITING_SETTINGS = 'APPLICATION_UNDERWRITING_SETTINGS'
export const MERCHANT_CHARTS = 'MERCHANT_CHARTS'
export const EXPORTS = 'EXPORTS'
export const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS'
export const ROLE_MEMBERS = 'ROLE_MEMBERS'
export const NOTIFICATION_ACKNOWLEDGMENT = 'NOTIFICATION_ACKNOWLEDGMENT'
export const API_LOG = 'API_LOG'
export const API_LOGS = 'API_LOGS'
export const DEVICE = 'DEVICE'
export const DEVICES = 'DEVICES'
export const UNMASKED_ITEM = 'UNMASKED_ITEM'
export const UNMASKED_ITEMS = 'UNMASKED_ITEMS'
export const SETTLEMENTS_APPLICATIONS = 'SETTLEMENTS_APPLICATIONS'
export const CUSTOMER_ONBOARDING_FORM = 'CUSTOMER_ONBOARDING_FORM'
export const CUSTOMER_ONBOARDING_FORMS = 'CUSTOMER_ONBOARDING_FORMS'
export const CUSTOMER_ONBOARDING_FORM_LINKS = 'CUSTOMER_ONBOARDING_FORM_LINKS'
export const CUSTOMER_ONBOARDING_FORM_ACCESS_FORM = 'CUSTOMER_ONBOARDING_FORM_ACCESS_FORM'
export const WEBHOOKS = 'WEBHOOKS'
export const WEBHOOK = 'WEBHOOK'
export const WEBHOOK_LOGS = 'WEBHOOK_LOGS'
export const WEBHOOK_LOG = 'WEBHOOK_LOG'
export const WEBHOOKS_WITH_STOPPED_EVENTS = 'WEBHOOKS_WITH_STOPPED_EVENTS'
export const RELATED_APPLICATION = 'RELATED_APPLICATION'
export const RELATED_IDENTITY = 'RELATED_IDENTITY'
export const RELATED_PAYMENT_INSTRUMENT = 'RELATED_PAYMENT_INSTRUMENT'
export const MERCHANT_APPLICATION = 'MERCHANT_APPLICATION'
export const MERCHANT_VERIFICATION = 'MERCHANT_VERIFICATION'
export const DASHBOARD_PRESIGNED_URL = 'DASHBOARD_PRESIGNED-URL'
export const DASHBOARD_V2_EMAILS = 'DASHBOARD_V2_EMAILS'
export const MEMBER = 'MEMBER'
export const MEMBERS = 'MEMBERS'
export const MEMBERSHIP = 'MEMBERSHIP'
export const ROLE_APPLICATION = 'ROLE_APPLICATION'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const MEMBER_ROLE = 'MEMBER_ROLE'
export const ACCESS_FORM = 'ACCESS_FORM'
export const ACCESS_FORM_CONTRACT = 'ACCESS_FORM_CONTRACT'
export const ACCESS_FORM_ADDITIONAL_DOCUMENTS = 'ACCESS_FORM_ADDITIONAL_DOCUMENTS'
export const ONBOARDING_FORM_BANK_DOCUMENT = 'ONBOARDING_FORM_BANK_DOCUMENT'
export const ONBOARDING_FORM_DOCUMENTS = 'ONBOARDING_FORM_DOCUMENTS'
export const SUBMIT_ACCESS_FORM = 'SUBMIT_ACCESS_FORM'
export const SUBMIT_GUEST_ACCESS_FORM = 'SUBMIT_GUEST_ACCESS_FORM'
export const ACCESS_FORMS = 'ACCESS_FORMS'
export const SUBMIT_ACCESS_FORM_SALES_REVIEW = 'SUBMIT_ACCESS_FORM_SALES_REVIEW'
export const UNDERWRITING_REVIEW = 'UNDERWRITING_REVIEW'
export const UNDERWRITING_REVIEWS = 'UNDERWRITING_REVIEWS'
export const UNDERWRITING_PROFILE = 'UNDERWRITING_PROFILE'
export const UNDERWRITING_RUN_SILENCE = 'UNDERWRITING_RUN_SILENCE'
export const UNDERWRITING_PROFILES = 'UNDERWRITING_PROFILES'
export const APPLICATION_UNDERWRITING_PROFILE = 'APPLICATION_UNDERWRITING_PROFILE'
export const PLATFORM_UNDERWRITING_PROFILE = 'PLATFORM_UNDERWRITING_PROFILE'
export const DASHBOARD_CONTRACTS = 'DASHBOARD_CONTRACTS'
export const MERCHANT_UNDERWRITING_REVIEW = 'MERCHANT_UNDERWRITING_REVIEW'
export const MERCHANT_UNDERWRITING_REVIEWS = 'MERCHANT_UNDERWRITING_REVIEWS'
export const MERCHANT_UNDERWRITING_REVIEW_IDENTITIES = 'MERCHANT_UNDERWRITING_REVIEW_IDENTITIES'
export const RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES = 'RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES'
export const API_KEY = 'API_KEY'
export const ACCEPT_DISPUTE = 'ACCEPT_DISPUTE'
export const UNDERWRITING_RUN_REPORT = 'UNDERWRITING_RUN_REPORT'
export const UNDERWRITING_REPORT = 'UNDERWRITING_REPORT'
export const DISPUTE_EVIDENCE_DOWNLOAD = 'DISPUTE_EVIDENCE_DOWNLOAD'
export const DASHBOARD_USER_MFA_TOKEN = 'DASHBOARD_USER_MFA_TOKEN'
export const DASHBOARD_USER_MFA_ASSOCIATE = 'DASHBOARD_USER_MFA_ASSOCIATE'
export const DASHBOARD_USER_MFA_VERIFY = 'DASHBOARD_USER_MFA_VERIFY'
export const DASHBOARD_USER_MFA_AUTHENTICATORS = 'DASHBOARD_USER_MFA_AUTHENTICATORS'
export const DASHBOARD_USER_MFA_RESET = 'DASHBOARD_USER_MFA_RESET'
export const ENTITY_CONFIGURATION = 'ENTITY_CONFIGURATION'
export const ENTITY_CONFIGURATIONS = 'ENTITY_CONFIGURATIONS'
export const SPLIT_TRANSFERS = 'SPLIT_TRANSFERS'
export const SPLIT_TRANSFER = 'SPLIT_TRANSFER'
export const RECIPIENT_UNDERWRITING_REVIEWS = 'RECIPIENT_UNDERWRITING_REVIEWS'
export const UNDERWRITING_SUBJECT_EVENTS = 'UNDERWRITING_SUBJECT_EVENTS'
export const UNDERWRITING_SUBJECT_EVENT = 'UNDERWRITING_SUBJECT_EVENT'
export const APPLICATION_BALANCES = 'APPLICATION_BALANCES'
export const CURRENT_USAGES = 'CURRENT_USAGES'
export const RECIPIENT_IDENTITIES = 'RECIPIENT_IDENTITIES'
export const RECIPIENT_IDENTITY_MERCHANTS = 'RECIPIENT_IDENTITY_MERCHANTS'
export const DISBURSEMENT_RULES = 'DISBURSEMENT_RULES'
export const BALANCE_ENTRIES = 'BALANCE_ENTRIES'
export const BALANCE_ENTRIES_TRANSFER = 'BALANCE_ENTRIES_TRANSFER'
export const BALANCE_ENTRIES_APPLICATION = 'BALANCE_ENTRIES_APPLICATION'
export const PAYMENT_LINK = 'PAYMENT_LINK'
export const PAYMENT_LINKS = 'PAYMENT_LINKS'
export const PAYMENT_LINKS_MERCHANT = 'PAYMENT_LINKS_MERCHANT'
export const PAYMENT_LINKS_APPLICATION = 'PAYMENT_LINKS_APPLICATION'
export const PURCHASE = 'PURCHASE'
export const PURCHASES = 'PURCHASES'
export const GUEST_CHECKOUT_TOKEN_VERIFICATION = 'GUEST_CHECKOUT_TOKEN_VERIFICATION'
export const GUEST_CHECKOUT_PAYMENT_LINK = 'GUEST_CHECKOUT_PAYMENT_LINK'
export const GUEST_CHECKOUT_FORM = 'GUEST_CHECKOUT_FORM'
export const SHOW_SUCCESS_FLASH_NOTIFICATION = 'SHOW_SUCCESS_FLASH_NOTIFICATION'
export const SHOW_ERROR_FLASH_NOTIFICATION = 'SHOW_ERROR_FLASH_NOTIFICATION'
export const SHOW_API_ERROR_FLASH_NOTIFICATION = 'SHOW_API_ERROR_FLASH_NOTIFICATION'
export const PURCHASES_TRANSFER = 'PURCHASES_TRANSFER'
export const PURCHASE_APPLICATION = 'PURCHASE_APPLICATION'
export const PURCHASE_MERCHANT = 'PURCHASE_MERCHANT'
export const PURCHASE_MERCHANT_IDENTITY = 'PURCHASE_MERCHANT_IDENTITY'
export const PURCHASE_TRANSFER_PAYMENT_INSTRUMENT = 'PURCHASE_TRANSFER_PAYMENT_INSTRUMENT'
export const NOTES = 'NOTES'
export const NOTE = 'NOTE'
export const DRAFT_NOTES = 'DRAFT_NOTES'
export const TRANSFER_MERCHANT = 'TRANSFER_MERCHANT'
export const DEVICE_MERCHANT = 'DEVICE_MERCHANT'
export const UNDERWRITING_WORKFLOW = 'UNDERWRITING_WORKFLOW'
export const PLAID_TOKEN = 'PLAID_TOKEN'
export const PLAID_PUBLIC_TOKEN = 'PLAID_PUBLIC_TOKEN'
export const PLAID_TOKEN_METADATA = 'PLAID_TOKEN_METADATA'
export const APPLICATION_PROCESSOR_CONFIGURATION = 'APPLICATION_PROCESSOR_CONFIGURATION'
export const APPLICATION_PROCESSOR_CONFIGURATIONS = 'APPLICATION_PROCESSOR_CONFIGURATIONS'
export const PAYOUT_PROFILE = 'PAYOUT_PROFILE'
export const AUDIT_LOG_EVENTS = 'AUDIT_LOG_EVENTS'
export const AUDIT_LOG_EVENT = 'AUDIT_LOG_EVENT'
export const FRAUD_SCORE = 'FRAUD_SCORE'
export const FRAUD_SCORES = 'FRAUD_SCORES'
export const SUBMIT_SALES_REVIEW_ACCESS_FORM = 'SUBMIT_SALES_REVIEW_ACCESS_FORM'
export const REQUEST_CHANGES = 'REQUEST_CHANGES'
export const SUBMIT_TO_UNDERWRITING = 'SUBMIT_TO_UNDERWRITING'
export const GUEST_CHECKOUT_PAYOUT_LINK = 'GUEST_CHECKOUT_PAYOUT_LINK'
export const PAYOUT_LINK = 'PAYOUT_LINK'
export const TRANSFER_ATTEMPTS = 'TRANSFER_ATTEMPTS'
export const PAYOUT_LINKS = 'PAYOUT_LINKS'
export const TRANSFER_ATTEMPT = 'TRANSFER_ATTEMPT'
export const GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS = 'GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS'
export const SPLIT_TRANSFER_SETTLEMENT = 'SPLIT_TRANSFER_SETTLEMENT'
export const UPDATE_DISPUTE = 'UPDATE_DISPUTE'
export const BALANCE_ADJUSTMENTS = 'BALANCE_ADJUSTMENTS'
export const BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT = 'BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT'
export const BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT'
export const BALANCE_ADJUSTMENT_BALANCE_ENTRY = 'BALANCE_ADJUSTMENT_BALANCE_ENTRY'
export const BALANCE_ENTRY_PARENT_BALANCE_ENTRY = 'BALANCE_ENTRY_PARENT_BALANCE_ENTRY'
export const RECEIPT = 'RECEIPT'
export const RECEIPTS = 'RECEIPTS'
export const ENTITY_RECEIPTS = 'ENTITY_RECEIPTS'
export const RECEIPT_DELIVERY_ATTEMPTS = 'RECEIPT_DELIVERY_ATTEMPTS'
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
export const SUBSCRIPTIONS_IDENTITY = 'SUBSCRIPTIONS_IDENTITY'
export const SUBSCRIPTIONS_MERCHANT = 'SUBSCRIPTIONS_MERCHANT'
export const SUBSCRIPTIONS_PAYMENT_INSTRUMENT = 'SUBSCRIPTIONS_PAYMENT_INSTRUMENT'
export const SUBSCRIPTIONS_PLAN = 'SUBSCRIPTIONS_PLAN'
export const SUBSCRIPTION = 'SUBSCRIPTION'
export const SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS'
export const SUBSCRIPTION_PLANS_MERCHANT = 'SUBSCRIPTION_PLANS_MERCHANT'
export const SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN'
export const SUBSCRIPTION_PLAN_MERCHANT_IDENTITY = 'SUBSCRIPTION_PLAN_MERCHANT_IDENTITY'
export const SUBSCRIPTION_PLAN_MERCHANT_APPLICATION = 'SUBSCRIPTION_PLAN_MERCHANT_APPLICATION'
export const TRANSFER_ATTEMPT_PAYOUT_LINK = 'TRANSFER_ATTEMPT_PAYOUT_LINK'
export const MONITORING_ALERTS = 'MONITORING_ALERTS'
export const MONITORING_ALERT = 'MONITORING_ALERT'
export const FUNDING_TRANSFER_PAYMENT_INSTRUMENTS = 'FUNDING_TRANSFER_PAYMENT_INSTRUMENTS'
export const INSTANT_PAYOUTS = 'INSTANT_PAYOUTS'
export const INSTANT_PAYOUTS_MERCHANT = 'INSTANT_PAYOUTS_MERCHANT'
export const INSTANT_PAYOUTS_PAYMENT_INSTRUMENT = 'INSTANT_PAYOUTS_PAYMENT_INSTRUMENT'
export const INSTANT_PAYOUT = 'INSTANT_PAYOUT'

// GET CONSTANTS
export const GET_SESSION = GET(SESSION)
export const GET_APP_CONFIGURATION = GET(APP_CONFIGURATION)
export const GET_CONFIGURATION = GET(CONFIGURATION)
export const GET_CONFIGURATIONS = GET(CONFIGURATIONS)
export const GET_CREDENTIAL = GET(CREDENTIAL)
export const GET_CREDENTIALS = GET(CREDENTIALS)
export const GET_RECENTLY_ACTIVE_CREDENTIALS = GET(RECENTLY_ACTIVE_CREDENTIALS)
export const GET_TRANSFERS_SEARCH = GET(TRANSFERS_SEARCH)
export const GET_TRANSFERS = GET(TRANSFERS)
export const GET_BALANCE_TRANSFER = GET(BALANCE_TRANSFER)
export const GET_BALANCE_TRANSFERS = GET(BALANCE_TRANSFERS)
export const GET_AUTHORIZATIONS = GET(AUTHORIZATIONS)
export const GET_AUTHORIZATIONS_SEARCH = GET(AUTHORIZATIONS_SEARCH)
export const GET_SETTLEMENTS = GET(SETTLEMENTS)
export const GET_DISPUTE = GET(DISPUTE)
export const GET_DISPUTES = GET(DISPUTES)
export const GET_REPORTS = GET(REPORTS)
export const GET_CATEGORIZED_REPORTS = GET(CATEGORIZED_REPORTS)
export const GET_IDENTITIES = GET(IDENTITIES)
export const GET_IDENTITY = GET(IDENTITY)
export const GET_IDENTITY_UNMASK = GET(IDENTITY_UNMASK)
export const GET_MERCHANT = GET(MERCHANT)
export const GET_MERCHANTS = GET(MERCHANTS)
export const GET_MERCHANT_COMPLIANCE_FORMS = GET(MERCHANT_COMPLIANCE_FORMS)
export const GET_COMPLIANCE_FORMS = GET(COMPLIANCE_FORMS)
export const GET_COMPLIANCE_FORM_TEMPLATE_FORMS = GET(COMPLIANCE_FORM_TEMPLATE_FORMS)
export const GET_COMPLIANCE_FORM = GET(COMPLIANCE_FORM)
export const GET_COMPLIANCE_FORM_APPLICATION = GET(COMPLIANCE_FORM_APPLICATION)
export const GET_COMPLIANCE_FORMS_APPLICATION = GET(COMPLIANCE_FORMS_APPLICATION)
export const GET_COMPLIANCE_FORM_TEMPLATE_APPLICATION = GET(COMPLIANCE_FORM_TEMPLATE_APPLICATION)
export const GET_COMPLIANCE_SETTINGS = GET(COMPLIANCE_SETTINGS)
export const GET_COMPLIANCE_FORM_TEMPLATES = GET(COMPLIANCE_FORM_TEMPLATES)
export const GET_COMPLIANCE_FORM_TEMPLATE = GET(COMPLIANCE_FORM_TEMPLATE)
export const GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE = GET(ACTIVE_COMPLIANCE_FORM_TEMPLATE)
export const GET_APPLICATION = GET(APPLICATION)
export const GET_APPLICATIONS = GET(APPLICATIONS)
export const GET_CUSTOMER_ONBOARDING_FORMS = GET(CUSTOMER_ONBOARDING_FORMS)
export const GET_CUSTOMER_ONBOARDING_FORM = GET(CUSTOMER_ONBOARDING_FORM)
export const GET_APPLICATIONS_IDENTITY = GET(APPLICATIONS_IDENTITY)
export const GET_PAYMENT_INSTRUMENT = GET(PAYMENT_INSTRUMENT)
export const GET_PAYMENT_INSTRUMENT_UNMASK = GET(PAYMENT_INSTRUMENT_UNMASK)
export const GET_PAYMENT_INSTRUMENTS = GET(PAYMENT_INSTRUMENTS)
export const GET_PAYMENT_INSTRUMENT_TRANSFERS = GET(PAYMENT_INSTRUMENT_TRANSFERS)
export const GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS = GET(PAYMENT_INSTRUMENT_AUTHORIZATIONS)
export const GET_PAYMENT_INSTRUMENT_VERIFICATIONS = GET(PAYMENT_INSTRUMENT_VERIFICATIONS)
export const GET_REVIEW_QUEUE = GET(REVIEW_QUEUE)
export const GET_REVIEW_RUNS = GET(REVIEW_RUNS)
export const GET_REVIEW_QUEUE_ITEM = GET(REVIEW_QUEUE_ITEM)
export const GET_ALL_REVIEW_QUEUE_ITEMS = GET(ALL_REVIEW_QUEUE_ITEMS)
export const GET_ALL_REVIEW_QUEUE_ITEMS_BATCH = GET(ALL_REVIEW_QUEUE_ITEMS_BATCH)
export const GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL = GET(ALL_REVIEW_QUEUE_ITEMS_CANCEL)
export const GET_REVIEW_QUEUE_SETTLEMENTS = GET(REVIEW_QUEUE_SETTLEMENTS)
export const GET_REVIEW_QUEUE_APPLICATION = GET(REVIEW_QUEUE_APPLICATION)
export const GET_REVIEW_QUEUE_MERCHANTS = GET(REVIEW_QUEUE_MERCHANTS)
export const GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT = GET(REVIEW_QUEUE_SETTLEMENTS_MERCHANT)
export const GET_REVIEW_QUEUE_MERCHANTS_IDENTITY = GET(REVIEW_QUEUE_MERCHANTS_IDENTITY)
export const GET_REVIEW_QUEUE_IDENTITIES = GET(REVIEW_QUEUE_IDENTITIES)
export const GET_TRANSFER = GET(TRANSFER)
export const GET_AUTHORIZATION = GET(AUTHORIZATION)
export const GET_SETTLEMENT = GET(SETTLEMENT)
export const GET_SETTLEMENT_TRANSFERS = GET(SETTLEMENT_TRANSFERS)
export const GET_SETTLEMENT_FUNDING_TRANSFERS = GET(SETTLEMENT_FUNDING_TRANSFERS)
export const GET_SETTLEMENT_ENTRIES = GET(SETTLEMENT_ENTRIES)
export const GET_SETTLEMENT_GROUP = GET(SETTLEMENT_GROUP)
export const GET_SETTLEMENT_GROUP_SETTLEMENTS = GET(SETTLEMENT_GROUP_SETTLEMENTS)
export const GET_FEES = GET(FEES)
export const GET_FEE = GET(FEE)
export const GET_SUBSCRIPTION_SCHEDULES = GET(SUBSCRIPTION_SCHEDULES)
export const GET_SUBSCRIPTION_SCHEDULES_AMOUNTS = GET(SUBSCRIPTION_SCHEDULES_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE = GET(SUBSCRIPTION_SCHEDULE)
export const GET_SUBSCRIPTION_SCHEDULE_AMOUNTS = GET(SUBSCRIPTION_SCHEDULE_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE_FEES = GET(SUBSCRIPTION_SCHEDULE_FEES)
export const GET_SUBSCRIPTION_ENROLLMENT = GET(SUBSCRIPTION_ENROLLMENT)
export const GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS = GET(SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT = GET(SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT)
export const GET_SUBSCRIPTION_ENROLLMENTS = GET(SUBSCRIPTION_ENROLLMENTS)
export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS = GET(SUBSCRIPTION_SCHEDULE_ENROLLMENTS)
export const GET_PAYOUT_PLAN = GET(PAYOUT_PLAN)
export const GET_PAYOUT_PLANS = GET(PAYOUT_PLANS)
export const GET_DISPUTE_EVIDENCES = GET(DISPUTE_EVIDENCES)
export const GET_DISPUTE_ADJUSTMENT_TRANSFERS = GET(DISPUTE_ADJUSTMENT_TRANSFERS)
export const GET_USERS = GET(USERS)
export const GET_ROLE = GET(ROLE)
export const GET_ROLES = GET(ROLES)
export const GET_ADMIN_USER = GET(ADMIN_USER)
export const GET_ADMIN_USER_MEMBERSHIPS = GET(ADMIN_USER_MEMBERSHIPS)
export const GET_ADMIN_USER_CREDENTIALS = GET(ADMIN_USER_CREDENTIALS)
export const GET_DASHBOARD_USERS = GET(DASHBOARD_USERS)
export const GET_ASSOCIATED_IDENTITIES = GET(ASSOCIATED_IDENTITIES)
export const GET_IDENTITY_AUTHORIZATIONS = GET(IDENTITY_AUTHORIZATIONS)
export const GET_IDENTITY_TRANSFERS = GET(IDENTITY_TRANSFERS)
export const GET_IDENTITY_DISPUTES = GET(IDENTITY_DISPUTES)
export const GET_IDENTITY_SETTLEMENTS = GET(IDENTITY_SETTLEMENTS)
export const GET_IDENTITY_PAYMENT_INSTRUMENTS = GET(IDENTITY_PAYMENT_INSTRUMENTS)
export const GET_IDENTITY_MERCHANTS = GET(IDENTITY_MERCHANTS)
export const GET_IDENTITY_VERIFICATIONS = GET(IDENTITY_VERIFICATIONS)
export const GET_IDENTITY_VERIFICATION = GET(IDENTITY_VERIFICATION)
export const GET_MERCHANT_VERIFICATIONS = GET(MERCHANT_VERIFICATIONS)
export const GET_MERCHANT_PAYOUT_SETTINGS = GET(MERCHANT_PAYOUT_SETTINGS)
export const GET_PAYOUT_SETTINGS = GET(PAYOUT_SETTINGS)
export const GET_MERCHANT_TRANSFERS = GET(MERCHANT_TRANSFERS)
export const GET_MERCHANT_AUTHORIZATIONS = GET(MERCHANT_AUTHORIZATIONS)
export const GET_MERCHANT_STATEMENTS = GET(MERCHANT_STATEMENTS)
export const GET_MERCHANT_SETTLEMENTS = GET(MERCHANT_SETTLEMENTS)
export const GET_MERCHANT_FEES = GET(MERCHANT_FEES)
export const GET_MERCHANT_FILES = GET(MERCHANT_FILES)
export const GET_IDENTITY_FILES = GET(IDENTITY_FILES)
export const GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS = GET(MERCHANT_SUBSCRIPTION_ENROLLMENTS)
export const GET_APPLICATION_ASSOCIATED_IDENTITIES = GET(APPLICATION_ASSOCIATED_IDENTITIES)
export const GET_APPLICATION_DISPUTES = GET(APPLICATION_DISPUTES)
export const GET_APPLICATION_IDENTITIES = GET(APPLICATION_IDENTITIES)
export const GET_APPLICATION_MERCHANTS = GET(APPLICATION_MERCHANTS)
export const GET_APPLICATION_PROCESSORS = GET(APPLICATION_PROCESSORS)
export const GET_APPLICATION_PROCESSOR = GET(APPLICATION_PROCESSOR)
export const GET_APPLICATION_SETTLEMENTS = GET(APPLICATION_SETTLEMENTS)
export const GET_APPLICATION_TRANSFERS = GET(APPLICATION_TRANSFERS)
export const GET_APPLICATION_USERS = GET(APPLICATION_USERS)
export const GET_DASHBOARD_USER = GET(DASHBOARD_USER)
export const GET_SETTINGS = GET(SETTINGS)
export const GET_SEARCH = GET(SEARCH)
export const GET_DISPUTE_TRANSFER = GET(DISPUTE_TRANSFER)
export const GET_DISPUTE_APPLICATION = GET(DISPUTE_APPLICATION)
export const GET_TRANSFERS_MERCHANT_IDENTITY = GET(TRANSFERS_MERCHANT_IDENTITY)
export const GET_TRANSFERS_PAYMENT_INSTRUMENT = GET(TRANSFERS_PAYMENT_INSTRUMENT)
export const GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY = GET(SPLIT_TRANSFERS_MERCHANT_IDENTITY)
export const GET_SPLIT_TRANSFERS_APPLICATION = GET(SPLIT_TRANSFERS_APPLICATION)
export const GET_SPLIT_TRANSFERS_MERCHANT = GET(SPLIT_TRANSFERS_MERCHANT)
export const GET_AUTHORIZATIONS_MERCHANT_IDENTITY = GET(AUTHORIZATIONS_MERCHANT_IDENTITY)
export const GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT = GET(AUTHORIZATIONS_PAYMENT_INSTRUMENT)
export const GET_AUTHORIZATIONS_APPLICATION = GET(AUTHORIZATIONS_APPLICATION)
export const GET_TRANSFER_APPLICATION = GET(TRANSFER_APPLICATION)
export const GET_SETTLEMENTS_MERCHANT = GET(SETTLEMENTS_MERCHANT)
export const GET_IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT = GET(IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT)
export const GET_IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT = GET(IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT)
export const GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING = GET(IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const GET_MERCHANT_IDENTITY = GET(MERCHANT_IDENTITY)
export const GET_SETTLEMENTS_IDENTITY = GET(SETTLEMENTS_IDENTITY)
export const GET_APPLICATION_SETTLEMENTS_IDENTITY = GET(APPLICATION_SETTLEMENTS_IDENTITY)
export const GET_FUNDING_TRANSFERS_RELATED_TRANSFER = GET(FUNDING_TRANSFERS_RELATED_TRANSFER)
export const GET_SETTLEMENT_TRANSFERS_RELATED_TRANSFER = GET(SETTLEMENT_TRANSFERS_RELATED_TRANSFER)
export const GET_SETTLEMENT_ENTRIES_FEE = GET(SETTLEMENT_ENTRIES_FEE)
export const GET_SETTLEMENT_ENTRIES_TRANSFER = GET(SETTLEMENT_ENTRIES_TRANSFER)
export const GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS = GET(SETTLEMENT_ENTRIES_SPLIT_TRANSFERS)
export const GET_ONBOARDING_FORMS_APPLICATION = GET(ONBOARDING_FORMS_APPLICATION)
export const GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA = GET(ONBOARDING_FORMS_ONBOARDING_FORM_DATA)
export const GET_SETTLEMENT_TRANSFER_FEES = GET(SETTLEMENT_TRANSFER_FEES)
export const GET_TRANSFER_FEES_SETTLEMENT_ENTRIES = GET(TRANSFER_FEES_SETTLEMENT_ENTRIES)
export const GET_SETTLEMENT_TRANSFER_FEE_COUNTS = GET(SETTLEMENT_TRANSFER_FEE_COUNTS)
export const GET_DISPUTES_WIDGET = GET(DISPUTES_WIDGET)
export const GET_TRANSFERS_WIDGET = GET(TRANSFERS_WIDGET)
export const GET_AUTHORIZATIONS_WIDGET = GET(AUTHORIZATIONS_WIDGET)
export const GET_REVIEW_QUEUE_WIDGET = GET(REVIEW_QUEUE_WIDGET)
export const GET_IDENTITIES_WIDGET = GET(IDENTITIES_WIDGET)
export const GET_MERCHANTS_WIDGET = GET(MERCHANTS_WIDGET)
export const GET_PAYMENT_INSTRUMENTS_WIDGET = GET(PAYMENT_INSTRUMENTS_WIDGET)
export const GET_WELCOME_EMAIL_TEMPLATE = GET(WELCOME_EMAIL_TEMPLATE)
export const GET_EMAIL_TEMPLATE = GET(EMAIL_TEMPLATE)
export const GET_PLATFORMS = GET(PLATFORMS)
export const GET_PLATFORM = GET(PLATFORM)
export const GET_PLATFORM_PROCESSOR_CONFIG = GET(PLATFORM_PROCESSOR_CONFIG)
export const GET_PLATFORM_PROCESSOR_CONFIGS = GET(PLATFORM_PROCESSOR_CONFIGS)
export const GET_PLATFORM_USERS = GET(PLATFORM_USERS)
export const GET_PLATFORM_USER = GET(PLATFORM_USER)
export const GET_USER_PERMISSIONS = GET(USER_PERMISSIONS)
export const GET_PAYMENT_INSTRUMENT_APPLICATION = GET(PAYMENT_INSTRUMENT_APPLICATION)
export const GET_PAYMENT_INSTRUMENT_IDENTITY = GET(PAYMENT_INSTRUMENT_IDENTITY)
export const GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY = GET(TRANSFER_PAYMENT_INSTRUMENT_IDENTITY)
export const GET_FEES_MERCHANT = GET(FEES_MERCHANT)
export const GET_CREDENTIAL_PERMISSIONS = GET(CREDENTIAL_PERMISSIONS)
export const GET_NOTIFICATIONS = GET(NOTIFICATIONS)
export const GET_LATEST_NOTIFICATIONS = GET(LATEST_NOTIFICATIONS)
export const GET_CURRENT_USER = GET(CURRENT_USER)
export const GET_DASHBOARD_CUSTOMIZATION = GET(DASHBOARD_CUSTOMIZATION)
export const GET_DASHBOARD_CUSTOMIZATIONS = GET(DASHBOARD_CUSTOMIZATIONS)
export const GET_GUEST_DASHBOARD_CUSTOMIZATIONS = GET(GUEST_DASHBOARD_CUSTOMIZATIONS)
export const GET_PAYMENT_METHOD_CONFIGURATION = GET(PAYMENT_METHOD_CONFIGURATION)
export const GET_WEBHOOK = GET(WEBHOOK)
export const GET_WEBHOOK_LOG = GET(WEBHOOK_LOG)
export const GET_ONBOARDING_FORM_DATA = GET(ONBOARDING_FORM_DATA)
export const GET_GUEST_ACCESS_FORM = GET(GUEST_ACCESS_FORM)
export const GET_GUEST_ONBOARDING_FORM_DATA = GET(GUEST_ONBOARDING_FORM_DATA)
export const GET_GUEST_ONBOARDING_FORM_FILES = GET(GUEST_ONBOARDING_FORM_FILES)
export const GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE= GET(GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE)
export const GET_HOSTED_FORM_TOKEN_VERIFICATION = GET(HOSTED_FORM_TOKEN_VERIFICATION)
export const GET_NOTIFICATION_SETTINGS = GET(NOTIFICATION_SETTINGS)
export const GET_ROLE_MEMBERS = GET(ROLE_MEMBERS)
// currently unused gateway admin tools fetch response files flow
export const GET_RESPONSE_FILES = GET(RESPONSE_FILES)
export const GET_FILE = GET(FILE)
export const GET_ACCESS_FORM_FILE = GET(ACCESS_FORM_FILE)
export const GET_FILES = GET(FILES)
export const GET_CERTIFICATE_SIGNING_REQUESTS = GET(CERTIFICATE_SIGNING_REQUESTS)
export const GET_CHART = GET(CHART)
export const GET_CHARTS = GET(CHARTS)
export const GET_CHART_DELTA = GET(CHART_DELTA)
export const GET_APPLICATION_UNDERWRITING_SETTINGS = GET(APPLICATION_UNDERWRITING_SETTINGS)
export const GET_EXPORTS = GET(EXPORTS)
export const GET_API_LOG = GET(API_LOG)
export const GET_API_LOGS = GET(API_LOGS)
export const GET_DEVICE = GET(DEVICE)
export const GET_DEVICES = GET(DEVICES)
export const GET_DEVICE_MERCHANT = GET(DEVICE_MERCHANT)
export const GET_UNMASKED_ITEM = GET(UNMASKED_ITEM)
export const GET_SETTLEMENTS_APPLICATIONS = GET(SETTLEMENTS_APPLICATIONS)
export const GET_WEBHOOKS = GET(WEBHOOKS)
export const GET_WEBHOOK_LOGS = GET(WEBHOOK_LOGS)
export const GET_WEBHOOKS_WITH_STOPPED_EVENTS = GET(WEBHOOKS_WITH_STOPPED_EVENTS)
export const GET_RELATED_APPLICATION = GET(RELATED_APPLICATION)
export const GET_RELATED_IDENTITY = GET(RELATED_IDENTITY)
export const GET_RELATED_PAYMENT_INSTRUMENT = GET(RELATED_PAYMENT_INSTRUMENT)
export const GET_MERCHANT_APPLICATION = GET(MERCHANT_APPLICATION)
export const GET_MERCHANT_VERIFICATION = GET(MERCHANT_VERIFICATION)
export const GET_ONBOARDING_FORMS = GET(ONBOARDING_FORMS)
export const GET_MEMBER = GET(MEMBER)
export const GET_MEMBERS = GET(MEMBERS)
export const GET_MEMBER_ROLE = GET(MEMBER_ROLE)
export const GET_ROLE_APPLICATION = GET(ROLE_APPLICATION)
export const GET_ACCESS_FORM = GET(ACCESS_FORM)
export const GET_ACCESS_FORM_CONTRACT = GET(ACCESS_FORM_CONTRACT)
export const GET_ACCESS_FORMS = GET(ACCESS_FORMS)
export const GET_UNDERWRITING_REVIEW = GET(UNDERWRITING_REVIEW)
export const GET_UNDERWRITING_REVIEWS = GET(UNDERWRITING_REVIEWS)
export const GET_DASHBOARD_CONTRACTS = GET(DASHBOARD_CONTRACTS)
export const GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS = GET(ACCESS_FORM_ADDITIONAL_DOCUMENTS)
export const GET_MERCHANT_UNDERWRITING_REVIEW =  GET(MERCHANT_UNDERWRITING_REVIEW)
export const GET_MERCHANT_UNDERWRITING_REVIEWS = GET(MERCHANT_UNDERWRITING_REVIEWS)
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES = GET(MERCHANT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES = GET(RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_FEE_PROFILE = GET(FEE_PROFILE)
export const GET_FEE_PROFILE_RULES = GET(FEE_PROFILE_RULES)
export const GET_FEE_PROFILE_SETTINGS = GET(FEE_PROFILE_SETTINGS)
export const GET_HOME_CHARTS = GET(HOME_CHARTS)
export const GET_TRANSACTION_CHARTS = GET(TRANSACTION_CHARTS)
export const GET_EXCEPTION_CHARTS = GET(EXCEPTION_CHARTS)
export const GET_UNDERWRITING_PROFILE = GET(UNDERWRITING_PROFILE)
export const GET_UNDERWRITING_PROFILES = GET(UNDERWRITING_PROFILES)
export const GET_APPLICATION_UNDERWRITING_PROFILE = GET(APPLICATION_UNDERWRITING_PROFILE)
export const GET_PLATFORM_UNDERWRITING_PROFILE = GET(PLATFORM_UNDERWRITING_PROFILE)
export const GET_UNDERWRITING_REPORT = GET(UNDERWRITING_REPORT)
export const GET_MERCHANT_CHARTS = GET(MERCHANT_CHARTS)
export const GET_DASHBOARD_USER_MFA_AUTHENTICATORS = GET(DASHBOARD_USER_MFA_AUTHENTICATORS)
export const GET_ENTITY_CONFIGURATIONS = GET(ENTITY_CONFIGURATIONS)
export const GET_SPLIT_TRANSFERS = GET(SPLIT_TRANSFERS)
export const GET_SPLIT_TRANSFER = GET(SPLIT_TRANSFER)
export const GET_TRANSFER_REVERSALS = GET(TRANSFER_REVERSALS)
export const GET_TRANSFER_DISPUTES = GET(TRANSFER_DISPUTES)
export const GET_UNDERWRITING_CHARTS = GET(UNDERWRITING_CHARTS)
export const GET_UNDERWRITING_SUBJECT_EVENTS = GET(UNDERWRITING_SUBJECT_EVENTS)
export const GET_UNDERWRITING_SUBJECT_EVENT = GET(UNDERWRITING_SUBJECT_EVENT)
export const GET_APPLICATION_BALANCES = GET(APPLICATION_BALANCES)
export const GET_CURRENT_USAGES = GET(CURRENT_USAGES)
export const GET_RECIPIENT_IDENTITIES = GET(RECIPIENT_IDENTITIES)
export const GET_RECIPIENT_MERCHANTS_IDENTITY = GET(RECIPIENT_IDENTITIES)
export const GET_RECIPIENT_UNDERWRITING_REVIEWS = GET(RECIPIENT_UNDERWRITING_REVIEWS)
export const GET_RECIPIENT_IDENTITY_MERCHANTS = GET(RECIPIENT_IDENTITY_MERCHANTS)
export const GET_DISBURSEMENT_RULES = GET(DISBURSEMENT_RULES)
export const GET_BALANCE_ENTRIES = GET(BALANCE_ENTRIES)
export const GET_BALANCE_ENTRIES_TRANSFER = GET(BALANCE_ENTRIES_TRANSFER)
export const GET_BALANCE_ENTRIES_APPLICATION = GET(BALANCE_ENTRIES_APPLICATION)
export const GET_BALANCE_ENTRY = GET(BALANCE_ENTRY)
export const GET_PAYMENT_LINK = GET(PAYMENT_LINK)
export const GET_PAYMENT_LINKS = GET(PAYMENT_LINKS)
export const GET_PAYMENT_LINKS_MERCHANT = GET(PAYMENT_LINKS_MERCHANT)
export const GET_PAYMENT_LINKS_APPLICATION = GET(PAYMENT_LINKS_APPLICATION)
export const GET_PURCHASES = GET(PURCHASES)
export const GET_GUEST_CHECKOUT_TOKEN_VERIFICATION = GET(GUEST_CHECKOUT_TOKEN_VERIFICATION)
export const GET_GUEST_CHECKOUT_PAYMENT_LINK = GET(GUEST_CHECKOUT_PAYMENT_LINK)
export const GET_PURCHASE = GET(PURCHASE)
export const GET_PURCHASES_TRANSFER = GET(PURCHASES_TRANSFER)
export const GET_PURCHASE_APPLICATION = GET(PURCHASE_APPLICATION)
export const GET_PURCHASE_MERCHANT = GET(PURCHASE_MERCHANT)
export const GET_PURCHASE_MERCHANT_IDENTITY = GET(PURCHASE_MERCHANT_IDENTITY)
export const GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT = GET(PURCHASE_TRANSFER_PAYMENT_INSTRUMENT)
export const GET_GUEST_CHECKOUT_FORM = GET(GUEST_CHECKOUT_FORM)
export const GET_NOTES = GET(NOTES)
export const GET_NOTE = GET(NOTE)
export const GET_DRAFT_NOTES = GET(DRAFT_NOTES)
export const GET_TRANSFER_MERCHANT = GET(TRANSFER_MERCHANT)
export const GET_UNDERWRITING_WORKFLOW = GET(UNDERWRITING_WORKFLOW)
export const GET_APPLICATION_PROCESSOR_CONFIGURATIONS = GET(APPLICATION_PROCESSOR_CONFIGURATIONS)
export const GET_AUDIT_LOG_EVENTS = GET(AUDIT_LOG_EVENTS)
export const GET_AUDIT_LOG_EVENT = GET(AUDIT_LOG_EVENT)
export const GET_FRAUD_SCORE = GET(FRAUD_SCORE)
export const GET_FRAUD_SCORES = GET(FRAUD_SCORES)
export const GET_GUEST_CHECKOUT_PAYOUT_LINK = GET(GUEST_CHECKOUT_PAYOUT_LINK)
export const GET_PAYOUT_LINK = GET(PAYOUT_LINK)
export const GET_TRANSFER_ATTEMPTS = GET(TRANSFER_ATTEMPTS)
export const GET_PAYOUT_LINKS = GET(PAYOUT_LINKS)
export const GET_TRANSFER_ATTEMPT = GET(TRANSFER_ATTEMPT)
export const GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS = GET(GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS)
export const GET_SPLIT_TRANSFER_SETTLEMENT = GET(SPLIT_TRANSFER_SETTLEMENT)
export const GET_BALANCE_ADJUSTMENTS = GET(BALANCE_ADJUSTMENTS)
export const GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT = GET(BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT)
export const GET_BALANCE_ADJUSTMENT = GET(BALANCE_ADJUSTMENT)
export const GET_MERCHANT_TRANSFERS_APPLICATION = GET(MERCHANT_TRANSFERS_APPLICATION)
export const GET_RECEIPT = GET(RECEIPT)
export const GET_RECEIPTS = GET(RECEIPTS)
export const GET_RECEIPT_DELIVERY_ATTEMPTS = GET(RECEIPT_DELIVERY_ATTEMPTS)
export const GET_SUBSCRIPTIONS = GET(SUBSCRIPTIONS)
export const GET_SUBSCRIPTION = GET(SUBSCRIPTION)
export const GET_SUBSCRIPTIONS_IDENTITY = GET(SUBSCRIPTIONS_IDENTITY)
export const GET_SUBSCRIPTIONS_MERCHANT = GET(SUBSCRIPTIONS_MERCHANT)
export const GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT = GET(SUBSCRIPTIONS_PAYMENT_INSTRUMENT)
export const GET_SUBSCRIPTION_PLANS = GET(SUBSCRIPTION_PLANS)
export const GET_SUBSCRIPTION_PLANS_MERCHANT = GET(SUBSCRIPTION_PLANS_MERCHANT)
export const GET_SUBSCRIPTION_PLAN = GET(SUBSCRIPTION_PLAN)
export const GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY = GET(SUBSCRIPTION_PLAN_MERCHANT_IDENTITY)
export const GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION = GET(SUBSCRIPTION_PLAN_MERCHANT_APPLICATION)
export const GET_SUBSCRIPTIONS_PLAN = GET(SUBSCRIPTIONS_PLAN)
export const GET_TRANSFER_ATTEMPT_PAYOUT_LINK = GET(TRANSFER_ATTEMPT_PAYOUT_LINK)
export const GET_MONITORING_ALERTS = GET(MONITORING_ALERTS)
export const GET_MONITORING_ALERT = GET(MONITORING_ALERT)
export const GET_INSTANT_PAYOUTS = GET(INSTANT_PAYOUTS)
export const GET_INSTANT_PAYOUTS_MERCHANT = GET(INSTANT_PAYOUTS_MERCHANT)
export const GET_INSTANT_PAYOUTS_PAYMENT_INSTRUMENT = GET(INSTANT_PAYOUTS_PAYMENT_INSTRUMENT)
export const GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY = GET(BALANCE_ADJUSTMENT_BALANCE_ENTRY)
export const GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY = GET(BALANCE_ENTRY_PARENT_BALANCE_ENTRY)
export const GET_INSTANT_PAYOUT = GET(INSTANT_PAYOUT)

// POST CONSTANTS
export const POST_SESSION = POST(SESSION)
export const POST_IDENTITY = POST(IDENTITY)
export const POST_RECIPIENT_IDENTITY = POST(RECIPIENT_IDENTITY)
export const POST_IDENTITY_SETTLEMENTS = POST(IDENTITY_SETTLEMENTS)
export const POST_IDENTITY_PAYMENT_INSTRUMENTS = POST(IDENTITY_PAYMENT_INSTRUMENTS)
export const POST_IDENTITY_MERCHANTS = POST(IDENTITY_MERCHANTS)
export const POST_IDENTITY_VERIFICATIONS = POST(IDENTITY_VERIFICATIONS)
export const POST_FEE = POST(FEE)
export const POST_APPLICATION = POST(APPLICATION)
export const POST_APPLICATION_ASSOCIATED_IDENTITIES = POST(APPLICATION_ASSOCIATED_IDENTITIES)
export const POST_APPLICATION_IDENTITIES = POST(APPLICATION_IDENTITIES)
export const POST_FEE_PROFILE = POST(FEE_PROFILE)
export const POST_DASHBOARD_USER = POST(DASHBOARD_USER)
export const POST_USER = POST(USER)
export const POST_CREDENTIALS = POST(CREDENTIALS)
export const POST_ADMIN_USER = POST(ADMIN_USER)
export const POST_SELF_SERVICE_SIGNUP_FORM = POST(SELF_SERVICE_SIGNUP_FORM)
export const POST_SELF_SERVICE_EMAIL_VERIFICATION = POST(SELF_SERVICE_EMAIL_VERIFICATION)
export const POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA = POST(SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA)
export const POST_SELF_SERVICE_RESEND_EMAIL = POST(SELF_SERVICE_RESEND_EMAIL)
export const POST_APPLICATION_PROCESSORS = POST(APPLICATION_PROCESSORS)
export const POST_USERS_EMAIL = POST(USERS_EMAIL)
export const POST_TRANSFER_REVERSAL = POST(TRANSFER_REVERSAL)
export const POST_UNDERWRITING_REVIEW_RUNS = POST(UNDERWRITING_REVIEW_RUNS)
export const POST_CUSTOMER_ONBOARDING_FORM = POST(CUSTOMER_ONBOARDING_FORM)
export const POST_TRANSFER = POST(TRANSFER)
export const POST_AUTHORIZATION = POST(AUTHORIZATION)
export const POST_BALANCE_TRANSFER = POST(BALANCE_TRANSFER)
export const POST_DISPUTE_EVIDENCES = POST(DISPUTE_EVIDENCES)
export const POST_SUBMIT_DISPUTE_EVIDENCE = POST(SUBMIT_DISPUTE_EVIDENCE)
export const POST_APPLICATION_USERS = POST(APPLICATION_USERS)
export const POST_PLATFORM_USER = POST(PLATFORM_USER)
export const POST_MERCHANT_VERIFICATIONS = POST(MERCHANT_VERIFICATIONS)
export const POST_MERCHANT_FEE_PROFILE = POST(MERCHANT_FEE_PROFILE)
export const POST_ADMIN_USER_MEMBERSHIPS = POST(ADMIN_USER_MEMBERSHIPS)
export const POST_DASHBOARD_FILE = POST(DASHBOARD_FILE)
export const POST_MASS_SETTLEMENT_APPROVAL = POST(MASS_SETTLEMENT_APPROVAL)
export const POST_PAYMENT_INSTRUMENT_VERIFICATIONS = POST(PAYMENT_INSTRUMENT_VERIFICATIONS)
export const POST_IDENTITY_UPLOAD = POST(IDENTITY_UPLOAD)
export const POST_PAYOUT_PLAN = POST(PAYOUT_PLAN)
export const POST_SUBSCRIPTION_SCHEDULE = POST(SUBSCRIPTION_SCHEDULE)
export const POST_SUBSCRIPTION_SCHEDULE_AMOUNT = POST(SUBSCRIPTION_SCHEDULE_AMOUNT)
export const POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT = POST(SUBSCRIPTION_SCHEDULE_ENROLLMENT)
export const POST_MERCHANT_IDENTITY = POST(MERCHANT_IDENTITY)
export const POST_DASHBOARD_CUSTOMIZATIONS = POST(DASHBOARD_CUSTOMIZATIONS)
export const POST_PAYMENT_METHOD_CONFIGURATIONS = POST(PAYMENT_METHOD_CONFIGURATIONS)
export const POST_CERTIFICATE_SIGNING_REQUESTS = POST(CERTIFICATE_SIGNING_REQUESTS)
export const POST_CERTIFICATE_SIGNING_REQUEST = POST(CERTIFICATE_SIGNING_REQUEST)
export const POST_MERCHANT_FILE = POST(MERCHANT_FILE)
export const POST_IDENTITY_FILE = POST(IDENTITY_FILE)
export const POST_COMPLIANCE_FORM_TEMPLATE = POST(COMPLIANCE_FORM_TEMPLATE)
export const POST_COMPLIANCE_FORM = POST(COMPLIANCE_FORM)
export const POST_DASHBOARD_FEEDBACK_EMAIL = POST(DASHBOARD_FEEDBACK_EMAIL)
export const POST_CONTACT_SUPPORT_EMAIL = POST(CONTACT_SUPPORT_EMAIL)
export const POST_CONTACT_SALES_EMAIL = POST(CONTACT_SALES_EMAIL)
export const POST_ONBOARDING_FORM_DATA = POST(ONBOARDING_FORM_DATA)
export const POST_EXPORTS = POST(EXPORTS)
export const POST_NOTIFICATION_SETTINGS = POST(NOTIFICATION_SETTINGS)
export const POST_NOTIFICATION_ACKNOWLEDGMENT = POST(NOTIFICATION_ACKNOWLEDGMENT)
export const POST_WEBHOOKS = POST(WEBHOOKS)
export const POST_ONBOARDING_FORM = POST(ONBOARDING_FORM)
export const POST_ONBOARDING_FORM_LINK = POST(ONBOARDING_FORM_LINK)
export const POST_DASHBOARD_PRESIGNED_URL = POST(DASHBOARD_PRESIGNED_URL)
export const POST_DASHBOARD_V2_EMAILS = POST(DASHBOARD_V2_EMAILS)
export const POST_MEMBER = POST(MEMBER)
export const POST_ROLE = POST(ROLE)
export const POST_CHANGE_PASSWORD = POST(CHANGE_PASSWORD)
export const POST_ACCESS_FORM = POST(ACCESS_FORM)
export const POST_SUBMIT_GUEST_ACCESS_FORM = POST(SUBMIT_GUEST_ACCESS_FORM)
export const POST_SUBMIT_ACCESS_FORM = POST(SUBMIT_ACCESS_FORM)
export const POST_ACCEPT_DISPUTE = POST(ACCEPT_DISPUTE)
export const POST_UNDERWRITING_PROFILE = POST(UNDERWRITING_PROFILE)
export const POST_UNDERWRITING_RUN_SILENCE = POST(UNDERWRITING_RUN_SILENCE)
export const POST_CUSTOMER_ONBOARDING_FORM_LINKS = GET(CUSTOMER_ONBOARDING_FORM_LINKS)
export const POST_DASHBOARD_USER_MFA_TOKEN = POST(DASHBOARD_USER_MFA_TOKEN)
export const POST_DASHBOARD_USER_MFA_ASSOCIATE = POST(DASHBOARD_USER_MFA_ASSOCIATE)
export const POST_DASHBOARD_USER_MFA_VERIFY = POST(DASHBOARD_USER_MFA_VERIFY)
export const POST_ENTITY_CONFIGURATION = POST(ENTITY_CONFIGURATION)
export const POST_DASHBOARD_USER_MFA_RESET = POST(DASHBOARD_USER_MFA_RESET)
export const POST_PAYMENT_INSTRUMENT = POST(PAYMENT_INSTRUMENT)
export const POST_DISBURSEMENT_RULES = POST(DISBURSEMENT_RULES)
export const POST_BALANCE_ENTRY = POST(BALANCE_ENTRY)
export const POST_PAYMENT_LINK = POST(PAYMENT_LINK)
export const POST_PURCHASE = POST(PURCHASE)
export const POST_NOTE = POST(NOTE)
export const POST_DEVICE = POST(DEVICE)
export const POST_PLAID_TOKEN = POST(PLAID_TOKEN)
export const POST_PLAID_PUBLIC_TOKEN = POST(PLAID_PUBLIC_TOKEN)
export const POST_SUBMIT_ACCESS_FORM_SALES_REVIEW = POST(SUBMIT_SALES_REVIEW_ACCESS_FORM)
export const POST_REQUEST_CHANGES = POST(REQUEST_CHANGES)
export const POST_SUBMIT_TO_UNDERWRITING = POST(SUBMIT_TO_UNDERWRITING)
export const POST_PAYOUT_LINK = POST(PAYOUT_LINK)
export const POST_TRANSFER_ATTEMPT = POST(TRANSFER_ATTEMPT)
export const POST_DISPUTE = POST(DISPUTE)
export const POST_FEE_PROFILE_SETTINGS = POST(FEE_PROFILE_SETTINGS)
export const POST_FEE_PROFILE_RULES = POST(FEE_PROFILE_RULES)
export const POST_BALANCE_ADJUSTMENT = POST(BALANCE_ADJUSTMENT)
export const POST_RECEIPT = POST(RECEIPT)
export const POST_RECEIPT_DELIVERY_ATTEMPTS = POST(RECEIPT_DELIVERY_ATTEMPTS)
export const POST_SUBSCRIPTION = POST(SUBSCRIPTION)
export const POST_SUBSCRIPTION_PLAN = POST(SUBSCRIPTION_PLAN)
export const POST_INSTANT_PAYOUT = POST(INSTANT_PAYOUT)

// PATCH CONSTANTS
export const PATCH_APPLICATION = PATCH(APPLICATION)
export const PATCH_AUTHORIZATION = PATCH(AUTHORIZATION)
export const PATCH_IDENTITY = PATCH(IDENTITY)
export const PATCH_TRANSFER = PATCH(TRANSFER)
export const PATCH_PAYMENT_INSTRUMENT = PATCH(PAYMENT_INSTRUMENT)
export const PATCH_REVIEW_QUEUE_ITEM = PATCH(REVIEW_QUEUE_ITEM)
export const PATCH_REVIEW_QUEUE_ITEMS = PATCH(REVIEW_QUEUE_ITEMS)
export const PATCH_MERCHANT = PATCH(MERCHANT)
export const PATCH_RISK_PROFILE = PATCH(RISK_PROFILE)
export const PATCH_MERCHANT_RISK_PROFILE = PATCH(MERCHANT_RISK_PROFILE)
export const PATCH_TRANSFERS_UPDATE = PATCH(TRANSFERS_UPDATE)
export const PATCH_VERIFICATIONS_UPDATE = PATCH(VERIFICATIONS_UPDATE)
export const PATCH_WELCOME_EMAIL_TEMPLATE = PATCH(WELCOME_EMAIL_TEMPLATE)
export const PATCH_EMAIL_TEMPLATE = PATCH(EMAIL_TEMPLATE)
export const PATCH_SETTLEMENT_V1 = POST(SETTLEMENT)
export const PATCH_PLATFORM_PROCESSOR_CONFIG = PATCH(PLATFORM_PROCESSOR_CONFIG)
export const PATCH_USER_PERMISSIONS = PATCH(USER_PERMISSIONS)
export const PATCH_SETTLEMENT = PATCH(SETTLEMENT_V2)
export const PATCH_PAYOUT_PLAN = PATCH(PAYOUT_PLAN)
export const PATCH_USER = PATCH(USER)
export const PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT = PATCH(SUBSCRIPTION_SCHEDULE_AMOUNT)
export const PATCH_FEE = PATCH(FEE)
export const PATCH_MERCHANT_IDENTITY = PATCH(MERCHANT_IDENTITY)
export const PATCH_DASHBOARD_CUSTOMIZATIONS = PATCH(DASHBOARD_CUSTOMIZATIONS)
export const PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING = PATCH(IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const PATCH_WEBHOOK = PATCH(WEBHOOK)
export const PATCH_PAYMENT_METHOD_CONFIGURATIONS = PATCH(PAYMENT_METHOD_CONFIGURATIONS)
export const PATCH_COMPLIANCE_FORM = PATCH(COMPLIANCE_FORM)
export const PATCH_COMPLIANCE_FORM_TEMPLATE = PATCH(COMPLIANCE_FORM_TEMPLATE)
export const PATCH_ONBOARDING_FORM_DATA = PATCH(ONBOARDING_FORM_DATA)
export const PATCH_APPLICATION_UNDERWRITING_SETTINGS = PATCH(APPLICATION_UNDERWRITING_SETTINGS)
export const PATCH_COMPLIANCE_SETTINGS = PATCH(COMPLIANCE_SETTINGS)
export const PATCH_PAYOUT_SETTINGS = PATCH(PAYOUT_SETTINGS)
export const PATCH_CREDENTIAL = PATCH(CREDENTIAL)
export const PATCH_MEMBERSHIP = PATCH(MEMBERSHIP)
export const PATCH_ROLE = PATCH(ROLE)
export const PATCH_DASHBOARD_USER = PATCH(DASHBOARD_USER)
export const PATCH_MEMBER = PATCH(MEMBER)
export const PATCH_ACCESS_FORM = PATCH(ACCESS_FORM)
export const PATCH_GUEST_ACCESS_FORM = PATCH(GUEST_ACCESS_FORM)
export const PATCH_UNDERWRITING_REVIEW = PATCH(UNDERWRITING_REVIEW)
export const PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS = PATCH(ACCESS_FORM_ADDITIONAL_DOCUMENTS)
export const PATCH_ONBOARDING_FORM_BANK_DOCUMENT = PATCH(ONBOARDING_FORM_BANK_DOCUMENT)
export const PATCH_UNDERWRITING_PROFILE = PATCH(UNDERWRITING_PROFILE)
export const PATCH_DISPUTE = PATCH(DISPUTE)
export const PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM = PATCH(CUSTOMER_ONBOARDING_FORM_ACCESS_FORM)
export const PATCH_ENTITY_CONFIGURATION = PATCH(ENTITY_CONFIGURATION)
export const PATCH_DISBURSEMENT_RULES = PATCH(DISBURSEMENT_RULES)
export const PATCH_PAYMENT_LINK = PATCH(PAYMENT_LINK)
export const PATCH_PURCHASE = PATCH(PURCHASE)
export const PATCH_NOTE = PATCH(NOTE)
export const PATCH_DEVICE = PATCH(DEVICE)
export const PATCH_UNDERWRITING_WORKFLOW = PATCH(UNDERWRITING_WORKFLOW)
export const PATCH_PLAID_TOKEN_METADATA = PATCH(PLAID_TOKEN_METADATA)
export const PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS = PATCH(APPLICATION_PROCESSOR_CONFIGURATIONS)
export const PATCH_PAYOUT_LINK = PATCH(PAYOUT_LINK)
export const PATCH_TRANSFER_ATTEMPT = PATCH(TRANSFER_ATTEMPT)
export const PATCH_UPDATE_DISPUTE = PATCH(UPDATE_DISPUTE)
export const PATCH_ONBOARDING_FORM_DOCUMENTS = PATCH(ONBOARDING_FORM_DOCUMENTS)
export const PATCH_FEE_PROFILE_SETTINGS = PATCH(FEE_PROFILE_SETTINGS)
export const PATCH_FEE_PROFILE_RULES = PATCH(FEE_PROFILE_RULES)
export const PATCH_SUBSCRIPTION_PLAN = PATCH(SUBSCRIPTION_PLAN)
export const PATCH_SUBSCRIPTION = PATCH(SUBSCRIPTION)
export const PATCH_MONITORING_ALERT = PATCH(MONITORING_ALERT)
export const PATCH_MONITORING_ALERTS = PATCH(MONITORING_ALERTS)

// DELETE
export const DELETE_SETTLEMENT_ENTRIES = DELETE(SETTLEMENT_ENTRIES)
export const DELETE_SUBSCRIPTION_ENROLLMENT = DELETE(SUBSCRIPTION_ENROLLMENT)
export const DELETE_SUBSCRIPTION_ENROLLMENTS = DELETE(SUBSCRIPTION_ENROLLMENTS)
export const DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT = DELETE(SUBSCRIPTION_SCHEDULE_AMOUNT)
export const DELETE_UNMASKED_ITEM = DELETE(UNMASKED_ITEM)
export const DELETE_UNMASKED_ITEMS = DELETE(UNMASKED_ITEMS)
export const DELETE_ACCESS_FORM = DELETE(ACCESS_FORM)
export const DELETE_API_KEY = DELETE(API_KEY)
export const DELETE_SUBSCRIPTION = DELETE(SUBSCRIPTION)

// TODO: look into if we can remove any of these unused imports
// DISPATCH CONSTANTS
export const GET_APP_CONFIGURATION_F_REQUEST = requestF(GET_APP_CONFIGURATION)
export const GET_APP_CONFIGURATION_F_SUCCESS = successF(GET_APP_CONFIGURATION)

export const GET_CONFIGURATION_F_REQUEST = requestF(GET_CONFIGURATION)
export const GET_CONFIGURATION_F_SUCCESS = successF(GET_CONFIGURATION)
export const GET_CONFIGURATION_F_FAILURE = failureF(GET_CONFIGURATION)

export const GET_CONFIGURATIONS_F_REQUEST = requestF(GET_CONFIGURATIONS)
export const GET_CONFIGURATIONS_F_SUCCESS = successF(GET_CONFIGURATIONS)
export const GET_CONFIGURATIONS_F_FAILURE = failureF(GET_CONFIGURATIONS)

export const GET_CREDENTIAL_F_REQUEST = requestF(GET_CREDENTIAL)
export const GET_CREDENTIAL_F_SUCCESS = successF(GET_CREDENTIAL)
export const GET_CREDENTIAL_F_FAILURE = failureF(GET_CREDENTIAL)

export const PATCH_CREDENTIAL_F_REQUEST = requestF(PATCH_CREDENTIAL)
export const PATCH_CREDENTIAL_F_SUCCESS = successF(PATCH_CREDENTIAL)
export const PATCH_CREDENTIAL_F_FAILURE = failureF(PATCH_CREDENTIAL)

export const GET_CREDENTIALS_F_REQUEST = requestF(GET_CREDENTIALS)
export const GET_CREDENTIALS_F_SUCCESS = successF(GET_CREDENTIALS)
export const GET_CREDENTIALS_F_FAILURE = failureF(GET_CREDENTIALS)

export const GET_RECENTLY_ACTIVE_CREDENTIALS_F_REQUEST = requestF(GET_RECENTLY_ACTIVE_CREDENTIALS)

export const GET_SESSION_F_REQUEST = requestF(GET_SESSION)
export const GET_SESSION_F_SUCCESS = successF(GET_SESSION)
export const GET_SESSION_F_FAILURE = failureF(GET_SESSION)

export const POST_SESSION_F_REQUEST = requestF(POST_SESSION)
export const POST_SESSION_F_SUCCESS = successF(POST_SESSION)
export const POST_SESSION_F_FAILURE = failureF(POST_SESSION)

export const GET_TRANSFERS_F_REQUEST = requestF(GET_TRANSFERS)
export const GET_TRANSFERS_F_SUCCESS = successF(GET_TRANSFERS)
export const GET_TRANSFERS_F_FAILURE = failureF(GET_TRANSFERS)

export const GET_BALANCE_TRANSFER_F_REQUEST = requestF(GET_BALANCE_TRANSFER)
export const GET_BALANCE_TRANSFER_F_SUCCESS = successF(GET_BALANCE_TRANSFER)
export const GET_BALANCE_TRANSFER_F_FAILURE = failureF(GET_BALANCE_TRANSFER)

export const GET_BALANCE_TRANSFERS_F_REQUEST = requestF(GET_BALANCE_TRANSFERS)
export const GET_BALANCE_TRANSFERS_F_SUCCESS = successF(GET_BALANCE_TRANSFERS)
export const GET_BALANCE_TRANSFERS_F_FAILURE = failureF(GET_BALANCE_TRANSFERS)

export const POST_BALANCE_TRANSFER_F_REQUEST = requestF(POST_BALANCE_TRANSFER)
export const POST_BALANCE_TRANSFER_F_SUCCESS = successF(POST_BALANCE_TRANSFER)
export const POST_BALANCE_TRANSFER_F_FAILURE = failureF(POST_BALANCE_TRANSFER)

export const GET_TRANSFERS_SEARCH_F_REQUEST = requestF(GET_TRANSFERS_SEARCH)
export const GET_TRANSFERS_SEARCH_F_SUCCESS = successF(GET_TRANSFERS_SEARCH)
export const GET_TRANSFERS_SEARCH_F_FAILURE = failureF(GET_TRANSFERS_SEARCH)

export const GET_APPLICATIONS_F_REQUEST = requestF(GET_APPLICATIONS)
export const GET_APPLICATIONS_F_SUCCESS = successF(GET_APPLICATIONS)

export const GET_CUSTOMER_ONBOARDING_FORMS_F_REQUEST = requestF(GET_CUSTOMER_ONBOARDING_FORMS)
export const GET_CUSTOMER_ONBOARDING_FORMS_F_SUCCESS = successF(GET_CUSTOMER_ONBOARDING_FORMS)
export const GET_CUSTOMER_ONBOARDING_FORMS_F_FAILURE = failureF(GET_CUSTOMER_ONBOARDING_FORMS)

export const GET_CUSTOMER_ONBOARDING_FORM_F_REQUEST = requestF(GET_CUSTOMER_ONBOARDING_FORM)
export const GET_CUSTOMER_ONBOARDING_FORM_F_SUCCESS = successF(GET_CUSTOMER_ONBOARDING_FORM)
export const GET_CUSTOMER_ONBOARDING_FORM_F_FAILURE = failureF(GET_CUSTOMER_ONBOARDING_FORM)

export const POST_CUSTOMER_ONBOARDING_FORM_LINKS_F_REQUEST = requestF(POST_CUSTOMER_ONBOARDING_FORM_LINKS)
export const POST_CUSTOMER_ONBOARDING_FORM_LINKS_F_SUCCESS = successF(POST_CUSTOMER_ONBOARDING_FORM_LINKS)
export const POST_CUSTOMER_ONBOARDING_FORM_LINKS_F_FAILURE = failureF(POST_CUSTOMER_ONBOARDING_FORM_LINKS)

export const GET_APPLICATIONS_IDENTITY_F_SUCCESS = successF(GET_APPLICATIONS_IDENTITY)
export const GET_APPLICATIONS_IDENTITY_F_FAILURE = failureF(GET_APPLICATIONS_IDENTITY)

export const GET_TRANSFER_F_REQUEST = requestF(GET_TRANSFER)
export const GET_TRANSFER_F_SUCCESS = successF(GET_TRANSFER)
export const GET_TRANSFER_F_FAILURE = failureF(GET_TRANSFER)

export const GET_APPLICATION_F_REQUEST = requestF(GET_APPLICATION)
export const GET_APPLICATION_F_SUCCESS = successF(GET_APPLICATION)

export const GET_PAYMENT_INSTRUMENT_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT)
export const GET_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT)
export const GET_PAYMENT_INSTRUMENT_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT)

export const POST_PAYMENT_INSTRUMENT_F_REQUEST = requestF(POST_PAYMENT_INSTRUMENT)
export const POST_PAYMENT_INSTRUMENT_F_SUCCESS = successF(POST_PAYMENT_INSTRUMENT)
export const POST_PAYMENT_INSTRUMENT_F_FAILURE = failureF(POST_PAYMENT_INSTRUMENT)

export const GET_PAYMENT_INSTRUMENT_UNMASK_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_UNMASK)
export const GET_PAYMENT_INSTRUMENT_UNMASK_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_UNMASK)
export const GET_PAYMENT_INSTRUMENT_UNMASK_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT_UNMASK)

export const GET_PAYMENT_INSTRUMENTS_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENTS)
export const GET_PAYMENT_INSTRUMENTS_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENTS)
export const GET_PAYMENT_INSTRUMENTS_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENTS)

export const GET_PAYMENT_INSTRUMENT_TRANSFERS_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_TRANSFERS)
export const GET_PAYMENT_INSTRUMENT_TRANSFERS_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_TRANSFERS)
export const GET_PAYMENT_INSTRUMENT_TRANSFERS_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT_TRANSFERS)

export const GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS)
export const GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS)

export const GET_PAYMENT_INSTRUMENT_VERIFICATIONS_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_VERIFICATIONS)
export const GET_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_VERIFICATIONS)
export const GET_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT_VERIFICATIONS)

export const POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_REQUEST = requestF(POST_PAYMENT_INSTRUMENT_VERIFICATIONS)
export const POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_SUCCESS = successF(POST_PAYMENT_INSTRUMENT_VERIFICATIONS)
export const POST_PAYMENT_INSTRUMENT_VERIFICATIONS_F_FAILURE = failureF(POST_PAYMENT_INSTRUMENT_VERIFICATIONS)

export const GET_AUTHORIZATION_F_REQUEST = requestF(GET_AUTHORIZATION)
export const GET_AUTHORIZATION_F_SUCCESS = successF(GET_AUTHORIZATION)
export const GET_AUTHORIZATION_F_FAILURE = failureF(GET_AUTHORIZATION)

export const GET_AUTHORIZATIONS_F_REQUEST = requestF(GET_AUTHORIZATIONS)
export const GET_AUTHORIZATIONS_F_SUCCESS = successF(GET_AUTHORIZATIONS)
export const GET_AUTHORIZATIONS_F_FAILURE = failureF(GET_AUTHORIZATIONS)

export const POST_AUTHORIZATION_F_REQUEST = requestF(POST_AUTHORIZATION)
export const POST_AUTHORIZATION_F_SUCCESS = successF(POST_AUTHORIZATION)
export const POST_AUTHORIZATION_F_FAILURE = failureF(POST_AUTHORIZATION)

export const GET_AUTHORIZATIONS_SEARCH_F_REQUEST = requestF(GET_AUTHORIZATIONS_SEARCH)
export const GET_AUTHORIZATIONS_SEARCH_F_SUCCESS = successF(GET_AUTHORIZATIONS_SEARCH)
export const GET_AUTHORIZATIONS_SEARCH_F_FAILURE = failureF(GET_AUTHORIZATIONS_SEARCH)

export const GET_SETTLEMENT_F_REQUEST = requestF(GET_SETTLEMENT)
export const GET_SETTLEMENT_F_SUCCESS = successF(GET_SETTLEMENT)
export const GET_SETTLEMENT_F_FAILURE = failureF(GET_SETTLEMENT)

export const GET_SETTLEMENTS_F_REQUEST = requestF(GET_SETTLEMENTS)
export const GET_SETTLEMENTS_F_SUCCESS = successF(GET_SETTLEMENTS)
export const GET_SETTLEMENTS_F_FAILURE = failureF(GET_SETTLEMENTS)

export const PATCH_SETTLEMENT_V1_F_REQUEST = requestF(PATCH_SETTLEMENT_V1)
export const PATCH_SETTLEMENT_V1_F_SUCCESS = successF(PATCH_SETTLEMENT_V1)
export const PATCH_SETTLEMENT_V1_F_FAILURE = failureF(PATCH_SETTLEMENT_V1)

export const GET_IDENTITIES_F_REQUEST = requestF(GET_IDENTITIES)
export const GET_IDENTITIES_F_SUCCESS = successF(GET_IDENTITIES)
export const GET_IDENTITIES_F_FAILURE = failureF(GET_IDENTITIES)

export const GET_SETTLEMENT_TRANSFERS_F_REQUEST = requestF(GET_SETTLEMENT_TRANSFERS)
export const GET_SETTLEMENT_TRANSFERS_F_SUCCESS = successF(GET_SETTLEMENT_TRANSFERS)
export const GET_SETTLEMENT_TRANSFERS_F_FAILURE = failureF(GET_SETTLEMENT_TRANSFERS)

export const GET_SETTLEMENT_FUNDING_TRANSFERS_F_REQUEST = requestF(GET_SETTLEMENT_FUNDING_TRANSFERS)
export const GET_SETTLEMENT_FUNDING_TRANSFERS_F_SUCCESS = successF(GET_SETTLEMENT_FUNDING_TRANSFERS)
export const GET_SETTLEMENT_FUNDING_TRANSFERS_F_FAILURE = failureF(GET_SETTLEMENT_FUNDING_TRANSFERS)

export const GET_DISPUTE_F_REQUEST = requestF(GET_DISPUTE)
export const GET_DISPUTE_F_SUCCESS = successF(GET_DISPUTE)
export const GET_DISPUTE_F_FAILURE = failureF(GET_DISPUTE)

export const GET_DISPUTES_F_REQUEST = requestF(GET_DISPUTES)
export const GET_DISPUTES_F_SUCCESS = successF(GET_DISPUTES)
export const GET_DISPUTES_F_FAILURE = failureF(GET_DISPUTES)

export const GET_DISPUTE_EVIDENCES_F_REQUEST = requestF(GET_DISPUTE_EVIDENCES)
export const GET_DISPUTE_EVIDENCES_F_SUCCESS = successF(GET_DISPUTE_EVIDENCES)
export const GET_DISPUTE_EVIDENCES_F_FAILURE = failureF(GET_DISPUTE_EVIDENCES)

export const POST_DISPUTE_EVIDENCES_F_REQUEST = requestF(POST_DISPUTE_EVIDENCES)
export const POST_DISPUTE_EVIDENCES_F_SUCCESS = successF(POST_DISPUTE_EVIDENCES)
export const POST_DISPUTE_EVIDENCES_F_FAILURE = failureF(POST_DISPUTE_EVIDENCES)

export const POST_SUBMIT_DISPUTE_EVIDENCE_F_REQUEST = requestF(POST_SUBMIT_DISPUTE_EVIDENCE)

export const POST_ACCEPT_DISPUTE_F_REQUEST = requestF(POST_ACCEPT_DISPUTE)

export const GET_DISPUTE_ADJUSTMENT_TRANSFERS_F_REQUEST = requestF(GET_DISPUTE_ADJUSTMENT_TRANSFERS)
export const GET_DISPUTE_ADJUSTMENT_TRANSFERS_F_SUCCESS = successF(GET_DISPUTE_ADJUSTMENT_TRANSFERS)
export const GET_DISPUTE_ADJUSTMENT_TRANSFERS_F_FAILURE = failureF(GET_DISPUTE_ADJUSTMENT_TRANSFERS)

export const GET_USERS_F_REQUEST = requestF(GET_USERS)
export const GET_USERS_F_SUCCESS = successF(GET_USERS)
export const GET_USERS_F_FAILURE = failureF(GET_USERS)

export const GET_ROLE_F_REQUEST = requestF(GET_ROLE)
export const GET_ROLE_F_SUCCESS = successF(GET_ROLE)
export const GET_ROLE_F_FAILURE = failureF(GET_ROLE)

export const GET_ROLES_F_REQUEST = requestF(GET_ROLES)
export const GET_ROLES_F_SUCCESS = successF(GET_ROLES)
export const GET_ROLES_F_FAILURE = failureF(GET_ROLES)

export const PATCH_ROLE_F_REQUEST = requestF(PATCH_ROLE)
export const PATCH_ROLE_F_SUCCESS = successF(PATCH_ROLE)
export const PATCH_ROLE_F_FAILURE = failureF(PATCH_ROLE)

export const POST_ROLE_F_REQUEST = requestF(POST_ROLE)
export const POST_ROLE_F_SUCCESS = successF(POST_ROLE)
export const POST_ROLE_F_FAILURE = failureF(POST_ROLE)

export const GET_ADMIN_USER_F_REQUEST = requestF(GET_ADMIN_USER)
export const GET_ADMIN_USER_F_SUCCESS = successF(GET_ADMIN_USER)
export const GET_ADMIN_USER_F_FAILURE = failureF(GET_ADMIN_USER)

export const GET_ADMIN_USER_MEMBERSHIPS_F_REQUEST = requestF(GET_ADMIN_USER_MEMBERSHIPS)
export const GET_ADMIN_USER_MEMBERSHIPS_F_SUCCESS = successF(GET_ADMIN_USER_MEMBERSHIPS)
export const GET_ADMIN_USER_MEMBERSHIPS_F_FAILURE = failureF(GET_ADMIN_USER_MEMBERSHIPS)

export const POST_ADMIN_USER_MEMBERSHIPS_F_REQUEST=  requestF(POST_ADMIN_USER_MEMBERSHIPS)
export const POST_ADMIN_USER_MEMBERSHIPS_F_SUCCESS = successF(POST_ADMIN_USER_MEMBERSHIPS)
export const POST_ADMIN_USER_MEMBERSHIPS_F_FAILURE = failureF(POST_ADMIN_USER_MEMBERSHIPS)

export const GET_ADMIN_USER_CREDENTIALS_F_REQUEST = requestF(GET_ADMIN_USER_CREDENTIALS)
export const GET_ADMIN_USER_CREDENTIALS_F_SUCCESS = successF(GET_ADMIN_USER_CREDENTIALS)
export const GET_ADMIN_USER_CREDENTIALS_F_FAILURE = failureF(GET_ADMIN_USER_CREDENTIALS)

export const GET_IDENTITY_F_REQUEST = requestF(GET_IDENTITY)
export const GET_IDENTITY_F_SUCCESS = successF(GET_IDENTITY)
export const GET_IDENTITY_F_FAILURE = failureF(GET_IDENTITY)

export const GET_IDENTITY_UNMASK_F_REQUEST = requestF(GET_IDENTITY_UNMASK)
export const GET_IDENTITY_UNMASK_F_SUCCESS = successF(GET_IDENTITY_UNMASK)
export const GET_IDENTITY_UNMASK_F_FAILURE = failureF(GET_IDENTITY_UNMASK)

export const POST_IDENTITY_F_REQUEST = requestF(POST_IDENTITY)
export const POST_IDENTITY_F_SUCCESS = successF(POST_IDENTITY)
export const POST_IDENTITY_F_FAILURE = failureF(POST_IDENTITY)

export const POST_RECIPIENT_IDENTITY_F_REQUEST = requestF(POST_RECIPIENT_IDENTITY)
export const POST_RECIPIENT_IDENTITY_F_SUCCESS = successF(POST_RECIPIENT_IDENTITY)
export const POST_RECIPIENT_IDENTITY_F_FAILURE = failureF(POST_RECIPIENT_IDENTITY)

export const POST_IDENTITY_SETTLEMENTS_F_REQUEST = requestF(POST_IDENTITY_SETTLEMENTS)
export const POST_IDENTITY_SETTLEMENTS_F_SUCCESS = successF(POST_IDENTITY_SETTLEMENTS)
export const POST_IDENTITY_SETTLEMENTS_F_FAILURE = failureF(POST_IDENTITY_SETTLEMENTS)

export const POST_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST = requestF(POST_IDENTITY_PAYMENT_INSTRUMENTS)
export const POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS = successF(POST_IDENTITY_PAYMENT_INSTRUMENTS)
export const POST_IDENTITY_PAYMENT_INSTRUMENTS_F_FAILURE = failureF(POST_IDENTITY_PAYMENT_INSTRUMENTS)

export const POST_IDENTITY_MERCHANTS_F_REQUEST = requestF(POST_IDENTITY_MERCHANTS)
export const POST_IDENTITY_MERCHANTS_F_SUCCESS = successF(POST_IDENTITY_MERCHANTS)
export const POST_IDENTITY_MERCHANTS_F_FAILURE = failureF(POST_IDENTITY_MERCHANTS)

export const POST_IDENTITY_VERIFICATIONS_F_REQUEST = requestF(POST_IDENTITY_VERIFICATIONS)
export const POST_IDENTITY_VERIFICATIONS_F_SUCCESS = successF(POST_IDENTITY_VERIFICATIONS)
export const POST_IDENTITY_VERIFICATIONS_F_FAILURE = failureF(POST_IDENTITY_VERIFICATIONS)

export const GET_MERCHANT_F_REQUEST = requestF(GET_MERCHANT)
export const GET_MERCHANT_F_SUCCESS = successF(GET_MERCHANT)
export const GET_MERCHANT_F_FAILURE = failureF(GET_MERCHANT)

export const GET_MERCHANTS_F_REQUEST = requestF(GET_MERCHANTS)
export const GET_MERCHANTS_F_SUCCESS = successF(GET_MERCHANTS)
export const GET_MERCHANTS_F_FAILURE = failureF(GET_MERCHANTS)

export const GET_MERCHANT_COMPLIANCE_FORMS_F_REQUEST = requestF(GET_MERCHANT_COMPLIANCE_FORMS)
export const GET_MERCHANT_COMPLIANCE_FORMS_F_SUCCESS = successF(GET_MERCHANT_COMPLIANCE_FORMS)
export const GET_MERCHANT_COMPLIANCE_FORMS_F_FAILURE = failureF(GET_MERCHANT_COMPLIANCE_FORMS)

export const GET_COMPLIANCE_FORMS_F_REQUEST = requestF(GET_COMPLIANCE_FORMS)
export const GET_COMPLIANCE_FORMS_F_SUCCESS = successF(GET_COMPLIANCE_FORMS)
export const GET_COMPLIANCE_FORMS_F_FAILURE = failureF(GET_COMPLIANCE_FORMS)

export const GET_COMPLIANCE_FORM_TEMPLATE_FORMS_F_REQUEST = requestF(GET_COMPLIANCE_FORM_TEMPLATE_FORMS)
export const GET_COMPLIANCE_FORM_TEMPLATE_FORMS_F_SUCCESS = successF(GET_COMPLIANCE_FORM_TEMPLATE_FORMS)
export const GET_COMPLIANCE_FORM_TEMPLATE_FORMS_F_FAILURE = failureF(GET_COMPLIANCE_FORM_TEMPLATE_FORMS)

export const GET_COMPLIANCE_FORM_F_REQUEST = requestF(GET_COMPLIANCE_FORM)
export const GET_COMPLIANCE_FORM_F_SUCCESS = successF(GET_COMPLIANCE_FORM)
export const GET_COMPLIANCE_FORM_F_FAILURE = failureF(GET_COMPLIANCE_FORM)

export const GET_COMPLIANCE_FORM_APPLICATION_F_REQUEST = requestF(GET_COMPLIANCE_FORM_APPLICATION)
export const GET_COMPLIANCE_FORM_APPLICATION_F_SUCCESS = successF(GET_COMPLIANCE_FORM_APPLICATION)
export const GET_COMPLIANCE_FORM_APPLICATION_F_FAILURE = failureF(GET_COMPLIANCE_FORM_APPLICATION)

export const GET_COMPLIANCE_FORMS_APPLICATION_F_SUCCESS = successF(GET_COMPLIANCE_FORMS_APPLICATION)

export const GET_COMPLIANCE_SETTINGS_F_REQUEST = requestF(GET_COMPLIANCE_SETTINGS)
export const GET_COMPLIANCE_SETTINGS_F_SUCCESS = successF(GET_COMPLIANCE_SETTINGS)
export const GET_COMPLIANCE_SETTINGS_F_FAILURE = failureF(GET_COMPLIANCE_SETTINGS)

export const PATCH_COMPLIANCE_SETTINGS_F_REQUEST = requestF(PATCH_COMPLIANCE_SETTINGS)
export const PATCH_COMPLIANCE_SETTINGS_F_SUCCESS = successF(PATCH_COMPLIANCE_SETTINGS)
export const PATCH_COMPLIANCE_SETTINGS_F_FAILURE = failureF(PATCH_COMPLIANCE_SETTINGS)

export const PATCH_PAYOUT_SETTINGS_F_REQUEST = requestF(PATCH_PAYOUT_SETTINGS)
export const PATCH_PAYOUT_SETTINGS_F_SUCCESS = successF(PATCH_PAYOUT_SETTINGS)
export const PATCH_PAYOUT_SETTINGS_F_FAILURE = failureF(PATCH_PAYOUT_SETTINGS)

export const GET_COMPLIANCE_FORM_TEMPLATES_F_REQUEST = requestF(GET_COMPLIANCE_FORM_TEMPLATES)
export const GET_COMPLIANCE_FORM_TEMPLATES_F_SUCCESS = successF(GET_COMPLIANCE_FORM_TEMPLATES)
export const GET_COMPLIANCE_FORM_TEMPLATES_F_FAILURE = failureF(GET_COMPLIANCE_FORM_TEMPLATES)

export const GET_COMPLIANCE_FORM_TEMPLATE_F_REQUEST = requestF(GET_COMPLIANCE_FORM_TEMPLATE)
export const GET_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS = successF(GET_COMPLIANCE_FORM_TEMPLATE)
export const GET_COMPLIANCE_FORM_TEMPLATE_F_FAILURE = failureF(GET_COMPLIANCE_FORM_TEMPLATE)

export const GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE_F_REQUEST = requestF(GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE)
export const GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS = successF(GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE)
export const GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE_F_FAILURE = failureF(GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE)

export const PATCH_COMPLIANCE_FORM_F_REQUEST = requestF(PATCH_COMPLIANCE_FORM)
export const PATCH_COMPLIANCE_FORM_F_SUCCESS = successF(PATCH_COMPLIANCE_FORM)
export const PATCH_COMPLIANCE_FORM_F_FAILURE = failureF(PATCH_COMPLIANCE_FORM)

export const POST_COMPLIANCE_FORM_TEMPLATE_F_REQUEST = requestF(POST_COMPLIANCE_FORM_TEMPLATE)
export const POST_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS = successF(POST_COMPLIANCE_FORM_TEMPLATE)
export const POST_COMPLIANCE_FORM_TEMPLATE_F_FAILURE = failureF(POST_COMPLIANCE_FORM_TEMPLATE)

export const POST_COMPLIANCE_FORM_F_REQUEST = requestF(POST_COMPLIANCE_FORM)
export const POST_COMPLIANCE_FORM_F_SUCCESS = successF(POST_COMPLIANCE_FORM)
export const POST_COMPLIANCE_FORM_F_FAILURE = failureF(POST_COMPLIANCE_FORM)

export const PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST = requestF(PATCH_COMPLIANCE_FORM_TEMPLATE)
export const PATCH_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS = successF(PATCH_COMPLIANCE_FORM_TEMPLATE)
export const PATCH_COMPLIANCE_FORM_TEMPLATE_F_FAILURE = failureF(PATCH_COMPLIANCE_FORM_TEMPLATE)

export const GET_MERCHANT_TRANSFERS_F_REQUEST = requestF(GET_MERCHANT_TRANSFERS)
export const GET_MERCHANT_TRANSFERS_F_SUCCESS = successF(GET_MERCHANT_TRANSFERS)
export const GET_MERCHANT_TRANSFERS_F_FAILURE = failureF(GET_MERCHANT_TRANSFERS)

export const GET_MERCHANT_AUTHORIZATIONS_F_REQUEST = requestF(GET_MERCHANT_AUTHORIZATIONS)
export const GET_MERCHANT_AUTHORIZATIONS_F_SUCCESS = successF(GET_MERCHANT_AUTHORIZATIONS)
export const GET_MERCHANT_AUTHORIZATIONS_F_FAILURE = failureF(GET_MERCHANT_AUTHORIZATIONS)

export const GET_MERCHANT_SETTLEMENTS_F_REQUEST = requestF(GET_MERCHANT_SETTLEMENTS)
export const GET_MERCHANT_SETTLEMENTS_F_SUCCESS = successF(GET_MERCHANT_SETTLEMENTS)
export const GET_MERCHANT_SETTLEMENTS_F_FAILURE = failureF(GET_MERCHANT_SETTLEMENTS)

export const GET_MERCHANT_FEES_F_REQUEST = requestF(GET_MERCHANT_FEES)
export const GET_MERCHANT_FEES_F_SUCCESS = successF(GET_MERCHANT_FEES)
export const GET_MERCHANT_FEES_F_FAILURE = failureF(GET_MERCHANT_FEES)

export const GET_MERCHANT_FILES_F_REQUEST = requestF(GET_MERCHANT_FILES)
export const GET_MERCHANT_FILES_F_SUCCESS = successF(GET_MERCHANT_FILES)
export const GET_MERCHANT_FILES_F_FAILURE = failureF(GET_MERCHANT_FILES)

export const GET_IDENTITY_FILES_F_REQUEST = requestF(GET_IDENTITY_FILES)
export const GET_IDENTITY_FILES_F_SUCCESS = successF(GET_IDENTITY_FILES)
export const GET_IDENTITY_FILES_F_FAILURE = failureF(GET_IDENTITY_FILES)

export const GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS_F_REQUEST = requestF(GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS)
export const GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS = successF(GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS)
export const GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS_F_FAILURE = failureF(GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS)

export const PATCH_MERCHANT_F_REQUEST = requestF(PATCH_MERCHANT)
export const PATCH_MERCHANT_F_SUCCESS = successF(PATCH_MERCHANT)
export const PATCH_MERCHANT_F_FAILURE = failureF(PATCH_MERCHANT)

export const PATCH_APPLICATION_F_REQUEST = requestF(PATCH_APPLICATION)
export const PATCH_APPLICATION_F_SUCCESS = successF(PATCH_APPLICATION)
export const PATCH_APPLICATION_F_FAILURE = failureF(PATCH_APPLICATION)

export const GET_APPLICATION_PROCESSOR_F_REQUEST = requestF(GET_APPLICATION_PROCESSOR)
export const GET_APPLICATION_PROCESSOR_F_SUCCESS = successF(GET_APPLICATION_PROCESSOR)
export const GET_APPLICATION_PROCESSOR_F_FAILURE = failureF(GET_APPLICATION_PROCESSOR)

export const PATCH_AUTHORIZATION_F_REQUEST = requestF(PATCH_AUTHORIZATION)
export const PATCH_AUTHORIZATION_F_SUCCESS = successF(PATCH_AUTHORIZATION)
export const PATCH_AUTHORIZATION_F_FAILURE = failureF(PATCH_AUTHORIZATION)

export const PATCH_IDENTITY_F_REQUEST = requestF(PATCH_IDENTITY)
export const PATCH_IDENTITY_F_SUCCESS = successF(PATCH_IDENTITY)
export const PATCH_IDENTITY_F_FAILURE = failureF(PATCH_IDENTITY)

export const PATCH_TRANSFER_F_REQUEST = requestF(PATCH_TRANSFER)
export const PATCH_TRANSFER_F_SUCCESS = successF(PATCH_TRANSFER)
export const PATCH_TRANSFER_F_FAILURE = failureF(PATCH_TRANSFER)

export const PATCH_PAYMENT_INSTRUMENT_F_REQUEST = requestF(PATCH_PAYMENT_INSTRUMENT)
export const PATCH_PAYMENT_INSTRUMENT_F_SUCCESS = successF(PATCH_PAYMENT_INSTRUMENT)
export const PATCH_PAYMENT_INSTRUMENT_F_FAILURE = failureF(PATCH_PAYMENT_INSTRUMENT)

export const POST_APPLICATION_F_REQUEST = requestF(POST_APPLICATION)
export const POST_APPLICATION_F_SUCCESS = successF(POST_APPLICATION)
export const POST_APPLICATION_F_FAILURE = failureF(POST_APPLICATION)

export const GET_REVIEW_QUEUE_ITEM_F_REQUEST = requestF(GET_REVIEW_QUEUE_ITEM)
export const GET_REVIEW_QUEUE_ITEM_F_SUCCESS = successF(GET_REVIEW_QUEUE_ITEM)
export const GET_REVIEW_QUEUE_ITEM_F_FAILURE = failureF(GET_REVIEW_QUEUE_ITEM)

export const GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS = successF(GET_ALL_REVIEW_QUEUE_ITEMS_BATCH)
export const GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST = requestF(GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL)
export const GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST = requestF(GET_ALL_REVIEW_QUEUE_ITEMS)
export const GET_ALL_REVIEW_QUEUE_ITEMS_F_SUCCESS = successF(GET_ALL_REVIEW_QUEUE_ITEMS)
export const GET_ALL_REVIEW_QUEUE_ITEMS_F_FAILURE = failureF(GET_ALL_REVIEW_QUEUE_ITEMS)

export const PATCH_REVIEW_QUEUE_ITEM_F_REQUEST = requestF(PATCH_REVIEW_QUEUE_ITEM)
export const PATCH_REVIEW_QUEUE_ITEM_F_SUCCESS = successF(PATCH_REVIEW_QUEUE_ITEM)
export const PATCH_REVIEW_QUEUE_ITEM_F_FAILURE = failureF(PATCH_REVIEW_QUEUE_ITEM)

export const PATCH_REVIEW_QUEUE_ITEMS_F_REQUEST = requestF(PATCH_REVIEW_QUEUE_ITEMS)
export const PATCH_REVIEW_QUEUE_ITEMS_F_SUCCESS = successF(PATCH_REVIEW_QUEUE_ITEMS)
export const PATCH_REVIEW_QUEUE_ITEMS_F_FAILURE = failureF(PATCH_REVIEW_QUEUE_ITEMS)

export const POST_APPLICATION_ASSOCIATED_IDENTITIES_F_REQUEST = requestF(POST_APPLICATION_ASSOCIATED_IDENTITIES)
export const POST_APPLICATION_ASSOCIATED_IDENTITIES_F_SUCCESS = successF(POST_APPLICATION_ASSOCIATED_IDENTITIES)
export const POST_APPLICATION_ASSOCIATED_IDENTITIES_F_FAILURE = failureF(POST_APPLICATION_ASSOCIATED_IDENTITIES)

export const POST_APPLICATION_IDENTITIES_F_REQUEST = requestF(POST_APPLICATION_IDENTITIES)
export const POST_APPLICATION_IDENTITIES_F_SUCCESS = successF(POST_APPLICATION_IDENTITIES)
export const POST_APPLICATION_IDENTITIES_F_FAILURE = failureF(POST_APPLICATION_IDENTITIES)

export const POST_MERCHANT_FILE_F_REQUEST = requestF(POST_MERCHANT_FILE)
export const POST_MERCHANT_FILE_F_SUCCESS = successF(POST_MERCHANT_FILE)
export const POST_MERCHANT_FILE_F_FAILURE = failureF(POST_MERCHANT_FILE)

export const POST_IDENTITY_FILE_F_REQUEST = requestF(POST_IDENTITY_FILE)
export const POST_IDENTITY_FILE_F_SUCCESS = successF(POST_IDENTITY_FILE)
export const POST_IDENTITY_FILE_F_FAILURE = failureF(POST_IDENTITY_FILE)

export const POST_DASHBOARD_FEEDBACK_EMAIL_F_REQUEST = requestF(POST_DASHBOARD_FEEDBACK_EMAIL)
export const POST_DASHBOARD_FEEDBACK_EMAIL_F_SUCCESS = successF(POST_DASHBOARD_FEEDBACK_EMAIL)
export const POST_DASHBOARD_FEEDBACK_EMAIL_F_FAILURE = failureF(POST_DASHBOARD_FEEDBACK_EMAIL)

export const POST_CONTACT_SUPPORT_EMAIL_F_REQUEST = requestF(POST_CONTACT_SUPPORT_EMAIL)
export const POST_CONTACT_SUPPORT_EMAIL_F_SUCCESS = successF(POST_CONTACT_SUPPORT_EMAIL)
export const POST_CONTACT_SUPPORT_EMAIL_F_FAILURE = failureF(POST_CONTACT_SUPPORT_EMAIL)

export const POST_CONTACT_SALES_EMAIL_F_REQUEST = requestF(POST_CONTACT_SALES_EMAIL)
export const POST_CONTACT_SALES_EMAIL_F_SUCCESS = successF(POST_CONTACT_SALES_EMAIL)
export const POST_CONTACT_SALES_EMAIL_F_FAILURE = failureF(POST_CONTACT_SALES_EMAIL)

export const GET_DASHBOARD_USER_F_REQUEST = requestF(GET_DASHBOARD_USER)
export const GET_DASHBOARD_USER_F_SUCCESS = successF(GET_DASHBOARD_USER)
export const GET_DASHBOARD_USER_F_FAILURE = failureF(GET_DASHBOARD_USER)
export const GET_DASHBOARD_USER_F_SUCCESS_NOTIFICATION = notification(successF(GET_DASHBOARD_USER))
export const GET_DASHBOARD_USER_F_FAILURE_NOTIFICATION = notification(failureF(GET_DASHBOARD_USER))

export const POST_DASHBOARD_USER_F_REQUEST = requestF(POST_DASHBOARD_USER)
export const POST_DASHBOARD_USER_F_SUCCESS = successF(POST_DASHBOARD_USER)
export const POST_DASHBOARD_USER_F_FAILURE = failureF(POST_DASHBOARD_USER)

export const POST_USER_F_REQUEST = requestF(POST_USER)
export const POST_USER_F_SUCCESS = successF(POST_USER)
export const POST_USER_F_FAILURE = failureF(POST_USER)

export const POST_ADMIN_USER_F_REQUEST = requestF(POST_ADMIN_USER)
export const POST_ADMIN_USER_F_SUCCESS = requestF(POST_ADMIN_USER)
export const POST_ADMIN_USER_F_FAILURE = requestF(POST_ADMIN_USER)

export const POST_SELF_SERVICE_SIGNUP_FORM_F_REQUEST = requestF(POST_SELF_SERVICE_SIGNUP_FORM)
export const POST_SELF_SERVICE_SIGNUP_FORM_F_SUCCESS = successF(POST_SELF_SERVICE_SIGNUP_FORM)
export const POST_SELF_SERVICE_SIGNUP_FORM_F_FAILURE = failureF(POST_SELF_SERVICE_SIGNUP_FORM)

export const POST_SELF_SERVICE_EMAIL_VERIFICATION_F_REQUEST = requestF(POST_SELF_SERVICE_EMAIL_VERIFICATION)
export const POST_SELF_SERVICE_EMAIL_VERIFICATION_F_SUCCESS = successF(POST_SELF_SERVICE_EMAIL_VERIFICATION)
export const POST_SELF_SERVICE_EMAIL_VERIFICATION_F_FAILURE = failureF(POST_SELF_SERVICE_EMAIL_VERIFICATION)

export const POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_REQUEST = requestF(POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA)
export const POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_SUCCESS = successF(POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA)
export const POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_FAILURE = failureF(POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA)

export const POST_SELF_SERVICE_RESEND_EMAIL_F_REQUEST = requestF(POST_SELF_SERVICE_RESEND_EMAIL)
export const POST_SELF_SERVICE_RESEND_EMAIL_F_SUCCESS = successF(POST_SELF_SERVICE_RESEND_EMAIL)
export const POST_SELF_SERVICE_RESEND_EMAIL_F_FAILURE = failureF(POST_SELF_SERVICE_RESEND_EMAIL)

export const POST_CREDENTIALS_F_REQUEST = requestF(POST_CREDENTIALS)
export const POST_CREDENTIALS_F_SUCCESS = requestF(POST_CREDENTIALS)
export const POST_CREDENTIALS_F_FAILURE = requestF(POST_CREDENTIALS)

export const POST_APPLICATION_PROCESSORS_F_REQUEST = requestF(POST_APPLICATION_PROCESSORS)
export const POST_APPLICATION_PROCESSORS_F_SUCCESS = successF(POST_APPLICATION_PROCESSORS)
export const POST_APPLICATION_PROCESSORS_F_FAILURE = failureF(POST_APPLICATION_PROCESSORS)

export const POST_USERS_EMAIL_F_REQUEST = requestF(POST_USERS_EMAIL)
export const POST_USERS_EMAIL_F_SUCCESS = successF(POST_USERS_EMAIL)
export const POST_USERS_EMAIL_F_FAILURE = failureF(POST_USERS_EMAIL)

export const POST_TRANSFER_REVERSAL_F_REQUEST = requestF(POST_TRANSFER_REVERSAL)
export const POST_TRANSFER_REVERSAL_F_SUCCESS = successF(POST_TRANSFER_REVERSAL)
export const POST_TRANSFER_REVERSAL_F_FAILURE = failureF(POST_TRANSFER_REVERSAL)

export const POST_UNDERWRITING_REVIEW_RUNS_F_REQUEST = requestF(POST_UNDERWRITING_REVIEW_RUNS)
export const POST_UNDERWRITING_REVIEW_RUNS_F_SUCCESS = successF(POST_UNDERWRITING_REVIEW_RUNS)
export const POST_UNDERWRITING_REVIEW_RUNS_F_FAILURE = failureF(POST_UNDERWRITING_REVIEW_RUNS)

export const POST_CUSTOMER_ONBOARDING_FORM_F_REQUEST = requestF(POST_CUSTOMER_ONBOARDING_FORM)
export const POST_CUSTOMER_ONBOARDING_FORM_F_SUCCESS = successF(POST_CUSTOMER_ONBOARDING_FORM)
export const POST_CUSTOMER_ONBOARDING_FORM_F_FAILURE = failureF(POST_CUSTOMER_ONBOARDING_FORM)

export const PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM_F_REQUEST = requestF(PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM)
export const PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM_F_SUCCESS = successF(PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM)
export const PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM_F_FAILURE = failureF(PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM)

export const POST_TRANSFER_F_REQUEST = requestF(POST_TRANSFER)
export const POST_TRANSFER_F_SUCCESS = successF(POST_TRANSFER)
export const POST_TRANSFER_F_FAILURE = failureF(POST_TRANSFER)

export const DELETE_SETTLEMENT_ENTRIES_F_REQUEST = requestF(DELETE_SETTLEMENT_ENTRIES)
export const DELETE_SETTLEMENT_ENTRIES_F_SUCCESS = successF(DELETE_SETTLEMENT_ENTRIES)
export const DELETE_SETTLEMENT_ENTRIES_F_FAILURE = failureF(DELETE_SETTLEMENT_ENTRIES)

export const GET_SETTINGS_F_REQUEST = requestF(GET_SETTINGS)
export const GET_SETTINGS_F_SUCCESS = successF(GET_SETTINGS)
export const GET_SETTINGS_F_FAILURE = failureF(GET_SETTINGS)

export const GET_APPLICATION_USERS_F_REQUEST = requestF(GET_APPLICATION_USERS)
export const GET_APPLICATION_USERS_F_SUCCESS = successF(GET_APPLICATION_USERS)
export const GET_APPLICATION_USERS_F_FAILURE = failureF(GET_APPLICATION_USERS)

export const POST_APPLICATION_USERS_F_REQUEST = requestF(POST_APPLICATION_USERS)
export const POST_APPLICATION_USERS_F_SUCCESS = successF(POST_APPLICATION_USERS)
export const POST_APPLICATION_USERS_F_FAILURE = failureF(POST_APPLICATION_USERS)

export const POST_PLATFORM_USER_F_REQUEST = requestF(POST_PLATFORM_USER)
export const POST_PLATFORM_USER_F_SUCCESS = successF(POST_PLATFORM_USER)
export const POST_PLATFORM_USER_F_FAILURE = failureF(POST_PLATFORM_USER)

export const POST_MERCHANT_VERIFICATIONS_F_REQUEST = requestF(POST_MERCHANT_VERIFICATIONS)
export const POST_MERCHANT_VERIFICATIONS_F_SUCCESS = successF(POST_MERCHANT_VERIFICATIONS)
export const POST_MERCHANT_VERIFICATIONS_F_FAILURE = failureF(POST_MERCHANT_VERIFICATIONS)

export const POST_MERCHANT_FEE_PROFILE_F_REQUEST = requestF(POST_MERCHANT_FEE_PROFILE)
export const POST_MERCHANT_FEE_PROFILE_F_SUCCESS = successF(POST_MERCHANT_FEE_PROFILE)
export const POST_MERCHANT_FEE_PROFILE_F_FAILURE = failureF(POST_MERCHANT_FEE_PROFILE)

export const POST_FEE_PROFILE_F_REQUEST = requestF(POST_FEE_PROFILE)
export const POST_FEE_PROFILE_F_SUCCESS = requestF(POST_FEE_PROFILE)
export const POST_FEE_PROFILE_F_FAILURE = requestF(POST_FEE_PROFILE)

export const GET_SEARCH_F_REQUEST = requestF(GET_SEARCH)
export const GET_SEARCH_F_SUCCESS = successF(GET_SEARCH)
export const GET_SEARCH_F_FAILURE = failureF(GET_SEARCH)

export const GET_IDENTITY_AUTHORIZATIONS_F_REQUEST = requestF(GET_IDENTITY_AUTHORIZATIONS)
export const GET_IDENTITY_AUTHORIZATIONS_F_SUCCESS = successF(GET_IDENTITY_AUTHORIZATIONS)
export const GET_IDENTITY_AUTHORIZATIONS_F_FAILURE = failureF(GET_IDENTITY_AUTHORIZATIONS)

export const GET_IDENTITY_TRANSFERS_F_REQUEST = requestF(GET_IDENTITY_TRANSFERS)
export const GET_IDENTITY_TRANSFERS_F_SUCCESS = successF(GET_IDENTITY_TRANSFERS)
export const GET_IDENTITY_TRANSFERS_F_FAILURE = failureF(GET_IDENTITY_TRANSFERS)

export const GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST = requestF(GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_SUCCESS = successF(GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_FAILURE = failureF(GET_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)

export const GET_IDENTITY_MERCHANTS_F_REQUEST = requestF(GET_IDENTITY_MERCHANTS)
export const GET_IDENTITY_MERCHANTS_F_SUCCESS = successF(GET_IDENTITY_MERCHANTS)

export const GET_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST = requestF(GET_IDENTITY_PAYMENT_INSTRUMENTS)
export const GET_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS = successF(GET_IDENTITY_PAYMENT_INSTRUMENTS)

export const GET_IDENTITY_SETTLEMENTS_F_SUCCESS = successF(GET_IDENTITY_SETTLEMENTS)

export const GET_IDENTITY_VERIFICATIONS_F_REQUEST = requestF(GET_IDENTITY_VERIFICATIONS)
export const GET_IDENTITY_VERIFICATIONS_F_SUCCESS = successF(GET_IDENTITY_VERIFICATIONS)

export const GET_IDENTITY_DISPUTES_F_SUCCESS = successF(GET_IDENTITY_DISPUTES)

export const GET_IDENTITY_VERIFICATION_F_REQUEST = requestF(GET_IDENTITY_VERIFICATION)

export const GET_APPLICATION_MERCHANTS_F_REQUEST = requestF(GET_APPLICATION_MERCHANTS)
export const GET_APPLICATION_MERCHANTS_F_SUCCESS = successF(GET_APPLICATION_MERCHANTS)
export const GET_APPLICATION_MERCHANTS_F_FAILURE = failureF(GET_APPLICATION_MERCHANTS)

export const GET_APPLICATION_ASSOCIATED_IDENTITIES_F_SUCCESS = successF(GET_APPLICATION_ASSOCIATED_IDENTITIES)
export const GET_APPLICATION_DISPUTES_F_SUCCESS = successF(GET_APPLICATION_DISPUTES)

export const GET_APPLICATION_IDENTITIES_F_REQUEST = requestF(GET_APPLICATION_IDENTITIES)
export const GET_APPLICATION_IDENTITIES_F_SUCCESS = successF(GET_APPLICATION_IDENTITIES)

export const GET_APPLICATION_SETTLEMENTS_F_SUCCESS = successF(GET_APPLICATION_SETTLEMENTS)
export const GET_APPLICATION_TRANSFERS_F_SUCCESS = successF(GET_APPLICATION_TRANSFERS)

export const GET_APPLICATION_PROCESSORS_F_REQUEST = requestF(GET_APPLICATION_PROCESSORS)
export const GET_APPLICATION_PROCESSORS_F_SUCCESS = successF(GET_APPLICATION_PROCESSORS)
export const GET_APPLICATION_PROCESSORS_F_FAILURE = failureF(GET_APPLICATION_PROCESSORS)

export const GET_MERCHANT_PAYOUT_SETTINGS_F_REQUEST = requestF(GET_MERCHANT_PAYOUT_SETTINGS)
export const GET_MERCHANT_PAYOUT_SETTINGS_F_SUCCESS = successF(GET_MERCHANT_PAYOUT_SETTINGS)
export const GET_MERCHANT_PAYOUT_SETTINGS_F_FAILURE = failureF(GET_MERCHANT_PAYOUT_SETTINGS)

export const GET_PAYOUT_SETTINGS_F_REQUEST = requestF(GET_PAYOUT_SETTINGS)
export const GET_PAYOUT_SETTINGS_F_SUCCESS = successF(GET_PAYOUT_SETTINGS)
export const GET_PAYOUT_SETTINGS_F_FAILURE = failureF(GET_PAYOUT_SETTINGS)

export const GET_REVIEW_QUEUE_F_REQUEST = requestF(GET_REVIEW_QUEUE)
export const GET_REVIEW_QUEUE_F_SUCCESS = successF(GET_REVIEW_QUEUE)
export const GET_REVIEW_QUEUE_F_FAILURE = failureF(GET_REVIEW_QUEUE)

export const PATCH_RISK_PROFILE_F_REQUEST = requestF(PATCH_RISK_PROFILE)
export const PATCH_RISK_PROFILE_F_SUCCESS = successF(PATCH_RISK_PROFILE)
export const PATCH_RISK_PROFILE_F_FAILURE = failureF(PATCH_RISK_PROFILE)

export const PATCH_MERCHANT_RISK_PROFILE_F_REQUEST = requestF(PATCH_MERCHANT_RISK_PROFILE)
export const PATCH_MERCHANT_RISK_PROFILE_F_SUCCESS = successF(PATCH_MERCHANT_RISK_PROFILE)
export const PATCH_MERCHANT_RISK_PROFILE_F_FAILURE = failureF(PATCH_MERCHANT_RISK_PROFILE)

export const PATCH_TRANSFERS_UPDATE_F_REQUEST = requestF(PATCH_TRANSFERS_UPDATE)
export const PATCH_TRANSFERS_UPDATE_F_SUCCESS = successF(PATCH_TRANSFERS_UPDATE)
export const PATCH_TRANSFERS_UPDATE_F_FAILURE = failureF(PATCH_TRANSFERS_UPDATE)

export const PATCH_VERIFICATIONS_UPDATE_F_REQUEST = requestF(PATCH_VERIFICATIONS_UPDATE)
export const PATCH_VERIFICATIONS_UPDATE_F_SUCCESS = successF(PATCH_VERIFICATIONS_UPDATE)
export const PATCH_VERIFICATIONS_UPDATE_F_FAILURE = failureF(PATCH_VERIFICATIONS_UPDATE)

export const GET_WELCOME_EMAIL_TEMPLATE_F_REQUEST = requestF(GET_WELCOME_EMAIL_TEMPLATE)
export const GET_WELCOME_EMAIL_TEMPLATE_F_SUCCESS = successF(GET_WELCOME_EMAIL_TEMPLATE)
export const GET_WELCOME_EMAIL_TEMPLATE_F_FAILURE = failureF(GET_WELCOME_EMAIL_TEMPLATE)

export const PATCH_WELCOME_EMAIL_TEMPLATE_F_REQUEST = requestF(PATCH_WELCOME_EMAIL_TEMPLATE)
export const PATCH_WELCOME_EMAIL_TEMPLATE_F_SUCCESS = successF(PATCH_WELCOME_EMAIL_TEMPLATE)
export const PATCH_WELCOME_EMAIL_TEMPLATE_F_FAILURE = failureF(PATCH_WELCOME_EMAIL_TEMPLATE)

export const PATCH_EMAIL_TEMPLATE_F_REQUEST = requestF(PATCH_EMAIL_TEMPLATE)
export const PATCH_EMAIL_TEMPLATE_F_SUCCESS = successF(PATCH_EMAIL_TEMPLATE)
export const PATCH_EMAIL_TEMPLATE_F_FAILURE = failureF(PATCH_EMAIL_TEMPLATE)

export const GET_EMAIL_TEMPLATE_F_REQUEST = requestF(GET_EMAIL_TEMPLATE)
export const GET_EMAIL_TEMPLATE_F_SUCCESS = successF(GET_EMAIL_TEMPLATE)
export const GET_EMAIL_TEMPLATE_F_FAILURE = failureF(GET_EMAIL_TEMPLATE)

export const GET_REVIEW_QUEUE_IDENTITIES_F_REQUEST = requestF(GET_REVIEW_QUEUE_IDENTITIES)
export const GET_REVIEW_QUEUE_SETTLEMENTS_F_REQUEST = requestF(GET_REVIEW_QUEUE_SETTLEMENTS)

export const POST_DASHBOARD_FILE_F_REQUEST = requestF(POST_DASHBOARD_FILE)
export const POST_DASHBOARD_FILE_F_SUCCESS = successF(POST_DASHBOARD_FILE)
export const POST_DASHBOARD_FILE_F_FAILURE = failureF(POST_DASHBOARD_FILE)

export const GET_PLATFORM_F_REQUEST = requestF(GET_PLATFORM)
export const GET_PLATFORM_F_SUCCESS = successF(GET_PLATFORM)
export const GET_PLATFORM_F_FAILURE = failureF(GET_PLATFORM)

export const GET_PLATFORM_PROCESSOR_CONFIG_F_REQUEST = requestF(GET_PLATFORM_PROCESSOR_CONFIG)
export const GET_PLATFORM_PROCESSOR_CONFIG_F_SUCCESS = successF(GET_PLATFORM_PROCESSOR_CONFIG)
export const GET_PLATFORM_PROCESSOR_CONFIG_F_FAILURE = failureF(GET_PLATFORM_PROCESSOR_CONFIG)

export const PATCH_PLATFORM_PROCESSOR_CONFIG_F_REQUEST = requestF(PATCH_PLATFORM_PROCESSOR_CONFIG)
export const PATCH_PLATFORM_PROCESSOR_CONFIG_F_SUCCESS = successF(PATCH_PLATFORM_PROCESSOR_CONFIG)
export const PATCH_PLATFORM_PROCESSOR_CONFIG_F_FAILURE = failureF(PATCH_PLATFORM_PROCESSOR_CONFIG)

export const GET_PLATFORM_USERS_F_REQUEST = requestF(GET_PLATFORM_USERS)
export const GET_PLATFORM_USERS_F_SUCCESS = successF(GET_PLATFORM_USERS)
export const GET_PLATFORM_USERS_F_FAILURE = failureF(GET_PLATFORM_USERS)

export const GET_PLATFORM_USER_F_REQUEST = requestF(GET_PLATFORM_USER)
export const GET_PLATFORM_USER_F_SUCCESS = successF(GET_PLATFORM_USER)
export const GET_PLATFORM_USER_F_FAILURE = failureF(GET_PLATFORM_USER)

export const GET_USER_PERMISSIONS_F_REQUEST = requestF(GET_USER_PERMISSIONS)
export const GET_USER_PERMISSIONS_F_SUCCESS = successF(GET_USER_PERMISSIONS)
export const GET_USER_PERMISSIONS_F_FAILURE = failureF(GET_USER_PERMISSIONS)

export const PATCH_USER_PERMISSIONS_F_REQUEST = requestF(PATCH_USER_PERMISSIONS)
export const PATCH_USER_PERMISSIONS_F_SUCCESS = successF(PATCH_USER_PERMISSIONS)
export const PATCH_USER_PERMISSIONS_F_FAILURE = failureF(PATCH_USER_PERMISSIONS)

export const PATCH_USER_F_REQUEST = requestF(PATCH_USER)
export const PATCH_USER_F_SUCCESS = successF(PATCH_USER)
export const PATCH_USER_F_FAILURE = failureF(PATCH_USER)

export const GET_PAYMENT_INSTRUMENT_APPLICATION_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_APPLICATION)
export const GET_PAYMENT_INSTRUMENT_APPLICATION_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_APPLICATION)
export const GET_PAYMENT_INSTRUMENT_APPLICATION_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT_APPLICATION)

export const GET_PAYMENT_INSTRUMENT_IDENTITY_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENT_IDENTITY)
export const GET_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_IDENTITY)
export const GET_PAYMENT_INSTRUMENT_IDENTITY_F_FAILURE = failureF(GET_PAYMENT_INSTRUMENT_IDENTITY)

export const GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENT_IDENTITY)

export const GET_FEES_F_REQUEST = requestF(GET_FEES)
export const GET_FEES_F_SUCCESS = successF(GET_FEES)
export const GET_FEES_F_FAILURE = failureF(GET_FEES)

export const GET_FEE_F_REQUEST = requestF(GET_FEE)
export const GET_FEE_F_SUCCESS = successF(GET_FEE)
export const GET_FEE_F_FAILURE = failureF(GET_FEE)

export const GET_SUBSCRIPTION_SCHEDULES_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULES)
export const GET_SUBSCRIPTION_SCHEDULES_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULES)
export const GET_SUBSCRIPTION_SCHEDULES_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULES)

export const GET_SUBSCRIPTION_SCHEDULES_AMOUNTS_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULES_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULES_AMOUNTS_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULES_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULES_AMOUNTS_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULES_AMOUNTS)

export const GET_SUBSCRIPTION_SCHEDULE_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULE)
export const GET_SUBSCRIPTION_SCHEDULE_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULE)
export const GET_SUBSCRIPTION_SCHEDULE_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULE)

export const GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULE_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULE_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULE_AMOUNTS)

export const GET_SUBSCRIPTION_SCHEDULE_FEES_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULE_FEES)
export const GET_SUBSCRIPTION_SCHEDULE_FEES_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULE_FEES)
export const GET_SUBSCRIPTION_SCHEDULE_FEES_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULE_FEES)

export const GET_SUBSCRIPTION_ENROLLMENT_F_REQUEST = requestF(GET_SUBSCRIPTION_ENROLLMENT)
export const GET_SUBSCRIPTION_ENROLLMENT_F_SUCCESS = successF(GET_SUBSCRIPTION_ENROLLMENT)
export const GET_SUBSCRIPTION_ENROLLMENT_F_FAILURE = failureF(GET_SUBSCRIPTION_ENROLLMENT)

export const GET_SUBSCRIPTION_ENROLLMENTS_F_REQUEST = requestF(GET_SUBSCRIPTION_ENROLLMENTS)
export const GET_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS = successF(GET_SUBSCRIPTION_ENROLLMENTS)
export const GET_SUBSCRIPTION_ENROLLMENTS_F_FAILURE = failureF(GET_SUBSCRIPTION_ENROLLMENTS)

export const DELETE_SUBSCRIPTION_ENROLLMENT_F_REQUEST = requestF(DELETE_SUBSCRIPTION_ENROLLMENT)
export const DELETE_SUBSCRIPTION_ENROLLMENT_F_SUCCESS = successF(DELETE_SUBSCRIPTION_ENROLLMENT)
export const DELETE_SUBSCRIPTION_ENROLLMENT_F_FAILURE = failureF(DELETE_SUBSCRIPTION_ENROLLMENT)

export const DELETE_SUBSCRIPTION_ENROLLMENTS_F_REQUEST = requestF(DELETE_SUBSCRIPTION_ENROLLMENTS)
export const DELETE_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS = successF(DELETE_SUBSCRIPTION_ENROLLMENTS)
export const DELETE_SUBSCRIPTION_ENROLLMENTS_F_FAILURE = failureF(DELETE_SUBSCRIPTION_ENROLLMENTS)

export const PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST = requestF(PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS = successF(PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE = failureF(PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT)

export const DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST = requestF(DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS = successF(DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE = failureF(DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT)

export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_REQUEST = requestF(GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS)
export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS)
export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_FAILURE = failureF(GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS)

export const POST_FEE_F_REQUEST = requestF(POST_FEE)
export const POST_FEE_F_SUCCESS = successF(POST_FEE)
export const POST_FEE_F_FAILURE = failureF(POST_FEE)

export const PATCH_FEE_F_REQUEST = requestF(PATCH_FEE)
export const PATCH_FEE_F_SUCCESS = successF(PATCH_FEE)
export const PATCH_FEE_F_FAILURE = failureF(PATCH_FEE)

export const GET_SETTLEMENT_ENTRIES_F_REQUEST = requestF(GET_SETTLEMENT_ENTRIES)
export const GET_SETTLEMENT_ENTRIES_F_SUCCESS = successF(GET_SETTLEMENT_ENTRIES)
export const GET_SETTLEMENT_ENTRIES_F_FAILURE = failureF(GET_SETTLEMENT_ENTRIES)

export const GET_SETTLEMENT_GROUP_F_REQUEST = requestF(GET_SETTLEMENT_GROUP)
export const GET_SETTLEMENT_GROUP_F_SUCCESS = successF(GET_SETTLEMENT_GROUP)
export const GET_SETTLEMENT_GROUP_F_FAILURE = failureF(GET_SETTLEMENT_GROUP)

export const GET_SETTLEMENT_GROUP_SETTLEMENTS_F_REQUEST = requestF(GET_SETTLEMENT_GROUP_SETTLEMENTS)
export const GET_SETTLEMENT_GROUP_SETTLEMENTS_F_SUCCESS = successF(GET_SETTLEMENT_GROUP_SETTLEMENTS)
export const GET_SETTLEMENT_GROUP_SETTLEMENTS_F_FAILURE = failureF(GET_SETTLEMENT_GROUP_SETTLEMENTS)

export const GET_PAYOUT_PLAN_F_REQUEST = requestF(GET_PAYOUT_PLAN)
export const GET_PAYOUT_PLAN_F_SUCCESS = successF(GET_PAYOUT_PLAN)
export const GET_PAYOUT_PLAN_F_FAILURE = failureF(GET_PAYOUT_PLAN)

export const GET_ONBOARDING_FORM_DATA_F_REQUEST = requestF(GET_ONBOARDING_FORM_DATA)
export const GET_ONBOARDING_FORM_DATA_F_SUCCESS = successF(GET_ONBOARDING_FORM_DATA)
export const GET_ONBOARDING_FORM_DATA_F_FAILURE = failureF(GET_ONBOARDING_FORM_DATA)

export const GET_GUEST_ONBOARDING_FORM_DATA_F_REQUEST = requestF(GET_GUEST_ONBOARDING_FORM_DATA)
export const GET_GUEST_ONBOARDING_FORM_DATA_F_SUCCESS = successF(GET_GUEST_ONBOARDING_FORM_DATA)
export const GET_GUEST_ONBOARDING_FORM_DATA_F_FAILURE = failureF(GET_GUEST_ONBOARDING_FORM_DATA)

export const GET_GUEST_ONBOARDING_FORM_FILES_F_REQUEST = requestF(GET_GUEST_ONBOARDING_FORM_FILES)
export const GET_GUEST_ONBOARDING_FORM_FILES_F_SUCCESS = successF(GET_GUEST_ONBOARDING_FORM_FILES)
export const GET_GUEST_ONBOARDING_FORM_FILES_F_FAILURE = failureF(GET_GUEST_ONBOARDING_FORM_FILES)

export const GET_GUEST_ACCESS_FORM_F_REQUEST = requestF(GET_GUEST_ACCESS_FORM)
export const GET_GUEST_ACCESS_FORM_F_SUCCESS = successF(GET_GUEST_ACCESS_FORM)
export const GET_GUEST_ACCESS_FORM_F_FAILURE = failureF(GET_GUEST_ACCESS_FORM)

export const GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE_F_REQUEST = requestF(GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE)
export const GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE_F_SUCCESS = successF(GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE)
export const GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE_F_FAILURE = failureF(GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE)

export const GET_ONBOARDING_FORMS_F_REQUEST = requestF(GET_ONBOARDING_FORMS)
export const GET_ONBOARDING_FORMS_F_SUCCESS = successF(GET_ONBOARDING_FORMS)
export const GET_ONBOARDING_FORMS_F_FAILURE = failureF(GET_ONBOARDING_FORMS)

export const POST_ONBOARDING_FORM_F_REQUEST = requestF(POST_ONBOARDING_FORM)
export const POST_ONBOARDING_FORM_F_SUCCESS = successF(POST_ONBOARDING_FORM)
export const POST_ONBOARDING_FORM_F_FAILURE = failureF(POST_ONBOARDING_FORM)

export const POST_ONBOARDING_FORM_LINK_F_REQUEST = requestF(POST_ONBOARDING_FORM_LINK)
export const POST_ONBOARDING_FORM_LINK_F_SUCCESS = successF(POST_ONBOARDING_FORM_LINK)
export const POST_ONBOARDING_FORM_LINK_F_FAILURE = failureF(POST_ONBOARDING_FORM_LINK)

export const PATCH_ONBOARDING_FORM_DATA_F_REQUEST = requestF(PATCH_ONBOARDING_FORM_DATA)
export const PATCH_ONBOARDING_FORM_DATA_F_SUCCESS = successF(PATCH_ONBOARDING_FORM_DATA)
export const PATCH_ONBOARDING_FORM_DATA_F_FAILURE = failureF(PATCH_ONBOARDING_FORM_DATA)

export const POST_ONBOARDING_FORM_DATA_F_REQUEST = requestF(POST_ONBOARDING_FORM_DATA)
export const POST_ONBOARDING_FORM_DATA_F_SUCCESS = successF(POST_ONBOARDING_FORM_DATA)
export const POST_ONBOARDING_FORM_DATA_F_FAILURE = failureF(POST_ONBOARDING_FORM_DATA)

export const GET_HOSTED_FORM_TOKEN_VERIFICATION_F_REQUEST = requestF(GET_HOSTED_FORM_TOKEN_VERIFICATION)
export const GET_HOSTED_FORM_TOKEN_VERIFICATION_F_SUCCESS = successF(GET_HOSTED_FORM_TOKEN_VERIFICATION)
export const GET_HOSTED_FORM_TOKEN_VERIFICATION_F_FAILURE = failureF(GET_HOSTED_FORM_TOKEN_VERIFICATION)

export const GET_PAYOUT_PLANS_F_REQUEST = requestF(GET_PAYOUT_PLANS)
export const GET_PAYOUT_PLANS_F_SUCCESS = successF(GET_PAYOUT_PLANS)
export const GET_PAYOUT_PLANS_F_FAILURE = failureF(GET_PAYOUT_PLANS)

export const POST_PAYOUT_PLAN_F_REQUEST = requestF(POST_PAYOUT_PLAN)
export const POST_PAYOUT_PLAN_F_SUCCESS = successF(POST_PAYOUT_PLAN)
export const POST_PAYOUT_PLAN_F_FAILURE = failureF(POST_PAYOUT_PLAN)

export const POST_SUBSCRIPTION_SCHEDULE_F_REQUEST = requestF(POST_SUBSCRIPTION_SCHEDULE)
export const POST_SUBSCRIPTION_SCHEDULE_F_SUCCESS = successF(POST_SUBSCRIPTION_SCHEDULE)
export const POST_SUBSCRIPTION_SCHEDULE_F_FAILURE = failureF(POST_SUBSCRIPTION_SCHEDULE)

export const POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST = requestF(POST_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_SUCCESS = successF(POST_SUBSCRIPTION_SCHEDULE_AMOUNT)
export const POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_FAILURE = failureF(POST_SUBSCRIPTION_SCHEDULE_AMOUNT)

export const POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_REQUEST = requestF(POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT)
export const POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_SUCCESS = successF(POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT)
export const POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_FAILURE = failureF(POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT)

export const PATCH_PAYOUT_PLAN_F_REQUEST = requestF(PATCH_PAYOUT_PLAN)
export const PATCH_PAYOUT_PLAN_F_SUCCESS = successF(PATCH_PAYOUT_PLAN)
export const PATCH_PAYOUT_PLAN_F_FAILURE = failureF(PATCH_PAYOUT_PLAN)

export const PATCH_SETTLEMENT_F_REQUEST = requestF(PATCH_SETTLEMENT)
export const PATCH_SETTLEMENT_F_SUCCESS = successF(PATCH_SETTLEMENT)
export const PATCH_SETTLEMENT_F_FAILURE = failureF(PATCH_SETTLEMENT)

export const GET_CREDENTIAL_PERMISSIONS_F_REQUEST = requestF(GET_CREDENTIAL_PERMISSIONS)
export const GET_CREDENTIAL_PERMISSIONS_F_SUCCESS = successF(GET_CREDENTIAL_PERMISSIONS)
export const GET_CREDENTIAL_PERMISSIONS_F_FAILURE = failureF(GET_CREDENTIAL_PERMISSIONS)

export const GET_PLATFORMS_F_REQUEST = requestF(GET_PLATFORMS)
export const GET_PLATFORMS_F_SUCCESS = successF(GET_PLATFORMS)
export const GET_PLATFORMS_F_FAILURE = failureF(GET_PLATFORMS)

export const GET_NOTIFICATIONS_F_REQUEST = requestF(GET_NOTIFICATIONS)
export const GET_NOTIFICATIONS_F_SUCCESS = successF(GET_NOTIFICATIONS)
export const GET_NOTIFICATIONS_F_FAILURE = failureF(GET_NOTIFICATIONS)

export const GET_LATEST_NOTIFICATIONS_F_REQUEST = requestF(GET_LATEST_NOTIFICATIONS)
export const GET_LATEST_NOTIFICATIONS_F_SUCCESS = successF(GET_LATEST_NOTIFICATIONS)
export const GET_LATEST_NOTIFICATIONS_F_FAILURE = failureF(GET_LATEST_NOTIFICATIONS)

export const GET_ASSOCIATED_IDENTITIES_F_REQUEST = requestF(GET_ASSOCIATED_IDENTITIES)

export const GET_CURRENT_USER_F_REQUEST = requestF(GET_CURRENT_USER)

export const GET_FILE_F_REQUEST = requestF(GET_FILE)
export const GET_FILE_F_SUCCESS = successF(GET_FILE)
export const GET_FILE_F_FAILURE = failureF(GET_FILE)

export const GET_ACCESS_FORM_FILE_F_REQUEST = requestF(GET_ACCESS_FORM_FILE)
export const GET_ACCESS_FORM_FILE_F_SUCCESS = successF(GET_ACCESS_FORM_FILE)
export const GET_ACCESS_FORM_FILE_F_FAILURE = failureF(GET_ACCESS_FORM_FILE)

export const GET_FILES_F_REQUEST = requestF(GET_FILES)
export const GET_FILES_F_SUCCESS = successF(GET_FILES)
export const GET_FILES_F_FAILURE = failureF(GET_FILES)

export const GET_CHART_F_REQUEST = requestF(GET_CHART)
export const GET_CHART_F_SUCCESS = successF(GET_CHART)
export const GET_CHART_F_FAILURE = failureF(GET_CHART)

export const GET_CHARTS_F_REQUEST = requestF(GET_CHARTS)
export const GET_CHARTS_F_SUCCESS = successF(GET_CHARTS)
export const GET_CHARTS_F_FAILURE = failureF(GET_CHARTS)

export const GET_REPORTS_F_REQUEST = requestF(GET_REPORTS)
export const GET_CATEGORIZED_REPORTS_F_REQUEST = requestF(GET_CATEGORIZED_REPORTS)

export const PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_REQUEST = requestF(PATCH_APPLICATION_UNDERWRITING_SETTINGS)
export const PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_SUCCESS = successF(PATCH_APPLICATION_UNDERWRITING_SETTINGS)
export const PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_FAILURE = failureF(PATCH_APPLICATION_UNDERWRITING_SETTINGS)

export const POST_EXPORTS_F_REQUEST = requestF(POST_EXPORTS)

export const GET_NOTIFICATION_SETTINGS_F_REQUEST = requestF(GET_NOTIFICATION_SETTINGS)
export const GET_NOTIFICATION_SETTINGS_F_SUCCESS = successF(GET_NOTIFICATION_SETTINGS)
export const GET_NOTIFICATION_SETTINGS_F_FAILURE = failureF(GET_NOTIFICATION_SETTINGS)

export const GET_ROLE_MEMBERS_F_REQUEST = requestF(GET_ROLE_MEMBERS)
export const GET_ROLE_MEMBERS_F_SUCCESS = successF(GET_ROLE_MEMBERS)
export const GET_ROLE_MEMBERS_F_FAILURE = failureF(GET_ROLE_MEMBERS)

export const GET_UNMASKED_ITEM_F_REQUEST = requestF(GET_UNMASKED_ITEM)
export const GET_UNMASKED_ITEM_F_SUCCESS = successF(GET_UNMASKED_ITEM)
export const GET_UNMASKED_ITEM_F_FAILURE = failureF(GET_UNMASKED_ITEM)

export const POST_NOTIFICATION_SETTINGS_F_REQUEST = requestF(POST_NOTIFICATION_SETTINGS)
export const POST_NOTIFICATION_SETTINGS_F_SUCCESS = successF(POST_NOTIFICATION_SETTINGS)
export const POST_NOTIFICATION_SETTINGS_F_FAILURE = failureF(POST_NOTIFICATION_SETTINGS)

export const POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST = requestF(POST_NOTIFICATION_ACKNOWLEDGMENT)
export const POST_NOTIFICATION_ACKNOWLEDGMENT_F_SUCCESS = successF(POST_NOTIFICATION_ACKNOWLEDGMENT)
export const POST_NOTIFICATION_ACKNOWLEDGMENT_F_FAILURE = failureF(POST_NOTIFICATION_ACKNOWLEDGMENT)

export const GET_SETTLEMENTS_APPLICATIONS_F_REQUEST = requestF(GET_SETTLEMENTS_APPLICATIONS)
export const GET_SETTLEMENTS_APPLICATIONS_F_SUCCESS = successF(GET_SETTLEMENTS_APPLICATIONS)
export const GET_SETTLEMENTS_APPLICATIONS_F_FAILURE = failureF(GET_SETTLEMENTS_APPLICATIONS)

export const GET_MEMBER_F_REQUEST = requestF(GET_MEMBER)
export const GET_MEMBER_F_SUCCESS = successF(GET_MEMBER)
export const GET_MEMBER_F_FAILURE = failureF(GET_MEMBER)

export const GET_MEMBERS_F_REQUEST = requestF(GET_MEMBERS)
export const GET_MEMBERS_F_SUCCESS = successF(GET_MEMBERS)
export const GET_MEMBERS_F_FAILURE = failureF(GET_MEMBERS)

export const POST_MEMBER_F_REQUEST = requestF(POST_MEMBER)
export const POST_MEMBER_F_SUCCESS = successF(POST_MEMBER)
export const POST_MEMBER_F_FAILURE = failureF(POST_MEMBER)

export const PATCH_MEMBER_F_REQUEST = requestF(PATCH_MEMBER)
export const PATCH_MEMBER_F_SUCCESS = successF(PATCH_MEMBER)
export const PATCH_MEMBER_F_FAILURE = failureF(PATCH_MEMBER)

export const GET_ACCESS_FORM_F_REQUEST = requestF(GET_ACCESS_FORM)
export const GET_ACCESS_FORM_F_SUCCESS = successF(GET_ACCESS_FORM)
export const GET_ACCESS_FORM_F_FAILURE = failureF(GET_ACCESS_FORM)

export const GET_ACCESS_FORM_CONTRACT_F_REQUEST = requestF(GET_ACCESS_FORM_CONTRACT)
export const GET_ACCESS_FORM_CONTRACT_F_SUCCESS = successF(GET_ACCESS_FORM_CONTRACT)
export const GET_ACCESS_FORM_CONTRACT_F_FAILURE = failureF(GET_ACCESS_FORM_CONTRACT)

export const GET_ACCESS_FORMS_F_REQUEST = requestF(GET_ACCESS_FORMS)
export const GET_ACCESS_FORMS_F_SUCCESS = successF(GET_ACCESS_FORMS)
export const GET_ACCESS_FORMS_F_FAILURE = failureF(GET_ACCESS_FORMS)

export const GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST = requestF(GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS)

export const GET_FEE_PROFILE_F_REQUEST = requestF(GET_FEE_PROFILE)
export const GET_FEE_PROFILE_F_SUCCESS = successF(GET_FEE_PROFILE)
export const GET_FEE_PROFILE_F_FAILURE = failureF(GET_FEE_PROFILE)

export const GET_FEE_PROFILE_RULES_F_REQUEST = requestF(GET_FEE_PROFILE_RULES)
export const GET_FEE_PROFILE_RULES_F_SUCCESS = successF(GET_FEE_PROFILE_RULES)
export const GET_FEE_PROFILE_RULES_F_FAILURE = failureF(GET_FEE_PROFILE_RULES)

export const POST_FEE_PROFILE_RULES_F_REQUEST = requestF(POST_FEE_PROFILE_RULES)
export const POST_FEE_PROFILE_RULES_F_SUCCESS = successF(POST_FEE_PROFILE_RULES)
export const POST_FEE_PROFILE_RULES_F_FAILURE = failureF(POST_FEE_PROFILE_RULES)

export const PATCH_FEE_PROFILE_RULES_F_REQUEST = requestF(PATCH_FEE_PROFILE_RULES)
export const PATCH_FEE_PROFILE_RULES_F_SUCCESS = successF(PATCH_FEE_PROFILE_RULES)
export const PATCH_FEE_PROFILE_RULES_F_FAILURE = failureF(PATCH_FEE_PROFILE_RULES)

export const GET_FEE_PROFILE_SETTINGS_F_REQUEST = requestF(GET_FEE_PROFILE_SETTINGS)
export const GET_FEE_PROFILE_SETTINGS_F_SUCCESS = successF(GET_FEE_PROFILE_SETTINGS)
export const GET_FEE_PROFILE_SETTINGS_F_FAILURE = failureF(GET_FEE_PROFILE_SETTINGS)

export const POST_ACCESS_FORM_F_REQUEST = requestF(POST_ACCESS_FORM)
export const POST_ACCESS_FORM_F_SUCCESS = successF(POST_ACCESS_FORM)
export const POST_ACCESS_FORM_F_FAILURE = failureF(POST_ACCESS_FORM)

export const POST_SUBMIT_ACCESS_FORM_F_REQUEST = requestF(POST_SUBMIT_ACCESS_FORM)
export const POST_SUBMIT_ACCESS_FORM_F_SUCCESS = successF(POST_SUBMIT_ACCESS_FORM)
export const POST_SUBMIT_ACCESS_FORM_F_FAILURE = failureF(POST_SUBMIT_ACCESS_FORM)

export const POST_SUBMIT_GUEST_ACCESS_FORM_F_REQUEST = requestF(POST_SUBMIT_GUEST_ACCESS_FORM)
export const POST_SUBMIT_GUEST_ACCESS_FORM_F_SUCCESS = successF(POST_SUBMIT_GUEST_ACCESS_FORM)
export const POST_SUBMIT_GUEST_ACCESS_FORM_F_FAILURE = failureF(POST_SUBMIT_GUEST_ACCESS_FORM)

export const PATCH_ACCESS_FORM_F_REQUEST = requestF(PATCH_ACCESS_FORM)
export const PATCH_ACCESS_FORM_F_SUCCESS = successF(PATCH_ACCESS_FORM)
export const PATCH_ACCESS_FORM_F_FAILURE = failureF(PATCH_ACCESS_FORM)

export const PATCH_GUEST_ACCESS_FORM_F_REQUEST = requestF(PATCH_GUEST_ACCESS_FORM)
export const PATCH_GUEST_ACCESS_FORM_F_SUCCESS = successF(PATCH_GUEST_ACCESS_FORM)
export const PATCH_GUEST_ACCESS_FORM_F_FAILURE = failureF(PATCH_GUEST_ACCESS_FORM)

export const GET_UNDERWRITING_REVIEW_F_REQUEST = requestF(GET_UNDERWRITING_REVIEW)
export const GET_UNDERWRITING_REVIEW_F_SUCCESS = successF(GET_UNDERWRITING_REVIEW)
export const GET_UNDERWRITING_REVIEW_F_FAILURE = failureF(GET_UNDERWRITING_REVIEW)

export const GET_RECIPIENT_UNDERWRITING_REVIEWS_F_REQUEST = requestF(GET_RECIPIENT_UNDERWRITING_REVIEWS)
export const GET_RECIPIENT_UNDERWRITING_REVIEWS_F_SUCCESS = successF(GET_RECIPIENT_UNDERWRITING_REVIEWS)
export const GET_RECIPIENT_UNDERWRITING_REVIEWS_F_FAILURE = failureF(GET_RECIPIENT_UNDERWRITING_REVIEWS)

export const GET_UNDERWRITING_REPORT_F_REQUEST = requestF(GET_UNDERWRITING_REPORT)
export const GET_UNDERWRITING_REPORT_F_SUCCESS = successF(GET_UNDERWRITING_REPORT)
export const GET_UNDERWRITING_REPORT_F_FAILURE = failureF(GET_UNDERWRITING_REPORT)

export const PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST = requestF(PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS)
export const PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_SUCCESS = successF(PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS)
export const PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_FAILURE = failureF(PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS)

export const PATCH_ONBOARDING_FORM_BANK_DOCUMENT_F_REQUEST = requestF(PATCH_ONBOARDING_FORM_BANK_DOCUMENT)
export const PATCH_ONBOARDING_FORM_BANK_DOCUMENT_F_SUCCESS = successF(PATCH_ONBOARDING_FORM_BANK_DOCUMENT)
export const PATCH_ONBOARDING_FORM_BANK_DOCUMENT_F_FAILURE = failureF(PATCH_ONBOARDING_FORM_BANK_DOCUMENT)

export const GET_UNDERWRITING_REVIEWS_F_REQUEST = requestF(GET_UNDERWRITING_REVIEWS)
export const GET_UNDERWRITING_REVIEWS_F_SUCCESS = successF(GET_UNDERWRITING_REVIEWS)
export const GET_UNDERWRITING_REVIEWS_F_FAILURE = failureF(GET_UNDERWRITING_REVIEWS)

export const GET_MERCHANT_UNDERWRITING_REVIEW_F_REQUEST = requestF(GET_MERCHANT_UNDERWRITING_REVIEW)
export const GET_MERCHANT_UNDERWRITING_REVIEW_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEW)
export const GET_MERCHANT_UNDERWRITING_REVIEW_F_FAILURE = failureF(GET_MERCHANT_UNDERWRITING_REVIEW)

export const GET_MERCHANT_UNDERWRITING_REVIEWS_F_REQUEST = requestF(GET_MERCHANT_UNDERWRITING_REVIEWS)
export const GET_MERCHANT_UNDERWRITING_REVIEWS_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEWS)
export const GET_MERCHANT_UNDERWRITING_REVIEWS_F_FAILURE = failureF(GET_MERCHANT_UNDERWRITING_REVIEWS)

export const PATCH_UNDERWRITING_REVIEW_F_REQUEST = requestF(PATCH_UNDERWRITING_REVIEW)
export const PATCH_UNDERWRITING_REVIEW_F_SUCCESS = successF(PATCH_UNDERWRITING_REVIEW)
export const PATCH_UNDERWRITING_REVIEW_F_FAILURE = failureF(PATCH_UNDERWRITING_REVIEW)

export const DELETE_ACCESS_FORM_F_REQUEST = requestF(DELETE_ACCESS_FORM)
export const DELETE_ACCESS_FORM_F_SUCCESS = successF(DELETE_ACCESS_FORM)
export const DELETE_ACCESS_FORM_F_FAILURE = failureF(DELETE_ACCESS_FORM)

export const DELETE_API_KEY_F_REQUEST = requestF(DELETE_API_KEY)
export const DELETE_API_KEY_F_SUCCESS = successF(DELETE_API_KEY)
export const DELETE_API_KEY_F_FAILURE = failureF(DELETE_API_KEY)

export const GET_CHART_DELTA_F_REQUEST = requestF(GET_CHART_DELTA)
export const GET_CHART_DELTA_F_SUCCESS = successF(GET_CHART_DELTA)
export const GET_CHART_DELTA_F_FAILURE = failureF(GET_CHART_DELTA)

export const PATCH_DISPUTE_F_REQUEST = requestF(PATCH_DISPUTE)
export const PATCH_DISPUTE_F_SUCCESS = successF(PATCH_DISPUTE)
export const PATCH_DISPUTE_F_FAILURE = failureF(PATCH_DISPUTE)

export const PATCH_SUBSCRIPTION_PLAN_F_REQUEST = requestF(PATCH_SUBSCRIPTION_PLAN)
export const PATCH_SUBSCRIPTION_PLAN_F_SUCCESS = successF(PATCH_SUBSCRIPTION_PLAN)
export const PATCH_SUBSCRIPTION_PLAN_F_FAILURE = failureF(PATCH_SUBSCRIPTION_PLAN)

export const GET_DASHBOARD_CONTRACTS_F_REQUEST = requestF(GET_DASHBOARD_CONTRACTS)

export const GET_UNDERWRITING_PROFILE_F_REQUEST = requestF(GET_UNDERWRITING_PROFILE)
export const GET_UNDERWRITING_PROFILE_F_SUCCESS = successF(GET_UNDERWRITING_PROFILE)
export const GET_UNDERWRITING_PROFILE_F_FAILURE = failureF(GET_UNDERWRITING_PROFILE)

export const GET_UNDERWRITING_PROFILES_F_REQUEST = requestF(GET_UNDERWRITING_PROFILES)
export const GET_UNDERWRITING_PROFILES_F_SUCCESS = successF(GET_UNDERWRITING_PROFILES)
export const GET_UNDERWRITING_PROFILES_F_FAILURE = failureF(GET_UNDERWRITING_PROFILES)

export const PATCH_UNDERWRITING_PROFILE_F_REQUEST = requestF(PATCH_UNDERWRITING_PROFILE)
export const PATCH_UNDERWRITING_PROFILE_F_SUCCESS = successF(PATCH_UNDERWRITING_PROFILE)
export const PATCH_UNDERWRITING_PROFILE_F_FAILURE = failureF(PATCH_UNDERWRITING_PROFILE)

export const POST_UNDERWRITING_PROFILE_F_REQUEST = requestF(POST_UNDERWRITING_PROFILE)
export const POST_UNDERWRITING_PROFILE_F_SUCCESS = successF(POST_UNDERWRITING_PROFILE)
export const POST_UNDERWRITING_PROFILE_F_FAILURE = failureF(POST_UNDERWRITING_PROFILE)

export const POST_UNDERWRITING_RUN_SILENCE_F_REQUEST = requestF(POST_UNDERWRITING_RUN_SILENCE)
export const POST_UNDERWRITING_RUN_SILENCE_F_SUCCESS = successF(POST_UNDERWRITING_RUN_SILENCE)
export const POST_UNDERWRITING_RUN_SILENCE_F_FAILURE = failureF(POST_UNDERWRITING_RUN_SILENCE)

export const GET_ENTITY_CONFIGURATIONS_F_REQUEST = requestF(GET_ENTITY_CONFIGURATIONS)
export const GET_ENTITY_CONFIGURATIONS_F_SUCCESS = successF(GET_ENTITY_CONFIGURATIONS)
export const GET_ENTITY_CONFIGURATIONS_F_FAILURE = failureF(GET_ENTITY_CONFIGURATIONS)

export const POST_ENTITY_CONFIGURATION_F_REQUEST = requestF(POST_ENTITY_CONFIGURATION)
export const POST_ENTITY_CONFIGURATION_F_SUCCESS = successF(POST_ENTITY_CONFIGURATION)
export const POST_ENTITY_CONFIGURATION_F_FAILURE = failureF(POST_ENTITY_CONFIGURATION)

export const PATCH_ENTITY_CONFIGURATION_F_REQUEST = requestF(PATCH_ENTITY_CONFIGURATION)
export const PATCH_ENTITY_CONFIGURATION_F_SUCCESS = successF(PATCH_ENTITY_CONFIGURATION)
export const PATCH_ENTITY_CONFIGURATION_F_FAILURE = failureF(PATCH_ENTITY_CONFIGURATION)

export const POST_SUBMIT_ACCESS_FORM_SALES_REVIEW_F_REQUEST = requestF(POST_SUBMIT_ACCESS_FORM_SALES_REVIEW)
export const POST_SUBMIT_ACCESS_FORM_SALES_REVIEW_F_SUCCESS = successF(POST_SUBMIT_ACCESS_FORM_SALES_REVIEW)
export const POST_SUBMIT_ACCESS_FORM_SALES_REVIEW_F_FAILURE = failureF(POST_SUBMIT_ACCESS_FORM_SALES_REVIEW)

export const POST_SUBMIT_TO_UNDERWRITING_F_REQUEST = requestF(POST_SUBMIT_TO_UNDERWRITING)
export const POST_SUBMIT_TO_UNDERWRITING_F_SUCCESS = successF(POST_SUBMIT_TO_UNDERWRITING)
export const POST_SUBMIT_TO_UNDERWRITING_F_FAILURE = failureF(POST_SUBMIT_TO_UNDERWRITING)

export const GET_BALANCE_ADJUSTMENTS_F_REQUEST = requestF(GET_BALANCE_ADJUSTMENTS)
export const GET_BALANCE_ADJUSTMENTS_F_SUCCESS = successF(GET_BALANCE_ADJUSTMENTS)
export const GET_BALANCE_ADJUSTMENTS_F_FAILURE = failureF(GET_BALANCE_ADJUSTMENTS)

export const GET_MERCHANT_TRANSFERS_APPLICATION_F_REQUEST = requestF(GET_MERCHANT_TRANSFERS_APPLICATION)
export const GET_MERCHANT_TRANSFERS_APPLICATION_F_SUCCESS = successF(GET_MERCHANT_TRANSFERS_APPLICATION)
export const GET_MERCHANT_TRANSFERS_APPLICATION_F_FAILURE = failureF(GET_MERCHANT_TRANSFERS_APPLICATION)

export const GET_SUBSCRIPTION_PLANS_F_REQUEST = requestF(GET_SUBSCRIPTION_PLANS)
export const GET_SUBSCRIPTION_PLANS_F_SUCCESS = successF(GET_SUBSCRIPTION_PLANS)
export const GET_SUBSCRIPTION_PLANS_F_FAILURE = failureF(GET_SUBSCRIPTION_PLANS)

export const GET_SUBSCRIPTION_PLANS_MERCHANT_F_SUCCESS = successF(GET_SUBSCRIPTION_PLANS_MERCHANT)

export const GET_SUBSCRIPTION_PLAN_F_REQUEST = requestF(GET_SUBSCRIPTION_PLAN)
export const GET_SUBSCRIPTION_PLAN_F_SUCCESS = successF(GET_SUBSCRIPTION_PLAN)
export const GET_SUBSCRIPTION_PLAN_F_FAILURE = failureF(GET_SUBSCRIPTION_PLAN)

export const GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY)

export const GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION_F_SUCCESS = successF(GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION)

export const GET_TRANSFER_ATTEMPT_PAYOUT_LINK_F_SUCCESS = successF(GET_TRANSFER_ATTEMPT_PAYOUT_LINK)

export const POST_RECEIPT_F_REQUEST = requestF(POST_RECEIPT)
export const POST_RECEIPT_F_SUCCESS = successF(POST_RECEIPT)
export const POST_RECEIPT_F_FAILURE = failureF(POST_RECEIPT)

export const GET_RECEIPT_F_REQUEST = requestF(GET_RECEIPT)
export const GET_RECEIPT_F_SUCCESS = successF(GET_RECEIPT)
export const GET_RECEIPT_F_FAILURE = failureF(GET_RECEIPT)

export const GET_RECEIPTS_F_REQUEST = requestF(GET_RECEIPTS)
export const GET_RECEIPTS_F_SUCCESS = successF(GET_RECEIPTS)
export const GET_RECEIPTS_F_FAILURE = failureF(GET_RECEIPTS)

export const GET_RECEIPT_DELIVERY_ATTEMPTS_F_REQUEST = requestF(GET_RECEIPT_DELIVERY_ATTEMPTS)
export const GET_RECEIPT_DELIVERY_ATTEMPTS_F_SUCCESS = successF(GET_RECEIPT_DELIVERY_ATTEMPTS)
export const GET_RECEIPT_DELIVERY_ATTEMPTS_F_FAILURE = failureF(GET_RECEIPT_DELIVERY_ATTEMPTS)

export const POST_RECEIPT_DELIVERY_ATTEMPTS_F_REQUEST = requestF(POST_RECEIPT_DELIVERY_ATTEMPTS)
export const POST_RECEIPT_DELIVERY_ATTEMPTS_F_SUCCESS = successF(POST_RECEIPT_DELIVERY_ATTEMPTS)
export const POST_RECEIPT_DELIVERY_ATTEMPTS_F_FAILURE = failureF(POST_RECEIPT_DELIVERY_ATTEMPTS)

export const GET_MONITORING_ALERTS_F_REQUEST = requestF(GET_MONITORING_ALERTS)
export const GET_MONITORING_ALERTS_F_SUCCESS = successF(GET_MONITORING_ALERTS)
export const GET_MONITORING_ALERTS_F_FAILURE = failureF(GET_MONITORING_ALERTS)

export const PATCH_MONITORING_ALERT_F_REQUEST = requestF(PATCH_MONITORING_ALERT)
export const PATCH_MONITORING_ALERT_F_SUCCESS = successF(PATCH_MONITORING_ALERT)
export const PATCH_MONITORING_ALERT_F_FAILURE = failureF(PATCH_MONITORING_ALERT)

export const GET_MONITORING_ALERT_F_REQUEST = requestF(GET_MONITORING_ALERT)
export const GET_MONITORING_ALERT_F_SUCCESS = successF(GET_MONITORING_ALERT)
export const GET_MONITORING_ALERT_F_FAILURE = failureF(GET_MONITORING_ALERT)

export const PATCH_MONITORING_ALERTS_F_REQUEST = requestF(PATCH_MONITORING_ALERTS)
export const PATCH_MONITORING_ALERTS_F_SUCCESS = successF(PATCH_MONITORING_ALERTS)
export const PATCH_MONITORING_ALERTS_F_FAILURE = failureF(PATCH_MONITORING_ALERTS)

export const GET_INSTANT_PAYOUTS_F_REQUEST = requestF(GET_INSTANT_PAYOUTS)
export const GET_INSTANT_PAYOUTS_F_SUCCESS = successF(GET_INSTANT_PAYOUTS)
export const GET_INSTANT_PAYOUTS_F_FAILURE = failureF(GET_INSTANT_PAYOUTS)

export const GET_INSTANT_PAYOUT_MERCHANT_F_SUCCESS = successF(GET_INSTANT_PAYOUTS_MERCHANT)

export const GET_INSTANT_PAYOUT_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_INSTANT_PAYOUTS_PAYMENT_INSTRUMENT)

export const GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY_F_SUCCESS = successF(GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY)

export const GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY_F_SUCCESS = successF(GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY)

export const GET_INSTANT_PAYOUT_F_REQUEST = requestF(GET_INSTANT_PAYOUT)
export const GET_INSTANT_PAYOUT_F_SUCCESS = successF(GET_INSTANT_PAYOUT)
export const GET_INSTANT_PAYOUT_F_FAILURE = failureF(GET_INSTANT_PAYOUT)

// TODO: update this
// related API object dispatches
export const GET_REVIEW_QUEUE_SETTLEMENTS_F_SUCCESS = successF(GET_REVIEW_QUEUE_SETTLEMENTS)
export const GET_REVIEW_QUEUE_MERCHANTS_F_SUCCESS = successF(GET_REVIEW_QUEUE_MERCHANTS)
export const GET_REVIEW_QUEUE_APPLICATION_F_SUCCESS = successF(GET_REVIEW_QUEUE_APPLICATION)
export const GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT_F_SUCCESS = successF(GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT)
export const GET_REVIEW_QUEUE_MERCHANTS_IDENTITY_F_SUCCESS = successF(GET_REVIEW_QUEUE_MERCHANTS_IDENTITY)
export const GET_SETTLEMENTS_MERCHANT_F_SUCCESS = successF(GET_SETTLEMENTS_MERCHANT)
export const GET_DISPUTE_TRANSFER_F_SUCCESS = successF(GET_DISPUTE_TRANSFER)

export const GET_DISPUTE_APPLICATION_F_SUCCESS = successF(GET_DISPUTE_APPLICATION)

export const GET_TRANSFERS_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_TRANSFERS_PAYMENT_INSTRUMENT)
export const GET_TRANSFER_APPLICATION_F_SUCCESS = successF(GET_TRANSFER_APPLICATION)
export const GET_AUTHORIZATIONS_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_AUTHORIZATIONS_MERCHANT_IDENTITY)
export const GET_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_TRANSFERS_MERCHANT_IDENTITY)
export const GET_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_MERCHANT_IDENTITY)
export const GET_SETTLEMENTS_IDENTITY_F_SUCCESS = successF(GET_SETTLEMENTS_IDENTITY)
export const GET_APPLICATION_SETTLEMENTS_IDENTITY_F_SUCCESS = successF(GET_APPLICATION_SETTLEMENTS_IDENTITY)
export const GET_FUNDING_TRANSFERS_RELATED_TRANSFER_F_SUCCESS = successF(GET_FUNDING_TRANSFERS_RELATED_TRANSFER)
export const GET_SETTLEMENT_TRANSFERS_RELATED_TRANSFER_F_SUCCESS = successF(GET_SETTLEMENT_TRANSFERS_RELATED_TRANSFER)
export const GET_SETTLEMENT_ENTRIES_FEE_F_SUCCESS = successF(GET_SETTLEMENT_ENTRIES_FEE)
export const GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS = successF(GET_SETTLEMENT_ENTRIES_TRANSFER)
export const GET_SETTLEMENT_TRANSFER_FEES_F_SUCCESS = successF(GET_SETTLEMENT_TRANSFER_FEES)
export const GET_TRANSFER_FEES_SETTLEMENT_ENTRIES_F_SUCCESS = successF(GET_TRANSFER_FEES_SETTLEMENT_ENTRIES)
export const GET_SETTLEMENT_TRANSFER_FEE_COUNTS_F_SUCCESS = successF(GET_SETTLEMENT_TRANSFER_FEE_COUNTS)
export const GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT)
export const GET_AUTHORIZATIONS_APPLICATION_F_SUCCESS = successF(GET_AUTHORIZATIONS_APPLICATION)
export const GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS_F_SUCCESS = successF(GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS)
export const GET_FEES_MERCHANT_F_SUCCESS = successF(GET_FEES_MERCHANT)

export const GET_ONBOARDING_FORMS_APPLICATION_F_SUCCESS = successF(GET_ONBOARDING_FORMS_APPLICATION)

export const GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA_F_SUCCESS = successF(GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA)

export const GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS_F_SUCCESS = successF(GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS)
export const GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT_F_SUCCESS = successF(GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT)
export const GET_RELATED_APPLICATION_F_SUCCESS = successF(GET_RELATED_APPLICATION)
export const GET_RELATED_IDENTITY_F_SUCCESS = successF(GET_RELATED_IDENTITY)
export const GET_RELATED_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_RELATED_PAYMENT_INSTRUMENT)
export const GET_ROLE_APPLICATION_F_SUCCESS = successF(GET_ROLE_APPLICATION)

// Misc.
export const POST_MASS_SETTLEMENT_APPROVAL_BATCH_F_SUCCESS = 'POST_MASS_SETTLEMENT_APPROVAL_BATCH_F_SUCCESS'
export const POST_MASS_SETTLEMENT_APPROVAL_F_REQUEST = requestF(POST_MASS_SETTLEMENT_APPROVAL)
export const POST_MASS_SETTLEMENT_APPROVAL_F_SUCCESS = successF(POST_MASS_SETTLEMENT_APPROVAL)
export const POST_MASS_SETTLEMENT_APPROVAL_F_CANCEL = cancelF(POST_MASS_SETTLEMENT_APPROVAL)
export const POST_MASS_SETTLEMENT_APPROVAL_F_CANCELLED = cancelledF(POST_MASS_SETTLEMENT_APPROVAL)

export const POST_IDENTITY_UPLOAD_BATCH = 'POST_IDENTITY_UPLOAD_BATCH'
export const POST_IDENTITY_UPLOAD_BATCH_F_SUCCESS = successF(POST_IDENTITY_UPLOAD_BATCH)
export const POST_IDENTITY_UPLOAD_BATCH_F_FAILURE = failureF(POST_IDENTITY_UPLOAD_BATCH)
export const POST_IDENTITY_UPLOAD_F_REQUEST = requestF(POST_IDENTITY_UPLOAD)
export const POST_IDENTITY_UPLOAD_F_SUCCESS = successF(POST_IDENTITY_UPLOAD)
export const POST_IDENTITY_UPLOAD_F_FAILURE = failureF(POST_IDENTITY_UPLOAD)
export const POST_IDENTITY_UPLOAD_F_CANCEL = cancelF(POST_IDENTITY_UPLOAD)
export const POST_IDENTITY_UPLOAD_F_CANCELLED = cancelledF(POST_IDENTITY_UPLOAD)
export const CLEAR_IDENTITY_UPLOADER_REDUCER = 'CLEAR_IDENTITY_UPLOADER_REDUCER'
export const CLEAR_IDENTITY_UPLOADER_REDUCER_F_REQUEST = requestF(CLEAR_IDENTITY_UPLOADER_REDUCER)

export const CLOSE_SETTLEMENTS_F_REQUEST = requestF(CLOSE_SETTLEMENTS)
export const CLOSE_SETTLEMENTS_F_SUCCESS = successF(CLOSE_SETTLEMENTS)
export const CLOSE_SETTLEMENTS_F_FAILURE = failureF(CLOSE_SETTLEMENTS)

export const GET_DISPUTES_WIDGET_F_REQUEST = requestF(GET_DISPUTES_WIDGET)
export const GET_TRANSFERS_WIDGET_F_REQUEST = requestF(GET_TRANSFERS_WIDGET)
export const GET_AUTHORIZATIONS_WIDGET_F_REQUEST = requestF(GET_AUTHORIZATIONS_WIDGET)
export const GET_REVIEW_QUEUE_WIDGET_F_REQUEST = requestF(GET_REVIEW_QUEUE_WIDGET)
export const GET_IDENTITIES_WIDGET_F_REQUEST = requestF(GET_IDENTITIES_WIDGET)
export const GET_MERCHANTS_WIDGET_F_REQUEST = requestF(GET_MERCHANTS_WIDGET)
export const GET_PAYMENT_INSTRUMENTS_WIDGET_F_REQUEST = requestF(GET_PAYMENT_INSTRUMENTS_WIDGET)

export const GET_DISPUTES_WIDGET_F_SUCCESS = successF(GET_DISPUTES_WIDGET)
export const GET_TRANSFERS_WIDGET_F_SUCCESS = successF(GET_TRANSFERS_WIDGET)
export const GET_AUTHORIZATIONS_WIDGET_F_SUCCESS = successF(GET_AUTHORIZATIONS_WIDGET)
export const GET_REVIEW_QUEUE_WIDGET_F_SUCCESS = successF(GET_REVIEW_QUEUE_WIDGET)
export const GET_IDENTITIES_WIDGET_F_SUCCESS = successF(GET_IDENTITIES_WIDGET)
export const GET_MERCHANTS_WIDGET_F_SUCCESS = successF(GET_MERCHANTS_WIDGET)
export const GET_PAYMENT_INSTRUMENTS_WIDGET_F_SUCCESS = successF(GET_PAYMENT_INSTRUMENTS_WIDGET)

export const POST_MERCHANT_IDENTITY_F_REQUEST = requestF(POST_MERCHANT_IDENTITY)
export const POST_MERCHANT_IDENTITY_F_SUCCESS = successF(POST_MERCHANT_IDENTITY)
export const POST_MERCHANT_IDENTITY_F_FAILURE = failureF(POST_MERCHANT_IDENTITY)

export const PATCH_MERCHANT_IDENTITY_F_REQUEST = requestF(PATCH_MERCHANT_IDENTITY)
export const PATCH_MERCHANT_IDENTITY_F_SUCCESS = successF(PATCH_MERCHANT_IDENTITY)

export const GET_DASHBOARD_CUSTOMIZATION_F_REQUEST = requestF(GET_DASHBOARD_CUSTOMIZATION)
export const GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST = requestF(GET_DASHBOARD_CUSTOMIZATIONS)

export const GET_GUEST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST = requestF(GET_GUEST_DASHBOARD_CUSTOMIZATIONS)

export const POST_DASHBOARD_CUSTOMIZATIONS_F_REQUEST = requestF(POST_DASHBOARD_CUSTOMIZATIONS)
export const POST_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS = successF(POST_DASHBOARD_CUSTOMIZATIONS)
export const POST_DASHBOARD_CUSTOMIZATIONS_F_FAILURE = failureF(POST_DASHBOARD_CUSTOMIZATIONS)

export const GET_WEBHOOKS_F_REQUEST = requestF(GET_WEBHOOKS)
export const GET_WEBHOOKS_F_SUCCESS = successF(GET_WEBHOOKS)
export const GET_WEBHOOKS_F_FAILURE = failureF(GET_WEBHOOKS)

export const GET_WEBHOOK_LOGS_F_REQUEST = requestF(GET_WEBHOOK_LOGS)
export const GET_WEBHOOK_LOGS_F_SUCCESS = successF(GET_WEBHOOK_LOGS)

export const GET_WEBHOOKS_WITH_STOPPED_EVENTS_F_REQUEST = requestF(GET_WEBHOOKS_WITH_STOPPED_EVENTS)

export const GET_WEBHOOK_F_REQUEST = requestF(GET_WEBHOOK)
export const GET_WEBHOOK_F_SUCCESS = successF(GET_WEBHOOK)
export const GET_WEBHOOK_F_FAILURE = failureF(GET_WEBHOOK)

export const GET_WEBHOOK_LOG_F_REQUEST = requestF(GET_WEBHOOK_LOG)

export const POST_WEBHOOKS_F_REQUEST = requestF(POST_WEBHOOKS)
export const POST_WEBHOOKS_F_SUCCESS = successF(POST_WEBHOOKS)
export const POST_WEBHOOKS_F_FAILURE = failureF(POST_WEBHOOKS)

export const PATCH_WEBHOOK_F_REQUEST = requestF(PATCH_WEBHOOK)
export const PATCH_WEBHOOK_F_SUCCESS = successF(PATCH_WEBHOOK)
export const PATCH_WEBHOOK_F_FAILURE = failureF(PATCH_WEBHOOK)

export const PATCH_DASHBOARD_CUSTOMIZATIONS_F_REQUEST = requestF(PATCH_DASHBOARD_CUSTOMIZATIONS)
export const PATCH_DASHBOARD_CUSTOMIZATIONS_F_SUCCESS = successF(PATCH_DASHBOARD_CUSTOMIZATIONS)
export const PATCH_DASHBOARD_CUSTOMIZATIONS_F_FAILURE = failureF(PATCH_DASHBOARD_CUSTOMIZATIONS)

export const PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST = requestF(PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_SUCCESS = successF(PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)
export const PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_FAILURE = failureF(PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING)

export const GET_PAYMENT_METHOD_CONFIGURATION_F_REQUEST = requestF(GET_PAYMENT_METHOD_CONFIGURATION)
export const GET_PAYMENT_METHOD_CONFIGURATION_F_SUCCESS = successF(GET_PAYMENT_METHOD_CONFIGURATION)
export const GET_PAYMENT_METHOD_CONFIGURATION_F_FAILURE = failureF(GET_PAYMENT_METHOD_CONFIGURATION)

export const POST_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST = requestF(POST_PAYMENT_METHOD_CONFIGURATIONS)
export const POST_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS = successF(POST_PAYMENT_METHOD_CONFIGURATIONS)
export const POST_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE = failureF(POST_PAYMENT_METHOD_CONFIGURATIONS)

export const PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST = requestF(PATCH_PAYMENT_METHOD_CONFIGURATIONS)
export const PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_SUCCESS = successF(PATCH_PAYMENT_METHOD_CONFIGURATIONS)
export const PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_FAILURE = failureF(PATCH_PAYMENT_METHOD_CONFIGURATIONS)

export const GET_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST = requestF(GET_CERTIFICATE_SIGNING_REQUESTS)
export const GET_CERTIFICATE_SIGNING_REQUESTS_F_SUCCESS = successF(GET_CERTIFICATE_SIGNING_REQUESTS)
export const GET_CERTIFICATE_SIGNING_REQUESTS_F_FAILURE = failureF(GET_CERTIFICATE_SIGNING_REQUESTS)

export const POST_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST = requestF(POST_CERTIFICATE_SIGNING_REQUESTS)
export const POST_CERTIFICATE_SIGNING_REQUESTS_F_SUCCESS = successF(POST_CERTIFICATE_SIGNING_REQUESTS)
export const POST_CERTIFICATE_SIGNING_REQUESTS_F_FAILURE = failureF(POST_CERTIFICATE_SIGNING_REQUESTS)

export const GET_MERCHANT_APPLICATION_F_REQUEST = requestF(GET_MERCHANT_APPLICATION)
export const GET_MERCHANT_APPLICATION_F_SUCCESS = successF(GET_MERCHANT_APPLICATION)
export const GET_MERCHANT_APPLICATION_F_FAILURE = failureF(GET_MERCHANT_APPLICATION)

export const GET_MERCHANT_VERIFICATIONS_F_REQUEST = requestF(GET_MERCHANT_VERIFICATIONS)

export const GET_MERCHANT_VERIFICATION_F_REQUEST = requestF(GET_MERCHANT_VERIFICATION)
export const GET_MERCHANT_VERIFICATION_F_SUCCESS = successF(GET_MERCHANT_VERIFICATION)
export const GET_MERCHANT_VERIFICATION_F_FAILURE = failureF(GET_MERCHANT_VERIFICATION)

export const POST_CERTIFICATE_SIGNING_REQUEST_F_REQUEST = requestF(POST_CERTIFICATE_SIGNING_REQUEST)

export const GET_API_LOGS_F_REQUEST = requestF(GET_API_LOGS)
export const GET_API_LOGS_F_SUCCESS = successF(GET_API_LOGS)

export const GET_API_LOG_F_REQUEST = requestF(GET_API_LOG)

export const GET_EXPORTS_F_REQUEST = requestF(GET_EXPORTS)

export const POST_DASHBOARD_V2_EMAILS_F_REQUEST = requestF(POST_DASHBOARD_V2_EMAILS)
export const POST_DASHBOARD_V2_EMAILS_F_SUCCESS = successF(POST_DASHBOARD_V2_EMAILS)
export const POST_DASHBOARD_V2_EMAILS_F_FAILURE = failureF(POST_DASHBOARD_V2_EMAILS)

export const PATCH_MEMBERSHIP_F_REQUEST = requestF(PATCH_MEMBERSHIP)
export const PATCH_MEMBERSHIP_F_SUCCESS = successF(PATCH_MEMBERSHIP)
export const PATCH_MEMBERSHIP_F_FAILURE = failureF(PATCH_MEMBERSHIP)

export const POST_CHANGE_PASSWORD_F_REQUEST = requestF(POST_CHANGE_PASSWORD)
export const POST_CHANGE_PASSWORD_F_SUCCESS = successF(POST_CHANGE_PASSWORD)
export const POST_CHANGE_PASSWORD_F_FAILURE = failureF(POST_CHANGE_PASSWORD)

export const PATCH_DASHBOARD_USER_F_REQUEST = requestF(PATCH_DASHBOARD_USER)
export const PATCH_DASHBOARD_USER_F_SUCCESS = successF(PATCH_DASHBOARD_USER)
export const PATCH_DASHBOARD_USER_F_FAILURE = failureF(PATCH_DASHBOARD_USER)

export const GET_MEMBER_ROLE_F_SUCCESS = successF(GET_MEMBER_ROLE)

export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_REQUEST = requestF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_FAILURE = failureF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES)

export const GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_REQUEST = requestF(GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS = successF(GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES)
export const GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_FAILURE = failureF(GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES)

export const GET_HOME_CHARTS_F_REQUEST = requestF(GET_HOME_CHARTS)
export const GET_HOME_CHARTS_F_SUCCESS = successF(GET_HOME_CHARTS)
export const GET_HOME_CHARTS_F_FAILURE = failureF(GET_HOME_CHARTS)

export const GET_TRANSACTION_CHARTS_F_REQUEST = requestF(GET_TRANSACTION_CHARTS)
export const GET_TRANSACTION_CHARTS_F_SUCCESS = successF(GET_TRANSACTION_CHARTS)
export const GET_TRANSACTION_CHARTS_F_FAILURE = failureF(GET_TRANSACTION_CHARTS)


export const GET_EXCEPTION_CHARTS_F_REQUEST = requestF(GET_EXCEPTION_CHARTS)
export const GET_EXCEPTION_CHARTS_F_SUCCESS = successF(GET_EXCEPTION_CHARTS)
export const GET_EXCEPTION_CHARTS_F_FAILURE = failureF(GET_EXCEPTION_CHARTS)

export const GET_MERCHANT_CHARTS_F_REQUEST = requestF(GET_MERCHANT_CHARTS)
export const GET_MERCHANT_CHARTS_F_SUCCESS = successF(GET_MERCHANT_CHARTS)
export const GET_MERCHANT_CHARTS_F_FAILURE = failureF(GET_MERCHANT_CHARTS)

export const GET_UNDERWRITING_CHARTS_F_REQUEST = requestF(GET_UNDERWRITING_CHARTS)
export const GET_UNDERWRITING_CHARTS_F_SUCCESS = successF(GET_UNDERWRITING_CHARTS)
export const GET_UNDERWRITING_CHARTS_F_FAILURE = failureF(GET_UNDERWRITING_CHARTS)

export const POST_DASHBOARD_USER_MFA_TOKEN_F_REQUEST = requestF(POST_DASHBOARD_USER_MFA_TOKEN)
export const POST_DASHBOARD_USER_MFA_TOKEN_F_SUCCESS = successF(POST_DASHBOARD_USER_MFA_TOKEN)
export const POST_DASHBOARD_USER_MFA_TOKEN_F_FAILURE = failureF(POST_DASHBOARD_USER_MFA_TOKEN)

export const POST_DASHBOARD_USER_MFA_ASSOCIATE_F_REQUEST = requestF(POST_DASHBOARD_USER_MFA_ASSOCIATE)
export const POST_DASHBOARD_USER_MFA_ASSOCIATE_F_SUCCESS = successF(POST_DASHBOARD_USER_MFA_ASSOCIATE)
export const POST_DASHBOARD_USER_MFA_ASSOCIATE_F_FAILURE = failureF(POST_DASHBOARD_USER_MFA_ASSOCIATE)

export const POST_DASHBOARD_USER_MFA_VERIFY_F_REQUEST = requestF(POST_DASHBOARD_USER_MFA_VERIFY)
export const POST_DASHBOARD_USER_MFA_VERIFY_F_SUCCESS = successF(POST_DASHBOARD_USER_MFA_VERIFY)
export const POST_DASHBOARD_USER_MFA_VERIFY_F_FAILURE = failureF(POST_DASHBOARD_USER_MFA_VERIFY)

export const GET_DASHBOARD_USER_MFA_AUTHENTICATORS_F_REQUEST = requestF(GET_DASHBOARD_USER_MFA_AUTHENTICATORS)
export const GET_DASHBOARD_USER_MFA_AUTHENTICATORS_F_SUCCESS = successF(GET_DASHBOARD_USER_MFA_AUTHENTICATORS)
export const GET_DASHBOARD_USER_MFA_AUTHENTICATORS_F_FAILURE = failureF(GET_DASHBOARD_USER_MFA_AUTHENTICATORS)

export const POST_DASHBOARD_USER_MFA_RESET_F_REQUEST = requestF(POST_DASHBOARD_USER_MFA_RESET)
export const POST_DASHBOARD_USER_MFA_RESET_F_SUCCESS = successF(POST_DASHBOARD_USER_MFA_RESET)
export const POST_DASHBOARD_USER_MFA_RESET_F_FAILURE = failureF(POST_DASHBOARD_USER_MFA_RESET)

export const GET_SPLIT_TRANSFERS_F_REQUEST = requestF(GET_SPLIT_TRANSFERS)
export const GET_SPLIT_TRANSFERS_F_SUCCESS = successF(GET_SPLIT_TRANSFERS)

export const GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY_F_REQUEST = requestF(GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY)
export const GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY)

export const GET_SPLIT_TRANSFERS_APPLICATION_F_REQUEST = requestF(GET_SPLIT_TRANSFERS_APPLICATION)
export const GET_SPLIT_TRANSFERS_APPLICATION_F_SUCCESS = successF(GET_SPLIT_TRANSFERS_APPLICATION)

export const GET_SPLIT_TRANSFERS_MERCHANT_F_REQUEST = requestF(GET_SPLIT_TRANSFERS_MERCHANT)
export const GET_SPLIT_TRANSFERS_MERCHANT_F_SUCCESS = successF(GET_SPLIT_TRANSFERS_MERCHANT)

export const GET_SPLIT_TRANSFER_F_REQUEST = requestF(GET_SPLIT_TRANSFER)

export const GET_UNDERWRITING_SUBJECT_EVENT_F_REQUEST = requestF(GET_UNDERWRITING_SUBJECT_EVENT)

export const GET_TRANSFER_REVERSALS_F_REQUEST = requestF(GET_TRANSFER_REVERSALS)
export const GET_TRANSFER_REVERSALS_F_SUCCESS = successF(GET_TRANSFER_REVERSALS)
export const GET_TRANSFER_DISPUTES_F_SUCCESS = successF(GET_TRANSFER_DISPUTES)

export const GET_TRANSFER_REVERSALS_MERCHANT_IDENTITY_F_SUCCESS = successF(TRANSFER_REVERSALS_MERCHANT_IDENTITY)
export const GET_TRANSFER_REVERSALS_APPLICATION_F_SUCCESS = successF(TRANSFER_REVERSALS_APPLICATION)
export const GET_TRANSFER_DISPUTES_MERCHANT_IDENTITY_F_SUCCESS = successF(TRANSFER_DISPUTES_MERCHANT_IDENTITY)
export const GET_TRANSFER_DISPUTES_APPLICATION_F_SUCCESS = successF(TRANSFER_DISPUTES_APPLICATION)

export const GET_APPLICATION_BALANCES_F_REQUEST = requestF(GET_APPLICATION_BALANCES)
export const GET_APPLICATION_BALANCES_F_SUCCESS = successF(GET_APPLICATION_BALANCES)
export const GET_APPLICATION_BALANCES_F_FAILURE = failureF(GET_APPLICATION_BALANCES)

export const GET_CURRENT_USAGES_F_REQUEST = requestF(GET_CURRENT_USAGES)
export const GET_CURRENT_USAGES_F_SUCCESS = successF(GET_CURRENT_USAGES)
export const GET_CURRENT_USAGES_F_FAILURE = failureF(GET_CURRENT_USAGES)

export const GET_RECIPIENT_IDENTITIES_F_REQUEST = requestF(GET_RECIPIENT_IDENTITIES)
export const GET_RECIPIENT_IDENTITIES_F_SUCCESS = successF(GET_RECIPIENT_IDENTITIES)
export const GET_RECIPIENT_IDENTITIES_F_FAILURE = failureF(GET_RECIPIENT_IDENTITIES)

export const GET_RECIPIENT_IDENTITY_MERCHANTS_F_REQUEST = requestF(GET_RECIPIENT_IDENTITY_MERCHANTS)
export const GET_RECIPIENT_IDENTITY_MERCHANTS_F_SUCCESS = successF(GET_RECIPIENT_IDENTITY_MERCHANTS)
export const GET_RECIPIENT_IDENTITY_MERCHANTS_F_FAILURE = failureF(GET_RECIPIENT_IDENTITY_MERCHANTS)

export const GET_DISBURSEMENT_RULES_F_REQUEST = requestF(GET_DISBURSEMENT_RULES)

export const GET_BALANCE_ENTRIES_F_REQUEST = requestF(GET_BALANCE_ENTRIES)
export const GET_BALANCE_ENTRIES_F_SUCCESS = successF(GET_BALANCE_ENTRIES)
export const GET_BALANCE_ENTRIES_F_FAILURE = failureF(GET_BALANCE_ENTRIES)

export const GET_BALANCE_ENTRIES_TRANSFER_F_REQUEST = requestF(GET_BALANCE_ENTRIES_TRANSFER)
export const GET_BALANCE_ENTRIES_TRANSFER_F_SUCCESS = successF(GET_BALANCE_ENTRIES_TRANSFER)
export const GET_BALANCE_ENTRIES_TRANSFER_F_FAILURE = failureF(GET_BALANCE_ENTRIES_TRANSFER)

export const GET_BALANCE_ENTRIES_APPLICATION_F_REQUEST = requestF(GET_BALANCE_ENTRIES_APPLICATION)
export const GET_BALANCE_ENTRIES_APPLICATION_F_SUCCESS = successF(GET_BALANCE_ENTRIES_APPLICATION)
export const GET_BALANCE_ENTRIES_APPLICATION_F_FAILURE = failureF(GET_BALANCE_ENTRIES_APPLICATION)

export const POST_DISBURSEMENT_RULES_F_REQUEST = requestF(POST_DISBURSEMENT_RULES)
export const POST_DISBURSEMENT_RULES_F_SUCCESS = successF(POST_DISBURSEMENT_RULES)

export const PATCH_DISBURSEMENT_RULES_F_REQUEST = requestF(PATCH_DISBURSEMENT_RULES)
export const PATCH_DISBURSEMENT_RULES_F_SUCCESS = successF(PATCH_DISBURSEMENT_RULES)

export const POST_BALANCE_ENTRY_F_REQUEST = requestF(POST_BALANCE_ENTRY)
export const POST_BALANCE_ENTRY_F_SUCCESS = successF(POST_BALANCE_ENTRY)
export const POST_BALANCE_ENTRY_F_FAILURE = failureF(POST_BALANCE_ENTRY)

export const GET_BALANCE_ENTRY_F_REQUEST = requestF(GET_BALANCE_ENTRY)
export const GET_BALANCE_ENTRY_F_SUCCESS = successF(GET_BALANCE_ENTRY)
export const GET_BALANCE_ENTRY_F_FAILURE = failureF(GET_BALANCE_ENTRY)

export const GET_PAYMENT_LINK_F_REQUEST = requestF(GET_PAYMENT_LINK)
export const GET_PAYMENT_LINK_F_SUCCESS = successF(GET_PAYMENT_LINK)
export const GET_PAYMENT_LINK_F_FAILURE = failureF(GET_PAYMENT_LINK)

export const GET_PAYMENT_LINKS_F_REQUEST = requestF(GET_PAYMENT_LINKS)
export const GET_PAYMENT_LINKS_F_SUCCESS = successF(GET_PAYMENT_LINKS)
export const GET_PAYMENT_LINKS_F_FAILURE = failureF(GET_PAYMENT_LINKS)

export const POST_PAYMENT_LINK_F_REQUEST = requestF(POST_PAYMENT_LINK)
export const POST_PAYMENT_LINK_F_SUCCESS = successF(POST_PAYMENT_LINK)
export const POST_PAYMENT_LINK_F_FAILURE = failureF(POST_PAYMENT_LINK)

export const GET_PAYMENT_LINKS_MERCHANT_F_REQUEST = requestF(GET_PAYMENT_LINKS_MERCHANT)
export const GET_PAYMENT_LINKS_MERCHANT_F_SUCCESS = successF(GET_PAYMENT_LINKS_MERCHANT)
export const GET_PAYMENT_LINKS_MERCHANT_F_FAILURE = failureF(GET_PAYMENT_LINKS_MERCHANT)

export const GET_PAYMENT_LINKS_APPLICATION_F_REQUEST = requestF(GET_PAYMENT_LINKS_APPLICATION)
export const GET_PAYMENT_LINKS_APPLICATION_F_SUCCESS = successF(GET_PAYMENT_LINKS_APPLICATION)
export const GET_PAYMENT_LINKS_APPLICATION_F_FAILURE = failureF(GET_PAYMENT_LINKS_APPLICATION)

export const PATCH_PAYMENT_LINK_F_REQUEST = requestF(PATCH_PAYMENT_LINK)
export const PATCH_PAYMENT_LINK_F_SUCCESS = successF(PATCH_PAYMENT_LINK)
export const PATCH_PAYMENT_LINK_F_FAILURE = failureF(PATCH_PAYMENT_LINK)

export const GET_PURCHASES_F_REQUEST = requestF(GET_PURCHASES)
export const GET_PURCHASES_F_SUCCESS = successF(GET_PURCHASES)
export const GET_PURCHASES_F_FAILURE = failureF(GET_PURCHASES)

export const GET_GUEST_CHECKOUT_TOKEN_VERIFICATION_F_REQUEST = requestF(GET_GUEST_CHECKOUT_TOKEN_VERIFICATION)
export const GET_GUEST_CHECKOUT_TOKEN_VERIFICATION_F_SUCCESS = successF(GET_GUEST_CHECKOUT_TOKEN_VERIFICATION)
export const GET_GUEST_CHECKOUT_TOKEN_VERIFICATION_F_FAILURE = failureF(GET_GUEST_CHECKOUT_TOKEN_VERIFICATION)

export const GET_GUEST_CHECKOUT_PAYMENT_LINK_F_REQUEST = requestF(GET_GUEST_CHECKOUT_PAYMENT_LINK)
export const GET_GUEST_CHECKOUT_PAYMENT_LINK_F_SUCCESS = successF(GET_GUEST_CHECKOUT_PAYMENT_LINK)
export const GET_GUEST_CHECKOUT_PAYMENT_LINK_F_FAILURE = failureF(GET_GUEST_CHECKOUT_PAYMENT_LINK)

export const GET_PURCHASE_F_REQUEST = requestF(GET_PURCHASE)
export const GET_PURCHASE_F_SUCCESS = successF(GET_PURCHASE)
export const GET_PURCHASE_F_FAILURE = failureF(GET_PURCHASE)

export const POST_PURCHASE_F_REQUEST = requestF(POST_PURCHASE)
export const POST_PURCHASE_F_SUCCESS = successF(POST_PURCHASE)
export const POST_PURCHASE_F_FAILURE = failureF(POST_PURCHASE)

export const PATCH_PURCHASE_F_REQUEST = requestF(PATCH_PURCHASE)
export const PATCH_PURCHASE_F_SUCCESS = successF(PATCH_PURCHASE)
export const PATCH_PURCHASE_F_FAILURE = failureF(PATCH_PURCHASE)

export const GET_PURCHASES_TRANSFER_F_REQUEST = requestF(GET_PURCHASES_TRANSFER)
export const GET_PURCHASES_TRANSFER_F_SUCCESS = successF(GET_PURCHASES_TRANSFER)
export const GET_PURCHASES_TRANSFER_F_FAILURE = failureF(GET_PURCHASES_TRANSFER)

export const GET_PURCHASE_APPLICATION_F_REQUEST = requestF(GET_PURCHASE_APPLICATION)
export const GET_PURCHASE_APPLICATION_F_SUCCESS = successF(GET_PURCHASE_APPLICATION)
export const GET_PURCHASE_APPLICATION_F_FAILURE = failureF(GET_PURCHASE_APPLICATION)

export const GET_PURCHASE_MERCHANT_F_REQUEST = requestF(GET_PURCHASE_MERCHANT)
export const GET_PURCHASE_MERCHANT_F_SUCCESS = successF(GET_PURCHASE_MERCHANT)
export const GET_PURCHASE_MERCHANT_F_FAILURE = failureF(GET_PURCHASE_MERCHANT)

export const GET_PURCHASE_MERCHANT_IDENTITY_F_REQUEST = requestF(GET_PURCHASE_MERCHANT_IDENTITY)
export const GET_PURCHASE_MERCHANT_IDENTITY_F_SUCCESS = successF(GET_PURCHASE_MERCHANT_IDENTITY)
export const GET_PURCHASE_MERCHANT_IDENTITY_F_FAILURE = failureF(GET_PURCHASE_MERCHANT_IDENTITY)

export const GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT_F_REQUEST = requestF(GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT)
export const GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT)
export const GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT_F_FAILURE = failureF(GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT)

export const GET_GUEST_CHECKOUT_FORM_F_REQUEST = requestF(GET_GUEST_CHECKOUT_FORM)
export const GET_GUEST_CHECKOUT_FORM_F_SUCCESS = successF(GET_GUEST_CHECKOUT_FORM)
export const GET_GUEST_CHECKOUT_FORM_F_FAILURE = failureF(GET_GUEST_CHECKOUT_FORM)

export const POST_NOTE_F_REQUEST = requestF(POST_NOTE)
export const POST_NOTE_F_SUCCESS = successF(POST_NOTE)
export const POST_NOTE_F_FAILURE = failureF(POST_NOTE)

export const GET_NOTES_F_REQUEST = requestF(GET_NOTES)
export const GET_NOTES_F_SUCCESS = successF(GET_NOTES)
export const GET_NOTES_F_FAILURE = failureF(GET_NOTES)

export const GET_NOTE_F_REQUEST = requestF(GET_NOTE)
export const GET_NOTE_F_SUCCESS = successF(GET_NOTE)
export const GET_NOTE_F_FAILURE = failureF(GET_NOTE)

export const PATCH_NOTE_F_REQUEST = requestF(PATCH_NOTE)
export const PATCH_NOTE_F_SUCCESS = successF(PATCH_NOTE)
export const PATCH_NOTE_F_FAILURE = failureF(PATCH_NOTE)

export const GET_DRAFT_NOTES_F_REQUEST = requestF(GET_DRAFT_NOTES)
export const GET_DRAFT_NOTES_F_SUCCESS = successF(GET_DRAFT_NOTES)
export const GET_DRAFT_NOTES_F_FAILURE = failureF(GET_DRAFT_NOTES)

export const GET_TRANSFER_MERCHANT_F_REQUEST = requestF(GET_TRANSFER_MERCHANT)
export const GET_TRANSFER_MERCHANT_F_SUCCESS = successF(GET_TRANSFER_MERCHANT)
export const GET_TRANSFER_MERCHANT_F_FAILURE = failureF(GET_TRANSFER_MERCHANT)

export const GET_DEVICE_F_REQUEST = requestF(GET_DEVICE)
export const GET_DEVICE_F_SUCCESS = successF(GET_DEVICE)
export const GET_DEVICE_F_FAILURE = failureF(GET_DEVICE)

export const GET_DEVICES_F_REQUEST = requestF(GET_DEVICES)
export const GET_DEVICES_F_SUCCESS = successF(GET_DEVICES)
export const GET_DEVICES_F_FAILURE = failureF(GET_DEVICES)

export const GET_DEVICE_MERCHANT_F_REQUEST = requestF(GET_DEVICE_MERCHANT)
export const GET_DEVICE_MERCHANT_F_SUCCESS = successF(GET_DEVICE_MERCHANT)

export const POST_DEVICE_F_REQUEST = requestF(POST_DEVICE)
export const POST_DEVICE_F_SUCCESS = successF(POST_DEVICE)
export const POST_DEVICE_F_FAILURE = failureF(POST_DEVICE)

export const PATCH_DEVICE_F_REQUEST = requestF(PATCH_DEVICE)
export const PATCH_DEVICE_F_SUCCESS = successF(PATCH_DEVICE)
export const PATCH_DEVICE_F_FAILURE = failureF(PATCH_DEVICE)

export const PATCH_UNDERWRITING_WORKFLOW_F_REQUEST = requestF(PATCH_UNDERWRITING_WORKFLOW)
export const PATCH_UNDERWRITING_WORKFLOW_F_SUCCESS = successF(PATCH_UNDERWRITING_WORKFLOW)
export const PATCH_UNDERWRITING_WORKFLOW_F_FAILURE = failureF(PATCH_UNDERWRITING_WORKFLOW)

export const GET_UNDERWRITING_WORKFLOW_F_REQUEST = requestF(GET_UNDERWRITING_WORKFLOW)
export const GET_UNDERWRITING_WORKFLOW_F_SUCCESS = successF(GET_UNDERWRITING_WORKFLOW)
export const GET_UNDERWRITING_WORKFLOW_F_FAILURE = failureF(GET_UNDERWRITING_WORKFLOW)

export const POST_PLAID_TOKEN_F_REQUEST = requestF(POST_PLAID_TOKEN)
export const POST_PLAID_TOKEN_F_SUCCESS = successF(POST_PLAID_TOKEN)
export const POST_PLAID_TOKEN_F_FAILURE = failureF(POST_PLAID_TOKEN)

export const POST_PLAID_PUBLIC_TOKEN_F_REQUEST = requestF(POST_PLAID_PUBLIC_TOKEN)
export const POST_PLAID_PUBLIC_TOKEN_F_SUCCESS = successF(POST_PLAID_PUBLIC_TOKEN)
export const POST_PLAID_PUBLIC_TOKEN_F_FAILURE = failureF(POST_PLAID_PUBLIC_TOKEN)

export const PATCH_PLAID_TOKEN_METADATA_F_REQUEST = requestF(PATCH_PLAID_TOKEN_METADATA)

export const GET_APPLICATION_PROCESSOR_CONFIGURATIONS_F_REQUEST = requestF(GET_APPLICATION_PROCESSOR_CONFIGURATIONS)
export const GET_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS = successF(GET_APPLICATION_PROCESSOR_CONFIGURATIONS)
export const GET_APPLICATION_PROCESSOR_CONFIGURATIONS_F_FAILURE = failureF(GET_APPLICATION_PROCESSOR_CONFIGURATIONS)

export const PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_REQUEST = requestF(PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS)
export const PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_SUCCESS = successF(PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS)
export const PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS_F_FAILURE = failureF(PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS)

export const GET_AUDIT_LOG_EVENTS_F_REQUEST = requestF(GET_AUDIT_LOG_EVENTS)
export const GET_AUDIT_LOG_EVENTS_F_SUCCESS = successF(GET_AUDIT_LOG_EVENTS)
export const GET_AUDIT_LOG_EVENTS_F_FAILURE = failureF(GET_AUDIT_LOG_EVENTS)

export const GET_AUDIT_LOG_EVENT_F_REQUEST = requestF(GET_AUDIT_LOG_EVENT)
export const GET_AUDIT_LOG_EVENT_F_SUCCESS = successF(GET_AUDIT_LOG_EVENT)
export const GET_AUDIT_LOG_F_FAILURE = failureF(GET_AUDIT_LOG_EVENT)

// Related Resource Flows
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS = 'GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS'
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS)

export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES = 'GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES'
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES)

export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS = 'GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS'
export const GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS_F_SUCCESS = successF(GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS)

export const GET_FRAUD_SCORE_F_REQUEST = requestF(GET_FRAUD_SCORE)
export const GET_FRAUD_SCORE_F_SUCCESS = successF(GET_FRAUD_SCORE)
export const GET_FRAUD_SCORE_F_FAILURE = failureF(GET_FRAUD_SCORE)

export const GET_FRAUD_SCORES_F_REQUEST = requestF(GET_FRAUD_SCORES)
export const GET_FRAUD_SCORES_F_SUCCESS = successF(GET_FRAUD_SCORES)
export const GET_FRAUD_SCORES_F_FAILURE = failureF(GET_FRAUD_SCORES)

export const POST_REQUEST_CHANGES_F_REQUEST = requestF(POST_REQUEST_CHANGES)
export const POST_REQUEST_CHANGES_F_SUCCESS = successF(POST_REQUEST_CHANGES)
export const POST_REQUEST_CHANGES_F_FAILURE = failureF(POST_REQUEST_CHANGES)

export const POST_FEE_PROFILE_SETTINGS_F_REQUEST = requestF(POST_FEE_PROFILE_SETTINGS)
export const POST_FEE_PROFILE_SETTINGS_F_SUCCESS = successF(POST_FEE_PROFILE_SETTINGS)
export const POST_FEE_PROFILE_SETTINGS_F_FAILURE = failureF(POST_FEE_PROFILE_SETTINGS)

export const PATCH_FEE_PROFILE_SETTINGS_F_REQUEST = requestF(PATCH_FEE_PROFILE_SETTINGS)
export const PATCH_FEE_PROFILE_SETTINGS_F_SUCCESS = successF(PATCH_FEE_PROFILE_SETTINGS)
export const PATCH_FEE_PROFILE_SETTINGS_F_FAILURE = failureF(PATCH_FEE_PROFILE_SETTINGS)

export const GET_GUEST_CHECKOUT_PAYOUT_LINK_F_REQUEST = requestF(GET_GUEST_CHECKOUT_PAYOUT_LINK)
export const GET_GUEST_CHECKOUT_PAYOUT_LINK_F_SUCCESS = successF(GET_GUEST_CHECKOUT_PAYOUT_LINK)
export const GET_GUEST_CHECKOUT_PAYOUT_LINK_F_FAILURE = failureF(GET_GUEST_CHECKOUT_PAYOUT_LINK)

export const POST_PAYOUT_LINK_F_REQUEST = requestF(POST_PAYOUT_LINK)
export const POST_PAYOUT_LINK_F_SUCCESS = successF(POST_PAYOUT_LINK)
export const POST_PAYOUT_LINK_F_FAILURE = failureF(POST_PAYOUT_LINK)

export const GET_PAYOUT_LINK_F_REQUEST = requestF(GET_PAYOUT_LINK)
export const GET_PAYOUT_LINK_F_SUCCESS = successF(GET_PAYOUT_LINK)
export const GET_PAYOUT_LINK_F_FAILURE = failureF(GET_PAYOUT_LINK)

export const GET_TRANSFER_ATTEMPTS_F_REQUEST = requestF(GET_TRANSFER_ATTEMPTS)
export const GET_TRANSFER_ATTEMPTS_F_SUCCESS = successF(GET_TRANSFER_ATTEMPTS)
export const GET_TRANSFER_ATTEMPTS_F_FAILURE = failureF(GET_TRANSFER_ATTEMPTS)

export const GET_PAYOUT_LINKS_F_REQUEST = requestF(GET_PAYOUT_LINKS)
export const GET_PAYOUT_LINKS_F_SUCCESS = successF(GET_PAYOUT_LINKS)
export const GET_PAYOUT_LINKS_F_FAILURE = failureF(GET_PAYOUT_LINKS)

export const PATCH_PAYOUT_LINK_F_REQUEST = requestF(PATCH_PAYOUT_LINK)
export const PATCH_PAYOUT_LINK_F_SUCCESS = successF(PATCH_PAYOUT_LINK)
export const PATCH_PAYOUT_LINK_F_FAILURE = failureF(PATCH_PAYOUT_LINK)

export const GET_TRANSFER_ATTEMPT_F_REQUEST = requestF(GET_TRANSFER_ATTEMPT)
export const GET_TRANSFER_ATTEMPT_F_SUCCESS = successF(GET_TRANSFER_ATTEMPT)
export const GET_TRANSFER_ATTEMPT_F_FAILURE = failureF(GET_TRANSFER_ATTEMPT)

export const PATCH_TRANSFER_ATTEMPT_F_REQUEST = requestF(PATCH_TRANSFER_ATTEMPT)
export const PATCH_TRANSFER_ATTEMPT_F_SUCCESS = successF(PATCH_TRANSFER_ATTEMPT)
export const PATCH_TRANSFER_ATTEMPT_F_FAILURE = failureF(PATCH_TRANSFER_ATTEMPT)

export const GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS_F_REQUEST = requestF(GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS)
export const GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS_F_SUCCESS = successF(GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS)
export const GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS_F_FAILURE = failureF(GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS)

export const POST_TRANSFER_ATTEMPT_F_REQUEST = requestF(POST_TRANSFER_ATTEMPT)
export const POST_TRANSFER_ATTEMPT_F_SUCCESS = successF(POST_TRANSFER_ATTEMPT)
export const POST_TRANSFER_ATTEMPT_F_FAILURE = failureF(POST_TRANSFER_ATTEMPT)

export const GET_SPLIT_TRANSFER_SETTLEMENT_F_REQUEST = requestF(GET_SPLIT_TRANSFER_SETTLEMENT)
export const GET_SPLIT_TRANSFER_SETTLEMENT_F_SUCCESS = successF(GET_SPLIT_TRANSFER_SETTLEMENT)
export const GET_SPLIT_TRANSFER_SETTLEMENT_F_FAILURE = failureF(GET_SPLIT_TRANSFER_SETTLEMENT)

export const POST_DISPUTE_F_REQUEST = requestF(POST_DISPUTE)
export const POST_DISPUTE_F_SUCCESS = successF(POST_DISPUTE)
export const POST_DISPUTE_F_FAILURE = failureF(POST_DISPUTE)

export const PATCH_UPDATE_DISPUTE_F_REQUEST = requestF(PATCH_UPDATE_DISPUTE)
export const PATCH_UPDATE_DISPUTE_F_SUCCESS = successF(PATCH_UPDATE_DISPUTE)
export const PATCH_UPDATE_DISPUTE_F_FAILURE = failureF(PATCH_UPDATE_DISPUTE)

export const PATCH_ONBOARDING_FORM_DOCUMENTS_F_REQUEST = requestF(PATCH_ONBOARDING_FORM_DOCUMENTS)
export const PATCH_ONBOARDING_FORM_DOCUMENTS_F_SUCCESS = successF(PATCH_ONBOARDING_FORM_DOCUMENTS)
export const PATCH_ONBOARDING_FORM_DOCUMENTS_F_FAILURE = failureF(PATCH_ONBOARDING_FORM_DOCUMENTS)

export const ONBOARDING_FORM_DOCUMENT = 'ONBOARDING_FORM_DOCUMENT'
export const PATCH_ONBOARDING_FORM_DOCUMENT = PATCH(ONBOARDING_FORM_DOCUMENT)
export const PATCH_ONBOARDING_FORM_DOCUMENT_F_REQUEST = requestF(PATCH_ONBOARDING_FORM_DOCUMENT)
export const PATCH_ONBOARDING_FORM_DOCUMENT_F_SUCCESS = successF(PATCH_ONBOARDING_FORM_DOCUMENT)
export const PATCH_ONBOARDING_FORM_DOCUMENT_F_FAILURE = failureF(PATCH_ONBOARDING_FORM_DOCUMENT)

export const GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT_F_REQUEST = requestF(GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT)
export const GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT)
export const GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT_F_FAILURE = failureF(GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT)

export const POST_BALANCE_ADJUSTMENT_F_REQUEST = requestF(POST_BALANCE_ADJUSTMENT)
export const POST_BALANCE_ADJUSTMENT_F_SUCCESS = successF(POST_BALANCE_ADJUSTMENT)
export const POST_BALANCE_ADJUSTMENT_F_FAILURE = failureF(POST_BALANCE_ADJUSTMENT)

export const GET_BALANCE_ADJUSTMENT_F_REQUEST = requestF(GET_BALANCE_ADJUSTMENT)
export const GET_BALANCE_ADJUSTMENT_F_SUCCESS = successF(GET_BALANCE_ADJUSTMENT)
export const GET_BALANCE_ADJUSTMENT_F_FAILURE = failureF(GET_BALANCE_ADJUSTMENT)

export const GET_SUBSCRIPTIONS_F_REQUEST = requestF(GET_SUBSCRIPTIONS)
export const GET_SUBSCRIPTIONS_F_SUCCESS = successF(GET_SUBSCRIPTIONS)
export const GET_SUBSCRIPTIONS_F_FAILURE = failureF(GET_SUBSCRIPTIONS)

export const GET_SUBSCRIPTION_F_REQUEST = requestF(GET_SUBSCRIPTION)
export const GET_SUBSCRIPTION_F_SUCCESS = successF(GET_SUBSCRIPTION)
export const GET_SUBSCRIPTION_F_FAILURE = failureF(GET_SUBSCRIPTION)

export const GET_SUBSCRIPTIONS_IDENTITY_F_REQUEST = requestF(GET_SUBSCRIPTIONS_IDENTITY)
export const GET_SUBSCRIPTIONS_IDENTITY_F_SUCCESS = successF(GET_SUBSCRIPTIONS_IDENTITY)
export const GET_SUBSCRIPTIONS_IDENTITY_F_FAILURE = failureF(GET_SUBSCRIPTIONS_IDENTITY)

export const GET_SUBSCRIPTIONS_MERCHANT_F_REQUEST = requestF(GET_SUBSCRIPTIONS_MERCHANT)
export const GET_SUBSCRIPTIONS_MERCHANT_F_SUCCESS = successF(GET_SUBSCRIPTIONS_MERCHANT)
export const GET_SUBSCRIPTIONS_MERCHANT_F_FAILURE = failureF(GET_SUBSCRIPTIONS_MERCHANT)

export const GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT_F_REQUEST = requestF(GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT)
export const GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT_F_SUCCESS = successF(GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT)
export const GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT_F_FAILURE = failureF(GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT)

export const POST_SUBSCRIPTION_F_REQUEST = requestF(POST_SUBSCRIPTION)
export const POST_SUBSCRIPTION_F_SUCCESS = successF(POST_SUBSCRIPTION)
export const POST_SUBSCRIPTION_F_FAILURE = failureF(POST_SUBSCRIPTION)

export const POST_SUBSCRIPTION_PLAN_F_REQUEST = requestF(POST_SUBSCRIPTION_PLAN)
export const POST_SUBSCRIPTION_PLAN_F_SUCCESS = successF(POST_SUBSCRIPTION_PLAN)
export const POST_SUBSCRIPTION_PLAN_F_FAILURE = failureF(POST_SUBSCRIPTION_PLAN)

export const DELETE_SUBSCRIPTION_F_REQUEST = requestF(DELETE_SUBSCRIPTION)
export const DELETE_SUBSCRIPTION_F_SUCCESS = successF(DELETE_SUBSCRIPTION)
export const DELETE_SUBSCRIPTION_F_FAILURE = failureF(DELETE_SUBSCRIPTION)

export const PATCH_SUBSCRIPTION_F_REQUEST = requestF(PATCH_SUBSCRIPTION)
export const PATCH_SUBSCRIPTION_F_SUCCESS = successF(PATCH_SUBSCRIPTION)
export const PATCH_SUBSCRIPTION_F_FAILURE = failureF(PATCH_SUBSCRIPTION)

export const GET_SUBSCRIPTIONS_PLAN_F_REQUEST = requestF(GET_SUBSCRIPTIONS_PLAN)
export const GET_SUBSCRIPTIONS_PLAN_F_SUCCESS = successF(GET_SUBSCRIPTIONS_PLAN)
export const GET_SUBSCRIPTIONS_PLAN_F_FAILURE = failureF(GET_SUBSCRIPTIONS_PLAN)

export const POST_INSTANT_PAYOUT_F_REQUEST =  requestF(POST_INSTANT_PAYOUT)
export const POST_INSTANT_PAYOUT_F_SUCCESS = successF(POST_INSTANT_PAYOUT)
export const POST_INSTANT_PAYOUT_F_FAILURE = failureF(POST_INSTANT_PAYOUT)

// keep all flow definitions above this
export const NON_CREDENTIAL_FLOWS = [
  GET_CREDENTIALS,
  GET_APP_CONFIGURATION,
  GET_CONFIGURATION,
  GET_CONFIGURATIONS,
  POST_DASHBOARD_FILE,
  GET_DASHBOARD_USERS,
  GET_RESPONSE_FILES,
]
export const POST_PATCH_FLOWS = [
  POST_TRANSFER,
  POST_IDENTITY,
  PATCH_IDENTITY,
  POST_APPLICATION,
  // PATCH_REVIEW_QUEUE_ITEMS, TODO: look into main reason of why this is triggering double spinners and refactor accordingly
  POST_DASHBOARD_USER,
  POST_USERS_EMAIL,
  POST_ADMIN_USER,
  POST_ADMIN_USER_MEMBERSHIPS,
  PATCH_WELCOME_EMAIL_TEMPLATE,
  POST_USERS_EMAIL,
  PATCH_SETTLEMENT_V1,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
  PATCH_PLATFORM_PROCESSOR_CONFIG,
  PATCH_TRANSFERS_UPDATE,
  PATCH_VERIFICATIONS_UPDATE,
  POST_IDENTITY_MERCHANTS,
  POST_DISPUTE_EVIDENCES,
  POST_USER,
  PATCH_USER_PERMISSIONS,
  PATCH_USER,
  POST_IDENTITY_VERIFICATIONS,
  POST_BALANCE_TRANSFER,
  PATCH_SETTLEMENT,
  DELETE_SETTLEMENT_ENTRIES,
  POST_FEE,
  POST_SUBSCRIPTION_SCHEDULE,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT,
  DELETE_SUBSCRIPTION_ENROLLMENT,
  POST_WEBHOOKS,
  PATCH_WEBHOOK,
  POST_PAYMENT_METHOD_CONFIGURATIONS,
  PATCH_PAYMENT_METHOD_CONFIGURATIONS,
  POST_MERCHANT_FILE,
  POST_IDENTITY_FILE,
  PATCH_COMPLIANCE_FORM,
  PATCH_COMPLIANCE_FORM_TEMPLATE,
  POST_COMPLIANCE_FORM_TEMPLATE,
  POST_IDENTITY_PAYMENT_INSTRUMENTS,
  PATCH_PAYOUT_SETTINGS,
  PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING,
  PATCH_PAYMENT_INSTRUMENT,
  POST_ONBOARDING_FORM,
  POST_DISPUTE_EVIDENCES,
  POST_SUBMIT_DISPUTE_EVIDENCE,
  POST_DASHBOARD_USER_MFA_TOKEN,
  POST_DASHBOARD_USER_MFA_ASSOCIATE,
  POST_ENTITY_CONFIGURATION,
  PATCH_ENTITY_CONFIGURATION,
  POST_TRANSFER_REVERSAL,
  POST_DISBURSEMENT_RULES,
  POST_PAYMENT_LINK,
  PATCH_PAYMENT_LINK,
  POST_UNDERWRITING_REVIEW_RUNS,
  POST_NOTE,
  PATCH_NOTE,
  PATCH_DEVICE,
  POST_COMPLIANCE_FORM,
  PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS,
  POST_DISPUTE,
  PATCH_UPDATE_DISPUTE,
  POST_RECEIPT,
  POST_RECEIPT_DELIVERY_ATTEMPTS,
  POST_SUBSCRIPTION_PLAN,
  POST_DASHBOARD_USER_MFA_ASSOCIATE,
  POST_DASHBOARD_USER_MFA_VERIFY,
  PATCH_MONITORING_ALERT,
  PATCH_MONITORING_ALERTS,
]
