import React, { Component } from 'react'
import { connect } from 'react-redux'
import VerificationRejectionDetails from './VerificationRejectionDetails'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import { VERIFICATION_RESULT_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const hasAPIAccess = hasPartnerAccess({ credentials })
  const verification = get(props, 'verification')

  return {
    verification,
    hasAPIAccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showVerificationResultsModal: (modalProps) => dispatch(showModalAction({ modalType: VERIFICATION_RESULT_MODAL, modalProps })),
  }
}

class VerificationRejectionDetailsC extends Component {
  render() {
    const { showVerificationResultsModal } = this.props

    return (
      <VerificationRejectionDetails
        {...this.props}
        showVerificationResultsModal={showVerificationResultsModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationRejectionDetailsC)
