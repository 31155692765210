import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MFA_RESET } from 'constants/apiConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const postDashboardUserMFAResetAPI = ({ id, meta }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_MFA_RESET({
      userId: id || get(getCurrentCredentials(), 'userId'),
    }),
    meta,
    values: {},
  })
}

export default postDashboardUserMFAResetAPI
