import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import EditActiveComplianceTemplateForm from 'components/Customer/Forms/EditActiveComplianceTemplateForm/EditActiveComplianceTemplateForm'
import { COMPLIANCE_FORM_TEMPLATES } from 'constants/selectedItemsConstants'
import { EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { UNSELECT_ALL_ITEMS } from 'constants/flowConstants'
import get from 'lodash/get'
import find from 'lodash/find'
import keys from 'lodash/keys'

const selectedItemsKey = COMPLIANCE_FORM_TEMPLATES

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM)
  const complianceTemplateIdSelectorValue = formSelector(state, 'complianceTemplateIdSelector')
  const complianceTemplateIdInput = formSelector(state, 'complianceTemplateIdInput')
  const selectedComplianceFormTemplates = get(state, 'selectedItemsR.COMPLIANCE_FORM_TEMPLATES')
  const selectInput = complianceTemplateIdSelectorValue === 'input'
  const selectTable = complianceTemplateIdSelectorValue === 'table'
  const complianceTemplateIdTable = find(keys(selectedComplianceFormTemplates), (templateId) => get(selectedComplianceFormTemplates, templateId))
  const enableSubmit = (selectInput && complianceTemplateIdInput) || (selectTable && complianceTemplateIdTable)
  const isFetching = get(state, `complianceFormTemplatesR.${FETCHING}`)

  return {
    selectedItemsKey,
    complianceTemplateIdTable,
    selectInput,
    selectTable,
    enableSubmit,
    isFetching,
    initialValues: {
      complianceTemplateIdSelector: 'table',
    },
  }
}

class EditActiveComplianceTemplateFormC extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isFetching: prevIsFetching } = prevProps
    const { isFetching, dispatch } = this.props

    if (prevIsFetching && !isFetching) {
      dispatch({ type: UNSELECT_ALL_ITEMS })
    }
  }

  render() {
    return (
      <EditActiveComplianceTemplateForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EditActiveComplianceTemplateFormC)
