import ContactSupportEmail from './ContactSupportEmail'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import { GET_EMAIL_TEMPLATE_F_REQUEST } from 'constants/flowConstants'
import { CONTACT_SUPPORT_TEMPLATE } from 'constants/emailTemplateConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `emailTemplatesR.${FETCHING}`)
  const contactSupportEmail = get(state, `emailTemplatesR.items.${CONTACT_SUPPORT_TEMPLATE}`)

  return {
    isFetching,
    contactSupportEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getContactSupportEmail: (templateName) => dispatch({
      type: GET_EMAIL_TEMPLATE_F_REQUEST,
      payload: { values: { templateName } },
    }),
  }
}

class ContactSupportEmailC extends Component {
  componentDidMount() {
    const { getContactSupportEmail } = this.props

    getContactSupportEmail(CONTACT_SUPPORT_TEMPLATE)
  }

  render() {
    return (
      <ContactSupportEmail {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupportEmailC)
