import './DashboardTypeSelectModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import DashboardTypeFormC from 'components/Customer/Forms/DashboardTypeForm/DashboardTypeFormC'

const DashboardTypeSelectModal = ({
  credentials,
}) => {
  return (
    <GenericModal
      className='DashboardTypeSelectModal'
      Component={DashboardTypeFormC}
      credentials={credentials}
    />
  )
}

DashboardTypeSelectModal.propTypes = {
  credentials: PropTypes.object,
}

export default DashboardTypeSelectModal
