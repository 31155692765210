import React, { Component } from 'react'
import { connect } from 'react-redux'
import LastUpdatedAt from './LastUpdatedAt'
import { getChartByChartName } from 'state-layer/selectors/index'
import { LAST_UPDATED_AT_BIG_NUMBER_TOTAL } from 'constants/chartConstants'
import { GET_CHART_F_REQUEST } from 'constants/flowConstants'
import { FETCHING } from 'constants/reducerConstants'
import moment from 'moment'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const chart = getChartByChartName(state, LAST_UPDATED_AT_BIG_NUMBER_TOTAL) || {}
  const chartId = get(chart, 'id')
  const getKey = get(chart, 'data.0.colnames.0')
  const epochTime = get(chart, `data.0.data.0.${getKey}`)
  const lastUpdatedAt = epochTime ? moment(epochTime).format('h:mm A z') : undefined
  const isFetching = get(chart, FETCHING, true)

  return {
    chartId,
    isFetching,
    lastUpdatedAt,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChart: ({ id }) => {
      dispatch({
        type: GET_CHART_F_REQUEST,
        payload: {
          id,
        },
      })
    },
  }
}

class LastUpdatedAtC extends Component {
  componentDidMount() {
    const {
      chartId,
      getChart,
    } = this.props

    if (chartId) {
      getChart({ id: chartId })
    }
  }

  render() {
    return (
      <LastUpdatedAt {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LastUpdatedAtC)
