import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PURCHASE,
  PURCHASES,
} from 'constants/apiConstants'

const getPurchaseAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: PURCHASE({ purchaseId: id }),
    credentials,
    meta,
    service: PURCHASES,
  })
}

export default getPurchaseAPI
