import './AttestComplianceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import Button from 'components/Shared/Button/Button'
import submitAttestComplianceForm from 'utilities/submit/submitAttestComplianceForm'
import validateAttestComplianceForm from 'utilities/validate/validateAttestComplianceForm'
import { ATTEST_COMPLIANCE_FORM } from 'constants/formConstants'

import {
  MERCHANT_EXECUTIVE_NAME,
  TITLE,
  ATTEST,
  ATTEST_COMPLIANCE_FORM_SUBMISSION_AGREEMENT_MSG,
} from 'constants/language/languageConstants'

const AttestComplianceForm = ({
  handleSubmit = () => {},
  submitting = false,
  attestComplianceAgreementAccepted = false,
  toggleAcceptAttestComplianceFormAgreement = () => {},
}) => {
  return (
    <form className='AttestComplianceForm' onSubmit={handleSubmit(submitAttestComplianceForm)}>
      <Field
        name='merchantExecutiveName'
        className='merchant-executive-name-field'
        label={MERCHANT_EXECUTIVE_NAME}
        component={InputField}
      />

      <Field
        name='title'
        className='title-field'
        label={TITLE}
        component={InputField}
      />

      <div className='submission-agreement-checkbox-field'>
        <Field
          name='attestComplianceAgreementAccepted'
          label={ATTEST_COMPLIANCE_FORM_SUBMISSION_AGREEMENT_MSG}
          component={CheckboxC}
          checked={attestComplianceAgreementAccepted}
          handleChange={toggleAcceptAttestComplianceFormAgreement}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} label={ATTEST} />
      </div>
    </form>
  )
}

AttestComplianceForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  attestComplianceAgreementAccepted: PropTypes.bool,
  toggleAcceptAttestComplianceFormAgreement: PropTypes.func,
}

export default reduxForm({
  form: ATTEST_COMPLIANCE_FORM,
  validate: validateAttestComplianceForm,
})(AttestComplianceForm)
