import './SendPayoutReviewAndSubmitFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { goToPath } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import prevFlowStep from 'utilities/prevFlowStep'
import submitSendPayoutReviewForm from 'utilities/submit/submitSendPayoutReviewForm'
import { SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM } from 'constants/formConstants'
import { HOME_PATH } from 'constants/pathConstants'

import {
  CANCEL,
  SEND,
  PREVIOUS_STEP,
  CONFIRM_DETAILS_AND_SEND,
  RECIPIENT_DETAILS,
  RECIPIENT_PAYMENT_INSTRUMENT,
  PAYOUT_DETAILS,
  PLEASE_REVIEW_AND_CLICK_SEND_MESSAGE,
  PLEASE_SHARE_INFORMATION_WITH_RECIPIENT_MESSAGE,
} from 'constants/language/languageConstants'

const SendPayoutReviewAndSubmitForm = ({
  handleSubmit = () => {},
  credentialId = '',
  selectedRecipientData = {},
  selectedPaymentInstrumentData = {},
  payoutDetailsData = {},
  isBankAccount = false,
  currentDate = '',
  applicationName = '',
  amount = '',
  isPosting = false,
}) => {
  return (
    <form className='SendPayoutReviewAndSubmitForm' onSubmit={handleSubmit(submitSendPayoutReviewForm)}>
      <div className='form-header'>
        <h5>{CONFIRM_DETAILS_AND_SEND}</h5>
        <div className='sub-header label-2'>{PLEASE_REVIEW_AND_CLICK_SEND_MESSAGE}</div>
      </div>

      <div className='form-content'>
        <PageSectionV2
          smallTitle={RECIPIENT_DETAILS}
          className='recipient-details'
          columns={selectedRecipientData}
        />
        <PageSectionV2
          smallTitle={RECIPIENT_PAYMENT_INSTRUMENT}
          className='recipient-payment-instrument'
          columns={selectedPaymentInstrumentData}
        />
        <PageSectionV2
          smallTitle={PAYOUT_DETAILS}
          className='payout-details'
          columns={payoutDetailsData}
        />
        {isBankAccount && (
        <div className='disclaimer-section'>
          <div className='p-3-bold'>{PLEASE_SHARE_INFORMATION_WITH_RECIPIENT_MESSAGE}</div>
          <div className='p-3 disclaimer'>By consenting to process this transaction, you authorize {applicationName} to initiate an automated clearing house (ACH) one-time credit in your name to your bank account on {currentDate}. A {amount} transaction will be presented to your financial institution by the next business day. You further agree that once the payout is processed, you may not revoke this authorization or cancel it. Do I have your authorization to process this payout?</div>
        </div>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => prevFlowStep()} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={() => goToPath({ pathname: HOME_PATH({ credentialId }) })} label={CANCEL} variant='ghost' />
            <Button type='submit' label={SEND} submitting={isPosting} />
          </div>
        </div>
      </div>
    </form>
  )
}

SendPayoutReviewAndSubmitForm.propTypes = {
  handleSubmit: PropTypes.func,
  credentialId: PropTypes.string,
  selectedRecipientData: PropTypes.object,
  selectedPaymentInstrumentData: PropTypes.object,
  payoutDetailsData: PropTypes.object,
  isBankAccount: PropTypes.bool,
  currentDate: PropTypes.string,
  applicationName: PropTypes.string,
  amount: PropTypes.string,
  isPosting: PropTypes.bool,
}

export default reduxForm({
  form: SEND_PAYOUT_REVIEW_AND_SUBMIT_FORM,
})(SendPayoutReviewAndSubmitForm)
