import dashboardAPI from 'utilities/api/dashboardAPI'

import {
  DASHBOARD_SERVICE_WELCOME_EMAIL,
  DASHBOARD_SERVICE_EMAIL_TEMPLATES,
} from 'constants/apiConstants'

const patchWelcomeEmailTemplateAPI = ({ values, meta }) => {
  return dashboardAPI.put({
    meta,
    values,
    path: DASHBOARD_SERVICE_WELCOME_EMAIL,
    service: DASHBOARD_SERVICE_EMAIL_TEMPLATES,
  })
}

export default patchWelcomeEmailTemplateAPI
