import './EditPayoutProfileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import submitEditPayoutProfileForm from 'utilities/submit/submitEditPayoutProfileForm'
import { EDIT_PAYOUT_PROFILE_FORM } from 'constants/formConstants'

import {
  CANCEL,
  SUBMIT,
  PUSH_TO_CARD,
  PULL_FROM_CARD,
  PUSH_TO_ACH,
  PULL_FROM_ACH,
  PUSH_TO_SAME_DAY_ACH,
  PULL_FROM_SAME_DAY_ACH,
} from 'constants/language/languageConstants'

const EditPayoutProfileForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  pristine = false,
  isPatching = false,
  invalid = false,
}) => {
  return (
    <form className='EditPayoutProfileForm' onSubmit={handleSubmit(submitEditPayoutProfileForm)}>
      <div className='modal-content'>
        <ToggleSwitchC
          name='disbursementsCardPushEnabled'
          label={PUSH_TO_CARD}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
        <ToggleSwitchC
          name='disbursementsCardPullEnabled'
          label={PULL_FROM_CARD}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
        <ToggleSwitchC
          name='disbursementsAchPushEnabled'
          label={PUSH_TO_ACH}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
        <ToggleSwitchC
          name='disbursementsAchPullEnabled'
          label={PULL_FROM_ACH}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
        <ToggleSwitchC
          name='disbursementsSameDayAchPushEnabled'
          label={PUSH_TO_SAME_DAY_ACH}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
        <ToggleSwitchC
          name='disbursementsSameDayAchPullEnabled'
          label={PULL_FROM_SAME_DAY_ACH}
          form={EDIT_PAYOUT_PROFILE_FORM}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={SUBMIT} disabled={pristine || invalid || isPatching} />
      </div>
    </form>
  )
}

EditPayoutProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  pristine: PropTypes.bool,
  isPatching: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_PAYOUT_PROFILE_FORM,
})(EditPayoutProfileForm)
