import React from 'react'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'

import {
  UPDATED_AT_LABEL,
  ID,
  PROCESSOR,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', { className: 'id' }),
  column(PROCESSOR, 'processor', { className: 'medium' }),
  column(UPDATED_AT_LABEL, 'displayUpdatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={UPDATED_AT_LABEL} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
]

export default columnDescriptors
