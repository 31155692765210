import dashboardAPI from 'utilities/api/dashboardAPI'
import getMany from 'utilities/get/getMany'
import { ACCESS_FORM_FILE_EXTERNAL_LINKS } from 'constants/apiConstants'

const postAccessFormFileExternalLinksAPI = ({ id, values, credentials, meta }) => {
  const [
    accessFormId,
    type,
    duration,
  ] = getMany(values, [
    'accessFormId',
    'type',
    'duration',
  ])

  return dashboardAPI.post({
    meta,
    credentials,
    path: ACCESS_FORM_FILE_EXTERNAL_LINKS({ fileId: id, accessFormId }),
    values: { type, duration },
  })
}

export default postAccessFormFileExternalLinksAPI
