import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_SERVICE_ONBOARDING_FORM } from 'constants/apiConstants'

const patchOnboardingFormDataAPI = ({ values }) => {
  const { id } = values
  const path = DASHBOARD_SERVICE_ONBOARDING_FORM({ id })

  return guestDashboardAPI.patch({
    path,
    values,
  })
}

export default patchOnboardingFormDataAPI
