import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORM } from 'constants/apiConstants'

const deleteAccessFormAPI = ({ id, meta }) => {
  return dashboardAPI.delete({
    path: DASHBOARD_ACCESS_FORM({ id }),
    meta,
  })
}

export default deleteAccessFormAPI
