import './ComponentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import { GENERIC_FORM } from 'constants/formConstants'

const Components = ({
  groupedComponents,
  isAdmin,
  redirectPath,
}) => {
  if (!isAdmin) {
    return (
      <div className='MainAdmin NoAccess'>
        <h3>You are not an administrator</h3>

        <Link to={redirectPath}>
          Back to Dashboard
        </Link>
      </div>
    )
  }

  return (
    <div className='Components'>
      <h1>Component Dictionary</h1>
      <p>This page represents commonly used components in our dashboard.</p>
      <form>
        {map(groupedComponents, (components, group) => {
          return (
            <div className={`group ${group}`}>
              <h1>{startCase(group)}</h1>
              {map(components, ({ Component, props, name }) => {
                return (
                  <>
                    <h3>{name}</h3>
                    <div className={`component ${name}`}>
                      <Component {...props} />
                    </div>
                  </>
                )
              })}
            </div>
          )
        })}
      </form>
    </div>
  )
}

Components.propTypes = {
  groupedComponents: PropTypes.array,
  isAdmin: PropTypes.bool,
  redirectPath: PropTypes.string,
}

export default reduxForm({ form: GENERIC_FORM })(Components)
