import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityVerificationForm from './IdentityVerificationForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import selectOption from 'utilities/forms/selectOption'
import hideModalAction from 'utilities/actions/hideModalAction'
import { getIdentitySelector } from 'state-layer/selectors'
import { APPLICATION_PATH } from 'constants/pathConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import some from 'lodash/some'
import includes from 'lodash/includes'

import {
  GIACT_V1,
  MICROBILT_V1,
  VERIFICATION_PROCESSORS,
} from 'constants/processorConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const identityId = get(props, 'params.identityId') || get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)

  const applicationId = get(identity, 'application.id')
  const applicationPath = `${APPLICATION_PATH({ credentialId, applicationId })}?tab=processors`

  const applicationProcessors = map(get(identity, 'application.processors', []), ({ processor }) => processor)
  const hasEnabledProcessors = isFlexPlatform() ? true : some(VERIFICATION_PROCESSORS, (processor) => includes(applicationProcessors, processor))
  const verificationProcessors = isFlexPlatform() ? VERIFICATION_PROCESSORS.concat(GIACT_V1) : VERIFICATION_PROCESSORS
  const processorOptions = map(verificationProcessors, (processor) => selectOption(processor, processor))

  const initialValues = {
    identity: identityId,
    processor: MICROBILT_V1,
  }

  return removeUndefined({
    identityId,
    credentials,
    initialValues,
    processors: VERIFICATION_PROCESSORS,
    processorOptions,
    hasEnabledProcessors,
    applicationPath,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class IdentityVerificationFormC extends Component {
  render() {
    return (
      <IdentityVerificationForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationFormC)
