import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import submitCreateSubscriptionAmountForm from 'utilities/submit/submitCreateSubscriptionAmountForm'
import validateCreateSubscriptionAmountForm from 'utilities/validate/validateCreateSubscriptionAmountForm'
import isEmpty from 'lodash/isEmpty'
import { CREATE_SUBSCRIPTION_AMOUNT } from 'constants/formConstants'

import {
  CANCEL,
  CREATE,
  CREATE_AMOUNT_CHARGE_ALL_MERCHANTS,
} from 'constants/language/languageConstants'

const CreateSubscriptionAmountForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  buttonGroup = {},
}) => {
  const onSubmit = !isEmpty(buttonGroup) ? handleSubmit : handleSubmit(submitCreateSubscriptionAmountForm)
  const isMultiSteps = !isEmpty(buttonGroup)

  return (
    <form className='CreateSubscriptionAmountForm' onSubmit={onSubmit}>
      <div className='section'>
        <Field
          name='amountNickname'
          label='Amount Nickname'
          component={InputField}
          required={false}
        />

        <AmountField
          name='amount'
          label='Amount'
        />

        <Field
          name='label'
          label='Label'
          placeholder='Label'
          component={InputField}
          required={false}
        />

        <TagsField
          name='tags'
          label='Tags'
        />

        { !isMultiSteps && <WarningMessage warningMessage={CREATE_AMOUNT_CHARGE_ALL_MERCHANTS} /> }
      </div>

      {
        isMultiSteps ?
          buttonGroup
          : (
            <div className='buttons flex space-between'>
              <Button variant='ghost' onClick={closeModal} label={CANCEL} />
              <Button type='submit' label={CREATE} />
            </div>
          )
      }
    </form>
  )
}

CreateSubscriptionAmountForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  buttonGroup: PropTypes.object,
}

export default reduxForm({
  form: CREATE_SUBSCRIPTION_AMOUNT,
  validate: validateCreateSubscriptionAmountForm,
})(CreateSubscriptionAmountForm)
