import './EmailVerificationS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import { ReactComponent as VerifyEmailSuccessIcon } from 'img/svg/verify-email-success-icon.svg'
import { ReactComponent as VerifyEmailErrorIcon } from 'img/svg/verify-email-error-icon.svg'
import { FINIX_LOGO_BLACK } from 'constants/logoConstants'

import {
  VERIFY_EMAIL_SUCCESS_TEXT_STRING,
  VERIFY_EMAIL_ERROR_TEXT_STRING,
  LOG_IN,
  VERIFY_EMAIL_AGAIN,
} from 'constants/language/languageConstants'

const EmailVerification = ({
  isFetching,
  emailVerificationSucceeded,
  resendEmail = () => {},
  emailVerificationTitleString = '',
}) => {
  if (isFetching) {
    return (
      <div className='email-verification-loader'>
        <Loader />
      </div>
    )
  }
  return (
    <>
      <div className='EmailVerification'>
        <div className='verification-modal'>
          <img src={FINIX_LOGO_BLACK} alt='finix-logo' />
          { emailVerificationSucceeded && <VerifyEmailSuccessIcon />}
          { !emailVerificationSucceeded && <VerifyEmailErrorIcon />}
          <h4 className='title'>{emailVerificationTitleString}</h4>
          <div className='text'>{emailVerificationSucceeded ? VERIFY_EMAIL_SUCCESS_TEXT_STRING : VERIFY_EMAIL_ERROR_TEXT_STRING}</div>
          { emailVerificationSucceeded && <Link to='/Login'><Button type='submit' className='redirect-to-login-button' variant='legacy-black' label={LOG_IN} /></Link>}
          { !emailVerificationSucceeded && <Button className='submit-resend-email-button' onClick={resendEmail} label={VERIFY_EMAIL_AGAIN} variant='legacy-black' />}
        </div>
      </div>
    </>
  )
}

EmailVerification.propTypes = {
  isFetching: PropTypes.bool,
  emailVerificationSucceeded: PropTypes.bool,
  resendEmail: PropTypes.func,
  emailVerificationTitleString: PropTypes.string,
}

export default EmailVerification
