import paymentsAPI from 'utilities/api/paymentsAPI'
import { ONBOARDING_FORMS } from 'constants/apiConstants'

const getOnboardingFormsAPI = ({ meta, queries, credentials }) => {
  return paymentsAPI.get({
    path: ONBOARDING_FORMS,
    queries,
    meta,
    credentials,
    service: ONBOARDING_FORMS,
  })
}

export default getOnboardingFormsAPI
