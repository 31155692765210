import removeUndefined from 'utilities/remove/removeUndefined'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { USA } from 'constants/countryConstants'

const backendPaymentInstrumentModel = ({ values }) => {
  const {
    id,
    enabled,
    tags,
    type,
    name,
    identity,
    accountNumber,
    accountType,
    routingNumber,
    country = USA,
    transitNumber,
    institutionNumber,
    thirdParty,
    thirdPartyToken,
    cardNumber,
    cvv,
    expiration,
    nameOnCard,
    postalCode,
    attemptBankAccountValidationCheck = false,
  } = values

  let valuesToSubmit

  if (thirdPartyToken) {
    valuesToSubmit = {
      third_party: thirdParty,
      third_party_token: thirdPartyToken,
      type,
      identity,
    }
  } else {
    if (type === 'BANK_ACCOUNT') {
      valuesToSubmit = {
        type,
        name,
        identity,
        account_number: accountNumber,
        account_type: accountType,
        bank_code: routingNumber,
        country,
        currency: COUNTRY_TO_CURRENCY_NAME_MAP[country],
        transit_number: transitNumber,
        institution_number: institutionNumber,
        attempt_bank_account_validation_check: attemptBankAccountValidationCheck,
      }
    }

    if (type === 'PAYMENT_CARD') {
      const [expirationMonth, expirationYear] = expiration.split('/')
      valuesToSubmit = {
        identity,
        type,
        number: cardNumber,
        security_code: cvv,
        expiration_month: expirationMonth,
        expiration_year: expirationYear,
        name: nameOnCard,
        address: {
          postal_code: postalCode,
          country,
        },
      }
    }
  }

  return removeUndefined({ id, enabled, tags, ...valuesToSubmit })
}

export default backendPaymentInstrumentModel
