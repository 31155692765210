import React from 'react'
import { DAY_MAPPING } from 'constants/timeConstants'
import toNumber from 'lodash/toNumber'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { PAYOUT_SETTINGS_SAME_DAY_ACH_ALLOWED } from 'constants/featureFlagConstants'

import {
  NET_LANGUAGE,
  GROSS_LANGUAGE,
  TYPE_NET_DESCRIPTION,
  TYPE_GROSS_DESCRIPTION,
  GROSS_FREQUENCY_DAILY_DESCRIPTION,
  GROSS_FREQUENCY_MONTHLY_DESCRIPTION,
  FEE_FREQUENCY_DAILY_DESCRIPTION,
  FEE_FREQUENCY_MONTHLY_DESCRIPTION,
  FREQUENCY_DAY_TEXT_29,
  FREQUENCY_DAY_TEXT_30,
  FREQUENCY_DAY_TEXT_31,
  DAILY_OPTION,
  MONTHLY_OPTION,
  MANUAL_OPTION,
  MANUAL_FREQUENCY_DESCRIPTION,
  NEXT_DAY_ACH_LABEL,
  NEXT_DAY_ACH_DESCRIPTION,
  SAME_DAY_ACH_LABEL,
  STANDARD_BATCH_TIME,
  STANDARD_BATCH_TIME_SUBLABEL,
  CUSTOM_BATCH_TIME,
  CUSTOM_BATCH_TIME_SUBLABEL,
} from 'constants/language/languageConstants'

export const convertDayToBusinessDay = (day, delay = 0) => {
  const dayNum = toNumber(day)
  const delayNum = toNumber(delay)
  const normalizedDay = dayNum === 6 || dayNum === 7 ? 1 : dayNum
  const delayedDay = (normalizedDay + delayNum) % 5 || 5

  return `${(dayNum + delayNum) > 5 ? 'Next' : ''} ${DAY_MAPPING[delayedDay]}`
}

export const NET = 'NET'
export const GROSS = 'GROSS'
export const CUSTOM = 'CUSTOM'
export const STANDARD = 'NEXT_DAY_ACH' // 'STANDARD'
export const FAST = 'SAME_DAY_ACH' // 'FAST'
export const DAILY = 'DAILY'
export const MANUAL = 'MANUAL'
export const MONTHLY = 'MONTHLY'
export const BEFORE_7_AM = 'Before 7 AM PT'
export const BEFORE_4_PM = 'Before 4 PM PT'
export const ESTIMATE = 'Estimate'
export const RECONCILIATION = 'RECONCILIATION'
export const SUCCESSFUL_CAPTURE = 'SUCCESSFUL_CAPTURE'
export const PROCESSOR_WINDOW = 'PROCESSOR_WINDOW'
export const CONFIGURABLE_WINDOW = 'CONFIGURABLE_WINDOW'
export const STANDARD_BATCH_TIME_VALUE = 'STANDARD'
export const CUSTOM_BATCH_TIME_VALUE = 'CUSTOM'

export const PAYOUT_SETTINGS_PAYOUT_FREQUENCY_DAY_OF_MONTH_TEXT_MAPPING = {
  29: FREQUENCY_DAY_TEXT_29,
  30: FREQUENCY_DAY_TEXT_30,
  31: FREQUENCY_DAY_TEXT_31,
}

export const PAYOUT_SETTINGS_TYPE_OPTIONS = [
  {
    label: (
      <div className='flex column'>
        <div>{NET_LANGUAGE}</div>
        <div className='supporting-text'>{TYPE_NET_DESCRIPTION}</div>
      </div>
    ),
    value: NET,
  },
  {
    label: (
      <div className='flex column'>
        <div>{GROSS_LANGUAGE}</div>
        <div className='supporting-text'>{TYPE_GROSS_DESCRIPTION}</div>
      </div>
    ),
    value: GROSS,
  },
]

export const PAYOUT_SETTINGS_PAYOUT_FREQUENCY_CORE_OPTIONS = [
  {
    label: (
      <div className='flex column'>
        <div>{DAILY_OPTION}</div>
        <div className='supporting-text'>{GROSS_FREQUENCY_DAILY_DESCRIPTION}</div>
      </div>
    ),
    value: DAILY,
  },
  {
    label: (
      <div className='flex column'>
        <div>{MONTHLY_OPTION}</div>
        <div className='supporting-text'>{GROSS_FREQUENCY_MONTHLY_DESCRIPTION}</div>
      </div>
    ),
    value: MONTHLY,
  },
  {
    label: (
      <div className='flex column'>
        <div>{MANUAL_OPTION}</div>
        <div className='supporting-text'>{MANUAL_FREQUENCY_DESCRIPTION}</div>
      </div>
    ),
    value: MANUAL,
  },
]

export const PAYOUT_SETTINGS_PAYOUT_FREQUENCY_FLEX_OPTIONS = [
  {
    label: (
      <div className='flex column'>
        <div>{DAILY_OPTION}</div>
        <div className='supporting-text'>{GROSS_FREQUENCY_DAILY_DESCRIPTION}</div>
      </div>
    ),
    value: DAILY,
  },
  {
    label: (
      <div className='flex column'>
        <div>{MONTHLY_OPTION}</div>
        <div className='supporting-text'>{GROSS_FREQUENCY_MONTHLY_DESCRIPTION}</div>
      </div>
    ),
    value: MONTHLY,
  },
]

export const PAYOUT_SETTINGS_BATCH_TIME_OPTIONS = [
  {
    label: (
        <div className='flex column'>
          <div>{STANDARD_BATCH_TIME}</div>
          <div className='supporting-text'>
              <div>Card - 10:00 PM ET</div>
              <div>ACH - 8:00 PM ET</div>
          </div>
        </div>
    ),
    value: STANDARD_BATCH_TIME_VALUE,
  },
  {
    label: (
        <div className='flex column'>
          <div>{CUSTOM_BATCH_TIME}</div>
          <div className='supporting-text'>{CUSTOM_BATCH_TIME_SUBLABEL}</div>
        </div>
    ),
    value: CUSTOM_BATCH_TIME_VALUE,
  },
]

export const PAYOUT_SETTINGS_FEE_FREQUENCY_OPTIONS = [
  {
    label: (
      <div className='flex column'>
        <div>Daily</div>
        <div className='supporting-text'>{FEE_FREQUENCY_DAILY_DESCRIPTION}</div>
      </div>
    ),
    value: DAILY,
  },
  {
    label: (
      <div className='flex column'>
        <div>Monthly</div>
        <div className='supporting-text'>{FEE_FREQUENCY_MONTHLY_DESCRIPTION}</div>
      </div>
    ),
    value: MONTHLY,
  },
]

export const PAYOUT_SETTINGS_RAIL_OPTIONS = () => {
  const sameDayACHEnabled = getFeatureFlag(PAYOUT_SETTINGS_SAME_DAY_ACH_ALLOWED)

  return [
    {
      label: (
        <div className='flex column'>
          <div>{NEXT_DAY_ACH_LABEL}</div>
          <div className='supporting-text'>{NEXT_DAY_ACH_DESCRIPTION}</div>
        </div>
      ),
      value: STANDARD,
    },
    {
      label: (
        <div className='flex column'>
          <div>{SAME_DAY_ACH_LABEL}</div>
          <div className='supporting-text'>
            Please contact your Finix point of contact or the<a href='mailto:support@finix.com' target='blank'>Finix Support team</a> to learn more.
          </div>
        </div>
      ),
      value: FAST,
      disabled: !sameDayACHEnabled,
    },
  ]
}

export const READY_TO_SETTLE_UPON_OPTIONS = [
  RECONCILIATION,
  SUCCESSFUL_CAPTURE,
  PROCESSOR_WINDOW,
]

export const SETTLE_UPON_LABEL_MAPS = {
  [RECONCILIATION]: 'Reconciliation',
  [SUCCESSFUL_CAPTURE]: 'Successful Capture',
  [PROCESSOR_WINDOW]: 'Standard',
  [CONFIGURABLE_WINDOW]: 'Custom',
}
