import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import IdentityACHReturns from './IdentityACHReturns'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { ACH_RETURNS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  AMOUNT,
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transaction'),
  ...TAG_FILTERS,
], 'label')

const quickFilters = [DATE_QUICK_FILTER]

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = ACH_RETURNS_PATH({ credentialId })

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    custom_transfer_type: 'echeck_return',
    merchant_identity_id: identityId,
  }

  return {
    allowedFilters,
    initialQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    paymentPath,
    quickFilters,
  }
}

class IdentityACHReturnsC extends Component {
  render() {
    return (
      <IdentityACHReturns {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityACHReturnsC)
