import './DeviceInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import DeviceDetailsC from 'components/Customer/Shared/Display/DeviceDetails/DeviceDetailsC'
import { PATCH_DEVICE_F_REQUEST } from 'constants/flowConstants'
import { TRIPOS_MOBILE_V1 } from 'constants/processorConstants'

import {
  CONFIGURATION_DETAILS,
  DEVICE_DETAILS,
  IDLE_MESSAGE_EMPTY_MESSAGE,
  IDLE_SCREEN,
  IDLE_SCREEN_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const DeviceInfo = ({
  isFetching = false,
  credentials = {},
  device = {},
  deviceDetailsSection = [],
  deviceDetailsActions = [],
  deviceConfigurationsSectionData = [],
  configurationDetailsActions = [],
  showIdleMessageSection = false,
  idleMessage = '',
  idleMessageActions = [],
  tagsSectionData = [],
  showTagsEditButton = false,
  processorGatewayValue = '',
  imageUrl = '',
  showIdleImage = false,
  showDeviceConfigurationsSection = true,
}) => {
  // TODO: Cata - remove check for TRIPOS_MOBILE_V1 when we have configurations supported for that gateway
  return (
    <div className='DeviceInfo'>
      { !isFetching && (
        <DeviceDetailsC
          device={device}
          credentials={credentials}
        />
      )}

      <PageSectionV2C
        title={DEVICE_DETAILS}
        columns={deviceDetailsSection}
        actions={deviceDetailsActions}
        isFetching={isFetching}
      />

      { showDeviceConfigurationsSection && (
        <PageSectionV2C
          title={CONFIGURATION_DETAILS}
          columns={deviceConfigurationsSectionData}
          actions={configurationDetailsActions}
          isFetching={isFetching}
        />
      )}

      { showIdleMessageSection && (
        <PageSectionV2C
          title={IDLE_SCREEN}
          actions={idleMessageActions}
          isFetching={isFetching}
        >
          { !isFetching && (
            <>
              {showIdleImage && (
                <>
                  {imageUrl && <img src={imageUrl} alt='idle-screen' className='idle-message-image' height={120} />}
                  {idleMessage && <div className='idle-message-text'>{`"${idleMessage}"`}</div>}

                  {!imageUrl && !idleMessage && <div className='idle-message-text'>{IDLE_SCREEN_EMPTY_MESSAGE}</div>}
                </>
              )}

              {!showIdleImage && (
                <div className='idle-message-text'>{idleMessage ? `"${idleMessage}"` : IDLE_MESSAGE_EMPTY_MESSAGE}</div>
              )}
            </>
          )}
        </PageSectionV2C>
      )}

      <TagsPageSectionV2
        data={tagsSectionData}
        actionType={showTagsEditButton ? PATCH_DEVICE_F_REQUEST : ''}
        record={device}
        isFetching={isFetching}
      />
    </div>
  )
}

DeviceInfo.propTypes = {
  isFetching: PropTypes.bool,
  credentials: PropTypes.object,
  device: PropTypes.object,
  deviceDetailsSection: PropTypes.array,
  deviceDetailsActions: PropTypes.array,
  deviceConfigurationsSectionData: PropTypes.array,
  configurationDetailsActions: PropTypes.array,
  showIdleMessageSection: PropTypes.bool,
  idleMessage: PropTypes.string,
  idleMessageActions: PropTypes.array,
  tagsSectionData: PropTypes.array,
  showTagsEditButton: PropTypes.bool,
  processorGatewayValue: PropTypes.string,
  imageUrl: PropTypes.string,
  showIdleImage: PropTypes.bool,
}

export default DeviceInfo
