import { convertTagValues } from 'utilities/convert/convertTagValues'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { UPDATE_TAGS } from 'constants/amplitudeConstants'

const submitTagsForm = (values, dispatch, props) => {
  const {
    record,
    actionType,
    credentials,
  } = props

  const { id } = record
  const newTags = convertTagValues(values)
  const newRecord = { id, tags: newTags }

  dispatch({
    type: actionType,
    payload: {
      values: newRecord,
      credentials,
      id,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPDATE_TAGS, {
        credentials,
        tags: newTags,
        resourceId: id,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default submitTagsForm
