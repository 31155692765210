import { GET_USER_PERMISSIONS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUserPermissionsRequest = ({
  userId,
  credentials,
  overwriteReducer = false,
}) => createAction({
  type: GET_USER_PERMISSIONS_F_REQUEST,
  credentials,
  id: userId,
  meta: { overwriteReducer },
})

export default getUserPermissionsRequest
