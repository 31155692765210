import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goBack } from 'state-layer/history'
import SelectAddDeviceMerchantForm from './SelectAddDeviceMerchantForm'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getApplicationsRequest from 'utilities/actions/get/getApplicationsRequest'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { SELECT_ADD_DEVICE_MERCHANT_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import values from 'lodash/values'

import {
  change,
  formValueSelector,
} from 'redux-form'

import {
  getApplicationsSelector,
  getMerchantsByApplicationIdSelector,
} from 'state-layer/selectors'

import {
  PROCESSORS_WITH_DEVICES,
  GATEWAYS_WITH_DEVICES,
} from 'constants/deviceConstants'

const mapStateToProps = (state, props) => {
  const isFetchingApplications = get(state, `applicationsR.${FETCHING}`, true)
  const credentials = getCurrentCredentials(state)
  const isRolePlatformCred = isRolePlatform({ credentials })
  const currentUser = getCurrentUser(state)
  const formSelector = formValueSelector(SELECT_ADD_DEVICE_MERCHANT_FORM)
  const selectedApplicationId = formSelector(state, 'application.value')
  const isApplicationView = get(props, 'isApplicationView')
  const applicationId = (!isRolePlatformCred || isApplicationView) ? get(props, 'applicationId') || get(currentUser, 'applicationId') : selectedApplicationId
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const filteredApplicationMerchants = filter(values(applicationMerchants), ({ processor, gateway, mid }) => mid && (includes(PROCESSORS_WITH_DEVICES, processor) || includes(GATEWAYS_WITH_DEVICES, gateway)))
  const applications = getApplicationsSelector(state)

  const applicationOptions = map(applications, (application) => {
    const {
      id,
      businessName,
    } = application

    return {
      label: businessName,
      value: id,
    }
  })

  const merchantOptions = !isEmpty(filteredApplicationMerchants) ? map(filteredApplicationMerchants, (merchant) => {
    const {
      id,
      name,
      merchantIdentity,
    } = merchant

    const displayBusinessAddress = get(merchantIdentity, 'displayBusinessAddress')

    return {
      label: (
        <div className='flex items-center'>
          {get(merchantIdentity, 'displayBusinessName') || name}
          <span className='secondary'>{displayBusinessAddress}</span>
        </div>
      ),
      value: id,
    }
  }) : []

  const noMerchantAccountsEligible = isEmpty(merchantOptions) || (isRolePlatformCred && !selectedApplicationId)

  const initialValues = isApplicationView ? {
    application: { label: get(props, 'applicationName'), value: applicationId },
  } : {}

  return {
    credentials,
    applicationId,
    applicationOptions,
    merchantOptions,
    isRolePlatformCred,
    selectedApplicationId,
    initialValues,
    isApplicationView,
    noMerchantAccountsEligible,
    isFetchingApplications,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplications: ({ credentials }) => dispatch(getApplicationsRequest({ credentials })),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    updateMerchantAccountValue: ({ newValue }) => dispatch(change(SELECT_ADD_DEVICE_MERCHANT_FORM, 'merchantAccount', newValue)),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class SelectAddDeviceMerchantFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
      isRolePlatformCred,
      getApplications,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }

    if (isRolePlatformCred) {
      getApplications({ credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      credentials,
      applicationId,
      getApplicationMerchants,
      updateMerchantAccountValue,
    } = this.props

    const {
      applicationId: prevApplicationId,
    } = prevProps

    if (prevApplicationId !== applicationId && applicationId) {
      updateMerchantAccountValue({ newValue: undefined })
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  exitModal = () => {
    const { closeModal } = this.props

    closeModal()
    goBack()
  }

  render() {
    return (
      <SelectAddDeviceMerchantForm
        {...this.props}
        exitModal={this.exitModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddDeviceMerchantFormC)
