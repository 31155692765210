import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlert from 'components/Customer/Pages/MonitoringAlert/MonitoringAlert'
import MonitoringAlertDetailsC from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertDetails/MonitoringAlertDetailsC'
import MonitoringAlertNotesC from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertNotes/MonitoringAlertNotesC'
import MonitoringAlertRelatedAlertsC from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertRelatedAlerts/MonitoringAlertRelatedAlertsC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getMany from 'utilities/get/getMany'
import getMonitoringAlertRequest from 'utilities/actions/get/getMonitoringAlertRequest'
import { FETCHING } from 'constants/reducerConstants'
import { RESOLVE_MONITORING_ALERTS_MODAL } from 'constants/modalConstants'
import { APPLICATION_PATH, MERCHANT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  getMerchantSelector,
  getMonitoringAlertSelector,
} from 'state-layer/selectors'

import {
  ALERT,
  APPLICATION_RESOURCE_TITLE,
  CASE_NOTES,
  DETAILS,
  LINKED_APPLICATION,
  LINKED_MERCHANT,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  STATUS,
  TRANSACTION_MONITORING_RELATED_ALERTS,
  TRANSACTION_MONITORING_RESOLVE_ALERT_ACTION,
  UPDATED_ON,
} from 'constants/language/languageConstants'

import {
  APPLICATION,
  MERCHANT,
  MONITORING_ALERT_STATUS_ICON_MAP,
  MONITORING_ALERT_STATUS_MAP,
  MONITORING_ALERT_STATUS_TO_DISPLAY_MAP,
  OPEN,
  RESOLVED,
} from 'constants/monitoringAlertConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `monitoringAlertsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const monitoringAlertId = get(props, 'params.monitoringAlertId')
  const monitoringAlert = getMonitoringAlertSelector(state, monitoringAlertId)

  const [
    groupedBy,
    linkedName,
    linkedTo,
    linkedType,
    status,
    displayUpdatedAt,
  ] = getMany(monitoringAlert, [
    'groupedBy',
    'linkedName',
    'linkedTo',
    'linkedType',
    'state',
    'displayUpdatedAt',
  ])

  let merchant
  let merchantIdentity
  let merchantIdentityId

  if (linkedType === MERCHANT) {
    merchant = getMerchantSelector(state, linkedTo)
    merchantIdentity = get(merchant, 'merchantIdentity')
    merchantIdentityId = get(merchantIdentity, 'id')
  }

  const canResolve = status === OPEN

  const tabs = [
    {
      id: 'details',
      name: DETAILS,
      component: MonitoringAlertDetailsC,
    },
    {
      id: 'related-alerts',
      name: TRANSACTION_MONITORING_RELATED_ALERTS,
      component: MonitoringAlertRelatedAlertsC,
    },
    {
      id: 'case-notes',
      name: CASE_NOTES,
      component: MonitoringAlertNotesC,
    },
  ]

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: linkedName,
        path: APPLICATION_PATH({ credentialId, applicationId: linkedTo }),
        condition: linkedType === APPLICATION,
      },
      {
        label: MERCHANT_ACCOUNT_RESOURCE_TITLE,
        value: linkedName,
        path: MERCHANT_PATH({ credentialId, merchantId: linkedTo, identityId: merchantIdentityId }),
        condition: linkedType === MERCHANT,
      },
    ],
  }

  const headerData = {
    resource: {
      label: ALERT,
      id: monitoringAlertId,
    },
    items: [
      {
        value: <h1>{groupedBy}</h1>,
      },
      {
        label: linkedType === APPLICATION ? LINKED_APPLICATION : LINKED_MERCHANT,
        value: linkedName,
      },
      {
        label: STATUS,
        value: <ColorcodedStatus data={MONITORING_ALERT_STATUS_MAP} value={MONITORING_ALERT_STATUS_TO_DISPLAY_MAP[status]} customIconMap={MONITORING_ALERT_STATUS_ICON_MAP} titleCase={false} />,
      },
      {
        label: UPDATED_ON,
        value: displayUpdatedAt,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    credentials,
    monitoringAlertId,
    monitoringAlert,
    tabs,
    contextBarData,
    headerData,
    canResolve,
    linkedType,
    linkedTo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMonitoringAlert: ({ monitoringAlertId, credentials }) => dispatch(getMonitoringAlertRequest({ monitoringAlertId, credentials })),
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    showResolveAlertsModal: (modalProps) => dispatch(showModalAction({ modalType: RESOLVE_MONITORING_ALERTS_MODAL, modalProps })),
  }
}

class MonitoringAlertC extends Component {
  componentDidMount() {
    const {
      credentials,
      monitoringAlertId,
      getMonitoringAlert,
      getMerchant,
      linkedType,
      linkedTo,
    } = this.props
    getMonitoringAlert({ monitoringAlertId, credentials })

    if (linkedType === MERCHANT) {
      getMerchant({ merchantId: linkedTo, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      getMerchant,
      linkedType,
      linkedTo,
    } = this.props

    const {
      linkedTo: prevLinkedTo,
    } = prevProps

    if (linkedType === MERCHANT && linkedTo !== prevLinkedTo) {
      getMerchant({ merchantId: linkedTo, credentials })
    }
  }

  resolveAlerts = () => {
    const {
      credentials,
      showResolveAlertsModal,
      monitoringAlert,
    } = this.props

    const item = merge({}, monitoringAlert, { state: RESOLVED })

    showResolveAlertsModal({ items: [item], credentials })
  }

  render() {
    const {
      canResolve,
    } = this.props

    const actions = [
      {
        label: TRANSACTION_MONITORING_RESOLVE_ALERT_ACTION,
        action: this.resolveAlerts,
        condition: canResolve,
      },
    ]

    return (
      <MonitoringAlert
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringAlertC)
