import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import { SUBMIT_CONTACT_SALES_FORM } from 'constants/amplitudeConstants'
import { POST_CONTACT_SALES_EMAIL_F_REQUEST } from 'constants/flowConstants'
import { CONTACT_SALES_TEMPLATE } from 'constants/emailTemplateConstants'
import get from 'lodash/get'


const submitContactSalesForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const currentUserEmail = get(props, 'currentUserEmail')

  const [
    title,
    approximatePaymentsVolume,
    processPayments,
    message,
  ] = getMany(values, [
    'title',
    'approximatePaymentsVolume',
    'processPayments',
    'message',
  ])

  const formValues = {
    email_template_name: CONTACT_SALES_TEMPLATE,
    reply_to_email: currentUserEmail,
    email_template_data: {
      amount: approximatePaymentsVolume,
      title,
      processPayments,
      message: message || '',
      currentUserEmail,
    },
  }

  dispatch({
    type: POST_CONTACT_SALES_EMAIL_F_REQUEST,
    payload: {
      values: formValues,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_CONTACT_SALES_FORM, {
        credentials,
        userName: currentUserEmail,
      }),
    },
  })
}

export default submitContactSalesForm
