import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM_TEMPLATE } from 'constants/apiConstants'

const patchComplianceFormTemplateAPI = ({ values, queries, credentials, id, meta }) => {
  return dashboardAPI.put({
    path: COMPLIANCE_FORM_TEMPLATE({ complianceFormTemplateId: id }),
    values,
    queries,
    credentials,
    meta,
  })
}

export default patchComplianceFormTemplateAPI
