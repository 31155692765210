import frontendPayoutSettingsModel from 'utilities/create/models/frontendPayoutSettingsModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPayoutsSettingsModel = ({ data: payoutsSettings }) => {
  const payoutsSettingsModels = map(payoutsSettings, (payoutSettings) => frontendPayoutSettingsModel({ data: payoutSettings }))

  return keyBy(payoutsSettingsModels, 'id')
}

export default frontendPayoutsSettingsModel
