import { LOCATION_CHANGE } from 'react-router-redux'
import get from 'lodash/get'

let lastPathName = ''

// https://redux-form.com/6.0.0-alpha.10/docs/faq/howtoclear.md/
const tableHeadersFormR = (state, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { payload } = action
      const pathName = get(payload, 'pathname')

      // need to check if pathname is same because that means a URL query was updated (tab / filters)
      if (pathName && pathName !== lastPathName) {
        lastPathName = pathName

        return undefined
      }

      return state
    }

    default:
      return state
  }
}

export default tableHeadersFormR
