import './CreateAPIKeyConfirmationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateAPIKeyConfirmation from 'components/Customer/Forms/CreateAPIKeyConfirmation/CreateAPIKeyConfirmation'

const CreateAPIKeyConfirmationModal = ({
  username,
  password,
}) => {
  return (
    <GenericModal
      title='API Key Created'
      className='CreateAPIKeyConfirmationModal'
      Component={CreateAPIKeyConfirmation}
      username={username}
      password={password}
    />
  )
}

CreateAPIKeyConfirmationModal.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
}

export default CreateAPIKeyConfirmationModal
