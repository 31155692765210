import './AchAndAdditionalRulesFormS.scss'
import React from 'react'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import prevFlowStep from 'utilities/prevFlowStep'
import validateAchAndAdditionalRulesForm from 'utilities/validate/validateAchAndAdditionalRulesForm'
import { EDIT_FEE_PROFILE_RULES_ACH_FORM } from 'constants/formConstants'
import { countryNameByCountryCode } from 'constants/countryConstants'
import { CURRENCY_TO_COUNTRY_NAME_MAP } from 'constants/currencyConstants'
import map from 'lodash/map'

import {
  ACH_CREDIT,
  ACH_DEBIT,
  ACH_TRANSFERS,
  ADDITIONAL_FEES,
  ADDITIONAL_FEES_SUBTITLE,
  CANCEL,
  FEE_TYPE,
  FIXED_FEE,
  MAX_UPPERCASE,
  PERCENTAGE_FEE,
  PREVIOUS_STEP,
  RETURNS,
  SAVE_AND_CONTINUE,
  SUPPLEMENTAL_FEE_1,
  SUPPLEMENTAL_FEE_2,
  MIN_UPPERCASE,
  ACH_TRANSFERS_FEE_PROFILE_RULES_EDIT_SUBTITLE,
  NA,
  REQUIRED_ACH,
} from 'constants/language/languageConstants'

const AchAndAdditionalRulesForm = ({
  handleSubmit = () => {},
  invalid = false,
  currencies = [],
}) => {
  return (
    <form className='AchAndAdditionalRulesForm' onSubmit={handleSubmit}>
      <div className='form-content'>
        <h4>{ACH_TRANSFERS}</h4>
        <div className='flex space-between'>
          <div className='p-1 secondary'>{ACH_TRANSFERS_FEE_PROFILE_RULES_EDIT_SUBTITLE}</div>
          <RequiredFieldNotice />
        </div>
        {map(currencies, currency => {
          const countryCode = CURRENCY_TO_COUNTRY_NAME_MAP[currency]
          return (
            <>
              <div className={`flex country-header ${currency}`}>
                <h5>{countryNameByCountryCode[countryCode]}</h5>
                <CountryFlagIcon country={countryCode} showCountryName={false} />
              </div>
              <div className='container-3'>
                <div className='item header p-2-bold'>{FEE_TYPE}</div>
                <div className='item header'>
                  <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
                  <div className='currency'>{currency}</div>
                </div>
                <div className='item header'>
                  <div className='p-2-bold'>{FIXED_FEE}</div>
                  <div className='currency'>{currency}</div>
                </div>
              </div>
              <div className='container-3'>
                <div className='item'>{REQUIRED_ACH}</div>
                <div className='item'>
                  <PercentageField
                    name={`${currency}.achBasisPointsMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <PercentageField
                    name={`${currency}.achBasisPointsMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
                <div className='item'>
                  <AmountField
                    name={`${currency}.achFixedFeeMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${currency}.achFixedFeeMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
              <div className='container-1'>
                <div className='item sub-header'>{RETURNS}</div>
              </div>
              <div className='container-2'>
                <div className='item'>{ACH_DEBIT}</div>
                <div className='item'>
                  <AmountField
                    name={`${currency}.achDebitReturnFixedFeeMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${currency}.achDebitReturnFixedFeeMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{ACH_CREDIT}</div>
                <div className='item'>
                  <AmountField
                    name={`${currency}.achCreditReturnFixedFeeMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${currency}.achCreditReturnFixedFeeMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
            </>
          )
        })}
        <div className='divider' />
        <h5>{ADDITIONAL_FEES}</h5>
        <div className='label-2 secondary'>{ADDITIONAL_FEES_SUBTITLE}</div>
        {map(currencies, currency => {
          const countryCode = CURRENCY_TO_COUNTRY_NAME_MAP[currency]
          return (
            <>
              <div className={`flex country-header ${currency}`}>
                <h6>{countryNameByCountryCode[countryCode]}</h6>
                <CountryFlagIcon country={countryCode} showCountryName={false} />
              </div>
              <div className='container-2'>
                <div className='item header p-2-bold'>{FEE_TYPE}</div>
                <div className='item header'>
                  <div className='p-2-bold'>{FIXED_FEE}</div>
                  <div className='currency'>{currency}</div>
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{SUPPLEMENTAL_FEE_1}</div>
                <div className='item'>
                  <AmountField
                    name={`${currency}.ancillaryFixedFeePrimaryMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${currency}.ancillaryFixedFeePrimaryMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
              <div className='container-2'>
                <div className='item'>{SUPPLEMENTAL_FEE_2}</div>
                <div className='item'>
                  <AmountField
                    name={`${currency}.ancillaryFixedFeeSecondaryMin`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${currency}.ancillaryFixedFeeSecondaryMax`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
            </>
          )
        })
      }
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SAVE_AND_CONTINUE} disabled={invalid} />
          </div>
        </div>
      </div>
    </form>
  )
}

AchAndAdditionalRulesForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  currencies: PropTypes.array,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_RULES_ACH_FORM,
  validate: validateAchAndAdditionalRulesForm,
})(AchAndAdditionalRulesForm)
