import './PageSectionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import DataSection from 'components/Customer/Shared/Page/PageSection/DataSection'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import includes from 'lodash/includes'

const PageSection = ({
  title = 'data',
  emptyMessage = '',
  actions = [],
  data = [],
  component: Component = DataSection,
  isFetching = false,
  extraProps = {},
  form = '',
  className = '',
  tooltip,
  tooltipMessage = '',
  collapse = false,
  showInfo = true,
  toggleInfo = () => {},
  subHeader = '',
  theme = {},
}) => {
  const showHeaderBorder = !includes(title, 'Details') ? 'headerBorder' : ''
  const collapseIcon = showInfo ? 'fa-chevron-up' : 'fa-chevron-down'
  const showInfoClassName = showInfo ? 'show' : 'hide'

  return (
    <section className={`PageSection ${className}`}>
      <header className={`flex space-between ${showHeaderBorder}`}>
        <div>
          <h4 className='flex'>
            {title}

            { tooltip && <TooltipLabelC message={tooltipMessage} /> }
          </h4>
          {subHeader && <p className='subheader'>{subHeader}</p>}
        </div>

        { !isEmpty(actions) && (
          <ul className='flex actions'>
            {
              map(actions, ({ label, link, action = () => {}, condition = true, icon, buttonClassName, customButtonStyles }) => {
                const button = <Button variant='secondary' className={buttonClassName} style={customButtonStyles} disabled={isFetching} label={label} rightIcon={icon} />

                return condition ? (
                  <li onClick={() => action({ extraProps })} key={label}>
                    { link ? (
                      <Link to={link} key={label}>{button}</Link>
                    ) : button }
                  </li>
                ) : null
              })
            }
          </ul>
        )}

        { collapse && (
          <i className={`collapse fa ${collapseIcon}`} onClick={toggleInfo} />
        )}
      </header>

      <div className={`content flex space-between ${showInfoClassName}`}>
        { isFetching && <Loader /> }

        { !isFetching && (
          <Component
            data={data}
            title={title}
            form={form}
            extraProps={extraProps}
            emptyMessage={emptyMessage}
            className={className}
            theme={theme}
          />
        )}
      </div>
    </section>
  )
}

PageSection.propTypes = {
  title: PropTypes.string,
  emptyMessage: PropTypes.string,
  actions: PropTypes.array,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  isFetching: PropTypes.bool,
  component: PropTypes.func,
  className: PropTypes.string,
  collapse: PropTypes.bool,
  showInfo: PropTypes.bool,
  toggleInfo: PropTypes.func,
  subHeader: PropTypes.string,
  theme: PropTypes.object,
}

export default PageSection
