import { POST_BALANCE_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { convertTagValues } from 'utilities/convert/convertTagValues'
import { CREATE_BALANCE_TRANSFER } from 'constants/amplitudeConstants'
import { BALANCE_TRANSFER_PATH } from 'constants/pathConstants'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'

const submitCreateBalanceTransferForm = (values, dispatch, props) => {
  const { credentials } = props
  const { createBalanceTransferForm = {} } = values

  const valuesToSubmit = merge(omit(createBalanceTransferForm, 'tags'), { tags: convertTagValues(createBalanceTransferForm) })

  dispatch({
    type: POST_BALANCE_TRANSFER_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const balanceTransferId = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const balanceTransferPath = BALANCE_TRANSFER_PATH({ balanceTransferId, credentialId })

        dispatch(redirectRequest({ path: balanceTransferPath }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_BALANCE_TRANSFER, {
        credentials,
        ...valuesToSubmit,
      }),
    },
  })
}

export default submitCreateBalanceTransferForm
