import React from 'react'
import PropTypes from 'prop-types'
import { LAST_UPDATED } from 'constants/language/languageConstants'
import capitalize from 'lodash/capitalize'

const LastUpdatedAt = ({
  lastUpdatedAt = '',
}) => {
  return (
    <div className='LastUpdatedAt'>
      { lastUpdatedAt && <>{capitalize(LAST_UPDATED)}: {lastUpdatedAt}</> }
    </div>
  )
}

LastUpdatedAt.propTypes = {
  lastUpdatedAt: PropTypes.string,
}

export default LastUpdatedAt
