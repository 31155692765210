import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlerts from 'components/Customer/Pages/MonitoringAlerts/MonitoringAlerts'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { FETCHING } from 'constants/reducerConstants'
import { RESOLVE_MONITORING_ALERTS_MODAL } from 'constants/modalConstants'
import { MONITORING_ALERTS } from 'constants/flowConstants'
import { OPEN, RESOLVED } from 'constants/monitoringAlertConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import map from 'lodash/map'
import pick from 'lodash/pick'

import {
  getMonitoringAlertsByStatusSelector,
  getSelectedItemsByKeySelector,
} from 'state-layer/selectors'

import {
  TRANSACTION_MONITORING_RESOLVE_ACTION,
} from 'constants/language/languageConstants'

import {
  MONITORING_ALERTS_QUICK_FILTER,
  MONITORING_ALERTS_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  MONITORING_ALERT_NAME,
  MONITORING_ALERT_LINKED_NAME,
  MONITORING_ALERT_LINKED_TO,
  MONITORING_ALERT_LINKED_TYPE,
} from 'constants/filterConstants'

import {
  MONITORING_ALERTS_PATH,
} from 'constants/pathConstants'

const initialQueries = {
  sort: 'created_at,desc',
}

const initialFilters = {
  state: {
    eq: OPEN,
  },
}

const quickFilters = [
  MONITORING_ALERTS_QUICK_FILTER,
  MONITORING_ALERTS_DATES_QUICK_FILTER,
]

const allowedFilters = [
  MONITORING_ALERT_NAME,
  MONITORING_ALERT_LINKED_NAME,
  MONITORING_ALERT_LINKED_TO,
  MONITORING_ALERT_LINKED_TYPE,
]

const selectedItemsKey = MONITORING_ALERTS

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `monitoringAlertsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const monitoringAlertPath = MONITORING_ALERTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const filterState = getUrlQuery('state')
  const isResolved = filterState === RESOLVED
  const selectedMonitoringAlertIds = Object.keys(getSelectedItemsByKeySelector(state, selectedItemsKey))
  const monitoringAlerts = getMonitoringAlertsByStatusSelector(state, filterState)

  return {
    initialQueries: mergedQueries,
    isFetching,
    credentials,
    quickFilters,
    allowedFilters,
    monitoringAlerts,
    selectedMonitoringAlertIds,
    selectedItemsKey,
    initialFilters,
    isResolved,
    selector: (currentState) => getMonitoringAlertsByStatusSelector(currentState, filterState),
    monitoringAlertPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showResolveAlertsModal: (modalProps) => dispatch(showModalAction({ modalType: RESOLVE_MONITORING_ALERTS_MODAL, modalProps })),
  }
}

class MonitoringAlertsC extends Component {
  resolveAlerts = () => {
    const {
      credentials,
      monitoringAlerts,
      selectedMonitoringAlertIds,
      showResolveAlertsModal,
    } = this.props

    const selectedMonitoringAlerts = pick(monitoringAlerts, selectedMonitoringAlertIds)
    const items = map(selectedMonitoringAlerts, (item) => merge({}, item, { state: RESOLVED }))

    showResolveAlertsModal({ items, credentials, selectedItemsKey })
  }

  render() {
    const {
      isResolved,
    } = this.props

    const selectedItemsData = !isResolved ? {
      actions: [
        {
          label: TRANSACTION_MONITORING_RESOLVE_ACTION,
          action: this.resolveAlerts,
        },
      ],
    } : undefined

    return (
      <MonitoringAlerts
        selectedItemsData={selectedItemsData}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringAlertsC)
