import './RecipientDetailS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import underwritingLogsColumnDescriptors from './underwritingLogsColumnDescriptors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import TableC from 'components/Shared/Table/TableC'
import RecipientVelocityLimitsC from 'components/Customer/Pages/Recipient/RecipientVelocityLimits/RecipientVelocityLimitsC'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { PATCH_IDENTITY_F_REQUEST, UNDERWRITING_REVIEW } from 'constants/flowConstants'

import {
  OWNER_DETAILS,
  RECIPIENT_DETAILS,
  BUSINESS_DETAILS,
  UNDERWRITING_LOGS,
  UNDERWRITING_REVIEWS_TABLE_EMPTY_MESSAGE,
  RECIPIENT_IDENTITY_UNDERWRITING_REVIEW,
} from 'constants/language/languageConstants'


const RecipientDetail = ({
  merchantId = '',
  ownerData = [],
  businessData = [],
  isFetching = false,
  isBusinessRecipient = false,
  businessDescriptionString = '',
  identityId = '',
  underwritingReviews = {},
  underwritingReviewPath = '',
  hasUnderwritingPermissions = false,
  tagsSectionData,
  identity,
}) => {
  return (
    <div className='RecipientDetail'>
      {isBusinessRecipient && (
        <PageSectionV2C
          title={BUSINESS_DETAILS}
          columns={convertPageSectionDataToV2(businessData)}
          isFetching={isFetching}
          className='business-details-section'
          subTitle={businessDescriptionString}
        />
      )}

      <PageSectionV2C
        title={isBusinessRecipient ? OWNER_DETAILS : RECIPIENT_DETAILS}
        columns={convertPageSectionDataToV2(ownerData)}
        isFetching={isFetching}
        className='owner-details-section'
      />

      <RecipientVelocityLimitsC
        merchantId={merchantId}
      />

      {hasUnderwritingPermissions && !isFetching && (
        <TableC
          title={UNDERWRITING_LOGS}
          columnDescriptors={underwritingLogsColumnDescriptors}
          data={underwritingReviews}
          entityType={UNDERWRITING_REVIEW}
          emptyMessage={UNDERWRITING_REVIEWS_TABLE_EMPTY_MESSAGE}
          identityId={identityId}
          path={underwritingReviewPath}
          fileNameCSV={`${RECIPIENT_IDENTITY_UNDERWRITING_REVIEW} ${identityId}`}
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_IDENTITY_F_REQUEST}
        data={tagsSectionData}
        record={identity}
        isFetching={isFetching}
      />
    </div>
  )
}

RecipientDetail.propTypes = {
  ownerData: PropTypes.array,
  businessData: PropTypes.array,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  isBusinessRecipient: PropTypes.bool,
  businessDescriptionString: PropTypes.string,
  identityId: PropTypes.string,
  underwritingReviews: PropTypes.object,
  underwritingReviewPath: PropTypes.string,
  hasUnderwritingPermissions: PropTypes.bool,
  tagsSectionData: PropTypes.array,
  identity: PropTypes.object,
}

export default RecipientDetail
