import './UpdateAccountBalanceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import validateUpdateAccountBalanceForm from 'utilities/validate/validateUpdateAccountBalanceForm'
import submitUpdateAccountBalanceForm from 'utilities/submit/submitUpdateAccountBalanceForm'
import { UPDATE_ACCOUNT_BALANCE_FORM } from 'constants/formConstants'
import { ALERT_ICON } from 'constants/iconConstants'

import {
  AMOUNT,
  CANCEL,
  DESCRIPTION,
  NOTE,
  UPDATE_ACCOUNT_BALANCE,
  UPDATE_ACCOUNT_BALANCE_WARNING_MESSAGE,
  WIRE_REFERENCE_NUMBER,
} from 'constants/language/languageConstants'

import {
  ADJUSTMENT_TYPE_LABEL,
  ADJUSTMENT_TYPE_OPTIONS,
} from 'constants/accountBalanceConstants'

const UpdateAccountBalanceForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  updateAccountBalanceModalSubtitle = '',
}) => {
  return (
    <form className='UpdateAccountBalanceForm' onSubmit={handleSubmit(submitUpdateAccountBalanceForm)}>
      <div className='form-header p-2'>
        <div className='subtitle'>{updateAccountBalanceModalSubtitle}</div>
      </div>

      <div className='form-content'>
        <Field
          name='balanceEntryType'
          component={RadioButton}
          options={ADJUSTMENT_TYPE_OPTIONS}
          label={ADJUSTMENT_TYPE_LABEL}
          required
        />

        <AmountField
          name='balanceEntryAmount'
          placeholder='0.00'
          label={AMOUNT}
          required
        />

        <Field
          name='wireReferenceNumber'
          component={InputField}
          label={WIRE_REFERENCE_NUMBER}
          required={false}
        />

        <Field
          name='description'
          component={InputField}
          label={NOTE}
          placeholder={DESCRIPTION}
          required={false}
        />
      </div>

      <div className='warning-container'>
        <div className='warning-message'><i className={`fa fa-${ALERT_ICON}`} />{UPDATE_ACCOUNT_BALANCE_WARNING_MESSAGE}</div>
      </div>

      <div className='sticky-footer'>
        <div className='btn-container flex flex-end'>
          <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button type='submit' label={UPDATE_ACCOUNT_BALANCE} />
        </div>
      </div>
    </form>
  )
}

UpdateAccountBalanceForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  updateAccountBalanceModalSubtitle: PropTypes.string,
}

export default reduxForm({
  form: UPDATE_ACCOUNT_BALANCE_FORM,
  validate: validateUpdateAccountBalanceForm,
})(UpdateAccountBalanceForm)
