import getSettlementAPI from 'api/finix/get/getSettlementAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getIdentityPaymentInstrumentsAPI from 'api/finix/get/getIdentityPaymentInstrumentsAPI'
import getSettlementCalculatedFundingDetailsAPI from 'api/finix/get/getSettlementCalculatedFundingDetailsAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getPayoutProfileAPI from 'api/finix/get/getPayoutProfileAPI'
import getReviewQueueAPI from 'api/finix/get/getReviewQueueAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendTransfersModel from 'utilities/create/models/frontendTransfersModel'
import frontendReviewQueueItemModel from 'utilities/create/models/frontendReviewQueueItemModel'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendPayoutSettingsModel from 'utilities/create/models/frontendPayoutSettingsModel'
import getMany from 'utilities/get/getMany'
import { SETTLEMENT } from 'constants/reviewQueueConstants'
import merge from 'lodash/merge'
import head from 'lodash/head'
import get from 'lodash/get'
import map from 'lodash/map'
import co from 'co'

function * getSettlementO ({
  id,
  credentials,
}) {
  const { data: response } = yield getSettlementAPI({ id, credentials })
  const links = getResponseLinks(response)

  const [
    transfersLink,
    fundingTransfersLink,
  ] = getMany(links, [
    'transfers',
    'funding_transfers',
  ])

  const {
    id: settlementId,
    source,
    destination,
  } = response

  const paymentInstrumentId = source || destination

  // TODO: these fail in v2, do we still need them?
  const [
    { data: transfersResponse },
    { data: paymentInstrument },
    { data: fundingTransfersResponse },
  ] = yield [
    transfersLink ? getPaymentsAPI({ absolutePath: transfersLink, credentials }) : {},
    paymentInstrumentId ? getPaymentInstrumentAPI({ id: paymentInstrumentId, credentials }) : {},
    fundingTransfersLink ? getPaymentsAPI({ absolutePath: fundingTransfersLink, credentials }) : {},
  ]

  const transferKey = '_embedded.settlement_entries'
  const transfers = get(transfersResponse, transferKey, [])
  // TODO: update transferses -> transfers once BE fixes naming
  const fundingTransferKey = '_embedded.transferses'
  const fundingTransfers = get(fundingTransfersResponse, fundingTransferKey, [])

  const merchantId = get(response, 'merchant')
  const { data: merchant } = yield getMerchantAPI({ id: merchantId, credentials })
  const merchantModel = merchant ? frontendMerchantModel({ data: merchant }) : {}

  const payoutPlanId = get(response, 'payout_plan')
  const { data: payoutProfile } = yield getPayoutProfileAPI({ id: payoutPlanId, credentials })
  const payoutProfileModel = frontendPayoutSettingsModel({ data: payoutProfile })

  const merchantIdentityId = get(merchantModel, 'identityId')
  const { data: merchantIdentity } = yield getIdentityAPI({ id: merchantIdentityId, credentials })
  const merchantIdentityModel = frontendIdentityModel({ data: merchantIdentity })

  const { data: settlementPaymentInstrumentsResponse } = merchantIdentityId ? yield getIdentityPaymentInstrumentsAPI({ values: { identityId: merchantIdentityId }, credentials }) : {}
  const settlementPaymentInstruments = get(settlementPaymentInstrumentsResponse, '_embedded.payment_instruments')

  const { data: settlementCalculatedFundingDetailsResponse } = yield getSettlementCalculatedFundingDetailsAPI({ id: settlementId, credentials })
  const settlementCalculatedFundingDetails = get(settlementCalculatedFundingDetailsResponse, 'calculated_funding_details')

  const applicationId = get(response, 'application')
  const { data: application } = yield getApplicationAPI({ id: applicationId, credentials })
  const applicationModel = frontendApplicationModel({ data: application })

  const transferModels = frontendTransfersModel({ data: transfers })
  const fundingTransferModels = frontendTransfersModel({ data: fundingTransfers })
  const paymentInstrumentModel = frontendPaymentInstrumentModel({ data: paymentInstrument })

  const { data: reviewQueueResponse } = yield getReviewQueueAPI({
    credentials,
    queries: {
      entity_type: SETTLEMENT,
      entity_id: settlementId,
    },
  })

  const reviewQueueItem = head(get(reviewQueueResponse, '_embedded.review_queue_items'))
  const reviewQueueItemModel = frontendReviewQueueItemModel({ data: reviewQueueItem })

  // TODO: rename application to applicationId and applicationModel to application
  const settlement = merge({}, response, {
    identity: merchantIdentityId,
    transfers: transferModels,
    paymentInstrument: paymentInstrumentModel,
    fundingTransfers: fundingTransferModels,
    merchant_id: merchantId,
    merchant: merchantModel,
    paymentInstruments: settlementPaymentInstruments,
    reviewQueueItem: reviewQueueItemModel,
    calculatedFundingDetails: settlementCalculatedFundingDetails,
    applicationModel,
    merchantIdentity: merchantIdentityModel,
    payoutProfile: payoutProfileModel,
  })

  return createSuccessResponse({
    data: [settlement],
  })
}

export default co.wrap(getSettlementO)
