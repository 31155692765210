import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const validateExportDataForm = (values) => {
  const errors = reduce(values, (total, fieldValues, fieldName) => {
    const { title } = fieldValues

    return merge({}, total, {
      [fieldName]: {
        title: isEmpty(title) && 'Field Title must not be empty.',
      },
    })
  })

  return errors
}

// TODO: short-hand? seems harder to read
// const validateExportDataForm = (values) => reduce(values, (total, { title }, fieldName) => merge({}, total, { [fieldName]: { title: isEmpty(title) && 'Field Title must not be empty.' } }))

export default validateExportDataForm
