import './EnableDisableWebhookModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EnableDisableWebhookC from 'components/Customer/Pages/EnableDisableWebhook/EnableDisableWebhookC'

import {
  ENABLE_WEBHOOK,
  DISABLE_WEBHOOK,
} from 'constants/language/languageConstants'

const EnableDisableWebhookModal = ({
  webhookId = '',
  enabled = false,
  isAcceptingEvents = false,
  credentials,
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title={enabled ? DISABLE_WEBHOOK : ENABLE_WEBHOOK}
      className='EnableDisableWebhookModal'
      Component={EnableDisableWebhookC}
      webhookId={webhookId}
      enabled={enabled}
      isAcceptingEvents={isAcceptingEvents}
      credentials={credentials}
      closeModal={closeModal}
    />
  )
}

EnableDisableWebhookModal.propTypes = {
  webhookId: PropTypes.string,
  enabled: PropTypes.bool,
  isAcceptingEvents: PropTypes.bool,
  credentials: PropTypes.object,
  closeModal: PropTypes.func,
}

export default EnableDisableWebhookModal
