import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SetupMFAMobileAuthenticatorFormC from 'components/Customer/Forms/SetupMFAMobileAuthenticatorForm/SetupMFAMobileAuthenticatorFormC'

import {
  SETUP_MULTI_FACTOR_AUTHENTICATION,
  PLEASE_FOLLOW_STEPS_BELOW_TO_SET_UP_MFA,
} from 'constants/language/languageConstants'

const SetupMFAMobileAuthenticatorModal = ({
  type,
  token,
  barcodeUri,
  mfaId,
  oobCode,
  recoveryCodes,
}) => {
  return (
    <GenericModal
      className='SetupMFAMobileAuthenticatorModal'
      title={SETUP_MULTI_FACTOR_AUTHENTICATION}
      subTitle={PLEASE_FOLLOW_STEPS_BELOW_TO_SET_UP_MFA}
    >
      <SetupMFAMobileAuthenticatorFormC
        type={type}
        token={token}
        barcodeUri={barcodeUri}
        mfaId={mfaId}
        oobCode={oobCode}
        recoveryCodes={recoveryCodes}
      />
    </GenericModal>
  )
}

SetupMFAMobileAuthenticatorModal.propTypes = {
  token: PropTypes.string,
  barcodeUri: PropTypes.string,
  mfaId: PropTypes.string,
  oobCode: PropTypes.string,
  recoveryCodes: PropTypes.array,
}

export default SetupMFAMobileAuthenticatorModal
