import './TableHeadersS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import { t } from 'constants/language/languageConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import invert from 'lodash/invert'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

const TableHeaders = ({
  headers,
  toggleSort = () => {},
  sort = '',
  selectedItems,
  selectedItemsAction = [],
  selectAllItemsHandler = () => {},
  items = [],
  action = '',
  selectSingleItem = false,
  relatedItemsKey,
  selectedItemsData = {},
}) => {
  const showSelectButtons = !isEmpty(selectedItemsAction) || !isEmpty(get(selectedItemsData, 'actions')) || selectSingleItem
  const selectedItemsCount = size(selectedItems)
  const indeterminate = selectedItemsCount > 0 && selectedItemsCount < items.length

  const checkboxClassnames = classnames({
    checkbox: true,
    'has-related-items': !!relatedItemsKey,
  })

  const headerItemClassnames = classnames({
    'header-items': true,
    'has-checkbox': showSelectButtons,
    'has-related-items': !!relatedItemsKey,
  })

  return (
    <ul className='TableHeaders columns flex items-center'>
      { showSelectButtons && (
        <>
          <li className={checkboxClassnames} key='checkbox'>
            <CheckboxC
              name='selectAll'
              checked={selectedItemsCount > 0 || action === 'Approve All'}
              handleChange={selectAllItemsHandler}
              indeterminate={indeterminate}
              action={action}
              disabled={selectSingleItem}
            />
          </li>
        </>
      )}

      <li className={headerItemClassnames}>
        <ul className='flex'>
          {
            map(headers, ({
              id,
              sort: itemSorts,
              className,
              displayValue,
              showColumn,
            }) => {
              const columnSorted = get(invert(itemSorts), sort, false)
              const sortOrder = columnSorted || 'asc'
              const sortDirections = sortOrder === 'desc' ? 'down' : 'up'
              const handleToggleSort = (e) => { if (allowedKeyPressControls(e)) toggleSort(itemSorts, sortOrder) }

              const iconClassName = classnames('fa', {
                'fa-caret-down': columnSorted && sortOrder === 'desc',
                'fa-caret-up': columnSorted && sortOrder === 'asc',
                'fa-sort': !columnSorted,
              })

              return showColumn ? (
                <li key={id} className={className}>
                  <span className='bold'>{t(displayValue)}</span>

                  { itemSorts && (
                    <i
                      role='button'
                      aria-label='column sort'
                      tabIndex='0'
                      className={iconClassName}
                      onClick={handleToggleSort}
                      onKeyDown={handleToggleSort}
                    />
                  )}
                </li>
              ) : null
            })
          }
        </ul>
      </li>
    </ul>
  )
}

TableHeaders.propTypes = {
  headers: PropTypes.array,
  toggleSort: PropTypes.func,
  sort: PropTypes.string,
  selectedItemsAction: PropTypes.array,
  selectAllItemsHandler: PropTypes.func,
  items: PropTypes.array,
  action: PropTypes.string,
  selectSingleItem: PropTypes.bool,
  relatedItemsKey: PropTypes.string,
  selectedItemsData: PropTypes.object,
}

export default TableHeaders
