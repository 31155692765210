import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResponseFiles from './ResponseFiles'

export const RESPONSE_FILE_UPLOAD_INSTRUCTIONS = [
  {
    name: 'main',
    message: 'Drag and drop, or click to select and upload a file',
  },
  {
    name: 'sub',
    message: 'Accept: xml; Max Size: 2 MB',
  },
]

const mapStateToProps = (state) => {
  return {
    instructions: RESPONSE_FILE_UPLOAD_INSTRUCTIONS,
  }
}

class ResponseFilesC extends Component {
  render() {
    return (
      <ResponseFiles
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ResponseFilesC)
