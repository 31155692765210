import './ExportDataV2FormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import camelCaseToTitleCase from 'utilities/display/camelCaseToTitleCase'
import submitExportDataV2Form from 'utilities/submit/submitExportDataV2Form'
import displayCurrentFilterValue from 'utilities/display/displayCurrentFilterValue'
import { CREATED_AT_FIELD } from 'constants/filterConstants'
import { EXPORT_DATA_V2_FORM } from 'constants/formConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'

import {
  FILTERED_BY,
  FIELDS_TO_EXPORT,
  NO_FILTERS_APPLIED,
  INITIATE_EXPORT,
  SELECT_ALL,
} from 'constants/language/languageConstants'

const ExportDataV2Form = ({
  handleSubmit = () => {},
  dataKeys = {},
  currentFilters = {},
  fileNameCSV = '',
  allFieldsSelected = true,
  setAllFieldsSelected = () => {},
  isButtonEnabled,
}) => {
  // find all entries from collection that do not have an empty value
  const normalizedCurrentFilters = pickBy(currentFilters, (val) => !isEmpty(val))

  const noFiltersApplied = isEmpty(normalizedCurrentFilters)

  return (
    <form className='ExportDataV2Form' onSubmit={handleSubmit(submitExportDataV2Form)}>
      {noFiltersApplied && <div className='no-filters-msg'>{NO_FILTERS_APPLIED}</div>}

      {!noFiltersApplied && (
        <div className='filtered-by-section'>
          <h2>{`${FILTERED_BY}:`}</h2>

          <div className='current-filters flex wrap'>
            {map(Object.keys(normalizedCurrentFilters), (currentFilter, index) => {
              const filterValueObj = get(normalizedCurrentFilters, currentFilter)

              return (
                !isEmpty(filterValueObj) && (
                <div className='flex filter-display'>
                  <div className='p-2-bold'>{currentFilter === CREATED_AT_FIELD ? 'Created' : camelCaseToTitleCase(camelCase(currentFilter))}:</div>
                  <div className='filter-value'>{displayCurrentFilterValue({ filterKey: currentFilter, filterValueObj, resource: fileNameCSV })}</div>
                </div>
                )
              )
            })}
          </div>
        </div>
      )}

      <div className='section-divider ' />

      <div className='fields-to-export-section'>
        <h2>{`${FIELDS_TO_EXPORT}:`}</h2>
        <li className='flex items-center select-all-container'>
          <CheckboxC
            checked={allFieldsSelected}
            handleChange={() => setAllFieldsSelected(!allFieldsSelected)}
          />
          <div className='p-2-bold label'>{SELECT_ALL}</div>
        </li>

        <ul className='grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 flex wrap'>
          {
            map(dataKeys, (key) => {
              const initialTitle = key === 'id' ? 'ID' : camelCaseToTitleCase(key)

              return (
                <li key={key} className='flex items-center'>
                  <Field
                    component={CheckboxInput}
                    name={key}
                  />
                  <div className='label'>{initialTitle}</div>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div className='btn-container flex flex-end'>
        <Button type='submit' className='initiate-export-btn' label={INITIATE_EXPORT} disabled={!isButtonEnabled} />
      </div>
    </form>
  )
}

ExportDataV2Form.propTypes = {
  handleSubmit: PropTypes.func,
  dataKeys: PropTypes.array,
  currentFilters: PropTypes.object,
  fileNameCSV: PropTypes.string,
  allFieldsSelected: PropTypes.bool,
  setAllFieldsSelected: PropTypes.func,
  isButtonEnabled: PropTypes.bool,
}

export default reduxForm({
  form: EXPORT_DATA_V2_FORM,
})(ExportDataV2Form)
