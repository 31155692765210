import frontendNotificationModel from './frontendNotificationModel'
import get from 'lodash/get'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'

const frontendNotificationsModel = ({ data: notificationsData }) => {
  const notifications = get(notificationsData, 'items')

  const notificationsModels = map(notifications, (notification) => frontendNotificationModel({ data: notification }))

  return keyBy(notificationsModels, 'id')
}

export default frontendNotificationsModel
