import './ConnectDeviceForPaymentModalS.scss'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getDispatch } from 'state-layer/configureStore'
import getDeviceAPI from 'api/finix/get/getDeviceAPI'
import postTransferAPI from 'api/finix/post/postTransferAPI'
import postAuthorizationAPI from 'api/finix/post/postAuthorizationAPI'
import Card from 'components/SVG/Card'
import Button from 'components/Shared/Button/Button'
import Terminal from 'components/SVG/Terminal'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import redirectRequest from 'utilities/actions/redirectRequest'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import { CREATE_TRANSACTION_CONFIRMATION_PATH } from 'constants/pathConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'

import {
  ALERT_ICON,
  LOAD_ICON_V2,
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

const ConnectDeviceForPaymentModal = ({
  amount = '',
  currency = USD,
  isSale = false,
  deviceId = '',
  deviceName = '',
  credentials = {},
  closeModal = () => {},
}) => {
  const [status, setStatus] = useState('connecting')

  useEffect(() => {
    async function connectToDevice() {
      setStatus('connecting')

      const {
        data: connectionData,
        error: connectionError,
      } = await getDeviceAPI({
        id: deviceId,
        credentials,
        queries: {
          include_connection: true,
        },
        meta: {
          customApiConfigs: {
            // This request can hang, product decision to cut it off after 5 seconds, should double-check this with them (thinking of slow internet connections)
            timeout: 5000,
          },
        },
      })

      if (connectionError) {
        setStatus('connecting-failed')
        return
      }

      if (get(connectionData, 'connection') === 'Closed') {
        setStatus('connecting-failed')
        return
      }

      setStatus('pending-payment-processing')

      const postAPI = isSale ? postTransferAPI : postAuthorizationAPI

      const {
        data: transferData,
        error: transferError,
      } = await postAPI({
        values: {
          currency,
          amount: displayAmountToAmount(amount, currency),
          device: deviceId,
          operation_key: isSale ? 'CARD_PRESENT_DEBIT' : 'CARD_PRESENT_AUTHORIZATION',
        },
        credentials,
      })

      const transferId = get(transferData, 'id')

      if (!transferId || transferError) {
        setStatus('payment-failed')
        return
      }

      setStatus('payment-successful')

      setTimeout(() => {
        closeModal()

        const transactionType = isSale ? 'sale' : 'authorization'
        const credentialId = get(credentials, 'id')
        const paymentConfirmationPath = `${CREATE_TRANSACTION_CONFIRMATION_PATH({ credentialId, entityId: transferId })}?type=${transactionType}&deviceId=${deviceId}`

        const dispatch = getDispatch()
        dispatch(redirectRequest({ path: paymentConfirmationPath }))
      }, 1000)
    }

    connectToDevice()
  }, [])

  const isConnecting = status === 'connecting'
  const connectingFailed = status === 'connecting-failed'
  const pendingPaymentProcessing = status === 'pending-payment-processing'
  const paymentSuccessful = status === 'payment-successful'
  const paymentFailed = status === 'payment-failed'

  return (
    <GenericModal className='ConnectDeviceForPaymentModal'>
      <div className='container'>
        { isConnecting && (
          <>
            <h5>Connecting to {deviceName}</h5>
            <p className='label-2 secondary'>Please do not refresh this page.</p>
            <i className={`connecting-loader fas fa-${LOAD_ICON_V2}`} />
          </>
        )}

        { connectingFailed && (
          <>
            <h5>Device connection failed</h5>
            <p className='label-2 secondary'>Ensure {deviceName} is connected to the internet and try again.</p>
            <i className={`connecting-failed fa fa-${ALERT_ICON}`} />
          </>
        )}

        { pendingPaymentProcessing && (
          <>
            <h5>Ready for payment</h5>
            <p className='label-2 secondary'>Device is ready for card tap, swipe or dip.</p>

            <div className='payment-processing-icons flex center'>
              <Terminal />
              <div className='animated-gap' />
              <Card />
            </div>
          </>
        )}

        { paymentSuccessful && (
          <>
            <i className={`payment-success-icon far fa-${CHECK_CIRCLE_ICON}`} />
            <h5>Payment successful</h5>
            <p className='label-2 secondary'>You can close this modal and proceed.</p>
          </>
        )}

        { paymentFailed && (
          <>
            <i className={`payment-failure-icon fa fa-${ALERT_ICON}`} />
            <h5>Payment failed</h5>
            <p className='label-2'>The transaction was declined for an unknown reason. Please contact the card issuer for more information or try a different payment method.</p>
          </>
        )}
      </div>

      { pendingPaymentProcessing && (
        <div className='buttons flex flex-end'>
          <Button onClick={closeModal} label='Cancel' variant='destructive-ghost' className='cancel-button' />
        </div>
      )}

      { paymentSuccessful && (
        <div className='buttons flex flex-end'>
          <Button type='submit' onClick={closeModal} label='Done' />
        </div>
      )}

      { paymentFailed && (
        <div className='buttons flex flex-end'>
          <Button onClick={closeModal} label='Close' variant='ghost' className='cancel-button' />
          <Button type='submit' label='Retry' />
        </div>
      )}
    </GenericModal>
  )
}

ConnectDeviceForPaymentModal.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  isSale: PropTypes.bool,
  deviceId: PropTypes.string,
  deviceName: PropTypes.string,
  credentials: PropTypes.object,
  closeModal: PropTypes.func,
}

export default ConnectDeviceForPaymentModal
