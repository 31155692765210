import {
  USA,
  CAN,
  FRA,
} from 'constants/countryConstants'

// ISO 639-1 Language Codes
export const EN = 'en'
export const ES = 'es'
export const FR = 'fr'

// ISO 3166-1 Alpha-2 Country Codes (used for language localization)
export const EN_US = 'en-US'
export const FR_FR = 'fr-FR'
export const FR_CA = 'fr-CA'

// Includes ISO 639-1 and ISO 3166-1 Alpha-2 Country Codes
export const languageCodeToCountryCodeISO3 = {
  [EN]: USA, // Default USA country code when english is selected
  [EN_US]: USA,
  [FR]: FRA,
  [FR_FR]: FRA,
  [FR_CA]: CAN,
}

export const languageCodeMap = {
  [EN]: EN,
  [EN_US]: EN,
  [ES]: ES,
  [FR]: FR,
  [FR_FR]: FR,
  [FR_CA]: FR_CA,
}
