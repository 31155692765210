import redirectRequest from 'utilities/actions/redirectRequest'
import getMany from 'utilities/get/getMany'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { SUBMIT_LIVE_ACCOUNT_APPLICATION } from 'constants/amplitudeConstants'
import get from 'lodash/get'

import {
  POST_SUBMIT_ACCESS_FORM_F_REQUEST,
  POST_SUBMIT_ACCESS_FORM_SALES_REVIEW_F_REQUEST,
} from 'constants/flowConstants'

import {
  CUSTOMER_ONBOARDING_SUCCESS_PATH,
  LIVE_ACCOUNT_APPLICATION_PATH,
} from 'constants/pathConstants'

const submitLiveAccountApplicationReviewAndSubmitForm = (values, dispatch, props) => {
  const [
    credentials,
    accessFormId,
    contractId,
    dashboardType,
    isHostedAccessForm = false,
    personaInquiryId,
  ] = getMany(props, [
    'credentials',
    'accessFormId',
    'contractId',
    'dashboardType',
    'isHostedAccessForm',
    'inquiryId',
  ])

  const {
    inquiryId,
  } = values

  if (isHostedAccessForm) {
    dispatch({
      type: POST_SUBMIT_ACCESS_FORM_SALES_REVIEW_F_REQUEST,
      payload: {
        values: removeUndefined({
          access_form_id: accessFormId,
          persona_inquiry_id: inquiryId || personaInquiryId,
        }),
      },
      meta: {
        successCallback: () => {
          const customerOnboardingFormSuccessPath = CUSTOMER_ONBOARDING_SUCCESS_PATH
          dispatch(redirectRequest({ path: customerOnboardingFormSuccessPath }))
        },
        actionId: sendAmplitudeActionEvent(SUBMIT_LIVE_ACCOUNT_APPLICATION, {
          accessFormId,
          dashboardType,
        }),
      },
    })
  }

  if (!isHostedAccessForm) {
    dispatch({
      type: POST_SUBMIT_ACCESS_FORM_F_REQUEST,
      payload: {
        values: {
          id: accessFormId,
          agreement_details: removeUndefined({
            agreement_id: contractId,
            persona_inquiry_id: inquiryId || personaInquiryId,
          }),
        },
      },
      meta: {
        successCallback: () => {
          const credentialId = get(credentials, 'id')
          const liveAccountApplicationPath = LIVE_ACCOUNT_APPLICATION_PATH({ accessFormId, credentialId })

          dispatch(redirectRequest({ path: liveAccountApplicationPath }))
        },
        actionId: sendAmplitudeActionEvent(SUBMIT_LIVE_ACCOUNT_APPLICATION, {
          accessFormId,
          dashboardType,
        }),
      },
    })
  }
}

export default submitLiveAccountApplicationReviewAndSubmitForm
