import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import VerifyMFASMSAuthenticationCodeFormC from 'components/Customer/Forms/VerifyMFASMSAuthenticationCodeForm/VerifyMFASMSAuthenticationCodeFormC'

import {
  SETUP_MULTI_FACTOR_AUTHENTICATION,
  PLEASE_ENTER_THE_AUTHENTICATION_CODE_SENT_TO_YOUR_MOBILE_NUMBER,
} from 'constants/language/languageConstants'

const VerifyMFASMSAuthenticationCodeModal = ({
  token,
  phone,
  mfaId,
  oobCode,
}) => {
  return (
    <GenericModal
      className='VerifyMFASMSAuthenticationCodeModal'
      title={SETUP_MULTI_FACTOR_AUTHENTICATION}
      subTitle={PLEASE_ENTER_THE_AUTHENTICATION_CODE_SENT_TO_YOUR_MOBILE_NUMBER}
    >
      <VerifyMFASMSAuthenticationCodeFormC
        token={token}
        phone={phone}
        mfaId={mfaId}
        oobCode={oobCode}
      />
    </GenericModal>
  )
}

VerifyMFASMSAuthenticationCodeModal.propTypes = {
  token: PropTypes.string,
  phone: PropTypes.string,
  mfaId: PropTypes.string,
  oobCode: PropTypes.string,
}

export default VerifyMFASMSAuthenticationCodeModal
