import removeUndefined from 'utilities/remove/removeUndefined'

const backendUnderwritingWorkflowModel = ({ values }) => {
  const {
    linkedTo,
    actOnDecision,
    businessRecipientsEnabled,
    personalRecipientsEnabled,
  } = values

  const definition = {
    act_on_decision: actOnDecision,
  }

  return removeUndefined({
    linked_to: linkedTo,
    definition,
    recipient_settings: {
      business_recipients_enabled: businessRecipientsEnabled,
      personal_recipients_enabled: personalRecipientsEnabled,
    },
  })
}

export default backendUnderwritingWorkflowModel
