import './AddProcessorsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EnableProcessorsFormC from 'components/Customer/Forms/EnableProcessorsForm/EnableProcessorsFormC'

const AddProcessorsModal = ({
  applicationId,
}) => {
  return (
    <GenericModal
      title='Add Processors to Application'
      className='AddProcessorsModal'
      Component={EnableProcessorsFormC}
      submitLabel='Add'
      applicationId={applicationId}
    />
  )
}

AddProcessorsModal.propTypes = {
  applicationId: PropTypes.string,
}

export default AddProcessorsModal
