import './BalanceAdjustmentsVelocityLimitsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { velocityLimitsColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { infoTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  BALANCE_ADJUSTMENTS_VELOCITY_LIMITS,
  GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const BalanceAdjustmentsVelocityLimits = ({
  isFetching,
  actions = [],
  balanceAdjustmentsACHRules = {},
  applicationBalanceAdjustmentsRulesMaxTransactionAmount,
}) => {
  return (
    <div className='BalanceAdjustmentsVelocityLimits'>
      <PageSectionV2C
        title={BALANCE_ADJUSTMENTS_VELOCITY_LIMITS}
        actions={actions}
        subTitle={applicationBalanceAdjustmentsRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${applicationBalanceAdjustmentsRulesMaxTransactionAmount}` : undefined}
        emptyMessage={isEmpty(balanceAdjustmentsACHRules) && GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE}
      >
        {!isEmpty(balanceAdjustmentsACHRules) && (
        <TableC
          isFetching={isFetching}
          showTableFooter={false}
          theme={infoTableTheme}
          columnDescriptors={velocityLimitsColumnDescriptors}
          data={balanceAdjustmentsACHRules}
        />
        )}
      </PageSectionV2C>
    </div>
  )
}

BalanceAdjustmentsVelocityLimits.propTypes = {
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  balanceAdjustmentsACHRules: PropTypes.object,
  applicationBalanceAdjustmentsRulesMaxTransactionAmount: PropTypes.string,
}

export default BalanceAdjustmentsVelocityLimits
