import './SelectSenderForCreatePaymentFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import { SELECT_SENDER_FOR_CREATE_PAYMENT_FORM } from 'constants/formConstants'
import isEmpty from 'lodash/isEmpty'

import {
  CANCEL,
  PROCEED,
  PROCESSOR,
  MERCHANT_ACCOUNT,
  MERCHANT_TO_PROCESS_PAYMENT_WITH,
  NO_MERCHANT_ACCOUNTS_ELIGIBLE,
  MERCHANT_TO_CREATE_SUBSCRIPTION_WITH,
  MERCHANT_TO_CREATE_SUBSCRIPTION_PLAN_WITH,
} from 'constants/language/languageConstants'

const SelectSenderForCreatePaymentForm = ({
  handleSubmit = () => {},
  merchantOptions = [],
  merchantFormValue,
  exitModal = () => {},
  processorOptions = [],
  processorFormValue,
  hasMultipleProcessorOptions = false,
  isCreateSubscriptionFlow = false,
  isCreateSubscriptionPlanFlow = false,
}) => {
  return (
    <form className='SelectSenderForCreatePaymentForm' onSubmit={handleSubmit}>
      <div className='modal-content'>
        {hasMultipleProcessorOptions && (
          <Field
            name='processor'
            className='processor-options'
            component={ReactSelect}
            options={processorOptions}
            label={PROCESSOR}
          />
        )}

        <Field
          name='merchant'
          component={ReactSelect}
          classNames={`merchant-options ${!hasMultipleProcessorOptions ? 'first-dropdown' : ''}`}
          options={merchantOptions}
          label={MERCHANT_ACCOUNT}
          tooltip={
            isCreateSubscriptionFlow
              ? MERCHANT_TO_CREATE_SUBSCRIPTION_WITH
              : isCreateSubscriptionPlanFlow
                ? MERCHANT_TO_CREATE_SUBSCRIPTION_PLAN_WITH
                : MERCHANT_TO_PROCESS_PAYMENT_WITH
          }
          placeholder={isEmpty(merchantOptions) && processorFormValue ? NO_MERCHANT_ACCOUNTS_ELIGIBLE : 'Select...'}
          isDisabled={(!processorFormValue && hasMultipleProcessorOptions) || isEmpty(merchantOptions)}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} onClick={exitModal} />
        <Button type='submit' label={PROCEED} disabled={!merchantFormValue} />
      </div>
    </form>
  )
}

SelectSenderForCreatePaymentForm.propTypes = {
  handleSubmit: PropTypes.func,
  merchantOptions: PropTypes.array,
  merchantFormValue: PropTypes.object,
  exitModal: PropTypes.func,
  processorOptions: PropTypes.array,
  processorFormValue: PropTypes.string,
  hasMultipleProcessorOptions: PropTypes.bool,
}

export default reduxForm({
  form: SELECT_SENDER_FOR_CREATE_PAYMENT_FORM,
})(SelectSenderForCreatePaymentForm)
