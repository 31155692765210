import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateRole from './CreateRole'
import { getRoleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import submitDuplicateRoleForm from 'utilities/submit/submitDuplicateRoleForm'
import getMany from 'utilities/get/getMany'
import getRoleRequest from 'utilities/actions/get/getRoleRequest'
import { formatPermissionsForForm } from 'utilities/formatters/formatPermissions'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  CREATE_ROLE,
  DUPLICATE_CUSTOM_ROLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentQueries = parseUrlQueries()

  const [
    entityType,
    entityId,
    roleId,
  ] = getMany(currentQueries, [
    'entityType',
    'entityId',
    'roleId',
  ])

  const role = getRoleSelector(state, roleId)

  const [
    permissions,
    type,
  ] = getMany(role, [
    'permissions',
    'type',
  ])

  const selectedEvents = formatPermissionsForForm({ type, userPermissions: permissions })
  const initialRoleValues = {
    selectedEvents,
  }

  return {
    entityId,
    entityType,
    roleId,
    credentials,
    credentialId,
    headerTitle: DUPLICATE_CUSTOM_ROLE,
    initialRoleValues,
    buttonText: CREATE_ROLE,
    submitFunction: submitDuplicateRoleForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRole: ({ credentials, roleId }) => dispatch(getRoleRequest({ credentials, roleId })),
  }
}
class DuplicateRoleC extends Component {
  componentDidMount() {
    const {
      credentials,
      roleId,
      getRole,
    } = this.props

    if (roleId) {
      getRole({ credentials, roleId })
    }
  }

  componentDidUpdate(prevProps) {
    const prevCredentials = get(prevProps, 'credentials')
    const [
      credentials,
      roleId,
      getRole,
    ] = getMany(this.props, [
      'credentials',
      'roleId',
      'getRole',
    ])

    if ((!isEmpty(credentials) && isEmpty(prevCredentials) && roleId)) {
      getRole({ roleId, credentials })
    }
  }

  render() {
    return (
      <CreateRole
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateRoleC)
