import ComplianceSettings from './ComplianceSettings'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import getComplianceSettingsRequest from 'utilities/actions/get/getComplianceSettingsRequest'
import patchComplianceSettingsRequest from 'utilities/actions/patch/patchComplianceSettingsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT_COMPLIANCE_TEMPLATE_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  getApplicationSelector,
  getPlatformSelector,
  getItemComplianceSettingsSelector,
} from 'state-layer/selectors'

import {
  EDIT_ACTIVE_COMPLIANCE_TEMPLATE,
  FILE_VIEWER_MODAL,
} from 'constants/modalConstants'

import {
  VIEW_PDF,
  CHANGE_TEMPLATE,
  NAME,
  CREATED_ON,
  ADD_TEMPLATE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const platformId = get(props, 'platformId', '')
  const applicationId = get(props, 'applicationId', '')
  const itemId = applicationId || platformId
  const item = platformId ? getPlatformSelector(state, platformId) : getApplicationSelector(state, applicationId)

  const itemName = platformId
    ? get(item, 'name') : formatBusinessNameString({
      businessName: get(item, 'ownerIdentity.businessName'),
      doingBusinessAs: get(item, 'ownerIdentity.doingBusinessAs'),
    })

  const isFetchingItem = get(state, `${platformId ? 'platforms' : 'applications'}R.${FETCHING}`)
  const isFetching = get(state, `complianceSettingsR.${FETCHING}`) || isFetchingItem

  const complianceSettings = getItemComplianceSettingsSelector(state, itemId)
  const activeComplianceTemplate = get(complianceSettings, 'activeComplianceTemplate', {})
  const activeComplianceTemplateLinkedType = get(activeComplianceTemplate, 'linkedType')
  const activeComplianceTemplateIsPlatform = activeComplianceTemplateLinkedType === 'PLATFORM'

  const autoGenerateComplianceForms = get(complianceSettings, 'autoGenerateComplianceForms')
  const autoRenewComplianceForms = get(complianceSettings, 'autoRenewComplianceForms')
  const isFetchingAutoGenerateComplianceForms = isFetching || !activeComplianceTemplateLinkedType
  const applicationInheritedPlatformTemplate = !isFetching && !!applicationId && activeComplianceTemplateIsPlatform

  const complianceTemplateTypes = [activeComplianceTemplate]

  return {
    credentials,
    isFetching,
    complianceTemplateTypes,
    itemName,
    itemId,
    autoGenerateComplianceForms,
    autoRenewComplianceForms,
    credentialId,
    platformId,
    isFetchingAutoGenerateComplianceForms,
    applicationInheritedPlatformTemplate,
    activeComplianceTemplateIsPlatform,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
    showEditActiveComplianceTemplateModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_ACTIVE_COMPLIANCE_TEMPLATE, modalProps })),
    getComplianceSettings: ({ linkedTo, credentials }) => dispatch(getComplianceSettingsRequest({ id: linkedTo, credentials })),
    updateComplianceFormSettings: ({ linkedTo, credentials, autoGenerateComplianceForms, autoRenewComplianceForms }) => {
      return dispatch(patchComplianceSettingsRequest({
        values: {
          allow_automatic_form_generation: autoGenerateComplianceForms,
          allow_annual_form_generation: autoRenewComplianceForms,
        },
        credentials,
        queries: { linked_to: linkedTo },
      }))
    },
  }
}

class ComplianceSettingsC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableToggle: false,
    }
  }

  componentDidMount() {
    const {
      getComplianceSettings,
      itemId,
      credentials,
    } = this.props

    if (itemId) getComplianceSettings({ linkedTo: itemId, credentials })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isFetching: prevIsFetching,
      itemId: prevItemId,
    } = prevProps

    const {
      isFetching,
      initialize,
      autoGenerateComplianceForms,
      autoRenewComplianceForms,
      getComplianceSettings,
      itemId,
      credentials,
    } = this.props

    if (prevIsFetching && !isFetching) {
      initialize({ autoGenerateComplianceForms, autoRenewComplianceForms })
      this.setState({ disableToggle: false })
    }

    if (itemId && prevItemId !== itemId) {
      getComplianceSettings({ linkedTo: itemId, credentials })
    }
  }

  toggleAutoGenerateComplianceForms = () => {
    const {
      updateComplianceFormSettings,
      itemId,
      credentials,
      autoGenerateComplianceForms,
      autoRenewComplianceForms,
    } = this.props

    this.setState({ disableToggle: true })

    updateComplianceFormSettings({
      credentials,
      linkedTo: itemId,
      autoGenerateComplianceForms: !autoGenerateComplianceForms,
      autoRenewComplianceForms,
    })
  }

  toggleAutoRenewComplianceForms = () => {
    const {
      updateComplianceFormSettings,
      itemId,
      credentials,
      autoRenewComplianceForms,
      autoGenerateComplianceForms,
    } = this.props

    this.setState({ disableToggle: true })

    updateComplianceFormSettings({
      credentials,
      linkedTo: itemId,
      autoRenewComplianceForms: !autoRenewComplianceForms,
      autoGenerateComplianceForms,
    })
  }

  render() {
    const {
      complianceTemplateTypes,
      showFileViewer,
      showEditActiveComplianceTemplateModal,
      itemName,
      itemId,
      isFetchingAutoGenerateComplianceForms,
      activeComplianceTemplateIsPlatform,
      applicationInheritedPlatformTemplate,
    } = this.props

    const { disableToggle } = this.state

    const editActiveComplianceTemplate = ({ complianceTemplateType }) => {
      showEditActiveComplianceTemplateModal({
        complianceTemplateType,
        itemId,
        itemName,
      })
    }

    const complianceTemplateCards = map(complianceTemplateTypes, (template) => {
      const {
        type = '',
        name,
        id,
        displayCreatedAt,
        fileId,
      } = template

      const complianceTemplateType = type.replaceAll('_', ' ')

      return {
        idKey: 'ID',
        idValue: id,
        largeTitle: <div>{complianceTemplateType} Template</div>,
        asideTitle: <a className='text-link' onClick={() => showFileViewer({ id: fileId, extension: 'pdf' })}>{VIEW_PDF}</a>,
        headerActions: [
          {
            label: CHANGE_TEMPLATE,
            onClick: () => editActiveComplianceTemplate({
              complianceTemplateType,
              itemId,
              itemName,
            }),
          },
        ],
        data: [
          {
            label: NAME,
            value: name,
            condition: !!id,
          },
          {
            label: CREATED_ON,
            value: displayCreatedAt,
            condition: !!id,
          },
        ],
      }
    })

    const complianceSettingsActions = applicationInheritedPlatformTemplate ? [{
      label: ADD_TEMPLATE,
      action: () => editActiveComplianceTemplate({ complianceTemplateType: 'PCI SAQ A' }),
    }] : []

    return (
      <ComplianceSettings
        complianceTemplateCards={complianceTemplateCards}
        toggleAutoGenerateComplianceForms={this.toggleAutoGenerateComplianceForms}
        toggleAutoRenewComplianceForms={this.toggleAutoRenewComplianceForms}
        disableToggle={disableToggle || isFetchingAutoGenerateComplianceForms || activeComplianceTemplateIsPlatform}
        complianceSettingsActions={complianceSettingsActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: EDIT_COMPLIANCE_TEMPLATE_FORM,
})(ComplianceSettingsC))
