import './EditDashboardAccountNameModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditDashboardAccountNameFormC from 'components/Customer/Forms/EditDashboardAccountNameForm/EditDashboardAccountNameFormC'
import { EDIT_DASHBOARD_ACCOUNT_NAME } from 'constants/language/languageConstants'

const EditDashboardAccountNameModal = ({
  credentialId,
  dashboardAccountName = '',
}) => {
  return (
    <GenericModal
      title={EDIT_DASHBOARD_ACCOUNT_NAME}
      className='EditDashboardAccountNameModal'
      Component={EditDashboardAccountNameFormC}
      credentialId={credentialId}
      dashboardAccountName={dashboardAccountName}
    />
  )
}

EditDashboardAccountNameModal.propTypes = {
  credentialId: PropTypes.string,
  dashboardAccountName: PropTypes.string,
}

export default EditDashboardAccountNameModal
