import './PermissionFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import ToggleSection from './ToggleSection'
import { PERMISSION_FORM } from 'constants/formConstants'
import map from 'lodash/map'

const PermissionForm = ({
  formattedPermissions = {},
  handleSubmit = () => {},
  onSubmit = () => {},
  submitting = false,
  submitLabel = 'Save',
  extraProps: { isReadOnly = false },
  fieldValueSelector = () => {},
}) => {
  return (
    <form className='PermissionForm' onSubmit={handleSubmit(onSubmit)}>
      { isReadOnly && <div className='read-only'>Permissions for this user are READ ONLY</div> }

      { map(formattedPermissions, (permissions, name) => {
        return (
          <ToggleSection
            key={name}
            name={name}
            permissions={permissions}
            isReadOnly={isReadOnly}
            fieldValueSelector={fieldValueSelector}
            form={PERMISSION_FORM}
          />
        )
      })}

      <div className='buttons flex flex-end'>
        { !isReadOnly && <Button type='submit' className='submit-permissions-button' submitting={submitting} label={submitLabel} /> }
      </div>
    </form>
  )
}

PermissionForm.propTypes = {
  formattedPermissions: PropTypes.object,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitLabel: PropTypes.string,
  isReadOnly: PropTypes.bool,
  fieldValueSelector: PropTypes.func,
}

export default PermissionForm
