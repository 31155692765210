import selectOption from 'utilities/forms/selectOption'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import filter from 'lodash/filter'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

import {
  ALL,
  SUCCEEDED,
  FAILED,
} from 'constants/transferConstants'

import {
  BASIC,
  BEARER_TOKEN,
  NONE,
  WEBHOOK_ALL_EVENTS_CHOICE_DESCRIPTION,
  WEBHOOK_SELECTED_EVENTS_CHOICE_DESCRIPTION,
  APPLICATION_RESOURCE_TITLE,
  APPLICATION_PROFILE,
  COMPLIANCE_FORM,
  FILE,
  EXTERNAL_LINK,
  INSTRUMENT_UPDATE,
  ACH_ENTRY,
  AUTHORIZATION,
  BALANCE_TRANSFER,
  DISPUTE_RESOURCE_TITLE,
  EVIDENCE,
  FEE_PROFILE,
  IDENTITY_RESOURCE_TITLE,
  INSTRUMENT,
  MERCHANT,
  MERCHANT_PROFILE,
  RISK_PROFILE,
  SETTLEMENT_RESOURCE_TITLE,
  TRANSFER,
  FEE,
  PAYOUT_PROFILE,
  VERIFICATION,
  SETTLEMENT_V2_RESOURCE_TITLE,
  CREATED,
  UPDATED,
  UNDERWRITTEN,
  ALL as ALL_STRING,
  FAILED as FAILED_STRING,
  SUCCEEDED as SUCCEEDED_STRING,
  TRUE,
  FALSE,
  ONBOARDING_FORM,
  ACCRUING_STARTED,
} from 'constants/language/languageConstants'

const CREATED_VALUE = lowerCase(CREATED)
const UPDATED_VALUE = lowerCase(UPDATED)
const UNDERWRITTEN_VALUE = lowerCase(UNDERWRITTEN)
const ACCRUING_STARTED_VALUE = 'accruing_started'
export const BASIC_VALUE = 'BASIC'
export const BEARER_TOKEN_VALUE = 'BEARER'
export const NONE_VALUE = 'NONE'

export const AUTHENTICATION_TYPE_OPTIONS = [
  selectOption(BASIC, BASIC_VALUE),
  selectOption(BEARER_TOKEN, BEARER_TOKEN_VALUE),
  selectOption(NONE, NONE_VALUE),
]

export const WEBHOOK_ENABLE_EVENTS_OPTIONS = [
  {
    value: 'all-events',
    label: WEBHOOK_ALL_EVENTS_CHOICE_DESCRIPTION,
  },
  {
    value: 'selected-events',
    label: WEBHOOK_SELECTED_EVENTS_CHOICE_DESCRIPTION,
  }
]

export const AUTHENTICATION_VALUE_TO_LABEL_MAP = {
  [BASIC_VALUE]: `${BASIC} ${AUTHORIZATION}`,
  [BEARER_TOKEN_VALUE]: BEARER_TOKEN,
  [NONE_VALUE]: NONE,
}

export const constructWebhookEventValues = ({ selectedEvents }) => {
  return map(selectedEvents, (entityTypes, entity) => {
    const types = filter(Object.keys(entityTypes), (type) => get(entityTypes, type))

    return ({ entity, types })
  })
}

export const constructWebhookEventFormValues = ({ enabledEvents }) => {
  return reduce(enabledEvents, (total, {entity, types}) => {
    const typesObj = {}
    forEach(types, (typeName) => typesObj[typeName] = true)

    return merge({}, total, {[entity]: typesObj})
  }, {})
}

export const WEBHOOK_EVENT_CHECKBOX_OPTIONS = [
  {
    label: APPLICATION_RESOURCE_TITLE,
    value: 'application',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: APPLICATION_PROFILE,
    value: 'application_profile',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: AUTHORIZATION,
    value: 'authorization',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: BALANCE_TRANSFER,
    value: 'balance_transfer',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: COMPLIANCE_FORM,
    value: 'compliance_form',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: DISPUTE_RESOURCE_TITLE,
    value: 'dispute',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: EVIDENCE,
    value: 'evidence',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: EXTERNAL_LINK,
    value: 'external_link',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: FEE,
    value: 'fee',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: FEE_PROFILE,
    value: 'fee_profile',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: FILE,
    value: 'file',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: IDENTITY_RESOURCE_TITLE,
    value: 'identity',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: INSTRUMENT,
    value: 'instrument',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: INSTRUMENT_UPDATE,
    value: 'instrument_update',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },{
    label: MERCHANT,
    value: 'merchant',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      },
      {
        label: UNDERWRITTEN,
        value: UNDERWRITTEN_VALUE,
      }
    ],
  },
  {
    label: MERCHANT_PROFILE,
    value: 'merchant_profile',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: ONBOARDING_FORM,
    value: 'onboarding_form',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: PAYOUT_PROFILE,
    value: 'payout_profile',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
    ],
  },
  {
    label: RISK_PROFILE,
    value: 'risk_profile',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: SETTLEMENT_RESOURCE_TITLE,
    value: 'settlement',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      },
      {
        label: ACCRUING_STARTED,
        value: ACCRUING_STARTED_VALUE,
      },
    ],
  },
  // commented out 3/18/24 per request from product bc the API docs removed it causing customer confusion
  // {
  //   label: SETTLEMENT_V2_RESOURCE_TITLE,
  //   value: 'settlement_v2',
  //   children: [
  //     {
  //       label: CREATED,
  //       value: CREATED_VALUE,
  //     },
  //     {
  //       label: UPDATED,
  //       value: UPDATED_VALUE,
  //     }
  //   ],
  // },
  {
    label: TRANSFER,
    value: 'transfer',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
  {
    label: VERIFICATION,
    value: 'verification',
    children: [
      {
        label: CREATED,
        value: CREATED_VALUE,
      },
      {
        label: UPDATED,
        value: UPDATED_VALUE,
      }
    ],
  },
]

export const WEBHOOK_LOG_STATE_OPTIONS = [
  selectOption(ALL_STRING, ALL),
  selectOption(SUCCEEDED_STRING, SUCCEEDED),
  selectOption(FAILED_STRING, FAILED)
]

export const WEBHOOK_EVENT_TYPE_OPTIONS = [
  selectOption(ALL_STRING, ALL),
  selectOption(CREATED, 'CREATED'),
  selectOption(UPDATED, 'UPDATED'),
  selectOption(UNDERWRITTEN, 'UNDERWRITTEN'),
  selectOption(ACCRUING_STARTED, 'ACCRUING_STARTED'),
]

export const EVENT_ENTITY_OPTIONS = [
  selectOption(ALL_STRING, ALL),
  selectOption(APPLICATION_RESOURCE_TITLE, 'APPLICATION'),
  selectOption(APPLICATION_PROFILE, 'APPLICATION_PROFILE'),
  selectOption(AUTHORIZATION, 'AUTHORIZATION'),
  selectOption(BALANCE_TRANSFER, 'BALANCE_TRANSFER'),
  selectOption(COMPLIANCE_FORM, 'COMPLIANCE_FORM'),
  selectOption(DISPUTE_RESOURCE_TITLE, 'DISPUTE'),
  selectOption(EVIDENCE, 'EVIDENCE'),
  selectOption(EXTERNAL_LINK, 'EXTERNAL_LINK'),
  selectOption(FEE, 'FEE'),
  selectOption(FEE_PROFILE, 'FEE_PROFILE'),
  selectOption(FILE, 'FILE'),
  selectOption(IDENTITY_RESOURCE_TITLE, 'IDENTITY'),
  selectOption(INSTRUMENT, 'INSTRUMENT'),
  selectOption(INSTRUMENT_UPDATE, 'INSTRUMENT_UPDATE'),
  selectOption(MERCHANT, 'MERCHANT'),
  selectOption(MERCHANT_PROFILE, 'MERCHANT_PROFILE'),
  selectOption(PAYOUT_PROFILE, 'PAYOUT_PROFILE'),
  selectOption(RISK_PROFILE, 'RISK_PROFILE'),
  selectOption(SETTLEMENT_RESOURCE_TITLE, 'SETTLEMENT'),
  // commented out 3/18/24 per request from product bc the API docs removed it causing customer confusion
  // selectOption(SETTLEMENT_V2_RESOURCE_TITLE, 'SETTLEMENT_V2'),
  selectOption(TRANSFER, 'TRANSFER'),
  selectOption(VERIFICATION, 'VERIFICATION'),
]

export const WEBHOOK_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Enabled', true),
  selectOption('Disabled', false),
]

export const WEBHOOK_ACCEPTING_EVENTS_OPTIONS = [
  selectOption('All', ALL),
  selectOption(TRUE, true),
  selectOption(FALSE, false),
]
