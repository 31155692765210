import {
  ADJUSTMENT_DECREASE,
  ADJUSTMENT_INCREASE,
} from 'constants/transferConstants'

import {
  ADD_TO_ACCOUNT_BALANCE,
  REMOVE_FROM_ACCOUNT_BALANCE,
} from 'constants/language/languageConstants'

export const ADJUSTMENT_TYPE_OPTIONS = [
  {
    value: ADJUSTMENT_INCREASE,
    label: ADD_TO_ACCOUNT_BALANCE
  },
  {
    value: ADJUSTMENT_DECREASE,
    label: REMOVE_FROM_ACCOUNT_BALANCE,
  }
]

export const ADJUSTMENT_TYPE_LABEL = 'Adjustment Type'
