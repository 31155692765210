import { getState } from 'state-layer/configureStore'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendFileModel from 'utilities/create/models/frontendFileModel'
import formatDate from 'utilities/formatters/formatDate'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import { DATE_SHORT_FORMAT } from 'constants/timeConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import split from 'lodash/split'
import lowerCase from 'lodash/lowerCase'

const frontendComplianceFormModel = ({ data: complianceForm }) => {
  const [
    id,
    type,
    version,
    state,
    createdAt,
    updatedAt,
    dueAt,
    linkedTo,
    linkedType,
    merchantIdentity,
    ownerIdentity,
    application,
    files,
    validFrom,
    validUntil,
    complianceFormTemplateId,
    tags,
  ] = getMany(complianceForm, [
    'id',
    'type',
    'version',
    'state',
    'created_at',
    'updated_at',
    'due_at',
    'linked_to',
    'linked_type',
    'merchant_identity_details',
    'owner_identity_details',
    'application_details',
    'files',
    'valid_from',
    'valid_until',
    'compliance_form_template',
    'tags',
  ])

  const formattedType = map(split(type, '_'), (word) => lowerCase(word)).join('_')
  const attestation = get(complianceForm, formattedType)

  const [
    attestorName,
    signedAt,
    userAgent,
    ipAddress,
    isAccepted,
    title,
  ] = getMany(attestation, [
    'name',
    'signed_at',
    'user_agent',
    'ip_address',
    'is_accepted',
    'title',
  ])

  const credentials = getCurrentCredentials(getState())

  return removeUndefined({
    id,
    type,
    state,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    dueAt,
    displayDueAt: formatDate({ date: dueAt, format: DATE_SHORT_FORMAT }),
    linkedTo,
    linkedType,
    merchantIdentity: frontendIdentityModel({ data: merchantIdentity }),
    ownerIdentity: frontendIdentityModel({ data: ownerIdentity }),
    validFrom,
    validUntil,
    displayValidUntil: formatDate({ date: validUntil, format: DATE_SHORT_FORMAT }),
    complianceFormTemplateId,
    tags,
    attestorName,
    signedAt,
    displaySignedAt: formatDate({ date: signedAt }),
    ipAddress,
    userAgent,
    merchantId: linkedTo,
    merchantIdentityId: get(merchantIdentity, 'id'),
    applicationId: hasPlatformAccess({ credentials }) ? get(application, 'id') : '',
    signedFile: frontendFileModel({ data: get(files, 'signed_file') }),
    unsignedFile: frontendFileModel({ data: get(files, 'unsigned_file') }),
  })
}

export default frontendComplianceFormModel
