import React, { Component } from 'react'
import { connect } from 'react-redux'
import SwitchDashboardTypeForm from 'components/Customer/Forms/SwitchDashboardTypeForm/SwitchDashboardTypeForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { SWITCH_CUSTOMER_PROFILE_TYPE_SUCCESS_CUSTOM_MESSAGE } from 'constants/language/languageConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const dashboardConfigurationId = get(props, 'dashboardConfigurationId')
  const displayBusinessName = get(props, 'displayBusinessName')

  const [
    dashboardType,
    entityId,
    entityType,
  ] = getMany(props, [
    'applicationDashboardType',
    'entityId',
    'entityType',
  ])

  const initialValues = {
    dashboardType,
  }

  return removeUndefined({
    credentials,
    initialValues,
    id: dashboardConfigurationId,
    dashboardType,
    entityId,
    entityType,
    displayBusinessName,
    customSuccessMsg: SWITCH_CUSTOMER_PROFILE_TYPE_SUCCESS_CUSTOM_MESSAGE,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class SwitchDashboardTypeFormC extends Component {
  render() {
    const { closeModal } = this.props

    const customSuccessFunc = () => closeModal()

    return (
      <SwitchDashboardTypeForm
        {...this.props}
        customSuccessFunc={customSuccessFunc}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchDashboardTypeFormC)
