import paymentsAPI from 'utilities/api/paymentsAPI'
import { WEBHOOKS } from 'constants/apiConstants'

const getWebhooksAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    queries,
    path: WEBHOOKS,
    service: WEBHOOKS,
  })
}

export default getWebhooksAPI
