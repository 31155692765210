import './PayoutsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { PAYOUTS } from 'constants/linkConstants'
import { PAYOUTS_ICON } from 'constants/iconConstants'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

import {
  PAYOUTS as PAYOUTS_LABEL,
  PAYOUTS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const Payouts = ({
  payoutPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters,
  hiddenExportFields = [],
}) => {
  return (
    <div className='Payouts'>
      <HeaderV2C
        title={PAYOUTS_LABEL}
      />

      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={PAYOUTS}
        entityType={TRANSFER}
        allowedFilters={allowedFilters}
        path={payoutPath}
        isFetching={isFetching}
        fileNameCSV={PAYOUTS_LABEL}
        showAdvancedExport
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${PAYOUTS_ICON}`}
        quickFilters={quickFilters}
        emptyMessage={PAYOUTS_EMPTY_TABLE_MESSAGE}
      />
    </div>
  )
}

Payouts.propTypes = {
  payoutPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.array,
  hiddenExportFields: PropTypes.array,
}

export default Payouts
