import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'

import {
  WELCOME_EMAIL_PASSWORD,
  DASHBOARD_SERVICE_WELCOME_EMAIL,
  DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2,
} from 'constants/apiConstants'

const getWelcomeEmailTemplateAPI = ({ meta } = {}) => {
  const dashboardId = getCurrentDashboardId()
  const dashboardServiceV2 = isDashboardServiceV2()
  const path = dashboardServiceV2 ? DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2({ dashboardId }) : DASHBOARD_SERVICE_WELCOME_EMAIL
  const queries = dashboardServiceV2 ? {
    email_template_name: `${getCurrentPlatformName()}-${WELCOME_EMAIL_PASSWORD}`,
  } : {}

  return dashboardAPI.get({
    meta,
    path,
    queries,
  })
}

export default getWelcomeEmailTemplateAPI
