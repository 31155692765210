import { GET_FEE_PROFILE_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getFeeProfileSettingsRequest = ({
  values,
  credentials,
}) => createAction({
  type: GET_FEE_PROFILE_SETTINGS_F_REQUEST,
  values,
  credentials,
})

export default getFeeProfileSettingsRequest
