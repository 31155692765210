import { goToPath } from 'state-layer/history'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import { GUEST_PAYOUT_LINK_CONFIRMATION } from 'constants/pathConstants'
import { PAYOUT_LINK_TRANSFER_ATTEMPT } from 'constants/amplitudeConstants'
import sample from 'lodash/sample'
import get from 'lodash/get'

import {
  SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
  YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
} from 'constants/language/languageConstants'

import {
  POST_TRANSFER_ATTEMPT_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
  SHOW_SUCCESS_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

// TODO: Cata - add unit tests
const submitGuestPayoutLinkForm = (values, dispatch, props = {}) => {
  const { payoutLink } = props

  const [
    id,
    recipientFirstName,
    recipientLastName,
    recipientBusinessName,
    recipientMerchantId,
    totalAmount,
    currency,
    applicationId,
    unsuccessfulReturnUrl,
    successReturnUrl,
  ] = getMany(payoutLink, [
    'id',
    'recipientFirstName',
    'recipientLastName',
    'recipientBusinessName',
    'recipientMerchantId',
    'totalAmount',
    'currency',
    'applicationId',
    'unsuccessfulReturnUrl',
    'successReturnUrl',
  ])

  const {
    firstName,
    lastName,
    personalAddress,
    email,
    phoneNumber,
    businessName,
    doingBusinessName,
    businessAddress,
    token,
    paymentInstrumentId,
    tags,
  } = values

  const recipientType = recipientBusinessName ? 'BUSINESS' : 'PERSONAL'
  const paymentInstrumentIdValue = get(paymentInstrumentId, 'value')

  const valuesToSubmit = {
    totalAmount,
    currency,
    id,
    token,
    paymentInstrumentId: token ? null : paymentInstrumentIdValue,
    recipientMerchantId,
    recipientDetails: {
      recipientType,
      recipientFirstName: recipientFirstName ? recipientFirstName : firstName,
      recipientLastName: recipientLastName ? recipientLastName : lastName,
      recipientBusinessName: recipientBusinessName ? recipientBusinessName : businessName,
      recipientEmail: email,
      recipientPhoneNumber: phoneNumber,
      recipientPersonalAddress: personalAddress,
      recipientBusinessAddress: businessAddress,
      recipientDoingBusinessAs: doingBusinessName,
    },
    tags,
  }

  dispatch({
    type: POST_TRANSFER_ATTEMPT_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues: transferAttempts }) => {
        // eslint-disable-next-line camelcase
        const { state, operation_key } = sample(transferAttempts) || {}

        if (state === 'FAILED') {
          dispatch({
            type: SHOW_ERROR_FLASH_NOTIFICATION,
            payload: {
              message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(PAYOUT_LINK_TRANSFER_ATTEMPT, {
                payoutLinkId: id,
                recipientMerchantId,
                applicationId,
                totalAmount,
                currency,
                state: 'FAILED',
              }),
            },
          })

          if (unsuccessfulReturnUrl) {
            setTimeout(() => {
              window.location.href = unsuccessfulReturnUrl
            }, 1000)
          } else {
            goToPath({
              pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
              queries: {
                status: 'failure',
              },
            })
          }
        } else if (state === 'PENDING') {
          dispatch({
            type: SHOW_SUCCESS_FLASH_NOTIFICATION,
            payload: {
              message: YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(PAYOUT_LINK_TRANSFER_ATTEMPT, {
                payoutLinkId: id,
                recipientMerchantId,
                applicationId,
                totalAmount,
                currency,
                state: 'PENDING',
              }),
            },
          })

          if (successReturnUrl) {
            setTimeout(() => {
              window.location.href = successReturnUrl
            }, 1000)
          } else {
            goToPath({
              pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
              queries: {
                status: 'awaiting_payout',
                operationKey: operation_key,
              },
            })
          }
        } else {
          dispatch({
            type: SHOW_SUCCESS_FLASH_NOTIFICATION,
            payload: {
              message: YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(PAYOUT_LINK_TRANSFER_ATTEMPT, {
                payoutLinkId: id,
                recipientMerchantId,
                applicationId,
                totalAmount,
                currency,
                state: 'SUCCESS',
              }),
            },
          })

          if (successReturnUrl) {
            setTimeout(() => {
              window.location.href = successReturnUrl
            }, 1000)
          } else {
            setTimeout(() => {
              goToPath({
                pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
                queries: {
                  status: 'success',
                  operationKey: operation_key,
                },
              })
            }, 1000)
          }
        }
      },
      errorCallback: () => {
        dispatch({
          type: SHOW_ERROR_FLASH_NOTIFICATION,
          payload: {
            message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
          },
          meta: {
            actionId: sendAmplitudeActionEvent(PAYOUT_LINK_TRANSFER_ATTEMPT, {
              payoutLinkId: id,
              recipientMerchantId,
              applicationId,
              totalAmount,
              currency,
              state: 'ERROR',
            }),
          },
        })

        if (unsuccessfulReturnUrl) {
          setTimeout(() => {
            window.location.href = unsuccessfulReturnUrl
          }, 1000)
        } else {
          goToPath({
            pathname: GUEST_PAYOUT_LINK_CONFIRMATION,
            queries: {
              status: 'failure',
            },
          })
        }
      },
    },
  })
}

export default submitGuestPayoutLinkForm
