import removeItems from 'utilities/remove/removeItems'
import mergeOverwriteTags from 'utilities/merge/mergeOverwriteTags'
import mergeOverwriteKeys from 'utilities/merge/mergeOverwriteKeys'
import { FETCHING } from 'constants/reducerConstants'
import updateState from 'utilities/reducers/updateState'
import { LOCATION_CHANGE } from 'react-router-redux'
import get from 'lodash/get'
import head from 'lodash/head'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import drop from 'lodash/drop'
import keys from 'lodash/keys'
import first from 'lodash/first'

import {
  GET_APPLICATION,
  PATCH_APPLICATION,
  GET_APPLICATIONS,
  GET_APPLICATIONS_IDENTITY,
  GET_AUTHORIZATION,
  PATCH_AUTHORIZATION,
  GET_AUTHORIZATIONS,
  GET_APP_CONFIGURATION,
  GET_CONFIGURATION,
  GET_CONFIGURATIONS,
  GET_CREDENTIALS,
  GET_CREDENTIAL,
  GET_DISPUTE,
  GET_DISPUTES,
  GET_DISPUTE_EVIDENCES,
  POST_DISPUTE_EVIDENCES,
  GET_DISPUTE_ADJUSTMENT_TRANSFERS,
  GET_DISPUTE_TRANSFER,
  GET_DISPUTE_APPLICATION,
  GET_IDENTITIES,
  GET_IDENTITY,
  POST_IDENTITY,
  PATCH_IDENTITY,
  GET_MERCHANT,
  PATCH_MERCHANT,
  GET_MERCHANTS,
  GET_PAYMENT_INSTRUMENT,
  GET_PAYMENT_INSTRUMENTS,
  GET_PAYMENT_INSTRUMENT_TRANSFERS,
  GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
  GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_REPORTS,
  GET_CATEGORIZED_REPORTS,
  GET_REVIEW_QUEUE,
  GET_SETTLEMENT,
  GET_SETTLEMENTS,
  GET_SETTLEMENT_TRANSFERS,
  GET_SETTLEMENT_FUNDING_TRANSFERS,
  GET_SETTLEMENT_GROUP,
  GET_TRANSFER,
  PATCH_TRANSFER,
  GET_AUTHORIZATIONS_MERCHANT_IDENTITY,
  GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT,
  GET_TRANSFERS_MERCHANT_IDENTITY,
  GET_TRANSFERS_PAYMENT_INSTRUMENT,
  GET_TRANSFER_APPLICATION,
  PATCH_PAYMENT_INSTRUMENT,
  GET_TRANSFERS,
  GET_USERS,
  GET_DASHBOARD_USERS,
  GET_ADMIN_USER,
  GET_ADMIN_USER_MEMBERSHIPS,
  GET_ADMIN_USER_CREDENTIALS,
  GET_ASSOCIATED_IDENTITIES,
  GET_IDENTITY_AUTHORIZATIONS,
  GET_IDENTITY_TRANSFERS,
  GET_IDENTITY_DISPUTES,
  GET_IDENTITY_SETTLEMENTS,
  GET_IDENTITY_PAYMENT_INSTRUMENTS,
  POST_IDENTITY_PAYMENT_INSTRUMENTS,
  GET_IDENTITY_MERCHANTS,
  POST_IDENTITY_MERCHANTS,
  GET_IDENTITY_VERIFICATIONS,
  GET_IDENTITY_VERIFICATION,
  POST_IDENTITY_VERIFICATIONS,
  GET_MERCHANT_VERIFICATIONS,
  POST_MERCHANT_VERIFICATIONS,
  GET_APPLICATION_ASSOCIATED_IDENTITIES,
  GET_APPLICATION_DISPUTES,
  GET_APPLICATION_IDENTITIES,
  GET_APPLICATION_MERCHANTS,
  GET_APPLICATION_PROCESSORS,
  POST_APPLICATION_PROCESSORS,
  GET_APPLICATION_PROCESSOR,
  GET_APPLICATION_SETTLEMENTS,
  GET_APPLICATION_TRANSFERS,
  GET_APPLICATION_USERS,
  GET_WEBHOOKS,
  GET_WEBHOOK_LOGS,
  GET_WEBHOOK_LOG,
  GET_WEBHOOKS_WITH_STOPPED_EVENTS,
  POST_WEBHOOKS,
  PATCH_WEBHOOK,
  PATCH_REVIEW_QUEUE_ITEMS,
  PATCH_REVIEW_QUEUE_ITEM,
  POST_APPLICATION_USERS,
  DELETE_SETTLEMENT_ENTRIES,
  GET_SETTINGS,
  GET_REVIEW_QUEUE_ITEM,
  GET_SEARCH,
  GET_REVIEW_QUEUE_SETTLEMENTS,
  GET_MERCHANT_IDENTITY,
  GET_REVIEW_QUEUE_APPLICATION,
  GET_REVIEW_QUEUE_MERCHANTS,
  GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT,
  GET_REVIEW_QUEUE_MERCHANTS_IDENTITY,
  GET_SETTLEMENTS_MERCHANT,
  GET_IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT,
  GET_IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT,
  GET_SETTLEMENTS_IDENTITY,
  GET_APPLICATION_SETTLEMENTS_IDENTITY,
  GET_FUNDING_TRANSFERS_RELATED_TRANSFER,
  GET_SETTLEMENT_TRANSFER_FEES,
  GET_SETTLEMENT_TRANSFER_FEE_COUNTS,
  POST_MASS_SETTLEMENT_APPROVAL,
  GET_ALL_REVIEW_QUEUE_ITEMS,
  GET_DISPUTES_WIDGET,
  GET_TRANSFERS_WIDGET,
  GET_AUTHORIZATIONS_WIDGET,
  GET_REVIEW_QUEUE_WIDGET,
  GET_IDENTITIES_WIDGET,
  GET_MERCHANTS_WIDGET,
  GET_PAYMENT_INSTRUMENTS_WIDGET,
  POST_ADMIN_USER,
  GET_WELCOME_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  PATCH_WELCOME_EMAIL_TEMPLATE,
  PATCH_EMAIL_TEMPLATE,
  GET_PLATFORM,
  GET_PLATFORMS,
  GET_PLATFORM_PROCESSOR_CONFIG,
  GET_PLATFORM_PROCESSOR_CONFIGS,
  GET_PLATFORM_USERS,
  GET_PLATFORM_USER,
  GET_USER_PERMISSIONS,
  PATCH_USER_PERMISSIONS,
  GET_PAYMENT_INSTRUMENT_APPLICATION,
  GET_MERCHANT_TRANSFERS,
  GET_MERCHANT_AUTHORIZATIONS,
  GET_MERCHANT_SETTLEMENTS,
  GET_SETTLEMENT_ENTRIES,
  GET_FEES,
  GET_FEE,
  GET_FEES_MERCHANT,
  POST_IDENTITY_UPLOAD,
  CLEAR_IDENTITY_UPLOADER_REDUCER,
  POST_IDENTITY_UPLOAD_BATCH,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_CREDENTIAL_PERMISSIONS,
  GET_PAYOUT_PLAN,
  GET_PAYOUT_PLANS,
  POST_PAYOUT_PLAN,
  PATCH_SETTLEMENT,
  CLOSE_SETTLEMENTS,
  GET_SETTLEMENT_ENTRIES_FEE,
  GET_SETTLEMENT_ENTRIES_TRANSFER,
  PATCH_USER,
  GET_NOTIFICATIONS,
  GET_SETTLEMENT_GROUP_SETTLEMENTS,
  GET_TRANSFER_FEES_SETTLEMENT_ENTRIES,
  GET_BALANCE_TRANSFERS,
  GET_BALANCE_TRANSFER,
  GET_SUBSCRIPTION_SCHEDULES,
  GET_SUBSCRIPTION_SCHEDULE,
  GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
  GET_SUBSCRIPTION_ENROLLMENT,
  GET_SUBSCRIPTION_ENROLLMENTS,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT,
  POST_SUBSCRIPTION_SCHEDULE,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
  DELETE_SUBSCRIPTION_ENROLLMENTS,
  GET_MERCHANT_FEES,
  PATCH_FEE,
  PATCH_MERCHANT_IDENTITY,
  GET_CURRENT_USER,
  GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
  GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS,
  GET_SUBSCRIPTION_SCHEDULE_FEES,
  GET_SUBSCRIPTION_SCHEDULES_AMOUNTS,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT,
  GET_DASHBOARD_CUSTOMIZATIONS,
  GET_GUEST_DASHBOARD_CUSTOMIZATIONS,
  POST_DASHBOARD_CUSTOMIZATIONS,
  PATCH_DASHBOARD_CUSTOMIZATIONS,
  GET_ALL_REVIEW_QUEUE_ITEMS_BATCH,
  GET_FILE,
  GET_FILES,
  GET_WEBHOOK,
  GET_PAYMENT_METHOD_CONFIGURATION,
  POST_PAYMENT_METHOD_CONFIGURATIONS,
  PATCH_PAYMENT_METHOD_CONFIGURATIONS,
  GET_CERTIFICATE_SIGNING_REQUESTS,
  POST_CERTIFICATE_SIGNING_REQUESTS,
  GET_MERCHANT_FILES,
  POST_MERCHANT_FILE,
  GET_COMPLIANCE_FORMS,
  GET_COMPLIANCE_FORM,
  GET_COMPLIANCE_FORM_TEMPLATES,
  GET_COMPLIANCE_FORM_TEMPLATE,
  POST_COMPLIANCE_FORM_TEMPLATE,
  PATCH_COMPLIANCE_FORM_TEMPLATE,
  GET_MERCHANT_COMPLIANCE_FORMS,
  PATCH_COMPLIANCE_FORM,
  GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
  GET_HOSTED_FORM_TOKEN_VERIFICATION,
  GET_COMPLIANCE_SETTINGS,
  PATCH_COMPLIANCE_SETTINGS,
  GET_EXPORTS,
  GET_COMPLIANCE_FORM_APPLICATION,
  GET_PAYMENT_INSTRUMENT_IDENTITY,
  GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY,
  GET_MERCHANT_PAYOUT_SETTINGS,
  GET_PAYOUT_SETTINGS,
  GET_NOTIFICATION_SETTINGS,
  POST_NOTIFICATION_ACKNOWLEDGMENT,
  GET_LATEST_NOTIFICATIONS,
  GET_API_LOGS,
  GET_API_LOG,
  GET_SETTLEMENTS_APPLICATIONS,
  GET_COMPLIANCE_FORMS_APPLICATION,
  GET_RELATED_APPLICATION,
  GET_RELATED_IDENTITY,
  GET_RELATED_PAYMENT_INSTRUMENT,
  GET_MERCHANT_APPLICATION,
  GET_MERCHANT_VERIFICATION,
  GET_ONBOARDING_FORMS,
  GET_ONBOARDING_FORMS_APPLICATION,
  GET_AUTHORIZATIONS_APPLICATION,
  POST_SELF_SERVICE_EMAIL_VERIFICATION,
  PATCH_CREDENTIAL,
  GET_MEMBER,
  GET_MEMBERS,
  GET_ROLE_MEMBERS,
  GET_ROLES,
  GET_ROLE,
  PATCH_ROLE,
  GET_RECENTLY_ACTIVE_CREDENTIALS,
  PATCH_MEMBERSHIP,
  GET_MEMBER_ROLE,
  PATCH_DASHBOARD_USER,
  GET_ACCESS_FORM,
  GET_ACCESS_FORMS,
  POST_ACCESS_FORM,
  PATCH_ACCESS_FORM,
  PATCH_GUEST_ACCESS_FORM,
  PATCH_UNDERWRITING_REVIEW,
  GET_UNDERWRITING_REVIEW,
  GET_UNDERWRITING_REVIEWS,
  GET_ACCESS_FORM_CONTRACT,
  GET_DASHBOARD_CONTRACTS,
  PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
  GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
  POST_SUBMIT_ACCESS_FORM,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES,
  GET_MERCHANT_UNDERWRITING_REVIEWS,
  GET_RECIPIENT_UNDERWRITING_REVIEWS,
  GET_FEE_PROFILE,
  POST_MERCHANT_FEE_PROFILE,
  POST_FEE_PROFILE,
  POST_PLATFORM_USER,
  GET_UNDERWRITING_PROFILE,
  POST_UNDERWRITING_PROFILE,
  PATCH_UNDERWRITING_PROFILE,
  GET_UNDERWRITING_PROFILES,
  GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE,
  PATCH_DISPUTE,
  GET_IDENTITY_FILES,
  POST_IDENTITY_FILE,
  GET_UNDERWRITING_REPORT,
  GET_CUSTOMER_ONBOARDING_FORMS,
  GET_CUSTOMER_ONBOARDING_FORM,
  POST_CUSTOMER_ONBOARDING_FORM,
  PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM,
  POST_CUSTOMER_ONBOARDING_FORM_LINKS,
  GET_GUEST_ACCESS_FORM,
  POST_SUBMIT_GUEST_ACCESS_FORM,
  GET_ACCESS_FORM_FILE,
  GET_ENTITY_CONFIGURATIONS,
  POST_ENTITY_CONFIGURATION,
  PATCH_ENTITY_CONFIGURATION,
  GET_SPLIT_TRANSFERS,
  GET_SPLIT_TRANSFER,
  GET_TRANSFER_REVERSALS,
  GET_TRANSFER_DISPUTES,
  POST_TRANSFER_REVERSAL,
  GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY,
  GET_SPLIT_TRANSFERS_APPLICATION,
  GET_SPLIT_TRANSFERS_MERCHANT,
  GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS,
  POST_TRANSFER,
  GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES,
  GET_UNDERWRITING_SUBJECT_EVENTS,
  GET_UNDERWRITING_SUBJECT_EVENT,
  GET_APPLICATION_BALANCES,
  GET_CURRENT_USAGES,
  GET_RECIPIENT_IDENTITIES,
  GET_RECIPIENT_IDENTITY_MERCHANTS,
  GET_DISBURSEMENT_RULES,
  GET_BALANCE_ENTRIES,
  GET_BALANCE_ENTRIES_TRANSFER,
  GET_BALANCE_ENTRIES_APPLICATION,
  POST_DISBURSEMENT_RULES,
  PATCH_DISBURSEMENT_RULES,
  POST_BALANCE_ENTRY,
  GET_BALANCE_ENTRY,
  GET_PAYMENT_LINK,
  GET_PAYMENT_LINKS,
  POST_PAYMENT_LINK,
  GET_PAYMENT_LINKS_MERCHANT,
  GET_PAYMENT_LINKS_APPLICATION,
  PATCH_PAYMENT_LINK,
  GET_GUEST_CHECKOUT_TOKEN_VERIFICATION,
  GET_GUEST_CHECKOUT_PAYMENT_LINK,
  GET_PURCHASE,
  GET_PURCHASES,
  POST_PURCHASE,
  GET_PURCHASES_TRANSFER,
  GET_PURCHASE_MERCHANT,
  GET_PURCHASE_MERCHANT_IDENTITY,
  GET_PURCHASE_APPLICATION,
  GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT,
  GET_ONBOARDING_FORM_DATA,
  PATCH_ONBOARDING_FORM_DATA,
  PATCH_ONBOARDING_FORM_BANK_DOCUMENT,
  POST_ONBOARDING_FORM_DATA,
  GET_GUEST_ONBOARDING_FORM_DATA,
  GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA,
  PATCH_RISK_PROFILE,
  PATCH_MERCHANT_RISK_PROFILE,
  GET_GUEST_CHECKOUT_FORM,
  GET_NOTE,
  GET_NOTES,
  POST_NOTE,
  PATCH_NOTE,
  GET_DRAFT_NOTES,
  GET_TRANSFER_MERCHANT,
  GET_DEVICE,
  GET_DEVICES,
  POST_DEVICE,
  PATCH_DEVICE,
  GET_DEVICE_MERCHANT,
  GET_UNDERWRITING_WORKFLOW,
  PATCH_UNDERWRITING_WORKFLOW,
  GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE,
  POST_COMPLIANCE_FORM,
  POST_PLAID_TOKEN,
  POST_PLAID_PUBLIC_TOKEN,
  PATCH_PLAID_TOKEN_METADATA,
  GET_APPLICATION_PROCESSOR_CONFIGURATIONS,
  PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS,
  GET_AUDIT_LOG_EVENTS,
  GET_AUDIT_LOG_EVENT,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS,
  GET_FRAUD_SCORES,
  GET_REVIEW_RUNS,
  POST_SUBMIT_ACCESS_FORM_SALES_REVIEW,
  POST_PAYOUT_LINK,
  GET_PAYOUT_LINK,
  GET_TRANSFER_ATTEMPTS,
  GET_PAYOUT_LINKS,
  PATCH_PAYOUT_LINK,
  GET_TRANSFER_ATTEMPT,
  PATCH_TRANSFER_ATTEMPT,
  GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS,
  GET_GUEST_CHECKOUT_PAYOUT_LINK,
  GET_MERCHANT_STATEMENTS,
  GET_SPLIT_TRANSFER_SETTLEMENT,
  POST_DISPUTE,
  PATCH_UPDATE_DISPUTE,
  PATCH_ONBOARDING_FORM_DOCUMENTS,
  PATCH_ONBOARDING_FORM_DOCUMENT,
  GET_GUEST_ONBOARDING_FORM_FILES,
  GET_FEE_PROFILE_RULES,
  POST_FEE_PROFILE_RULES,
  PATCH_FEE_PROFILE_RULES,
  GET_FEE_PROFILE_SETTINGS,
  POST_FEE_PROFILE_SETTINGS,
  PATCH_FEE_PROFILE_SETTINGS,
  GET_BALANCE_ADJUSTMENTS,
  GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT,
  POST_BALANCE_ADJUSTMENT,
  GET_BALANCE_ADJUSTMENT,
  GET_MERCHANT_TRANSFERS_APPLICATION,
  POST_RECEIPT,
  GET_RECEIPT,
  GET_RECEIPT_DELIVERY_ATTEMPTS,
  POST_RECEIPT_DELIVERY_ATTEMPTS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTIONS_IDENTITY,
  GET_SUBSCRIPTIONS_MERCHANT,
  GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT,
  POST_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_MERCHANT,
  GET_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION,
  GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY,
  DELETE_SUBSCRIPTION,
  PATCH_SUBSCRIPTION_PLAN,
  PATCH_SUBSCRIPTION,
  GET_SUBSCRIPTIONS_PLAN,
  POST_SUBSCRIPTION_PLAN,
  GET_TRANSFER_ATTEMPT_PAYOUT_LINK,
  GET_MONITORING_ALERTS,
  GET_RECEIPTS,
  PATCH_MONITORING_ALERT,
  GET_MONITORING_ALERT,
  PATCH_MONITORING_ALERTS,
  GET_INSTANT_PAYOUTS,
  GET_INSTANT_PAYOUTS_PAYMENT_INSTRUMENT,
  GET_INSTANT_PAYOUTS_MERCHANT,
  GET_INSTANT_PAYOUT,
  GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY,
  GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY,
} from 'constants/flowConstants'

const genericReducers = [
  {
    name: 'applicationsR',
    flows: {
      GET_APPLICATION,
      GET_APPLICATIONS,
      [PATCH_APPLICATION]: { success: mergeOverwriteTags },
      GET_REVIEW_QUEUE_APPLICATION,
      GET_PAYMENT_INSTRUMENT_APPLICATION,
      GET_TRANSFER_APPLICATION,
      GET_COMPLIANCE_FORM_APPLICATION,
      GET_SETTLEMENTS_APPLICATIONS,
      GET_DISPUTE_APPLICATION,
      GET_MERCHANT_APPLICATION,
      GET_COMPLIANCE_FORMS_APPLICATION,
      GET_RELATED_APPLICATION,
      GET_ONBOARDING_FORMS_APPLICATION,
      GET_AUTHORIZATIONS_APPLICATION,
      GET_SPLIT_TRANSFERS_APPLICATION,
      GET_BALANCE_ENTRIES_APPLICATION,
      GET_PAYMENT_LINKS_APPLICATION,
      GET_PURCHASE_APPLICATION,
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS,
      GET_MERCHANT_TRANSFERS_APPLICATION,
      GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION,
      [PATCH_RISK_PROFILE]: {
        success: (state, action) => {
          const riskProfileId = get(action, 'payload.id')
          const riskProfile = get(action, `payload.newValues.${riskProfileId}`)
          const applicationId = get(action, `payload.newValues.${riskProfileId}.application`)

          const application = {
            [applicationId]: {
              riskProfile,
            },
          }

          return merge({}, state, {
            items: application,
            [FETCHING]: false,
          })
        },
      },
      PATCH_APPLICATION_UNDERWRITING_SETTINGS: {
        success: (state, action) => {
          const applicationId = get(action, 'payload.id')
          const actOnDecisions = get(action, `payload.newValues.${applicationId}.act_on_decisions`, false)

          const application = {
            [applicationId]: {
              underwritingDecisionSettings: {
                actOnDecisions,
              },
            },
          }

          return merge({}, state, {
            items: application,
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'associatedIdentitiesR',
    flows: {
      GET_ASSOCIATED_IDENTITIES,
      GET_APPLICATION_ASSOCIATED_IDENTITIES,
    },
  },
  {
    name: 'authorizationsR',
    flows: {
      GET_AUTHORIZATION,
      [PATCH_AUTHORIZATION]: { success: mergeOverwriteTags },
      GET_AUTHORIZATIONS,
      GET_IDENTITY_AUTHORIZATIONS,
      GET_MERCHANT_AUTHORIZATIONS,
      GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
      GET_AUTHORIZATIONS_WIDGET,
    },
  },
  {
    name: 'configurationsR',
    flows: {
      GET_CONFIGURATION,
      GET_CONFIGURATIONS,
    },
  },
  {
    name: 'appConfigurationsR',
    flows: {
      GET_APP_CONFIGURATION,
    },
  },
  {
    name: 'credentialsR',
    flows: {
      GET_CREDENTIALS,
      GET_CREDENTIAL,
      GET_CREDENTIAL_PERMISSIONS,
      GET_ADMIN_USER_CREDENTIALS,
      PATCH_CREDENTIAL,
      GET_RECENTLY_ACTIVE_CREDENTIALS,
    },
  },
  {
    name: 'recentlyActiveCredentialsR',
    flows: {
      [GET_RECENTLY_ACTIVE_CREDENTIALS]: {
        success: (state, action) => {
          const newValues = get(action, 'payload.newValues')
          const sortedNewValues = orderBy(newValues, ['displayLastActivity'], ['desc'])
          const count = get(action, 'payload.page.count')
          const normalizedNewValues = drop(sortedNewValues, 1)

          return {
            items: normalizedNewValues,
            [FETCHING]: false,
            count,
          }
        },
      },
    },
  },
  {
    name: 'disputesR',
    flows: {
      GET_DISPUTE,
      GET_DISPUTES,
      GET_IDENTITY_DISPUTES,
      GET_APPLICATION_DISPUTES,
      GET_DISPUTES_WIDGET,
      PATCH_DISPUTE,
      POST_DISPUTE,
      PATCH_UPDATE_DISPUTE,
    },
  },
  {
    name: 'transferDisputesR',
    flows: {
      GET_TRANSFER_DISPUTES,
    },
  },
  {
    name: 'evidencesR',
    flows: {
      GET_DISPUTE_EVIDENCES,
      POST_DISPUTE_EVIDENCES,
    },
  },
  {
    name: 'identitiesR',
    flows: {
      GET_IDENTITIES,
      GET_IDENTITY,
      POST_IDENTITY,
      [PATCH_IDENTITY]: { success: mergeOverwriteTags },
      GET_APPLICATION_IDENTITIES,
      GET_TRANSFERS_MERCHANT_IDENTITY,
      GET_AUTHORIZATIONS_MERCHANT_IDENTITY,
      GET_MERCHANT_IDENTITY,
      GET_SETTLEMENTS_IDENTITY,
      GET_APPLICATION_SETTLEMENTS_IDENTITY,
      GET_REVIEW_QUEUE_MERCHANTS_IDENTITY,
      GET_IDENTITIES_WIDGET,
      PATCH_MERCHANT_IDENTITY,
      GET_APPLICATIONS_IDENTITY,
      GET_PAYMENT_INSTRUMENT_IDENTITY,
      GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY,
      GET_RELATED_IDENTITY,
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES,
      GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY,
      GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES,
      GET_RECIPIENT_IDENTITY_MERCHANTS,
      GET_RECIPIENT_IDENTITIES,
      GET_PURCHASE_MERCHANT_IDENTITY,
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES,
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS,
      GET_SUBSCRIPTIONS_IDENTITY,
      GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY,
    },
  },
  {
    name: 'merchantsR',
    flows: {
      GET_MERCHANT,
      PATCH_MERCHANT,
      GET_MERCHANTS,
      GET_IDENTITY_MERCHANTS,
      POST_IDENTITY_MERCHANTS,
      GET_APPLICATION_MERCHANTS,
      GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT,
      GET_SETTLEMENTS_MERCHANT,
      GET_REVIEW_QUEUE_MERCHANTS,
      GET_MERCHANTS_WIDGET,
      GET_FEES_MERCHANT,
      GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT,
      GET_SPLIT_TRANSFERS_MERCHANT,
      GET_PAYMENT_LINKS_MERCHANT,
      GET_PURCHASE_MERCHANT,
      GET_DEVICE_MERCHANT,
      PATCH_RISK_PROFILE,
      GET_TRANSFER_MERCHANT,
      GET_SUBSCRIPTIONS_MERCHANT,
      GET_SUBSCRIPTION_PLANS_MERCHANT,
      GET_INSTANT_PAYOUTS_MERCHANT,
      [PATCH_MERCHANT_RISK_PROFILE]: {
        success: (state, action) => {
          const riskProfileId = get(action, 'payload.id')
          const riskProfile = get(action, `payload.newValues.${riskProfileId}`)
          const merchantId = get(action, 'meta.merchantId')

          const merchant = {
            [merchantId]: {
              riskProfile,
            },
          }

          return merge({}, state, {
            items: merchant,
            [FETCHING]: false,
          })
        },
      },
      [POST_IDENTITY_PAYMENT_INSTRUMENTS]: {
        success: (state, action) => {
          const paymentInstrument = get(action, 'payload.newValues')
          const merchantId = get(action, 'meta.merchantId')

          const newValues = merchantId ? {
            [merchantId]: {
              paymentInstruments: paymentInstrument,
            },
          } : {}

          return updateState(state, {}, false, newValues)
        },
      },
    },
  },
  {
    name: 'merchantFilesR',
    flows: {
      GET_MERCHANT_FILES,
      POST_MERCHANT_FILE,
    },
  },
  {
    name: 'identityFilesR',
    flows: {
      GET_IDENTITY_FILES,
      POST_IDENTITY_FILE,
    },
  },
  {
    name: 'payoutSettingsR',
    flows: {
      GET_MERCHANT_PAYOUT_SETTINGS,
      GET_PAYOUT_SETTINGS,
    },
  },
  {
    name: 'merchantPayoutSettingsR',
    flows: {
      [GET_PAYOUT_SETTINGS]: {
        success: (state, action) => {
          const payoutSettings = head(Object.values(get(action, 'payload.newValues')))
          const merchantId = get(payoutSettings, 'linkedId')
          const newValues = { [merchantId]: payoutSettings }

          return updateState(state, action, false, newValues)
        },
      },
      [GET_MERCHANT_PAYOUT_SETTINGS]: {
        success: (state, action) => {
          const payoutSettings = head(Object.values(get(action, 'payload.newValues')))
          const merchantId = get(payoutSettings, 'linkedId')
          const newValues = { [merchantId]: payoutSettings }

          return updateState(state, action, false, newValues)
        },
      },
    },
  },
  {
    name: 'paymentInstrumentsR',
    flows: {
      GET_PAYMENT_INSTRUMENT,
      GET_PAYMENT_INSTRUMENTS,
      GET_IDENTITY_PAYMENT_INSTRUMENTS,
      POST_IDENTITY_PAYMENT_INSTRUMENTS,
      [PATCH_PAYMENT_INSTRUMENT]: { success: mergeOverwriteTags },
      GET_TRANSFERS_PAYMENT_INSTRUMENT,
      GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT,
      GET_IDENTITY_AUTHORIZATIONS_PAYMENT_INSTRUMENT,
      GET_IDENTITY_TRANSFERS_PAYMENT_INSTRUMENT,
      GET_PAYMENT_INSTRUMENTS_WIDGET,
      GET_RELATED_PAYMENT_INSTRUMENT,
      GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT,
      GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS,
      GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT,
      GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT,
      GET_INSTANT_PAYOUTS_PAYMENT_INSTRUMENT,
    },
  },
  {
    name: 'reportsR',
    flows: {
      GET_REPORTS,
    },
  },
  {
    name: 'categorizedReportsR',
    flows: {
      GET_CATEGORIZED_REPORTS,
    },
  },
  {
    name: 'reviewQueueR',
    flows: {
      GET_REVIEW_QUEUE_WIDGET,
      GET_REVIEW_QUEUE,
      GET_REVIEW_QUEUE_ITEM,
      [PATCH_REVIEW_QUEUE_ITEM]: { success: mergeOverwriteKeys(['outcomeReason']) },
      PATCH_REVIEW_QUEUE_ITEMS,
    },
  },
  {
    name: 'underwritingMerchantApplicationsR',
    flows: {
      GET_REVIEW_QUEUE,
    },
  },
  {
    name: 'settlementsR',
    flows: {
      GET_SETTLEMENT,
      GET_SETTLEMENTS,
      GET_IDENTITY_SETTLEMENTS,
      GET_APPLICATION_SETTLEMENTS,
      GET_REVIEW_QUEUE_SETTLEMENTS,
      GET_SETTLEMENT_TRANSFER_FEE_COUNTS,
      GET_MERCHANT_SETTLEMENTS,
      PATCH_SETTLEMENT,
      CLOSE_SETTLEMENTS,
      GET_SETTLEMENT_GROUP_SETTLEMENTS,
      [GET_SPLIT_TRANSFER_SETTLEMENT]: {
        success: (state, action) => {
          const settlement = head(Object.values(get(action, 'payload.newValues')))
          const settlementId = get(settlement, 'id')
          const splitTransferId = head(Object.values(get(action, 'payload.queries')))
          const newValues = { [settlementId]: { ...settlement, splitTransferId } }

          return updateState(state, action, false, newValues)
        },
      },
    },
  },
  {
    name: 'settlementGroupsR',
    flows: {
      GET_SETTLEMENT_GROUP,
    },
  },
  {
    name: 'feesR',
    flows: {
      GET_FEES,
      GET_FEE,
      GET_SETTLEMENT_ENTRIES_FEE,
      GET_MERCHANT_FEES,
      [PATCH_FEE]: { success: mergeOverwriteTags },
      GET_SUBSCRIPTION_SCHEDULE_FEES,
    },
  },
  {
    name: 'payoutPlansR',
    flows: {
      GET_PAYOUT_PLAN,
      GET_PAYOUT_PLANS,
      POST_PAYOUT_PLAN,
      // PATCH_PAYOUT_PLAN,
    },
  },
  {
    name: 'entriesR',
    flows: {
      GET_SETTLEMENT_ENTRIES,
      GET_TRANSFER_FEES_SETTLEMENT_ENTRIES,
      [DELETE_SETTLEMENT_ENTRIES]: {
        success: (state, action) => removeItems(state, get(action, 'payload.values.entry_ids')),
      },
    },
  },
  {
    name: 'transfersR',
    flows: {
      GET_TRANSFER,
      [PATCH_TRANSFER]: { success: mergeOverwriteTags },
      GET_TRANSFERS,
      POST_TRANSFER,
      GET_DISPUTE_ADJUSTMENT_TRANSFERS,
      GET_IDENTITY_TRANSFERS,
      GET_MERCHANT_TRANSFERS,
      GET_SETTLEMENT_TRANSFERS,
      GET_SETTLEMENT_FUNDING_TRANSFERS,
      GET_APPLICATION_TRANSFERS,
      GET_PAYMENT_INSTRUMENT_TRANSFERS,
      GET_FUNDING_TRANSFERS_RELATED_TRANSFER,
      GET_SETTLEMENT_TRANSFER_FEES,
      GET_TRANSFERS_WIDGET,
      GET_SETTLEMENT_ENTRIES_TRANSFER,
      GET_DISPUTE_TRANSFER,
      GET_BALANCE_ENTRIES_TRANSFER,
      GET_PURCHASES_TRANSFER,
    },
  },
  {
    name: 'transferReversalsR',
    flows: {
      GET_TRANSFER_REVERSALS,
      POST_TRANSFER,
      POST_TRANSFER_REVERSAL: {
        success: (state, action) => {
          const id = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          const data = {
            [id]: {
              reversals: newValues,
            },
          }

          return updateState(state, action, false, data)
        },
      },
    },
  },
  {
    name: 'balanceTransfersR',
    flows: {
      GET_BALANCE_TRANSFERS,
      GET_BALANCE_TRANSFER,
    },
  },
  {
    name: 'usersR',
    flows: {
      GET_USERS,
      GET_DASHBOARD_USERS,
      GET_ADMIN_USER,
      POST_ADMIN_USER,
      // GET_PLATFORM_USERS,
      // GET_PLATFORM_USER,
    },
  },
  {
    name: 'membershipsR',
    flows: {
      GET_ADMIN_USER_MEMBERSHIPS,
    },
  },
  {
    name: 'verificationsR',
    flows: {
      GET_IDENTITY_VERIFICATIONS,
      GET_IDENTITY_VERIFICATION,
      POST_IDENTITY_VERIFICATIONS,
      GET_MERCHANT_VERIFICATIONS,
      GET_MERCHANT_VERIFICATION,
      POST_MERCHANT_VERIFICATIONS,
      GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
      POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
    },
  },
  {
    name: 'processorsR',
    flows: {
      GET_APPLICATION_PROCESSORS,
      POST_APPLICATION_PROCESSORS,
      GET_APPLICATION_PROCESSOR,
    },
  },
  {
    name: 'webhooksR',
    flows: {
      GET_WEBHOOK,
      GET_WEBHOOKS,
      POST_WEBHOOKS,
      PATCH_WEBHOOK,
    },
  },
  {
    name: 'webhookLogsR',
    flows: {
      GET_WEBHOOK_LOGS,
      GET_WEBHOOK_LOG,
    },
  },
  {
    name: 'webhooksWithStoppedEventsR',
    flows: {
      GET_WEBHOOKS_WITH_STOPPED_EVENTS,
    },
  },
  {
    name: 'settingsR',
    flows: {
      GET_SETTINGS,
    },
  },
  {
    name: 'applicationUsersR',
    flows: {
      GET_APPLICATION_USERS,
      POST_APPLICATION_USERS,
      POST_PLATFORM_USER,
      GET_PLATFORM_USERS,
      GET_PLATFORM_USER,
      PATCH_USER,
      DELETE_API_KEY: {
        success: (state, action) => {
          const id = get(action, 'payload.id')

          return merge({}, state, {
            items: { [id]: { state: false } },
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'searchR',
    flows: {
      GET_SEARCH,
      [LOCATION_CHANGE]: () => ({}),
    },
  },
  {
    name: 'massApprovalR',
    flows: {
      POST_MASS_SETTLEMENT_APPROVAL,
      POST_MASS_SETTLEMENT_APPROVAL_BATCH: {
        success: (state, action) => merge({}, state, get(action, 'payload.newValues', {})),
      },
    },
  },
  {
    name: 'getAllReviewQueueItemsR',
    flows: {
      [GET_ALL_REVIEW_QUEUE_ITEMS]: {
        request: (state, action) => merge({}, state, get(action, 'payload.newValues', {})),
        failure: (state, action) => merge({}, state, get(action, 'payload.newValues', {})),
      },
      [GET_ALL_REVIEW_QUEUE_ITEMS_BATCH]: {
        success: (state, action) => merge({}, state, get(action, 'payload.newValues', {})),
      },
    },
  },
  {
    name: 'identityUploaderR',
    flows: {
      POST_IDENTITY_UPLOAD,
      [POST_IDENTITY_UPLOAD_BATCH]: {
        success: (state, action) => merge({}, omit(state, 'export'), get(action, 'payload.newValues', {})),
        failure: (state, action) => merge({}, omit(state, 'export'), get(action, 'payload.newValues', {})),
      },
      [CLEAR_IDENTITY_UPLOADER_REDUCER]: {
        request: (state, action) => merge({}, omit(state, 'export'), get(action, 'payload.newValues', {})),
      },
    },
  },
  {
    name: 'emailTemplatesR',
    flows: {
      GET_WELCOME_EMAIL_TEMPLATE,
      PATCH_WELCOME_EMAIL_TEMPLATE,
      GET_EMAIL_TEMPLATE,
      PATCH_EMAIL_TEMPLATE,
    },
  },
  {
    name: 'platformsR',
    flows: {
      GET_PLATFORM,
      GET_PLATFORMS,
    },
  },
  {
    name: 'processorConfigsR',
    flows: {
      GET_PLATFORM_PROCESSOR_CONFIG,
      GET_PLATFORM_PROCESSOR_CONFIGS,
      GET_APPLICATION_PROCESSOR_CONFIGURATIONS,
      [PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS]: {
        success: (state, action) => {
          const newValues = get(action, 'payload.newValues')
          const processorId = head(keys(newValues))

          // need to omit the old values to clear out anything that was removed
          return merge({}, omit(state, `items.${processorId}`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'userPermissionsR',
    flows: {
      GET_USER_PERMISSIONS,
      [PATCH_USER_PERMISSIONS]: {
        success: (state, action) => {
          const id = get(action, 'payload.id')
          const userPermissions = { id, permissions: get(action, 'payload.newValuesOnSuccess', []) }

          return merge({}, omit(state, `items.${id}`), {
            items: { [id]: userPermissions },
          })
        },
      },
    },
  },
  {
    name: 'notificationsR',
    flows: {
      GET_NOTIFICATIONS,
      GET_LATEST_NOTIFICATIONS,
      POST_NOTIFICATION_ACKNOWLEDGMENT,
    },
  },
  {
    name: 'subscriptionSchedulesR',
    flows: {
      GET_SUBSCRIPTION_SCHEDULE,
      GET_SUBSCRIPTION_SCHEDULES,
      POST_SUBSCRIPTION_SCHEDULE,
      GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS,
      GET_SUBSCRIPTION_SCHEDULES_AMOUNTS,
    },
  },
  {
    name: 'subscriptionAmountsR',
    flows: {
      GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
      POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
      [PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT]: {
        success: (state, action) => {
          const amountId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, omit(state, `items.${amountId}`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
      [DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT]: {
        success: (state, action) => removeItems(state, get(action, 'payload.id')),
      },
    },
  },
  {
    name: 'subscriptionEnrollmentsR',
    flows: {
      GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
      POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
      GET_SUBSCRIPTION_ENROLLMENT,
      GET_SUBSCRIPTION_ENROLLMENTS,
      [DELETE_SUBSCRIPTION_ENROLLMENTS]: {
        success: (state, action) => removeItems(state, get(action, 'payload.values.enrollmentIds')),
      },
      GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
    },
  },
  {
    name: 'currentUserR',
    flows: {
      GET_CURRENT_USER,
      PATCH_DASHBOARD_USER: {
        success: (state, action) => {
          const dashboardId = get(action, 'payload.id')
          const fullName = get(action, `payload.newValues.${dashboardId}.fullName`)

          return merge({}, state, {
            items: { fullName },
            [FETCHING]: false,
          })
        },
      },
      CLEAR_CURRENT_USER: {
        success: () => ({
          [FETCHING]: false,
        }),
      },
    },
  },
  {
    name: 'dashboardConfigurationsR',
    flows: {
      GET_DASHBOARD_CUSTOMIZATIONS,
      GET_GUEST_DASHBOARD_CUSTOMIZATIONS,
      POST_DASHBOARD_CUSTOMIZATIONS,
      [PATCH_DASHBOARD_CUSTOMIZATIONS]: {
        success: (state, action) => {
          const newValues = get(action, 'payload.newValues')
          const dashboardCustomizationId = get(newValues, 'id')

          // need to omit the old customization to clear out anything that was removed
          return merge({}, omit(state, `items.${dashboardCustomizationId}`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
      [GET_CURRENT_USER]: {
        request: (state) => state,
        success: (state, action) => {
          // special reducer logic to take current user custom settings from getUserO
          const customSettings = get(action, 'payload.newValues.customSettings')
          const id = get(customSettings, 'id')

          if (!id) {
            return state
          }

          return merge({}, state, {
            items: {
              [id]: customSettings,
            },
          })
        },
        failure: (state) => state,
      },
      [GET_DASHBOARD_CONTRACTS]: {
        request: (state) => state,
        success: (state, action) => {
          const contractId = get(action, 'payload.newValues.id')
          const dashboardCustomizationId = first(keys(get(state, 'items')))

          return merge({}, state, {
            items: {
              [dashboardCustomizationId]: { contractId },
            },
          })
        },
        failure: (state) => state,
      },
    },
  },
  {
    name: 'filesR',
    flows: {
      GET_FILE,
      GET_ACCESS_FORM_FILE,
      GET_FILES,
      GET_GUEST_ONBOARDING_FORM_FILES,
    },
  },
  {
    name: 'applePayRegisteredMerchantR',
    flows: {
      GET_PAYMENT_METHOD_CONFIGURATION,
      POST_PAYMENT_METHOD_CONFIGURATIONS,
      PATCH_PAYMENT_METHOD_CONFIGURATIONS,
    },
  },
  {
    name: 'applePayCertsR',
    flows: {
      POST_CERTIFICATE_SIGNING_REQUESTS,
      GET_CERTIFICATE_SIGNING_REQUESTS,
    },
  },
  {
    name: 'complianceFormsR',
    flows: {
      GET_COMPLIANCE_FORMS,
      GET_COMPLIANCE_FORM,
      GET_MERCHANT_COMPLIANCE_FORMS,
      [PATCH_COMPLIANCE_FORM]: { success: mergeOverwriteTags },
      GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
      POST_COMPLIANCE_FORM,
    },
  },
  {
    name: 'complianceFormTemplatesR',
    flows: {
      GET_COMPLIANCE_FORM_TEMPLATES,
      GET_COMPLIANCE_FORM_TEMPLATE,
      POST_COMPLIANCE_FORM_TEMPLATE,
      PATCH_COMPLIANCE_FORM_TEMPLATE,
      GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE,
    },
  },
  {
    name: 'complianceSettingsR',
    flows: {
      [GET_COMPLIANCE_SETTINGS]: {
        success: (state, action) => {
          return updateState(state, action, false, get(action, 'payload.newValues'), true)
        },
      },
      PATCH_COMPLIANCE_SETTINGS,
    },
  },
  {
    name: 'hostedFormTokenDataR',
    flows: {
      GET_HOSTED_FORM_TOKEN_VERIFICATION,
    },
  },
  {
    name: 'onboardingFormsR',
    flows: {
      GET_ONBOARDING_FORMS,
    },
  },
  {
    name: 'onboardingFormDataR',
    flows: {
      GET_ONBOARDING_FORM_DATA,
      PATCH_ONBOARDING_FORM_DATA,
      PATCH_ONBOARDING_FORM_BANK_DOCUMENT,
      POST_ONBOARDING_FORM_DATA,
      GET_GUEST_ONBOARDING_FORM_DATA,
      GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA,
      PATCH_ONBOARDING_FORM_DOCUMENTS,
      PATCH_ONBOARDING_FORM_DOCUMENT,
    },
  },
  {
    name: 'exportsR',
    flows: {
      GET_EXPORTS,
    },
  },
  {
    name: 'notificationSettingsR',
    flows: {
      GET_NOTIFICATION_SETTINGS,
    },
  },
  {
    name: 'apiLogsR',
    flows: {
      GET_API_LOGS,
      GET_API_LOG,
    },
  },
  {
    name: 'selfServiceSignupFormR',
    flows: {
      POST_SELF_SERVICE_EMAIL_VERIFICATION,
    },
  },
  {
    name: 'membersR',
    flows: {
      GET_MEMBER,
      GET_MEMBERS,
      PATCH_CREDENTIAL,
      GET_ROLE_MEMBERS,
    },
  },
  {
    name: 'rolesR',
    flows: {
      GET_ROLES,
      GET_ROLE,
      PATCH_ROLE,
      GET_MEMBER_ROLE,
    },
  },
  {
    name: 'membershipsR',
    flows: {
      PATCH_MEMBERSHIP,
    },
  },
  {
    name: 'accessFormsR',
    flows: {
      GET_ACCESS_FORM,
      GET_GUEST_ACCESS_FORM,
      GET_ACCESS_FORMS,
      POST_ACCESS_FORM,
      PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
      GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
      PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM,
      POST_SUBMIT_ACCESS_FORM_SALES_REVIEW,
      POST_SUBMIT_ACCESS_FORM,
      POST_SUBMIT_GUEST_ACCESS_FORM,
      [PATCH_ACCESS_FORM]: {
        success: (state, action) => {
          const accessFormId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, omit(state, `items.${accessFormId}.beneficialOwners`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
      [PATCH_GUEST_ACCESS_FORM]: {
        success: (state, action) => {
          const accessFormId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, omit(state, `items.${accessFormId}.beneficialOwners`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
      [GET_ACCESS_FORM_CONTRACT]: {
        success: (state, action) => {
          const accessFormId = get(action, 'payload.id')
          const contractId = get(action, 'payload.newValues.id')

          return merge({}, state, {
            items: {
              [accessFormId]: { contractId },
            },
          })
        },
      },
    },
  },
  {
    name: 'underwritingReviewsR',
    flows: {
      GET_UNDERWRITING_REVIEW,
      GET_UNDERWRITING_REVIEWS,
      [PATCH_UNDERWRITING_REVIEW]: {
        success: (state, action) => {
          const reviewId = get(action, 'payload.values.id')
          const review = get(action, 'payload.newValues')

          return merge({}, omit(state, `items.${reviewId}.reasons`), { items: review, [FETCHING]: false })
        },
      },
      GET_MERCHANT_UNDERWRITING_REVIEWS,
      GET_RECIPIENT_UNDERWRITING_REVIEWS,
    },
  },
  {
    name: 'underwritingReportsR',
    flows: {
      GET_UNDERWRITING_REPORT,
    },
  },
  {
    name: 'feeProfilesR',
    flows: {
      GET_FEE_PROFILE,
      POST_FEE_PROFILE,
      POST_MERCHANT_FEE_PROFILE,
    },
  },
  {
    name: 'feeProfileRulesR',
    flows: {
      GET_FEE_PROFILE_RULES,
      POST_FEE_PROFILE_RULES,
      PATCH_FEE_PROFILE_RULES,
    },
  },
  {
    name: 'feeProfileSettingsR',
    flows: {
      GET_FEE_PROFILE_SETTINGS,
      [POST_FEE_PROFILE_SETTINGS]: {
        success: (state, action) => {
          const entityId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, state, {
            items: {
              [entityId]: newValues,
            },
            [FETCHING]: false,
          })
        },
      },
      [PATCH_FEE_PROFILE_SETTINGS]: {
        success: (state, action) => {
          const entityId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, state, {
            items: {
              [entityId]: newValues,
            },
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'underwritingProfilesR',
    flows: {
      GET_UNDERWRITING_PROFILE,
      GET_UNDERWRITING_PROFILES,
      GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE,
      POST_UNDERWRITING_PROFILE,
      [PATCH_UNDERWRITING_PROFILE]: {
        success: (state, action) => {
          const underwritingProfileId = get(action, 'payload.id')
          const underwritingProfile = get(action, 'payload.newValues')

          // TODO: Remove once BE returns linked_to and linked_type correctly
          const omittedUnderwritingProfile = omit(underwritingProfile, [`${underwritingProfileId}.linkedTo`, `${underwritingProfileId}.linkedType`])

          return merge({}, omit(state, `items.${underwritingProfileId}.mccDetails`), { items: omittedUnderwritingProfile, [FETCHING]: false })
        },
      },
    },
  },
  {
    name: 'customerOnboardingFormsR',
    flows: {
      GET_CUSTOMER_ONBOARDING_FORMS,
      GET_CUSTOMER_ONBOARDING_FORM,
      POST_CUSTOMER_ONBOARDING_FORM,
    },
  },
  {
    name: 'customerOnboardingFormLinksR',
    flows: {
      [POST_CUSTOMER_ONBOARDING_FORM_LINKS]: {
        success: (state, action) => {
          const customerOnboardingFormId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')
          return merge({}, state, {
            items: {
              [customerOnboardingFormId]: newValues,
            },
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'entityConfigurationsR',
    flows: {
      GET_ENTITY_CONFIGURATIONS,
      POST_ENTITY_CONFIGURATION,
      PATCH_ENTITY_CONFIGURATION,
    },
  },
  {
    name: 'splitTransfersR',
    flows: {
      GET_SPLIT_TRANSFERS,
      GET_SPLIT_TRANSFER,
      GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS,
      GET_SPLIT_TRANSFER_SETTLEMENT,
    },
  },
  {
    name: 'underwritingSubjectEventsR',
    flows: {
      GET_UNDERWRITING_SUBJECT_EVENTS,
      GET_UNDERWRITING_SUBJECT_EVENT,
    },
  },
  {
    name: 'balancesR',
    flows: {
      GET_APPLICATION_BALANCES,
    },
  },
  {
    name: 'currentUsagesR',
    flows: {
      GET_CURRENT_USAGES,
    },
  },
  {
    name: 'disbursementRulesR',
    flows: {
      [GET_DISBURSEMENT_RULES]: {
        success: (state, action) => {
          return merge({}, state, {
            items: get(action, 'payload.newValues'),
            [FETCHING]: false,
          })
        },
      },
      POST_DISBURSEMENT_RULES,
      PATCH_DISBURSEMENT_RULES,
    },
  },
  {
    name: 'balanceEntriesR',
    flows: {
      GET_BALANCE_ENTRIES,
      POST_BALANCE_ENTRY,
      GET_BALANCE_ENTRY,
      GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY,
      GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY,
    },
  },
  {
    name: 'paymentLinksR',
    flows: {
      GET_PAYMENT_LINK,
      GET_PAYMENT_LINKS,
      POST_PAYMENT_LINK,
      PATCH_PAYMENT_LINK,
      GET_GUEST_CHECKOUT_PAYMENT_LINK,
    },
  },
  {
    name: 'guestCheckoutTokenVerificationR',
    flows: {
      [GET_GUEST_CHECKOUT_TOKEN_VERIFICATION]: {
        success: (state, action) => get(action, 'payload.newValues'),
      },
    },
  },
  {
    name: 'purchasesR',
    flows: {
      GET_PURCHASE,
      GET_PURCHASES,
      POST_PURCHASE,
    },
  },
  {
    name: 'checkoutFormsR',
    flows: {
      GET_GUEST_CHECKOUT_FORM,
    },
  },
  {
    name: 'notesR',
    flows: {
      GET_NOTE,
      GET_NOTES,
      POST_NOTE,
      PATCH_NOTE,
      GET_DRAFT_NOTES,
      [PATCH_NOTE]: {
        success: (state, action) => {
          const noteId = get(action, 'payload.id')
          const newValues = get(action, 'payload.newValues')

          return merge({}, omit(state, `items.${noteId}`), {
            items: newValues,
            [FETCHING]: false,
          })
        },
      },
    },
  },
  {
    name: 'devicesR',
    flows: {
      GET_DEVICE,
      GET_DEVICES,
      POST_DEVICE,
      PATCH_DEVICE,
    },
  },
  {
    name: 'underwritingWorkflowsR',
    flows: {
      GET_UNDERWRITING_WORKFLOW,
      PATCH_UNDERWRITING_WORKFLOW,
    },
  },
  {
    name: 'plaidLinkModalTokensR',
    flows: {
      [POST_PLAID_TOKEN]: { success: (state, action) => get(action, 'payload.newValues') },
    },
  },
  {
    name: 'plaidPublicTokensR',
    flows: {
      [POST_PLAID_PUBLIC_TOKEN]: { success: (state, action) => get(action, 'payload.newValues') },
    },
  },
  {
    name: 'plaidTokenMetadataR',
    flows: {
      [PATCH_PLAID_TOKEN_METADATA]: { request: (state, action) => get(action, 'payload.newValues.metadata') },
    },
  },
  {
    name: 'auditLogEventsR',
    flows: {
      GET_AUDIT_LOG_EVENTS,
      GET_AUDIT_LOG_EVENT,
    },
  },
  {
    name: 'fraudScoresR',
    flows: {
      GET_FRAUD_SCORES,
    },
  },
  {
    name: 'runsR',
    flows: {
      GET_REVIEW_RUNS,
    },
  },
  {
    name: 'payoutLinksR',
    flows: {
      POST_PAYOUT_LINK,
      GET_PAYOUT_LINK,
      GET_PAYOUT_LINKS,
      PATCH_PAYOUT_LINK,
      GET_GUEST_CHECKOUT_PAYOUT_LINK,
      GET_TRANSFER_ATTEMPT_PAYOUT_LINK,
    },
  },
  {
    name: 'transferAttemptsR',
    flows: {
      GET_TRANSFER_ATTEMPTS,
      GET_TRANSFER_ATTEMPT,
      PATCH_TRANSFER_ATTEMPT,
      GET_GUEST_CHECKOUT_PAYOUT_LINK,
    },
  },
  {
    name: 'statementsR',
    flows: {
      GET_MERCHANT_STATEMENTS,
    },
  },
  {
    name: 'balanceAdjustmentsR',
    flows: {
      GET_BALANCE_ADJUSTMENTS,
      POST_BALANCE_ADJUSTMENT,
      GET_BALANCE_ADJUSTMENT,
    },
  },
  {
    name: 'receiptsR',
    flows: {
      POST_RECEIPT,
      GET_RECEIPT,
      GET_RECEIPTS,
    },
  },
  {
    name: 'receiptDeliveryAttemptsR',
    flows: {
      GET_RECEIPT_DELIVERY_ATTEMPTS,
      POST_RECEIPT_DELIVERY_ATTEMPTS,
    },
  },
  {
    name: 'subscriptionsR',
    flows: {
      GET_SUBSCRIPTIONS,
      GET_SUBSCRIPTION,
      POST_SUBSCRIPTION,
      [DELETE_SUBSCRIPTION]: {
        success: (state, action) => {
          const subscriptionId = get(action, 'payload.id')

          return merge({}, state, {
            items: {
              [subscriptionId]: { state: 'CANCELED' },
            },
            [FETCHING]: false,
          })
        },
      },
      PATCH_SUBSCRIPTION,
    },
  },
  {
    name: 'subscriptionPlansR',
    flows: {
      GET_SUBSCRIPTION_PLANS,
      GET_SUBSCRIPTION_PLAN,
      [PATCH_SUBSCRIPTION_PLAN]: {
        success: (state, action) => {
          const subscriptionPlanId = get(action, 'payload.newValues')

          return merge({}, state, {
            items: {
              [subscriptionPlanId]: { state: 'INACTIVE' },
            },
            [FETCHING]: false,
          })
        },
      },
      GET_SUBSCRIPTIONS_PLAN,
      POST_SUBSCRIPTION_PLAN,
    },
  },
  {
    name: 'monitoringAlertsR',
    flows: {
      GET_MONITORING_ALERTS,
      PATCH_MONITORING_ALERT,
      GET_MONITORING_ALERT,
      PATCH_MONITORING_ALERTS,
    },
  },
  {
    name: 'fundingTransferAttemptsR',
    flows: {
      GET_INSTANT_PAYOUTS,
      GET_INSTANT_PAYOUT,
    },
  },
]

export default genericReducers
