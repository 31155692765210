import { GET_RECEIPT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getReceiptRequest = ({
  receiptId,
  credentials,
  queries,
  meta = { hideFlashNotification: true },
}) => createAction({
  type: GET_RECEIPT_F_REQUEST,
  id: receiptId,
  credentials,
  queries,
  meta,
})

export default getReceiptRequest
