import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'

const frontendBalanceTransferModel = ({ data: balanceTransfer }) => {
  const [
    id,
    amount,
    currency,
    description,
    source,
    destination,
    railType,
    platformId,
    tags,
    createdAt,
    updatedAt,
    state,
    externalReferenceId,
    referenceId,
    processor,
  ] = getMany(balanceTransfer, [
    'id',
    'amount',
    'currency',
    'description',
    'source',
    'destination',
    'rail_type',
    'platform_id',
    'tags',
    'created_at',
    'updated_at',
    'state',
    'external_reference_id',
    'reference_id',
    'processor_type',
  ])

  return removeUndefined({
    id,
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    description,
    source,
    destination,
    railType,
    platformId,
    tags,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    state,
    externalReferenceId,
    referenceId,
    processor,
  })
}

export default frontendBalanceTransferModel
