import React, { Component } from 'react'
import FilesManagement from './FilesManagement'
import { connect } from 'react-redux'
import ResponseFilesC from 'components/GatewayAdmin/Pages/FilesManagement/ResponseFiles/ResponseFilesC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const tabs = [
  {
    id: 'response-files',
    name: 'LITLE Response Files',
    component: ResponseFilesC,
  },
  // {
  //   id: 'response-files',
  //   name: 'EMAF Files',
  // },
]

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  const headerTitle = 'Manage Files'

  return {
    credentials,
    headerTitle,
    tabs,
  }
}

class FilesManagementC extends Component {
  render() {
    return (
      <FilesManagement {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(FilesManagementC)
