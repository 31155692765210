import getMany from 'utilities/get/getMany'
import toString from 'lodash/toString'

import {
  fieldEmpty,
  nonEmptyPositiveAmount,
} from 'utilities/validate'

import {
  START_TIME,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_START_DATE,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  MAX_TRANSACTION_AMOUNT_FOR_ACH,
} from 'constants/language/languageConstants'

const validateUpdateBalanceAdjustmentsVelocityLimitsForm = (values, props) => {
  const [
    maxTransactionAmountForACH,
    startTime,
    dailyACHCount,
    dailyACHVolume,
    monthlyStartDate,
    monthlyACHCount,
    monthlyACHVolume,
  ] = getMany(values, [
    'maxTransactionAmountForACH',
    'startTime.value',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyACHCount',
    'monthlyACHVolume',
  ])

  return {
    startTime: fieldEmpty(toString(startTime), START_TIME),
    dailyACHCount: fieldEmpty(toString(dailyACHCount), DAILY_ACH_COUNT),
    dailyACHVolume: fieldEmpty(toString(dailyACHVolume), DAILY_ACH_VOLUME),
    monthlyStartDate: fieldEmpty(toString(monthlyStartDate), MONTHLY_START_DATE),
    monthlyACHCount: fieldEmpty(toString(monthlyACHCount), MONTHLY_ACH_COUNT),
    monthlyACHVolume: fieldEmpty(toString(monthlyACHVolume), MONTHLY_ACH_VOLUME),
    maxTransactionAmountForACH: nonEmptyPositiveAmount({ field: maxTransactionAmountForACH, name: MAX_TRANSACTION_AMOUNT_FOR_ACH }),
  }
}

export default validateUpdateBalanceAdjustmentsVelocityLimitsForm
