import './ViewAPIResponseModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import APIResponse from 'components/Customer/Shared/Display/APIResponse/APIResponse'
import Button from 'components/Shared/Button/Button'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import { API_METHOD, API_RESPONSE, API_ROUTE, API_DOCS_LABEL } from 'constants/language/languageConstants'
import { COPY, EXTERNAL_LINK } from 'constants/iconConstants'
import map from 'lodash/map'

const ViewAPIResponseModal = ({
  api = () => {},
  apiProps = {},
  getKey,
  apiMethod,
  apiRoute,
  apiDocuments,
}) => {
  return (
    <GenericModal
      title={API_RESPONSE}
      className='ViewAPIResponseModal'
    >
      {apiRoute && (
      <div className='flex row flex-grow items-flex-start fields'>
        <label className='label'>{API_ROUTE}</label>
        <div className='value flex col'>
          <div className='api-route flex-grow'>
            <ClickToCopyC content={apiRoute} customView={<span className='text-link'>{apiRoute}</span>} />
          </div>
          <ClickToCopyC customView={<Button label={<i className={`far fa-${COPY}`} />} />} content={apiRoute} />
        </div>
      </div>
      )}
      {apiMethod && apiRoute && (
      <div className='flex row flex-grow items-flex-start fields'>
        <label className='label'>{API_METHOD}</label>
        <div className='value'>
          {apiMethod}
        </div>
      </div>
      )}
      {apiDocuments && (
      <div className='flex row flex-grow items-flex-start fields'>
        <label className='label'>{API_DOCS_LABEL}</label>
        <div className='value flex column'>
          { map(apiDocuments, ({ idx, label, link }) => {
            return (
              <a href={link} key={idx} className='external-link text-link' target='blank' rel='noreferrer'>
                {label}
                <i className={`fa fa-${EXTERNAL_LINK}`} />
              </a>
            )
          })}
        </div>
      </div>
      )}
      <div className='flex column flex-grow fields'>
        <label className='label'>{API_RESPONSE}</label>
        <APIResponse
          api={api}
          apiProps={apiProps}
          getKey={getKey}
        />
      </div>
    </GenericModal>
  )
}

ViewAPIResponseModal.propTypes = {
  api: PropTypes.func.isRequired,
  apiProps: PropTypes.object.isRequired,
  getKey: PropTypes.string,
  apiMethod: PropTypes.string,
  apiRoute: PropTypes.string,
  apiDocuments: PropTypes.array,
}

export default ViewAPIResponseModal
