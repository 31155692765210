import frontendCategorizedReportModel from './frontendCategorizedReportModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendCategorizedReportsModel = ({ data: categorizedReports }) => {
  const categorizedReportsModel = map(categorizedReports, (report) => frontendCategorizedReportModel({ data: report }))

  return keyBy(categorizedReportsModel, 'id')
}

export default frontendCategorizedReportsModel
