import MerchantDeposit from './MerchantDeposit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import { getTransferWithRelatedSelector } from 'state-layer/selectors'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import getMany from 'utilities/get/getMany'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import { FAILED } from 'constants/transferConstants'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  STATE,
  TRACE_ID,
  MERCHANT_DEPOSIT_RESOURCE_TITLE,
  DEPOSIT,
  CREATED_ON,
  PAYOUT_ACCOUNT,
  SETTLEMENT_RESOURCE_TITLE,
  DEPOSIT_TYPE,
  RETURNED_ON,
} from 'constants/language/languageConstants'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const transferId = get(props, 'params.transferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  const [
    traceId = '',
    currency = '',
    transferState = '',
    displayAmount = '',
    displayCreatedAt = '',
    paymentInstrument = {},
    tags,
    raw,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    settlement,
    displayType,
    device,
  ] = getMany(transfer, [
    'traceId',
    'currency',
    'state',
    'displayAmount',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
    'raw',
    'application.id',
    'application.businessName',
    'merchantIdentity.id',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'settlement',
    'displayType',
    'device',
  ])

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const showError = transferState === FAILED

  const transactionType = get(paymentInstrument, 'type')

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const [
    paymentInstrumentId,
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentBuyerName,
  ] = getMany(paymentInstrument, [
    'id',
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'name',
  ])

  const [
    settlementId,
  ] = getMany(settlement, [
    'id',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: SETTLEMENT_PATH({ credentialId, settlementId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: isStandaloneMerchant ? DEPOSIT : MERCHANT_DEPOSIT_RESOURCE_TITLE,
      id: transferId,
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={transferState} rawMessage={raw} />,
      },
      {
        label: DEPOSIT_TYPE,
        value: displayType,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: RETURNED_ON,
        value: get(raw, 'Return Date'),
        condition: showError,
      },
      {
        label: PAYOUT_ACCOUNT,
        value: displayBuyerPaymentInstrumentLink({
          type: transactionType,
          credentialId,
          paymentInstrument,
          paymentInstrumentId,
          maskedAccountNumber,
          maskedFullCardNumber,
          paymentInstrumentBuyerName,
        }),
        condition: !!paymentInstrumentId,
      },
    ],
  }

  return {
    isFetching,
    credentials,
    transfer,
    paymentInstrument,
    tagsSectionData,
    headerData,
    contextBarData,
    transferId,
    showError,
    device,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
  }
}

class MerchantDepositC extends Component {
  componentDidMount() {
    this.fetchTransfer()
  }

  componentDidUpdate(prevProps) {
    const { transferId } = this.props
    const { transferId: prevTransferId } = prevProps

    if (transferId && (transferId !== prevTransferId)) {
      this.fetchTransfer()
    }
  }

    fetchTransfer = () => {
      const {
        transferId,
        credentials,
        getTransfer,
      } = this.props

      getTransfer({ credentials, transferId })
    }

    render() {
      return (
        <MerchantDeposit
          {...this.props}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDepositC)
