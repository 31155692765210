import './FileUploaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import numeral from 'numeral'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import { UPLOAD_ICON } from 'constants/iconConstants'
import merge from 'lodash/merge'
import head from 'lodash/head'
import map from 'lodash/map'
import isString from 'lodash/isString'

import {
  DEFAULT_CONFIG,
  DEFAULT_INSTRUCTIONS,
} from 'constants/fileUploaderConstants'

/*
To-Do: refactor this component, so it can support other use cases
1) accept custom props, for extra file types and size
2) file upload action (api) need to be provided from form
3) the 'instruction' component need to be updated to reflect the config
*/
const FileUploader = ({
  input,
  label,
  meta: {
    error,
    warning,
  },
  config = {},
  instructions = {},
  fileIcon,
  helperText = '',
}) => {
  const { onChange, value } = input
  const onDrop = (acceptedFiles) => onChange(head(acceptedFiles))
  const onDropRejected = (rejectedFiles) => onChange(head(rejectedFiles))

  const uploaderConfig = merge({}, DEFAULT_CONFIG, config, { onDrop, onDropRejected })

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone(uploaderConfig)

  const files = map(acceptedFiles, ({ path, size }) => (
    <li key={path} className='item'>
      {path} - {numeral(size).format('0.00 b')}
    </li>
  ))

  const uploadInstructions = merge({}, DEFAULT_INSTRUCTIONS, instructions)
  const instructionsComp = map(uploadInstructions, ({ name, message }) => (
    <li key={name} className={classnames('item', { main: name === 'main' })}>
      {message}
    </li>
  ))

  return (
    <section className='FileUploader'>
      {label && <div className='label-2'>{label}</div>}
      { helperText && <div className='helper-text'>{helperText}</div> }

      <div {...getRootProps()} className='dropzone'>
        <input {...getInputProps()} />

        <ul className='instructions'>
          <li className='item'>

            <span className='icons fa-stack fa-2x'>
              <i className={`fal fa-${UPLOAD_ICON} fa-stack-1x uploadIcon`} />
              {fileIcon && <i className={`${fileIcon} fa-stack-2x fileIcon`} />}
            </span>
          </li>
          {instructionsComp}
        </ul>

        { value && !isString(value) && acceptedFiles && <ul className='files'>{files}</ul> }
      </div>

      { error && <WarningMessage warningMessage={error} /> }
    </section>
  )
}

FileUploader.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  config: PropTypes.object,
  instructions: PropTypes.object,
  fileIcon: PropTypes.string,
  helperText: PropTypes.string,
}

export default FileUploader
