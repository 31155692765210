import React, { Component } from 'react'
import { connect } from 'react-redux'
import AssociatedIdentityNew from './AssociatedIdentityNew'
import { getIdentitySelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitAssociatedIdentityForm from 'utilities/submit/submitAssociatedIdentityForm'
import get from 'lodash/get'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)

  return {
    credentials,
    identityId,
    identity,
    onSubmit: submitAssociatedIdentityForm,
    submitLabel: 'Create',
  }
}

class AssociatedIdentityNewC extends Component {
  render() {
    return (
      <AssociatedIdentityNew {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(AssociatedIdentityNewC)
