import frontendLevelThreeItemDataModel from './frontendLevelThreeItemDataModel'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  NO,
  YES,
  VOIDED,
} from 'constants/language/languageConstants'

import {
  CAPTURED,
  NOT_CAPTURED,
} from 'constants/capturedConstants'

const frontendAuthorizationModel = ({ data: authorization }) => {
  const [
    id,
    merchant, // TODO: missing from OL
    buyer, // TODO: missing from OL
    captured, // TODO: missing from OL
    state,
    createdAt,
    amount,
    currency,
    expiresAt,
    transferId,
    isVoid,
    traceId,
    tags,
    applicationId,
    merchantIdentityId,
    sourceId,
    destinationId,
    deviceId,
    paymentInstrument,
    raw,
    messages,
    application,
    merchantIdentity,
    buyerIdentity,
    transfer,
    failureCode,
    failureMessage,
    device,
    voidState,
    additionalPurchaseData,
    tipAmount,
    clinicAmount,
    dentalAmount,
    prescriptionAmount,
    visionAmount,
    convenienceAmount,
    rentSurchargeAmount,
    surchargeAmount,
    receipts,
    fees,
  ] = getMany(authorization, [
    'id',
    'merchant', // TODO: missing from OL
    'buyer', // TODO: missing from OL
    'captured', // TODO: missing from OL
    'state',
    'created_at',
    'amount',
    'currency',
    'expires_at',
    'transfer',
    'is_void',
    'trace_id',
    'tags',
    'application',
    'merchant_identity',
    'source',
    'destination',
    'device',
    'paymentInstrument',
    'raw',
    'messages',
    'applicationData',
    'merchantIdentity',
    'buyerIdentity',
    'transferData',
    'failure_code',
    'failure_message',
    'device',
    'void_state',
    'additional_purchase_data',
    'tip_amount',
    'additional_healthcare_data.clinic_amount',
    'additional_healthcare_data.dental_amount',
    'additional_healthcare_data.prescription_amount',
    'additional_healthcare_data.vision_amount',
    'additional_buyer_charges.convenience_amount',
    'additional_buyer_charges.rent_surcharge_amount',
    'additional_buyer_charges.surcharge_amount',
    'receipts',
    'fees',
  ])

  const [
    customerReferenceNumber,
    salesTax,
    customsDutyAmount,
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    discountAmount,
    itemData,
  ] = getMany(additionalPurchaseData, [
    'customer_reference_number',
    'sales_tax',
    'customs_duty_amount',
    'destination_postal_code',
    'ship_from_postal_code',
    'shipping_amount',
    'discount_amount',
    'item_data',
  ])

  const normalizedItemData = map(itemData, (item) => frontendLevelThreeItemDataModel({ data: item, currency }))

  const normalizedAdditionalPurchaseData = additionalPurchaseData ? removeUndefined({
    customerReferenceNumber,
    salesTax,
    displaySalesTax: formatMoney({ amount: salesTax }),
    customsDutyAmount,
    displayCustomsDutyAmount: formatMoney({ amount: customsDutyAmount, currency, showCurrencyCode: true }),
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    displayShippingAmount: formatMoney({ amount: shippingAmount, currency, showCurrencyCode: true }),
    discountAmount,
    displayDiscountAmount: formatMoney({ amount: discountAmount, currency, showCurrencyCode: true }),
    itemData: !isEmpty(normalizedItemData) ? normalizedItemData : undefined,
  }) : undefined

  return removeUndefined({
    id,
    merchant,
    buyer,
    captured,
    state,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    amount,
    currency,
    expiresAt,
    displayExpirationDate: formatDate({ date: expiresAt }),
    isExpired: new Date() > new Date(expiresAt),
    transfer,
    transferId,
    isVoid: isVoid ? YES : NO,
    traceId,
    displayCaptured: isVoid ? VOIDED : transferId ? CAPTURED : NOT_CAPTURED,
    displayAmount: formatMoney({ amount, currency }),
    displayAmountWithCurrencyCode: formatMoney({ amount, currency, showCurrencyCode: true }),
    tags,
    applicationId,
    merchantIdentityId,
    paymentInstrumentId: sourceId || destinationId || deviceId,
    paymentInstrument,
    raw,
    messages,
    application,
    merchantIdentity,
    buyerIdentity,
    failureCode,
    failureMessage,
    displayFailureCode: convertSnakeToSentenceCase(failureCode),
    deviceId,
    device,
    voidState,
    additionalPurchaseData: normalizedAdditionalPurchaseData,
    isLevelTwoEnabled: !!(customerReferenceNumber && salesTax),
    isLevelThreeEnabled: !isEmpty(itemData),
    tipAmount,
    displayTipAmount: formatMoney({ amount: tipAmount, currency }),
    surchargeAmount,
    displaySurchargeAmount: formatMoney({ amount: surchargeAmount, currency }),
    convenienceAmount,
    displayConvenienceAmount: formatMoney({ amount: convenienceAmount, currency }),
    rentSurchargeAmount,
    displayRentSurchargeAmount: formatMoney({ amount: rentSurchargeAmount, currency }),
    clinicAmount,
    displayClinicAmount: formatMoney({ amount: clinicAmount, currency }),
    dentalAmount,
    displayDentalAmount: formatMoney({ amount: dentalAmount, currency }),
    prescriptionAmount,
    displayPrescriptionAmount: formatMoney({ amount: prescriptionAmount, currency }),
    visionAmount,
    displayVisionAmount: formatMoney({ amount: visionAmount, currency }),
    receipts,
    fees,
  })
}

export default frontendAuthorizationModel
