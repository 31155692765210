import paymentsAPI from 'utilities/api/paymentsAPI'
import { APPLICATIONS } from 'constants/apiConstants'

const getApplicationsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: APPLICATIONS,
    queries,
    credentials,
    service: APPLICATIONS,
  })
}

export default getApplicationsAPI
