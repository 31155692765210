import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISBURSEMENT_RULES } from 'constants/apiConstants'

const getDisbursementRulesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: DISBURSEMENT_RULES,
    queries,
    credentials,
    service: DISBURSEMENT_RULES,
  })
}

export default getDisbursementRulesAPI
