import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  DEVICE,
  DEVICES,
} from 'constants/apiConstants'

const patchDeviceAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    credentials,
    path: DEVICE({ deviceId: id }),
    values,
    service: DEVICES,
  })
}

export default patchDeviceAPI
