import React, { Component } from 'react'
import { connect } from 'react-redux'
import RecipientVelocityLimits from './RecipientVelocityLimits'
import { getDisbursementRulesSelector, getRecipientCurrentUsagesByLinkedTo } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import find from 'lodash/find'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const merchantId = get(props, 'merchantId')
  const currentUsages = getRecipientCurrentUsagesByLinkedTo(state, merchantId)
  const disbursementRules = getDisbursementRulesSelector(state)

  const [
    recipientACHRules,
    recipientCardRules,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  ] = getMany(disbursementRules, [
    'recipientACHRules',
    'recipientCardRules',
    'recipientACHRulesMaxTransactionAmount',
    'recipientCardRulesMaxTransactionAmount',
  ])

  const [
    recipientDailyACHCurrentCount,
    recipientMonthlyACHCurrentCount,
    recipientDailyACHCurrentVolume,
    recipientMonthlyACHCurrentVolume,
    recipientDailyCardCurrentCount,
    recipientMonthlyCardCurrentCount,
    recipientDailyCardCurrentVolume,
    recipientMonthlyCardCurrentVolume,
  ] = getMany(currentUsages, [
    'achDailyVelocityCurrentCount',
    'achMonthlyVelocityCurrentCount',
    'displayAchDailyVelocityCurrentVolume',
    'displayAchMonthlyVelocityCurrentVolume',
    'cardDailyVelocityCurrentCount',
    'cardMonthlyVelocityCurrentCount',
    'displayCardDailyVelocityCurrentVolume',
    'displayCardMonthlyVelocityCurrentVolume',
  ])

  // TODO: create a utility to merge current usages and velocity limit models
  const recipientDailyCardRules = {
    ...find(recipientCardRules, { timeFrame: 'DAILY' }),
    count: recipientDailyCardCurrentCount,
    volume: recipientDailyCardCurrentVolume,
  }
  const recipientMonthlyCardRules = {
    ...find(recipientCardRules, { timeFrame: 'MONTHLY' }),
    count: recipientMonthlyCardCurrentCount,
    volume: recipientMonthlyCardCurrentVolume,
  }
  const recipientDailyACHRules = {
    ...find(recipientACHRules, { timeFrame: 'DAILY' }),
    count: recipientDailyACHCurrentCount,
    volume: recipientDailyACHCurrentVolume,
  }
  const recipientMonthlyACHRules = {
    ...find(recipientACHRules, { timeFrame: 'MONTHLY' }),
    count: recipientMonthlyACHCurrentCount,
    volume: recipientMonthlyACHCurrentVolume,
  }

  return {
    credentials,
    recipientACHRules: [recipientDailyACHRules, recipientMonthlyACHRules],
    recipientCardRules: [recipientDailyCardRules, recipientMonthlyCardRules],
    isFetching: get(state, `currentUsagesR.${FETCHING}`),
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  }
}

class RecipientVelocityLimitsC extends Component {
  render() {
    return (
      <RecipientVelocityLimits
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(RecipientVelocityLimitsC)
