/* eslint-disable no-underscore-dangle */
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from 'constants/regexConstants'
import every from 'lodash/every'
import size from 'lodash/size'
import filter from 'lodash/filter'

import {
  AT_LEAST_ONE_SEND_METHOD,
  INVALID_EMAIL,
  INVALID_EMAIL_LIST,
  INVALID_PHONE_NUMBERS_LIST,
  INVALID_PHONE_NUMBER,
  NO_DEVICE_SELECTED,
} from 'constants/language/languageConstants'

const validateEmailList = (emails) => {
  if (!Array.isArray(emails) || size(emails) === 0) {
    return INVALID_EMAIL_LIST
  }

  const allValid = every(emails, ({ value: email }) => email && EMAIL_REGEX.test(email))
  if (!allValid) {
    return INVALID_EMAIL
  }

  return false
}

const validatePhoneNumbers = (phoneNumbers) => {
  if (!phoneNumbers || size(phoneNumbers) === 0) {
    return INVALID_PHONE_NUMBERS_LIST
  }
  const invalidPhones = filter(phoneNumbers, phone => !PHONE_NUMBER_REGEX.test(phone.value))
  if (size(invalidPhones) > 0) {
    return INVALID_PHONE_NUMBER
  }

  return false
}

const validateSendReceiptForm = (values) => {
  const { sendMethodEmail, sendMethodSMS, sendMethodPrint, emails, phoneNumbers, device } = values

  let _error
  let emailsError
  let phoneNumbersError
  let devicesError

  if (!sendMethodEmail && !sendMethodSMS && !sendMethodPrint) {
    _error = AT_LEAST_ONE_SEND_METHOD
  }

  if (sendMethodEmail) {
    emailsError = validateEmailList(emails)
  }

  if (sendMethodSMS) {
    phoneNumbersError = validatePhoneNumbers(phoneNumbers)
  }

  if (sendMethodPrint) {
    if (!device) {
      devicesError = NO_DEVICE_SELECTED
    }
  }

  const errors = {
    emails: emailsError ? emailsError : undefined,
    phoneNumbers: phoneNumbersError ? phoneNumbersError : undefined,
    device: devicesError ? devicesError : undefined,
    _error: _error ? _error : undefined,
  }

  return errors
}

export default validateSendReceiptForm
