import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateNewRunC from 'components/Customer/Pages/UnderwritingV2/CreateNewRun/CreateNewRunC'
import { CREATE_NEW_RUN } from 'constants/language/languageConstants'

const CreateNewRunModal = ({
  reviewId = '',
  subjectId = '',
  entityId = '',
}) => {
  return (
    <GenericModal
      title={CREATE_NEW_RUN}
      className='CreateNewRunModal'
      Component={CreateNewRunC}
      reviewId={reviewId}
      subjectId={subjectId}
      entityId={entityId}
    />
  )
}

CreateNewRunModal.propTypes = {
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  entityId: PropTypes.string,
}

export default CreateNewRunModal
