import getApplicationFeeProfileAPI from 'api/dashboard/get/getApplicationFeeProfileAPI'
import getMerchantFeeProfileAPI from 'api/dashboard/get/getMerchantFeeProfileAPI'
import getPlatformFeeProfileAPI from 'api/finix/get/getPlatformFeeProfileAPI'
import createErrorResponse from 'utilities/api/createErrorResponse'
import frontendFeeProfileModel from 'utilities/create/models/frontendFeeProfileModel'
import get from 'lodash/get'
import co from 'co'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'

const entityTypeToApiMap = {
  PLATFORM: getPlatformFeeProfileAPI,
  APPLICATION: getApplicationFeeProfileAPI,
  MERCHANT: getMerchantFeeProfileAPI,
}

function * getFeeProfileO ({
  values = {},
  credentials,
}) {
  const {
    entityId,
    entityType,
  } = values

  if (!entityId || !entityType) {
    return createErrorResponse({ error: 'entityId or entityType not supplied' })
  }

  const api = get(entityTypeToApiMap, entityType)

  if (!api) {
    return createErrorResponse({ error: 'entityId or entityType not supplied' })
  }

  const { data: response } = yield api({
    credentials,
    id: entityId,
  })

  const [
    feeProfile = {},
    feeProfileInheritance = {},
    cardPresentFeeProfile = {},
    cardPresentFeeProfileInheritance = {},
  ] = getMany(response, [
    'fee_profile',
    'fee_profile_inheritance',
    'card_present_fee_profile',
    'card_present_fee_profile_inheritance',
  ])

  // API response for platform fee profile differs from the others, so fetch the correct data to pass to the model
  const feeProfileResponseData = entityType === 'PLATFORM' ? response : { ...feeProfile, ...feeProfileInheritance }
  const feeProfileNormalizedValues = normalizeResponse({ content: feeProfileResponseData })
  const feeProfileModel = frontendFeeProfileModel({ data: feeProfileNormalizedValues })

  const cardPresentFeeProfileResponseData = { ...cardPresentFeeProfile, ...cardPresentFeeProfileInheritance }
  const cardPresentFeeProfileNormalizedValues = normalizeResponse({ content: cardPresentFeeProfileResponseData })
  const cardPresentFeeProfileModel = frontendFeeProfileModel({ data: cardPresentFeeProfileNormalizedValues })

  return createSuccessResponse({
    data: {
      [entityId]: {
        feeProfile: isEmpty(feeProfileModel) ? undefined : feeProfileModel,
        cardPresentFeeProfile: (isEmpty(cardPresentFeeProfile) || entityType === 'PLATFORM') ? undefined : cardPresentFeeProfileModel,
      },
    },
  })
}

export default co.wrap(getFeeProfileO)
