import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlatformInfo from './PlatformInfo'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getPlatformSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import { settlementFundingIdentifierOptionsMapping } from 'constants/platformConstants'
import { UPDATED_AT_LABEL } from 'constants/language/languageConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `platformsR.${FETCHING}`)
  const platformId = get(props, 'platformId')
  const platform = getPlatformSelector(state, platformId)

  const {
    name,
    billingPrefix,
    feeProfileId,
    displayUpdatedAt,
    settlementFundingIdentifier,
  } = platform

  const detailsSectionData = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Billing Prefix',
      value: billingPrefix,
    },
    {
      label: 'Fee Profile ID',
      value: feeProfileId,
    },
    {
      label: UPDATED_AT_LABEL,
      value: displayUpdatedAt,
    },
    {
      label: 'Deposit Statement Descriptor',
      value: settlementFundingIdentifierOptionsMapping[settlementFundingIdentifier],
    },
  ]

  return {
    isFetching,
    platformId,
    platform,
    credentials,
    detailsSectionData,
  }
}

class PlatformInfoC extends Component {
  render() {
    return (
      <PlatformInfo {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PlatformInfoC)
