import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Payments/columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { PAYMENT_DEVICE_TRANSACTIONS_EMPTY_MESSAGE } from 'constants/language/languageConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

const DevicePayments = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  paymentPath = '',
  isFetching = false,
  deviceId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='DevicePayments'>
      <TableC
        flow={GET_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={`Device_${deviceId}_Transfers`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        quickFilters={quickFilters}
        emptyMessage={PAYMENT_DEVICE_TRANSACTIONS_EMPTY_MESSAGE}
        entityType={TRANSFER}
        showAdvancedExport
      />
    </div>
  )
}

DevicePayments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  deviceId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default DevicePayments
