import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { APPLE_PAY } from 'constants/paymentMethodConstants'
import { POST_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST } from 'constants/flowConstants'
import { REGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAIN } from 'constants/amplitudeConstants'

const submitAddDomainForm = (values, dispatch) => {
  const { websiteDomain, identityId, credentials } = values

  const domains = [
    {
      name: websiteDomain,
      enabled: true,
    },
  ]

  const valuesToSubmit = removeUndefined({
    type: APPLE_PAY,
    merchant_identity: identityId,
    domains,
  })

  dispatch({
    type: POST_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(REGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAIN, {
        credentials,
        valuesToSubmit,
      }),
    },
  })
}

export default submitAddDomainForm
