import './DashboardsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getCredentialsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { CREDENTIALS } from 'constants/linkConstants'
import { GET_CREDENTIALS } from 'constants/flowConstants'
import { getPathByCredential } from 'utilities/validate/checkCredentialFeatures'

import {
  ACCOUNTS_EMPTY_TABLE_MESSAGE,
  DASHBOARDS,
} from 'constants/language/languageConstants'

const Dashboards = ({
  isFetching = false,
  flowValues = {},
  initialQueries = {},
  quickFilters,
}) => {
  return (
    <div className='Dashboards'>
      <HeaderV2C
        title={DASHBOARDS}
      />

      <TableC
        flow={GET_CREDENTIALS}
        flowValues={flowValues}
        selector={getCredentialsSelector}
        columnDescriptors={columnDescriptors}
        linksKey={CREDENTIALS}
        path={getPathByCredential}
        isFetching={isFetching}
        initialQueries={initialQueries}
        openNewTab={false}
        showLimitCount={false}
        emptyMessage={ACCOUNTS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

Dashboards.propTypes = {
  isFetching: PropTypes.bool,
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  quickFilters: PropTypes.array,
}

export default Dashboards
