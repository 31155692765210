import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import Disbursements from './Disbursements'
import DropdownButton from 'components/Customer/Shared/DropdownButton/DropdownButton'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isDisbursementsAndPaymentsDashboard } from 'utilities/is/isDashboardType'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREDIT } from 'constants/transferConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  CREATE_PAYOUT_LINK_PATH,
  DISBURSEMENTS_PATH,
  SEND_PAYOUT_PATH,
} from 'constants/pathConstants'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  PAYOUT_RESOURCE_TITLE,
  SEND_MONEY_DIRECTLY, SEND_PAYOUT,
  SHARE_PAYOUT_LINK,
} from 'constants/language/languageConstants'

import {
  TRACE_ID,
  AMOUNT,
  TRANSFER_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(PAYOUT_RESOURCE_TITLE),
  AMOUNT(),
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  TRANSFER_TYPE,
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isDisbursementsAndPayments = isDisbursementsAndPaymentsDashboard(state) || get(props, 'isDisbursementsAndPaymentsApplication', false)
  const currentUser = getCurrentUser(state)
  const isFetching = get(state, `applicationTransfersR.${FETCHING}`)
  const applicationId = get(props, 'applicationId') || get(currentUser, 'applicationId')
  const disbursementsPath = DISBURSEMENTS_PATH({ credentialId })
  const hideHeader = get(props, 'hideHeader')
  const disbursementsFileNameCSV = `Application_${applicationId}_Payouts`

  const flowValues = {
    applicationId,
  }

  const initialQueries = removeUndefined({
    application_id: applicationId,
    offset: 0,
    type: isDisbursementsAndPayments ? CREDIT : undefined,
  })

  return {
    applicationId,
    disbursementsPath,
    flowValues,
    initialQueries,
    allowedFilters,
    quickFilters,
    isFetching,
    initialFilters: createdLast3MonthQueries(),
    hideHeader,
    disbursementsFileNameCSV,
    credentialId,
  }
}

class DisbursementsC extends Component {
  render() {
    const { credentialId } = this.props

    const sendPayoutActions = [
      {
        label: SEND_MONEY_DIRECTLY,
        className: 'send-payout',
        action: () => goToPath({
          pathname: SEND_PAYOUT_PATH({ credentialId }),
        }),
      },
      {
        label: SHARE_PAYOUT_LINK,
        className: 'share-payout-link',
        action: () => goToPath({
          pathname: CREATE_PAYOUT_LINK_PATH({ credentialId }),
        }),
      },
    ]

    const actions = [
      {
        Component: (
          <DropdownButton
            label={SEND_PAYOUT}
            actions={sendPayoutActions}
          />
        ),
      },
    ]

    return (
      <Disbursements
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps)(DisbursementsC)
