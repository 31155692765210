import { SELF_SERVICE_PASSWORD_REGEX } from 'constants/regexConstants'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import {
  fieldEmpty,
  validateSelfServicePassword,
} from 'utilities/validate'

import {
  OLD_PASSWORD,
  NEW_PASSWORD,
  PASSWORDS_CANNOT_MATCH,
} from 'constants/language/languageConstants'

const validateChangePasswordForm = (values) => {
  const {
    oldPassword,
    password = '',
  } = values

  const matchingPasswords = !isEmpty(oldPassword) && isEqual(oldPassword, password) && PASSWORDS_CANNOT_MATCH

  return {
    oldPassword: fieldEmpty(oldPassword, OLD_PASSWORD) || matchingPasswords,
    password: matchingPasswords || fieldEmpty(password, NEW_PASSWORD) || validateSelfServicePassword({
      field: password,
      name: 'Password',
      regex: SELF_SERVICE_PASSWORD_REGEX,
      noMessages: true,
    }),
  }
}

export default validateChangePasswordForm
