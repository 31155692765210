import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import redirectRequest from 'utilities/actions/redirectRequest'
import { PAYMENT_INSTRUMENT_PATH } from 'constants/pathConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import omit from 'lodash/omit'
import head from 'lodash/head'
import keys from 'lodash/keys'

import {
  CREATE_IDENTITY_PAYMENT_INSTRUMENT,
  CREATE_IDENTITY_PAYMENT_INSTRUMENT_MAPPING,
} from 'constants/amplitudeConstants'

import {
  POST_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST,
  PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST,
} from 'constants/flowConstants'

const submitAddIdentityBankAccountForm = (values, dispatch, props) => {
  const {
    name,
    accountType,
    settlementAlias,
  } = values

  const {
    credentials,
    paymentInstrumentType,
    identityId,
    successFunction,
    merchantId,
    identityCountry,
    isDisbursementsDashboard,
  } = props

  const valuesToSubmit = merge({}, omit(values, 'settlementAlias', 'confirmAccountNumber'), { type: paymentInstrumentType, country: identityCountry })

  dispatch({
    type: POST_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      merchantId,
      selectorId: get(valuesToSubmit, 'identity'),
      actionId: sendAmplitudeActionEvent(CREATE_IDENTITY_PAYMENT_INSTRUMENT, {
        credentials,
        name,
        paymentInstrumentType,
        identityId,
        accountType,
        country: identityCountry,
      }),
      successCallback: ({ newValues }) => {
        const paymentInstrumentId = head(keys(newValues))
        const newName = get(newValues, `${paymentInstrumentId}.name`)
        const newMaskedAccountNumber = get(newValues, `${paymentInstrumentId}.maskedAccountNumber`)
        if (successFunction) successFunction(paymentInstrumentId, newName, newMaskedAccountNumber)

        if (isDisbursementsDashboard) {
          const credentialId = get(credentials, 'id')
          const path = PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId })
          dispatch(redirectRequest({ path }))
        }

        const payoutPlanInstrumentMapping = {
          payout_plan_instrument_mapping_list: [
            {
              payment_instrument: paymentInstrumentId,
              settlement_alias: settlementAlias,
            },
          ],
        }

        if (settlementAlias) {
          setTimeout(() => {
            dispatch({
              type: PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST,
              payload: {
                values: payoutPlanInstrumentMapping,
                credentials,
                id: identityId,
              },
              meta: {
                actionId: sendAmplitudeActionEvent(CREATE_IDENTITY_PAYMENT_INSTRUMENT_MAPPING, {
                  credentials,
                  identityId,
                  payoutPlanInstrumentMapping,
                }),
              },
            })

            dispatch(getIdentityRequest({ identityId, credentials }))
          }, 3000) // needed for BE to create default mapping before patching
        }
      },
    },
  })
}

export default submitAddIdentityBankAccountForm
