import './CTABannerS.scss'
import React from 'react'
import { t } from 'constants/language/languageConstants'
import PropTypes from 'prop-types'

const CTABanner = ({
  status,
  title,
  subTitle,
  action = () => {},
  actionLabel,
}) => {
  return (
    <div className={`CTABanner flex items-center space-between ${status}`}>
      <div className='flex'>
        {title && (<div className='p-2-bold title'>{t(title)}</div>)}
        {subTitle && (<div>{t(subTitle)}</div>)}
      </div>
      {actionLabel && (<a className='text-link' onClick={action}>{t(actionLabel)}</a>)}
    </div>
  )
}

CTABanner.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
}

export default CTABanner
