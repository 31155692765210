import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationIdentities from './ApplicationIdentities'
import { goToPath } from 'state-layer/history'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { isFlexPlatform } from 'constants/flexConstants'
import { ADD_ICON } from 'constants/iconConstants'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATION_IDENTITIES } from 'constants/flowConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  CREATE_NEW_IDENTITY,
} from 'constants/language/languageConstants'

import {
  IDENTITIES_PATH,
  NEW_IDENTITY_PATH,
  NEW_APPLICATION_IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  BUSINESS_TYPE,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  TITLE,
  TAG_FILTERS,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  BUSINESS_NAME,
  BUSINESS_TYPE,
  DOING_BUSINESS_AS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  convertFilterToResourceIdFilter('Identity'),
  TITLE,
  ...TAG_FILTERS,
], 'label')

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const credentialId = getCurrentCredentialsId(state)
  const isFetching = get(state, `applicationIdentitiesR.${FETCHING}`)
  const identityPath = IDENTITIES_PATH({ credentialId })
  const isFlex = isFlexPlatform()

  const flowValues = {
    applicationId,
  }

  const initialQueries = {
    application_id: applicationId,
  }

  const actions = [
    {
      label: CREATE_NEW_IDENTITY,
      leftButtonIcon: ADD_ICON,
      action: () => goToPath({
        pathname: NEW_IDENTITY_PATH({ credentialId, applicationId }),
        queries: {
          recordId: applicationId,
          type: APPLICATION_IDENTITIES,
        },
      }),
      className: 'create-new-identity-button',
      condition: !isFlex,
    },
    {
      label: CREATE_NEW_IDENTITY,
      leftButtonIcon: ADD_ICON,
      action: () => goToPath({
        pathname: NEW_APPLICATION_IDENTITY_PATH({ credentialId, applicationId }),
      }),
      className: 'create-new-identity-button',
      condition: isFlex,
    },
  ]

  return {
    applicationId,
    flowValues,
    isFetching,
    actions,
    identityPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: createdLastMonthQueries(),
  }
}

class ApplicationIdentitiesC extends Component {
  render() {
    return (
      <ApplicationIdentities {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationIdentitiesC)
