import co from 'co'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendSettlementEntryModel from 'utilities/create/models/frontendSettlementEntryModel'
import getFeeAPI from 'api/finix/get/getFeeAPI'
import getTransferAPI from 'api/finix/get/getTransferAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getSettlementsAPI from 'api/finix/get/getSettlementsAPI'
import getSettlementEntriesAPI from 'api/finix/get/getSettlementEntriesAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import get from 'lodash/get'
import merge from 'lodash/merge'

function * getFeeO ({
  id,
  credentials,
}) {
  const { data: response } = yield getFeeAPI({ id, credentials })
  const merchantId = get(response, 'merchant')
  const { data: merchant } = yield getMerchantAPI({ id: merchantId, credentials })

  const { data: settlementsResponse } = yield getSettlementsAPI({ credentials,
    queries: { entry_id: id },
  })

  const settlement = get(settlementsResponse, '_embedded.settlements.0')
  const settlementId = get(settlement, 'id')

  let settlementEntriesResponse
  let settlementEntry

  if (settlementId) {
    settlementEntriesResponse = yield getSettlementEntriesAPI({ credentials,
      id: settlementId,
      queries: { entry_id: id },
    })

    settlementEntry = frontendSettlementEntryModel({ data: get(settlementEntriesResponse, 'data._embedded.settlement_entries.0') })
  }

  const linkedTo = get(response, 'linked_id')
  const linkedType = get(response, 'linked_type')
  const applicationId = get(merchant, 'application')
  const identityId = get(merchant, 'identity')

  const { data: transfer } = (linkedType === 'TRANSFER' && linkedTo) ? yield getTransferAPI({
    credentials,
    id: linkedTo,
  }) : {}

  const { data: application } = applicationId ? yield getApplicationAPI({
    credentials,
    id: applicationId,
  }) : {}

  const { data: identity } = identityId ? yield getIdentityAPI({
    credentials,
    id: identityId,
  }) : {}

  const fee = merge({}, response, {
    settlement,
    settlementId,
    settlementEntry,
    related_merchant: merchant ? frontendMerchantModel({ data: merchant }) : {},
    transfer,
    application,
    identity,
  })

  return createSuccessResponse({
    data: [fee],
  })
}

export default co.wrap(getFeeO)
