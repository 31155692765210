import './PlatformProcessorConfigsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { PLATFORM_PROCESSOR_CONFIGS } from 'constants/linkConstants'
import { GET_PLATFORM_PROCESSOR_CONFIGS } from 'constants/flowConstants'
import { PLATFORMS_ICON } from 'constants/iconConstants'
import { getPlatformProcessorConfigsSelector } from 'state-layer/selectors'

const PlatformProcessorConfigs = ({
  platformProcessorConfigsPath = '',
  initialQueries = {},
  flowValues = {},
  isFetching = false,
}) => {
  return (
    <div className='PlatformProcessorConfigs'>
      <TableC
        flow={GET_PLATFORM_PROCESSOR_CONFIGS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getPlatformProcessorConfigsSelector}
        initialQueries={initialQueries}
        linksKey={PLATFORM_PROCESSOR_CONFIGS}
        path={platformProcessorConfigsPath}
        isFetching={isFetching}
        emptyMessage='No Processor Configurations Available'
        icon={`fa fa-${PLATFORMS_ICON}`}
      />
    </div>
  )
}

PlatformProcessorConfigs.propTypes = {
  platformProcessorConfigsPath: PropTypes.string,
  initialQueries: PropTypes.object,
  flowValues: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default PlatformProcessorConfigs
