import getCurrentAuthSession from 'utilities/get/getCurrentAuthSession'
import getMany from 'utilities/get/getMany'

const getGuestDashboardAxiosConfigs = () => {
  const authSession = getCurrentAuthSession()

  const [
    idToken,
    tokenType,
  ] = getMany(authSession, [
    'idToken',
    'tokenType',
  ])

  const Authorization = `${tokenType} ${idToken}`

  const headers = { Authorization }

  return { headers }
}

export default getGuestDashboardAxiosConfigs
