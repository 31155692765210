import React, { Component } from 'react'
import { connect } from 'react-redux'
import Files from './Files'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { IDENTITY_UPLOADER_ICON } from 'constants/iconConstants'
import { UPLOAD_FILE } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  FILE_UPLOAD_MODAL,
  FILE_VIEWER_MODAL,
} from 'constants/modalConstants'

const allowedFilters = []

const mapStateToProps = (state, props) => {
  const uploadForIdentity = get(props, 'uploadForIdentity', false)
  const credentials = getCurrentCredentials(state)
  const isFetching = uploadForIdentity ? get(state, `identityFilesR.${FETCHING}`) : (get(state, `filesR.${FETCHING}`) || get(state, `merchantFilesR.${FETCHING}`))

  return {
    allowedFilters,
    credentials,
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
    showUploadFileModal: (modalProps) => dispatch(showModalAction({ modalType: FILE_UPLOAD_MODAL, modalProps })),
  }
}

class FilesC extends Component {
  render() {
    const {
      credentials,
      merchantId,
      showUploadFileModal,
      showUploadButton,
      merchant,
      identityId,
      uploadForIdentity,
      identityMerchants,
    } = this.props

    const actions = []

    if (showUploadButton) {
      actions.push(
        {
          label: <div><i className={`fa fa-${IDENTITY_UPLOADER_ICON}`} />{UPLOAD_FILE}</div>,
          action: () => {
            showUploadFileModal({
              credentials,
              merchantId,
              merchant,
              identityId,
              uploadForIdentity,
              identityMerchants,
            })
          },
          className: 'upload-file-button',
          buttonVariant: 'secondary',
        },
      )
    }

    return (
      <Files
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesC)
