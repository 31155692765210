import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SEND_PLAID_PUBLIC_TOKEN } from 'constants/amplitudeConstants'
import { POST_PLAID_PUBLIC_TOKEN_F_REQUEST } from 'constants/flowConstants'

// this request send the public token to the server after successfully linking a bank account
const postPlaidPublicTokenRequest = ({
  id,
  plaidPublicToken,
  accountId,
  tokenType,
  overwriteReducer = true,
  entityType,
  credentials,
}) => createAction({
  type: POST_PLAID_PUBLIC_TOKEN_F_REQUEST,
  id,
  credentials: ['ONBOARDING_FORM', 'ACCESS_FORM'].includes(entityType) ? null : credentials,
  values: {
    accountId,
    plaidPublicToken,
    type: tokenType,
    actionType: POST_PLAID_PUBLIC_TOKEN_F_REQUEST,
    entityType,
  },
  meta: {
    actionId: sendAmplitudeActionEvent(SEND_PLAID_PUBLIC_TOKEN, {
      plaidPublicToken,
      tokenType,
      accountId,
    }),
    overwriteReducer,
  },
})

export default postPlaidPublicTokenRequest
