import './TemplatesFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import validateTemplatesForm from 'utilities/validate/validateTemplatesForm'
import map from 'lodash/map'
import size from 'lodash/size'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import omit from 'lodash/omit'
import capitalize from 'lodash/capitalize'
import { TEMPLATES_FORM } from 'constants/formConstants'
import { ADD_TEMPLATE } from 'constants/language/languageConstants'

import {
  templatesConfigLabels,
  templatesConfigOptionsMap,
  ALL,
  EXCLUDES,
  INCLUDES,
} from 'constants/payoutPlanConstants'

const renderTemplates = ({
  fields,
  templatesConfig,
  meta: {
    error: errors = [],
    submitFailed,
  },
}) => (
  <ul className='templates'>
    <li className='flex center'>
      <Button onClick={() => fields.push({})} label={ADD_TEMPLATE} />
    </li>

    {fields.map((template, index) => (
      <li className='template'>
        <div className='header flex space-between'>
          <h3>Template #{index + 1}</h3>

          <span
            className='removeTemplate fa fa-times'
            onClick={() => fields.remove(index)}
          />
        </div>

        <div className='contents'>
          <Field
            name={`${template}.name`}
            type='text'
            component={InputField}
            label='Name'
          />

          {
            map(templatesConfigLabels, ({ label: templateConfigLabel, value: templateConfigValue }) => {
              const templatesConfigOptions = get(templatesConfigOptionsMap, templateConfigValue)

              return (
                <div className='templateConfig'>
                  <h3>{templateConfigLabel}</h3>

                  <div className='flex'>
                    {
                      map(templatesConfigOptions, ({ includesExcludes, templateConfigOptions }) => {
                        return (
                          <div className='templatesConfigOptions'>
                            <h3>{capitalize(includesExcludes)}</h3>

                            { map(templateConfigOptions, ({ label: optionLabel, value: optionValue }) => {
                              // handles logic for disabling template config options
                              let disabled
                              const includes = get(templatesConfig, `${index}.${templateConfigValue}.${INCLUDES}`)
                              const excludes = get(templatesConfig, `${index}.${templateConfigValue}.${EXCLUDES}`)

                              if (includesExcludes === EXCLUDES) {
                                const includesAll = get(includes, ALL)
                                const excludesOption = get(excludes, optionValue)
                                const excludesTotalCount = size(templateConfigOptions)
                                const excludesCount = reduce(excludes, (sum, value) => sum + (value ? 1 : 0), 0)
                                const disableExcludes = !excludesOption && excludesCount + 1 === excludesTotalCount ? true : false

                                disabled = !includesAll || disableExcludes ? true : false
                              } else {
                                const includesNoAll = omit(includes, ALL)
                                const disableIncludes = optionValue === ALL ? (!isEmpty(pickBy(excludes)) || !isEmpty(pickBy(includesNoAll))) : get(includes, ALL)

                                disabled = disableIncludes ? true : false
                              }

                              const name = `${template}.config.${templateConfigValue}.${includesExcludes}.${optionValue}`

                              return (
                                <div className='templateConfigOption'>
                                  <ToggleSwitchC
                                    name={name}
                                    label={optionLabel}
                                    className='flex-grow flex space-between'
                                    disabled={disabled}
                                    form={TEMPLATES_FORM}
                                  />
                                </div>
                              )
                            }) }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </li>
    ))}

    {!isEmpty(errors) && submitFailed && map(errors, error => (<div className='payoutPlanError'>{error}</div>))}
  </ul>
)

const TemplatesForm = ({
  handleSubmit = () => {},
  templatesConfig = [],
  dispatch = () => {},
  buttonGroup = {},
}) => {
  return (
    <form className='TemplatesForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h3>Templates</h3>

        <FieldArray
          name='templates'
          component={renderTemplates}
          templatesConfig={templatesConfig}
          dispatch={dispatch}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

TemplatesForm.propTypes = {
  handleSubmit: PropTypes.func,
  templatesConfig: PropTypes.array,
}

export default reduxForm({
  form: TEMPLATES_FORM,
  validate: validateTemplatesForm,
})(TemplatesForm)
