import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDisputeSelector } from 'state-layer/selectors'
import Dispute from './Dispute'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import showModalAction from 'utilities/actions/showModalAction'
import getDisputeRequest from 'utilities/actions/get/getDisputeRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import displayOriginalPaymentLink from 'utilities/display/displayOriginalPaymentLink'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { disputeStatusMap } from 'constants/statusConstants'
import { UPDATE_DISPUTE_PERMISSION } from 'constants/permissionConstants'
import { UPDATE_DISPUTE_STATE_MODAL } from 'constants/modalConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  DISPUTES_PATH,
  APPLICATION_PATH,
  PAYMENTS_PATH,
  IDENTITY_PATH,
  DISPUTE_UPDATE_PATH,
} from 'constants/pathConstants'

import {
  STATE,
  CASE_ID,
  RESPOND_BY,
  CREATED_ON,
  ORIGINAL_PAYMENT,
  UPDATE_DISPUTE,
  DISPUTES_RESOURCE_TITLE,
  DISPUTE_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

import {
  LIVE,
  LIVE_HOST,
  QA,
  QA_HOST,
  SANDBOX,
  SANDBOX_HOST,
} from 'constants/dashboardServiceConstants'

import {
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

const credentialEnvToDashboardHostMap = {
  [QA]: QA_HOST,
  [SANDBOX]: SANDBOX_HOST,
  [LIVE]: LIVE_HOST,
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `disputesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentCredentialEnv = get(credentials, 'environment')
  const dashboardServiceHost = getDashboardServiceHost()
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()
  const credentialEnvMatchesDashboardEnv = dashboardServiceHost === credentialEnvToDashboardHostMap[currentCredentialEnv]
  const disputeId = get(props, 'params.disputeId')
  const dispute = getDisputeSelector(state, disputeId)
  const paymentsPath = PAYMENTS_PATH({ credentialId })
  const showTwoTabs = isFlexPlatform()
    && isRolePlatform({ credentials })
    && hasPermissions([UPDATE_DISPUTE_PERMISSION])
    && !isStandaloneUnderwriting

  const [
    disputeAmount,
    disputeState,
    displayCreatedAt,
    displayRespondBy,
    application,
    paymentInstrument,
    identity,
    transfer,
    caseId,
    merchant,
  ] = getMany(dispute, [
    'displayAmount',
    'state',
    'displayCreatedAt',
    'displayRespondBy',
    'applicationId',
    'paymentInstrument',
    'identity',
    'transferDetails',
    'caseId',
    'merchantObj',
  ])

  const [
    applicationId,
    applicationBusinessName,
  ] = getMany(application, [
    'id',
    'name',
  ])

  const [
    identityId,
    identityDisplayBusinessName,
  ] = getMany(identity, [
    'id',
    'displayBusinessName',
  ])

  const [
    transferId,
    transferAmount,
    transferCurrency,
  ] = getMany(transfer, [
    'id',
    'displayAmount',
    'currency',
  ])

  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const brand = get(paymentInstrument, 'brand')

  const contextBarData = {
    back: {
      label: DISPUTES_RESOURCE_TITLE,
      path: DISPUTES_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationBusinessName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: identityDisplayBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
        condition: !isStandaloneMerchant,
      },
    ],
  }

  const headerData = {
    resource: {
      label: DISPUTE_RESOURCE_TITLE,
      id: disputeId,
      additionalIds: [
        {
          label: CASE_ID,
          id: caseId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={disputeAmount} currency={transferCurrency} isTitle={false} />,
      },
      {
        label: STATE,
        value: <ColorcodedStatus value={disputeState} data={disputeStatusMap} />,
      },
      {
        label: RESPOND_BY,
        value: displayRespondBy,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: ORIGINAL_PAYMENT,
        value: displayOriginalPaymentLink({
          displayAmount: transferAmount,
          currency: transferCurrency,
          brand,
          credentialId,
          transferId,
        }),
      },
    ],
    isFetching,
  }


  return {
    credentials,
    dispute,
    disputeId,
    contextBarData,
    headerData,
    paymentInstrument,
    paymentsPath,
    showTwoTabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUpdateDisputeStateModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_DISPUTE_STATE_MODAL, modalProps })),
    getDispute: ({ credentials, disputeId }) => { dispatch(getDisputeRequest({ credentials, disputeId })) },
  }
}

class DisputeC extends Component {
  componentDidMount() {
    const {
      credentials,
      disputeId,
      getDispute,
    } = this.props

    getDispute({ disputeId, credentials })
  }

  render() {
    const {
      disputeId,
      headerData,
      credentials,
    } = this.props

    const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')

    const actions = [
      {
        label: UPDATE_DISPUTE,
        buttonClassName: 'update-dispute-state-button',
        link: DISPUTE_UPDATE_PATH({
          credentialId: get(credentials, 'id'),
          disputeId,
        }),
        condition: isFlexPlatform() && isRolePlatform({ credentials }) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
      },
    ]

    return (
      <Dispute
        {...this.props}
        headerData={{
          ...headerData,
          actions,
        }}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeC)
