import './TableFiltersV2S.scss'
import 'react-datepicker/dist/react-datepicker.css'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import TableFiltersMenuC from 'components/Shared/Table/TableFiltersV2/TableFiltersMenuC'
import ActionButtonsDropdown from 'components/Shared/ActionButtonsDropdown/ActionButtonsDropdown'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import QuickFilterC from 'components/Shared/Table/TableFiltersV2/QuickFilters/QuickFilterC'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import { CONFIGURATION_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import camelCase from 'lodash/camelCase'
import join from 'lodash/join'

import {
  t,
  ALL_FILTERS,
  CLEAR_SELECTIONS,
  EXPORT_ALL,
  EXPORT,
} from 'constants/language/languageConstants'

const TableFiltersV2 = ({
  title = '',
  subtitle = '',
  actions = [],
  filterOptions = [],
  handleDownloadCSV = () => {},
  showAdvancedExportModal = () => {},
  showExportV2Modal = () => {},
  credentials,
  disableCSV = false,
  patching = false,
  fileNameCSV = '',
  fileResourceTitleCSV = '',
  isFetching = false,
  toggleFiltersMenu = () => {},
  allowedFilters = [],
  showFilters = false,
  activeFilters = {},
  toggleShowActionsDropdown = () => {},
  actionDropdownMap = {},
  handleCloseDropdowns = () => {},
  filterSets = [],
  handleClickFilterSet = () => {},
  filterSetsStates = {},
  showAdvancedExport = false,
  quickFilters = [],
  hasSelectedItems = false,
  selectedItemsData = {},
  deselectAllItems = () => {},
  dropdownFilterCount = 0,
  tooltip = '',
  tooltipPosition = '',
  tableLink = '',
  linkPath = '',
  linksKey = '',
}) => {
  const role = get(credentials, 'role')
  const platform = getCurrentPlatform()
  const disabled = disableCSV || patching || isFetching
  const exportContainerClasses = classnames('exportContainer', { disabled })
  const hasActiveDropdownFilters = dropdownFilterCount > 0
  const createFilterClassnames = classnames('createFilter flex', { 'has-dropdown-filters': hasActiveDropdownFilters })
  const showExportBtn = fileNameCSV || fileResourceTitleCSV
  const selectedItemsActions = get(selectedItemsData, 'actions')
  const selectedItemsInfo = get(selectedItemsData, 'info')

  const addFilterButtonClassnames = classnames({
    'add-filter flex items-center': true,
    open: !!showFilters,
    closed: !showFilters,
  })

  return (
    <div className='TableFiltersV2'>
      { !hasSelectedItems && (
      <div className='header flex space-between items-center wrap'>
        <div className='table-filters flex wrap'>
          { (title || tooltip || tableLink || subtitle) && (
            <div>
              <div className='flex items-center'>
                { !isEmpty(title) && <h4 className='table-title'>{t(title)}</h4> }
                { !isEmpty(tooltip) && <div className='table-tooltip flex'>{t(<TooltipLabelC position={tooltipPosition} message={tooltip} />)}</div> }
                { !isEmpty(tableLink) && <Link className='table-link text-link' to={linkPath}>{t(tableLink)}</Link> }
              </div>
              { !isEmpty(subtitle) && <div className='table-subtitle'>{t(subtitle)}</div> }
            </div>
          )}

          { !isEmpty(quickFilters) && (
            <div className='quick-filters flex wrap'>
              {
              map(quickFilters, (quickFilter) => (
                <QuickFilterC
                  key={get(quickFilter, 'name') || join(get(quickFilter, 'names'))}
                  isFetching={isFetching}
                  {...quickFilter}
                />
              ))}
            </div>
          )}

          {(!isEmpty(filterOptions) || showFilters) && (
          <div className='filters'>
            { !isEmpty(filterOptions) && (
            <div className={createFilterClassnames} onClick={toggleFiltersMenu}>
              <Button
                ariaLabel='filters'
                ariaExpanded={showFilters}
                disabled={patching}
                label={(
                  <>
                    {ALL_FILTERS}
                    {hasActiveDropdownFilters && <div className='filter-count'>{dropdownFilterCount}</div>}
                  </>
                )}
                className={addFilterButtonClassnames}
                variant='tertiary'
              />
            </div>
            )}

            { showFilters && (
            <TableFiltersMenuC
              allowedFilters={allowedFilters}
              toggleFiltersMenu={toggleFiltersMenu}
              quickFilters={quickFilters}
              filterSets={filterSets}
            />
            )}
          </div>
          )}

          {/* TODO: make filter sets meet WCAG standards */}
          <div className='flex filter-sets'>
            { !isEmpty(filterSets) && map(filterSets, ({ filterSetName, filterSetDisplayName, filters }) => {
              const filterSetState = get(filterSetsStates, filterSetName)

              return (
                <div className={`flex items-center filter-set ${filterSetName}`} key={filterSetName} onClick={() => handleClickFilterSet({ filterSetName, filters })}>
                  <CheckboxC checked={filterSetState} name={filterSetName} />

                  <span className='filter-set-label'>{filterSetDisplayName}</span>
                </div>
              )
            }) }
          </div>
        </div>

        <div className='table-filters actions flex'>
          {
            // TODO: support a disabled prop to support button load
            map(actions, ({
              label,
              action,
              actionList,
              link,
              queries = {},
              icon,
              className = '',
              condition = true,
              leftButtonIcon,
              buttonIcon,
              underwritingFlag = false,
              customButtonStyles,
              buttonVariant = 'primary',
              disabledButton = false,
              Component,
            }) => {
              const showTab = isFunction(condition) ? condition({ platform, credentials, role }) : condition

              if (!showTab) {
                return null
              }

              const buttonClassName = className ? className : `${camelCase(label)}HeaderButton`
              const showActionsDropdown = actionDropdownMap[label]

              if (Component) {
                return Component
              }

              const buttonAction = isFunction(action) ? (
                <Button
                  className={buttonClassName}
                  onClick={() => {
                    action()
                    handleCloseDropdowns()
                  }}
                  style={customButtonStyles}
                  leftIcon={leftButtonIcon}
                  label={label}
                  rightIcon={buttonIcon}
                  variant={buttonVariant}
                  disabled={disabledButton}
                />
              ) : ActionButtonsDropdown({
                handleCloseDropdowns,
                toggleShowActionsDropdown,
                showActionsDropdown,
                label,
                actionList,
              })

              return link
                ? (
                  <Link className={className} to={{ pathname: link, query: queries, state: { underwritingFlag } }} key={label}>
                    <Button className={`${className}`} label={label} leftIcon={leftButtonIcon} rightIcon={icon} />
                  </Link>
                )
                : <Fragment key={label}>{buttonAction}</Fragment>
            })
          }

          { !showAdvancedExport && showExportBtn && (
            <div className={exportContainerClasses}>
              <Button disabled={disabled} onClick={handleDownloadCSV} ariaLabel='export CSV' variant='secondary' label={EXPORT} />
              <Button icon={CONFIGURATION_ICON} disabled={disabled} onClick={showAdvancedExportModal} variant='secondary' ariaLabel='advanced export CSV' />
            </div>
          )}

          { showAdvancedExport && showExportBtn && (
          <div className='export-all'>
            <Button disabled={disabled} onClick={showExportV2Modal} ariaLabel='export CSV' label={t(EXPORT_ALL)} variant='secondary' />
          </div>
          )}
        </div>
      </div>
      )}

      { hasSelectedItems && (
        <div className='header table-filters flex space-between items-center'>
          <div className='flex'>{selectedItemsInfo}</div>

          <div className='actions flex'>
            <Button variant='secondary' onClick={deselectAllItems} label={CLEAR_SELECTIONS} />

            { map(selectedItemsActions, ({ label, action = () => {} }) => {
              return <Button className='selected-items-action' onClick={action} label={label} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

TableFiltersV2.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.array,
  filterOptions: PropTypes.array,
  fileNameCSV: PropTypes.string,
  fileResourceTitleCSV: PropTypes.string,
  handleDownloadCSV: PropTypes.func,
  showExportV2Modal: PropTypes.func,
  credentials: PropTypes.object,
  disableCSV: PropTypes.bool,
  isFetching: PropTypes.bool,
  allowedFilters: PropTypes.array,
  showFilters: PropTypes.bool,
  toggleFiltersMenu: PropTypes.func,
  activeFilters: PropTypes.object,
  toggleShowActionsDropdown: PropTypes.func,
  actionDropdownMap: PropTypes.object,
  handleCloseDropdowns: PropTypes.func,
  filterSets: PropTypes.array,
  handleClickFilterSet: PropTypes.func,
  filterSetsStates: PropTypes.object,
  showAdvancedExport: PropTypes.bool,
  quickFilters: PropTypes.array,
  hasSelectedItems: PropTypes.bool,
  selectedItemsData: PropTypes.object,
  deselectAllItems: PropTypes.func,
  dropdownFilterCount: PropTypes.number,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tableLink: PropTypes.string,
  linkPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
}

export default TableFiltersV2
