import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_PROFILES } from 'constants/apiConstants'

const getUnderwritingProfilesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: UNDERWRITING_PROFILES,
    meta,
    queries,
  })
}

export default getUnderwritingProfilesAPI
