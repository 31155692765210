import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplicationsC from 'components/Customer/Pages/Applications/ApplicationsC'
import CustomerOnboardingFormsC from 'components/Customer/Pages/CustomerOnboardingForms/CustomerOnboardingFormsC'
import ApplicationTabs from './ApplicationTabs'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getDashboardServiceHost from 'utilities/api/getDashboardServiceHost'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { isFlexPlatform } from 'constants/flexConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  APPLICATIONS_LIST,
  ONBOARDING_FORMS,
} from 'constants/language/languageConstants'

import {
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
  SYSTEM_SALES_ROLE,
} from 'constants/roleConstants'

import {
  LIVE,
  LIVE_HOST,
  QA,
  QA_HOST,
  SANDBOX,
  SANDBOX_HOST,
} from 'constants/dashboardServiceConstants'

const credentialEnvToDashboardHostMap = {
  [QA]: QA_HOST,
  [SANDBOX]: SANDBOX_HOST,
  [LIVE]: LIVE_HOST,
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()
  const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')
  const currentCredentialEnv = get(credentials, 'environment')
  const dashboardServiceHost = getDashboardServiceHost()
  const credentialEnvMatchesDashboardEnv = dashboardServiceHost === credentialEnvToDashboardHostMap[currentCredentialEnv]

  const showTwoTabs = isFlexPlatform()
      && isRolePlatform({ credentials })
      && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE, SYSTEM_SALES_ROLE], dashboardUserRoleName)
      && credentialEnvMatchesDashboardEnv
      && !isStandaloneUnderwriting

  const tabs = [
    {
      id: 'applications',
      name: APPLICATIONS_LIST,
      component: ApplicationsC,
      props: { showTwoTabs },
    },
    {
      id: 'onboarding-forms',
      name: ONBOARDING_FORMS,
      component: CustomerOnboardingFormsC,
    },
  ]

  return {
    tabs,
    showTwoTabs,
    credentials,
  }
}

class ApplicationTabsC extends Component {
  render() {
    const { showTwoTabs } = this.props

    return (
      showTwoTabs ? (
        <ApplicationTabs
          {...this.props}
        />
      ) :
        <ApplicationsC />
    )
  }
}

export default connect(mapStateToProps)(ApplicationTabsC)
