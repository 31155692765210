import './CreateRefundFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import validateCreateRefundForm from 'utilities/validate/validateCreateRefundForm'
import getMany from 'utilities/get/getMany'
import { CREATE_REFUND_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  CANCEL,
  REFUND,
  CREATE_REFUND_AMOUNT_QUESTION,
  ENTER_REFUND_AMOUNT,
  SPLIT_REFUND_CONFIRMATION_QUESTION,
  SPLIT_REFUND_FORM_TABLE_TITLE,
  SPLIT_REFUND_FORM_TABLE_SUBTITLE,
  MERCHANT_ACCOUNT,
  ORIGINAL_PAYMENT_AMOUNT,
  SPLIT_REFUND_AMOUNT,
  PRIMARY_MERCHANT,
} from 'constants/language/languageConstants'

const CreateRefundForm = ({
  handleSubmit = () => {},
  showCreateRefundConfirmation = () => {},
  invalid = false,
  offerSplitRefundExperience = false,
  splitRefundRadioButtonOptions = [],
  selectedSplitRefundExperience = false,
  splitTransfers = [],
  displayTransferAmount = '',
  displaySplitRefundAmountSum = '',
  splitRefundTotalAmountErrorMsg = '',
  showTotalAmountError = false,
  disabledRefundButton = false,
  disabledAmountInput = false,
  formErrorsMap = {},
  primaryMerchantId = '',
  currency = USD,
}) => {
  const formErrorClassname = (id) => (get(formErrorsMap, id, false) ? 'row-error' : '')

  return (
    <form className='CreateRefundForm' onSubmit={handleSubmit(showCreateRefundConfirmation)}>
      {offerSplitRefundExperience && (
        <div className='split-refund-experience'>
          <h4>{SPLIT_REFUND_CONFIRMATION_QUESTION}</h4>

          <Field
            name='splitRefund'
            component={RadioButton}
            options={splitRefundRadioButtonOptions}
          />

          {selectedSplitRefundExperience && (
            <div className='split-refund-form-table-area'>
              <h5>{SPLIT_REFUND_FORM_TABLE_TITLE}</h5>
              <div className='split-refund-table-subtitle p-1'>{SPLIT_REFUND_FORM_TABLE_SUBTITLE}</div>

              <div className='split-refund-form-table'>
                <div className='merchant-account-col'>
                  <div className='col-header p-2-bold'>{MERCHANT_ACCOUNT}</div>
                  {map(splitTransfers, ({ id, merchant }) => {
                    const [
                      merchantId,
                      displayBusinessName,
                      businessName,
                      name,
                    ] = getMany(merchant, [
                      'id',
                      'displayBusinessName',
                      'businessName',
                      'name',
                    ])

                    return (
                      <div className={`${formErrorClassname(id)} row flex items-center`}>
                        <div>{businessName ? displayBusinessName : name}</div>
                        {isEqual(merchantId, primaryMerchantId) && <div className='primary-merchant-badge'>{PRIMARY_MERCHANT}</div>}
                      </div>
                    )
                  })}
                  <div className='row empty-row' />
                </div>

                <div className='original-payment-amount-col'>
                  <div className='col-header p-2-bold'>{ORIGINAL_PAYMENT_AMOUNT}</div>
                  {map(splitTransfers, ({ id, displayAmount }) => <div className={`split-amount row ${formErrorClassname(id)}`}>{displayAmount}</div>)}
                  <div className='row original-transfer-amount p-2-bold'>{displayTransferAmount}</div>
                </div>

                <div className='split-refund-col'>
                  <div className='col-header p-2-bold'>{SPLIT_REFUND_AMOUNT}</div>
                  {map(splitTransfers, ({ id }) => (
                    <div className='row'>
                      <AmountField
                        name={`splitRefundAmount-${id}`}
                      />
                    </div>
                  ))}
                  <div className={`total-refund-sum-row row ${showTotalAmountError && 'total-sum-error'}`}>
                    <div className='p-2-bold total-refund-sum'>{displaySplitRefundAmountSum}</div>
                    {showTotalAmountError && <div className='total-amount-error-msg'>{splitRefundTotalAmountErrorMsg}</div>}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!selectedSplitRefundExperience && (
            <div className='non-split-refund-experience'>
              <h4>{CREATE_REFUND_AMOUNT_QUESTION}</h4>

              <AmountField
                name='refundAmount'
                label={ENTER_REFUND_AMOUNT}
                disabled={disabledAmountInput}
                contextLabel={currency}
              />
            </div>
          )}
        </div>
      )}

      {!offerSplitRefundExperience && (
      <div className='non-split-refund-experience'>
        <h4>{CREATE_REFUND_AMOUNT_QUESTION}</h4>

        <AmountField
          name='refundAmount'
          label={ENTER_REFUND_AMOUNT}
          disabled={disabledAmountInput}
          contextLabel={currency}
        />
      </div>
      )}

      <div className='sticky-footer flex flex-end items-center'>
        <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' className='refund-button' label={REFUND} disabled={invalid || disabledRefundButton} />
      </div>
    </form>
  )
}

CreateRefundForm.propTypes = {
  handleSubmit: PropTypes.func,
  showCreateRefundConfirmation: PropTypes.func,
  invalid: PropTypes.bool,
  offerSplitRefundExperience: PropTypes.bool,
  splitRefundRadioButtonOptions: PropTypes.array,
  selectedSplitRefundExperience: PropTypes.bool,
  displayTransferAmount: PropTypes.string,
  displaySplitRefundAmountSum: PropTypes.string,
  splitRefundTotalAmountErrorMsg: PropTypes.string,
  showTotalAmountError: PropTypes.bool,
  disabledRefundButton: PropTypes.bool,
  disabledAmountInput: PropTypes.bool,
  formErrorsMap: PropTypes.object,
  primaryMerchantId: PropTypes.string,
  splitTransfers: PropTypes.array,
  currency: PropTypes.string,
}

export default reduxForm({
  form: CREATE_REFUND_FORM,
  enableReinitialize: true,
  validate: validateCreateRefundForm,
})(CreateRefundForm)
