import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import get from 'lodash/get'

const validateSendPayoutAmountForm = (values, props) => {
  const {
    amount,
  } = values

  const {
    applicationCurrentUsages,
    availableAmount,
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
    isBankAccount,
    isPaymentCard,
  } = props

  const formattedAmount = displayAmountToAmount(amount)

  let amountMessage = false

  if (formattedAmount > displayAmountToAmount(availableAmount)) {
    amountMessage = 'Amount cannot exceed available balance.'
  }

  if (isBankAccount) {
    if (formattedAmount > displayAmountToAmount(applicationACHRulesMaxTransactionAmount)) {
      amountMessage = `Amount cannot exceed the allowed max transaction amount of $${applicationACHRulesMaxTransactionAmount}.`
    }
    if (formattedAmount > displayAmountToAmount(recipientACHRulesMaxTransactionAmount)) {
      amountMessage = `Amount cannot exceed the allowed max transaction amount of $${recipientACHRulesMaxTransactionAmount}.`
    }
    if (formattedAmount > get(applicationCurrentUsages, 'remainingDailyACHVolume')) {
      amountMessage = 'Amount cannot exceed daily volume limit.'
    }
    if (formattedAmount > get(applicationCurrentUsages, 'remainingMonthlyACHVolume')) {
      amountMessage = 'Amount cannot exceed monthly volume limit.'
    }
    if (formattedAmount > 1000000) {
      amountMessage = 'Bank payouts cannot exceed $10,000.'
    }
  }

  if (isPaymentCard) {
    if (formattedAmount > displayAmountToAmount(applicationCardRulesMaxTransactionAmount)) {
      amountMessage = `Amount cannot exceed the allowed max transaction amount of $${applicationCardRulesMaxTransactionAmount}.`
    }
    if (formattedAmount > displayAmountToAmount(recipientCardRulesMaxTransactionAmount)) {
      amountMessage = `Amount cannot exceed the allowed max transaction amount of $${recipientCardRulesMaxTransactionAmount}.`
    }
    if (formattedAmount > get(applicationCurrentUsages, 'remainingDailyCardVolume')) {
      amountMessage = 'Amount cannot exceed daily volume limit.'
    }
    if (formattedAmount > get(applicationCurrentUsages, 'remainingMonthlyCardVolume')) {
      amountMessage = 'Amount cannot exceed monthly volume limit.'
    }
    if (formattedAmount > 5000000) {
      amountMessage = 'Card payouts cannot exceed $50,000.'
    }
  }

  return {
    amount: (!amount && 'Amount must not be empty.') || (amount <= 0 && 'Amount must be a positive number.') || amountMessage,
  }
}

export default validateSendPayoutAmountForm
