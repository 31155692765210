import removeUndefined from 'utilities/remove/removeUndefined'
import moment from 'moment'

const backendSubscriptionEnrollmentModel = ({ values }) => {
  const {
    merchantId,
    enrollmentNickname,
    startedAt,
    endedAt,
    tags,
  } = values

  const formattedStartedAt = moment(new Date(startedAt)).toISOString()
  const formattedEndedAt = moment(new Date(endedAt)).toISOString()

  return removeUndefined({
    merchant: merchantId,
    nickname: enrollmentNickname,
    started_at: formattedStartedAt,
    ended_at: endedAt ? formattedEndedAt : undefined,
    tags,
  })
}

export default backendSubscriptionEnrollmentModel
