import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_SERVICE_ONBOARDING_FORM_TOKEN_VERIFICATION } from 'constants/apiConstants'

const getHostedFormTokenVerificationAPI = () => {
  return guestDashboardAPI.get({
    path: DASHBOARD_SERVICE_ONBOARDING_FORM_TOKEN_VERIFICATION,
  })
}

export default getHostedFormTokenVerificationAPI
