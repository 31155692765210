import './RemoveSettlementEntriesFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitRemoveSettlementEntriesForm from 'utilities/submit/submitRemoveSettlementEntriesForm'
import { REMOVE_SETTLEMENT_ENTRIES_FORM } from 'constants/formConstants'
import { ALERT_ICON } from 'constants/iconConstants'
import map from 'lodash/map'

import {
  AMOUNT,
  CANCEL,
  ID,
  REMOVE,
  REMOVE_FROM_SETTLEMENT_DESCRIPTION,
  REMOVE_FROM_SETTLEMENT_WARNING,
} from 'constants/language/languageConstants'

const RemoveSettlementEntriesForm = ({
  handleSubmit = () => {},
  settlementId = '',
  entriesData = [],
  closeModal = () => {},
}) => {
  return (
    <form className='RemoveSettlementEntriesForm' onSubmit={handleSubmit(submitRemoveSettlementEntriesForm)}>
      <div className='warning-message'><i className={`fa fa-${ALERT_ICON}`} />{REMOVE_FROM_SETTLEMENT_WARNING}</div>

      <div>
        {`${REMOVE_FROM_SETTLEMENT_DESCRIPTION} (${settlementId}):`}
      </div>

      <div className='section'>
        <ul>
          <div className='transfer-amount-table flex'>
            <div className='id-column flex-grow'>
              <div className='column-name p-2-bold'>{ID}</div>
              { map(entriesData, ({ id }) => <div className='value'>{id}</div>)}
            </div>

            <div className='amount-column flex-grow'>
              <div className='column-name p-2-bold'>{AMOUNT}</div>
              { map(entriesData, ({ amount }) => <div className='value'>{amount}</div>)}
            </div>
          </div>
        </ul>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={REMOVE} />
      </div>
    </form>
  )
}

RemoveSettlementEntriesForm.propTypes = {
  handleSubmit: PropTypes.func,
  settlementId: PropTypes.string,
  entriesData: PropTypes.array,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: REMOVE_SETTLEMENT_ENTRIES_FORM,
})(RemoveSettlementEntriesForm)
