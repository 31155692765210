import formatDate from 'utilities/formatters/formatDate'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import { roleTypeMap } from 'constants/roleConstants'
import { MM_DD_YYYY } from 'constants/timeConstants'

const frontendApplicationUsersModel = ({ data: applicationUsers }) => {
  const applicationUsersModel = map(applicationUsers, (applicationUser) => {
    const [
      createdAt,
      state,
      id,
      tags,
      password,
      identityId,
      role,
      createdBy,
      lastUsedDate,
    ] = getMany(applicationUser, [
      'created_at',
      'enabled',
      'id',
      'tags',
      'password',
      'identity',
      'role',
      'created_by',
      'last_used_date',
    ])

    const [
      name,
    ] = getMany(tags, [
      'name',
    ])

    return removeUndefined({
      displayCreatedAt: formatDate({ date: createdAt }),
      createdAt,
      state,
      id,
      name,
      password,
      identityId,
      role,
      displayRole: roleTypeMap[role],
      createdBy,
      lastUsedDate,
      displayLastUsedDate: formatDate({ date: lastUsedDate, format: MM_DD_YYYY }),
    })
  })

  return keyBy(applicationUsersModel, 'id')
}

export default frontendApplicationUsersModel
