import { fieldEmpty } from 'utilities/validate'
import trim from 'lodash/trim'

import {
  FIRST_NAME,
  LAST_NAME,
} from 'constants/language/languageConstants'

const validateBuyerInformationForm = (values) => {
  const {
    firstName,
    lastName,
  } = values

  return {
    firstName: fieldEmpty(trim(firstName), FIRST_NAME),
    lastName: fieldEmpty(trim(lastName), LAST_NAME),
  }
}

export default validateBuyerInformationForm
