import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import PlatformProcessorConfigsForm from 'components/Customer/Forms/PlatformProcessorConfigsForm/PlatformProcessorConfigsForm'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import sortByKey from 'utilities/sort/sortByKey'
import { isNumber } from 'utilities/validate/index'
import convertToString from 'utilities/convert/convertToString'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'

class PlatformProcessorConfigsFormC extends Component {
  componentDidMount() {
    const { initialize, data } = this.props
    // TODO: find another way to stringify - only use JSON.stringify if is object
    const processorConfigFieldsArray = !isEmpty(data) ?
      map(sortByKey(data), (value, name) => ({ name, value: this.formatProcessorConfigValue(value) }))
      : []

    initialize({ processorConfigFieldsArray })
  }

  formatProcessorConfigValue = (value) => {
    if (isBoolean(value) || isNumber(value)) {
      return value
    }

    return convertToString(value)
  }

  render() {
    const { emptyMessage, extraProps, data } = this.props

    const [
      sectionId,
      processorConfigId,
      updateConfigValue,
    ] = getMany(extraProps, [
      'sectionId',
      'processorConfigId',
      'updateConfigValue',
    ])

    return (
      <PlatformProcessorConfigsForm
        name={isEmpty(data) ? 'empty' : 'processorConfigFieldsArray'}
        sectionId={sectionId}
        emptyMessage={emptyMessage}
        processorConfigId={processorConfigId}
        updateConfigValue={updateConfigValue}
        {...this.props}
      />
    )
  }
}

PlatformProcessorConfigsFormC.propTypes = {
  initialize: PropTypes.func,
  data: PropTypes.object,
  extraProps: PropTypes.object,
}

export default reduxForm({
  enableReinitialize: true,
})(PlatformProcessorConfigsFormC)
