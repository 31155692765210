import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

import {
  REFUNDS_EMPTY_MESSAGE,
  REFUNDS_RESOURCE_TITLE,
  REFUNDS_TABLE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Refunds = ({
  refundsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  hiddenExportFields = [],
  quickFilters,
}) => {
  return (
    <div className='Refunds'>
      <HeaderV2C
        title={REFUNDS_RESOURCE_TITLE}
        subTitle={REFUNDS_TABLE_SUBTITLE_DESCRIPTION}
      />

      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={refundsPath}
        isFetching={isFetching}
        fileNameCSV={REFUNDS_RESOURCE_TITLE}
        entityType={TRANSFER}
        showAdvancedExport
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${PAYMENTS_ICON}`}
        emptyMessage={REFUNDS_EMPTY_MESSAGE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

Refunds.propTypes = {
  refundsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  hiddenExportFields: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default Refunds
