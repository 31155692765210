import MainNav from './MainNav'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentUserRole from 'utilities/get/getCurrentUserRole'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import isAdministrator from 'utilities/is/isAdministrator'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import isPlatform from 'utilities/is/isPlatform'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import { PATHNAME } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { UNDERWRITING_ENABLED_FINIX_EMPLOYEES } from 'constants/underwritingConstants'
import { DASHBOARD_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { ELAVON_PARTNER_CONNECT } from 'constants/platformConstants'
import head from 'lodash/head'
import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'

import {
  SHOW_LANDING_PAGE,
  SHOW_REPORTS_LANDING,
  SHOW_ACH_RETURNS,
  SHOW_MERCHANT_DEPOSITS,
  SHOW_REPORTS,
  SHOW_ELAVON_UNDERWRITING,
  SHOW_DEVICE_MANAGEMENT,
  SHOW_PAYMENT_LINKS,
  SHOW_VIRTUAL_TERMINAL,
  SHOW_SUBSCRIPTION_BILLING,
} from 'constants/featureFlagConstants'

import {
  isRoleMerchant,
  isRolePartner,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  isDisbursementsAndPaymentsDashboard,
  isDisbursementsPartnerDashboard,
  isStandaloneMerchantDashboard,
} from 'utilities/is/isDashboardType'


import {
  PAYMENTS_PATH,
  PAYOUTS_PATH,
  AUTHORIZATIONS_PATH,
  SETTLEMENTS_PATH,
  DISPUTES_PATH,
  ACH_RETURNS_PATH,
  REPORTS_PATH,
  REPORTS_HOME_PATH,
  IDENTITIES_PATH,
  MERCHANTS_PATH,
  COMPLIANCE_PATH,
  APPLICATIONS_PATH,
  PAYMENT_INSTRUMENTS_PATH,
  REVIEW_QUEUE_SETTLEMENTS_PATH,
  ONBOARDING_APPLICATIONS_PATH,
  ADMIN_USERS_PATH,
  SETTINGS_PATH,
  UPDATE_TRANSFERS_PATH,
  UPDATE_VERIFICATIONS_PATH,
  DASHBOARD,
  PAYOUT_PLANS_PATH,
  SUBSCRIPTION_BILLING_PATH,
  HOME_PATH,
  EXPORTS_PATH,
  DEVELOPER_PATH,
  COMPANY_PATH,
  REFUNDS_PATH,
  DASHBOARD_PATH,
  MERCHANT_DEPOSITS_PATH,
  ONBOARDING_FORMS_PATH,
  BUYERS_PATH,
  UNDERWRITING_MERCHANTS_PATH,
  TRANSACTION_INSIGHTS_PATH,
  EXCEPTIONS_INSIGHTS_PATH,
  MERCHANT_INSIGHTS_PATH,
  UNDERWRITING_INSIGHTS_PATH,
  UNDERWRITING_RECIPIENTS_PATH,
  BALANCE_PATH,
  DISBURSEMENTS_PATH,
  RECIPIENTS_PATH,
  BALANCES_PATH,
  PAYMENT_LINKS_PATH,
  PAYMENT_DEVICES_PATH,
  SEND_PAYOUT_PATH,
  PAYOUT_LINKS_PATH,
  SEND_PAYOUT_OPTIONS_PATH,
  STATEMENTS_PATH,
  VIRTUAL_TERMINAL_PATH,
  FEES_PATH,
  MERCHANT_PAYOUTS_PATH,
  BALANCE_ADJUSTMENTS_PATH,
  SUBSCRIPTIONS_PATH,
  SUBSCRIPTION_PLANS_PATH,
  MONITORING_ALERTS_PATH,
} from 'constants/pathConstants'

import {
  PAYMENTS_ICON,
  PAYOUTS_ICON,
  EXCEPTIONS_ICON,
  REPORTS_ICON,
  MERCHANTS_ICON,
  APPLICATIONS_ICON,
  USERS_ICON,
  CONFIGURATIONS_ICON,
  SUBSCRIPTION_BILLING_ICON,
  EXPORTS_ICON,
  COMPANY_ICON,
  CONFIGURATION_ICON,
  DEVELOPER_ICON,
  HOME_ICON,
  REFUNDS_ICON,
  ACH_RETURNS_ICON,
  GROUP_ICON,
  FILE_SIGNATURE,
  PIGGY_BANK_ICON,
  LINK_ICON,
  FAX_ICON,
  HAND_HOLDING_USD,
  BUYER_PAYMENT_INSTRUMENTS_ICON,
  SEND_MONEY_ICON,
  STORE_ICON,
  DEPOSITS_ICON,
  RECURRING_BILLING_ICON,
} from 'constants/iconConstants'

import {
  ALL_ROLES,
  NON_MERCHANT_ROLES,
  ADMIN_PLATFORM_ROLES,
  ROLE_MERCHANT,
  ROLE_ADMIN,
  ROLE_PARTNER,
  PARTNER_AND_MERCHANT_ROLES,
  ROLE_PLATFORM,
} from 'constants/roleConstants'

import {
  TRANSACTIONS,
  PAYMENTS,
  PAYOUTS_RESOURCE_TITLE,
  MERCHANT_UNDERWRITING_RESOURCE_TITLE,
  SUBSCRIPTION_BILLING_RESOURCE_TITLE,
  APPLICATIONS_RESOURCE_TITLE,
  REPORTS_RESOURCE_TITLE,
  DISPUTES_RESOURCE_TITLE,
  MERCHANTS,
  MERCHANT_ACCOUNTS_RESOURCE_TITLE,
  HOME,
  INSTANT_PAYOUTS_RESOURCE_TITLE,
  RESOURCES,
  IDENTITIES_RESOURCE_TITLE,
  PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
  AUTHORIZATIONS_RESOURCE_TITLE,
  COMPLIANCE_RESOURCE_TITLE,
  PAYOUT_PLANS_RESOURCE_TITLE,
  REVIEW_SETTLEMENTS_RESOURCE_TITLE,
  COMPANY,
  DEVELOPER_RESOURCE_TITLE,
  SETTINGS_TITLE,
  SETTLEMENTS,
  SUPPORT_TOOLS,
  UPDATE_TRANSFERS,
  UPDATE_VERIFICATIONS,
  MERCHANT_DEPOSITS_RESOURCE_TITLE,
  DEPOSITS,
  ONBOARDING_FORMS_TITLE,
  DASHBOARD_ACCOUNTS,
  DASHBOARD_USER_MANAGEMENT,
  BUYERS,
  UNDERWRITING as UNDERWRITING_LABEL,
  APPLICATION_UNDERWRITING,
  MERCHANT_INSIGHTS,
  UNDERWRITING_INSIGHTS,
  RECIPIENT_UNDERWRITING_RESOURCE_TITLE,
  RECIPIENTS,
  PAYOUTS,
  PAYMENT_LINKS,
  PAYMENT_DEVICES,
  ACCOUNT,
  PAYOUT_LINKS,
  STATEMENTS,
  TAKE_A_PAYMENT,
  VIRTUAL_TERMINAL,
  REFUNDS,
  FEES,
  ACH_RETURNS,
  MERCHANT_PAYOUTS,
  DATA_RESOURCES,
  SCHEDULED_REPORTS,
  USER_EXPORTS,
  USER,
  ACTIVITY,
  BALANCE_ADJUSTMENTS,
  SEND_PAYOUTS,
  EXPORTS_RESOURCE_TITLE,
  ALL_ACTIVITY,
  TRANSACTION_ACTIVITY,
  PAYOUT_ACTIVITY,
  RECURRING_BILLING,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTIONS_INSIGHTS,
  SUBSCRIPTIONS,
  TRANSACTION_MONITORING_TITLE,
} from 'constants/language/languageConstants'

import {
  VIEW_ALT_PAYMENT_METHODS_PERMISSION,
  VIEW_API_LOGS_LIST_PERMISSION,
  VIEW_APPLICATIONS_LIST_PERMISSION,
  VIEW_AUTHORIZATIONS_LIST_PERMISSION,
  VIEW_COMPLIANCE_FORMS_LIST_PERMISSION,
  VIEW_DISPUTES_LIST_PERMISSION,
  VIEW_IDENTITIES_LIST_PERMISSION,
  EDIT_IDENTITY_PERMISSION,
  VIEW_MERCHANTS_LIST_PERMISSION,
  VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION,
  VIEW_PAYOUT_PLANS_LIST_PERMISSION,
  VIEW_REVIEW_QUEUE_LIST_PERMISSION,
  VIEW_SETTLEMENTS_LIST_PERMISSION,
  VIEW_SUBSCRIPTION_BILLING_LIST_PERMISSION,
  VIEW_TRANSACTIONS_LIST_PERMISSION,
  VIEW_WEBHOOKS_LIST_PERMISSION,
  UPDATE_SETTLEMENT_PERMISSION,
  VIEW_TRANSACTION_READ_PERMISSION,
} from 'constants/permissionConstants'

import {
  PUSH_TO_CARD,
  UNDERWRITING,
} from 'constants/dashboardConfigurationsConstants'

// role partner level sidebars
const disbursementsSidebar = () => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_LANDING_PAGE),
      key: 'home',
    },
    {
      label: ACTIVITY,
      icon: PIGGY_BANK_ICON,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'account',
      items: [
        {
          label: PAYOUTS,
          route: DISBURSEMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payouts',
        },
        {
          label: BALANCE_ADJUSTMENTS,
          route: BALANCE_ADJUSTMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'balance-adjustments',
        },
        {
          label: ALL_ACTIVITY,
          route: BALANCE_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'balance-entries',
        },
      ],
    },
    {
      label: SEND_PAYOUTS,
      icon: HAND_HOLDING_USD,
      route: SEND_PAYOUT_OPTIONS_PATH,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'send-payout',
    },
    {
      label: PAYOUT_LINKS,
      icon: LINK_ICON,
      route: PAYOUT_LINKS_PATH,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'payout-links',
    },
    {
      label: DATA_RESOURCES,
      icon: USERS_ICON,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'resources',
      items: [
        {
          label: RECIPIENTS,
          route: RECIPIENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'recipients',
        },
        {
          label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
          route: PAYMENT_INSTRUMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payment-instruments',
        },
      ],
    },
    // {
    //   label: REPORTS_RESOURCE_TITLE,
    //   icon: REPORTS_ICON,
    //   route: REPORTS_HOME_PATH,
    //   allowedRoles: NON_MERCHANT_ROLES,
    //   key: 'reports',
    // },
  ]
}

const standaloneMerchantSidebar = () => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_LANDING_PAGE),
      key: 'home',
    },
    {
      label: TAKE_A_PAYMENT,
      icon: SEND_MONEY_ICON,
      key: 'take-a-payment-section',
      allowedRoles: PARTNER_AND_MERCHANT_ROLES,
      items: [
        {
          label: VIRTUAL_TERMINAL,
          route: VIRTUAL_TERMINAL_PATH,
          isHidden: !getFeatureFlag(SHOW_VIRTUAL_TERMINAL),
          key: 'virtual-terminal',
        },
        {
          type: 'link',
          label: PAYMENT_LINKS,
          route: PAYMENT_LINKS_PATH,
          isHidden: !getFeatureFlag(SHOW_PAYMENT_LINKS) || !isFlexPlatform() || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION, VIEW_TRANSACTION_READ_PERMISSION]),
          key: 'payment-links',
        },
      ],
    },
    {
      label: TRANSACTIONS,
      icon: PAYMENTS_ICON,
      key: 'payments-section',
      items: [
        {
          label: 'Transaction Insights',
          route: TRANSACTION_INSIGHTS_PATH,
          key: 'transaction-insights',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: PAYMENTS,
          route: PAYMENTS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'transactions',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: AUTHORIZATIONS_RESOURCE_TITLE,
          route: AUTHORIZATIONS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'authorizations',
          isHidden: !hasPermissions([VIEW_AUTHORIZATIONS_LIST_PERMISSION]),
        },
        {
          label: REFUNDS,
          icon: REFUNDS_ICON,
          route: REFUNDS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'refunds',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        // TODO: Cata - uncomment this out once the BE filter for payments is available
        // {
        //   label: FEES,
        //   route: FEES_PATH,
        //   allowedRoles: ALL_ROLES,
        //   key: 'fees',
        //   isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        // },
        {
          type: 'link',
          label: DISPUTES_RESOURCE_TITLE,
          icon: EXCEPTIONS_ICON,
          route: DISPUTES_PATH,
          allowedRoles: ALL_ROLES,
          key: 'disputes',
          isHidden: isPlatform(ELAVON_PARTNER_CONNECT) || !hasPermissions([VIEW_DISPUTES_LIST_PERMISSION]),
        },
        {
          type: 'link',
          label: ACH_RETURNS,
          icon: ACH_RETURNS_ICON,
          route: ACH_RETURNS_PATH,
          allowedRoles: ALL_ROLES,
          isHidden: !getFeatureFlag(SHOW_ACH_RETURNS) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'ach-returns',
        },
      ],
    },
    {
      label: RECURRING_BILLING,
      icon: RECURRING_BILLING_ICON,
      key: 'recurring-billing-section',
      isHidden: !getFeatureFlag(SHOW_SUBSCRIPTION_BILLING),
      items: [
        // {
        //   label: SUBSCRIPTIONS_INSIGHTS,
        // },
        {
          type: 'link',
          label: SUBSCRIPTIONS,
          route: SUBSCRIPTIONS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'subscriptions',
        },
        {
          type: 'link',
          label: SUBSCRIPTION_PLANS,
          route: SUBSCRIPTION_PLANS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'subscription-plans',
        },
      ],
    },
    {
      type: 'link',
      label: PAYMENT_DEVICES,
      icon: FAX_ICON,
      route: PAYMENT_DEVICES_PATH,
      isHidden: !getFeatureFlag(SHOW_DEVICE_MANAGEMENT),
      key: 'payment-devices',
    },
    {
      label: PAYOUTS_RESOURCE_TITLE,
      icon: PAYOUTS_ICON,
      key: 'payouts-section',
      allowedRoles: ROLE_PARTNER,
      items: [
        {
          type: 'link',
          label: SETTLEMENTS,
          route: SETTLEMENTS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'settlements',
          isHidden: !hasPermissions([VIEW_SETTLEMENTS_LIST_PERMISSION]),
        },
        {
          type: 'link',
          label: DEPOSITS,
          route: MERCHANT_DEPOSITS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-deposits',
          isHidden: !getFeatureFlag(SHOW_MERCHANT_DEPOSITS) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: DATA_RESOURCES,
      icon: USERS_ICON,
      key: 'data-resources',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: BUYERS,
          icon: GROUP_ICON,
          route: BUYERS_PATH,
          allowedRoles: [ROLE_PARTNER],
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
          key: 'buyers',
        },
        {
          label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
          route: PAYMENT_INSTRUMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payment-instruments',
          isHidden: !hasPermissions([VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: REPORTS_RESOURCE_TITLE,
      icon: EXPORTS_ICON,
      key: 'reports-section',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: SCHEDULED_REPORTS,
          key: 'scheduled-reports',
          route: REPORTS_HOME_PATH,
          isHidden: !getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: REPORTS_RESOURCE_TITLE,
          icon: REPORTS_ICON,
          route: REPORTS_PATH,
          isHidden: getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'reports',
        },
        {
          label: USER_EXPORTS,
          key: 'user-exports',
          route: EXPORTS_PATH,
        },
      ],
    },
  ]
}

const disbursementsAndPaymentsSidebar = ({
  canReviewSettlements = false,
}) => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_LANDING_PAGE),
      key: 'home',
    },
    { label: PAYMENTS, isDivider: true },
    {
      label: TRANSACTION_ACTIVITY,
      icon: PAYMENTS_ICON,
      key: 'payments-section',
      items: [
        {
          label: 'Transaction Insights',
          route: TRANSACTION_INSIGHTS_PATH,
          key: 'transaction-insights',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: PAYMENTS,
          route: PAYMENTS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'transactions',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: AUTHORIZATIONS_RESOURCE_TITLE,
          route: AUTHORIZATIONS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'authorizations',
          isHidden: !hasPermissions([VIEW_AUTHORIZATIONS_LIST_PERMISSION]),
        },
        {
          label: REFUNDS,
          icon: REFUNDS_ICON,
          route: REFUNDS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'refunds',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        // TODO: Cata - uncomment this out once the BE filter for payments is available
        // {
        //   label: FEES,
        //   route: FEES_PATH,
        //   allowedRoles: ALL_ROLES,
        //   key: 'fees',
        //   isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        // },
        {
          type: 'link',
          label: DISPUTES_RESOURCE_TITLE,
          icon: EXCEPTIONS_ICON,
          route: DISPUTES_PATH,
          allowedRoles: ALL_ROLES,
          key: 'disputes',
          isHidden: isPlatform(ELAVON_PARTNER_CONNECT) || !hasPermissions([VIEW_DISPUTES_LIST_PERMISSION]),
        },
        {
          type: 'link',
          label: ACH_RETURNS,
          icon: ACH_RETURNS_ICON,
          route: ACH_RETURNS_PATH,
          allowedRoles: ALL_ROLES,
          isHidden: !getFeatureFlag(SHOW_ACH_RETURNS) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'ach-returns',
        },
      ],
    },
    {
      label: TAKE_A_PAYMENT,
      icon: SEND_MONEY_ICON,
      key: 'take-a-payment-section',
      allowedRoles: PARTNER_AND_MERCHANT_ROLES,
      items: [
        {
          label: VIRTUAL_TERMINAL,
          route: VIRTUAL_TERMINAL_PATH,
          isHidden: !getFeatureFlag(SHOW_VIRTUAL_TERMINAL),
          key: 'virtual-terminal',
        },
        {
          type: 'link',
          label: PAYMENT_LINKS,
          route: PAYMENT_LINKS_PATH,
          isHidden: !getFeatureFlag(SHOW_PAYMENT_LINKS) || !isFlexPlatform() || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION, VIEW_TRANSACTION_READ_PERMISSION]),
          key: 'payment-links',
        },
      ],
    },
    {
      label: RECURRING_BILLING,
      icon: RECURRING_BILLING_ICON,
      key: 'recurring-billing-section',
      isHidden: !getFeatureFlag(SHOW_SUBSCRIPTION_BILLING),
      items: [
        // {
        //   label: SUBSCRIPTIONS_INSIGHTS,
        // },
        {
          type: 'link',
          label: SUBSCRIPTIONS,
          route: SUBSCRIPTIONS_PATH,
          allowedRoles: ALL_ROLES,
          // isHidden: add feature flag here later
          key: 'subscriptions',
        },
        {
          type: 'link',
          label: SUBSCRIPTION_PLANS,
          route: SUBSCRIPTION_PLANS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'subscription-plans',
        },
      ],
    },
    {
      label: MERCHANTS,
      allowedRoles: NON_MERCHANT_ROLES,
      icon: STORE_ICON,
      key: 'merchants-section',
      items: [
        {
          label: MERCHANT_INSIGHTS,
          route: MERCHANT_INSIGHTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-insights',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: MERCHANT_ACCOUNTS_RESOURCE_TITLE,
          route: MERCHANTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-accounts',
          isHidden: !hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
        },
        {
          label: MERCHANT_PAYOUTS,
          route: MERCHANT_PAYOUTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-payouts',
          isHidden: !hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
        },
        {
          label: PAYOUT_PLANS_RESOURCE_TITLE,
          route: PAYOUT_PLANS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          key: 'payout-plans',
          isHidden: !hasPermissions([VIEW_PAYOUT_PLANS_LIST_PERMISSION]),
        },
        {
          label: REVIEW_SETTLEMENTS_RESOURCE_TITLE,
          route: REVIEW_QUEUE_SETTLEMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'review-settlements',
          isHidden: !canReviewSettlements,
        },
        {
          label: COMPLIANCE_RESOURCE_TITLE,
          route: COMPLIANCE_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !isFlexPlatform() || !hasPermissions([VIEW_COMPLIANCE_FORMS_LIST_PERMISSION]),
          key: 'compliance',
        },
        {
          label: ONBOARDING_FORMS_TITLE,
          route: ONBOARDING_FORMS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !isFlexPlatform() || !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
          key: 'onboarding-forms',
        },
        {
          label: SUBSCRIPTION_BILLING_RESOURCE_TITLE,
          icon: SUBSCRIPTION_BILLING_ICON,
          route: SUBSCRIPTION_BILLING_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          isHidden: !hasPermissions([VIEW_SUBSCRIPTION_BILLING_LIST_PERMISSION]),
          key: 'subscription-billing',
        },
      ],
    },
    {
      type: 'link',
      label: PAYMENT_DEVICES,
      icon: FAX_ICON,
      route: PAYMENT_DEVICES_PATH,
      isHidden: !getFeatureFlag(SHOW_DEVICE_MANAGEMENT),
      key: 'payment-devices',
    },
    { label: PAYOUTS, isDivider: true },
    {
      label: PAYOUT_ACTIVITY,
      icon: PIGGY_BANK_ICON,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'account',
      items: [
        {
          label: PAYOUTS,
          route: DISBURSEMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payouts',
        },
        {
          label: BALANCE_ADJUSTMENTS,
          route: BALANCE_ADJUSTMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'balance-adjustments',
        },
        {
          label: ALL_ACTIVITY,
          route: BALANCE_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'balance-entries',
        },
      ],
    },
    {
      label: SEND_PAYOUTS,
      icon: HAND_HOLDING_USD,
      route: SEND_PAYOUT_OPTIONS_PATH,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'send-payout',
    },
    {
      label: PAYOUT_LINKS,
      icon: LINK_ICON,
      route: PAYOUT_LINKS_PATH,
      allowedRoles: NON_MERCHANT_ROLES,
      key: 'payout-links',
    },
    { label: RESOURCES, isDivider: true },
    {
      label: DATA_RESOURCES,
      icon: USERS_ICON,
      key: 'data-resources',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: IDENTITIES_RESOURCE_TITLE,
          route: IDENTITIES_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-identities',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: RECIPIENTS,
          route: RECIPIENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'recipients',
        },
        {
          label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
          route: PAYMENT_INSTRUMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payment-instruments',
          isHidden: !hasPermissions([VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: REPORTS_RESOURCE_TITLE,
      icon: EXPORTS_ICON,
      key: 'reports-section',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: SCHEDULED_REPORTS,
          key: 'scheduled-reports',
          route: REPORTS_HOME_PATH,
          isHidden: !getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: REPORTS_RESOURCE_TITLE,
          icon: REPORTS_ICON,
          route: REPORTS_PATH,
          isHidden: getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'reports',
        },
        {
          label: USER_EXPORTS,
          key: 'user-exports',
          route: EXPORTS_PATH,
        },
      ],
    },
    {
      label: EXPORTS_RESOURCE_TITLE,
      key: 'user-exports',
      icon: EXPORTS_ICON,
      route: EXPORTS_PATH,
      allowedRoles: ROLE_MERCHANT,
    },
  ]
}

// role platform level sidebars
const payFacSidebar = ({
  merchantUnderwritingEnabled,
  applicationUnderwritingEnabled,
  flexUnderwritingEnabled,
  isCanadianRoleMerchant,
  canReviewSettlements,
}) => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_LANDING_PAGE),
      key: 'home',
    },
    // {
    //   type: 'link',
    //   label: 'Admin',
    //   icon: ADMIN_ICON,
    //   route: ADMINISTRATOR_PATH,
    //   allowedRoles: [ROLE_ADMIN],
    // },
    {
      label: TAKE_A_PAYMENT,
      icon: SEND_MONEY_ICON,
      key: 'take-a-payment-section',
      allowedRoles: ALL_ROLES,
      items: [
        {
          label: VIRTUAL_TERMINAL,
          route: VIRTUAL_TERMINAL_PATH,
          isHidden: !getFeatureFlag(SHOW_VIRTUAL_TERMINAL),
          key: 'virtual-terminal',
        },
        {
          type: 'link',
          label: PAYMENT_LINKS,
          route: PAYMENT_LINKS_PATH,
          isHidden: !getFeatureFlag(SHOW_PAYMENT_LINKS) || !isFlexPlatform() || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION, VIEW_TRANSACTION_READ_PERMISSION]),
          key: 'payment-links',
        },
      ],
    },
    {
      label: TRANSACTIONS,
      icon: PAYMENTS_ICON,
      key: 'payments-section',
      items: [
        {
          label: 'Transaction Insights',
          route: TRANSACTION_INSIGHTS_PATH,
          key: 'transaction-insights',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: PAYMENTS,
          route: PAYMENTS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'transactions',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: AUTHORIZATIONS_RESOURCE_TITLE,
          route: AUTHORIZATIONS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'authorizations',
          isHidden: !hasPermissions([VIEW_AUTHORIZATIONS_LIST_PERMISSION]),
        },
        {
          label: REFUNDS,
          icon: REFUNDS_ICON,
          route: REFUNDS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'refunds',
          isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        // TODO: Cata - uncomment this out once the BE filter for payments is available
        // {
        //   label: FEES,
        //   route: FEES_PATH,
        //   allowedRoles: ALL_ROLES,
        //   key: 'fees',
        //   isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        // },
        {
          type: 'link',
          label: DISPUTES_RESOURCE_TITLE,
          icon: EXCEPTIONS_ICON,
          route: DISPUTES_PATH,
          allowedRoles: ALL_ROLES,
          key: 'disputes',
          isHidden: isPlatform(ELAVON_PARTNER_CONNECT) || !hasPermissions([VIEW_DISPUTES_LIST_PERMISSION]),
        },
        {
          type: 'link',
          label: ACH_RETURNS,
          icon: ACH_RETURNS_ICON,
          route: ACH_RETURNS_PATH,
          allowedRoles: ALL_ROLES,
          isHidden: !getFeatureFlag(SHOW_ACH_RETURNS) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'ach-returns',
        },
      ],
    },
    {
      label: RECURRING_BILLING,
      icon: RECURRING_BILLING_ICON,
      key: 'recurring-billing-section',
      isHidden: !getFeatureFlag(SHOW_SUBSCRIPTION_BILLING),
      items: [
        // {
        //   label: SUBSCRIPTIONS_INSIGHTS,
        // },
        {
          type: 'link',
          label: SUBSCRIPTIONS,
          route: SUBSCRIPTIONS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'subscriptions',
        },
        {
          type: 'link',
          label: SUBSCRIPTION_PLANS,
          route: SUBSCRIPTION_PLANS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'subscription-plans',
        },
      ],
    },
    {
      type: 'link',
      label: STATEMENTS,
      icon: BUYER_PAYMENT_INSTRUMENTS_ICON,
      route: STATEMENTS_PATH,
      allowedRoles: [ROLE_MERCHANT],
      isHidden: !isFlexPlatform() || !isCanadianRoleMerchant,
      key: 'statements',
    },
    {
      label: MERCHANTS,
      allowedRoles: NON_MERCHANT_ROLES,
      icon: STORE_ICON,
      key: 'merchants-section',
      items: [
        {
          label: MERCHANT_INSIGHTS,
          route: MERCHANT_INSIGHTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-insights',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: MERCHANT_ACCOUNTS_RESOURCE_TITLE,
          route: MERCHANTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-accounts',
          isHidden: !hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
        },
        {
          label: MERCHANT_PAYOUTS,
          route: MERCHANT_PAYOUTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-payouts',
          isHidden: !hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
        },
        {
          label: PAYOUT_PLANS_RESOURCE_TITLE,
          route: PAYOUT_PLANS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          key: 'payout-plans',
          isHidden: !hasPermissions([VIEW_PAYOUT_PLANS_LIST_PERMISSION]),
        },
        {
          label: REVIEW_SETTLEMENTS_RESOURCE_TITLE,
          route: REVIEW_QUEUE_SETTLEMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'review-settlements',
          isHidden: !canReviewSettlements,
        },
        {
          label: COMPLIANCE_RESOURCE_TITLE,
          route: COMPLIANCE_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !isFlexPlatform() || !hasPermissions([VIEW_COMPLIANCE_FORMS_LIST_PERMISSION]),
          key: 'compliance',
        },
        {
          label: ONBOARDING_FORMS_TITLE,
          route: ONBOARDING_FORMS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !isFlexPlatform() || !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
          key: 'onboarding-forms',
        },
        {
          label: SUBSCRIPTION_BILLING_RESOURCE_TITLE,
          icon: SUBSCRIPTION_BILLING_ICON,
          route: SUBSCRIPTION_BILLING_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          isHidden: !hasPermissions([VIEW_SUBSCRIPTION_BILLING_LIST_PERMISSION]),
          key: 'subscription-billing',
        },
      ],
    },
    {
      label: APPLICATIONS_RESOURCE_TITLE,
      icon: APPLICATIONS_ICON,
      route: APPLICATIONS_PATH,
      allowedRoles: ADMIN_PLATFORM_ROLES,
      key: 'applications',
      isHidden: !hasPermissions([VIEW_APPLICATIONS_LIST_PERMISSION]),
    },
    {
      type: 'link',
      label: PAYMENT_DEVICES,
      icon: FAX_ICON,
      route: PAYMENT_DEVICES_PATH,
      isHidden: !getFeatureFlag(SHOW_DEVICE_MANAGEMENT),
      key: 'payment-devices',
    },
    {
      label: PAYOUTS_RESOURCE_TITLE,
      icon: PAYOUTS_ICON,
      key: 'payouts-section',
      allowedRoles: ROLE_MERCHANT,
      items: [
        {
          type: 'link',
          label: SETTLEMENTS,
          route: SETTLEMENTS_PATH,
          allowedRoles: ALL_ROLES,
          key: 'settlements',
          isHidden: !hasPermissions([VIEW_SETTLEMENTS_LIST_PERMISSION]),
        },
        {
          type: 'link',
          label: DEPOSITS,
          route: MERCHANT_DEPOSITS_PATH,
          allowedRoles: [ROLE_MERCHANT],
          key: 'merchant-deposits',
          isHidden: !getFeatureFlag(SHOW_MERCHANT_DEPOSITS) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: UNDERWRITING_LABEL,
      allowedRoles: ADMIN_PLATFORM_ROLES,
      icon: FILE_SIGNATURE,
      key: 'underwriting-section',
      isHidden: !merchantUnderwritingEnabled && !applicationUnderwritingEnabled,
      items: [
        {
          label: UNDERWRITING_INSIGHTS,
          route: UNDERWRITING_INSIGHTS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          key: 'underwriting-insights',
        },
        {
          label: APPLICATION_UNDERWRITING,
          route: ONBOARDING_APPLICATIONS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          isHidden: !applicationUnderwritingEnabled,
          key: 'application-underwriting',
        },
        {
          label: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
          route: UNDERWRITING_MERCHANTS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          isHidden: !merchantUnderwritingEnabled,
          key: 'merchant-underwriting',
        },
        {
          label: RECIPIENT_UNDERWRITING_RESOURCE_TITLE,
          route: UNDERWRITING_RECIPIENTS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          isHidden: !flexUnderwritingEnabled,
          key: 'recipient-underwriting',
        },
        {
          label: TRANSACTION_MONITORING_TITLE,
          route: MONITORING_ALERTS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          key: 'transaction-monitoring',
        },
      ],
    },
    {
      label: DATA_RESOURCES,
      icon: USERS_ICON,
      key: 'data-resources',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: IDENTITIES_RESOURCE_TITLE,
          route: IDENTITIES_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-identities',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
          route: PAYMENT_INSTRUMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payment-instruments',
          isHidden: !hasPermissions([VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: REPORTS_RESOURCE_TITLE,
      icon: EXPORTS_ICON,
      key: 'reports-section',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: SCHEDULED_REPORTS,
          key: 'scheduled-reports',
          route: REPORTS_HOME_PATH,
          isHidden: !getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
        },
        {
          label: REPORTS_RESOURCE_TITLE,
          icon: REPORTS_ICON,
          route: REPORTS_PATH,
          isHidden: getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
          key: 'reports',
        },
        {
          label: USER_EXPORTS,
          key: 'user-exports',
          route: EXPORTS_PATH,
        },
      ],
    },
    {
      label: EXPORTS_RESOURCE_TITLE,
      key: 'user-exports',
      icon: EXPORTS_ICON,
      route: EXPORTS_PATH,
      allowedRoles: ROLE_MERCHANT,
    },
  ]
}

const pushToCardSidebar = () => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_LANDING_PAGE),
      key: 'home',
    },
    {
      label: INSTANT_PAYOUTS_RESOURCE_TITLE,
      icon: SUBSCRIPTION_BILLING_ICON,
      route: PAYOUTS_PATH,
      allowedRoles: ALL_ROLES,
      key: 'instant-payouts',
      isHidden: !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
    },
    {
      label: RESOURCES,
      icon: PAYMENTS_ICON,
      key: 'resources',
      allowedRoles: NON_MERCHANT_ROLES,
      items: [
        {
          label: IDENTITIES_RESOURCE_TITLE,
          route: IDENTITIES_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'identities',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: APPLICATIONS_RESOURCE_TITLE,
          icon: APPLICATIONS_ICON,
          route: APPLICATIONS_PATH,
          allowedRoles: ADMIN_PLATFORM_ROLES,
          key: 'applications',
          isHidden: !hasPermissions([VIEW_APPLICATIONS_LIST_PERMISSION]),
        },
        {
          label: PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
          route: PAYMENT_INSTRUMENTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'payment-instruments',
          isHidden: !hasPermissions([VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION]),
        },
      ],
    },
    {
      label: REPORTS_RESOURCE_TITLE,
      icon: REPORTS_ICON,
      route: REPORTS_HOME_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: !getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
      key: 'reports',
    },
    {
      label: REPORTS_RESOURCE_TITLE,
      icon: REPORTS_ICON,
      route: REPORTS_PATH,
      allowedRoles: ALL_ROLES,
      isHidden: getFeatureFlag(SHOW_REPORTS_LANDING) || !hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]),
      key: 'reports',
    },
  ]
}

const standaloneUnderwritingSidebar = () => {
  return [
    {
      label: HOME,
      icon: HOME_ICON,
      route: HOME_PATH,
      allowedRoles: ALL_ROLES,
      key: 'home',
    },
    {
      label: MERCHANTS,
      allowedRoles: NON_MERCHANT_ROLES,
      icon: MERCHANTS_ICON,
      key: 'merchants-section',
      items: [
        {
          label: MERCHANT_INSIGHTS,
          route: MERCHANT_INSIGHTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-insights',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: IDENTITIES_RESOURCE_TITLE,
          route: IDENTITIES_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-identities',
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
        },
        {
          label: MERCHANT_ACCOUNTS_RESOURCE_TITLE,
          route: MERCHANTS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          key: 'merchant-accounts',
          isHidden: !hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
        },
        {
          label: COMPLIANCE_RESOURCE_TITLE,
          route: COMPLIANCE_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !isFlexPlatform() || !hasPermissions([VIEW_COMPLIANCE_FORMS_LIST_PERMISSION]),
          key: 'compliance',
        },
        {
          label: ONBOARDING_FORMS_TITLE,
          route: ONBOARDING_FORMS_PATH,
          allowedRoles: NON_MERCHANT_ROLES,
          isHidden: !hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]),
          key: 'onboarding-forms',
        },
      ],
    },
    {
      label: APPLICATIONS_RESOURCE_TITLE,
      icon: APPLICATIONS_ICON,
      route: APPLICATIONS_PATH,
      allowedRoles: ADMIN_PLATFORM_ROLES,
      key: 'applications',
      isHidden: !hasPermissions([VIEW_APPLICATIONS_LIST_PERMISSION]),
    },
    {
      label: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
      icon: FILE_SIGNATURE,
      route: UNDERWRITING_MERCHANTS_PATH,
      allowedRoles: ADMIN_PLATFORM_ROLES,
      key: 'merchant-underwriting',
      isHidden: !hasPermissions([VIEW_APPLICATIONS_LIST_PERMISSION]),
    },
  ]
}

const getBottomNavBar = () => {
  return [
    {
      label: DEVELOPER_RESOURCE_TITLE,
      icon: DEVELOPER_ICON,
      route: DEVELOPER_PATH,
      key: 'developer',
      allowedRoles: NON_MERCHANT_ROLES,
      isHidden: !hasPermissions([VIEW_WEBHOOKS_LIST_PERMISSION, VIEW_API_LOGS_LIST_PERMISSION, VIEW_ALT_PAYMENT_METHODS_PERMISSION]),
    },
    {
      label: SETTINGS_TITLE,
      icon: CONFIGURATION_ICON,
      key: 'settings',
      allowedRoles: ALL_ROLES,
      items: [
        {
          label: COMPANY,
          icon: COMPANY_ICON,
          route: COMPANY_PATH,
          key: 'company',
          allowedRoles: ALL_ROLES,
        },
        {
          label: USER,
          route: SETTINGS_PATH,
          key: 'user',
          allowedRoles: ALL_ROLES,
        },
      ],
    },
    {
      label: SUPPORT_TOOLS,
      adminCheck: isAdministrator,
      allowedRoles: [ROLE_ADMIN],
      icon: USERS_ICON,
      key: 'support-section',
      items: [
        {
          label: UPDATE_TRANSFERS,
          route: UPDATE_TRANSFERS_PATH,
          allowedRoles: [ROLE_ADMIN],
          key: 'update-transfers',
        },
        {
          label: UPDATE_VERIFICATIONS,
          route: UPDATE_VERIFICATIONS_PATH,
          allowedRoles: [ROLE_ADMIN],
          key: 'update-verifications',
        },
      ],
    },
  ]
}

const credentialsPageAdminSidebar = [
  {
    label: DASHBOARD_ACCOUNTS,
    icon: HOME_ICON,
    route: DASHBOARD_PATH,
    key: null,
  },
  {
    label: DASHBOARD_USER_MANAGEMENT,
    icon: CONFIGURATIONS_ICON,
    route: ADMIN_USERS_PATH,
    key: 'users',
  },
  // {
  //   label: EMAIL_TEMPLATES,
  //   icon: EMAILS_ICON,
  //   route: EMAIL_TEMPLATES_PATH,
  //   key: 'email-templates',
  // },
]

const mapStateToProps = (state, props) => {
  const platform = getCurrentPlatform()
  const applicationLogoImage = get(platform, 'applicationLogoImage')
  const isFetchingUser = get(state, `currentUserR.${FETCHING}`, true)
  const credentials = getCurrentCredentials(state)

  const [
    credentialId = get(props, 'credentialId'),
    credentialPermissions = [],
  ] = getMany(credentials, [
    'id',
    'permissions',
  ])

  const isRolePartnerCred = isRolePartner({ credentials })
  const inCredentialsPage = !credentialId
  const currentUser = getCurrentUser(state)
  const currentUserEmail = get(currentUser, 'email')
  const currentRole = getCurrentUserRole(state)
  const platformType = get(currentUser, 'customSettings.platformType', 'FLEX')
  const pathname = get(state, PATHNAME)
  const customDashboardSidebar = get(state, `configurationsR.sidebar.${currentRole}`)
  const isElavon = isPlatform('elavonpartnerconnect')
  const hasUnderwritingPermission = hasPermissions([EDIT_IDENTITY_PERMISSION])
  // TODO: remove underwriting checks in favor of FLEX && PAYFAC_WITH_UNDERWRITING platform types for standalone underwriting release
  const elavonUnderwritingEnabled = isElavon && isRolePlatform({ credentials }) && hasUnderwritingPermission && getFeatureFlag(SHOW_ELAVON_UNDERWRITING)
  const flexUnderwritingEnabled = includes(UNDERWRITING_ENABLED_FINIX_EMPLOYEES, currentUserEmail) || (isFlexPlatform() && (isRolePartner({ credentials }) || isRolePlatform({ credentials })) && hasUnderwritingPermission)
  const merchantUnderwritingEnabled = elavonUnderwritingEnabled || flexUnderwritingEnabled
  const applicationUnderwritingEnabled = flexUnderwritingEnabled
  const isPayout = isPayoutFeature({ credentials })
  const currentActiveHeaderElement = get(document.getElementsByClassName('header-item active'), '[0]')
  const isDisbursements = isDisbursementsPartnerDashboard(state)
  const isDisbursementsAndPayments = isDisbursementsAndPaymentsDashboard(state)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isCanadianRoleMerchant = get(currentUser, 'isCanadianRoleMerchant')
  const applicationSettlementApprovalEnabled = get(currentUser, 'application.settlementApprovalEnabled', false)
  const canReviewSettlements = isRolePartner({ credentials }) ? (applicationSettlementApprovalEnabled && hasPermissions([VIEW_REVIEW_QUEUE_LIST_PERMISSION, UPDATE_SETTLEMENT_PERMISSION])) : hasPermissions([VIEW_REVIEW_QUEUE_LIST_PERMISSION])

  // custom sidebar logic
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const dashboardConfiguration = getCurrentDashboardConfig(state)
  const dashboardConfigurationEnabled = get(dashboardConfiguration, 'enabled', false) === 'true'
  const sidebarSettings = dashboardConfigurationEnabled ? dashboardConfiguration : {}
  const formValues = getFormValues(DASHBOARD_CONFIGURATIONS_FORM)(state)
  const showLivePreview = get(formValues, 'showLivePreview')
  const sidebarLogoImageUrl = get(sidebarSettings, 'sidebarLogoImageUrl')
  const sidebarBackgroundColor = showLivePreview ? get(formValues, 'sidebarBackgroundColor') : get(sidebarSettings, 'sidebarBackgroundColor')
  const sidebarTextColor = showLivePreview ? get(formValues, 'sidebarTextColor') : get(sidebarSettings, 'sidebarTextColor')
  const sidebarHighlightColor = showLivePreview ? get(formValues, 'sidebarHighlightColor') : get(sidebarSettings, 'sidebarHighlightColor')

  const isAdmin = isAdministrator(state)
  const topLevelPath = head(pathname.split('/', 1))
  const inDashboard = topLevelPath === DASHBOARD || !topLevelPath
  // TODO: home page for admin tools might change upon design revisit
  const mainLink = HOME_PATH({ credentialId })

  // logic to determine which nav bar to render
  // base navbar is the payFacSidebar for FLEX, PAYFAC, and PAYFAC_WITH_UNDERWRITING platforms
  let sidebarData = payFacSidebar({
    merchantUnderwritingEnabled,
    applicationUnderwritingEnabled,
    flexUnderwritingEnabled,
    isCanadianRoleMerchant,
    canReviewSettlements,
  })

  if (customDashboardSidebar) {
    sidebarData = map(customDashboardSidebar, (item) => {
      const {
        icon,
        label,
        route,
      } = item

      return {
        icon,
        label,
        route: head(route), // TODO: we need to either update all older routes, or make a map of old routes -> new routes
      }
    })
  }

  if (isDisbursements && isRolePartnerCred) {
    sidebarData = disbursementsSidebar()
  }

  if (isStandaloneMerchant && isRolePartnerCred) {
    sidebarData = standaloneMerchantSidebar()
  }

  if (isDisbursementsAndPayments && isRolePartnerCred) {
    sidebarData = disbursementsAndPaymentsSidebar({
      canReviewSettlements,
    })
  }

  if (platformType === PUSH_TO_CARD || isPayout) {
    sidebarData = pushToCardSidebar()
  }

  if (platformType === UNDERWRITING) {
    sidebarData = standaloneUnderwritingSidebar()
  }

  if (inCredentialsPage) {
    sidebarData = []
  }

  if (inCredentialsPage && isAdmin) {
    sidebarData = credentialsPageAdminSidebar
  }

  const isFetchingPermission = get(state, `userPermissionsR.${FETCHING}`)

  return {
    currentRole,
    bottomNavBar: getBottomNavBar(),
    credentialId,
    applicationLogoImage,
    pathname: `/${pathname}`,
    inDashboard,
    mainLink,
    isFetchingUser,
    sidebarLogoImageUrl,
    sidebarTextColor,
    sidebarBackgroundColor,
    sidebarHighlightColor,
    customDashboardSidebar,
    credentialPermissions,
    sidebarData,
    currentActiveHeaderElement,
    isFetchingConfiguration,
    inCredentialsPage,
  }
}

class MainNavC extends Component {
  componentDidUpdate(prevProps) {
    const { currentActiveHeaderElement: prevCurrentActiveHeaderElement } = prevProps

    const { currentActiveHeaderElement } = this.props
    const noChildren = currentActiveHeaderElement?.classList.contains('no-children')
    const prevNoChildren = prevCurrentActiveHeaderElement?.classList.contains('no-children')
    const clicked = currentActiveHeaderElement?.parentElement.classList.contains('clicked')

    if (currentActiveHeaderElement && !isEqual(currentActiveHeaderElement, prevCurrentActiveHeaderElement) && !noChildren) {
      currentActiveHeaderElement.parentElement.classList.add('expanded')
    }

    if (!isEqual(prevNoChildren, noChildren) && !isEqual(currentActiveHeaderElement, prevCurrentActiveHeaderElement) && noChildren && prevCurrentActiveHeaderElement && !clicked) {
      prevCurrentActiveHeaderElement.parentElement.classList.remove('expanded')
    }
  }

  handleLogout = () => {
    const { auth } = this.props

    if (auth) {
      auth.logout()
    }
  }

  toggleSidebarSections = ({ e, noChildren }) => {
    e.currentTarget.parentNode.classList.add('clicked')

    if (!noChildren) {
      e.currentTarget.parentNode.classList.toggle('expanded')
      this.forceUpdate()
    }
  }

  render() {
    return (
      <MainNav
        {...this.props}
        handleLogout={this.handleLogout}
        toggleSidebarSections={this.toggleSidebarSections}
      />
    )
  }
}

export default connect(mapStateToProps)(MainNavC)
