const formatPhone = (value) => {
  if (!value) {
    return ''
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  if (onlyNums.length <= 10) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
  return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`
}

export default formatPhone
