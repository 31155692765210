import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import validateVerifyMFASMSAuthenticationCodeForm from 'utilities/validate/validateVerifyMFASMSAuthenticationCodeForm'
import submitVerifyMFASMSAuthenticationCodeForm from 'utilities/submit/submitVerifyMFASMSAuthenticationCodeForm'
import { CONFIRM_PASSWORD_MFA_FORM } from 'constants/formConstants'

import {
  AUTHENTICATION_CODE,
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

const VerifyMFASMSAuthenticationCodeForm = ({
  type = '',
  token = '',
  handleSubmit = () => {},
  showSetupMFASMSModal = () => {},
  isPatching = false,
  invalid = false,
}) => {
  return (
    <form className='VerifyMFASMSAuthenticationCodeForm' onSubmit={handleSubmit(submitVerifyMFASMSAuthenticationCodeForm)}>
      <div className='modal-content'>
        <Field
          name='code'
          label={AUTHENTICATION_CODE}
          component={InputField}
          required={false}
          disabled={isPatching}
        />
      </div>

      <div className='buttons flex space-between'>
        <Button
          label={BACK}
          variant='tertiary'
          className='cancel-button'
          onClick={() => showSetupMFASMSModal({ type, token })}
          disabled={isPatching}
        />

        <Button
          type='submit'
          label={NEXT}
          disabled={invalid || isPatching}
        />
      </div>
    </form>
  )
}

VerifyMFASMSAuthenticationCodeForm.propTypes = {
  type: PropTypes.string,
  token: PropTypes.string,
  handleSubmit: PropTypes.func,
  showSetupMFASMSModal: PropTypes.func,
  isPatching: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: CONFIRM_PASSWORD_MFA_FORM,
  validate: validateVerifyMFASMSAuthenticationCodeForm,
})(VerifyMFASMSAuthenticationCodeForm)
