import React, { Component } from 'react'
import { connect } from 'react-redux'
import RefundsInsights from './RefundsInsights'
import getTransactionChartsRequest from 'utilities/actions/get/getTransactionChartsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'

import {
  isRolePlatform,
  isRoleMerchant,
} from 'utilities/validate/checkRoleCredentials'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `chartsR.${FETCHING}`, true)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})
  const credentials = getCurrentCredentials(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const showRefundsPerMerchant = !isStandaloneMerchantDashboard(state) && !isRoleMerchant({ credentials })

  return {
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
    isRolePlatformCredential,
    showRefundsPerMerchant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionCharts: ({ queries }) => dispatch(getTransactionChartsRequest({ queries })),
  }
}

class RefundsInsightsC extends Component {
  componentDidMount() {
    const {
      getTransactionCharts,
    } = this.props

    getTransactionCharts({
      queries: {
        tab: 'refunds',
      },
    })
  }

  render() {
    return (
      <RefundsInsights
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundsInsightsC)
