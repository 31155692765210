import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_RUN_SILENCE } from 'constants/apiConstants'

const postUnderwritingRunSilenceAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    credentials,
    path: UNDERWRITING_RUN_SILENCE({ runId: id }),
  })
}

export default postUnderwritingRunSilenceAPI
