import React from 'react'
import moment from 'moment'
import { SETTLEMENT_STATUS_OPTIONS } from 'constants/settlementConstants'
import ButtonGroupQuickFilter from 'components/Shared/Table/TableFiltersV2/QuickFilters/ButtonGroupQuickFilter/ButtonGroupQuickFilter'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import { WEBHOOK_LOG_STATE_OPTIONS } from 'constants/webhookConstants'
import { MERCHANT_ACCOUNTS_ONBOARDING_STATE_OPTIONS } from 'constants/merchantAccountsConstants'
import { ONBOARDING_FORMS_STATUS_OPTIONS } from 'constants/onboardingFormConstants'
import { WEBHOOK_STATE_OPTIONS } from 'constants/webhookConstants'
import { DASHBOARDS_ENVIRONMENT_OPTIONS } from 'constants/roleConstants'
import { REVIEW_QUEUE_STATE_OPTIONS } from 'constants/reviewQueueConstants'
import { UNDERWRITING_REVIEW_STATE_OPTIONS } from 'constants/underwritingReviewConstants'
import { DEVICE_STATE_OPTIONS } from 'constants/deviceConstants'
import find from 'lodash/find'
import get from 'lodash/get'

import {
  DISPUTE_TYPE_OPTIONS,
  SUBSCRIPTION_PLAN_STATE_OPTIONS,
  SUBSCRIPTION_STATE_OPTIONS,
} from 'constants/statusConstants'

import {
  PAYMENT_LINK_PAYMENTS_OPTIONS,
  PAYMENT_LINK_STATUS_OPTIONS,
} from 'constants/paymentLinkConstants'

import {
  MEMBER_STATE_OPTIONS,
  ROLE_TYPE_OPTIONS,
} from 'constants/memberConstants'

import {
  TRANSFER_STATE_OPTIONS,
  AUTHORIZATION_STATE_OPTIONS,
} from 'constants/transferConstants'

import {
  CREATED_ON,
  DUE_ON_LABEL,
  TODAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  QUARTER_TO_DATE,
  YEAR_TO_DATE,
  CUSTOM,
  CREATED,
  DUE,
  UPDATED,
  RESPOND_BY,
} from 'constants/language/languageConstants'
import { MONITORING_ALERT_STATUS_OPTIONS } from './monitoringAlertConstants'

export const DATES_LABEL_MAP = [
  { label: CREATED, value: 'created_at' },
  { label: DUE, value: 'due_at' },
  { label: UPDATED, value: 'updated_at' },
  { label: RESPOND_BY, value: 'respond_by' },
]

export const TRANSFER_STATE_QUICK_FILTER = {
  name: 'state',
  options: TRANSFER_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const WEBHOOK_STATE_QUICK_FILTER = {
  name: 'enabled',
  options: WEBHOOK_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const AUTHORIZATION_STATE_QUICK_FILTER = {
  name: 'state',
  options: AUTHORIZATION_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const SETTLEMENTS_STATUS_QUICK_FILTER = {
  name: 'status',
  options: SETTLEMENT_STATUS_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const DISPUTE_STATE_QUICK_FILTER = {
  name: 'state',
  options: DISPUTE_TYPE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const WEBHOOK_LOG_STATUS_QUICK_FILTER = {
  name: 'response_status',
  options: WEBHOOK_LOG_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const PAYMENT_DEVICES_STATE_QUICK_FILTER = {
  name: 'enabled',
  options: DEVICE_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const DEVICE_DATES_QUICK_FILTER = {
  names: ['created_at', 'updated_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

export const REVIEW_QUEUE_SETTLEMENTS_STATE_QUICK_FILTER = {
  name: 'outcome',
  options: REVIEW_QUEUE_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const MERCHANT_ACCOUNTS_STATUS_QUICK_FILTER = {
  name: 'onboarding_state',
  options: MERCHANT_ACCOUNTS_ONBOARDING_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const ONBOARDING_FORMS_STATUS_QUICK_FILTER = {
  name: 'status',
  options: ONBOARDING_FORMS_STATUS_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const DASHBOARDS_ENVIRONMENT_QUICK_FILTER = {
  name: 'env',
  options: DASHBOARDS_ENVIRONMENT_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const UNDERWRITING_REVIEW_STATE_QUICK_FILTER = {
  name: 'state',
  options: UNDERWRITING_REVIEW_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const DATE_QUICK_FILTER = {
  name: 'created_at',
  label: CREATED_ON,
  QuickFilterComponent: DatePickerNew,
}

export const DISPUTES_DATES_QUICK_FILTER = {
  names: ['created_at', 'respond_by', 'updated_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

export const UNDERWRITING_REVIEW_DATES_QUICK_FILTER = {
  names: ['created_at', 'updated_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

export const PAYMENT_LINKS_QUICK_FILTERS = {
  names: ['created_at', 'updated_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

export const DUE_ON_DATE_QUICK_FILTER = {
  name: 'due_at',
  label: DUE_ON_LABEL,
  QuickFilterComponent: DatePickerNew,
}

export const MEMBER_STATE_QUICK_FILTER = {
  name: 'enabled',
  options: MEMBER_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const ROLE_TYPE_QUICK_FILTER = {
  name: 'type',
  options: ROLE_TYPE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const SUBSCRIPTION_STATE_QUICK_FILTER = {
  name: 'state',
  options: SUBSCRIPTION_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const SUBSCRIPTION_PLAN_STATE_QUICK_FILTER = {
  name: 'state',
  options: SUBSCRIPTION_PLAN_STATE_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const DATE_PICKER_PRESET_MAPPING = {
  today: {
    operand: 'on',
    getDate: () => ([
      moment().toDate(),
      moment().toDate(),
    ]),
  },
  last7Days: {
    operand: 'between',
    getDate: () => ([
      moment().subtract(7, 'day').toDate(),
      moment().toDate(),
    ])
  },
  last30Days: {
    operand: 'between',
    getDate: () => ([
      moment().subtract(30, 'day').toDate(),
      moment().toDate(),
    ]),
  },
  last3Months: {
    operand: 'between',
    getDate: () => ([
      moment().subtract(3, 'months').toDate(),
      moment().toDate(),
    ]),
  },
  last6Months: {
    operand: 'between',
    getDate: () => ([
      moment().subtract(6, 'months').toDate(),
      moment().toDate(),
    ]),
  },
  quarterToDate: {
    operand: 'between',
    getDate: () => ([
      moment().startOf('quarter').toDate(),
      moment().toDate(),
    ]),
  },
  yearToDate: {
    operand: 'between',
    getDate: () => ([
      moment().startOf('year').toDate(),
      moment().toDate(),
    ]),
  },
  on: {
    operand: 'on',
  },
  between: {
    operand: 'between',
  },
  gte: {
    operand: 'gte',
  },
  lte: {
    operand: 'lte',
  },
}

export const DATE_PICKER_PRESETS = [
  {
    label: TODAY,
    name: 'today',
  },
  {
    label: LAST_7_DAYS,
    name: 'last7Days',
  },
  {
    label: LAST_30_DAYS,
    name: 'last30Days',
  },
  {
    label: LAST_3_MONTHS,
    name: 'last3Months',
  },
  {
    label: LAST_6_MONTHS,
    name: 'last6Months',
  },
  {
    label: QUARTER_TO_DATE,
    name: 'quarterToDate',
  },
  {
    label: YEAR_TO_DATE,
    name: 'yearToDate',
  },
  {
    label: CUSTOM,
    name: 'custom',
  },
]

export const COMPLIANCE_DATES_QUICK_FILTER = {
  names: ['created_at', 'due_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

export const WEBHOOK_LOG_DATE_PICKER_PRESETS = [
  {
    label: TODAY,
    name: 'today',
  },
  {
    label: LAST_7_DAYS,
    name: 'last7Days',
  },
  {
    label: LAST_30_DAYS,
    name: 'last30Days',
  },
  {
    label: CUSTOM,
    name: 'custom',
  },
]

export const API_LOG_DATE_PICKER_PRESETS = [
  {
    label: TODAY,
    name: 'today',
  },
  {
    label: LAST_7_DAYS,
    name: 'last7Days',
  },
  {
    label: LAST_30_DAYS,
    name: 'last30Days',
  },
  {
    label: CUSTOM,
    name: 'custom',
  },
]

export const DATE_PICKER_CUSTOM_OPTIONS = [
  {
    label: 'On',
    value: 'on',
  },
  {
    label: 'Is between',
    value: 'between',
  },
  {
    label: 'Is on or after',
    value: 'gte',
  },
  {
    label: 'Is on or before',
    value: 'lte',
  },
]

export const WEBHOOK_LOGS_DATE_QUICK_FILTER = {
  name: 'created_at',
  label: CREATED_ON,
  QuickFilterComponent: DatePickerNew,
  customPresets: WEBHOOK_LOG_DATE_PICKER_PRESETS,
}

export const API_LOGS_DATE_QUICK_FILTER = {
  name: 'created_at',
  label: CREATED_ON,
  QuickFilterComponent: DatePickerNew,
  customPresets: API_LOG_DATE_PICKER_PRESETS,
}

export const PAYMENT_LINKS_STATUS_QUICK_FILTER = {
  name: 'state',
  options: PAYMENT_LINK_STATUS_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const PAYMENT_LINK_PAYMENTS_QUICK_FILTER = {
  name: 'state',
  options: PAYMENT_LINK_PAYMENTS_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const MONITORING_ALERTS_QUICK_FILTER = {
  name: 'state',
  options: MONITORING_ALERT_STATUS_OPTIONS,
  QuickFilterComponent: ButtonGroupQuickFilter,
}

export const MONITORING_ALERTS_DATES_QUICK_FILTER = {
  names: ['created_at', 'updated_at'],
  labelMap: DATES_LABEL_MAP,
  QuickFilterComponent: DatePickerNew,
}

// return operand based on startDate + endDate
export const convertDatesToOperand = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    if (startDate.toDateString() === endDate.toDateString()) return 'on'
    return 'between'
  }

  if (startDate) return 'gte'
  return 'lte'
}

// return name of DatePicker input display value based on startDate + endDate
export const formatDatePickerValue = ({ startDate, endDate }) => {
  const formattedStartDateQuery = startDate?.toLocaleDateString('en-US')
  const formattedEndDateQuery = endDate?.toLocaleDateString('en-US')
  const displayEnd = startDate ? 'or after' : 'or before'

  if (formattedStartDateQuery === formattedEndDateQuery) return formattedEndDateQuery
  return startDate && endDate ? `${formattedStartDateQuery} — ${formattedEndDateQuery}` : `${formattedStartDateQuery || formattedEndDateQuery} ${displayEnd}`
}

// return name of DatePicker preset based on startDate + endDate
export const getDefaultDatePickerPreset = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return 'custom'

  const datePickerPreset = find(DATE_PICKER_PRESETS, ({ name }) => {
    if (name === 'custom') return false

    const getDatePreset = get(DATE_PICKER_PRESET_MAPPING, `${name}.getDate`, () => {})
    const datePreset = getDatePreset()
    const startDatePreset = datePreset?.[0]
    const endDatePreset = datePreset?.[1]

    if ((startDate?.toDateString() === endDate?.toDateString()) && !startDatePreset) {
      return startDate?.toDateString() === datePreset?.toDateString()
    } else {
      const startDateEqual = startDate?.toDateString() === startDatePreset?.toDateString()
      const endDateEqual = endDate?.toDateString() === endDatePreset?.toDateString()

      return startDateEqual && endDateEqual
    }
  })

  return get(datePickerPreset, 'name') || 'custom'
}
