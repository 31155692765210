import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import UserActions from './UserActions'
import getDashboardUser from 'utilities/get/getDashboardUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getRecentlyActiveCredentialsRequest from 'utilities/actions/get/getRecentlyActiveCredentialsRequest'
import { lastActivityRange } from 'utilities/table/sort'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import getMany from 'utilities/get/getMany'
import getPlatformRequest from 'utilities/actions/get/getPlatformRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import { DASHBOARD_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { PATHNAME } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { roleTypeMap } from 'constants/roleConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  isRolePlatform,
  isRolePartner,
} from 'utilities/validate/checkRoleCredentials'

import {
  getIdentitySelector,
  getPlatformSelector,
  getRecentlyActiveCredentialsSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const pathname = get(state, PATHNAME)
  const dashboardUser = getDashboardUser()
  const credentials = getCurrentCredentials(state)
  const userRoleName = get(credentials, 'displayDashboardUserRoleName')
  const userEmail = get(dashboardUser, CLIENT_EMAIL, '')
  const currentUser = getCurrentUser(state)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)

  const [
    platformId,
    userIdentityId,
    applicationId,
    fullName,
  ] = getMany(currentUser, [
    'platformId',
    'identityId',
    'applicationId',
    'fullName',
  ])

  const platform = getPlatformSelector(state, platformId)
  const platformIdentityId = get(platform, 'identityId')
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const identityId = isRolePlatformCredential ? platformIdentityId : userIdentityId
  const identity = getIdentitySelector(state, identityId)

  let businessEntityId
  if (isRolePlatformCredential) { businessEntityId = platformId }
  if (isRolePartner({ credentials })) { businessEntityId = applicationId }

  // TODO: grab custom configuration logic - unused right now but will be used for top nav user avatar branding in the future
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const dashboardConfiguration = getCurrentDashboardConfig(state)
  const dashboardConfigurationEnabled = get(dashboardConfiguration, 'enabled', false) === 'true'
  const sidebarSettings = dashboardConfigurationEnabled ? dashboardConfiguration : {}
  const formValues = getFormValues(DASHBOARD_CONFIGURATIONS_FORM)(state)
  const showLivePreview = get(formValues, 'showLivePreview')
  const sidebarTextColor = showLivePreview ? get(formValues, 'sidebarTextColor') : get(sidebarSettings, 'sidebarTextColor')
  const sidebarBackgroundColor = showLivePreview ? get(formValues, 'sidebarBackgroundColor') : get(sidebarSettings, 'sidebarBackgroundColor')
  const sidebarHighlightColor = showLivePreview ? get(formValues, 'sidebarHighlightColor') : get(sidebarSettings, 'sidebarHighlightColor')

  const [
    credentialId,
    credentialName,
    userId,
    role,
    environment,
  ] = getMany(credentials, [
    'id',
    'name',
    'username',
    'role',
    'environment',
  ])

  const roleType = get(roleTypeMap, role)
  const recentlyActiveCredentials = getRecentlyActiveCredentialsSelector(state)
  const totalDashboardsCount = get(state, 'recentlyActiveCredentialsR.count')
  const businessName = get(identity, 'displayBusinessName') || credentialName

  return {
    pathname: `/${pathname}`,
    dashboardUser,
    userEmail,
    credentialName,
    userId,
    role: roleType,
    credentialId,
    credentials,
    userRoleName,
    fullName,
    recentlyActiveCredentials,
    totalDashboardsCount,
    environment,
    sidebarTextColor,
    sidebarBackgroundColor,
    sidebarHighlightColor,
    businessName,
    platformId,
    applicationId,
    identityId,
    isRolePlatformCredential,
    businessEntityId,
    isFetching: isFetchingConfiguration || isFetchingCurrentUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRecentCredentials: () => dispatch(getRecentlyActiveCredentialsRequest({ queries: { sort: get(lastActivityRange, 'desc'), limit: 6 } })),
    getPlatform: ({ credentials, platformId }) => dispatch(getPlatformRequest({ credentials, platformId })),
    getIdentity: ({ credentials, identityId }) => dispatch(getIdentityRequest({ credentials, identityId })),
  }
}

class UserActionsC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showUserMenu: false,
      showDashboardsMenu: false,
    }
  }

  componentDidMount() {
    const {
      getRecentCredentials,
      getPlatform,
      getIdentity,
      platformId,
      identityId,
      platform,
      identity,
      credentials,
      isRolePlatformCredential,
    } = this.props

    if (platformId && isEmpty(platform) && isRolePlatformCredential) {
      getPlatform({ platformId, credentials })
    }

    if (identityId && isEmpty(identity)) {
      getIdentity({ identityId, credentials })
    }

    getRecentCredentials()
  }

  componentDidUpdate(prevProps) {
    const {
      platformId: prevPlatformId,
      identityId: prevIdentityId,
    } = prevProps

    const {
      getPlatform,
      getIdentity,
      platformId,
      identityId,
      platform,
      identity,
      isRolePlatformCredential,
      credentials,
    } = this.props

    if (!prevPlatformId && platformId && isEmpty(platform) && isRolePlatformCredential) {
      getPlatform({ platformId, credentials })
    }

    if (!prevIdentityId && identityId && isEmpty(identity)) {
      getIdentity({ identityId, credentials })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideUserSettingsMenu)
  }

  handleClickOutsideUserSettingsMenu = (event) => {
    const clickedInside = event?.target.closest('.dashboards-menu')

    if (!clickedInside) {
      this.setState({ showUserMenu: false })
      document.removeEventListener('click', this.handleClickOutsideUserSettingsMenu)
    }
  }

  handleClickOutsideDashboardsMenu = (event) => {
    const clickedInside = event?.target.closest('.dashboards-menu-container')

    if (!clickedInside) {
      this.setState({ showDashboardsMenu: false })
      document.removeEventListener('click', this.handleClickOutsideDashboardsMenu)
    }
  }

  toggleUserMenu = (e) => {
    const { showUserMenu } = this.state

    if (allowedKeyPressControls(e)) {
      this.setState({ showUserMenu: !showUserMenu })

      if (!showUserMenu) {
        document.addEventListener('click', this.handleClickOutsideUserSettingsMenu)
      } else {
        document.removeEventListener('click', this.handleClickOutsideUserSettingsMenu)
      }
    }
  }

  toggleDashboardsMenu = (e) => {
    const { showDashboardsMenu } = this.state

    if (allowedKeyPressControls(e)) {
      this.setState({ showDashboardsMenu: !showDashboardsMenu })

      if (!showDashboardsMenu) {
        document.addEventListener('click', this.handleClickOutsideDashboardsMenu)
      } else {
        document.removeEventListener('click', this.handleClickOutsideDashboardsMenu)
      }
    }
  }

  render() {
    const { showUserMenu, showDashboardsMenu } = this.state
    const { toggleUserMenu, toggleDashboardsMenu } = this

    return (
      <UserActions
        {...this.props}
        showUserMenu={showUserMenu}
        showDashboardsMenu={showDashboardsMenu}
        toggleUserMenu={toggleUserMenu}
        toggleDashboardsMenu={toggleDashboardsMenu}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActionsC)
