import paymentsAPI from 'utilities/api/paymentsAPI'
import { CERTIFICATE_SIGNING_REQUESTS } from 'constants/apiConstants'

const postCertificateSigningRequestsAPI = ({ values, credentials }) => {
  return paymentsAPI.post({
    values,
    credentials,
    path: CERTIFICATE_SIGNING_REQUESTS,
  })
}

export default postCertificateSigningRequestsAPI
