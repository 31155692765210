import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'
import Button from 'components/Shared/Button/Button'
import normalizeObject from 'utilities/api/normalizeObject'
import submitCreateComplianceFormTemplate from 'utilities/submit/submitCreateComplianceFormTemplate'
import get from 'lodash/get'
import map from 'lodash/map'
import slice from 'lodash/slice'
import split from 'lodash/split'
import { CREATE } from 'constants/language/languageConstants'

const CreateComplianceFormTemplateConfirmation = ({
  credentials,
  dispatch,
  goToStep = () => {},
  steps = [],
  formValues = {},
  skipStatus,
  leftButton = false,
  leftButtonLabel = 'Back',
  leftButtonAction = () => {},
  complianceFormTemplateId,
}) => {
  const inputSteps = slice(steps, 0, -1)
  const createComplianceFormTemplate = () => { submitCreateComplianceFormTemplate(formValues, dispatch, { credentials, complianceFormTemplateId }) }

  return (
    <div className='CreateComplianceFormTemplateConfirmation'>
      { map(inputSteps, (step, idx) => {
        const {
          name,
          dataKey,
          getDisplayData,
        } = step

        const skipStatusForStep = get(skipStatus, idx, false)
        const data = get(formValues, dataKey, {})
        const normalizeData = normalizeObject(data)
        const displayData = getDisplayData ? getDisplayData(normalizeData) : map(normalizeData, (value, label) => ({ value, label }))
        const sectionData = !skipStatusForStep ? displayData : []
        const key = split(name, ' ').join('-').toLowerCase()

        const actions = [
          {
            label: 'Edit',
            action: () => { goToStep(idx, null, true) },
          },
        ]

        return (
          <PageSection
            actions={actions}
            title={name}
            data={sectionData}
            key={key}
            className={key}
          />
        )
      })}

      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button variant='secondary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
        </div>

        <div className='right flex flex-end'>
          <Button onClick={createComplianceFormTemplate} label={CREATE} />
        </div>
      </div>
    </div>
  )
}

CreateComplianceFormTemplateConfirmation.propTypes = {
  credentials: PropTypes.object,
  dispatch: PropTypes.func,
  goToStep: PropTypes.func,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  complianceFormTemplateId: PropTypes.string,
}

export default CreateComplianceFormTemplateConfirmation
