import './FormFileUploaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactDropzone from 'components/Shared/Inputs/ReactDropzone/ReactDropzone'
import Button from 'components/Shared/Button/Button'
import { SUPPORTED_FILE_UPLOAD_DOCUMENT_TYPES } from 'constants/fileConstants'
import { DEFAULT_INSTRUCTIONS } from 'constants/fileUploaderConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  ALERT_ICON,
  ANGLE_DOWN_ICON,
} from 'constants/iconConstants'

import {
  CANCEL,
  PLEASE_UPLOAD_FILE,
  UPLOAD,
  DOCUMENT_TYPE,
  FILE_NAME,
  EMPTY_FILE_NAME_VALIDATION_ERROR,
  EMPTY_FILE_TYPE_VALIDATION_ERROR,
} from 'constants/language/languageConstants'

const FormFileUploader = ({
  displayFieldName = '',
  onFileChange = () => {},
  onFileUpload = () => {},
  setFileName = () => {},
  setDocumentType = () => {},
  closeModal = () => {},
  config = {},
  instructions = DEFAULT_INSTRUCTIONS,
  fileIcon,
  uploadedFile,
  fileType,
  noFileTypeSet = false,
  fileNameRequired = false,
  noInputtedFileName = false,
  inputtedFileNameTouched = false,
  onBlurFileName = () => {},
  selectDocumentTypeTouched = false,
}) => {
  const hasFileNameError = fileNameRequired && noInputtedFileName && inputtedFileNameTouched
  const hasFileTypeSelectionError = noFileTypeSet && selectDocumentTypeTouched
  const disabledUpload = isEmpty(uploadedFile) || noFileTypeSet || (fileNameRequired && noInputtedFileName)

  const fileNameClassNames = classnames('file-name-input InputField', {
    'has-error': hasFileNameError,
  })

  const fileTypeClassNames = classnames('document-type-select SelectField', {
    'has-error': hasFileTypeSelectionError,
  })

  return (
    <div className='FormFileUploader'>
      {fileType && <div className='instructions-description-msg'>{PLEASE_UPLOAD_FILE}: {displayFieldName}</div>}

      {/* TODO: look into making these use InputField & SelectField components */}
      {!fileType && (
        <div>
          <div className={fileNameClassNames}>
            <div className='flex items-center'>{FILE_NAME} {fileNameRequired && '*'}</div>

            <input
              id='file-name'
              onChange={setFileName}
              onBlur={onBlurFileName}
            />

            {hasFileNameError && <span className='file-name-error-msg'><i className={`fal fa-${ALERT_ICON} error-icon`} />{EMPTY_FILE_NAME_VALIDATION_ERROR}</span>}
          </div>

          <div className={fileTypeClassNames}>
            <div>{DOCUMENT_TYPE}</div>

            <div className='select-area'>
              <select
                id='select-document-type'
                onChange={setDocumentType}
              >
                {map(SUPPORTED_FILE_UPLOAD_DOCUMENT_TYPES, ({ label: optionLabel, value: optionValue }) => <option value={optionValue} key={optionValue || 'key'}>{optionLabel}</option>)}
              </select>

              {hasFileTypeSelectionError && <span className='file-type-error-msg'><i className={`fal fa-${ALERT_ICON} error-icon`} />{EMPTY_FILE_TYPE_VALIDATION_ERROR}</span>}
            </div>
            <span className={`arrow fa fa-${ANGLE_DOWN_ICON}`} />
          </div>
        </div>
      )}

      <ReactDropzone
        onFileChange={onFileChange}
        config={config}
        instructions={instructions}
        fileIcon={fileIcon}
      />

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={onFileUpload} disabled={disabledUpload} label={UPLOAD} />
      </div>
    </div>
  )
}

FormFileUploader.propTypes = {
  displayFieldName: PropTypes.string,
  onFileChange: PropTypes.func,
  onFileUpload: PropTypes.func,
  closeModal: PropTypes.func,
  fileIcon: PropTypes.string,
  config: PropTypes.object,
  instructions: PropTypes.array,
  uploadedFile: PropTypes.object,
  fileType: PropTypes.string,
  setFileName: PropTypes.func,
  setDocumentType: PropTypes.func,
  noFileTypeSet: PropTypes.bool,
  fileNameRequired: PropTypes.bool,
  noInputtedFileName: PropTypes.bool,
  inputtedFileNameTouched: PropTypes.bool,
  onBlurFileName: PropTypes.func,
  selectDocumentTypeTouched: PropTypes.bool,
}

export default FormFileUploader
