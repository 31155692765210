import React from 'react'
import PropTypes from 'prop-types'
import EditPlatformProcessorConfigFormC from 'components/Customer/Forms/EditPlatformProcessorConfigForm/EditPlatformProcessorConfigFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { SYSTEM_CONFIG } from 'constants/configConstants'

const EditPlatformProcessorConfigModal = ({
  credentials,
  actionType = '',
  configKey = '',
  configValue = '',
  sectionId = '',
  platformId = '',
  processorConfigId = '',
  processorType = '',
}) => {
  return (
    <GenericModal
      title={sectionId === SYSTEM_CONFIG ? 'Edit System Config' : 'Edit Application Config'}
      className='EditProcessorConfigModal'
      Component={EditPlatformProcessorConfigFormC}
      actionType={actionType}
      credentials={credentials}
      configKey={configKey}
      configValue={configValue}
      sectionId={sectionId}
      platformId={platformId}
      processorConfigId={processorConfigId}
      processorType={processorType}
    />
  )
}

EditPlatformProcessorConfigModal.propTypes = {
  credentials: PropTypes.object,
  actionType: PropTypes.string,
  configKey: PropTypes.string,
  configValue: PropTypes.string,
  sectionId: PropTypes.string,
  platformId: PropTypes.string,
  processorConfigId: PropTypes.string,
  processorType: PropTypes.string,
}

export default EditPlatformProcessorConfigModal
