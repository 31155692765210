import './SubscriptionScheduleS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const SubscriptionSchedule = ({
  tabs = [],
  subscriptionScheduleId = '',
  contextBarData = {},
  headerData = {},
}) => {
  return (
    <div className='SubscriptionSchedule'>
      <ContextBar {...contextBarData} />

      <HeaderV2C {...headerData} />

      <TabsC
        subscriptionScheduleId={subscriptionScheduleId}
        tabs={tabs}
      />
    </div>
  )
}

SubscriptionSchedule.propTypes = {
  tabs: PropTypes.array,
  subscriptionScheduleId: PropTypes.string,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default SubscriptionSchedule
