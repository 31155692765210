import './HostedCustomerOnboardingS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { FINIX_LOGO_BLACK } from 'constants/logoConstants'
import StartHostedCustomerOnboardingScreen from './StartHostedCustomerOnboardingScreen/StartHostedCustomerOnboardingScreen'
import HostedLiveAccountApplicationFlowC from 'components/Customer/Pages/HostedLiveAccountApplicationFlow/HostedLiveAccountApplicationFlowC'
import HostedCustomerOnboardingExpired from 'components/System/HostedCustomerOnboarding/HostedCustomerOnboardingExpired/HostedCustomerOnboardingExpired'
import Loader from 'components/Shared/Loader/Loader'

const HostedCustomerOnboarding = ({
  isFetching,
  hideWelcomePage,
  accessFormId = '',
  formExpirationString = '',
  isFormValid,
  accessFormCountry = '',
}) => {
  return (
    <div className='HostedCustomerOnboarding'>
      <div className='hosted-customer-onboarding-header flex center'>
        <div className='logo-container flex items-center space-between'>
          <img className='logo' src={FINIX_LOGO_BLACK} alt='finixLogo' />
          {isFormValid && (<div className='expiration-string'>{formExpirationString}</div>)}
        </div>
      </div>
      {isFetching && <Loader />}
      {!isFetching && !isFormValid && (<HostedCustomerOnboardingExpired />)}
      {!isFetching && isFormValid && (hideWelcomePage ?
        <HostedLiveAccountApplicationFlowC accessFormId={accessFormId} accessFormCountry={accessFormCountry} /> : <StartHostedCustomerOnboardingScreen accessFormId={accessFormId} accessFormCountry={accessFormCountry} />)}
    </div>
  )
}

HostedCustomerOnboarding.propTypes = {
  isFetching: PropTypes.bool,
  hideWelcomePage: PropTypes.bool,
  accessFormId: PropTypes.string,
  formExpirationString: PropTypes.string,
  isFormValid: PropTypes.bool,
  accessFormCountry: PropTypes.string,
}

export default HostedCustomerOnboarding
