import showModalAction from 'utilities/actions/showModalAction'
import { ORCHESTRATION_STATUS } from 'constants/modalConstants'

export const getPlatform = 'getPlatform'
export const createAPIUser = 'createAPIUser'
export const setAPIPermissions = 'setAPIPermissions'
export const createDashboardCredentials = 'createDashboardCredentials'
export const createDashbordUser = 'createDashbordUser'
export const updateDashboardUserCredentials = 'updateDashboardUserCredentials'
export const sendEmail = 'sendEmail'

export const createApplication = 'createApplication'
export const createBankAccount = 'createBankAccount'
export const createAssociatedIdentity = 'createAssociatedIdentity'
export const updateApplicationProcessor = 'updateApplicationProcessor'
export const createMerchantIdentity = 'createMerchantIdentity'
export const editMerchantIdentity = 'editMerchantIdentity'
export const editAssociatedIdentity = 'editAssociatedIdentity'

export const POST_USER_O_DATA = {
  [getPlatform]: {
    name: 'Get Plaform',
  },
  [createAPIUser]: {
    name: 'Create API User',
    dependency: [getPlatform]
  },
  [setAPIPermissions]: {
    name: 'Set API Permissions',
    dependency: [createAPIUser],
  },
  [createDashboardCredentials]: {
    name: 'Create Dashboard Credential',
    dependency: [createAPIUser],
  },
  [createDashbordUser]: {
    name: 'Find or Create Dashboard User',
    dependency: [createDashboardCredentials]
  },
  [updateDashboardUserCredentials]: {
    name: 'Update Dashboard User\'s Credentials',
    dependency: [createDashboardCredentials, createDashbordUser],
  },
  [sendEmail]: {
    name: 'Send Invitation Email',
    dependency: [createDashbordUser],
  },
}

export const POST_USER_O_ORDER = [
  getPlatform,
  createAPIUser,
  setAPIPermissions,
  createDashboardCredentials,
  createDashbordUser,
  updateDashboardUserCredentials,
  sendEmail,
]

export const POST_APPLICATION_O_DATA = {
  [createAPIUser]: {
    name: 'Create API User',
  },
  [createApplication]: {
    name: 'Create Application',
    dependency: [createAPIUser],
  },
  [createBankAccount]: {
    name: 'Create Bank Account',
    dependency: [createApplication],
  },
  [createAssociatedIdentity]: {
    name: 'Create Associated Identity',
    dependency: [createApplication]
  },
  [updateApplicationProcessor]: {
    name: 'Update Application Processor',
    dependency: [createApplication],
  },
}

export const POST_APPLICATION_O_ORDER = [
  createAPIUser,
  createApplication,
  createBankAccount,
  createAssociatedIdentity,
  updateApplicationProcessor,
]

export const POST_MERCHANT_IDENTITY_O_DATA = {
  [createMerchantIdentity]: {
    name: 'Create Merchant Identity',
  },
  [createAssociatedIdentity]: {
    name: 'Add Principals',
    dependency: [createMerchantIdentity]
  },
  [createBankAccount]: {
    name: 'Create Bank Account',
    dependency: [createMerchantIdentity],
  },
}

export const POST_MERCHANT_IDENTITY_O_ORDER = [
  createMerchantIdentity,
  createAssociatedIdentity,
  createBankAccount,
]

export const EDIT_MERCHANT_IDENTITY_O_DATA = {
  [editMerchantIdentity]: {
    name: 'Edit Merchant Identity',
  },
  [editAssociatedIdentity]: {
    name: 'Edit Principals',
  }
}

export const EDIT_MERCHANT_IDENTITY_O_ORDER = [
  editMerchantIdentity,
  editAssociatedIdentity,
]

export const createIdentityModalAction = showModalAction({
  modalType: ORCHESTRATION_STATUS,
  modalProps: {
    title: 'Create Identity - Status',
    orchestrationData: POST_MERCHANT_IDENTITY_O_DATA,
    orchestrationOrder: POST_MERCHANT_IDENTITY_O_ORDER,
  },
})

export const editIdentityModalAction = (isFlex) => showModalAction({
  modalType: ORCHESTRATION_STATUS,
  modalProps: {
    title: 'Edit Identity - Status',
    orchestrationData: EDIT_MERCHANT_IDENTITY_O_DATA,
    orchestrationOrder: EDIT_MERCHANT_IDENTITY_O_ORDER,
  },
})
