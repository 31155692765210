import './ApplicationAuthorizationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Authorizations/columnDescriptors'
import { AUTHORIZATIONS } from 'constants/linkConstants'
import { AUTHORIZATIONS_ICON } from 'constants/iconConstants'
import { getAuthorizationMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  AUTHORIZATION,
  GET_AUTHORIZATIONS,
} from 'constants/flowConstants'

const ApplicationAuthorizations = ({
  initialQueries = {},
  allowedFilters = [],
  authorizationPath = '',
  isFetching = false,
  applicationId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='ApplicationAuthorizations'>
      <TableC
        flow={GET_AUTHORIZATIONS}
        columnDescriptors={columnDescriptors}
        selector={getAuthorizationMerchantsBuyersSelector}
        initialQueries={initialQueries}
        linksKey={AUTHORIZATIONS}
        allowedFilters={allowedFilters}
        path={authorizationPath}
        isFetching={isFetching}
        icon={`fa fa-${AUTHORIZATIONS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Authorizations`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        entityType={AUTHORIZATION}
        showAdvancedExport
      />
    </div>
  )
}

ApplicationAuthorizations.propTypes = {
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  authorizationPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default ApplicationAuthorizations
