import './RevokeApplePayCertModalS.scss'
import React from 'react'
import { FINIX_SUPPORT_HELP_URL } from 'constants/urlConstants'

const RevokeApplePayCertModal = () => {
  return (
    <div className='RevokeApplePayCertModal'>
      <h4>Revoke iOS app certificate</h4>
      <div className='msg'>Please contact support if you would like to revoke this certificate.</div>

      <div className='flex flex-end'>
        <a className='text-link' href={FINIX_SUPPORT_HELP_URL} target='blank'>Contact Support</a>
      </div>
    </div>
  )
}

export default RevokeApplePayCertModal
