import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import CreateTransactionReviewForm from 'components/Customer/Forms/CreateTransactionReviewForm/CreateTransactionReviewForm'
import getIdentityMerchantsRequest from 'utilities/actions/get/getIdentityMerchantsRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatMoney from 'utilities/formatters/formatMoney'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getMany from 'utilities/get/getMany'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { CREATE_TRANSACTION_REVIEW_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import { find } from 'lodash'

import {
  BANK_ACCOUNT,
  getCardBrand,
} from 'constants/bankConstants'

import {
  getIdentitySelector,
  getMerchantSelector,
  getPaymentInstrumentSelector,
  getFormValues,
} from 'state-layer/selectors'

import {
  L2_ENABLED,
  L3_ENABLED,
} from 'constants/processorConstants'

import {
  USD,
  COUNTRY_TO_CURRENCY_NAME_MAP,
} from 'constants/currencyConstants'

import {
  BUYER_NAME,
  COUNTRY,
  DESCRIPTION,
  EMAIL,
  EXPIRATION_DATE,
  NAME_ON_CARD,
  PHONE,
  REVIEW_PAYMENT_STATEMENT_DESCRIPTOR_TOOLTIP,
  STATEMENT_DESCRIPTOR,
  TOTAL_PURCHASE_AMOUNT,
  ZIP_POSTAL_CODE,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  DESTINATION_POSTAL_CODE,
  SHIP_FROM_POSTAL_CODE,
  TOTAL_SHIPPING_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT,
  CUSTOMS_DUTY_AMOUNT,
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  BANK_CODE,
  ACCOUNT_TYPE,
  MERCHANT_ACCOUNT,
  CARD_NUMBER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const isFetchingMerchantData = get(state, `merchantsR.${FETCHING}`)
  const isFetchingReceiptData = get(state, `receiptsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const formValues = get(props, 'formValues')
  const isSale = get(props, 'isSale')
  const createPaymentReviewFormValues = get(state, `form.${CREATE_TRANSACTION_REVIEW_FORM}.values`)
  const createPaymentFormValues = get(formValues, 'createPayment')
  const merchantIdentityId = get(props, 'identityId')
  const merchantIdentity = getIdentitySelector(state, merchantIdentityId)
  const merchantBusinessName = get(merchantIdentity, 'businessName')
  const merchantIdentityBusinessName = get(merchantIdentity, 'displayBusinessName')
  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantCountry = get(merchant, 'country', 'USA')
  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[merchantCountry] || USD

  const [
    formTotalAmount,
    formPaymentDescription,
    formPaymentMethod,
    buyerName,
    salesTaxAmount,
    customerReferenceNumber,
    additionalDataSelection,
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    totalDiscountAmount,
    totalCustomsDutyAmount,
    levelThreeItems,
  ] = getMany(createPaymentFormValues, [
    'amount',
    'description',
    'paymentMethod',
    'buyerName',
    'salesTaxAmount',
    'customerReferenceNumber',
    'additionalDataSelection',
    'destinationPostalCode',
    'shipFromPostalCode',
    'shippingAmount',
    'totalDiscountAmount',
    'totalCustomsDutyAmount',
    'levelThreeItems',
  ])

  const [
    sendReceiptToBuyer = false,
  ] = getFormValues(state, CREATE_TRANSACTION_REVIEW_FORM, [
    'sendReceiptToBuyer',
  ])

  const formPaymentInstrumentId = get(formPaymentMethod, 'value')
  const paymentInstrument = getPaymentInstrumentSelector(state, formPaymentInstrumentId)
  const buyerIdentityId = get(buyerName, 'value')
  const buyerIdentity = getIdentitySelector(state, buyerIdentityId)
  const additionalDataSelectedValue = get(additionalDataSelection, 'value')
  const levelTwoEnabled = isEqual(additionalDataSelectedValue, L2_ENABLED)
  const levelThreeEnabled = isEqual(additionalDataSelectedValue, L3_ENABLED)
  const isACHTransaction = isEqual(get(paymentInstrument, 'instrumentType'), BANK_ACCOUNT)

  const [
    paymentInstrumentId,
    name,
    maskedFullCardNumber,
    expirationDate,
    addressCountry,
    bankAccountCountry,
    postalCode,
    maskedAccountNumber,
    bankCode,
    displayAccountType,
    brand,
  ] = getMany(paymentInstrument, [
    'id',
    'name',
    'maskedFullCardNumber',
    'expirationDate',
    'addressCountry',
    'displayCountry',
    'address.postalCode',
    'maskedAccountNumber',
    'bankCode',
    'displayAccountType',
    'brand',
  ])

  const [
    fullName,
    displayPhone,
    email,
  ] = getMany(buyerIdentity, [
    'fullName',
    'displayPhone',
    'email',
  ])

  const cardBrandIcon = getCardBrand(brand)

  const purchaseDetailsSectionData = convertPageSectionDataToV2([
    {
      label: TOTAL_PURCHASE_AMOUNT,
      value: formatMoney({ amount: formTotalAmount, currency, showCurrencyCode: true }),
    },
    {
      label: DESCRIPTION,
      value: formPaymentDescription,
    },
    {
      label: MERCHANT_ACCOUNT,
      value: merchantBusinessName,
    },
    {
      label: STATEMENT_DESCRIPTOR,
      value: merchantIdentityBusinessName,
      tooltip: REVIEW_PAYMENT_STATEMENT_DESCRIPTOR_TOOLTIP,
      tooltipPosition: 'right',
    },
  ], 1)

  const paymentDetailsSectionData = convertPageSectionDataToV2([
    {
      label: BUYER_NAME,
      value: fullName,
    },
    {
      label: EMAIL,
      value: email,
    },
    {
      label: PHONE,
      value: displayPhone,
    },
    {
      label: NAME_ON_CARD,
      value: name,
      condition: !!maskedFullCardNumber,
    },
    {
      label: CARD_NUMBER,
      value: <div className='flex items-center'><i className={cardBrandIcon} /><div>&nbsp;{maskedFullCardNumber}</div></div>,
      condition: !!maskedFullCardNumber,
    },
    {
      label: EXPIRATION_DATE,
      value: expirationDate,
      condition: !!maskedFullCardNumber,
    },
    {
      label: ZIP_POSTAL_CODE,
      value: postalCode,
      condition: !!maskedFullCardNumber,
    },
    {
      label: COUNTRY,
      value: addressCountry,
      condition: !!maskedFullCardNumber,
    },
    {
      label: ACCOUNT_HOLDER_NAME,
      value: name,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: maskedAccountNumber,
      condition: !!maskedAccountNumber,
    },
    {
      label: BANK_CODE,
      value: bankCode,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_TYPE,
      value: displayAccountType,
      condition: !!maskedAccountNumber,
    },
    {
      label: COUNTRY,
      value: bankAccountCountry,
      condition: !!maskedAccountNumber,
    },
  ], 1)

  const levelTwoAdditionalDataSectionData = convertPageSectionDataToV2([
    {
      label: SALES_TAX_AMOUNT,
      value: formatMoney({ amount: salesTaxAmount, currency, showCurrencyCode: true }),
    },
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
  ], 1)

  const levelThreeAdditionalDataSectionData = convertPageSectionDataToV2([
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
    {
      label: DESTINATION_POSTAL_CODE,
      value: destinationPostalCode,
    },
    {
      label: SHIP_FROM_POSTAL_CODE,
      value: shipFromPostalCode,
    },
    {
      label: TOTAL_SHIPPING_AMOUNT,
      value: formatMoney({ amount: shippingAmount, currency, showCurrencyCode: true }),
    },
    {
      label: TOTAL_DISCOUNT_AMOUNT,
      value: formatMoney({ amount: totalDiscountAmount, currency, showCurrencyCode: true }),
    },
    {
      label: CUSTOMS_DUTY_AMOUNT,
      value: formatMoney({ amount: totalCustomsDutyAmount, currency, showCurrencyCode: true }),
    },
  ], 1)

  const today = new Date().toLocaleDateString()

  const achLegalText = (
    <div className='p-3 text'>{`By consenting to process this payment, you authorize ${merchantIdentityBusinessName} to initiate an automated clearing house (ACH) one-time debit in your name to your bank account on ${today}.
    A $${formTotalAmount} transaction will be presented to your financial institution by the next business day. You further agree that once the payment is processed, you may not revoke this authorization or cancel this payment.
    Do I have your authorization to process this payment?`}
    </div>
  )

  const initialValues = merge(
    {},
    createPaymentFormValues,
    createPaymentReviewFormValues,
    { sendReceiptToBuyer: true },
  )


  return {
    credentials,
    isFetching,
    purchaseDetailsSectionData,
    paymentDetailsSectionData,
    levelTwoAdditionalDataSectionData,
    levelThreeAdditionalDataSectionData,
    merchantIdentityId,
    paymentInstrumentId,
    merchantIdentity,
    isFetchingMerchantData,
    merchantId,
    levelTwoEnabled,
    levelThreeEnabled,
    levelThreeItems,
    isSale,
    isACHTransaction,
    achLegalText,
    currency,
    sendReceiptToBuyer,
    isFetchingReceiptData,
    currentUser,
    initialValues,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentityMerchants: ({ identityId, credentials }) => { dispatch(getIdentityMerchantsRequest({ identityId, credentials })) },
    getIdentity: ({ identityId, credentials, meta }) => { dispatch(getIdentityRequest({ identityId, credentials, meta })) },
    changeFormValue: (form, field, value) => dispatch(change(form, field, value)),
  }
}

class CreateTransactionReviewFormC extends Component {
  componentDidMount() {
    const {
      buyerIdentityId,
      getIdentity,
      credentials,
      merchantIdentityId,
      getIdentityMerchants,
    } = this.props

    if (merchantIdentityId) {
      getIdentityMerchants({ identityId: merchantIdentityId, credentials })
    }

    if (buyerIdentityId) {
      getIdentity({ identityId: buyerIdentityId, credentials, meta: { skipOrchestration: true } })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sendReceiptToBuyer,
      changeFormValue,
      paymentDetailsSectionData,
    } = this.props

    const {
      sendReceiptToBuyer: prevSendReceiptToBuyer,
    } = prevProps

    const email = find(get(paymentDetailsSectionData, '[0].data'), ({ label }) => label === EMAIL)
    const emailValue = get(email, 'value')
    if (!prevSendReceiptToBuyer && sendReceiptToBuyer && emailValue) {
      changeFormValue(CREATE_TRANSACTION_REVIEW_FORM, 'buyerEmails', [{ label: emailValue, value: emailValue }])
    }
  }

  render() {
    return (
      <CreateTransactionReviewForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionReviewFormC)
