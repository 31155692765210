import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_ENROLLMENT,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const getSubscriptionEnrollmentAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    path: SUBSCRIPTION_ENROLLMENT({ subscriptionEnrollmentId: id }),
    service: SUBSCRIPTION,
  })
}

export default getSubscriptionEnrollmentAPI
