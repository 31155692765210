import { POST_CUSTOMER_ONBOARDING_FORM_LINKS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getCustomerOnboardingFormLinkRequest = ({
  customerOnboardingFormId,
  credentials,
  meta,
}) => createAction({
  type: POST_CUSTOMER_ONBOARDING_FORM_LINKS_F_REQUEST,
  id: customerOnboardingFormId,
  credentials,
  meta,
})

export default getCustomerOnboardingFormLinkRequest
