import './PayoutLinkCreatedConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { goToPath } from 'state-layer/history'
import DictionaryListV2 from 'components/Customer/Shared/Page/DictionaryList/DictionaryListV2'
import Button from 'components/Shared/Button/Button'
import copyToClipboard from 'utilities/copyToClipboard'
import { CHECK_ICON } from 'constants/iconConstants'
import { COMPANY_PATH } from 'constants/pathConstants'

import {
  CREATE_NEW_PAYOUT_LINK,
  PAYOUT_LINK_CREATED_SUCCESSFULLY,
  PAYOUT_LINK_DETAILS, VIEW_PAYOUT_LINK_DETAILS,
} from 'constants/language/languageConstants'

const PayoutLinkCreatedConfirmation = ({
  payoutLinkData = [],
  linkUrl = '',
  goToNewPayoutLinkForm = () => {},
  payoutLinkPath = '',
  isFetching = false,
  credentialId,
}) => {
  return (
    <div className='PayoutLinkCreatedConfirmation'>
      <div className='banner flex'>
        <i className={`check-icon fa fa-${CHECK_ICON}`} />

        <div>
          <h6>{PAYOUT_LINK_CREATED_SUCCESSFULLY}</h6>
          <p className='p-2'><span className='link' onClick={() => copyToClipboard(linkUrl)}>Copy Link URL</span> to send to your recipient. Customize the look of your payout links under <span className='link' onClick={() => goToPath({ pathname: COMPANY_PATH({ credentialId }), queries: { tab: 'branding' } })}>Branding</span>.</p>
        </div>
      </div>

      { !isFetching && (
        <div className='details'>
          <h5>{PAYOUT_LINK_DETAILS}</h5>

          <DictionaryListV2 data={payoutLinkData} className='payout-link-details' />

          <div className='buttons flex'>
            <Button label={CREATE_NEW_PAYOUT_LINK} onClick={goToNewPayoutLinkForm} />
            <Link to={payoutLinkPath}><Button variant='ghost' label={VIEW_PAYOUT_LINK_DETAILS} /></Link>
          </div>
        </div>
      )}
    </div>
  )
}

PayoutLinkCreatedConfirmation.propTypes = {
  payoutLinkData: PropTypes.array,
  linkUrl: PropTypes.string,
  goToNewPayoutLinkForm: PropTypes.func,
  payoutLinkPath: PropTypes.string,
  isFetching: PropTypes.bool,
  credentialId: PropTypes.string,
}

export default PayoutLinkCreatedConfirmation
