import React, { Component } from 'react'
import { connect } from 'react-redux'
import Payments from './Payments'
import DropdownButton from 'components/Customer/Shared/DropdownButton/DropdownButton'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { isDisbursementsAndPaymentsDashboard } from 'utilities/is/isDashboardType'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import { FETCHING } from 'constants/reducerConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import size from 'lodash/size'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  SHOW_PAYMENT_LINKS,
  SHOW_VIRTUAL_TERMINAL,
} from 'constants/featureFlagConstants'

import {
  LIVE_ENVIRONMENT,
  QA_ENVIRONMENT,
} from 'constants/environmentConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

import {
  CREATE_PAYMENT_LINK_PATH,
  CREATE_PAYMENT_PATH,
  PAYMENTS_PATH,
} from 'constants/pathConstants'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  PAYMENT,
  CREATE_PAYMENT,
  CREATE_PAYMENT_LINK,
  CREATE_MANUAL_PAYMENT,
} from 'constants/language/languageConstants'

import {
  TRANSFER,
  FAILED,
  SUCCEEDED,
  DEBIT,
} from 'constants/transferConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  INSTRUMENT_FINGERPRINT,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
  IDEMPOTENCY_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_FINGERPRINT,
  DEVICE_ID,
  IDEMPOTENCY_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  convertFilterToResourceIdFilter(PAYMENT),
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const isDisbursementsAndPayments = isDisbursementsAndPaymentsDashboard(state)
  const currentUser = getCurrentUser(state)
  const credentialId = get(credentials, 'id')
  const paymentPath = PAYMENTS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const stateQuery = getUrlQuery('state')
  const isFailedQuickFilter = stateQuery === FAILED
  const isSucceededQuickFilter = stateQuery === SUCCEEDED
  const hiddenFailedFields = isFailedQuickFilter ? ['readyToSettleAt'] : []
  const hiddenSucceededFields = isSucceededQuickFilter ? ['failureCode'] : []
  const conditionalHiddenExportFields = concat(hiddenFailedFields, hiddenSucceededFields)
  const hiddenFilters = get(props, 'hiddenFilters', [])
  const mergedFilters = filter(allowedFilters, ({ field }) => !includes(hiddenFilters, field))
  const applicationId = get(currentUser, 'applicationId')
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, LIVE_ENVIRONMENT) ? [LITLE_V1, FINIX_V1] : [DUMMY_V1]
  const createPaymentEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))

  return {
    quickFilters,
    allowedFilters: mergedFilters,
    initialQueries: mergedQueries,
    initialFilters: merge(
      {},
      createdLast3MonthQueries(),
      { type: { eq: isDisbursementsAndPayments ? DEBIT : TRANSFER } },
    ),
    isFetching,
    hiddenExportFields: conditionalHiddenExportFields,
    paymentPath,
    applicationId,
    credentials,
    createPaymentEnabledApplicationMerchants,
  }
}

class PaymentsC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    const {
      applicationId: prevApplicationId,
    } = prevProps

    if (applicationId && prevApplicationId !== applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      credentials,
      createPaymentEnabledApplicationMerchants,
    } = this.props

    const credentialId = get(credentials, 'id')
    const environment = get(credentials, 'environment')
    const isFlex = isFlexPlatform()
    const hasOneMerchant = size(createPaymentEnabledApplicationMerchants) === 1
    const showPaymentLinks = getFeatureFlag(SHOW_PAYMENT_LINKS) && size(createPaymentEnabledApplicationMerchants) > 0
    const showVirtualTerminal = getFeatureFlag(SHOW_VIRTUAL_TERMINAL)

    const createPaymentButtonActions = [
      {
        label: CREATE_MANUAL_PAYMENT,
        className: 'create-manual-payment',
        link: CREATE_PAYMENT_PATH({ credentialId }),
        condition: isFlex && environment !== QA_ENVIRONMENT && isAdministratorRole() && showVirtualTerminal,
      },
      {
        label: CREATE_PAYMENT_LINK,
        className: 'create-payment-link',
        action: () => goToPath({
          pathname: CREATE_PAYMENT_LINK_PATH({ credentialId }),
          queries: hasOneMerchant ? { merchantId: get(createPaymentEnabledApplicationMerchants, '[0].id') } : {},
        }),
        condition: isFlex && environment !== QA_ENVIRONMENT && isAdministratorRole() && showPaymentLinks,
      },
    ]

    const filteredCreatePaymentButtonsActions = filter(createPaymentButtonActions, ({ condition }) => condition)

    let actions = [
      {
        Component: (
          <DropdownButton
            label={CREATE_PAYMENT}
            actions={createPaymentButtonActions}
          />
        ),
        condition: isFlex && environment !== QA_ENVIRONMENT && isAdministratorRole() && (showPaymentLinks || showVirtualTerminal),
      },
    ]

    if (size(filteredCreatePaymentButtonsActions) === 1) {
      actions = createPaymentButtonActions
    }

    return (
      <Payments
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsC)
