import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { getApplePayCertsSelector } from 'state-layer/selectors'
import { GET_CERTIFICATE_SIGNING_REQUESTS } from 'constants/flowConstants'
import { MOBILE_ICON } from 'constants/iconConstants'
import { APPLE_PAY_IOS_APPLICATIONS_TITLE } from 'constants/language/languageConstants'

const ApplePayCertManagement = ({
  flowValues = {},
  initialQueries = {},
  isFetching = false,
  actions = [],
  selectedItemsKey,
  selectedItemActions = [],
}) => {
  return (
    <div className='ApplePayCertManagement'>
      <TableC
        title='iOS applications'
        flow={GET_CERTIFICATE_SIGNING_REQUESTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        initialQueries={initialQueries}
        selector={getApplePayCertsSelector}
        isFetching={isFetching}
        actions={actions}
        fileNameCSV="Apple_Pay_iOS_Applications"
        fileResourceTitleCSV={APPLE_PAY_IOS_APPLICATIONS_TITLE}
        emptyMessage='No Applications Registered'
        icon={`fa fa-${MOBILE_ICON}`}
        selectedItemsKey={selectedItemsKey}
        selectedItemActions={selectedItemActions}
      />
    </div>
  )
}

ApplePayCertManagement.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  selectedItemActions: PropTypes.array,
  selectedItemsKey: PropTypes.string,
}

export default ApplePayCertManagement
