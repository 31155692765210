import frontendSettlementEntryModel from 'utilities/create/models/frontendSettlementEntryModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSettlementEntriesModel = ({ data: settlementEntries }) => {
  const settlementEntryModels = map(settlementEntries, (settlementEntry) => frontendSettlementEntryModel({ data: settlementEntry }))

  return keyBy(settlementEntryModels, 'id')
}

export default frontendSettlementEntriesModel
