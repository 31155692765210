import './SelectInputS.scss'
import React from 'react'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import get from 'lodash/get'

const SelectInput = ({
  name,
  options,
  classNames,
  onChange,
  placeholder,
}) => {
  const handleChange = (event) => {
    onChange(get(event, 'target.value'))
  }

  return (
    <div className='SelectInput flex'>
      <select
        name={name}
        className={classNames}
        onChange={handleChange}
      >
        { placeholder && <option value='' disabled selected>{placeholder}</option> }
        {map(options, ({ label, value }) => (
          <option value={value} key={kebabCase(`${name}-option-${value}`)}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectInput
