import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACCESS_FORM_FILE } from 'constants/apiConstants'
import get from 'lodash/get'

const getAccessFormFileAPI = ({ id, credentials, values, meta }) => {
  const accessFormId = get(values, 'accessFormId')

  return dashboardAPI.get({
    meta,
    credentials,
    path: ACCESS_FORM_FILE({ fileId: id, accessFormId }),
  })
}

export default getAccessFormFileAPI
