import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationPayouts from './ApplicationPayouts'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { PAYMENTS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TRANSFER_TYPE,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter('Transfer'),
  TRANSFER_TYPE,
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `applicationTransfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = PAYMENTS_PATH({ credentialId })

  const flowValues = {
    applicationId,
  }

  const initialQueries = {
    application_id: applicationId,
  }

  return {
    applicationId,
    flowValues,
    isFetching,
    paymentPath,
    initialQueries,
    allowedFilters,
    quickFilters,
  }
}

class ApplicationPayoutsC extends Component {
  render() {
    return (
      <ApplicationPayouts {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationPayoutsC)
