import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import { PCI_COMPLIANCE_LEARN_MORE_LINK } from 'constants/urlConstants'
import { PCI_COMPLIANCE_TITLE } from 'constants/language/languageConstants'

const Compliance = ({
  tabs = [],
  subTitle = '',
}) => {
  return (
    <div className='Compliance'>
      <HeaderV2C
        title={PCI_COMPLIANCE_TITLE}
        subTitle={subTitle}
        learnMoreLink={PCI_COMPLIANCE_LEARN_MORE_LINK}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

Compliance.propTypes = {
  tabs: PropTypes.array,
  subTitle: PropTypes.string,
}

export default Compliance
