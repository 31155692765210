import postApplicationUserAPI from 'api/finix/post/postApplicationUserAPI'
import postIdentityUserAPI from 'api/finix/post/postIdentityUserAPI'
import postCredentialsAPI from 'api/dashboard/post/postCredentialsAPI'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { APPLICATION } from 'constants/flowConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getDashboardUser from 'utilities/get/getDashboardUser'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * postDashboardUserO ({
  id,
  values,
  credentials,
}) {
  const {
    name,
    email,
    type,
    identityId,
    applicationId,
  } = values

  const {
    paymentFacilitatorEnabled,
    pushToCardEnabled,
  } = credentials

  const dashboardId = getCurrentDashboardId()
  const { host } = credentials
  const creatorEmail = get(getDashboardUser(), CLIENT_EMAIL)

  const tagsPayload = {
    tags: {
      name: `created by ${creatorEmail}`,
    },
  }

  const isApplication = type === APPLICATION

  const userPayload = isApplication
    ? tagsPayload
    : merge({}, tagsPayload, {
      identity: identityId,
      application_id: applicationId,
    })

  const resourceUserAPI = isApplication ? postApplicationUserAPI : postIdentityUserAPI
  const { data: resourceUserResponse } = yield resourceUserAPI({ values: userPayload, credentials, id })

  if (!resourceUserResponse) {
    return createErrorResponse(`Error on creating user for ${type}:${id}`)
  }

  const { id: username, password } = resourceUserResponse
  const credentialsResponseRaw = yield postCredentialsAPI({
    values: {
      username,
      password,
      email_address: email,
      dashboard_host: host,
      credential_name: name,
      dashboard_id: dashboardId,
      payment_facilitator_enabled: paymentFacilitatorEnabled,
      push_to_card_enabled: pushToCardEnabled,
    },
  })

  const credentialsResponse = get(credentialsResponseRaw, 'data')

  if (!credentialsResponse) {
    return createErrorResponse(`Error on creating credentials for ${name}`)
  }

  const {
    dashboard_user_id: userId,
    created_at: createdAt,
  } = credentialsResponse

  const userResponse = {
    id: get(credentialsResponse, 'dashboard_user_id'),
    email_address: email,
    created_at: get(credentialsResponse, 'created_at'),
  }

  return createSuccessResponse({
    data: [userResponse],
  })
}

export default co.wrap(postDashboardUserO)
