import createAction from 'utilities/actions/createAction'
import { PATCH_UNDERWRITING_REVIEW_F_REQUEST } from 'constants/flowConstants'

const patchUnderwritingReviewRequest = ({
  id,
  subjectId,
  assignee,
  state,
  reasons,
  credentials,
  meta,
}) => createAction({
  type: PATCH_UNDERWRITING_REVIEW_F_REQUEST,
  credentials,
  values: {
    id,
    subjectId,
    assignee,
    state,
    reasons,
  },
  meta,
})

export default patchUnderwritingReviewRequest
