import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import { DEV_MODE } from 'config'
import createSagaMiddleware from 'redux-saga'
import loggerMiddleware from 'utilities/middleware/logger'
import thunkMiddleware from 'redux-thunk'
import monitorReducersEnhancer from 'utilities/enhancers/monitorReducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createHistory } from 'state-layer/history'
import { routerMiddleware } from 'react-router-redux'
import isEmpty from 'lodash/isEmpty'

let store = {}

const configureStore = ({
  initialState = {},
  reducers,
  sagas,
}) => {
  const history = createHistory()
  const sagaMiddleware = createSagaMiddleware()
  const routerHistoryMiddleware = routerMiddleware(history)
  const devMiddleware = DEV_MODE ? [loggerMiddleware] : []

  const middlewareList = [
    thunkMiddleware,
    sagaMiddleware,
    routerHistoryMiddleware,
    ...devMiddleware,
  ]

  const middlewareEnhancer = applyMiddleware(...middlewareList)
  const enhancers = DEV_MODE ? [middlewareEnhancer, monitorReducersEnhancer] : [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)
  store = createStore(reducers, initialState, composedEnhancers)

  sagaMiddleware.run(sagas)

  return store
}

export const getStore = () => store

export const getState = () => (!isEmpty(store) ? store.getState() : {})

export const getDispatch = () => (!isEmpty(store) ? store.dispatch : () => {})


export default configureStore
