import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import UploadPaymentProcessingCertForm from './UploadPaymentProcessingCertForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getPendingCertId } from 'state-layer/selectors'
import { UPLOAD_PAYMENT_PROCESSING_CERT_FORM } from 'constants/formConstants'
import { APPLE_PAY_UPLOAD_PAYMENT_PROCESSING_CERT_INSTRUCTION } from 'constants/language/languageConstants'
import get from 'lodash/get'

const config = {
  multiple: false,
  maxSize: 2000000,
  accept: '.cer',
}

const instructions = [
  {
    name: 'main',
    message: APPLE_PAY_UPLOAD_PAYMENT_PROCESSING_CERT_INSTRUCTION,
  },
  {
    name: 'sub',
    message: 'Accept: .cer',
  },
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const csrId = get(props, 'csrId', getPendingCertId(state))
  const formSelector = formValueSelector(UPLOAD_PAYMENT_PROCESSING_CERT_FORM)
  const uploadedFile = formSelector(state, 'file')

  return {
    credentials,
    config,
    instructions,
    csrId,
    uploadedFile,
  }
}

class UploadPaymentProcessingCertFormC extends Component {
  render() {
    return (
      <UploadPaymentProcessingCertForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UploadPaymentProcessingCertFormC)
