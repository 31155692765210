import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT_FUNDING_TRANSFER,
  SETTLEMENTS_BASE,
  FINIX_VERSION,
  FINIX_VERSION_2022_02_01,
} from 'constants/apiConstants'

const getInstantPayoutAPI = ({ values, credentials, meta }) => {
  const { settlementId, fundingTransferAttemptId } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: SETTLEMENT_FUNDING_TRANSFER({ settlementId, fundingTransferAttemptId }),
    service: SETTLEMENTS_BASE,
    headers: {
      [FINIX_VERSION]: FINIX_VERSION_2022_02_01,
    },
  })
}

export default getInstantPayoutAPI
