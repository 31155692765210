import replace from 'lodash/replace'

const formatUSAPostalCode = (value) => {
  if (!value) {
    return value
  }

  const onlyNums = replace(value, /[^\d*]/g, '')

  return onlyNums.slice(0, 5)
}

export default formatUSAPostalCode
