import paymentsAPI from 'utilities/api/paymentsAPI'
import { TRANSFERS } from 'constants/apiConstants'

const getTransfersAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: TRANSFERS,
    queries,
    credentials,
    service: TRANSFERS,
  })
}

export default getTransfersAPI
