import formatAddress from 'utilities/formatters/formatAddress'
import formatDateObject from 'utilities/formatters/formatDateObject'
import { BUSINESS_ADDRESS } from 'constants/language/languageConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const IdentityBusinessFields = [
  {
    name: 'businessName',
    label: 'Legal Business Name',
  },
  {
    name: 'doingBusinessAs',
    label: 'Doing Business As',
  },
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'businessPhone',
    label: 'Business Phone',
    highlight: true,
  },
  {
    name: 'businessAddress',
    label: BUSINESS_ADDRESS,
    formatter: (address) => formatAddress({ address }),
  },
  {
    name: 'businessType',
    label: 'Business Type',
    highlight: true,
  },
  {
    name: 'ownershipType',
    label: 'Ownership Type',
  },
  {
    name: 'incorporationDate',
    label: 'Date of Incorporation',
    formatter: (date) => formatDateObject({ date }),
  },
  {
    name: 'businessTaxId',
    label: 'Tax ID/EIN/SSN',
  },
]

const IdentityBusinessDisplayData = (values) => map(IdentityBusinessFields, ({ name, label, formatter, highlight = false }) => ({
  label,
  value: formatter ? formatter(get(values, name)) : get(values, name),
  highlight,
}))

export default IdentityBusinessDisplayData
