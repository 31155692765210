import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import WebhookFailureDetails from './WebhookFailureDetails'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { VIEW_WEBHOOK_LOGS } from 'constants/language/languageConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import isEqual from 'lodash/isEqual'

import {
  DEVELOPER_PATH,
  APPLICATION_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    webhookId,
    applicationId,
  ] = getMany(props, [
    'webhookId',
    'applicationId',
  ])

  const pathName = get(state, 'routing.locationBeforeTransitions.pathname')
  const topLevelPage = get(split(pathName, '/'), '[2]')
  const fromApplicationWebhook = isRolePlatform({ credentials }) && isEqual(topLevelPage, 'applications')

  const redirectPath = fromApplicationWebhook ?
    `${APPLICATION_PATH({ credentialId, applicationId })}?tab=developer&secondaryTab=webhook-logs&webhook_id=${webhookId}` :
    `${DEVELOPER_PATH({ credentialId })}?tab=webhook-logs&webhook_id=${webhookId}`

  const webhookLogsLink = <Link className='text-link' to={redirectPath}>{VIEW_WEBHOOK_LOGS}</Link>

  return {
    credentials,
    webhookLogsLink,
  }
}

class WebhookFailureDetailsC extends Component {
  render() {
    return (
      <WebhookFailureDetails
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(WebhookFailureDetailsC)
