import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'

const backendFeeProfileModel = ({ values }) => {
  const [
    id,
    achBasisPoints,
    achCreditReturnFixedFee,
    achDebitReturnFixedFee,
    achFixedFee,
    americanExpressAssessmentBasisPoints,
    americanExpressBasisPoints,
    americanExpressChargeInterchange,
    americanExpressExternallyFundedBasisPoints,
    americanExpressExternallyFundedFixedFee,
    americanExpressFixedFee,
    ancillaryFixedFeePrimary,
    ancillaryFixedFeeSecondary,
    basisPoints,
    chargeInterchange,
    dinersClubBasisPoints,
    dinersClubChargeInterchange,
    dinersClubFixedFee,
    discoverAssessmentsBasisPoints,
    discoverBasisPoints,
    discoverChargeInterchange,
    discoverDataUsageFixedFee,
    discoverExternallyFundedBasisPoints,
    discoverExternallyFundedFixedFee,
    discoverFixedFee,
    discoverNetworkAuthorizationFixedFee,
    disputeFixedFee,
    disputeInquiryFixedFee,
    externallyFundedBasisPoints,
    externallyFundedFixedFee,
    fixedFee,
    jcbBasisPoints,
    jcbChargeInterchange,
    jcbFixedFee,
    mastercardAcquirerFeesBasisPoints,
    mastercardAssessmentsOver1kBasisPoints,
    mastercardAssessmentsUnder1kBasisPoints,
    mastercardBasisPoints,
    mastercardChargeInterchange,
    mastercardFixedFee,
    qualifiedTiers,
    roundingMode,
    tags,
    visaAcquirerProcessingFixedFee,
    visaAssessmentsBasisPoints,
    visaBaseIICreditVoucherFixedFee,
    visaBaseIISystemFileTransmissionFixedFee,
    visaBasisPoints,
    visaChargeInterchange,
    visaCreditVoucherFixedFee,
    visaFixedFee,
    visaKilobyteAccessFixedFee,
    cardCrossBorderBasisPoints,
    cardCrossBorderFixedFee,
    achBasisPointsFeeLimit,
    visaOverrideEnabled,
    mastercardOverrideEnabled,
    amexOverrideEnabled,
    discoverOverrideEnabled,
    jcbOverrideEnabled,
    dinersClubOverrideEnabled,
    chargeDuesAssessments,
    visaChargeDuesAssessments,
    mastercardChargeDuesAssessments,
    americanExpressChargeDuesAssessments,
    discoverChargeDuesAssessments,
  ] = getMany(values, [
    'id',
    'achBasisPoints',
    'achCreditReturnFixedFee',
    'achDebitReturnFixedFee',
    'achFixedFee',
    'americanExpressAssessmentBasisPoints',
    'americanExpressBasisPoints',
    'americanExpressChargeInterchange',
    'americanExpressExternallyFundedBasisPoints',
    'americanExpressExternallyFundedFixedFee',
    'americanExpressFixedFee',
    'ancillaryFixedFeePrimary',
    'ancillaryFixedFeeSecondary',
    'basisPoints',
    'chargeInterchange',
    'dinersClubBasisPoints',
    'dinersClubChargeInterchange',
    'dinersClubFixedFee',
    'discoverAssessmentsBasisPoints',
    'discoverBasisPoints',
    'discoverChargeInterchange',
    'discoverDataUsageFixedFee',
    'discoverExternallyFundedBasisPoints',
    'discoverExternallyFundedFixedFee',
    'discoverFixedFee',
    'discoverNetworkAuthorizationFixedFee',
    'disputeFixedFee',
    'disputeInquiryFixedFee',
    'externallyFundedBasisPoints',
    'externallyFundedFixedFee',
    'fixedFee',
    'jcbBasisPoints',
    'jcbChargeInterchange',
    'jcbFixedFee',
    'mastercardAcquirerFeesBasisPoints',
    'mastercardAssessmentsOver1kBasisPoints',
    'mastercardAssessmentsUnder1kBasisPoints',
    'mastercardBasisPoints',
    'mastercardChargeInterchange',
    'mastercardFixedFee',
    'qualifiedTiers',
    'roundingMode',
    'tags',
    'visaAcquirerProcessingFixedFee',
    'visaAssessmentsBasisPoints',
    'visaBaseIICreditVoucherFixedFee',
    'visaBaseIISystemFileTransmissionFixedFee',
    'visaBasisPoints',
    'visaChargeInterchange',
    'visaCreditVoucherFixedFee',
    'visaFixedFee',
    'visaKilobyteAccessFixedFee',
    'cardCrossBorderBasisPoints',
    'cardCrossBorderFixedFee',
    'achBasisPointsFeeLimit',
    'visaOverrideEnabled',
    'mastercardOverrideEnabled',
    'amexOverrideEnabled',
    'discoverOverrideEnabled',
    'jcbOverrideEnabled',
    'dinersClubOverrideEnabled',
    'chargeDuesAssessments',
    'visaChargeDuesAssessments',
    'mastercardChargeDuesAssessments',
    'americanExpressChargeDuesAssessments',
    'discoverChargeDuesAssessments',
  ])

  // set card specific charge_interchange values to true when fee profile is IC+ and override is enabled
  const visaChargeInterchangeValue = chargeInterchange === 'interchange-plus' && visaOverrideEnabled ? true : null
  const mastercardChargeInterchangeValue = chargeInterchange === 'interchange-plus' && mastercardOverrideEnabled ? true : null
  const amexChargeInterchangeValue = chargeInterchange === 'interchange-plus' && amexOverrideEnabled ? true : null
  const discoverChargeInterchangeValue = chargeInterchange === 'interchange-plus' && discoverOverrideEnabled ? true : null
  const jcbChargeInterchangeValue = chargeInterchange === 'interchange-plus' && jcbOverrideEnabled ? true : null
  const dinersClubChargeInterchangeValue = chargeInterchange === 'interchange-plus' && dinersClubOverrideEnabled ? true : null

  // set specific charge_dues_assessments values when fee profile is IC+
  const amexDuesAssessmentsValue = chargeDuesAssessments !== americanExpressChargeDuesAssessments ? americanExpressChargeDuesAssessments : null
  const discoverDuesAssessmentsValue = chargeDuesAssessments !== discoverChargeDuesAssessments ? discoverChargeDuesAssessments : null
  const mastercardDuesAssessmentsValue = chargeDuesAssessments !== mastercardChargeDuesAssessments ? mastercardChargeDuesAssessments : null
  const visaDuesAssessmentsValue = chargeDuesAssessments !== visaChargeDuesAssessments ? visaChargeDuesAssessments : null

  return removeUndefined({
    id,
    ach_basis_points: achBasisPoints,
    ach_credit_return_fixed_fee: achCreditReturnFixedFee,
    ach_debit_return_fixed_fee: achDebitReturnFixedFee,
    ach_fixed_fee: achFixedFee,
    american_express_assessment_basis_points: americanExpressAssessmentBasisPoints,
    american_express_basis_points: americanExpressBasisPoints,
    american_express_charge_interchange: amexChargeInterchangeValue,
    american_express_externally_funded_basis_points: americanExpressExternallyFundedBasisPoints,
    american_express_externally_funded_fixed_fee: americanExpressExternallyFundedFixedFee,
    american_express_fixed_fee: americanExpressFixedFee,
    ancillary_fixed_fee_primary: ancillaryFixedFeePrimary,
    ancillary_fixed_fee_secondary: ancillaryFixedFeeSecondary,
    basis_points: basisPoints,
    charge_interchange: chargeInterchange === 'blended' ? false : true,
    diners_club_basis_points: dinersClubBasisPoints,
    diners_club_charge_interchange: dinersClubChargeInterchangeValue,
    diners_club_fixed_fee: dinersClubFixedFee,
    discover_assessments_basis_points: discoverAssessmentsBasisPoints,
    discover_basis_points: discoverBasisPoints,
    discover_charge_interchange: discoverChargeInterchangeValue,
    discover_data_usage_fixed_fee: discoverDataUsageFixedFee,
    discover_externally_funded_basis_points: discoverExternallyFundedBasisPoints,
    discover_externally_funded_fixed_fee: discoverExternallyFundedFixedFee,
    discover_fixed_fee: discoverFixedFee,
    discover_network_authorization_fixed_fee: discoverNetworkAuthorizationFixedFee,
    dispute_fixed_fee: disputeFixedFee,
    dispute_inquiry_fixed_fee: disputeInquiryFixedFee,
    externally_funded_basis_points: externallyFundedBasisPoints,
    externally_funded_fixed_fee: externallyFundedFixedFee,
    fixed_fee: fixedFee,
    jcb_basis_points: jcbBasisPoints,
    jcb_charge_interchange: jcbChargeInterchangeValue,
    jcb_fixed_fee: jcbFixedFee,
    mastercard_acquirer_fees_basis_points: mastercardAcquirerFeesBasisPoints,
    mastercard_assessments_over1k_basis_points: mastercardAssessmentsOver1kBasisPoints,
    mastercard_assessments_under1k_basis_points: mastercardAssessmentsUnder1kBasisPoints,
    mastercard_basis_points: mastercardBasisPoints,
    mastercard_charge_interchange: mastercardChargeInterchangeValue,
    mastercard_fixed_fee: mastercardFixedFee,
    qualified_tiers: qualifiedTiers,
    rounding_mode: roundingMode,
    tags,
    visa_acquirer_processing_fixed_fee: visaAcquirerProcessingFixedFee,
    visa_assessments_basis_points: visaAssessmentsBasisPoints,
    visa_base_II_credit_voucher_fixed_fee: visaBaseIICreditVoucherFixedFee,
    visa_base_II_system_file_transmission_fixed_fee: visaBaseIISystemFileTransmissionFixedFee,
    visa_basis_points: visaBasisPoints,
    visa_charge_interchange: visaChargeInterchangeValue,
    visa_credit_voucher_fixed_fee: visaCreditVoucherFixedFee,
    visa_fixed_fee: visaFixedFee,
    visa_kilobyte_access_fixed_fee: visaKilobyteAccessFixedFee,
    card_cross_border_basis_points: cardCrossBorderBasisPoints,
    card_cross_border_fixed_fee: cardCrossBorderFixedFee,
    ach_basis_points_fee_limit: achBasisPointsFeeLimit,
    charge_dues_assessments: chargeInterchange === 'blended' ? null : chargeDuesAssessments,
    american_express_charge_dues_assessments: amexDuesAssessmentsValue,
    discover_charge_dues_assessments: discoverDuesAssessmentsValue,
    mastercard_charge_dues_assessments: mastercardDuesAssessmentsValue,
    visa_charge_dues_assessments: visaDuesAssessmentsValue,
  })
}

export default backendFeeProfileModel
