import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MFA_AUTHENTICATORS } from 'constants/apiConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const getDashboardUserMFAAuthenticatorsAPI = ({ meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_MFA_AUTHENTICATORS({
      userId: get(getCurrentCredentials(), 'userId'),
    }),
    meta,
  })
}

export default getDashboardUserMFAAuthenticatorsAPI
