import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import formatDate from 'utilities/formatters/formatDate'
import column from 'utilities/table/column'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { disputeStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'

import {
  createdAtRange,
  updatedAtRange,
} from 'utilities/table/sort'

import {
  ID,
  CREATED_ON,
  FEE_TYPE,
  AMOUNT,
  READY_TO_SETTLE,
  LEVEL,
  STATE,
  ADJUSTMENT_TYPE,
  TIME_FRAME,
  ENABLED,
  COUNT_LIMIT,
  VOLUME_LIMIT,
  TIME_ZONE,
  START_TIME,
  START_DAY,
  START_DATE,
  CURRENT_COUNT,
  CURRENT_VOLUME,
} from 'constants/language/languageConstants'

export const disputesColumnDescriptors = [
  column('Dispute ID', 'id', {
    className: 'id',
    condition: notRoleMerchant,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column('State', 'state', {
    className: 'state',
    formatter: ({ state }) => <ColorcodedStatus value={state} data={disputeStatusMap} />,
  }),
  column('Respond By', 'respondBy', {
    className: 'date',
    formatter: ({ respondBy }) => formatDate({ date: respondBy }),
  }),
  column('Reason', 'displayReason', {
    className: 'small',
  }),
  column('Amount', ['displayAmount', 'currency'], {
    className: 'amount',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
]

export const transferFeesColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column('Fee Category', 'displayCategory'),
  column(FEE_TYPE, 'displayFeeType', {
    className: 'x-large',
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount',
    highlight: true,
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
]

export const adjustmentTransfersColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(READY_TO_SETTLE, 'displayReadyToSettleAt', {
    className: 'date',
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(ADJUSTMENT_TYPE, 'displayType'),
  column(LEVEL, 'displaySubtype'),
  column(STATE, 'state', {
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
]

// application velocity limits descriptors
export const velocityLimitsColumnDescriptors = [
  column(TIME_FRAME, 'displayTimeFrame', { className: 'small' }),
  column(ENABLED, 'rules[0].enabled', {
    className: 'small',
    formatter: ({ rules }) => {
      return <EnabledStatus value={get(rules, '[0].enabledStatus')} />
    },
  }),
  column(COUNT_LIMIT, 'rules[0].displayCountLimit', { className: 'small' }),
  column(VOLUME_LIMIT, 'rules[0].displayVolumeLimit', { className: 'small' }),
  column(TIME_ZONE, 'displayTimeZone', { className: 'medium' }),
  column(START_TIME, 'displayStartTime', { className: 'medium' }),
  // TODO : Cata - commenting this out for first release because we're not supporting weekly for MVP
  // column(START_DAY, 'displayStartDay', { className: 'medium' }),
  column(START_DATE, 'displayStartDate', { className: 'x-small' }),
]

// recipient velocity limits and current usages descriptors
export const recipientVelocityLimitsColumnDescriptors = [
  column(TIME_FRAME, 'displayTimeFrame', { className: 'small' }),
  column(ENABLED, 'rules[0].enabled', {
    className: 'small',
    formatter: ({ rules }) => {
      return <EnabledStatus value={get(rules, '[0].enabledStatus')} />
    },
  }),
  column(COUNT_LIMIT, 'rules[0].displayCountLimit', { className: 'small' }),
  column(CURRENT_COUNT, 'count', { className: 'small' }),
  column(VOLUME_LIMIT, 'rules[0].displayVolumeLimit', { className: 'small' }),
  column(CURRENT_VOLUME, 'volume', { className: 'small' }),
  column(TIME_ZONE, 'displayTimeZone', { className: 'medium' }),
  column(START_TIME, 'displayStartTime', { className: 'small' }),
  column(START_DATE, 'displayStartDate', { className: 'x-small' }),
]
