import axios from 'axios'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import postDashboardPresignedUrlAPI from 'api/dashboard/post/postDashboardPresignedUrlAPI'
import { MM_DD_YYYY_FILE_NAME } from 'constants/timeConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import co from 'co'

function * postFileToCustomerUploadedAssetsO ({
  credentials,
  file,
}) {
  const fileName = get(file, 'name')
  const normalizedFileName = `${moment().format(MM_DD_YYYY_FILE_NAME)}_${fileName}_${uuidv4()}`

  const { data: presignedUrl } = yield postDashboardPresignedUrlAPI({
    credentials,
    values: { file_name: normalizedFileName },
  })

  const response = yield axios.put(presignedUrl, file, {
    headers: { 'Content-Type': get(file, 'type') },
  })

  const url = get(split(get(response, 'config.url'), '?'), '[0]')

  return { data: url }
}

export default co.wrap(postFileToCustomerUploadedAssetsO)
