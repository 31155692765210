import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'

const frontendAdminUserMembershipsModel = ({ data: users }) => {
  const dashboardServiceV2 = isDashboardServiceV2()

  const usersModels = map(users, (user) => {
    const [
      id,
      email,
      createdAt,
      displayName,
    ] = getMany(user, [
      'id',
      dashboardServiceV2 ? 'dashboard_user_id' : 'attributes.email-address',
      dashboardServiceV2 ? 'created_at' : 'attributes.created-at',
      dashboardServiceV2 ? 'api_credential_id' : 'attributes.display-name',
    ])

    return {
      createdAt,
      email,
      displayName,
      id,
    }
  })

  return keyBy(usersModels, 'id')
}

export default frontendAdminUserMembershipsModel
