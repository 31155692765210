import './CreateTeamMemberS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TeamMemberFormC from 'components/Customer/Forms/TeamMemberForm/TeamMemberFormC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const CreateTeamMember = ({
  headerTitle = '',
  headerSubTitle = '',
  roles = [],
  entityId = '',
  entityType = '',
  memberId = '',
  currentBusinessName,
}) => {
  return (
    <div className='CreateTeamMember'>
      <ContextBar />

      <HeaderV2C
        title={headerTitle}
        subTitle={headerSubTitle}
      />

      <TeamMemberFormC
        roles={roles}
        entityId={entityId}
        entityType={entityType}
        memberId={memberId}
        currentBusinessName={currentBusinessName}
      />
    </div>
  )
}

CreateTeamMember.propTypes = {
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  roles: PropTypes.array,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  memberId: PropTypes.string,
  currentBusinessName: PropTypes.string,
}

export default CreateTeamMember
