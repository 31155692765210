import './AdminUserS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  CREATED_ON,
  DASHBOARD_ACCESS_EMPTY_TABLE_MESSAGE,
  DISPLAY_NAME,
} from 'constants/language/languageConstants'

const AdminUser = ({
  userCredentials = {},
  actions = [],
  headerData = {},
}) => {
  return (
    <div className='AdminUser'>
      <ContextBar />

      <HeaderV2C
        {...headerData}
      />

      <div className='Table'>
        <div className='table-header-area flex space-between items-center'>
          <h4>Dashboard Access</h4>

          {map(actions, ({ label, action, className }) => <div className={`action ${className}`} onClick={action}>{label}</div>)}
        </div>

        {isEmpty(userCredentials) ? DASHBOARD_ACCESS_EMPTY_TABLE_MESSAGE : (
          <table>
            <thead>
              <tr>
                <th>{DISPLAY_NAME}</th>
                <th>{CREATED_ON}</th>
              </tr>
            </thead>
            <tbody>
              {map(userCredentials, (userCredential) => {
                const [
                  name,
                  displayCreatedAt,
                ] = getMany(userCredential, [
                  'name',
                  'displayCreatedAt',
                ])

                return (
                  <tr>
                    <td>{name}</td>
                    <td>{displayCreatedAt}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

AdminUser.propTypes = {
  userCredentials: PropTypes.object,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default AdminUser
