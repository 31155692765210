import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApproveAllConfirmationForm from 'components/Customer/Forms/ApproveAllConfirmationForm/ApproveAllConfirmationForm'
import redirectRequest from 'utilities/actions/redirectRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { POST_MASS_SETTLEMENT_APPROVAL_F_CANCEL } from 'constants/flowConstants'
import updateUrlQueries from 'utilities/updateUrlQueries'

const mapStateToProps = (state) => {
  const [
    totalSettlementsCount,
    isApprovingAll,
    totalApproved = 0,
    totalFailed = 0,
  ] = getMany(state, [
    'linksR.REVIEW_QUEUE.page.count',
    `massApprovalR.${FETCHING}`,
    'massApprovalR.totalApproved',
    'massApprovalR.totalFailed',
  ])

  return {
    isApprovingAll,
    totalSettlementsCount,
    totalApproved,
    totalFailed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirectPathAction: (redirectPath) => dispatch(redirectRequest({ path: redirectPath })),
    closeModal: () => dispatch(hideModalAction()),
    cancelApproveAll: () => {
      dispatch({ type: POST_MASS_SETTLEMENT_APPROVAL_F_CANCEL })
      updateUrlQueries({ fetchData: true })
    },
    finishApproveAll: () => {
      dispatch(hideModalAction())
      updateUrlQueries({ fetchData: true })
    },
  }
}

class ApproveAllConfirmationFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newApproveAll: true,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isApprovingAll: prevIsApprovingAll,
      totalApproved: prevTotalApproved,
      totalFailed: prevTotalFailed,
    } = prevProps

    const {
      isApprovingAll,
      totalApproved,
      totalFailed,
    } = this.props

    if (prevIsApprovingAll === true && isApprovingAll === false) {
      this.setState({
        newApproveAll: false,
        totalApproved,
        totalFailed,
      })
    } else if (prevTotalApproved !== totalApproved || prevTotalFailed !== totalFailed) {
      this.setState({
        totalApproved,
        totalFailed,
      })
    }
  }

  componentWillUnmount() {
    const {
      cancelApproveAll,
      totalApproved,
      totalFailed,
      isApprovingAll,
    } = this.props

    const { newApproveAll } = this.state

    // cancel approve all -> fetchData on close only if approve all was called
    if (isApprovingAll || (!newApproveAll && (totalApproved || totalFailed))) cancelApproveAll()
  }

  handleFinishApproveAll = () => {
    const { finishApproveAll } = this.props

    finishApproveAll()

    this.setState({
      newApproveAll: true,
      totalApproved: 0,
      totalFailed: 0,
    })
  }

  render() {
    const {
      newApproveAll,
      totalApproved,
      totalFailed,
    } = this.state

    return (
      <ApproveAllConfirmationForm
        {...this.props}
        handleFinishApproveAll={this.handleFinishApproveAll}
        totalApproved={totalApproved}
        totalFailed={totalFailed}
        newApproveAll={newApproveAll}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveAllConfirmationFormC)
