import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitEditComplianceFormTemplateForm from 'utilities/submit/submitEditComplianceFormTemplateForm'
import { SAVE } from 'constants/language/languageConstants'

const EditComplianceFormTemplateForm = ({
  handleSubmit = () => {},
  submitting = false,
}) => {
  return (
    <form className='EditComplianceFormTemplateForm' onSubmit={handleSubmit(submitEditComplianceFormTemplateForm)}>
      <div className='section'>
        <Field
          name='name'
          label='New Name'
          component={InputField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label={SAVE} submitting={submitting} />
      </div>
    </form>
  )
}

EditComplianceFormTemplateForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
}

export default EditComplianceFormTemplateForm
