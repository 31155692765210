import './AuthorizationsInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatNumber from 'utilities/formatters/formatNumber'
import formatPercentage from 'utilities/formatters/formatPercentage'
import formatChartAmount from 'utilities/formatters/formatChartAmount'

import {
  AUTHORIZATION_INSIGHTS_TOOLTIP_TITLE,
  AUTHORIZATION_RATE,
  AUTHORIZATION_RATE_TOOLTIP,
  AUTHORIZATION_TRENDS,
  AUTHORIZATIONS_PER_MERCHANT,
  AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION,
  AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION_TOOLTIP,
  AUTHORIZATIONS_PER_MERCHANT_TOOLTIP,
  CAPTURED_AUTHORIZATIONS,
  CAPTURED_AUTHORIZATIONS_TOOLTIP,
  CARD_AUTHORIZATION_DATA,
  CARD_AUTHORIZATION_DATA_TOOLTIP,
  FAILED_AUTHORIZATIONS_BY_FAILURE_CODE,
  FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TOOLTIP,
  REQUESTS_RECEIVED,
  REQUESTS_RECEIVED_TOOLTIP,
  VOIDED_AUTHORIZATIONS,
  VOIDED_AUTHORIZATIONS_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  AUTHORIZATIONS_REQUESTS_RECEIVED_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_REQUESTS_RECEIVED_COUNT_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL,
  AUTHORIZATIONS_AUTHORIZATION_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE,
  AUTHORIZATIONS_AUTHORIZATION_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR,
  AUTHORIZATIONS_AUTHORIZATIONS_PER_MERCHANT_TABLE,
  AUTHORIZATIONS_CARD_AUTHORIZATION_DATA_TABLE,
  AUTHORIZATIONS_FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TABLE,
} from 'constants/chartConstants'

const AuthorizationsInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRolePlatformCredential = false,
  showAuthorizationsPerMerchant = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='AuthorizationsInsights flex column'>
      <div className='time-frame-container flex space-between items-center'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>Time Frame</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated p-2'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>Summary</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>{AUTHORIZATION_INSIGHTS_TOOLTIP_TITLE}</p>
                <p><span className='bold'>{REQUESTS_RECEIVED}</span>{REQUESTS_RECEIVED_TOOLTIP}</p>
                <p><span className='bold'>{AUTHORIZATION_RATE}</span>{AUTHORIZATION_RATE_TOOLTIP}</p>
                <p><span className='bold'>{VOIDED_AUTHORIZATIONS}</span>{VOIDED_AUTHORIZATIONS_TOOLTIP}</p>
                <p><span className='bold'>{CAPTURED_AUTHORIZATIONS}</span>{CAPTURED_AUTHORIZATIONS_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={REQUESTS_RECEIVED}
            headerData={{
              name: AUTHORIZATIONS_REQUESTS_RECEIVED_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: AUTHORIZATIONS_REQUESTS_RECEIVED_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} authorizations`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={AUTHORIZATION_RATE}
            headerData={{
              name: AUTHORIZATIONS_AUTHORIZATION_RATE_BIG_NUMBER_TOTAL,
              formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
            }}
            subHeaderData={{
              name: AUTHORIZATIONS_AUTHORIZATION_RATE_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={VOIDED_AUTHORIZATIONS}
            headerData={{
              name: AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: AUTHORIZATIONS_VOIDED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={CAPTURED_AUTHORIZATIONS}
            headerData={{
              name: AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: AUTHORIZATIONS_CAPTURED_AUTHORIZATIONS_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={AUTHORIZATION_TRENDS}
          classNames='transaction-trends'
          chartClassNames='transaction-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: AUTHORIZATIONS_AUTHORIZATION_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE, height: 260 },
            { name: AUTHORIZATIONS_AUTHORIZATION_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR, height: 260 },
          ]}
        />

        { showAuthorizationsPerMerchant && (
          <div className='mtl'>
            <ChartC
              title={isRolePlatformCredential ? AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION : AUTHORIZATIONS_PER_MERCHANT}
              name={AUTHORIZATIONS_AUTHORIZATIONS_PER_MERCHANT_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              showGroupBy
              hideTitle
              tooltipMessage={<p>{isRolePlatformCredential ? AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION_TOOLTIP : AUTHORIZATIONS_PER_MERCHANT_TOOLTIP}</p>}
            />
          </div>
        )}

        <div className='mtl'>
          <ChartC
            title={CARD_AUTHORIZATION_DATA}
            name={AUTHORIZATIONS_CARD_AUTHORIZATION_DATA_TABLE}
            height={280}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{CARD_AUTHORIZATION_DATA_TOOLTIP}</p>}
          />
        </div>

        <div className='mtl'>
          <ChartC
            title={FAILED_AUTHORIZATIONS_BY_FAILURE_CODE}
            name={AUTHORIZATIONS_FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TABLE}
            height={484}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TOOLTIP}</p>}
          />
        </div>
      </div>
    </div>
  )
}

AuthorizationsInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  isRolePlatformCredential: PropTypes.bool,
  showAuthorizationsPerMerchant: PropTypes.bool,
}

export default AuthorizationsInsights
