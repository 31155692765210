// V2 Underwriting Constants (Underwriting Reviews)
import selectOption from 'utilities/forms/selectOption'

import {
  MANUAL_REVIEW as MANUAL_REVIEW_LABEL,
  VERIFICATION_NEEDED as VERIFICATION_NEEDED_LABEL,
  APPROVED as APPROVED_LABEL,
  REJECTED as REJECTED_LABEL,
  WITHDRAWN as WITHDRAWN_LABEL,
  ALL as ALL_LABEL,
} from 'constants/language/languageConstants'

import {
  ACCEPTED as V1_ACCEPTED,
  REJECTED as V1_REJECTED,
  MANUAL_REVIEW as V1_MANUAL_REVIEW,
  ADDITIONAL_VERIFICATION_NEEDED as V1_ADDITIONAL_VERIFICATION_NEEDED,
  WITHDRAWN as V1_WITHDRAWN,
} from 'constants/underwritingConstants'

export const ALL = 'ALL'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'
export const MANUAL_REVIEW = 'MANUAL_REVIEW'
export const PENDING = 'PENDING'
export const CHANGE_REQUESTED = 'CHANGE_REQUESTED'
export const CLOSED = 'CLOSED'

export const UNDERWRITING_REVIEW_STATE_OPTIONS = [
  selectOption(MANUAL_REVIEW_LABEL, MANUAL_REVIEW),
  selectOption(VERIFICATION_NEEDED_LABEL, CHANGE_REQUESTED),
  selectOption(APPROVED_LABEL, APPROVED),
  selectOption(REJECTED_LABEL, REJECTED),
  selectOption(WITHDRAWN_LABEL, CLOSED),
  selectOption(ALL_LABEL, ALL),
]

export const PLATFORMS_WORKFLOW = 'PLATFORMS'
export const STANDALONE_MERCHANTS_WORKFLOW = 'STANDALONE_MERCHANTS'
export const MERCHANTS = 'MERCHANTS'
export const DISBURSEMENTS_WORKFLOW = 'DISBURSEMENTS'
export const RECIPIENTS = 'RECIPIENTS'
export const ADVERSE_MEDIA_WORKFLOW = 'ADVERSE_MEDIA'
export const SANCTIONS_WORKFLOW = 'SANCTIONS'

export const V1_STATUS_TO_V2_STATE = {
  [V1_ACCEPTED]: APPROVED,
  [V1_REJECTED]: REJECTED,
  [V1_MANUAL_REVIEW]: MANUAL_REVIEW,
  [V1_ADDITIONAL_VERIFICATION_NEEDED]: CHANGE_REQUESTED,
  [V1_WITHDRAWN]: CLOSED,
}
