import get from 'lodash/get'
import map from 'lodash/map'
import formatMoney from 'utilities/formatters/formatMoney'
import { REFUND_POLICY_DISPLAY_OPTIONS_MAP } from 'constants/underwritingConstants'
import { isNumber } from 'utilities/validate/index'

const AdditionalUnderwritingDisplayDataFields = (isFlex) => {
  return [
    {
      name: 'averageCardTransferAmount',
      label: 'Average Card Transaction Amount',
      formatter: (value) => formatMoney({ amount: value }),
    },
    {
      name: 'averageACHTransferAmount',
      label: 'Average ACH Transaction Amount',
      formatter: (value) => formatMoney({ amount: value }),
    },
    {
      name: 'annualACHVolume',
      label: 'Annual ACH Volume',
      formatter: (value) => formatMoney({ amount: value }),
    },
    {
      name: 'businessDescription',
      label: 'Business Description',
    },
    {
      name: 'cardPresentPercentage',
      label: 'Card Present Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
    {
      name: 'ecommercePercentage',
      label: 'E-commerce Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
    {
      name: 'mailOrderTelephoneOrderPercentage',
      label: 'Mail Order/Telephone Order Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
    {
      name: 'merchantAgreementAccepted',
      label: isFlex ? 'Finix Terms of Service Accepted' : 'Merchant Agreement Accepted',
      formatter: (bool) => (bool === false ? 'No' : '-'),
    },
    {
      name: 'refundPolicy',
      label: 'Refund Policy',
      formatter: (value) => get(REFUND_POLICY_DISPLAY_OPTIONS_MAP, value),
    },
    {
      name: 'businessToBusinessVolumePercentage',
      label: 'Business to Business Volume Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
    {
      name: 'businessToConsumerVolumePercentage',
      label: 'Business to Consumer Volume Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
    {
      name: 'otherVolumePercentage',
      label: 'Other Volume Percentage',
      formatter: (value) => (isNumber(value) ? `${value}%` : '-'),
    },
  ]
}

const AdditionalUnderwritingDataInfo = (values, isFlex) => {
  const additionalUnderwritingDisplayDataFields = AdditionalUnderwritingDisplayDataFields(isFlex)

  return map(additionalUnderwritingDisplayDataFields, ({ name, label, formatter }) => ({
    label,
    value: formatter ? formatter(get(values, name)) : get(values, name),
  }))
}

export default AdditionalUnderwritingDataInfo
