import React from 'react'
import column from 'utilities/table/column'
import getUrlQuery from 'utilities/get/getUrlQuery'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import includes from 'lodash/includes'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  ID,
  BUYER,
  AMOUNT,
  CREATED_ON,
  STATE,
  REASON_CODE,
  TRANSACTION_TYPE,
} from 'constants/language/languageConstants'

import {
  ALL,
  CANCELED,
  FAILED,
} from 'constants/transferConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(BUYER, 'paymentInstrument.name', { className: 'normal' }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === CANCELED}
        subtitleIndent
      />
    ),
  }),
  column(TRANSACTION_TYPE, 'displayType'),
]

export default columnDescriptors
