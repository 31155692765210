import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantUnderwritingReview from './MerchantUnderwritingReview'
import Address from 'components/Customer/Shared/Display/Address/Address'
import DisplayUnmaskC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import getUnderwritingSubjectUnmaskAPI from 'api/finix/get/getUnderwritingSubjectUnmaskAPI'
import hasPermission from 'utilities/hasPermission'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatDateObject from 'utilities/formatters/formatDateObject'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import showModalAction from 'utilities/actions/showModalAction'
import formatPercentage from 'utilities/formatters/formatPercentage'
import formatMoney from 'utilities/formatters/formatMoney'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { FETCHING } from 'constants/reducerConstants'
import { EDD_FILE_TYPES } from 'constants/eddDocumentConstants'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import values from 'lodash/values'
import filter from 'lodash/filter'
import join from 'lodash/join'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  getIdentityFileSelector,
  getIdentityFilesWithMerchantData,
  getUnderwritingReviewSelector,
} from 'state-layer/selectors'

import {
  FILE_VIEWER_MODAL,
  GOOGLE_MAP_DETAILS_MODAL,
} from 'constants/modalConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  AMERICAN_EXPRESS_MID,
  ANNUAL_CARD_VOLUME,
  AVERAGE_CARD_TRANSACTION_AMOUNT,
  BUSINESS_ADDRESS,
  BUSINESS_NAME,
  BUSINESS_PHONE,
  BUSINESS_TAX_ID,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  BUSINESS_TYPE,
  CARD_PRESENT,
  CONSUMER_TO_CONSUMER,
  CREDIT_CHECK_ALLOWED,
  CREDIT_CHECK_IP_ADDRESS,
  CREDIT_CHECK_TIMESTAMP,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DISCOVER_MID,
  DOING_BUSINESS_AS,
  ECOMMERCE,
  INCORPORATION_DATE,
  INSTITUTION_NUMBER,
  MAIL_TELEPHONE_ORDER,
  MAP_DETAILS,
  MAX_TRANSACTION_AMOUNT,
  MERCHANT_AGREEMENT_ACCEPTED,
  MERCHANT_AGREEMENT_TIMESTAMP,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_IP_ADDRESS,
  NO,
  OTHER,
  OWNERSHIP_TYPE,
  PERSON_TO_PERSON,
  PREVIOUSLY_ACCEPTED_CREDIT_CARDS,
  REFUND_POLICY,
  ROUTING_NUMBER,
  TRANSIT_NUMBER,
  WEBSITE,
  YES,
  CURRENCY,
  CONNECTION_TYPE,
  PLAID,
  MANUAL_OPTION,
} from 'constants/language/languageConstants'

import {
  annualACHVolumeLabelMap,
  averageACHTransferAmountLabelMap,
} from 'constants/labelConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)

  const [
    reviewId,
    subjectId,
    entityId,
    entityType,
  ] = getMany(props, [
    'reviewId',
    'subjectId',
    'entityId',
    'entityType',
  ])

  const review = getUnderwritingReviewSelector(state, reviewId)
  const identityMerchants = values(get(review, 'entity.merchants'))
  const identityFilesSelector = () => getIdentityFilesWithMerchantData(state, reviewId)
  const associatedFiles = get(review, 'entity.onboardingForm.associatedFiles', [])
  const eddFiles = filter(associatedFiles, (associatedFile) => includes(EDD_FILE_TYPES, get(associatedFile, 'type')))

  const eddFilesWithData = map(eddFiles, (eddFile) => ({
    ...eddFile,
    fileData: getIdentityFileSelector(state, get(eddFile, 'id')),
  }))

  const groupedEDDFiles = groupBy(eddFilesWithData, 'type')

  const [
    entity = {},
    runSubject = {},
    reviewCountry,
    reviewType,
    bankAccountReportVersion,
    selfieReport,
    governmentIdReport,
  ] = getMany(review, [
    'entity',
    'run.subject',
    'country',
    'reviewType',
    'report.bank_accounts[0].version',
    'report.selfie_files[0]',
    'report.government_id_files[0]',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[reviewCountry]

  const [
    businessReports = {},
    businessMaxTransactionAmount,
    businessAchMaxTransactionAmount,
    businessMcc,
    businessAcceptedCardsBefore,
    owners = [],
    additionalData = {},
    bankAccountReport,
    bankAccountCountry,
  ] = getMany(runSubject, [
    'business.reports',
    'business.max_transaction_amount',
    'business.ach_max_transaction_amount',
    'business.mcc',
    'business.accepted_cards_before',
    'owners',
    'additional_data.payload',
    'bank_accounts[0].reports.bank_accounts',
    'bank_accounts[0].country',
  ])

  const primaryOwner = filter(owners, { primary_owner: true })
  const beneficialOwners = filter(owners, { primary_owner: false })

  const [
    defaultStatementDescriptor,
  ] = getMany(entity, [
    'defaultStatementDescriptor',
  ])

  const [
    annualACHVolume,
    annualCardVolume,
    averageACHTransferAmount,
    averageCardTransferAmount,
    businessDescription,
    cardPresentPercentage,
    ecommercePercentage,
    mailTelephoneOrderPercentage,
    refundPolicy,
    businessToBusinessPercentage,
    businessToConsumerPercentage,
    consumerToConsumerPercentage,
    otherPercentage,
    personToPersonPercentage,
    amexMID,
    discoverMID,
    merchantAgreementAccepted,
    merchantAgreementTimestamp,
    merchantAgreementIPAddress,
    creditCheckAllowed,
    creditCheckTimestamp,
    creditCheckIPAddress,
  ] = getMany(additionalData, [
    'annual_ach_volume',
    'identities_entity.annual_card_volume',
    'average_ach_transfer_amount',
    'average_card_transfer_amount',
    'business_description',
    'card_volume_distribution.card_present_percentage',
    'card_volume_distribution.ecommerce_percentage',
    'card_volume_distribution.mail_order_telephone_order_percentage',
    'refund_policy',
    'volume_distribution_by_business_type.business_to_business_volume_percentage',
    'volume_distribution_by_business_type.business_to_consumer_volume_percentage',
    'volume_distribution_by_business_type.consumer_to_consumer_volume_percentage',
    'volume_distribution_by_business_type.other_volume_percentage',
    'volume_distribution_by_business_type.person_to_person_volume_percentage',
    'identities_entity.amex_mid',
    'identities_entity.discover_mid',
    'merchant_agreement_accepted',
    'merchant_agreement_timestamp',
    'merchant_agreement_ip_address',
    'credit_check_allowed',
    'credit_check_timestamp',
    'credit_check_ip_address',
  ])

  const processingInformationDataSection = convertPageSectionDataToV2([
    { label: MERCHANT_CATEGORY_CODE, value: join(businessMcc, ', ') },
    { label: DEFAULT_STATEMENT_DESCRIPTOR, value: defaultStatementDescriptor },
    { label: MAX_TRANSACTION_AMOUNT, value: formatMoney({ amount: businessMaxTransactionAmount, currency, showCurrencyCode: true }) },
    { label: ANNUAL_CARD_VOLUME, value: formatMoney({ amount: annualCardVolume, currency, showCurrencyCode: true }) },
    { label: annualACHVolumeLabelMap(reviewCountry), value: formatMoney({ amount: annualACHVolume, currency, showCurrencyCode: true }) },
    { label: AVERAGE_CARD_TRANSACTION_AMOUNT, value: formatMoney({ amount: averageCardTransferAmount, currency, showCurrencyCode: true }) },
    { label: averageACHTransferAmountLabelMap(reviewCountry), value: formatMoney({ amount: averageACHTransferAmount, currency, showCurrencyCode: true }) },
    { label: AMERICAN_EXPRESS_MID, value: amexMID },
    { label: DISCOVER_MID, value: discoverMID },
    { label: REFUND_POLICY, value: snakeCaseToTitleCase({ key: refundPolicy }) },
    { label: PREVIOUSLY_ACCEPTED_CREDIT_CARDS, value: businessAcceptedCardsBefore ? YES : NO },
  ])

  const paymentVolumeDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_TO_BUSINESS, value: formatPercentage({ percentage: businessToBusinessPercentage }) },
    { label: BUSINESS_TO_CONSUMER, value: formatPercentage({ percentage: businessToConsumerPercentage }) },
    { label: PERSON_TO_PERSON, value: formatPercentage({ percentage: personToPersonPercentage }) },
    { label: CONSUMER_TO_CONSUMER, value: formatPercentage({ percentage: consumerToConsumerPercentage }) },
    { label: OTHER, value: formatPercentage({ percentage: otherPercentage }) },
  ])

  const cardVolumeDataSection = convertPageSectionDataToV2([
    { label: CARD_PRESENT, value: formatPercentage({ percentage: cardPresentPercentage }) },
    { label: ECOMMERCE, value: formatPercentage({ percentage: ecommercePercentage }) },
    { label: MAIL_TELEPHONE_ORDER, value: formatPercentage({ percentage: mailTelephoneOrderPercentage }) },
  ])

  const additionalInformationDataSection = convertPageSectionDataToV2([
    { label: CREDIT_CHECK_ALLOWED, value: creditCheckAllowed ? YES : NO },
    { label: MERCHANT_AGREEMENT_ACCEPTED, value: merchantAgreementAccepted ? YES : NO },
    { label: CREDIT_CHECK_IP_ADDRESS, value: creditCheckIPAddress },
    { label: MERCHANT_IP_ADDRESS, value: merchantAgreementIPAddress },
    { label: CREDIT_CHECK_TIMESTAMP, value: formatDate({ date: creditCheckTimestamp }) },
    { label: MERCHANT_AGREEMENT_TIMESTAMP, value: formatDate({ date: merchantAgreementTimestamp }) },
  ])

  return {
    isFetching,
    credentials,
    reviewId,
    reviewType,
    subjectId,
    entityId,
    entityType,
    runSubject,
    entity,
    businessReports,
    processingInformationDataSection,
    paymentVolumeDataSection,
    cardVolumeDataSection,
    bankAccountReport,
    additionalInformationDataSection,
    identityFilesSelector,
    identityMerchants,
    primaryOwner,
    beneficialOwners,
    businessInformationSubTitle: businessDescription ? `Description: ${businessDescription}` : 'Description: N/A',
    showUnmaskAction,
    reviewCountry,
    isStandaloneUnderwriting,
    bankAccountCurrency: currency,
    bankAccountReportVersion,
    selfieReport,
    governmentIdReport,
    groupedEDDFiles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showGoogleMapDetailsModal: (modalProps) => dispatch(showModalAction({ modalType: GOOGLE_MAP_DETAILS_MODAL, modalProps })),
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
  }
}

class MerchantUnderwritingReviewC extends Component {
  render() {
    const {
      runSubject,
      showUnmaskAction,
      subjectId,
      showGoogleMapDetailsModal,
      reviewCountry,
      bankAccountCurrency,
      bankAccountReportVersion,
    } = this.props

    const [
      businessName,
      doingBusinessAs,
      businessType,
      businessOwnershipType,
      businessIncorporationDate,
      businessWebsite,
      businessPhone,
      businessAddressLine1,
      businessAddressLine2,
      businessAddressCity,
      businessAddressRegion,
      businessAddressCountry,
      businessAddressPostalCode,
      businessTaxId,
      accountNumber,
      routingNumber,
      bankAccountName,
      bankAccountType,
      transitNumber,
      institutionNumber,
    ] = getMany(runSubject, [
      'business.name',
      'business.doing_business_as',
      'business.type',
      'business.ownership_type',
      'business.incorporation_date',
      'business.website_url',
      'business.phone',
      'business.address.line1',
      'business.address.line2',
      'business.address.city',
      'business.address.region',
      'business.address.country',
      'business.address.postal_code',
      'business.tax_id',
      'bank_accounts[0].account_number',
      'bank_accounts[0].bank_code',
      'bank_accounts[0].name',
      'bank_accounts[0].account_type',
      'bank_accounts[0].transit_number',
      'bank_accounts[0].institution_number',
    ])

    const formattedBusinessAddress = {
      line1: businessAddressLine1,
      line2: businessAddressLine2,
      city: businessAddressCity,
      region: businessAddressRegion,
      country: businessAddressCountry,
      postalCode: businessAddressPostalCode,
    }

    const businessInformationDataSection = [
      {
        type: 'data',
        data: [
          { label: BUSINESS_NAME, value: businessName },
          { label: DOING_BUSINESS_AS, value: doingBusinessAs },
          { label: BUSINESS_TYPE, value: snakeCaseToTitleCase({ key: businessType }) },
        ],
      },
      {
        type: 'data',
        data: [
          { label: OWNERSHIP_TYPE, value: snakeCaseToTitleCase({ key: businessOwnershipType }) },
          { label: INCORPORATION_DATE, value: formatDateObject({ date: businessIncorporationDate }) },
          {
            label: BUSINESS_TAX_ID,
            value: (showUnmaskAction && businessTaxId) ? (
              <DisplayUnmaskC
                id={subjectId}
                api={getUnderwritingSubjectUnmaskAPI}
                feKey='business_tax_id'
                beKey='business.tax_id'
                defaultValue={businessTaxId}
              />
            ) : businessTaxId,
          },
        ],
      },
      {
        type: 'data',
        data: [
          { label: WEBSITE, value: validateWebsiteLink({ website: businessWebsite }) ? <a className='text-link' href={businessWebsite} target='blank'>{businessWebsite}</a> : businessWebsite },
          { label: BUSINESS_PHONE, value: formatPhone(businessPhone) },
          { label: BUSINESS_ADDRESS, value: <div><Address address={formattedBusinessAddress} /><a className='text-link' onClick={() => showGoogleMapDetailsModal({ businessAddress: formattedBusinessAddress, doingBusinessAs, businessName })}>{MAP_DETAILS}</a></div> },
        ],
      },
    ]

    const bankAccountInformationDataSection = convertPageSectionDataToV2([
      { label: ACCOUNT_HOLDER_NAME, value: bankAccountName },
      { label: ACCOUNT_TYPE, value: snakeCaseToTitleCase({ key: bankAccountType }) },
      {
        label: ROUTING_NUMBER,
        value: routingNumber,
        condition: reviewCountry === USA,
      },
      {
        label: TRANSIT_NUMBER,
        value: transitNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: INSTITUTION_NUMBER,
        value: institutionNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: ACCOUNT_NUMBER,
        value: (showUnmaskAction && accountNumber) ? (
          <DisplayUnmaskC
            id={subjectId}
            api={getUnderwritingSubjectUnmaskAPI}
            feKey='bank_account_number'
            beKey='bank_accounts[0].account_number'
            defaultValue={accountNumber}
          />
        ) : accountNumber,
      },
      {
        label: CURRENCY,
        value: bankAccountCurrency,
      },
      {
        label: CONNECTION_TYPE,
        value: isEqual(bankAccountReportVersion, 'V2') ? PLAID : MANUAL_OPTION,
      },
    ])

    return (
      <MerchantUnderwritingReview
        {...this.props}
        businessInformationDataSection={businessInformationDataSection}
        bankAccountInformationDataSection={bankAccountInformationDataSection}
        businessType={businessType}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantUnderwritingReviewC)
