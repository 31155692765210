/* eslint no-debugger: 0 */
import { take, put, fork, cancel, call } from 'redux-saga/effects'
import redirectRequest from 'utilities/actions/redirectRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getMany from 'utilities/get/getMany'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/language/languageConstants'
import { HIDE_MODAL } from 'constants/actionConstants'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import get from 'lodash/get'
import head from 'lodash/head'

import {
  GET_CREDENTIALS_F_REQUEST,
  GET_CREDENTIALS_F_SUCCESS,
  GET_CREDENTIAL_F_REQUEST,
  GET_CREDENTIAL_F_SUCCESS,
  SHOW_SUCCESS_FLASH_NOTIFICATION,
  SHOW_ERROR_FLASH_NOTIFICATION,
  SHOW_API_ERROR_FLASH_NOTIFICATION,
  LOG_ERROR_MESSAGE,
} from 'constants/flowConstants'

const genericFlowLoop = function * ({
  saga,
  successF,
  failureF,
  successS,
  failureS,
  payload = {},
  meta = {},
  debug,
}) {
  try {
    if (debug) { debugger }

    const {
      type,
      payload: apiPayload,
      errors,
    } = yield call(saga, { payload, meta })

    if (type === successS) {
      if (debug) { debugger }

      const [
        page,
        links,
        newValues,
        orchestrationStatus,
      ] = getMany(apiPayload, [
        'page',
        'links',
        'newValues',
        'orchestrationStatus',
      ])

      yield put(removeUndefined({
        type: successF,
        payload: merge({}, payload, {
          page,
          links,
          newValues,
          orchestrationStatus,
        }),
        meta,
      }))

      const successRedirect = get(meta, 'successRedirect')

      if (successRedirect) {
        yield put(redirectRequest({ path: successRedirect }))
      }

      const successCallback = get(meta, 'successCallback')

      if (successCallback) {
        // TODO: see if there is anything else we would want to send back besides newValues
        successCallback({ newValues, meta })
      }

      const closeModal = get(meta, 'closeModal', false)

      if (closeModal) {
        yield put({
          type: HIDE_MODAL,
        })
      }

      const flashNotificationSuccessMessage = get(meta, 'flashNotification.success.message')
      const flashNotificationSuccessIcon = get(meta, 'flashNotification.success.icon')

      if (flashNotificationSuccessMessage) {
        yield put({
          type: SHOW_SUCCESS_FLASH_NOTIFICATION,
          payload: removeUndefined({
            values: {
              message: flashNotificationSuccessMessage,
              icon: flashNotificationSuccessIcon,
            },
          }),
        })
      }
    } else {
      if (debug) { debugger }
      const orchestrationStatus = get(apiPayload, 'orchestrationStatus')

      yield put(removeUndefined({
        type: failureF,
        payload: merge({}, payload, { orchestrationStatus }),
        meta,
        errors,
      }))

      const [
        failureRedirect,
        errorCallback,
        showErrors = true,
        failMessage,
      ] = getMany(meta, [
        'failureRedirect',
        'errorCallback',
        'showErrors',
        'failMessage',
      ])

      if (failureRedirect) {
        yield put(redirectRequest({ path: failureRedirect }))
      }

      if (errorCallback) {
        errorCallback(errors)
      }

      const flashNotificationErrorMessage = get(meta, 'flashNotification.error.message')
      const flashNotificationErrorIcon = get(meta, 'flashNotification.error.icon')

      if (flashNotificationErrorMessage) {
        yield put({
          type: SHOW_ERROR_FLASH_NOTIFICATION,
          payload: removeUndefined({
            values: {
              message: flashNotificationErrorMessage,
              icon: flashNotificationErrorIcon,
            },
          }),
        })

        yield put({
          type: LOG_ERROR_MESSAGE,
          errors,
        })
      } else if (!isEmpty(errors) && showErrors) {
        yield put({
          type: SHOW_API_ERROR_FLASH_NOTIFICATION,
          payload: {
            values: {
              message: get(head(errors), 'message', GENERIC_API_ERROR_MESSAGE),
            },
          },
        })

        yield put({
          type: LOG_ERROR_MESSAGE,
          errors,
        })
      }
    }
  } catch (error) {
    if (debug) { debugger }

    yield put(removeUndefined({
      type: failureF,
      payload,
      meta,
      error,
    }))

    const failureRedirect = get(meta, 'failureRedirect')

    if (failureRedirect) {
      yield put(redirectRequest({ path: failureRedirect }))
    }
  }
}

const genericFlow = ({
  saga,
  requestF,
  successF,
  failureF,
  successS,
  failureS,
  debug,
}) => {
  return function * () {
    let lastTask = null

    while (true) {
      const {
        type,
        payload = {},
        meta = {},
      } = yield take(requestF)

      const { concurrent = false } = meta

      if (lastTask && !concurrent) {
        yield cancel(lastTask)
      }

      const { credentials } = payload

      const dashboardServiceV2 = isDashboardServiceV2()
      const credentialFlow = dashboardServiceV2 ? GET_CREDENTIAL_F_REQUEST : GET_CREDENTIALS_F_REQUEST
      const credentialFlowSuccess = dashboardServiceV2 ? GET_CREDENTIAL_F_SUCCESS : GET_CREDENTIALS_F_SUCCESS

      // TODO (FE-2344): add refactor to deprecate requiring credentials for all flows, getRequests, etc
      // if we need credentials but do not have them, wait until GET_CREDENTIALS_F_SUCCESS to continue flow work
      if (type !== credentialFlow && credentials && isEmpty(credentials)) {
        const { payload: credentialsPayload } = yield take(credentialFlowSuccess)
        const credentialId = get(credentialsPayload, 'id')
        const newCredentials = get(credentialsPayload, `newValues.${credentialId}`)
        const mergedPayload = merge({}, payload, { credentials: newCredentials })

        lastTask = yield fork(genericFlowLoop, {
          saga,
          successF,
          failureF,
          successS,
          failureS,
          payload: mergedPayload,
          meta,
          debug,
        })
      } else {
        lastTask = yield fork(genericFlowLoop, {
          saga,
          successF,
          failureF,
          successS,
          failureS,
          payload,
          meta,
          debug,
        })
      }
    }
  }
}

export default genericFlow
