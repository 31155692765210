import './CreateRoleFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import RoleFieldset from 'components/Shared/Fieldsets/RoleFieldset/RoleFieldset'
import Button from 'components/Shared/Button/Button'
import validateCreateRoleForm from 'utilities/validate/validateCreateRoleForm'
import { CREATE_ROLE_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'

import {
  CANCEL,
  CUSTOMIZE_PERMISSIONS_MESSAGE,
} from 'constants/language/languageConstants'

const CreateRoleForm = ({
  handleSubmit = () => {},
  currentSelectedEvents,
  buttonText = '',
  permissionsError = '',
  authRole,
  submitFunction = () => {},
  invalid,
}) => {
  return (
    <form className='CreateRoleForm' onSubmit={handleSubmit(submitFunction)}>
      <div className='section'>
        <div>{CUSTOMIZE_PERMISSIONS_MESSAGE}</div>

        <RoleFieldset
          currentSelectedEvents={currentSelectedEvents}
          formName={CREATE_ROLE_FORM}
          permissionsError={permissionsError}
          authRole={authRole}
        />
      </div>

      <div className='sticky-footer'>
        <div className='btn-container flex flex-end items-center'>
          <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button className='submit-button' type='submit' label={buttonText} disabled={invalid} />
        </div>
      </div>
    </form>
  )
}

CreateRoleForm.propTypes = {
  handleSubmit: PropTypes.func,
  currentSelectedEvents: PropTypes.object,
  buttonText: PropTypes.string,
  permissionsError: PropTypes.string,
  authRole: PropTypes.string,
  submitFunction: PropTypes.func,
}

export default reduxForm({
  form: CREATE_ROLE_FORM,
  validate: validateCreateRoleForm,
  enableReinitialize: true,
})(CreateRoleForm)
