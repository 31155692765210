import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditFeeProfileSettingsC from 'components/Customer/Pages/FeeProfile/EditFeeProfileSettings/EditFeeProfileSettingsC'

const EditFeeProfileSettingsModal = ({
  modalTitle = '',
  modalText = '',
  buttonText = '',
  buttonVariant = '',
  fieldName = '',
  currency = '',
  currentFeeProfileSettings = {},
  hasNoFeeProfileSettings = false,
  applicationId = '',
}) => {
  return (
    <GenericModal
      title={modalTitle}
      modalText={modalText}
      buttonText={buttonText}
      buttonVariant={buttonVariant}
      fieldName={fieldName}
      currency={currency}
      currentFeeProfileSettings={currentFeeProfileSettings}
      hasNoFeeProfileSettings={hasNoFeeProfileSettings}
      applicationId={applicationId}
      Component={EditFeeProfileSettingsC}
    />
  )
}

EditFeeProfileSettingsModal.propTypes = {
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  fieldName: PropTypes.string,
  currency: PropTypes.string,
  currentFeeProfileSettings: PropTypes.object,
  hasNoFeeProfileSettings: PropTypes.bool,
  applicationId: PropTypes.string,
}

export default EditFeeProfileSettingsModal
