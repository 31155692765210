import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminUser from './AdminUser'
import showModalAction from 'utilities/actions/showModalAction'
import getAdminUserRequest from 'utilities/actions/get/getAdminUserRequest'
import getAdminUserCredentialsRequest from 'utilities/actions/get/getAdminUserCredentialsRequest'
import getMany from 'utilities/get/getMany'
import { SEND_EMAIL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  getAdminUserSelector,
  getAdminUserCredentialsByUserId,
} from 'state-layer/selectors'

import {
  CREATED_ON,
  DASHBOARD_USER,
  SEND_EMAIL as SEND_EMAIL_LABEL,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `membershipsR.${FETCHING}`)
  const userId = get(props, 'params.userId')
  const user = getAdminUserSelector(state, userId)

  const [
    displayCreatedAt,
    email,
  ] = getMany(user, [
    'displayCreatedAt',
    'email',
  ])

  const userCredentials = getAdminUserCredentialsByUserId(state, userId)

  const headerData = {
    resource: {
      label: DASHBOARD_USER,
      id: userId,
      clickToCopyPosition: 'right',
    },
    items: [
      {
        value: <h1>{email}</h1>,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    userId,
    user,
    userCredentials,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminUser: (userId) => dispatch(getAdminUserRequest(userId)),
    getAdminUserMemberships: (userId) => dispatch(getAdminUserCredentialsRequest({ userId, queries: { limit: 100 } })),
    showSendEmailModal: (modalProps) => dispatch(showModalAction({ modalType: SEND_EMAIL, modalProps })),
  }
}

class AdminUserC extends Component {
  componentDidMount() {
    const { userId, getAdminUser, getAdminUserMemberships } = this.props
    getAdminUser(userId)
    getAdminUserMemberships(userId)
  }

  render() {
    const { user, showSendEmailModal } = this.props

    const actions = [
      {
        label: SEND_EMAIL_LABEL,
        action: () => showSendEmailModal({ user }),
      },
    ]

    return (
      <AdminUser
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserC)
