import './WelcomeEmailFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import submitWelcomeEmailForm from 'utilities/submit/submitWelcomeEmailForm'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import { IMAGE_FILE_ICON } from 'constants/iconConstants'
import { SAVE } from 'constants/language/languageConstants'

const WelcomeEmailForm = ({
  handleSubmit = () => {},
  submitting = false,
  invalid = false,
  config,
  instructions,
}) => {
  return (
    <form className='WelcomeEmailForm' onSubmit={handleSubmit(submitWelcomeEmailForm)}>
      <div className='section'>
        <h3>Welcome Email Template</h3>

        <Field
          name='subject'
          label='Subject'
          component={InputField}
        />

        <Field
          name='html'
          label='HTML Body'
          placeholder='HTML Body'
          component={TextAreaField}
        />

        <Field
          name='logo'
          label='Logo'
          config={config}
          instructions={instructions}
          component={FileUploader}
          fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
        />

        <Field
          name='text'
          label='Text Body (when HTML is not Supported)'
          placeholder='Text Body (when HTML is not Supported)'
          component={TextAreaField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} disabled={invalid} label={SAVE} />
      </div>
    </form>
  )
}

WelcomeEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
}

export default WelcomeEmailForm
