import './MerchantVerificationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import Button from 'components/Shared/Button/Button'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  VERIFICATION_DETAILS,
  CREATED_ON,
  STATE,
  PROCESSOR,
  RESULT,
  ID,
  VIEW_API_RESPONSE,
  VERIFICATION_DETAILS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const MerchantVerifications = ({
  title = VERIFICATION_DETAILS,
  actions = [],
  verifications = [],
  showVerificationResultsModal = () => {},
}) => {
  return (
    <div className='MerchantVerifications Table'>
      <div className='table-header-area flex space-between items-center'>
        <h4>{title}</h4>

        {map(actions, ({ label, action, className }) => <Button className={className} onClick={action} label={label} variant='secondary' />)}
      </div>

      {isEmpty(verifications) ? VERIFICATION_DETAILS_EMPTY_TABLE_MESSAGE : (
        <table>
          <thead>
            <tr>
              <th className='bold'>{ID}</th>
              <th className='bold'>{CREATED_ON}</th>
              <th className='bold'>{STATE}</th>
              <th className='bold'>{PROCESSOR}</th>
              <th className='bold'>{RESULT}</th>
            </tr>
          </thead>
          <tbody>
            {map(verifications, (verification) => {
              const [
                id,
                displayCreatedAt,
                state,
                processor,
              ] = getMany(verification, [
                'id',
                'displayCreatedAt',
                'state',
                'processor',
              ])

              return (
                <tr>
                  <td><ClickToCopyC position='right' content={id} /></td>
                  <td>{displayCreatedAt}</td>
                  <td><TransferStatus value={state} /></td>
                  <td>{snakeCaseToTitleCase({ key: processor })}</td>
                  <td>
                    <div className='view-processor-details' onClick={() => showVerificationResultsModal({ verification })}>
                      {VIEW_API_RESPONSE}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

MerchantVerifications.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  verifications: PropTypes.array,
  showVerificationResultsModal: PropTypes.func,
}

export default MerchantVerifications
