import './DeleteEnrollmentsConfirmationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import AmountsDisplaySection from 'components/Customer/Shared/Page/PageSection/AmountsDisplaySection'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import { STARTED_AT_LABEL, ENDED_AT_LABEL } from 'constants/language/languageConstants'

const DeleteEnrollmentsConfirmationModal = ({
  enrollments = [],
}) => {
  return (
    <div className='DeleteEnrollmentsConfirmationModal'>
      <table>
        <tbody>
          <tr>
            <th>Merchant</th>
            <th>Enrollment Nickname</th>
            <th>Schedule Nickname</th>
            <th>Schedule Type</th>
            <th>Schedule</th>
            <th>Schedule Amounts</th>
            <th>{STARTED_AT_LABEL}</th>
            <th>{ENDED_AT_LABEL}</th>
          </tr>

          {
          map(enrollments, (enrollment) => {
            const [
              id,
              enrollmentNickname,
              displayStartedAt,
              displayEndedAt,
              schedule,
              merchantName,
            ] = getMany(enrollment, [
              'id',
              'enrollmentNickname',
              'displayStartedAt',
              'displayEndedAt',
              'schedule',
              'identity.displayBusinessName',
            ])

            const [
              scheduleNickname,
              subscriptionType,
              periodOffset,
              fixedTimeIntervalOffset,
              amounts,
            ] = getMany(schedule, [
              'scheduleNickname',
              'subscriptionType',
              'periodOffset',
              'fixedTimeIntervalOffset',
              'amounts',
            ])

            return (
              <tr key={id}>
                <td>{merchantName}</td>
                <td>{enrollmentNickname}</td>
                <td>{scheduleNickname}</td>
                <td>{subscriptionScheduleTypeMapping[subscriptionType]}</td>
                <td>{convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset })}</td>
                <td>{AmountsDisplaySection({ data: amounts })}</td>
                <td>{displayStartedAt}</td>
                <td>{displayEndedAt}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}

DeleteEnrollmentsConfirmationModal.propTypes = {
  enrollments: PropTypes.array,
}

export default DeleteEnrollmentsConfirmationModal
