import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import { TIME } from 'constants/language/languageConstants'
import join from 'lodash/join'

const DateTimestampHeader = ({
  title = '',
}) => {
  const timezoneName = moment.tz.guess()
  const timezoneAbbr = moment.tz(timezoneName).format('z')
  const timeString = join([TIME, `(${timezoneAbbr})`], ' ')

  return (
    <div className='DateTimestampHeader'>
      <TableItemSubtitle title={title} subtitle={timeString} />
    </div>
  )
}

DateTimestampHeader.propTypes = {
  title: PropTypes.string,
}

export default DateTimestampHeader
