import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { TRIPOS_CLOUD_V1 } from 'constants/processorConstants'
import { DEVICE_IDLE_IMAGE } from 'constants/fileConstants'
import { SET_IDLE_IMAGE_SUCCESS_MSG } from 'constants/language/languageConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'

import {
  PATCH_DEVICE_F_REQUEST,
  POST_MERCHANT_FILE_F_REQUEST,
} from 'constants/flowConstants'

import {
  SET_IDLE_SCREEN,
  UPLOAD_MERCHANT_DEVICE_FILE,
} from 'constants/amplitudeConstants'

const submitSetIdleScreenForm = (values, dispatch, props) => {
  const [
    idleMessage,
    file,
  ] = getMany(values, [
    'idleMessage',
    'file',
  ])

  const [
    deviceId,
    credentials,
    deviceName,
    actionType,
    processorGatewayValue,
    merchantId,
  ] = getMany(props, [
    'deviceId',
    'credentials',
    'deviceName',
    'actionType',
    'processorGatewayValue',
    'merchantId',
  ])

  const name = get(file, 'name')
  const nameOfFile = `${deviceId}_${name}`

  // if we have a file, we upload it but if we don't we just send the request to edit device
  if (file) {
    dispatch({
      type: POST_MERCHANT_FILE_F_REQUEST,
      payload: {
        values: {
          file,
          nameOfFile,
          documentType: DEVICE_IDLE_IMAGE,
          merchantId,
        },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(UPLOAD_MERCHANT_DEVICE_FILE, {
          credentials,
          merchantId,
          documentType: DEVICE_IDLE_IMAGE,
        }),
        successCallback: ({ newValues }) => {
          const fileId = head(keys(newValues))

          dispatch({
            type: PATCH_DEVICE_F_REQUEST,
            payload: {
              id: deviceId,
              credentials,
              values: {
                idleMessage,
                idleImageFileId: fileId,
                action: processorGatewayValue === TRIPOS_CLOUD_V1 ? actionType : undefined,
                processorGateway: processorGatewayValue,
              },
            },
            meta: {
              successCallback: () => {
                dispatch(hideModalAction())
              },
              actionId: sendAmplitudeActionEvent(SET_IDLE_SCREEN, {
                credentials,
              }),
              successMessage: `${deviceName} has been successfully updated.`,
            },
          })
        },
        successMessage: SET_IDLE_IMAGE_SUCCESS_MSG,
      },
    })
  }

  if (!file) {
    dispatch({
      type: PATCH_DEVICE_F_REQUEST,
      payload: {
        id: deviceId,
        credentials,
        values: {
          idleMessage,
          action: processorGatewayValue === TRIPOS_CLOUD_V1 ? actionType : undefined,
          processorGateway: processorGatewayValue,
        },
      },
      meta: {
        successCallback: () => {
          dispatch(hideModalAction())
        },
        actionId: sendAmplitudeActionEvent(SET_IDLE_SCREEN, {
          credentials,
        }),
        successMessage: `${deviceName} has been successfully updated.`,
      },
    })
  }
}

export default submitSetIdleScreenForm
