import redirectRequest from 'utilities/actions/redirectRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/language/languageConstants'
import { AUTHORIZATION_PATH, PAYMENT_PATH } from 'constants/pathConstants'
import { EMAIL, SMS, PRINT } from 'constants/receiptConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  POST_RECEIPT_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

const submitCreateReceiptForm = (values, dispatch, props) => {
  const { credentials } = props

  const {
    subtotal,
    tax,
    discount,
    shipping,
    tip,
    surcharge,
    convenienceFee,
    rentSurcharge,
    subtotalAmount,
    taxAmount,
    discountAmount,
    shippingAmount,
    tipAmount,
    surchargeAmount,
    convenienceFeeAmount,
    rentSurchargeAmount,
    items,
    productDetails,
    sendMethodEmail,
    sendMethodSMS,
    sendMethodPrint,
    phoneNumbers,
    buyerEmails,
    sendReceiptToBuyer,
    device,
  } = values

  const sendMethods = []
  if (sendMethodEmail) sendMethods.push(EMAIL)
  if (sendMethodSMS) sendMethods.push(SMS)
  if (sendMethodPrint) sendMethods.push(PRINT)

  const amountBreakdown = {
    subtotalAmount: subtotal ? subtotalAmount : undefined,
    taxAmount: tax ? taxAmount : undefined,
    discountAmount: discount ? discountAmount : undefined,
    shippingAmount: shipping ? shippingAmount : undefined,
    tipAmount: tip ? tipAmount : undefined,
    surchargeAmount: surcharge ? surchargeAmount : undefined,
    convenienceFeeAmount: convenienceFee ? convenienceFeeAmount : undefined,
    rentSurchargeAmount: rentSurcharge ? rentSurchargeAmount : undefined,
  }

  const transferId = get(props, 'params.transferId')
  const authorizationId = get(props, 'params.authorizationId')
  const entityId = transferId ? transferId : authorizationId

  dispatch({
    type: POST_RECEIPT_F_REQUEST,
    payload: {
      credentials,
      values: { ...removeUndefined({
        entityId,
        amountBreakdown,
        items: productDetails ? items : undefined,
        phoneNumbers: sendMethodSMS ? map(phoneNumbers, ({ value }) => value) : undefined,
        emails: sendMethodEmail ? map(buyerEmails, ({ value }) => value) : undefined,
        sendMethods,
        sendReceiptToBuyer,
        devices: sendMethodPrint ? [get(device, 'value')] : undefined,
      }) },
    },
    meta: {
      successCallback: () => {
        let redirectPath
        if (transferId) {
          redirectPath = PAYMENT_PATH({ credentialId: get(credentials, 'id'), transferId: get(props, 'params.transferId') })
        } else if (authorizationId) {
          redirectPath = AUTHORIZATION_PATH({ credentialId: get(credentials, 'id'), authorizationId: get(props, 'params.authorizationId') })
        }
        dispatch(redirectRequest({ path: redirectPath }))
      },
      errorCallback: () => {
        dispatch({
          type: SHOW_ERROR_FLASH_NOTIFICATION,
          payload: {
            message: GENERIC_API_ERROR_MESSAGE,
          },
        })
      },
    },
  })
}

export default submitCreateReceiptForm
