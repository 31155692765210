import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  FINIX_VERSION,
  FINIX_VERSION_2022_02_01,
  SETTLEMENTS_BASE,
} from 'constants/apiConstants'

const getSplitTransferSettlementAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    path: SETTLEMENTS_BASE,
    queries,
    credentials,
    meta,
    service: SETTLEMENTS_BASE,
    headers: {
      [FINIX_VERSION]: FINIX_VERSION_2022_02_01,
    },
  })
}

export default getSplitTransferSettlementAPI
