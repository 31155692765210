// 2022 - Q3
export const SHOW_LANDING_PAGE = 'showLandingPage' // https://app.launchdarkly.com/default/production/features/Show-Landing-Page/targeting
export const SHOW_REPORTS_LANDING = 'showReportsLanding' // https://app.launchdarkly.com/default/production/features/Show-Reports-Landing/targeting
export const SHOW_PAYOUT_SETTINGS = 'showPayoutSettings' // https://app.launchdarkly.com/default/production/features/Show-Payout-Settings/targeting
export const SHOW_NOTIFICATIONS = 'showNotifications' // https://app.launchdarkly.com/default/production/features/Show-Notifications/targeting
export const SHOW_ACH_RETURNS = 'showAchReturns' // https://app.launchdarkly.com/default/production/features/Show-Async-Export/targeting
export const SHOW_MERCHANT_DEPOSITS = 'showMerchantDeposits' // https://app.launchdarkly.com/default/production/features/Show-Merchant-Deposits/targeting
export const SHOW_NEW_FEE_PROFILE = 'showNewFeeProfile'// https://app.launchdarkly.com/default/production/features/Show-New-Fee-Profile/targeting - removed from code 3/7/23
export const SHOW_API_LOGS = 'showApiLogs' // https://app.launchdarkly.com/default/production/features/Show-API-Logs/targeting
export const PAYOUT_SETTINGS_SAME_DAY_ACH_ALLOWED = 'payoutSettingsSameDayAchAllowed' // https://app.launchdarkly.com/default/production/features/Payout-Settings-Same-Day-ACH-Allowed/targeting
export const SHOW_ACH_RETURN_NOTIFICATION_SETTING = 'showAchReturnNotificationSetting' // https://app.launchdarkly.com/default/production/features/Show-ACH-Return-Notification-Setting/targeting
// 2022 - Q4
export const SHOW_REPORTS = 'showReports' // https://app.launchdarkly.com/default/production/features/Show-Reports/targeting
// 2023 - Q1
export const SHOW_WEBHOOK_LOGS = 'showWebhookLogs' // https://app.launchdarkly.com/default/production/features/Show-Webhook-Logs/targeting
// 2023 - Q2
export const SHOW_ELAVON_UNDERWRITING = 'showElavonUnderwriting'
export const SHOW_NET_PROFIT_TABLE = 'showNetProfitTable'
// 2023 - Q4
export const SHOW_MFA = 'showMfa' // https://app.launchdarkly.com/default/production/features/Show-MFA/targeting
// 2023 - Q4
export const SHOW_VIRTUAL_TERMINAL = 'showVirtualTerminal' // https://app.launchdarkly.com/default/production/features/Show-Virtual-Terminal/targeting
export const SHOW_PAYMENT_LINKS = 'showPaymentLinks' // https://app.launchdarkly.com/default/production/features/Show-Payment-Links/targeting
// 2024 -Q1
export const SHOW_ACH_VIRTUAL_TERMINAL = 'showAchVirtualTerminal' // https://app.launchdarkly.com/default/production/features/Show-ACH-Virtual-Terminal/targeting
export const SHOW_DEVICE_MANAGEMENT = 'showDeviceManagement' // https://app.launchdarkly.com/default/production/features/Show-Device-Management/targeting
export const SHOW_SIFT_FRAUD_SCORES = 'showSiftFraudScores' // https://app.launchdarkly.com/default/production/features/Show-Sift-Fraud-Scores/targeting
export const SHOW_PREMIUM_SIFT_FRAUD_SCORES = 'showPremiumSiftFraudScores' // https://app.launchdarkly.com/default/production/features/Show-Premium-Sift-Fraud-Scores/targeting
export const SHOW_ENTERPRISE_SIFT_FRAUD_SCORES = 'showEnterpriseSiftFraudScores' // https://app.launchdarkly.com/default/production/features/Show-Enterprise-Sift-Fraud-Scores/targeting
// 2024 - Q2
export const SHOW_SETTLEMENT_BATCH_TIME_EDIT = 'showSettlementBatchTimeEdit' // https://app.launchdarkly.com/default/production/features/Show-Settlement-Batch-Time-Edit/targeting
export const SHOW_SUBSCRIPTION_BILLING = 'showSubscriptionBilling' // https://app.launchdarkly.com/projects/default/flags/Show-Subscription-Billing/targeting
export const SHOW_FEE_PROFILE_DUES_ASSESSMENTS = 'showFeeProfileDuesAssessments' //https://app.launchdarkly.com/projects/default/flags/Show-Fee-Profile-Dues-Assessments/targeting
export const SHOW_INSTANT_PAYOUTS = 'showInstantPayouts' // https://app.launchdarkly.com/projects/default/flags/Show-Instant-Payouts/targeting
