import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import downloadReport from 'utilities/downloadReport'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { createdAtRange } from 'utilities/table/sort'
import { SHOW_REPORTS_LANDING } from 'constants/featureFlagConstants'
import { TYPE_CSV } from 'constants/fileConstants'
import capitalize from 'lodash/capitalize'

import {
  CREATED_ON,
  DOWNLOAD_LINK,
  FREQUENCY,
  ID,
  REPORT_NAME,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(REPORT_NAME, 'reportTitle', {
    className: 'x-large',
    condition: () => getFeatureFlag(SHOW_REPORTS_LANDING),
  }),
  column(FREQUENCY, 'periodType', {
    className: 'small',
    condition: () => getFeatureFlag(SHOW_REPORTS_LANDING),
    formatter: ({ periodType }) => capitalize(periodType),
  }),
  column(DOWNLOAD_LINK, ['id', 'reportTitle'], {
    className: 'x-large',
    formatter: (data) => {
      const { id, reportTitle, credentials, fileName, createdAt } = data
      const name = fileName || `${reportTitle}.csv`

      // TODO/NOTE: commented out for MVP release as file viewing might not be possible from an API perspective due to authorization access restrictions
      const fileViewerModalProps = {
        // src,
        displayType: TYPE_CSV,
        displayName: fileName,
        displayCreatedAt: createdAt,
      }

      return (
        <div className='flex items-center'>
          <span className='link' onClick={() => downloadReport({ id, name, credentials })}>{name}</span>
          {/* <span className='report-file-viewer-link fal fa-eye' onClick={() => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps: fileViewerModalProps }))} /> */}
        </div>
      )
    },
  }),
]

export default columnDescriptors
