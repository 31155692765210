import './UpdateBalanceAdjustmentsVelocityLimitsFormS.scss'
import React from 'react'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Loader from 'components/Shared/Loader/Loader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import formatNumberFormatField from 'utilities/forms/format/formatNumberFormatField'
import validateUpdateBalanceAdjustmentsVelocityLimitsForm from 'utilities/validate/validateUpdateBalanceAdjustmentsVelocityLimitsForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'

import {
  timeZoneOptions,
  startTimeOptions,
  monthStartDayOptions,
  meridianOptions,
} from 'constants/timeConstants'

import {
  CANCEL,
  TIME_ZONE,
  START_TIME,
  WINDOW_START_TIME,
  WINDOW_START_TIME_DESCRIPTION,
  DAILY_LIMITS,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_LIMITS,
  MONTHLY_START_DATE,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  PLEASE_COMPLETE_ALL_FIELDS,
  TRANSACTION_DETAILS,
  MAX_TRANSACTION_AMOUNT_FOR_ACH,
  SET_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS,
  NEXT_STEP,
  PREVIOUS_STEP,
  EDIT_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS,
  SET_VELOCITY_LIMITS,
} from 'constants/language/languageConstants'

const UpdateBalanceAdjustmentsVelocityLimitsForm = ({
  subtitle,
  handleSubmit = () => {},
  isFormInvalid = false,
  isFetchingApplication = false,
  customSubmitFunc = () => {},
  isSetLimitsFlow = false,
  invalid = false,
}) => {
  return (
    <form className='UpdateBalanceAdjustmentsVelocityLimitsForm' onSubmit={isSetLimitsFlow ? handleSubmit(customSubmitFunc) : handleSubmit}>
      <div className='form-header'>
        <h3>{isSetLimitsFlow ? SET_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS : EDIT_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS}</h3>
        {subtitle && !isFetchingApplication && <div className='p-1 subtitle'>{subtitle}</div>}
        {isFetchingApplication && <Loader />}
      </div>

      <div className='form-content'>
        <RequiredFieldNotice />
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_ALL_FIELDS} />}

        <h5>{TRANSACTION_DETAILS}</h5>

        <AmountField
          name='maxTransactionAmountForACH'
          className='max-transaction-amount-for-ach-field'
          label={MAX_TRANSACTION_AMOUNT_FOR_ACH}
          contextLabel={USD}
        />

        <h5>{WINDOW_START_TIME}</h5>
        <div className='description label-2'>{WINDOW_START_TIME_DESCRIPTION}</div>

        <div className='time-section flex'>
          <Field
            name='startTime'
            label={START_TIME}
            component={ReactSelect}
            options={startTimeOptions}
            classNames={['start-time']}
          />

          <Field
            name='timeMeridian'
            classNames={['time-meridian']}
            label='hidden label'
            hiddenLabel
            component={ReactSelect}
            options={meridianOptions}
            required={false}
          />

          <Field
            name='timeZone'
            label={TIME_ZONE}
            classNames={['time-zone']}
            component={ReactSelect}
            options={timeZoneOptions}
            isDisabled
          />
        </div>

        <h5>{DAILY_LIMITS}</h5>

        <div className='daily-ach-section flex space-between'>
          <Field
            name='dailyACHCount'
            className='daily-ach-count'
            label={DAILY_ACH_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name='dailyACHVolume'
            className='daily-ach-volume'
            label={DAILY_ACH_VOLUME}
            component={InputField}
          />
        </div>

        <h5>{MONTHLY_LIMITS}</h5>

        <Field
          name='monthlyStartDate'
          label={MONTHLY_START_DATE}
          component={ReactSelect}
          options={monthStartDayOptions}
        />

        <div className='monthly-ach-section flex space-between'>
          <Field
            name='monthlyACHCount'
            label={MONTHLY_ACH_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name='monthlyACHVolume'
            label={MONTHLY_ACH_VOLUME}
            component={InputField}
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          {isSetLimitsFlow && (
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          )}
          <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' />
          <Button className='submit-button' type='submit' disabled={invalid} label={isSetLimitsFlow ? SET_VELOCITY_LIMITS : NEXT_STEP} />
        </div>
      </div>
    </form>
  )
}

UpdateBalanceAdjustmentsVelocityLimitsForm.propTypes = {
  subtitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  isFetchingApplication: PropTypes.bool,
  customSubmitFunc: PropTypes.func,
  isSetLimitsFlow: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM,
  validate: validateUpdateBalanceAdjustmentsVelocityLimitsForm,
})(UpdateBalanceAdjustmentsVelocityLimitsForm)
