import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  GO_TO_THE_PREVIOUS_STEP,
  GO_TO_THE_PREVIOUS_STEP_DESCRIPTION,
  GO_TO_THE_PREVIOUS_STEP_QUESTION,
} from 'constants/language/languageConstants'

const PreviousStep = ({
  goToPreviousStep = () => {},
  closeModal = () => {},
}) => {
  return (
    <div className='PreviousStep'>
      <div className='message'>
        <div>{GO_TO_THE_PREVIOUS_STEP_QUESTION}</div>
        <br />
        <div>{GO_TO_THE_PREVIOUS_STEP_DESCRIPTION}</div>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={goToPreviousStep} label={GO_TO_THE_PREVIOUS_STEP} />
      </div>
    </div>
  )
}

PreviousStep.propTypes = {
  goToPreviousStep: PropTypes.func,
  closeModal: PropTypes.func,
}

export default PreviousStep
