import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateNewRun from './CreateNewRun'
import hideModalAction from 'utilities/actions/hideModalAction'
import postUnderwritingReviewRunsRequest from 'utilities/actions/post/postUnderwritingReviewRunsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isPatching from 'utilities/is/isPatching'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isPatchingReview = isPatching(state)
  const reviewId = get(props, 'reviewId')
  const credentials = getCurrentCredentials(state)

  return {
    isPatchingReview,
    reviewId,
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    postUnderwritingReviewRuns: (actionProps) => dispatch(postUnderwritingReviewRunsRequest(actionProps)),
  }
}

class CreateNewRunC extends Component {
  createNewRunFunc = () => {
    const {
      reviewId,
      credentials,
      postUnderwritingReviewRuns,
      closeModal,
      entityId,
      subjectId,
    } = this.props

    postUnderwritingReviewRuns({
      id: reviewId,
      credentials,
      closeModal,
      entityId,
      subjectId,
    })
  }

  render() {
    return (
      <CreateNewRun
        {...this.props}
        createNewRunFunc={this.createNewRunFunc}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewRunC)
