export const APPLE_PAY_FINIX_DEV_DOCS_URL = 'https://finix.com/docs/guides/payments/alternative-payment-methods/apple-pay/'
export const APPLE_PAY_FINIX_DASHBOARD_REGISTRATION_DEV_DOCS_URL = 'https://finix.com/docs/guides/payments/alternative-payment-methods/apple-pay/apple-pay-on-ios/'
export const APPLE_PAY_DEVELOPER_PORTAL_URL = 'https://developer.apple.com/account/resources/identifiers/list/merchant'
export const GOOGLE_PAY_DEVELOPER_PORTAL_URL = 'https://finix.com/docs/guides/payments/alternative-payment-methods/google-pay/'
export const FINIX_SUPPORT_HELP_URL = 'https://support.finixpayments.com/hc/en-us/requests/new'
export const FINIX_SUPPORT_DOCS_URL = 'https://finix.com/docs/'
export const PAYMENTS_LEARN_MORE_LINK = 'https://finix.com/docs/api/tag/Transfers/'
export const REFUNDS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/after-the-payment/refunding-and-cancelling-payments/'
export const SETTLEMENTS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payouts/'
export const DISPUTES_LEARN_MORE_LINK = 'https://finix.com/docs/guides/after-the-payment/managing-disputes/'
export const MERCHANT_IDENTITIES_LEARN_MORE_LINK = 'https://finix.com/docs/guides/getting-started/account-structure/#identities'
export const MERCHANT_ACCOUNTS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/getting-started/account-structure/#merchants'
export const MERCHANT_UNDERWRITING_LEARN_MORE_LINK = 'https://finix.com/docs/guides/onboarding/identity-verification/'
export const SUBSCRIPTION_BILLING_LEARN_MORE_LINK = 'https://finix.com/docs/guides/after-the-payment/subscription-billing/'
export const PCI_COMPLIANCE_LEARN_MORE_LINK = 'https://finix.com/docs/guides/security-and-compliance/pci-dss-compliance/'
export const APPLICATIONS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/developers/account-structure/#applications'
export const PAYOUTS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payouts/'
export const MERCHANT_DEPOSITS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payouts/'
export const WEBHOOKS_AUTHENTICATION_LEARN_MORE_LINK = 'https://finix.com/docs/guides/developers/webhooks/#authenticating-webhooks'
export const WEBHOOK_EVENTS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/developers/webhooks/webhook-events/'
export const ONBOARDING_FORMS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/onboarding/onboarding-forms/'
export const L2_L3_PROCESSING_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payments/making-a-payment/level-2-and-level-3-processing/'
export const DASHBOARD_BRANDING_LEARN_MORE_LINK = 'https://finix.com/docs/guides/your-account/dashboard/white-labeling-and-customizing-finix-dashboards'
export const INTEGRATION_OVERVIEW_LEARN_MORE_LINK = 'https://finix.com/docs/guides/getting-started/integration-overview/'
export const LIVE_ACCOUNT_APPLICATION_PRICING_LINK = 'https://finix.com/pricing'
export const LIVE_ACCOUNT_APPLICATION_PRICING_LINK_PLATFORMS = 'https://finix.com/pricing/platforms'
export const STANDALONE_MERCHANT_LIVE_ACCOUNT_APPLICATION_PRICING_LINK = 'https://finix.com/pricing/individual-businesses/details'
export const DISPUTE_EVIDENCE_LEARN_MORE_LINK = 'https://finix.com/docs/guides/after-the-payment/disputes/responding-disputes/'
export const ACCEPTING_LIABILITY_LEARN_MORE_LINK = 'https://finix.com/docs/guides/after-the-payment/disputes/accepting-disputes/'
export const APPROVED_MERCHANTS_LINK = 'https://finix.com/docs/guides/business/security-and-compliance/approved-merchants/'
export const VIRTUAL_TERMINAL_DOCS_LINK = 'https://finix.com/docs/guides/payments/virtual-terminal/'
export const SENDING_PAYOUTS_DOCS_LINK = 'https://finix.com/docs/guides/payouts/payouts-getting-started/'
export const RECIPIENTS_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payouts/verification-process/'
export const BUSINESS_ANALYTICS_LINK = 'https://finix.com/docs/guides/business/analytics/'
export const PAYMENT_LINK_DOCS_LINK = 'https://finix.com/docs/guides/payments/modify/payment-links/'
export const VIRTUAL_TERMINAL_L2_L3_LEAR_MORE_LINK = 'https://finix.com/docs/guides/payments/modify/level-2-and-level-3-processing/'
export const PAYMENT_DEVICES_LEARN_MORE_LINK = 'https://finix.com/docs/guides/payments/in-person-payments/getting-started-in-person/'
export const CONTACT_SALES_LINK = 'https://finix.com/contact-us'
export const MAKING_A_PAYMENT_API_DOC_LINK = 'https://finix.com/docs/guides/payments/online-payments/getting-started/finix-api/making-a-payment-api/'
export const SPLIT_TRANSFERS_API_DOC_LINK= 'https://finix.com/docs/api/tag/Split-Transfers/'
export const PAYMENT_INSTRUMENT_API_DOCS_LINK = 'https://finix.com/docs/api/tag/Payment-Instruments/#tag/Payment-Instruments/operation/getPaymentInstrument'
export const IDENTITIES_API_DOCS_LINK = 'https://finix.com/docs/api/tag/Identities/#tag/Identities/operation/getIdentity'
export const AUTHORIZATION_API_DOC_LINK = 'https://finix.com/docs/api/tag/Authorizations/#tag/Authorizations/operation/getAuthorization'

// reporting external links
export const TOP_LEVEL_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/'
export const NET_PROFIT_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#net-profit-report'
export const TRANSACTION_INTERCHANGE_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#transaction-interchange-report'
export const INTERCHANGE_OPTIMIZATION_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const FEES_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const FLEX_CLIENT_BALANCES_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const MERCHANT_BALANCES_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const SETTLEMENTS_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#settlement-merchant'
export const FUNDING_INSTRUCTIONS_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#failed-funding-instructions'
export const AUTHORIZATIONS_REPORT_TYPE_URL =  TOP_LEVEL_REPORT_TYPE_URL
export const TRANSACTIONS_REPORT_TYPE_URL =  TOP_LEVEL_REPORT_TYPE_URL
export const DISPUTES_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#chargeback-merchant-daily'
export const NOTICE_OF_CHANGE_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const DISPUTES_HISTORICAL_REPORT_TYPE_URL = 'https://finix.com/docs/guides/after-the-payment/reports/#chargeback-merchant-historical'
export const TRANSACTION_MONITORING_REPORT_TYPE_URL =  TOP_LEVEL_REPORT_TYPE_URL
export const SETTLED_ITEMS_NON_FEES_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const SETTLED_ITEMS_FEES_REPORT_TYPE_URL = TOP_LEVEL_REPORT_TYPE_URL
export const FINIX_PRIVACY_POLICY_URL = 'https://finix.com/terms-and-policies/privacy-policy'
export const FINIX_TERMS_OF_SERVICE_URL = 'https://finix-hosted-content.s3.amazonaws.com/flex/v2/finix-terms-of-service.html'
export const FINIX_V1_TERMS_OF_SERVICE_URL = 'https://finix-hosted-content.s3.amazonaws.com/flex/v3/finix-terms-of-service.html'
export const FINIX_CANADA_TERMS_OF_SERVICE_URL = 'https://finix-hosted-content.s3.amazonaws.com/flex/v3/finix-terms-of-service-canada.html'
export const SANDBOX_TERMS_OF_SERVICE_URL = 'https://finix-hosted-content.s3.amazonaws.com/flex/v2/sandbox-terms-of-service.html'
export const APPLICATION_INFORMATION_URL = 'https://finix.com/docs/guides/business/live-account-application-form'
export const PROHIBITED_OR_RESTRICTED_BUSINESS_AND_ACTIVITIES_URL = 'https://finix.com/terms-and-policies/prohibited-and-restricted-businesses'
export const USER_GUIDE_URL = 'https://finix-hosted-content.s3.amazonaws.com/guides/navigating_your_payments_dashboard_the%20_basics_january_2024.pdf'
export const PAYOUT_PROFILE_SETTLEMENT_BATCH_TIME_URL = 'https://finix.com/docs/guides/seller-payouts/managing-seller-payouts/'

// sift console
export const SIFT_CONSOLE_URL = 'https://console.sift.com/users'
