import React, { Component } from 'react'
import { connect } from 'react-redux'
import ExitThisFlow from './ExitThisFlow'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import redirectRequest from 'utilities/actions/redirectRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import get from 'lodash/get'

import {
  RECIPIENTS_PATH,
  PAYMENT_INSTRUMENTS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { credentialId } = ownProps

  return {
    redirectUserToRecipientsPage: () => {
      dispatch(redirectRequest({ path: RECIPIENTS_PATH({ credentialId }) }))
      dispatch(hideModalAction())
    },
    redirectUserToPaymentInstrumentsPage: () => {
      dispatch(redirectRequest({ path: PAYMENT_INSTRUMENTS_PATH({ credentialId }) }))
      dispatch(hideModalAction())
    },
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ExitThisFlowC extends Component {
  render() {
    return (
      <ExitThisFlow
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitThisFlowC)
