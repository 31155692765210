import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import UploadEvidenceForm from 'components/Customer/Forms/UploadEvidenceForm/UploadEvidenceForm'
import { UPLOAD_EVIDENCE_FORM } from 'constants/formConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'

const config = {
  multiple: false,
  maxSize: 1000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag and drop, or click to select and upload a file',
  },
  {
    name: 'sub',
    message: 'Max PNG and JPEG size is 50 KB. Max PDF and TIFF size is 1 MB',
  },
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
    config,
    instructions,
  }
}

class UploadEvidenceFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadSuccess: false,
      uploaded: false,
      uploadErrors: [],
      supportUpload: true,
    }
  }

  updateUploadStatus = ({ uploaded, uploadSuccess, uploadErrors, supportUpload }) => {
    this.setState({ uploaded, uploadSuccess, uploadErrors, supportUpload })
  }

  clearForm = () => {
    const { dispatch } = this.props

    dispatch(reset(UPLOAD_EVIDENCE_FORM))
  }

  closeModal = () => {
    const { dispatch } = this.props

    this.setState({
      uploaded: false,
      uploadSuccess: false,
      supportUpload: true,
      uploadErrors: [],
    })

    dispatch(hideModalAction())
  }

  render() {
    const { updateUploadStatus, closeModal, clearForm } = this
    const { uploadSuccess, uploaded, uploadErrors, supportUpload } = this.state

    return (
      <UploadEvidenceForm
        {...this.props}
        updateUploadStatus={updateUploadStatus}
        uploaded={uploaded}
        uploadErrors={uploadErrors}
        uploadSuccess={uploadSuccess}
        supportUpload={supportUpload}
        closeModal={closeModal}
        clearForm={clearForm}
      />
    )
  }
}

export default connect(mapStateToProps)(UploadEvidenceFormC)
