import './NewBuyerIdentityModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import AddNewBuyerIdentityFormC from 'components/Customer/Forms/AddNewBuyerIdentityForm/AddNewBuyerIdentityFormC'

import {
  ADD,
  ADD_NEW_BUYER,
} from 'constants/language/languageConstants'

const NewBuyerIdentityModal = ({
  credentials = {},
  successFunction = () => {},
}) => {
  return (
    <GenericModal
      title={ADD_NEW_BUYER}
      className='NewBuyerIdentityModal'
      Component={AddNewBuyerIdentityFormC}
      submitLabel={ADD}
      successFunction={successFunction}
      credentials={credentials}
    />
  )
}

NewBuyerIdentityModal.propTypes = {
  credentials: PropTypes.object,
}

export default NewBuyerIdentityModal
