import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPaymentLinksSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import GuestPaymentLinkForm from 'components/Customer/Forms/GuestPaymentLinkForm/GuestPaymentLinkForm'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import getGuestCheckoutTokenVerificationRequest from 'utilities/actions/get/getGuestCheckoutTokenVerificationRequest'
import submitGuestPaymentLinkForm from 'utilities/submit/submitGuestPaymentLinkForm'
import validateGuestPaymentLinkForm from 'utilities/validate/validateGuestPaymentLinkForm'
import getGuestCheckoutHost from 'utilities/api/getGuestCheckoutHost'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { GET_GUEST_CHECKOUT_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import { GUEST_PAYMENT_LINK_CONFIRMATION } from 'constants/pathConstants'
import { GUEST_PAYMENT_LINK_FORM } from 'constants/formConstants'
import { LOAD_PAYMENT_LINK } from 'constants/amplitudeConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import { LIVE_URL } from 'constants/credentialConstants'
import { FAILURE } from 'constants/flashNotificationConstants'
import { USA } from 'constants/countryConstants'
import { USD } from 'constants/currencyConstants'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  change,
  getFormValues,
  reduxForm,
  isInvalid,
  isPristine,
} from 'redux-form'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
  COMPLETED,
  DEACTIVATED,
  EXPIRED,
} from 'constants/paymentLinkConstants'

import {
  t,
  CVC,
  ZIP,
  NAME,
  COUNTRY,
  BANK_CODE,
  EXPIRATION,
  CARD_NUMBER,
  ACCOUNT_TYPE,
  ACCOUNT_NUMBER,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  GUEST_CHECKOUT_ERROR_MESSAGE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const {
    previewMode,
    noValidation = false,
  } = props

  const paymentLinks = !previewMode ? getPaymentLinksSelector(state) : []
  const paymentLinkId = head(Object.keys(paymentLinks))
  const paymentLink = get(paymentLinks, paymentLinkId, get(props, 'paymentLink', {}))
  const currency = get(paymentLink, 'currency', USD)
  const formValues = getFormValues(GUEST_PAYMENT_LINK_FORM)(state)
  const displayVariableAmount = formatMoney({ amount: get(formValues, 'saleAmount', '0.00'), currency })
  const formIsInvalid = isInvalid(GUEST_PAYMENT_LINK_FORM)(state)
  const formIsPristine = isPristine(GUEST_PAYMENT_LINK_FORM)(state)
  const lng = get(state, 'languageR.lng')
  const email = get(formValues, 'email', '')
  const receiptEmails = get(formValues, 'receiptEmails', [])

  return removeUndefined({
    lng,
    paymentLink,
    noValidation,
    isFetching: isEmpty(paymentLink),
    displayVariableAmount,
    formIsInvalid,
    formIsPristine,
    initialValues: {
      shippingAddress: {
        country: USA,
      },
    },
    email,
    receiptEmails,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestCheckoutPaymentLink: ({ paymentLinkId, meta }) => {
      dispatch({
        type: GET_GUEST_CHECKOUT_PAYMENT_LINK_F_REQUEST,
        payload: {
          id: paymentLinkId,
        },
        meta,
      })
    },
    getHostedFormTokenVerification: ({ meta }) => { dispatch(getGuestCheckoutTokenVerificationRequest({ meta })) },
    updateToken: (token) => { dispatch(change(GUEST_PAYMENT_LINK_FORM, 'token', token)) },
    flashErrorNotification: () => {
      dispatch(flashNotificationAction({
        message: GUEST_CHECKOUT_ERROR_MESSAGE,
        type: FAILURE,
        time: 3000,
      }))
    },
    updateReceiptEmails: ({ email }) => dispatch(change(GUEST_PAYMENT_LINK_FORM, 'receiptEmails', [{ label: email, value: email }])),
  }
}

class GuestPaymentLinkFormC extends Component {
  state = {
    env: null,
    token: null,
    form: null,
    sessionKey: null,
    formHasErrors: false,
    isProcessingPayment: false,
    showAchWarning: false,
    country: USA,
  }

  componentDidMount() {
    const {
      previewMode,
      getGuestCheckoutPaymentLink,
      getHostedFormTokenVerification,
    } = this.props

    if (previewMode) {
      // skip token checks for preview mode
      this.initializeTokenForm()
      return
    }

    const tokenQuery = getUrlQuery('bearer_token')

    if (tokenQuery) {
      // store bearer token to state
      this.setState({ token: tokenQuery })

      // clear localStorage to ensure hosted payment links hits the correct env
      localStorage.clear()

      // set the temp bearer token for localStorage for verify call
      localStorage.setItem(AUTH_SESSION, JSON.stringify({
        idToken: tokenQuery,
        tokenType: 'Bearer',
      }))

      // verify bearer token from url
      getHostedFormTokenVerification({
        meta: {
          successCallback: ({ newValues: tokenResponse }) => {
            const paymentLinkId = get(tokenResponse, 'entity_id')
            const isValid = get(tokenResponse, 'is_valid')
            const additionalDetails = get(tokenResponse, 'additional_details', {})
            const bearerToken = get(tokenResponse, 'bearer_token')

            // set the returned bearer token to localStorage for further authentication uses
            localStorage.setItem(AUTH_SESSION, JSON.stringify({
              idToken: bearerToken,
              tokenType: 'Bearer',
            }))

            const {
              collect_billing_address: collectBillingAddress,
              collect_email: collectEmail,
              collect_name: collectName,
              collect_phone: collectPhone,
              collect_shipping_address: collectShippingAddress,
              expiration_in_minutes: expirationInMinutes,
              generate_receipt: generateReceipt,
            } = additionalDetails

            if (!isValid) {
              // redirect to invalid token page
              goToPath({
                pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
                queries: {
                  status: 'expired',
                },
              })
            } else {
              // make fetch for the payment link
              getGuestCheckoutPaymentLink({
                paymentLinkId,
                meta: {
                  actionId: sendAmplitudeActionEvent(LOAD_PAYMENT_LINK, {
                    paymentLinkId,
                    collectEmail,
                    collectName,
                    collectPhone,
                    collectBillingAddress,
                    collectShippingAddress,
                    expirationInMinutes,
                    generateReceipt,
                  }),
                },
              })
            }
          },
          errorCallback: () => {
            // there was an error validating the token, take to some error page
            goToPath({
              pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
              queries: {
                status: 'expired',
              },
            })
          },
        },
      })
    } else {
      // no token, go to expired page for now? Check with Oscar on how this should work
      goToPath({
        pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
        queries: {
          status: 'expired',
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      previewMode,
      paymentLink,
    } = this.props

    if (previewMode) {
      return
    }

    const { form } = this.state

    if (form === null && !isEmpty(paymentLink)) {
      const paymentLinkState = get(paymentLink, 'state')

      // redirect if payment link is completed, expired, or deactivated
      if (includes([COMPLETED, EXPIRED, DEACTIVATED], paymentLinkState)) {
        const paymentLinKStateToStatusMap = {
          [COMPLETED]: 'success',
          [EXPIRED]: 'expired',
          [DEACTIVATED]: 'deactivated',
        }

        goToPath({
          pathname: GUEST_PAYMENT_LINK_CONFIRMATION,
          queries: {
            status: paymentLinKStateToStatusMap[paymentLinkState],
          },
        })
      } else {
        this.initializeTokenForm()
      }
    }
  }

  componentWillUnmount() {
    this.setState({ form: null })
  }

  onUpdate = (state, bin, hasErrors) => {
    const addressCountry = get(state, 'address.country') || {}
    const countryCode = get(addressCountry, 'country', USA)
    // need to update the legal text depending on if USA or CAN
    this.setState({ country: countryCode })
    this.setState({ showAchWarning: !!document.querySelector('.field-holder.account_number') })
    this.setState({ formHasErrors: hasErrors })
  }

  initializeReceiptEmails = () => {
    const {
      email,
      receiptEmails,
      updateReceiptEmails,
    } = this.props

    if (isEmpty(receiptEmails) && email) {
      updateReceiptEmails({ email })
    }
  }

  initializeTokenForm = () => {
    const { paymentLink } = this.props
    const { form } = this.state

    const {
      collectBillingAddress = false,
      allowedPaymentMethods = [],
      merchantId,
    } = paymentLink

    const env = getGuestCheckoutHost() === LIVE_URL ? 'live' : 'sandbox'
    this.setState({ env })

    window.Finix.Auth(env, merchantId, (sessionKey) => this.setState({ sessionKey }))

    const tokenFormOptions = {
      showAddress: true,
      hideFields: collectBillingAddress ? [] : ['address_line1', 'address_line2', 'address_city', 'address_state', 'address_region'],
      requiredFields: collectBillingAddress ? ['name', 'address_line1', 'address_city', 'address_region', 'address_postal_code', 'address_country'] : ['name', 'address_postal_code', 'address_country'],
      labels: {
        name: t(NAME),
        number: t(CARD_NUMBER),
        account_number: t(ACCOUNT_NUMBER),
        bank_code: t(BANK_CODE),
        account_type: t(ACCOUNT_TYPE),
        transit_number: t(TRANSIT_NUMBER),
        institution_number: t(INSTITUTION_NUMBER),
        expiration_date: t(EXPIRATION),
        security_code: t(CVC),
        address_postal_code: t(ZIP),
        address_country: t(COUNTRY),
      },
      placeholders: {
        name: t(NAME),
        security_code: t(CVC),
        address_postal_code: t(ZIP),
      },
      styles: {
        default: {
          color: '#000',
          border: '1px solid #7D90A5',
          borderRadius: '4px',
          padding: '8px 16px',
          fontFamily: 'Helvetica',
          fontSize: '16px',
        },
      },
      onUpdate: this.onUpdate,
    }

    document.querySelector('.pay-button').addEventListener('click', this.submitTokenForm)

    let formFunction = 'TokenForm'
    if (allowedPaymentMethods.length === 1 && allowedPaymentMethods.includes(PAYMENT_CARD)) formFunction = 'CardTokenForm'
    if (allowedPaymentMethods.length === 1 && allowedPaymentMethods.includes(BANK_ACCOUNT)) formFunction = 'BankTokenForm'

    if (!form && window.Finix && window.Finix.TokenForm) {
      this.setState({ form: window.Finix[formFunction]('payment-instrument-form', tokenFormOptions) })
    }
  }

  submitTokenForm = () => {
    const {
      env,
      form,
    } = this.state

    const {
      paymentLink,
      handleSubmit,
      updateToken,
      flashErrorNotification,
      previewMode,
    } = this.props

    if (previewMode) {
      return
    }

    const applicationId = get(paymentLink, 'applicationId')

    this.setState({ isProcessingPayment: true })

    form.submit(env, applicationId, (err, res) => {
      const tokenData = get(res, 'data', {})
      const { id } = tokenData

      if (id) {
        updateToken(id)
        handleSubmit(submitGuestPaymentLinkForm)()
      } else {
        this.setState({ isProcessingPayment: false })
        flashErrorNotification()
      }
    })
  }

  render() {
    const {
      token,
      sessionKey,
      formHasErrors,
      isProcessingPayment,
      showAchWarning,
      country,
    } = this.state

    return (
      <GuestPaymentLinkForm
        {...this.props}
        token={token}
        sessionKey={sessionKey}
        formHasErrors={formHasErrors}
        isProcessingPayment={isProcessingPayment}
        showAchWarning={showAchWarning}
        country={country}
        initializeReceiptEmails={this.initializeReceiptEmails}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: GUEST_PAYMENT_LINK_FORM,
    validate: validateGuestPaymentLinkForm,
  })(GuestPaymentLinkFormC),
)
