import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ChangePasswordFormC from 'components/Customer/Forms/ChangePasswordForm/ChangePasswordFormC'
import { CHANGE_PASSWORD } from 'constants/language/languageConstants'

const ChangePasswordModal = () => {
  return (
    <GenericModal
      title={CHANGE_PASSWORD}
      className='ChangePasswordModal'
      Component={ChangePasswordFormC}
    />
  )
}

export default ChangePasswordModal
