import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import get from 'lodash/get'

import {
  DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2,
} from 'constants/apiConstants'

const getEmailTemplateAPI = ({ meta, values }) => {
  const templateName = get(values, 'templateName')
  const dashboardId = getCurrentDashboardId()
  const path = DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2({ dashboardId })
  const queries = {
    email_template_name: templateName,
  }

  return dashboardAPI.get({
    meta,
    path,
    queries,
  })
}

export default getEmailTemplateAPI
