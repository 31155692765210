// TODO: unit test for this
import removeUndefined from 'utilities/remove/removeUndefined'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import includes from 'lodash/includes'
import map from 'lodash/map'

import {
  USD,
  currenciesWithCents,
} from 'constants/currencyConstants'

const backendPaymentLinkModel = ({ values }) => {
  const {
    merchantId,
    applicationId,
    isMultipleUse,
    allowedPaymentMethods,
    nickname,
    items,
    collectEmail,
    collectName,
    collectPhoneNumber,
    collectShippingAddress,
    collectBillingAddress,
    expirationInMinutes,
    amountType,
    paymentFrequency = 'ONE_TIME',
    currency = USD,
    termsOfServiceUrl,
    successReturnUrl = '',
    unsuccessfulReturnUrl = '',
    expiredSessionUrl = '',
    icon,
    brandColor,
    accentColor,
    buttonFontColor,
    logo,
    tags,
    state,
    maxTransactionAmount,
    sendReceipt,
    receiptDeliveryMethods,
  } = values

  const isFixedAmount = amountType === 'FIXED'
  let totalAmount = 0
  let minimumAmount
  let maximumAmount

  const normalizedItems = map(items, (item) => {
    const {
      name,
      quantity = '1',
      saleAmount,
      description,
      primaryImageUrl,
      minAmount = includes(currenciesWithCents, currency) ? '0.01' : '1',
      maxAmount = maxTransactionAmount,
    } = item

    if (isFixedAmount) {
      // convert to display amount to actual amount for calculation
      const amount = displayAmountToAmount(saleAmount, currency)
      totalAmount += (amount * parseInt(quantity, 10))
    } else {
      minimumAmount = minAmount
      maximumAmount = maxAmount
    }

    return {
      name,
      description,
      quantity,
      image_details: {
        primary_image_url: primaryImageUrl,
      },
      price_details: {
        sale_amount: saleAmount,
        currency,
      },
    }
  })

  return removeUndefined({
    state,
    merchant_id: merchantId,
    application_id: applicationId,
    payment_frequency: paymentFrequency,
    is_multiple_use: isMultipleUse,
    allowed_payment_methods: allowedPaymentMethods,
    nickname,
    items: normalizedItems,
    amount_details: {
      amount_type: amountType,
      // need to convert back to display amount due to auto normalization
      total_amount: isFixedAmount ? amountToDisplayAmount(totalAmount, currency) : undefined,
      min_amount: minimumAmount,
      max_amount: maximumAmount,
      currency,
    },
    additional_details: {
      collect_name: collectName,
      collect_email: collectEmail,
      collect_phone: collectPhoneNumber,
      collect_shipping_address: collectShippingAddress,
      collect_billing_address: collectBillingAddress,
      expiration_in_minutes: expirationInMinutes,
      terms_of_service_url: termsOfServiceUrl,
      success_return_url: successReturnUrl,
      unsuccessful_return_url: unsuccessfulReturnUrl,
      expired_session_url: expiredSessionUrl,
      send_receipt: sendReceipt,
      receipt_requested_delivery_methods: receiptDeliveryMethods,
    },
    branding: {
      icon,
      brand_color: brandColor,
      accent_color: accentColor,
      button_font_color: buttonFontColor,
      logo,
    },
    tags,
  })
}

export default backendPaymentLinkModel
