import frontendSubscriptionModel from 'utilities/create/models/frontendSubscriptionModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSubscriptionsModel = ({ data: subscriptions }) => {
  const subscriptionsModel = map(subscriptions, (subscription) => frontendSubscriptionModel({ data: subscription }))

  return keyBy(subscriptionsModel, 'id')
}

export default frontendSubscriptionsModel
