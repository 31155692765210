import genericFlowConfigs from 'state-layer/flows/genericFlows.config'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import isArray from 'lodash/isArray'

import {
  requestF,
  successF,
  failureF,
} from 'constants/flowConstants'

import {
  successS,
  failureS,
} from 'constants/sagaConstants'

const genericFlows = keyBy(
  flatten(
    map(genericFlowConfigs, (flowProperties) => {
      const { name } = flowProperties
      const flowNames = isArray(name) ? name : [name]

      return map(flowNames, (flowName) => merge({}, flowProperties, {
        name: flowName,
        requestF: requestF(flowName),
        successF: successF(flowName),
        failureF: failureF(flowName),
        successS: successS(flowName),
        failureS: failureS(flowName),
      }))
    }),
  ),
  'name',
)

export default genericFlows
