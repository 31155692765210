import postApplicationUserAPI from 'api/finix/post/postApplicationUserAPI'
import postIdentityUserAPI from 'api/finix/post/postIdentityUserAPI'
import postCredentialsAPI from 'api/dashboard/post/postCredentialsAPI'
import postV1UserAPI from 'api/dashboard/post/postV1UserAPI'
import postUserMembershipsAPI from 'api/dashboard/post/postUserMembershipsAPI'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { APPLICATION } from 'constants/flowConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getDashboardUser from 'utilities/get/getDashboardUser'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * postDashboardUserO ({
  id,
  values,
  credentials,
}) {
  const {
    name,
    email,
    type,
    identityId,
    applicationId,
  } = values

  const { host } = credentials
  const creatorEmail = get(getDashboardUser(), CLIENT_EMAIL)

  const tagsPayload = {
    tags: {
      name: `created by ${creatorEmail}`,
    },
  }

  const isApplication = type === APPLICATION

  const userPayload = isApplication
    ? tagsPayload
    : merge({}, tagsPayload, {
      identity: identityId,
      application_id: applicationId,
    })

  const resourceUserAPI = isApplication ? postApplicationUserAPI : postIdentityUserAPI
  const { data: resourceUserResponse } = yield resourceUserAPI({ values: userPayload, credentials, id })

  if (!resourceUserResponse) {
    return createErrorResponse(`Error on creating user for ${type}:${id}`)
  }

  const { id: username, password } = resourceUserResponse
  const credentialsResponseRaw = yield postCredentialsAPI({ values: { username, password, host, name } })
  const credentialsResponse = get(credentialsResponseRaw, 'data.data')

  if (!credentialsResponse) {
    return createErrorResponse(`Error on creating credentials for ${name}`)
  }

  const { id: credentialId } = credentialsResponse

  const emailPayload = {
    type: 'email-address',
    properties: {
      email_address: email,
    },
  }

  const userResponseRaw = yield postV1UserAPI({ values: emailPayload })
  const userResponse = get(userResponseRaw, 'data.data')

  if (!userResponse) {
    return createErrorResponse(`Error on creating user for ${email}`)
  }

  const { id: userId } = userResponse

  const { data: membershipResponse } = yield postUserMembershipsAPI({
    id: userId,
    values: {
      credential: credentialId,
    },
  })

  return createSuccessResponse({
    data: [userResponse],
  })
}

export default co.wrap(postDashboardUserO)
