import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  APPLICATION_PROFILE,
  APPLICATION_PROFILES,
} from 'constants/apiConstants'

const patchApplicationProfileAPI = ({ values, credentials, meta }) => {
  // TODO: refactor this flow to send ID through id
  const id = get(values, 'id')
  const path = APPLICATION_PROFILE({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: APPLICATION_PROFILES,
  })
}

export default patchApplicationProfileAPI
