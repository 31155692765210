import './PaymentLinkItemsS.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import stripNonNumericChars from 'utilities/string/stripNonNumericChars'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import formatMoney from 'utilities/formatters/formatMoney'
import size from 'lodash/size'
import get from 'lodash/get'

import {
  QUANTITY,
  IMAGE_URL,
  UNIT_PRICE,
  DESCRIPTION,
  PRODUCT_OR_SERVICE_NAME,
  CONTROL_WHAT_BUYERS_CAN_ENTER,
  NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS,
  OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY,
  SUGGESTED_AMOUNT,
  MINIMUM_ALLOWED,
  MAXIMUM_ALLOWED,
  DELETE_ITEM,
  ADD_ANOTHER_ITEM,
} from 'constants/language/languageConstants'

import {
  ADD_ICON,
  TRASH_ALT_ICON,
} from 'constants/iconConstants'

const PaymentLinkItems = ({
  fields,
  amountType,
  currentItems = [],
}) => {
  const [showPriceControls, setShowPriceControls] = useState(false)

  useEffect(() => {
    fields.push({})
  }, [])

  useEffect(() => {
    fields.removeAll()
    fields.push({})
  }, [amountType])

  const isFixedAmount = amountType === 'FIXED'

  return (
    <div className='PaymentLinkItems'>
      { fields.map((item, index) => {
        const key = `item_${index}`
        const image = get(currentItems, `[${index}].primaryImageUrl`, '')
        const saleAmount = get(currentItems, `[${index}].saleAmount`, '')
        const quantity = get(currentItems, `[${index}].quantity`, '')
        const name = get(currentItems, `[${index}].name`, '')
        const description = get(currentItems, `[${index}].description`, '')

        return (
          <div key={key} className='item'>
            <Field
              name={`${item}.name`}
              label={PRODUCT_OR_SERVICE_NAME}
              placeholder={NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS}
              component={InputField}
            />

            <Field
              name={`${item}.description`}
              label={DESCRIPTION}
              placeholder={OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY}
              component={InputField}
              required={false}
            />

            { isFixedAmount && (
              <div className='flex space-between'>
                <AmountField
                  name={`${item}.saleAmount`}
                  label={UNIT_PRICE}
                  placeholder='0.00'
                />

                <Field
                  name={`${item}.quantity`}
                  label={QUANTITY}
                  placeholder={QUANTITY}
                  component={InputField}
                  format={stripNonNumericChars}
                  required={false}
                />
              </div>
            )}

            <Field
              name={`${item}.primaryImageUrl`}
              label={IMAGE_URL}
              placeholder='https://www.example.com/123rw21w.jpg'
              component={InputField}
              required={false}
            />

            { !isFixedAmount && (
              <div className='variable-payment-controls'>
                <div className='flex'>
                  <CheckboxC checked={showPriceControls} onChange={() => setShowPriceControls(!showPriceControls)} />
                  <span className='flex'>
                    {CONTROL_WHAT_BUYERS_CAN_ENTER}
                    <TooltipLabelC message='Display a pre-filled amount to the buyer and control the minimum and maximum amounts they can enter.' />
                  </span>
                </div>

                { showPriceControls && (
                  <div className='variable-amounts flex space-between'>
                    <AmountField
                      name={`${item}.saleAmount`}
                      label={SUGGESTED_AMOUNT}
                      placeholder='0.00'
                      required={false}
                    />

                    <AmountField
                      name={`${item}.minAmount`}
                      label={MINIMUM_ALLOWED}
                      placeholder='0.00'
                      required={false}
                    />

                    <AmountField
                      name={`${item}.maxAmount`}
                      label={MAXIMUM_ALLOWED}
                      placeholder='0.00'
                      required={false}
                    />
                  </div>
                )}
              </div>
            )}

            { image && isFixedAmount && (
              <div className='item-preview flex'>
                { image && <img src={image} alt='preview' width={100} /> }
                { !image && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                <div className='info flex column flex-grow space-between'>
                  <div>
                    <p className='name p-2'>{name}</p>
                    <p className='description p-3 secondary'>{description}</p>
                  </div>

                  { isFixedAmount && <p className='quantity p-3 secondary'>{formatMoney({ amount: saleAmount })}{quantity ? ` x ${quantity}` : ''}</p> }
                </div>

                {/* <h6 className='total'>{formatMoney({ amount: saleAmount })}</h6> */}
              </div>
            )}

            { isFixedAmount && size(fields) > 1 && (
              <div className='delete-item flex' onClick={() => fields.remove(index)}>
                <i className={`fal fa-${TRASH_ALT_ICON}`} />
                <span>{DELETE_ITEM}</span>
              </div>
            )}
          </div>
        )
      })}

      { isFixedAmount && (
        <div className='add-item flex' onClick={() => fields.push({})}>
          <i className={`fal fa-${ADD_ICON}`} />
          <span>{ADD_ANOTHER_ITEM}</span>
        </div>
      )}
    </div>
  )
}

PaymentLinkItems.propTypes = {
  fields: PropTypes.object,
  amountType: PropTypes.string,
  currentItems: PropTypes.array,
}

export default PaymentLinkItems
