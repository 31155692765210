import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDateWithTimeZoneAbrreviation from 'utilities/formatters/formatDateWithTimeZoneAbrreviation'
import formatDate from 'utilities/formatters/formatDate'

const frontendNoteModel = ({ data: note }) => {
  const [
    id,
    linkedTo,
    linkedType,
    content,
    jsonContent,
    userDetails,
    tags,
    status,
    applicationId,
    createdAt,
    updatedAt,
  ] = getMany(note, [
    'id',
    'linked_to',
    'linked_type',
    'content',
    'json_content',
    'user_details',
    'tags',
    'status',
    'application_id',
    'created_at',
    'updated_at',
  ])

  const [
    firstName,
    lastName,
    email,
  ] = getMany(userDetails, [
    'first_name',
    'last_name',
    'email',
  ])

  return removeUndefined({
    id,
    linkedTo,
    linkedType,
    content,
    jsonContent,
    firstName,
    lastName,
    email,
    userDetails,
    tags,
    status,
    applicationId,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDateWithTimeZoneAbrreviation({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
  })
}

export default frontendNoteModel
