import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutPlanContents from './PayoutPlanContents'
import { getPayoutPlanSelector } from 'state-layer/selectors'
import TemplateTooltip from 'components/Customer/Pages/PayoutPlan/TemplateTooltip'
import convertCombinationPlanEquationToTerms from 'utilities/convert/convertCombinationPlanEquationToTerms'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPayoutPlanRequest from 'utilities/actions/get/getPayoutPlanRequest'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import { payoutPlanLevelToTitleMap } from 'constants/payoutPlanConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import filter from 'lodash/filter'
import words from 'lodash/words'
import isFinite from 'lodash/isFinite'
import find from 'lodash/find'
import includes from 'lodash/includes'

import {
  CREATED_ON,
  DESTINATION_INSTRUMENT_LABEL,
  EQUATION,
  LEVEL,
  RAIL,
  SCHEDULE_AUTO_CLOSE_TIME,
  SOURCE_INSTRUMENT_LABEL,
  SUBMISSION_TYPE,
  UPDATED_AT_LABEL,
  PAYOUT_PLAN,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `payoutPlansR.${FETCHING}`)
  const payoutPlanId = get(props, 'payoutPlanId')
  const payoutPlan = getPayoutPlanSelector(state, payoutPlanId)

  const [
    level,
    templates,
    schedules,
    combinationPlans,
  ] = getMany(payoutPlan, [
    'level',
    'templates',
    'schedules',
    'combinationPlans',
  ])

  const payoutPlanCards = map(combinationPlans, (combinationPlan) => {
    const [
      createdAt,
      destinationInstrumentLabel,
      equation = '',
      combinationPlanLevel,
      combinationPlanName = '',
      combinationPlanRail,
      sourceInstrumentLabel,
      submissionSettings,
      updatedAt,
    ] = getMany(combinationPlan, [
      'created_at',
      'destination_instrument_label',
      'equation',
      'level',
      'name',
      'rail',
      'source_instrument_label',
      'submission_settings',
      'updated_at',
    ])

    const [
      submissionType,
      submissionOffsetBusinessDays,
    ] = getMany(submissionSettings, [
      'type',
      'submission_offset_business_days',
    ])

    // parse template names from combination plan equation
    const combinationPlanTemplates = filter(words(equation, /[^(+|\-|*|(|)]+/g), (template) => !isFinite(parseInt(template, 10)))
    // find combination plan's schedule based on above results (all combination plan templates have same schedule)
    const combinationPlanSchedule = find(schedules, ({ template_names: templateNames }) => includes(templateNames, get(combinationPlanTemplates, '0')))
    // remove () and then separate equation into terms, coefficients and operators
    const equationTerms = convertCombinationPlanEquationToTerms(equation)

    const [
      autoCloseOffsetDays,
      autoCloseOffsetHours,
      autoCloseOffsetMinutes,
      timezone,
      scheduleType,
    ] = getMany(combinationPlanSchedule, [
      'auto_close_offset_days',
      'auto_close_offset_hours',
      'auto_close_offset_minutes',
      'timezone',
      'type',
    ])

    return {
      largeTitle: convertSnakeToSentenceCase(combinationPlanName),
      data: [
        {
          label: EQUATION,
          value: (
            <div className='equation flex wrap items-center'>
              {
                map(equationTerms, (term) => {
                  const template = find(templates, ({ name: templateName }) => templateName === term)
                  const tooltipName = combinationPlanName.concat('.', term)

                  return template ? (
                    <div
                      key={tooltipName}
                      className='equationTerm'
                    >
                      <div className='equationTermName'>{convertSnakeToSentenceCase(term)}</div>

                      <TemplateTooltip
                        key={`${tooltipName}-tooltip`}
                        template={template}
                      />
                    </div>
                  ) : (
                    <div
                      className='equationOperator'
                      key={term}
                    >
                      {convertSnakeToSentenceCase(term)}
                    </div>
                  )
                })
              }
            </div>
          ),
        },
        {
          label: CREATED_ON,
          value: formatDate({ date: createdAt }),
        },
        {
          label: UPDATED_AT_LABEL,
          value: formatDate({ date: updatedAt }),
        },
        {
          label: LEVEL,
          value: capitalize(combinationPlanLevel),
        },
        {
          label: RAIL,
          value: convertSnakeToSentenceCase(combinationPlanRail),
        },
        {
          label: SOURCE_INSTRUMENT_LABEL,
          value: convertSnakeToSentenceCase(sourceInstrumentLabel),
        },
        {
          label: DESTINATION_INSTRUMENT_LABEL,
          value: convertSnakeToSentenceCase(destinationInstrumentLabel),
        },
        {
          label: SCHEDULE_AUTO_CLOSE_TIME,
          value: `${capitalize(scheduleType)} - ${autoCloseOffsetDays}d ${autoCloseOffsetHours}h ${autoCloseOffsetMinutes}m (${timezone})`,
        },
        {
          label: SUBMISSION_TYPE,
          value: capitalize(submissionType),
        },
      ],
    }
  })

  const title = get(payoutPlanLevelToTitleMap, level, PAYOUT_PLAN)

  return {
    title,
    isFetching,
    credentials,
    payoutPlanId,
    payoutPlanCards,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPayoutPlan: ({ credentials, payoutPlanId }) => { dispatch(getPayoutPlanRequest({ credentials, payoutPlanId })) },
  }
}


class PayoutPlanContentsC extends Component {
  componentDidMount() {
    const {
      credentials,
      payoutPlanId,
      getPayoutPlan,
    } = this.props

    if (payoutPlanId) {
      getPayoutPlan({ payoutPlanId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const { payoutPlanId: prevPayoutPlanId } = prevProps

    const {
      credentials,
      payoutPlanId,
      getPayoutPlan,
    } = this.props

    if (payoutPlanId && payoutPlanId !== prevPayoutPlanId) {
      getPayoutPlan({ payoutPlanId, credentials })
    }
  }

  render() {
    return (
      <PayoutPlanContents {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutPlanContentsC)
