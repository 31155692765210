import './DeactivateTeamMemberModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import DeactivateTeamMemberC from 'components/Customer/Pages/DeactivateTeamMember/DeactivateTeamMemberC'
import { DEACTIVATE_TEAM_MEMBER } from 'constants/language/languageConstants'

const DeactivateTeamMemberModal = ({
  memberId,
  memberName,
  dashboardName,
}) => {
  return (
    <GenericModal
      title={DEACTIVATE_TEAM_MEMBER}
      className='DeactivateTeamMemberModal'
      Component={DeactivateTeamMemberC}
      memberId={memberId}
      memberName={memberName}
      dashboardName={dashboardName}
    />
  )
}

DeactivateTeamMemberModal.propTypes = {
  memberId: PropTypes.string,
  memberName: PropTypes.string,
  dashboardName: PropTypes.string,
}

export default DeactivateTeamMemberModal
