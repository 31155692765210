import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PaymentPanelC from 'components/Customer/Pages/Payment/PaymentPanelC'
import columnDescriptors from './columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  TRANSFER,
  GET_TRANSFERS,
} from 'constants/flowConstants'

import {
  PAYMENTS,
  PAYMENTS_EMPTY_MESSAGE,
  PAYMENTS_TABLE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Payments = ({
  paymentPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  hiddenExportFields = [],
  quickFilters,
  actions = [],
}) => {
  return (
    <div className='Payments'>
      <HeaderV2C
        title={PAYMENTS}
        subTitle={PAYMENTS_TABLE_SUBTITLE_DESCRIPTION}
      />

      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={PAYMENTS}
        entityType={TRANSFER}
        showAdvancedExport
        hiddenExportFields={hiddenExportFields}
        icon={`fa fa-${PAYMENTS_ICON}`}
        emptyMessage={PAYMENTS_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        actions={actions}
        // TODO: add back in once preview panel ready to go out with usability 2.0
        // previewPanel={PaymentPanelC}
      />
    </div>
  )
}

Payments.propTypes = {
  paymentPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  hiddenExportFields: PropTypes.array,
  quickFilters: PropTypes.array,
  actions: PropTypes.array,
}

export default Payments
