import PermissionForm from './PermissionForm'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import submitPermissionsForm from 'utilities/submit/submitPermissionsForm'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PERMISSION_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'

const formatPermissions = (permissions) => {
  return reduce(permissions, (formattedPermissionObject, permission) => {
    const category = get(permission, 'category', 'Others')

    if (!formattedPermissionObject[category]) {
      formattedPermissionObject[category] = []
    }

    formattedPermissionObject[category].push(permission)

    return formattedPermissionObject
  }, {})
}

const getInitialValues = (permissions) => {
  return reduce(permissions, (permissionValues, permission) => {
    const name = get(permission, 'name')
    const enabled = get(permission, 'enabled')

    return merge({}, permissionValues, { [name]: enabled })
  }, {})
}

const mapStateToProps = (state, props) => {
  const { records, filteredRecords } = props
  const formattedPermissions = formatPermissions(filteredRecords)
  const selector = formValueSelector(PERMISSION_FORM)
  const fieldValueSelector = (fieldName) => selector(state, fieldName)

  return removeUndefined({
    formattedPermissions,
    permissions: records,
    initialValues: !isEmpty(filteredRecords) ? getInitialValues(filteredRecords) : undefined,
    fieldValueSelector,
  })
}

class PermissionFormC extends Component {
  componentDidUpdate(prevProps) {
    const {
      permissions: prevPermissions,
    } = prevProps

    const {
      initialize,
      initialValues,
      permissions,
    } = this.props

    if (!isEqual(permissions, prevPermissions)) {
      initialize(initialValues)
    }
  }

  render() {
    return (
      <PermissionForm
        {...this.props}
        onSubmit={submitPermissionsForm}
      />
    )
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: PERMISSION_FORM,
})(PermissionFormC))
