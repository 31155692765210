import { take, put } from 'redux-saga/effects'
import values from 'lodash/values'
import head from 'lodash/head'
import get from 'lodash/get'
import redirectRequest from 'utilities/actions/redirectRequest'

import {
  LOGOUT_PATH,
  DASHBOARD_PATH,
} from 'constants/pathConstants'

import {
  GET_CREDENTIALS_F_SUCCESS,
  GET_CREDENTIALS_F_FAILURE,
  GET_CREDENTIAL_F_FAILURE,
} from 'constants/flowConstants'

// TODO: refactor since this is handled by auth0 Auth controller
const credentialsWatcherF = function * () {
  while (true) {
    const {
      type,
      payload,
    } = yield take([
      GET_CREDENTIALS_F_SUCCESS,
      GET_CREDENTIALS_F_FAILURE,
      GET_CREDENTIAL_F_FAILURE,
    ])

    const { id, newValues } = payload

    if (id && type === GET_CREDENTIALS_F_SUCCESS) {
      const role = get(head(values(newValues)), 'role')

      if (role) {
        localStorage.setItem('role', role)
      }
    }

    if (type === GET_CREDENTIAL_F_FAILURE) {
      yield put(redirectRequest({ path: DASHBOARD_PATH }))
    }

    if (type === GET_CREDENTIALS_F_FAILURE) {
      // we failed to get credentials, usually means auth0 token is no longer valid
      yield put(redirectRequest({ path: LOGOUT_PATH }))
    }
  }
}

export default credentialsWatcherF
