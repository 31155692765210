import React from 'react'
import Button from 'components/Shared/Button/Button'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import hideModalAction from 'utilities/actions/hideModalAction'
import { getDispatch } from 'state-layer/configureStore'
import { goBack } from 'state-layer/history'

import {
  CLOSE,
  CONTACT_FINIX_SUPPORT_TO_GET_STARTED,
  CONTACT_SUPPORT,
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_PAYMENT_LINKS,
  PAYMENT_LINKS_ARE_NOT_AVAILABLE,
  t,
} from 'constants/language/languageConstants'

const closeModal = () => {
  const dispatch = getDispatch()
  goBack()
  dispatch(hideModalAction())
}

const contactSupportMailTo = () => {
  const mailtoLink = document.createElement('a')
  mailtoLink.href = 'mailto:support@finix.com'
  mailtoLink.click()
  mailtoLink.remove()
}

const PaymentLinksNotAvailableModal = () => {
  return (
    <GenericModal
      title={PAYMENT_LINKS_ARE_NOT_AVAILABLE}
      className='PaymentLinksNotAvailableModal'
    >
      <div className='modal-content'>
        <p className='p-1'>{t(MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_PAYMENT_LINKS)} {t(CONTACT_FINIX_SUPPORT_TO_GET_STARTED)}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button label={t(CONTACT_SUPPORT)} variant='ghost' onClick={contactSupportMailTo} />
        <Button label={t(CLOSE)} onClick={closeModal} />
      </div>
    </GenericModal>
  )
}

export default PaymentLinksNotAvailableModal
