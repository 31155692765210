import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment/moment'
import ApplicationComplianceForms from './ApplicationComplianceForms'
import columnDescriptors from 'components/Customer/Pages/Compliance/ComplianceForms/columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { FETCHING } from 'constants/reducerConstants'
import { COMPLIANCE_FORM_STATUS } from 'constants/filterConstants'
import { COMPLIANCE_FORMS_PATH } from 'constants/pathConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import get from 'lodash/get'

const allowedFilters = [
  COMPLIANCE_FORM_STATUS,
]

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `applicationProcessorsR.${FETCHING}`)

  // data needed for the compliance forms table
  const isFetchingComplianceForms = get(state, `complianceFormsR.${FETCHING}`)
  const complianceFormsPath = COMPLIANCE_FORMS_PATH({ credentialId })
  const formattedColumnDescriptors = columnDescriptors({ isRolePlatform: isRolePlatform({ credentials }) })

  const initialQueries = removeUndefined({
    sort: 'created_at,asc',
    linked_to: applicationId,
  })

  const initialFilters = {
    created_at: {
      gte: '2022-01-01',
      lte: moment().format(YYYY_MM_DD),
    },
    state: {
      eq: 'INCOMPLETE,OVERDUE',
    },
  }

  return {
    credentials,
    applicationId,
    isFetching,
    complianceFormsPath,
    columnDescriptors: formattedColumnDescriptors,
    initialQueries,
    initialFilters,
    isFetchingComplianceForms,
    allowedFilters,
    quickFilters,
  }
}

class ApplicationComplianceFormsC extends Component {
  render() {
    return (
      <ApplicationComplianceForms {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationComplianceFormsC)
