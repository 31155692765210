import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import {
  CANCEL_APPLICATION_FORM_MESSAGE,
  NO,
  YES,
} from 'constants/language/languageConstants'

const CancelApplicationForm = ({
  redirectUserToHome = () => {},
  closeModal = () => {},
}) => {
  return (
    <div className='CancelApplicationForm'>
      <div className='message'>{CANCEL_APPLICATION_FORM_MESSAGE}</div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={NO} variant='ghost' className='cancel-button' />
        <Button onClick={redirectUserToHome} label={YES} />
      </div>
    </div>
  )
}

CancelApplicationForm.propTypes = {
  redirectUserToHome: PropTypes.func,
  closeModal: PropTypes.func,
}

export default CancelApplicationForm
