import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from './Checkbox'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'

import {
  allowedKeyPressControls,
  preventDefaultEvent,
  MOUSE_CLICK_KEYCODE,
} from 'constants/focusControlConstants'

// TODO: Remove all other checkboxes and use this one instead for all cases, including redux forms
const mapStateToProps = (state, props) => {
  const {
    checked = false,
    handleChange,
    input,
    name,
  } = props

  return {
    name: get(input, 'name', name),
    checked: !!get(input, 'value', checked),
    handleChange,
  }
}

class CheckboxC extends Component {
  constructor(props) {
    super(props)

    const {
      checked = false,
    } = props

    this.state = {
      checked,
    }
  }

  componentDidUpdate (prevProps) {
    const {
      checked: nextChecked,
      action,
      input,
      handleChange = () => {},
    } = this.props

    const {
      input: prevInput,
      checked: prevChecked,
    } = prevProps

    const prevInputValue = get(prevInput, 'value')
    const nextInputValue = get(input, 'value')

    if (prevInputValue !== nextInputValue) {
      this.setState({ checked: nextInputValue })
    } else if (nextChecked !== prevChecked) {
      this.setState({ checked: nextChecked })

      if (action === 'Approve All') {
        handleChange({ checked: nextChecked })
      }
    }
  }

  handleChange = (event) => {
    const {
      objectId = '',
      checked,
      handleChange,
      onChange,
      input,
    } = this.props

    if (isFunction(handleChange)) {
      preventDefaultEvent(event)
      handleChange({ event, objectId, checked })
    } else if (allowedKeyPressControls(event)) {
      preventDefaultEvent(event)
      const targetChecked = get(event, 'target.checked', false)
      const nextChecked = get(event, 'keyCode') === MOUSE_CLICK_KEYCODE ? targetChecked : !targetChecked

      this.setState({ checked: nextChecked })

      if (isFunction(onChange)) {
        onChange(nextChecked)
      }

      const inputOnChange = get(input, 'onChange')

      if (isFunction(inputOnChange)) {
        inputOnChange(nextChecked)
      }
    }
  }

  render() {
    const { handleChange } = this

    const { checked } = this.state

    return (
      <Checkbox
        {...this.props}
        handleChange={handleChange}
        checked={checked}
      />
    )
  }
}

export default connect(mapStateToProps)(CheckboxC)
