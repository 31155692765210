import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateUser from 'components/Customer/Modals/UpdateUserModal/UpdateUser'
import { UPDATE_USER } from 'constants/amplitudeConstants'
import { PATCH_USER_F_REQUEST } from 'constants/flowConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

class UpdateUserC extends Component {
  closeModal = () => {
    const { dispatch } = this.props

    dispatch(hideModalAction())
  }

  toggleUser = () => {
    const {
      credentials,
      user = {},
      userId,
      dispatch,
    } = this.props

    const enabledState = get(user, 'state')

    dispatch({
      type: PATCH_USER_F_REQUEST,
      payload: {
        values: {
          enabled: !enabledState,
        },
        credentials,
        id: userId,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(UPDATE_USER, {
          credentials,
          userId,
        }),
      },
    })
  }

  render() {
    const { closeModal, toggleUser } = this

    return (
      <UpdateUser
        closeModal={closeModal}
        toggleUser={toggleUser}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UpdateUserC)
