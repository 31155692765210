import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  ALL,
  CANCELED,
  FAILED,
} from 'constants/transferConstants'

import {
  ID,
  CREATED_ON,
  APPLICATION,
  BUYER,
  AMOUNT,
  STATE,
  REASON_CODE,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  REFUND_TYPE,
  SPLIT_TRANSACTION,
  PAYMENT_INSTRUMENT,
  MASKED_NUMBER,
  BRAND_SLASH_TYPE,
  INSTRUMENT_TYPE,
  EMAIL,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(BUYER, 'buyerIdentity.buyerName', {
    className: 'name',
    headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={EMAIL} />,
    formatter: ({ buyerIdentity }) => <TableItemSubtitle title={get(buyerIdentity, 'buyerName')} subtitle={get(buyerIdentity, 'email')} />,
    condition: notRoleMerchant,
  }),
  column(AMOUNT, ['amount'], {
    className: 'amount divider',
    headerFormatter: () => <TableItemSubtitle title={AMOUNT} subtitle={REFUND_TYPE} />,
    formatter: ({ displayAmount, currency, splitTransferIds = [] }) => (
      <TableItemSubtitle
        titleComponent={() => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />}
        subtitle={!isEmpty(splitTransferIds) ? SPLIT_TRANSACTION : ' '}
      />
    ),
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleIndent
        emptySignifierCheck={state === FAILED || state === CANCELED}
      />
    ),
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return (
        <TableItemSubtitle
          title={name}
          subtitle={maskedAccountNumber || maskedFullCardNumber}
        />
      )
    },
    className: 'normal',
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
]

export default columnDescriptors
