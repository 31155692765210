import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import hideModalAction from 'utilities/actions/hideModalAction'
import { getDispatch } from 'state-layer/configureStore'
import { goBack } from 'state-layer/history'

import {
  CLOSE,
  CONTACT_FINIX_SUPPORT_TO_GET_STARTED,
  CONTACT_SUPPORT,
  t,
} from 'constants/language/languageConstants'

const closeModal = () => {
  const dispatch = getDispatch()
  goBack()
  dispatch(hideModalAction())
}

const contactSupportMailTo = () => {
  const mailtoLink = document.createElement('a')
  mailtoLink.href = 'mailto:support@finix.com'
  mailtoLink.click()
  mailtoLink.remove()
}

const FeatureNotAvailableModal = ({
  title = '',
  className = '',
  description = '',
}) => {
  return (
    <GenericModal
      title={title}
      className={className}
    >
      <div className='modal-content'>
        <p className='p-1'>{t(description)} {t(CONTACT_FINIX_SUPPORT_TO_GET_STARTED)}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button label={t(CONTACT_SUPPORT)} variant='ghost' onClick={contactSupportMailTo} />
        <Button label={t(CLOSE)} onClick={closeModal} />
      </div>
    </GenericModal>
  )
}

FeatureNotAvailableModal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
}

export default FeatureNotAvailableModal
