import React from 'react'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { httpCodeStatusMap } from 'constants/statusConstants'

import {
  ID,
  TIMESTAMP,
  HTTP_STATUS,
  METHOD,
  REQUEST_PATH,
  REQUESTING_USER,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', { className: 'large' }),
  column(TIMESTAMP, 'displayCreatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={TIMESTAMP} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(HTTP_STATUS, 'httpStatusCode', {
    className: 'state',
    formatter: ({ httpStatusCode }) => {
      return (
        <ColorcodedStatus
          value={httpStatusCode.length > 0 ? httpStatusCode.charAt(0) : ''}
          data={httpCodeStatusMap}
          icon='circle'
          customValue={httpStatusCode}
        />
      )
    },
  }),
  column(METHOD, 'httpMethod', { className: 'medium' }),
  column(REQUEST_PATH, 'apiPath', { className: 'x-large' }),
  column(REQUESTING_USER, 'requestingUser', { className: 'id' }),
]

export default columnDescriptors
