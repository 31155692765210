import './EditActiveComplianceTemplateFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import ComplianceFormTemplatesC from 'components/Customer/Pages/Compliance/ComplianceFormTemplates/ComplianceFormTemplatesC'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import submitEditActiveComplianceTemplateForm from 'utilities/submit/submitEditActiveComplianceTemplateForm'
import { EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM } from 'constants/formConstants'
import { COMPLIANCE_TEMPLATE_ID_SELECTOR_OPTIONS } from 'constants/complianceConstants'
import { CHANGE_TEMPLATE } from 'constants/language/languageConstants'

const EditActiveComplianceTemplateForm = ({
  handleSubmit = () => {},
  selectedItemsKey = '',
  selectInput = false,
  selectTable = false,
  enableSubmit = false,
}) => {
  return (
    <form className='EditActiveComplianceTemplateForm' onSubmit={handleSubmit(submitEditActiveComplianceTemplateForm)}>
      <div className='section'>
        <Field
          name='complianceTemplateIdSelector'
          label='Select compliance template ID by:'
          component={RadioButton}
          options={COMPLIANCE_TEMPLATE_ID_SELECTOR_OPTIONS}
        />

        { selectInput && (
          // TODO: add validation to check for valid template ID
          <Field
            name='complianceTemplateIdInput'
            label='Compliance Form Template ID'
            component={InputField}
          />
        )}

        { selectTable && (
          // TODO: move to modal. can't have form be a descendant of another form
          <ComplianceFormTemplatesC
            selectedItemsKey={selectedItemsKey}
            selectSingleItem
          />
        )}
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label={CHANGE_TEMPLATE} disabled={!enableSubmit} />
      </div>
    </form>
  )
}

EditActiveComplianceTemplateForm.propTypes = {
  handleSubmit: PropTypes.func,
  selectedItemsKey: PropTypes.string,
  selectInput: PropTypes.bool,
  selectTable: PropTypes.bool,
  enableSubmit: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_DEFAULT_APPLICATION_COMPLIANCE_TEMPLATE_FORM,
})(EditActiveComplianceTemplateForm)
