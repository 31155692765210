import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_LINKS } from 'constants/apiConstants'
import { PAYMENT_LINKS as PAYMENT_LINKS_SERVICE } from 'constants/pathConstants'

const getPaymentLinksAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    path: PAYMENT_LINKS,
    queries,
    credentials,
    meta,
    service: PAYMENT_LINKS_SERVICE,
  })
}

export default getPaymentLinksAPI
