import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  AUTHORIZATION,
  AUTHORIZATIONS,
} from 'constants/apiConstants'

const getAuthorizationAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: AUTHORIZATION({ authorizationId: id }),
    credentials,
    service: AUTHORIZATIONS,
  })
}

export default getAuthorizationAPI
