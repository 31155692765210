import i18next from 'i18next'
import React from 'react'

// i18next translation (only execute on strings)
export const t = (text) => {
  // if running jest tests, return the text as is
  if (typeof jest !== 'undefined') return text

  // check for strings we may not want to convert
  if (typeof text !== 'string') return text
  if (text.includes('://')) return text

  return i18next.t(text)
}

export const A_RECEIPT_WILL_BE_EMAILED_TO_YOU = 'A receipt will be emailed to you'
export const ACCEPT_LIABILITY = 'Accept Liability'
export const ACCEPT_LIABILITY_CONFIRMATION_MSG = 'Please confirm that you would like to accept liability for this dispute. This will end the dispute process.'
export const ACCEPT_LIABILITY_QUESTION = 'Don\'t want to defend this dispute?'
export const ACCEPT_LIABILITY_WARNING = 'This decision will be final and cannot be undone.'
export const ACCEPT_LIABILITY_CHECKBOX_STRING = 'I understand that this decision means the merchant acknowledges and agrees to the buyer\'s claim.'
export const ACCEPTED = 'Accepted'
export const ACCEPTED_BY = 'Accepted By'
export const ACCEPTED_ON = 'Accepted On'
export const ACCEPTED_CARDS = 'Accepted Cards'
export const ACCEPTED_PAYMENT_METHODS = 'Accepted Payment Methods'
export const ACCEPTED_TERMS_OF_SERVICE = 'Accepted Terms of Service'
export const ACCEPTING_EVENTS = 'Accepting Events'
export const ACCEPTING_LIABILITY = 'Accepting Liability'
export const ACCEPTING_LIABILITY_LEARN_MORE_MSG = 'Learn more about when to accept liability'
export const ACCEPTOR_ID = 'Acceptor ID'
export const ACCOUNT = 'Account'
export const ACCOUNT_ADDED = 'Account Added'
export const ACCOUNT_BALANCE = 'Account Balance'
export const ACCOUNT_CURRENCY = 'Account Currency'
export const ACCOUNT_DETAILS = 'Account Details'
export const ACCOUNT_EXECUTIVE = 'Account Executive'
export const ACCOUNT_NAME = 'Account Name'
export const ACCOUNT_EXECUTIVE_SUBTITLE = 'Select the AE who is responsible for the Opportunity.'
export const ACCOUNT_EXECUTIVE_TOOLTIP = 'This is a list of users with the Sales role. To add someone new, reach out to an Administrator.'
export const ACCOUNT_ID = 'Account ID'
export const ACCOUNT_INFO = 'Account Info'
export const ACCOUNT_LAST_FOUR = 'Account Last Four'
export const ACCOUNT_FIRST_SEEN_DATE = 'Account First Seen Date'
export const ACCOUNT_LAST_UPDATED_DATE = 'Account Last Updated Date'
export const ACCOUNT_HOLDER_NAME = 'Account Holder Name'
export const ACCOUNT_NUMBER = 'Account Number'
export const ACCOUNT_ROUTING_NUMBER = 'Account Routing Number'
export const ACCOUNT_RESPONSE_CODE = 'Account Response Code'
export const ACCOUNT_NUMBER_PROVIDED = 'Account Number Provided'
export const ACCRUING_STARTED = 'Accruing Started'
export const ACTIVE_MERCHANTS = 'Active Merchants'
export const CONFIRM_ACCOUNT_NUMBER = 'Confirm Account Number'
export const CONFIRM_TOTAL_REFUND_AMOUNT = 'Confirm Total Refund Amount'
export const CONFIRM_STOP_ACCRUING_SETTLEMENT = 'Confirm Stop Accruing Settlement'
export const CONNECTION_TYPE = 'Connection Type'
export const ACCOUNT_TOKEN = 'Account Token'
export const ACCOUNT_TYPE = 'Account Type'
export const ACCOUNTS = 'Accounts'
export const ACQUIRER_REFERENCE_NUMBER = 'Acquirer Reference Number (ARN)'
export const ACTIVE = 'Active'
export const ACTIVE_DISPUTES = 'Active Disputes'
export const ACTIVE_DISPUTES_TOOLTIP_ROLE_PARTNER = ' represents the number of disputes open that you must decide to accept or challenge.'
export const ACTIVE_DISPUTES_TOOLTIP_ROLE_PLATFORM = ' represents the number of currently open disputes that you can decide to accept or challenge.'
export const ACTIVE_DISPUTE_VOLUME = 'Active Dispute Volume'
export const ADD = 'Add'
export const ADD_ADDITIONAL_DETAILS_ABOUT_YOUR_BUSINESS = 'Add additional details about your business'
export const ADD_ANOTHER = 'Add Another'
export const ADD_BUYER = 'Add Buyer'
export const ADD_BUYER_SUCCESS_MSG = 'Buyer information was successfully added'
export const ADD_BUYER_INFORMATION = 'Add Buyer Information'
export const ADD_NOTE_TO_BALANCE_ADJUSTMENT_PLACEHOLDER = 'Add note to this balance adjustment.'
export const ADD_DETAILS_AND_SUBMIT = 'Add Details and Submit'
export const ADD_PROCESSOR = 'Add Processor'
export const ADD_NEW_TEAM_MEMBER = 'Add New Team Member'
export const ADD_NEW_BANK_ACCOUNT_LABEL = 'Add a new bank account'
export const ADD_TERM = 'Add Term'
export const ADD_SCHEDULE = 'Add Schedule'
export const ADD_TEMPLATE = 'Add Template'
export const ADD_COMBINATION_PLAN = 'Add Combination Plan'
export const ADD_VALUE = 'Add Value'
export const ADD_FIELD = 'Add Field'
export const ADD_FUNDS = 'Add Funds'
export const ADD_FUNDS_FROM_LABEL = 'Add funds from'
export const ADDED = 'Added'
export const ADDITIONAL_DATA = 'Additional Data'
export const ADDITIONAL_EDD_DOCUMENTATION_REQUIRED = 'Additional EDD Documentation Required'
export const ADDITIONAL_MERCHANT_INFORMATION = 'Additional Merchant Information'
export const ADDITIONAL_MERCHANT_INFORMATION_TOOLTIP = 'Additional dimensions to view your merchant data.'
export const ADDITIONAL_VERIFICATION_NEEDED = 'Additional Verification Needed'
export const ADJUSTMENT_TRANSFER_ID = 'Adjustment Transfer ID'
export const ACH = 'ACH'
export const REQUIRED_ACH = 'ACH *'
export const ACH_CREDIT = 'ACH Credit'
export const ACH_DEBIT = 'ACH Debit'
export const ACH_ENTRY = 'ACH Entry'
export const ACH_RETURNS = 'ACH Returns'
export const ACH_RETURNS_INSIGHTS_TOOLTIP_TITLE = 'Summary of ACH debit returns within the filtered time frame.'
export const ACH_RETURNS_TRENDS = 'ACH Returns Trends'
export const ACH_RETURNS_TRENDS_TOOLTIP = 'Historical view of ACH returns by monetary volume, quantity, and return rate percentage calculated as the quantity of transactions returned versus total quantity of ACH transactions attempted.'
export const ACH_RETURNS_PER_MERCHANT_OR_APPLICATION_TOOLTIP = 'Table of ACH return activity per merchant or application with the ability to sort on multiple data points.'
export const ACH_RETURNS_PER_MERCHANT = 'ACH Returns per Merchant'
export const ACH_RETURNS_PER_MERCHANT_OR_APPLICATION = 'ACH Returns per Merchant or Application'
export const ACH_RETURNS_PER_MERCHANT_TOOLTIP = 'Table of ACH return activity per merchant with the ability to sort on multiple data points.'
export const ACH_RETURNS_BY_REASON_CODE = 'ACH Returns by Reason Code'
export const ACH_RETURNS_BY_REASON_CODE_TOOLTIP = 'Reason codes help explain why ACH transactions were returned. You can use this to identify areas that need attention.'
export const ACH_RETURN_VOLUME = 'ACH Return Volume'
export const ACH_RETURN_COUNT = 'ACH Return Count'
export const ACH_RETURN_RATE = 'ACH Return Rate'
export const ACH_AMPERSAND_ADDITIONAL = 'ACH & Additional'
export const ACH_TRANSFERS = 'ACH Transfers'
export const ACH_TRANSFERS_FEE_PROFILE_RULES_SUBTITLE = 'Application can charge merchants fees per ACH payments.'
export const ACH_TRANSFERS_FEE_PROFILE_RULES_EDIT_SUBTITLE = 'Set fee limits for the application to charge merchants for processing ACH transfers.'
export const ACH_TRANSFERS_SUBTITLE = 'Processing fees triggered by bank transfers.'
export const ACH_TRANSACTION_REVIEW_HEADER = 'Please share the information below with the buyer before proceeding.'
export const ACTION = 'Action'
export const ACTIONS ='Actions'
export const ACTION_REQUIRED = 'Action Required'
export const ACTIVITY = 'Activity'
export const ADDITIONAL_BUSINESS_DETAILS_DESCRIPTION = 'Let us know your contact information and where your business is located.'
export const ADDITIONAL_DETAILS = 'Additional Details'
export const ADDITIONAL_DOCUMENTS = 'Additional Documents'
export const ADDITIONAL_INFORMATION = 'Additional Information'
export const ADDITIONAL_FEES = 'Additional Fees'
export const ADDITIONAL_FEES_FEE_PROFILE_SUBTITLE = 'Application can charge merchants with additional fees applied to all card and ACH payments.'
export const ADDITIONAL_FEES_SUBTITLE = 'Additional charges will be applied to all card and ACH payments.'
export const ADDRESS = 'Address'
export const ADDRESSES = 'Addresses'
export const ADDRESS_LINE_ONE = 'Address Line 1'
export const ADDRESS_LINE_TWO = 'Address Line 2'
export const ADDRESS_INFORMATION = 'Address Information'
export const ADDRESS_VERIFICATION = 'Address Verification'
export const ADDRESS_RECORDS = 'Address Records'
export const ADD_A_BEARER_TOKEN = 'Add a bearer token'
export const ADD_A_NEW_CARD = 'Add a new card'
export const ADD_A_NEW_PAYMENT_METHOD = 'Add a new payment method'
export const ADD_BANK_ACCOUNT = 'Add Bank Account'
export const ADD_BANK_ACCOUNT_MANUALLY = 'Add Bank Account Manually'
export const ADD_PAYMENT_INSTRUMENT = 'Add Payment Instrument'
export const ADD_BASIC_AUTHENTICATION_CREDENTIALS = 'Add basic authentication credentials'
export const ADDED_BY = 'Added By'
export const ADDED_BY_ACQUIRER_ID = 'Added By Acquirer ID'
export const ADDED_ON = 'Added On'
export const ADDITIONAL_VERIFICATION_NEEDED_CONFIRMATION_MSG = 'Please confirm that you would like to request additional verification.'
export const ADD_TEAM_MEMBER = 'Add Team Member'
export const ADD_NEW_TEAM_MEMBER_SUBTITLE = 'You are adding a new member '
export const ADD_NOTE = 'Add Note'
export const ADD_NEW_NOTE = 'Add New Note'
export const ADJUSTMENT_TRANSFERS = 'Adjustment Transfers'
export const ADJUSTMENT_TYPE = 'Adjustment Type'
export const ADJUSTMENT = 'Adjustment'
export const ADJUSTMENTS = 'Adjustments'
export const ADJUSTMENT_DEBITS = 'Adjustment Debits'
export const ADJUSTMENT_CREDITS = 'Adjustment Credits'
export const ADMINISTRATOR_TOOLS = 'Administrator Tools'
export const ADVANCED_SETTINGS = 'Advanced Settings'
export const ADVERSE_MEDIA = 'Adverse Media'
export const ADVERSE_MEDIA_SCREENING = 'Adverse Media Screening'
export const ADVERSE_MEDIA_SCREENING_DETAILS = 'Adverse Media Screening Details'
export const ADVERSE_MEDIA_REVIEWS = 'Adverse Media Reviews'
export const ADVERSE_MEDIA_REVIEW_CADENCE = 'Adverse Media Review Cadence'
export const AMOUNT = 'Amount'
export const ALERT = 'Alert'
export const ALL = 'All'
export const ALL_ACTIVITY = 'All Activity'
export const ALL_CARD_BRANDS = 'All Card Brands'
export const ALL_CARD_BRANDS_REQUIRED = 'All Card Brands *'
export const ALL_FILTERS = 'All Filters'
export const ALLOW_AVS_FAILURE = 'Allow AVS Failure'
export const ALLOW_AVS_FAILURE_TOOLTIP = 'If enabled, transactions will succeed even if the AVS check fails. If disabled, transactions will fail if the AVS check fails.'
export const ALLOW_AVS_FAILURE_HELPER_TEXT = 'Transactions will succeed even if the AVS check fails.'
export const ALLOW_CVV_FAILURE = 'Allow CVV Failure'
export const ALLOW_CVV_FAILURE_TOOLTIP = 'If enabled, transactions will succeed even if the CVV check fails. If disabled, transactions will fail if the CVV check fails.'
export const ALLOW_CVV_FAILURE_HELPER_TEXT = 'Transactions will succeed even if the CVV check fails.'
export const ALLOW_ALL_MCCS = 'Allow All MCCs'
export const ALLOW_ALL_MCCS_SUBLABEL = 'All MCCs approved by Finix will be enabled for this Underwriting Profile'
export const ALLOWED = 'Allowed'
export const ALLOWED_PAYMENT_METHODS = 'Allowed Payment Methods'
export const ALLOWED_MERCHANT_CATEGORY_CODES_TITLE = 'Allowed Merchant Category Codes (MCC)'
export const ALSO_KNOWN_AS = 'Also Known As'
export const ALWAYS = 'Always'
export const AMERICAN_EXPRESS_MID = 'American Express MID'
export const AMERICAN_EXPRESS_ASSESSMENTS = 'American Express Assessments'
export const AMERICAN_EXPRESS_MERCHANT_ID_LABEL = 'Do you have an American Express Merchant ID (MID)?'
export const AMERICAN_EXPRESS_MARKETING_CONSENT = 'American Express Marketing Consent'
export const AMEX_MARKETING_CONSENT_USA_CHECKBOX_LABEL = 'By checking this box, you consent to (i) Finix sharing your contact information (business name, business contact name, phone number and email address) with American Express and (ii) American Express reaching out to you directly to discuss the American Express card acceptance.'
export const AMEX_MARKETING_CONSENT_CAN_CHECKBOX_LABEL = 'By checking this box, you consent to (i) Finix sharing your contact information (business name, business contact name, phone number and email address) with Amex Bank of Canada and (ii) Amex Bank of Canada reaching out to you directly to discuss the American Express card acceptance.'
export const AMOUNT_TYPE = 'Amount Type'
export const AMOUNT_DETAILS = 'Amount Details'
export const AMOUNT_TYPE_FIXED_OPTION_LABEL = 'Fixed Amount (for product or services)'
export const AMOUNT_TYPE_VARIABLE_OPTION_LABEL = 'Variable Amount (buyer chooses what to pay)'
export const ANNUAL_ACH_VOLUME = 'Annual ACH Volume'
export const ANNUAL_EFT_VOLUME = 'Annual EFT Volume'
export const ANNUAL_ACH_VOLUME_USD = 'Annual ACH Volume (USD)'
export const ANNUAL_CARD_VOLUME = 'Annual Card Volume'
export const ANNUAL_GROSS_PROCESSING_VOLUME = 'Annual Gross Processing Volume'
export const ANNUAL_GROSS_PROCESSING_VOLUME_TOOLTIP = 'Total volume processed in the previous year'
export const ANNUAL_CARD_VOLUME_USD = 'Annual Card Volume (USD)'
export const ANNUAL_TRANSACTION_COUNT = 'Annual Transaction Count'
export const API_RESPONSE = 'API Response'
export const API_METHOD = 'API Method'
export const API_ROUTE = 'API Route'
export const API_DOCS_LABEL = "Documentation Links"
export const APPLICATION = 'Application'
export const APPLICATION_ID = 'Application ID'
export const APPLICATION_LIMITS = 'Application Limits'
export const APPLICATION_ACH_VELOCITY_LIMITS = 'Application ACH Velocity Limits'
export const APPLICATION_CARD_VELOCITY_LIMITS = 'Application Card Velocity Limits'
export const APPLICATION_MAX_CARD_TRANSACTION_AMOUNT = 'Application\'s Max Card Transaction Amount'
export const APPLICATION_MAX_ACH_TRANSACTION_AMOUNT = 'Application\'s Max ACH Transaction Amount'
export const APPLICATION_PAYOUT_PLAN = 'Application Payout Plan'
export const APPLICATION_PAYOUT_LIMITS = 'Application Payout Limits'
export const APPLICATION_PROFILE = 'Application Profile'
export const APPLICATION_WEBHOOKS = 'Application Webhooks'
export const APPLICATIONS = 'Applications'
export const APPLICATIONS_LIST = 'Applications List'
export const APPLICATION_TO_ADD_DEVICE_FOR_TOOLTIP_MSG = 'The application you want to add the device for.'
export const APPLICATION_UNDERWRITING = 'Application Underwriting'
export const APPLICATION_UNDERWRITING_SUBTITLE_DESCRIPTION = 'Review application forms received from self-service applicants and sales prospects. Request for additional verification, approve, or reject them. If approved, you will need to manually create an Application for them in production.'
export const APPLICATION_FORM_WITHDRAWN = 'Application Form Withdrawn'
export const APPLICATION_FORM_DETAILS = 'Application Form Details'
export const APPLICATION_MERCHANT_NAME = 'Application / Merchant Name'
export const APPLICATION_NAME = 'Application Name'
export const APPROVE = 'Approve'
export const APPROVE_MERCHANT = 'Approve Merchant'
export const APPROVE_APPLICANT = 'Approve Applicant'
export const APPROVED_MERCHANTS = 'Approved Merchants'
export const APPROVE_SETTLEMENTS = 'Approve Settlements'
export const APPROXIMATE_PAYMENTS_VOLUME = 'Approximate Payments Volume'
export const APPLY_NOW = 'Apply Now'
export const APPLY_CARD_NOT_PRESENT_FEE_PROFILE = 'Apply Card-Not-Present Fee Profile'
export const APPLY_CARD_NOT_PRESENT_FEE_PROFILE_SUBTITLE = 'In-person payments will charge the same set of fees as online payments.'
export const APPROVED = 'Approved'
export const ARE_THERE_ANY_BENEFICIAL_OWNERS = 'Are there any Beneficial Owners?'
export const ARE_THERE_ANY_BENEFICIAL_OWNERS_SUBTITLE = 'A beneficial owner owns 25% or more of the business. We collect each individual’s information (up to a max of 4 beneficial owners) to verify their identity. Additional information may be requested.'
export const ARCHIVE_A_PLAN = 'Archive a Plan'
export const ARCHIVE_PLAN  = 'Archive Plan'
export const ARCHIVE_STANDARD_PLAN = 'Archive Standard Plan'
export const ARCHIVE_STANDARD_PLAN_CONFIRMATION_MSG = 'Please confirm that you would like to archive this subscription plan. Please note that all customers on this plan will continue to be charged until you cancel the subscriptions.'
export const ASSIGNEE = 'Assignee'
export const ASSIGN = 'Assign'
export const ASSOCIATED_FEES = 'Associated Fees'
export const ASSOCIATED_PERSON = 'Associated Person'
export const ASSOCIATED_PERSONS = 'Associated Persons'
export const ASSOCIATED_PERSONS_INFORMATION = 'Associated Persons Information'
export const ASSIGN_TO_ME = 'Assign to me'
export const ASSIGN_TO_SELF = 'Assign to Self'
export const ASSIGNED_TO = 'Assigned To'
export const ASSOCIATED_IDENTITIES = 'Associated Identities'
export const ASSOCIATED_IDENTITY_ID = 'Associated Identity ID'
export const ASSOCIATION_ESTATE_TRUST = 'Association Estate Trust'
export const ASK_FOR_MORE_INFORMATION = 'Ask for more information'
export const ATTEMPT_COUNT = 'Attempt Count'
export const ATTEMPT_ID = 'Attempt ID'
export const ATTEMPT_NUMBER = 'Attempt Number'
export const ATTESTOR_NAME = 'Attestor Name'
export const AUDIT_LOG = 'Audit Log'
export const AUDIT_LOGS = 'Audit Logs'
export const AUDIT_LOG_EVENT = 'Audit Log Event'
export const AUTHENTICATION = 'Authentication'
export const AUTHENTICATION_CODE = 'Authentication Code'
export const AUTHENTICATION_SECURITY_MSG = 'For security purposes, the credentials saved in the webhook are not available for review.'
export const AUTHENTICATION_TYPE = 'Authentication Type'
export const AUTHORIZATION = 'Authorization'
export const AUTHORIZATION_ID = 'Authorization ID'
export const AUTHORIZATION_SUCCESSFUL = 'Authorization Successful!'
export const AUTHORIZATION_CONFIRMATION = 'Authorization Confirmation'
export const AUTHORIZATION_INSIGHTS_TOOLTIP_TITLE = 'Summary of authorizations within the filtered time frame.'
export const AUTHORIZATION_TYPE = 'Authorization Type'
export const AUTHORIZATION_RATE = 'Authorization Rate'
export const AUTHORIZATION_RATE_TOOLTIP = ' represents the average authorization rate of aggregate transactions for the filtered time frame.'
export const AUTHORIZATION_RATE_HOMEPAGE_TOOLTIP = ' represents the average authorization rate of aggregate transactions in the time period selected.'
export const AUTHORIZATION_VOIDED = 'Authorization Voided'
export const AUTHORIZATIONS = 'Authorizations'
export const AUTHORIZED_ON = 'Authorized On'
export const AUTHORIZATION_API_REFERENCE = 'Authorization API Reference'
export const AUTOMATED_UNDERWRITING = 'Automated Underwriting'
export const AUTOMATIC_APPROVAL_RATE = 'Automatic Approval Rate'
export const AUTO_CLOSE = 'Auto Close'
export const AUTO_APPROVED = 'Auto Approved'
export const AMOUNT_TO_BE_ADDED = 'Amount to be added'
export const AMOUNT_TO_BE_DEDUCTED = 'Amount to be deducted'
export const AMOUNT_TO_BE_CAPTURED = 'Amount to be captured from the cardholder.'
export const AVAILABLE_BALANCE = 'Available Balance'
export const AVAILABLE_ENTITIES_AND_TYPES = 'Available entities and types'
export const AVERAGE_TRANSACTION_AMOUNT = 'Average Transaction Amount'
export const AVERAGE_TRANSACTION_AMOUNT_TOOLTIP = ' represents the average transaction amount across all of your merchants within the time period selected.'
export const AVG_TRANSACTION_AMOUNT = 'Avg. Transaction Amount'
export const AVG_TRANSACTION_AMOUNT_HOMEPAGE_TOOLTIP = ' represents the average transaction amount across all of your merchants within the time period selected.'
export const AVERAGE_CARD_TRANSFER_AMOUNT_LABEL = 'Avg Card Transfer Amount'
export const AVERAGE_CARD_TRANSFER_AMOUNT = 'Average Card Transfer Amount'
export const AVERAGE_CARD_TRANSACTION_AMOUNT = 'Average Card Transaction Amount'
export const AVERAGE_ACH_TRANSACTION_AMOUNT = 'Average ACH Transaction Amount'
export const AVERAGE_EFT_TRANSACTION_AMOUNT = 'Average EFT Transaction Amount'
export const AVERAGE_TIME_TO_COMPLETION = 'Avg. Time to Completion'
export const BACK = 'Back'
export const BACK_PHOTO = 'Back Photo'
export const BACK_TO_DASHBOARD = 'Back to Dashboard'
export const BALANCE_TRANSFER = 'Balance Transfer'
export const BALANCE_TRANSFERS = 'Balance Transfers'
export const BANK_ACCOUNT = 'Bank Account'
export const BANK_CODE = 'Bank Code'
export const BANK_ACCOUNT_INFO = 'Bank Account Info'
export const BANK_ACCOUNT_TYPE = 'Bank Account Type'
export const BANK_ACCOUNT_SUBHEADER = 'Select the merchant\'s bank account where payouts will be deposited.'
export const BANK_ACCOUNT_INFORMATION = 'Bank Account Information'
export const BANK_ACCOUNT_VERIFICATION = 'Bank Account Verification'
export const BANK_ACCOUNT_SUCCESSFULLY_LINKED_MSG = 'Your bank account has been successfully linked. '
export const BANK_ACCOUNTS = 'Bank Accounts'
export const BANK_DOCUMENT = 'Bank Document'
export const BANK_DOCUMENT_PROVIDED = 'Bank Document Provided'
export const BANK_NAME = 'Bank Name'
export const BANK_ADDRESS = 'Bank Address'
export const BANK_TRANSFER = 'Bank Transfer'
export const BANKRUPTCY_SUBJECT_RECORD_COUNT = 'Bankruptcy Subject Record Count'
export const BANKRUPTCY_CREDITOR_RECORD_COUNT = 'Bankruptcy Creditor Record Count'
export const BASIC = 'Basic'
export const BASED_ON_WHO_YOUR_CUSTOMERS_ARE = 'Based on who your customers are'
export const BASED_ON_WHO_YOUR_USERS_CUSTOMERS_ARE = 'Based on who your users\' customers are'
export const BASED_ON_THE_CHANNEL_FOR_CARD_ACCEPTANCE = 'Based on the channel for card acceptance'
export const BATCH_TIME_SETTING = 'Batch Time Setting'
export const CUTOFF_TIME = 'Cutoff Time'
export const BEARER_TOKEN = 'Bearer Token'
export const BEFORE_CONTINUING_CONFIRM = 'Before continuing, please confirm:'
export const BENEFICIAL_OWNER = 'Beneficial Owner'
export const BENEFICIAL_OWNERSHIP = 'Beneficial Ownership'
export const BENEFICIAL_OWNERSHIP_INFO = 'Beneficial Ownership Info'
export const BENEFICIAL_OWNER_INFO = 'Beneficial Owner Info'
export const BENEFICIAL_OWNER_NOTICE = 'Note: If you are providing information on behalf of a beneficial owner, you certify that you received their permission to share their information for identity verification and that they authorize us to perform any necessary background or other checks.'
export const BIN = 'BIN'
export const BILLING_ADDRESS = 'Billing Address'
export const BILLING_ADDRESS_1_LINE_LABEL = 'Billing Address Line 1'
export const BILLING_ADDRESS_2_LINE_LABEL = 'Billing Address Line 2'
export const BILLING_DETAILS = 'Billing Details'
export const BILLING_SETTINGS = 'Billing Settings'
export const BILLING_SETTING = 'Billing Setting'
export const BILLING_FREQUENCY = 'Billing Frequency'
export const BILLING_METHOD = 'Billing Method'
export const BIRTH_YEAR = 'Birth Year'
export const BIRTH_YEARS = 'Birth Years'
export const BIRTH_DATES = 'Birth Dates'
export const BIRTHDATE = 'Birthdate'
export const BLENDED = 'Blended'
export const BLENDED_HELPER_TEXT = 'Set a fixed rate that encompass the markup, interchange, and any other card associated fees that the applications plan to charge.'
export const BLENDED_PRICING = 'Blended Pricing'
export const BLENDED_PRICING_DESCRIPTION = 'A set of fixed rates that encompass the markup, interchange, and any other card associated fees the applications plan to charge.'
export const BRANDING = 'Branding'
export const BRAND_COLORS = 'Brand Colors'
export const BRAND_SLASH_TYPE = 'Brand / Type'
export const BUSINESS = 'Business'
export const BUSINESS_ADDRESS = 'Business Address'
export const BUSINESS_ALIASES = 'Business Aliases'
export const BUSINESS_ADDRESS_MAP_SEARCH = 'Business Address Map Search'
export const BUSINESS_APPROVED_FOR_A_LIVE_ACCOUNT = 'Business Approved for a Live Account'
export const BUSINESS_NOT_APPROVED_FOR_A_LIVE_ACCOUNT = 'Business Not Approved for a Live Account'
export const BUSINESS_CONTACTS = 'Business Contacts'
export const BUSINESS_CHECKING_ACCOUNT = 'Business Checking Account'
export const BUSINESS_CHECKING = 'Business Checking'
export const BUSINESS_SAVINGS = 'Business Savings'
export const BUSINESS_CHECKING_ACCOUNT_HELPER_TEXT = 'Let us know where your business should receive payouts to. The bank account must allow deposits (credits) and withdrawals (debits).'
export const BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT = ' The bank account must allow deposits (credits) and withdrawals (debits). '
export const BUSINESS_CHEQUING = 'Business Chequing'
export const BUSINESS_CHEQUING_ACCOUNT = 'Business Chequing Account'
export const BUSINESS_DBA = 'Business DBA'
export const BUSINESS_DESCRIPTION = 'Business Description'
export const BUSINESS_HOURS = 'Business Hours'
export const SHORT_BUSINESS_DESCRIPTION = 'Short Business Description'
export const BUSINESS_DETAILS = 'Business Details'
export const BUSINESS_IDENTITY = 'Business Identity'
export const BUSINESS_INFO = 'Business Info'
export const BUSINESS_INFO_DESCRIPTION = 'Finix collects this information to verify your business.'
export const BUSINESS_INFORMATION = 'Business Information'
export const BUSINESS_LOCATION = 'Business Location'
export const BUSINESS_NAME = 'Business Name'
export const BUSINESS_NAME_RECORDS = 'Business Name Records'
export const BUSINESS_NAME_MAP_SEARCH = 'Business Name Map Search'
export const BUSINESS_PHONE = 'Business Phone'
export const BUSINESS_PHONE_NUMBER = 'Business Phone Number'
export const BUSINESS_PRINCIPALS = 'Business Principals'
export const BUSINESS_REGISTRATIONS = 'Business Registrations'
export const BUSINESS_RECIPIENT = 'Business Recipient'
export const BUSINESS_RECIPIENT_ENABLED = 'Business Recipient Enabled'
export const BUSINESS_STATUS = 'Business Status'
export const BUSINESS_TO_BUSINESS = 'Business to Business'
export const BUSINESS_TO_BUSINESS_VOLUME_PERCENTAGE = 'Business to Business Volume Percentage'
export const BUSINESS_TO_CONSUMER = 'Business to Consumer'
export const BUSINESS_TO_CONSUMER_VOLUME_PERCENTAGE = 'Business to Consumer Volume Percentage'
export const BUSINESS_TYPE = 'Business Type'
export const BUSINESS_TYPE_ALIAS = 'Business Type Alias'
export const BUSINESS_TAX_ID = 'Business Tax ID'
export const BUSINESS_TAX_ID_TYPE = 'Business Tax ID Type'
export const BUSINESS_TAX_ID_EIN = 'Employer Identification Number (EIN)'
export const BUSINESS_TAX_ID_BN = 'Business Number (BN)'
export const BUSINESS_TAX_ID_TAN_HELPER_TEXT = 'Enter your 9-digit Business Number (BN) or 8-digit trust account number preceded by the letter "T"'
export const BUSINESS_TAX_ID_BN_HELPER_TEXT = 'Enter your 9-digit Business Number (BN).'
export const BUSINESS_TAX_ID_EIN_HELPER_TEXT = 'Enter your 9-digit Employer Identification Number (EIN).'
export const BUSINESS_TAX_ID_PROVIDED = 'Business Tax ID Provided'
export const BUSINESS_WEBSITE = 'Business Website'
export const BUSINESS_WEBSITES = 'Business Websites'
export const BUTTON_BACKGROUND_COLOR = 'Button Background Color'
export const BUTTON_TEXT_COLOR = 'Button Text Color'
export const BUYER = 'Buyer'
export const BUYERS = 'Buyers'
export const BUYER_DETAIL = 'Buyer Detail'
export const BUYER_DETAILS = 'Buyer Details'
export const BUYER_INFO = 'Buyer Info'
export const BUYER_NAME = 'Buyer Name'
export const BUYER_NAME_TOOLTIP = 'Search a buyer with previous transactions by typing their name.'
export const BUYER_NAME_PLACEHOLDER = 'Search or create new buyer'
export const CADENCE = 'Cadence'
export const CANCEL = 'Cancel'
export const CAPTURE_CARD_PRESENT_AUTH =  'Capture Card Present Auth'
export const CANCELED = 'Canceled'
export const CANNOT_BE_A_PO_BOX_FORWARDING_OR_REGISTERED_AGENT = 'PO Box, Mail Forwarding, or Registered Agent Address are not allowed'
export const CANNOT_BE_EMPTY = 'Cannot be empty'
export const CAPTURED = 'Captured'
export const CAPTURED_STATUS = 'Captured Status'
export const CAPTURE_AUTHORIZATION = 'Capture Authorization'
export const CAPTURE_AUTHORIZATION_SUCCESS_MSG = 'Authorization was successfully captured'
export const CAPTURE_AUTHORIZATION_SUBTITLE = 'Complete the card transaction with the actual sale amount.'
export const CAPTURED_TRANSACTION = 'Captured Transaction'
export const CAPTURED_AUTHORIZATIONS = 'Captured Authorizations'
export const CAPTURE_AMOUNT = 'Capture Amount'
export const CAPTURED_AUTHORIZATIONS_TOOLTIP = ' represents the total of all authorizations that were successfully captured in the filtered time frame.'
export const CARD = 'Card'
export const CARD_AUTHORIZATION_DATA = 'Card Authorization Data'
export const CARD_AUTHORIZATION_DATA_TOOLTIP = 'Table of authorization data per card brand, card type, card issuer country, or payment channel for the filtered time frame.'
export const CARD_BRAND = 'Card Brand'
export const ALLOW_CARD_BRAND_OVERRIDES = 'Allow Card Brand Overrides?'
export const CARD_BRAND_OVERRIDES = 'Card Brand Overrides'
export const CARD_CROSS_BORDER = 'Card Cross Border'
export const CARD_LAST_4 = 'Card Last 4'
export const CARD_PAYMENT_DATA = 'Card Payment Data'
export const CARD_PAYMENT_DATA_TOOLTIP = 'Table of payment data per card brand, card type, card issuer country, or payment channel.'
export const CARD_DISPUTE_DATA = 'Card Dispute Data'
export const CARD_DISPUTE_DATA_TOOLTIP = 'Table of dispute data per card brand, card type, card issuer country, or payment channel.'
export const CARD_PRESENT_CAPTURE_LABEL = 'How would you like to capture this authorization?'
export const CAPTURE_ORIGINAL_AUTH_AMOUNT = 'Capture the original authorized amount'
export const CAPTURE_MORE_THAN_THE_AUTH_AMOUNT = 'Capture more than the authorized amount'
export const CAPTURE_LESS_THAN_THE_AUTH_AMOUNT = 'Capture less than the authorized amount'
export const CARD_NUMBER = 'Card Number'
export const CARD_NUMBER_LABEL = 'Card Number *'
export const CARD_NOT_PRESENT = 'Card-Not-Present'
export const CARD_NOT_PRESENT_PAYMENTS = 'Card-Not-Present Payments'
export const CARD_NOT_PRESENT_PAYMENTS_SUBTITLE = 'These are fixed rates set that encompass the markup, interchange, and any other associated fees the applications plan to charge.'
export const EDIT_CARD_NOT_PRESENT_PAYMENTS_SUBTITLE = 'Processing fees triggered by online card payments.'
export const CARD_REFUND_DATA = 'Card Refund Data'
export const CARD_REFUND_DATA_TOOLTIP = 'Table of refunds per card brand, card type, card issuer country, or payment channel for the filtered time frame.'
export const CARD_PRESENT = 'Card Present'
export const CARD_DASH_PRESENT = 'Card-Present'
export const CARD_PRESENT_PAYMENTS = 'Card-Present Payments'
export const CARD_PAYMENT = 'Card Payment'
export const CARD_PAYMENTS = 'Card Payments'
export const CARD_PAYMENTS_EDIT_RULES_DESCRIPTION = 'Set fee limits for the application to charge merchants for processing card payments.'
export const CARD_PAYMENTS_SUBTITLE = 'Application can charge merchants for processing card payments under these fee limits.'
export const EDIT_CARD_PRESENT_PAYMENTS_SUBTITLE = 'Processing fees triggered by in-person card payments.'
export const CARD_PRESENT_PAYMENTS_SUBTITLE = 'In-person payments will charge the same set of fees as online payments.'
export const CARD_PRESENT_PERCENTAGE = 'Card Present Percentage'
export const CARD_BRAND_TYPE_SUBTITLE = 'Card Brand/Type'
export const CARD_TYPE = 'Card Type'
export const CARD_CVV = 'Card CVV'
export const CARD_CVV_REQUIRED = 'Card CVV Required'
export const CARD_EXPIRATION_DATE = 'Card Expiration Date'
export const CARD_EXPIRATION_DATE_REQUIRED = 'Card Expiration Date Required'
export const CARD_VOLUME = 'Card Volume'
export const CASE_DETAILS = 'Case Details'
export const CASE_ID = 'Case ID'
export const CANCEL_APPLYING = 'Cancel Applying'
export const CATEGORIES = 'Categories'
export const CATEGORY = 'Category'
export const CHANGE_PASSWORD = 'Change Password'
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Your password has been successfully updated'
export const CHECKING = 'Checking'
export const CHECK_HERE_IF_YOU_DO_NOT_HAVE_THE_REQUESTED_DOCUMENTS = 'Check here if you do not have the requested documents'
export const CHANGE_TEMPLATE = 'Change Template'
export const CHEQUING = 'Chequing'
export const ALLOW_CHARGEBACK_FEES = 'Allow Chargeback Fees?'
export const CHARGEBACKS = 'Chargebacks'
export const CHARGEBACK_NOTIFICATION = 'Chargeback Notification'
export const CHARGEBACK_INQUIRY = 'Chargeback Inquiry'
export const CITY = 'City'
export const CLASSIFICATION_TITLE = 'Classification Title'
export const CLASSIFICATION = 'Classification'
export const CLEAR = 'Clear'
export const CLEAR_SELECTIONS = 'Clear Selections'
export const CLICK_TO_COPY = 'Click To Copy'
export const CLOSE = 'Close'
export const CODE = 'Code'
export const COLLECT_BILLING_ADDRESS = 'Collect Billing Address'
export const COLLECT_BUYER_EMAIL = 'Collect Buyer Email'
export const COLLECT_BUYER_PHONE_NUMBER = 'Collect Buyer Phone Number'
export const COLLECT_SHIPPING_ADDRESS = 'Collect Shipping Address'
export const COLLECT_EMAIL = 'Collect Email'
export const COLLECTION_METHOD = 'Collection Method'
export const COLLECT_RECIPIENT_EMAIL = 'Collect Recipient Email'
export const COLLECT_RECIPIENT_PHONE_NUMBER = 'Collect Recipient Phone Number'
export const COLLECTING_GOVERNMENT_ID_AND_SELFIE = 'Collecting your government ID and selfie helps us verify your identity and protects against fraud.'
export const COMPANY = 'Company'
export const COMPANY_EMAIL = 'Company Email'
export const COMPANY_NAME = 'Company Name'
export const COMPANY_TITLE = 'Company Title'
export const COMPLETED_ON = 'Completed On'
export const COMPLETED = 'Completed'
export const COMPLIANCE = 'Compliance'
export const COMPLIANCE_CHECKS = 'Compliance Checks'
export const COMPLIANCE_SCORE = 'Compliance Score'
export const COMPLIANCE_SCREENING_SCORE = 'Compliance Screening Score'
export const COMPLIANCE_SCREENING_SCORE_TOOLTIP = 'This is a weighted score that factors in any enabled screening reports for both the business and business principal(s). The score can include results from either the Watchlist Screening and/or the Adverse Media Screening reports.'
export const COMPLIANCE_SETTINGS = 'Compliance Settings'
export const COMPLIANCE_TRENDS = 'Compliance Trends'
export const CONFIGURATIONS = 'Configurations'
export const CONFIGURATION_DETAILS = 'Configuration Details'
export const CONFIRM = 'Confirm'
export const CONFIRM_PASSWORD = 'Confirm Password'
export const CONFIDENCE_SCORE = 'Confidence Score'
export const CONFIDENCE_REASONS = 'Confidence Reasons'
export const CONTACT = 'Contact'
export const CONTACT_DETAILS = 'Contact Details'
export const CONTACT_SUPPORT = 'Contact Support'
export const CONTACT_SUPPORT_MESSAGE = 'A support ticket will be generated and sent to our technical support team.'
export const CONTACT_SALES_MESSAGE = 'To process live payments, you must have an eligible business and get approved to be a Finix customer. See if Finix is the right fit for you.'
export const CONTACT_YOUR_ADMINISTRATOR = 'Contact your administrator for questions about your available permissions.'
export const CONTINUE = 'Continue'
export const CONTINUE_APPLYING = 'Continue Applying'
export const UPDATE_CONTINUOUS_MONITORING_SETTINGS = 'Update Continuous Monitoring Settings'
export const CONTINUOUS_MONITORING_SETTINGS = 'Continuous Monitoring Settings'
export const CONTINUOUS_MONITORING_SETTINGS_SUBTITLE = 'Set up the screening cadence to continuously monitor merchants.'
export const CONTROL_OWNER = 'Control Owner'
export const CONTROL_PERSON = 'Control Person'
export const CONTROL_PERSON_INFO = 'Control Person Info'
export const CONTROL_PERSON_PREFERRED = 'Control person is preferred but not required'
export const CONTROL_PERSON_INFO_SUBHEADER_ISP = 'We collect this information to verify the identity of the control person - the individual who owns the business (sole proprietor). Additional information may be requested.'
export const CONTROL_PERSON_INFO_SUBHEADER = 'We collect this information to verify the identity of the control person - the individual who has significant responsibility in managing and controlling the business. Additional information may be requested.'
export const CONTROL_PERSON_INFO_SUBHEADER_WHITE_LABEL = 'This information is collected to verify the identity of the control person - the individual who owns the business (sole proprietor) or who has significant responsibility in managing and controlling the business. Additional information may be requested.'
export const CONTROL_WHAT_BUYERS_CAN_ENTER = 'Control what buyers can enter'
export const CONSUMER = 'Consumer'
export const CONSUMER_TO_CONSUMER = 'Consumer to Consumer'
export const CONSUMER_TO_CONSUMER_VOLUME_PERCENTAGE = 'Consumer to Consumer Volume Percentage'
export const CONVENIENCE_AMOUNT = 'Convenience Amount'
export const CONVENIENCE_CHARGES = 'Convenience Charges'
export const CONVENIENCE_CHARGES_ENABLED = 'Convenience Charges Enabled'
export const CONTINUE_APPLICATION = 'Continue Application'
export const COPY_URL = 'Copy URL'
export const COPY_AND_SHARE_THE_URL_LINK = 'Copy and Share the URL Link'
export const COPY_LINK = 'Copy Link'
export const CORPORATION = 'Corporation'
export const CORPORATION_TYPE = 'Corporation Type'
export const COST_PER_UNIT  = 'Cost Per Unit'
export const COUNTRIES = 'Countries'
export const COUNTRY = 'Country'
export const COUNT_LIMIT = 'Count Limit'
export const COUNT = 'Count'
export const COUNTRY_OF_RESIDENCE = 'Country of Residence'
export const COUNTRY_HELPER_TEXT = 'The country this merchant’s business is located in.'
export const APPLICATION_COUNTRY_HELPER_TEXT = 'The Opportunity\'s business location'
export const CREATE = 'Create'
export const CREATE_REFUND_CONFIRMATION_MSG = 'By clicking "Confirm", you acknowledge that the specified amount will be refunded to the original payment method.'
export const CREATE_REFUND_MSG = 'Create a refund back to the buyer\'s original payment method.'
export const CREATE_NEW_RUN = 'Create New Run'
export const CREATE_NEW_RUN_DESCRIPTION = 'The review for this Application will run again and move back to manual review for final approval.'
export const CREATED = 'Created'
export const CREATE_PLAN = 'Create Plan'
export const CREATED_ON = 'Created On'
export const CREATE_AUTHORIZATION = 'Create Authorization'
export const CREATE_BALANCE_ADJUSTMENT_SUCCESS_MSG = 'Your balance adjustment is being processed.'
export const CREATED_VIA = 'Created Via'
export const CREATED_BY = 'Created By'
export const CREATE_REFUND = 'Create Refund'
export const CREATE_REFUND_AMOUNT_QUESTION = 'How much do you want to refund?'
export const CREATE_ROLE = 'Create Role'
export const CREATE_ACCOUNT = 'Create Account'
export const CREATE_AMOUNT_CHARGE_ALL_MERCHANTS = 'This action affects all merchants enrolled in this schedule. Are you sure you want to add this amount?'
export const CREATE_API_KEY_LABEL = 'Create API Key'
export const CREATE_CUSTOM_ROLE = 'Create Custom Role'
export const CREATE_COMPLIANCE_FORM = 'Create Compliance Form'
export const CREATE_DISPUTE = 'Create Dispute'
export const CREATE_MERCHANT_ADJUSTMENT = 'Create Merchant Adjustment'
export const CREATE_MERCHANT_FEE = 'Create Merchant Fee'
export const CREATE_FORM = 'Create Form'
export const CREATE_NEW_IDENTITY = 'Create New Identity'
export const CREATE_NEW_PAYMENT = 'Create New Payment'
export const CREATE_NEW_PAYMENT_LINK = 'Create New Payment Link'
export const CREATE_NEW_TRANSACTION = 'Create New Transaction'
export const CREATE_SUBSCRIPTION = 'Create Subscription'
export const CREATE_SUBSCRIPTION_WITHOUT_PLAN_LABEL = 'Create subscription without a plan'
export const CREATE_SUBSCRIPTION_WITHOUT_PLAN_DESCRIPTION = 'Clicking on ‘Start Subscription’ will initiate the subscription for this customer today. The first payment will be charged to the selected payment instrument in accordance with the plan rules.'
export const CREATE_SUBSCRIPTION_LEGAL_LANGUAGE = 'Before implementing any recurring, subscription, or automatic renewal payment plan you should consult with legal counsel on compliance with applicable federal, state, and network rules requiring a merchant to disclose key terms, obtain the customer’s authorization, provide simple mechanisms to cancel, and provide confirmation to the customer, among other requirements. '
export const CREATE_IDENTITY = 'Create Identity'
export const CREATE_VERIFICATION = 'Create Verification'
export const CREATE_A_WEBHOOK = 'Create a webhook'
export const CREATE_PASSWORD = 'Create password'
export const CREATE_PASSWORD_TITLE_CASE = 'Create Password'
export const CREATE_PROFILE = 'Create Profile'
export const CREATE_WEBHOOK = 'Create Webhook'
export const CREATE_USER = 'Create User'
export const CREATE_USERNAME = 'Create username'
export const CREATE_MANUAL_PAYMENT = 'Create Manual Payment'
export const CREATE_PAYMENT_LINK = 'Create Payment Link'
export const CREATE_A_BALANCE_ADJUSTMENT = 'Create a Balance Adjustment'
export const CREATE_A_BALANCE_ADJUSTMENT_SUBTITLE = 'Add funds to your account balance'
export const CREATE_A_DISPUTE = 'Create a Dispute'
export const CREATE_A_PAYMENT_LINK = 'Create a Payment Link'
export const CREATE_A_TRANSACTION = 'Create a Transaction'
export const CREATE_A_SUBSCRIPTION = 'Create a Subscription'
export const CREATE_A_PLAN = 'Create a Plan'
export const CREATE_A_SUBSCRIPTION_PLAN = 'Create a Subscription Plan'
export const CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_1 = 'Create a plan with a default recurring price and billing frequency.'
export const CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_2 = 'Plans may also include free trials.'
export const CREATE_A_SUBSCRIPTION_SUBTITLE = 'Create a custom subscription or use an existing plan to create one.'
export const CREATE_ASSOCIATED_IDENTITY = 'Create Associated Identity'
export const CREATE_UNDERWRITING_PROFILE = 'Create Underwriting Profile'
export const CREATE_NEW_SANDBOX_ACCOUNT = 'Create a Sandbox Account'
export const CREATE_NEW_CUSTOMER_ONBOARDING_FORM = 'Create New Customer Onboarding Form'
export const CUSTOMER = 'Customer'
export const CUSTOMER_NAME = 'Customer Name'
export const CUSTOMER_ONBOARDING_FORM = 'Customer Onboarding Form'
export const CUSTOMER_ONBOARDING_FORM_WELCOME_TEXT_2 = 'Any information you enter in this form will be saved once you click “Save and Continue” at the end of each step. Prior to submitting your application, any information saved is to only help you resume your application at a later time. Your information will not be used for any other purpose until you complete and submit the form.'
export const CUSTOMER_REFERENCE_NUMBER = 'Customer Reference Number'
export const CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG = 'A unique identifier for a particular customer or transaction.'
export const CUSTOMER_RESPONSE_CODE = 'Customer Response Code'
export const CUSTOMER_DETAILS = 'Customer Details'
export const CREATING_TRANSFER_ENABLED = 'Creating Transfer Enabled'
export const CREDITS = 'Credits'
export const CREDIT_CHECK = 'Credit Check'
export const CREDIT_CHECK_IP_ADDRESS = 'Credit Check IP Address'
export const CREDIT_CHECK_TIMESTAMP = 'Credit Check Timestamp'
export const CREDIT_CHECK_ALLOWED = 'Credit Check Allowed'
export const CREDIT_AND_DEBIT_CARDS = 'Credit and Debit Cards'
export const CUSTOM = 'Custom'
export const CUSTOMS_DUTY_AMOUNT = 'Customs Duty Amount'
export const CUSTOMER_PROFILE = 'Customer Profile'
export const CUSTOMER_PROFILE_TYPE = 'Customer Profile Type'
export const CUSTOMER_PROFILE_TYPE_LABEL = 'Customer Profile Type'
export const CUSTOM_BRANDING = 'Custom Branding'
export const CUSTOM_SUCCESS_RETURN_URL = 'Custom success return URL'
export const CUSTOM_FAILURE_RETURN_URL = 'Custom failure return URL'
export const CUSTOM_EXPIRED_SESSION_URL = 'Custom expired session URL'
export const CURRENCY = 'Currency'
export const CURRENT_OPERATING_HOURS = 'Current Operating Hours'
export const CURRENT_BALANCE = 'Current Balance'
export const CURRENT_STATUS = 'Current Status'
export const CURRENT_COUNT = 'Current Count'
export const CURRENT_VOLUME = 'Current Volume'
export const CUSTOM_COLORS = 'Custom Colors'
export const CUSTOMER_INFO = 'Customer Info'
export const CUSTOMER_INFORMATION = 'Customer Information'
export const CUSTOMER_ONBOARDING_FORM_REVIEW_SUBTITLE = 'A link will be generated for sharing once you create the form.'
export const CUSTOMER_ONBOARDING_FORM_SUCCESS_HEADER = 'Your customer onboarding form has been created.'
export const CUSTOMER_ONBOARDING_FORM_SUCCESS_MESSAGE = 'The link expires in 7 days and will not be available once you exit. You will need to generate a new link if the onboarding form requires additional completion time.'
export const CUSTOMER_INFORMATION_SUBTITLE = 'The customer onboarding form will pre-populate any information entered below.'
export const CUSTOMIZE_PERMISSIONS_MESSAGE = 'Customize permissions for your specific dashboard needs.'
export const CUSTOM_FIELDS = 'Custom Fields'
export const CUSTOM_FIELD_LIMIT_REACHED = 'Custom field limit reached'
export const CVV_VERIFICATION = 'CVV Verification'
export const CUSTOM_BATCH_TIME = 'Custom Batch Time'
export const CUSTOM_BATCH_TIME_SUBLABEL = 'Select the cutoff time for batch processing'
export const CVV = 'CVV'
export const CVC = 'CVC'
export const DASHBOARD = 'Dashboard'
export const DASHBOARDS = 'Dashboards'
export const DASHBOARD_ACCOUNT_NAME = 'Dashboard Account Name'
export const DASHBOARD_ACCOUNTS = 'Dashboard Accounts'
export const DASHBOARD_BRANDING = 'Dashboard Branding'
export const DASHBOARD_NAME = 'Dashboard Name'
export const GENERAL_BRANDING_OVERVIEW = 'General branding settings allow you to add a custom subdomain, your logo, and custom colors in your dashboard.'
export const DASHBOARD_FEEDBACK = 'Dashboard Feedback'
export const DASHBOARD_LOGO = 'Dashboard Logo'
export const DASHBOARD_ROLE = 'Dashboard Role'
export const DASHBOARD_TUTORIAL = 'Dashboard tutorial'
export const DASHBOARD_USER_MANAGEMENT = 'Dashboard User Management'
export const DASHBOARD_USER = 'Dashboard User'
export const DASHBOARD_SETTINGS = 'Dashboard Settings'
export const DATE = 'Date'
export const DATE_OF_BIRTH = 'Date of Birth'
export const DATE_OF_INCORPORATION = 'Date of Incorporation'
export const DATA_SOURCE = 'Data Source'
export const DATA_RESOURCES = 'Data Resources'
export const DATABASE_CHECK_RESULT = 'Database Check Result'
export const DAYS_SINCE_APPROVAL = 'Days Since Approval'
export const DBA = 'DBA'
export const DEACTIVATE_TEAM_MEMBER = 'Deactivate Team Member'
export const DEACTIVATE_TEAM_MEMBER_CONFIRMATION_LABEL = 'Yes, deactivate team member'
export const DEACTIVATE_TEAM_MEMBER_MSG = 'Are you sure you would like to deactivate this team member?'
export const DEACTIVATE = 'Deactivate'
export const REACTIVATE = 'Reactivate'
export const DEACTIVATED = 'Deactivated'
export const DEACTIVATE_PAYMENT_LINK = 'Deactivate Payment Link'
export const DEACTIVATE_PAYOUT_LINK = 'Deactivate Payout Link'
export const DEACTIVATE_PAYOUT_LINK_SUCCESS_MESSAGE = 'Payout link has been successfully deactivated.'
export const DESTINATION_POSTAL_CODE  = 'Destination Postal Code'
export const ACTIVATE_PAYMENT_LINK = 'Activate Payment Link'
export const ACTIVATE_PAYOUT_LINK = 'Activate Payout Link'
export const DEACTIVATE_PAYMENT_LINK_MODAL_TEXT = 'Deactivating a payment link will mean that your buyers will no longer be able to use it.'
export const DEACTIVATE_PAYOUT_LINK_MODAL_TEXT = 'Deactivating a payout link will mean your customers will no longer be able to use it.'
export const REACTIVATE_PAYMENT_LINK = 'Reactivate Payment Link'
export const REACTIVATE_PAYOUT_LINK = 'Reactivate Payout Link'
export const REACTIVATE_PAYMENT_LINK_SUCCESS_MESSAGE = 'Payout link has been successfully reactivated.'
export const REACTIVATE_PAYMENT_LINK_MODAL_TEXT = 'Reactivating this payment link will mean that your buyers can start using it again.'
export const REACTIVATE_PAYOUT_LINK_MODAL_TEXT = 'Reactivating this payout link will mean that your customers can start using it again.'
export const DEAL_TYPE = 'Deal Type'
export const DEAL_NAME = 'Deal Name'
export const DEAL_INFO = 'Deal Info'
export const DEAL_INFORMATION = 'Deal Information'
export const DEBITS = 'Debits'
export const DECISION = 'Decision'
export const DEFAULT = 'Default'
export const DECISION_DESCRIPTION = 'Decision Description'
export const DECISION_RULE = 'Decision Rule'
export const DEFAULT_STATEMENT_DESCRIPTOR = 'Default Statement Descriptor'
export const DEFAULT_TERMINAL_ID = 'Default Terminal ID'
export const DEFAULT_AMOUNT = 'Default Amount'
export const DEFAULT_AMOUNT_TYPE_SELECTION = 'Default Amount Type Selection'
export const DELETE = 'Delete'
export const DELETE_ITEM = 'Delete Item'
export const DELETE_AMOUNT_CHARGE_ALL_MERCHANTS = 'This action affects all merchants enrolled in this schedule. Are you sure you want to delete this amount?'
export const DELETE_CUSTOM_FIELD = 'Delete custom field'
export const DELETE_ENROLLMENTS = 'Delete Enrollments'
export const DELETE_SUBSCRIPTION = 'Delete Subscription'
export const DEPOSIT = 'Deposit'
export const DEPOSIT_STATEMENT_DESCRIPTOR = 'Deposit Statement Descriptor'
export const DEPOSIT_TYPE = 'Deposit Type'
export const DEPOSITED_ON = 'Deposited On'
export const DEPOSITED_TO_ACCOUNT = 'Deposited to Account'
export const DEPOSITS = 'Deposits'
export const DESELECT_ALL = 'Deselect all'
export const DESTINATION = 'Destination'
export const DESTINATION_INSTRUMENT_LABEL = 'Destination Instrument Label'
export const DETAILS = 'Details'
export const DEVELOPER = 'Developer'
export const DEVICE_CONFIGURATION = 'Device Configuration'
export const DEVICE_CONFIGURATION_SUBTITLE = 'Configure the details of the new device'
export const DEVICE_INFORMATION = 'Device Information'
export const DISBURSEMENTS = 'Disbursements'
export const DISPUTE_DEBITS = 'Dispute Debits'
export const DISPUTE_CREDITS = 'Dispute Credits'
export const DISABLE = 'Disable'
export const DISABLE_API_KEY = 'Disable API Key'
export const DISABLE_API_KEY_MESSAGE = 'Disabling an API Key will revoke access of that key to all Finix APIs. Once a key has been disabled it cannot be re-enabled at a later time. Please verify that the key is no longer being used in any production environments or tools before disabling the key to avoid any possible disruptions to existing services.'
export const DISABLE_USER = 'Disable User'
export const DISABLE_WEBHOOK = 'Disable Webhook'
export const DISABLE_ROLE = 'Disable Role'
export const DISABLE_ROLE_WARNING = 'This role cannot be enabled again once disabled.'
export const DISABLE_ROLE_FAILED_WARNING = 'There are currently members assigned to this role. Please reassign members before disabling role.'
export const DISABLE_ROLE_CONFIRMATION_MESSAGE = 'Are you sure you would like to disable this role?'
export const DISABLE_ROLE_REASSIGN_MEMBERS = 'Once all team members have been reassigned to a new role, this role can be disabled.'
export const DISABLE_CUSTOM_ROLE = 'Disable Custom Role'
export const DISABLE_CUSTOM_ROLE_CONFIRMATION_LABEL = 'Yes, disable custom role'
export const DISABLE_PAYMENT_INSTRUMENT = 'Disable Payment Instrument'
export const REASSIGNED_ROLE_MEMBERS_MSG = 'In order to disable a role, please ensure there are no members currently assigned to this role. To reassign any current members:'
export const DISABLE_WEBHOOK_CONFIRMATION_MSG = 'Are you sure you want to disable this webhook?'
export const DISABLE_WEBHOOK_DESCRIPTION = 'Any unaccepted events that are currently queued will no longer be available for resending.'
export const DISABLE_WEBHOOK_WITH_ACCEPTING_EVENTS_DESCRIPTION = 'Events will no longer be sent to your webhook endpoint.'
export const DISABLE_AUTOMATION = 'Disable Automation'
export const DISABLED = 'Disabled'
export const DISCOUNT = 'Discount'
export const DISCOUNT_PER_UNIT = 'Discount Per Unit'
export const DISCOUNT_PER_UNIT_TOOLTIP_MSG = 'Deducted from cost per unit.'
export const DISCOVERY_MID = 'Discovery MID'
export const DISCOVER_MID = 'Discover MID'
export const DISCOVER_ASSESSMENTS = 'Discover Assessments'
export const DISCOVER_DATA_USAGE = 'Discover Data Usage'
export const DISCOVER_NETWORK_AUTHORIZATION = 'Discover Network Authorization'
export const DISPLAY_NAME = 'Display Name'
export const DISPLAY_NAME_HELPER_TEXT = 'Your business name that will let merchants know who is onboarding them.'
export const DISPUTE = 'Dispute'
export const DISPUTES = 'Disputes'
export const DISPUTE_AMOUNT = 'Dispute Amount'
export const DISPUTE_CREATED = 'Dispute Created'
export const DISPUTE_DETAILS = 'Dispute Details'
export const DISPUTE_ID = 'Dispute ID'
export const DISPUTE_UPDATE_NOTE = 'Dispute Update Note'
export const DISPUTE_RATIO = 'Dispute Ratio'
export const DISPUTE_RATIO_TOOLTIP_ROLE_PARTNER = ' represents the volume of disputes received compared to the volume of total payments processed.'
export const DISPUTE_RATIO_TOOLTIP_ROLE_PLATFORM = ' represents the monetary volume of disputes received compared to the monetary volume of total payments processed.'
export const DISPUTE_RATE = 'Dispute Rate'
export const DISPUTE_VOLUME = 'Dispute Volume'
export const DISPUTE_VOLUME_RECEIVED = 'Dispute Volume Received'
export const DISPUTE_NOTES_EMPTY_MSG = 'No dispute notes.'
export const DO_YOU_WANT_TO_ASSIGN_THIS = 'Do you want to assign this to yourself?'
export const PROCESS_PAYMENT = 'Process Payment'
export const PROCESS_AUTHORIZATION = 'Process Authorization'
export const PROCESS_PAYMENTS_QUESTION = 'Do you plan to process payments in the US?'
export const PROVIDE_GOVERNMENT_ISSUED_ID_AND_SELFIE = 'Provide a government-issued ID and selfie'
export const DOCUMENT_TYPE = 'Document Type'
export const DOCUMENTATION = 'Documentation'
export const DOCUMENT_DETAILS = 'Document Details'
export const DOCUMENT_SIMILARITY_SCORE = 'Document Similarity Score'
export const DOES_BUSINESS_OPERATE_IN_FOLLOWING_INDUSTRIES = 'Does the business operate in the following industries?'
export const DOMAIN = 'Domain'
export const DOMAIN_MATCH_CHECK = 'Domain Match Check'
export const DOMAIN_EXISTS = 'Domain Exists'
export const DONE = 'Done'
export const DOING_BUSINESS_AS = 'Doing Business As'
export const DOMAINS = 'Domains'
export const DOWNLOAD = 'Download'
export const DOWNLOAD_LINK = 'Download Link'
export const DOWNLOAD_MERCHANT_USER_GUIDE = 'Download Merchant User Guide'
export const DOWNLOAD_USER_GUIDE = 'Download User Guide'
export const DUE = 'Due'
export const DUES_AND_ASSESSMENTS = 'Dues & Assessments'
export const DUE_IN_LESS_THAN_30_DAYS = 'Due in less than 30 days'
export const DUPLICATE_CUSTOM_ROLE = 'Duplicate Custom Role'
export const DUNS_NUMBER = 'Duns Number'
export const DYNAMIC = 'Dynamic'
export const DYNAMIC_PRICING_DESCRIPTION_PT_1 = 'Interchange + dues and assessments + card processing fees + fees for certain features'
export const DYNAMIC_PRICING_DESCRIPTION_PT_2 = 'Best for marketplaces, mid-to-large companies, and SaaS platforms with varied buyer payment patterns'
export const ECOMMERCE = 'Ecommerce'
export const ECOMMERCE_PERCENTAGE = 'Ecommerce Percentage'
export const EDIT = 'Edit'
export const EDIT_AMOUNT_CHARGE_ALL_MERCHANTS = 'This action affects all merchants enrolled in this schedule. Are you sure you want to edit this amount?'
export const EDIT_BUSINESS_INFO = 'Edit Business Info'
export const EDIT_DASHBOARD_ACCOUNT_NAME = 'Edit Dashboard Account Name'
export const EDIT_DEVICE_DETAILS = 'Edit Device Details'
export const EDIT_CUSTOM_ROLE = 'Edit Custom Role'
export const EDIT_DASHBOARD_ACCOUNT_NAME_MESSAGE = 'Edit the name of the Dashboard Account. This will only update the Account name seen in your list of accounts.'
export const EDIT_BUYER_INFORMATION = 'Edit Buyer Information'
export const EDIT_BUYER_SUCCESS_MSG = 'Buyer information was successfully updated'
export const EDIT_NAME = 'Edit Name'
export const EDIT_PAYOUT_SETTINGS = 'Edit Payout Settings'
export const EDIT_PROCESSING_INFORMATION = 'Edit Processing Information'
export const EDIT_WEBHOOK = 'Edit Webhook'
export const EDIT_FEE_PROFILE = 'Edit Fee Profile'
export const EDIT_IDENTITY = 'Edit Identity'
export const EDIT_TEAM_MEMBER = 'Edit Team Member Role'
export const EDIT_DASHBOARD_USER_NAME_SUCCESS_MESSAGE = 'Your personal info has been successfully updated'
export const EDIT_ROLE = 'Edit Role'
export const EDIT_RECIPIENT_CONTACT_DETAILS = 'Edit Recipient Contact Details'
export const EDIT_RECIPIENT = 'Edit Recipient'
export const EDIT_TEAM_MEMBER_SUBTITLE = 'You are editing the role for '
export const EDIT_UNDERWRITING_PROFILE = 'Edit Underwriting Profile'
export const EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PARTNER = 'Set the default values that will determine the transaction limits and allowed MCCs for Merchants under this Application.'
export const EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PLATFORM = 'Set the default values that Applications will inherit if they do not have a custom Underwriting Profile.'
export const EDIT_UNDERWRITING_AUTOMATION_SETTINGS = 'Edit Underwriting Automation Settings'
export const EDIT_UNDERWRITING_AUTOMATION_SETTINGS_SUBTITLE = 'Enable or disable the automation workflows for each underwriting case under this application.'
export const EDIT_UNDERWRITING_WORKFLOW_MODAL_MESSAGE = 'The automation will affect all new onboarding merchants immediately.'
export const EDIT_VISA_APPLICATION_CONFIGS = 'Edit Visa Application Configuration'
export const EDIT_MASTERCARD_APPLICATION_CONFIGS = 'Edit Mastercard Application Configuration'
export const EDIT_SUBSCRIPTION = 'Edit Subscription'
export const EDIT_A_SUBSCRIPTION = 'Edit a Subscription'
export const EDIT_A_SUBSCRIPTION_PLAN = 'Edit Subscription Plan'
export const EDIT_A_SUBSCRIPTION_SUBTITLE = 'Update the details of an existing subscription for a buyer.'
export const EDIT_A_SUBSCRIPTION_PLAN_SUBTITLE = 'Update the details of an existing subscription plan.'
export const EDIT_A_SUBSCRIPTION_WITHOUT_PLAN_LEGAL_COPY = 'Before implementing any recurring, subscription, or automatic renewal payment plan you should consult with legal counsel on compliance with applicable federal, state, and network rules requiring a merchant to disclose key terms, obtain the customer’s authorization, provide simple mechanisms to cancel, and provide confirmation to the customer, among other requirements.'
export const EIN_VERIFICATION_DOCUMENT = 'EIN Verification Document'
export const EIN_VERIFICATION_DOCUMENT_UPLOADED = 'EIN Verification Document Uploaded'
export const EMAIL = 'Email'
export const EMAIL_IS_DELIVERABLE = 'Email Is Deliverable'
export const EMAIL_IS_NEW = 'Email Is New'
export const EMAIL_CREATED = 'Email Created'
export const EMAILS = 'Emails'
export const EMAIL_DOMAIN = 'Email Domain'
export const EMAIL_DOMAIN_EXISTS = 'Email Domain Exists'
export const EMAIL_DOMAIN_IS_NEW = 'Email Domain Is New'
export const EMAIL_DOMAIN_CREATED = 'Email Domain Created'
export const EMAIL_IS_DISPOSABLE = 'Email Is Disposable'
export const EMAIL_IS_SPAM = 'Email Is Spam'
export const EMAIL_IS_SUSPICIOUS = 'Email Is Suspicious'
export const EMAIL_ADDRESS = 'Email Address'
export const EMAIL_ADDRESS_REPORT = 'Email Address Report'
export const EMAIL_ADDRESS_VERIFICATION = 'Email Address Verification'
export const EMAIL_ADDRESS_FOUND = 'Email Address Found'
export const EMAIL_ADDRESS_ADVICE = 'Email Address Advice'
export const EMAIL_BRANDING = 'Email Branding'
export const EMAIL_BRANDING_OVERVIEW = 'Email branding allows you to customize the logo and display name that appears in emails to your sub-merchants.'
export const EMAIL_TEMPLATES = 'Email Templates'
export const EMAIL_STATUS_ID = 'Email Status ID'
export const ENABLE = 'Enable'
export const ENABLE_AUTOMATION = 'Enable Automation'
export const ENABLE_IDENTITY_MERCHANTS = 'Enable Identity Merchants'
export const ENABLE_PROCESSING = 'Enable Processing'
export const ENABLE_PAYMENT_INSTRUMENT = 'Enable Payment Instrument'
export const ENABLE_SETTLEMENTS= 'Enable Settlements'
export const ENABLE_USER = 'Enable User'
export const ENABLE_WEBHOOK = 'Enable Webhook'
export const ENABLE_WEBHOOK_CONFIRMATION_MSG = 'Are you sure you want to enable this webhook?'
export const ENABLED = 'Enabled'
export const ENABLED_EVENTS = 'Enabled Events'
export const ENABLED_EVENTS_EMPTY_MSG = 'All events are enabled by default.'
export const ENABLED_EVENTS_SUBHEADER = 'The endpoint is subscribed to the following entities and types.'
export const ENABLED_GIACT_REPORTS = 'Enabled GIACT Reports'
export const ENABLED_PERSONA_REPORTS = 'Enabled Persona Reports'
export const ENABLE_LITLE_VANTIV_US_WORKFLOW_OPTION = 'Enable LITLE_V1/VANTIV_V1 (US)'
export const ENABLE_FINIX_V1_US_WORKFLOW_OPTION = 'Enable FINIX_V1 (US)'
export const ENABLE_FINIX_V1_CAN_WORKFLOW_OPTION = 'Enable FINIX_V1 (CA)'
export const ENABLE_FINIX_V1_RECIPIENT_WORKFLOW_OPTION = 'Enable Recipient Underwriting'
export const ENABLE_INDIVIDUAL_RECIPIENTS = 'Enable Individual Recipients'
export const ENABLE_BUSINESS_RECIPIENTS = 'Enable Business Recipients'
export const END_DATE = 'End Date'
export const ENDPOINT = 'Endpoint'
export const ENROLL_IN_EXISTING_SUBSCRIPTION = 'Enroll in Existing Subscription'
export const ENROLLMENT_NICKNAME = 'Enrollment Nickname'
export const ENTER_CODE_FROM_AUTHENTICATOR_APP_TO_FINISH_SETUP = 'Enter the code from your authenticator app to finish this setup.'
export const ENTER_DISPUTE_DETAILS = 'Enter Dispute Details'
export const ENTER_REFUND_AMOUNT = 'Enter Refund Amount'
export const ENTITY_ID = 'Entity ID'
export const ENTRY_ACCRUAL_END = 'Entry Accrual End'
export const ENTRY_ACCRUAL_START = 'Entry Accrual Start'
export const ENTRY_TYPE = 'Entry Type'
export const ENVIRONMENT = 'Environment'
export const ESTIMATED_TAX = 'Estimated Tax'
export const ERROR = 'Error'
export const EQUATION = 'Equation'
export const EVENTS = 'Events'
export const EVENT_DATE = 'Event Date'
export const EVENT_DETAILS = 'Event Details'
export const EVENT_ENTITY = 'Event Entity'
export const EVENT_ID = 'Event ID'
export const EVENT_NAME = 'Event Name'
export const EVENT_REQUEST_BODY = 'Event Request Body'
export const EVENT_RESPONSE_BODY = 'Event Response Body'
export const EVENT_TYPE = 'Event Type'
export const EVIDENCE = 'Evidence'
export const EVIDENCE_FILES = 'Evidence Files'
export const EXACT_HIT = 'Exact Hit'
export const EXACT_MATCH = 'Exact Match'
export const EXCEPTION_SETTLEMENT = 'Exception Settlement'
export const EXCEPTIONS = 'Exceptions'
export const EXCEPTIONS_INSIGHTS = 'Exceptions Insights'
export const EXCEPTIONS_INSIGHTS_SUBTITLE = 'Historical look at all of your transactions that were disputed by cardholders or returned.'
export const EXCEPTIONS_INSIGHTS_TOOLTIP_TITLE = 'Summary of your disputes within the filtered time frame.'
export const EXIT_APPLICATION_FORM = 'Exit Application Form'
export const EXIT_THIS_FLOW = 'Exit this flow'
export const EXIT_APPLICATION_FORM_MESSAGE = 'Are you sure you want to exit your application form?'
export const EXPORT = 'Export'
export const EXPORTED_FROM = 'Exported From'
export const EXPIRED_FORMS = 'Expired Forms'
export const EXPIRE_ON = 'Expire On'
export const EXPIRES_ON = 'Expires On'
export const CANCEL_APPLICATION_FORM_MESSAGE = 'Are you sure you want to cancel applying for a live account?'
export const CANCEL_SUBSCRIPTION = 'Cancel Subscription'
export const EXP_DATE_OR_ROUTING_NUM = 'Exp Date or Routing #'
export const EXPERIMENT = 'Experiment'
export const EXPERIMENTS = 'Experiments'
export const EXPIRATION = 'Expiration'
export const EXPIRY = 'Expiry'
export const EXPIRATION_DATE = 'Expiration Date'
export const EXPIRATION_MONTH = 'Expiration Month'
export const EXCLUDING_SALES_TAX = 'Excluding Sales Tax'
export const EXPIRATION_TIME = 'Expiration Time'
export const EXPIRATION_YEAR = 'Expiration Year'
export const EXPIRATION_URL = 'Expiration URL'
export const EXPIRATION_TIME_MINUTES = 'Expiration Time (minutes)'
export const EXPIRATION_TIME_DAYS = 'Link Expiration Time (Days)'
export const EXPIRATION_TIME_SUBTITLE = 'Enter the number of days the onboarding form link should be available for before expiring.'
export const EXPIRED = 'Expired'
export const EXPIRED_RESPONSE_DEADLINE = 'Response deadline has expired'
export const EXPIRED_SESSION_URL = 'Expired Session URL'
export const EXPIRED_URL = 'Expired URL'
export const EXPIRED_URL_HELPER_TEXT = 'A link to redirect the merchant to if the onboarding form link is expired.'
export const EXPORT_ALL = 'Export All'
export const EXPORT_ALL_WARNING = 'Export all items is disabled unless the created at filter is within 7 days'
export const EXPORT_CONFIRMATION_MSG_PT_1 = 'Once the file is ready, you\'ll get an email with a download link at'
export const EXPORT_CONFIRMATION_MSG_PT_2 = 'You can download this file from the Exports page.'
export const EXPORTING_IN_PROGRESS = 'Exporting in progress'
export const EXPORTS = 'Exports'
export const EXPORTS_TABLE_DESCRIPTION = 'A table of recently-initiated exports.'
export const EXTERNAL_LINK = 'External Link'
export const EXTERNALLY_FUNDED = 'Externally Funded'
export const EXTERNAL_REFERENCE_ID = 'External Reference ID'
export const EVIDENCE_SUBMITTED = 'Evidence Submitted'
export const FAILED = 'Failed'
export const FAILED_AUTHORIZATIONS_BY_FAILURE_CODE = 'Failed Authorizations by Failure Code'
export const FAILED_AUTHORIZATIONS_BY_FAILURE_CODE_TOOLTIP = 'Failure codes help explain why authorizations failed. You can use this to identify areas that need attention.'
export const FAILED_SALES = 'Failed Sales'
export const FAILED_SALES_TOOLTIP = ' represents the total of all unsuccessful sales transactions within the time period selected.'
export const FAILED_REFUNDS = 'Failed Refunds'
export const FAILED_REFUNDS_TOOLTIP = ' represents the total of all unsuccessful refund transactions for the filtered time frame.'
export const FAILED_TRANSACTIONS_BY_FAILURE_CODE = 'Failed Transactions by Failure Code'
export const FAILED_TRANSACTIONS_BY_FAILURE_CODE_TOOLTIP = 'Failure codes help explain why transactions failed. You can use this to identify areas that need attention.'
export const FAILURE_CODE = 'Failure Code'
export const FAILURE_DESCRIPTION = 'Failure Description'
export const FAILURE_DETAILS = 'Failure Details'
export const FALSE = 'False'
export const FALSE_STRING = 'FALSE'
export const FACEBOOK = 'Facebook'
export const FEE = 'Fee'
export const FEIN = 'FEIN'
export const FEE_AND_RISK_PROFILE = 'Fee & Risk Profile'
export const FEE_AND_RISK_PROFILES = 'Fee & Risk Profiles'
export const FEE_SCHEDULE_PREVIEW = 'Fee Schedule Preview'
export const FEE_SCHEDULE_PREVIEW_DESCRIPTION = 'When a settlement is approved, a Funding Transfer is created to deduct fees from the merchant\'s back account. When the transfer is complete, the funds will be deducted from the merchant\'s bank account.'
export const FEE_BANK_ACCOUNT_DESCRIPTION = 'The details of the merchant\'s bank account where fees will be deducted from.'
export const FEE_BANK_ACCOUNT_SUBLABEL = 'The bank account where fees are debited'
export const FEE_BANK_ACCOUNT_TITLE = 'Fee Bank Account'
export const FEE_CREATED = 'Fee Created'
export const FEE_PROFILE = 'Fee Profile'
export const FEE_PROFILE_LIMITS = 'Fee Profile Limits'
export const FEE_PROFILES = 'Fee Profiles'
export const FEE_SETTING = 'Fee Setting'
export const FEE_SCHEDULE = 'Fee Schedule'
export const FEE_SCHEDULE_DESCRIPTION = 'Customize how often and how quickly fees will be deducted from merchants.'
export const FEE_SETTLEMENT_SCHEDULE = 'Fee Settlement Schedule'
export const FEE_TRANSFER_SPEED = 'Fee Delay'
export const FEE_URL = 'Fee URL'
export const FEE_URL_HELPER_TEXT = 'A link for the merchant to view your fee structure.'
export const FEES = 'Fees'
export const FEES_AMOUNT = 'Fees Amount'
export const FEES_COLLECTED = 'Fees Collected'
export const FEE_TYPE = 'Fee Type'
export const FIELDS_TO_EXPORT = 'Fields to export'
export const FILE = 'File'
export const FILE_ID = 'File ID'
export const FILE_LINK = 'File Link'
export const FILE_NAME = 'File Name'
export const FILE_TYPE = 'File Type'
export const FILE_STATUS = 'File Status'
export const FILE_UPLOAD_INSTRUCTIONS = 'Drag and drop, or click to select and upload a file'
export const FILE_UPLOAD_SUB_INSTRUCTIONS = 'Acceptable file types are jpg/jpeg or png: max file size: 2 MB'
export const FILE_UPLOAD_PAYMENT_LINK_BRANDING_DETAILS = 'Max file size: 1MB. Accepted formats: jpg/jpeg, png'
export const FILE_UPLOAD_IDLE_SCREEN_IMAGE_DETAILS = 'Max image dimension: 300px by 300px and max file size: 300KB. Accepted formats: jpg/jpeg, png.'
export const FILES = 'Files'
export const FINAL_AMOUNT_TO_BE_CHARGED_TO_CARDHOLDER = 'Final amount to be charged to cardholder'
export const FILTERED_BY = 'Filtered by'
export const FINGERPRINT = 'Fingerprint'
export const FINGERPRINT_ID = 'Fingerprint ID'
export const FINIX = 'Finix'
export const FINIX_FEES = 'Finix Fees'
export const FINIX_FEES_DESCRIPTION = 'These are the fees that Finix charges for processing payments.'
export const FINIX_INTEGRATION = 'Finix Integration'
export const FINIX_PROCESSING_DETAILS = 'Finix Processing Details'
export const FINIX_PROCESSING_DETAILS_DESCRIPTION = 'Use the Application and Merchant IDs along with and API Key to process payments in your integration.'
export const FINIX_MERCHANT_TERMS_OF_SERVICE = 'Finix Merchant Terms of Service'
export const FINIX_PLATFORM_TERMS_OF_SERVICE = 'Finix Platform Terms of Service'
export const FINIX_COLLECTS_THIS_INFORMATION_DESCRIPTION = 'Finix collects this information to better understand and serve your business'
export const FINIX_COLLECTS_EDD_DOCUMENTATION_DESCRIPTION = 'Finix collects these for enhanced due diligence (EDD) based on MCC types.'
export const FIXED_AMOUNT = 'Fixed Amount'
export const FIXED_AMOUNT_FOR_PRODUCTS_OR_SERVICES = 'Fixed Amount (for products or services)'
export const FIXED_FEE = 'Fixed Fee'
export const FIRST_NAME = 'First Name'
export const FIRST_PAYMENT_DATE = 'First Payment Date'
export const FIRST_PAYMENT = 'First Payment'
export const FINISH_APPLYING_FOR_LIVE_ACCOUNT = 'Finish Applying for a Live Account'
export const FLAT_RATE = 'Flat Rate'
export const FLAT_RATE_DESCRIPTION_PT_1 = 'Flat-rate per card transaction + fees for certain features'
export const FLAT_RATE_DESCRIPTION_PT_2 =  'Best for smaller businesses who want to plan around a set card transaction cost'
export const FORM_BASICS = 'Form Basics'
export const FORM_CREATED_ON = 'Form Created On'
export const FORMS_COMPLETED = 'Forms Completed'
export const FORMS_IN_PROGRESS = 'Forms in progress'
export const FORM_DETAILS = 'Form Details'
export const FORM_INFO = 'Form Info'
export const FORM_ID = 'Form ID'
export const FORM_TYPE = 'Form Type'
export const FORMS_GENERATED = 'Forms Generated'
export const FORMS_DUE_IN_30_DAYS = 'Forms Due in 30 Days'
export const FORMS_DUE_IN_60_DAYS = 'Forms Due in 60 Days'
export const FRAUD = 'Fraud'
export const FRAUD_MONITORING = 'Fraud Monitoring'
export const FULL_NAME = 'Full Name'
export const FUNDING_INSTRUCTIONS = 'Funding Instructions'
export const FUNDING_STATUS = 'Funding Status'
export const FUNDING_TRANSFERS = 'Funding Transfers'
export const FREQUENCY = 'Frequency'
export const FROM = 'From'
export const FRONT_PHOTO = 'Front Photo'
export const GATEWAY = 'Gateway'
export const GENERAL_OVERVIEW = 'General Overview'
export const GENERATE_FORM = 'Generate Form'
export const GENERATED_FORMS = 'Generated Forms'
export const GENERATE_NEW_FORM_URL = 'Generate new form URL'
export const GENERATE_NEW_URL_LINK = 'Generate New URL Link'
export const GENERATE_LINK = 'Generate Link'
export const GENERAL_BRANDING = 'General Branding'
export const GENERAL_INFORMATION = 'General Information'
export const GET_PAID_INSTANTLY = 'Get Paid Instantly'
export const GITHUB = 'Github'
export const GIVE_FEEDBACK = 'Give us Feedback'
export const GIVE_FEEDBACK_MESSAGE = 'Have ideas for how we can improve? Sharing your feedback helps us make our products better. If you need assistance please contact support instead.'
export const GOOGLE_MAPS_URL = 'Google Maps URL'
export const GOOGLE_MAPS_VERIFICATION = 'Google Maps Verification'
export const GOOGLE_PLACE_DETAILS = 'Google Place Details'
export const GO_TO_ROLE_MEMBERS_TAB = 'Go to Role Members Tab'
export const GO_TO_THE_FINIX_DASHBOARD = 'Go to the Finix Dashboard'
export const GO_TO_THE_PREVIOUS_STEP = 'Go to the Previous Step'
export const GO_TO_THE_PREVIOUS_STEP_QUESTION = 'Are you sure you want to go to the previous step?'
export const GO_TO_THE_PREVIOUS_STEP_DESCRIPTION = 'You will lose your current progress if you go to the previous step without completing and saving the current step.'
export const GOVERNMENT_AGENCY = 'Government Agency'
export const HAVE_8_TO_20_CHARACTERS = 'Have 8-20 characters'
export const HAVE_12_TO_20_CHARACTERS = 'Have 12-20 characters'
export const HAVE_ONE_LOWERCASE = 'Have one lower case (a-z)'
export const HAVE_ONE_UPPERCASE = 'Have one upper case (A-Z)'
export const HAVE_ONE_NUMBER = 'Have one number (0-9)'
export const HAVE_ONE_SPECIAL_CHARACTER = 'Have one special character'
export const HAVE_QUESTIONS = 'Have Questions?'
export const HEADERS = 'Headers'
export const HEADER_COLOR = 'Header Color'
export const HEADER_COLOR_HELPER_TEXT = 'The background color for the page header of the onboarding form.'
export const HELP = 'Help'
export const HIDE_OTHER_RECEIPT_OPTIONS = 'Hide other receipt delivery options'
export const HIGH_MATCH_HIT = 'High Match Hit'
export const HISTORY = 'History'
export const HOME = 'Home'
export const HOME_ADDRESS = 'Home Address'
export const HOME_INSIGHTS_SUBTITLE = 'View your payments data within the time frames you select below.'
export const HOSTED_ONBOARDING_CONFIRMATION_MESSAGE = 'Here is a summary of your application. Please review it carefully. By submitting this application, you certify that the information you provided is correct and complete.'
export const HOSTED_ACCESS_FORM_CONFIRMATION_MESSAGE = 'You certify that all the information you provided is complete and correct and that you are authorized to complete this form on behalf of your business.'
export const HOSTED_ONBOARDING_SIGNUP_TEXT = 'The information you provide in this payment processing services application will be used for identity verification. Additional information may be requested.'
export const HOSTED_ONBOARDING_SIGNUP_TITLE = 'Sign up for a Merchant Account'
export const HOW_ARE_YOUR_TRANSACTIONS_DISTRIBUTED = 'How are your transactions distributed?'
export const HOW_MUCH_DO_YOU_WANT_TO_SEND = 'How much money do you want to send?'
export const HSA_FSA_CLINIC = 'HSA/FSA - Clinic'
export const HSA_FSA_DENTAL = 'HSA/FSA - Dental'
export const HSA_FSA_RX = 'HSA/FSA - Rx'
export const HSA_FSA_VISION = 'HSA/FSA - Vision'
export const HUBSPOT_DEAL_ID = 'Hubspot Deal ID'
export const TRANSACTION_DISTRIBUTION_SUBTITLE = 'Help us understand the distribution of your transactions based on card acceptance channel. Total percentage must add up to 100%.'
export const PAYMENT_VOLUME_DISTRIBUTION_SUBTITLE = 'Help us understand the distribution of your transactions based on who your customers are. Total percentage must add up to 100%.'
export const CUSTOMER_ONBOARDING_TRANSACTION_DISTRIBUTION_SUBTITLE = 'Enter the distribution of transactions based on the channel for card acceptance. Total percentage must add up to 100%.'
export const ID = 'ID'
export const ID_NUMBER = 'ID Number'
export const ID_PHOTOS = 'ID Photos'
export const ID_SEARCH = 'ID Search'
export const ID_TYPE = 'ID Type'
export const ID_CHECKS = 'ID Checks'
export const IDENTITY = 'Identity'
export const IDENTITY_ID = 'Identity ID'
export const IDENTITY_MATCH = 'Identity Match'
export const IDENTITY_MATCHES = 'Identity Matches'
export const IDENTITY_MATCH_SUMMARY = 'Identity Match Summary'
export const IDENTITY_NAME = 'Merchant Identity Name'
export const IDENTITY_VERIFICATION = 'Identity Verification'
export const IDENTITY_PROVISION = 'This identity does not have any merchants, please provision a merchant before inviting a user.'
export const IDENTITY_UNDERWRITING_REVIEW = 'Identity Underwriting Review'
export const IDENTITY_ROLES = 'Identity Roles'
export const IDENTITIES = 'Identities'
export const IDENTITIES_AND_MERCHANTS = 'Identities and Merchants'
export const IDENTITIES_API_REFERENCE = 'Identities API Reference'
export const IDEMPOTENCY_ID = 'Idempotency ID'
export const IF_GREATER_THAN_THRESHOLD_AMOUNT = 'If greater than threshold amount'
export const IMAGE_URL = 'Image URL'
export const INACTIVE = 'Inactive'
export const INCOMPLETE_ONBOARDING_FORMS = 'Incomplete Onboarding Forms'
export const INCORPORATION_DATE = 'Incorporation Date'
export const INCORPORATION_STATE = 'Incorporation State'
export const INCOMPLETE_PCI_COMPLIANCE_FORMS = 'Incomplete PCI Compliance Forms'
export const INDIVIDUAL = 'Individual'
export const INDIVIDUAL_BUSINESS = 'Individual Business'
export const INDIVIDUAL_RECIPIENT_ENABLED = 'Individual Recipient Enabled'
export const INDIVIDUAL_SOLE_PROPRIETORSHIP = 'Individual Sole Proprietorship'
export const INDIVIDUAL_TAX_ID_PROVIDED = 'Individual Tax ID Provided'
export const INDIVIDUAL_RECIPIENT = 'Individual Recipient'
export const INDUSTRY_CLASSIFICATIONS = 'Industry Classifications'
export const INITIATE_EXPORT = 'Initiate Export'
export const IN_PERSON_PAYMENTS = 'In-Person Payments'
export const INCLUDING_SALES_TAX = 'Including Sales Tax'
export const INSTITUTION_NUMBER = 'Institution Number'
export const INSTRUMENT = 'Instrument'
export const INSTRUMENT_CARD_TYPE = 'Instrument Card Type'
export const INSTRUMENT_FINGERPRINT = 'Instrument Fingerprint'
export const INSTRUMENT_NAME = 'Payment Instrument Name'
export const INSTRUMENT_TYPE = 'Instrument Type'
export const INSTRUMENT_UPDATE = 'Instrument Update'
export const RECIEVE_PAYOUT_INSTANLY_MSG = 'Receive payout instantly to your card'
export const INTERNAL_ONLY = 'Internal Only'
export const INTERNAL_NOTES = 'Internal Notes'
export const INTERNATIONAL_PHONE_NUMBER = 'International Phone Number'
export const INTERNATIONAL_ORGANIZATION  = 'International Organization'
export const INTERCHANGE_PLUS = 'Interchange Plus'
export const INTERCHANGE_PLUS_HELPER_TEXT = 'Set a markup rate and pass through interchange and card association fees.'
export const INTERCHANGE_PLUS_PRICING = 'Interchange Plus Pricing'
export const INTERCHANGE_PLUS_PRICING_DESCRIPTION = 'Set rates only encompass the markups and pass through interchange, and any other card associated fees.'
export const INVALIDATE_COMPLIANCE_FORM = 'Invalidate Compliance Form'
export const INVALIDATE_FORM = 'Invalidate Form'
export const INVALIDATE = 'Invalidate'
export const INVITE_USER = 'Invite User'
export const INVITE = 'Invite'
export const INSTANTLY_LINK_BANK_ACCOUNT = 'Instantly Link Bank Account'
export const INSTANT_BANK_VERIFICATION = 'Instant Bank Verification'
export const INSTANT_PAYOUTS = 'Instant Payouts'
export const IP_ADDRESS = 'IP Address'
export const ISSUER_COUNTRY = 'Issuer Country'
export const ISSUER_RECALLED = 'Issuer Recalled'
export const ISSUE_DATE = 'Issue Date'
export const ISSUING_SUBDIVISION = 'Issuing Subdivision'
export const IS_NOT_DELIVERABLE = 'Is Not Deliverable'
export const ITEM = 'Item'
export const ITEM_INFORMATION = 'Item Information'
export const ITEM_PREVIEW = 'Item Preview'
export const KEEP_ACTIVE_CONFIRMATION_LABEL = 'No, keep active'
export const KEEP_INACTIVE_CONFIRMATION_LABEL = 'No, keep inactive'
export const L2_L3_PROCESSING = 'L2/L3 Processing'
export const L2_L3_PROCESSING_SUBLABEL = 'Permits merchants to process both Level 2 and Level 3 transfers and authorizations.'
export const LEVEL_2_CREDIT_CARD_PROCESSING  = 'Level 2 Credit Card Processing'
export const LEVEL_3_CREDIT_CARD_PROCESSING = 'Level 3 Credit Card Processing'
export const LEFT_SELFIE_SIMILARITY_SCORE = 'Left Selfie Similarity Score'
export const LEFT_SELFIE = 'Left Selfie'
export const L2_CHECKBOX_TOOLTIP_MSG = 'Submitting Level 2 details does not guarantee a lower interchange rate.'
export const LABEL = 'Label'
export const LAST_7_DAYS = 'Last 7 Days'
export const LAST_30_DAYS = 'Last 30 Days'
export const LAST_3_MONTHS = 'Last 3 Months'
export const LAST_6_MONTHS = 'Last 6 Months'
export const LAST_ACTIVITY = 'Last Disbursements'
export const LAST_ACCESSED = 'Last Accessed'
export const LAST_FOUR = 'Last Four'
export const LAST_MONTH_INSIGHTS = 'Last Month\'s Insights'
export const LAST_MONTH_INSIGHTS_TOOLTIP = 'Payment data from the prior calendar month, updated on the 5th of each month.'
export const LAST_USED = 'Last Used'
export const LAST_UPDATED = 'Last Updated'
export const LAST_SAVED = 'Last Saved'
export const LEVEL = 'Level'
export const LAST_NAME = 'Last Name'
export const LAST_RUN = 'Last Run'
export const LAST_RUN_DATE = 'Last Run Date'
export const RUN_DATE = 'Run Date'
export const LEGAL_BUSINESS_NAME = 'Legal Business Name'
export const LET_US_KNOW_WHERE_YOUR_BUSINESS_IS_LOCATED = 'Let us know where your business is located.'
export const LEVEL_2_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE = 'This authorization was submitted with level 2 processing details below.'
export const LEVEL_3_AUTHORIZATION_ADDITIONAL_DATA_SECTION_SUBTITLE = 'This authorization was submitted with level 3 processing details below.'
export const LEVEL_2_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE = 'This payment was submitted with level 2 processing details below.'
export const LEVEL_3_PAYMENT_ADDITIONAL_DATA_SECTION_SUBTITLE = 'This payment was submitted with level 3 processing details below.'
export const LEVEL_2_LEVEL_3_DATA = 'Level 2 / 3 Data'
export const LEVEL_2_CREDIT_CARD_PROCESSING_CHECKBOX_LABEL = 'Attempt Level 2 credit card processing'
export const LIABILITY_ACCEPTED = 'Liability Accepted'
export const LIMITED_LIABILITY_COMPANY = 'Limited Liability Company'
export const LIMITED_LIABILITY_COMPANY_SINGLE_MEMBER = 'Limited Liability Company (Single Member)'
export const LINE_1 = 'Line 1'
export const LINE_2 = 'Line 2'
export const LINK = 'Link'
export const LINK_BANK_ACCOUNT = 'Link Bank Account'
export const LINK_EXPIRY = 'Link Expiry'
export const LINK_URL = 'Link URL'
export const LINKED_APPLICATION_MERCHANT_ID = 'Linked Application / Merchant ID'
export const LINKED_APPLICATION = 'Linked Application'
export const LINKED_ID = 'Linked ID'
export const LINKED_MERCHANT = 'Linked Merchant'
export const LINKED_NAME = 'Linked Name'
export const LINKED_TRANSACTION_AMOUNT = 'Linked Transaction Amount'
export const LINKED_TO = 'Linked To'
export const LINKED_TYPE = 'Linked Type'
export const LINKEDIN = 'LinkedIn'
export const LINK_EXPIRATION_HELPER_TEXT = 'Once the onboarding form link is generated, it will be available for the number of days entered before expiring.'
export const LINK_TYPE = 'Link Type'
export const LINK_TO_WEBSITE = 'Link to Website'
export const LINK_FOR_DEPOSITS = 'Link for deposits'
export const DEFAULT_LINK_VALIDITY = 'Default Link Validity'
export const LINK_VALIDITY = 'Link Validity'
export const PAYMENT_LINK_NAME_HELPER_TEXT = 'Optional name to help you manage payment links - not visible to buyers.'
export const LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_1 = 'We\'ve reviewed your company\'s application for a live account and need additional information before making a final decision.'
export const LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_2 = 'The Finix underwriting team will follow up with an email to the original applicant and outline the information required for additional verification.'
export const LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_NEXT_STEPS_MSG = 'Update your application with the information requested by Finix and resubmit your application for final review.'
export const LIVE_ACCOUNT_APPLICATION_APPLY_WARNING = 'You can only apply if you have an Administrator role.'
export const LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_1 = 'We\'re excited to share that your business has been approved for a live account!'
export const LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_2 = 'We\'ll follow up with an email shortly to let you know when your account setup is complete and also share a guide to help you integrate with Finix.'
export const LIVE_ACCOUNT_APPLICATION_APPROVED_NEXT_STEPS_MSG = 'Follow the guide to integrate with Finix and set up your live account to accept live payments.'
export const LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_1 = 'We\'ve finished reviewing your application and we\'re unable to approve your business for a live account.'
export const LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_2 = 'We apologize that Finix is not the right solution for you at this time and wish you luck on your payments journey.'
export const LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE = 'Please carefully review the information below and agree to the Terms of Service at the end before you submit your application.'
export const HOSTED_LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE = 'Please carefully review the information below. Confirm at the end that all information is correct before you submit your application.'
export const HOSTED_LIVE_ACCOUNT_ADDITIONAL_VERIFICATION_NEEDED_MESSAGE = 'Please update your application based on the additional information requested by Finix. Confirm at the end that all information is correct before you submit your application.'
export const LIVE_ACCOUNT_APPLICATION_VIEW_PAGE_MESSAGE = 'This application has been received and is currently under review by Finix. An email update will be sent to the original applicant within 1-2 business days.'
export const LIVE_ACCOUNT_APPLICATION_FORM = 'Live Account Application Form'
export const LIVE_ACCOUNT_APPLICATION_FORM_STARTER_MSG = 'Thanks for your interest in Finix\'s payment processing services.'
export const LIVE_ACCOUNT_APPLICATION_FORM_DESCRIPTION = 'We need to collect and verify certain information about you and your business as required by regulation and our financial partners.'
export const LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_1 = 'We\'ve withdrawn your application for a live account due to inactivity.'
export const LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_2 = 'The Finix underwriting team has not received any updates from you based on the additional information or changes we requested. Although your application has been withdrawn, you can still reapply for a live account in the future.'
export const LIVE_PREVIEW = 'Live Preview'
export const LIVE_PREVIEW_DESCRIPTION = 'See live updates of color changes in the sidebar'
export const LIVE_PREVIEW_DESCRIPTION_FOR_PAYMENT_LINKS = 'See live updates of color changes in the preview section'
export const LLC_DISREGARDED = 'LLC Disregarded'
export const LOCATIONS = 'Locations'
export const LOGO = 'Logo'
export const LOGO_URL = 'Logo URL'
export const LOGO_URL_PLACEHOLDER = 'E.g. https://www.dropbox.com/logo'
export const LOGOUT = 'Logout'
export const LOG_IN = 'Log In'
export const LOST = 'Lost'
export const MAIL_TELEPHONE_ORDER = 'Mail/Telephone Order'
export const MAIL_ORDER_TELEPHONE_ORDER_PERCENTAGE = 'Mail Order / Telephone Order Percentage'
export const MANUAL_REVIEW = 'Manual Review'
export const MANUAL_REVIEW_REASONS = 'Manual Review Reasons'
export const MANUAL_SETTLEMENT_SCHEDULE_TOOLTIP_MESSAGE = 'Settlements have to be manually closed to initiate a transfer.'
export const MANUAL_SETTLEMENT_SCHEDULE_WARNING_MESSAGE = 'Settlements have to be manually closed to generate the Funding Transfers.'
export const MARKETPLACE = 'Marketplace'
export const MASKED_NUMBER = 'Masked Number'
export const MASKED_ACCOUNT_NUMBER = 'Masked Account Number'
export const MASKED_TRANSACTION_AMOUNT = 'Masked Transaction Amount'
export const MASTERCARD_ASSESSMENTS_UNDER_1000 =  'Mastercard Assessments Under $1,000'
export const MASTERCARD_ASSESSMENTS_OVER_1000 =  'Mastercard Assessments Over $1,000'
export const MASTERCARD_ACQUIRER_FEES = 'Mastercard Acquirer Fees'
export const MATCH_LIST_SCREENING = 'MATCH List Screening'
export const MATCHING_BUSINESS_CONTACT = 'Matching Business Contact'
export const MATCHES_FOUND = 'Matches found.'
export const MATCH = 'Match'
export const MAKING_A_PAYMENT = 'Making a Payment'
export const SPLIT_TRANSFERS_API_REFERENCE = 'Split Transfer API Reference'
export const MATCH_SUMMARY = 'Match Summary'
export const MATCHES_NOT_FOUND = 'Matches not found.'
export const MAX_TRANSACTION_AMOUNT = 'Max Transaction Amount'
export const MAX_TRANSACTION_AMOUNT_FOR_ACH = 'Max Transaction Amount for ACH'
export const MAX_TRANSACTION_AMOUNT_FOR_CARDS = 'Max Transaction Amount for Cards'
export const MAX_TRANSACTION_AMOUNT_USD = 'Maximum Transaction Amount (USD)'
export const MAXIMUM_ACH_PERCENTAGE_FEE_ALLOWED = 'Maximum ACH Percentage Fee Allowed'
export const MAX_CARD_TRANSACTION_AMOUNT = 'Max Card Transaction Amount'
export const MAX_CARD_TRANSACTION_AMOUNT_HELPER_TEXT = 'Enter the max amount for card transactions this merchant should be allowed to process.'
export const MAX_CARD_TRANSACTION_AMOUNT_USD = 'Max Card Transaction Amount (USD)'
export const MAX_ACH_TRANSACTION_AMOUNT = 'Max ACH Transaction Amount'
export const MAX_ACH_TRANSACTION_AMOUNT_HELPER_TEXT = 'Enter the max amount for ACH transactions this merchant should be allowed to process.'
export const MAX_ACH_TRANSACTION_AMOUNT_USD = 'Max ACH Transaction Amount (USD)'
export const ACH_MAX_TRANSACTION_AMOUNT = 'ACH Max Transaction Amount'
export const MAX_FILES_UPLOADED = 'Max numbers of files have been uploaded'
export const MAXIMUM_ALLOWED = 'Maximum Allowed'
export const MCC = 'MCC'
export const MCC_EMPTY_MESSAGE = 'No MCCs available'
export const MCC_SELECTED_EDD_DOCUMENTATION_WARNING = 'Based on the MCC selected, additional documentation is required below'
export const MEDIAN = 'median'
export const MEDIUM_MATCH_HIT = 'Medium Match Hit'
export const MEMBERS = 'Members'
export const DEFAULT_FEE_PROFILE = 'Default Fee Profile'
export const DEFAULT_MERCHANT_FEE_PROFILE = 'Default Merchant Fee Profile'
export const MERCHANT = 'Merchant'
export const MERCHANT_BUSINESS_NAME = 'Merchant Business Name'
export const MERCHANT_NAME = 'Merchant Name'
export const MERCHANT_NAME_HELPER_TEXT = 'This will pre-fill the merchant’s business name in the onboarding form.'
export const MERCHANT_ACCOUNT = 'Merchant Account'
export const MERCHANT_ACCOUNT_LABEL = 'Merchant Account'
export const MERCHANT_ADJUSTMENTS = 'Merchant Adjustments'
export const MERCHANT_ADJUSTMENT_DEBITS = 'Merchant Adjustment Debits'
export const MERCHANT_ADJUSTMENT_CREDITS = 'Merchant Adjustment Credits'
export const MERCHANT_ACCOUNT_TOOLTIP = 'Select a merchant that your buyer will recognize for this payment.'
export const SELECT_MERCHANT_ACCOUNT_PLACEHOLDER = 'Select a merchant account'
export const MERCHANT_ACCOUNTS = 'Merchant Accounts'
export const MERCHANT_AGREEMENT_ACCEPTED = 'Merchant Agreement Accepted'
export const MERCHANT_AGREEMENT_IP_ADDRESS = 'Merchant Agreement IP Address'
export const MERCHANT_AGREEMENT_TIMESTAMP = 'Merchant Agreement Timestamp'
export const MERCHANT_COUNT = 'Merchant Count'
export const CASE_NOTES = 'Case Notes'
export const MERCHANT_DASHBOARD_USER_GUIDE = 'Merchant Dashboard User Guide'
export const MERCHANT_DASHBOARD_USER_GUIDE_DESCRIPTION = 'A PDF guide for your merchants to use the white-labeled dashboard to manage their payments.'
export const MERCHANT_DEPOSITS = 'Merchant Deposits'
export const MERCHANT_FEES_COLLECTED = 'Merchant Fees Collected'
export const MERCHANT_FEES_COLLECTED_HOMEPAGE_TOOLTIP = ' represents the total amount of fees you’ve collected from all of your merchants within the time period selected.'
export const MERCHANT_ID = 'Merchant ID'
export const MERCHANT_IDENTITY = 'Merchant Identity'
export const MERCHANT_IDENTITY_ID = 'Merchant Identity ID'
export const MERCHANT_INSIGHTS = 'Merchant Insights'
export const MERCHANT_INFORMATION = 'Merchant Information'
export const MERCHANT_IDENT_STRING = 'Merchant Ident String'
export const MERCHANT_IP_ADDRESS = 'Merchant IP Address'
export const MERCHANT_PAYOUTS = 'Merchant Payouts'
export const MERCHANT_PAYOUTS_SUBTITLE = 'Settlements batch all the transactions, refunds, and adjustment transfers that have been processed. Deposits are payouts sent to merchants.'
export const MERCHANT_PAYOUT_PLAN = 'Merchant Payout Plan'
export const MERCHANT_PAYOUT_PROFILE = 'Merchant Payout Profile'
export const MERCHANT_PROFILE = 'Merchant Profile'
export const MERCHANT_CATEGORY_CODE = 'Merchant Category Code (MCC)'
export const MERCHANT_CATEGORY_CODE_HELPER_TEXT = 'Select the MCC you intend to process transactions for.'
export const MERCHANT_ENROLLED_IN_SCHEDULE = 'This merchant is enrolled in this schedule. Are you sure you want to enroll them again?'
export const MERCHANT_ONBOARDING_FORMS_BRANDING = 'Merchant Onboarding Forms Branding'
export const MERCHANT_ONBOARDING_FORMS_PRESET = 'Merchant Onboarding Forms Preset'
export const MERCHANT_ONBOARDING_FORM_SETTINGS = 'Merchant Onboarding Form Settings'
export const MERCHANT_ONBOARDING_FORMS_BRANDING_OVERVIEW = 'Customize the onboarding forms to provide merchants with a branded experience.'
export const MERCHANT_ONBOARDING_FORMS_PRESET_OVERVIEW = 'Define the values used to pre-fill the form information step when creating new onboarding forms. You will still be able to change the values, if needed, during that step.'
export const MERCHANT_ONBOARDING_BRANDING_SB_WARNING = 'This feature is available only in our production environment at this time. Contact support if you have questions or would like to learn more.'
export const MERCHANT_PRODUCT_CODE = 'Merchant Product Code'
export const MERCHANT_PROVISIONING_STATUS = 'Merchant Provisioning Status'
export const MERCHANT_TERMS_OF_SERVICE = 'Merchant Terms of Service'
export const MERCHANT_TRENDS = 'Merchant Trends'
export const MERCHANT_TO_ADD_DEVICE_FOR_PARTNER_TOOLTIP_MSG = 'The merchant account you want to add the device for.'
export const MERCHANT_TO_ADD_DEVICE_FOR_PLATFORM_TOOLTIP_MSG = 'The merchant account associated with the application above that you want to add the device for.'
export const MERCHANT_TO_PROCESS_PAYMENT_WITH = 'The merchant account that you would like to process the payment with.'
export const MERCHANT_TO_CREATE_SUBSCRIPTION_WITH = 'The merchant account that you would like to use to create the subscription.'
export const MERCHANT_TO_CREATE_SUBSCRIPTION_PLAN_WITH = 'The merchant account that you would like to use to create the subscription plan.'
export const MERCHANT_VERIFICATIONS = 'Merchant Verifications'
export const MERCHANTS = 'Merchants'
export const MERCHANTS_APPLICATION = 'Merchant\'s Application'
export const MERCHANTS_APPLICATION_SUBTITLE = 'The application this merchant belongs to.'
export const MERCHANTS_BY_LOCATION = 'Merchants by Location'
export const MERCHANT_UNDERWRITING_INSIGHTS_SUMMARY_TOOLTIP_TITLE = 'Summary of your merchant underwriting insights within the filtered time frame. Please note that data before 03/01/2023 is not available and cannot be displayed.'
export const MERCHANT_IN_HIGH_RISK_VERTICAL_EDD_FILES_REQUIRED = 'Merchant is in a high-risk vertical. Additional EDD Documentation requires review.'
export const MESSAGE = 'Message'
export const MID = 'MID'
export const MINIMUM_8_CHARACTERS = 'Minimum 8 Characters'
export const MINIMUM_12_CHARACTERS = 'Minimum 12 Characters'
export const MINIMUM_SCORE_REQUIRED = 'Minimum Score Required'
export const MINIMUM_AMOUNT = 'Minimum Amount'
export const MIN_UPPERCASE = 'MIN'
export const MAX_UPPERCASE = 'MAX'
export const MIN_AMOUNT = 'Min Amount'
export const MAX_AMOUNT = 'Max Amount'
export const MINIMUM_ALLOWED = 'Minimum Allowed'
export const MOBILE = 'Mobile'
export const MOBILE_AUTHENTICATOR = 'Mobile Authenticator'
export const MODEL = 'Model'
export const MULTI_FACTOR_AUTHENTICATION_MFA = 'Multi-Factor Authentication (MFA)'
export const MULTI_USE_LINK = 'Multi-Use Link'
export const MULTI_USE_LINK_SETTINGS = 'Multi-Use Link Settings'
export const MULTI_USE_LINK_TOOLTIP = 'A multi-use link can be used to process multiple payments by several buyers.'
export const MUST_NOT_BE_EMPTY = 'must not be empty'
export const NA = 'N/A'
export const NAME = 'Name'
export const NAME_ON_CARD = 'Name on Card'
export const NAME_ALIASES = 'Name Aliases'
export const NEEDS_RESPONSE = 'Needs Response'
export const NAME_OF_FILE = 'Name of File'
export const NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS = 'Name of item or service shown to customers'
export const NAME_RECORDS = 'Name Records'
export const NATIONAL_IDS = 'National IDs'
export const NET_AMOUNT = 'Net Amount'
export const NET_MERCHANT_DEBIT = 'Net merchant debit'
export const NET_PROFIT_ESTIMATE = 'Net Profit Estimate'
export const NET_PROFIT_ESTIMATE_TOOLTIP_PT1 = 'This is an estimate of the net profit for your merchants based on the time range selected above.'
export const NET_PROFIT_ESTIMATE_TOOLTIP_PT2 = 'Please use the monthly Net Profit report to reference your actual residuals from Finix.'
export const NEVER = 'Never'
export const NEW_EXPERIMENT = 'New Experiment'
export const NEW_FORM_URL_GENERATED = 'New Form URL Generated'
export const NEW_PASSWORD = 'New Password'
export const NEW_SETUP = 'New Setup'
export const NEW_URL_LINK_GENERATED = 'New URL Link Generated'
export const NEW_FEE_PROFILE_APPLY_TO_MERCHANTS = 'New fee profile will only apply to new merchants'
export const NEW_FEE_PROFILE_DIFFERENT_FEES_SAME_DAY = 'New fee profile might yield different fees on the same day'
export const NEXT_STEP = 'Next Step'
export const NEXT_STEPS = 'Next Steps'
export const NEXT = 'Next'
export const NEXT_PRINCIPAL = 'Next Principal'
export const NEXT_RUN = 'Next Run'
export const NEXT_RUN_ON = 'Next Run On'
export const NEXT_PAYMENT_DATE = 'Next Payment Date'
export const NICKNAME = 'Nickname'
export const NO = 'No'
export const NO_MATCH = 'No Match'
export const NO_LINK_FOUND = 'No link found'
export const NO_BUSINESS_PRINCIPALS = 'No Business Principals'
export const NO_FILTERS_APPLIED = 'No filters applied.'
export const NO_HIT = 'No hit'
export const NO_MERCHANT_ACCOUNTS_ELIGIBLE = 'No merchant accounts eligible'
export const NO_REPORT_DETAILS_AVAILABLE = 'No report details available.'
export const NO_RECEIPT = 'No receipt'
export const NOT_COLLECTED = 'Not Collected'
export const NO_UNREAD_NOTIFICATIONS = 'No unread notifications.'
export const NONE = 'None'
export const NOTE = 'Note'
export const NOT_ALLOWED = 'Not Allowed'
export const UW_CASE_NOTE_EMPTY_MSG = 'No case note associated with this underwriting review at the moment.'
export const NOTIFICATION_SETTINGS = 'Notification Settings'
export const NOTIFICATIONS = 'Notifications'
export const NOT_PROVIDED = 'Not Provided'
export const NUMBER = 'Number'
export const OLD_PASSWORD = 'Old Password'
export const OK = 'Ok'
export const LINK_SETTINGS = 'Link Settings'
export const ONE_TIME_LINK = 'One-Time Link'
export const ONE_TIME_LINK_SETTINGS = 'One-Time Link Settings'
export const ONE_TIME_LINK_TOOLTIP = 'A one-time link can only be used to process a payment once from a single buyer.'
export const ORDER_SUMMARY = 'Order Summary'
export const OUTCOME_REASONS = 'Outcome Reasons'
export const OVERDUE = 'Overdue'
export const OVERVIEW = 'Overview'
export const OVERDUE_COMPLIANCE_FORMS = 'Overdue PCI Compliance Forms'
export const OVERALL_APPROVAL_RATE = 'Overall Approval Rate'
export const OVERALL_RATING = 'Overall Rating'
export const OVERALL_TRENDS = 'Overall Trends'
export const ONBOARDED_MERCHANTS = 'Onboarded Merchants'
export const ONBOARDING = 'Onboarding'
export const ONBOARDING_FORM = 'Onboarding Form'
export const ONBOARDING_FORM_ID = 'Onboarding Form ID'
export const ONBOARDING_FORM_MODAL_TITLE = 'Create New Merchant Onboarding Form'
export const ONBOARDING_FORM_LINK_MODAL_TITLE = 'New Merchant Onboarding Form Link'
export const ONBOARDING_FORM_MODAL_SUBTITLE = 'A link will be generated for sharing after the form is created.'
export const ONBOARDING_FORM_WELCOME_PAGE_TEXT_1 = 'To get approved for payment processing services, we need to collect and verify certain information about you and your business as required by regulation and our financial partners.'
export const ONBOARDING_FORM_WELCOME_PAGE_TEXT_2 = 'Any information you enter in this form will be saved once you click “Save and Continue” at the end of each step. Prior to submitting your application, any information saved is to only help you resume your application at a later time. Your information will not be used for any other purpose until you complete and submit the form.'
export const ONBOARDING_FORM_WELCOME_FORM_TEXT = 'Let us know where your business is located and your business type.'
export const ONBOARDING_FORM_SUCCESS_MODAL_TITLE = 'Form Successfully Generated'
export const ONBOARDING_FORM_BRANDING = 'Onboarding Form Branding'
export const ONBOARDING_FORM_COLOR_NOTE = 'Note: Button colors on hosted onboarding form inherit from the Dashboard Branding.'
export const ONBOARDING_FORM_LOGO = 'Onboarding Form Logo'
export const ONBOARDING_FORMS = 'Onboarding Forms'
export const ONBOARDING_FORMS_COMPLETION_RATE = 'Onboarding Form Completion Rate'
export const ONBOARDING_FORMS_GENERATED = 'Onboarding Forms Generated'
export const ONBOARDING_STATE_TITLE = 'Onboarding State'
export const ONE_OR_MORE_EMAILS_ARE_INCORRECT = 'One or more emails are incorrect'
export const ONE_OR_MORE_PHONE_NUMBERS_ARE_INCORRECT = 'One or more phone numbers are incorrect'
export const ONLINE_PAYMENTS = 'Online Payments'
export const ONLY_COLLECT_EMAIL = 'Only collect email'
export const ONLY_COLLECT_NAME_ADDRESS_AND_BUSINESS_DETAILS = 'Only collect name, address, and business details (if applicable)'
export const ON_THRESHOLD_AMOUNT = 'On threshold amount'
export const ON_NETWORK_RECOMMENDATION = 'On network recommendation'
export const OPTIONAL = 'Optional'
export const OPPORTUNITY_NAME = 'Opportunity Name'
export const OPTIONAL_NOTE = 'Optional Note'
export const OPTIONAL_ADD_ONS = 'Optional Add-Ons'
export const OPEN_DISPUTES = 'Open Disputes'
export const OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY = 'Optional description shown to customers when they pay'
export const ORIGINAL_PAYMENT = 'Original Payment'
export const ORIGINAL_PAYMENT_AMOUNT = 'Original Payment Amount'
export const ORIGINAL_SPLIT_PAYMENT = 'Original Split Payment'
export const ORIGINAL_AUTHORIZATION = 'Original Authorization'
export const OTHER = 'Other'
export const OTHER_ALIASES = 'Other Aliases'
export const OTHER_INFORMATION = 'Other Information'
export const OTHER_VOLUME_PERCENTAGE = 'Other Volume Percentage'
export const OVERDUE_FORMS = 'Overdue Forms'
export const OWNER = 'Owner'
export const OWNERS = 'Owners'
export const OWNER_IDENTITY = 'Owner Identity'
export const OWNER_IDENTITY_ID = 'Owner Identity ID'
export const OWNER_INFO = 'Owner Info'
export const OWNER_INFORMATION = 'Owner Information'
export const OWNER_DETAILS = 'Owner Details'
export const OWNER_NAME = 'Owner Name'
export const OWNER_EMAIL = 'Owner Email'
export const OWNER_PHONE = 'Owner Phone'
export const OWNER_TITLE = 'Owner Title'
export const OWNER_BIRTHDATE = 'Owner Birthdate'
export const OWNER_ADDRESS = 'Owner Address'
export const OWNER_TAX_ID = 'Owner Tax ID'
export const OWNER_TAX_ID_PROVIDED = 'Owner Tax ID Provided'
export const OWNERSHIP = 'Ownership'
export const OWNERSHIP_PERCENTAGE = 'Ownership Percentage'
export const OWNERSHIP_TYPE = 'Ownership Type'
export const INSTANT_BANK_ACCOUNT_VERIFICATION_MSG = 'Instantly link and verify your business checking account to let us know where your business should receive payouts. The bank account must allow deposits (credits) and withdrawals (debits).'
export const PARENT = 'Parent'
export const PARENT_PAYMENT = 'Parent Payment'
export const PARENT_REFUND = 'Parent Refund'
export const PARENT_SETTLEMENT_CLOSE = 'Parent Settlement Close'
export const PARTNERSHIP = 'Partnership'
export const PASSED = 'Passed'
export const PASSWORD = 'Password'
export const PASS_THROUGH_CARD_ASSOCIATION_FEES = 'Pass Through Card Association Fees'
export const PASS_THROUGH_DUES_ASSESSMENTS = 'Pass Through Dues & Assessments'
export const PASS_THROUGH_DUES_ASSESSMENTS_HELPER_TEXT = 'Automatically pass through dues & assessments for all card brands.'
export const PAST_RUNS = 'Past Runs'
export const PAST_RUN = 'Past Run'
export const PASSWORDS_CANNOT_MATCH = 'Passwords cannot match'
export const PAY = 'Pay'
export const PAYMENT = 'Payment'
export const PAYMENTS = 'Payments'
export const PAYMENT_ID = 'Payment ID'
export const PAYMENT_CONFIRMATION = 'Payment Confirmation'
export const PAYMENT_RESULT = 'Payment Result'
export const PAYMENT_SUCCESSFUL = 'Payment Successful!'
export const PAYMENT_INSTRUMENTS = 'Payment Instruments'
export const PAYMENT_INSTRUMENT = 'Payment Instrument'
export const PAYMENT_INSTRUMENT_ID = 'Payment Instrument ID'
export const PAYMENT_INSTRUMENT_LABEL = 'Payment instrument'
export const PAYMENT_DETAILS = 'Payment Details'
export const PAYMENT_DESCRIPTION_TOOLTIP = 'Describe the product or service and include information (Order No., etc.) to help refer to this payment later.'
export const PAYMENT_DESCRIPTION_PLACEHOLDER = 'Product or service, or reference number'
export const PAYMENT_DEVICE = 'Payment Device'
export const PAYMENT_DEVICES = 'Payment Devices'
export const PAYMENT_DEVICES_TABLE_SUBTITLE = 'Payment devices are point-of-sale terminals used for in-person transactions.'
export const PAYMENT_PROCESSING = 'Payment Processing'
export const PAYMENT_PROCESSORS = 'Payment Processors'
export const PAYMENT_PROCESSORS_GATEWAYS = 'Payment Processors/Gateways'
export const PAYMENT_PROCESSORS_SUBTITLE = 'Select the payment processor(s) and gateways to be enabled.'
export const PAYMENT_SOLUTIONS = 'Payment Solution(s)'
export const PAYMENT_SOLUTION_TITLE = 'What payment solution(s) are you looking for from Finix?'
export const PAYMENT_SOLUTION_NOTICE = 'Note: In-Person Payments require an additional step to understand your device needs. If your business is approved for a live account, we will reach out to you to complete this step.'
export const PAYMENT_TRENDS = 'Payment Trends'
export const PAYMENT_TRENDS_TOOLTIP = 'Historical view of your payment volume and payment count, per payment method and card brand, within the filtered time frame.'
export const PAYMENT_VOLUME_BY_CUSTOMER_TYPE = 'Payment Volume by Customer Type'
export const PAYMENT_VOLUME_BY_BUSINESS_TYPE = 'Payment Volume by Business Type'
export const PAYMENTS_PER_MERCHANT = 'Payments per Merchant'
export const PAYMENTS_PER_MERCHANT_OR_APPLICATION = 'Payments per Merchant or Application'
export const PAYMENTS_PER_MERCHANT_OR_APPLICATION_TOOLTIP = 'Table of payment activity per merchant or application with the ability to sort on multiple data points.'
export const PAYMENTS_PER_MERCHANT_TOOLTIP = 'Table of payment activity per merchant for the filtered time frame, with the ability to sort on multiple data points.'
export const PAYMENT_AMOUNT_CANNOT_BE_GREATER_THAN_AMOUNT_OF = 'Payment amount cannot be greater than the maximum amount of'
export const PAYMENT_AMOUNT_CANNOT_BE_LESS_THAN_MINIMUM_AMOUNT_OF = 'Payment amount cannot be less than the minimum amount of'
export const PAYMENT_ATTEMPT = 'Payment Attempt'
export const PAYMENT_ATTEMPTS = 'Payment Attempts'
export const PAYOUT_ACCOUNT = 'Payout Account'
export const PAYOUT_ACTIVITY = 'Payout Activity'
export const PAYOUT_AMOUNT = 'Payout Amount'
export const PAYOUT_ATTEMPT = 'Payout Attempt'
export const PAYOUT_ATTEMPTS = 'Payout Attempts'
export const PAYOUT_CONFIRMATION = 'Payout Confirmation'
export const PAYOUT_DELAY = 'Payout Delay'
export const PAYOUT_SCHEDULE_PREVIEW = 'Payout Schedule Preview'
export const PAYOUT_SCHEDULE_PREVIEW_DESCRIPTION = 'When a settlement is approved, a Funding Transfer is created to deliver the merchant\'s payout. When the transfer is complete, the funds will be deposited into the merchant\'s bank account.'
export const PAYOUT_SPEED = 'Payout Speed'
export const PAYOUT_SUCCESSFUL = 'Payout Successful!'
export const PAYMENT_AMOUNT = 'Payment Amount'
export const PAYMENT_AMOUNT_HELPER_TEXT = 'Including taxes, tips, fees, etc.'
export const PAYMENT_DATE = 'Payment Date'
export const PAYMENT_TYPE = 'Payment Type'
export const PAYOUT_AND_FEE_BANK_ACCOUNT_DESCRIPTION = 'The bank account where funds are deposited and fees are debited'
export const PAYOUT_BANK_ACCOUNT_DESCRIPTION = 'The details of the merchant\'s bank account where payouts will be deposited.'
export const PAYOUT_BANK_ACCOUNT_SUBLABEL = 'The bank account where funds are deposited'
export const PAYOUT_BANK_ACCOUNT_TITLE = 'Payout Bank Account'
export const PAYOUT_METHOD = 'Payout Method'
export const PAYOUT_METHODS = 'Payout Methods'
export const PAYMENT_FREQUENCY = 'Payment Frequency'
export const PAYMENT_LINK = 'Payment Link'
export const PAYMENT_LINK_TEMPLATE = 'Payment Link Template'
export const PAYMENT_LINK_TEMPLATE_SUBTITLE = 'Templates save time by pre-filling certain inputs with your selections below.'
export const PAYMENT_LINK_BRANDING = 'Payment Link Branding'
export const PAYMENT_LINK_CREATED_SUCCESSFULLY = 'Payment Link Created Successfully'
export const PAYMENT_LINKS = 'Payment Links'
export const PAYMENT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS = 'If you do not add anything here, payment links will inherit your general branding settings. You will also have the option to override these settings while creating a payment link.'
export const PAYMENT_LINKS_BRANDING_COLORS_INSTRUCTIONS = 'Set the colors that different components in your payment links should use.'
export const PAYMENT_LINKS_BRANDING_LOGO_LABEL = 'Display a full-size logo of your company on your links.'
export const PAYMENT_LINKS_BRANDING_SUBTITLE = 'Set how your payment links should look.'
export const PAYMENT_LINKS_ARE_NOT_AVAILABLE = 'Payment Links are unavailable'
export const ADDING_DEVICES_NOT_AVAILABLE = 'Adding devices is not available'
export const PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP = 'Override the Pay button background color'
export const PAYMENT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP = 'Override the Pay button text color'
export const PAYMENT_LINK_BRANDING_HEADER_TOOLTIP = 'Override the header background color at the top of the page'
export const PAYMENT_LINK_BRANDING_LOGO_TOOLTIP = 'This logo will only be used on this payment link. If left empty, your default payment links logo will be used.'
export const PAYMENT_LINK_NAME = 'Payment Link Name'
export const PAYMENT_LINK_EXPIRATION_HELPER_TEXT = 'Link will expire at 11:59:59 PM on the date selected.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US = 'By clicking Pay, you authorize us to initiate an automated clearing house (ACH) one-time debit in your name to your bank account indicated above. The amount of this transaction as noted above will be presented to your financial institution by the next business day. You further agree that once you click Pay you may not revoke this authorization or cancel this payment.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1 = 'By clicking submit, you accept this one-time pre-authorized debit (PAD) Agreement and authorize Finix Canada, Inc. to debit the specified bank account for any amount owed for charges arising from the use of services and/or purchase of products.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2 = 'Payments will be debited from the specified account within 3 days of your agreement to this PAD.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P3 = 'Where a scheduled debit date is not a business day, Finix Canada, Inc. will debit the specified account on the next business day.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4 = 'You agree that any payments due will be debited from your account immediately upon acceptance of this PAD Agreement and that confirmation of this PAD Agreement may be sent within five (5) days of acceptance of this PAD Agreement.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD = 'You further agree that your authority under this PAD Agreement is to remain in effect until we complete the one-time payment at which time your PAD Agreement will automatically terminate.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5 = 'You have certain recourse rights if any debit does not comply with this PAD Agreement. For example, you have the right to receive reimbursement for any debit that is not authorized or is not consistent with this PAD Agreement. To obtain more information on your recourse rights, contact your financial institution or visit www.payments.ca.'
export const PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6 = 'You may amend or cancel this PAD Agreement at any time by providing Finix Canada, Inc. with thirty (30) days’ advanced notice at support@finix.com. To obtain a sample cancellation form, or further information on cancelling this PAD Agreement, please contact your financial institution or visit www.payments.ca.'
export const PAYMENT_LINK_DETAILS = 'Payment Link Details'
export const PAYMENT_LINK_TYPE = 'Payment Link Type'
export const PAYMENT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG = 'The title shown to your buyer'
export const PAYMENT_METHOD = 'Payment Method'
export const PAYMENT_METHOD_PLACEHOLDER = 'Select a payment method'
export const PAYMENT_TERMINAL_DETAILS = 'Payment Terminal Details'
export const PAYMENT_METHOD_LABEL = 'Payment Method *'
export const PAYOUT = 'Payout'
export const PAYOUT_PLAN = 'Payout Plan'
export const PAYOUT_PLANS = 'Payout Plans'
export const PAYOUT_PROFILE = 'Payout Profile'
export const PAYOUT_SETTING = 'Payout Setting'
export const PAYOUT_SETTINGS = 'Payout Settings'
export const PAYOUT_SCHEDULE_LABEL = 'Payout Schedule'
export const PAYOUT_SCHEDULE_DESCRIPTION = 'Customize how often and how quickly merchants receive their payouts.'
export const PAYOUT_SETTINGS_BANK_ACCOUNT_WARNING = "By adding a new bank account, it will be used for payouts going forward. To ensure your desired payout settings are used, please complete the flow in its entirety."
export const PAYOUT_SETTINGS_DESCRIPTION = 'Details on how the settlements and transfers with payout funds get created'
export const PAYOUT_SETTLEMENT_SCHEDULE = 'Payout Settlement Schedule'
export const PAYOUT_TRANSFER_SPEED = 'Payout Transfer Speed'
export const PAYOUT_TYPE = 'Payout Type'
export const PAYOUT_INSTANTLY = 'Payout Instantly'
export const PAYOUT_TYPE_SUBHEADER = 'Select how to distribute payments and fees into the payouts sent to merchants.'
export const PAYOUT_PREFERENCE = 'Payout Preference'
export const PAYOUT_PREFERENCE_DESCRIPTION = 'Configure how and when merchants will receive payouts.'
export const PAYOUTS = 'Payouts'
export const PAYOUTS_LIMIT_REACHED = 'Payouts Limit Reached'
export const PENDING_STRING = 'Pending'
export const PENDING = 'Pending'
export const PEOPLES_TRUST_COMPANY = 'Peoples Trust Company'
export const PEOPLES_TRUST_COMPANY_ADDRESS = '95 Wellington St W Suite 1310, Toronto, ON M5J 2N7, Canada'
export const PER_CARD_PAYMENT_LOWERCASE = 'per card payment'
export const PER_NOTIFICATION_LOWERCASE = 'per notification'
export const PER_INQUIRY_LOWERCASE = 'per inquiry'
export const PER_TRANSACTION_LOWERCASE = 'per transaction'
export const PERMISSIONS = 'Permissions'
export const PERCENTAGE_FEE = 'Percentage Fee'
export const PERSON_TAX_ID = 'Person Tax ID'
export const PERSON_IDENTITY = 'Person Identity'
export const PERSON_NAME = 'Person Name'
export const PERSON_PHONE = 'Person Phone'
export const PERSON_ADDRESS = 'Person Address'
export const PERSON_TO_PERSON = 'Person to Person'
export const PERSON_TO_PERSON_VOLUME_PERCENTAGE = 'Person to Person Volume Percentage'
export const PERSONAL_ADDRESS = 'Personal Address'
export const PERSONA_REFERENCE_ID = 'Persona Reference ID'
export const PERSONA_REPORT_ID = 'Persona Report ID'
export const PERSONAL_INFO = 'Personal Info'
export const PERSONAL_NAME = 'Personal Name'
export const PERSON = 'Person'
export const PERSONAL = 'Personal'
export const PERSONAL_PHONE = 'Personal Phone'
export const PERSONAL_INFORMATION = 'Personal Information'
export const PERSONAL_IDENTITY_VERIFICATION = 'Personal Identity Verification'
export const PERSONAL_TAX_ID = 'Personal Tax ID'
export const PERSONAL_CHECKING = 'Personal Checking'
export const PERSONAL_SAVINGS = 'Personal Savings'
export const PERSONAL_RECIPIENT = 'Personal Recipient'
export const PERSONAL_TAX_ID_SSN_ITIN_HELPER_TEXT = 'Enter a Social Security Number (SSN) or an Individual Taxpayer Identification Number (ITIN). This is required for identity verification purposes.'
export const PHONE = 'Phone'
export const PHONE_NUMBER = 'Phone Number'
export const PHOTOS = 'Photos'
export const PHONE_NUMBERS = 'Phone Numbers'
export const PHONE_CALLER_NAME = 'Phone Caller Name'
export const PHONE_CALLER_TYPE = 'Phone Caller Type'
export const PHONE_CARRIER = 'Phone Carrier'
export const PHONE_LINE_TYPE = 'Phone Line Type'
export const PHONE_LINE_TYPE_CHECK = 'Phone Line Type Check'
export const PHONE_OWNERSHIP_CHECK = 'Phone Ownership Check'
export const PHONETIC_MATCH = 'Phonetic Match'
export const PIN_DEBIT = 'Pin Debit'
export const PIN_DEBIT_ADJUSTMENT_NUMBER = 'Pin Debit Adjustment Number'
export const PLAID = 'Plaid'
export const PLAN_NAME = 'Plan Name'
export const PLAN_DETAILS = 'Plan Details'
export const PLAN_DETAILS_INFO_MSG = 'Every subscription under this plan will require a saved payment instrument which will be charged automatically at the selected billing frequency'
export const PLAN_DETAILS_EMPTY_MESSAGE = 'No plan was used to create this subscription'
export const PLATFORM = 'Platform'
export const PLATFORM_ADJUSTMENTS = 'Platform Adjustments'
export const PLATFORM_FUNDING_TRANSFER = 'Platform Funding Transfer'
export const PLATFORM_PAYOUT_PLAN = 'Platform Payout Plan'
export const PLATFORM_TERMS_OF_SERVICE = 'Platform Terms of Service'
export const PLEASE_ENTER_A_VALID_ADDRESS = 'Please enter a valid address'
export const PLEASE_ENABLE_AT_LEAST_ONE_PRICING_STRATEGY = 'Please enable at least one pricing strategy.'
export const PLEASE_UPLOAD_FILE = 'Please upload a file for'
export const PLEASE_COMPLETE_ALL_FIELDS = 'Please complete all fields.'
export const PLEASE_COMPLETE_STEP_MESSAGE = 'Please complete this entire step to save and continue.'
export const PLEASE_RESOLVE_ALL_ERRORS_MESSAGE = 'Please resolve all errors before continuing to the next step.'
export const PLEASE_REVIEW_AND_CLICK_SEND_MESSAGE = 'Please review the information below and then click Send.'
export const PLEASE_SHARE_INFORMATION_WITH_RECIPIENT_MESSAGE = 'Please share the information below with the recipient before proceeding'
export const PLEASE_CONFIRM_PASSWORD_BEFORE_PROCEEDING = 'Please confirm your password once again before proceeding.'
export const PLEASE_ENTER_MOBILE_NUMBER_TO_RECEIVE_TEXT = 'Please enter the mobile number you would like to receive the text on.'
export const PLEASE_FOLLOW_STEPS_BELOW_TO_SET_UP_MFA = 'Please follow the steps below to set up multi-factor authentication.'
export const PLEASE_ENTER_PROPER_DATE_FORMAT = 'Please enter a date in the format MM/YYYY.'
export const PLEASE_COMPLETE_PCI_ATTESTATION = 'Please complete your PCI Attestation requirement.'
export const PLEASE_SET_YOUR_VELOCITY_LIMITS = 'Please set your velocity limits.'
export const PLEASE_ENTER_THE_AUTHENTICATION_CODE_SENT_TO_YOUR_MOBILE_NUMBER = 'Please enter the authentication code sent to your mobile number.'
export const POST_PAYMENT_ACTIVITY = 'Post-Payment Disbursements'
export const POSSIBLE_MERCHANT_MATCHES = 'Possible Merchant Matches'
export const POSTAL_CODE = 'Postal Code'
export const POSTED_ON = 'Posted On'
export const JOB_TITLE = 'Job Title'
export const POTENTIAL_MATCH_LEVEL = 'Potential Match Level'
export const POTENTIAL_MATCH_DETAILS = 'Potential Match Details'
export const POTENTIAL_MATCH_AKA = 'Potential Match AKA'
export const PREVIEW = 'Preview'
export const PREVIOUSLY_ACCEPTED_CREDIT_CARDS = 'Previously Accepted Credit Cards'
export const PREVIOUSLY_PROCESSED_CREDIT_CARDS_LABEL = 'Have you processed credit cards before?'
export const PREVIOUS_REFUND = 'Previous Refund'
export const PREVIOUS_REFUND_AMOUNT = 'Previous Refund Amount'
export const PREVIOUS_NOTES = 'Previous Notes'
export const PRICING_INFO = 'Pricing Info'
export const PRICING_INFORMATION = 'Pricing Information'
export const PRICING_TYPE = 'Pricing Type'
export const PRICING_STRATEGY = 'Pricing Strategy'
export const PRICING_STRATEGY_DESCRIPTION = 'Please enable at least 1 pricing strategy to set fee limits for '
export const PRICING_INFO_DESCRIPTION = 'View the details of each pricing model to understand the fees for transactions and features.'
export const PROCEED = 'Proceed'
export const STANDALONE_MERCHANT_PRICING_INFO_TITLE = 'Individual Business Pricing'
export const STANDALONE_MERCHANT_PRICING_INFO_DESCRIPTION = 'View the details of the pricing model to understand the fees for transactions and features.'
export const STANDALONE_MERCHANT_PRICING_NOTICE = 'Note: Subscription pricing is determined by processing volume. If certain volume thresholds are exceeded, pricing will increase upon notice to you.'
export const PRICING_NOTICE = 'Note: Your pricing selection is subject to Finix\'s approval. We determine your eligibility based on several factors, including the type of transactions you process and your industry.'
export const PREVIOUS_STEP = 'Previous Step'
export const PREVIOUS = 'Previous'
export const PREVIOUS_PRINCIPAL = 'Previous Principal'
export const PRIMARY_COLOR = 'Primary Color'
export const PRIMARY_MERCHANT = 'Primary Merchant'
export const PRINCIPAL = 'Principal'
export const PRINCIPAL_NAME = 'Principal Name'
export const PRINCIPAL_OWNERSHIP = 'Principal Ownership'
export const PRINT_CONFIRMATION = 'Print Confirmation'
export const PRIVACY_POLICY = 'Privacy Policy'
export const PRIVATE = 'Private'
export const PRIVATE_CORPORATION = 'Corporation (Private)'
export const PROCESSED_CARDS_BEFORE = 'Processed Cards Before'
export const PROCESSING = 'Processing'
export const PROCESSING_DETAILS = 'Processing Details'
export const PROCESSING_INFO = 'Processing Info'
export const PROCESSING_INFORMATION = 'Processing Information'
export const PROCESSING_INFO_SUBTITLE = 'Finix collects this information to better understand and serve your business.'
export const PROCESSING_DETAILS_DESCRIPTION = 'Use this merchant account along with an API Key to process payments in your integration.'
export const PROCESSING_ENABLED = 'Processing Enabled'
export const PROCESSOR = 'Processor'
export const PROCESSOR_MID = 'Processor MID'
export const PROCESSORS = 'Processors'
export const PROCESSOR_DETAILS = 'Processor Details'
export const PROCESSOR_GATEWAY = 'Processor Gateway'
export const PROCESS_LIVE_PAYMENTS_ONLINE = 'Process Live Payments Online'
export const PROCESS_LIVE_PAYMENTS_ONLINE_DESCRIPTION = 'Apply for a live account if you\'re ready to start processing payments with Finix. We\'ll save your progress so you can finish any time.'
export const PRODUCT_DESCRIPTION = 'Product Description'
export const PRODUCT_OR_SERVICE_NAME = 'Product or Service Name'
export const PRODUCT_OR_SERVICE_DETAILS = 'Product or Service Details'
export const PROFILE = 'Profile'
export const PROFITABLE_MERCHANTS = 'Profitable Merchants'
export const PROVISION_MERCHANT_PI_WARNING_MSG = 'Please add payment instruments before enabling merchants'
export const PUBLIC = 'Public'
export const PUBLIC_CORPORATION = 'Corporation (Public)'
export const PURCHASE_DETAILS = 'Purchase Details'
export const PURCHASE = 'Purchase'
export const PURCHASES = 'Purchases'
export const QUANTITY = 'Quantity'
export const QUARTER_TO_DATE = 'Quarter to date'
export const SET_ACH_VELOCITY_LIMITS = 'Set ACH Velocity Limits'
export const SET_APPLICATION_CARD_LIMITS = 'Set Application Card Limits'
export const SET_APPLICATION_VELOCITY_LIMITS = 'Set Application Velocity Limits'
export const SET_RECIPIENT_VELOCITY_LIMITS = 'Set Recipient Velocity Limits'
export const RECIPIENT_VALIDATION_MESSAGE = 'Please note that each new recipient will need to be approved before receiving payouts. This may take up to 24 hours if additional verification is needed.'
export const SET_CARD_VELOCITY_LIMITS = 'Set Card Velocity Limits'
export const SET_CUSTOMER_PROFILE_TYPE = 'Set Customer Profile Type'
export const SET_DEFAULT_LIMITS = 'Set Default Limits'
export const SET_IDLE_MESSAGE = 'Set Idle Message'
export const SET_IDLE_MESSAGE_DESCRIPTION = 'The idle message will be displayed on the dRuevice screen when it is not in use.'
export const SET_IDLE_IMAGE_SUCCESS_MSG = 'Idle image was successfully updated.'
export const SET_LIMITS = 'Set Limits'
export const SET_VELOCITY_LIMITS = 'Set Velocity Limits'
export const EDIT_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS = 'Edit Velocity Limits on Balance Adjustments'
export const SET_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS = 'Set Velocity Limits on Balance Adjustments'
export const SHOW_OTHER_RECEIPT_OPTIONS = 'Show other receipt options'
export const SOFTWARE_PLATFORM = 'Software Platform'
export const SOURCE = 'Source'
export const STANDARD_PLAN = 'Standard Plan'
export const STANDARD_BATCH_TIME = 'Standard Batch Time'
export const STANDARD_BATCH_TIME_SUBLABEL = 'Processes at 9:00 PM PT'
export const SUBSCRIPTION_PLANS_EMPTY_MSG = 'No subscription plans available.'
export const SUBSCRIPTION_NAME_PLACEHOLDER = 'Custom name for this subscription'
export const SWITCH_CUSTOMER_PROFILE_TYPE = 'Switch Customer Profile Type'
export const SWITCH_DASHBOARD_TYPE = 'Switch Dashboard Type'
export const SWITCH_CUSTOMER_PROFILE_TYPE_SUCCESS_CUSTOM_MESSAGE = 'Customer Profile Type was successfully updated.'
export const RAIL = 'Rail'
export const RATING = 'Rating'
export const RAW_RESPONSE = 'Raw Response'
export const READY_TO_SETTLE = 'Ready to Settle'
export const READY_TO_SETTLE_LABEL = 'Ready to Settle at'
export const FEE_READY_TO_SETTLE_LABEL = 'Fee Ready to Settle at'
export const REASSIGN = 'Reassign'
export const REASON = 'Reason'
export const REASON_CODE = 'Reason Code'
export const REASON_DESCRIPTION = 'Reason Description'
export const RESOURCE_TYPE = 'Resource Type'
export const RESOURCE_ID = 'Resource ID'
export const RECOMMENDED = 'Recommended'
export const RECOMMENDED_ACTION = 'Recommended Action'
export const RECEIPT = 'Receipt'
export const RECEIVE_A_CODE_VIA_TEXT_MESSAGE = 'Receive a code via text message.'
export const RECIPIENT_IDENTITY = 'Recipient Identity'
export const RECIPIENT_IDENTITY_ID = 'Recipient Identity ID'
export const RECIPIENT_CARD_VELOCITY_LIMITS = 'Recipient Card Velocity Limits'
export const RECIPIENT_ACH_VELOCITY_LIMITS = 'Recipient ACH Velocity Limits'
export const RECIPIENT_INFORMATION = 'Recipient Information'
export const RECIPIENT_NAME = 'Recipient Name'
export const RECIPIENT_NAME_TOOLTIP_MSG = 'Optional. Selecting a recipient will let them use stored payment methods.'
export const RECIPIENT_TYPE = 'Recipient Type'
export const RECIPIENT_IDENTITY_UNDERWRITING_REVIEW = 'Recipient Identity Underwriting Review'
export const RECIPIENT_PAYMENT_INSTRUMENT = 'Recipient Payment Instrument'
export const REFUND = 'Refund'
export const REFUNDS = 'Refunds'
export const REFUND_AMOUNT = 'Refund Amount'
export const REFUND_DETAILS = 'Refund Details'
export const REFUND_ID = 'Refund ID'
export const REFUND_RECIPIENT = 'Refund Recipient'
export const REFUND_TO = 'Refund To'
export const REFUND_INSIGHTS_TOOLTIP_TITLE = 'Summary of refunds within the filtered time frame.'
export const REFUND_POLICY = 'Refund Policy'
export const REFUND_TRENDS = 'Refund Trends'
export const REFUND_TYPE = 'Refund Type'
export const REFUNDS_PER_MERCHANT_OR_APPLICATION = 'Refunds per Merchant or Application'
export const REFUNDS_PER_MERCHANT_OR_APPLICATION_TOOLTIP = 'Table of refund data per merchant or application for the filtered time frame, with the ability to sort on multiple data points.'
export const REFUNDS_PER_MERCHANT = 'Refund per Merchant'
export const REFUNDS_PER_MERCHANT_TOOLTIP = 'Table of refund data per merchant for the filtered time frame, with the ability to sort on multiple data points.'
export const REFUND_PER_MERCHANT_TOOLTIP = 'Table of refund data per merchant.'
export const REGION = 'Region'
export const REGISTRATION_TYPE = 'Registration Type'
export const REGISTRATIONS = 'Registrations'
export const REINSTATE_TEAM_MEMBER = 'Reinstate Team Member'
export const REINSTATE_TEAM_MEMBER_CONFIRMATION_LABEL = 'Yes, reinstate team member'
export const REINSTATE_TEAM_MEMBER_MSG = 'Are you sure you would like to reinstate this team member?'
export const REJECT = 'Reject'
export const REJECTION_DETAILS = 'Rejection Details'
export const REJECTED = 'Rejected'
export const REJECT_CODE = 'Reject Code'
export const REJECT_DESCRIPTION = 'Reject Description'
export const REJECT_INFORMATION = 'Reject Information'
export const REJECT_REASONS = 'Reject Reasons'
export const REJECT_MERCHANT = 'Reject Merchant'
export const REJECTED_MERCHANTS = 'Rejected Merchants'
export const REJECT_APPLICANT = 'Reject Applicant'
export const RELATED_FILES = 'Related Files'
export const REMEDIATION = 'Remediation'
export const REMOVE = 'Remove'
export const REMOVE_DOMAIN = 'Remove Domain'
export const REMOVE_ONE_ENTRY = 'Remove 1 Entry'
export const REMOVE_FROM_SETTLEMENT = 'Remove from Settlement'
export const REMOVE_FROM_SETTLEMENT_WARNING = 'Removed entries cannot be added back to the original settlement.'
export const REMOVE_FROM_SETTLEMENT_DESCRIPTION = 'Remove the following entries from the settlement'
export const REMOVE_PRINCIPAL = 'Remove Principal'
export const REMOVE_FILE = 'Remove File'
export const RENT_SURCHARGE_AMOUNT = 'Rent Surcharge Amount'
export const RENT_SURCHARGES = 'Rent Surcharges'
export const RENT_SURCHARGES_ENABLED = 'Rent Surcharges Enabled'
export const REPORT_NAME = 'Report Name'
export const REPRESENTMENT_AMOUNT = 'Representment Amount'
export const REPORT = 'Report'
export const REPORT_FAILED_TO_RUN = 'Report Failed to Run'
export const REQUEST_ADDITIONAL_VERIFICATION = 'Request Additional Verification'
export const REQUEST_DATE = 'Request Date'
export const REQUEST_INFORMATION_FOR_ADDITIONAL_VERIFICATION = 'Request Information for Additional Verification'
export const REQUIRED = 'Required'
export const RESEND = 'Resend'
export const RESEND_TRANSFER_LABEL = 'Re-send Transfer'
export const RESEND_UNACCEPTED_EVENTS = 'Resend Unaccepted Events'
export const RESEND_UNACCEPTED_EVENTS_DESCRIPTION = 'All unaccepted events that are currently queued will be sent to the webhook. If the webhook encounters an issue again, you will be notified.'
export const RESEND_UNACCEPTED_EVENTS_ERROR_MSG = 'There was an error resending unaccepted events.'
export const RESEND_UNACCEPTED_EVENTS_SUCCESS_MSG = 'Unaccepted events have been resent to the webhook.'
export const RESET_ALL = 'Reset All'
export const RESET = 'Reset'
export const RETURNED_ON = 'Returned On'
export const RESEND_DASHBOARD_INVITE_EMAIL = 'Resend Dashboard Invite Email'
export const RESEND_DASHBOARD_INVITE_EMAIL_INSTRUCTIONS = 'Type in the email address of the team member you would like to resend the dashboard invite email to:'
export const RESOLVE = 'Resolve'
export const RESPOND_BY = 'Respond By'
export const RESPOND_WITHIN = 'Respond Within'
export const RESPONSE_STATE = 'Response State'
export const RESPONSE_TO_ISSUER = 'Response to Issuer'
export const RESULT = 'Result'
export const RETURN_HOME = 'Return Home'
export const RETURNS = 'Returns'
export const RETURN_DETAILS = 'Return Details'
export const RETURN_URL = 'Return URL'
export const RETURN_URL_HELPER_TEXT = 'A link to redirect the merchant to after they submit the form.'
export const RETURN_TO_EDITING = 'Return to Editing'
export const REVIEW_AND_SUBMIT = 'Review and Submit'
export const REVIEW_AND_SUBMIT_CONFIRMATION_MESSAGE = 'Please carefully review the information below and agree to the terms before you submit your application. Any incorrect information may delay the onboarding approval process.'
export const REVIEW_AMPERSAND_SUBMIT = 'Review & Submit'
export const REVIEW = 'Review'
export const REVIEW_DEVICE_DETAILS = 'Review Device Details'
export const REVIEW_ID = 'Review ID'
export const REVIEW_PAYMENT = 'Review Payment'
export const REVIEW_AUTHORIZATION = 'Review Authorization'
export const REVIEW_TRANSACTION = 'Review Transaction'
export const REVIEW_PAYMENT_STATEMENT_DESCRIPTOR_TOOLTIP = 'This will show up on your buyer\'s bank statement.'
export const REVIEW_STATE = 'Review State'
export const REVIEW_STATUS = 'Review Status'
export const REVIEWS = 'Reviews'
export const REVIEW_CADENCE = 'Review Cadence'
export const REVIEW_CREATED_ON = 'Review Created On'
export const REVIEW_TYPE = 'Review Type'
export const REVIEW_DEFAULT_FEE_PROFILE = 'Review Default Fee Profile'
export const REVIEW_DEFAULT_MERCHANT_FEE_PROFILE = 'Review Default Merchant Fee Profile'
export const RISK_PROFILE = 'Risk Profile'
export const RIGHT_SELFIE = 'Right Selfie'
export const RIGHT_SELFIE_SIMILARITY_SCORE = 'Right Selfie Similarity Score'
export const ROLE = 'Role'
export const ROLE_NAME = 'Role Name'
export const ROLE_DESCRIPTION = 'Role Description'
export const ROLE_PERMISSIONS = 'Role Permissions'
export const ROLE_PERMISSIONS_SUBLABEL = 'Select the permissions this role will be able to access'
export const ROLES = 'Roles'
export const ROLE_SUBLABEL = 'A role defines what someone has access to within the dashboard. Expand a role to view specific permissions for that role.'
export const ROLE_DETAILS = 'Role Details'
export const ROLE_MEMBERS = 'Role Members'
export const ROUTING_NUMBER = 'Routing Number'
export const RULE_DECISION = 'Rule Decision'
export const RULE_DESCRIPTION = 'Rule Description'
export const RULE_OUTCOME = 'Rule Outcome'
export const RUN_ID = 'Run ID'
export const SALESFORCE_OPPORTUNITY_RECORD_ID = 'Salesforce Opportunity Record ID'
export const SFDC_OPPORTUNITY_RECORD_ID = 'SFDC Opportunity Record ID'
export const SALES_PROSPECT = 'Sales Prospect'
export const SALES_TAX_AMOUNT = 'Sales Tax Amount'
export const SALES_TAX_AMOUNT_TOOLTIP_MSG = 'For non-taxable transactions enter $0.00.'
export const SALE = 'Sale'
export const SALESFORCE_OPPORTUNITY_RECORD_ID_HELPER_TEXT = 'The Opportunity will be updated with the customer onboarding form data upon completion.'
export const SALESFORCE_OPPORTUNITY_RECORD_ID_TOOLTIP = 'The Opportunity Record ID is available in the Opportunity’s Details tab within Salesforce.'
export const SANCTIONS = 'Sanctions'
export const SANCTIONS_SCREENING = 'Sanctions Screening'
export const SANCTIONS_SCREENING_CADENCE = 'Sanctions Screening Cadence'
export const SAVE = 'Save'
export const SAVE_AND_CONTINUE = 'Save and Continue'
export const SAVE_CHANGES = 'Save Changes'
export const SAVING_IN_PROGRESS = 'Saving in progress'
export const SCHEDULE = 'Schedule'
export const SCHEDULE_AMOUNTS = 'Schedule Amounts'
export const SCHEDULE_AUTO_CLOSE_TIME = 'Schedule Auto Close Time'
export const SCHEDULE_NICKNAME = 'Schedule Nickname'
export const SCHEDULE_PREVIEW_DESCRIPTION = 'Estimates for when funding can be expected'
export const SCHEDULE_PREVIEW_TITLE = 'Schedule Preview'
export const SCHEDULE_TYPE = 'Schedule Type'
export const SCHEDULED_REPORTS = 'Scheduled Reports'
export const SHOW_DASHBOARD_BRANDING_TO_MERCHANTS = 'Show Dashboard Branding to Merchants'
export const SHOW_DASHBOARD_BRANDING_SUB_DESCRIPTION = 'Allow merchants to see the dashboard branding configured by you'
export const SHOW_LESS = 'Show Less'
export const SHOW_MORE = 'Show More'
export const SHOW_MORE_LABEL = 'Show more'
export const SHOW_LESS_LABEL = 'Show less'
export const SEARCH = 'Search'
export const SEARCH_BY_APPLICATION_NAME_OR_ID = 'Search by Application Name or ID'
export const SECURITY = 'Security'
export const SELECT = 'Select'
export const SELECT_ALL = 'Select All'
export const SELECT_APPLICATION_FIELD_PLACEHOLDER = 'Select an application'
export const SELECT_ONE = 'Select one'
export const SELECT_A_PAYMENT_INSTRUMENT = 'Select a payment instrument'
export const SELECT_A_CARD = 'Select a card'
export const SELECT_A_CUSTOMER_LABEL = 'Select a customer'
export const SELECT_REASONS_FOR_MORE_INFO = 'Select the reasons you are requesting for more information.'
export const SELECT_A_MERCHANT_TITLE = 'Please select a merchant account'
export const SELECT_AN_APPLICATION_AND_MERCHANT_TITLE = 'Please select an application and merchant account'
export const SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR = 'Select the application and associated merchant account you want to add a device for.'
export const SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR = 'Select the merchant account you want to add a device for.'
export const SELECT_A_MERCHANT = 'Please select a merchant account'
export const SELECT_STATE = 'Select State'
export const SELECT_COUNTRY = 'Select Country'
export const SELECT_WHICH_PAYMENT_METHODS_STRING = 'Select which payment methods should be allowed by default'
export const SELECT_THE_MERCHANT_ACCOUNT_TO_PROCESS_PAYMENT_FOR = 'Select the merchant account you want to process the payment for.'
export const SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_FOR = 'Select the merchant account you want to create a subscription for.'
export const SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_PLAN_FOR = 'Select the merchant account you want to create a subscription plan for.'
export const SELF_SERVICE_DASH = 'Self-Service'
export const CENTER_SELFIE = 'Center Selfie'
export const SEND = 'Send'
export const SEND_A_COPY_OF_RECEIPT_TO_SELF = 'Send a copy of the receipt to yourself'
export const SEND_EMAIL = 'Send Email'
export const SEND_FEEDBACK = 'Send Feedback'
export const SEND_MONEY = 'Send Money'
export const SEND_PAYOUT = 'Send Payout'
export const SEND_PAYOUTS = 'Send Payouts'
export const SEND_PAYMENT_TO = 'Send Payment To'
export const SEND_PAYOUT_OPTIONS_SUBTITLE = 'Send money to a recipient directly or send them a link to enter their details.'
export const SEND_ANOTHER_PAYOUT = 'Send Another Payout'
export const SEND_RECEIPT_TO_BUYER_ON_SUCCESS = 'Send a receipt to the buyer upon success'
export const SEND_TO_BUYER = 'Send to buyer'
export const SEND_TO_USER = 'Send to user'
export const SETTINGS = 'Settings'
export const SETTLED_EXTERNALLY = 'Settled Externally'
export const SETTLEMENT_CLOSED_ON = 'Settlement Closed On'
export const SETTLEMENT_ENTRIES = 'Settlement Entries'
export const SETTLEMENT_ENTRY = 'Settlement Entry'
export const SETTLEMENT_STATE = 'Settlement State'
export const SETTLEMENT = 'Settlement'
export const SETTLEMENTS = 'Settlements'
export const SETTLEMENT_ID = 'Settlement ID'
export const SETTLEMENT_AMOUNT = 'Settlement Amount'
export const SETTLEMENTS_ENABLED = 'Settlements Enabled'
export const SETTLEMENT_SCHEDULE = 'Settlement Schedule'
export const SETTLEMENT_APPROVED_ON = 'Settlement Approved on'
export const SETTLEMENT_BATCH_TIME = 'Settlement Batch Time'
export const SELECT_BANK_TRANSFER_SPEED_LABEL = 'Select Bank Transfer Speed'
export const SELECT_BATCH_TIME_CUTOFF = 'Select the cutoff time for batch transaction processing in a 24-hour period.'
export const SETTLEMENT_BATCH_TIME_SUBTITLE = 'Details of the cutoff time for processing financial transactions in a 24-hour period.'
export const SETUP = 'Setup'
export const SETUPS = 'Setups'
export const SETUP_MULTI_FACTOR_AUTHENTICATION = 'Set Up Multi-Factor Authentication'
export const SET_UP_NEW_ACCOUNT_AUTHENTICATOR_APP_AND_SCAN_BARCODE = 'Set up a new account in your authenticator app and scan the following barcode.'
export const SET_NEW_BEARER_TOKEN = 'Set new bearer token'
export const SET_NEW_CREDENTIALS = 'Set new credentials'
export const SEX = 'Sex'
export const SELECT_A_PLAN = 'Select a plan'
export const SHIP_FROM_POSTAL_CODE = 'Ship From Postal Code'
export const SHIPPING = 'Shipping'
export const SHIPPING_ADDRESS = 'Shipping Address'
export const SHIPPING_AMOUNT = 'Shipping Amount'
export const SHIPPING_AMOUNT_TOOLTIP_MSG = 'Total shipping cost for this order'
export const SHIPPING_INFORMATION = 'Shipping Information'
export const SHIPPING_DETAILS = 'Shipping Details'
export const SHOULD_FUND = 'Should Fund'
export const SHOULD_NOT_FUND = 'Should Not Fund'
export const SIDEBAR_BACKGROUND = 'Sidebar Background'
export const SIDEBAR_TEXT = 'Sidebar Text'
export const SIDEBAR_TEXT_HIGHLIGHT= 'Sidebar Text Highlight'
export const SIDEBAR_LOGO_IMAGE_URL = 'Sidebar Logo Image URL'
export const SIGNED_ON = 'Signed On'
export const SIGNED_PDF = 'Signed PDF'
export const SMS = 'SMS'
export const SOCIAL_SECURITY_NUMBER = 'Social Security Number'
export const SOCIAL_SECURITY_NUMBER_LABEL = 'Social Security Number (SSN)'
export const SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT = 'Something went wrong while processing the payment.'
export const SOURCE_INSTRUMENT_LABEL = 'Source Instrument Label'
export const SOCIAL_INSURANCE_NUMBER = 'Social Insurance Number (SIN)'
export const SOCIAL_INSURANCE_NUMBER_HELPER_TEXT = 'Enter your 9-digit Social Insurance Number (SIN). This is used for identity verification purposes.'
export const SOCIAL_MEDIA_REPORT = 'Social Media Report'
export const SPLIT = 'Split'
export const SPLIT_TRANSFER_ID = 'Split Transfer ID'
export const SPLIT_DETAILS = 'Split Details'
export const SPLIT_PAYMENT = 'Split Payment'
export const SPLIT_PAYMENTS = 'Split Payments'
export const SPLIT_REFUND = 'Split Refund'
export const SPLIT_REFUND_AMOUNT = 'Split Refund Amount'
export const SPLIT_REFUND_CONFIRMATION_QUESTION = 'Do you want to split this refund?'
export const SPLIT_REFUND_FORM_TABLE_SUBTITLE = 'The total refund amount can not exceed the original payment amount.'
export const SPLIT_REFUND_FORM_TABLE_TITLE = 'Enter the split refund amount for each merchant'
export const SPLIT_REFUND_RADIO_BUTTON_NO_OPTION = 'No. Only provide the refund from'
export const SPLIT_REFUND_RADIO_BUTTON_YES_OPTION = 'Yes. Split the refund across multiple merchants.'
export const SPLIT_TRANSACTION = 'Split Transaction'
export const SSN_ISSUE_STATE = 'SSN Issue State'
export const SSN_STATUS = 'SSN Status'
export const SSN_ISSUE_START_END_YEAR = 'SSN Issue Start/End Year'
export const SSN = 'SSN'
export const SIN = 'SIN'
export const START_APPLICATION = 'Start Application'
export const START_DATE = 'Start Date'
export const START_DAY = 'Start Day'
export const START_TIME = 'Start Time'
export const START_NEW_APPLICATION = 'Start New Application'
export const START_WITH_A_TEMPLATE = 'Start with a template'
export const STATE = 'State'
export const STATEMENT_DESCRIPTOR = 'Statement Descriptor'
export const STATEMENTS = 'Statements'
export const STATUS = 'Status'
export const STEP_1_SCAN_BARCODE = 'Step 1: Scan barcode'
export const STEP_2_ENTER_AUTHENTICATION_CODE = 'Step 2: Enter authentication code'
export const STOP_ACCRUING = 'Stop Accruing'
export const SUBDOMAIN = 'Subdomain'
export const SUBDOMAINS = 'Subdomains'
export const SURCHARGES = 'Surcharges'
export const SURCHARGES_ENABLED = 'Surcharges Enabled'
export const SURCHARGE_AMOUNT = 'Surcharge Amount'
export const SUBDOMAIN_CONFIG_TOOLTIP = 'Note: Sandbox domains have a -sandbox prefix to help with testing. In production, you can choose your final domain name.'
export const SUBJECT_ID = 'Subject ID'
export const SUBMIT = 'Submit'
export const SUBMISSION_TYPE = 'Submission Type'
export const SUBMIT_ADDITIONAL_INFO = 'Submit Additional Info'
export const SUBMIT_TO_UNDERWRITING_CONFIRMATION_MSG = 'Please confirm that you would like to send this to underwriting review.'
export const SUBMIT_EVIDENCE = 'Submit Evidence'
export const SUBMIT_EVIDENCE_FORM = 'Submit Evidence Form'
export const SUBMIT_EVIDENCE_FOR_DISPUTE_INSTRUCTIONS = 'First, upload the files you want to submit as evidence.'
export const SUBMIT_EVIDENCE_FOR_DISPUTE_WARNING = 'Submitting evidence is final. Once submitted, you can no longer change your defense or upload more files.'
export const SUBMIT_EVIDENCE_FOR_THIS_DISPUTE = 'Submit evidence for this dispute'
export const SUBMIT_TO_UNDERWRITING = 'Submit to Underwriting'
export const SUBMITTED = 'Submitted'
export const SUBMITTED_BY = 'Submitted By'
export const SUBMITTED_ON = 'Submitted On'
export const SUBMIT_APPLICATION_FORM = 'Submit Application Form'
export const SUBMITTED_FILES = 'Submitted Files'
export const SUBMITTING_EVIDENCE = 'Submitting Evidence'
export const SUBSCRIPTION_PLANS = 'Subscription Plans'
export const SUBSCRIPTION_PLAN_ID = 'Subscription Plan ID'
export const SUBSCRIPTION_PLAN = 'Subscription Plan'
export const SUBSCRIPTION_PLANS_TOOLTIP = 'Subscription plans are templates with set recurring costs and frequencies that can be used to create subscriptions for individual buyers.'
export const SUBMIT_NOTE = 'Submit Note'
export const SUBMIT_NOTE_CONFIRMATION_MSG = 'Are you sure you want to submit this note?'
export const SUBMIT_NOTE_CONFIRMATION_SUBTITLE = 'Please make sure your note does not contain any personal information. All submitted notes cannot be edited or deleted.'
export const SUBSCRIPTION = 'Subscription'
export const SUBSCRIPTIONS = 'Subscriptions'
export const SUBSCRIPTIONS_SUBTITLE = 'A subscription is a recurring charge in exchange for the use of a product or service.'
export const SUBSCRIPTIONS_INSIGHTS = 'Subscriptions Insights'
export const SUBSCRIPTIONS_ARE_NOT_AVAILABLE = 'Subscriptions are not available'
export const SUBSCRIPTION_PLANS_ARE_NOT_AVAILABLE = 'Subscription plans are not available'
export const SUBSCRIPTION_ENROLLMENTS = 'Subscription Enrollments'
export const SUBSCRIPTION_NAME = 'Subscription Name'
export const SUBSCRIPTION_NAME_TOOLTIP = 'Custom name for this subscription.'
export const SUBSCRIPTION_TYPE = 'Subscription Type'
export const SUBSCRIPTION_DETAILS = 'Subscription Details'
export const SUBSCRIPTION_STARTS = 'Subscription Starts'
export const SUBTOTAL = 'Subtotal'
export const SUBTYPE = 'Subtype'
export const SUCCEEDED = 'Succeeded'
export const SUCCESSFUL_SALES = 'Successful Sales'
export const SUCCESSFULLY_LINKED = 'Successfully Linked'
export const SUCCESSFUL_SALES_TOOLTIP = ' represents the total of all successful sales transactions within the time period selected.'
export const SUCCESSFUL_REFUNDS = 'Successful Refunds'
export const SUCCESSFUL_REFUNDS_TOOLTIP = ' represents the total of all successful refund transactions for the filtered time frame.'
export const SUCCESSFULLY_COPIED_TO_CLIPBOARD = 'Successfully copied to clipboard.'
export const SUGGESTED_AMOUNT = 'Suggested Amount'
export const SUPPLEMENTAL_FEE_1 =  'Supplemental Fee 1'
export const SUPPLEMENTAL_FEE_2 =  'Supplemental Fee 2'
export const START_WITH_A_TEMPLATE_MSG = 'Start with a template'
export const SWITCH_ACCOUNTS = 'Switch Accounts'
export const SWITCH_TO = 'Switch to'
export const TAX_AUTHORITY = 'Tax Authority'
export const TAX_EXEMPT_ORGANIZATION = 'Tax Exempt Organization'
export const TAX_ID = 'Tax ID'
export const TAX_ID_LONG_LABEL = 'Individual Taxpayer Identification Number (ITIN) / Social Security Number (SSN)'
export const TAX_ID_NAMES = 'Tax ID/EIN/SSN'
export const TAX_ID_NUMBER = 'Tax ID Number'
export const TAX_ID_PROVIDED = 'Tax ID Provided'
export const TAX_ID_TYPE = 'Tax ID Type'
export const TAX_ID_ISSUANCE = 'Tax ID Issuance'
export const TAX_ID_REGISTRATION_DOCUMENT = 'Tax ID Registration Document'
export const TALK_TO_SALES = 'Talk to Sales'
export const TAX_ID_REGISTRATION_HELPER_TEXT = 'The document of letter that verifies your tax identification number issued by the Canada Revenue Agency.'
export const TEAM = 'Team'
export const TEAM_MANAGEMENT = 'Team Management'
export const TEAM_MEMBER = 'Team Member'
export const TEAM_MEMBER_EMAIL = 'Team Member Email'
export const TELL_US_ABOUT_YOUR_BUSINESS = 'Tell us about your business'
export const TELL_US_ABOUT_YOUR_BUSINESS_AND_LOCATION_TO_VERIFY = 'Tell us about your business and where it\'s located. This information is collected to verify your business.'
export const TEMPLATE_CREATED_ON = 'Template Created On'
export const TEMPLATE_INFO = 'Template Info'
export const TERMINALS = 'Terminals'
export const TERMINATION_REASON = 'Termination Reason'
export const TERMS_OF_SERVICE = 'Terms of Service'
export const TERMS_AND_CONDITIONS = 'Terms and Condition'
export const TERMS_OF_SERVICE_URL = 'Terms of Service URL'
export const TERMS_OF_SERVICE_URL_TOOLTIP = 'You must inform buyers of the terms and conditions that apply to their transactions.'
export const TERMS_OF_SERVICE_URL_HELPER_TEXT = 'A link to your company\'s terms of service.'
export const TERMS_OF_SERVICE_URL_HELPER_TEXT_2 = 'Terms of Service link that should be used by default. Selection is editable.'
export const THIS_LINK_HAS_EXPIRED = 'This link has expired.'
export const THIS_LINK_HAS_EXPIRED_DESCRIPTION = 'Please reach out to your Finix contact for a new link if you still need to complete your application.'
export const THIS_MERCHANT_ONBOARDING_LINK_HAS_EXPIRED_DESCRIPTION = 'Please contact us for a new link if you still need to complete your application.'
export const THIS_ACTION_REMOVES_ENTRIES_FROM_SETTLEMENT = 'This action removes the following entries from settlement'
export const THIS_FORM_WILL_EXPIRE_ONE_YEAR_AFTER_SIGNING = 'This form will expire 1 year after signing.'
export const THIS_ACTION_CANNOT_BE_UNDONE = 'This action cannot be undone.'
export const TITLE = 'Title'
export const TIP_AMOUNT = 'Tip Amount'
export const TIP_AMOUNT_EXAMPLE = 'Eg: tip amount'
export const TO_FINAL_DECISION = 'To final decision'
export const TO = 'To'
export const TO_SETUP_MFA_DESCRIPTION = 'To set up MFA, you\'ll need your password and an authenticator app or access to text messages on your phone. Once set up, you will need your password and a one-time code to log in.'
export const TODAY = 'Today'
export const TOTAL_ACH_VOLUME = 'Total ACH Volume'
export const TOTAL_AMOUNT = 'Total Amount'
export const TOTAL_CAPTURE_AMOUNT = 'Total Capture Amount'
export const TOTAL_DUE = 'Total Due'
export const TOTAL_PAID = 'Total Paid'
export const TOTAL_PAYOUT = 'Total Payout'
export const TOTAL_FEE = 'Total Fee'
export const TOTAL_PURCHASE_AMOUNT = 'Total Purchase Amount'
export const TOTAL_AMOUNT_SUBTITLE = 'Including taxes, tips, or other fees'
export const TOTAL_CUSTOMS_DUTY_AMOUNT = 'Total Customs Duty Amount'
export const TOTAL_CUSTOMS_DUTY_AMOUNT_TOOLTIP_MSG = 'Total customs duties or taxes associated with the import/export of goods.'
export const TOTAL_DISCOUNT_AMOUNT = 'Total Discount Amount'
export const TOTAL_DISCOUNT_AMOUNT_TOOLTIP_MSG = 'Total discounts applied to the entire transaction.'
export const TOTAL_DISPUTE_VOLUME = 'Total Dispute Volume'
export const TOTAL_DISPUTED_VOLUME = 'Total Disputed Volume'
export const TOTAL_DISPUTES_VOLUME_TOOLTIP = ' represents the total monetary volume of payments disputed (excluding dispute fees) and the total quantity of disputes received.'
export const TOTAL_ITEM_COST = 'Total Item Cost'
export const TOTAL_ITEM_COST_INCLUDING_SALES_TAX_TOOLTIP_MSG = 'For non-taxable transactions enter the total cost of the item.'
export const TOTAL_MERCHANT_COUNT = 'Total Merchant Count'
export const TOTAL_RUN_COUNT = 'Total Run Count'
export const TOTAL_SHIPPING_AMOUNT = 'Total Shipping Amount'
export const TOTAL_TRANSACTION_VOLUME_TOOLTIP = ' represents the total aggregate transaction volume including all of your merchants for the filtered time frame.'
export const TOTAL_TRANSACTION_VOLUME_HOMEPAGE_TOOLTIP = ' represents the total aggregate transaction volume including all of your merchants.'
export const TOTAL_TRANSACTION_VOL = 'Total Transaction Vol.'
export const TOTAL_TRANSACTION_VOL_TOOLTIP = ' represents the total aggregate transaction volume including all of your merchants.'
export const TOTAL_TRANSACTION_VOLUME = 'Total Transaction Volume'
export const TOTAL_TRANSACTION_VOLUME_BY_MERCHANT = 'Total Transaction Volume by Merchant'
export const TOTAL_TRANSACTION_VOLUME_BY_MCC = 'Total Transaction Volume by MCCs'
export const TRACE_ID = 'Trace ID'
export const TRANSACTION = 'Transaction'
export const TRANSACTIONS = 'Transactions'
export const TRANSACTION_ACTIVITY = 'Transaction Activity'
export const TRANSACTION_CREDITS = 'Transaction Credits'
export const TRANSACTION_DEBITS = 'Transaction Debits'
export const TRANSACTION_DETAILS = 'Transaction Details'
export const TRANSACTION_INSIGHTS = 'Transaction Insights'
export const TRANSACTION_INSIGHTS_SUBTITLE = 'Historical look at all of your transactions, including payments, refunds, and authorizations.'
export const TRANSACTION_TYPE = 'Transaction Type'
export const TRANSACTION_TRENDS = 'Transaction Trends'
export const TRANSACTION_LIMITS = 'Transaction Limits'
export const TRANSACTION_MONITORING = 'Transaction Monitoring'
export const TRANSIT_NUMBER = 'Transit Number'
export const TRANSFER_COUNT = 'Transfer Count'
export const TRANSFERS_API_REFERENCE = 'Transfers API Reference'
export const TRUE = 'True'
export const TRIAL_START_DATE = 'Trial Start Date'
export const TRIAL_DURATION = 'Trial Duration'
export const FREE_TRIAL_PHASE = 'Free Trial Phase'
export const TRIAL_PHASE = 'Trial Phase'
export const TRIAL_PRICE = 'Trial Price'
export const TRIAL_PRICE_AMOUNT = '$0.00'
export const TRIAL_HELPER_TEXT = 'Trial period will begin the day the subscription is created. After the trial ends, the recurring price selected above will be charged.'
export const TWITTER = 'Twitter'
export const TYPE = 'Type'
export const UPLOAD = 'Upload'
export const UPLOAD_FILE = 'Upload File'
export const UPLOADED_FILES = 'Uploaded Files'
export const UPLOAD_LOGO = 'Upload Logo'
export const UPLOAD_LOGO_HELPER_TEXT = 'The logo that will appear on the page header of the onboarding form.'
export const UPLOAD_IDENTITIES = 'Upload Identities'
export const UPDATE_DASHBOARD_ACCOUNT_NAME_SUCCESS = 'The Dashboard Name was successfully updated.'
export const UPDATE_DISPUTE = 'Update Dispute'
export const UPDATE_RECIPIENT_CONTACT_INFO_SUCCESS_MSG = 'Recipient contact information successfully updated.'
export const UPDATE_RECIPIENT_SETTINGS = 'Update Recipient Settings'
export const UPDATE_RECIPIENT_SETTINGS_SUBTITLE = 'Control what types of Recipients can be boarded'
export const UPDATE_IDLE_SCREEN = 'Update Idle Screen'
export const UPDATE_IDLE_SCREEN_DESCRIPTION = 'The idle image and message will be displayed on the device screen when it is not in use.'
export const UPDATE_FILE_NAME = 'Update File Name'
export const UNASSIGNED = 'Unassigned'
export const UNDERWRITING = 'Underwriting'
export const UNDERWRITING_LOGS = 'Underwriting Logs'
export const UNDERWRITING_INFORMATION = 'Underwriting Information'
export const UNDERWRITING_REVIEW = 'Underwriting Review'
export const UNDERWRITING_REVIEWS = 'Underwriting Reviews'
export const UNDERWRITING_INFORMATION_BY_APPLICATION = 'Underwriting Information by Application'
export const UNDERWRITING_INFORMATION_BY_APPLICATION_TOOLTIP = 'Summary of underwriting data for all merchants, grouped by application.'
export const UNDERWRITING_INFORMATION_BY_MERCHANT = 'Underwriting Information by Merchant'
export const UNDERWRITING_INFORMATION_BY_MERCHANT_TOOLTIP = 'Summary of underwriting data for all mercahtns (one record per merchant).'
export const UNDERWRITING_INFORMATION_BY_MERCHANT_BUSINESS_TYPE = 'Underwriting Information by Merchant Business Type'
export const UNDERWRITING_INSIGHTS = 'Underwriting Insights'
export const UNDERWRITING_INSIGHTS_SUBTITLE = 'Historical view of all underwriting reviews'
export const UNDERWRITING_STATUS = 'Underwriting Status'
export const UNDERWRITING_STATUS_NOT_UPDATED_AUTOMATICALLY = 'This merchant does not have automatic underwriting enabled'
export const UNDERWRITING_STATUS_UPDATED_AUTOMATICALLY = 'Underwriting status was automatically updated by the decision engine'
export const UNDERWRITING_TABLE_EMPTY_MESSAGE = 'No merchant applications available'
export const UNDERWRITING_TRENDS_TOOLTIP = 'Underwriting trends over the selected time frame.'
export const UNDERWRITING_APPLICATIONS_TABLE_EMPTY_MESSAGE = 'No application forms available'
export const UNDERWRITING_RECIPIENT_TABLE_EMPTY_MESSAGE = 'No recipients applications available'
export const UNDERWRITING_REVIEWS_TABLE_EMPTY_MESSAGE = 'No reviews available.'
export const UNDERWRITING_MERCHANTS_TABLE_EMPTY_MESSAGE = 'No merchant underwriting reviews available'
export const UNDERWRITING_PROFILE = 'Underwriting Profile'
export const UNDERWRITING_PROFILE_ROLE_PLATFORM_TOOLTIP = 'The default values that Applications will inherit unless they have a custom Underwriting Profile.'
export const UNDERWRITING_PROFILE_ROLE_PARTNER_TOOLTIP = 'The default values that will determine the transaction limits and allowed MCCs for Merchants under this Application.'
export const UNDERWRITING_PROFILE_EMPTY_MESSAGE = 'No Underwriting Profile available.'
export const UNDERWRITING_ISP_BANNER_MESSAGE = 'Since this business is a Sole Proprietorship, reports will only run at the individual level.'
export const UNDERWRITING_TRENDS = 'Underwriting Trends'
export const UNDERWRITING_AUTOMATION = 'Underwriting Automation'
export const UNDERWRITING_AUTOMATION_SETTINGS = 'Underwriting Automation Settings'
export const UNDERWRITING_AUTOMATION_UPDATES_SUCCESS_MESSAGE = 'Underwriting automation settings updated.'
export const UNDERWRITING_AUTOMATION_UPDATES_ERROR_MESSAGE = 'Failed to update underwriting automation settings. Please try again later.'
export const UNKNOWN = 'Unknown'
export const UNDERWRITTEN = 'Underwritten'
export const UNREFERENCED_REFUND = 'Unreferenced Refund'
export const UNREFERENCED_REFUND_DETAILS = 'Unreferenced Refund Details'
export const UNSIGNED_PDF = 'Unsigned PDF'
export const UNIT_OF_MEASURE = 'Unit of Measure'
export const UNIT_PRICE = 'Unit Price'
export const UPDATE = 'Update'
export const UPDATE_APPLICATION_SETTINGS = 'Update Application Settings'
export const UPDATE_DISPUTE_STATE = 'Update Dispute State'
export const UPDATE_MERCHANT_SETTINGS = 'Update Merchant Settings'
export const UPDATE_STATE_TO = 'Update State To'
export const UPDATE_TRANSFERS = 'Update Transfers'
export const UPDATE_VERIFICATIONS = 'Update Verifications'
export const UPDATE_SUBSCRIPTION = 'Update Subscription'
export const UPDATE_PLAN = 'Update Plan'
export const UPDATED = 'Updated'
export const UPDATED_ON = 'Updated On'
export const UPDATE_ROLE = 'Update Role'
export const UPDATE_PERSONAL_INFO = 'Update Personal Info'
export const UPDATE_PERSONAL_INFO_MODAL_INSTRUCTIONS = 'Update the name we have in your profile. This will update your name for all dashboards.'
export const UPLOADED = 'Uploaded'
export const UPLOADED_ON = 'Uploaded On'
export const UPLOADING = 'Uploading'
export const UPLOADING_FILES = 'Uploading Files'
export const USE_CUSTOM_BRANDING = 'Use Custom Branding'
export const USE_CUSTOM_BRANDING_PAYMENT_LINK_TOOLTIP_MSG = 'This will override your branding settings and apply custom branding to this payment link. Other payment links will be unaffected.'
export const USE_CUSTOM_BRANDING_PAYOUT_LINK_TOOLTIP_MSG = 'This will override your branding settings and apply custom branding to this payout link. Other payout links will be unaffected.'
export const USER = 'User'
export const USER_AGENT = 'User Agent'
export const USER_ID = 'User ID'
export const USER_EXPORTS = 'User Exports'
export const USERNAME = 'Username'
export const USERS = 'Users'
export const USE_A_LAPTOP_OR_MOBILE_DEVICE = 'Use a laptop or mobile device with camera access'
export const USE_AN_AUTHENTICATOR_APP = 'Use an authenticator app to set up MFA.'
export const VALID_UNTIL = 'Valid Until'
export const VALID_US_CAN_NUMBERS_ONLY_RATES_APPLY = 'Valid US or Canadian numbers only. Standard message and data rates may apply.'
export const VARIABLE_AMOUNT = 'Variable Amount'
export const VARIABLE_AMOUNT_BUYER_CHOOSES = 'Variable Amount (buyer chooses what to pay)'
export const VERIFY_EMAIL_TITLE_STRING = 'Almost there - let\'s verify your email.'
export const VERIFY_EMAIL_TEXT_STRING = 'We sent an email to '
export const VERIFY_EMAIL_SUBTEXT_STRING = 'Didn\'t receive an email? Check your spam folder or'
export const VERIFY_EMAIL_ERROR_TITLE_STRING = 'There was an issue verifying your email.'
export const VERIFY_EMAIL_ERROR_TEXT_STRING = 'Verify your email again to finish creating your account.'
export const VERIFY_EMAIL_SUCCESS_TITLE_STRING = 'Email successfully verified.'
export const VERIFY_EMAIL_SUCCESS_TITLE_STRING_2 = 'Email already verified.'
export const VERIFY_EMAIL_SUCCESS_TEXT_STRING = 'Log in to the Finix Dashboard and start exploring.'
export const VERIFY = 'Verify'
export const VERIFY_EMAIL_AGAIN = 'Verify Email Again'
export const VERIFY_ACCOUNT_NUMBER = 'Verify Account Number'
export const VERIFICATION_CREATED_AT = 'Verification Created At'
export const VERIFICATION_DETAILS = 'Verification Details'
export const VERIFICATION_ID = 'Verification ID'
export const VERIFICATION_RESULT = 'Verification Result'
export const VERIFICATION_STATE = 'Verification State'
export const VERIFICATION_STATUS = 'Verification Status'
export const VERIFICATIONS = 'Verifications'
export const VERIFICATION_NEEDED = 'Verification Needed'
export const VERIFICATION_REJECTION_DETAILS = 'Verification Rejection Details'
export const VERIFICATION_SUMMARY = 'Verification Summary'
export const VIEW_ALL = 'View all'
export const VIEW = 'View'
export const VIEW_ALL_DASHBOARDS = 'View all Dashboards'
export const VIEW_ALL_DETAILS = 'View All Details'
export const VIEW_ALL_ONBOARDING_FORMS = 'View All Onboarding Forms'
export const VIEW_ALL_PAYMENTS = 'View All Payments'
export const VIEW_ALL_AUTHORIZATIONS = 'View All Authorizations'
export const VIEW_API_RESPONSE = 'View API Response'
export const VIEW_DETAIL_PAGE = 'View Detail Page'
export const VIEW_BALANCE_ENTRY = 'View Balance Entry'
export const VIEW_PARENT_BALANCE_ENTRY = 'View Parent Balance Entry'
export const VIEW_BUYER_DETAILS = 'View Buyer Details'
export const VIEW_CUSTOMER = 'View Customer'
export const VIEW_DETAILS = 'View Details'
export const VIEW_DEVICE_INFO = 'View Device Info'
export const VIEW_EXPORTS_PAGE = 'View Exports Page'
export const VIEW_FILE = 'View File'
export const VIEW_OTHER_RECEIPT_OPTIONS = 'View other receipt delivery options'
export const VIEW_PAYMENT = 'View Payment'
export const VIEW_PAYMENT_INSTRUMENT = 'View Payment Instrument'
export const VIEW_PAYMENT_LINK = 'View Payment Link'
export const VIEW_PAYMENT_LINK_DETAILS = 'View Payment Link Details'
export const VIEW_PHOTO = 'View Photo'
export const VIEW_PROFILE = 'View Profile'
export const VIEW_PDF = 'View PDF'
export const VIEW_TEMPLATE_PDF = 'View Template PDF'
export const VIEW_WEBHOOK = 'View Webhook'
export const VIEW_WEBHOOK_LOGS = 'View Webhook Logs'
export const VIEW_WEBHOOKS_WITH_ISSUES = 'View Webhooks With Issues'
export const VIEW_SUBSCRIPTION_PLAN = 'View Subscription Plan'
export const VIRTUAL_TERMINAL = 'Virtual Terminal'
export const VIRTUAL_TERMINAL_ADDITIONAL_DATA_SUBTITLE = 'Submitting Level 2 or 3 details does not guarantee a lower interchange rate.'
export const VIRTUAL_TERMINAL_IS_NOT_AVAILABLE_AT_THIS_TIME = 'Virtual Terminal is unavailable'
export const VISIT_WEBSITE = 'Visit Website'
export const VISA_MERCHANT_VERIFICATION_VALUE = 'Visa Merchant Verification Value (MVV)'
export const VISA_ASSESSMENTS = 'Visa Assessments'
export const VISA_ACQUIRER_PROCESSING = 'Visa Acquirer Processing'
export const VISA_CREDIT_VOUCHER = 'Visa Credit Voucher'
export const VISA_BASE_SYSTEM_FILE_TRANSMISSION = 'Visa Base II System File Transmission'
export const VISA_KILOBYTE_ACCESS = 'Visa Kilobyte Access'
export const VOIDED_AUTHORIZATIONS = 'Voided Authorizations'
export const VOIDED_AUTHORIZATIONS_TOOLTIP = ' represents the total of all authorizations that were voided in the filtered time frame.'
export const WATCHLIST = 'Watchlist'
export const WEBSITE = 'Website'
export const WEBSITES = 'Websites'
export const WEBSITE_DOMAIN = 'Website Domain'
export const WELCOME_MODAL_NAME_CHANGE_INSTRUCTIONS = 'Please let us know your name so we can add it to your profile.'
export const WELCOME_MODAL_TITLE = 'We\'re making things more personal'
export const WHO_WOULD_YOU_LIKE_TO_SEND_MONEY_TO = 'Who would you like to send money to?'
export const WITHDRAW = 'Withdraw'
export const WITHDRAW_APPLICATION_FORM = 'Withdraw Application Form'
export const WITHDRAW_APPLICATION_CONFIRMATION_MSG = 'Are you sure you want to withdraw your application form for a live account?'
export const WITHDRAW_APPLICATION_FORM_SUCCESS_MSG = 'Your application form for a live account was withdrawn.'
export const WITHDRAW_APPLICATION_WARNING_MSG = 'You will have to submit a new application if you wish to apply again.'
export const WINDOW_START_TIME = 'Window Start Time'
export const WINDOW_START_TIME_DESCRIPTION = 'Set the start time for when the limit for each time frame begins.'
export const WIN_RATE = 'Win Rate'
export const WIN_RATE_TOOLTIP = ' represents the percentage of disputes that you’ve won compared to the number of disputes that have been filed.'
export const WON = 'Won'
export const WITHDRAW_MERCHANT = 'Withdraw Merchant'
export const WITHDRAW_APPLICANT = 'Withdraw Applicant'
export const MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_THE_VIRTUAL_TERMINAL = 'A merchant account is required to access the virtual terminal.'
export const MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_PAYMENT_LINKS = 'A merchant account is required to access payment links.'
export const MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTIONS = 'A merchant account is required to create subscriptions.'
export const MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTION_PLAN = 'A merchant account is required to create a subscription plan.'
export const MERCHANT_ACCOUNT_IS_REQUIRED_TO_ADD_DEVICE = 'A merchant account is required to add a new device.'
export const CONTACT_FINIX_SUPPORT_TO_GET_STARTED = 'Contact Finix Support to get started.'
export const WORKFLOW = 'Workflow'
export const WORKFLOW_ID = 'Workflow ID'
export const WORKFLOW_NAME = 'Workflow Name'
export const YOU_NEED_A_PROVISIONING_MERCHANT_TO_PROCESS_PAYMENT = 'You need a provisioning merchant account to process payment. Please contact support to set up your merchant account.'
export const YOUR_APPLICATION_HAS_ALREADY_BEEN_SUBMITTED = 'Your application has already been submitted!'
export const YOUR_ORGANIZATION_REQUIRES_MFA_FOR_YOUR_ACCOUNT = 'Your organization requires multi-factor authentication (MFA) for your account. Please select how you would like to register for MFA.'

export const API_LOG = 'API Log'
export const API_LOGS = 'API Logs'
export const API_LOGS_BANNER_MESSAGE = 'API logs retain data for 40 days only.'
export const TIME = 'Time'
export const TIMESTAMP = 'Timestamp'
export const TIME_IN_MANUAL_REVIEW = 'Time in Manual Review'
export const TIMEFRAME = 'Timeframe'
export const TIME_ZONE = 'Time Zone'
export const HTTP_STATUS = 'HTTP Status'
export const HTTP_METHOD = 'HTTP Method'
export const HTTP_LOG_ENTITY_ID = 'Entity Id'
export const METHOD = 'Method'
export const REQUEST_PATH = 'Request Path'
export const REQUEST_ID = 'Request ID'
export const REQUESTING_USER_ID = 'Requesting User ID'
export const API_KEYS = 'API Keys'
export const WEBHOOK = 'Webhook'
export const WEBHOOK_EVENT_ATTEMPT = 'Webhook Event Attempt'
export const WEBHOOK_EVENT_ID = 'Webhook Event ID'
export const WEBHOOK_ID = 'Webhook ID'
export const WEBHOOK_LOGS = 'Webhook Logs'
export const WEBHOOK_LOGS_BANNER_MSG = 'Webhook logs retain data for 30 days only.'
export const WEBHOOK_ALL_EVENTS_CHOICE_DESCRIPTION = 'All current events'
export const WEBHOOK_EMPTY_EVENTS_VALIDATION = 'You must select at least one entity.'
export const WEBHOOK_EVENTS_DESCRIPTION = 'The specified endpoint will receive the following events.'
export const WEBHOOK_ISSUE_DESCRIPTION = 'All unaccepted events will queue for up to 30 days until the webhook issue is resolved. Any queued events older than 30 days will be permanently deleted.'
export const WEBHOOK_ISSUE_DETAILS = 'Webhook Issue Details'
export const WEBHOOK_NICKNAME_VALIDATION_MSG = 'Max of 75 characters allowed.'
export const WIRE_REFERENCE_NUMBER_VALIDATION_MSG = 'Wire Reference Number cannot exceed 30 characters.'
export const WEBHOOK_SELECTED_EVENTS_CHOICE_DESCRIPTION = 'Only the following entities and types'
export const WEBHOOK_STOPPED_ACCEPTING_EVENTS = 'Webhook Stopped Accepting Events'
export const WEBHOOKS = 'Webhooks'
export const WEBHOOKS_LIST_WARNING_MSG = 'One or More Webhooks Have Stopped Accepting Events'
export const WEBHOOKS_LIST_WARNING_DESCRIPTION = 'All unaccepted events for a webhook will queue and be stored for up to 30 days until the webhook issue is resolved.'
export const WEBHOOKS_BASIC_AUTHENTICATION_DESCRIPTION = 'Webhooks will display the provided username and password in the authorization header of the HTTP request. Make sure to copy your entered username and password, as it cannot be read after saving.'
export const WEBHOOKS_BEARER_TOKEN_AUTHENTICATION_DESCRIPTION = 'Webhooks will display the provided bearer token in the authorization header of the HTTP request. Make sure to copy your entered bearer token, as it cannot be read after saving.'
export const WEBHOOKS_NO_AUTHENTICATION_DESCRIPTION = 'Warning: Opting out of authentication leaves you more susceptible to a bad actor sending you malicious webhooks.'
export const WITHDRAWN = 'Withdrawn'
export const ZIP = 'ZIP'
export const ZIP_LABEL = 'Zipcode / Postal Code'
export const ZIP_CODE = 'Zip Code'
export const SUMMARY = 'Summary'
export const SUMMARY_OF_EVIDENCE_FOR_ISSUER = 'Summary of Evidence for Issuer'
export const INFO = 'Info'
export const RECIPIENT = 'Recipient'
export const RECIPIENT_LIMITS = 'Recipient Limits'
export const RECIPIENT_PAYOUT_LIMITS = 'Recipient Payout Limits'
export const RECOMMENDATION = 'Recommendation'
export const RECIPIENTS = 'Recipients'
export const RECIPIENT_UNDERWRITING = 'Recipient Underwriting'
export const RECIPIENT_UNDERWRITING_WORKFLOW = 'Recipient Underwriting Workflow'
export const RECURRING_BILLING = 'Recurring Billing'
export const RECURRING_AMOUNT = 'Recurring Amount'
export const RECURRING_PRICE = 'Recurring Price'
export const REQUEST = 'Request'
export const REQUEST_SENT_BY = 'Request Sent By'
export const REQUEST_HEADERS = 'Request Headers'
export const RESPONSE_HEADERS = 'Response Headers'
export const REQUEST_BODY = 'Request Body'
export const RETURN_AMOUNT_EXAMPLE = 'Eg: return amount'
export const RESPONDED = 'Responded'
export const RESEND_FUNDING_TRANSFER_INSTRUCTIONS = 'Resend Funding Transfer Instructions'
export const RESPONSE_BODY = 'Response Body'
export const RESOURCE_DETAILS = 'Resource Details'
export const REQUESTS_RECEIVED = 'Requests Received'
export const REQUESTS_RECEIVED_TOOLTIP = ' represents the total of all transaction requests, including authorizations and transfer requests.'
export const API_LOG_ID = 'API Log ID'
export const REQUESTING_USER = 'Requesting User'
export const RISK_ANALYSIS = 'Risk Analysis'
export const RISK_CHECKS = 'Risk Checks'
export const CARD_PRESENT_INFORMATION = 'Card Present Information'
export const COMMODITY_CODE = 'Commodity Code'
export const COMMODITY_CODE_TOOLTIP_MSG = 'A Harmonized System code (HS Code) or tariff code.'
export const DESCRIPTION = 'Description'
export const DESCRIPTION_FOR_DEVICE_PLACEHOLDER = 'Eg: Self serving terminal'
export const DESKTOP = 'Desktop'
export const RESPONSE_MESSAGES = 'Response Messages'
export const SUBMIT_DISPUTE_EVIDENCE_LEARN_MORE_MSG = 'Learn more about what evidence to submit'
export const TAGS = 'Tags'
export const TAGS_LIMIT_REACHED = 'You have reached the limit of 50 tags.'
export const TRANSFER = 'Transfer'
export const TRANSFERS = 'Transfers'
export const TRANSFER_ID = 'Transfer ID'
export const TRANSFER_SPEED = 'Transfer Speed'
export const TRANSFER_ATTEMPTS_EMPTY_TABLE_MESSAGE = 'No payout attempts available'
export const TRY_AGAIN = 'Try again'
export const RESEND_EMAIL = 'Resend Email'
export const VELOCITY_INFO = 'Velocity Info'
export const VELOCITY_LIMITS = 'Velocity Limits'
export const VIEW_REFUND = 'View Refund'
export const VIEW_DISPUTE = 'View Dispute'
export const VIEW_REFUND_DISPUTE = 'View Refund Dispute'
export const VIEW_IN_SIFT = 'View in Sift'
export const VIEW_SETTLEMENT = 'View Settlement'
export const VIEW_SOURCE = 'View Source'
export const VOLUME_LIMIT = 'Volume Limit'
export const VOLUME_PERCENTAGE_DISTRIBUTION = 'Volume Percentage Distribution'
export const YEAR_TO_DATE = 'Year to date'
export const YEAR_TO_DATE_INSIGHTS = 'Year-to-Date Insights'
export const YEAR_TO_DATE_INSIGHTS_TOOLTIP = 'Your payments data to-date within the current calendar year.'
export const YOUR_APPLICATION_HAS_BEEN_SUBMITTED = 'Your application has been submitted!'
export const YOUR_APPLICATION_HAS_BEEN_SUBMITTED_DESCRIPTION = 'Your Finix contact will send an email update once your application has been reviewed.'
export const YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING = 'Your payment has been successfully processed. Redirecting...'
export const YES = 'Yes'
export const YEAR_OF_INCOORPORATION = 'Year of Incorporation'
export const YOUR_ROLE = 'Your Role'
export const UPDATE_WEBHOOK = 'Update Webhook'
export const URL = 'URL'
export const VANTIV_MERCHANT_ID = 'Vantiv Merchant ID'
export const VERIFICATION = 'Verification'
export const WEB_DOMAINS = 'Web Domains'
export const ZIP_POSTAL_CODE = 'Zip / Postal Code'
export const ALLOW_CVV_FAILURE_DESCRIPTION = 'Allow CVV Failure: Transactions with mismatching CVV codes won\'t fail and will be submitted.'
export const ALLOW_AVS_FAILURE_DESCRIPTION = 'Allow AVS Failure: Transactions with mismatching billing addresses won\'t fail and will be submitted.'
export const RISK_PROFILE_DESCRIPTION = 'Determines how your Application processes transactions. Click the Edit button in this section to update these configurations.'
export const TWENTY_FOUR_HOURS = '24 hours'
export const FORTY_EIGHT_HOURS = '48 hours'
export const SEVENTY_TWO_HOURS = '72 hours'
export const ONE_WEEK = '1 week'
export const ONE_MONTH = '1 month'
export const THREE_MONTHS = '3 months'
export const SIXTY_DAYS = '60 days'
export const SIX_MONTHS = '6 months'
export const THREE_YEARS = '3 years'

// Titles and Headers
export const TELL_US_MORE_ABOUT_YOUR_BUSINESS = 'Tell Us More About Your Business'
export const FINIX_SANDBOX_CREATION_EXPERIENCE_LABEL = "We'll create your Finix sandbox account with the right dashboard experience."
export const SELECT_BUSINESS_TYPE_LABEL = 'Select the one that best describes your business:'

// page tabs
export const GENERAL_INFO = 'General Info'
export const ACCOUNT_CREATED = 'Account Created'
export const SUPPORT = 'Support'
export const SUPPORT_TOOLS = 'Support Tools'
export const CONTACT_SALES = 'Contact Sales'

export const NOTIFICATION_SETTINGS_HEADER_MESSAGE = 'Events'
export const NOTIFICATION_SETTINGS_SUBHEADER_MESSAGE = 'Choose when and how we notify you when the following changes occur.'
export const NOTIFICATION_SETTINGS_PAYMENT_FAILED_TITLE = 'Payment Failed'
export const NOTIFICATION_SETTINGS_DISPUTE_CREATED_TITLE = 'Dispute Created'
export const NOTIFICATION_SETTINGS_DISPUTE_UPDATED_TITLE = 'Dispute Status Updated'
export const NOTIFICATION_SETTINGS_WEBHOOK_ISSUE_TITLE = 'Webhook Issue'
export const NOTIFICATION_SETTINGS_WEBHOOK_DISABLED_TITLE = 'Webhook Disabled'
export const NOTIFICATIONS_TITLE = 'Notifications'
export const NOTIFICATIONS_DROPDOWN_MARK_AS_READ_TITLE = 'Mark all as read'
export const NOTIFICATIONS_VIEW_ALL_TITLE = 'View All Notifications'
export const NOTIFICATIONS_SUB_TITLE = 'Notifications are stored for 90 days and then deleted.'
export const NOTIFICATION_SETTINGS_ECHECK_RETURN_TITLE = 'ACH Return'
export const NOTIFICATION_SETTINGS_FAILED_FUNDING_TRANSFER_TITLE = 'Failed Funding Transfer'
export const MOVE_MONEY_TITLE = 'Let\'s create your sandbox account.'
export const MOVE_MONEY_DESCRIPTION_1 = 'Your sandbox account allows you to explore the Finix Dashboard, simulate payments and payouts, and build a test integration with Finix.'
export const MOVE_MONEY_DESCRIPTION_2 = 'To process live payments, you must have an eligible business and get approved to be a Finix customer.'
export const MOVE_MONEY_DESCRIPTION_3 = 'Want to see if Finix is the right fit for you?'
export const ROLE_DETAILS_SUBHEADER = 'View and manage team members and merchants'

// Alt Payment Method strings
export const ALT_PAYMENT_METHODS = 'Alt Payment Methods'
export const APPLE_PAY = 'Apple Pay'
export const APPLE_PAY_DESCRIPTION = 'Apple Pay is a mobile and online purchasing experience for customers with supported iOS and macOS devices.'
export const APPLE_PAY_LINK_DESCRIPTION = 'Learn how to accept Apple Pay with Finix.'
export const APPLE_PAY_REGISTER_DOMAIN_INSTRUCTION =  'Input the top-level domain (e.g. example.com) or the sub-domain (e.g. shop.example.com) that you wish to enable Apple Pay for.'
export const APPLE_PAY_DOMAIN_VERIFICATION_FILE_INSTRUCTION = 'You will use this verification file to prove ownership of your domain.'
export const APPLE_PAY_DOWNLOAD_CSR_FILE_INSTRUCTION = 'You will use this CSR file in the Apple Developer Portal in the next step.'
export const APPLE_PAY_UPLOAD_PAYMENT_PROCESSING_CERT_INSTRUCTION = 'Upload the certificate file you just downloaded from Apple.'
export const APPLE_PAY_DOMAIN_TABLE_EMPTY_MESSAGE = 'No Web Domains'
export const APPLE_PAY_DOMAIN_REGISTRATIONS_TITLE = 'Apple Pay Domain Registrations'
export const APPLE_PAY_IOS_APPLICATIONS_TITLE = 'Apple Pay iOS Applications'
// TODO:  will use this commented out string in future when there is ability to revoke cert
// export const APPLE_PAY_PENDING_IOS_CERT_INSTRUCTION = 'Please contact support if you would like to revoke the pending certificate in order to create a new one, or upload the certificate to finish setting up Apple Pay for this app.'
export const GOOGLE_PAY_DESCRIPTION = 'Google Pay allows buyers to purchase goods and services using their Android phones. To enable Google Pay, please visit the Android Developer Portal and follow the instructions there.'
export const GOOGLE_PAY_FINIX_DESCRIPTION = 'No configuration is required on Finix to enable Google Pay.'
export const GOOGLE_PAY_LINK_DESCRIPTION = 'Learn how to accept Google Pay with Finix.'

// Headers
export const CREATED_AT_LABEL = 'Created'
export const UPDATED_AT_LABEL = 'Updated'
export const STARTED_AT_LABEL = 'Started'
export const ENDED_AT_LABEL = 'Ended'
export const DUE_ON_LABEL = 'Due On'
export  const TIME_FRAME = 'Time Frame'

// Table subtitle description strings
export const PAYMENTS_TABLE_SUBTITLE_DESCRIPTION = 'Payments include credit and debit card sales, captures, and eChecks (ACH).'
export const REFUNDS_TABLE_SUBTITLE_DESCRIPTION = 'All refunds and reversals for eChecks and credit and debit card sales.'
export const AUTHORIZATIONS_SUB_TITLE = 'Authorizations are temporary holds on cards verifying the availability of funds to complete transactions. Authorizations can be captured or voided.'
export const PAYMENT_INSTRUMENTS_TABLE_SUBTITLE_DESCRIPTION = 'Payment Instruments represent the secured bank accounts and cards processed in transactions.'
export const PAYMENT_LINKS_TABLE_SUBTITLE_DESCRIPTION = 'Create payment links that you can send to buyers to complete a transaction.'
export const SETTLEMENTS_SUB_TITLE = 'Settlements batch all the transactions, refunds, and adjustment transfers that have been processed.'
export const REVIEW_SETTLEMENTS_SUB_TITLE = 'Review Settlements for accuracy and approve or reject them.'
export const DISPUTES_TABLE_SUBTITLE_DESCRIPTION = 'Disputes (also called chargebacks) get created when a buyer protests a charge on their statement with their issuing bank.'
export const MERCHANT_IDENTITIES_SUBTITLE_DESCRIPTION = 'The Identity of a Merchant represents your user\'s business and the information used to verify them. For business purposes, an Identity can have multiple Merchants linked.'
export const MERCHANT_ACCOUNTS_SUB_TITLE = 'A Merchant Account configures how a Merchant processes payments, creates transactions, refunds, and more.'
export const PCI_COMPLIANCE_SUBTITLE_DESCRIPTION = 'Review the forms needed to comply with Payment Processing requirements.'
export const MERCHANT_UNDERWRITING_SUBTITLE_DESCRIPTION = 'Review Merchants that require approval, rejection, or additional information.'
export const SUBSCRIPTION_BILLING_SUBTITLE_DESCRIPTION = 'Use Subscription Billing to charge Merchant Accounts a recurring fee.'
export const APPLICATION_SUB_TITLE = 'An Application represents a customer’s business or platform within the Finix system and will have other resources (Transactions, Settlements, Payment Instruments, etc.) point back to it.'
export const APPLICATION_ONBOARDING_FORMS_SUB_TITLE = 'An Application represents a customer’s business or platform within the Finix system and will have other resources (Transactions, Settlements, Payment Instruments, etc.) point back to it. Customer Onboarding Forms are for sales prospects to complete for underwriting approval. '
export const COMPANY_SUBTITLE_DESCRIPTION = 'Manage your company account and settings.'
export const DEVELOPER_SUBTITLE_DESCRIPTION = 'Configure your developer integration into Finix.'
export const SETTINGS_SUBTITLE_DESCRIPTION = 'Manage your Finix-wide profile, plus change notification settings and view your permissions specific to this dashboard.'
export const PAYOUT_SCHEDULE_DESCRIPTION_STANDARD = 'Funding Transfers get sent immediately after a Settlement is approved. The payout bank account will receive the deposit one business day after via next-day ACH, as long as the payout Settlement gets approved before 4 PM PT (on a business day).'
export const PAYOUT_SCHEDULE_DESCRIPTION_FAST = 'Funding Transfers get sent immediately after a Settlement is approved. The payout bank account will receive the deposit the same day via same-day ACH, as long as the payout Settlement gets approved before 7 AM PT (on a business day).'
export const FEE_SCHEDULE_DESCRIPTION_STANDARD = 'Transfers with fees get created one business day after a fee Settlement is approved. The fees get debited from the fee Bank Account one business day after via next-day ACH, as long as the fee Settlement gets approved before 4 PM PT (on a business day).'
export const FEE_SCHEDULE_DESCRIPTION_FAST = 'Transfers with fees get sent immediately after a Settlement is approved. The fees get debited from the fee Bank Account the same day via same-day ACH, as long as the fee Settlement gets approved before 7 AM PT (on a business day).'
export const ACH_RETURNS_SUBTITLE_DESCRIPTION = 'An ACH return indicates the original ACH payment by the buyer did not succeed.'
export const MERCHANT_DEPOSITS_SUB_TITLE = 'Deposits are payouts sent to merchants.'
export const ONBOARDING_FORMS_TABLE_SUBTITLE_DESCRIPTION = 'Online forms for merchants to onboard to your platform.'
export const ADMIN_USERS_SUBTITLE_DESCRIPTION = 'This is an internal only screen to manage dashboard users'
export const BUYERS_SUBTITLE_DESCRIPTION = 'Information about your consumers.'
export const UNDERWRITING_ACTIONS_WARNING_MESSAGE = 'This decision will be final and cannot be undone.'
export const PARENT_PAYMENT_SPLIT_DETAILS_DESCRIPTION = 'The parent payment is divided across the merchants listed below using split transactions.'
export const CHILD_PAYMENT_SPLIT_DETAILS_DESCRIPTION = `${PARENT_PAYMENT_SPLIT_DETAILS_DESCRIPTION} Currently viewed merchant is highlighted.`
export const SPLIT_REFUND_DETAILS_CHILD_SUBTITLE_DESCRIPTION = 'This transaction is part of a single refund back to the buyer\'s original payment method.'
export const REFUNDS_SPLIT_DETAILS_SUBTITLE_DESCRIPTION = 'This refund is split across multiple merchants listed below.'
export const RECIPIENT_SUBTITLE_DESCRIPTION = 'Information about your recipients.'
export const STATEMENTS_SUBTITLE_DESCRIPTION = 'Statements are PDF reports containing a breakdown of all transactions, costs, and fees.'
// Empty Messages
export const MERCHANT_FILES_EMPTY_TABLE_MESSAGE = 'No Files Available'
export const MERCHANT_ACCOUNTS_EMPTY_TABLE_MESSAGE = 'No Merchant Accounts Available'
export const ACCOUNTS_EMPTY_TABLE_MESSAGE = 'No Accounts Available'
export const PAYMENTS_EMPTY_MESSAGE = 'No Payments Available'
export const PAYMENT_DEVICE_TRANSACTIONS_EMPTY_MESSAGE = 'No Payment Device Transactions Available'
export const PAYMENT_DEVICE_AUTHORIZATIONS_EMPTY_MESSAGE = 'No Payment Device Authorizations Available'
export const PAYMENT_DEVICE_REFUNDS_EMPTY_MESSAGE = 'No Payment Device Refunds Available'
export const REFUNDS_EMPTY_MESSAGE = 'No Refunds Available'
export const REPORTS_EMPTY_TABLE_MESSAGE = 'No Reports Found'
export const APPLICATIONS_EMPTY_TABLE_MESSAGE = 'No Applications Available'
export const PAYMENT_INSTRUMENTS_EMPTY_TABLE_MESSAGE = 'No Payment Instruments Available'
export const PAYMENT_LINKS_EMPTY_TABLE_MESSAGE = 'No Payment Links Available'
export const PAYOUT_LINKS_EMPTY_TABLE_MESSAGE = 'No Payout Links Available'
export const PURCHASES_EMPTY_TABLE_MESSAGE = 'No Purchases Available'
export const DISPUTE_REASONS = 'Dispute Reasons'
export const DISPUTE_REASONS_TOOLTIP = 'Table of disputes by reason to help you identify possible areas of improvement.'
export const DISPUTES_EMPTY_TABLE_MESSAGE = 'No disputes'
export const IDENTITY_DISPUTES_EMPTY_TABLE_MESSAGE = 'No Identity Disputes Available'
export const PAYOUTS_EMPTY_TABLE_MESSAGE = 'No Payouts Available'
export const ACH_RETURNS_EMPTY_TABLE_MESSAGE = 'No ACH Returns Available'
export const MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE = 'No Merchant Deposits Available'
export const API_LOGS_EMPTY_TABLE_MESSAGE = 'No API Logs Available'
export const APPLICATION_BANK_ACCOUNTS_EMPTY_MESSAGE = 'No Bank Accounts Available.'
export const DISPUTES_ON_REVERSALS_EMPTY_TABLE_MESSAGE = 'No Disputes on Reversals Available'
export const NO_TAGS_AVAILABLE = 'No tags have been added.'
export const AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION = 'Authorizations per Merchant or Application'
export const AUTHORIZATIONS_PER_MERCHANT_OR_APPLICATION_TOOLTIP = 'Table of authorization data per merchant or application for the filtered time frame, with the ability to sort on multiple data points.'
export const AUTHORIZATIONS_PER_MERCHANT = 'Authorizations per Merchant'
export const AUTHORIZATIONS_PER_MERCHANT_TOOLTIP = 'Table of authorization data per merchant for the filtered time frame, with the ability to sort on multiple data points.'
export const AUTHORIZATIONS_EMPTY_TABLE_MESSAGE = 'No Authorizations Available'
export const PAYMENT_DEVICES_EMPTY_TABLE_MESSAGE = 'No Payment Devices Available'
export const POST_PAYMENT_ACTIVITY_EMPTY_MESSAGE = 'No refunds or disputes.'
export const WEBHOOKS_EMPTY_TABLE_MESSAGE = 'No Webhooks Available'
export const WEBHOOK_LOGS_EMPTY_TABLE_MESSAGE = 'No Webhook Logs Available'
export const FAILURE_DETAILS_EMPTY_MESSAGE = 'No failure code or message available.'
export const RETURN_DETAILS_EMPTY_MESSAGE = 'No return code or message available.'
export const ONBOARDING_FORMS_EMPTY_TABLE_MESSAGE = 'No Onboarding Forms Available'
export const DISPUTE_CASE_DETAILS_EMPTY_MESSAGE = 'No case details.'
export const VERIFICATIONS_EMPTY_MESSAGE = 'No verifications available'
export const VERIFICATION_DETAILS_EMPTY_TABLE_MESSAGE = 'No verification details.'
export const DASHBOARD_ACCESS_EMPTY_TABLE_MESSAGE = 'No Dashboard Access.'
export const COMPLIANCE_FORMS_EMPTY_MESSAGE = 'No Compliance Forms Available'
export const PAYOUT_PLAN_EMPTY_MESSAGE = 'No Payout Plan Provided'
export const PERMISSIONS_EMPTY_MESSAGE = 'No Permissions Found.'
export const BUYERS_EMPTY_MESSAGE = 'No Buyers Available'
export const DOCUMENTS_EMPTY_MESSAGE = 'No documents have been uploaded.'
export const RISK_PROFILE_SECTION_EMPTY_MESSAGE = 'No Risk Profile available.'
export const REQUEST_BODY_EMPTY_MESSAGE = 'No Request Body available.'
export const RESPONSE_BODY_EMPTY_MESSAGE = 'No Responsße Body available.'
export const BENEFICIAL_OWNERS_EMPTY_MESSAGE = 'No beneficial owners added.'
export const CONTROL_PERSON_EMPTY_MESSAGE = 'No control person added.'
export const ADDITIONAL_DOCUMENTS_EMPTY_MESSAGE = 'No additional documents uploaded.'
export const REFUNDS_EMPTY_TABLE_MESSAGE = 'No refunds'
export const PAYMENT_DISPUTES_EMPTY_MESSAGE = 'No disputes associated with this payment at this moment.'
export const PAYMENT_REFUNDS_EMPTY_MESSAGE = 'No refunds associated with this payment at this moment.'
export const PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE = 'No fees associated with this payment.'
export const PARENT_PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE = 'No fees associated with this parent payment. Navigate to a split payment to see associated fees for each merchant.'
export const BUSINESS_NAMES_EMPTY_MESSAGE = 'No business name records found.'
export const ADDRESS_RECORDS_EMPTY_MESSAGE = 'No address records found.'
export const BUSINESS_CONTACTS_EMPTY_MESSAGE = 'No business contacts found.'
export const ASSOCIATED_PERSONS_EMPTY_MESSAGE = 'No associated persons found.'
export const INDUSTRY_CLASSIFICATIONS_EMPTY_MESSAGE = 'No industry classifications found.'
export const BUSINESS_REGISTRATIONS_EMPTY_MESSAGE = 'No business registrations found.'
export const AUDIT_LOG_EMPTY_TABLE_MESSAGE = 'No events available.'
export const GENERIC_EMPTY_MESSAGE = 'No information has been provided.'
export const GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE = 'No limits have been set.'
export const VELOCITY_LIMITS_EMPTY_MESSAGE = 'No limits have been set for this application or recipients.'
export const RECIPIENTS_EMPTY_MESSAGE = 'No Recipients Available'
export const SETTLEMENTS_EMPTY_MESSAGE = 'No Settlements Available'
export const FEE_PROFILE_LIMITS_EMPTY_MESSAGE = 'No Fee Profile Limits have been set'
export const SUBSCRIPTION_PLANS_NO_ADD_ONS_EMPTY_MESSAGE = 'This plan does not have any add ons.'
export const SUBSCRIPTION_PHASE = 'Subscription Phase'

// Form strings
export const EDIT_PAYOUT_SETTINGS_FORM_TITLE = 'Edit Payout Settings'
export const EDIT_PAYMENT_INSTRUMENT_WARNING = 'Note: Disabling a Payment Instrument will cause all future Transactions, Authorizations, and Sales to decline.'

// Tooltip Messages
export const CREATE_IDENTITY_CARD_VOLUME_TOOLTIP = 'Approximate annual credit card sales expected to be processed by your company.'
export const CREATE_IDENTITY_STATEMENT_DESCRIPTOR_TOOLTIP = 'The text that will show up next to transactions on a customer\'s bank or credit card statement. Using a statement descriptor that clearly reflects your business name and is recognizable by customers can help reduce disputes. Must be no longer than 20 characters (special characters like "%" and "!" are not allowed).'
export const CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP = 'The maximum amount per card transaction the platform allows the business to charge.'
export const CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP = 'The maximum amount per electronic bank transaction the platform allows the business to charge.'
export const CREATE_IDENTITY_AMEX_MID_TOOLTIP = 'If you have a direct relationship with Amex, please provide your 10 digit Amex processing account number.'
export const CREATE_IDENTITY_DISCOVER_MID_TOOLTIP = 'If you have a direct relationship with Discover, please provide your 15 character Discover processing account number.'
export const CREATE_IDENTITY_BUSINESS_DESCRIPTION_TOOLTIP = 'Brief description of what your business does. For example, bicycle sales and repair.'
export const CREATE_IDENTITY_AVERAGE_CARD_TRANSACTION_AMOUNT_TOOLTIP = 'Average amount per card transaction processed by the business.'
export const CREATE_IDENTITY_AVERAGE_ECHECK_TRANSACTION_AMOUNT_TOOLTIP = 'Average amount per electronic bank transaction processed by the business.'
export const CREATE_IDENTITY_ANNUAL_ACH_VOLUME_TOOLTIP = 'Average total amount of ACH (eCheck) transactions per year you expect to process.'
export const CREATE_IDENTITY_GENERIC_ANNUAL_ACH_VOLUME_TOOLTIP = 'Approximate annual electronic bank transaction volume expected to be processed by the business.'
export const CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP = 'Percentage of transactions for the business that are accepted in person via device and requires a customer to swipe, tap, or insert their card. Must be between 0 and 100.'
export const CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP = 'Percentage of transactions for the business that are accepted online via a website or mobile app. Must be between 0 and 100.'
export const CREATE_IDENTITY_MAIL_ORDER_PERCENTAGE_TOOLTIP = 'Percentage of transactions for your business that are mail order or order by telephone. Must be between 0 and 100.'
export const CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP = 'Percentage of transactions which are business to business. Must be between 0 and 100.'
export const CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP = 'Percentage of transactions which are business to consumer. Must be between 0 and 100.'
export const CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP = 'Percentage of transactions that is not with a business or consumer. Must be between 0 and 100.'
export const CREATE_IDENTITY_BUSINESS_NAME_TOOLTIP = 'The legal name of your company as shown on your EIN issuance letter.'
export const CREATE_IDENTITY_DOING_BUSINESS_AS_TOOLTIP = 'The operating or trade name of your company, the name your customers will recognize.'
export const CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP = 'The legal structure of your company.'
export const CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP = 'A public business is publicly traded while a private business is privately held.'
export const CREATE_IDENTITY_TAXID_OR_SSN_TOOLTIP = 'If you are using a Social Security Number (SSN), it will be assumed you are an Individual Sole Proprietorship.'
export const CREATE_IDENTITY_MCC_CODE_TOOLTIP = 'The four digit number that classifies businesses by the type of goods or services provided.'
export const CREATE_IDENTITY_CONTROL_PERSON_TOOLTIP = 'An individual with significant responsibility to control, manage, or direct a legal entity (e.g. executive officer or senior manager)'
export const CREATE_IDENTITY_ADD_PRINCIPAL_TOOLTIP = 'An individual who owns at least 25% of the company.'
export const CREATE_IDENTITY_BANK_ACCOUNT_INFORMATION_TOOLTIP = 'The details of the bank checking account where your settled transactions will be received.'
export const CREATE_IDENTITY_ACCOUNT_TYPE_TOOLTIP = 'The bank account must be a checking account.'
export const CREATE_IDENTITY_ADDRESS_LINE1_TOOLTIP = 'You must enter a physical address for your business. PO Boxes are not allowed.'
export const CREATE_IDENTITY_BANK_ACCOUNT_CONFIRMATION = 'You agree to use this account for legitimate business purposes and not for personal, family, or household purposes.'
export const PROCESSING_ENABLED_TOOLTIP = 'If processing is enabled, this merchant can process payments. If this flag is disabled, refunds will continue to be processed, but new debit payments will be blocked.'
export const SETTLEMENT_ENABLED_TOOLTIP = 'If settlements are enabled, settlements will be generated and have the ability to be approved. If settlements are disabled, settlements will continue to be generated but unable to be approved for this merchant.'
export const CARD_CVV_REQUIRED_TOOLTIP = 'If enabled, this merchant will be required collect CVV when tokenizing a card. If disabled, the merchant will be able to tokenize a card without collecting CVV.'
export const CARD_EXPIRATION_DATE_REQUIRED_TOOLTIP = 'If enabled, this merchant will be required to collect expiration date when tokenizing a card. If disabled, the merchant will be able to tokenize a card without collecting expiration date.'
export const CONVENIENCE_CHARGES_ENABLED_TOOLTIP = 'If enabled, this merchant can apply convenience fees. A convenience fee is a charge passed to the customers when the merchant is providing a method of payment (usually internet or over the phone) different from its standard payment channel.'
export const LEVEL_2_LEVEL_3_DATA_ENABLED_TOOLTIP = 'If enabled, this merchant can use Level 2 and Level 3 processing. For Level 2 and Level 3 processing, only business-to-business merchants and the government can receive lower interchange fees. Additionally, a corporate, business, or purchase card is required to obtain the lower interchange rate for Level 2 and 3 processing.'
export const RENT_SURCHARGES_ENABLED_TOOLTIP = 'If enabled, this merchant can apply rent surcharges. Visa offers the Visa Rent Payment Program, which allows qualifying property owners to collect a fee from cardholders who make rent payments with their credit or debit card. Include the rent surcharge in rent_surcharge_amount when creating a Transfer or an Authorization.'
export const FEE_BANK_ACCOUNT_TOOLTIP = 'The payout bank account is also the fee bank account. Any changes made to the payout bank account will be reflected in the fee bank account.'
export const CUSTOM_ROLE_DESCRIPTION_TOOLTIP = 'A high-level description of this custom role, which will be displayed in the description field on the Roles page.'
export const WEBHOOK_ISSUE_TOOLTIP = 'A webhook issue is when the webhook stops accepting events.'
export const BUSINESS_DESCRIPTION_TOOLTIP = 'A short description about your business and the services provided.'
export const EMAIL_ADDRESS_TOOLTIP = 'The contact email for the business.'
export const BUSINESS_PHONE_NUMBER_TOOLTIP = 'A primary cell phone is also acceptable.'
export const MERCHANT_COUNT_TOOLTIP = 'The number of merchants on the business\' platform.'
export const ANNUAL_CARD_VOLUME_TOOLTIP = 'Approximate annual credit card sales expected to be processed by the business.'
export const ANNUAL_ACH_VOLUME_TOOLTIP = 'Approximate annual ACH transaction volume expected to be processed by the business.'
export const MAX_CARD_TRANSACTION_AMOUNT_TOOLTIP = 'The maximum amount per card card transaction the business allows to be charged.'
export const MAX_CARD_TRANSACTION_AMOUNT_GENERIC_TOOLTIP = 'The maximum amount per card transaction that is allowed to be charged.'
export const MAX_CARD_TRANSACTION_AMOUNT_LABEL = 'Max Card Transaction Amount'
export const MAX_ACH_TRANSACTION_AMOUNT_TOOLTIP = 'The maximum amount per ACH transaction the business allows to be charged.'
export const MAX_ACH_TRANSACTION_AMOUNT_GENERIC_TOOLTIP = 'The maximum amount per ACH transaction that is allowed to be charged.'
export const MAX_ACH_TRANSACTION_AMOUNT_LABEL = 'Max ACH Transaction Amount'
export const MAX_EFT_TRANSACTION_AMOUNT_LABEL =  'Max EFT Transaction Amount'
export const ANNUAL_TRANSACTION_COUNT_TOOLTIP = 'Total number of transactions processed in the past 12 months'
export const MERCHANT_CATEGORY_CODE_TOOLTIP = 'The four digit number that classifies businesses by the type of goods or services provided.'
export const SUBMIT_DISPUTE_EVIDENCE_FILES_NOTE = 'Note: Files will be saved even if you don\'t submit evidence at this time.'
export const SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_1 = 'If you have evidence that the charge is legitimate, you can send it to the processor by uploading your files and clicking'
export const SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_MSG_PT_2 = 'Only click Submit Evidence once you have everything ready to submit to the processor.'
export const SUBMIT_DISPUTE_EVIDENCE_TOOLTIP_NOTE = 'Note: Uploaded files will be saved even if you don\'t click Submit Evidence.'
export const ACCEPTING_LIABILITY_TOOLTIP_MSG = 'Accepting liability for a dispute means that the merchant acknowledges and agrees with the buyer\'s claim. This ends the dispute process.'
export const DISPUTE_REPRESENTMENT_AMOUNT_TOOLTIP_MSG = 'Enter the amount you want to dispute. The default value is the full disputed amount.'
export const SUMMARY_OF_EVIDENCE_TOOLTIP_MSG = 'The response that\'ll be sent to the issuer along with your evidence. This should be a brief explanation of the evidence you\'re submitting for this dispute.'

export const OVERDUE_FORMS_TOOLTIP_MSG = ' represent the total number of compliance forms past their due date.'
export const FORMS_DUE_30DAYS_TOOLTIP_MSG = ' represent the total number of compliance forms due in 30 days or less.'
export const FORMS_DUE_60DAYS_TOOLTIP_MSG = ' represent the total number of compliance forms due in 60 days or less.'
export const AVG_TIME_TO_COMPLETION_TOOLTIP_MSG = ' represent the average time it takes for your merchants to complete compliance forms.'
export const COMPLIANCE_TRENDS_TOOLTIP_MSG = ' Historical look at your compliance forms within the filtered time frame.'
export const ONBOARDING_FORMS_GENERATED_TOOLTIP_MSG =' represents the total number of onboarding forms that you generated within the filtered time frame.'
export const ONBOARDING_FORMS_COMPLETED_TOOLTIP_MSG =' represents the total number of onboarding forms completed within the filtered time frame.'
export const ONBOARDING_FORMS_IN_PROGRESS_TOOLTIP_MSG = ' represents the total number of onboarding forms that were created but have not completed.'
export const ONBOARDING_FORMS_TREND_TOOLTIP_MSG = 'Historical look at your onboarding forms that were generated or completed within the filtered time frame.'
export const INCOMPLETE_ONBOARDING_FORMS_TOOLTIP_MSG ='Table of all merchants with onboarding forms that are incomplete.'
export const ONBOARDEDMERCHANTS_TOOLTIP_MSG = ' represents the total number of merchants onboarded.'
export const REJECTED_MERCHANTS_TOOLTIP_MSG = ' represents the number of merchants rejected within the filtered time frame.'
export const ACTIVE_MERCHANTS_TOOLTIP_MSG = ' represents the number of merchants actively transacting within the filtered time frame.'
export const PROFITABLE_MERCHANTS_TOOLTIP_MSG = ' represents the number of merchants profitable within the filtered time frame.'
export const EXPIRED_FORMS_TOOLTIP_MSG = ' represents the total number of onboarding forms that were created within the filtered time frame but have now expired.'
export const TOTAL_TRANSACTION_VOLUME_BY_MERCHANT_TOOLTIP_MSG = 'Table of card and ACH transaction volume and count per merchant within the filtered time frame.'
export const TOTAL_TRANSACTION_VOLUME_BY_MCC_TOOLTIP_MSG = 'Table of card and ACH transaction volume and count per MCC within the filtered time frame.'
export const MERCHANTS_BY_LOCATION_TOOLTIP_MSG = 'Table of total merchants, active merchants, and total transaction volume, based on merchant location within the filtered time frame. '
export const OVERDUE_COMPLIANCE_FORMS_TOOLTIP_MSG = 'Table of all merchants with compliance forms that are past their due date.'
export const SPLIT_PARENT_TRANSFER_SETTLEMENT_STATE_TOOLTIP_MSG = 'Parent payment will not go into your settlements.'
export const SPLIT_REFUND_SETTLEMENT_STATE_TOOLTIP_MSG = 'Parent refund will not go into your settlments.'

// Custom Error Messages
export const ROUTING_NUMBER_VALIDATION_ERROR = 'Routing Number must be exactly 9 digits.'
export const MASKED_BANK_ACCOUNT_NUMBER_VALIDATION_ERROR = 'Account Number must be between 5 and 17 digits.'
export const BANK_ACCOUNT_NUMBER_VALIDATION_ERROR = 'Account Number must be between 5 and 17 digits.'
export const STATEMENT_DESCRIPTOR_LENGTH_VALIDATION_ERROR = 'Default Statement Descriptor must be 1 to 20 characters long.'
export const STATEMENT_DESCRIPTOR_ASTERISK_VALIDATION_ERROR = 'The Asterisk character is not allowed or required because a prefix is applied to all Statement Descriptors automatically.'
export const STATEMENT_DESCRIPTOR_SPECIAL_CHARACTER_VALIDATION_ERROR = 'Default Statement Descriptor can only contain alphanumeric characters and select special characters: ampersand, comma, dash, period, space, or pound sign.'
export const INVALID_BASIC_AUTH_PASSWORD_LENGTH_ERROR = 'Password must be between 16 and 256 characters.'
export const EIN_VERIFICATION_DOCUMENT_UPLOAD_ERROR = 'EIN Verification Document file must be uploaded.'
export const BANK_ACCOUNT_VERIFICATION_DOCUMENT_UPLOAD_ERROR = 'Bank Account Verification Document file must be uploaded.'
export const WEBSITE_LENGTH_VALIDATION_ERROR = 'Website must be less than 120 characters.'
export const FUTURE_DATE_VALIDATION_ERROR = 'Date cannot be in the future.'
export const EMAIL_LENGTH_VALIDATION_ERROR = 'Email Address must be less than 100 characters.'
export const FIRST_NAME_LENGTH_VALIDATION_ERROR = 'First Name must be 20 characters or less.'
export const LAST_NAME_LENGTH_VALIDATION_ERROR = 'Last Name must be 20 characters or less.'
export const PHONE_NUMBER_LENGTH_VALIDATION_ERROR = 'Phone number must be a max of 11 digits.'
export const TITLE_LENGTH_VALIDATION_ERROR = 'Title must be 60 characters or less.'
export const TERMS_OF_SERVICE_ACCEPTANCE_VALIDATION_ERROR = 'Terms of Service must be accepted.'
export const TAG_NAME_LENGTH_VALIDATION_ERROR = 'Tag name must not exceed 40 characters'
export const TAG_VALUE_LENGTH_VALIDATION_ERROR = 'Tag value must not exceed 500 characters'
export const SAME_PERSONAL_ADDRESS_AS_BUSINESS_ADDRESS = 'The same address was used for the business. Please ensure the correct addresses have been provided so the business and individual can be successfully verified.'
export const SAME_PERSONAL_PHONE_AS_BUSINESS_PHONE = 'The same phone number was used for the business. Please ensure the correct phone numbers have been provided so the business and individual can be successfully verified.'
export const ADULT_VALIDATION_ERROR = 'Individual must be at least 18 years old.'
export const EMPTY_FILE_NAME_VALIDATION_ERROR = 'File Name must not be empty.'
export const EMPTY_FILE_TYPE_VALIDATION_ERROR = 'File Type must not be empty.'
export const CONFIRM_BANK_ACCOUNT_VALIDATION_ERROR = 'Confirm Bank Account must match Bank Account.'
export const GENERIC_API_ERROR_MESSAGE = 'We\'re experiencing an error with this request. Please try again and reach out to our support team if it continues.'
export const GUEST_CHECKOUT_ERROR_MESSAGE = 'There was an error processing your payment. Please check your information and try again.'

//Iframe Error Messages
export const CARD_NUMBER_EMPTY_ERROR_MESSAGE = 'Name on Card must not be empty.'
export const ZIPCODE_EMPTY_ERROR_MESSAGE = 'Zipcode must not be empty.'

// Compliance
export const COMPLIANCE_FORM_TEMPLATES_DESCRIPTION = 'Compliance form templates are used to generate PCI compliance forms for merchants.'
export const COMPLIANCE_FORM_TEMPLATE_DESCRIPTION = 'Existing templates cannot be modified, but you can duplicate it and modify that copy.'
export const COMPLIANCE_ASSESSMENT_INFORMATION_SUBMISSION_INSTRUCTIONS = 'This document must be completed as a declaration of the results of the merchant’s self-assessment with the Payment Card Industry Data Security Standard Requirements and Security Assessment Procedures (PCI DSS). Complete all sections: The merchant is responsible for ensuring that each section is completed by the relevant parties, as applicable. Contact acquirer (merchant bank) or the payment brands to determine reporting and submission procedures.'
export const CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_TITLE = 'No compliance form template assigned to this application'
export const CHANGE_APPLICATION_COMPLIANCE_TEMPLATE_INSTRUCTIONS = 'Finix cannot generate compliance for the merchants under this application without a compliance form template.'
export const DUPLICATE = 'Duplicate'
export const DUPLICATE_AND_MODIFIY = 'Duplicate & Modify'
export const ATTEST = 'Attest'
export const ATTESTATION = 'Attestation'
export const COMPLIANCE_FORM = 'Compliance Form'
export const COMPLIANCE_FORM_SETTINGS = 'Compliance Form Settings'
export const COMPLIANCE_FORM_TEMPLATE = 'Compliance Form Template'
export const COMPLIANCE_FORM_TEMPLATES = 'Compliance Form Templates'
export const COMPLIANCE_FORMS_DUE = 'Compliance Forms Due'
export const COMPLIANCE_FORMS_DUE_MONTHLY = 'Compliance Forms Due (Monthly)'
export const ATTEST_COMPLIANCE_FORM = 'Attest Compliance Form'
export const ATTEST_TO_FORM = 'Attest to Form'
export const MERCHANT_EXECUTIVE_NAME = 'Merchant Executive Name'
export const ATTEST_COMPLIANCE_FORM_SUBMISSION_AGREEMENT_MSG = 'By submitting the Self-Assessment Questionnaire, I certify that I am an authorized representative of the company and that all the information submitted is true and correct.'

// Input Placeholders
export const REPORTS_SEARCH_BAR_PLACEHOLDER = 'Filter reports by category and title'

// Reporting Strings
export const VIEW_ALL_REPORTS_LABEL = 'View table of all reports'
export const DOWNLOAD_ALL_LABEL = 'Download All'
export const LEARN_MORE_LABEL = 'Learn more'
export const ALL_REPORTS_LABEL = 'All Reports'
export const MONTHLY = 'Monthly'
export const WEEKLY = 'Weekly'
export const DAILY = 'Daily'

// report categories
export const STRATEGIC_METRICS = 'Strategic Metrics'
export const FINANCE_DATA = 'Finance Data'
export const OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT = 'Operations, Reconciliation, & Exceptions Management'
export const RISK_MANAGEMENT = 'Risk Management'

// TODO: change report types to be named report subcategories
// report types
export const NET_PROFIT_REPORT_TYPE_DISPLAY = 'Net Profit'
export const TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY = 'Transaction Interchange'
export const INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY = 'Interchange Optimization'
export const FEES_REPORT_TYPE_DISPLAY = 'Fees'
export const FEE_PROFILE_TYPE_DISPLAY = 'Fee Profile'
export const FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY = 'Flex Client Balances'
export const MERCHANT_BALANCES_REPORT_TYPE_DISPLAY = 'Merchant Balances'
export const SETTLEMENTS_REPORT_TYPE_DISPLAY = 'Settlements'
export const FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY = 'Funding Instructions'
export const AUTHORIZATIONS_REPORT_TYPE_DISPLAY = 'Authorizations'
export const TRANSACTIONS_REPORT_TYPE_DISPLAY= 'Transactions'
export const DISPUTES_REPORT_TYPE_DISPLAY = 'Disputes'
export const DISPUTES_PER_MERCHANT_OR_APPLICATION = 'Disputes per Merchant or Application'
export const DISPUTES_PER_MERCHANT_OR_APPLICATION_TOOLTIP = 'Table of dispute data per application or merchant.'
export const DISPUTES_PER_MERCHANT = 'Disputes per Merchant'
export const DISPUTES_PER_MERCHANT_TOOLTIP = 'Table of dispute data per merchant.'
export const DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY = 'Disputes Historical'
export const NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY = 'Notice Of Change'
export const SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY = 'Settled Items Non Fees'
export const SETTLED_ITEMS_FEES_TYPE_DISPLAY = 'Settled Items Fees'
export const TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY = 'Transaction Monitoring'

// report type descriptions
export const NET_PROFIT_REPORT_TYPE_DESCRIPTION = 'Gross revenues and fees collected, with allocation of fees to passthrough interchange & assessments, program fees, and revenue share.'
export const TRANSACTION_INTERCHANGE_REPORT_TYPE_DESCRIPTION = 'Per-transaction fees and costs including interchange.'
export const INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DESCRIPTION = 'Optimize interchange costs.'
export const FEES_REPORT_TYPE_DESCRIPTION = 'All fees collected, network fees passed through, and program fees charged.'
export const FEE_PROFILE_REPORT_TYPE_DESCRIPTION = 'All of your onboarded merchants and the fees applied to their transactions.'
export const FLEX_CLIENT_BALANCES_REPORT_TYPE_DESCRIPTION = 'All balances at the Flex Client level.'
export const MERCHANT_BALANCES_REPORT_TYPE_DESCRIPTION = 'All merchant balances including negative balances.'
export const SETTLEMENTS_REPORT_TYPE_DESCRIPTION = 'All settlements including those not yet funded.'
export const FUNDING_INSTRUCTIONS_REPORT_TYPE_DESCRIPTION = 'All funding instructions including those that have failed.'
export const AUTHORIZATIONS_REPORT_TYPE_DESCRIPTION = 'All authorizations including those that have failed.'
export const TRANSACTIONS_REPORT_TYPE_DESCRIPTION = 'All transactions including those that have failed.'
export const DISPUTES_REPORT_TYPE_DESCRIPTION = 'All disputes including those that have been won, lost, or expired.'
export const NOTICE_OF_CHANGE_REPORT_TYPE_DESCRIPTION = 'All notices of change.'
export const SETTLED_ITEMS_NON_FEES_TYPE_DESCRIPTION = 'All settlement entries that are merchant-facing, excludes fees collected.'
export const SETTLED_ITEMS_FEES_TYPE_DESCRIPTION = 'All fee-related settlement entries.'
export const DISPUTES_HISTORICAL_REPORT_TYPE_DESCRIPTION = 'Per-merchant chargeback metrics.'
export const TRANSACTION_MONITORING_REPORT_TYPE_DESCRIPTION = 'Various monitoring reports for operations and risk management.'

// Resource Types
export const ACH_RETURN_RESOURCE_TITLE = 'ACH Return'
export const ACH_RETURNS_RESOURCE_TITLE = 'ACH Returns'
export const TRANSACTION_RESOURCE_TITLE = 'Transaction'
export const REFUNDS_RESOURCE_TITLE = 'Refunds'
export const REFUND_RESOURCE_TITLE = 'Refund'
export const AUTHORIZATIONS_RESOURCE_TITLE = 'Authorizations'
export const AUTHORIZATION_TRENDS = 'Authorization Trends'
export const SETTLEMENTS_RESOURCE_TITLE = 'Settlements'
export const SETTLEMENT_RESOURCE_TITLE = 'Settlement'
export const SETTLEMENT_V2_RESOURCE_TITLE = 'Settlement V2'
export const DISPUTES_RESOURCE_TITLE = 'Disputes'
export const DISPUTE_RESOURCE_TITLE = 'Dispute'
export const DISPUTE_TRENDS = 'Dispute Trends'
export const DISPUTE_TRENDS_TOOLTIP = 'Historical look at your disputes, including won and lost, within the filtered time frame.'
export const REPORTS_RESOURCE_TITLE = 'Reports'
export const MERCHANT_IDENTITIES_RESOURCE_TITLE = 'Merchant Identities'
export const APPLICATIONS_RESOURCE_TITLE = 'Applications'
export const APPLICATION_RESOURCE_TITLE = 'Application'
export const PAYMENT_INSTRUMENT_NAME = 'Payment Instrument Name'
export const PAYMENT_INSTRUMENT_RESOURCE_TITLE = 'Payment Instrument'
export const PAYMENT_INSTRUMENT_API_REFERENCE = 'Payment Instrument API Reference'
export const PAYMENT_INSTRUMENTS_RESOURCE_TITLE = 'Payment Instruments'
export const PAYMENT_LINKS_RESOURCE_TITLE = 'Payment Links'
export const PAYMENT_INSIGHTS_TOOLTIP_TITLE = 'Summary of payments within the filtered time frame.'
export const BUYER_PAYMENT_INSTRUMENTS_RESOURCE_TITLE = 'Buyer Payment Instruments'
export const BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE = 'Buyer Payment Instrument'
export const UNDERWRITING_APPLICATIONS_RESOURCE_TITLE = 'Application Underwriting'
export const UNDERWRITING_RESOURCE_TITLE = 'Underwriting Merchant Applications'
export const UNDERWRITING_RECIPIENT_RESOURCE_TITLE = 'Underwriting Recipient Application'
export const COMPLIANCE_FORMS_RESOURCE_TITLE = 'Compliance Forms'
export const REVIEW_QUEUE_SETTLEMENTS_RESOURCE_TITLE = 'Review Queue Settlements'
export const SUBSCRIPTION_SCHEDULES_RESOURCE_TITLE = 'Subscription Schedules'
export const SUBSCRIPTION_SCHEDULE_AMOUNTS_RESOURCE_TITLE = 'Subscription Schedule Amounts'
export const SUBSCRIPTION_FEES_RESOURCE_TITLE = 'Subscription Fees'
export const PAYOUT_PLANS_RESOURCE_TITLE = 'Payout Plans'
export const USERS_RESOURCE_TITLE = 'Users'
export const OWNER_IDENTITY_PAYMENT_INSTRUMENT_RESOURCE_TITLE = 'Owner Identity Payment Instruments'
export const IDENTITY_ASSOCIATED_IDENTITIES_RESOURCE_TITLE = 'Identity Associated Identities'
export const IDENTITY_AUTHORIZATIONS_RESOURCE_TITLE = 'Identity Authorizations'
export const IDENTITY_TRANSFERS_RESOURCE_TITLE = 'Identity Transfers'
export const IDENTITY_DISPUTES_RESOURCE_TITLE = 'Identity Disputes'
export const IDENTITY_PAYMENT_INSTRUMENTS_RESOURCE_TITLE = 'Identity Payment Instruments'
export const IDENTITY_VERIFICATION_SCORE = 'Identity Verification Score'
export const IDENTITY_VERIFICATION_SCORE_TOOLTIP = 'This is a weighted score that factors in the results from the Identity Verification reports for both the business and business principal(s).'
export const IDENTITY_SETTLEMENTS_RESOURCE_TITLE = 'Identity Settlements'
export const IDENTITY_MERCHANTS_RESOURCE_TITLE = 'Identity Merchants'
export const IDENTITY_VERIFICATIONS_RESOURCE_TITLE = 'Identity Verifications'
export const MERCHANT_IDENTITY_RESOURCE_TITLE = 'Merchant Identity'
export const RECIPIENT_MERCHANT = 'Recipient Merchant'
export const SUBSCRIPTION_BILLING_RESOURCE_TITLE = 'Subscription Billing'
export const MERCHANT_UNDERWRITING_INSIGHTS = 'Merchant Underwriting Insights'
export const MERCHANT_UNDERWRITING_RESOURCE_TITLE = 'Merchant Underwriting'
export const MERCHANT_UNDERWRITING_SUBTITLE = 'Historical view of Merchant Underwriting'
export const MERCHANT_UNDERWRITING_WORKFLOW = 'Merchant Underwriting Workflow'
export const MERCHANT_DEPOSITS_RESOURCE_TITLE = 'Merchant Deposits'
export const MERCHANT_INSIGHTS_SUBTITLE = 'Historical look at all of your merchants.'
export const MERCHANT_DEPOSIT_RESOURCE_TITLE = 'Merchant Deposit'
export const ONBOARDING_FORMS_TITLE = 'Onboarding Forms'
export const ONBOARDING_FORMS_TREND = 'Onboarding Forms Trend'
export const ONBOARDING_FORMS_RESOURCE_TITLE = 'Merchant Onboarding Forms'
export const ONBOARDING_FORM_RESOURCE_TITLE = 'Merchant Onboarding Form'
export const CREATE_NEW_FORM = 'Create New Form'
export const CREATE_NEW_USER = 'Create New User'
export const DEVELOPER_RESOURCE_TITLE = 'Developer'
export const MERCHANT_ACCOUNTS_RESOURCE_TITLE = 'Merchant Accounts'
export const PAYOUTS_RESOURCE_TITLE = 'Payouts'
export const PAYOUT_RESOURCE_TITLE = 'Payout'
export const PAYOUT_DEPOSITED_TO_BANK_ACCOUNT = 'Payout Deposited to Bank Account'
export const SETTINGS_TITLE = 'Settings'
export const MERCHANT_ACCOUNT_RESOURCE_TITLE = 'Merchant Account'
export const PCI_COMPLIANCE_TITLE = 'Compliance'
export const INSTANT_PAYOUT_LABEL = 'Instant Payout'
export const INSTANT_PAYOUT_CONFIRMATION = 'Instant Payout Confirmation'
export const INSTANT_PAYOUTS_RESOURCE_TITLE = 'Instant Payouts'
export const INSTANT_PAYOUT_SUCCEEDED = 'Instant payout is successful!'
export const INSTANT_PAYOUT_FAILED = 'Instant Payout Failed'
export const INSTANT_PAYOUT_FAILED_MSG = 'Instant payout failed. Please try again.'
export const INSTANT_PAYOUT_PENDING_MSG = 'Instant payout is being processed. Please wait for a few minutes or come back later to see the result.'
export const INSTANT_PAYOUT_ID = 'Instant Payout ID'
export const INSTANT_PAYOUT_ATTEMPTS = 'Instant Payout Attempts'
export const INSTANT_PAYOUT_ATTEMPTS_EMPTY_MSG = 'No Instant Payouts Attempts Available'
export const RESOURCE = 'Resource'
export const RESOURCES = 'Resources'
export const IDENTITIES_RESOURCE_TITLE = 'Identities'
export const IDENTITY_RESOURCE_TITLE = 'Identity'
export const COMPLIANCE_RESOURCE_TITLE = 'Compliance'
export const REVIEW_SETTLEMENTS_RESOURCE_TITLE = 'Review Settlements'
export const EXPORTS_RESOURCE_TITLE = 'Exports'
export const REVIEW_QUEUE_MERCHANTS = 'Review Queue - Merchants'
export const REVIEW_QUEUE_IDENTITIES = 'Review Queue - Identities'
export const REVERSALS = 'Reversals'
export const REVERSE_PHONE_LOOKUP = 'Reverse Phone Lookup'
export const CUSTOM_ROLE = 'Custom Role'
export const RECIPIENT_UNDERWRITING_RESOURCE_TITLE = 'Recipient Underwriting'
export const RECIPIENT_UNDERWRITING_SUBTITLE_DESCRIPTION = 'Review disbursements applicants for potential risk and request for more information as needed to determine their eligibility for disbursements services.'

//disbursements dashboard language constants
export const ACCOUNT_BALANCE_SUBTITLE = 'Your account\'s financial activity and current balance.'
export const ACCOUNT_BALANCE_LINK_TEXT = 'View instructions for adding funds'
export const ADD_TO_ACCOUNT_BALANCE = 'Add to account balance'
export const ALLOWED_PAYOUT_METHODS = 'Allowed Payout Methods'
export const PAYOUTS_LIST_PAGE_SUBTITLE = 'A historical record of the payout activity in your account, both outbound and inbound.'
export const BENEFICIARY = 'Beneficiary'
export const BALANCE_ACCOUNT = 'Balance Account'
export const BALANCE_ACCOUNT_ID = 'Balance Account ID'
export const BALANCE_ADJUSTMENT = 'Balance Adjustment'
export const BALANCE_ADJUSTMENT_ID = 'Balance Adjustment ID'
export const BALANCE_ADJUSTMENT_CONFIRMATION = 'Balance Adjustment Confirmation'
export const BALANCE_ADJUSTMENTS = 'Balance Adjustments'
export const APPLICATION_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS = 'Application Balance Adjustments Velocity Limits'
export const BALANCE_ADJUSTMENTS_VELOCITY_LIMITS = 'Balance Adjustments Velocity Limits'
export const BALANCE_ADJUSTMENTS_LINK_TEXT = 'View wire transfer instructions.'
export const BALANCE_ADJUSTMENTS_SUBTITLE = 'Balance Adjustments increase your account balance for Payouts. You can either add funds directly or create a wire transfer to Finix.'
export const BALANCE_ENTRIES = 'Balance Entries'
export const BALANCE_ENTRIES_EMPTY_MESSAGE = 'No Balance Entries Available'
export const CLAIM_PAYOUT = 'Claim Payout'
export const CREATE_PAYOUT_LINK = 'Create Payout Link'
export const CREATE_NEW_PAYOUT_LINK = 'Create New Payout Link'
export const ACCOUNT_BALANCE_ENTRY = 'Account Balance Entry'
export const BALANCE_ENTRY_DETAILS = 'Balance Entry Details'
export const PARENT_BALANCE_ENTRY_DETAILS = 'Parent Balance Entry Details'
export const DAILY_ADJUSTMENT_VOLUME = 'Daily Adjustment Volume'
export const DAILY_ADJUSTMENT_COUNT = 'Daily Adjustment Count'
export const DAILY_ACH_PAYOUT_VOLUME = 'Daily ACH Payout Volume'
export const DAILY_ACH_PAYOUT_COUNT = 'Daily ACH Payout Count'
export const DAILY_CARD_PAYOUT_VOLUME = 'Daily Card Payout Volume'
export const DAILY_CARD_PAYOUT_COUNT = 'Daily Card Payout Count'
export const DAILY_CARD_PAYOUT_VOLUME_TOOLTIP = ' represents the volume of card payouts you\'ve processed today and the maximum volume you can pay out in a day.'
export const DAILY_CARD_PAYOUT_COUNT_TOOLTIP = ' represents the number of card payouts you\'ve processed today and the maximum number of payouts you can process in a day.'
export const DAILY_ACH_PAYOUT_VOLUME_TOOLTIP = ' represents the volume of ACH payouts you\'ve processed today and the maximum volume you can pay out in a day.'
export const DAILY_ACH_PAYOUT_COUNT_TOOLTIP = ' represents the number of ACH payouts you\'ve processed today and the maximum number of payouts you can process in a day.'
export const DAILY_ACH_VOLUME = 'Daily ACH Volume'
export const DAILY_ACH_COUNT = 'Daily ACH Count'
export const DAILY_ACH_LIMITS = 'Daily ACH Limits'
export const DAILY_CARD_VOLUME = 'Daily Card Volume'
export const DAILY_CARD_COUNT = 'Daily Card Count'
export const DAILY_CARD_LIMITS = 'Daily Card Limits'
export const DAILY_COUNT = 'Daily Count'
export const DAILY_LIMITS = 'Daily Limits'
export const DAILY_VOLUME = 'Daily Volume'
export const DISBURSEMENTS_LABEL = 'Disbursements'
export const DISBURSEMENTS_TYPE_DISPLAY_LABEL = 'Payouts'
export const DISBURSEMENTS_AND_PAYMENTS_LABEL = 'Acquiring and Payouts'
export const DOWNLOAD_WIRE_INSTRUCTIONS = 'Download wire instructions'
export const INSTITUTION_NAME = 'Institution Name'
export const ENABLED_FOR_PAYOUT = 'Enabled for Payout'
export const ENTRIES = 'Entries'
export const INSTRUCTIONS_FOR_ADDING_FUNDS = 'Instructions for adding funds'
export const INSTRUCTION_FOR_ADDING_FUNDS_SUBTITLE = 'Use the information below to create a wire transfer to Finix to add funds to your account. After wiring, please email your point of contact. We\'ll update your balance shortly after confirming the wire.'
export const MONEY_RECEIVED = 'Money Received'
export const MONEY_SENT = 'Money Sent'
export const MONTHLY_ADJUSTMENT_VOLUME = 'Monthly Adjustment Volume'
export const MONTHLY_ADJUSTMENT_COUNT = 'Monthly Adjustment Count'
export const MONTHLY_LIMITS = 'Monthly Limits'
export const MONTHLY_ACH_COUNT = 'Monthly ACH Count'
export const MONTHLY_ACH_VOLUME = 'Monthly ACH Volume'
export const MONTHLY_ACH_LIMITS = 'Monthly ACH Limits'
export const MONTHLY_CARD_LIMITS = 'Monthly Card Limits'
export const MONTHLY_CARD_COUNT = 'Monthly Card Count'
export const MONTHLY_CARD_VOLUME = 'Monthly Card Volume'
export const MONTHLY_COUNT = 'Monthly Count'
export const MONTHLY_DATE = 'Monthly Date'
export const MONTHLY_START_DATE = 'Monthly Start Date'
export const MONTHLY_VOLUME = 'Monthly Volume'
export const MY_ACCOUNT_BALANCE = 'My Account Balance'
export const DISBURSEMENTS_SUMMARY_TOOLTIP_TITLE = 'Summary of your disbursements within the filtered time frame, depending on the time zone configured for your application.'
export const LATEST_PAYOUTS = 'Latest Payouts'
export const LATEST_RUN = 'Latest Run'
export const SEE_ALL_PAYOUTS = 'See All Payouts'
export const LATEST_PAYOUTS_TOOLTIP = 'The most recent payouts (up to 10) that were sent/received in your account.'
export const LINK_NAME = 'Link Name'
export const LINK_NAME_PLACEHOLDER = 'Eg: Insurance claim reimbursement'
export const PAYOUT_ID = 'Payout ID'
export const PAYOUT_LINK_ID_LABEL = 'Payout Link ID'
export const PAYOUT_LINK_NAME = 'Payout Link Name'
export const PAYOUT_DETAILS = 'Payout Details'
export const PAYOUT_LINK_BRANDING_LOGO_TOOLTIP = 'This logo will only be used on this payout link. If left empty, your default payout links logo will be used.'
export const PAYOUT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG = 'The title shown to your recipient'
export const PAYOUT_LINK_CREATED_SUCCESSFULLY = 'Payout Link Created Successfully.'
export const PAYOUT_LINK_DETAILS = 'Payout Link Details'
export const PAYOUT_LINK_NAME_HELPER_TEXT = 'Optional name to help you manage your links - not visible to customers'
export const PAYOUT_CONFIGURATIONS = 'Payout Configurations'
export const PAYOUT_LINK = 'Payout Link'
export const PAYOUT_LINK_BRANDING = 'Payout Link Branding'
export const PAYOUT_LINK_TEMPLATE = 'Payout Link Template'
export const PAYOUT_LINKS = 'Payout Links'
export const PAYOUT_LINKS_BRANDING_SUBTITLE = 'Set how your payout links should look.'
export const PAYOUT_LINKS_BRANDING_ADDITIONAL_INSTRUCTIONS = 'If you do not add anything here, payout links will inherit your general branding settings. You will also have the option to override these settings while creating a payout link.'
export const PAYOUT_LINKS_BRANDING_COLORS_INSTRUCTIONS = 'Set the colors that different components in your payout links should use.'
export const PAYOUT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP = 'Override the Claim Payout button background color'
export const PAYOUT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP = 'Override the Claim Payout button text color'
export const PAYOUT_LINKS_TABLE_SUBTITLE = 'All active, expired, and inactive payout links.'
export const SENT = 'Sent'
export const SEND_MONEY_DIRECTLY = 'Send Money Directly'
export const SEND_MONEY_DIRECTLY_SUBTITLE = 'Send money directly to a recipient\'s bank account or card.'
export const SENDING_PAYOUTS = 'Sending Payouts'
export const SHARE_PAYOUT_LINK = 'Share Payout Link'
export const SHARE_A_PAYOUT_LINK = 'Share a Payout Link'
export const SHARE_A_PAYOUT_LINK_SUBTITLE = 'Share a link and let recipients enter their information.'
export const SWIFT_CODE = 'SWIFT Code'
export const RECEIVED = 'Received'
export const RECIPIENT_DETAILS = 'Recipient Details'
export const RECIPIENT_SETTINGS = 'Recipient Settings'
export const RECIPIENT_SETTINGS_SUBTITLE = 'Controls around what types of Recipients can be boarded'
export const REMOVE_FROM_ACCOUNT_BALANCE = 'Remove from account balance'
export const RECEIVING_BANK_DETAILS = 'Receiving Bank Details'
export const UPDATE_ACTIVITY = 'Update Activity'
export const UPDATE_ACCOUNT_BALANCE = 'Update Account Balance'
export const UPDATE_ACCOUNT_BALANCE_WARNING_MESSAGE = 'The account balance for this Application will be updated immediately and cannot be undone.'
export const VIEW_RECIPIENT_DETAILS = 'View Recipient Details'
export const RECIPIENT_ACH_LIMITS_EMPTY_MSG = 'No ACH limits have been set'
export const VIEW_PAYOUT = 'View Payout'
export const VIEW_PAYOUT_LINK = 'View Payout Link'
export const VIEW_PAYOUT_LINK_DETAILS = 'View Payout Link Details'
export const VIEW_ALL_PAYOUTS = 'View All Payouts'
export const WIRE_REFERENCE_NUMBER = 'Wire Reference Number'
export const PUSH_TO_CARD = 'Push to Card'
export const PULL_FROM_CARD = 'Pull from Card'
export const PUSH_TO_ACH = 'Push to ACH'
export const PULL_FROM_ACH = 'Pull from ACH'
export const EDIT_PAYOUT_PROFILE = 'Edit Payout Profile'
export const PUSH_TO_SAME_DAY_ACH  = 'Push to Same-Day ACH'
export const PULL_FROM_SAME_DAY_ACH = 'Pull from Same-Day ACH'

// Mastercard payout configuration constants
export const COUNTRY_ERROR_MSG = 'Default Sender Country must be an ISO 3166-1 alpha-3 country code.'
export const CARD_ACCEPTOR_ID_CODE = 'Card Acceptor ID Code'
export const CARD_ACCEPTOR_TERMINAL_ID = 'Card Acceptor Terminal ID'
export const DEFAULT_PAYMENT_TYPE = 'Default Payment Type'
export const DEFAULT_CURRENCIES = 'Default Currencies'
export const DEFAULT_MCC = 'Default MCC'
export const DEFAULT_SENDER_ACCOUNT_NUMBER = 'Default Sender Account Number'
export const DEFAULT_SENDER_FIRST_NAME = 'Default Sender First Name'
export const DEFAULT_SENDER_LAST_NAME = 'Default Sender Last Name'
export const DEFAULT_SENDER_FULL_NAME = 'Default Sender Full Name'
export const DEFAULT_SENDER_ADDRESS_LINE_1 = 'Default Sender Address Line 1'
export const DEFAULT_SENDER_ADDRESS_LINE_2 = 'Default Sender Address Line 2'
export const DEFAULT_SENDER_CITY = 'Default Sender City'
export const DEFAULT_SENDER_COUNTRY_SUBDIVISION = 'Default Sender Country Subdivision'
export const DEFAULT_SENDER_POSTAL_CODE = 'Default Sender Postal Code'
export const DEFAULT_SENDER_COUNTRY = 'Default Sender Country'
export const DEFAULT_SENDER_COUNTRY_CODE = 'Default Sender Country Code'
export const DEFAULT_SENDER_SUBDIVISION = 'Default Sender Subdivision'
export const MC_DEFAULT_STATEMENT_DESCRIPTOR = 'Statement Descriptor'
export const MASTERCARD_CONFIGURATION = 'Mastercard Configuration'
export const MASTERCARD_ASSIGNED_ID = 'Mastercard Assigned ID'
export const MC_ACCEPTOR_ID_CODE_ERROR_MSG = 'Card Acceptor ID Code must be exactly 15 characters.'
export const MC_DEFAULT_SENDER_COUNTRY_CODE_ERROR_MSG = 'Default Sender Country Code must be between 2 - 3 characters.'

// Visa payout configuration constants
export const VISA_ALLOWED_BUSINESS_APPLICATION_IDS = 'Allowed Business Application IDs '
export const VISA_CARD_ACCEPTOR_ID_CODE = 'Card Acceptor ID Code'
export const VISA_CARD_ACCEPTOR_TERMINAL_ID = 'Card Acceptor Terminal ID'
export const VISA_DEFAULT_SENDER_NAME = 'Default Sender Name'
export const VISA_DEFAULT_SENDER_ADDRESS = 'Default Sender Address'
export const VISA_DEFAULT_SENDER_COUNTY = 'Default Sender County'
export const VISA_DEFAULT_SENDER_CITY = 'Default Sender City'
export const VISA_DEFAULT_SENDER_COUNTRY = 'Default Sender Country'
export const VISA_DEFAULT_SENDER_STATE_CODE = 'Default Sender State Code'
export const VISA_DEFAULT_SENDER_ZIP_CODE = 'Default Sender Zip Code'
export const VISA_CARD_ACCEPTOR_TERMINAL_ID_TOOLTIP_MSG = 'An identifier for the card acceptor.'
export const VISA_ALLOWED_APPLICATION_ID_TOOLTIP_MSG = 'Identifies the program\'s business application type for VisaNet transaction processing. PP is used for person-to-person and FD is used for funds disbursement.'
export const VISA_CONFIGURATION = 'Visa Configuration'
export const UPDATE_APP_CONFIGS_SUCCESS_MSG = 'Application Payout Configurations updated successfully.'
export const VISA_ACCEPTOR_ID_CODE_ERROR_MSG = 'Card Acceptor ID Code must be exactly 15 characters.'
export const VISA_CARD_ACCEPTOR_TERMINAL_ID_ERROR_MSG = 'Card Acceptor Terminal ID must be 8 characters.'
export const VISA_DEFAULT_SENDER_COUNTRY_ERROR_MSG = 'Default Sender Name must be between 1 - 25 characters.'

// payout settings language constants
export const NET_LANGUAGE = 'Net'
export const GROSS_LANGUAGE = 'Gross'
export const GROSS_PROCESSING_VOL = 'Gross Processing Vol.'
export const GROSS_PROCESSING_VOL_TOOLTIP = ' represents the total aggregate sales volume less total refund volume.'
export const TYPE_NET_DESCRIPTION = 'Fees get deducted from the payout to your merchants. The remaining payout amount is sent to your merchants in one Funding Transfer.'
export const TYPE_GROSS_DESCRIPTION = 'Payouts are sent to the merchants in two Funding Transfers: one for fees and one for the remaining payout amount.'
export const GROSS_FREQUENCY_DAILY_DESCRIPTION = 'Automatically close settlements at 10 AM PT.'
export const GROSS_FREQUENCY_MONTHLY_DESCRIPTION = 'Automatically close settlements at 10 AM PT on the selected day of each month.'
export const MANUAL_FREQUENCY_DESCRIPTION = 'Settlements become ready for approval manually on an on-demand basis.'
export const FEE_FREQUENCY_DAILY_DESCRIPTION = 'Settlements get created daily and are available for review at 12 PM PT. Daily Fee Settlements contain every fee that got approved in the past 24 hours.'
export const FEE_FREQUENCY_MONTHLY_DESCRIPTION = 'Settlements get created monthly and are available for review on the day of the month you specify. Monthly Fee Settlements contain every fee that got approved in the past month.'
export const RAIL_STANDARD_DESCRIPTION = 'Funding Transfers submitted before 4 PM PT (on a business day) get deposited into the payout bank account on the following business day'
export const RAIL_FAST_DESCRIPTION = 'Funding Transfers submitted before 8 AM PT (on a business day) get deposited into the payout bank account on the same business day.'
export const FREQUENCY_DAY_TEXT_29 = 'A Settlement will enter the review queue on the 29th day of every month except for February, where it will occur on the last day of the month'
export const FREQUENCY_DAY_TEXT_30 = 'A Settlement will enter the review queue on the 30th day of every month except for February, where it will occur on the last day of the month'
export const FREQUENCY_DAY_TEXT_31 = 'A Settlement will enter the review queue on the 31st or the last day of the month'
export const TRANSFER_SPEED_SUBHEADER = 'Select how fast Funding Transfers to merchants will be delivered after settlements are approved.'
export const FUNDING_TRANSFER_SPEED_LABEL = 'Funding Transfer Speed'
export const FUNDING_TRANSFER_SPEED_SUBLABEL = 'Choose how quickly funding Transfers get submitted for deposit via the ACH network'
export const FUNDING_TRANSFER_DELAY_LABEL = 'Funding Transfer Delay'
export const FUNDING_TRANSFER_DELAY_SUBLABEL = 'Set a delay for Funding Transfers after settlements are approved.'
export const FEE_DELAY_LABEL = 'Fee Delay'
export const FEE_DELAY_SUBLABEL = 'Once a Fee Settlement is approved, configure how many days must pass before the Transfer with fees gets created'
export const FEE_BANK_ACCOUNT_SUBHEADER = 'Select the merchant\'s bank account where fees will be deducted from.'
export const FEE_SCHEDULE_SUPPORTING_TEXT = 'Choose how often a new Settlement (with the fees that accrued) gets created for review and approval.'
export const FEE_SCHEDULE_SUPPORTING_TEXT_2 = 'Note: If fee and payout Settlements are configured to get created at the same, then only one Settlement is created.'
export const FEE_PREFERENCE_TITLE = 'Fee Preference'
export const FEE_PREFERENCE_DESCRIPTION = 'Configure how and when fees will be deducted from the merchant\'s bank account.'
export const FEE_READY_TO_SETTLE_AT_LABEL = 'Fee Ready to Settle at'
export const MONTHLY_TOP_MESSAGE = 'Select the day of each month that a Settlement will be available for review:'
export const SCHEDULE_SUBHEADER = 'Select how often new settlements can be approved.'
export const SCHEDULE_SUBLABEL = 'Choose how often a new payout Settlement gets created for review and approval'
export const DAY_OF_THE_MONTH = 'Day of the month'
export const ADD_NEW_BANK_ACCOUNT = 'Add new bank account'
export const ADD_NEW_BUYER = 'Add New Buyer'
export const ADD_NEW_CARD = 'Add New Card'
export const ADD_NEW_DEVICE = 'Add New Device'
export const ADD_NEW_RECIPIENT = 'Add New Recipient'
export const ADD_RECIPIENT = 'Add Recipient'
export const ADD_ANOTHER_CUSTOM_FIELD = 'Add another custom field'
export const ADD_ANOTHER_ITEM = 'Add another item'
export const ADD_NEW_PAYOUT_METHOD = 'Add New Payout Method'
export const ADD_NEW_PAYMENT_INSTRUMENT = 'Add New Payment Instrument'
export const ADD_MORE_FUNDS = 'Add More Funds'
export const SELECT_PAYOUT_BANK_ACCOUNT = 'Select payout bank account'
export const SELECT_FEE_BANK_ACCOUNT = 'Select fee bank account'
export const SELECT_DAY_OF_MONTH = 'Select day of the month'
export const SAME_DAY_ACH_LABEL = 'Same-day ACH'
export const STARTER = 'Starter'
export const STARTER_PRICING_SUBTITLE = 'Monthly subscription + interchange + transaction processing fees'
export const DAILY_OPTION = 'Daily'
export const MONTHLY_OPTION = 'Monthly'
export const MANUAL_OPTION = 'Manual'
export const MANUAL_REVIEW_REASONS_COMPLIANCE_CHECKS_TOOLTIP = 'Office of Foreign Assets Control (OFAC) check outcomes, for each day, week, or month (depending on the filter chosen), which are part of the Compliance Score.'
export const MANUAL_REVIEW_REASONS_IDENTITY_VERIFICATION_TOOLTIP = 'Know Your Customer (KYC) and Know Your Business (KYB) outcomes for each day, week, or month (depending on the filter chosen), which are a part of the Identity Score.'
export const MANUAL_REVIEW_REASONS_SUMMARY_TOOLTIP = 'Identity Verification Score and Compliance Screening score outcomes for each day, week, or month (depending on the filter chosen), explaining why merchants were sent to manual review.'
export const MAPS = 'Maps'
export const MAP_BY_ADDRESS = 'Map by Address'
export const MAP_DETAILS = 'Map Details'
export const MAP_BY_BUSINESS_NAME = 'Map by Business Name'
export const NEXT_DAY_ACH_LABEL = 'Next-day ACH'
export const NEXT_DAY_ACH_DESCRIPTION = 'Funding Transfers submitted before 4PM PT on a business day will be available on the next business day.'
export const NEXT_DAY_ACH_HELPER_TEXT = 'Funds sent by 10pm EST are available the next business day to the recipient.'
export const SAME_DAY_ACH_HELPER_TEXT = 'Funds sent by 1pm EST are available the same businesss day by 8pm EST to the recipient.'
export const RECONCILIATION_TITLE = 'Reconciliation'
export const RECONCILIATION_SUBHEADER = 'Select when Funding Transfers will be ready to settle.'
export const RECONCILIATION_WARNING_MESSAGE = 'It\'s recommended to switch to Successful Capture or Processor Window to initiate Same-day ACH funding transfer in time.'
export const NO_DELAY_LABEL = 'No delay'
export const SELECT_READY_TO_SETTLE_AT_LABEL = 'Select Ready to Settle at'
export const USE_EXISTING_CARD_LABEL = 'Use existing card on file'
export const USE_AN_EXISTING_PAYMENT_METHOD = 'Use an existing payment method'
export const USE_PAYOUT_BANK_ACCOUNT_LABEL = 'Use payout bank account'
export const UPDATE_PAYOUT_SETTINGS_CONFIRMATION = 'Are you sure you want to update payout settings?'
export const UPDATE_PAYOUT_SETTINGS_CONFIRMATION_DESCRIPTION = 'Any accruing settlements will be automatically closed in order to apply the updated settings immediately.'
export const UPDATE_PAYMENT_DETAILS = 'Update Payment Details'
export const PAYOUT_BANK_ACCOUNT_LABEL = 'Payout bank account'
export const FEE_BANK_ACCOUNT_LABEL = 'Fee bank account'

// Permissions Language Constants
export const VIEW_TEAM_MEMBERS = 'View team members'
export const INVITE_TEAM_MEMBERS = 'Invite team members'
export const CREATE_AND_ASSIGN_ROLES = 'Create and assign roles'
export const RESET_PASSWORD_IN_DASHBOARD = 'Reset password in dashboard'
export const CREATE_MANAGE_2FA_FOR_YOURSELF = 'Create and manage 2FA for yourself'
export const RESET_2FA_FOR_INDIVIDUAL = 'Reset 2FA for an individual'
export const RESET_2FA_FOR_USERS = 'Configure if dashboard account users require 2FA'
export const ENFORCE_2FA_FOR_TEAM = 'Enforce 2FA for a team'
export const MANAGE_API_KEYS = 'Create, view, edit, and delete API keys'
export const VIEW_WEBHOOKS = 'View webhooks (includes webhook log)'
export const MANAGE_WEBHOOKS = 'Create, modify, and delete webhooks'
export const VIEW_ALL_TRANSACTIONS = 'View all transactions'
export const VIEW_ALL_BALANCE_ADJUSTMENTS = 'View All Balance Adjustments'
export const VIEW_PAYMENT_INSTRUMENTS_FOR_TRANSACTIONS = 'View payment instrument details of transactions'
export const CREATE_PAYMENT = 'Create Payment'
export const CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP = 'Select an application that you want to process this payment for.'
export const CREATE_A_PAYMENT = 'Create a Payment'
export const TAKE_PAYMENT = 'Take Payment'
export const CREATE_A_PAYMENT_SUBTITLE = 'Take a payment over the phone, mail, or in person using a payment device.'
export const TAKE_A_PAYMENT = 'Take a Payment'
export const CREATE_A_PAYMENT_LEARN_MORE_LINK = 'Learn more about minimizing chargebacks.'
export const CREATE_PAYMENT_INSTRUMENT = 'Create payment instrument'
export const MANAGE_PAYMENT_INSTRUMENT = 'Modify, disable, or reverify a payment instrument'
export const REFUND_AND_MODIFY_TRANSACTIONS = 'Refund and modify transactions'
export const EXPORT_TRANSACTIONS = 'Export transactions'
export const VIEW_DISPUTES = 'View disputes'
export const MANAGE_DISPUTES = 'Take actions on disputes'
export const VIEW_RISK_PROFILE = 'View risk profile'
export const MANAGE_RISK_PROFILE = 'Edit risk profile'
export const VIEW_FEE_PROFILES = 'View fee profile'
export const VOIDED = 'Voided'
export const VOID_AUTHORIZATION = 'Void Authorization'
export const VOID_AUTHORIZATION_SUCCESS_MSG = 'Authorization successfully voided'
export const VOID_AUTHORIZATIONS = 'Void Authorizations'
export const CREATE_FEE_PROFILES = 'Create new fee profiles'
export const CREATE_PLATFORM_FEE_PROFILE = 'Create fee profile for platform'
export const CREATE_NEW_PAYMENT_INSTRUMENTS_PAYOUTS = 'Create new payment instruments (add a new bank account to a merchant)'
export const VIEW_PAYOUT_SETTINGS = 'View payout settings'
export const MANAGE_PAYOUT_SETTINGS = 'Edit payout settings'
export const VIEW_SETTLEMENTS = 'View settlement information'
export const VIEW_ALL_SETTLEMENTS = 'View All Settlements'
export const MANAGE_SETTLEMENTS = 'Review and approve settlements in review queue'
export const VIEW_MERCHANT_IDENTITY_INFORMATION = 'View merchant identity information'
export const MANAGE_MERCHANT_IDENTITY_INFORMATION = 'Modify merchant identity information'
export const VIEW_MERCHANT_ACCOUNTS = 'View merchant accounts'
export const MODIFY_MERCHANT_ACCOUNTS = 'Modify merchant account information'
export const MANAGE_MERCHANT_ACCOUNTS = 'Review, approve, or reject merchants'
export const MODIFY_APPLICATION_INFORMATION = 'Modify application information'
export const VIEW_APPLICATION_INFORMATION = 'View application information'
export const EDIT_APPLICATION_RISK_SETTINGS = 'Edit application risk settings'
export const VIEW_APPLICATION_RISK_SETTINGS = 'View application risk settings'
export const VIEW_APPLICATION_ACCOUNT_BALANCES = 'View application account balances'
export const EDIT_RECIPIENT_IDENTITY_CONTACT_INFORMATION = 'Edit Recipient Contact Details'
export const VIEW_FRAUD_SCORES = 'View fraud scores'
// funding transfers tooltip messages
// standalone merchants + flex
export const STANDALONE_SM_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Merchant Funding Transfers are deposits to you.'
export const STANDALONE_SP_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Platform Funding Transfers are the fees charged to you for this settlement.'

// not standalone merchants + flex
export const FLEX_SM_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Merchant Funding Transfers are payouts to your merchants.'
export const FLEX_SP_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Platform Funding Transfers are the fees paid to Finix for this settlement.'

// role platform
export const PLATFORM_SM_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Merchant Funding Transfers are payouts to your merchants.'
export const PLATFORM_SP_FUNDING_TRANSFERS_TOOLTIP = 'Settlement Platform Funding Transfers are the fees you collect for this settlement.'

// dashboard types constants
export const SOFTWARE_COMPANY_TYPE_DISPLAY_LABEL = 'Software Platform'
export const SOFTWARE_COMPANY_BADGE_LABEL = 'Finix for Software Platforms'
export const SOFTWARE_COMPANY_INFO = 'Platforms enabling other companies to take payments.'
export const SOFTWARE_COMPANY_EXAMPLE = 'Example: A company that sells their scheduling and payment software to fitness studios.'

export const STANDALONE_MERCHANT_TYPE_DISPLAY_LABEL = 'Individual Business'
export const STANDALONE_MERCHANT_BADGE_LABEL = 'Finix for Businesses'
export const STANDALONE_MERCHANT_INFO = 'Small to large businesses accepting payments for their own goods and services.'
export const STANDALONE_MERCHANT_EXAMPLE = 'Example: A sporting goods company that accepts online and in-person payments for the athletic equipment it sells.'

export const MARKETPLACE_TYPE_DISPLAY_LABEL = 'Online Marketplace'
export const MARKETPLACE_BADGE_LABEL = 'Finix for Marketplaces'
export const MARKETPLACE_INFO = 'An online business that connects buyers and sellers.'
export const MARKETPLACE_EXAMPLE = 'Example: A peer-to-peer website that connects shoppers with sellers of second-hand fashion.'

// lower labels
export const BUSINESS_NAME_LOWER_LABEL = 'Enter the business name exactly as it is recorded in legal documents.'
export const BUSINESS_DBA_LOWER_LABEL = 'Enter the business name used in day-to-day business operations.'
export const BUSINESS_TAX_ID_LOWER_LABEL = 'If you are using a Social Security Number (SSN), it will be assumed you are an Individual Sole Proprietorship.'
export const WEBSITE_LOWER_LABEL = 'If you don\'t have a business website, enter a relevant link that provides more information about your business'
export const BUSINESS_PHONE_NUMBER_LOWER_LABEL = 'Enter a phone number that is only used for business purposes and is not a personal phone number.'
export const SUBDOMAINS_LOWER_LABEL = 'Separate each subdomain with a comma. If there are none, enter \"N/A\".'
export const EIN_VERIFICATION_DOCUMENT_LOWER_LABEL = 'The Form SS-4 of 147C Letter that verifies your EIN issuance'
export const BANK_DOCUMENT_LOWER_LABEL = 'Bank letter or bank statement that confirms the bank account info (must be in USD).'
export const BANK_DOCUMENT_LOWER_LABEL_CAN = 'Bank letter or bank statement that confirms the bank account info (must be in CAD).'
export const ACCEPTING_ONLINE_PAYMENTS_LOWER_LABEL = 'Accepting online payments via website or mobile app'
export const ACCEPTING_IN_PERSON_PAYMENTS_LOWER_LABEL = 'Accepting payments in person via device (e.g. card reader)'
export const ANNUAL_TRANSACTION_COUNT_LOWER_LABEL = 'Total transaction count for the past 12 months'
export const MCC_CODE_LOWER_LABEL_STANDALONE_MERCHANT = 'Provide all MCCs you intend to process transactions for'
export const MCC_CODE_LOWER_LABEL = 'Provide all MCCs your users intend to process transactions for'
export const TAX_ID_LOWER_LABEL = 'Enter a SSN if an ITIN is not available'
export const BUSINESS_ADDRESS_LINE1_LOWER_LABEL = 'Enter an address that is only used for business purposes and is not a personal home address. PO Box, Mail Forwarding, or Registered Agent Address is not allowed.'
export const PERSONAL_ADDRESS_LINE1_LOWER_LABEL = 'Enter a personal home address only. PO Box, Mail Forwarding, or Registered Agent Address is not allowed.'

// device configuration strings
export const ACTIVATE_A_DEVICE = 'Activate a Device'
export const ACTIVATE_A_DEVICE_SUBTITLE = 'Add and activate a new payment device to your dashboard.'
export const ACTIVATE_DEVICE = 'Activate Device'
export const ACTIVATE_LATER = 'Activate Later'
export const ACTIVATION_CODE = 'Activation Code'
export const ACTIVATION_CODE_HELPER_TEXT = 'Displayed on your device and refreshes periodically.'
export const ALLOW_CASH_BACK = 'Allow Cash Back'
export const ALLOW_CASH_BACK_TOOLTIP_MSG = 'Sets whether cashback is allowed with the terminal.'
export const ALLOW_DEBIT = 'Allow Debit'
export const ALLOW_DEBIT_TOOLTIP_MSG = 'Sets whether transactions can be processed on Debit rails. If turned OFF, Debit card transactions will be processed on Credit rails.'
export const ALLOW_GIFT_CARDS = 'Allow Gift Cards'
export const ALLOW_GIFT_CARDS_TOOLTIP_MSG = 'Sets whether gift cards are supported.'
export const ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION = 'Allow Manual Entry of Card Information'
export const ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG = 'Sets if the terminal presents the ability to manually enter card information.'
export const ALLOW_PARTIAL_APPROVALS = 'Allow Partial Approvals'
export const ALLOW_PARTIAL_TRANSACTION_APPROVALS = 'Allow Partial Transaction Approvals'
export const ALLOW_PARTIAL_TRANSACTION_APPROVALS_TOOLTIP_MSG = 'Sets whether the terminal partially approves transactions.'
export const ALLOW_STANDALONE_AUTHORIZATIONS = 'Allow Standalone Authorizations'
export const ALLOW_STANDALONE_AUTHORIZATIONS_TOOLTIP_MSG = 'Sets if the terminal presents the ability to do authorizations via standalone mode.'
export const ALLOW_STANDALONE_REFUNDS = 'Allow Standalone Refunds'
export const ALLOW_STANDALONE_REFUNDS_TOOLTIP_MSG = 'Sets if the terminal presents the ability to do refunds via standalone mode.'
export const ALLOW_STANDALONE_SALES = 'Allow Standalone Sales'
export const ALLOW_STANDALONE_SALES_TOOLTIP_MSG = 'Sets if the terminal presents the ability to do sales via standalone mode.'
export const BYPASS_DEVICE_ON_CAPTURE = 'Bypass Device on Capture'
export const BYPASS_DEVICE_ON_CAPTURE_TOOLTIP_MSG = 'Sets whether the device will be used to capture Authorizations. If turned OFF, the device should be connected to the internet to capture the Authorization.'
export const CASH_BACK_OPTIONS = 'Cash Back Options'
export const CASH_BACK_OPTIONS_TOOLTIP_MSG = 'Set whether the terminal provides cashback options.'
export const CHECK_CONNECTION = 'Check Connection'
export const CHECK_FOR_DUPLICATE_TRANSACTIONS = 'Check for Duplicate Transactions'
export const CHECK_FOR_DUPLICATE_TRANSACTIONS_TOOLTIP_MSG = 'Sets whether the device will check for duplicate transactions.'
export const CONFIRM_DEACTIVATION = 'Confirm Deactivation'
export const CONFIRM_DEVICE_ACTIVATION = 'Confirm Device Activation'
export const CONFIRM_DETAILS_AND_SEND = 'Confirm Details and Send'
export const DEVICE_DETAILS = 'Device Details'
export const DEVICE_ID = 'Device ID'
export const DEVICE_MODEL = 'Device Model'
export const DEVICE_NAME = 'Device Name'
export const DEVICE_NAME_PLACEHOLDER = 'Eg: My Store Reader #1'
export const DEVICE_SERIAL_NUMBER = 'Device Serial Number'
export const DEVICE_SERIAL_NUMBER_PLACEHOLDER = '0000000'
export const DEVICE_STATUS = 'Device Status'
export const DEACTIVATE_DEVICE = 'Deactivate Device'
export const DISPLAY_TIP_ON_RECEIPT = 'Display Tip on Receipt'
export const DISPLAY_TIP_ON_RECEIPT_TOOLTIP_MSG = 'Sets whether the terminal displays tips on the receipt.'
export const EDIT_DEVICE_CONFIGURATION_DETAILS = 'Edit Device Configuration Details'
export const IDLE_MESSAGE = 'Idle Message'
export const IDLE_MESSAGE_EMPTY_MESSAGE = 'No idle message has been added.'
export const IDLE_SCREEN = 'Idle Screen'
export const IDLE_SCREEN_EMPTY_MESSAGE = 'No idle image or message has been added.'
export const IDLE_SCREEN_IMAGE = 'Idle Screen Image'
export const INCLUDE_TIP_ON_RECEIPT = 'Include Tip on Receipt'
export const MARKET_CODE = 'Market Code'
export const MARKET_CODE_TOOLTIP_MSG = 'Sets whether the terminal is retail or restaurant.'
export const NOT_SUPPORTED = 'Not supported'
export const NOT_SUPPORTED_ON_DEVICE_TOOLTIP_MSG = 'Not supported on this device'
export const PROMPT_AMOUNT_CONFIRMATION = 'Prompt Amount Confirmation'
export const PROMPT_AMOUNT_CONFIRMATION_TOOLTIP_MSG = 'Sets if the card holder needs to confirm the amount they will pay.'
export const PROMPT_MANUAL_ENTRY = 'Prompt Manual Entry'
export const PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION = 'Prompt Manual Entry of Card Information'
export const PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG = 'Sets if users can manually enter the card information.'
export const PROMPT_RECEIPT_CONFIRMATION = 'Prompt Receipt Confirmation'
export const PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG = 'Sets if the terminal prompts for printing receipts.'
export const PROMPT_SIGNATURE = 'Prompt Signature'
export const PROMPT_SIGNATURE_TOOLTIP_MESSAGE = 'Sets whether the terminal prompt users for signatures.'
export const REBOOT_DEVICE = 'Reboot Device'
export const SERIAL_NUMBER = 'Serial Number'
export const SIGNATURE_THRESHOLD_AMOUNT = 'Signature Threshold Amount'
export const SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG = 'Sets the amount that will trigger a signature request.'
export const ADD_DEVICE = 'Add Device'
export const ADD_AND_ACTIVATE_DEVICE = 'Add and Activate Device'
export const ADD_AND_ACTIVATE_DEVICE_MODAL_SUBTITLE = 'To activate your device, it must be powered on, connected to the internet, and have the payment app open. You can also choose to add the device to your dashboard now and active it for payment processing later.'
export const ADD_DEVICE_WITHOUT_ACTIVATING = 'Add Device Without Activating'
export const ADD_A_DEVICE = 'Add a Device'
export const ADD_A_DEVICE_SUBTITLE = 'Add and activate a new payment device on your dashboard.'
export const ADD_A_TRIAL_PHASE_LABEL = 'Add a free trial phase'
export const ADD_A_TRIAL_PHASE_TOOLTIP = 'A type of phase during which the account is not charged.'
export const ADD_DEVICE_REVIEW_FORM_SUBTITLE = 'Please review the details below before proceeding'
export const AVAILABLE_RECEIPT_DELIVERY_METHODS = 'Available Receipt Delivery Methods'
export const AUTOMATIC_RECEIPT_DELIVERY_METHODS = 'Automatic Receipt Delivery Methods'
export const CREATE_COMPLIANCE_FORM_MODAL_TITLE = 'Select an application and merchant account for the new compliance form'
export const TIP_COLLECTION_ON_RECEIPT = 'Tip collection on receipt'
export const TIP_COLLECTION_ON_TERMINAL = 'Tip collection on terminal'
export const TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG = 'Sets whether the terminal presents a tipping screen on the transaction.'
export const TIPPING_SETTINGS = 'Tipping Settings'
export const PERCENTAGE_TIP_OPTIONS = 'Percentage Tip Options'
export const OPTIONAL_SETTINGS = 'Optional Settings'
export const PERCENTAGE_TIPPING_THRESHOLD_LABEL = 'If sale is less than'
export const THRESHOLD_FIXED_AMOUNT_OPTIONS_LABEL = 'Fixed amount tip options are:'
export const PERCENTAGE_TIP_THRESHOLD = 'Percentage Tip Threshold'
export const FIXED_AMOUNT_TIP_OPTIONS = 'Fixed Amount Tip options'
export const RECEIPT_SETTINGS = 'Receipt Settings'
export const PRINT = 'Print'
export const TEXT = 'Text'
export const RECEIPT_DELIVERY_METHODS_LABEL = 'Receipt delivery methods shown on terminal *'
export const RECEIPT_DELIVERY_METHODS_TOOLTIP = 'These are the options that will be presented for receipts on the receipt confirmation screen.'
export const SUPPORTED_AUTOMATIC_DELIVERY_METHODS_LABEL = 'Supported automatic receipt delivery type *'
export const SUPPORTED_AUTOMATIC_DELIVERY_METHODS_TOOLTIP = 'These are the types of receipts that will be automatically generated if cardholders select the option. If you are managing your own receipts, do not select any options here.'

// payout/payment links confirmation messages
export const CONFIRMATION_PAGE_SUCCESS_STATUS_TITLE = 'Thanks for your payment.'
export const CONFIRMATION_PAGE_FAILURE_STATUS_TITLE = 'Sorry, your payment failed.'
export const CONFIRMATION_PAGE_EXPIRED_LINK_STATUS_TITLE = 'This link has expired.'
export const CONFIRMATION_PAGE_DEACTIVATED_LINK_STATUS_TITLE = 'This link is no longer active.'
export const CONFIRMATION_PAGE_SUCCESS_STATUS_MSG = 'Your payment was successfully processed.'
export const CONFIRMATION_PAGE_FAILURE_STATUS_MSG = 'Something went wrong with your payment. Please try again or contact the merchant to request a new link.'
export const CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG = 'Please contact the merchant to request a new link.'
export const CONFIRMATION_PAGE_TRANSACTION_SUCCESSFUL_TITLE = 'Transaction successful!'
export const CONFIRMATION_PAGE_PAYOUT_FAILED_TITLE = 'Sorry, your payout was unsuccessful'
export const CONFIRMATION_PAGE_PAYOUT_FAILED_MSG = 'Something went wrong with your payout. Please try again or contact the merchant to request a new link.'
export const CONFIRMATION_PAGE_PAYOUT_COMPLETED_TITLE = 'This transaction has been completed'
export const CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_1 = 'It looks like nothing is due at this time.'
export const CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_2 = 'Please contact the merchant if this does not sound right.'
export const CONFIRMATION_PAGE_TRANSACTION_PENDING_TITLE = 'Transaction pending'
export const CONFIRMATION_PAGE_TRANSACTION_PENDING_MSG = 'You can keep waiting or refresh the page later.'

// receipts
export const RECEIPT_HISTORY = 'Receipt History'
export const CREATE_RECEIPT = 'Create Receipt'
export const SEND_RECEIPT = 'Send Receipt'
export const VIEW_RECEIPT = 'View Receipt'
export const TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS = 'Email a receipt to the buyer upon success'
export const TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF = 'Email a copy of the receipt to yourself'
export const TRANSACTION_CONFIRMATION_NO_RECEIPT = 'However, could not retrieve receipt. Please try again from the transaction details page.'
export const EMAIL_MULTI_INPUT_HELPER_TEXT = 'Type to add one or multiple emails.'
export const PHONE_MULTI_INPUT_HELPER_TEXT = 'Type to add one or multiple numbers.'
export const CREATE_RECEIPT_SUBTITLE = 'A basic receipt will include total amount, buyer billing information, merchant name and contact information. Add more details with the options below.'
export const RECEIPT_CONTENTS = 'Receipt Contents'
export const RECEIPT_DELIVERY = 'Receipt Delivery'
export const ADDITIONAL_RECEIPT_CONTENT_OPTIONS = 'Additional Receipt Content Options'
export const DISPLAY_TOTAL_AMOUNT_BREAKDOWN_TITLE = 'Display total amount breakdown'
export const DISPLAY_ADDITIONAL_DETAILS_TITLE = 'Add additional details to be shown on a receipt for this transaction of '
export const DISPLAY_ITEMIZED_PRODUCT_DETAILS_TITLE = 'Display itemized product details'
export const DISPLAY_PRODUCT_DETAILS = 'Display product name, description and image for each item in this order'
export const SEND_RECEIPT_AUTOMATICALLY = 'Send this receipt automatically upon creation'
export const SEND_TO = 'Send to'
export const SENT_TO = 'Sent to'
export const DELIVERY_METHOD = 'Delivery Method'
export const NO_RECEIPT_DELIVERY_ATTEMPTS = 'No receipt delivery attempts available.'
export const SEND_RECEIPT_MODAL_SUBTITLE = 'Send a digital receipt to one or multiple people.'
export const RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER = 'Recipient email address'
export const RECIPIENT_PHONE_NUMBER_PLACEHOLDER = 'Recipient phone number'
export const RECEIPTS_EMPTY_MESSAGE = 'No past receipt created or sent at this time.'
export const INVALID_EMAIL = 'Please provide a valid email address'
export const INVALID_EMAIL_LIST = 'Please provide at least one valid email address'
export const INVALID_PHONE_NUMBERS_LIST = 'Please enter at least one phone number'
export const INVALID_PHONE_NUMBER = 'Please provide a valid phone number'
export const AT_LEAST_ONE_SEND_METHOD = 'At least one send method must be selected.'
export const NO_DEVICE_SELECTED = 'Please select a device.'
export const CONTACT_INFORMATION = 'Contact Information';
export const RECEIPT_DETAILS_INFO_TEXT = 'The following information is automatically displayed on all receipts:'

// EDD File Language Constants
export const PROCESSING_STATEMENTS_6_MONTHS_FILE_NAME = 'Processing Statements (6 Months)'
export const FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_NAME = 'Financial Statements (2 Years Unaudited)'
export const POLICY_CUSTOMER_SUPPORT_FILE_NAME = 'Customer Support Policy'
export const POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_NAME = 'Complaint and Dispute Resolution Policy'
export const POLICY_PRIVACY_FILE_NAME = 'Privacy Policy'
export const POLICY_ACCEPTABLE_USE_FILE_NAME = 'Acceptable Use Policy'
export const POLICY_AML_BSA_OFAC_FILE_NAME = 'AML / BSA / OFAC Policy'
export const POLICY_GEOLOCATION_CONTROLS_FILE_NAME = 'Geolocation Controls Policy'
export const POLICY_INFORMATION_SECURITY_FILE_NAME = 'Information Security Policy'
export const POLICY_BUSINESS_CONTINUITY_FILE_NAME = 'Business Continuity and Disaster Recovery Plan'
export const ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_NAME = 'Operations Department Organization Structure'
export const ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_NAME = 'Compliance Department Organization Structure'
export const MARKETING_MATERIALS_FILE_NAME = 'Marketing Materials'
export const LEGAL_COMPLIANCE_OPINION_FILE_NAME = 'Legal Compliance Opinion'
export const PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_NAME = 'Partnerships and Third-Party Agreements'
export const DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_NAME = 'Merchant Questionnaire'
export const DIGITAL_WALLET_OPERATING_LICENSE_FILE_NAME = 'Operating License'
export const HEMP_CBD_OPERATING_LICENSE_FILE_NAME = 'Hemp / CBD Operating License'
export const THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_NAME = 'THC Concentration Testing Lab Results'
export const THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_NAME = 'THC Concentration Testing Lab Invoice'
export const LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_NAME = 'Hemp / CBD Product and Service List'
export const HEMP_CBD_TERMS_SERVICE_FILE_NAME = 'Terms of Service'
export const HEMP_CBD_TERMS_SHIPPING_HANDLING_FILE_NAME = 'Shipping and Handling Terms and Disclosures'
export const HEMP_CBD_TERMS_REFUNDS_RETURNS_FILE_NAME = 'Returns and Refunds Terms and Disclosures'
export const POLICY_EMPLOYEE_SCREENING_FILE_NAME = 'Employee Screening Policy'
export const POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_NAME = 'Vendor Training and Compliance Policy'
export const POLICY_PHYSICAL_SECURITY_FILE_NAME = 'Physical Security Policy'
export const POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_NAME = 'Cannabis Product Logistics Policy'
export const HEMP_CBD_AWARENESS_LETTER_BANK_FILE_NAME = 'Bank Hemp / CBD Awareness Letter'
export const HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_NAME = 'Lessor Hemp / CBD Awareness Letter'
export const FORM_8300_FILE_NAME = 'Form 8300'
export const DATA_FLOW_DIAGRAM_FILE_NAME = 'Data Flow Diagram'
export const GAMING_OPERATING_LICENSE_FILE_NAME = 'Gaming Operating License'
export const GAMBLING_CONTROLS_CERTIFICATION_FILE_NAME = 'Gambling Controls Third-Party Certification'
export const FAIRNESS_OF_PLAY_FILE_NAME = 'Fairness of Play (Disclosures, Rules, Limitations)'
export const TERMS_USE_ELIGIBILITY_OFFERS_FILE_NAME = 'Terms of Use, Eligibility, and Promotional Offers'
export const PAYMENTS_TERMS_LIMITATIONS_FILE_NAME = 'Payments Terms and Limitations'
export const RACETRACK_LIST_FILE_NAME = 'Racetrack List'
export const CONSENT_RACETRACK_FILE_NAME = 'Consent from Racetracks'
export const CONSENT_HOST_RACING_ASSOCIATION_FILE_NAME = 'Consent from Host Racing Association'
export const CONSENT_HOST_RACING_COMMISSION_FILE_NAME = 'Consent from Host Racing Commission'
export const CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_NAME = 'Consent from Off Track Racing Commission'
export const OPERATING_LICENSE_MARKETPLACE_FILE_NAME = 'Marketplace License'
export const DIAGRAM_FUNDS_FLOW_FILE_NAME = 'Funds Flow Diagram'
export const SELLER_AGREEMENT_FILE_NAME = 'Seller Agreements'
export const VALUE_PROPOSITION_FILE_NAME = 'Value Proposition'
export const PROVIDED_SERVICES_FILE_NAME = 'Provided Services'
export const LIST_SELLERS_VOLUMES_FILE_NAME = 'List of Sellers and Volumes'
export const NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_NAME = 'Website Checkout Flow'
export const NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_NAME = 'Website Product Content'
export const OPERATING_LICENSE_LENDING_FILE_NAME = 'Lending License'
export const TEMPLATE_LOAN_APPLICATION_FILE_NAME = 'Loan Application Template'
export const TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_NAME = 'Lending / Origination Agreement Template'
export const REQUIRED_FIELD_NOTICE = 'Asterisk (*) denotes a required field'
export const REQUIRED_DISCLOSURES_FEDERAL_FILE_NAME = 'Federally Required Disclosures'
export const REQUIRED_DISCLOSURES_STATE_FILE_NAME = 'State Required Disclosures'
export const TEMPLATE_BORROWER_COMMUNICATIONS_FILE_NAME = 'Communications with Borrowers'
export const POLICY_LENDING_UNDERWRITING_SERVICING_FILE_NAME = 'Policies / Procedures around Lending / Underwriting / Loan Servicing'
export const POLICY_BORROWER_CREDIT_FILE_NAME = 'Policies / Procedures around Borrower Credit Histories and Credit Reporting'
export const POLICY_FAIR_LENDING_FILE_NAME = 'Policies / Procedures around Fair Lending'
export const LIST_LEAD_GENERATORS_FILE_NAME = 'Lists of Lead Generators used by Merchant'
export const LIST_BRANCH_LOCATIONS_FILE_NAME = 'List of Merchant\'s Branch Locations'
export const CREDIT_REPAIR_MERCHANT_QUESTIONNAIRE_FILE_NAME = 'Merchant Questionnaire, Credit Repair'
export const CREDIT_REPAIR_OPERATING_LICENSE_FILE_NAME = 'Credit Repair License'
export const CREDIT_REPAIR_AGREEMENT_FILE_NAME = 'Credit Repair Agreement Template'
export const CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_NAME = 'Credit Repair Customer Communications'
export const CREDIT_REPAIR_TERM_USAGE_FILE_NAME = 'Credit Repair Term Usage'
export const CREDIT_REPAIR_SIGN_UP_FLOW_FILE_NAME = 'Credit Repair Sign Up Flow'
export const CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_NAME = 'Credit Repair Products and Services'
export const CREDIT_REPAIR_LAWS_FILE_NAME = 'Policy / Procedures around Credit Repair Organization Act'
export const TELEMARKETING_LAWS_FILE_NAME = 'Policy / Procedures around Telemarketing Laws'
export const THIRD_PARTY_SERVICE_PROVIDERS_FILE_NAME = 'Policy / Procedures around Third Party Service Providers'
export const FINANCIAL_STATEMENT_2_YEARS_UNAUDITED_FILE_DESCRIPTION = 'Two years of financial statements, including balance sheets, income statements, and cash flow statements.'
export const PROCESSING_STATEMENTS_6_MONTHS_FILE_DESCRIPTION = 'Six months of processing statements showing chargebacks, disputes, and ACH returns'
export const POLICY_CUSTOMER_SUPPORT_FILE_DESCRIPTION = 'Your customer support policy.'
export const POLICY_COMPLAINT_DISPUTE_RESOLUTION_FILE_DESCRIPTION = 'Your compliant and dispute resolution policy.'
export const POLICY_ACCEPTABLE_USE_FILE_DESCRIPTION = 'Your acceptable use policy.'
export const POLICY_PRIVACY_FILE_DESCRIPTION = 'Your privacy policy.'
export const POLICY_AML_BSA_OFAC_FILE_DESCRIPTION = 'Your AML / BSA / OFAC policy.'
export const POLICY_INFORMATION_SECURITY_FILE_DESCRIPTION = 'Your information security policy.'
export const POLICY_GEOLOCATION_CONTROLS_FILE_DESCRIPTION = 'Your geolocatoin controls policy.'
export const POLICY_BUSINESS_CONTINUITY_FILE_DESCRIPTION = 'Your business continuity and disaster recovery plan.'
export const ORGANIZATION_STRUCTURE_OPERATIONS_DEPARTMENT_FILE_DESCRIPTION = 'Organizational chart of your operations department.'
export const ORGANIZATION_STRUCTURE_COMPLIANCE_DEPARTMENT_FILE_DESCRIPTION = 'Organizational chart of your compliance department.'
export const MARKETING_MATERIALS_FILE_DESCRIPTION = 'Copies of your marketing materials (e.g., email, social media, display advertising, etc.).'
export const LEGAL_COMPLIANCE_OPINION_FILE_DESCRIPTION = 'Legal opinion on compliance with applicable laws and regulations you are subject to, including licensing and card network registration requirements.'
export const PARTNERSHIPS_THIRD_PARTY_AGREEMENTS_FILE_DESCRIPTION = 'Copies of your partnerships and third-party agreements.'
export const DIGITAL_WALLET_MERCHANT_QUESTIONNAIRE_FILE_DESCRIPTION = <>Please download, fill out and upload <a href='' target='blank'>this questionnaire</a></>
export const DIGITAL_WALLET_OPERATING_LICENSE_FILE_DESCRIPTION = 'Your license or license(s) for operating in the digital wallet space.'
export const LICENSE_HEMP_CBD_FILE_DESCRIPTION = 'Your license or license(s) for operating in the hemp / CBD space.'
export const THC_CONCENTRATION_TESTING_LAB_RESULTS_FILE_DESCRIPTION = 'Lab results demonstrating your products do not contain THC concentrations of >0.3%.'
export const THC_CONCENTRATION_TESTING_LAB_INVOICE_FILE_DESCRIPTION = 'Invoice of lab results demonstrating your products do not contain THC concentrations of >0.3%.'
export const LIST_HEMP_CBD_PRODUCTS_SERVICES_FILE_DESCRIPTION = 'List of all CBD-related products and services provided, including the product or service\'s name and description, as well as the type of CBD it relates to or includes.'
export const TERMS_SERVICE_FILE_DESCRIPTION = 'Your terms of service.'
export const TERMS_SHIPPING_HANDLING_FILE_DESCRIPTION = 'Your terms and disclosures regarding shipping and handling.'
export const TERMS_REFUNDS_RETURNS_FILE_DESCRIPTION = 'Your terms and disclosures regarding returns and refunds.'
export const POLICY_EMPLOYEE_SCREENING_FILE_DESCRIPTION = 'Your employee screening policy.'
export const POLICY_VENDOR_TRAINING_COMPLIANCE_FILE_DESCRIPTION = 'Your vendor training and compliance policy.'
export const POLICY_PHYSICAL_SECURITY_FILE_DESCRIPTION = 'Your physical security policy.'
export const POLICY_CANNABIS_PRODUCT_LOGISTICS_FILE_DESCRIPTION = 'Your cannabis product logistics policy.'
export const HEMP_CBD_AWARENESS_LETTER_BANK_FILE_DESCRIPTION = 'Letter(s) from your bank(s) demonstrating awareness of your hemp / CBD business.'
export const HEMP_CBD_AWARENESS_LETTER_LESSOR_FILE_DESCRIPTION = 'Letter(s) from your lessor(s) demonstrating awareness of your hemp / CBD business.'
export const FORM_8300_FILE_DESCRIPTION = 'Copy of your 8300 Forms for cash transactions exceeding $10,000.'
export const DIAGRAM_DATA_FLOW_FILE_DESCRIPTION = 'Diagram of your process for moving transaction data, including the relationships between parties, and what data is being passed to whom.'
export const OPERATING_LICENSE_GAMING_FILE_DESCRIPTION = 'Diagram of your process for moving transaction data, including the relationships between parties, and what data is being passed to whom.'
export const CERTIFICATION_GAMBLING_CONTROLS_FILE_DESCRIPTION = 'Third-party certification that your systems for engaging in the internet gambling business are reasonably designed to ensure that your internet gambling business will remain within the licensed or otherwise lawful limits, including with respect to age and location verification.'
export const POLICY_FAIRNESS_PLAY_FILE_DESCRIPTION = 'Disclosures, rules, and limitations related to fairness of play.'
export const TERMS_USE_ELIGIBILITY_OFFERS_FILE_DESCRIPTION = 'General terms of use, eligibility, and promotional offers.'
export const TERMS_PAYMENTS_FILE_DESCRIPTION = 'Payments (both pay-in and pay-out) terms and limitations.'
export const LIST_RACETRACKS_FILE_DESCRIPTION = 'List of all racetracks where betting activities are occuring.'
export const CONSENT_RACETRACK_FILE_DESCRIPTION = 'Consent from racetracks operating within 60 miles of the off-track betting office, or, if there are none, the closest operating racetrack in an adjoining state.'
export const CONSENT_HOST_RACING_ASSOCIATION_FILE_DESCRIPTION = 'Consent from Host Racing Association, including written consent of the host horsemen\'s group.'
export const CONSENT_HOST_RACING_COMMISSION_FILE_DESCRIPTION = 'Consent from Host Racing Commission.'
export const CONSENT_OFF_TRACK_RACING_COMMISSION_FILE_DESCRIPTION = 'Consent from Off Track Racing Commission.'
export const OPERATING_LICENSE_MARKETPLACE_FILE_DESCRIPTION = 'Your license or license(s) for operating in the marketplace space (e.g., Money Transmission License (MTL)).'
export const DIAGRAM_FUNDS_FLOW_FILE_DESCRIPTION = 'Diagram of your marketplace\'s funds flow.'
export const SELLER_AGREEMENT_FILE_DESCRIPTION = 'Copies of legal agreements between you and your sellers, including but not limited to sales agreements, ACH authorization forms, and contracts.'
export const VALUE_PROPOSITION_FILE_DESCRIPTION = 'Description of your marketplace\'s value proposition and market segment being served.'
export const PROVIDED_SERVICES_FILE_DESCRIPTION = 'List of all services offered to sellers.'
export const LIST_SELLERS_VOLUMES_FILE_DESCRIPTION = 'List of your sellers and volumes.'
export const NUTRACEUTICALS_ONLINE_CHECKOUT_FILE_DESCRIPTION = 'Screenshots of your website\'s checkout / purchase flow, including but not limited to your shopping cart page, customer information page, payment page, checkout page, and order confirmation page.'
export const NUTRACEUTICALS_ONLINE_PRODUCT_CONTENT_FILE_DESCRIPTION = 'Screenshots of your website\'s product content, including but not limited to claims supported by clinical studies, product descriptions, product ingredient labels, and FDA disclosures.'
export const OPERATING_LICENSE_LENDING_FILE_DESCRIPTION = 'Your license or license(s) for operating in the lending space.'
export const TEMPLATE_LOAN_APPLICATION_FILE_DESCRIPTION = 'Your loan application template.'
export const TEMPLATE_LENDING_ORIGINATION_AGREEMENT_FILE_DESCRIPTION = 'Your lending / origination agreement template.'
export const REQUIRED_DISCLOSURES_FEDERAL_FILE_DESCRIPTION = 'Copy or copies of your federally-required disclosures (e.g., TILA, MILA).'
export const REQUIRED_DISCLOSURES_STATE_FILE_DESCRIPTION = 'Copy or copies of your state-required disclosures.'
export const TEMPLATE_BORROWER_COMMUNICATIONS_FILE_DESCRIPTION = 'Template(s) used for your customer communications.'
export const POLICY_LENDING_UNDERWRITING_SERVICING_FILE_DESCRIPTION = 'Policy or policies related to lending, underwriting, and loan servicing.'
export const POLICY_BORROWER_CREDIT_FILE_DESCRIPTION = 'Policy or policies related to borrower credit histories and credit reporting.'
export const POLICY_FAIR_LENDING_FILE_DESCRIPTION = 'Policy or policies related to fair lending and equal credit opportunity.'
export const LIST_LEAD_GENERATORS_FILE_DESCRIPTION = 'List of your lead generators.'
export const LIST_BRANCH_LOCATIONS_FILE_DESCRIPTION = 'List of your branch locations.'
export const MERCHANT_QUESTIONNAIRE_CREDIT_REPAIR_FILE_DESCRIPTION = <>Please download, fill out and upload <a href='' target='blank'>this questionnaire</a></>
export const OPERATING_LICENSE_CREDIT_REPAIR_FILE_DESCRIPTION = 'Your license or license(s) for operating in the credit repair space.'
export const TEMPLATE_CREDIT_REPAIR_AGREEMENT_FILE_DESCRIPTION = 'Template(s) used for customer agreenements, including your credit repair agreement(s) and accompanying disclosures, notices, and attachments.'
export const TEMPLATE_CREDIT_REPAIR_CUSTOMER_COMMUNICATIONS_FILE_DESCRIPTION = 'Template(s) used for customer communications.'
export const CREDIT_REPAIR_TERM_USAGE_FILE_DESCRIPTION = 'Copies or screenshots of customer-facing materials that use credit-related terms, such as credit repair, credit score, credit rating, credit history, credit record, credit standing, or credit worthiness, or otherwise suggest customers can "establish", "rebuild", "raise", "improve" their creditworthiness.'
export const CREDIT_REPAIR_SIGN_UP_FLOW_FILE_DESCRIPTION = 'Screenshots of each step in the online process consumers use to sign up for your credit repair program.'
export const CREDIT_REPAIR_PRODUCTS_SERVICES_FILE_DESCRIPTION = 'Copies or screenshots of the products and services provided throughout your credit repair program, such as credit repair products offered and places where customers provide information or make choices to use credit repair services.'
export const POLICY_CREDIT_REPAIR_LAWS_FILE_DESCRIPTION = 'Policy or policies related to the federal Credit Repair Organizations Act (CROA).'
export const POLICY_TELEMARKETING_LAWS_FILE_DESCRIPTION = 'Policy or policies related to telemarkating laws, including any telemarketing scripts used.'
export const POLICY_THIRD_PARTY_SERVICE_PROVIDERS_FILE_DESCRIPTION = 'Applicable laws governing your relationships with third-party service providers.'

// Tranasction Monitoring
export const TRANSACTION_MONITORING_TITLE = 'Transaction Monitoring'
export const TRANSACTION_MONITORING_SUBTITLE_DESCRIPTION = 'Review transactions and payouts that triggered our Anti-Money Laundering rule sets.'
export const TRANSACTION_MONITORING_TARGET = 'Target'
export const TRANSACTION_MONITORING_ALERT_NAME = 'Alert Name'
export const TRANSACTION_MONITORING_ALERT_DETAILS = 'Alert Details'
export const TRANSACTION_MONITORING_APPLICATION_MERCHANT = 'Application / Merchant'
export const TRANSACTION_MONITORING_ACTUAL_VALUE = 'Actual Value'
export const TRANSACTION_MONITORING_THRESHOLD = 'Threshold'
export const TRANSACTION_MONITORING_LOOKBACK = 'Lookback'
export const TRANSACTION_MONITORING_CADENCE = 'Cadence'
export const TRANSACTION_MONITORING_LOOKBACK_START_DATE = 'Lookback Start Date'
export const TRANSACTION_MONITORING_LOOKBACK_END_DATE = 'Lookback End Date'
export const TRANSACTION_MONITORING_OPEN_STATUS = 'Awaiting Review'
export const TRANSACTION_MONITORING_RESOLVED_STATUS = 'Resolved'
export const TRANSACTION_MONITORING_TABLE_EMPTY_MESSAGE = 'No monitoring alerts available'
export const TRANSACTION_MONITORING_RESOURCE_TITLE = 'Monitoring Alerts'
export const TRANSACTION_MONITORING_RESOLVE_MULTIPLE_TITLE = 'Resolve Selected Alerts'
export const TRANSACTION_MONITORING_RESOLVE_MULTIPLE_DESCRIPTION = 'The status of selected alerts will be updated from "Awaiting Review" to "Resolved". And this action cannot be undone.'
export const TRANSACTION_MONITORING_RESOLVE_ONE_TITLE = 'Resolve Alert'
export const TRANSACTION_MONITORING_RESOLVE_ONE_DESCRIPTION = 'The status of this alert will be updated from "Awaiting Review" to "Resolved". And this action cannot be undone.'
export const TRANSACTION_MONITORING_RESOLVE_ACTION = 'Resolve Selected'
export const TRANSACTION_MONITORING_RESOLVE_ALERT_ACTION = 'Resolve Alert'
export const TRANSACTION_MONITORING_RELATED_ALERTS = 'Related Alerts'
export const TRANSACTION_MONITORING_RELATED_PAYMENTS = 'Related Payments'
export const TRANSACTION_MONITORING_RELATED_PAYOUTS = 'Related Payouts'
export const TRANSACTION_MONITORING_RELATED_ACH_RETURNS = 'Related ACH Returns'
export const TRANSACTION_MONITORING_RELATED_REFUNDS = 'Related Refunds'
export const TRANSACTION_MONITORING_RELATED_AUTHORIZATIONS = 'Related Authorizations'
export const TRANSACTION_MONITORING_RELATED_SETTLEMENTS = 'Related Settlements'
export const TRANSACTION_MONITORING_RELATED_DISPUTES = 'Related Disputes'
export const INTERNATIONAL_TRANSACTION_COUNT = 'International Transaction Count'
export const TRANSACTION_MONITORING_DOMESTIC_TRANSACTION_COUNT = 'Domestic Transaction Count'
export const TRANSACTION_MONITORING_INTERNATIONAL_CARD_RATIO = 'International Card Ratio'
export const TRANSACTION_MONITORING_TRANSACTION_COUNT = 'Transaction Count'
export const TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT = 'Total Transaction Amount'
export const TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT = 'Average Transaction Amount'
export const TRANSACTION_MONITORING_TOTAL_SPLIT_PAYOUT_AMOUNT = 'Total Split Payout Amount'
export const TRANSACTION_MONITORING_AVERAGE_SPLIT_PAYOUT_AMOUNT = 'Average Split Payout Amount'
export const TRANSACTION_MONITORING_SPLIT_PAYOUT_COUNT = 'Split Payout Count'
export const TRANSACTION_MONITORING_SETTLEMENT_COUNT = 'Settlement Count'
export const TRANSACTION_MONITORING_UNIQUE_IDENTITY_COUNT = 'Unique Identity Count'
export const TRANSACTION_MONITORING_TOTAL_PAYOUT_AMOUNT = 'Total Payout Amount';
export const TRANSACTION_MONITORING_AVERAGE_PAYOUT_AMOUNT = 'Average Payout Amount'
export const TRANSACTION_MONITORING_PAYOUT_COUNT = 'Payout Count'
export const TRANSACTION_MONITORING_LOOKBACK_DATE = 'Lookback Date'
export const TRANSACTION_MONITORING_ACH_RETURN_COUNT = 'ACH Return Count'
export const TRANSACTION_MONITORING_TOTAL_ACH_RETURN_AMOUNT = 'Total ACH Return Amount'
export const TRANSACTION_MONITORING_AVERAGE_ACH_RETURN_AMOUNT = 'Average ACH Return Amount'
export const TRANSACTION_MONITORING_ACH_RETURN_RATE = 'ACH Return Rate'
export const TRANSACTION_MONITORING_REFUND_COUNT = 'Refund Count'
export const TRANSACTION_MONITORING_TOTAL_REFUND_AMOUNT = 'Total Refund Amount'
export const TRANSACTION_MONITORING_AVERAGE_REFUND_AMOUNT = 'Average Refund Amount'
export const TRANSACTION_MONITORING_REFUND_RATE = 'Refund Rate'
export const TRANSACTION_MONITORING_AUTHORIZATION_COUNT = 'Authorization Count'
export const TRANSACTION_MONITORING_TOTAL_AUTHORIZATION_AMOUNT = 'Total Authorization Amount'
export const TRANSACTION_MONITORING_AVERAGE_AUTHORIZATION_AMOUNT = 'Average Authorization Amount'
export const TRANSACTION_MONITORING_FAILED_AUTHORIZATION_COUNT = 'Failed Authorization Count'
export const TRANSACTION_MONITORING_TOTAL_FAILED_AUTHORIZATION_AMOUNT = 'Total Failed Authorization Amount'
export const TRANSACTION_MONITORING_AVERAGE_FAILED_AUTHORIZATION_AMOUNT = 'Average Failed Authorization Amount'
export const TRANSACTION_MONITORING_FAILED_AUTHORIZATION_RATE = 'Failed Authorization Rate'
export const TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_RATIO = 'Small Dollar Transfer Ratio'
export const TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_COUNT = 'Small Dollar Transfer Count'
export const TRANSACTION_MONITORING_TOTAL_TRANSFER_COUNT = 'Total Transfer Count'
export const TRANSACTION_MONITORING_AVERAGE_TRANSFER_AMOUNT = 'Average Transfer Amount'
export const TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS = 'Application Payout Insights'
export const TRANSACTION_MONITORING_RECIPIENT_PAYOUT_INSIGHTS = 'Recipient Payout Insights'
export const TRANSACTION_MONITORING_PAYMENT_INSIGHTS = 'Payment Insights'
export const TRANSACTION_MONITORING_ACH_RETURN_INSIGHTS = 'ACH Return Insights'
export const TRANSACTION_MONITORING_REFUND_INSIGHTS = 'Refund Insights'
export const TRANSACTION_MONITORING_FAILED_AUTHORIZATION_INSIGHTS = 'Failed Authorization Insights'
export const TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS = 'Settlement Insights'
export const TRANSACTION_MONITORING_TICKET_INSIGHTS = 'Ticket Insights'
export const TRANSACTION_MONITORING_DISPUTE_INSIGHTS = 'Dispute Insights'
export const TRANSACTION_MONITORING_TRANSFER_COUNT = 'Transfer Count'
export const TRANSACTION_MONITORING_SETTLEMENT_AMOUNT = 'Settlement Amount'
export const TRANSACTION_MONITORING_DAYS_SINCE_PRIOR_SETTLEMENT = 'Days Since Prior Settlement'
export const TRANSACTION_MONITORING_SIZE_DIFFERENCE = 'Size Difference'
export const TRANSACTION_MONITORING_SETTLEMENT_TRANSFER_COUNT = 'Settlement Transfer Count'
export const TRANSACTION_MONITORING_DUPLICATE_SETTLEMENT_COUNT = 'Duplicate Settlement Count'
export const TRANSACTION_MONITORING_DISPUTE_COUNT = 'Dispute Count'

export const TRANSACTION_MONITORING_TOTAL_DISPUTE_AMOUNT = 'Total Dispute Amount'
export const TRANSACTION_MONITORING_AVERAGE_DISPUTE_AMOUNT = 'Average Dispute Amount'
export const TRANSACTION_MONITORING_DISPUTE_RATE = 'Dispute Rate'
