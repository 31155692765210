import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import UnderwritingMerchantsC from 'components/Customer/Pages/UnderwritingMerchants/UnderwritingMerchantsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { MERCHANT_UNDERWRITING_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  otherReviewColumnDescriptors,
  onboardingReviewColumnDescriptors,
} from './columnDescriptors'

import {
  ADVERSE_MEDIA_WORKFLOW,
  MERCHANTS,
  SANCTIONS_WORKFLOW,
} from 'constants/underwritingReviewConstants'

import {
  MERCHANT_UNDERWRITING_RESOURCE_TITLE,
  MERCHANT_UNDERWRITING_SUBTITLE_DESCRIPTION,
  ONBOARDING,
  ADVERSE_MEDIA_REVIEWS,
  SANCTIONS_SCREENING,
} from 'constants/language/languageConstants'

import {
  DATE_QUICK_FILTER,
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const tabs = [
  {
    id: 'onboarding',
    name: ONBOARDING,
    component: UnderwritingMerchantsC,
    queries: {
      workflow_name: MERCHANTS,
    },
    props: {
      columnDescriptors: onboardingReviewColumnDescriptors,
      quickFilters: [
        UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
        UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
      ],
    },
  },
  {
    id: 'adverse-media',
    name: ADVERSE_MEDIA_REVIEWS,
    component: UnderwritingMerchantsC,
    queries: {
      workflow_name: ADVERSE_MEDIA_WORKFLOW,
    },
    props: {
      columnDescriptors: otherReviewColumnDescriptors,
      quickFilters: [
        UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
        DATE_QUICK_FILTER,
      ],
    },
  },
  {
    id: 'sanctions-screening',
    name: SANCTIONS_SCREENING,
    component: UnderwritingMerchantsC,
    queries: {
      workflow_name: SANCTIONS_WORKFLOW,
    },
    props: {
      columnDescriptors: otherReviewColumnDescriptors,
      quickFilters: [
        UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
        DATE_QUICK_FILTER,
      ],
    },
  },
]

const UnderwritingMerchantsTabs = () => {
  return (
    <div className='UnderwritingMerchantsTabs'>
      <HeaderV2C
        title={MERCHANT_UNDERWRITING_RESOURCE_TITLE}
        subTitle={MERCHANT_UNDERWRITING_SUBTITLE_DESCRIPTION}
        learnMoreLink={MERCHANT_UNDERWRITING_LEARN_MORE_LINK}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

export default UnderwritingMerchantsTabs
