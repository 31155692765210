import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPaymentInstrumentsModel = ({ data: paymentInstruments }) => {
  const paymentInstrumentModels = map(paymentInstruments, (paymentInstrument) => frontendPaymentInstrumentModel({ data: paymentInstrument }))

  return keyBy(paymentInstrumentModels, 'id')
}

export default frontendPaymentInstrumentsModel
