import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const Device = ({
  isFetching = false,
  headerData = {},
  contextBarData = {},
  actions = [],
  tabs = [],
}) => {
  return (
    <div className='Device'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        actions={actions}
        isFetching={isFetching}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

Device.propTypes = {
  isFetching: PropTypes.bool,
  headerData: PropTypes.object,
  deviceId: PropTypes.string,
  contextBarData: PropTypes.object,
  actions: PropTypes.array,
  tabs: PropTypes.array,
}

export default Device
