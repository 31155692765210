import getComplianceFormTemplateAPI from 'api/dashboard/get/getComplianceFormTemplateAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getPlatformAPI from 'api/finix/get/getPlatformAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * getComplianceFormTemplateO ({
  id,
  credentials,
}) {
  const { data: response } = yield getComplianceFormTemplateAPI({ id, credentials })
  const linkedTo = get(response, 'linked_to')
  const linkedType = get(response, 'linked_type')
  const linkedTypeIsApplication = linkedType === 'APPLICATION'

  const { data: linkedToResponse } = linkedTypeIsApplication ? yield getApplicationAPI({
    id: linkedTo,
    credentials,
  }) : yield getPlatformAPI({
    id: linkedTo,
    credentials,
  })

  const complianceFormTemplate = merge({}, response, {
    [linkedTypeIsApplication ? 'application' : 'platform']: linkedToResponse,
  })

  return createSuccessResponse({
    data: complianceFormTemplate,
  })
}

export default co.wrap(getComplianceFormTemplateO)
