import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutPlanForm from './PayoutPlanForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import selectOption from 'utilities/forms/selectOption'
import map from 'lodash/map'
import concat from 'lodash/concat'
import { getAvailableProcessors } from 'constants/processorConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const availableProcessorsOptions = map(getAvailableProcessors(), processor => selectOption(processor, processor))

  return {
    credentials,
    availableProcessorsOptions: concat(selectOption('Select Processor', ''), availableProcessorsOptions),
  }
}

class PayoutPlanFormC extends Component {
  render() {
    return (
      <PayoutPlanForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PayoutPlanFormC)
