import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingOwnerPageSection from './UnderwritingOwnerPageSection'
import Address from 'components/Customer/Shared/Display/Address/Address'
import DisplayUnmaskC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import getUnderwritingSubjectUnmaskAPI from 'api/finix/get/getUnderwritingSubjectUnmaskAPI'
import hasPermission from 'utilities/hasPermission'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import formatDateObject from 'utilities/formatters/formatDateObject'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatPhone from 'utilities/forms/format/formatPhone'
import getMany from 'utilities/get/getMany'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { CAN } from 'constants/countryConstants'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

import {
  ADDRESS,
  DATE_OF_BIRTH,
  EMAIL_ADDRESS,
  PERSONAL_TAX_ID,
  PHONE_NUMBER,
  SIN,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(props, 'isFetching')
  const owner = get(props, 'owner')
  const subjectId = get(props, 'subjectId')
  const credentials = getCurrentCredentials(state)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials })

  const [
    firstName,
    lastName,
    title,
    principalPercentageOwnership,
    dateOfBirth,
    taxId,
    email,
    phone,
    addressLine1,
    addressLine2,
    addressCity,
    addressRegion,
    addressCountry,
    addressPostalCode,
    reports,
    subjectIndex,
  ] = getMany(owner, [
    'first_name',
    'last_name',
    'title',
    'principal_percentage_ownership',
    'date_of_birth',
    'tax_id',
    'email',
    'phone',
    'address.line1',
    'address.line2',
    'address.city',
    'address.region',
    'address.country',
    'address.postal_code',
    'reports',
    'subjectIndex',
  ])

  const formattedBusinessAddress = {
    line1: addressLine1,
    line2: addressLine2,
    city: addressCity,
    region: addressRegion,
    country: addressCountry,
    postalCode: addressPostalCode,
  }

  const ownerDataSection = [
    {
      type: 'data',
      data: [
        { label: DATE_OF_BIRTH, value: formatDateObject({ date: dateOfBirth }) },
        {
          label: addressCountry === (CAN || 'CA') ? SIN : PERSONAL_TAX_ID,
          value: (showUnmaskAction && taxId) ? (
            <DisplayUnmaskC
              id={subjectId}
              api={getUnderwritingSubjectUnmaskAPI}
              feKey={taxId}
              beKey={`owners[${subjectIndex}].tax_id`}
              defaultValue={taxId}
            />
          ) : taxId,
        },
      ],
    },
    {
      type: 'data',
      data: [
        { label: EMAIL_ADDRESS, value: email },
        { label: PHONE_NUMBER, value: formatPhone(phone) },
      ],
    },
    {
      type: 'data',
      data: [
        { label: ADDRESS, value: <Address address={formattedBusinessAddress} /> },
      ],
    },
  ]

  const ownerDataSectionTitle = firstName && lastName ? `${capitalize(firstName)} ${capitalize(lastName)}` : undefined
  const ownerDataSectionAsideTitle = `(${title}: ${principalPercentageOwnership}%)`

  return {
    isFetching,
    ownerDataSection,
    ownerDataSectionTitle,
    ownerDataSectionAsideTitle,
    reports,
  }
}

class UnderwritingOwnerPageSectionC extends Component {
  render() {
    return (
      <UnderwritingOwnerPageSection
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UnderwritingOwnerPageSectionC)
