import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_PROCESSOR,
} from 'constants/apiConstants'

const getApplicationProcessorAPI = ({ values, credentials, meta }) => {
  const {
    applicationId,
    processor,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: APPLICATION_PROCESSOR({ applicationId, processor }),
    service: APPLICATIONS,
  })
}

export default getApplicationProcessorAPI
