import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import IdentityDisputesC from 'components/Customer/Pages/Identity/IdentityDisputes/IdentityDisputesC'
import IdentityACHReturnsC from 'components/Customer/Pages/Identity/IdentityACHReturns/IdentityACHReturnsC'

import {
  DISPUTES,
  ACH_RETURNS,
} from 'constants/language/languageConstants'

class IdentityExceptionsTabs extends Component {
  render() {
    const {
      identityId,
    } = this.props

    const tabs = [
      {
        id: 'disputes',
        name: DISPUTES,
        component: IdentityDisputesC,
        props: {
          identityId,
        },
      },
      {
        id: 'ach-returns',
        name: ACH_RETURNS,
        component: IdentityACHReturnsC,
        props: {
          identityId,
        },
      },
    ]

    return (
      <div className='IdentityExceptionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default IdentityExceptionsTabs
