import frontendFeeProfileRuleModel from 'utilities/create/models/frontendFeeProfileRuleModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendFeeProfileRulesModel = ({ data: feeProfileRules }) => {
  const feeProfileRulesModels = map(feeProfileRules, (feeProfileRule) => frontendFeeProfileRuleModel({ data: feeProfileRule }))

  return keyBy(feeProfileRulesModels, 'entityId')
}

export default frontendFeeProfileRulesModel
