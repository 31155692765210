import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getFilesAPI from 'api/dashboard/get/getFilesAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getOnboardingFormsAPI from 'api/finix/get/getOnboardingFormsAPI'
import getOnboardingFormDataAPI from 'api/finix/get/getOnboardingFormDataAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import frontendFilesModel from 'utilities/create/models/frontendFilesModel'
import getMany from 'utilities/get/getMany'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

// this orchestration file is used to pull an identity with the proper data for the underwriting view page (should not be used for the list page!)
function * getUnderwritingIdentityO ({
  id,
  credentials,
}) {
  const { data: response } = yield getIdentityAPI({ id, credentials })
  const links = getResponseLinks(response)

  const [
    applicationLink,
    merchantsLink,
  ] = getMany(links, [
    'application',
    'merchants',
  ])

  const [
    { data: application },
    { data: merchantsResponse },
  ] = yield [
    hasPartnerAccess({ credentials }) ? getPaymentsAPI({
      absolutePath: applicationLink,
      credentials,
    }) : {},
    getPaymentsAPI({ absolutePath: merchantsLink, credentials }),
  ]

  const merchants = get(merchantsResponse, '_embedded.merchants')
  const merchantsModels = frontendMerchantsModel({ data: merchants })

  const identityFilesResponse = yield getFilesAPI({
    queries: {
      identity_id: id,
      limit: 1000,
    },
    credentials,
  })

  const identityFiles = get(identityFilesResponse, 'data._embedded.files', [])
  const applicationModel = frontendApplicationModel({ data: application })

  // get onboarding form
  const onboardingFormsResponse = yield getOnboardingFormsAPI({ queries: { identity_id: id, limit: 1 }, credentials })
  const onboardingFormId = get(onboardingFormsResponse, 'data._embedded.onboarding_forms[0].id')
  const { data: onboardingForm } = yield onboardingFormId ? getOnboardingFormDataAPI({ id: onboardingFormId, credentials }) : {}
  const onboardingFormModel = onboardingForm ? frontendOnboardingFormDataModel({ data: onboardingForm }) : {}

  const identity = merge({}, response, {
    application: applicationModel,
    merchants: merchantsModels,
    files: frontendFilesModel({ data: identityFiles }),
    onboardingForm: onboardingFormModel,
  })

  return createSuccessResponse({
    data: [identity],
  })
}

export default co.wrap(getUnderwritingIdentityO)
