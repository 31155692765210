import './DictionaryListV2S.scss'
import React, { Fragment } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import map from 'lodash/map'
import isFunction from 'lodash/isFunction'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'

const DictionaryListV2 = ({
  data: terms = [],
  displayDataVertical,
  oneColumn,
  className = '',
}) => {
  return (
    <div className={`DictionaryListV2 ${className}`}>
      { map(terms, ({ label, value = '-', path, formatter, action, condition = true, tooltip = '', tooltipPosition = 'default' }) => {
        const ValueComponent = formatter ? formatter({ value }) : (value || '-')
        const InvokeValueComponent = isFunction(ValueComponent) ? ValueComponent() : ValueComponent
        const displayComponent = path ? <Link className='text-link' to={path}>{InvokeValueComponent}</Link> : InvokeValueComponent
        const labelCamelCase = camelCase(label)
        const parentClassName = displayDataVertical ? 'vertical' : `flex ${!oneColumn ? 'unset' : ''} horizontal flex-start parent`
        const labelClassName = displayDataVertical ? `flex ${labelCamelCase}Label label label-2` : `${labelCamelCase}Label label label-2`
        const labelValueClassName = `${kebabCase(label)}-value value-container label-2 ${value === '-' ? 'null-value' : ''}`

        return condition ? (
          <Fragment key={label}>
            <div className={parentClassName}>
              <div className={labelClassName}>
                <label>{label}</label>
              </div>
              <div className={labelValueClassName} onClick={action}>
                <span className={action ? 'action' : ''}>{displayComponent}</span>
                { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
              </div>
            </div>
          </Fragment>
        ) : undefined
      })}
    </div>
  )
}

DictionaryListV2.propTypes = {
  data: PropTypes.array,
  displayDataVertical: PropTypes.bool,
  oneColumn: PropTypes.bool,
  className: PropTypes.string,
}

export default DictionaryListV2
