import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const isAdministratorRole = (credentials = getCurrentCredentials()) => {
  const isSystemRole = get(credentials, 'dashboardUserRoleType') === 'SYSTEM'
  const isRoleAdministrator = get(credentials, 'dashboardUserRoleName') === 'Administrator'

  return isSystemRole && isRoleAdministrator
}

export default isAdministratorRole
