import './CreateRoleS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import CreateRoleFormC from 'components/Customer/Forms/CreateRoleForm/CreateRoleFormC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const CreateRole = ({
  entityType = '',
  entityId = '',
  roleId = '',
  headerTitle = '',
  buttonText = '',
  initialRoleValues = {},
  submitFunction = () => {},
}) => {
  return (
    <div className='CreateRole'>
      <ContextBar />

      <HeaderV2C
        title={headerTitle}
      />

      <CreateRoleFormC
        entityType={entityType}
        entityId={entityId}
        roleId={roleId}
        initialRoleValues={initialRoleValues}
        buttonText={buttonText}
        submitFunction={submitFunction}
      />
    </div>
  )
}

CreateRole.propTypes = {
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  roleId: PropTypes.string,
  headerTitle: PropTypes.string,
  buttonText: PropTypes.string,
  initialRoleValues: PropTypes.object,
  submitFunction: PropTypes.func,
}

export default CreateRole
