import removeUndefined from 'utilities/remove/removeUndefined'
import formatAddress from 'utilities/formatters/formatAddress'
import formatDate from 'utilities/formatters/formatDate'
import formatIssuerCountry from 'utilities/formatters/formatIssuerCountry'
import getMany from 'utilities/get/getMany'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { normalizeAddress } from 'constants/normalizationConstants'
import { countryNameByCountryCode } from 'constants/countryConstants'
import get from 'lodash/get'

import {
  BE_UNKNOWN,
  BE_TO_FE_PAYMENT_INSTRUMENT_TYPE,
} from 'constants/paymentInstrumentConstants'

import {
  MANUAL_OPTION,
  PLAID,
} from 'constants/language/languageConstants'

const frontendPaymentInstrumentModel = ({ data: paymentInstrument }) => {
  const [
    id,
    bin,
    instrumentType,
    createdAt,
    updatedAt,
    fingerprint,
    settlementEnabled,
    brand,
    lastFour,
    expirationMonth,
    expirationYear,
    applicationId,
    identityId,
    securityCodeVerification,
    addressVerification,
    cardType,
    address,
    name,
    bankCode,
    maskedAccountNumber,
    currency,
    accountType,
    tags,
    addressCountry, // only returns for Payment Card Instruments
    country, // only returns for Bank Account Instruments
    enabled,
    issuerCountry,
    createdVia,
    transitNumber,
    institutionNumber,
    thirdParty,
    thirdPartyToken,
  ] = getMany(paymentInstrument, [
    'id',
    'bin',
    'instrument_type',
    'created_at',
    'updated_at',
    'fingerprint',
    'settlement_enabled',
    'brand',
    'last_four',
    'expiration_month',
    'expiration_year',
    'application',
    'identity',
    'security_code_verification',
    'address_verification',
    'card_type',
    'address',
    'name',
    'bank_code',
    'masked_account_number',
    'currency',
    'account_type',
    'tags',
    'address.country',
    'country',
    'enabled',
    'issuer_country',
    'created_via',
    'transit_number',
    'institution_number',
    'third_party',
    'third_party_token',
  ])

  const expirationDate = expirationMonth && expirationYear ? `${expirationMonth}/${expirationYear}` : undefined
  const maskedFullCardNumber = bin && lastFour ? `${bin} •••••• ${lastFour}` : undefined
  const displayAddress = formatAddress({ address })
  const displayBrand = snakeCaseToTitleCase({ key: brand })
  const displayEnabled = enabled ? 'Enabled' : 'Disabled'
  const displayIssuerCountry = formatIssuerCountry({ issuerCountry })
  const displayCreatedVia = convertSnakeToSentenceCase(createdVia)

  const information = removeUndefined({
    name,
    brand,
    lastFour,
    bankCode,
    maskedAccountNumber,
    expirationDate,
  })

  return removeUndefined({
    id,
    bin,
    type: get(BE_TO_FE_PAYMENT_INSTRUMENT_TYPE, instrumentType, BE_UNKNOWN),
    instrumentType,
    displayInstrumentType: snakeCaseToTitleCase({ key: instrumentType }),
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    fingerprint,
    brand,
    displayBrand,
    lastFour,
    settlementEnabled,
    expirationMonth,
    expirationYear,
    expirationDate,
    applicationId,
    identityId,
    securityCodeVerification,
    addressVerification,
    displayAddressVerification: convertSnakeToSentenceCase(addressVerification),
    displaySecurityCodeVerification: convertSnakeToSentenceCase(securityCodeVerification),
    cardType: snakeCaseToTitleCase({ key: cardType }),
    maskedAccountNumber,
    maskedFullCardNumber,
    address: normalizeAddress(address),
    displayAddress,
    name,
    bankCode,
    currency,
    accountType,
    displayAccountType: convertSnakeToSentenceCase(accountType),
    tags,
    information,
    addressCountry,
    country,
    displayCountry: countryNameByCountryCode[country],
    enabled,
    displayEnabled,
    displayIssuerCountry,
    displayCreatedVia,
    transitNumber,
    institutionNumber,
    canadianRoutingNumber: transitNumber && institutionNumber ? `0${institutionNumber}${transitNumber}` : undefined,
    thirdParty,
    thirdPartyToken,
    connectionType: thirdPartyToken ? PLAID : MANUAL_OPTION,
  })
}

export default frontendPaymentInstrumentModel
