import paymentsAPI from 'utilities/api/paymentsAPI'
import { SETTLEMENT_TRANSFERS } from 'constants/apiConstants'
import get from 'lodash/get'

const getSettlementTransfersAPI = ({ values, queries, credentials, id, meta }) => {
  const settlementId = id || get(values, 'settlementId')

  return paymentsAPI.get({
    meta,
    path: SETTLEMENT_TRANSFERS({ settlementId }),
    credentials,
    queries,
    service: SETTLEMENT_TRANSFERS,
  })
}

export default getSettlementTransfersAPI
