import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentityUploaderConfirmationForm from 'components/Admin/Forms/IdentityUploaderConfirmationForm/IdentityUploaderConfirmationForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import printCSV from 'utilities/printCSV'
import { FETCHING } from 'constants/reducerConstants'
import { POST_IDENTITY_UPLOAD_F_CANCEL } from 'constants/flowConstants'

const mapStateToProps = (state) => {
  const [
    totalCount,
    isUploading,
    totalSucceeded = 0,
    totalFailed = 0,
    csvExport,
  ] = getMany(state, [
    'identityUploaderR.totalCount',
    `identityUploaderR.${FETCHING}`,
    'identityUploaderR.totalSucceeded',
    'identityUploaderR.totalFailed',
    'identityUploaderR.export',
  ])

  const exportCSV = () => printCSV(csvExport, 'identity_uploader_export')

  return {
    isUploading,
    totalCount,
    totalSucceeded,
    totalFailed,
    exportCSV,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancelIdentityUpload: () => dispatch({ type: POST_IDENTITY_UPLOAD_F_CANCEL }),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class IdentityUploaderConfirmationFormC extends Component {
  componentWillUnmount() {
    const { cancelIdentityUpload, isUploading } = this.props

    if (isUploading) cancelIdentityUpload()
  }

  render() {
    return (
      <IdentityUploaderConfirmationForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityUploaderConfirmationFormC)
