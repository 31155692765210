import React, { Component } from 'react'
import { connect } from 'react-redux'
import FlashNotification from './FlashNotification'
import getMany from 'utilities/get/getMany'
import sleep from 'utilities/sleep'
import get from 'lodash/get'

import { DEFAULT_TIME } from 'constants/flashNotificationConstants'
import { HIDE_FLASH_NOTIFICATION } from 'constants/flowConstants'

import {
  ALERT_ICON,
  TOOLTIP_ICON,
  TIMES_OCTAGON,
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

const notificationTypeToIconMap = {
  SUCCESS: CHECK_CIRCLE_ICON,
  FAILURE: TIMES_OCTAGON,
  WARNING: ALERT_ICON,
  MESSAGE: TOOLTIP_ICON,
}

const mapStateToProps = (state) => {
  const [
    type,
    message,
    time,
    hide = true,
    title,
  ] = getMany(state, [
    'flashNotificationsR.type',
    'flashNotificationsR.message',
    'flashNotificationsR.time',
    'flashNotificationsR.hide',
    'flashNotificationsR.title',
  ])

  const icon = get(state, 'flashNotificationsR.icon', notificationTypeToIconMap[type])

  return {
    type,
    message,
    time,
    hide,
    title,
    icon,
  }
}

class FlashNotificationC extends Component {
  componentDidUpdate (prevProps) {
    const {
      time = DEFAULT_TIME,
      hide,
      dispatch,
    } = this.props

    const {
      hide: prevHide,
    } = prevProps

    if (hide === false && prevHide === true) {
      sleep(time).then(() => dispatch({ type: HIDE_FLASH_NOTIFICATION }))
    }
  }

  closeFlashNotification = () => {
    const {
      dispatch,
    } = this.props

    dispatch({ type: HIDE_FLASH_NOTIFICATION })
  }

  render() {
    const {
      closeFlashNotification,
    } = this

    return (
      <FlashNotification
        closeFlashNotification={closeFlashNotification}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(FlashNotificationC)
