import { fieldEmpty } from 'utilities/validate'

const validateResendEmailForm = (values) => {
  const {
    hCaptchaToken,
  } = values

  return {
    hCaptchaToken: fieldEmpty(hCaptchaToken),
  }
}

export default validateResendEmailForm
