import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateAllPermissionsForm from 'components/Customer/Forms/UpdateAllPermissionsForm/UpdateAllPermissionsForm'
import { getUpdateOptionsByRole } from 'constants/userPermissions'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const role = get(props, 'user.role')
  const updateOptions = getUpdateOptionsByRole(role)
  const initialValues = { role }

  return {
    credentials,
    updateOptions,
    initialValues,
  }
}

class UpdateAllPermissionsFormC extends Component {
  closeModal = () => {
    const { dispatch } = this.props

    dispatch(hideModalAction())
  }

  render() {
    const { closeModal } = this

    return (
      <UpdateAllPermissionsForm
        closeModal={closeModal}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UpdateAllPermissionsFormC)
