import './CustomFieldArrayS.scss'
import React from 'react'
import { FieldArray, Field } from 'redux-form'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import map from 'lodash/map'
import isString from 'lodash/isString'
import size from 'lodash/size'

const component = ({
  fields = [],
  addButtonLabel,
  fieldOptions,
  disabled,
  maxFields,
}) => (
  <div>
    { fields.map((field, index) => (
      <div className='field flex space-between' key={field}>
        <div className='flex wrap field-inputs'>
          {
            map(fieldOptions, ({ label, placeholder, value, fieldComponent, options, props }) => {
              return (
                <div className='field-input' key={`${field}.${value}`}>
                  <Field
                    name={`${field}.${value}`}
                    label={label}
                    placeholder={placeholder}
                    component={fieldComponent}
                    options={options}
                    {...props}
                  />
                </div>
              )
            })
          }
        </div>

        <div className='remove-field-button flex items-flex-end' onClick={() => fields.remove(index)}>
          <span>Remove</span>
        </div>
      </div>
    ))}

    <Button className='add-field-button' disabled={disabled || size(fields) >= maxFields} label={`Add ${addButtonLabel}`} onClick={() => fields.push({})} variant='secondary' />
  </div>
)


const CustomFieldArray = ({
  input,
  label,
  disabled = false,
  meta: {
    error: errors,
    warning,
  },
  fieldOptions,
  addButtonLabel,
  maxFields,
}) => {
  const { name } = input
  const errorJSX = isString(errors) && <span className='error'>{errors}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>

  return (
    <div className='CustomFieldArray'>
      <div className='custom-field-array-container'>
        <div className='custom-field-array-label'>{label}</div>

        <FieldArray
          name={name}
          component={component}
          addButtonLabel={addButtonLabel}
          disabled={disabled}
          fieldOptions={fieldOptions}
          maxFields={maxFields}
        />

      </div>

      { errorJSX || warningJSX }
    </div>
  )
}

CustomFieldArray.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  fieldOptions: PropTypes.array,
  addButtonLabel: PropTypes.string,
  maxFields: PropTypes.number,
}

export default CustomFieldArray
