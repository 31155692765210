import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import { PLATFORM_DATA, FINIX } from 'constants/platformConstants'

const getCurrentPlatform = () => {
  const platformName = getCurrentPlatformName()

  return PLATFORM_DATA[platformName] || PLATFORM_DATA[FINIX]
}

export default getCurrentPlatform
