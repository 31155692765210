import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_FEE_PROFILE_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_FEE_PROFILE_SETTINGS } from 'constants/amplitudeConstants'

const postFeeProfileSettingsRequest = ({
  credentials,
  values,
  applicationId,
}) => createAction({
  type: POST_FEE_PROFILE_SETTINGS_F_REQUEST,
  credentials,
  values,
  id: applicationId,
  meta: {
    closeModal: true,
    actionId: sendAmplitudeActionEvent(UPDATE_FEE_PROFILE_SETTINGS, {}),
  },
})

export default postFeeProfileSettingsRequest
