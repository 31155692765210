import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISPUTE_CREATE } from 'constants/apiConstants'

const postDisputeAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: DISPUTE_CREATE,
  })
}

export default postDisputeAPI
