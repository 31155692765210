import paymentsAPI from 'utilities/api/paymentsAPI'
import { APPLICATIONS } from 'constants/apiConstants'

const postApplicationAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    values,
    credentials,
    path: APPLICATIONS,
    meta,
    service: APPLICATIONS,
  })
}

export default postApplicationAPI
