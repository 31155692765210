import selectOption from 'utilities/forms/selectOption'
import { REQUIRES_UPLOAD} from 'constants/fileConstants'

import {
  CHECK_CIRCLE_ICON,
  CIRCLE_MINUS_ICON,
  CLOCK_ICON,
  TIMES_CIRCLE_ICON,
  DOT_CIRCLE,
  FILE_CHECK,
  ALERT_ICON,
} from 'constants/iconConstants'

export const SUCCESS = 'SUCCESS'
export const DANGER = 'DANGER'
export const WARNING = 'WARNING'
export const GOLD_WARNING = 'GOLD_WARNING'
export const NOTIFICATION = 'NOTIFICATION'
export const DEFAULT = 'DEFAULT'
export const EXPIRED = 'EXPIRED'

export const SUCCEEDED = 'SUCCEEDED'
export const SUCCEEDED_COMPLIANCE = 'SUCCEEDED_COMPLIANCE'
export const PENDING = 'PENDING'
export const FAILED = 'FAILED'
export const CANCELED = 'CANCELED'
export const UNKNOWN = 'UNKNOWN'
export const DONE = 'DONE'
export const IN_PROGRESS = 'IN_PROGRESS'
export const ALL = 'ALL'
export const UPLOADED = 'UPLOADED'
export const UNDER_REVIEW = 'UNDER_REVIEW'
export const WITHDRAWN = 'WITHDRAWN'
export const ADDITIONAL_VERIFICATION_REQUIRED = 'ADDITIONAL_VERIFICATION_REQUIRED'
export const UNATTEMPTED = 'UNATTEMPTED'
export const SALES_REVIEW = 'SALES_REVIEW'
export const RETURNED = 'RETURNED'

export const INQUIRY = 'INQUIRY'
export const WON = 'WON'
export const LOST = 'LOST'

export const YES = 'YES'
export const NO = 'NO'

export const ACTIVE = 'ACTIVE'
export const INACTIVE = 'INACTIVE'

export const COMPLETE = 'COMPLETE'
export const INCOMPLETE = 'INCOMPLETE'
export const COMPLETED = 'COMPLETED'
export const OVERDUE = 'OVERDUE'
export const INVALID = 'INVALID'

export const ENABLED = 'ENABLED'
export const DISABLED = 'DISABLED'

export const SUBMITTED = 'SUBMITTED'
// export const SUBMITTING = 'SUBMITTING'
// export const STAGED = 'STAGED'
// export const STAGING = 'STAGING'
// export const UNKNOWN = 'UNKNOWN'
// export const REVERTED = 'REVERTED'

// live account application statuses
export const IN_PROGRESS_BE_VALUE = 'IN_PROGRESS'
export const IN_REVIEW_BE_VALUE = 'IN_REVIEW'
export const WITHDRAWN_BE_VALUE = 'WITHDRAWN'
export const ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE = 'ADDITIONAL_VERIFICATION_REQUIRED'
export const REJECTED_BE_VALUE = 'REJECTED'
export const APPROVED_BE_VALUE = 'APPROVED'
export const SALES_REVIEW_BE_VALUE = 'SALES_REVIEW'

// dispute response states
export const NEEDS_RESPONSE = 'NEEDS_RESPONSE'
export const RESPONDED = 'RESPONDED'
export const ACCEPTED = 'ACCEPTED'
export const NO_RESPONSE_ALLOWED = 'NO_RESPONSE_ALLOWED'
export const UNKNOWN_RESPONSE_STATE = 'UNKNOWN'

// sift rule decision statuses
export const BLOCKED = 'BLOCKED'
export const WATCHED = 'WATCHED'

export const disputeStatusMap = {
  [WON]: SUCCEEDED,
  [PENDING]: WARNING,
  [LOST]: FAILED,
  [INQUIRY]: WARNING,
  [CANCELED]: DEFAULT,
}

export const disputeEvidenceStatusMap = {
  [SUCCEEDED]: SUCCEEDED,
  [PENDING]: PENDING,
  [FAILED]: FAILED,
  [CANCELED]: DEFAULT,
}

export const balanceEntryStatusMap = {
  [RETURNED]: RETURNED,
  [SUCCEEDED]: SUCCEEDED,
  [PENDING]: PENDING,
  [FAILED]: FAILED,
}

export const fileStatusMap = {
  [UPLOADED]: SUCCEEDED,
  [PENDING]: DEFAULT,
  [REQUIRES_UPLOAD]: DEFAULT,
  [FAILED]: FAILED,
}

export const shouldFundMap = {
  True: SUCCEEDED,
  False: DEFAULT,
}

export const enabledMap = {
  YES: SUCCEEDED,
  [ENABLED]: SUCCEEDED,
  NO: FAILED,
  [DISABLED]: FAILED,
}

export const externallyFundedMap = {
  True: SUCCEEDED,
  False: DEFAULT,
  Unknown: DEFAULT,
}

export const pinDebitStatusMap = {
  [YES]: DEFAULT,
  [NO]: DEFAULT,
}

export const certStatusMap = {
  [PENDING]: DEFAULT,
  [DONE]: SUCCEEDED,
}

export const subscriptionPlanStatusMap = {
  [ACTIVE]: SUCCEEDED,
  [INACTIVE]: FAILED,
}

export const exportStatusMap = {
  [COMPLETED]: SUCCEEDED,
  [PENDING]: DOT_CIRCLE,
  [FAILED]: FAILED,
  [IN_PROGRESS]: DOT_CIRCLE,
}

export const onboardingFormStatusMap = {
  [COMPLETED]: SUCCEEDED,
  [IN_PROGRESS]: DEFAULT,
}

export const subscriptionStatusMap = {
  [ACTIVE]: SUCCEEDED,
  [CANCELED]: FAILED,
}

export const ruleDecisionStatusMap = {
  ACCEPTED: SUCCEEDED,
  BLOCKED: FAILED,
  WATCHED,
}

export const statusIconMap = {
  [SUCCEEDED]: 'fa-check-circle',
  [UPLOADED]: 'fa-check-circle',
  [SUCCEEDED_COMPLIANCE]: 'fa-clipboard-check',
  [FAILED]: 'fa-times-circle',
  [PENDING]: 'fa-clock',
  [WARNING]: 'fa-clock',
  [CANCELED]: 'fa-minus-circle',
  [UNKNOWN]: 'fa-dot-circle',
  [DEFAULT]: 'fa-dot-circle',
  [EXPIRED]: 'fa-minus-circle',
  [ENABLED]: 'fa-check-circle',
  [DISABLED]: 'fa-times-circle',
  [WATCHED]: 'fa-exclamation-triangle',
  [RETURNED]: 'fa-arrow-circle-left',
}

export const httpCodeStatusMap = {
  '1': DEFAULT,
  '2': SUCCEEDED,
  '3': DEFAULT,
  '4': FAILED,
  '5': FAILED,
}

export const enabledTruthyMap = {
  true: SUCCEEDED,
  false: DEFAULT,
}

export const truthyMap = {
  true: SUCCEEDED,
  false: FAILED,
}

export const configNotSupportedMap = {
  true: DEFAULT,
  false: CANCELED,
}

export const phoneOwnershipReportStatusMap = {
  PARTIAL_MATCH: WARNING,
  MATCH: SUCCEEDED,
  NO_MATCH: FAILED,
}

export const phoneAndEmailAddressReportStatusMap = {
  PASSED: SUCCEEDED,
  FAILED: FAILED,
}

export const DISPUTE_TYPE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Pending', PENDING),
  selectOption('Inquiry', INQUIRY),
  selectOption('Won', WON),
  selectOption('Lost', LOST),
]

export const underwritingIconMap = {
  [IN_PROGRESS]: DOT_CIRCLE,
  [DEFAULT]: CIRCLE_MINUS_ICON,
  [GOLD_WARNING]: CLOCK_ICON,
  [DANGER]: `fa-${TIMES_CIRCLE_ICON}`,
  [SUCCESS]: `fa-${CHECK_CIRCLE_ICON}`,
  [WARNING]: CLOCK_ICON,
}

export const disputeEvidenceFileIconMap = {
  [SUCCEEDED]: `fa-${FILE_CHECK}`,
  [PENDING]: CLOCK_ICON,
  [FAILED]: `fa-${TIMES_CIRCLE_ICON}`,
}

export const accessFormIconMap = {
  [IN_PROGRESS_BE_VALUE]: DOT_CIRCLE,
  [APPROVED_BE_VALUE]: `fa-${CHECK_CIRCLE_ICON}`,
  [REJECTED_BE_VALUE]:  `fa-${TIMES_CIRCLE_ICON}`,
  [IN_REVIEW_BE_VALUE] : CLOCK_ICON,
  [WITHDRAWN_BE_VALUE]: CIRCLE_MINUS_ICON,
  [ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE]: CLOCK_ICON,
  [SALES_REVIEW_BE_VALUE]: CLOCK_ICON,
}

export const siftDecisionIconMap = {
  [ACCEPTED]: `fa-${CHECK_CIRCLE_ICON}`,
  [BLOCKED]: `fa-${TIMES_CIRCLE_ICON}`,
  [WATCHED]: ALERT_ICON,
}

export const SUBSCRIPTION_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Active', ACTIVE),
  selectOption('Canceled', CANCELED),
]

export const SUBSCRIPTION_PLAN_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Active', ACTIVE),
  selectOption('Archived', INACTIVE),
]
