import map from 'lodash/map'

import {
  validateTagNameLength,
  validateTagValueLength,
} from 'utilities/validate'

const validateTagsForm = ({ tags }) => {
  const tagErrors = map(tags, ({ name, value }) => {
    return {
      name: validateTagNameLength({ field: name }),
      value: validateTagValueLength({ field: value }),
    }
  })

  return {
    tags: tagErrors,
  }
}

export default validateTagsForm
