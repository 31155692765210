import './ReviewQueueIdentitiesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { GET_REVIEW_QUEUE } from 'constants/flowConstants'
import { REVIEW_QUEUE } from 'constants/linkConstants'
import { getPendingReviewQueueIdentitiesSelector } from 'state-layer/selectors'


const ReviewQueueIdentities = ({
  identitiesPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  selectedItemsKey,
  selectedItemsData = {},
  selector = getPendingReviewQueueIdentitiesSelector,
}) => {
  return (
    <div className='ReviewQueueIdentities'>
      <TableC
        flow={GET_REVIEW_QUEUE}
        columnDescriptors={columnDescriptors}
        selector={selector}
        linksKey={REVIEW_QUEUE}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        path={identitiesPath}
        fileNameCSV='ReviewQueue_Identities'
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
      />
    </div>
  )
}

ReviewQueueIdentities.propTypes = {
  identitiesPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  selectedItemsData: PropTypes.object,
}

export default ReviewQueueIdentities
