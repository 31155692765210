import removeUndefined from 'utilities/remove/removeUndefined'
import { USD } from 'constants/currencyConstants'
import { balanceTransferProcessors } from 'constants/balanceTransferConstants'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

import {
  PAYFAC_CREDIT,
  IMMEDIATE,
} from 'constants/payoutPlanConstants'

import {
  getAvailableProcessorsOptions,
  LITLE_V1,
  VANTIV_V1,
} from 'constants/processorConstants'

const backendBalanceTransferModel = ({ values }) => {
  const {
    id,
    source,
    destination,
    amount,
    description,
    tags,
  } = values

  const processorOptions = reduce(getAvailableProcessorsOptions(), (total, { value }) => {
    return (get(balanceTransferProcessors, value)) ? merge({}, total, { [value]: true }) : total
  }, {})

  const processor = get(processorOptions, VANTIV_V1) && !get(processorOptions, LITLE_V1)
    ? VANTIV_V1
    : LITLE_V1

  return removeUndefined({
    id,
    amount,
    currency: USD,
    description,
    source,
    destination,
    rail_type: PAYFAC_CREDIT,
    submission_type: IMMEDIATE,
    submission_offset_days: 0,
    processor_type: processor,
    tags,
  })
}

export default backendBalanceTransferModel
