import './APIResponseS.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import Loader from 'components/Shared/Loader/Loader'
import { COPY } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const APIResponse = ({
  api = () => {},
  apiProps = {},
  getKey = 'data',
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [rawResponse, setRawResponse] = useState({})
  const { credentials } = apiProps
  const credentialId = get(credentials, 'id')
  const rawResponseString = JSON.stringify(rawResponse, null, 2)

  const getAPIResponse = async () => {
    try {
      const response = await api(apiProps)
      setRawResponse(get(response, getKey, response))
    } catch (error) {
      setRawResponse({ error })
    }

    setIsFetching(false)
  }

  useEffect(() => {
    if (!isEmpty(credentials) && !isFetching) {
      setIsFetching(true)
      getAPIResponse()
    }
  }, [credentialId])

  return (
    <div className='APIResponse'>
      { isFetching ? <Loader /> : (
        <>
          <PrettyJSON data={rawResponse} />

          <div className='flex'>
            <ClickToCopyC customView={<Button label={<i className={`far fa-${COPY}`} />} />} content={rawResponseString} />
          </div>
        </>
      )}
    </div>
  )
}

APIResponse.propTypes = {
  api: PropTypes.func,
  apiProps: PropTypes.object,
  getKey: PropTypes.string,
}

export default APIResponse
