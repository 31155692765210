import './ButtonGroupQuickFilterS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { ALL } from 'constants/transferConstants'
import classnames from 'classnames'
import map from 'lodash/map'
import toString from 'lodash/toString'

const ButtonGroupQuickFilter = ({
  filterValue,
  options,
  handleClick,
  isFetching,
}) => {
  return (
    <div className='ButtonGroupQuickFilter flex center'>
      { map(options, ({ value, label }) => {
        const active = toString(filterValue) === toString(value) || (!filterValue && value === ALL) ? 'active' : ''

        const classNames = classnames({
          'quick-filter-button': true,
          active,
        })

        return (
          <button type='button' key={value} onClick={() => (isFetching ? {} : handleClick(value))} className={classNames} title={label}>
            {label}
          </button>
        )
      })}
    </div>
  )
}

ButtonGroupQuickFilter.propTypes = {
  filterValue: PropTypes.string,
  options: PropTypes.array,
  handleClick: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default ButtonGroupQuickFilter
