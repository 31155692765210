import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'

import {
  t,
  CANCEL,
  REACTIVATE_PAYOUT_LINK,
  REACTIVATE_PAYOUT_LINK_MODAL_TEXT,
  REACTIVATE,
} from 'constants/language/languageConstants'

const EnablePayoutLinkModal = ({
  isPatching = false,
  enablePayoutLink = () => {},
  closeModal = () => {},
}) => {
  return (
    <GenericModal title={REACTIVATE_PAYOUT_LINK}>
      <div className='modal-content'>
        <p className='p-1'>{t(REACTIVATE_PAYOUT_LINK_MODAL_TEXT)}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} onClick={closeModal} />
        <Button label={REACTIVATE} onClick={enablePayoutLink} disabled={isPatching} />
      </div>
    </GenericModal>
  )
}

EnablePayoutLinkModal.propTypes = {
  isPatching: PropTypes.bool,
  enablePayoutLink: PropTypes.func,
  closeModal: PropTypes.func,
}

export default EnablePayoutLinkModal
