// TRIPOS Cloud V1
import selectOption from 'utilities/forms/selectOption'
import { ALL } from 'constants/transferConstants'
import map from 'lodash/map'

import {
  ALL as ALL_STRING,
  ENABLED,
  DISABLED,
  ALWAYS,
  NEVER,
  IF_GREATER_THAN_THRESHOLD_AMOUNT,
  ALLOW_DEBIT,
  ALLOW_PARTIAL_TRANSACTION_APPROVALS,
  BYPASS_DEVICE_ON_CAPTURE,
  CHECK_FOR_DUPLICATE_TRANSACTIONS,
  ALLOW_CASH_BACK,
  ALLOW_GIFT_CARDS,
  ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION,
  PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION,
  PROMPT_AMOUNT_CONFIRMATION,
  ALLOW_STANDALONE_AUTHORIZATIONS,
  ALLOW_STANDALONE_SALES,
  ALLOW_STANDALONE_REFUNDS,
  ALLOW_DEBIT_TOOLTIP_MSG,
  BYPASS_DEVICE_ON_CAPTURE_TOOLTIP_MSG,
  CHECK_FOR_DUPLICATE_TRANSACTIONS_TOOLTIP_MSG,
  PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  PROMPT_AMOUNT_CONFIRMATION_TOOLTIP_MSG,
  ALLOW_STANDALONE_AUTHORIZATIONS_TOOLTIP_MSG,
  ALLOW_STANDALONE_SALES_TOOLTIP_MSG,
  ALLOW_STANDALONE_REFUNDS_TOOLTIP_MSG,
  ALLOW_CASH_BACK_TOOLTIP_MSG,
  ALLOW_GIFT_CARDS_TOOLTIP_MSG,
  PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
  PROMPT_RECEIPT_CONFIRMATION,
  CASH_BACK_OPTIONS,
  CASH_BACK_OPTIONS_TOOLTIP_MSG,
  MARKET_CODE,
  MARKET_CODE_TOOLTIP_MSG,
  ALLOW_PARTIAL_TRANSACTION_APPROVALS_TOOLTIP_MSG,
  ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  DISPLAY_TIP_ON_RECEIPT,
  ALLOW_PARTIAL_APPROVALS,
  DISPLAY_TIP_ON_RECEIPT_TOOLTIP_MSG,
  TIP_COLLECTION_ON_RECEIPT,
  TIP_COLLECTION_ON_TERMINAL,
  TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG,
  ON_THRESHOLD_AMOUNT,
  ON_NETWORK_RECOMMENDATION,
} from 'constants/language/languageConstants'

import {
  TRIPOS_MOBILE_V1,
  TRIPOS_CLOUD_V1,
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

export const IPP320 = 'IPP320'
export const IPP350 = 'IPP350'
export const ISC250 = 'ISC250'
export const ISC480 = 'ISC480'
export const ISMP4 = 'ISMP4'
export const MX915 = 'MX915'
export const MX925 = 'MX925'
export const LANE_3000 = 'LANE_3000'
export const LINK_2500 = 'LINK_2500'
export const BBPOS = 'BBPOS'
export const LANE_5000 = 'LANE_5000'
export const LANE_7000 = 'LANE_7000'
export const LANE_8000 = 'LANE_8000'
export const PAX_A30 = 'PAX_A30'
export const PAX_A35 = 'PAX_A35'
export const PAX_A77 = 'PAX_A77'
export const PAX_A920PRO = 'PAX_A920PRO'
export const PAX_IM30 = 'PAX_IM30'
export const PAX_ARIES8 = 'PAX_ARIES8'
export const PAX_A800 = 'PAX_A800'
export const PAX_D135 = 'PAX_D135'
export const SUNMI_P2 = 'SUNMI_P2'
export const SUNMI_P2_SMARTPAD = 'SUNMI_P2_SMARTPAD'
export const SUNMI_P3_MIX = 'SUNMI_P3_MIX'
export const DESK_3500 = 'DESK_3500'
export const DESK_5000 = 'DESK_5000'
export const MOVE_5000 = 'MOVE_5000'

export const BBPOS_LABEL = 'BBPOS'
export const DESK_3500_LABEL = 'Desk 3500'
export const DESK_5000_LABEL = 'Desk 5000'
export const IPP320_LABEL = 'iPP320'
export const IPP350_LABEL = 'iPP350'
export const ISC320_LABEL = 'iSC320'
export const ISC480_LABEL = 'iSC480'
export const ISMP4_LABEL = 'iSMP4'
export const LANE_3000_LABEL = 'Lane 3000'
export const LANE_5000_LABEL = 'Lane 5000'
export const LANE_7000_LABEL = 'Lane 7000'
export const LANE_8000_LABEL = 'Lane 8000'
export const LINK_2500_LABEL = 'Link 2500'
export const MOVE_5000_LABEL = 'Move 5000'
export const MX915_LABEL = 'MX915'
export const MX925_LABEL = 'MX925'
export const PAX_A30_LABEL = 'Pax A30'
export const PAX_A35_LABEL = 'Pax A35'
export const PAX_A77_LABEL = 'Pax A77'
export const PAX_A920PRO_LABEL = 'Pax A920Pro'
export const PAX_ARIES8_LABEL = 'Pax Aries8'
export const PAX_IM30_LABEL = 'Pax IM30'
export const PAX_A800_LABEL = 'Pax A800'
export const PAX_D135_LABEL = 'Pax D135'
export const SUNMI_P2_LABEL = 'Sunmi P2'
export const SUNMI_P2_SMARTPAD_LABEL = 'Sunmi P2 Smartpad'
export const SUNMI_P3_MIX_LABEL = 'Sunmi P3 Mix'

export const DEVICE_MODEL_TO_LABEL_MAP = {
  [BBPOS]: BBPOS_LABEL,
  [DESK_3500]: DESK_3500_LABEL,
  [DESK_5000]: DESK_5000_LABEL,
  [IPP320]: IPP320_LABEL,
  [IPP350]: IPP350_LABEL,
  [ISC250]: ISC320_LABEL,
  [ISC480]: ISC480_LABEL,
  [ISMP4]: ISMP4_LABEL,
  [IPP350]: IPP350_LABEL,
  [LANE_3000]: LANE_3000_LABEL,
  [LANE_5000]: LANE_5000_LABEL,
  [LANE_7000]: LANE_7000_LABEL,
  [LANE_8000]: LANE_8000_LABEL,
  [LINK_2500]: LINK_2500_LABEL,
  [MOVE_5000]: MOVE_5000_LABEL,
  [MX915]: MX915_LABEL,
  [MX925]: MX925_LABEL,
  [PAX_A30]: PAX_A30_LABEL,
  [PAX_A35]: PAX_A35_LABEL,
  [PAX_A77]: PAX_A77_LABEL,
  [PAX_A920PRO]: PAX_A920PRO_LABEL,
  [PAX_ARIES8]: PAX_ARIES8_LABEL,
  [PAX_A800]: PAX_A800_LABEL,
  [PAX_D135]: PAX_D135_LABEL,
  [PAX_IM30]: PAX_IM30_LABEL,
  [SUNMI_P2]: SUNMI_P2_LABEL,
  [SUNMI_P2_SMARTPAD]: SUNMI_P2_SMARTPAD_LABEL,
  [SUNMI_P3_MIX]: SUNMI_P3_MIX_LABEL,
}

export const CARD_PRESENT_DEVICE_IMAGE_MAP = {
  [BBPOS]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/BBPOS_Chipper.png',
  [IPP320]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_IPP320.png',
  [IPP350]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_IPP350.png',
  [ISC250]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_ISC250.png',
  [ISC480]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_ISC480.png',
  [ISMP4]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_ISMP4.png',
  [LANE_3000]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_Lane_3000.png',
  [LANE_5000]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_Lane_5000.png',
  [LANE_7000]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_Lane_7000_8000.png',
  [LANE_8000]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_Lane_7000_8000.png',
  [LINK_2500]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Ingenico_Link_2500.png',
  [MX915]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Verifone_MX915.png',
  [MX925]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/Verifone_MX925.png',
  [PAX_A30]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_A30.png',
  [PAX_A35]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_A35.png',
  [PAX_A77]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_A77.png',
  [PAX_A920PRO]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_A920PRO.png',
  [PAX_ARIES8]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_ARIES8.png',
  [PAX_IM30]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/PAX_IM30.png',
  [PAX_A800]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/A800.png',
  [PAX_D135]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/D135.png',
  [SUNMI_P2]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/SUNMI_P2.png',
  [SUNMI_P2_SMARTPAD]: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/P2_SMARTPAD.png',
  [SUNMI_P3_MIX]: 'https://payments-dashboard-assets.s3.us-west-2.amazonaws.com/dashboard.finixpayments.com/SUNMI-P3M-Mix.png',
}

export const DEVICE_STATE_OPTIONS = [
  selectOption(ALL_STRING, ALL),
  selectOption(ENABLED, 'true'),
  selectOption(DISABLED, 'false'),
]

export const DEVICE_MODEL_SELECT_OPTIONS = [
  selectOption('Select Type' , ''),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[BBPOS], BBPOS),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[IPP320], IPP320),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[IPP350], IPP350),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[ISC250], ISC250),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[ISC480], ISC480),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[ISMP4], ISMP4),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[LANE_3000], LANE_3000),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[LANE_5000], LANE_5000),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[LANE_7000], LANE_7000),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[LANE_8000], LANE_8000),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[LINK_2500], LINK_2500),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[MX915], MX915),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[MX925], MX925),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_A30], PAX_A30),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_A35], PAX_A35),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_A77], PAX_A77),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_A920PRO], PAX_A920PRO),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_ARIES8], PAX_ARIES8),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[PAX_IM30], PAX_IM30),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[SUNMI_P2], SUNMI_P2),
  selectOption(DEVICE_MODEL_TO_LABEL_MAP[SUNMI_P2_SMARTPAD], SUNMI_P2_SMARTPAD),
  // selectOption(DEVICE_MODEL_TO_LABEL_MAP[SUNMI_P3_MIX], SUNMI_P3_MIX),
]

export const ALWAYS_VALUE = 'ALWAYS'
export const NEVER_VALUE = 'NEVER'
export const AMOUNT_VALUE = 'AMOUNT'
export const ACTIVATE_VALUE = 'ACTIVATE'
export const DEACTIVATE_VALUE = 'DEACTIVATE'
export const REBOOT_VALUE = 'REBOOT'
export const CONNECTION_OPEN_VALUE = 'Open'
export const CONNECTION_CLOSED_VALUE = 'Closed'
export const CREATE_IDLE_MESSAGE_VALUE = 'CREATE_IDLE_MESSAGE'
export const PRINT_VALUE = 'PRINT'
export const EMAIL_VALUE = 'EMAIL'
export const SMS_VALUE = 'SMS'
export const ON_THRESHOLD_AMOUNT_VALUE = 'ON_THRESHOLD_AMOUNT'
export const ON_NETWORK_RECOMMENDATION_VALUE = 'ON_NETWORK_RECOMMENDATION'

export const PROMPT_SIGNATURE_OPTIONS = [
  selectOption(ALWAYS, ALWAYS_VALUE),
  selectOption(NEVER, NEVER_VALUE),
  selectOption(IF_GREATER_THAN_THRESHOLD_AMOUNT, AMOUNT_VALUE),
]

export const PROMPT_SIGNATURE_VALUE_TO_LABEL_MAP = {
  [ALWAYS_VALUE]: ALWAYS,
  [NEVER_VALUE]: NEVER,
  [AMOUNT_VALUE]: IF_GREATER_THAN_THRESHOLD_AMOUNT,
}

// prompt_for_signature field only supported by FINIX_V1 and DUMMY_V1
export const PROMPT_FOR_SIGNATURE_OPTIONS = [
  selectOption(ALWAYS, ALWAYS_VALUE),
  selectOption(NEVER, NEVER_VALUE),
  selectOption(ON_THRESHOLD_AMOUNT, ON_THRESHOLD_AMOUNT_VALUE),
  selectOption(ON_NETWORK_RECOMMENDATION, ON_NETWORK_RECOMMENDATION_VALUE),
]

export const PROMPT_FOR_SIGNATURE_VALUE_TO_LABEL_MAP = {
  [ALWAYS_VALUE]: ALWAYS,
  [NEVER_VALUE]: NEVER,
  [ON_THRESHOLD_AMOUNT_VALUE]: ON_THRESHOLD_AMOUNT,
  [ON_NETWORK_RECOMMENDATION_VALUE]: ON_NETWORK_RECOMMENDATION,
}

// device configuration individual select options
export const ALLOW_DEBIT_SELECT = {
  name: 'allowDebit',
  field: 'allow_debit',
  label: ALLOW_DEBIT,
  tooltip: ALLOW_DEBIT_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, FINIX_V1, DUMMY_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const ALLOW_PARTIAL_TRANSACTION_APPROVALS_SELECT = {
  name: 'allowPartialTransactionApprovals',
  field: 'allow_partial_approvals',
  label: ALLOW_PARTIAL_APPROVALS,
  tooltip: ALLOW_PARTIAL_TRANSACTION_APPROVALS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: false,
}

export const BYPASS_DEVICE_ON_CAPTURE_SELECT = {
  name: 'bypassDeviceOnCapture',
  field: 'bypass_device_on_capture',
  label: BYPASS_DEVICE_ON_CAPTURE,
  tooltip: BYPASS_DEVICE_ON_CAPTURE_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: true,
}

export const CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT = {
  name: 'checkForDuplicateTransactions',
  field: 'check_for_duplicate_transactions',
  label: CHECK_FOR_DUPLICATE_TRANSACTIONS,
  tooltip: CHECK_FOR_DUPLICATE_TRANSACTIONS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: true,
}

export const ALLOW_CASH_BACK_SELECT = {
  name: 'allowCashBack',
  field: 'is_cash_back_allowed',
  label: ALLOW_CASH_BACK,
  tooltip: ALLOW_CASH_BACK_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1, FINIX_V1, DATACAP_V1, DUMMY_V1],
  visibleToCustomers: false,
}

export const ALLOW_GIFT_CARDS_SELECT = {
  name: 'allowGiftCards',
  field: 'is_gift_supported',
  label: ALLOW_GIFT_CARDS,
  tooltip: ALLOW_GIFT_CARDS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1, FINIX_V1, DATACAP_V1, DUMMY_V1],
  visibleToCustomers: false,
}

export const ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT = {
  name: 'allowManualEntryOfCardInformation',
  field: 'is_manual_entry_allowed',
  label: ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION,
  tooltip: PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1, FINIX_V1, DATACAP_V1, DUMMY_V1],
  visibleToCustomers: false,
}

export const PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT = {
  name: 'promptManualEntryOfCardInformation',
  field: 'prompt_manual_entry',
  label: PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION,
  tooltip: PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: true,
}

export const PROMPT_AMOUNT_CONFIRMATION_SELECT = {
  name: 'promptAmountConfirmation',
  field: 'prompt_amount_confirmation',
  label: PROMPT_AMOUNT_CONFIRMATION,
  tooltip: PROMPT_AMOUNT_CONFIRMATION_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_MOBILE_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: true,
}

export const CASH_BACK_OPTIONS_SELECT = {
  name: 'cashBackOptions',
  field: 'cashback_options',
  label: CASH_BACK_OPTIONS,
  tooltip: CASH_BACK_OPTIONS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1, FINIX_V1, DUMMY_V1],
  notVisibleToCustomers: true,
}

export const MARKET_CODE_SELECT = {
  name: 'marketCode',
  field: 'market_code',
  label: MARKET_CODE,
  tooltip: MARKET_CODE_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1, FINIX_V1, DUMMY_V1],
  visibleToCustomers: false,
}

export const DISPLAY_TIP_ON_RECEIPT_SELECT = {
  name: 'displayTipOnReceipt',
  field: 'display_tip_on_receipt',
  label: TIP_COLLECTION_ON_RECEIPT,
  tooltip: DISPLAY_TIP_ON_RECEIPT_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const PROMPT_TIP_ON_SCREEN_SELECT = {
  name: 'promptTipOnScreen',
  field: 'prompt_tip_on_screen',
  label: TIP_COLLECTION_ON_TERMINAL,
  tooltip: TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const PROMPT_RECEIPT_CONFIRMATION_SELECT = {
  name: 'promptReceiptConfirmation',
  field: 'prompt_receipt_confirmation',
  label: PROMPT_RECEIPT_CONFIRMATION,
  tooltip: PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const ALLOW_STANDALONE_AUTHORIZATIONS_SELECT = {
  name: 'allowStandaloneAuthorizations',
  field: 'allow_standalone_authorizations',
  label: ALLOW_STANDALONE_AUTHORIZATIONS,
  tooltip: ALLOW_STANDALONE_AUTHORIZATIONS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const ALLOW_STANDALONE_SALES_SELECT = {
  name: 'allowStandaloneSales',
  label: ALLOW_STANDALONE_SALES,
  field: 'allow_standalone_sales',
  tooltip: ALLOW_STANDALONE_SALES_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const ALLOW_STANDALONE_REFUNDS_SELECT = {
  name: 'allowStandaloneRefunds',
  label: ALLOW_STANDALONE_REFUNDS,
  field: 'allow_standalone_refunds',
  tooltip: ALLOW_STANDALONE_REFUNDS_TOOLTIP_MSG,
  disabledForGateways: [TRIPOS_CLOUD_V1, TRIPOS_MOBILE_V1, DATACAP_V1],
  visibleToCustomers: true,
}

export const GATEWAY_PROCESSOR_TO_DEVICE_MODELS_MAP = {
  [TRIPOS_MOBILE_V1]: [BBPOS],
  [TRIPOS_CLOUD_V1]: [MX915, MX925, IPP320, IPP350, ISC250, ISC480, ISMP4, LINK_2500, LANE_3000],
  [DATACAP_V1]: [PAX_A30, PAX_A35, PAX_A77, PAX_A920PRO, PAX_ARIES8, PAX_IM30, LANE_3000, LANE_5000, LANE_7000, LANE_8000, LINK_2500],
  [FINIX_V1]:[SUNMI_P2, SUNMI_P2_SMARTPAD, SUNMI_P3_MIX, PAX_A920PRO, PAX_A800, PAX_D135],
  [DUMMY_V1]: [SUNMI_P2, SUNMI_P2_SMARTPAD, SUNMI_P3_MIX, PAX_A920PRO, PAX_A800, PAX_D135],
}

export const generateDeviceModelOptions = ({allowedModels}) => map(allowedModels, (model) => {
    return { label: DEVICE_MODEL_TO_LABEL_MAP[model], value: model }
})

export const DEVICE_CONFIGURATIONS_SELECT_OPTIONS = [
  ALLOW_DEBIT_SELECT,
  ALLOW_PARTIAL_TRANSACTION_APPROVALS_SELECT,
  BYPASS_DEVICE_ON_CAPTURE_SELECT,
  ALLOW_CASH_BACK_SELECT,
  CASH_BACK_OPTIONS_SELECT,
  CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT,
  ALLOW_GIFT_CARDS_SELECT,
  ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT,
  PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT,
  MARKET_CODE_SELECT,
  PROMPT_AMOUNT_CONFIRMATION_SELECT,
  DISPLAY_TIP_ON_RECEIPT_SELECT,
  ALLOW_STANDALONE_AUTHORIZATIONS_SELECT,
  ALLOW_STANDALONE_SALES_SELECT,
  ALLOW_STANDALONE_REFUNDS_SELECT,
]

export const DEVICE_CONFIGURATION_LABEL_TO_TOOLTIP_MAP = {
  [ALLOW_DEBIT]: ALLOW_DEBIT_TOOLTIP_MSG,
  [ALLOW_PARTIAL_APPROVALS]: ALLOW_PARTIAL_TRANSACTION_APPROVALS_TOOLTIP_MSG,
  [BYPASS_DEVICE_ON_CAPTURE]: BYPASS_DEVICE_ON_CAPTURE_TOOLTIP_MSG,
  [ALLOW_CASH_BACK]: ALLOW_CASH_BACK_TOOLTIP_MSG,
  [CASH_BACK_OPTIONS]: CASH_BACK_OPTIONS_TOOLTIP_MSG,
  [CHECK_FOR_DUPLICATE_TRANSACTIONS]: CHECK_FOR_DUPLICATE_TRANSACTIONS_TOOLTIP_MSG,
  [ALLOW_GIFT_CARDS]: ALLOW_GIFT_CARDS_TOOLTIP_MSG,
  [ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION]: ALLOW_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  [PROMPT_AMOUNT_CONFIRMATION]: PROMPT_AMOUNT_CONFIRMATION_TOOLTIP_MSG,
  [PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION]: PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_TOOLTIP_MSG,
  [MARKET_CODE]: MARKET_CODE_TOOLTIP_MSG,
  [DISPLAY_TIP_ON_RECEIPT]: DISPLAY_TIP_ON_RECEIPT_TOOLTIP_MSG,
}

export const GATEWAY_PROCESSOR_TO_DEVICE_CONFIGURATIONS_MAP = {
  [TRIPOS_CLOUD_V1]: [ALLOW_DEBIT_SELECT, CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT, PROMPT_AMOUNT_CONFIRMATION_SELECT, PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT, BYPASS_DEVICE_ON_CAPTURE_SELECT],
  [DATACAP_V1]: [ALLOW_DEBIT_SELECT, CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT, PROMPT_AMOUNT_CONFIRMATION_SELECT, PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT, BYPASS_DEVICE_ON_CAPTURE_SELECT],
  [FINIX_V1]: [ALLOW_DEBIT_SELECT, CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT, PROMPT_AMOUNT_CONFIRMATION_SELECT, PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT, BYPASS_DEVICE_ON_CAPTURE_SELECT, PROMPT_RECEIPT_CONFIRMATION_SELECT],
  [DUMMY_V1]: [ALLOW_DEBIT_SELECT, CHECK_FOR_DUPLICATE_TRANSACTIONS_SELECT, PROMPT_AMOUNT_CONFIRMATION_SELECT, PROMPT_MANUAL_ENTRY_OF_CARD_INFORMATION_SELECT, BYPASS_DEVICE_ON_CAPTURE_SELECT, PROMPT_RECEIPT_CONFIRMATION_SELECT],
}


export const PROCESSORS_WITH_DEVICES = [DUMMY_V1, FINIX_V1] // technically includes VANTIV too but if it's VANTIV, there NEEDS to be a gateway
export const GATEWAYS_WITH_DEVICES = [TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1, DATACAP_V1]
