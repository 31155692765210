import React from 'react'
import { Link } from 'react-router'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import NotificationSettingsForm from './NotificationSettingsForm'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { NOTIFICATION_SETTINGS_FORM } from 'constants/formConstants'

import {
  NOTIFICATIONS_TITLE,
  NOTIFICATION_SETTINGS_SUBHEADER_MESSAGE,
  NOTIFICATIONS_VIEW_ALL_TITLE,
} from 'constants/language/languageConstants'

const NotificationSettings = ({
  settingOptions = [],
  handleSubmit = () => {},
  pristine = false,
  isFetching = false,
  viewAllPath,
}) => {
  const allNotificationsLink = <Link className='notification-view-all-link text-link' to={viewAllPath}>{NOTIFICATIONS_VIEW_ALL_TITLE}</Link>

  return (
    <div className='NotificationSettings'>
      <PageSectionV2C
        isFetching={isFetching}
        title={NOTIFICATIONS_TITLE}
        subTitle={NOTIFICATION_SETTINGS_SUBHEADER_MESSAGE}
        asideTitle={allNotificationsLink}
      >

        <NotificationSettingsForm
          data={settingOptions}
          extraProps={{ handleSubmit, pristine }}
        />
      </PageSectionV2C>
    </div>
  )
}

NotificationSettings.propTypes = {
  settingOptions: PropTypes.array,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  isFetching: PropTypes.bool,
  viewAllPath: PropTypes.string,
}

export default reduxForm({
  form: NOTIFICATION_SETTINGS_FORM,
  enableReinitialize: true,
})(NotificationSettings)
