import React from 'react'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import _ from 'utilities/display/displayString'
import column from 'utilities/table/column'
import formatMoney from 'utilities/formatters/formatMoney'
import { isDisplayTraceId } from 'constants/platformConstants'
import includes from 'lodash/includes'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import getUrlQuery from 'utilities/get/getUrlQuery'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  ALL,
  CANCELED,
  FAILED,
} from 'constants/transferConstants'

import {
  ID,
  CREATED_ON,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  APPLICATION,
  BUYER,
  AMOUNT,
  STATE,
  CARD_BRAND,
  PAYMENT_INSTRUMENT,
  REASON_CODE,
  CARD_TYPE,
  TRANSACTION_TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    condition: notRoleMerchant,
  }),
  column(BUYER, 'paymentInstrument.name', {
    className: 'name',
  }),
  column(AMOUNT, ['amount', 'currency'], {
    className: 'amount divider',
    formatter: formatMoney,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === CANCELED}
        subtitleIndent
      />
    ),
  }),
  column(TRANSACTION_TYPE, 'type', {
    className: 'normal ',
    formatter: ({ type }) => startCase(lowerCase(type)),
  }),
  column(CARD_BRAND, 'paymentInstrument.brand', {
    headerFormatter: () => <TableItemSubtitle title={CARD_BRAND} subtitle={CARD_TYPE} />,
    className: 'normal',
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.type', {
    className: 'normal',
  }),
  column(_('Trace ID'), 'traceId', { condition: isDisplayTraceId }),
]

export default columnDescriptors
