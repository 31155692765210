import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantOnboardingWelcomeForm from './MerchantOnboardingWelcomeForm'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetchingOnboardingFormData = get(state, `onboardingFormDataR.${FETCHING}`)
  const onboardingFormId = get(props, 'onboardingFormId')
  const onboardingFormCountry = get(props, 'onboardingFormCountry')

  const initialValues = isFetchingOnboardingFormData ? undefined : {
    country: onboardingFormCountry,
  }

  return {
    onboardingFormId,
    onboardingFormCountry,
    initialValues,
  }
}

class MerchantOnboardingWelcomeFormC extends Component {
  render() {
    return (
      <MerchantOnboardingWelcomeForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(MerchantOnboardingWelcomeFormC)
