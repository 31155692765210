import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UpdateAccountBalanceFormC from 'components/Customer/Forms/UpdateAccountBalanceForm/UpdateAccountBalanceFormC'
import { UPDATE_ACCOUNT_BALANCE } from 'constants/language/languageConstants'

const UpdateAccountBalanceModal = ({
  credentials = {},
  applicationId = '',
  applicationName = '',
  balanceId = '',
  availableAmount = '',
  displayAvailableAmount = '',
}) => {
  return (
    <GenericModal
      title={UPDATE_ACCOUNT_BALANCE}
      className='UpdateAccountBalanceModal'
      Component={UpdateAccountBalanceFormC}
      applicationId={applicationId}
      applicationName={applicationName}
      balanceId={balanceId}
      availableAmount={availableAmount}
      displayAvailableAmount={displayAvailableAmount}
      credentials={credentials}
    />
  )
}

UpdateAccountBalanceModal.propTypes = {
  credentials: PropTypes.object,
  applicationId: PropTypes.string,
  applicationName: PropTypes.string,
  balanceId: PropTypes.string,
}

export default UpdateAccountBalanceModal
