import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditDeviceDetailsForm from 'components/Customer/Forms/EditDeviceDetailsForm/EditDeviceDetailsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'
import includes from 'lodash/includes'

import {
  TRIPOS_MOBILE_V1,
  TRIPOS_CLOUD_V1,
} from 'constants/processorConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)

  const [
    deviceName,
    deviceDescription,
    serialNumber,
    deviceStatusEnabled,
    gateway,
    processor,
  ] = getMany(props, [
    'deviceName',
    'deviceDescription',
    'serialNumber',
    'deviceStatusEnabled',
    'gateway',
    'processor',
  ])

  // the logic here is if device is disabled, every gateway can edit serial number. if device is enabled, only tripos mobile can edit serial number
  const canEditSerialNumber = !deviceStatusEnabled || (deviceStatusEnabled && (includes([TRIPOS_MOBILE_V1, TRIPOS_CLOUD_V1], gateway)))

  const initialValues = {
    deviceName,
    deviceDescription,
    serialNumber,
  }

  return {
    credentials,
    initialValues,
    canEditSerialNumber,
    serialNumber,
    isUpdating: isPatching(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditDeviceDetailsFormC extends Component {
  render() {
    return (
      <EditDeviceDetailsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDeviceDetailsFormC)
