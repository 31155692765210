import fileDownload from 'js-file-download'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import { APPLE_PAY_PROCESSING } from 'constants/paymentMethodConstants'
import { POST_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST } from 'constants/flowConstants'
import { DOWNLOAD_APPLE_PAY_CSR_FILE } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import head from 'lodash/head'

const postDownloadCSRFileRequest = ({
  identityId,
  credentials,
  iOSApplicationName = '',
}) => createAction({
  type: POST_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST,
  values: {
    type: APPLE_PAY_PROCESSING,
    linked_to: identityId,
    name: iOSApplicationName,
  },
  credentials,
  meta: {
    successCallback: ({ newValues }) => {
      const [
        file,
        fileName,
      ] = getMany(head(Object.values(newValues)), [
        'fileData',
        'fileName',
      ])

      const decodedFile = atob(file)

      fileDownload(decodedFile, fileName)
    },
    actionId: sendAmplitudeActionEvent(DOWNLOAD_APPLE_PAY_CSR_FILE, {
      credentials,
      identityId,
    }),
  },
})

export default postDownloadCSRFileRequest
