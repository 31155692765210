import React, { Component } from 'react'
import { connect } from 'react-redux'
import FileUploadForm from './FileUploadForm'
import selectOption from 'utilities/forms/selectOption'
import { SUPPORTED_FILE_UPLOAD_DOCUMENT_TYPES } from 'constants/fileConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const config = {
  multiple: false,
  maxSize: 10000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag & Drop, or click to select a file',
  },
  {
    name: 'sub',
    message: 'Accept: jpeg, png, tiff, pdf; Max Size: 10 MB',
  },
]

const mapStateToProps = (state, props) => {
  const identityMerchants = get(props, 'identityMerchants')

  const merchantAccountOptions = [
    selectOption('Select Merchant Account', ''),
    ...map(identityMerchants, ({ id, name, processor }) => selectOption(`${name} (${processor})`, id)),
  ]

  return { merchantAccountOptions }
}

class FileUploadFormC extends Component {
  render() {
    return (
      <FileUploadForm
        {...this.props}
        config={config}
        instructions={instructions}
        fileTypeOptions={SUPPORTED_FILE_UPLOAD_DOCUMENT_TYPES}
      />
    )
  }
}

export default connect(mapStateToProps)(FileUploadFormC)
