import './DocumentDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  ID_PHOTOS,
  DOCUMENT_DETAILS,
} from 'constants/language/languageConstants'

const DocumentDetails = ({
  fullName = '',
  idPhotosData = [],
  documentDetailsData = [],
}) => {
  return (
    <div className='DocumentDetails'>
      <h5>{fullName}</h5>

      <PageSectionV2C
        smallTitle={ID_PHOTOS}
        className='id-photos-data-section no-pad'
        columns={idPhotosData}
      />

      <PageSectionV2C
        smallTitle={DOCUMENT_DETAILS}
        className='document-details-data-section no-pad'
        columns={documentDetailsData}
      />
    </div>
  )
}

DocumentDetails.propTypes = {
  fullName: PropTypes.string,
  idPhotosData: PropTypes.array,
  documentDetailsData: PropTypes.array,
}

export default DocumentDetails
