import { GET_UNDERWRITING_REVIEWS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUnderwritingReviewsRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_UNDERWRITING_REVIEWS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getUnderwritingReviewsRequest
