import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import { enabledTruthyMap } from 'constants/statusConstants'
import { PAYMENT_DEVICE_PATH } from 'constants/pathConstants'
import { SIFT_CONSOLE_URL } from 'constants/urlConstants'
import toString from 'lodash/toString'

import {
  PAYMENT_CARD,
  PAYMENT_CARD_PRESENT,
  BANK_ACCOUNT,
  APPLE_PAY,
  GOOGLE_PAY,
} from 'constants/paymentInstrumentConstants'

import {
  TYPE,
  CREATED_VIA,
  CARD_BRAND,
  CARD_TYPE,
  MASKED_NUMBER,
  EXPIRATION_DATE,
  CVV_VERIFICATION,
  ISSUER_COUNTRY,
  ADDRESS,
  ADDRESS_VERIFICATION,
  STATE,
  ENABLED,
  DISABLED,
  CREATED_ON,
  NAME,
  ROUTING_NUMBER,
  VIEW_DEVICE_INFO,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  COUNTRY,
  RISK_ANALYSIS,
  VIEW_IN_SIFT,
} from 'constants/language/languageConstants'

const formatPaymentInstrumentDataSection = ({
  paymentInstrument = {},
  noDevice = false,
  deviceId = '',
  credentialId = '',
  isRoleMerchantCred = false,
  isEnterpriseSiftFraudScoreTier = false, // only non-role_merchant creds with enterprise tier should see sift link
  isPreviewPanel = false,
}) => {
  const [
    type,
    cardBrand,
    cardType,
    expirationDate,
    maskedCardNumber,
    maskedAccountNumber,
    bankCode,
    displayCVVVerification,
    issuerCountry,
    displayAddressVerification,
    displayAddress,
    createdVia,
    name,
    enabled,
    displayCreatedAt,
    transitNumber,
    institutionNumber,
    displayCountry,
    fingerprint,
  ] = getMany(paymentInstrument, [
    'type',
    'displayBrand',
    'cardType',
    'expirationDate',
    'maskedFullCardNumber',
    'maskedAccountNumber',
    'bankCode',
    'displaySecurityCodeVerification',
    'displayIssuerCountry',
    'displayAddressVerification',
    'displayAddress',
    'displayCreatedVia',
    'name',
    'enabled',
    'displayCreatedAt',
    'transitNumber',
    'institutionNumber',
    'displayCountry',
    'fingerprint',
  ])

  switch (type) {
    case PAYMENT_CARD:
      return [
        {
          type: 'data',
          data: [
            {
              label: STATE,
              value: enabled,
              formatter: () => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
            },
            {
              label: CREATED_ON,
              value: displayCreatedAt,
            },
            {
              label: NAME,
              value: name,
            },
            {
              label: TYPE,
              value: type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
            {
              label: CARD_TYPE,
              value: cardType,
            },
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: CVV_VERIFICATION,
              value: displayCVVVerification,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: ADDRESS,
              value: displayAddress,
            },
            {
              label: ADDRESS_VERIFICATION,
              value: displayAddressVerification,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
            {
              label: RISK_ANALYSIS,
              value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${fingerprint}`} target='blank'>{VIEW_IN_SIFT}</a>,
              condition: !isRoleMerchantCred && isEnterpriseSiftFraudScoreTier,
            },
          ],
        },
      ]

    case PAYMENT_CARD_PRESENT:
      return [
        {
          type: 'data',
          data: [
            {
              label: STATE,
              value: enabled,
              formatter: () => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
            },
            {
              label: CREATED_ON,
              value: displayCreatedAt,
            },
            {
              label: NAME,
              value: name,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: TYPE,
              value: type,
              formatter: !noDevice ? () => (
                <div className='flex device-info-container'>
                  <div className='device-type'>{type}</div>
                  <Link className='view-device-info text-link' to={PAYMENT_DEVICE_PATH({ credentialId, deviceId })}>{VIEW_DEVICE_INFO}</Link>
                </div>
              ) : () => type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
            {
              label: CARD_TYPE,
              value: cardType,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
            {
              label: RISK_ANALYSIS,
              value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${fingerprint}`} target='blank'>{VIEW_IN_SIFT}</a>,
              condition: !isRoleMerchantCred && isEnterpriseSiftFraudScoreTier,
            },
          ],
        },
      ]

    case BANK_ACCOUNT:
      return convertPageSectionDataToV2([
        {
          label: STATE,
          value: enabled,
          formatter: () => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
        },
        {
          label: CREATED_ON,
          value: displayCreatedAt,
        },
        {
          label: NAME,
          value: name,
        },
        {
          label: TYPE,
          value: type,
        },
        {
          label: CREATED_VIA,
          value: createdVia,
        },
        {
          label: COUNTRY,
          value: displayCountry,
        },
        {
          label: MASKED_NUMBER,
          value: maskedAccountNumber,
        },
        {
          label: ROUTING_NUMBER,
          value: bankCode,
          condition: !!bankCode,
        },
        {
          label: TRANSIT_NUMBER,
          value: transitNumber,
          condition: !!transitNumber,
        },
        {
          label: INSTITUTION_NUMBER,
          value: institutionNumber,
          condition: !!institutionNumber,
        },
        {
          label: RISK_ANALYSIS,
          value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${fingerprint}`} target='blank'>{VIEW_IN_SIFT}</a>,
          condition: !isRoleMerchantCred && isEnterpriseSiftFraudScoreTier,
        },
      ], isPreviewPanel ? 1 : 3)

    case APPLE_PAY:
    case GOOGLE_PAY:
      return [
        {
          type: 'data',
          data: [
            {
              label: STATE,
              value: enabled,
              formatter: () => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
            },
            {
              label: CREATED_ON,
              value: displayCreatedAt,
            },
            {
              label: NAME,
              value: name,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: TYPE,
              value: type,
            },
            {
              label: CREATED_VIA,
              value: createdVia,
            },
            {
              label: CARD_BRAND,
              value: cardBrand,
            },
          ],
        },
        {
          type: 'data',
          data: [
            {
              label: MASKED_NUMBER,
              value: maskedCardNumber,
            },
            {
              label: EXPIRATION_DATE,
              value: expirationDate,
            },
            {
              label: ISSUER_COUNTRY,
              value: issuerCountry,
            },
            {
              label: RISK_ANALYSIS,
              value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${fingerprint}`} target='blank'>{VIEW_IN_SIFT}</a>,
              condition: !isRoleMerchantCred && isEnterpriseSiftFraudScoreTier,
            },
          ],
        },
      ]

    default:
      return []
  }
}

formatPaymentInstrumentDataSection.propTypes = {
  paymentInstrument: PropTypes.object,
  noDevice: PropTypes.bool,
  deviceId: PropTypes.string,
  credentialId: PropTypes.string,
  isRoleMerchantCred: PropTypes.bool,
  isEnterpriseSiftFraudScoreTier: PropTypes.bool,
  isPreviewPanel: PropTypes.bool,
}

export default formatPaymentInstrumentDataSection
