import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerOnboardingFormNewUrlLink from './CustomerOnboardingFormNewUrlLink'
import { getCustomerOnboardingFormLinkSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCustomerOnboardingFormLinkRequest from 'utilities/actions/get/getCustomerOnboardingFormLinkRequest'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import { SUCCESSFULLY_COPIED_TO_CLIPBOARD } from 'constants/language/languageConstants'
import { SUCCESS } from 'constants/flashNotificationConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import join from 'lodash/join'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const customerOnboardingFormId = get(props, 'customerOnboardingFormId')
  const isAdditionalVerificationNeeded = get(props, 'isAdditionalVerificationNeeded')
  let url = get(getCustomerOnboardingFormLinkSelector(state, customerOnboardingFormId), 'link_url')
  const isFetching = get(state, `customerOnboardingFormLinksR.${FETCHING}`)

  // if the form is in additional verification needed state, update the url to take the user to the final step
  if (isAdditionalVerificationNeeded) {
    url = join([url, '&activeStep=4'], '')
  }

  return {
    customerOnboardingFormId,
    credentials,
    url,
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerOnboardingFormLink: ({ credentials, customerOnboardingFormId }) => dispatch(getCustomerOnboardingFormLinkRequest({ customerOnboardingFormId, credentials })),
    triggerFlashNotificationAction: ({ message, type, time }) => dispatch(flashNotificationAction({ message, type, time })),
  }
}

class CustomerOnboardingFormNewUrlLinkC extends Component {
  componentDidMount() {
    const {
      credentials,
      customerOnboardingFormId,
      getCustomerOnboardingFormLink,
    } = this.props

    getCustomerOnboardingFormLink({ customerOnboardingFormId, credentials })
  }

    copyToClipboard = (url = '') => {
      const {
        triggerFlashNotificationAction,
      } = this.props

      const input = document.createElement('input')

      input.setAttribute('value', url)
      document.body.appendChild(input)
      input.select()

      const result = document.execCommand('copy')

      document.body.removeChild(input)

      triggerFlashNotificationAction({
        message: SUCCESSFULLY_COPIED_TO_CLIPBOARD,
        type: SUCCESS,
        time: 3000,
      })

      return result
    }

    render() {
      return (
        <CustomerOnboardingFormNewUrlLink
          {...this.props}
          copyToClipboard={this.copyToClipboard}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOnboardingFormNewUrlLinkC)
