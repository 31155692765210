import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import EditPayoutSettingsForm from './EditPayoutSettingsForm'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import TimeFieldset from 'components/Shared/Fieldsets/TimeFieldset/TimeFieldset'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import getPayoutSettingsRequest from 'utilities/actions/get/getPayoutSettingsRequest'
import selectOption from 'utilities/forms/selectOption'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import createRangeArray from 'utilities/create/createRangeArray'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT_PAYOUT_SETTINGS_FORM } from 'constants/formConstants'
import { MERCHANT_PATH } from 'constants/pathConstants'
import { SHOW_SETTLEMENT_BATCH_TIME_EDIT } from 'constants/featureFlagConstants'
import { ALERT_ICON } from 'constants/iconConstants'
import { TIME_ZONE_TO_DISPLAY_MAP } from 'constants/timeConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import orderBy from 'lodash/orderBy'
import includes from 'lodash/includes'

import {
  Field,
  formValueSelector,
  change,
  getFormValues,
} from 'redux-form'

import {
  PAYOUT_PROFILE_SETTLEMENT_BATCH_TIME_URL,
  PAYOUTS_LEARN_MORE_LINK,
} from 'constants/urlConstants'

import {
  FEE_BANK_ACCOUNT_TOOLTIP,
  LEARN_MORE_LABEL,
  PAYOUT_SETTINGS_BANK_ACCOUNT_WARNING,
  FUNDING_TRANSFER_SPEED_LABEL,
  FUNDING_TRANSFER_DELAY_LABEL,
  FEE_DELAY_LABEL,
  FUNDING_TRANSFER_SPEED_SUBLABEL,
  FUNDING_TRANSFER_DELAY_SUBLABEL,
  FEE_DELAY_SUBLABEL,
  FEE_BANK_ACCOUNT_TITLE,
  FEE_BANK_ACCOUNT_SUBLABEL,
  PAYOUT_BANK_ACCOUNT_TITLE,
  PAYOUT_BANK_ACCOUNT_SUBLABEL,
  SCHEDULE_SUBHEADER,
  PAYOUT_SETTLEMENT_SCHEDULE,
  SCHEDULE_SUBLABEL,
  FEE_SETTLEMENT_SCHEDULE,
  DAY_OF_THE_MONTH,
  ADD_NEW_BANK_ACCOUNT,
  SELECT_PAYOUT_BANK_ACCOUNT,
  SELECT_FEE_BANK_ACCOUNT,
  PAYOUT_TYPE,
  SELECT_DAY_OF_MONTH,
  PAYOUT_TYPE_SUBHEADER,
  BANK_ACCOUNT,
  BANK_ACCOUNT_SUBHEADER,
  SETTLEMENT_SCHEDULE,
  TRANSFER_SPEED,
  TRANSFER_SPEED_SUBHEADER,
  RECONCILIATION_TITLE,
  RECONCILIATION_SUBHEADER,
  READY_TO_SETTLE_LABEL,
  MANUAL_SETTLEMENT_SCHEDULE_WARNING_MESSAGE,
  SELECT_READY_TO_SETTLE_AT_LABEL,
  NO_DELAY_LABEL,
  FEE_BANK_ACCOUNT_SUBHEADER,
  USE_PAYOUT_BANK_ACCOUNT_LABEL,
  RECONCILIATION_WARNING_MESSAGE,
  FEE_READY_TO_SETTLE_LABEL,
  SETTLEMENT_BATCH_TIME,
  SELECT_BATCH_TIME_CUTOFF,
} from 'constants/language/languageConstants'

import {
  ADD_IDENTITY_BANK_ACCOUNT,
  EDIT_PAYOUT_SETTINGS_CONFIRMATION,
} from 'constants/modalConstants'

import {
  GROSS,
  MONTHLY,
  PAYOUT_SETTINGS_TYPE_OPTIONS,
  PAYOUT_SETTINGS_RAIL_OPTIONS,
  READY_TO_SETTLE_UPON_OPTIONS,
  MANUAL,
  RECONCILIATION,
  PAYOUT_SETTINGS_PAYOUT_FREQUENCY_FLEX_OPTIONS,
  PAYOUT_SETTINGS_PAYOUT_FREQUENCY_CORE_OPTIONS,
  PAYOUT_SETTINGS_BATCH_TIME_OPTIONS,
  CUSTOM_BATCH_TIME_VALUE,
  STANDARD_BATCH_TIME_VALUE,
  CONFIGURABLE_WINDOW,
  PROCESSOR_WINDOW,
} from 'constants/payoutSettingsConstants'

import {
  getPayoutSettingsSelector,
  getMerchantSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFlex = isFlexPlatform(credentials)
  const payoutSettingsId = get(props, 'params.payoutSettingsId')
  const payoutSettings = getPayoutSettingsSelector(state, payoutSettingsId)
  const merchantId = get(payoutSettings, 'linkedId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantDoingBusinessAs = get(merchant, 'merchantDoingBusinessAs')
  const merchantIdentityId = get(merchant, 'identityId')
  const merchantReadyToSettleUpon = get(merchant, 'readyToSettleUpon')
  const merchantFeeReadyToSettleUpon = get(merchant, 'feeReadyToSettleUpon')
  const merchantConfigurableWindowSettlement = merchantReadyToSettleUpon === CONFIGURABLE_WINDOW && merchantFeeReadyToSettleUpon === CONFIGURABLE_WINDOW
  const merchantProcessorWindowSettlement = merchantReadyToSettleUpon === PROCESSOR_WINDOW && merchantFeeReadyToSettleUpon === PROCESSOR_WINDOW
  // only show edit section for certain processors and when the merchant has specific readyToSettleUpon values
  const showSettlementBatchTimeSection = isFlex && includes(['FINIX_V1', 'DUMMY_V1'], get(merchant, 'processor')) && getFeatureFlag(SHOW_SETTLEMENT_BATCH_TIME_EDIT) && (merchantProcessorWindowSettlement || merchantConfigurableWindowSettlement)
  const merchantPath = `${MERCHANT_PATH({ credentialId, merchantId, identityId: merchantIdentityId })}?tab=merchant-payout-settings`
  const isFetching = (get(state, `merchantsR.${FETCHING}`) || get(state, `payoutSettingsR.${FETCHING}`)) && (isEmpty(merchant) || isEmpty(payoutSettings))

  const formSelector = formValueSelector(EDIT_PAYOUT_SETTINGS_FORM)
  const payoutSettingsValues = getFormValues(EDIT_PAYOUT_SETTINGS_FORM)(state)
  const payoutPaymentInstrumentId = formSelector(state, 'payoutPaymentInstrumentId')
  const feePaymentInstrumentId = formSelector(state, 'feePaymentInstrumentId')
  const type = formSelector(state, 'type')
  const isTypeGross = type === GROSS
  const merchantPaymentInstruments = get(merchant, 'paymentInstruments')

  const merchantPaymentInstrumentOptions = orderBy(map(merchantPaymentInstruments, ({ id, name, maskedAccountNumber = '', lastFour = '' }) => {
    const formattedNumber = lastFour ? lastFour : maskedAccountNumber.slice(maskedAccountNumber.length - 4, maskedAccountNumber.length)

    return selectOption(`${name} (...${formattedNumber})`, id)
  }), ({ value }) => get(merchantPaymentInstruments, `${value}.createdAt`), 'desc')

  const payoutMonthlyDay = formSelector(state, 'payoutMonthlyDay')
  const feeMonthlyDay = formSelector(state, 'feeMonthlyDay')
  const delayDaysArray = createRangeArray({ start: 1, end: 3 })
  const delayOptions = [selectOption(NO_DELAY_LABEL, ''), ...map(delayDaysArray, (day) => selectOption(`${day} days`, toNumber(day)))]
  // TODO: change dayOfMonthArray back to 1-31 once BE bug is fixed
  const dayOfMonthArray = createRangeArray({ start: 1, end: 28 })
  const dayOfMonthOptions = [selectOption(SELECT_DAY_OF_MONTH, ''), ...map(dayOfMonthArray, (day) => selectOption(moment.localeData().ordinal(toNumber(day)), toNumber(day)))]
  const payoutFrequency = formSelector(state, 'payoutFrequency')
  const batchTime = formSelector(state, 'batchTime')
  const feeFrequency = formSelector(state, 'feeFrequency')
  const readyToSettleUponOptions = [selectOption(SELECT_READY_TO_SETTLE_AT_LABEL, ''), ...map(READY_TO_SETTLE_UPON_OPTIONS, (option) => ({ value: option, label: snakeCaseToTitleCase({ key: option }) }))]
  const readyToSettleUpon = formSelector(state, 'readyToSettleUpon')
  const feeReadyToSettleUpon = formSelector(state, 'feeReadyToSettleUpon')

  const contextBarData = {
    back: {
      label: merchantDoingBusinessAs,
      path: merchantPath,
    },
  }

  const payoutTypeSectionData = {
    title: PAYOUT_TYPE,
    className: 'payout-type',
    subHeader: <div>{PAYOUT_TYPE_SUBHEADER}<a className='text-link' href={PAYOUTS_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a></div>,
    isFetching,
    fields: [
      {
        label: PAYOUT_TYPE,
        name: 'type',
        FieldComponent: RadioButton,
        options: PAYOUT_SETTINGS_TYPE_OPTIONS,
      },
    ],
  }

  const settlementsSectionData = {
    title: SETTLEMENT_SCHEDULE,
    className: 'settlements',
    subHeader: SCHEDULE_SUBHEADER,
    isFetching,
    fields: [
      {
        label: PAYOUT_SETTLEMENT_SCHEDULE,
        subLabel: SCHEDULE_SUBLABEL,
        className: 'payout-settlement-schedule',
        Component: (
          <>
            <Field
              name='payoutFrequency'
              component={RadioButton}
              options={isFlex ? PAYOUT_SETTINGS_PAYOUT_FREQUENCY_FLEX_OPTIONS : PAYOUT_SETTINGS_PAYOUT_FREQUENCY_CORE_OPTIONS}
            />

            { payoutFrequency === MONTHLY && (
              <Field
                name='payoutMonthlyDay'
                component={SelectField}
                options={dayOfMonthOptions}
                label={DAY_OF_THE_MONTH}
              />
            ) }

            { payoutFrequency === MANUAL && !isFlex && (
              <WarningMessage
                warningMessage={MANUAL_SETTLEMENT_SCHEDULE_WARNING_MESSAGE}
                icon={ALERT_ICON}
              />
            )}
          </>
        ),
      },
    ],
  }

  const settlementBatchTimeData = {
    title: SETTLEMENT_BATCH_TIME,
    className: 'settlement-batch-time',
    subHeader: SELECT_BATCH_TIME_CUTOFF,
    isFetching,
    fields: [
      {
        Component: (
          <>
            <Field
              name='batchTime'
              component={RadioButton}
              options={PAYOUT_SETTINGS_BATCH_TIME_OPTIONS}
            />

            { batchTime === CUSTOM_BATCH_TIME_VALUE && (
            <>
              <TimeFieldset
                showHoursAndMinutes
              />
              <div className='batch-warning-message flex items-center'>
                <i className={`far fa-${ALERT_ICON}`} />
                <div className='p-3 secondary'>Note: By default, a one-day delay will be added to custom batch times before settlements are ready for approval in order to ensure a sufficient account balance. Refer to this<a className='text-link' href={PAYOUT_PROFILE_SETTLEMENT_BATCH_TIME_URL} target='blank'>article</a> for more details.</div>
              </div>
            </>
            ) }
          </>
        ),
      },
    ],
  }

  const feeSettlementSectionData = {
    title: SETTLEMENT_SCHEDULE,
    className: 'fee-settlement',
    subHeader: SCHEDULE_SUBHEADER,
    isFetching,
    fields: [
      {
        label: FEE_SETTLEMENT_SCHEDULE,
        subLabel: SCHEDULE_SUBLABEL,
        className: 'fee-settlement-schedule',
        Component: (
          <>
            <Field
              name='feeFrequency'
              component={RadioButton}
              options={isFlex ? PAYOUT_SETTINGS_PAYOUT_FREQUENCY_FLEX_OPTIONS : PAYOUT_SETTINGS_PAYOUT_FREQUENCY_CORE_OPTIONS}
            />

            { feeFrequency === MONTHLY && (
              <Field
                name='feeMonthlyDay'
                component={SelectField}
                options={dayOfMonthOptions}
                label={DAY_OF_THE_MONTH}
              />
            ) }

            { feeFrequency === MANUAL && (
              <WarningMessage
                warningMessage={MANUAL_SETTLEMENT_SCHEDULE_WARNING_MESSAGE}
                icon={ALERT_ICON}
              />
            )}
          </>
        ),
      },
    ],
  }

  const transfersSectionData = {
    title: TRANSFER_SPEED,
    className: 'transfers',
    subHeader: TRANSFER_SPEED_SUBHEADER,
    isFetching,
    fields: [
      {
        label: FUNDING_TRANSFER_SPEED_LABEL,
        subLabel: FUNDING_TRANSFER_SPEED_SUBLABEL,
        className: 'transfers-funding-transfer',
        name: 'payoutRail',
        FieldComponent: RadioButton,
        options: PAYOUT_SETTINGS_RAIL_OPTIONS(),
      },
    ],
  }

  const feeTransfersSectionData = {
    title: TRANSFER_SPEED,
    className: 'fee-transfers',
    subHeader: TRANSFER_SPEED_SUBHEADER,
    isFetching,
    fields: [
      {
        label: FEE_DELAY_LABEL,
        subLabel: FEE_DELAY_SUBLABEL,
        className: 'fee-funding-transfer',
        name: 'feeRail',
        FieldComponent: RadioButton,
        options: PAYOUT_SETTINGS_RAIL_OPTIONS(),
      },
    ],
  }

  const fundingTransferDelaySectionData = {
    title: FUNDING_TRANSFER_DELAY_LABEL,
    className: 'funding-transfer-delay',
    subHeader: FUNDING_TRANSFER_DELAY_SUBLABEL,
    isFetching,
    fields: [
      {
        label: FUNDING_TRANSFER_DELAY_LABEL,
        name: 'payoutSubmissionDelayDays',
        FieldComponent: SelectField,
        options: delayOptions,
      },
    ],
  }

  const feeFundingTransferDelaySectionData = {
    title: FUNDING_TRANSFER_DELAY_LABEL,
    className: 'fee-funding-transfer-delay',
    subHeader: FUNDING_TRANSFER_DELAY_SUBLABEL,
    isFetching,
    fields: [
      {
        label: FUNDING_TRANSFER_DELAY_LABEL,
        name: 'feeSubmissionDelayDays',
        FieldComponent: SelectField,
        options: delayOptions,
      },
    ],
  }

  const reconciliationSectionData = {
    title: RECONCILIATION_TITLE,
    className: 'reconciliation',
    subHeader: RECONCILIATION_SUBHEADER,
    isFetching,
    fields: [
      {
        label: RECONCILIATION_TITLE,
        Component: (
          <>
            <Field
              name='readyToSettleUpon'
              component={SelectField}
              options={readyToSettleUponOptions}
              label={READY_TO_SETTLE_LABEL}
            />

            { readyToSettleUpon === RECONCILIATION && (
              <WarningMessage
                warningMessage={RECONCILIATION_WARNING_MESSAGE}
                icon={ALERT_ICON}
              />
            )}
          </>
        ),
      },
    ],
  }

  const feeReconciliationSectionData = {
    title: RECONCILIATION_TITLE,
    className: 'fee-reconciliation',
    subHeader: RECONCILIATION_SUBHEADER,
    isFetching,
    fields: [
      {
        label: RECONCILIATION_TITLE,
        Component: (
          <>
            <Field
              name='feeReadyToSettleUpon'
              component={SelectField}
              options={readyToSettleUponOptions}
              label={FEE_READY_TO_SETTLE_LABEL}
            />

            { feeReadyToSettleUpon === RECONCILIATION && (
              <WarningMessage
                warningMessage={RECONCILIATION_WARNING_MESSAGE}
                icon={ALERT_ICON}
              />
            )}
          </>
        ),
      },
    ],
  }

  return {
    credentials,
    contextBarData,
    payoutTypeSectionData,
    settlementsSectionData,
    settlementBatchTimeData,
    feeSettlementSectionData,
    fundingTransferDelaySectionData,
    feeFundingTransferDelaySectionData,
    reconciliationSectionData,
    feeReconciliationSectionData,
    transfersSectionData,
    feeTransfersSectionData,
    merchant,
    payoutSettingsId,
    merchantId,
    isFetching,
    isFlex,
    payoutPaymentInstrumentId,
    feePaymentInstrumentId,
    merchantPaymentInstrumentOptions,
    isTypeGross,
    merchantIdentityId,
    merchantPath,
    merchantPaymentInstruments,
    payoutSettingsValues,
    initialValues: {
      ...payoutSettings,
      batchTime: !isFlex ? undefined : merchantConfigurableWindowSettlement ? CUSTOM_BATCH_TIME_VALUE : STANDARD_BATCH_TIME_VALUE,
      time: merchantConfigurableWindowSettlement ? {
        hour: get(payoutSettings, 'payoutTimeData.hour'),
        minute: get(payoutSettings, 'payoutTimeData.minute'),
        timeMeridian: { label: get(payoutSettings, 'payoutTimeData.timeMeridian'), value: get(payoutSettings, 'payoutTimeData.timeMeridian') },
        timeZone: { label: TIME_ZONE_TO_DISPLAY_MAP[get(payoutSettings, 'payoutTimeData.timeZone')], value: get(payoutSettings, 'payoutTimeData.timeZone') },
      } : undefined,
      readyToSettleUpon: isFlex ? undefined : merchantReadyToSettleUpon,
      feeReadyToSettleUpon: isFlex ? undefined : merchantFeeReadyToSettleUpon,
    },
    readyToSettleUponOptions,
    showSettlementBatchTimeSection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    getPayoutSettings: ({ payoutSettingsId, credentials }) => dispatch(getPayoutSettingsRequest({ payoutSettingsId, credentials })),
    updatePaymentInstrumentId: ({ paymentInstrumentId, field }) => dispatch(change(EDIT_PAYOUT_SETTINGS_FORM, field, paymentInstrumentId)),
    showAddBankModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_IDENTITY_BANK_ACCOUNT, modalProps })),
    showConfirmationModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PAYOUT_SETTINGS_CONFIRMATION, modalProps })),
  }
}

class EditPayoutSettingsFormC extends Component {
  constructor(props) {
    super(props)

    this.state = { usePayoutBankAccount: false }
  }

  componentDidMount() {
    const {
      credentials,
      payoutSettingsId,
      getPayoutSettings,
    } = this.props

    getPayoutSettings({ payoutSettingsId, credentials })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      merchantId: prevMerchantId,
      payoutPaymentInstrumentId: prevPayoutPaymentInstrumentId,
      feePaymentInstrumentId: prevFeePaymentInstrumentId,
      isTypeGross: prevIsTypeGross,
    } = prevProps

    const {
      credentials,
      merchantId,
      getMerchant,
      updatePaymentInstrumentId,
      payoutPaymentInstrumentId,
      feePaymentInstrumentId,
      showAddBankModal,
      merchantIdentityId,
      isTypeGross,
    } = this.props

    const { usePayoutBankAccount: prevUsePayoutBankAccount } = prevState
    const { usePayoutBankAccount } = this.state

    // fetch merchant if merchant ID "changes" (aka refreshing the page, which is needed since this is the payout settings form, not the merchant page)
    if (!prevMerchantId && merchantId) {
      getMerchant({ merchantId, credentials })
    }

    // check default fee BA when switching to gross type & (no fee BA or payout BA === fee BA)
    if (!prevIsTypeGross && isTypeGross && (!feePaymentInstrumentId || (payoutPaymentInstrumentId === feePaymentInstrumentId))) {
      this.setState({ usePayoutBankAccount: true })
    }

    // change fee BA to payout BA if 'use payout BA' is checked and fee BA isn't payout BA
    if (!prevUsePayoutBankAccount && usePayoutBankAccount && payoutPaymentInstrumentId !== feePaymentInstrumentId) {
      updatePaymentInstrumentId({ field: 'feePaymentInstrumentId', paymentInstrumentId: payoutPaymentInstrumentId })
    }

    // change fee BA to payout BA if 'use payout BA' is already checked + payout BA is changed + payout BA isn't fee BA/showBankAccountModal
    if (usePayoutBankAccount && prevPayoutPaymentInstrumentId !== payoutPaymentInstrumentId && payoutPaymentInstrumentId !== feePaymentInstrumentId && payoutPaymentInstrumentId !== 'showAddBankModal' && payoutPaymentInstrumentId) {
      updatePaymentInstrumentId({ field: 'feePaymentInstrumentId', paymentInstrumentId: payoutPaymentInstrumentId })
    }

    // payout/fee - open create bank modal if option is selected + change select to default option (so user can re-select it)
    const showAddBankModalPayout = prevPayoutPaymentInstrumentId !== payoutPaymentInstrumentId && payoutPaymentInstrumentId === 'showAddBankModal'
    const showAddBankModalFee = prevFeePaymentInstrumentId !== feePaymentInstrumentId && feePaymentInstrumentId === 'showAddBankModal'

    if (showAddBankModalPayout || showAddBankModalFee) {
      const fieldName = showAddBankModalPayout ? 'payoutPaymentInstrumentId' : 'feePaymentInstrumentId'

      updatePaymentInstrumentId({ field: fieldName, paymentInstrumentId: '' })

      showAddBankModal({
        identityId: merchantIdentityId,
        successFunction: (paymentInstrumentId) => updatePaymentInstrumentId({ field: fieldName, paymentInstrumentId }),
        merchantId,
        warningMessage: PAYOUT_SETTINGS_BANK_ACCOUNT_WARNING,
      })
    }
  }

  render() {
    const {
      isFetching,
      merchantPaymentInstrumentOptions,
    } = this.props

    const { usePayoutBankAccount } = this.state

    const bankAccountsSectionData = {
      title: BANK_ACCOUNT,
      className: 'bank-account',
      subHeader: BANK_ACCOUNT_SUBHEADER,
      isFetching,
      fields: [
        {
          label: PAYOUT_BANK_ACCOUNT_TITLE,
          subLabel: PAYOUT_BANK_ACCOUNT_SUBLABEL,
          className: 'payout-bank-account',
          Component: (
            <>
              <Field
                name='payoutPaymentInstrumentId'
                component={SelectField}
                options={[
                  selectOption(SELECT_PAYOUT_BANK_ACCOUNT, ''),
                  ...merchantPaymentInstrumentOptions,
                  { label: ADD_NEW_BANK_ACCOUNT, value: 'showAddBankModal' },
                ]}
              />
            </>
          ),
        },
      ],
    }

    const feeBankAccountSectionData = {
      title: BANK_ACCOUNT,
      className: 'fee-bank-account',
      subHeader: FEE_BANK_ACCOUNT_SUBHEADER,
      isFetching,
      fields: [
        {
          label: FEE_BANK_ACCOUNT_TITLE,
          subLabel: FEE_BANK_ACCOUNT_SUBLABEL,
          className: 'fee-bank-account',
          Component: (
            <>
              <Field
                name='usePayoutBankAccount'
                component={CheckboxC}
                label={(
                  <div className='flex'>
                    <div>{USE_PAYOUT_BANK_ACCOUNT_LABEL}</div>
                    <TooltipLabelC
                      message={FEE_BANK_ACCOUNT_TOOLTIP}
                    />
                  </div>
                )}
                checked={usePayoutBankAccount}
                handleChange={() => this.setState({ usePayoutBankAccount: !usePayoutBankAccount })}
              />

              <Field
                name='feePaymentInstrumentId'
                component={SelectField}
                options={[
                  selectOption(SELECT_FEE_BANK_ACCOUNT, ''),
                  ...merchantPaymentInstrumentOptions,
                  { label: ADD_NEW_BANK_ACCOUNT, value: 'showAddBankModal' },
                ]}
                disabled={usePayoutBankAccount}
              />
            </>
          ),
        },
      ],
    }

    return (
      <EditPayoutSettingsForm
        bankAccountsSectionData={bankAccountsSectionData}
        feeBankAccountSectionData={feeBankAccountSectionData}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayoutSettingsFormC)
