import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  UNDERWRITING_PROFILE,
  UNDERWRITING_PROFILES,
} from 'constants/apiConstants'

const patchUnderwritingProfileAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: UNDERWRITING_PROFILE({ underwritingProfileId: id }),
    service: UNDERWRITING_PROFILES,
  })
}

export default patchUnderwritingProfileAPI
