import './AddressS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, change } from 'redux-form'
import { getDispatch } from 'state-layer/configureStore'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import convertGooglePlaceToAddressObject from 'utilities/convert/convertGooglePlaceToAddressObject'
import formatCanadianPostalCode from 'utilities/forms/format/formatCanadianPostalCode'
import formatUSAPostalCode from 'utilities/forms/format/formatUSAPostalCode'
import size from 'lodash/size'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  GOOGLE_MAP_API_KEY,
} from 'constants/googleMapConstants'

import {
  CITY,
  STATE,
  COUNTRY,
  POSTAL_CODE,
  REGION,
  CANNOT_BE_A_PO_BOX_FORWARDING_OR_REGISTERED_AGENT,
} from 'constants/language/languageConstants'

import {
  USA,
  defaultRegionLabel,
  countryNamesOptions,
  defaultPostalCodeLabel,
  countryToRegionLabelMap,
  countryToPostalCodeLabelMap,
  convertCountryISO2toISO3,
  regionNameOptionsByCountryCode,
  convertCountryISO3toISO2,
  CAN,
} from 'constants/countryConstants'

const postalCodeFormatterMap = (countryCode) => {
  switch (countryCode) {
    case CAN:
      return formatCanadianPostalCode

    case USA:
      return formatUSAPostalCode

    default:
      return null
  }
}

// preset country prop means the country is already decided and the field should be disabled and unchangeble
const Address = ({
  name = 'address',
  label = '',
  form = '',
  tooltips = {},
  helperText = {},
  noPlaceholders = false,
  showLabels = true,
  hideLine2 = false,
  useColumn = false,
  presetCountry,
  countryCode = USA,
  required = true,
}) => {
  const fieldName = (field) => `${name}.${field}`
  const ISO2CountryCode = convertCountryISO3toISO2(countryCode)
  const regionNameOptions = regionNameOptionsByCountryCode(countryCode)
  const regionLabel = get(countryToRegionLabelMap, countryCode, defaultRegionLabel)
  const postalCodeLabel = get(countryToPostalCodeLabelMap, countryCode, defaultPostalCodeLabel)
  const { addressLine1, addressLine2, city, state, postalCode, country } = tooltips
  const {
    addressLine1HelperText = CANNOT_BE_A_PO_BOX_FORWARDING_OR_REGISTERED_AGENT,
    addressLine2HelperText,
    cityHelperText,
    regionHelperText,
    postalCodeHelperText,
    countryHelperText,
  } = helperText

  const [isInputFocused, setInputFocused] = useState(false)
  const handleInputFocus = () => setTimeout(() => setInputFocused(true), 150)
  const handleInputBlur = () => setTimeout(() => setInputFocused(false), 150)
  const componentRestrictionsCountry = presetCountry ? { country: ISO2CountryCode } : {}

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: GOOGLE_MAP_API_KEY,
    debounce: 500,
    options: {
      componentRestrictions: componentRestrictionsCountry,
    },
  })

  const selectPlace = (place) => {
    if (placesService) {
      placesService.getDetails({ placeId: get(place, 'place_id') }, (placeDetails) => {
        const dispatch = getDispatch()

        const {
          line1: placeLine1,
          line2: placeLine2,
          city: placeCity,
          region: placeRegion,
          postalCode: placePostalCode,
          country: placeCountry,
        } = convertGooglePlaceToAddressObject(placeDetails)

        dispatch(change(form, `${name}.line1`, placeLine1, true, false))
        dispatch(change(form, `${name}.line2`, placeLine2, true, false))
        dispatch(change(form, `${name}.city`, placeCity, true, false))
        dispatch(change(form, `${name}.region`, placeRegion, true, false))
        dispatch(change(form, `${name}.postalCode`, placePostalCode, true, false))
        dispatch(change(form, `${name}.country`, convertCountryISO2toISO3(placeCountry), true, false))
      })
    }
  }

  const showPredictions = form && isInputFocused && size(placePredictions) > 1

  return (
    <div className='Address'>
      <div className='autocomplete-container'>
        <Field
          name={fieldName('line1')}
          label={showLabels && `${label} Line 1`}
          placeholder={!noPlaceholders && 'Line 1'}
          component={InputField}
          tooltip={addressLine1}
          ariaLabel={`${label} Line 1`}
          autoComplete={showPredictions ? 'off' : 'address-line1'}
          helperText={addressLine1HelperText}
          required={required}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(event, value) => {
            if (form) getPlacePredictions({ input: value })
            return value
          }}
        />

        <div className={`predictions ${showPredictions ? 'show' : 'hide'}`}>
          { map(placePredictions, (place) => {
            const {
              place_id: placeId,
              description,
            } = place

            return (
              <div key={placeId} onClick={() => selectPlace(place)}>
                {description}
              </div>
            )
          })}
        </div>
      </div>

      <div className='show'>
        { !hideLine2 && (
          <Field
            name={fieldName('line2')}
            label={showLabels && `${label} Line 2`}
            placeholder={!noPlaceholders && 'Line 2'}
            component={InputField}
            tooltip={addressLine2}
            ariaLabel={`${label} Line 2`}
            autoComplete='address-line2'
            required={false}
            helperText={addressLine2HelperText}
          />
        )}

        <div className={`city-region-postal flex space-between mt-0 ${useColumn ? 'useColumn' : ''}`}>
          <Field
            name={fieldName('city')}
            label={showLabels && CITY}
            placeholder={!noPlaceholders && CITY}
            component={InputField}
            tooltip={city}
            ariaLabel={`${label} ${CITY}`}
            required={required}
            autoComplete='address-level2'
            helperText={cityHelperText}
          />

          { regionNameOptions && (
            <Field
              name={fieldName('region')}
              label={showLabels && countryToRegionLabelMap[countryCode]}
              placeholder={!noPlaceholders && regionLabel}
              component={SelectField}
              tooltip={state}
              options={regionNameOptions}
              ariaLabel={`${label} ${REGION}`}
              required={required}
              autoComplete='address-level1'
              helperText={regionHelperText}
            />
          )}

          { !regionNameOptions && (
            <Field
              name={fieldName('region')}
              label={showLabels && STATE}
              placeholder={!noPlaceholders && regionLabel}
              component={InputField}
              tooltip={state}
              ariaLabel={`${label} ${STATE}`}
              required={required}
              autoComplete='address-level1'
              helperText={regionHelperText}
            />
          )}

          <Field
            name={fieldName('postalCode')}
            label={showLabels && postalCodeLabel}
            placeholder={!noPlaceholders && postalCodeLabel}
            component={InputField}
            tooltip={postalCode}
            ariaLabel={`${label} ${POSTAL_CODE}`}
            format={postalCodeFormatterMap(countryCode)}
            required={required}
            autoComplete='postal-code'
            helperText={postalCodeHelperText}
          />
        </div>

        <Field
          name={fieldName('country')}
          label={showLabels && COUNTRY}
          placeholder={!noPlaceholders && COUNTRY}
          component={SelectField}
          tooltip={country}
          options={countryNamesOptions}
          ariaLabel={`${label} ${COUNTRY}`}
          required={required}
          autoComplete='country'
          disabled={!!presetCountry}
          helperText={countryHelperText}
        />
      </div>
    </div>
  )
}

Address.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.string,
  tooltips: PropTypes.object,
  noPlaceholders: PropTypes.bool,
  showLabels: PropTypes.bool,
  hideLine2: PropTypes.bool,
  useColumn: PropTypes.bool,
  helperText: PropTypes.object,
  presetCountry: PropTypes.string,
  countryCode: PropTypes.string,
  required: PropTypes.bool,
}

export default Address
