import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import map from 'lodash/map'
import get from 'lodash/get'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'

const SubscriptionScheduleFields = [
  {
    name: 'scheduleNickname',
    label: 'Schedule Nickname',
  },
  {
    name: 'subscriptionType',
    label: 'Subscription Type',
    formatter: (subscriptionType) => subscriptionScheduleTypeMapping[subscriptionType],
  },
  {
    name: 'schedule',
    label: 'Schedule',
    formatter: (schedule, values = {}) => {
      const { subscriptionType, fixedTimeIntervalOffset, periodOffset } = values

      return convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, fixedTimeIntervalOffset, periodOffset })
    },
  },
  {
    name: 'tags',
    label: 'Tags',
    formatter: (tags) => TagsDisplaySection({
      data: map(tags, ({ name, value }) => ({ value, label: name })),
      emptyMessage: '-',
    }),
  },
]

const SubscriptionScheduleDisplayData = (values) => map(SubscriptionScheduleFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name, {}), values) : get(values, name),
}))

export default SubscriptionScheduleDisplayData
