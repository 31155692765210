import './HostedCustomerOnboardingSuccessS.scss'
import React from 'react'
import { FINIX_LOGO_BLACK } from 'constants/logoConstants'
import { ReactComponent as VerifyEmailSuccessIcon } from 'img/svg/verify-email-success-icon.svg'

import {
  YOUR_APPLICATION_HAS_BEEN_SUBMITTED,
  YOUR_APPLICATION_HAS_BEEN_SUBMITTED_DESCRIPTION,
} from 'constants/language/languageConstants'

const HostedCustomerOnboardingSuccess = () => {
  return (
    <div className='HostedCustomerOnboardingSuccess'>
      <div className='hosted-customer-onboarding-header flex center'>
        <div className='logo-container flex items-center'>
          <img className='logo' src={FINIX_LOGO_BLACK} alt='finixLogo' />
        </div>
      </div>
      <div className='flex center'>
        <div className='hosted-customer-onboarding-success flex column center'>
          <div className='img-container'>
            <VerifyEmailSuccessIcon />
          </div>
          <h5>{YOUR_APPLICATION_HAS_BEEN_SUBMITTED}</h5>
          <div className='p-2'>{YOUR_APPLICATION_HAS_BEEN_SUBMITTED_DESCRIPTION}</div>
        </div>
      </div>
    </div>
  )
}

export default HostedCustomerOnboardingSuccess
