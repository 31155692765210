import './EmailVerificationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as VerifyEmailIcon } from 'img/svg/verify-email-icon.svg'
import { FINIX_LOGO_BLACK } from 'constants/logoConstants'

import {
  VERIFY_EMAIL_SUBTEXT_STRING,
  VERIFY_EMAIL_TEXT_STRING,
  VERIFY_EMAIL_TITLE_STRING,
} from 'constants/language/languageConstants'

const EmailVerificationPending = ({
  emailAddress = '',
  showResendEmailModal = () => {},
}) => {
  return (
    <div className='EmailVerificationPending'>
      <div className='verification-modal'>
        <img src={FINIX_LOGO_BLACK} alt='finix-logo' />
        <VerifyEmailIcon />
        <h4 className='title'>{VERIFY_EMAIL_TITLE_STRING}</h4>
        <div className='text'>{VERIFY_EMAIL_TEXT_STRING}{emailAddress}</div>
        <div className='flex'>
          <div className='subtext'>{VERIFY_EMAIL_SUBTEXT_STRING}</div>
          <div className='resend-email-link' onClick={showResendEmailModal}>resend email.</div>
        </div>
      </div>
    </div>
  )
}

EmailVerificationPending.propTypes = {
  emailAddress: PropTypes.string,
  showResendEmailModal: PropTypes.func,
}

export default EmailVerificationPending
