import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPayoutLinkSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import PayoutLinkCreatedConfirmation from './PayoutLinkCreatedConfirmation'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPayoutLinkRequest from 'utilities/actions/get/getPayoutLinkRequest'
import copyToClipboard from 'utilities/copyToClipboard'
import { FETCHING } from 'constants/reducerConstants'
import { COPY } from 'constants/iconConstants'
import get from 'lodash/get'
import split from 'lodash/split'

import {
  COMPANY_PATH,
  CREATE_PAYOUT_LINK_PATH,
  PAYOUT_LINK_PATH,
} from 'constants/pathConstants'

import {
  AMOUNT,
  LINK_NAME,
  LINK_URL,
  RECIPIENT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `payoutLinksR.${FETCHING}`)
  const payoutLinkId = get(props, 'params.payoutLinkId')
  const payoutLink = getPayoutLinkSelector(state, payoutLinkId)
  const payoutLinkPath = PAYOUT_LINK_PATH({ credentialId, payoutLinkId })
  const brandingPath = `${COMPANY_PATH({ credentialId })}?tab=branding`

  const {
    nickname,
    displayTotalAmount,
    linkUrl,
    recipientFullName,
    recipientBusinessName,
  } = payoutLink

  const payoutLinkData = [
    {
      label: LINK_NAME,
      value: nickname,
    },
    {
      label: AMOUNT,
      value: displayTotalAmount,
    },
    {
      label: RECIPIENT,
      value: recipientBusinessName || recipientFullName,
    },
    {
      label: LINK_URL,
      value: <><a href={linkUrl} target='blank'>{split(linkUrl, '?')[0]}</a> <i className={`far fa-${COPY} copy-icon`} onClick={() => copyToClipboard(linkUrl)} /></>,
    },
  ]

  return {
    payoutLinkId,
    credentials,
    credentialId,
    payoutLinkData,
    payoutLinkPath,
    brandingPath,
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPayoutLink: ({ payoutLinkId, credentials }) => dispatch(getPayoutLinkRequest({ payoutLinkId, credentials })),
  }
}

class PayoutLinkCreatedConfirmationC extends Component {
  componentDidMount() {
    const {
      getPayoutLink,
      credentials,
      payoutLinkId,
    } = this.props

    getPayoutLink({ payoutLinkId, credentials })
  }

  goToNewPayoutLinkForm = () => {
    const { credentialId } = this.props

    goToPath({
      pathname: CREATE_PAYOUT_LINK_PATH({ credentialId }),
    })
  }

  render() {
    return (
      <PayoutLinkCreatedConfirmation
        {...this.props}
        goToNewPayoutLinkForm={this.goToNewPayoutLinkForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutLinkCreatedConfirmationC)
