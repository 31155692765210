import './SelectApplicationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import { SELECT_APPLICATION_FORM } from 'constants/formConstants'
import includes from 'lodash/includes'

const formatOptionLabel = ({ value = {}, label }, { context }) => {
  const { name, maskedAccountNumber } = value

  if (context === 'value') {
    return label
  }

  return label
}

const filterOption = (option = {}, inputValue) => {
  const { value = {} } = option
  const { id, name, maskedAccountNumber } = value

  return includes(id, inputValue) || includes(name, inputValue) || includes(maskedAccountNumber, inputValue)
}

const SelectApplicationForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  submitting = false,
  disabled = false,
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  applicationOptions = [],
}) => {
  return (
    <form className='SelectApplicationForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h2>Select Application</h2>

        <Field
          name='selectedApplicationId'
          component={ReactSelect}
          options={applicationOptions}
          formatOptionLabel={formatOptionLabel}
        />
      </div>

      <div className='buttons flex space-between'>
        <div className='left flex flex-start'>
          { leftButton && <Button onClick={leftButtonAction} label={leftButtonLabel} variant='secondary' className='left-button' /> }
        </div>

        <div className='right flex flex-end'>
          <Button type='submit' disabled={disabled} submitting={submitting} label={submitLabel} />
        </div>
      </div>

    </form>
  )
}

SelectApplicationForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  applicationOptions: PropTypes.array,
}

export default reduxForm({
  form: SELECT_APPLICATION_FORM,
// TODO: validate,
})(SelectApplicationForm)
