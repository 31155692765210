import map from 'lodash/map'
import numeral from 'numeral'

const convertCombinationPlanEquationArrayToString = (equation) => {
  return map(equation, (variable, index) => {
    const { coefficient = '', template = '' } = variable
    const number = numeral(coefficient).value()
    const numberAbs = Math.abs(number)
    const plusSign = index > 0 ? '+' : ''
    const sign = number < 0 ? '-' : plusSign

    return `${sign}(${numberAbs === 0 || numberAbs === 1 ? '' : `${numberAbs}*`}${template})`
  }).join('')
}

export default convertCombinationPlanEquationArrayToString
