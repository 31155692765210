import removeUndefined from 'utilities/remove/removeUndefined'
import removeEmpty from 'utilities/remove/removeEmpty'
import { EMAIL, SMS, PRINT } from 'constants/receiptConstants'
import size from 'lodash/size'
import includes from 'lodash/includes'

const backendReceiptDeliveryAttemptsModel = ({ values }) => {
  const {
    emails,
    phoneNumbers,
    sendMethods,
    devices,
  } = values

  const requestedDeliveryMethods = []

  if (includes(sendMethods, EMAIL) && size(emails) > 0) {
    requestedDeliveryMethods.push({
      type: EMAIL,
      destinations: emails,
    })
  }

  if (includes(sendMethods, SMS) && size(phoneNumbers) > 0) {
    requestedDeliveryMethods.push({
      type: SMS,
      destinations: phoneNumbers,
    })
  }

  if (includes(sendMethods, PRINT) && devices) {
    requestedDeliveryMethods.push({
      type: PRINT,
      destinations: devices,
    })
  }

  return removeEmpty(removeUndefined({
    requested_delivery_methods: size(requestedDeliveryMethods) > 0 ? requestedDeliveryMethods : undefined,
  }))
}

export default backendReceiptDeliveryAttemptsModel
