import EmailVerificationPending from './EmailVerificationPending'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import showModalAction from 'utilities/actions/showModalAction'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { RESEND_EMAIL_MODAL } from 'constants/modalConstants'

const mapStateToProps = (state) => {
  // fetch values from url
  const dashboardSandboxId = getUrlQuery('id')
  const emailAddress = getUrlQuery('email')

  return {
    dashboardSandboxId,
    emailAddress,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showResendEmailModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_EMAIL_MODAL, modalProps })),
  }
}

class EmailVerificationPendingC extends Component {
  showResendEmailModal = () => {
    const { dashboardSandboxId, showResendEmailModal, emailAddress } = this.props

    showResendEmailModal({ dashboardSandboxId, emailAddress })
  }

  render () {
    return (
      <EmailVerificationPending
        {...this.props}
        showResendEmailModal={this.showResendEmailModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPendingC)
