import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDeviceSelector } from 'state-layer/selectors'
import DeviceInfo from './DeviceInfo'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { MERCHANT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import concat from 'lodash/concat'

import {
  AMOUNT_VALUE,
  DEVICE_CONFIGURATIONS_SELECT_OPTIONS, ON_THRESHOLD_AMOUNT_VALUE,
  PAX_D135,
} from 'constants/deviceConstants'

import {
  ACTIVATE_DEVICE_MODAL,
  DEACTIVATE_DEVICE_MODAL,
  EDIT_DEVICE_DETAILS_MODAL,
  SET_IDLE_SCREEN_MODAL,
  EDIT_DEVICE_CONFIGURATIONS_MODAL,
} from 'constants/modalConstants'

import {
  FAILED,
  SUCCEEDED,
} from 'constants/statusConstants'

import {
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
  TRIPOS_CLOUD_V1,
  TRIPOS_MOBILE_V1,
} from 'constants/processorConstants'

import {
  DESCRIPTION,
  DEVICE_NAME,
  EDIT,
  FALSE,
  MERCHANT_NAME,
  PROCESSOR_GATEWAY,
  SERIAL_NUMBER,
  TRUE,
  PROMPT_SIGNATURE,
  PROMPT_SIGNATURE_TOOLTIP_MESSAGE,
  SIGNATURE_THRESHOLD_AMOUNT,
  SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG,
  PERCENTAGE_TIP_OPTIONS,
  PERCENTAGE_TIP_THRESHOLD,
  FIXED_AMOUNT_TIP_OPTIONS,
  TIP_COLLECTION_ON_TERMINAL,
  TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG,
  PROMPT_RECEIPT_CONFIRMATION,
  PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
  AVAILABLE_RECEIPT_DELIVERY_METHODS,
  AUTOMATIC_RECEIPT_DELIVERY_METHODS,
  NOT_SUPPORTED,
  NOT_SUPPORTED_ON_DEVICE_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetchingDevices = get(state, `devicesR.${FETCHING}`)
  const isFetchingFiles = get(state, `filesR.${FETCHING}`)
  const isFetching = isFetchingDevices || isFetchingFiles
  const deviceId = get(props, 'deviceId')
  const idleImageExternalLink = get(props, 'idleImageExternalLink')
  const imageUrl = get(idleImageExternalLink, 'url')
  const device = getDeviceSelector(state, deviceId)

  const [
    deviceName,
    deviceStatusEnabled,
    description,
    merchantData,
    serialNumber,
    idleMessage,
    tags,
    configurationDetails,
    displayPromptSignature,
    displaySignatureThresholdAmount,
    deviceModel,
    displayPercentOptions,
    fixedOptions,
    displayFixedOptions,
    percentTippingThreshold,
    displayPercentTippingThreshold,
    promptReceiptConfirmationValue,
    promptTipOnScreenValue,
    displayAvailableReceiptMethods,
    displayAutomaticReceiptDeliveryMethods,
    displayPromptForSignature,
    promptSignature,
    promptForSignature,
  ] = getMany(device, [
    'name',
    'enabled',
    'description',
    'merchantData',
    'serialNumber',
    'idleMessage',
    'tags',
    'configurationDetails',
    'displayPromptSignature',
    'displaySignatureThresholdAmount',
    'deviceModel',
    'displayPercentOptions',
    'fixedOptions',
    'displayFixedOptions',
    'percentTippingThreshold',
    'displayPercentTippingThreshold',
    'promptReceiptConfirmation',
    'promptTipOnScreen',
    'displayAvailableReceiptMethods',
    'displayAutomaticReceiptDeliveryMethods',
    'displayPromptForSignature',
    'promptSignature',
    'promptForSignature',
  ])

  const [
    merchantId,
    merchantName,
    gateway,
    processor,
    identityId,
    displayGateway,
  ] = getMany(merchantData, [
    'id',
    'name',
    'gateway',
    'processor',
    'identityId',
    'displayGateway',
  ])

  const showActions = !isEqual(gateway, TRIPOS_MOBILE_V1)
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  const showTagsEditButton = includes([TRIPOS_MOBILE_V1, DATACAP_V1, TRIPOS_CLOUD_V1], gateway)
  const processorGatewayValue = gateway ? gateway : processor
  const showReceiptAndTipConfigOptions = includes([DUMMY_V1, FINIX_V1], processorGatewayValue)
  const showIdleMessageSection = includes([FINIX_V1, DUMMY_V1, TRIPOS_CLOUD_V1], processorGatewayValue) && deviceModel !== PAX_D135
  const showPromptForSignatureField = includes([FINIX_V1, DUMMY_V1], processorGatewayValue)
  const showThresholdAmountField = promptSignature === AMOUNT_VALUE || promptForSignature === ON_THRESHOLD_AMOUNT_VALUE

  const deviceDetailsSection = [
    {
      type: 'data',
      data: [
        {
          label: DEVICE_NAME,
          value: deviceName,
        },
        {
          label: DESCRIPTION,
          value: description,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: MERCHANT_NAME,
          value: merchantName,
          path: MERCHANT_PATH({ credentialId, identityId, merchantId }),
        },
        {
          label: PROCESSOR_GATEWAY,
          value: displayGateway,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: SERIAL_NUMBER,
          value: serialNumber,
        },
      ],
    },
  ]

  const deviceConfigData = {
    True: SUCCEEDED,
    False: FAILED,
  }

  const deviceConfigurationData = sortBy(map(filter(DEVICE_CONFIGURATIONS_SELECT_OPTIONS, ({ visibleToCustomers, disabledForGateways }) => visibleToCustomers && !includes(disabledForGateways, processorGatewayValue)), ({ label, field, tooltip, disabledForGateways }) => {
    const fieldValue = get(configurationDetails, field)

    return {
      label,
      value: <ColorcodedStatus data={deviceConfigData} value={fieldValue ? TRUE : FALSE} />,
      tooltip,
      tooltipPosition: 'top',
    }
  }), 'label')

  const tippingAndReceiptSettings = showReceiptAndTipConfigOptions ? [
    {
      label: PROMPT_RECEIPT_CONFIRMATION,
      value: <ColorcodedStatus data={deviceConfigData} value={promptReceiptConfirmationValue ? TRUE : FALSE} />,
      tooltip: PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
      tooltipPosition: 'top',
    },
    {
      label: AVAILABLE_RECEIPT_DELIVERY_METHODS,
      value: displayAvailableReceiptMethods,
      condition: promptReceiptConfirmationValue,
    },
    {
      label: AUTOMATIC_RECEIPT_DELIVERY_METHODS,
      value: displayAutomaticReceiptDeliveryMethods,
      condition: promptReceiptConfirmationValue,
    },
    {
      label: TIP_COLLECTION_ON_TERMINAL,
      value: <ColorcodedStatus data={deviceConfigData} value={promptTipOnScreenValue ? TRUE : FALSE} />,
      tooltip: TIP_COLLECTION_ON_TERMINAL_TOOLTIP_MSG,
      tooltipPosition: 'top',
    },
    {
      label: PERCENTAGE_TIP_OPTIONS,
      value: displayPercentOptions,
      condition: promptTipOnScreenValue,
    },
    {
      label: PERCENTAGE_TIP_THRESHOLD,
      value: displayPercentTippingThreshold,
      condition: percentTippingThreshold > 0 && promptTipOnScreenValue,
    },
    {
      label: FIXED_AMOUNT_TIP_OPTIONS,
      value: displayFixedOptions,
      condition: get(fixedOptions, '[0]') > 0 && promptTipOnScreenValue,
    },
  ] : []

  const additionalDeviceConfigurationData = [
    {
      label: PROMPT_SIGNATURE,
      value: gateway === TRIPOS_CLOUD_V1 ? displayPromptSignature : NOT_SUPPORTED,
      tooltip: gateway === TRIPOS_CLOUD_V1 ? PROMPT_SIGNATURE_TOOLTIP_MESSAGE : NOT_SUPPORTED_ON_DEVICE_TOOLTIP_MSG,
      tooltipPosition: 'top',
      condition: !showPromptForSignatureField,
    },
    {
      label: PROMPT_SIGNATURE,
      value: displayPromptForSignature,
      tooltip: PROMPT_SIGNATURE_TOOLTIP_MESSAGE,
      tooltipPosition: 'top',
      condition: showPromptForSignatureField,
    },
    {
      label: SIGNATURE_THRESHOLD_AMOUNT,
      value: (gateway === TRIPOS_CLOUD_V1 || showPromptForSignatureField) ? displaySignatureThresholdAmount : NOT_SUPPORTED,
      tooltip: (gateway === TRIPOS_CLOUD_V1 || showPromptForSignatureField) ? SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG : NOT_SUPPORTED_ON_DEVICE_TOOLTIP_MSG,
      tooltipPosition: 'top',
      condition: showThresholdAmountField,
    },
  ]

  const deviceConfigurationsSectionData = convertPageSectionDataToV2(concat(deviceConfigurationData, tippingAndReceiptSettings, additionalDeviceConfigurationData), 3)

  return {
    isFetching,
    deviceId,
    deviceStatusEnabled,
    device,
    deviceName,
    credentials,
    credentialId,
    deviceDetailsSection,
    deviceConfigurationsSectionData,
    showIdleMessageSection,
    idleMessage,
    tagsSectionData,
    showActions,
    deviceDescription: description,
    serialNumber,
    processor,
    gateway,
    merchantName,
    merchantId,
    showTagsEditButton,
    processorGatewayValue,
    imageUrl,
    showIdleImage: showReceiptAndTipConfigOptions,
    showDeviceConfigurationsSection: deviceModel !== PAX_D135 && includes([TRIPOS_CLOUD_V1, DATACAP_V1, DUMMY_V1, FINIX_V1], processorGatewayValue),
  }
}

const mapDispatchToProps = (dispatch) => ({
  showEditDeviceDetailsModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_DEVICE_DETAILS_MODAL, modalProps, className: 'modal-md no-pad' })),
  showActivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: ACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-sm no-pad' })),
  showDeactivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-sm no-pad' })),
  showSetIdleScreenModal: (modalProps) => dispatch(showModalAction({ modalType: SET_IDLE_SCREEN_MODAL, modalProps, className: 'modal-sm no-pad' })),
  showEditConfigurationDetailsModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_DEVICE_CONFIGURATIONS_MODAL, modalProps, className: 'modal-md no-pad' })),
})

class DeviceInfoC extends Component {
  render() {
    const {
      showActions,
      credentialId,
      deviceName,
      deviceDescription,
      serialNumber,
      deviceStatusEnabled,
      showEditDeviceDetailsModal,
      deviceId,
      device,
      gateway,
      processor,
      processorGatewayValue,
      showSetIdleScreenModal,
      showEditConfigurationDetailsModal,
      merchantName,
      merchantId,
      idleMessage,
    } = this.props

    const deviceDetailsActions = [
      {
        label: EDIT,
        action: () => showEditDeviceDetailsModal({
          credentialId,
          deviceName,
          deviceDescription,
          serialNumber,
          deviceId,
          deviceStatusEnabled,
          gateway,
          processor,
        }),
      },
    ]

    const configurationDetailsActions = showActions ? [
      {
        label: EDIT,
        action: () => showEditConfigurationDetailsModal({
          processor,
          gateway,
          deviceName,
          device,
        }),
      },
    ] : []

    const idleMessageActions = [
      {
        label: EDIT,
        action: () => showSetIdleScreenModal({
          deviceId,
          deviceName,
          merchantName,
          idleMessage,
          processor,
          gateway,
          processorGatewayValue,
          merchantId,
        }),
      },
    ]

    return (
      <DeviceInfo
        {...this.props}
        deviceDetailsActions={deviceDetailsActions}
        configurationDetailsActions={configurationDetailsActions}
        idleMessageActions={idleMessageActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoC)
