import {
  GET_DISPUTE_EVIDENCES,
  POST_DISPUTE_EVIDENCES,
  GET_DISPUTE_ADJUSTMENT_TRANSFERS,
  GET_SETTLEMENT_ENTRIES,
  GET_SETTLEMENT_FUNDING_TRANSFERS,
  GET_IDENTITY_AUTHORIZATIONS,
  GET_IDENTITY_TRANSFERS,
  GET_IDENTITY_DISPUTES,
  GET_IDENTITY_SETTLEMENTS,
  GET_IDENTITY_PAYMENT_INSTRUMENTS,
  POST_IDENTITY_PAYMENT_INSTRUMENTS,
  GET_IDENTITY_MERCHANTS,
  POST_IDENTITY_MERCHANTS,
  GET_IDENTITY_VERIFICATIONS,
  GET_IDENTITY_VERIFICATION,
  POST_IDENTITY_VERIFICATIONS,
  GET_MERCHANT_VERIFICATIONS,
  POST_MERCHANT_VERIFICATIONS,
  GET_APPLICATION_ASSOCIATED_IDENTITIES,
  GET_APPLICATION_DISPUTES,
  GET_APPLICATION_IDENTITIES,
  GET_APPLICATION_MERCHANTS,
  GET_APPLICATION_PROCESSORS,
  POST_APPLICATION_PROCESSORS,
  GET_APPLICATION_PROCESSOR,
  GET_APPLICATION_SETTLEMENTS,
  GET_APPLICATION_TRANSFERS,
  GET_PAYMENT_INSTRUMENT_TRANSFERS,
  GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
  GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_PLATFORM_PROCESSOR_CONFIGS,
  GET_MERCHANT_TRANSFERS,
  GET_MERCHANT_AUTHORIZATIONS,
  GET_MERCHANT_SETTLEMENTS,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_SUBSCRIPTION_ENROLLMENTS,
  GET_SUBSCRIPTION_ENROLLMENT,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
  GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
  GET_MERCHANT_FEES,
  GET_ASSOCIATED_IDENTITIES,
  GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
  GET_SUBSCRIPTION_SCHEDULE_FEES,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
  GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
} from 'constants/flowConstants'

const genericConnectors = [
  {
    name: 'complianceFormTemplateFormsR',
    flows: {
      GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
    },
  },
  {
    name: 'subscriptionScheduleFeesR',
    flows: {
      GET_SUBSCRIPTION_SCHEDULE_FEES,
    },
  },
  {
    name: 'subscriptionScheduleEnrollmentsR',
    flows: {
      GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
    },
  },
  {
    name: 'merchantSubscriptionEnrollmentsR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
      GET_SUBSCRIPTION_ENROLLMENTS,
      GET_SUBSCRIPTION_ENROLLMENT,
      POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
    },
  },
  {
    name: 'merchantFeesR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_FEES,
    },
  },
  {
    name: 'subscriptionScheduleAmountsR',
    flows: {
      POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
      GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
    },
  },
  {
    name: 'settlementEntriesR',
    id: 'settlementId',
    flows: {
      GET_SETTLEMENT_ENTRIES,
    },
  },
  {
    name: 'settlementFundingTransfersR',
    id: 'settlementId',
    flows: {
      GET_SETTLEMENT_FUNDING_TRANSFERS,
    },
  },
  {
    name: 'disputeEvidencesR',
    id: 'disputeId',
    flows: {
      GET_DISPUTE_EVIDENCES,
      POST_DISPUTE_EVIDENCES,
    },
  },
  {
    name: 'disputeAdjustmentTransfersR',
    id: 'disputeId',
    flows: {
      GET_DISPUTE_ADJUSTMENT_TRANSFERS,
    },
  },
  {
    name: 'identityAuthorizationsR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_AUTHORIZATIONS,
    },
  },
  {
    name: 'identityAssociatedIdentitiesR',
    id: 'identityId',
    flows: {
      GET_ASSOCIATED_IDENTITIES,
    },
  },
  {
    name: 'identityTransfersR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_TRANSFERS,
    },
  },
  {
    name: 'merchantTransfersR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_TRANSFERS,
    },
  },
  {
    name: 'merchantSettlementsR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_SETTLEMENTS,
    },
  },
  {
    name: 'merchantAuthorizationsR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_AUTHORIZATIONS,
    },
  },
  {
    name: 'identityDisputesR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_DISPUTES,
    },
  },
  {
    name: 'identitySettlementsR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_SETTLEMENTS,
    },
  },
  {
    name: 'identityPaymentInstrumentsR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_PAYMENT_INSTRUMENTS,
      POST_IDENTITY_PAYMENT_INSTRUMENTS,
    },
  },
  {
    name: 'identityMerchantsR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_MERCHANTS,
      POST_IDENTITY_MERCHANTS,
    },
  },
  {
    name: 'identityVerificationsR',
    id: 'identityId',
    flows: {
      GET_IDENTITY_VERIFICATIONS,
      GET_IDENTITY_VERIFICATION,
      POST_IDENTITY_VERIFICATIONS,
    },
  },
  {
    name: 'merchantVerificationsR',
    id: 'merchantId',
    flows: {
      GET_MERCHANT_VERIFICATIONS,
      POST_MERCHANT_VERIFICATIONS,
    },
  },
  {
    name: 'applicationAssociatedIdentitiesR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_ASSOCIATED_IDENTITIES,
    },
  },
  {
    name: 'applicationDisputesR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_DISPUTES,
    },
  },
  {
    name: 'applicationIdentitiesR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_IDENTITIES,
    },
  },
  {
    name: 'applicationMerchantsR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_MERCHANTS,
    },
  },
  {
    name: 'applicationSettlementsR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_SETTLEMENTS,
    },
  },
  {
    name: 'applicationTransfersR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_TRANSFERS,
    },
  },
  {
    name: 'applicationProcessorsR',
    id: 'applicationId',
    flows: {
      GET_APPLICATION_PROCESSORS,
      POST_APPLICATION_PROCESSORS,
      GET_APPLICATION_PROCESSOR,
    },
  },
  {
    name: 'paymentInstrumentTransfersR',
    id: 'paymentInstrumentId',
    flows: {
      GET_PAYMENT_INSTRUMENT_TRANSFERS,
    },
  },
  {
    name: 'paymentInstrumentAuthorizationsR',
    id: 'paymentInstrumentId',
    flows: {
      GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
    },
  },
  {
    name: 'paymentInstrumentVerificationsR',
    id: 'paymentInstrumentId',
    flows: {
      GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
      // POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
    },
  },
  // {
  //   name: 'processorConfigsR',
  //   id: 'platformId',
  //   flows: {
  //     GET_PLATFORM_PROCESSOR_CONFIGS,
  //   },
  // },
]

export default genericConnectors
