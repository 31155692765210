import './DisputesInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import formatPercentage from 'utilities/formatters/formatPercentage'

import {
  TIME_FRAME,
  SUMMARY,
  EXCEPTIONS_INSIGHTS_TOOLTIP_TITLE,
  DISPUTE_RATIO,
  WIN_RATE,
  DISPUTE_TRENDS,
  DISPUTES_PER_MERCHANT,
  DISPUTES_PER_MERCHANT_TOOLTIP,
  CARD_DISPUTE_DATA,
  CARD_DISPUTE_DATA_TOOLTIP,
  ACTIVE_DISPUTE_VOLUME,
  DISPUTES_PER_MERCHANT_OR_APPLICATION,
  DISPUTES_PER_MERCHANT_OR_APPLICATION_TOOLTIP,
  DISPUTE_TRENDS_TOOLTIP,
  TOTAL_DISPUTES_VOLUME_TOOLTIP,
  DISPUTE_RATIO_TOOLTIP_ROLE_PARTNER,
  DISPUTE_RATIO_TOOLTIP_ROLE_PLATFORM,
  ACTIVE_DISPUTES_TOOLTIP_ROLE_PLATFORM,
  ACTIVE_DISPUTES_TOOLTIP_ROLE_PARTNER,
  WIN_RATE_TOOLTIP,
  TOTAL_DISPUTED_VOLUME,
  DISPUTE_REASONS,
  DISPUTE_REASONS_TOOLTIP,
  TOTAL_TRANSACTION_VOL,
  TOTAL_TRANSACTION_VOL_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  TOTAL_DISPUTE_VOLUME_BIG_NUMBER_TOTAL,
  DISPUTE_VOLUME_AND_COUNT_MIXED_TIMESERIES_BAR,
  DISPUTE_RATE_BIG_NUMBER_TOTAL,
  COUNT_OF_DISPUTES_BIG_NUMBER_TOTAL,
  ACTIVE_DISPUTE_VOLUME_BIG_NUMBER_TOTAL,
  DISPUTE_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE,
  WIN_RATE_BIG_NUMBER_TOTAL,
  COUNT_OF_WON_DISPUTES_BIG_NUMBER_TOTAL,
  DISPUTES_PER_MERCHANT_CARD_BRAND_DISPUTE_RATE_TABLE,
  COUNT_OF_ACTIVE_DISPUTES_BIG_NUMBER_TOTAL,
  CARD_DISPUTE_DATE_TABLE,
  COUNT_OF_DISPUTES_DISPUTE_RATE_BIG_NUMBER_TOTAL,
  CARD_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL,
  COUNT_OF_CARD_TRANSACTIONS_BIG_NUMBER_TOTAL,
  DISPUTE_STATES_PIE,
  DISPUTE_RESPONSE_STATES_PIE,
  DISPUTE_REASONS_TABLE,
} from 'constants/chartConstants'

const DisputesInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isStandaloneMerchant = false,
  isRolePlatformCredential = false,
  isFlex = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='DisputesInsights flex column'>
      <div className='time-frame-container flex space-between'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{TIME_FRAME}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>{EXCEPTIONS_INSIGHTS_TOOLTIP_TITLE}</p>
                <p><span className='bold'>{TOTAL_DISPUTED_VOLUME}</span>{TOTAL_DISPUTES_VOLUME_TOOLTIP}</p>
                <p><span className='bold'>{DISPUTE_RATIO}</span>{isRolePlatformCredential ? DISPUTE_RATIO_TOOLTIP_ROLE_PLATFORM : DISPUTE_RATIO_TOOLTIP_ROLE_PARTNER}</p>
                <p><span className='bold'>{ACTIVE_DISPUTE_VOLUME}</span>{isRolePlatformCredential ? ACTIVE_DISPUTES_TOOLTIP_ROLE_PLATFORM : ACTIVE_DISPUTES_TOOLTIP_ROLE_PARTNER}</p>
                <p><span className='bold'>{isRolePlatformCredential ? WIN_RATE : TOTAL_TRANSACTION_VOL}</span>{isRolePlatformCredential ? WIN_RATE_TOOLTIP : TOTAL_TRANSACTION_VOL_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={TOTAL_DISPUTED_VOLUME}
            headerData={{
              name: TOTAL_DISPUTE_VOLUME_BIG_NUMBER_TOTAL,
              formatter: (amount = 0) => formatMoney({ amount: formatNumber(amount) }),
            }}
            subHeaderData={{
              name: COUNT_OF_DISPUTES_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} disputes`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={DISPUTE_RATIO}
            headerData={{
              name: DISPUTE_RATE_BIG_NUMBER_TOTAL,
              formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
            }}
            subHeaderData={{
              name: COUNT_OF_DISPUTES_DISPUTE_RATE_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} disputes`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={ACTIVE_DISPUTE_VOLUME}
            headerData={{
              name: ACTIVE_DISPUTE_VOLUME_BIG_NUMBER_TOTAL,
              formatter: (amount = 0) => formatMoney({ amount: formatNumber(amount) }),
            }}
            subHeaderData={{
              name: COUNT_OF_ACTIVE_DISPUTES_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} disputes`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={isRolePlatformCredential ? WIN_RATE : TOTAL_TRANSACTION_VOL}
            headerData={{
              name: isRolePlatformCredential ? WIN_RATE_BIG_NUMBER_TOTAL : CARD_TRANSACTION_VOLUME_BIG_NUMBER_TOTAL,
              formatter: isRolePlatformCredential ? (percentage) => formatPercentage({ percentage, isDecimal: true }) : (amount = 0) => formatMoney({ amount: formatNumber(amount) }),
            }}
            subHeaderData={{
              name: isRolePlatformCredential ? COUNT_OF_WON_DISPUTES_BIG_NUMBER_TOTAL : COUNT_OF_CARD_TRANSACTIONS_BIG_NUMBER_TOTAL,
              formatter: isRolePlatformCredential ? (count) => `${formatNumber(count)} disputes won` : (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={DISPUTE_TRENDS}
          classNames='dispute-trends'
          chartClassNames='dispute-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={(isRolePlatformCredential && isFlex) ? [
            { name: DISPUTE_VOLUME_AND_COUNT_MIXED_TIMESERIES_BAR, height: 260 },
            { name: DISPUTE_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE, height: 260 },
            { name: DISPUTE_STATES_PIE, height: 260 },
            { name: DISPUTE_RESPONSE_STATES_PIE, height: 260 },
          ] : [
            { name: DISPUTE_VOLUME_AND_COUNT_MIXED_TIMESERIES_BAR, height: 260 },
            { name: DISPUTE_RATE_BY_CARD_BRAND_ECHARTS_TIMESERIES_LINE, height: 260 },
          ]}
          tooltipMessage={<p>{DISPUTE_TRENDS_TOOLTIP}</p>}
        />

        { !isStandaloneMerchant && (
          <div className='mtl'>
            <ChartC
              title={isRolePlatformCredential ? DISPUTES_PER_MERCHANT_OR_APPLICATION : DISPUTES_PER_MERCHANT}
              name={DISPUTES_PER_MERCHANT_CARD_BRAND_DISPUTE_RATE_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              hideTitle
              showGroupBy
              showPivots
              tooltipMessage={<p>{isRolePlatformCredential ? DISPUTES_PER_MERCHANT_OR_APPLICATION_TOOLTIP : DISPUTES_PER_MERCHANT_TOOLTIP}</p>}
            />
          </div>
        )}

        <div className='mtl'>
          <ChartC
            title={CARD_DISPUTE_DATA}
            name={CARD_DISPUTE_DATE_TABLE}
            height={280}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{CARD_DISPUTE_DATA_TOOLTIP}</p>}
          />
        </div>

        <div className='mtl'>
          <ChartC
            title={DISPUTE_REASONS}
            name={DISPUTE_REASONS_TABLE}
            height={484}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{DISPUTE_REASONS_TOOLTIP}</p>}
          />
        </div>
      </div>
    </div>
  )
}

DisputesInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.string,
  isStandaloneMerchant: PropTypes.bool,
  isRolePlatformCredential: PropTypes.bool,
  isFlex: PropTypes.bool,
}

export default DisputesInsights
