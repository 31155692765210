import './RefundS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TransferFailureDetailsC from 'components/Customer/Shared/Display/TransferFailureDetails/TransferFailureDetailsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import { transferFeesColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import { SHOW_FEE_PROFILE_DUES_ASSESSMENTS } from 'constants/featureFlagConstants'
import { resourceTableThemeV2 } from 'constants/themeConstants'
import { PATCH_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isEmpty from 'lodash/isEmpty'

import {
  BUYER_DETAILS,
  REFUND_DETAILS,
  SPLIT_DETAILS,
  ASSOCIATED_FEES,
  REFUNDS_SPLIT_DETAILS_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Refund = ({
  detailsDataSection = [],
  paymentInstrument = {},
  tagsSectionData = [],
  refund = {},
  isFetching = false,
  actions = [],
  headerData = {},
  contextBarData = {},
  showError = false,
  device = {},
  buyerIdentity = {},
  isSplitParentRefund = false,
  splitDetailsTableData = [],
  splitDetailsColumnDescriptors = [],
  feesPath = () => {},
  fees = [],
}) => {
  return (
    <div className='Refund'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      { showError && <TransferFailureDetailsC transfer={refund} /> }

      <PageSectionV2C
        title={REFUND_DETAILS}
        columns={detailsDataSection}
        isFetching={isFetching}
      />


      {isSplitParentRefund && (
        <TableC
          title={SPLIT_DETAILS}
          subtitle={REFUNDS_SPLIT_DETAILS_SUBTITLE_DESCRIPTION}
          classNames='SplitDetails Table'
          data={splitDetailsTableData}
          columnDescriptors={splitDetailsColumnDescriptors}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          noEmptyMessageIcon
          doNotSortData
          isFetching={isFetching}
        />
      )}

      <BuyerDetailsC
        title={BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      {getFeatureFlag(SHOW_FEE_PROFILE_DUES_ASSESSMENTS) && !isEmpty(fees) && (
        <TableC
          title={ASSOCIATED_FEES}
          columnDescriptors={transferFeesColumnDescriptors}
          data={fees}
          isFetching={isFetching}
          path={feesPath}
          showTableFooter={false}
          theme={resourceTableThemeV2}
          classNames='Associated-Fees-Table'
        />
      )}

      <TagsPageSectionV2
        actionType={PATCH_TRANSFER_F_REQUEST}
        data={tagsSectionData}
        record={refund}
        isFetching={isFetching}
      />
    </div>
  )
}

Refund.propTypes = {
  detailsDataSection: PropTypes.array,
  paymentInstrument: PropTypes.object,
  tagsSectionData: PropTypes.array,
  refund: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  showError: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  device: PropTypes.object,
  buyerIdentity: PropTypes.object,
  isSplitParentRefund: PropTypes.bool,
  feesPath: PropTypes.func,
  fees: PropTypes.array,
}

export default Refund
