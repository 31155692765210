import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import CardPaymentsForm from './CardPaymentsForm'
import { EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

import {
  AMEX_LABEL,
  DINERS_CLUB_LABEL,
  DISCOVER_LABEL,
  JCB_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

import {
  CURRENCIES_TO_COLLECT,
  BLENDED_FEE_PROFILE_RULES_FIELDS,
  INTERCHANGE_FEE_PROFILE_RULES_FIELDS,
  CARD_BRAND_FEE_PROFILE_RULES_FIELDS,
  CHARGEBACK_FEE_PROFILE_RULES_FIELDS,
} from 'constants/feeProfileConstants'

const guardRailsInitialValues = ({ currencies, formValues, feeProfileRules }) => {
  return reduce(currencies, (total, currency) => {
    const blended = reduce(BLENDED_FEE_PROFILE_RULES_FIELDS, (blendedTotal, field) => {
      return merge({}, blendedTotal, {
        [field]: get(formValues, `blended.${currency}.${field}`) || get(feeProfileRules, `BLENDED.${currency}.${field}`),
      })
    }, {})
    const interchange = reduce(INTERCHANGE_FEE_PROFILE_RULES_FIELDS, (interchangeTotal, field) => {
      return merge({}, interchangeTotal, {
        [field]: get(formValues, `interchange-plus.${currency}.${field}`) || get(feeProfileRules, `INTERCHANGE_PLUS.${currency}.${field}`),
      })
    }, {})

    return merge({}, total, {
      blended: {
        [currency]: {
          ...blended,
          open: get(formValues, `blended.${currency}.open`) || !isEmpty(get(feeProfileRules, `BLENDED.${currency}`)),
        },
      },
      'interchange-plus': {
        [currency]: {
          ...interchange,
          open: get(formValues, `interchange-plus.${currency}.open`) || !isEmpty(get(feeProfileRules, `INTERCHANGE_PLUS.${currency}`)),
        },
      },
    })
  }, {})
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feeProfileRulesR.${FETCHING}`, true)
  const formSelector = formValueSelector(EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM)
  const formValues = get(state, 'flowsR.formValues.card') || get(state, `form.${EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM}.values`)
  const feeProfileRules = get(props, 'feeProfileRules')
  const noRequiredRulesetSelectedError = get(state, `form.${EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM}.syncErrors.noUsdRulesetSelected`)

  const cardBrandRowData = {
    [VISA_LABEL]: {
      basisPointsMinField: 'visaBasisPointsMin',
      basisPointsMaxField: 'visaBasisPointsMax',
      fixedFeeMinField: 'visaFixedFeeMin',
      fixedFeeMaxField: 'visaFixedFeeMax',
    },
    [MASTERCARD_LABEL]: {
      basisPointsMinField: 'mastercardBasisPointsMin',
      basisPointsMaxField: 'mastercardBasisPointsMax',
      fixedFeeMinField: 'mastercardFixedFeeMin',
      fixedFeeMaxField: 'mastercardFixedFeeMax',
    },
    [AMEX_LABEL]: {
      basisPointsMinField: 'americanExpressBasisPointsMin',
      basisPointsMaxField: 'americanExpressBasisPointsMax',
      fixedFeeMinField: 'americanExpressFixedFeeMin',
      fixedFeeMaxField: 'americanExpressFixedFeeMax',
    },
    [DISCOVER_LABEL]: {
      basisPointsMinField: 'discoverBasisPointsMin',
      basisPointsMaxField: 'discoverBasisPointsMax',
      fixedFeeMinField: 'discoverFixedFeeMin',
      fixedFeeMaxField: 'discoverFixedFeeMax',
    },
    [JCB_LABEL]: {
      basisPointsMinField: 'jcbBasisPointsMin',
      basisPointsMaxField: 'jcbBasisPointsMax',
      fixedFeeMinField: 'jcbFixedFeeMin',
      fixedFeeMaxField: 'jcbFixedFeeMax',
    },
    [DINERS_CLUB_LABEL]: {
      basisPointsMinField: 'dinersClubBasisPointsMin',
      basisPointsMaxField: 'dinersClubBasisPointsMax',
      fixedFeeMinField: 'dinersClubFixedFeeMin',
      fixedFeeMaxField: 'dinersClubFixedFeeMax',
    },
  }

  const countrySections = reduce(CURRENCIES_TO_COLLECT, (total, currency) => {
    return merge({}, total, {
      [currency]: {
        showCardBrandOverridesBlendedSection: formSelector(state, `blended.${currency}.allowCardBrandOverrides`),
        showCardBrandOverridesInterchangeSection: formSelector(state, `interchange-plus.${currency}.allowCardBrandOverrides`),
        showChargebackFeesBlendedSection: formSelector(state, `blended.${currency}.allowChargebackFees`),
        showChargebackFeesInterchangeSection: formSelector(state, `interchange-plus.${currency}.allowChargebackFees`),
        showBlendedAccordion: get(formValues, `blended.${currency}.open`) || !isEmpty(get(feeProfileRules, `BLENDED.${currency}`)),
        showInterchangeAccordion: get(formValues, `interchange-plus.${currency}.open`) || !isEmpty(get(feeProfileRules, `INTERCHANGE_PLUS.${currency}`)),
        cardBrandRowData,
        isOptional: currency === USD ? false : true,
      } })
  }, {})

  const initialValues = !isFetching ? guardRailsInitialValues({ currencies: CURRENCIES_TO_COLLECT, formValues, feeProfileRules }) : null

  return {
    isFetching,
    initialValues,
    countrySections,
    formValues,
    noRequiredRulesetSelectedError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateField: (fieldName, value) => dispatch(change(EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM, fieldName, value)),
  }
}


class CardPaymentsFormC extends Component {
  render() {
    const clickAccordion = (currency, type) => {
      const { updateField, formValues } = this.props
      const currentValue = get(formValues, `${type}.${currency}.open`)
      updateField(`${type}.${currency}.open`, !currentValue)
    }

    const toggleAllowChargebackFees = (type, currency) => {
      const { updateField } = this.props
      forEach(CHARGEBACK_FEE_PROFILE_RULES_FIELDS, field => {
        updateField(`${type}.${currency}.${field}`, null)
      })
    }

    const toggleAllowCardBrandOverrides = (type, currency) => {
      const { updateField } = this.props
      forEach(CARD_BRAND_FEE_PROFILE_RULES_FIELDS, field => {
        updateField(`${type}.${currency}.${field}`, null)
      })
    }

    return (
      <CardPaymentsForm
        {...this.props}
        clickAccordion={clickAccordion}
        toggleAllowChargebackFees={toggleAllowChargebackFees}
        toggleAllowCardBrandOverrides={toggleAllowCardBrandOverrides}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentsFormC)
