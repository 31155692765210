import './LiveAccountApplicationWithdrawnDetails.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  APPLICATION_FORM_WITHDRAWN,
  LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_1,
  LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_2,
} from 'constants/language/languageConstants'

const LiveAccountApplicationWithdrawnDetails = ({
  title = APPLICATION_FORM_WITHDRAWN,
  rightTitle = '',
}) => {
  return (
    <PageSectionV2C
      title={<div className='application-withdrawn-details-title'>{title}</div>}
      rightTitle={rightTitle}
      className='LiveAccountApplicationWithdrawnDetails application-withdrawn-details-highlight'
    >
      <div className='withdrawn-details-highlight'>
        <div className='info-pt-1'>{LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_1}</div>
        <div className='info-pt-2'>{LIVE_ACCOUNT_APPLICATION_FORM_WITHDRAWN_PT_2}</div>
      </div>
    </PageSectionV2C>
  )
}

LiveAccountApplicationWithdrawnDetails.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
}

export default LiveAccountApplicationWithdrawnDetails
