import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'

const frontendPlatformProcessorConfigModel = ({ data: processorConfig }) => {
  const [
    id,
    platformId,
    processor,
    systemConfig,
    applicationConfig,
    createdAt,
    updatedAt,
  ] = getMany(processorConfig, [
    'id',
    'platform_id',
    'processor_type',
    'system_config',
    'application_config',
    'created_at',
    'updated_at',
  ])

  return removeUndefined({
    id,
    platformId,
    processor,
    systemConfig,
    applicationConfig,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
  })
}

export default frontendPlatformProcessorConfigModel
