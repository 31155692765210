import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_USER_PERMISSIONS_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_ALL_USER_PERMISSIONS } from 'constants/amplitudeConstants'
import { getPermissionsByRoleAndAccess } from 'constants/userPermissions'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'

const submitUpdateAllPermissionsForm = (values, dispatch, props) => {
  const { role, access } = values
  const { userId, permissions, credentials } = props
  const permissionsMap = keyBy(permissions, 'name')

  const newPermissions = getPermissionsByRoleAndAccess(role, access, permissions)
  const newPermissionsMap = keyBy(newPermissions, 'name')

  const valuesToSubmit = reduce(permissionsMap, (total, permission, name) => {
    const prevEnabled = get(permission, 'enabled', false)
    const enabled = get(newPermissionsMap, `${name}.enabled`, false)

    if (prevEnabled !== enabled) {
      total.push(merge({}, permission, {
        enabled,
      }))
    }

    return total
  }, [])

  if (!isEmpty(valuesToSubmit)) {
    const submitValuesMap = keyBy(valuesToSubmit, 'name')
    const newValuesOnSuccess = map(permissionsMap, (value, key) => get(submitValuesMap, key, value))

    dispatch({
      type: PATCH_USER_PERMISSIONS_F_REQUEST,
      payload: {
        values: valuesToSubmit,
        credentials,
        id: userId,
        newValuesOnSuccess,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(UPDATE_ALL_USER_PERMISSIONS, {
          credentials,
          userId,
        }),
      },
    })
  }
}

export default submitUpdateAllPermissionsForm
