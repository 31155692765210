import './EditSubscriptionFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import submitEditSubscriptionForm from 'utilities/submit/submitEditSubscriptionForm'
import validateEditSubscriptionForm from 'utilities/validate/validateEditSubscriptionForm'
import { EDIT_SUBSCRIPTION_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'

import {
  BILLING_FREQUENCY,
  BILLING_SETTING,
  BILLING_SETTINGS,
  CANCEL,
  CUSTOMER,
  EDIT_A_SUBSCRIPTION,
  EDIT_A_SUBSCRIPTION_SUBTITLE,
  EDIT_A_SUBSCRIPTION_WITHOUT_PLAN_LEGAL_COPY,
  PAYMENT_INSTRUMENT_LABEL,
  RECURRING_PRICE,
  SELECT_A_CUSTOMER_LABEL,
  STANDARD_PLAN,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_NAME,
  UPDATE_SUBSCRIPTION,
} from 'constants/language/languageConstants'

const EditSubscriptionForm = ({
  handleSubmit = () => {},
  identityPaymentInstrumentOptions = [],
  reactSelectCustomStyles = {},
  invalid = false,
  pristine = false,
  isFetching = false,
  hasSubscriptionPlan = false,
  planDetailsSectionData = [],
}) => {
  const customOptionStyles = get(reactSelectCustomStyles, 'customOption', {})
  const optionStyles = get(reactSelectCustomStyles, 'option', {})

  return (
    <form className='EditSubscriptionForm' onSubmit={handleSubmit(submitEditSubscriptionForm)}>
      <div className='form-header'>
        <h3 className='title'>{EDIT_A_SUBSCRIPTION}</h3>
        <div className='p-1'>{EDIT_A_SUBSCRIPTION_SUBTITLE}</div>
      </div>

      { isFetching && <Loader />}

      { !isFetching && (
        <div className='form-content'>
          <div className='subscription-details-section'>
            <h5 className='subtitle'>{SUBSCRIPTION_DETAILS}</h5>

            { !hasSubscriptionPlan ? (
              <Field
                name='nickname'
                className='nickname-field'
                label={SUBSCRIPTION_NAME}
                component={InputField}
              />
            ) : (
              <div className='plan-details-section'>
                <h6 className='plan-details-title'>{STANDARD_PLAN}</h6>
                <PageSectionV2C
                  columns={planDetailsSectionData}
                />
              </div>
            )}
          </div>

          {!hasSubscriptionPlan && (
            <div className='billing-settings-section'>
              <h5 className='subtitle'>{BILLING_SETTINGS}</h5>
              <div className='flex row items-flex-start'>
                <Field
                  name='billingInterval'
                  className='billing-interval-field'
                  label={BILLING_FREQUENCY}
                  component={ReactSelect}
                  isDisabled
                />

                <AmountField
                  name='amount'
                  className='billing-amount-field'
                  label={RECURRING_PRICE}
                  contextLabel={USD}
                />
              </div>

              <Field
                name='collectionMethod'
                className='collection-method-field'
                label={BILLING_SETTING}
                component={ReactSelect}
                isDisabled
              />
            </div>
          )}

          <div className='customer-details-section'>
            <h5 className='subtitle'>{CUSTOMER}</h5>

            <Field
              name='buyerIdentityId'
              className='buyer-identity-field'
              label={SELECT_A_CUSTOMER_LABEL}
              component={ReactSelect}
              isDisabled
            />

            <Field
              name='buyerInstrumentId'
              className='payment-instrument-field'
              label={PAYMENT_INSTRUMENT_LABEL}
              component={ReactSelect}
              options={identityPaymentInstrumentOptions}
              customStyles={{
                option: (styles, state) => (state.data.value === 'newPaymentInstrument' ? customOptionStyles : optionStyles),
              }}
            />
          </div>

          <div className='description flex p-2'>{EDIT_A_SUBSCRIPTION_WITHOUT_PLAN_LEGAL_COPY}</div>
        </div>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button className='submit-button' type='submit' disabled={pristine || invalid || isFetching} submitting={isFetching} label={UPDATE_SUBSCRIPTION} />
          </div>
        </div>
      </div>
    </form>
  )
}

EditSubscriptionForm.propTypes = {
  handleSubmit: PropTypes.func,
  identityPaymentInstrumentOptions: PropTypes.array,
  reactSelectCustomStyles: PropTypes.object,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasSubscriptionPlan: PropTypes.bool,
  planDetailsSectionData: PropTypes.array,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_SUBSCRIPTION_FORM,
  validate: validateEditSubscriptionForm,
})(EditSubscriptionForm)
