import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_SCHEDULE_AMOUNTS,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const postSubscriptionScheduleAmountAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: SUBSCRIPTION_SCHEDULE_AMOUNTS({ subscriptionScheduleId: id }),
    service: SUBSCRIPTION,
  })
}

export default postSubscriptionScheduleAmountAPI
