import { fieldEmpty } from 'utilities/validate'
import formatMoney from 'utilities/formatters/formatMoney'
import { CAPTURE_AMOUNT } from 'constants/language/languageConstants'
import numeral from 'numeral'

const validateCaptureAuthorizationForm = (values, props) => {
  const {
    captureAmount,
    addToAuthAmount,
    totalCaptureAmount,
    totalSubtractedCaptureAmount,
    captureAmountCardNotPresent,
    subtractFromAuthAmount,
  } = values

  const {
    originalAmount,
    maxTransactionAmount,
  } = props

  const invalidAmount = numeral(captureAmountCardNotPresent).value() > numeral(originalAmount).value()
  const lowerThanAuthAmount = numeral(subtractFromAuthAmount).value() > numeral(originalAmount).value()
  const notOverAuthAmount = numeral(totalCaptureAmount).value() <= numeral(originalAmount).value()
  const notLowerThanAuthAmount = numeral(totalSubtractedCaptureAmount).value() >= numeral(originalAmount).value()
  const greaterThanMaxTransactionAmount = numeral(totalCaptureAmount).value() > maxTransactionAmount
  const is100PercentGreaterThanOriginalAmount = numeral(addToAuthAmount).value() > numeral(originalAmount).value()
  const is200PercentGreaterThanOriginalAmount = (numeral(totalCaptureAmount).value()) <= (numeral(originalAmount).value() * 2)

  return {
    captureAmountCardNotPresent: fieldEmpty(captureAmountCardNotPresent, 'Capture amount') || (invalidAmount && `You can capture up to ${formatMoney({ amount: originalAmount })}.`),
    captureAmount: fieldEmpty(captureAmount, CAPTURE_AMOUNT) || (invalidAmount && `You can capture up to ${formatMoney({ amount: originalAmount })}.`),
    addToAuthAmount: fieldEmpty(addToAuthAmount, 'Amount to be added') ||
      (is100PercentGreaterThanOriginalAmount && 'Total tip amount cannot exceed 100% of the original authorization.'),
    totalCaptureAmount: fieldEmpty(totalCaptureAmount, 'Total capture amount') ||
      (notOverAuthAmount && 'Please enter an amount greater than the authorized amount or select a different adjustment option') ||
      (!is200PercentGreaterThanOriginalAmount && 'Total capture amount cannot exceed 200% of the original authorization.') ||
      (greaterThanMaxTransactionAmount && 'Total capture amount cannot exceed the maximum merchant max transaction amount.'),
    totalSubtractedCaptureAmount: fieldEmpty(totalSubtractedCaptureAmount, 'Total capture amount') ||
      (notLowerThanAuthAmount && 'Please enter an amount less than the authorized amount or select a different adjustment option'),
    subtractFromAuthAmount:
      (notLowerThanAuthAmount && 'Please enter an amount less than the authorized amount or select a different adjustment option.') ||
      (lowerThanAuthAmount && 'Please enter an amount less than original authorized amount')
    ,
  }
}

export default validateCaptureAuthorizationForm
