import './RequestAdditionalInformationForUnderwritingEntityFormS.scss'
import { Field, reduxForm } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import NestedCheckboxesTable from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import submitRequestAdditionInfoOrRejectUnderwritingEntityForm from 'utilities/submit/submitRequestAdditionInfoOrRejectUnderwritingEntityForm'
import validateRequestAdditionalInformationForUnderwritingEntityForm from 'utilities/validate/validateRequestAdditionalInformationForUnderwritingEntityForm'
import { REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM } from 'constants/formConstants'
import { REQUEST_ADDITIONAL_INFO_UNDERWRITING_ENTITY_OPTIONS } from 'constants/reviewQueueConstants'

import {
  CANCEL,
  REQUEST,
  SELECT_REASONS_FOR_MORE_INFO,
} from 'constants/language/languageConstants'

const RequestAdditionalInformationForUnderwritingEntityForm = ({
  closeModal = () => {},
  currentSelectedEvents = {},
  handleSubmit = () => {},
  invalid = false,
  isFetching = false,
}) => {
  return (
    <form className='RequestAdditionalInformationForUnderwritingEntityForm' onSubmit={handleSubmit(submitRequestAdditionInfoOrRejectUnderwritingEntityForm)}>
      <div className='modal-content'>
        <div className='p-2 flex header'>{SELECT_REASONS_FOR_MORE_INFO}</div>
        <Field
          name='selectedEvents'
          component={NestedCheckboxesTable}
          options={REQUEST_ADDITIONAL_INFO_UNDERWRITING_ENTITY_OPTIONS}
          formName={REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM}
          showHeaderCheckboxes={false}
          currentSelectedEvents={currentSelectedEvents}
          showActions={false}
        />
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} label={REQUEST} submitting={isFetching} />
      </div>
    </form>
  )
}

RequestAdditionalInformationForUnderwritingEntityForm.propTypes = {
  closeModal: PropTypes.func,
  currentSelectedEvents: PropTypes.object,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
}

export default reduxForm({
  form: REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_FORM,
  validate: validateRequestAdditionalInformationForUnderwritingEntityForm,
})(RequestAdditionalInformationForUnderwritingEntityForm)
