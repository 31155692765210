import { normalizeTimestamp } from 'constants/normalizationConstants'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'

// TODO: maybe we should just always pre-normalize all timeStamps (like we do amounts) and get rid of displayDates and displayAmounts
const normalizeMap = {
  updatedAt: normalizeTimestamp,
  createdAt: normalizeTimestamp,
  readyToSettleAt: normalizeTimestamp,
  dueAt: normalizeTimestamp,
  validFrom: normalizeTimestamp,
  validUntil: normalizeTimestamp,
}

const convertFieldItemToExportData = (fields, data) => reduce(fields, (total, fieldValues, fieldName) => {
  const fieldTitle = get(fieldValues, 'title')
  const fieldFormatter = get(fieldValues, 'formatter', (value) => value)
  const fieldValue = get(data, fieldName, '')
  const normalizer = get(normalizeMap, fieldName, (value) => value)
  const displayValue = fieldValue ? normalizer(fieldValue) : fieldValue
  const formattedDisplayValue = fieldFormatter ? fieldFormatter(displayValue) : displayValue

  return merge({}, total, { [fieldTitle]: formattedDisplayValue })
}, {})

export default convertFieldItemToExportData
