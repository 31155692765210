import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisputesInsights from './DisputesInsights'
import getExceptionChartsRequest from 'utilities/actions/get/getExceptionChartsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const isFetching = get(state, `chartsR.${FETCHING}`, false)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})
  const credentials = getCurrentCredentials(state)
  const isFlex = isFlexPlatform()
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  return {
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
    isRolePlatformCredential,
    isStandaloneMerchant,
    isFlex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDisputesCharts: ({ queries }) => dispatch(getExceptionChartsRequest({ queries })),
  }
}

class DisputesInsightsC extends Component {
  componentDidMount() {
    const {
      getDisputesCharts,
    } = this.props

    getDisputesCharts({
      queries: {
        tab: 'disputes',
      },
    })
  }

  render() {
    return (
      <DisputesInsights {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputesInsightsC)
