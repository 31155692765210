import './DeactivateTeamMemberS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  DEACTIVATE_TEAM_MEMBER_MSG,
  KEEP_ACTIVE_CONFIRMATION_LABEL,
  DEACTIVATE_TEAM_MEMBER_CONFIRMATION_LABEL,
} from 'constants/language/languageConstants'

const DeactivateTeamMember = ({
  memberName = '',
  dashboardName = '',
  closeModal = () => {},
  deactivateTeamMember = () => {},
}) => {
  return (
    <div className='DeactivateTeamMember'>
      <div className='deactivate-description'>This will deactivate {memberName} from {dashboardName} dashboard and they will no longer have access to the dashboard.</div>
      <div className='deactivate-confirmation-msg'>{DEACTIVATE_TEAM_MEMBER_MSG}</div>

      <div className='btn-container flex flex-end items-center'>
        <Button variant='tertiary' className='cancel-button' onClick={closeModal} label={KEEP_ACTIVE_CONFIRMATION_LABEL} />
        <Button variant='destructive' label={DEACTIVATE_TEAM_MEMBER_CONFIRMATION_LABEL} onClick={deactivateTeamMember} />
      </div>
    </div>
  )
}

DeactivateTeamMember.propTypes = {
  memberName: PropTypes.string,
  dashboardName: PropTypes.string,
  closeModal: PropTypes.func,
  deactivateTeamMember: PropTypes.func,
}

export default DeactivateTeamMember
