import { parseUrlQueries } from 'utilities/parseUrlQueries'
import head from 'lodash/head'
import get from 'lodash/get'

const getCurrentPlatformName = () => {
  const queryPlatform = get(parseUrlQueries(), 'platform') || localStorage.getItem('platformName')
  const hostPlatform = head(get(window, 'location.hostname', '').split('.'))
  const normalizedHostPlatform = head(hostPlatform.split('-'))
  // TODO:possibly will need a map here to map hostname -> platform name, but most of them will be the same due to naming convention

  return queryPlatform || normalizedHostPlatform || 'finix'
}

export default getCurrentPlatformName
