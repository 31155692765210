import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateQuickTransactionForm from './CreateQuickTransactionForm'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import getUrlQuery from 'utilities/get/getUrlQuery'
import removeUndefined from 'utilities/remove/removeUndefined'
import updateUrlQueries from 'utilities/updateUrlQueries'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_ACH_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { CREATE_TRANSACTION_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { USA } from 'constants/countryConstants'
import get from 'lodash/get'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import capitalize from 'lodash/capitalize'

import {
  L2_ENABLED_FORM_FIELDS,
  L3_ENABLED_FORM_FIELDS,
} from 'constants/processorConstants'

import {
  change,
  untouch,
  formValueSelector,
} from 'redux-form'

import {
  USD,
  COUNTRY_TO_CURRENCY_NAME_MAP,
} from 'constants/currencyConstants'

import {
  getIdentitiesSelector,
  getMerchantSelector,
} from 'state-layer/selectors'

import {
  BILLING_ADDRESS_1_LINE_LABEL,
  BILLING_ADDRESS_2_LINE_LABEL,
  CARD_NUMBER_EMPTY_ERROR_MESSAGE,
  CARD_NUMBER_LABEL,
  COUNTRY,
  CVV,
  EXPIRATION_DATE,
  NAME,
  NOT_PROVIDED,
  ZIP_LABEL,
  ZIPCODE_EMPTY_ERROR_MESSAGE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const [
    credentials,
    applicationId,
    identityMaxTransactionAmount,
  ] = getMany(props, [
    'credentials',
    'applicationId',
    'identityMaxTransactionAmount',
  ])

  const environment = get(credentials, 'environment')
  const isFetchingDashboardConfig = get(state, `dashboardConfigR.${FETCHING}`)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const identities = getIdentitiesSelector(state)
  const formSelector = formValueSelector(CREATE_TRANSACTION_FORM)
  const existingFormValues = get(state, 'flowsR.formValues.createPayment')
  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantCountry = get(merchant, 'country', USA)
  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[merchantCountry] || USD
  const transferType = getUrlQuery('type') || 'sale'
  const isSale = transferType === 'sale'
  const amount = formSelector(state, 'amount') || get(existingFormValues, 'amount') || getUrlQuery('amount')
  const description = formSelector(state, 'description') || get(existingFormValues, 'description') || getUrlQuery('transferDescription')
  const additionalDataSelection = formSelector(state, 'additionalDataSelection.value') || get(existingFormValues, 'additionalDataSelection.value')
  const levelThreeItemsValue = formSelector(state, 'levelThreeItems') || get(existingFormValues, 'levelThreeItems')
  const canUseACHPayment = isSale && getFeatureFlag(SHOW_ACH_VIRTUAL_TERMINAL)

  const initialValues = removeUndefined({
    amount,
    description,
    additionalDataSelection: {
      label: capitalize(NOT_PROVIDED),
      value: 'N/A',
    },
  })

  return removeUndefined({
    isFetchingDashboardConfig,
    credentials,
    isStandaloneMerchant,
    isRolePlatformCredential,
    applicationId,
    identityMaxTransactionAmount,
    identities,
    environment,
    initialValues,
    additionalDataSelection,
    levelThreeItemsValue,
    transferType,
    isSale,
    canUseACHPayment,
    currency,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCreateTransactionFormField: ({ value, field }) => dispatch(change(CREATE_TRANSACTION_FORM, field, value)),
    untouchCreateTransactionFormField: ({ field }) => dispatch(untouch(CREATE_TRANSACTION_FORM, field)),
  }
}

class CreateQuickTransactionFormC extends Component {
  state = {
    tokenForm: null,
    formHasErrors: true,
  }

  componentDidMount() {
    if (window.Finix) {
      const options = {
        showAddress: true,
        showPlaceholders: true,
        styles: {
          default: {
            border: '1px solid #7D90A5',
            borderRadius: '4px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            padding: '0px 8px',
          },
          error: {
            border: '1px solid #F04438',
          },
          focus: {
            border: '1px solid #7D90A5',
          },
        },
        labels: {
          name: `${NAME} *`,
          expiration_date: `${EXPIRATION_DATE} *`,
          security_code: `${CVV} *`,
          address_line1: BILLING_ADDRESS_1_LINE_LABEL,
          address_line2: BILLING_ADDRESS_2_LINE_LABEL,
          address_postal_code: `${ZIP_LABEL} *`,
          address_country: `${COUNTRY} *`,
          number: CARD_NUMBER_LABEL,
        },
        placeholders: {
          name: 'Jane Doe',
          number: '0000 0000 0000 0000',
          security_code: '123',
        },
        requiredFields: [
          'name',
          'address_postal_code',
          'address_country',
        ],
        errorMessages: {
          name: CARD_NUMBER_EMPTY_ERROR_MESSAGE,
          address_postal_code: ZIPCODE_EMPTY_ERROR_MESSAGE,
        },
        onUpdate: (state, binInfo, formHasErrors) => {
          this.setState({ formHasErrors })
        },
      }

      this.setState({ tokenForm: window.Finix.CardTokenForm('payment-instrument-form', options) })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      additionalDataSelection,
      updateCreateTransactionFormField,
      untouchCreateTransactionFormField,
    } = this.props

    const {
      additionalDataSelection: prevAdditionalDataSelection,
    } = prevProps

    // when switching additional data options, clear out redux form values associated with level 2 or level 3 fields only
    if (!isEqual(prevAdditionalDataSelection, additionalDataSelection)) {
      forEach(concat(L2_ENABLED_FORM_FIELDS, L3_ENABLED_FORM_FIELDS), (field) => {
        updateCreateTransactionFormField({
          field,
          value: null,
        })

        untouchCreateTransactionFormField({ field })
      })
    }
  }

  setTransferType = (query) => updateUrlQueries({ type: query })

  render() {
    const {
      tokenForm,
      formHasErrors,
    } = this.state

    return (
      <CreateQuickTransactionForm
        {...this.props}
        tokenForm={tokenForm}
        formHasErrors={formHasErrors}
        setTransferType={this.setTransferType}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuickTransactionFormC)
