import './UnderwritingReviewReportsSummaryS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  CIRCLE_MINUS_ICON,
} from 'constants/iconConstants'

import {
  ADVERSE_MEDIA_REVIEW,
  ONBOARDING_REVIEW,
  SANCTIONS_REVIEW,
} from 'constants/reviewQueueConstants'

import {
  ADVERSE_MEDIA_SCREENING,
  BANK_ACCOUNT_VERIFICATION,
  EMAIL_ADDRESS_VERIFICATION,
  IDENTITY_VERIFICATION,
  SANCTIONS_SCREENING,
} from 'constants/language/languageConstants'

const UnderwritingReviewReportsSummary = ({
  identitiesData = {},
  sanctionsData = {},
  bankAccountsData = {},
  emailsData = {},
  adverseMediaData = {},
  isStandaloneUnderwriting = false,
  reviewType = '',
}) => {
  const showIdentitiesData = reviewType === ONBOARDING_REVIEW
  const showSanctionsData = reviewType === ONBOARDING_REVIEW || reviewType === SANCTIONS_REVIEW
  const showEmailsData = reviewType === ONBOARDING_REVIEW
  const showAdverseMediaData = reviewType === ONBOARDING_REVIEW || reviewType === ADVERSE_MEDIA_REVIEW
  const showBankAccountsData = reviewType === ONBOARDING_REVIEW

  return (
    <div className='UnderwritingReviewReportsSummary'>
      {showIdentitiesData && (
      <>
        {!isEmpty(identitiesData) ? (
          <div className='summary-section'>
            <div className='flex items-center'>
              {get(identitiesData, 'isRejectedOrReview') ? <i className={`fa fa-${ALERT_ICON}`} /> : <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />}
              <div className='p-2-bold title'>{IDENTITY_VERIFICATION}</div>
              <div className='p-2-bold count-string'>{get(identitiesData, 'countString')}</div>
            </div>
            <div className='description'>{get(identitiesData, 'description')}</div>
          </div>
        ) : (
          <div className='summary-section'>
            <div className='flex items-center'>
              <i className={`fa ${CIRCLE_MINUS_ICON}`} />
              <div className='p-2-bold title'>{IDENTITY_VERIFICATION}</div>
              <div className='p-2-bold count-string'>(0 entities evaluated)</div>
            </div>
          </div>
        )}
      </>
      )}
      {showSanctionsData && (
      <>
        {!isEmpty(sanctionsData) ? (
          <div className='summary-section'>
            <div className='flex items-center'>
              {get(sanctionsData, 'isRejectedOrReview') ? <i className={`fa fa-${ALERT_ICON}`} /> : <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />}
              <div className='p-2-bold title'>{SANCTIONS_SCREENING}</div>
              <div className='p-2-bold count-string'>{get(sanctionsData, 'countString')}</div>
            </div>
            <div className='p-2 description'>{get(sanctionsData, 'description')}</div>
          </div>
        ) : (
          <div className='summary-section'>
            <div className='flex items-center'>
              <i className={`fa ${CIRCLE_MINUS_ICON}`} />
              <div className='p-2-bold title'>{SANCTIONS_SCREENING}</div>
              <div className='p-2-bold count-string'>(0 entities evaluated)</div>
            </div>
          </div>
        )}
      </>
      )}
      {showEmailsData && (
      <>
        {!isEmpty(emailsData) && !isStandaloneUnderwriting ? (
          <div className='summary-section'>
            <div className='flex items-center'>
              {get(emailsData, 'isRejectedOrReview') ? <i className={`fa fa-${ALERT_ICON}`} /> : <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />}
              <div className='p-2-bold title'>{EMAIL_ADDRESS_VERIFICATION}</div>
              <div className='p-2-bold count-string'>{get(emailsData, 'countString')}</div>
            </div>
            <div className='p-2 description'>{get(emailsData, 'description')}</div>
          </div>
        ) : (
          <div className='summary-section'>
            <div className='flex items-center'>
              <i className={`fa ${CIRCLE_MINUS_ICON}`} />
              <div className='p-2-bold title'>{EMAIL_ADDRESS_VERIFICATION}</div>
              <div className='p-2-bold count-string'>(0 entities evaluated)</div>
            </div>
          </div>
        )}
      </>
      )}
      {showAdverseMediaData && (
      <>
        {!isEmpty(adverseMediaData) ? (
          <div className='summary-section'>
            <div className='flex items-center'>
              {get(adverseMediaData, 'isRejectedOrReview') ? <i className={`fa fa-${ALERT_ICON}`} /> : <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />}
              <div className='p-2-bold title'>{ADVERSE_MEDIA_SCREENING}</div>
              <div className='p-2-bold count-string'>{get(adverseMediaData, 'countString')}</div>
            </div>
            <div className='p-2 description'>{get(adverseMediaData, 'description')}</div>
          </div>
        ) : (
          <div className='summary-section'>
            <div className='flex items-center'>
              <i className={`fa ${CIRCLE_MINUS_ICON}`} />
              <div className='p-2-bold title'>{ADVERSE_MEDIA_SCREENING}</div>
              <div className='p-2-bold count-string'>(0 entities evaluated)</div>
            </div>
          </div>
        )}
      </>
      )}
      {showBankAccountsData && (
      <>
        {!isEmpty(bankAccountsData) ? (
          <div className='summary-section'>
            <div className='flex items-center'>
              {get(bankAccountsData, 'isRejectedOrReview') ? <i className={`fa fa-${ALERT_ICON}`} /> : <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />}
              <div className='p-2-bold title'>{BANK_ACCOUNT_VERIFICATION}</div>
              <div className='p-2-bold count-string'>{get(bankAccountsData, 'countString')}</div>
            </div>
            <div className='p-2 description'>{get(bankAccountsData, 'description')}</div>
          </div>
        ) : (
          <div className='summary-section'>
            <div className='flex items-center'>
              <i className={`fa ${CIRCLE_MINUS_ICON}`} />
              <div className='p-2-bold title'>{BANK_ACCOUNT_VERIFICATION}</div>
              <div className='p-2-bold count-string'>(0 entities evaluated)</div>
            </div>
          </div>
        )}
      </>
      )}
    </div>
  )
}

UnderwritingReviewReportsSummary.propTypes = {
  identitiesData: PropTypes.object,
  sanctionsData: PropTypes.object,
  bankAccountsData: PropTypes.object,
  emailsData: PropTypes.object,
  adverseMediaData: PropTypes.object,
  isStandaloneUnderwriting: PropTypes.bool,
  reviewType: PropTypes.string,
}

export default UnderwritingReviewReportsSummary
