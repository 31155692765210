import React from 'react'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { accessFormIconMap } from 'constants/statusConstants'

import {
  ACCOUNT_EXECUTIVE,
  CREATED_ON,
  STATUS,
  DEAL_TYPE,
  ID,
  DEAL_NAME,
  COUNTRY,
} from 'constants/language/languageConstants'

import {
  ACCESS_FORM_STATE_TO_DISPLAY_MAP,
  ACCESS_FORM_STATUS_MAP,
} from 'constants/underwritingConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(DEAL_NAME, ['dealName', 'displayDealType'], {
    headerFormatter: () => <TableItemSubtitle title={DEAL_NAME} subtitle={DEAL_TYPE} />,
    formatter: ({ dealName, displayDealType }) => (
      <TableItemSubtitle
        title={dealName}
        subtitle={displayDealType}
      />
    ),
  }),
  column(COUNTRY, 'displayCountry'),
  column(ACCOUNT_EXECUTIVE, 'requestedBy', { className: 'large divider' }),
  column(STATUS, 'displayStatus', {
    className: 'grow',
    formatter: ({ status }) => <ColorcodedStatus data={ACCESS_FORM_STATUS_MAP} value={ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] ? ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] : status} customIconMap={accessFormIconMap} titleCase={false} />,
  }),
]

export default columnDescriptors
