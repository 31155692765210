import React, { Component } from 'react'
import { connect } from 'react-redux'
import Resources from './Resources'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { USER_GUIDE_URL } from 'constants/urlConstants'

import {
  MERCHANT_DASHBOARD_USER_GUIDE,
  MERCHANT_DASHBOARD_USER_GUIDE_DESCRIPTION,
} from 'constants/language/languageConstants'


const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const merchantData = [
    {
      fileName: MERCHANT_DASHBOARD_USER_GUIDE,
      description: MERCHANT_DASHBOARD_USER_GUIDE_DESCRIPTION,
      fileLink: USER_GUIDE_URL,
    },
  ]

  const partnerPlatformData = [
    {
      fileName: MERCHANT_DASHBOARD_USER_GUIDE,
      description: MERCHANT_DASHBOARD_USER_GUIDE_DESCRIPTION,
      fileLink: USER_GUIDE_URL,
    },
  ]

  return {
    merchantData,
    partnerPlatformData,
    isRoleMerchantCred: isRoleMerchant({ credentials }),
  }
}

class ResourcesC extends Component {
  render() {
    return (
      <Resources
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ResourcesC)
