import './ExportConfirmationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  EXPORTING_IN_PROGRESS,
  // EXPORT_CONFIRMATION_MSG_PT_1,
  EXPORT_CONFIRMATION_MSG_PT_2,
  VIEW_EXPORTS_PAGE,
  DONE,
} from 'constants/language/languageConstants'

const ExportConfirmationModal = ({
  // currentUserEmail = '',
  closeModal = () => {},
  redirectToExportsLanding = () => {},
}) => {
  return (
    <div className='ExportConfirmationModal'>
      <h4>{`${EXPORTING_IN_PROGRESS}...`}</h4>
      <div className='msg'>{EXPORT_CONFIRMATION_MSG_PT_2}</div>

      <div className='buttons flex flex-end'>
        <Button onClick={redirectToExportsLanding} label={VIEW_EXPORTS_PAGE} variant='ghost' className='view-exports-btn' />
        <Button onClick={closeModal} label={DONE} className='done-btn' />
      </div>
    </div>
  )
}

ExportConfirmationModal.propTypes = {
  // currentUserEmail: PropTypes.string,
  closeModal: PropTypes.func,
  redirectToExportsLanding: PropTypes.func,
}

export default ExportConfirmationModal
