import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import PlatformProcessorConfig from './PlatformProcessorConfig'
import { getPlatformProcessorConfigSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import { SYSTEM_CONFIG_FORM, APPLICATION_CONFIG_FORM, EDIT_PROCESSOR_CONFIG_FORM } from 'constants/formConstants'
import { EDIT_PROCESSOR_CONFIG } from 'constants/modalConstants'
import { SYSTEM_CONFIG } from 'constants/configConstants'
import { PATCH_PLATFORM_PROCESSOR_CONFIG_F_REQUEST } from 'constants/flowConstants'
import { UPDATED_AT_LABEL } from 'constants/language/languageConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getPlatformProcessorConfigRequest from 'utilities/actions/get/getPlatformProcessorConfigRequest'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `processorConfigsR.${FETCHING}`)
  const platformId = get(props, 'params.platformId')
  const processorConfigId = get(props, 'params.processorConfigId')
  const processorConfig = getPlatformProcessorConfigSelector(state, processorConfigId)

  const systemConfigFormSelector = formValueSelector(SYSTEM_CONFIG_FORM)
  const systemConfigFieldValue = systemConfigFormSelector(state, 'processorConfigFieldsArray')
  const applicationConfigFormSelector = formValueSelector(APPLICATION_CONFIG_FORM)
  const applicationConfigFieldValue = applicationConfigFormSelector(state, 'processorConfigFieldsArray')

  const formSelector = formValueSelector(EDIT_PROCESSOR_CONFIG_FORM)
  const currentFieldValue = formSelector(state, 'configPair[0].value')

  const headerTitle = processorConfigId

  const [
    processor,
    systemConfig,
    applicationConfig,
    displayCreatedAt = '',
    displayUpdatedAt = '',
  ] = getMany(processorConfig, [
    'processor',
    'systemConfig',
    'applicationConfig',
    'displayCreatedAt',
    'displayUpdatedAt',
  ])

  const detailsSectionData = [
    {
      label: 'Processor',
      value: processor,
    },
    {
      label: UPDATED_AT_LABEL,
      value: displayUpdatedAt,
    },
  ]

  return {
    isFetching,
    platformId,
    processorConfigId,
    processorConfig,
    credentials,
    headerTitle,
    headerSubTitle: displayCreatedAt,
    detailsSectionData,
    systemConfig,
    applicationConfig,
    systemConfigFieldValue,
    applicationConfigFieldValue,
    processorType: processor,
    currentFieldValue,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformProcessorConfig: ({ platformId, processorConfigId, credentials }) => dispatch(getPlatformProcessorConfigRequest({ platformId, processorConfigId, credentials })),
    showEditPlatformProcessorConfigModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PROCESSOR_CONFIG, modalProps })),
  }
}

class PlatformProcessorConfigC extends Component {
  componentDidMount() {
    const {
      credentials,
      platformId,
      processorConfigId,
      getPlatformProcessorConfig,
    } = this.props

    getPlatformProcessorConfig({ platformId, processorConfigId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { credentials, platformId, processorConfigId, getPlatformProcessorConfig } = this.props
    const { processorConfigId: oldProcessorConfigId } = prevProps

    if (!isEqual(oldProcessorConfigId, processorConfigId)) {
      getPlatformProcessorConfig({ platformId, processorConfigId, credentials })
    }
  }

  updateConfigValue = (index, sectionId) => {
    const {
      credentials,
      systemConfigFieldValue,
      applicationConfigFieldValue,
      showEditPlatformProcessorConfigModal,
      platformId,
      processorConfigId,
      processorType,
    } = this.props

    const fieldValues = sectionId === SYSTEM_CONFIG ? systemConfigFieldValue : applicationConfigFieldValue

    const [
      configKey,
      configValue,
    ] = getMany(fieldValues[index], [
      'name',
      'value',
    ])

    showEditPlatformProcessorConfigModal({
      credentials,
      configKey,
      configValue,
      sectionId,
      actionType: PATCH_PLATFORM_PROCESSOR_CONFIG_F_REQUEST,
      platformId,
      processorConfigId,
      processorType,
    })
  }

  render() {
    return (
      <PlatformProcessorConfig
        updateConfigValue={this.updateConfigValue}
        {...this.props}
      />
    )
  }
}

PlatformProcessorConfigC.propTypes = {
  credentials: PropTypes.object,
  systemConfigFieldValue: PropTypes.string,
  applicationConfigFieldValue: PropTypes.string,
  showEditPlatformProcessorConfigModal: PropTypes.func,
  platformId: PropTypes.string,
  processorConfigId: PropTypes.string,
  processorType: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformProcessorConfigC)
