import { GET_MERCHANT_CHARTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantChartsRequest = ({
  queries,
}) => createAction({
  type: GET_MERCHANT_CHARTS_F_REQUEST,
  queries,
})

export default getMerchantChartsRequest
