import './MerchantsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { MERCHANTS } from 'constants/linkConstants'
import { GET_MERCHANTS } from 'constants/flowConstants'
import { MERCHANT_ACCOUNTS_LEARN_MORE_LINK } from 'constants/urlConstants'
import { MERCHANTS_ICON } from 'constants/iconConstants'
import { getMerchantIdentitySelector } from 'state-layer/selectors'

import {
  MERCHANT_ACCOUNTS_RESOURCE_TITLE,
  MERCHANT_ACCOUNTS_SUB_TITLE,
  MERCHANT_ACCOUNTS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const Merchants = ({
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  merchantPath = () => {},
  quickFilters,
}) => {
  return (
    <div className='Merchants'>
      <HeaderV2C
        title={MERCHANT_ACCOUNTS_RESOURCE_TITLE}
        subTitle={MERCHANT_ACCOUNTS_SUB_TITLE}
        learnMoreLink={MERCHANT_ACCOUNTS_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_MERCHANTS}
        columnDescriptors={columnDescriptors}
        selector={getMerchantIdentitySelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={MERCHANTS}
        allowedFilters={allowedFilters}
        path={merchantPath}
        isFetching={isFetching}
        emptyMessage={MERCHANT_ACCOUNTS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${MERCHANTS_ICON}`}
        fileNameCSV={MERCHANT_ACCOUNTS_RESOURCE_TITLE}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={MERCHANTS}
      />
    </div>
  )
}

Merchants.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  merchantPath: PropTypes.func,
  quickFilters: PropTypes.array,
}

export default Merchants
