import removeUndefined from 'utilities/remove/removeUndefined'
import isArray from 'lodash/isArray'

const column = (title = '', value = '', optionals = {}) => {
  const {
    className,
    headerFormatter,
    formatter,
    sort,
    condition,
    highlight = false,
    actionIndex,
    actionLabel,
  } = optionals

  return removeUndefined({
    getKeys: isArray(value) ? value : [value],
    title,
    className,
    headerFormatter,
    formatter,
    sort,
    condition,
    highlight,
    actionIndex,
    actionLabel,
  })
}

export default column
