import frontendPaymentLinkModel from 'utilities/create/models/frontendPaymentLinkModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPaymentLinksModel = ({ data: paymentLinks }) => {
  const paymentLinkModels = map(paymentLinks, (paymentLink) => frontendPaymentLinkModel({ data: paymentLink }))

  return keyBy(paymentLinkModels, 'id')
}

export default frontendPaymentLinksModel
