import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditDeviceDetailsFormC from 'components/Customer/Forms/EditDeviceDetailsForm/EditDeviceDetailsFormC'
import { EDIT_DEVICE_DETAILS } from 'constants/language/languageConstants'

const EditDeviceDetailsModal = ({
  credentialId,
  deviceName,
  deviceDescription,
  serialNumber,
  deviceId,
  deviceStatusEnabled,
  gateway,
  processor,
}) => {
  return (
    <GenericModal
      title={EDIT_DEVICE_DETAILS}
      subTitle={`Editing details for ${deviceName}`}
      className='EditDeviceDetailsModal'
      Component={EditDeviceDetailsFormC}
      credentialId={credentialId}
      deviceName={deviceName}
      deviceDescription={deviceDescription}
      serialNumber={serialNumber}
      deviceId={deviceId}
      deviceStatusEnabled={deviceStatusEnabled}
      gateway={gateway}
      processor={processor}
    />
  )
}

EditDeviceDetailsModal.propTypes = {
  credentialId: PropTypes.string,
  deviceName: PropTypes.string,
  deviceDescription: PropTypes.string,
  serialNumber: PropTypes.string,
  deviceId: PropTypes.string,
  deviceStatusEnabled: PropTypes.bool,
  gateway: PropTypes.string,
  processor: PropTypes.string,
}

export default EditDeviceDetailsModal
