import React from 'react'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import column from 'utilities/table/column'
import map from 'lodash/map'
import get from 'lodash/get'
import keys from 'lodash/keys'

import {
  createdAtRange,
  updatedAtRange,
} from 'utilities/table/sort'

import {
  ID,
  BUSINESS_NAME,
  UPDATED_ON,
  ASSIGNEE,
  UNDERWRITING_STATUS,
  PROCESSOR,
  MERCHANT_PROVISIONING_STATUS,
  RELATED_FILES,
  FILES,
  FILE,
  UPLOADED as UPLOADED_TEXT,
  APPLICATION,
  CREATED_ON,
  COUNTRY,
  NEXT_RUN,
  MERCHANT_ACCOUNT,
  REVIEW_STATUS,
  CADENCE,
  LATEST_RUN,
} from 'constants/language/languageConstants'

export const onboardingReviewColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdOn }) => <DateTimestampColumn timestamp={createdOn} />,
    sort: createdAtRange,
  }),
  column(UPDATED_ON, 'displayUpdatedOn', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedOn }) => <DateTimestampColumn timestamp={updatedOn} />,
  }),
  column(BUSINESS_NAME, 'entity.businessName', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={BUSINESS_NAME} subtitle={APPLICATION} />,
    formatter: (review) => <TableItemSubtitle title={get(review, 'entity.businessName')} subtitle={get(review, 'entity.application.businessName')} />,
  }),
  column(COUNTRY, 'displayCountry', { className: 'small' }),
  column(ASSIGNEE, 'assignee', { className: 'medium divider' }),
  column(UNDERWRITING_STATUS, 'state', {
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
  column(PROCESSOR, 'entity.merchantProcessorsString', {
    className: 'medium',
    formatter: (review) => {
      const id = get(review, 'id')
      const merchants = get(review, 'entity.merchants')

      if (!merchants) return '-'

      return <div className='processors'>{ map(merchants, ({ processor }) => (<div className='processor' key={`${id}-${processor}`}>{processor}</div>)) }</div>
    },
  }),
  column(MERCHANT_PROVISIONING_STATUS, 'entity.merchantProcessorOnboardingStatesString', {
    className: 'medium',
    formatter: (review) => {
      const id = get(review, 'id')
      const merchants = get(review, 'entity.merchants')
      if (!merchants) return '-'

      return <ul className='onboarding-states'>{ map(merchants, ({ onboardingState, processor }) => (<li key={`${id}-${processor}-${onboardingState}`}><OnboardingState value={onboardingState} /></li>)) }</ul>
    },
  }),
  column(RELATED_FILES, 'files', {
    className: 'small',
    formatter: (review) => {
      const files = get(review, 'entity.files', {})

      const totalFiles = keys(files).length

      if (totalFiles) {
        const fileString = totalFiles > 1 ? FILES : FILE

        return `${totalFiles} ${fileString} ${UPLOADED_TEXT}`
      }

      return '-'
    },
  }),
]

export const otherReviewColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(LATEST_RUN, 'displayLastRun', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={LATEST_RUN} />,
    formatter: ({ lastRun }) => <DateTimestampColumn timestamp={lastRun} />,
  }),
  column(MERCHANT_ACCOUNT, 'entity.businessName', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT} subtitle={APPLICATION} />,
    formatter: (review) => <TableItemSubtitle title={get(review, 'entity.businessName')} subtitle={get(review, 'entity.application.businessName')} />,
  }),
  column(REVIEW_STATUS, 'state', {
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
  column(ASSIGNEE, 'assignee', { className: 'medium divider' }),
  column(NEXT_RUN, 'displayNextRun', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={NEXT_RUN} />,
    formatter: ({ nextRun }) => <DateTimestampColumn timestamp={nextRun} />,
  }),
  column(CADENCE, 'cadence', { className: 'small' }),
  column(CREATED_ON, 'displayCreatedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdOn }) => <DateTimestampColumn timestamp={createdOn} />,
    sort: createdAtRange,
  }),
]
