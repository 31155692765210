import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { goBack } from 'state-layer/history'
import { PATCH_CREDENTIAL_F_REQUEST } from 'constants/flowConstants'
import { EDIT_TEAM_MEMBER } from 'constants/amplitudeConstants'

const patchTeamMemberRequest = ({
  memberId,
  dashboardAccountName,
  firstName,
  lastName,
  rolesChoice,
  host,
  dashboardId,
  pushToCardEnabled,
  paymentFacilitatorEnabled,
  credentials,
  email,
  dispatch,
}) => createAction({
  type: PATCH_CREDENTIAL_F_REQUEST,
  id: memberId,
  values: {
    credential_name: dashboardAccountName,
    first_name: firstName,
    last_name: lastName,
    dashboard_user_role: rolesChoice,
    dashboard_host: host,
    dashboard_id: dashboardId,
    push_to_card_enabled: pushToCardEnabled,
    payment_facilitator_enabled: paymentFacilitatorEnabled,
  },
  meta: {
    successCallback: () => {
      // redirect user back to team members page
      dispatch(goBack)
    },
    actionId: sendAmplitudeActionEvent(EDIT_TEAM_MEMBER, {
      credentials,
      email,
      role: rolesChoice,
      memberId,
    }),
  },
})

export default patchTeamMemberRequest
