import isPlainObject from 'lodash/isPlainObject'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'

const removeValues = (obj, values = [null, undefined], nested = false) => {
  const newObj = {}
  const valuesArr = isArray(values) ? values : [values]

  Object.keys(obj).forEach(key => {
    const val = obj[key]

    if (!includes(valuesArr, val)) {
      if (nested && isPlainObject(val)) {
        newObj[key] = removeValues(val, values, nested)
      } else {
        newObj[key] = val
      }
    }
  })

  return newObj
}

export default removeValues
