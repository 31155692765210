import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'

import {
  JPEG,
  PNG,
  TIFF,
  PDF,
} from 'constants/fileUploaderConstants'

const evidenceFileLimit = {
  [JPEG]: 50000,
  [PNG]: 50000,
  [TIFF]: 1000000,
  [PDF]: 1000000,
}

const validateEvidenceFile = (file) => {
  if (isEmpty(file)) {
    return 'Please attach a file'
  }

  const {
    name,
    size,
    type,
  } = file

  const invalidType = !has(evidenceFileLimit, type)

  if (invalidType) {
    return `${name}: file type '${type}' is not allowed`
  }

  const maxSize = get(evidenceFileLimit, type, 1000000)
  const invalidSize = size > maxSize

  const maxSizeText = numeral(maxSize).format('0.00 b')
  const sizeText = numeral(size).format('0.00 b')

  if (invalidSize) {
    return `${name}: file size ${sizeText} exceed max size (${maxSizeText})`
  }

  return false
}

const validateUploadEvidenceForm = ({ file } = {}, { config } = {}) => {
  return {
    file: validateEvidenceFile(file),
  }
}

export default validateUploadEvidenceForm
