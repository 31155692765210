import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstrumentVerifications from './PaymentInstrumentVerifications'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_PAYMENT_INSTRUMENT_VERIFICATION } from 'constants/modalConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const isFetching = get(state, `paymentInstrumentVerificationsR.${FETCHING}`)

  const flowValues = {
    paymentInstrumentId,
  }

  return {
    paymentInstrumentId,
    flowValues,
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreatePaymentInstrumentVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_VERIFICATION, modalProps })),
  }
}

class PaymentInstrumentVerificationsC extends Component {
  render() {
    const { paymentInstrumentId, availableProcessors, showCreatePaymentInstrumentVerificationModal } = this.props

    const actions = [
      {
        label: 'Create Verification',
        action: () => { showCreatePaymentInstrumentVerificationModal({ paymentInstrumentId, availableProcessors }) },
        className: 'create-payment-instrument-verification-button',
      },
    ]

    return <PaymentInstrumentVerifications actions={actions} {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInstrumentVerificationsC)
