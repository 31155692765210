import getCurrentUserRoles from 'utilities/get/getCurrentUserRoles'
import includes from 'lodash/includes'

import {
  ADMINISTRATOR,
  CLIENT_ADMINISTRATOR,
} from 'constants/roleConstants'

const isAdminOrClientAdmin = () => {
  const roles = getCurrentUserRoles()

  return includes(roles, ADMINISTRATOR) || includes(roles, CLIENT_ADMINISTRATOR)
}

export default isAdminOrClientAdmin
