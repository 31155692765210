import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_METHOD_CONFIGURATIONS } from 'constants/apiConstants'

const getPaymentMethodConfigurationAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    queries,
    path: PAYMENT_METHOD_CONFIGURATIONS,
  })
}

export default getPaymentMethodConfigurationAPI
