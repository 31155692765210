import { take, put, fork } from 'redux-saga/effects'
import frontendChartModel from 'utilities/create/models/frontendChartModel'

import {
  GET_CHART_DELTA_F_REQUEST,
  GET_CHART_DELTA_F_SUCCESS,
} from 'constants/flowConstants'

import getChartDeltaAPI from 'api/dashboard/get/getChartDeltaAPI'

const fetchChart = function * ({ payload }) {
  const { id, queries } = payload
  const { data } = yield getChartDeltaAPI({ id, queries })

  yield put({
    type: GET_CHART_DELTA_F_SUCCESS,
    payload: {
      id,
      queries,
      newValues: frontendChartModel({ data }),
    },
  })
}

const getChartDeltaF = function * () {
  while (true) {
    const { payload } = yield take([GET_CHART_DELTA_F_REQUEST])

    // fork request to init non-blocking fetch event
    yield fork(fetchChart, { payload })
  }
}

export default getChartDeltaF
