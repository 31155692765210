import './EmailTemplatesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const EmailTemplates = ({
  headerTitle = '',
  headerSubTitle = '',
  tabs = [],
}) => {
  return (
    <div className='EmailTemplates'>
      <HeaderV2C
        title={headerTitle}
        subTitle={headerSubTitle}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

EmailTemplates.propTypes = {
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  tabs: PropTypes.array,
}

export default EmailTemplates
