import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import convertToMilitaryTime from 'utilities/convert/convertToMilitaryTime'
import toNumber from 'lodash/toNumber'

import {
  CUSTOM_BATCH_TIME_VALUE,
  GROSS,
  MONTHLY,
} from 'constants/payoutSettingsConstants'

const backendPayoutSettingsModel = ({ values = {} }) => {
  const {
    type,
    payoutPaymentInstrumentId,
    feePaymentInstrumentId,
    usePayoutBankAccount,
    payoutRail,
    feeRail,
    payoutFrequency,
    payoutMonthlyDay,
    feeFrequency,
    feeMonthlyDay,
    payoutSubmissionDelayDays,
    feeSubmissionDelayDays,
    batchTime,
    time,
  } = values

  const [
    hour,
    minute,
    timeMeridian,
    timeZone,
  ] = getMany(time, [
    'hour',
    'minute',
    'timeMeridian.value',
    'timeZone.value',
  ])

  const militaryHour = convertToMilitaryTime({ hour, meridian: timeMeridian, useTwoDigitHourFormat: true })
  const startTime = hour ? militaryHour + minute : undefined

  const isTypeGross = type === GROSS
  const payoutSettings = {
    payment_instrument_id: payoutPaymentInstrumentId,
    frequency: payoutFrequency,
    day_of_month: payoutFrequency === MONTHLY ? toNumber(payoutMonthlyDay) : undefined,
    submission_delay_days: toNumber(payoutSubmissionDelayDays),
    rail: payoutRail,
    start_time: startTime,
    time_zone: timeZone,
  }

  const feeSettings = {
    payment_instrument_id: usePayoutBankAccount ? payoutPaymentInstrumentId : feePaymentInstrumentId,
    frequency: feeFrequency,
    day_of_month: feeFrequency === MONTHLY ? toNumber(feeMonthlyDay) : undefined,
    submission_delay_days: toNumber(feeSubmissionDelayDays),
    rail: feeRail,
    start_time: startTime,
    time_zone: timeZone,
  }

  const payoutProfile = isTypeGross ? {
    gross: {
      payouts: payoutSettings,
      fees: feeSettings,
    },
  } : {
    net: payoutSettings,
  }

  return removeUndefined({
    type,
    ...payoutProfile,
  })
}

export default backendPayoutSettingsModel
