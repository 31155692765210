import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComplianceFormTemplates from './ComplianceFormTemplates'
import getPlatformRequest from 'utilities/actions/get/getPlatformRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentPlatformId from 'utilities/get/getCurrentPlatformId'
import { FETCHING } from 'constants/reducerConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'

import {
  COMPLIANCE_FORM_TEMPLATES_PATH,
  CREATE_COMPLIANCE_FORM_TEMPLATE_PATH,
} from 'constants/pathConstants'

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state) => {
  const isFetching = get(state, `complianceFormTemplatesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const complianceFormTemplatesPath = COMPLIANCE_FORM_TEMPLATES_PATH({ credentialId })
  const platformId = getCurrentPlatformId(state)

  return {
    quickFilters,
    isFetching,
    complianceFormTemplatesPath,
    credentials,
    credentialId,
    platformId,
  }
}

const mapDispatchToProps = {
  getPlatform: getPlatformRequest,
}

class ComplianceFormTemplatesC extends Component {
  componentDidMount() {
    const { platformId, getPlatform, credentials } = this.props

    if (platformId) getPlatform({ platformId, credentials })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { platformId: prevPlatformId } = prevProps
    const { platformId, getPlatform, credentials } = this.props

    if (!prevPlatformId && platformId) {
      getPlatform({ platformId, credentials })
    }
  }

  render() {
    const { credentialId, selectSingleItem } = this.props

    const actions = selectSingleItem ? [] : [{
      label: 'Create New Template',
      link: CREATE_COMPLIANCE_FORM_TEMPLATE_PATH({ credentialId }),
      className: 'createComplianceFormTemplate',
    }]

    return (
      <ComplianceFormTemplates
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceFormTemplatesC)
