import { fieldEmpty } from 'utilities/validate'

const validateCreateCredentialForm = ({
  credentialName,
  userName,
  password,
  credentialEmail,
}) => {
  return {
    credentialName: fieldEmpty(credentialName, 'Credential name'),
    userName: fieldEmpty(userName, 'Username'),
    password: fieldEmpty(password, 'Password'),
    credentialEmail: fieldEmpty(credentialEmail, 'Credential email'),
  }
}

export default validateCreateCredentialForm
