import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import concat from 'lodash/concat'

// eslint-disable-next-line consistent-return
const mergeConcatArrays = (a, b) => mergeWith({}, a, b, (aVal, bVal) => {
  if (isArray(aVal) && isArray(bVal)) {
    return concat(aVal, bVal)
  }
})

export default mergeConcatArrays
