import numeral from 'numeral'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  CORPORATION_VALUE,
  PRIVATE_CORPORATION_VALUE,
  PUBLIC_CORPORATION_VALUE,
} from 'constants/identityConstants'

const formatPaymentSolutionsBackend = (acceptedPaymentTypes = {}) => {
  const paymentSolutions = []
  if (get(acceptedPaymentTypes, 'inPersonPayments')) {
    paymentSolutions.push('IN_PERSON_PAYMENTS')
  }
  if (get(acceptedPaymentTypes, 'onlinePayments')) {
    paymentSolutions.push('ONLINE_PAYMENTS')
  }
  return paymentSolutions
}

const backendAccessFormModel = ({ values }) => {
  const {
    id,
    customerType,
    pricingType,
    sandboxApplicationId,
    einVerificationFileName,
    einVerificationFileId,
    bankAccountVerificationFileName,
    bankAccountVerificationFileId,
    businessData,
    beneficialOwners,
    controlPerson,
    processingData,
    bankAccountData,
    additionalDocuments,
    country,
  } = values

  const [
    businessName,
    doingBusinessAs,
    businessDescription,
    businessType,
    businessOwnershipType,
    businessIncorporationDate,
    businessTaxId,
    businessMerchantCount,
    businessWebsite,
    businessWebsiteDomains,
    businessEmail,
    businessPhone,
    businessAddress,
  ] = getMany(businessData, [
    'businessName',
    'doingBusinessAs',
    'businessDescription',
    'businessType',
    'businessOwnershipType',
    'businessIncorporationDate',
    'businessTaxId',
    'businessMerchantCount',
    'businessWebsite',
    'businessWebsiteDomains',
    'businessEmail',
    'businessPhone',
    'businessAddress',
  ])

  const [
    acceptedPaymentTypes,
    mccCode,
    defaultStatementDescriptor,
    annualTransactionCount,
    annualCardVolume,
    annualACHVolume,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
  ] = getMany(processingData, [
    'acceptedPaymentTypes',
    'mccCode',
    'defaultStatementDescriptor',
    'annualTransactionCount',
    'annualCardVolume',
    'annualACHVolume',
    'maxCardTransactionAmount',
    'maxACHTransactionAmount',
    'ecommercePercentage',
    'cardPresentPercentage',
    'businessToBusinessVolumePercentage',
    'businessToConsumerVolumePercentage',
    'otherVolumePercentage',
  ])

  const [
    businessAddressLine1,
    businessAddressLine2,
    businessAddressCity,
    businessAddressRegion,
    businessAddressPostalCode,
    businessAddressCountry,
  ] = getMany(businessAddress, [
    'line1',
    'line2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  const backendMccValues = map(mccCode, (code) => { return code.value })

  const businessAddressValues = removeUndefined({
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    city: businessAddressCity,
    region: businessAddressRegion,
    postal_code: businessAddressPostalCode,
    country: businessAddressCountry,
  })

  const einVerificationValues = removeUndefined({
    name: einVerificationFileName,
    file_id: einVerificationFileId,
  })

  const cardVolumeDistributionValues = removeUndefined({
    ecommerce_percentage: ecommercePercentage ? numeral(ecommercePercentage).value() : undefined,
    card_present_percentage: cardPresentPercentage ? numeral(cardPresentPercentage).value() : undefined,
  })

  const volumeDistributionByBusinessValues = removeUndefined({
    business_to_business_volume_percentage: businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : undefined,
    business_to_consumer_volume_percentage: businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : undefined,
    other_volume_percentage: otherVolumePercentage ? numeral(otherVolumePercentage).value() : undefined,
  })

  const normalizedBusinessType = includes([PRIVATE_CORPORATION_VALUE, PUBLIC_CORPORATION_VALUE], businessType) ? CORPORATION_VALUE : businessType

  const backendBusinessDataValues = removeUndefined({
    business_name: businessName,
    doing_business_as: doingBusinessAs,
    business_description: businessDescription,
    business_type: normalizedBusinessType,
    business_ownership_type: businessOwnershipType,
    business_incorporation_date: businessIncorporationDate,
    business_tax_id: businessTaxId,
    business_merchant_count: businessMerchantCount,
    business_website: businessWebsite,
    business_website_domains: businessWebsiteDomains,
    business_email: businessEmail,
    business_phone: businessPhone,
    business_ein_form: isEmpty(einVerificationValues) ? undefined : einVerificationValues,
    business_address: isEmpty(businessAddressValues) ? undefined : businessAddressValues,
    accepted_payment_types: isEmpty(acceptedPaymentTypes) ? undefined : formatPaymentSolutionsBackend(acceptedPaymentTypes),
    mcc: isEmpty(backendMccValues) ? undefined : backendMccValues,
    default_statement_descriptor: defaultStatementDescriptor,
    annual_transaction_count: annualTransactionCount,
    annual_card_volume: annualCardVolume ? numeral(annualCardVolume).value() : undefined,
    annual_ach_volume: annualACHVolume ? numeral(annualACHVolume).value() : undefined,
    max_transaction_amount: maxCardTransactionAmount ? numeral(maxCardTransactionAmount).value() : undefined,
    ach_max_transaction_amount: maxACHTransactionAmount ? numeral(maxACHTransactionAmount).value() : undefined,
    card_volume_distribution: isEmpty(cardVolumeDistributionValues) ? undefined : cardVolumeDistributionValues,
    volume_distribution_by_business_type: isEmpty(volumeDistributionByBusinessValues) ? undefined : volumeDistributionByBusinessValues,
    business_additional_documents: isEmpty(additionalDocuments) ? undefined : additionalDocuments,
  })

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress = {},
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth = {},
    controlPersonTaxId,
    controlPersonPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'firstName',
    'lastName',
    'email',
    'personalAddress',
    'principalPercentageOwnership',
    'dateOfBirth',
    'taxId',
    'phone',
    'title',
  ])

  const personalAddressValues = removeUndefined({
    line1: controlPersonPersonalAddress.line1,
    line2: controlPersonPersonalAddress.line2,
    region: controlPersonPersonalAddress.region,
    postal_code: controlPersonPersonalAddress.postalCode,
    city: controlPersonPersonalAddress.city,
    country: controlPersonPersonalAddress.country,
  })

  const controlPersonDobValues = removeUndefined({
    month: controlPersonDateOfBirth.month,
    day: controlPersonDateOfBirth.day,
    year: controlPersonDateOfBirth.year,
  })

  const backendControlPersonValues = removeUndefined({
    first_name: controlPersonFirstName,
    last_name: controlPersonLastName,
    title: controlPersonTitle,
    personal_address: isEmpty(personalAddressValues) ? undefined : personalAddressValues,
    dob: isEmpty(controlPersonDobValues) ? undefined : controlPersonDobValues,
    tax_id: controlPersonTaxId,
    percentage_ownership: controlPersonPrincipalPercentageOwnership ? numeral(controlPersonPrincipalPercentageOwnership).value() : undefined,
    phone: controlPersonPhone,
    email: controlPersonEmail,
  })

  const backendBeneficialOwnerValues = (isEmpty(beneficialOwners) && !isEqual(beneficialOwners, [])) ? undefined : map(beneficialOwners, (beneficialOwner) => {
    const [
      beneficialOwnerFirstName,
      beneficialOwnerLastName,
      beneficialOwnerEmail,
      beneficialOwnerPersonalAddress = {},
      beneficialOwnerPrincipalPercentageOwnership,
      beneficialOwnerDateOfBirth = {},
      beneficialOwnerTaxId,
      beneficialOwnerPhone,
      beneficialOwnerTitle,
      beneficialOwnerIndex,
    ] = getMany(beneficialOwner, [
      'firstName',
      'lastName',
      'email',
      'personalAddress',
      'principalPercentageOwnership',
      'dateOfBirth',
      'taxId',
      'phone',
      'title',
      'index',
    ])

    return removeUndefined({
      index: beneficialOwnerIndex,
      first_name: beneficialOwnerFirstName,
      last_name: beneficialOwnerLastName,
      title: beneficialOwnerTitle,
      personal_address: removeUndefined({
        line1: beneficialOwnerPersonalAddress.line1,
        line2: beneficialOwnerPersonalAddress.line2,
        region: beneficialOwnerPersonalAddress.region,
        postal_code: beneficialOwnerPersonalAddress.postalCode,
        city: beneficialOwnerPersonalAddress.city,
        country: beneficialOwnerPersonalAddress.country,
      }),
      dob: {
        month: beneficialOwnerDateOfBirth.month,
        day: beneficialOwnerDateOfBirth.day,
        year: beneficialOwnerDateOfBirth.year,
      },
      tax_id: beneficialOwnerTaxId,
      percentage_ownership: beneficialOwnerPrincipalPercentageOwnership ? numeral(beneficialOwnerPrincipalPercentageOwnership).value() : undefined,
      phone: beneficialOwnerPhone,
      email: beneficialOwnerEmail,
    })
  })

  const [
    bankAccountName,
    bankAccountAccountType,
    bankAccountBankCode,
    bankAccountAccountNumber,
    transitNumber,
    institutionNumber,
    thirdParty,
    thirdPartyToken,
    bankAccountVerification,
  ] = getMany(bankAccountData, [
    'name',
    'accountType',
    'bankCode',
    'accountNumber',
    'transitNumber',
    'institutionNumber',
    'thirdParty',
    'thirdPartyToken',
    'bankAccountVerification',
  ])

  const bankAccountVerificationValues = removeUndefined({
    name: bankAccountVerificationFileName,
    file_id: bankAccountVerificationFileId,
  })

  const backendPaymentInstrumentValues = removeUndefined({
    name: bankAccountName,
    bank_code: bankAccountBankCode,
    account_number: bankAccountAccountNumber,
    transit_number: transitNumber,
    institution_number: institutionNumber,
    account_type: bankAccountAccountType,
    bank_account_verification: bankAccountVerification !== null ? (isEmpty(bankAccountVerificationValues) ? undefined : bankAccountVerificationValues) : null,
    country,
    third_party: thirdParty,
    third_party_token: thirdPartyToken,
  }, true)

  const backendPrincipalDataValues = removeUndefined({
    principal: isEmpty(backendControlPersonValues) ? undefined : backendControlPersonValues,
    associated_identities: backendBeneficialOwnerValues,
  })

  return removeUndefined({
    id,
    customer_model: customerType,
    pricing_type: pricingType,
    sandbox_application_id: sandboxApplicationId,
    business_data: isEmpty(backendBusinessDataValues) ? undefined : backendBusinessDataValues,
    principal_data: isEmpty(backendPrincipalDataValues) ? undefined : backendPrincipalDataValues,
    payment_instruments: isEmpty(backendPaymentInstrumentValues) ? undefined : backendPaymentInstrumentValues,
  }, true)
}

export default backendAccessFormModel
