import './EditPayoutSettingsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import validateEditPayoutSettingsForm from 'utilities/validate/validateEditPayoutSettingsForm'
import FieldSectionV2 from 'components/Customer/Shared/Page/FieldSectionV2/FieldSectionV2'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import Button from 'components/Shared/Button/Button'
import PayoutSettingsSchedulePreview from 'components/Customer/Pages/PayoutSettings/PayoutSettingsSchedulePreview'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import { EDIT_PAYOUT_SETTINGS_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'

import {
  EDIT_PAYOUT_SETTINGS_FORM_TITLE,
  FEE_PREFERENCE_DESCRIPTION,
  FEE_PREFERENCE_TITLE,
  FEE_SCHEDULE_PREVIEW,
  PAYOUT_PREFERENCE,
  PAYOUT_PREFERENCE_DESCRIPTION,
  PAYOUT_SCHEDULE_PREVIEW,
  PAYOUT_SCHEDULE_PREVIEW_DESCRIPTION,
  CANCEL,
  FEE_SCHEDULE_PREVIEW_DESCRIPTION,
  SAVE,
} from 'constants/language/languageConstants'

const EditPayoutSettingsForm = ({
  handleSubmit = () => {},
  payoutTypeSectionData = {},
  bankAccountsSectionData = {},
  feeBankAccountSectionData = {},
  settlementsSectionData = {},
  settlementBatchTimeData = {},
  feeSettlementSectionData = {},
  fundingTransferDelaySectionData = {},
  feeFundingTransferDelaySectionData = {},
  reconciliationSectionData = {},
  feeReconciliationSectionData = {},
  transfersSectionData = {},
  feeTransfersSectionData = {},
  isFlex = false,
  showConfirmationModal = () => {},
  contextBarData = {},
  payoutSettingsValues = {},
  isTypeGross = false,
  pristine,
  showSettlementBatchTimeSection = false,
}) => {
  return (
    <form className='EditPayoutSettingsForm' onSubmit={handleSubmit(() => showConfirmationModal({ handleSubmit }))}>
      <ContextBar {...contextBarData} />

      <div className='page-content'>
        <div className='page-header'>
          <h2>{EDIT_PAYOUT_SETTINGS_FORM_TITLE}</h2>
        </div>

        <FieldSectionV2 {...payoutTypeSectionData} />

        <div className='field-container'>
          <h4 className='header'>{PAYOUT_PREFERENCE}</h4>
          <div className='description'>{PAYOUT_PREFERENCE_DESCRIPTION}</div>
          <div className='fields'>
            <FieldSectionV2 {...bankAccountsSectionData} />
            <FieldSectionV2 {...settlementsSectionData} />
            {showSettlementBatchTimeSection && <FieldSectionV2 {...settlementBatchTimeData} /> }
            <FieldSectionV2 {...transfersSectionData} />
            <FieldSectionV2 {...fundingTransferDelaySectionData} />
            {
              !isFlex && (
                <>
                  <FieldSectionV2 {...reconciliationSectionData} />
                </>
              )
            }
          </div>
        </div>

        <Accordion
          title={PAYOUT_SCHEDULE_PREVIEW}
          description={PAYOUT_SCHEDULE_PREVIEW_DESCRIPTION}
        >
          <PayoutSettingsSchedulePreview data={payoutSettingsValues} />
        </Accordion>

        {isTypeGross && (
          <>
            <div className='field-container'>
              <h4 className='header'>{FEE_PREFERENCE_TITLE}</h4>
              <div className='description'>{FEE_PREFERENCE_DESCRIPTION}</div>
              <div className='fields'>
                <FieldSectionV2 {...feeBankAccountSectionData} />
                <FieldSectionV2 {...feeSettlementSectionData} />
                <FieldSectionV2 {...feeTransfersSectionData} />
                <FieldSectionV2 {...feeFundingTransferDelaySectionData} />
                {
                  !isFlex && isTypeGross && (
                    <>
                      <FieldSectionV2 {...feeReconciliationSectionData} />
                    </>
                  )
                }
              </div>
            </div>

            <Accordion
              title={FEE_SCHEDULE_PREVIEW}
              description={FEE_SCHEDULE_PREVIEW_DESCRIPTION}
            >
              <PayoutSettingsSchedulePreview data={payoutSettingsValues} isFeePreferenceSection />
            </Accordion>
          </>
        )}
      </div>
      <div className='sticky-footer'>
        <div className='btn-container flex flex-end items-center'>
          <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button type='submit' disabled={pristine} label={SAVE} className='submit-button' />
        </div>
      </div>
    </form>
  )
}

EditPayoutSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  payoutTypeSectionData: PropTypes.object,
  bankAccountsSectionData: PropTypes.object,
  feeBankAccountSectionData: PropTypes.object,
  settlementsSectionData: PropTypes.object,
  settlementBatchTimeData: PropTypes.object,
  feeSettlementSectionData: PropTypes.object,
  fundingTransferDelaySectionData: PropTypes.object,
  feeFundingTransferDelaySectionData: PropTypes.object,
  reconciliationSectionData: PropTypes.object,
  feeReconciliationSectionData: PropTypes.object,
  transfersSectionData: PropTypes.object,
  feeTransfersSectionData: PropTypes.object,
  isFlex: PropTypes.bool,
  showConfirmationModal: PropTypes.func,
  contextBarData: PropTypes.object,
  payoutSettingsValues: PropTypes.object,
  isTypeGross: PropTypes.bool,
  invalid: PropTypes.bool,
  showSettlementBatchTimeSection: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_PAYOUT_SETTINGS_FORM,
  validate: validateEditPayoutSettingsForm,
  enableReinitialize: true,
})(EditPayoutSettingsForm)
