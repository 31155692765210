import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/MerchantDeposits/columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE } from 'constants/language/languageConstants'

import {
  GET_TRANSFERS,
  TRANSFER,
} from 'constants/flowConstants'

const MerchantMerchantDeposits = ({
  flowValues = {},
  merchantDepositsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters = [],
  merchantId = '',
}) => {
  return (
    <div className='MerchantDeposits'>
      <TableC
        flow={GET_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={merchantDepositsPath}
        isFetching={isFetching}
        fileNameCSV={`Merchant_${merchantId}_Merchant_Deposits`}
        entityType={TRANSFER}
        icon={`fa fa-${PAYMENTS_ICON}`}
        emptyMessage={MERCHANT_DEPOSITS_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
        showAdvancedExport
      />
    </div>
  )
}

MerchantMerchantDeposits.propTypes = {
  flowValues: PropTypes.object,
  merchantDepositsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  merchantId: PropTypes.string,
}

export default MerchantMerchantDeposits
