import React, { Component } from 'react'
import ResponseFilesForm from './ResponseFilesForm'

class ResponseFilesFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }
  }

  toggle = (showResponse) => {
    this.setState({ show: showResponse })
  }

  render() {
    const { show } = this.state

    return (
      <ResponseFilesForm
        {...this.props}
        toggle={this.toggle}
        show={show}
      />
    )
  }
}

export default ResponseFilesFormC
