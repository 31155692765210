import showModalAction from 'utilities/actions/showModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_DASHBOARD_USER_F_REQUEST } from 'constants/flowConstants'
import { SEND_EMAIL } from 'constants/modalConstants'
import { INVITE_USER } from 'constants/amplitudeConstants'
import _values from 'lodash/values'
import head from 'lodash/head'

const submitInviteUserForm = (values, dispatch, props) => {
  const {
    email,
    name,
  } = values

  const {
    id,
    type,
    identityId,
    applicationId,
    credentials,
  } = props

  dispatch({
    type: POST_DASHBOARD_USER_F_REQUEST,
    payload: {
      values: {
        email,
        name,
        type,
        identityId,
        applicationId,
      },
      credentials,
      id,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const user = head(_values(newValues))

        dispatch(showModalAction({
          modalType: SEND_EMAIL,
          modalProps: { user, applicationId },
        }))
      },
      actionId: sendAmplitudeActionEvent(INVITE_USER, {
        email,
        platformName: name,
        type,
        identityId,
        applicationId,
        credentials,
      }),
    },
  })
}

export default submitInviteUserForm
