import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import BuyerDetails from './BuyerDetails'
import Address from 'components/Customer/Shared/Display/Address/Address'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hasPermissions from 'utilities/hasPermissions'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { VIEW_IDENTITIES_LIST_PERMISSION } from 'constants/permissionConstants'
import get from 'lodash/get'

import {
  BUYER_PATH,
  RECIPIENT_PATH,
} from 'constants/pathConstants'

import {
  NAME,
  EMAIL,
  PHONE,
  BILLING_ADDRESS,
  VIEW_BUYER_DETAILS,
  VIEW_RECIPIENT_DETAILS,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_ADDRESS,
  BUSINESS_PHONE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isDisbursements = isDisbursementsPartnerDashboard(state)

  const [
    buyerIdentity,
  ] = getMany(props, [
    'buyerIdentity',
  ])

  const [
    fullName,
    displayBusinessName,
    email,
    displayPhone,
    personalAddress,
    identityId,
    businessName,
    businessAddress,
    doingBusinessAs,
    displayBusinessPhone,
  ] = getMany(buyerIdentity, [
    'fullName',
    'displayBusinessName',
    'email',
    'displayPhone',
    'personalAddress',
    'id',
    'businessName',
    'businessAddress',
    'doingBusinessAs',
    'displayBusinessPhone',
  ])

  const buyerDetailsLink = hasPermissions([VIEW_IDENTITIES_LIST_PERMISSION]) && notRoleMerchant({ credentials }) ? <Link className='text-link' to={BUYER_PATH({ credentialId, identityId })}>{VIEW_BUYER_DETAILS}</Link> : null
  const recipientDetailsLink = <Link className='text-link' to={RECIPIENT_PATH({ credentialId, recipientId: identityId })}>{VIEW_RECIPIENT_DETAILS}</Link>
  const detailsLink = isDisbursements ? recipientDetailsLink : buyerDetailsLink

  const buyerIdentityClickToCopies = [
    {
      content: identityId,
    },
  ]

  const buyerDetailsDataSection = convertPageSectionDataToV2([
    {
      label: NAME,
      value: fullName,
    },
    {
      label: BUSINESS_NAME,
      value: displayBusinessName,
      condition: !!businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
      condition: !!doingBusinessAs,
    },
    {
      label: EMAIL,
      value: email,
    },
    {
      label: PHONE,
      value: displayPhone,
    },
    {
      label: BILLING_ADDRESS,
      value: <Address address={personalAddress} />,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
      condition: !!businessAddress,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
      condition: !!displayBusinessPhone,
    },
  ])

  return {
    credentialId,
    buyerDetailsDataSection,
    detailsLink,
    clickToCopies: buyerIdentityClickToCopies,
  }
}

class BuyerDetailsC extends Component {
  render() {
    return (
      <BuyerDetails
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(BuyerDetailsC)
