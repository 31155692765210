import { GET_PAYOUT_PLAN_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPayoutPlanRequest = ({
  payoutPlanId,
  credentials,
}) => createAction({
  type: GET_PAYOUT_PLAN_F_REQUEST,
  id: payoutPlanId,
  credentials,
})

export default getPayoutPlanRequest
