import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditFeeProfileRulesFlow from './EditFeeProfileRulesFlow'
import CardPaymentsFormC from 'components/Customer/Forms/EditFeeProfileRulesForm/CardPaymentsForm/CardPaymentsFormC'
import ReviewAndSubmitFormC from 'components/Customer/Forms/EditFeeProfileRulesForm/ReviewAndSubmitForm/ReviewAndSubmitFormC'
import AchAndAdditionalRulesFormC from 'components/Customer/Forms/EditFeeProfileRulesForm/AchAndAdditionalRulesForm/AchAndAdditionalRulesFormC'
import getFeeProfileRulesRequest from 'utilities/actions/get/getFeeProfileRulesRequest'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_FEE_PROFILE_DUES_ASSESSMENTS } from 'constants/featureFlagConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  getApplicationSelector,
  getFeeProfileRuleSelector,
} from 'state-layer/selectors'

import {
  ACH_AMPERSAND_ADDITIONAL,
  CARD_PAYMENTS,
  REVIEW_AMPERSAND_SUBMIT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    entityId,
    entityType,
  ] = getMany(parseUrlQueries(), [
    'entityId',
    'entityType',
  ])

  const feeProfileRules = getFeeProfileRuleSelector(state, entityId)
  const noExistingFeeProfileRules = isEmpty(feeProfileRules)
  const application = getApplicationSelector(state, entityId)
  const title = `Edit Fee Profile Limits for ${get(application, 'businessName')}`
  const showDuesAssessments = getFeatureFlag(SHOW_FEE_PROFILE_DUES_ASSESSMENTS)

  const steps = [
    {
      name: CARD_PAYMENTS,
      component: CardPaymentsFormC,
      dataKey: 'card',
      props: {
        entityId,
        entityType,
        feeProfileRules,
        noExistingFeeProfileRules,
        showDuesAssessments,
      },
    },
    {
      name: ACH_AMPERSAND_ADDITIONAL,
      component: AchAndAdditionalRulesFormC,
      dataKey: 'ach',
      props: {
        entityId,
        entityType,
        feeProfileRules,
        noExistingFeeProfileRules,
      },
    },
    {
      name: REVIEW_AMPERSAND_SUBMIT,
      component: ReviewAndSubmitFormC,
      props: {
        entityId,
        entityType,
        credentialId,
        noExistingFeeProfileRules,
      },
    },
  ]

  return {
    steps,
    entityId,
    entityType,
    title,
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeProfileRules: ({ credentials, values }) => dispatch(getFeeProfileRulesRequest({ credentials, values })),
    getApplication: ({ applicationId, credentials }) => dispatch(getApplicationRequest({ applicationId, credentials })),
  }
}

class EditFeeProfileRulesFlowC extends Component {
  componentDidMount() {
    const {
      getFeeProfileRules,
      entityId,
      credentials,
      getApplication,
    } = this.props

    if (entityId) {
      getFeeProfileRules({ credentials, values: { applicationId: entityId } })
      getApplication({ applicationId: entityId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      entityId,
      credentials,
      getFeeProfileRules,
      getApplication,
    } = this.props

    const { entityId: prevEntityId } = prevProps

    if (entityId && entityId !== prevEntityId) {
      getFeeProfileRules({ credentials, values: { applicationId: entityId } })
      getApplication({ applicationId: entityId, credentials })
    }
  }

  render () {
    return (
      <EditFeeProfileRulesFlow {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeeProfileRulesFlowC)
