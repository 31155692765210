import { goToPath } from 'state-layer/history'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import removeUndefined from 'utilities/remove/removeUndefined'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { PAYMENT_LINK_CREATE_CONFIRMATION_PATH } from 'constants/pathConstants'
import { CREATE_PAYMENT_LINK } from 'constants/amplitudeConstants'
import { FINIX_NAV_BAR_LOGO } from 'constants/logoConstants'
import { USD } from 'constants/currencyConstants'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
} from 'constants/paymentLinkConstants'

// TODO: Add unit tests
const submitCreatePaymentLinkForm = (values, dispatch, props) => {
  const merchantId = getUrlQuery('merchantId')

  const {
    credentials,
    applicationId,
    isMultipleUse,
    currency = USD,
    maxTransactionAmount,
    paymentLinkLogo,
    currentUserEmail,
  } = props

  const {
    nickname,
    allowPaymentCard,
    allowBankTransfer,
    items,
    expiresIn,
    expirationDate,
    collectBuyerDetails,
    collectName = false,
    collectPhoneNumber = false,
    collectShippingAddress = false,
    collectBillingAddress = false,
    collectCustomFields = false,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    amountType,
    customFields,
    paymentLinkLogoURL,
    paymentLinkHeaderColor,
    paymentLinkButtonBackgroundColor,
    paymentLinkButtonTextColor,
    sendReceipt,
    sendReceiptToSelf,
  } = values

  const customFieldTags = reduce(customFields, (result, { name }, index) => {
    if (name) {
      result[`custom_field_${index + 1}`] = name
    }

    return result
  }, {})

  const allowedPaymentMethods = []
  if (allowPaymentCard) allowedPaymentMethods.push(PAYMENT_CARD)
  if (allowBankTransfer) allowedPaymentMethods.push(BANK_ACCOUNT)

  let expirationInMinutes
  if (expiresIn === 'custom') {
    // custom means the user selected a date, which is inclusive of the selected date
    const currentDate = new Date()
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000
    // we need to add additional day because the expirationDate is they date they selected at 12:00 AM. We want to include the entire day.
    const timeDifference = expirationDate - currentDate + oneDayInMilliseconds
    expirationInMinutes = Math.round(timeDifference / (1000 * 60))
  } else {
    // expiresIn is a string of the number of hours, so convert hours to minutes
    expirationInMinutes = parseInt(expiresIn, 10) * 60
  }

  const collectMoreBuyerInfo = collectBuyerDetails === 'more'

  const valuesToSubmit = removeUndefined({
    merchantId,
    applicationId,
    isMultipleUse,
    allowedPaymentMethods,
    nickname,
    items,
    collectEmail: true,
    collectName: collectMoreBuyerInfo ? collectName : false,
    collectPhoneNumber: collectMoreBuyerInfo ? collectPhoneNumber : false,
    collectShippingAddress: collectMoreBuyerInfo ? collectShippingAddress : false,
    collectBillingAddress: collectMoreBuyerInfo ? collectBillingAddress : false,
    expirationInMinutes,
    amountType: get(amountType, 'value'),
    currency,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    brandColor: paymentLinkHeaderColor,
    accentColor: paymentLinkButtonBackgroundColor,
    buttonFontColor: paymentLinkButtonTextColor,
    logo: paymentLinkLogoURL || paymentLinkLogo,
    maxTransactionAmount,
    icon: '',
    tags: collectCustomFields && !isEmpty(customFieldTags) ? customFieldTags : undefined,
    sendReceipt,
    receiptDeliveryMethods: sendReceiptToSelf ? [{
      type: 'EMAIL',
      destinations: [currentUserEmail],
    }] : undefined,
  })

  dispatch({
    type: POST_PAYMENT_LINK_F_REQUEST,
    payload: {
      credentials,
      values: valuesToSubmit,
    },
    actionId: sendAmplitudeActionEvent(CREATE_PAYMENT_LINK, {
      credentials,
      merchantId,
      applicationId,
      isMultipleUse,
      allowedPaymentMethods,
      nickname,
      collectName,
      collectEmail: true,
      collectPhoneNumber,
      collectShippingAddress,
      collectBillingAddress,
      amount: get(amountType, 'value'),
      currency,
      termsOfServiceUrl,
      successReturnUrl,
      unsuccessfulReturnUrl,
      expiredSessionUrl,
      expirationInMinutes,
    }),
    meta: {
      successCallback: ({ newValues }) => {
        const paymentLinkId = head(Object.keys(newValues))
        const credentialId = get(credentials, 'id')

        if (paymentLinkId) {
          goToPath({
            pathname: PAYMENT_LINK_CREATE_CONFIRMATION_PATH({ credentialId, paymentLinkId }),
          })
        }
      },
    },
  })
}

export default submitCreatePaymentLinkForm
