import React from 'react'
import { connect } from 'react-redux'
import TableC from 'components/Shared/Table/TableC'
import Address from 'components/Customer/Shared/Display/Address/Address'
import column from 'utilities/table/column'
import formatDateObject from 'utilities/formatters/formatDateObject'
import get from 'lodash/get'
import merge from 'lodash/merge'
import map from 'lodash/map'

const columnDescriptors = [
  column('Title', 'title', { className: 'small' }),
  column('First Name', 'firstName', { className: 'small' }),
  column('Last Name', 'lastName', { className: 'small' }),
  column('Phone', 'phone', { className: 'small', highlight: true }),
  column('Email', 'email'),
  column('Ownership', 'ownershipType', { className: 'small' }),
  column('Principal Ownership', 'principalPercentageOwnership', {
    className: 'small',
    formatter: ({ principalPercentageOwnership }) => (principalPercentageOwnership ? `${principalPercentageOwnership}%` : '-'),
  }),
  column('Tax ID', 'taxId', { className: 'small', highlight: true }),
  column('Date of Birth', 'dateOfBirth', {
    formatter: ({ dateOfBirth }) => formatDateObject({ date: dateOfBirth }),
    highlight: true,
  }),
  column('Address', 'personalAddress', {
    formatter: ({ personalAddress }) => <Address address={personalAddress} />,
  }),
]

const mapStateToProps = (state, props) => {
  const ownershipType = get(state, 'flowsR.formValues.businessInfo.ownershipType')
  const data = get(props, 'data')
  const combinedData = map(data, (principal) => merge({}, principal, { ownershipType }))

  return {
    combinedData,
  }
}

const IdentityPrincipalsConfirmationC = (props) => {
  const { combinedData } = props

  return (
    <TableC
      columnDescriptors={columnDescriptors}
      {...props}
      data={combinedData}
    />
  )
}

export default connect(mapStateToProps)(IdentityPrincipalsConfirmationC)
