import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, hasSubmitFailed, isInvalid } from 'redux-form'
import IdentityBankAccountInfoWithPlaidLinkForm from './IdentityBankAccountInfoWithPlaidLinkForm'
import showModalAction from 'utilities/actions/showModalAction'
import patchOnboardingFormBankDocumentRequest from 'utilities/actions/patch/patchOnboardingFormBankDocumentRequest'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { FORM_FILE_UPLOADER_MODAL } from 'constants/modalConstants'
import { IDENTITY_BANK_ACCOUNT_INFO_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { UNDERWRITING_ONLY_V1 } from 'constants/processorConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import size from 'lodash/size'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'

import {
  getOnboardingFormDataItemSelector,
  getPlaidPublicTokensSelector,
  getPlaidTokenMetadataSelector,
} from 'state-layer/selectors'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  BANK_ACCOUNT,
  BANK_NAME,
  SUCCESSFULLY_LINKED,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetchingOnboardingFormData = get(state, `onboardingFormDataR.${FETCHING}`)
  const isFetchingPlaidToken = get(state, `plaidPublicTokensR.${FETCHING}`)
  const onboardingFormId = get(props, 'onboardingFormId')
  const applicationId = get(props, 'applicationId')
  const onboardingForm = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const bankAccountData = get(onboardingForm, 'bankAccountData')
  const isFormInvalid = isInvalid(IDENTITY_BANK_ACCOUNT_INFO_FORM)(state) && hasSubmitFailed(IDENTITY_BANK_ACCOUNT_INFO_FORM)(state)
  const processors = get(onboardingForm, 'processorGatewayDetails.processors')
  const isStandaloneUnderwriting = size(processors) === 1 && head(processors) === UNDERWRITING_ONLY_V1
  const bankDocumentFileName = get(state, 'form.IDENTITY_BANK_ACCOUNT_INFO_FORM.values.bankAccountData.bankDocument.display_name', '')
  const bankDocumentFileErrorMsg = get(state, 'form.IDENTITY_BANK_ACCOUNT_INFO_FORM.syncErrors.bankAccountData.bankDocument', '')
  const initialValues = isFetchingOnboardingFormData ? undefined : { bankAccountData }
  const plaidProcessorToken = get(getPlaidPublicTokensSelector(state), 'token') || get(onboardingForm, 'bankAccountData.plaidProcessorToken')
  const associatedFiles = get(onboardingForm, 'associatedFiles')

  // disable submit button if neither manual pr plaid option has been selected
  const formSelector = formValueSelector(IDENTITY_BANK_ACCOUNT_INFO_FORM)
  const accountNumberValue = formSelector(state, 'bankAccountData.accountNumber')
  const isSubmitButtonDisabled = !accountNumberValue && !plaidProcessorToken

  // on success, display metadata returned from plaid
  const plaidTokenMetadata = getPlaidTokenMetadataSelector(state)

  const [
    bankName,
    accountType,
    maskedAccountNumber,
  ] = getMany(plaidTokenMetadata, [
    'institution.name',
    'account.subtype',
    'account.mask',
  ])

  const plaidLinkAccountDataSection = convertPageSectionDataToV2([
    {
      label: BANK_NAME,
      value: bankName,
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_TYPE,
      value: capitalize(accountType),
      condition: !!maskedAccountNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: `**${maskedAccountNumber}`,
      condition: !!maskedAccountNumber,
    },
    {
      label: BANK_ACCOUNT,
      value: <div><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
      condition: isEmpty(maskedAccountNumber),
    },
  ], 1)

  return {
    initialValues,
    isFetchingOnboardingFormData,
    bankDocumentFileName,
    bankDocumentFileErrorMsg,
    isFormInvalid,
    onboardingFormId,
    applicationId,
    bankAccountData,
    isStandaloneUnderwriting,
    onboardingForm,
    plaidLinkAccountDataSection,
    plaidProcessorToken,
    isSubmitButtonDisabled,
    isFetchingPlaidToken,
    associatedFiles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUploadFileModal: (modalProps) => dispatch(showModalAction({ modalType: FORM_FILE_UPLOADER_MODAL, modalProps })),
    removeFile: () => dispatch(change(IDENTITY_BANK_ACCOUNT_INFO_FORM, 'bankAccountData.bankDocument', null)),
    patchOnboardingFormBankDocument: ({
      file,
      fileType,
      fileName,
      onboardingFormId,
      applicationId,
      associatedFiles,
    }) => dispatch(patchOnboardingFormBankDocumentRequest({
      file,
      fileType,
      fileName,
      onboardingFormId,
      applicationId,
      associatedFiles,
    })),
  }
}

class IdentityBankAccountInfoWithPlaidLinkFormC extends Component {
  constructor(props) {
    super(props)

    this.state = { showBankFieldset: false }
  }

  componentDidMount() {
    const {
      bankAccountData,
      plaidProcessorToken,
    } = this.props

    // if we have a plaid public token on page load, don't show bank fieldset
    if (plaidProcessorToken) {
      this.setState({ showBankFieldset: false })
    }

    // if we have bank data, show bank fieldset
    const bankAccountNumber = get(bankAccountData, 'accountNumber')
    if (bankAccountNumber) {
      this.setState({ showBankFieldset: true })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      bankAccountData,
      plaidProcessorToken,
      dispatch,
    } = this.props

    const {
      bankAccountData: prevBankAccountData,
      plaidProcessorToken: prevPlaidProcessorToken,
    } = prevProps

    const prevBankDocumentId = get(prevBankAccountData, 'bankDocument.id')
    const bankDocumentId = get(bankAccountData, 'bankDocument.id')
    const bankDocumentChanged = prevBankDocumentId !== bankDocumentId

    const prevBankAccountNumber = get(prevBankAccountData, 'accountNumber')
    const bankAccountNumber = get(bankAccountData, 'accountNumber')

    // if the bank document is updated via api, update the form to have the correct bank documents
    if (bankDocumentId && bankDocumentChanged) {
      dispatch(change(IDENTITY_BANK_ACCOUNT_INFO_FORM, 'bankAccountData.bankDocument', bankAccountData.bankDocument))
    }

    // if we have a plaid public token on page load, don't show bank fieldset
    if (prevPlaidProcessorToken !== plaidProcessorToken && plaidProcessorToken) {
      this.setState({ showBankFieldset: false })
    }

    // if we have bank data on page load, show bank fieldset
    if (prevBankAccountNumber !== bankAccountNumber && bankAccountNumber) {
      this.setState({ showBankFieldset: true })
    }
  }

  handleClick = () => {
    this.setState({ showBankFieldset: true })
  }

  fileUploadHandler = ({
    file,
    fileName,
    fileType,
  }) => {
    const {
      onboardingFormId,
      applicationId,
      associatedFiles,
      patchOnboardingFormBankDocument,
    } = this.props

    patchOnboardingFormBankDocument({
      file,
      fileType,
      fileName,
      onboardingFormId,
      applicationId,
      associatedFiles,
    })
  }

  render() {
    const { showBankFieldset } = this.state

    return (
      <IdentityBankAccountInfoWithPlaidLinkForm
        {...this.props}
        showBankFieldset={showBankFieldset}
        handleClick={this.handleClick}
        handlePlaidClick={this.handlePlaidClick}
        fileUploadHandler={this.fileUploadHandler}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityBankAccountInfoWithPlaidLinkFormC)
