import './CreatePaymentInstrumentFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import { TIMES_OCTAGON } from 'constants/iconConstants'

import {
  ADD,
  CANCEL,
} from 'constants/language/languageConstants'

// This form uses our iframe to create a payment instrument
const CreatePaymentInstrumentForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
}) => {
  return (
    <form id='form' className='finix-library' onSubmit={handleSubmit}>
      <div className='modal-content'>
        <div id='loader' className='loading'>
          <Loader />
        </div>

        <div id='error-container' className='error-container hidden'>
          <i id='error-icon' className={`fa fa-${TIMES_OCTAGON} error-icon`} />
          <div id='form-errors' className='form-errors hidden' />
        </div>
        <div id='payment-instrument-form' className='form-elements' />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button id='button-submit' type='submit' label={ADD} />
      </div>
    </form>
  )
}

CreatePaymentInstrumentForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default CreatePaymentInstrumentForm
