import { getState } from 'state-layer/configureStore'
import { getComplianceSelfAssessmentQuestionnaireASelector } from 'state-layer/selectors'
import get from 'lodash/get'
import map from 'lodash/map'
import some from 'lodash/some'

const ComplianceCompensatingControlsWorksheetFields = [
  {
    name: 'constraints',
    label: 'Constraints',
  },
  {
    name: 'objective',
    label: 'Objective',
  },
  {
    name: 'identifiedRisk',
    label: 'Identified',
  },
  {
    name: 'definitionOfCompensatingControls',
    label: 'Definition',
  },
  {
    name: 'validationOfCompensatingControls',
    label: 'Validation',
  },
  {
    name: 'maintenance',
    label: 'Maintenance',
  },
]

const ComplianceCompensatingControlsWorksheetDisplayData = (values) => {
  const state = getState()
  const complianceSelfAssessmentQuestionnaireAValues = getComplianceSelfAssessmentQuestionnaireASelector(state)
  const saqAFormHasYesWithCCW = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'YES_WITH_CCW')

  return map(ComplianceCompensatingControlsWorksheetFields, ({ name, label }) => ({
    label,
    value: saqAFormHasYesWithCCW ? get(values, name) : '-',
  }))
}

export default ComplianceCompensatingControlsWorksheetDisplayData
