import './NewUserFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import validateNewUserForm from 'utilities/validate/validateNewUserForm'
import submitNewUserForm from 'utilities/submit/submitNewUserForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import { NEW_USER_FORM } from 'constants/formConstants'

import {
  CREATE,
  EMAIL_ADDRESS,
} from 'constants/language/languageConstants'

const NewUserForm = ({
  handleSubmit = () => {},
  emailTemplateOptions = [],
  rolePlatformAccessOptions = [],
}) => {
  return (
    <form className='NewUserForm content-card' onSubmit={handleSubmit(submitNewUserForm)}>
      <div className='section'>
        <Field
          name='name'
          label='Name'
          component={InputField}
        />

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          component={InputField}
        />

        <Field
          name='platform'
          label='Platform Name'
          component={InputField}
        />

        {
        // <Field
        //   name='role'
        //   label='Role'
        //   component={SelectField}
        // />

        // <Field
        //   name='id'
        //   label='Platform ID / Application ID / Identity ID'
        //   component={InputField}
        // />
        }

        <Field
          name='access'
          label='Access'
          component={SelectField}
          options={rolePlatformAccessOptions}
        />

        <Field
          name='template'
          label='Email Template'
          component={SelectField}
          options={emailTemplateOptions}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label={CREATE} />
      </div>
    </form>
  )
}

NewUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  emailTemplateOptions: PropTypes.array,
  rolePlatformAccessOptions: PropTypes.array,
}

export default reduxForm({
  form: NEW_USER_FORM,
  validate: validateNewUserForm,
})(NewUserForm)
