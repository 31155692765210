import MobileHeader from './MobileHeader'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { PATHNAME } from 'constants/queryConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import head from 'lodash/head'
import get from 'lodash/get'
import upperCase from 'lodash/upperCase'

import {
  DASHBOARD,
  ADMINISTRATOR,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const pathname = get(state, PATHNAME)
  const formattedPath = get(props, 'formattedPath')
  const topLevelPath = head(pathname.split('/', 1))
  const inDashboard = topLevelPath === DASHBOARD || !topLevelPath
  const switchLink = inDashboard ? ADMINISTRATOR : DASHBOARD
  const credentials = getCurrentCredentials(state)
  const environment = upperCase(get(credentials, 'environment'))
  const showEnvironmentHeaderTag = environment === 'QA' || environment === 'SANDBOX'
  const credentialId = get(credentials, 'id')
  const inCredentialsPage = !credentialId
  const currentUser = getCurrentUser()
  const userEmail = get(currentUser, CLIENT_EMAIL, '')
  const hasIncompleteComplianceForm = get(currentUser, 'hasIncompleteComplianceForm', false)
  const hasOverdueComplianceForm = get(currentUser, 'hasOverdueComplianceForm', false)
  const mobileNavOpen = get(state, 'mobileNavR.open', false)

  return {
    pathname: `/${pathname}`,
    switchLink,
    inDashboard,
    formattedPath,
    environment,
    showEnvironmentHeaderTag,
    inCredentialsPage,
    userEmail,
    hasIncompleteComplianceForm,
    hasOverdueComplianceForm,
    credentialId,
    mobileNavOpen,
  }
}

class MobileHeaderC extends Component {
  handleLogout = () => {
    const { auth } = this.props

    if (auth) {
      auth.logout()
    }
  }

  setMobileNavOpen = (open = false) => {
    const { dispatch } = this.props

    dispatch({ type: 'SET_MOBILE_NAV_OPEN', payload: { open } })
  }

  render() {
    return (
      <MobileHeader
        {...this.props}
        handleLogout={this.handleLogout}
        setMobileNavOpen={this.setMobileNavOpen}
      />
    )
  }
}

export default connect(mapStateToProps)(MobileHeaderC)
