import React from 'react'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { notPayoutFeature } from 'utilities/validate/checkCredentialFeatures'

const columnDescriptors = [
  column('ID', 'id'),
  column('Business', 'identity.displayBusinessName'),
  column('Created', 'createdAt', { className: 'date', sort: createdAtRange }),
  column('Processing', 'processing', {
    className: 'state',
    formatter: ({ processing }) => <EnabledStatus value={processing} />,
  }),
  column('Settlements', 'settlementsEnabledLabel', {
    className: 'state',
    condition: notPayoutFeature,
    formatter: ({ settlementsEnabledLabel }) => (
      <EnabledStatus value={settlementsEnabledLabel} />
    ),
  }),
]

export default columnDescriptors
