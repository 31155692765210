import React, { Component } from 'react'
import { connect } from 'react-redux'
import HostedMerchantOnboardingFlow from './HostedMerchantOnboardingFlow'
import IdentityBusinessInfoFormC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityBusinessInfoForm/IdentityBusinessInfoFormC'
import IdentityPrincipalInfoFormC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityPrincipalInfoForm/IdentityPrincipalInfoFormC'
import IdentityProcessingInfoFormC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityProcessingInfoForm/IdentityProcessingInfoFormC'
import IdentityBankAccountInfoWithPlaidLinkFormC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityBankAccountInfoWithPlaidLinkForm/IdentityBankAccountInfoWithPlaidLinkFormC'
import HostedMerchantOnboardingConfirmationC from 'components/Customer/Pages/HostedMerchantOnboardingFlow/HostedMerchantOnboardingConfirmationC'
import getMany from 'utilities/get/getMany'
import { POST_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import filter from 'lodash/filter'

import {
  BANK_ACCOUNT_INFO,
  BUSINESS_INFO,
  OWNER_INFO,
  PROCESSING_INFO,
  REVIEW_AMPERSAND_SUBMIT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const [
    onboardingFormId,
    token,
    termsOfServiceUrl,
    returnUrl,
    applicationName,
    maxTransactionAmount,
    achMaxTransactionAmount,
    feeProfileUrl,
    underwritingProfile,
    onboardingFormCountry,
    applicationId,
  ] = getMany(props, [
    'onboardingFormId',
    'token',
    'termsOfServiceUrl',
    'returnUrl',
    'applicationName',
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'feeProfileUrl',
    'underwritingProfile',
    'onboardingFormCountry',
    'applicationId',
  ])

  const credentials = { token }

  const steps = [
    {
      name: BUSINESS_INFO,
      component: IdentityBusinessInfoFormC,
      props: {
        onboardingFormId,
        isHostedOnboarding: true,
        onboardingFormCountry,
      },
    },
    {
      name: OWNER_INFO,
      component: IdentityPrincipalInfoFormC,
      props: {
        onboardingFormId,
        onboardingFormCountry,
      },
    },
    {
      name: PROCESSING_INFO,
      component: IdentityProcessingInfoFormC,
      props: {
        onboardingFormId,
        maxTransactionAmount,
        achMaxTransactionAmount,
        underwritingProfile,
        onboardingFormCountry,
      },
    },
    {
      name: BANK_ACCOUNT_INFO,
      component: IdentityBankAccountInfoWithPlaidLinkFormC,
      props: {
        onboardingFormId,
        onboardingFormCountry,
        applicationId,
      },
    },
    {
      name: REVIEW_AMPERSAND_SUBMIT,
      component: HostedMerchantOnboardingConfirmationC,
      props: {
        action: POST_ONBOARDING_FORM_DATA_F_REQUEST,
        recordId: onboardingFormId,
        credentials,
        termsOfServiceUrl,
        returnUrl,
        applicationName,
        feeProfileUrl,
        underwritingProfile,
      },
    },
  ]

  const filteredSteps = filter(steps, (step) => get(step, 'condition', true))

  return {
    credentials,
    layout: 'vertical',
    steps: filteredSteps,
  }
}

class HostedMerchantOnboardingFlowC extends Component {
  render () {
    return (
      <HostedMerchantOnboardingFlow {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(HostedMerchantOnboardingFlowC)
