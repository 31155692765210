import './PanelContextBarS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import map from 'lodash/map'
import { t } from 'constants/language/languageConstants'

const PanelContextBar = ({
  data = [],
}) => {
  return (
    <div className='PanelContextBar'>
      { map(data, ({ label, value, path, condition = true }, index) => {
        if (!condition) return undefined

        return (
          <Fragment key={`panel-context-item-${index}`}>
            <div className='label p-2 secondary'>{t(label)}</div>
            { path && <Link to={path} className='value side-panel-value'>{t(value)}</Link> }
            { !path && t(value) }
          </Fragment>
        )
      })}
    </div>
  )
}

PanelContextBar.propTypes = {
  data: PropTypes.array,
}

export default PanelContextBar
