import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutLinks from './PayoutLinks'
import { goToPath } from 'state-layer/history'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hasPermissions from 'utilities/hasPermissions'
import { createdQueriesForLastMonths } from 'constants/queryConstants'
import { CREATE_PAYOUT_LINK } from 'constants/language/languageConstants'
import { PAYOUT_LINK_ID } from 'constants/filterConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  CREATE_PAYOUT_LINK_PATH,
  PAYOUT_LINKS_PATH,
} from 'constants/pathConstants'


import {
  PAYMENT_LINKS_QUICK_FILTERS,
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
  PAYMENT_LINKS_QUICK_FILTERS,
]

const allowedFilters = [
  PAYOUT_LINK_ID,
]

const mapStateToProps = (state) => {
  const isFetching = get(state, `payoutLinksR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const payoutLinksPath = PAYOUT_LINKS_PATH({ credentialId })

  return {
    initialQueries,
    initialFilters: createdQueriesForLastMonths(6),
    quickFilters,
    credentials,
    credentialId,
    isFetching,
    allowedFilters,
    payoutLinksPath,
  }
}

class PayoutLinksC extends Component {
  render() {
    const { credentialId } = this.props

    const actions = [
      {
        label: CREATE_PAYOUT_LINK,
        className: 'create-payout-link-button',
        action: () => goToPath({
          pathname: CREATE_PAYOUT_LINK_PATH({ credentialId }),
        }),
        condition: hasPermissions([
          'processing:transfer:create',
          'processing:identity:create',
          'processing:instrument:create',
          'processing:transfer:update',
          'processing:merchant:create',
        ]),
      },
    ]

    return (
      <PayoutLinks
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps)(PayoutLinksC)
