import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableC from 'components/Shared/Table/TableC'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { MONITORING_ALERT_RELATED_TRANSFERS } from 'constants/linkConstants'
import { CREDIT } from 'constants/transferConstants'
import { MONITORING_ALERT_TYPE, MONITORING_ALERT_RELATED_TRANSFERS_TITLE_MAPPING } from 'constants/monitoringAlertConstants'
import { FETCHING } from 'constants/reducerConstants'
import createMonitoringAlertRelatedTransfersFilters from 'utilities/create/createMonitoringAlertRelatedTransfersFilters'
import { isDisbursementsAndPaymentsDashboard } from 'utilities/is/isDashboardType'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import removeUndefined from 'utilities/remove/removeUndefined'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  relatedPayoutsColumnDescriptors,
  relatedPaymentsColumnDescriptors,
  relatedAchReturnsColumnDescriptors,
  relatedAuthorizationsColumnDescriptors,
  relatedSettlementsColumnDescriptors,
  relatedDisputesColumnDescriptors,
  relatedInternationalTransfersColumnDescriptors,
} from '../columnDescriptors'

import {
  getApplicationTransfers,
  getAuthorizationMerchantsBuyersSelector,
  getDisputeMerchantsBuyersSelector,
  getMerchantTransfers,
  getSettlementsDataSelector,
  getTransferMerchantsBuyersSelector,
} from 'state-layer/selectors'

import {
  ACH_RETURNS,
  AUTHORIZATIONS,
  DISPUTES,
  PAYOUTS,
  REFUNDS,
  RESOURCE_LIST_PATH_MAP,
  SETTLEMENTS,
  TRANSFERS,
} from 'constants/pathConstants'

import {
  TRANSFER,
  APPLICATION,
  MERCHANT,
  GET_APPLICATION_TRANSFERS,
  GET_MERCHANT_TRANSFERS,
  GET_TRANSFERS,
  GET_AUTHORIZATIONS,
  GET_SETTLEMENTS,
  GET_DISPUTES,
} from 'constants/flowConstants'

const ALERT_TYPE_CONFIG = {
  [MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_DAILY]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_WEEKLY]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.PAYOUTS_GREATER_THAN_10K]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_GREATER_THAN_10K]: {
    flow: GET_APPLICATION_TRANSFERS,
    selector: getApplicationTransfers,
    columnDescriptors: relatedPayoutsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[PAYOUTS],
  },
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_NAME]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_CARD]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE]: {
    flow: GET_TRANSFERS,
    selector: getTransferMerchantsBuyersSelector,
    columnDescriptors: relatedAchReturnsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[ACH_RETURNS],
  },
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE_ADMINISTRATIVE]: {
    flow: GET_TRANSFERS,
    selector: getTransferMerchantsBuyersSelector,
    columnDescriptors: relatedAchReturnsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[ACH_RETURNS],
  },
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE_UNAUTHORIZED]: {
    flow: GET_TRANSFERS,
    selector: getTransferMerchantsBuyersSelector,
    columnDescriptors: relatedAchReturnsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[ACH_RETURNS],
  },
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_BANK]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.INTERNATIONAL_CARD_RATIO]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedInternationalTransfersColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.UNREFERENCED_REFUNDS]: {
    flow: GET_TRANSFERS,
    selector: getTransferMerchantsBuyersSelector,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[REFUNDS],
  },
  [MONITORING_ALERT_TYPE.REFUND_RATE]: {
    flow: GET_TRANSFERS,
    selector: getTransferMerchantsBuyersSelector,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[REFUNDS],
  },
  [MONITORING_ALERT_TYPE.FAILED_AUTHORIZATION_RATE]: {
    flow: GET_AUTHORIZATIONS,
    selector: getAuthorizationMerchantsBuyersSelector,
    columnDescriptors: relatedAuthorizationsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[AUTHORIZATIONS],
  },
  [MONITORING_ALERT_TYPE.REPEAT_DONATIONS]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.LENDING_CREDIT_CARD_TRANSFERS]: {
    flow: GET_MERCHANT_TRANSFERS,
    selector: getMerchantTransfers,
    columnDescriptors: relatedPaymentsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[TRANSFERS],
  },
  [MONITORING_ALERT_TYPE.FIRST_SETTLEMENT]: {
    flow: GET_SETTLEMENTS,
    selector: getSettlementsDataSelector,
    columnDescriptors: relatedSettlementsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[SETTLEMENTS],
  },
  [MONITORING_ALERT_TYPE.HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT]: {
    flow: GET_SETTLEMENTS,
    selector: getSettlementsDataSelector,
    columnDescriptors: relatedSettlementsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[SETTLEMENTS],
  },
  [MONITORING_ALERT_TYPE.DUPLICATE_SETTLEMENT]: {
    flow: GET_SETTLEMENTS,
    selector: getSettlementsDataSelector,
    columnDescriptors: relatedSettlementsColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[SETTLEMENTS],
  },
  [MONITORING_ALERT_TYPE.DORMANT_MERCHANT_SETTLEMENT]: {
    flow: null,
    selector: null,
    columnDescriptors: null,
    path: null,
  },
  [MONITORING_ALERT_TYPE.SETTLEMENT_SIZE_DIFFERENCE]: {
    flow: null,
    selector: null,
    columnDescriptors: null,
    path: null,
  },
  [MONITORING_ALERT_TYPE.HIGH_TICKET]: {
    flow: null,
    selector: null,
    columnDescriptors: null,
    path: null,
  },
  [MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK]: {
    flow: GET_DISPUTES,
    selector: getDisputeMerchantsBuyersSelector,
    columnDescriptors: relatedDisputesColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[DISPUTES],
  },
  [MONITORING_ALERT_TYPE.DISPUTE_COUNT]: {
    flow: GET_DISPUTES,
    selector: getDisputeMerchantsBuyersSelector,
    columnDescriptors: relatedDisputesColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[DISPUTES],
  },
  [MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW]: {
    flow: GET_DISPUTES,
    selector: getDisputeMerchantsBuyersSelector,
    columnDescriptors: relatedDisputesColumnDescriptors,
    path: RESOURCE_LIST_PATH_MAP[DISPUTES],
  },
}

const getConfig = (alertType) => ALERT_TYPE_CONFIG[alertType] || { flow: null, selector: null, columnDescriptors: null, path: null }

const MonitoringAlertTable = ({
  alertType,
  monitoringAlert,
}) => {
  const { flow, selector, columnDescriptors, path } = getConfig(alertType)

  if (!flow || !selector || !columnDescriptors || !path) return null

  const state = useSelector(storeState => storeState)
  const linkedType = get(monitoringAlert, 'linkedType')
  const isDisbursementsAndPayments = isDisbursementsAndPaymentsDashboard(state)
  const credentialId = getCurrentCredentialsId(state)
  const entityPath = path({ credentialId })
  const isFetchingRelatedTransfers = get(state, `transfersR.${FETCHING}`)
  const linkedTo = get(monitoringAlert, 'linkedTo')

  const initialQueries = removeUndefined({
    offset: 0,
    type: isDisbursementsAndPayments ? CREDIT : undefined,
  })

  const generatedQueries = createMonitoringAlertRelatedTransfersFilters({ monitoringAlert })
  const mergedQueries = merge({}, initialQueries, generatedQueries)

  const flowValues = linkedType === MERCHANT
    ? { merchantId: linkedTo }
    : linkedType === APPLICATION
      ? { applicationId: linkedTo }
      : {}

  return (
    <TableC
      title={MONITORING_ALERT_RELATED_TRANSFERS_TITLE_MAPPING[alertType] || 'Related Transfers'}
      flow={flow}
      flowValues={flowValues}
      isFetching={isFetchingRelatedTransfers}
      columnDescriptors={columnDescriptors}
      selector={selector}
      selectorId={linkedTo}
      initialQueries={mergedQueries}
      linksKey={MONITORING_ALERT_RELATED_TRANSFERS}
      path={entityPath}
      entityType={TRANSFER}
      icon={`fa fa-${PAYMENTS_ICON}`}
    />
  )
}

MonitoringAlertTable.propTypes = {
  alertType: PropTypes.string.isRequired,
  monitoringAlert: PropTypes.object.isRequired,
}

export default MonitoringAlertTable
