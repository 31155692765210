import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import BalanceAdjustments from './BalanceAdjustments'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import { getCurrentUsagesByLinkedTo } from 'state-layer/selectors'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { FETCHING } from 'constants/reducerConstants'
import { ADD_FUNDS } from 'constants/language/languageConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'

import {
  ADD_FUNDS_PATH,
  BALANCE_ADJUSTMENTS_PATH,
} from 'constants/pathConstants'


const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const applicationId = get(getCurrentUser(state), 'applicationId')
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `balanceAdjustmentsR.${FETCHING}`)
  const isFetchingCurrentUsages = get(state, `currentUsagesR.${FETCHING}`)
  const balanceAdjustmentPath = BALANCE_ADJUSTMENTS_PATH({ credentialId })
  const applicationVelocityLimit = getCurrentUsagesByLinkedTo(state, applicationId)
  const hasApplicationVelocityLimit = !isEmpty(applicationVelocityLimit)

  const [
    displayBalanceAdjustmentDailyVelocityCurrentVolume,
    displayBalanceAdjustmentDailyVelocityVolumeLimit,
    balanceAdjustmentDailyVelocityCurrentCount,
    balanceAdjustmentDailyVelocityCountLimit,
    displayBalanceAdjustmentMonthlyVelocityCurrentVolume,
    displayBalanceAdjustmentMonthlyVelocityVolumeLimit,
    balanceAdjustmentMonthlyVelocityCurrentCount,
    balanceAdjustmentMonthlyVelocityCountLimit,
  ] = getMany(applicationVelocityLimit, [
    'displayBalanceAdjustmentDailyVelocityCurrentVolume',
    'displayBalanceAdjustmentDailyVelocityVolumeLimit',
    'balanceAdjustmentDailyVelocityCurrentCount',
    'balanceAdjustmentDailyVelocityCountLimit',
    'displayBalanceAdjustmentMonthlyVelocityCurrentVolume',
    'displayBalanceAdjustmentMonthlyVelocityVolumeLimit',
    'balanceAdjustmentMonthlyVelocityCurrentCount',
    'balanceAdjustmentMonthlyVelocityCountLimit',
  ])

  return {
    credentialId,
    credentials,
    isFetching,
    isFetchingCurrentUsages,
    initialQueries,
    quickFilters: [DATE_QUICK_FILTER],
    balanceAdjustmentPath,
    applicationId,
    displayBalanceAdjustmentDailyVelocityCurrentVolume,
    displayBalanceAdjustmentDailyVelocityVolumeLimit,
    balanceAdjustmentDailyVelocityCurrentCount,
    balanceAdjustmentDailyVelocityCountLimit,
    displayBalanceAdjustmentMonthlyVelocityCurrentVolume,
    displayBalanceAdjustmentMonthlyVelocityVolumeLimit,
    balanceAdjustmentMonthlyVelocityCurrentCount,
    balanceAdjustmentMonthlyVelocityCountLimit,
    hasApplicationVelocityLimit,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationVelocityLimits: ({ credentials, entityId }) => dispatch(getCurrentUsagesRequest({ credentials, entityId })),
  }
}

class BalanceAdjustmentsC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationVelocityLimits,
    } = this.props

    if (applicationId) {
      getApplicationVelocityLimits({ credentials, entityId: applicationId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      applicationId,
      getApplicationVelocityLimits,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationVelocityLimits({ credentials, entityId: applicationId })
    }
  }

  render() {
    const { credentialId } = this.props

    const actions = [
      {
        label: ADD_FUNDS,
        link: ADD_FUNDS_PATH({ credentialId }),
        className: 'createBalanceAdjustment',
      },
    ]

    return (
      <BalanceAdjustments
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceAdjustmentsC)
