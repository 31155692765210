import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_METHOD_CONFIGURATIONS } from 'constants/apiConstants'

const postPaymentMethodConfigurationsAPI = ({ values, credentials }) => {
  return paymentsAPI.post({
    values,
    credentials,
    path: PAYMENT_METHOD_CONFIGURATIONS,
  })
}

export default postPaymentMethodConfigurationsAPI
