import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  underwritingIconMap,
} from 'constants/statusConstants'

import {
  UNDERWRITING_STATE_TO_DISPLAY_MAP,
  UNDERWRITING_STATUS_MAP,
} from 'constants/underwritingConstants'

const UnderwritingReviewStatus = ({
  value = '',
}) => (
  <ColorcodedStatus
    data={UNDERWRITING_STATUS_MAP}
    value={UNDERWRITING_STATE_TO_DISPLAY_MAP[value] ? UNDERWRITING_STATE_TO_DISPLAY_MAP[value] : value}
    customIconMap={underwritingIconMap}
    titleCase={false}
  />
)

UnderwritingReviewStatus.propTypes = {
  value: PropTypes.string,
}

export default UnderwritingReviewStatus
