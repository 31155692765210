import createAction from 'utilities/actions/createAction'
import { GET_UNDERWRITING_REPORT_F_REQUEST } from 'constants/flowConstants'

const getUnderwritingReportRequest = ({
  reportId,
  credentials,
}) => createAction({
  type: GET_UNDERWRITING_REPORT_F_REQUEST,
  id: reportId,
  credentials,
})

export default getUnderwritingReportRequest
