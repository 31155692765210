import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

import {
  PHONE_NUMBER_REGEX,
  EMAIL_REGEX,
  WEBSITE_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  checkboxGroupFieldEmpty,
  selectFieldEmpty,
  invalidRegex,
} from 'utilities/validate'

const validateComplianceAssessmentInformationForm = ({
  emailQSA,
  urlQSA,
  typeMerchantBusiness = {},
  typeMerchantBusinessOther,
  typePaymentChannelsServe,
  paymentChannelsCovered,
  howAndInWhatCapacityDoesYourBusinessHandleCardholderData,
  facilityLocations = [],
  oneOrMorePaymentApplications,
  paymentApplicationsUsed = [],
  provideHighLevelDescriptionEnvironment,
  networkSegmentation,
  usedQir,
  nameOfQirCompany,
  qirIndividualName,
  descriptionOfServicesProvidedByQir,
  companyShareCardholderDataWithThirdPartyServiceProviders,
  thirdPartyServiceProviders = [],
  eligibilityToCompleteSaqA,
  telephoneQSA,
}) => {
  const facilityLocationsErrors = []

  facilityLocations.forEach((facilityLocation, index) => {
    const { type_of_facility: type, number_facilities: number, locations_of_facility: locations } = facilityLocation

    facilityLocationsErrors[index] = {
      type_of_facility: fieldEmpty(type, 'Type'),
      number_facilities: fieldEmpty(number, 'Number'),
      locations_of_facility: fieldEmpty(locations, 'Locations'),
    }
  })

  let paymentApplicationsUsedError

  if (isEmpty(paymentApplicationsUsed)) {
    paymentApplicationsUsedError = { _error: 'At least one payment application must be provided.' }
  } else {
    const paymentApplicationsUsedErrors = []

    paymentApplicationsUsed.forEach((thirdPartyServiceProvider, index) => {
      const {
        payment_application_name: name,
        version_number: versionNumber,
        application_vendor: applicationVendor,
        is_application_pa_dss_listed: applicationPaDssListed,
      } = thirdPartyServiceProvider

      paymentApplicationsUsedErrors[index] = {
        payment_application_name: fieldEmpty(name, 'Name'),
        version_number: fieldEmpty(versionNumber, 'Version number'),
        application_vendor: fieldEmpty(applicationVendor, 'Application vendor'),
        is_application_pa_dss_listed: fieldEmpty(applicationPaDssListed, 'Application PA-DSS Listed'),
      }
    })

    paymentApplicationsUsedError = paymentApplicationsUsedErrors
  }

  let thirdPartyServiceProvidersError

  if (isEmpty(thirdPartyServiceProviders)) {
    thirdPartyServiceProvidersError = { _error: 'At least one third party service provider must be provided.' }
  } else {
    const thirdPartyServiceProvidersErrors = []

    thirdPartyServiceProviders.forEach((thirdPartyServiceProvider, index) => {
      const { name_of_service_provider: name, description_of_services_provided: description } = thirdPartyServiceProvider

      thirdPartyServiceProvidersErrors[index] = {
        name_of_service_provider: fieldEmpty(name, 'Name'),
        description_of_services_provided: fieldEmpty(description, 'Description'),
      }
    })

    thirdPartyServiceProvidersError = thirdPartyServiceProvidersErrors
  }

  const typeMerchantBusinessHasValue = !some(Object.values(typeMerchantBusiness)) && !typeMerchantBusinessOther

  return {
    emailQSA: invalidRegex({ field: emailQSA, name: 'Email', regex: EMAIL_REGEX }),
    urlQSA: invalidRegex({ field: urlQSA, name: 'URL', regex: WEBSITE_REGEX }),
    typeMerchantBusiness: typeMerchantBusinessHasValue,
    typeMerchantBusinessOther: typeMerchantBusinessHasValue && 'At least one merchant business type must be selected.',
    typePaymentChannelsServe: checkboxGroupFieldEmpty({ field: typePaymentChannelsServe }),
    paymentChannelsCovered: checkboxGroupFieldEmpty({ field: paymentChannelsCovered }),
    howAndInWhatCapacityDoesYourBusinessHandleCardholderData: fieldEmpty(howAndInWhatCapacityDoesYourBusinessHandleCardholderData, 'Field'),
    facilityLocations: facilityLocationsErrors,
    oneOrMorePaymentApplications: fieldEmpty(oneOrMorePaymentApplications, 'Field'),
    paymentApplicationsUsed: paymentApplicationsUsedError,
    provideHighLevelDescriptionEnvironment: fieldEmpty(provideHighLevelDescriptionEnvironment, 'Field'),
    networkSegmentation: selectFieldEmpty(networkSegmentation, 'Field'),
    usedQir: selectFieldEmpty(usedQir, 'Field'),
    nameOfQirCompany: fieldEmpty(nameOfQirCompany, 'Field'),
    qirIndividualName: fieldEmpty(qirIndividualName, 'Field'),
    descriptionOfServicesProvidedByQir: fieldEmpty(descriptionOfServicesProvidedByQir, 'Field'),
    companyShareCardholderDataWithThirdPartyServiceProviders: selectFieldEmpty(companyShareCardholderDataWithThirdPartyServiceProviders, 'Field'),
    thirdPartyServiceProviders: thirdPartyServiceProvidersError,
    eligibilityToCompleteSaqA: checkboxGroupFieldEmpty({ field: eligibilityToCompleteSaqA }),
    telephoneQSA: invalidRegex({ field: telephoneQSA, name: 'Phone Number', regex: PHONE_NUMBER_REGEX, customErrorMessage: 'Phone number must be a max of 11 digits.' }),

  }
}

export default validateComplianceAssessmentInformationForm
