import './WarningS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import isFunction from 'lodash/isFunction'

const Warning = ({
  message,
  condition = false,
}) => {
  return condition && (
    <div className='Warning flex'>
      <div className='warningMessage'>
        {isFunction(message) ? message() : message}
      </div>
    </div>
  )
}

Warning.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  condition: PropTypes.bool,
}

export default Warning
