import './VerificationRejectionDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'
import map from 'lodash/map'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import {
  VIEW_API_RESPONSE,
  FAILURE_DETAILS_EMPTY_MESSAGE,
  VERIFICATION_REJECTION_DETAILS,
  REMEDIATION,
  CREATED,
} from 'constants/language/languageConstants'

const VerificationRejectionDetails = ({
  title = VERIFICATION_REJECTION_DETAILS,
  asideTitle = VIEW_API_RESPONSE,
  verification = {},
  showVerificationResultsModal = () => {},
  isFetching = false,
  hasAPIAccess = false,
  reasons = [],
}) => {
  const {
    id,
    result = [],
    displayCreatedAt,
  } = verification

  const rightTitle = displayCreatedAt ? `${CREATED} ${displayCreatedAt}` : ''

  const noDataAvailable = isEmpty(result)

  return (
    <PageSectionV2C
      className='verification-rejection-highlight failure-highlight'
      title={<div className='failure-details-title'>{title}</div>}
      clickToCopies={[{ content: id }]}
      asideTitle={hasAPIAccess && (
        <div
          className='view-api-response-link'
          onClick={() => showVerificationResultsModal({ verification })}
        >
          {asideTitle}
        </div>
      )}
      rightTitle={rightTitle}
    >
      {isFetching && <Loader />}
      {noDataAvailable && isEmpty(reasons) && <span className='empty-message'>{FAILURE_DETAILS_EMPTY_MESSAGE}</span>}
      {!noDataAvailable && isString(result) && <div>{`${result}. Please contact support.`}</div>}
      {!noDataAvailable && !isString(result) && (
        <div className='verification-rejection-details-highlight'>
          {map(result, (detail) => {
            const displayOutcomeCode = snakeCaseToTitleCase({ key: get(detail, 'outcome_code', '-') })

            return (
              <div className='detail'>
                <div className='rejection-code'>{displayOutcomeCode}</div>
                <div className='rejection-description'>{get(detail, 'description', '-')}</div>
                <div className='remediation'><span className='label'>{REMEDIATION}: </span>{get(detail, 'remediation_item', '-')}</div>
              </div>
            )
          })}
        </div>
      )}
      {!isEmpty(reasons) && (
      <div className='verification-rejection-details-highlight'>
        {map(reasons, (detail) => {
          const displayOutcomeCode = snakeCaseToTitleCase({ key: get(detail, 'code', '-') })

          return (
            <div className='detail'>
              <div className='rejection-code'>{displayOutcomeCode}</div>
              <div className='rejection-description'>{get(detail, 'description', '-')}</div>
              <div className='remediation'><span className='label'>{REMEDIATION}: </span>{get(detail, 'remediation', '-')}</div>
            </div>
          )
        })}
      </div>
      )}
    </PageSectionV2C>
  )
}

VerificationRejectionDetails.propTypes = {
  title: PropTypes.string,
  asideTitle: PropTypes.string,
  verification: PropTypes.object,
  showVerificationResultsModal: PropTypes.func,
  isFetching: PropTypes.bool,
  hasAPIAccess: PropTypes.bool,
}

export default VerificationRejectionDetails
