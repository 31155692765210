import './CreateInstantPayoutFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import submitCreateInstantPayoutForm from 'utilities/submit/submitCreateInstantPayoutForm'
import validateCreateInstantPayoutForm from 'utilities/validate/validateCreateInstantPayoutForm'
import { CREATE_INSTANT_PAYOUT_FORM } from 'constants/formConstants'
import get from 'lodash/get'

import {
  CANCEL,
  CONFIRM,
  GET_PAID_INSTANTLY,
  RECIEVE_PAYOUT_INSTANLY_MSG,
  SEND_PAYMENT_TO,
  SETTLEMENT_AMOUNT,
} from 'constants/language/languageConstants'

const CreateInstantPayoutForm = ({
  handleSubmit = () => {},
  isFetchingSettlement = false,
  isFetchingPaymentInstruments = false,
  paymentInstrumentOptionsOnlyCard = [],
  reactSelectCustomStyles = {},
  displayNetAmountWithCurrencyCode = '',
  invalid = false,
}) => {
  const customOptionStyles = get(reactSelectCustomStyles, 'customOption', {})
  const optionStyles = get(reactSelectCustomStyles, 'option', {})

  return (
    <form className='CreateInstantPayoutForm' onSubmit={handleSubmit(submitCreateInstantPayoutForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{GET_PAID_INSTANTLY}</h3>
        <div className='p-1'>{RECIEVE_PAYOUT_INSTANLY_MSG}</div>
      </div>

      { isFetchingSettlement && <Loader /> }
      <div className='form-content'>
        <div className='instant-payout-details'>
          <h5 className='title'>{SETTLEMENT_AMOUNT}{':'}&nbsp;{displayNetAmountWithCurrencyCode}</h5>
        </div>

        { isFetchingPaymentInstruments && <Loader /> }
        <div className='payment-instrument'>
          <Field
            name='paymentMethod'
            component={ReactSelect}
            label={SEND_PAYMENT_TO}
            required
            options={paymentInstrumentOptionsOnlyCard}
            customStyles={{
              option: (styles, state) => (state.data.value === 'newPaymentMethod' ? customOptionStyles : optionStyles),
            }}
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button label={CANCEL} onClick={goBack} disabled={isFetchingSettlement} variant='ghost' />
          <Button label={CONFIRM} type='submit' disabled={invalid || isFetchingSettlement} className='btn btn-primary' />
        </div>
      </div>
    </form>
  )
}

CreateInstantPayoutForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetchingSettlement: PropTypes.bool,
  paymentInstrumentOptionsOnlyCard: PropTypes.array,
  reactSelectCustomStyles: PropTypes.object,
  displayNetAmountWithCurrencyCode: PropTypes.string,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_INSTANT_PAYOUT_FORM,
  validate: validateCreateInstantPayoutForm,
})(CreateInstantPayoutForm)
