import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdentitySettlementForm from './IdentitySettlementForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import selectOption from 'utilities/forms/selectOption'
import { getIdentitySelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import getMany from 'utilities/get/getMany'
import head from 'lodash/head'
import get from 'lodash/get'
import map from 'lodash/map'
import { USD } from 'constants/currencyConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'params.identityId') || get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const isFetching = get(state, `identitiesR.${FETCHING}`)

  const [
    currency = USD, // TODO: remove this once PI api properly returns with currency
    merchants,
  ] = getMany(identity, [
    'currency',
    'merchants',
  ])

  const processors = map(merchants, ({ processor }) => processor)
  const processorOptions = map(processors, (processor) => selectOption(processor))

  const initialValues = isFetching ? null : {
    identity: identityId,
    currency,
    processor: head(processors),
  }

  return removeUndefined({
    identity,
    identityId,
    credentials,
    initialValues,
    processorOptions,
  })
}

const mapDispatchToProps = {
  getIdentity: getIdentityRequest,
}

class IdentitySettlementFormC extends Component {
  componentDidMount() {
    const {
      identity,
      credentials,
      identityId,
      getIdentity,
    } = this.props

    if (!identity) {
      getIdentity({ identityId, credentials })
    }
  }

  render() {
    return (
      <IdentitySettlementForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentitySettlementFormC)
