import './EditUnderwritingProfileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import MultiSelectField from 'components/Shared/Inputs/MultiSelectField/MultiSelectField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import submitEditUnderwritingProfileForm from 'utilities/submit/submitEditUnderwritingProfileForm'
import validateEditUnderwritingProfileForm from 'utilities/validate/validateEditUnderwritingProfileForm'
import { EDIT_UNDERWRITING_PROFILE_FORM } from 'constants/formConstants'
import { finixMCCsSelectOptions } from 'constants/mccConstants'

import {
  MAX_ACH_TRANSACTION_AMOUNT,
  MAX_CARD_TRANSACTION_AMOUNT,
  MAX_CARD_TRANSACTION_AMOUNT_GENERIC_TOOLTIP,
  MAX_ACH_TRANSACTION_AMOUNT_GENERIC_TOOLTIP,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_CATEGORY_CODE_TOOLTIP,
  SAVE,
  CANCEL,
  ALLOW_ALL_MCCS,
  ALLOW_ALL_MCCS_SUBLABEL,
} from 'constants/language/languageConstants'

const EditUnderwritingProfileForm = ({
  handleSubmit = () => {},
  modalDescriptionString = '',
  closeModal = () => {},
  allowAllMCCsField = false,
}) => {
  return (
    <form className='EditUnderwritingProfileForm' onSubmit={handleSubmit(submitEditUnderwritingProfileForm)}>
      <div className='form-content'>
        <div className='header'>{modalDescriptionString}</div>
        <ToggleSwitchC
          name='allowAllMCCs'
          label={ALLOW_ALL_MCCS}
          helperText={ALLOW_ALL_MCCS_SUBLABEL}
          checked={allowAllMCCsField}
          form={EDIT_UNDERWRITING_PROFILE_FORM}
        />
        <MultiSelectField
          name='merchantCategoryCodes'
          label={MERCHANT_CATEGORY_CODE}
          options={finixMCCsSelectOptions}
          disabled={allowAllMCCsField}
          hideSelectedOptions={false}
          formatOptionLabel={(option, { context }) => {
            return context === 'menu' ? option.label : option.value
          }}
          tooltip={MERCHANT_CATEGORY_CODE_TOOLTIP}
          tooltipPosition='right'
          meta={{ touched: false, error: '' }}
          maxMenuHeight={200}
        />
        <AmountField
          name='maxTransactionAmount'
          label={MAX_CARD_TRANSACTION_AMOUNT}
          tooltip={MAX_CARD_TRANSACTION_AMOUNT_GENERIC_TOOLTIP}
          tooltipPosition='right'
        />
        <AmountField
          name='achMaxTransactionAmount'
          label={MAX_ACH_TRANSACTION_AMOUNT}
          tooltip={MAX_ACH_TRANSACTION_AMOUNT_GENERIC_TOOLTIP}
          tooltipPosition='right'
        />
      </div>

      <div className='btn-container flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={SAVE} />
      </div>
    </form>
  )
}

EditUnderwritingProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  modalDescriptionString: PropTypes.string,
  closeModal: PropTypes.func,
  allowAllMCCsField: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_UNDERWRITING_PROFILE_FORM,
  validate: validateEditUnderwritingProfileForm,
})(EditUnderwritingProfileForm)
