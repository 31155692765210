import './SettingsS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

import {
  SETTINGS_TITLE,
  SETTINGS_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Settings = ({
  identityId = '',
  applicationId = '',
  tabs = [],
  actions = [],
}) => {
  return (
    <div className='Settings'>
      <HeaderV2C
        title={SETTINGS_TITLE}
        subTitle={SETTINGS_SUBTITLE_DESCRIPTION}
        actions={actions}
      />

      <TabsC
        tabs={tabs}
        identityId={identityId}
        applicationId={applicationId}
      />
    </div>
  )
}

Settings.propTypes = {
  identityId: PropTypes.string,
  applicationId: PropTypes.string,
  tabs: PropTypes.array,
  actions: PropTypes.array,
}

export default Settings
