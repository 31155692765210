import React, { Component } from 'react'
import { connect } from 'react-redux'
import WebhookForm from './WebhookForm'
import { allEventsEnabledFormValues } from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import { WEBHOOK_FORM } from 'constants/formConstants'
import head from 'lodash/head'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  formValueSelector,
  change,
} from 'redux-form'

import {
  WEBHOOK_PATH,
  APPLICATION_WEBHOOK_PATH,
  DEVELOPER_PATH,
} from 'constants/pathConstants'

import {
  AUTHENTICATION_TYPE_OPTIONS,
  WEBHOOK_ENABLE_EVENTS_OPTIONS,
  BASIC_VALUE,
  BEARER_TOKEN_VALUE,
  WEBHOOK_EVENT_CHECKBOX_OPTIONS,
} from 'constants/webhookConstants'

import {
  CREATE_WEBHOOK,
  UPDATE_WEBHOOK,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const formSelector = formValueSelector(WEBHOOK_FORM)
  const currentAuthenticationType = formSelector(state, 'authenticationType')
  const allEventsSelected = formSelector(state, 'eventsChoice') === 'all-events'
  const currentSelectedEvents = formSelector(state, 'selectedEvents')
  const noItemsSelected = isEmpty(currentSelectedEvents)
  const applicationId = get(props, 'applicationId')
  const isApplicationWebhook = !!applicationId && isRolePlatform({ credentials })
  const eventsTableValidationMsg = get(state, 'form.WEBHOOK_FORM.syncErrors.selectedEvents')
  const selectedEventsTouched = get(state, 'form.WEBHOOK_FORM.fields.selectedEvents.touched')

  const [
    editMode,
    webhook,
    existingWebhookValues,
  ] = getMany(props, [
    'editMode',
    'webhook',
    'initialWebhookValues',
  ])

  const [
    webhookId,
    authenticationType,
  ] = getMany(webhook, [
    'id',
    'authenticationType',
  ])

  const createInitialValues = {
    authenticationType: get(head(AUTHENTICATION_TYPE_OPTIONS), 'value'),
    eventsChoice: get(head(WEBHOOK_ENABLE_EVENTS_OPTIONS), 'value'),
    selectedEvents: allEventsEnabledFormValues({ allEvents: WEBHOOK_EVENT_CHECKBOX_OPTIONS }),
  }

  const webhookPath = isApplicationWebhook ? APPLICATION_WEBHOOK_PATH({ credentialId, applicationId, webhookId }) : WEBHOOK_PATH({ credentialId, webhookId })
  const cancelRedirectPath = editMode ? webhookPath : `${DEVELOPER_PATH({ credentialId })}?tab=webhooks`
  const submitButtonLabel = editMode ? UPDATE_WEBHOOK : CREATE_WEBHOOK

  return {
    credentials,
    credentialId,
    authenticationType,
    initialValues: webhook ? existingWebhookValues : createInitialValues,
    currentAuthenticationType,
    allEventsSelected,
    currentSelectedEvents,
    cancelRedirectPath,
    submitButtonLabel,
    editMode,
    applicationId,
    isApplicationWebhook,
    noItemsSelected,
    eventsTableValidationMsg,
    selectedEventsTouched,
  }
}

class WebhookFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      enabledBasicAuthorizationInput: false,
      enabledBearerTokenInput: false,
    }
  }

  componentDidUpdate(prevProps, props) {
    const {
      allEventsSelected: prevAllEventsSelected,
      currentAuthenticationType: prevAuthenticationType,
    } = prevProps

    const {
      dispatch,
      allEventsSelected,
      currentAuthenticationType,
    } = this.props

    if (prevAllEventsSelected !== allEventsSelected) {
      const value = allEventsSelected ? allEventsEnabledFormValues({ allEvents: WEBHOOK_EVENT_CHECKBOX_OPTIONS }) : {}
      dispatch(change(WEBHOOK_FORM, 'selectedEvents', value))
    }

    if (prevAuthenticationType !== currentAuthenticationType) {
      // TODO: hack to force validation before form input is touched, look into proper way to async form validation
      dispatch(change(WEBHOOK_FORM, '_forceValidation', Date.now()))
    }
  }

  enableAuthorizationInput = (type) => {
    const { dispatch } = this.props
    dispatch(change(WEBHOOK_FORM, '_forceValidation', Date.now()))

    if (type === 'basic') {
      this.setState({ enabledBasicAuthorizationInput: true })
    } else {
      this.setState({ enabledBearerTokenInput: true })
    }
  }

  render() {
    const {
      editMode,
      authenticationType,
    } = this.props

    const {
      enabledBasicAuthorizationInput,
      enabledBearerTokenInput,
    } = this.state

    const disableBasicAuthorizationInput = editMode && (authenticationType === BASIC_VALUE) && !enabledBasicAuthorizationInput
    const disableBearerTokenInput = editMode && (authenticationType === BEARER_TOKEN_VALUE) && !enabledBearerTokenInput

    return (
      <WebhookForm
        {...this.props}
        enabledBasicAuthorizationInput={enabledBasicAuthorizationInput}
        enabledBearerTokenInput={enabledBearerTokenInput}
        enableAuthorizationInput={this.enableAuthorizationInput}
        updateSelectedEvents={this.updateSelectedEvents}
        disableBasicAuthorizationInput={disableBasicAuthorizationInput}
        disableBearerTokenInput={disableBearerTokenInput}
      />
    )
  }
}

export default connect(mapStateToProps)(WebhookFormC)
