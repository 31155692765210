import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateMerchantVerificationFormC from 'components/Customer/Forms/CreateMerchantVerificationForm/CreateMerchantVerificationFormC'

const MerchantVerificationModal = ({
  merchantId,
}) => {
  return (
    <GenericModal
      title='Create a Verification'
      className='MerchantVerificationModal'
      Component={CreateMerchantVerificationFormC}
      submitLabel='Create'
      merchantId={merchantId}
    />
  )
}

MerchantVerificationModal.propTypes = {
  merchantId: PropTypes.string,
}

export default MerchantVerificationModal
