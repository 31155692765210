import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import { fieldEmpty, validateInteger, validateHour, validateMinute } from 'utilities/validate'

const validateSchedulesForm = ({
  schedules,
}, {
  templateNames,
}) => {
  if (isEmpty(schedules)) {
    return { schedules: { _error: ['At least one schedule must be created.'] } }
  }

  // errors for individual schedules
  const schedulesErrors = []

  // errors for all schedules
  const schedulesTotalErrors = []

  const selectedTemplateNames = []

  schedules.forEach((schedule, index) => {
    const {
      templateNames: scheduleTemplateNames = {},
      type,
      autoCloseOffsetHours,
      autoCloseOffsetMinutes,
      autoCloseOffsetDays,
    } = schedule

    // schedule must have at least 1 template selected
    const templateNamesCheck = !isEmpty(pickBy(scheduleTemplateNames))

    templateNames.forEach(templateName => {
      if (scheduleTemplateNames[`name-${templateName}`]) selectedTemplateNames.push(templateName)
    })

    schedulesErrors[index] = {
      type: fieldEmpty(type, 'Type'),
      autoCloseOffsetHours: validateHour({ field: autoCloseOffsetHours, name: 'Auto Close Offset Hours' }),
      autoCloseOffsetMinutes: validateMinute({ field: autoCloseOffsetMinutes, name: 'Auto Close Offset Minutes' }),
      autoCloseOffsetDays: validateInteger({ field: autoCloseOffsetDays, name: 'Auto Close Offset Days' }),
    }

    if (!templateNamesCheck) {
      schedulesTotalErrors.push(`Schedule #${index + 1} must have at least one template selected.`)
    }
  })

  if (!isEqual(sortBy(templateNames), sortBy(selectedTemplateNames))) {
    schedulesTotalErrors.push('Each template can only be selected once.')
  }

  if (!isEmpty(schedulesTotalErrors)) {
    set(schedulesErrors, '_error', schedulesTotalErrors)
  }

  return {
    schedules: schedulesErrors,
  }
}

export default validateSchedulesForm
