import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'
import getMany from 'utilities/get/getMany'
import includes from 'lodash/includes'
import keyBy from 'lodash/keyBy'

import {
  ADDRESS,
  CVV2,
  FAST_FUNDS,
  PUSH_FUNDS_BLOCK,
  SUCCESSFUL_ADDRESS_VERIFICATIONS,
  FAILED_ADDRESS_VERIFICATIONS,
  WARNING_ADDRESS_VERIFICATIONS,
  VERIFIED,
  NOT_VERIFIED,
  VERIFIED_ADDRESS_WARNING,
  SUCCESSFUL_CVV2_VERIFICATIONS,
  FAILED_CVV2_VERIFICATIONS,
  WARNING_CVV2_VERIFICATIONS,
  NOT_VERIFIED_CVV2,
  UNKNOWN_CVV2_VERIFICATION,
  ELIGIBLE_FAST_FUNDS,
  NON_ELIGIBLE_FAST_FUNDS,
  ELIGIBLE,
  NON_ELIGIBLE,
  ACCEPTED,
  NOT_ACCEPTED,
  ACCEPTED_PUSH_FUNDS,
  NOT_ACCEPTED_PUSH_FUNDS,
  MATCHED,
  NOT_MATCHED,
  IMMEDIATE,
} from 'constants/verificationConstants'

import {
  SUCCESS,
  DANGER,
  WARNING,
} from 'constants/statusConstants'

const CapturedData = {
  [VERIFIED]: SUCCESS,
  [ELIGIBLE]: SUCCESS,
  [ACCEPTED]: SUCCESS,
  [NOT_VERIFIED]: DANGER,
  [VERIFIED_ADDRESS_WARNING]: WARNING,
}

const addressVerificationMatch = (value) => {
  if (includes(SUCCESSFUL_ADDRESS_VERIFICATIONS, value)) {
    return VERIFIED
  }
  if (includes(FAILED_ADDRESS_VERIFICATIONS, value)) {
    return NOT_VERIFIED
  }
  if (includes(WARNING_ADDRESS_VERIFICATIONS, value)) {
    return VERIFIED_ADDRESS_WARNING
  }

  return null
}

const cvv2VerificationMatch = (value) => {
  if (includes(SUCCESSFUL_CVV2_VERIFICATIONS, value)) {
    return VERIFIED
  }
  if (includes(FAILED_CVV2_VERIFICATIONS, value)) {
    return NOT_VERIFIED
  }
  if (includes(WARNING_CVV2_VERIFICATIONS, value)) {
    return UNKNOWN_CVV2_VERIFICATION
  }

  return null
}

const fastFundsVerificationMatch = (value) => {
  if (includes(ELIGIBLE_FAST_FUNDS, value)) {
    return ELIGIBLE
  }

  if (includes(NON_ELIGIBLE_FAST_FUNDS, value)) {
    return NON_ELIGIBLE
  }

  if (value !== IMMEDIATE) {
    return NON_ELIGIBLE
  }

  return null
}

const pushFundsBlockVerificationMatch = (value) => {
  const [
    pushFundsBlockIndicator,
    ppgsNetworkInfo,
  ] = getMany(value, [
    'visa_network_info[0].push_funds_block_indicator',
    'ppgs_network_info',
  ])

  const networkIds = keyBy(ppgsNetworkInfo, 'network_id')

  if (includes(ACCEPTED_PUSH_FUNDS, pushFundsBlockIndicator) || '16' in networkIds || value === true) {
    return ACCEPTED
  }

  if (includes(NOT_ACCEPTED_PUSH_FUNDS, pushFundsBlockIndicator) || !value) {
    return NOT_ACCEPTED
  }

  return null
}

const matchValueName = (value, verificationKey) => {
  switch (verificationKey) {
    case ADDRESS:
      return addressVerificationMatch(value)

    case CVV2:
      return cvv2VerificationMatch(value)

    case FAST_FUNDS:
      return fastFundsVerificationMatch(value)

    case PUSH_FUNDS_BLOCK:
      return pushFundsBlockVerificationMatch(value)

    default:
      return ''
  }
}

const VerificationStatusIndicator = ({
  value = '',
  verificationKey = '',
}) => (
  <ColorcodedStatus data={CapturedData} value={matchValueName(value, verificationKey)} />
)


VerificationStatusIndicator.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  verificationKey: PropTypes.string,
}

export default VerificationStatusIndicator
