import React from 'react'
import column from 'utilities/table/column'
import ReviewQueueOnboardingStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ReviewQueueOnboardingStatus'

import {
  createdAtRange,
} from 'utilities/table/sort'

const columnDescriptors = [
  column('Merchant', 'entity.businessName', { className: 'small' }),
  column('Doing Business As', 'entity.doingBusinessAs'),
  column('Onboarding State', 'outcome', {
    className: 'large',
    formatter: ({ outcome }) => <ReviewQueueOnboardingStatus value={outcome} />,
  }),
  column('Created', 'createdAt', {
    className: 'date',
    sort: createdAtRange,
  }),
  column('Assigned To', 'tags.assigned_to', { className: 'medium' }),
]

export default columnDescriptors
