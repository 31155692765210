import './ProcessorDetailsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ProcessorDetails from 'components/Customer/Shared/Display/ProcessorDetails/ProcessorDetails'
import { PROCESSOR_DETAILS } from 'constants/language/languageConstants'

const ProcessorDetailsModal = ({
  processorDetails = {},
}) => {
  return (
    <GenericModal
      title={PROCESSOR_DETAILS}
      className='ProcessorDetailsModal'
      Component={ProcessorDetails}
      processorDetails={processorDetails}
    />
  )
}

ProcessorDetailsModal.propTypes = {
  processorDetails: PropTypes.object,
}

export default ProcessorDetailsModal
