import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_TRANSACTION_COUNT,
  TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_REFUND_COUNT,
  TRANSACTION_MONITORING_TOTAL_REFUND_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_REFUND_AMOUNT,
  TRANSACTION_MONITORING_REFUND_RATE,
} from 'constants/language/languageConstants'

const RefundCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    transferCount,
    refundCount,
    displayTransferAmountDollarsSum,
    displayRefundAmountDollarsSum,
    displayTransferAmountDollarsAverage,
    displayRefundAmountDollarsAverage,
    displayRefundRate,
  ] = getMany(calculations, [
    'transferCount',
    'refundCount',
    'displayTransferAmountDollarsSum',
    'displayRefundAmountDollarsSum',
    'displayTransferAmountDollarsAverage',
    'displayRefundAmountDollarsAverage',
    'displayRefundRate',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_REFUND_COUNT}
        headerData={{
          value: refundCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_REFUND_AMOUNT}
        headerData={{
          value: displayRefundAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_REFUND_AMOUNT}
        headerData={{
          value: displayRefundAmountDollarsAverage,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_REFUND_RATE}
        headerData={{
          value: displayRefundRate,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TRANSACTION_COUNT}
        headerData={{
          value: transferCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsAverage,
        }}
      />
    </div>
  )
}

export default RefundCards
