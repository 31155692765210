import formatNumber from 'utilities/formatters/formatNumber'
import { isNumber } from 'utilities/validate/index'
import get from 'lodash/get'
import isString from 'lodash/isString'
import toString from 'lodash/toString'

const validatePercentageProps = (percentageProps) => {
  const percentage = get(percentageProps, 'percentage')
  const onlyWholeNumbers = get(percentageProps, 'onlyWholeNumbers', false)

  if (percentage || (isNumber(percentage) && percentage >= 0)) {
    if (onlyWholeNumbers) {
      return toString(percentage).replace(/\D/g, '')
    }
    if (!isString(percentage)) {
      return percentage >= 0 ? toString(percentage) : undefined
    }

    return percentage
  }

  return undefined
}

const formatPercentageField = (percentageProps) => {
  const percentage = validatePercentageProps(percentageProps)

  if (percentage) {
    const greaterPercentageOption = get(percentageProps, 'greaterPercentageOption')
    const periodFound = percentage.includes('.')
    const parts = percentage.split('.')
    const integer = get(parts, '[0]', '').replace(/\D/g, '')
    const fractional = get(parts, '[1]', '').replace(/\D/g, '').substring(0, 4)

    if (integer > 100 && !greaterPercentageOption) {
      return integer.substring(0, 2)
    }

    if (!periodFound && integer) {
      return formatNumber(integer)
    }

    if (integer === '100' && periodFound && !greaterPercentageOption) {
      return '100'
    }

    return `${formatNumber(integer)}.${fractional}`
  }

  return ''
}

export default formatPercentageField
