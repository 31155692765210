import EmailVerification from './EmailVerification'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSelfServiceSignupFormsSelector } from 'state-layer/selectors'
import postSelfServiceEmailVerificationRequest from 'utilities/actions/post/postSelfServiceEmailVerificationRequest'
import postSelfServiceResendEmailRequest from 'utilities/actions/post/postSelfServiceResendEmailRequest'
import { FETCHING } from 'constants/reducerConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import get from 'lodash/get'

import {
  VERIFY_EMAIL_ERROR_TITLE_STRING,
  VERIFY_EMAIL_SUCCESS_TITLE_STRING,
  VERIFY_EMAIL_SUCCESS_TITLE_STRING_2,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `selfServiceSignupFormR.${FETCHING}`)
  const queries = get(state, 'routing.locationBeforeTransitions.query')
  const token = get(queries, 'token')
  const dashboardSandboxId = get(queries, 'id')

  const dashboardSandboxForm = getSelfServiceSignupFormsSelector(state)
  const emailVerificationSucceeded = get(dashboardSandboxForm, 'emailVerified', false) || get(state, 'errorsR.errors.0.status') === 409
  let emailVerificationTitleString = emailVerificationSucceeded ? VERIFY_EMAIL_SUCCESS_TITLE_STRING : VERIFY_EMAIL_ERROR_TITLE_STRING

  if (get(state, 'errorsR.errors.0.status') === 409) {
    emailVerificationTitleString = VERIFY_EMAIL_SUCCESS_TITLE_STRING_2
  }

  return {
    dashboardSandboxId,
    token,
    isFetching,
    emailVerificationSucceeded,
    emailVerificationTitleString,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSelfServiceEmailVerification: ({ dashboardSandboxId }) => { dispatch(postSelfServiceEmailVerificationRequest({ dashboardSandboxId })) },
    resendEmail: ({ dashboardSandboxId }) => { dispatch(postSelfServiceResendEmailRequest({ dashboardSandboxId })) },
  }
}

class EmailVerificationC extends Component {
  componentDidMount() {
    const {
      dashboardSandboxId,
      token,
      postSelfServiceEmailVerification,
    } = this.props

    // clear localStorarge to ensure hosted onboarding hits the correct env
    localStorage.clear()

    // set the bearer token for localStorage for authentication uses
    localStorage.setItem(AUTH_SESSION, JSON.stringify({
      idToken: token,
      tokenType: 'Bearer',
    }))

    // verify email if token and id are in the link
    if (dashboardSandboxId) {
      postSelfServiceEmailVerification({ dashboardSandboxId })
    }
  }

  resendEmail = () => {
    const { resendEmail, dashboardSandboxId } = this.props

    if (dashboardSandboxId) {
      resendEmail({ dashboardSandboxId })
    }
  }

  render () {
    return (
      <EmailVerification
        {...this.props}
        resendEmail={this.resendEmail}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationC)
