import './UnderwritingAssigneeS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { REJECTED } from 'constants/underwritingConstants'

import {
  ASSIGN_TO_SELF,
  AUTO_APPROVED,
  REASSIGN,
  UNASSIGNED,
} from 'constants/language/languageConstants'

const UnderwritingAssignee = ({
  hasUpdatePermission = false,
  assignee,
  reviewId = '',
  subjectId = '',
  currentUserEmail = '',
  showAssignToMeModal = () => {},
  outcome = '',
}) => {
  if (assignee === AUTO_APPROVED || outcome === REJECTED) {
    return assignee
  }

  if (hasUpdatePermission && (!assignee || assignee === UNASSIGNED)) {
    return <a className='text-link' onClick={() => { showAssignToMeModal({ reviewId, subjectId }) }}>{ASSIGN_TO_SELF}</a>
  }

  if (hasUpdatePermission && assignee && assignee !== currentUserEmail) {
    return (
      <div className='UnderwritingAssignee flex'>
        <div className='assignee'>{assignee}</div>
        <a className='text-link' onClick={() => { showAssignToMeModal({ reviewId, subjectId }) }}>{REASSIGN}</a>
      </div>
    )
  }

  return assignee || '--'
}

UnderwritingAssignee.propTypes = {
  hasUpdatePermission: PropTypes.bool,
  assignee: PropTypes.string,
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  currentUserEmail: PropTypes.string,
  showAssignToMeModal: PropTypes.func,
}

export default UnderwritingAssignee
