import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditSubscriptionPlanForm from './EditSubscriptionPlanForm'
import { getSubscriptionPlanSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getSubscriptionPlanRequest from 'utilities/actions/get/getSubscriptionPlanRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionPlansR.${FETCHING}`, true)
  const credentials = getCurrentCredentials(state)
  const subscriptionPlanId = get(props, 'params.subscriptionPlanId')
  const subscriptionPlanInfo = getSubscriptionPlanSelector(state, subscriptionPlanId)

  const [
    planName,
    description,
    displayAmount,
    displayBillingInterval,
    billingInterval,
    collectionMethod,
    displayCollectionMethod,
    trialDefaults,
    intervalCount,
    displayIntervalType,
    intervalType,
  ] = getMany(subscriptionPlanInfo, [
    'planName',
    'description',
    'displayAmount',
    'displayBillingInterval',
    'billingInterval',
    'collectionMethod',
    'displayCollectionMethod',
    'trialDefaults',
    'intervalCount',
    'displayIntervalType',
    'intervalType',
  ])

  const hasTrialDefaultAddOns = !isEmpty(trialDefaults)

  const initialValues = !isFetching ? {
    subscriptionPlanName: planName,
    description,
    amount: displayAmount,
    billingInterval: { value: billingInterval, label: displayBillingInterval },
    collectionMethod: { value: collectionMethod, label: displayCollectionMethod },
    enableTrialPhase: hasTrialDefaultAddOns,
    trialIntervalCount: intervalCount,
    trialIntervalType: { value: intervalType, label: displayIntervalType },
  } : undefined

  return {
    isFetching,
    planName,
    subscriptionPlanId,
    subscriptionPlanInfo,
    initialValues,
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionPlan: ({ credentials, subscriptionPlanId }) => { dispatch(getSubscriptionPlanRequest({ credentials, subscriptionPlanId })) },
  }
}

class EditSubscriptionPlanFormC extends Component {
  componentDidMount() {
    const {
      getSubscriptionPlan,
      credentials,
      subscriptionPlanId,
    } = this.props

    getSubscriptionPlan({ credentials, subscriptionPlanId })
  }

  render() {
    return (
      <EditSubscriptionPlanForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscriptionPlanFormC)
