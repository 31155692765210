import frontendFeeProfileSettingModel from 'utilities/create/models/frontendFeeProfileSettingModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendFeeProfileSettingsModel = ({ data: feeProfileSettings }) => {
  const feeProfileSettingsModels = map(feeProfileSettings, (feeProfileSetting) => frontendFeeProfileSettingModel({ data: feeProfileSetting }))

  return keyBy(feeProfileSettingsModels, 'entityId')
}

export default frontendFeeProfileSettingsModel
