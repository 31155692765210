import React, { Component } from 'react'
import { connect } from 'react-redux'
import BalanceAdjustmentsVelocityLimits from './BalanceAdjustmentsVelocityLimits'
import { goToPath } from 'state-layer/history'
import { getDisbursementRulesSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { UPDATE_BALANCE_ADJUSTMENTS_LIMITS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  EDIT,
  PLEASE_SET_YOUR_VELOCITY_LIMITS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const disbursementRules = getDisbursementRulesSelector(state)
  const limitsExist = get(disbursementRules, 'id')
  const balanceAdjustmentsACHRules = get(disbursementRules, 'applicationBalanceAdjustmentRules')
  const applicationBalanceAdjustmentsRulesMaxTransactionAmount = get(disbursementRules, 'applicationBalanceAdjustmentsRulesMaxTransactionAmount')

  return {
    limitsExist,
    applicationId,
    balanceAdjustmentsACHRules,
    applicationBalanceAdjustmentsRulesMaxTransactionAmount,
    credentialId,
  }
}

class BalanceAdjustmentsVelocityLimitsC extends Component {
  render() {
    const { limitsExist, credentialId, applicationId } = this.props
    const actions = [
      {
        label: EDIT,
        action: () => goToPath({
          pathname: UPDATE_BALANCE_ADJUSTMENTS_LIMITS_PATH({ credentialId, applicationId }),
        }),
        isDisabledButton: !limitsExist,
        disabledButtonMessage: PLEASE_SET_YOUR_VELOCITY_LIMITS,
      },
    ]

    return (
      <BalanceAdjustmentsVelocityLimits
        actions={actions}
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(BalanceAdjustmentsVelocityLimitsC)
