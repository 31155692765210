import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_PATCH_CREDENTIAL } from 'constants/apiConstants'


const patchCredentialAPI = ({ id, values, meta }) => {
  return dashboardAPI.patch({
    meta,
    values,
    path: DASHBOARD_SERVICE_PATCH_CREDENTIAL({ id }),
  })
}

export default patchCredentialAPI
