import ManagePermission from './ManagePermission'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApplicationUserSelector, getUserPermissionsSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import { READ_ONLY_ROLES } from 'constants/roleConstants'
import getUserPermissionsRequest from 'utilities/actions/get/getUserPermissionsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isAdministrator from 'utilities/is/isAdministrator'
import showModalAction from 'utilities/actions/showModalAction'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import { UPDATE_ALL_PERMISSIONS } from 'constants/modalConstants'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'

const mapStateToProps = (state, props) => {
  const userId = get(props, 'userId')
  const user = getApplicationUserSelector(state, userId)
  const isFetching = get(state, `userPermissionsR.${FETCHING}`)
  const isAdmin = isAdministrator(state)
  const permissions = get(getUserPermissionsSelector(state, userId), 'permissions')

  const filteredPermissions = filter(permissions, ({ name, category }) => {
    const balanceTransferCondition = isAdmin || category !== 'balance_transfer'

    const hideSpecificPermissions = !includes([
      PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION,
    ], name)

    return balanceTransferCondition && hideSpecificPermissions
  })

  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isReadOnly = includes(READ_ONLY_ROLES, get(user, 'role'))

  return {
    userId,
    user,
    permissions: !isEmpty(filteredPermissions) ? filteredPermissions : [],
    isFetching,
    isReadOnly,
    credentials,
    credentialId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showDisableAllPermissionsModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_ALL_PERMISSIONS, modalProps })),
    getUserPermissions: (props) => dispatch(getUserPermissionsRequest(props)),
  }
}

class ManagePermissionC extends Component {
  componentDidMount() {
    const {
      credentials,
      userId,
      getUserPermissions,
    } = this.props

    getUserPermissions({ credentials, userId })
  }

  componentDidUpdate(prevProps) {
    const { userId, credentials, getUserPermissions, credentialId } = this.props
    const { userId: prevUserId, credentialId: prevCredentialId } = prevProps

    // first condition triggers getUserPermissions if credentials haven't been fetched
    // second condition triggers getUserPermissions if a user is searched when already on the user permissions page
    if ((prevCredentialId !== credentialId) || prevUserId !== userId) {
      getUserPermissions({ credentials, userId })
    }
  }

  render() {
    const {
      showDisableAllPermissionsModal,
      user,
      userId,
      permissions,
    } = this.props

    const disableAllPermissions = () => {
      showDisableAllPermissionsModal({
        user,
        userId,
        permissions,
      })
    }

    return (
      <ManagePermission
        {...this.props}
        disableAllPermissions={disableAllPermissions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePermissionC)
