import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import WelcomeEmailForm from 'components/Admin/Forms/WelcomeEmailForm/WelcomeEmailForm'
import { WELCOME_EMAIL_FORM } from 'constants/formConstants'
import validateWelcomeEmailForm from 'utilities/validate/validateWelcomeEmailForm'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import some from 'lodash/some'

const config = {
  multiple: false,
  maxSize: 2000000,
  accept: 'image/jpeg, image/png, image/tiff',
}

const instructions = [
  {
    name: 'main',
    message: 'Drag & Drop, or click to select a file',
  },
  {
    name: 'sub',
    message: 'Accept: jpeg, png, tiff; Max Size: 2 MB',
  },
]

const mapStateToProps = (state, props) => {
  const welcomeEmail = get(props, 'welcomeEmail')
  const templateExist = some(['html', 'subject', 'text'], (property) => get(welcomeEmail, property) !== undefined)

  return {
    config,
    instructions,
    templateExist,
  }
}


class WelcomeEmailFormC extends Component {
  componentDidUpdate(prevProps) {
    const { welcomeEmail: prevWelcomeEmail } = prevProps

    const {
      initialize,
      initialized,
      welcomeEmail,
    } = this.props

    if (!isEqual(welcomeEmail, prevWelcomeEmail) || !initialized) {
      initialize(welcomeEmail)
    }
  }

  render() {
    return (
      <WelcomeEmailForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: WELCOME_EMAIL_FORM,
  validate: validateWelcomeEmailForm,
})(WelcomeEmailFormC))
