import React, { Component } from 'react'
import SelfieChecks from './SelfieChecks'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import PlaidCheckColorcodedStatus from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/PlaidCheckColorcodedStatus/PlaidCheckColorcodedStatus'
import get from 'lodash/get'

const personaSelfieCheckTypes = [
  'selfie_account_comparison',
  'selfie_age_comparison',
  'selfie_attribute_comparison',
  'selfie_face_covering_detection',
  'selfie_glare_detection',
  'selfie_glasses_detection',
  'selfie_id_comparison',
  'selfie_liveness_detection',
  'selfie_multiple_faces_detection',
  'selfie_pose_detection',
  'selfie_pose_repeat_detection',
  'selfie_public_figure_detection',
  'selfie_suspicious_entity_detection',
]

const personaSelfieCheckTypesToLabelMap = {
  selfie_account_comparison: 'Account Comparison',
  selfie_age_comparison: 'Age Comparison',
  selfie_attribute_comparison: 'Attribute Comparison',
  selfie_face_covering_detection: 'Face Covering Detection',
  selfie_glare_detection: 'Glare Detection',
  selfie_glasses_detection: 'Glasses Detection',
  selfie_id_comparison: 'Self ID Comparison',
  selfie_liveness_detection: 'Liveness Detection',
  selfie_multiple_faces_detection: 'Multiple Faces Detection',
  selfie_pose_detection: 'Pose Detection',
  selfie_pose_repeat_detection: 'Pose Repeat Detection',
  selfie_public_figure_detection: 'Public Figure Detection',
  selfie_suspicious_entity_detection: 'Suspicious Entity Detection',
}

class SelfieChecksC extends Component {
  render() {
    const report = get(this.props, 'report', {})

    const selfieChecksData = convertPageSectionDataToV2(
      personaSelfieCheckTypes.map((checkType) => {
        return {
          label: personaSelfieCheckTypesToLabelMap[checkType],
          value: <PlaidCheckColorcodedStatus
            value={get(report, `details.checks.${checkType}.result`, '')}
            description={get(report, `details.checks.${checkType}.description`, '')}
          />,
        }
      }),
    )

    return (
      <SelfieChecks
        {...this.props}
        selfieChecksData={selfieChecksData}
      />
    )
  }
}

export default SelfieChecksC
