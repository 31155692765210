import './CreateInstantPayoutConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import { SETTLEMENT_PATH, SETTLEMENTS_PATH } from 'constants/pathConstants'
import { TIMES_OCTAGON, TOOLTIP_ICON, CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import {
  INSTANT_PAYOUT_CONFIRMATION,
  PAYOUT_DETAILS,
  INSTANT_PAYOUT_FAILED_MSG,
  INSTANT_PAYOUT_PENDING_MSG,
  INSTANT_PAYOUT_FAILED,
  INSTANT_PAYOUT_LABEL,
  INSTANT_PAYOUT_SUCCEEDED,
  VIEW_SETTLEMENT,
  VIEW_ALL_SETTLEMENTS,
  PRINT_CONFIRMATION,
} from 'constants/language/languageConstants'

const CreateInstantPayoutConfirmation = ({
  printPage = () => {},
  isFetchingInstantPayout,
  isFetchingPaymentInstrument,
  instantPayoutDetails = [],
  isPending = false,
  isFailed = false,
  isSucceeded = false,
  credentialId = {},
  settlementId = '',
}) => {
  return (
    <div className='CreateInstantPayoutConfirmation'>
      { (isFetchingInstantPayout || isFetchingPaymentInstrument) && <Loader /> }

      { !isFetchingInstantPayout && !isFetchingPaymentInstrument && (
        <>
          { isFailed && (
            <div className='failed-payout'>
              <h2 className='failed-header'>{INSTANT_PAYOUT_FAILED}</h2>
              <div className='failure-message-container'>
                <div className='failure-message'> <i className={`fa fa-${TIMES_OCTAGON} failure-icon`} />{INSTANT_PAYOUT_FAILED_MSG}</div>
              </div>
            </div>
          )}

          { isPending && (
            <div className='pending-payout'>
              <h2 className='pending-header'>{INSTANT_PAYOUT_LABEL}</h2>
              <div className='pending-message-container'>
                <div className='pending-message'> <i className={`fa fa-${TOOLTIP_ICON} pending-icon`} />{INSTANT_PAYOUT_PENDING_MSG}</div>
              </div>
            </div>
          )}

          { isSucceeded && (
            <div className='success-payout'>
              <h2 className='success-header'>{INSTANT_PAYOUT_CONFIRMATION}</h2>
              <div className='success-message-container'>
                <div className='success-message'> <i className={`fa fa-${CHECK_CIRCLE_ICON} success-icon`} />{INSTANT_PAYOUT_SUCCEEDED}  <a className='print-page-link' onClick={printPage}>{PRINT_CONFIRMATION}</a></div>
              </div>
            </div>
          )}

          <div className='instant-payout-details'>
            <PageSectionV2
              title={PAYOUT_DETAILS}
              columns={instantPayoutDetails}
            />
          </div>

          <div className='buttons-container flex flex-start'>
            <Button className='view-settlement-button' onClick={() => goToPath({ pathname: SETTLEMENT_PATH({ credentialId, settlementId }) })} label={VIEW_SETTLEMENT} />
            { (isPending || isFailed) && (
              <Button className='view-all-settlements' variant='ghost' onClick={() => goToPath({ pathname: SETTLEMENTS_PATH({ credentialId }) })} label={VIEW_ALL_SETTLEMENTS} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

CreateInstantPayoutConfirmation.propTypes = {
  printPage: PropTypes.func,
  isFetchingInstantPayout: PropTypes.bool,
  instantPayoutDetails: PropTypes.array,
  isPending: PropTypes.bool,
  isFailed: PropTypes.bool,
  isSucceeded: PropTypes.bool,
  credentialId: PropTypes.string,
  settlementId: PropTypes.string,
}

export default CreateInstantPayoutConfirmation
