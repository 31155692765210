import { GET_ACCESS_FORM_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAccessFormRequest = ({
  accessFormId,
  credentials,
}) => createAction({
  type: GET_ACCESS_FORM_F_REQUEST,
  id: accessFormId,
  credentials,
})

export default getAccessFormRequest
