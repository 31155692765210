import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateBalanceAdjustmentsVelocityLimitsReviewForm from './UpdateBalanceAdjustmentsVelocityLimitsReviewForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import convertToMilitaryTime from 'utilities/convert/convertToMilitaryTime'
import convertToAMPMTime from 'utilities/convert/convertToAMPMTime'
import { FETCHING } from 'constants/reducerConstants'
import { TIME_ZONE_TO_DISPLAY_MAP } from 'constants/timeConstants'
import get from 'lodash/get'

import {
  MAX_TRANSACTION_AMOUNT_FOR_CARDS,
  START_TIME,
  TIME_ZONE,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  MONTHLY_START_DATE,
} from 'constants/language/languageConstants'

import {
  getApplicationSelector,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const formValues = get(state, 'flowsR.formValues.balanceAdjustmentsVelocityLimits')
  const applicationId = get(props, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const businessName = get(application, 'businessName')
  const disbursementRules = getDisbursementRulesSelector(state)
  const disbursementRulesId = get(disbursementRules, 'id')

  const [
    maxTransactionAmountForACH,
    startTime,
    timeMeridian,
    timeZone,
    dailyACHCount,
    dailyACHVolume,
    monthlyStartDate,
    monthlyACHCount,
    monthlyACHVolume,
  ] = getMany(formValues, [
    'maxTransactionAmountForACH',
    'startTime.value',
    'timeMeridian.value',
    'timeZone.value',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyACHCount',
    'monthlyACHVolume',
  ])

  const transactionDetails = convertPageSectionDataToV2([
    {
      label: MAX_TRANSACTION_AMOUNT_FOR_CARDS,
      value: formatMoney({ amount: maxTransactionAmountForACH }),
    },
  ], 1)


  const windowStartTimeDetails = convertPageSectionDataToV2([
    {
      label: START_TIME,
      value: convertToAMPMTime({ hour: convertToMilitaryTime({ hour: startTime, meridian: timeMeridian }) }),
    },
    {
      label: TIME_ZONE,
      value: TIME_ZONE_TO_DISPLAY_MAP[timeZone],
    },
  ], 1)

  const dailyLimitsDetails = convertPageSectionDataToV2([
    {
      label: DAILY_ACH_COUNT,
      value: formatNumber(dailyACHCount),
    },
    {
      label: DAILY_ACH_VOLUME,
      value: formatMoney({ amount: dailyACHVolume }),
    },
  ], 1)

  const monthlyLimitsDetails = convertPageSectionDataToV2([
    {
      label: MONTHLY_START_DATE,
      value: monthlyStartDate,
    },
    {
      label: MONTHLY_ACH_COUNT,
      value: formatNumber(monthlyACHCount),
    },
    {
      label: MONTHLY_ACH_VOLUME,
      value: formatMoney({ amount: monthlyACHVolume }),
    },
  ], 1)

  let subtitle = ''
  if (applicationId) {
    subtitle = `Review velocity limits on Balance Adjustments for ${businessName}.`
  }

  return {
    credentials,
    isFetching,
    subtitle,
    transactionDetails,
    windowStartTimeDetails,
    dailyLimitsDetails,
    monthlyLimitsDetails,
    disbursementRulesId,
  }
}

class UpdateBalanceAdjustmentsVelocityLimitsReviewFormC extends Component {
  render() {
    return (
      <UpdateBalanceAdjustmentsVelocityLimitsReviewForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(UpdateBalanceAdjustmentsVelocityLimitsReviewFormC)
