import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'
import { VOIDED } from 'constants/language/languageConstants'

import {
  SUCCEEDED,
  DEFAULT,
  CANCELED,
} from 'constants/statusConstants'

const CapturedData = {
  Captured: SUCCEEDED,
  'Not Captured': DEFAULT,
  [VOIDED]: CANCELED,
}

const CapturedStatus = ({
  value = '',
}) => <ColorcodedStatus data={CapturedData} value={value} />

CapturedStatus.propTypes = {
  value: PropTypes.string,
}

export default CapturedStatus
