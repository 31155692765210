import './APILogS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'

import {
  DETAILS,
  REQUEST_HEADERS,
  RESPONSE_HEADERS,
  REQUEST_BODY,
  RESPONSE_BODY,
  REQUEST_BODY_EMPTY_MESSAGE,
  RESPONSE_BODY_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const APILog = ({
  isFetching = false,
  headerData = {},
  detailsSectionData = [],
  requestHeadersSectionData = [],
  responseHeadersSectionData = [],
  requestBody = {},
  responseBody = {},
}) => {
  return (
    <div className='APILog'>
      <ContextBar />

      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        className='info-section'
        title={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetching}
      />


      <PageSectionV2C
        className='request-headers-section'
        title={REQUEST_HEADERS}
        columns={requestHeadersSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        className='request-body-section'
        title={REQUEST_BODY}
        isFetching={isFetching}
      >
        <PrettyJSON
          data={requestBody}
          isFetching={isFetching}
          emptyMessage={REQUEST_BODY_EMPTY_MESSAGE}
          showCopyButton
        />
      </PageSectionV2C>

      <PageSectionV2C
        className='response-headers-section'
        title={RESPONSE_HEADERS}
        columns={responseHeadersSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        className='response-body-section'
        title={RESPONSE_BODY}
        isFetching={isFetching}
      >
        <PrettyJSON
          data={responseBody}
          isFetching={isFetching}
          emptyMessage={RESPONSE_BODY_EMPTY_MESSAGE}
          showCopyButton
        />
      </PageSectionV2C>
    </div>
  )
}

APILog.propTypes = {
  isFetching: PropTypes.bool,
  headerData: PropTypes.object,
  detailsSectionData: PropTypes.array,
  requestHeadersSectionData: PropTypes.array,
  responseHeadersSectionData: PropTypes.array,
  requestBody: PropTypes.object,
  responseBody: PropTypes.object,
}

export default APILog
