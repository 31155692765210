import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import MerchantPayments from './MerchantPayments'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { PAYMENT } from 'constants/language/languageConstants'
import { PAYMENTS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { TRANSFER } from 'constants/transferConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  DEVICE_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter(PAYMENT),
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const merchantId = get(props, 'merchantId')
  const isFetching = get(state, `merchantTransfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = PAYMENTS_PATH({ credentialId })

  const flowValues = {
    merchantId,
  }

  const initialQueries = {
    merchant_id: merchantId,
  }

  return {
    merchantId,
    flowValues,
    isFetching,
    paymentPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: merge({}, createdLastMonthQueries(), { type: { eq: TRANSFER } }),
  }
}

class MerchantPaymentsC extends Component {
  render() {
    return (
      <MerchantPayments {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(MerchantPaymentsC)
