import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import moment from 'moment'
import CreateSubscriptionScheduleForm from 'components/Customer/Forms/CreateSubscriptionScheduleForm/CreateSubscriptionScheduleForm'
import { createDayOptions } from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import getMany from 'utilities/get/getMany'
import { CREATE_SUBSCRIPTION_SCHEDULE } from 'constants/formConstants'

import {
  PERIODIC_MONTHLY,
  FIXED_TIME_INTERVAL,
} from 'constants/subscriptionConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(CREATE_SUBSCRIPTION_SCHEDULE)
  const subscriptionType = formSelector(state, 'subscriptionType')
  const periodOffsetMonth = formSelector(state, 'periodOffset.month')
  const periodOffset = formSelector(state, 'periodOffset')
  const fixedTimeIntervalOffset = formSelector(state, 'fixedTimeIntervalOffset')
  const [hourlyInterval, intervalCount] = getMany(fixedTimeIntervalOffset, ['hourlyInterval', 'intervalCount'])
  const [day, month] = getMany(periodOffset, ['day', 'month'])

  // included 29 for feb since BE supports leap year
  const daysInMonthTypeYearly = periodOffsetMonth === '2' ? 29 : moment(periodOffsetMonth, 'MM').daysInMonth()
  const dayOptions = subscriptionType === PERIODIC_MONTHLY ? createDayOptions() : createDayOptions(daysInMonthTypeYearly)

  const scheduleText = `This schedule will charge ${convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset, lowerCase: true })}`
  const showFixedTimeIntervalOffsetText = hourlyInterval && intervalCount
  const showPeriodOffsetText = subscriptionType === PERIODIC_MONTHLY ? day : day && month
  const showScheduleText = subscriptionType === FIXED_TIME_INTERVAL ? showFixedTimeIntervalOffsetText : showPeriodOffsetText

  return {
    subscriptionType,
    dayOptions,
    periodOffsetMonth,
    scheduleText,
    showScheduleText,
  }
}

class CreateSubscriptionScheduleFormC extends Component {
  componentDidUpdate(prevProps) {
    const { periodOffsetMonth: prevPeriodOffsetMonth } = prevProps
    const { dispatch, periodOffsetMonth } = this.props

    // clear period offset day whenever month is changed
    if (prevPeriodOffsetMonth !== periodOffsetMonth && prevPeriodOffsetMonth && periodOffsetMonth) {
      dispatch(change(CREATE_SUBSCRIPTION_SCHEDULE, 'periodOffset.day', ''))
    }
  }

  render() {
    return (
      <CreateSubscriptionScheduleForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateSubscriptionScheduleFormC)
