import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_REPORT } from 'constants/apiConstants'

const getUnderwritingReportAPI = ({
  id,
  credentials,
  queries,
  meta,
}) => {
  return paymentsAPI.get({
    path: UNDERWRITING_REPORT({ reportId: id }),
    credentials,
    queries,
    meta,
  })
}

export default getUnderwritingReportAPI
