import { fork } from 'redux-saga/effects'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import find from 'lodash/find'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import head from 'lodash/head'
import co from 'co'
import { getState } from 'state-layer/configureStore'
import relatedResourcesSaga from 'utilities/relatedResourcesSaga'


function * fetchRelatedResourcesGen ({
  relatedItemsMap,
  condition,
  api,
  credentials,
}) {
  const relatedIds = []

  return yield map(relatedItemsMap, (relatedResourceId) => {
    // this conditional skips adding a relatedResource if it was previously found
    // duplicate resources do not need to be put into the reducer
    if (includes(relatedIds, relatedResourceId)) return {}

    relatedIds.push(relatedResourceId)

    return condition({ relatedResourceId }) ? api({ id: relatedResourceId, credentials }) : {}
  })
}

const fetchRelatedResources = co.wrap(fetchRelatedResourcesGen)

function * getRelatedResourcesO ({
  api,
  items,
  idKey = 'id',
  credentials,
  frontendModel = ({ data }) => data,
  reducer,
  condition,
}) {
  const state = getState()
  const existingItems = get(state, `${reducer}.items`, {})

  // create a key/val pair list of parentId -> relatedResourceId
  const relatedItemsMap = reduce(items, (total, item, parentId) => {
    const relatedResourceId = get(item, idKey)
    return !isEmpty(relatedResourceId)
      ? merge({}, total, { [parentId]: relatedResourceId })
      : total
  }, {})

  if (isEmpty(relatedItemsMap)) return createSuccessResponse({})

  // since this fn is now a pure generate, it cannot handle yield map() and need to move it to it's own wrapped function
  const relatedResourceResponses = yield fetchRelatedResources({
    relatedItemsMap,
    condition,
    api,
    credentials,
  })

  const normalizedResponses = normalizeResponse({ content: relatedResourceResponses })

  // check if the data is in an array (orchestration files always return an array, even when a single resource)
  const relatedResourcesData = map(normalizedResponses, (response) => {
    const data = get(response, 'data')

    return isArray(data) ? head(data) : data
  })

  const filteredResourcesData = filter(relatedResourcesData, (data) => data !== undefined)

  yield fork(relatedResourcesSaga, { resources: filteredResourcesData })

  // turn the related resource into a reducer ready list of resource models
  const relatedResourceModels = reduce(relatedItemsMap, (total, relatedResourceId) => {
    // TODO: look into any situation where subResource id is NOT found at 'id' - would need to pass that in if possible
    const data = find(relatedResourcesData, (response) => get(response, 'id') === relatedResourceId)

    return data ? merge({}, total, { [relatedResourceId]: frontendModel({ data }) }) : total
  }, {})

  return createSuccessResponse({
    data: relatedResourceModels,
  })
}

export default getRelatedResourcesO
