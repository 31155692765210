import getCurrentUser from 'utilities/get/getCurrentUser'
import every from 'lodash/every'
import get from 'lodash/get'

const hasPermissions = (permissions = []) => {
  const currentUserPermissions = get(getCurrentUser(), 'permissions')

  return every(permissions, (permission) => {
    return get(currentUserPermissions, `${permission}.enabled`) === true
  })
}

export default hasPermissions
