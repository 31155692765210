import get from 'lodash/get'
import map from 'lodash/map'

const getMany = (object, getKeys) => map(getKeys, (key) => get(object, key))

export const getManyCaseInsensitive = (object, getKeys) => map(
  map(getKeys, (key) => key.toLowerCase()),
  (key) => get(object, key.toLowerCase()),
)

export default getMany
