import './ReactSelectS.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import { ALERT_ICON } from 'constants/iconConstants'
import { t } from 'constants/language/languageConstants'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import get from 'lodash/get'

const colors = {
  borderInputDefault: '#7D90A5',
  actionActive: '#0B5DBC',
  textPrimary: '#020F1C',
  actionHoverLight: '#E6F3FD',
  bgLight: '#F6F7F8',
  textInactive: '#7D90A5',
}

// TODO: add reactSelectCustomStyles here so we don't have to ass it every time we use ReactSelect in form
const selectComponents = [
  'menu',
  'menuList',
  'noOptionsMessage',
  'loadingMessage',
  'menuPortal',
  'container',
  'valueContainer',
  'indicatorsContainer',
  'dropdownIndicator',
  'clearIndicator',
  'indicatorSeparator',
  'loadingIndicator',
  'control',
  'group',
  'groupHeading',
  'input',
  'multiValue',
  'multiValueLabel',
  'multiValueRemove',
  'option',
  'placeholder',
  'singleValue',
]

const defaultStyles = {
  control: (styles = {}, state = {}) => ({
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    boxShadow: state.isFocused ? '0px 0px 1px 1px rgba(11, 93, 188, 0.50)' : 'none',
    backgroundColor: state.isDisabled ? '#F6F7F8' : 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    minHeight: '40px',
  }),
  option: (styles = {}, state = {}) => ({
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    backgroundColor: state.isSelected ? colors?.actionHoverLight : 'none',
    color: colors?.textPrimary,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors?.bgLight,
    },
    padding: '8px 16px',
  }),
  menu: {
    border: '1px solid #7D90A5',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuList: {
    padding: '0px',
    borderRadius: '4px',
  },
  indicatorSeparator: {
    display: 'none !important',
  },
  placeholder: (styles = {}, state = {}) => ({ color: state.isDisabled ? colors?.textInactive : colors?.textInactive }),
}

/**
 * This function merges our default select styles with custom styles.
 * Custom styles will overwrite default styles if they are changing the same css property.
 * If custom styles are a function, it will be called with the default styles and state.
 * If custom styles are an object, it will be merged directly with the default styles.
 *
 * @param {Object} customStyles - Object with keys representing React Select components and values as style functions or objects.
 * @returns {Object} - Merged styles object for React Select components.
 *
 * @example
 * mergeReactSelectStyles({
 *   option: (styles = {}, state = {}) => ({
 *     borderColor: state.isFocused ? 'red' : 'blue',
 *     backgroundColor: state.isSelected ? 'gray' : 'none',
 *     fontSize: '16px',
 *   }),
 *   menu: {
 *     padding: '8px',
 *   },
 * })
 */
const mergeReactSelectStyles = (customStyles) => reduce(selectComponents, (total, component) => {
  const defaultStyle = get(defaultStyles, component, () => ({}))
  const customStyle = get(customStyles, component, () => ({}))

  total[component] = (styles, state) => {
    return {
      ...styles,
      ...isFunction(defaultStyle) ? defaultStyle(styles, state) : defaultStyle,
      ...isFunction(customStyle) ? customStyle(styles, state) : customStyle,
    }
  }

  return total
}, {})

class ReactSelect extends Component {
  render() {
    const {
      input,
      options,
      name,
      formatOptionLabel,
      placeholder,
      menuPlacement = 'bottom',
      isDisabled = false,
      classNames = '',
      filterOption,
      label,
      tooltip,
      tooltipPosition,
      leadingIcon,
      trailingIcon,
      helperText,
      customStyles = {},
      required = true,
      maxMenuHeight,
      hiddenLabel,
      components,
      noOptionsMessage = () => 'No options',
      meta: {
        active,
        touched,
        error,
        warning,
      },
    } = this.props

    const styles = mergeReactSelectStyles(customStyles)
    const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
    const warningJSX = warning && <span className='warning'>{warning}</span>
    const inputName = get(input, 'name')
    const inputClassName = inputName && inputName.replace('.', '_')
    const hasIcon = (leadingIcon || trailingIcon) ? 'hasIcon' : ''
    const hasLeadingIcon = leadingIcon ? 'hasLeadingIcon' : ''
    const isActive = active ? 'focus' : ''
    const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

    const parentClassNames = classnames({
      ReactSelect: true,
      'react-select-parent': true,
      [inputClassName]: true,
      [classNames]: !isEmpty(classNames),
      disabled: !!isDisabled,
    })

    const inputAreaClassNames = classnames({
      'input-area': true,
      [hasIcon]: !!hasIcon,
      [hasLeadingIcon]: !!hasLeadingIcon,
      [isActive]: !!isActive,
      [hasError]: !!hasError,
    })

    return (
      <div className={parentClassNames}>
        <div className='flex label-area'>
          { (label || hiddenLabel) && <label className={`label-2 ${hiddenLabel && 'hidden-label'}`}>{t(label)}</label> }
          {required && label && <div className='required-field'>*</div>}

          { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
        </div>

        <div className={inputAreaClassNames}>
          {leadingIcon && <i className={`leadingIcon ${leadingIcon}`} />}

          <Select
            className='ReactSelectComponent'
            {...input}
            name={name}
            options={options}
            value={input.value}
            onChange={(value) => input.onChange(value)}
            // onBlur={() => input.onBlur(input.value)} TODO: Look into why this breaks mobile... we need to prevent the onBlur event from firing on mobile
            onBlur={event => event.preventDefault()}
            formatOptionLabel={formatOptionLabel}
            menuPlacement={menuPlacement}
            isDisabled={isDisabled}
            placeholder={placeholder}
            filterOption={filterOption}
            styles={styles}
            maxMenuHeight={maxMenuHeight}
            components={components}
            noOptionsMessage={() => noOptionsMessage()}
          />

          {trailingIcon && <i className={`trailingIcon ${trailingIcon}`} />}
        </div>

        { helperText && <div className='helper-text label-2'>{helperText}</div> }

        { (touched && errorJSX) || warningJSX }
      </div>
    )
  }
}

ReactSelect.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  name: PropTypes.string,
  formatOptionLabel: PropTypes.func,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
  classNames: PropTypes.string,
  filterOption: PropTypes.func,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  leadingIcon: PropTypes.string,
  trailingIcon: PropTypes.string,
  helperText: PropTypes.string,
  customStyles: PropTypes.object,
  maxMenuHeight: PropTypes.number,
  required: PropTypes.bool,
  meta: PropTypes.object,
  hiddenLabel: PropTypes.string,
}

export default ReactSelect
