import keyBy from 'lodash/keyBy'

import getAppConfigurationAPI from 'api/dashboard/get/getAppConfigurationAPI'
import getConfigurationAPI from 'api/dashboard/get/getConfigurationAPI'
import getConfigurationsAPI from 'api/dashboard/get/getConfigurationsAPI'
import getCredentialsAPI from 'api/dashboard/get/getCredentialsAPI'
import getTransfersAPI from 'api/finix/get/getTransfersAPI'
import getSplitTransfersAPI from 'api/finix/get/getSplitTransfersAPI'
import getDashboardTransfersAPI from 'api/dashboard/get/getTransfersAPI'
import getBalanceTransferAPI from 'api/finix/get/getBalanceTransferAPI'
import getBalanceTransfersAPI from 'api/finix/get/getBalanceTransfersAPI'
import getAuthorizationsAPI from 'api/finix/get/getAuthorizationsAPI'
import getSettlementsAPI from 'api/finix/get/getSettlementsAPI'
import getSettlementTransfersAPI from 'api/finix/get/getSettlementTransfersAPI'
import getSettlementFundingTransfersAPI from 'api/finix/get/getSettlementFundingTransfersAPI'
import getSettlementGroupAPI from 'api/finix/get/getSettlementGroupAPI'
import getDisputesAPI from 'api/finix/get/getDisputesAPI'
import getDisputeEvidencesAPI from 'api/finix/get/getDisputeEvidencesAPI'
import getDisputeAdjustmentTransfersAPI from 'api/finix/get/getDisputeAdjustmentTransfersAPI'
import getReportsAPI from 'api/finix/get/getReportsAPI'
import getCategorizedReportsAPI from 'api/finix/get/getCategorizedReportsAPI'
import getIdentitiesAPI from 'api/finix/get/getIdentitiesAPI'
import getMerchantsAPI from 'api/finix/get/getMerchantsAPI'
import getComplianceFormsAPI from 'api/dashboard/get/getComplianceFormsAPI'
import getComplianceFormAPI from 'api/dashboard/get/getComplianceFormAPI'
import getComplianceFormTemplatesAPI from 'api/dashboard/get/getComplianceFormTemplatesAPI'
import getComplianceFormTemplateFormsAPI from 'api/dashboard/get/getComplianceFormTemplateFormsAPI'
import getStatementsAPI from 'api/finix/get/getStatementsAPI'
import patchComplianceSettingsAPI from 'api/dashboard/patch/patchComplianceSettingsAPI'
import getApplicationsAPI from 'api/finix/get/getApplicationsAPI'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import getPaymentInstrumentsAPI from 'api/finix/get/getPaymentInstrumentsAPI'
import getPaymentInstrumentVerificationsAPI from 'api/finix/get/getPaymentInstrumentVerificationsAPI'
import getReviewQueueAPI from 'api/finix/get/getReviewQueueAPI'
import getCredentialUsersAPI from 'api/dashboard/get/getCredentialUsersAPI'
import getDashboardUsersAPI from 'api/dashboard/get/getDashboardUsersAPI'
import getAssociatedIdentitiesAPI from 'api/finix/get/getAssociatedIdentitiesAPI'
import getIdentityDisputesAPI from 'api/finix/get/getIdentityDisputesAPI'
import getIdentitySettlementsAPI from 'api/finix/get/getIdentitySettlementsAPI'
import getIdentityMerchantsAPI from 'api/finix/get/getIdentityMerchantsAPI'
import getIdentityVerificationsAPI from 'api/finix/get/getIdentityVerificationsAPI'
import getIdentityVerificationAPI from 'api/finix/get/getIdentityVerificationAPI'
import getMerchantVerificationsAPI from 'api/finix/get/getMerchantVerificationsAPI'
import getMerchantSettlementsAPI from 'api/finix/get/getMerchantSettlementsAPI'
import getApplicationAssociatedIdentitiesAPI from 'api/finix/get/getApplicationAssociatedIdentitiesAPI'
import getApplicationDisputesAPI from 'api/finix/get/getApplicationDisputesAPI'
import getApplicationIdentitiesAPI from 'api/finix/get/getApplicationIdentitiesAPI'
import getApplicationMerchantsAPI from 'api/finix/get/getApplicationMerchantsAPI'
import getApplicationProcessorsAPI from 'api/finix/get/getApplicationProcessorsAPI'
import getApplicationProcessorAPI from 'api/finix/get/getApplicationProcessorAPI'
import getApplicationSettlementsAPI from 'api/finix/get/getApplicationSettlementsAPI'
import getWebhooksAPI from 'api/finix/get/getWebhooksAPI'
import getWebhookLogsAPI from 'api/finix/get/getWebhookLogsAPI'
import getApplicationUsersAPI from 'api/finix/get/getApplicationUsersAPI'
import getV1UserAPI from 'api/dashboard/get/getV1UserAPI'
import getAdminUserAPI from 'api/dashboard/get/getAdminUserAPI'
import getAdminUserMembershipsAPI from 'api/dashboard/get/getAdminUserMembershipsAPI'
import getAdminUserCredentialsAPI from 'api/dashboard/get/getAdminUserCredentialsAPI'
import getWelcomeEmailTemplateAPI from 'api/dashboard/get/getWelcomeEmailTemplateAPI'
import getPlatformsAPI from 'api/finix/get/getPlatformsAPI'
import getPlatformAPI from 'api/finix/get/getPlatformAPI'
import getPlatformProcessorConfigAPI from 'api/finix/get/getPlatformProcessorConfigAPI'
import getPlatformProcessorConfigsAPI from 'api/finix/get/getPlatformProcessorConfigsAPI'
import getPlatformUsersAPI from 'api/finix/get/getPlatformUsersAPI'
import getPlatformUserAPI from 'api/finix/get/getPlatformUserAPI'
import getSettlementEntriesAPI from 'api/finix/get/getSettlementEntriesAPI'
import getFeeAPI from 'api/finix/get/getFeeAPI'
import getPayoutPlanAPI from 'api/finix/get/getPayoutPlanAPI'
import getPayoutPlansAPI from 'api/finix/get/getPayoutPlansAPI'
import getNotificationsAPI from 'api/dashboard/get/getNotificationsAPI'
import getSubscriptionScheduleAPI from 'api/finix/get/getSubscriptionScheduleAPI'
import getSubscriptionSchedulesAPI from 'api/finix/get/getSubscriptionSchedulesAPI'
import getSubscriptionScheduleAmountsAPI from 'api/finix/get/getSubscriptionScheduleAmountsAPI'
import getSubscriptionEnrollmentAPI from 'api/finix/get/getSubscriptionEnrollmentAPI'
import getSubscriptionEnrollmentsAPI from 'api/finix/get/getSubscriptionEnrollmentsAPI'
import getDashboardCustomizationsAPI from 'api/dashboard/get/getDashboardCustomizationsAPI'
import getDashboardCustomizationAPI from 'api/dashboard/get/getDashboardCustomizationAPI'
import getGuestDashboardCustomizationsAPI from 'api/guestDashboard/get/getGuestDashboardCustomizationsAPI'
import getGuestAccessFormAPI from 'api/guestDashboard/get/getGuestAccessFormAPI'
import getGuestOnboardingFormUnderwritingProfileAPI from 'api/guestDashboard/get/getGuestOnboardingFormUnderwritingProfileAPI'
import getResponseFilesAPI from 'api/finix/get/getResponseFilesAPI'
import getPaymentMethodConfigurationAPI from 'api/finix/get/getPaymentMethodConfigurationAPI'
import getCertificateSigningRequestsAPI from 'api/finix/get/getCertificateSigningRequestsAPI'
import getFilesAPI from 'api/dashboard/get/getFilesAPI'
import getEmailTemplateAPI from 'api/dashboard/get/getEmailTemplateAPI'
import getChartsAPI from 'api/dashboard/get/getChartsAPI'
import getGuestOnboardingFormDataAPI from 'api/guestDashboard/get/getGuestOnboardingFormDataAPI'
import getHostedFormTokenVerificationAPI from 'api/guestDashboard/get/getHostedFormTokenVerificationAPI'
import getMerchantPayoutSettingsAPI from 'api/finix/get/getMerchantPayoutSettingsAPI'
import getPayoutSettingsAPI from 'api/finix/get/getPayoutSettingsAPI'
import getExportsAPI from 'api/dashboard/get/getExportsAPI'
import getExportsO from 'state-layer/orchestration/get/getExportsO'
import getNotificationSettingsAPI from 'api/dashboard/get/getNotificationSettingsAPI'
import getAPILogsAPI from 'api/dashboard/get/getAPILogsAPI'
import getAPILogAPI from 'api/dashboard/get/getAPILogAPI'
import getCustomerOnboardingFormsAPI from 'api/dashboard/get/getCustomerOnboardingFormsAPI'
import getIdentityUnmaskAPI from 'api/dashboard/get/getIdentityUnmaskAPI'
import getPaymentInstrumentUnmaskAPI from 'api/dashboard/get/getPaymentInstrumentUnmaskAPI'
import getOnboardingFormsAPI from 'api/finix/get/getOnboardingFormsAPI'
import getOnboardingFormDataAPI from 'api/finix/get/getOnboardingFormDataAPI'
import getRolesAPI from 'api/dashboard/get/getRolesAPI'
import getRoleAPI from 'api/dashboard/get/getRoleAPI'
import getMemberAPI from 'api/dashboard/get/getMemberAPI'
import getAccessFormAPI from 'api/dashboard/get/getAccessFormAPI'
import getAccessFormsAPI from 'api/dashboard/get/getAccessFormsAPI'
import getUnderwritingReviewsAPI from 'api/dashboard/get/getUnderwritingReviewsAPI'
import getAccessFormContractAPI from 'api/dashboard/get/getAccessFormContractAPI'
import getDashboardContractsAPI from 'api/dashboard/get/getDashboardContractsAPI'
import getHomeChartsAPI from 'api/dashboard/get/getHomeChartsAPI'
import getTransactionChartsAPI from 'api/dashboard/get/getTransactionChartsAPI'
import getExceptionChartsAPI from 'api/dashboard/get/getExceptionChartsAPI'
import getMerchantChartsAPI from 'api/dashboard/get/getMerchantChartsAPI'
import getUnderwritingProfileAPI from 'api/finix/get/getUnderwritingProfileAPI'
import getUnderwritingProfilesAPI from 'api/finix/get/getUnderwritingProfilesAPI'
import getUnderwritingReportAPI from 'api/finix/get/getUnderwritingReportAPI'
import getRoleMembersAPI from 'api/dashboard/get/getRoleMembersAPI'
import getCustomerOnboardingFormAPI from 'api/dashboard/get/getCustomerOnboardingFormAPI'
import getDashboardUserMFAAuthenticatorsAPI from 'api/dashboard/get/getDashboardUserMFAAuthenticatorsAPI'
import getEntityConfigurationsAPI from 'api/dashboard/get/getEntityConfigurationsAPI'
import getTransferReversalsAPI from 'api/finix/get/getTransferReversalsAPI'
import getTransferDisputesAPI from 'api/finix/get/getTransferDisputesAPI'
import getUnderwritingChartsAPI from 'api/dashboard/get/getUnderwritingChartsAPI'
import getMerchantUnderwritingSubjectEventsAPI from 'api/finix/get/getMerchantUnderwritingSubjectEventsAPI'
import getMerchantUnderwritingSubjectEventAPI from 'api/finix/get/getMerchantUnderwritingSubjectEventAPI'
import getBalancesAPI from 'api/finix/get/getBalancesAPI'
import getCurrentUsagesAPI from 'api/finix/get/getCurrentUsagesAPI'
import getDisbursementRulesAPI from 'api/finix/get/getDisbursementRulesAPI'
import getBalanceEntriesAPI from 'api/finix/get/getBalanceEntriesAPI'
import getBalanceEntryAPI from 'api/finix/get/getBalanceEntryAPI'
import getPaymentLinkAPI from 'api/finix/get/getPaymentLinkAPI'
import getPaymentLinksAPI from 'api/finix/get/getPaymentLinksAPI'
import getPurchasesAPI from 'api/finix/get/getPurchasesAPI'
import getGuestCheckoutTokenVerificationAPI from 'api/guestCheckout/get/getGuestCheckoutTokenVerificationAPI'
import getGuestCheckoutPaymentLinkAPI from 'api/guestCheckout/get/getGuestCheckoutPaymentLinkAPI'
import getGuestCheckoutFormAPI from 'api/guestCheckout/get/getGuestCheckoutFormAPI'
import getNotesAPI from 'api/dashboard/get/getNotesAPI'
import getNoteAPI from 'api/dashboard/get/getNoteAPI'
import getDraftNotesAPI from 'api/dashboard/get/getDraftNotesAPI'
import getDevicesAPI from 'api/finix/get/getDevicesAPI'
import getActiveComplianceFormTemplateAPI from 'api/dashboard/get/getActiveComplianceFormTemplateAPI'
import getApplicationProcessorConfigAPI from 'api/finix/get/getApplicationProcessorConfigAPI'
import getAuditLogEventsAPI from 'api/finix/get/getAuditLogEventsAPI'
import getAuditLogEventAPI from 'api/finix/get/getAuditLogEventAPI'
import getFraudScoresAPI from 'api/finix/get/getFraudScoresAPI'
import getUnderwritingReviewRunsAPI from 'api/finix/get/getUnderwritingReviewRunsAPI'
import getPayoutLinkAPI from 'api/finix/get/getPayoutLinkAPI'
import getTransferAttemptsAPI from 'api/finix/get/getTransferAttemptsAPI'
import getPayoutLinksAPI from 'api/finix/get/getPayoutLinksAPI'
import getTransferAttemptAPI from 'api/finix/get/getTransferAttemptAPI'
import getGuestCheckoutMerchantPaymentInstrumentsAPI from 'api/guestCheckout/get/getGuestCheckoutMerchantPaymentInstrumentsAPI'
import getGuestCheckoutPayoutLinkAPI from 'api/guestCheckout/get/getGuestCheckoutPayoutLinkAPI'
import getSplitTransferSettlementAPI from 'api/finix/get/getSplitTransferSettlementAPI'
import getFeeProfileRulesAPI from 'api/finix/get/getFeeProfileRulesAPI'
import getFeeProfileSettingsAPI from 'api/finix/get/getFeeProfileSettingsAPI'
import getBalanceAdjustmentsAPI from 'api/finix/get/getBalanceAdjustmentsAPI'
import getBalanceAdjustmentAPI from 'api/finix/get/getBalanceAdjustmentAPI'
import getReceiptAPI from 'api/finix/get/getReceiptAPI'
import getReceiptsAPI from 'api/finix/get/getReceiptsAPI'
import getReceiptDeliveryAttemptsAPI from 'api/finix/get/getReceiptDeliveryAttemptsAPI'
import getSubscriptionsAPI from 'api/finix/get/getSubscriptionsAPI'
import getSubscriptionAPI from 'api/finix/get/getSubscriptionAPI'
import getSubscriptionPlansAPI from 'api/finix/get/getSubscriptionPlansAPI'
import getSubscriptionPlanAPI from 'api/finix/get/getSubscriptionPlanAPI'
import getMonitoringAlertsAPI from 'api/finix/get/getMonitoringAlertsAPI'
import getMonitoringAlertAPI from 'api/finix/get/getMonitoringAlertAPI'
import getInstantPayoutsAPI from 'api/finix/get/getInstantPayoutsAPI'
import getInstantPayoutAPI from 'api/finix/get/getInstantPayoutAPI'

import postIdentityAPI from 'api/finix/post/postIdentityAPI'
import postIdentitySettlementsAPI from 'api/finix/post/postIdentitySettlementsAPI'
import postIdentityPaymentInstrumentAPI from 'api/finix/post/postIdentityPaymentInstrumentAPI'
import postIdentityMerchantAPI from 'api/finix/post/postIdentityMerchantAPI'
import postIdentityVerificationAPI from 'api/finix/post/postIdentityVerificationAPI'
import postApplicationIdentityAPI from 'api/finix/post/postApplicationIdentityAPI'
import postApplicationAssociatedIdentityAPI from 'api/finix/post/postApplicationAssociatedIdentityAPI'
import postUserEmailAPI from 'api/dashboard/post/postUserEmailAPI'
import postTransferReversalAPI from 'api/finix/post/postTransferReversalAPI'
import postTransferAPI from 'api/finix/post/postTransferAPI'
import postBalanceTransferAPI from 'api/finix/post/postBalanceTransferAPI'
import postDisputeEvidenceAPI from 'api/finix/post/postDisputeEvidenceAPI'
import postSubmitDisputeEvidenceAPI from 'api/finix/post/postSubmitDisputeEvidenceAPI'
import postApplicationUserAPI from 'api/finix/post/postApplicationUserAPI'
import postMerchantVerificationAPI from 'api/finix/post/postMerchantVerificationAPI'
import postV1UserAPI from 'api/dashboard/post/postV1UserAPI'
import postCredentialsAPI from 'api/dashboard/post/postCredentialsAPI'
import postUserMembershipsAPI from 'api/dashboard/post/postUserMembershipsAPI'
import postDashboardFileAPI from 'api/dashboard/post/postDashboardFileAPI'
import postPaymentInstrumentVerificationsAPI from 'api/finix/post/postPaymentInstrumentVerificationsAPI'
import postPayoutPlanAPI from 'api/finix/post/postPayoutPlanAPI'
import postSubscriptionScheduleAPI from 'api/finix/post/postSubscriptionScheduleAPI'
import postSubscriptionScheduleAmountAPI from 'api/finix/post/postSubscriptionScheduleAmountAPI'
import postSubscriptionScheduleEnrollmentAPI from 'api/finix/post/postSubscriptionScheduleEnrollmentAPI'
import postFeeAPI from 'api/finix/post/postFeeAPI'
import postWebhooksAPI from 'api/finix/post/postWebhooksAPI'
import postPaymentMethodConfigurationsAPI from 'api/finix/post/postPaymentMethodConfigurationsAPI'
import postCertificateSigningRequestsAPI from 'api/finix/post/postCertificateSigningRequestsAPI'
import postCertificateSigningRequestAPI from 'api/finix/post/postCertificateSigningRequestAPI'
import postComplianceFormTemplateAPI from 'api/dashboard/post/postComplianceFormTemplateAPI'
import postOnboardingFormAPI from 'api/finix/post/postOnboardingFormAPI'
import postExportsAPI from 'api/dashboard/post/postExportsAPI'
import postUsersAPI from 'api/finix/post/postUsersAPI'
import postNotificationSettingsAPI from 'api/dashboard/post/postNotificationSettingsAPI'
import postNotificationAcknowledgmentAPI from 'api/dashboard/post/postNotificationAcknowledgmentAPI'
import postOnboardingFormDataAPI from 'api/guestDashboard/post/postOnboardingFormDataAPI'
import postOnboardingFormLinkAPI from 'api/finix/post/postOnboardingFormLinkAPI'
import postDashboardPresignedUrlAPI from 'api/dashboard/post/postDashboardPresignedUrlAPI'
import postDashboardV2EmailsAPI from 'api/dashboard/post/postDashboardV2EmailsAPI'
import postSelfServiceSignupFormAPI from 'api/guestDashboard/post/postSelfServiceSignupFormAPI'
import postSelfServiceEmailVerificationAPI from 'api/guestDashboard/post/postSelfServiceEmailVerificationAPI'
import postSelfServiceResendEmailWithCaptchaAPI from 'api/guestDashboard/post/postSelfServiceResendEmailWithCaptchaAPI'
import postSelfServiceResendEmailAPI from 'api/guestDashboard/post/postSelfServiceResendEmailAPI'
import postMemberAPI from 'api/dashboard/post/postMemberAPI'
import postRoleAPI from 'api/dashboard/post/postRoleAPI'
import postUserChangePasswordAPI from 'api/dashboard/post/postUserChangePasswordAPI'
import postAccessFormAPI from 'api/dashboard/post/postAccessFormAPI'
import postSubmitAccessFormAPI from 'api/dashboard/post/postSubmitAccessFormAPI'
import postAcceptDisputeAPI from 'api/finix/post/postAcceptDisputeAPI'
import postUnderwritingProfileAPI from 'api/finix/post/postUnderwritingProfileAPI'
import postCustomerOnboardingFormAPI from 'api/dashboard/post/postCustomerOnboardingFormAPI'
import postCustomerOnboardingFormLinksAPI from 'api/dashboard/post/postCustomerOnboardingFormLinksAPI'
import postDashboardUserMFAResetAPI from 'api/dashboard/post/postDashboardUserMFAResetAPI'
import postEntityConfigurationAPI from 'api/dashboard/post/postEntityConfigurationAPI'
import postPaymentInstrumentAPI from 'api/finix/post/postPaymentInstrumentAPI'
import postDisbursementRulesAPI from 'api/finix/post/postDisbursementRulesAPI'
import postBalanceEntryAPI from 'api/finix/post/postBalanceEntryAPI'
import postPaymentLinkAPI from 'api/finix/post/postPaymentLinkAPI'
import postGuestPurchaseAPI from 'api/guestCheckout/post/postGuestPurchaseAPI'
import postUnderwritingReviewRunsAPI from 'api/finix/post/postUnderwritingReviewRunsAPI'
import postAuthorizationAPI from 'api/finix/post/postAuthorizationAPI'
import postNoteAPI from 'api/dashboard/post/postNoteAPI'
import postDeviceAPI from 'api/finix/post/postDeviceAPI'
import postComplianceFormAPI from 'api/finix/post/postComplianceFormAPI'
import postCustomerOnboardingFormRequestChangesAPI from 'api/dashboard/post/postCustomerOnboardingFormRequestChangesAPI'
import postCustomerOnboardingFormSubmitToUnderwritingAPI from 'api/dashboard/post/postCustomerOnboardingFormSubmitToUnderwritingAPI'
import postPayoutLinkAPI from 'api/finix/post/postPayoutLinkAPI'
import postGuestTransferAttemptAPI from 'api/guestCheckout/post/postGuestTransferAttemptAPI'
import postDisputeAPI from 'api/finix/post/postDisputeAPI'
import postFeeProfileSettingsAPI from 'api/finix/post/postFeeProfileSettingsAPI'
import postFeeProfileRulesAPI from 'api/finix/post/postFeeProfileRulesAPI'
import postBalanceAdjustmentAPI from 'api/finix/post/postBalanceAdjustmentAPI'
import postReceiptAPI from 'api/finix/post/postReceiptAPI'
import postReceiptDeliveryAttemptsAPI from 'api/finix/post/postReceiptDeliveryAttemptsAPI'
import postSubscriptionAPI from 'api/finix/post/postSubscriptionAPI'
import postSubscriptionPlanAPI from 'api/finix/post/postSubscriptionPlanAPI'
import postUnderwritingRunSilenceAPI from 'api/finix/post/postUnderwritingRunSilenceAPI'
import postInstantPayoutAPI from 'api/finix/post/postInstantPayoutAPI'

import patchTransferAPI from 'api/finix/patch/patchTransferAPI'
import patchIdentityAPI from 'api/finix/patch/patchIdentityAPI'
import patchAuthorizationAPI from 'api/finix/patch/patchAuthorizationAPI'
import patchApplicationAPI from 'api/finix/patch/patchApplicationAPI'
import patchMerchantAPI from 'api/finix/patch/patchMerchantAPI'
import patchPaymentInstrumentAPI from 'api/finix/patch/patchPaymentInstrumentAPI'
import patchRiskProfileAPI from 'api/finix/patch/patchRiskProfileAPI'
import patchReviewQueueItemAPI from 'api/finix/patch/patchReviewQueueItemAPI'
import patchAdminUpdateAPI from 'api/finix/patch/patchAdminUpdateAPI'
import patchSettlementV1API from 'api/finix/patch/patchSettlementV1API'
import patchPlatformProcessorConfigAPI from 'api/finix/patch/patchPlatformProcessorConfigAPI'
import patchUserPermissionsAPI from 'api/finix/patch/patchUserPermissionsAPI'
import patchSettlementAPI from 'api/finix/patch/patchSettlementAPI'
import patchPayoutPlanAPI from 'api/finix/patch/patchPayoutPlanAPI'
import patchUserAPI from 'api/finix/patch/patchUserAPI'
import patchSubscriptionScheduleAmountAPI from 'api/finix/patch/patchSubscriptionScheduleAmountAPI'
import patchFeeAPI from 'api/finix/patch/patchFeeAPI'
import patchIdentityPayoutPlanInstrumentMappingAPI from 'api/finix/patch/patchIdentityPayoutPlanInstrumentMappingAPI'
import patchWebhookAPI from 'api/finix/patch/patchWebhookAPI'
import patchComplianceFormAPI from 'api/dashboard/patch/patchComplianceFormAPI'
import patchComplianceFormTemplateAPI from 'api/dashboard/patch/patchComplianceFormTemplateAPI'
import patchEmailTemplateAPI from 'api/dashboard/patch/patchEmailTemplateAPI'
import patchOnboardingFormDataAPI from 'api/guestDashboard/patch/patchOnboardingFormDataAPI'
import patchPayoutSettingsAPI from 'api/finix/patch/patchPayoutSettingsAPI'
import patchApplicationUnderwritingSettings from 'api/finix/patch/patchApplicationUnderwritingSettingsAPI'
import patchCredentialAPI from 'api/dashboard/patch/patchCredentialAPI'
import patchRoleAPI from 'api/dashboard/patch/patchRoleAPI'
import patchMembershipAPI from 'api/dashboard/patch/patchMembershipAPI'
import patchDashboardUserAPI from 'api/dashboard/patch/patchDashboardUserAPI'
import patchAccessFormAPI from 'api/dashboard/patch/patchAccessFormAPI'
import patchUnderwritingReviewAPI from 'api/finix/patch/patchUnderwritingReviewAPI'
import patchUnderwritingProfileAPI from 'api/finix/patch/patchUnderwritingProfileAPI'
import patchDisputeAPI from 'api/finix/patch/patchDisputeAPI'
import patchCustomerOnboardingFormAccessFormAPI from 'api/dashboard/patch/patchCustomerOnboardingFormAccessFormAPI'
import postSubmitGuestAccessFormAPI from 'api/guestDashboard/post/postSubmitGuestAcccessFormAPI'
import postDashboardUserMFATokenAPI from 'api/dashboard/post/postDashboardUserMFATokenAPI'
import postDashboardUserMFAAssociateAPI from 'api/dashboard/post/postDashboardUserMFAAssociateAPI'
import postDashboardUserMFAVerifyAPI from 'api/dashboard/post/postDashboardUserMFAVerifyAPI'
import patchEntityConfigurationAPI from 'api/dashboard/patch/patchEntityConfigurationAPI'
import patchDisbursementRulesAPI from 'api/finix/patch/patchDisbursementRulesAPI'
import patchPaymentLinkAPI from 'api/finix/patch/patchPaymentLinkAPI'
import patchNoteAPI from 'api/dashboard/patch/patchNoteAPI'
import patchDeviceAPI from 'api/finix/patch/patchDeviceAPI'
import patchUnderwritingWorkflowAPI from 'api/finix/patch/patchUnderwritingWorkflowAPI'
import patchApplicationProcessorConfigsAPI from 'api/finix/patch/patchApplicationProcessorConfigsAPI'
import postSubmitAccessFormSalesReviewAPI from 'api/guestDashboard/post/postSubmitAccessFormSalesReviewAPI'
import patchPayoutLinkAPI from 'api/finix/patch/patchPayoutLinkAPI'
import patchTransferAttemptAPI from 'api/finix/patch/patchTransferAttemptAPI'
import patchUpdateDisputeAPI from 'api/finix/patch/patchUpdateDisputeAPI'
import patchFeeProfileSettingsAPI from 'api/finix/patch/patchFeeProfileSettingsAPI'
import patchFeeProfileRulesAPI from 'api/finix/patch/patchFeeProfileRules'
import patchSubscriptionPlanAPI from 'api/finix/patch/patchSubscriptionPlanAPI'
import patchSubscriptionAPI from 'api/finix/patch/patchSubscriptionAPI'
import patchMonitoringAlertAPI from 'api/finix/patch/patchMonitoringAlertAPI'

import deleteSubscriptionEnrollmentAPI from 'api/finix/delete/deleteSubscriptionEnrollmentAPI'
import deleteSubscriptionScheduleAmountAPI from 'api/finix/delete/deleteSubscriptionScheduleAmountAPI'
import deleteAccessFormAPI from 'api/dashboard/delete/deleteAccessFormAPI'
import deleteAPIKeyAPI from 'api/finix/delete/deleteAPIKeyAPI'
import deleteSubscriptionAPI from 'api/finix/delete/deleteSubscriptionAPI'

import updateOne from 'utilities/create/models/updateOne'

import frontendConfigurationsModel from 'utilities/create/models/frontendConfigurationsModel'
import frontendCredentialsModel from 'utilities/create/models/frontendCredentialsModel'
import frontendTransfersModel from 'utilities/create/models/frontendTransfersModel'
import frontendBalanceTransfersModel from 'utilities/create/models/frontendBalanceTransfersModel'
import frontendAuthorizationsModel from 'utilities/create/models/frontendAuthorizationsModel'
import frontendSettlementsModel from 'utilities/create/models/frontendSettlementsModel'
import frontendSettlementEntriesModel from 'utilities/create/models/frontendSettlementEntriesModel'
import frontendSettlementGroupsModel from 'utilities/create/models/frontendSettlementGroupsModel'
import frontendDisputesModel from 'utilities/create/models/frontendDisputesModel'
import frontendReportsModel from 'utilities/create/models/frontendReportsModel'
import frontendCategorizedReportsModel from 'utilities/create/models/frontendCategorizedReportsModel'
import frontendIdentitiesModel from 'utilities/create/models/frontendIdentitiesModel'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import frontendComplianceFormsModel from 'utilities/create/models/frontendComplianceFormsModel'
import frontendComplianceFormTemplatesModel from 'utilities/create/models/frontendComplianceFormTemplatesModel'
import frontendApplicationsModel from 'utilities/create/models/frontendApplicationsModel'
import frontendPaymentInstrumentsModel from 'utilities/create/models/frontendPaymentInstrumentsModel'
import frontendReviewQueueItemsModel from 'utilities/create/models/frontendReviewQueueItemsModel'
import frontendEvidencesModel from 'utilities/create/models/frontendEvidencesModel'
import frontendUsersModel from 'utilities/create/models/frontendUsersModel'
import frontendAdminUserMembershipsModel from 'utilities/create/models/frontendAdminUserMembershipsModel'
import frontendApplicationUsersModel from 'utilities/create/models/frontendApplicationUsersModel'
import frontendVerificationsModel from 'utilities/create/models/frontendVerificationsModel'
import frontendProcessorsModel from 'utilities/create/models/frontendProcessorsModel'
import frontendWebhooksModel from 'utilities/create/models/frontendWebhooksModel'
import frontendWebhookLogsModel from 'utilities/create/models/frontendWebhookLogsModel'
import frontendSettingsModel from 'utilities/create/models/frontendSettingsModel'
import frontendPlatformsModel from 'utilities/create/models/frontendPlatformsModel'
import frontendPlatformProcessorConfigsModel from 'utilities/create/models/frontendPlatformProcessorConfigsModel'
import frontendPayoutPlansModel from 'utilities/create/models/frontendPayoutPlansModel'
import frontendFeesModel from 'utilities/create/models/frontendFeesModel'
import frontendFilesModel from 'utilities/create/models/frontendFilesModel'
import frontendSubscriptionScheduleModel from 'utilities/create/models/frontendSubscriptionScheduleModel'
import frontendSubscriptionSchedulesModel from 'utilities/create/models/frontendSubscriptionSchedulesModel'
import frontendSubscriptionAmountModel from 'utilities/create/models/frontendSubscriptionAmountModel'
import frontendSubscriptionAmountsModel from 'utilities/create/models/frontendSubscriptionAmountsModel'
import frontendSubscriptionEnrollmentModel from 'utilities/create/models/frontendSubscriptionEnrollmentModel'
import frontendSubscriptionEnrollmentsModel from 'utilities/create/models/frontendSubscriptionEnrollmentsModel'
import frontendCurrentUserModel from 'utilities/create/models/frontendCurrentUserModel'
import frontendDashboardCustomizationsModel from 'utilities/create/models/frontendDashboardCustomizationsModel'
import frontendApplePayRegisteredMerchantModel from 'utilities/create/models/frontendApplePayRegisteredMerchantModel'
import frontendPayoutSettingsModel from 'utilities/create/models/frontendPayoutsSettingsModel'
import frontendNotificationSettingsModel from 'utilities/create/models/frontendNotificationSettingsModel'
import frontendNotificationsModel from 'utilities/create/models/frontendNotificationsModel'
import frontendAPILogsModel from 'utilities/create/models/frontendAPILogsModel'
import frontendDeviceModel from 'utilities/create/models/frontendDeviceModel'
import frontendPostOnboardingFormLinkModel from 'utilities/create/models/frontendPostOnboardingFormLinkModel'
import frontendSelfServiceSignupFormModel from 'utilities/create/models/frontendSelfServiceSignupFormModel'
import frontendMembersModel from 'utilities/create/models/frontendMembersModel'
import frontendRolesModel from 'utilities/create/models/frontendRolesModel'
import frontendRoleModel from 'utilities/create/models/frontendRoleModel'
import frontendMembershipsModel from 'utilities/create/models/frontendMembershipsModel'
import frontendAccessFormsModel from 'utilities/create/models/frontendAccessFormsModel'
import frontendUnderwritingReviewsModel from 'utilities/create/models/frontendUnderwritingReviewsModel'
import frontendUnderwritingProfileModel from 'utilities/create/models/frontendUnderwritingProfileModel'
import frontendUnderwritingProfilesModel from 'utilities/create/models/frontendUnderwritingProfilesModel'
import frontendEntityConfigurationsModel from 'utilities/create/models/frontendEntityConfigurationsModel'
import frontendSplitTransfersModel from 'utilities/create/models/frontendSplitTransfersModel'
import frontendBalancesModel from 'utilities/create/models/frontendBalancesModel'
import frontendCurrentUsageModel from 'utilities/create/models/frontendCurrentUsageModel'
import frontendDisbursementRulesModel from 'utilities/create/models/frontendDisbursementRulesModel'
import frontendCurrentUsagesModel from 'utilities/create/models/frontendCurrentUsagesModel'
import frontendBalanceEntryModel from 'utilities/create/models/frontendBalanceEntryModel'
import frontendBalanceEntriesModel from 'utilities/create/models/frontendBalanceEntriesModel'
import frontendPaymentLinksModel from 'utilities/create/models/frontendPaymentLinksModel'
import frontendPurchasesModel from 'utilities/create/models/frontendPurchasesModel'
import frontendApplePayCertModel from 'utilities/create/models/frontendApplePayCertModel'
import frontendApplePayCertsModel from 'utilities/create/models/frontendApplePayCertsModel'
import frontendChartsModel from 'utilities/create/models/frontendChartsModel'
import frontendPostOnboardingFormDataModel from 'utilities/create/models/frontendPostOnboardingFormDataModel'
import frontendPostOnboardingFormModel from 'utilities/create/models/frontendPostOnboardingFormModel'
import frontendHostedFormTokenVerificationModel from 'utilities/create/models/frontendHostedFormTokenVerificationModel'
import frontendExportsModel from 'utilities/create/models/frontendExportsModel'
import frontendOnboardingFormsModel from 'utilities/create/models/frontendOnboardingFormsModel'
import frontendOnboardingFormsDataModel from 'utilities/create/models/frontendOnboardingFormsDataModel'
import frontendAccessFormContractModel from 'utilities/create/models/frontendAccessFormContractModel'
import frontendNotesModel from 'utilities/create/models/frontendNotesModel'
import frontendDevicesModel from 'utilities/create/models/frontendDevicesModel'
import frontendUnderwritingWorkflowsModel from 'utilities/create/models/frontendUnderwritingWorkflowsModel'
import frontendPlaidTokenModel from 'utilities/create/models/frontendPlaidTokenModel'
import frontendApplicationProcessorConfigModel from 'utilities/create/models/frontendApplicationProcessorConfigModel'
import frontendApplicationProcessorsConfigModel from 'utilities/create/models/frontendApplicationProcessorsConfigModel'
import frontendAuditLogEventsModel from 'utilities/create/models/frontendAuditLogEventsModel'
import frontendFraudScoreModel from 'utilities/create/models/frontendFraudScoreModel'
import frontendFraudScoresModel from 'utilities/create/models/frontendFraudScoresModel'
import frontendUnderwritingRunsModel from 'utilities/create/models/frontendUnderwritingRunsModel'
import frontendPayoutLinksModel from 'utilities/create/models/frontendPayoutLinksModel'
import frontendTransferAttemptsModel from 'utilities/create/models/frontendTransferAttemptsModel'
import frontendStatementsModel from 'utilities/create/models/frontendStatementsModel'
import frontendFeeProfileRulesModel from 'utilities/create/models/frontendFeeProfileRulesModel'
import frontendFeeProfileSettingsModel from 'utilities/create/models/frontendFeeProfileSettingsModel'
import frontendFeeProfileSettingModel from 'utilities/create/models/frontendFeeProfileSettingModel'
import frontendBalanceAdjustmentsModel from 'utilities/create/models/frontendBalanceAdjustmentsModel'
import frontendSubscriptionsModel from 'utilities/create/models/frontendSubscriptionsModel'
import frontendBalanceAdjustmentModel from 'utilities/create/models/frontendBalanceAdjustmentModel'
import frontendReceiptsModel from 'utilities/create/models/frontendReceiptsModel'
import frontendReceiptDeliveryAttemptModel from 'utilities/create/models/frontendReceiptDeliveryAttemptModel'
import frontendReceiptDeliveryAttemptsModel from 'utilities/create/models/frontendReceiptDeliveryAttemptsModel'
import frontendSubscriptionPlansModel from 'utilities/create/models/frontendSubscriptionPlansModel'
import frontendMonitoringAlertsModel from 'utilities/create/models/frontendMonitoringAlertsModel'
import frontendInstantPayoutsModel from 'utilities/create/models/frontendInstantPayoutsModel'

import {
  frontendWelcomeEmailTemplatesModel,
  frontendGenericEmailTemplatesModel,
} from 'utilities/create/models/frontendEmailTemplatesModel'

import backendIdentityModel from 'utilities/create/models/backendIdentityModel'
import backendTransferModel from 'utilities/create/models/backendTransferModel'
import backendBalanceTransferModel from 'utilities/create/models/backendBalanceTransferModel'
import backendApplicationModel from 'utilities/create/models/backendApplicationModel'
import backendApplicationOModel from 'utilities/create/models/backendApplicationOModel'
import backendAuthorizationModel from 'utilities/create/models/backendAuthorizationModel'
import backendFeeProfileModel from 'utilities/create/models/backendFeeProfileModel'
import backendMerchantModel from 'utilities/create/models/backendMerchantModel'
import backendPaymentInstrumentModel from 'utilities/create/models/backendPaymentInstrumentModel'
import backendPlatformProcessorConfigModel from 'utilities/create/models/backendPlatformProcessorConfigModel'
import backendPayoutPlanModel from 'utilities/create/models/backendPayoutPlanModel'
import backendSubscriptionScheduleModel from 'utilities/create/models/backendSubscriptionScheduleModel'
import backendSubscriptionAmountModel from 'utilities/create/models/backendSubscriptionAmountModel'
import backendSubscriptionEnrollmentModel from 'utilities/create/models/backendSubscriptionEnrollmentModel'
import backendFeeModel from 'utilities/create/models/backendFeeModel'
import backendOnboardingFormDataModel from 'utilities/create/models/backendOnboardingFormDataModel'
import backendComplianceFormTemplateModel from 'utilities/create/models/backendComplianceFormTemplateModel'
import backendPayoutSettingsModel from 'utilities/create/models/backendPayoutSettingsModel'
import backendAccessFormModel from 'utilities/create/models/backendAccessFormModel'
import backendUnderwritingReviewModel from 'utilities/create/models/backendUnderwritingReviewModel'
import frontendCustomerOnboardingFormsModel from 'utilities/create/models/frontendCustomerOnboardingFormsModel'
import backendEntityConfigurationModel from 'utilities/create/models/backendEntityConfigurationModel'
import backendBalanceEntryModel from 'utilities/create/models/backendBalanceEntryModel'
import backendPaymentLinkModel from 'utilities/create/models/backendPaymentLinkModel'
import backendPurchaseModel from 'utilities/create/models/backendPurchaseModel'
import backendNoteModel from 'utilities/create/models/backendNoteModel'
import backendDeviceModel from 'utilities/create/models/backendDeviceModel'
import backendUnderwritingWorkflowModel from 'utilities/create/models/backendUnderwritingWorkflowModel'
import backendApplicationProcessorConfigModel from 'utilities/create/models/backendApplicationProcessorConfigModel'
import backendPayoutLinkModel from 'utilities/create/models/backendPayoutLinkModel'
import backendTransferAttemptModel from 'utilities/create/models/backendTransferAttemptModel'
import backendDisputeModel from 'utilities/create/models/backendDisputeModel'
import backendFeeProfileRulesModel from 'utilities/create/models/backendFeeProfileRulesModel'
import backendBalanceAdjustmentModel from 'utilities/create/models/backendBalanceAdjustmentModel'
import backendReceiptModel from 'utilities/create/models/backendReceiptModel'
import backendReceiptDeliveryAttemptsModel from 'utilities/create/models/backendReceiptDeliveryAttemptsModel'
import backendSubscriptionModel from 'utilities/create/models/backendSubscriptionModel'
import backendSubscriptionPlanModel from 'utilities/create/models/backendSubscriptionPlanModel'
import backendMonitoringAlertModel from 'utilities/create/models/backendMonitoringAlertModel'
import backendInstantPayoutModel from 'utilities/create/models/backendInstantPayoutModel'

import getTransferO from 'state-layer/orchestration/get/getTransferO'
import getAuthorizationO from 'state-layer/orchestration/get/getAuthorizationO'
import getFeesO from 'state-layer/orchestration/get/getFeesO'
import getSettlementO from 'state-layer/orchestration/get/getSettlementO'
import getDisputeO from 'state-layer/orchestration/get/getDisputeO'
import getApplicationO from 'state-layer/orchestration/get/getApplicationO'
import getIdentityO from 'state-layer/orchestration/get/getIdentityO'
import getMerchantO from 'state-layer/orchestration/get/getMerchantO'
import getSettingsO from 'state-layer/orchestration/get/getSettingsO'
import getReviewQueueItemO from 'state-layer/orchestration/get/getReviewQueueItemO'
import getUserPermissionsO from 'state-layer/orchestration/get/getUserPermissionsO'
import getFeeO from 'state-layer/orchestration/get/getFeeO'
import getCredentialPermissionsO from 'state-layer/orchestration/get/getCredentialPermissionsO'
import getPayoutPlanO from 'state-layer/orchestration/get/getPayoutPlanO'
import getCurrentUserO from 'state-layer/orchestration/get/getCurrentUserO'
import getSubscriptionSchedulesAmountsO from 'state-layer/orchestration/get/getSubscriptionSchedulesAmountsO'
import getSubscriptionScheduleAmountsO from 'state-layer/orchestration/get/getSubscriptionScheduleAmountsO'
import getFileO from 'state-layer/orchestration/get/getFileO'
import getAccessFormFileO from 'state-layer/orchestration/get/getAccessFormFileO'
import getComplianceSettingsO from 'state-layer/orchestration/get/getComplianceSettingsO'
import getComplianceFormTemplateO from 'state-layer/orchestration/get/getComplianceFormTemplateO'
import getWebhookO from 'state-layer/orchestration/get/getWebhookO'
import getWebhookLogO from 'state-layer/orchestration/get/getWebhookLogO'
import getMembersO from 'state-layer/orchestration/get/getMembersO'
import getUnderwritingReviewO from 'state-layer/orchestration/get/getUnderwritingReviewO'
import getAccessFormAdditionalDocumentsO from 'state-layer/orchestration/get/getAccessFormAdditionalDocumentsO'
import getFeeProfileO from 'state-layer/orchestration/get/getFeeProfileO'
import getSplitTransferO from 'state-layer/orchestration/get/getSplitTransferO'
import getUnderwritingEventO from 'state-layer/orchestration/get/getUndewritingEventO'
import getPurchaseO from 'state-layer/orchestration/get/getPurchaseO'
import getDeviceO from 'state-layer/orchestration/get/getDeviceO'
import getGuestOnboardingFormFilesO from 'state-layer/orchestration/get/getGuestOnboardingFormFilesO'

import postApplicationO from 'state-layer/orchestration/post/postApplicationO'
import postApplicationFeeProfileO from 'state-layer/orchestration/post/postApplicationFeeProfileO'
import postDashboardUserO from 'state-layer/orchestration/post/postDashboardUserO'
import postDashboardUserV2O from 'state-layer/orchestration/post/postDashboardUserV2O'
import postApplicationProcessorsO from 'state-layer/orchestration/post/postApplicationProcessorsO'
import postMerchantFeeProfileO from 'state-layer/orchestration/post/postMerchantFeeProfileO'
import postUserO from 'state-layer/orchestration/post/postUserO'
import postUserV2O from 'state-layer/orchestration/post/postUserV2O'
import postMerchantIdentityO from 'state-layer/orchestration/post/postMerchantIdentityO'
import postFileO from 'state-layer/orchestration/post/postFileO'
import postDashboardCustomizationO from 'state-layer/orchestration/post/postDashboardCustomizationO'
import postPlaidTokenO from 'state-layer/orchestration/post/postPlaidTokenO'
import postRecipientIdentityO from 'state-layer/orchestration/post/postRecipientIdentityO'

import patchPaymentMethodConfigurationsO from 'state-layer/orchestration/patch/patchPaymentMethodConfigurationsO'
import patchSettlementO from 'state-layer/orchestration/patch/patchSettlementO'
import patchReviewQueueItemsO from 'state-layer/orchestration/patch/patchReviewQueueItemsO'
import patchWelcomeEmailTemplateO from 'state-layer/orchestration/patch/patchWelcomeEmailTemplateO'
import patchWelcomeEmailTemplateV2O from 'state-layer/orchestration/patch/patchWelcomeEmailTemplateV2O'
import patchTransfersUpdateO from 'state-layer/orchestration/patch/patchTransfersUpdateO'
import patchVerificationsUpdateO from 'state-layer/orchestration/patch/patchVerificationsUpdateO'
import patchMerchantIdentityO from 'state-layer/orchestration/patch/patchMerchantIdentityO'
import patchDashboardCustomizationO from 'state-layer/orchestration/patch/patchDashboardCustomizationO'
import patchAccessFormO from 'state-layer/orchestration/patch/patchAccessFormO'
import patchGuestAccessFormO from 'state-layer/orchestration/patch/patchGuestAccessFormO'
import patchAccessFormAdditionalDocumentsO from 'state-layer/orchestration/patch/patchAccessFormAdditionalDocumentsO'
import patchOnboardingFormBankDocumentO from 'state-layer/orchestration/patch/patchOnboardingFormBankDocumentO'
import patchComplianceSettingsO from 'state-layer/orchestration/patch/patchComplianceSettingsO'
import patchOnboardingFormDocumentsO from 'state-layer/orchestration/patch/patchOnboardingFormDocumentsO'
import patchOnboardingFormDocumentO from 'state-layer/orchestration/patch/patchOnboardingFormDocumentO'
import patchMonitoringAlertsO from 'state-layer/orchestration/patch/patchMonitoringAlertsO'

import deleteSubscriptionEnrollmentsO from 'state-layer/orchestration/delete/deleteSubscriptionEnrollmentsO'

import {
  GET_APP_CONFIGURATION,
  GET_CONFIGURATION,
  GET_CONFIGURATIONS,
  GET_CREDENTIALS,
  GET_RECENTLY_ACTIVE_CREDENTIALS,
  GET_CREDENTIAL,
  GET_TRANSFERS,
  GET_BALANCE_TRANSFER,
  GET_BALANCE_TRANSFERS,
  GET_AUTHORIZATION,
  PATCH_AUTHORIZATION,
  GET_AUTHORIZATIONS,
  GET_SETTLEMENT,
  GET_SETTLEMENTS,
  GET_SETTLEMENT_TRANSFERS,
  GET_SETTLEMENT_FUNDING_TRANSFERS,
  GET_SETTLEMENT_GROUP,
  GET_SETTLEMENT_GROUP_SETTLEMENTS,
  GET_DISPUTE,
  GET_DISPUTES,
  GET_DISPUTE_EVIDENCES,
  POST_DISPUTE_EVIDENCES,
  POST_SUBMIT_DISPUTE_EVIDENCE,
  GET_DISPUTE_ADJUSTMENT_TRANSFERS,
  GET_REPORTS,
  GET_CATEGORIZED_REPORTS,
  GET_IDENTITIES,
  GET_IDENTITY,
  POST_IDENTITY,
  PATCH_IDENTITY,
  POST_IDENTITY_SETTLEMENTS,
  POST_IDENTITY_PAYMENT_INSTRUMENTS,
  POST_IDENTITY_MERCHANTS,
  POST_IDENTITY_VERIFICATIONS,
  GET_MERCHANT,
  PATCH_MERCHANT,
  GET_MERCHANTS,
  GET_APPLICATION,
  POST_APPLICATION,
  PATCH_APPLICATION,
  GET_APPLICATIONS,
  GET_PAYMENT_INSTRUMENT,
  GET_PAYMENT_INSTRUMENTS,
  GET_PAYMENT_INSTRUMENT_TRANSFERS,
  GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
  GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_REVIEW_QUEUE,
  PATCH_REVIEW_QUEUE_ITEMS,
  PATCH_REVIEW_QUEUE_ITEM,
  GET_TRANSFER,
  POST_TRANSFER,
  PATCH_TRANSFER,
  PATCH_PAYMENT_INSTRUMENT,
  GET_USERS,
  GET_ROLE,
  GET_ROLES,
  GET_ADMIN_USER,
  GET_ADMIN_USER_MEMBERSHIPS,
  GET_DASHBOARD_USERS,
  GET_ASSOCIATED_IDENTITIES,
  GET_IDENTITY_AUTHORIZATIONS,
  GET_IDENTITY_TRANSFERS,
  GET_IDENTITY_DISPUTES,
  GET_IDENTITY_SETTLEMENTS,
  GET_IDENTITY_PAYMENT_INSTRUMENTS,
  GET_IDENTITY_MERCHANTS,
  GET_IDENTITY_VERIFICATIONS,
  GET_IDENTITY_VERIFICATION,
  GET_MERCHANT_VERIFICATIONS,
  POST_MERCHANT_VERIFICATIONS,
  POST_MERCHANT_FEE_PROFILE,
  GET_APPLICATION_ASSOCIATED_IDENTITIES,
  POST_APPLICATION_ASSOCIATED_IDENTITIES,
  GET_APPLICATION_DISPUTES,
  GET_APPLICATION_IDENTITIES,
  POST_APPLICATION_IDENTITIES,
  GET_APPLICATION_MERCHANTS,
  GET_APPLICATION_PROCESSORS,
  GET_APPLICATION_PROCESSOR,
  POST_APPLICATION_PROCESSORS,
  GET_APPLICATION_SETTLEMENTS,
  GET_APPLICATION_TRANSFERS,
  GET_WEBHOOKS,
  GET_WEBHOOK_LOGS,
  GET_WEBHOOK_LOG,
  GET_WEBHOOKS_WITH_STOPPED_EVENTS,
  POST_WEBHOOKS,
  PATCH_WEBHOOK,
  POST_FEE_PROFILE,
  GET_DASHBOARD_USER,
  POST_DASHBOARD_USER,
  POST_ADMIN_USER,
  POST_USERS_EMAIL,
  POST_TRANSFER_REVERSAL,
  DELETE_SETTLEMENT_ENTRIES,
  GET_SETTINGS,
  GET_APPLICATION_USERS,
  POST_APPLICATION_USERS,
  GET_REVIEW_QUEUE_ITEM,
  PATCH_RISK_PROFILE,
  PATCH_TRANSFERS_UPDATE,
  PATCH_VERIFICATIONS_UPDATE,
  GET_DISPUTES_WIDGET,
  GET_TRANSFERS_WIDGET,
  GET_AUTHORIZATIONS_WIDGET,
  GET_REVIEW_QUEUE_WIDGET,
  GET_IDENTITIES_WIDGET,
  GET_MERCHANTS_WIDGET,
  GET_PAYMENT_INSTRUMENTS_WIDGET,
  GET_WELCOME_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  PATCH_WELCOME_EMAIL_TEMPLATE,
  PATCH_EMAIL_TEMPLATE,
  POST_CREDENTIALS,
  POST_ADMIN_USER_MEMBERSHIPS,
  POST_DASHBOARD_FILE,
  PATCH_SETTLEMENT_V1,
  GET_PLATFORMS,
  GET_PLATFORM,
  GET_PLATFORM_PROCESSOR_CONFIG,
  GET_PLATFORM_PROCESSOR_CONFIGS,
  PATCH_PLATFORM_PROCESSOR_CONFIG,
  GET_TRANSFERS_SEARCH,
  GET_AUTHORIZATIONS_SEARCH,
  GET_PLATFORM_USERS,
  GET_PLATFORM_USER,
  GET_USER_PERMISSIONS,
  PATCH_USER_PERMISSIONS,
  POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
  GET_MERCHANT_TRANSFERS,
  GET_MERCHANT_AUTHORIZATIONS,
  GET_MERCHANT_SETTLEMENTS,
  GET_FEES,
  GET_FEE,
  GET_SETTLEMENT_ENTRIES,
  GET_SETTLEMENT_ENTRIES_FEE,
  GET_CREDENTIAL_PERMISSIONS,
  PATCH_SETTLEMENT,
  GET_PAYOUT_PLAN,
  GET_PAYOUT_PLANS,
  POST_PAYOUT_PLAN,
  PATCH_PAYOUT_PLAN,
  CLOSE_SETTLEMENTS,
  POST_USER,
  PATCH_USER,
  GET_NOTIFICATIONS,
  GET_LATEST_NOTIFICATIONS,
  POST_BALANCE_TRANSFER,
  GET_SUBSCRIPTION_SCHEDULES,
  GET_SUBSCRIPTION_SCHEDULE,
  GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
  GET_SUBSCRIPTION_ENROLLMENT,
  GET_SUBSCRIPTION_ENROLLMENTS,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
  PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT,
  DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT,
  POST_SUBSCRIPTION_SCHEDULE,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
  DELETE_SUBSCRIPTION_ENROLLMENT,
  DELETE_SUBSCRIPTION_ENROLLMENTS,
  POST_FEE,
  GET_MERCHANT_FEES,
  GET_COMPLIANCE_FORMS,
  GET_COMPLIANCE_FORM,
  GET_COMPLIANCE_FORM_TEMPLATES,
  GET_COMPLIANCE_FORM_TEMPLATE,
  PATCH_COMPLIANCE_FORM_TEMPLATE,
  POST_COMPLIANCE_FORM_TEMPLATE,
  GET_MERCHANT_COMPLIANCE_FORMS,
  GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
  GET_MERCHANT_FILES,
  PATCH_FEE,
  GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
  GET_ADMIN_USER_CREDENTIALS,
  POST_MERCHANT_IDENTITY,
  PATCH_MERCHANT_IDENTITY,
  GET_CURRENT_USER,
  GET_SUBSCRIPTION_SCHEDULE_FEES,
  GET_SUBSCRIPTION_SCHEDULES_AMOUNTS,
  GET_DASHBOARD_CUSTOMIZATION,
  GET_DASHBOARD_CUSTOMIZATIONS,
  GET_GUEST_DASHBOARD_CUSTOMIZATIONS,
  POST_DASHBOARD_CUSTOMIZATIONS,
  PATCH_DASHBOARD_CUSTOMIZATIONS,
  PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING,
  GET_RESPONSE_FILES,
  GET_FILE,
  GET_WEBHOOK,
  GET_PAYMENT_METHOD_CONFIGURATION,
  POST_PAYMENT_METHOD_CONFIGURATIONS,
  PATCH_PAYMENT_METHOD_CONFIGURATIONS,
  GET_CERTIFICATE_SIGNING_REQUESTS,
  POST_CERTIFICATE_SIGNING_REQUESTS,
  POST_CERTIFICATE_SIGNING_REQUEST,
  POST_MERCHANT_FILE,
  POST_IDENTITY_FILE,
  PATCH_COMPLIANCE_FORM,
  POST_DASHBOARD_FEEDBACK_EMAIL,
  POST_CONTACT_SUPPORT_EMAIL,
  GET_CHARTS,
  GET_HOME_CHARTS,
  GET_TRANSACTION_CHARTS,
  GET_GUEST_ONBOARDING_FORM_DATA,
  GET_ONBOARDING_FORMS,
  PATCH_ONBOARDING_FORM_DATA,
  GET_HOSTED_FORM_TOKEN_VERIFICATION,
  PATCH_APPLICATION_UNDERWRITING_SETTINGS,
  PATCH_COMPLIANCE_SETTINGS,
  GET_COMPLIANCE_SETTINGS,
  GET_EXPORTS,
  POST_EXPORTS,
  POST_PLATFORM_USER,
  GET_MERCHANT_PAYOUT_SETTINGS,
  GET_PAYOUT_SETTINGS,
  PATCH_PAYOUT_SETTINGS,
  GET_NOTIFICATION_SETTINGS,
  POST_NOTIFICATION_SETTINGS,
  POST_NOTIFICATION_ACKNOWLEDGMENT,
  GET_API_LOGS,
  GET_API_LOG,
  GET_DEVICE,
  GET_ONBOARDING_FORM_DATA,
  POST_ONBOARDING_FORM,
  POST_ONBOARDING_FORM_DATA,
  POST_ONBOARDING_FORM_LINK,
  POST_DASHBOARD_PRESIGNED_URL,
  POST_DASHBOARD_V2_EMAILS,
  POST_SELF_SERVICE_SIGNUP_FORM,
  POST_SELF_SERVICE_EMAIL_VERIFICATION,
  POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA,
  POST_SELF_SERVICE_RESEND_EMAIL,
  POST_CONTACT_SALES_EMAIL,
  PATCH_CREDENTIAL,
  GET_MEMBER,
  GET_MEMBERS,
  POST_MEMBER,
  POST_ROLE,
  PATCH_ROLE,
  POST_CHANGE_PASSWORD,
  PATCH_MEMBERSHIP,
  PATCH_DASHBOARD_USER,
  GET_ACCESS_FORM,
  GET_ACCESS_FORMS,
  POST_ACCESS_FORM,
  PATCH_ACCESS_FORM,
  PATCH_GUEST_ACCESS_FORM,
  GET_UNDERWRITING_REVIEW,
  GET_UNDERWRITING_REVIEWS,
  GET_MERCHANT_UNDERWRITING_REVIEW,
  GET_MERCHANT_UNDERWRITING_REVIEWS,
  GET_RECIPIENT_UNDERWRITING_REVIEWS,
  PATCH_UNDERWRITING_REVIEW,
  POST_SUBMIT_ACCESS_FORM,
  POST_SUBMIT_GUEST_ACCESS_FORM,
  GET_ACCESS_FORM_CONTRACT,
  DELETE_ACCESS_FORM,
  DELETE_API_KEY,
  GET_DASHBOARD_CONTRACTS,
  PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
  GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
  GET_FEE_PROFILE,
  POST_ACCEPT_DISPUTE,
  GET_UNDERWRITING_PROFILE,
  GET_UNDERWRITING_PROFILES,
  PATCH_UNDERWRITING_PROFILE,
  POST_UNDERWRITING_PROFILE,
  GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE,
  PATCH_DISPUTE,
  GET_IDENTITY_FILES,
  GET_EXCEPTION_CHARTS,
  GET_UNDERWRITING_REPORT,
  GET_MERCHANT_CHARTS,
  GET_CUSTOMER_ONBOARDING_FORMS,
  GET_ROLE_MEMBERS,
  POST_CUSTOMER_ONBOARDING_FORM,
  PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM,
  POST_CUSTOMER_ONBOARDING_FORM_LINKS,
  GET_CUSTOMER_ONBOARDING_FORM,
  GET_GUEST_ACCESS_FORM,
  GET_ACCESS_FORM_FILE,
  POST_DASHBOARD_USER_MFA_TOKEN,
  POST_DASHBOARD_USER_MFA_ASSOCIATE,
  POST_DASHBOARD_USER_MFA_VERIFY,
  GET_DASHBOARD_USER_MFA_AUTHENTICATORS,
  GET_ENTITY_CONFIGURATIONS,
  POST_ENTITY_CONFIGURATION,
  PATCH_ENTITY_CONFIGURATION,
  POST_DASHBOARD_USER_MFA_RESET,
  GET_SPLIT_TRANSFERS,
  GET_SPLIT_TRANSFER,
  GET_TRANSFER_REVERSALS,
  GET_TRANSFER_DISPUTES,
  POST_PAYMENT_INSTRUMENT,
  GET_UNDERWRITING_CHARTS,
  GET_UNDERWRITING_SUBJECT_EVENTS,
  GET_UNDERWRITING_SUBJECT_EVENT,
  PATCH_ONBOARDING_FORM_BANK_DOCUMENT,
  GET_APPLICATION_BALANCES,
  GET_CURRENT_USAGES,
  GET_RECIPIENT_IDENTITIES,
  GET_DISBURSEMENT_RULES,
  GET_BALANCE_ENTRIES,
  POST_DISBURSEMENT_RULES,
  PATCH_DISBURSEMENT_RULES,
  POST_BALANCE_ENTRY,
  GET_BALANCE_ENTRY,
  GET_PAYMENT_LINK,
  GET_PAYMENT_LINKS,
  POST_PAYMENT_LINK,
  PATCH_PAYMENT_LINK,
  GET_PURCHASE,
  GET_PURCHASES,
  GET_GUEST_CHECKOUT_TOKEN_VERIFICATION,
  GET_GUEST_CHECKOUT_PAYMENT_LINK,
  POST_PURCHASE,
  PATCH_MERCHANT_RISK_PROFILE,
  POST_UNDERWRITING_REVIEW_RUNS,
  GET_GUEST_CHECKOUT_FORM,
  POST_AUTHORIZATION,
  GET_NOTES,
  GET_NOTE,
  POST_NOTE,
  PATCH_NOTE,
  GET_DRAFT_NOTES,
  GET_DEVICES,
  POST_DEVICE,
  PATCH_DEVICE,
  PATCH_UNDERWRITING_WORKFLOW,
  GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE,
  POST_COMPLIANCE_FORM,
  POST_PLAID_TOKEN,
  POST_PLAID_PUBLIC_TOKEN,
  GET_APPLICATION_PROCESSOR_CONFIGURATIONS_F_REQUEST,
  GET_APPLICATION_PROCESSOR_CONFIGURATIONS,
  PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS,
  GET_AUDIT_LOG_EVENTS,
  GET_AUDIT_LOG_EVENT,
  GET_FRAUD_SCORE,
  GET_FRAUD_SCORES,
  POST_RECIPIENT_IDENTITY,
  GET_REVIEW_RUNS,
  POST_SUBMIT_ACCESS_FORM_SALES_REVIEW,
  POST_REQUEST_CHANGES,
  POST_SUBMIT_TO_UNDERWRITING,
  POST_PAYOUT_LINK,
  GET_PAYOUT_LINK,
  GET_PAYOUT_LINKS,
  GET_TRANSFER_ATTEMPTS,
  PATCH_PAYOUT_LINK,
  GET_TRANSFER_ATTEMPT,
  PATCH_TRANSFER_ATTEMPT,
  GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS,
  GET_GUEST_CHECKOUT_PAYOUT_LINK,
  POST_TRANSFER_ATTEMPT,
  GET_MERCHANT_STATEMENTS,
  GET_SPLIT_TRANSFER_SETTLEMENT,
  POST_DISPUTE,
  PATCH_UPDATE_DISPUTE,
  GET_FILES,
  PATCH_ONBOARDING_FORM_DOCUMENTS,
  GET_GUEST_ONBOARDING_FORM_FILES,
  PATCH_ONBOARDING_FORM_DOCUMENT,
  GET_FEE_PROFILE_RULES,
  GET_FEE_PROFILE_SETTINGS,
  POST_FEE_PROFILE_SETTINGS,
  PATCH_FEE_PROFILE_SETTINGS,
  POST_FEE_PROFILE_RULES,
  PATCH_FEE_PROFILE_RULES,
  GET_BALANCE_ADJUSTMENTS,
  POST_BALANCE_ADJUSTMENT,
  GET_BALANCE_ADJUSTMENT,
  POST_RECEIPT,
  GET_RECEIPT,
  GET_RECEIPTS,
  GET_RECEIPT_DELIVERY_ATTEMPTS,
  POST_RECEIPT_DELIVERY_ATTEMPTS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANS,
  POST_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN,
  DELETE_SUBSCRIPTION,
  POST_UNDERWRITING_RUN_SILENCE,
  PATCH_SUBSCRIPTION_PLAN,
  PATCH_SUBSCRIPTION,
  GET_MONITORING_ALERTS,
  PATCH_MONITORING_ALERT,
  GET_MONITORING_ALERT,
  PATCH_MONITORING_ALERTS,
  GET_INSTANT_PAYOUTS,
  POST_INSTANT_PAYOUT,
  GET_INSTANT_PAYOUT,
} from 'constants/flowConstants'

export default [
  {
    name: PATCH_PAYOUT_SETTINGS,
    api: patchPayoutSettingsAPI,
    backendModel: backendPayoutSettingsModel,
  },
  {
    name: GET_PAYOUT_SETTINGS,
    api: getPayoutSettingsAPI,
    frontendModel: updateOne(frontendPayoutSettingsModel),
  },
  {
    name: GET_MERCHANT_PAYOUT_SETTINGS,
    api: getMerchantPayoutSettingsAPI,
    frontendModel: updateOne(frontendPayoutSettingsModel),
  },
  {
    name: GET_COMPLIANCE_SETTINGS,
    api: getComplianceSettingsO,
  },
  {
    name: PATCH_COMPLIANCE_SETTINGS,
    orchestration: patchComplianceSettingsO,
  },
  {
    name: PATCH_COMPLIANCE_FORM,
    api: patchComplianceFormAPI,
  },
  {
    name: PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING,
    api: patchIdentityPayoutPlanInstrumentMappingAPI,
  },
  {
    name: GET_APP_CONFIGURATION,
    api: getAppConfigurationAPI,
    frontendModel: updateOne(frontendConfigurationsModel),
    dataKey: 'data',
    dataKeyV2: '_embedded.dashboard_configurations',
  },
  {
    name: GET_CONFIGURATION,
    api: getConfigurationAPI,
    frontendModel: updateOne(frontendConfigurationsModel),
    dataKey: 'data',
  },
  {
    name: GET_CONFIGURATIONS,
    api: getConfigurationsAPI,
    frontendModel: frontendConfigurationsModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.dashboard_configurations',
  },
  {
    name: GET_CREDENTIALS,
    api: getCredentialsAPI,
    frontendModel: frontendCredentialsModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.api_credentials',
  },
  {
    name: GET_RECENTLY_ACTIVE_CREDENTIALS,
    api: getCredentialsAPI,
    frontendModel: frontendCredentialsModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.api_credentials',
  },
  {
    name: GET_CREDENTIAL,
    api: getCredentialsAPI,
    frontendModel: frontendCredentialsModel,
  },
  {
    name: PATCH_CREDENTIAL,
    api: patchCredentialAPI,
    frontendModel: frontendCredentialsModel,
  },
  {
    name: [GET_TRANSFERS, GET_TRANSFERS_WIDGET],
    api: getDashboardTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_BALANCE_TRANSFERS,
    api: getBalanceTransfersAPI,
    frontendModel: frontendBalanceTransfersModel,
    dataKey: '_embedded.balance_transfers',
  },
  {
    name: GET_BALANCE_TRANSFER,
    api: getBalanceTransferAPI,
    frontendModel: updateOne(frontendBalanceTransfersModel),
  },
  {
    name: GET_TRANSFERS_SEARCH,
    api: getTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: [GET_AUTHORIZATIONS, GET_AUTHORIZATIONS_WIDGET],
    api: getAuthorizationsAPI,
    frontendModel: frontendAuthorizationsModel,
    dataKey: '_embedded.authorizations',
  },
  {
    name: GET_AUTHORIZATIONS_SEARCH,
    api: getAuthorizationsAPI,
    frontendModel: frontendAuthorizationsModel,
    dataKey: '_embedded.authorizations',
  },
  {
    name: GET_SETTLEMENTS,
    api: getSettlementsAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: '_embedded.settlements',
  },
  {
    name: GET_SETTLEMENT_TRANSFERS,
    api: getSettlementTransfersAPI,
    frontendModel: frontendTransfersModel,
    // TODO: this will not work if switching between customer platforms without refreshing the app, not possible now, but something to consider moving forward
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_SETTLEMENT_FUNDING_TRANSFERS,
    api: getSettlementFundingTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_SETTLEMENT_GROUP,
    api: getSettlementGroupAPI,
    frontendModel: updateOne(frontendSettlementGroupsModel),
  },
  {
    name: GET_SETTLEMENT_GROUP_SETTLEMENTS,
    api: getSettlementGroupAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: 'settlements',
  },
  {
    name: GET_FEES,
    api: getFeesO,
    frontendModel: frontendFeesModel,
    dataKey: 'fees',
  },
  {
    name: GET_MERCHANT_FEES,
    api: getFeesO,
    frontendModel: frontendFeesModel,
    dataKey: 'fees',
  },
  {
    name: GET_COMPLIANCE_FORMS,
    api: getComplianceFormsAPI,
    frontendModel: frontendComplianceFormsModel,
    dataKey: '_embedded.compliance_forms',
  },
  {
    name: GET_COMPLIANCE_FORM,
    api: getComplianceFormAPI,
    frontendModel: updateOne(frontendComplianceFormsModel),
  },
  {
    name: GET_COMPLIANCE_FORM_TEMPLATES,
    api: getComplianceFormTemplatesAPI,
    frontendModel: frontendComplianceFormTemplatesModel,
    dataKey: '_embedded.compliance_form_templates',
  },
  {
    name: GET_COMPLIANCE_FORM_TEMPLATE,
    api: getComplianceFormTemplateO,
    frontendModel: updateOne(frontendComplianceFormTemplatesModel),
  },
  {
    name: PATCH_COMPLIANCE_FORM_TEMPLATE,
    api: patchComplianceFormTemplateAPI,
    frontendModel: updateOne(frontendComplianceFormTemplatesModel),
    backendModel: backendComplianceFormTemplateModel,
  },
  {
    name: POST_COMPLIANCE_FORM_TEMPLATE,
    api: postComplianceFormTemplateAPI,
    backendModel: backendComplianceFormTemplateModel,
  },
  {
    name: GET_MERCHANT_COMPLIANCE_FORMS,
    api: getComplianceFormsAPI,
    frontendModel: frontendComplianceFormsModel,
    dataKey: '_embedded.compliance_forms',
  },
  {
    name: GET_COMPLIANCE_FORM_TEMPLATE_FORMS,
    api: getComplianceFormTemplateFormsAPI,
    frontendModel: frontendComplianceFormsModel,
    dataKey: '_embedded.compliance_forms',
  },
  {
    name: GET_FILES,
    api: getFilesAPI,
    frontendModel: frontendFilesModel,
    dataKey: '_embedded.files',
  },
  {
    name: GET_MERCHANT_FILES,
    api: getFilesAPI,
    frontendModel: frontendFilesModel,
    dataKey: '_embedded.files',
  },
  {
    name: GET_IDENTITY_FILES,
    api: getFilesAPI,
    frontendModel: frontendFilesModel,
    dataKey: '_embedded.files',
  },
  {
    name: GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS,
    api: getSubscriptionEnrollmentsAPI,
    frontendModel: frontendSubscriptionEnrollmentsModel,
    dataKey: '_embedded.subscription_enrollments',
  },
  {
    name: GET_FEE,
    api: getFeeO,
    frontendModel: frontendFeesModel,
  },
  {
    name: POST_FEE,
    api: postFeeAPI,
    backendModel: backendFeeModel,
  },
  {
    name: PATCH_FEE,
    api: patchFeeAPI,
    backendModel: backendFeeModel,
  },
  {
    name: GET_SETTLEMENT_ENTRIES_FEE,
    api: getFeeAPI,
    frontendModel: frontendFeesModel,
  },
  {
    name: GET_SETTLEMENT_ENTRIES,
    api: getSettlementEntriesAPI,
    frontendModel: frontendSettlementEntriesModel,
    dataKey: '_embedded.settlement_entries',
  },
  {
    name: PATCH_SETTLEMENT,
    api: patchSettlementAPI,
    frontendModel: updateOne(frontendSettlementsModel),
  },
  {
    name: CLOSE_SETTLEMENTS,
    api: patchSettlementO,
    frontendModel: frontendSettlementsModel,
  },
  {
    name: [GET_DISPUTES, GET_DISPUTES_WIDGET],
    api: getDisputesAPI,
    frontendModel: frontendDisputesModel,
    dataKey: '_embedded.disputes',
  },
  {
    name: GET_REPORTS,
    api: getReportsAPI,
    frontendModel: frontendReportsModel,
    dataKey: '_embedded.reports',
  },
  {
    name: GET_CATEGORIZED_REPORTS,
    api: getCategorizedReportsAPI,
    frontendModel: frontendCategorizedReportsModel,
    dataKey: '_embedded.reports',
    normalizeQueries: ({ queries }) => queries,
  },
  {
    name: [
      GET_IDENTITIES,
      GET_IDENTITIES_WIDGET,
      GET_APPLICATION_IDENTITIES,
    ],
    api: getIdentitiesAPI,
    frontendModel: frontendIdentitiesModel,
    dataKey: '_embedded.identities',
  },
  {
    name: GET_IDENTITY,
    orchestration: getIdentityO,
    frontendModel: frontendIdentitiesModel,
  },
  {
    name: GET_MERCHANT,
    orchestration: getMerchantO,
    frontendModel: frontendMerchantsModel,
  },
  {
    name: [GET_MERCHANTS, GET_MERCHANTS_WIDGET],
    api: getMerchantsAPI,
    frontendModel: frontendMerchantsModel,
    dataKey: '_embedded.merchants',
  },
  {
    name: GET_APPLICATION,
    orchestration: getApplicationO,
    frontendModel: frontendApplicationsModel,
  },
  {
    name: GET_APPLICATIONS,
    api: getApplicationsAPI,
    frontendModel: frontendApplicationsModel,
    dataKey: '_embedded.applications',
  },
  {
    name: [GET_PAYMENT_INSTRUMENTS, GET_PAYMENT_INSTRUMENTS_WIDGET],
    api: getPaymentInstrumentsAPI,
    frontendModel: frontendPaymentInstrumentsModel,
    dataKey: '_embedded.payment_instruments',
  },
  {
    name: [GET_REVIEW_QUEUE, GET_REVIEW_QUEUE_WIDGET],
    api: getReviewQueueAPI,
    frontendModel: frontendReviewQueueItemsModel,
    dataKey: '_embedded.review_queue_items',
  },
  {
    name: GET_TRANSFER,
    orchestration: getTransferO,
    frontendModel: frontendTransfersModel,
    // we are handling the normalization all in the OL to avoid double normalization issues
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_AUTHORIZATION,
    orchestration: getAuthorizationO,
    frontendModel: frontendAuthorizationsModel,
  },
  {
    name: GET_SETTLEMENT,
    orchestration: getSettlementO,
    frontendModel: frontendSettlementsModel,
  },
  {
    name: GET_DISPUTE,
    orchestration: getDisputeO,
    frontendModel: frontendDisputesModel,
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_DISPUTE_EVIDENCES,
    api: getDisputeEvidencesAPI,
    frontendModel: frontendEvidencesModel,
    dataKey: '_embedded.evidences',
  },
  {
    name: GET_DISPUTE_ADJUSTMENT_TRANSFERS,
    api: getDisputeAdjustmentTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_USERS,
    api: getCredentialUsersAPI,
    frontendModel: frontendUsersModel,
    dataKey: 'data',
  },
  {
    name: GET_ROLE,
    api: getRoleAPI,
    frontendModel: updateOne(frontendRolesModel),
  },
  {
    name: GET_ROLES,
    api: getRolesAPI,
    frontendModel: frontendRolesModel,
    dataKey: '_embedded.dashboard_user_roles',
  },
  {
    name: GET_ROLE,
    api: getRoleAPI,
    frontendModel: updateOne(frontendRolesModel),
  },
  {
    name: POST_ROLE,
    api: postRoleAPI,
    frontendModel: frontendRoleModel,
  },
  {
    name: PATCH_ROLE,
    api: patchRoleAPI,
    frontendModel: updateOne(frontendRolesModel),
  },
  {
    name: GET_ADMIN_USER,
    api: getAdminUserAPI,
    frontendModel: updateOne(frontendUsersModel),
  },
  {
    name: GET_ADMIN_USER_MEMBERSHIPS,
    api: getAdminUserMembershipsAPI,
    frontendModel: frontendAdminUserMembershipsModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.memberships',
  },
  {
    name: GET_ADMIN_USER_CREDENTIALS,
    api: getAdminUserCredentialsAPI,
    frontendModel: frontendCredentialsModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.api_credentials',
  },
  {
    name: GET_DASHBOARD_USERS,
    api: getDashboardUsersAPI,
    frontendModel: frontendUsersModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.dashboard_users',
  },
  {
    name: GET_ASSOCIATED_IDENTITIES,
    api: getAssociatedIdentitiesAPI,
    frontendModel: frontendIdentitiesModel,
    dataKey: '_embedded.identities',
  },
  {
    name: [GET_IDENTITY_AUTHORIZATIONS, GET_MERCHANT_AUTHORIZATIONS],
    api: getAuthorizationsAPI,
    frontendModel: frontendAuthorizationsModel,
    dataKey: '_embedded.authorizations',
  },
  {
    name: [GET_IDENTITY_TRANSFERS, GET_MERCHANT_TRANSFERS],
    api: getTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_MERCHANT_SETTLEMENTS,
    api: getMerchantSettlementsAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: '_embedded.settlements',
  },
  {
    name: GET_IDENTITY_DISPUTES,
    api: getIdentityDisputesAPI,
    frontendModel: frontendDisputesModel,
    dataKey: '_embedded.disputes',
  },
  {
    name: GET_IDENTITY_SETTLEMENTS,
    api: getIdentitySettlementsAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: '_embedded.settlements',
  },
  {
    name: GET_IDENTITY_PAYMENT_INSTRUMENTS,
    api: getPaymentInstrumentsAPI,
    frontendModel: frontendPaymentInstrumentsModel,
    dataKey: '_embedded.payment_instruments',
  },
  {
    name: GET_IDENTITY_MERCHANTS,
    api: getIdentityMerchantsAPI,
    frontendModel: frontendMerchantsModel,
    dataKey: '_embedded.merchants',
  },
  {
    name: GET_IDENTITY_VERIFICATIONS,
    api: getIdentityVerificationsAPI,
    frontendModel: frontendVerificationsModel,
    dataKey: '_embedded.verifications',
  },
  {
    name: GET_IDENTITY_VERIFICATION,
    api: getIdentityVerificationAPI,
    frontendModel: frontendVerificationsModel,
  },
  {
    name: GET_MERCHANT_VERIFICATIONS,
    api: getMerchantVerificationsAPI,
    frontendModel: frontendVerificationsModel,
    dataKey: '_embedded.verifications',
  },
  {
    name: GET_APPLICATION_ASSOCIATED_IDENTITIES,
    api: getApplicationAssociatedIdentitiesAPI,
    frontendModel: frontendIdentitiesModel,
    dataKey: '_embedded.identities',
  },
  {
    name: GET_APPLICATION_DISPUTES,
    api: getApplicationDisputesAPI,
    frontendModel: frontendDisputesModel,
    dataKey: '_embedded.disputes',
  },
  // {
  //   name: GET_APPLICATION_IDENTITIES,
  //   api: getApplicationIdentitiesAPI,
  //   frontendModel: frontendIdentitiesModel,
  //   dataKey: '_embedded.identities',
  // },
  {
    name: GET_APPLICATION_MERCHANTS,
    api: getApplicationMerchantsAPI,
    frontendModel: frontendMerchantsModel,
    dataKey: '_embedded.merchants',
  },
  {
    name: GET_APPLICATION_SETTLEMENTS,
    api: getApplicationSettlementsAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: '_embedded.settlements',
  },
  {
    name: GET_APPLICATION_TRANSFERS,
    api: getTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_APPLICATION_PROCESSORS,
    api: getApplicationProcessorsAPI,
    frontendModel: frontendProcessorsModel,
    dataKey: '_embedded.processors',
  },
  {
    name: GET_APPLICATION_PROCESSOR,
    api: getApplicationProcessorAPI,
    frontendModel: updateOne(frontendProcessorsModel),
  },
  {
    name: GET_WEBHOOKS,
    api: getWebhooksAPI,
    frontendModel: frontendWebhooksModel,
    dataKey: '_embedded.webhooks',
  },
  {
    name: GET_WEBHOOKS_WITH_STOPPED_EVENTS,
    api: getWebhooksAPI,
    frontendModel: frontendWebhooksModel,
    dataKey: '_embedded.webhooks',
  },
  {
    name: POST_WEBHOOKS,
    api: postWebhooksAPI,
    frontendModel: updateOne(frontendWebhooksModel),
  },
  {
    name: GET_WEBHOOK,
    api: getWebhookO,
    frontendModel: frontendWebhooksModel,
  },
  {
    name: GET_WEBHOOK_LOGS,
    api: getWebhookLogsAPI,
    frontendModel: frontendWebhookLogsModel,
    dataKey: '_embedded.webhook_logs',
  },
  {
    name: GET_WEBHOOK_LOG,
    api: getWebhookLogO,
    frontendModel: frontendWebhookLogsModel,
  },
  {
    name: PATCH_WEBHOOK,
    api: patchWebhookAPI,
    frontendModel: updateOne(frontendWebhooksModel),
  },
  {
    name: POST_IDENTITY,
    api: postIdentityAPI,
    backendModel: backendIdentityModel,
    frontendModel: updateOne(frontendIdentitiesModel),
  },
  {
    name: POST_RECIPIENT_IDENTITY,
    api: postRecipientIdentityO,
  },
  {
    name: POST_IDENTITY_SETTLEMENTS,
    api: postIdentitySettlementsAPI,
    dataKey: 'data',
  },
  {
    name: POST_IDENTITY_PAYMENT_INSTRUMENTS,
    api: postIdentityPaymentInstrumentAPI,
    frontendModel: updateOne(frontendPaymentInstrumentsModel),
    backendModel: backendPaymentInstrumentModel,
  },
  {
    name: POST_IDENTITY_MERCHANTS,
    api: postIdentityMerchantAPI,
    frontendModel: updateOne(frontendMerchantsModel),
  },
  {
    name: POST_IDENTITY_VERIFICATIONS,
    api: postIdentityVerificationAPI,
    frontendModel: updateOne(frontendVerificationsModel),
  },
  {
    name: POST_ADMIN_USER_MEMBERSHIPS,
    api: postUserMembershipsAPI,
  },
  {
    name: PATCH_TRANSFER,
    api: patchTransferAPI,
    backendModel: backendTransferModel,
  },
  {
    name: PATCH_PAYMENT_INSTRUMENT,
    api: patchPaymentInstrumentAPI,
    backendModel: backendPaymentInstrumentModel,
    frontendModel: updateOne(frontendPaymentInstrumentsModel),
  },
  {
    name: PATCH_IDENTITY,
    api: patchIdentityAPI,
    frontendModel: updateOne(frontendIdentitiesModel),
    backendModel: backendIdentityModel,
  },
  {
    name: PATCH_APPLICATION,
    api: patchApplicationAPI,
    backendModel: backendApplicationModel,
    frontendModel: updateOne(frontendApplicationsModel),
  },
  {
    name: PATCH_AUTHORIZATION,
    api: patchAuthorizationAPI,
    frontendModel: updateOne(frontendAuthorizationsModel),
    backendModel: backendAuthorizationModel,
  },
  { // To Do: this flows handle all actions when creating an new application, rename it when needed
    name: POST_APPLICATION,
    orchestration: postApplicationO,
    backendModel: backendApplicationOModel,
    frontendModel: frontendApplicationsModel,
    dataKey: 'applications',
  },
  {
    name: PATCH_REVIEW_QUEUE_ITEMS,
    orchestration: patchReviewQueueItemsO,
    frontendModel: frontendReviewQueueItemsModel,
    dataKey: 'data',
  },
  {
    name: PATCH_REVIEW_QUEUE_ITEM,
    frontendModel: updateOne(frontendReviewQueueItemsModel),
    api: patchReviewQueueItemAPI,
  },
  {
    name: POST_APPLICATION_IDENTITIES,
    api: postApplicationIdentityAPI,
    backendModel: backendIdentityModel,
  },
  {
    name: POST_APPLICATION_ASSOCIATED_IDENTITIES,
    api: postApplicationAssociatedIdentityAPI,
    backendModel: backendIdentityModel,
  },
  {
    name: POST_FEE_PROFILE,
    orchestration: postApplicationFeeProfileO,
  },
  {
    name: GET_PAYMENT_INSTRUMENT,
    api: getPaymentInstrumentAPI,
    frontendModel: updateOne(frontendPaymentInstrumentsModel),
  },
  {
    name: GET_PAYMENT_INSTRUMENT_TRANSFERS,
    api: getTransfersAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS,
    api: getAuthorizationsAPI,
    frontendModel: frontendAuthorizationsModel,
    dataKey: '_embedded.authorizations',
  },
  {
    name: GET_PAYMENT_INSTRUMENT_VERIFICATIONS,
    api: getPaymentInstrumentVerificationsAPI,
    frontendModel: frontendVerificationsModel,
    dataKey: '_embedded.verifications',
  },
  {
    name: POST_DASHBOARD_USER,
    orchestration: postDashboardUserO,
    orchestrationV2: postDashboardUserV2O,
    frontendModel: frontendUsersModel,
  },
  {
    name: POST_ADMIN_USER,
    api: postV1UserAPI,
    frontendModel: frontendUsersModel,
    frontendModelV2: updateOne(frontendUsersModel),
  },
  {
    name: POST_CREDENTIALS,
    api: postCredentialsAPI,
  },
  {
    name: POST_SELF_SERVICE_SIGNUP_FORM,
    api: postSelfServiceSignupFormAPI,
    frontendModel: frontendSelfServiceSignupFormModel,
  },
  {
    name: POST_SELF_SERVICE_EMAIL_VERIFICATION,
    api: postSelfServiceEmailVerificationAPI,
    frontendModel: frontendSelfServiceSignupFormModel,
  },
  {
    name: POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA,
    api: postSelfServiceResendEmailWithCaptchaAPI,
  },
  {
    name: POST_SELF_SERVICE_RESEND_EMAIL,
    api: postSelfServiceResendEmailAPI,
  },
  {
    name: POST_APPLICATION_PROCESSORS,
    orchestration: postApplicationProcessorsO,
    frontendModel: frontendProcessorsModel,
  },
  {
    name: GET_DASHBOARD_USER,
    orchestration: getV1UserAPI,
    frontendModel: updateOne(frontendUsersModel),
    dataKey: 'data',
    dataKeyV2: '_embedded.dashboard_users.0',
  },
  {
    name: POST_USERS_EMAIL,
    api: postUserEmailAPI,
  },
  {
    name: POST_TRANSFER_REVERSAL,
    api: postTransferReversalAPI,
  },
  {
    name: POST_TRANSFER,
    api: postTransferAPI,
    backendModel: backendTransferModel,
  },
  {
    name: POST_AUTHORIZATION,
    api: postAuthorizationAPI,
    backendModel: backendAuthorizationModel,
  },
  {
    name: POST_BALANCE_TRANSFER,
    api: postBalanceTransferAPI,
    backendModel: backendBalanceTransferModel,
  },
  {
    name: DELETE_SETTLEMENT_ENTRIES,
    api: patchSettlementAPI,
  },
  {
    name: POST_DISPUTE_EVIDENCES,
    api: postDisputeEvidenceAPI,
    frontendModel: updateOne(frontendEvidencesModel),
  },
  {
    name: POST_SUBMIT_DISPUTE_EVIDENCE,
    api: postSubmitDisputeEvidenceAPI,
  },
  {
    name: POST_ACCEPT_DISPUTE,
    api: postAcceptDisputeAPI,
  },
  {
    name: GET_SETTINGS,
    api: getSettingsO,
    frontendModel: frontendSettingsModel,
  },
  {
    name: GET_APPLICATION_USERS,
    api: getApplicationUsersAPI,
    frontendModel: frontendApplicationUsersModel,
    dataKey: '_embedded.users',
  },
  {
    name: POST_APPLICATION_USERS,
    api: postApplicationUserAPI,
    frontendModel: updateOne(frontendApplicationUsersModel),
  },
  {
    name: POST_PLATFORM_USER,
    api: postUsersAPI,
    frontendModel: updateOne(frontendApplicationUsersModel),
  },
  {
    name: PATCH_MERCHANT,
    api: patchMerchantAPI,
    backendModel: backendMerchantModel,
    frontendModel: updateOne(frontendMerchantsModel),
  },
  {
    name: POST_MERCHANT_VERIFICATIONS,
    api: postMerchantVerificationAPI,
    frontendModel: updateOne(frontendVerificationsModel),
  },
  {
    name: POST_MERCHANT_FEE_PROFILE,
    orchestration: postMerchantFeeProfileO,
  },
  {
    name: GET_REVIEW_QUEUE_ITEM,
    orchestration: getReviewQueueItemO,
    frontendModel: frontendReviewQueueItemsModel,
    shouldNormalizeFrontend: false,
  },
  {
    name: PATCH_RISK_PROFILE,
    api: patchRiskProfileAPI,
  },
  {
    name: PATCH_MERCHANT_RISK_PROFILE,
    api: patchRiskProfileAPI,
  },
  {
    name: PATCH_TRANSFERS_UPDATE,
    orchestration: patchTransfersUpdateO,
  },
  {
    name: PATCH_VERIFICATIONS_UPDATE,
    orchestration: patchVerificationsUpdateO,
  },
  {
    name: PATCH_WELCOME_EMAIL_TEMPLATE,
    orchestration: patchWelcomeEmailTemplateO,
    orchestrationV2: patchWelcomeEmailTemplateV2O,
    frontendModel: frontendWelcomeEmailTemplatesModel,
    dataKey: 'data',
    dataKeyV2: '_embedded.dashboard_emails',
  },
  {
    name: PATCH_EMAIL_TEMPLATE,
    api: patchEmailTemplateAPI,
    frontendModel: frontendGenericEmailTemplatesModel,
    dataKey: '_embedded.dashboard_emails',
  },
  {
    name: GET_WELCOME_EMAIL_TEMPLATE,
    api: getWelcomeEmailTemplateAPI,
    frontendModel: frontendWelcomeEmailTemplatesModel,
    dataKeyV2: '_embedded.dashboard_emails.0',
  },
  {
    name: GET_EMAIL_TEMPLATE,
    api: getEmailTemplateAPI,
    frontendModel: frontendGenericEmailTemplatesModel,
    dataKey: '_embedded.dashboard_emails.0',
  },
  {
    name: POST_DASHBOARD_FILE,
    api: postDashboardFileAPI,
  },
  {
    name: PATCH_SETTLEMENT_V1,
    api: patchSettlementV1API,
  },
  {
    name: GET_PLATFORMS,
    api: getPlatformsAPI,
    frontendModel: frontendPlatformsModel,
    dataKey: '_embedded.platforms',
  },
  {
    name: GET_PLATFORM,
    api: getPlatformAPI,
    frontendModel: updateOne(frontendPlatformsModel),
  },
  {
    name: GET_PLATFORM_PROCESSOR_CONFIG,
    api: getPlatformProcessorConfigAPI,
    frontendModel: updateOne(frontendPlatformProcessorConfigsModel),
  },
  {
    name: GET_PLATFORM_PROCESSOR_CONFIGS,
    api: getPlatformProcessorConfigsAPI,
    frontendModel: frontendPlatformProcessorConfigsModel,
    dataKey: '_embedded.platformProcessorConfigDetailses',
  },
  {
    name: PATCH_PLATFORM_PROCESSOR_CONFIG,
    api: patchPlatformProcessorConfigAPI,
    backendModel: backendPlatformProcessorConfigModel,
  },
  {
    name: GET_PLATFORM_USERS,
    api: getPlatformUsersAPI,
    frontendModel: frontendApplicationUsersModel,
    dataKey: '_embedded.users',
  },
  {
    name: GET_PLATFORM_USER,
    api: getPlatformUserAPI,
    frontendModel: ({ data }) => frontendApplicationUsersModel({ data: [data] }),
  },
  {
    name: GET_USER_PERMISSIONS,
    orchestration: getUserPermissionsO,
    frontendModel: ({ data }) => keyBy(data, 'id'), // TODO: add FE model + index permissions by name instead of keeping everything in a single array
  },
  {
    name: GET_CREDENTIAL_PERMISSIONS,
    orchestration: getCredentialPermissionsO,
  },
  {
    name: PATCH_USER_PERMISSIONS,
    api: patchUserPermissionsAPI,
  },
  {
    name: POST_PAYMENT_INSTRUMENT_VERIFICATIONS,
    api: postPaymentInstrumentVerificationsAPI,
  },
  {
    name: GET_PAYOUT_PLAN,
    api: getPayoutPlanO,
    frontendModel: frontendPayoutPlansModel,
  },
  {
    name: GET_PAYOUT_PLANS,
    api: getPayoutPlansAPI,
    frontendModel: frontendPayoutPlansModel,
    dataKey: '_embedded.payout_plans',
  },
  {
    name: GET_GUEST_ONBOARDING_FORM_DATA,
    api: getGuestOnboardingFormDataAPI,
    frontendModel: updateOne(frontendOnboardingFormsDataModel),
  },
  {
    name: GET_ONBOARDING_FORM_DATA,
    api: getOnboardingFormDataAPI,
    frontendModel: updateOne(frontendOnboardingFormsDataModel),
  },
  {
    name: GET_ONBOARDING_FORMS,
    api: getOnboardingFormsAPI,
    frontendModel: frontendOnboardingFormsModel,
    dataKey: '_embedded.onboarding_forms',
  },
  {
    name: PATCH_ONBOARDING_FORM_DATA,
    api: patchOnboardingFormDataAPI,
    backendModel: backendOnboardingFormDataModel,
    frontendModel: updateOne(frontendOnboardingFormsDataModel),
  },
  {
    name: POST_ONBOARDING_FORM,
    api: postOnboardingFormAPI,
    frontendModel: frontendPostOnboardingFormModel,
  },
  {
    name: POST_ONBOARDING_FORM_LINK,
    api: postOnboardingFormLinkAPI,
    frontendModel: frontendPostOnboardingFormLinkModel,
  },
  {
    name: POST_ONBOARDING_FORM_DATA,
    api: postOnboardingFormDataAPI,
    frontendModel: frontendPostOnboardingFormDataModel,
  },
  {
    name: GET_HOSTED_FORM_TOKEN_VERIFICATION,
    api: getHostedFormTokenVerificationAPI,
    frontendModel: frontendHostedFormTokenVerificationModel,
  },
  {
    name: POST_PAYOUT_PLAN,
    api: postPayoutPlanAPI,
    backendModel: backendPayoutPlanModel,
  },
  // {
  //   name: PATCH_PAYOUT_PLAN,
  //   api: patchPayoutPlanAPI,
  //   dataKey: '_embedded.payout_plans',
  // },
  {
    name: POST_USER,
    orchestration: postUserO,
    orchestrationV2: postUserV2O,
    frontendModel: frontendUsersModel,
    dataKey: 'users',
  },
  {
    name: PATCH_USER,
    api: patchUserAPI,
    frontendModel: updateOne(frontendApplicationUsersModel),
  },
  {
    name: GET_NOTIFICATIONS,
    api: getNotificationsAPI,
    frontendModel: frontendNotificationsModel,
  },
  {
    name: GET_LATEST_NOTIFICATIONS,
    api: getNotificationsAPI,
    frontendModel: frontendNotificationsModel,
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULES,
    api: getSubscriptionSchedulesAPI,
    frontendModel: frontendSubscriptionSchedulesModel,
    dataKey: '_embedded.subscription_schedules',
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULES_AMOUNTS,
    api: getSubscriptionSchedulesAmountsO,
    frontendModel: frontendSubscriptionSchedulesModel,
    dataKey: 'schedules',
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULE,
    api: getSubscriptionScheduleAPI,
    frontendModel: updateOne(frontendSubscriptionSchedulesModel),
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULE_AMOUNTS,
    api: getSubscriptionScheduleAmountsAPI,
    frontendModel: frontendSubscriptionAmountsModel,
    dataKey: '_embedded.subscription_amounts',
  },
  {
    name: GET_SUBSCRIPTION_ENROLLMENTS,
    api: getSubscriptionEnrollmentsAPI,
    frontendModel: frontendSubscriptionEnrollmentsModel,
    dataKey: '_embedded.subscription_enrollments',
  },
  {
    name: GET_SUBSCRIPTION_ENROLLMENT,
    api: getSubscriptionEnrollmentAPI,
    frontendModel: updateOne(frontendSubscriptionEnrollmentModel),
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
    api: getSubscriptionEnrollmentsAPI,
    frontendModel: frontendSubscriptionEnrollmentsModel,
    dataKey: '_embedded.subscription_enrollments',
  },
  {
    name: PATCH_SUBSCRIPTION_SCHEDULE_AMOUNT,
    api: patchSubscriptionScheduleAmountAPI,
    backendModel: backendSubscriptionAmountModel,
    frontendModel: updateOne(frontendSubscriptionAmountsModel),
  },
  {
    name: DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT,
    api: deleteSubscriptionScheduleAmountAPI,
  },
  {
    name: POST_SUBSCRIPTION_SCHEDULE,
    api: postSubscriptionScheduleAPI,
    backendModel: backendSubscriptionScheduleModel,
    frontendModel: updateOne(frontendSubscriptionSchedulesModel),
  },
  {
    name: POST_SUBSCRIPTION_SCHEDULE_AMOUNT,
    api: postSubscriptionScheduleAmountAPI,
    backendModel: backendSubscriptionAmountModel,
    frontendModel: updateOne(frontendSubscriptionAmountsModel),
  },
  {
    name: POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
    api: postSubscriptionScheduleEnrollmentAPI,
    backendModel: backendSubscriptionEnrollmentModel,
    frontendModel: updateOne(frontendSubscriptionEnrollmentsModel),
  },
  {
    name: DELETE_SUBSCRIPTION_ENROLLMENT,
    api: deleteSubscriptionEnrollmentAPI,
  },
  {
    name: POST_MERCHANT_IDENTITY,
    orchestration: postMerchantIdentityO,
    frontendModel: frontendIdentitiesModel,
  },
  {
    name: PATCH_MERCHANT_IDENTITY,
    orchestration: patchMerchantIdentityO,
    frontendModel: frontendIdentitiesModel,
  },
  {
    name: GET_CURRENT_USER,
    orchestration: getCurrentUserO,
    frontendModel: frontendCurrentUserModel,
  },
  {
    name: DELETE_SUBSCRIPTION_ENROLLMENTS,
    orchestration: deleteSubscriptionEnrollmentsO,
  },
  {
    name: GET_SUBSCRIPTION_SCHEDULE_FEES,
    orchestration: getFeesO,
    frontendModel: frontendFeesModel,
    dataKey: 'fees',
  },
  {
    name: GET_DASHBOARD_CUSTOMIZATION,
    api: getDashboardCustomizationAPI,
    frontendModel: updateOne(frontendDashboardCustomizationsModel),
  },
  {
    name: GET_DASHBOARD_CUSTOMIZATIONS,
    api: getDashboardCustomizationsAPI,
    frontendModel: frontendDashboardCustomizationsModel,
    dataKey: '_embedded.dashboard_customizations',
  },
  {
    name: GET_GUEST_DASHBOARD_CUSTOMIZATIONS,
    api: getGuestDashboardCustomizationsAPI,
    frontendModel: frontendDashboardCustomizationsModel,
    dataKey: '_embedded.dashboard_customizations',
  },
  {
    name: POST_DASHBOARD_CUSTOMIZATIONS,
    orchestration: postDashboardCustomizationO,
    frontendModel: updateOne(frontendDashboardCustomizationsModel),
  },
  {
    name: PATCH_DASHBOARD_CUSTOMIZATIONS,
    orchestration: patchDashboardCustomizationO,
    frontendModel: updateOne(frontendDashboardCustomizationsModel),
  },
  // API setup only - waiting on BE endpoints implementation
  {
    name: GET_RESPONSE_FILES,
    api: getResponseFilesAPI,
  },
  {
    name: GET_PAYMENT_METHOD_CONFIGURATION,
    api: getPaymentMethodConfigurationAPI,
    frontendModel: updateOne(frontendApplePayRegisteredMerchantModel),
  },
  {
    name: POST_PAYMENT_METHOD_CONFIGURATIONS,
    api: postPaymentMethodConfigurationsAPI,
    frontendModel: updateOne(frontendApplePayRegisteredMerchantModel),
  },
  {
    name: PATCH_PAYMENT_METHOD_CONFIGURATIONS,
    api: patchPaymentMethodConfigurationsO,
    frontendModel: updateOne(frontendApplePayRegisteredMerchantModel),
  },
  {
    name: GET_FILE,
    orchestration: getFileO,
    frontendModel: frontendFilesModel,
  },
  {
    name: GET_ACCESS_FORM_FILE,
    orchestration: getAccessFormFileO,
  },
  {
    name: GET_CERTIFICATE_SIGNING_REQUESTS,
    api: getCertificateSigningRequestsAPI,
    frontendModel: frontendApplePayCertsModel,
    dataKey: 'certificate_signing_requests',
  },
  {
    name: POST_CERTIFICATE_SIGNING_REQUESTS,
    api: postCertificateSigningRequestsAPI,
    frontendModel: updateOne(frontendApplePayCertsModel),
  },
  {
    name: POST_CERTIFICATE_SIGNING_REQUEST,
    api: postCertificateSigningRequestAPI,
  },
  {
    name: POST_MERCHANT_FILE,
    orchestration: postFileO,
    frontendModel: frontendFilesModel,
  },
  {
    name: POST_IDENTITY_FILE,
    orchestration: postFileO,
    frontendModel: frontendFilesModel,
  },
  {
    name: POST_DASHBOARD_FEEDBACK_EMAIL,
    api: postUserEmailAPI,
  },
  {
    name: POST_CONTACT_SUPPORT_EMAIL,
    api: postUserEmailAPI,
  },
  {
    name: POST_CONTACT_SALES_EMAIL,
    api: postUserEmailAPI,
  },
  {
    name: GET_EXPORTS,
    api: getExportsO,
    frontendModel: frontendExportsModel,
  },
  {
    name: POST_EXPORTS,
    api: postExportsAPI,
  },
  {
    name: GET_CHARTS,
    api: getChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_HOME_CHARTS,
    api: getHomeChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_TRANSACTION_CHARTS,
    api: getTransactionChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_EXCEPTION_CHARTS,
    api: getExceptionChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_MERCHANT_CHARTS,
    api: getMerchantChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_UNDERWRITING_CHARTS,
    api: getUnderwritingChartsAPI,
    frontendModel: frontendChartsModel,
    dataKey: 'result',
  },
  {
    name: GET_UNDERWRITING_SUBJECT_EVENTS,
    api: getMerchantUnderwritingSubjectEventsAPI,
    frontendModel: frontendAuditLogEventsModel,
    dataKey: '_embedded.events',
  },
  {
    name: PATCH_APPLICATION_UNDERWRITING_SETTINGS,
    api: patchApplicationUnderwritingSettings,
  },
  {
    name: GET_NOTIFICATION_SETTINGS,
    orchestration: getNotificationSettingsAPI,
    frontendModel: frontendNotificationSettingsModel,
  },
  {
    name: POST_NOTIFICATION_SETTINGS,
    api: postNotificationSettingsAPI,
  },
  {
    name: POST_NOTIFICATION_ACKNOWLEDGMENT,
    api: postNotificationAcknowledgmentAPI,
    // TODO: have the BE team update endpoint to follow items convention for collection return
    frontendModel: ({ data }) => frontendNotificationsModel({ data: { items: data } }),
  },
  {
    name: GET_API_LOGS,
    api: getAPILogsAPI,
    frontendModel: frontendAPILogsModel,
    dataKey: '_embedded.apilogs',
  },
  {
    name: GET_API_LOG,
    api: getAPILogAPI,
    frontendModel: updateOne(frontendAPILogsModel),
  },
  {
    name: GET_DEVICE,
    orchestration: getDeviceO,
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_DEVICES,
    dataKey: '_embedded.devices',
    api: getDevicesAPI,
    frontendModel: frontendDevicesModel,
  },
  {
    name: POST_DEVICE,
    api: postDeviceAPI,
    frontendModel: updateOne(frontendDevicesModel),
    backendModel: backendDeviceModel,
  },
  {
    name: PATCH_DEVICE,
    api: patchDeviceAPI,
    frontendModel: updateOne(frontendDevicesModel),
    backendModel: backendDeviceModel,
  },
  {
    name: POST_DASHBOARD_PRESIGNED_URL,
    api: postDashboardPresignedUrlAPI,
  },
  {
    name: POST_DASHBOARD_V2_EMAILS,
    api: postDashboardV2EmailsAPI,
  },
  {
    name: GET_MEMBER,
    api: getMemberAPI,
    frontendModel: updateOne(frontendMembersModel),
  },
  {
    name: GET_MEMBERS,
    orchestration: getMembersO,
    frontendModel: frontendMembersModel,
    dataKey: '_embedded.dashboard_users',
  },
  {
    name: POST_MEMBER,
    api: postMemberAPI,
  },
  {
    name: POST_CHANGE_PASSWORD,
    api: postUserChangePasswordAPI,
  },
  {
    name: PATCH_MEMBERSHIP,
    api: patchMembershipAPI,
    frontendModel: frontendMembershipsModel,
  },
  {
    name: PATCH_DASHBOARD_USER,
    api: patchDashboardUserAPI,
    frontendModel: updateOne(frontendUsersModel),
  },
  {
    name: GET_ACCESS_FORM,
    api: getAccessFormAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: GET_ACCESS_FORM_CONTRACT,
    api: getAccessFormContractAPI,
    frontendModel: frontendAccessFormContractModel,
  },
  {
    name: GET_ACCESS_FORMS,
    api: getAccessFormsAPI,
    frontendModel: frontendAccessFormsModel,
    dataKey: '_embedded.access_forms',
  },
  {
    name: POST_ACCESS_FORM,
    api: postAccessFormAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
    backendModel: backendAccessFormModel,
  },
  {
    name: POST_SUBMIT_ACCESS_FORM,
    api: postSubmitAccessFormAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: POST_SUBMIT_GUEST_ACCESS_FORM,
    api: postSubmitGuestAccessFormAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: PATCH_ACCESS_FORM,
    frontendModel: updateOne(frontendAccessFormsModel),
    orchestration: patchAccessFormO,
  },
  {
    name: PATCH_GUEST_ACCESS_FORM,
    frontendModel: updateOne(frontendAccessFormsModel),
    orchestration: patchGuestAccessFormO,
  },
  {
    name: GET_UNDERWRITING_REVIEWS,
    api: getUnderwritingReviewsAPI,
    frontendModel: frontendUnderwritingReviewsModel,
    dataKey: '_embedded.reviews',
  },
  {
    name: GET_UNDERWRITING_REVIEW,
    orchestration: getUnderwritingReviewO,
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_UNDERWRITING_REPORT,
    api: getUnderwritingReportAPI,
  },
  {
    name: GET_MERCHANT_UNDERWRITING_REVIEWS,
    api: getUnderwritingReviewsAPI,
    frontendModel: frontendUnderwritingReviewsModel,
    dataKey: '_embedded.reviews',
  },
  {
    name: GET_RECIPIENT_UNDERWRITING_REVIEWS,
    api: getUnderwritingReviewsAPI,
    frontendModel: frontendUnderwritingReviewsModel,
    dataKey: '_embedded.reviews',
  },
  {
    name: PATCH_UNDERWRITING_REVIEW,
    api: patchUnderwritingReviewAPI,
    backendModel: backendUnderwritingReviewModel,
  },
  {
    name: PATCH_DISPUTE,
    api: patchDisputeAPI,
    frontendModel: updateOne(frontendDisputesModel),
  },
  {
    name: DELETE_ACCESS_FORM,
    api: deleteAccessFormAPI,
  },
  {
    name: GET_DASHBOARD_CONTRACTS,
    api: getDashboardContractsAPI,
    frontendModel: frontendAccessFormContractModel,
  },
  {
    name: PATCH_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
    orchestration: patchAccessFormAdditionalDocumentsO,
  },
  {
    name: PATCH_ONBOARDING_FORM_BANK_DOCUMENT,
    orchestration: patchOnboardingFormBankDocumentO,
  },
  {
    name: GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS,
    orchestration: getAccessFormAdditionalDocumentsO,
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_FEE_PROFILE,
    orchestration: getFeeProfileO,
    frontendModel: ({ data }) => { return data },
  },
  {
    name: GET_FEE_PROFILE_RULES,
    api: getFeeProfileRulesAPI,
    frontendModel: frontendFeeProfileRulesModel,
    dataKey: '_embedded.fee_profile_rules',
  },
  {
    name: POST_FEE_PROFILE_RULES,
    api: postFeeProfileRulesAPI,
    backendModel: backendFeeProfileRulesModel,
    frontendModel: updateOne(frontendFeeProfileRulesModel),
  },
  {
    name: PATCH_FEE_PROFILE_RULES,
    api: patchFeeProfileRulesAPI,
    backendModel: backendFeeProfileRulesModel,
    frontendModel: updateOne(frontendFeeProfileRulesModel),
  },
  {
    name: GET_FEE_PROFILE_SETTINGS,
    api: getFeeProfileSettingsAPI,
    frontendModel: frontendFeeProfileSettingsModel,
    dataKey: '_embedded.fee_profile_settings',
  },
  {
    name: POST_FEE_PROFILE_SETTINGS,
    api: postFeeProfileSettingsAPI,
    frontendModel: frontendFeeProfileSettingModel,
  },
  {
    name: PATCH_FEE_PROFILE_SETTINGS,
    api: patchFeeProfileSettingsAPI,
    frontendModel: frontendFeeProfileSettingModel,
  },
  {
    name: DELETE_API_KEY,
    api: deleteAPIKeyAPI,
  },
  {
    name: GET_UNDERWRITING_PROFILE,
    api: getUnderwritingProfileAPI,
    frontendModel: frontendUnderwritingProfileModel,
  },

  {
    name: GET_UNDERWRITING_PROFILES,
    api: getUnderwritingProfilesAPI,
    frontendModel: frontendUnderwritingProfilesModel,
    dataKey: '_embedded.underwriting_profiles',
  },
  {
    name: PATCH_UNDERWRITING_PROFILE,
    api: patchUnderwritingProfileAPI,
    frontendModel: updateOne(frontendUnderwritingProfilesModel),
  },
  {
    name: POST_UNDERWRITING_PROFILE,
    api: postUnderwritingProfileAPI,
    frontendModel: updateOne(frontendUnderwritingProfilesModel),
  },
  {
    name: GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE,
    api: getGuestOnboardingFormUnderwritingProfileAPI,
    frontendModel: updateOne(frontendUnderwritingProfilesModel),
  },
  {
    name: GET_CUSTOMER_ONBOARDING_FORMS,
    api: getCustomerOnboardingFormsAPI,
    frontendModel: frontendCustomerOnboardingFormsModel,
    dataKey: '_embedded.customer_onboarding_forms',
  },
  {
    name: GET_CUSTOMER_ONBOARDING_FORM,
    api: getCustomerOnboardingFormAPI,
    frontendModel: updateOne(frontendCustomerOnboardingFormsModel),
  },
  {
    name: POST_CUSTOMER_ONBOARDING_FORM,
    api: postCustomerOnboardingFormAPI,
    frontendModel: updateOne(frontendCustomerOnboardingFormsModel),
  },
  {
    name: GET_ROLE_MEMBERS,
    api: getRoleMembersAPI,
    frontendModel: frontendMembersModel,
    dataKey: '_embedded.dashboard_users',
  },
  {
    name: PATCH_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM,
    api: patchCustomerOnboardingFormAccessFormAPI,
    shouldNormalizeBackend: false,
  },
  {
    name: POST_CUSTOMER_ONBOARDING_FORM_LINKS,
    api: postCustomerOnboardingFormLinksAPI,
    frontendModel: ({ data }) => { return data },
  },
  {
    name: GET_GUEST_ACCESS_FORM,
    api: getGuestAccessFormAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: POST_DASHBOARD_USER_MFA_TOKEN,
    api: postDashboardUserMFATokenAPI,
    frontendModel: ({ data }) => data,
  },
  {
    name: POST_DASHBOARD_USER_MFA_ASSOCIATE,
    api: postDashboardUserMFAAssociateAPI,
    frontendModel: ({ data }) => data,
  },
  {
    name: POST_DASHBOARD_USER_MFA_VERIFY,
    api: postDashboardUserMFAVerifyAPI,
    frontendModel: ({ data }) => data,
  },
  {
    name: GET_DASHBOARD_USER_MFA_AUTHENTICATORS,
    api: getDashboardUserMFAAuthenticatorsAPI,
    frontendModel: ({ data }) => data,
  },
  {
    name: GET_ENTITY_CONFIGURATIONS,
    api: getEntityConfigurationsAPI,
    frontendModel: frontendEntityConfigurationsModel,
    dataKey: '_embedded.entity_configurations',
  },
  {
    name: POST_ENTITY_CONFIGURATION,
    api: postEntityConfigurationAPI,
    frontendModel: updateOne(frontendEntityConfigurationsModel),
    backendModel: backendEntityConfigurationModel,
  },
  {
    name: PATCH_ENTITY_CONFIGURATION,
    api: patchEntityConfigurationAPI,
    frontendModel: updateOne(frontendEntityConfigurationsModel),
    backendModel: backendEntityConfigurationModel,
  },
  {
    name: POST_DASHBOARD_USER_MFA_RESET,
    api: postDashboardUserMFAResetAPI,
  },
  {
    name: GET_SPLIT_TRANSFERS,
    api: getSplitTransfersAPI,
    frontendModel: frontendSplitTransfersModel,
    dataKey: '_embedded.split_transfers',
  },
  {
    name: GET_SPLIT_TRANSFER,
    orchestration: getSplitTransferO,
    frontendModel: frontendSplitTransfersModel,
    // we are handling the normalization all in the OL to avoid double normalization issues
    shouldNormalizeFrontend: false,
  },
  {
    name: GET_TRANSFER_REVERSALS,
    api: getTransferReversalsAPI,
    frontendModel: frontendTransfersModel,
    dataKey: '_embedded.transfers',
  },
  {
    name: GET_TRANSFER_DISPUTES,
    api: getTransferDisputesAPI,
    frontendModel: frontendDisputesModel,
    dataKey: '_embedded.disputes',
  },
  {
    name: POST_PAYMENT_INSTRUMENT,
    api: postPaymentInstrumentAPI,
    frontendModel: updateOne(frontendPaymentInstrumentsModel),
    backendModel: backendPaymentInstrumentModel,
  },
  {
    name: GET_APPLICATION_BALANCES,
    api: getBalancesAPI,
    frontendModel: frontendBalancesModel,
    dataKey: '_embedded.balances',
  },
  {
    name: GET_CURRENT_USAGES,
    api: getCurrentUsagesAPI,
    frontendModel: frontendCurrentUsagesModel,
    dataKey: '_embedded.current_usages',
  },
  {
    name: GET_BALANCE_ENTRIES,
    api: getBalanceEntriesAPI,
    frontendModel: frontendBalanceEntriesModel,
    dataKey: '_embedded.balance_entries',
  },
  {
    name: GET_RECIPIENT_IDENTITIES,
    api: getIdentitiesAPI,
    frontendModel: frontendIdentitiesModel,
    dataKey: '_embedded.identities',
  },
  {
    name: GET_DISBURSEMENT_RULES,
    api: getDisbursementRulesAPI,
    frontendModel: frontendDisbursementRulesModel,
    dataKey: '_embedded.disbursement_rules',
  },
  {
    name: POST_DISBURSEMENT_RULES,
    api: postDisbursementRulesAPI,
    frontendModel: updateOne(frontendDisbursementRulesModel),
    shouldNormalizeBackend: false,
  },
  {
    name: PATCH_DISBURSEMENT_RULES,
    api: patchDisbursementRulesAPI,
    frontendModel: updateOne(frontendDisbursementRulesModel),
    shouldNormalizeBackend: false,
  },
  {
    name: POST_BALANCE_ENTRY,
    api: postBalanceEntryAPI,
    frontendModel: updateOne(frontendBalanceEntriesModel),
    backendModel: backendBalanceEntryModel,
  },
  {
    name: GET_BALANCE_ENTRY,
    api: getBalanceEntryAPI,
    frontendModel: updateOne(frontendBalanceEntriesModel),
  },
  {
    name: POST_PAYMENT_LINK,
    api: postPaymentLinkAPI,
    backendModel: backendPaymentLinkModel,
  },
  {
    name: GET_PAYMENT_LINK,
    api: getPaymentLinkAPI,
    frontendModel: updateOne(frontendPaymentLinksModel),
  },
  {
    name: GET_PAYMENT_LINKS,
    api: getPaymentLinksAPI,
    frontendModel: frontendPaymentLinksModel,
    dataKey: '_embedded.payment_links',
  },
  {
    name: PATCH_PAYMENT_LINK,
    api: patchPaymentLinkAPI,
    frontendModel: updateOne(frontendPaymentLinksModel),
  },
  {
    name: GET_PURCHASES,
    api: getPurchasesAPI,
    frontendModel: frontendPurchasesModel,
    dataKey: '_embedded.purchases',
  },
  {
    name: GET_GUEST_CHECKOUT_TOKEN_VERIFICATION,
    api: getGuestCheckoutTokenVerificationAPI,
    frontendModel: ({ data }) => data,
  },
  {
    name: GET_GUEST_CHECKOUT_PAYMENT_LINK,
    api: getGuestCheckoutPaymentLinkAPI,
    frontendModel: updateOne(frontendPaymentLinksModel),
  },
  {
    name: POST_PURCHASE,
    api: postGuestPurchaseAPI,
    frontendModel: updateOne(frontendPurchasesModel),
    backendModel: backendPurchaseModel,
  },
  {
    name: GET_PURCHASE,
    api: getPurchaseO,
    shouldNormalizeFrontend: false,
  },
  {
    name: POST_UNDERWRITING_REVIEW_RUNS,
    api: postUnderwritingReviewRunsAPI,
  },
  {
    name: GET_GUEST_CHECKOUT_FORM,
    api: getGuestCheckoutFormAPI,
    frontendModel: updateOne(frontendPaymentLinksModel),
  },
  {
    name: GET_NOTES,
    api: getNotesAPI,
    frontendModel: frontendNotesModel,
    dataKey: '_embedded.notes',
  },
  {
    name: GET_NOTE,
    api: getNoteAPI,
    frontendModel: frontendNotesModel,
  },
  {
    name: POST_NOTE,
    api: postNoteAPI,
    frontendModel: updateOne(frontendNotesModel),
    backendModel: backendNoteModel,
  },
  {
    name: GET_DRAFT_NOTES,
    api: getDraftNotesAPI,
    frontendModel: frontendNotesModel,
    dataKey: '_embedded.notes',
  },
  {
    name: PATCH_NOTE,
    api: patchNoteAPI,
    frontendModel: updateOne(frontendNotesModel),
    backendModel: backendNoteModel,
  },
  {
    name: PATCH_UNDERWRITING_WORKFLOW,
    api: patchUnderwritingWorkflowAPI,
    backendModel: backendUnderwritingWorkflowModel,
    frontendModel: updateOne(frontendUnderwritingWorkflowsModel),
  },
  {
    name: GET_ACTIVE_COMPLIANCE_FORM_TEMPLATE,
    api: getActiveComplianceFormTemplateAPI,
    frontendModel: updateOne(frontendComplianceFormTemplatesModel),
    dataKey: 'active_compliance_form_templates.pci_saq_a',
  },
  {
    name: POST_COMPLIANCE_FORM,
    api: postComplianceFormAPI,
    frontendModel: updateOne(frontendComplianceFormsModel),
  },
  {
    name: POST_PLAID_TOKEN,
    orchestration: postPlaidTokenO,
    frontendModel: frontendPlaidTokenModel,
  },
  {
    name: POST_PLAID_PUBLIC_TOKEN,
    orchestration: postPlaidTokenO,
    frontendModel: frontendPlaidTokenModel,
  },
  {
    name: GET_APPLICATION_PROCESSOR_CONFIGURATIONS,
    api: getApplicationProcessorConfigAPI,
    frontendModel: updateOne(frontendApplicationProcessorsConfigModel),
  },
  {
    name: PATCH_APPLICATION_PROCESSOR_CONFIGURATIONS,
    api: patchApplicationProcessorConfigsAPI,
    backendModel: backendApplicationProcessorConfigModel,
    frontendModel: updateOne(frontendApplicationProcessorsConfigModel),
  },
  {
    name: GET_AUDIT_LOG_EVENTS,
    api: getAuditLogEventsAPI,
    frontendModel: frontendAuditLogEventsModel,
    dataKey: '_embedded.events',
  },
  {
    name: GET_AUDIT_LOG_EVENT,
    api: getAuditLogEventAPI,
    frontendModel: updateOne(frontendAuditLogEventsModel),
  },
  {
    name: GET_FRAUD_SCORES,
    api: getFraudScoresAPI,
    frontendModel: frontendFraudScoresModel,
    dataKey: '_embedded.fraud_scores',
  },
  {
    name: GET_REVIEW_RUNS,
    api: getUnderwritingReviewRunsAPI,
    frontendModel: frontendUnderwritingRunsModel,
    dataKey: '_embedded.runs',
  },
  {
    name: POST_SUBMIT_ACCESS_FORM_SALES_REVIEW,
    api: postSubmitAccessFormSalesReviewAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: POST_REQUEST_CHANGES,
    api: postCustomerOnboardingFormRequestChangesAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: POST_SUBMIT_TO_UNDERWRITING,
    api: postCustomerOnboardingFormSubmitToUnderwritingAPI,
    frontendModel: updateOne(frontendAccessFormsModel),
  },
  {
    name: POST_PAYOUT_LINK,
    api: postPayoutLinkAPI,
    backendModel: backendPayoutLinkModel,
  },
  {
    name: GET_PAYOUT_LINK,
    api: getPayoutLinkAPI,
    frontendModel: updateOne(frontendPayoutLinksModel),
  },
  {
    name: GET_PAYOUT_LINKS,
    api: getPayoutLinksAPI,
    frontendModel: frontendPayoutLinksModel,
    dataKey: '_embedded.payout_links',
  },
  {
    name: GET_TRANSFER_ATTEMPTS,
    api: getTransferAttemptsAPI,
    frontendModel: frontendTransferAttemptsModel,
    dataKey: '_embedded.transfer_attempts',
  },
  {
    name: PATCH_PAYOUT_LINK,
    api: patchPayoutLinkAPI,
    frontendModel: updateOne(frontendPayoutLinksModel),
  },
  {
    name: GET_TRANSFER_ATTEMPT,
    api: getTransferAttemptAPI,
    frontendModel: updateOne(frontendTransferAttemptsModel),
  },
  {
    name: PATCH_TRANSFER_ATTEMPT,
    api: patchTransferAttemptAPI,
    frontendModel: updateOne(frontendTransferAttemptsModel),
  },
  {
    name: GET_GUEST_CHECKOUT_MERCHANT_PAYMENT_INSTRUMENTS,
    api: getGuestCheckoutMerchantPaymentInstrumentsAPI,
    frontendModel: frontendPaymentInstrumentsModel,
    dataKey: '_embedded.payment_instruments',
  },
  {
    name: GET_GUEST_CHECKOUT_PAYOUT_LINK,
    api: getGuestCheckoutPayoutLinkAPI,
    frontendModel: updateOne(frontendPayoutLinksModel),
  },
  {
    name: POST_TRANSFER_ATTEMPT,
    api: postGuestTransferAttemptAPI,
    backendModel: backendTransferAttemptModel,
  },
  {
    name: GET_MERCHANT_STATEMENTS,
    api: getStatementsAPI,
    dataKey: '_embedded.statements',
    frontendModel: frontendStatementsModel,
  },
  {
    name: GET_SPLIT_TRANSFER_SETTLEMENT,
    api: getSplitTransferSettlementAPI,
    frontendModel: frontendSettlementsModel,
    dataKey: '_embedded.settlements',
  },
  {
    name: POST_DISPUTE,
    api: postDisputeAPI,
    backendModel: backendDisputeModel,
    frontendModel: updateOne(frontendDisputesModel),
  },
  {
    name: PATCH_UPDATE_DISPUTE,
    api: patchUpdateDisputeAPI,
    backendModel: backendDisputeModel,
    frontendModel: updateOne(frontendDisputesModel),
  },
  {
    name: GET_BALANCE_ADJUSTMENTS,
    api: getBalanceAdjustmentsAPI,
    dataKey: '_embedded.balance_adjustments',
    frontendModel: frontendBalanceAdjustmentsModel,
  },
  {
    name: POST_BALANCE_ADJUSTMENT,
    api: postBalanceAdjustmentAPI,
    backendModel: backendBalanceAdjustmentModel,
  },
  {
    name: GET_BALANCE_ADJUSTMENT,
    api: getBalanceAdjustmentAPI,
    frontendModel: updateOne(frontendBalanceAdjustmentsModel),
  },
  {
    name: POST_RECEIPT,
    api: postReceiptAPI,
    backendModel: backendReceiptModel,
    frontendModel: updateOne(frontendReceiptsModel),
  },
  {
    name: GET_RECEIPT,
    api: getReceiptAPI,
    frontendModel: updateOne(frontendReceiptsModel),
  },
  {
    name: GET_RECEIPTS,
    api: getReceiptsAPI,
    frontendModel: frontendReceiptsModel,
    dataKey: '_embedded.receipts',
  },
  {
    name: GET_RECEIPT_DELIVERY_ATTEMPTS,
    api: getReceiptDeliveryAttemptsAPI,
    frontendModel: frontendReceiptDeliveryAttemptsModel,
    dataKey: '_embedded.delivery_attempts',
  },
  {
    name: POST_RECEIPT_DELIVERY_ATTEMPTS,
    api: postReceiptDeliveryAttemptsAPI,
    backendModel: backendReceiptDeliveryAttemptsModel,
    frontendModel: frontendReceiptDeliveryAttemptModel,
  },
  {
    name: GET_SUBSCRIPTIONS,
    api: getSubscriptionsAPI,
    frontendModel: frontendSubscriptionsModel,
    dataKey: '_embedded.subscriptions',
  },
  {
    name: GET_SUBSCRIPTION,
    api: getSubscriptionAPI,
    frontendModel: updateOne(frontendSubscriptionsModel),
  },
  {
    name: POST_SUBSCRIPTION,
    api: postSubscriptionAPI,
    backendModel: backendSubscriptionModel,
    frontendModel: updateOne(frontendSubscriptionsModel),
  },
  {
    name: GET_SUBSCRIPTION_PLANS,
    api: getSubscriptionPlansAPI,
    dataKey: '_embedded.subscription_plans',
    frontendModel: frontendSubscriptionPlansModel,
  },
  {
    name: POST_SUBSCRIPTION_PLAN,
    api: postSubscriptionPlanAPI,
    backendModel: backendSubscriptionPlanModel,
    frontendModel: updateOne(frontendSubscriptionPlansModel),
  },
  {
    name: GET_SUBSCRIPTION_PLAN,
    api: getSubscriptionPlanAPI,
    frontendModel: updateOne(frontendSubscriptionPlansModel),
  },
  {
    name: PATCH_ONBOARDING_FORM_DOCUMENTS,
    orchestration: patchOnboardingFormDocumentsO,
  },
  {
    name: GET_GUEST_ONBOARDING_FORM_FILES,
    orchestration: getGuestOnboardingFormFilesO,
    frontendModel: frontendFilesModel,
  },
  {
    name: PATCH_ONBOARDING_FORM_DOCUMENT,
    orchestration: patchOnboardingFormDocumentO,
  },
  {
    name: DELETE_SUBSCRIPTION,
    api: deleteSubscriptionAPI,
  },
  {
    name: POST_UNDERWRITING_RUN_SILENCE,
    api: postUnderwritingRunSilenceAPI,
  },
  {
    name: PATCH_SUBSCRIPTION_PLAN,
    api: patchSubscriptionPlanAPI,
    backendModel: backendSubscriptionPlanModel,
    frontendModel: updateOne(frontendSubscriptionPlansModel),
  },
  {
    name: PATCH_SUBSCRIPTION,
    api: patchSubscriptionAPI,
    backendModel: backendSubscriptionModel,
    frontendModel: updateOne(frontendSubscriptionsModel),
  },
  {
    name: GET_MONITORING_ALERTS,
    api: getMonitoringAlertsAPI,
    frontendModel: frontendMonitoringAlertsModel,
    dataKey: '_embedded.alerts',
  },
  {
    name: PATCH_MONITORING_ALERT,
    api: patchMonitoringAlertAPI,
    frontendModel: updateOne(frontendMonitoringAlertsModel),
    backendModel: backendMonitoringAlertModel,
  },
  {
    name: PATCH_MONITORING_ALERTS,
    orchestration: patchMonitoringAlertsO,
    frontendModel: frontendMonitoringAlertsModel,
  },
  {
    name: GET_MONITORING_ALERT,
    api: getMonitoringAlertAPI,
    frontendModel: updateOne(frontendMonitoringAlertsModel),
  },
  {
    name: GET_INSTANT_PAYOUTS,
    api: getInstantPayoutsAPI,
    dataKey: '_embedded.funding_transfer_attempts',
    frontendModel: frontendInstantPayoutsModel,
  },
  {
    name: POST_INSTANT_PAYOUT,
    api: postInstantPayoutAPI,
    backendModel: backendInstantPayoutModel,
    frontendModel: updateOne(frontendInstantPayoutsModel),
  },
  {
    name: GET_INSTANT_PAYOUT,
    api: getInstantPayoutAPI,
    frontendModel: updateOne(frontendInstantPayoutsModel),
  },
]
