import isEmpty from 'lodash/isEmpty'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  validateBankCode,
  validateMaskedBankAccountNumber,
} from 'utilities/validate'

import {
  INSTITUTION_NUMBER_REGEX,
  TRANSIT_NUMBER_REGEX,
} from 'constants/regexConstants'

const validateBankAccountInfoForm = (values, props) => {
  const { bankAccountData = {} } = values
  const { showBankFieldset } = props

  const {
    name,
    bankCode,
    accountNumber,
    accountType,
    transitNumber,
    institutionNumber,
    bankDocument,
  } = bankAccountData

  return {
    bankAccountData: showBankFieldset ? {
      name: fieldEmpty(name, 'Account Holder Name'),
      bankCode: validateBankCode({ bankCode, name: 'Routing Number' }),
      accountNumber: validateMaskedBankAccountNumber({ accountNumber, name: 'Account Number' }),
      accountType: fieldEmpty(accountType, 'Account Type'),
      transitNumber: invalidOrEmptyRegex({ field: transitNumber, name: 'Transit Number', regex: TRANSIT_NUMBER_REGEX }),
      institutionNumber: invalidOrEmptyRegex({ field: institutionNumber, name: 'Institution Number', regex: INSTITUTION_NUMBER_REGEX }),
      bankDocument: isEmpty(bankDocument) ? 'Please upload a bank document' : '',
    } : {},
  }
}

export default validateBankAccountInfoForm
