import { PATCH_APPLICATION_F_REQUEST } from 'constants/flowConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import assign from 'lodash/assign'
import { UPDATE_PAYOUT_PROFILE } from 'constants/amplitudeConstants'

// TODO: Add submit unit test
const submitEditPayoutProfileForm = (values, dispatch, props) => {
  const {
    disbursementsCardPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsAchPullEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
  } = values

  const {
    payoutProfileInfo,
    credentials,
    applicationId,
  } = props

  const newValues = assign({}, payoutProfileInfo,
    { disbursementsCardPushEnabled,
      disbursementsCardPullEnabled,
      disbursementsAchPullEnabled,
      disbursementsAchPushEnabled,
      disbursementsSameDayAchPullEnabled,
      disbursementsSameDayAchPushEnabled,
    })

  dispatch({
    type: PATCH_APPLICATION_F_REQUEST,
    payload: {
      values: newValues,
      credentials,
      id: applicationId,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPDATE_PAYOUT_PROFILE, {
        applicationId,
        values,
        credentials,
      }),
      successMessage: 'Payout profile has been successfully updated.',
    },
  })
}

export default submitEditPayoutProfileForm
