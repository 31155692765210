import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import {
  AM,
  PM,
} from 'constants/timeConstants'

const convertToMilitaryTime = ({ hour, meridian, useTwoDigitHourFormat = false }) => {
  let normalizedHour = toNumber(hour)

  if (normalizedHour < 1 || normalizedHour > 12 || (meridian !== AM && meridian !== PM)) {
    return toString(normalizedHour)
  }

  if (meridian === PM && normalizedHour !== 12) {
    normalizedHour += 12
  } else if (meridian === AM && normalizedHour === 12) {
    normalizedHour = useTwoDigitHourFormat ? '00' : 0
  } else if (meridian === AM && normalizedHour < 10 && useTwoDigitHourFormat) {
    normalizedHour = `0${normalizedHour}`
  }

  return toString(normalizedHour)
}

export default convertToMilitaryTime
