import frontendWebhookLogModel from 'utilities/create/models/frontendWebhookLogModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendWebhookLogsModel = ({ data: webhookLogs }) => {
  const webhookLogsModels = map(webhookLogs, (webhookLog) => frontendWebhookLogModel({ data: webhookLog }))

  return keyBy(webhookLogsModels, 'id')
}

export default frontendWebhookLogsModel
