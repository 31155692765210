import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { PATCH_UNDERWRITING_PROFILE_F_REQUEST } from 'constants/flowConstants'

const submitUpdateRecipientSettingsForm = (values, dispatch, props) => {
  const {
    credentials,
    underwritingProfile,
    closeModal,
  } = props

  const {
    businessRecipientsEnabled,
    personalRecipientsEnabled,
  } = values

  const [
    underwritingProfileId,
    merchantCategoryCodes,
    maxTransactionAmount,
    achMaxTransactionAmount,
    allowAllMCCs,
  ] = getMany(underwritingProfile, [
    'id',
    'mccDetails.enabledMCCs',
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'mccDetails.allowAllMCCs',
  ])

  const valuesToSubmit = {
    max_transaction_amount: maxTransactionAmount,
    ach_max_transaction_amount: achMaxTransactionAmount,
    mcc_details: {
      allow_all_mccs: allowAllMCCs,
      enabled_mccs: allowAllMCCs ? [] : merchantCategoryCodes,
    },
    recipient_settings: {
      personal_recipients_enabled: personalRecipientsEnabled,
      business_recipients_enabled: businessRecipientsEnabled,
    },
  }

  dispatch({
    type: PATCH_UNDERWRITING_PROFILE_F_REQUEST,
    payload: removeUndefined({
      id: underwritingProfileId,
      values: valuesToSubmit,
      credentials,
    }),
    meta: {
      successCallback: () => {
        closeModal()
      },
    },
  })
}

export default submitUpdateRecipientSettingsForm
