const parsePhone = (value) => {
  if (!value) {
    return ''
  }

  const onlyNums = value.replace(/-/g, '')
  return onlyNums.slice(0, 11)
}

export default parsePhone
