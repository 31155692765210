import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeactivateDeviceForm from 'components/Customer/Forms/DeactivateDeviceForm/DeactivateDeviceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import { DEACTIVATE_VALUE } from 'constants/deviceConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
    actionType: DEACTIVATE_VALUE,
    isUpdating: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class DeactivateDeviceFormC extends Component {
  render() {
    return (
      <DeactivateDeviceForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateDeviceFormC)
