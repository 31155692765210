import './AccordionS.scss'
import React, { useState } from 'react'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import PropTypes from 'prop-types'
import { t } from 'constants/language/languageConstants'

import {
  ANGLE_UP_ICON,
  ANGLE_DOWN_ICON,
} from 'constants/iconConstants'

const Accordion = ({
  title,
  description,
  children,
  isEmpty,
  isEmptyMessage = '',
  isDisabled,
  isDisabledMessage = '',
  className = 'default',
  open = false,
  showCheckbox = false,
  checkboxFunc = () => {},
}) => {
  const [isActive, setIsActive] = useState(open)

  const clickAccordion = () => {
    setIsActive(!isActive)
    if (checkboxFunc) checkboxFunc()
  }

  if (isDisabled) {
    return (
      <div className={`Accordion ${className}`}>
        <div className='accordion-header disabled'>
          <div className='accordion-header-container flex'>
            <div className='flex items-center'>
              <h6>{t(title)}</h6>
              <div className='dash'> - </div>
              <div className='disabled-message'>{t(isDisabledMessage)}</div>
            </div>
            <i className={`fa fa-${ANGLE_DOWN_ICON} arrow`} />
          </div>
        </div>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div className={`Accordion ${className}`}>
        <div className={`accordion-header empty ${isActive ? 'open' : 'close'}`} onClick={clickAccordion}>
          <div className='accordion-header-container flex'>
            <h6>{t(title)}</h6>
            <i className={`fa fa-${isActive ? ANGLE_UP_ICON : ANGLE_DOWN_ICON} arrow`} />
          </div>
          <div className='description'>{t(description)}</div>
        </div>
        {isActive && (
        <div className='content-container'>
          <div className='empty-message'>
            {t(isEmptyMessage)}
          </div>
        </div>
        )}
      </div>
    )
  }

  return (
    <div className={`Accordion ${className}`}>
      <div className={`accordion-header ${isActive ? 'open' : 'close'}`} onClick={clickAccordion}>
        <div className='accordion-header-container flex'>
          <div className='flex'>
            {showCheckbox && <CheckboxC checked={isActive} />}
            <div className='flex column'>
              <h6>{t(title)}</h6>
              {description && <div className='description'>{t(description)}</div>}
            </div>
          </div>
          <i className={`fa fa-${isActive ? ANGLE_UP_ICON : ANGLE_DOWN_ICON} arrow`} />
        </div>
      </div>
      <div className={`content-container ${isActive ? 'visible' : 'hidden'}`}>{children}</div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  description: PropTypes.string,
  children: PropTypes.node,
  isEmpty: PropTypes.bool,
  isEmptyMessage: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checkboxFunc: PropTypes.func,
}

export default Accordion
