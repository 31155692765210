import './FlowsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

// TODO: refactor to be able to support the flows to end on a form that doesn't have to be a confirmation page
const Flows = ({
  activeStep,
  clickForStep,
  clickForNextStep,
  clickForPreviousStep,
  credentials,
  dispatcher,
  progress,
  steps,
  formValues,
  skipStep,
  skipStatus = {},
  flowClassName = '',
  hideFlowProgress = false,
  showProgressString = false,
  cancelProgress = () => {},
  rightButtonAction,
  disabledLeftButton = false,
  disabledRightButton = false,
  justifyContent = 'flex-start',
}) => {
  const activeStepConfig = get(steps, activeStep, {})

  const {
    component: ActiveComponent = () => <div />,
    props = {},
    dataKey = '',
    optional = false,
    initialValues = {},
  } = activeStepConfig

  const componentValues = get(formValues, dataKey) || initialValues
  const skipStatusForStep = get(skipStatus, activeStep, false)
  const skipStepMsg = skipStatusForStep ? 'This step has been skipped' : 'This step is optional'
  const skipButtonTxt = skipStatusForStep ? 'Undo Skip' : 'Skip to Next'
  const totalSteps = steps.length
  const progressString = `${activeStep + 1} of ${totalSteps}`
  const leftButtonLabel = activeStep === 0 ? 'Cancel' : 'Previous'
  const leftButtonAction = activeStep === 0 ? cancelProgress : clickForPreviousStep
  const leftButtonClassnames = classnames('left-button', { danger: leftButtonLabel === 'Cancel' })
  const leftButton = get(steps, `${activeStep}.props.leftButton`)
  const submitLabel = get(steps, `${activeStep}.props.submitLabel`)
  const lastStep = (activeStep + 1) === totalSteps

  const buttonGroup = (
    <div className='buttons flex space-between items-center'>
      <div className='left flex flex-start'>
        { leftButton && <Button variant='secondary' className={leftButtonClassnames} disabled={disabledLeftButton} onClick={leftButtonAction} label={leftButtonLabel} /> }
      </div>

      {showProgressString && <div className='progress-string'>{progressString}</div>}

      <div className='right flex flex-end'>
        <Button type='submit' label={submitLabel} disabled={disabledRightButton} />
      </div>
    </div>
  )

  const componentClassNames = classnames('Flows', {
    flex: true,
    [justifyContent]: true,
    [flowClassName]: true,
  })

  return (
    <div className={componentClassNames}>
      {!hideFlowProgress && (
      <ul className='flex column flow-progress'>
        { map(steps, (step, index) => {
          const {
            name,
            iconName = 'fa-dot-circle',
            form = '',
          } = step

          const shouldDisable = index > progress
          const separationNeeded = index > 0
          const processClassnames = classnames('process-session', {
            completed: index < progress,
            'in-progress': index === progress,
            'not-completed': index > progress,
          })

          return (
            <li key={name} className={processClassnames}>
              { separationNeeded && (
                <div className='flex flex-end separation'>
                  <div className='separation-line' />
                </div>
              )}

              <div className='flex flex-end control'>
                <div className='process-name'>{name}</div>
                <div
                  type='button'
                  className='process-btn'
                  onClick={() => clickForStep(index, progress)}
                >
                  <i className={`fa ${iconName}`} />
                </div>
              </div>
            </li>
          )
        }) }
      </ul>
      )}

      <div className='flow-container content-card'>
        { optional && (
          <div className='flex flex-end skip-controller'>
            <span className='message'>{skipStepMsg}</span>
            <Button variant='tertiary' className='skip-button' onClick={() => skipStep(activeStep, !skipStatusForStep)} label={skipButtonTxt} />
          </div>
        )}

        { skipStatusForStep && (
          <div className='flex space-between flow-controller'>
            <Button variant='secondary' onClick={clickForPreviousStep} label={BACK} />
            <Button onClick={clickForNextStep} label={NEXT} />
          </div>
        )}

        { !skipStatusForStep && (
          <ActiveComponent
            flows
            onSubmit={rightButtonAction ? rightButtonAction : clickForNextStep}
            initialValues={componentValues}
            formValues={formValues}
            steps={steps}
            skipStatus={skipStatus}
            goToStep={clickForStep}
            credentials={credentials}
            dispatch={dispatcher}
            buttonGroup={buttonGroup}
            leftButtonLabel={leftButtonLabel}
            leftButtonAction={leftButtonAction}
            activeStep={activeStep}
            lastStep={lastStep}
            {...props}
          />
        )}
      </div>
    </div>
  )
}

Flows.propTypes = {
  activeStep: PropTypes.number,
  clickForStep: PropTypes.func,
  clickForNextStep: PropTypes.func,
  clickForPreviousStep: PropTypes.func,
  credentials: PropTypes.object,
  dispatcher: PropTypes.func,
  progress: PropTypes.number,
  steps: PropTypes.array,
  formValues: PropTypes.object,
  skipStep: PropTypes.func,
  skipStatus: PropTypes.object,
  hideFlowProgress: PropTypes.bool,
  showProgressString: PropTypes.bool,
  cancelProgress: PropTypes.func,
  rightButtonAction: PropTypes.func,
  disabledLeftButton: PropTypes.bool,
  disabledRightButton: PropTypes.bool,
}

export default Flows
