import fullPlatformAdministrator from 'constants/userPermissions/fullPlatformAdministrator'
import platformManager from 'constants/userPermissions/platformManager'
import platformManagerReadOnly from 'constants/userPermissions/platformManagerReadOnly'
import rolePlatformPermissionNames from 'constants/userPermissions/rolePlatformPermissionNames'
import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'

export const FULL_PLATFORM_ADMINISTRATOR = 'Full Platform Administrator'
export const PLATFORM_MANAGER = 'Platform Manager'
export const PLATFORM_MANAGER_READ_ONLY = 'Platform Manager (Read Only)'

export const rolePlatformAccessLevels = {
  FULL_PLATFORM_ADMINISTRATOR,
  PLATFORM_MANAGER,
  PLATFORM_MANAGER_READ_ONLY,
}

export const rolePlatformAccessPermissionMap = {
  [FULL_PLATFORM_ADMINISTRATOR]: fullPlatformAdministrator,
  [PLATFORM_MANAGER]: platformManager,
  [PLATFORM_MANAGER_READ_ONLY]: platformManagerReadOnly,
}

export const rolePlatformAccessOptions = [
  selectOption('Select Access Level', ''),
  ...map(rolePlatformAccessLevels, (access) => selectOption(access))
]

export const rolePlatformPermissions = [
  'authentication:user:create',
  'authentication:user:list',
  'authentication:user:merchant_create',
  'authentication:user:partner_create',
  // 'authentication:user:permission_delete',
  // 'authentication:user:permission_duplicate',
  'authentication:user:permission_list',
  'authentication:user:permission_update',
  'authentication:user:read',
  // 'authentication:user:sync',
  'authentication:user:update',
  // 'processing:admin:update',
  'processing:application_profile:create',
  'processing:application_profile:list',
  'processing:application_profile:read',
  'processing:application_profile:update',
  'processing:application:create',
  'processing:application:list',
  'processing:application:merchant_profile_update',
  'processing:application:processor_disable',
  'processing:application:processor_enable',
  'processing:application:processors_list',
  'processing:application:read',
  'processing:application:update',
  'processing:application:users_list',
  // 'processing:authorization:create',
  'processing:authorization:list',
  'processing:authorization:read',
  // 'processing:authorization:update',
  'processing:black_listed_instrument:create',
  'processing:black_listed_instrument:delete',
  'processing:black_listed_instrument:read',
  'processing:dispute:create',
  'processing:dispute:evidence_submit',
  'processing:dispute:files_list',
  'processing:dispute:list',
  'processing:dispute:read',
  'processing:dispute:update',
  'processing:fee_profile:create',
  'processing:fee_profile:list',
  'processing:fee_profile:read',
  'processing:fee_profile:update',
  'processing:fee_strategy:create',
  'processing:fee_strategy:list',
  'processing:fee_strategy:read',
  'processing:fee_strategy:update',
  // 'processing:fee:create',
  'processing:fee:list',
  'processing:fee:read',
  'processing:identity:create',
  'processing:identity:list',
  'processing:identity:read',
  'processing:identity:update',
  'processing:instrument_update:create',
  'processing:instrument_update:list',
  'processing:instrument_update:read',
  'processing:instrument_update:update',
  'processing:instrument:create',
  'processing:instrument:list',
  'processing:instrument:read',
  'processing:instrument:update',
  // 'processing:mcc_codes:create',
  // 'processing:mcc_codes:update',
  'processing:merchant_profile:create',
  'processing:merchant_profile:list',
  'processing:merchant_profile:read',
  'processing:merchant_profile:update',
  'processing:merchant:create',
  'processing:merchant:list',
  'processing:merchant:read',
  'processing:merchant:update',
  'processing:platform_fee_profile:update',
  'processing:platform_processor_config:create',
  'processing:platform_processor_config:list',
  'processing:platform_processor_config:read',
  'processing:platform_processor_config:update',
  'processing:platform_webhook:create',
  'processing:platform:create',
  'processing:platform:list',
  'processing:platform:read',
  'processing:platform:update',
  'processing:processor:create',
  'processing:processor:list',
  'processing:processor:read',
  'processing:processor:update',
  'processing:reserve_profile:create',
  'processing:reserve_profile:list',
  'processing:reserve_profile:read',
  'processing:reserve_profile:update',
  'processing:review_queue:create',
  'processing:review_queue:list',
  'processing:review_queue:read',
  'processing:review_queue:update',
  'processing:risk_profile_rule:create',
  'processing:risk_profile_rule:list',
  'processing:risk_profile_rule:read',
  'processing:risk_profile_rule:update',
  'processing:risk_profile:create',
  'processing:risk_profile:list',
  'processing:risk_profile:read',
  'processing:risk_profile:update',
  'processing:settlement:create',
  'processing:settlement:list',
  'processing:settlement:read',
  'processing:settlement:settling_transfer_create',
  'processing:settlement:transfers_list',
  'processing:settlement:update',
  'processing:transaction:create',
  'processing:transaction:list',
  'processing:transaction:read',
  'processing:transaction:update',
  'processing:transfer:accounting_create',
  'processing:transfer:adjustment_create',
  'processing:transfer:create',
  'processing:transfer:fee_return',
  'processing:transfer:fees_list',
  'processing:transfer:funds_release',
  'processing:transfer:list',
  'processing:transfer:payout',
  'processing:transfer:platform_fee_return',
  'processing:transfer:read',
  'processing:transfer:refund',
  'processing:transfer:refunds_list',
  'processing:transfer:update',
  'processing:verification:create',
  'processing:verification:list',
  'processing:verification:read',
  'processing:verification:update',
  'processing:view:application_list_all',
  'processing:view:entity_system',
  'processing:view:identity_list_all',
  'processing:view:settlement_system',
  'processing:webhook:create',
  'processing:webhook:list',
  'processing:webhook:read',
  'processing:webhook:update',
  // 'reporting:report:create',
  'reporting:report:list',
  'reporting:report:read',
  // 'reporting:report:update',
  'risk:rule_instance:create',
  'risk:rule_instance:list',
  'risk:rule_instance:update',
  // 'search:search:create',
  // 'search:search:delete',
  'search:search:list',
  'search:search:read',
  // 'search:search:update',
  'settlement:funding_instruction_preview:list',
  'settlement:funding_instruction:create',
  'settlement:funding_instruction:list',
  'settlement:funding_instruction:resubmit',
  'settlement:funding_instruction:update',
  // 'settlement:payout_plans:create',
  'settlement:payout_plans:list',
  'settlement:payout_plans:read',
  'settlement:payout_plans:update',
  'settlement:settlement_group_settings:create',
  'settlement:settlement_group_settings:delete',
  'settlement:settlement_group_settings:list',
  'settlement:settlement_group_settings:read',
  'settlement:settlement_group:read',
  'subscription:subscription_group:create',
  'subscription:subscription_group:list',
  'subscription:subscription_group:read',
  'subscription:subscription_group:update',
  'subscription:subscription_item:create',
  'subscription:subscription_item:list',
  'subscription:subscription_item:read',
  'subscription:subscription_item:update',
  'subscription:subscription_plan:create',
  'subscription:subscription_plan:list',
  'subscription:subscription_plan:read',
  'subscription:subscription_plan:update',
  'subscription:subscription_schedule:create',
  'subscription:subscription_schedule:list',
  'subscription:subscription_schedule:read',
  'subscription:subscription_schedule:update',
]

export const getPermissionsForAccessLevel = (access) => {
  const accessLevelPermissions = get(rolePlatformAccessPermissionMap, access, [])
  const permissions = map(rolePlatformPermissionNames, (name) => {
    return {
      name,
      enabled: get(accessLevelPermissions, name, false),
    }
  })

  return permissions
}
