import getMany from 'utilities/get/getMany'
import numeral from 'numeral'

const warnEditIdentityProcessingInformationForm = (values, props) => {
  const {
    maxTransactionAmount,
    achMaxTransactionAmount,
  } = values

  const [
    applicationMaxTransactionAmount,
    applicationMaxACHTransactionAmount,
    displayApplicationMaxACHTransactionAmount,
    displayApplicationMaxTransactionAmount,
    isRolePartnerCredential,
    isInitialMCCOnList,
  ] = getMany(props, [
    'applicationMaxTransactionAmount',
    'applicationMaxACHTransactionAmount',
    'displayApplicationMaxACHTransactionAmount',
    'displayApplicationMaxTransactionAmount',
    'isRolePartnerCredential',
    'isInitialMCCOnList',
  ])

  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(applicationMaxTransactionAmount).value()
  const invalidACHMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(applicationMaxACHTransactionAmount).value()

  return {
    mcc: (!isInitialMCCOnList && isInitialMCCOnList !== undefined) && 'MCC is not on the list of enabled MCCs for the Application',
    maxTransactionAmount: (invalidMaxTransactionAmount && !isRolePartnerCredential) && `Value exceeds the Application's max amount of ${displayApplicationMaxTransactionAmount}`,
    achMaxTransactionAmount: (invalidACHMaxTransactionAmount && !isRolePartnerCredential) && `Value exceeds the Application's ACH max amount of ${displayApplicationMaxACHTransactionAmount}`,
  }
}

export default warnEditIdentityProcessingInformationForm
