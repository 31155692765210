import './ResourcesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { RESOURCES } from 'constants/language/languageConstants'
import { infoTableTheme } from 'constants/themeConstants'

import {
  merchantResourcesColumnDescriptors,
  partnerPlatformResourcesColumnDescriptors,
} from './columnDescriptors'

const Resources = ({
  merchantData = [],
  partnerPlatformData = [],
  isRoleMerchantCred = false,
}) => {
  return (
    <div className='Resources'>
      <TableC
        title={RESOURCES}
        columnDescriptors={isRoleMerchantCred ? merchantResourcesColumnDescriptors : partnerPlatformResourcesColumnDescriptors}
        theme={infoTableTheme}
        showTableFooter={false}
        data={isRoleMerchantCred ? merchantData : partnerPlatformData}
      />
    </div>
  )
}

Resources.propTypes = {
  isRoleMerchantCred: PropTypes.bool,
  merchantData: PropTypes.array,
  partnerPlatformData: PropTypes.array,
}

export default Resources
