import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_MERCHANT_VERIFICATIONS_F_REQUEST } from 'constants/flowConstants'
import { CREATE_MERCHANT_VERIFICATION } from 'constants/amplitudeConstants'
import removeUndefined from 'utilities/remove/removeUndefined'
import get from 'lodash/get'

const submitCreateMerchantVerificationForm = (values, dispatch, props) => {
  const {
    merchantId,
    credentials,
    optionalProcessorParamsAllowed = false,
  } = props

  const {
    chainCode,
    divisionCode,
    defaultBankAccount,
    creditDeposits,
    creditChargebacks,
    creditProcessingFees,
    creditConvenienceFees,
    debitDeposits,
    debitChargebacks,
    debitConvenienceFees,
  } = values

  const optionalProcessorSpecificParameters = optionalProcessorParamsAllowed ? {
    chain_code: chainCode,
    division_code: divisionCode,
    settlement_accounts: {
      default_bank_account: get(defaultBankAccount, 'value.id'),
      credit_deposits: get(creditDeposits, 'value.id'),
      debit_deposits: get(debitDeposits, 'value.id'),
      credit_chargebacks: get(creditChargebacks, 'value.id'),
      debit_chargebacks: get(debitChargebacks, 'value.id'),
      credit_processing_fees: get(creditProcessingFees, 'value.id'),
      credit_convenience_fees: get(creditConvenienceFees, 'value.id'),
      debit_convenience_fees: get(debitConvenienceFees, 'value.id'),
    },
  } : undefined

  const action = removeUndefined({
    type: POST_MERCHANT_VERIFICATIONS_F_REQUEST,
    payload: {
      values: {
        merchant: merchantId,
        processor_specific_parameters: optionalProcessorSpecificParameters,
      },
      credentials,
    },
    meta: {
      selectorId: merchantId,
      actionId: sendAmplitudeActionEvent(CREATE_MERCHANT_VERIFICATION, {
        credentials,
        merchantId,
      }),
    },
  })

  dispatch(action)
}

export default submitCreateMerchantVerificationForm
