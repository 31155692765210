import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from './Notifications'
import getResourceLink from 'utilities/get/getResourceLink'
import { FETCHING } from 'constants/reducerConstants'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST } from 'constants/flowConstants'
import { CLICK_ON_NOTIFICATION } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const initialQueries = {
  include_seen: true,
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `notificationsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {
    isFetching,
    initialQueries,
    credentials,
    credentialId,
  }
}

class NotificationsC extends Component {
  clickNotificationAction = (data) => {
    const { dispatch, credentialId, credentials } = this.props

    const [
      resourceType,
      resourceId,
      notificationId,
      merchantId,
    ] = getMany(data, [
      'resourceType',
      'resourceId',
      'id',
      'merchantId',
    ])

    // reroute the user to the resource related to the notification
    const path = getResourceLink({ resourceId, resourceType, credentialId, merchantId })
    if (path) dispatch(push(path))

    // mark the notification as read
    dispatch({
      type: POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST,
      payload: {
        values: { ids: [notificationId] },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(CLICK_ON_NOTIFICATION, {
          credentials,
        }),
      },
    })
  }

  render() {
    return (
      <Notifications
        clickNotificationAction={this.clickNotificationAction}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(NotificationsC)
