import React from 'react'
import PropTypes from 'prop-types'
import DictionaryList from 'components/Customer/Shared/Page/DictionaryList/DictionaryList'
import { ALERT_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import size from 'lodash/size'
import chunk from 'lodash/chunk'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import filter from 'lodash/filter'

const DataSection = ({
  data = [],
  title = '',
  emptyMessage = '',
}) => {
  const filteredData = filter(data, ({ condition = true }) => condition === true)
  const formattedEmptyMessage = emptyMessage || `No ${title} Provided`
  const middleIndex = Math.ceil(size(filteredData) / 2)
  const dataChunks = chunk(filteredData, middleIndex)
  const firstColumn = get(dataChunks, '0', [])
  const secondColumn = get(dataChunks, '1', [])
  const allDataMissing = every(filteredData, ({ value }) => isNil(value))

  return (
    <>
      { allDataMissing && <span className='emptyMessage'><i className={`fa fa-${ALERT_ICON}`} />{formattedEmptyMessage}</span> }

      { !allDataMissing && (
        <>
          <DictionaryList data={firstColumn} />
          <DictionaryList data={secondColumn} />
        </>
      )}
    </>
  )
}

DataSection.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  emptyMessage: PropTypes.string,
}

export default DataSection
