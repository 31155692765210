import axios from 'axios'
import merge from 'lodash/merge'
import createErrorResponse from 'utilities/api/createErrorResponse'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import removeUndefined from 'utilities/remove/removeUndefined'

export const AXIOS_CONFIG = {}

const axiosConfig = ({ config }) => merge({}, AXIOS_CONFIG, config)

const API = {
  async get (url, config = {}) {
    try {
      const response = await axios.get(url, axiosConfig({ config }))

      return createSuccessResponse(response)
    } catch (error) {
      return createErrorResponse({ error })
    }
  },

  async post (url, payload = {}, config = {}) {
    try {
      const response = await axios.post(url, payload, axiosConfig({ config }))

      return createSuccessResponse(response)
    } catch (error) {
      return createErrorResponse({ error })
    }
  },

  async patch (url, payload = {}, config = {}) {
    try {
      const response = await axios.patch(url, payload, axiosConfig({ config }))

      return createSuccessResponse(response)
    } catch (error) {
      return createErrorResponse({ error })
    }
  },

  async put (url, payload = {}, config = {}) {
    try {
      const response = await axios.put(url, payload, axiosConfig({ config }))

      return createSuccessResponse(response)
    } catch (error) {
      return createErrorResponse({ error })
    }
  },

  async delete (url, payload, config = {}) {
    try {
      const mergedConfig = removeUndefined(merge({}, AXIOS_CONFIG, config, { data: payload }))

      const { data } = await axios.delete(url, mergedConfig)

      return createSuccessResponse({ data })
    } catch (error) {
      return createErrorResponse({ error })
    }
  },
}

export default API
