import './WarningMessageS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { DO_NOT_ENTER } from 'constants/iconConstants'

const WarningMessage = ({
  warningMessage = '',
  icon = DO_NOT_ENTER,
}) => {
  return warningMessage && (
    <div className='WarningMessage flex'>
      <i className={`icon fa fa-${icon}`} />
      <div className='warning-message-text'>{warningMessage}</div>
    </div>
  )
}

WarningMessage.propTypes = {
  warningMessage: PropTypes.string,
}

export default WarningMessage
