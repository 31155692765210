import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddFunds from './AddFunds'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { COPY } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  BENEFICIARY_COUNTRY,
  BANK_ACCOUNT_NAME,
  getEnvironmentRoutingNumber,
  getEnvironmentAccountNumber,
  getEnvironmentBankName,
  getEnvironmentBankAddress,
} from 'constants/bankConstants'

import {
  BENEFICIARY,
  NAME,
  COUNTRY,
  RECEIVING_BANK_DETAILS,
  ACCOUNT_NAME,
  ROUTING_NUMBER,
  BANK_NAME,
  BANK_ADDRESS,
  ACCOUNT_NUMBER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const environment = get(credentials, 'environment')
  const routingNumber = getEnvironmentRoutingNumber(environment)
  const accountNumber = getEnvironmentAccountNumber(environment)
  const bankName = getEnvironmentBankName(environment)
  const bankAddress = getEnvironmentBankAddress(environment)

  const addingFundsData = [
    {
      largeTitle: BENEFICIARY,
      data: [
        {
          label: NAME,
          value: BANK_ACCOUNT_NAME,
        },
        {
          label: COUNTRY,
          value: BENEFICIARY_COUNTRY,
        },
      ],
    },
    {
      largeTitle: RECEIVING_BANK_DETAILS,
      data: [
        {
          label: ACCOUNT_NAME,
          value: BANK_ACCOUNT_NAME,
        },
        {
          label: BANK_NAME,
          value: bankName,
        },
        {
          label: BANK_ADDRESS,
          value: bankAddress,
        },
        {
          label: ROUTING_NUMBER,
          value: <div className='flex'>{routingNumber}<ClickToCopyC customView={<i className={`far fa-${COPY}`} />} content={routingNumber} /></div>,
        },
        {
          label: ACCOUNT_NUMBER,
          value: <div className='flex'>{accountNumber}<ClickToCopyC customView={<i className={`far fa-${COPY}`} />} content={accountNumber} /></div>,
        },
      ],
    },
  ]

  return {
    credentials,
    credentialId,
    addingFundsData,
  }
}

class AddFundsC extends Component {
  render() {
    return (
      <AddFunds
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AddFundsC)
