import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import ApplicationComplianceFormsC from 'components/Customer/Pages/Application/ApplicationCompliance/ApplicationComplianceForms/ApplicationComplianceFormsC'
import ComplianceFormSettingsC from 'components/Customer/Pages/Compliance/ComplianceSettings/ComplianceSettingsC'

import {
  COMPLIANCE_FORMS_RESOURCE_TITLE,
  SETTINGS,
} from 'constants/language/languageConstants'

class ApplicationComplianceTabs extends Component {
  render() {
    const {
      applicationId,
    } = this.props

    const tabs = [
      {
        id: 'forms',
        name: COMPLIANCE_FORMS_RESOURCE_TITLE,
        component: ApplicationComplianceFormsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'settings',
        name: SETTINGS,
        component: ComplianceFormSettingsC,
        props: {
          applicationId,
        },
      },
    ]

    return (
      <div className='ApplicationComplianceTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationComplianceTabs
