import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import validateCreateFeeForm from 'utilities/validate/validateCreateFeeForm'
import submitCreateFeeForm from 'utilities/submit/submitCreateFeeForm'
import selectOption from 'utilities/forms/selectOption'
import { CREATE_FEE_FORM } from 'constants/formConstants'
import { CREATE } from 'constants/language/languageConstants'
import map from 'lodash/map'

const CreateFeeForm = ({
  handleSubmit = () => {},
  submitting = false,
  identityMerchants = [],
}) => {
  const merchantOptions = [selectOption('Select Merchant', '')].concat(map(identityMerchants, ({ id, name }) => selectOption(`${name} - ${id}`, id)))

  return (
    <form className='CreateFeeForm' onSubmit={handleSubmit(submitCreateFeeForm)}>
      <div className='section'>
        <AmountField
          name='amount'
          label='Amount'
        />

        <Field
          name='merchant'
          label='Merchant'
          component={SelectField}
          options={merchantOptions}
        />

        <Field
          name='label'
          label='Label'
          component={InputField}
        />

        <TagsField
          name='tags'
          label='Tags'
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label={CREATE} submitting={submitting} />
      </div>
    </form>
  )
}

CreateFeeForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  identityMerchants: PropTypes.array,
}

export default reduxForm({
  form: CREATE_FEE_FORM,
  validate: validateCreateFeeForm,
})(CreateFeeForm)
