// import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  EMAIL_ADDRESS,
  MERCHANT_CATEGORY_CODE,
} from 'constants/language/languageConstants'

import {
  MCC_REGEX,
  EMAIL_REGEX,
  WEBSITE_REGEX,
  AMEX_MID_REGEX,
  DISCOVER_MID_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  nonEmptyNumber,
  invalidRegex,
  invalidOrEmptyRegex,
  validateDate,
  validateAmount,
  validateTaxId,
  validateAddress,
  validatePercentage,
  validateDefaultStatementDescriptor,
} from 'utilities/validate'

const validateIdentityForm = (values) => {
  const {
    businessName,
    businessType,
    doingBusinessAs,
    title,
    firstName,
    lastName,
    email,
    phone,
    principalPercentageOwnership,
    businessPhone,
    businessAddress,
    personalAddress,
    dateOfBirth,
    incorporationDate,
    annualCardVolume,
    maxTransactionAmount,
    mcc,
    defaultStatementDescriptor,
    ownershipType,
    website,
    taxId,
    businessTaxId,
    taxAuthority,
    amexMid,
    discoverMid,
  } = values

  // TODO: look into why the phone library is not working properly
  const phoneEmpty = fieldEmpty(phone, 'Phone')
  const phoneErrors = phoneEmpty

  const businessPhoneEmpty = fieldEmpty(businessPhone, 'Business Phone')
  const businessPhoneErrors = businessPhoneEmpty

  return {
    businessName: fieldEmpty(businessName, 'Business Name'),
    businessType: fieldEmpty(businessType, 'Business Type'),
    doingBusinessAs: fieldEmpty(doingBusinessAs, 'Doing Business As'),
    // title: fieldEmpty(title, 'Title'),
    // firstName: fieldEmpty(firstName, 'First Name'),
    // lastName: fieldEmpty(lastName, 'Last Name'),
    // email: invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
    // phone: phoneErrors,
    businessPhone: businessPhoneErrors,
    businessAddress: validateAddress({ address: businessAddress }),
    // personalAddress: validateAddress({ address: personalAddress }),
    // dateOfBirth: validateDate({ date: dateOfBirth }),
    // incorporationDate: validateDate({ date: incorporationDate }),
    // principalPercentageOwnership: principalPercentageOwnershipErrors,
    annualCardVolume: nonEmptyNumber({ field: annualCardVolume, name: 'Annual Card Volume' }),
    maxTransactionAmount: nonEmptyNumber({ field: maxTransactionAmount, name: 'Max Transaction Amount' }),
    // mcc: invalidOrEmptyRegex({ field: mcc, name: MERCHANT_CATEGORY_CODE, regex: MCC_REGEX }),
    defaultStatementDescriptor: validateDefaultStatementDescriptor({ descriptor: defaultStatementDescriptor, name: 'Default Statement Descriptor' }),
    // ownershipType: fieldEmpty(ownershipType, 'Ownership Type'),
    // website: invalidOrEmptyRegex({ field: website, name: 'Website', regex: WEBSITE_REGEX }),
    taxId: taxId && validateTaxId({ taxId, name: 'Tax ID' }),
    businessTaxId: businessTaxId && validateTaxId({ taxId: businessTaxId, name: 'Business Tax ID' }),
    taxAuthority: mcc === '9311' && fieldEmpty(taxAuthority, 'Tax Authority'),
    amexMid: invalidRegex({ field: amexMid, name: 'Amex MID', regex: AMEX_MID_REGEX }),
    discoverMid: invalidRegex({ field: discoverMid, name: 'Discover MID', regex: DISCOVER_MID_REGEX }),
  }
}

export default validateIdentityForm
