import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'

import {
  DASHBOARD_SERVICE_USERS,
  DASHBOARD_SERVICE_USERS_V2,
} from 'constants/apiConstants'

const getDashboardUsersAPI = ({ queries, meta }) => {
  const dashboardId = getCurrentDashboardId()
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_USERS_V2({ dashboardId }) : DASHBOARD_SERVICE_USERS

  return dashboardAPI.get({
    meta,
    path,
    queries,
  })
}

export default getDashboardUsersAPI
