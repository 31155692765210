import React from 'react'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import column from 'utilities/table/column'
import { DASHBOARD_TYPE_BE_TO_LABEL_MAP } from 'constants/dashboardConfigurationsConstants'

import {
  ID,
  BUSINESS_NAME,
  SUBMITTED_BY,
  SUBMITTED_ON,
  UPDATED_ON,
  ASSIGNEE,
  UNDERWRITING_STATUS,
  CUSTOMER_PROFILE_TYPE,
  TYPE,
  COUNTRY,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(SUBMITTED_ON, 'displaySubmittedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={SUBMITTED_ON} />,
    formatter: ({ submittedOn }) => <DateTimestampColumn timestamp={submittedOn} />,
  }),
  column(UPDATED_ON, 'displayUpdatedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedOn }) => <DateTimestampColumn timestamp={updatedOn} />,
  }),
  column(BUSINESS_NAME, 'businessName', {
    headerFormatter: () => <TableItemSubtitle title={BUSINESS_NAME} subtitle={CUSTOMER_PROFILE_TYPE} />,
    formatter: ({ businessName, customerType }) => <TableItemSubtitle title={businessName} subtitle={DASHBOARD_TYPE_BE_TO_LABEL_MAP[customerType]} />,
    className: 'medium',
  }),
  column(ASSIGNEE, 'assignee', { className: 'medium divider' }),
  column(UNDERWRITING_STATUS, 'state', {
    className: 'medium',
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
  column(TYPE, 'displayAccessFormType', {
    headerFormatter: () => <TableItemSubtitle title={TYPE} subtitle={SUBMITTED_BY} />,
    formatter: ({ displayAccessFormType, submittedBy }) => <TableItemSubtitle title={displayAccessFormType} subtitle={submittedBy} />,
    className: 'small',
  }),
  column(COUNTRY, 'displayCountry', { className: 'small' }),
]

export default columnDescriptors
