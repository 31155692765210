import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerOnboardingFormReviewForm from 'components/Customer/Forms/CustomerOnboardingFormReview/CustomerOnboardingFormReviewForm'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatNumber from 'utilities/formatters/formatNumber'
import formatPercentage from 'utilities/formatters/formatPercentage'
import formatMoney from 'utilities/formatters/formatMoney'
import { STANDALONE_MERCHANT } from 'constants/dashboardConfigurationsConstants'
import { countryNameByCountryCode } from 'constants/countryConstants'
import get from 'lodash/get'
import join from 'lodash/join'
import map from 'lodash/map'

import {
  COUNTRY_TO_CURRENCY_NAME_MAP,
  USD,
} from 'constants/currencyConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
} from 'constants/labelConstants'

import {
  ACCOUNT_EXECUTIVE,
  ADDRESS,
  ANNUAL_CARD_VOLUME,
  ANNUAL_TRANSACTION_COUNT,
  BUSINESS_NAME,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  COUNTRY,
  CUSTOMER_PROFILE,
  DOING_BUSINESS_AS,
  EMAIL_ADDRESS,
  IN_PERSON_PAYMENTS,
  MAX_CARD_TRANSACTION_AMOUNT,
  MERCHANT_CATEGORY_CODE,
  MERCHANT_COUNT,
  ONLINE_PAYMENTS,
  OPPORTUNITY_NAME,
  OTHER,
  PHONE_NUMBER,
  SUBDOMAINS,
  WEBSITE,
  HUBSPOT_DEAL_ID,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  // get form values for the firs two steps for the review page
  const dealInfoValues = get(state, 'flowsR.formValues.dealInfo')
  const customerInfoValues = get(state, 'flowsR.formValues.customerInfo')

  const [
    accountExecutive,
    hubspotDealId,
    opportunityName,
    customerProfile,
    onboardingFormCountry,
  ] = getMany(dealInfoValues, [
    'accountExecutive.value',
    'hubspotDealId',
    'opportunityName',
    'customerProfile.value',
    'country',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry] || USD

  const [
    mccCodes,
    annualTransactionCount,
    annualCardVolume,
    annualACHVolume,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
    businessName,
    doingBusinessAs,
    businessWebsite,
    businessWebsiteDomains,
    businessEmail,
    businessPhone,
    businessMerchantCount,
    businessAddress,
  ] = getMany(customerInfoValues, [
    'mccCode',
    'annualTransactionCount',
    'annualCardVolume',
    'annualACHVolume',
    'maxCardTransactionAmount',
    'maxACHTransactionAmount',
    'ecommercePercentage',
    'cardPresentPercentage',
    'businessToBusinessVolumePercentage',
    'businessToConsumerVolumePercentage',
    'otherVolumePercentage',
    'businessName',
    'doingBusinessAs',
    'businessWebsite',
    'businessWebsiteDomains',
    'businessEmail',
    'businessPhone',
    'businessMerchantCount',
    'businessAddress',
  ])

  const formattedMccCodes = map(mccCodes, (code) => { return code.value })
  const isStandaloneMerchant = customerProfile === STANDALONE_MERCHANT

  const dealInformationDataSection = convertPageSectionDataToV2([
    { label: ACCOUNT_EXECUTIVE, value: accountExecutive },
    { label: HUBSPOT_DEAL_ID, value: hubspotDealId },
    { label: OPPORTUNITY_NAME, value: opportunityName },
    { label: COUNTRY, value: countryNameByCountryCode[onboardingFormCountry] },
    { label: CUSTOMER_PROFILE, value: snakeCaseToTitleCase({ key: customerProfile }) },
  ], 1)

  const businessInfoDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_NAME, value: businessName },
    { label: DOING_BUSINESS_AS, value: doingBusinessAs },
    { label: MERCHANT_COUNT, value: businessMerchantCount, condition: !isStandaloneMerchant },
    { label: WEBSITE, value: businessWebsite },
    { label: SUBDOMAINS, value: businessWebsiteDomains },
    { label: EMAIL_ADDRESS, value: businessEmail },
    { label: PHONE_NUMBER, value: formatPhone(businessPhone) },
    { label: ADDRESS, value: get(businessAddress, 'line1') ? <Address address={businessAddress} /> : '-' },
  ], 1)

  const processingInfoDataSection = convertPageSectionDataToV2([
    { label: MERCHANT_CATEGORY_CODE, value: join(formattedMccCodes, ', ') },
    { label: ANNUAL_TRANSACTION_COUNT, value: annualTransactionCount ? formatNumber(annualTransactionCount) : '-' },
    { label: ANNUAL_CARD_VOLUME, value: formatMoney({ amount: annualCardVolume, currency, showCurrencyCode: true }) },
    { label: annualACHVolumeLabelMap(onboardingFormCountry), value: formatMoney({ amount: annualACHVolume, currency, showCurrencyCode: true }) },
    { label: MAX_CARD_TRANSACTION_AMOUNT, value: formatMoney({ amount: maxCardTransactionAmount, currency, showCurrencyCode: true }) },
    { label: achMaxTransactionAmountLabelMap(onboardingFormCountry), value: formatMoney({ amount: maxACHTransactionAmount, currency, showCurrencyCode: true }) },
  ], 1)

  const cardVolumeDataSection = convertPageSectionDataToV2([
    { label: ONLINE_PAYMENTS, value: ecommercePercentage ? formatPercentage({ percentage: ecommercePercentage }) : null },
    { label: IN_PERSON_PAYMENTS, value: cardPresentPercentage ? formatPercentage({ percentage: cardPresentPercentage }) : null },
  ], 1)

  const paymentVolumeDataSection = convertPageSectionDataToV2([
    { label: BUSINESS_TO_BUSINESS, value: businessToBusinessVolumePercentage ? formatPercentage({ percentage: businessToBusinessVolumePercentage }) : null },
    { label: BUSINESS_TO_CONSUMER, value: businessToConsumerVolumePercentage ? formatPercentage({ percentage: businessToConsumerVolumePercentage }) : null },
    { label: OTHER, value: otherVolumePercentage ? formatPercentage({ percentage: otherVolumePercentage }) : null },
  ], 1)

  return {
    credentials,
    credentialId,
    dealInformationDataSection,
    businessInfoDataSection,
    processingInfoDataSection,
    cardVolumeDataSection,
    paymentVolumeDataSection,
  }
}

class CustomerOnboardingFormReviewFormC extends Component {
  render() {
    return (
      <CustomerOnboardingFormReviewForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(CustomerOnboardingFormReviewFormC)
