import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import SendPayoutRecipientForm from './SendPayoutRecipientForm'
import Address from 'components/Customer/Shared/Display/Address/Address'
import formatAddress from 'utilities/formatters/formatAddress'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import { EXIT_THIS_FLOW_MODAL } from 'constants/modalConstants'
import { SEND_PAYOUT_RECIPIENT_FORM } from 'constants/formConstants'
import { getCardBrand } from 'constants/bankConstants'
import { BANK_ICON } from 'constants/iconConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import {
  FINIX_V1,
  DUMMY_V1,
} from 'constants/processorConstants'

import {
  GET_PAYMENT_INSTRUMENTS_F_REQUEST,
  GET_RECIPIENT_IDENTITIES_F_REQUEST,
} from 'constants/flowConstants'

import {
  getIdentitiesSelector,
  getIdentitySelector,
  getPaymentInstrumentSelector,
  getPaymentInstrumentsSelector,
  getRecipientCurrentUsagesByLinkedTo,
} from 'state-layer/selectors'

import {
  BE_BANK_ACCOUNT,
  BE_PAYMENT_CARD,
} from 'constants/paymentInstrumentConstants'

import {
  EMAIL,
  NAME,
  PHONE,
  ADDRESS,
  ACCOUNT_TYPE,
  ACCOUNT_NUMBER,
  COUNTRY,
  CARD_NUMBER,
  EXPIRY,
  ZIP_CODE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const currentProcessor = isProductionEnv ? FINIX_V1 : DUMMY_V1
  const formSelector = formValueSelector(SEND_PAYOUT_RECIPIENT_FORM)
  const applicationCurrentUsages = get(props, 'applicationCurrentUsages')
  const showWarningMessage = get(applicationCurrentUsages, 'cannotSendMoney', false)
  const warningMessageData = get(applicationCurrentUsages, 'cannotSendMoneyInfo')

  // get selected recipient and payment instrument to show more data in the form
  const selectedRecipientId = formSelector(state, 'identityId.value')
  const selectedRecipient = getIdentitySelector(state, selectedRecipientId)
  const selectedRecipientMerchantId = get(find(get(selectedRecipient, 'merchants'), ({ processor }) => processor === currentProcessor), 'id')
  const selectedRecipientCurrentUsages = getRecipientCurrentUsagesByLinkedTo(state, selectedRecipientMerchantId)

  const selectedRecipientData = convertPageSectionDataToV2([
    {
      label: NAME,
      value: get(selectedRecipient, 'businessName') ? get(selectedRecipient, 'businessName') : get(selectedRecipient, 'fullName'),
    },
    {
      label: EMAIL,
      value: get(selectedRecipient, 'email'),
    },
    {
      label: PHONE,
      value: get(selectedRecipient, 'businessName') ? get(selectedRecipient, 'businessPhone') : get(selectedRecipient, 'phone'),
    },
    {
      label: ADDRESS,
      value: get(selectedRecipient, 'businessName') ? <Address address={get(selectedRecipient, 'businessAddress')} /> : <Address address={get(selectedRecipient, 'personalAddress')} />,
    },
  ], 1)

  const selectedPaymentInstrumentId = formSelector(state, 'paymentInstrumentId.value')
  const selectedPaymentInstrument = getPaymentInstrumentSelector(state, selectedPaymentInstrumentId)
  const isBankAccount = get(selectedPaymentInstrument, 'instrumentType') === BE_BANK_ACCOUNT
  const isPaymentCard = get(selectedPaymentInstrument, 'instrumentType') === BE_PAYMENT_CARD
  const cardBrandIcon = getCardBrand(get(selectedPaymentInstrument, 'brand'))

  const selectedPaymentInstrumentData = convertPageSectionDataToV2([
    {
      label: NAME,
      value: get(selectedPaymentInstrument, 'name'),
    },
    {
      label: ACCOUNT_TYPE,
      value: get(selectedPaymentInstrument, 'displayAccountType'),
      condition: isBankAccount,
    },
    {
      label: ACCOUNT_NUMBER,
      value: get(selectedPaymentInstrument, 'maskedAccountNumber'),
      condition: isBankAccount,
    },
    {
      label: COUNTRY,
      value: get(selectedPaymentInstrument, 'displayCountry'),
      condition: isBankAccount,
    },
    {
      label: CARD_NUMBER,
      value: <div className='flex items-center'><i className={cardBrandIcon} /><div>&nbsp;{get(selectedPaymentInstrument, 'maskedFullCardNumber')}</div></div>,
      condition: isPaymentCard,
    },
    {
      label: EXPIRY,
      value: get(selectedPaymentInstrument, 'expirationDate'),
      condition: isPaymentCard,
    },
    {
      label: ZIP_CODE,
      value: get(selectedPaymentInstrument, 'address.postalCode'),
      condition: isPaymentCard,
    },
    {
      label: COUNTRY,
      value: get(selectedPaymentInstrument, 'address.country'),
      condition: isPaymentCard,
    },
  ], 1)

  // get list of recipients for dropdown
  const identities = getIdentitiesSelector(state)
  const underwrittenRecipients = filter(identities, (identity) => {
    const merchants = get(identity, 'merchants')
    const currentMerchant = find(merchants, { processor: currentProcessor })
    return get(identity, 'identityRole') === 'RECIPIENT' && get(currentMerchant, 'onboardingState') === 'APPROVED'
  })

  const recipientNameOptions = map(underwrittenRecipients, (recipient) => {
    const {
      id,
      businessName,
      fullName,
      businessAddress,
      personalAddress,
    } = recipient

    const isBusinessRecipient = !!businessName
    const name = isBusinessRecipient ? businessName : fullName
    const address = isBusinessRecipient ? businessAddress : personalAddress

    return {
      label: (
        <>
          <div className='primary'>{name}</div>
          <div className='secondary'>{formatAddress({ address })}</div>
        </>
      ),
      value: id,
      data: {
        name,
      },
    }
  })

  // get list of payment instruments for dropdown
  const recipientPaymentInstruments = getPaymentInstrumentsSelector(state)
  const filteredRecipientPaymentInstruments = filter(recipientPaymentInstruments, (paymentInstrument) => {
    const { instrumentType } = paymentInstrument
    let allowedInstrumentTypes = [BE_BANK_ACCOUNT, BE_PAYMENT_CARD]
    if (showWarningMessage) {
      allowedInstrumentTypes = !get(applicationCurrentUsages, 'canSendACHPayout') ? [BE_PAYMENT_CARD] : [BE_BANK_ACCOUNT]
    }
    return includes(allowedInstrumentTypes, instrumentType)
  })

  const recipientPaymentInstrumentOptions = map(filteredRecipientPaymentInstruments, (paymentInstrument) => {
    const {
      id,
      instrumentType,
      brand,
      expirationDate,
      maskedFullCardNumber,
      maskedAccountNumber,
    } = paymentInstrument

    const cardIcon = getCardBrand(brand)

    return {
      label: instrumentType === BE_BANK_ACCOUNT ? (
        <div className='flex items-center'>
          <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
          {maskedAccountNumber}
        </div>
      ) : (
        <div className='flex items-center'>
          <i className={`card-brand-icon ${cardIcon}`} />
          {maskedFullCardNumber}
          <span className='secondary-expiry-date'>({expirationDate})</span>
        </div>
      ),
      value: id,
    }
  })

  return {
    credentialId,
    isFetching: isEmpty(applicationCurrentUsages) && isEmpty(identities),
    selectedRecipient,
    selectedRecipientId,
    selectedPaymentInstrument,
    selectedPaymentInstrumentId,
    recipientNameOptions,
    recipientPaymentInstrumentOptions,
    selectedRecipientData,
    selectedPaymentInstrumentData,
    showWarningMessage,
    warningMessageData,
    selectedRecipientMerchantId,
    selectedRecipientCurrentUsages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationIdentities: ({ applicationId, credentials }) => dispatch({
      type: GET_RECIPIENT_IDENTITIES_F_REQUEST,
      payload: {
        values: {
          applicationId,
        },
        credentials,
        queries: {
          limit: 200,
        },
      },
    }),
    getRecipientPaymentInstruments: ({ credentials, queries, meta }) => dispatch({
      type: GET_PAYMENT_INSTRUMENTS_F_REQUEST,
      payload: {
        credentials,
        queries,
      },
      meta,
    }),
    getCurrentUsages: ({ credentials, entityId, currency }) => dispatch(getCurrentUsagesRequest({ credentials, entityId, currency })),
    updateFormField: ({ value, field }) => dispatch(change(SEND_PAYOUT_RECIPIENT_FORM, field, value)),
    clearReducer: (reducerNames) => dispatch(clearReducerRequest(reducerNames)),
    showExitThisFlowModal: ({ credentialId, isRecipientFlow }) => dispatch(showModalAction({ modalType: EXIT_THIS_FLOW_MODAL, modalProps: { credentialId, isRecipientFlow }, className: 'modal-sm no-pad' })),
  }
}

class SendPayoutRecipientFormC extends Component {
  componentDidMount() {
    const { applicationId, getApplicationIdentities, credentials } = this.props

    getApplicationIdentities({
      applicationId,
      credentials,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedRecipientId,
      getRecipientPaymentInstruments,
      credentials,
      updateFormField,
      getCurrentUsages,
      selectedRecipient,
      selectedRecipientMerchantId,
    } = this.props

    const { selectedRecipientId: prevSelectedRecipientId } = prevProps

    if (selectedRecipientId && selectedRecipientId !== prevSelectedRecipientId) {
      if (prevSelectedRecipientId) {
        updateFormField({ field: 'paymentInstrumentId', value: null })
      }

      getRecipientPaymentInstruments({
        credentials,
        queries: {
          owner_identity_id: selectedRecipientId,
        },
        meta: {
          overwriteReducer: true,
        },
      })

      getCurrentUsages({ credentials, entityId: selectedRecipientMerchantId, currency: 'USD' })
    }
  }

  render() {
    const noRecipientOptionsMessage = () => {
      const { showExitThisFlowModal, credentialId } = this.props
      return (
        <div>
          No recipients found. Please <a className='text-link' onClick={() => showExitThisFlowModal({ credentialId })}>add a recipient</a> before proceeding.
        </div>
      )
    }

    const noPaymentInstrumentOptionsMessage = () => {
      const { showExitThisFlowModal, credentialId } = this.props
      return (
        <div>
          No payment instruments found. Please <a className='text-link' onClick={() => showExitThisFlowModal({ credentialId, isRecipientFlow: false })}>add a payment instrument</a> before proceeding.
        </div>
      )
    }

    return (
      <SendPayoutRecipientForm
        {...this.props}
        noRecipientOptionsMessage={noRecipientOptionsMessage}
        noPaymentInstrumentOptionsMessage={noPaymentInstrumentOptionsMessage}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPayoutRecipientFormC)
