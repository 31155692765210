import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

import {
  CONTINUOUS_MONITORING_LABELS,
  RECIPIENT_SETTINGS_LABELS,
} from 'constants/underwritingProfileConstants'

const frontendUnderwritingProfileModel = ({ data: underwritingProfile }) => {
  const [
    id,
    createdAt,
    updatedAt,
    linkedTo,
    linkedType,
    maxTransactionAmount,
    achMaxTransactionAmount,
    mccDetails,
    biometricDataCollection,
    adverseMediaCadence,
    sanctionsScreeningCadence,
    businessRecipientsEnabled,
    personalRecipientsEnabled,
  ] = getMany(underwritingProfile, [
    'id',
    'created_at',
    'updated_at',
    'linked_to',
    'linked_type',
    'max_transaction_amount',
    'ach_max_transaction_amount',
    'mcc_details',
    'biometric_data_collection',
    'continuous_monitoring_details.adverse_media_cadence',
    'continuous_monitoring_details.ofac_cadence',
    'recipient_settings.business_recipients_enabled',
    'recipient_settings.personal_recipients_enabled',
  ])

  const [
    allowAllMCCs,
    enabledMCCs,
  ] = getMany(mccDetails, [
    'allow_all_mccs',
    'enabled_mccs',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    linkedTo,
    linkedType,
    maxTransactionAmount,
    displayMaxTransactionAmount: formatMoney({ amount: maxTransactionAmount, showCurrencyCode: true }),
    achMaxTransactionAmount,
    displayAchMaxTransactionAmount: formatMoney({ amount: achMaxTransactionAmount, showCurrencyCode: true }),
    mccDetails: {
      allowAllMCCs,
      enabledMCCs,
    },
    biometricDataCollection,
    displayBiometricDataCollection: biometricDataCollection ? snakeCaseToTitleCase({ key: biometricDataCollection }) : undefined,
    adverseMediaCadence,
    displayAdverseMediaCadence: CONTINUOUS_MONITORING_LABELS[adverseMediaCadence],
    sanctionsScreeningCadence,
    displaySanctionsScreeningCadence: CONTINUOUS_MONITORING_LABELS[sanctionsScreeningCadence],
    businessRecipientsEnabled,
    displayBusinessRecipientsEnabled: RECIPIENT_SETTINGS_LABELS[businessRecipientsEnabled],
    personalRecipientsEnabled,
    displayPersonalRecipientsEnabled: RECIPIENT_SETTINGS_LABELS[personalRecipientsEnabled],
  })
}

export default frontendUnderwritingProfileModel
