import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import WebhookLog from './WebhookLog'
import { getWebhookLogSelector } from 'state-layer/selectors'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getWebhookLogRequest from 'utilities/actions/get/getWebhookLogRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany, { getManyCaseInsensitive } from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { httpCodeStatusMap } from 'constants/statusConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import size from 'lodash/size'

import {
  APPLICATION_PATH,
  WEBHOOK_PATH,
} from 'constants/pathConstants'

import {
  CREATED_ON,
  URL,
  STATE,
  AUTHORIZATION_TYPE,
  UPDATED_ON,
  WEBHOOK_EVENT_ATTEMPT,
  HTTP_STATUS,
  ATTEMPT_NUMBER,
  NICKNAME,
  VIEW_WEBHOOK,
  APPLICATION,
  NONE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `webhookLogsR.${FETCHING}`)
  const webhookLogId = get(props, 'params.webhookLogId')
  const webhookLog = getWebhookLogSelector(state, webhookLogId)

  const [
    id,
    displayEntityType,
    displayEventType,
    responseStatusCode,
    displayCreatedAt,
    url,
    attemptCount,
    webhook,
    webhookEventHeadersObj,
    eventId,
  ] = getMany(webhookLog, [
    'id',
    'displayEntityType',
    'displayEventType',
    'responseStatusCode',
    'displayCreatedAt',
    'url',
    'attemptCount',
    'webhook',
    'webhookEventHeadersObj',
    'eventId',
  ])

  const [
    webhookId,
    enabledStatus,
    webhookCreatedAt,
    webhookUpdatedOn,
    nickname,
    authorizationType,
    application,
  ] = getMany(webhook, [
    'id',
    'enabledStatus',
    'displayCreatedAt',
    'displayUpdatedAt',
    'nickname',
    'displayAuthenticationType',
    'application',
  ])

  const applicationId = get(props, 'params.applicationId', get(application, 'id'))
  const webhookLink = <Link className='text-link view-webhook-link' to={WEBHOOK_PATH({ credentialId, webhookId })}>{VIEW_WEBHOOK}</Link>
  const businessName = get(application, 'businessName')

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: businessName,
        path: APPLICATION_PATH({ credentialId, applicationId }),
        condition: isRolePlatform({ credentials }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: WEBHOOK_EVENT_ATTEMPT,
      id,
    },
    items: [
      {
        value: <h1>{`${displayEntityType} (${displayEventType})`}</h1>,
      },
      {
        label: HTTP_STATUS,
        value: <ColorcodedStatus
          value={size(responseStatusCode) > 0 ? responseStatusCode.charAt(0) : ''}
          data={httpCodeStatusMap}
          icon='circle'
          customValue={responseStatusCode}
        />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: URL,
        value: url,
      },
    ],
    isFetching,
  }

  const detailsData = [
    {
      label: ATTEMPT_NUMBER,
      value: attemptCount,
    },
  ]

  const detailsSectionData = convertPageSectionDataToV2(detailsData)

  const webhookData = [
    {
      label: STATE,
      value: <EnabledStatus value={enabledStatus} />,
    },
    {
      label: CREATED_ON,
      value: webhookCreatedAt,
    },
    {
      label: UPDATED_ON,
      value: webhookUpdatedOn,
    },
    {
      label: NICKNAME,
      value: nickname,
    },
    {
      label: AUTHORIZATION_TYPE,
      value: authorizationType,
      condition: authorizationType !== NONE,
    },
  ]

  const webhookSectionData = convertPageSectionDataToV2(webhookData)

  const [
    finixSignature,
    contentType,
    authorization,
  ] = getManyCaseInsensitive(webhookEventHeadersObj, [
    'Finix-Signature',
    'content-type',
    'Authorization',
  ])

  const headersData = [
    {
      label: 'content-type',
      value: contentType,
    },
    {
      label: 'finix-signature',
      value: finixSignature,
    },
    {
      label: 'authorization',
      value: authorization,
      condition: !!authorization,
    },
  ]

  const headersSectionData = convertPageSectionDataToV2(headersData)

  return {
    credentials,
    credentialId,
    isFetching,
    webhookLogId,
    webhookLog,
    contextBarData,
    headerData,
    detailsSectionData,
    webhookSectionData,
    headersSectionData,
    applicationId,
    webhookId,
    webhookLink,
    webhookEventHeadersObj,
    eventId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWebhookLog: ({ credentials, webhookLogId }) => dispatch(getWebhookLogRequest({ credentials, webhookLogId })),
  }
}

class WebhookLogC extends Component {
  componentDidMount() {
    const {
      credentials,
      webhookLogId,
      getWebhookLog,
    } = this.props

    getWebhookLog({ webhookLogId, credentials })
  }

  render() {
    return (
      <WebhookLog
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhookLogC)
