import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import OwnerIdentityVerificationReportV2C from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/OwnerIdentityVerificationReportV2/OwnerIdentityVerificationReportV2C'
import RawResponseC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/RawResponse/RawResponseC'
import get from 'lodash/get'

import {
  REPORT,
  RAW_RESPONSE,
} from 'constants/language/languageConstants'

class OwnerIdentityVerificationReportTabsV2 extends Component {
  render() {
    const report = get(this.props, 'report', {})
    const rawResponse = get(report, 'raw', {})

    const tabs = [
      {
        id: 'report',
        name: REPORT,
        component: OwnerIdentityVerificationReportV2C,
        props: {
          report,
        },
      },
      {
        id: 'raw-response',
        name: RAW_RESPONSE,
        component: RawResponseC,
        props: {
          rawResponse,
        },
      },
    ]

    return (
      <div className='OwnerIdentityVerificationReportTabsV2'>
        <TabsC
          tabs={tabs}
          persistQueries={['subjectId', 'entityId', 'entityType']}
        />
      </div>
    )
  }
}

export default OwnerIdentityVerificationReportTabsV2
