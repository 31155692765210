import './StartLiveAccountApplicationFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { START_LIVE_ACCOUNT_APPLICATION_FORM } from 'constants/formConstants'

import {
  countryOptionsForFlex,
  CAN,
} from 'constants/countryConstants'

import {
  CONTACT_SALES_LINK,
  FINIX_PRIVACY_POLICY_URL,
} from 'constants/urlConstants'

import {
  PRIVACY_POLICY,
  LIVE_ACCOUNT_APPLICATION_FORM_STARTER_MSG,
  LIVE_ACCOUNT_APPLICATION_FORM_DESCRIPTION,
  COUNTRY,
  LET_US_KNOW_WHERE_YOUR_BUSINESS_IS_LOCATED,
} from 'constants/language/languageConstants'

const StartLiveAccountApplicationForm = ({
  startLiveApplication = () => {},
  country,
  actionLabel = '',
  disableStartApplication = false,
}) => {
  return (
    <form className='StartLiveAccountApplicationForm'>
      <h4 className='title'>{LIVE_ACCOUNT_APPLICATION_FORM_STARTER_MSG}</h4>

      <div className='description'>
        {LIVE_ACCOUNT_APPLICATION_FORM_DESCRIPTION}&nbsp;
        <span>
          See our <a onClick={sendAmplitudeActionEvent(FINIX_PRIVACY_POLICY_URL)} className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>{PRIVACY_POLICY}</a> for details.
        </span>
      </div>

      <h6 className='country-title'>{LET_US_KNOW_WHERE_YOUR_BUSINESS_IS_LOCATED}</h6>
      <Field
        name='country'
        label={COUNTRY}
        component={SelectField}
        options={countryOptionsForFlex}
        required
      />

      <div className='flex items-center'>
        {country === CAN ? (
          <a href={CONTACT_SALES_LINK} target='_blank' rel="noreferrer">
            <Button className='start-application-button' disabled={disableStartApplication} label={actionLabel} />
          </a>
        ) : (
          <Button
            className='start-application-button'
            onClick={() => startLiveApplication()}
            disabled={disableStartApplication}
            label={actionLabel}
          />
        )}
      </div>
    </form>
  )
}

StartLiveAccountApplicationForm.propTypes = {
  startLiveApplication: PropTypes.func,
  country: PropTypes.string,
  disableStartApplication: PropTypes.bool,
  actionLabel: PropTypes.string,
}

export default reduxForm({
  form: START_LIVE_ACCOUNT_APPLICATION_FORM,
})(StartLiveAccountApplicationForm)
