import './BusinessIdentityVerificationReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'

import {
  ADDRESS_RECORDS,
  BUSINESS_CONTACTS,
  BUSINESS_INFORMATION,
  NAME_RECORDS,
} from 'constants/language/languageConstants'

const BusinessIdentityVerificationReport = ({
  nameRecordsData = [],
  businessInformationData = [],
  addressRecordsData = [],
  businessContactsData = [],
}) => {
  return (
    <div className='BusinessIdentityVerificationReport'>
      <h6 className='name-records-title'>{NAME_RECORDS}</h6>
      <PageSectionV2C
        columns={convertPageSectionDataToV2(nameRecordsData)}
        className='name-records-section'
      />

      <h6 className='business-information-title'>{BUSINESS_INFORMATION}</h6>
      <PageSectionV2C
        columns={convertPageSectionDataToV2(businessInformationData)}
        className='business-information-section'
      />

      <h6 className='address-records-title'>{ADDRESS_RECORDS}</h6>
      <PageSectionV2C
        columns={convertPageSectionDataToV2(addressRecordsData)}
        className='address-records-section'
      />

      <h6 className='business-contacts-title'>{BUSINESS_CONTACTS}</h6>
      <PageSectionV2C
        columns={convertPageSectionDataToV2(businessContactsData)}
        className='business-contacts-section'
      />
    </div>
  )
}

BusinessIdentityVerificationReport.propTypes = {
  nameRecordsData: PropTypes.array,
  businessInformationData: PropTypes.array,
  addressRecordsData: PropTypes.array,
  businessContactsData: PropTypes.array,
}

export default BusinessIdentityVerificationReport
