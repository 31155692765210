import React from 'react'

const Card = ({
  width = '34',
  height = '24',
  fill = '#4C5B6A',
}) => {
  return (
    <svg
      className='Card'
      width={width}
      height={height}
      viewBox='0 0 34 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.92982 0H15.7193H29.9649C31.864 0 33.4035 1.75944 33.4035 3.92982V19.6491C33.4035 21.8195 31.864 23.5789 29.9649 23.5789H15.7193H3.92982C1.75944 23.5789 0 21.8195 0 19.6491V3.92982C0 1.75944 1.75944 0 3.92982 0ZM15.7193 19.6491H28.131C29.1438 19.6491 29.9649 18.7694 29.9649 17.6842V11.7895H15.7193H3.92982V17.6842C3.92982 18.7694 4.86819 19.6491 6.02573 19.6491H15.7193ZM15.7193 7.85965H29.9649V5.89474C29.9649 4.80955 29.1438 3.92982 28.131 3.92982H15.7193H6.02573C4.86819 3.92982 3.92982 4.80955 3.92982 5.89474V7.85965H15.7193Z'
        fill={fill}
      />
    </svg>
  )
}

export default Card
