import React, { Component } from 'react'

class LogoutC extends Component {
  componentDidMount() {
    const {
      auth,
    } = this.props

    auth.logout()
  }

  render () {
    return (
      <div>Logging out...</div>
    )
  }
}

export default LogoutC
