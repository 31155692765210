import './CreateSubscriptionScheduleAmountModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import CreateSubscriptionScheduleFormC from 'components/Customer/Forms/CreateSubscriptionScheduleForm/CreateSubscriptionScheduleFormC'
import CreateSubscriptionAmountForm from 'components/Customer/Forms/CreateSubscriptionAmountForm/CreateSubscriptionAmountForm'
import CreateSubscriptionConfirmation from 'components/Customer/Pages/CreateSubscriptionConfirmation/CreateSubscriptionConfirmation'
import SubscriptionScheduleDisplayData from 'components/Customer/Forms/CreateSubscriptionScheduleForm/SubscriptionScheduleDisplayData'
import SubscriptionAmountDisplayData from 'components/Customer/Forms/CreateSubscriptionAmountForm/SubscriptionAmountDisplayData'
import GenericModalC from 'components/Customer/Modals/GenericModal/GenericModalC'

import {
  CREATE_SUBSCRIPTION_SCHEDULE_DATA_KEY,
  CREATE_SUBSCRIPTION_AMOUNT_DATA_KEY,
} from 'constants/flowsConstants'

const steps = [
  {
    title: 'Create a Subscription Schedule',
    stepTitle: 'Schedule',
    component: CreateSubscriptionScheduleFormC,
    props: {
      submitLabel: 'Next',
      leftButton: true,
    },
    dataKey: CREATE_SUBSCRIPTION_SCHEDULE_DATA_KEY,
    getDisplayData: SubscriptionScheduleDisplayData,
  },
  {
    title: 'Create a Subscription Amount',
    stepTitle: 'Amount',
    component: CreateSubscriptionAmountForm,
    props: {
      submitLabel: 'Next',
      leftButton: true,
    },
    dataKey: CREATE_SUBSCRIPTION_AMOUNT_DATA_KEY,
    getDisplayData: SubscriptionAmountDisplayData,
  },
  {
    stepTitle: 'Confirmation of Subscription',
    component: CreateSubscriptionConfirmation,
    props: {
      submitLabel: 'Submit',
      leftButton: true,
    },
  },
]

const CreateSubscriptionScheduleAmountModal = ({
  merchantId,
  credentials,
}) => {
  return (
    <GenericModalC
      title='Create Subscription'
      className='CreateSubscriptionScheduleAmountModal'
      steps={steps}
      merchantId={merchantId}
      credentials={credentials}
      multiSteps
    />
  )
}

CreateSubscriptionScheduleAmountModal.propTypes = {
  merchantId: PropTypes.string,
  credentials: PropTypes.object,
}

export default CreateSubscriptionScheduleAmountModal
