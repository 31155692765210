import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment/moment'
import SendPayoutReviewAndSubmitForm from './SendPayoutReviewAndSubmitForm'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatMoney from 'utilities/formatters/formatMoney'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { MM_DD_YYYY } from 'constants/timeConstants'
import { getCardBrand } from 'constants/bankConstants'
import { FETCHING } from 'constants/reducerConstants'
import { ACH_PAYOUT_SPEED_MAP } from 'constants/transferConstants'
import { LIGHTNING_BOLT_ICON } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  CARD_NUMBER,
  COUNTRY,
  EMAIL,
  EXPIRY,
  NAME,
  PHONE,
  AMOUNT,
  NOTE,
  RECIPIENT_TYPE,
  BUSINESS_RECIPIENT,
  PERSONAL_RECIPIENT,
  RECIPIENT_NAME,
  ZIP_CODE,
  TRANSFER_SPEED,
} from 'constants/language/languageConstants'

import {
  BE_BANK_ACCOUNT,
  BE_PAYMENT_CARD,
} from 'constants/paymentInstrumentConstants'

import {
  getIdentitySelector,
  getPaymentInstrumentSelector,
  getApplicationSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  // get selected recipient data
  const selectedRecipientId = get(state, 'flowsR.formValues.recipient.identityId.value')
  const selectedRecipient = getIdentitySelector(state, selectedRecipientId)
  const selectedRecipientData = convertPageSectionDataToV2([
    {
      label: RECIPIENT_TYPE,
      value: get(selectedRecipient, 'businessName') ? BUSINESS_RECIPIENT : PERSONAL_RECIPIENT,
    },
    {
      label: RECIPIENT_NAME,
      value: get(selectedRecipient, 'businessName') ? get(selectedRecipient, 'businessName') : get(selectedRecipient, 'fullName'),
    },
    {
      label: ADDRESS,
      value: get(selectedRecipient, 'businessName') ? <Address address={get(selectedRecipient, 'businessAddress')} /> : <Address address={get(selectedRecipient, 'personalAddress')} />,
    },
    {
      label: EMAIL,
      value: get(selectedRecipient, 'email'),
    },
    {
      label: PHONE,
      value: get(selectedRecipient, 'businessName') ? get(selectedRecipient, 'businessPhone') : get(selectedRecipient, 'phone'),
    },
  ], 1)

  // get payment instrument data
  const selectedPaymentInstrumentId = get(state, 'flowsR.formValues.recipient.paymentInstrumentId.value')
  const selectedPaymentInstrument = getPaymentInstrumentSelector(state, selectedPaymentInstrumentId)
  const isBankAccount = get(selectedPaymentInstrument, 'instrumentType') === BE_BANK_ACCOUNT
  const isPaymentCard = get(selectedPaymentInstrument, 'instrumentType') === BE_PAYMENT_CARD
  const cardBrandIcon = getCardBrand(get(selectedPaymentInstrument, 'brand'))
  const transferSpeed = get(state, 'flowsR.formValues.amount.transferSpeed')
  const mappedTransferSpeed = ACH_PAYOUT_SPEED_MAP[transferSpeed]
  const isSameDayACH = transferSpeed === 'PUSH_TO_SAME_DAY_ACH'

  const selectedPaymentInstrumentData = convertPageSectionDataToV2([
    {
      label: NAME,
      value: get(selectedPaymentInstrument, 'name'),
    },
    {
      label: ACCOUNT_TYPE,
      value: get(selectedPaymentInstrument, 'displayAccountType'),
      condition: isBankAccount,
    },
    {
      label: ACCOUNT_NUMBER,
      value: get(selectedPaymentInstrument, 'maskedAccountNumber'),
      condition: isBankAccount,
    },
    {
      label: COUNTRY,
      value: get(selectedPaymentInstrument, 'displayCountry'),
      condition: isBankAccount,
    },
    {
      label: CARD_NUMBER,
      value: <div className='flex items-center'><i className={cardBrandIcon} /><div>&nbsp;{get(selectedPaymentInstrument, 'maskedFullCardNumber')}</div></div>,
      condition: isPaymentCard,
    },
    {
      label: EXPIRY,
      value: get(selectedPaymentInstrument, 'expirationDate'),
      condition: isPaymentCard,
    },
    {
      label: ZIP_CODE,
      value: get(selectedPaymentInstrument, 'address.postalCode'),
      condition: isPaymentCard,
    },
    {
      label: COUNTRY,
      value: get(selectedPaymentInstrument, 'address.country'),
      condition: isPaymentCard,
    },
    {
      label: TRANSFER_SPEED,
      value: <div className='flex items-center'><i className={`fa fa-${LIGHTNING_BOLT_ICON} icon`} /><div>&nbsp;{mappedTransferSpeed}</div></div>,
      condition: isBankAccount && isSameDayACH,
    },
  ], 1)

  const payoutDetailsData = convertPageSectionDataToV2([
    {
      label: AMOUNT,
      value: formatMoney({ amount: get(state, 'flowsR.formValues.amount.amount') }),
    },
    {
      label: NOTE,
      value: get(state, 'flowsR.formValues.amount.note'),
    },
  ], 1)

  return {
    credentials,
    credentialId,
    selectedRecipientData,
    selectedPaymentInstrumentData,
    payoutDetailsData,
    isBankAccount,
    isPosting: get(state, `transfersR.${FETCHING}`),
    currentDate: moment().format(MM_DD_YYYY),
    applicationName: get(application, 'businessName'),
    amount: formatMoney({ amount: get(state, 'flowsR.formValues.amount.amount') }),
  }
}

class SendPayoutReviewAndSubmitFormC extends Component {
  render() {
    return (
      <SendPayoutReviewAndSubmitForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SendPayoutReviewAndSubmitFormC)
