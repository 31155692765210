import './SettlementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import SettlementPreviewPanelC from 'components/Customer/Pages/Settlements/SettlementPreviewPanel/SettlementPreviewPanelC'
import columnDescriptors from './columnDescriptors'
import { SETTLEMENTS } from 'constants/linkConstants'
import { getSettlementsDataSelector } from 'state-layer/selectors'
import { SETTLEMENTS_ICON } from 'constants/iconConstants'
import { SETTLEMENTS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  SETTLEMENTS_SUB_TITLE,
  SETTLEMENTS_RESOURCE_TITLE,
  SETTLEMENTS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

import {
  GET_SETTLEMENTS,
  SETTLEMENT,
} from 'constants/flowConstants'

const Settlements = ({
  settlementsPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters = [],
  showHeader = true,
}) => {
  return (
    <div className='Settlements'>
      {showHeader && (
        <HeaderV2C
          title={SETTLEMENTS_RESOURCE_TITLE}
          subTitle={SETTLEMENTS_SUB_TITLE}
          learnMoreLink={SETTLEMENTS_LEARN_MORE_LINK}
        />
      )}

      <TableC
        flow={GET_SETTLEMENTS}
        columnDescriptors={columnDescriptors}
        selector={getSettlementsDataSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={SETTLEMENTS}
        allowedFilters={allowedFilters}
        path={settlementsPath}
        isFetching={isFetching}
        fileNameCSV={SETTLEMENTS_RESOURCE_TITLE}
        icon={`fa fa-${SETTLEMENTS_ICON}`}
        emptyMessage={SETTLEMENTS_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={SETTLEMENT}
        previewPanel={SettlementPreviewPanelC}
      />
    </div>
  )
}

Settlements.propTypes = {
  settlementsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  showHeader: PropTypes.bool,
}

export default Settlements
