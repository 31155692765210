import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import toString from 'lodash/toString'
import isEqual from 'lodash/isEqual'

import {
  fieldEmpty,
  nonEmptyPositiveAmount,
  validateMaxAmount,
} from 'utilities/validate'

import {
  START_TIME,
  DAILY_CARD_COUNT,
  DAILY_CARD_VOLUME,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_START_DATE,
  MONTHLY_CARD_COUNT,
  MONTHLY_CARD_VOLUME,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  MAX_TRANSACTION_AMOUNT_FOR_ACH,
  MAX_TRANSACTION_AMOUNT_FOR_CARDS,
} from 'constants/language/languageConstants'

import {
  MONTHLY_APPLICATION_CARD_MAX_COUNT,
  DAILY_APPLICATION_CARD_MAX_COUNT,
  MONTHLY_RECIPIENT_CARD_MAX_COUNT,
  DAILY_RECIPIENT_CARD_MAX_COUNT,
  MONTHLY_APPLICATION_CARD_MAX_VOLUME,
  DAILY_APPLICATION_CARD_MAX_VOLUME,
  MONTHLY_RECIPIENT_CARD_MAX_VOLUME,
  DAILY_RECIPIENT_CARD_MAX_VOLUME,
  MONTHLY_APPLICATION_ACH_MAX_COUNT,
  DAILY_APPLICATION_ACH_MAX_COUNT,
  MONTHLY_RECIPIENT_ACH_MAX_COUNT,
  DAILY_RECIPIENT_ACH_MAX_COUNT,
  MONTHLY_APPLICATION_ACH_MAX_VOLUME,
  DAILY_APPLICATION_ACH_MAX_VOLUME,
  MONTHLY_RECIPIENT_ACH_MAX_VOLUME,
  DAILY_RECIPIENT_ACH_MAX_VOLUME,
} from 'constants/velocityLimitsConstants'

const validateSetLimitsForm = (values, props) => {
  const entityType = get(props, 'entityType')
  const normalizedValues = get(values, entityType)
  const applicationType = isEqual(entityType, 'application')

  const [
    startTime,
    dailyCardCount,
    dailyCardVolume,
    dailyACHCount,
    dailyACHVolume,
    monthlyCardCount,
    monthlyCardVolume,
    monthlyACHCount,
    monthlyACHVolume,
    monthlyStartDate,
    maxTransactionAmountForCards,
    maxTransactionAmountForACH,
  ] = getMany(normalizedValues, [
    'startTime.value',
    'dailyCardCount',
    'dailyCardVolume',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyCardCount',
    'monthlyCardVolume',
    'monthlyACHCount',
    'monthlyACHVolume',
    'monthlyStartDate.value',
    'maxTransactionAmountForCards',
    'maxTransactionAmountForACH',
  ])

  return {
    [entityType]: {
      startTime: fieldEmpty(toString(startTime), START_TIME),
      dailyCardCount: fieldEmpty(toString(dailyCardCount), DAILY_CARD_COUNT),
      dailyCardVolume: fieldEmpty(toString(dailyCardVolume), DAILY_CARD_VOLUME),
      dailyACHCount: fieldEmpty(toString(dailyACHCount), DAILY_ACH_COUNT),
      dailyACHVolume: fieldEmpty(toString(dailyACHVolume), DAILY_ACH_VOLUME),
      monthlyStartDate: fieldEmpty(toString(monthlyStartDate), MONTHLY_START_DATE),
      monthlyCardCount: fieldEmpty(toString(monthlyCardCount), MONTHLY_CARD_COUNT),
      monthlyCardVolume: fieldEmpty(toString(monthlyCardVolume), MONTHLY_CARD_VOLUME),
      monthlyACHCount: fieldEmpty(toString(monthlyACHCount), MONTHLY_ACH_COUNT),
      monthlyACHVolume: fieldEmpty(toString(monthlyACHVolume), MONTHLY_ACH_VOLUME),
      maxTransactionAmountForCards: nonEmptyPositiveAmount({ field: maxTransactionAmountForCards, name: MAX_TRANSACTION_AMOUNT_FOR_CARDS }),
      maxTransactionAmountForACH: nonEmptyPositiveAmount({ field: maxTransactionAmountForACH, name: MAX_TRANSACTION_AMOUNT_FOR_ACH }),
      // TODO: comment out max amount checks until product finalizes
      // dailyCardCount: applicationType ? validateMaxAmount({ field: dailyCardCount, name: DAILY_CARD_COUNT, maxAmount: DAILY_APPLICATION_CARD_MAX_COUNT }) :
      //   validateMaxAmount({ field: dailyCardCount, name: DAILY_CARD_COUNT, maxAmount: DAILY_RECIPIENT_CARD_MAX_COUNT }),
      // dailyCardVolume: applicationType ? validateMaxAmount({ field: dailyCardVolume, name: DAILY_CARD_VOLUME, maxAmount: DAILY_APPLICATION_CARD_MAX_VOLUME, showCurrencySymbol: true }) :
      //   validateMaxAmount({ field: dailyCardVolume, name: DAILY_CARD_VOLUME, maxAmount: DAILY_RECIPIENT_CARD_MAX_VOLUME, showCurrencySymbol: true }),
      // dailyACHCount: applicationType ? validateMaxAmount({ field: dailyACHCount, name: DAILY_ACH_COUNT, maxAmount: DAILY_APPLICATION_ACH_MAX_COUNT }) :
      //   validateMaxAmount({ field: dailyACHCount, name: DAILY_ACH_COUNT, maxAmount: DAILY_RECIPIENT_ACH_MAX_COUNT }),
      // dailyACHVolume: applicationType ? validateMaxAmount({ field: dailyACHVolume, name: DAILY_ACH_VOLUME, maxAmount: DAILY_APPLICATION_ACH_MAX_VOLUME, showCurrencySymbol: true }) :
      //   validateMaxAmount({ field: dailyACHVolume, name: DAILY_ACH_VOLUME, maxAmount: DAILY_RECIPIENT_ACH_MAX_VOLUME, showCurrencySymbol: true }),
      // monthlyStartDate: fieldEmpty(toString(monthlyStartDate), MONTHLY_START_DATE),
      // monthlyCardCount: applicationType ? validateMaxAmount({ field: monthlyCardCount, name: MONTHLY_CARD_COUNT, maxAmount: MONTHLY_APPLICATION_CARD_MAX_COUNT }) :
      //   validateMaxAmount({ field: monthlyCardCount, name: MONTHLY_CARD_COUNT, maxAmount: MONTHLY_RECIPIENT_CARD_MAX_COUNT }),
      // monthlyCardVolume: applicationType ? validateMaxAmount({ field: monthlyCardVolume, name: MONTHLY_CARD_VOLUME, maxAmount: MONTHLY_APPLICATION_CARD_MAX_VOLUME, showCurrencySymbol: true }) :
      //   validateMaxAmount({ field: monthlyCardVolume, name: MONTHLY_CARD_VOLUME, maxAmount: MONTHLY_RECIPIENT_CARD_MAX_VOLUME, showCurrencySymbol: true }),
      // monthlyACHCount: applicationType ? validateMaxAmount({ field: monthlyACHCount, name: MONTHLY_ACH_COUNT, maxAmount: MONTHLY_APPLICATION_ACH_MAX_COUNT }) :
      //   validateMaxAmount({ field: monthlyACHCount, name: MONTHLY_ACH_COUNT, maxAmount: MONTHLY_RECIPIENT_ACH_MAX_COUNT }),
      // monthlyACHVolume: applicationType ? validateMaxAmount({ field: monthlyACHVolume, name: MONTHLY_ACH_VOLUME, maxAmount: MONTHLY_APPLICATION_ACH_MAX_VOLUME, showCurrencySymbol: true }) :
      //   validateMaxAmount({ field: monthlyACHVolume, name: MONTHLY_ACH_VOLUME, maxAmount: MONTHLY_RECIPIENT_ACH_MAX_VOLUME, showCurrencySymbol: true }),
    },
  }
}

export default validateSetLimitsForm
