import frontendSettlementGroupModel from 'utilities/create/models/frontendSettlementGroupModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSettlementGroupsModel = ({ data: settlementGroups }) => {
  const settlementGroupModels = map(settlementGroups, (settlementGroup) => frontendSettlementGroupModel({ data: settlementGroup }))

  return keyBy(settlementGroupModels, 'id')
}

export default frontendSettlementGroupsModel
