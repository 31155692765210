import './FilterSearchBarS.scss'
import React from 'react'
import PropTypes from 'prop-types'

import {
  SEARCH_ICON,
  REMOVE_ICON,
} from 'constants/iconConstants'

const FilterSearchBar = ({
  filterQuery = '',
  updateFilter = () => {},
  removeFilter = () => {},
  searchPlaceholder = 'Search',
}) => {
  return (
    <div className='FilterSearchBar search-field flex'>
      <i className={`search fal fa-${SEARCH_ICON}`} />
      <input
        value={filterQuery}
        onChange={updateFilter}
        placeholder={searchPlaceholder}
      />
      {filterQuery && <i className={`remove fal fa-${REMOVE_ICON}`} onClick={removeFilter} /> }
    </div>
  )
}

FilterSearchBar.propTypes = {
  filterQuery: PropTypes.string,
  updateFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  searchPlaceholder: PropTypes.string,
}

export default FilterSearchBar
