import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getDashboardCustomizationRequest = ({
  entityId,
  entityType,
  credentials,
}) => createAction({
  type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
  queries: {
    entity_id: entityId,
    entity_type: entityType,
  },
  credentials,
})

export default getDashboardCustomizationRequest
