import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisputeTabs from './DisputeTabs'
import DisputeReviewC from 'components/Customer/Pages/Dispute/DisputeReview/DisputeReviewC'
import Notes from 'components/Customer/Pages/Notes/Notes'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

import {
  DETAILS,
  DISPUTE_NOTES_EMPTY_MSG,
  INTERNAL_NOTES,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const disputeId = get(props, 'disputeId')

  const tabs = [
    {
      id: 'details',
      name: DETAILS,
      component: DisputeReviewC,
      props: {
        disputeId,
      },
    },
    {
      id: 'internal-notes',
      name: INTERNAL_NOTES,
      component: Notes,
      props: {
        linkedTo: disputeId,
        emptyMessage: DISPUTE_NOTES_EMPTY_MSG,
        showAddNewNote: true,
      },
    },
  ]

  return {
    credentials,
    tabs,
  }
}

class DisputeTabsC extends Component {
  render() {
    return (
      <DisputeTabs
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DisputeTabsC)
