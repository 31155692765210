import { fieldEmpty } from 'utilities/validate'
import get from 'lodash/get'

import {
  APPLICATION,
  MERCHANT_ACCOUNT,
} from 'constants/language/languageConstants'

const validateAddDeviceMerchantForm = (values, props) => {
  const { isRolePlatformCred } = props

  const {
    application,
    merchantAccount,
  } = values

  return {
    application: isRolePlatformCred ? fieldEmpty(get(application, 'value'), APPLICATION) : false,
    merchantAccount: fieldEmpty(get(merchantAccount, 'value'), MERCHANT_ACCOUNT),
  }
}

export default validateAddDeviceMerchantForm
