import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANTS,
  MERCHANT_PAYOUT_PROFILE,
} from 'constants/apiConstants'

const getMerchantPayoutSettingsAPI = ({ id, credentials, queries, meta }) => {
  return paymentsAPI.get({
    path: MERCHANT_PAYOUT_PROFILE({ merchantId: id }),
    credentials,
    queries,
    meta,
    service: MERCHANTS,
  })
}

export default getMerchantPayoutSettingsAPI
