import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationSettlements from './ApplicationSettlements'
import get from 'lodash/get'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { SETTLEMENTS_PATH } from 'constants/pathConstants'

import {
  // SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const quickFilters = [
  // TODO: add when the API supports
  // SETTLEMENTS_STATUS_QUICK_FILTER,
  // DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `applicationSettlementsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const settlementPath = SETTLEMENTS_PATH({ credentialId })

  const flowValues = {
    applicationId,
  }

  return {
    applicationId,
    flowValues,
    isFetching,
    settlementPath,
    quickFilters,
  }
}

class ApplicationSettlementsC extends Component {
  render() {
    return (
      <ApplicationSettlements {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationSettlementsC)
