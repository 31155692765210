import './ApplicationLimitsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { velocityLimitsColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { infoTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  APPLICATION_CARD_VELOCITY_LIMITS,
  APPLICATION_ACH_VELOCITY_LIMITS,
  GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const ApplicationLimits = ({
  isFetching,
  setCardVelocityLimitActions = [],
  setACHVelocityLimitActions = [],
  applicationACHRules = {},
  applicationCardRules = {},
  applicationACHRulesMaxTransactionAmount,
  applicationCardRulesMaxTransactionAmount,
}) => {
  return (
    <div className='ApplicationLimits'>
      <PageSectionV2C
        title={APPLICATION_CARD_VELOCITY_LIMITS}
        actions={setCardVelocityLimitActions}
        subTitle={applicationCardRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${applicationCardRulesMaxTransactionAmount}` : undefined}
        emptyMessage={isEmpty(applicationCardRules) && GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE}
      >
        {!isEmpty(applicationCardRules) && (
          <TableC
            isFetching={isFetching}
            showTableFooter={false}
            theme={infoTableTheme}
            columnDescriptors={velocityLimitsColumnDescriptors}
            data={applicationCardRules}
          />
        )}
      </PageSectionV2C>

      <PageSectionV2C
        title={APPLICATION_ACH_VELOCITY_LIMITS}
        actions={setACHVelocityLimitActions}
        subTitle={applicationACHRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${applicationACHRulesMaxTransactionAmount}` : undefined}
        emptyMessage={isEmpty(applicationACHRules) && GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE}
      >
        {!isEmpty(applicationACHRules) && (
          <TableC
            isFetching={isFetching}
            showTableFooter={false}
            theme={infoTableTheme}
            columnDescriptors={velocityLimitsColumnDescriptors}
            data={applicationACHRules}
          />
        )}
      </PageSectionV2C>
    </div>
  )
}

ApplicationLimits.propTypes = {
  isFetching: PropTypes.bool,
  setCardVelocityLimitActions: PropTypes.array,
  setACHVelocityLimitActions: PropTypes.array,
  applicationACHRules: PropTypes.object,
  applicationCardRules: PropTypes.object,
  applicationACHRulesMaxTransactionAmount: PropTypes.string,
  applicationCardRulesMaxTransactionAmount: PropTypes.string,
}

export default ApplicationLimits
