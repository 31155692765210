import React, { Component } from 'react'
import { connect } from 'react-redux'
import Disputes from './Disputes'
import columnDescriptors from './columnDescriptors'
import { getTableFiltersSelector } from 'state-layer/selectors'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { createdQueriesForLastMonths } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { DISPUTES_PATH } from 'constants/pathConstants'
import { DISPUTE } from 'constants/language/languageConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'

import {
  DISPUTE_STATE_QUICK_FILTER,
  DISPUTES_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  LOST,
  WON,
} from 'constants/statusConstants'

import {
  TRANSFER_ID,
  ADJUSTMENT_TRANSFER_ID,
  AMOUNT,
  DISPUTE_RESPONSE_STATE,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME_EXACT,
  INSTRUMENT_NAME_EXACT,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_CARD_TYPE,
  INSTRUMENT_FINGERPRINT,
  TAGS_KEY_LIKE,
  TAGS_VALUE_LIKE,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(DISPUTE),
  TRANSFER_ID,
  ADJUSTMENT_TRANSFER_ID,
  AMOUNT(),
  DISPUTE_RESPONSE_STATE,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME_EXACT,
  INSTRUMENT_NAME_EXACT,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_CARD_TYPE,
  INSTRUMENT_FINGERPRINT,
  TAGS_KEY_LIKE,
  TAGS_VALUE_LIKE,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'updated_at,desc',
}

const quickFilters = [
  DISPUTE_STATE_QUICK_FILTER,
  DISPUTES_DATES_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `disputesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const disputesPath = DISPUTES_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)

  const currentFilters = getTableFiltersSelector(state)
  const isWonQuickFilter = get(currentFilters, 'state.eq', false) === WON
  const isLostQuickFilter = get(currentFilters, 'state.eq', false) === LOST
  const formattedColumnDescriptors = columnDescriptors({ isWonOrLostQuickFilter: isWonQuickFilter || isLostQuickFilter })

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    initialFilters: createdQueriesForLastMonths(6),
    isFetching,
    disputesPath,
    columnDescriptors: formattedColumnDescriptors,
  }
}

class DisputesC extends Component {
  render() {
    return (
      <Disputes {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(DisputesC)
