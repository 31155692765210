import get from 'lodash/get'

import { UPDATE_FEATURE_FLAGS } from 'constants/flowConstants'

const featureFlagsR = (state = { _isLoaded: false }, action = {}) => {
  const actionType = get(action, 'type', '')

  switch (actionType) {
    case UPDATE_FEATURE_FLAGS:
      return { ...get(action, 'payload', {}), _isLoaded: true }

    default:
      return state
  }
}

export default featureFlagsR
