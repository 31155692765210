import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { QA_ENVIRONMENT } from 'constants/environmentConstants'
import { isFlexPlatform } from 'constants/flexConstants'

const hideSupportMenu = ({ credentials = {} }) => {
  const {
    environment,
  } = credentials

  if (environment === QA_ENVIRONMENT) {
    return false
  }

  return (!isFlexPlatform() && isRolePartner({ credentials }))
}

export default hideSupportMenu
