import { GET_UNDERWRITING_CHARTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUnderwritingChartsRequest = ({
  queries,
}) => createAction({
  type: GET_UNDERWRITING_CHARTS_F_REQUEST,
  queries,
})

export default getUnderwritingChartsRequest
