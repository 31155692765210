import './EditUnderwritingProfileModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import EditUnderwritingProfileFormC from 'components/Customer/Forms/EditUnderwritingProfileForm/EditUnderwritingProfileFormC'

import {
  CREATE_UNDERWRITING_PROFILE,
  EDIT_UNDERWRITING_PROFILE,
} from 'constants/language/languageConstants'

const EditUnderwritingProfileModal = ({
  underwritingProfileId,
  isRolePartnerCredential,
  isApplicationInfoPage,
  entityId,
}) => {
  return (
    <GenericModal
      title={underwritingProfileId ? EDIT_UNDERWRITING_PROFILE : CREATE_UNDERWRITING_PROFILE}
      className='EditUnderwritingProfileModal'
      Component={EditUnderwritingProfileFormC}
      underwritingProfileId={underwritingProfileId}
      isRolePartnerCredential={isRolePartnerCredential}
      isApplicationInfoPage={isApplicationInfoPage}
      entityId={entityId}
    />
  )
}

EditUnderwritingProfileModal.propTypes = {
  underwritingProfileId: PropTypes.string,
  isRolePartnerCredential: PropTypes.bool,
  isApplicationInfoPage: PropTypes.bool,
  entityId: PropTypes.string,
}

export default EditUnderwritingProfileModal
