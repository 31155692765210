import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisbursementsDashboardOverview from './DisbursementsDashboardOverview'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getApplicationBalancesRequest from 'utilities/actions/get/getApplicationBalancesRequest'
import getCurrentUsagesRequest from 'utilities/actions/get/getCurrentUsagesRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { GET_TRANSFERS_F_REQUEST } from 'constants/flowConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

import {
  DISBURSEMENTS_PATH,
  PAYMENTS_PATH,
} from 'constants/pathConstants'

import {
  getBalancesByApplicationId,
  getBalanceSelector,
  getCurrentUsagesByLinkedTo,
  getIdentitiesSelector,
  getPaymentInstrumentsSelector,
  getTransfersSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const isFetching = get(state, `chartsR.${FETCHING}`, true)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})
  const applicationBalances = getBalancesByApplicationId(state, applicationId)
  const applicationBalanceId = get(applicationBalances, 'id')
  const applicationBalance = getBalanceSelector(state, applicationBalanceId)
  const applicationVelocityLimit = getCurrentUsagesByLinkedTo(state, applicationId)
  const transactionPath = PAYMENTS_PATH({ credentialId })
  const disbursementsPath = DISBURSEMENTS_PATH({ credentialId })

  const latestActivityData = () => {
    const applicationTransfers = getTransfersSelector(state)
    const paymentInstruments = getPaymentInstrumentsSelector(state)
    const identities = getIdentitiesSelector(state)

    const items = reduce(applicationTransfers, (total, transfer, transferId) => {
      const paymentInstrumentId = get(transfer, 'paymentInstrumentId')
      const paymentInstrument = get(paymentInstruments, paymentInstrumentId)
      const identityId = get(paymentInstrument, 'merchantIdentityId')
      const merchantIdentity = get(identities, identityId)

      const combinedTransfer = merge({}, transfer, { merchantIdentity, paymentInstrument })

      return merge({}, total, { [transferId]: combinedTransfer })
    }, {})

    return items
  }

  const [
    applicationBalanceAmount,
    currency,
  ] = getMany(applicationBalance, [
    'displayAvailableAmount',
    'currency',
  ])

  const [
    displayCardDailyVelocityCurrentVolume,
    displayCardDailyVelocityVolumeLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCountLimit,
    displayAchDailyVelocityCurrentVolume,
    displayAchDailyVelocityVolumeLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCountLimit,
  ] = getMany(applicationVelocityLimit, [
    'displayCardDailyVelocityCurrentVolume',
    'displayCardDailyVelocityVolumeLimit',
    'cardDailyVelocityCurrentCount',
    'cardDailyVelocityCountLimit',
    'displayAchDailyVelocityCurrentVolume',
    'displayAchDailyVelocityVolumeLimit',
    'achDailyVelocityCurrentCount',
    'achDailyVelocityCountLimit',
  ])

  return {
    credentials,
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
    applicationId,
    displayCardDailyVelocityCurrentVolume,
    displayCardDailyVelocityVolumeLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCountLimit,
    displayAchDailyVelocityCurrentVolume,
    displayAchDailyVelocityVolumeLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCountLimit,
    applicationBalanceAmount,
    currency,
    latestActivityData: latestActivityData(),
    transactionPath,
    disbursementsPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationBalances: ({ credentials, applicationId }) => dispatch(getApplicationBalancesRequest({ credentials, queries: { linked_to: applicationId } })),
    getApplicationVelocityLimits: ({ credentials, entityId }) => dispatch(getCurrentUsagesRequest({ entityId, credentials })),
    getApplicationTransfers: ({ credentials, applicationId }) => dispatch({
      type: GET_TRANSFERS_F_REQUEST,
      payload: {
        credentials,
        queries: {
          application_id: applicationId,
          limit: 10,
          sort: 'created_at,desc',
        },
      },
    }),
  }
}

class DisbursementsDashboardOverviewC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationBalances,
      getApplicationVelocityLimits,
      getApplicationTransfers,
    } = this.props

    getApplicationBalances({ credentials, applicationId })
    getApplicationVelocityLimits({ credentials, entityId: applicationId })
    getApplicationTransfers({ credentials, applicationId })
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      applicationId,
      getApplicationBalances,
      getApplicationVelocityLimits,
      getApplicationTransfers,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationBalances({ credentials, applicationId })
      getApplicationVelocityLimits({ credentials, entityId: applicationId })
      getApplicationTransfers({ credentials, applicationId })
    }
  }

  render() {
    return (
      <DisbursementsDashboardOverview
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisbursementsDashboardOverviewC)
