import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingReviewReportsSummary from './UnderwritingReviewReportsSummary'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { FETCHING } from 'constants/reducerConstants'
import size from 'lodash/size'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  MANUAL_REVIEW,
  REJECTED,
  ACCEPTED,
} from 'constants/underwritingConstants'

const constructReportSummary = ({ resources, isAdverseMedia = false }) => {
  if (isEmpty(resources)) {
    return {}
  }

  const count = size(resources)
  let isRejectedOrReview = false
  let reviewCount = 0
  let rejectedCount = 0
  let acceptedCount = 0

  if (isAdverseMedia) {
    reviewCount = size(filter(resources, ({ found }) => found))
    acceptedCount = size(filter(resources, ({ found }) => !found))
  } else {
    reviewCount = size(filter(resources, ({ decisioned_response: decisionedResponse }) => decisionedResponse === MANUAL_REVIEW))
    rejectedCount = size(filter(resources, ({ decisioned_response: decisionedResponse }) => decisionedResponse === REJECTED))
    acceptedCount = size(filter(resources, ({ decisioned_response: decisionedResponse }) => decisionedResponse === ACCEPTED))
  }

  if (reviewCount > 0 || rejectedCount > 0) {
    isRejectedOrReview = true
  }

  return {
    countString: count > 1 ? `(${count} entities evaluated)` : `(${count} entity evaluated)`,
    isRejectedOrReview,
    description: (
      <div>
        {acceptedCount > 0 && <div>{acceptedCount} accepted</div>}
        {reviewCount > 0 && <div>{reviewCount} requires manual review</div>}
        {rejectedCount > 0 && <div>{rejectedCount} rejected</div>}
      </div>
    ),
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const reviewId = get(props, 'reviewId')
  const review = getUnderwritingReviewSelector(state, reviewId)
  const reviewType = get(review, 'reviewType')
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)

  const {
    businesses = [],
    owners = [],
    sanctions = [],
    bank_accounts: bankAccounts = [],
    emails = [],
    adverse_media: adverseMedia = [],
  } = get(review, 'report', {})

  const identitiesData = constructReportSummary({ resources: [...businesses, ...owners] })
  const sanctionsData = constructReportSummary({ resources: sanctions })
  const bankAccountsData = constructReportSummary({ resources: bankAccounts })
  const emailsData = constructReportSummary({ resources: emails })
  const adverseMediaData = constructReportSummary({ resources: adverseMedia, isAdverseMedia: true })

  return {
    isFetching,
    identitiesData,
    sanctionsData,
    bankAccountsData,
    emailsData,
    adverseMediaData,
    isStandaloneUnderwriting,
    reviewType,
  }
}

class UnderwritingReviewReportsSummaryC extends Component {
  render() {
    return (
      <UnderwritingReviewReportsSummary
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UnderwritingReviewReportsSummaryC)
