import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import IdentityPaymentsC from 'components/Customer/Pages/Identity/IdentityPayments/IdentityPaymentsC'
import IdentityPayoutsC from 'components/Customer/Pages/Identity/IdentityPayouts/IdentityPayoutsC'
import IdentityAuthorizationsC from 'components/Customer/Pages/Identity/IdentityAuthorizations/IdentityAuthorizationsC'
import IdentityRefundsC from 'components/Customer/Pages/Identity/IdentityRefunds/IdentityRefundsC'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

import {
  PAYMENTS,
  PAYOUTS,
  AUTHORIZATIONS,
  REFUNDS,
} from 'constants/language/languageConstants'

class IdentityTransactionsTabs extends Component {
  render() {
    const {
      identityId,
    } = this.props

    const credentials = getCurrentCredentials()
    const isPayout = isPayoutFeature({ credentials })

    const tabs = [
      {
        id: 'payments',
        name: isPayout ? PAYOUTS : PAYMENTS,
        component: isPayout ? IdentityPayoutsC : IdentityPaymentsC,
        props: {
          identityId,
        },
      },
      {
        id: 'authorizations',
        name: AUTHORIZATIONS,
        component: IdentityAuthorizationsC,
        condition: !isPayout,
        props: {
          identityId,
        },
      },
      {
        id: 'refunds',
        name: REFUNDS,
        component: IdentityRefundsC,
        condition: !isPayout,
        props: {
          identityId,
        },
      },
    ]

    return (
      <div className='IdentityTransactionsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default IdentityTransactionsTabs
