import frontendWebhookModel from 'utilities/create/models/frontendWebhookModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendWebhooksModel = ({ data: webhooks }) => {
  const webhooksModels = map(webhooks, (webhook) => frontendWebhookModel({ data: webhook }))

  return keyBy(webhooksModels, 'id')
}

export default frontendWebhooksModel
