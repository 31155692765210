import React, { Component } from 'react'
import ComplianceCompensatingControlsWorksheetForm from './ComplianceCompensatingControlsWorksheetForm'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { getComplianceSelfAssessmentQuestionnaireASelector } from 'state-layer/selectors'
import some from 'lodash/some'
import { COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const complianceSelfAssessmentQuestionnaireAValues = getComplianceSelfAssessmentQuestionnaireASelector(state)
  const saqAFormHasYesWithCCW = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'YES_WITH_CCW')

  return {
    saqAFormHasYesWithCCW,
  }
}

class ComplianceCompensatingControlsWorksheetFormC extends Component {
  componentDidMount() {
    const {
      saqAFormHasYesWithCCW,
      dispatch,
    } = this.props

    if (!saqAFormHasYesWithCCW) {
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'constraints', ''))
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'objective', ''))
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'identifiedRisk', ''))
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'definitionOfCompensatingControls', ''))
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'validationOfCompensatingControls', ''))
      dispatch(change(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_FORM, 'maintenance', ''))
    }
  }

  render() {
    return (
      <ComplianceCompensatingControlsWorksheetForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceCompensatingControlsWorksheetFormC)
