import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import ApplicationPaymentLinks from './ApplicationPaymentLinks'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { PAYMENT_LINKS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
  PAYMENT_LINKS_QUICK_FILTERS,
} from 'constants/quickFilterConstants'

const quickFilters = [
  PAYMENT_LINKS_STATUS_QUICK_FILTER,
  PAYMENT_LINKS_QUICK_FILTERS,
]

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentLinksPath = PAYMENT_LINKS_PATH({ credentialId })

  const flowValues = {
    applicationId,
  }

  const initialQueries = {
    application_id: applicationId,
  }

  return {
    applicationId,
    flowValues,
    isFetching,
    paymentLinksPath,
    initialQueries,
    quickFilters,
  }
}

class ApplicationPaymentLinksC extends Component {
  render() {
    return (
      <ApplicationPaymentLinks {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ApplicationPaymentLinksC)
