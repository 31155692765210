import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatNumber from 'utilities/formatters/formatNumber'
import capitalize from 'lodash/capitalize'

const frontendFraudScoreModel = ({ data: fraudScore }) => {
  const [
    id,
    createdAt,
    updatedAt,
    applicationId,
    merchantId,
    entityType,
    entityId,
    score,
    scoreCategory,
    thirdPartyRule,
    thirdPartyRuleDescription,
    thirdPartyOutcome,
    decision,
    traceId,
  ] = getMany(fraudScore, [
    'id',
    'created_at',
    'updated_at',
    'application_id',
    'merchant_id',
    'entity_type',
    'entity_id',
    'score',
    'score_category',
    'third_party_rule',
    'third_party_rule_description',
    'third_party_outcome',
    'decision',
    'trace_id',
  ])

  return {
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    applicationId,
    merchantId,
    entityType,
    entityId,
    score,
    displayScore: formatNumber(score),
    scoreCategory,
    displayScoreCategory: capitalize(scoreCategory),
    thirdPartyRule,
    thirdPartyRuleDescription,
    thirdPartyOutcome,
    decision,
    displayDecision: capitalize(decision),
    traceId,
  }
}

export default frontendFraudScoreModel
