import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PLATFORMS,
  PLATFORM_PROCESSOR_CONFIG,
} from 'constants/apiConstants'

const patchPlatformProcessorConfigAPI = ({ values, id, credentials, meta }) => {
  const { platformId } = meta

  return paymentsAPI.patch({
    meta,
    values,
    credentials,
    path: PLATFORM_PROCESSOR_CONFIG({ platformId, processorConfigId: id }),
    service: PLATFORMS,
  })
}

export default patchPlatformProcessorConfigAPI
