import {
  DUMMY_V1,
  VISA_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'peoplespayments',
  id: 'DASHc6QHtGdhCzFE34teBWngnt',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/peoplespayments/2018-05-11-00_52_39-dashboard-peoples-payments.png',
  faviconImage: 'https://finix-payments.github.io/customer-logos/assets/peoplespayments-favicon.png',
  credentialsHost: 'https://peoplespayments.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: 'N8C46YOiOST3LxxijKGdRh8fO_U1roTe',
    domain: 'peoplespayments.auth0.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'VISA_V1'],
  homeCountry: 'CAN',
  dashboardServiceV2: true,
}
