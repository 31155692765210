export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS'
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE'

export const POST_SESSION_SUCCESS = 'POST_SESSION_SUCCESS'
export const POST_SESSION_FAILURE = 'POST_SESSION_FAILURE'

// UTILITIES
export const successS = (type) => `${type}_${SUCCESS}`
export const failureS = (type) => `${type}_${FAILURE}`
