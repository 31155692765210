import './TagsDisplaySectionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { TAG_ICON } from 'constants/iconConstants'
import { NO_TAGS_AVAILABLE } from 'constants/language/languageConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

const TagsDisplaySection = ({
  data = [],
  title = '',
  emptyMessage = NO_TAGS_AVAILABLE,
  isFetching = false,
}) => {
  const formattedEmptyMessage = emptyMessage || `No ${title} Provided`
  const allDataMissing = isEmpty(data)

  return (
    <div className='TagsDisplaySection flex wrap'>
      { allDataMissing && !isFetching && <span className='emptyMessage p-2'>{formattedEmptyMessage}</span> }

      { !allDataMissing && (
        map(data, ({ label, value = '-' }) => {
          return (
            <span className='tagItem' key={label}>
              <i className={`fa fa-${TAG_ICON}`} />
              <span className='tagLabel p-2-bold'>{label}:</span>
              {value}
            </span>
          )
        })
      )}
    </div>
  )
}

TagsDisplaySection.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  emptyMessage: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default TagsDisplaySection
