import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlaidReport from './PlaidReport'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatPhone from 'utilities/forms/format/formatPhone'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import get from 'lodash/get'
import find from 'lodash/find'
import capitalize from 'lodash/capitalize'

import {
  normalizeAddress,
  normalizeAmount,
} from 'constants/normalizationConstants'

import {
  INSTITUTION_NAME,
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  CURRENT_BALANCE,
  AVAILABLE_BALANCE,
  ACCOUNT_CURRENCY,
  PHONE_NUMBER,
  EMAIL,
  ADDRESS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const report = get(props, 'report', {})
  const details = get(report, 'details')

  const [
    institutionName,
    accountType,
    accountSubtype,
    currentAccountBalance,
    accountName,
    accountOfficialName,
    accountCurrency,
    availableBalance,
    owners,
  ] = getMany(details, [
    'institution_details.name',
    'account_details.type',
    'account_details.subtype',
    'account_details.balances.current',
    'account_details.name',
    'account_details.name_official',
    'account_details.balances.iso_currency_code',
    'account_details.balances.available',
    'account_details.owners',
  ])

  const summaryResponseData = convertPageSectionDataToV2(
    [
      {
        label: INSTITUTION_NAME,
        value: institutionName,
      },
      {
        label: ACCOUNT_TYPE,
        value: `${capitalize(accountType)} ${capitalize(accountSubtype)}`,
      },
      {
        label: ACCOUNT_NAME,
        value: `${accountName}\n(${accountOfficialName})`,
      },
      {
        label: ACCOUNT_CURRENCY,
        value: accountCurrency,
      },
      {
        label: CURRENT_BALANCE,
        value: formatMoney({ amount: currentAccountBalance, currency: accountCurrency }),
      },
      {
        label: AVAILABLE_BALANCE,
        value: formatMoney({ amount: availableBalance, currency: accountCurrency }),
      },
    ],
  )

  const constructOwnerData = (owner) => {
    const [
      phoneNumbers,
      emails,
      addresses,
    ] = getMany(owner, [
      'phone_numbers',
      'emails',
      'addresses',
    ])

    const primaryPhoneNumber = get(find(phoneNumbers, { primary: true }), 'data') || get(phoneNumbers, '[0].data')
    const primaryEmail = get(find(emails, { primary: true }), 'data') || get(emails, '[0].data')
    const primaryAddress = get(find(addresses, { primary: true }), 'data') || get(addresses, '[0].data')

    return convertPageSectionDataToV2([
      {
        label: PHONE_NUMBER,
        value: formatPhone(primaryPhoneNumber),
      },
      {
        label: EMAIL,
        value: primaryEmail,
      },
      {
        label: ADDRESS,
        value: <Address address={normalizeAddress(primaryAddress)} />,
      },
    ])
  }

  return {
    summaryResponseData,
    details,
    owners,
    constructOwnerData,
  }
}

class PlaidReportC extends Component {
  render() {
    return (
      <PlaidReport {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PlaidReportC)
