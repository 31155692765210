import './TooltipLabelS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { TOOLTIP_ICON } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'

const TooltipLabel = ({
  label = '',
  message = '',
  position = 'default',
  children,
  showIcon = true,
}) => {
  const tooltipClasses = showIcon ? classnames(`far fa-${TOOLTIP_ICON}`, 'tooltip') : classnames('tooltip')

  return (
    <div className='TooltipLabel flex'>
      {!isEmpty(label) && <div className='label'>{label}</div>}
      <div className={tooltipClasses}>
        <div className={`message ${position}`}>
          {message}
          <div className={`tooltip-arrow ${position}`} />
        </div>
        {children}
      </div>
    </div>
  )
}

TooltipLabel.propTypes = {
  label: PropTypes.string,
  position: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

export default TooltipLabel
