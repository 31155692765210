import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendPostOnboardingFormDataModel = ({ data: postOnboardingForm }) => {
  const [
    entityId,
    onboardingFormId,
  ] = getMany(postOnboardingForm, [
    'entity_id',
    'onboarding_form_id',
  ])

  return removeUndefined({
    entityId,
    onboardingFormId,
  })
}

export default frontendPostOnboardingFormDataModel
