import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccessFormSelector } from 'state-layer/selectors'
import HostedLiveAccountApplicationFlow from 'components/Customer/Pages/HostedLiveAccountApplicationFlow/HostedLiveAccountApplicationFlow'
import LiveAccountApplicationBusinessFormC from 'components/Customer/Forms/LiveAccountApplicationBusinessForm/LiveAccountApplicationBusinessFormC'
import LiveAccountApplicationOwnerInfoFormC from 'components/Customer/Forms/LiveAccountApplicationOwnerInfoForm/LiveAccountApplicationOwnerInfoFormC'
import LiveAccountApplicationProcessingInfoFormC from 'components/Customer/Forms/LiveAccountApplicationProcessingInfoForm/LiveAccountApplicationProcessingInfoFormC'
import LiveAccountApplicationBankAccountInfoFormC from 'components/Customer/Forms/LiveAccountApplicationBankAccountInfoForm/LiveAccountApplicationBankAccountInfoFormC'
import LiveAccountApplicationReviewAndSubmitFormC from 'components/Customer/Forms/LiveAccountApplicationReviewAndSubmitForm/LiveAccountApplicationReviewAndSubmitFormC'
import showModalAction from 'utilities/actions/showModalAction'
import getAccessFormRequest from 'utilities/actions/get/getAccessFormRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import prevFlowStep from 'utilities/prevFlowStep'
import get from 'lodash/get'

import {
  FORM_FILE_UPLOADER_MODAL,
  GO_TO_PREVIOUS_STEP_MODAL,
} from 'constants/modalConstants'

import {
  BUSINESS_INFO,
  OWNER_INFO,
  PROCESSING_INFO,
  BANK_ACCOUNT_INFO,
  REVIEW_AND_SUBMIT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const accessFormCountry = get(accessForm, 'country')

  return {
    accessFormId,
    credentials,
    accessFormCountry,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUploadFileModal: (modalProps) => dispatch(showModalAction({ modalType: FORM_FILE_UPLOADER_MODAL, modalProps })),
    getAccessForm: ({ credentials, accessFormId }) => dispatch(getAccessFormRequest({ credentials, accessFormId })),
    showPreviousStepModal: (modalProps) => dispatch(showModalAction({ modalType: GO_TO_PREVIOUS_STEP_MODAL, modalProps })),
  }
}

class HostedLiveAccountApplicationFlowC extends Component {
  render() {
    const {
      showUploadFileModal,
      accessFormId,
      showPreviousStepModal,
      accessFormCountry,
    } = this.props

    const goToPreviousStep = ({ isFormDirty }) => {
      if (isFormDirty) {
        showPreviousStepModal()
      } else {
        prevFlowStep()
      }
    }

    const steps = [
      {
        name: BUSINESS_INFO,
        component: LiveAccountApplicationBusinessFormC,
        props: {
          showUploadFileModal,
          accessFormId,
          isHostedAccessForm: true,
          accessFormCountry,
        },
      },
      {
        name: OWNER_INFO,
        component: LiveAccountApplicationOwnerInfoFormC,
        props: {
          accessFormId,
          goToPreviousStep,
          isHostedAccessForm: true,
          accessFormCountry,
        },
      },
      {
        name: PROCESSING_INFO,
        component: LiveAccountApplicationProcessingInfoFormC,
        props: {
          accessFormId,
          goToPreviousStep,
          isHostedAccessForm: true,
          accessFormCountry,
        },
      },
      {
        name: BANK_ACCOUNT_INFO,
        component: LiveAccountApplicationBankAccountInfoFormC,
        props: {
          accessFormId,
          showUploadFileModal,
          goToPreviousStep,
          isHostedAccessForm: true,
          accessFormCountry,
          plaidEnabled: true,
        },
      },
      {
        name: REVIEW_AND_SUBMIT,
        component: LiveAccountApplicationReviewAndSubmitFormC,
        props: {
          accessFormId,
          showUploadFileModal,
          isHostedAccessForm: true,
          accessFormCountry,
        },
      },
    ]

    return (
      <HostedLiveAccountApplicationFlow
        {...this.props}
        steps={steps}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HostedLiveAccountApplicationFlowC)
