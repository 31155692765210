import { GET_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getCertificateSigningRequestsRequest = ({
  id,
  credentials,
  queries,
}) => createAction({
  id,
  type: GET_CERTIFICATE_SIGNING_REQUESTS_F_REQUEST,
  credentials,
  queries,
})

export default getCertificateSigningRequestsRequest
