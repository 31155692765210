import React, { Component } from 'react'
import { connect } from 'react-redux'
import Member from './Member'
import { getMemberSelector, getRoleSelector } from 'state-layer/selectors'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { goToPath } from 'state-layer/history'
import { FETCHING } from 'constants/reducerConstants'
import { GET_MEMBER_F_REQUEST } from 'constants/flowConstants'
import { SYSTEM } from 'constants/roleConstants'
import { SHOW_MFA } from 'constants/featureFlagConstants'
import { EDIT_MEMBER_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  RESEND_DASHBOARD_INVITE_MODAL,
  DEACTIVATE_TEAM_MEMBER_MODAL,
  REINSTATE_TEAM_MEMBER_MODAL,
  RESET_MEMBER_MFA_MODAL,
} from 'constants/modalConstants'

import {
  ROLE,
  ADDED,
  STATE,
  EMAIL,
  ACTIVE,
  INACTIVE,
  ADDED_BY,
  PASSWORD,
  TEAM_MEMBER,
  LAST_ACCESSED,
  AUTHENTICATION,
  EDIT_TEAM_MEMBER,
  DEACTIVATE_TEAM_MEMBER,
  RESEND_DASHBOARD_INVITE_EMAIL,
  REINSTATE_TEAM_MEMBER,
  APPLICATION_RESOURCE_TITLE,
  MEMBERS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isFetching = get(state, `membersR.${FETCHING}`)
  const dashboardId = getCurrentDashboardId()
  const memberId = get(props, 'params.memberId')
  const member = getMemberSelector(state, memberId)
  const dashboardName = get(credentials, 'name')
  const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')
  const memberDashboardUserId = get(member, 'dashboardUserId')

  const [
    email,
    fullName,
    enabled,
    displayCreatedAt,
    roleName,
    displayLastActivity,
    createdBy,
    membershipId,
    roleId,
    authenticationType,
  ] = getMany(member, [
    'email',
    'fullName',
    'enabled',
    'displayCreatedAt',
    'roleName',
    'displayLastActivity',
    'createdBy',
    'membershipId',
    'roleId',
    'authenticationType',
  ])

  const role = getRoleSelector(state, roleId)

  const [
    roleEntityId,
    roleEntityType,
    userPermissions,
    type,
    authRole,
  ] = getMany(role, [
    'entityId',
    'entityType',
    'permissions',
    'type',
    'authRole',
  ])

  const [
    urlEntityId,
    urlEntityType,
  ] = getMany(parseUrlQueries(), [
    'entityId',
    'entityType',
  ])

  const headerData = {
    resource: {
      label: TEAM_MEMBER,
      id: memberId,
    },
    items: [
      {
        value: <h1>{fullName || email}</h1>,
      },
      {
        label: STATE,
        value: <EnabledStatus value={enabled ? ACTIVE : INACTIVE} />,
      },
      {
        label: ADDED,
        value: displayCreatedAt,
      },
    ],
    isFetching,
  }

  const detailsSectionData = [
    {
      label: EMAIL,
      value: email,
    },
    {
      label: ROLE,
      value: roleName,
    },
    {
      label: AUTHENTICATION,
      value: authenticationType,
    },
    {
      label: LAST_ACCESSED,
      value: displayLastActivity,
    },
    {
      label: ADDED_BY,
      value: createdBy,
    },
  ]

  return {
    credentials,
    credentialId,
    isFetching,
    memberId,
    member,
    headerData,
    detailsSectionData,
    dashboardId,
    memberName: fullName ? fullName : email,
    membershipId,
    email,
    enabled,
    dashboardName,
    roleName,
    dashboardUserRoleType,
    entityType: roleEntityType || urlEntityType,
    entityId: roleEntityId || urlEntityId,
    userPermissions,
    type,
    authRole,
    memberDashboardUserId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMember: ({ credentials, memberId }) => dispatch({
      type: GET_MEMBER_F_REQUEST,
      payload: {
        id: memberId,
        credentials,
      },
    }),
    showResendDashboardInviteModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_DASHBOARD_INVITE_MODAL, modalProps })),
    showDeactivateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_TEAM_MEMBER_MODAL, modalProps })),
    showReinstateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: REINSTATE_TEAM_MEMBER_MODAL, modalProps })),
    showResetMemberMFAModal: (modalProps) => dispatch(showModalAction({
      modalType: RESET_MEMBER_MFA_MODAL,
      modalProps,
      className: 'modal-md no-pad',
    })),
  }
}

class MemberC extends Component {
  componentDidMount() {
    const {
      memberId,
      credentials,
      getMember,
    } = this.props

    getMember({ credentials, memberId })
  }

  render() {
    const {
      email,
      showResendDashboardInviteModal,
      memberId,
      memberName,
      showDeactivateTeamMemberModal,
      showReinstateTeamMemberModal,
      showResetMemberMFAModal,
      enabled,
      dashboardName,
      roleName,
      dashboardUserRoleType,
      credentialId,
      entityType,
      entityId,
      memberDashboardUserId,
    } = this.props

    const actions = [
      {
        label: RESEND_DASHBOARD_INVITE_EMAIL,
        action: () => showResendDashboardInviteModal({ email, dashboardName, entityId }),
        condition: enabled,
      },
      {
        label: EDIT_TEAM_MEMBER,
        action: () => {
          goToPath({
            pathname: EDIT_MEMBER_PATH({ credentialId, memberId }),
            queries: {
              entityType,
              entityId,
            },
          })
        },
        condition: dashboardUserRoleType === SYSTEM,
      },
      {
        label: DEACTIVATE_TEAM_MEMBER,
        action: () => showDeactivateTeamMemberModal({ memberId, memberName, dashboardName }),
        condition: enabled && dashboardUserRoleType === SYSTEM,
      },
      {
        label: REINSTATE_TEAM_MEMBER,
        action: () => showReinstateTeamMemberModal({ memberId, memberName, dashboardName, roleName }),
        condition: !enabled && dashboardUserRoleType === SYSTEM,
      },
      {
        label: 'Reset Multi-Factor Authentication',
        action: () => showResetMemberMFAModal({ name: memberName, dashboardUserId: memberDashboardUserId }),
        condition: getFeatureFlag(SHOW_MFA) && isAdministratorRole(),
      },
    ]

    return (
      <Member
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberC)
