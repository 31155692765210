import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import EditUnderwritingProfileForm from 'components/Customer/Forms/EditUnderwritingProfileForm/EditUnderwritingProfileForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import selectOption from 'utilities/forms/selectOption'
import { getUnderwritingProfileSelector } from 'state-layer/selectors'
import removeUndefined from 'utilities/remove/removeUndefined'
import { EDIT_UNDERWRITING_PROFILE_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PARTNER,
  EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PLATFORM,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const underwritingProfileId = get(props, 'underwritingProfileId')
  const underwritingProfile = getUnderwritingProfileSelector(state, underwritingProfileId)
  const formSelector = formValueSelector(EDIT_UNDERWRITING_PROFILE_FORM)
  const allowAllMCCsField = formSelector(state, 'allowAllMCCs')
  const merchantCategoryCodesField = formSelector(state, 'merchantCategoryCodes')

  const [
    achMaxTransactionAmount,
    maxTransactionAmount,
    mccDetails,
  ] = getMany(underwritingProfile, [
    'achMaxTransactionAmount',
    'maxTransactionAmount',
    'mccDetails',
  ])

  const enabledMCCs = get(mccDetails, 'enabledMCCs')
  const arrayOfMMCs = map(enabledMCCs, (value) => selectOption(value, value))
  const allowAllMCCs = get(mccDetails, 'allowAllMCCs')
  const initialValues = removeUndefined({ achMaxTransactionAmount, maxTransactionAmount, merchantCategoryCodes: arrayOfMMCs, allowAllMCCs })

  return {
    initialValues,
    credentials,
    allowAllMCCsField,
    merchantCategoryCodesField,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(hideModalAction()),
  resetMCCField: () => dispatch(change(EDIT_UNDERWRITING_PROFILE_FORM, 'merchantCategoryCodes', [])),
})

class EditUnderwritingProfileFormC extends Component {
  componentDidUpdate(prevProps) {
    const { allowAllMCCsField, resetMCCField } = this.props
    const { allowAllMCCsField: prevAllowAllMCCsField } = prevProps

    if ((allowAllMCCsField !== prevAllowAllMCCsField) && allowAllMCCsField === true) {
      resetMCCField()
    }
  }

  render() {
    const {
      isRolePartnerCredential,
      isApplicationInfoPage,
    } = this.props

    let modalDescriptionString = EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PARTNER

    if (!isRolePartnerCredential && !isApplicationInfoPage) {
      modalDescriptionString = EDIT_UNDERWRITING_PROFILE_DESCRIPTION_MODAL_ROLE_PLATFORM
    }

    return (
      <EditUnderwritingProfileForm
        modalDescriptionString={modalDescriptionString}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUnderwritingProfileFormC)
