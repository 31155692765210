import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import get from 'lodash/get'

const frontendSubscriptionScheduleModel = ({ data: subscriptionSchedule }) => {
  const [
    id,
    periodOffset,
    subscriptionType,
    scheduleNickname,
    fixedTimeIntervalOffset = {},
    tags,
    lineItemType,
    userId,
    createdAt,
    updatedAt,
    amounts,
  ] = getMany(subscriptionSchedule, [
    'id',
    'period_offset',
    'subscription_type',
    'nickname',
    'fixed_time_interval_offset',
    'tags',
    'line_item_type',
    'created_by',
    'created_at',
    'updated_at',
    'amounts',
  ])

  const hourlyInterval = get(fixedTimeIntervalOffset, 'hourly_interval')
  const intervalCount = get(fixedTimeIntervalOffset, 'interval_count')

  return removeUndefined({
    id,
    periodOffset,
    subscriptionType,
    scheduleNickname,
    fixedTimeIntervalOffset: {
      hourlyInterval,
      intervalCount,
    },
    tags,
    userId,
    lineItemType,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    amounts,
  })
}

export default frontendSubscriptionScheduleModel
