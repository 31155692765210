import React, { Component } from 'react'
import { connect } from 'react-redux'
import PermissionsSettings from './PermissionsSettings'
import { getRoleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getRoleRequest from 'utilities/actions/get/getRoleRequest'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { CONTACT_YOUR_ADMINISTRATOR } from 'constants/language/languageConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const roleId = get(props, 'roleId')
  const role = getRoleSelector(state, roleId)
  const isFetching = get(state, `rolesR.${FETCHING}`)
  const credentialName = get(credentials, 'name')

  const [
    type,
    permissions,
    roleName,
    authRole,
  ] = getMany(role, [
    'type',
    'permissions',
    'roleName',
    'authRole',
  ])

  const subTitle = (
    <div className='subtitle'>
      <div className='flex'>
        <div className='text'>Your Role for {credentialName} dashboard:</div>
        <div className='roleName'>{roleName}</div>
      </div>
      <div className='subtext'>{CONTACT_YOUR_ADMINISTRATOR}</div>
    </div>
  )

  return {
    credentials,
    permissions,
    type,
    isFetching,
    roleId,
    subTitle,
    authRole,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRole: ({ roleId, credentials }) => dispatch(getRoleRequest({ roleId, credentials })),
  }
}

class PermissionsSettingsC extends Component {
  componentDidMount() {
    const {
      credentials,
      roleId,
      getRole,
    } = this.props

    if (roleId) {
      getRole({ credentials, roleId })
    }
  }

  componentDidUpdate(prevProps) {
    const [
      prevCredentials,
    ] = getMany(prevProps, [
      'credentials',
    ])
    const [
      credentials,
      roleId,
      getRole,
    ] = getMany(this.props, [
      'credentials',
      'roleId',
      'getRole',
    ])

    if ((!isEmpty(credentials) && isEmpty(prevCredentials) && roleId)) {
      getRole({ roleId, credentials })
    }
  }

  render() {
    return (
      <PermissionsSettings
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionsSettingsC)
