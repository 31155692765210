import { put } from 'redux-saga/effects'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  frontendModelByResourceType,
  successActionByResourceType,
} from 'constants/relatedResourceConstants'

function * relatedResourcesSaga ({ resources }) {
  const normalizedResources = isArray(resources) ? resources : [resources]
  // loop through all resources, check for related info, run them through FE models, and group them by resourceType
  const relatedResourceModelsGroupedByResourceType = reduce(normalizedResources, (total, resource) => {
    const related = get(resource, 'related')

    if (!related) {
      return total
    }

    const groupedRelatedResources = reduce(related, (acc, relatedResource, resourceType) => {
      const frontendModel = frontendModelByResourceType[resourceType]

      if (!frontendModel) {
        return acc
      }

      return merge({}, acc, {
        [resourceType]: {
          [get(relatedResource, 'id')]: frontendModel({ data: relatedResource }),
        },
      })
    }, {})

    return merge({}, total, groupedRelatedResources)
  }, {})

  if (isEmpty(relatedResourceModelsGroupedByResourceType)) {
    return
  }

  const resourceTypes = Object.keys(relatedResourceModelsGroupedByResourceType)
  // for each group of related resources, dispatch their success function to inject into proper reducers
  for (let i = 0; i < resourceTypes.length; i += 1) {
    const resourceType = resourceTypes[i]
    const relatedResources = get(relatedResourceModelsGroupedByResourceType, resourceType)
    const successAction = successActionByResourceType[resourceType]

    if (successAction) {
      yield put({
        type: successAction,
        payload: {
          newValues: relatedResources,
        },
        meta: { skipRelated: true },
      })
    }
  }
}

export default relatedResourcesSaga
