import IdentityAuditLogs from './IdentityAuditLogs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AUDIT_LOG_EVENT_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `auditLogEventsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const auditLogEventPath = AUDIT_LOG_EVENT_PATH({ credentialId, identityId })

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    identity_id: identityId,
    type: 'IDENTITY_CREATED,IDENTITY_UPDATED,INSTRUMENT_CREATED',
  }

  return {
    initialQueries,
    isFetching,
    auditLogEventPath,
    identityId,
  }
}

class IdentityAuditLogsC extends Component {
  render() {
    return (
      <IdentityAuditLogs {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityAuditLogsC)
