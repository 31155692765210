import React from 'react'
import PropTypes from 'prop-types'
import { getPaymentLinksWithMerchantSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/PaymentLinks/columnDescriptors'
import { PAYMENT_LINKS } from 'constants/linkConstants'
import { GET_PAYMENT_LINKS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  PAYMENT_LINKS_RESOURCE_TITLE,
  PAYMENT_LINKS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const ApplicationPaymentLinks = ({
  paymentLinksPath = '',
  initialQueries,
  initialFilters,
  quickFilters,
  actions = [],
  isFetching = false,
}) => {
  return (
    <div className='ApplicationPaymentLinks'>
      <TableC
        flow={GET_PAYMENT_LINKS}
        columnDescriptors={columnDescriptors}
        selector={getPaymentLinksWithMerchantSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        quickFilters={quickFilters}
        actions={actions}
        linksKey={PAYMENT_LINKS}
        path={paymentLinksPath}
        isFetching={isFetching}
        emptyMessage={PAYMENT_LINKS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV='Application_Payment_Links'
        fileResourceTitleCSV={PAYMENT_LINKS_RESOURCE_TITLE}
      />
    </div>
  )
}

ApplicationPaymentLinks.propTypes = {
  paymentLinksPath: PropTypes.string,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.array,
  actions: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default ApplicationPaymentLinks
