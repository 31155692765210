import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import { countryNameByCountryCode } from 'constants/countryConstants'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import join from 'lodash/join'
import toLower from 'lodash/toLower'
import head from 'lodash/head'

const frontendMerchantModel = ({ data: merchant }) => {
  const [
    id,
    name,
    identityId,
    applicationId,
    merchantIdentity,
    merchantFirstName,
    merchantLastName,
    merchantName,
    merchantDoingBusinessAs,
    merchantIdentityCreatedAt,
    merchantIdentityRoles,
    merchantProfileId,
    doingBusinessAs,
    onboardingState,
    createdAt,
    processor,
    processorSpecificParameters,
    processingEnabled,
    processorDetails,
    settlementEnabled,
    tags,
    feeProfile,
    riskProfile,
    merchantProfile,
    application,
    paymentInstruments,
    payoutPlan,
    settlementFundingIdentifier,
    files,
    mcc,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    rentSurchargesEnabled,
    surchargesEnabled,
    verificationId,
    mid,
    readyToSettleUpon,
    feeReadyToSettleUpon,
    country,
    gateway,
    visaMerchantVerificationValue,
    mastercardAssignedId,
    currencies,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
  ] = getMany(merchant, [
    'id',
    'merchant_name',
    'identity',
    'application',
    'merchantIdentity',
    'merchantIdentity.entity.first_name',
    'merchantIdentity.entity.last_name',
    'merchantIdentity.entity.business_name',
    'merchantIdentity.entity.doing_business_as',
    'merchantIdentity.created_at',
    'merchantIdentity.identity_roles',
    'merchant_profile',
    'doing_business_as',
    'onboarding_state',
    'created_at',
    'processor',
    'processor_specific_parameters',
    'processing_enabled',
    'processor_details',
    'settlement_enabled',
    'tags',
    'feeProfile',
    'riskProfile',
    'merchantProfile',
    'applicationData',
    'paymentInstruments',
    'payoutPlan',
    'settlement_funding_identifier',
    'files',
    'mcc',
    'card_cvv_required',
    'convenience_charges_enabled',
    'card_expiration_date_required',
    'level_two_level_three_data_enabled',
    'rent_surcharges_enabled',
    'surcharges_enabled',
    'verification',
    'mid',
    'ready_to_settle_upon',
    'fee_ready_to_settle_upon',
    'country',
    'gateway',
    'card_network_details.visa_merchant_verification_value',
    'card_network_details.mastercard_assigned_id',
    'currencies',
    'disbursements_same_day_ach_push_enabled',
    'disbursements_same_day_ach_pull_enabled',
  ])

  return removeUndefined({
    id,
    name,
    identityId,
    applicationId,
    merchantName,
    merchantDoingBusinessAs,
    displayFullName: merchantFirstName && merchantLastName ? `${startCase(merchantFirstName)} ${startCase(merchantLastName)}` : undefined,
    displayBusinessName: formatBusinessNameString({ businessName: merchantName, doingBusinessAs: merchantDoingBusinessAs }),
    merchantProfileId,
    doingBusinessAs,
    onboardingState,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    merchantIdentityRoles,
    displayMerchantIdentityRoles: startCase(toLower(join(merchantIdentityRoles, ', '))),
    processor,
    displayProcessor: convertSnakeToSentenceCase(processor),
    processorSpecificParameters,
    processorDetails,
    processingEnabled,
    settlementEnabled,
    tags,
    riskProfile,
    merchantProfile,
    application,
    merchantIdentityCreatedAt,
    displayMerchantIdentityCreatedAt: formatDate({ date: merchantIdentityCreatedAt }),
    paymentInstruments,
    payoutPlan,
    settlementFundingIdentifier,
    files,
    mcc,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    surchargesEnabled,
    rentSurchargesEnabled,
    verificationId,
    mid,
    readyToSettleUpon,
    displayReadyToSettleUpon: startCase(capitalize(readyToSettleUpon)),
    feeReadyToSettleUpon,
    displayFeeReadyToSettleUpon: startCase(capitalize(feeReadyToSettleUpon)),
    country,
    displayCountry: countryNameByCountryCode[country],
    merchantIdentity,
    gateway,
    displayGateway: convertSnakeToSentenceCase(gateway) || convertSnakeToSentenceCase(processor),
    visaMerchantVerificationValue,
    mastercardAssignedId,
    currency: head(currencies),
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
  })
}

export default frontendMerchantModel
