import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CUSTOMER_ONBOARDING_FORMS } from 'constants/apiConstants'

const getCustomerOnboardingFormsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_CUSTOMER_ONBOARDING_FORMS,
    queries,
    meta,
  })
}

export default getCustomerOnboardingFormsAPI
