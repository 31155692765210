import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { EDIT_MERCHANT } from 'constants/amplitudeConstants'
import removeUndefined from 'utilities/remove/removeUndefined'

const submitMerchantSettingForm = (values, dispatch, props) => {
  const {
    id,
    processingEnabled,
    settlementEnabled,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    rentSurchargesEnabled,
    surchargesEnabled,
    settlementFundingIdentifier,
    visaMerchantVerificationValue,
    mastercardAssignedId,
  } = values

  const {
    actionType,
    credentials,
  } = props

  const newRecord = removeUndefined({
    processingEnabled,
    settlementEnabled,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    rentSurchargesEnabled,
    surchargesEnabled,
    settlementFundingIdentifier,
    visaMerchantVerificationValue,
    mastercardAssignedId,
  })

  dispatch({
    type: actionType,
    payload: {
      id,
      credentials,
      values: newRecord,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_MERCHANT, {
        credentials,
        processingEnabled,
        settlementEnabled,
        merchantId: id,
      }),
    },
  })
}

export default submitMerchantSettingForm
