export const responseFilesStub = {
  data: [
    {
      application: 'APxxx1',
      file_name: 'FNXIDxx1',
    },
    {
      application: 'APxxx1',
      file_name: 'FNXIDxx2',
    },
    {
      application: 'APxxx2',
      file_name: 'FNXIDxx3',
    },
    {
      application: 'APxxx2',
      file_name: 'FNXIDxx4',
    },
  ],
}
