import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const getCurrentUserRole = (state) => {
  const credentials = getCurrentCredentials(state)
  const role = get(credentials, 'role', localStorage.getItem('role'))

  return role
}

export default getCurrentUserRole
