import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYOUT_LINKS } from 'constants/apiConstants'
import { PAYOUT_LINKS as PAYOUT_LINKS_SERVICE } from 'constants/pathConstants'

const getPayoutLinksAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    path: PAYOUT_LINKS,
    queries,
    credentials,
    meta,
    service: PAYOUT_LINKS_SERVICE,
  })
}

export default getPayoutLinksAPI
