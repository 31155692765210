import createAction from 'utilities/actions/createAction'
import { GET_NOTES_F_REQUEST } from 'constants/flowConstants'

const getNotesRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_NOTES_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getNotesRequest
