import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ManagePermissionC from 'components/Customer/Pages/User/ManagePermission/ManagePermissionC'

const User = ({
  userId = '',
  actions = [],
  headerData = {},
  contextBarData = {},
}) => {
  return (
    <div className='User'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <ManagePermissionC userId={userId} />
    </div>
  )
}

User.propTypes = {
  userId: PropTypes.string,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default User
