import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetIdleScreenForm from './SetIdleScreenForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import { SET_IDLE_SCREEN_FORM } from 'constants/formConstants'
import { CREATE_IDLE_MESSAGE_VALUE } from 'constants/deviceConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  change,
  formValueSelector,
  reset,
} from 'redux-form'


import {
  FILE_UPLOAD_IDLE_SCREEN_IMAGE_DETAILS,
  FILE_UPLOAD_INSTRUCTIONS,
} from 'constants/language/languageConstants'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

const config = {
  multiple: false,
  maxSize: 300000,
  accept: 'image/jpeg, image/png, image/jpg',
}

const instructions = [
  {
    name: 'main',
    message: FILE_UPLOAD_INSTRUCTIONS,
  },
  {
    name: 'sub',
    message: FILE_UPLOAD_IDLE_SCREEN_IMAGE_DETAILS,
  },
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(SET_IDLE_SCREEN_FORM)
  const currentIdleMessageValue = formSelector(state, 'idleMessage')
  const idleMessage = get(props, 'idleMessage')
  const processorGatewayValue = get(props, 'processorGatewayValue')
  const showFileUploader = includes([FINIX_V1, DUMMY_V1], processorGatewayValue)

  return {
    credentials,
    currentIdleMessageValue,
    isUpdating: isPatching(state),
    actionType: CREATE_IDLE_MESSAGE_VALUE,
    initialValues: { idleMessage },
    showFileUploader,
    instructions,
    config,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    resetForm: () => dispatch(reset(SET_IDLE_SCREEN_FORM)),
    clearIdleMessage: () => dispatch(change(SET_IDLE_SCREEN_FORM, 'idleMessage', '')),
    removeFile: () => dispatch(change(SET_IDLE_SCREEN_FORM, 'file', null)),
  }
}

class SetIdleScreenFormC extends Component {
  clearForm = () => {
    const {
      resetForm,
      removeFile,
      clearIdleMessage,
    } = this.props

    resetForm()
    clearIdleMessage()
    removeFile()
  }


  render() {
    return (
      <SetIdleScreenForm
        {...this.props}
        clearForm={this.clearForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetIdleScreenFormC)
