import stringify from 'fast-json-stable-stringify'

const baseSerializer = (...args) => {
  return args.map((x) => {
    if (typeof x === 'object' && x !== null) { return stringify(x) }
    if (typeof x === 'string') { return `"${x}"` }

    return x
  }).join('|')
}

// in case we ever want to deserialize the data, although we should never really need to
const deserializer = (id) => id?.split('|').map((x) => JSON.parse(x))

/**
 * Memoizes a given function by caching its output based on the arguments provided.
 * This can significantly improve performance for expensive function calls by avoiding redundant computations.
 *
 * @param {Function} func - The function to be memoized. It should be a pure function without side effects.
 * @param {Function} [serializer=baseSerializer] - Optional. A function to serialize the arguments into a cache key. Defaults to `baseSerializer`.
 * @param {number} [cacheSize=Infinity] - Optional. The maximum size of the cache. Defaults to `Infinity`, meaning the cache can grow indefinitely.
 * @returns {Function} - A new function that wraps the original function with caching logic.
 *
 * @example
 * // A basic pure function
 * const add = (a, b) => a + b
 * // Memoized version of the add function
 * const memoizedAdd = memoize(add)
 *
 * console.log(memoizedAdd(1, 2)) // 3, computed and cached
 * console.log(memoizedAdd(1, 2)) // 3, returned from cache
 *
 * @example
 * // Using a custom serializer
 * const customSerializer = (...args) => args.join('-')
 * const memoizedFunction = memoize((x, y) => x * y, customSerializer, 100)
 *
 * console.log(memoizedFunction(3, 4)) // 12, computed and cached
 * console.log(memoizedFunction(3, 4)) // 12, returned from cache
 *
 * @example
 * // Function with object arguments
 * const complexFunction = (obj1, obj2) => ({ ...obj1, ...obj2 })
 * const memoizedComplexFunction = memoize(complexFunction)
 *
 * console.log(memoizedComplexFunction({a: 1}, {b: 2})) // {a: 1, b: 2}, computed and cached
 * console.log(memoizedComplexFunction({a: 1}, {b: 2})) // {a: 1, b: 2}, returned from cache
 *
 * @function baseSerializer
 * @param {...*} args - The arguments to be serialized.
 * @returns {string} - The serialized string representation of the arguments.
 *
 * @function deserializer
 * @param {string} id - The serialized string to be deserialized.
 * @returns {Array} - The deserialized array of arguments.
 */

const memoize = (func, serializer = baseSerializer, cacheSize = Infinity) => {
  const memoized = function(...args) {
    const { cache } = memoized
    const key = serializer(...args)

    if (cache.has(key)) {
      return cache.get(key)
    }

    const result = func.apply(this, args)

    if (cache.size >= cacheSize) {
      const firstKey = cache.keys().next().value
      cache.delete(firstKey)
    }

    memoized.cache = cache.set(key, result) || cache
    return result
  }

  memoized.cache = new Map()
  return memoized
}

export default memoize
