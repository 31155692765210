import './CheckboxS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import { ALERT_ICON } from 'constants/iconConstants'
import { t } from 'constants/language/languageConstants'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

// Checkbox is used for non-form checkboxes. Allows an onChange function and initial checked value
const Checkbox = ({
  input,
  type = 'checkbox',
  name,
  label = '',
  checked = false,
  handleChange = () => {},
  indeterminate = false,
  meta,
  tooltip,
  disabled = false,
  helperText,
  disabledTooltip = '',
  disabledTooltipPosition = 'top-of-radio',
  className,
}) => {
  const error = get(meta, 'error')
  const touched = get(meta, 'touched')
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const hasError = touched && errorJSX ? 'error' : ''

  const checkboxClassNames = classnames({
    'checkbox-input': true,
    flex: true,
    'items-center': helperText ? false : true,
    disabled,
    [hasError]: !!hasError,
    [className]: true,
  })

  return (
    <div className='Checkbox'>
      <div className={checkboxClassNames}>
        {/* if input is disabled, wrap the input with a tooltip */}
        {(disabled && disabledTooltip) ? (
          <TooltipLabelC
            showIcon={false}
            message={disabledTooltip}
            position={disabledTooltipPosition}
          >
            <input
              {...input}
              tabIndex='0'
              role='checkbox'
              aria-checked={checked}
              id={name}
              type={type}
              name={name}
              checked={checked}
              onChange={handleChange}
              onKeyDown={handleChange}
              disabled={disabled}
              ref={el => {
                if (!isNil(el)) {
                  el.indeterminate = indeterminate
                }
              }}
            />
          </TooltipLabelC>
        ) : (
          <input
            {...input}
            tabIndex='0'
            role='checkbox'
            aria-checked={checked}
            id={name}
            type={type}
            name={name}
            checked={checked}
            onChange={handleChange}
            onKeyDown={handleChange}
            disabled={disabled}
            ref={el => {
              if (!isNil(el)) {
                el.indeterminate = indeterminate
              }
            }}
          />
        )}

        <div className='checkbox-label-container'>
          <div className='flex'>
            { label && <span className='checkbox-label label-2'>{t(label)}</span> }

            { tooltip && <TooltipLabel message={tooltip} /> }
          </div>
          { helperText && <div className='checkbox-helper-text label-2'>{t(helperText)}</div> }
        </div>
      </div>
      {touched && errorJSX}
    </div>
  )
}

Checkbox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  meta: PropTypes.object,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  disabledTooltip: PropTypes.string,
  disabledTooltipPosition: PropTypes.string,
}

export default Checkbox
