import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import CreatePaymentInstrumentForm from './CreatePaymentInstrumentForm'
import { getDispatch } from 'state-layer/configureStore'
import hideModalAction from 'utilities/actions/hideModalAction'
import postData from 'utilities/api/postData'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import { credentialNameToEnvironmentUrl } from 'constants/credentialConstants'
import { POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS } from 'constants/flowConstants'
import { CREATE_TRANSACTION_FORM } from 'constants/formConstants'
import { BANK_ICON } from 'constants/iconConstants'
import { lowerFirst } from 'lodash'
import get from 'lodash/get'

import {
  BILLING_ADDRESS_1_LINE_LABEL,
  BILLING_ADDRESS_2_LINE_LABEL,
  CARD,
  CARD_NUMBER_EMPTY_ERROR_MESSAGE,
  CARD_NUMBER_LABEL,
  COUNTRY,
  CVV,
  EXPIRATION_DATE,
  NAME,
  ZIP_LABEL,
  ZIPCODE_EMPTY_ERROR_MESSAGE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const authToken = get(credentials, 'authToken')
  const dispatch = getDispatch()

  const [
    applicationId,
    buyerIdentityId,
    environment,
    formName,
    fieldName,
  ] = getMany(props, [
    'applicationId',
    'buyerIdentityId',
    'environment',
    'formName',
    'fieldName',
  ])

  return {
    credentials,
    applicationId,
    environment,
    buyerIdentityId,
    dispatch,
    authToken,
    formName,
    fieldName,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CreatePaymentInstrumentFormC extends Component {
  form = null

  componentDidMount() {
    const { showTokenForm } = this.props
    // the code below initializes the finix iframe and set some styling for it as well
    if (window.Finix) {
      const options = {
        showAddress: true,
        showPlaceholders: false,
        styles: {
          default: {
            border: '1px solid #7D90A5',
            borderRadius: '4px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            padding: '0px 8px',
          },
          error: {
            border: '1px solid #F04438',
          },
          focus: {
            border: '1px solid #7D90A5',
          },
        },
        labels: {
          name: `${NAME} *`,
          expiration_date: `${EXPIRATION_DATE} *`,
          security_code: `${CVV} *`,
          address_line1: BILLING_ADDRESS_1_LINE_LABEL,
          address_line2: BILLING_ADDRESS_2_LINE_LABEL,
          address_postal_code: `${ZIP_LABEL} *`,
          address_country: `${COUNTRY} *`,
          number: CARD_NUMBER_LABEL,
        },
        requiredFields: [
          'name',
          'number',
          'expiration_date',
          'security_code',
          'address_postal_code',
          'address_country',
        ],
        errorMessages: {
          name: CARD_NUMBER_EMPTY_ERROR_MESSAGE,
          address_postal_code: ZIPCODE_EMPTY_ERROR_MESSAGE,
        },
        onLoad: () => {
          document.getElementById('form').classList.remove('loading')

          const loadingSpinner = document.getElementById('loader')
          const finixForm = document.getElementById('payment-instrument-form')
          loadingSpinner.style.display = 'none'
          finixForm.style.display = 'block'
        },
        onUpdate: (state, binInfo, formHasErrors) => {
          const submitButton = document.querySelector('.CreatePaymentInstrumentModal button[type="submit"]')

          if (submitButton) {
            submitButton.disabled = formHasErrors
          }
        },
      }
      this.form = showTokenForm ? window.Finix.TokenForm('payment-instrument-form', options) : window.Finix.CardTokenForm('payment-instrument-form', options)
      const finixForm = document.getElementById('payment-instrument-form')
      finixForm.style.display = 'none'
    }
  }

  showErrors = (message) => {
    const errorDiv = document.getElementById('error-container')
    const errorElement = document.getElementById('form-errors')
    errorElement.classList.remove('hidden')
    errorDiv.classList.remove('hidden')
    errorElement.textContent = message
  }

  handleSubmit = (e) => {
    const {
      applicationId,
      buyerIdentityId,
      environment,
      closeModal,
      dispatch,
      authToken,
      formName,
      fieldName,
    } = this.props

    e.preventDefault()

    const envIframe = lowerFirst(environment)

    this.form.submit(envIframe, applicationId, async (err, response) => {
      // handle any errors that may have occurred and show the error message
      if (err) {
        const errors = get(response, 'data._embedded.errors', [])

        if (Array.isArray(errors) && errors.length > 0) {
          this.showErrors(errors[0]?.message)
          return
        }
      }

      const tokenData = response.data || {}
      const token = tokenData.id
      const apiUrl = get(credentialNameToEnvironmentUrl, environment)

      // check if we have an identity, if we do we use that to associate the token with the identity
      if (buyerIdentityId) {
        const paymentInstrumentResponse = await postData(`${apiUrl}/payment_instruments`, authToken, {
          token,
          identity: buyerIdentityId,
          type: 'TOKEN',
        })

        // all the code below runs response through FE model, updates the reducer w/ new payment instrument and updates dropdown options
        const paymentInstrumentResponseModel = frontendPaymentInstrumentModel({ data: paymentInstrumentResponse })

        const [
          paymentInstrumentId,
          displayBrand,
          maskedFullCardNumber,
          maskedAccountNumber,
          type,
        ] = getMany(paymentInstrumentResponseModel, [
          'id',
          'displayBrand',
          'maskedFullCardNumber',
          'maskedAccountNumber',
          'type',
        ])

        const paymentMethodLabel = type === 'Bank Account' ? (
          <div className='flex items-center'>
            <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
            {maskedAccountNumber}
          </div>
        ) : `${displayBrand === 'Unknown' ? CARD : displayBrand} ${maskedFullCardNumber}`

        const formToUpdate = formName ? formName : CREATE_TRANSACTION_FORM
        const fieldToUpdate = fieldName ? fieldName : 'paymentMethod'

        // update form to have new payment instrument value
        dispatch(change(formToUpdate, fieldToUpdate, { value: paymentInstrumentId, label: paymentMethodLabel }))

        // update reducer to have newly created payment instrument
        dispatch({
          type: POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
          id: paymentInstrumentId,
          payload: {
            newValues: {
              [paymentInstrumentId]: paymentInstrumentResponseModel,
            },
          },
          meta: {
            selectorId: buyerIdentityId,
          },
        })
      } else {
        // we don't have an identity, so we need to create one for the token
        const identityResponse = await postData(`${apiUrl}/identities`, authToken, {})
        const identityId = get(identityResponse, 'id')

        const paymentInstrumentResponse = await postData(`${apiUrl}/payment_instruments`, authToken, {
          token,
          identity: identityId,
          type: 'TOKEN',
        })

        // all the code below runs response through FE model, updates the reducer w/ new payment instrument and updates dropdown options
        const paymentInstrumentResponseModel = frontendPaymentInstrumentModel({ data: paymentInstrumentResponse })

        const [
          paymentInstrumentId,
          displayBrand,
          maskedFullCardNumber,
          maskedAccountNumber,
          type,
        ] = getMany(paymentInstrumentResponseModel, [
          'id',
          'displayBrand',
          'maskedFullCardNumber',
          'maskedAccountNumber',
          'type',
        ])

        const paymentMethodLabel = type === 'Bank Account' ? (
          <div className='flex items-center'>
            <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
            {maskedAccountNumber}
          </div>
        ) : `${displayBrand === 'Unknown' ? CARD : displayBrand} ${maskedFullCardNumber}`

        // update form to have new payment instrument value
        dispatch(change(CREATE_TRANSACTION_FORM, 'paymentMethod', { value: paymentInstrumentId, label: paymentMethodLabel }))

        // update reducer to have newly created payment instrument
        dispatch({
          type: POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
          id: paymentInstrumentId,
          payload: {
            newValues: {
              [paymentInstrumentId]: paymentInstrumentResponseModel,
            },
          },
        })
      }
      // close modal
      closeModal()
    })
  }

  render() {
    const { closeModal } = this.props

    return (
      <CreatePaymentInstrumentForm
        {...this.props}
        closeModal={closeModal}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentInstrumentFormC)
