import './UnregisterApplePayDomainModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'

const UnregisterApplePayDomainModal = ({
  domainToUnregister = '',
}) => {
  return (
    <div className='UnregisterApplePayDomainModal'>
      <div>{`Removing the domain "${domainToUnregister}" will prevent it from accepting Apple Pay.`} </div>
    </div>
  )
}

UnregisterApplePayDomainModal.propTypes = {
  domainToUnregister: PropTypes.string,
}

export default UnregisterApplePayDomainModal
