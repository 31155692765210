import { PATCH_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'

const patchPayoutLinkRequest = ({
  payoutLinkId,
  queries,
  credentials,
  values,
  closeModal = () => {},
}) => createAction({
  type: PATCH_PAYOUT_LINK_F_REQUEST,
  id: payoutLinkId,
  credentials,
  values,
  queries,
  meta: removeUndefined({
    actionId: sendAmplitudeActionEvent(PATCH_PAYOUT_LINK_F_REQUEST, {
      payoutLinkId,
      values,
      credentials,
    }),
    successCallback: closeModal,
  }),
})

export default patchPayoutLinkRequest
