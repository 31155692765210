import getIdentityMerchantsAPI from 'api/finix/get/getIdentityMerchantsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * getRecipientIdentityO ({
  id,
  credentials,
}) {
  const [{ data: merchantsResponse }] = yield [
    getIdentityMerchantsAPI({ values: { identityId: id }, credentials }),
  ]

  const merchants = get(merchantsResponse, '_embedded.merchants')
  const merchantsModels = frontendMerchantsModel({ data: merchants })

  const identity = merge({ id }, {
    merchants: merchantsModels,
  })

  return createSuccessResponse({
    data: [identity],
  })
}

export default co.wrap(getRecipientIdentityO)
