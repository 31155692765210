import './HelpS.scss'
import React from 'react'
import PropTypes from 'prop-types'

import {
  FINIX_SUPPORT_DOCS_URL,
  USER_GUIDE_URL,
} from 'constants/urlConstants'

import {
  DOCUMENTATION,
  CONTACT_SUPPORT,
  DOWNLOAD_MERCHANT_USER_GUIDE,
  DOWNLOAD_USER_GUIDE,
} from 'constants/language/languageConstants'

const Help = ({
  clickOnDocumentationAction = () => {},
  showContactSupportModal = () => {},
  isRoleMerchantCred = false,
}) => {
  return (
    <div className='Help menu'>
      {!isRoleMerchantCred && (
      <a href={FINIX_SUPPORT_DOCS_URL} target='blank'>
        <div className='menu-action documentation' onClick={clickOnDocumentationAction}>{DOCUMENTATION}</div>
      </a>
      )}

      <a href={USER_GUIDE_URL} target='blank'>
        <div className='menu-action download-user-guide'>{isRoleMerchantCred ? DOWNLOAD_USER_GUIDE : DOWNLOAD_MERCHANT_USER_GUIDE}</div>
      </a>

      {!isRoleMerchantCred && <div className='menu-action contact-support' onClick={showContactSupportModal}>{CONTACT_SUPPORT}</div>}
    </div>
  )
}

Help.propTypes = {
  clickOnDocumentationAction: PropTypes.func,
  showContactSupportModal: PropTypes.func,
  isRoleMerchantCred: PropTypes.bool,
}

export default Help
