import React, { Component } from 'react'
import { connect } from 'react-redux'
import SocialMediaReport from './SocialMediaReport'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import { DOT_CIRCLE } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  FACEBOOK,
  GITHUB,
  LINKEDIN,
  NO_LINK_FOUND,
  TWITTER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    linkedinUrl,
    facebookUrl,
    twitterUrl,
    githubUrl,
  ] = getMany(report, [
    'details.linkedin_url',
    'details.facebook_url',
    'details.twitter_url',
    'details.github_url',
  ])

  const socialMediaData = convertPageSectionDataToV2([
    { label: LINKEDIN, value: linkedinUrl || <div className='flex items-center'><i className={`icon fa ${DOT_CIRCLE}`} />{NO_LINK_FOUND}</div> },
    { label: FACEBOOK, value: facebookUrl || <div className='flex items-center'><i className={`icon fa ${DOT_CIRCLE}`} />{NO_LINK_FOUND}</div> },
    { label: TWITTER, value: twitterUrl || <div className='flex items-center'><i className={`icon fa ${DOT_CIRCLE}`} />{NO_LINK_FOUND}</div> },
    { label: GITHUB, value: githubUrl || <div className='flex items-center'><i className={`icon fa ${DOT_CIRCLE}`} />{NO_LINK_FOUND}</div> },
  ], 1)

  return {
    credentials,
    socialMediaData,
  }
}

class SocialMediaReportC extends Component {
  render() {
    return (
      <SocialMediaReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SocialMediaReportC)
