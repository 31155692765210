import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTableFiltersSelector } from 'state-layer/selectors'
import columnDescriptors from 'components/Customer/Pages/Disputes/columnDescriptors'
import IdentityDisputes from './IdentityDisputes'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { DISPUTE } from 'constants/language/languageConstants'
import { DISPUTES_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import { createdQueriesForLastMonths } from 'constants/queryConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  LOST,
  WON,
} from 'constants/statusConstants'

import {
  DISPUTE_STATE_QUICK_FILTER,
  DISPUTES_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRANSFER_ID,
  ADJUSTMENT_TRANSFER_ID,
  AMOUNT,
  DISPUTE_RESPONSE_STATE,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_NAME_EXACT,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_CARD_TYPE,
  INSTRUMENT_FINGERPRINT,
  TAGS_KEY_LIKE,
  TAGS_VALUE_LIKE,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(DISPUTE),
  TRANSFER_ID,
  ADJUSTMENT_TRANSFER_ID,
  AMOUNT(),
  DISPUTE_RESPONSE_STATE,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_NAME_EXACT,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_CARD_TYPE,
  INSTRUMENT_FINGERPRINT,
  TAGS_KEY_LIKE,
  TAGS_VALUE_LIKE,
], 'label')

const quickFilters = [
  DISPUTE_STATE_QUICK_FILTER,
  DISPUTES_DATES_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `disputesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const disputePath = DISPUTES_PATH({ credentialId })

  const currentFilters = getTableFiltersSelector(state)
  const isWonQuickFilter = get(currentFilters, 'state.eq', false) === WON
  const isLostQuickFilter = get(currentFilters, 'state.eq', false) === LOST
  const formattedColumnDescriptors = columnDescriptors({ isWonOrLostQuickFilter: isWonQuickFilter || isLostQuickFilter })

  const flowValues = {
    identityId,
  }

  const initialQueries = {
    offset: 0,
    sort: 'updated_at,desc',
    merchant_identity_id: identityId,
  }

  return {
    identityId,
    isFetching,
    disputePath,
    flowValues,
    columnDescriptors: formattedColumnDescriptors,
    initialQueries,
    initialFilters: createdQueriesForLastMonths(6),
    quickFilters,
    allowedFilters,
  }
}

class IdentityDisputesC extends Component {
  render() {
    return (
      <IdentityDisputes {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityDisputesC)
