import './EmailConfigurationsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import validateEmailConfigurationForm from 'utilities/validate/validateEmailConfigurationForm'
import { IMAGE_FILE_ICON } from 'constants/iconConstants'
import { EMAIL_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { DASHBOARD_BRANDING_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  EMAIL_BRANDING,
  EMAIL_BRANDING_OVERVIEW,
  DISPLAY_NAME,
  SAVE,
  LEARN_MORE_LABEL,
  UPLOAD_LOGO,
  RESET_ALL,
} from 'constants/language/languageConstants'

const EmailConfigurationsForm = ({
  handleSubmit = () => {},
  isFetching = true,
  config,
  instructions,
  resetForm = () => {},
}) => {
  return (
    <form className='EmailConfigurationsForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <h3>{EMAIL_BRANDING}</h3>
      <div className='emails-branding-overview p-1 secondary'>
        {EMAIL_BRANDING_OVERVIEW}
        <a target='blank' href={DASHBOARD_BRANDING_LEARN_MORE_LINK} className='learn-more-link'>{LEARN_MORE_LABEL}</a>
      </div>

      { isFetching && <Loader /> }

      { !isFetching && (
      <>
        <div>
          <Field
            name='companyName'
            className='company-name-input'
            label={DISPLAY_NAME}
            component={InputField}
          />
        </div>

        <Field
          name='emailLogo'
          label={UPLOAD_LOGO}
          component={FileUploader}
          fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
          config={config}
          instructions={instructions}
        />

        <div className='buttons flex'>
          <Button onClick={resetForm} label={RESET_ALL} variant='secondary' className='reset-all-button' />
          <Button type='submit' label={SAVE} />
        </div>
      </>
      )}
    </form>
  )
}

EmailConfigurationsForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
}

export default reduxForm({
  enableReinitialize: true,
  validate: validateEmailConfigurationForm,
  form: EMAIL_CONFIGURATIONS_FORM,
})(EmailConfigurationsForm)
