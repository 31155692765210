import i18next from 'i18next'
import get from 'lodash/get'
import getBrowserLanguage from 'utilities/get/getBrowserLanguage'
import { ES_TRANSLATION } from 'constants/language/translations/esTranslation'
import { FR_TRANSLATION } from 'constants/language/translations/frTranslation'
import { FR_CA_TRANSLATION } from 'constants/language/translations/frcaTranslation'
import { getDispatch } from 'state-layer/configureStore'

import {
  EN,
  ES,
  FR,
  FR_FR,
  FR_CA,
  languageCodeMap,
} from 'constants/language/isoCodeConstants'

export const changeLanguage = (lng) => {
  if (i18next) {
    i18next.changeLanguage(lng, (err, t) => {
      const dispatch = getDispatch()

      dispatch({
        type: 'SET_LANGUAGE',
        payload: { lng },
      })
    })
  }
}

export default {
  init: () => {
    const languageCode = getBrowserLanguage()
    const lng = get(languageCodeMap, languageCode, EN)

    i18next.init({
      lng,
      resources: {
        [ES]: { translation: ES_TRANSLATION },
        [FR]: { translation: FR_TRANSLATION },
        [FR_FR]: { translation: FR_TRANSLATION },
        [FR_CA]: { translation: FR_CA_TRANSLATION },
      }
    }, function (err, t) {
      // library is initialized
    })
  },
}
