import './ReinstateTeamMemberS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  REINSTATE_TEAM_MEMBER_MSG,
  KEEP_INACTIVE_CONFIRMATION_LABEL,
  REINSTATE_TEAM_MEMBER_CONFIRMATION_LABEL,
} from 'constants/language/languageConstants'

const ReinstateTeamMember = ({
  memberName = '',
  dashboardName = '',
  roleName = '',
  closeModal = () => {},
  reinstateTeamMember = () => {},
}) => {
  return (
    <div className='ReinstateTeamMember'>
      <div className='reinstate-description'>This will reinstate {memberName} to {dashboardName} dashboard and they will have {roleName} permissions.</div>
      <div className='deactivate-confirmation-msg'>{REINSTATE_TEAM_MEMBER_MSG}</div>

      <div className='btn-container flex flex-end items-center'>
        <Button onClick={closeModal} label={KEEP_INACTIVE_CONFIRMATION_LABEL} variant='ghost' className='cancel-button' />
        <Button onClick={reinstateTeamMember} label={REINSTATE_TEAM_MEMBER_CONFIRMATION_LABEL} />
      </div>
    </div>
  )
}

ReinstateTeamMember.propTypes = {
  memberName: PropTypes.string,
  dashboardName: PropTypes.string,
  roleName: PropTypes.string,
  closeModal: PropTypes.func,
  reinstateTeamMember: PropTypes.func,
}

export default ReinstateTeamMember
