import { EMAIL_REGEX } from 'constants/regexConstants'
import { EMAIL_ADDRESS } from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
} from 'utilities/validate'

const validateNewUserForm = ({
  name,
  email,
  access,
  template,
}) => {
  return {
    name: fieldEmpty(name, 'Name'),
    email: invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
    access: fieldEmpty(access, 'Access Level'),
    template: fieldEmpty(template, 'Email Template'),
  }
}

export default validateNewUserForm
