import { take, put, fork } from 'redux-saga/effects'
import getUnderwritingWorkflowAPI from 'api/finix/get/getUnderwritingWorkflowAPI'
import frontendUnderwritingWorkflowsModel from 'utilities/create/models/frontendUnderwritingWorkflowsModel'
import get from 'lodash/get'

import {
  GET_UNDERWRITING_WORKFLOW_F_FAILURE,
  GET_UNDERWRITING_WORKFLOW_F_REQUEST,
  GET_UNDERWRITING_WORKFLOW_F_SUCCESS,
} from 'constants/flowConstants'

const fetchUnderwritingWorkflow = function * ({ payload }) {
  const { id, queries, credentials } = payload
  const { data, error } = yield getUnderwritingWorkflowAPI({ id, queries, credentials })

  if (error) {
    yield put({
      type: GET_UNDERWRITING_WORKFLOW_F_FAILURE,
      payload,
      errors: [get(error, 'message', 'Unknown error occurred.')],
    })
    return
  }

  yield put({
    type: GET_UNDERWRITING_WORKFLOW_F_SUCCESS,
    payload: {
      id,
      queries,
      credentials,
      newValues: frontendUnderwritingWorkflowsModel({ data: [data] }),
    },
  })
}

const getUnderwritingWorkflowF = function * () {
  while (true) {
    const { payload } = yield take([GET_UNDERWRITING_WORKFLOW_F_REQUEST])

    // fork request to init non-blocking fetch event
    yield fork(fetchUnderwritingWorkflow, { payload })
  }
}

export default getUnderwritingWorkflowF
