import {
  PAYOUTS,
  DASHBOARD,
  TRANSFERS,
  TRANSACTIONS,
  AUTHORIZATIONS,
  SETTLEMENTS,
  BALANCE_TRANSFERS,
  DISPUTES,
  REPORTS,
  IDENTITIES,
  MERCHANTS,
  APPLICATIONS,
  PAYMENT_INSTRUMENTS,
  ONBOARDING_SETTLEMENTS,
  ONBOARDING_MERCHANTS,
  ONBOARDING_IDENTITIES,
  USERS,
  SETTINGS,
  ONBOARDING,
  UPDATE_TRANSFERS,
  UPDATE_VERIFICATIONS,
  PLATFORMS,
  SUBSCRIPTION_BILLING,
  // route params name
  CREDENTIAL_ID,
  TRANSFER_ID,
  AUTHORIZATION_ID,
  SETTLEMENT_ID,
  DISPUTE_ID,
  IDENTITY_ID,
  MERCHANT_ID,
  APPLICATION_ID,
  PROCESSOR,
  PAYMENT_INSTRUMENT_ID,
  REVIEW_QUEUE_ID,
  IDENTITY_UPLOADER,
  PAYOUT_PLANS,
  DEVELOPER,
  COMPANY,
  EXPORTS,
  REFUNDS,
  MERCHANT_DEPOSITS,
  ACH_RETURNS,
  COMPLIANCE,
  MEMBERS,
  HOME,
  PAYMENTS,
  ONBOARDING_FORMS,
  ONBOARDING_APPLICATIONS,
  UNDERWRITING,
  REPORTS_HOME,
  REVIEW_SETTLEMENTS,
  COMPLIANCE_FORMS,
  NOTIFICATIONS,
  GUEST_PAYMENT_LINK,
  GUEST_PAYOUT_LINK,
  GUEST_CHECKOUT_FORM,
  GUEST_PAYMENT_LINK_CONFIRMATION,
  GUEST_PAYOUT_LINK_CONFIRMATION,
  SUBSCRIPTIONS,
  SUBSCRIPTION_PLANS,
} from 'constants/pathConstants'

export const CREDENTIALS_PAGE = 'Credentials'
export const DASHBOARD_PAGE = 'Dashboard'
export const TRANSFERS_PAGE = 'Transfers'
export const TRANSACTION_PAGE = 'Transactions'
export const PAYOUTS_PAGE = 'Payouts'
export const AUTHORIZATIONS_PAGE = 'Authorizations'
export const SETTLEMENTS_PAGE = 'Settlements'
export const BALANCE_TRANSFERS_PAGE = 'Balance Transfers'
export const DISPUTES_PAGE = 'Disputes'
export const REPORTS_PAGE = 'Reports'
export const IDENTITIES_PAGE = 'Identities'
export const MERCHANTS_PAGE = 'Merchants'
export const APPLICATIONS_PAGE = 'Applications'
export const PAYMENT_INSTRUMENTS_PAGE = 'Payment Instruments'
export const ONBOARDING_SETTLEMENTS_PAGE = 'Onboarding Settlements'
export const ONBOARDING_SETTLEMENTS_LIST_PAGE = 'Onboarding Settlements List'
export const ONBOARDING_MERCHANTS_PAGE = 'Onboarding Merchants'
export const ONBOARDING_IDENTITIES_PAGE = 'Onboarding Identities'
export const USERS_PAGE = 'Users'
export const SETTINGS_PAGE = 'Settings'
export const PLATFORMS_PAGE = 'Platforms'
export const UPDATE_TRANSFERS_PAGE = 'Update Transfers'
export const UPDATE_VERIFICATIONS_PAGE = 'Update Verifications'
export const UPLOAD_IDENTITY_PAGE = 'Upload Identity'
export const SUBSCRIPTION_BILLING_PAGE = 'Subscription Billing'
export const PAYOUT_PLANS_PAGE = 'Payout Plans'
export const DEVELOPER_PAGE = 'Developer'
export const COMPANY_PAGE = 'Company'
export const EXPORTS_PAGE = 'Exports'
export const REFUNDS_PAGE = 'Refunds'
export const MERCHANT_DEPOSITS_PAGE = 'Merchant Deposits'
export const ACH_RETURNS_PAGE = 'ACH Returns'
export const COMPLIANCE_PAGE = 'Compliance'
export const MEMBER_PAGE = 'Member'
export const HOME_PAGE = 'Home'
export const PAYMENTS_PAGE = 'Payments'
export const ONBOARDING_FORMS_PAGE = 'Onboarding Forms'
export const ONBOARDING_APPLICATIONS_PAGE = 'Application Underwriting'
export const UNDERWRITING_PAGE = 'Underwriting'
export const REPORTS_HOME_PAGE = 'Reports Home'
export const REVIEW_SETTLEMENTS_PAGE = 'Review Settlements'
export const COMPLIANCE_FORMS_PAGE = 'Compliance Forms'
export const NOTIFICATIONS_PAGE = 'Notifications Page'
export const GUEST_PAYMENT_LINK_PAGE = 'Guest Payment Link Page'
export const GUEST_PAYOUT_LINK_PAGE = 'Guest Payout Link Page'
export const GUEST_CHECKOUT_FORM_PAGE = 'Guest Checkout Form Page'
export const GUEST_PAYMENT_LINK_CONFIRMATION_PAGE = 'Guest Payment Link Confirmation Page'
export const GUEST_PAYOUT_LINK_CONFIRMATION_PAGE = 'Guest Payout Link Confirmation Page'
export const SUBSCRIPTION_PAGE = 'Subscription Page'
export const SUBSCRIPTION_PLAN_PAGE = 'Subscription Plan Page'

export const NON_LIST_VIEWS = [UPDATE_TRANSFERS_PAGE, UPDATE_VERIFICATIONS_PAGE, UPLOAD_IDENTITY_PAGE, HOME_PAGE]

export const PATH_TO_PAGE_MAP = {
  [DASHBOARD]: CREDENTIALS_PAGE,
  [TRANSFERS]: TRANSFERS_PAGE,
  [TRANSACTIONS]: TRANSACTION_PAGE,
  [AUTHORIZATIONS]: AUTHORIZATIONS_PAGE,
  [SETTLEMENTS]: SETTLEMENTS_PAGE,
  [BALANCE_TRANSFERS]: BALANCE_TRANSFERS_PAGE,
  [DISPUTES]: DISPUTES_PAGE,
  [REPORTS]: REPORTS_PAGE,
  [IDENTITIES]: IDENTITIES_PAGE,
  [MERCHANTS]: MERCHANTS_PAGE,
  [APPLICATIONS]: APPLICATIONS_PAGE,
  [PAYMENT_INSTRUMENTS]: PAYMENT_INSTRUMENTS_PAGE,
  [ONBOARDING_SETTLEMENTS]: ONBOARDING_SETTLEMENTS_PAGE,
  [ONBOARDING_MERCHANTS]: ONBOARDING_MERCHANTS_PAGE,
  [ONBOARDING_IDENTITIES]: ONBOARDING_IDENTITIES_PAGE,
  [USERS]: USERS_PAGE,
  [SETTINGS]: SETTINGS_PAGE,
  // [ONBOARDING]: ONBOARDING_PAGE,
  [UPDATE_TRANSFERS]: UPDATE_TRANSFERS_PAGE,
  [UPDATE_VERIFICATIONS]: UPDATE_VERIFICATIONS_PAGE,
  [PLATFORMS]: PLATFORMS_PAGE,
  [PAYOUTS]: PAYOUTS_PAGE,
  [IDENTITY_UPLOADER]: UPLOAD_IDENTITY_PAGE,
  [SUBSCRIPTION_BILLING]: SUBSCRIPTION_BILLING_PAGE,
  [PAYOUT_PLANS]: PAYOUT_PLANS_PAGE,
  [DEVELOPER]: DEVELOPER_PAGE,
  [COMPANY]: COMPANY_PAGE,
  [EXPORTS]: EXPORTS_PAGE,
  [REFUNDS]: REFUNDS_PAGE,
  [MERCHANT_DEPOSITS]: MERCHANT_DEPOSITS_PAGE,
  [ACH_RETURNS]: ACH_RETURNS_PAGE,
  [COMPLIANCE]: COMPLIANCE_PAGE,
  [MEMBERS]: MEMBER_PAGE,
  [HOME]: HOME_PAGE,
  [PAYMENTS]: PAYMENTS_PAGE,
  [ONBOARDING_FORMS]: ONBOARDING_FORMS_PAGE,
  [ONBOARDING_APPLICATIONS]: ONBOARDING_APPLICATIONS_PAGE,
  [UNDERWRITING]: UNDERWRITING_PAGE,
  [REPORTS_HOME]: REPORTS_HOME_PAGE,
  [REVIEW_SETTLEMENTS]: REVIEW_SETTLEMENTS_PAGE,
  [COMPLIANCE_FORMS]: COMPLIANCE_FORMS_PAGE,
  [NOTIFICATIONS]: NOTIFICATIONS_PAGE,
  [GUEST_PAYMENT_LINK]: GUEST_PAYMENT_LINK_PAGE,
  [GUEST_PAYOUT_LINK]: GUEST_PAYOUT_LINK_PAGE,
  [GUEST_CHECKOUT_FORM]: GUEST_CHECKOUT_FORM_PAGE,
  [GUEST_PAYMENT_LINK_CONFIRMATION]: GUEST_PAYMENT_LINK_CONFIRMATION_PAGE,
  [GUEST_PAYOUT_LINK_CONFIRMATION]: GUEST_PAYOUT_LINK_CONFIRMATION_PAGE,
  [SUBSCRIPTIONS]: SUBSCRIPTION_PAGE,
  [SUBSCRIPTION_PLANS]: SUBSCRIPTION_PLAN_PAGE,
}

const TRANSFERS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${TRANSFERS}`
const PAYMENTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${TRANSACTIONS}`
const AUTHORIZATIONS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${AUTHORIZATIONS}`
const SETTLEMENTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${SETTLEMENTS}`
const DISPUTES_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${DISPUTES}`
const IDENTITIES_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${IDENTITIES}`
const MERCHANTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${MERCHANTS}`
const APPLICATIONS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${APPLICATIONS}`
const PAYMENT_INSTRUMENTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${PAYMENT_INSTRUMENTS}`
const ONBOARDING_SETTLEMENTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${ONBOARDING_SETTLEMENTS}`
const ONBOARDING_MERCHANTS_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${ONBOARDING_MERCHANTS}`
const ONBOARDING_IDENTITIES_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${ONBOARDING_IDENTITIES}`

const TRANSFER_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${TRANSFERS}/${TRANSFER_ID}`
const TRANSACTION_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${TRANSFERS}/${TRANSFER_ID}`
const AUTHORIZATION_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${AUTHORIZATIONS}/${AUTHORIZATION_ID}`
const SETTLEMENT_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${SETTLEMENTS}/${SETTLEMENT_ID}`
const DISPUTE_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${DISPUTES}/${DISPUTE_ID}`
const IDENTITY_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${IDENTITIES}/${IDENTITY_ID}`
const MERCHANT_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${MERCHANTS}/${MERCHANT_ID}`
const APPLICATION_PATH_FORMAT = `${DASHBOARD}/${CREDENTIAL_ID}/${APPLICATIONS}/${APPLICATION_ID}`

const createRecordText = (recordName) => `Create a new ${recordName} by clicking the Create ${recordName} button. Follow the steps presented to complete the action.`

const ID_SEARCH_HIGHLIGHTS = {
  selector: '.SearchForm',
  description: 'You can search for any object in the system by entering in the ID in the global search bar at the top of the Dashboard. The search bar supports both Resource and Trace ID searches.',
  padding: 0,
  left: '200px',
}

const FILTER_HIGHLIGHTS = {
  selector: '.createFilter',
  description: 'Data tables can be filtered by selecting a filter type from the drop down and inputting or selecting a value. Click the + Add Filter button to save your filter. Multiple filters can be used at the same time.',
  padding: 5,
}

const EXPORT_HIGHLIGHTS = {
  selector: '.exportContainer',
  description: 'Data can be exported to a .CSV by clicking on the gear icon at the top right of the Dashboard. See the pop-up modal for export details. Only data that has been loaded into the table can be exported.',
  padding: 5,
}

const TABLE_CREATED_AT_HIGHLIGHTS = {
  selector: '.date',
  description: 'For specific tables, you can sort data by date and time by clicking on the \'Created\' icon. Click on the down arrow to sort by oldest to latest.',
  padding: 5,
}

const TABLE_ITEMS_HIGHLIGHTS = {
  selector: '.items',
  description: 'To view more data, scroll down using the scroll bar to the right of Dashboard.',
  top: '40px'
}

// Create Identity button remove from identities page
// const CREATE_IDENTITY_HIGHLIGHTS = {
//   selector: '.createIdentity',
//   description: createRecordText('Identity'),
//   padding: 5,
// }

const CREATE_APPLICATION_HIGHLIGHTS = {
  selector: '.createApplication',
  description: createRecordText('Application'),
  padding: 5,
}

const CHECKBOX_HIGHLIGHTS = {
  selector: '.checkbox',
  description: 'Select or unselect all rows with this checkbox, or use individual checkboxes for each row. At this time, only rows loaded into the table at the bottom are selected with select-all.',
  padding: 5,
}

const FOOTER_ACTIONS_HIGHLIGHTS = {
  selector: '.footer-actions',
  description: 'Perform actions on selected table items here.',
  padding: 5,
}

const RELATED_OBJECTS_HIGHLIGHTS = {
  selector: '.relatedObjects',
  description: 'To view the current resource\'s related objects, click on the Related Objects dropdown.',
  padding: 5,
}

const EDIT_TAGS_HIGHLIGHTS = {
  selector: '.tagsSection .actions button',
  description: 'Add or Remove custom tags to your resources to keep track of any external information that may be helpful.',
  padding: 5,
  top: '35%',
}

const VIEW_PAGE_HIGHLIGHTS = [RELATED_OBJECTS_HIGHLIGHTS]
const LIST_PAGE_HIGHLIGHTS = [ID_SEARCH_HIGHLIGHTS, EXPORT_HIGHLIGHTS, TABLE_CREATED_AT_HIGHLIGHTS, TABLE_ITEMS_HIGHLIGHTS]
const LIST_PAGE_WITH_FILTER_HIGHLIGHTS = [ID_SEARCH_HIGHLIGHTS, FILTER_HIGHLIGHTS, EXPORT_HIGHLIGHTS, TABLE_CREATED_AT_HIGHLIGHTS, TABLE_ITEMS_HIGHLIGHTS]

const ACTION_LIST_PAGE_HIGHLIGHTS = [CHECKBOX_HIGHLIGHTS, FOOTER_ACTIONS_HIGHLIGHTS, ...LIST_PAGE_HIGHLIGHTS]
const ACTION_LIST_PAGE_WITH_FILTER_HIGHLIGHTS = [CHECKBOX_HIGHLIGHTS, FOOTER_ACTIONS_HIGHLIGHTS, ...LIST_PAGE_WITH_FILTER_HIGHLIGHTS]

const TRANSFERS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const PAYMENTS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const AUTHORIZATIONS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const SETTLEMENTS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const DISPUTES_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const IDENTITIES_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const MERCHANTS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const APPLICATIONS_HIGHLIGHTS = [CREATE_APPLICATION_HIGHLIGHTS, ...LIST_PAGE_WITH_FILTER_HIGHLIGHTS]
const PAYMENT_INSTRUMENTS_HIGHLIGHTS = LIST_PAGE_WITH_FILTER_HIGHLIGHTS

const ONBOARDING_SETTLEMENTS_HIGHLIGHTS = ACTION_LIST_PAGE_WITH_FILTER_HIGHLIGHTS
const ONBOARDING_MERCHANTS_HIGHLIGHTS = ACTION_LIST_PAGE_HIGHLIGHTS
const ONBOARDING_IDENTITIES_HIGHLIGHTS = ACTION_LIST_PAGE_HIGHLIGHTS

export const PATH_QUERY = {
  [SETTLEMENT_PATH_FORMAT]: ['tab'],
  [IDENTITY_PATH_FORMAT]: ['tab'],
  [APPLICATION_PATH_FORMAT]: ['tab'],
}

// TODO: Add in the view pages once the help icon has been moved
export const PAGE_NAME_TO_HIGHLIGHT_MAP = {
  [TRANSFER_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [TRANSFERS_PATH_FORMAT]: TRANSFERS_HIGHLIGHTS,
  [PAYMENTS_PATH_FORMAT]: PAYMENTS_HIGHLIGHTS,
  [TRANSACTION_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [AUTHORIZATION_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [AUTHORIZATIONS_PATH_FORMAT]: AUTHORIZATIONS_HIGHLIGHTS,
  [SETTLEMENT_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [SETTLEMENTS_PATH_FORMAT]: SETTLEMENTS_HIGHLIGHTS,
  [DISPUTE_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [DISPUTES_PATH_FORMAT]: DISPUTES_HIGHLIGHTS,
  [IDENTITY_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [IDENTITIES_PATH_FORMAT]: IDENTITIES_HIGHLIGHTS,
  [MERCHANT_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [MERCHANTS_PATH_FORMAT]: MERCHANTS_HIGHLIGHTS,
  [APPLICATION_PATH_FORMAT]: VIEW_PAGE_HIGHLIGHTS,
  [APPLICATIONS_PATH_FORMAT]: APPLICATIONS_HIGHLIGHTS,
  [PAYMENT_INSTRUMENTS_PATH_FORMAT]: PAYMENT_INSTRUMENTS_HIGHLIGHTS,
  [ONBOARDING_SETTLEMENTS_PATH_FORMAT]: ONBOARDING_SETTLEMENTS_HIGHLIGHTS,
  [ONBOARDING_MERCHANTS_PATH_FORMAT]: ONBOARDING_MERCHANTS_HIGHLIGHTS,
  [ONBOARDING_IDENTITIES_PATH_FORMAT]: ONBOARDING_IDENTITIES_HIGHLIGHTS,
}
