import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { HOSTED_PAYMENTS_TOKEN_VERIFICATION } from 'constants/apiConstants'

const getGuestCheckoutTokenVerificationAPI = () => {
  return guestCheckoutAPI.post({
    path: HOSTED_PAYMENTS_TOKEN_VERIFICATION,
  })
}

export default getGuestCheckoutTokenVerificationAPI
