import React from 'react'
import { connect } from 'react-redux'
import PreviewPanel from 'components/Shared/PreviewPanel/PreviewPanel'
import hidePreviewPanelAction from 'utilities/actions/hidePreviewPanelAction'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const panelProps = get(state, 'panelR.panelProps')
  const panelOpen = get(state, 'panelR.show', false)

  const [
    resourceId,
    viewPageLink,
    Component,
    prevId,
    nextId,
  ] = getMany(panelProps, [
    'resourceId',
    'viewPageLink',
    'PanelComponent',
    'prevId',
    'nextId',
  ])

  return {
    resourceId,
    viewPageLink,
    Component,
    panelProps,
    panelOpen,
    prevId,
    nextId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePanel: () => { dispatch(hidePreviewPanelAction()) },
  }
}

class PreviewPanelC extends React.Component {
  constructor(props) {
    super(props)

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { panelOpen: prevPanelOpen } = prevProps
    const { panelOpen } = this.props

    if (prevPanelOpen !== panelOpen) {
      if (panelOpen) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside = (event) => {
    const { closePanel } = this.props
    const isListItem = !!event.target.closest('.item')

    if (!this.wrapperRef?.contains(event.target) && !isListItem) {
      closePanel()
    }
  }

  // TODO: look into a better way of somehow handling correctly updating and passing current item's prev + next everytime without direct access to table items
  handlePreviewItemsNavigation = ({ prev, next }) => {
    const {
      prevId,
      nextId,
    } = this.props

    if (prev) {
      const prevRowElement = document.querySelector(`#${prevId} .table-item > ul`)
      if (prevRowElement) prevRowElement.click()
    }

    if (next) {
      const nextElement = document.querySelector(`#${nextId} .table-item > ul`)
      if (nextElement) nextElement.click()
    }
  }

  render() {
    return (
      <PreviewPanel
        {...this.props}
        setWrapperRef={this.setWrapperRef}
        handlePreviewItemsNavigation={this.handlePreviewItemsNavigation}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPanelC)
