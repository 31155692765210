import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT_FUNDING_TRANSFER_ATTEMPTS,
  SETTLEMENTS_BASE,
  FINIX_VERSION,
  FINIX_VERSION_2022_02_01,
} from 'constants/apiConstants'

const getInstantPayoutsAPI = ({ queries, credentials, meta }) => {
  const { selectorId } = meta

  return paymentsAPI.get({
    path: SETTLEMENT_FUNDING_TRANSFER_ATTEMPTS({ settlementId: selectorId }),
    queries,
    credentials,
    meta,
    service: SETTLEMENTS_BASE,
    headers: {
      [FINIX_VERSION]: FINIX_VERSION_2022_02_01,
    },
  })
}

export default getInstantPayoutsAPI
