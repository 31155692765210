import frontendReportModel from 'utilities/create/models/frontendReportModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendReportsModel = ({ data: reports }) => {
  const reportsModel = map(reports, (report) => frontendReportModel({ data: report }))

  return keyBy(reportsModel, 'id')
}

export default frontendReportsModel
