import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYMENT_LINK,
  PAYMENT_LINKS,
} from 'constants/apiConstants'

const patchPaymentLinkAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    path: PAYMENT_LINK({ paymentLinkId: id }),
    values,
    meta,
    credentials,
    service: PAYMENT_LINKS,
  })
}

export default patchPaymentLinkAPI
