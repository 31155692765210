import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import keys from 'lodash/keys'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import {
  CLEAR_TABLE_IDS,
  REFRESH_TABLE,
  TOGGLE_PAGINATION,
} from 'constants/actionConstants'

const initialState = {
  pagination: false,
  tableStates: {},
}

const tableR = (state = initialState, action = {}) => {
  const actionType = get(action, 'type', '')
  const linksKey = get(action, 'payload.linksKey')

  if (actionType.includes('_F_REQUEST') && linksKey) {
    return merge({}, state, {
      tableStates: {
        [linksKey]: {
          [FETCHING]: true,
        },
      },
    })
  }

  if (actionType.includes('_F_SUCCESS') && linksKey) {
    const newIds = keys(get(action, 'payload.newValues', {}))
    const existingIds = get(state, `tableStates.${linksKey}.activeIds`, [])

    return merge({}, state, {
      tableStates: {
        [linksKey]: {
          activeIds: uniq(concat(existingIds, newIds)),
          [FETCHING]: false,
        },
      },
    })
  }

  if (actionType.includes('_F_FAILURE') && linksKey) {
    return merge({}, state, {
      tableStates: {
        [linksKey]: {
          [FETCHING]: false,
        },
      },
    })
  }

  switch (action.type) {
    case REFRESH_TABLE:
      return merge({}, state, {
        shouldRefresh: true,
      })

    case TOGGLE_PAGINATION:
      return merge({}, state, {
        pagination: get(action, 'payload.status'),
      })

    case CLEAR_TABLE_IDS:
      if (get(state, `tableStates.${linksKey}`)) {
        const newState = cloneDeep(state)
        set(newState, `tableStates.${linksKey}.activeIds`, [])
        return newState
      }
      return state

    default:
      return omit(state, 'shouldRefresh')
  }
}

export default tableR
