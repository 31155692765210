import get from 'lodash/get'
import has from 'lodash/has'
import invert from 'lodash/invert'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import createQueryString from 'utilities/create/createQueryString'
import { PATHNAME, QUERY } from 'constants/queryConstants'
import { PATH_QUERY } from 'constants/pageConstants'

const getFormattedPath = (state, props) => {
  const pathname = get(state, PATHNAME)
  const queries = get(state, QUERY)

  const params = invert(get(props, 'params', {}))

  const mainPath = pathname.split('/').map((path) => {
    return has(params, path) ? get(params, path) : path
  }).join('/')

  const pathQuery = get(PATH_QUERY, mainPath, [])

  const pathQueries = reduce(pathQuery, (total, query) => {
    if (has(queries, query)) {
      return merge({}, total, { [query]: get(queries, query) })
    }

    return total
  }, {})

  return isEmpty(pathQueries) ? mainPath : `${mainPath}${createQueryString({ queries: pathQueries })}`
}

export default getFormattedPath
