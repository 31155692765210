import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getFilesAPI from 'api/dashboard/get/getFilesAPI'
import getPaymentInstrumentsAPI from 'api/finix/get/getPaymentInstrumentsAPI'
import getUnderwritingReviewsAPI from 'api/dashboard/get/getUnderwritingReviewsAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getUnderwritingReviewRunsAPI from 'api/finix/get/getUnderwritingReviewRunsAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendProcessorsModel from 'utilities/create/models/frontendProcessorsModel'
import frontendPaymentInstrumentsModel from 'utilities/create/models/frontendPaymentInstrumentsModel'
import frontendMerchantsModel from 'utilities/create/models/frontendMerchantsModel'
import frontendUnderwritingRunsModel from 'utilities/create/models/frontendUnderwritingRunsModel'
import frontendUnderwritingReviewsModel from 'utilities/create/models/frontendUnderwritingReviewsModel'
import frontendFilesModel from 'utilities/create/models/frontendFilesModel'
import getMany from 'utilities/get/getMany'
import merge from 'lodash/merge'
import get from 'lodash/get'
import map from 'lodash/map'
import values from 'lodash/values'
import co from 'co'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

function * getIdentityO ({
  id,
  credentials,
  meta,
}) {
  const {
    skipOrchestration = false,
  } = meta

  const { data: response } = yield getIdentityAPI({ id, credentials })

  if (skipOrchestration) {
    return createSuccessResponse({
      data: [response],
    })
  }

  const links = getResponseLinks(response)

  const [
    applicationLink,
    merchantsLink,
  ] = getMany(links, [
    'application',
    'merchants',
  ])

  const [
    { data: application },
    { data: merchantsResponse },
    { data: paymentInstrumentsResponse },
    { data: underwritingReviewsResponse },
    // { data: identityPayoutPlanInstrumentMappingResponse },
  ] = yield [
    hasPartnerAccess({ credentials }) ? getPaymentsAPI({
      absolutePath: applicationLink,
      credentials,
    }) : {},
    getPaymentsAPI({ absolutePath: merchantsLink, credentials }),
    getPaymentInstrumentsAPI({ credentials, queries: { owner_identity_id: id } }),
    hasPlatformAccess({ credentials }) ? getUnderwritingReviewsAPI({ credentials, queries: { identity_id: id } }) : {},
    // getIdentityPayoutPlanInstrumentMappingAPI({ credentials, id }),
  ]

  const merchants = get(merchantsResponse, '_embedded.merchants')
  const merchantsModels = frontendMerchantsModel({ data: merchants })

  const identityFilesResponse = yield getFilesAPI({
    queries: {
      identity_id: id,
      limit: 1000,
    },
    credentials,
  })
  const identityFiles = get(identityFilesResponse, 'data._embedded.files', [])

  const paymentInstruments = get(paymentInstrumentsResponse, '_embedded.payment_instruments')
  const paymentInstrumentsModels = frontendPaymentInstrumentsModel({ data: paymentInstruments })

  const processorsLink = get(application, '_links.processors.href')
  const { data: processorsResponse } = yield getPaymentsAPI({ absolutePath: processorsLink, credentials })
  const processors = get(processorsResponse, '_embedded.processors')
  const processorModels = map(frontendProcessorsModel({ data: processors }), (processor) => processor)

  const applicationModel = frontendApplicationModel({ data: application })
  const applicationModelWithProcessorInfo = merge({}, applicationModel, { processors: processorModels })

  const underwritingReviews = values(frontendUnderwritingReviewsModel({ data: get(underwritingReviewsResponse, '_embedded.reviews') }))

  const underwritingReviewsWithRuns = yield map(underwritingReviews, function * (item) {
    const reviewId = get(item, 'id')
    const { data: underwritingReviewRunsResponse } = yield getUnderwritingReviewRunsAPI({ id: reviewId, credentials })
    const underwritingReviewRuns = values(frontendUnderwritingRunsModel({ data: get(underwritingReviewRunsResponse, '_embedded.runs') }))

    return merge({}, item, { runs: underwritingReviewRuns })
  })

  // const payoutPlanInstrumentMapping = get(identityPayoutPlanInstrumentMappingResponse, '_embedded.payout_plan_instrument_mappings')
  // const payoutPlanInstrumentMappingModels = frontendPayoutPlanInstrumentMappingsModel({ data: payoutPlanInstrumentMapping })

  const identity = merge({}, response, {
    application: applicationModelWithProcessorInfo,
    paymentInstruments: paymentInstrumentsModels,
    merchants: merchantsModels,
    files: frontendFilesModel({ data: identityFiles }),
    underwritingReviews: underwritingReviewsWithRuns,
    // payoutPlanInstrumentMapping: payoutPlanInstrumentMappingModels,
  })

  return createSuccessResponse({
    data: [identity],
  })
}

export default co.wrap(getIdentityO)
