import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import formatAddress from 'utilities/formatters/formatAddress'
import formatDateObject from 'utilities/formatters/formatDateObject'
import formatMoney from 'utilities/formatters/formatMoney'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import formatPercentage from 'utilities/formatters/formatPercentage'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import formatPhone from 'utilities/forms/format/formatPhone'
import { normalizeInteger } from 'constants/normalizationConstants'
import { countryNameByCountryCode, CAN } from 'constants/countryConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { CORPORATION_VALUE } from 'constants/identityConstants'
import { BUSINESS_CHECKING } from 'constants/language/languageConstants'
import { businessCheckingLabelMap } from 'constants/labelConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'
import merge from 'lodash/merge'
import round from 'lodash/round'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'
import isEqual from 'lodash/isEqual'

const frontendOnboardingFormDataModel = ({ data: onboardingForm }) => {
  const [
    id,
    onboardingFormData,
    merchantProcessors,
    onboardingLinkDetails,
    status,
    identityId,
    applicationId,
    createdAt,
    updatedAt,
    tags,
  ] = getMany(onboardingForm, [
    'id',
    'onboarding_data',
    'merchant_processors',
    'onboarding_link_details',
    'status',
    'identity_id',
    'application_id',
    'created_at',
    'updated_at',
    'tags',
  ])

  const [
    returnUrl,
    expiredSessionUrl,
    feeDetailsUrl,
    termsOfServiceUrl,
    expirationInMinutes,
  ] = getMany(onboardingLinkDetails, [
    'return_url',
    'expired_session_url',
    'fee_details_url',
    'terms_of_service_url',
    'expiration_in_minutes',
  ])

  const expirationInDays = round(expirationInMinutes / 1440)

  const frontendOnboardingLinkDetails = removeUndefined({
    returnUrl,
    expiredSessionUrl,
    feeDetailsUrl,
    termsOfServiceUrl,
    expirationInMinutes,
    expirationInDays: toString(expirationInDays),
  })

  const frontendProcessorGatewayValues = reduce(merchantProcessors, (result, merchantProcessor) => {
    let processor = get(merchantProcessor, 'processor')
    const l2L3Enabled = get(merchantProcessor, 'level_two_level_three_data_enabled')

    // TODO: Add map to track processors with / without gateways
    if (processor === 'VANTIV_V1') {
      processor = get(merchantProcessor, 'gateways')
    } else {
      processor = [processor]
    }

    const currentProcessors = get(result, 'processors', [])
    return removeUndefined(merge({}, result, {
      processors: currentProcessors.concat(processor),
      L2L3Enabled: l2L3Enabled ? 'Enabled' : 'Disabled',
    }))
  }, {})

  const [
    entity,
    associatedEntities,
    paymentInstruments,
    additionalUnderwritingData,
    maxTransactionAmount,
    achMaxTransactionAmount,
    country,
    associatedFiles,
  ] = getMany(onboardingFormData, [
    'entity',
    'associated_entities',
    'payment_instruments',
    'additional_underwriting_data',
    'max_transaction_amount',
    'ach_max_transaction_amount',
    'country',
    'associated_files',
  ])

  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[country]

  const [
    title,
    firstName,
    lastName,
    email,
    businessName,
    businessType,
    doingBusinessAs,
    phone,
    businessPhone,
    mcc,
    taxAuthority,
    website,
    ownershipType,
    defaultStatementDescriptor,
    annualCardVolume,
    principalPercentageOwnership,
    taxId,
    businessTaxId,
    previouslyProcessedCreditCards,
    personalAddress = {},
    businessAddress = {},
    dateOfBirth,
    incorporationDate,
    amexMid,
    discoverMid,
  ] = getMany(entity, [
    'title',
    'first_name',
    'last_name',
    'email',
    'business_name',
    'business_type',
    'doing_business_as',
    'phone',
    'business_phone',
    'mcc',
    'tax_authority',
    'url',
    'ownership_type',
    'default_statement_descriptor',
    'annual_card_volume',
    'principal_percentage_ownership',
    'tax_id',
    'business_tax_id',
    'has_accepted_credit_cards_previously',
    'personal_address',
    'business_address',
    'dob',
    'incorporation_date',
    'amex_mid',
    'discover_mid',
  ])

  const {
    city: personalAddressCity,
    country: personalAddressCountry,
    line1: personalAddressLine1,
    line2: personalAddressLine2,
    postal_code: personalAddressPostalCode,
    region: personalAddressRegion,
  } = personalAddress

  const formattedPersonalAddress = removeUndefined({
    city: personalAddressCity,
    country: personalAddressCountry || country,
    line1: personalAddressLine1,
    line2: personalAddressLine2,
    postalCode: personalAddressPostalCode,
    region: personalAddressRegion,
  })

  const {
    city: businessAddressCity,
    country: businessAddressCountry,
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    postal_code: businessAddressPostalCode,
    region: businessAddressRegion,
  } = businessAddress

  const formattedBusinessAddress = removeUndefined({
    city: businessAddressCity,
    country: country || businessAddressCountry,
    line1: businessAddressLine1,
    line2: businessAddressLine2,
    postalCode: businessAddressPostalCode,
    region: businessAddressRegion,
  })

  const [
    accountType,
    name,
    bankCode,
    accountNumber,
    transitNumber,
    institutionNumber,
    plaidProcessorToken,
    thirdParty,
  ] = getMany(paymentInstruments, [
    'account_type',
    'name',
    'bank_code',
    'account_number',
    'transit_number',
    'institution_number',
    'third_party_token',
    'third_party',
  ])

  const [
    refundPolicy,
    averageACHTransferAmount,
    averageCardTransferAmount,
    businessDescription,
    annualACHVolume,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
    cardPresentPercentage,
    ecommercePercentage,
    mailOrderTelephoneOrderPercentage,
    merchantAgreementAccepted,
    amexMarketingConsentAccepted,
  ] = getMany(additionalUnderwritingData, [
    'refund_policy',
    'average_ach_transfer_amount',
    'average_card_transfer_amount',
    'business_description',
    'annual_ach_volume',
    'volume_distribution_by_business_type.business_to_business_volume_percentage',
    'volume_distribution_by_business_type.business_to_consumer_volume_percentage',
    'volume_distribution_by_business_type.other_volume_percentage',
    'card_volume_distribution.card_present_percentage',
    'card_volume_distribution.ecommerce_percentage',
    'card_volume_distribution.mail_order_telephone_order_percentage',
    'merchant_agreement_accepted',
    'amex_marketing_consent_accepted',
  ])

  const normalizedBusinessType = isEqual(CORPORATION_VALUE, businessType) ? `${ownershipType}_${businessType}` : businessType

  const frontendBusinessDataValues = removeUndefined({
    businessName,
    businessType: normalizedBusinessType,
    displayBusinessType: businessType ? convertSnakeToSentenceCase(businessType) : undefined,
    businessDescription,
    doingBusinessAs,
    displayBusinessName: businessName ? formatBusinessNameString({ businessName, doingBusinessAs }) : undefined,
    businessPhone,
    displayBusinessPhone: businessPhone ? formatPhone(businessPhone) : undefined,
    website: website || 'https://',
    ownershipType,
    displayOwnershipType: ownershipType ? convertSnakeToSentenceCase(ownershipType) : undefined,
    businessTaxId,
    businessAddress: !isEmpty(formattedBusinessAddress) ? formattedBusinessAddress : undefined,
    displayBusinessAddress: !isEmpty(formattedBusinessAddress) ? formatAddress({ address: formattedBusinessAddress }) : undefined,
    incorporationDate,
    displayIncorporationDate: formatDateObject({ date: incorporationDate }),
  })

  const frontendControlPersonData = removeUndefined({
    title,
    firstName,
    lastName,
    email,
    phone,
    displayPhone: formatPhone(phone),
    principalPercentageOwnership,
    displayPrincipalPercentageOwnership: formatPercentage({ percentage: principalPercentageOwnership }),
    taxId,
    personalAddress: formattedPersonalAddress,
    displayPersonalAddress: formatAddress({ address: formattedPersonalAddress }),
    dateOfBirth,
    displayDateOfBirth: formatDateObject({ date: dateOfBirth }),
  })

  const frontendAssociatedEntityValues = map(associatedEntities, (associatedEntity) => {
    const [
      associatedEntityFirstName,
      associatedEntityLastName,
      associatedEntityEmail,
      associatedEntityPersonalAddress = {},
      associatedEntityPrincipalPercentageOwnership,
      associatedEntityDateOfBirth = {},
      associatedEntityTaxId,
      associatedEntityPhone,
      associatedEntityTitle,
    ] = getMany(associatedEntity, [
      'first_name',
      'last_name',
      'email',
      'personal_address',
      'principal_percentage_ownership',
      'dob',
      'tax_id',
      'phone',
      'title',
    ])

    return removeUndefined({
      firstName: associatedEntityFirstName,
      lastName: associatedEntityLastName,
      title: associatedEntityTitle,
      personalAddress: removeUndefined({
        line1: get(associatedEntityPersonalAddress, 'line1'),
        line2: get(associatedEntityPersonalAddress, 'line2'),
        region: get(associatedEntityPersonalAddress, 'region'),
        postalCode: get(associatedEntityPersonalAddress, 'postal_code'),
        city: get(associatedEntityPersonalAddress, 'city'),
        country: get(associatedEntityPersonalAddress, 'country') || country,
      }),
      displayPersonalAddress: formatAddress({ address: associatedEntityPersonalAddress }),
      dateOfBirth: {
        month: get(associatedEntityDateOfBirth, 'month'),
        day: get(associatedEntityDateOfBirth, 'day'),
        year: get(associatedEntityDateOfBirth, 'year'),
      },
      displayDateOfBirth: formatDateObject({ date: associatedEntityDateOfBirth }),
      taxId: associatedEntityTaxId,
      principalPercentageOwnership: associatedEntityPrincipalPercentageOwnership,
      displayPrincipalPercentageOwnership: formatPercentage({ percentage: associatedEntityPrincipalPercentageOwnership }),
      phone: associatedEntityPhone,
      displayPhone: formatPhone(associatedEntityPhone),
      email: associatedEntityEmail,
    })
  })

  const frontendBankAccountValues = removeUndefined({
    name,
    bankCode,
    accountNumber,
    accountNumberVerification: accountNumber,
    accountType: accountType || 'BUSINESS_CHECKING',
    displayAccountType: businessCheckingLabelMap(country),
    transitNumber,
    institutionNumber,
    bankDocument: find(associatedFiles, (associatedFile) => get(associatedFile, 'type') === 'BANK_STATEMENT'),
    plaidProcessorToken,
    thirdParty,
  })

  const frontendProcessingData = removeUndefined({
    mcc,
    taxAuthority,
    refundPolicy,
    defaultStatementDescriptor,
    previouslyProcessedCreditCards: previouslyProcessedCreditCards === true ? 'true' : 'false',
    displayPreviouslyProcessedCreditCards: previouslyProcessedCreditCards ? 'Yes' : 'No',
    amexMid,
    discoverMid,
    displayRefundPolicy: convertSnakeToSentenceCase(refundPolicy),
    averageACHTransferAmount,
    displayAverageACHTransferAmount: formatMoney({ amount: averageACHTransferAmount, currency, showCurrencyCode: true }),
    averageCardTransferAmount,
    displayAverageCardTransferAmount: formatMoney({ amount: averageCardTransferAmount, currency, showCurrencyCode: true }),
    annualCardVolume: annualCardVolume ? normalizeInteger(annualCardVolume) : undefined,
    displayAnnualCardVolume: formatMoney({ amount: annualCardVolume, currency, showCurrencyCode: true }),
    annualACHVolume,
    displayAnnualACHVolume: formatMoney({ amount: annualACHVolume, currency, showCurrencyCode: true }),
    maxTransactionAmount: maxTransactionAmount ? normalizeInteger(maxTransactionAmount) : undefined,
    displayMaxTransactionAmount: formatMoney({ amount: maxTransactionAmount, currency, showCurrencyCode: true }),
    achMaxTransactionAmount: achMaxTransactionAmount ? normalizeInteger(achMaxTransactionAmount) : undefined,
    displayAchMaxTransactionAmount: formatMoney({ amount: achMaxTransactionAmount, currency, showCurrencyCode: true }),
    otherVolumePercentage,
    displayOtherVolumePercentage: formatPercentage({ percentage: otherVolumePercentage }),
    businessToBusinessVolumePercentage,
    displayBusinessToBusinessVolumePercentage: formatPercentage({ percentage: businessToBusinessVolumePercentage }),
    businessToConsumerVolumePercentage,
    displayBusinessToConsumerVolumePercentage: formatPercentage({ percentage: businessToConsumerVolumePercentage }),
    ecommercePercentage,
    displayEcommercePercentage: formatPercentage({ percentage: ecommercePercentage }),
    cardPresentPercentage,
    displayCardPresentPercentage: formatPercentage({ percentage: cardPresentPercentage }),
    mailOrderTelephoneOrderPercentage,
    displayMailOrderTelephoneOrderPercentage: formatPercentage({ percentage: mailOrderTelephoneOrderPercentage }),
    merchantAgreementAccepted,
    displayMerchantAgreementAccepted: merchantAgreementAccepted ? 'Yes' : 'No',
    amexMarketingConsentAccepted,
    displayAmexMarketingConsentAccepted: amexMarketingConsentAccepted ? 'Yes' : 'No',
  })

  return removeUndefined({
    id,
    status,
    identityId,
    applicationId,
    tags,
    country,
    displayCountry: countryNameByCountryCode[country],
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    onboardingLinkDetails: frontendOnboardingLinkDetails,
    processorGatewayDetails: frontendProcessorGatewayValues,
    businessData: frontendBusinessDataValues,
    controlPerson: frontendControlPersonData,
    beneficialOwners: frontendAssociatedEntityValues,
    bankAccountData: frontendBankAccountValues,
    processingData: frontendProcessingData,
    associatedFiles,
  })
}

export default frontendOnboardingFormDataModel
