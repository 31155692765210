import './IdentityPaymentInstrumentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getIdentityPaymentInstrumentsSettlementAliases } from 'state-layer/selectors'
import columnDescriptors from 'components/Customer/Pages/PaymentInstruments/columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { IDENTITY_PAYMENT_INSTRUMENTS } from 'constants/linkConstants'
import { GET_IDENTITY_PAYMENT_INSTRUMENTS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { IDENTITY_PAYMENT_INSTRUMENTS_RESOURCE_TITLE } from 'constants/language/languageConstants'

const IdentityPaymentInstruments = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  paymentInstrumentPath = '',
  isFetching = false,
  identityId = '',
  actions = [],
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='IdentityPaymentInstruments'>
      <TableC
        flow={GET_IDENTITY_PAYMENT_INSTRUMENTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getIdentityPaymentInstrumentsSettlementAliases}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_PAYMENT_INSTRUMENTS}
        allowedFilters={allowedFilters}
        path={paymentInstrumentPath}
        isFetching={isFetching}
        actions={actions}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV={`Identity_${identityId}_PaymentInstruments`}
        fileResourceTitleCSV={IDENTITY_PAYMENT_INSTRUMENTS_RESOURCE_TITLE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

IdentityPaymentInstruments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  paymentInstrumentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default IdentityPaymentInstruments
