import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import submitIdentityUploaderForm from 'utilities/submit/submitIdentityUploaderForm'
import { REUPLOAD_FILE_FORM } from 'constants/formConstants'
import { CODE_FILE_ICON } from 'constants/iconConstants'
import { validateFile } from 'utilities/validate'

import {
  RESET,
  SUBMIT,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 200000,
  accept: 'text/csv',
}

const required = (value) => validateFile(value, config)

const ReuploadFileForm = ({
  handleSubmit = () => {},
  clearIdentityUploaderReducer = () => {},
  totalCount = 0,
  invalid = false,
  instructions = [],
}) => {
  return (
    <form className='ReuploadFileForm' onSubmit={handleSubmit(submitIdentityUploaderForm)}>
      <h4>Re-upload file</h4>

      <div className='section'>
        <Field
          name='file'
          config={config}
          instructions={instructions}
          component={FileUploader}
          validate={required}
          fileIcon={`fal fa-${CODE_FILE_ICON}`}
        />
      </div>

      <div className='buttons flex flex-end'>
        {totalCount > 0 && <Button onClick={clearIdentityUploaderReducer} label={RESET} variant='secondary' className='reset-count-button' />}
        <Button type='submit' disabled={invalid} label={SUBMIT} />
      </div>
    </form>
  )
}

ReuploadFileForm.propTypes = {
  handleSubmit: PropTypes.func,
  clearIdentityUploaderReducer: PropTypes.func,
  totalCount: PropTypes.number,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: REUPLOAD_FILE_FORM,
})(ReuploadFileForm)
