import { getState } from 'state-layer/configureStore'
import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentPlatformId from 'utilities/get/getCurrentPlatformId'
import removeUndefined from 'utilities/remove/removeUndefined'
import { COMPLIANCE_FORM_TEMPLATE_PATH } from 'constants/pathConstants'
import { APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE } from 'constants/selectedItemsConstants'
import { PLATFORM } from 'constants/payoutPlanConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import find from 'lodash/find'

import {
  CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY,
  COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY,
  COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY,
  COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY,
  COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY,
  COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY,
  COMPLIANCE_APPLY_TEMPLATE_DATA_KEY,
} from 'constants/flowsConstants'

import {
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
  POST_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_COMPLIANCE_FORM_TEMPLATE,
  DUPLICATE_COMPLIANCE_FORM_TEMPLATE,
  EDIT_APPLICATION_COMPLIANCE_TEMPLATE,
  EDIT_PLATFORM_COMPLIANCE_TEMPLATE,
} from 'constants/amplitudeConstants'

const submitCreateComplianceFormTemplate = (values, dispatch, { credentials, complianceFormTemplateId }) => {
  const createComplianceFormTemplateFormValues = get(values, CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY)
  const complianceAssessmentInformationFormValues = get(values, COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY)
  const complianceSelfAssessmentQuestionnaireAFormValues = get(values, COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY)
  const complianceCompensatingControlsWorksheetFormValues = get(values, COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY)
  const complianceExplanationOfNonApplicabilityFormValues = get(values, COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY)
  const complianceValidationAndAttestationDetailsFormValues = get(values, COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY)
  const ComplianceApplyTemplateDisplayDataValues = get(values, COMPLIANCE_APPLY_TEMPLATE_DATA_KEY)
  const amplitudeConstant = complianceFormTemplateId ? DUPLICATE_COMPLIANCE_FORM_TEMPLATE : CREATE_COMPLIANCE_FORM_TEMPLATE

  const typeApplied = get(ComplianceApplyTemplateDisplayDataValues, 'typeApplied')
  const state = getState()
  const selectedApplications = get(state, `selectedItemsR.${APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE}`, {})
  const selectedApplication = find(Object.keys(selectedApplications), (application) => selectedApplications[application])
  const platformId = getCurrentPlatformId(state)
  const linkedTo = typeApplied === PLATFORM ? platformId : selectedApplication

  const complianceFormTemplateFormValues = {
    ...createComplianceFormTemplateFormValues,
    ...complianceAssessmentInformationFormValues,
    ...complianceSelfAssessmentQuestionnaireAFormValues,
    ...complianceCompensatingControlsWorksheetFormValues,
    ...complianceExplanationOfNonApplicabilityFormValues,
    ...complianceValidationAndAttestationDetailsFormValues,
    linkedTo,
  }

  // create compliance form template
  dispatch({
    type: POST_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
    payload: {
      values: complianceFormTemplateFormValues,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const complianceFormTemplateIdNew = head(keys(newValues))
        const credentialId = get(credentials, 'id')
        const amplitudeEventType = linkedTo.slice(0, 2) === 'AP' ? EDIT_APPLICATION_COMPLIANCE_TEMPLATE : EDIT_PLATFORM_COMPLIANCE_TEMPLATE // TODO: replace with ID check util

        // apply created compliance form template to platform or application
        dispatch({
          type: PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
          payload: {
            id: complianceFormTemplateIdNew,
            credentials,
            values: {
              linkTo: linkedTo,
            },
          },
          meta: {
            actionId: sendAmplitudeActionEvent(amplitudeEventType, removeUndefined({
              credentials,
              linkTo: linkedTo,
              complianceTemplateId: complianceFormTemplateIdNew,
            })),
            successCallback: ({ newValues: newerValues }) => {
              const complianceFormTemplateIdNewer = head(keys(newerValues))

              // applying compliance form template to platform/application creates another compliance form template
              const complianceTemplatePath = COMPLIANCE_FORM_TEMPLATE_PATH({ credentialId, complianceFormTemplateId: complianceFormTemplateIdNewer })
              dispatch(redirectRequest({ path: complianceTemplatePath }))
            },
          },
        })
      },
      actionId: sendAmplitudeActionEvent(amplitudeConstant, {
        credentials,
        complianceFormTemplateId,
        complianceFormTemplateFormValues,
      }),
    },
  })
}

export default submitCreateComplianceFormTemplate
