import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  WEBHOOK_LOG,
  WEBHOOK_LOGS,
} from 'constants/apiConstants'

const getWebhookLogAPI = ({ id, values, credentials, meta }) => {
  const path = WEBHOOK_LOG({ webhookLogId: id })

  return paymentsAPI.get({
    meta,
    values,
    credentials,
    path,
    service: WEBHOOK_LOGS,
  })
}

export default getWebhookLogAPI
