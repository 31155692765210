import dashboardAPI from 'utilities/api/dashboardAPI'
import {
  NOTE,
  NOTES,
} from 'constants/apiConstants'

const getNoteAPI = ({ id, credentials, meta }) => {
  return dashboardAPI.get({
    meta,
    credentials,
    path: NOTE({ noteId: id }),
    service: NOTES,
  })
}

export default getNoteAPI
