import React from 'react'
import Loader from 'components/Shared/Loader/Loader'
import PropTypes from 'prop-types'

const DisplayUnmaskedItem = ({
  canReadPersonalIdentifiableInformation = false,
  condition = true,
  unmaskedState = false,
  unmaskedItemIsFetching = false,
  unmaskedItemValue = '',
  formattedValue,
}) => {
  return (
    <div className='DisplayUnmaskedItem'>
      { canReadPersonalIdentifiableInformation && condition && (
        <>{ (unmaskedState || unmaskedItemIsFetching) && !unmaskedItemValue ? <Loader /> : formattedValue }</>
      )}
    </div>
  )
}

DisplayUnmaskedItem.propTypes = {
  canReadPersonalIdentifiableInformation: PropTypes.bool,
  condition: PropTypes.bool,
  unmaskedState: PropTypes.bool,
  unmaskedItemIsFetching: PropTypes.bool,
  unmaskedItemValue: PropTypes.string,
  formattedValue: PropTypes.object,
}

export default DisplayUnmaskedItem
