import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  FEES,
  FEE,
} from 'constants/apiConstants'

const getFeeAPI = ({ queries, id = '', credentials, meta }) => {
  const prefix = id.slice(0, 2)

  // need to call getFeeAPI as part of getSettlementEntriesFee
  // but some entries are transfers so added a condition to skip call if id isn't FExxx
  return prefix === 'FE' ? paymentsAPI.get({
    meta,
    credentials,
    path: FEE({ feeId: id }),
    queries,
    service: FEES,
  }) : {}
}

export default getFeeAPI
