import co from 'co'
import getUserAPI from 'api/dashboard/get/getUserAPI'
import getCredentialsAPI from 'api/dashboard/get/getCredentialsAPI'
import getDashboardCustomizationsAPI from 'api/dashboard/get/getDashboardCustomizationsAPI'
import getSelfServiceSandboxFormAPI from 'api/dashboard/get/getSelfServiceSandboxFormAPI'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getAdminUserAPI from 'api/dashboard/get/getAdminUserAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getComplianceFormsAPI from 'api/dashboard/get/getComplianceFormsAPI'
import removeUndefined from 'utilities/remove/removeUndefined'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendDashboardCustomizationModel from 'utilities/create/models/frontendDashboardCustomizationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendUserModel from 'utilities/create/models/frontendUserModel'
import getCredentialPermissionsO from 'state-layer/orchestration/get/getCredentialPermissionsO'
import find from 'lodash/find'
import get from 'lodash/get'
import some from 'lodash/some'

import {
  isRolePartner,
  isRoleMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION,
  MERCHANT,
  PLATFORM,
} from 'constants/dashboardConfigurationsConstants'

import {
  INCOMPLETE,
  OVERDUE,
} from 'constants/complianceConstants'

function * getCurrentUserO ({
  id,
  credentials,
  values,
}) {
  const currentUserEmail = get(values, 'currentUserEmail')
  const credentialId = get(credentials, 'id')
  const dashboardUserId = get(credentials, 'userId')
  const dashboardId = getCurrentDashboardId()

  if (credentialId && dashboardId) {
    // TODO: investigate whether or not we should store the response here
    yield getCredentialsAPI({ id: credentialId, values: { dashboardId } })
  }

  const { data: userResponse } = yield getUserAPI({ id })
  const { data: adminUserResponse } = yield getAdminUserAPI({ id: dashboardUserId })
  const normalizedAdminUserResponse = frontendUserModel({ data: adminUserResponse })

  const {
    fullName,
    firstName,
    lastName,
    mfaEnabled,
    mfaRequired,
    mfaConfigurations,
  } = normalizedAdminUserResponse

  const enabledMfaConfiguration = find(mfaConfigurations, ({ enabled }) => enabled === true)

  // default IDs to empty string to ensure they're overwritten when changing credentials
  const platformId = get(userResponse, 'platform', '')
  const applicationId = get(userResponse, 'application', '')
  const identityId = get(userResponse, 'identity', '')

  const [
    { data: platformCustomProfiles },
    { data: applicationCustomProfiles },
    { data: identityCustomProfiles },
  ] = yield [
    platformId ? getDashboardCustomizationsAPI({
      queries: {
        entity_type: PLATFORM,
        entity_id: platformId,
      },
    }) : {},
    applicationId ? getDashboardCustomizationsAPI({
      queries: {
        entity_type: APPLICATION,
        entity_id: applicationId,
      },
    }) : {},
    identityId && isRoleMerchant({ credentials }) ? getDashboardCustomizationsAPI({
      queries: {
        entity_type: MERCHANT,
        entity_id: identityId,
      },
    }) : {},
  ]

  const platformProfile = get(platformCustomProfiles, '_embedded.dashboard_customizations.0')
  const platformType = get(platformProfile, 'platform_type')
  const applicationProfile = get(applicationCustomProfiles, '_embedded.dashboard_customizations.0')
  const identityProfile = get(identityCustomProfiles, '_embedded.dashboard_customizations.0')
  const customProfile = { ...(identityProfile || applicationProfile), platform_type: platformType }

  let isSelfServiceSandboxUser = false

  if (applicationId && isRolePartner({ credentials })) {
    const { data: sandboxFormResponse } = yield getSelfServiceSandboxFormAPI({ queries: { application_id: applicationId }, credentials })
    if (get(sandboxFormResponse, '_embedded.sandboxes[0].id')) {
      isSelfServiceSandboxUser = true
    }
  }

  const { data: permissionsResponse } = yield getCredentialPermissionsO({ id: get(credentials, 'username'), credentials })
  const permissions = get(permissionsResponse, 'permissions')

  // fetch compliance forms for role merchant to show due banner in dashboard
  let hasIncompleteComplianceForm
  let hasOverdueComplianceForm
  let isCanadianRoleMerchant = false
  const dashboardType = get(customProfile, 'dashboard_type')

  if (isRoleMerchant({ credentials }) || dashboardType === 'STANDALONE_MERCHANT') {
    const { data: complianceResponse } = yield getComplianceFormsAPI({})
    const forms = get(complianceResponse, '_embedded.compliance_forms', [])
    hasIncompleteComplianceForm = some(forms, ({ state }) => state === INCOMPLETE)
    hasOverdueComplianceForm = some(forms, ({ state }) => state === OVERDUE)

    const { data: identityResponse } = yield getIdentityAPI({ id: identityId, credentials })
    const identityModel = frontendIdentityModel({ data: identityResponse })
    if (get(identityModel, 'country') === 'CAN') {
      isCanadianRoleMerchant = true
    }
  }

  let applicationModel
  if (isRolePartner({ credentials })) {
    const { data: application } = yield getApplicationAPI({ id: applicationId, credentials })
    applicationModel = frontendApplicationModel({ data: application })
  }

  const currentUser = removeUndefined({
    userName: id,
    userEmail: currentUserEmail,
    platformId,
    applicationId,
    identityId,
    customSettings: frontendDashboardCustomizationModel({ data: customProfile }),
    isSelfServiceSandboxUser,
    fullName,
    firstName,
    lastName,
    permissions,
    mfaEnabled,
    mfaRequired,
    mfaConfigurations,
    enabledMfaConfiguration,
    hasIncompleteComplianceForm,
    hasOverdueComplianceForm,
    isCanadianRoleMerchant,
    application: applicationModel,
  })

  return createSuccessResponse({
    data: currentUser,
  })
}

export default co.wrap(getCurrentUserO)
