import frontendSplitTransferModel from 'utilities/create/models/frontendSplitTransferModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSplitTransfersModel = ({ data: splitTransfers }) => {
  const splitTransfersModels = map(splitTransfers, (splitTransfer) => frontendSplitTransferModel({ data: splitTransfer }))

  return keyBy(splitTransfersModels, 'id')
}

export default frontendSplitTransfersModel
