import getMany from 'utilities/get/getMany'
import numeral from 'numeral'

import {
  nonEmptyNumber,
} from 'utilities/validate'

import {
  ACH_MAX_TRANSACTION_AMOUNT,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const validateEditIdentityProcessingInformationForm = (values, props) => {
  const {
    maxTransactionAmount,
    achMaxTransactionAmount,
  } = values

  const [
    applicationMaxTransactionAmount,
    applicationMaxACHTransactionAmount,
    displayApplicationMaxACHTransactionAmount,
    displayApplicationMaxTransactionAmount,
    isRolePartnerCredential,
  ] = getMany(props, [
    'applicationMaxTransactionAmount',
    'applicationMaxACHTransactionAmount',
    'displayApplicationMaxACHTransactionAmount',
    'displayApplicationMaxTransactionAmount',
    'isRolePartnerCredential',
  ])
  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(applicationMaxTransactionAmount).value()
  const invalidACHMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(applicationMaxACHTransactionAmount).value()

  return {
    maxTransactionAmount: nonEmptyNumber({ field: maxTransactionAmount, name: MAX_TRANSACTION_AMOUNT }) || ((invalidMaxTransactionAmount && isRolePartnerCredential) && `Value exceeds the Application's max amount of ${displayApplicationMaxTransactionAmount}`),
    achMaxTransactionAmount: nonEmptyNumber({ field: achMaxTransactionAmount, name: ACH_MAX_TRANSACTION_AMOUNT }) || ((invalidACHMaxTransactionAmount && isRolePartnerCredential) && `Value exceeds the Application's ACH max amount of ${displayApplicationMaxACHTransactionAmount}`),
  }
}

export default validateEditIdentityProcessingInformationForm
