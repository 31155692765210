import './FileViewerModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from '../GenericModal/GenericModal'
import FileViewerC from 'components/Customer/Pages/FileViewer/FileViewerC'

const FileViewerModal = ({
  id = '',
  displayType = '',
  displayName = '',
  displayCreatedAt = '',
  src = '',
  fileType = '',
  extension,
  accessFormId,
  isHostedAccessForm,
}) => {
  return (
    <GenericModal
      className='FileViewerModal'
      Component={FileViewerC}
      id={id}
      displayType={displayType}
      displayName={displayName}
      displayCreatedAt={displayCreatedAt}
      src={src}
      fileType={fileType}
      extension={extension}
      accessFormId={accessFormId}
      isHostedAccessForm={isHostedAccessForm}
    />
  )
}

FileViewerModal.propTypes = {
  id: PropTypes.string,
  displayType: PropTypes.string,
  displayName: PropTypes.string,
  displayCreatedAt: PropTypes.string,
  src: PropTypes.string,
  fileType: PropTypes.string,
  accessFormId: PropTypes.string,
  isHostedAccessForm: PropTypes.bool,
}

export default FileViewerModal
