import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MERCHANT_FEE_PROFILE } from 'constants/apiConstants'

const getMerchantFeeProfileAPI = ({ id, meta, credentials }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_SERVICE_MERCHANT_FEE_PROFILE({ merchantId: id }),
    meta,
  })
}

export default getMerchantFeeProfileAPI
