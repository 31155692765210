import './ButtonS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import classnames from 'classnames'
import { LOAD_ICON_V2 } from 'constants/iconConstants'
import { t } from 'constants/language/languageConstants'

// possible variants: primary, secondary, tertiary, ghost, destructive, destructive-ghost, legacy-black
// possible types: submit, button
// possible sizes: default, large

const Button = ({
  variant = 'primary',
  type = 'button',
  onClick = () => {},
  icon = '',
  leftIcon = '',
  rightIcon = '',
  label = 'Submit',
  size = 'default',
  disabled = false,
  submitting = false,
  className = '',
  ariaLabel = '',
  ariaExpanded = () => {},
  key = '',
  style = {},
}) => {
  let buttonDisplay = icon ? <i className={`icon fa fa-${icon}`} /> : (
    <>
      {leftIcon && <i className={`left-icon fa fa-${leftIcon}`} />}
      {t(label)}
      {rightIcon && <i className={`right-icon fa fa-${rightIcon}`} />}
    </>
  )

  if (submitting) {
    buttonDisplay = <Loader icon={LOAD_ICON_V2} />
  }

  const buttonClassNames = classnames('button', size, variant, className, { iconOnly: icon })

  return (
    <button
      className={buttonClassNames}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      disabled={disabled || submitting}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      key={key}
      style={style}
    >
      {buttonDisplay}
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaExpanded: PropTypes.func,
  key: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default Button
