import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import { PAYMENTS_PATH } from 'constants/pathConstants'
import PaymentInstrumentPayments from './PaymentInstrumentPayments'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import columnDescriptors from 'components/Customer/Pages/DisbursementsDashboardOverview/columnDescriptors'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  TRANSFER_TYPE,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_BRAND_TYPE,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter('Transfer'),
  AMOUNT(),
  DEVICE_ID,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  TRANSFER_TYPE,
  INSTRUMENT_BRAND_TYPE,
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const isFetchingDashboardConfig = get(state, `dashboardConfigurationsR.${FETCHING}`)
  const isFetchingPaymentInstrumentTransfers = get(state, `paymentInstrumentTransfersR.${FETCHING}`)
  const isFetching = isFetchingDashboardConfig || isFetchingPaymentInstrumentTransfers
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = PAYMENTS_PATH({ credentialId })
  const isDisbursements = isDisbursementsPartnerDashboard(state)

  const initialQueries = {
    instrument_id: paymentInstrumentId,
  }

  const flowValues = {
    paymentInstrumentId,
  }

  return {
    paymentInstrumentId,
    flowValues,
    isFetching,
    paymentPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    columnDescriptors: isDisbursements ? columnDescriptors : undefined,
  }
}

class PaymentInstrumentPaymentsC extends Component {
  render() {
    return <PaymentInstrumentPayments {...this.props} />
  }
}

export default connect(mapStateToProps)(PaymentInstrumentPaymentsC)
