import { fieldEmpty } from 'utilities/validate'

const validateEditDashboardAccountNameForm = (values) => {
  const { dashboardName } = values

  return {
    dashboardName: fieldEmpty(dashboardName, 'Dashboard Name'),
  }
}

export default validateEditDashboardAccountNameForm
