import removeUndefined from 'utilities/remove/removeUndefined'

const backendReviewQueueItemModel = ({ values }) => {
  const {
    id,
    applicationId,
    tags,
    outcome,
    outcomeReason,
  } = values

  return removeUndefined({
    id,
    tags,
    outcome,
    outcome_reason: outcomeReason,
    application: applicationId,
  })
}

export default backendReviewQueueItemModel
