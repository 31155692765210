import React from 'react'
import PropTypes from 'prop-types'
import { getBalanceTransfersSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { BALANCE_TRANSFERS } from 'constants/linkConstants'
import { GET_BALANCE_TRANSFERS } from 'constants/flowConstants'

const BalanceTransfers = ({
  balanceTransferPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  actions = [],
}) => {
  return (
    <div className='BalanceTransfers'>
      <TableC
        flow={GET_BALANCE_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getBalanceTransfersSelector}
        initialQueries={initialQueries}
        linksKey={BALANCE_TRANSFERS}
        allowedFilters={allowedFilters}
        path={balanceTransferPath}
        isFetching={isFetching}
        fileNameCSV='Balance_Transfers'
        emptyMessage='No Balance Transfers Available'
        actions={actions}
      />
    </div>
  )
}

BalanceTransfers.propTypes = {
  balanceTransferPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
}

export default BalanceTransfers
