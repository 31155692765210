import './CreateRefundConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  CONFIRM,
  CREATE_REFUND_CONFIRMATION_MSG,
} from 'constants/language/languageConstants'

const CreateRefundConfirmation = ({
  createRefund = () => {},
  closeModal = () => {},
  submitting = false,
}) => {
  return (
    <div className='CreateRefundConfirmation'>
      <div className='confirmation-msg'>{CREATE_REFUND_CONFIRMATION_MSG}</div>

      <div className='divider' />

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button label={CONFIRM} onClick={createRefund} disabled={submitting} submitting={submitting} />
      </div>
    </div>
  )
}

CreateRefundConfirmation.propTypes = {
  createRefund: PropTypes.func,
  closeModal: PropTypes.func,
  submitting: PropTypes.bool,
}

export default CreateRefundConfirmation
