import './TableItemsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import { t } from 'constants/language/languageConstants'
import { ALERT_CIRCLE_ICON } from 'constants/iconConstants'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'

const showRelatedHandler = (id) => {
  const item = document.getElementById(id)
  const related = document.getElementById(`${id}_related`)

  if (item) item.classList.toggle('showingRelated')
  if (related) related.classList.toggle('show')
}

const TableItems = ({
  items,
  path,
  selectedItemsAction = [],
  selectItemHandler = () => {},
  selectedItemsPath,
  selectedItems = {},
  isFetching = false,
  emptyMessage,
  relatedItemsKey = '',
  relatedItemsWarning = {},
  openNewTab = true,
  clickAction = () => {},
  selectSingleItem = false,
  showPreviewPanel = () => {},
  activePanel = false,
  activeResourceId = '',
  selectedItemsData = {},
  previewPanel,
}) => {
  const message = isFetching ? 'Fetching data...' : emptyMessage
  const showSelectButtons = !isEmpty(selectedItemsAction) || !isEmpty(get(selectedItemsData, 'actions')) || selectSingleItem
  const hasPreviewPanel = !!previewPanel
  const target = openNewTab ? '_blank' : ''

  return (
    <ul className='TableItems'>
      {
        !isEmpty(items) && map(items, (item, index) => {
          const {
            attributes,
            data,
            relatedItems,
          } = item

          const itemID = selectedItemsPath ? get(item, selectedItemsPath) : get(item, 'id')
          const prevItem = index > 0 ? get(items[index - 1], 'id') : undefined
          const nextItem = index < items.length ? get(items[index + 1], 'id') : undefined
          const checkboxKey = `${itemID}-checkbox`
          const checked = get(selectedItems, itemID, false)
          const link = isFunction(path) ? path(data) : `${path}/${itemID}`

          const id = get(item, 'id')
          const relatedItem = document.getElementById(`${id}_related`)
          const isShowingRelated = relatedItem && relatedItem.classList.contains('show')

          const itemClassNames = classnames({
            item: true,
            [itemID]: true,
            checked,
            showingRelated: isShowingRelated,
            'active-panel': activePanel && (itemID === activeResourceId),
          })

          const listItems = map(attributes, ({
            id: attributeID = '',
            className,
            displayValue,
            showColumn,
            highlight = false,
          }) => {
            const normalizedAttributeName = attributeID.split('-').slice(1).join(' ')
            const classIdentifier = kebabCase(`${normalizedAttributeName} value`)

            let displayedValue = displayValue || '-'

            if (displayValue === 'showEmptyString') {
              displayedValue = ''
            }

            const itemValueClassNames = classnames({
              'display-value': true,
              'flex column center': true,
              checked,
              [classIdentifier]: true,
              [className]: !!className,
              highlight,
            })

            const wrappedDisplayValue = typeof displayedValue === 'string' ? <div>{t(displayedValue)}</div> : displayedValue
            const title = typeof displayedValue === 'string' ? t(displayedValue) : null

            return showColumn ? (
              <li id={attributeID} key={attributeID} className={itemValueClassNames} title={title}>{wrappedDisplayValue}</li>
            ) : null
          })

          const relatedListItems = map(relatedItems, ({
            attributes: relatedItemAttributes,
            id: relatedItemId,
            data: relatedItemData = {},
          }) => {
            const relatedItemAttributesJSX = map(relatedItemAttributes, ({
              id: attributeID,
              className,
              displayValue = '',
              showColumn,
            }) => {
              const wrappedDisplayValue = typeof displayValue === 'string' ? <div>{t(displayValue)}</div> : displayValue
              const title = typeof displayValue === 'string' ? t(displayValue) : null
              const normalizedAttributeName = attributeID.split('-').slice(1).join(' ')
              const classIdentifier = kebabCase(`${normalizedAttributeName} value`)

              const itemValueClassNames = classnames({
                'related-resource-item': true,
                'flex column center': true,
                checked,
                [classIdentifier]: true,
                [className]: !!className,
              })

              return showColumn ? (
                <li id={attributeID} className={itemValueClassNames} key={attributeID} title={title}>{wrappedDisplayValue}</li>
              ) : null
            })

            // TODO: expand support to all table items, not just related items?
            // TODO: fix CSS to support longer warning message (tooltip gets cut off by boundary of item + related items)
            const relatedItemsWarningCondition = get(relatedItemsWarning, 'condition', () => {})
            const relatedItemsWarningMessage = get(relatedItemsWarning, 'warningMessage', 'There is an issue with this item.')
            const showRelatedItemWarning = relatedItemsWarningCondition(relatedItemData) ? relatedItemsWarningCondition(relatedItemData) : false

            return (
              <ul className='table-row flex' key={relatedItemId}>
                { showRelatedItemWarning && (
                  <li className='warning-tooltip'>
                    <span className={`fa fa-${ALERT_CIRCLE_ICON}`} />
                    <span className='warning-tooltip-text'>{t(relatedItemsWarningMessage)}</span>
                  </li>
                )}

                <li>
                  {path ? (
                    <Link to={path(relatedItemData)} target={target}>
                      <ul className='flex related-resource-items'>{relatedItemAttributesJSX}</ul>
                    </Link>
                  ) :
                    (
                      <ul className='flex related-resource-items'>
                        {relatedItemAttributesJSX}
                      </ul>
                    )}
                </li>
              </ul>
            )
          })

          let tableItem = path && link ? (
            <Link to={link} target={target}>
              <ul className='table-row flex'>
                {listItems}
              </ul>
            </Link>
          ) : (
            <ul className='table-row flex' onClick={() => { clickAction(data) }}>
              {listItems}
            </ul>
          )

          if (hasPreviewPanel) {
            const tableRowClassnames = classnames({
              link: true,
              flex: true,
              'table-row': true,
              'flex-grow': true,
              'space-between': true,
            })

            tableItem = (
              <ul
                className={tableRowClassnames}
                onClick={() => showPreviewPanel({
                  resourceId: itemID,
                  viewPageLink: link,
                  Component: previewPanel,
                  prevId: prevItem,
                  nextId: nextItem,
                })}
              >
                {listItems}
              </ul>
            )
          }

          return (
            <li key={itemID} id={itemID} className={itemClassNames}>
              <ul className='flex items-center'>
                {(relatedItemsKey && (!isEmpty(relatedListItems) && !isFetching)) && (
                  <li onClick={() => showRelatedHandler(itemID)}><span className='fa chevron' /></li>
                )}

                {relatedItemsKey && isEmpty(relatedListItems) && <li><span className='fa chevron chevron-placeholder' /></li>}

                { showSelectButtons && (
                  <>
                    <li id={checkboxKey} className='checkbox'>
                      <CheckboxC
                        name={checkboxKey}
                        checked={checked}
                        objectId={selectedItemsPath ? get(item, selectedItemsPath) : itemID}
                        handleChange={selectItemHandler}
                        disabled={selectSingleItem && !isEmpty(selectedItems) && !get(selectedItems, itemID)}
                      />
                    </li>
                  </>
                )}

                <li className='table-item'>{tableItem}</li>
              </ul>

              { !isEmpty(relatedListItems) && (
                <div id={`${itemID}_related`} className='related-resources item'>
                  {relatedListItems}
                </div>
              )}
            </li>
          )
        })
      }

      { isEmpty(items) && <h5 className='empty-message'>{t(message)}</h5> }
    </ul>
  )
}
TableItems.propTypes = {
  items: PropTypes.array,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  selectedItemsAction: PropTypes.array,
  selectedItemsPath: PropTypes.string,
  selectItemHandler: PropTypes.func,
  selectedItems: PropTypes.object,
  isFetching: PropTypes.bool,
  emptyMessage: PropTypes.object,
  relatedItemsKey: PropTypes.string,
  relatedItemsWarning: PropTypes.object,
  openNewTab: PropTypes.bool,
  clickAction: PropTypes.func,
  selectSingleItem: PropTypes.bool,
  showPreviewPanel: PropTypes.func,
  activePanel: PropTypes.bool,
  activeResourceId: PropTypes.string,
  previewPanel: PropTypes.elementType,
  selectedItemsData: PropTypes.object,
}
export default TableItems
