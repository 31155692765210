import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import EditFeeProfileSettings from './EditFeeProfileSettings'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import patchFeeProfileSettingsRequest from 'utilities/actions/patch/patchFeeProfileSettingsRequest'
import postFeeProfileSettingsRequest from 'utilities/actions/post/postFeeProfileSettingsRequest'
import { EDIT_FEE_PROFILE_SETTINGS_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import set from 'lodash/set'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    updateFormField: ({ value, field }) => dispatch(change(EDIT_FEE_PROFILE_SETTINGS_FORM, field, value)),
    postFeeProfileSettings: ({
      credentials,
      values,
      applicationId,
    }) => dispatch(postFeeProfileSettingsRequest({
      credentials,
      values,
      applicationId,
    })),
    patchFeeProfileSettings: ({
      credentials,
      values,
      applicationId,
    }) => dispatch(patchFeeProfileSettingsRequest({
      credentials,
      values,
      applicationId,
    })),
  }
}


class EditFeeProfileSettingsC extends Component {
    disableAction = () => {
      const {
        currentFeeProfileSettings,
        hasNoFeeProfileSettings,
        postFeeProfileSettings,
        patchFeeProfileSettings,
        fieldName,
        currency,
        credentials,
        applicationId,
      } = this.props

      // set the value we are updating to the correct value
      const currentValue = get(currentFeeProfileSettings, [currency, fieldName])
      set(currentFeeProfileSettings, [currency, fieldName], !currentValue)

      const values = {
        fee_profile_settings: [{
          currency: 'USD',
          settings: {
            can_create_fee_profiles: get(currentFeeProfileSettings, 'USD.canCreateFeeProfiles', false),
            can_assign_fee_profiles: get(currentFeeProfileSettings, 'USD.canAssignFeeProfiles', false),
            enforce_limits_for_fee_profiles: get(currentFeeProfileSettings, 'USD.enforceLimitsForFeeProfiles', false),
          },
        },
        // TODO: add back as part of FE-4225
        // {
        //   currency: 'CAD',
        //   settings: {
        //     can_create_fee_profiles: get(currentFeeProfileSettings, 'CAD.canCreateFeeProfiles', false),
        //     can_assign_fee_profiles: get(currentFeeProfileSettings, 'CAD.canAssignFeeProfiles', false),
        //     enforce_limits_for_fee_profiles: get(currentFeeProfileSettings, 'CAD.enforceLimitsForFeeProfiles', false),
        //   },
        // }
        ],
      }

      return hasNoFeeProfileSettings ? postFeeProfileSettings({ credentials, values, applicationId }) : patchFeeProfileSettings({ credentials, values, applicationId })
    }

  closeModalFunc = () => {
    const {
      currentFeeProfileSettings,
      updateFormField,
      fieldName,
      currency,
      closeModal,
    } = this.props

    // set the toggle back to its original value if the user closes the modal
    const currentValue = get(currentFeeProfileSettings, [currency, fieldName])
    updateFormField({ value: currentValue, field: `${currency}.${fieldName}` })
    closeModal()
  }

  render() {
    return (
      <EditFeeProfileSettings
        {...this.props}
        disableAction={this.disableAction}
        closeModalFunc={this.closeModalFunc}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeeProfileSettingsC)
