import Help from './Help'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showHighlightsActionRequest from 'utilities/actions/showHighlightsActionRequest'
import showDocumentationActionRequest from 'utilities/actions/showDocumentationActionRequest'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { PAGE_NAME_TO_HIGHLIGHT_MAP } from 'constants/pageConstants'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import get from 'lodash/get'

import {
  CONTACT_SUPPORT_MODAL,
  DASHBOARD_FEEDBACK_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const formattedPath = get(props, 'formattedPath')
  const highlights = get(PAGE_NAME_TO_HIGHLIGHT_MAP, formattedPath, [])
  const currentUserEmail = get(getCurrentUser(), 'userEmail')

  return {
    credentials,
    highlights,
    currentUserEmail,
    isRoleMerchantCred: isRoleMerchant({ credentials }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showHighlightsAction: (highlights, credentials) => dispatch(showHighlightsActionRequest({ highlights, credentials })),
    showDocumentationAction: (credentials) => dispatch(showDocumentationActionRequest({ credentials })),
    showContactSupportModalAction: (modalProps) => dispatch(showModalAction({ modalType: CONTACT_SUPPORT_MODAL, modalProps })),
    showFeedbackModalAction: (modalProps) => dispatch(showModalAction({ modalType: DASHBOARD_FEEDBACK_MODAL, modalProps })),
  }
}

class HelpC extends Component {
    clickOnDocumentation = () => {
      const { credentials, showDocumentationAction } = this.props

      showDocumentationAction(credentials)
    }

    showHighlights = (e) => {
      const { highlights, showHighlightsAction, credentials } = this.props

      if (allowedKeyPressControls(e)) {
        showHighlightsAction(highlights, credentials)
      }
    }

    showContactSupportModal = () => {
      const { showContactSupportModalAction, credentials, currentUserEmail } = this.props
      showContactSupportModalAction({ credentials, currentUserEmail })
    }

    showFeedbackModal = () => {
      const { showFeedbackModalAction, credentials, currentUserEmail } = this.props
      showFeedbackModalAction({ credentials, currentUserEmail })
    }

    render() {
      return (
        <Help
          {...this.props}
          showHighlights={this.showHighlights}
          clickOnDocumentationAction={this.clickOnDocumentation}
          showContactSupportModal={this.showContactSupportModal}
          showFeedbackModal={this.showFeedbackModal}
        />
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpC)
