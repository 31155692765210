import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import { DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2 } from 'constants/apiConstants'

const postEmailTemplateAPI = ({ values, meta }) => {
  const dashboardId = getCurrentDashboardId()

  return dashboardAPI.post({
    meta,
    values: {
      dashboard_id: dashboardId,
      ...values,
    },
    path: DASHBOARD_SERVICE_EMAIL_TEMPLATES_V2({ dashboardId }),
  })
}

export default postEmailTemplateAPI
