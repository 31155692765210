import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import sleep from 'utilities/sleep'
import { convertTagValues } from 'utilities/convert/convertTagValues'
import { CREATE_FEE } from 'constants/amplitudeConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'

import {
  GET_SEARCH_F_REQUEST,
  POST_FEE_F_REQUEST,
} from 'constants/flowConstants'

const submitCreateFeeForm = (values, dispatch, props) => {
  const { credentials } = props

  const {
    amount,
    merchant,
    label,
  } = values

  const valuesToSubmit = {
    amount,
    merchant,
    label,
    tags: convertTagValues(values),
  }

  dispatch({
    type: POST_FEE_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const feeId = head(keys(newValues))

        sleep(1500).then(() => {
          dispatch({
            type: GET_SEARCH_F_REQUEST,
            payload: {
              input: feeId,
              credentials,
            },
          })
        })
      },
      actionId: sendAmplitudeActionEvent(CREATE_FEE, {
        credentials,
        ...valuesToSubmit,
      }),
    },
  })
}

export default submitCreateFeeForm
