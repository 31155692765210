import './FeeS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { PATCH_FEE_F_REQUEST } from 'constants/flowConstants'
import { DETAILS } from 'constants/language/languageConstants'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const Fee = ({
  detailsSectionData = [],
  isFetching = false,
  actions = [],
  tagsSectionData = [],
  fee = {},
  headerData = {},
  contextBarData = {},
}) => {
  return (
    <div className='Fee'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={convertPageSectionDataToV2(detailsSectionData)}
        isFetching={isFetching}
      />

      <TagsPageSectionV2
        actionType={PATCH_FEE_F_REQUEST}
        data={tagsSectionData}
        record={fee}
        isFetching={isFetching}
      />
    </div>
  )
}

Fee.propTypes = {
  headerTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headerSubTitle: PropTypes.string,
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  tagsSectionData: PropTypes.array,
  fee: PropTypes.object,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default Fee
