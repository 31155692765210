import './CardPaymentsFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import validateCardPaymentsForm from 'utilities/validate/validateCardPaymentsForm'
import Loader from 'components/Shared/Loader/Loader'
import { EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM } from 'constants/formConstants'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import AccordionGroup from 'components/Customer/Shared/Page/PageSection/AccordionGroup'
import { CURRENCY_TO_COUNTRY_NAME_MAP } from 'constants/currencyConstants'
import { countryNameByCountryCode, USA } from 'constants/countryConstants'
import { TIMES_OCTAGON } from 'constants/iconConstants'
import map from 'lodash/map'

import {
  ALL_CARD_BRANDS_REQUIRED,
  ALLOW_CARD_BRAND_OVERRIDES,
  ALLOW_CHARGEBACK_FEES,
  AMERICAN_EXPRESS_ASSESSMENTS,
  BLENDED_PRICING,
  BLENDED_PRICING_DESCRIPTION,
  CANCEL,
  CARD_CROSS_BORDER,
  CARD_PAYMENTS,
  CARD_PAYMENTS_EDIT_RULES_DESCRIPTION,
  CHARGEBACK_INQUIRY,
  CHARGEBACK_NOTIFICATION,
  DISCOVER_ASSESSMENTS,
  DISCOVER_DATA_USAGE,
  DISCOVER_NETWORK_AUTHORIZATION,
  FEE_TYPE,
  FIXED_FEE,
  INTERCHANGE_PLUS_PRICING,
  INTERCHANGE_PLUS_PRICING_DESCRIPTION,
  MASTERCARD_ACQUIRER_FEES,
  MASTERCARD_ASSESSMENTS_OVER_1000,
  MASTERCARD_ASSESSMENTS_UNDER_1000,
  MAX_UPPERCASE,
  MIN_UPPERCASE,
  NA,
  PASS_THROUGH_CARD_ASSOCIATION_FEES,
  PERCENTAGE_FEE, PLEASE_ENABLE_AT_LEAST_ONE_PRICING_STRATEGY,
  PRICING_STRATEGY,
  PRICING_STRATEGY_DESCRIPTION,
  SAVE_AND_CONTINUE,
  VISA_ACQUIRER_PROCESSING,
  VISA_ASSESSMENTS,
  VISA_BASE_SYSTEM_FILE_TRANSMISSION,
  VISA_CREDIT_VOUCHER,
  VISA_KILOBYTE_ACCESS,
} from 'constants/language/languageConstants'

import {
  AMEX_LABEL,
  DISCOVER_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

const BlendedPricingFields = ({
  type,
  currency,
  cardBrandRowData,
  showCardBrandOverridesSection,
  showChargebackFeesSection,
  toggleAllowCardBrandOverrides,
  toggleAllowChargebackFees,
}) => {
  return (
    <>
      <div className='container-3'>
        <div className='item header p-2-bold'>{FEE_TYPE}</div>
        <div className='item header'>
          <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
          <div className='currency'>{currency}</div>
        </div>
        <div className='item header'>
          <div className='p-2-bold'>{FIXED_FEE}</div>
          <div className='currency'>{currency}</div>
        </div>
      </div>
      <div className='container-3'>
        <div className='item'>{ALL_CARD_BRANDS_REQUIRED}</div>
        <div className='item'>
          <PercentageField
            name={`${type}.${currency}.basisPointsMin`}
            contextLabel={MIN_UPPERCASE}
            placeholder={NA}
          />
          <div className='dash'>-</div>
          <PercentageField
            name={`${type}.${currency}.basisPointsMax`}
            contextLabel={MAX_UPPERCASE}
            placeholder={NA}
          />
        </div>
        <div className='item'>
          <AmountField
            name={`${type}.${currency}.fixedFeeMin`}
            contextLabel={MIN_UPPERCASE}
            placeholder={NA}
          />
          <div className='dash'>-</div>
          <AmountField
            name={`${type}.${currency}.fixedFeeMax`}
            contextLabel={MAX_UPPERCASE}
            placeholder={NA}
          />
        </div>
      </div>
      <div className='container-3'>
        <div className='item'>{CARD_CROSS_BORDER}</div>
        <div className='item'>
          <PercentageField
            name={`${type}.${currency}.cardCrossBorderBasisPointsMin`}
            contextLabel={MIN_UPPERCASE}
            placeholder={NA}
          />
          <div className='dash'>-</div>
          <PercentageField
            name={`${type}.${currency}.cardCrossBorderBasisPointsMax`}
            contextLabel={MAX_UPPERCASE}
            placeholder={NA}
          />
        </div>
        <div className='item'>
          <AmountField
            name={`${type}.${currency}.cardCrossBorderFixedFeeMin`}
            contextLabel={MIN_UPPERCASE}
            placeholder={NA}
          />
          <div className='dash'>-</div>
          <AmountField
            name={`${type}.${currency}.cardCrossBorderFixedFeeMax`}
            contextLabel={MAX_UPPERCASE}
            placeholder={NA}
          />
        </div>
      </div>
      <div className='container-1'>
        <div className='item sub-header'>
          <Field
            name={`${type}.${currency}.allowCardBrandOverrides`}
            component={ToggleSwitchC}
            customHandleChange={() => toggleAllowCardBrandOverrides(type, currency)}
          />
          {ALLOW_CARD_BRAND_OVERRIDES}
        </div>
      </div>
      {showCardBrandOverridesSection && (
        <>
          {map(cardBrandRowData, ({ basisPointsMinField, basisPointsMaxField, fixedFeeMinField, fixedFeeMaxField }, countryKey) => {
            return (
              <div className='container-3'>
                <div className='item'>{countryKey}</div>
                <div className='item'>
                  <PercentageField
                    name={`${type}.${currency}.${basisPointsMinField}`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <PercentageField
                    name={`${type}.${currency}.${basisPointsMaxField}`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
                <div className='item'>
                  <AmountField
                    name={`${type}.${currency}.${fixedFeeMinField}`}
                    contextLabel={MIN_UPPERCASE}
                    placeholder={NA}
                  />
                  <div className='dash'>-</div>
                  <AmountField
                    name={`${type}.${currency}.${fixedFeeMaxField}`}
                    contextLabel={MAX_UPPERCASE}
                    placeholder={NA}
                  />
                </div>
              </div>
            )
          })}
        </>
      )}
      <div className='container-1'>
        <div className='item sub-header'>
          <Field
            name={`${type}.${currency}.allowChargebackFees`}
            component={ToggleSwitchC}
            customHandleChange={() => toggleAllowChargebackFees(type, currency)}
          />
          {ALLOW_CHARGEBACK_FEES}
        </div>
      </div>
      {showChargebackFeesSection && (
        <>
          <div className='container-2'>
            <div className='item'>{CHARGEBACK_NOTIFICATION}</div>
            <div className='item'>
              <AmountField
                name={`${type}.${currency}.disputeFixedFeeMin`}
                contextLabel={MIN_UPPERCASE}
                placeholder={NA}
              />
              <div className='dash'>-</div>
              <AmountField
                name={`${type}.${currency}.disputeFixedFeeMax`}
                contextLabel={MAX_UPPERCASE}
                placeholder={NA}
              />
            </div>
          </div>
          <div className='container-2'>
            <div className='item'>{CHARGEBACK_INQUIRY}</div>
            <div className='item'>
              <AmountField
                name={`${type}.${currency}.disputeInquiryFixedFeeMin`}
                contextLabel={MIN_UPPERCASE}
                placeholder={NA}
              />
              <div className='dash'>-</div>
              <AmountField
                name={`${type}.${currency}.disputeInquiryFixedFeeMax`}
                contextLabel={MAX_UPPERCASE}
                placeholder={NA}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const CardPaymentsForm = ({
  handleSubmit = () => {},
  clickAccordion = () => {},
  countrySections = [],
  isFetching,
  invalid = false,
  toggleAllowChargebackFees = () => {},
  toggleAllowCardBrandOverrides = () => {},
  noRequiredRulesetSelectedError = false,
  submitFailed = false,
  showDuesAssessments = false,
}) => {
  return (
    <form className='CardPaymentsForm' onSubmit={handleSubmit}>
      <div className='form-content'>
        {isFetching && <Loader />}
        {!isFetching && (
        <>
          <h4>{CARD_PAYMENTS}</h4>
          <div className='flex space-between'>
            <div className='p-1 secondary'>{CARD_PAYMENTS_EDIT_RULES_DESCRIPTION}</div>
            <RequiredFieldNotice />
          </div>
          { map(countrySections, (
            {
              showCardBrandOverridesBlendedSection,
              showCardBrandOverridesInterchangeSection,
              showChargebackFeesBlendedSection,
              showChargebackFeesInterchangeSection,
              cardBrandRowData,
              isOptional,
              showBlendedAccordion,
              showInterchangeAccordion,
            }, currency,
          ) => {
            const country = CURRENCY_TO_COUNTRY_NAME_MAP[currency]
            return (
              <>
                <div className='flex country-header'>
                  <h5>{PRICING_STRATEGY} - {countryNameByCountryCode[country]}{isOptional ? ' (Optional)' : ''}</h5>
                  <CountryFlagIcon country={country} showCountryName={false} />
                </div>
                <div className='p-1 secondary'>{PRICING_STRATEGY_DESCRIPTION}{countryNameByCountryCode[country]}.</div>
                {(noRequiredRulesetSelectedError && country === USA && submitFailed) && (
                <div className='error-message'>
                  <div className='flex items-center'>
                    <i className={`fa fa-${TIMES_OCTAGON}`} />
                    <div className='p-1'>{PLEASE_ENABLE_AT_LEAST_ONE_PRICING_STRATEGY}</div>
                  </div>
                </div>
                )}
                <AccordionGroup>
                  <Accordion
                    title={BLENDED_PRICING}
                    description={BLENDED_PRICING_DESCRIPTION}
                    showCheckbox
                    open={showBlendedAccordion}
                    checkboxFunc={() => clickAccordion(currency, 'blended')}
                  >
                    <BlendedPricingFields
                      type='blended'
                      currency={currency}
                      cardBrandRowData={cardBrandRowData}
                      showCardBrandOverridesSection={showCardBrandOverridesBlendedSection}
                      showChargebackFeesSection={showChargebackFeesBlendedSection}
                      toggleAllowChargebackFees={toggleAllowChargebackFees}
                      toggleAllowCardBrandOverrides={toggleAllowCardBrandOverrides}
                    />
                  </Accordion>
                  <Accordion
                    title={INTERCHANGE_PLUS_PRICING}
                    description={INTERCHANGE_PLUS_PRICING_DESCRIPTION}
                    showCheckbox
                    open={showInterchangeAccordion}
                    checkboxFunc={() => clickAccordion(currency, 'interchange-plus')}
                  >
                    <BlendedPricingFields
                      type='interchange-plus'
                      currency={currency}
                      cardBrandRowData={cardBrandRowData}
                      showCardBrandOverridesSection={showCardBrandOverridesInterchangeSection}
                      showChargebackFeesSection={showChargebackFeesInterchangeSection}
                      toggleAllowChargebackFees={toggleAllowChargebackFees}
                      toggleAllowCardBrandOverrides={toggleAllowCardBrandOverrides}
                    />
                    {!showDuesAssessments && (
                    <>
                      <div className='container-3'>
                        <div className='item header p-2-bold'>{PASS_THROUGH_CARD_ASSOCIATION_FEES}</div>
                        <div className='item header'>
                          <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
                          <div className='currency'>{currency}</div>
                        </div>
                        <div className='item header'>
                          <div className='p-2-bold'>{FIXED_FEE}</div>
                          <div className='currency'>{currency}</div>
                        </div>
                      </div>
                      <div className='container-1'>
                        <div className='item sub-header'>{VISA_LABEL}</div>
                      </div>
                      <div className='container-3'>
                        <div className='item'>{VISA_ASSESSMENTS}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.visaAssessmentsBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.visaAssessmentsBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-2'>
                        <div className='item'>{VISA_ACQUIRER_PROCESSING}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.visaAcquirerProcessingFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.visaAcquirerProcessingFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                      <div className='container-2'>
                        <div className='item'>{VISA_CREDIT_VOUCHER}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.visaCreditVoucherFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.visaCreditVoucherFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                      <div className='container-2'>
                        <div className='item'>{VISA_BASE_SYSTEM_FILE_TRANSMISSION}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.visaBaseIISystemFileTransmissionFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.visaBaseIISystemFileTransmissionFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                      <div className='container-2'>
                        <div className='item'>{VISA_KILOBYTE_ACCESS}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.visaKilobyteAccessFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.visaKilobyteAccessFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                      <div className='container-1'>
                        <div className='item sub-header'>{MASTERCARD_LABEL}</div>
                      </div>
                      <div className='container-3'>
                        <div className='item'>{MASTERCARD_ASSESSMENTS_UNDER_1000}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAssessmentsUnder1kBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAssessmentsUnder1kBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-3'>
                        <div className='item'>{MASTERCARD_ASSESSMENTS_OVER_1000}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAssessmentsOver1kBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAssessmentsOver1kBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-3'>
                        <div className='item'>{MASTERCARD_ACQUIRER_FEES}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAcquirerFeesBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.mastercardAcquirerFeesBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-1'>
                        <div className='item sub-header'>{AMEX_LABEL}</div>
                      </div>
                      <div className='container-3'>
                        <div className='item'>{AMERICAN_EXPRESS_ASSESSMENTS}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.americanExpressAssessmentBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.americanExpressAssessmentBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-1'>
                        <div className='item sub-header'>{DISCOVER_LABEL}</div>
                      </div>
                      <div className='container-3'>
                        <div className='item'>{DISCOVER_ASSESSMENTS}</div>
                        <div className='item'>
                          <PercentageField
                            name={`interchange-plus.${currency}.discoverAssessmentsBasisPointsMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <PercentageField
                            name={`interchange-plus.${currency}.discoverAssessmentsBasisPointsMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                        <div className='item' />
                      </div>
                      <div className='container-2'>
                        <div className='item'>{DISCOVER_DATA_USAGE}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.discoverDataUsageFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.discoverDataUsageFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                      <div className='container-2'>
                        <div className='item'>{DISCOVER_NETWORK_AUTHORIZATION}</div>
                        <div className='item'>
                          <AmountField
                            name={`interchange-plus.${currency}.discoverNetworkAuthorizationFixedFeeMin`}
                            contextLabel={MIN_UPPERCASE}
                            placeholder={NA}
                          />
                          <div className='dash'>-</div>
                          <AmountField
                            name={`interchange-plus.${currency}.discoverNetworkAuthorizationFixedFeeMax`}
                            contextLabel={MAX_UPPERCASE}
                            placeholder={NA}
                          />
                        </div>
                      </div>
                    </>
                    )}
                  </Accordion>
                </AccordionGroup>

              </>
            )
          })}
        </>
        )}
        <Field type='hidden' name='noUsdRulesetSelected' component='input' />
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SAVE_AND_CONTINUE} disabled={invalid} />
          </div>
        </div>
      </div>
    </form>
  )
}

CardPaymentsForm.propTypes = {
  handleSubmit: PropTypes.func,
  clickAccordion: PropTypes.func,
  countrySections: PropTypes.array,
  isFetching: PropTypes.bool,
  invalid: PropTypes.bool,
  toggleAllowChargebackFees: PropTypes.func,
  toggleAllowCardBrandOverrides: PropTypes.func,
  noRequiredRulesetSelectedError: PropTypes.bool,
  showDuesAssessments: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_RULES_CARD_PAYMENTS_FORM,
  validate: validateCardPaymentsForm,
})(CardPaymentsForm)
