import React from 'react'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { ReviewQueueStatusData } from 'constants/reviewQueueConstants'
import get from 'lodash/get'

import {
  ID,
  FEES,
  AMOUNT,
  PROCESSOR,
  CREATED_ON,
  NET_AMOUNT,
  FEES_AMOUNT,
  APPLICATION,
  TRANSACTIONS,
  MERCHANT_ACCOUNT,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  REVIEW_STATE,
  SETTLEMENT_STATE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'entityId', {
    className: 'copy-click-id',
    formatter: ({ entityId }) => <ClickToCopyC position='right' content={entityId} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'identity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (settlement) => <TransferMerchantApplicationColumn transfer={settlement} />,
  }),
  column(TRANSACTIONS, 'entity.transferCount', {
    className: 'small',
    formatter: ({ entity }) => {
      const transferCount = get(entity, 'transferCount')

      return transferCount !== undefined ? formatNumber(transferCount) : '-'
    },
  }),
  column(FEES, 'entity.feeCount', {
    className: 'small',
    formatter: ({ entity }) => {
      const feeCount = get(entity, 'feeCount')

      return feeCount !== undefined ? formatNumber(feeCount) : '-'
    },
  }),
  column(AMOUNT, ['entity.totalAmount', 'small'], {
    className: 'amount',
    formatter: ({ entity = {} }) => {
      const { totalAmount, currency } = entity

      return <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />
    },
  }),
  column(FEES_AMOUNT, ['entity.totalFees', 'small'], {
    className: 'amount',
    formatter: ({ entity = {} }) => {
      const { totalFees, currency } = entity

      return <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalFees, currency })} currency={currency} />
    },
  }),
  column(NET_AMOUNT, ['entity.netAmount', 'small'], {
    className: 'amount divider',
    formatter: ({ entity = {} }) => {
      const { netAmount, currency } = entity

      return <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: netAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />
    },
  }),
  column(PROCESSOR, 'entity.processor', { className: 'small' }),
  column(REVIEW_STATE, 'displayOutcome', {
    className: 'small',
    formatter: ({ displayOutcome }) => <ColorcodedStatus data={ReviewQueueStatusData} value={displayOutcome} />,
  }),
  column(SETTLEMENT_STATE, 'entity.status', {
    className: 'status',
    formatter: ({ entity }) => <SettlementStatus value={get(entity, 'status')} />,
  }),
]

export default columnDescriptors
