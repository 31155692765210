import './RecipientLimitsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { velocityLimitsColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { infoTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  RECIPIENT_CARD_VELOCITY_LIMITS,
  RECIPIENT_ACH_VELOCITY_LIMITS,
  GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

const RecipientLimits = ({
  isFetching,
  setCardVelocityLimitActions = [],
  setACHVelocityLimitActions = [],
  recipientACHRules = {},
  recipientCardRules = {},
  recipientACHRulesMaxTransactionAmount,
  recipientCardRulesMaxTransactionAmount,
}) => {
  return (
    <div className='RecipientLimits'>
      <PageSectionV2C
        title={RECIPIENT_CARD_VELOCITY_LIMITS}
        subTitle={recipientCardRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${recipientCardRulesMaxTransactionAmount}` : undefined}
        actions={setCardVelocityLimitActions}
        emptyMessage={isEmpty(recipientCardRules) && GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE}
      >
        {!isEmpty(recipientCardRules) && (
          <TableC
            isFetching={isFetching}
            showTableFooter={false}
            theme={infoTableTheme}
            columnDescriptors={velocityLimitsColumnDescriptors}
            data={recipientCardRules}
          />
        )}
      </PageSectionV2C>

      <PageSectionV2C
        title={RECIPIENT_ACH_VELOCITY_LIMITS}
        subTitle={recipientACHRulesMaxTransactionAmount ? `${MAX_TRANSACTION_AMOUNT}: ${recipientACHRulesMaxTransactionAmount}` : undefined}
        actions={setACHVelocityLimitActions}
        emptyMessage={isEmpty(recipientACHRules) && GENERIC_VELOCITY_LIMITS_EMPTY_MESSAGE}
      >
        {!isEmpty(recipientACHRules) && (
          <TableC
            isFetching={isFetching}
            showTableFooter={false}
            theme={infoTableTheme}
            columnDescriptors={velocityLimitsColumnDescriptors}
            data={recipientACHRules}
          />
        )}
      </PageSectionV2C>
    </div>
  )
}

RecipientLimits.propTypes = {
  isFetching: PropTypes.bool,
  setCardVelocityLimitActions: PropTypes.array,
  setACHVelocityLimitActions: PropTypes.array,
  recipientACHRules: PropTypes.object,
  recipientCardRules: PropTypes.object,
  recipientACHRulesMaxTransactionAmount: PropTypes.string,
  recipientCardRulesMaxTransactionAmount: PropTypes.string,
}

export default RecipientLimits
