import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  SETTLEMENT_CALCULATED_FUNDING_DETAILS,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getSettlementCalculatedFundingDetailsAPI = ({ id, queries, credentials, meta, values }) => {
  const settlementId = id || get(values, 'settlementId')

  return paymentsAPI.get({
    meta,
    path: SETTLEMENT_CALCULATED_FUNDING_DETAILS({ settlementId }),
    queries,
    credentials,
    service: SETTLEMENTS,
  })
}

export default getSettlementCalculatedFundingDetailsAPI
