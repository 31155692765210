import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import { Link } from 'react-router'
import Payment from './Payment'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import { transferFeesColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import getTransferAPI from 'api/finix/get/getTransferAPI'
import getAuthorizationAPI from 'api/finix/get/getAuthorizationAPI'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getFraudScoresRequest from 'utilities/actions/get/getFraudScoresRequest'
import showModalAction from 'utilities/actions/showModalAction'
import formatDate from 'utilities/formatters/formatDate'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import displayFundingDetailsValue from 'utilities/display/displayFundingDetailsValue'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import pluralizeWord from 'utilities/pluralizeWord'
import getSplitTransfersRequest from 'utilities/actions/get/getSplitTransfersRequest'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import { APPROVED } from 'constants/settlementConstants'
import { ruleDecisionStatusMap } from 'constants/statusConstants'
import { API } from 'constants/amplitudeConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import concat from 'lodash/concat'
import size from 'lodash/size'
import values from 'lodash/values'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'

import {
  SHIELD_ICON,
  LIGHTNING_BOLT_ICON,
} from 'constants/iconConstants'

import {
  MAKING_A_PAYMENT_API_DOC_LINK,
  PAYMENTS_LEARN_MORE_LINK,
  SIFT_CONSOLE_URL,
} from 'constants/urlConstants'

import {
  REMOVE_SETTLEMENT_ENTRIES,
  VIEW_API_RESPONSE_MODAL,
} from 'constants/modalConstants'

import {
  isStandaloneMerchantDashboard,
  isDisbursementsPartnerDashboard,
} from 'utilities/is/isDashboardType'

import {
  NON_MERCHANT_ROLES,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  PAYMENT_CARD,
  PAYMENT_CARD_PRESENT,
} from 'constants/paymentInstrumentConstants'

import {
  SHOW_ENTERPRISE_SIFT_FRAUD_SCORES,
  SHOW_PREMIUM_SIFT_FRAUD_SCORES,
  SHOW_SIFT_FRAUD_SCORES,
} from 'constants/featureFlagConstants'

import {
  REFUND_TRANSACTION_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION,
  VIEW_SIFT_FRAUD_SCORES_PERMISSION,
} from 'constants/permissionConstants'

import {
  refundsColumnDescriptors,
  splitTransfersColumnDescriptors,
  splitParentPaymentRefundsColumnDescriptors,
} from 'components/Customer/Pages/Payment/columnDescriptors'

import {
  getReversalsSelector,
  getTransferDisputesSelector,
  getTransferWithRelatedSelector,
  getSplitTransfersByParentIdSelector,
  getRouteLocationSelector,
  getMerchantSelector,
  getFraudScoreByEntityId,
  getTransferDisputes,
} from 'state-layer/selectors'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRoleMerchant,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  FAILED,
  CANCELED,
  REVERSAL,
  DEBIT,
  TRANSFERS_SUBTYPE_LABEL_MAP,
  CREDIT,
  ACH_PAYOUT_SPEED_MAP,
  PUSH_TO_SAME_DAY_ACH,
} from 'constants/transferConstants'

import {
  FUNDING_STATUS,
  PAYMENT,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  STATE,
  TRACE_ID,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  CREATE_REFUND,
  CREATED_ON,
  CONVENIENCE_AMOUNT,
  REMOVE_FROM_SETTLEMENT,
  SETTLEMENT_RESOURCE_TITLE,
  PARENT_PAYMENT,
  TYPE,
  SETTLEMENT_STATE,
  CREATED_VIA,
  RENT_SURCHARGE_AMOUNT,
  READY_TO_SETTLE,
  SPLIT_PAYMENT,
  SPLIT,
  SPLIT_TRANSACTION,
  SPLIT_PARENT_TRANSFER_SETTLEMENT_STATE_TOOLTIP_MSG,
  RECIPIENT_IDENTITY,
  RECIPIENT,
  RECEIVED,
  SENT,
  METHOD,
  CARD,
  ACH,
  ACCOUNT,
  PAYOUT,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  DESTINATION_POSTAL_CODE,
  SHIP_FROM_POSTAL_CODE,
  TOTAL_SHIPPING_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT,
  CUSTOMS_DUTY_AMOUNT,
  MERCHANT_PRODUCT_CODE,
  ITEM,
  PRODUCT_DESCRIPTION,
  COST_PER_UNIT,
  DISCOUNT_PER_UNIT,
  QUANTITY,
  UNIT_OF_MEASURE,
  TOTAL_ITEM_COST,
  EXCLUDING_SALES_TAX,
  INCLUDING_SALES_TAX,
  COMMODITY_CODE,
  PROCESSOR,
  MERCHANT_ACCOUNT,
  RISK_ANALYSIS,
  DECISION_RULE,
  RULE_DECISION,
  VIEW_IN_SIFT,
  RULE_DESCRIPTION,
  RULE_OUTCOME,
  TIP_AMOUNT,
  SURCHARGE_AMOUNT,
  HSA_FSA_CLINIC,
  HSA_FSA_DENTAL,
  HSA_FSA_RX,
  HSA_FSA_VISION,
  CREATE_DISPUTE,
  UNREFERENCED_REFUND,
  UNREFERENCED_REFUND_DETAILS,
  PAYMENT_DETAILS,
  CREATE_RECEIPT,
  LINK_TYPE,
  PAYMENT_LINK_NAME,
  STATUS,
  AMOUNT_TYPE,
  TOTAL_AMOUNT,
  EXPIRES_ON,
  VIEW_PAYMENT_LINK,
  MAKING_A_PAYMENT,
  TRANSFERS_API_REFERENCE,
  ENTRY_TYPE,
} from 'constants/language/languageConstants'

import {
  PAYMENTS_PATH,
  PAYMENT_PATH,
  APPLICATION_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
  FEES_PATH,
  REFUNDS_PATH,
  DISPUTES_PATH,
  CREATE_REFUND_PATH,
  MERCHANT_PATH,
  DISPUTE_PAYMENT_PATH,
  CREATE_TRANSFER_RECEIPT_PATH,
  PAYMENT_LINK_PATH,
} from 'constants/pathConstants'

import {
  AUTHORIZATION,
  TRANSFER,
} from 'constants/apiConstants'

const mapStateToProps = (state, props) => {
  const isFetchingDashboardConfig = get(state, `dashboardConfigR.${FETCHING}`)
  const isFetchingTransfer = get(state, `transfersR.${FETCHING}`)
  const isFetchingSplitTransfers = get(state, `splitTransfersR.${FETCHING}`)
  const isFetchingFraudScores = get(state, `fraudScoresR.${FETCHING}`)
  const transferId = get(props, 'params.transferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const merchant = get(transfer, 'merchant')
  const merchantProcessor = get(merchant, 'displayProcessor')
  const paymentPath = PAYMENTS_PATH({ credentialId })
  // TODO: remove this hack once BE can return the Fee ID properly for the /fees endpoint
  const feesPath = ({ id = '' }) => `${FEES_PATH({ credentialId })}/${id}`
  const refundsPath = REFUNDS_PATH({ credentialId })
  const disputesPath = DISPUTES_PATH({ credentialId })
  const isFlex = isFlexPlatform()
  const isStandaloneMerchant = isStandaloneMerchantDashboard()
  const routingInfo = getRouteLocationSelector(state)
  const pathname = get(routingInfo, 'pathname', '')
  const isDisbursements = isDisbursementsPartnerDashboard(state) || includes(pathname, 'disbursements')
  const transferReversals = getReversalsSelector(state)
  const splitTransfers = getSplitTransfersByParentIdSelector(state, transferId)
  const relatedDisputes = getTransferDisputesSelector(state)
  const fraudScoreData = getFraudScoreByEntityId(state, transferId)
  const noRefunds = isEmpty(transferReversals)
  const noDisputes = isEmpty(relatedDisputes)
  const isSiftFraudEnabled = getFeatureFlag(SHOW_SIFT_FRAUD_SCORES)
  const isPremiumOrEnterpriseSiftFraudScoreTier = getFeatureFlag(SHOW_PREMIUM_SIFT_FRAUD_SCORES) || getFeatureFlag(SHOW_ENTERPRISE_SIFT_FRAUD_SCORES) // only paid applications have access to fraud score, everyone else just sees the category
  const isEnterpriseSiftFraudScoreTier = getFeatureFlag(SHOW_ENTERPRISE_SIFT_FRAUD_SCORES) // only enterprise tier sees risk analysis page section
  const hasFraudPermissions = hasPermissions([VIEW_SIFT_FRAUD_SCORES_PERMISSION, VIEW_SIFT_FRAUD_SCORES_LIST_PERMISSION])
  const isRoleMerchantCred = isRoleMerchant({ credentials })
  const hasDisputes = !isEmpty(getTransferDisputes(state, transferId))

  const transferReversalsSum = reduce(values(transferReversals), (sum, reversal) => {
    const reversalState = get(reversal, 'state')
    const amount = includes([FAILED, CANCELED], reversalState) ? 0 : get(reversal, 'amount', 0)
    return sum + convertAmountStringToNumber({ amount })
  }, 0)

  const [
    displayScore,
    displayScoreCategory,
    scoreCategory,
    thirdPartyRule,
    thirdPartyOutcome,
    thirdPartyRuleDescription,
    decision,
  ] = getMany(fraudScoreData, [
    'displayScore',
    'displayScoreCategory',
    'scoreCategory',
    'thirdPartyRule',
    'thirdPartyOutcome',
    'thirdPartyRuleDescription',
    'decision',
  ])

  const [
    type = '',
    traceId = '',
    currency = '',
    transferState = '',
    readyToSettleAt = '',
    statementDescriptor = '',
    displayAmount = '',
    displayAmountWithCurrencyCode = '',
    displayCreatedAt = '',
    paymentInstrument = {},
    tags,
    raw,
    settlement,
    settlementEntry,
    shouldFund,
    externallyFunded,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    merchantAccountId,
    subtype,
    parentTransfer,
    fees,
    device,
    displayConvenienceAmount,
    displayRentSurchargeAmount,
    buyerIdentity,
    transferAmount,
    splitTransferIds = [],
    primaryMerchantId,
    additionalPurchaseData,
    isLevelThreeEnabled,
    displayTipAmount,
    displaySurchargeAmount,
    displayClinicAmount,
    displayDentalAmount,
    displayPrescriptionAmount,
    displayVisionAmount,
    receipts,
    paymentLinkId,
    paymentLink,
    displayEntryMode,
    operationKey,
  ] = getMany(transfer, [
    'type',
    'traceId',
    'currency',
    'state',
    'readyToSettleAt',
    'statementDescriptor',
    'displayAmount',
    'displayAmountWithCurrencyCode',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
    'raw',
    'settlement',
    'settlementEntry',
    'settlementEntry.shouldFund',
    'externallyFunded',
    'application.id',
    'application.businessName',
    'merchantIdentity.id',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'merchantId',
    'displaySubtype',
    'parentTransfer',
    'fees',
    'device',
    'displayConvenienceAmount',
    'displayRentSurchargeAmount',
    'buyerIdentity',
    'amount',
    'splitTransferIds',
    'merchantId',
    'additionalPurchaseData',
    'isLevelThreeEnabled',
    'displayTipAmount',
    'displaySurchargeAmount',
    'displayClinicAmount',
    'displayDentalAmount',
    'displayPrescriptionAmount',
    'displayVisionAmount',
    'receipts',
    'paymentLink.id',
    'paymentLink',
    'displayEntryMode',
    'operationKey',
  ])

  const receiptsArray = orderBy(receipts, ['createdAt'], ['desc'])

  const merchantAccount = getMerchantSelector(state, merchantAccountId)
  const merchantAccountName = get(merchantAccount, 'name')

  const buyerIdentityName = get(buyerIdentity, 'fullName')
  const buyerIdentityId = get(buyerIdentity, 'id')
  const disbursementTransferType = type === DEBIT ? RECEIVED : SENT

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const showError = transferState === FAILED
  const primaryMerchantAssociatedSplitTransfer = find(splitTransfers, { merchantId: primaryMerchantId })
  const otherSplitTransfers = filter(splitTransfers, (element) => !isEqual(element, primaryMerchantAssociatedSplitTransfer))

  const isSplitParentPayment = !isEmpty(splitTransferIds)
  const splitTransfersCount = size(splitTransfers)
  const splitDetailsTableData = !isEmpty(splitTransfers) ? concat([transfer], primaryMerchantAssociatedSplitTransfer ? [primaryMerchantAssociatedSplitTransfer] : [], otherSplitTransfers) : []
  const isFetching = isFetchingTransfer || isFetchingSplitTransfers || isFetchingDashboardConfig || isFetchingFraudScores
  const showFundingStatus = (!isFlex || (isFlex && isRolePlatform({ credentials }))) && !isSplitParentPayment
  const isSameDayACH = isEqual(operationKey, PUSH_TO_SAME_DAY_ACH)
  const mappedSameDayACHStatus = ACH_PAYOUT_SPEED_MAP[operationKey]
  const sameDayAchBadge = isSameDayACH ? <div className='same-day-ach-badge'><span className='span-badge'><i className={`fas fa-${LIGHTNING_BOLT_ICON}`} /> {mappedSameDayACHStatus}</span></div> : null

  const [
    parentId,
    displayParentTransactionAmount,
  ] = getMany(parentTransfer, [
    'id',
    'displayAmountWithCurrencyCode',
  ])

  const parentTransactionPath = PAYMENT_PATH({ credentialId, transferId: parentId })

  const [
    paymentInstrumentType,
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentFingerprint,
  ] = getMany(paymentInstrument, [
    'type',
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'fingerprint',
  ])

  const isCardPresent = isEqual(paymentInstrumentType, PAYMENT_CARD_PRESENT)
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  // TODO: use actual operation key from the transfer object to determine if the transfer is an unreferenced refund
  const isPaymentCard = includes([PAYMENT_CARD, PAYMENT_CARD_PRESENT], paymentInstrumentType)
  const isCredit = type === CREDIT
  const isSystemOrAPI = includes([API, 'System'], subtype)
  const isUnreferencedRefund = false

  const [
    settlementId,
    settlementStatus,
    settlementException,
  ] = getMany(settlement, [
    'id',
    'status',
    'exception',
  ])

  const splitParentTransferLabel = <div className='flex items-center'>{PAYMENT} <div className='split-transaction-badge'>{SPLIT_TRANSACTION}</div></div>
  const settlementEntryId = get(settlementEntry, 'id')
  const enableRemoveFromSettlement = !!settlementEntryId && !!settlementStatus && settlementStatus !== APPROVED && !settlementException && !isDisbursements
  const canRemoveFromSettlement = !isEmpty(paymentInstrument) && includes(NON_MERCHANT_ROLES, role) && enableRemoveFromSettlement
  const paymentLabel = isSplitParentPayment ? splitParentTransferLabel : get(TRANSFERS_SUBTYPE_LABEL_MAP, subtype, PAYMENT)

  const settlementStatusValue = (
    <div className='flex items-center'>
      <SettlementStatus value={settlementStatus} isSplitParentPayment={isSplitParentPayment} />
      {isSplitParentPayment && <TooltipLabelC message={SPLIT_PARENT_TRANSFER_SETTLEMENT_STATE_TOOLTIP_MSG} position='right' />}
    </div>
  )

  const [
    displaySalesTax,
    customerReferenceNumber,
    destinationPostalCode,
    shipFromPostalCode,
    displayShippingAmount,
    displayDiscountAmount,
    displayCustomsDutyAmount,
    itemData,
  ] = getMany(additionalPurchaseData, [
    'displaySalesTax',
    'customerReferenceNumber',
    'destinationPostalCode',
    'shipFromPostalCode',
    'displayShippingAmount',
    'displayDiscountAmount',
    'displayCustomsDutyAmount',
    'itemData',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant && !isDisbursements,
      },
      {
        label: RECIPIENT_IDENTITY,
        value: buyerIdentityName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: buyerIdentityId }) : null, // TODO: Cata - double-check this is the correct path for recipient identity
        condition: isDisbursements,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
        condition: !isStandaloneMerchant && !isDisbursements,
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantAccountName,
        path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId: merchantAccountId }) : null,
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: SETTLEMENT_PATH({ credentialId, settlementId }),
        condition: !isSplitParentPayment && !isDisbursements,
      },
    ],
  }

  const siftFraudScoreBadge = <div className={`risk-badge ${scoreCategory}`}><i className={`fas fa-${SHIELD_ICON}`} />{isPremiumOrEnterpriseSiftFraudScoreTier ? displayScore : displayScoreCategory}</div>
  const showRiskAnalysis = (!!displayScore || !!displayScoreCategory) && isSiftFraudEnabled

  const headerData = {
    resource: {
      label: (!isFetchingDashboardConfig && isDisbursements) ? PAYOUT : (isUnreferencedRefund ? UNREFERENCED_REFUND : paymentLabel),
      id: transferId,
      badge: sameDayAchBadge,
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={transferState} rawMessage={raw} />,
      },
      {
        label: RISK_ANALYSIS,
        value: siftFraudScoreBadge,
        condition: showRiskAnalysis && isFlex,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: RECIPIENT,
        value: buyerIdentityName,
        condition: isDisbursements,
      },
      {
        label: TYPE,
        value: disbursementTransferType,
        condition: isDisbursements,
      },
      {
        label: METHOD,
        value: paymentInstrumentType === PAYMENT_CARD ? CARD : ACH,
        condition: isDisbursements,
      },
      {
        label: TYPE,
        value: paymentInstrumentType,
        condition: !isDisbursements,
      },
      {
        label: isDisbursements ? ACCOUNT : BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: paymentInstrumentType,
          credentialId,
          paymentInstrument,
        }),
        condition: !isEmpty(paymentInstrument) && (!!maskedAccountNumber || !!maskedFullCardNumber),
      },
      {
        label: PARENT_PAYMENT,
        value: displayParentTransactionAmount,
        path: parentTransactionPath,
        condition: type === REVERSAL && !!parentId && !isSplitParentPayment,
      },
      {
        label: SPLIT_PAYMENT,
        value: `${splitTransfersCount} ${pluralizeWord({ count: splitTransfersCount, word: SPLIT })}`,
        condition: isSplitParentPayment,
      },
    ],
  }

  // <-------***** BEGIN usability 2.0 data props *****------->
  const fundingStatusValue = displayFundingDetailsValue({ shouldFund, externallyFunded })

  const detailsData = [
    {
      label: SETTLEMENT_STATE,
      value: settlementStatusValue,
    },
    {
      label: READY_TO_SETTLE,
      value: readyToSettleAt ? formatDate({ date: readyToSettleAt }) : '-',
      condition: !isFlex,
    },
    {
      label: CONVENIENCE_AMOUNT,
      value: displayConvenienceAmount,
      condition: !!displayConvenienceAmount,
    },
    {
      label: CREATED_VIA,
      value: subtype,
    },
    {
      label: ENTRY_TYPE,
      value: displayEntryMode,
      condition: !!displayEntryMode,
    },
    {
      label: RENT_SURCHARGE_AMOUNT,
      value: displayRentSurchargeAmount,
      condition: !!displayRentSurchargeAmount,
    },
    {
      label: FUNDING_STATUS,
      value: fundingStatusValue,
      condition: showFundingStatus,
    },
    {
      label: PROCESSOR,
      value: merchantProcessor,
    },
    {
      label: TIP_AMOUNT,
      value: displayTipAmount,
      condition: !!displayTipAmount,
    },
    {
      label: SURCHARGE_AMOUNT,
      value: displaySurchargeAmount,
      condition: !!displaySurchargeAmount,
    },
    {
      label: HSA_FSA_CLINIC,
      value: displayClinicAmount,
      condition: !!displayClinicAmount,
    },
    {
      label: HSA_FSA_DENTAL,
      value: displayDentalAmount,
      condition: !!displayDentalAmount,
    },
    {
      label: HSA_FSA_RX,
      value: displayPrescriptionAmount,
      condition: !!displayPrescriptionAmount,
    },
    {
      label: HSA_FSA_VISION,
      value: displayVisionAmount,
      condition: !!displayVisionAmount,
    },
  ]

  const additionalLevelTwoDataSectionData = convertPageSectionDataToV2([
    {
      label: SALES_TAX_AMOUNT,
      value: displaySalesTax,
    },
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
  ])

  const additionalLevelThreeDataSectionData = convertPageSectionDataToV2([
    {
      label: CUSTOMER_REFERENCE_NUMBER,
      value: customerReferenceNumber,
    },
    {
      label: DESTINATION_POSTAL_CODE,
      value: destinationPostalCode,
    },
    {
      label: SHIP_FROM_POSTAL_CODE,
      value: shipFromPostalCode,
    },
    {
      label: TOTAL_SHIPPING_AMOUNT,
      value: displayShippingAmount,
    },
    {
      label: TOTAL_DISCOUNT_AMOUNT,
      value: displayDiscountAmount,
    },
    {
      label: CUSTOMS_DUTY_AMOUNT,
      value: displayCustomsDutyAmount,
    },
  ])

  const itemInformationCardsData = map(itemData, (item, index) => {
    const [
      merchantProductCode,
      itemDescription,
      displayCostPerUnit,
      displayItemDiscountAmount,
      quantity,
      displayAmountExcludingSalesTax,
      displayAmountIncludingSalesTax,
      unitOfMeasure,
      commodityCode,
    ] = getMany(item, [
      'merchantProductCode',
      'itemDescription',
      'displayCostPerUnit',
      'displayItemDiscountAmount',
      'quantity',
      'displayAmountExcludingSalesTax',
      'displayAmountIncludingSalesTax',
      'unitOfMeasure',
      'commodityCode',
    ])

    return {
      largeTitle: `${ITEM} #${index + 1}`,
      data: [
        {
          label: MERCHANT_PRODUCT_CODE,
          value: merchantProductCode,
        },
        {
          label: PRODUCT_DESCRIPTION,
          value: itemDescription,
        },
        {
          label: COST_PER_UNIT,
          value: displayCostPerUnit,
        },
        {
          label: DISCOUNT_PER_UNIT,
          value: displayItemDiscountAmount,
        },
        {
          label: QUANTITY,
          value: quantity,
        },
        {
          label: UNIT_OF_MEASURE,
          value: unitOfMeasure,
        },
        {
          label: <><div>{TOTAL_ITEM_COST}</div><div>({EXCLUDING_SALES_TAX})</div></>,
          value: displayAmountExcludingSalesTax,
        },
        {
          label: <><div>{TOTAL_ITEM_COST}</div><div>({INCLUDING_SALES_TAX})</div></>,
          value: displayAmountIncludingSalesTax,
        },
        {
          label: COMMODITY_CODE,
          value: commodityCode,
        },
      ],
    }
  })

  const detailsDataSection = convertPageSectionDataToV2(detailsData)
  const splitTransfersColumnDescriptorsData = splitTransfersColumnDescriptors({ isParent: true, primaryMerchantId })

  const siftFraudScoreData = convertPageSectionDataToV2([
    {
      label: DECISION_RULE,
      value: thirdPartyRule,
    },
    {
      label: RISK_ANALYSIS,
      value: <a className='text-link' href={`${SIFT_CONSOLE_URL}/${paymentInstrumentFingerprint}?orderId=${traceId}`} target='blank'>{VIEW_IN_SIFT}</a>,
    },
    {
      label: RULE_DESCRIPTION,
      value: thirdPartyRuleDescription,
    },
    {
      label: RULE_OUTCOME,
      value: thirdPartyOutcome,
    },
    {
      label: RULE_DECISION,
      value: <ColorcodedStatus data={ruleDecisionStatusMap} value={decision} />,
    },
  ], 3)

  const [
    nickname,
    displayCreatedAtPaymentLink,
    displayLinkExpiresAt,
    linkType,
    displayAmountType,
    paymentLinkStatus,
    paymentLinkTotalAmount,
  ] = getMany(paymentLink, [
    'nickname',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'linkType',
    'displayAmountType',
    'displayState',
    'displayTotalAmount',
  ])

  const paymentLinkDetailsData = convertPageSectionDataToV2([
    {
      label: LINK_TYPE,
      value: linkType,
    },
    {
      label: PAYMENT_LINK_NAME,
      value: nickname,
    },
    {
      label: STATUS,
      value: paymentLinkStatus,
    },
    {
      label: AMOUNT_TYPE,
      value: displayAmountType,
    },
    {
      label: TOTAL_AMOUNT,
      value: paymentLinkTotalAmount,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAtPaymentLink,
    },
    {
      label: EXPIRES_ON,
      value: displayLinkExpiresAt,
    },
  ])

  const paymentLinkPath = <Link className='text-link' to={PAYMENT_LINK_PATH({ credentialId, paymentLinkId })}>{VIEW_PAYMENT_LINK}</Link>

  return {
    isFetching,
    transferId,
    credentials,
    credentialId,
    transfer,
    paymentInstrument,
    tagsSectionData,
    transferFeesColumnDescriptors,
    fees,
    paymentPath,
    showError,
    displayAmount,
    displayAmountWithCurrencyCode,
    canRemoveFromSettlement,
    settlementId,
    settlementEntryId,
    headerData,
    contextBarData,
    isCardPresent,
    detailsDataSection,
    feesPath,
    refundsPath,
    disputesPath,
    type,
    device,
    buyerIdentity,
    isSplitParentPayment,
    isDisbursements,
    splitDetailsTableData,
    transferReversals,
    transferReversalsSum,
    transferAmount,
    splitTransferIds,
    noRefunds,
    noDisputes,
    additionalPurchaseData,
    isLevelThreeEnabled,
    additionalLevelTwoDataSectionData,
    additionalLevelThreeDataSectionData,
    itemInformationCardsData,
    splitTransfersColumnDescriptors: splitTransfersColumnDescriptorsData,
    refundsColumnDescriptors: isSplitParentPayment ? splitParentPaymentRefundsColumnDescriptors : refundsColumnDescriptors,
    isSiftFraudEnabled,
    siftFraudScoreData,
    siftFraudScoreBadge,
    showRiskAnalysis,
    isEnterpriseSiftFraudScoreTier,
    hasFraudPermissions,
    isRoleMerchantCred,
    hasDisputes,
    paymentDetailsSectionTitle: isUnreferencedRefund ? UNREFERENCED_REFUND_DETAILS : PAYMENT_DETAILS,
    receiptsArray,
    merchantId: primaryMerchantId,
    paymentLinkId,
    paymentLinkDetailsData,
    paymentLinkPath,
    operationKey,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => dispatch(getTransferRequest({ credentials, transferId })),
    getSplitTransfers: ({ credentials, queries }) => dispatch(getSplitTransfersRequest({ credentials, queries })),
    showRemoveFromSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: REMOVE_SETTLEMENT_ENTRIES, modalProps })),
    getFraudScores: ({ credentials, transferId }) => dispatch(getFraudScoresRequest({ credentials, queries: { entity_id: transferId } })),
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({
      modalType: VIEW_API_RESPONSE_MODAL,
      modalProps,
    })),
  }
}

class PaymentC extends Component {
  componentDidMount() {
    const {
      splitTransferIds,
      isSiftFraudEnabled,
      hasFraudPermissions,
      isRoleMerchantCred,
    } = this.props

    this.fetchTransfer()

    if (isSiftFraudEnabled && hasFraudPermissions && !isRoleMerchantCred) {
      this.fetchFraudScores()
    }

    if (!isEmpty(splitTransferIds)) {
      this.fetchSplitTransfers()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      transferId,
      splitTransferIds,
      isSiftFraudEnabled,
      hasFraudPermissions,
      isRoleMerchantCred,
    } = this.props

    const {
      transferId: prevTransferId,
      splitTransferIds: prevSplitTransferIds,
      isSiftFraudEnabled: prevIsSiftFraudEnabled,
    } = prevProps

    if (transferId !== prevTransferId) {
      this.fetchTransfer()
    }

    if (isSiftFraudEnabled && !prevIsSiftFraudEnabled && hasFraudPermissions && !isRoleMerchantCred) {
      this.fetchFraudScores()
    }

    if (!isEqual(prevSplitTransferIds, splitTransferIds) && !isEmpty(splitTransferIds)) {
      this.fetchSplitTransfers()
    }
  }

  fetchTransfer = () => {
    const {
      transferId,
      credentials,
      getTransfer,
    } = this.props

    getTransfer({ credentials, transferId })
  }

  fetchSplitTransfers = () => {
    const {
      transferId,
      credentials,
      getSplitTransfers,
    } = this.props

    const queries = { parent_transfer_id: transferId }
    getSplitTransfers({ credentials, queries })
  }

  fetchFraudScores = () => {
    const {
      transferId,
      credentials,
      getFraudScores,
    } = this.props

    getFraudScores({ credentials, transferId, meta: { overwriteReducer: true } })
  }

  render() {
    const {
      type,
      credentialId,
      transferId,
      displayAmountWithCurrencyCode,
      canRemoveFromSettlement,
      showRemoveFromSettlementModal,
      settlementId,
      settlementEntryId,
      transferAmount,
      transferReversalsSum,
      credentials,
      hasDisputes,
      showAPIResponseModal,
    } = this.props

    const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')
    const host = get(credentials, 'host')
    const paymentAPIDocs = [
      {
        label: MAKING_A_PAYMENT,
        link: MAKING_A_PAYMENT_API_DOC_LINK,
      },
      {
        label: TRANSFERS_API_REFERENCE,
        link: PAYMENTS_LEARN_MORE_LINK,
      },
    ]

    const responseModalProps = () => showAPIResponseModal({
      api: transferId?.substring(0, 2) === 'TR' ? getTransferAPI : getAuthorizationAPI,
      apiProps: {
        id: transferId,
        credentials,
      },
      apiRoute: host && `${host}/${transferId?.substring(0, 2) === 'TR' ? TRANSFER({ transferId }) : AUTHORIZATION({ transferId })}`,
      apiMethod: 'GET',
      apiDocuments: paymentAPIDocs,
    })

    const actions = [
      {
        label: REMOVE_FROM_SETTLEMENT,
        action: () => {
          showRemoveFromSettlementModal({
            settlementId,
            settlementEntryIds: [settlementEntryId],
            entriesData: [{ id: transferId, amount: displayAmountWithCurrencyCode }],
          })
        },
        className: 'remove-from-settlement-button',
        condition: canRemoveFromSettlement,
      },
      {
        label: CREATE_DISPUTE,
        link: DISPUTE_PAYMENT_PATH({ credentialId, transferId }),
        condition: !hasDisputes && isFlexPlatform() && isRolePlatform({ credentials }) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
      },
      {
        label: CREATE_RECEIPT,
        action: () => goToPath({ pathname: CREATE_TRANSFER_RECEIPT_PATH({ credentialId, transferId }) }),
        condition: type === DEBIT,
      },
    ]

    const refundActions = [
      {
        label: CREATE_REFUND,
        buttonVariant: 'secondary',
        action: () => goToPath({ pathname: CREATE_REFUND_PATH({ credentialId, transferId }) }),
        condition: type === DEBIT && hasPermissions([REFUND_TRANSACTION_PERMISSION]),
        className: 'create-refund-button',
        disabledButton: isEqual(transferReversalsSum, convertAmountStringToNumber({ amount: transferAmount })),
      },
    ]

    return (
      <Payment
        actions={actions}
        refundActions={refundActions}
        showResponseModal={responseModalProps}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentC)
