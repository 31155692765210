import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_PLAN_COMBINATION_PLANS,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getPayoutPlanCombinationPlansAPI = ({ payoutPlanId, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: PAYOUT_PLAN_COMBINATION_PLANS({ payoutPlanId }),
    credentials,
    service: SETTLEMENTS,
  })
}

export default getPayoutPlanCombinationPlansAPI
