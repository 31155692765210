import './AdditionalUnderwritingDataFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import validateAdditionalUnderwritingDataForm from 'utilities/validate/validateAdditionalUnderwritingDataForm'
import { ADDITIONAL_UNDERWRITING_DATA_FORM } from 'constants/formConstants'
import { REFUND_POLICY_OPTIONS } from 'constants/underwritingConstants'

import {
  CREATE_IDENTITY_BUSINESS_DESCRIPTION_TOOLTIP,
  CREATE_IDENTITY_AVERAGE_CARD_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_AVERAGE_ECHECK_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_ANNUAL_ACH_VOLUME_TOOLTIP,
  CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_MAIL_ORDER_PERCENTAGE_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP,
  CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP,
} from 'constants/language/languageConstants'

const AdditionalUnderwritingDataForm = ({
  handleSubmit = () => {},
  submitLabel = 'Submit',
  submitting = false,
  disabled = false,
  leftButton = false,
  leftButtonLabel = '',
  leftButtonAction = () => {},
  creditCheckAllowed = false,
  toggleAllowCreditCheck = () => {},
  merchantAgreementAccepted = false,
  toggleAcceptMerchantAgreement = () => {},
  isFlex = false,
  isMerchantOnboarding = false,
}) => {
  return (
    <form className='AdditionalUnderwritingDataForm' onSubmit={handleSubmit} onKeyPress={(event) => { return event.key === 'Enter' && event.preventDefault() }}>
      <div className='section'>
        <h5>Additional Underwriting Data</h5>

        <div className='flex additional-underwriting-data'>
          <div className='first-column'>
            <Field
              id='business-description-input'
              name='businessDescription'
              label='Business Description'
              tooltip={CREATE_IDENTITY_BUSINESS_DESCRIPTION_TOOLTIP}
              component={InputField}
              autofill={false}
            />

            <AmountField
              name='averageCardTransferAmount'
              placeholder='Ex: 500'
              label='Average Card Transaction Amount'
              tooltip={CREATE_IDENTITY_AVERAGE_CARD_TRANSACTION_AMOUNT_TOOLTIP}
            />

            <AmountField
              name='averageACHTransferAmount'
              placeholder='Ex: 2,000.00'
              label='Average eCheck Transaction Amount'
              tooltip={CREATE_IDENTITY_AVERAGE_ECHECK_TRANSACTION_AMOUNT_TOOLTIP}
            />

            <AmountField
              name='annualACHVolume'
              placeholder='Ex: 200,000.00'
              label='Annual ACH Volume'
              tooltip={CREATE_IDENTITY_ANNUAL_ACH_VOLUME_TOOLTIP}
            />

            {/*
              <Field
                name='creditCheckAllowed'
                label='Credit Check Allowed'
                component={CheckboxC}
                checked={creditCheckAllowed}
                handleChange={toggleAllowCreditCheck}
              />

              <Field
                id='credit-inquiry-ip-address'
                name='creditCheckIPAddress'
                label='Credit Check IP Address'
                placeholder='Ex: 255.255.255.255'
                component={InputField}
                autofill={false}
              />
            */}

            {!isMerchantOnboarding && (
            <Field
              name='merchantAgreementAccepted'
              label={isFlex ? 'Finix Terms of Service Accepted' : 'Merchant Agreement Accepted'}
              component={CheckboxC}
              checked={merchantAgreementAccepted}
              handleChange={toggleAcceptMerchantAgreement}
              tooltip={isFlex ? 'Sets whether this merchant has read and accepted Finix\'s Terms of Service Agreement.' : 'Sets whether this merchant has accepted the terms and conditions of the merchant agreement.'}
            />
            )}

            {/*
              <Field
                id='merchant-agreement-ip-address'
                name='merchantAgreementIPAddress'
                label='Merchant Agreement IP Address'
                placeholder='Ex: 255.255.255.255'
                component={InputField}
                autofill={false}
              />
              */}

            <Field
              name='refundPolicy'
              label='Refund Policy'
              tooltip='This field cannot be left blank. Please pick from the three options from the drop down.'
              component={SelectField}
              options={REFUND_POLICY_OPTIONS}
            />
          </div>

          <div className='second-column'>
            <div className='nested-section volume-distribution'>
              <div className='section-title'>Payment Volumes by Business Type</div>
              <PercentageField
                name='businessToBusinessVolumePercentage'
                label='Business to Business Volume Percentage'
                tooltip={CREATE_IDENTITY_BUSINESS_TO_BUSINESS_VOLUME_TOOLTIP}
              />

              <PercentageField
                name='businessToConsumerVolumePercentage'
                label='Business to Consumer Volume Percentage'
                tooltip={CREATE_IDENTITY_BUSINESS_TO_CONSUMER_VOLUME_TOOLTIP}
              />

              <PercentageField
                name='otherVolumePercentage'
                label='Other Volume Percentage'
                tooltip={CREATE_IDENTITY_OTHER_VOLUME_TOOLTIP}
              />
            </div>

            <div className='nested-section'>
              <div className='section-title card-volume'>Card Volume Details</div>

              <PercentageField
                name='cardPresentPercentage'
                label='Card Present Percentage'
                tooltip={CREATE_IDENTITY_CARD_PRESENT_PERCENTAGE_TOOLTIP}
              />

              <PercentageField
                name='ecommercePercentage'
                label='E-commerce Percentage'
                tooltip={CREATE_IDENTITY_ECOMMERCE_PERCENTAGE_TOOLTIP}
              />

              <PercentageField
                name='mailOrderTelephoneOrderPercentage'
                label='Mail Order/Telephone Order Percentage'
                tooltip={CREATE_IDENTITY_MAIL_ORDER_PERCENTAGE_TOOLTIP}
              />
            </div>
          </div>
        </div>

        <div className='buttons flex space-between'>
          <div className='left flex flex-start'>
            { leftButton && <Button variant='tertiary' className='left-button' onClick={leftButtonAction} label={leftButtonLabel} /> }
          </div>

          <div className='right flex flex-end'>
            <Button type='submit' label={submitLabel} submitting={submitting} disabled={disabled} variant='legacy-black' />
          </div>
        </div>
      </div>
    </form>
  )
}

AdditionalUnderwritingDataForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool,
  leftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  leftButtonAction: PropTypes.func,
  isFlex: PropTypes.bool,
}

export default reduxForm({
  form: ADDITIONAL_UNDERWRITING_DATA_FORM,
  validate: validateAdditionalUnderwritingDataForm,
})(AdditionalUnderwritingDataForm)
