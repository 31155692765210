import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateComplianceFormTemplateFlow from './CreateComplianceFormTemplateFlow'
import CreateComplianceFormTemplateForm from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/CreateComplianceFormTemplateForm/CreateComplianceFormTemplateForm'
import ComplianceAssessmentInformationFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceAssessmentInformationForm/ComplianceAssessmentInformationFormC'
import ComplianceSelfAssessmentQuestionnaireAFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceSelfAssessmentQuestionnaireAForm/ComplianceSelfAssessmentQuestionnaireAFormC'
import ComplianceCompensatingControlsWorksheetFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceCompensatingControlsWorksheetForm/ComplianceCompensatingControlsWorksheetFormC'
import ComplianceExplanationOfNonApplicabilityFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceExplanationOfNonApplicabilityForm/ComplianceExplanationOfNonApplicabilityFormC'
import ComplianceValidationAndAttestationDetailsFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceValidationAndAttestationDetailsForm/ComplianceValidationAndAttestationDetailsFormC'
import ComplianceApplyTemplateFormC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceApplyTemplateForm/ComplianceApplyTemplateFormC'
import CreateComplianceFormTemplateConfirmation from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/CreateComplianceFormTemplateConfirmation/CreateComplianceFormTemplateConfirmation'
import CreateComplianceFormTemplateDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/CreateComplianceFormTemplateForm/CreateComplianceFormTemplateDisplayData'
import ComplianceAssessmentInformationDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceAssessmentInformationForm/ComplianceAssessmentInformationDisplayData'
import ComplianceSelfAssessmentQuestionnaireADisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceSelfAssessmentQuestionnaireAForm/ComplianceSelfAssessmentQuestionnaireADisplayData'
import ComplianceCompensatingControlsWorksheetDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceCompensatingControlsWorksheetForm/ComplianceCompensatingControlsWorksheetDisplayData'
import ComplianceExplanationOfNonApplicabilityDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceExplanationOfNonApplicabilityForm/ComplianceExplanationOfNonApplicabilityDisplayData'
import ComplianceValidationAndAttestationDetailsDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceValidationAndAttestationDetailsForm/ComplianceValidationAndAttestationDetailsDisplayData'
import ComplianceApplyTemplateDisplayData from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/ComplianceApplyTemplateForm/ComplianceApplyTemplateDisplayData'
import { getComplianceFormTemplateSelector } from 'state-layer/selectors'
import getComplianceFormTemplateRequest from 'utilities/actions/get/getComplianceFormTemplateRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { UNSELECT_ALL_ITEMS } from 'constants/flowConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import getMany from 'utilities/get/getMany'

import {
  CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY,
  COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY,
  COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY,
  COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY,
  COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY,
  COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY,
  COMPLIANCE_APPLY_TEMPLATE_DATA_KEY,
} from 'constants/flowsConstants'

import {
  MERCHANT_QSA_DYNAMIC_VALUES,
  PCI_SAQ_A,
} from 'constants/complianceConstants'

const mapStateToProps = (state, props) => {
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const complianceFormTemplateId = get(props, 'params.complianceFormTemplateId')
  const complianceFormTemplate = getComplianceFormTemplateSelector(state, complianceFormTemplateId)
  const credentials = getCurrentCredentials(state, props)
  const isFetching = get(state, `complianceFormTemplatesR.${FETCHING}`)

  const [
    createComplianceFormTemplateForm,
    complianceAssessmentInformationForm,
    complianceSelfAssessmentQuestionnaireAForm,
    complianceCompensatingControlsWorksheetForm,
    complianceExplanationOfNonApplicabilityForm,
    complianceValidationAndAttestationDetailsForm,
  ] = getMany(complianceFormTemplate, [
    'complianceFormTemplateData.createComplianceFormTemplateForm',
    'complianceFormTemplateData.complianceAssessmentInformationForm',
    'complianceFormTemplateData.complianceSelfAssessmentQuestionnaireAForm',
    'complianceFormTemplateData.complianceCompensatingControlsWorksheetForm',
    'complianceFormTemplateData.complianceExplanationOfNonApplicabilityForm',
    'complianceFormTemplateData.complianceValidationAndAttestationDetailsForm',
  ])

  const steps = [
    {
      name: complianceFormTemplateId ? 'Duplicate Template' : 'Create New Template',
      component: CreateComplianceFormTemplateForm,
      props: {
        complianceFormTemplateId,
        submitLabel: 'Continue',
      },
      dataKey: CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY,
      getDisplayData: CreateComplianceFormTemplateDisplayData,
      initialValues: merge({}, { type: PCI_SAQ_A }, createComplianceFormTemplateForm),
      formValues: () => reduxFormValues(CREATE_NEW_COMPLIANCE_FORM_TEMPLATE_DATA_KEY),
    },
    {
      name: 'Assessment Information',
      component: ComplianceAssessmentInformationFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY,
      getDisplayData: ComplianceAssessmentInformationDisplayData,
      initialValues: merge({}, MERCHANT_QSA_DYNAMIC_VALUES, complianceAssessmentInformationForm),
      formValues: () => reduxFormValues(COMPLIANCE_ASSESSMENT_INFORMATION_DATA_KEY),
    },
    {
      name: 'SAQ A',
      component: ComplianceSelfAssessmentQuestionnaireAFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY,
      getDisplayData: ComplianceSelfAssessmentQuestionnaireADisplayData,
      initialValues: complianceSelfAssessmentQuestionnaireAForm,
      formValues: () => reduxFormValues(COMPLIANCE_SELF_ASSESSMENT_QUESTIONNAIRE_A_DATA_KEY),
    },
    {
      name: 'Appendix B',
      component: ComplianceCompensatingControlsWorksheetFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY,
      getDisplayData: ComplianceCompensatingControlsWorksheetDisplayData,
      initialValues: complianceCompensatingControlsWorksheetForm,
      formValues: () => reduxFormValues(COMPLIANCE_COMPENSATING_CONTROLS_WORKSHEET_DATA_KEY),
    },
    {
      name: 'Appendix C',
      component: ComplianceExplanationOfNonApplicabilityFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY,
      getDisplayData: ComplianceExplanationOfNonApplicabilityDisplayData,
      initialValues: complianceExplanationOfNonApplicabilityForm,
      formValues: () => reduxFormValues(COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_DATA_KEY),
    },
    {
      name: 'Validation and Attestation',
      component: ComplianceValidationAndAttestationDetailsFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY,
      getDisplayData: ComplianceValidationAndAttestationDetailsDisplayData,
      initialValues: complianceValidationAndAttestationDetailsForm,
      formValues: () => reduxFormValues(COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_DATA_KEY),
    },
    {
      name: 'Publish Options',
      component: ComplianceApplyTemplateFormC,
      props: {
        submitLabel: 'Continue',
        leftButton: true,
      },
      dataKey: COMPLIANCE_APPLY_TEMPLATE_DATA_KEY,
      formValues: () => reduxFormValues(COMPLIANCE_APPLY_TEMPLATE_DATA_KEY),
      getDisplayData: ComplianceApplyTemplateDisplayData,
    },
    {
      name: 'Confirmation',
      component: CreateComplianceFormTemplateConfirmation,
      props: {
        complianceFormTemplateId,
        submitLabel: 'Submit',
        leftButton: true,
      },
    },
  ]

  const onConfirmationPage = get(state, 'flowsR.activeStep') === steps.length - 1 // prevent confirmation page from disappearing

  return {
    steps,
    complianceFormTemplate,
    complianceFormTemplateId,
    credentials,
    isFetching,
    onConfirmationPage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComplianceFormTemplate: ({ complianceFormTemplateId, credentials }) => dispatch(getComplianceFormTemplateRequest({ complianceFormTemplateId, credentials })),
    unselectAllItems: () => dispatch({ type: UNSELECT_ALL_ITEMS }),
  }
}

class CreateComplianceFormTemplateFlowC extends Component {
  componentDidMount() {
    const {
      complianceFormTemplateId,
      complianceFormTemplate,
      getComplianceFormTemplate,
      credentials,
    } = this.props

    if (complianceFormTemplateId && isEmpty(complianceFormTemplate)) getComplianceFormTemplate({ complianceFormTemplateId, credentials })
  }

  componentWillUnmount() {
    const { unselectAllItems } = this.props

    unselectAllItems()
  }

  render () {
    return (
      <CreateComplianceFormTemplateFlow {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateComplianceFormTemplateFlowC)
