import React, { Component } from 'react'
import DocumentDetails from './DocumentDetails'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatAddress from 'utilities/formatters/formatAddress'
import getMany from 'utilities/get/getMany'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import get from 'lodash/get'

import {
  FIRST_NAME,
  LAST_NAME,
  BIRTHDATE,
  ADDRESS,
  ISSUE_DATE,
  EXPIRATION_DATE,
  ID_TYPE,
  ID_NUMBER,
  ISSUING_SUBDIVISION,
  FRONT_PHOTO,
  BACK_PHOTO,
  NOT_PROVIDED,
  VIEW_PHOTO,
  SOURCE,
  VIEW_SOURCE,
} from 'constants/language/languageConstants'

class DocumentDetailsC extends Component {
  render() {
    const report = get(this.props, 'report', {})

    const [
      firstName,
      lastName,
      birthDate,
      address = {},
      issueDate,
      expirationDate,
      idClass,
      idNumber,
      issuingSubdivision,
      frontPhotoUrl,
      backPhotoUrl,
      vendorReportId,
    ] = getMany(report, [
      'details.photo_id_details.first_name',
      'details.photo_id_details.last_name',
      'details.photo_id_details.birthdate',
      'details.photo_id_details.address',
      'details.photo_id_details.issue_date',
      'details.photo_id_details.expiration_date',
      'details.photo_id_details.id_class',
      'details.photo_id_details.id_number',
      'details.photo_id_details.issuing_subdivision',
      'input.front_photo_url',
      'input.back_photo_url',
      'sources[0].vendor_report_id',
    ])

    const sourceUrl = `https://app.withpersona.com/dashboard/verifications/${vendorReportId}`
    const fullName = `${firstName} ${lastName}`

    const {
      street1,
      street2,
      city,
      region,
      postal_code: postalCode,
      country,
    } = address

    const normalizedAddress = {
      line1: street1,
      line2: street2,
      city,
      region,
      postalCode,
      country,
    }

    const idPhotosData = convertPageSectionDataToV2(
      [
        {
          label: FRONT_PHOTO,
          value: frontPhotoUrl ? <a href={frontPhotoUrl} target='_blank' rel='noreferrer'>{VIEW_PHOTO}</a> : NOT_PROVIDED,
        },
        {
          label: BACK_PHOTO,
          value: backPhotoUrl ? <a href={backPhotoUrl} target='_blank' rel='noreferrer'>{VIEW_PHOTO}</a> : NOT_PROVIDED,
        },
        {
          label: SOURCE,
          value: vendorReportId ? <a className='text-link' href={sourceUrl} target='_blank' rel='noreferrer'>{VIEW_SOURCE} <i className={`fa fa-${EXTERNAL_LINK}`} /></a> : NOT_PROVIDED,
        },
      ],
    )

    const documentDetailsData = convertPageSectionDataToV2(
      [
        {
          label: FIRST_NAME,
          value: firstName,
        },
        {
          label: LAST_NAME,
          value: lastName,
        },
        {
          label: BIRTHDATE,
          value: birthDate,
        },
        {
          label: ADDRESS,
          value: formatAddress({ address: normalizedAddress }),
        },
        {
          label: ISSUE_DATE,
          value: issueDate,
        },
        {
          label: EXPIRATION_DATE,
          value: expirationDate,
        },
        {
          label: ID_TYPE,
          value: idClass,
        },
        {
          label: ID_NUMBER,
          value: idNumber,
        },
        {
          label: ISSUING_SUBDIVISION,
          value: issuingSubdivision,
        },
      ],
    )

    return (
      <DocumentDetails
        {...this.props}
        fullName={fullName}
        idPhotosData={idPhotosData}
        documentDetailsData={documentDetailsData}
      />
    )
  }
}

export default DocumentDetailsC
