import removeUndefined from 'utilities/remove/removeUndefined'
import { FETCHING } from 'constants/reducerConstants'
import getMany from 'utilities/get/getMany'
import filter from 'lodash/filter'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'

// TODO: should we es6 deconstruct this? I usually would, but it could make reducer code look a bit uglier
const updateState = (
  state = {},
  action,
  isFetching = false,
  data = {},
  overwriteReducer = false,
) => {
  // TODO: move these to own reducer, however that could get ugly to look for that all the time, or handle it in genericFlow/Saga
  const payload = get(action, 'payload')

  const [
    links,
    page,
    updateSelf,
  ] = getMany(payload, [
    'links',
    'page',
    'updateSelf',
  ])

  const existingItems = get(state, 'items', {})
  const combinedItems = updateSelf ? keyBy(filter(data, (item, itemId) => get(existingItems, itemId)), 'id') : data

  const newState = removeUndefined({
    items: combinedItems,
    [FETCHING]: isFetching,
    links, // TODO: add reducer to store all link/page info keyed by reducer. could be refactor of linksR
    page,
  })

  // TODO: Should we wipe out the reducer even on the fetch request? Or should we only wipe it when we get a successful response back?
  return overwriteReducer ? merge({}, newState) : merge({}, state, newState)
}

export default updateState
