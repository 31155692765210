import { useRouterHistory } from 'react-router'
import { createHistory as createHistoryOriginal } from 'history'
import createQueryString from 'utilities/create/createQueryString'

let history = null

export const createHistory = () => {
  // TODO: make this basename come from env config files
  history = useRouterHistory(createHistoryOriginal)({ basename: '/' })

  return history
}

export const getHistory = () => {
  return history
}

export const goToPath = ({ pathname, queries = {} }) => {
  history.push({
    pathname,
    search: createQueryString({ queries }),
  })
}

export const goBack = () => {
  if (history) {
    history.goBack()
  }
}
