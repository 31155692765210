import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import hideModalAction from 'utilities/actions/hideModalAction'
import { SEND_DASHBOARD_INVITE_EMAIL } from 'constants/amplitudeConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'

import {
  POST_USERS_EMAIL_F_REQUEST,
  POST_DASHBOARD_V2_EMAILS_F_REQUEST,
} from 'constants/flowConstants'

import {
  FLEX_PAYMENTS_DASHBOARD_WELCOME_EMAIL_TEMPLATE,
  WELCOME_EMAIL_PASSWORD_TEMPLATE,
} from 'constants/emailTemplateConstants'

const submitSendWelcomeEmailForm = (values, dispatch, props) => {
  const {
    id,
    credentials,
    entityId = '',
    email = '',
  } = props

  const template = WELCOME_EMAIL_PASSWORD_TEMPLATE
  const normalizedEmail = email.toLowerCase()
  const isFlex = isFlexPlatform()

  const dashboardConfig = getCurrentDashboardConfig()
  const dashboardConfigEntityId = get(dashboardConfig, 'entityId')

  const valuesToSubmit = isFlex ? {
    email_recipient: normalizedEmail,
    entity_id: dashboardConfigEntityId || entityId,
    email_template: FLEX_PAYMENTS_DASHBOARD_WELCOME_EMAIL_TEMPLATE,
  } : {
    recipient_email: normalizedEmail,
    email_template_name: `${getCurrentPlatformName()}-${template}`, // <platformName>-welcome-email-password
    email_template_data: {
      url: 'https://finix.com', // BE is not using this data
    },
  }

  const actionType = isFlex ? POST_DASHBOARD_V2_EMAILS_F_REQUEST : POST_USERS_EMAIL_F_REQUEST

  dispatch({
    type: actionType,
    payload: {
      id,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SEND_DASHBOARD_INVITE_EMAIL, {
        userId: id,
        template,
        credentials,
      }),
      successMessage: `Welcome email has been successfully sent to ${email}`,
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default submitSendWelcomeEmailForm
