import React, { Component } from 'react'
import { connect } from 'react-redux'
import Login from './Login'
import getGuestDashboardCustomizationsRequest from 'utilities/actions/get/getGuestDashboardCustomizationsRequest'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import getCurrentAuth0Configs from 'utilities/get/getCurrentAuth0Configs'
import { PLATFORM_NAME } from 'constants/queryConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'
import head from 'lodash/head'

const mapStateToProps = (state) => {
  const platformName = get(state, PLATFORM_NAME, 'finix')
  const hostname = get(window, 'location.hostname', '')
  const SUBDOMAIN_ARRAY_CHECK = ['finix', 'qa', 'sandbox', 'dev']
  const subDomain = hostname.split('.')[0]

  const showSignUpButton = includes(SUBDOMAIN_ARRAY_CHECK, subDomain)

  return {
    hostname,
    platformName,
    showSignUpButton,
  }
}

class LoginC extends Component {
  componentDidMount() {
    const {
      auth,
      dispatch,
    } = this.props

    localStorage.removeItem('auth-session')
    localStorage.removeItem('isLoggedIn')

    dispatch(getGuestDashboardCustomizationsRequest({
      queries: {
        sub_domain_name: getCurrentPlatformName(),
      },
      meta: {
        successCallback: ({ newValues = {} }) => {
          const customization = head(Object.keys(newValues))
          const customLogo = get(newValues, `${customization}.sidebarLogoImageUrl`)

          if (customLogo) {
            const {
              title = '',
              allowSignUp = false,
            } = getCurrentAuth0Configs()

            const AuthLockOptions = {
              languageDictionary: {
                title,
              },
              theme: {
                logo: customLogo,
              },
              container: 'login-container',
              closable: false,
              allowSignUp,
            }

            auth.updateAuth0Options(AuthLockOptions)

            setTimeout(() => {
              auth.login()
            }, 100)
          } else {
            auth.login()
          }
        },
        errorCallback: () => {
          auth.login()
        },
      },
    }))

    // localStorage.setItem('platformName', getCurrentPlatformName())
  }

  render () {
    return (
      <Login {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(LoginC)
