import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_USERS,
} from 'constants/apiConstants'

const postIdentityUserAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: IDENTITY_USERS({ identityId: id }),
    service: IDENTITIES,
  })
}

export default postIdentityUserAPI
