import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import DisputeReview from './DisputeReview'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import { getDisputeSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { VANTIV_V1 } from 'constants/processorConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import partition from 'lodash/partition'
import map from 'lodash/map'

import {
  AMOUNT,
  CREATED_ON,
  FEES,
  LAST_UPDATED,
  MERCHANT_ACCOUNT,
  PIN_DEBIT,
  PIN_DEBIT_ADJUSTMENT_NUMBER,
  PROCESSOR,
  RESPOND_BY,
  STATE,
  STATEMENT_DESCRIPTOR,
  TRACE_ID,
  TYPE,
  VIEW_PAYMENT,
} from 'constants/language/languageConstants'

import {
  isRoleMerchant as isRoleMerchantCred,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  MERCHANT_PATH,
  PAYMENT_PATH,
  PAYMENTS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `disputesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const disputeId = get(props, 'disputeId')
  const dispute = getDisputeSelector(state, disputeId)
  const paymentsPath = PAYMENTS_PATH({ credentialId })
  const isRolePlatform = hasPlatformAccess({ credentials })
  const isRoleMerchant = isRoleMerchantCred({ credentials })

  const [
    displayRespondBy,
    displayUpdatedAt,
    isPinDebit,
    pinDebitAdjustmentNumber,
    merchant,
    transfer,
    buyerIdentity,
    paymentInstrument,
    adjustmentTransfers,
    tags,
  ] = getMany(dispute, [
    'displayRespondBy',
    'displayUpdatedAt',
    'isPinDebit',
    'pinDebitAdjustmentNumber',
    'merchantObj',
    'transferDetails',
    'buyerIdentity',
    'paymentInstrument',
    'adjustmentTransfers',
    'tags',
  ])

  const [
    processor,
    displayProcessor,
    merchantIdentityId,
    merchantId,
    merchantName,
  ] = getMany(merchant, [
    'processor',
    'displayProcessor',
    'identityId',
    'id',
    'displayBusinessName',
  ])

  const [
    transferId,
    transferTraceId,
    transferAmountWithCurrencyCode,
    transferFeeWithCurrencyCode,
    transferState,
    transferCreatedOn,
    transferType,
    transferStatementDescriptor,
    transferCurrency,
  ] = getMany(transfer, [
    'id',
    'traceId',
    'displayAmountWithCurrencyCode',
    'displayFeeWithCurrencyCode',
    'state',
    'displayCreatedAt',
    'displayType',
    'statementDescriptor',
    'currency',
  ])

  const parentPaymentLink = <Link className='text-link' to={PAYMENT_PATH({ credentialId, transferId })}>{VIEW_PAYMENT}</Link>
  const isVantivDispute = isEqual(processor, VANTIV_V1)
  const merchantLink = <Link className='text-link' to={MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId })}>{merchantName}</Link>
  const groupedAdjustmentTransfers = partition(adjustmentTransfers, ({ subtype }) => { return subtype === 'PLATFORM_DEBIT' || subtype === 'PLATFORM_CREDIT' }, [])
  const platformAdjustmentTransfers = groupedAdjustmentTransfers[0]
  const merchantAdjustmentTransfers = groupedAdjustmentTransfers[1]
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  const brand = get(paymentInstrument, 'brand')

  const disputeDetailsSection = [
    {
      type: 'data',
      data: [
        {
          label: RESPOND_BY,
          value: displayRespondBy,
        },
        {
          label: LAST_UPDATED,
          value: displayUpdatedAt,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: PIN_DEBIT,
          value: isPinDebit,
        },
        {
          label: PIN_DEBIT_ADJUSTMENT_NUMBER,
          value: pinDebitAdjustmentNumber,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: MERCHANT_ACCOUNT,
          value: merchantLink,
        },
        {
          label: PROCESSOR,
          value: displayProcessor,
        },
      ],
    },
  ]

  const parentPaymentClickToCopies = [
    {
      content: transferId,
    },
    {
      label: TRACE_ID,
      content: transferTraceId,
    },
  ]

  const parentPaymentSection = [
    {
      type: 'data',
      data: [
        {
          label: AMOUNT,
          value: transferAmountWithCurrencyCode,
        },
        {
          label: FEES,
          value: transferFeeWithCurrencyCode,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: STATE,
          value: <TransferStatus value={transferState} />,
        },
        {
          label: CREATED_ON,
          value: transferCreatedOn,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: TYPE,
          value: transferType,
        },
        {
          label: STATEMENT_DESCRIPTOR,
          value: transferStatementDescriptor,
        },
      ],
    },
  ]

  return {
    isFetching,
    credentials,
    credentialId,
    dispute,
    disputeId,
    isVantivDispute,
    disputeDetailsSection,
    parentPaymentClickToCopies,
    parentPaymentSection,
    parentPaymentLink,
    paymentsPath,
    isRolePlatform,
    isRoleMerchant,
    buyerIdentity,
    paymentInstrument,
    platformAdjustmentTransfers,
    merchantAdjustmentTransfers,
    tagsSectionData,
    brand,
  }
}

class DisputeReviewC extends Component {
  render() {
    return (
      <DisputeReview
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DisputeReviewC)
