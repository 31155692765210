import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import SendEmailForm from 'components/Customer/Forms/SendEmailForm/SendEmailForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'
import { SEND_EMAIL_FORM } from 'constants/formConstants'
import selectOption from 'utilities/forms/selectOption'
import { isFlexPlatform } from 'constants/flexConstants'

const emailTemplateOptions = [
  selectOption('Select Template', ''),
  selectOption('Welcome Email', 'welcome-email-password'),
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const user = get(props, 'user')
  const isFlex = isFlexPlatform()
  const { id, email } = user

  const formSelector = formValueSelector(SEND_EMAIL_FORM)
  const templateFieldValue = formSelector(state, 'template')

  return {
    id,
    email,
    credentials,
    templateFieldValue,
    isFlex,
  }
}

class SendEmailFormC extends Component {
  render() {
    return (
      <SendEmailForm
        {...this.props}
        emailTemplateOptions={emailTemplateOptions}
      />
    )
  }
}

export default connect(mapStateToProps)(SendEmailFormC)
