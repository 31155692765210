import deleteSubscriptionEnrollmentAPI from 'api/finix/delete/deleteSubscriptionEnrollmentAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import map from 'lodash/map'
import get from 'lodash/get'
import co from 'co'

function * deleteSubscriptionEnrollmentsO ({
  values = {},
  credentials,
}) {
  const { enrollmentIds } = values

  const responses = yield map(enrollmentIds, (enrollmentId) => deleteSubscriptionEnrollmentAPI({ id: enrollmentId, credentials }))

  const enrollments = map(responses, ({ data, error }, index) => {
    const id = get(enrollmentIds, index)

    if (error) {
      return {
        id,
        errors: get(error, 'response.data._embedded.errors'),
      }
    }

    return id
  })

  // TODO: handle path for displaying multiple errors
  return createSuccessResponse({
    data: {
      data: enrollments,
    },
  })
}

export default co.wrap(deleteSubscriptionEnrollmentsO)
