import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  EXIT_APPLICATION_FORM,
  EXIT_APPLICATION_FORM_MESSAGE,
} from 'constants/language/languageConstants'

const ExitApplicationForm = ({
  redirectUserToHome = () => {},
  closeModal = () => {},
}) => {
  return (
    <div className='ExitApplicationForm'>
      <div className='message'>{EXIT_APPLICATION_FORM_MESSAGE}</div>
      <div className='second-message'>Any partially completed step cannot be saved. If you completed this entire step, click <span className='bold'>Save and Continue</span> to save your progress before exiting.</div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={redirectUserToHome} label={EXIT_APPLICATION_FORM} />
      </div>
    </div>
  )
}

ExitApplicationForm.propTypes = {
  redirectUserToHome: PropTypes.func,
  closeModal: PropTypes.func,
}

export default ExitApplicationForm
