import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST } from 'constants/flowConstants'
import { UNREGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAINS } from 'constants/amplitudeConstants'

import {
  APPLE_PAY,
  UNREGISTER,
} from 'constants/paymentMethodConstants'

const unregisterApplePayDomainRequest = ({
  domainToUnregister,
  credentials,
  dispatch,
  paymentMethodConfigId,
  identityId,
}) => {
  return createAction({
    type: PATCH_PAYMENT_METHOD_CONFIGURATIONS_F_REQUEST,
    values: { domainToUnregister, paymentMethodConfigId, identityId },
    credentials,
    meta: {
      actionType: UNREGISTER,
      type: APPLE_PAY,
      actionId: sendAmplitudeActionEvent(UNREGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAINS, {
        credentials,
        domainToUnregister,
        paymentMethodConfigId,
      }),
      successMessage: `${domainToUnregister} successfully removed.`,
      failMessage: `Failed to remove ${domainToUnregister}`,
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default unregisterApplePayDomainRequest
