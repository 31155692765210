import './PaymentInstrumentAuthorizationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { PAYMENT_INSTRUMENT_AUTHORIZATIONS } from 'constants/linkConstants'
import { GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getPaymentInstrumentAuthorizations } from 'state-layer/selectors'

const PaymentInstrumentAuthorizations = ({
  flowValues = {},
  initialQueries = {},
  authorizationPath = '',
  isFetching = false,
  paymentInstrumentId = '',
  allowedFilters = [],
  quickFilters = [],
}) => {
  return (
    <div className='PaymentInstrumentAuthorizations'>
      <TableC
        flow={GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getPaymentInstrumentAuthorizations}
        selectorId={paymentInstrumentId}
        initialQueries={initialQueries}
        linksKey={PAYMENT_INSTRUMENT_AUTHORIZATIONS}
        path={authorizationPath}
        isFetching={isFetching}
        fileNameCSV={`PaymentInstrument_${paymentInstrumentId}_Authorizations`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        allowedFilters={allowedFilters}
        quickFilters={quickFilters}
      />
    </div>
  )
}

PaymentInstrumentAuthorizations.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  authorizationPath: PropTypes.string,
  isFetching: PropTypes.bool,
  paymentInstrumentId: PropTypes.string,
  allowedFilters: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default PaymentInstrumentAuthorizations
