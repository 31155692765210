import React from 'react'
import { payoutColumnDescriptors } from '../columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import getMany from 'utilities/get/getMany'
import { infoTableTheme } from 'constants/themeConstants'
import get from 'lodash/get'

const WeeklyPayoutsTable = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displayPayoutCreatedAtWeek,
    displayPayoutCreatedAtWeekPrior,
    displayPayoutAmountDollarsSum,
    displayPayoutAmountDollarsSumPriorWeek,
    displayPayoutAmountDollarsAverage,
    displayPayoutAmountDollarsAveragePriorWeek,
    payoutCount,
    payoutCountPriorWeek,
  ] = getMany(calculations, [
    'displayPayoutCreatedAtWeek',
    'displayPayoutCreatedAtWeekPrior',
    'displayPayoutAmountDollarsSum',
    'displayPayoutAmountDollarsSumPriorWeek',
    'displayPayoutAmountDollarsAverage',
    'displayPayoutAmountDollarsAveragePriorWeek',
    'payoutCount',
    'payoutCountPriorWeek',
  ])

  const payoutRows = [
    {
      createdAt: displayPayoutCreatedAtWeek,
      sum: displayPayoutAmountDollarsSum,
      average: displayPayoutAmountDollarsAverage,
      count: payoutCount,
    },
    {
      createdAt: displayPayoutCreatedAtWeekPrior,
      sum: displayPayoutAmountDollarsSumPriorWeek,
      average: displayPayoutAmountDollarsAveragePriorWeek,
      count: payoutCountPriorWeek,
    },
  ]

  return (
    <TableC
      classNames='alert-insights-table'
      showTableFooter={false}
      theme={infoTableTheme}
      columnDescriptors={payoutColumnDescriptors}
      data={payoutRows}
    />
  )
}

export default WeeklyPayoutsTable
