const specialWords = {
  is: 'are',
}

const pluralizeWord = ({ count = 1, word = '' }) => {
  const specialPluralWord = specialWords[word]

  if (specialPluralWord) {
    return (count > 1 || count === 0) ? specialPluralWord : word
  }

  return `${word}${(count > 1 || count === 0) ? 's' : ''}`
}

export default pluralizeWord
