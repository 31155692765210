import co from 'co'
import getFeesAPI from 'api/finix/get/getFeesAPI'
import getSettlementsAPI from 'api/finix/get/getSettlementsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getResponseLinks from 'utilities/get/getResponseLinks'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'

function * getFeesO ({
  meta,
  credentials = {},
  queries = {},
}) {
  const { data: response } = yield getFeesAPI({ credentials, queries, meta })
  const fees = get(response, '_embedded.fees', [])

  const settlementsResponse = yield map(fees, ({ id }) => {
    return getSettlementsAPI({ credentials,
      queries: { entry_id: id },
    })
  })

  const mergedFees = fees.map((fee, idx) => {
    const settlement = get(settlementsResponse, `${idx}.data._embedded.settlements.0`)
    const settlementId = get(settlement, 'id')

    return merge({}, fee, {
      settlement,
      settlementId,
    })
  })

  const page = get(response, 'page')
  const links = getResponseLinks(response)

  return createSuccessResponse({
    data: {
      fees: mergedFees,
      page,
      links,
    },
  })
}

export default co.wrap(getFeesO)
