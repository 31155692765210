import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { notPayoutFeature } from 'utilities/validate/checkCredentialFeatures'

import {
  BUSINESS,
  CREATED_ON,
  ID,
  PAYMENT_PROCESSING,
  SETTLEMENTS,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ isStandaloneUnderwriting = false }) => [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(BUSINESS, 'identity.displayBusinessName', { className: 'business-name large' }),
  column(PAYMENT_PROCESSING, 'processing', {
    condition: !isStandaloneUnderwriting,
    formatter: ({ processing }) => <EnabledStatus value={processing} />,
  }),
  column(SETTLEMENTS, 'settlementsEnabledLabel', {
    condition: notPayoutFeature && !isStandaloneUnderwriting,
    formatter: ({ settlementsEnabledLabel }) => (
      <EnabledStatus value={settlementsEnabledLabel} />
    ),
  }),
]

export default columnDescriptors
