import './StartHostedCustomerOnboardingScreenS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import CustomerOnboardingFormWelcomeFormC from 'components/Customer/Forms/CustomerOnboardingFormWelcomeForm/CustomerOnboardingFormWelcomeFormC'
import { ReactComponent as ProcessLivePaymentsImg } from 'img/svg/application-header-image.svg'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { FINIX_PRIVACY_POLICY_URL } from 'constants/urlConstants'

import {
  CUSTOMER_ONBOARDING_FORM_WELCOME_TEXT_2,
  PRIVACY_POLICY,
} from 'constants/language/languageConstants'

const StartHostedCustomerOnboardingScreen = ({
  accessFormId = '',
  accessFormCountry = '',
  isSelfService = false,
  dashboardType = '',
}) => {
  return (
    <div className='StartHostedCustomerOnboardingScreen'>
      <div className='hosted-customer-onboarding-welcome'>
        <div className='img-container flex items-center center'>
          <ProcessLivePaymentsImg />
        </div>
        <div className='text-container'>
          <h5>Let us help your business process payments.</h5>
          <div className='body p-2'>We need to collect and verify certain information about you and your business as required by regulation and our financial partners. See our <a onClick={sendAmplitudeActionEvent(FINIX_PRIVACY_POLICY_URL)} className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>{PRIVACY_POLICY}</a> for details.</div>
          <div className='body p-2'>{CUSTOMER_ONBOARDING_FORM_WELCOME_TEXT_2}</div>
          <CustomerOnboardingFormWelcomeFormC
            accessFormId={accessFormId}
            accessFormCountry={accessFormCountry}
            isSelfService={isSelfService}
            dashboardType={dashboardType}
          />
        </div>
      </div>
    </div>
  )
}

StartHostedCustomerOnboardingScreen.propTypes = {
  accessFormId: PropTypes.string,
  accessFormCountry: PropTypes.string,
  isSelfService: PropTypes.bool,
  dashboardType: PropTypes.string,
}

export default StartHostedCustomerOnboardingScreen
