import './EditContinuousMonitoringSettingsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import submitEditContinuousMonitoringSettingsForm from 'utilities/submit/submitEditContinuousMonitoringSettingsForm'
import { EDIT_CONTINUOUS_MONITORING_SETTINGS_FORM } from 'constants/formConstants'

import {
  sanctionsScreeningCadenceOptions,
  adverseMediaReviewCadenceOptions,
} from 'constants/underwritingProfileConstants'

import {
  ADVERSE_MEDIA_REVIEW_CADENCE,
  CANCEL,
  SANCTIONS_SCREENING_CADENCE,
  UPDATE,
} from 'constants/language/languageConstants'

const EditContinuousMonitoringSettingsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
}) => {
  return (
    <form className='EditContinuousMonitoringSettingsForm' onSubmit={handleSubmit(submitEditContinuousMonitoringSettingsForm)}>
      <div className='modal-content'>
        <Field
          name='adverseMediaReviewCadence'
          component={ReactSelect}
          options={adverseMediaReviewCadenceOptions}
          label={ADVERSE_MEDIA_REVIEW_CADENCE}
          required
        />

        <Field
          name='sanctionsScreeningCadence'
          component={ReactSelect}
          options={sanctionsScreeningCadenceOptions}
          label={SANCTIONS_SCREENING_CADENCE}
          required
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' className='save-payment-instrument-button' label={UPDATE} />
      </div>
    </form>
  )
}

EditContinuousMonitoringSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: EDIT_CONTINUOUS_MONITORING_SETTINGS_FORM,
})(EditContinuousMonitoringSettingsForm)
