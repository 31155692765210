import './EditFeeProfileRulesFlowS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const EditFeeProfileRulesFlow = ({
  steps,
  title = '',
}) => {
  return (
    <div className='EditFeeProfileRulesFlow'>
      <FlowsC
        layout='vertical'
        steps={steps}
        title={title}
      />
    </div>
  )
}

EditFeeProfileRulesFlow.propTypes = {
  steps: PropTypes.array,
  title: PropTypes.string,
}

export default EditFeeProfileRulesFlow
