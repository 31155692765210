import React from 'react'
import PropTypes from 'prop-types'
import EditIdentityBankAccountsFormC from 'components/Customer/Forms/EditIdentityBankAccountsForm/EditIdentityBankAccountsFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const EditIdentityBankAccountsModal = ({
  identityId,
  settlementAliasWarningObject,
}) => {
  return (
    <GenericModal
      title='Edit bank accounts'
      className='EditIdentityBankAccountsModal'
      Component={EditIdentityBankAccountsFormC}
      identityId={identityId}
      settlementAliasWarningObject={settlementAliasWarningObject}
    />
  )
}

EditIdentityBankAccountsModal.propTypes = {
  identityId: PropTypes.string,
  settlementAliasWarningObject: PropTypes.object,
}

export default EditIdentityBankAccountsModal
