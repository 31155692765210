import frontendSubscriptionEnrollmentModel from 'utilities/create/models/frontendSubscriptionEnrollmentModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSubscriptionEnrollmentsModel = ({ data: subscriptionEnrollments }) => {
  const subscriptionEnrollmentsModels = map(subscriptionEnrollments, (subscriptionEnrollment) => frontendSubscriptionEnrollmentModel({ data: subscriptionEnrollment }))

  return keyBy(subscriptionEnrollmentsModels, 'id')
}

export default frontendSubscriptionEnrollmentsModel
