import { POST_IDENTITY_SETTLEMENTS_F_REQUEST } from 'constants/flowConstants'

const submitIdentitySettlementForm = (values, dispatch, { credentials }) => {
  dispatch({
    type: POST_IDENTITY_SETTLEMENTS_F_REQUEST,
    payload: {
      values,
      credentials,
    },
  })
}

export default submitIdentitySettlementForm
