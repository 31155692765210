import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import get from 'lodash/get'
import _values from 'lodash/values'
import some from 'lodash/some'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { CLOSE_MERCHANT_SETTLEMENTS } from 'constants/amplitudeConstants'
import { EQUALS } from 'constants/filterConstants'
import { MERCHANT_SETTLEMENTS } from 'constants/linkConstants'

import {
  CLOSE_SETTLEMENTS_F_REQUEST,
  CLOSE_SETTLEMENTS_F_FAILURE,
  PATCH_SETTLEMENT_F_SUCCESS,
  UNSELECT_ALL_ITEMS,
  REMOVE_TABLE_FILTER,
} from 'constants/flowConstants'

const closeSettlementsRequest = ({
  settlementIds,
  credentials,
  dispatch,
  selectedItemsKey,
}) => {
  return createAction({
    type: CLOSE_SETTLEMENTS_F_REQUEST,
    values: {
      settlementIds,
    },
    credentials,
    meta: {
      successCallback: ({ newValues }) => {
        const hasErrors = some(_values(newValues), (newValue) => get(newValue, 'errors'))

        if (!dispatch) return

        if (!hasErrors) {
          dispatch({
            type: UNSELECT_ALL_ITEMS,
            payload: {
              key: selectedItemsKey,
            },
          })

          dispatch({
            type: REMOVE_TABLE_FILTER,
            payload: {
              field: 'status',
              operand: EQUALS,
              linksKey: MERCHANT_SETTLEMENTS,
            },
          })

          dispatch({ type: PATCH_SETTLEMENT_F_SUCCESS })
        } else {
          dispatch({ type: CLOSE_SETTLEMENTS_F_FAILURE })
        }
      },
      actionId: sendAmplitudeActionEvent(CLOSE_MERCHANT_SETTLEMENTS, {
        credentials,
        settlementIds,
      }),
    },
  })
}

export default closeSettlementsRequest
