import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentDevices from './PaymentDevices'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import { FETCHING } from 'constants/reducerConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import {
  ADD_NEW_DEVICE,
  SELECT_A_MERCHANT_TITLE,
  SELECT_AN_APPLICATION_AND_MERCHANT_TITLE,
  SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR,
  SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR,
} from 'constants/language/languageConstants'

import {
  isRolePlatform,
  notRoleMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  DEVICE_DATES_QUICK_FILTER,
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  ADD_NEW_DEVICE_PATH,
  PAYMENT_DEVICES_PATH,
} from 'constants/pathConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

import {
  APPLICATION_ID,
  DEVICE_MODEL,
  DEVICE_NAME,
  MERCHANT_ID,
  SERIAL_NUMBER,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  APPLICATION_ID,
  DEVICE_MODEL,
  DEVICE_NAME,
  MERCHANT_ID,
  SERIAL_NUMBER,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
  DEVICE_DATES_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `devicesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const paymentDevicePath = PAYMENT_DEVICES_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const isRolePlatformCred = isRolePlatform({ credentials })
  const selectAddDeviceMerchantModalTitle = isRolePlatformCred ? SELECT_AN_APPLICATION_AND_MERCHANT_TITLE : SELECT_A_MERCHANT_TITLE
  const selectAddDeviceMerchantModalSubtitle = isRolePlatformCred ? SELECT_THE_APPLICATION_AND_MERCHANT_TO_ADD_DEVICE_FOR : SELECT_THE_MERCHANT_ACCOUNT_TO_ADD_DEVICE_FOR
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId') // this will only have a non-empty value when in a partner cred
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const createDeviceEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    isFetching,
    paymentDevicePath,
    credentials,
    credentialId,
    selectAddDeviceMerchantModalTitle,
    selectAddDeviceMerchantModalSubtitle,
    applicationId,
    createDeviceEnabledApplicationMerchants,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

class PaymentDevicesC extends Component {
  componentDidMount() {
    const {
      getApplicationMerchants,
      applicationId,
      credentials,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getApplicationMerchants,
      applicationId,
      credentials,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      credentials,
      credentialId,
      createDeviceEnabledApplicationMerchants,
    } = this.props

    const hasOneMerchant = size(createDeviceEnabledApplicationMerchants) === 1

    const actions = [
      {
        label: ADD_NEW_DEVICE,
        className: 'add-new-device',
        condition: notRoleMerchant({ credentials }),
        action: () => goToPath({
          pathname: ADD_NEW_DEVICE_PATH({ credentialId }),
          queries: hasOneMerchant ? { merchantId: get(createDeviceEnabledApplicationMerchants, '[0].id') } : {},
        }),
      },
    ]

    return (
      <PaymentDevices
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDevicesC)
