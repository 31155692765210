import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_VERIFICATIONS,
} from 'constants/apiConstants'

const postIdentityVerificationAPI = ({ values, credentials, meta }) => {
  const {
    identity,
  } = values

  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: IDENTITY_VERIFICATIONS({ identityId: identity }),
    service: IDENTITIES,
  })
}

export default postIdentityVerificationAPI
