import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import convertToMilitaryTime from 'utilities/convert/convertToMilitaryTime'
import redirectRequest from 'utilities/actions/redirectRequest'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import { USD } from 'constants/currencyConstants'
import { ALL } from 'constants/transferConstants'
import { GLOBAL } from 'constants/disbursementsConstants'
import { POST_DISBURSEMENT_RULES_F_REQUEST } from 'constants/flowConstants'
import { APPLICATION_PATH } from 'constants/pathConstants'
import { SET_VELOCITY_LIMITS } from 'constants/amplitudeConstants'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  DAILY,
  MONTHLY,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

import {
  APPLICATION,
  RECIPIENT,
} from 'constants/language/languageConstants'

const constructBalanceAdjustmentsRulesObject = ({ values }) => {
  const [
    maxTransactionAmountForACH,
    startTime,
    timeMeridian,
    dailyACHCount,
    dailyACHVolume,
    monthlyStartDate,
    monthlyACHCount,
    monthlyACHVolume,
  ] = getMany(values, [
    'maxTransactionAmountForACH',
    'startTime.value',
    'timeMeridian.value',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyACHCount',
    'monthlyACHVolume',
  ])

  return {
    application_details: {
      balance_adjustment_rules: [
        {
          currency: USD,
          type: GLOBAL,
          max_transaction_amount: normalizeBackendAmount(maxTransactionAmountForACH),
        },
        {
          currency: USD,
          time_frame: DAILY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: startTime, meridian: timeMeridian }),
          rules: [
            {
              day: ALL,
              count_limit: convertAmountStringToNumber({ amount: dailyACHCount }),
              volume_limit: normalizeBackendAmount(dailyACHVolume),
              enabled: true,
            },
          ],
        },
        {
          currency: USD,
          time_frame: MONTHLY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: startTime, meridian: timeMeridian }),
          start_date: monthlyStartDate,
          rules: [
            {
              count_limit: convertAmountStringToNumber({ amount: monthlyACHCount }),
              volume_limit: normalizeBackendAmount(monthlyACHVolume),
              enabled: true,
            },
          ],
        },
      ],
    },
  }
}

const constructRulesRequestObject = ({ entityType, values }) => {
  const {
    startTime,
    timeMeridian,
    timeZone = AMERICA_NY_TIMEZONE_BE_FIELD,
    dailyCardCount,
    dailyCardVolume,
    dailyACHCount,
    dailyACHVolume,
    monthlyStartDate,
    monthlyCardCount,
    monthlyCardVolume,
    monthlyACHCount,
    monthlyACHVolume,
    currency = USD, // TODO: support for Canada done in phase 1.5
    maxTransactionAmountForCards,
    maxTransactionAmountForACH,
  } = values

  const startTimeValue = get(startTime, 'value')
  const timeMeridianValue = get(timeMeridian, 'value')
  const monthlyStartDateValue = get(monthlyStartDate, 'value')
  const timeZoneValue = get(timeZone, 'value')

  const requestBody = {
    application_details: {
      ach_rules: [],
      card_rules: [],
    },
    recipient_details: {
      ach_rules: [],
      card_rules: [],
    },
    sender_details: {
      ach_rules: [
        {
          currency: USD,
          time_frame: DAILY,
          start_time: 0,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          rules: [
            {
              day: ALL,
              count_limit: 0,
              volume_limit: 0,
              enabled: true,
            },
          ],
        },
        {
          currency: USD,
          time_frame: MONTHLY,
          start_time: 0,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_date: 1,
          rules: [
            {
              count_limit: 0,
              volume_limit: 0,
              enabled: true,
            },
          ],
        },
        {
          currency,
          type: GLOBAL,
          max_transaction_amount: 0,
        },
      ],
      card_rules: [
        {
          currency: USD,
          time_frame: DAILY,
          start_time: 0,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          rules: [
            {
              day: ALL,
              count_limit: 0,
              volume_limit: 0,
              enabled: true,
            },
          ],
        },
        {
          currency: USD,
          time_frame: MONTHLY,
          start_time: 0,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_date: 1,
          rules: [
            {
              count_limit: 0,
              volume_limit: 0,
              enabled: true,
            },
          ],
        },
        {
          currency,
          type: GLOBAL,
          max_transaction_amount: 0,
        },
      ],
    },
  }

  const detailsPath = isEqual(entityType, APPLICATION) ? 'application_details' : 'recipient_details'

  const dailyCardObject = {
    currency,
    time_frame: DAILY,
    start_time: convertToMilitaryTime({ hour: startTimeValue, meridian: timeMeridianValue }),
    time_zone: timeZoneValue,
    rules: [
      {
        day: ALL,
        count_limit: convertAmountStringToNumber({ amount: dailyCardCount }),
        volume_limit: normalizeBackendAmount(dailyCardVolume),
        enabled: true,
      },
    ],
  }

  const dailyACHObject = {
    currency,
    time_frame: DAILY,
    start_time: convertToMilitaryTime({ hour: startTimeValue, meridian: timeMeridianValue }),
    time_zone: timeZoneValue,
    rules: [
      {
        day: ALL,
        count_limit: convertAmountStringToNumber({ amount: dailyACHCount }),
        volume_limit: normalizeBackendAmount(dailyACHVolume),
        enabled: true,
      },
    ],
  }

  const monthlyCardObject = {
    currency,
    time_frame: MONTHLY,
    start_time: convertToMilitaryTime({ hour: startTimeValue, meridian: timeMeridianValue }),
    time_zone: timeZoneValue,
    start_date: monthlyStartDateValue,
    rules: [
      {
        count_limit: convertAmountStringToNumber({ amount: monthlyCardCount }),
        volume_limit: normalizeBackendAmount(monthlyCardVolume),
        enabled: true,
      },
    ],
  }

  const monthlyACHObject = {
    currency,
    time_frame: MONTHLY,
    start_time: convertToMilitaryTime({ hour: startTimeValue, meridian: timeMeridianValue }),
    time_zone: timeZoneValue,
    start_date: monthlyStartDateValue,
    rules: [
      {
        count_limit: convertAmountStringToNumber({ amount: monthlyACHCount }),
        volume_limit: normalizeBackendAmount(monthlyACHVolume),
        enabled: true,
      },
    ],
  }

  const globalACHObject = {
    currency,
    type: GLOBAL,
    max_transaction_amount: normalizeBackendAmount(maxTransactionAmountForACH),
  }

  const globalCardObject = {
    currency,
    type: GLOBAL,
    max_transaction_amount: normalizeBackendAmount(maxTransactionAmountForCards),
  }

  requestBody[detailsPath].card_rules.push(dailyCardObject)
  requestBody[detailsPath].card_rules.push(monthlyCardObject)
  requestBody[detailsPath].card_rules.push(globalCardObject)
  requestBody[detailsPath].ach_rules.push(dailyACHObject)
  requestBody[detailsPath].ach_rules.push(monthlyACHObject)
  requestBody[detailsPath].ach_rules.push(globalACHObject)

  return requestBody
}

const submitSetVelocityLimitsForm = (values, dispatch, props) => {
  const { credentials, applicationId, platformId } = props
  const credentialId = get(credentials, 'id')
  const linkedTo = applicationId || platformId
  const applicationValues = get(props, 'formValues.applicationVelocityLimits.application')
  const recipientValues = get(props, 'formValues.recipientVelocityLimits.recipient')
  const balanceAdjustmentsValues = values

  const rulesRequestBody = merge({},
    constructRulesRequestObject({ entityType: APPLICATION, values: applicationValues }),
    constructRulesRequestObject({ entityType: RECIPIENT, values: recipientValues }),
    constructBalanceAdjustmentsRulesObject({ values: balanceAdjustmentsValues }))

  const valuesToSubmit = merge({}, rulesRequestBody, {
    linked_to: linkedTo,
  })

  dispatch({
    type: POST_DISBURSEMENT_RULES_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: () => {
        const applicationVelocityInfoPath = `${APPLICATION_PATH({ credentialId, applicationId })}?tab=velocity-info`
        dispatch(redirectRequest({ path: applicationVelocityInfoPath }))
      },
      actionId: sendAmplitudeActionEvent(SET_VELOCITY_LIMITS, {
        credentials,
        applicationId,
      }),
    },
  })
}

export default submitSetVelocityLimitsForm
