import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import PayoutsLimitReachedC from 'components/Customer/Pages/SendPayout/PayoutsLimitReached/PayoutsLimitReachedC'
import { PAYOUTS_LIMIT_REACHED } from 'constants/language/languageConstants'

const PayoutsLimitReachedModal = ({
  credentialId,
}) => {
  return (
    <GenericModal
      className='PayoutsLimitReachedModal'
      title={PAYOUTS_LIMIT_REACHED}
      Component={PayoutsLimitReachedC}
      credentialId={credentialId}
    />
  )
}

PayoutsLimitReachedModal.propTypes = {
  credentialId: PropTypes.string,
}

export default PayoutsLimitReachedModal
