import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import submitEditSubscriptionScheduleAmountForm from 'utilities/submit/submitEditSubscriptionScheduleAmountForm'
import validateEditSubscriptionAmountForm from 'utilities/validate/validateEditSubscriptionAmountForm'
import { EDIT_SUBSCRIPTION_AMOUNT } from 'constants/formConstants'

import {
  EDIT,
  EDIT_AMOUNT_CHARGE_ALL_MERCHANTS,
  CANCEL,
} from 'constants/language/languageConstants'

const EditSubscriptionAmountForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  subscriptionAmountsOptions,
}) => {
  return (
    <form className='EditSubscriptionAmountForm' onSubmit={handleSubmit(submitEditSubscriptionScheduleAmountForm)}>
      <div className='section'>
        <Field
          name='subscriptionAmountId'
          label='Subscription Amount'
          component={SelectField}
          options={subscriptionAmountsOptions}
          autofill={false}
        />

        <Field
          name='amountNickname'
          label='Amount Nickname'
          component={InputField}
        />

        <AmountField
          name='amount'
          label='Amount'
        />

        <Field
          name='label'
          label='Label'
          placeholder='Label'
          required={false}
          component={InputField}
        />

        <TagsField
          name='tags'
          label='Tags'
        />

        <WarningMessage warningMessage={EDIT_AMOUNT_CHARGE_ALL_MERCHANTS} />
      </div>

      <div className='buttons flex space-between'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={EDIT} />
      </div>
    </form>
  )
}

EditSubscriptionAmountForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  subscriptionAmountsOptions: PropTypes.array,
}

export default reduxForm({
  form: EDIT_SUBSCRIPTION_AMOUNT,
  validate: validateEditSubscriptionAmountForm,
})(EditSubscriptionAmountForm)
