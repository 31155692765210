import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import getMany from 'utilities/get/getMany'
import { httpCodeStatusMap } from 'constants/statusConstants'
import size from 'lodash/size'

import {
  ATTEMPT_ID,
  CREATED_ON,
  HTTP_STATUS,
  ATTEMPT_NUMBER,
  WEBHOOK_EVENT_ID,
  EVENT_ENTITY,
  WEBHOOK_ID,
  NICKNAME,
  URL,
  EVENT_TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ATTEMPT_ID, 'id', {
    className: 'small',
    formatter: ({ id }) => <ClickToCopyC position='right' label={ATTEMPT_ID} content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(HTTP_STATUS, 'responseStatusCode', {
    className: 'small',
    formatter: ({ responseStatusCode }) => {
      return (
        <ColorcodedStatus
          value={size(responseStatusCode) > 0 ? responseStatusCode.charAt(0) : ''}
          data={httpCodeStatusMap}
          icon='circle'
          customValue={responseStatusCode}
        />
      )
    },
  }),
  column(ATTEMPT_NUMBER, 'attemptCount', { className: 'small attempt-count-header' }),
  column(WEBHOOK_EVENT_ID, 'eventId', {
    className: 'x-small',
    formatter: ({ eventId = '' }) => (
      <ClickToCopyC
        position='right'
        label={`${eventId.substring(0, 2)}...${eventId.slice(-4)}`}
        content={eventId}
      />
    ),
  }),
  column(EVENT_ENTITY, 'entityType', {
    headerFormatter: () => <TableItemSubtitle title={EVENT_ENTITY} subtitle={EVENT_TYPE} />,
    formatter: (webhookLog) => {
      const [
        entityType,
        eventType,
      ] = getMany(webhookLog, [
        'displayEntityType',
        'displayEventType',
      ])

      return <TableItemSubtitle title={entityType} subtitle={eventType} />
    },
  }),
  column(WEBHOOK_ID, 'webhookId', {
    className: 'small',
    formatter: ({ webhookId = '' }) => (
      <ClickToCopyC
        position='left'
        label={`WH...${webhookId.slice(-4)}`}
        content={webhookId}
      />
    ),
  }),
  column(NICKNAME, 'nickname', {
    headerFormatter: () => <TableItemSubtitle title={NICKNAME} subtitle={URL} />,
    formatter: (webhookLog) => {
      const [
        nickname,
        url,
      ] = getMany(webhookLog, [
        'nickname',
        'url',
      ])

      return <TableItemSubtitle title={nickname} subtitle={url} />
    },
  }),
]

export default columnDescriptors
