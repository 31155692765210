import HostedCustomerOnboarding from './HostedCustomerOnboarding'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import getGuestAccessFormRequest from 'utilities/actions/get/getGuestAccessFormRequest'
import getHostedFormTokenVerificationRequest from 'utilities/actions/get/getHostedFormTokenVerificationRequest'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { FETCHING } from 'constants/reducerConstants'
import { AUTH_SESSION } from 'constants/cookieConstants'
import { CREATED_AT_DEFAULT_FORMAT } from 'constants/timeConstants'
import { USA } from 'constants/countryConstants'
import { MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES } from 'constants/identityConstants'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  getAccessFormSelector,
  getHostedFormTokenVerificationSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state) => {
  const isFetching = get(state, `hostedFormTokenDataR.${FETCHING}`)

  // fetch values from url
  const queries = get(state, 'routing.locationBeforeTransitions.query')
  const token = get(queries, 'bearerToken')
  const accessFormId = get(queries, 'formId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const accessFormCountry = get(accessForm, 'country', USA)

  const tokenData = getHostedFormTokenVerificationSelector(state)
  const epochTime = get(tokenData, 'exp')
  const formExpirationTime = moment.unix(epochTime).format(CREATED_AT_DEFAULT_FORMAT)
  const formExpirationString = `The link to this form expires on ${formExpirationTime}`

  const isFormValid = get(tokenData, 'isValid')

  // hide or show the welcome page based on url queries
  const urlQueries = parseUrlQueries()
  const hideWelcomePage = !!get(urlQueries, 'activeStep')

  return {
    isFetching,
    token,
    accessFormId,
    accessForm,
    formExpirationString,
    isFormValid,
    hideWelcomePage,
    accessFormCountry,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestAccessForm: ({ id }) => { dispatch(getGuestAccessFormRequest({ id })) },
    getHostedFormTokenVerification: () => { dispatch(getHostedFormTokenVerificationRequest()) },
  }
}

class HostedCustomerOnboardingC extends Component {
  componentDidMount() {
    const {
      getGuestAccessForm,
      token,
      accessFormId,
      getHostedFormTokenVerification,
    } = this.props

    // clear localStorarge to ensure hosted onboarding hits the correct env
    localStorage.clear()

    // set the bearer token for localStorage for authentication uses
    localStorage.setItem(AUTH_SESSION, JSON.stringify({
      idToken: token,
      tokenType: 'Bearer',
    }))

    // verify bearer token from url
    getHostedFormTokenVerification()

    // Once bearer token has been verified, fetch existing access form data
    getGuestAccessForm({ id: accessFormId })
  }

  componentDidUpdate(prevProps) {
    const { accessForm } = this.props
    const { accessForm: prevAccessForm } = prevProps
    const urlQueries = parseUrlQueries()
    const hasActiveStepQuery = !!get(urlQueries, 'activeStep')

    if (isEmpty(prevAccessForm) && !isEmpty(accessForm)) {
      const businessType = get(accessForm, 'businessData.businessType')
      const isValidBusinessType = includes(MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES, businessType)

      if (!hasActiveStepQuery && isValidBusinessType) {
        updateUrlQueries({ activeStep: 0 })
      }
    }
  }

  render () {
    return (
      <HostedCustomerOnboarding
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HostedCustomerOnboardingC)
