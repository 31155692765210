import dashboardAPI from 'utilities/api/dashboardAPI'
import { UNDERWRITING_CHARTS } from 'constants/apiConstants'

const getUnderwritingChartsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: UNDERWRITING_CHARTS,
    queries,
    meta,
  })
}

export default getUnderwritingChartsAPI
