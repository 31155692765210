import React, { Component } from 'react'
import IDChecks from './IDChecks'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import PlaidCheckColorcodedStatus from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/PlaidCheckColorcodedStatus/PlaidCheckColorcodedStatus'
import get from 'lodash/get'

const personaIdCheckTypes = [
  'id_account_comparison',
  'id_age_comparison',
  'id_age_inconsistency_detection',
  'id_attribute_comparison',
  'id_barcode_detection',
  'id_barcode_inconsistency_detection',
  'id_blur_detection',
  'id_color_inconsistency_detection',
  'id_compromised_detection',
  'id_disallowed_type_detection',
  'id_double_side_detection',
  'id_electronic_replica_detection',
  'id_entity_detection',
  'id_expired_detection',
  'id_extraction_inconsistency_detection',
  'id_fabrication_detection',
  'id_glare_detection',
  'id_handwriting_detection',
  'id_inconsistent_repeat_detection',
  'id_inquiry_comparison',
  'id_mrz_detection',
  'id_mrz_inconsistency_detection',
  'id_number_format_inconsistency_detection',
  'id_paper_detection',
  'id_po_box_detection',
  'id_portrait_clarity_detection',
  'id_portrait_detection',
  'id_public_figure_detection',
  'id_repeat_detection',
  'id_selfie_comparison',
  'id_tamper_detection',
  'id_unprocessable_submission_detection',
  'id_valid_dates_detection',
]

const personaIdCheckTypesToLabelMap = {
  id_account_comparison: 'Account Comparison',
  id_age_comparison: 'Age Comparison',
  id_age_inconsistency_detection: 'Age Inconsistency Detection',
  id_attribute_comparison: 'Attribute Comparison',
  id_barcode_detection: 'Barcode Detection',
  id_barcode_inconsistency_detection: 'Barcode Inconsistency Detection',
  id_blur_detection: 'Blur Detection',
  id_color_inconsistency_detection: 'Color Inconsistency Detection',
  id_compromised_detection: 'Compromised ID Detection',
  id_disallowed_type_detection: 'Disallowed ID Type Detection',
  id_double_side_detection: 'Double-Sided Detection',
  id_electronic_replica_detection: 'Electronic Replica Detection',
  id_entity_detection: 'ID Entity Detection',
  id_expired_detection: 'Expired ID Detection',
  id_extraction_inconsistency_detection: 'Extraction Inconsistency Detection',
  id_fabrication_detection: 'Fabrication Detection',
  id_glare_detection: 'Glare Detection',
  id_handwriting_detection: 'Handwriting Detection',
  id_inconsistent_repeat_detection: 'Inconsistent Repeat Detection',
  id_inquiry_comparison: 'Inquiry Comparison',
  id_mrz_detection: 'Machine-readable Text Detection',
  id_mrz_inconsistency_detection: 'Inconsistent Machine-readable Text',
  id_number_format_inconsistency_detection: 'Inconsistent ID Number Format',
  id_paper_detection: 'ID Paper Detection',
  id_po_box_detection: 'ID PO Box Detection',
  id_portrait_clarity_detection: 'Portrait Clarity Detection',
  id_portrait_detection: 'Portrait Detection',
  id_public_figure_detection: 'Public Figure Detection',
  id_repeat_detection: 'Repeat Detection',
  id_selfie_comparison: 'Selfie Comparison',
  id_tamper_detection: 'Tamper Detection',
  id_unprocessable_submission_detection: 'Unprocessable Submission Detection',
  id_valid_dates_detection: 'Valid Date Detection',
}

class IDChecksC extends Component {
  render() {
    const report = get(this.props, 'report', {})

    const idChecksData = convertPageSectionDataToV2(
      personaIdCheckTypes.map((checkType) => {
        return {
          label: personaIdCheckTypesToLabelMap[checkType],
          value: <PlaidCheckColorcodedStatus
            value={get(report, `details.checks.${checkType}.result`, '')}
            description={get(report, `details.checks.${checkType}.description`, '')}
          />,
        }
      }),
    )

    return (
      <IDChecks
        {...this.props}
        idChecksData={idChecksData}
      />
    )
  }
}

export default IDChecksC
