import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettlementPreviewPanel from './SettlementPreviewPanel'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import getSettlementGroupRequest from 'utilities/actions/get/getSettlementGroupRequest'
import getSettlementGroupSettlementsRequest from 'utilities/actions/get/getSettlementGroupSettlementsRequest'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import hidePreviewPanelAction from 'utilities/actions/hidePreviewPanelAction'
import formatMoney from 'utilities/formatters/formatMoney'
import showModalAction from 'utilities/actions/showModalAction'
import { CLOSE_SETTLEMENT } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  getSettlementSelector,
  getApplicationSelector,
  getMerchantSelector,
  getIdentitySelector,
} from 'state-layer/selectors'

import {
  ACCRUING,
  APPROVED,
  STOP_ACCRUING,
} from 'constants/settlementConstants'

import {
  t,
  APPLICATION_RESOURCE_TITLE,
  AUTO_CLOSE,
  CREATED_ON,
  ENTRY_ACCRUAL_END,
  ENTRY_ACCRUAL_START,
  EXCEPTION_SETTLEMENT,
  FEE_DELAY_LABEL,
  FEE_SETTLEMENT_SCHEDULE,
  FEES_AMOUNT,
  FUNDING_TRANSFER_DELAY_LABEL,
  FUNDING_TRANSFER_SPEED_LABEL,
  MERCHANT_IDENTITY,
  NET_AMOUNT,
  PAYOUT_SETTLEMENT_SCHEDULE,
  PAYOUT_TYPE,
  PROCESSOR,
  SETTLEMENT,
  STATUS,
  TRANSACTION_DEBITS,
  TRANSACTION_CREDITS,
  FEES,
  REVERSALS,
  DISPUTE_DEBITS,
  DISPUTE_CREDITS,
  MERCHANT_ADJUSTMENT_DEBITS,
  MERCHANT_ADJUSTMENT_CREDITS,
} from 'constants/language/languageConstants'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
} from 'constants/pathConstants'

import {
  GROSS,
  CUSTOM,
} from 'constants/payoutSettingsConstants'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `settlementsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const settlementId = get(props, 'resourceId')
  const settlement = getSettlementSelector(state, settlementId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  const [
    id,
    applicationId,
    merchantId,
    displayTotalAmount,
    exception,
    currency,
    status,
    displayCreatedAt,
    displayNetAmount,
    totalFees,
    displayWindowStart,
    displayWindowEnd,
    displayAutoCloseTime,
    processor,
    payoutProfile,
    settlementGroupId,
    displayTransferDebitCount,
    displayTransferDebitAmount,
    displayTransferCreditCount,
    displayTransferCreditAmount,
    displayTransferFeesCount,
    displayTransferFeesAmount,
    displayTransferRefundsCount,
    displayTransferRefundsAmount,
    displayDisputeDebitCount,
    displayDisputeDebitAmount,
    displayDisputeCreditCount,
    displayDisputeCreditAmount,
    displayAdjustmentDebitCount,
    displayAdjustmentDebitAmount,
    displayAdjustmentCreditCount,
    displayAdjustmentCreditAmount,
    tags,
  ] = getMany(settlement, [
    'id',
    'applicationId',
    'merchantId',
    'displayTotalAmount',
    'exception',
    'currency',
    'status',
    'displayCreatedAt',
    'displayNetAmount',
    'totalFees',
    'displayWindowStart',
    'displayWindowEnd',
    'displayAutoCloseTime',
    'processor',
    'payoutProfile',
    'settlementGroupId',
    'displayTransferDebitCount',
    'displayTransferDebitAmount',
    'displayTransferCreditCount',
    'displayTransferCreditAmount',
    'displayTransferFeesCount',
    'displayTransferFeesAmount',
    'displayTransferRefundsCount',
    'displayTransferRefundsAmount',
    'displayDisputeDebitCount',
    'displayDisputeDebitAmount',
    'displayDisputeCreditCount',
    'displayDisputeCreditAmount',
    'displayAdjustmentDebitCount',
    'displayAdjustmentDebitAmount',
    'displayAdjustmentCreditCount',
    'displayAdjustmentCreditAmount',
    'tags',
  ])

  const application = getApplicationSelector(state, applicationId)
  const businessName = get(application, 'businessName')
  const merchant = getMerchantSelector(state, merchantId)
  const identityId = get(merchant, 'identityId')
  const identity = getIdentitySelector(state, identityId)

  const [
    merchantBusinessName,
    merchantDoingBusinessAs,
  ] = getMany(identity, [
    'businessName',
    'doingBusinessAs',
  ])

  const [
    payoutProfileType,
    payoutProfileDisplayType,
    displayFeeFrequency,
    displayPayoutFrequency,
    feeSubmissionDelayDays,
    payoutSubmissionDelayDays,
    displayPayoutRail,
  ] = getMany(payoutProfile, [
    'type',
    'displayType',
    'displayFeeFrequency',
    'displayPayoutFrequency',
    'feeSubmissionDelayDays',
    'payoutSubmissionDelayDays',
    'displayPayoutRail',
  ])

  const isFetchingDetails = isEmpty(settlement)
  const isFetchingPayoutProfile = isEmpty(get(settlement, 'payoutProfile'))

  const panelContextBarData = [
    {
      label: APPLICATION_RESOURCE_TITLE,
      value: businessName,
      path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      condition: !isStandaloneMerchant,
    },
    {
      label: MERCHANT_IDENTITY,
      value: formatBusinessNameString({ businessName: merchantBusinessName, doingBusinessAs: merchantDoingBusinessAs }),
      path: hasPartnerAccess ? IDENTITY_PATH({ credentialId, identityId }) : null,
      condition: !isStandaloneMerchant,
    },
  ]

  const headerResourceTitle = exception ? EXCEPTION_SETTLEMENT : SETTLEMENT

  const headerData = {
    resource: {
      label: headerResourceTitle,
      id: settlementId,
      clickToCopyPosition: 'default',
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayTotalAmount} currency={currency} />,
      },
    ],
  }

  const settlementDetailsData = convertPageSectionDataToV2([
    {
      label: STATUS,
      value: <SettlementStatus value={status} />,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: FEES_AMOUNT,
      value: `(${formatMoney({ amount: totalFees, currency, showCurrencyCode: true })})`,
    },
    {
      label: NET_AMOUNT,
      value: displayNetAmount,
    },
  ], 1)


  const detailsSectionData = convertPageSectionDataToV2([
    {
      label: ENTRY_ACCRUAL_START,
      value: displayWindowStart,
    },
    {
      label: ENTRY_ACCRUAL_END,
      value: displayWindowEnd,
    },
    {
      label: AUTO_CLOSE,
      value: displayAutoCloseTime,
    },
    {
      label: PROCESSOR,
      value: processor,
    },
  ], 1)

  const merchantPayoutProfileSectionData = convertPageSectionDataToV2(payoutProfileType === CUSTOM ? [
    {
      label: PAYOUT_TYPE,
      value: payoutProfileDisplayType,
    },
  ] : [
    {
      label: PAYOUT_TYPE,
      value: payoutProfileDisplayType,
    },
    {
      label: FEE_SETTLEMENT_SCHEDULE,
      value: displayFeeFrequency,
      condition: payoutProfileType === GROSS,
    },
    {
      label: PAYOUT_SETTLEMENT_SCHEDULE,
      value: displayPayoutFrequency,
    },
    {
      label: FEE_DELAY_LABEL,
      value: feeSubmissionDelayDays,
      condition: payoutProfileType === GROSS,
    },
    {
      label: FUNDING_TRANSFER_SPEED_LABEL,
      value: displayPayoutRail,
    },
    {
      label: FUNDING_TRANSFER_DELAY_LABEL,
      value: payoutSubmissionDelayDays,
      condition: payoutProfileType === GROSS,
    },
  ], 1)

  const overviewTableRows = [
    [TRANSACTION_DEBITS, displayTransferDebitCount, displayTransferDebitAmount],
    [TRANSACTION_CREDITS, displayTransferCreditCount, displayTransferCreditAmount],
    [FEES, displayTransferFeesCount, displayTransferFeesAmount],
    [REVERSALS, displayTransferRefundsCount, displayTransferRefundsAmount],
    [DISPUTE_DEBITS, displayDisputeDebitCount, displayDisputeDebitAmount],
    [DISPUTE_CREDITS, displayDisputeCreditCount, displayDisputeCreditAmount],
    [MERCHANT_ADJUSTMENT_DEBITS, displayAdjustmentDebitCount, displayAdjustmentDebitAmount],
    [MERCHANT_ADJUSTMENT_CREDITS, displayAdjustmentCreditCount, displayAdjustmentCreditAmount],
    [<span className='p-2-bold'>{t(NET_AMOUNT)}</span>, '-', <span className='p-2-bold'>{displayNetAmount}</span>],
  ]

  const isApprovedSettlement = status === APPROVED
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  const isRolePlatformCreds = isRolePlatform({ credentials })
  const isAccruing = status === 'ACCRUING'
  const showAction = isAccruing && isRolePlatformCreds
  const entries = `${SETTLEMENT_PATH({ credentialId, settlementId: id })}?tab=entries`

  return {
    credentials,
    isFetching,
    settlementId,
    settlement,
    panelContextBarData,
    headerData,
    settlementDetailsData,
    detailsSectionData,
    merchantPayoutProfileSectionData,
    isFetchingDetails,
    isFetchingPayoutProfile,
    settlementGroupId,
    overviewTableRows,
    currency,
    tagsSectionData,
    isApprovedSettlement,
    entries,
    showAction,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSettlement: ({ settlementId, credentials }) => dispatch(getSettlementRequest({ settlementId, credentials })),
    getSettlementGroup: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupRequest({ credentials, settlementGroupId })) },
    getSettlementGroupSettlements: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupSettlementsRequest({ credentials, settlementGroupId })) },
    showCloseSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: CLOSE_SETTLEMENT, modalProps })) && dispatch(dispatch(hidePreviewPanelAction())),
  }
}

class SettlementPreviewPanelC extends Component {
  state = {
    timeout: null,
    fetchedSettlementGroup: false,
  }

  componentDidMount() {
    const {
      credentials,
      settlementId,
      getSettlement,
    } = this.props

    if (settlementId) {
      getSettlement({ settlementId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const { settlementId: prevSettlementId } = prevProps

    const {
      credentials,
      settlementId,
      getSettlement,
      settlementGroupId,
    } = this.props

    const { fetchedSettlementGroup } = this.state

    if (prevSettlementId !== settlementId) {
      getSettlement({ settlementId, credentials })
    }

    if (hasPartnerAccess({ credentials }) && settlementGroupId && (!fetchedSettlementGroup || prevSettlementId !== settlementId)) {
      this.fetchSettlementGroup()
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state
    this.setState({ fetchedSettlementGroup: false })

    if (timeout) {
      clearTimeout(timeout)
    }
  }

  fetchSettlementGroup = () => {
    this.setState({ fetchedSettlementGroup: true })

    const {
      settlementGroupId,
      credentials,
      getSettlementGroup,
      getSettlementGroupSettlements,
    } = this.props

    const timeout = setTimeout(() => {
      getSettlementGroup({ credentials, settlementGroupId })
      getSettlementGroupSettlements({ credentials, settlementGroupId })
    }, 250)

    this.setState({ timeout })
  }

  render() {
    const {
      settlementId,
      credentials,
      showCloseSettlementModal,
      showAction,
    } = this.props

    const actions = showAction ? [
      {
        label: STOP_ACCRUING,
        action: () => {
          showCloseSettlementModal({ settlementId, credentials })
        },
      },
    ] : []

    return (
      <SettlementPreviewPanel
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementPreviewPanelC)
