import './SetVelocityLimitsFormS.scss'
import React from 'react'
import { goBack } from 'state-layer/history'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Loader from 'components/Shared/Loader/Loader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import prevFlowStep from 'utilities/prevFlowStep'
import validateSetLimitsForm from 'utilities/validate/validateSetLimitsForm'
import formatNumberFormatField from 'utilities/forms/format/formatNumberFormatField'
import { CREATE_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'

import {
  timeZoneOptions,
  startTimeOptions,
  monthStartDayOptions,
  meridianOptions,
} from 'constants/timeConstants'

import {
  CANCEL,
  TIME_ZONE,
  START_TIME,
  WINDOW_START_TIME,
  WINDOW_START_TIME_DESCRIPTION,
  DAILY_LIMITS,
  DAILY_CARD_COUNT,
  DAILY_CARD_VOLUME,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_LIMITS,
  MONTHLY_START_DATE,
  MONTHLY_CARD_COUNT,
  MONTHLY_CARD_VOLUME,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  PLEASE_COMPLETE_ALL_FIELDS,
  PREVIOUS_STEP,
  TRANSACTION_DETAILS,
  MAX_TRANSACTION_AMOUNT_FOR_ACH,
  MAX_TRANSACTION_AMOUNT_FOR_CARDS,
  NEXT_STEP,
} from 'constants/language/languageConstants'

const SetVelocityLimitsForm = ({
  title = '',
  subtitle,
  handleSubmit = () => {},
  isFetchingApplication = false,
  entityType,
  firstStep = false,
  isFormInvalid = false,
  isSetting = false,
  invalid = false,
}) => {
  return (
    <form className='SetVelocityLimitsForm' onSubmit={handleSubmit}>
      <div className='form-header'>
        <h3>{title}</h3>

        {subtitle && !isFetchingApplication && <div className='p-1 subtitle'>{subtitle}</div>}
        {isFetchingApplication && <Loader />}
      </div>

      <div className='form-content'>
        <RequiredFieldNotice />
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_ALL_FIELDS} />}

        <h5>{TRANSACTION_DETAILS}</h5>

        <div className='max-transaction-amount-section flex space-between'>
          <AmountField
            name={`${entityType}.maxTransactionAmountForCards`}
            className='max-transaction-amount-for-cards-field'
            label={MAX_TRANSACTION_AMOUNT_FOR_CARDS}
            contextLabel={USD}
          />

          <AmountField
            name={`${entityType}.maxTransactionAmountForACH`}
            className='max-transaction-amount-for-ach-field'
            label={MAX_TRANSACTION_AMOUNT_FOR_ACH}
            contextLabel={USD}
          />
        </div>

        <h5>{WINDOW_START_TIME}</h5>
        <div className='description label-2'>{WINDOW_START_TIME_DESCRIPTION}</div>

        <div className='time-section flex'>
          <Field
            name={`${entityType}.startTime`}
            label={START_TIME}
            component={ReactSelect}
            options={startTimeOptions}
            classNames={['start-time']}
          />

          <Field
            name={`${entityType}.timeMeridian`}
            classNames={['time-meridian']}
            label='hidden label'
            hiddenLabel
            component={ReactSelect}
            options={meridianOptions}
            required={false}
          />

          <Field
            name={`${entityType}.timeZone`}
            label={TIME_ZONE}
            classNames={['time-zone']}
            component={ReactSelect}
            options={timeZoneOptions}
            isDisabled
          />
        </div>

        <h5>{DAILY_LIMITS}</h5>

        <div className='daily-card-section flex space-between'>
          <Field
            name={`${entityType}.dailyCardCount`}
            className='daily-card-count'
            label={DAILY_CARD_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name={`${entityType}.dailyCardVolume`}
            className='daily-card-volume'
            label={DAILY_CARD_VOLUME}
            component={InputField}
          />
        </div>

        <div className='daily-ach-section flex space-between'>
          <Field
            name={`${entityType}.dailyACHCount`}
            className='daily-ach-count'
            label={DAILY_ACH_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name={`${entityType}.dailyACHVolume`}
            className='daily-ach-volume'
            label={DAILY_ACH_VOLUME}
            component={InputField}
          />
        </div>

        <h5>{MONTHLY_LIMITS}</h5>

        <Field
          name={`${entityType}.monthlyStartDate`}
          label={MONTHLY_START_DATE}
          component={ReactSelect}
          options={monthStartDayOptions}
        />

        <div className='monthly-card-section flex space-between'>
          <Field
            name={`${entityType}.monthlyCardCount`}
            label={MONTHLY_CARD_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name={`${entityType}.monthlyCardVolume`}
            label={MONTHLY_CARD_VOLUME}
            component={InputField}
          />
        </div>

        <div className='monthly-ach-section flex space-between'>
          <Field
            name={`${entityType}.monthlyACHCount`}
            label={MONTHLY_ACH_COUNT}
            format={formatNumberFormatField}
            component={InputField}
            placeholder='0'
          />

          <AmountField
            name={`${entityType}.monthlyACHVolume`}
            label={MONTHLY_ACH_VOLUME}
            component={InputField}
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          {!firstStep && (
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          )}

          <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' />
          <Button className='submit-button' type='submit' submitting={isSetting} disabled={invalid} label={NEXT_STEP} />
        </div>
      </div>
    </form>
  )
}

SetVelocityLimitsForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  isFetchingApplication: PropTypes.bool,
  entityType: PropTypes.string,
  firstStep: PropTypes.bool,
  isFormInvalid: PropTypes.bool,
  isSetting: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_VELOCITY_LIMITS_FORM,
  validate: validateSetLimitsForm,
  enableReinitialize: true,
})(SetVelocityLimitsForm)
