import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import { SPLIT_TRANSFER } from 'constants/flowConstants'
import last from 'lodash/last'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  ENTRY_NOT_IN_SETTLEMENT,
  ENTRY_EXTERNALLY_FUNDED,
} from 'constants/settlementConstants'

import {
  ID,
  TRACE_ID,
  AMOUNT,
  TRANSACTION_TYPE,
  FEE_TYPE,
  BUYER_NAME,
  CREATED_ON,
  SPLIT_TRANSACTION,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column('', '', {
    className: 'xx-small',
    condition: ({ shouldFund, settlementId }) => {
      const currentSettlementId = last(window.location.pathname.split('/'))
      const entryIsNotInSettlement = currentSettlementId !== settlementId
      const entryIsExternallyFunded = !shouldFund

      return !(entryIsNotInSettlement || entryIsExternallyFunded)
    },
    formatter: ({ shouldFund, settlementId }) => {
      const currentSettlementId = last(window.location.pathname.split('/'))
      const entryIsNotInSettlement = currentSettlementId !== settlementId
      const entryIsExternallyFunded = !shouldFund

      let tooltipMessage
      if (entryIsNotInSettlement) {
        tooltipMessage = ENTRY_NOT_IN_SETTLEMENT
      } else if (entryIsExternallyFunded) {
        tooltipMessage = ENTRY_EXTERNALLY_FUNDED
      }

      return entryIsNotInSettlement || entryIsExternallyFunded ? (
        <li className='table-item-tooltip'>
          <span className='fa fa-exclamation-circle' />
          <span className='table-item-tooltip-text'>{tooltipMessage}</span>
        </li>
      ) : ''
    },
  }),
  column(ID, 'resourceId', {
    className: 'copy-click-id',
    formatter: ({ resourceId, entryId, entryType }) => {
      const id = isEqual(entryType, SPLIT_TRANSFER) ? entryId : resourceId
      return <ClickToCopyC position='right' content={id} />
    },
  }),
  column(TRACE_ID, 'transfer.traceId', {
    className: 'x-small',
    formatter: ({ transfer }) => {
      const traceId = get(transfer, 'traceId')

      return <ClickToCopyC label='Trace ID' position='right' content={traceId} />
    },
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    formatter: ({ transfer, fee, createdAt }) => {
      const transferCreated = get(transfer, 'createdAt')
      const feeCreated = get(fee, 'createdAt')
      const date = transferCreated || feeCreated || createdAt

      return <DateTimestampColumn timestamp={date} />
    },
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column(TRANSACTION_TYPE, 'transfer.displayType', {
    className: 'normal',
    formatter: ({ transfer, entryType, displayEntrySubType }) => {
      const isSplitTransferType = isEqual(entryType, SPLIT_TRANSFER)

      return (
        <TableItemSubtitle
          title={isSplitTransferType ? displayEntrySubType : get(transfer, 'displayType')}
          subtitle={isSplitTransferType ? SPLIT_TRANSACTION : ' '}
        />
      )
    },
  }),
  column(FEE_TYPE, 'fee.displayFeeType', { className: 'normal' }),
  column(BUYER_NAME, 'paymentInstrument.name', { className: 'small' }),
]

export default columnDescriptors
