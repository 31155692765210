import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'

import {
  DASHBOARD_SERVICE_USERS_EMAILS,
  DASHBOARD_SERVICE_USERS_EMAILS_V2,
} from 'constants/apiConstants'

const mock = {
  created_at: '2021-04-26T19:24:40.49Z',
}

const postUserEmailAPI = ({ values, id, meta }) => {
  const dashboardId = getCurrentDashboardId()
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_USERS_EMAILS_V2({ dashboardId }) : DASHBOARD_SERVICE_USERS_EMAILS(id)

  return dashboardAPI.post({
    meta,
    path,
    values,
    mock,
  })
}

export default postUserEmailAPI
