import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import generateGenericReducers from 'utilities/reducers/generateGenericReducers'
import generateGenericConnectors from 'utilities/reducers/generateGenericConnectors'
import sortByKey from 'utilities/sort/sortByKey'
import get from 'lodash/get'

import {
  LOGIN_FORM, // TODO: get rid of login form, as it is not used anymore after auth0
  IDENTITY_FORM,
  TABLE_HEADERS_FORM,
} from 'constants/formConstants'

// custom reducers
import linksR from 'state-layer/reducers/linksR'
import modalR from 'state-layer/reducers/modalR'
import selectedItemsR from 'state-layer/reducers/selectedItemsR'
import flashNotificationsR from 'state-layer/reducers/flashNotificationsR'
import tableFiltersR from 'state-layer/reducers/tableFiltersR'
import flowsR from 'state-layer/reducers/flowsR'
import errorsR from 'state-layer/reducers/errorsR'
import currentUserR from 'state-layer/reducers/currentUserR'
import patchingR from 'state-layer/reducers/patchingR'
import highlightR from 'state-layer/reducers/highlightR'
import actionStatusR from 'state-layer/reducers/actionStatusR'
import tableR from 'state-layer/reducers/tableR'
import orchestrationStatusR from 'state-layer/reducers/orchestrationStatusR'
import chartsR from 'state-layer/reducers/chartsR'
import featureFlagsR from 'state-layer/reducers/featureFlagsR'
import toastNotificationsR from 'state-layer/reducers/toastNotificationsR'
import unmaskedItemsR from 'state-layer/reducers/unmaskedItemsR'
import tabsR from 'state-layer/reducers/tabsR'
import panelR from 'state-layer/reducers/panelR'
import languageR from 'state-layer/reducers/languageR'
import mobileNavR from 'state-layer/reducers/mobileNavR'

// form reducers
import identityFormR from 'state-layer/reducers/forms/identityFormR'
import tableHeadersFormR from 'state-layer/reducers/forms/tableHeadersFormR'

const reducers = {
  chartsR,
  actionStatusR,
  currentUserR,
  errorsR,
  featureFlagsR,
  flashNotificationsR,
  flowsR,
  form: formReducer.plugin({
    [IDENTITY_FORM]: identityFormR,
    [TABLE_HEADERS_FORM]: tableHeadersFormR,
  }),
  highlightR,
  languageR,
  linksR,
  mobileNavR,
  modalR,
  orchestrationStatusR,
  patchingR,
  selectedItemsR,
  tableR,
  tableFiltersR,
  tabsR,
  toastNotificationsR,
  unmaskedItemsR,
  panelR,
  routing: routerReducer,
  ...generateGenericReducers,
  ...generateGenericConnectors,
}

const sortedReducers = sortByKey(reducers)
const combinedReducers = combineReducers(sortedReducers)

const rootReducer = (state, action) => {
  const type = get(action, 'type')

  // TODO: see if we ever need to only return certain reducers, rather than all
  switch (type) {
    default:
      return combinedReducers(state, action)
  }
}

export default rootReducer
