import get from 'lodash/get'

import {
  POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS,
  POST_MERCHANT_VERIFICATIONS_F_SUCCESS,
} from 'constants/flowConstants'

export const GET = 'GET'
export const POST = 'POST'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'

const ACTION_TYPE = {
  [POST_IDENTITY_PAYMENT_INSTRUMENTS_F_SUCCESS]: POST,
  [POST_MERCHANT_VERIFICATIONS_F_SUCCESS]: POST,
}

export const getActionType = (type) => get(ACTION_TYPE, type) || GET
