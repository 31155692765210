import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { createdAtRange } from 'utilities/table/sort'
import { subscriptionStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'

import {
  AMOUNT,
  BUYER,
  BRAND_SLASH_TYPE,
  CREATED_ON,
  FIRST_PAYMENT_DATE,
  ID,
  MASKED_NUMBER,
  MERCHANT_ACCOUNT,
  PAYMENT_INSTRUMENT,
  INSTRUMENT_TYPE,
  PLAN_NAME,
  STATUS,
  SUBSCRIPTION_NAME,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(BUYER, 'identity.fullName', {
    className: 'normal',
    headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={MERCHANT_ACCOUNT} />,
    formatter: ({ identity, merchant }) => {
      const fullName = get(identity, 'fullName', '')
      const merchantName = get(merchant, 'name', '')

      return <TableItemSubtitle title={fullName} subtitle={merchantName} />
    },
  }),
  column(SUBSCRIPTION_NAME, 'nickname', {
    className: 'normal',
  }),
  column(PLAN_NAME, 'subscriptionPlan.planName', {
    className: 'normal',
  }),
  column(FIRST_PAYMENT_DATE, 'firstChargeAt', {
    className: 'normal',
    headerFormatter: () => <DateTimestampHeader title={FIRST_PAYMENT_DATE} />,
    formatter: ({ firstChargeAt }) => <DateTimestampColumn timestamp={firstChargeAt} />,
  }),
  column(AMOUNT, ['amount', 'currency'], {
    className: 'amount divider',
    formatter: ({ amount, currency }) => <AmountCurrencyDisplayTable displayAmount={amount} currency={currency} />,
  }),
  column(STATUS, 'state', {
    className: 'small',
    formatter: ({ state }) => <ColorcodedStatus data={subscriptionStatusMap} value={state} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return <TableItemSubtitle title={name} subtitle={maskedAccountNumber || maskedFullCardNumber} />
    },
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
]

export default columnDescriptors
