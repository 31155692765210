import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_API_LOGS } from 'constants/apiConstants'

const getAPILogsAPI = ({ meta, queries }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_API_LOGS,
    queries,
    meta,
  })
}

export default getAPILogsAPI
