import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import getFileAPI from 'api/dashboard/get/getFileAPI'
import getAccessFormFileAPI from 'api/dashboard/get/getAccessFormFileAPI'
import postFileExternalLinks from 'api/dashboard/post/postFileExternalLinksAPI'
import postAccessFormFileExternalLinksAPI from 'api/dashboard/post/postAccessFormFileExternalLinksAPI'
import frontendFileModel from 'utilities/create/models/frontendFileModel'
import co from 'co'
import get from 'lodash/get'

function * getFileO ({
  id,
  meta,
  credentials,
}) {
  const [
    { data: fileResponse },
    { data: externalLinksResponse },
  ] = yield [
    getFileAPI({ id, credentials, meta }),
    postFileExternalLinks({
      id,
      values: {
        type: 'VIEW',
        duration: '3000',
      },
      meta: {
        contentType: 'application/vnd.api+json',
      },
      credentials,
    }),
  ]

  const fileModel = frontendFileModel({
    data: {
      externalLink: externalLinksResponse,
      ...fileResponse,
    },
  })

  return createSuccessResponse({
    data: [fileModel],
  })
}

export default co.wrap(getFileO)
