import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettlementInfo from './SettlementInfo'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { APPROVED } from 'constants/settlementConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'

import {
  getSettlementSelector,
} from 'state-layer/selectors'

import {
  AUTO_CLOSE,
  ENTRY_ACCRUAL_END,
  ENTRY_ACCRUAL_START,
  FEE_DELAY_LABEL,
  FEE_SETTLEMENT_SCHEDULE,
  FUNDING_TRANSFER_DELAY_LABEL,
  FUNDING_TRANSFER_SPEED_LABEL,
  PAYOUT_SETTLEMENT_SCHEDULE,
  PAYOUT_TYPE,
  PROCESSOR,
  STANDALONE_SM_FUNDING_TRANSFERS_TOOLTIP,
  STANDALONE_SP_FUNDING_TRANSFERS_TOOLTIP,
  FLEX_SP_FUNDING_TRANSFERS_TOOLTIP,
  FLEX_SM_FUNDING_TRANSFERS_TOOLTIP,
  PLATFORM_SP_FUNDING_TRANSFERS_TOOLTIP,
  PLATFORM_SM_FUNDING_TRANSFERS_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  CUSTOM,
  GROSS,
} from 'constants/payoutSettingsConstants'

const generateFundingTransferTooltipMessage = (msg1, msg2) => (
  <div className='funding-transfers-tooltip'>
    <div className='msg-part-one'>{msg1}</div>
    <div className='msg-part-two'>{msg2}</div>
  </div>
)

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `settlementsR.${FETCHING}`)
  const settlementId = get(props, 'settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isFlex = isFlexPlatform()
  let fundingTransfersTooltipMessage = {}

  if (isFlex && isStandaloneMerchant) {
    fundingTransfersTooltipMessage = generateFundingTransferTooltipMessage(STANDALONE_SM_FUNDING_TRANSFERS_TOOLTIP, STANDALONE_SP_FUNDING_TRANSFERS_TOOLTIP)
  } else if (!isStandaloneMerchant && isFlex) {
    fundingTransfersTooltipMessage = generateFundingTransferTooltipMessage(FLEX_SM_FUNDING_TRANSFERS_TOOLTIP, FLEX_SP_FUNDING_TRANSFERS_TOOLTIP)
  } else if (isRolePlatform({ credentials })) {
    fundingTransfersTooltipMessage = generateFundingTransferTooltipMessage(PLATFORM_SM_FUNDING_TRANSFERS_TOOLTIP, PLATFORM_SP_FUNDING_TRANSFERS_TOOLTIP)
  }

  const {
    tags,
    processor,
    paymentInstrument,
    status,
    displayWindowStart,
    displayWindowEnd,
    displayAutoCloseTime,
    merchantIdentityId,
    payoutProfile,
    displayTransferDebitCount,
    displayTransferDebitAmount,
    displayTransferCreditCount,
    displayTransferCreditAmount,
    displayTransferFeesCount,
    displayTransferFeesAmount,
    displayTransferSettledExternallyCount,
    displayTransferSettledExternallyAmount,
    displayTransferRefundsCount,
    displayTransferRefundsAmount,
    displayDisputeDebitCount,
    displayDisputeDebitAmount,
    displayDisputeCreditCount,
    displayDisputeCreditAmount,
    displayAdjustmentDebitCount,
    displayAdjustmentDebitAmount,
    displayAdjustmentCreditCount,
    displayAdjustmentCreditAmount,
    displayNetAmount,
    currency,
    paymentInstruments,
  } = settlement

  const isApprovedSettlement = status === APPROVED

  const bankAccountPaymentInstruments = filter(paymentInstruments, (instrument) => isEqual(get(instrument, 'instrument_type'), 'BANK_ACCOUNT'))

  const [
    payoutProfileType,
    payoutProfileDisplayType,
    displayFeeFrequency,
    displayPayoutFrequency,
    displayRail,
    feeSubmissionDelayDays,
    payoutSubmissionDelayDays,
  ] = getMany(payoutProfile, [
    'type',
    'displayType',
    'displayFeeFrequency',
    'displayPayoutFrequency',
    'displayRail',
    'feeSubmissionDelayDays',
    'payoutSubmissionDelayDays',
  ])

  const detailsSectionData = [
    {
      type: 'data',
      data: [
        {
          label: ENTRY_ACCRUAL_START,
          value: displayWindowStart,
        },
        {
          label: ENTRY_ACCRUAL_END,
          value: displayWindowEnd,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: AUTO_CLOSE,
          value: displayAutoCloseTime,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: PROCESSOR,
          value: processor,
        },
      ],
    },
  ]

  const merchantPayoutProfileSectionData = payoutProfileType === CUSTOM ? [
    {
      type: 'data',
      data: [
        {
          label: PAYOUT_TYPE,
          value: payoutProfileDisplayType,
        },
      ],
    },
  ] : [
    {
      type: 'data',
      data: [
        {
          label: PAYOUT_TYPE,
          value: payoutProfileDisplayType,
        },
        {
          label: FEE_SETTLEMENT_SCHEDULE,
          value: displayFeeFrequency,
          condition: payoutProfileType === GROSS,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: PAYOUT_SETTLEMENT_SCHEDULE,
          value: displayPayoutFrequency,
        },
        {
          label: FEE_DELAY_LABEL,
          value: feeSubmissionDelayDays,
          condition: payoutProfileType === GROSS,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: FUNDING_TRANSFER_SPEED_LABEL,
          value: displayRail,
        },
        {
          label: FUNDING_TRANSFER_DELAY_LABEL,
          value: payoutSubmissionDelayDays,
          condition: payoutProfileType === GROSS,
        },
      ],
    },
  ]

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  return {
    isFetching,
    settlementId,
    settlement,
    credentials,
    detailsSectionData,
    merchantPayoutProfileSectionData,
    paymentInstrument,
    tagsSectionData,
    isApprovedSettlement,
    displayTransferDebitCount,
    displayTransferDebitAmount,
    displayTransferCreditCount,
    displayTransferCreditAmount,
    displayTransferFeesCount,
    displayTransferFeesAmount,
    displayTransferSettledExternallyCount,
    displayTransferSettledExternallyAmount,
    displayTransferRefundsCount,
    displayTransferRefundsAmount,
    displayDisputeDebitCount,
    displayDisputeDebitAmount,
    displayDisputeCreditCount,
    displayDisputeCreditAmount,
    displayAdjustmentDebitCount,
    displayAdjustmentDebitAmount,
    displayAdjustmentCreditCount,
    displayAdjustmentCreditAmount,
    displayNetAmount,
    fundingTransfersTooltipMessage,
    currency,
    bankAccountPaymentInstruments,
  }
}

const mapDispatchToProps = {
  getSettlement: getSettlementRequest,
}

class SettlementInfoC extends Component {
  // TODO see if we need this, it is causing a 2nd call request due to the parent container also calling it
  // however, removing it here means that the component by itself will not fetch the data if used outside of the context of its parent wrapper (which is not great for re-use)
  // componentDidMount() {
  //   const {
  //     credentials,
  //     settlementId,
  //     getSettlement,
  //   } = this.props
  //
  //   getSettlement({ settlementId, credentials })
  // }

  render() {
    return (
      <SettlementInfo {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementInfoC)
