import { GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSubscriptionScheduleAmountsRequest = ({
  subscriptionScheduleId,
  credentials,
  meta,
}) => createAction({
  type: GET_SUBSCRIPTION_SCHEDULE_AMOUNTS_F_REQUEST,
  id: subscriptionScheduleId,
  credentials,
  meta,
})

export default getSubscriptionScheduleAmountsRequest
