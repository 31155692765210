import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SETTLEMENT,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getSettlementAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: SETTLEMENT({ settlementId: id }),
    credentials,
    service: SETTLEMENTS,
  })
}

export default getSettlementAPI
