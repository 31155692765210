import get from 'lodash/get'
import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_REQUEST } from 'constants/flowConstants'
import { SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_BEFORE_VERIFICATION } from 'constants/amplitudeConstants'

const postSelfServiceResendEmailWithCaptchaRequest = ({
  dashboardSandboxId,
  hCaptchaToken,
  emailAddress,
}) => createAction({
  type: POST_SELF_SERVICE_RESEND_EMAIL_WITH_CAPTCHA_F_REQUEST,
  values: {
    id: dashboardSandboxId,
  },
  meta: {
    actionId: sendAmplitudeActionEvent(SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_BEFORE_VERIFICATION, {
      dashboardSandboxId,
      emailAddress,
    }),
    hCaptchaToken,
  },
})

export default postSelfServiceResendEmailWithCaptchaRequest
