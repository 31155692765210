import React, { Component } from 'react'
import { connect } from 'react-redux'
import LanguageSelector from './LanguageSelector'
import { EN } from 'constants/language/isoCodeConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  return {
    lng: get(state, 'languageR.lng', EN),
  }
}

class LanguageSelectorC extends Component {
  render() {
    return (
      <LanguageSelector {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(LanguageSelectorC)
