import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { HOSTED_PAYMENTS_PAYMENT_LINK } from 'constants/apiConstants'

const getGuestCheckoutPaymentLinkAPI = ({ id }) => {
  return guestCheckoutAPI.get({
    path: HOSTED_PAYMENTS_PAYMENT_LINK({ paymentLinkId: id }),
  })
}

export default getGuestCheckoutPaymentLinkAPI
