import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasSubmitFailed, isInvalid } from 'redux-form'
import UpdateVelocityLimitsForm from './UpdateVelocityLimitsForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import convertMilitaryHourToRegularHour from 'utilities/convert/convertMilitaryHourToRegularHour'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import { maxTransactionAmountKeyMapping } from 'constants/disbursementsConstants'
import { PATHNAME } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { UPDATE_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import last from 'lodash/last'
import includes from 'lodash/includes'
import lowerCase from 'lodash/lowerCase'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import toString from 'lodash/toString'

import {
  getApplicationSelector,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

import {
  AM,
  AMERICA_NY_TIMEZONE,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

import {
  ACH,
  CARD,
  APPLICATION,
  RECIPIENT,
  RECIPIENTS,
} from 'constants/language/languageConstants'

import {
  DAILY,
  MONTHLY,
} from 'constants/payoutSettingsConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentPath = get(state, PATHNAME, window.location.pathname)
  const lastPathString = last(split(currentPath, '/'))
  const entityType = includes(lastPathString, 'recipient') ? RECIPIENT : APPLICATION
  const normalizedEntityType = lowerCase(entityType)
  const limitType = includes(lastPathString, 'ach') ? ACH : CARD
  const title = `Edit ${entityType} ${limitType} Velocity Limits`
  const isFetchingApplication = get(state, `applicationsR.${FETCHING}`)
  const applicationId = get(props, 'params.applicationId')
  const disbursementRules = getDisbursementRulesSelector(state)
  const application = getApplicationSelector(state, applicationId)
  const businessName = get(application, 'businessName')
  const currentUser = getCurrentUser(state)
  const platformId = get(currentUser, 'platformId')
  const disbursementRuleId = get(disbursementRules, 'id')
  const isFormInvalid = isInvalid(UPDATE_VELOCITY_LIMITS_FORM)(state) && hasSubmitFailed(UPDATE_VELOCITY_LIMITS_FORM)(state)

  const possibleRulesKeys = [
    'applicationACHRules',
    'applicationCardRules',
    'recipientACHRules',
    'recipientCardRules',
  ]

  const targetedRulesSet = get(filter(possibleRulesKeys, (key) => includes(key, normalizedEntityType) && includes(key, limitType)), '[0]')
  const rulesSet = get(disbursementRules, targetedRulesSet)
  const dailyRulesSet = get(filter(rulesSet, (set) => isEqual(get(set, 'timeFrame'), DAILY)), '[0]')
  const monthlyRulesSet = get(filter(rulesSet, (set) => isEqual(get(set, 'timeFrame'), MONTHLY)), '[0]')
  const maxTransactionAmountValue = get(disbursementRules, get(maxTransactionAmountKeyMapping, `${normalizedEntityType}.${limitType}`))

  const [
    dailyStartTimeValue,
    dailyTimeMeridianValue,
    dailyCountValue,
    dailyVolumeValue,
  ] = getMany(dailyRulesSet, [
    'startTime',
    'startTimeMeridian',
    'rules[0].countLimit',
    'rules[0].displayVolumeLimit',
  ])

  const [
    monthlyStartTimeValue,
    monthlyTimeMeridianValue,
    monthlyCountValue,
    monthlyVolumeValue,
    monthlyDateValue,
  ] = getMany(monthlyRulesSet, [
    'startTime',
    'startTimeMeridian',
    'rules[0].countLimit',
    'rules[0].displayVolumeLimit',
    'startDate',
  ])

  const normalizedDailyStartTimeValue = convertMilitaryHourToRegularHour({ militaryHour: dailyStartTimeValue })
  const normalizedMonthlyStartTimeValue = convertMilitaryHourToRegularHour({ militaryHour: monthlyStartTimeValue })

  const initialValues = removeUndefined({
    dailyTimeZone: ({ label: AMERICA_NY_TIMEZONE, value: AMERICA_NY_TIMEZONE_BE_FIELD }),
    monthlyTimeZone: ({ label: AMERICA_NY_TIMEZONE, value: AMERICA_NY_TIMEZONE_BE_FIELD }),
    dailyStartTime: ({ label: toString(normalizedDailyStartTimeValue) || 'Select...', value: normalizedDailyStartTimeValue || '' }),
    dailyTimeMeridian: ({ label: dailyTimeMeridianValue || AM, value: dailyTimeMeridianValue || AM }),
    dailyCount: dailyCountValue,
    dailyVolume: dailyVolumeValue,
    monthlyStartTime: ({ label: toString(normalizedMonthlyStartTimeValue) || 'Select...', value: normalizedMonthlyStartTimeValue || '' }),
    monthlyTimeMeridian: ({ label: monthlyTimeMeridianValue || AM, value: monthlyTimeMeridianValue || AM }),
    monthlyCount: monthlyCountValue,
    monthlyVolume: monthlyVolumeValue,
    monthlyDate: ({ label: toString(monthlyDateValue) || 'Select...', value: monthlyDateValue || '' }),
    maxTransactionAmount: maxTransactionAmountValue,
  })

  let subtitle = `Edit the velocity limits for ${isEqual(limitType, CARD) ? 'card' : 'ACH'} payouts for ${isEqual(entityType, APPLICATION) ? 'Applications' : 'Recipients'}`

  if (applicationId) {
    subtitle = `Edit the velocity limits for ${isEqual(limitType, CARD) ? 'card' : 'ACH'} payouts for ${isEqual(entityType, APPLICATION) ? businessName : lowerCase(RECIPIENTS)}.`
  }

  return {
    credentials,
    credentialId,
    applicationId,
    platformId,
    title,
    subtitle,
    isFetchingApplication,
    limitType,
    entityType,
    initialValues,
    disbursementRules,
    disbursementRuleId,
    isFormInvalid,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    getDisbursementRules: ({ entityId, credentials }) => dispatch(getDisbursementRulesRequest({ entityId, credentials })),
  }
}

class UpdateVelocityLimitsFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      platformId,
      getApplication,
      getDisbursementRules,
    } = this.props

    if (applicationId) {
      getApplication({
        credentials,
        applicationId,
      })
    }

    if (applicationId || platformId) {
      getDisbursementRules({
        credentials,
        entityId: applicationId || platformId,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { platformId: prevPlatformId } = prevProps
    const { credentials, platformId, applicationId, getDisbursementRules } = this.props

    if (!applicationId && platformId && !isEqual(prevPlatformId, platformId)) {
      getDisbursementRules({
        credentials,
        entityId: platformId,
      })
    }
  }

  render() {
    return (
      <UpdateVelocityLimitsForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVelocityLimitsFormC)
