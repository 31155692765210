import './ExportDataV2ModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ExportDataV2FormC from 'components/Customer/Forms/ExportDataV2Form/ExportDataV2FormC'

const ExportDataV2Modal = ({
  title = 'Export',
  data = [],
  items = [],
  fileNameCSV = '',
  fileResourceTitleCSV = '',
  currentFilters = {},
  entityType = '',
  hiddenExportFields = [],
  additionalExportValues,
}) => {
  return (
    <GenericModal
      title={title}
      className='ExportDataV2Modal'
      Component={ExportDataV2FormC}
      data={data}
      items={items}
      fileNameCSV={fileNameCSV}
      fileResourceTitleCSV={fileResourceTitleCSV}
      currentFilters={currentFilters}
      entityType={entityType}
      hiddenExportFields={hiddenExportFields}
      additionalExportValues={additionalExportValues}
    />
  )
}

ExportDataV2Modal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  items: PropTypes.array,
  fileNameCSV: PropTypes.string,
  fileResourceTitleCSV: PropTypes.string,
  currentFilters: PropTypes.object,
  entityType: PropTypes.string,
  hiddenExportFields: PropTypes.array,
  additionalExportValues: PropTypes.object,
}

export default ExportDataV2Modal
