import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  APPLICATIONS,
  APPLICATION_PROCESSOR_CONFIGS,
} from 'constants/apiConstants'

const patchApplicationProcessorConfigsAPI = ({ id, values, credentials, meta }) => {
  const applicationId = get(values, 'applicationId')

  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: APPLICATION_PROCESSOR_CONFIGS({ applicationId: id, processor: 'FINIX_V1' }),
    service: APPLICATIONS,
  })
}

export default patchApplicationProcessorConfigsAPI
