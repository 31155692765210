import numeral from 'numeral'
import isEmpty from 'lodash/isEmpty'

import {
  nonEmptyNumber,
  validateDefaultStatementDescriptor,
  invalidRegex,
  fieldEmpty,
  validateTotalPercentage,
} from 'utilities/validate'

import {
  AMEX_MID_REGEX,
  DISCOVER_MID_REGEX,
} from 'constants/regexConstants'

const validateIdentityProcessingInfoForm = (values, props) => {
  const {
    processingData = {},
  } = values

  const {
    mcc,
    taxAuthority,
    defaultStatementDescriptor,
    previouslyProcessedCreditCards,
    amexMid,
    discoverMid,
    refundPolicy,
    annualCardVolume,
    annualACHVolume,
    averageCardTransferAmount,
    averageACHTransferAmount,
    maxTransactionAmount,
    achMaxTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    mailOrderTelephoneOrderPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
    noHighRiskIndustries,
  } = processingData

  const {
    highRiskIndustries,
    selectedIndustries,
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    isRolePartnerWithEditIdentityPermissions,
  } = props

  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(underwritingProfileMaxTransactionAmount).value()
  const inValidAchMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(underwritingProfileACHMaxTransactionAmount).value()

  const normalizedCardPresentPercentage = cardPresentPercentage ? numeral(cardPresentPercentage).value() : cardPresentPercentage
  const normalizedEcommercePercentage = ecommercePercentage ? numeral(ecommercePercentage).value() : ecommercePercentage
  const normalizedMailOrderTelephoneOrderPercentage = mailOrderTelephoneOrderPercentage ? numeral(mailOrderTelephoneOrderPercentage).value() : mailOrderTelephoneOrderPercentage
  const normalizedB2BVolumePercentage = businessToBusinessVolumePercentage ? numeral(businessToBusinessVolumePercentage).value() : businessToBusinessVolumePercentage
  const normalizedB2CVolumePercentage = businessToConsumerVolumePercentage ? numeral(businessToConsumerVolumePercentage).value() : businessToConsumerVolumePercentage
  const normalizedOtherVolumePercentage = otherVolumePercentage ? numeral(otherVolumePercentage).value() : otherVolumePercentage
  const noIndustryOrOptOutSelected = isEmpty(selectedIndustries) && !noHighRiskIndustries ? 'High risk industry must be selected or opted out of' : false

  return {
    processingData: {
      mcc: fieldEmpty(mcc, 'MCC'),
      taxAuthority: mcc === '9311' && fieldEmpty(taxAuthority, 'Tax Authority'),
      defaultStatementDescriptor: validateDefaultStatementDescriptor({ descriptor: defaultStatementDescriptor, name: 'Default Statement Descriptor' }),
      previouslyProcessedCreditCards: fieldEmpty(previouslyProcessedCreditCards),
      amexMid: previouslyProcessedCreditCards === 'true' ? invalidRegex({ field: amexMid, name: 'Amex MID', regex: AMEX_MID_REGEX, customErrorMessage: 'American Express MID must be 10 digits.' }) || fieldEmpty(amexMid, 'Amex MID') : null,
      discoverMid: invalidRegex({ field: discoverMid, name: 'Discover MID', regex: DISCOVER_MID_REGEX, customErrorMessage: 'Discover MID must be 15 digits.' }),
      refundPolicy: fieldEmpty(refundPolicy, 'Refund Policy'),
      annualCardVolume: nonEmptyNumber({ field: annualCardVolume, name: 'Annual Card Volume' }),
      annualACHVolume: nonEmptyNumber({ field: annualACHVolume, name: 'Annual ACH Volume' }),
      averageCardTransferAmount: nonEmptyNumber({ field: averageCardTransferAmount, name: 'Average Card Transaction Amount' }),
      averageACHTransferAmount: nonEmptyNumber({ field: averageACHTransferAmount, name: 'Average ACH Transaction Amount' }),
      maxTransactionAmount: nonEmptyNumber({ field: maxTransactionAmount, name: 'Max Transaction Amount' }) || ((invalidMaxTransactionAmount && isRolePartnerWithEditIdentityPermissions) && `Value exceeds the Application's max amount of ${underwritingProfileDisplayMaxTransactionAmount}`),
      achMaxTransactionAmount: nonEmptyNumber({ field: achMaxTransactionAmount, name: 'ACH Max Transaction Amount' }) || ((inValidAchMaxTransactionAmount && isRolePartnerWithEditIdentityPermissions) && `Value exceeds the Application's ACH max amount of ${underwritingProfileDisplayACHMaxTransactionAmount}`),
      cardPresentPercentage: validateTotalPercentage({ name: 'Total Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
      ecommercePercentage: validateTotalPercentage({ name: 'Total Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
      mailOrderTelephoneOrderPercentage: validateTotalPercentage({ name: 'Total Card Volume', amounts: [normalizedCardPresentPercentage, normalizedEcommercePercentage, normalizedMailOrderTelephoneOrderPercentage], exactAmount: 100 }),
      businessToBusinessVolumePercentage: validateTotalPercentage({ name: 'Total Payment Volume', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
      businessToConsumerVolumePercentage: validateTotalPercentage({ name: 'Total Payment Volume', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
      otherVolumePercentage: validateTotalPercentage({ name: 'Total Payment Volume', amounts: [normalizedB2BVolumePercentage, normalizedB2CVolumePercentage, normalizedOtherVolumePercentage], exactAmount: 100 }),
      noHighRiskIndustries: !isEmpty(highRiskIndustries) ? noIndustryOrOptOutSelected : false,
    },
  }
}

export default validateIdentityProcessingInfoForm
