import './IdentityMatchS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import AdverseMediaScreeningDetailsC from 'components/Customer/Shared/Display/AdverseMediaScreeningDetails/AdverseMediaScreeningDetailsC'
import formatDate from 'utilities/formatters/formatDate'
import { MM_DD_YYYY } from 'constants/timeConstants'
import size from 'lodash/size'
import map from 'lodash/map'

import {
  ADVERSE_MEDIA,
} from 'constants/language/languageConstants'

const IdentityMatch = ({
  isFetching = false,
  headerData = {},
  hit = {},
  mediaHits = [],
  entityName = '',
  personaRawData = {},
}) => {
  const adverseMediaHitsSection = map(mediaHits, ({ title, snippet, date, url }, index) => {
    return (
      <div className='adverse-media-hit' key={`adverse-media-hit-${index}`}>
        <div className='p-2-bold title'>{title}</div>
        <div className='snippet p-2'>{snippet}</div>

        <div className='bottom-section flex items-center'>
          <div className='date p-2'>{formatDate({ date, format: MM_DD_YYYY })}</div>
          {date && <span className='circle' />}
          <a className='text-link url' target='#' href={url}>{url}</a>
        </div>
      </div>
    )
  })

  return (
    <div className='IdentityMatch'>
      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      <AdverseMediaScreeningDetailsC
        entityName={entityName}
        personaRawData={personaRawData}
        hit={hit}
      />

      <PageSectionV2C
        title={<div className='flex items-center'>{ADVERSE_MEDIA} <span className='number-of-hits'>{size(mediaHits)}</span></div>}
        isFetching={isFetching}
      >
        <div className='adverse-media-hits-section'>{adverseMediaHitsSection}</div>
      </PageSectionV2C>
    </div>
  )
}

IdentityMatch.propTypes = {
  isFetching: PropTypes.bool,
  headerData: PropTypes.object,
  hit: PropTypes.object,
  mediaHits: PropTypes.array,
  entityName: PropTypes.string,
  personaRawData: PropTypes.object,
}

export default IdentityMatch
