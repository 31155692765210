import getMany from 'utilities/get/getMany'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
} from 'constants/identityConstants'

import {
  SAME_PERSONAL_PHONE_AS_BUSINESS_PHONE,
  SAME_PERSONAL_ADDRESS_AS_BUSINESS_ADDRESS,
} from 'constants/language/languageConstants'

const warnIdentityPrincipalInfoForm = (values, props) => {
  const [
    beneficialOwnersArray = [],
    controlPerson,
  ] = getMany(values, [
    'beneficialOwners',
    'controlPerson',
  ])

  const onboardingForm = get(props, 'onboardingForm', {})
  const businessPhone = get(onboardingForm, 'entity.businessPhone', '')
  const businessAddress = get(onboardingForm, 'entity.businessAddress', {})
  const businessType = get(onboardingForm, 'entity.businessType', '')
  const isSoleProp = businessType === INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE

  // check control person for warnings
  const [
    controlPersonAddress,
    controlPersonPhone,
  ] = getMany(controlPerson, [
    'personalAddress',
    'phone',
  ])

  const controlPersonPhoneEqualsBusinessPhone = controlPersonPhone === businessPhone
  const controlPersonAddressEqualsBusinessAddress = isEqual(controlPersonAddress, businessAddress)

  const controlPersonWarnings = {
    personalAddress: {
      line1: !isSoleProp && controlPersonAddressEqualsBusinessAddress && SAME_PERSONAL_ADDRESS_AS_BUSINESS_ADDRESS,
    },
    phone: !isSoleProp && controlPersonPhoneEqualsBusinessPhone && SAME_PERSONAL_PHONE_AS_BUSINESS_PHONE,
  }

  // check all beneficial owners for warnings
  const beneficialOwnersWarnings = map(beneficialOwnersArray, (owner = {}, index) => {
    const [
      personalAddress,
      phone,
    ] = getMany(owner, [
      'personalAddress',
      'phone',
    ])

    const personalPhoneEqualsBusinessPhone = phone === businessPhone
    const personalAddressEqualsBusinessAddress = isEqual(personalAddress, businessAddress)

    return {
      personalAddress: {
        line1: !isSoleProp && personalAddressEqualsBusinessAddress && SAME_PERSONAL_ADDRESS_AS_BUSINESS_ADDRESS,
      },
      phone: !isSoleProp && personalPhoneEqualsBusinessPhone && SAME_PERSONAL_PHONE_AS_BUSINESS_PHONE,
    }
  })

  return {
    controlPerson: controlPersonWarnings,
    beneficialOwners: beneficialOwnersWarnings,
  }
}

export default warnIdentityPrincipalInfoForm
