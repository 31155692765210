import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatPercentage from 'utilities/formatters/formatPercentage'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import { CURRENCY_TO_COUNTRY_NAME_MAP } from 'constants/currencyConstants'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import isNaN from 'lodash/isNaN'

import {
  normalizeNonNullBasisPoints,
  normalizeNonNullFeeAmount,
} from 'constants/normalizationConstants'

const formatDisplayString = (minValue, maxValue, isBasisPoints) => {
  const displayMinValue = isBasisPoints ? formatPercentage({ percentage: minValue }) : formatMoney({ amount: minValue })
  const displayMaxValue = isBasisPoints ? formatPercentage({ percentage: maxValue }) : formatMoney({ amount: maxValue })

  if ((minValue !== null && !isNaN(minValue)) && (maxValue === null || isNaN(maxValue))) {
    return `Above or equal to ${displayMinValue}`
  }
  if ((maxValue !== null && !isNaN(maxValue)) && (minValue === null || isNaN(minValue))) {
    return `Below or equal to ${displayMaxValue}`
  }
  if (!minValue && !maxValue) {
    return null
  }
  return `${displayMinValue} - ${displayMaxValue}`
}

const frontendFeeProfileRuleModel = ({ data: feeProfileRule }) => {
  const [
    rules,
    entityId,
    entityType,
    createdAt,
    updatedAt,
  ] = getMany(feeProfileRule, [
    'rules',
    'entity_id',
    'entity_type',
    'created_at',
    'updated_at',
  ])

  const rulesModels = reduce(rules, (total, rule) => {
    const [
      currency,
      feeStrategy,
      isAllowed,
      achBasisPointsMinBackendValue = null,
      achBasisPointsMaxBackendValue = null,
      achCreditReturnFixedFeeMinBackendValue = null,
      achCreditReturnFixedFeeMaxBackendValue = null,
      achDebitReturnFixedFeeMinBackendValue = null,
      achDebitReturnFixedFeeMaxBackendValue = null,
      achFixedFeeMinBackendValue = null,
      achFixedFeeMaxBackendValue = null,
      americanExpressAssessmentBasisPointsMinBackendValue = null,
      americanExpressAssessmentBasisPointsMaxBackendValue = null,
      americanExpressBasisPointsMinBackendValue = null,
      americanExpressBasisPointsMaxBackendValue = null,
      americanExpressFixedFeeMinBackendValue = null,
      americanExpressFixedFeeMaxBackendValue = null,
      americanExpressExternallyFundedBasisPointsMinBackendValue = null,
      americanExpressExternallyFundedBasisPointsMaxBackendValue = null,
      americanExpressExternallyFundedFixedFeeMinBackendValue = null,
      americanExpressExternallyFundedFixedFeeMaxBackendValue = null,
      ancillaryFixedFeePrimaryMinBackendValue = null,
      ancillaryFixedFeePrimaryMaxBackendValue = null,
      ancillaryFixedFeeSecondaryMinBackendValue = null,
      ancillaryFixedFeeSecondaryMaxBackendValue = null,
      basisPointsMinBackendValue = null,
      basisPointsMaxBackendValue = null,
      cardCrossBorderBasisPointsMinBackendValue = null,
      cardCrossBorderBasisPointsMaxBackendValue = null,
      cardCrossBorderFixedFeeMinBackendValue = null,
      cardCrossBorderFixedFeeMaxBackendValue = null,
      dinersClubBasisPointsMinBackendValue = null,
      dinersClubBasisPointsMaxBackendValue = null,
      dinersClubFixedFeeMinBackendValue = null,
      dinersClubFixedFeeMaxBackendValue = null,
      discoverAssessmentsBasisPointsMinBackendValue = null,
      discoverAssessmentsBasisPointsMaxBackendValue = null,
      discoverBasisPointsMinBackendValue = null,
      discoverBasisPointsMaxBackendValue = null,
      discoverDataUsageFixedFeeMinBackendValue = null,
      discoverDataUsageFixedFeeMaxBackendValue = null,
      discoverFixedFeeMinBackendValue = null,
      discoverFixedFeeMaxBackendValue = null,
      discoverExternallyFundedBasisPointsMinBackendValue = null,
      discoverExternallyFundedBasisPointsMaxBackendValue = null,
      discoverExternallyFundedFixedFeeMinBackendValue = null,
      discoverExternallyFundedFixedFeeMaxBackendValue = null,
      discoverNetworkAuthorizationFixedFeeMinBackendValue = null,
      discoverNetworkAuthorizationFixedFeeMaxBackendValue = null,
      disputeFixedFeeMinBackendValue = null,
      disputeFixedFeeMaxBackendValue = null,
      disputeInquiryFixedFeeMinBackendValue = null,
      disputeInquiryFixedFeeMaxBackendValue = null,
      externallyFundedBasisPointsMinBackendValue = null,
      externallyFundedBasisPointsMaxBackendValue = null,
      externallyFundedFixedFeeMinBackendValue = null,
      externallyFundedFixedFeeMaxBackendValue = null,
      fixedFeeMinBackendValue = null,
      fixedFeeMaxBackendValue = null,
      jcbBasisPointsMinBackendValue = null,
      jcbBasisPointsMaxBackendValue = null,
      jcbFixedFeeMinBackendValue = null,
      jcbFixedFeeMaxBackendValue = null,
      mastercardAcquirerFeesBasisPointsMinBackendValue = null,
      mastercardAcquirerFeesBasisPointsMaxBackendValue = null,
      mastercardAssessmentsOver1kBasisPointsMinBackendValue = null,
      mastercardAssessmentsOver1kBasisPointsMaxBackendValue = null,
      mastercardAssessmentsUnder1kBasisPointsMinBackendValue = null,
      mastercardAssessmentsUnder1kBasisPointsMaxBackendValue = null,
      mastercardBasisPointsMinBackendValue = null,
      mastercardBasisPointsMaxBackendValue = null,
      mastercardFixedFeeMinBackendValue = null,
      mastercardFixedFeeMaxBackendValue = null,
      visaAcquirerProcessingFixedFeeMinBackendValue = null,
      visaAcquirerProcessingFixedFeeMaxBackendValue = null,
      visaAssessmentsBasisPointsMinBackendValue = null,
      visaAssessmentsBasisPointsMaxBackendValue = null,
      visaBaseIICreditVoucherFixedFeeMinBackendValue = null,
      visaBaseIICreditVoucherFixedFeeMaxBackendValue = null,
      visaBaseIISystemFileTransmissionFixedFeeMinBackendValue = null,
      visaBaseIISystemFileTransmissionFixedFeeMaxBackendValue = null,
      visaBasisPointsMinBackendValue = null,
      visaBasisPointsMaxBackendValue = null,
      visaCreditVoucherFixedFeeMinBackendValue = null,
      visaCreditVoucherFixedFeeMaxBackendValue = null,
      visaFixedFeeMinBackendValue = null,
      visaFixedFeeMaxBackendValue = null,
      visaKilobyteAccessFixedFeeMinBackendValue = null,
      visaKilobyteAccessFixedFeeMaxBackendValue = null,
    ] = getMany(rule, [
      'currency',
      'fee_strategy',
      'is_allowed',
      'fee_bounds.ach_basis_points.min',
      'fee_bounds.ach_basis_points.max',
      'fee_bounds.ach_credit_return_fixed_fee.min',
      'fee_bounds.ach_credit_return_fixed_fee.max',
      'fee_bounds.ach_debit_return_fixed_fee.min',
      'fee_bounds.ach_debit_return_fixed_fee.max',
      'fee_bounds.ach_fixed_fee.min',
      'fee_bounds.ach_fixed_fee.max',
      'fee_bounds.american_express_assessment_basis_points.min',
      'fee_bounds.american_express_assessment_basis_points.max',
      'fee_bounds.american_express_basis_points.min',
      'fee_bounds.american_express_basis_points.max',
      'fee_bounds.american_express_fixed_fee.min',
      'fee_bounds.american_express_fixed_fee.max',
      'fee_bounds.american_express_externally_funded_basis_points.min',
      'fee_bounds.american_express_externally_funded_basis_points.max',
      'fee_bounds.american_express_externally_funded_fixed_fee.min',
      'fee_bounds.american_express_externally_funded_fixed_fee.max',
      'fee_bounds.ancillary_fixed_fee_primary.min',
      'fee_bounds.ancillary_fixed_fee_primary.max',
      'fee_bounds.ancillary_fixed_fee_secondary.min',
      'fee_bounds.ancillary_fixed_fee_secondary.max',
      'fee_bounds.basis_points.min',
      'fee_bounds.basis_points.max',
      'fee_bounds.card_cross_border_basis_points.min',
      'fee_bounds.card_cross_border_basis_points.max',
      'fee_bounds.card_cross_border_fixed_fee.min',
      'fee_bounds.card_cross_border_fixed_fee.max',
      'fee_bounds.diners_club_basis_points.min',
      'fee_bounds.diners_club_basis_points.max',
      'fee_bounds.diners_club_fixed_fee.min',
      'fee_bounds.diners_club_fixed_fee.max',
      'fee_bounds.discover_assessments_basis_points.min',
      'fee_bounds.discover_assessments_basis_points.max',
      'fee_bounds.discover_basis_points.min',
      'fee_bounds.discover_basis_points.max',
      'fee_bounds.discover_data_usage_fixed_fee.min',
      'fee_bounds.discover_data_usage_fixed_fee.max',
      'fee_bounds.discover_fixed_fee.min',
      'fee_bounds.discover_fixed_fee.max',
      'fee_bounds.discover_externally_funded_basis_points.min',
      'fee_bounds.discover_externally_funded_basis_points.max',
      'fee_bounds.discover_externally_funded_fixed_fee.min',
      'fee_bounds.discover_externally_funded_fixed_fee.max',
      'fee_bounds.discover_network_authorization_fixed_fee.min',
      'fee_bounds.discover_network_authorization_fixed_fee.max',
      'fee_bounds.dispute_fixed_fee.min',
      'fee_bounds.dispute_fixed_fee.max',
      'fee_bounds.dispute_inquiry_fixed_fee.min',
      'fee_bounds.dispute_inquiry_fixed_fee.max',
      'fee_bounds.externally_funded_basis_points.min',
      'fee_bounds.externally_funded_basis_points.max',
      'fee_bounds.externally_funded_fixed_fee.min',
      'fee_bounds.externally_funded_fixed_fee.max',
      'fee_bounds.fixed_fee.min',
      'fee_bounds.fixed_fee.max',
      'fee_bounds.jcb_basis_points.min',
      'fee_bounds.jcb_basis_points.max',
      'fee_bounds.jcb_fixed_fee.min',
      'fee_bounds.jcb_fixed_fee.max',
      'fee_bounds.mastercard_acquirer_fees_basis_points.min',
      'fee_bounds.mastercard_acquirer_fees_basis_points.max',
      'fee_bounds.mastercard_assessments_over1k_basis_points.min',
      'fee_bounds.mastercard_assessments_over1k_basis_points.max',
      'fee_bounds.mastercard_assessments_under1k_basis_points.min',
      'fee_bounds.mastercard_assessments_under1k_basis_points.max',
      'fee_bounds.mastercard_basis_points.min',
      'fee_bounds.mastercard_basis_points.max',
      'fee_bounds.mastercard_fixed_fee.min',
      'fee_bounds.mastercard_fixed_fee.max',
      'fee_bounds.visa_acquirer_processing_fixed_fee.min',
      'fee_bounds.visa_acquirer_processing_fixed_fee.max',
      'fee_bounds.visa_assessments_basis_points.min',
      'fee_bounds.visa_assessments_basis_points.max',
      'fee_bounds.visa_base_II_credit_voucher_fixed_fee.min',
      'fee_bounds.visa_base_II_credit_voucher_fixed_fee.max',
      'fee_bounds.visa_base_II_system_file_transmission_fixed_fee.min',
      'fee_bounds.visa_base_II_system_file_transmission_fixed_fee.max',
      'fee_bounds.visa_basis_points.min',
      'fee_bounds.visa_basis_points.max',
      'fee_bounds.visa_credit_voucher_fixed_fee.min',
      'fee_bounds.visa_credit_voucher_fixed_fee.max',
      'fee_bounds.visa_fixed_fee.min',
      'fee_bounds.visa_fixed_fee.max',
      'fee_bounds.visa_kilobyte_access_fixed_fee.min',
      'fee_bounds.visa_kilobyte_access_fixed_fee.max',
    ])

    // normalize fee bounds
    const achBasisPointsMin = normalizeNonNullBasisPoints(achBasisPointsMinBackendValue)
    const achBasisPointsMax = normalizeNonNullBasisPoints(achBasisPointsMaxBackendValue)
    const achCreditReturnFixedFeeMin = normalizeNonNullFeeAmount(achCreditReturnFixedFeeMinBackendValue)
    const achCreditReturnFixedFeeMax = normalizeNonNullFeeAmount(achCreditReturnFixedFeeMaxBackendValue)
    const achDebitReturnFixedFeeMin = normalizeNonNullFeeAmount(achDebitReturnFixedFeeMinBackendValue)
    const achDebitReturnFixedFeeMax = normalizeNonNullFeeAmount(achDebitReturnFixedFeeMaxBackendValue)
    const achFixedFeeMin = normalizeNonNullFeeAmount(achFixedFeeMinBackendValue)
    const achFixedFeeMax = normalizeNonNullFeeAmount(achFixedFeeMaxBackendValue)
    const americanExpressAssessmentBasisPointsMin = normalizeNonNullBasisPoints(americanExpressAssessmentBasisPointsMinBackendValue)
    const americanExpressAssessmentBasisPointsMax = normalizeNonNullBasisPoints(americanExpressAssessmentBasisPointsMaxBackendValue)
    const americanExpressBasisPointsMin = normalizeNonNullBasisPoints(americanExpressBasisPointsMinBackendValue)
    const americanExpressBasisPointsMax = normalizeNonNullBasisPoints(americanExpressBasisPointsMaxBackendValue)
    const americanExpressFixedFeeMin = normalizeNonNullFeeAmount(americanExpressFixedFeeMinBackendValue)
    const americanExpressFixedFeeMax = normalizeNonNullFeeAmount(americanExpressFixedFeeMaxBackendValue)
    const americanExpressExternallyFundedBasisPointsMin = normalizeNonNullBasisPoints(americanExpressExternallyFundedBasisPointsMinBackendValue)
    const americanExpressExternallyFundedBasisPointsMax = normalizeNonNullBasisPoints(americanExpressExternallyFundedBasisPointsMaxBackendValue)
    const americanExpressExternallyFundedFixedFeeMin = normalizeNonNullFeeAmount(americanExpressExternallyFundedFixedFeeMinBackendValue)
    const americanExpressExternallyFundedFixedFeeMax = normalizeNonNullFeeAmount(americanExpressExternallyFundedFixedFeeMaxBackendValue)
    const ancillaryFixedFeePrimaryMin = normalizeNonNullFeeAmount(ancillaryFixedFeePrimaryMinBackendValue)
    const ancillaryFixedFeePrimaryMax = normalizeNonNullFeeAmount(ancillaryFixedFeePrimaryMaxBackendValue)
    const ancillaryFixedFeeSecondaryMin = normalizeNonNullFeeAmount(ancillaryFixedFeeSecondaryMinBackendValue)
    const ancillaryFixedFeeSecondaryMax = normalizeNonNullFeeAmount(ancillaryFixedFeeSecondaryMaxBackendValue)
    const basisPointsMin = normalizeNonNullBasisPoints(basisPointsMinBackendValue)
    const basisPointsMax = normalizeNonNullBasisPoints(basisPointsMaxBackendValue)
    const cardCrossBorderBasisPointsMin = normalizeNonNullBasisPoints(cardCrossBorderBasisPointsMinBackendValue)
    const cardCrossBorderBasisPointsMax = normalizeNonNullBasisPoints(cardCrossBorderBasisPointsMaxBackendValue)
    const cardCrossBorderFixedFeeMin = normalizeNonNullFeeAmount(cardCrossBorderFixedFeeMinBackendValue)
    const cardCrossBorderFixedFeeMax = normalizeNonNullFeeAmount(cardCrossBorderFixedFeeMaxBackendValue)
    const dinersClubBasisPointsMin = normalizeNonNullBasisPoints(dinersClubBasisPointsMinBackendValue)
    const dinersClubBasisPointsMax = normalizeNonNullBasisPoints(dinersClubBasisPointsMaxBackendValue)
    const dinersClubFixedFeeMin = normalizeNonNullFeeAmount(dinersClubFixedFeeMinBackendValue)
    const dinersClubFixedFeeMax = normalizeNonNullFeeAmount(dinersClubFixedFeeMaxBackendValue)
    const discoverAssessmentsBasisPointsMin = normalizeNonNullBasisPoints(discoverAssessmentsBasisPointsMinBackendValue)
    const discoverAssessmentsBasisPointsMax = normalizeNonNullBasisPoints(discoverAssessmentsBasisPointsMaxBackendValue)
    const discoverBasisPointsMin = normalizeNonNullBasisPoints(discoverBasisPointsMinBackendValue)
    const discoverBasisPointsMax = normalizeNonNullBasisPoints(discoverBasisPointsMaxBackendValue)
    const discoverDataUsageFixedFeeMin = normalizeNonNullFeeAmount(discoverDataUsageFixedFeeMinBackendValue)
    const discoverDataUsageFixedFeeMax = normalizeNonNullFeeAmount(discoverDataUsageFixedFeeMaxBackendValue)
    const discoverFixedFeeMin = normalizeNonNullFeeAmount(discoverFixedFeeMinBackendValue)
    const discoverFixedFeeMax = normalizeNonNullFeeAmount(discoverFixedFeeMaxBackendValue)
    const discoverExternallyFundedBasisPointsMin = normalizeNonNullBasisPoints(discoverExternallyFundedBasisPointsMinBackendValue)
    const discoverExternallyFundedBasisPointsMax = normalizeNonNullBasisPoints(discoverExternallyFundedBasisPointsMaxBackendValue)
    const discoverExternallyFundedFixedFeeMin = normalizeNonNullFeeAmount(discoverExternallyFundedFixedFeeMinBackendValue)
    const discoverExternallyFundedFixedFeeMax = normalizeNonNullFeeAmount(discoverExternallyFundedFixedFeeMaxBackendValue)
    const discoverNetworkAuthorizationFixedFeeMin = normalizeNonNullFeeAmount(discoverNetworkAuthorizationFixedFeeMinBackendValue)
    const discoverNetworkAuthorizationFixedFeeMax = normalizeNonNullFeeAmount(discoverNetworkAuthorizationFixedFeeMaxBackendValue)
    const disputeFixedFeeMin = normalizeNonNullFeeAmount(disputeFixedFeeMinBackendValue)
    const disputeFixedFeeMax = normalizeNonNullFeeAmount(disputeFixedFeeMaxBackendValue)
    const disputeInquiryFixedFeeMin = normalizeNonNullFeeAmount(disputeInquiryFixedFeeMinBackendValue)
    const disputeInquiryFixedFeeMax = normalizeNonNullFeeAmount(disputeInquiryFixedFeeMaxBackendValue)
    const externallyFundedBasisPointsMin = normalizeNonNullBasisPoints(externallyFundedBasisPointsMinBackendValue)
    const externallyFundedBasisPointsMax = normalizeNonNullBasisPoints(externallyFundedBasisPointsMaxBackendValue)
    const externallyFundedFixedFeeMin = normalizeNonNullFeeAmount(externallyFundedFixedFeeMinBackendValue)
    const externallyFundedFixedFeeMax = normalizeNonNullFeeAmount(externallyFundedFixedFeeMaxBackendValue)
    const fixedFeeMin = normalizeNonNullFeeAmount(fixedFeeMinBackendValue)
    const fixedFeeMax = normalizeNonNullFeeAmount(fixedFeeMaxBackendValue)
    const jcbBasisPointsMin = normalizeNonNullBasisPoints(jcbBasisPointsMinBackendValue)
    const jcbBasisPointsMax = normalizeNonNullBasisPoints(jcbBasisPointsMaxBackendValue)
    const jcbFixedFeeMin = normalizeNonNullFeeAmount(jcbFixedFeeMinBackendValue)
    const jcbFixedFeeMax = normalizeNonNullFeeAmount(jcbFixedFeeMaxBackendValue)
    const mastercardAcquirerFeesBasisPointsMin = normalizeNonNullBasisPoints(mastercardAcquirerFeesBasisPointsMinBackendValue)
    const mastercardAcquirerFeesBasisPointsMax = normalizeNonNullBasisPoints(mastercardAcquirerFeesBasisPointsMaxBackendValue)
    const mastercardAssessmentsOver1kBasisPointsMin = normalizeNonNullBasisPoints(mastercardAssessmentsOver1kBasisPointsMinBackendValue)
    const mastercardAssessmentsOver1kBasisPointsMax = normalizeNonNullBasisPoints(mastercardAssessmentsOver1kBasisPointsMaxBackendValue)
    const mastercardAssessmentsUnder1kBasisPointsMin = normalizeNonNullBasisPoints(mastercardAssessmentsUnder1kBasisPointsMinBackendValue)
    const mastercardAssessmentsUnder1kBasisPointsMax = normalizeNonNullBasisPoints(mastercardAssessmentsUnder1kBasisPointsMaxBackendValue)
    const mastercardBasisPointsMin = normalizeNonNullBasisPoints(mastercardBasisPointsMinBackendValue)
    const mastercardBasisPointsMax = normalizeNonNullBasisPoints(mastercardBasisPointsMaxBackendValue)
    const mastercardFixedFeeMin = normalizeNonNullFeeAmount(mastercardFixedFeeMinBackendValue)
    const mastercardFixedFeeMax = normalizeNonNullFeeAmount(mastercardFixedFeeMaxBackendValue)
    const visaAcquirerProcessingFixedFeeMin = normalizeNonNullFeeAmount(visaAcquirerProcessingFixedFeeMinBackendValue)
    const visaAcquirerProcessingFixedFeeMax = normalizeNonNullFeeAmount(visaAcquirerProcessingFixedFeeMaxBackendValue)
    const visaAssessmentsBasisPointsMin = normalizeNonNullBasisPoints(visaAssessmentsBasisPointsMinBackendValue)
    const visaAssessmentsBasisPointsMax = normalizeNonNullBasisPoints(visaAssessmentsBasisPointsMaxBackendValue)
    const visaBaseIICreditVoucherFixedFeeMin = normalizeNonNullFeeAmount(visaBaseIICreditVoucherFixedFeeMinBackendValue)
    const visaBaseIICreditVoucherFixedFeeMax = normalizeNonNullFeeAmount(visaBaseIICreditVoucherFixedFeeMaxBackendValue)
    const visaBaseIISystemFileTransmissionFixedFeeMin = normalizeNonNullFeeAmount(visaBaseIISystemFileTransmissionFixedFeeMinBackendValue)
    const visaBaseIISystemFileTransmissionFixedFeeMax = normalizeNonNullFeeAmount(visaBaseIISystemFileTransmissionFixedFeeMaxBackendValue)
    const visaBasisPointsMin = normalizeNonNullBasisPoints(visaBasisPointsMinBackendValue)
    const visaBasisPointsMax = normalizeNonNullBasisPoints(visaBasisPointsMaxBackendValue)
    const visaCreditVoucherFixedFeeMin = normalizeNonNullFeeAmount(visaCreditVoucherFixedFeeMinBackendValue)
    const visaCreditVoucherFixedFeeMax = normalizeNonNullFeeAmount(visaCreditVoucherFixedFeeMaxBackendValue)
    const visaFixedFeeMin = normalizeNonNullFeeAmount(visaFixedFeeMinBackendValue)
    const visaFixedFeeMax = normalizeNonNullFeeAmount(visaFixedFeeMaxBackendValue)
    const visaKilobyteAccessFixedFeeMin = normalizeNonNullFeeAmount(visaKilobyteAccessFixedFeeMinBackendValue)
    const visaKilobyteAccessFixedFeeMax = normalizeNonNullFeeAmount(visaKilobyteAccessFixedFeeMaxBackendValue)

    const allowCardBrandOverrides = some([
      visaBasisPointsMin,
      visaBasisPointsMax,
      visaFixedFeeMin,
      visaFixedFeeMax,
      mastercardBasisPointsMin,
      mastercardBasisPointsMax,
      mastercardFixedFeeMin,
      mastercardFixedFeeMax,
      americanExpressBasisPointsMin,
      americanExpressBasisPointsMax,
      americanExpressFixedFeeMin,
      americanExpressFixedFeeMax,
      discoverBasisPointsMin,
      discoverBasisPointsMax,
      discoverFixedFeeMin,
      discoverFixedFeeMax,
      jcbBasisPointsMin,
      jcbBasisPointsMax,
      jcbFixedFeeMin,
      jcbFixedFeeMax,
      dinersClubBasisPointsMin,
      dinersClubBasisPointsMax,
      dinersClubFixedFeeMin,
      dinersClubFixedFeeMax,
    ])

    const allowChargebackFees = some([disputeFixedFeeMin, disputeFixedFeeMax, disputeInquiryFixedFeeMin, disputeInquiryFixedFeeMax])

    return merge({}, total, {
      [feeStrategy]: {
        [currency]: removeUndefined({
          currency,
          country: CURRENCY_TO_COUNTRY_NAME_MAP[currency],
          feeStrategy,
          isAllowed,
          achBasisPointsMin,
          displayAchBasisPointsMin: formatPercentage({ percentage: achBasisPointsMin }),
          achBasisPointsMax,
          displayAchBasisPointsMax: formatPercentage({ percentage: achBasisPointsMax }),
          achBasisPointsString: formatDisplayString(achBasisPointsMin, achBasisPointsMax, true),
          achCreditReturnFixedFeeMin,
          displayAchCreditReturnFixedFeeMin: formatMoney({ amount: achCreditReturnFixedFeeMin }),
          achCreditReturnFixedFeeMax,
          displayAchCreditReturnFixedFeeMax: formatMoney({ amount: achCreditReturnFixedFeeMax }),
          achCreditReturnFixedFeeString: formatDisplayString(achCreditReturnFixedFeeMin, achCreditReturnFixedFeeMax, false),
          achDebitReturnFixedFeeMin,
          displayAchDebitReturnFixedFeeMin: formatMoney({ amount: achDebitReturnFixedFeeMin }),
          achDebitReturnFixedFeeMax,
          displayAchDebitReturnFixedFeeMax: formatMoney({ amount: achDebitReturnFixedFeeMax }),
          achDebitReturnFixedFeeString: formatDisplayString(achDebitReturnFixedFeeMin, achDebitReturnFixedFeeMax, false),
          achFixedFeeMin,
          displayAchFixedFeeMin: formatMoney({ amount: achFixedFeeMin }),
          achFixedFeeMax,
          displayAchFixedFeeMax: formatMoney({ amount: achFixedFeeMax }),
          achFixedFeeString: formatDisplayString(achFixedFeeMin, achFixedFeeMax, false),
          americanExpressAssessmentBasisPointsMin,
          displayAmericanExpressAssessmentBasisPointsMin: formatPercentage({ percentage: americanExpressAssessmentBasisPointsMin }),
          americanExpressAssessmentBasisPointsMax,
          displayAmericanExpressAssessmentBasisPointsMax: formatPercentage({ percentage: americanExpressAssessmentBasisPointsMax }),
          americanExpressAssessmentBasisPointsString: formatDisplayString(americanExpressAssessmentBasisPointsMin, americanExpressAssessmentBasisPointsMax, true),
          americanExpressBasisPointsMin,
          displayAmericanExpressBasisPointsMin: formatPercentage({ percentage: americanExpressBasisPointsMin }),
          americanExpressBasisPointsMax,
          displayAmericanExpressBasisPointsMax: formatPercentage({ percentage: americanExpressBasisPointsMax }),
          americanExpressBasisPointsString: formatDisplayString(americanExpressBasisPointsMin, americanExpressBasisPointsMax, true),
          americanExpressFixedFeeMin,
          displayAmericanExpressFixedFeeMin: formatMoney({ amount: americanExpressFixedFeeMin }),
          americanExpressFixedFeeMax,
          displayAmericanExpressFixedFeeMax: formatMoney({ amount: americanExpressFixedFeeMax }),
          americanExpressFixedFeeString: formatDisplayString(americanExpressFixedFeeMin, americanExpressFixedFeeMax, false),
          americanExpressExternallyFundedBasisPointsMin,
          displayAmericanExpressExternallyFundedBasisPointsMin: formatPercentage({ percentage: americanExpressExternallyFundedBasisPointsMin }),
          americanExpressExternallyFundedBasisPointsMax,
          displayAmericanExpressExternallyFundedBasisPointsMax: formatPercentage({ percentage: americanExpressExternallyFundedBasisPointsMax }),
          americanExpressExternallyFundedBasisPointsString: formatDisplayString(americanExpressExternallyFundedBasisPointsMin, americanExpressExternallyFundedBasisPointsMax, true),
          americanExpressExternallyFundedFixedFeeMin,
          displayAmericanExpressExternallyFundedFixedFeeMin: formatMoney({ amount: americanExpressExternallyFundedFixedFeeMin }),
          americanExpressExternallyFundedFixedFeeMax,
          displayAmericanExpressExternallyFundedFixedFeeMax: formatMoney({ amount: americanExpressExternallyFundedFixedFeeMax }),
          americanExpressExternallyFundedFixedFeeString: formatDisplayString(americanExpressExternallyFundedFixedFeeMin, americanExpressExternallyFundedFixedFeeMax, false),
          ancillaryFixedFeePrimaryMin,
          displayAncillaryFixedFeePrimaryMin: formatMoney({ amount: ancillaryFixedFeePrimaryMin }),
          ancillaryFixedFeePrimaryMax,
          displayAncillaryFixedFeePrimaryMax: formatMoney({ amount: ancillaryFixedFeePrimaryMax }),
          ancillaryFixedFeePrimaryString: formatDisplayString(ancillaryFixedFeePrimaryMin, ancillaryFixedFeePrimaryMax, false),
          ancillaryFixedFeeSecondaryMin,
          displayAncillaryFixedFeeSecondaryMin: formatMoney({ amount: ancillaryFixedFeeSecondaryMin }),
          ancillaryFixedFeeSecondaryMax,
          displayAncillaryFixedFeeSecondaryMax: formatMoney({ amount: ancillaryFixedFeeSecondaryMax }),
          ancillaryFixedFeeSecondaryString: formatDisplayString(ancillaryFixedFeeSecondaryMin, ancillaryFixedFeeSecondaryMax, false),
          basisPointsMin,
          displayBasisPointsMin: formatPercentage({ percentage: basisPointsMin }),
          basisPointsMax,
          displayBasisPointsMax: formatPercentage({ percentage: basisPointsMax }),
          basisPointsString: formatDisplayString(basisPointsMin, basisPointsMax, true),
          cardCrossBorderBasisPointsMin,
          displayCardCrossBorderBasisPointsMin: formatPercentage({ percentage: cardCrossBorderBasisPointsMin }),
          cardCrossBorderBasisPointsMax,
          displayCardCrossBorderBasisPointsMax: formatPercentage({ percentage: cardCrossBorderBasisPointsMax }),
          cardCrossBorderBasisPointsString: formatDisplayString(cardCrossBorderBasisPointsMin, cardCrossBorderBasisPointsMax, true),
          cardCrossBorderFixedFeeMin,
          displayCardCrossBorderFixedFeeMin: formatMoney({ amount: cardCrossBorderFixedFeeMin }),
          cardCrossBorderFixedFeeMax,
          displayCardCrossBorderFixedFeeMax: formatMoney({ amount: cardCrossBorderFixedFeeMax }),
          cardCrossBorderFixedFeeString: formatDisplayString(cardCrossBorderFixedFeeMin, cardCrossBorderFixedFeeMax, false),
          dinersClubBasisPointsMin,
          displayDinersClubBasisPointsMin: formatPercentage({ percentage: dinersClubBasisPointsMin }),
          dinersClubBasisPointsMax,
          displayDinersClubBasisPointsMax: formatPercentage({ percentage: dinersClubBasisPointsMax }),
          dinersClubBasisPointsString: formatDisplayString(dinersClubBasisPointsMin, dinersClubBasisPointsMax, true),
          dinersClubFixedFeeMin,
          displayDinersClubFixedFeeMin: formatMoney({ amount: dinersClubFixedFeeMin }),
          dinersClubFixedFeeMax,
          displayDinersClubFixedFeeMax: formatMoney({ amount: dinersClubFixedFeeMax }),
          dinersClubFixedFeeString: formatDisplayString(dinersClubFixedFeeMin, dinersClubFixedFeeMax, false),
          discoverAssessmentsBasisPointsMin,
          displayDiscoverAssessmentsBasisPointsMin: formatPercentage({ percentage: discoverAssessmentsBasisPointsMin }),
          discoverAssessmentsBasisPointsMax,
          displayDiscoverAssessmentsBasisPointsMax: formatPercentage({ percentage: discoverAssessmentsBasisPointsMax }),
          discoverAssessmentsBasisPointsString: formatDisplayString(discoverAssessmentsBasisPointsMin, discoverAssessmentsBasisPointsMax, true),
          discoverBasisPointsMin,
          displayDiscoverBasisPointsMin: formatPercentage({ percentage: discoverBasisPointsMin }),
          discoverBasisPointsMax,
          displayDiscoverBasisPointsMax: formatPercentage({ percentage: discoverBasisPointsMax }),
          discoverBasisPointsString: formatDisplayString(discoverBasisPointsMin, discoverBasisPointsMax, true),
          discoverDataUsageFixedFeeMin,
          displayDiscoverDataUsageFixedFeeMin: formatMoney({ amount: discoverDataUsageFixedFeeMin }),
          discoverDataUsageFixedFeeMax,
          displayDiscoverDataUsageFixedFeeMax: formatMoney({ amount: discoverDataUsageFixedFeeMax }),
          discoverDataUsageFixedFeeString: formatDisplayString(discoverDataUsageFixedFeeMin, discoverDataUsageFixedFeeMax, false),
          discoverFixedFeeMin,
          displayDiscoverFixedFeeMin: formatMoney({ amount: discoverFixedFeeMin }),
          discoverFixedFeeMax,
          displayDiscoverFixedFeeMax: formatMoney({ amount: discoverFixedFeeMax }),
          discoverFixedFeeString: formatDisplayString(discoverFixedFeeMin, discoverFixedFeeMax, false),
          discoverExternallyFundedBasisPointsMin,
          displayDiscoverExternallyFundedBasisPointsMin: formatPercentage({ percentage: discoverExternallyFundedBasisPointsMin }),
          discoverExternallyFundedBasisPointsMax,
          displayDiscoverExternallyFundedBasisPointsMax: formatPercentage({ percentage: discoverExternallyFundedBasisPointsMax }),
          discoverExternallyFundedBasisPointsString: formatDisplayString(discoverExternallyFundedBasisPointsMin, discoverExternallyFundedBasisPointsMax, true),
          discoverExternallyFundedFixedFeeMin,
          displayDiscoverExternallyFundedFixedFeeMin: formatMoney({ amount: discoverExternallyFundedFixedFeeMin }),
          discoverExternallyFundedFixedFeeMax,
          displayDiscoverExternallyFundedFixedFeeMax: formatMoney({ amount: discoverExternallyFundedFixedFeeMax }),
          discoverExternallyFundedFixedFeeString: formatDisplayString(discoverExternallyFundedFixedFeeMin, discoverExternallyFundedFixedFeeMax, false),
          discoverNetworkAuthorizationFixedFeeMin,
          displayDiscoverNetworkAuthorizationFixedFeeMin: formatMoney({ amount: discoverNetworkAuthorizationFixedFeeMin }),
          discoverNetworkAuthorizationFixedFeeMax,
          displayDiscoverNetworkAuthorizationFixedFeeMax: formatMoney({ amount: discoverNetworkAuthorizationFixedFeeMax }),
          discoverNetworkAuthorizationFixedFeeString: formatDisplayString(discoverNetworkAuthorizationFixedFeeMin, discoverNetworkAuthorizationFixedFeeMax, false),
          disputeFixedFeeMin,
          displayDisputeFixedFeeMin: formatMoney({ amount: disputeFixedFeeMin }),
          disputeFixedFeeMax,
          displayDisputeFixedFeeMax: formatMoney({ amount: disputeFixedFeeMax }),
          disputeFixedFeeString: formatDisplayString(disputeFixedFeeMin, disputeFixedFeeMax, false),
          disputeInquiryFixedFeeMin,
          displayDisputeInquiryFixedFeeMin: formatMoney({ amount: disputeInquiryFixedFeeMin }),
          disputeInquiryFixedFeeMax,
          displayDisputeInquiryFixedFeeMax: formatMoney({ amount: disputeInquiryFixedFeeMax }),
          disputeInquiryFixedFeeString: formatDisplayString(disputeInquiryFixedFeeMin, disputeInquiryFixedFeeMax, false),
          externallyFundedBasisPointsMin,
          displayExternallyFundedBasisPointsMin: formatPercentage({ percentage: externallyFundedBasisPointsMin }),
          externallyFundedBasisPointsMax,
          displayExternallyFundedBasisPointsMax: formatPercentage({ percentage: externallyFundedBasisPointsMax }),
          externallyFundedBasisPointsString: formatDisplayString(externallyFundedBasisPointsMin, externallyFundedBasisPointsMax, true),
          externallyFundedFixedFeeMin,
          displayExternallyFundedFixedFeeMin: formatMoney({ amount: externallyFundedFixedFeeMin }),
          externallyFundedFixedFeeMax,
          displayExternallyFundedFixedFeeMax: formatMoney({ amount: externallyFundedFixedFeeMax }),
          externallyFundedFixedFeeString: formatDisplayString(externallyFundedFixedFeeMin, externallyFundedFixedFeeMax, false),
          fixedFeeMin,
          displayFixedFeeMin: formatMoney({ amount: fixedFeeMin }),
          fixedFeeMax,
          displayFixedFeeMax: formatMoney({ amount: fixedFeeMax }),
          fixedFeeString: formatDisplayString(fixedFeeMin, fixedFeeMax, false),
          jcbBasisPointsMin,
          displayJcbBasisPointsMin: formatPercentage({ percentage: jcbBasisPointsMin }),
          jcbBasisPointsMax,
          displayJcbBasisPointsMax: formatPercentage({ percentage: jcbBasisPointsMax }),
          jcbBasisPointsString: formatDisplayString(jcbBasisPointsMin, jcbBasisPointsMax, true),
          jcbFixedFeeMin,
          displayJcbFixedFeeMin: formatMoney({ amount: jcbFixedFeeMin }),
          jcbFixedFeeMax,
          displayJcbFixedFeeMax: formatMoney({ amount: jcbFixedFeeMax }),
          jcbFixedFeeString: formatDisplayString(jcbFixedFeeMin, jcbFixedFeeMax, false),
          mastercardAcquirerFeesBasisPointsMin,
          displayMastercardAcquirerFeesBasisPointsMin: formatPercentage({ percentage: mastercardAcquirerFeesBasisPointsMin }),
          mastercardAcquirerFeesBasisPointsMax,
          displayMastercardAcquirerFeesBasisPointsMax: formatPercentage({ percentage: mastercardAcquirerFeesBasisPointsMax }),
          mastercardAcquirerFeesBasisPointsString: formatDisplayString(mastercardAcquirerFeesBasisPointsMin, mastercardAcquirerFeesBasisPointsMax, true),
          mastercardAssessmentsOver1kBasisPointsMin,
          displayMastercardAssessmentsOver1kBasisPointsMin: formatPercentage({ percentage: mastercardAssessmentsOver1kBasisPointsMin }),
          mastercardAssessmentsOver1kBasisPointsMax,
          displayMastercardAssessmentsOver1kBasisPointsMax: formatPercentage({ percentage: mastercardAssessmentsOver1kBasisPointsMax }),
          mastercardAssessmentsOver1kBasisPointsString: formatDisplayString(mastercardAssessmentsOver1kBasisPointsMin, mastercardAssessmentsOver1kBasisPointsMax, true),
          mastercardAssessmentsUnder1kBasisPointsMin,
          displayMastercardAssessmentsUnder1kBasisPointsMin: formatPercentage({ percentage: mastercardAssessmentsUnder1kBasisPointsMin }),
          mastercardAssessmentsUnder1kBasisPointsMax,
          displayMastercardAssessmentsUnder1kBasisPointsMax: formatPercentage({ percentage: mastercardAssessmentsUnder1kBasisPointsMax }),
          mastercardAssessmentsUnder1kBasisPointsString: formatDisplayString(mastercardAssessmentsUnder1kBasisPointsMin, mastercardAssessmentsUnder1kBasisPointsMax, true),
          mastercardBasisPointsMin,
          displayMastercardBasisPointsMin: formatPercentage({ percentage: mastercardBasisPointsMin }),
          mastercardBasisPointsMax,
          displayMastercardBasisPointsMax: formatPercentage({ percentage: mastercardBasisPointsMax }),
          mastercardBasisPointsString: formatDisplayString(mastercardBasisPointsMin, mastercardBasisPointsMax, true),
          mastercardFixedFeeMin,
          displayMastercardFixedFeeMin: formatMoney({ amount: mastercardFixedFeeMin }),
          mastercardFixedFeeMax,
          displayMastercardFixedFeeMax: formatMoney({ amount: mastercardFixedFeeMax }),
          mastercardFixedFeeString: formatDisplayString(mastercardFixedFeeMin, mastercardFixedFeeMax, false),
          visaAcquirerProcessingFixedFeeMin,
          displayVisaAcquirerProcessingFixedFeeMin: formatMoney({ amount: visaAcquirerProcessingFixedFeeMin }),
          visaAcquirerProcessingFixedFeeMax,
          displayVisaAcquirerProcessingFixedFeeMax: formatMoney({ amount: visaAcquirerProcessingFixedFeeMax }),
          visaAcquirerProcessingFixedFeeString: formatDisplayString(visaAcquirerProcessingFixedFeeMin, visaAcquirerProcessingFixedFeeMax, false),
          visaAssessmentsBasisPointsMin,
          displayVisaAssessmentsBasisPointsMin: formatPercentage({ percentage: visaAssessmentsBasisPointsMin }),
          visaAssessmentsBasisPointsMax,
          displayVisaAssessmentsBasisPointsMax: formatPercentage({ percentage: visaAssessmentsBasisPointsMax }),
          visaAssessmentsBasisPointsString: formatDisplayString(visaAssessmentsBasisPointsMin, visaAssessmentsBasisPointsMax, true),
          visaBaseIICreditVoucherFixedFeeMin,
          displayVisaBaseIICreditVoucherFixedFeeMin: formatMoney({ amount: visaBaseIICreditVoucherFixedFeeMin }),
          visaBaseIICreditVoucherFixedFeeMax,
          displayVisaBaseIICreditVoucherFixedFeeMax: formatMoney({ amount: visaBaseIICreditVoucherFixedFeeMax }),
          visaBaseIICreditVoucherFixedFeeString: formatDisplayString(visaBaseIICreditVoucherFixedFeeMin, visaBaseIICreditVoucherFixedFeeMax, false),
          visaBaseIISystemFileTransmissionFixedFeeMin,
          displayVisaBaseIISystemFileTransmissionFixedFeeMin: formatMoney({ amount: visaBaseIISystemFileTransmissionFixedFeeMin }),
          visaBaseIISystemFileTransmissionFixedFeeMax,
          displayVisaBaseIISystemFileTransmissionFixedFeeMax: formatMoney({ amount: visaBaseIISystemFileTransmissionFixedFeeMax }),
          visaBaseIISystemFileTransmissionFixedFeeString: formatDisplayString(visaBaseIISystemFileTransmissionFixedFeeMin, visaBaseIISystemFileTransmissionFixedFeeMax, false),
          visaBasisPointsMin,
          displayVisaBasisPointsMin: formatPercentage({ percentage: visaBasisPointsMin }),
          visaBasisPointsMax,
          displayVisaBasisPointsMax: formatPercentage({ percentage: visaBasisPointsMax }),
          visaBasisPointsString: formatDisplayString(visaBasisPointsMin, visaBasisPointsMax, true),
          visaCreditVoucherFixedFeeMin,
          displayVisaCreditVoucherFixedFeeMin: formatMoney({ amount: visaCreditVoucherFixedFeeMin }),
          visaCreditVoucherFixedFeeMax,
          displayVisaCreditVoucherFixedFeeMax: formatMoney({ amount: visaCreditVoucherFixedFeeMax }),
          visaCreditVoucherFixedFeeString: formatDisplayString(visaCreditVoucherFixedFeeMin, visaCreditVoucherFixedFeeMax, false),
          visaFixedFeeMin,
          displayVisaFixedFeeMin: formatMoney({ amount: visaFixedFeeMin }),
          visaFixedFeeMax,
          displayVisaFixedFeeMax: formatMoney({ amount: visaFixedFeeMax }),
          visaFixedFeeString: formatDisplayString(visaFixedFeeMin, visaFixedFeeMax, false),
          visaKilobyteAccessFixedFeeMin,
          displayVisaKilobyteAccessFixedFeeMin: formatMoney({ amount: visaKilobyteAccessFixedFeeMin }),
          visaKilobyteAccessFixedFeeMax,
          displayVisaKilobyteAccessFixedFeeMax: formatMoney({ amount: visaKilobyteAccessFixedFeeMax }),
          visaKilobyteAccessFixedFeeString: formatDisplayString(visaKilobyteAccessFixedFeeMin, visaKilobyteAccessFixedFeeMax, false),
          allowCardBrandOverrides,
          allowChargebackFees,
        }),
      },
    })
  }, {})

  return {
    entityId,
    entityType,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    ...rulesModels,
  }
}

export default frontendFeeProfileRuleModel
