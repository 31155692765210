import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInstrumentInfo from './PaymentInstrumentInfo'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT_PAYMENT_INSTRUMENT } from 'constants/modalConstants'
import { EDIT_LIGHT } from 'constants/iconConstants'
import { EDIT } from 'constants/language/languageConstants'
import { PATCH_PAYMENT_INSTRUMENT_F_REQUEST } from 'constants/flowConstants'
import { AUDIT_LOG_EVENT_PATH } from 'constants/pathConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import showModalAction from 'utilities/actions/showModalAction'
import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  isDisbursementsPartnerDashboard,
  isStandaloneMerchantDashboard,
} from 'utilities/is/isDashboardType'

import {
  getIdentitySelector,
  getPaymentInstrumentSelector,
} from 'state-layer/selectors'

import {
  SYSTEM,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentInstrument: getPaymentInstrumentRequest,
    showEditPaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PAYMENT_INSTRUMENT, modalProps })),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isFetching = get(state, `paymentInstrumentsR.${FETCHING}`)
  const paymentInstrumentId = get(props, 'paymentInstrumentId')
  const identityId = get(props, 'identityId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isDisbursements = isDisbursementsPartnerDashboard(state)
  const auditLogEventPath = AUDIT_LOG_EVENT_PATH({ credentialId, identityId })
  const { tags } = paymentInstrument
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))
  const buyerIdentity = getIdentitySelector(state, identityId)

  const [
    dashboardUserRoleName,
    dashboardUserRoleType,
  ] = getMany(credentials, [
    'dashboardUserRoleName',
    'dashboardUserRoleType',
  ])

  const hasUnderwritingPermissions = isFlexPlatform() && isRolePlatform({ credentials }) && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName)

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    resource_id: paymentInstrumentId,
    type: 'INSTRUMENT_CREATED',
  }

  return {
    isFetching,
    paymentInstrumentId,
    paymentInstrument,
    credentials,
    tagsSectionData,
    buyerIdentity,
    isDisbursements,
    auditLogEventPath,
    initialQueries,
    hasUnderwritingPermissions,
  }
}

class PaymentInstrumentInfoC extends Component {
  componentDidMount() {
    const {
      credentials,
      paymentInstrumentId,
      getPaymentInstrument,
    } = this.props

    getPaymentInstrument({ paymentInstrumentId, credentials })
  }

  render() {
    const {
      paymentInstrument,
      credentials,
      showEditPaymentInstrumentModal,
    } = this.props

    const actions = [
      {
        label: EDIT,
        buttonClassName: 'edit-payment-instrument-button',
        action: () => {
          showEditPaymentInstrumentModal({
            paymentInstrument,
            actionType: PATCH_PAYMENT_INSTRUMENT_F_REQUEST,
            credentials,
          })
        },
        icon: EDIT_LIGHT,
      },
    ]

    return (
      <PaymentInstrumentInfo
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInstrumentInfoC)
