import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATCH_ROLE_F_REQUEST } from 'constants/flowConstants'

const patchCustomRoleRequest = ({
  credentials,
  roleId,
  roleName,
  values,
  amplitudeActionType,
  showErrors = true,
  hideFlashNotification = false,
  successCallback,
}) => createAction({
  type: PATCH_ROLE_F_REQUEST,
  id: roleId,
  credentials,
  values,
  meta: {
    roleName,
    successCallback,
    showErrors,
    hideFlashNotification,
    actionId: sendAmplitudeActionEvent(amplitudeActionType, removeUndefined({
      credentials,
      values,
      roleName,
      roleId,
    })),
  },
})

export default patchCustomRoleRequest
