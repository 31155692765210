import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeviceDetails from './DeviceDetails'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getDeviceRequest from 'utilities/actions/get/getDeviceRequest'
import showModalAction from 'utilities/actions/showModalAction'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'

import {
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
  TRIPOS_CLOUD_V1,
  TRIPOS_MOBILE_V1,
} from 'constants/processorConstants'

import {
  CARD_PRESENT_DEVICE_IMAGE_MAP,
  CONNECTION_CLOSED_VALUE,
  CONNECTION_OPEN_VALUE,
} from 'constants/deviceConstants'

import {
  ACTIVATE_DEVICE_MODAL,
  DEACTIVATE_DEVICE_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const device = get(props, 'device')

  const [
    deviceId,
    deviceModel,
    displayDeviceModel,
    deviceStatusEnabled,
    serialNumber,
    deviceName,
    connectionStatus,
  ] = getMany(device, [
    'id',
    'deviceModel',
    'displayDeviceModel',
    'enabled',
    'serialNumber',
    'name',
    'connectionStatus',
  ])

  const merchantData = get(device, 'merchantData')

  const [
    gateway,
    processor,
  ] = getMany(merchantData, [
    'gateway',
    'processor',
  ])

  const deviceImageUrl = CARD_PRESENT_DEVICE_IMAGE_MAP[deviceModel]
  const showSerialNumber = includes([TRIPOS_MOBILE_V1, DATACAP_V1], gateway) || includes([DUMMY_V1, FINIX_V1], processor)

  let canActivateDevice = !isEqual(gateway, TRIPOS_MOBILE_V1) && !deviceStatusEnabled

  if (includes([FINIX_V1, DUMMY_V1], processor) || isEqual(gateway, DATACAP_V1)) {
    canActivateDevice = canActivateDevice && serialNumber
  }

  let activateDeviceModalSubtitle = ''

  if (isEqual(gateway, TRIPOS_CLOUD_V1)) {
    activateDeviceModalSubtitle = `Please enter the activation code shown on ${deviceName}.`
  }

  if ((isEqual(gateway, DATACAP_V1) || includes([FINIX_V1, DUMMY_V1], processor)) && !serialNumber) {
    activateDeviceModalSubtitle = `Please enter the serial number of ${deviceName}.`
  }

  return {
    credentials,
    displayDeviceModel,
    showSerialNumber,
    serialNumber,
    processor,
    deviceImageUrl,
    deviceName,
    deviceStatusEnabled,
    deviceId,
    gateway,
    connectionStatus,
    activateDeviceModalSubtitle,
    canActivateDevice,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkDeviceConnection: ({ deviceId, deviceName, credentials }) => dispatch(getDeviceRequest({ deviceId, credentials, queries: { include_connection: true }, meta: { successMessage: `${deviceName} is online.`, errorMessage: `${deviceName} is offline.` } })),
    showActivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: ACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-md no-pad' })),
    showDeactivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class DeviceDetailsC extends Component {
  render() {
    const {
      credentials,
      deviceId,
      deviceName,
      deviceStatusEnabled,
      gateway,
      showActivateDeviceModal,
      checkDeviceConnection,
      activateDeviceModalSubtitle,
      processor,
      connectionStatus,
      serialNumber,
      canActivateDevice,
    } = this.props

    const deviceStatusMessageMap = {
      true: `${deviceName} is enabled for processing transactions.`,
      false: <span>{deviceName} is not active. {canActivateDevice && (<><span className='activate-device-link text-link' onClick={() => showActivateDeviceModal({ deviceId, deviceName, deviceStatusEnabled, gateway, activateDeviceModalSubtitle, processor, serialNumber })}> Activate the device</span> to start processing transactions.</>)}</span>,
      Open: <span>{deviceName} is enabled for processing transactions and is online.</span>,
      Closed: <span>{deviceName} is currently offline. <span className='check-connection-link text-link' onClick={() => checkDeviceConnection({ deviceId, deviceName, credentials })}>Check device connection.</span></span>,
    }

    const deviceStatusClassnamesMap = {
      true: 'device-enabled',
      false: 'device-disabled',
      Open: 'device-connection-open',
      Closed: 'device-connection-closed',
    }

    let deviceStatusMessage = ''
    let className = ''

    if (connectionStatus === CONNECTION_OPEN_VALUE) {
      deviceStatusMessage = deviceStatusMessageMap[connectionStatus]
      className = deviceStatusClassnamesMap[connectionStatus]
    } else if (connectionStatus === CONNECTION_CLOSED_VALUE) {
      deviceStatusMessage = deviceStatusMessageMap[connectionStatus]
      className = deviceStatusClassnamesMap[connectionStatus]
    } else {
      deviceStatusMessage = deviceStatusMessageMap[deviceStatusEnabled]
      className = deviceStatusClassnamesMap[deviceStatusEnabled]
    }

    return (
      <DeviceDetails
        {...this.props}
        deviceStatusMessage={deviceStatusMessage}
        className={className}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsC)
