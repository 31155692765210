import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import IdentityAuthorizations from './IdentityAuthorizations'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { AUTHORIZATIONS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  DATE_QUICK_FILTER,
  AUTHORIZATION_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const quickFilters = [
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter('Authorization'),
  AMOUNT(),
  DEVICE_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  ...TAG_FILTERS,
], 'label')

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `identityAuthorizationsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const authorizationPath = AUTHORIZATIONS_PATH({ credentialId })

  const flowValues = {
    identityId,
  }

  const initialQueries = {
    merchant_identity_id: identityId,
  }

  return {
    identityId,
    flowValues,
    isFetching,
    authorizationPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: createdLastMonthQueries(),
  }
}

class IdentityAuthorizationsC extends Component {
  render() {
    return (
      <IdentityAuthorizations {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityAuthorizationsC)
