import SelectApplicationForm from './SelectApplicationForm'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getApplicationsSelector } from 'state-layer/selectors'
import { GET_APPLICATIONS_F_REQUEST } from 'constants/flowConstants'
import map from 'lodash/map'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const applications = getApplicationsSelector(state)

  const applicationOptions = map(applications, (application) => {
    const {
      id,
      businessName,
    } = application

    return {
      label: `${id} - ${businessName}`,
      value: { id },
    }
  })

  return {
    credentials,
    applications,
    applicationOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // TODO:  look into default of how many records Finix API returns - we may not be showing all of them
    getApplications: ({ credentials }) => dispatch({
      type: GET_APPLICATIONS_F_REQUEST,
      payload: {
        credentials,
      },
    }),
  }
}

class SelectApplicationFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      getApplications,
    } = this.props

    getApplications({ credentials })
  }

  render() {
    return (
      <SelectApplicationForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectApplicationFormC)
