import './MerchantPaymentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Payments/columnDescriptors'
import { MERCHANT_TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getMerchantTransfers } from 'state-layer/selectors'

import {
  GET_MERCHANT_TRANSFERS,
  TRANSFER,
} from 'constants/flowConstants'

const MerchantPayments = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  paymentPath = '',
  isFetching = false,
  merchantId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='MerchantPayments'>
      <TableC
        flow={GET_MERCHANT_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getMerchantTransfers}
        selectorId={merchantId}
        initialQueries={initialQueries}
        linksKey={MERCHANT_TRANSFERS}
        allowedFilters={allowedFilters}
        path={paymentPath}
        isFetching={isFetching}
        fileNameCSV={`Merchant_${merchantId}_Transfers`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={TRANSFER}
      />
    </div>
  )
}

MerchantPayments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default MerchantPayments
