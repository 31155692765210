import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { CREATE_TRANSACTION_CONFIRMATION_PATH } from 'constants/pathConstants'
import { USD } from 'constants/currencyConstants'
import { EMAIL } from 'constants/receiptConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'
import trim from 'lodash/trim'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'

import {
  AUTHORIZATION,
  SALE,
} from 'constants/transferConstants'

import {
  L2_ENABLED,
  L3_ENABLED,
} from 'constants/processorConstants'

import {
  POST_TRANSFER_F_REQUEST,
  POST_AUTHORIZATION_F_REQUEST,
  POST_RECEIPT_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

import {
  CREATE_VIRTUAL_TERMINAL_AUTHORIZATION,
  CREATE_VIRTUAL_TERMINAL_PAYMENT,
} from 'constants/amplitudeConstants'

const submitCreateTransactionReviewForm = (values, dispatch, props) => {
  const {
    credentials,
    merchantId,
    paymentInstrumentId,
    isSale,
    currency = USD,
    currentUser,
  } = props

  const {
    amount,
    description,
    salesTaxAmount,
    customerReferenceNumber,
    destinationPostalCode,
    shipFromPostalCode,
    shippingAmount,
    levelThreeItems,
    totalDiscountAmount,
    totalCustomsDutyAmount,
    additionalDataSelection,
    sendReceiptToBuyer,
    sendReceiptToSelf,
    buyerEmails,
  } = values

  let additionalPurchaseData
  const additionalDataSelectionValue = get(additionalDataSelection, 'value')
  const isLevelTwoEnabled = isEqual(additionalDataSelectionValue, L2_ENABLED)
  const isLevelThreeEnabled = isEqual(additionalDataSelectionValue, L3_ENABLED)

  if (isLevelTwoEnabled) {
    additionalPurchaseData = removeUndefined({
      sales_tax: salesTaxAmount,
      customer_reference_number: customerReferenceNumber ? trim(customerReferenceNumber) : undefined,
    })
  }

  if (isLevelThreeEnabled) {
    const itemData = map(levelThreeItems, ({
      merchantProductCode,
      productDescription,
      costPerUnit,
      discountPerUnit,
      quantity,
      unitOfMeasure,
      totalItemCostExcludingSalesTax,
      totalItemCostIncludingSalesTax,
      commodityCode,
    }) => removeUndefined({
      quantity,
      amount_excluding_sales_tax: totalItemCostExcludingSalesTax,
      amount_including_sales_tax: totalItemCostIncludingSalesTax,
      merchant_product_code: merchantProductCode,
      cost_per_unit: costPerUnit,
      item_discount_amount: discountPerUnit,
      unit_of_measure: get(unitOfMeasure, 'value'),
      commodity_code: commodityCode,
      item_description: productDescription,
    }))

    additionalPurchaseData = removeUndefined({
      customer_reference_number: customerReferenceNumber ? trim(customerReferenceNumber) : undefined,
      customs_duty_amount: totalCustomsDutyAmount,
      shipping_amount: shippingAmount,
      discount_amount: totalDiscountAmount,
      destination_postal_code: destinationPostalCode,
      ship_from_postal_code: shipFromPostalCode,
      item_data: itemData,
    })
  }

  const transferValuesToSubmit = removeUndefined({
    action: isSale ? 'virtualTerminalPayment' : undefined,
    amount,
    currency,
    operationKey: isSale ? SALE : AUTHORIZATION,
    merchantId,
    paymentInstrumentId,
    additionalPurchaseData,
    tags: {
      description: description || '',
    },
  })

  dispatch({
    type: isSale ? POST_TRANSFER_F_REQUEST : POST_AUTHORIZATION_F_REQUEST,
    payload: {
      credentials,
      values: transferValuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues: transferNewValues }) => {
        const entityId = head(keys(transferNewValues))
        const transactionType = isSale ? 'sale' : 'authorization'
        const credentialId = get(credentials, 'id')
        const paymentConfirmationPath = `${CREATE_TRANSACTION_CONFIRMATION_PATH({ credentialId, entityId })}?type=${transactionType}`

        const emails = []
        if (sendReceiptToBuyer && buyerEmails) {
          emails.push(...map(buyerEmails, ({ value }) => value))
        }
        const currentUserEmail = get(currentUser, 'userEmail')
        if (sendReceiptToSelf && currentUserEmail) {
          emails.push(currentUserEmail)
        }

        const sendMethods = []
        if (sendReceiptToBuyer || sendReceiptToSelf) {
          sendMethods.push(EMAIL)
        }

        dispatch({
          type: POST_RECEIPT_F_REQUEST,
          payload: {
            credentials,
            values: {
              ...removeUndefined({
                sendReceiptToBuyer,
                sendReceiptToSelf,
                sendMethods,
                emails,
              }),
              entityId,
            },
          },
          meta: {
            successCallback: ({ newValues: receiptNewValues }) => {
              const receiptId = head(keys(receiptNewValues))
              dispatch(redirectRequest({ path: `${paymentConfirmationPath}&receiptId=${receiptId}` }))
            },
            errorCallback: (errors = []) => {
              dispatch(redirectRequest({ path: paymentConfirmationPath }))
            },
            hideFlashNotification: true,
          },
        })
      },
      showErrors: false,
      errorCallback: (errors = []) => {
        const errorCode = get(errors, '[0].code')

        if (isEqual(errorCode, 'INVALID_BANK_ACCOUNT_VALIDATION_CHECK')) {
          dispatch({
            type: SHOW_ERROR_FLASH_NOTIFICATION,
            payload: {
              values: {
                time: 15000,
                message: 'The bank account failed validation. Please use another bank account.',
              },
            },
          })
        }
      },
      hideFlashNotification: true,
    },
    actionId: sendAmplitudeActionEvent(isSale ? CREATE_VIRTUAL_TERMINAL_PAYMENT : CREATE_VIRTUAL_TERMINAL_AUTHORIZATION, {
      credentials,
      amount,
      currency,
      merchantId,
      paymentInstrumentId,
    }),
  })
}

export default submitCreateTransactionReviewForm
