import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const isMerchantManagerRole = (credentials = getCurrentCredentials()) => {
  const isSystemRole = get(credentials, 'dashboardUserRoleType') === 'SYSTEM'
  const isRoleAdministrator = get(credentials, 'dashboardUserRoleName') === 'Merchant Manager'

  return isSystemRole && isRoleAdministrator
}

export default isMerchantManagerRole
