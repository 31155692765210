import './IdentityProvisionMerchantS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import ProvisionMerchantProcessorFormC from 'components/Customer/Forms/ProvisionMerchantProcessorForm/ProvisionMerchantProcessorFormC'
import Warning from 'components/Shared/Warning/Warning'
import { PROVISION_MERCHANT_PI_WARNING_MSG } from 'constants/language/languageConstants'
import map from 'lodash/map'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

const IdentityProvisionMerchant = ({
  processors = [],
  enabledProcessors = [],
  paymentInstruments = {},
  identity = {},
  credentials = {},
  isFetching = false,
  settlementAliasWarningObject = {},
}) => {
  if (isFetching) {
    return (
      <div className='IdentityProvisionMerchant'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='IdentityProvisionMerchant'>
      { isEmpty(paymentInstruments) && <h3 className='warning'>{PROVISION_MERCHANT_PI_WARNING_MSG}</h3>}
      {
        map(processors, ({ processor, gateway }) => {
          const enabled = includes(enabledProcessors, processor)
          const processorLabel = gateway ? `${processor} - ${gateway}` : processor

          return (
            <div className='processor'>
              <ProvisionMerchantProcessorFormC
                credentials={credentials}
                identity={identity}
                processorLabel={processorLabel}
                processor={processor}
                gateway={gateway}
                paymentInstruments={paymentInstruments}
                enabled={enabled}
              />
            </div>
          )
        })
      }
      { isEmpty(processors) && <h3>This Identity has no processors</h3> }
      <Warning {...settlementAliasWarningObject} />
    </div>
  )
}

IdentityProvisionMerchant.propTypes = {
  processors: PropTypes.array,
  enabledProcessors: PropTypes.array,
  paymentInstruments: PropTypes.object,
  identity: PropTypes.object,
  credentials: PropTypes.object,
  isFetching: PropTypes.bool,
  settlementAliasWarningObject: PropTypes.object,
}

export default IdentityProvisionMerchant
