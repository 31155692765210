import React, { Component } from 'react'
import { connect } from 'react-redux'
import EnablePayoutLinkModal from './EnablePayoutLinkModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import patchPayoutLinkRequest from 'utilities/actions/patch/patchPayoutLinkRequest'
import { ACTIVE } from 'constants/paymentLinkConstants'

const mapStateToProps = (state, props) => {
  return {
    credentials: getCurrentCredentials(state),
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activatePayoutLink: ({ credentials, payoutLinkId, closeModal }) => dispatch(patchPayoutLinkRequest({ payoutLinkId, credentials, values: { state: ACTIVE }, closeModal })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EnablePayoutLinkModalC extends Component {
  enablePayoutLink = () => {
    const {
      credentials,
      payoutLinkId,
      activatePayoutLink,
      closeModal,
    } = this.props

    activatePayoutLink({ credentials, payoutLinkId, closeModal })
  }

  render() {
    return (
      <EnablePayoutLinkModal
        {...this.props}
        enablePayoutLink={this.enablePayoutLink}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablePayoutLinkModalC)
