import frontendPurchaseModel from 'utilities/create/models/frontendPurchaseModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPurchasesModel = ({ data: purchases }) => {
  const purchaseModels = map(purchases, (purchase) => frontendPurchaseModel({ data: purchase }))

  return keyBy(purchaseModels, 'id')
}

export default frontendPurchasesModel
