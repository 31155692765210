import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'
import get from 'lodash/get'

const validateCreateSubscriptionPlanForm = (values, props) => {
  const {
    planName,
    amount,
    billingInterval,
    enableTrialPhase,
    trialIntervalType,
    trialIntervalCount,
  } = values

  return {
    planName: fieldEmpty(planName, 'Plan Name'),
    amount: nonEmptyNumber({ field: amount, name: 'Recurring Price' }),
    billingInterval: fieldEmpty(get(billingInterval, 'value'), 'Billing Interval'),
    trialIntervalCount: enableTrialPhase ? fieldEmpty(trialIntervalCount, 'Trial Count') : false,
    trialIntervalType: enableTrialPhase ? fieldEmpty(get(trialIntervalType, 'value')) : false,
  }
}

export default validateCreateSubscriptionPlanForm
