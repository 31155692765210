import './HostedMerchantOnboardingFlowS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const HostedMerchantOnboardingFlow = ({
  layout,
  steps,
}) => {
  return (
    <div className='HostedMerchantOnboardingFlow'>
      <FlowsC
        flowClassName='new-hosted-merchant'
        layout={layout}
        steps={steps}
      />
    </div>
  )
}

HostedMerchantOnboardingFlow.propTypes = {
  layout: PropTypes.string,
  steps: PropTypes.array,
}

export default HostedMerchantOnboardingFlow
