import get from 'lodash/get'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { IDENTITY_PATH } from 'constants/pathConstants'
import { CREATE_IDENTITY_VERIFICATION } from 'constants/amplitudeConstants'
import { POST_IDENTITY_VERIFICATIONS_F_REQUEST } from 'constants/flowConstants'

const submitIdentityVerificationForm = (values, dispatch, { credentials, redirectToVerificationsTab = false, identityId }) => {
  const credentialId = get(credentials, 'id')
  const identity = get(values, 'identity')

  dispatch({
    type: POST_IDENTITY_VERIFICATIONS_F_REQUEST,
    payload: {
      values,
      credentials,
    },
    meta: {
      selectorId: identity,
      actionId: sendAmplitudeActionEvent(CREATE_IDENTITY_VERIFICATION, {
        credentials,
        identityId: identity,
      }),
      successCallback: () => {
        if (redirectToVerificationsTab) {
          const identityVerificationsPath = `${IDENTITY_PATH({ credentialId, identityId })}?tab=verifications`

          dispatch(redirectRequest({ path: identityVerificationsPath }))
        }
      },
    },
  })
}

export default submitIdentityVerificationForm
