import { fieldEmpty } from 'utilities/validate'

const validateConfirmPasswordMFAForm = ({
  password,
}) => {
  return {
    password: fieldEmpty(password, 'Password'),
  }
}

export default validateConfirmPasswordMFAForm
