import './MerchantUnderwritingInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatNumber from 'utilities/formatters/formatNumber'
import formatPercentage from 'utilities/formatters/formatPercentage'

import {
  MERCHANT_UNDERWRITING_INSIGHTS,
  MERCHANT_UNDERWRITING_SUBTITLE,
  TIME_FRAME,
  SUMMARY,
  TOTAL_MERCHANT_COUNT,
  AUTOMATIC_APPROVAL_RATE,
  MERCHANTS,
  OVERALL_APPROVAL_RATE,
  TIME_IN_MANUAL_REVIEW,
  TO_FINAL_DECISION,
  MEDIAN,
  UNDERWRITING_TRENDS,
  UNDERWRITING_INFORMATION_BY_APPLICATION,
  UNDERWRITING_INFORMATION_BY_MERCHANT_BUSINESS_TYPE,
  ADDITIONAL_MERCHANT_INFORMATION,
  OVERALL_TRENDS,
  MANUAL_REVIEW_REASONS,
  IDENTITY_VERIFICATION,
  COMPLIANCE_CHECKS,
  MERCHANT_UNDERWRITING_INSIGHTS_SUMMARY_TOOLTIP_TITLE,
  UNDERWRITING_TRENDS_TOOLTIP,
  MANUAL_REVIEW_REASONS_SUMMARY_TOOLTIP,
  MANUAL_REVIEW_REASONS_IDENTITY_VERIFICATION_TOOLTIP,
  MANUAL_REVIEW_REASONS_COMPLIANCE_CHECKS_TOOLTIP,
  UNDERWRITING_INFORMATION_BY_APPLICATION_TOOLTIP,
  ADDITIONAL_MERCHANT_INFORMATION_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  TOTAL_MERCHANTS_BIG_NUMBER_TOTAL,
  AUTOMATIC_APPROVAL_RATE_BIG_NUMBER_TOTAL,
  TOTAL_APPROVAL_RATE_BIG_NUMBER_TOTAL,
  AVG_TIME_IN_MANUAL_REVIEW_BIG_NUMBER_TOTAL,
  TOTAL_MERCHANT_COUNT_BY_UNDERWRITING_STATUS_ECHARTS_TIMESERIES_BAR,
  APPROVAL_RATES_ECHARTS_TIMESERIES_LINE,
  ALL_APPLICATIONS_TABLE,
  BUSINESS_TYPES_TABLE,
  MERCHANT_COUNT_BY_NUMBER_OF_BUSINESS_OWNERS_PIE,
  MERCHANT_COUNT_BY_BUSINESS_OWNERSHIP_TYPE_PIE,
  IDENTITY_SCORE_RESULTS_ECHARTS_TIMESERIES_BAR,
  COMPLIANCE_SCORES_RESULTS_ECHARTS_TIMESERIES_BAR,
  KYC_RESULTS_ECHARTS_TIMESERIES_BAR,
  KYB_RESULTS_ECHARTS_TIMESERIES_BAR,
  BUSINESS_OFAC_RESULTS_ECARTS_TIMESERIES_BAR,
  OWNER_OFAC_RESULTS_ECHARTS_TIMESERIES_BAR,
} from 'constants/chartConstants'

const MerchantUnderwritingInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRolePlatformCredential = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <>
      {/* TODO: remove header data here since used in UnderwritingInsightsTabs.jsx once other tabs are ready */}
      <HeaderV2C
        title={MERCHANT_UNDERWRITING_INSIGHTS}
        subTitle={MERCHANT_UNDERWRITING_SUBTITLE}
      />

      <div className='MerchantUnderwritingInsights flex column'>
        <div className='time-frame-container flex space-between items-center'>
          <div className='time-frame flex'>
            <div className='time-frame-title'>{TIME_FRAME}</div>
            <div className='time-frame-content'>
              <DatePickerNew
                name='created_at'
                initialFilters={createdAtFilters}
                minDate={new Date('March 1, 2023')}
              />
            </div>
          </div>

          <div className='last-updated'><LastUpdatedAtC TOTAL_MERCHANTS_BIG_NUMBER_TOTAL /></div>
        </div>

        <div className='content-padding'>
          <div className='flex summary items-center'>
            <h4>{SUMMARY}</h4>
            <TooltipLabelC
              position='right'
              message={(
                <>
                  <p>{MERCHANT_UNDERWRITING_INSIGHTS_SUMMARY_TOOLTIP_TITLE}</p>
                  <p><span className='bold'>{TOTAL_MERCHANT_COUNT}</span> represents the total number of merchants sent to underwriting.</p>
                  <p><span className='bold'>{AUTOMATIC_APPROVAL_RATE}</span> represents the percentage of merchants that were automatically approved.</p>
                  <p><span className='bold'>{OVERALL_APPROVAL_RATE}</span> represents the percentage of merchants that were approved automatically and manually.</p>
                  <p><span className='bold'>{TIME_IN_MANUAL_REVIEW}</span> represents the median amount of time in minutes it took to manually approve or reject merchants (excludes Merchants submitted outside business hours).</p>
                </>
            )}
            />
          </div>

          <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
            <CustomBigNumberC
              title={TOTAL_MERCHANT_COUNT}
              headerData={{
                name: TOTAL_MERCHANTS_BIG_NUMBER_TOTAL,
              }}
              subHeaderData={{
                formatter: () => MERCHANTS,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />

            <CustomBigNumberC
              title={AUTOMATIC_APPROVAL_RATE}
              headerData={{
                name: AUTOMATIC_APPROVAL_RATE_BIG_NUMBER_TOTAL,
                formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
              }}
              subHeaderData={{
                name: TOTAL_MERCHANTS_BIG_NUMBER_TOTAL,
                formatter: (count) => `of ${formatNumber(count)} merchants`,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />

            <CustomBigNumberC
              title={OVERALL_APPROVAL_RATE}
              headerData={{
                name: TOTAL_APPROVAL_RATE_BIG_NUMBER_TOTAL,
                formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
              }}
              subHeaderData={{
                name: TOTAL_MERCHANTS_BIG_NUMBER_TOTAL,
                formatter: (count) => `of ${formatNumber(count)} merchants`,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />

            <CustomBigNumberC
              title={TIME_IN_MANUAL_REVIEW}
              headerData={{
                name: AVG_TIME_IN_MANUAL_REVIEW_BIG_NUMBER_TOTAL,
              }}
              subHeaderData={{
                formatter: () => `${TO_FINAL_DECISION} (${MEDIAN})`,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />
          </div>

          <GroupedCharts
            title={UNDERWRITING_TRENDS}
            subtitle={OVERALL_TRENDS}
            classNames='underwriting-trends overall-trends'
            chartClassNames='underwriting-trends-charts grid grid-cols-2 md:grid-cols-1'
            timeRange={timeRange}
            timeGrainFilters={[
              { name: 'Daily', value: 'daily' },
              { name: 'Weekly', value: 'weekly', active: true },
              { name: 'Monthly', value: 'monthly' },
            ]}
            chartsWithSubtitles={[
              {
                subtitle: OVERALL_TRENDS,
                subtitleTooltipMessage: UNDERWRITING_TRENDS_TOOLTIP,
                charts: [
                  {
                    name: TOTAL_MERCHANT_COUNT_BY_UNDERWRITING_STATUS_ECHARTS_TIMESERIES_BAR,
                    height: 260,
                  },
                  {
                    name: APPROVAL_RATES_ECHARTS_TIMESERIES_LINE,
                    height: 260,
                  },
                ],
              },
              {
                subtitle: `${MANUAL_REVIEW_REASONS} - ${SUMMARY}`,
                subtitleTooltipMessage: MANUAL_REVIEW_REASONS_SUMMARY_TOOLTIP,
                charts: [
                  { name: IDENTITY_SCORE_RESULTS_ECHARTS_TIMESERIES_BAR, height: 260 },
                  { name: COMPLIANCE_SCORES_RESULTS_ECHARTS_TIMESERIES_BAR, height: 260 },
                ],
              },
              {
                subtitle: `${MANUAL_REVIEW_REASONS} - ${IDENTITY_VERIFICATION}`,
                subtitleTooltipMessage: MANUAL_REVIEW_REASONS_IDENTITY_VERIFICATION_TOOLTIP,
                charts: [
                  { name: KYC_RESULTS_ECHARTS_TIMESERIES_BAR, height: 260 },
                  { name: KYB_RESULTS_ECHARTS_TIMESERIES_BAR, height: 260 },
                ],
              },
              {
                subtitle: `${MANUAL_REVIEW_REASONS} - ${COMPLIANCE_CHECKS}`,
                subtitleTooltipMessage: MANUAL_REVIEW_REASONS_COMPLIANCE_CHECKS_TOOLTIP,
                charts: [
                  { name: BUSINESS_OFAC_RESULTS_ECARTS_TIMESERIES_BAR, height: 260 },
                  { name: OWNER_OFAC_RESULTS_ECHARTS_TIMESERIES_BAR, height: 260 },
                ],
              },
            ]}
          />

          {/* TODO: product decision to not be apart of MVP release  */}
          {/* <div className='mtl'> */}
          {/*   <ChartC */}
          {/*     title={UNDERWRITING_INFORMATION_BY_MERCHANT} */}
          {/*     name={ALL_MERCHANTS_TABLE} */}
          {/*     height={484} */}
          {/*     className='table' */}
          {/*     timeRange={timeRange} */}
          {/*     hideTitle */}
          {/*     showGroupBy */}
          {/*     showPivots */}
          {/*   /> */}
          {/* </div> */}

          <div className='mtl'>
            <ChartC
              title={UNDERWRITING_INFORMATION_BY_APPLICATION}
              tooltipMessage={UNDERWRITING_INFORMATION_BY_APPLICATION_TOOLTIP}
              name={ALL_APPLICATIONS_TABLE}
              height={280}
              className='table'
              timeRange={timeRange}
              showGroupBy
              hideTitle
            />
          </div>

          <div className='mtl'>
            <ChartC
              title={UNDERWRITING_INFORMATION_BY_MERCHANT_BUSINESS_TYPE}
              name={BUSINESS_TYPES_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              showGroupBy
              hideTitle
            />
          </div>

          <GroupedCharts
            title={ADDITIONAL_MERCHANT_INFORMATION}
            tooltipMessage={ADDITIONAL_MERCHANT_INFORMATION_TOOLTIP}
            classNames='underwriting-trends'
            chartClassNames='dispute-trends-charts grid grid-cols-2 md:grid-cols-1'
            timeRange={timeRange}
            charts={[
              { name: MERCHANT_COUNT_BY_NUMBER_OF_BUSINESS_OWNERS_PIE, height: 260 },
              { name: MERCHANT_COUNT_BY_BUSINESS_OWNERSHIP_TYPE_PIE, height: 260 },
            ]}
          />
        </div>
      </div>
    </>
  )
}


MerchantUnderwritingInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.string,
  isRolePlatformCredential: PropTypes.bool,
}

export default MerchantUnderwritingInsights
