import removeUndefined from 'utilities/remove/removeUndefined'

const backendSubscriptionModel = ({ values }) => {
  const {
    amount,
    currency,
    linkedTo,
    linkedType,
    nickname,
    billingInterval,
    collectionMethod,
    sendInvoice,
    sendReceipt,
    trialIntervalType,
    trialIntervalCount,
    buyerIdentityId,
    buyerInstrumentId,
    enableTrialPhase,
    subscriptionPlanId,
  } = values

  return removeUndefined({
    amount,
    currency,
    linked_to: linkedTo,
    linked_type: linkedType,
    nickname,
    billing_interval: billingInterval,
    subscription_plan_id: subscriptionPlanId,
    subscription_details: collectionMethod ? {
      collection_method: collectionMethod,
      send_invoice: sendInvoice,
      send_receipt: sendReceipt,
      trial_details: enableTrialPhase ? { interval_type: trialIntervalType, interval_count: trialIntervalCount } : undefined,
    } : undefined,
    buyer_details: {
      identity_id: buyerIdentityId,
      instrument_id: buyerInstrumentId,
    },
  })
}

export default backendSubscriptionModel
