import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantDepositPreviewPanel from './MerchantDepositPreviewPanel'
import { getTransferWithRelatedSelector } from 'state-layer/selectors'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import getMany from 'utilities/get/getMany'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  CREATED_ON,
  DEPOSIT,
  DEPOSIT_TYPE,
  MERCHANT_DEPOSIT_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  PAYOUT_ACCOUNT,
  SETTLEMENT_RESOURCE_TITLE,
  STATE,
  TRACE_ID,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const transferId = get(props, 'resourceId')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  const [
    traceId,
    applicationId,
    applicationBusinessName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    settlement,
    displayAmount,
    currency,
    transferState,
    displayType,
    displayCreatedAt,
    paymentInstrument,
    tags,
  ] = getMany(transfer, [
    'traceId',
    'application.id',
    'application.businessName',
    'merchantIdentity.id',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'settlement',
    'displayAmount',
    'currency',
    'state',
    'displayType',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
  ])

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })
  const settlementId = get(settlement, 'id')
  const headerResourceTitle = isStandaloneMerchant ? DEPOSIT : MERCHANT_DEPOSIT_RESOURCE_TITLE
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const [
    paymentInstrumentId,
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentBuyerName,
    transactionType,
  ] = getMany(paymentInstrument, [
    'id',
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'name',
    'type',
  ])

  const panelContextBarData = [
    {
      label: APPLICATION_RESOURCE_TITLE,
      value: applicationBusinessName,
      path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      condition: !isStandaloneMerchant,
    },
    {
      label: MERCHANT_IDENTITY_RESOURCE_TITLE,
      value: merchantBusinessName,
      path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
      condition: !isStandaloneMerchant,
    },
    {
      label: SETTLEMENT_RESOURCE_TITLE,
      value: settlementId,
      path: SETTLEMENT_PATH({ credentialId, settlementId }),
    },
  ]

  const headerData = {
    resource: {
      label: headerResourceTitle,
      id: transferId,
      clickToCopyPosition: 'default',
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
    ],
  }

  const merchantDepositDetailsData = convertPageSectionDataToV2([
    {
      label: STATE,
      value: <TransferStatus value={transferState} />,
    },
    {
      label: DEPOSIT_TYPE,
      value: displayType,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: PAYOUT_ACCOUNT,
      value: displayBuyerPaymentInstrumentLink({
        type: transactionType,
        credentialId,
        paymentInstrument,
        paymentInstrumentId,
        maskedAccountNumber,
        maskedFullCardNumber,
        paymentInstrumentBuyerName,
      }),
      condition: !!paymentInstrumentId,
    },
  ], 1)

  return {
    isFetching,
    credentials,
    transferId,
    transfer,
    panelContextBarData,
    headerData,
    merchantDepositDetailsData,
    paymentInstrument,
    tagsSectionData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
  }
}

class MerchantDepositPreviewPanelC extends Component {
  componentDidMount() {
    const {
      credentials,
      transferId,
      getTransfer,
    } = this.props

    if (transferId) {
      getTransfer({ credentials, transferId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      transferId,
      getTransfer,
    } = this.props

    const { transferId: prevTransferId } = prevProps

    if (transferId !== prevTransferId) {
      getTransfer({ credentials, transferId })
    }
  }


  render() {
    return (
      <MerchantDepositPreviewPanel {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDepositPreviewPanelC)
