import './MapS.scss'
import React, { useState, useEffect } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import PropTypes from 'prop-types'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Loader from 'components/Shared/Loader/Loader'
import { GOOGLE_MAP_API_KEY } from 'constants/googleMapConstants'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  compareTwoStrings,
  findBestMatch,
} from 'string-similarity'

const MapC = ({
  title = 'Map Information',
  address,
  google,
  mapStyles = { height: '500px', width: '500px' },
  mapType = 'roadmap',
  allowGeocode = false,
  zoom = 18,
}) => {
  const [latLng, setLatLng] = useState({ lat: null, lng: null })
  const [mapLoaded, setMapLoaded] = useState(false)
  const [place, setPlace] = useState({})

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: GOOGLE_MAP_API_KEY,
    debounce: 500,
  })

  useEffect(() => {
    getPlacePredictions({
      input: address,
      types: allowGeocode ? ['establishment', 'geocode'] : ['establishment'],
    })
  }, [])

  useEffect(() => {
    if (placePredictions && placePredictions.length > 0) {
      const placeDescriptions = map(placePredictions, ({ description }) => toLower(description))
      const bestMatch = findBestMatch(toLower(address), placeDescriptions)
      const matchComparison = compareTwoStrings(toLower(address), toLower(bestMatch.bestMatch.target))
      const bestMatchIndex = get(bestMatch, 'bestMatchIndex', 0)

      if (matchComparison > 0.5) {
        const placeId = get(placePredictions, `[${bestMatchIndex}].place_id`)

        placesService.getDetails({ placeId }, (placeDetails) => {
          const lat = get(placeDetails, 'geometry.location.lat', () => {})()
          const lng = get(placeDetails, 'geometry.location.lng', () => {})()

          setLatLng({ lat, lng })
          setPlace(placeDetails)
        })
      }
      // once the google api call has been made, set the map to loaded
      setMapLoaded(true)
    }
  }, [placePredictions])

  const noLatLngFound = get(latLng, 'lat') === null || get(latLng, 'lng') === null

  return (
    <div className='Map'>
      <div className='header flex space-between'>
        <h6>Map by {title}</h6>
        <a className='view-on-google' href={`https://www.google.com/maps/search/${encodeURIComponent(address)}`} target='blank'>{noLatLngFound ? 'Search on Google Maps' : 'View on Google Maps'}</a>
      </div>

      <div className='map-container' style={mapStyles}>
        { !mapLoaded && <Loader /> }
        { mapLoaded && noLatLngFound && <p>No location could be found by {title}</p>}
        { mapLoaded && !noLatLngFound && (
        <Map
          google={google}
          zoom={zoom}
          initialCenter={latLng}
          center={latLng}
          mapType={mapType}
          streetViewControl
        >
          <Marker position={latLng} />
        </Map>
        )}
      </div>
    </div>
  )
}

MapC.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  google: PropTypes.object,
  mapStyles: PropTypes.object,
  mapType: PropTypes.string,
  allowGeocode: PropTypes.bool,
  zoom: PropTypes.number,
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(MapC)
