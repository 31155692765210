import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CUSTOMER_ONBOARDING_FORM } from 'constants/apiConstants'

const getCustomerOnboardingFormAPI = ({ id, credentials, meta }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_CUSTOMER_ONBOARDING_FORM({ id }),
    meta,
  })
}

export default getCustomerOnboardingFormAPI
