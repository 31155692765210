import React, { Component } from 'react'
import TransactionsManagement from './TransactionsManagement'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const tabs = [
  {
    id: 'submissions',
    name: 'Duplicate Transfers',
  },
  {
    id: 'submissions',
    name: 'Update Transfers',
  },
  {
    id: 'submissions',
    name: 'Submissions',
  },
]

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  const headerTitle = 'Manage Transactions'

  return {
    credentials,
    headerTitle,
    tabs,
  }
}

class TransactionsManagementC extends Component {
  render() {
    return (
      <TransactionsManagement {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(TransactionsManagementC)
