import normalizeObject from 'utilities/api/normalizeObject'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import { frontendNormalizationMap } from 'constants/normalizationConstants'

const normalizeArray = (array, normalizationMap = frontendNormalizationMap) => {
  if (!isArray(array)) {
    return array
  }

  return map(array, (value) => {
    if (isArray(value)) {
      return normalizeArray(value, normalizationMap)
    }

    if (isPlainObject(value)) {
      return normalizeObject(value, normalizationMap)
    }

    return value
  })
}

export default normalizeArray
