import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UpdateUserC from 'components/Customer/Modals/UpdateUserModal/UpdateUserC'
import get from 'lodash/get'

const UpdateUserModal = ({
  user = {},
  userId = '',
  actionType = '',
}) => {
  return (
    <GenericModal
      title={actionType}
      className='UpdateUserModal'
      Component={UpdateUserC}
      user={user}
      userId={userId}
      actionType={actionType}
    />
  )
}

UpdateUserModal.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  actionType: PropTypes.string,
}

export default UpdateUserModal
