import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentsC from 'components/Customer/Pages/Payments/PaymentsC'
import PayoutsC from 'components/Customer/Pages/Payouts/PayoutsC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const isPayout = isPayoutFeature({ credentials })

  return { isPayout }
}

// TODO: temporary landing page
class TransfersPayoutsC extends Component {
  render() {
    const { isPayout } = this.props

    return isPayout ? <PayoutsC /> : <PaymentsC />
  }
}

export default connect(mapStateToProps)(TransfersPayoutsC)
