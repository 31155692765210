import { validateTruthyValue } from 'utilities/validate'

const validateAcceptLiabilityConfirmationForm = (values) => {
  const {
    acceptLiability,
  } = values

  return {
    acceptLiability: validateTruthyValue({ field: acceptLiability, customErrorMessage: 'This field must not be empty.' }),
  }
}

export default validateAcceptLiabilityConfirmationForm
