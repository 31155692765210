import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { infoTableTheme } from 'constants/themeConstants'

const DisputeEvidencesSectionTable = ({
  disputeEvidenceFiles = {},
  isFetchingEvidences = false,
  title = '',
  classNames = '',
}) => {
  const tableClassNames = classnames('DisputeEvidencesSectionTable', classNames)

  return (
    <div className={tableClassNames}>
      <TableC
        columnDescriptors={columnDescriptors}
        showTableFooter={false}
        data={disputeEvidenceFiles}
        isFetching={isFetchingEvidences}
        title={title}
        theme={infoTableTheme}
      />
    </div>
  )
}

DisputeEvidencesSectionTable.propTypes = {
  isFetchingEvidences: PropTypes.bool,
  disputeEvidenceFiles: PropTypes.object,
  title: PropTypes.string,
  classNames: PropTypes.string,
}

export default DisputeEvidencesSectionTable
