import { POST_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_ADJUSTMENT } from 'constants/amplitudeConstants'
import { USD } from 'constants/currencyConstants'

const submitCreateAdjustmentForm = (values, dispatch, props) => {
  const { id, credentials } = props

  const {
    amount,
    currency = USD,
    adjustmentType,
    merchantId,
    paymentInstrumentId,
    reason,
  } = values

  const valuesToSubmit = {
    amount,
    currency,
    operationKey: adjustmentType,
    merchantId,
    paymentInstrumentId,
    tags: reason ? { reason } : {},
  }

  dispatch({
    type: POST_TRANSFER_F_REQUEST,
    payload: {
      id,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(CREATE_ADJUSTMENT, {
        transferId: id,
        credentials,
        merchantId,
        paymentInstrumentId,
        adjustmentType,
        amount,
        currency,
        reason,
      }),
    },
  })
}

export default submitCreateAdjustmentForm
