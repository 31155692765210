const fullPlatformAdministrator = {
  'authentication:user:create': true,
  'authentication:user:list': true,
  'authentication:user:merchant_create': true,
  'authentication:user:partner_create': true,
  // 'authentication:user:permission_delete': false,
  // 'authentication:user:permission_duplicate': false,
  'authentication:user:permission_list': true,
  'authentication:user:permission_update': true,
  'authentication:user:read': true,
  // 'authentication:user:sync': false,
  'authentication:user:update': true,
  // 'processing:admin:update': false,
  'processing:application_profile:create': true,
  'processing:application_profile:list': true,
  'processing:application_profile:read': true,
  'processing:application_profile:update': true,
  'processing:application:create': true,
  'processing:application:list': true,
  'processing:application:merchant_profile_update': true,
  'processing:application:processor_disable': true,
  'processing:application:processor_enable': true,
  'processing:application:processors_list': true,
  'processing:application:read': true,
  'processing:application:update': true,
  'processing:application:users_list': true,
  // 'processing:authorization:create': false,
  'processing:authorization:list': true,
  'processing:authorization:read': true,
  // 'processing:authorization:update': false,
  'processing:black_listed_instrument:create': true,
  'processing:black_listed_instrument:delete': true,
  'processing:black_listed_instrument:read': true,
  'processing:dispute:create': true,
  'processing:dispute:evidence_submit': true,
  'processing:dispute:files_list': true,
  'processing:dispute:list': true,
  'processing:dispute:read': true,
  'processing:dispute:update': true,
  'processing:fee_profile:create': true,
  'processing:fee_profile:list': true,
  'processing:fee_profile:read': true,
  'processing:fee_profile:update': true,
  'processing:fee_strategy:create': true,
  'processing:fee_strategy:list': true,
  'processing:fee_strategy:read': true,
  'processing:fee_strategy:update': true,
  // 'processing:fee:create': false,
  'processing:fee:list': true,
  'processing:fee:read': true,
  'processing:identity:create': true,
  'processing:identity:list': true,
  'processing:identity:read': true,
  'processing:identity:update': true,
  'processing:instrument_update:create': true,
  'processing:instrument_update:list': true,
  'processing:instrument_update:read': true,
  'processing:instrument_update:update': true,
  'processing:instrument:create': true,
  'processing:instrument:list': true,
  'processing:instrument:read': true,
  'processing:instrument:update': true,
  // 'processing:mcc_codes:create': false,
  // 'processing:mcc_codes:update': false,
  'processing:merchant_profile:create': true,
  'processing:merchant_profile:list': true,
  'processing:merchant_profile:read': true,
  'processing:merchant_profile:update': true,
  'processing:merchant:create': true,
  'processing:merchant:list': true,
  'processing:merchant:read': true,
  'processing:merchant:update': true,
  'processing:platform_fee_profile:update': true,
  'processing:platform_processor_config:create': true,
  'processing:platform_processor_config:list': true,
  'processing:platform_processor_config:read': true,
  'processing:platform_processor_config:update': true,
  'processing:platform_webhook:create': true,
  'processing:platform:create': true,
  'processing:platform:list': true,
  'processing:platform:read': true,
  'processing:platform:update': true,
  'processing:processor:create': true,
  'processing:processor:list': true,
  'processing:processor:read': true,
  'processing:processor:update': true,
  'processing:reserve_profile:create': true,
  'processing:reserve_profile:list': true,
  'processing:reserve_profile:read': true,
  'processing:reserve_profile:update': true,
  'processing:review_queue:create': true,
  'processing:review_queue:list': true,
  'processing:review_queue:read': true,
  'processing:review_queue:update': true,
  'processing:risk_profile_rule:create': true,
  'processing:risk_profile_rule:list': true,
  'processing:risk_profile_rule:read': true,
  'processing:risk_profile_rule:update': true,
  'processing:risk_profile:create': true,
  'processing:risk_profile:list': true,
  'processing:risk_profile:read': true,
  'processing:risk_profile:update': true,
  'processing:settlement:create': true,
  'processing:settlement:list': true,
  'processing:settlement:read': true,
  'processing:settlement:settling_transfer_create': true,
  'processing:settlement:transfers_list': true,
  'processing:settlement:update': true,
  'processing:transaction:create': true,
  'processing:transaction:list': true,
  'processing:transaction:read': true,
  'processing:transaction:update': true,
  'processing:transfer:accounting_create': true,
  'processing:transfer:adjustment_create': true,
  'processing:transfer:create': true,
  'processing:transfer:fee_return': true,
  'processing:transfer:fees_list': true,
  'processing:transfer:funds_release': true,
  'processing:transfer:list': true,
  'processing:transfer:payout': true,
  'processing:transfer:platform_fee_return': true,
  'processing:transfer:read': true,
  'processing:transfer:refund': true,
  'processing:transfer:refunds_list': true,
  'processing:transfer:update': true,
  'processing:verification:create': true,
  'processing:verification:list': true,
  'processing:verification:read': true,
  'processing:verification:update': true,
  'processing:view:application_list_all': true,
  'processing:view:entity_system': true,
  'processing:view:identity_list_all': true,
  'processing:view:settlement_system': true,
  'processing:webhook:create': true,
  'processing:webhook:list': true,
  'processing:webhook:read': true,
  'processing:webhook:update': true,
  // 'reporting:report:create': false,
  'reporting:report:list': true,
  'reporting:report:read': true,
  // 'reporting:report:update': false,
  'risk:rule_instance:create': true,
  'risk:rule_instance:list': true,
  'risk:rule_instance:update': true,
  // 'search:search:create': false,
  // 'search:search:delete': false,
  'search:search:list': true,
  'search:search:read': true,
  // 'search:search:update': false,
  'settlement:funding_instruction_preview:list': true,
  'settlement:funding_instruction:create': true,
  'settlement:funding_instruction:list': true,
  'settlement:funding_instruction:resubmit': true,
  'settlement:funding_instruction:update': true,
  // 'settlement:payout_plans:create': false,
  'settlement:payout_plans:list': true,
  'settlement:payout_plans:read': true,
  'settlement:payout_plans:update': true,
  'settlement:settlement_group_settings:create': true,
  'settlement:settlement_group_settings:delete': true,
  'settlement:settlement_group_settings:list': true,
  'settlement:settlement_group_settings:read': true,
  'settlement:settlement_group:read': true,
  'subscription:subscription_group:create': true,
  'subscription:subscription_group:list': true,
  'subscription:subscription_group:read': true,
  'subscription:subscription_group:update': true,
  'subscription:subscription_item:create': true,
  'subscription:subscription_item:list': true,
  'subscription:subscription_item:read': true,
  'subscription:subscription_item:update': true,
  'subscription:subscription_plan:create': true,
  'subscription:subscription_plan:list': true,
  'subscription:subscription_plan:read': true,
  'subscription:subscription_plan:update': true,
  'subscription:subscription_schedule:create': true,
  'subscription:subscription_schedule:list': true,
  'subscription:subscription_schedule:read': true,
  'subscription:subscription_schedule:update': true,
}

export default fullPlatformAdministrator
