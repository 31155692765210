import React from 'react'
import { Link } from 'react-router'
import { getState } from 'state-layer/configureStore'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { PAYMENT_INSTRUMENT_PATH } from 'constants/pathConstants'
import { getCardBrand } from 'constants/bankConstants'
import { BANK_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  PAYMENT_CARD,
  PAYMENT_CARD_PRESENT,
  APPLE_PAY,
  GOOGLE_PAY,
  ALT_PAYMENT_METHODS_BE_MAPPING,
} from 'constants/paymentInstrumentConstants'

// TODO: write unit test
const displayBuyerPaymentInstrumentLink = ({
  type,
  credentialId,
  paymentInstrument,
}) => {
  const state = getState()
  const credentials = getCurrentCredentials(state)
  const isRoleMerchantCred = isRoleMerchant({ credentials })

  const [
    paymentInstrumentId,
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentBuyerName,
  ] = getMany(paymentInstrument, [
    'id',
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'name',
  ])

  const paymentCard = includes([PAYMENT_CARD, PAYMENT_CARD_PRESENT, APPLE_PAY, GOOGLE_PAY], type)
  const brand = includes([GOOGLE_PAY, APPLE_PAY], type) ? ALT_PAYMENT_METHODS_BE_MAPPING[type] : get(paymentInstrument, 'brand')
  const icon = paymentCard ? getCardBrand(brand) : `fa fa-${BANK_ICON}`
  const displayValue = paymentCard ? maskedFullCardNumber : maskedAccountNumber

  return (
    <>
      <i className={`icon ${icon}`} />
      {isRoleMerchantCred ? <div>{displayValue} {paymentInstrumentBuyerName && <>({ paymentInstrumentBuyerName })</>}</div> :
        (
          <Link className='text-link' to={PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId })}>
            {displayValue} {paymentInstrumentBuyerName && <>({ paymentInstrumentBuyerName })</>}
          </Link>
        )}
    </>
  )
}

export default displayBuyerPaymentInstrumentLink
