import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateAdjustmentForm from 'components/Customer/Forms/CreateAdjustmentForm/CreateAdjustmentForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import { getIdentitySelector } from 'state-layer/selectors'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  getMerchantsOptions,
  getPaymentInstrumentsOptions,
  AdjustmentTypeOptions,
} from 'constants/adjustmentConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)

  const merchants = get(identity, 'merchants')
  const paymentInstruments = get(identity, 'paymentInstruments')
  const merchantsOptions = getMerchantsOptions(merchants)
  const paymentInstrumentOptions = getPaymentInstrumentsOptions(paymentInstruments)

  const initialValues = {
    merchantId: get(head(merchantsOptions), 'value'),
    paymentInstrumentId: get(head(paymentInstrumentOptions), 'value'),
    adjustmentType: get(head(AdjustmentTypeOptions), 'value'),
  }

  return {
    id: identityId,
    identity,
    credentials,
    merchantsOptions,
    paymentInstrumentOptions,
    adjustmentTypeOptions: AdjustmentTypeOptions,
    initialValues,
  }
}

const mapDispatchToProps = {
  getIdentity: getIdentityRequest,
}

class CreateAdjustmentFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  render() {
    return (
      <CreateAdjustmentForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdjustmentFormC)
