import dashboardAPI from 'utilities/api/dashboardAPI'
import { SUBMIT_DASHBOARD_ACCESS_FORM } from 'constants/apiConstants'
import get from 'lodash/get'

const postSubmitAccessFormAPI = ({ values, meta }) => {
  const id = get(values, 'id')
  return dashboardAPI.post({
    path: SUBMIT_DASHBOARD_ACCESS_FORM({ id }),
    values,
    meta,
  })
}

export default postSubmitAccessFormAPI
