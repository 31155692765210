import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import Address from './Address'

const mapStateToProps = (state, props) => {
  const { form, name, presetCountry } = props
  const formSelector = formValueSelector(form)
  const countryCode = formSelector(state, `${name}.country`) || presetCountry

  return {
    countryCode,
  }
}

class AddressC extends Component {
  render() {
    return (
      <Address
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AddressC)
