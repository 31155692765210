import React, { Component } from 'react'
import { connect } from 'react-redux'
import SplitRefund from './SplitRefund'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import { splitRefundsColumnDescriptors } from 'components/Customer/Pages/Payment/columnDescriptors'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import getMany from 'utilities/get/getMany'
import getSplitTransfersRequest from 'utilities/actions/get/getSplitTransfersRequest'
import getSplitTransferRequest from 'utilities/actions/get/getSplitTransferRequest'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import concat from 'lodash/concat'
import filter from 'lodash/filter'

import {
  getSplitTransferSelector,
  getSplitTransfersByParentIdSelector,
} from 'state-layer/selectors'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION,
  MERCHANT_IDENTITY,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  CREATED_ON,
  TYPE,
  SPLIT_REFUND,
  REFUND,
  SPLIT_TRANSACTION,
  ORIGINAL_SPLIT_PAYMENT,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  MERCHANT_ACCOUNT,
  STATE,
} from 'constants/language/languageConstants'

import {
  PAYMENT_PATH,
  APPLICATION_PATH,
  MERCHANT_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const splitRefundId = get(props, 'params.splitRefundId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const splitRefund = getSplitTransferSelector(state, splitRefundId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard()

  const [
    displayCreatedAt,
    parentRefund,
    currency,
    displayAmount,
    applicationName,
    applicationId,
    merchantName,
    merchantId,
    merchantIdentityName,
    merchantIdentityId,
  ] = getMany(splitRefund, [
    'displayCreatedAt',
    'parentTransfer',
    'currency',
    'displayAmount',
    'application.businessName',
    'application.id',
    'merchant.businessName',
    'merchant.id',
    'identity.businessName',
    'identity.id',
  ])

  const [
    parentRefundId,
    originalPaymentId,
    paymentInstrument,
    originalPaymentAmount,
    buyerIdentity,
    primaryMerchantId,
    parentTransferState,
    parentRaw,
  ] = getMany(parentRefund, [
    'id',
    'parentTransfer.id',
    'paymentInstrument',
    'parentTransfer.displayAmount',
    'buyerIdentity',
    'merchantId',
    'state',
    'raw',
  ])

  const originalPaymentPath = PAYMENT_PATH({ credentialId, transferId: originalPaymentId })
  const allSplitRefunds = getSplitTransfersByParentIdSelector(state, parentRefundId)
  const primaryMerchantSplitTransfer = filter(allSplitRefunds, ({ merchantId: splitTransferMerchantId }) => isEqual(splitTransferMerchantId, primaryMerchantId))
  const allOtherSplitTransfers = filter(allSplitRefunds, ({ merchantId: splitTransferMerchantId }) => !isEqual(splitTransferMerchantId, primaryMerchantId))
  const splitDetailsTableData = !isEmpty(allSplitRefunds) ? concat([parentRefund], primaryMerchantSplitTransfer, allOtherSplitTransfers) : []
  const isFetching = get(state, `splitTransfersR.${FETCHING}`) && isEmpty(splitDetailsTableData)
  const splitRefundsColumnDescriptorsData = splitRefundsColumnDescriptors({ isParent: false, rowId: splitRefundId })

  const [
    paymentInstrumentType,
    maskedAccountNumber,
    maskedFullCardNumber,
  ] = getMany(paymentInstrument, [
    'type',
    'maskedAccountNumber',
    'maskedFullCardNumber',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantIdentityName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantName,
        path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId }) : null,
      },
      // TODO: add settlement info in
      // {
      //   label: SETTLEMENT_RESOURCE_TITLE,
      //   value: settlementId,
      //   path: SETTLEMENT_PATH({ credentialId, settlementId }),
      // },
    ],
  }

  const headerData = {
    resource: {
      label: <div className='flex items-center'>{REFUND} <div className='split-transaction-badge'>{SPLIT_TRANSACTION}</div></div>,
      id: splitRefundId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={parentTransferState} rawMessage={parentRaw} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: TYPE,
        value: paymentInstrumentType,
      },
      {
        label: BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: paymentInstrumentType,
          credentialId,
          paymentInstrument,
        }),
        condition: !isEmpty(paymentInstrument) && (!!maskedAccountNumber || !!maskedFullCardNumber),
      },
      {
        label: ORIGINAL_SPLIT_PAYMENT,
        value: originalPaymentAmount,
        path: originalPaymentPath,
        condition: !!originalPaymentId,
      },
    ],
  }


  // TODO: add in once API is ready
  // const paymentDetailsDataSection = convertPageSectionDataToV2([
  //   {
  //     label: SETTLEMENT_STATE,
  //     value: settlementStatusValue,
  //   },
  // ])

  return {
    isFetching,
    splitRefundId,
    parentRefundId,
    contextBarData,
    credentials,
    credentialId,
    paymentInstrument,
    headerData,
    // paymentDetailsDataSection,
    splitDetailsTableData,
    buyerIdentity,
    splitRefundsColumnDescriptors: splitRefundsColumnDescriptorsData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSplitRefund: ({ credentials, splitRefundId }) => { dispatch(getSplitTransferRequest({ credentials, splitTransferId: splitRefundId })) },
    getSplitRefunds: ({ credentials, queries }) => { dispatch(getSplitTransfersRequest({ credentials, queries })) },
  }
}

class SplitRefundC extends Component {
  componentDidMount() {
    const { parentPaymentId } = this.props
    this.fetchSplitRefund()

    if (parentPaymentId) {
      this.fetchAllSplitRefunds()
    }
  }

  componentDidUpdate(prevProps) {
    const { splitRefundId, parentRefundId } = this.props

    const {
      splitRefundId: prevSplitRefundId,
      parentRefundId: prevParentRefundId,
    } = prevProps

    if (!isEqual(splitRefundId, prevSplitRefundId)) {
      this.fetchSplitRefund()
    }

    if (parentRefundId && !isEqual(parentRefundId, prevParentRefundId)) {
      this.fetchAllSplitRefunds()
    }

    // add className to table item to highlight selected row
    const element = document.getElementById(splitRefundId)
    if (element && !element.classList.contains('highlight')) {
      element.classList.add('highlight')
    }
  }

  fetchSplitRefund = () => {
    const {
      credentials,
      splitRefundId,
      getSplitRefund,
    } = this.props

    getSplitRefund({ credentials, splitRefundId })
  }

  fetchAllSplitRefunds = () => {
    const {
      parentRefundId,
      credentials,
      getSplitRefunds,
    } = this.props

    const queries = { parent_transfer_id: parentRefundId }
    getSplitRefunds({ credentials, queries })
  }

  render() {
    const {
      transferId,
      displayAmount,
      showAction,
      showRemoveFromSettlementModal,
      settlementId,
      settlementEntryId,
    } = this.props

    // TODO: add in once API is ready
    // const actions = showAction ? [
    //   {
    //     label: REMOVE_FROM_SETTLEMENT,
    //     action: () => {
    //       showRemoveFromSettlementModal({
    //         settlementId,
    //         settlementEntryIds: [settlementEntryId],
    //         entriesData: [{ id: transferId, amount: displayAmount }],
    //       })
    //     },
    //     className: 'remove-from-settlement-button',
    //   },
    // ] : []

    return (
      <SplitRefund
        {...this.props}
        // actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplitRefundC)
