import React, { Component } from 'react'
import { connect } from 'react-redux'
import AssociatedIdentityEdit from './AssociatedIdentityEdit'
import { getIdentitySelector } from 'state-layer/selectors'
import { IDENTITY_PRINCIPAL_FIELDS } from 'constants/identityConstants'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitAssociatedIdentityEditForm from 'utilities/submit/submitAssociatedIdentityEditForm'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import pick from 'lodash/pick'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)
  const parentIdentityId = get(identity, 'application.identityId')
  const principal = pick(identity, IDENTITY_PRINCIPAL_FIELDS)
  const initialValues = { principals: [principal] }

  return {
    isFetching,
    credentials,
    identityId,
    identity,
    initialValues,
    parentIdentityId,
    onSubmit: submitAssociatedIdentityEditForm,
    submitLabel: 'Save',
  }
}

class AssociatedIdentityEditC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ credentials, identityId })
  }

  render() {
    return (
      <AssociatedIdentityEdit {...this.props} />
    )
  }
}

const mapDispatchToProps = {
  getIdentity: getIdentityRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedIdentityEditC)
