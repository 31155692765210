import './ResendTransferModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ResendTransferFormC from 'components/Customer/Forms/ResendTransferForm/ResendTransferFormC'
import { RESEND_FUNDING_TRANSFER_INSTRUCTIONS } from 'constants/language/languageConstants'

const ResendTransferModal = ({
  settlementPaymentInstruments = [],
  bankAccountPaymentInstruments = [],
  settlementId = '',
}) => {
  return (
    <GenericModal
      title={RESEND_FUNDING_TRANSFER_INSTRUCTIONS}
      className='ResendTransferModal'
      Component={ResendTransferFormC}
      settlementPaymentInstruments={settlementPaymentInstruments}
      settlementId={settlementId}
      bankAccountPaymentInstruments={bankAccountPaymentInstruments}
    />
  )
}

ResendTransferModal.propTypes = {
  settlementPaymentInstruments: PropTypes.array,
  bankAccountPaymentInstruments: PropTypes.array,
  settlementId: PropTypes.string,
}

export default ResendTransferModal
