import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACCESS_FORM_BENEFICIAL_OWNER_TAX_ID_UNMASK } from 'constants/apiConstants'
import get from 'lodash/get'

const getAccessFormBeneficialOwnerTaxIdUnmaskAPI = ({ id, values }) => {
  const index = get(values, 'index')

  return dashboardAPI.get({
    path: ACCESS_FORM_BENEFICIAL_OWNER_TAX_ID_UNMASK({ accessFormId: id, index }),
  })
}

export default getAccessFormBeneficialOwnerTaxIdUnmaskAPI
