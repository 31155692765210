import React from 'react'
import PropTypes from 'prop-types'
import { getPaymentLinksWithMerchantSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { PAYMENT_LINKS } from 'constants/linkConstants'
import { GET_PAYMENT_LINKS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { PAYMENT_LINK_DOCS_LINK } from 'constants/urlConstants'

import {
  PAYMENT_LINKS_RESOURCE_TITLE,
  PAYMENT_LINKS_EMPTY_TABLE_MESSAGE,
  PAYMENT_LINKS_TABLE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const PaymentLinks = ({
  paymentLinksPath = '',
  initialQueries,
  initialFilters,
  quickFilters,
  actions = [],
  isFetching = false,
}) => {
  return (
    <div className='PaymentLinks'>
      <HeaderV2C
        title={PAYMENT_LINKS_RESOURCE_TITLE}
        subTitle={PAYMENT_LINKS_TABLE_SUBTITLE_DESCRIPTION}
        learnMoreLink={PAYMENT_LINK_DOCS_LINK}
      />

      <TableC
        flow={GET_PAYMENT_LINKS}
        columnDescriptors={columnDescriptors}
        selector={getPaymentLinksWithMerchantSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        quickFilters={quickFilters}
        actions={actions}
        linksKey={PAYMENT_LINKS}
        path={paymentLinksPath}
        isFetching={isFetching}
        emptyMessage={PAYMENT_LINKS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV='Payment_Links'
        fileResourceTitleCSV={PAYMENT_LINKS_RESOURCE_TITLE}
      />
    </div>
  )
}

PaymentLinks.propTypes = {
  paymentLinksPath: PropTypes.string,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.array,
  actions: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default PaymentLinks
