import selectOption from 'utilities/forms/selectOption'
import createUrl from 'utilities/create/createUrl'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import {
  IDENTITY_PATH,
  MERCHANT_PATH,
  SETTLEMENT_PATH,
  REVIEW_QUEUE_MERCHANT_PATH,
  REVIEW_QUEUE_MERCHANTS_PATH,
  REVIEW_QUEUE_SETTLEMENT_PATH,
  REVIEW_QUEUE_SETTLEMENTS_PATH,
} from 'constants/pathConstants'

import {
  DEFAULT,
  FAILED,
  SUCCEEDED,
} from 'constants/statusConstants'

import {
  APPROVE_IDENTITIES,
  APPROVE_MERCHANTS,
  APPROVE_SETTLEMENTS,
  PATCH_REVIEW_QUEUE_ITEM,
  PEND_IDENTITIES,
  PEND_MERCHANTS,
  PEND_SETTLEMENTS,
  REJECT_IDENTITIES,
  REJECT_MERCHANTS,
  REJECT_SETTLEMENTS,
} from 'constants/amplitudeConstants'

export const ALL = 'ALL'
export const SETTLEMENT = 'SETTLEMENT'
export const SETTLEMENT_V2 = 'SETTLEMENT_V2'
export const MERCHANT = 'MERCHANT'
export const IDENTITY = 'IDENTITY'
export const PENDING = 'PENDING'
export const REJECTED = 'REJECTED'
export const ACCEPTED = 'ACCEPTED'
export const APPROVED = 'APPROVED'
export const MANUAL_REVIEW = 'MANUAL_REVIEW'
export const WITHDRAWN = 'WITHDRAWN'
export const ADDITIONAL_VERIFICATION_NEEDED = 'ADDITIONAL_VERIFICATION_NEEDED'

// review types
export const ONBOARDING_REVIEW = 'ONBOARDING'
export const SANCTIONS_REVIEW = 'SANCTIONS'
export const ADVERSE_MEDIA_REVIEW = 'ADVERSE_MEDIA'

export const REVIEW_QUEUE_DISPLAY_VALUES = {
  [PENDING]: 'Pending',
  [REJECTED]: 'Rejected',
  [ACCEPTED]: 'Approved',
}

export const ReviewQueueStatusData = {
  ['Approved']: SUCCEEDED,
  ['Pending']: DEFAULT,
  ['Rejected']: FAILED,
}

export const REVIEW_QUEUE_OPTIONS = [
  selectOption('Pending', PENDING),
  selectOption('Rejected', REJECTED),
  selectOption('Accepted', ACCEPTED),
]

export const REVIEW_QUEUE_STATE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Pending', PENDING),
  selectOption('Rejected', REJECTED),
  selectOption('Approved', ACCEPTED),
]

export const REVIEW_QUEUE_QUERIES = ['fetchData', 'entity_type', 'outcome']

const MATCH_LIST = 'MATCH_LIST'
const HIGH_RISK = 'HIGH_RISK'
const INSUFFICIENT_HISTORY = 'INSUFFICIENT_HISTORY'
const FRAUD = 'FRAUD'
const PROHIBITED = 'PROHIBITED'
const MULTIPLE_FACTORS = 'MULTIPLE_FACTORS'
const LOW_FICO_SCORE = 'LOW_FICO_SCORE'
const POOR_PROCESSING_HISTORY = 'POOR_PROCESSING_HISTORY'
const VIOLATES_CHARGEBACK_THRESHOLD = 'VIOLATES_CHARGEBACK_THRESHOLD'
const VIOLATES_RETURNS_THRESHOLD = 'VIOLATES_RETURNS_THRESHOLD'
const VIOLATES_UNDERWRITING_REQUIREMENTS = 'VIOLATES_UNDERWRITING_REQUIREMENTS'

export const REJECT_REASONS = [
  MATCH_LIST,
  HIGH_RISK,
  INSUFFICIENT_HISTORY,
  FRAUD,
  PROHIBITED,
  MULTIPLE_FACTORS,
  LOW_FICO_SCORE,
  POOR_PROCESSING_HISTORY,
  VIOLATES_CHARGEBACK_THRESHOLD,
  VIOLATES_RETURNS_THRESHOLD,
  VIOLATES_UNDERWRITING_REQUIREMENTS,
]

const ENTITY_NAMES = {
  [IDENTITY]: 'Identity',
  [MERCHANT]: 'Merchant',
  [SETTLEMENT]: 'Settlement',
  [SETTLEMENT_V2]: 'Settlement',
}

const ENTITY_PATHS = {
  [IDENTITY]: ({ id, credentialId }) => IDENTITY_PATH({ identityId: id, credentialId }),
  [MERCHANT]: ({ id, credentialId, identityId }) => MERCHANT_PATH({ merchantId: id, credentialId, identityId }),
  [SETTLEMENT]: ({ id, credentialId }) => SETTLEMENT_PATH({ settlementId: id, credentialId }),
  [SETTLEMENT_V2]: ({id, credentialId }) => SETTLEMENT_PATH({ settlementId: id, credentialId }),
}

const ENTITY_REVIEW_QUEUE_PATHS = {
  [MERCHANT]: ({ reviewQueueId, credentialId }) => REVIEW_QUEUE_MERCHANT_PATH({ reviewQueueId, credentialId }),
  [SETTLEMENT]: ({ reviewQueueId, credentialId }) => REVIEW_QUEUE_SETTLEMENT_PATH({ reviewQueueId, credentialId }),
  [SETTLEMENT_V2]: ({ reviewQueueId, credentialId }) => REVIEW_QUEUE_SETTLEMENT_PATH({ reviewQueueId, credentialId }),
}

const ENTITY_REVIEW_QUEUE_LIST_PATHS = {
  [MERCHANT]: ({ credentialId }) => REVIEW_QUEUE_MERCHANTS_PATH({ credentialId }),
  [SETTLEMENT]: ({ credentialId }) => REVIEW_QUEUE_SETTLEMENTS_PATH({ credentialId }),
  [SETTLEMENT_V2]: ({ credentialId }) => REVIEW_QUEUE_SETTLEMENTS_PATH({ credentialId }),
}

const REVIEW_QUEUE_STATUS_TO_TAB_NAMES = {
  [PENDING]: 'pending',
  [ACCEPTED]: 'approved',
  [REJECTED]: 'rejected',
}

export const getEntityName = ({ entityType }) => ENTITY_NAMES[entityType] || 'Entity'

export const getEntityPath = ({ entityId, credentialId, reviewQueueItem }) => {
  const { entityType } = reviewQueueItem
  const identityId = get(reviewQueueItem, 'merchant.identityId', '')
  const path = ENTITY_PATHS[entityType]

  return path ? path({ id: entityId, credentialId, identityId }) : '/'
}

// TODO: this may be useful in the future, may not need reviewQueueItemId though
export const getReviewQueueItemPath = ({ reviewQueueItemId, credentialId, reviewQueueItem }) => {
  const entityType = get(reviewQueueItem, 'entityType')
  const reviewQueueItemPath = get(ENTITY_REVIEW_QUEUE_PATHS, entityType)

  return reviewQueueItemPath ? reviewQueueItemPath({ reviewQueueId: reviewQueueItemId, credentialId }) : '/'
}

export const getReviewQueueItemListPath = ({ reviewQueueItemId, credentialId, reviewQueueItem }) => {
  const entityId =  get(reviewQueueItem, 'entityId')
  const entityType = get(reviewQueueItem, 'entityType')
  const status = get(reviewQueueItem, 'outcome')
  const reviewQueueItemPath = get(ENTITY_REVIEW_QUEUE_LIST_PATHS, entityType)

  const path = createUrl({
    url: reviewQueueItemPath({ reviewQueueId: reviewQueueItemId, credentialId }),
    queries: {
      entity_id: entityId,
      tab: get(REVIEW_QUEUE_STATUS_TO_TAB_NAMES, status)
    }
  })

  return reviewQueueItemPath ? path : '/'
}

export const OUTCOME_REJECT_REASONS = [
  { label: 'Invalid Owner Data', value: 'INVALID_OWNER_DATA', tab: 'Control Owner Verification' },
  { label: 'ID Verification Failed', value: 'ID_VERIFICATION_FAILED', tab: 'Control Owner Verification' },
  { label: 'Match List Decline', value: 'MATCH_LIST_DECLINE', tab: 'Control Owner Verification' },
  { label: 'OFAC Decline', value: 'OFAC_DECLINE', tab: 'Control Owner Verification' },
  { label: 'Invalid Bank Account Details', value: 'INVALID_BANK_ACCOUNT_DETAILS', tab: 'Bank Account Verification' },
  { label: 'Invalid Bank Routing Number', value: 'INVALID_BANK_ROUTING_NUMBER', tab: 'Bank Account Verification' },
  { label: 'Invalid Bank Account Number', value: 'INVALID_BANK_ACCOUNT_NUMBER', tab: 'Bank Account Verification' },
  { label: 'Bank Verification Failed', value: 'BANK_VERIFICATION_FAILED', tab: 'Bank Account Verification' },
  { label: 'Bank Not Supported', value: 'BANK_NOT_SUPPORTED', tab: 'Bank Account Verification' },
  { label: 'Invalid Bank Account Ownership', value: 'INVALID_BANK_ACCOUNT_OWNERSHIP', tab: 'Bank Account Verification' },
  { label: 'Invalid Business Information', value: 'INVALID_BUSINESS_INFORMATION', tab: 'Company Verification' },
  { label: 'Invalid Business Type', value: 'INVALID_BUSINESS_TYPE', tab: 'Company Verification' },
  { label: 'Invalid Tax ID Number', value: 'INVALID_TAX_ID_NUMBER', tab: 'Company Verification' },
  { label: 'Tax ID Verification Failed', value: 'TAX_ID_VERIFICATION_FAILED', tab: 'Company Verification' },
  { label: 'Insufficient Business History', value: 'INSUFFICIENT_BUSINESS_HISTORY', tab: 'Company Verification' },
  { label: 'Invalid MCC', value: 'INVALID_MCC', tab: 'Other' },
  { label: 'Prohibited MCC', value: 'PROHIBITED_MCC', tab: 'Other' },
  { label: 'PO Box Address Not Supported', value: 'PO_BOX_ADDRESS_NOT_SUPPORTED', tab: 'Other' },
  { label: 'Generic Decline', value: 'GENERIC_DECLINE', tab: 'Other' },
  { label: 'Declined Do Not Submit Again', value: 'DECLINED_DO_NOT_SUBMIT_AGAIN', tab: 'Other' },
  { label: 'High Risk', value: 'HIGH_RISK', tab: 'Other' },
  { label: 'Potential Fraud', value: 'POTENTIAL_FRAUD', tab: 'Other' },
  { label: 'Financial History', value: 'FINANCIAL_HISTORY', tab: 'Other' },
  { label: 'Multiple Factors', value: 'MULTIPLE_FACTORS', tab: 'Other' },
  // { label: 'Failed Review', value: 'FAILED_REVIEW', tab: 'Other' },
]

export const OUTCOME_REJECT_REASONS_KEYED_BY_VALUE = keyBy(OUTCOME_REJECT_REASONS, 'value')

export const OUTCOME_MORE_INFO_REASONS = [
  { label: 'ID Verification Needed', value: 'ID_VERIFICATION_NEEDED', tab: 'Control Owner Verification' },
  { label: 'Match List Decline', value: 'MATCH_LIST_DECLINE', tab: 'Control Owner Verification' },
  { label: 'OFAC Decline', value: 'OFAC_DECLINE', tab: 'Control Owner Verification' },
  { label: 'Bank Verification Needed', value: 'BANK_VERIFICATION_NEEDED', tab: 'Bank Account Verification' },
  { label: 'Tax ID Verification Needed', value: 'TAX_ID_VERIFICATION_NEEDED', tab: 'Company Verification' },
  // { label: 'Change Requested', value: 'CHANGE_REQUESTED', tab: 'Company Verification' },
]

export const OUTCOME_MORE_INFO_REASONS_KEYED_BY_VALUE = keyBy(OUTCOME_MORE_INFO_REASONS, 'value')

const ALL_OUTCOME_REASONS = [
  ...OUTCOME_REJECT_REASONS,
  ...OUTCOME_MORE_INFO_REASONS,
]

export const ALL_OUTCOME_REASONS_BY_ID = keyBy(ALL_OUTCOME_REASONS, 'value')

export const FILE_REQUIRED_OUTCOMES = [
  'ID_VERIFICATION_NEEDED',
  'BANK_VERIFICATION_NEEDED',
  'TAX_ID_VERIFICATION_NEEDED',
]

export const REJECT_UNDERWRITING_ENTITY_OPTIONS = [
  {
    label: 'Business Verification',
    value: 'BUSINESS_VERIFICATION',
    children: [
      {
        label: 'Insufficient Business History',
        value: 'INSUFFICIENT_BUSINESS_HISTORY',
      },
      {
        label: 'Tax ID Verification Failed',
        value: 'TAX_ID_VERIFICATION_FAILED',
      },
      {
        label: 'Invalid Tax ID Number',
        value: 'INVALID_TAX_ID_NUMBER',
      },
    ],
  },
  {
    label: 'Individual Verification',
    value: 'INDIVIDUAL_VERIFICATION',
    children: [
      {
        label: 'ID Verification Failed',
        value: 'ID_VERIFICATION_FAILED',
      },
      {
        label: 'Match List Decline',
        value: 'MATCH_LIST_DECLINE',
      },
      {
        label: 'Invalid Owner Data',
        value: 'INVALID_OWNER_DATA',
      },
      {
        label: 'OFAC Decline',
        value: 'OFAC_DECLINE',
      },
    ],
  },
  {
    label: 'Bank Account Verification',
    value: 'BANK_ACCOUNT_VERIFICATION',
    children: [
      {
        label: 'Bank Not Supported',
        value: 'BANK_NOT_SUPPORTED',
      },
      {
        label: 'Invalid Account Ownership',
        value: 'INVALID_BANK_ACCOUNT_OWNERSHIP',
      },
      {
        label: 'Bank Verification Failed',
        value: 'BANK_VERIFICATION_FAILED',
      },
    ],
  },
  {
    label: 'Other',
    value: 'OTHER',
    children: [
      {
        label: 'Declined Do Not Submit Again',
        value: 'DECLINED_DO_NOT_SUBMIT_AGAIN',
      },
      {
        label: 'Multiple Factors',
        value: 'MULTIPLE_FACTORS',
      },
      {
        label: 'Financial History',
        value: 'FINANCIAL_HISTORY',
      },
      {
        label: 'PO Box Address Not Supported',
        value: 'PO_BOX_ADDRESS_NOT_SUPPORTED',
      },
      {
        label: 'Generic Decline',
        value: 'GENERIC_DECLINE',
      },
      {
        label: 'Potential Fraud',
        value: 'POTENTIAL_FRAUD',
      },
      {
        label: 'High Risk',
        value: 'HIGH_RISK',
      },
      {
        label: 'Prohibited MCC',
        value: 'PROHIBITED_MCC',
      },
      {
        label: 'Invalid MCC',
        value: 'INVALID_MCC',
      },
    ],
  },
]

export const REQUEST_ADDITIONAL_INFO_UNDERWRITING_ENTITY_OPTIONS = [
  {
    label: 'Business Verification',
    value: 'BUSINESS_VERIFICATION',
    children: [
      {
        label: 'Insufficient Business History',
        value: 'INSUFFICIENT_BUSINESS_HISTORY',
      },
      {
        label: 'Tax ID Verification Failed',
        value: 'TAX_ID_VERIFICATION_FAILED',
      },
      {
        label: 'Tax ID Verification Needed',
        value: 'TAX_ID_VERIFICATION_NEEDED',
      },
    ],
  },
  {
    label: 'Individual Verification',
    value: 'INDIVIDUAL_VERIFICATION',
    children: [
      {
        label: 'ID Verification Failed',
        value: 'ID_VERIFICATION_FAILED',
      },
      {
        label: 'ID Verification Needed',
        value: 'ID_VERIFICATION_NEEDED',
      },
      {
        label: 'Invalid Owner Data',
        value: 'INVALID_OWNER_DATA',
      },
    ],
  },
  {
    label: 'Bank Account Verification',
    value: 'BANK_ACCOUNT_VERIFICATION',
    children: [
      {
        label: 'Bank Verification Needed',
        value: 'BANK_VERIFICATION_NEEDED',
      },
      {
        label: 'Invalid Account Number',
        value: 'INVALID_BANK_ACCOUNT_NUMBER',
      },
      {
        label: 'Bank Verification Failed',
        value: 'BANK_VERIFICATION_FAILED',
      },
      {
        label: 'Invalid Routing Number',
        value: 'INVALID_BANK_ROUTING_NUMBER',
      },
      {
        label: 'Invalid Account Details',
        value: 'INVALID_BANK_ACCOUNT_DETAILS',
      },
    ],
  },
  {
    label: 'Other',
    value: 'OTHER',
    children: [
      {
        label: 'PO Box Address Not Supported',
        value: 'PO_BOX_ADDRESS_NOT_SUPPORTED',
      },
    ],
  },
]

export const REVIEW_QUEUE_ENTITY_OUTCOME_ACTIONS = {
  [SETTLEMENT]: {
    [PENDING]: PEND_SETTLEMENTS,
    [REJECTED]: REJECT_SETTLEMENTS,
    [ACCEPTED]: APPROVE_SETTLEMENTS,
  },
  [SETTLEMENT_V2]: {
    [PENDING]: PEND_SETTLEMENTS,
    [REJECTED]: REJECT_SETTLEMENTS,
    [ACCEPTED]: APPROVE_SETTLEMENTS,
  },
  [IDENTITY]: {
    [PENDING]: PEND_IDENTITIES,
    [REJECTED]: REJECT_IDENTITIES,
    [ACCEPTED]: APPROVE_IDENTITIES,
  },
  [MERCHANT]: {
    [PENDING]: PEND_MERCHANTS,
    [REJECTED]: REJECT_MERCHANTS,
    [ACCEPTED]: APPROVE_MERCHANTS,
  },
}

export const getReviewQueueAmplitudeAction = ({ entityType, outcome }) => {
  return get(REVIEW_QUEUE_ENTITY_OUTCOME_ACTIONS, `${entityType}.${outcome}`, PATCH_REVIEW_QUEUE_ITEM)
}
