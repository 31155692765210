import { GET_ADMIN_USER_CREDENTIALS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAdminUserCredentialsRequest = ({ userId, queries }) => createAction({
  type: GET_ADMIN_USER_CREDENTIALS_F_REQUEST,
  id: userId,
  queries,
})

export default getAdminUserCredentialsRequest
