import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ComplianceFormStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ComplianceFormStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING } from 'constants/complianceConstants'

import {
  APPLICATION,
  DUE_ON_LABEL,
  FORM_TYPE,
  ID,
  MERCHANT_ACCOUNT,
  STATUS,
  CREATED_ON,
  EXPIRES_ON,
} from 'constants/language/languageConstants'

const columnDescriptors = ({ isRolePlatform }) => {
  const merchantAccountColumn = isRolePlatform ?
    column(MERCHANT_ACCOUNT, 'merchantIdentity.displayBusinessName', {
      className: 'large',
      headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT} subtitle={APPLICATION} />,
      formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    }) :
    column(MERCHANT_ACCOUNT, 'merchantIdentity.displayBusinessName', { className: 'large' })

  return [
    column(ID, 'id', {
      className: 'copy-click-id',
      formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
    }),
    column(CREATED_ON, 'createdAt', {
      className: 'date',
      sort: createdAtRange,
      headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
      formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
    }),
    column(DUE_ON_LABEL, 'displayDueAt', {
      className: 'date',
      headerFormatter: () => <DateTimestampHeader title={DUE_ON_LABEL} />,
      formatter: ({ dueAt }) => <DateTimestampColumn timestamp={dueAt} />,
      // sort: dueRange, // TODO: add back once due at is supported
    }),
    merchantAccountColumn,
    column(FORM_TYPE, 'type', {
      className: 'small',
      formatter: ({ type }) => COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type],
    }),
    column(STATUS, 'state', {
      className: 'state',
      formatter: ({ state, dueAt }) => <ComplianceFormStatus state={state} dueAt={dueAt} />,
    }),
    column(EXPIRES_ON, 'displayValidUntil', { className: 'date' }),
  ]
}

export default columnDescriptors
