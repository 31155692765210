import getDashboardUser from 'utilities/get/getDashboardUser'
import { AUTH0_RULE_ROLES } from 'constants/configConstants'
import get from 'lodash/get'

const getCurrentUserRoles = () => {
  const currentUser = getDashboardUser()
  const auth0ruleRoles = get(currentUser, AUTH0_RULE_ROLES, [])

  return get(currentUser, 'roles', auth0ruleRoles)
}

export default getCurrentUserRoles
