import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdverseMediaScreeningDetails from './AdverseMediaScreeningDetails'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import get from 'lodash/get'
import map from 'lodash/map'
import toString from 'lodash/toString'

import {
  countryISO2toISO3,
  countryNameByCountryCode,
} from 'constants/countryConstants'

import {
  PERSONA_REPORT_ID,
  FULL_NAME,
  BIRTH_YEAR,
  BIRTH_YEARS,
  OTHER_ALIASES,
  COUNTRIES,
  CATEGORIES,
  COMPLETED_ON,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)
  const [
    hit,
    personaRawData,
    entityName,
  ] = getMany(props, [
    'hit',
    'personaRawData',
    'entityName',
  ])

  const [
    name,
    birthdates,
    aliases,
    sources,
  ] = getMany(hit, [
    'name',
    'birthdates',
    'akas',
    'sources',
  ])

  const personaReportId = get(personaRawData, 'data.id')
  const clickToCopies = isStandaloneUnderwriting ? [] : [{ label: PERSONA_REPORT_ID, content: personaReportId }]
  const birthdateYear = get(personaRawData, 'data.attributes.birthdate-year')
  const countryCodes = get(sources, '[0].country_codes')
  const categories = get(sources, '[0].types')
  const countries = map(countryCodes, (countryCode) => get(countryNameByCountryCode, get(countryISO2toISO3, countryCode)))
  const rightTitle = `${COMPLETED_ON} ${formatDate({ date: get(personaRawData, 'data.attributes.completed-at') })}`

  const identityDataPoints = [
    {
      label: FULL_NAME,
      data: entityName,
      className: 'full-name',
    },
    {
      label: BIRTH_YEAR,
      data: toString(birthdateYear),
      className: 'birthdate-year',
    },
  ]

  const identityMatchDataPoints = [
    {
      label: BIRTH_YEARS,
      data: birthdates,
      className: 'birth-years',
    },
    {
      label: OTHER_ALIASES,
      data: aliases,
      className: 'aliases',
    },
    {
      label: COUNTRIES,
      data: countries,
      className: 'countries',
    },
    {
      label: CATEGORIES,
      data: categories,
      separator: false,
      normalizer: snakeCaseToTitleCase,
      className: 'categories',
    },
  ]

  return {
    clickToCopies,
    name,
    birthdates,
    aliases,
    birthdateYear,
    categories,
    identityDataPoints,
    identityMatchDataPoints,
    rightTitle,
  }
}

class AdverseMediaScreeningDetailsC extends Component {
  render() {
    return (
      <AdverseMediaScreeningDetails {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(AdverseMediaScreeningDetailsC)
