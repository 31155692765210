import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { UPDATE_NOTIFICATION_SETTINGS } from 'constants/amplitudeConstants'
import { POST_NOTIFICATION_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import reduce from 'lodash/reduce'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'

const submitNotificationSettingsForm = (values, dispatch, props) => {
  const { credentials } = props
  const role = get(credentials, 'role')
  const applicationId = get(props, 'applicationId')
  const platformId = get(props, 'platformId')
  const entityType = role === 'ROLE_PLATFORM' ? 'PLATFORM' : 'APPLICATION'
  const entityId = role === 'ROLE_PLATFORM' ? platformId : applicationId

  const valuesToSubmit = reduce(values, (total, notificationTypes, eventType) => {
    // remove any notifications set to false
    const enabledFields = pickBy(notificationTypes, (notificationType) => notificationType)
    const enabledNotifications = Object.keys(enabledFields)

    const patchObject = {
      events: enabledNotifications,
      notification_type: eventType,
      entity_type: entityType,
      entity_id: entityId,
    }

    return total.concat(patchObject)
  }, [])

  dispatch({
    type: POST_NOTIFICATION_SETTINGS_F_REQUEST,
    payload: {
      values: { data: valuesToSubmit },
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPDATE_NOTIFICATION_SETTINGS, {
        credentials,
      }),
    },
  })
}

export default submitNotificationSettingsForm
