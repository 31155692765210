import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import DashboardConfigurationFormC from 'components/Customer/Forms/DashboardConfigurationForm/DashboardConfigurationFormC'
import OnboardingConfigurationFormC from 'components/Customer/Forms/OnboardingConfigurationForm/OnboardingConfigurationFormC'
import PaymentLinkConfigurationsFormC from 'components/Customer/Forms/PaymentLinkConfigurationsForm/PaymentLinkConfigurationsFormC'
import EmailConfigurationsFormC from 'components/Customer/Forms/EmailConfigurationsForm/EmailConfigurationsFormC'
import PaymentLinkTemplatesFormC from 'components/Customer/Forms/PaymentLinkTemplatesForm/PaymentLinkTemplatesFormC'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import hasPermissions from 'utilities/hasPermissions'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { isFlexPlatform } from 'constants/flexConstants'
import { VIEW_TRANSACTIONS_LIST_PERMISSION } from 'constants/permissionConstants'

import {
  GENERAL_BRANDING,
  ONBOARDING_FORMS,
  EMAILS,
  PAYOUT_LINK_BRANDING,
  PAYMENT_LINK_BRANDING,
  PAYOUT_LINK_TEMPLATE,
  PAYMENT_LINK_TEMPLATE,
} from 'constants/language/languageConstants'

class BrandingTabs extends Component {
  render() {
    const {
      queryKey,
      queryValue,
      hiddenFilters,
      entityId,
      entityType,
    } = this.props

    const isDisbursementsDashboard = isDisbursementsPartnerDashboard()
    const credentials = getCurrentCredentials()

    const tabs = [
      {
        id: 'general-branding',
        name: GENERAL_BRANDING,
        component: DashboardConfigurationFormC,
        queries: {
          [queryKey]: queryValue,
        },
        props: {
          hiddenFilters,
          hideHeader: true,
          entityId,
          entityType,
        },
      },
      {
        id: 'onboarding-forms-branding',
        name: ONBOARDING_FORMS,
        component: OnboardingConfigurationFormC,
        queries: {
          [queryKey]: queryValue,
        },
        props: {
          hiddenFilters,
          hideHeader: true,
          entityId,
          entityType,
        },
      },
      {
        id: 'payment-links-and-checkout-branding',
        name: isDisbursementsDashboard ? PAYOUT_LINK_BRANDING : PAYMENT_LINK_BRANDING,
        component: PaymentLinkConfigurationsFormC,
        condition: isRolePartner({ credentials }) && hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]) && isFlexPlatform() && (isAdministratorRole() || isPaymentOperationsManagerRole()),
        queries: {
          [queryKey]: queryValue,
        },
        props: {
          entityId,
          entityType,
          isPayoutLink: isDisbursementsDashboard ? true : false,
        },
      },
      {
        id: 'payment-links-template',
        name: isDisbursementsDashboard ? PAYOUT_LINK_TEMPLATE : PAYMENT_LINK_TEMPLATE,
        component: PaymentLinkTemplatesFormC,
        condition: isRolePartner({ credentials }) && hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION]) && isFlexPlatform() && (isAdministratorRole() || isPaymentOperationsManagerRole()),
        queries: {
          [queryKey]: queryValue,
        },
        props: {
          entityId,
          entityType,
          isPayoutLink: isDisbursementsDashboard ? true : false,
        },
      },
      {
        id: 'emails-branding',
        name: EMAILS,
        component: EmailConfigurationsFormC,
        queries: {
          [queryKey]: queryValue,
        },
        props: {
          hiddenFilters,
          hideHeader: true,
          entityId,
          entityType,
        },
      },
    ]

    return (
      <div className='BrandingTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default BrandingTabs
