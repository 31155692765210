import dashboardAPI from 'utilities/api/dashboardAPI'
import {
  NOTES,
} from 'constants/apiConstants'

const postNoteAPI = ({ credentials, meta, values }) => {
  return dashboardAPI.post({
    credentials,
    meta,
    values,
    path: NOTES,
    service: NOTES,
  })
}

export default postNoteAPI
