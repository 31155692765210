import removeUndefined from 'utilities/remove/removeUndefined'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const backendTransferModel = ({ values }) => {
  const {
    amount,
    application,
    currency = USD,
    fee,
    id,
    merchantId,
    operationKey,
    paymentInstrumentId,
    statementDescriptor,
    subtype,
    tags,
    traceId,
    type,
    additionalPurchaseData,
    processor,
  } = values

  const destinationMap = {
    MERCHANT_CREDIT_ADJUSTMENT: 'destination',
    MERCHANT_DEBIT_ADJUSTMENT: 'source',
    SALE: 'source',
    PUSH_TO_ACH: 'destination',
    PUSH_TO_CARD: 'destination',
    PUSH_TO_SAME_DAY_ACH: 'destination',
  }

  const destinationName = destinationMap[operationKey]

  return removeUndefined({
    amount,
    application: get(application, 'id'),
    currency,
    fee,
    id,
    operation_key: operationKey,
    [destinationName]: paymentInstrumentId,
    merchant: merchantId,
    statement_descriptor: statementDescriptor,
    subtype,
    tags,
    trace_id: traceId,
    type,
    additional_purchase_data: !isEmpty(additionalPurchaseData) ? additionalPurchaseData : undefined,
    processor,
  })
}

export default backendTransferModel
