import React from 'react'
import selectOption from 'utilities/forms/selectOption'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  PLATFORM,
  APPLICATION,
} from 'constants/payoutPlanConstants'

export const COMPLETE = 'COMPLETE'
export const INCOMPLETE = 'INCOMPLETE'
export const OVERDUE = 'OVERDUE'
export const EXPIRED = 'EXPIRED'
export const INVALID = 'INVALID'

// compliance form types
export const PCI_SAQ_A = 'PCI_SAQ_A'

export const COMPLIANCE_FORM_OPTIONS = [
  selectOption('Completed', COMPLETE),
  selectOption('Incomplete', INCOMPLETE),
  selectOption('Overdue', OVERDUE),
  selectOption('Expired', EXPIRED),
  selectOption('Invalid', INVALID),
]

export const MERCHANT_QSA_DYNAMIC_VALUES = {
  businessName: '[identity.entity.business_name]',
  doingBusinessAs: '[identity.entity.doing_business_as]',
  contactName: '[identity.entity.first_name] + [identity.entity.last_name]',
  title: '[identity.entity.title]',
  telephone: '[identity.entity.business_phone]',
  email: '[identity.entity.email]',
  url: '[identity.entity.url]',
  businessAddress: '[identity.entity.business_address.line1] + [identity.entity.business_address.line2]',
  city: '[identity.entity.business_address.city]',
  state: '[identity.entity.business_address.state]',
  zipCode: '[identity.entity.business_address.postal_code]',
  country: '[identity.entity.business_address.country]',
}

export const YES_NO_YES_NO_RADIO_OPTIONS = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
]

export const YES_NO_YESCCW_NA_RADIO_OPTIONS = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Yes with CCW', value: 'YES_WITH_CCW' },
  { label: 'N/A', value: 'N/A' },
]

export const COMPLIANCE_FORM_TEMPLATE_TYPE_OPTIONS = [
  { label: 'SAQ A', value: 'PCI_SAQ_A' },
  { label: 'SAQ C', value: 'PCI_SAQ_C', disabled: true },
]

export const COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING = {
  PCI_SAQ_A: 'SAQ A',
}

export const COMPLIANCE_MERCHANT_BUSINESS_OPTIONS = [
  { label: 'Retailer', value: 'retailer' },
  { label: 'Telecommunication', value: 'telecommunication' },
  { label: 'Grocery and supermarkets', value: 'grocery_and_supermarkets' },
  { label: 'Petroleum', value: 'petroleum' },
  { label: 'E-Commerce', value: 'e-commerce' },
  { label: 'Mail order / telephone order (MOTO)', value: 'mail_order_or_telephone_order' },
]

export const COMPLIANCE_MERCHANT_BUSINESS_MAPPING = {
  retailer: 'Retailer',
  telecommunication: 'Telecommunication',
  grocery_and_supermarkets: 'Grocery and supermarkets',
  petroleum: 'Petroleum',
  'e-commerce': 'E-Commerce',
  mail_order_or_telephone_order: 'Mail order / telephone order (MOTO)',
}

export const COMPLIANCE_PAYMENT_CHANNELS_OPTIONS = [
  { label: 'Mail order / telephone order (MOTO)', value: 'mail_order_or_telephone_order' },
  { label: 'E-Commerce', value: 'e-commerce' },
  { label: 'Card-present (face-to-face)', value: 'card_present' },
]

export const COMPLIANCE_PAYMENT_CHANNELS_MAPPING = {
  'mail_order_or_telephone_order': 'Mail order / telephone order (MOTO)',
  'e-commerce': 'E-Commerce',
  'card_present': 'Card-present (face-to-face)',
}

export const COMPLIANCE_FACILITY_LOCATION_OPTIONS = [
  {
    label: 'Type of facility',
    placeholder: 'e.g. Retail outlets',
    value: 'type_of_facility',
    fieldComponent: InputField,
  },
  {
    label: 'Number of facilities of this type',
    placeholder: 'e.g. 3',
    value: 'number_facilities',
    fieldComponent: InputField,
  },
  {
    label: 'Locations(s) of facility (city, country)',
    placeholder: 'e.g. Boston, MA, USA',
    value: 'locations_of_facility',
    fieldComponent: InputField,
  },
]

export const COMPLIANCE_PAYMENT_APPLICATION_OPTIONS = [
  {
    label: 'Payment application name',
    value: 'payment_application_name',
    fieldComponent: InputField,
  },
  {
    label: 'Version number',
    value: 'version_number',
    fieldComponent: InputField,
  },
  {
    label: 'Application vendor',
    value: 'application_vendor',
    fieldComponent: InputField,
  },
  {
    label: 'Is application PA-DSS Listed?',
    value: 'is_application_pa_dss_listed',
    fieldComponent: RadioButton,
    options: YES_NO_YES_NO_RADIO_OPTIONS,
    props: {
      buttonsRow: true,
    },
  },
  {
    label: 'PA-DSS Listing Expiry date (if applicable)',
    value: 'pa_dss_listing_expiry_date',
    fieldComponent: DatePickerInput,
    props: {
      autofill: false,
    },
  },
]

export const COMPLIANCE_THIRD_PARTY_SERVICE_PROVIDER_OPTIONS = [
  {
    label: 'Name of service provider',
    value: 'name_of_service_provider',
    fieldComponent: InputField,
  },
  {
    label: 'Description of services provided',
    value: 'description_of_services_provided',
    fieldComponent: TextAreaField,
  },
]

export const COMPLIANCE_ELIGIBILITY_TO_COMPLETE_SAQ_A_OPTIONS = [
  { label: 'Merchant accepts only card-not-present (e-commerce or mail/telephone-order) transactions', value: 'merchant_accepts_only_card_not_present_transactions' },
  { label: 'All processing of cardholder data is entirely outsourced to PCI DSS validated third-party service providers', value: 'all_processing_cardholder_data_outsorced_to_PCI_DSS_validated_third_party_service_providers' },
  { label: 'Merchant does not electronically store, process, or transmit any cardholder data on merchant systems or premises, but relies entirely on a third party(s) to handle all these functions', value: 'merchant_does_not_electronically_store_process_transmit_cardholder_data_on_merchant_systems_on_premises' },
  { label: 'Merchant has confirmed that all third party(s) handling storage, processing, and/or transmission of cardholder data are PCI DSS compliant', value: "merchant_has_confirmed_third_party(s)_handling_storage_processing_pci_dss_compliant" },
  { label: 'Any cardholder data the merchant retains is on paper (for example, printed reports or receipts), and these documents are not received electronically', value: 'any_cardholder_data_merchant_retains_is_on_paper_not_received_electronically' },
  { header: 'Additionally, for e-commerce channels:', label: 'All elements of the payment page(s) delivered to the consumer’s browser originate only and directly from a PCI DSS validated third-party service provider(s).', value:  "all_elements_of_payment_pages_delivered_to_consumers_browser_originate_from_pci_dss_validated_third_party_service_provider(s)" },
]

const EXPECTED_TESTING_OPTIONS = {
  observeWrittenAgreements: 'Observe written agreements.',
  reviewPoliciesAndProcedures: 'Review policies and procedures.',
  examineSystemComponents: 'Examine system components.',
  examineVendorDocumentation: 'Examine vendor documentation.',
  examineTerminatedUsersAccounts: 'Examine terminated users accounts.',
  reviewCurrentAccessLists: 'Review current access lists.',
  observeReturnPhysicalAuthenticationDevices: 'Observe returned physical authentication devices',
  observeSystemConfigurationsAndAccountSettings: 'Observe system configurations and account settings.',
  interviewPersonnel: 'Interview personnel.',
  compareSecurityPatches: 'Compare list of security patches installed to recent vendor patch lists.',
  reviewPasswordProcedures: 'Review password procedures.',
  observeAuthenticationProcesses: 'Observe authentication processes.',
  verifyPasswordParameters: 'Examine system configuration settings to verify password parameters.',
  examineUserIdLists: 'Examine user ID lists.',
  reviewPoliciesAndProceduresForPhysicallySecuringMedia: 'Review policies and procedures for physically securing media.',
  reviewPoliciesAndProceduresForDistributionOfMedia: 'Review policies and procedures for distribution of media.',
  examineMediaDistributionTrackingLogsAndDocumentation: 'Examine media distribution tracking logs and documentation.',
  reviewPeriodicMediaDestructionPoliciesAndProcedures: 'Review periodic media destruction policies and procedures.',
  observeProcesses: 'Observe processes.',
  examineSecurityOfStorageContainers: 'Examine security of storage containers.',
  reviewListOfServiceProviders: 'Review list of service providers.',
  reviewListPoliciesAndProceduresAndSupportingDocumentation: 'Review policies and procedures and supporting documentation.',
  reviewIncidentResponsePlan: 'Review the incident response plan.',
  reviewIncidentResponsePlanProcedures: 'Review incident response plan procedures.',
}

export const expectedTestingLabel = (expectedTestingOptions) => (
  <div className='description flex column'>
    <span>Expected Testing</span>
    <ul>
      { map(expectedTestingOptions, (expectedTestingOption) => {
        const option = get(EXPECTED_TESTING_OPTIONS, expectedTestingOption)

        return (<li key={option}>{option}</li>)
      })}
    </ul>
  </div>
)

export const vendorSuppliedDefaultsChangedBeforeInstallOnNetworkLabel = (
  <div className='description flex column'>
    <span>Question 2.1 (a): Are vendor-supplied defaults always changed before installing a system on the network?</span>
    <i>Note: This applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).</i>
    {expectedTestingLabel([
      'reviewPoliciesAndProcedures',
      'examineVendorDocumentation',
      'observeSystemConfigurationsAndAccountSettings',
      'interviewPersonnel',
    ])}
  </div>
)

export const unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetworkLabel = (
  <div className='description flex column'>
    <span>Question 2.1 (b): Are unnecessary default accounts removed or disabled before installing a system on the network?</span>
    {expectedTestingLabel(['reviewPoliciesAndProcedures'])}
  </div>
)

export const systemAndSoftwareProtectedFromKnownVulnerabilitiesLabel = (
  <div className='description flex column'>
    <span>Question 6.2 (a): Are all system components and software protected from known vulnerabilities by installing applicable vendor-supplied security patches?</span>
    {expectedTestingLabel(['reviewPoliciesAndProcedures'])}
  </div>
)

export const criticalSecurityPatchesInstalledWithinOneMonthOfReleaseLabel = (
  <div className='description flex column'>
    <span>Question 6.2 (b): Are critical security patches installed within one month of release?</span>
    {expectedTestingLabel(['reviewPoliciesAndProcedures', 'examineSystemComponents', 'compareSecurityPatches'])}
  </div>
)

export const usersAssignedUniqueIdBeforeAccessToSystemLabel = (
  <div className='description flex column'>
    <span>Question 8.1.1: Are all users assigned a unique ID before allowing them to access system components or cardholder data?</span>
    {expectedTestingLabel(['reviewPasswordProcedures', 'interviewPersonnel'])}
  </div>
)

export const accessForTerminatedUsersRemovedLabel = (
  <div className='description flex column'>
    <span>Question 8.1.3: Is access for any terminated users immediately deactivated or removed?</span>
    {expectedTestingLabel([
      'reviewPasswordProcedures',
      'examineTerminatedUsersAccounts',
      'reviewCurrentAccessLists',
      'observeReturnPhysicalAuthenticationDevices',
    ])}
  </div>
)

export const authenticateUsersUsingPasswordOrTokenOrBiometricLabel = (
  <div className='description flex column'>
    <span>Question 8.2: In addition to assigning a unique ID, is one or more of the following methods employed to authenticate all users?</span>
    <ul>
      <li>Something you know, such as a password or passphrase</li>
      <li>Something you have, such as a token device or smart card</li>
      <li>Something you are, such as a biometric</li>
    </ul>
    {expectedTestingLabel(['reviewPasswordProcedures', 'observeAuthenticationProcesses'])}
  </div>
)

export const userPasswordParametersConfiguredToMeetReqsLabel = (
  <div className='description flex column'>
    <span>Question 8.2.3 (a): Are user password parameters configured to require passwords/passphrases meet the following?</span>
    <ul>
      <li>A minimum password length of at least seven characters</li>
      <li>Contain both numeric and alphabetic characters</li>
      <li>Alternatively, the passwords/passphrases must have complexity and strength at least equivalent to the parameters specified above</li>
    </ul>
    {expectedTestingLabel(['verifyPasswordParameters'])}
  </div>
)

export const groupSharedAccountsPasswordsAuthenticationMethodsProhibitedLabel = (
  <div className='description flex column'>
    <span>Question 8.5: Are group, shared, or generic accounts, passwords, or other authentication methods prohibited as follows:</span>
    <ul>
      <li>Generic user IDs and accounts are disabled or removed</li>
      <li>Shared user IDs for system administration activities and other critical functions do not exist</li>
      <li>Shared and generic user IDs are not used to administer any system components</li>
    </ul>
    {expectedTestingLabel(['reviewPoliciesAndProcedures', 'examineUserIdLists', 'interviewPersonnel'])}
  </div>
)

export const allMediaPhysicallySecuredLabel = (
  <div className='description flex column'>
    <span>Question 9.5: Are all media physically secured (including but not limited to computers, removable electronic media, paper receipts, paper reports, and faxes)?</span>
    <i>For purposes of Requirement 9, "media" refers to all paper and electronic media containing cardholder data.</i>
    {expectedTestingLabel(['reviewPoliciesAndProceduresForPhysicallySecuringMedia', 'interviewPersonnel'])}
  </div>
)

export const strictControlOverDistributionOfMediaLabel = (
  <div className='description flex column'>
    <span>Question 9.6 (a): Is strict control maintained over the internal or external distribution of any kind of media?</span>
    {expectedTestingLabel(['reviewPoliciesAndProceduresForDistributionOfMedia'])}
  </div>
)

export const mediaClassifiedToDetermineSensitivityOfDataLabel = (
  <div className='description flex column'>
    <span>Question 9.6 (b): Do controls include the following:</span>
    <ul>
      <li>Is media classified so the sensitivity of the data can be determined?</li>
      <li>Is media sent by secured courier or other delivery method that can be accurately tracked?</li>
      <li>Is management approval obtained prior to moving the media (especially when media is distributed to individuals)?</li>
    </ul>
    {expectedTestingLabel(['reviewPoliciesAndProcedures'])}
  </div>
)

export const mediaSentCanBeAccuratelyTrackedLabel = (
  <div className='description flex column'>
    <span>Question 9.6.2: Is media sent by secured courier or other delivery method that can be accurately tracked?</span>
    {expectedTestingLabel(['interviewPersonnel', 'examineMediaDistributionTrackingLogsAndDocumentation'])}
  </div>
)

export const managementApprovalObtainedToMoveMediaLabel = (
  <div className='description flex column'>
    <span>Question 9.6.3: Is management approval obtained prior to moving the media (especially when media is distributed to individuals)?</span>
    {expectedTestingLabel(['interviewPersonnel', 'examineMediaDistributionTrackingLogsAndDocumentation'])}
  </div>
)

export const strictControlMaintainedOverStorageAndAccessibilityOfMediaLabel = (
  <div className='description flex column'>
    <span>Question 9.7: Is strict control maintained over the storage and accessibility of media?</span>
    {expectedTestingLabel(['reviewPoliciesAndProcedures'])}
  </div>
)

export const mediaDestroyedWhenNoLongerNeededLabel = (
  <div className='description flex column'>
    <span>Question 9.8 (a): Is all media destroyed when it is no longer needed for business or legal reasons?</span>
    {expectedTestingLabel(['reviewPeriodicMediaDestructionPoliciesAndProcedures'])}
  </div>
)

// 9.8c?

export const hardCopyMaterialsCrosscutShreddedIncineratedOrPulpedLabel = (
  <div className='description flex column'>
    <span>Question 9.8.1 (a): Are hardcopy materials cross-cut shredded, incinerated, or pulped so that cardholder data cannot be reconstructed?</span>
    {expectedTestingLabel(['reviewPeriodicMediaDestructionPoliciesAndProcedures', 'interviewPersonnel', 'observeProcesses'])}
  </div>
)

export const storageContainersForMaterialsDestroyedToPreventAccessToContentsLabel = (
  <div className='description flex column'>
    <span>Question 9.8.1 (b): Are storage containers used for materials that contain information to be destroyed secured to prevent access to the contents?</span>
    {expectedTestingLabel(['examineSecurityOfStorageContainers'])}
  </div>
)

// 12.8?

export const listOfServiceProvidersMaintainedLabel = (
  <div className='description flex column'>
    <span>Question 12.8.1: Is a list of service providers maintained, including a description of the service(s) provided?</span>
    {expectedTestingLabel(['reviewPoliciesAndProcedures', 'observeProcesses', 'reviewListOfServiceProviders'])}
  </div>
)

export const writtenAgreementMaintainedLabel = (
  <div className='description flex column'>
    <span>Question 12.8.2: Is a written agreement maintained that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process, or transmit on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment?</span>
    <i>The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party.</i>
    {expectedTestingLabel(['observeWrittenAgreements', 'reviewPoliciesAndProcedures'])}
  </div>
)

export const establishedProcessForEngagingServicesProvidersLabel = (
  <div className='description flex column'>
    <span>Question 12.8.3: Is there an established process for engaging service providers, including proper due diligence prior to engagement?</span>
    {expectedTestingLabel(['observeProcesses', 'reviewListPoliciesAndProceduresAndSupportingDocumentation'])}
  </div>
)

export const programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnuallyLabel = (
  <div className='description flex column'>
    <span>Question 12.8.4: Is a program maintained to monitor service providers’ PCI DSS compliance status at least annually?</span>
    {expectedTestingLabel(['observeProcesses', 'reviewListPoliciesAndProceduresAndSupportingDocumentation'])}
  </div>
)

export const informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntityLabel = (
  <div className='description flex column'>
    <span>Question 12.8.5: Is information maintained about which PCI DSS requirements are managed by each service provider, and which are managed by the entity?</span>
    {expectedTestingLabel(['observeProcesses', 'reviewListPoliciesAndProceduresAndSupportingDocumentation'])}
  </div>
)

export const incidentResponsePlanBeenCreatedInCaseOfSystemBreachLabel = (
  <div className='description flex column'>
    <span>Question 12.8.10 (a): Has an incident response plan been created to be implemented in the event of system breach?</span>
    {expectedTestingLabel(['reviewIncidentResponsePlan', 'reviewIncidentResponsePlanProcedures'])}
  </div>
)

export const EXPLANATION_OF_NON_APPLICABILITY_OPTIONS = [
  {
    label: 'Requirement',
    value: 'requirement',
    fieldComponent: InputField,
    placeholder: 'e.g. 3, 4',
  },
  {
    label: 'Reason requirement is not applicable',
    value: 'reason_requirement_is_not_applicable',
    fieldComponent: TextAreaField,
    placeholder: 'e.g. Cardholder data is never stored electronically',
  },
]

export const PCI_DSS_VALIDATION_OPTIONS = [
  {
    value: 'compliant',
    label: 'Compliant',
    labelHTML: (
      <div className='description flex column'>
        <span>Compliant</span>
        <span>All sections of the PCI DSS SAQ are complete, all questions answered affirmatively, resulting in an overall COMPLIANT rating; thereby (Merchant Company Name) has demonstrated full compliance with the PCI DSS.</span>
      </div>
    ),
  },
  {
    value: 'compliant_with_legal_exception',
    label: 'Compliant With Legal Exception',
    labelHTML: (
      <div className='description flex column'>
        <span>Compliant but with legal exception</span>
        <span>One or more requirements are marked "No" due to a legal restriction that prevents the requirement from being met. This option requires additional review from acquirer or payment brand.</span>
      </div>
    ),
  },
]

export const ACKNOWLEDGEMENT_OF_STATUS_OPTIONS = [
  { label: 'PCI DSS Self-Assessment Questionnaire A, Version (version of SAQ), was completed according to the instructions therein.', value: 'pci_dss_self_assesment_questionnaire_completed' },
  { label: 'All information within the above-referenced SAQ and in this attestation fairly represents the results of my assessment in all material respects.', value: 'information_within_saq_represets_results_of_my_assessment_in_all_material_respects' },
  { label: 'I have confirmed with my payment application vendor that my payment system does not store sensitive authentication data after authorization.', value: 'confimed_that_payment_system_doesnt_store_sensitive_authentication_data' },
  { label: 'I have read the PCI DSS and I recognize that I must maintain PCI DSS compliance, as applicable to my environment, at all times.', value: 'will_maintain_pci_dss_compaince' },
  { label: 'If my environment changes, I recognize I must reassess my environment and implement any additional PCI DSS requirements that apply. ', value: 'on_environment_change_reassess_environment_and_implement_additional_pci_dss_reqs' },
  { label: 'No evidence of full track data, CAV2, CVC2, CID, or CVV2 data, or PIN data storage after transaction authorization was found on ANY system reviewed during this assessment.', value: 'no_evidence_of_full_track_data' },
  { label: 'ASV scans are being completed by the PCI SSC Approved Scanning Vendor (ASV Name)', value: 'asv_scans_completed_by_pci_ssc_asv' },
]

export const COMPLIANCE_TEMPLATE_ID_SELECTOR_OPTIONS = [
  { label: 'Compliance Form Template ID', value: 'input' },
  { label: 'Compliance Form Templates Table', value: 'table' },
]

export const COMPLIANCE_APPLY_TEMPLATE_OPTIONS = [
  { label: 'Platform', value: PLATFORM },
  { label: 'Application', value: APPLICATION },
]
