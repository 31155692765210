import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import { MERCHANT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import {
  PATCH_MERCHANT_F_REQUEST,
  PATCH_PAYOUT_SETTINGS_F_REQUEST,
} from 'constants/flowConstants'

import {
  EDIT_MERCHANT,
  EDIT_PAYOUT_SETTINGS,
} from 'constants/amplitudeConstants'

import {
  PROCESSOR_WINDOW,
  STANDARD_BATCH_TIME_VALUE,
} from 'constants/payoutSettingsConstants'

const submitEditPayoutSettingsForm = (values, dispatch, props) => {
  const { id } = values
  const { credentials, merchantIdentityId, merchantId } = props
  const credentialId = get(credentials, 'id')
  const payoutSettingsValues = omit(values, ['readyToSettleUpon', 'feeReadyToSettleUpon'])
  const readyToSettleUpon = get(values, 'readyToSettleUpon')
  const feeReadyToSettleUpon = get(values, 'feeReadyToSettleUpon')
  // if they select standard batch time, reset their merchant ready to settle upon values
  const batchTime = get(values, 'batchTime')
  const merchantValues = batchTime === STANDARD_BATCH_TIME_VALUE ? { readyToSettleUpon: PROCESSOR_WINDOW, feeReadyToSettleUpon: PROCESSOR_WINDOW } : { readyToSettleUpon, feeReadyToSettleUpon }
  const emptyMerchantValues = isEmpty(removeUndefined(merchantValues))

  dispatch({
    type: PATCH_PAYOUT_SETTINGS_F_REQUEST,
    payload: {
      values: payoutSettingsValues,
      credentials,
      id,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_PAYOUT_SETTINGS, {
        credentials,
        values: payoutSettingsValues,
      }),
      successCallback: () => {
        if (!emptyMerchantValues) {
          dispatch({
            type: PATCH_MERCHANT_F_REQUEST,
            payload: {
              values: merchantValues,
              credentials,
              id: merchantId,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(EDIT_MERCHANT, {
                credentials,
                values: merchantValues,
              }),
              successCallback: () => {
                dispatch(redirectRequest({ path: `${MERCHANT_PATH({ credentialId, merchantId, identityId: merchantIdentityId })}?tab=merchant-payout-settings` }))
              },
            },
          })
        } else {
          dispatch(redirectRequest({ path: `${MERCHANT_PATH({ credentialId, merchantId, identityId: merchantIdentityId })}?tab=merchant-payout-settings` }))
        }
      },
    },
  })
}

export default submitEditPayoutSettingsForm
