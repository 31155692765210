import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import CreateSubscriptionForm from './CreateSubscriptionForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import showModalAction from 'utilities/actions/showModalAction'
import getIdentityPaymentInstrumentsRequest from 'utilities/actions/get/getIdentityPaymentInstrumentsRequest'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import getMany from 'utilities/get/getMany'
import formatAddress from 'utilities/formatters/formatAddress'
import getApplicationIdentitiesRequest from 'utilities/actions/get/getApplicationIdentitiesRequest'
import getSubscriptionPlanRequest from 'utilities/actions/get/getSubscriptionPlanRequest'
import getSubscriptionPlansRequest from 'utilities/actions/get/getSubscriptionPlansRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getUrlQuery from 'utilities/get/getUrlQuery'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { getCardBrand } from 'constants/bankConstants'
import { SHOW_ACH_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_SUBSCRIPTION_FORM } from 'constants/formConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import map from 'lodash/map'
import concat from 'lodash/concat'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'
import head from 'lodash/head'

import {
  ADD_ICON,
  BANK_ICON,
} from 'constants/iconConstants'

import {
  intervalTypeMap,
  DAY,
} from 'constants/subscriptionsConstants'

import {
  CREATE_PAYMENT_INSTRUMENT_MODAL,
  FEATURE_NOT_AVAILABLE_MODAL,
  NEW_BUYER_IDENTITY_MODAL,
  SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
} from 'constants/modalConstants'

import {
  TRIAL_START_DATE,
  TRIAL_DURATION,
  ADD_NEW_PAYMENT_INSTRUMENT,
  RECURRING_PRICE,
  COLLECTION_METHOD,
  BILLING_FREQUENCY,
  ADD_NEW_BUYER,
  SUBSCRIPTIONS_ARE_NOT_AVAILABLE,
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTIONS,
} from 'constants/language/languageConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

import {
  getIdentitiesSelector,
  getMerchantsSelector,
  getPaymentInstrumentsSelector,
  getSubscriptionPlanSelector,
  getSubscriptionPlansSelector,
} from 'state-layer/selectors'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
  option: {},
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `subscriptionsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const environment = get(credentials, 'environment')
  const applicationMerchants = getMerchantsSelector(state)
  const applicationIdentities = getIdentitiesSelector(state)
  const subscriptionPlans = getSubscriptionPlansSelector(state)
  const formSelector = formValueSelector(CREATE_SUBSCRIPTION_FORM)
  const selectedIdentityId = formSelector(state, 'buyerIdentityId.value') || getUrlQuery('identityId')
  const selectedPaymentInstrumentId = formSelector(state, 'buyerInstrumentId.value')
  const identities = getIdentitiesSelector(state)
  const buyerIdentity = getIdentitiesSelector(state, selectedIdentityId)
  const buyerIdentityId = get(formSelector(state, 'buyerIdentityId'), 'value')
  const enableTrialPhase = formSelector(state, 'enableTrialPhase')
  const createSubscriptionWithoutPlanCheckbox = formSelector(state, 'createSubscriptionWithoutPlanCheckbox')
  const selectedPlan = formSelector(state, 'plan')
  const selectedPlanId = get(selectedPlan, 'value')
  const plan = getSubscriptionPlanSelector(state, selectedPlanId)
  const intervalType = get(plan, 'intervalType')
  const displayInterval = intervalTypeMap[intervalType]
  const identityPaymentInstruments = getPaymentInstrumentsSelector(state)
  const linkedTo = getUrlQuery('merchantId')
  const canUseACHPayment = getFeatureFlag(SHOW_ACH_VIRTUAL_TERMINAL)
  const subscriptionPlanId = getUrlQuery('subscriptionPlanId')
  const currentPlanId = selectedPlanId || subscriptionPlanId
  const subscriptionPlanInfo = getSubscriptionPlanSelector(state, currentPlanId)
  const createCustomPlan = createSubscriptionWithoutPlanCheckbox
  const allowedProcessors = isEqual(environment, LIVE_ENVIRONMENT) ? [LITLE_V1, FINIX_V1] : [DUMMY_V1]
  const filteredApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const identitiesWithFullName = filter(identities, (identity) => !!get(identity, 'fullName'))
  const isFetchingIdentities = get(state, `identitiesR.${FETCHING}`, true)
  const isFetchingSubscriptionPlans = get(state, `subscriptionPlansR.${FETCHING}`, true)

  const identitiesNames = map(identitiesWithFullName, (identity) => {
    const {
      id,
      fullName,
      email,
    } = identity

    return {
      label: <div className='flex items-center'>{fullName} <span className='secondary'>({ email ? email : '-' })</span></div>,
      value: id,
    }
  })

  const sortedIdentitiesNames = concat({ label: <div className='flex items-center'><span className={`add-icon fa fa-${ADD_ICON}`} /> {ADD_NEW_BUYER}</div>, value: 'newBuyer' }, identitiesNames)

  const [
    merchantIdentity,
    amountFromSelectedPlan,
    intervalCount,
    displayAmount,
    displayBillingInterval,
    displayCollectionMethod,
    trialDefaults,
    displayTrialStartDate,
    displayTrialDuration,
  ] = getMany(subscriptionPlanInfo, [
    'linkedTo',
    'amount',
    'intervalCount',
    'displayAmount',
    'displayBillingInterval',
    'displayCollectionMethod',
    'trialDefaults',
    'displayTrialStartDate',
    'displayTrialDuration',
  ])

  const paymentInstrumentOptions = map(identityPaymentInstruments, (paymentInstrument) => {
    const {
      id,
      brand,
      expirationDate,
      maskedFullCardNumber,
      maskedAccountNumber,
      type,
    } = paymentInstrument

    const cardIcon = getCardBrand(brand)
    const bankAccountOptionLabel = <div className='flex items-center'><i className={`bank-icon fa fa-${BANK_ICON}`} />{maskedAccountNumber}</div>
    const cardOptionLabel = <div className='flex items-center'><i className={`card-brand-icon ${cardIcon}`} />{maskedFullCardNumber}<span className='card-expiration-date secondary'>({expirationDate})</span></div>

    return {
      label: type === 'Bank Account' ? bankAccountOptionLabel : cardOptionLabel,
      value: id,
    }
  })

  const identityPaymentInstrumentOptions = concat(
    {
      label: (
        <div className='add-new-payment-instrument flex items-center'>
          <span className={`add-icon fa fa-${ADD_ICON}`} />
          {ADD_NEW_PAYMENT_INSTRUMENT}
        </div>),
      value: 'newPaymentInstrument',
    }, paymentInstrumentOptions,
  )

  const [
    platformId,
    applicationId,
  ] = getMany(currentUser, [
    'platformId',
    'applicationId',
  ])

  const subscriptionPlansOptions = map(subscriptionPlans, ({ id, planName }) => {
    return {
      label: planName ? planName : '-',
      value: id,
    }
  })

  const applicationIdentityNameOptions = map(applicationIdentities, (identity) => {
    const {
      id,
      businessName,
      doingBusinessAs,
      fullName,
      businessAddress,
      personalAddress,
    } = identity

    const name = businessName || doingBusinessAs || fullName || '-'
    const address = businessAddress || personalAddress || '-'

    return {
      label: (
        <>
          <div className='primary'>{name}</div>
          <div className='secondary'>{formatAddress({ address })}</div>
        </>
      ),
      value: id,
      data: {
        id,
        name,
        address,
      },
    }
  })

  const initialValues = (subscriptionPlanId && get(state, `subscriptionPlansR.${FETCHING}`, true)) ? undefined : {
    createSubscriptionWithoutPlanCheckbox: !subscriptionPlanId,
    trialIntervalCount: 0,
    trialIntervalType: { label: 'Days', value: DAY },
    plan: !isEmpty(subscriptionPlanInfo) ? { label: get(subscriptionPlanInfo, 'planName'), value: get(subscriptionPlanInfo, 'id') } : undefined,
  }

  const trialPhaseSectionData = [
    {
      label: TRIAL_START_DATE,
      value: displayTrialStartDate,
    },
    {
      label: TRIAL_DURATION,
      value: !isEmpty(trialDefaults) ? displayTrialDuration : '-',
    },
  ]

  const subscriptionPhaseSectionData = [
    {
      label: RECURRING_PRICE,
      value: displayAmount,
    },
    {
      label: BILLING_FREQUENCY,
      value: displayBillingInterval,
    },
    {
      label: COLLECTION_METHOD,
      value: displayCollectionMethod,
    },
  ]


  return {
    credentials,
    platformId,
    applicationId,
    selectedIdentityId,
    applicationMerchants,
    applicationIdentityNameOptions,
    identityPaymentInstrumentOptions,
    reactSelectCustomStyles,
    isPosting: isFetching,
    linkedTo,
    selectedPaymentInstrumentId,
    environment,
    canUseACHPayment,
    showTrialPhaseDetails: enableTrialPhase,
    initialValues,
    subscriptionPlanId,
    selectedPlanId,
    plan,
    subscriptionPlansOptions,
    displayInterval,
    createCustomPlan,
    subscriptionPlanInfo,
    merchantIdentity,
    amountFromSelectedPlan,
    intervalCount,
    trialPhaseSectionData,
    subscriptionPhaseSectionData,
    identityPaymentInstruments,
    createSubscriptionWithoutPlanCheckbox,
    buyerIdentity,
    sortedIdentitiesNames,
    buyerIdentityId,
    filteredApplicationMerchants,
    isFetchingIdentities,
    isFetchingSubscriptionPlans,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationIdentities: ({ applicationId, credentials, queries }) => dispatch(getApplicationIdentitiesRequest({ applicationId, credentials, queries })),
    getIdentityPaymentInstruments: ({ identityId, credentials }) => dispatch(getIdentityPaymentInstrumentsRequest({ identityId, credentials })),
    showCreatePaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_MODAL, className: 'modal-md no-pad', modalProps })),
    updateCreateSubscriptionFormField: ({ value, field }) => dispatch(change(CREATE_SUBSCRIPTION_FORM, field, value)),
    clearReducer: (reducerNames) => dispatch(clearReducerRequest(reducerNames)),
    getSubscriptionPlan: ({ credentials, subscriptionPlanId }) => { dispatch(getSubscriptionPlanRequest({ credentials, subscriptionPlanId })) },
    getSubscriptionPlans: ({ credentials, queries }) => dispatch(getSubscriptionPlansRequest({ credentials, queries })),
    showNewBuyerIdentityModal: (modalProps) => dispatch(showModalAction({ modalType: NEW_BUYER_IDENTITY_MODAL, modalProps })),
    showFeatureNotAvailableModal: () => dispatch(showModalAction({
      modalType: FEATURE_NOT_AVAILABLE_MODAL,
      modalProps: {
        canCloseModal: false,
        title: SUBSCRIPTIONS_ARE_NOT_AVAILABLE,
        className: 'SubscriptionsNotAvailableModal',
        description: MERCHANT_ACCOUNT_IS_REQUIRED_TO_CREATE_SUBSCRIPTIONS,
      },
    })),
    showSelectSenderForCreatePaymentModal: () => dispatch(showModalAction({
      modalType: SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
      className: 'modal-md no-pad overflow-visible',
      modalProps:
        { canCloseModal: false,
          isCreateSubscriptionFlow: true,
        } })),
  }
}

class CreateSubscriptionFormC extends Component {
  componentDidMount() {
    const {
      applicationId,
      credentials,
      getApplicationIdentities,
      clearReducer,
      getSubscriptionPlan,
      subscriptionPlanId,
      getSubscriptionPlans,
    } = this.props

    if (applicationId) {
      clearReducer(['identitiesR', 'paymentInstrumentsR'])
      getApplicationIdentities({ applicationId, credentials, queries: { limit: 100 } })
      getSubscriptionPlans({ credentials })
    }

    if (subscriptionPlanId) {
      getSubscriptionPlan({ credentials, subscriptionPlanId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId,
      selectedIdentityId,
      createSubscriptionWithoutPlanCheckbox,
      getApplicationIdentities,
      getIdentityPaymentInstruments,
      credentials,
      showCreatePaymentInstrumentModal,
      selectedPaymentInstrumentId,
      updateCreateSubscriptionFormField,
      environment,
      canUseACHPayment,
      clearReducer,
      getSubscriptionPlans,
      showNewBuyerIdentityModal,
      buyerIdentityId,
      linkedTo,
      applicationMerchants,
      filteredApplicationMerchants,
      showFeatureNotAvailableModal,
      showSelectSenderForCreatePaymentModal,
      isFetchingIdentities,
    } = this.props

    const {
      applicationId: prevApplicationId,
      selectedIdentityId: prevSelectedIdentityId,
      selectedPaymentInstrumentId: prevSelectedPaymentInstrumentId,
      createSubscriptionWithoutPlanCheckbox: prevCreateSubscriptionWithoutPlanCheckbox,
      buyerIdentityId: prevBuyerIdentityId,
      isFetchingIdentities: prevIsFetchingIdentities,
    } = prevProps

    const filteredApplicationMerchantsSize = size(filteredApplicationMerchants)

    if (applicationId && applicationId !== prevApplicationId) {
      showSelectSenderForCreatePaymentModal()
    }

    if (!linkedTo) {
      if (prevIsFetchingIdentities === true && isFetchingIdentities === false && !isEmpty(applicationMerchants)) {
        if (filteredApplicationMerchantsSize === 0) {
          showFeatureNotAvailableModal()
        }

        if (filteredApplicationMerchantsSize === 1) {
          const { id } = head(filteredApplicationMerchants)
          updateUrlQueries({ merchantId: id })
        }

        if (filteredApplicationMerchantsSize > 1) {
          showSelectSenderForCreatePaymentModal()
        }
      }
    }

    const showNewBuyerModal = prevBuyerIdentityId !== buyerIdentityId && buyerIdentityId === 'newBuyer'
    const showNewPaymentMethodModal = prevSelectedPaymentInstrumentId !== selectedPaymentInstrumentId && selectedPaymentInstrumentId === 'newPaymentInstrument'

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationIdentities({ applicationId, credentials, queries: { limit: 100 } })
    }

    if (selectedIdentityId && selectedIdentityId !== prevSelectedIdentityId && selectedIdentityId !== 'newBuyer') {
      if (prevSelectedIdentityId) {
        updateCreateSubscriptionFormField({ field: 'buyerInstrumentId', value: null })
      }

      clearReducer(['paymentInstrumentsR'])
      getIdentityPaymentInstruments({ identityId: selectedIdentityId, credentials })
    }

    if (createSubscriptionWithoutPlanCheckbox && !prevCreateSubscriptionWithoutPlanCheckbox) {
      getSubscriptionPlans({ credentials })
    }

    if (showNewBuyerModal) {
      updateCreateSubscriptionFormField({ field: 'buyerIdentityId', value: null })
      showNewBuyerIdentityModal({
        successFunction: (newIdentityId, newFullName, newEmail) => updateCreateSubscriptionFormField({ field: 'buyerIdentityId', value: { value: newIdentityId, label: `${newFullName} (${newEmail})` } }),
      })
    }

    if (showNewPaymentMethodModal) {
      updateCreateSubscriptionFormField({ field: 'buyerInstrumentId', value: null })
      showCreatePaymentInstrumentModal({
        modalProps: credentials,
        applicationId,
        selectedIdentityId,
        environment,
        showTokenForm: canUseACHPayment,
        formName: CREATE_SUBSCRIPTION_FORM,
        fieldName: 'buyerInstrumentId',
        buyerIdentityId: selectedIdentityId,
      })
    }
  }

  render() {
    return (
      <CreateSubscriptionForm
        {...this.props}
        toggleSelectWithoutPlan={this.toggleSelectWithoutPlan}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionFormC)
