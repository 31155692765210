import React, { Component } from 'react'
import { change, formValueSelector } from 'redux-form'
import HostedMerchantOnboardingConfirmation from './HostedMerchantOnboardingConfirmation'
import { connect } from 'react-redux'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getGuestOnboardingFormFilesRequest from 'utilities/actions/get/getGuestOnboardingFormFilesRequest'
import { FETCHING } from 'constants/reducerConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import { EDD_FILE_TYPES } from 'constants/eddDocumentConstants'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { MERCHANT_IDENTITY_CONFIRMATION_FORM } from 'constants/formConstants'
import getMany from 'utilities/get/getMany'
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import head from 'lodash/head'
import size from 'lodash/size'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  BIOMETRIC_COLLECTION_REQUIRED,
  BIOMETRIC_COLLECTION_NOT_COLLECTED,
} from 'constants/underwritingProfileConstants'

import {
  FINIX_CANADA_TERMS_OF_SERVICE_URL,
  FINIX_PRIVACY_POLICY_URL,
  FINIX_TERMS_OF_SERVICE_URL,
  FINIX_V1_TERMS_OF_SERVICE_URL,
} from 'constants/urlConstants'

import {
  getFileSelector,
  getPlaidTokenMetadataSelector,
  getOnboardingFormDataItemSelector,
} from 'state-layer/selectors'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  ADDRESS,
  AMERICAN_EXPRESS_MID,
  ANNUAL_CARD_VOLUME,
  AVERAGE_CARD_TRANSACTION_AMOUNT,
  BANK_DOCUMENT_PROVIDED,
  BUSINESS_ADDRESS,
  BUSINESS_DESCRIPTION,
  BUSINESS_PHONE,
  BUSINESS_TYPE,
  DATE_OF_BIRTH,
  DATE_OF_INCORPORATION,
  DEFAULT_STATEMENT_DESCRIPTOR,
  DISCOVERY_MID,
  EMAIL_ADDRESS,
  INSTITUTION_NUMBER,
  LEGAL_BUSINESS_NAME,
  MERCHANT_CATEGORY_CODE,
  OWNERSHIP_TYPE,
  PHONE_NUMBER,
  PROCESSED_CARDS_BEFORE,
  REFUND_POLICY,
  ROUTING_NUMBER,
  TAX_AUTHORITY,
  TAX_ID,
  TRANSIT_NUMBER,
  WEBSITE,
  YES,
  NO,
  DOING_BUSINESS_AS,
  BUSINESS_TAX_ID,
  MAX_CARD_TRANSACTION_AMOUNT,
  ONLINE_PAYMENTS,
  IN_PERSON_PAYMENTS,
  MAIL_TELEPHONE_ORDER,
  BUSINESS_TO_BUSINESS,
  BUSINESS_TO_CONSUMER,
  OTHER,
  ACCOUNT_HOLDER_NAME,
  BANK_NAME,
  BANK_ACCOUNT,
  SUCCESSFULLY_LINKED,
} from 'constants/language/languageConstants'

import {
  achMaxTransactionAmountLabelMap,
  annualACHVolumeLabelMap,
  averageACHTransferAmountLabelMap,
} from 'constants/labelConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(MERCHANT_IDENTITY_CONFIRMATION_FORM)
  const inquiryId = formSelector(state, 'inquiryId')
  const isFetchingOnboardingFormData = get(state, `onboardingFormDataR.${FETCHING}`)

  const [
    onboardingFormId,
    termsOfServiceUrl,
    applicationName,
    feeProfileUrl,
    underwritingProfile,
  ] = getMany(props, [
    'recordId',
    'termsOfServiceUrl',
    'applicationName',
    'feeProfileUrl',
    'underwritingProfile',
  ])

  const onboardingForm = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const biometricDataCollection = get(underwritingProfile, 'biometricDataCollection')
  const biometricDataCollectionRequired = biometricDataCollection === BIOMETRIC_COLLECTION_REQUIRED
  const biometricDataNotCollected = biometricDataCollection === BIOMETRIC_COLLECTION_NOT_COLLECTED

  const [
    businessData,
    controlPerson,
    beneficialOwners,
    processingData,
    bankAccount,
    country,
    processors,
    associatedFiles,
  ] = getMany(onboardingForm, [
    'businessData',
    'controlPerson',
    'beneficialOwners',
    'processingData',
    'bankAccountData',
    'country',
    'processorGatewayDetails.processors',
    'associatedFiles',
  ])

  const isBoardingToFinixV1Only = size(processors) === 1 && head(processors) === FINIX_V1

  const eddFiles = filter(associatedFiles, (associatedFile) => includes(EDD_FILE_TYPES, get(associatedFile, 'type')))

  const eddFilesWithData = map(eddFiles, (eddFile) => ({
    ...eddFile,
    fileData: getFileSelector(state, get(eddFile, 'id')),
  }))

  // TODO: Look into whether it makes sense to group these in the model, if they happen tp have access to the fileData at that point
  const groupedEDDFiles = groupBy(eddFilesWithData, 'type')

  const [
    businessName,
    doingBusinessAs,
    businessDescription,
    website,
    ownershipType,
    displayBusinessPhone,
    businessAddress,
    businessType,
    displayBusinessType,
    displayIncorporationDate,
    businessTaxId,
  ] = getMany(businessData, [
    'businessName',
    'doingBusinessAs',
    'businessDescription',
    'website',
    'displayOwnershipType',
    'displayBusinessPhone',
    'businessAddress',
    'businessType',
    'displayBusinessType',
    'displayIncorporationDate',
    'businessTaxId',
  ])

  const [
    title,
    firstName,
    lastName,
    displayDateOfBirth,
    displayPhone,
    email,
    personalAddress,
    displayPrincipalPercentageOwnership,
    taxId,
  ] = getMany(controlPerson, [
    'title',
    'firstName',
    'lastName',
    'displayDateOfBirth',
    'displayPhone',
    'email',
    'personalAddress',
    'displayPrincipalPercentageOwnership',
    'taxId',
  ])

  const [
    displayRefundPolicy,
    displayAverageACHTransferAmount,
    displayAverageCardTransferAmount,
    displayAnnualACHVolume,
    displayBusinessToBusinessVolumePercentage,
    displayBusinessToConsumerVolumePercentage,
    displayOtherVolumePercentage,
    displayEcommercePercentage,
    displayCardPresentPercentage,
    displayMailOrderTelephoneOrderPercentage,
    mcc,
    taxAuthority,
    displayPreviouslyProcessedCreditCards,
    amexMid,
    discoverMid,
    displayMaxTransactionAmount,
    displayAchMaxTransactionAmount,
    displayAnnualCardVolume,
    defaultStatementDescriptor,
  ] = getMany(processingData, [
    'displayRefundPolicy',
    'displayAverageACHTransferAmount',
    'displayAverageCardTransferAmount',
    'displayAnnualACHVolume',
    'displayBusinessToBusinessVolumePercentage',
    'displayBusinessToConsumerVolumePercentage',
    'displayOtherVolumePercentage',
    'displayEcommercePercentage',
    'displayCardPresentPercentage',
    'displayMailOrderTelephoneOrderPercentage',
    'mcc',
    'taxAuthority',
    'displayPreviouslyProcessedCreditCards',
    'amexMid',
    'discoverMid',
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
    'displayAnnualCardVolume',
    'defaultStatementDescriptor',
  ])

  const [
    name,
    bankCode,
    accountNumber,
    displayAccountType,
    transitNumber,
    institutionNumber,
    bankDocumentId,
    plaidProcessorToken,
  ] = getMany(bankAccount, [
    'name',
    'bankCode',
    'accountNumber',
    'displayAccountType',
    'transitNumber',
    'institutionNumber',
    'bankDocument.id',
    'plaidProcessorToken',
  ])

  // show plaid metadata if available
  const plaidTokenMetadata = getPlaidTokenMetadataSelector(state)

  const [
    plaidBankName,
    plaidAccountType,
    plaidMaskedAccountNumber,
  ] = getMany(plaidTokenMetadata, [
    'institution.name',
    'account.subtype',
    'account.mask',
  ])

  const businessInfoData = [
    {
      label: LEGAL_BUSINESS_NAME,
      value: businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
    },
    {
      label: BUSINESS_DESCRIPTION,
      value: businessDescription,
    },
    {
      label: BUSINESS_TYPE,
      value: displayBusinessType,
    },
    {
      label: OWNERSHIP_TYPE,
      value: ownershipType,
    },
    {
      label: DATE_OF_INCORPORATION,
      value: displayIncorporationDate,
    },
    {
      label: BUSINESS_TAX_ID,
      value: businessTaxId,
    },
    {
      label: WEBSITE,
      value: website,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
    },
  ]

  const controlPersonData = [{
    largeTitle: `${firstName} ${lastName}`,
    asideTitle: `(${title}, ${displayPrincipalPercentageOwnership})`,
    data: [
      { label: DATE_OF_BIRTH, value: displayDateOfBirth },
      { label: TAX_ID, value: taxId },
      { label: EMAIL_ADDRESS, value: email },
      { label: PHONE_NUMBER, value: displayPhone },
      { label: ADDRESS, value: <Address address={personalAddress} /> },
    ],
  }]

  const beneficialOwnersData = map(beneficialOwners, beneficialOwner => {
    const [
      ownerTitle,
      ownerFirstName,
      ownerLastName,
      ownerDateOfBirth,
      ownerPhone,
      ownerEmail,
      ownerPersonalAddress,
      ownerPrincipalPercentageOwnership,
      ownerTaxId,
    ] = getMany(beneficialOwner, [
      'title',
      'firstName',
      'lastName',
      'displayDateOfBirth',
      'displayPhone',
      'email',
      'personalAddress',
      'displayPrincipalPercentageOwnership',
      'taxId',
    ])

    return {
      largeTitle: `${ownerFirstName} ${ownerLastName}`,
      asideTitle: `(${ownerTitle}, ${ownerPrincipalPercentageOwnership})`,
      data: [
        { label: DATE_OF_BIRTH, value: ownerDateOfBirth },
        { label: TAX_ID, value: ownerTaxId },
        { label: EMAIL_ADDRESS, value: ownerEmail },
        { label: PHONE_NUMBER, value: ownerPhone },
        { label: ADDRESS, value: <Address address={ownerPersonalAddress} /> },
      ],
    }
  })

  const processingDetailsData = [
    {
      label: MERCHANT_CATEGORY_CODE,
      value: mcc,
    },
    {
      label: TAX_AUTHORITY,
      value: taxAuthority,
      condition: !!taxAuthority,
    },
    {
      label: DEFAULT_STATEMENT_DESCRIPTOR,
      value: defaultStatementDescriptor,
    },
    // {
    //   label: PROCESSED_CARDS_BEFORE,
    //   value: displayPreviouslyProcessedCreditCards,
    // },
    {
      label: AMERICAN_EXPRESS_MID,
      value: amexMid,
      condition: isBoardingToFinixV1Only && displayPreviouslyProcessedCreditCards === 'Yes',
    },
    // {
    //   label: DISCOVERY_MID,
    //   value: discoverMid,
    //   condition: displayPreviouslyProcessedCreditCards === 'Yes',
    // },
    {
      label: REFUND_POLICY,
      value: displayRefundPolicy,
    },
    {
      label: ANNUAL_CARD_VOLUME,
      value: displayAnnualCardVolume,
    },
    {
      label: annualACHVolumeLabelMap(country),
      value: displayAnnualACHVolume,
    },
    {
      label: AVERAGE_CARD_TRANSACTION_AMOUNT,
      value: displayAverageCardTransferAmount,
    },
    {
      label: averageACHTransferAmountLabelMap(country),
      value: displayAverageACHTransferAmount,
    },
    {
      label: MAX_CARD_TRANSACTION_AMOUNT,
      value: displayMaxTransactionAmount,
    },
    {
      label: achMaxTransactionAmountLabelMap(country),
      value: displayAchMaxTransactionAmount,
    },
  ]

  const cardVolumeData = [
    {
      label: IN_PERSON_PAYMENTS,
      value: displayCardPresentPercentage,
    },
    {
      label: ONLINE_PAYMENTS,
      value: displayEcommercePercentage,
    },
    {
      label: MAIL_TELEPHONE_ORDER,
      value: displayMailOrderTelephoneOrderPercentage,
    },
  ]

  const paymentVolumeData = [
    {
      label: BUSINESS_TO_BUSINESS,
      value: displayBusinessToBusinessVolumePercentage,
    },
    {
      label: BUSINESS_TO_CONSUMER,
      value: displayBusinessToConsumerVolumePercentage,
    },
    {
      label: OTHER,
      value: displayOtherVolumePercentage,
    },
  ]

  const bankAccountData = plaidProcessorToken ? [
    {
      label: BANK_NAME,
      value: plaidBankName,
      condition: !!plaidMaskedAccountNumber,
    },
    {
      label: ACCOUNT_TYPE,
      value: capitalize(plaidAccountType),
      condition: !!plaidMaskedAccountNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: `**${plaidMaskedAccountNumber}`,
      condition: !!plaidMaskedAccountNumber,
    },
    {
      label: BANK_ACCOUNT,
      value: <div><i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />{SUCCESSFULLY_LINKED}</div>,
      condition: isEmpty(plaidMaskedAccountNumber),
    },
  ] : [
    {
      label: ACCOUNT_HOLDER_NAME,
      value: name,
    },
    {
      label: ACCOUNT_TYPE,
      value: displayAccountType,
    },
    {
      label: ROUTING_NUMBER,
      value: bankCode,
      condition: country === USA,
    },
    {
      label: TRANSIT_NUMBER,
      value: transitNumber,
      condition: country === CAN,
    },
    {
      label: INSTITUTION_NUMBER,
      value: institutionNumber,
      condition: country === CAN,
    },
    {
      label: ACCOUNT_NUMBER,
      value: accountNumber,
    },
    {
      label: BANK_DOCUMENT_PROVIDED,
      value: bankDocumentId ? YES : NO,
      condition: country === CAN,
    },
  ]

  const finixTermsOfServiceUrl = isEqual(country, CAN) ? FINIX_CANADA_TERMS_OF_SERVICE_URL : (isBoardingToFinixV1Only ? FINIX_V1_TERMS_OF_SERVICE_URL : FINIX_TERMS_OF_SERVICE_URL)

  const merchantOnboardingConfirmationCheckboxString = (
    <div>
      You agree to&nbsp;
      <a href={termsOfServiceUrl} className='text-link' target='blank'>{applicationName ? `the ${applicationName}` : 'our'} Terms of Service</a>
      &nbsp;and <a href={feeProfileUrl} className='text-link' target='blank'>fees.</a>
      &nbsp;You agree that any personal information provided in this form may be used by Finix in accordance with the&nbsp;
      <a href={finixTermsOfServiceUrl} className='text-link' target='blank'>Finix Terms of Service</a> and <a className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>Privacy Policy.</a>
      &nbsp;You certify that all the information you provided is complete and correct and that you are authorized to sign on behalf of your business.
    </div>
  )

  return {
    onboardingFormId,
    businessInfoData,
    controlPersonData,
    beneficialOwnersData,
    processingDetailsData,
    cardVolumeData,
    paymentVolumeData,
    bankAccountData,
    merchantOnboardingConfirmationCheckboxString,
    businessType,
    isFetchingOnboardingFormData,
    country,
    isBoardingToFinixV1Only,
    inquiryId,
    biometricDataCollection,
    biometricDataCollectionRequired,
    biometricDataNotCollected,
    groupedEDDFiles,
    associatedFiles,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOnboardingFormFiles: ({ onboardingFormId, fileIds }) => { dispatch(getGuestOnboardingFormFilesRequest({ onboardingFormId, fileIds })) },
})

class HostedMerchantOnboardingConfirmationC extends Component {
  componentDidMount () {
    const {
      associatedFiles,
      onboardingFormId,
      getOnboardingFormFiles,
    } = this.props

    const fileIds = map(associatedFiles, 'id')

    if (!isEmpty(fileIds)) {
      getOnboardingFormFiles({ onboardingFormId, fileIds })
    }
  }

  personaInquiryOnComplete = ({ inquiryId }) => {
    const { dispatch } = this.props
    dispatch(change(MERCHANT_IDENTITY_CONFIRMATION_FORM, 'inquiryId', inquiryId))
  }

  render() {
    return (
      <HostedMerchantOnboardingConfirmation
        {...this.props}
        personaInquiryOnComplete={this.personaInquiryOnComplete}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HostedMerchantOnboardingConfirmationC)
