import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantAccountInsights from './MerchantAccountsInsights'
import getMerchantChartsRequest from 'utilities/actions/get/getMerchantChartsRequest'
import { isFlexPlatform } from 'constants/flexConstants'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'


const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `chartsR.${FETCHING}`, true)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})
  const isFlex = isFlexPlatform()
  const isRolePlatformCredential = isRolePlatform({ credentials })
  return {
    isFetching,
    isFlex,
    isRolePlatformCredential,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantAccountCharts: ({ queries }) => dispatch(getMerchantChartsRequest({ queries })),
  }
}

class MerchantAccountsInsightsC extends Component {
  componentDidMount() {
    const {
      getMerchantAccountCharts,
    } = this.props
    getMerchantAccountCharts({
      queries: {
        tab: 'merchant-accounts',
      },
    })
  }

  render() {
    return (
      <MerchantAccountInsights
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantAccountsInsightsC)
