import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import IdentityPrincipalsForm from './IdentityPrincipalsForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitAssociatedIdentityForm from 'utilities/submit/submitAssociatedIdentityForm'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { IDENTITY_PRINCIPALS_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const onSubmit = get(props, 'onSubmit', submitAssociatedIdentityForm)
  const initialValues = get(props, 'initialValues', { principals: [{}] })
  const identityId = get(props, 'params.identityId')

  const formSelector = formValueSelector(IDENTITY_PRINCIPALS_FORM)
  const formPrinciples = formSelector(state, 'principals')

  const totalPrincipalPercentageOwnership = reduce(formPrinciples, (total, principal) => {
    const {
      principalPercentageOwnership = '0',
    } = principal

    return total + parseFloat(principalPercentageOwnership)
  }, 0)

  return {
    credentials,
    onSubmit,
    initialValues,
    identityId,
    formPrinciples,
    totalPrincipalPercentageOwnership,
  }
}

class IdentityPrincipalFormC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      displayPrincipal: 0,
    }
  }

  updateDisplayPrincipal = (idx) => {
    this.setState({
      displayPrincipal: idx,
    })
  }

  removePrincipal = (idx) => {
    const {
      dispatch,
      formPrinciples,
    } = this.props

    this.updateDisplayPrincipal(idx - 1)
    formPrinciples.splice(idx, 1)
    dispatch(change(IDENTITY_PRINCIPALS_FORM, 'principals', formPrinciples))
  }

  render() {
    const { displayPrincipal } = this.state

    const {
      updateDisplayPrincipal,
      removePrincipal,
    } = this

    return (
      <IdentityPrincipalsForm
        {...this.props}
        displayPrincipal={displayPrincipal}
        updateDisplayPrincipal={updateDisplayPrincipal}
        removePrincipal={removePrincipal}
      />
    )
  }
}

export default connect(mapStateToProps)(IdentityPrincipalFormC)
