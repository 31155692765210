import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDateWithTimeZoneAbrreviation from 'utilities/formatters/formatDateWithTimeZoneAbrreviation'
import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import { TIME_FORMAT } from 'constants/timeConstants'
import subtract from 'lodash/subtract'
import numeral from 'numeral'

const frontendCurrentUsageModel = ({ data: currentUsage }) => {
  const [
    linkedTo,
    linkedType,
    type,
    currency,
    achVelocity,
    cardVelocity,
    balanceAdjustmentVelocity,
  ] = getMany(currentUsage, [
    'linked_to',
    'linked_type',
    'type',
    'currency',
    'ach_velocity',
    'card_velocity',
    'balance_adjustment_velocity',
  ])

  const [
    achDailyVelocityCountLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCurrentVolume,
    achDailyVelocityVolumeLimit,
    achDailyVelocityWindowReset,
    achMonthlyVelocityCountLimit,
    achMonthlyVelocityCurrentCount,
    achMonthlyVelocityCurrentVolume,
    achMonthlyVelocityVolumeLimit,
    achMonthlyVelocityWindowReset,
  ] = getMany(achVelocity, [
    'daily_velocity.count_limit',
    'daily_velocity.current_count',
    'daily_velocity.current_volume',
    'daily_velocity.volume_limit',
    'daily_velocity.window_reset',
    'monthly_velocity.count_limit',
    'monthly_velocity.current_count',
    'monthly_velocity.current_volume',
    'monthly_velocity.volume_limit',
    'monthly_velocity.window_reset',
  ])

  const [
    cardDailyVelocityCountLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCurrentVolume,
    cardDailyVelocityVolumeLimit,
    cardDailyVelocityWindowReset,
    cardMonthlyVelocityCountLimit,
    cardMonthlyVelocityCurrentCount,
    cardMonthlyVelocityCurrentVolume,
    cardMonthlyVelocityVolumeLimit,
    cardMonthlyVelocityWindowReset,
  ] = getMany(cardVelocity, [
    'daily_velocity.count_limit',
    'daily_velocity.current_count',
    'daily_velocity.current_volume',
    'daily_velocity.volume_limit',
    'daily_velocity.window_reset',
    'monthly_velocity.count_limit',
    'monthly_velocity.current_count',
    'monthly_velocity.current_volume',
    'monthly_velocity.volume_limit',
    'monthly_velocity.window_reset',
  ])

  const [
    balanceAdjustmentDailyVelocityCurrentVolume,
    balanceAdjustmentDailyVelocityCurrentCount,
    balanceAdjustmentDailyVelocityVolumeLimit,
    balanceAdjustmentDailyVelocityCountLimit,
    balanceAdjustmentDailyVelocityWindowReset,
    balanceAdjustmentMonthlyVelocityCurrentVolume,
    balanceAdjustmentMonthlyVelocityCurrentCount,
    balanceAdjustmentMonthlyVelocityVolumeLimit,
    balanceAdjustmentMonthlyVelocityCountLimit,
    balanceAdjustmentMonthlyVelocityWindowReset,
  ] = getMany(balanceAdjustmentVelocity, [
    'daily_velocity.current_volume',
    'daily_velocity.current_count',
    'daily_velocity.volume_limit',
    'daily_velocity.count_limit',
    'daily_velocity.window_reset',
    'monthly_velocity.current_volume',
    'monthly_velocity.current_count',
    'monthly_velocity.volume_limit',
    'monthly_velocity.count_limit',
    'monthly_velocity.window_reset',
  ])

  const dailyCardPayoutLimitReached = numeral(cardDailyVelocityCurrentCount).value() >= numeral(cardDailyVelocityCountLimit).value() || displayAmountToAmount(cardDailyVelocityCurrentVolume) >= displayAmountToAmount(cardDailyVelocityVolumeLimit)
  const monthlyCardPayoutLimitReached = numeral(cardMonthlyVelocityCurrentCount).value() >= numeral(cardMonthlyVelocityCountLimit).value() || displayAmountToAmount(cardMonthlyVelocityCurrentVolume) >= displayAmountToAmount(cardMonthlyVelocityVolumeLimit)
  const remainingDailyCardVolume = subtract(displayAmountToAmount(cardDailyVelocityVolumeLimit), displayAmountToAmount(cardDailyVelocityCurrentVolume))
  const remainingMonthlyCardVolume = subtract(displayAmountToAmount(cardMonthlyVelocityVolumeLimit), displayAmountToAmount(cardMonthlyVelocityCurrentVolume))

  const dailyACHPayoutLimitReached = numeral(achDailyVelocityCurrentCount).value() >= numeral(achDailyVelocityCountLimit).value() || displayAmountToAmount(achDailyVelocityCurrentVolume) >= displayAmountToAmount(achDailyVelocityVolumeLimit)
  const monthlyACHPayoutLimitReached = numeral(achMonthlyVelocityCurrentCount).value() >= numeral(achMonthlyVelocityCountLimit).value() || displayAmountToAmount(achMonthlyVelocityCurrentVolume) >= displayAmountToAmount(achMonthlyVelocityVolumeLimit)
  const remainingDailyACHVolume = subtract(displayAmountToAmount(achDailyVelocityVolumeLimit), displayAmountToAmount(achDailyVelocityCurrentVolume))
  const remainingMonthlyACHVolume = subtract(displayAmountToAmount(achMonthlyVelocityVolumeLimit), displayAmountToAmount(achMonthlyVelocityCurrentVolume))

  const canSendACHPayout = !dailyACHPayoutLimitReached && !monthlyACHPayoutLimitReached
  const canSendCardPayout = !dailyCardPayoutLimitReached && !monthlyCardPayoutLimitReached
  const canSendMoney = canSendACHPayout || canSendCardPayout

  const displayAchDailyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: achDailyVelocityWindowReset, format: TIME_FORMAT })
  const displayAchMonthlyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: achMonthlyVelocityWindowReset, format: TIME_FORMAT })
  const displayCardDailyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: cardDailyVelocityWindowReset, format: TIME_FORMAT })
  const displayCardMonthlyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: cardMonthlyVelocityWindowReset, format: TIME_FORMAT })

  const cannotSendMoney = !canSendACHPayout && !canSendCardPayout
  const cannotSendMoneyInfo = !canSendACHPayout ? {
    limitReached: dailyACHPayoutLimitReached ? 'daily' : 'monthly',
    type: 'ACH',
    windowReset: dailyACHPayoutLimitReached ? displayAchDailyVelocityWindowReset : displayAchMonthlyVelocityWindowReset,
  } : {
    limitReached: dailyCardPayoutLimitReached ? 'daily' : 'monthly',
    type: 'card',
    windowReset: dailyCardPayoutLimitReached ? displayCardDailyVelocityWindowReset : displayCardMonthlyVelocityWindowReset,
  }

  const displayBalanceAdjustmentDailyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: balanceAdjustmentDailyVelocityWindowReset, format: TIME_FORMAT })
  const displayBalanceAdjustmentMonthlyVelocityWindowReset = formatDateWithTimeZoneAbrreviation({ date: balanceAdjustmentMonthlyVelocityWindowReset, format: TIME_FORMAT })

  return removeUndefined({
    linkedTo,
    linkedType,
    type,
    currency,
    achDailyVelocityCountLimit,
    achDailyVelocityCurrentCount,
    achDailyVelocityCurrentVolume,
    displayAchDailyVelocityCurrentVolume: formatMoney({ amount: achDailyVelocityCurrentVolume, currency }),
    achDailyVelocityVolumeLimit,
    displayAchDailyVelocityVolumeLimit: formatMoney({ amount: achDailyVelocityVolumeLimit, currency }),
    achMonthlyVelocityCountLimit,
    achMonthlyVelocityCurrentCount,
    displayAchMonthlyVelocityCurrentVolume: formatMoney({ amount: achMonthlyVelocityCurrentVolume, currency }),
    achMonthlyVelocityVolumeLimit,
    displayAchMonthlyVelocityVolumeLimit: formatMoney({ amount: achMonthlyVelocityVolumeLimit, currency }),
    achDailyVelocityWindowReset,
    achMonthlyVelocityWindowReset,
    displayAchDailyVelocityWindowReset,
    displayAchMonthlyVelocityWindowReset,
    cardDailyVelocityCountLimit,
    cardDailyVelocityCurrentCount,
    cardDailyVelocityCurrentVolume,
    displayCardDailyVelocityCurrentVolume: formatMoney({ amount: cardDailyVelocityCurrentVolume, currency }),
    cardDailyVelocityVolumeLimit,
    displayCardDailyVelocityVolumeLimit: formatMoney({ amount: cardDailyVelocityVolumeLimit, currency }),
    cardMonthlyVelocityCountLimit,
    cardMonthlyVelocityCurrentCount,
    cardMonthlyVelocityCurrentVolume,
    displayCardMonthlyVelocityCurrentVolume: formatMoney({ amount: cardMonthlyVelocityCurrentVolume, currency }),
    cardMonthlyVelocityVolumeLimit,
    displayCardMonthlyVelocityVolumeLimit: formatMoney({ amount: cardMonthlyVelocityVolumeLimit, currency }),
    cardDailyVelocityWindowReset,
    cardMonthlyVelocityWindowReset,
    displayCardDailyVelocityWindowReset,
    displayCardMonthlyVelocityWindowReset,
    canSendACHPayout,
    canSendCardPayout,
    canSendMoney,
    remainingDailyCardVolume,
    remainingMonthlyCardVolume,
    remainingDailyACHVolume,
    remainingMonthlyACHVolume,
    cannotSendMoney,
    cannotSendMoneyInfo,
    balanceAdjustmentDailyVelocityCurrentVolume,
    displayBalanceAdjustmentDailyVelocityCurrentVolume: formatMoney({ amount: balanceAdjustmentDailyVelocityCurrentVolume, currency }),
    balanceAdjustmentDailyVelocityVolumeLimit,
    displayBalanceAdjustmentDailyVelocityVolumeLimit: formatMoney({ amount: balanceAdjustmentDailyVelocityVolumeLimit, currency }),
    balanceAdjustmentDailyVelocityCurrentCount,
    balanceAdjustmentDailyVelocityCountLimit,
    displayBalanceAdjustmentMonthlyVelocityCurrentVolume: formatMoney({ amount: balanceAdjustmentMonthlyVelocityCurrentVolume, currency }),
    displayBalanceAdjustmentMonthlyVelocityVolumeLimit: formatMoney({ amount: balanceAdjustmentMonthlyVelocityVolumeLimit, currency }),
    balanceAdjustmentMonthlyVelocityCurrentCount,
    balanceAdjustmentMonthlyVelocityCountLimit,
    displayBalanceAdjustmentDailyVelocityWindowReset,
    displayBalanceAdjustmentMonthlyVelocityWindowReset,
  })
}

export default frontendCurrentUsageModel
