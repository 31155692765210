import './ComplianceApplyTemplateFormS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { getApplicationOwnerIdentitySelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import columnDescriptors from './columnDescriptors'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { GET_APPLICATIONS } from 'constants/flowConstants'
import { APPLICATIONS } from 'constants/linkConstants'
import { APPLICATIONS_ICON } from 'constants/iconConstants'
import { APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE } from 'constants/selectedItemsConstants'
import { COMPLIANCE_APPLY_TEMPLATE_OPTIONS } from 'constants/complianceConstants'
import { APPLICATIONS_EMPTY_TABLE_MESSAGE } from 'constants/language/languageConstants'

const selectedItemsKey = APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE

const allowedFilters = [
  convertFilterToResourceIdFilter('Application'),
]

const ComplianceApplyTemplateForm = ({
  isFetching = false,
  buttonGroup = {},
  handleSubmit = () => {},
  showApplicationTable = false,
}) => {
  return (
    <form className='ComplianceApplyTemplateForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Publish Options</h1>
      </div>

      <div className='section'>
        <Field
          label='What do you want the compliance template applied to?'
          name='typeApplied'
          component={RadioButton}
          options={COMPLIANCE_APPLY_TEMPLATE_OPTIONS}
        />
      </div>

      { showApplicationTable && (
        <TableC
          flow={GET_APPLICATIONS}
          columnDescriptors={columnDescriptors}
          selector={getApplicationOwnerIdentitySelector}
          linksKey={APPLICATIONS}
          allowedFilters={allowedFilters}
          isFetching={isFetching}
          emptyMessage={APPLICATIONS_EMPTY_TABLE_MESSAGE}
          icon={`fa fa-${APPLICATIONS_ICON}`}
          selectedItemsKey={selectedItemsKey}
          selectSingleItem
        />
      ) }

      {buttonGroup}
    </form>
  )
}

ComplianceApplyTemplateForm.propTypes = {
  isFetching: PropTypes.bool,
  buttonGroup: PropTypes.object,
  handleSubmit: PropTypes.func,
  showApplicationTable: PropTypes.bool,
}

export default ComplianceApplyTemplateForm
