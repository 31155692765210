import { GET_MERCHANT_SETTLEMENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantSettlementsRequest = ({
  merchantId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_MERCHANT_SETTLEMENTS_F_REQUEST,
  values: { merchantId },
  credentials,
  queries,
  meta,
})

export default getMerchantSettlementsRequest
