import { fieldEmpty, validateFile } from 'utilities/validate'

const validateFileUploadForm = ({
  nameOfFile,
  documentType,
  file,
  merchantId,
}, { config, uploadForIdentity } = {}) => {
  const nameEmpty = fieldEmpty(nameOfFile)
  const documentTypeEmpty = fieldEmpty(documentType)
  const validatedFile = validateFile(file, config)
  const validatedMerchantId = uploadForIdentity ? fieldEmpty(merchantId) : false

  return {
    nameOfFile: nameEmpty,
    documentType: documentTypeEmpty,
    file: validatedFile,
    merchantId: validatedMerchantId,
  }
}

export default validateFileUploadForm
