import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import ExportDataFormV2 from './ExportDataV2Form'
import formatNumber from 'utilities/formatters/formatNumber'
import getPageName from 'utilities/get/getPageName'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { EXPORT_DATA_V2_FORM } from 'constants/formConstants'
import { ONBOARDING_SETTLEMENTS_LIST_PAGE } from 'constants/pageConstants'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import get from 'lodash/get'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import some from 'lodash/some'
import map from 'lodash/map'

import {
  GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST,
  GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
} from 'constants/flowConstants'

import {
  PATHNAME,
  SEARCH,
} from 'constants/queryConstants'

import {
  RESOURCE_TO_EXPORT_FIELDS_MAP,
} from 'constants/exportConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const pathName = get(state, PATHNAME)
  const search = get(state, SEARCH)
  const queries = parseUrlQueries(search)
  const pageName = getPageName(pathName, queries)
  const isFetching = get(state, `getAllReviewQueueItemsR.${FETCHING}`)
  const currentUserEmail = get(getCurrentUser(state), 'email')
  const formValues = getFormValues(EXPORT_DATA_V2_FORM)(state)
  const isButtonEnabled = some(formValues, (value) => !!value)

  const [
    currentFilters,
    data,
    hiddenExportFields = [],
    entityType,
  ] = getMany(props, [
    'currentFilters',
    'data',
    'hiddenExportFields',
    'entityType',
  ])

  const filteredKeys = RESOURCE_TO_EXPORT_FIELDS_MAP[entityType]

  const normalizedFilteredKeys = filter(filteredKeys, (key) => !includes(hiddenExportFields, key))

  const initialValues = reduce(normalizedFilteredKeys, (total, key) => merge({}, total, {
    [key]: true,
  }), {})

  const [
    progressCount,
    progressTotal,
    isFetchingAllReviewQueueItems,
    totalSettlementsCount,
  ] = getMany(state, [
    'getAllReviewQueueItemsR.progressCount',
    'getAllReviewQueueItemsR.progressTotal',
    `getAllReviewQueueItemsR.${FETCHING}`,
    'linksR.REVIEW_QUEUE.page.count',
  ])

  const createdAtLte = get(queries, 'created_at.lte')
  const createdAtGte = get(queries, 'created_at.gte')
  const createdAtLteDate = moment(createdAtLte)
  const createdAtGteDate = moment(createdAtGte)
  const createdAtDiff = moment.duration(createdAtLteDate.diff(createdAtGteDate)).asDays()
  const showExportAll = pageName === ONBOARDING_SETTLEMENTS_LIST_PAGE // TODO: convert logic to page to function map
  const enableExportAll = createdAtGte && createdAtDiff <= 7

  const progress = progressCount ? formatNumber(progressCount / progressTotal * 100) : 0
  const progressPercent = `${progress}%`

  return {
    credentials,
    data,
    dataKeys: normalizedFilteredKeys,
    initialValues,
    pageName,
    isFetching,
    isFetchingAllReviewQueueItems,
    progressPercent,
    totalSettlementsCount,
    showExportAll,
    enableExportAll,
    currentFilters,
    currentUserEmail,
    queries,
    formValues,
    isButtonEnabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancelExportAll: () => dispatch({ type: GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST }),
    updateForm: (fieldName, formValues) => dispatch(change(EXPORT_DATA_V2_FORM, fieldName, formValues)),
    exportAllReviewQueueItems: ({ credentials, fileNameCSV }) => dispatch({
      type: GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
      payload: {
        credentials,
        fileNameCSV,
        // TODO: write a custom success handler instead
        newValues: { // resets progress bar after clicking out of Advanced Export modal
          progressCount: 0,
          progressTotal: 0,
          [FETCHING]: true,
        },
      },
    }),
  }
}

class ExportDataV2FormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newFetchAll: false,
      allFieldsSelected: true,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFetchingAllReviewQueueItems: prevIsFetchingAllReviewQueueItems } = prevProps
    const { isFetchingAllReviewQueueItems, updateForm, formValues } = this.props
    const { allFieldsSelected: prevAllFieldsSelected } = prevState
    const { allFieldsSelected } = this.state

    if (!prevIsFetchingAllReviewQueueItems && isFetchingAllReviewQueueItems) {
      this.setState({ newFetchAll: true })
    }

    if (prevAllFieldsSelected !== allFieldsSelected) {
      map(formValues, (value, key) => {
        updateForm(key, allFieldsSelected ? true : false)
      })
    }
  }

  componentWillUnmount() {
    const { cancelExportAll } = this.props

    cancelExportAll()
  }

  submitExportAllReviewQueueItems = () => {
    const {
      credentials,
      fileNameCSV,
      exportAllReviewQueueItems,
    } = this.props

    exportAllReviewQueueItems({ credentials, fileNameCSV })
  }

  setAllFieldsSelected = (allFieldsSelected) => {
    this.setState({ allFieldsSelected })
  }

  render() {
    const { newFetchAll, allFieldsSelected } = this.state

    return (
      <ExportDataFormV2
        newFetchAll={newFetchAll}
        submitExportAllReviewQueueItems={this.submitExportAllReviewQueueItems}
        allFieldsSelected={allFieldsSelected}
        setAllFieldsSelected={this.setAllFieldsSelected}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDataV2FormC)
