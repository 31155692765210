import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'

import {
  AMOUNT,
  CREATED_ON,
  DEPOSIT_TYPE,
  ID,
  LEVEL,
  STATE,
  TRACE_ID,
  PAYMENT_INSTRUMENT,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(TRACE_ID, 'traceId', {
    className: 'x-small',
    formatter: ({ traceId }) => <ClickToCopyC label='Trace ID' position='right' content={traceId} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(DEPOSIT_TYPE, 'displayType', { className: 'small' }),
  column(LEVEL, 'displaySubtype', { className: 'medium' }),
  column(STATE, 'state', {
    className: 'state',
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrumentId', { className: 'medium' }),
]
export default columnDescriptors
