import React, { Component } from 'react'
import { connect } from 'react-redux'
import BuyerInfo from './BuyerInfo'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import showModalAction from 'utilities/actions/showModalAction'
import { ADD_EDIT_BUYER_INFORMATION_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  NAME,
  EMAIL,
  PHONE,
  BILLING_ADDRESS,
  EDIT,
  BUSINESS_NAME,
  BUSINESS_ADDRESS,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const identity = get(props, 'identity')

  const [
    identityId,
    fullName,
    email,
    displayPhone,
    address,
    displayBusinessName,
    businessName,
    businessAddress,
    doingBusinessAs,
    displayBusinessPhone,
  ] = getMany(identity, [
    'id',
    'fullName',
    'email',
    'displayPhone',
    'personalAddress',
    'displayBusinessName',
    'businessName',
    'businessAddress',
    'doingBusinessAs',
    'displayBusinessPhone',
  ])

  const detailsData = [
    {
      label: NAME,
      value: fullName,
    },
    {
      label: BUSINESS_NAME,
      value: displayBusinessName,
      condition: !!businessName,
    },
    {
      label: DOING_BUSINESS_AS,
      value: doingBusinessAs,
      condition: !!doingBusinessAs,
    },
    {
      label: EMAIL,
      value: email,
    },
    {
      label: PHONE,
      value: displayPhone,
    },
    {
      label: BILLING_ADDRESS,
      value: <Address address={address} />,
    },
    {
      label: BUSINESS_ADDRESS,
      value: <Address address={businessAddress} />,
      condition: !!businessAddress,
    },
    {
      label: BUSINESS_PHONE,
      value: displayBusinessPhone,
      condition: !!displayBusinessPhone,
    },
  ]

  const detailsSectionData = convertPageSectionDataToV2(detailsData)

  return {
    isFetching,
    identity,
    credentials,
    credentialId,
    detailsSectionData,
    identityId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAddBuyerModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_EDIT_BUYER_INFORMATION_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class BuyerInfoC extends Component {
  render() {
    const {
      identityId,
      showAddBuyerModal,
    } = this.props

    const actions = [
      {
        label: EDIT,
        action: () => showAddBuyerModal({ identityId }),
      },
    ]

    return (
      <BuyerInfo
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerInfoC)
