import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  REVIEW_QUEUE_ITEM,
  REVIEW_QUEUE,
} from 'constants/apiConstants'

const patchReviewQueueItemAPI = ({ values, credentials, meta }) => {
  // TODO: refactor this flow to send ID through id
  const { id } = values
  const path = REVIEW_QUEUE_ITEM({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: REVIEW_QUEUE,
  })
}

export default patchReviewQueueItemAPI
