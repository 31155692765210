import getMany from 'utilities/get/getMany'
import numeral from 'numeral'

const warnIdentityProcessingForm = (values, props) => {
  const {
    maxTransactionAmount,
    achMaxTransactionAmount,
  } = values

  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    isRolePlatformWithEditIdentityPermissions,
  ] = getMany(props, [
    'underwritingProfileMaxTransactionAmount',
    'underwritingProfileACHMaxTransactionAmount',
    'underwritingProfileDisplayMaxTransactionAmount',
    'underwritingProfileDisplayACHMaxTransactionAmount',
    'isRolePlatformWithEditIdentityPermissions',
  ])

  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(underwritingProfileMaxTransactionAmount).value()
  const invalidACHMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(underwritingProfileACHMaxTransactionAmount).value()
  const maxTransactionAmountWarning = (isRolePlatformWithEditIdentityPermissions && underwritingProfileMaxTransactionAmount) ? `Value exceeds the Application's max amount of ${underwritingProfileDisplayMaxTransactionAmount}` : ''
  const achMaxTransactionAmountWarning = (isRolePlatformWithEditIdentityPermissions && underwritingProfileACHMaxTransactionAmount) ? `Value exceeds the Application's ACH max amount of ${underwritingProfileDisplayACHMaxTransactionAmount}` : ''

  return {
    maxTransactionAmount: invalidMaxTransactionAmount && maxTransactionAmountWarning,
    achMaxTransactionAmount: invalidACHMaxTransactionAmount && achMaxTransactionAmountWarning,
  }
}

export default warnIdentityProcessingForm
