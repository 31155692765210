import React, { Component } from 'react'
import { connect } from 'react-redux'
import NewUserForm from 'components/Customer/Forms/NewUserForm/NewUserForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import selectOption from 'utilities/forms/selectOption'
import showModalAction from 'utilities/actions/showModalAction'
import { rolePlatformAccessOptions } from 'constants/userPermissions/rolePlatformPermissions'
import { ORCHESTRATION_STATUS } from 'constants/modalConstants'

import {
  POST_USER_O_DATA,
  POST_USER_O_ORDER,
} from 'constants/orchestrationConstants'

const emailTemplateOptions = [
  selectOption('Select Template', ''),
  selectOption('Welcome Email', 'welcome-email-password'),
]

const orchestrationModalAction = showModalAction({
  modalType: ORCHESTRATION_STATUS,
  modalProps: {
    title: 'Create User - Status',
    orchestrationData: POST_USER_O_DATA,
    orchestrationOrder: POST_USER_O_ORDER,
  },
})

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
    emailTemplateOptions,
    rolePlatformAccessOptions,
    orchestrationModalAction,
  }
}

class NewUserFormC extends Component {
  render() {
    return (
      <NewUserForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(NewUserFormC)
