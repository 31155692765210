import numeral from 'numeral'
import { parseAmount } from 'constants/normalizationConstants'
import size from 'lodash/size'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  fieldEmpty,
  nonEmptyNumber,
  nonEmptyPositiveAmount,
} from 'utilities/validate'

import {
  L2_ENABLED,
  L3_ENABLED,
} from 'constants/processorConstants'

import {
  PAYMENT_METHOD,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  SHIPPING_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT,
  TOTAL_CUSTOMS_DUTY_AMOUNT,
  MERCHANT_PRODUCT_CODE,
  PRODUCT_DESCRIPTION,
  COST_PER_UNIT,
  DISCOUNT_PER_UNIT,
  QUANTITY,
  UNIT_OF_MEASURE,
  TOTAL_ITEM_COST,
  EXCLUDING_SALES_TAX,
  INCLUDING_SALES_TAX,
  COMMODITY_CODE,
} from 'constants/language/languageConstants'

const validateCreateTransactionForm = (values, props) => {
  const {
    amount,
    paymentMethod,
    additionalDataSelection,
    salesTaxAmount,
    customerReferenceNumber,
    shippingAmount,
    totalDiscountAmount,
    totalCustomsDutyAmount,
    levelThreeItems,
    device,
  } = values

  const {
    sendMethod,
    identityMaxTransactionAmount,
  } = props

  const additionalDataSelectionValue = get(additionalDataSelection, 'value')
  const isLevelTwoEnabled = isEqual(additionalDataSelectionValue, L2_ENABLED)
  const isLevelThreeEnabled = isEqual(additionalDataSelectionValue, L3_ENABLED)

  const levelTwoFieldsValidation = isLevelTwoEnabled ? {
    salesTaxAmount: fieldEmpty(salesTaxAmount, SALES_TAX_AMOUNT),
    customerReferenceNumber: fieldEmpty(customerReferenceNumber, CUSTOMER_REFERENCE_NUMBER) || (size(customerReferenceNumber) > 17 && `${CUSTOMER_REFERENCE_NUMBER} cannot exceed the max of 17 characters.`),
  } : {}

  const itemsValidation = map(levelThreeItems, (item) => {
    const {
      merchantProductCode,
      productDescription,
      costPerUnit,
      discountPerUnit,
      quantity,
      unitOfMeasure,
      totalItemCostExcludingSalesTax,
      totalItemCostIncludingSalesTax,
      commodityCode,
    } = item

    return {
      merchantProductCode: fieldEmpty(merchantProductCode, MERCHANT_PRODUCT_CODE) || (size(merchantProductCode) > 12 && `${MERCHANT_PRODUCT_CODE} cannot exceed the max of 12 characters.`),
      productDescription: fieldEmpty(productDescription, PRODUCT_DESCRIPTION) || (size(productDescription) > 25 && `${PRODUCT_DESCRIPTION} cannot exceed the max of 25 characters.`),
      costPerUnit: fieldEmpty(costPerUnit, COST_PER_UNIT),
      discountPerUnit: fieldEmpty(discountPerUnit, DISCOUNT_PER_UNIT),
      quantity: fieldEmpty(quantity, QUANTITY) || (size(quantity) <= 0 && `${QUANTITY} must be greater than 0.`),
      unitOfMeasure: fieldEmpty(unitOfMeasure, UNIT_OF_MEASURE),
      totalItemCostExcludingSalesTax: nonEmptyPositiveAmount({ field: totalItemCostExcludingSalesTax, name: `${TOTAL_ITEM_COST} (${EXCLUDING_SALES_TAX})` }),
      totalItemCostIncludingSalesTax: nonEmptyPositiveAmount({ field: totalItemCostIncludingSalesTax, name: `${TOTAL_ITEM_COST} (${INCLUDING_SALES_TAX})` }),
      commodityCode: fieldEmpty(commodityCode, COMMODITY_CODE) || (size(commodityCode) > 12 && `${COMMODITY_CODE} cannot exceed the max of 12 characters.`),
    }
  })

  const levelThreeFieldsValidation = isLevelThreeEnabled ? {
    customerReferenceNumber: fieldEmpty(customerReferenceNumber, CUSTOMER_REFERENCE_NUMBER) || (size(customerReferenceNumber) > 17 && `${CUSTOMER_REFERENCE_NUMBER} cannot exceed the max of 17 characters.`),
    shippingAmount: fieldEmpty(shippingAmount, SHIPPING_AMOUNT),
    totalDiscountAmount: fieldEmpty(totalDiscountAmount, TOTAL_DISCOUNT_AMOUNT),
    totalCustomsDutyAmount: fieldEmpty(totalCustomsDutyAmount, TOTAL_CUSTOMS_DUTY_AMOUNT),
    levelThreeItems: itemsValidation,
  } : {}

  const deviceEmpty = fieldEmpty(get(device, 'value'), 'Device')

  return {
    amount: nonEmptyNumber({ field: amount, name: 'Total amount' })
      || (identityMaxTransactionAmount && (parseAmount(amount) > parseAmount(identityMaxTransactionAmount)) && `Maximum transaction amount is $${identityMaxTransactionAmount}`)
      || (numeral(amount).value() <= 0 && 'Total amount must be greater than $0.00'),
    paymentMethod: fieldEmpty(paymentMethod, PAYMENT_METHOD),
    ...levelTwoFieldsValidation,
    ...levelThreeFieldsValidation,
    device: sendMethod === 'device' ? deviceEmpty : false,
  }
}

export default validateCreateTransactionForm
