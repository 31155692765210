import { GET_FEE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getFeeRequest = ({
  feeId,
  credentials,
}) => createAction({
  type: GET_FEE_F_REQUEST,
  id: feeId,
  credentials,
})

export default getFeeRequest
