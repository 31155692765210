import './ComplianceFormTemplateFormsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { getComplianceFormTemplateFormsApplicationSelector } from 'state-layer/selectors'
import { COMPLIANCE_FORM_TEMPLATE_FORMS } from 'constants/linkConstants'
import { GET_COMPLIANCE_FORM_TEMPLATE_FORMS } from 'constants/flowConstants'
import { COMPLIANCE_FORMS_ICON } from 'constants/iconConstants'
import { COMPLIANCE_FORMS_EMPTY_MESSAGE } from 'constants/language/languageConstants'

const ComplianceFormTemplateForms = ({
  complianceFormsPath = '',
  isFetching = false,
  complianceFormTemplateId = '',
  showLimitCount = false,
  flowValues,
  allowedFilters,
  initialQueries,
  quickFilters,
  filterSets = [],
  columnDescriptors = [],
}) => {
  return (
    <TableC
      classNames='ComplianceFormTemplateForms'
      flow={GET_COMPLIANCE_FORM_TEMPLATE_FORMS}
      columnDescriptors={columnDescriptors}
      flowValues={flowValues}
      selector={getComplianceFormTemplateFormsApplicationSelector}
      selectorId={complianceFormTemplateId}
      linksKey={COMPLIANCE_FORM_TEMPLATE_FORMS}
      allowedFilters={allowedFilters}
      path={complianceFormsPath}
      isFetching={isFetching}
      emptyMessage={COMPLIANCE_FORMS_EMPTY_MESSAGE}
      icon={`fa fa-${COMPLIANCE_FORMS_ICON}`}
      fileNameCSV='Compliance_Form_Templates_Forms'
      showLimitCount={showLimitCount}
      showTableFooter={false}
      initialQueries={initialQueries}
      quickFilters={quickFilters}
      filterSets={filterSets}
    />
  )
}

ComplianceFormTemplateForms.propTypes = {
  complianceFormsPath: PropTypes.string,
  isFetching: PropTypes.bool,
  complianceFormTemplateId: PropTypes.string,
  showLimitCount: PropTypes.bool,
  flowValues: PropTypes.object,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  quickFilters: PropTypes.array,
  filterSets: PropTypes.array,
  columnDescriptors: PropTypes.array,
}

export default ComplianceFormTemplateForms
