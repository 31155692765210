export const QA = 'QA'
export const SANDBOX = 'SANDBOX'
export const PROD = 'PROD'

export const QA_URL = 'https://finix.qa-payments-api.com'
export const SANDBOX_URL = 'https://finix.sandbox-payments-api.com'
export const LIVE_URL = 'https://finix.live-payments-api.com'

import {
  QA_ENVIRONMENT,
  SANDBOX_ENVIRONMENT,
  LIVE_ENVIRONMENT,
} from 'constants/environmentConstants'

export const CREDENTIAL_ENVIRONMENTS = {
  [QA]: QA_ENVIRONMENT,
  [SANDBOX]: SANDBOX_ENVIRONMENT,
  [PROD]: LIVE_ENVIRONMENT,
}

export const credentialNameToEnvironmentUrl = {
  QA: QA_URL,
  Sandbox: SANDBOX_URL,
  Live: LIVE_URL,
}
