import { POST_DASHBOARD_USER_MFA_VERIFY_F_REQUEST } from 'constants/flowConstants'
import getCurrentUserRequest from 'utilities/actions/get/getCurrentUserRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import get from 'lodash/get'

const submitMFAMobileAuthenticatorForm = (values, dispatch, props) => {
  const {
    token,
    mfaId,
    oobCode,
  } = props

  const { code } = values

  const valuesToSubmit = {
    mfa_id: mfaId,
    oob_code: oobCode,
    mfa_verification_code: code,
    mfa_type: 'TOTP',
  }

  dispatch({
    type: POST_DASHBOARD_USER_MFA_VERIFY_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      customHeaders: {
        'X-Mfa-Token': token,
      },
      successCallback: () => {
        dispatch(getCurrentUserRequest({
          credentials: getCurrentCredentials(),
          currentUserEmail: get(getCurrentUser(), 'email'),
        }))
      },
    },
  })
}

export default submitMFAMobileAuthenticatorForm
