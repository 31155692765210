import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { PURCHASES } from 'constants/apiConstants'

const postGuestPurchaseAPI = ({ values }) => {
  return guestCheckoutAPI.post({
    path: PURCHASES,
    values,
  })
}

export default postGuestPurchaseAPI
