import './EditFeeProfileSettingsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import { EDIT_FEE_PROFILE_SETTINGS_FORM } from 'constants/formConstants'

const EditFeeProfileSettingsForm = ({
  toggleSwitchAction = () => {},
  disableEnforceLimitsToggleUsd = false,
  disableEnforceLimitsToggleCad = false,
}) => {
  return (
    <form className='EditFeeProfileSettingsForm'>
      <div className='flex column gap-12'>
        <div className='flex title'>
          <h6>Fee Profile Settings - United States</h6>
          <CountryFlagIcon country='USA' showCountryName={false} />
        </div>
        <ToggleSwitchC
          name='USD.canCreateFeeProfiles'
          label='Allow application to create merchant fee profiles'
          customHandleChange={() => toggleSwitchAction('USD.canCreateFeeProfiles')}
          className='fee-profile-toggle'
          form={EDIT_FEE_PROFILE_SETTINGS_FORM}
        />
        <ToggleSwitchC
          name='USD.canAssignFeeProfiles'
          label='Allow application to assign merchant fee profiles'
          customHandleChange={() => toggleSwitchAction('USD.canAssignFeeProfiles')}
          className='fee-profile-toggle'
          form={EDIT_FEE_PROFILE_SETTINGS_FORM}
        />
        <ToggleSwitchC
          name='USD.enforceLimitsForFeeProfiles'
          label='Enforce fee profile limits'
          customHandleChange={() => toggleSwitchAction('USD.enforceLimitsForFeeProfiles')}
          className='fee-profile-toggle'
          form={EDIT_FEE_PROFILE_SETTINGS_FORM}
          disabled={disableEnforceLimitsToggleUsd}
          disabledTooltip='Please edit fee profile limits before enforcing the limits.'
        />
      </div>
      {/* TODO: add back as part of FE-4225 */}
      {/* <div className='flex column gap-12'> */}
      {/*  <div className='flex title canada'> */}
      {/*    <h6>Fee Profile Settings - Canada</h6> */}
      {/*    <CountryFlagIcon country='CAN' showCountryName={false} /> */}
      {/*  </div> */}
      {/*  <ToggleSwitchC */}
      {/*    name='CAD.canCreateFeeProfiles' */}
      {/*    label='Allow application to create merchant fee profiles' */}
      {/*    customHandleChange={() => toggleSwitchAction('CAD.canCreateFeeProfiles')} */}
      {/*    className='fee-profile-toggle' */}
      {/*    form={EDIT_FEE_PROFILE_SETTINGS_FORM} */}
      {/*  /> */}
      {/*  <ToggleSwitchC */}
      {/*    name='CAD.canAssignFeeProfiles' */}
      {/*    label='Allow application to assign merchant fee profiles' */}
      {/*    customHandleChange={() => toggleSwitchAction('CAD.canAssignFeeProfiles')} */}
      {/*    className='fee-profile-toggle' */}
      {/*    form={EDIT_FEE_PROFILE_SETTINGS_FORM} */}
      {/*  /> */}
      {/*  <ToggleSwitchC */}
      {/*    name='CAD.enforceLimitsForFeeProfiles' */}
      {/*    label='Enforce fee profile limits' */}
      {/*    customHandleChange={() => toggleSwitchAction('CAD.enforceLimitsForFeeProfiles')} */}
      {/*    className='fee-profile-toggle' */}
      {/*    form={EDIT_FEE_PROFILE_SETTINGS_FORM} */}
      {/*    disabled={disableEnforceLimitsToggleCad} */}
      {/*    disabledTooltip='Please edit fee profile limits before enforcing the limits.' */}
      {/*  /> */}
      {/* </div> */}
    </form>
  )
}

EditFeeProfileSettingsForm.propTypes = {
  toggleSwitchAction: PropTypes.func,
  disableEnforceLimitsToggleUsd: PropTypes.bool,
  disableEnforceLimitsToggleCad: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_SETTINGS_FORM,
})(EditFeeProfileSettingsForm)
