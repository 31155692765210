import './IdentityUploaderS.scss'
import IdentityUploaderFormC from 'components/Admin/Forms/IdentityUploaderForm/IdentityUploaderFormC'
import React from 'react'

const IdentityUploader = () => {
  return (
    <div className='IdentityUploader'>
      <div className='content-header flex space-between'>
        <h2>Identity Uploader</h2>
      </div>

      <div className='content-card'>
        <IdentityUploaderFormC />
      </div>
    </div>
  )
}

export default IdentityUploader
