import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { TAGS_FORM } from 'constants/formConstants'
import TagsForm from 'components/Customer/Forms/TagsForm/TagsForm'
import validateTagsForm from 'utilities/validate/validateTagsForm'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

class TagsFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      record,
    } = this.props

    const tags = get(record, 'tags')
    const tagsArray = !isEmpty(tags) ? map(tags, (value, name) => ({ name, value })) : [{ name: '', value: '' }]

    initialize({ tags: tagsArray })
  }

  render() {
    return (
      <TagsForm {...this.props} />
    )
  }
}

TagsFormC.propTypes = {
  initialize: PropTypes.func,
  record: PropTypes.object,
}

export default reduxForm({
  form: TAGS_FORM,
  validate: validateTagsForm,
})(TagsFormC)
