import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import BalanceAdjustment from './BalanceAdjustment'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getBalanceAdjustmentRequest from 'utilities/actions/get/getBalanceAdjustmentRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { balanceEntryStatusMap } from 'constants/statusConstants'
import { BALANCE_ENTRY_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  getBalanceAdjustmentSelector,
  getPaymentInstrumentSelector,
  getBalanceEntrySelector,
} from 'state-layer/selectors'

import {
  BALANCE_ADJUSTMENT,
  CREATED_ON,
  METHOD,
  NOTE,
  STATE,
  AMOUNT,
  TYPE,
  POSTED_ON,
  VIEW_BALANCE_ENTRY,
  RECEIVED,
  SENT,
} from 'constants/language/languageConstants'

import {
  ADJUSTMENT_DECREASE,
  PULL_FROM_ACH,
  PULL_FROM_CARD,
} from 'constants/transferConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `balanceAdjustmentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const balanceAdjustmentId = get(props, 'params.balanceAdjustmentId', '')
  const balanceAdjustment = getBalanceAdjustmentSelector(state, balanceAdjustmentId)

  const [
    displayAmount,
    balanceAdjustmentState,
    displayCreatedAt,
    rail,
    description,
    instrumentId,
    balanceEntryId,
  ] = getMany(balanceAdjustment, [
    'displayAmount',
    'state',
    'displayCreatedAt',
    'rail',
    'description',
    'instrumentId',
    'balanceEntryId',
  ])

  const paymentInstrument = getPaymentInstrumentSelector(state, instrumentId)
  const balanceEntry = getBalanceEntrySelector(state, balanceEntryId)
  const balanceEntryLink = <Link className='balance-entry-link text-link' to={BALANCE_ENTRY_PATH({ credentialId, balanceEntryId })}>{VIEW_BALANCE_ENTRY}</Link>

  const [
    balanceEntryAmount,
    balanceEntryState,
    balanceEntryType,
    balanceEntryPostedOn,
  ] = getMany(balanceEntry, [
    'displayAmount',
    'state',
    'displayType',
    'displayPostedAt',
  ])

  const headerData = {
    resource: {
      label: BALANCE_ADJUSTMENT,
      id: balanceAdjustmentId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency='USD' />,
      },
      {
        label: STATE,
        value: <TransferStatus value={balanceAdjustmentState} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
    ],
  }

  const balanceAdjustmentDetailsSectionData = convertPageSectionDataToV2([
    {
      label: STATE,
      value: <TransferStatus value={balanceAdjustmentState} />,
    },
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: METHOD,
      value: rail,
    },
    {
      label: NOTE,
      value: description,
    },
  ], 2)

  const balanceEntryDetailsData = convertPageSectionDataToV2([
    {
      label: STATE,
      value: <TransferStatus value={balanceEntryState} data={balanceEntryStatusMap} />,
    },
    {
      label: AMOUNT,
      value: balanceEntryAmount,
    },
    {
      label: TYPE,
      value: balanceEntryType,
    },
    {
      label: POSTED_ON,
      value: balanceEntryPostedOn,
    },
  ], 2)

  return {
    credentials,
    balanceAdjustmentId,
    headerData,
    balanceAdjustmentDetailsSectionData,
    balanceEntryDetailsData,
    instrumentId,
    paymentInstrument,
    isFetching,
    balanceEntryId,
    balanceEntryLink,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceAdjustment: ({ balanceAdjustmentId, credentials }) => dispatch(getBalanceAdjustmentRequest({ balanceAdjustmentId, credentials })),
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })),
  }
}

class BalanceAdjustmentC extends Component {
  componentDidMount() {
    const {
      balanceAdjustmentId,
      credentials,
      getBalanceAdjustment,
    } = this.props

    getBalanceAdjustment({ balanceAdjustmentId, credentials })
  }

  componentDidUpdate(prevProps) {
    const {
      balanceAdjustmentId,
      credentials,
      getBalanceAdjustment,
      getPaymentInstrument,
      instrumentId,
    } = this.props

    if (balanceAdjustmentId && balanceAdjustmentId !== prevProps.balanceAdjustmentId) {
      getBalanceAdjustment({ balanceAdjustmentId, credentials })
    }

    if (instrumentId && instrumentId !== prevProps.instrumentId) {
      getPaymentInstrument({ paymentInstrumentId: instrumentId, credentials })
    }
  }

  render() {
    return (
      <BalanceAdjustment {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceAdjustmentC)
