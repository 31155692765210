import removeUndefined from 'utilities/remove/removeUndefined'
import removeEmpty from 'utilities/remove/removeEmpty'
import { EMAIL, SMS, PRINT } from 'constants/receiptConstants'
import { USD } from 'constants/currencyConstants'
import map from 'lodash/map'
import size from 'lodash/size'
import includes from 'lodash/includes'

const backendReceiptModel = ({ values }) => {
  const {
    entityId,
    sendReceiptToBuyer,
    emails,
    amountBreakdown,
    items,
    currency = USD,
    sendMethods,
    phoneNumbers,
    devices,
  } = values

  const normalizedItems = map(items, (item) => {
    const {
      name,
      quantity,
      saleAmount,
      description,
      primaryImageUrl,
    } = item

    return {
      name,
      description,
      quantity,
      image_details: {
        primary_image_url: primaryImageUrl,
      },
      price_details: {
        sale_amount: saleAmount,
        currency,
      },
    }
  })

  let normalizedAmountBreakdown
  if (amountBreakdown) {
    const {
      subtotalAmount,
      discountAmount,
      shippingAmount,
      taxAmount,
      tipAmount,
      surchargeAmount,
      convenienceFeeAmount,
      rentSurchargeAmount,
    } = amountBreakdown

    normalizedAmountBreakdown = removeEmpty(removeUndefined({
      subtotal_amount: subtotalAmount,
      discount_amount: discountAmount,
      shipping_amount: shippingAmount,
      estimated_tax_amount: taxAmount,
      tip_amount: tipAmount,
      additional_buyer_charges: removeUndefined({
        surcharge_amount: surchargeAmount,
        convenience_amount: convenienceFeeAmount,
        rent_surcharge_amount: rentSurchargeAmount,
      }),
    }))
  }

  const requestedDeliveryMethods = []
  if (includes(sendMethods, EMAIL) && size(emails) > 0) {
    requestedDeliveryMethods.push({
      type: EMAIL,
      destinations: emails,
    })
  }
  if (includes(sendMethods, SMS) && size(phoneNumbers) > 0) {
    requestedDeliveryMethods.push({
      type: SMS,
      destinations: phoneNumbers,
    })
  }
  if (includes(sendMethods, PRINT) && devices) {
    requestedDeliveryMethods.push({
      type: PRINT,
      destinations: devices,
    })
  }

  return removeEmpty(removeUndefined({
    entity_id: entityId,
    send_receipt_to_buyer: Boolean(sendReceiptToBuyer) || size(emails) > 0 || size(phoneNumbers) > 0,
    requested_delivery_methods: size(requestedDeliveryMethods) > 0 ? requestedDeliveryMethods : undefined,
    amount_breakdown: normalizedAmountBreakdown,
    items: normalizedItems,
  }))
}

export default backendReceiptModel
