import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeleteSubscriptionScheduleAmountForm from 'components/Customer/Forms/DeleteSubscriptionAmountForm/DeleteSubscriptionAmountForm'
import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const subscriptionAmounts = Object.values(get(props, 'subscriptionAmounts'))
  const subscriptionAmountsOptions = [selectOption('Select Subscription Amount', '')].concat(map(subscriptionAmounts, ({ id, amountNickname, amount }) => selectOption(`${amountNickname} - $${amount}`, id)))

  return {
    subscriptionAmounts,
    subscriptionAmountsOptions,
  }
}

class DeleteSubscriptionAmountFormC extends Component {
  render() {
    return (
      <DeleteSubscriptionScheduleAmountForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(DeleteSubscriptionAmountFormC)
