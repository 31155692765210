import './FlashNotificationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { SUCCESS } from 'constants/flashNotificationConstants'
import { t } from 'constants/language/languageConstants'

const FlashNotification = ({
  message = '',
  type = SUCCESS,
  hide = true,
  title = '',
  icon = '',
  closeFlashNotification = () => {},
}) => {
  const showClass = hide ? 'hide' : 'show'
  const isHidden = (!message && !title) ? 'hidden' : ''
  const classNames = `FlashNotification ${type} ${showClass} ${isHidden}`

  return (
    <div className={classNames}>
      <div className='flex'>
        <div className='left-border' />
        <div className='body flex'>
          { icon && <i className={`icon fa fa-${icon}`} /> }

          <div className='content'>
            { title && <div className='title bold'>{t(title)}</div> }

            { message && <div className='message'>{t(message)}</div> }
          </div>

          <i className='close fa fa-close' onClick={closeFlashNotification} />
        </div>
      </div>
    </div>
  )
}

FlashNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  hide: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
  closeFlashNotification: PropTypes.func,
}

export default FlashNotification
