import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLANS,
} from 'constants/apiConstants'

const patchSubscriptionPlanAPI = ({ id, values, credentials, meta }) => {
  const path = SUBSCRIPTION_PLAN({ subscriptionPlanId: id })

  return paymentsAPI.put({
    values,
    path,
    meta,
    credentials,
    service: SUBSCRIPTION_PLANS,
  })
}

export default patchSubscriptionPlanAPI
