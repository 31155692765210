import { nonEmptyNumber, validateFile, validateWebsite } from 'utilities/validate'

const validateOnboardingConfigurationForm = (values, { config }) => {
  const {
    onboardingFormLogo,
    onboardingLinkExpiration,
    onboardingFeeUrl,
    onboardingTermsOfServiceUrl,
    onboardingReturnUrl,
    onboardingExpiredUrl,
  } = values

  const validatedFile = onboardingFormLogo ? validateFile(onboardingFormLogo, config) : false

  return {
    onboardingFormLogo: validatedFile,
    onboardingLinkExpiration: onboardingLinkExpiration ? nonEmptyNumber({ field: onboardingLinkExpiration, name: 'Link Expiration Time (Days)' }) : false,
    onboardingFeeUrl: validateWebsite({ field: onboardingFeeUrl, name: 'Fee URL', canBeEmpty: true }),
    onboardingReturnUrl: validateWebsite({ field: onboardingReturnUrl, name: 'Return URL', canBeEmpty: true }),
    onboardingTermsOfServiceUrl: validateWebsite({ field: onboardingTermsOfServiceUrl, name: 'Terms of Service URL', canBeEmpty: true }),
    onboardingExpiredUrl: validateWebsite({ field: onboardingExpiredUrl, name: 'Expired Session URL', canBeEmpty: true }),
  }
}

export default validateOnboardingConfigurationForm
