import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  REVIEW_QUEUE,
  FINIX_VERSION,
  FINIX_VERSION_2022_02_01,
} from 'constants/apiConstants'

const getReviewQueueAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: REVIEW_QUEUE,
    queries,
    credentials,
    service: REVIEW_QUEUE,
    // TODO: Remove until count is supported, tested that all filters still work
    // headers: {
    //   [FINIX_VERSION]: FINIX_VERSION_2022_02_01,
    // },
  })
}

export default getReviewQueueAPI
