import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import { CREATE_IDENTITY_PAYMENT_INSTRUMENT_MAPPING } from 'constants/amplitudeConstants'
import { PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST } from 'constants/flowConstants'
import { SETTLEMENT_ALIAS_FE_BE_MAPPING } from 'constants/paymentInstrumentConstants'
import map from 'lodash/map'

const submitEditIdentityBankAccountsForm = (values, dispatch, props) => {
  const { credentials, identityId } = props

  const payoutPlanInstrumentMapping = {
    payout_plan_instrument_mapping_list: map(values, (paymentInstrumentId, settlementAlias) => {
      return {
        payment_instrument: paymentInstrumentId,
        settlement_alias: SETTLEMENT_ALIAS_FE_BE_MAPPING[settlementAlias],
      }
    }),
  }

  dispatch({
    type: PATCH_IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING_F_REQUEST,
    payload: {
      values: payoutPlanInstrumentMapping,
      credentials,
      id: identityId,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(CREATE_IDENTITY_PAYMENT_INSTRUMENT_MAPPING, {
        credentials,
        identityId,
        payoutPlanInstrumentMapping,
      }),
      successCallback: () => dispatch(getIdentityRequest({ identityId, credentials })),
    },
  })
}

export default submitEditIdentityBankAccountsForm
