import React from 'react'
import { connect } from 'react-redux'
import Modal from 'components/Shared/Modal/Modal'
import hideModalAction from 'utilities/actions/hideModalAction'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import { MODAL_TYPE_TO_COMPONENT_MAP } from 'constants/modalComponentsContants'

const mapStateToProps = (state) => ({ ...state.modalR })

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (e) => {
      if (allowedKeyPressControls(e)) {
        if (e) e.preventDefault()
        dispatch(hideModalAction())
      }
    },
  }
}

class ModalC extends React.Component {
  render() {
    const {
      isOpen,
      modalType,
      Component,
    } = this.props

    const ModalComponent = Component || MODAL_TYPE_TO_COMPONENT_MAP[modalType]

    if (!ModalComponent) {
      return null
    }

    return (
      <Modal
        isOpen={isOpen}
        ModalComponent={ModalComponent}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalC)
