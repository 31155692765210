import './ApplicationPayoutsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Payouts/columnDescriptors'
import { APPLICATION_PAYOUTS } from 'constants/linkConstants'
import { GET_APPLICATION_TRANSFERS } from 'constants/flowConstants'
import { PAYOUTS_ICON } from 'constants/iconConstants'
import { getApplicationTransfers } from 'state-layer/selectors'

const ApplicationPayouts = ({
  flowValues = {},
  initialQueries = {},
  paymentPath = '',
  isFetching = false,
  applicationId = '',
  allowedFilters = [],
  quickFilters,
}) => {
  return (
    <div className='ApplicationPayouts'>
      <TableC
        flow={GET_APPLICATION_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationTransfers}
        selectorId={applicationId}
        initialQueries={initialQueries}
        linksKey={APPLICATION_PAYOUTS}
        path={paymentPath}
        isFetching={isFetching}
        icon={`fa fa-${PAYOUTS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Payouts`}
        allowedFilters={allowedFilters}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ApplicationPayouts.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  allowedFilters: PropTypes.array,
  quickFilters: PropTypes.array,
}

export default ApplicationPayouts
