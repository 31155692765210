import './IdentityBusinessInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import Button from 'components/Shared/Button/Button'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import BusinessTaxIdField from 'components/Shared/Inputs/BusinessTaxIdField/BusinessTaxIdField'
import validateIdentityBusinessInfoForm from 'utilities/validate/validateIdentityBusinessInfoForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import submitOnboardingStepForm from 'utilities/submit/submitOnboardingStepForm'
import { IDENTITY_BUSINESS_INFO_FORM } from 'constants/formConstants'

import {
  CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP,
  CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP,
  BUSINESS_ADDRESS,
  BUSINESS_INFORMATION,
  TELL_US_ABOUT_YOUR_BUSINESS,
  BUSINESS_INFO_DESCRIPTION,
  ADD_ADDITIONAL_DETAILS_ABOUT_YOUR_BUSINESS,
  ADDITIONAL_BUSINESS_DETAILS_DESCRIPTION,
  LEGAL_BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_DESCRIPTION,
  BUSINESS_TYPE,
  OWNERSHIP_TYPE,
  DATE_OF_INCORPORATION,
  WEBSITE,
  BUSINESS_PHONE_NUMBER,
  SAVE_AND_CONTINUE,
  PLEASE_COMPLETE_STEP_MESSAGE,
  BUSINESS_NAME_LOWER_LABEL,
  BUSINESS_DBA_LOWER_LABEL,
  BUSINESS_DESCRIPTION_TOOLTIP,
  WEBSITE_LOWER_LABEL,
  BUSINESS_PHONE_NUMBER_LOWER_LABEL,
  BUSINESS_ADDRESS_LINE1_LOWER_LABEL,
} from 'constants/language/languageConstants'

import {
  MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS,
  OWNERSHIP_TYPE_OPTIONS,
} from 'constants/identityConstants'

const IdentityBusinessInfoForm = ({
  handleSubmit = () => {},
  submitting = false,
  disabled = false,
  hideOwnershipTypeDropdown = false,
  isFormInvalid = false,
  onboardingFormCountry,
  businessType = '',
  isCanadianSoleProp = false,
  isBoardingToFinixV1Only = false,
}) => {
  return (
    <form className='IdentityBusinessInfoForm' onSubmit={handleSubmit(submitOnboardingStepForm)}>
      <div className='form-header'>
        <h3>{BUSINESS_INFORMATION}</h3>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} />}
        <div className='primary-business-section'>
          <h5>{TELL_US_ABOUT_YOUR_BUSINESS}</h5>
          <div className='sub-title'>{BUSINESS_INFO_DESCRIPTION}</div>
          <Field
            name='businessData.businessName'
            label={LEGAL_BUSINESS_NAME}
            helperText={BUSINESS_NAME_LOWER_LABEL}
            component={InputField}
            autofill={false}
          />

          <Field
            name='businessData.doingBusinessAs'
            label={DOING_BUSINESS_AS}
            helperText={BUSINESS_DBA_LOWER_LABEL}
            component={InputField}
            autofill={false}
          />

          <Field
            id='business-description-input'
            name='businessData.businessDescription'
            label={BUSINESS_DESCRIPTION}
            characterLimit={250}
            tooltip={BUSINESS_DESCRIPTION_TOOLTIP}
            component={TextAreaField}
            autofill={false}
          />

          <Field
            name='businessData.businessType'
            label={BUSINESS_TYPE}
            tooltip={CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP}
            component={SelectField}
            options={MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS}
          />

          {!hideOwnershipTypeDropdown && (
          <Field
            name='businessData.ownershipType'
            label={OWNERSHIP_TYPE}
            tooltip={CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP}
            component={SelectField}
            options={OWNERSHIP_TYPE_OPTIONS}
          />
          )}

          <DateFieldset
            name='businessData.incorporationDate'
            label={DATE_OF_INCORPORATION}
          />

          <BusinessTaxIdField
            name='businessData.businessTaxId'
            country={onboardingFormCountry}
            required={!isCanadianSoleProp}
            businessType={businessType}
          />
        </div>

        <div className='additional-business-details-section'>
          <h5>{ADD_ADDITIONAL_DETAILS_ABOUT_YOUR_BUSINESS}</h5>
          <div className='sub-title'>{ADDITIONAL_BUSINESS_DETAILS_DESCRIPTION}</div>
          <Field
            name='businessData.website'
            label={WEBSITE}
            helperText={WEBSITE_LOWER_LABEL}
            placeholder='https://example.com'
            component={InputField}
          />

          <Field
            name='businessData.businessPhone'
            label={BUSINESS_PHONE_NUMBER}
            helperText={BUSINESS_PHONE_NUMBER_LOWER_LABEL}
            placeholder='123-456-7890'
            component={InputField}
            autofill={false}
            format={formatPhone}
            parse={parsePhone}
            required={!isCanadianSoleProp}
          />

          {onboardingFormCountry && (
          <AddressC
            name='businessData.businessAddress'
            label={BUSINESS_ADDRESS}
            helperText={{ addressLine1HelperText: BUSINESS_ADDRESS_LINE1_LOWER_LABEL }}
            form={IDENTITY_BUSINESS_INFO_FORM}
            required={!isCanadianSoleProp}
            presetCountry={onboardingFormCountry}
          />
          )}
        </div>
      </div>


      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button type='submit' label={SAVE_AND_CONTINUE} submitting={submitting} disabled={disabled} />
          </div>
        </div>
      </div>
    </form>
  )
}

IdentityBusinessInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  hideOwnershipTypeDropdown: PropTypes.bool,
  isFormInvalid: PropTypes.bool,
  onboardingFormCountry: PropTypes.string,
  businessType: PropTypes.string,
  isCanadianSoleProp: PropTypes.bool,
  isBoardingToFinixV1Only: PropTypes.bool,
}

export default reduxForm({
  form: IDENTITY_BUSINESS_INFO_FORM,
  validate: validateIdentityBusinessInfoForm,
  enableReinitialize: true,
})(IdentityBusinessInfoForm)
