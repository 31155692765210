import paymentsAPI from 'utilities/api/paymentsAPI'

const getPaymentsAPI = ({
  path,
  absolutePath,
  credentials,
  queries,
  meta,
  service,
}) => {
  if (!absolutePath && !path) return {}

  return paymentsAPI.get({
    path,
    absolutePath,
    credentials,
    queries,
    meta,
    service,
  })
}

export default getPaymentsAPI
