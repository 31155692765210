import merge from 'lodash/merge'
import {
  CORPORATION_VALUE,
} from 'constants/identityConstants'

const formatIdentityInitialValues = (identity = {}) => {
  const { businessType, ownershipType } = identity

  return merge({}, identity, {
    businessType: businessType === CORPORATION_VALUE ? `${ownershipType}_${businessType}` : businessType,
  })
}

export default formatIdentityInitialValues
