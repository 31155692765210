import { GET_UNDERWRITING_WORKFLOW_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUnderwritingWorkflowByApplicationIdRequest = ({
  workflowId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_UNDERWRITING_WORKFLOW_F_REQUEST,
  id: workflowId,
  credentials,
  queries,
  meta,
})

export default getUnderwritingWorkflowByApplicationIdRequest
