import React from 'react'
import ReactDOM from 'react-dom'
import MainRouter from 'components/System/MainRouter/MainRouter'
import configureStore from 'state-layer/configureStore'
import rootReducer from 'state-layer/reducers'
import rootFlows from 'state-layer/flows'
import 'styles/base.scss'

const render = (Component) => {
  ReactDOM.render(
    <Component
      store={configureStore({
        initialState: {},
        reducers: rootReducer,
        sagas: rootFlows,
      })}
    />,
    document.getElementById('root'),
  )
}

render(MainRouter)
