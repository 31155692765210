import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import InvalidateComplianceFormC from 'components/Customer/Forms/InvalidateComplianceForm/InvalidateComplianceFormC'
import { INVALIDATE_COMPLIANCE_FORM } from 'constants/language/languageConstants'

const InvalidateComplianceFormModal = ({
  complianceFormId = '',
}) => {
  return (
    <GenericModal
      className='InvalidateComplianceFormModal'
      title={INVALIDATE_COMPLIANCE_FORM}
      Component={InvalidateComplianceFormC}
      complianceFormId={complianceFormId}
    />
  )
}

InvalidateComplianceFormModal.propTypes = {
  complianceFormId: PropTypes.string,
}

export default InvalidateComplianceFormModal
