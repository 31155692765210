import { GET_PLATFORM_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPlatformRequest = ({
  platformId,
  credentials,
}) => createAction({
  type: GET_PLATFORM_F_REQUEST,
  id: platformId,
  credentials,
})

export default getPlatformRequest
