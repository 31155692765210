import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { convertTagValues } from 'utilities/convert/convertTagValues'
import { CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT } from 'constants/amplitudeConstants'
import { POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST } from 'constants/flowConstants'
import omit from 'lodash/omit'
import merge from 'lodash/merge'

const submitCreateSubscriptionAmountForm = (values = {}, dispatch, props) => {
  const { credentials, subscriptionScheduleId } = props
  const amountValues = merge(omit(values, 'tags'), { tags: convertTagValues(values) })

  dispatch({
    type: POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
    payload: {
      id: subscriptionScheduleId,
      values: amountValues,
      credentials,
    },
    meta: {
      selectorId: subscriptionScheduleId,
      actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT, {
        credentials,
        subscriptionScheduleId,
        ...amountValues,
      }),
    },
  })
}

export default submitCreateSubscriptionAmountForm
