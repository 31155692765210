import { LOCATION_CHANGE } from 'react-router-redux'
import { RENDER_TAB } from 'constants/flowConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'

let currentPath = ''

const tabsR = (state = {}, action) => {
  switch (action.type) {
    case RENDER_TAB: {
      const tabQuery = get(action, 'payload.values.tabQuery')
      const isSecondaryTab = get(action, 'payload.values.isSecondaryTab')

      if (isSecondaryTab) {
        return merge({}, state, {
          secondaryTab: tabQuery,
        })
      }
      return { tab: tabQuery }
    }

    case LOCATION_CHANGE: {
      const pathname = get(action, 'payload.pathname')

      if (pathname !== currentPath) {
        currentPath = pathname

        return {}
      }

      return state
    }

    default:
      return state
  }
}

export default tabsR
