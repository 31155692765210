import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ComplianceForms from './ComplianceForms'
import columnDescriptors from './columnDescriptors'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { FETCHING } from 'constants/reducerConstants'
import { COMPLIANCE_FORMS_PATH } from 'constants/pathConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'

import {
  COMPLIANCE_FORM_STATUS,
  LINKED_TO,
} from 'constants/filterConstants'

const allowedFilters = [
  LINKED_TO,
  COMPLIANCE_FORM_STATUS,
]

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `complianceFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const complianceFormsPath = COMPLIANCE_FORMS_PATH({ credentialId })
  const formattedColumnDescriptors = columnDescriptors({ isRolePlatform: isRolePlatform({ credentials }) })
  const isRoleMerchantView = get(props, 'isRoleMerchantView', false)

  const initialQueries = removeUndefined({
    sort: 'created_at,asc',
  })

  const initialFilters = isRoleMerchantView ? {
    created_at: {
      gte: '2022-01-01',
      lte: moment().format(YYYY_MM_DD),
    },
  } : {
    created_at: {
      gte: '2022-01-01',
      lte: moment().format(YYYY_MM_DD),
    },
    state: {
      eq: 'INCOMPLETE,OVERDUE',
    },
  }

  return {
    allowedFilters,
    initialQueries,
    initialFilters,
    isFetching,
    complianceFormsPath,
    columnDescriptors: formattedColumnDescriptors,
    quickFilters,
  }
}

class ComplianceFormsC extends Component {
  render() {
    return (
      <ComplianceForms {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceFormsC)
