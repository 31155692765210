import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'

// TODO: expand to cover all settlement settlementEntry types + add test
const frontendSettlementEntryModel = ({ data: settlementEntry }) => {
  const [
    id,
    settlementId,
    entryType,
    entryId = '',
    entrySubType,
    amount,
    currency,
    transferId,
    shouldFund,
    createdAt,
    updatedAt,
  ] = getMany(settlementEntry, [
    'id',
    'settlement_id',
    'entry_type',
    'entry_id',
    'entry_sub_type',
    'amount',
    'currency',
    'legacy_transfer_id',
    'should_fund',
    'created_at',
    'updated_at',
  ])

  const entryIdPrefix = entryId.slice(0, 2)
  // used to display TRxxx instead of RVxxx/DIxxx/etc. in SettlementEntries table -> ID column
  const resourceId = entryIdPrefix === 'FE' ? entryId : transferId

  return removeUndefined({
    id,
    resourceId,
    settlementId,
    entryType,
    entryId,
    entrySubType,
    displayEntrySubType: convertSnakeToSentenceCase(entrySubType),
    amount,
    displayAmount: formatMoney({ amount, currency }),
    displayAmountWithCurrencyCode: formatMoney({ amount, currency, showCurrencyCode: true }),
    currency,
    transferId,
    shouldFund,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
  })
}

export default frontendSettlementEntryModel
