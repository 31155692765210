import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import WebhookLogs from './WebhookLogs'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  WEBHOOK_LOG_STATUS_QUICK_FILTER,
  WEBHOOK_LOGS_DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  ATTEMPT_ID,
  WEBHOOK_ID,
  EVENT_ID,
  EVENT_ENTITY,
  EVENT_TYPE,
} from 'constants/filterConstants'

import {
  WEBHOOK_LOGS_PATH,
  APPLICATION_WEBHOOK_LOGS_PATH,
} from 'constants/pathConstants'

const allowedFilters = [
  ATTEMPT_ID,
  EVENT_ID,
  EVENT_ENTITY,
  EVENT_TYPE,
  WEBHOOK_ID,
]

const quickFilters = [
  WEBHOOK_LOG_STATUS_QUICK_FILTER,
  WEBHOOK_LOGS_DATE_QUICK_FILTER,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `webhookLogsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const applicationId = isRolePlatform({ credentials }) ? get(props, 'applicationId') : null
  const webhookPath = applicationId ? APPLICATION_WEBHOOK_LOGS_PATH({ credentialId, applicationId }) : WEBHOOK_LOGS_PATH({ credentialId })
  const normalizedQueries = applicationId ? merge({}, initialQueries, { application_id: applicationId }) : initialQueries

  return {
    webhookPath,
    initialQueries: normalizedQueries,
    isFetching,
    credentials,
    allowedFilters,
    credentialId,
    quickFilters,
    applicationId,
  }
}

class WebhookLogsC extends Component {
  render() {
    return (
      <WebhookLogs
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(WebhookLogsC)
