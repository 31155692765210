const formatPercentage = ({
  percentage = 0,
  isDecimal = false,
}) => {
  const normalizedPercent = isDecimal ? (percentage * 100).toFixed(2) : percentage

  if (normalizedPercent === 0) {
    return '0%'
  }

  if (!normalizedPercent) {
    return ''
  }

  return `${normalizedPercent}%`
}

export default formatPercentage
