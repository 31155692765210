import frontendUserModel from 'utilities/create/models/frontendUserModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendUsersModel = ({ data: users }) => {
  const usersModels = map(users, (user) => frontendUserModel({ data: user }))

  return keyBy(usersModels, 'id')
}

export default frontendUsersModel
