import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import find from 'lodash/find'

import {
  USD,
  CAD,
} from 'constants/currencyConstants'

const frontendFeeProfileSettingModel = ({ data: feeProfileSetting }) => {
  const [
    createdAt,
    updatedAt,
    entityId,
    entityType,
    settings,
  ] = getMany(feeProfileSetting, [
    'created_at',
    'updated_at',
    'entity_id',
    'entity_type',
    'fee_profile_settings',
  ])

  const settingModels = map(settings, setting => {
    const [
      canCreateFeeProfiles,
      canAssignFeeProfiles,
      enforceLimitsForFeeProfiles,
      currency,
    ] = getMany(setting, [
      'settings.can_create_fee_profiles',
      'settings.can_assign_fee_profiles',
      'settings.enforce_limits_for_fee_profiles',
      'currency',
    ])

    return {
      canCreateFeeProfiles,
      canAssignFeeProfiles,
      enforceLimitsForFeeProfiles,
      createdAt,
      updatedAt,
      currency,
    }
  })

  return {
    entityId,
    entityType,
    [USD]: find(settingModels, { currency: USD }),
    [CAD]: find(settingModels, { currency: CAD }),
  }
}

export default frontendFeeProfileSettingModel
