import SelectSenderForCreatePaymentForm from './SelectSenderForCreatePaymentForm'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { goBack } from 'state-layer/history'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getUrlQuery from 'utilities/get/getUrlQuery'
import hideModalAction from 'utilities/actions/hideModalAction'
import { SELECT_SENDER_FOR_CREATE_PAYMENT_FORM } from 'constants/formConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'

import {
  DUMMY_V1,
  LITLE_V1,
  FINIX_V1,
  PROCESSOR_LABEL_MAP,
} from 'constants/processorConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const environment = get(credentials, 'environment')
  const applicationId = getUrlQuery('applicationId') || get(currentUser, 'applicationId')
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const formSelector = formValueSelector(SELECT_SENDER_FOR_CREATE_PAYMENT_FORM)
  const merchantFormValue = formSelector(state, 'merchant')
  const processorFormValue = formSelector(state, 'processor.value')
  const hasMultipleProcessorOptions = size(allowedProcessors) > 1
  const selectedProcessor = hasMultipleProcessorOptions ? processorFormValue : DUMMY_V1
  const filteredMerchants = filter(applicationMerchants, ({ mid, processor }) => isEqual(processor, selectedProcessor) && !isEmpty(mid))
  const isCreateSubscriptionFlow = get(props, 'isCreateSubscriptionFlow')

  const merchantOptions = !isEmpty(filteredMerchants) ? map(filteredMerchants, (merchant) => {
    const {
      id,
      name,
      merchantIdentity,
    } = merchant

    const displayBusinessAddress = get(merchantIdentity, 'displayBusinessAddress')

    return {
      label: <div className='flex items-center'>{get(merchantIdentity, 'displayBusinessName') || name} <span className='secondary'>{displayBusinessAddress}</span></div>,
      value: id,
    }
  }) : []

  const processorOptions = hasMultipleProcessorOptions ? map(allowedProcessors, (processor) => {
    return {
      label: get(PROCESSOR_LABEL_MAP, processor, processor),
      value: processor,
    }
  }) : []

  return {
    credentials,
    applicationId,
    merchantOptions,
    merchantFormValue,
    processorOptions,
    hasMultipleProcessorOptions,
    processorFormValue,
    isCreateSubscriptionFlow,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class SelectSenderForCreatePaymentFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    const {
      applicationId: prevApplicationId,
    } = prevProps

    if (prevApplicationId !== applicationId && applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  exitModal = () => {
    const { closeModal } = this.props

    closeModal()
    goBack()
  }

  render() {
    return (
      <SelectSenderForCreatePaymentForm
        {...this.props}
        exitModal={this.exitModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSenderForCreatePaymentFormC)
