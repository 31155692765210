import React, { Component } from 'react'
import { connect } from 'react-redux'
import Identities from './Identities'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { isFlexPlatform } from 'constants/flexConstants'
import { ROLE_PARTNER } from 'constants/roleConstants'
import { FETCHING } from 'constants/reducerConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { ADD_ICON } from 'constants/iconConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'

import {
  IDENTITIES_PATH,
  NEW_IDENTITY_PATH,
  NEW_IDENTITY_PATH_FLEX,
} from 'constants/pathConstants'

import {
  APPLICATION_ID,
  BUSINESS_TYPE,
  TAG_FILTERS,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  TITLE,
} from 'constants/filterConstants'

const quickFilters = [
  DATE_QUICK_FILTER,
]

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter('Identity'),
  APPLICATION_ID,
  BUSINESS_NAME,
  BUSINESS_TYPE,
  DOING_BUSINESS_AS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  TITLE,
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const identitiesPath = IDENTITIES_PATH({ credentialId })
  const isFlex = isFlexPlatform()

  const actions = [
    {
      label: 'Create Identity',
      leftButtonIcon: ADD_ICON,
      link: NEW_IDENTITY_PATH({ credentialId }),
      className: 'createIdentity',
      condition: ({ role }) => (role === ROLE_PARTNER) && !isFlex,
    },
    {
      label: 'Create Identity',
      leftButtonIcon: ADD_ICON,
      link: NEW_IDENTITY_PATH_FLEX({ credentialId }),
      className: 'create-new-identity-button',
      condition: ({ role }) => (role === ROLE_PARTNER) && isFlex,
    },
  ]

  return {
    quickFilters,
    allowedFilters,
    initialQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    identitiesPath,
    actions,
  }
}

class IdentitiesC extends Component {
  render() {
    return (
      <Identities {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentitiesC)
