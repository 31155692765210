import './ToggleSectionS.scss'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import classnames from 'classnames'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import getCheckedStatus from 'utilities/get/getCheckedStatus'
import { PERMISSION_FORM } from 'constants/formConstants'
import { ANGLE_UP_ICON, ANGLE_DOWN_ICON } from 'constants/iconConstants'
import capitalize from 'lodash/capitalize'
import join from 'lodash/join'
import map from 'lodash/map'
import split from 'lodash/split'
import replace from 'lodash/replace'

class ToggleSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: true,
    }
  }

  toggleContents = () => {
    const { show } = this.state
    this.setState({ show: !show })
  }

  render() {
    const { toggleContents } = this
    const { show } = this.state

    const {
      name,
      permissions,
      isReadOnly,
      fieldValueSelector,
      form = PERMISSION_FORM,
    } = this.props

    const headerClasses = classnames('header', 'bold', 'flex', 'space-between', { show })
    const iconClasses = classnames('fa', {
      [`fa-${ANGLE_UP_ICON}`]: show,
      [`fa-${ANGLE_DOWN_ICON}`]: !show,
    })

    const titleWithoutDashes = replace(name, '-', '_')
    const formattedTitle = titleWithoutDashes ? join(map(split(titleWithoutDashes, '_'), capitalize), ' ') : ''

    return (
      <div className='ToggleSection section'>
        <div className={headerClasses} onClick={toggleContents}>
          <h3>{formattedTitle}</h3>
          <i className={iconClasses} />
        </div>

        { show && (
          <div className='contents'>
            { map(permissions, ({ name: permissionName, alias_name: alias }) => {
              const aliasWithoutUnderscore = replace(alias, '_', '-')
              const formattedAlias = join(map(split(replace(aliasWithoutUnderscore, '-', ' '), ' '), capitalize), ' ')
              const classByName = permissionName.replace(/:/g, '-')
              const classByValue = fieldValueSelector(permissionName) ? 'enabled' : 'disabled'
              const checked = getCheckedStatus(permissionName, form)

              const componentProps = {
                className: `${classByName} ${classByValue}`,
              }

              return (
                <Field
                  key={permissionName}
                  name={permissionName}
                  label={formattedAlias}
                  component={ToggleSwitchC}
                  props={componentProps}
                  disabled={isReadOnly}
                  checked={checked}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default ToggleSection
