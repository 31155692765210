import './CreateFindUserFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { CREATE_FIND_USER_FORM } from 'constants/formConstants'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import validateUserEmailForm from 'utilities/validate/validateUserEmailForm'
import Button from 'components/Shared/Button/Button'
import { EMAIL_ADDRESS } from 'constants/language/languageConstants'

const CreateFindUserForm = ({
  handleSubmit = () => {},
  submitLabel = 'Save',
}) => {
  return (
    <form className='CreateFindUserForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h4>Create or Find User</h4>

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          placeholder='user-email@example.com'
          component={InputField}
        />

        <div className='buttons flex flex-end'>
          <Button type='submit' label={submitLabel} />
        </div>
      </div>
    </form>
  )
}

CreateFindUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
}

export default reduxForm({
  form: CREATE_FIND_USER_FORM,
  validate: validateUserEmailForm,
})(CreateFindUserForm)
