import React, { Component } from 'react'
import { connect } from 'react-redux'
import RecipientLimits from './RecipientLimits'
import { goToPath } from 'state-layer/history'
import { getDisbursementRulesSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  UPDATE_RECIPIENT_CARD_LIMITS_PATH,
  UPDATE_RECIPIENT_ACH_LIMITS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const noEditAllowed = get(props, 'noEditAllowed')
  const disbursementRules = getDisbursementRulesSelector(state)
  const isRolePlatformCred = isRolePlatform({ credentials })

  const [
    recipientACHRules,
    recipientCardRules,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  ] = getMany(disbursementRules, [
    'recipientACHRules',
    'recipientCardRules',
    'recipientACHRulesMaxTransactionAmount',
    'recipientCardRulesMaxTransactionAmount',
  ])

  const setCardVelocityLimitActions = [
    {
      label: EDIT,
      action: () => goToPath({
        pathname: UPDATE_RECIPIENT_CARD_LIMITS_PATH({ credentialId, applicationId }),
      }),
      condition: isRolePlatformCred && !noEditAllowed,
    },
  ]

  const setACHVelocityLimitActions = [
    {
      label: EDIT,
      action: () => goToPath({
        pathname: UPDATE_RECIPIENT_ACH_LIMITS_PATH({ credentialId, applicationId }),
      }),
      condition: isRolePlatformCred && !noEditAllowed,
    },
  ]

  return {
    credentials,
    applicationId,
    setCardVelocityLimitActions,
    setACHVelocityLimitActions,
    recipientACHRules,
    recipientCardRules,
    isFetching: get(state, `disbursementRulesR.${FETCHING}`),
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  }
}

class RecipientLimitsC extends Component {
  render() {
    return (
      <RecipientLimits
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(RecipientLimitsC)
