import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORMS_PRESIGNED_URL } from 'constants/apiConstants'

const postAccessFormFilePresignedUrlAPI = ({ values, credentials }) => {
  return dashboardAPI.post({
    path: DASHBOARD_ACCESS_FORMS_PRESIGNED_URL,
    values,
    credentials,
  })
}

export default postAccessFormFilePresignedUrlAPI
