import dashboardAPI from 'utilities/api/dashboardAPI'
import { USER_FWS } from 'constants/apiConstants'

const getUserAPI = ({ id, meta }) => {
  return dashboardAPI.get({
    meta,
    path: USER_FWS({ id }),
  })
}

export default getUserAPI
