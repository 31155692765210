import { CAMEL_CASE_REGEX } from 'constants/regexConstants'
import { isNumber } from 'utilities/validate/index'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import toString from 'lodash/toString'
import isString from 'lodash/isString'

const camelCaseToTitleCase = (key = '') => {
  if (!isString(key)) {
    return isNumber(key) ? toString(key) : ''
  }

  const parts = key.match(CAMEL_CASE_REGEX).slice(0, -1)

  return map(parts, (part) => capitalize(part)).join(' ')
}

export default camelCaseToTitleCase
