import paymentsAPI from 'utilities/api/paymentsAPI'
import { STATEMENTS } from 'constants/apiConstants'

const getStatementsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: STATEMENTS,
    queries,
    credentials,
  })
}

export default getStatementsAPI
