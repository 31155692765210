import React, { Component } from 'react'
import ComplianceAssessmentInformationForm from './ComplianceAssessmentInformationForm'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { COMPLIANCE_ASSESSMENT_INFORMATION_FORM } from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(COMPLIANCE_ASSESSMENT_INFORMATION_FORM)
  const oneOrMorePaymentApplications = formSelector(state, 'oneOrMorePaymentApplications')
  const usedQir = formSelector(state, 'usedQir')
  const companyShareCardholderDataWithThirdPartyServiceProviders = formSelector(state, 'companyShareCardholderDataWithThirdPartyServiceProviders')

  return {
    oneOrMorePaymentApplications,
    usedQir,
    companyShareCardholderDataWithThirdPartyServiceProviders,
  }
}

class ComplianceAssessmentInformationFormC extends Component {
  render() {
    return (
      <ComplianceAssessmentInformationForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceAssessmentInformationFormC)
