import './LevelThreeItemsSectionDataS.scss'
import React from 'react'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import formatMoney from 'utilities/formatters/formatMoney'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  COMMODITY_CODE,
  COST_PER_UNIT,
  DISCOUNT_PER_UNIT,
  EXCLUDING_SALES_TAX,
  INCLUDING_SALES_TAX,
  ITEM,
  MERCHANT_PRODUCT_CODE,
  PRODUCT_DESCRIPTION,
  QUANTITY,
  TOTAL_ITEM_COST,
  UNIT_OF_MEASURE,
} from 'constants/language/languageConstants'

const LevelThreeItemsSectionData = ({ levelThreeItems }) => {
  return map(levelThreeItems, (item, index) => {
    const [
      merchantProductCode,
      productDescription,
      itemDescription,
      costPerUnit,
      discountPerUnit,
      quantity,
      unitOfMeasure,
      totalItemCostExcludingSalesTax,
      totalItemCostIncludingSalesTax,
      displayAmountExcludingSalesTax,
      displayAmountIncludingSalesTax,
      commodityCode,
      displayItemDiscountAmount,
    ] = getMany(item, [
      'merchantProductCode',
      'productDescription',
      'itemDescription',
      'costPerUnit',
      'discountPerUnit',
      'quantity',
      'unitOfMeasure',
      'totalItemCostExcludingSalesTax',
      'totalItemCostIncludingSalesTax',
      'displayAmountExcludingSalesTax',
      'displayAmountIncludingSalesTax',
      'commodityCode',
      'displayItemDiscountAmount',
    ])

    const columns = convertPageSectionDataToV2([
      {
        label: MERCHANT_PRODUCT_CODE,
        value: merchantProductCode,
      },
      {
        label: PRODUCT_DESCRIPTION,
        value: productDescription || itemDescription,
      },
      {
        label: COST_PER_UNIT,
        value: formatMoney({ amount: costPerUnit, showCurrencyCode: true }),
      },
      {
        label: DISCOUNT_PER_UNIT,
        value: formatMoney({ amount: discountPerUnit, showCurrencyCode: true }) || displayItemDiscountAmount,
      },
      {
        label: QUANTITY,
        value: quantity,
      },
      {
        label: UNIT_OF_MEASURE,
        value: get(unitOfMeasure, 'value') || unitOfMeasure,
      },
      {
        label: <><div>{TOTAL_ITEM_COST}</div><div>({EXCLUDING_SALES_TAX})</div></>,
        value: formatMoney({ amount: totalItemCostExcludingSalesTax, showCurrencyCode: true }) || displayAmountExcludingSalesTax,
      },
      {
        label: <><div>{TOTAL_ITEM_COST}</div><div>({INCLUDING_SALES_TAX})</div></>,
        value: formatMoney({ amount: totalItemCostIncludingSalesTax, showCurrencyCode: true }) || displayAmountIncludingSalesTax,
      },
      {
        label: COMMODITY_CODE,
        value: commodityCode,
      },
    ], 1)

    return (
      <PageSectionV2
        smallTitle={`${ITEM} #${(index + 1)}`}
        className='item-section'
        columns={columns}
      />
    )
  })
}

export default LevelThreeItemsSectionData
