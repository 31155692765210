import MapC from 'components/Customer/Shared/Map/MapC'
import React from 'react'
import PropTypes from 'prop-types'

import {
  ADDRESS,
  BUSINESS_NAME,
} from 'constants/language/languageConstants'

const BusinessLocationMaps = ({
  businessNameMapQuery = '',
  businessAddressMapQuery = '',
}) => {
  return (
    <div className='BusinessLocationMaps flex'>
      <MapC
        title={ADDRESS}
        address={businessAddressMapQuery}
        mapStyles={{ width: '460px', height: '420px' }}
        mapType='satellite'
        allowGeocode
      />

      <MapC
        title={BUSINESS_NAME}
        address={businessNameMapQuery}
        mapStyles={{ width: '460px', height: '420px' }}
      />
    </div>
  )
}

BusinessLocationMaps.propTypes = {
  businessNameMapQuery: PropTypes.string,
  businessAddressMapQuery: PropTypes.string,
}

export default BusinessLocationMaps
