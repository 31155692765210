import paymentsAPI from 'utilities/api/paymentsAPI'
import { USER_PERMISSIONS } from 'constants/apiConstants'
import get from 'lodash/get'

const getUserPermissionsAPI = ({ id, values, credentials, queries, meta }) => {
  const userId = id || get(values, 'userId')

  return paymentsAPI.get({
    meta,
    path: USER_PERMISSIONS({ id: userId }),
    credentials,
    queries,
  })
}

export default getUserPermissionsAPI
