import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantIdentityEdit from './MerchantIdentityEdit'
import IdentityBusinessFormC from 'components/Customer/Forms/IdentityForm/IdentityBusinessFormC'
import IdentityBusinessDisplayData from 'components/Customer/Forms/IdentityForm/IdentityBusinessDisplayData'
import IdentityPrincipalsFormC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsFormC'
import IdentityPrincipalsConfirmationC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsConfirmationC'
import IdentityProcessingFormC from 'components/Customer/Forms/IdentityForm/IdentityProcessingFormC'
import IdentityProcessingDisplayData from 'components/Customer/Forms/IdentityForm/IdentityProcessingDisplayData'
import AdditionalUnderwritingDataFormC from 'components/Customer/Forms/AdditionalUnderwritingDataForm/AdditionalUnderwritingDataFormC'
import AdditionalUnderwritingDisplayData from 'components/Customer/Forms/AdditionalUnderwritingDataForm/AdditionalUnderwritingDisplayData'
import MerchantIdentityConfirmation from 'components/Customer/Pages/IdentityNew/MerchantIdentityConfirmation'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import getAssociatedIdentitiesRequest from 'utilities/actions/get/getAssociatedIdentitiesRequest'
import formatIdentityInitialValues from 'utilities/formatters/formatIdentityInitialValues'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isFlexPlatform } from 'constants/flexConstants'
import { FETCHING } from 'constants/reducerConstants'
import { finixMCCsList } from 'constants/mccConstants'
import { PATCH_MERCHANT_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import values from 'lodash/values'
import includes from 'lodash/includes'
import mapKeys from 'lodash/mapKeys'
import pick from 'lodash/pick'

import {
  IDENTITY_PRINCIPALS_FORM,
  IDENTITY_PROCESSING_FORM,
  IDENTITY_BUSINESS_FORM,
  ADDITIONAL_UNDERWRITING_DATA_FORM,
} from 'constants/formConstants'

import {
  IDENTITY_BUSINESS_FIELDS,
  IDENTITY_PRINCIPAL_FIELDS,
  IDENTITY_PROCESSING_FIELDS,
  ADDITIONAL_UNDERWRITING_DATA_FIELDS,
  ADDITIONAL_UNDERWRITING_DATA_FIELDS_TO_RENAME,
  ADDITIONAL_UNDERWRITING_DATA_FIELDS_KEY_VALUE_MAP,
} from 'constants/identityConstants'

import {
  getIdentitySelector,
  getIdentityAssociatedIdentitiesSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`) || get(state, `AssociatedIdentitiesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)
  const applicationId = get(identity, 'applicationId')
  const associatedIdentities = values(getIdentityAssociatedIdentitiesSelector(state, identityId))
  const owners = [identity, ...associatedIdentities]
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const isFlex = isFlexPlatform()

  const additionalUnderwritingDataInitialValues = mapKeys(pick(identity, ADDITIONAL_UNDERWRITING_DATA_FIELDS), (value, key) => {
    if (includes(ADDITIONAL_UNDERWRITING_DATA_FIELDS_TO_RENAME, key)) {
      return ADDITIONAL_UNDERWRITING_DATA_FIELDS_KEY_VALUE_MAP[key]
    }

    return key
  })

  const mcc = get(identity, 'mcc')
  const mccLabel = finixMCCsList[mcc]

  const processingInfoInitialValues = removeUndefined({
    ...pick(identity, IDENTITY_PROCESSING_FIELDS),
    mcc: mcc ? {
      label: `${mcc} - ${mccLabel}`,
      value: mcc,
    } : undefined,
    achMaxTransactionAmount: get(identity, 'maxACHTransactionAmount'),
  })

  const steps = [
    {
      name: 'Business Info',
      iconName: 'fa-store-alt',
      component: IdentityBusinessFormC,
      props: {
        submitLabel: 'Next',
        edit: true,
      },
      dataKey: 'businessInfo',
      formValues: () => reduxFormValues(IDENTITY_BUSINESS_FORM),
      getDisplayData: IdentityBusinessDisplayData,
      initialValues: formatIdentityInitialValues(pick(identity, IDENTITY_BUSINESS_FIELDS)),
    },
    {
      name: 'Principal Info',
      iconName: 'fa-user',
      component: IdentityPrincipalsFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
        addPrincipal: false,
        edit: true,
      },
      dataKey: 'principalsInfo',
      formValues: () => reduxFormValues(IDENTITY_PRINCIPALS_FORM),
      getDisplayData: ({ principals }) => principals,
      initialValues: { principals: map(owners, (owner) => pick(owner, IDENTITY_PRINCIPAL_FIELDS)) },
      displayComponent: IdentityPrincipalsConfirmationC,
    },
    {
      name: 'Processing Info',
      iconName: 'fa-cog',
      component: IdentityProcessingFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
        applicationId,
      },
      dataKey: 'processingInfo',
      formValues: () => reduxFormValues(IDENTITY_PROCESSING_FORM),
      getDisplayData: IdentityProcessingDisplayData,
      initialValues: processingInfoInitialValues,
    },
    {
      name: 'Additional Info',
      iconName: 'fa-list',
      component: AdditionalUnderwritingDataFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: 'additionalUnderwritingInfo',
      formValues: () => reduxFormValues(ADDITIONAL_UNDERWRITING_DATA_FORM),
      getDisplayData: AdditionalUnderwritingDisplayData,
      initialValues: additionalUnderwritingDataInitialValues,
      hidden: !isFlex,
    },
    {
      name: 'Confirmation',
      iconName: 'fa-check',
      component: MerchantIdentityConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
        recordId: identityId,
        records: map(owners, ({ id }) => id),
        action: PATCH_MERCHANT_IDENTITY_F_REQUEST,
        isFlex,
        backgroundColor: '#0D121A',
      },
    },
  ]

  return {
    isFetching,
    layout: 'vertical',
    steps,
    credentials,
    credentialId,
    identityId,
    identity,
    associatedIdentities,
    applicationId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
    getAssociatedIdentities: ({ identityId, credentials }) => dispatch(getAssociatedIdentitiesRequest({ identityId, credentials })),
  }
}

class MerchantIdentityEditC extends Component {
  componentDidMount() {
    this.fetchAllIdentities()
  }

  componentDidUpdate(prevProps) {
    const { identityId } = this.props
    const { identityId: prevIdentityId } = prevProps

    if (identityId && identityId !== prevIdentityId) {
      this.fetchAllIdentities()
    }
  }

  fetchAllIdentities = () => {
    const {
      credentials,
      identityId,
      getAssociatedIdentities,
      getIdentity,
    } = this.props

    getAssociatedIdentities({ identityId, credentials })
    getIdentity({ identityId, credentials })
  }

  render () {
    return (
      <MerchantIdentityEdit {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantIdentityEditC)
