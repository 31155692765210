import './LiveAccountApplicationRejectedDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  BUSINESS_NOT_APPROVED_FOR_A_LIVE_ACCOUNT,
  LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_1,
  LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_2,
} from 'constants/language/languageConstants'

const LiveAccountApplicationRejectedDetails = ({
  title = BUSINESS_NOT_APPROVED_FOR_A_LIVE_ACCOUNT,
  rightTitle = '',
}) => {
  return (
    <PageSectionV2C
      title={<div className='approved-details-title'>{title}</div>}
      rightTitle={rightTitle}
      className='LiveAccountApplicationRejectedDetails rejected-highlight'
    >
      <div className='rejected-details-highlight'>
        <div className='info-pt-1'>{LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_1}</div>
        <div className='info-pt-2'>{LIVE_ACCOUNT_APPLICATION_REJECTED_INFO_PT_2}</div>
      </div>
    </PageSectionV2C>
  )
}

LiveAccountApplicationRejectedDetails.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
}

export default LiveAccountApplicationRejectedDetails
