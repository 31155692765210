import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import IdentitySettlements from './IdentitySettlements'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { getIdentitySelector } from 'state-layer/selectors'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { IDENTITY_SETTLEMENT } from 'constants/modalConstants'
import { SETTLEMENTS_PATH } from 'constants/pathConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  TOTAL_AMOUNT,
  TRANSFER_ID,
  ENTRY_ID,
} from 'constants/filterConstants'

import {
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const allowedFilters = sortBy([
  TOTAL_AMOUNT(),
  TRANSFER_ID,
  ENTRY_ID,
], 'label')

const quickFilters = [
  SETTLEMENTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const initialFilters = {
  ...createdLastMonthQueries(),
}


const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `identitySettlementsR.${FETCHING}`)
  const identity = getIdentitySelector(state, identityId)
  const merchants = get(identity, 'merchants')
  const showAddSettlementButton = !isEmpty(merchants)
  const credentialId = getCurrentCredentialsId(state)
  const settlementPath = SETTLEMENTS_PATH({ credentialId })

  const flowValues = {
    identityId,
  }

  return {
    identityId,
    initialFilters,
    flowValues,
    isFetching,
    showAddSettlementButton,
    settlementPath,
    allowedFilters,
    quickFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: IDENTITY_SETTLEMENT, modalProps })),
  }
}

class IdentitySettlementsC extends Component {
  showIdentitySettlementModal = () => {
    const {
      identityId,
      showSettlementModal,
    } = this.props

    showSettlementModal({ identityId })
  }

  render() {
    const { showIdentitySettlementModal } = this
    const { showAddSettlementButton } = this.props

    const identitySettlementActions = showAddSettlementButton ? [
      {
        label: 'Create Settlement',
        action: showIdentitySettlementModal,
      },
    ] : []

    return (
      <IdentitySettlements
        {...this.props}
        identitySettlementActions={identitySettlementActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentitySettlementsC)
