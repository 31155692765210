import './PastRunsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getPastRunsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { GET_REVIEW_RUNS } from 'constants/flowConstants'
import { REVIEW_RUNS } from 'constants/linkConstants'

const PastRuns = ({
  isFetching = false,
  flowValues = {},
  pastRunPath = '',
}) => {
  return (
    <div className='PastRuns'>
      <TableC
        flow={GET_REVIEW_RUNS}
        columnDescriptors={columnDescriptors}
        urlQueriesToIgnore={['subjectId', 'entityType', 'entityId']}
        flowValues={flowValues}
        selector={getPastRunsSelector}
        emptyMessage='No Past Runs'
        fileNameCSV='Past_Runs'
        linksKey={REVIEW_RUNS}
        path={pastRunPath}
        isFetching={isFetching}
      />
    </div>
  )
}

PastRuns.propTypes = {
  isFetching: PropTypes.bool,
  flowValues: PropTypes.object,
  pastRunPath: PropTypes.string,
}

export default PastRuns
