import removeUndefined from 'utilities/remove/removeUndefined'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_CHECKOUT_PURCHASE } from 'constants/amplitudeConstants'
import sample from 'lodash/sample'
import split from 'lodash/split'

import {
  SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
  YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
} from 'constants/language/languageConstants'

import {
  POST_PURCHASE_F_REQUEST,
  SHOW_ERROR_FLASH_NOTIFICATION,
  SHOW_SUCCESS_FLASH_NOTIFICATION,
} from 'constants/flowConstants'

// TODO: Add unit tests
const submitGuestCheckoutForm = (values, dispatch, props = {}) => {
  const { checkoutForm } = props

  const {
    id,
    merchantId,
    applicationId,
    amountType,
    totalAmount,
    currency,
    paymentFrequency,
    successReturnUrl,
    unsuccessfulReturnUrl,
  } = checkoutForm

  const {
    name,
    email,
    phone,
    token,
    saleAmount,
    shippingAddress,
  } = values

  const isFixedAmount = amountType === 'FIXED'

  const valuesToSubmit = removeUndefined({
    merchantId,
    applicationId,
    amount: isFixedAmount ? totalAmount : saleAmount,
    currency,
    paymentFrequency,
    token,
    entityId: id,
    entityType: 'CHECKOUT_FORM',
    firstName: split(name, ' ')[0] || '',
    lastName: split(name, ' ')[1] || '',
    email,
    phone,
    shippingAddress,
  })

  dispatch({
    type: POST_PURCHASE_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues: purchases }) => {
        const { state } = sample(purchases) || {}

        if (state === 'FAILED') {
          dispatch({
            type: SHOW_ERROR_FLASH_NOTIFICATION,
            payload: {
              values: {
                message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
              },
            },
            meta: {
              actionId: sendAmplitudeActionEvent(CREATE_CHECKOUT_PURCHASE, {
                paymentLinkId: id,
                merchantId,
                applicationId,
                amountType,
                currency,
                paymentFrequency,
                state: 'FAILED',
              }),
            },
          })

          setTimeout(() => {
            window.location.href = unsuccessfulReturnUrl
          }, 1000)
        } else {
          dispatch({
            type: SHOW_SUCCESS_FLASH_NOTIFICATION,
            payload: {
              values: {
                message: YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED_REDIRECTING,
              },
            },
            meta: {
              actionId: sendAmplitudeActionEvent(CREATE_CHECKOUT_PURCHASE, {
                paymentLinkId: id,
                merchantId,
                applicationId,
                amountType,
                currency,
                paymentFrequency,
                state: 'SUCCESS',
              }),
            },
          })

          setTimeout(() => {
            window.location.href = successReturnUrl
          }, 1000)
        }
      },
    },
    errorCallback: () => {
      dispatch({
        type: SHOW_ERROR_FLASH_NOTIFICATION,
        payload: {
          values: {
            message: SOMETHING_WENT_WRONG_WHILE_PROCESSING_PAYMENT,
          },
        },
        meta: {
          actionId: sendAmplitudeActionEvent(CREATE_CHECKOUT_PURCHASE, {
            paymentLinkId: id,
            merchantId,
            applicationId,
            amountType,
            currency,
            paymentFrequency,
            state: 'ERROR',
          }),
        },
      })

      setTimeout(() => {
        window.location.href = unsuccessfulReturnUrl
      }, 1000)
    },
  })
}

export default submitGuestCheckoutForm
