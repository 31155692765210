import './DisputesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getDisputeMerchantsBuyersSelector } from 'state-layer/selectors'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TableC from 'components/Shared/Table/TableC'
import { DISPUTES } from 'constants/linkConstants'
import { EXCEPTIONS_ICON } from 'constants/iconConstants'
import { DISPUTES_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  GET_DISPUTES,
  DISPUTE,
} from 'constants/flowConstants'

import {
  DISPUTES_RESOURCE_TITLE,
  DISPUTES_EMPTY_TABLE_MESSAGE,
  DISPUTES_TABLE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const Disputes = ({
  disputesPath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters,
  columnDescriptors = [],
}) => {
  return (
    <div className='Disputes'>
      <HeaderV2C
        title={DISPUTES_RESOURCE_TITLE}
        subTitle={DISPUTES_TABLE_SUBTITLE_DESCRIPTION}
        learnMoreLink={DISPUTES_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_DISPUTES}
        columnDescriptors={columnDescriptors}
        selector={getDisputeMerchantsBuyersSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={DISPUTES}
        allowedFilters={allowedFilters}
        path={disputesPath}
        isFetching={isFetching}
        emptyMessage={DISPUTES_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${EXCEPTIONS_ICON}`}
        fileNameCSV={DISPUTES_RESOURCE_TITLE}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={DISPUTE}
      />
    </div>
  )
}

Disputes.propTypes = {
  disputesPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  columnDescriptors: PropTypes.array,
}

export default Disputes
