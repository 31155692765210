import './TemplateTooltipS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import convertTemplateConfigToSectionData from 'utilities/convert/convertTemplateConfigToSectionData'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'

import {
  CREATED_ON,
  UPDATED_AT_LABEL,
  LEVEL,
} from 'constants/language/languageConstants'

const TemplateTooltip = ({
  template = {},
}) => {
  const [
    templateName,
    templateLevel,
    templateConfig = {},
    templateCreatedAt,
    templateUpdatedAt,
  ] = getMany(template, [
    'name',
    'level',
    'config',
    'created_at',
    'updated_at',
  ])

  const templateConfigText = convertTemplateConfigToSectionData(templateConfig)

  return (
    <div className='templateTooltipContainer'>
      <div className='templateTooltip'>
        <h3>{convertSnakeToSentenceCase(templateName)}</h3>

        <div className='tooltip-content'>
          <div className='templateTooltipLabel'><strong>{CREATED_ON}</strong></div>
          <div className='templateTooltipValue'>{formatDate({ date: templateCreatedAt })}</div>

          <div className='templateTooltipLabel'><strong>{UPDATED_AT_LABEL}</strong></div>
          <div className='templateTooltipValue'>{formatDate({ date: templateUpdatedAt })}</div>

          <div className='templateTooltipLabel'><strong>{LEVEL}</strong></div>
          <div className='templateTooltipValue'>{convertSnakeToSentenceCase(templateLevel)}</div>
        </div>

        { map(templateConfigText, ({ label: configLabel, value: configValues }) => {
          return (
            <div key={`${templateName}-${configLabel}`} className='template-configs'>
              <div className='templateTooltipLabel'><strong>{configLabel}</strong></div>
              <div className='templateTooltipValue'>{map(configValues, (configValue) => {
                return (
                  <div key={configValue}>{convertSnakeToSentenceCase(configValue)}</div>
                )
              })}
              </div>
            </div>
          )
        }) }
      </div>
    </div>
  )
}

TemplateTooltip.propTypes = {
  template: PropTypes.object,
}

export default TemplateTooltip
