import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ComplianceFormTemplateForms from './ComplianceFormTemplateForms'
import columnDescriptors from 'components/Customer/Pages/Compliance/ComplianceForms/columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { COMPLIANCE_FORMS_PATH } from 'constants/pathConstants'
import { DUE_ON_DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { YYYY_MM_DD } from 'constants/timeConstants'
import { INCOMPLETE } from 'constants/complianceConstants'
import get from 'lodash/get'

import {
  COMPLIANCE_FORM_STATUS,
} from 'constants/filterConstants'

const allowedFilters = [
  COMPLIANCE_FORM_STATUS,
]

const quickFilters = [
  DUE_ON_DATE_QUICK_FILTER,
]

const filterSets = [
  {
    filterSetName: 'overdue',
    filterSetDisplayName: 'Overdue',
    filters: {
      due_at: {
        operand: 'lte',
        value: moment().subtract(1, 'days').format(YYYY_MM_DD),
      },
      state: {
        operand: 'eq',
        value: INCOMPLETE,
      },
    },
  },
  {
    filterSetName: 'dueInLessThan30Days',
    filterSetDisplayName: 'Due in less than 30 days',
    filters: {
      due_at: {
        operand: 'between',
        gte: moment().add(0, 'days').format(YYYY_MM_DD),
        lte: moment().add(30, 'days').format(YYYY_MM_DD),
      },
      state: {
        operand: 'eq',
        value: INCOMPLETE,
      },
    },
  },
]

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `complianceFormsR.${FETCHING}`)
  const complianceFormsPath = COMPLIANCE_FORMS_PATH({ credentialId })
  const complianceFormTemplateId = get(props, 'complianceFormTemplateId')
  const formattedColumnDescriptors = columnDescriptors({ isRolePlatform: isRolePlatform({ credentials }) })

  const initialQueries = removeUndefined({
    sort: 'due_at,asc',
  })

  return {
    isFetching,
    complianceFormsPath,
    complianceFormTemplateId,
    initialQueries,
    filterSets,
    allowedFilters,
    quickFilters,
    columnDescriptors: formattedColumnDescriptors,
    flowValues: {
      id: complianceFormTemplateId,
    },
  }
}

class ComplianceFormTemplateFormsC extends Component {
  render() {
    return (
      <ComplianceFormTemplateForms {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceFormTemplateFormsC)
