import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { ONBOARDING_FORM_PLAID_TOKEN } from 'constants/apiConstants'

const postOnboardingFormPlaidTokenAPI = ({ id, values, meta }) => {
  return guestDashboardAPI.post({
    path: ONBOARDING_FORM_PLAID_TOKEN({ id }),
    values,
    meta,
  })
}

export default postOnboardingFormPlaidTokenAPI
