import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateOnboardingFormFileForm from './UpdateOnboardingFormFileForm'

const mapStateToProps = (state, props) => {
  const { fileName } = props

  return {
    initialValues: {
      fileName,
    },
  }
}

class UpdateOnboardingFormFileFormC extends Component {
  render() {
    return (
      <UpdateOnboardingFormFileForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(UpdateOnboardingFormFileFormC)
