import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIdentityBankAccountForm from './AddIdentityBankAccountForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getIdentitySelector } from 'state-layer/selectors'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATHNAME } from 'constants/queryConstants'
import { USA } from 'constants/countryConstants'
import { RECIPIENT_IDENTITY_ROLE } from 'constants/identityConstants'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import hideModalAction from 'utilities/actions/hideModalAction'
import getPageName from 'utilities/get/getPageName'
import isPatching from 'utilities/is/isPatching'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  BANK_ACCOUNT,
  accountTypeOptions,
} from 'constants/bankConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const elavonEnabled = get(props, 'elavonEnabled')
  const identityId = get(props, 'params.identityId') || get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const pathName = get(state, PATHNAME)
  const pageName = getPageName(pathName)
  const isIdentitiesView = pageName === 'Identities View'
  const showSettlementAlias = elavonEnabled && isIdentitiesView
  const identityRole = get(identity, 'identityRoles')
  const identityCountry = get(identity, 'country', USA)
  const hasRecipientRole = includes(identityRole, RECIPIENT_IDENTITY_ROLE)
  const isDisbursementsDashboard = isDisbursementsPartnerDashboard(state)

  const initialValues = {
    identity: identityId,
  }

  return removeUndefined({
    identityId,
    credentials,
    initialValues,
    accountTypeOptions,
    paymentInstrumentType: BANK_ACCOUNT,
    showSettlementAlias,
    hasRecipientRole,
    isPatching: isPatching(state),
    identityCountry,
    isDisbursementsDashboard,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class AddIdentityBankAccountFormC extends Component {
  render() {
    return (
      <AddIdentityBankAccountForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIdentityBankAccountFormC)
