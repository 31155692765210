import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { APPLICATION } from 'constants/dashboardConfigurationsConstants'
import get from 'lodash/get'

import {
  APPLICATION_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

import {
  POST_FEE_PROFILE_F_REQUEST,
  POST_MERCHANT_FEE_PROFILE_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_APPLICATION_FEE_PROFILE,
  CREATE_MERCHANT_FEE_PROFILE,
} from 'constants/amplitudeConstants'

const submitEditFeeProfileForm = (values, dispatch, props) => {
  const {
    credentials,
    formValues,
    entityType,
    entityId,
    merchantProfileId,
    merchantApplicationId,
    merchantProfileTags,
    applicationProfileId,
    applicationProfileTags,
    credentialId,
    merchantIdentityId,
    noExistingCardPresentFeeProfile,
  } = props

  const cnpValues = get(formValues, 'cnp')
  const cpValues = get(formValues, 'cp')
  const achValues = get(formValues, 'ach')

  const cardNotPresentFeeProfile = { ...cnpValues, ...achValues }
  const cardPresentFeeProfile = { ...cpValues, ...achValues }

  const action = entityType === APPLICATION ? POST_FEE_PROFILE_F_REQUEST : POST_MERCHANT_FEE_PROFILE_F_REQUEST
  const amplitudeEventName = entityType === APPLICATION ? CREATE_APPLICATION_FEE_PROFILE : CREATE_MERCHANT_FEE_PROFILE

  const extraValues = entityType === APPLICATION ? {
    application: entityId,
    applicationProfileId,
    applicationProfileTags,
    noExistingCardPresentFeeProfile,
  } : {
    merchantId: entityId,
    merchantProfileId,
    application: merchantApplicationId,
    merchantProfileTags,
    noExistingCardPresentFeeProfile,
  }

  dispatch({
    type: action,
    payload: {
      values: {
        cardNotPresentFeeProfile,
        cardPresentFeeProfile,
        ...extraValues,
      },
      credentials,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(amplitudeEventName, {
        credentials,
      }),
      successCallback: () => {
        const path = entityType === APPLICATION ? `${APPLICATION_PATH({ credentialId, applicationId: entityId })}?tab=fee-profile` : `${MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId: entityId })}?tab=merchant-fee-risk-profile`
        dispatch(redirectRequest({ path }))
      },
      failureRedirect: entityType === APPLICATION ? `${APPLICATION_PATH({ credentialId, applicationId: entityId })}?tab=fee-profile` : `${MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId: entityId })}?tab=merchant-fee-risk-profile`,
    },
  })
}

export default submitEditFeeProfileForm
