import './PlatformInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'

const PlatformInfo = ({
  detailsSectionData = [],
  isFetching = false,
}) => {
  return (
    <div className='PlatformInfo'>
      <PageSection
        title='Details'
        data={detailsSectionData}
        isFetching={isFetching}
      />
    </div>
  )
}

PlatformInfo.propTypes = {
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default PlatformInfo
