import paymentsAPI from 'utilities/api/paymentsAPI'
import { USERS } from 'constants/apiConstants'

const postUsersAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: USERS,
    service: USERS,
  })
}

export default postUsersAPI
