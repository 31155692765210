import './CreateSubscriptionScheduleFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import validateCreateSubscriptionScheduleForm from 'utilities/validate/validateCreateSubscriptionScheduleForm'
import { CREATE_SUBSCRIPTION_SCHEDULE } from 'constants/formConstants'

import {
  FIXED_TIME_INTERVAL,
  PERIODIC_MONTHLY,
  PERIODIC_YEARLY,
  SUBSCRIPTION_TYPE_OPTIONS,
} from 'constants/subscriptionConstants'

const CreateSubscriptionScheduleForm = ({
  subscriptionType,
  handleSubmit = () => {},
  buttonGroup = {},
  dayOptions = [],
  scheduleText = '',
  showScheduleText = false,
}) => {
  const showFixedOffset = subscriptionType === FIXED_TIME_INTERVAL
  const showPeriodicMonthly = subscriptionType === PERIODIC_MONTHLY
  const showPeriodicYearly = subscriptionType === PERIODIC_YEARLY

  return (
    <form className='CreateSubscriptionScheduleForm' onSubmit={handleSubmit}>
      <div className='section'>
        <Field
          name='scheduleNickname'
          label='Schedule Nickname'
          component={InputField}
        />

        <Field
          name='subscriptionType'
          label='Subscription Type'
          component={SelectField}
          options={SUBSCRIPTION_TYPE_OPTIONS}
        />

        { showFixedOffset && (
          <div className='flex'>
            <Field
              name='fixedTimeIntervalOffset.hourlyInterval'
              label='Interval (Hours)'
              component={InputField}
            />

            <Field
              name='fixedTimeIntervalOffset.intervalCount'
              label='Interval Count'
              component={InputField}
            />
          </div>
        )}

        {
          (showPeriodicMonthly || showPeriodicYearly) && (
            <DateFieldset
              name='periodOffset'
              label='Period Offset'
              hideField={showPeriodicMonthly ? { month: true, year: true } : { year: true }}
              dayOptions={dayOptions}
            />
          )
        }

        <TagsField
          name='tags'
          label='Tags'
        />

        { showScheduleText && (<div className='success'>{scheduleText}</div>)}
      </div>

      {buttonGroup}
    </form>
  )
}

CreateSubscriptionScheduleForm.propTypes = {
  subscriptionType: PropTypes.string,
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  dayOptions: PropTypes.array,
  scheduleText: PropTypes.string,
  showScheduleText: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_SUBSCRIPTION_SCHEDULE,
  validate: validateCreateSubscriptionScheduleForm,
})(CreateSubscriptionScheduleForm)
