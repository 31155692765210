import { LOCATION_CHANGE } from 'react-router-redux'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getPageName from 'utilities/get/getPageName'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  SHOW_PREVIEW_PANEL,
  HIDE_PREVIEW_PANEL,
} from 'constants/actionConstants'


const initialState = {
  show: false,
}

let lastPathName = ''

const panelR = (state = initialState, action) => {
  const payload = get(action, 'payload', {})

  const [
    panelProps = {},
  ] = getMany(payload, [
    'panelProps',
  ])

  switch (action.type) {
    case SHOW_PREVIEW_PANEL:
      return {
        show: true,
        panelProps,
      }

    case HIDE_PREVIEW_PANEL:
      return initialState

    case LOCATION_CHANGE: {
      const pathname = get(payload, 'pathname')

      // if path has changed, reset the panel
      if (pathname && pathname !== lastPathName) {
        lastPathName = pathname

        return initialState
      }

      return state
    }

    default:
      return state
  }
}

export default panelR
