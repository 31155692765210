import { GET_SUBSCRIPTION_PLAN_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSubscriptionPlanRequest = ({
  subscriptionPlanId,
  credentials,
}) => createAction({
  type: GET_SUBSCRIPTION_PLAN_F_REQUEST,
  id: subscriptionPlanId,
  credentials,
})

export default getSubscriptionPlanRequest
