import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// react select examples: https://react-select.com/home
// react select prop definitions: https://react-select.com/props

const MultiSelectInput = ({
  input = {},
  options = [],
  className = '',
  classNamePrefix = '',
  placeholder = '',
  styles = {},
  meta: {
    touched,
    error,
    warning,
  },
  helperText = '',
  hideSelectedOptions = true,
  formatOptionLabel = (option) => { return option.label },
  disabled = false,
  maxMenuHeight,
}) => {
  const errorJSX = error && <span className='error'>{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

  return (
    <>
      <Select
        {...input}
        options={options}
        className={classnames('MultiSelectInput', { [className]: !!className, [hasError]: !!hasError })}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        styles={styles}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        isDisabled={disabled}
        isMulti
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={hideSelectedOptions}
        maxMenuHeight={maxMenuHeight}
      />
      { helperText && <div className='lower-label p-2'>{helperText}</div> }
      { touched && (errorJSX || warningJSX) }
    </>
  )
}

MultiSelectInput.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  meta: PropTypes.object,
  helperText: PropTypes.string,
  formatOptionLabel: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  disabled: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
}

export default MultiSelectInput
