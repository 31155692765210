import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import TemplatesForm from './TemplatesForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { TEMPLATES_FORM } from 'constants/formConstants'
import map from 'lodash/map'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(TEMPLATES_FORM)
  const templatesConfig = map(formSelector(state, 'templates'), ({ config }) => config)

  return {
    credentials,
    templatesConfig,
  }
}

class TemplatesFormC extends Component {
  render() {
    return (
      <TemplatesForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(TemplatesFormC)
