import './PaymentInstrumentFieldsetS.scss'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import formatCardNumber from 'utilities/forms/format/formatCardNumber'
import formatUSAPostalCode from 'utilities/forms/format/formatUSAPostalCode'
import formatExpirationDate from 'utilities/forms/format/formatExpirationDate'
import { accountTypeOptionsV2 } from 'constants/bankConstants'

import {
  CAN,
  countryOptionsForFlex,
  USA,
} from 'constants/countryConstants'

import {
  BANK_ICON,
  PAYMENTS_ICON,
} from 'constants/iconConstants'

import {
  COUNTRY,
  CARD,
  BANK_ACCOUNT,
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_TYPE,
  ROUTING_NUMBER,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  ACCOUNT_NUMBER,
  NAME_ON_CARD,
  CARD_NUMBER,
  EXPIRATION_DATE,
  CVV,
  ZIP_CODE,
} from 'constants/language/languageConstants'

// this fieldset mimics the iFrame but is using our usual redux form flow
const PaymentInstrumentFieldset = ({
  name = 'paymentInstrument',
  selectedCountry = USA,
  disableCountry = false,
}) => {
  const [isCard, setIsCard] = useState(true)
  const fieldName = (field) => `${name}.${field}`
  const showRoutingNumber = selectedCountry === USA
  const showTransitNumber = selectedCountry === CAN
  const showInstitutionNumber = selectedCountry === CAN

  return (
    <fieldset className='PaymentInstrumentFieldset'>
      <div className='link-type-buttons flex'>
        <div className={`one-time-link ${isCard ? 'active' : 'inactive'}`} onClick={() => setIsCard(true)}>
          <span className='flex column items-center center'>
            <i className={`fa fa-${PAYMENTS_ICON}`} />
            <RadioButton options={[{ value: 'card', label: CARD }]} input={{ value: isCard ? 'card' : 'bank', name: 'radio-button' }} hideInput />
          </span>
        </div>
        <div className={`multi-use-link ${isCard ? 'inactive' : 'active'}`} onClick={() => setIsCard(false)}>
          <span className='flex column items-center center'>
            <i className={`fa fa-${BANK_ICON}`} />
            <RadioButton options={[{ value: 'bank', label: BANK_ACCOUNT }]} input={{ value: isCard ? 'card' : 'bank', name: 'radio-button' }} hideInput />
          </span>
        </div>
      </div>

      {isCard && (
      <>
        <Field
          name={fieldName('nameOnCard')}
          label={NAME_ON_CARD}
          component={InputField}
        />
        <Field
          name={fieldName('cardNumber')}
          label={CARD_NUMBER}
          component={InputField}
          format={formatCardNumber}
          placeholder='1234 1234 1234 1234'
        />
        <div className='flex space-between'>
          <Field
            name={fieldName('expiration')}
            label={EXPIRATION_DATE}
            component={InputField}
            placeholder='MM / YYYY'
            format={formatExpirationDate}
          />
          <Field
            name={fieldName('cvv')}
            label={CVV}
            component={InputField}
            placeholder='123'
            format={formatNumberField}
          />
        </div>
        <div className='flex space-between'>
          <Field
            name={fieldName('postalCode')}
            label={ZIP_CODE}
            component={InputField}
            placeholder='12345'
            format={formatUSAPostalCode}
          />
          <Field
            name={fieldName('country')}
            label={COUNTRY}
            component={SelectField}
            options={countryOptionsForFlex}
            disabled={disableCountry}
          />
        </div>
      </>
      )}
      {!isCard && (
      <>
        <Field
          name={fieldName('name')}
          label={ACCOUNT_HOLDER_NAME}
          component={InputField}
        />
        <Field
          name={fieldName('accountNumber')}
          label={ACCOUNT_NUMBER}
          component={InputField}
          format={formatNumberField}
          placeholder='0000000000000000'
        />
        {showRoutingNumber && (
        <Field
          name={fieldName('routingNumber')}
          label={ROUTING_NUMBER}
          component={InputField}
          format={formatNumberField}
          placeholder='123456789'
        />
        )}
        {showTransitNumber && (
        <Field
          name='transitNumber'
          label={TRANSIT_NUMBER}
          component={InputField}
          format={formatNumberField}
          placeholder='12345'
        />
        )}
        {showInstitutionNumber && (
        <Field
          name='institutionNumber'
          label={INSTITUTION_NUMBER}
          component={InputField}
          format={formatNumberField}
          placeholder='123'
        />
        )}
        <Field
          name={fieldName('accountType')}
          label={ACCOUNT_TYPE}
          component={ReactSelect}
          options={accountTypeOptionsV2}
        />
        <div className='flex space-between'>
          <Field
            name={fieldName('postalCode')}
            label={ZIP_CODE}
            component={InputField}
            placeholder='12345'
            format={formatUSAPostalCode}
          />
          <Field
            name={fieldName('country')}
            label={COUNTRY}
            component={SelectField}
            options={countryOptionsForFlex}
            disabled={disableCountry}
          />
        </div>
      </>
      )}
    </fieldset>
  )
}

PaymentInstrumentFieldset.propTypes = {
  name: PropTypes.string,
  selectedCountry: PropTypes.string,
  disableCountry: PropTypes.bool,
}

export default PaymentInstrumentFieldset
