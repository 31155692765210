import React from 'react'
import column from 'utilities/table/column'

import {
  isRolePlatform,
  notRoleMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  EVENT_TYPE,
  EVENT_DETAILS,
  EVENT_DATE,
  APPLICATION,
  MERCHANT,
  RESOURCE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(EVENT_DATE, 'displayCreatedAt'),
  column(EVENT_TYPE, 'displayEventType'),
  column(EVENT_DETAILS, 'message', {
    className: 'large',
    formatter: ({ message, webhookDisplay }) => {
      return webhookDisplay ? webhookDisplay : message
    },
  }),
  column(APPLICATION, 'applicationName', { condition: isRolePlatform }),
  column(MERCHANT, 'merchantName', { condition: notRoleMerchant }),
  column(RESOURCE, 'displayResourceType'),
]

export default columnDescriptors
