import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  SUBMIT_TO_UNDERWRITING_CONFIRMATION_MSG,
  CONFIRM,
  SUBMIT,
} from 'constants/language/languageConstants'

const SubmitToUnderwriting = ({
  closeModal = () => {},
  submitToUnderwriting = () => {},
}) => {
  return (
    <div className='SubmitToUnderwriting'>
      <div className='modal-content confirmation-msg'>
        {SUBMIT_TO_UNDERWRITING_CONFIRMATION_MSG}
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label='Cancel' variant='ghost' className='cancel-button' />
        <Button type={SUBMIT} label={CONFIRM} onClick={submitToUnderwriting} />
      </div>
    </div>
  )
}

SubmitToUnderwriting.propTypes = {
  closeModal: PropTypes.func,
  submitToUnderwriting: PropTypes.func,
}

export default SubmitToUnderwriting
