import './ReviewQueueSettlementsPreviewPanelS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import PanelContextBar from 'components/Shared/PanelContextBar/PanelContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import SettlementFundingTransfersC from 'components/Customer/Pages/Settlement/SettlementFundingTransfers/SettlementFundingTransfersC'
import SettlementCalculatedFundingDetailsC from 'components/Customer/Pages/Settlement/SettlementCalculatedFundingDetails/SettlementCalculatedFundingDetailsC'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import map from 'lodash/map'
import { USD } from 'constants/currencyConstants'

import {
  t,
  TAGS,
  TYPE,
  COUNT,
  AMOUNT,
  DETAILS,
  ENTRIES,
  PAYOUT_PROFILE,
  MERCHANT_PAYOUT_PROFILE,
  FUNDING_TRANSFERS,
  FUNDING_INSTRUCTIONS,
} from 'constants/language/languageConstants'

const ReviewQueueSettlementsPreviewPanel = ({
  isFetchingDetails = false,
  isFetchingPayoutProfile = false,
  panelContextBarData = [],
  headerData = {},
  reviewQueueDetailsData = [],
  detailsSectionData = [],
  merchantPayoutProfileSectionData = [],
  overviewTableRows = [],
  currency = USD,
  isApprovedSettlement = false,
  settlementId = '',
  settlementPaymentInstruments = [],
  tagsSectionData = [],
  entries = '',
}) => {
  return (
    <div className='ReviewQueueSettlementsPreviewPanel'>
      <PanelContextBar data={panelContextBarData} />

      <HeaderV2C {...headerData} />

      <PageSectionV2C columns={reviewQueueDetailsData} />

      <PageSectionV2C
        smallTitle={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetchingDetails}
      />

      <PageSectionV2C
        smallTitle={isStandaloneMerchantDashboard() ? PAYOUT_PROFILE : MERCHANT_PAYOUT_PROFILE}
        columns={merchantPayoutProfileSectionData}
        isFetching={isFetchingPayoutProfile}
      />

      <PageSectionV2C smallTitle='Overview'>
        <div className='table overview-table'>
          <div className='header tal'>{t(TYPE)}</div>
          <div className='header'>{t(COUNT)}</div>
          <div className='header'>{t(AMOUNT)} ({currency})</div>

          { map(overviewTableRows, (row, rowIndex) => (
            <Fragment key={`overview-row-${rowIndex}`}>
              { map(row, (column, columnIndex) => {
                return (
                  <div
                    key={`overview-row-${rowIndex}-column-${columnIndex}`}
                    className={columnIndex === 0 ? 'tal' : ''}
                  >
                    {t(column)}
                  </div>
                )
              })}
            </Fragment>
          ))}
        </div>
      </PageSectionV2C>

      { isApprovedSettlement ? (
        <PageSectionV2C
          className='funding-transfers-section'
          smallTitle={FUNDING_TRANSFERS}
        >
          <SettlementFundingTransfersC
            settlementId={settlementId}
            settlementPaymentInstruments={settlementPaymentInstruments}
            showExportButton={false}
          />
        </PageSectionV2C>
      ) : (
        <SettlementCalculatedFundingDetailsC
          settlementId={settlementId}
          smallTitle={FUNDING_INSTRUCTIONS}
          showExportButton={false}
        />
      )}

      <TagsPageSectionV2
        title=''
        smallTitle={TAGS}
        data={tagsSectionData}
      />

      <PageSectionV2C
        smallTitle='Additional Information'
        linkToView={entries}
        textForLink={ENTRIES}
      />
    </div>
  )
}

ReviewQueueSettlementsPreviewPanel.propTypes = {
  isFetchingDetails: PropTypes.bool,
  isFetchingPayoutProfile: PropTypes.bool,
  panelContextBarData: PropTypes.array,
  headerData: PropTypes.object,
  reviewQueueDetailsData: PropTypes.array,
  detailsSectionData: PropTypes.array,
  merchantPayoutProfileSectionData: PropTypes.array,
  overviewTableRows: PropTypes.array,
  currency: PropTypes.string,
  isApprovedSettlement: PropTypes.bool,
  settlementId: PropTypes.string,
  settlementPaymentInstruments: PropTypes.array,
  tagsSectionData: PropTypes.array,
  entries: PropTypes.string,
}

export default ReviewQueueSettlementsPreviewPanel
