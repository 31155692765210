import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import { REVIEW_QUEUE_DISPLAY_VALUES } from 'constants/reviewQueueConstants'

const frontendReviewQueueItemModel = ({ data: reviewQueueItem }) => {
  const [
    id,
    merchant, // TODO: missing from OL
    doingBusinessAs, // TODO: missing from OL
    status, // TODO: missing from OL
    transfersCount, // TODO: missing from OL
    createdAt,
    processor,
    totalFees,
    totalAmount,
    netAmount,
    currency,
    tags,
    applicationName,
    outcome,
    outcomeReason,
    entityId,
    entityType,
    entityEmail, // TODO: comes from entity.email
    applicationId,
    errors,
    identity,
    settlement,
    verifications,
    paymentInstruments,
    associatedIdentities,
    updatedAt,
    application,
    merchantId,
    underwritingDecision,
    riskScore,
  ] = getMany(reviewQueueItem, [
    'id',
    'merchant', // TODO: missing from OL
    'doingBusinessAs', // TODO: missing from OL
    'status', // TODO: missing from OL
    'transfersCount', // TODO: missing from OL
    'created_at',
    'processor_type',
    'total_fees',
    'total_amount',
    'net_amount',
    'currency',
    'tags',
    'applicationName',
    'outcome',
    'outcome_reason',
    'entity_id',
    'entity_type',
    'entity.email',
    'application',
    'errors',
    'identity',
    'settlement',
    'verifications',
    'paymentInstruments',
    'associatedIdentities',
    'updated_at',
    'applicationObject',
    'merchantId',
    'underwritingDecision',
    'riskScore',
  ])

  return {
    id,
    application,
    merchant,
    doingBusinessAs,
    status,
    transfersCount,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    processor,
    totalFees,
    totalAmount,
    netAmount,
    currency,
    tags,
    applicationName,
    outcome,
    displayOutcome: REVIEW_QUEUE_DISPLAY_VALUES[outcome],
    outcomeReason,
    entityId,
    entityType,
    entityEmail,
    applicationId,
    errors,
    identity,
    settlement,
    verifications,
    paymentInstruments,
    associatedIdentities,
    updatedAt,
    merchantId,
    underwritingDecision,
    riskScore,
  }
}

export default frontendReviewQueueItemModel
