import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasSubmitFailed, isInvalid } from 'redux-form'
import LiveAccountApplicationPricingForm from 'components/Customer/Forms/LiveAccountApplicationPricingInfoForm/LiveAccountApplicationPricingForm'
import { getAccessFormSelector } from 'state-layer/selectors'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import { LIVE_ACCOUNT_APPLICATION_PRICING_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  LIVE_ACCOUNT_APPLICATION_PRICING_LINK,
  LIVE_ACCOUNT_APPLICATION_PRICING_LINK_PLATFORMS,
} from 'constants/urlConstants'

import {
  FLAT_RATE,
  DYNAMIC,
  FLAT_RATE_DESCRIPTION_PT_1,
  FLAT_RATE_DESCRIPTION_PT_2,
  DYNAMIC_PRICING_DESCRIPTION_PT_1,
  DYNAMIC_PRICING_DESCRIPTION_PT_2,
  VIEW_DETAILS,
} from 'constants/language/languageConstants'

const PRICING_PLAN_OPTIONS = [
  { label: FLAT_RATE,
    value: 'BLENDED',
    helperText: (
      <div>
        <div className='description-part-one'>{FLAT_RATE_DESCRIPTION_PT_1}<a onClick={sendAmplitudeActionEvent(LIVE_ACCOUNT_APPLICATION_PRICING_LINK)} className='view-details-link text-link' href={LIVE_ACCOUNT_APPLICATION_PRICING_LINK_PLATFORMS} target='blank'>{VIEW_DETAILS} <i className={`fa fa-${EXTERNAL_LINK}`} /></a> </div>
        <div>{FLAT_RATE_DESCRIPTION_PT_2}</div>
      </div>
    ),
  },
  { label: DYNAMIC,
    value: 'INTERCHANGE_PLUS',
    helperText: (
      <div>
        <div className='description-part-one'>{DYNAMIC_PRICING_DESCRIPTION_PT_1}<a onClick={sendAmplitudeActionEvent(LIVE_ACCOUNT_APPLICATION_PRICING_LINK)} className='view-details-link text-link' href={LIVE_ACCOUNT_APPLICATION_PRICING_LINK_PLATFORMS} target='blank'>{VIEW_DETAILS} <i className={`fa fa-${EXTERNAL_LINK}`} /> </a></div>
        <div>{DYNAMIC_PRICING_DESCRIPTION_PT_2}</div>
      </div>
    ),
  },
]

const mapStateToProps = (state, props) => {
  const accessFormId = get(props, 'accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const pricingType = get(accessForm, 'pricingType')
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)

  const isFormInvalid = isInvalid(LIVE_ACCOUNT_APPLICATION_PRICING_FORM)(state) && hasSubmitFailed(LIVE_ACCOUNT_APPLICATION_PRICING_FORM)(state)

  return {
    accessFormId,
    initialValues: { pricingType },
    isFormInvalid,
    isStandaloneMerchant: isStandaloneMerchantDashboard(state),
    isFetchingCurrentUser,
  }
}

class LiveAccountApplicationPricingFormC extends Component {
  render() {
    return (
      <LiveAccountApplicationPricingForm
        {...this.props}
        pricingTypeOptions={PRICING_PLAN_OPTIONS}
      />
    )
  }
}

export default connect(mapStateToProps)(LiveAccountApplicationPricingFormC)
