export const createdAtRange = {
  asc: 'created_at,asc',
  desc: 'created_at,desc',
}

export const updatedAtRange = {
  asc: 'updated_at,asc',
  desc: 'updated_at,desc',
}

export const amountRange = {
  asc: 'amount,asc',
  desc: 'amount,desc',
}

export const dueRange = {
  asc: 'due_at,asc',
  desc: 'due_at,desc',
}

export const lastActivityRange = {
  asc: 'last_activity,asc',
  desc: 'last_activity,desc',
}

export const submittedAtRange = {
  asc: 'submitted_at, asc',
  desc: 'submitted_at, desc',
}

export const respondByRange = {
  asc: 'respond_by,asc',
  desc: 'respond_by,desc',
}

export const linkExpiresAtRange = {
  asc: 'link_expires_at,asc',
  desc: 'link_expires_at,desc',
}
