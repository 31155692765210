import co from 'co'
import postOnboardingFormFileO from 'state-layer/orchestration/post/postOnboardingFormFileO'
import patchOnboardingFormDataAPI from 'api/guestDashboard/patch/patchOnboardingFormDataAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import createErrorResponse from 'utilities/api/createErrorResponse'
import backendOnboardingFormDataModel from 'utilities/create/models/backendOnboardingFormDataModel'
import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'

function * patchOnboardingFormDocumentsO ({
  id,
  values,
}) {
  const {
    fileType,
    files,
    applicationId,
    associatedFiles = [],
  } = values

  const fileResponses = yield map(files, (file) => {
    return postOnboardingFormFileO({
      id,
      file,
      fileType: 'OTHER', // TODO: This is a temp hack to get the file to upload. All the new file type enums are not currently supported.
      fileName: get(file, 'name'),
      applicationId,
    })
  })

  // TODO: How should we handle 1 or more failed uploads?
  const successfullyUploadedFiles = filter(fileResponses, ({ error }) => !error)

  const valuesToSubmit = {
    id,
    associatedFiles: [
      ...associatedFiles,
      ...map(successfullyUploadedFiles, ({ fileId, fileName }) => ({
        id: fileId,
        display_name: fileName,
        type: fileType,
      })),
    ],
  }

  const backendModelValuesToSubmit = backendOnboardingFormDataModel({ values: valuesToSubmit })

  const {
    data: onboardingFormResponse,
    error: onboardingFormPatchError,
  } = yield patchOnboardingFormDataAPI({ values: backendModelValuesToSubmit })

  const frontendModelValues = frontendOnboardingFormDataModel({ data: onboardingFormResponse })

  if (onboardingFormPatchError) {
    return createErrorResponse({ error: onboardingFormPatchError })
  }

  return createSuccessResponse({
    data: frontendModelValues,
  })
}

export default co.wrap(patchOnboardingFormDocumentsO)
