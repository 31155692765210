import './BreadcrumbsS.scss'
import React, { Fragment } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { RESOURCE_ICON_MAP } from 'constants/iconConstants'
import toUpper from 'lodash/toUpper'
import map from 'lodash/map'
import get from 'lodash/get'
import upperCase from 'lodash/upperCase'

const Breadcrumbs = ({
  resourceLinks = [],
  oneLevel = false,
}) => {
  const numResourceLinks = get(resourceLinks, 'length', 0)

  return (
    <div className='Breadcrumbs flex space-between'>
      <div className='breadcrumbs-path flex items-center'>
        {
          map(resourceLinks, ({ id, name, path, linkClass }, index) => {
            const isLastRecord = index === numResourceLinks - 1
            const breadcrumbIcon = RESOURCE_ICON_MAP[upperCase(name)]

            const linkClassnames = classnames(linkClass, {
              last: isLastRecord,
            })

            return (
              <Fragment key={id}>
                { !oneLevel && index !== 0 && (<p className='path-divider'>/</p>) }
                { (isLastRecord && (numResourceLinks !== 1)) ? (
                  !oneLevel && (
                    <>
                      {breadcrumbIcon && <i className={`breadcrumbs-icon fal fa-${breadcrumbIcon}`} />}
                      <p className={linkClassnames} aria-label={name}>{name}</p>
                    </>
                  )
                ) : (
                  (index === 0 || !oneLevel) && (
                  <p className={linkClassnames} aria-label={`${name}`}>
                    {breadcrumbIcon && <i className={`breadcrumbs-icon fal fa-${breadcrumbIcon}`} />}
                    <Link className='link' to={path}>{ index % 2 === 0 ? toUpper(name) : name }</Link>
                  </p>
                  )
                )}
              </Fragment>
            )
          })
        }
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  resourceLinks: PropTypes.array,
  oneLevel: PropTypes.bool,
}

export default Breadcrumbs
