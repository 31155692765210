import React, { Component } from 'react'
import { connect } from 'react-redux'
import ToggleSwitch from './ToggleSwitch'
import toggleCheckedStatus from 'utilities/toggleCheckedStatus'
import getCheckedStatus from 'utilities/get/getCheckedStatus'
import { preventDefaultEvent } from 'constants/focusControlConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const {
    name,
    input,
    form,
    checked,
    meta,
  } = props

  const inputName = name ? name : get(input, 'name')
  const formName = form ? form : get(meta, 'form')

  return {
    name: inputName,
    checked: checked ? checked : getCheckedStatus(inputName, formName),
    form: formName,
  }
}

class ToggleSwitchC extends Component {
  handleChange = (event) => {
    const {
      name = '',
      dispatch,
      form,
      customHandleChange,
    } = this.props

    if (customHandleChange) customHandleChange()
    preventDefaultEvent(event)
    toggleCheckedStatus({ dispatch, event, name, form })
  }

  render() {
    const { handleChange } = this

    return (
      <ToggleSwitch
        {...this.props}
        handleChange={handleChange}
      />
    )
  }
}

export default connect(mapStateToProps)(ToggleSwitchC)
