import './CreateReceiptFormS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import ReactSelectCreatable from 'components/Shared/Inputs/ReactSelectCreatable/ReactSelectCreatable'
import ReceiptItems from 'components/Customer/Forms/CreateReceiptForm/ReceiptItems'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import Loader from 'components/Shared/Loader/Loader'
import submitCreateReceiptForm from 'utilities/submit/submitCreateReceiptForm'
import validateCreateReceiptForm from 'utilities/validate/validateCreateReceiptForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import { CREATE_RECEIPT_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'

import {
  t,
  CANCEL,
  CREATE_RECEIPT,
  CREATE_RECEIPT_SUBTITLE,
  CREATE,
  ADDITIONAL_RECEIPT_CONTENT_OPTIONS,
  DISPLAY_ITEMIZED_PRODUCT_DETAILS_TITLE,
  DISPLAY_TOTAL_AMOUNT_BREAKDOWN_TITLE,
  DISPLAY_PRODUCT_DETAILS,
  EMAIL_MULTI_INPUT_HELPER_TEXT,
  SEND_RECEIPT_AUTOMATICALLY,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
  DISPLAY_ADDITIONAL_DETAILS_TITLE,
  RECIPIENT_PHONE_NUMBER_PLACEHOLDER,
  PHONE_MULTI_INPUT_HELPER_TEXT,
  PRINT as PRINT_LABEL,
  TEXT as TEXT_LABEL,
  EMAIL as EMAIL_LABEL,
  RECEIPT_DELIVERY,
  TOTAL_AMOUNT,
  BILLING_ADDRESS,
  PAYMENT_METHOD,
  MERCHANT_NAME,
  CONTACT_INFORMATION,
  RECEIPT_DETAILS_INFO_TEXT,
} from 'constants/language/languageConstants'

const renderAmountField = (showField, name, placeholder = '0.00', required = false) => (
  showField ? (
    <AmountField
      name={name}
      placeholder={placeholder}
      required={required}
      className='amount-breakdown-field'
    />
  ) : null
)

const CreateReceiptForm = ({
  handleSubmit = () => {},
  invalid = false,
  isCardPresent = false,
  deviceOptions = [],
  isFetching = false,
  formError = '',
  displayAmount = '',
}) => {
  const [showFields, setShowFields] = useState({
    subtotal: false,
    discount: false,
    shipping: false,
    tax: false,
    tip: false,
    surcharge: false,
    convenienceFee: false,
    rentSurcharge: false,
    productDetails: false,
    sendReceiptToBuyer: false,
    sendMethodEmail: false,
    sendMethodSMS: false,
    sendMethodPrint: false,
  })

  const handleCheckboxChange = (field) => {
    setShowFields((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  const {
    subtotal,
    discount,
    shipping,
    tax,
    tip,
    surcharge,
    convenienceFee,
    rentSurcharge,
    productDetails,
    sendReceiptToBuyer,
    sendMethodEmail,
    sendMethodSMS,
    sendMethodPrint,
  } = showFields

  const shouldDisableButton = invalid || isFetching || (!sendMethodEmail && !sendMethodSMS && !sendMethodPrint && sendReceiptToBuyer)

  return (
    <form className='CreateReceiptForm' onSubmit={handleSubmit(submitCreateReceiptForm)}>
      {isFetching && <Loader />}
      {!isFetching && (
        <>
          <div className='form-header'>
            <h3 className='title'>{t(CREATE_RECEIPT)}</h3>
            <div className='subtitle p-1'>{t(CREATE_RECEIPT_SUBTITLE)}</div>
          </div>
          <div className='form-content'>
            <h5>Receipt Contents</h5>
            <div className='receipt-info-details subtitle'>
              <div className='text'>{RECEIPT_DETAILS_INFO_TEXT}</div>
              <ul>
                <li>{TOTAL_AMOUNT}</li>
                <li>{BILLING_ADDRESS}</li>
                <li>{PAYMENT_METHOD}</li>
                <li>{MERCHANT_NAME}</li>
                <li>{CONTACT_INFORMATION}</li>
              </ul>
            </div>

            <h5 className='section-title-with-border'>{t(ADDITIONAL_RECEIPT_CONTENT_OPTIONS)}</h5>
            {formError && <ErrorMessage errorMessage={formError} />}
            <h6>{t(DISPLAY_TOTAL_AMOUNT_BREAKDOWN_TITLE)}</h6>
            <div className='subtitle additional-details-title'>{t(DISPLAY_ADDITIONAL_DETAILS_TITLE)}{displayAmount}</div>
            <div className='item-group'>
              <Field
                name='subtotal'
                label='Subtotal'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('subtotal')}
                checked={subtotal}
              />
              {renderAmountField(subtotal, 'subtotalAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='discount'
                label='Discount'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('discount')}
                checked={discount}
              />
              {renderAmountField(discount, 'discountAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='shipping'
                label='Shipping'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('shipping')}
                checked={shipping}
              />
              {renderAmountField(shipping, 'shippingAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='tax'
                label='Tax'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('tax')}
                checked={tax}
              />
              {renderAmountField(tax, 'taxAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='tip'
                label='Tip'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('tip')}
                checked={tip}
              />
              {renderAmountField(tip, 'tipAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='surcharge'
                label='Surcharge'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('surcharge')}
                checked={surcharge}
              />
              {renderAmountField(surcharge, 'surchargeAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='convenienceFee'
                label='Convenience Fee'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('convenienceFee')}
                checked={convenienceFee}
              />
              {renderAmountField(convenienceFee, 'convenienceFeeAmount')}
            </div>
            <div className='item-group'>
              <Field
                name='rentSurcharge'
                label='Rent Surcharge'
                component={CheckboxC}
                onChange={() => handleCheckboxChange('rentSurcharge')}
                checked={rentSurcharge}
              />
              {renderAmountField(rentSurcharge, 'rentSurchargeAmount')}
            </div>

            <div className='section'>
              <h6 className='section-heading'>{t(DISPLAY_ITEMIZED_PRODUCT_DETAILS_TITLE)}</h6>
              <Field
                name='productDetails'
                label={t(DISPLAY_PRODUCT_DETAILS)}
                component={CheckboxC}
                onChange={() => handleCheckboxChange('productDetails')}
                checked={productDetails}
              />
              { productDetails && (
              <div className='items'>
                <FieldArray
                  name='items'
                  component={ReceiptItems}
                  productDetails
                />
              </div>
              )}
            </div>

            <h5 className='section-title-with-border'>{t(RECEIPT_DELIVERY)}</h5>
            <div>
              <Field
                name='sendReceiptToBuyer'
                label={t(SEND_RECEIPT_AUTOMATICALLY)}
                component={CheckboxC}
                onChange={() => handleCheckboxChange('sendReceiptToBuyer')}
              />
              {sendReceiptToBuyer && (
              <div className='send-methods'>
                <div className='send-method-option'>
                  <Field
                    name='sendMethodEmail'
                    component={CheckboxC}
                    label={EMAIL_LABEL}
                    onChange={() => handleCheckboxChange('sendMethodEmail')}
                    checked={sendMethodEmail}
                  />
                  {sendMethodEmail && (
                  <Field
                    name='buyerEmails'
                    component={ReactSelectCreatable}
                    helperText={EMAIL_MULTI_INPUT_HELPER_TEXT}
                    disableDropdown
                    placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
                    className='send-method-input'
                  />
                  )}
                </div>

                <div className='send-method-option'>
                  <Field
                    name='sendMethodSMS'
                    component={CheckboxC}
                    label={TEXT_LABEL}
                    onChange={() => handleCheckboxChange('sendMethodSMS')}
                    checked={sendMethodSMS}
                  />
                  {sendMethodSMS && (
                  <Field
                    name='phoneNumbers'
                    component={ReactSelectCreatable}
                    helperText={PHONE_MULTI_INPUT_HELPER_TEXT}
                    disableDropdown
                    placeholder={RECIPIENT_PHONE_NUMBER_PLACEHOLDER}
                    className='send-method-input'
                    formatValue={formatPhone}
                  />
                  )}
                </div>

                {isCardPresent && (
                <div className='send-method-option'>
                  <Field
                    name='sendMethodPrint'
                    component={CheckboxC}
                    label={PRINT_LABEL}
                    onChange={() => handleCheckboxChange('sendMethodPrint')}
                    checked={sendMethodPrint}
                  />
                  {sendMethodPrint && (
                  <Field
                    name='device'
                    label='Device'
                    component={ReactSelect}
                    options={deviceOptions}
                    required={false}
                    customStyles={{
                      option: {
                        textAlign: 'left',
                      },
                      menu: {
                        position: 'relative',
                      },
                    }}
                  />
                  )}
                </div>
                )}
              </div>
              )}
            </div>
          </div>

          <div className='form-footer'>
            <div className='footer-button-container'>
              <div className='exit-and-save-container'>
                <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
                <Button type='submit' disabled={shouldDisableButton} submitting={isFetching} label={CREATE} className='submit-button' />
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

CreateReceiptForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  isCardPresent: PropTypes.bool,
  deviceOptions: PropTypes.array,
  isFetching: PropTypes.bool,
  formError: PropTypes.string,
  displayAmount: PropTypes.string,
}

export default reduxForm({
  form: CREATE_RECEIPT_FORM,
  validate: validateCreateReceiptForm,
})(CreateReceiptForm)
