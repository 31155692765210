import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import { getState } from 'state-layer/configureStore'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import map from 'lodash/map'
import get from 'lodash/get'
import head from 'lodash/head'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import co from 'co'

function * getTransfersRelatedFeesO ({
  api,
  items,
  idKey = 'id',
  credentials,
  frontendModel = ({ data }) => data,
}) {
  // only perform orchestration when debit filter is applied
  const state = getState()
  const typeDebitFilter = get(state, 'tableFiltersR.type.eq') === 'debit'
  if (!typeDebitFilter) return {}

  const relatedItemsMap = reduce(items, (total, item, parentId) => {
    const relatedItem = get(item, idKey)

    return !isEmpty(relatedItem) ? merge({}, total, {
      [parentId]: relatedItem,
    }) : total
  }, {})

  if (isEmpty(relatedItemsMap)) return createSuccessResponse({})

  const relatedIds = []

  const subResourcesResponses = yield map(relatedItemsMap, (subResourceId) => {
    if (includes(relatedIds, subResourceId)) return {}

    relatedIds.push(subResourceId)

    return api({ id: subResourceId, credentials })
  })

  const normalizedResponses = normalizeResponse({ content: subResourcesResponses })
  const filteredResponses = filter(normalizedResponses, ({ data }) => !isEmpty(data))

  const subResources = reduce(filteredResponses, (total, responses) => {
    const fees = get(responses, 'data')
    const feeModels = map(fees, (fee) => frontendModel({ data: fee }))
    const keyedFees = keyBy(feeModels, 'id')
    const feeIds = Object.keys(keyedFees)
    const parentId = get(head(fees), 'parentId')

    return merge({}, total, keyedFees, {
      [parentId]: { feeIds },
    })
  }, {})

  return createSuccessResponse({
    data: subResources,
  })
}

export default co.wrap(getTransfersRelatedFeesO)
