import './LiveAccountApplicationApprovedDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  BUSINESS_APPROVED_FOR_A_LIVE_ACCOUNT,
  LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_1,
  LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_2,
  LIVE_ACCOUNT_APPLICATION_APPROVED_NEXT_STEPS_MSG,
  NEXT_STEPS,
} from 'constants/language/languageConstants'

const LiveAccountApplicationApprovedDetails = ({
  title = BUSINESS_APPROVED_FOR_A_LIVE_ACCOUNT,
  rightTitle = '',
}) => {
  return (
    <PageSectionV2C
      title={<div className='approved-details-title'>{title}</div>}
      rightTitle={rightTitle}
      className='LiveAccountApplicationApprovedDetails approved-highlight'
    >
      <div className='approved-details-highlight'>
        <div className='info-pt-1 p-1'>{LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_1}</div>
        <div className='info-pt-2 p-1'>{LIVE_ACCOUNT_APPLICATION_APPROVED_INFO_PT_2}</div>

        <div className='flex items-center next-steps-container'>
          <div className='next-steps p-2'>{NEXT_STEPS}:</div>
          <div className='next-steps-msg p-2'>{LIVE_ACCOUNT_APPLICATION_APPROVED_NEXT_STEPS_MSG}</div>
        </div>
      </div>
    </PageSectionV2C>
  )
}

LiveAccountApplicationApprovedDetails.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
}

export default LiveAccountApplicationApprovedDetails
