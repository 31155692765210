import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendIdentitiesModel = ({ data: identities }) => {
  const identitiesModels = map(identities, (identity) => frontendIdentityModel({ data: identity }))

  return keyBy(identitiesModels, 'id')
}

export default frontendIdentitiesModel
