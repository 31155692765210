import { fieldEmpty } from 'utilities/validate'

const validateDisputeTransferForm = (values) => {
  const {
    amount,
    caseId,
    reasonCode,
    respondBy,
    state,
  } = values

  return {
    amount: fieldEmpty(amount, 'Amount'),
    caseId: fieldEmpty(caseId, 'Case ID'),
    reasonCode: fieldEmpty(reasonCode, 'Reason Code'),
    respondBy: !respondBy ? 'Respond By must not be empty.' : false,
    state: fieldEmpty(state, 'State'),
  }
}

export default validateDisputeTransferForm
