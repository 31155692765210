import React from 'react'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import formatDate from 'utilities/formatters/formatDate'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import formatMoney from 'utilities/formatters/formatMoney'

const columnDescriptors = [
  column('Merchant', 'merchantIdentity.displayBusinessName', { className: 'large' }),
  column('Buyer', 'paymentInstrument.name', { className: 'small' }),
  column('State', 'state', {
    className: 'state',
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column('Created', 'createdAt', {
    className: 'date',
    sort: createdAtRange,
  }),
  column('Respond By', 'respondBy', {
    className: 'date',
    formatter: ({ respondBy }) => formatDate({ date: respondBy }),
  }),
  column('Reason', 'displayReason', { className: 'small' }),
  column('Amount', ['amount', 'currency'], { className: 'amount', formatter: formatMoney }),
]

export default columnDescriptors
