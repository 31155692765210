import React, { Component } from 'react'
import { connect } from 'react-redux'
import TeamMemberForm from 'components/Customer/Forms/TeamMemberForm/TeamMemberForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { GET_MEMBER_F_REQUEST } from 'constants/flowConstants'
import { getMemberSelector } from 'state-layer/selectors/index'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const mapDispatchToProps = (dispatch) => {
  return {
    getMember: ({ credentials, memberId }) => dispatch({
      type: GET_MEMBER_F_REQUEST,
      payload: {
        id: memberId,
        credentials,
      },
    }),
  }
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser()
  const memberId = get(props, 'memberId')
  const member = getMemberSelector(state, memberId)
  const currentBusinessName = get(props, 'currentBusinessName', undefined)

  const {
    email,
    roleId,
  } = member

  const memberInitialValues = removeUndefined({
    email,
    rolesChoice: roleId,
    dashboardAccountName: currentBusinessName,
  })

  return removeUndefined({
    initialValues: memberInitialValues,
    credentials,
    currentUser,
  })
}

class TeamMemberFormC extends Component {
  componentDidMount() {
    const {
      memberId,
      getMember,
      credentials,
    } = this.props

    if (memberId) {
      getMember({ credentials, memberId })
    }
  }

  render() {
    return (
      <TeamMemberForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberFormC)
