import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PayoutCards from './PayoutCards'
import SplitPayoutCards from './SplitPayoutCards'
import TransactionCards from './TransactionCards'
import InternationalTransactionCards from './InternationalTransactionCards'
import ACHCards from './ACHCards'
import DailyPayoutsTable from './DailyPayoutsTable'
import WeeklyPayoutsTable from './WeeklyPayoutsTable'
import RefundCards from './RefundCards'
import FailedAuthorizationCards from './FailedAuthorizationCards'
import FirstSettlementCards from './FirstSettlementCards'
import SmallSettlementCards from './SmallSettlementCards'
import DuplicateSettlementCards from './DuplicateSettlementCards'
import DormantSettlementTable from './DormantSettlementTable'
import SettlementSizeDifferenceTable from './SettlementSizeDifferenceTable'
import HighTicketTable from './HighTicketTable'
import DisputeCards from './DisputeCards'

import {
  MONITORING_ALERT_INSIGHTS_TITLE_MAPPING,
  MONITORING_ALERT_TYPE,
} from 'constants/monitoringAlertConstants'

const insightsComponentMap = {
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME]: PayoutCards,
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD]: PayoutCards,
  [MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK]: PayoutCards,
  [MONITORING_ALERT_TYPE.PAYOUTS_GREATER_THAN_10K]: PayoutCards,
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME]: SplitPayoutCards,
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD]: SplitPayoutCards,
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK]: SplitPayoutCards,
  [MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_GREATER_THAN_10K]: SplitPayoutCards,
  [MONITORING_ALERT_TYPE.INTERNATIONAL_CARD_RATIO]: InternationalTransactionCards,
  [MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_DAILY]: DailyPayoutsTable,
  [MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_WEEKLY]: WeeklyPayoutsTable,
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_NAME]: TransactionCards,
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_CARD]: TransactionCards,
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE]: ACHCards,
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE_ADMINISTRATIVE]: ACHCards,
  [MONITORING_ALERT_TYPE.ACH_RETURN_RATE_UNAUTHORIZED]: ACHCards,
  [MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_BANK]: TransactionCards,
  [MONITORING_ALERT_TYPE.UNREFERENCED_REFUNDS]: RefundCards,
  [MONITORING_ALERT_TYPE.REFUND_RATE]: RefundCards,
  [MONITORING_ALERT_TYPE.FAILED_AUTHORIZATION_RATE]: FailedAuthorizationCards,
  [MONITORING_ALERT_TYPE.REPEAT_DONATIONS]: TransactionCards,
  [MONITORING_ALERT_TYPE.LENDING_CREDIT_CARD_TRANSFERS]: TransactionCards,
  [MONITORING_ALERT_TYPE.FIRST_SETTLEMENT]: FirstSettlementCards,
  [MONITORING_ALERT_TYPE.HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT]: SmallSettlementCards,
  [MONITORING_ALERT_TYPE.DUPLICATE_SETTLEMENT]: DuplicateSettlementCards,
  [MONITORING_ALERT_TYPE.DORMANT_MERCHANT_SETTLEMENT]: DormantSettlementTable,
  [MONITORING_ALERT_TYPE.SETTLEMENT_SIZE_DIFFERENCE]: SettlementSizeDifferenceTable,
  [MONITORING_ALERT_TYPE.HIGH_TICKET]: HighTicketTable,
  [MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK]: DisputeCards,
  [MONITORING_ALERT_TYPE.DISPUTE_COUNT]: DisputeCards,
  [MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW]: DisputeCards,
}

export const getInsightsComponent = (alertType) => insightsComponentMap[alertType] || null

const MonitoringAlertInsights = ({ alertType, monitoringAlert }) => {
  const InsightsComponent = getInsightsComponent(alertType)

  if (!InsightsComponent) return null

  return (
    <PageSectionV2C
      className='monitoring-alert-insights'
      title={MONITORING_ALERT_INSIGHTS_TITLE_MAPPING[alertType]}
    >
      <InsightsComponent monitoringAlert={monitoringAlert} />
    </PageSectionV2C>
  )
}

MonitoringAlertInsights.propTypes = {
  alertType: PropTypes.string.isRequired,
  monitoringAlert: PropTypes.object.isRequired,
}

export default MonitoringAlertInsights
