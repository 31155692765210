import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTION_SCHEDULE_ENROLLMENTS,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const postSubscriptionScheduleEnrollmentAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: SUBSCRIPTION_SCHEDULE_ENROLLMENTS({ subscriptionScheduleId: id }),
    service: SUBSCRIPTION,
  })
}

export default postSubscriptionScheduleEnrollmentAPI
