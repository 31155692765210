import './ExpandableButtonS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'

import {
  ANGLE_DOWN_ICON,
  ANGLE_UP_ICON,
} from 'constants/iconConstants'

const ExpandableButton = ({
  label = 'Submit',
  disabled = false,
  buttonClassName = '',
  toggleExpansion = () => {},
  expanded = false,
  Component,
  extraProps = {},
}) => {
  const buttonClassNames = classnames(`${buttonClassName} flex items-center bold`, { expanded })

  return (
    <div className='ExpandableButton'>
      <Button className={buttonClassNames} disabled={disabled} onClick={toggleExpansion} variant='secondary' rightIcon={expanded ? ANGLE_UP_ICON : ANGLE_DOWN_ICON} label={label} />

      {expanded && (<div className='expanded-area'><Component {...extraProps} /></div>)}
    </div>
  )
}

ExpandableButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  buttonClassName: PropTypes.string,
  toggleExpansion: PropTypes.func,
  expanded: PropTypes.bool,
  Component: PropTypes.object,
  extraProps: PropTypes.object,
}

export default ExpandableButton
