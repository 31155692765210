import paymentsAPI from 'utilities/api/paymentsAPI'
import { TRANSFER_REVERSALS } from 'constants/apiConstants'
import get from 'lodash/get'

const getTransferReversalsAPI = ({ id, values, queries, credentials, meta }) => {
  const transferId = id || get(values, 'transferId')

  return paymentsAPI.get({
    meta,
    path: TRANSFER_REVERSALS({ transferId }),
    queries,
    credentials,
  })
}

export default getTransferReversalsAPI
