import './CreatePaymentLinkFormS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { ChromePicker } from 'react-color'
import { goBack } from 'state-layer/history'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import ButtonGroup from 'components/Shared/ButtonGroup/ButtonGroup'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import PaymentLinkCustomFields from 'components/Customer/Forms/CreatePaymentLinkForm/PaymentLinkCustomFields/PaymentLinkCustomFields'
import PaymentLinkItems from 'components/Customer/Forms/CreatePaymentLinkForm/PaymentLinkItems'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import GuestPaymentLinkFormC from 'components/Customer/Forms/GuestPaymentLinkForm/GuestPaymentLinkFormC'
import submitCreatePaymentLinkForm from 'utilities/submit/submitCreatePaymentLinkForm'
import validateCreatePaymentLinkForm from 'utilities/validate/validateCreatePaymentLinkForm'
import { DATE_PICKER_DATE_FORMAT } from 'constants/timeConstants'
import { CREATE_PAYMENT_LINK_FORM } from 'constants/formConstants'

import {
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form'

import {
  CANCEL,
  ONE_TIME_LINK,
  MULTI_USE_LINK,
  CREATE_A_PAYMENT_LINK,
  PAYMENT_LINK_NAME,
  PAYMENT_LINK_NAME_HELPER_TEXT,
  TERMS_OF_SERVICE_URL,
  CREDIT_AND_DEBIT_CARDS,
  BANK_TRANSFER,
  LINK_VALIDITY,
  TWENTY_FOUR_HOURS,
  FORTY_EIGHT_HOURS,
  SEVENTY_TWO_HOURS,
  ONE_WEEK,
  SIX_MONTHS,
  THREE_YEARS,
  CUSTOM,
  PAYMENT_LINK_EXPIRATION_HELPER_TEXT,
  EXPIRE_ON,
  BUYER_DETAILS,
  ONLY_COLLECT_EMAIL,
  NAME,
  BILLING_ADDRESS,
  SHIPPING_ADDRESS,
  PHONE_NUMBER,
  CUSTOM_SUCCESS_RETURN_URL,
  CUSTOM_FAILURE_RETURN_URL,
  CUSTOM_EXPIRED_SESSION_URL,
  ASK_FOR_MORE_INFORMATION,
  CREATE_PAYMENT_LINK,
  CUSTOM_FIELDS,
  PREVIEW,
  USE_CUSTOM_BRANDING,
  MOBILE,
  DESKTOP,
  GENERAL_INFORMATION,
  ALLOWED_PAYMENT_METHODS,
  ADVANCED_SETTINGS,
  CUSTOM_BRANDING,
  LOGO_URL,
  HEADER_COLOR,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  PAYMENT_LINK_BRANDING_HEADER_TOOLTIP,
  PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP,
  PAYMENT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP,
  PAYMENT_LINK_BRANDING_LOGO_TOOLTIP,
  TERMS_OF_SERVICE_URL_TOOLTIP,
  USE_CUSTOM_BRANDING_PAYMENT_LINK_TOOLTIP_MSG,
  ONE_TIME_LINK_TOOLTIP,
  MULTI_USE_LINK_TOOLTIP,
  SEND_RECEIPT_TO_BUYER_ON_SUCCESS,
  SEND_A_COPY_OF_RECEIPT_TO_SELF,
} from 'constants/language/languageConstants'

const CreatePaymentLinkForm = ({
  handleSubmit = () => {},
  isMultipleUse = false,
  setMultipleUse = () => {},
  setSingleUse = () => {},
  amountType = '',
  amountTypeOptions = [],
  collectMoreBuyerDetails = false,
  showExpirationDatePicker = false,
  currentItems = [],
  isPosting = false,
  previewPaymentLink = {},
  guestPaymentLinkComponentKey = '',
  collectCustomFields = false,
  useCustomBranding = false,
  displayHeaderColorPicker = false,
  displayButtonBackgroundColorPicker = false,
  displayButtonTextColorPicker = false,
  updateColor = () => {},
  handleClick = () => {},
  handleClose = () => {},
  headerColor = '',
  buttonBackgroundColor = '',
  buttonTextColor = '',
  currentPaymentLinkHeaderColor = '',
  isFetching = false,
  invalid = false,
  merchantId = '',
  isFetchingMerchants = false,
  pristine,
}) => {
  const [activePreviewMode, setActivePreviewMode] = useState('mobile')

  return (
    <>
      {(!merchantId && isFetchingMerchants) && <Loader />}

      {(merchantId || !isFetchingMerchants) && (
        <form className='CreatePaymentLinkForm' onSubmit={handleSubmit(submitCreatePaymentLinkForm)}>
          <div className='content-container flex'>
            <div className='form-content'>
              <div className='form-holder'>
                <div className='form-header'>
                  <h3 className='title'>{CREATE_A_PAYMENT_LINK}</h3>
                </div>

                {(isFetching || isFetchingMerchants) && <Loader />}

                {!isFetching && (
                  <div className='form-body'>
                    <div className='link-type-buttons flex'>
                      <div className={`one-time-link ${isMultipleUse ? 'inactive' : 'active'}`} onClick={setSingleUse}>
                        <span className='flex center'>
                          <RadioButton options={[{ value: 'one-time', label: ONE_TIME_LINK }]} input={{ value: isMultipleUse ? 'multi-use' : 'one-time', name: 'radio-button' }} />
                          <TooltipLabelC message={ONE_TIME_LINK_TOOLTIP} />
                        </span>
                      </div>
                      <div className={`multi-use-link ${isMultipleUse ? 'active' : 'inactive'}`} onClick={setMultipleUse}>
                        <span className='flex center'>
                          <RadioButton options={[{ value: 'multi-use', label: MULTI_USE_LINK }]} input={{ value: isMultipleUse ? 'multi-use' : 'one-time', name: 'radio-button' }} />
                          <TooltipLabelC message={MULTI_USE_LINK_TOOLTIP} position='left' />
                        </span>
                      </div>
                    </div>

                    <Accordion title={GENERAL_INFORMATION} className='borderless' open>
                      <Field
                        name='nickname'
                        label={PAYMENT_LINK_NAME}
                        placeholder={isMultipleUse ? 'E.g. School Fundraiser' : 'E.g. Dental Invoice #12345'}
                        helperText={PAYMENT_LINK_NAME_HELPER_TEXT}
                        component={InputField}
                        required={false}
                      />

                      <Field
                        name='amountType'
                        label='Amount Type'
                        component={ReactSelect}
                        options={amountTypeOptions}
                        required
                      />

                      { amountType && (
                        <div className='items'>
                          <FieldArray
                            name='items'
                            component={PaymentLinkItems}
                            amountType={amountType}
                            currentItems={currentItems}
                          />
                        </div>
                      )}

                      <Field
                        name='termsOfServiceUrl'
                        label={TERMS_OF_SERVICE_URL}
                        placeholder='https://www.example.com/terms-of-service'
                        component={InputField}
                        tooltip={TERMS_OF_SERVICE_URL_TOOLTIP}
                      />
                    </Accordion>

                    <Accordion title={ALLOWED_PAYMENT_METHODS} className='borderless'>
                      <Field
                        component={CheckboxC}
                        name='allowPaymentCard'
                        label={CREDIT_AND_DEBIT_CARDS}
                        checked
                      />

                      <Field
                        component={CheckboxC}
                        name='allowBankTransfer'
                        label={BANK_TRANSFER}
                      />
                    </Accordion>

                    <Accordion title={ADVANCED_SETTINGS} className='borderless'>
                      <Field
                        label={LINK_VALIDITY}
                        name='expiresIn'
                        component={RadioButton}
                        options={[
                          { label: TWENTY_FOUR_HOURS, value: '24' }, // 1440 minutes (should we just set this to minutes right here?
                          { label: FORTY_EIGHT_HOURS, value: '48' }, // 2880 minutes
                          { label: SEVENTY_TWO_HOURS, value: '72' }, // 4320 minutes
                          { label: ONE_WEEK, value: '168' }, // 10080 minutes
                          { label: SIX_MONTHS, value: '4380' }, // 262800 minutes
                          { label: THREE_YEARS, value: '26280' }, // 1576800 minutes
                          { label: CUSTOM, value: 'custom' }, // set by calendar picker
                        ]}
                      />

                      { showExpirationDatePicker && (
                        <Field
                          name='expirationDate'
                          label={EXPIRE_ON}
                          component={DatePickerInput}
                          minDate={moment().add(1, 'days').toDate()}
                          maxDate={moment().add(3, 'years').toDate()}
                          dateFormat={DATE_PICKER_DATE_FORMAT}
                          placeholderText='MM/DD/YYYY'
                          helperText={PAYMENT_LINK_EXPIRATION_HELPER_TEXT}
                          autofill={false}
                        />
                      )}

                      <Field
                        label={BUYER_DETAILS}
                        name='collectBuyerDetails'
                        component={RadioButton}
                        options={[
                          { label: ONLY_COLLECT_EMAIL, value: 'email' },
                          { label: ASK_FOR_MORE_INFORMATION, value: 'more' },
                        ]}
                      />

                      { collectMoreBuyerDetails && (
                        <div className='more-buyer-details'>
                          <Field
                            component={CheckboxC}
                            name='collectName'
                            label={NAME}
                            type='checkbox'
                          />

                          <Field
                            component={CheckboxC}
                            name='collectPhoneNumber'
                            label={PHONE_NUMBER}
                          />

                          <Field
                            component={CheckboxC}
                            name='collectShippingAddress'
                            label={SHIPPING_ADDRESS}
                          />

                          <Field
                            component={CheckboxC}
                            name='collectBillingAddress'
                            label={BILLING_ADDRESS}
                          />

                          <Field
                            component={CheckboxC}
                            name='collectCustomFields'
                            label={CUSTOM_FIELDS}
                          />

                          { collectCustomFields && (
                            <div className='custom-fields'>
                              <FieldArray
                                name='customFields'
                                component={PaymentLinkCustomFields}
                              />
                            </div>
                          )}
                        </div>
                      )}

                      <Field
                        name='successReturnUrl'
                        label={CUSTOM_SUCCESS_RETURN_URL}
                        placeholder='https://www.example.com/success.html'
                        component={InputField}
                        required={false}
                      />

                      <Field
                        name='unsuccessfulReturnUrl'
                        label={CUSTOM_FAILURE_RETURN_URL}
                        placeholder='https://www.example.com/failure.html'
                        component={InputField}
                        required={false}
                      />

                      <Field
                        name='expiredSessionUrl'
                        label={CUSTOM_EXPIRED_SESSION_URL}
                        placeholder='https://www.example.com/expired.html'
                        component={InputField}
                        required={false}
                      />
                    </Accordion>

                    {useCustomBranding && (
                      <Accordion title={CUSTOM_BRANDING} className='borderless' open>
                        <Field
                          name='paymentLinkLogoURL'
                          label={LOGO_URL}
                          placeholder='E.g. https://www.dropbox.com/logo'
                          component={InputField}
                          required={false}
                          tooltip={PAYMENT_LINK_BRANDING_LOGO_TOOLTIP}
                          tooltipPosition='right'
                        />

                        <div className='flex items-center'>
                          <Field
                            name='paymentLinkHeaderColor'
                            label={HEADER_COLOR}
                            placeholder={currentPaymentLinkHeaderColor}
                            component={InputField}
                            tooltip={PAYMENT_LINK_BRANDING_HEADER_TOOLTIP}
                            tooltipPosition='right'
                            required={false}
                          />

                          <div>
                            <div
                              className='color-block'
                              style={{ backgroundColor: headerColor }}
                              onClick={() => handleClick('displayHeaderColorPicker')}
                            />
                            { displayHeaderColorPicker ? (
                              <div className='color-picker-popup'>
                                <div className='color-picker-cover' onClick={() => handleClose('displayHeaderColorPicker')} />
                                <ChromePicker
                                  color={headerColor}
                                  onChange={(color) => { updateColor('paymentLinkHeaderColor', color) }}
                                  disableAlpha
                                />
                              </div>
                            ) : null }
                          </div>
                        </div>

                        <div className='flex items-center'>
                          <Field
                            name='paymentLinkButtonBackgroundColor'
                            label={BUTTON_BACKGROUND_COLOR}
                            placeholder='#0B5DBC'
                            component={InputField}
                            tooltip={PAYMENT_LINK_BRANDING_BUTTON_BACKGROUND_TOOLTIP}
                            tooltipPosition='right'
                            required={false}
                          />

                          <div>
                            <div
                              className='color-block'
                              style={{ backgroundColor: buttonBackgroundColor }}
                              onClick={() => handleClick('displayButtonBackgroundColorPicker')}
                            />
                            { displayButtonBackgroundColorPicker ? (
                              <div className='color-picker-popup'>
                                <div className='color-picker-cover' onClick={() => handleClose('displayButtonBackgroundColorPicker')} />
                                <ChromePicker
                                  color={buttonBackgroundColor}
                                  onChange={(color) => { updateColor('paymentLinkButtonBackgroundColor', color) }}
                                  disableAlpha
                                />
                              </div>
                            ) : null }
                          </div>
                        </div>

                        <div className='flex items-center'>
                          <Field
                            name='paymentLinkButtonTextColor'
                            label={BUTTON_TEXT_COLOR}
                            placeholder='#FFFFFF'
                            component={InputField}
                            tooltip={PAYMENT_LINK_BRANDING_BUTTON_TEXT_TOOLTIP}
                            tooltipPosition='right'
                            required={false}
                          />

                          <div>
                            <div
                              className='color-block'
                              style={{ backgroundColor: buttonTextColor }}
                              onClick={() => handleClick('displayButtonTextColorPicker')}
                            />
                            { displayButtonTextColorPicker ? (
                              <div className='color-picker-popup'>
                                <div className='color-picker-cover' onClick={() => handleClose('displayButtonTextColorPicker')} />
                                <ChromePicker
                                  color={buttonTextColor}
                                  onChange={(color) => { updateColor('paymentLinkButtonTextColor', color) }}
                                  disableAlpha
                                />
                              </div>
                            ) : null }
                          </div>
                        </div>
                      </Accordion>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className='preview flex column'>
              {isFetching && <Loader />}

              {!isFetching && (
                <>
                  <div className='preview-header flex space-between'>
                    <div className='flex column'>
                      <h5>{PREVIEW}</h5>
                    </div>

                    <ButtonGroup
                      options={[
                        { label: MOBILE, onClick: () => setActivePreviewMode('mobile') },
                        { label: DESKTOP, onClick: () => setActivePreviewMode('desktop') },
                      ]}
                    />
                  </div>

                  <Field
                    component={CheckboxC}
                    className='use-custom-branding-checkbox'
                    name='useCustomBranding'
                    label={USE_CUSTOM_BRANDING}
                    tooltip={USE_CUSTOM_BRANDING_PAYMENT_LINK_TOOLTIP_MSG}
                  />

                  <div className='preview-container flex flex-grow items-center'>
                    { activePreviewMode === 'mobile' && (
                      <div className='mobile-container'>
                        <div className='mobile-line line-1' />
                        <div className='mobile-line line-2' />
                        <div className='mobile-line line-3' />
                        <div className='mobile-line line-4' />

                        <div className='mobile-content-wrapper'>
                          <GuestPaymentLinkFormC
                            openOrderSummary
                            paymentLink={previewPaymentLink}
                            previewMode='mobile'
                            key={guestPaymentLinkComponentKey}
                          />
                        </div>
                      </div>
                    )}

                    { activePreviewMode === 'desktop' && (
                      <div className='desktop-size-container'>
                        <div className='desktop-container'>
                          <div className='container-1'>
                            <div className='desktop-content-wrapper'>
                              <GuestPaymentLinkFormC
                                openOrderSummary
                                paymentLink={previewPaymentLink}
                                previewMode='desktop'
                                key={guestPaymentLinkComponentKey}
                              />
                            </div>
                          </div>
                          <div className='container-2' />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='form-footer'>
            <Button variant='ghost' label={CANCEL} onClick={goBack} />
            <Button type='submit' label={CREATE_PAYMENT_LINK} disabled={isPosting || invalid || pristine} />
          </div>
        </form>
      )}
    </>
  )
}

CreatePaymentLinkForm.propTypes = {
  handleSubmit: PropTypes.func,
  isMultipleUse: PropTypes.bool,
  setMultipleUse: PropTypes.func,
  setSingleUse: PropTypes.func,
  amountType: PropTypes.string,
  amountTypeOptions: PropTypes.array,
  collectMoreBuyerDetails: PropTypes.bool,
  showExpirationDatePicker: PropTypes.bool,
  isPosting: PropTypes.bool,
  previewPaymentLink: PropTypes.object,
  guestPaymentLinkComponentKey: PropTypes.string,
  collectCustomFields: PropTypes.bool,
  currentPaymentLinkHeaderColor: PropTypes.string,
  updateColor: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  headerColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  isFetching: PropTypes.bool,
  invalid: PropTypes.bool,
  merchantId: PropTypes.string,
  isFetchingMerchants: PropTypes.bool,
  currentItems: PropTypes.array,
  useCustomBranding: PropTypes.bool,
  displayHeaderColorPicker: PropTypes.bool,
  displayButtonBackgroundColorPicker: PropTypes.bool,
  displayButtonTextColorPicker: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_PAYMENT_LINK_FORM,
  validate: validateCreatePaymentLinkForm,
})(CreatePaymentLinkForm)
