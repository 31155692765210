import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIdentitySelector } from 'state-layer/selectors/index'
import Buyer from './Buyer'
import BuyerInfoC from 'components/Customer/Pages/Buyer/BuyerInfo/BuyerInfoC'
import IdentityPaymentInstrumentsC from 'components/Customer/Pages/Identity/IdentityPaymentInstruments/IdentityPaymentInstrumentsC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { isFlexPlatform } from 'constants/flexConstants'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_PAYMENT_PATH } from 'constants/pathConstants'
import { SHOW_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { goToPath } from 'state-layer/history'
import get from 'lodash/get'

import {
  ADDED,
  EMAIL,
  BUYER_INFO,
  PAYMENT_INSTRUMENTS,
  CREATE_A_PAYMENT,
  BUYER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const credentialId = get(credentials, 'id')
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isFlex = isFlexPlatform()

  const [
    resourceTitle,
    createdAt,
    email,
  ] = getMany(identity, [
    'resourceTitle',
    'displayCreatedAt',
    'email',
  ])

  const headerData = {
    resource: {
      label: BUYER,
      id: identityId,
    },
    items: [
      {
        value: <h1>{resourceTitle}</h1>,
      },
      {
        label: ADDED,
        value: createdAt,
      },
      {
        label: EMAIL,
        value: email,
      },
    ],
  }

  const tabs = [
    {
      id: 'buyer-info',
      name: BUYER_INFO,
      component: BuyerInfoC,
      props: {
        identity,
      },
    },
    {
      id: 'payment-instruments',
      name: PAYMENT_INSTRUMENTS,
      component: IdentityPaymentInstrumentsC,
      props: {
        identityId,
        hideAddBankAccountBtn: true,
      },
    },
  ]

  return {
    isFetching,
    credentials,
    credentialId,
    identityId,
    tabs,
    headerData,
    isStandaloneMerchant,
    environment,
    isFlex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}

class BuyerC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  render() {
    const {
      credentialId,
      identityId,
      isFlex,
      environment,
    } = this.props

    const actions = [
      {
        label: CREATE_A_PAYMENT,
        buttonClassName: 'create-a-payment-button',
        action: () => goToPath({
          pathname: CREATE_PAYMENT_PATH({ credentialId }),
          queries: {
            buyerIdentityId: identityId,
          },
        }),
        condition: isFlex && environment !== 'QA' && getFeatureFlag(SHOW_VIRTUAL_TERMINAL) && isAdministratorRole(),
      },
    ]

    return (
      <Buyer
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerC)
