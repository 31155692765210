import './MerchantIdentityNewS.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantIdentityNew from './MerchantIdentityNew'
import SelectApplicationFormC from 'components/Customer/Forms/SelectApplicationForm/SelectApplicationFormC'
import SelectApplicationDisplayData from 'components/Customer/Forms/SelectApplicationForm/SelectApplicationDisplayData'
import IdentityBusinessFormC from 'components/Customer/Forms/IdentityForm/IdentityBusinessFormC'
import IdentityBusinessDisplayData from 'components/Customer/Forms/IdentityForm/IdentityBusinessDisplayData'
import IdentityPrincipalsFormC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsFormC'
import IdentityPrincipalsConfirmationC from 'components/Customer/Forms/IdentityForm/IdentityPrincipalForms/IdentityPrincipalsConfirmationC'
import IdentityProcessingFormC from 'components/Customer/Forms/IdentityForm/IdentityProcessingFormC'
import IdentityProcessingDisplayData from 'components/Customer/Forms/IdentityForm/IdentityProcessingDisplayData'
import AdditionalUnderwritingDataFormC from 'components/Customer/Forms/AdditionalUnderwritingDataForm/AdditionalUnderwritingDataFormC'
import AdditionalUnderwritingDataInfo from 'components/Customer/Forms/AdditionalUnderwritingDataForm/AdditionalUnderwritingDisplayData'
import BankAccountForm from 'components/Customer/Forms/BankAccountForm/BankAccountForm'
import BankAccountDisplayData from 'components/Customer/Forms/BankAccountForm/BankAccountDisplayData'
import MerchantIdentityConfirmation from 'components/Customer/Pages/IdentityNew/MerchantIdentityConfirmation'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { NO_REFUNDS } from 'constants/underwritingConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import filter from 'lodash/filter'

import {
  createBankAccount,
  createMerchantIdentity,
  createAssociatedIdentity,
} from 'constants/orchestrationConstants'

import {
  GET_APPLICATIONS_F_REQUEST,
  POST_MERCHANT_IDENTITY_F_REQUEST,
} from 'constants/flowConstants'

import {
  IDENTITY_PRINCIPALS_FORM,
  IDENTITY_PROCESSING_FORM,
  IDENTITY_BUSINESS_FORM,
  BANK_ACCOUNT_FORM,
  ADDITIONAL_UNDERWRITING_DATA_FORM,
  SELECT_APPLICATION_FORM,
} from 'constants/formConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const applicationId = get(props, 'params.applicationId') || get(currentUser, 'applicationId')
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const underwritingCondition = get(props, 'location.state.underwritingFlag', false)
  const isFlex = isFlexPlatform()
  const isRolePartnerCredential = isRolePartner({ credentials })

  const steps = [
    {
      name: 'Select Application',
      iconName: 'fa-hand-pointer',
      component: SelectApplicationFormC,
      getDisplayData: SelectApplicationDisplayData,
      props: {
        submitLabel: 'Save and Continue',
      },
      dataKey: 'selectedApplication',
      formValues: () => reduxFormValues(SELECT_APPLICATION_FORM),
      condition: underwritingCondition && !isRolePartnerCredential,
    },
    {
      name: 'Business Info',
      iconName: 'fa-store-alt',
      component: IdentityBusinessFormC,
      props: {
        submitLabel: 'Save and Continue',
      },
      dataKey: 'businessInfo',
      formValues: () => reduxFormValues(IDENTITY_BUSINESS_FORM),
      getDisplayData: IdentityBusinessDisplayData,
      orchestrationStatus: createMerchantIdentity,
      initialValues: {
        businessAddress: { country: 'USA' },
      },
    },
    {
      name: 'Principal Info',
      iconName: 'fa-user',
      component: IdentityPrincipalsFormC,
      props: {
        submitLabel: 'Save and Continue',
        leftButton: true,
      },
      dataKey: 'principalsInfo',
      formValues: () => reduxFormValues(IDENTITY_PRINCIPALS_FORM),
      getDisplayData: ({ principals }) => principals,
      displayComponent: IdentityPrincipalsConfirmationC,
      initialValues: { principals: [{ personalAddress: { country: 'USA' } }] },
      orchestrationStatus: createAssociatedIdentity,
    },
    {
      name: 'Processing Info',
      iconName: 'fa-cog',
      component: IdentityProcessingFormC,
      props: {
        submitLabel: 'Save and Continue',
        leftButton: true,
        applicationId,
      },
      dataKey: 'processingInfo',
      formValues: () => reduxFormValues(IDENTITY_PROCESSING_FORM),
      getDisplayData: IdentityProcessingDisplayData,
    },
    {
      name: 'Additional Info',
      iconName: 'fa-list',
      component: AdditionalUnderwritingDataFormC,
      props: {
        submitLabel: 'Save and Continue',
        leftButton: true,
      },
      dataKey: 'additionalUnderwritingInfo',
      formValues: () => reduxFormValues(ADDITIONAL_UNDERWRITING_DATA_FORM),
      initialValues: {
        refundPolicy: NO_REFUNDS,
      },
      getDisplayData: AdditionalUnderwritingDataInfo,
      condition: underwritingCondition || isFlex,
    },
    {
      name: 'Bank Account',
      iconName: 'fa-university',
      component: BankAccountForm,
      props: {
        submitLabel: 'Save and Continue',
        leftButton: true,
      },
      dataKey: 'bankAccount',
      formValues: () => reduxFormValues(BANK_ACCOUNT_FORM),
      getDisplayData: BankAccountDisplayData,
      orchestrationStatus: createBankAccount,
    },
    {
      name: 'Confirmation',
      iconName: 'fa-check',
      component: MerchantIdentityConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
        recordId: applicationId,
        action: POST_MERCHANT_IDENTITY_F_REQUEST,
        underwritingFlag: true,
        isFlex,
        backgroundColor: '#0D121A',
      },
    },
  ]

  const filteredSteps = filter(steps, (step) => get(step, 'condition', true))

  return {
    credentials,
    layout: 'vertical',
    steps: filteredSteps,
    underwritingCondition,
    isRolePartnerCredential,
    isFlex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplications: ({ credentials }) => dispatch({
      type: GET_APPLICATIONS_F_REQUEST,
      payload: {
        credentials,
      },
    }),
  }
}

class MerchantIdentityNewC extends Component {
  componentDidMount() {
    const {
      credentials,
      getApplications,
      isRolePartnerCredential,
    } = this.props

    if (!isRolePartnerCredential) {
      getApplications({ credentials })
    }
  }

  render () {
    return (
      <MerchantIdentityNew {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantIdentityNewC)
