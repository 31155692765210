import { fieldEmpty } from 'utilities/validate'

const validateCreateRoleForm = (values) => {
  const {
    roleName,
    roleDescription,
    selectedEvents,
  } = values

  return {
    roleName: fieldEmpty(roleName, 'Role Name'),
    roleDescription: fieldEmpty(roleDescription, 'Role Description'),
    selectedEvents: fieldEmpty(selectedEvents, 'Selected Events'),
  }
}

export default validateCreateRoleForm
