import removeUndefined from 'utilities/remove/removeUndefined'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'

const frontendSplitTransferModel = ({ data: splitTransfer }) => {
  const [
    id,
    createdAt,
    updatedAt,
    amount,
    applicationId,
    currency,
    fee,
    feeProfileId,
    identityId,
    merchantId,
    parentTransferId,
    readyToSettleAt,
    type,
    parentTransfer,
    application,
    identity,
    merchant,
    fees,
  ] = getMany(splitTransfer, [
    'id',
    'created_at',
    'updated_at',
    'amount',
    'application_id',
    'currency',
    'fee',
    'fee_profile_id',
    'identity_id',
    'merchant_id',
    'parent_transfer_id',
    'ready_to_settle_at',
    'type',
    'parentTransfer',
    'application',
    'identity',
    'merchant',
    'fees',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    amount,
    displayAmount: formatMoney({ amount, currency }),
    applicationId,
    currency,
    fee,
    feeProfileId,
    identityId,
    merchantId,
    parentTransferId,
    type,
    readyToSettleAt,
    displayReadyToSettleAt: formatDate({ date: readyToSettleAt }),
    parentTransfer,
    application,
    identity,
    merchant,
    fees,
  })
}

export default frontendSplitTransferModel
