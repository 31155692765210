import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UpdateOnboardingFormFileFormC from 'components/Customer/Forms/UpdateOnboardingFormFileForm/UpdateOnboardingFormFileFormC'

const UpdateOnboardingFormFileModal = ({
  fileId = '',
  fileName = '',
  associatedFiles = [],
  onboardingFormId = '',
  removeFile = false,
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title={removeFile ? 'Remove File' : 'Update File Name'}
      className='UpdateOnboardingFormFileModal'
      Component={UpdateOnboardingFormFileFormC}
      fileId={fileId}
      fileName={fileName}
      associatedFiles={associatedFiles}
      onboardingFormId={onboardingFormId}
      removeFile={removeFile}
      closeModal={closeModal}
    />
  )
}

UpdateOnboardingFormFileModal.propTypes = {
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  associatedFiles: PropTypes.array,
  onboardingFormId: PropTypes.string,
  removeFile: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default UpdateOnboardingFormFileModal
