import { GET_SETTLEMENT_GROUP_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSettlementGroupRequest = ({
  settlementGroupId,
  credentials,
}) => {
  return createAction({
    type: GET_SETTLEMENT_GROUP_F_REQUEST,
    id: settlementGroupId,
    credentials,
  })
}

export default getSettlementGroupRequest
