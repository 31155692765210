import './DatePickerNewS.scss'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import ReactDatePicker, { CalendarContainer } from 'react-datepicker'
import moment from 'moment'
import Button from 'components/Shared/Button/Button'
import submitTableFiltersForm from 'utilities/submit/submitTableFiltersForm'
import getSubstringInObjectKeys from 'utilities/get/getSubstringInObjectKeys'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import { ANGLE_DOWN_ICON, CALENDAR } from 'constants/iconConstants'
import { REMOVE_TABLE_FILTER } from 'constants/flowConstants'
import { ENTER_KEYCODE } from 'constants/focusControlConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import includes from 'lodash/includes'
import trim from 'lodash/trim'
import split from 'lodash/split'
import keys from 'lodash/keys'
import intersection from 'lodash/intersection'

import {
  CREATED_AT_FIELD,
  UPDATED_AT_FIELD,
  RESPOND_BY_FIELD,
} from 'constants/filterConstants'

import {
  DATE_PICKER_PRESET_MAPPING,
  DATE_PICKER_PRESETS,
  convertDatesToOperand,
  getDefaultDatePickerPreset,
} from 'constants/quickFilterConstants'

import {
  CLEAR,
  CANCEL,
  DONE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const currentTableFilters = get(state, 'tableFiltersR')

  return {
    currentTableFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchDatePicker: (values, props) => submitTableFiltersForm(values, dispatch, props),
    dispatchRemoveFilter: ({ filterFieldName, operand }) => dispatch({ type: REMOVE_TABLE_FILTER, payload: { field: filterFieldName, operand } }),
  }
}

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}


const DatePickerNew = (props) => {
  const {
    dispatchDatePicker,
    dispatchRemoveFilter,
    isFetching,
    customPresets = DATE_PICKER_PRESETS,
    label: placeholderText,
    labelMap = [],
    currentTableFilters,
    initialFilters,
    minDate,
  } = props

  const filterNames = get(props, 'names')

  const urlQueries = parseUrlQueries()
  const createdAtQuery = getSubstringInObjectKeys(urlQueries, CREATED_AT_FIELD) ? CREATED_AT_FIELD : undefined
  const updatedAtQuery = getSubstringInObjectKeys(urlQueries, UPDATED_AT_FIELD) ? UPDATED_AT_FIELD : undefined
  const respondByQuery = getSubstringInObjectKeys(urlQueries, RESPOND_BY_FIELD) ? RESPOND_BY_FIELD : undefined
  const currentDateQuery = createdAtQuery || updatedAtQuery || respondByQuery

  const filterName = filterNames ? (get(intersection(filterNames, keys(currentTableFilters)), '[0]') || currentDateQuery || get(filterNames, '[0]')) : get(props, 'name')
  const [activePreset, setActivePreset] = useState('')
  const [customOption, setCustomOption] = useState('')
  const [datePickerRef, setDatePickerRef] = useState(false)
  const [filterFieldName, setFilterFieldName] = useState(filterName)
  const [isCalendarOpen, toggleCalendarOpen] = useState(false)
  const prevFilterFieldName = usePrevious(filterFieldName)

  const gte = get(currentTableFilters, `${filterFieldName}.gte`, get(initialFilters, `${filterFieldName}.gte`))
  const lte = get(currentTableFilters, `${filterFieldName}.lte`, get(initialFilters, `${filterFieldName}.lte`))

  const startDateMoment = gte ? moment(gte) : undefined
  const endDateMoment = lte ? moment(lte) : undefined
  const startDateQuery = startDateMoment?.isValid() ? startDateMoment.toDate() : undefined
  const endDateQuery = endDateMoment?.isValid() ? endDateMoment.toDate() : undefined
  const prevStartDateQuery = usePrevious(startDateQuery)
  const prevEndDateQuery = usePrevious(endDateQuery)

  const [startDate, setStartDate] = useState(startDateQuery)
  const [endDate, setEndDate] = useState(endDateQuery)

  const DatePickerNewClassnames = classnames('DatePickerNew flex', {
    'multiple-date-filters': !!filterNames,
  })

  const setDates = (date) => {
    setStartDate(date?.[0] || date)
    setEndDate(date?.[1])
  }

  const handleToggleDatePicker = () => {
    if (isCalendarOpen) {
      toggleCalendarOpen(false)
      datePickerRef.setOpen(false)
      setActivePreset('')
    } else {
      toggleCalendarOpen(true)
      datePickerRef.setOpen(true)
    }
  }

  const handleApplyDatePicker = () => {
    const operand = activePreset === 'custom' ? customOption : get(DATE_PICKER_PRESET_MAPPING, `${activePreset}.operand`)
    let endDateVal = endDate

    if (!endDate) {
      endDateVal = startDate
    }

    const values = {
      [filterFieldName]: {
        gte: {
          value: includes(['between', 'gte', 'on'], operand) ? startDate : undefined,
          operand: 'gte',
        },
        lte: {
          value: includes(['between', 'lte', 'on'], operand) ? endDateVal : undefined,
          operand: 'lte',
        },
        operand,
        value: startDate,
      },
    }

    const dispatchDatePickerProps = {
      checkedFilters: { [filterFieldName]: (startDate || endDateVal) ? true : false },
    }

    dispatchDatePicker(values, dispatchDatePickerProps)
    setActivePreset('') // clears active preset so it can be reset by useEffect

    if (isCalendarOpen) {
      handleToggleDatePicker()
    }
  }

  const handleOnKeyDown = (e) => {
    if (isEqual(get(e, 'key'), 'Enter') || isEqual(get(e, 'keyCode'), ENTER_KEYCODE)) {
      handleApplyDatePicker()
    }
  }

  // handle active preset changes
  useEffect(() => {
    if (activePreset === 'custom') {
      setCustomOption('between')
    } else {
      const getDate = get(DATE_PICKER_PRESET_MAPPING, `${activePreset}.getDate`, () => {})
      const date = getDate()

      setDates(date)
      // eslint-disable-next-line no-unused-expressions
      if (datePickerRef) datePickerRef.setPreSelection(...(date?.[0] ? date : [date])) // hack to update calendar to correct month
    }
  }, [activePreset])

  useEffect(() => {
    setFilterFieldName(filterName)
  }, [filterName])

  // initialize datepicker with current created at queries
  useEffect(() => {
    const initialCustomSelect = convertDatesToOperand({ startDate: startDateQuery, endDate: endDateQuery })
    const defaultDatePickerPreset = getDefaultDatePickerPreset({ startDate: startDateQuery, endDate: endDateQuery })

    setStartDate(endDateQuery && !startDateQuery ? endDateQuery : startDateQuery) // endDate must be set as startDate for lte only
    setEndDate(endDateQuery)
    setActivePreset(defaultDatePickerPreset)

    if (defaultDatePickerPreset === 'custom') setCustomOption(initialCustomSelect)
  }, [(startDateQuery || endDateQuery) && !activePreset])

  useEffect(() => {
    if (prevFilterFieldName && filterFieldName && !isEqual(filterFieldName, prevFilterFieldName)) {
      // clear current date filter
      dispatchRemoveFilter({ filterFieldName: prevFilterFieldName, operand: 'gte' })
      dispatchRemoveFilter({ filterFieldName: prevFilterFieldName, operand: 'lte' })

      handleApplyDatePicker()
    }
  }, [filterFieldName])

  useEffect(() => {
    if (startDateQuery) {
      setStartDate(startDateQuery)
    }
  }, [startDateQuery ? startDateQuery.getTime() : undefined])

  useEffect(() => {
    if (endDateQuery) {
      setEndDate(endDateQuery)
    }
  }, [endDateQuery ? endDateQuery.getTime() : undefined])

  const handleClear = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setActivePreset('custom')
  }

  const handleOnChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates
      const endDateVal = end

      if (activePreset !== 'custom') {
        setActivePreset('custom')
      }

      if (!isEqual(start, end)) {
        setCustomOption('between')
      }

      setStartDate(start)
      setEndDate(endDateVal)
    }
  }

  const handleOnChangeRaw = (e) => {
    const inputValue = e.target?.value

    if (inputValue) {
      const splitValue = split(inputValue, '-')
      const normalizedSplitValue = map(splitValue, (val) => trim(val))

      const startDateVal = get(normalizedSplitValue, '[0]')
      const momentStartDate = startDateVal ? moment(startDateVal) : undefined
      const normalizedStartDate = momentStartDate?.isValid() ? momentStartDate?.toDate() : undefined
      const endDateVal = get(normalizedSplitValue, '[1]')
      const momentEndDate = endDateVal ? moment(endDateVal) : undefined
      let normalizedEndDate = momentEndDate?.isValid() ? momentEndDate.toDate() : undefined

      // no end date or start date input is equal to end date input means end date is same as start date
      if (!normalizedEndDate || isEqual(normalizedStartDate, normalizedEndDate)) {
        normalizedEndDate = normalizedStartDate
        setCustomOption('on')
      }

      if (normalizedStartDate && normalizedEndDate) {
        handleOnChange([normalizedStartDate, normalizedEndDate], { event: e })
      }
    }
  }

  const handleOnSelectDateFilterChange = (e) => {
    const selectValue = e.target.value

    setFilterFieldName(selectValue)
  }

  const handleOnClickOutside = (e) => {
    const target = get(e, 'target')
    const calendarIconClicked = document.querySelector('.calendar-icon-container')?.contains(target)

    if (!calendarIconClicked) {
      handleToggleDatePicker()
    }
  }

  const MyContainer = ({ className, children }) => {
    return (
      <div className='datepicker'>
        <div className='datepicker-container flex'>
          <div className='datepicker-presets flex column'>
            {
              map(customPresets, ({ label, name }) => {
                return (
                  <button
                    key={name}
                    type='button'
                    className={activePreset === name ? 'active' : ''}
                    onClick={() => setActivePreset(name)}
                  >
                    {label}
                  </button>
                )
              })
            }
          </div>

          <div className='datepicker-content'>
            <CalendarContainer className={className}>
              <div>{children}</div>
            </CalendarContainer>
          </div>
        </div>

        <div className='datepicker-footer flex flex-end'>
          <Button onClick={handleToggleDatePicker} label={CANCEL} variant='secondary' />
          <Button onClick={handleClear} label={CLEAR} variant='secondary' />
          <Button onClick={handleApplyDatePicker} disabled={isFetching} label={DONE} />
        </div>
      </div>
    )
  }

  return (
    <div className={DatePickerNewClassnames}>
      {filterNames && (
      <div className='multiple-date-filters-select-area flex items-center'>
        <select onChange={handleOnSelectDateFilterChange}>
          { map(labelMap, ({ label: optionLabel, value: optionValue }) => includes(filterNames, optionValue) && <option value={optionValue} key={optionValue || 'key'} selected={filterName === optionValue}>{optionLabel}</option>) }
        </select>

        <span className={`arrow fa fa-${ANGLE_DOWN_ICON}`} />
      </div>
      )}

      <ReactDatePicker
        ref={(ref) => setDatePickerRef(ref)}
        monthsShown={2}
        selected={startDate}
        onChange={handleOnChange}
        startDate={startDate}
        endDate={endDate}
        calendarContainer={MyContainer}
        selectsRange
        shouldCloseOnSelect={false}
        placeholderText={placeholderText}
        onChangeRaw={handleOnChangeRaw}
        onClickOutside={handleOnClickOutside}
        onKeyDown={handleOnKeyDown}
        onCalendarOpen={() => toggleCalendarOpen(true)}
        minDate={minDate}
      />

      <div className='calendar-icon-container'>
        <i className={`far fa-${CALENDAR}`} onClick={handleToggleDatePicker} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerNew)
