import './PaymentInstrumentVerificationsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { PAYMENT_INSTRUMENT_VERIFICATIONS } from 'constants/linkConstants'
import { GET_PAYMENT_INSTRUMENT_VERIFICATIONS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getPaymentInstrumentVerificationsSelector } from 'state-layer/selectors'

const PaymentInstrumentVerifications = ({
  flowValues = {},
  isFetching = false,
  paymentInstrumentId = '',
  actions = [],
}) => {
  return (
    <div className='PaymentInstrumentVerifications'>
      <TableC
        flow={GET_PAYMENT_INSTRUMENT_VERIFICATIONS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getPaymentInstrumentVerificationsSelector}
        selectorId={paymentInstrumentId}
        linksKey={PAYMENT_INSTRUMENT_VERIFICATIONS}
        isFetching={isFetching}
        actions={actions}
        fileNameCSV={`PaymentInstrument_${paymentInstrumentId}_Verifications`}
        icon={`fa fa-${PAYMENTS_ICON}`}
      />
    </div>
  )
}

PaymentInstrumentVerifications.propTypes = {
  flowValues: PropTypes.object,
  isFetching: PropTypes.bool,
  paymentInstrumentId: PropTypes.string,
  actions: PropTypes.array,
}

export default PaymentInstrumentVerifications
