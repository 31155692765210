import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import ApplicationIdentitiesC from 'components/Customer/Pages/Application/ApplicationIdentities/ApplicationIdentitiesC'
import ApplicationPaymentInstrumentsC from 'components/Customer/Pages/Application/ApplicationPaymentInstruments/ApplicationPaymentInstrumentsC'

import {
  IDENTITIES,
  PAYMENT_INSTRUMENTS,
} from 'constants/language/languageConstants'

class ApplicationDataResourcesTabs extends Component {
  render() {
    const { applicationId } = this.props

    const tabs = [
      {
        id: 'identities',
        name: IDENTITIES,
        component: ApplicationIdentitiesC,
        props: {
          applicationId,
        },
      },
      {
        id: 'payment-instruments',
        name: PAYMENT_INSTRUMENTS,
        component: ApplicationPaymentInstrumentsC,
        props: {
          applicationId,
        },
      },
    ]

    return (
      <div className='ApplicationDataResourcesTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationDataResourcesTabs
