import { PATCH_ONBOARDING_FORM_BANK_DOCUMENT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const patchOnboardingFormBankDocumentRequest = ({
  file,
  fileType,
  fileName,
  onboardingFormId,
  applicationId,
  associatedFiles,
}) => createAction({
  type: PATCH_ONBOARDING_FORM_BANK_DOCUMENT_F_REQUEST,
  id: onboardingFormId,
  values: {
    file,
    fileType,
    fileName,
    applicationId,
    associatedFiles,
  },
})

export default patchOnboardingFormBankDocumentRequest
