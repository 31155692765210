import removeUndefined from 'utilities/remove/removeUndefined'

const backendBankAccountModel = ({ values }) => {
  const {
    name,
    bankCode,
    accountNumber,
    accountType,
  } = values

  return removeUndefined({
    name,
    bank_code: bankCode,
    account_number: accountNumber,
    account_type: accountType,
  })
}

export default backendBankAccountModel
