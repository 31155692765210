import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import get from 'lodash/get'

const getDashboardServiceHost = () => {
  const platform = getCurrentPlatform()

  const {
    dashboardServiceV2 = false,
    credentialsHost,
    credentialsHostV2,
  } = platform

  return dashboardServiceV2 ? credentialsHostV2 : credentialsHost
}

export default getDashboardServiceHost
