import React, { Component } from 'react'
import { connect } from 'react-redux'
import SanctionsScreeningReport from './SanctionsScreeningReport'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import map from 'lodash/map'
import toString from 'lodash/toString'
import join from 'lodash/join'

import {
  ALSO_KNOWN_AS,
  DATA_SOURCE,
  EMAIL_ADDRESS,
  LAST_UPDATED,
  NATIONAL_IDS,
  POTENTIAL_MATCH_AKA,
  POTENTIAL_MATCH_DETAILS,
  POTENTIAL_MATCH_LEVEL,
  TAX_ID_NUMBER,
  WEBSITE,
  TRUE,
  FALSE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})
  const matches = get(report, 'raw.PotentialMatchers', [])

  const sanctionsData = map(matches, (match) => {
    const [
      name,
      dataSource,
      lastUpdatedDate,
      potentialMatchLevel,
      potentialMatchDetails,
      potentialMatchAka,
      aka,
      nationalIds,
      taxIdNumber,
      website,
      email,
      addresses,
    ] = getMany(match, [
      'Name.ListItemName',
      'DataSource',
      'LastUpdatedDate',
      'Summary.PotentialMatchLevel',
      'Summary.PotentialMatchDetails',
      'Summary.PotentialMatchAka',
      'AlsoKnownAs',
      'NationalIds',
      'TaxIdNumber',
      'Website',
      'EmailAddress',
      'Addresses',
    ])

    const addressData = map(addresses, (address, index) => {
      return { label: `Address #${index + 1}`, value: <Address address={address} /> }
    })

    return {
      title: name,
      columns: [
        {
          type: 'data',
          data: [
            { label: DATA_SOURCE, value: dataSource },
            { label: LAST_UPDATED, value: formatDate({ date: lastUpdatedDate }) },
            { label: POTENTIAL_MATCH_LEVEL, value: toString(potentialMatchLevel) },
            { label: POTENTIAL_MATCH_DETAILS, value: join(potentialMatchDetails, ', ') },
          ],
        },
        {
          type: 'data',
          data: [
            { label: POTENTIAL_MATCH_AKA, value: potentialMatchAka ? TRUE : FALSE },
            { label: ALSO_KNOWN_AS, value: join(aka, ', ') },
            { label: NATIONAL_IDS, value: join(nationalIds, ', ') },
            { label: TAX_ID_NUMBER, value: taxIdNumber },
          ],
        },
        {
          type: 'data',
          data: [
            ...[
              { label: WEBSITE, value: website },
              { label: EMAIL_ADDRESS, value: email },
            ], addressData,
          ],
        },
      ],
    }
  })

  return {
    credentials,
    sanctionsData,
  }
}

class SanctionsScreeningReportC extends Component {
  render() {
    return (
      <SanctionsScreeningReport
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SanctionsScreeningReportC)
