import createAction from 'utilities/actions/createAction'
import { GET_SETTLEMENT_FUNDING_TRANSFERS_F_REQUEST } from 'constants/flowConstants'

const getSettlementFundingTransfersRequest = ({
  settlementId,
  credentials,
}) => {
  return createAction({
    type: GET_SETTLEMENT_FUNDING_TRANSFERS_F_REQUEST,
    values: {
      settlementId,
    },
    credentials,
  })
}

export default getSettlementFundingTransfersRequest
