import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import ApplicationSettingForm from 'components/Customer/Forms/ApplicationSettingForm/ApplicationSettingForm'
import { APPLICATION_SETTING_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(APPLICATION_SETTING_FORM)
  const processingEnabled = formSelector(state, 'processingEnabled')
  const settlementsEnabled = formSelector(state, 'settlementsEnabled')
  const actOnDecisions = formSelector(state, 'actOnDecisions')

  return {
    processingEnabled,
    settlementsEnabled,
    actOnDecisions,
  }
}

class ApplicationSettingFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      record,
    } = this.props

    const {
      id,
      processingEnabled,
      settlementsEnabled,
      settlementFundingIdentifier,
    } = record

    const actOnDecisions = get(record, 'underwritingDecisionSettings.actOnDecisions', false)

    initialize({
      id,
      processingEnabled,
      settlementsEnabled,
      settlementFundingIdentifier,
      actOnDecisions,
    })
  }

  render() {
    return (
      <ApplicationSettingForm {...this.props} />
    )
  }
}

ApplicationSettingFormC.propTypes = {
  initialize: PropTypes.func,
  record: PropTypes.object,
}

// TODO: look into why redux form is defined in container instead of view
export default connect(mapStateToProps)(reduxForm({
  form: APPLICATION_SETTING_FORM,
})(ApplicationSettingFormC))
