import React, { Component } from 'react'
import { connect } from 'react-redux'
import RecipientUnderwritingReview from './RecipientUnderwritingReview'
import Address from 'components/Customer/Shared/Display/Address/Address'
import getUnderwritingSubjectUnmaskAPI from 'api/finix/get/getUnderwritingSubjectUnmaskAPI'
import DisplayUnmaskC from 'components/Customer/Shared/Display/DisplayUnmaskedItem/DisplayUnmaskedItemC'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import hasPermission from 'utilities/hasPermission'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import formatPhone from 'utilities/forms/format/formatPhone'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import { PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION } from 'constants/permissionConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

import {
  getIdentityFilesWithMerchantData,
  getUnderwritingReviewSelector,
} from 'state-layer/selectors'

import {
  NAME,
  EMAIL,
  PHONE_NUMBER,
  ADDRESS,
  BUSINESS,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
  BUSINESS_ADDRESS,
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_TYPE,
  ROUTING_NUMBER,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  ACCOUNT_NUMBER,
  CURRENCY,
} from 'constants/language/languageConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const showUnmaskAction = hasPermission(state, PERSONAL_IDENTIFIABLE_INFORMATION_READ_PERMISSION) && isRolePlatform({ credentials })

  const [
    reviewId,
    subjectId,
    entityId,
  ] = getMany(props, [
    'reviewId',
    'subjectId',
    'entityId',
  ])

  const review = getUnderwritingReviewSelector(state, reviewId)
  const identityMerchants = values(get(review, 'entity.merchants'))
  const identityFilesSelector = () => getIdentityFilesWithMerchantData(state, reviewId)
  const runSubject = get(review, 'run.subject', {})
  const entity = get(review, 'entity', {})
  const reviewCountry = get(review, 'country')
  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[reviewCountry]
  const showBusinessInformation = get(review, 'recipientType') === BUSINESS
  const showBankAccountInformation = !isEmpty(get(runSubject, 'bank_accounts'))
  const recipientBusinessDescription = get(entity, 'businessDescription')
  const businessDescriptionString = recipientBusinessDescription ? `Description: ${recipientBusinessDescription}` : null

  const [
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientPhone,
    recipientAddressLine1,
    recipientAddressLine2,
    recipientAddressCity,
    recipientAddressState,
    recipientAddressPostalCode,
    recipientCountry,
    recipientReports,
    recipientDoingBusinessAs,
    recipientBusinessName,
    recipientBusinessPhone,
    recipientBusinessAddressLine1,
    recipientBusinessAddressLine2,
    recipientBusinessAddressCity,
    recipientBusinessAddressState,
    recipientBusinessAddressPostalCode,
    recipientBusinessCountry,
    recipientBusinessReports,
    bankAccountReport,
  ] = getMany(runSubject, [
    'owners[0].first_name',
    'owners[0].last_name',
    'owners[0].email',
    'owners[0].phone',
    'owners[0].address.line1',
    'owners[0].address.line2',
    'owners[0].address.city',
    'owners[0].address.region',
    'owners[0].address.postal_code',
    'owners[0].address.country',
    'owners[0].reports[sanctions]',
    'business.doing_business_as',
    'business.name',
    'business.phone',
    'business.address.line1',
    'business.address.line2',
    'business.address.city',
    'business.address.region',
    'business.address.postal_code',
    'business.address.country',
    'business.reports[sanctions]',
    'bank_accounts[0].reports.bank_accounts',
  ])

  const formattedRecipientAddress = {
    line1: recipientAddressLine1,
    line2: recipientAddressLine2,
    city: recipientAddressCity,
    region: recipientAddressState,
    postalCode: recipientAddressPostalCode,
    country: recipientCountry,
  }

  const formattedRecipientBusinessAddress = {
    line1: recipientBusinessAddressLine1,
    line2: recipientBusinessAddressLine2,
    city: recipientBusinessAddressCity,
    region: recipientBusinessAddressState,
    postalCode: recipientBusinessAddressPostalCode,
    country: recipientBusinessCountry,
  }

  const recipientInformationDataSection = [
    {
      type: 'data',
      data: [
        { label: NAME, value: `${recipientFirstName} ${recipientLastName}` },
        { label: EMAIL, value: recipientEmail },
      ],
    },
    {
      type: 'data',
      data: [
        { label: PHONE_NUMBER, value: formatPhone(recipientPhone) },
      ],
    },
    {
      type: 'data',
      data: [
        { label: ADDRESS, value: <Address address={formattedRecipientAddress} /> },
      ],
    },
  ]

  const businessInformationDataSection = [
    {
      type: 'data',
      data: [
        { label: BUSINESS_NAME, value: recipientBusinessName },
        { label: DOING_BUSINESS_AS, value: recipientDoingBusinessAs },
      ],
    },
    {
      type: 'data',
      data: [
        { label: BUSINESS_PHONE, value: formatPhone(recipientBusinessPhone) },
      ],
    },
    {
      type: 'data',
      data: [
        { label: BUSINESS_ADDRESS, value: <Address address={formattedRecipientBusinessAddress} /> },
      ],
    },
  ]

  return {
    isFetching,
    reviewId,
    subjectId,
    entityId,
    recipientInformationDataSection,
    businessInformationDataSection,
    recipientReports,
    recipientBusinessReports,
    runSubject,
    entity,
    identityFilesSelector,
    identityMerchants,
    showUnmaskAction,
    businessDescriptionString,
    showBusinessInformation,
    currency,
    bankAccountReport,
    showBankAccountInformation,
  }
}

class RecipientUnderwritingReviewC extends Component {
  render() {
    const {
      runSubject,
      showUnmaskAction,
      subjectId,
      reviewCountry,
      currency,
    } = this.props

    const [
      accountNumber,
      routingNumber,
      bankAccountName,
      bankAccountType,
      transitNumber,
      institutionNumber,
    ] = getMany(runSubject, [
      'bank_accounts[0].account_number',
      'bank_accounts[0].bank_code',
      'bank_accounts[0].name',
      'bank_accounts[0].account_type',
      'bank_accounts[0].transit_number',
      'bank_accounts[0].institution_number',
    ])

    const bankAccountInformationDataSection = convertPageSectionDataToV2([
      { label: ACCOUNT_HOLDER_NAME, value: bankAccountName },
      { label: ACCOUNT_TYPE, value: snakeCaseToTitleCase({ key: bankAccountType }) },
      {
        label: ROUTING_NUMBER,
        value: routingNumber,
        condition: reviewCountry === USA,
      },
      {
        label: TRANSIT_NUMBER,
        value: transitNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: INSTITUTION_NUMBER,
        value: institutionNumber,
        condition: reviewCountry === CAN,
      },
      {
        label: ACCOUNT_NUMBER,
        value: (showUnmaskAction && accountNumber) ? (
          <DisplayUnmaskC
            id={subjectId}
            api={getUnderwritingSubjectUnmaskAPI}
            feKey='bank_account_number'
            beKey='bank_accounts[0].account_number'
            defaultValue={accountNumber}
          />
        ) : accountNumber,
      },
      {
        label: CURRENCY,
        value: currency,
      },
    ])

    return (
      <RecipientUnderwritingReview
        {...this.props}
        bankAccountInformationDataSection={bankAccountInformationDataSection}
      />
    )
  }
}

export default connect(mapStateToProps)(RecipientUnderwritingReviewC)
