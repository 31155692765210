import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTIONS } from 'constants/apiConstants'

const postSubscriptionAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    path: SUBSCRIPTIONS,
    values,
    credentials,
    meta,
  })
}

export default postSubscriptionAPI
