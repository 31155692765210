import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAPILogSelector } from 'state-layer/selectors'
import APILog from './APILog'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getAPILogRequest from 'utilities/actions/get/getAPILogRequest'
import getMany from 'utilities/get/getMany'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { FETCHING } from 'constants/reducerConstants'
import { httpCodeStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import split from 'lodash/split'
import size from 'lodash/size'
import includes from 'lodash/includes'

import {
  API_LOG,
  HTTP_STATUS,
  TIMESTAMP,
  IP_ADDRESS,
  REQUEST_PATH,
  REQUEST_ID,
  REQUESTING_USER_ID,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `apiLogsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const apiLogId = get(props, 'params.apiLogId')
  const apiLog = getAPILogSelector(state, apiLogId)

  const [
    httpStatusCode,
    displayCreatedAt,
    httpMethod,
    requestPath,
    requestingUser,
    ipAddress,
    requestId,
    requestHeaders,
    responseHeaders,
    requestBody,
    responseBody,
  ] = getMany(apiLog, [
    'httpStatusCode',
    'displayCreatedAt',
    'httpMethod',
    'apiPath',
    'requestingUser',
    'ipAddress',
    'requestId',
    'requestContentHeaders',
    'responseContentHeaders',
    'requestContentBody',
    'responseContentBody',
  ])

  let displayRequestPath = requestPath
  const hasQueries = includes(requestPath, '?')
  const hasMultipleLevels = hasQueries || size(split(requestPath?.substring(1), '/')) > 1

  if (hasMultipleLevels) {
    const normalizedRequestPath = get(split(requestPath?.substring(1), '/'), '[0]')
    const truncatedRequestPath = hasQueries ? get(split(normalizedRequestPath.substring(1), '?'), '[0]') : normalizedRequestPath
    displayRequestPath = `${httpMethod} /${truncatedRequestPath}...`
  }

  const displayStatusCodeValue = (
    <ColorcodedStatus
      value={httpStatusCode?.length > 0 ? httpStatusCode.charAt(0) : ''}
      data={httpCodeStatusMap}
      icon='circle'
      customValue={httpStatusCode}
    />
  )

  const headerData = {
    resource: {
      label: API_LOG,
      id: apiLogId,
    },
    items: [
      {
        value: <h1 title={hasMultipleLevels ? requestPath : ''}>{displayRequestPath}</h1>,
      },
      {
        label: HTTP_STATUS,
        value: displayStatusCodeValue,
      },
      {
        label: TIMESTAMP,
        value: displayCreatedAt,
      },
      {
        label: REQUEST_ID,
        value: requestId,
      },
    ],
  }

  const detailsSectionData = convertPageSectionDataToV2([
    {
      label: REQUEST_PATH,
      value: requestPath,
    },
    {
      label: REQUESTING_USER_ID,
      value: requestingUser,
    },
    {
      label: IP_ADDRESS,
      value: ipAddress,
    },
  ])

  const requestHeadersSectionData = convertPageSectionDataToV2(map(requestHeaders, (value, label) => { return { label, value } }))
  const responseHeadersSectionData = convertPageSectionDataToV2(map(responseHeaders, (value, label) => { return { label, value } }))

  return {
    isFetching,
    apiLogId,
    apiLog,
    credentials,
    headerData,
    detailsSectionData,
    requestHeadersSectionData,
    responseHeadersSectionData,
    requestBody,
    responseBody,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAPILog: ({ apiLogId, credentials }) => { dispatch(getAPILogRequest({ apiLogId, credentials })) },
  }
}

class APILogC extends Component {
  componentDidMount() {
    const {
      apiLogId,
      getAPILog,
      credentials,
    } = this.props

    if (apiLogId) {
      getAPILog({ apiLogId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiLogId, getAPILog } = this.props
    const { apiLogId: prevAPILogId } = prevProps

    if (apiLogId && apiLogId !== prevAPILogId) {
      getAPILog({ apiLogId })
    }
  }

  render() {
    return (
      <APILog
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APILogC)
