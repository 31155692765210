import './DatePickerS.scss'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import Cleave from 'cleave.js/react'
import { DATE_PICKER_TIME_FORMAT } from 'constants/timeConstants'

const DatePicker = ({
  input,
  meta: {
    error,
    touched,
  },
  placeholderText = 'mm/dd/yyyy',
  minDate,
  maxDate,
  disabled,
  dateFormat,
  showTimeSelect,
  autofill,
}) => {
  const { value } = input
  const valueDate = value ? new Date(value) : undefined

  const cleaveOptions = dateFormat === DATE_PICKER_TIME_FORMAT ?
    {
      delimiters: ['/', '/', ' ', ':', ' '],
      blocks: [2, 2, 4, 2, 2, 2],
    } : {
      date: true,
      datePattern: ['m', 'd', 'Y'],
    }

  return (
    <div className='DatePicker'>
      <i className='far fa-calendar-alt' />

      <ReactDatePicker
        {...input}
        placeholderText={placeholderText}
        selected={valueDate}
        disabledKeyboardNavigation
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        autoComplete={!autofill ? 'off' : ''}
        customInput={(
          <Cleave
            options={cleaveOptions}
          />
          )}
      />

      { touched && error && <span className='error'>{error}</span> }
    </div>
  )
}

DatePicker.propTypes = {}

export default DatePicker
