import './OnboardingFormLinkS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { COPY_LINK } from 'constants/language/languageConstants'

const OnboardingFormLink = ({
  onboardingFormLink = '',
  copyToClipboard = () => {},
  expirationTime = '',
}) => {
  return (
    <div className='OnboardingFormLink'>
      <div className='modal-body'>
        <div>The link expires in {expirationTime} days and will not be available once you exit. You will need to generate a new link if the onboarding form requires additional completion time.</div>
        <div className='copy-url-container flex items-center'>
          <div className='url'>{onboardingFormLink}</div>
        </div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={() => copyToClipboard(onboardingFormLink)} label={COPY_LINK} />
      </div>
    </div>
  )
}

OnboardingFormLink.propTypes = {
  onboardingFormLink: PropTypes.string,
  copyToClipboard: PropTypes.func,
  expirationTime: PropTypes.string,
}

export default OnboardingFormLink
