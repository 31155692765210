import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORMS } from 'constants/apiConstants'

const getAccessFormsAPI = ({ credentials, meta, queries }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_ACCESS_FORMS,
    meta,
    queries,
  })
}

export default getAccessFormsAPI
