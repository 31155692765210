import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dashboards from './Dashboards'
import { getCredentialsSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import { CREDENTIALS } from 'constants/linkConstants'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import redirectRequest from 'utilities/actions/redirectRequest'
import { getPathByCredential } from 'utilities/validate/checkCredentialFeatures'
import get from 'lodash/get'
import includes from 'lodash/includes'
import { DASHBOARDS_ENVIRONMENT_QUICK_FILTER } from 'constants/quickFilterConstants'

const initialQueries = {
  page: 1,
  limit: 20,
}

const quickFilters = [
  DASHBOARDS_ENVIRONMENT_QUICK_FILTER,
]

const mapStateToProps = (state) => {
  const credentials = getCredentialsSelector(state)
  const isFetching = get(state, `credentialsR.${FETCHING}`)
  // firstPage based on DS_V1 response and oneRecord based on DS_V2 response
  const firstPage = includes(get(state, `linksR.${CREDENTIALS}.self`), 'page=1')
  const oneRecord = get(state, `linksR.${CREDENTIALS}.page.count`) === 1
  const hasFilterApplied = !!get(state, 'tableFiltersR.env')

  const flowValues = {
    dashboardId: getCurrentDashboardId(),
  }

  return {
    isFetching,
    credentials,
    initialQueries,
    flowValues,
    firstPage,
    oneDashboard: oneRecord && !hasFilterApplied,
    quickFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirectPathAction: path => dispatch(redirectRequest({ path })),
    clearCurrentUser: () => dispatch({ type: 'CLEAR_CURRENT_USER_F_SUCCESS' }),
  }
}

class DashboardsC extends Component {
  componentDidMount() {
    const {
      clearCurrentUser,
    } = this.props

    clearCurrentUser()
  }

  componentDidUpdate(prevProps) {
    const { credentials, redirectPathAction, firstPage, oneDashboard } = this.props
    const { credentials: prevCredentials } = prevProps

    const credentialsIds = Object.keys(credentials)
    const prevCredentialsIds = Object.keys(prevCredentials)

    const diffCredential = credentialsIds.length !== prevCredentialsIds.length
      || credentialsIds[0] !== prevCredentialsIds[0]

    if (credentialsIds.length === 1 && diffCredential && (firstPage || oneDashboard)) {
      const credentialsId = credentialsIds[0]
      const credential = get(credentials, credentialsId)

      redirectPathAction(getPathByCredential(credential))
    }
  }

  render () {
    return (
      <Dashboards {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsC)
