import getFilesAPI from 'api/dashboard/get/getFilesAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendFilesModel from 'utilities/create/models/frontendFilesModel'
import get from 'lodash/get'
import co from 'co'

function * getUnderwritingIdentityFilesO ({
  id,
  credentials,
}) {
  const identityFilesResponse = yield getFilesAPI({
    queries: {
      identity_id: id,
      limit: 1000,
    },
    credentials,
  })

  const identityFiles = get(identityFilesResponse, 'data._embedded.files', [])

  const identity = {
    id,
    files: frontendFilesModel({ data: identityFiles }),
  }

  return createSuccessResponse({
    data: [identity],
  })
}

export default co.wrap(getUnderwritingIdentityFilesO)
