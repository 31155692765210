import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SelectSenderForCreatePaymentFormC from 'components/Customer/Forms/SelectSenderForCreatePaymentForm/SelectSenderForCreatePaymentFormC'
import submitSelectSenderForCreatePaymentForm from 'utilities/submit/submitSelectSenderForCreatePaymentForm'

import {
  SELECT_A_MERCHANT_TITLE,
  SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_FOR,
  SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_PLAN_FOR,
  SELECT_THE_MERCHANT_ACCOUNT_TO_PROCESS_PAYMENT_FOR,
} from 'constants/language/languageConstants'

const SelectSenderForCreatePaymentModal = ({
  isCreateSubscriptionFlow = false,
  isCreateSubscriptionPlanFlow = false,
}) => {
  return (
    <GenericModal
      title={SELECT_A_MERCHANT_TITLE}
      subTitle={
        isCreateSubscriptionFlow
          ? SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_FOR
          : isCreateSubscriptionPlanFlow
            ? SELECT_THE_MERCHANT_ACCOUNT_TO_CREATE_SUBSCRIPTION_PLAN_FOR
            : SELECT_THE_MERCHANT_ACCOUNT_TO_PROCESS_PAYMENT_FOR
      }
      className='SelectSenderForCreatePaymentModal'
      Component={SelectSenderForCreatePaymentFormC}
      onSubmit={submitSelectSenderForCreatePaymentForm}
      isCreateSubscriptionFlow={isCreateSubscriptionFlow}
      isCreateSubscriptionPlanFlow={isCreateSubscriptionPlanFlow}
    />
  )
}

SelectSenderForCreatePaymentModal.propTypes = {
  isCreateSubscriptionFlow: PropTypes.bool,
  isCreateSubscriptionPlanFlow: PropTypes.bool,
}

export default SelectSenderForCreatePaymentModal
