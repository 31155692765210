import paymentsAPI from 'utilities/api/paymentsAPI'
import { REPORTS } from 'constants/apiConstants'
import { REPORTS as REPORTS_SERVICE } from 'constants/pathConstants'

const getReportsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: REPORTS,
    queries,
    credentials,
    service: REPORTS_SERVICE,
  })
}

export default getReportsAPI
