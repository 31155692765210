export const ACCESS_DENIED = 'ACCESS_DENIED'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const UPDATE_FLOW_STATUS = 'UPDATE_FLOW_STATUS'
export const RESET_FLOW_STATUS = 'RESET_FLOW_STATUS'
export const UPDATE_ACTION_STATUS = 'UPDATE_ACTION_STATUS'
export const UPDATE_ORCHESTRATION_STATUS = 'UPDATE_ORCHESTRATION_STATUS'
export const REMOVE_ENTRIES = 'REMOVE_ENTRIES'
export const VALUES_PATH = 'payload.values'
export const NEW_VALUES_PATH = 'payload.newValues'
export const REFRESH_TABLE = 'REFRESH_TABLE'
export const TOGGLE_PAGINATION = 'TOGGLE_PAGINATION'
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS'
export const SHOW_SUPPORT_DROPDOWN = 'SHOW_SUPPORT_DROPDOWN'
export const SHOW_DOCUMENTATION = 'SHOW_DOCUMENTATION'
export const CLICK_ON_NOTIFICATION_ACTION = 'CLICK_ON_NOTIFICATION'
export const SHOW_PREVIEW_PANEL = 'SHOW_PREVIEW_PANEL'
export const HIDE_PREVIEW_PANEL = 'HIDE_PREVIEW_PANEL'
export const CLEAR_TABLE_IDS = 'CLEAR_TABLE_IDS'
