import React from 'react'
import { Field } from 'redux-form'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import Checkbox from 'components/Shared/Inputs/Checkbox/Checkbox'
import DatePicker from 'components/Shared/Inputs/DatePicker/DatePicker'
import DateRadioButtons from 'components/Shared/Inputs/DateRadioButtons/DateRadioButtons'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import TagsField from 'components/Shared/Inputs/TagsField/TagsField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import MultiSelectField from 'components/Shared/Inputs/MultiSelectField/MultiSelectField'
import selectOption from 'utilities/forms/selectOption'
import Address from 'components/Shared/Inputs/Address/Address'
import { GENERIC_FORM } from 'constants/formConstants'
import { PERCENTAGE_ICON } from 'constants/iconConstants'

import {
  CREATE_IDENTITY_ADDRESS_LINE1_TOOLTIP,
} from 'constants/language/languageConstants'

// category constants
const FORM = 'form'
const INPUT = 'input'

export const components = [
  {
    name: 'Address Field Set',
    Component: AddressFieldset,
    props: {
      name: 'businessAddress',
      label: 'Business Address',
    },
    category: FORM,
  },
  {
    name: 'Address Autocomplete',
    Component: Address,
    props: {
      form: GENERIC_FORM,
      tooltips: { addressLine1: CREATE_IDENTITY_ADDRESS_LINE1_TOOLTIP },
      label: 'Business Address',
    },
  },
  {
    name: 'Date Field Set',
    Component: DateFieldset,
    props: {},
    category: FORM,
  },
  {
    name: 'MultiSelectInput',
    Component: MultiSelectField,
    props: {
      name: 'color',
      label: 'Color',
      tooltip: 'Hi. Im a tooltip... I like to tip tools over.',
      className: 'unique-class-name',
      options: [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630' },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
      ],
      // NOTE: Do NOT use this ugly style object, this is being used here as a reference for all the things that you could theoretically do
      styles: {
        // input styling
        control: (styles) => ({
          ...styles,
          backgroundColor: '#FAFAFA',
          ':hover': {
            cursor: 'pointer',
          },
        }),
        // dropdown options styling
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
                ? data.color
                : isFocused
                  ? 'pink'
                  : undefined,
            color: isDisabled
              ? '#ccc'
              : isSelected
                ? 'black'
                : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? data.color
                  : 'lightblue'
                : undefined,
            },
            ':hover': {
              cursor: isDisabled ? '' : 'pointer',
            },
          }
        },
        // selected option pill styling
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: data.color,
          }
        },
        // selected option text styling
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: 'white',
        }),
        // selected option remove button styling
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: 'white',
          ':hover': {
            backgroundColor: 'red',
            color: 'white',
            cursor: 'pointer',
          },
        }),
      },
    },
    category: FORM,
  },
  {
    name: 'Amount Input',
    Component: AmountField,
    props: {
      name: 'amount',
      label: 'Amount',
      placeholder: '0.00',
    },
    category: INPUT,
  },
  {
    name: 'Amount Input With Tooltip',
    Component: AmountField,
    props: {
      name: 'amount-tooltip',
      label: 'Amount',
      placeholder: '0.00',
      tooltip: 'This is a tooltip',
    },
    category: INPUT,
  },
  {
    name: 'Unchecked Checkbox',
    Component: Checkbox,
    props: {},
    category: 'input',
  },
  {
    name: 'Checked Checkbox',
    Component: Checkbox,
    props: {
      checked: true,
    },
    category: INPUT,
  },
  {
    name: 'Date Picker',
    Component: DatePicker,
    props: {
      meta: { error: null },
      input: { value: '' },
      component: DatePicker,
      operands: ['on', 'between'],
      field: 'start_date',
      label: 'Start Date',
    },
    category: INPUT,
  },
  {
    name: 'Date Radio Buttons',
    Component: DateRadioButtons,
    props: {
      input: { value: 'today' },
    },
    category: INPUT,
  },
  {
    name: 'File Uploader',
    Component: FileUploader,
    props: {
      meta: { error: null },
      input: { value: '', onChange: '' },
    },
    category: INPUT,
  },
  {
    name: 'Percentage Input',
    Component: PercentageField,
    props: {
      name: 'percentage',
      label: 'Percentage',
    },
    category: INPUT,
  },
  {
    name: 'Percentage Input With Tooltip',
    Component: PercentageField,
    props: {
      name: 'percentage-tooltip',
      label: 'Percentage Tooltip',
      tooltip: 'This is a tooltip',
    },
    category: INPUT,
  },
  {
    name: 'Radio Field',
    Component: RadioButton,
    props: {
      options: [{ value: 'button', label: 'label' }, { value: 'button', label: 'label2' }],
      name: 'button',
      input: { name: 'input' },
    },
    category: INPUT,
  },
  {
    name: 'Select Field',
    Component: SelectField,
    props: {
      meta: {},
      input: { name: 'input' },
      options: [
        selectOption('Select Month', ''),
        selectOption('1 - January', '1'),
        selectOption('2 - February', '2'),
        selectOption('3 - March', '3'),
        selectOption('4 - April', '4'),
        selectOption('5 - May', '5'),
        selectOption('6 - June', '6'),
        selectOption('7 - July', '7'),
        selectOption('8 - August', '8'),
        selectOption('9 - September', '9'),
        selectOption('10 - October', '10'),
        selectOption('11 - November', '11'),
        selectOption('12 - December', '12'),
      ],
      operands: 'eq',
    },
    category: INPUT,
  },
  {
    name: 'Tags Field',
    Component: TagsField,
    props: {},
    category: 'input',
  },
  {
    name: 'Text Area Field',
    Component: Field,
    props: {
      component: TextAreaField,
      name: 'text-area-field',
    },
    category: INPUT,
  },
  {
    name: 'Toggle Field Active',
    Component: ToggleSwitchC,
    props: {
      name: 'toggleButtonChecked',
      label: 'Toggle Button',
      checked: true,
      form: { GENERIC_FORM },
    },
    category: INPUT,
  },
  {
    name: 'Toggle Field Inactive',
    Component: ToggleSwitchC,
    props: {
      name: 'toggleButtonChecked',
      label: 'Toggle Button',
      checked: false,
      form: { GENERIC_FORM },
    },
    category: INPUT,
  },
]
