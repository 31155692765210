// categories
// WARNING: do NOT change categories without explicit permission from FE lead and Product Managers
import get from "lodash/get";

export const API = 'API'
export const PAGE_VIEW = 'Page View'
export const USER_ACTION = 'User Action'

// events
export const CREATE_ADJUSTMENT = 'Create Adjustment'
export const CREATE_APPLICATION_IDENTITY = 'Create Application Identity'
export const CREATE_APPLICATION = 'Create Application'
export const CREATE_IDENTITY = 'Create Identity'
export const EDIT_IDENTITY = 'Edit Identity'
export const CREATE_ASSOCIATED_IDENTITY = 'Create Associated Identity'
export const EDIT_ASSOCIATED_IDENTITY = 'Edit Associated Identity'
export const CREATE_MERCHANT_IDENTITY = 'Create Merchant Identity'
export const EDIT_MERCHANT_IDENTITY = 'Edit Merchant Identity'
export const CREATE_USER = 'Create User'
export const INVITE_USER = 'Invite User'
export const REMOVE_SETTLEMENT_ENTRY = 'Remove Settlement Entry'
export const CREATE_REFUND = 'Create Refund'
export const UPLOAD_DISPUTE_EVIDENCE = 'Upload Dispute Evidence'
export const CREATE_MERCHANT_VERIFICATION = 'Create Merchant Verification'
export const CREATE_APPLICATION_FEE_PROFILE = 'Create Application Fee Profile'
export const CREATE_MERCHANT_FEE_PROFILE = 'Create Merchant Fee Profile'
export const EDIT_MERCHANT = 'Edit Merchant'
export const ENABLE_PROVISION_MERCHANT = 'Enable Provision Merchant'
export const GENERATE_NEW_ONBOARDING_FORM_LINK = 'Generate New Onboarding Form Link'
export const CREATE_IDENTITY_VERIFICATION = 'Create Identity Verification'
export const CREATE_IDENTITY_PAYMENT_INSTRUMENT = 'Create Identity Payment Instrument'
export const CREATE_IDENTITY_PAYMENT_INSTRUMENT_MAPPING = 'Create Identity Payment Instrument Mapping'
export const UPDATE_APPLICATION_SETTINGS = 'Update Application Settings'
export const UPDATE_APPLICATION_UNDERWRITING_SETTINGS = 'Update Application Underwriting Settings'
export const EXPORT_CSV = 'Export CSV'
export const EXPORT_ALL_CSV = 'Export All CSV'
export const ADVANCED_EXPORT_CSV = 'Advanced Export CSV'
export const ADD_PROCESSORS = 'Add Processor'
export const SEARCH_USER = 'Search User'
export const SEND_EMAIL = 'Send Email'
export const SEND_WELCOME_EMAIL = 'Send Welcome Email after team member creation'
export const PEND_SETTLEMENTS = 'Pend Settlements'
export const REJECT_SETTLEMENTS = 'Reject Settlements'
export const APPROVE_SETTLEMENTS = 'Approve Settlements'
export const APPROVE_ALL_SETTLEMENTS = 'Approve All Settlements'
export const PEND_IDENTITIES = 'Pend Identities'
export const REJECT_IDENTITIES = 'Reject Identities'
export const APPROVE_IDENTITIES = 'Approve Identities'
export const PEND_MERCHANTS = 'Pend Merchants'
export const REJECT_MERCHANTS = 'Reject Merchants'
export const APPROVE_MERCHANTS = 'Approve Merchants'
export const PATCH_REVIEW_QUEUE_ITEM = 'Patch Review Queue Item'
export const DOWNLOAD_REPORT = 'Download Report'
export const UPDATE_TAGS = 'Update Tags'
export const ADD_TABLE_FILTER = 'Add Table Filter'
export const REMOVE_TABLE_FILTER = 'Remove Table Filter'
export const GLOBAL_SEARCH = 'Global Search'
export const TABLE_LOAD_MORE = 'Table Load More'
export const UPDATE_TRANSFERS = 'Update Transfers'
export const UPDATE_VERIFICATIONS = 'Update Verifications'
export const RESEND_TRANSFER = 'Resend Transfer'
export const RISK_PROFILE = 'Risk Profile'
export const MERCHANT_RISK_PROFILE = 'Merchant Risk Profile'
export const CLOSE_SETTLEMENT = 'Close Settlement'
export const CLOSE_MERCHANT_SETTLEMENTS = 'Close Merchant Settlements'
export const IDENTITY_UPLOADER = 'Identity Uploader'
export const CREATE_PAYOUT_PLAN = 'Create Payout Plan'
export const CREATE_API_KEY = 'Create API Key'
export const UPDATE_USER_PERMISSIONS = 'Update User Permissions'
export const UPDATE_USER = 'Update User'
export const UPDATE_ALL_USER_PERMISSIONS = 'Update All User Permissions'
export const EDIT_SYSTEM_CONFIG = 'Edit System Configuration'
export const EDIT_APPLICATION_CONFIG = 'Edit Application Configuration'
export const APPLICATION_CREATED = 'Application Created'
export const TOGGLE_TABLE_SCROLL_VIEW = 'Toggle Table Scroll View'
export const UPDATE_TABLE_LIMIT = 'Update Table Limit'
export const NAVIGATE_TABLE_PAGE = 'Navigate Table Page'
export const OPEN_NOTIFICATIONS = 'Open Notifications'
export const OPEN_SUPPORT_DROPDOWN = 'Open Support Dropdown'
export const SHOW_DASHBOARD_TUTORIAL = 'Show Dashboard Tutorial'
export const LINK_TO_FINIX_DOCUMENTATION = 'Link To Finix Documentation'
export const CLICK_ON_NOTIFICATION = 'Click on Notification'
export const CREATE_VIRTUAL_TERMINAL_PAYMENT = 'Create Payment'
export const CREATE_VIRTUAL_TERMINAL_PAYOUT = 'Create Virtual Terminal Payout'
export const CREATE_VIRTUAL_TERMINAL_AUTHORIZATION = 'Create Virtual Terminal Authorization'
export const CREATE_BALANCE_TRANSFER = 'Create Balance Transfer'
export const CREATE_SUBSCRIPTION_SCHEDULE = 'Create Subscription Schedule'
export const CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT = 'Create Subscription Schedule Amount'
export const EDIT_SUBSCRIPTION_SCHEDULE_AMOUNT = 'Edit Subscription Schedule Amount'
export const EDIT_PAYOUT_APPLICATION_CONFIGS = 'Edit Payout Application Configs'
export const DELETE_SUBSCRIPTION_SCHEDULE_AMOUNT = 'Delete Subscription Schedule Amount'
export const CREATE_SUBSCRIPTION_ENROLLMENT = 'Create Subscription Enrollment'
export const CREATE_SUBSCRIPTION_SCHEDULE_ENROLLMENT = 'Create Subscription Schedule Enrollment'
export const CREATE_TEAM_MEMBER = 'Create Team Member'
export const EDIT_TEAM_MEMBER = 'Edit Team Member'
export const DELETE_SUBSCRIPTION_AMOUNT = 'Delete Subscription Amount'
export const DELETE_SUBSCRIPTION_ENROLLMENTS = 'Delete Subscription Enrollments'
export const CREATE_FEE = 'Create Fee'
export const UPDATE_REVIEW_QUEUE_ITEM = 'Update Review Queue Item'
export const UPDATE_UNDERWRITING_REVIEW_ITEM = 'Update Underwriting Review Item'
export const ASSIGN_REVIEW_QUEUE_ITEM_TO = 'Assign Review Queue Item To'
export const CREATE_WEBHOOK = 'Create Webhook'
export const UPDATE_WEBHOOK = 'Update Webhook'
export const REGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAIN = 'Register Apple Pay Merchant Registration Domain'
export const UNREGISTER_APPLE_PAY_MERCHANT_REGISTRATION_DOMAINS = 'Unregister Apple Pay Merchant Registration Domain(s)'
export const DOWNLOAD_APPLE_PAY_CSR_FILE = 'Download Apple Pay CSR File'
export const UPLOAD_PAYMENT_PROCESSING_CERT = 'Upload Payment Processing Cert'
export const ADD_FILTER_SET = 'Add Filter Set'
export const REMOVE_FILTER_SET = 'Remove Filter Set'
export const UPLOAD_MERCHANT_FILE = 'Upload Merchant File'
export const UPLOAD_MERCHANT_DEVICE_FILE = 'Upload Merchant Device File'
export const UPLOAD_IDENTITY_FILE = 'Upload Identity File'
export const EDIT_COMPLIANCE_FORM_TEMPLATE = 'Edit Compliance Form Template'
export const CREATE_COMPLIANCE_FORM_TEMPLATE = 'Create Compliance Form Template'
export const DUPLICATE_COMPLIANCE_FORM_TEMPLATE = 'Duplicate Compliance Form Template'
export const SUBMIT_DASHBOARD_FEEDBACK = 'Submit Dashboard Feedback'
export const SUBMIT_CONTACT_SUPPORT_FORM = 'Submit Contact Support Form'
export const SUBMIT_CONTACT_SALES_FORM = 'Submit Contact Sales Form'
export const COMPLETE_MERCHANT_BOARDING_STEP = 'Complete Merchant Boarding Step'
export const COMPLETE_MERCHANT_BOARDING = 'Complete Merchant Boarding'
export const EDIT_APPLICATION_COMPLIANCE_TEMPLATE = "Edit Application's Compliance Template"
export const EDIT_PLATFORM_COMPLIANCE_TEMPLATE = "Edit Platform's Compliance Template"
export const TOGGLE_AUTO_GENERATE_COMPLIANCE_TEMPLATE_FORMS = 'Toggle auto-generate compliance template forms'
export const DOWNLOAD_EXPORT = 'Download Export'
export const CREATE_EXPORT = 'Create Export'
export const EDIT_PAYOUT_SETTINGS = 'Edit Payout Settings'
export const UPDATE_NOTIFICATION_SETTINGS = 'Update Notification Settings'
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'Mark all notifications as read.'
export const ATTEST_COMPLIANCE_FORM = 'Attest Compliance Form'
export const ENABLE_DISABLE_PAYMENT_INSTRUMENT = 'Enable/Disable Payment Instrument'
export const ENABLE_WEBHOOK = 'Enable Webhook'
export const DISABLE_WEBHOOK = 'Disable Webhook'
export const CREATE_ONBOARDING_FORM = 'Create Onboarding Form'
export const SUBMIT_SELF_SERVICE_SIGNUP_FORM = 'Submit Self Service Signup Form'
export const SELF_SERVICE_SIGNUP_REQUEST_SUCCESSFUL = 'Self Service Signup Request Successful'
export const SELF_SERVICE_SIGNUP_REQUEST_FAILURE = 'Self Service Signup Request Failure'
export const SELF_SERVICE_EMAIL_VERIFICATION_ATTEMPTED = 'Self Service Email Verification Attempted'
export const SELF_SERVICE_EMAIL_VERIFICATION_SUCCEEDED = 'Self Service Email Verification Succeeded'
export const SELF_SERVICE_EMAIL_VERIFICATION_FAILED = 'Self Service Email Verification Failed'
export const SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_BEFORE_VERIFICATION = 'Self Service Resend Email Attempted Before Verification'
export const SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_AFTER_VERIFICATION_FAILED = 'Self Service Resend Email Attempted After Verification Failed'
export const RESEND_WEBHOOK_UNACCEPTED_EVENTS = 'Resend Webhook Unaccepted Events'
export const SUBMIT_EDIT_DASHBOARD_ACCOUNT_NAME_FORM = 'Submit Edit Dashboard Account Name Form'
export const SEND_DASHBOARD_INVITE_EMAIL = 'Resend Dashboard Invite Email'
export const CREATE_CUSTOM_ROLE = 'Create Custom Role'
export const UPDATE_CUSTOM_ROLE = 'Update Custom Role'
export const CHANGE_PASSWORD = 'Change Password'
export const DEACTIVATE_TEAM_MEMBER = 'Deactivate Team Member'
export const REINSTATE_TEAM_MEMBER = 'Reinstate Team Member'
export const SUBMIT_WELCOME_NAME_CAPTURE = 'Submit Welcome Name Capture'
export const DISABLE_CUSTOM_ROLE = 'Disable Custom Role'
export const CLICKED_ON_SANDBOX_TERMS_OF_SERVICES_URL = 'Clicked on Sandbox Terms of Services URL'
export const CLICKED_ON_PRIVACY_POLICY_URL = 'Clicked on Privacy Policy URL'
export const CHOOSE_SANDBOX_DASHBOARD = 'Choose Sandbox Dashboard'
export const UPDATE_DASHBOARD_CONFIGURATIONS = 'Update Dashboard Configurations'
export const START_LIVE_ACCOUNT_APPLICATION_FORM = 'Start Live Account Application Form'
export const UPDATE_PRICING_TYPE_FOR_LIVE_ACCOUNT_APPLICATION_FORM = 'Update Pricing Type For Live Account Application Form'
export const SUBMIT_LIVE_ACCOUNT_APPLICATION = 'Submit Live Account Application'
export const SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_2 = 'Submitted Live Account Application Step 2: Business Info'
export const SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_3 = 'Submitted Live Account Application Step 3: Ownership Info'
export const SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_4 = 'Submitted Live Account Application Step 4: Processing Info'
export const SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_5 = 'Submitted Live Account Application Step 5: Bank Account Info'
export const WITHDRAW_ACCESS_FORM = 'Withdraw Access Form'
export const DISABLE_API_KEY = 'Disable API Key'
export const ACCEPT_LIABILITY_FOR_DISPUTE = 'Accept Liability For Dispute'
export const SUBMIT_DISPUTE_EVIDENCE = 'Submit Dispute Evidence'
export const CREATE_CUSTOMER_ONBOARDING_FORM = 'Create Customer Onboarding Form'
export const UPDATE_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM = 'Update Customer Onboarding Form Access Form'
export const SET_VELOCITY_LIMITS = 'Set Velocity Limits'
export const UPDATE_VELOCITY_LIMITS = 'Update Velocity Limits'
export const UPDATE_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS = 'Update Velocity Limits on Balance Adjustments'
export const CREATE_BALANCE_ENTRY = 'Create Balance Entry'
export const EDIT_RECIPIENT_IDENTITY = 'Edit Recipient Identity'
export const ADD_RECIPIENT_IDENTITY = 'Add Recipient Identity'
export const CREATE_PAYMENT_LINK = 'Create Payment Link'
export const LOAD_PAYMENT_LINK = 'Load Payment Link'
export const CREATE_PAYMENT_LINK_PURCHASE = 'Create Payment Link Purchase'
export const LOAD_CHECKOUT_PAGE = 'Load Checkout Page'
export const CREATE_CHECKOUT_PURCHASE = 'Create Checkout Purchase'
export const LOAD_PAYMENT_LINK_CONFIRMATION = 'Load Payment Link Confirmation'
export const CREATE_NEW_UNDERWRITING_REVIEW_RUN = 'Create New Underwriting Review Run'
export const UPDATE_AUTHORIZATION = 'Update Authorization'
export const CREATE_NOTE = 'Create Note'
export const UPDATE_NOTE = 'Update Note'
export const ADD_DEVICE = 'Add Device'
export const EDIT_DEVICE_DETAILS = 'Edit Device Details'
export const ACTIVATE_DEVICE = 'Activate Device'
export const DEACTIVATE_DEVICE = 'Deactivate Device'
export const SET_DEVICE_IDLE_MESSAGE = 'Set Device Idle Message'
export const SET_IDLE_SCREEN = 'Set Idle Screen'
export const REBOOT_DEVICE = 'Reboot Device'
export const EDIT_DEVICE_CONFIGURATION_DETAILS = 'Edit Device Configuration Details'
export const EDIT_UNDERWRITING_WORKFLOW = 'Edit Underwriting Workflow'
export const INVALIDATE_COMPLIANCE_FORM = 'Invalidate Compliance Form'
export const CREATE_COMPLIANCE_FORM = 'Create Compliance Form'
export const CREATE_PLAID_TOKEN = 'Create Plaid Token'
export const SEND_PLAID_PUBLIC_TOKEN = 'Send Plaid Public Token'
export const CREATE_PAYMENT_INSTRUMENT = 'Create Payment Instrument'
export const UPDATE_PAYOUT_PROFILE = 'Update Payout Profile'
export const REQUEST_ADDITIONAL_VERIFICATION = 'Request Additional Verification'
export const SUBMIT_TO_UNDERWRITING = 'Submit to Underwriting'
export const LOAD_PAYOUT_LINK = 'Load Payout Link'
export const CREATE_PAYOUT_LINK = 'Create Payout Link'
export const GET_RECIPIENT_PAYMENT_INSTRUMENTS = 'Get Recipient Payment Instruments'
export const PAYOUT_LINK_TRANSFER_ATTEMPT = 'Payout Link Transfer Attempt'
export const LOAD_PAYOUT_LINK_CONFIRMATION = 'Load Payout Link Confirmation'
export const UPDATE_FEE_PROFILE_SETTINGS = 'Update Fee Profile Settings'
export const CREATE_FEE_PROFILE_RULES = 'Create Fee Profile Rules'
export const UPDATE_FEE_PROFILE_RULES = 'Update Fee Profile Rules'
export const CREATE_BALANCE_ADJUSTMENT = 'Create Balance Adjustment'
export const CREATE_SUBSCRIPTION = 'Create Subscription'
export const CREATE_SUBSCRIPTION_PLAN = 'Create Subscription Plan'
export const DELETE_SUBSCRIPTION = 'Delete Subscription'
export const EDIT_SUBSCRIPTION_PLAN = 'Edit Subscription Plan'
export const EDIT_SUBSCRIPTION = 'Edit Subscription'
