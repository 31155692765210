import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import CreateInstantPayoutForm from './CreateInstantPayoutForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import isFetching from 'utilities/is/isFetching'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_INSTANT_PAYOUT_FORM } from 'constants/formConstants'
import { GET_PAYMENT_INSTRUMENTS_F_REQUEST } from 'constants/flowConstants'
import { ADD_ICON } from 'constants/iconConstants'
import { ADD_A_NEW_CARD } from 'constants/language/languageConstants'
import { CREATE_PAYMENT_INSTRUMENT_MODAL } from 'constants/modalConstants'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import concat from 'lodash/concat'

import {
  getSettlementSelector,
  getPaymentInstrumentsSelector,
} from 'state-layer/selectors'

import {
  getCardBrand,
  PAYMENT_CARD,
} from 'constants/bankConstants'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
  option: {},
}

const mapStateToProps = (state, props) => {
  const isFetchingSettlement = isFetching(state, 'settlementR')
  const isFetchingPaymentInstruments = get(state, `paymentInstrumentsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const currentUser = getCurrentUser(state)
  const settlementId = get(props, 'params.settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const identityPaymentInstruments = getPaymentInstrumentsSelector(state)
  const formSelector = formValueSelector(CREATE_INSTANT_PAYOUT_FORM)
  const paymentInstrumentId = get(formSelector(state, 'paymentMethod'), 'value')

  const [
    displayNetAmount,
    currency,
    merchant,
    applicationId,
    merchantIdentity,
    netAmount,
    displayNetAmountWithCurrencyCode,
  ] = getMany(settlement, [
    'displayNetAmount',
    'currency',
    'merchant.id',
    'applicationId',
    'merchantIdentity.id',
    'netAmount',
    'displayNetAmountWithCurrencyCode',
  ])

  const filteredMerchantPaymentInstruments = filter(identityPaymentInstruments, ({ instrumentType }) => isEqual(instrumentType, 'PAYMENT_CARD'))

  const paymentInstrumentOptions = map(filteredMerchantPaymentInstruments, (instrument) => {
    const {
      id,
      brand,
      maskedFullCardNumber,
      expirationDate,
    } = instrument

    const cardIcon = getCardBrand(brand)
    const cardOptionLabel = <div className='flex items-center'><i className={`card-brand-icon ${cardIcon}`} />{maskedFullCardNumber}<span className='card-expiration-date secondary'>({expirationDate})</span></div>

    return {
      value: id,
      label: cardOptionLabel,
    }
  })

  const paymentInstrumentOptionsOnlyCard = concat(
    {
      label: (
        <div className='add-new-payment-instrument flex items-center'>
          <span className={`add-icon fa fa-${ADD_ICON}`} />
          {ADD_A_NEW_CARD}
        </div>),
      value: 'newPaymentMethod',
    }, paymentInstrumentOptions,
  )

  return {
    isFetchingSettlement,
    isFetchingPaymentInstruments,
    credentials,
    currentUser,
    displayNetAmount,
    currency,
    merchant,
    paymentInstrumentOptions,
    paymentInstrumentOptionsOnlyCard,
    paymentInstrumentId,
    environment,
    applicationId,
    settlementId,
    merchantIdentity,
    netAmount,
    displayNetAmountWithCurrencyCode,
    reactSelectCustomStyles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreatePaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_MODAL, className: 'modal-md no-pad', modalProps })),
    closeModal: () => dispatch(hideModalAction()),
    updateCreateNewPaymentInstrumentFormField: ({ value, field }) => dispatch(change(CREATE_INSTANT_PAYOUT_FORM, field, value)),
    getSettlement: ({ settlementId, credentials }) => dispatch(getSettlementRequest({ settlementId, credentials })),
    getPaymentInstruments: ({ credentials, queries }) => dispatch({
      type: GET_PAYMENT_INSTRUMENTS_F_REQUEST,
      payload: {
        credentials,
        queries,
      },
    }),
    clearReducer: (reducerNames) => dispatch(clearReducerRequest(reducerNames)),
  }
}

class CreateInstantPayoutFormC extends Component {
  componentDidMount() {
    const {
      settlementId,
      credentials,
      getSettlement,
      merchantIdentity,
      getPaymentInstruments,
    } = this.props

    if (settlementId) {
      getSettlement({ settlementId, credentials })
    }

    if (merchantIdentity) {
      getPaymentInstruments({ credentials, queries: { owner_identity_id: merchantIdentity } })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      paymentInstrumentId: prevPaymentInstrumentId,
      merchantIdentity: prevMerchantIdentity,
    } = prevProps

    const {
      showCreatePaymentInstrumentModal,
      paymentInstrumentId,
      credentials,
      updateCreateNewPaymentInstrumentFormField,
      environment,
      applicationId,
      merchantIdentity,
      clearReducer,
      getPaymentInstruments,
    } = this.props

    if (merchantIdentity && prevMerchantIdentity !== merchantIdentity) {
      clearReducer(['paymentInstrumentsR'])
      getPaymentInstruments({ credentials, queries: { owner_identity_id: merchantIdentity } })
    }

    const showNewPaymentMethodModal = prevPaymentInstrumentId !== paymentInstrumentId && paymentInstrumentId === 'newPaymentMethod'

    if (showNewPaymentMethodModal) {
      updateCreateNewPaymentInstrumentFormField({ field: 'paymentMethod', value: null })
      showCreatePaymentInstrumentModal({ credentials, environment, applicationId, buyerIdentityId: merchantIdentity, formName: CREATE_INSTANT_PAYOUT_FORM, fieldName: 'paymentMethod' })
    }
  }

  render() {
    return (
      <CreateInstantPayoutForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInstantPayoutFormC)
