import './MultiFileUploaderS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import Loader from 'components/Shared/Loader/Loader'
import { FILE_UPLOAD_ICON } from 'constants/iconConstants'
import { DEFAULT_MULTIFILE_CONFIG } from 'constants/fileUploaderConstants'
import { UPLOADING_FILES } from 'constants/language/languageConstants'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

const MultiFileUploader = ({
  config = {},
  instructions = {},
  onDrop = () => {},
  isUploading = false,
  disabled = false,
  disabledMessage = '',
  errorMessage = '',
  onDropRejected = () => {},
}) => {
  const dropzoneClassnames = classnames('dropzone', { disabled })
  const uploaderConfig = merge({}, DEFAULT_MULTIFILE_CONFIG, config, { onDrop, onDropRejected })
  const { getRootProps, getInputProps, rejectedFiles } = useDropzone(uploaderConfig)
  const rootProps = disabled ? {} : getRootProps()

  return (
    <section className='MultiFileUploader'>
      <div {...rootProps} className={dropzoneClassnames}>
        {!disabled && <input {...getInputProps()} />}

        {!isUploading && !disabled && (
        <span className='instructions flex center items-center p-3'>
          <i className={`file-upload-icon fa fa-${FILE_UPLOAD_ICON}`} />
          {instructions}
        </span>
        )}

        {isUploading && !disabled && <div className='is-uploading-state flex items-center p-2-bold'><Loader /> {UPLOADING_FILES}</div>}

        {disabled && <div className='disabled-message flex'>{disabledMessage}</div>}
      </div>

      {!isEmpty(errorMessage) && <div className='error-message'>{errorMessage}</div>}
      {!isEmpty(rejectedFiles) && <div className='custom-validator-error-message'>{onDropRejected(rejectedFiles)}</div>}
    </section>
  )
}

MultiFileUploader.propTypes = {
  config: PropTypes.object,
  instructions: PropTypes.object,
  onDrop: PropTypes.func,
  isUploading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onDropRejected: PropTypes.func,
}

export default MultiFileUploader
