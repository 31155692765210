/* eslint no-debugger: 0 */

import { fork, call } from 'redux-saga/effects'
import normalizeResponse from 'utilities/api/normalizeResponse'
import defaultNormalizeQueries from 'utilities/api/normalizeQueries'
import { parseUrlQueries as parsedNextQueries } from 'utilities/parseUrlQueries'
import createGenericFrontendModel from 'utilities/create/createGenericFrontendModel'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import relatedResourcesSaga from 'utilities/relatedResourcesSaga'
import { backendNormalizationMap } from 'constants/normalizationConstants'
// TODO: create generic backend model, even though it may do very little
import get from 'lodash/get'
import omit from 'lodash/omit'
import isArray from 'lodash/isArray'

const genericSaga = ({
  api,
  orchestration, // TODO: get rid of this once BE handles orchestration and update API locations
  dataKey,
  successS,
  failureS,
  frontendModel = createGenericFrontendModel,
  backendModel,
  debug,
  // TODO: remove the V2 logic after all customers migrate to DS_V2
  orchestrationV2,
  frontendModelV2,
  dataKeyV2,
  normalizeQueries = defaultNormalizeQueries,
  shouldNormalizeFrontend = true,
  shouldNormalizeBackend = true,
}) => {
  // this function receive all props inside of action.payload, does not include meta... will we need meta here at some point?
  return function * ({ payload, meta }) {
    // TODO: remove the V2 logic after all customers migrate to DS_V2
    const dashboardServiceV2 = isDashboardServiceV2()
    const flowOrchestration = dashboardServiceV2 && orchestrationV2 ? orchestrationV2 : orchestration
    const flowFrontendModel = dashboardServiceV2 && frontendModelV2 ? frontendModelV2 : frontendModel
    const flowDataKey = dashboardServiceV2 && dataKeyV2 ? dataKeyV2 : dataKey

    const {
      values = {},
      queries = {},
      credentials: payloadCredentials = {},
      id = '',
    } = payload

    // TODO: figure out whether we want to send credentials through meta or not
    const {
      credentials: metaCredentials = {},
    } = meta

    // TODO: figure out whether we want to send credentials through meta or not
    const credentials = payloadCredentials || metaCredentials
    const normalizedQueries = normalizeQueries({ queries, normalizationMap: backendNormalizationMap })

    try {
      const beModel = backendModel
        ? backendModel({ values, queries })
        : values

      const normalizedValues = shouldNormalizeBackend ? normalizeResponse({ content: beModel, normalizationMap: backendNormalizationMap }) : beModel

      const apiPayload = {
        id,
        meta,
        credentials,
        queries: normalizedQueries,
        values: normalizedValues,
      }

      // TODO: get rid of this once BE handles orchestration and updates API locations
      const fetchData = flowOrchestration || api

      if (debug) { debugger }

      const {
        data,
        error,
      } = yield call(fetchData, apiPayload)

      const errorOrchestrationStatus = get(error, 'orchestrationStatus', {})

      if (error) {
        if (debug) { debugger }

        // errors could be found in different locations
        const embeddedErrors = get(error, 'response.data._embedded.errors')
        const responseErrors = get(error, 'response.data')
        const foundErrors = embeddedErrors || responseErrors || []
        // normalize errors to ensure they are stored in an arr
        const normalizedErrors = isArray(foundErrors) ? foundErrors : [foundErrors]

        return {
          type: failureS,
          errors: normalizedErrors,
          payload: {
            orchestrationStatus: errorOrchestrationStatus,
          },
        }
      }
      const links = get(data, '_links') || get(data, 'links', {})
      const linksPage = parsedNextQueries(get(links, 'next'))
      const nextUndefined = get(linksPage, 'page') === 'undefined'
      const normalizedLinks = nextUndefined ? omit(links, 'next') : links

      const page = get(data, 'page')
      const responseData = flowDataKey ? get(data, flowDataKey) : data
      // normalize the response
      const normalizedData = shouldNormalizeFrontend ? normalizeResponse({ content: responseData }) : responseData
      if (debug) { debugger }
      // execute async task to scan resources for related data and send to proper reducers
      yield fork(relatedResourcesSaga, { resources: normalizedData, meta })
      if (debug) { debugger }
      // run the normalized data through our FE model
      const model = flowFrontendModel({ data: normalizedData, values, queries })
      if (debug) { debugger }
      // get any orchestrationStatus data
      const orchestrationStatus = get(data, 'orchestrationStatus', {})

      if (debug) { debugger }

      return {
        type: successS,
        payload: {
          links: normalizedLinks,
          newValues: model,
          page,
          orchestrationStatus,
        },
      }
    } catch (error) {
      if (debug) { debugger }

      return {
        type: failureS,
        errors: error,
      }
    }
  }
}

export default genericSaga
