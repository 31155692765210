import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIdentitySelector } from 'state-layer/selectors'
import AddNewBuyerIdentityForm from './AddNewBuyerIdentityForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import formatIdentityInitialValues from 'utilities/formatters/formatIdentityInitialValues'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const initialValues = formatIdentityInitialValues(identity)

  return {
    credentials,
    initialValues,
    identityId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class AddNewBuyerIdentityFormC extends Component {
  render() {
    const { closeModal } = this.props

    return (
      <AddNewBuyerIdentityForm
        {...this.props}
        closeModal={closeModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBuyerIdentityFormC)
