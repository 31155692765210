import React from 'react'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DisbursementsAccountColumn from 'components/Customer/Shared/Display/Columns/DisbursementsAccountColumn/DisbursementsAccountColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { RETURNED } from 'constants/statusConstants'
import includes from 'lodash/includes'

import {
  ALL,
  FAILED,
} from 'constants/transferConstants'

import {
  AMOUNT,
  CREATED_ON,
  ID,
  NOTE,
  PAYMENT_INSTRUMENT_NAME,
  PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  REASON_CODE,
  STATE,
  TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(PAYMENT_INSTRUMENT_NAME, 'paymentInstrument.name', {
    className: 'medium',
  }),
  column(PAYMENT_INSTRUMENT_RESOURCE_TITLE, 'paymentInstrument.maskedFullCardNumber', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT_RESOURCE_TITLE} subtitle={TYPE} />,
    className: 'medium',
    formatter: ({ paymentInstrument }) => <DisbursementsAccountColumn paymentInstrument={paymentInstrument} />,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
  column(STATE, 'state', {
    className: 'state',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED, RETURNED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED, RETURNED], getUrlQuery('state', ALL))}
        emptySignifierCheck={state === FAILED || state === RETURNED}
        subtitleIndent
      />
    ),
  }),
  column(NOTE, 'description', {
    className: 'normal',
  }),
]

export default columnDescriptors
