import paymentsAPI from 'utilities/api/paymentsAPI'
import { RECEIPT } from 'constants/apiConstants'

const getReceiptAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: RECEIPT({ receiptId: id }),
    credentials,
  })
}

export default getReceiptAPI
