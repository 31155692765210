import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_SELF_SERVICE_RESEND_EMAIL_F_REQUEST } from 'constants/flowConstants'
import { SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_AFTER_VERIFICATION_FAILED } from 'constants/amplitudeConstants'

const postSelfServiceResendEmailRequest = ({
  dashboardSandboxId,
}) => createAction({
  type: POST_SELF_SERVICE_RESEND_EMAIL_F_REQUEST,
  values: {
    id: dashboardSandboxId,
  },
  meta: {
    actionId: sendAmplitudeActionEvent(SELF_SERVICE_RESEND_EMAIL_ATTEMPTED_AFTER_VERIFICATION_FAILED, {
      dashboardSandboxId,
    }),
  },
})

export default postSelfServiceResendEmailRequest
