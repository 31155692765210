import React, { Component } from 'react'
import { connect } from 'react-redux'
import OrchestrationStatus from 'components/Customer/Modals/OrchestrationStatusModal/OrchestrationStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const orchestrationStatus = get(state, 'orchestrationStatusR')
  const errors = get(orchestrationStatus, 'errors')

  return {
    credentials,
    orchestrationStatus,
    errors,
  }
}

class OrchestrationStatusC extends Component {
  closeModal = () => {
    const { dispatch } = this.props

    dispatch(hideModalAction())
  }

  render() {
    const { closeModal } = this

    return (
      <OrchestrationStatus
        {...this.props}
        closeModal={closeModal}
      />
    )
  }
}

export default connect(mapStateToProps)(OrchestrationStatusC)
