import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'

import {
  DASHBOARD_SERVICE_USERS_CREDENTIALS_V2,
} from 'constants/apiConstants'

const getAdminUserCredentialsAPI = ({ id, meta, queries }) => {
  const dashboardId = getCurrentDashboardId()

  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_USERS_CREDENTIALS_V2({ dashboardId, userId: id }),
    meta,
    queries,
  })
}

export default getAdminUserCredentialsAPI
