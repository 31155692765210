import React, { Component } from 'react'
import { connect } from 'react-redux'
import Merchants from './Merchants'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { createdQueriesForLastMonths } from 'constants/queryConstants'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { MERCHANT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  MERCHANT_ACCOUNTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter('Merchant'),
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  MERCHANT_ACCOUNTS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `merchantsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)

  const merchantPath = (merchant) => {
    const {
      id,
      identityId,
    } = merchant

    return MERCHANT_PATH({ credentialId, identityId, merchantId: id })
  }

  return {
    allowedFilters,
    initialQueries: mergedQueries,
    initialFilters: createdQueriesForLastMonths(6),
    isFetching,
    merchantPath,
    quickFilters,
  }
}

class MerchantsC extends Component {
  render() {
    return (
      <Merchants {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(MerchantsC)
