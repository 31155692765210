import React from 'react'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TableRowActionsC from 'components/Customer/Shared/TableRowActions/TableRowActionsC'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { countryNameByCountryCode } from 'constants/countryConstants'
import get from 'lodash/get'

import {
  ALL,
  COUNTRY,
  DISABLE_AUTOMATION,
  DISABLED,
  ENABLE_AUTOMATION,
  ENABLED,
  PROCESSOR,
  UNDERWRITING_AUTOMATION,
  WORKFLOW,
} from 'constants/language/languageConstants'

const UnderwritingWorkflowsColumnDescriptors = ({ columnActions = {} }) => [
  column(WORKFLOW, 'workflow', {
    className: 'large',
  }),
  column(PROCESSOR, 'processor', {
    className: 'large',
  }),
  column(COUNTRY, 'country', {
    className: 'large',
    formatter: ({ country }) => {
      if (country === ALL) {
        return 'All'
      }
      return CountryFlagIcon({ country })
    },
  }),
  column(UNDERWRITING_AUTOMATION, 'actOnDecision', {
    className: 'large',
    formatter: ({ actOnDecision }) => <EnabledStatus value={actOnDecision ? ENABLED : DISABLED} />,
  }),
  column('', 'rowActions', {
    className: 'row-actions',
    formatter: (workflow, extraProps) => {
      const showEditUnderwritingWorkflowModal = get(columnActions, 'showEditUnderwritingWorkflowModal', () => {})

      const [
        actOnDecision,
        workflowId,
        country,
        processor,
      ] = getMany(workflow, [
        'actOnDecision',
        'id',
        'country',
        'processor',
      ])

      const actionLabel = actOnDecision ? DISABLE_AUTOMATION : ENABLE_AUTOMATION

      const {
        applicationId,
        environment,
      } = extraProps

      const actions = [
        {
          label: actionLabel,
          action: () => showEditUnderwritingWorkflowModal({
            applicationId,
            environment,
            actOnDecision,
            workflowId,
            displayCountry: countryNameByCountryCode[country],
            processor,
          }),
        },
      ]

      return <TableRowActionsC actions={actions} />
    },
  }),
]

export default UnderwritingWorkflowsColumnDescriptors
