import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { POST_ONBOARDING_FORM_LINK_F_REQUEST } from 'constants/flowConstants'
import { GENERATE_NEW_ONBOARDING_FORM_LINK } from 'constants/amplitudeConstants'
import { SHOW_ONBOARDING_FORM_LINK_MODAL } from 'constants/modalConstants'

const postOnboardingFormLinkRequest = ({
  onboardingFormId,
  expirationInMinutes,
  expirationInDays,
  expiredSessionUrl,
  feeDetailsUrl,
  returnUrl,
  termsOfServiceUrl,
  credentials,
  dispatch,
}) => createAction({
  type: POST_ONBOARDING_FORM_LINK_F_REQUEST,
  id: onboardingFormId,
  values: {
    expiration_in_minutes: expirationInMinutes,
    expired_session_url: expiredSessionUrl,
    fee_details_url: feeDetailsUrl,
    return_url: returnUrl,
    terms_of_service_url: termsOfServiceUrl,
  },
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(GENERATE_NEW_ONBOARDING_FORM_LINK, {
      credentials,
      onboardingFormId,
    }),
    successCallback: ({ newValues: onboardingFormNewValues }) => {
      const [
        onboardingFormLink,
      ] = getMany(onboardingFormNewValues, [
        'onboardingFormLink',
      ])

      dispatch(showModalAction({ modalType: SHOW_ONBOARDING_FORM_LINK_MODAL, modalProps: { onboardingFormLink, isCreateNewFormFlow: false, expirationTime: expirationInDays }, className: 'modal-md no-pad' }))
    },
  },
})

export default postOnboardingFormLinkRequest
