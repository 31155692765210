import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import FormFileUploader from './FormFileUploader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const defaultConfig = {
  multiple: false,
  maxSize: 2000000,
  accept: 'image/jpeg, image/png, image/tiff, application/pdf',
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    fieldName,
    formName,
    fileType,
    displayFieldName,
    config,
    instructions,
  ] = getMany(props, [
    'fieldName',
    'formName',
    'fileType',
    'displayFieldName',
    'config',
    'instructions',
  ])

  return removeUndefined({
    credentials,
    displayFieldName,
    fieldName,
    formName,
    fileType,
    config: config || defaultConfig,
    instructions,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    storeFileUploaded: ({ fieldName, formName, file }) => dispatch(change(formName, fieldName, file)),
  }
}

class FormFileUploaderC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadedFile: {},
      inputtedFileName: null,
      inputtedFileNameTouched: false,
      selectedDocumentType: null,
      selectDocumentTypeTouched: false,
    }
  }

  setFileName = (e) => {
    this.setState({ inputtedFileName: e.target.value, inputtedFileNameTouched: true })
  }

  onBlurFileName= () => {
    this.setState({ inputtedFileNameTouched: true })
  }

  setDocumentType = (e) => {
    this.setState({ selectedDocumentType: e.target.value, selectDocumentTypeTouched: true })
  }

  onFileChange = ({ file, rejected }) => {
    if (!rejected) {
      this.setState({ uploadedFile: file })
    }
  }

  onFileUpload = () => {
    const {
      storeFileUploaded,
      formName,
      fieldName,
      fileType,
      closeModal,
      directUpload = false,
      credentials,
      accessFormId,
      onboardingFormId,
      applicationId,
      existingAdditionalDocuments,
      directUploadFunc,
      isHostedAccessForm,
    } = this.props

    const {
      inputtedFileName,
      selectedDocumentType,
    } = this.state

    const { uploadedFile } = this.state
    // strip any commas from the file name
    const fileName = inputtedFileName ? inputtedFileName : get(uploadedFile, 'name', '')
    const normalizedFileName = fileName.replaceAll(',', '')
    const normalizedFile = new File([uploadedFile], normalizedFileName, { type: get(uploadedFile, 'type', '') })
    const normalizedFileType = selectedDocumentType ? selectedDocumentType : fileType
    const storedFile = { file: normalizedFile, fileType: normalizedFileType }

    if (directUpload) {
      directUploadFunc({
        credentials,
        fileType: fileType ? fileType : selectedDocumentType,
        file: storedFile,
        fileName: normalizedFileName,
        id: accessFormId,
        onboardingFormId,
        applicationId,
        existingAdditionalDocuments,
        isHostedAccessForm,
      })
    } else {
      storeFileUploaded({
        formName,
        fieldName,
        file: storedFile,
        fileType,
      })
    }

    closeModal()
  }

  render() {
    const { fileType } = this.props

    const {
      uploadedFile,
      selectedDocumentType,
      inputtedFileName = '',
      inputtedFileNameTouched,
      selectDocumentTypeTouched,
    } = this.state

    const noFileTypeSet = isEmpty(fileType) && isEmpty(selectedDocumentType)
    const noInputtedFileName = isEmpty(inputtedFileName)

    return (
      <FormFileUploader
        {...this.props}
        onFileChange={this.onFileChange}
        onFileUpload={this.onFileUpload}
        uploadedFile={uploadedFile}
        setFileName={this.setFileName}
        setDocumentType={this.setDocumentType}
        noFileTypeSet={noFileTypeSet}
        noInputtedFileName={noInputtedFileName}
        inputtedFileNameTouched={inputtedFileNameTouched}
        onBlurFileName={this.onBlurFileName}
        selectDocumentTypeTouched={selectDocumentTypeTouched}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFileUploaderC)
