import './EditMerchantOnboardingFormSettingsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import submitEditMerchantOnboardingFormSettingsForm from 'utilities/submit/submitEditMerchantOnboardingFormSettingsForm'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import { EDIT_MERCHANT_ONBOARDING_FORM_SETTINGS_FORM } from 'constants/formConstants'
import get from 'lodash/get'

import {
  BIOMETRIC_COLLECTION_TYPE_OPTIONS,
  BIOMETRIC_COLLECTION_TYPE_DESCRIPTIONS,
} from 'constants/underwritingProfileConstants'

import {
  CANCEL,
  SAVE,
} from 'constants/language/languageConstants'

const biometricDataCollectionValue = ({ innerProps, data }) => (
  <div {...innerProps} className='selected-biometric-data-collection'>{get(data, 'label')}</div>
)

const formatOptionLabel = ({ value, label }) => {
  return (
    <>
      <div className='label-2'>{label}</div>
      <div className='label-3 secondary mt-sm'>{BIOMETRIC_COLLECTION_TYPE_DESCRIPTIONS[value]}</div>
    </>
  )
}

const EditMerchantOnboardingFormSettingsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  submitting = false,
  pristine = false,
}) => {
  return (
    <form className='EditMerchantOnboardingFormSettingsForm' onSubmit={handleSubmit(submitEditMerchantOnboardingFormSettingsForm)}>
      <div className='modal-content'>
        <Field
          name='biometricDataCollection'
          component={ReactSelect}
          options={BIOMETRIC_COLLECTION_TYPE_OPTIONS}
          components={{ SingleValue: biometricDataCollectionValue }}
          formatOptionLabel={formatOptionLabel}
          label='Verification Upon Submission'
          tooltip='Select how merchants will be required to verify their identity upon submission of the onboarding form.'
          required
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' className='save-payment-instrument-button' submitting={submitting} disabled={pristine} label={SAVE} />
      </div>
    </form>
  )
}

EditMerchantOnboardingFormSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_MERCHANT_ONBOARDING_FORM_SETTINGS_FORM,
})(EditMerchantOnboardingFormSettingsForm)
