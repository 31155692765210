import {
  CONTACT,
  NAME,
  EMAIL,
  PHONE,
  SHIPPING,
  SHIPPING_AMOUNT,
  SHIPPING_DETAILS,
  LINE_1,
  LINE_2,
  ADDRESS_LINE_ONE,
  ADDRESS_LINE_TWO,
  CITY,
  STATE,
  REGION,
  COUNTRY,
  POSTAL_CODE,
  SELECT_COUNTRY,
  BILLING_DETAILS,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  PAY,
  ORDER_SUMMARY,
  TOTAL_DUE,
  SUBTOTAL,
  DISCOUNT,
  ESTIMATED_TAX,
  TIP_AMOUNT,
  PAYMENT_AMOUNT,
  PAYMENT_AMOUNT_HELPER_TEXT,
  PROCESSING,
} from 'constants/language/languageConstants'

export const FR_TRANSLATION = {
  [CONTACT]: 'Contact',
  [NAME]: 'Nom et prénom',
  [EMAIL]: 'Email',
  [PHONE]: 'Téléphone',
  [SHIPPING]: 'Livraison',
  [SHIPPING_AMOUNT]: 'Frais de livraison',
  [SHIPPING_DETAILS]: 'Détails de livraison',
  [LINE_1]: 'Adresse ligne 1',
  [LINE_2]: 'Adresse ligne 2',
  [ADDRESS_LINE_ONE]: 'Adresse ligne 1',
  [ADDRESS_LINE_TWO]: 'Adresse ligne 2',
  [CITY]: 'Ville',
  [STATE]: 'État / Région / Canton',
  [REGION]: 'État / Région / Canton',
  [POSTAL_CODE]: 'Code postal',
  [COUNTRY]: 'Pays',
  [SELECT_COUNTRY]: 'Choisissez le pays',
  [BILLING_DETAILS]: 'Détails de facturation',
  [PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US]: 'En cliquant sur Payer, vous nous autorisez à initier un débit unique de la chambre de compensation automatisée (ACH) en votre nom sur votre compte bancaire indiqué ci-dessus. Le montant de cette transaction tel que mentionné ci-dessus sera présenté à votre institution financière le jour ouvrable suivant. Vous acceptez en outre qu\'une fois que vous avez cliqué sur Payer, vous ne pouvez pas révoquer cette autorisation ni annuler ce paiement.',
  [TERMS_OF_SERVICE]: 'Conditions d\'utilisation',
  [PRIVACY_POLICY]: 'Politique de confidentialité',
  [PAY]: 'Payer',
  [ORDER_SUMMARY]: 'Récapitulatif de la commande',
  [TOTAL_DUE]: 'Total dû',
  [SUBTOTAL]: 'Sous-total',
  [DISCOUNT]: 'Remise',
  [ESTIMATED_TAX]: 'Taxe estimée',
  [TIP_AMOUNT]: 'Montant du pourboire',
  [PAYMENT_AMOUNT]: 'Montant du paiement',
  [PAYMENT_AMOUNT_HELPER_TEXT]: 'Y compris les taxes, les pourboires, les frais, etc.',
  [PROCESSING]: 'Traitement',
}
