import './DashboardOverviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import setupPlugins from 'components/Charts/setupPlugins'
import HomepageBannerC from 'components/Customer/Pages/HomepageBanner/HomepageBannerC'
import HomeInsightsC from 'components/Customer/Pages/Insights/HomeInsights/HomeInsightsC'

setupPlugins()

const DashboardOverview = ({
  isLiveEnvironment = false,
  isSelfServiceSandboxUser = false,
  isAccessFormCompleted = false,
  isRoleAdministrator = false,
}) => {
  return (
    <div className='DashboardOverview'>
      { (!isLiveEnvironment && isSelfServiceSandboxUser && !isAccessFormCompleted && isRoleAdministrator) && <HomepageBannerC /> }

      <HomeInsightsC />
    </div>
  )
}

DashboardOverview.propTypes = {
  isLiveEnvironment: PropTypes.bool,
  isSelfServiceSandboxUser: PropTypes.bool,
  isAccessFormCompleted: PropTypes.bool,
  isRoleAdministrator: PropTypes.bool,
}

export default DashboardOverview
