import selectOption from 'utilities/forms/selectOption'
import map from 'lodash/map'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

export const PROCESSORS_WITH_ADJUSTMENTS = ['LITLE_V1', 'VANTIV_V1', 'DUMMY_V1', 'FINIX_V1']

export const MERCHANT_CREDIT_ADJUSTMENT = 'MERCHANT_CREDIT_ADJUSTMENT'
export const MERCHANT_DEBIT_ADJUSTMENT = 'MERCHANT_DEBIT_ADJUSTMENT' 

export const AdjustmentTypeOptions = [
  selectOption('Select Type', ''),
  selectOption('Credit', MERCHANT_CREDIT_ADJUSTMENT),
  selectOption('Debit', MERCHANT_DEBIT_ADJUSTMENT),
]

export const getPaymentInstrumentsOptions = (paymentInstruments = {}) => map(paymentInstruments, ({ id }) => selectOption(id, id))

export const getTransferEnabledMerchants = (merchants) => filter(merchants, ({ processor }) => {
  return includes(PROCESSORS_WITH_ADJUSTMENTS, processor)
})

export const hasTransferEnabledMerchants = (merchants = {}) => !isEmpty(getTransferEnabledMerchants(merchants))

export const getMerchantsOptions = (merchants = {}) => {
  const transferEnabledMerchants = getTransferEnabledMerchants(merchants)
  
  return map(transferEnabledMerchants, ({ id, processor }) => selectOption(`${processor} - ${id}`, id))
}
