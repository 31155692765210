import './SendPayoutConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goToPath } from 'state-layer/history'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import isEmpty from 'lodash/isEmpty'

import {
  CHECK_CIRCLE_ICON,
  TIMES_OCTAGON,
} from 'constants/iconConstants'

import {
  DISBURSEMENTS_PATH,
  SEND_PAYOUT_PATH,
} from 'constants/pathConstants'

import {
  PAYMENT_RESULT,
  PRINT_CONFIRMATION,
  UPDATE_PAYMENT_DETAILS,
  PAYOUT_CONFIRMATION,
  RECIPIENT_DETAILS,
  PAYMENT_INSTRUMENT,
  PAYOUT_DETAILS,
  SEND_ANOTHER_PAYOUT,
  VIEW_ALL_PAYOUTS,
  PAYOUT_SUCCESSFUL,
} from 'constants/language/languageConstants'

const SendPayoutConfirmation = ({
  credentialId = '',
  printPage = () => {},
  recipientDetailsData = [],
  recipientPaymentInstrumentData = [],
  payoutDetailsData = [],
  isFetchingTransferData = false,
  isFetchingMerchantData = false,
  displayFailureCode = '',
  failureMessage = '',
}) => {
  return (
    <div className='SendPayoutConfirmation'>
      { isFetchingTransferData && <Loader /> }

      { !isFetchingTransferData && (
      <>
        { !isEmpty(failureMessage) ? (
          <>
            <div className='header-container'>
              <h2 className='title'>{PAYMENT_RESULT}</h2>
            </div>
            <div className='failure-message-container row'>
              <h6 className='title'><i className={`fa fa-${TIMES_OCTAGON} failure-icon`} /> {displayFailureCode}</h6>
              <div className='failure-message'>{failureMessage}</div>
            </div>
          </>
        ) : (
          <>
            <div className='header-container'>
              <h2 className='title'>{PAYOUT_CONFIRMATION}</h2>
            </div>

            <div className='success-message-container'>
              <span className='success-message'><i className={`fa fa-${CHECK_CIRCLE_ICON} success-icon`} />{PAYOUT_SUCCESSFUL} <a className='text-link' onClick={printPage}>{PRINT_CONFIRMATION}</a></span>
            </div>

            <PageSectionV2
              smallTitle={RECIPIENT_DETAILS}
              className='recipient-details'
              columns={recipientDetailsData}
              isFetching={isFetchingMerchantData}
            />

            <PageSectionV2
              smallTitle={PAYMENT_INSTRUMENT}
              className='payment-instrument-details'
              columns={recipientPaymentInstrumentData}
              isFetching={isFetchingMerchantData}
            />

            <PageSectionV2
              smallTitle={PAYOUT_DETAILS}
              className='payout-details'
              columns={payoutDetailsData}
              isFetching={isFetchingMerchantData}
            />

            <div className='buttons-container flex flex-start'>
              <Button className='send-another-payout-button' onClick={() => goToPath({ pathname: SEND_PAYOUT_PATH({ credentialId }) })} label={SEND_ANOTHER_PAYOUT} />
              <Button className='view-all-payouts-button' onClick={() => goToPath({ pathname: DISBURSEMENTS_PATH({ credentialId }) })} label={VIEW_ALL_PAYOUTS} variant='ghost' />
            </div>
          </>
        )}
      </>
      )}
    </div>
  )
}

SendPayoutConfirmation.propTypes = {
  credentialId: PropTypes.string,
  printPage: PropTypes.func,
  recipientDetailsData: PropTypes.array,
  recipientPaymentInstrumentData: PropTypes.array,
  payoutDetailsData: PropTypes.array,
  isFetchingTransferData: PropTypes.bool,
  isFetchingMerchantData: PropTypes.bool,
  displayFailureCode: PropTypes.string,
  failureMessage: PropTypes.string,
}

export default SendPayoutConfirmation
