import Device from './Device'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDeviceSelector, getFileSelector } from 'state-layer/selectors'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import DeviceTransactionsTabs from 'components/Shared/Tabs/DeviceTransactionsTabs'
import DeviceInfoC from 'components/Customer/Pages/Device/DeviceInfo/DeviceInfoC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDeviceRequest from 'utilities/actions/get/getDeviceRequest'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import getFileRequest from 'utilities/actions/get/getFileRequest'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  APPLICATION_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

import {
  ACTIVATE_DEVICE_MODAL,
  DEACTIVATE_DEVICE_MODAL,
  REBOOT_DEVICE_MODAL,
} from 'constants/modalConstants'

import {
  FINIX_V1,
  DUMMY_V1,
  DATACAP_V1,
  TRIPOS_CLOUD_V1,
  TRIPOS_MOBILE_V1,
} from 'constants/processorConstants'

import {
  ACTIVATE_DEVICE,
  APPLICATION_RESOURCE_TITLE,
  CHECK_CONNECTION,
  CREATED_ON,
  DEACTIVATE_DEVICE,
  DISABLED,
  ENABLED,
  MERCHANT_ACCOUNT,
  MODEL,
  PAYMENT_DEVICE,
  STATE,
  REBOOT_DEVICE,
  GENERAL_INFO,
  TRANSACTIONS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetchingDevices = get(state, `devicesR.${FETCHING}`)
  const isFetchingFiles = get(state, `filesR.${FETCHING}`)
  const isFetching = isFetchingDevices || isFetchingFiles
  const deviceId = get(props, 'params.deviceId')
  const device = getDeviceSelector(state, deviceId)
  const isRolePlatformCredential = isRolePlatform({ credentials })

  const [
    deviceName,
    displayCreatedAt,
    displayDeviceModel,
    deviceStatusEnabled,
    merchantData,
    serialNumber,
    idleImageFileId,
  ] = getMany(device, [
    'name',
    'displayCreatedAt',
    'displayDeviceModel',
    'enabled',
    'merchantData',
    'serialNumber',
    'idleImageFileId',
  ])

  const idleImageFile = getFileSelector(state, idleImageFileId)
  const idleImageExternalLink = get(idleImageFile, 'externalLink')

  const [
    merchantId,
    merchantName,
    gateway,
    identityId,
    processor,
  ] = getMany(merchantData, [
    'id',
    'name',
    'gateway',
    'identityId',
    'processor',
  ])

  const applicationData = get(device, 'applicationData', {})
  const applicationName = get(applicationData, 'name')
  const applicationId = get(applicationData, 'id')
  const showActions = !isEqual(gateway, TRIPOS_MOBILE_V1)

  let canActivateDevice = !deviceStatusEnabled

  if (includes([FINIX_V1, DUMMY_V1], processor) || isEqual(gateway, DATACAP_V1)) {
    canActivateDevice = canActivateDevice && serialNumber
  }

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: isRolePlatformCredential ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantName,
        path: MERCHANT_PATH({ credentialId, identityId, merchantId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: PAYMENT_DEVICE,
      id: deviceId,
    },
    items: [
      {
        value: <h1>{deviceName}</h1>,
      },
      {
        label: MODEL,
        value: displayDeviceModel,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: STATE,
        value: <EnabledStatus value={deviceStatusEnabled ? ENABLED : DISABLED} />,
      },
    ],
  }

  const tabs = [
    {
      id: 'general-info',
      name: GENERAL_INFO,
      component: DeviceInfoC,
      props: {
        deviceId,
        idleImageExternalLink,
      },
    },
    {
      id: 'transactions',
      name: TRANSACTIONS,
      component: DeviceTransactionsTabs,
      props: {
        deviceId,
      },
    },
  ]

  let activateDeviceModalSubtitle = ''

  if (isEqual(gateway, TRIPOS_CLOUD_V1)) {
    activateDeviceModalSubtitle = `Please enter the activation code shown on ${deviceName}.`
  }

  return {
    isFetching,
    headerData,
    deviceId,
    deviceStatusEnabled,
    device,
    deviceName,
    credentials,
    contextBarData,
    showActions,
    gateway,
    tabs,
    activateDeviceModalSubtitle,
    processor,
    serialNumber,
    canActivateDevice,
    merchantId,
    idleImageFileId,
    idleImageExternalLink,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDevice: ({ deviceId, credentials }) => dispatch(getDeviceRequest({ deviceId, credentials })),
  checkDeviceConnection: ({ deviceId, deviceName, credentials }) => dispatch(getDeviceRequest({
    deviceId,
    credentials,
    queries: { include_connection: true },
    meta: { successMessage: `${deviceName} is online.`, errorMessage: `${deviceName} is offline.` },
  })),
  showActivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: ACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-md no-pad' })),
  showDeactivateDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_DEVICE_MODAL, modalProps, className: 'modal-md no-pad' })),
  showRebootDeviceModal: (modalProps) => dispatch(showModalAction({ modalType: REBOOT_DEVICE_MODAL, modalProps, className: 'modal-md no-pad' })),
  getDeviceFile: ({ idleImageFileId, credentials }) => dispatch(getFileRequest({ id: idleImageFileId, credentials })),
})

class DeviceC extends Component {
  componentDidMount() {
    const {
      getDevice,
      getDeviceFile,
      deviceId,
      credentials,
      idleImageFileId,
    } = this.props

    getDevice({ deviceId, credentials })

    if (idleImageFileId) {
      getDeviceFile({ idleImageFileId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getDeviceFile,
      credentials,
      idleImageFileId,
    } = this.props

    if (idleImageFileId && idleImageFileId !== prevProps.idleImageFileId) {
      getDeviceFile({ idleImageFileId, credentials })
    }
  }

  render() {
    const {
      showActions,
      deviceName,
      deviceStatusEnabled,
      deviceId,
      gateway,
      showActivateDeviceModal,
      showDeactivateDeviceModal,
      showRebootDeviceModal,
      checkDeviceConnection,
      credentials,
      activateDeviceModalSubtitle,
      processor,
      serialNumber,
      canActivateDevice,
    } = this.props

    const actions = showActions ? [
      {
        label: CHECK_CONNECTION,
        condition: deviceStatusEnabled,
        action: () => checkDeviceConnection({ deviceId, credentials, deviceName }),
      },
      {
        label: REBOOT_DEVICE,
        action: () => showRebootDeviceModal({ deviceId, deviceName }),
        condition: isEqual(gateway, TRIPOS_CLOUD_V1),
      },
      {
        label: ACTIVATE_DEVICE,
        condition: canActivateDevice,
        action: () => showActivateDeviceModal({
          deviceStatusEnabled,
          deviceName,
          gateway,
          deviceId,
          activateDeviceModalSubtitle,
          processor,
          serialNumber,
        }),
      },
      {
        label: DEACTIVATE_DEVICE,
        condition: deviceStatusEnabled,
        action: () => showDeactivateDeviceModal({
          deviceName,
          deviceId,
        }),
      },
    ] : []

    return (
      <Device
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceC)
