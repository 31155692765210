import './PaymentLinkCustomFieldsS.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'

import {
  t,
  TITLE,
  DELETE_CUSTOM_FIELD,
  CUSTOM_FIELD_LIMIT_REACHED,
  PAYOUT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG,
  PAYMENT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG,
  ADD_ANOTHER_CUSTOM_FIELD,
} from 'constants/language/languageConstants'

import {
  ADD_ICON,
  ALERT_ICON,
  TRASH_ALT_ICON,
} from 'constants/iconConstants'

const PaymentLinkCustomFields = ({ fields, isPayoutLink = false }) => {
  useEffect(() => {
    if (fields && fields.length === 0) {
      fields.push({})
    }
  }, [])

  const tagLimitReached = fields && fields.length >= 50
  const showRemoveButton = fields && fields.length > 1

  return (
    <div className='PaymentLinkCustomFields'>
      <ul className='flex column'>
        {fields.map((customField, index) => {
          const key = `payment_link_custom_field_${index}`

          return (
            <li key={key} className='custom-field'>
              <Field
                name={`${customField}.name`}
                label={TITLE}
                placeholder={isPayoutLink ? PAYOUT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG : PAYMENT_LINK_CUSTOM_FIELDS_PLACEHOLDER_MSG}
                component={InputField}
              />

              { showRemoveButton && (
                <div className='remove-field flex' onClick={() => fields.remove(index)}>
                  <i className={`fal fa-${TRASH_ALT_ICON}`} />
                  <span>{t(DELETE_CUSTOM_FIELD)}</span>
                </div>
              )}
            </li>
          )
        })}
      </ul>

      <div className='add-field'>
        {
          tagLimitReached ? (
            <span className='fields-limit-reached-info'><i className={`fa fa-${ALERT_ICON}`} />{t(CUSTOM_FIELD_LIMIT_REACHED)}</span>
          ) : (
            <span onClick={() => fields.push({})}>
              <i className={`fal fa-${ADD_ICON}`} />
              <span>{ADD_ANOTHER_CUSTOM_FIELD}</span>
            </span>
          )
        }
      </div>
    </div>
  )
}

PaymentLinkCustomFields.propTypes = {
  fields: PropTypes.object,
}

export default PaymentLinkCustomFields
