import { GET_GUEST_ONBOARDING_FORM_FILES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestOnboardingFormFilesRequest = ({
  onboardingFormId,
  fileIds,
  meta,
}) => createAction({
  type: GET_GUEST_ONBOARDING_FORM_FILES_F_REQUEST,
  values: {
    onboardingFormId,
    fileIds,
  },
  meta,
})

export default getGuestOnboardingFormFilesRequest
