import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SearchUserForm from 'components/Customer/Forms/SearchUserForm/SearchUserForm'

const SearchUserModal = ({
  retryMessage,
  applicationId,
}) => (
  <GenericModal
    title='Search User'
    className='SearchUserModal'
    Component={SearchUserForm}
    retryMessage={retryMessage}
    applicationId={applicationId}
  />
)

export default SearchUserModal
