import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasSubmitFailed, isInvalid } from 'redux-form'
import { getApplicationSelector } from 'state-layer/selectors'
import SetVelocityLimitsForm from './SetVelocityLimitsForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import isPatching from 'utilities/is/isPatching'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { PATHNAME } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import split from 'lodash/split'
import last from 'lodash/last'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import lowerCase from 'lodash/lowerCase'
import toString from 'lodash/toString'
import merge from 'lodash/merge'

import {
  AM,
  AMERICA_NY_TIMEZONE,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

import {
  ACH,
  CARD,
  APPLICATION,
  RECIPIENTS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentPath = get(state, PATHNAME, window.location.pathname)
  const lastPathString = last(split(currentPath, '/'))
  const limitType = includes(lastPathString, 'ach') ? ACH : CARD
  const entityType = get(props, 'entityType')
  const title = `Set ${entityType} Velocity Limits`
  const firstStep = isEqual(entityType, APPLICATION)
  const isFetchingApplication = get(state, `applicationsR.${FETCHING}`)
  const applicationId = get(props, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const businessName = get(application, 'businessName')
  const existingApplicationFormValues = get(state, 'flowsR.formValues.applicationVelocityLimits')
  const existingRecipientFormValues = get(state, 'flowsR.formValues.recipientVelocityLimits')
  const existingFormValues = merge({}, existingApplicationFormValues, existingRecipientFormValues)
  const normalizedEntityTypeValue = lowerCase(entityType)
  const normalizedExistingFormValues = get(existingFormValues, normalizedEntityTypeValue)
  const isFormInvalid = isInvalid(CREATE_VELOCITY_LIMITS_FORM)(state) && hasSubmitFailed(CREATE_VELOCITY_LIMITS_FORM)(state)
  const currentUser = getCurrentUser()
  const platformId = get(currentUser, 'platformId')

  const [
    startTimeValue,
    timeMeridianValue,
    dailyCardCountValue,
    dailyCardVolumeValue,
    dailyACHCountValue,
    dailyACHVolumeValue,
    monthlyStartDateValue,
    monthlyCardCountValue,
    monthlyCardVolumeValue,
    monthlyACHCountValue,
    monthlyACHVolumeValue,
    maxTransactionAmountForCards,
    maxTransactionAmountForACH,
  ] = getMany(normalizedExistingFormValues, [
    'startTime.value',
    'timeMeridian.value',
    'dailyCardCount',
    'dailyCardVolume',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyCardCount',
    'monthlyCardVolume',
    'monthlyACHCount',
    'monthlyACHVolume',
    'maxTransactionAmountForCards',
    'maxTransactionAmountForACH',
  ])

  const initialValues = {
    [normalizedEntityTypeValue]: removeUndefined({
      startTime: ({ label: toString(startTimeValue) || 'Select...', value: startTimeValue || '' }),
      timeZone: ({ label: AMERICA_NY_TIMEZONE, value: AMERICA_NY_TIMEZONE_BE_FIELD }),
      timeMeridian: ({ label: timeMeridianValue || AM, value: timeMeridianValue || AM }),
      dailyCardCount: dailyCardCountValue,
      dailyCardVolume: dailyCardVolumeValue,
      dailyACHCount: dailyACHCountValue,
      dailyACHVolume: dailyACHVolumeValue,
      monthlyStartDate: ({ label: toString(monthlyStartDateValue) || 'Select...', value: monthlyStartDateValue || '' }),
      monthlyCardCount: monthlyCardCountValue,
      monthlyCardVolume: monthlyCardVolumeValue,
      monthlyACHCount: monthlyACHCountValue,
      monthlyACHVolume: monthlyACHVolumeValue,
      maxTransactionAmountForCards,
      maxTransactionAmountForACH,
    }),
  }

  let subtitle = `Set your velocity limits for cards and ACH payouts for ${isEqual(entityType, APPLICATION) ? 'Applications' : 'Recipients'}`

  if (applicationId) {
    subtitle = `Set the velocity limits for card and ACH payouts for ${isEqual(entityType, APPLICATION) ? businessName : lowerCase(RECIPIENTS)}.`
  }

  return {
    credentials,
    credentialId,
    applicationId,
    platformId,
    title,
    subtitle,
    isFetchingApplication,
    limitType,
    entityType: normalizedEntityTypeValue,
    initialValues,
    firstStep,
    isFormInvalid,
    isSetting: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
  }
}

class SetVelocityLimitsFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplication,
    } = this.props

    if (applicationId) {
      getApplication({
        credentials,
        applicationId,
      })
    }
  }

  render() {
    return (
      <SetVelocityLimitsForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetVelocityLimitsFormC)
