import {
  DUMMY_V1,
  VISA_V1,
} from 'constants/processorConstants'

export const DATA = {
  name: 'dcbank',
  id: 'DASHkC6qh6Y2oEiVKAd93ucXM6',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2020-05-27-17_20_30-dc-bank-powered-light-logo-v1.png',
  faviconImage: '',
  credentialsHost: 'https://dcbank.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  auth0: {
    clientID: 'l1pGGfF7Zjj4EV5LXxM4FqlOjF3kDYqZ',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: '',
    logo: '',
  },
  availableProcessors: ['DUMMY_V1', 'VISA_V1'],
  homeCountry: 'CAN',
  dashboardServiceV2: true, // using this to control dashboard service version
}
