import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { PATCHING } from 'constants/reducerConstants'

import {
  requestF,
  successF,
  failureF,
  POST_PATCH_FLOWS,
} from 'constants/flowConstants'

const setPatching = (state) => merge({}, state, { [PATCHING]: true })
const unsetPatching = (state) => merge({}, state, { [PATCHING]: false })

const flowActions = reduce(POST_PATCH_FLOWS, (total, flow) => {
  return merge({}, total, {
    [requestF(flow)]: setPatching,
    [successF(flow)]: unsetPatching,
    [failureF(flow)]: unsetPatching,
  })
}, {})

const patchingR = (state = {}, action = {}) => {
  const actionType = get(action, 'type')
  const flowAction = get(flowActions, actionType)

  return flowAction ? flowAction(state) : state
}

export default patchingR
