import './ConfirmPasswordMFAFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import submitConfirmPasswordMFAForm from 'utilities/submit/submitConfirmPasswordMFAForm'
import validateConfirmPasswordMFAForm from 'utilities/validate/validateConfirmPasswordMFAForm'
import { CONFIRM_PASSWORD_MFA_FORM } from 'constants/formConstants'

import {
  BACK,
  NEXT,
} from 'constants/language/languageConstants'

const ConfirmPasswordMFAForm = ({
  handleSubmit = () => {},
  back = () => {},
  isPatching = false,
  invalid = false,
}) => {
  return (
    <form className='ConfirmPasswordMFAForm' onSubmit={handleSubmit(submitConfirmPasswordMFAForm)}>
      <div className='modal-content'>
        <Field
          name='password'
          type='password'
          label='Current Password'
          component={InputField}
          disabled={isPatching}
          placeholder='XXXXXXXXXX'
          required={false}
        />
      </div>

      <div className='buttons flex space-between'>
        <Button onClick={back} label={BACK} variant='tertiary' className='cancel-button' disabled={isPatching} />
        <Button type='submit' label={NEXT} disabled={invalid || isPatching} />
      </div>
    </form>
  )
}

ConfirmPasswordMFAForm.propTypes = {
  handleSubmit: PropTypes.func,
  back: PropTypes.func,
  isPatching: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  validate: validateConfirmPasswordMFAForm,
  form: CONFIRM_PASSWORD_MFA_FORM,
})(ConfirmPasswordMFAForm)
