import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import nextFlowStep from 'utilities/nextFlowStep'
import getMany from 'utilities/get/getMany'
import { PATCH_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import { COMPLETE_MERCHANT_BOARDING_STEP } from 'constants/amplitudeConstants'
import { COUNTRY_TO_CURRENCY_NAME_MAP } from 'constants/currencyConstants'
import get from 'lodash/get'

// TODO: add unit test for this submit function
const submitOnboardingFormWithPlaidLinkedAccount = (values, dispatch, props) => {
  const onboardingFormId = get(props, 'onboardingFormId')
  const onboardingFormCountry = get(props, 'onboardingFormCountry')
  const onboardingFormCurrency = COUNTRY_TO_CURRENCY_NAME_MAP[onboardingFormCountry]
  const showBankFieldset = get(props, 'showBankFieldset')
  const plaidProcessorToken = get(props, 'plaidProcessorToken')

  const [
    name,
    bankCode,
    transitNumber,
    institutionNumber,
    accountNumber,
  ] = getMany(values, [
    'bankAccountData.name',
    'bankAccountData.bankCode',
    'bankAccountData.transitNumber',
    'bankAccountData.institutionNumber',
    'bankAccountData.accountNumber',
  ])

  let valuesToSubmit = {}

  // overwrite any manual or plaid data when submitting a new bank account
  if (!showBankFieldset) {
    valuesToSubmit = {
      id: onboardingFormId,
      bankAccountData: {
        plaidProcessorToken,
        thirdParty: 'PLAID',
        type: 'BANK_ACCOUNT',
        name: null,
        bankCode: null,
        accountNumber: null,
        accountType: null,
        transitNumber: null,
        institutionNumber: null,
        country: null,
        currency: null,
      },
    }
  } else {
    valuesToSubmit = {
      id: onboardingFormId,
      bankAccountData: {
        name,
        bankCode,
        accountNumber,
        accountType: 'BUSINESS_CHECKING',
        transitNumber,
        institutionNumber,
        type: 'BANK_ACCOUNT',
        country: onboardingFormCountry,
        currency: onboardingFormCurrency,
        plaidProcessorToken: null,
        thirdParty: null,
      },
    }
  }

  dispatch({
    type: PATCH_ONBOARDING_FORM_DATA_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: nextFlowStep,
      actionId: sendAmplitudeActionEvent(COMPLETE_MERCHANT_BOARDING_STEP, {
        id: onboardingFormId,
      }),
      overwriteReducer: true,
    },
  })
}

export default submitOnboardingFormWithPlaidLinkedAccount
