import './SettlementEntriesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { SETTLEMENT_ENTRIES } from 'constants/linkConstants'
import { GET_SETTLEMENT_ENTRIES } from 'constants/flowConstants'
import { getSettlementEntries } from 'state-layer/selectors'

const SettlementEntries = ({
  flowValues = {},
  initialQueries = {},
  initialFilters = {},
  entryPath = () => {},
  isFetching = false,
  settlementId = '',
  selectedItemsKey,
  allowedFilters = [],
  relatedItemsKey,
  columnDescriptors = [],
  selectedItemsData = {},
}) => {
  return (
    <div className='SettlementEntries'>
      <TableC
        flow={GET_SETTLEMENT_ENTRIES}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getSettlementEntries}
        selectorId={settlementId}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={SETTLEMENT_ENTRIES}
        allowedFilters={allowedFilters}
        path={entryPath}
        isFetching={isFetching}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        relatedItemsKey={relatedItemsKey}
        fileNameCSV='Settlement_Entries'
        showAdvancedExport
        entityType={SETTLEMENT_ENTRIES}
        additionalExportValues={{ entity_id: settlementId }}
      />
    </div>
  )
}

SettlementEntries.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  entryPath: PropTypes.func,
  isFetching: PropTypes.bool,
  settlementId: PropTypes.string,
  selectedItemsKey: PropTypes.string,
  allowedFilters: PropTypes.array,
  relatedItemsKey: PropTypes.string,
  columnDescriptors: PropTypes.array,
  selectedItemsData: PropTypes.object,
}

export default SettlementEntries
