import './MerchantIdentityNewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const MerchantIdentityNew = ({
  layout,
  steps,
  edit = false,
}) => {
  return (
    <div className='MerchantIdentityNew'>
      <FlowsC
        flowClassName='new-merchant-identity'
        layout={layout}
        steps={steps}
        edit={edit}
      />
    </div>
  )
}

MerchantIdentityNew.propTypes = {
  layout: PropTypes.string,
  steps: PropTypes.array,
  edit: PropTypes.bool,
}

export default MerchantIdentityNew
