import './ApplicationSettlementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Settlements/columnDescriptors'
import { APPLICATION_SETTLEMENTS } from 'constants/linkConstants'
import { SETTLEMENTS_ICON } from 'constants/iconConstants'
import { getApplicationSettlements } from 'state-layer/selectors'
// TODO: comment this back in once BE fix for showAdvancedExport is fixed ticket BE-15281
import {
  GET_APPLICATION_SETTLEMENTS,
  // SETTLEMENT,
} from 'constants/flowConstants'

const ApplicationSettlements = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  settlementPath = '',
  isFetching = false,
  applicationId = '',
  quickFilters = [],
}) => {
  return (
    <div className='ApplicationSettlements'>
      <TableC
        flow={GET_APPLICATION_SETTLEMENTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationSettlements}
        selectorId={applicationId}
        initialQueries={initialQueries}
        linksKey={APPLICATION_SETTLEMENTS}
        allowedFilters={allowedFilters}
        path={settlementPath}
        isFetching={isFetching}
        icon={`fa fa-${SETTLEMENTS_ICON}`}
        fileNameCSV={`Application_${applicationId}_Settlements`}
        quickFilters={quickFilters}
        // showAdvancedExport
        // entityType={SETTLEMENT}
      />
    </div>
  )
}

ApplicationSettlements.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  settlementPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  quickFilters: PropTypes.array,
}

export default ApplicationSettlements
