import React from 'react'
import { ReactComponent as StrategicMetricsIcon } from 'img/svg/category-icon-1.svg'
import { ReactComponent as FinanceDataIcon } from 'img/svg/category-icon-2.svg'
import { ReactComponent as OperationsReconciliationExceptionsMgmtIcon } from 'img/svg/category-icon-3.svg'
import { ReactComponent as RiskManagementIcon } from 'img/svg/category-icon-4.svg'
import includes from 'lodash/includes'

import {
  ROLE_PLATFORM,
  PLATFORM_AND_PARTNER_ROLES,
} from 'constants/roleConstants'

import {
  STRATEGIC_METRICS,
  FINANCE_DATA,
  OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT,
  RISK_MANAGEMENT,
  NET_PROFIT_REPORT_TYPE_DISPLAY,
  TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY,
  INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY,
  FEES_REPORT_TYPE_DISPLAY,
  FEE_PROFILE_TYPE_DISPLAY,
  FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY,
  MERCHANT_BALANCES_REPORT_TYPE_DISPLAY,
  SETTLEMENTS_REPORT_TYPE_DISPLAY,
  FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY,
  AUTHORIZATIONS_REPORT_TYPE_DISPLAY,
  TRANSACTIONS_REPORT_TYPE_DISPLAY,
  DISPUTES_REPORT_TYPE_DISPLAY,
  NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY,
  DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY,
  TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY,
  SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY,
  SETTLED_ITEMS_FEES_TYPE_DISPLAY,
  NET_PROFIT_REPORT_TYPE_DESCRIPTION,
  TRANSACTION_INTERCHANGE_REPORT_TYPE_DESCRIPTION,
  INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DESCRIPTION,
  FEES_REPORT_TYPE_DESCRIPTION,
  FLEX_CLIENT_BALANCES_REPORT_TYPE_DESCRIPTION,
  MERCHANT_BALANCES_REPORT_TYPE_DESCRIPTION,
  SETTLEMENTS_REPORT_TYPE_DESCRIPTION,
  FUNDING_INSTRUCTIONS_REPORT_TYPE_DESCRIPTION,
  AUTHORIZATIONS_REPORT_TYPE_DESCRIPTION,
  TRANSACTIONS_REPORT_TYPE_DESCRIPTION,
  DISPUTES_REPORT_TYPE_DESCRIPTION,
  NOTICE_OF_CHANGE_REPORT_TYPE_DESCRIPTION,
  DISPUTES_HISTORICAL_REPORT_TYPE_DESCRIPTION,
  TRANSACTION_MONITORING_REPORT_TYPE_DESCRIPTION,
  SETTLED_ITEMS_NON_FEES_TYPE_DESCRIPTION,
  SETTLED_ITEMS_FEES_TYPE_DESCRIPTION,
  FEE_PROFILE_REPORT_TYPE_DESCRIPTION,
} from 'constants/language/languageConstants'

import {
  NET_PROFIT_REPORT_TYPE_URL,
  TRANSACTION_INTERCHANGE_REPORT_TYPE_URL,
  INTERCHANGE_OPTIMIZATION_REPORT_TYPE_URL,
  FEES_REPORT_TYPE_URL,
  FLEX_CLIENT_BALANCES_REPORT_TYPE_URL,
  MERCHANT_BALANCES_REPORT_TYPE_URL,
  SETTLEMENTS_REPORT_TYPE_URL,
  FUNDING_INSTRUCTIONS_REPORT_TYPE_URL,
  AUTHORIZATIONS_REPORT_TYPE_URL,
  TRANSACTIONS_REPORT_TYPE_URL,
  DISPUTES_REPORT_TYPE_URL,
  NOTICE_OF_CHANGE_REPORT_TYPE_URL,
  DISPUTES_HISTORICAL_REPORT_TYPE_URL,
  TRANSACTION_MONITORING_REPORT_TYPE_URL,
  SETTLED_ITEMS_NON_FEES_REPORT_TYPE_URL,
  SETTLED_ITEMS_FEES_REPORT_TYPE_URL,
} from 'constants/urlConstants'

export const MAX_REPORTS_DISPLAY = 5

export const REPORT_CATEGORY_TO_ICON_MAPPING = {
  [STRATEGIC_METRICS]: <StrategicMetricsIcon />,
  [FINANCE_DATA]: <FinanceDataIcon />,
  [OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT]: <OperationsReconciliationExceptionsMgmtIcon />,
  [RISK_MANAGEMENT]: <RiskManagementIcon />,
}


export const REPORT_CATEGORY_TO_DISPLAY_MAPPING = {
  'strategic': STRATEGIC_METRICS,
  'operations': OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT,
  'finance': FINANCE_DATA,
  'risk_management': RISK_MANAGEMENT,
}

export const REPORT_CATEGORY_TO_API_FIELD_MAPPING = {
  [STRATEGIC_METRICS]: 'strategic',
  'Operations Reconciliation Exceptions Management': 'operations',
  [FINANCE_DATA]: 'finance',
  [RISK_MANAGEMENT]: 'risk_management',
}

// TODO: in future making api field snake case into constants
export const REPORT_TYPE_TO_DISPLAY_MAPPING = {
  'net_profit': NET_PROFIT_REPORT_TYPE_DISPLAY,
  'transaction_interchange': TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY,
  'interchange_optimization': INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY,
  'fees': FEES_REPORT_TYPE_DISPLAY,
  'fee_profile': FEE_PROFILE_TYPE_DISPLAY,
  'client_balance': FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY,
  'merchant_balance': MERCHANT_BALANCES_REPORT_TYPE_DISPLAY,
  'settlements': SETTLEMENTS_REPORT_TYPE_DISPLAY,
  'funding_instructions': FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY,
  'authorizations': AUTHORIZATIONS_REPORT_TYPE_DISPLAY,
  'transactions': TRANSACTIONS_REPORT_TYPE_DISPLAY,
  'disputes': DISPUTES_REPORT_TYPE_DISPLAY,
  'disputes_historical': DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY,
  'transaction_monitoring': TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY,
  'settled_items_non_fees': [SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY],
  'settled_items_fees': [SETTLED_ITEMS_FEES_TYPE_DISPLAY],
  'notice_of_change': [NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY],
}

export const REPORT_TYPE_TO_API_FIELD_MAPPING = {
  [NET_PROFIT_REPORT_TYPE_DISPLAY]: 'net_profit',
  [TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY]: 'transaction_interchange',
  [INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY]: 'interchange_optimization',
  [FEES_REPORT_TYPE_DISPLAY]: 'fees',
  [FEE_PROFILE_TYPE_DISPLAY]: 'fee_profile',
  [FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY]: 'client_balance',
  [MERCHANT_BALANCES_REPORT_TYPE_DISPLAY]: 'merchant_balance',
  [SETTLEMENTS_REPORT_TYPE_DISPLAY]: 'settlements',
  [FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY]: 'funding_instructions',
  [AUTHORIZATIONS_REPORT_TYPE_DISPLAY]: 'authorizations',
  [TRANSACTIONS_REPORT_TYPE_DISPLAY]: 'transactions',
  [DISPUTES_REPORT_TYPE_DISPLAY]: 'disputes',
  [DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY]: 'disputes_historical',
  [TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY]: 'transaction_monitoring',
  [SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY]: 'settled_items_non_fees',
  [SETTLED_ITEMS_FEES_TYPE_DISPLAY]: 'settled_items_fees',
  [NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY]: 'notice_of_change',
}

export const REPORT_TYPE_TO_DESCRIPTION_MAPPING = {
  [NET_PROFIT_REPORT_TYPE_DISPLAY]: NET_PROFIT_REPORT_TYPE_DESCRIPTION,
  [TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY]: TRANSACTION_INTERCHANGE_REPORT_TYPE_DESCRIPTION,
  [INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY]: INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DESCRIPTION,
  [FEES_REPORT_TYPE_DISPLAY]: FEES_REPORT_TYPE_DESCRIPTION,
  [FEE_PROFILE_TYPE_DISPLAY]: FEE_PROFILE_REPORT_TYPE_DESCRIPTION,
  [FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY]: FLEX_CLIENT_BALANCES_REPORT_TYPE_DESCRIPTION,
  [MERCHANT_BALANCES_REPORT_TYPE_DISPLAY]: MERCHANT_BALANCES_REPORT_TYPE_DESCRIPTION,
  [SETTLEMENTS_REPORT_TYPE_DISPLAY]: SETTLEMENTS_REPORT_TYPE_DESCRIPTION,
  [FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY]: FUNDING_INSTRUCTIONS_REPORT_TYPE_DESCRIPTION,
  [AUTHORIZATIONS_REPORT_TYPE_DISPLAY]: AUTHORIZATIONS_REPORT_TYPE_DESCRIPTION,
  [TRANSACTIONS_REPORT_TYPE_DISPLAY]: TRANSACTIONS_REPORT_TYPE_DESCRIPTION,
  [DISPUTES_REPORT_TYPE_DISPLAY]: DISPUTES_REPORT_TYPE_DESCRIPTION,
  [NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY]: NOTICE_OF_CHANGE_REPORT_TYPE_DESCRIPTION,
  [DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY]: DISPUTES_HISTORICAL_REPORT_TYPE_DESCRIPTION,
  [TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY]: TRANSACTION_MONITORING_REPORT_TYPE_DESCRIPTION,
  [SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY]: SETTLED_ITEMS_NON_FEES_TYPE_DESCRIPTION,
  [SETTLED_ITEMS_FEES_TYPE_DISPLAY]: SETTLED_ITEMS_FEES_TYPE_DESCRIPTION,
}

export const REPORT_TYPE_TO_LEARN_MORE_LINK_MAPPING = {
  [NET_PROFIT_REPORT_TYPE_DISPLAY]: NET_PROFIT_REPORT_TYPE_URL,
  [TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY]: TRANSACTION_INTERCHANGE_REPORT_TYPE_URL,
  [INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY]: INTERCHANGE_OPTIMIZATION_REPORT_TYPE_URL,
  [FEES_REPORT_TYPE_DISPLAY]: FEES_REPORT_TYPE_URL,
  [FEE_PROFILE_TYPE_DISPLAY]: FEES_REPORT_TYPE_URL,
  [FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY]: FLEX_CLIENT_BALANCES_REPORT_TYPE_URL,
  [MERCHANT_BALANCES_REPORT_TYPE_DISPLAY]: MERCHANT_BALANCES_REPORT_TYPE_URL,
  [SETTLEMENTS_REPORT_TYPE_DISPLAY]: SETTLEMENTS_REPORT_TYPE_URL,
  [FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY]: FUNDING_INSTRUCTIONS_REPORT_TYPE_URL,
  [AUTHORIZATIONS_REPORT_TYPE_DISPLAY]: AUTHORIZATIONS_REPORT_TYPE_URL,
  [TRANSACTIONS_REPORT_TYPE_DISPLAY]: TRANSACTIONS_REPORT_TYPE_URL,
  [DISPUTES_REPORT_TYPE_DISPLAY]: DISPUTES_REPORT_TYPE_URL,
  [NOTICE_OF_CHANGE_REPORT_TYPE_DISPLAY]: NOTICE_OF_CHANGE_REPORT_TYPE_URL,
  [DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY]: DISPUTES_HISTORICAL_REPORT_TYPE_URL,
  [TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY]: TRANSACTION_MONITORING_REPORT_TYPE_URL,
  [SETTLED_ITEMS_NON_FEES_TYPE_DISPLAY]: SETTLED_ITEMS_NON_FEES_REPORT_TYPE_URL,
  [SETTLED_ITEMS_FEES_TYPE_DISPLAY]: SETTLED_ITEMS_FEES_REPORT_TYPE_URL,
}

export const REPORTS_SORTING_OBJECT = ({ flex = false, role, isStandaloneMerchant = false }) => [
  {
    category: STRATEGIC_METRICS,
    condition: includes(PLATFORM_AND_PARTNER_ROLES, role),
    subcategories: [
      {
        name: NET_PROFIT_REPORT_TYPE_DISPLAY,
        condition: flex && !isStandaloneMerchant,
      },
      {
        name: TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY,
      }
    ],
  },
  {
    category: FINANCE_DATA,
    condition: includes(PLATFORM_AND_PARTNER_ROLES, role),
    subcategories: [
      {
        name: FEES_REPORT_TYPE_DISPLAY,
        condition: flex,
      },
      {
        name: FEE_PROFILE_TYPE_DISPLAY,
        condition: !isStandaloneMerchant,
      }
    ],
  },
  {
    category: OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT,
    subcategories: [
      {
        name: TRANSACTIONS_REPORT_TYPE_DISPLAY,
      },
      {
        name: SETTLEMENTS_REPORT_TYPE_DISPLAY,
      },
      {
        name: AUTHORIZATIONS_REPORT_TYPE_DISPLAY,
      },
      {
        name: FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY,
      },
      {
        name: DISPUTES_REPORT_TYPE_DISPLAY,
      }
    ],
  },
  {
    category: RISK_MANAGEMENT,
    subcategories: [
      {
        name: DISPUTES_HISTORICAL_REPORT_TYPE_DISPLAY,
      },
    ],
  },
]
