import get from 'lodash/get'

import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateCreateSubscriptionForm = (values) => {
  const {
    nickname,
    billingInterval,
    amount,
    buyerIdentityId,
    buyerInstrumentId,
    enableTrialPhase,
    trialIntervalCount,
    trialIntervalType,
  } = values

  return {
    nickname: fieldEmpty(nickname, 'Subscription Name'),
    billingInterval: fieldEmpty(get(billingInterval, 'value'), 'Billing Frequency'),
    amount: nonEmptyNumber({ field: amount, name: 'Recurring Price' }),
    buyerIdentityId: fieldEmpty(get(buyerIdentityId, 'value'), 'Customer'),
    buyerInstrumentId: fieldEmpty(get(buyerInstrumentId, 'value'), 'Payment instrument'),
    trialIntervalCount: enableTrialPhase ? fieldEmpty(trialIntervalCount, 'Trial Duration') : false,
    trialIntervalType: enableTrialPhase ? fieldEmpty(get(trialIntervalType, 'value')) : false,
  }
}

export default validateCreateSubscriptionForm
