import getCurrentUserRoles from 'utilities/get/getCurrentUserRoles'
import { ADMINISTRATOR } from 'constants/roleConstants'
import { getState } from 'state-layer/configureStore'
import includes from 'lodash/includes'

// TODO:  state still necessary to be passed in or are we always grabbing from Auth0 from now on?
const isAdministrator = (state) => {
  const currentState = state || getState()
  const roles = getCurrentUserRoles(currentState)

  return includes(roles, ADMINISTRATOR)
}

export default isAdministrator
