import createAction from 'utilities/actions/createAction'
import { PATCH_UNDERWRITING_WORKFLOW_F_REQUEST } from 'constants/flowConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { EDIT_UNDERWRITING_WORKFLOW } from 'constants/amplitudeConstants'

const patchUnderwritingWorkflowRequest = ({
  workflowId,
  credentials,
  values,
  successCallback,
}) => createAction({
  type: PATCH_UNDERWRITING_WORKFLOW_F_REQUEST,
  id: workflowId,
  values,
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(EDIT_UNDERWRITING_WORKFLOW, removeUndefined({
      credentials,
      values,
      workflowId,
    })),
    successCallback,
  },
})

export default patchUnderwritingWorkflowRequest
