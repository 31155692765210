import './InputFieldS.scss'
import React from 'react'
import { change, formValueSelector } from 'redux-form'
import { getState } from 'state-layer/configureStore'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Loader from 'components/Shared/Loader/Loader'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import { t, LINK } from 'constants/language/languageConstants'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  EXTERNAL_LINK,
  TIMES_CIRCLE_ICON as CLEAR_INPUT_ICON,
} from 'constants/iconConstants'

const InputField = ({
  id = '',
  input,
  label,
  type = 'text',
  inputMode,
  placeholder,
  disabled = false,
  className,
  autofill = true,
  ariaLabel,
  tooltip = '',
  tooltipPosition,
  helperText,
  autoComplete = 'on',
  required = true,
  successMessage,
  contextLabel,
  link,
  leadingIcon,
  leadingIconString,
  trailingIcon,
  trailingIconString,
  trailingIconAction = () => {},
  isFetching = false,
  meta: {
    touched,
    error,
    valid,
    active,
    form,
    dispatch,
  },
}) => {
  const { name } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const successJSX = successMessage && valid && <span className='success'><i className={`fal fa-${CHECK_CIRCLE_ICON} success-icon`} />{successMessage}</span>
  const inputClassName = name.replace('.', '_')
  const autoCompleteInput = autofill ? autoComplete : 'new-off'
  const hasError = touched && errorJSX ? 'error' : ''
  const formSelector = formValueSelector(form)
  const emptyInput = isEmpty(formSelector(getState(), name))
  const showClearButton = !disabled && !emptyInput && active

  const classNames = classnames({
    InputField: true,
    [inputClassName]: true,
    [className]: !!className,
    'disabled-input': !!disabled,
  })

  const inputAreaClassNames = classnames({
    'input-area': true,
    [hasError]: !!hasError,
    disabled,
    active: !!active && !disabled && !hasError,
    'has-context-label': !!contextLabel,
    'has-leading-icon': !!leadingIcon || !!leadingIconString,
    'has-trailing-icon': !!trailingIcon || !!trailingIconString,
  })

  const clearInput = () => {
    dispatch(change(form, name, ''))
  }

  return (
    <div className={classNames}>
      {label && (
      <div className='flex label-area items-flex-start'>
        <label htmlFor={id} className='label label-2'>{t(label)}</label>

        {required && label && <div className='required-field'>*</div>}

        { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
      </div>
      )}

      <div className={inputAreaClassNames}>
        <div className='input-leading-area flex items-center'>
          {contextLabel && <div className='context-label'>{t(contextLabel)}</div>}

          <div className='input-wrapper flex items-center'>
            {(leadingIcon || leadingIconString) && leadingIconString ? <div className='leading-icon'>{leadingIconString}</div> : <i className={`leading-icon fa fa-${leadingIcon}`} />}

            <input
              id={id}
              aria-label={ariaLabel ? t(ariaLabel) : t(label)}
              {...input}
              type={type}
              inputMode={inputMode}
              placeholder={placeholder}
              disabled={disabled}
              autoComplete={autoCompleteInput}
            />

            <i
              className={`fal fa-${CLEAR_INPUT_ICON} clear-input-icon ${showClearButton ? 'show-clear-icon' : 'hide-clear-icon'}`}
              onClick={clearInput}
            />

            {(!isFetching && (trailingIcon || trailingIconString)) && (
              trailingIconString ?
                <div className='trailing-icon' onClick={trailingIconAction}>{trailingIconString}</div> :
                <i className={`trailing-icon ${trailingIcon}`} onClick={trailingIconAction} />
            )}
          </div>
        </div>

        {isFetching && <Loader />}
      </div>

      { helperText && <div className='helper-text'>{t(helperText)}</div> }

      {link && (
        <a href={link} className='external-link text-link' target='blank'> {t(LINK)} <i className={`fa fa-${EXTERNAL_LINK}`} /></a>
      )}

      { touched && (errorJSX || successJSX) }
    </div>
  )
}

InputField.propTypes = {
  input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  successMessage: PropTypes.string,
  contextLabel: PropTypes.string,
  link: PropTypes.string,
  leadingIconString: PropTypes.string,
  leadingIcon: PropTypes.string,
  trailingIconString: PropTypes.string,
  trailingIcon: PropTypes.string,
  trailingIconAction: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default InputField
