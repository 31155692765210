import { LOCATION_CHANGE } from 'react-router-redux'
import get from 'lodash/get'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  GET_CHART_F_REQUEST,
  GET_CHART_F_SUCCESS,
  GET_CHART_F_FAILURE,
  GET_CHARTS_F_REQUEST,
  GET_CHARTS_F_SUCCESS,
  GET_CHARTS_F_FAILURE,
  GET_HOME_CHARTS_F_REQUEST,
  GET_HOME_CHARTS_F_SUCCESS,
  GET_HOME_CHARTS_F_FAILURE,
  GET_MERCHANT_CHARTS_F_REQUEST,
  GET_MERCHANT_CHARTS_F_SUCCESS,
  GET_MERCHANT_CHARTS_F_FAILURE,
  GET_TRANSACTION_CHARTS_F_REQUEST,
  GET_TRANSACTION_CHARTS_F_SUCCESS,
  GET_TRANSACTION_CHARTS_F_FAILURE,
  GET_CHART_DELTA_F_SUCCESS,
  GET_EXCEPTION_CHARTS_F_REQUEST,
  GET_EXCEPTION_CHARTS_F_SUCCESS,
  GET_EXCEPTION_CHARTS_F_FAILURE,
  GET_UNDERWRITING_CHARTS_F_REQUEST,
  GET_UNDERWRITING_CHARTS_F_SUCCESS,
  GET_UNDERWRITING_CHARTS_F_FAILURE,
} from 'constants/flowConstants'

const chartsR = (state = {}, action = {}) => {
  const id = get(action, 'payload.id')
  const newValues = get(action, 'payload.newValues')
  const items = get(state, 'items', {})

  switch (action.type) {
    case GET_CHART_F_REQUEST:
      return merge({}, state, {
        items: {
          [id]: {
            _isFetching: true,
          },
        },
      })

    case GET_CHART_F_SUCCESS:
      return merge({}, omit(state, `items.${id}.data`), {
        items: {
          [id]: {
            _isFetching: false,
            ...newValues,
          },
        },
      })

    case GET_CHART_DELTA_F_SUCCESS:
      return merge({}, state, {
        items: {
          [id]: {
            ...newValues,
          },
        },
      })


    case GET_CHART_F_FAILURE:
      return merge({}, state, {
        items: {
          [id]: {
            _isFetching: false,
          },
        },
      })

    case GET_CHARTS_F_REQUEST:
      return merge({}, state, {
        _isFetching: true,
      })

    case GET_CHARTS_F_SUCCESS:
      return merge({}, state, {
        _isFetching: false,
        items: newValues,
      })

    case GET_CHARTS_F_FAILURE:
      return merge({}, state, {
        _isFetching: false,
      })

    case GET_HOME_CHARTS_F_REQUEST:
    case GET_TRANSACTION_CHARTS_F_REQUEST:
    case GET_EXCEPTION_CHARTS_F_REQUEST:
    case GET_MERCHANT_CHARTS_F_REQUEST:
    case GET_UNDERWRITING_CHARTS_F_REQUEST: {
      return merge({}, state, {
        _isFetching: true,
      })
    }

    case GET_HOME_CHARTS_F_SUCCESS:
    case GET_TRANSACTION_CHARTS_F_SUCCESS:
    case GET_EXCEPTION_CHARTS_F_SUCCESS:
    case GET_MERCHANT_CHARTS_F_SUCCESS:
    case GET_UNDERWRITING_CHARTS_F_SUCCESS: {
      return merge({}, state, {
        _isFetching: false,
        items: newValues,
      })
    }

    case GET_HOME_CHARTS_F_FAILURE:
    case GET_TRANSACTION_CHARTS_F_FAILURE:
    case GET_EXCEPTION_CHARTS_F_FAILURE:
    case GET_MERCHANT_CHARTS_F_FAILURE:
    case GET_UNDERWRITING_CHARTS_F_FAILURE: {
      return merge({}, state, {
        _isFetching: false,
      })
    }

    case LOCATION_CHANGE:
      return {}

    default:
      return state
  }
}

export default chartsR
