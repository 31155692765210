import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import column from 'utilities/table/column'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { onboardingFormStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'

import {
  createdAtRange,
  updatedAtRange,
} from 'utilities/table/sort'

import {
  APPLICATION,
  CREATED_ON,
  DOING_BUSINESS_AS,
  ID,
  MERCHANT_BUSINESS_NAME,
  COUNTRY,
  STATUS,
  UPDATED_ON,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(UPDATED_ON, 'updatedAt', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
  column(MERCHANT_BUSINESS_NAME, 'onboardingFormData.businessData.businessName', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_BUSINESS_NAME} subtitle={DOING_BUSINESS_AS} />,
    formatter: ({ onboardingFormData }) => (
      <TableItemSubtitle
        title={get(onboardingFormData, 'businessData.businessName')}
        subtitle={get(onboardingFormData, 'businessData.doingBusinessAs')}
      />
    ),
  }),
  column(COUNTRY, 'onboardingFormData.displayCountry', {
    formatter: ({ onboardingFormData }) => {
      const country = get(onboardingFormData, 'country')
      return CountryFlagIcon({ country })
    },
  }),
  column(APPLICATION, 'application.businessName', { condition: isRolePlatform }),
  column(STATUS, 'status', { className: 'small',
    formatter: ({ status }) => <ColorcodedStatus data={onboardingFormStatusMap} value={status} />,
  }),
]

export default columnDescriptors
