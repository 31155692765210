import './AssignUnderwritingEntityToMeS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  ASSIGN,
  CANCEL,
  DO_YOU_WANT_TO_ASSIGN_THIS,
} from 'constants/language/languageConstants'

const AssignUnderwritingEntityToMe = ({
  closeModal = () => {},
  assignToSelf = () => {},
}) => {
  return (
    <div className='AssignUnderwritingEntityToMe'>
      <div className='text'>{DO_YOU_WANT_TO_ASSIGN_THIS}</div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={assignToSelf} label={ASSIGN} />
      </div>
    </div>
  )
}

AssignUnderwritingEntityToMe.propTypes = {
  closeModal: PropTypes.func,
  assignToSelf: PropTypes.func,
}

export default AssignUnderwritingEntityToMe
