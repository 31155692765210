import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingInformation from './UnderwritingInformation'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import showModalAction from 'utilities/actions/showModalAction'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getCurrentUser from 'utilities/get/getCurrentUser'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import toString from 'lodash/toString'

import {
  APPROVE,
  AUTOMATED_UNDERWRITING,
  COMPLIANCE_SCORE,
  CREATE_NEW_RUN,
  LATEST_RUN,
  NEXT_RUN_ON,
  RECOMMENDED_ACTION,
  REJECT,
  REQUEST_ADDITIONAL_VERIFICATION,
  REVIEW_CADENCE,
  UNASSIGNED,
  WITHDRAW,
} from 'constants/language/languageConstants'

import {
  CREATE_NEW_RUN_MODAL,
  REJECT_UNDERWRITING_ENTITY_MODAL,
  REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL,
  WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL,
} from 'constants/modalConstants'

import {
  CHANGE_REQUESTED,
  CLOSED,
  MANUAL_REVIEW,
  REJECTED,
} from 'constants/underwritingConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)

  const [
    reviewId,
    subjectId,
    isApplicationUnderwriting,
    isMerchantUnderwriting,
  ] = getMany(props, [
    'reviewId',
    'subjectId',
    'isApplicationUnderwriting',
    'isMerchantUnderwriting',
  ])

  const review = getUnderwritingReviewSelector(state, reviewId)
  const reviewType = get(review, 'reviewType')
  const runId = get(review, 'run.id')
  const currentUserEmail = get(getCurrentUser(), 'userEmail')
  const assignee = get(review, 'assignee')
  const dropdownActionsDisabled = (!assignee || assignee === UNASSIGNED) || isFetching || currentUserEmail !== assignee
  let dropdownActionsDisabledMessage = ''

  if (!assignee || assignee === UNASSIGNED) {
    dropdownActionsDisabledMessage = 'An assignee is required for an action to be taken.'
  }

  if (assignee && currentUserEmail !== assignee) {
    dropdownActionsDisabledMessage = 'Only the assignee can take an action.'
  }

  const [
    complianceScore = 'N/A',
    identityScore = 'N/A',
    recommendation,
    reviewState,
    reasons = [],
    automatedUnderwriting,
    displayLastRun,
    displayNextRun,
    cadence,
  ] = getMany(review, [
    'run.decision.scores.COMPLIANCE',
    'run.decision.scores.IDENTITY',
    'run.decision.recommendation',
    'state',
    'reasons',
    'run.decision.displayActOnDecision',
    'displayLastRun',
    'displayNextRun',
    'cadence',
  ])

  let identityScoreColor = identityScore === 'N/A' ? 'gray' : 'green'
  let complianceScoreColor = complianceScore === 'N/A' ? 'gray' : 'green'

  if (parseInt(identityScore, 10) < 70) {
    identityScoreColor = 'yellow'

    if (parseInt(identityScore, 10) < 50) {
      identityScoreColor = 'red'
    }
  }

  if (parseInt(complianceScore, 10) < 70) {
    complianceScoreColor = 'yellow'

    if (parseInt(complianceScore, 10) < 50) {
      complianceScoreColor = 'red'
    }
  }

  const formattedReasons = map(reasons, ({ code, description }) => {
    return { code: snakeCaseToTitleCase({ key: code }), description }
  })

  const summaryDataSection = convertPageSectionDataToV2([
    { label: RECOMMENDED_ACTION, value: <UnderwritingReviewStatus value={recommendation} /> },
    { label: COMPLIANCE_SCORE, value: toString(complianceScore) },
    { label: AUTOMATED_UNDERWRITING, value: <EnabledStatus value={automatedUnderwriting} /> },
    { label: REVIEW_CADENCE, value: cadence },
    { label: LATEST_RUN, value: displayLastRun },
    { label: NEXT_RUN_ON, value: displayNextRun },
  ])

  return {
    isFetching,
    reviewId,
    subjectId,
    reviewState,
    identityScoreColor,
    identityScore,
    complianceScoreColor,
    complianceScore,
    isMerchantUnderwriting,
    isApplicationUnderwriting,
    recommendation,
    automatedUnderwriting,
    formattedReasons,
    dropdownActionsDisabled,
    dropdownActionsDisabledMessage,
    summaryDataSection,
    reviewType,
    runId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showWithdrawOrApproveUnderwritingEntityModal: (modalProps) => dispatch(showModalAction({ modalType: WITHDRAW_OR_APPROVE_UNDERWRITING_ENTITY_MODAL, modalProps, className: 'modal-md no-pad' })),
    showRejectUnderwritingEntityModal: (modalProps) => dispatch(showModalAction({ modalType: REJECT_UNDERWRITING_ENTITY_MODAL, modalProps, className: 'modal-lg no-pad' })),
    showRequestAdditionalVerificationForUnderwritingEntityModal: (modalProps) => dispatch(showModalAction({ modalType: REQUEST_ADDITIONAL_INFORMATION_FOR_UNDERWRITING_ENTITY_MODAL, modalProps, className: 'modal-lg no-pad' })),
    showCreateNewRunModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_NEW_RUN_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class UnderwritingInformationC extends Component {
  render() {
    const {
      showWithdrawOrApproveUnderwritingEntityModal,
      showRejectUnderwritingEntityModal,
      showRequestAdditionalVerificationForUnderwritingEntityModal,
      showCreateNewRunModal,
      reviewId,
      subjectId,
      entityId,
      reviewState,
      isMerchantUnderwriting,
      isApplicationUnderwriting,
      isPastRunView,
      reviewType,
      runId,
    } = this.props

    const underwritingDecisionActions = isPastRunView ? [] : [
      {
        label: CREATE_NEW_RUN,
        action: () => showCreateNewRunModal({ reviewId, subjectId, entityId }),
        condition: isApplicationUnderwriting && reviewState === CHANGE_REQUESTED,
      },
      {
        label: APPROVE,
        action: () => showWithdrawOrApproveUnderwritingEntityModal({ isApproveMerchantModal: true, reviewId, subjectId, isMerchantUnderwriting, reviewType, runId }),
        condition: reviewState === MANUAL_REVIEW,
      },
      {
        label: REQUEST_ADDITIONAL_VERIFICATION,
        action: () => showRequestAdditionalVerificationForUnderwritingEntityModal({ reviewId, subjectId }),
        condition: reviewState === MANUAL_REVIEW,
      },
      {
        label: REJECT,
        action: () => showRejectUnderwritingEntityModal({ reviewId, subjectId, isMerchantUnderwriting }),
        condition: reviewState !== CLOSED && reviewState !== REJECTED,
      },
      {
        label: WITHDRAW,
        action: () => showWithdrawOrApproveUnderwritingEntityModal({ isApproveMerchantModal: false, reviewId, subjectId, isMerchantUnderwriting }),
        condition: reviewState !== CLOSED,
      },
    ]

    return (
      <UnderwritingInformation
        underwritingDecisionActions={underwritingDecisionActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingInformationC)
