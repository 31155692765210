import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING,
} from 'constants/apiConstants'

const patchIdentityPayoutPlanInstrumentMappingAPI = ({ values, id, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    path: IDENTITY_PAYOUT_PLAN_INSTRUMENT_MAPPING({ identityId: id }),
    credentials,
    service: IDENTITIES,
  })
}

export default patchIdentityPayoutPlanInstrumentMappingAPI
