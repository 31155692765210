import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResolveMonitoringAlertsForm from 'components/Customer/Forms/ResolveMonitoringAlertsForm/ResolveMonitoringAlertsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)

  return {
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ResolveMonitoringAlertsFormC extends Component {
  render() {
    return (
      <ResolveMonitoringAlertsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolveMonitoringAlertsFormC)
