import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ROLE } from 'constants/apiConstants'

const getRoleAPI = ({ credentials, id, meta }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_SERVICE_ROLE({ roleId: id }),
    meta,
  })
}

export default getRoleAPI
