import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { UNDERWRITING_EVENTS } from 'constants/linkConstants'
import { getAuditLogEventsSelector } from 'state-layer/selectors'

import {
  GET_AUDIT_LOG_EVENTS,
  AUDIT_LOG_EVENT,
} from 'constants/flowConstants'

import {
  AUDIT_LOG,
  AUDIT_LOG_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const IdentityAuditLogs = ({
  initialQueries,
  isFetching = false,
  auditLogEventPath = '',
}) => {
  return (
    <div className='IdentityAuditLogs'>
      <TableC
        flow={GET_AUDIT_LOG_EVENTS}
        columnDescriptors={columnDescriptors}
        selector={getAuditLogEventsSelector}
        initialQueries={initialQueries}
        linksKey={UNDERWRITING_EVENTS}
        isFetching={isFetching}
        path={auditLogEventPath}
        entityType={AUDIT_LOG_EVENT}
        emptyMessage={AUDIT_LOG_EMPTY_TABLE_MESSAGE}
      />
    </div>
  )
}

IdentityAuditLogs.propTypes = {
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  auditLogEventPath: PropTypes.string,
}

export default IdentityAuditLogs
