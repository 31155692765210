import React from 'react'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  createdAtRange,
  updatedAtRange,
} from 'utilities/table/sort'

import {
  ID,
  UPDATED_ON,
  ASSIGNEE,
  ONBOARDING_STATE_TITLE,
  UNDERWRITING_STATUS,
  CREATED_ON,
  COUNTRY,
  APPLICATION,
  RECIPIENT_NAME,
  RECIPIENT_TYPE,
  BUSINESS,
  PERSONAL,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedOn', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdOn }) => <DateTimestampColumn timestamp={createdOn} />,
  }),
  column(UPDATED_ON, 'displayUpdatedOn', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedOn }) => <DateTimestampColumn timestamp={updatedOn} />,
  }),
  column(RECIPIENT_NAME, 'recipientName', {
    className: 'name',
    headerFormatter: () => <TableItemSubtitle title={RECIPIENT_NAME} subtitle={APPLICATION} />,
    formatter: (review) => <TableItemSubtitle title={get(review, 'entity.businessName') || get(review, 'entity.fullName')} subtitle={get(review, 'entity.application.businessName')} />,
  }),
  column(RECIPIENT_TYPE, 'recipientType', {
    className: 'small',
    formatter: (review) => { return get(review, 'entity.businessName') ? BUSINESS : PERSONAL },
  }),
  column(COUNTRY, 'displayCountry', { className: 'small' }),
  column(ASSIGNEE, 'assignee'),
  column(UNDERWRITING_STATUS, 'state', {
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
  column(ONBOARDING_STATE_TITLE, 'entity.merchantProcessorOnboardingStatesString', {
    className: 'medium',
    formatter: (review) => {
      const id = get(review, 'id')
      const merchants = get(review, 'entity.merchants')
      return <ul className='onboarding-states'>{ map(merchants, ({ onboardingState, processor }) => (<li key={`${id}-${processor}-${onboardingState}`}><OnboardingState value={onboardingState} /></li>)) }</ul>
    },
  }),
]

export default columnDescriptors
