import frontendAuditLogEventModel from './frontendAuditLogEventModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendAuditLogEventsModel = ({ data: events }) => {
  const auditLogEventModels = map(events, (event) => frontendAuditLogEventModel({ data: event }))

  return keyBy(auditLogEventModels, 'id')
}

export default frontendAuditLogEventsModel
