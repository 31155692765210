import getAuthorizationAPI from 'api/finix/get/getAuthorizationAPI'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getReceiptsAPI from 'api/finix/get/getReceiptsAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import frontendDeviceModel from 'utilities/create/models/frontendDeviceModel'
import frontendReceiptsModel from 'utilities/create/models/frontendReceiptsModel'
import normalizeResponse from 'utilities/api/normalizeResponse'
import frontendFeesModel from 'utilities/create/models/frontendFeesModel'
import getFeesAPI from 'api/finix/get/getFeesAPI'
import get from 'lodash/get'
import merge from 'lodash/merge'
import co from 'co'

import {
  DEVICE,
  IDENTITY,
} from 'constants/apiConstants'

function * getAuthorizationO ({
  id,
  credentials,
}) {
  const { data: response } = yield getAuthorizationAPI({ id, credentials })
  const links = getResponseLinks(response)

  const {
    source,
    destination,
    application: applicationId,
  } = response

  const paymentInstrumentId = source || destination

  const deviceId = get(response, 'device')
  let deviceResponseModel

  if (deviceId) {
    // TODO: device reducer?
    const { data: deviceResponse } = yield getPaymentsAPI({ path: DEVICE({ deviceId }), credentials })
    deviceResponseModel = deviceResponse ? frontendDeviceModel({ data: deviceResponse }) : undefined
  }

  const {
    transfer: transferLink,
    application: applicationLink,
    merchant_identity: merchantIdentityLink,
  } = links

  const [
    { data: paymentInstrument },
    { data: application },
    { data: merchantIdentity },
    { data: transfer },
  ] = yield [
    paymentInstrumentId ? getPaymentInstrumentAPI({ id: paymentInstrumentId, credentials }) : {},
    applicationLink ? getPaymentsAPI({ absolutePath: applicationLink, credentials }) : {},
    merchantIdentityLink ? getPaymentsAPI({ absolutePath: merchantIdentityLink, credentials }) : {},
    transferLink ? getPaymentsAPI({ absolutePath: transferLink, credentials }) : {},
  ]

  // get buyer identity from the payment instrument
  const buyerIdentityId = get(paymentInstrument, 'identity')
  const { data: buyerIdentityResponse } = yield getPaymentsAPI({ path: IDENTITY({ identityId: buyerIdentityId }), credentials })
  const buyerIdentityResponseModel = frontendIdentityModel({ data: normalizeResponse({ content: buyerIdentityResponse }) })

  // receipts
  let receiptsResponseModel
  if (id) {
    const { data: receiptsResponse } = yield getReceiptsAPI({ credentials, queries: { entity_id: id } })
    const receipts = get(receiptsResponse, '_embedded.receipts')
    receiptsResponseModel = receipts ? frontendReceiptsModel({ data: normalizeResponse({ content: receipts }) }) : undefined
  }

  const { data: feesResponse } = yield getFeesAPI({
    credentials,
    queries: {
      linked_id: id,
    },
  })

  const fees = get(feesResponse, '_embedded.fees')
  const feesModels = fees ? frontendFeesModel({ data: normalizeResponse({ content: fees }) }) : undefined

  // TODO: just realized that all transfer data needs to be normalized before running through a model in OL, should refactor?
  const normalizedTransfer = normalizeResponse({ content: transfer })

  const paymentInstrumentModel = frontendPaymentInstrumentModel({ data: paymentInstrument })
  const applicationModel = frontendApplicationModel({ data: application })
  const merchantIdentityModel = frontendIdentityModel({ data: merchantIdentity })
  const transferModel = frontendTransferModel({ data: normalizedTransfer })

  const authorization = merge({}, response, {
    paymentInstrument: paymentInstrumentModel,
    applicationId,
    applicationData: applicationModel,
    merchantIdentity: merchantIdentityModel,
    buyerIdentity: buyerIdentityResponseModel,
    transferData: transferModel,
    deviceId,
    device: deviceResponseModel,
    receipts: receiptsResponseModel,
    fees: feesModels,
  })

  return createSuccessResponse({
    data: [authorization],
  })
}

export default co.wrap(getAuthorizationO)
