import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateFeeForm from 'components/Customer/Forms/CreateFeeForm/CreateFeeForm'

const CreateFeeModal = ({
  credentials,
  identityMerchants,
}) => {
  return (
    <GenericModal
      title='Create Fee'
      className='CreateFeeModal'
      Component={CreateFeeForm}
      credentials={credentials}
      identityMerchants={identityMerchants}
    />
  )
}

CreateFeeForm.propTypes = {
  credentials: PropTypes.object,
  identityMerchants: PropTypes.object,
}

export default CreateFeeModal
