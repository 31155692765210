import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM } from 'constants/apiConstants'
import get from 'lodash/get'
import omit from 'lodash/omit'

const patchCustomerOnboardingFormAccessFormAPI = ({ id, values }) => {
  const accessFormId = get(values, 'access_form_id')
  const valuesToPatch = omit(values, ['access_form_id'])

  return dashboardAPI.patch({
    path: DASHBOARD_CUSTOMER_ONBOARDING_FORM_ACCESS_FORM({ customerOnboardingFormId: id, accessFormId }),
    values: valuesToPatch,
  })
}

export default patchCustomerOnboardingFormAccessFormAPI
