import getCurrentAuthSession from 'utilities/get/getCurrentAuthSession'
import getMany from 'utilities/get/getMany'

const getDashboardUser = () => {
  const authSession = getCurrentAuthSession()

  const [
    profile,
  ] = getMany(authSession, [
    'profile',
  ])

  return profile
}

export default getDashboardUser
