import './ChangePasswordFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import PasswordInputFieldC from 'components/Shared/Inputs/PasswordInputField/PasswordInputFieldC'
import Button from 'components/Shared/Button/Button'
import submitChangePasswordForm from 'utilities/submit/submitChangePasswordForm'
import validateChangePasswordForm from 'utilities/validate/validateChangePasswordForm'
import { CHANGE_PASSWORD_FORM } from 'constants/formConstants'

import {
  CHANGE_PASSWORD,
  OLD_PASSWORD,
  NEW_PASSWORD,
  CANCEL,
} from 'constants/language/languageConstants'

const ChangePasswordForm = ({
  handleSubmit = () => {},
  invalid = false,
  closeModal = () => {},
  passwordRequirements = [],
}) => {
  return (
    <form className='ChangePasswordForm' onSubmit={handleSubmit(submitChangePasswordForm)}>
      <Field
        className='old-password-input'
        name='oldPassword'
        label={OLD_PASSWORD}
        component={PasswordInputFieldC}
      />

      <Field
        className='new-password-input'
        name='password'
        label={NEW_PASSWORD}
        component={PasswordInputFieldC}
        passwordRequirements={passwordRequirements}
        formName={CHANGE_PASSWORD_FORM}
      />

      <div className='buttons flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} label={CHANGE_PASSWORD} />
      </div>
    </form>
  )
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  closeModal: PropTypes.func,
  passwordRequirements: PropTypes.array,
}

export default reduxForm({
  form: CHANGE_PASSWORD_FORM,
  validate: validateChangePasswordForm,
})(ChangePasswordForm)
