import './WebhookLogS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import APIResponse from 'components/Customer/Shared/Display/APIResponse/APIResponse'
import getWebhookLogAPI from 'api/finix/get/getWebhookLogAPI'

import {
  DETAILS,
  WEBHOOK,
  WEBHOOK_EVENT_ID,
  WEBHOOK_ID,
  HEADERS,
  EVENT_REQUEST_BODY,
} from 'constants/language/languageConstants'

const WebhookLog = ({
  headerData = {},
  contextBarData = {},
  detailsSectionData = [],
  webhookSectionData = [],
  headersSectionData = [],
  isFetching = false,
  webhookLink,
  eventId = '',
  webhookId = '',
  webhookLogId = '',
  credentials = {},
}) => {
  return (
    <div className='WebhookLog'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={DETAILS}
        className='details-section'
        columns={detailsSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={WEBHOOK}
        className='webhook-details-section'
        columns={webhookSectionData}
        isFetching={isFetching}
        clickToCopies={[{ label: WEBHOOK_ID, content: webhookId }]}
        asideTitle={webhookLink}
      />

      <PageSectionV2C
        title={HEADERS}
        className='headers-section'
        columns={headersSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={EVENT_REQUEST_BODY}
        className='event-request-section'
        clickToCopies={[{ label: WEBHOOK_EVENT_ID, content: eventId }]}
      >
        <APIResponse
          api={getWebhookLogAPI}
          apiProps={{ id: webhookLogId, credentials }}
          getKey='data.webhook_event'
        />
      </PageSectionV2C>
    </div>
  )
}

WebhookLog.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsSectionData: PropTypes.array,
  webhookSectionData: PropTypes.array,
  headersSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  webhookId: PropTypes.string,
  webhookLogId: PropTypes.string,
  webhookLink: PropTypes.object,
  eventId: PropTypes.string,
  credentials: PropTypes.object,
}

export default WebhookLog
