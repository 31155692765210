import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import MerchantSettingFormC from 'components/Customer/Forms/MerchantSettingForm/MerchantSettingFormC'

const MerchantSettingModal = ({
  actionType,
  credentials,
  record,
}) => {
  return (
    <GenericModal
      title='Update Merchant Settings'
      className='MerchantSettingModal'
      Component={MerchantSettingFormC}
      actionType={actionType}
      credentials={credentials}
      record={record}
    />
  )
}

MerchantSettingModal.propTypes = {
  actionType: PropTypes.string,
  credentials: PropTypes.object,
  record: PropTypes.object,
}

export default MerchantSettingModal
