import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getSubscriptionScheduleAPI from 'api/finix/get/getSubscriptionScheduleAPI'
import getSubscriptionScheduleAmountsAPI from 'api/finix/get/getSubscriptionScheduleAmountsAPI'
import frontendSubscriptionAmountsModel from 'utilities/create/models/frontendSubscriptionAmountsModel'
import merge from 'lodash/merge'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import co from 'co'

function * getSubscriptionScheduleAmountsO ({
  id,
  credentials,
}) {
  const schedule = get(yield getSubscriptionScheduleAPI({ id, credentials }), 'data')
  const amounts = get(yield getSubscriptionScheduleAmountsAPI({ id, credentials }), 'data._embedded.subscription_amounts')
  const amountsModels = frontendSubscriptionAmountsModel({ data: amounts })

  const mergedSchedule = merge({}, schedule, {
    amounts: keyBy(amountsModels, 'id'),
  })

  return createSuccessResponse({
    data: mergedSchedule,
  })
}

export default co.wrap(getSubscriptionScheduleAmountsO)
