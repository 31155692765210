import { GET_ROLE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getRoleRequest = ({
  roleId,
  credentials,
}) => createAction({
  type: GET_ROLE_F_REQUEST,
  id: roleId,
  credentials,
})

export default getRoleRequest
