import React from 'react'
import PropTypes from 'prop-types'
import PayoutPlanContentsC from 'components/Customer/Pages/PayoutPlan/PayoutPlanContentsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const PayoutPlan = ({
  isFetching = false,
  payoutPlanId = {},
  detailsSectionData = [],
  headerData = {},
  contextBarData = {},
}) => {
  return (
    <div className='PayoutPlan'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      <PageSectionV2C
        title='Details'
        columns={detailsSectionData}
        isFetching={isFetching}
      />

      <PayoutPlanContentsC
        isFetching={isFetching}
        payoutPlanId={payoutPlanId}
      />
    </div>
  )
}

PayoutPlan.propTypes = {
  isFetching: PropTypes.bool,
  payoutPlanId: PropTypes.string,
  detailsSectionData: PropTypes.object,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default PayoutPlan
