import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  REBOOT_DEVICE,
  CANCEL,
} from 'constants/language/languageConstants'

const RebootDevice = ({
  deviceName,
  rebootDevice = () => {},
  closeModal = () => {},
  isRebooting = false,
}) => {
  return (
    <div className='RebootDevice'>
      <div className='modal-content reboot-confirmation-msg'>
        {`Please confirm that you would like to reboot ${deviceName}. This might take over a minute.`}
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} className='cancel-button' label={CANCEL} variant='ghost' />
        <Button type='submit' onClick={rebootDevice} submitting={isRebooting} label={REBOOT_DEVICE} />
      </div>
    </div>
  )
}

RebootDevice.propTypes = {
  deviceName: PropTypes.string,
  rebootDevice: PropTypes.func,
  closeModal: PropTypes.func,
  isRebooting: PropTypes.bool,
}

export default RebootDevice
