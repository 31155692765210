import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  SUCCEEDED,
  CANCELED,
} from 'constants/statusConstants'

const EnabledData = {
  Enabled: SUCCEEDED,
  Disabled: CANCELED,
  Active: SUCCEEDED,
  Inactive: CANCELED,
  True: SUCCEEDED,
  False: CANCELED,
}

const EnabledStatus = ({
  value = '',
  customValue,
}) => <ColorcodedStatus data={EnabledData} value={value} customValue={customValue} />

EnabledStatus.propTypes = {
  value: PropTypes.string,
  customValue: PropTypes.string,
}

export default EnabledStatus
