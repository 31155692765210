import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import isMerchantManagerRole from 'utilities/is/isMerchantManagerRole'
import MerchantSettingForm from 'components/Customer/Forms/MerchantSettingForm/MerchantSettingForm'
import { MERCHANT_SETTING_FORM } from 'constants/formConstants'
import { isFlexPlatform } from 'constants/flexConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const showOptionalMerchantIdentifierFields = isRolePlatform({ credentials }) && isFlexPlatform() && (isAdministratorRole(credentials) || isMerchantManagerRole(credentials))

  return {
    showOptionalMerchantIdentifierFields,
  }
}

class MerchantSettingFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      record,
    } = this.props

    const {
      id,
      processingEnabled,
      settlementEnabled,
      settlementFundingIdentifier,
      cardCvvRequired,
      convenienceChargesEnabled,
      cardExpirationDateRequired,
      levelTwoLevelThreeDataEnabled,
      rentSurchargesEnabled,
      surchargesEnabled,
      visaMerchantVerificationValue,
      mastercardAssignedId,
    } = record

    initialize({
      id,
      processingEnabled,
      settlementEnabled,
      settlementFundingIdentifier,
      cardCvvRequired,
      convenienceChargesEnabled,
      cardExpirationDateRequired,
      levelTwoLevelThreeDataEnabled,
      rentSurchargesEnabled,
      surchargesEnabled,
      visaMerchantVerificationValue,
      mastercardAssignedId,
    })
  }

  render() {
    return (
      <MerchantSettingForm
        {...this.props}
        isRolePlatformCred={isRolePlatform({ credentials: getCurrentCredentials() })}
      />
    )
  }
}

MerchantSettingFormC.propTypes = {
  initialize: PropTypes.func,
  record: PropTypes.object,
}

export default connect(mapStateToProps)(reduxForm({
  form: MERCHANT_SETTING_FORM,
})(MerchantSettingFormC))
