import './ReviewAndSubmitFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import FeeProfileRulesC from 'components/Customer/Pages/FeeProfileRules/FeeProfileRulesC'
import submitEditFeeProfileRulesForm from 'utilities/submit/submitEditFeeProfileRulesForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { EDIT_FEE_PROFILE_RULES_REVIEW_AND_SUBMIT_FORM } from 'constants/formConstants'

import {
  CANCEL,
  PREVIOUS_STEP,
  SUBMIT,
} from 'constants/language/languageConstants'

const ReviewAndSubmitForm = ({
  handleSubmit = () => {},
  feeProfileRulesFormValues = {},
  entityId = '',
}) => {
  return (
    <form className='ReviewAndSubmitForm' onSubmit={handleSubmit(submitEditFeeProfileRulesForm)}>
      <div className='form-content'>
        <FeeProfileRulesC
          feeProfileRulesForReviewPage={feeProfileRulesFormValues}
          isReviewPage
          entityId={entityId}
        />
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => prevFlowStep()} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' label={SUBMIT} />
          </div>
        </div>
      </div>
    </form>
  )
}

ReviewAndSubmitForm.propTypes = {
  handleSubmit: PropTypes.func,
  feeProfileRulesFormValues: PropTypes.object,
  entityId: PropTypes.string,
}

export default reduxForm({
  form: EDIT_FEE_PROFILE_RULES_REVIEW_AND_SUBMIT_FORM,
})(ReviewAndSubmitForm)
