import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_FILE } from 'constants/apiConstants'

const postDashboardFileAPI = ({ values, meta }) => {
  const path = DASHBOARD_SERVICE_FILE

  return dashboardAPI.post({
    path,
    values,
    meta,
  })
}

export default postDashboardFileAPI
