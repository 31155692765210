import React from 'react'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  ALL,
  FAILED,
  CANCELED,
} from 'constants/transferConstants'

import {
  ID,
  TYPE,
  STATE,
  AMOUNT,
  MERCHANT,
  RECIPIENT,
  CREATED_ON,
  APPLICATION,
  REASON_CODE,
  MASKED_ACCOUNT_NUMBER,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
  }),
  column(RECIPIENT, 'paymentInstrument.name', {
    className: 'medium',
  }),
  column(AMOUNT, ['amount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column(STATE, 'state', {
    className: 'small',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ state, raw }) => (
      <TableItemSubtitle
        titleComponent={() => <TransferStatus value={state} />}
        subtitle={get(raw, 'Reason Message')}
        subtitleIndent
        emptySignifierCheck={state === FAILED || state === CANCELED}
      />
    ),
  }),
  column(MASKED_ACCOUNT_NUMBER, 'paymentInstrument.maskedAccountNumber'),
  column(TYPE, 'displayType', { className: 'small' }),
]

export default columnDescriptors
