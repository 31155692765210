import split from 'lodash/split'
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'
import normalizeQueries from 'utilities/api/normalizeQueries'

// TODO: look into using url-parse https://www.npmjs.com/package/url-parse
export const parseUrlQueries = (url, normalizationMap) => {
  let queries = {}
  const query = url ? split(url, '?')[1] : window.location.search.substring(1)
  const queryVars = query ? query.split('&') : []

  forEach(queryVars, (queryVar = '') => {
    const firstEqualsIndex = queryVar.indexOf('=')
    const key = queryVar.substring(0, firstEqualsIndex)
    const value = queryVar.substring(firstEqualsIndex + 1)

    queries = merge({}, queries, {
      [key]: decodeURIComponent(value),
    })
  })

  const normalizedQueries = normalizationMap ? normalizeQueries({ queries, normalizationMap }) : queries

  return normalizedQueries
}
