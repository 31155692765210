import './CreateTransactionReviewFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import Loader from 'components/Shared/Loader/Loader'
import LevelThreeItemsSectionData from 'components/Customer/Shared/Display/LevelThreeItemsSectionData'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import ReactSelectCreatable from 'components/Shared/Inputs/ReactSelectCreatable/ReactSelectCreatable'
import submitCreateTransactionReviewForm from 'utilities/submit/submitCreateTransactionReviewForm'
import validateCreateTransactionReviewForm from 'utilities/validate/validateCreateTransactionReviewForm'
import { CREATE_TRANSACTION_REVIEW_FORM } from 'constants/formConstants'

import {
  BILLING_DETAILS,
  PURCHASE_DETAILS,
  EDIT,
  CANCEL,
  PROCESS_PAYMENT,
  REVIEW_PAYMENT,
  ADDITIONAL_DATA,
  REVIEW_AUTHORIZATION,
  PROCESS_AUTHORIZATION,
  ACH_TRANSACTION_REVIEW_HEADER,
  TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS,
  TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF,
  EMAIL_MULTI_INPUT_HELPER_TEXT,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
} from 'constants/language/languageConstants'

const CreateTransactionReviewForm = ({
  handleSubmit = () => {},
  purchaseDetailsSectionData = [],
  paymentDetailsSectionData = [],
  levelTwoAdditionalDataSectionData = [],
  levelThreeAdditionalDataSectionData = [],
  goToPreviousStep = () => {},
  isFetching = false,
  isFetchingMerchantData = false,
  levelTwoEnabled = false,
  levelThreeEnabled = false,
  levelThreeItems = [],
  isSale,
  isACHTransaction = false,
  achLegalText,
  sendReceiptToBuyer = false,
  isFetchingReceiptData = false,
  invalid = false,
}) => {
  return (
    <form className='CreateTransactionReviewForm' onSubmit={handleSubmit(submitCreateTransactionReviewForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{isSale ? REVIEW_PAYMENT : REVIEW_AUTHORIZATION}</h3>
      </div>

      { isFetchingMerchantData && <Loader /> }

      { !isFetchingMerchantData && (
        <>
          <div className='form-content'>
            <PageSectionV2
              smallTitle={PURCHASE_DETAILS}
              className='purchase-details'
              columns={purchaseDetailsSectionData}
            />

            <PageSectionV2
              smallTitle={BILLING_DETAILS}
              className='billing-details'
              columns={paymentDetailsSectionData}
            />

            {levelTwoEnabled && (
              <PageSectionV2
                smallTitle={ADDITIONAL_DATA}
                className='additional-data-section'
                columns={levelTwoAdditionalDataSectionData}
              />
            )}

            { levelThreeEnabled && (
              <>
                <PageSectionV2
                  smallTitle={ADDITIONAL_DATA}
                  className='additional-data-section'
                  columns={levelThreeAdditionalDataSectionData}
                />

                <LevelThreeItemsSectionData levelThreeItems={levelThreeItems} />
              </>
            )}

            {isACHTransaction && (
              <div className='ach-legal-text'>
                <div className='header p-3-bold'>{ACH_TRANSACTION_REVIEW_HEADER}</div>
                {achLegalText}
              </div>
            )}
          </div>

          <div className='receipt-details'>
            <div className='buyer-email'>
              <Field
                name='sendReceiptToBuyer'
                label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS}
                component={CheckboxC}
                value={sendReceiptToBuyer}
              />


              {sendReceiptToBuyer && (
                <Field
                  name='buyerEmails'
                  component={ReactSelectCreatable}
                  helperText={EMAIL_MULTI_INPUT_HELPER_TEXT}
                  disableDropdown
                  placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
                />
              )}
            </div>

            <Field
              name='sendReceiptToSelf'
              label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF}
              component={CheckboxC}
            />
          </div>

          <div className='form-footer flex'>
            <div className='footer-button-container'>
              <div className='previous-step-container'>
                <Button onClick={() => goToPreviousStep()} className='prev-step-button' label={EDIT} variant='tertiary' />
              </div>
              <div className='exit-and-save-container'>
                <Button onClick={goBack} className='cancel-button' label={CANCEL} variant='ghost' />
                <Button type='submit' className='submit-button' disabled={invalid || isFetching || isFetchingReceiptData} submitting={isFetching || isFetchingReceiptData} label={isSale ? PROCESS_PAYMENT : PROCESS_AUTHORIZATION} />
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

CreateTransactionReviewForm.propTypes = {
  purchaseDetailsSectionData: PropTypes.array,
  paymentDetailsSectionData: PropTypes.array,
  levelTwoAdditionalDataSectionData: PropTypes.array,
  levelThreeAdditionalDataSectionData: PropTypes.array,
  goToPreviousStep: PropTypes.func,
  isFetching: PropTypes.bool,
  isFetchingMerchantData: PropTypes.bool,
  levelTwoEnabled: PropTypes.bool,
  levelThreeEnabled: PropTypes.bool,
  levelThreeItems: PropTypes.array,
  isSale: PropTypes.bool,
  isACHTransaction: PropTypes.bool,
  achLegalText: PropTypes.object,
  sendReceiptToBuyer: PropTypes.bool,
  isFetchingReceiptData: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_TRANSACTION_REVIEW_FORM,
  validate: validateCreateTransactionReviewForm,
  touchOnChange: true,
})(CreateTransactionReviewForm)
