import React, { Component } from 'react'
import { connect } from 'react-redux'
import LiveAccountApplicationFlow from 'components/Customer/Pages/LiveAccountApplicationFlow/LiveAccountApplicationFlow'
import LiveAccountApplicationPricingFormC from 'components/Customer/Forms/LiveAccountApplicationPricingInfoForm/LiveAccountApplicationPricingFormC'
import LiveAccountApplicationBusinessFormC from 'components/Customer/Forms/LiveAccountApplicationBusinessForm/LiveAccountApplicationBusinessFormC'
import LiveAccountApplicationOwnerInfoFormC from 'components/Customer/Forms/LiveAccountApplicationOwnerInfoForm/LiveAccountApplicationOwnerInfoFormC'
import LiveAccountApplicationProcessingInfoFormC from 'components/Customer/Forms/LiveAccountApplicationProcessingInfoForm/LiveAccountApplicationProcessingInfoFormC'
import LiveAccountApplicationBankAccountInfoFormC from 'components/Customer/Forms/LiveAccountApplicationBankAccountInfoForm/LiveAccountApplicationBankAccountInfoFormC'
import LiveAccountApplicationReviewAndSubmitFormC from 'components/Customer/Forms/LiveAccountApplicationReviewAndSubmitForm/LiveAccountApplicationReviewAndSubmitFormC'
import { getAccessFormSelector } from 'state-layer/selectors'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import showModalAction from 'utilities/actions/showModalAction'
import updateUrlQueries from 'utilities/updateUrlQueries'
import getAccessFormRequest from 'utilities/actions/get/getAccessFormRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import prevFlowStep from 'utilities/prevFlowStep'
import { MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES } from 'constants/identityConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

import {
  CANCEL_APPLICATION_FORM_MODAL,
  EXIT_APPLICATION_FORM_MODAL,
  FORM_FILE_UPLOADER_MODAL,
  GO_TO_PREVIOUS_STEP_MODAL,
} from 'constants/modalConstants'

import {
  PRICING_INFO,
  BUSINESS_INFO,
  OWNER_INFO,
  PROCESSING_INFO,
  BANK_ACCOUNT_INFO,
  REVIEW_AND_SUBMIT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const dashboardConfig = getCurrentDashboardConfig(state)
  const dashboardType = get(dashboardConfig, 'dashboardType')
  const accessFormId = get(props, 'params.accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const accessFormCountry = get(accessForm, 'country')

  // hide or show the welcome page based on url queries
  const urlQueries = parseUrlQueries()
  const hideWelcomePage = !!get(urlQueries, 'activeStep')

  return {
    dashboardType,
    accessFormId,
    credentials,
    hideWelcomePage,
    accessForm,
    accessFormCountry,
    urlQueries,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUploadFileModal: (modalProps) => dispatch(showModalAction({ modalType: FORM_FILE_UPLOADER_MODAL, modalProps })),
    getAccessForm: ({ credentials, accessFormId }) => dispatch(getAccessFormRequest({ credentials, accessFormId })),
    showCancelApplicationFormModal: (modalProps) => dispatch(showModalAction({ modalType: CANCEL_APPLICATION_FORM_MODAL, modalProps })),
    showExitApplicationFormModal: (modalProps) => dispatch(showModalAction({ modalType: EXIT_APPLICATION_FORM_MODAL, modalProps })),
    showPreviousStepModal: (modalProps) => dispatch(showModalAction({ modalType: GO_TO_PREVIOUS_STEP_MODAL, modalProps })),
  }
}

class LiveAccountApplicationFlowC extends Component {
  componentDidMount() {
    const {
      credentials,
      accessFormId,
      getAccessForm,
    } = this.props

    if (accessFormId) {
      getAccessForm({ credentials, accessFormId })
    }
  }

  componentDidUpdate(prevProps) {
    const { accessForm, urlQueries } = this.props
    const { accessForm: prevAccessForm } = prevProps
    const hasActiveStepQuery = !!get(urlQueries, 'activeStep')

    if (isEmpty(prevAccessForm) && !isEmpty(accessForm)) {
      const businessType = get(accessForm, 'businessData.businessType')
      const isValidBusinessType = includes(MERCHANT_ONBOARDING_BUSINESS_TYPE_VALUES, businessType)

      if (!hasActiveStepQuery && isValidBusinessType) {
        updateUrlQueries({ activeStep: 0 })
      }
    }
  }

  render() {
    const {
      dashboardType,
      showUploadFileModal,
      accessFormId,
      showCancelApplicationFormModal,
      showExitApplicationFormModal,
      showPreviousStepModal,
      accessFormCountry,
    } = this.props

    const goToPreviousStep = ({ isFormDirty }) => {
      if (isFormDirty) {
        showPreviousStepModal()
      } else {
        prevFlowStep()
      }
    }

    const steps = [
      {
        name: PRICING_INFO,
        component: LiveAccountApplicationPricingFormC,
        props: {
          dashboardType,
          accessFormId,
          showCancelApplicationFormModal,
          showExitApplicationFormModal,
        },
      },
      {
        name: BUSINESS_INFO,
        component: LiveAccountApplicationBusinessFormC,
        props: {
          dashboardType,
          showUploadFileModal,
          accessFormId,
          showExitApplicationFormModal,
          goToPreviousStep,
          accessFormCountry,
        },
      },
      {
        name: OWNER_INFO,
        component: LiveAccountApplicationOwnerInfoFormC,
        props: {
          dashboardType,
          accessFormId,
          showExitApplicationFormModal,
          goToPreviousStep,
          accessFormCountry,
        },
      },
      {
        name: PROCESSING_INFO,
        component: LiveAccountApplicationProcessingInfoFormC,
        props: {
          dashboardType,
          accessFormId,
          showExitApplicationFormModal,
          goToPreviousStep,
          accessFormCountry,
        },
      },
      {
        name: BANK_ACCOUNT_INFO,
        component: LiveAccountApplicationBankAccountInfoFormC,
        props: {
          dashboardType,
          accessFormId,
          showUploadFileModal,
          showExitApplicationFormModal,
          goToPreviousStep,
          accessFormCountry,
        },
      },
      {
        name: REVIEW_AND_SUBMIT,
        component: LiveAccountApplicationReviewAndSubmitFormC,
        props: {
          dashboardType,
          accessFormId,
          showExitApplicationFormModal,
          showUploadFileModal,
          accessFormCountry,
        },
      },
    ]

    return (
      <LiveAccountApplicationFlow
        {...this.props}
        steps={steps}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationFlowC)
