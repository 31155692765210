import validateTagsForm from 'utilities/validate/validateTagsForm'

import {
  fieldEmpty,
} from 'utilities/validate'

const validateCreateSubscriptionEnrollmentForm = (values) => {
  const {
    startedAt,
    endedAt,
    enrollmentNickname,
    tags,
  } = values

  const tagErrors = validateTagsForm({ tags })

  return {
    startedAt: (!Date.parse(startedAt) || (Date.parse(startedAt) < Date.now())) && 'Start date must be in the future.', // TODO: create validateDateTime
    endedAt: Date.parse(endedAt) && startedAt > endedAt && 'End date must be after start date.',
    enrollmentNickname: fieldEmpty(enrollmentNickname, 'Enrollment nickname'),
    ...tagErrors,
  }
}

export default validateCreateSubscriptionEnrollmentForm
