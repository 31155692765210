import './LiveAccountApplicationFlowS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'
import StartHostedCustomerOnboardingScreen from 'components/System/HostedCustomerOnboarding/StartHostedCustomerOnboardingScreen/StartHostedCustomerOnboardingScreen'
import { LIVE_ACCOUNT_APPLICATION_FORM } from 'constants/language/languageConstants'

const LiveAccountApplicationFlow = ({
  steps,
  edit = false,
  hideWelcomePage,
  accessFormId,
  accessFormCountry = 'USA',
  dashboardType = '',
}) => {
  return (
    <div className='LiveAccountApplicationFlow'>
      {hideWelcomePage ? (
        <FlowsC
          layout='vertical'
          steps={steps}
          edit={edit}
          title={LIVE_ACCOUNT_APPLICATION_FORM}
        />
      ) : (
        <StartHostedCustomerOnboardingScreen
          accessFormId={accessFormId}
          accessFormCountry={accessFormCountry}
          isSelfService
          dashboardType={dashboardType}
        />
      )
        }
    </div>
  )
}

LiveAccountApplicationFlow.propTypes = {
  steps: PropTypes.array,
  edit: PropTypes.bool,
  hideWelcomePage: PropTypes.bool,
  accessFormId: PropTypes.string,
  accessFormCountry: PropTypes.string,
  dashboardType: PropTypes.string,
}

export default LiveAccountApplicationFlow
