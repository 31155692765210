import frontendEntityConfigurationModel from 'utilities/create/models/frontendEntityConfigurationModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendEntityConfigurationsModel = ({ data: entityConfigurations }) => {
  const entityConfigurationModels = map(entityConfigurations, (data) => frontendEntityConfigurationModel({ data }))

  return keyBy(entityConfigurationModels, 'id')
}

export default frontendEntityConfigurationsModel
