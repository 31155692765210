import './PasswordInputFieldS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

const PasswordInputField = ({
  input,
  label,
  subLabel,
  placeholder,
  disabled = false,
  meta: {
    active,
    touched,
    error,
    warning,
  },
  className,
  leadingIcon,
  trailingIcon,
  helperText = '',
  ariaLabel,
  tooltip = '',
  tooltipPosition,
  togglePasswordType = () => {},
  passwordType = 'password',
  passwordRequirements = [],
  showPasswordRequirements,
}) => {
  const { name } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const inputClassName = name.replace('.', '_')
  const hasIcon = leadingIcon || trailingIcon ? 'hasIcon' : ''
  const hasLeadingIcon = leadingIcon ? 'hasLeadingIcon' : ''
  const isActive = active ? 'active' : ''
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''
  const hiddenPassword = passwordType === 'password'
  const shownPassword = passwordType === 'text'

  const classNames = classnames({
    PasswordInputField: true,
    [inputClassName]: true,
    [className]: !!className,
  })

  const inputAreaClassNames = classnames({
    flex: true,
    'input-area': true,
    [hasIcon]: !!hasIcon,
    [hasLeadingIcon]: !!hasLeadingIcon,
    [isActive]: !!isActive,
    [hasError]: !!hasError,
  })

  return (
    <div className={classNames}>
      <div className='flex'>
        { label && <label htmlFor='password' className='label label-2'>{label}</label> }

        { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
      </div>

      { subLabel && <div className='subLabel label-2'>{subLabel}</div> }

      <div className={inputAreaClassNames}>
        {leadingIcon && <i className={`leadingIcon ${leadingIcon}`} />}

        <input
          id='password'
          aria-label={ariaLabel ? ariaLabel : label}
          {...input}
          type={passwordType}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete='new-password'
        />
        {showPasswordRequirements && !isEmpty(passwordRequirements) && (
        <div className='desktop-password-requirements'>
          <span className='arrow' />
          {map(passwordRequirements, (req) => {
            const { title, passed } = req
            const passedClassName = passed ? 'passed' : 'failed'
            const iconName = passed ? CHECK_CIRCLE_ICON : TIMES_CIRCLE_ICON
            return <div className='requirement p-2'><i className={`fa fa-${iconName} ${passedClassName}`} />{title}</div>
          })}
        </div>
        )}
        {hiddenPassword && <i className='hiddenPassword fa fa-eye-slash' onClick={togglePasswordType} />}
        {shownPassword && <i className='shownPassword fa fa-eye' onClick={togglePasswordType} />}
        {trailingIcon && <i className={`trailingIcon ${trailingIcon}`} />}
      </div>
      {showPasswordRequirements && !isEmpty(passwordRequirements) && (
      <div className='mobile-password-requirements'>
        {map(passwordRequirements, (req) => {
          const { title, passed } = req
          const passedClassName = passed ? 'passed' : 'failed'
          const iconName = passed ? CHECK_CIRCLE_ICON : TIMES_CIRCLE_ICON
          return <div className='requirement p-2'><i className={`fa fa-${iconName} ${passedClassName}`} />{title}</div>
        })}
      </div>
      )}

      { (touched && (errorJSX || warningJSX)) || <div className='helperText'> {helperText} </div>}
    </div>
  )
}

PasswordInputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  leadingIcon: PropTypes.string,
  trailingIcon: PropTypes.string,
  helperText: PropTypes.string,
  ariaLabel: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  togglePasswordType: PropTypes.func,
  passwordType: PropTypes.string,
  passwordRequirements: PropTypes.array,
  showPasswordRequirements: PropTypes.bool,
}

export default PasswordInputField
