export const DATA = {
  name: 'elavonpartnerconnect',
  id: 'DASHn8Pu4WDjKB86YFXBKFtVRv',
  applicationName: 'elavonpartnerconnect',
  applicationLogoImage: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2021-02-09-21_17_57-Partner_connect_white.png',
  credentialsHost: 'https://elavonpartnerconnect.paymentsdashboard.io',
  credentialsHostV2: 'https://live.paymentsdashboard.io',
  paymentsApiHosts: 'https://*.live-payments-api.com',
  auth0: {
    clientID: 'E2xqeUIBATV4tSZInjZ49jE0QApKLnH8',
    domain: 'login.payments-dashboard.com',
    allowSignUp: false,
    title: 'Elavon Partner Connect',
    logo: 'https://s3-us-west-2.amazonaws.com/payments-dashboard-assets/dashboard.finixpayments.com/2020-07-29-20_51_12-Partner_Connect_Logo_Black.png',
  },
  availableProcessors: ['DUMMY_V1', 'MICROBILT_V1', 'ELAVON_V1'],
  dashboardServiceV2: true, // using this to control dashboard service version
}
