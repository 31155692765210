import './TextAreaFieldS.scss'
import React from 'react'
import { change, formValueSelector } from 'redux-form'
import { getState } from 'state-layer/configureStore'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import { LINK } from 'constants/language/languageConstants'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import toString from 'lodash/toString'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  EXTERNAL_LINK,
  TIMES_CIRCLE_ICON as CLEAR_INPUT_ICON,
} from 'constants/iconConstants'

const TextAreaField = ({
  input,
  label,
  type,
  placeholder,
  className = '',
  disabled = false,
  meta: {
    touched,
    error,
    valid,
    form,
    dispatch,
    active,
  },
  tooltip = '',
  tooltipPosition,
  autoComplete = 'on',
  required = true,
  successMessage,
  helperText,
  link,
  characterLimit,
}) => {
  const hasError = touched && error

  const textAreaClassNames = classnames({
    InputField: true,
    TextAreaField: true,
    error: !!hasError,
    [className]: true,
    disabled,
  })

  const { name } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const successJSX = successMessage && valid && <span className='success'><i className={`fal fa-${CHECK_CIRCLE_ICON} success-icon`} />{successMessage}</span>
  const formSelector = formValueSelector(form)
  const emptyInput = isEmpty(formSelector(getState(), name))
  const showClearButton = !disabled && !emptyInput && active
  const characterCount = characterLimit ? toString(size(formSelector(getState(), name))) : ''

  const clearInput = () => {
    dispatch(change(form, name, ''))
  }

  return (
    <div className={textAreaClassNames}>
      <div className='flex items-flex-start'>
        { label && <label className='label label-2'>{label}</label> }

        {required && label && <div className='required-field'>*</div>}

        { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
      </div>

      <div className='text-input-area'>
        <textarea
          {...input}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={characterLimit}
        />

        <i
          className={`fal fa-${CLEAR_INPUT_ICON} clear-input-icon ${showClearButton ? 'show-clear-icon' : 'hide-clear-icon'}`}
          onClick={clearInput}
        />
      </div>

      { helperText && <div className='helper-text'>{helperText}</div> }

      {link && (
        <a href={link} className='external-link text-link' target='blank'> {LINK} <i className={`fa fa-${EXTERNAL_LINK}`} /></a>
      )}

      <div className={hasError ? 'error-and-count-container' : 'count-container'}>
        { touched && (errorJSX || successJSX)}
        {characterLimit && <div className='character-count'>{characterCount}/{characterLimit}</div>}
      </div>
    </div>
  )
}

TextAreaField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  autoComplete: PropTypes.string,
  successMessage: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  link: PropTypes.string,
  characterLimit: PropTypes.number,
}

export default TextAreaField
