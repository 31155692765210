import { POST } from 'constants/connectorConstants'

async function postData(url = '', authToken, data = {}) {
  const response = await fetch(url, {
    method: POST,
    headers: {
      'Content-Type': 'application/vnd.json+api',
      Authorization: `Basic ${authToken}`,
    },
    body: JSON.stringify(data),
  })

  return response.json()
}

export default postData
