import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  ACCRUING,
  AWAITING_APPROVAL,
  APPROVED,
  STAGED,
  PENDING,
} from 'constants/settlementConstants'

import {
  DEFAULT,
  NOTIFICATION,
  WARNING,
  SUCCEEDED,
  CANCELED,
} from 'constants/statusConstants'

const StatusData = {
  [ACCRUING]: DEFAULT,
  [AWAITING_APPROVAL]: WARNING,
  [STAGED]: NOTIFICATION,
  [APPROVED]: SUCCEEDED,
  [PENDING]: DEFAULT,
  'N/A': CANCELED,
}

const SettlementStatus = ({
  value = '',
  isSplitParentPayment = false,
}) => {
  const normalizedValue = isSplitParentPayment ? 'N/A' : value

  return <ColorcodedStatus data={StatusData} value={normalizedValue} titleCase={!isSplitParentPayment} />
}

SettlementStatus.propTypes = {
  value: PropTypes.string,
  isSplitParentPayment: PropTypes.bool,
}

export default SettlementStatus
