import React from 'react'
import { Provider } from 'react-redux'
import { Route, Router, IndexRoute, IndexRedirect } from 'react-router'
import PropTypes from 'prop-types'
import { getHistory } from 'state-layer/history'
import { getState } from 'state-layer/configureStore'
import { syncHistoryWithStore } from 'react-router-redux'
import getCurrentAuth0Configs from 'utilities/get/getCurrentAuth0Configs'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import getCurrentUserRole from 'utilities/get/getCurrentUserRole'
import getCurrentUser from 'utilities/get/getCurrentUser'
import isAdministrator from 'utilities/is/isAdministrator'
import isAdminOrClientAdmin from 'utilities/is/isAdminOrClientAdmin'
import includes from 'lodash/includes'
import get from 'lodash/get'
import { initAmplitude } from 'utilities/amplitude'
import { ThemeProvider, supersetTheme } from '@superset-ui/core'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import i18n from 'constants/language/i18n'

import {
  ANON_KEY,
  launchDarklyClientId,
} from 'constants/launchDarklyConstants'

// System
import Auth from 'components/System/Auth/Auth'
import AuthCallback from 'components/System/AuthCallback/AuthCallback'
import MainAppC from 'components/System/MainApp/MainAppC'
import LoginC from 'components/System/Login/LoginC'
import LogoutC from 'components/System/Logout/LogoutC'
import HostedMerchantOnboardingC from 'components/System/HostedMerchantOnboarding/HostedMerchantOnboardingC'
import HostedCustomerOnboardingC from 'components/System/HostedCustomerOnboarding/HostedCustomerOnboardingC'
import HostedCustomerOnboardingSuccess from 'components/System/HostedCustomerOnboarding/HostedCustomerOnboardingSuccess/HostedCustomerOnboardingSuccess'
import SelfServiceSignupC from 'components/System/SelfServiceSignup/SelfServiceSignupC'
import EmailVerificationC from 'components/System/SelfServiceSignup/EmailVerification/EmailVerificationC'
import EmailVerificationPendingC from 'components/System/SelfServiceSignup/EmailVerification/EmailVerificationPendingC'

// Customer
import HomeC from 'components/Customer/Pages/Home/HomeC'
import DashboardsC from 'components/Customer/Pages/Dashboards/DashboardsC'
import TransfersPayoutsC from 'components/Customer/Pages/LandingPage/TransfersPayoutsC'
import BalanceTransfersC from 'components/Customer/Pages/BalanceTransfers/BalanceTransfersC'
import PayoutsC from 'components/Customer/Pages/Payouts/PayoutsC'
import PayoutC from 'components/Customer/Pages/Payout/PayoutC'
import AuthorizationsC from 'components/Customer/Pages/Authorizations/AuthorizationsC'
import SettlementsC from 'components/Customer/Pages/Settlements/SettlementsC'
import DisputeC from 'components/Customer/Pages/Dispute/DisputeC'
import ACHReturnC from 'components/Customer/Pages/ACHReturn/ACHReturnC'
import ReportsC from 'components/Customer/Pages/Reports/ReportsC'
import ReportsTableC from 'components/Customer/Pages/Reports/ReportsTable/ReportsTableC'
import IdentitiesC from 'components/Customer/Pages/Identities/IdentitiesC'
import IdentityC from 'components/Customer/Pages/Identity/IdentityC'
import ComplianceC from 'components/Customer/Pages/Compliance/ComplianceC'
import ComplianceFormC from 'components/Customer/Pages/Compliance/ComplianceForm/ComplianceFormC'
import ComplianceFormTemplateC from 'components/Customer/Pages/Compliance/ComplianceFormTemplate/ComplianceFormTemplateC'
import CreateComplianceFormTemplateC from 'components/Customer/Pages/Compliance/CreateComplianceFormTemplateFlow/CreateComplianceFormTemplateFlowC'
import ApplicationTabsC from 'components/Customer/Pages/Applications/ApplicationTabsC'
import ApplicationsC from 'components/Customer/Pages/Applications/ApplicationsC'
import ApplicationProcessorC from 'components/Customer/Pages/Application/ApplicationProcessor/ApplicationProcessorC'
import ApplicationNew from 'components/Customer/Pages/ApplicationNew/ApplicationNew'
import PaymentInstrumentC from 'components/Customer/Pages/PaymentInstrument/PaymentInstrumentC'
import PaymentInstrumentsC from 'components/Customer/Pages/PaymentInstruments/PaymentInstrumentsC'
import OnboardingFormsC from 'components/Customer/Pages/OnboardingForms/OnboardingFormsC'
import OnboardingFormC from 'components/Customer/Pages/OnboardingForm/OnboardingFormC'
import MerchantsC from 'components/Customer/Pages/Merchants/MerchantsC'
import DisputesC from 'components/Customer/Pages/Disputes/DisputesC'
import ACHReturnsC from 'components/Customer/Pages/ACHReturns/ACHReturnsC'
import RefundsC from 'components/Customer/Pages/Refunds/RefundsC'
import ReviewQueueSettlementsC from 'components/Customer/Pages/ReviewQueue/ReviewQueueSettlements/ReviewQueueSettlementsC'
import MerchantStatementsC from 'components/Customer/Pages/Merchant/MerchantStatements/MerchantStatementsC'
import ReviewQueueMerchantsTabs from 'components/Customer/Pages/ReviewQueue/ReviewQueueMerchants/ReviewQueueMerchantsTabs'
import IdentitiesTabs from 'components/Customer/Pages/ReviewQueue/IdentitiesTabs/IdentitiesTabs'
import SettlementsTabs from 'components/Customer/Pages/Settlements/SettlementsTabs'
import ReviewQueueItemC from 'components/Customer/Pages/ReviewQueue/ReviewQueueItem/ReviewQueueItemC'
import PaymentsC from 'components/Customer/Pages/Payments/PaymentsC'
import DisbursementsC from 'components/Customer/Pages/Disbursements/DisbursementsC'
import AccountBalanceC from 'components/Customer/Pages/AccountBalance/AccountBalanceC'
import PaymentC from 'components/Customer/Pages/Payment/PaymentC'
import MerchantDepositC from 'components/Customer/Pages/MerchantDeposit/MerchantDepositC'
import PlatformFundingTransferC from 'components/Customer/Pages/PlatformFundingTransfer/PlatformFundingTransferC'
import RefundC from 'components/Customer/Pages/Refund/RefundC'
import BalanceTransferC from 'components/Customer/Pages/BalanceTransfer/BalanceTransferC'
import AuthorizationC from 'components/Customer/Pages/Authorization/AuthorizationC'
import SettlementC from 'components/Customer/Pages/Settlement/SettlementC'
import ReviewSettlementC from 'components/Customer/Pages/Settlement/ReviewSettlementC'
import FeeC from 'components/Customer/Pages/Fee/FeeC'
import WebhookC from 'components/Customer/Pages/Webhook/WebhookC'
import WebhookLogC from 'components/Customer/Pages/WebhookLog/WebhookLogC'
import CreateWebhook from 'components/Customer/Pages/Webhook/CreateWebhook/CreateWebhook'
import EditWebhookC from 'components/Customer/Pages/Webhook/EditWebhook/EditWebhookC'
import CompanyTabsC from 'components/Customer/Pages/Company/CompanyTabsC'
import ExportsC from 'components/Customer/Pages/Exports/ExportsC'
import EditPayoutSettingsFormC from 'components/Customer/Forms/EditPayoutSettingsForm/EditPayoutSettingsFormC'
import NotificationsC from 'components/Customer/Shared/Notifications/NotificationsC'
import MerchantDepositsC from 'components/Customer/Pages/MerchantDeposits/MerchantDepositsC'
import APILogsC from 'components/Customer/Pages/APILogs/APILogsC'
import APILogC from 'components/Customer/Pages/APILog/APILogC'
import CreateRoleC from 'components/Customer/Pages/Roles/CreateRole/CreateRoleC'
import DuplicateRoleC from 'components/Customer/Pages/Roles/CreateRole/DuplicateRoleC'
import BuyersC from 'components/Customer/Pages/Buyers/BuyersC'
import BuyerC from 'components/Customer/Pages/Buyer/BuyerC'
import LiveAccountApplicationFlowC from 'components/Customer/Pages/LiveAccountApplicationFlow/LiveAccountApplicationFlowC'
import AccessFormC from 'components/Customer/Pages/AccessForm/AccessFormC'
import UnderwritingMerchantC from 'components/Customer/Pages/UnderwritingMerchant/UnderwritingMerchantC'
import IdentityMatchC from 'components/Customer/Pages/IdentityMatch/IdentityMatchC'
import CreateCustomerOnboardingFormFlowC from 'components/Customer/Pages/CreateCustomerOnboardingFormFlow/CreateCustomerOnboardingFormFlowC'
import CustomerOnboardingFormSuccessC from 'components/Customer/Pages/CreateCustomerOnboardingFormFlow/CustomerOnboardingFormSuccess/CustomerOnboardingFormSuccessC'
import AuditLogEventC from 'components/Customer/Pages/AuditLogEvent/AuditLogEventC'
import AddFundsC from 'components/Customer/Pages/AddFunds/AddFundsC'
import SetVelocityLimitsFlowC from 'components/Customer/Pages/SetVelocityLimitsFlow/SetVelocityLimitsFlowC'
import UpdateVelocityLimitsFormC from 'components/Customer/Forms/UpdateVelocityLimitsForm/UpdateVelocityLimitsFormC'
import UpdateBalanceAdjustmentsVelocityLimitsFlowC from 'components/Customer/Pages/UpdateBalanceAdjustmentsVelocityLimitsFlow/UpdateBalanceAdjustmentsVelocityLimitsFlowC'
import CreateRefundC from 'components/Customer/Pages/CreateRefund/CreateRefundC'
import SplitPaymentC from 'components/Customer/Pages/SplitPayment/SplitPaymentC'
import SplitRefundC from 'components/Customer/Pages/SplitRefund/SplitRefundC'
import CreateTransactionC from 'components/Customer/Pages/CreateTransaction/CreateTransactionC'
import CreateTransactionConfirmationC from 'components/Customer/Pages/CreateTransactionConfirmation/CreateTransactionConfirmationC'
import UnderwritingRecipientsC from 'components/Customer/Pages/UnderwritingRecipients/UnderwritingRecipientsC'
import UnderwritingRecipientC from 'components/Customer/Pages/UnderwritingRecipient/UnderwritingRecipientC'
import RecipientsC from 'components/Customer/Pages/Recipients/RecipientsC'
import RecipientC from 'components/Customer/Pages/Recipient/RecipientC'
import BalanceEntryC from 'components/Customer/Pages/BalanceEntry/BalanceEntryC'
import EditRecipientIdentityContactFormC from 'components/Customer/Forms/EditRecipientIdentityContactForm/EditRecipientIdentityContactFormC'
import AddRecipientFormC from 'components/Customer/Forms/AddRecipientForm/AddRecipientFormC'
import PaymentDevicesC from 'components/Customer/Pages/PaymentDevices/PaymentDevicesC'
import AddNewDeviceC from 'components/Customer/Pages/AddNewDevice/AddNewDeviceC'
import CreatePayoutLinkFormC from 'components/Customer/Forms/CreatePayoutLinkForm/CreatePayoutLinkFormC'
import PayoutLinkCreatedConfirmationC from 'components/Customer/Pages/PayoutLinkCreatedConfirmation/PayoutLinkCreatedConfirmationC'
import GuestPayoutLinkFormC from 'components/Customer/Forms/GuestPayoutLinkForm/GuestPayoutLinkFormC'
import SubscriptionPlansC from 'components/Customer/Pages/SubscriptionPlans/SubscriptionPlansC'
// import CustomerUsersC from 'components/Customer/Pages/CustomerUsers/CustomerUsersC'
import PayoutLinkC from 'components/Customer/Pages/PayoutLink/PayoutLinkC'
import SendPayoutOptionsC from 'components/Customer/Pages/SendPayoutOptions/SendPayoutOptionsC'
import SendPayoutC from 'components/Customer/Pages/SendPayout/SendPayoutC'
import SendPayoutConfirmationC from 'components/Customer/Pages/SendPayout/SendPayoutConfirmation/SendPayoutConfirmationC'
import ApplicationC from 'components/Customer/Pages/Application/ApplicationC'
import CustomerOnboardingFormC from 'components/Customer/Pages/CustomerOnboardingForm/CustomerOnboardingFormC'
import MerchantC from 'components/Customer/Pages/Merchant/MerchantC'
import IdentityNew from 'components/Customer/Pages/IdentityNew/IdentityNew'
import IdentityEdit from 'components/Customer/Pages/IdentityEdit/IdentityEdit'
import EditFeeProfileFlowC from 'components/Customer/Forms/EditFeeProfileForm/EditFeeProfileFlowC'
import EditFeeProfileRulesFlowC from 'components/Customer/Forms/EditFeeProfileRulesForm/EditFeeProfileRulesFlowC'
import SettingsC from 'components/Customer/Pages/Settings/SettingsC'
import DeveloperC from 'components/Customer/Pages/Developer/DeveloperC'
import ReviewIdentityV1C from 'components/Customer/Pages/ReviewIdentity/ReviewIdentityV1C'
import UpdateTransfers from 'components/Customer/Pages/UpdateTransfers/UpdateTransfers'
import UpdateVerifications from 'components/Customer/Pages/UpdateVerifications/UpdateVerifications'
import DashboardOverviewC from 'components/Customer/Pages/DashboardOverview/DashboardOverviewC'
import AdminUserC from 'components/Admin/Pages/AdminUser/AdminUserC'
import PlatformsC from 'components/Customer/Pages/Platforms/PlatformsC'
import PlatformC from 'components/Customer/Pages/Platform/PlatformC'
import PlatformProcessorConfigsC from 'components/Customer/Pages/Platform/PlatformProcessorConfigs/PlatformProcessorConfigsC'
import PlatformProcessorConfigC from 'components/Customer/Pages/Platform/PlatformProcessorConfig/PlatformProcessorConfigC'
// import PlatformProcessorConfigC from 'components/Customer/Pages/Platform/PlatformProcessorConfigs/PlatformProcessorConfig/PlatformProcessorConfigC'
import UserC from 'components/Customer/Pages/User/UserC'
import UserNew from 'components/Customer/Pages/UserNew/UserNew'
import AssociatedIdentityNewC from 'components/Customer/Pages/AssociatedIdentityNew/AssociatedIdentityNewC'
import AssociatedIdentityEditC from 'components/Customer/Pages/AssociatedIdentityEdit/AssociatedIdentityEditC'
import MerchantIdentityNewC from 'components/Customer/Pages/IdentityNew/MerchantIdentityNewC'
import MerchantIdentityEditC from 'components/Customer/Pages/IdentityEdit/MerchantIdentityEditC'
import UnderwritingApplicationsTabs from 'components/Customer/Pages/UnderwritingApplications/UnderwritingApplicationsTabs'
import UnderwritingApplicationC from 'components/Customer/Pages/UnderwritingApplication/UnderwritingApplicationC'
import UnderwritingMerchantsTabs from 'components/Customer/Pages/UnderwritingMerchants/UnderwritingMerchantsTabs'
import MemberC from 'components/Customer/Pages/Member/MemberC'
import CreateTeamMemberC from 'components/Customer/Pages/Members/CreateTeamMember/CreateTeamMemberC'
import RoleC from 'components/Customer/Pages/Role/RoleC'
import HomeInsightsC from 'components/Customer/Pages/Insights/HomeInsights/HomeInsightsC'
import TransactionInsightsTabs from 'components/Customer/Pages/Insights/TransactionInsights/TransactionInsightsTabs'
import ExceptionsInsightsTabs from 'components/Customer/Pages/Insights/ExceptionsInsights/ExceptionsInsightsTabs'
import MerchantInsightsTabs from 'components/Customer/Pages/Insights/MerchantInsights/MerchantInsightsTabs'
import MerchantPayoutsTabs from 'components/Customer/Pages/Merchants/MerchantPayoutsTabs/MerchantPayoutsTabs'
import MerchantUnderwritingInsightsC from 'components/Customer/Pages/Insights/UnderwritingInsights/MerchantUnderwritingInsights/MerchantUnderwritingInsightsC'
import CreatePaymentLinkFormC from 'components/Customer/Forms/CreatePaymentLinkForm/CreatePaymentLinkFormC'
import PaymentLinkCreatedConfirmationC from 'components/Customer/Pages/PaymentLinkCreatedConfirmation/PaymentLinkCreatedConfirmationC'
import PaymentLinksC from 'components/Customer/Pages/PaymentLinks/PaymentLinksC'
import PaymentLinkC from 'components/Customer/Pages/PaymentLink/PaymentLinkC'
import GuestPaymentLinkFormC from 'components/Customer/Forms/GuestPaymentLinkForm/GuestPaymentLinkFormC'
import GuestCheckoutFormC from 'components/Customer/Forms/GuestPaymentLinkForm/GuestCheckoutFormC'
import PaymentLinkConfirmationC from 'components/Customer/Pages/PaymentLinkConfirmation/PaymentLinkConfirmationC'
import PurchaseC from 'components/Customer/Pages/Purchase/PurchaseC'
import DeviceC from 'components/Customer/Pages/Device/DeviceC'
import PayoutLinksC from 'components/Customer/Pages/PayoutLinks/PayoutLinksC'
import TransferAttemptC from 'components/Customer/Pages/TransferAttempt/TransferAttemptC'
import PayoutLinkConfirmationC from 'components/Customer/Pages/PayoutLinkConfirmation/PayoutLinkConfirmationC'
import DisputeTransferFormC from 'components/Customer/Forms/DisputeTransferForm/DisputeTransferFormC'
import BalanceAdjustmentsC from 'components/Customer/Pages/BalanceAdjustments/BalanceAdjustmentsC'
import CreateBalanceAdjustmentC from 'components/Customer/Pages/CreateBalanceAdjustment/CreateBalanceAdjustmentC'
import CreateBalanceAdjustmentConfirmationC from 'components/Customer/Pages/CreateBalanceAdjustmentConfirmation/CreateBalanceAdjustmentConfirmationC'
import BalanceAdjustmentC from 'components/Customer/Pages/BalanceAdjustment/BalanceAdjustmentC'
import CreateReceiptFormC from 'components/Customer/Forms/CreateReceiptForm/CreateReceiptFormC'
import SubscriptionsC from 'components/Customer/Pages/Subscriptions/SubscriptionsC'
import SubscriptionC from 'components/Customer/Pages/Subscription/SubscriptionC'
import SubscriptionPlanTabsC from 'components/Customer/Pages/SubscriptionPlan/SubscriptionPlanTabsC'
import CreateSubscriptionFormC from 'components/Customer/Forms/CreateSubscriptionForm/CreateSubscriptionFormC'
import CreateSubscriptionPlanFormC from 'components/Customer/Forms/CreateSubscriptionPlanForm/CreateSubscriptionPlanFormC'
import EditSubscriptionFormC from 'components/Customer/Forms/EditSubscriptionForm/EditSubscriptionFormC'
import MonitoringAlertsC from 'components/Customer/Pages/MonitoringAlerts/MonitoringAlertsC'
import MonitoringAlertC from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertC'
import EditSubscriptionPlanFormC from 'components/Customer/Forms/EditSubscriptionPlanForm/EditSubscriptionPlanFormC'
import CreateInstantPayoutFormC from 'components/Customer/Forms/CreateInstantPayoutForm/CreateInstantPayoutFormC'
import CreateInstantPayoutConfirmationC from 'components/Customer/Forms/CreateInstantPayoutConfirmation/CreateInstantPayoutConfirmationC'

// Admin
import MainAdminC from 'components/Admin/Pages/MainAdmin/MainAdminC'
import AdminUsersC from 'components/Admin/Pages/AdminUsers/AdminUsersC'
import AdminUserNew from 'components/Admin/Pages/AdminUserNew/AdminUserNew'
import EmailTemplatesC from 'components/Admin/Pages/EmailTemplates/EmailTemplatesC'
import IdentityUploader from 'components/Admin/Pages/IdentityUploader/IdentityUploader'
import SubscriptionBillingTabs from 'components/Admin/Pages/SubscriptionBilling/SubscriptionBillingTabs'
import SubscriptionScheduleC from 'components/Admin/Pages/SubscriptionBilling/SubscriptionSchedule/SubscriptionScheduleC'
import PayoutPlansC from 'components/Admin/Pages/PayoutPlans/PayoutPlansC'
import PayoutPlanC from 'components/Admin/Pages/PayoutPlan/PayoutPlanC'
import PayoutPlanNew from 'components/Admin/Pages/PayoutPlanNew/PayoutPlanNew'
import ComponentsC from 'components/Admin/Pages/Components/ComponentsC'

// Gateway Admin
import GatewayAdminToolsC from 'components/GatewayAdmin/Pages/GatewayAdminTools/GatewayAdminToolsC'
import FilesManagementC from 'components/GatewayAdmin/Pages/FilesManagement/FilesManagementC'
import TransactionsManagementC from 'components/GatewayAdmin/Pages/TransactionsManagement/TransactionsManagementC'

import {
  DASHBOARD,
  PAYMENTS,
  BALANCE_TRANSFERS,
  PAYOUTS,
  AUTHORIZATIONS,
  SETTLEMENTS,
  DISPUTES,
  ACH_RETURNS,
  REPORTS,
  IDENTITIES,
  MERCHANTS,
  APPLICATIONS,
  PAYMENT_INSTRUMENTS,
  ONBOARDING_SETTLEMENTS,
  ONBOARDING_MERCHANTS,
  ONBOARDING_IDENTITIES,
  ONBOARDING_APPLICATIONS,
  REVIEW_QUEUE_IDENTITIES,
  APPROVED_ONBOARDING_IDENTITIES,
  USERS,
  SETTINGS,
  ONBOARDING,
  UPDATE_TRANSFERS,
  UPDATE_VERIFICATIONS,
  ADMINISTRATOR,
  EMAIL_TEMPLATES,
  PLATFORMS,
  PROCESSOR_CONFIGS,
  ENTRIES,
  IDENTITY_UPLOADER,
  SUBSCRIPTION_BILLING,
  SUBSCRIPTION_SCHEDULES,
  PAYOUT_PLANS,
  PROCESSORS,
  GATEWAY_ADMINISTRATOR_TOOLS,
  FILES_MANAGEMENT,
  TRANSACTIONS_MANAGEMENT,
  WEBHOOKS,
  WEBHOOK_LOGS,
  COMPLIANCE,
  COMPONENTS,
  COMPANY,
  COMPLIANCE_FORMS,
  COMPLIANCE_FORM_TEMPLATES,
  EXPORTS,
  DEVELOPER,
  REFUNDS,
  FEES,
  PAYOUT_SETTINGS,
  NOTIFICATIONS,
  MERCHANT_DEPOSITS,
  API_LOGS,
  CREATE_WEBHOOK,
  PLATFORM_FUNDING_TRANSFER,
  ONBOARDING_FORMS,
  VERIFY_EMAIL_PATH,
  SELF_SERVICE_SIGNUP_PATH,
  VERIFY_EMAIL_PENDING_PATH,
  MEMBERS,
  ADD_TEAM_MEMBER,
  DASHBOARD_USER_ROLES,
  ADD_ROLE,
  ROLES,
  DUPLICATE_ROLE,
  BUYERS,
  LIVE_ACCOUNT_APPLICATION_FORM,
  UNDERWRITING,
  LIVE_ACCOUNT_APPLICATION,
  HOME_INSIGHTS,
  TRANSACTION_INSIGHTS,
  ADVERSE_MEDIA,
  EXCEPTIONS_INSIGHTS,
  MERCHANT_INSIGHTS,
  CUSTOMER_ONBOARDING_FORM,
  CUSTOMER_ONBOARDING_PATH,
  MERCHANT_ONBOARDING_PATH,
  SPLIT_PAYMENTS,
  SPLIT_REFUNDS,
  UNDERWRITING_INSIGHTS,
  ONBOARDING_RECIPIENTS,
  DISBURSEMENTS,
  RECIPIENTS,
  BALANCES,
  BALANCE,
  ADDING_FUNDS,
  SET_VELOCITY_LIMITS,
  UPDATE_APPLICATION_CARD_LIMITS,
  UPDATE_APPLICATION_ACH_LIMITS,
  UPDATE_RECIPIENT_CARD_LIMITS,
  UPDATE_RECIPIENT_ACH_LIMITS,
  EDIT_RECIPIENT_IDENTITY,
  PAYMENT_LINKS,
  CREATE_CONFIRMATION,
  GUEST_PAYMENT_LINK,
  GUEST_CHECKOUT_FORM,
  GUEST_PAYMENT_LINK_CONFIRMATION,
  PURCHASES,
  PAYMENT_DEVICES,
  ADD_NEW_DEVICE,
  SEND_PAYOUT,
  PAST_RUN,
  PAYOUT_LINKS,
  TRANSFER_ATTEMPTS,
  PAYOUT_ATTEMPTS,
  GUEST_PAYOUT_LINK,
  GUEST_PAYOUT_LINK_CONFIRMATION,
  VIRTUAL_TERMINAL,
  MERCHANT_PAYOUTS,
  ADJUSTMENTS,
  BALANCE_ADJUSTMENTS,
  ADD_FUNDS,
  ADD_FUNDS_CONFIRMATION,
  SUBSCRIPTIONS,
  MONITORING_ALERTS,
  CREATE_INSTANT_PAYOUT,
  SUBSCRIPTION_PLANS,
  UPDATE_BALANCE_ADJUSTMENT_LIMITS,
} from 'constants/pathConstants'

import {
  ALL_ROLES,
  NON_MERCHANT_ROLES,
  ADMIN_PLATFORM_ROLES,
  PARTNER_AND_MERCHANT_ROLES,
  ROLE_ADMIN,
  ROLE_PLATFORM,
  ROLE_PARTNER,
  ROLE_MERCHANT,
  PLATFORM_AND_PARTNER_ROLES,
} from 'constants/roleConstants'

i18n.init()

const platformName = getCurrentPlatformName()
localStorage.setItem('platformName', platformName)

initAmplitude(getCurrentUser(), platformName)

const {
  domain,
  clientID,
  logo = '',
  title = '',
  allowSignUp = false,
} = getCurrentAuth0Configs()

const AuthLockOptions = {
  languageDictionary: {
    title,
  },
  theme: {
    logo,
  },
  container: 'login-container',
  closable: false,
  allowSignUp,
}

const auth = new Auth(domain, clientID, AuthLockOptions)

// TODO: see if there is a better way to handle this
const MainAppAuth = (props) => <MainAppC auth={auth} {...props} />
const LoginAuth = (props) => <LoginC auth={auth} {...props} />
const LogoutAuth = (props) => <LogoutC auth={auth} {...props} />

const RootAuth = (props) => {
  const goToDashboard = auth && localStorage.getItem('isLoggedIn') === 'true'

  return goToDashboard ? <DashboardsC {...props} /> : <LoginC auth={auth} {...props} />
}

const AuthCallbackHandler = (props) => {
  const locationHash = get(props, 'location.hash')

  if (/access_token|id_token|error/.test(locationHash)) {
    auth.handleAuthentication()
  }

  return <AuthCallback {...props} />
}

const onRouteUpdate = () => {
  const hash = get(this, 'state.location.hash')

  if (hash === '#TOP') {
    window.scrollTo(0, 0)
  }
}

const AuthRoute = (Component, allowedRoles, adminAccessCheck = () => true) => (props) => {
  const state = getState()
  const currentRole = getCurrentUserRole(state)
  const roleAccess = includes(allowedRoles, currentRole)
  const adminAccess = adminAccessCheck(state)
  // TODO: temporarily removing role check as of now to reduce bug where role is empty on page refresh (will need to look into long term fix)
  const isAllowed = adminAccess // roleAccess && adminAccess

  // TODO: make an actual non-authorized
  return isAllowed ? <Component {...props} /> : (<div>You are not allowed here!</div>)
}

const MainRouter = ({ store }) => {
  const syncedHistory = syncHistoryWithStore(getHistory(), store)

  return (
    <ThemeProvider theme={supersetTheme}>
      <Provider store={store}>
        <Router onUpdate={onRouteUpdate} history={syncedHistory}>
          <Route path='/' component={MainAppAuth}>
            <IndexRoute component={RootAuth} />

            <Route className='login' path='Login' component={LoginAuth} />

            <Route path='Logout' component={LogoutAuth} />

            <Route path='AuthCallback' component={AuthCallbackHandler} />

            <Route path={MERCHANT_ONBOARDING_PATH} component={HostedMerchantOnboardingC} />

            <Route path={CUSTOMER_ONBOARDING_PATH}>
              <IndexRoute component={HostedCustomerOnboardingC} />

              <Route path='success'>
                <IndexRoute component={HostedCustomerOnboardingSuccess} />
              </Route>
            </Route>

            <Route path={SELF_SERVICE_SIGNUP_PATH} component={SelfServiceSignupC} />

            <Route path={VERIFY_EMAIL_PATH}>
              <IndexRoute component={EmailVerificationC} />
            </Route>

            <Route path={VERIFY_EMAIL_PENDING_PATH}>
              <IndexRoute component={EmailVerificationPendingC} />
            </Route>

            <Route path={GUEST_CHECKOUT_FORM}>
              <IndexRoute component={GuestCheckoutFormC} />
            </Route>

            <Route path={GUEST_PAYMENT_LINK}>
              <IndexRoute component={GuestPaymentLinkFormC} />
            </Route>

            <Route path={GUEST_PAYOUT_LINK}>
              <IndexRoute component={GuestPayoutLinkFormC} />
            </Route>

            <Route path={GUEST_PAYMENT_LINK_CONFIRMATION}>
              <IndexRoute component={PaymentLinkConfirmationC} />
            </Route>

            <Route path={GUEST_PAYOUT_LINK_CONFIRMATION}>
              <IndexRoute component={PayoutLinkConfirmationC} />
            </Route>

            <Route path={DASHBOARD}>
              <IndexRoute component={DashboardsC} />

              <Route path=':credentialId'>
                <IndexRoute component={HomeC} />

                <Route path='home' component={HomeC} />

                <Route path={HOME_INSIGHTS} component={HomeInsightsC} />

                <Route path={TRANSACTION_INSIGHTS} component={TransactionInsightsTabs} />

                <Route path={MERCHANT_INSIGHTS} component={MerchantInsightsTabs} />

                <Route path={EXCEPTIONS_INSIGHTS} component={ExceptionsInsightsTabs} />

                <Route path={VIRTUAL_TERMINAL}>
                  <IndexRoute component={AuthRoute(CreateTransactionC, NON_MERCHANT_ROLES)} />
                  <Route path=':transferId'>
                    <Route path='create-transaction-confirmation' component={AuthRoute(CreateTransactionConfirmationC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={MERCHANT_PAYOUTS}>
                  <IndexRoute component={MerchantPayoutsTabs} />

                  <Route path={SETTLEMENTS}>
                    <Route path=':settlementId'>
                      <IndexRoute component={SettlementC} />
                    </Route>
                  </Route>

                  <Route path={MERCHANT_DEPOSITS}>
                    <Route path=':transferId'>
                      <IndexRoute component={MerchantDepositC} />
                    </Route>
                  </Route>
                </Route>

                {/* TODO: replace component with UndewritingInsightsTabs once other tabs are ready */}
                <Route path={UNDERWRITING_INSIGHTS} component={MerchantUnderwritingInsightsC} />

                <Route path={ADD_NEW_DEVICE} component={AddNewDeviceC} />

                <Route path={PAYMENTS}>
                  <IndexRoute component={PaymentsC} />

                  <Route path='create-transaction' component={AuthRoute(CreateTransactionC, NON_MERCHANT_ROLES)} />

                  <Route path=':transferId'>
                    <IndexRoute component={PaymentC} />

                    <Route path='create-refund'>
                      <IndexRoute component={CreateRefundC} />
                    </Route>

                    <Route path='dispute'>
                      <IndexRoute component={DisputeTransferFormC} />
                    </Route>

                    <Route path={SPLIT_PAYMENTS}>
                      <Route path=':splitTransferId'>
                        <IndexRoute component={SplitPaymentC} />
                      </Route>
                    </Route>
                    <Route path='create-transaction-confirmation' component={AuthRoute(CreateTransactionConfirmationC, NON_MERCHANT_ROLES)} />

                    <Route path='create-receipt' component={CreateReceiptFormC} />
                  </Route>
                </Route>

                <Route path={SUBSCRIPTIONS}>
                  <IndexRoute component={SubscriptionsC} />

                  <Route path='create' component={AuthRoute(CreateSubscriptionFormC, NON_MERCHANT_ROLES)} />

                  <Route path=':subscriptionId'>
                    <IndexRoute component={SubscriptionC} />

                    <Route path='edit-subscription'>
                      <IndexRoute component={EditSubscriptionFormC} />
                    </Route>
                  </Route>
                </Route>

                <Route path={SUBSCRIPTION_PLANS}>
                  <IndexRoute component={SubscriptionPlansC} />

                  <Route path='create' component={AuthRoute(CreateSubscriptionPlanFormC, NON_MERCHANT_ROLES)} />

                  <Route path=':subscriptionPlanId'>
                    <IndexRoute component={SubscriptionPlanTabsC} />
                    <Route path='edit-subscription-plan'>
                      <IndexRoute component={EditSubscriptionPlanFormC} />
                    </Route>
                  </Route>

                </Route>

                <Route path={DISBURSEMENTS}>
                  <IndexRoute component={DisbursementsC} />

                  <Route path=':transferId'>
                    <IndexRoute component={PaymentC} />
                  </Route>
                </Route>

                <Route path={SEND_PAYOUT}>
                  <IndexRoute component={SendPayoutOptionsC} />

                  <Route path='manual'>
                    <IndexRoute component={AuthRoute(SendPayoutC, NON_MERCHANT_ROLES)} />

                    <Route path=':transferId'>
                      <IndexRoute component={AuthRoute(SendPayoutConfirmationC, NON_MERCHANT_ROLES)} />
                    </Route>
                  </Route>
                </Route>

                <Route path={PAYOUT_LINKS}>
                  <IndexRoute component={AuthRoute(PayoutLinksC, NON_MERCHANT_ROLES)} />

                  <Route path='create' component={AuthRoute(CreatePayoutLinkFormC, NON_MERCHANT_ROLES)} />

                  <Route path=':payoutLinkId'>
                    <IndexRoute component={AuthRoute(PayoutLinkC, NON_MERCHANT_ROLES)} />

                    <Route path={CREATE_CONFIRMATION} component={AuthRoute(PayoutLinkCreatedConfirmationC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={PAYOUT_ATTEMPTS}>
                  <Route path=':transferAttemptId'>
                    <IndexRoute component={AuthRoute(TransferAttemptC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={PAYOUT_LINKS}>
                  <Route path=':payoutLinkId'>
                    <Route path={CREATE_CONFIRMATION} component={AuthRoute(PayoutLinkCreatedConfirmationC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={BALANCE}>
                  <IndexRoute component={AccountBalanceC} />

                  <Route path={ADDING_FUNDS} component={AddFundsC} />

                  <Route path=':balanceEntryId'>
                    <IndexRoute component={BalanceEntryC} />
                  </Route>

                  <Route path={ADDING_FUNDS}>
                    <IndexRoute component={AddFundsC} />
                  </Route>
                </Route>

                <Route path={ADJUSTMENTS}>
                  <IndexRoute component={BalanceAdjustmentsC} />

                  <Route path={ADD_FUNDS}>
                    <IndexRoute component={CreateBalanceAdjustmentC} />
                  </Route>

                  <Route path=':balanceAdjustmentId'>
                    <IndexRoute component={BalanceAdjustmentC} />

                    <Route path={ADD_FUNDS_CONFIRMATION} component={AuthRoute(CreateBalanceAdjustmentConfirmationC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={REFUNDS}>
                  <IndexRoute component={RefundsC} />

                  <Route path=':transferId'>
                    <IndexRoute component={RefundC} />

                    <Route path={SPLIT_REFUNDS}>
                      <Route path=':splitRefundId'>
                        <IndexRoute component={SplitRefundC} />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route path={BALANCE_TRANSFERS}>
                  <IndexRoute component={BalanceTransfersC} />

                  <Route path=':balanceTransferId'>
                    <IndexRoute component={BalanceTransferC} />
                  </Route>
                </Route>

                <Route path={PAYOUTS}>
                  <IndexRoute component={PayoutsC} />

                  <Route path=':transferId'>
                    <IndexRoute component={PayoutC} />
                  </Route>
                </Route>

                <Route path={AUTHORIZATIONS}>
                  <IndexRoute component={AuthorizationsC} />

                  <Route path=':authorizationId'>
                    <IndexRoute component={AuthorizationC} />

                    <Route path='create-receipt' component={CreateReceiptFormC} />
                  </Route>
                </Route>

                <Route path={FEES}>
                  <Route path=':feeId'>
                    <IndexRoute component={FeeC} />
                  </Route>
                </Route>

                <Route path={SETTLEMENTS}>
                  <IndexRoute component={SettlementsC} />

                  <Route path=':settlementId'>
                    <IndexRoute component={SettlementC} />


                    <Route path={CREATE_INSTANT_PAYOUT}>
                      <IndexRoute component={CreateInstantPayoutFormC} />
                      <Route path=':instantPayoutId'>
                        <Route path='instant-payout-confirmation' component={CreateInstantPayoutConfirmationC} />
                      </Route>
                    </Route>

                    <Route path={ENTRIES}>
                      <Route path=':feeId' component={FeeC} />
                    </Route>

                  </Route>
                </Route>

                <Route path='review-settlements'>
                  <Route path=':settlementId'>
                    <IndexRoute component={ReviewSettlementC} />
                  </Route>
                </Route>

                <Route path={ENTRIES}>
                  <Route path=':feeId' component={FeeC} />
                </Route>

                <Route path={DISPUTES}>
                  <IndexRoute component={DisputesC} />

                  <Route path=':disputeId'>
                    <IndexRoute component={DisputeC} />

                    <Route path='update'>
                      <IndexRoute component={DisputeTransferFormC} />
                    </Route>
                  </Route>
                </Route>

                <Route path={ACH_RETURNS}>
                  <IndexRoute component={ACHReturnsC} />

                  <Route path=':transferId'>
                    <IndexRoute component={ACHReturnC} />
                  </Route>
                </Route>

                <Route path={`${REPORTS}-home`}>
                  <IndexRoute component={AuthRoute(ReportsC, ADMIN_PLATFORM_ROLES)} />
                </Route>

                <Route path={REPORTS}>
                  <IndexRoute component={AuthRoute(ReportsTableC, ADMIN_PLATFORM_ROLES)} />
                  <Route path=':reportCategory'>
                    <Route path=':reportType'>
                      <IndexRoute component={AuthRoute(ReportsTableC, ADMIN_PLATFORM_ROLES)} />
                    </Route>
                  </Route>
                </Route>

                <Route path={RECIPIENTS}>
                  <IndexRoute component={AuthRoute(RecipientsC, NON_MERCHANT_ROLES)} />

                  <Route path='add-recipient-identity' component={AuthRoute(AddRecipientFormC, NON_MERCHANT_ROLES)} />

                  <Route path=':identityId'>
                    <IndexRoute component={RecipientC} />

                    <Route path='edit-recipient-identity'>
                      <IndexRoute component={AuthRoute(EditRecipientIdentityContactFormC, NON_MERCHANT_ROLES)} />
                    </Route>
                  </Route>
                </Route>

                <Route path={IDENTITIES}>
                  <IndexRoute component={AuthRoute(IdentitiesC, NON_MERCHANT_ROLES)} />

                  <Route path='new' component={AuthRoute(IdentityNew, NON_MERCHANT_ROLES)} />
                  <Route path='new_identity' component={AuthRoute(MerchantIdentityNewC, ADMIN_PLATFORM_ROLES)} />
                  <Route path='new-merchant-application' component={AuthRoute(MerchantIdentityNewC, ROLE_PARTNER)} />

                  <Route path=':identityId'>
                    <IndexRoute component={AuthRoute(IdentityC, NON_MERCHANT_ROLES)} />

                    <Route path='edit' component={AuthRoute(IdentityEdit, NON_MERCHANT_ROLES)} />
                    <Route path='edit_identity' component={AuthRoute(MerchantIdentityEditC, NON_MERCHANT_ROLES)} />
                    <Route path='edit_new' component={AuthRoute(MerchantIdentityEditC, NON_MERCHANT_ROLES)} />
                    <Route path='new_associated_identity' component={AuthRoute(AssociatedIdentityNewC, NON_MERCHANT_ROLES)} />
                    <Route path='edit_associated_identity' component={AuthRoute(AssociatedIdentityEditC, NON_MERCHANT_ROLES)} />

                    <Route path={MERCHANTS}>
                      <Route path=':merchantId'>
                        <IndexRoute component={AuthRoute(MerchantC, NON_MERCHANT_ROLES)} />

                        <Route path='edit-fee-profile' component={AuthRoute(EditFeeProfileFlowC, ADMIN_PLATFORM_ROLES)} />
                      </Route>
                    </Route>

                    <Route path='audit-log-events'>
                      <Route path=':auditLogEventId' component={AuthRoute(AuditLogEventC, NON_MERCHANT_ROLES)} />
                    </Route>
                  </Route>
                </Route>

                <Route path={MERCHANTS}>
                  <IndexRoute component={AuthRoute(MerchantsC, NON_MERCHANT_ROLES)} />
                </Route>

                <Route path={MERCHANT_DEPOSITS}>
                  <IndexRoute component={MerchantDepositsC} />

                  <Route path=':transferId'>
                    <IndexRoute component={MerchantDepositC} />
                  </Route>
                </Route>

                <Route path={PLATFORM_FUNDING_TRANSFER}>
                  <Route path=':transferId'>
                    <IndexRoute component={PlatformFundingTransferC} />
                  </Route>
                </Route>

                <Route path={PAYOUT_SETTINGS}>
                  <Route path=':payoutSettingsId'>
                    <IndexRoute component={AuthRoute(EditPayoutSettingsFormC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={COMPLIANCE}>
                  <IndexRoute component={AuthRoute(ComplianceC, NON_MERCHANT_ROLES)} />
                </Route>

                <Route path={COMPLIANCE_FORMS}>
                  <Route path=':complianceFormId'>
                    <IndexRoute component={AuthRoute(ComplianceFormC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={COMPLIANCE_FORM_TEMPLATES}>
                  <Route path='new' component={AuthRoute(CreateComplianceFormTemplateC, ADMIN_PLATFORM_ROLES)} />

                  <Route path=':complianceFormTemplateId'>
                    <IndexRoute component={AuthRoute(ComplianceFormTemplateC, ADMIN_PLATFORM_ROLES)} />
                    <Route path='duplicate' component={AuthRoute(CreateComplianceFormTemplateC, ADMIN_PLATFORM_ROLES)} />
                  </Route>
                </Route>

                <Route path={APPLICATIONS}>
                  <IndexRoute component={AuthRoute(ApplicationTabsC, ADMIN_PLATFORM_ROLES)} />

                  <Route path='new' component={AuthRoute(ApplicationNew, ADMIN_PLATFORM_ROLES)} />

                  <Route path=':applicationId'>
                    <IndexRoute component={AuthRoute(ApplicationC, ADMIN_PLATFORM_ROLES)} />

                    <Route path='new_identity' component={AuthRoute(MerchantIdentityNewC, ADMIN_PLATFORM_ROLES)} />

                    <Route path='edit-fee-profile' component={AuthRoute(EditFeeProfileFlowC, ADMIN_PLATFORM_ROLES)} />

                    <Route path='edit-fee-profile-rules' component={AuthRoute(EditFeeProfileRulesFlowC, ADMIN_PLATFORM_ROLES)} />

                    <Route path={CREATE_WEBHOOK} component={AuthRoute(CreateWebhook, [ROLE_PLATFORM])} />

                    <Route path={WEBHOOKS}>
                      <Route path=':webhookId'>
                        <IndexRoute component={AuthRoute(WebhookC, [ROLE_PLATFORM])} />
                        <Route path='edit' component={AuthRoute(EditWebhookC, [ROLE_PLATFORM])} />
                      </Route>
                    </Route>

                    <Route path={WEBHOOK_LOGS}>
                      <Route path=':webhookLogId'>
                        <IndexRoute component={AuthRoute(WebhookLogC, [ROLE_PLATFORM])} />
                      </Route>
                    </Route>

                    <Route path={PROCESSORS}>
                      <Route path=':processor'>
                        <IndexRoute component={AuthRoute(ApplicationProcessorC, ADMIN_PLATFORM_ROLES)} />
                      </Route>
                    </Route>

                    <Route path={SET_VELOCITY_LIMITS}>
                      <IndexRoute component={AuthRoute(SetVelocityLimitsFlowC, ADMIN_PLATFORM_ROLES)} />
                    </Route>

                    <Route path={UPDATE_APPLICATION_CARD_LIMITS}>
                      <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                    </Route>

                    <Route path={UPDATE_APPLICATION_ACH_LIMITS}>
                      <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                    </Route>

                    <Route path={UPDATE_RECIPIENT_CARD_LIMITS}>
                      <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                    </Route>

                    <Route path={UPDATE_RECIPIENT_ACH_LIMITS}>
                      <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                    </Route>

                    <Route path={UPDATE_BALANCE_ADJUSTMENT_LIMITS}>
                      <IndexRoute component={AuthRoute(UpdateBalanceAdjustmentsVelocityLimitsFlowC, ADMIN_PLATFORM_ROLES)} />
                    </Route>
                  </Route>
                </Route>

                <Route path={CUSTOMER_ONBOARDING_FORM}>
                  <Route path='new' component={AuthRoute(CreateCustomerOnboardingFormFlowC, ADMIN_PLATFORM_ROLES)} />

                  <Route path=':customerOnboardingFormId'>
                    <IndexRoute component={AuthRoute(CustomerOnboardingFormC, ADMIN_PLATFORM_ROLES)} />
                    <Route path='success' component={AuthRoute(CustomerOnboardingFormSuccessC, ADMIN_PLATFORM_ROLES)} />
                  </Route>
                </Route>

                <Route path={UNDERWRITING}>
                  <Route path={ONBOARDING_APPLICATIONS}>
                    <IndexRoute component={AuthRoute(UnderwritingApplicationsTabs, ADMIN_PLATFORM_ROLES)} />

                    <Route path=':reviewId'>
                      <IndexRoute component={UnderwritingApplicationC} />
                    </Route>
                  </Route>

                  <Route path={ONBOARDING_MERCHANTS}>
                    <IndexRoute component={AuthRoute(UnderwritingMerchantsTabs, ADMIN_PLATFORM_ROLES)} />

                    <Route path=':reviewId'>
                      <IndexRoute component={UnderwritingMerchantC} />

                      <Route path={PAST_RUN}>
                        <Route path=':runId'>
                          <IndexRoute component={UnderwritingMerchantC} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path={ONBOARDING_RECIPIENTS}>
                    <IndexRoute component={AuthRoute(UnderwritingRecipientsC, ADMIN_PLATFORM_ROLES)} />

                    <Route path=':reviewId'>
                      <IndexRoute component={UnderwritingRecipientC} />
                    </Route>
                  </Route>
                </Route>

                <Route path={ADVERSE_MEDIA}>
                  <Route path=':reportId'>
                    <IndexRoute component={IdentityMatchC} />
                  </Route>
                </Route>

                <Route path={PAYMENT_INSTRUMENTS}>
                  <IndexRoute component={PaymentInstrumentsC} />

                  <Route path=':paymentInstrumentId'>
                    <IndexRoute component={PaymentInstrumentC} />
                  </Route>
                </Route>

                <Route path={ONBOARDING_FORMS}>
                  <IndexRoute component={AuthRoute(OnboardingFormsC, NON_MERCHANT_ROLES)} />

                  <Route path=':onboardingFormId'>
                    <IndexRoute component={OnboardingFormC} />
                  </Route>
                </Route>

                <Route path={ONBOARDING_SETTLEMENTS}>
                  <IndexRoute component={AuthRoute(ReviewQueueSettlementsC, ADMIN_PLATFORM_ROLES)} />
                  <Route path=':reviewQueueId'>
                    <IndexRoute component={ReviewQueueItemC} />
                  </Route>
                </Route>

                <Route path={REVIEW_QUEUE_IDENTITIES}>
                  <IndexRoute component={AuthRoute(IdentitiesTabs, ADMIN_PLATFORM_ROLES)} />
                  <Route path=':reviewQueueId'>
                    <IndexRoute component={ReviewIdentityV1C} />
                  </Route>
                </Route>

                <Route path={SETTINGS}>
                  <IndexRoute component={AuthRoute(SettingsC, PARTNER_AND_MERCHANT_ROLES)} />
                </Route>

                <Route path={DEVELOPER}>
                  <IndexRoute component={AuthRoute(DeveloperC, NON_MERCHANT_ROLES)} />

                  <Route path={WEBHOOKS}>
                    <Route path={CREATE_WEBHOOK} component={AuthRoute(CreateWebhook, PLATFORM_AND_PARTNER_ROLES)} />

                    <Route path=':webhookId'>
                      <IndexRoute component={AuthRoute(WebhookC, PLATFORM_AND_PARTNER_ROLES)} />
                      <Route path='edit' component={AuthRoute(EditWebhookC, PLATFORM_AND_PARTNER_ROLES)} />
                    </Route>
                  </Route>

                  <Route path={WEBHOOK_LOGS}>
                    <Route path=':webhookLogId'>
                      <IndexRoute component={AuthRoute(WebhookLogC, PLATFORM_AND_PARTNER_ROLES)} />
                    </Route>
                  </Route>

                  <Route path={API_LOGS}>
                    <Route path=':apiLogId' component={AuthRoute(APILogC, PLATFORM_AND_PARTNER_ROLES)} />
                  </Route>
                </Route>

                <Route path={NOTIFICATIONS}>
                  <IndexRoute component={NotificationsC} />
                </Route>

                <Route path={UPDATE_TRANSFERS}>
                  <IndexRoute component={AuthRoute(UpdateTransfers, [ROLE_ADMIN])} />
                </Route>

                <Route path={UPDATE_VERIFICATIONS}>
                  <IndexRoute component={AuthRoute(UpdateVerifications, [ROLE_ADMIN])} />
                </Route>

                <Route path={IDENTITY_UPLOADER}>
                  <IndexRoute component={AuthRoute(IdentityUploader, [ROLE_PLATFORM], isAdministrator)} />
                </Route>

                <Route path={SUBSCRIPTION_BILLING}>
                  <IndexRoute component={SubscriptionBillingTabs} />

                  <Route path={SUBSCRIPTION_SCHEDULES}>
                    <Route path=':subscriptionScheduleId'>
                      <IndexRoute component={SubscriptionScheduleC} />
                    </Route>
                  </Route>
                </Route>

                <Route path={BUYERS}>
                  <IndexRoute component={AuthRoute(BuyersC, [ROLE_PARTNER])} />

                  <Route path=':identityId'>
                    <IndexRoute component={AuthRoute(BuyerC, [ROLE_PARTNER])} />
                  </Route>
                </Route>

                <Route path={COMPANY}>
                  <IndexRoute component={CompanyTabsC} />

                  <Route path={USERS}>
                    <Route path='new' component={AuthRoute(UserNew, ADMIN_PLATFORM_ROLES, isAdminOrClientAdmin)} />
                  </Route>

                  <Route path={MEMBERS}>
                    <Route path={ADD_TEAM_MEMBER} component={AuthRoute(CreateTeamMemberC)} />

                    <Route path=':memberId'>
                      <IndexRoute component={MemberC} />

                      <Route path='edit' component={AuthRoute(CreateTeamMemberC, [ROLE_PARTNER])} />
                    </Route>
                  </Route>

                  <Route path={SET_VELOCITY_LIMITS}>
                    <IndexRoute component={AuthRoute(SetVelocityLimitsFlowC, ADMIN_PLATFORM_ROLES)} />
                  </Route>

                  <Route path={UPDATE_APPLICATION_CARD_LIMITS}>
                    <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                  </Route>

                  <Route path={UPDATE_APPLICATION_ACH_LIMITS}>
                    <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                  </Route>

                  <Route path={UPDATE_RECIPIENT_CARD_LIMITS}>
                    <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                  </Route>

                  <Route path={UPDATE_RECIPIENT_ACH_LIMITS}>
                    <IndexRoute component={AuthRoute(UpdateVelocityLimitsFormC, ADMIN_PLATFORM_ROLES)} />
                  </Route>

                  <Route path={ROLES}>
                    <Route path={ADD_ROLE} component={AuthRoute(CreateRoleC)} />
                    <Route path={DUPLICATE_ROLE} component={AuthRoute(DuplicateRoleC)} />
                    <Route path=':roleId'>
                      <IndexRoute component={RoleC} />
                    </Route>
                  </Route>
                </Route>

                <Route path={EXPORTS}>
                  <IndexRoute component={ExportsC} />
                </Route>

                <Route path={PAYOUT_PLANS}>
                  <IndexRoute component={PayoutPlansC} />

                  <Route path='new' component={PayoutPlanNew} />

                  <Route path=':payoutPlanId'>
                    <IndexRoute component={PayoutPlanC} />
                  </Route>
                </Route>

                <Route path={PLATFORMS}>
                  <IndexRoute component={AuthRoute(PlatformsC, [ROLE_ADMIN])} />

                  <Route path=':platformId'>
                    <IndexRoute component={PlatformC} />

                    <Route path={PROCESSOR_CONFIGS}>
                      <IndexRoute component={PlatformProcessorConfigsC} />

                      <Route path=':processorConfigId'>
                        <IndexRoute component={PlatformProcessorConfigC} />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route path={LIVE_ACCOUNT_APPLICATION_FORM}>
                  <IndexRoute component={LiveAccountApplicationFlowC} />

                  <Route path=':accessFormId'>
                    <IndexRoute component={LiveAccountApplicationFlowC} />
                  </Route>
                </Route>

                <Route path={LIVE_ACCOUNT_APPLICATION}>
                  <Route path=':accessFormId'>
                    <IndexRoute component={AccessFormC} />
                  </Route>
                </Route>

                <Route path={PAYMENT_LINKS}>
                  <IndexRoute component={AuthRoute(PaymentLinksC, NON_MERCHANT_ROLES)} />

                  <Route path='create' component={AuthRoute(CreatePaymentLinkFormC, NON_MERCHANT_ROLES)} />

                  <Route path=':paymentLinkId'>
                    <IndexRoute component={AuthRoute(PaymentLinkC, NON_MERCHANT_ROLES)} />

                    <Route path={CREATE_CONFIRMATION} component={AuthRoute(PaymentLinkCreatedConfirmationC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={PURCHASES}>
                  <Route path=':purchaseId'>
                    <IndexRoute component={AuthRoute(PurchaseC, NON_MERCHANT_ROLES)} />
                  </Route>
                </Route>

                <Route path={PAYMENT_DEVICES}>
                  <IndexRoute component={AuthRoute(PaymentDevicesC)} />

                  <Route path=':deviceId'>
                    <IndexRoute component={DeviceC} />
                  </Route>
                </Route>

                <Route path='statements'>
                  <IndexRoute component={AuthRoute(MerchantStatementsC, ROLE_MERCHANT)} />
                </Route>

                <Route path={MONITORING_ALERTS}>
                  <IndexRoute component={AuthRoute(MonitoringAlertsC, [ROLE_PLATFORM])} />

                  <Route path=':monitoringAlertId'>
                    <IndexRoute component={MonitoringAlertC} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path={ADMINISTRATOR} component={MainAdminC}>
              <Route path={USERS}>
                <IndexRoute component={AdminUsersC} />

                <Route path='new' component={AdminUserNew} />

                <Route path=':userId'>
                  <IndexRoute component={AdminUserC} />
                </Route>
              </Route>

              <Route path={EMAIL_TEMPLATES}>
                <IndexRoute component={EmailTemplatesC} />
              </Route>
            </Route>

            <Route path={EXPORTS}>
              <IndexRoute component={ExportsC} />
            </Route>

            <Route path={PAYOUT_PLANS}>
              <IndexRoute component={PayoutPlansC} />
            </Route>

            <Route path={COMPONENTS}>
              <IndexRoute component={ComponentsC} />
            </Route>

            <Route path={GATEWAY_ADMINISTRATOR_TOOLS} component={GatewayAdminToolsC}>
              <IndexRedirect to={FILES_MANAGEMENT} />

              <Route path={FILES_MANAGEMENT}>
                <IndexRoute component={FilesManagementC} />
              </Route>

              <Route path={TRANSACTIONS_MANAGEMENT}>
                <IndexRoute component={TransactionsManagementC} />
              </Route>
            </Route>
          </Route>
        </Router>
      </Provider>
    </ThemeProvider>
  )
}

MainRouter.propTypes = {
  store: PropTypes.object.isRequired,
}

const LDProviderConfig = {
  clientSideID: launchDarklyClientId,
  user: {
    key: ANON_KEY,
  },
}

export default withLDProvider(LDProviderConfig)(MainRouter)
