import React, { Component } from 'react'
import { connect } from 'react-redux'
import UpdateBalanceAdjustmentsVelocityLimitsFlow from './UpdateBalanceAdjustmentsVelocityLimitsFlow'
import UpdateBalanceAdjustmentsVelocityLimitsFormC from 'components/Customer/Forms/UpdateBalanceAdjustmentsVelocityLimitsForm/UpdateBalanceAdjustmentsVelocityLimitsFormC'
import UpdateBalanceAdjustmentsVelocityLimitsReviewFormC from 'components/Customer/Forms/UpdateBalanceAdjustmentsVelocityLimitsReviewForm/UpdateBalanceAdjustmentsVelocityLimitsReviewFormC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import get from 'lodash/get'

import {
  APPLICATION,
  REVIEW,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const applicationId = get(props, 'params.applicationId')
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)

  const steps = [
    {
      name: APPLICATION,
      component: UpdateBalanceAdjustmentsVelocityLimitsFormC,
      dataKey: 'balanceAdjustmentsVelocityLimits',
      props: {
        applicationId,
        isSetLimitsFlow: false,
      },
      formValues: () => reduxFormValues(UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM),
    },
    {
      name: REVIEW,
      component: UpdateBalanceAdjustmentsVelocityLimitsReviewFormC,
      dataKey: 'review',
      props: {
        applicationId,
      },
    },
  ]

  return {
    credentials,
    applicationId,
    steps,
  }
}

class UpdateBalanceAdjustmentsVelocityLimitsFlowC extends Component {
  render() {
    return (
      <UpdateBalanceAdjustmentsVelocityLimitsFlow
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UpdateBalanceAdjustmentsVelocityLimitsFlowC)
