import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateCredentialForm from './CreateCredentialForm'
import submitCredentialForm from 'utilities/submit/submitCredentialForm'
import get from 'lodash/get'
import head from 'lodash/head'
import selectOption from 'utilities/forms/selectOption'

import {
  QA_URL,
  SANDBOX_URL,
  LIVE_URL,
} from 'constants/credentialConstants'

import {
  QA_ENVIRONMENT,
  SANDBOX_ENVIRONMENT,
  LIVE_ENVIRONMENT,
} from 'constants/environmentConstants'

const credentialTypeOptions = [
  selectOption('Payment Facilitator', 'pay-fac'),
  selectOption('Push-to-Card', 'push-to-card'),
]

const apiHostOptions = [
  selectOption(QA_ENVIRONMENT, QA_URL),
  selectOption(SANDBOX_ENVIRONMENT, SANDBOX_URL),
  selectOption(LIVE_ENVIRONMENT, LIVE_URL),
]

const mapStateToProps = (state, props) => {
  const onSubmit = get(props, 'onSubmit', submitCredentialForm)

  const initialValues = {
    credentialType: get(head(credentialTypeOptions), 'value'),
    apiHost: get(head(apiHostOptions), 'value'),
  }

  return {
    onSubmit,
    initialValues,
    credentialTypeOptions,
    apiHostOptions,
  }
}

class CreateCredentialFormC extends Component {
  render() {
    return (
      <CreateCredentialForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateCredentialFormC)
