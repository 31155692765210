import './ComplianceFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { PATCH_COMPLIANCE_FORM_F_REQUEST } from 'constants/flowConstants'

import {
  FORM_INFO,
  BUSINESS_INFO,
  ATTESTATION,
} from 'constants/language/languageConstants'

const ComplianceForm = ({
  complianceFormInfoSectionData,
  businessInfoSectionData,
  attestationSectionData,
  isFetching = false,
  tagsSectionData = [],
  complianceForm = {},
  contextBarData = {},
  headerData = {},
  attestationActions = [],
  actions = [],
}) => {
  return (
    <div className='ComplianceForm'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        actions={actions}
      />

      <PageSectionV2C
        className='form-info'
        title={FORM_INFO}
        isFetching={isFetching}
        columns={complianceFormInfoSectionData}
      />

      <PageSectionV2C
        className='business-info'
        title={BUSINESS_INFO}
        isFetching={isFetching}
        columns={businessInfoSectionData}
      />

      <PageSectionV2C
        className='attestation-info'
        title={ATTESTATION}
        isFetching={isFetching}
        columns={attestationSectionData}
        actions={attestationActions}
      />

      <TagsPageSectionV2
        actionType={PATCH_COMPLIANCE_FORM_F_REQUEST}
        data={tagsSectionData}
        record={complianceForm}
        isFetching={isFetching}
      />
    </div>
  )
}

ComplianceForm.propTypes = {
  complianceFormInfoSectionData: PropTypes.array,
  businessInfoSectionData: PropTypes.array,
  attestationSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  tagsSectionData: PropTypes.array,
  complianceForm: PropTypes.object,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  attestationActions: PropTypes.array,
  actions: PropTypes.array,
}

export default ComplianceForm
