import paymentsAPI from 'utilities/api/paymentsAPI'
import { PURCHASES } from 'constants/apiConstants'

const getPurchasesAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    path: PURCHASES,
    queries,
    credentials,
    meta,
    service: PURCHASES,
  })
}

export default getPurchasesAPI
