import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmailAddressReportV2 from './EmailAddressReportV2'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import { phoneAndEmailAddressReportStatusMap } from 'constants/statusConstants'
import get from 'lodash/get'

import {
  EMAIL_CREATED,
  EMAIL_DOMAIN,
  EMAIL_DOMAIN_CREATED,
  EMAIL_DOMAIN_EXISTS,
  EMAIL_DOMAIN_IS_NEW,
  EMAIL_IS_DELIVERABLE,
  EMAIL_IS_DISPOSABLE,
  EMAIL_IS_NEW,
  EMAIL_IS_SPAM,
  EMAIL_IS_SUSPICIOUS,
  RESULT,
  WEBSITE_DOMAIN,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    emailAddressDomainMatchDetailsResult,
    emailAddressDomainMatchDetailsDescription,
    emailAddressDomainMatchDetailsEmailAddressDomain,
    emailAddressDomainMatchDetailsWebsiteDomain,
    emailAddressExistsResult,
    emailAddressExistsDescription,
    emailAddressDomainIsNewResult,
    emailAddressDomainIsNewDescription,
    emailAddressDomainIsNewCreatedAt,
    emailAddressIsDeliverableResult,
    emailAddressIsDeliverableDescription,
    emailAddressIsNewResult,
    emailAddressIsNewDescription,
    emailAddressIsNewCreatedAt,
    emailAddressIsDisposableResult,
    emailAddressIsDisposableDescription,
    emailAddressIsSpamResult,
    emailAddressIsSpamDescription,
    emailAddressIsSuspiciousResult,
    emailAddressIsSuspiciousDescription,
  ] = getMany(report, [
    'details.domain_match_details.result',
    'details.domain_match_details.description',
    'details.domain_match_details.email_address_domain',
    'details.domain_match_details.website_domain',
    'details.email_domain_exists.result',
    'details.email_domain_exists.description',
    'details.email_domain_is_new.result',
    'details.email_domain_is_new.description',
    'details.email_domain_is_new.email_address_created_at',
    'details.email_address_is_deliverable.result',
    'details.email_address_is_deliverable.description',
    'details.email_address_is_new.result',
    'details.email_address_is_new.description',
    'details.email_address_is_new.email_address_created_at',
    'details.email_address_is_disposable.result',
    'details.email_address_is_disposable.description',
    'details.email_address_is_spam.result',
    'details.email_address_is_spam.description',
    'details.email_address_is_suspicious.result',
    'details.email_address_is_suspicious.description',
  ])

  const emailAddressDomainMatchCheckData = convertPageSectionDataToV2([
    {
      label: RESULT,
      value: <ColorcodedStatus value={emailAddressDomainMatchDetailsResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressDomainMatchDetailsDescription,
    },
    {
      label: EMAIL_DOMAIN,
      value: emailAddressDomainMatchDetailsEmailAddressDomain,
    },
    {
      label: WEBSITE_DOMAIN,
      value: emailAddressDomainMatchDetailsWebsiteDomain,
    },
  ])

  const emailAddressRiskChecksData = convertPageSectionDataToV2([
    {
      label: EMAIL_DOMAIN_EXISTS,
      value: <ColorcodedStatus value={emailAddressExistsResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressExistsDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_DOMAIN_IS_NEW,
      value: <ColorcodedStatus value={emailAddressDomainIsNewResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressDomainIsNewDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_DOMAIN_CREATED,
      value: formatDate({ date: emailAddressDomainIsNewCreatedAt }),
    },
    {
      label: EMAIL_IS_DELIVERABLE,
      value: <ColorcodedStatus value={emailAddressIsDeliverableResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressIsDeliverableDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_IS_NEW,
      value: <ColorcodedStatus value={emailAddressIsNewResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressIsNewDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_CREATED,
      value: formatDate({ date: emailAddressIsNewCreatedAt }),
    },
    {
      label: EMAIL_IS_DISPOSABLE,
      value: <ColorcodedStatus value={emailAddressIsDisposableResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressIsDisposableDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_IS_SPAM,
      value: <ColorcodedStatus value={emailAddressIsSpamResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressIsSpamDescription,
      tooltipPosition: 'top',
    },
    {
      label: EMAIL_IS_SUSPICIOUS,
      value: <ColorcodedStatus value={emailAddressIsSuspiciousResult} data={phoneAndEmailAddressReportStatusMap} />,
      tooltip: emailAddressIsSuspiciousDescription,
      tooltipPosition: 'top',
    },
  ])

  return {
    credentials,
    emailAddressDomainMatchCheckData,
    emailAddressRiskChecksData,
  }
}

class EmailAddressReportV2C extends Component {
  render() {
    return (
      <EmailAddressReportV2 {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EmailAddressReportV2C)
