import React, { Component } from 'react'
import { connect } from 'react-redux'
import ToggleDataSections from 'components/Customer/Shared/Page/PageSection/ToggleDataSections'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const mapStateToProps = (state, props) => {
  const data = get(props, 'data')

  const [
    sectionData,
    sectionTitle,
    sectionName,
    sectionFields,
    showSectionsDefault,
  ] = getMany(data, [
    'sectionData',
    'sectionTitle',
    'sectionName',
    'sectionFields',
    'showSectionsDefault',
  ])

  return {
    sectionData,
    sectionTitle,
    sectionName,
    sectionFields,
    showSectionsDefault,
  }
}

class ToggleDataSectionsC extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps) {
    // conditionally show or hide all sections via showSectionsDefault
    const { sectionData: prevSectionData } = prevProps
    const { sectionData = {}, showSectionsDefault } = this.props

    if (isEmpty(prevSectionData) && !isEmpty(sectionData) && showSectionsDefault) {
      const showSections = reduce(sectionData, (total, amount, amountId) => {
        return merge({}, total, { [amountId]: true })
      }, {})

      this.setState({ showSections })
    }
  }

  toggleSection = (sectionName) => {
    const { showSections } = this.state
    const showSection = get(this.state, `showSections.${sectionName}`)

    this.setState(merge({}, {
      showSections: {
        ...showSections,
        [sectionName]: !showSection,
      },
    }))
  }

  render() {
    const { toggleSection } = this
    const { showSections } = this.state

    return (
      <ToggleDataSections
        {...this.props}
        toggleSection={toggleSection}
        showSections={showSections}
      />
    )
  }
}

export default connect(mapStateToProps)(ToggleDataSectionsC)
