import createAction from 'utilities/actions/createAction'
import { SHOW_DOCUMENTATION } from 'constants/actionConstants'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { LINK_TO_FINIX_DOCUMENTATION } from 'constants/amplitudeConstants'

const showDocumentationActionRequest = ({
  credentials,
}) => {
  return createAction({
    type: SHOW_DOCUMENTATION,
    meta: {
      actionId: sendAmplitudeActionEvent(LINK_TO_FINIX_DOCUMENTATION, {
        credentials,
      }),
    },
  })
}

export default showDocumentationActionRequest
