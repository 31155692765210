import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditPayoutSettingsConfirmationModal from './EditPayoutSettingsConfirmationModal'
import isPatching from 'utilities/is/isPatching'

const mapStateToProps = (state, props) => {
  return {
    isPatching: isPatching(state),
  }
}

class EditPayoutSettingsConfirmationModalC extends Component {
  render() {
    return (
      <EditPayoutSettingsConfirmationModal {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EditPayoutSettingsConfirmationModalC)
