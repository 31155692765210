import './ApplicationPaymentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import defaultColumnDescriptors from 'components/Customer/Pages/Payments/columnDescriptors'
import { APPLICATION_TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getApplicationTransfers } from 'state-layer/selectors'

import {
  GET_APPLICATION_TRANSFERS,
  TRANSFER,
} from 'constants/flowConstants'

const ApplicationPayments = ({
  flowValues = {},
  initialQueries = {},
  initialFilters = {},
  allowedFilters = [],
  transactionPath = '',
  isFetching = false,
  applicationId = '',
  quickFilters,
  customPath = '',
  columnDescriptors = defaultColumnDescriptors,
  customFileNameCSV = '',
  actions = [],
}) => {
  return (
    <div className='ApplicationPayments'>
      <TableC
        flow={GET_APPLICATION_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getApplicationTransfers}
        selectorId={applicationId}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={APPLICATION_TRANSFERS}
        allowedFilters={allowedFilters}
        path={customPath || transactionPath}
        isFetching={isFetching}
        showAdvancedExport
        entityType={TRANSFER}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV={customFileNameCSV || `Application_${applicationId}_Transfers`}
        quickFilters={quickFilters}
        actions={actions}
      />
    </div>
  )
}

ApplicationPayments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  allowedFilters: PropTypes.array,
  transactionPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  quickFilters: PropTypes.array,
  customPath: PropTypes.string,
  columnDescriptors: PropTypes.array,
  customFileNameCSV: PropTypes.string,
  actions: PropTypes.array,
}

export default ApplicationPayments
