import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import get from 'lodash/get'

// TODO: add unit tests
const frontendChartModel = ({ data: chart }) => {
  const [
    id,
    name,
    type,
    data,
    formData,
    category,
    order,
    metadata,
    previousCycle,
    currentCycle,
    deltaPercent,
  ] = getMany(chart, [
    'slice_id',
    'slice_name',
    'viz_type',
    'data',
    'form_data',
    'metadata.category',
    'metadata.order_id',
    'metadata',
    'previous_cycle',
    'current_cycle',
    'delta_percent',
  ])

  // check for empty data or null value and convert to 0
  const metric = get(formData, 'metric', 'amount')
  const dataNull = size(data) === 1 && get(data, `[0].${metric}`) === null
  const normalizedData = (isEmpty(data) || dataNull) ? [{ [metric]: 0 }] : data

  return removeUndefined({
    id,
    name,
    type,
    data: normalizedData,
    formData,
    category,
    order,
    metadata,
    previousCycle,
    currentCycle,
    deltaPercent,
  })
}

export default frontendChartModel
