import './LiveAccountApplicationAdditionalDocumentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { FILE_CHECK } from 'constants/iconConstants'
import { DOCUMENTS_EMPTY_MESSAGE } from 'constants/language/languageConstants'
import { infoTableTheme } from 'constants/themeConstants'

const LiveAccountApplicationAdditionalDocuments = ({
  isFetching = false,
  accessFormAdditionalDocuments = {},
  showFileViewerOnClick = () => {},
  disableClickAction = false,
}) => {
  return (
    <div className='LiveAccountApplicationAdditionalDocuments'>
      <TableC
        columnDescriptors={columnDescriptors}
        isFetching={isFetching}
        emptyMessage={DOCUMENTS_EMPTY_MESSAGE}
        icon={`fa fa-${FILE_CHECK}`}
        showTableFooter={false}
        data={accessFormAdditionalDocuments}
        clickAction={disableClickAction ? () => {} : showFileViewerOnClick}
        theme={disableClickAction ? infoTableTheme : null}
      />
    </div>
  )
}

LiveAccountApplicationAdditionalDocuments.propTypes = {
  isFetching: PropTypes.bool,
  accessFormAdditionalDocuments: PropTypes.object,
  showFileViewerOnClick: PropTypes.func,
  disableClickAction: PropTypes.bool,
}

export default LiveAccountApplicationAdditionalDocuments
