import './PayoutSettingsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PayoutSettingsSchedulePreview from './PayoutSettingsSchedulePreview'
import PayoutPlanContentsC from 'components/Customer/Pages/PayoutPlan/PayoutPlanContentsC'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'

import {
  PAYOUT_SETTINGS,
  PAYOUT_PREFERENCE,
  FEE_PREFERENCE_TITLE,
  FEE_SCHEDULE_PREVIEW,
  FEE_SCHEDULE_PREVIEW_DESCRIPTION,
  PAYOUT_SCHEDULE_PREVIEW,
  PAYOUT_SCHEDULE_PREVIEW_DESCRIPTION,
} from 'constants/language/languageConstants'

const PayoutSettings = ({
  payoutPreferenceCardData = [],
  feePreferenceCardData = [],
  isFetching = false,
  payoutSettingsActions = [],
  payoutSettings = {},
  showPayoutPlan = false,
  payoutPlanId = '',
  isTypeGross,
}) => {
  return (
    <div className='PayoutSettings'>
      <PageSectionV2C
        title={PAYOUT_SETTINGS}
        isFetching={isFetching}
        actions={payoutSettingsActions}
        className='payout-settings'
      />
      <PageSectionV2C
        title={PAYOUT_PREFERENCE}
        isFetching={isFetching}
        columns={payoutPreferenceCardData}
        className='bank-accounts'
      />

      <Accordion
        title={PAYOUT_SCHEDULE_PREVIEW}
        description={PAYOUT_SCHEDULE_PREVIEW_DESCRIPTION}
      >
        <PayoutSettingsSchedulePreview data={payoutSettings} />
      </Accordion>

      {isTypeGross && (
        <>
          <PageSectionV2C
            title={FEE_PREFERENCE_TITLE}
            isFetching={isFetching}
            columns={feePreferenceCardData}
            className='fee-preference'
          />

          <Accordion
            title={FEE_SCHEDULE_PREVIEW}
            description={FEE_SCHEDULE_PREVIEW_DESCRIPTION}
          >
            <PayoutSettingsSchedulePreview data={payoutSettings} isFeePreferenceSection />
          </Accordion>
        </>
      )}

      {showPayoutPlan && (
      <PayoutPlanContentsC
        payoutPlanId={payoutPlanId}
      />
      )}
    </div>
  )
}

PayoutSettings.propTypes = {
  payoutPreferenceCardData: PropTypes.array,
  feePreferenceCardData: PropTypes.array,
  isFetching: PropTypes.bool,
  payoutSettingsActions: PropTypes.array,
  payoutSettings: PropTypes.object,
  showPayoutPlan: PropTypes.bool,
  payoutPlanId: PropTypes.string,
  isTypeGross: PropTypes.bool,
}

export default PayoutSettings
