import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { accessFormIconMap } from 'constants/statusConstants'

import {
  ID,
  CREATED_ON,
  SUBMITTED_ON,
  UPDATED_ON,
  SUBMITTED_BY,
  STATUS,
} from 'constants/language/languageConstants'

import {
  ACCESS_FORM_STATE_TO_DISPLAY_MAP,
  ACCESS_FORM_STATUS_MAP,
} from 'constants/underwritingConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(SUBMITTED_ON, 'displaySubmittedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={SUBMITTED_ON} />,
    formatter: ({ submittedAt }) => <DateTimestampColumn timestamp={submittedAt} />,
  }),
  column(UPDATED_ON, 'displayUpdatedAt', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
  column(SUBMITTED_BY, 'submittedBy', { className: 'large' }),
  column(STATUS, 'displayStatus', {
    className: 'grow',
    formatter: ({ status }) => <ColorcodedStatus data={ACCESS_FORM_STATUS_MAP} value={ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] ? ACCESS_FORM_STATE_TO_DISPLAY_MAP[status] : status} customIconMap={accessFormIconMap} titleCase={false} />,
  }),
]

export default columnDescriptors
