import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import UnderwritingApplicationsC from 'components/Customer/Pages/UnderwritingApplications/UnderwritingApplicationsC'
import UnderwritingDisbursementsC from 'components/Customer/Pages/UnderwritingApplications/UnderwritingDisbursements/UnderwritingDisbursementsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

import {
  PLATFORMS_WORKFLOW,
  STANDALONE_MERCHANTS_WORKFLOW,
  DISBURSEMENTS_WORKFLOW,
} from 'constants/underwritingReviewConstants'

import {
  PLATFORM,
  INDIVIDUAL_BUSINESS,
  APPLICATION_UNDERWRITING,
  APPLICATION_UNDERWRITING_SUBTITLE_DESCRIPTION,
  DISBURSEMENTS,
} from 'constants/language/languageConstants'

const tabs = [
  {
    id: 'platform',
    name: PLATFORM,
    component: UnderwritingApplicationsC,
    queries: {
      workflow_name: PLATFORMS_WORKFLOW,
    },
  },
  {
    id: 'individual-business',
    name: INDIVIDUAL_BUSINESS,
    component: UnderwritingApplicationsC,
    queries: {
      workflow_name: STANDALONE_MERCHANTS_WORKFLOW,
    },
  },
  {
    id: 'disbursements',
    name: DISBURSEMENTS,
    component: UnderwritingDisbursementsC,
    queries: {
      workflow_name: DISBURSEMENTS_WORKFLOW,
    },
  },
]

const UnderwritingApplicationsTabs = () => {
  return (
    <div className='UnderwritingApplicationsTabs'>
      <HeaderV2C
        title={APPLICATION_UNDERWRITING}
        subTitle={APPLICATION_UNDERWRITING_SUBTITLE_DESCRIPTION}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

export default UnderwritingApplicationsTabs
