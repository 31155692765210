import './FeeProfileRulesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import Loader from 'components/Shared/Loader/Loader'
import getMany from 'utilities/get/getMany'
import { countryNameByCountryCode } from 'constants/countryConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ACH_TRANSFERS,
  ACH_TRANSFERS_FEE_PROFILE_RULES_SUBTITLE,
  ADDITIONAL_FEES,
  ADDITIONAL_FEES_FEE_PROFILE_SUBTITLE,
  BLENDED,
  CARD_BRAND_OVERRIDES,
  CARD_PAYMENTS,
  CARD_PAYMENTS_SUBTITLE,
  CHARGEBACKS,
  FEE_PROFILE_LIMITS,
  FEE_PROFILE_LIMITS_EMPTY_MESSAGE,
  FEE_TYPE,
  FIXED_FEE,
  INTERCHANGE_PLUS,
  PASS_THROUGH_CARD_ASSOCIATION_FEES,
  PERCENTAGE_FEE,
  RETURNS,
} from 'constants/language/languageConstants'

import {
  AMEX_LABEL,
  DISCOVER_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

const Header = ({ title = FEE_TYPE, currency, showPercentageColumn = true, showCurrency = true }) => {
  return (
    <>
      <div className='item header p-2-bold'>{title}</div>
      {showPercentageColumn && (
        <div className='item header'>
          <div className='p-2-bold'>{PERCENTAGE_FEE}</div>
          {showCurrency && <div className='currency'>{currency}</div>}
        </div>
      )}
      <div className='item header'>
        <div className='p-2-bold'>{FIXED_FEE}</div>
        {showCurrency && <div className='currency'>{currency}</div>}
      </div>
    </>
  )
}

const ThreeColumnRows = ({ rows }) => {
  return map(rows, ({ isVisible, columns = [] }) => {
    return isVisible ? (
      <>
        <div className='item'>{columns[0]}</div>
        <div className='item'>{columns[1]}</div>
        <div className='item'>{columns[2]}</div>
      </>
    ) : null
  })
}

const TwoColumnRows = ({ rows }) => {
  return map(rows, ({ isVisible, columns = [] }) => {
    return isVisible ? (
      <>
        <div className='item'>{columns[0]}</div>
        <div className='item'>{columns[1]}</div>
      </>
    ) : null
  })
}

const FeeProfileRules = ({
  isFetching = false,
  hasNoFeeProfileRules = false,
  blendedData = [],
  interchangeData = [],
  achTransfersData = [],
  additionalFeesData = [],
  editFeeProfileRulesActions = [],
}) => {
  return (
    <div className='FeeProfileRules'>
      <PageSectionV2C
        title={FEE_PROFILE_LIMITS}
        subTitle={hasNoFeeProfileRules ? FEE_PROFILE_LIMITS_EMPTY_MESSAGE : ''}
        className='parent-page-section'
        actions={editFeeProfileRulesActions}
      >
        {isFetching && <Loader />}
        {!isFetching && !hasNoFeeProfileRules && (
          <>
            {!isEmpty(blendedData) && (
            <PageSectionV2C
              smallTitle={CARD_PAYMENTS}
              subTitle={CARD_PAYMENTS_SUBTITLE}
              badge={BLENDED}
              className='nested-page-section'
            >
              {map(blendedData, (data) => {
                const [
                  feeTypeRows,
                  hasCardBrandOverrides,
                  cardBrandRows,
                  hideChargebackRows,
                  chargebackRows,
                  currency,
                  country,
                ] = getMany(data, [
                  'feeTypeRows',
                  'hasCardBrandOverrides',
                  'cardBrandRows',
                  'hideChargebackRows',
                  'chargebackRows',
                  'currency',
                  'country',
                ])
                return (
                  <>
                    <div className='flex country-header'>
                      <h6>{countryNameByCountryCode[country]}</h6>
                      <CountryFlagIcon country={country} showCountryName={false} />
                    </div>
                    <div className='container-3'>
                      <Header currency={currency} />
                      <ThreeColumnRows rows={feeTypeRows} />
                    </div>
                    {hasCardBrandOverrides && (
                    <>
                      <div className='container-1'>
                        <div className='item sub-header'>{CARD_BRAND_OVERRIDES}</div>
                      </div>
                      <div className='container-3'>
                        <ThreeColumnRows rows={cardBrandRows} />
                      </div>
                    </>
                    )}
                    {!hideChargebackRows && (
                    <>
                      <div className='container-1'>
                        <div className='item sub-header'>{CHARGEBACKS}</div>
                      </div>
                      <div className='container-2'>
                        <TwoColumnRows rows={chargebackRows} />
                      </div>
                    </>
                    )}
                  </>
                )
              })}
            </PageSectionV2C>
            )}
            {!isEmpty(interchangeData) && (
            <PageSectionV2C
              smallTitle={CARD_PAYMENTS}
              subTitle={CARD_PAYMENTS_SUBTITLE}
              badge={INTERCHANGE_PLUS}
              className='nested-page-section'
            >
              {map(interchangeData, (data) => {
                const [
                  hasInterchangeVisaRows,
                  interchangeVisaRows,
                  hasInterchangeMastercardRows,
                  interchangeMastercardRows,
                  hasInterchangeAmexRows,
                  interchangeAmexRows,
                  hasInterchangeDiscoverRows,
                  interchangeDiscoverRows,
                  showPassThroughFeesSection,
                  feeTypeRows,
                  hasCardBrandOverrides,
                  cardBrandRows,
                  hideChargebackRows,
                  chargebackRows,
                  currency,
                  country,
                ] = getMany(data, [
                  'hasInterchangeVisaRows',
                  'interchangeVisaRows',
                  'hasInterchangeMastercardRows',
                  'interchangeMastercardRows',
                  'hasInterchangeAmexRows',
                  'interchangeAmexRows',
                  'hasInterchangeDiscoverRows',
                  'interchangeDiscoverRows',
                  'showPassThroughFeesSection',
                  'feeTypeRows',
                  'hasCardBrandOverrides',
                  'cardBrandRows',
                  'hideChargebackRows',
                  'chargebackRows',
                  'currency',
                  'country',
                ])
                return (
                  <>
                    <div className='flex country-header'>
                      <h6>{countryNameByCountryCode[country]}</h6>
                      <CountryFlagIcon country={country} showCountryName={false} />
                    </div>
                    <div className='container-3'>
                      <Header currency={currency} />
                      <ThreeColumnRows rows={feeTypeRows} />
                    </div>
                    {hasCardBrandOverrides && (
                    <>
                      <div className='container-1'>
                        <div className='item sub-header'>{CARD_BRAND_OVERRIDES}</div>
                      </div>
                      <div className='container-3'>
                        <ThreeColumnRows rows={cardBrandRows} />
                      </div>
                    </>
                    )}
                    {!hideChargebackRows && (
                    <>
                      <div className='container-1'>
                        <div className='item sub-header'>{CHARGEBACKS}</div>
                      </div>
                      <div className='container-2'>
                        <TwoColumnRows rows={chargebackRows} />
                      </div>
                    </>
                    )}
                    {showPassThroughFeesSection && (
                    <>
                      <div className='container-3'>
                        <Header title={PASS_THROUGH_CARD_ASSOCIATION_FEES} currency={currency} />
                      </div>
                      {hasInterchangeVisaRows && (
                      <>
                        <div className='container-1'>
                          <div className='item sub-header'>{VISA_LABEL}</div>
                        </div>
                        <div className='container-3'>
                          <ThreeColumnRows rows={interchangeVisaRows} />
                        </div>
                      </>
                      )}
                      {hasInterchangeMastercardRows && (
                      <>
                        <div className='container-1'>
                          <div className='item sub-header'>{MASTERCARD_LABEL}</div>
                        </div>
                        <div className='container-3'>
                          <ThreeColumnRows rows={interchangeMastercardRows} />
                        </div>
                      </>
                      )}
                      {hasInterchangeAmexRows && (
                      <>
                        <div className='container-1'>
                          <div className='item sub-header'>{AMEX_LABEL}</div>
                        </div>
                        <div className='container-3'>
                          <ThreeColumnRows rows={interchangeAmexRows} />
                        </div>
                      </>
                      )}
                      {hasInterchangeDiscoverRows && (
                      <>
                        <div className='container-1'>
                          <div className='item sub-header'>{DISCOVER_LABEL}</div>
                        </div>
                        <div className='container-3'>
                          <ThreeColumnRows rows={interchangeDiscoverRows} />
                        </div>
                      </>
                      )}
                    </>
                    )}
                  </>
                )
              })
                  }
            </PageSectionV2C>
            )}
            <PageSectionV2C
              smallTitle={ACH_TRANSFERS}
              subTitle={ACH_TRANSFERS_FEE_PROFILE_RULES_SUBTITLE}
              className='nested-page-section'
            >
              {map(achTransfersData, (data) => {
                const [
                  achFeeTypesRows,
                  hideAchReturnsRows,
                  achReturnsRows,
                  country,
                  currency,
                ] = getMany(data, [
                  'achFeeTypesRows',
                  'hideAchReturnsRows',
                  'achReturnsRows',
                  'country',
                  'currency',
                ])
                return (
                  <>
                    <div className='flex country-header'>
                      <h6>{countryNameByCountryCode[country]}</h6>
                      <CountryFlagIcon country={country} showCountryName={false} />
                    </div>
                    <div className='container-3'>
                      <Header currency={currency} />
                      <ThreeColumnRows rows={achFeeTypesRows} />
                    </div>
                    {!hideAchReturnsRows && (
                      <>
                        <div className='container-1'>
                          <div className='item sub-header'>{RETURNS}</div>
                        </div>
                        <div className='container-3'>
                          <ThreeColumnRows rows={achReturnsRows} />
                        </div>
                      </>
                    )}
                  </>
                )
              })
             }
            </PageSectionV2C>
            {!isEmpty(additionalFeesData) && (
            <PageSectionV2C
              smallTitle={ADDITIONAL_FEES}
              subTitle={ADDITIONAL_FEES_FEE_PROFILE_SUBTITLE}
              className='nested-page-section'
            >
              {map(additionalFeesData, (data) => {
                const [
                  additionalFeesRows,
                  country,
                  currency,
                ] = getMany(data, [
                  'additionalFeesRows',
                  'country',
                  'currency',
                ])
                return (
                  <>
                    <div className='flex country-header'>
                      <h6>{countryNameByCountryCode[country]}</h6>
                      <CountryFlagIcon country={country} showCountryName={false} />
                    </div>
                    <div className='container-2'>
                      <Header currency={currency} showPercentageColumn={false} />
                      <TwoColumnRows rows={additionalFeesRows} />
                    </div>
                  </>
                )
              })
             }
            </PageSectionV2C>
            )}
          </>
        )}
      </PageSectionV2C>
    </div>
  )
}

FeeProfileRules.propTypes = {
  isFetching: PropTypes.bool,
  hasNoFeeProfileRules: PropTypes.bool,
  blendedData: PropTypes.array,
  interchangeData: PropTypes.array,
  achTransfersData: PropTypes.array,
  additionalFeesData: PropTypes.array,
  editFeeProfileRulesActions: PropTypes.array,
}

export default FeeProfileRules
