import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANTS,
  MERCHANT_VERIFICATIONS,
} from 'constants/apiConstants'

const postMerchantVerificationAPI = ({ values, credentials, meta }) => {
  const { merchant } = values

  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: MERCHANT_VERIFICATIONS({ merchantId: merchant }),
    service: MERCHANTS,
  })
}

export default postMerchantVerificationAPI
