import map from 'lodash/map'
import get from 'lodash/get'

const BankAccountFields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'bankCode',
    label: 'Routing Number',
    highlight: true,
  },
  {
    name: 'accountNumber',
    label: 'Account Number',
    highlight: true,
  },
  {
    name: 'accountType',
    label: 'Account Type',
  },
]

const BankAccountDisplayData = (values) => map(BankAccountFields, ({ name, label, highlight = false }) => ({ label, value: get(values, name), highlight }))

export default BankAccountDisplayData
