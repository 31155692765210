import './IDChecksS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const IDChecks = ({
  idChecksData = [],
}) => {
  return (
    <div className='IDChecks'>
      <PageSectionV2C
        className='id-checks-data-section no-pad'
        columns={idChecksData}
      />
    </div>
  )
}

IDChecks.propTypes = {
  idChecksData: PropTypes.array,
}

export default IDChecks
