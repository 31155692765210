import isPlainObject from 'lodash/isPlainObject'
import reduce from 'lodash/reduce'
import omit from 'lodash/omit'

const removePristineValues = ({ values = {}, initialValues = {}, keysToIgnore = [] }) => {
  const updatedInitialValues = omit(initialValues, keysToIgnore)
  return reduce(values, (total, value, key) => {
    if (value !== updatedInitialValues[key]) {
      if (isPlainObject(value)) {
        return { ...total, [key]: removePristineValues({ values: value, initialValues: updatedInitialValues[key] }) }
      }
      return { ...total, [key]: value }
    }
    return total
  }, {})
}

export default removePristineValues
