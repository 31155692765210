import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MEMBER } from 'constants/apiConstants'

const getMemberAPI = ({ credentials, id, meta }) => {
  return dashboardAPI.get({
    credentials,
    path: DASHBOARD_SERVICE_MEMBER({ memberId: id }),
    meta,
  })
}

export default getMemberAPI
