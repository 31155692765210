import { fieldEmpty } from 'utilities/validate'
import getMany from 'utilities/get/getMany'

const validateMerchantOnboardingWelcomeForm = (values) => {
  const [
    country,
    businessType,
  ] = getMany(values, [
    'country',
    'businessData.businessType',
  ])

  return {
    businessData: {
      businessType: fieldEmpty(businessType, 'Business Type'),
    },
    country: fieldEmpty(country, 'Country'),
  }
}

export default validateMerchantOnboardingWelcomeForm
