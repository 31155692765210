import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_UNDERWRITING_SETTINGS,
} from 'constants/apiConstants'

const patchApplicationUnderwritingSettings = ({ id, values, credentials, meta }) => {
  return paymentsAPI.patch({
    meta,
    credentials,
    path: APPLICATION_UNDERWRITING_SETTINGS({ applicationId: id }),
    values,
    service: APPLICATIONS,
  })
}

export default patchApplicationUnderwritingSettings
