import createAction from 'utilities/actions/createAction'
import { GET_FILE_F_REQUEST } from 'constants/flowConstants'

const getFileRequest = ({
  id,
  credentials,
  meta,
}) => createAction({
  type: GET_FILE_F_REQUEST,
  id,
  credentials,
  meta,
})

export default getFileRequest
