import Components from './Components'
import { components } from './componentsArray'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DASHBOARD_PATH } from 'constants/pathConstants'
import isAdministrator from 'utilities/is/isAdministrator'
import groupBy from 'lodash/groupBy'

const mapStateToProps = (state) => {
  const isAdmin = isAdministrator(state)

  const initialValues = {
    color: [
      { value: 'orange', label: 'Orange', color: '#FF8B00' },
      { value: 'green', label: 'Green', color: '#36B37E' },
    ],
  }

  return {
    isAdmin,
    redirectPath: DASHBOARD_PATH,
    groupedComponents: groupBy(components, 'category'),
    initialValues,
  }
}

class ComponentsC extends Component {
  render () {
    return (
      <Components
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ComponentsC)
