import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisableCustomRole from './DisableCustomRole'
import DisableCustomRoleError from './DisableCustomRoleError'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchCustomRoleRequest from 'utilities/actions/patch/patchCustomRoleRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { DISABLE_CUSTOM_ROLE } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const credentialName = get(credentials, 'name')
  const roleId = get(props, 'roleId')
  const roleName = get(props, 'roleName')
  const roleHasMembersError = get(state, 'errorsR.errors.0.status') === 409

  return {
    credentials,
    roleId,
    roleName,
    roleHasMembersError,
    credentialId,
    credentialName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    disableCustomRoleAction: ({ credentials, roleId, roleName }) => dispatch(patchCustomRoleRequest({
      credentials,
      roleId,
      roleName,
      amplitudeActionType: DISABLE_CUSTOM_ROLE,
      values: {
        enabled: false,
      },
      showErrors: false,
      hideFlashNotification: true,
      successCallback: () => {
        dispatch(hideModalAction())
      },
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class DisableCustomRoleC extends Component {
  disableCustomRole = () => {
    const {
      credentials,
      roleId,
      roleName,
      disableCustomRoleAction,
    } = this.props

    disableCustomRoleAction({ credentials, roleId, roleName })
  }

  render() {
    const { roleHasMembersError, closeModal } = this.props

    if (roleHasMembersError) {
      return (
        <DisableCustomRoleError
          {...this.props}
          closeModal={closeModal}
        />
      )
    }
    return (
      <DisableCustomRole
        {...this.props}
        disableCustomRole={this.disableCustomRole}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisableCustomRoleC)
