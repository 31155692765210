import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { getDashboardConfigByEntityTypeAndId, getFormValues } from 'state-layer/selectors/index'
import PaymentLinkTemplatesForm from './PaymentLinkTemplatesForm'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { PAYMENT_LINK_TEMPLATE_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

import {
  LINK_SETTINGS,
  MULTI_USE_LINK_SETTINGS,
  ONE_TIME_LINK_SETTINGS,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const {
    entityType,
    entityId,
    isPayoutLink,
  } = props

  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, false)
  const customSettings = entityType && entityId ? getDashboardConfigByEntityTypeAndId(state, entityType, entityId) : get(currentUser, 'customSettings')

  const initialValues = !isFetchingConfiguration ? {
    paymentLinkTemplate: {
      termsOfServiceUrl: get(customSettings, 'paymentLinkTemplate.termsOfServiceUrl'),
      successReturnUrl: get(customSettings, 'paymentLinkTemplate.successReturnUrl'),
      unsuccessfulReturnUrl: get(customSettings, 'paymentLinkTemplate.unsuccessfulReturnUrl'),
      expiredSessionUrl: get(customSettings, 'paymentLinkTemplate.expiredSessionUrl'),
      sendReceipt: get(customSettings, 'paymentLinkTemplate.sendReceipt', true),
      sendReceiptToSelf: get(customSettings, 'paymentLinkTemplate.sendReceiptToSelf', false),
      oneTime: {
        amountType: 'FIXED',
        allowPaymentCard: true,
        allowBankTransfer: false,
        expiresIn: '168',
        collectBuyerDetails: 'email',
        ...get(customSettings, 'paymentLinkTemplate.oneTime', {}),
      },
      multiUse: {
        amountType: 'FIXED',
        allowPaymentCard: true,
        allowBankTransfer: false,
        expiresIn: '168',
        collectBuyerDetails: 'email',
        ...get(customSettings, 'paymentLinkTemplate.multiUse', {}),
      },
    },
  } : undefined

  const [
    collectBuyerDetailsOneTime,
    collectBuyerDetailsMultiUse,
    collectCustomFieldsOneTime = false,
    collectCustomFieldsMultiUse = false,
    expiresInOneTime,
    expiresInMultiUse,
  ] = getFormValues(state, PAYMENT_LINK_TEMPLATE_FORM, [
    'paymentLinkTemplate.oneTime.collectBuyerDetails',
    'paymentLinkTemplate.multiUse.collectBuyerDetails',
    'paymentLinkTemplate.oneTime.collectCustomFields',
    'paymentLinkTemplate.multiUse.collectCustomFields',
    'paymentLinkTemplate.oneTime.expiresIn',
    'paymentLinkTemplate.multiUse.expiresIn',
  ])

  const settingsSections = isPayoutLink ? [
    {
      title: LINK_SETTINGS,
      fieldKey: 'oneTime',
      collectMoreBuyerDetails: collectBuyerDetailsOneTime === 'more',
      collectCustomFields: collectCustomFieldsOneTime,
      showExpirationDatePicker: expiresInOneTime === 'custom',
    },
  ] : [
    {
      title: ONE_TIME_LINK_SETTINGS,
      fieldKey: 'oneTime',
      collectMoreBuyerDetails: collectBuyerDetailsOneTime === 'more',
      collectCustomFields: collectCustomFieldsOneTime,
      showExpirationDatePicker: expiresInOneTime === 'custom',
    },
    {
      title: MULTI_USE_LINK_SETTINGS,
      fieldKey: 'multiUse',
      collectMoreBuyerDetails: collectBuyerDetailsMultiUse === 'more',
      collectCustomFields: collectCustomFieldsMultiUse,
      showExpirationDatePicker: expiresInMultiUse === 'custom',
    },
  ]

  return removeUndefined({
    id: get(customSettings, 'id'),
    credentials,
    customSettings,
    isFetchingCurrentUser,
    entityId,
    entityType,
    isPayoutLink,
    settingsSections,
    initialValues,
    isFetchingConfiguration,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    resetForm: () => dispatch(reset(PAYMENT_LINK_TEMPLATE_FORM)),
  }
}

class PaymentLinkTemplatesFormC extends Component {
  componentDidMount() {
    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingCurrentUser: prevIsFetchingCurrentUser,
    } = prevProps

    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && prevIsFetchingCurrentUser && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  render() {
    const { resetForm } = this.props

    return (
      <PaymentLinkTemplatesForm
        {...this.props}
        getDashboardCustomization={this.getDashboardCustomization}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        resetForm={resetForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkTemplatesFormC)
