import './CreateTransactionFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import Button from 'components/Shared/Button/Button'
import Skeleton from 'components/Shared/Skeleton/Skeleton'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import LevelThreeItems from './LevelThreeItems'
import submitCreateTransactionForm from 'utilities/submit/submitCreateTransactionForm'
import validateCreateTransactionForm from 'utilities/validate/validateCreateTransactionForm'
import { USD } from 'constants/currencyConstants'
import { CREATE_TRANSACTION_FORM } from 'constants/formConstants'
import { goBack } from 'state-layer/history'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  VIRTUAL_TERMINAL_DOCS_LINK,
  VIRTUAL_TERMINAL_L2_L3_LEAR_MORE_LINK,
} from 'constants/urlConstants'

import {
  L2_ENABLED,
  L3_ENABLED,
  ADDITIONAL_PROCESSOR_DATA_OPTIONS,
} from 'constants/processorConstants'

import {
  t,
  APPLICATION,
  BUYER_NAME,
  BUYER_NAME_PLACEHOLDER,
  BUYER_NAME_TOOLTIP,
  CANCEL,
  CREATE_A_PAYMENT_SUBTITLE,
  CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP,
  DESCRIPTION,
  LEARN_MORE_LABEL,
  MERCHANT_ACCOUNT,
  SELECT_MERCHANT_ACCOUNT_PLACEHOLDER,
  MERCHANT_ACCOUNT_TOOLTIP,
  PAYMENT_DESCRIPTION_PLACEHOLDER,
  PAYMENT_DESCRIPTION_TOOLTIP,
  PAYMENT_METHOD,
  PAYMENT_METHOD_PLACEHOLDER,
  TOTAL_AMOUNT_SUBTITLE,
  TOTAL_PURCHASE_AMOUNT,
  SALES_TAX_AMOUNT,
  CUSTOMER_REFERENCE_NUMBER,
  SALES_TAX_AMOUNT_TOOLTIP_MSG,
  CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG,
  L2_CHECKBOX_TOOLTIP_MSG,
  ADDITIONAL_DATA,
  VIRTUAL_TERMINAL_ADDITIONAL_DATA_SUBTITLE,
  CUSTOMER_INFORMATION,
  SHIPPING_INFORMATION,
  DESTINATION_POSTAL_CODE,
  SHIP_FROM_POSTAL_CODE,
  SHIPPING_AMOUNT,
  SHIPPING_AMOUNT_TOOLTIP_MSG,
  OTHER_INFORMATION,
  TOTAL_DISCOUNT_AMOUNT,
  TOTAL_CUSTOMS_DUTY_AMOUNT,
  TOTAL_DISCOUNT_AMOUNT_TOOLTIP_MSG,
  TOTAL_CUSTOMS_DUTY_AMOUNT_TOOLTIP_MSG,
  CREATE_A_TRANSACTION,
  SALE,
  AUTHORIZATION,
  REVIEW_TRANSACTION,
  SELECT_A_CARD,
  VIRTUAL_TERMINAL,
  TRANSACTION_DETAILS,
  PAYMENT_DETAILS,
} from 'constants/language/languageConstants'

const CreateTransactionForm = ({
  handleSubmit = () => {},
  isStandaloneMerchant = false,
  isRolePlatformCredential = false,
  merchantAccounts = [],
  applicationNames = [],
  isFetchingDashboardConfig = false,
  sortedIdentitiesNames = [],
  paymentInstrumentOptions = [],
  paymentInstrumentOptionsWithBankAccounts = [],
  invalid = false,
  reactSelectCustomStyles = {},
  merchantLevelTwoLevelThreeDataEnabled = false,
  additionalDataSelection = '',
  isCardMethod = false,
  levelThreeItemsValue = [],
  setIsSale = () => {},
  isSale = true,
  canUseACHPayment = false,
  currency = USD,
  isFetchingDevices = false,
  hasDevices = false,
  deviceOptions = [],
  sendMethod = '',
  setSendMethod = () => {},
  selectedDevice,
}) => {
  const sendMethodManual = sendMethod === 'manual'
  const customOptionStyles = get(reactSelectCustomStyles, 'customOption', {})
  const optionStyles = get(reactSelectCustomStyles, 'option', {})
  const deviceNotSelected = sendMethod === 'device' && !selectedDevice

  return (
    <form className='CreateTransactionForm' onSubmit={handleSubmit(submitCreateTransactionForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{t(VIRTUAL_TERMINAL)}</h3>
        <div className='p-1'>{t(CREATE_A_PAYMENT_SUBTITLE)} <a className='text-link' href={VIRTUAL_TERMINAL_DOCS_LINK} target='blank'>{t(LEARN_MORE_LABEL)}</a></div>
      </div>

      <div className='form-content'>
        <div className='link-type-buttons flex'>
          <div className={`one-time-link ${isSale ? 'active' : 'inactive'}`} onClick={() => setIsSale('sale')}>
            <span className='flex items-center'>
              <RadioButton
                name='sale-type'
                options={[{ value: 'sale', label: SALE }]}
                input={{ value: isSale ? 'sale' : 'authorization', name: 'send-type' }}
              />
            </span>
          </div>
          <div className={`multi-use-link ${isSale ? 'inactive' : 'active'}`} onClick={() => setIsSale('authorization')}>
            <span className='flex items-center'>
              <RadioButton
                name='sale-type'
                options={[{ value: 'authorization', label: AUTHORIZATION }]}
                input={{ value: isSale ? 'sale' : 'authorization', name: 'send-type' }}
              />
            </span>
          </div>
        </div>

        <h5>{t(TRANSACTION_DETAILS)}</h5>

        <AmountField
          name='amount'
          label={TOTAL_PURCHASE_AMOUNT}
          placeholder='0.00'
          helperText={TOTAL_AMOUNT_SUBTITLE}
          contextLabel={currency}
          currency={currency}
        />

        <Field
          name='description'
          label={DESCRIPTION}
          component={InputField}
          tooltip={PAYMENT_DESCRIPTION_TOOLTIP}
          tooltipPosition='right'
          placeholder={PAYMENT_DESCRIPTION_PLACEHOLDER}
          required={false}
        />

        <div className='payment-details'>
          <h5>{t(PAYMENT_DETAILS)}</h5>

          { isFetchingDevices && (
            <div className='payment-details-skeleton flex column gap-8'>
              <Skeleton height='22px' />
              <Skeleton height='22px' />
              <Skeleton height='22px' />
            </div>
          )}

          { !isFetchingDevices && !hasDevices && (
            <>
              { isRolePlatformCredential && (
                <Field
                  name='applicationId'
                  label={APPLICATION}
                  component={ReactSelect}
                  options={applicationNames}
                  tooltip={CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP}
                />
              )}

              { (isFetchingDashboardConfig && !isStandaloneMerchant) && (
                <Field
                  name='merchantAccount'
                  label={MERCHANT_ACCOUNT}
                  component={ReactSelect}
                  options={merchantAccounts}
                  tooltip={MERCHANT_ACCOUNT_TOOLTIP}
                  tooltipPosition='right'
                  placeholder={SELECT_MERCHANT_ACCOUNT_PLACEHOLDER}
                />
              )}

              <Field
                name='buyerName'
                label={BUYER_NAME}
                component={ReactSelect}
                options={sortedIdentitiesNames}
                tooltip={BUYER_NAME_TOOLTIP}
                tooltipPosition='right'
                placeholder={BUYER_NAME_PLACEHOLDER}
                maxMenuHeight={200}
                required={false}
                customStyles={{
                  option: (styles, state) => (state.data.value === 'newBuyer' ? customOptionStyles : optionStyles),
                }}
              />

              <Field
                name='paymentMethod'
                label={PAYMENT_METHOD}
                component={ReactSelect}
                options={canUseACHPayment ? paymentInstrumentOptionsWithBankAccounts : paymentInstrumentOptions}
                placeholder={canUseACHPayment ? PAYMENT_METHOD_PLACEHOLDER : SELECT_A_CARD}
                maxMenuHeight={150}
                customStyles={{
                  option: (styles, state) => (state.data.value === 'newPaymentMethod' ? customOptionStyles : optionStyles),
                }}
              />
            </>
          )}

          { !isFetchingDevices && hasDevices && (
            <div className='send-method-buttons flex'>
              <div className={`one-time-link ${sendMethodManual ? 'active' : 'inactive'}`} onClick={() => setSendMethod('manual')}>
                <span className='flex items-center'>
                  <RadioButton
                    options={[{ value: 'manual', label: 'Manually enter payment method' }]}
                    input={{ value: sendMethodManual ? 'manual' : 'device', name: 'send-method' }}
                  />
                </span>

                { sendMethodManual && (
                  <>
                    { isRolePlatformCredential && (
                      <Field
                        name='applicationId'
                        label={APPLICATION}
                        component={ReactSelect}
                        options={applicationNames}
                        tooltip={CREATE_PAYMENT_APPLICATION_FIELD_TOOLTIP}
                      />
                    )}

                    { (isFetchingDashboardConfig && !isStandaloneMerchant) && (
                      <Field
                        name='merchantAccount'
                        label={MERCHANT_ACCOUNT}
                        component={ReactSelect}
                        options={merchantAccounts}
                        tooltip={MERCHANT_ACCOUNT_TOOLTIP}
                        tooltipPosition='right'
                        placeholder={SELECT_MERCHANT_ACCOUNT_PLACEHOLDER}
                      />
                    )}

                    <Field
                      name='buyerName'
                      label={BUYER_NAME}
                      component={ReactSelect}
                      options={sortedIdentitiesNames}
                      tooltip={BUYER_NAME_TOOLTIP}
                      tooltipPosition='right'
                      placeholder={BUYER_NAME_PLACEHOLDER}
                      maxMenuHeight={200}
                      required={false}
                      customStyles={{
                        option: (styles, state) => (state.data.value === 'newBuyer' ? customOptionStyles : optionStyles),
                      }}
                    />

                    <Field
                      name='paymentMethod'
                      label={PAYMENT_METHOD}
                      component={ReactSelect}
                      options={canUseACHPayment ? paymentInstrumentOptionsWithBankAccounts : paymentInstrumentOptions}
                      placeholder={canUseACHPayment ? PAYMENT_METHOD_PLACEHOLDER : SELECT_A_CARD}
                      maxMenuHeight={150}
                      customStyles={{
                        option: (styles, state) => (state.data.value === 'newPaymentMethod' ? customOptionStyles : optionStyles),
                      }}
                    />
                  </>
                )}
              </div>

              <div className={`multi-use-link ${sendMethodManual ? 'inactive' : 'active'}`} onClick={() => setSendMethod('device')}>
                <span className='flex items-center'>
                  <RadioButton
                    name='send-method'
                    options={[{ value: 'device', label: 'Send to a payment device' }]}
                    input={{ value: sendMethodManual ? 'manual' : 'device', name: 'send-method' }}
                  />
                </span>

                { !sendMethodManual && (
                  <Field
                    name='device'
                    label='Device'
                    component={ReactSelect}
                    options={deviceOptions}
                    customStyles={{
                      option: {
                        textAlign: 'left',
                      },
                      menu: {
                        position: 'relative',
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        { merchantLevelTwoLevelThreeDataEnabled && isCardMethod && (
          <div className='additional-data-section'>
            <h5>{ADDITIONAL_DATA}</h5>
            <div className='additional-data-subtitle'>
              {VIRTUAL_TERMINAL_ADDITIONAL_DATA_SUBTITLE}
              <a className='text-link learn-more-link' href={VIRTUAL_TERMINAL_L2_L3_LEAR_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
            </div>

            <Field
              name='additionalDataSelection'
              className='additional-data-select'
              label={ADDITIONAL_DATA}
              component={ReactSelect}
              options={ADDITIONAL_PROCESSOR_DATA_OPTIONS}
              required={false}
            />

            { isEqual(additionalDataSelection, L2_ENABLED) && (
            <div className='level-two-enabled-fields-section'>
              {/* TODO: instead of just hardcoding USD, look into supporting different country currencies when possible */}
              <AmountField
                name='salesTaxAmount'
                className='sales-tax-field'
                label={SALES_TAX_AMOUNT}
                component={InputField}
                contextLabel={USD}
                tooltip={SALES_TAX_AMOUNT_TOOLTIP_MSG}
                tooltipPosition='right'
              />

              <Field
                name='customerReferenceNumber'
                className='customer-ref-number-field'
                label={CUSTOMER_REFERENCE_NUMBER}
                component={InputField}
                tooltip={CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG}
                tooltipPosition='right'
                placeholder='123456'
              />
            </div>
            )}

            {isEqual(additionalDataSelection, L3_ENABLED) && (
            <div className='level-three-enabled-fields-section'>
              <h6 className='customer-info-heading'>{CUSTOMER_INFORMATION}</h6>

              <Field
                name='customerReferenceNumber'
                className='customer-ref-number-field'
                label={CUSTOMER_REFERENCE_NUMBER}
                component={InputField}
                tooltip={CUSTOMER_REFERENCE_NUMBER_TOOLTIP_MSG}
                tooltipPosition='right'
                placeholder='123456'
              />

              <h6 className='shipping-info-heading'>{SHIPPING_INFORMATION}</h6>

              <Field
                name='destinationPostalCode'
                className='destination-postal-code-field'
                label={DESTINATION_POSTAL_CODE}
                component={InputField}
                placeholder='12345'
                required={false}
              />

              <Field
                name='shipFromPostalCode'
                className='ship-from-postal-code-field'
                label={SHIP_FROM_POSTAL_CODE}
                component={InputField}
                placeholder='12345'
                required={false}
              />

              <AmountField
                name='shippingAmount'
                className='shipping-amount-field'
                label={SHIPPING_AMOUNT}
                component={InputField}
                contextLabel={USD}
                tooltip={SHIPPING_AMOUNT_TOOLTIP_MSG}
                tooltipPosition='right'
              />

              <div className='items'>
                <FieldArray
                  name='levelThreeItems'
                  component={LevelThreeItems}
                  levelThreeItems={levelThreeItemsValue}
                />
              </div>

              <h6 className='other-info-heading'>{OTHER_INFORMATION}</h6>

              <AmountField
                name='totalDiscountAmount'
                className='total-discount-amount-field'
                label={TOTAL_DISCOUNT_AMOUNT}
                component={InputField}
                contextLabel={USD}
                tooltip={TOTAL_DISCOUNT_AMOUNT_TOOLTIP_MSG}
                tooltipPosition='right'
              />

              <AmountField
                name='totalCustomsDutyAmount'
                className='total-customs-duty-amount-field'
                label={TOTAL_CUSTOMS_DUTY_AMOUNT}
                component={InputField}
                contextLabel={USD}
                tooltip={TOTAL_CUSTOMS_DUTY_AMOUNT_TOOLTIP_MSG}
                tooltipPosition='right'
              />
            </div>
            )}
          </div>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='exit-and-save-container'>
            <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button type='submit' disabled={invalid || deviceNotSelected} label={REVIEW_TRANSACTION} className='submit-button' />
          </div>
        </div>
      </div>
    </form>
  )
}

CreateTransactionForm.propTypes = {
  handleSubmit: PropTypes.func,
  isStandaloneMerchant: PropTypes.bool,
  isRolePlatformCredential: PropTypes.bool,
  merchantAccounts: PropTypes.array,
  applicationNames: PropTypes.array,
  isFetchingDashboardConfig: PropTypes.bool,
  sortedIdentitiesNames: PropTypes.array,
  paymentInstrumentOptions: PropTypes.array,
  paymentInstrumentOptionsWithBankAccounts: PropTypes.array,
  invalid: PropTypes.bool,
  reactSelectCustomStyles: PropTypes.object,
  merchantLevelTwoLevelThreeDataEnabled: PropTypes.bool,
  additionalDataSelection: PropTypes.string,
  isCardMethod: PropTypes.bool,
  levelThreeItemsValue: PropTypes.array,
  setIsSale: PropTypes.func,
  isSale: PropTypes.bool,
  canUseACHPayment: PropTypes.bool,
  currency: PropTypes.string,
  isFetchingDevices: PropTypes.bool,
  hasDevices: PropTypes.bool,
  deviceOptions: PropTypes.array,
  sendMethod: PropTypes.string,
  setSendMethod: PropTypes.func,
  selectedDevice: PropTypes.string,
}

export default reduxForm({
  form: CREATE_TRANSACTION_FORM,
  validate: validateCreateTransactionForm,
})(CreateTransactionForm)
