import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerOnboardingFormWelcomeForm from './CustomerOnboardingFormWelcomeForm'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetchingAccessForm = get(state, `accessFormsR.${FETCHING}`)
  const accessFormId = get(props, 'accessFormId')
  const accessFormCountry = get(props, 'accessFormCountry')

  const initialValues = isFetchingAccessForm ? undefined : {
    country: accessFormCountry,
  }

  return {
    accessFormId,
    accessFormCountry,
    initialValues,
  }
}

class CustomerOnboardingFormWelcomeFormC extends Component {
  render() {
    return (
      <CustomerOnboardingFormWelcomeForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(CustomerOnboardingFormWelcomeFormC)
