import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import hideModalAction from 'utilities/actions/hideModalAction'
import { SEND_EMAIL } from 'constants/amplitudeConstants'

import {
  POST_USERS_EMAIL_F_REQUEST,
  POST_DASHBOARD_V2_EMAILS_F_REQUEST,
} from 'constants/flowConstants'

const submitSendEmailForm = (values, dispatch, props) => {
  const {
    id,
    email = '',
    credentials,
    applicationId = '',
    isFlex = false,
  } = props

  const { template } = values
  const normalizedEmail = email.toLowerCase()

  const valuesToSubmit = isFlex ? {
    email_recipient: normalizedEmail,
    entity_id: applicationId,
    email_template: 'PAYMENTS_DASHBOARD_WELCOME_EMAIL',
  } : {
    recipient_email: normalizedEmail,
    email_template_name: `${getCurrentPlatformName()}-${template}`, // <platformName>-welcome-email-password
    email_template_data: {
      url: 'www.finixpayments.com', // BE is not using this data
    },
  }

  const actionType = isFlex ? POST_DASHBOARD_V2_EMAILS_F_REQUEST : POST_USERS_EMAIL_F_REQUEST

  dispatch({
    type: actionType,
    payload: {
      id,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SEND_EMAIL, {
        userId: id,
        template,
        credentials,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
    },
  })
}

export default submitSendEmailForm
