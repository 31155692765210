import {
  PATCH_REVIEW_QUEUE_ITEM_F_REQUEST,
} from 'constants/flowConstants'

import createAction from 'utilities/actions/createAction'
import { REVIEW_QUEUE } from 'constants/linkConstants'

const patchReviewQueueItemRequest = ({
  items,
  credentials,
  meta,
}) => createAction({
  type: PATCH_REVIEW_QUEUE_ITEM_F_REQUEST,
  values: items,
  linksKey: REVIEW_QUEUE,
  credentials,
  meta,
})

export default patchReviewQueueItemRequest
