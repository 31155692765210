import frontendProcessorModel from 'utilities/create/models/frontendProcessorModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendProcessorsModel = ({ data: processors }) => {
  const processorsModels = map(processors, (processor) => frontendProcessorModel({ data: processor }))

  return keyBy(processorsModels, 'id')
}

export default frontendProcessorsModel
