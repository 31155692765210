import paymentsAPI from 'utilities/api/paymentsAPI'
import { AUDIT_LOG_EVENT } from 'constants/apiConstants'

const getAuditLogEventAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: AUDIT_LOG_EVENT({ eventId: id }),
    credentials,
  })
}

export default getAuditLogEventAPI
