import React from 'react'
import { goToPath } from 'state-layer/history'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { disputeStatusMap } from 'constants/statusConstants'
import { LONG_ARROW_RIGHT } from 'constants/iconConstants'
import { SMS } from 'constants/receiptConstants'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import includes from 'lodash/includes'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

import {
  PAYMENT_PATH,
  SPLIT_PAYMENT_PATH,
  REFUND_PATH,
  SPLIT_REFUND_PATH,
} from 'constants/pathConstants'

import {
  ID,
  CREATED_ON,
  TYPE,
  MERCHANT,
  APPLICATION,
  STATE,
  REFUND_AMOUNT,
  DISPUTE_AMOUNT,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  AMOUNT,
  PARENT_PAYMENT,
  SPLIT_PAYMENT,
  PARENT_REFUND,
  SPLIT_REFUND,
  REFUND,
  PRIMARY_MERCHANT,
  BUSINESS_RECIPIENT,
  PERSONAL_RECIPIENT,
  ROLE,
  SENT_TO,
  DELIVERY_METHOD,
  DATE,
} from 'constants/language/languageConstants'

export const refundsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(STATE, 'state', {
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column(REFUND_AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
]

export const splitParentPaymentRefundsColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(TYPE, 'type', {
    formatter: ({ id, credentials, splitTransferIds = [] }) => (isEmpty(splitTransferIds) ? (
      <div
        className='regular-refund-redirect'
        onClick={() => goToPath({ pathname: REFUND_PATH({ credentialId: get(credentials, 'id'), transferId: id }) })}
      >
        {REFUND}
      </div>
    ) : (
      <div
        className='parent-refund-redirect'
        onClick={() => goToPath({ pathname: REFUND_PATH({ credentialId: get(credentials, 'id'), transferId: id }) })}
      >
        {PARENT_REFUND}
      </div>
    )),
  }),
  column(MERCHANT, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(STATE, 'state', {
    formatter: ({ state }) => <TransferStatus value={state} />,
  }),
  column(REFUND_AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
]

export const splitRefundsColumnDescriptors = ({ isParent = true, rowId = '' }) => {
  return [
    column(ID, 'id', {
      className: 'copy-click-id',
      formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
    }),
    column(CREATED_ON, 'createdAt', {
      className: 'date',
      sort: createdAtRange,
      headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
      formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
    }),
    column(TYPE, 'type', {
      className: 'small',
      formatter: ({
        id,
        credentials,
        parentTransferId,
        splitTransferIds = [],
      }) => (!isEmpty(splitTransferIds) ? (
        <div
          className='parent-refund-redirect'
          onClick={isParent ? () => {} :
            () => goToPath({
              pathname: REFUND_PATH({
                credentialId: get(credentials, 'id'),
                transferId: id,
              }),
            })}
        >
          {PARENT_REFUND}
        </div>
      ) : (
        <div
          className={isEqual(id, rowId) ? '' : 'split-refund-redirect'}
          onClick={isEqual(id, rowId) ? () => {} :
            () => goToPath({
              pathname: SPLIT_REFUND_PATH({
                credentialId: get(credentials, 'id'),
                transferId: parentTransferId,
                splitRefundId: id,
              }),
            })}
        >
          <div className='split-arrow'><i className={`fal fa-${LONG_ARROW_RIGHT}`} />{SPLIT_REFUND}</div>
        </div>
      )),
    }),
    column(MERCHANT, 'merchantIdentity.displayBusinessName', {
      className: 'large',
      headerFormatter: () => <TableItemSubtitle title={MERCHANT} subtitle={APPLICATION} />,
      formatter: (refund) => <TransferMerchantApplicationColumn transfer={refund} />,
      condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
    }),
    column(ROLE, 'identity.identityRole', {
      className: 'medium',
      formatter: ({ identity, splitTransferIds }) => {
        const identityRoles = get(identity, 'identityRoles', [])
        let role = PRIMARY_MERCHANT

        if (includes(identityRoles, 'RECIPIENT')) {
          if (get(identity, 'businessName')) {
            role = BUSINESS_RECIPIENT
          } else {
            role = PERSONAL_RECIPIENT
          }
        }

        if (includes(identityRoles, 'SELLER')) {
          role = MERCHANT
        }

        if (!isEmpty(splitTransferIds)) {
          role = PRIMARY_MERCHANT
        }

        return role
      },
    }),
    column(REFUND_AMOUNT, ['displayAmount', 'currency'], {
      className: 'amount',
      formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    }),
  ]
}

export const disputesColumnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT} subtitle={APPLICATION} />,
    formatter: (dispute) => <TransferMerchantApplicationColumn transfer={dispute} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(STATE, 'state', {
    formatter: ({ state }) => <ColorcodedStatus value={state} data={disputeStatusMap} />,
  }),
  column(DISPUTE_AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
  }),
]

export const splitTransfersColumnDescriptors = ({ isParent = true, rowId = '' }) => {
  return [
    column(ID, 'id', {
      className: 'copy-click-id',
      formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
    }),
    column(CREATED_ON, 'createdAt', {
      className: 'date',
      sort: createdAtRange,
      headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
      formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
    }),
    column(TYPE, 'small', {
      formatter: ({ splitTransferIds = [], credentials, id = '', parentTransferId }) => (!isEmpty(splitTransferIds) ? (
        <div
          className='parent-payment-redirect'
          onClick={isParent ? () => {} : () => goToPath({ pathname: PAYMENT_PATH({ credentialId: get(credentials, 'id'), transferId: id }) })}
        >
          {PARENT_PAYMENT }
        </div>
      ) : (
        <div
          className={isEqual(id, rowId) ? '' : 'split-payment-redirect'}
          onClick={isEqual(id, rowId) ? () => {} : () => goToPath({ pathname: SPLIT_PAYMENT_PATH({ credentialId: get(credentials, 'id'), transferId: parentTransferId, splitTransferId: id }) })}
        >
          <div className='split-arrow'><i className={`fal fa-${LONG_ARROW_RIGHT}`} />{SPLIT_PAYMENT}</div>
        </div>
      )
      ),
    }),
    column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'identity.displayBusinessName', {
      className: 'x-large',
      headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
      formatter: (transfer) => {
        const subTitle = get(transfer, 'application.businessName')
        const title = get(transfer, 'merchantIdentity.businessName') || get(transfer, 'identity.businessName') || get(transfer, 'identity.fullName')
        return <TableItemSubtitle title={title} subtitle={subTitle} />
      },
      condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
    }),
    column(ROLE, 'identity.identityRole', {
      className: 'medium',
      formatter: ({ identity, splitTransferIds }) => {
        const identityRoles = get(identity, 'identityRoles', [])
        let role = PRIMARY_MERCHANT

        if (includes(identityRoles, 'RECIPIENT')) {
          if (get(identity, 'businessName')) {
            role = BUSINESS_RECIPIENT
          } else {
            role = PERSONAL_RECIPIENT
          }
        }

        if (includes(identityRoles, 'SELLER')) {
          role = MERCHANT
        }

        if (!isEmpty(splitTransferIds)) {
          role = PRIMARY_MERCHANT
        }

        return role
      },
    }),
    column(AMOUNT, ['displayAmount', 'currency'], {
      className: 'amount',
      formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    }),
  ]
}

export const receiptDeliveryAttemptsColumnDescriptors = [
  column(DATE, 'createdAt', {
    className: 'col-33',
    headerFormatter: () => <DateTimestampHeader title={DATE} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(SENT_TO, 'destination', {
    className: 'col-33',
  }),
  column(DELIVERY_METHOD, 'type', {
    className: 'col-33',
    formatter: ({ type }) => {
      if (type === SMS) {
        return type
      }
      return startCase(toLower(type))
    },
  }),
]
