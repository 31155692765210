import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISPUTE_ACCEPT } from 'constants/apiConstants'

const postAcceptDisputeAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: DISPUTE_ACCEPT({ disputeId: id }),
  })
}

export default postAcceptDisputeAPI
