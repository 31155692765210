import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitContactSupportForm from 'utilities/submit/submitContactSupportForm'
import validateContactSupportForm from 'utilities/validate/validateContactSupportForm'
import { CONTACT_SUPPORT_FORM } from 'constants/formConstants'
import { CONTACT_SUPPORT_MESSAGE } from 'constants/language/languageConstants'

const ContactSupportForm = ({
  handleSubmit = () => {},
  currentUserEmail = '',
  invalid = false,
}) => {
  return (
    <form className='ContactSupportForm' onSubmit={handleSubmit(submitContactSupportForm)}>
      <div>{CONTACT_SUPPORT_MESSAGE}</div>

      <Field
        name='subject'
        label='Subject'
        component={InputField}
      />

      <Field
        name='message'
        label='Message'
        component={TextAreaField}
      />

      {currentUserEmail && <div className='confirmation-message'>A confirmation email will be sent to {currentUserEmail}</div>}

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={invalid} label='Send' />
      </div>
    </form>
  )
}

ContactSupportForm.propTypes = {
  handleSubmit: PropTypes.func,
  currentUserEmail: PropTypes.string,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: CONTACT_SUPPORT_FORM,
  validate: validateContactSupportForm,
})(ContactSupportForm)
