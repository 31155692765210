import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ConfirmationFormC from 'components/Customer/Forms/ConfirmationForm/ConfirmationFormC'

const ConfirmationModal = ({
  message,
  description,
  confirmationAction,
  redirectPath,
  customButtonGroup,
  Component = ConfirmationFormC,
}) => {
  return (
    <GenericModal
      className='ConfirmationModal'
      Component={Component}
      message={message}
      description={description}
      confirmationAction={confirmationAction}
      customButtonGroup={customButtonGroup}
      redirectPath={redirectPath}
    />
  )
}

ConfirmationModal.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  confirmationAction: PropTypes.func,
  redirectPath: PropTypes.string,
  customButtonGroup: PropTypes.object,
}

export default ConfirmationModal
