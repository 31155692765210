import './InstantBankAccountVerificationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import PlaidLinkC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityBankAccountInfoWithPlaidLinkForm/PlaidLinkC'

import {
  CANCEL,
  NEXT,
  ADD_BANK_ACCOUNT_MANUALLY,
  INSTANT_BANK_ACCOUNT_VERIFICATION_MSG,
} from 'constants/language/languageConstants'

const InstantBankAccountVerification = ({
  addManualBankAccount = () => {},
  closeModal = () => {},
  plaidSuccessFunc = () => {},
  identityId = '',
  country = '',
  isDisbursementsDashboard = false,
}) => {
  return (
    <div className='InstantBankAccountVerification'>
      { !isDisbursementsDashboard && (
      <div className='modal-content'>{INSTANT_BANK_ACCOUNT_VERIFICATION_MSG}</div>
      )}
      { isDisbursementsDashboard && (
        <div className='modal-content'>We use <b>Plaid</b> to add and instantly verify your bank account information, and periodically check the balance to see if theres enough money to cover the transactions.</div>
      )}
      <div className='buttons flex space-between'>
        <Button onClick={addManualBankAccount} label={ADD_BANK_ACCOUNT_MANUALLY} variant='tertiary' />
        <div className='flex'>
          <Button className='cancel-button' onClick={closeModal} label={CANCEL} variant='ghost' />
          <PlaidLinkC
            className='plaid-link'
            id={identityId}
            country={country}
            entityType='IDENTITY'
            style={{ border: 'none', padding: '0px', background: 'inherit' }}
            onSuccess={plaidSuccessFunc}
          >
            <Button label={NEXT} />
          </PlaidLinkC>
        </div>
      </div>
    </div>
  )
}

InstantBankAccountVerification.propTypes = {
  addManualBankAccount: PropTypes.func,
  closeModal: PropTypes.func,
  identityId: PropTypes.string,
  country: PropTypes.string,
  plaidSuccessFunc: PropTypes.func,
  isDisbursementsDashboard: PropTypes.bool,
}

export default InstantBankAccountVerification
