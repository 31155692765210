import createAction from 'utilities/actions/createAction'
import { GET_APPLICATION_MERCHANTS_F_REQUEST } from 'constants/flowConstants'

const getApplicationMerchantsRequest = ({
  applicationId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_APPLICATION_MERCHANTS_F_REQUEST,
  values: {
    applicationId,
  },
  credentials,
  queries,
  meta,
})

export default getApplicationMerchantsRequest
