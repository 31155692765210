import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { PATCH_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import { EDIT_PROCESSING_INFORMATION } from 'constants/language/languageConstants'
import get from 'lodash/get'

const submitEditIdentityProcessingInformationForm = (values, dispatch, props) => {
  const {
    maxTransactionAmount,
    achMaxTransactionAmount,
    taxAuthority,
    mcc,
  } = values

  const {
    credentials,
    identityId,
    isRolePartnerCredential,
  } = props

  dispatch({
    type: PATCH_IDENTITY_F_REQUEST,
    payload: {
      values: {
        maxTransactionAmount,
        achMaxTransactionAmount,
        taxAuthority,
        mcc: isRolePartnerCredential ? null : get(mcc, 'value', ''),
      },
      id: identityId,
      credentials,
    },
    meta: {
      successCallback: () => dispatch(hideModalAction()),
      actionId: sendAmplitudeActionEvent(EDIT_PROCESSING_INFORMATION, {
        max_transaction_amount: maxTransactionAmount,
        ach_max_transaction_amount: achMaxTransactionAmount,
        tax_authority: taxAuthority,
        mcc,
        id: identityId,
      }),
    },
  })
}

export default submitEditIdentityProcessingInformationForm
