import './AdminUserNewS.scss'
import React from 'react'
import CreateFindUserFormC from 'components/Admin/Forms/CreateFindUserForm/CreateFindUserFormC'
import CreateCredentialFormC from 'components/Admin/Forms/CreateCredentialForm/CreateCredentialFormC'
import submitUserForm from 'utilities/submit/submitUserForm'
import submitCredentialForm from 'utilities/submit/submitCredentialForm'

const AdminUserNew = () => {
  return (
    <div className='AdminUserNew flex space-evenly center'>
      <div className='userForm'>
        <CreateFindUserFormC onSubmit={submitUserForm} />
      </div>

      <div className='credentialForm'>
        <CreateCredentialFormC onSubmit={submitCredentialForm} />
      </div>
    </div>
  )
}

export default AdminUserNew
