import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_ENTRIES } from 'constants/apiConstants'
import get from 'lodash/get'

const postBalanceEntryAPI = ({ id, credentials, values, props, meta }) => {
  const balanceId = id || get(props, 'balanceId')

  return paymentsAPI.post({
    path: BALANCE_ENTRIES({ balanceId }),
    credentials,
    values,
    meta,
  })
}

export default postBalanceEntryAPI
