import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import removeUndefined from 'utilities/remove/removeUndefined'

const frontendSelfServiceSignupFormModel = ({ data: processor }) => {
  const [
    id,
    firstName,
    lastName,
    companyName,
    country,
    marketingConsentAccepted,
    emailAddress,
    createdAt,
    emailVerified,
  ] = getMany(processor, [
    'id',
    'first_name',
    'last_name',
    'company_name',
    'country',
    'marketing_consent_accepted',
    'email_address',
    'created_at',
    'email_verified',
  ])

  return removeUndefined({
    id,
    createdAt: formatDate({ date: createdAt }),
    firstName,
    lastName,
    companyName,
    country,
    marketingConsentAccepted,
    emailAddress,
    emailVerified,
  })
}

export default frontendSelfServiceSignupFormModel
