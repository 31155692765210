import { take, put, fork } from 'redux-saga/effects'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  SHOW_ERROR_FLASH_NOTIFICATION,
  GET_UNMASKED_ITEM_F_REQUEST,
  GET_UNMASKED_ITEM_F_SUCCESS,
  GET_UNMASKED_ITEM_F_FAILURE,
} from 'constants/flowConstants'

const fetchUnmaskedItem = function * ({ payload }) {
  const { id, api, feKey, values, credentials } = payload
  const response = yield api({ id, values, credentials })
  const { data, error } = response

  if (error) {
    yield put({
      type: GET_UNMASKED_ITEM_F_FAILURE,
      payload: {
        id,
        feKey,
      },
    })

    yield put({
      type: SHOW_ERROR_FLASH_NOTIFICATION,
      payload: {
        values: {
          message: get(error, 'response.data._embedded.errors[0].message', GENERIC_API_ERROR_MESSAGE),
        },
      },
    })
  } else {
    yield put({
      type: GET_UNMASKED_ITEM_F_SUCCESS,
      payload: {
        ...payload,
        newValues: data,
      },
    })
  }
}

const getUnmaskedItemF = function * () {
  while (true) {
    const response = yield take([GET_UNMASKED_ITEM_F_REQUEST])

    yield fork(fetchUnmaskedItem, response)
  }
}

export default getUnmaskedItemF
