import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminUsers from './AdminUsers'
import { FETCHING } from 'constants/reducerConstants'
import { EMAIL_ADDRESS } from 'constants/filterConstants'
import { CREATE_USER } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  ADMIN_USERS_PATH,
  NEW_ADMIN_USER_PATH,
} from 'constants/pathConstants'

const initialQueries = {
  sort: 'created_at,desc',
}

const allowedFilters = [
  EMAIL_ADDRESS,
]

const mapStateToProps = (state) => {
  const isFetching = get(state, `usersR.${FETCHING}`)

  const actions = [
    {
      label: CREATE_USER,
      link: NEW_ADMIN_USER_PATH,
      className: 'primary-btn',
    },
  ]

  return {
    actions,
    initialQueries,
    isFetching,
    allowedFilters,
    usersPath: ADMIN_USERS_PATH(),
  }
}

class AdminUsersC extends Component {
  render() {
    return (
      <AdminUsers {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(AdminUsersC)
