import './RefundsInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatNumber from 'utilities/formatters/formatNumber'
import formatChartAmount from 'utilities/formatters/formatChartAmount'

import {
  CARD_REFUND_DATA,
  CARD_REFUND_DATA_TOOLTIP,
  FAILED_REFUNDS,
  FAILED_REFUNDS_TOOLTIP,
  GROSS_PROCESSING_VOL,
  GROSS_PROCESSING_VOL_TOOLTIP,
  REFUND_INSIGHTS_TOOLTIP_TITLE,
  REFUNDS_PER_MERCHANT_TOOLTIP,
  REFUND_TRENDS,
  REFUNDS_PER_MERCHANT,
  REFUNDS_PER_MERCHANT_OR_APPLICATION,
  REFUNDS_PER_MERCHANT_OR_APPLICATION_TOOLTIP,
  SUCCESSFUL_REFUNDS, SUCCESSFUL_REFUNDS_TOOLTIP,
  TOTAL_TRANSACTION_VOL,
  TOTAL_TRANSACTION_VOLUME,
  TOTAL_TRANSACTION_VOLUME_TOOLTIP,
} from 'constants/language/languageConstants'

import {
  REFUNDS_GROSS_PROCESSING_VOLUME_BIG_NUMBER_TOTAL,
  REFUNDS_GROSS_PROCESSING_VOLUME_COUNT_BIG_NUMBER_TOTAL,
  REFUNDS_SUCCESSFUL_REFUNDS_BIG_NUMBER_TOTAL,
  REFUNDS_SUCCESSFUL_REFUNDS_COUNT_BIG_NUMBER_TOTAL,
  REFUNDS_FAILED_REFUNDS_BIG_NUMBER_TOTAL,
  REFUNDS_FAILED_REFUNDS_COUNT_BIG_NUMBER_TOTAL,
  REFUNDS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL,
  REFUNDS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
  REFUNDS_REFUND_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR,
  REFUNDS_REFUND_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR,
  REFUNDS_REFUND_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR,
  REFUNDS_REFUND_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR,
  REFUNDS_REFUNDS_PER_MERCHANT_TABLE,
  REFUNDS_CARD_REFUND_DATA_TABLE,
} from 'constants/chartConstants'

const RefundsInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRolePlatformCredential = false,
  showRefundsPerMerchant = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='RefundsInsights flex column'>
      <div className='time-frame-container flex space-between items-center'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>Time Frame</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated p-2'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>Summary</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>{REFUND_INSIGHTS_TOOLTIP_TITLE}</p>
                <p><span className='bold'>{GROSS_PROCESSING_VOL}</span>{GROSS_PROCESSING_VOL_TOOLTIP}</p>
                <p><span className='bold'>{SUCCESSFUL_REFUNDS}</span>{SUCCESSFUL_REFUNDS_TOOLTIP}</p>
                <p><span className='bold'>{FAILED_REFUNDS}</span>{FAILED_REFUNDS_TOOLTIP}</p>
                <p><span className='bold'>{TOTAL_TRANSACTION_VOLUME}</span>{TOTAL_TRANSACTION_VOLUME_TOOLTIP}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={GROSS_PROCESSING_VOL}
            headerData={{
              name: REFUNDS_GROSS_PROCESSING_VOLUME_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: REFUNDS_GROSS_PROCESSING_VOLUME_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={SUCCESSFUL_REFUNDS}
            headerData={{
              name: REFUNDS_SUCCESSFUL_REFUNDS_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: REFUNDS_SUCCESSFUL_REFUNDS_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={FAILED_REFUNDS}
            headerData={{
              name: REFUNDS_FAILED_REFUNDS_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: REFUNDS_FAILED_REFUNDS_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          <CustomBigNumberC
            title={TOTAL_TRANSACTION_VOL}
            headerData={{
              name: REFUNDS_TOTAL_TRANSACTION_VOL_BIG_NUMBER_TOTAL,
              formatter: formatChartAmount,
            }}
            subHeaderData={{
              name: REFUNDS_TOTAL_TRANSACTION_VOLUME_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)} transactions`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
        </div>

        <GroupedCharts
          title={REFUND_TRENDS}
          classNames='transaction-trends'
          chartClassNames='transaction-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: REFUNDS_REFUND_VOLUME_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: REFUNDS_REFUND_COUNT_BY_PAYMENT_METHOD_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: REFUNDS_REFUND_VOLUME_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR, height: 260 },
            { name: REFUNDS_REFUND_COUNT_BY_CARD_BRAND_ECHARTS_TIMESERIES_BAR, height: 260 },
          ]}
        />

        { showRefundsPerMerchant && (
          <div className='mtl'>
            <ChartC
              title={isRolePlatformCredential ? REFUNDS_PER_MERCHANT_OR_APPLICATION : REFUNDS_PER_MERCHANT}
              name={REFUNDS_REFUNDS_PER_MERCHANT_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              showPivots
              showGroupBy
              hideTitle
              tooltipMessage={<p>{isRolePlatformCredential ? REFUNDS_PER_MERCHANT_OR_APPLICATION_TOOLTIP : REFUNDS_PER_MERCHANT_TOOLTIP}</p>}
            />
          </div>
        )}

        <div className='mtl'>
          <ChartC
            title={CARD_REFUND_DATA}
            name={REFUNDS_CARD_REFUND_DATA_TABLE}
            height={280}
            className='table'
            timeRange={timeRange}
            hideTitle
            showGroupBy
            tooltipMessage={<p>{CARD_REFUND_DATA_TOOLTIP}</p>}
          />
        </div>
      </div>
    </div>
  )
}

RefundsInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  showRefundsPerMerchant: PropTypes.bool,
}

export default RefundsInsights
