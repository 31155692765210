import './AdverseMediaScreeningDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import map from 'lodash/map'
import size from 'lodash/size'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import {
  DETAILS,
  IDENTITY_MATCH_SUMMARY,
  BUSINESS_IDENTITY,
} from 'constants/language/languageConstants'

const AdverseMediaScreeningDetails = ({
  title = DETAILS,
  rightTitle = '',
  clickToCopies = [],
  identityDataPoints = [],
  identityMatchDataPoints = [],
}) => {
  const listedItems = ({ data, separator = true, normalizer, className }) => map(data, (dataPoint, index) => (
    <div className={`${className} listed-item flex items-center`} key={index}>
      <div className='value p-2'>{normalizer ? normalizer(dataPoint) : dataPoint}</div>
      {!isEqual(index, size(data) - 1) && separator && <div className='dot' />}
    </div>
  ))

  return (
    <PageSectionV2C
      title={title}
      rightTitle={rightTitle}
      clickToCopies={clickToCopies}
      className='AdverseMediaScreeningDetails screening-details-highlight'
    >
      <div className='adverse-media-screening-details'>
        <h6>{BUSINESS_IDENTITY}</h6>

        <ul className='identity-data-points'>
          {map(identityDataPoints, ({
            label,
            data,
            className,
          }) => (
            !isEmpty(data) && (
            <li className={`${className} data-point`} key={label}>
              <div className='flex items-center'>
                <div className='label p-2-bold'>{label}: </div>
                <div className='value p-2'>{data}</div>
              </div>
            </li>
            )
          ))}
        </ul>

        <h6 className='identity-match-summary'>{IDENTITY_MATCH_SUMMARY}</h6>

        <ul className='identity-match-data-points'>
          {map(identityMatchDataPoints, ({
            label,
            data,
            className,
            separator = true,
            normalizer,
          }) => (
            !isEmpty(data) && (
              <li className='identity-match-data-point data-point' key={label}>
                <div className='flex'>
                  <div className='label p-2-bold'>{label}:</div>
                  <div className='listed-items flex items-center'>{listedItems({ data, separator, normalizer, className })}</div>
                </div>
              </li>
            )))}
        </ul>

      </div>
    </PageSectionV2C>
  )
}

AdverseMediaScreeningDetails.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
  clickToCopies: PropTypes.array,
  identityDataPoints: PropTypes.array,
  identityMatchDataPoints: PropTypes.array,
}

export default AdverseMediaScreeningDetails
