import './SelfieResultsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const SelfieResults = ({
  selfiePhotosData = [],
  resultDetailsData = [],
}) => {
  return (
    <div className='SelfieResults'>
      <PageSectionV2C
        smallTitle='Selfie Photos'
        className='selfie-photos-data-section no-pad'
        columns={selfiePhotosData}
      />

      <PageSectionV2C
        smallTitle='Result Details'
        className='result-details-data-section no-pad'
        columns={resultDetailsData}
      />
    </div>
  )
}

SelfieResults.propTypes = {
  selfiePhotosData: PropTypes.array,
  resultDetailsData: PropTypes.array,
}

export default SelfieResults
