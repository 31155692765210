import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import get from 'lodash/get'

const displayString = (string) => {
  const platform = getCurrentPlatform()
  const platformStrings = get(platform, 'strings')

  return get(platformStrings, string, string)
}

export default displayString
