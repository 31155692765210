import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isInvalid } from 'redux-form'
import UpdateAdminToolsForm from './UpdateAdminToolsForm'
import ActionStatusFormC from 'components/Customer/Forms/ActionStatusForm/ActionStatusFormC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { CONFIRM_ACTION } from 'constants/modalConstants'

const confirmationModalAction = showModalAction({
  modalType: CONFIRM_ACTION,
  modalProps: {
    Component: ActionStatusFormC,
  },
})

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    form,
    initialValues,
  ] = getMany(props, [
    'form',
    'initialValues',
  ])

  const formInvalid = isInvalid(form)(state)

  return {
    credentials,
    form,
    initialValues,
    confirmationModalAction,
    formInvalid,
  }
}

class UpdateAdminToolsFormC extends Component {
  render() {
    return (
      <UpdateAdminToolsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(UpdateAdminToolsFormC)
