import './PageSectionV2S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DataSectionV2 from 'components/Customer/Shared/Page/PageSection/DataSectionV2'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import { ACTIONS, t } from 'constants/language/languageConstants'
import { CHEVRON_DOWN_ICON } from 'constants/iconConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import isString from 'lodash/isString'
import kebabCase from 'lodash/kebabCase'

const PageSectionV2 = ({
  children,
  title = '',
  smallTitle,
  asideTitle,
  rightTitle,
  subTitle = '',
  linkToView,
  textForLink,
  columns = [],
  actions = [],
  filteredDropdownActions = [],
  toggleDropdownActions = () => {},
  showDropdownActions = false,
  dropdownActionsDisabled = false,
  dropdownActionsDisabledMessage = '',
  dropdownActionsTitle = ACTIONS,
  isFetching = false,
  className = '',
  tooltip = false,
  tooltipMessage = '',
  clickToCopies = [],
  emptyMessage = '',
  tooltipPosition = 'default',
  badge,
  isPreviewPanel = false,
}) => {
  const oneColumn = columns.length === 1

  const pageSectionClassnames = classnames({
    PageSectionV2: true,
    [className]: true,
  })

  return (
    <section className={pageSectionClassnames}>
      {isFetching && <Loader />}

      {!isFetching && (
      <>
        <header className='flex space-between'>
          {(title || smallTitle || tooltip || subTitle) && (
            <div>
              <div className='flex header items-center'>
                {title && <h4 className='title'>{t(title)}</h4>}
                {smallTitle && <h5 className='small-title'>{t(smallTitle)}</h5>}
                { map(clickToCopies, ({
                  label,
                  content,
                }, index) => {
                  return content ? <ClickToCopyC key={index} className='page-section' label={label} content={content} /> : null
                })}
                {badge && <div className='badge'>{badge}</div>}

                {asideTitle && !isPreviewPanel && <div className='aside-title p-3'>{t(asideTitle)}</div>}

                {tooltip && <TooltipLabelC message={tooltipMessage} position={tooltipPosition} />}
              </div>
              {subTitle && (isString(subTitle) ? <p className='subheader p-1'>{t(subTitle)}</p> : t(subTitle))}

              {linkToView && isString(linkToView) && (<div className='link-to-view'><Link to={linkToView}>{t(textForLink)}</Link></div>)}

              {isPreviewPanel && <div className='preview-panel-aside-title p-3'>{t(asideTitle)}</div>}

            </div>
          )}

          {rightTitle && <div className='right-title p-2'>{t(rightTitle)}</div>}

          {!isEmpty(actions) && (
          <ul className='flex actions'>
            {
              map(actions, ({
                label,
                link,
                action = () => {},
                condition = true,
                icon,
                buttonClassName,
                customButtonStyles,
                component: Component,
                extraProps = {},
                variant = 'secondary',
                isDisabledButton = false,
                disabledButtonMessage = '',
              }) => {
                const button = isDisabledButton && disabledButtonMessage ? (
                  <TooltipLabelC
                    showIcon={false}
                    message={disabledButtonMessage}
                    position='left-of-children'
                  >
                    <Button variant={variant} className={buttonClassName} style={customButtonStyles} disabled={isDisabledButton} label={label} rightIcon={icon} />
                  </TooltipLabelC>
                ) : (
                  <Button variant={variant} className={buttonClassName} style={customButtonStyles} disabled={isFetching} label={label} rightIcon={icon} />
                )

                return condition ? (
                  Component ? <Component {...extraProps} /> : (
                    <li onClick={() => action()} key={label}>
                      {link ? (
                        <Link to={link} key={label}>{button}</Link>
                      ) : button}
                    </li>
                  )
                ) : null
              })
            }
          </ul>
          )}

          {!isEmpty(filteredDropdownActions) && dropdownActionsDisabled && dropdownActionsDisabledMessage && (
          <TooltipLabelC
            showIcon={false}
            message={dropdownActionsDisabledMessage}
            position='left-of-children'
          >
            <div className={`dropdown-actions flex ${dropdownActionsDisabled ? 'disabled' : 'enabled'}`}>
              {t(dropdownActionsTitle)} <i className={`fa fa-${CHEVRON_DOWN_ICON}`} />
            </div>
          </TooltipLabelC>
          )}

          {!isEmpty(filteredDropdownActions) && !dropdownActionsDisabled && !dropdownActionsDisabledMessage && (
          <div className={`dropdown-actions flex ${dropdownActionsDisabled ? 'disabled' : 'enabled'}`} onClick={dropdownActionsDisabled ? null : toggleDropdownActions}>
            {t(dropdownActionsTitle)} <i className={`fa fa-${CHEVRON_DOWN_ICON}`} />

            <div className={`dropdown-actions-items ${showDropdownActions ? 'open' : 'closed'}`}>
              { map(filteredDropdownActions, ({ label, action, className: dropdownClassName, link }) => {
                return link ? <Link className={`item ${dropdownClassName}`} to={link} key={label}>{t(label)}</Link> :
                <div className={`item ${className}`} onClick={action} key={label}>{t(label)}</div>
              })}
            </div>
          </div>
          )}
        </header>

        <div className='content'>
          {!isEmpty(columns) ? (
            <ul className='flex actions wrap data-list'>
              {
              map(columns, ({
                data,
                smallTitle: dataSectionSmallTitle,
                largeTitle,
                subtitle,
                type,
                condition = true,
                idKey,
                idValue,
                displayDataVertical = false,
                headerActions = [],
                asideTitle: dataSectionAsideTitle,
              }, index) => {
                return (
                  condition ? (
                    <DataSectionV2
                      data={data}
                      largeTitle={largeTitle}
                      smallTitle={dataSectionSmallTitle}
                      subtitle={subtitle}
                      type={type}
                      idKey={idKey}
                      idValue={idValue}
                      displayDataVertical={displayDataVertical}
                      headerActions={headerActions}
                      oneColumn={oneColumn}
                      asideTitle={dataSectionAsideTitle}
                      title={kebabCase(`${title}-${index}`)}
                      key={kebabCase(`${title}-${index}`)}
                    />
                  ) : null
                )
              })
            }
            </ul>
          ) : <div className='empty-message p-2'>{t(emptyMessage)}</div>}
        </div>
      </>
      )}
      {children}
    </section>
  )
}

PageSectionV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  asideTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  rightTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  columns: PropTypes.array,
  actions: PropTypes.array,
  filteredDropdownActions: PropTypes.array,
  toggleDropdownActions: PropTypes.func,
  showDropdownActions: PropTypes.bool,
  isFetching: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  clickToCopies: PropTypes.array,
  emptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  tooltipPosition: PropTypes.string,
  filteredActionsDropdownTitle: PropTypes.string,
  dropdownActionsDisabled: PropTypes.bool,
  dropdownActionsDisabledMessage: PropTypes.string,
  badge: PropTypes.string,
  linkToView: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  textForLink: PropTypes.string,
  isPreviewPanel: PropTypes.bool,
}

export default PageSectionV2
