import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  V1_SETTLEMENT,
  SETTLEMENTS_BASE,
} from 'constants/apiConstants'

const patchSettlementV1API = ({ values, id, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: V1_SETTLEMENT({ settlementId: id }),
    service: SETTLEMENTS_BASE,
  })
}

export default patchSettlementV1API
