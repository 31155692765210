import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendPostOnboardingFormModel = ({ data: onboardingForm }) => {
  const [
    id,
    onboardingFormLink,
  ] = getMany(onboardingForm, [
    'id',
    'onboarding_link.link_url',
  ])

  return removeUndefined({
    id,
    onboardingFormLink,
  })
}

export default frontendPostOnboardingFormModel
