import './CreateNewRunS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  CREATE_NEW_RUN,
  CREATE_NEW_RUN_DESCRIPTION,
} from 'constants/language/languageConstants'

const CreateNewRun = ({
  closeModal = () => {},
  createNewRunFunc = () => {},
  isPatchingReview = false,
}) => {
  return (
    <div className='CreateNewRun'>
      <div className='modal-content'>
        <div className='description'>{CREATE_NEW_RUN_DESCRIPTION}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={createNewRunFunc} label={CREATE_NEW_RUN} submitting={isPatchingReview} />
      </div>
    </div>
  )
}

CreateNewRun.propTypes = {
  closeModal: PropTypes.func,
  createNewRunFunc: PropTypes.func,
  isPatchingReview: PropTypes.bool,
}

export default CreateNewRun
