import { GET_GUEST_ONBOARDING_FORM_DATA_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestOnboardingFormDataRequest = ({
  id,
  meta,
}) => createAction({
  type: GET_GUEST_ONBOARDING_FORM_DATA_F_REQUEST,
  id,
  meta,
})

export default getGuestOnboardingFormDataRequest
