import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import convertMilitaryHourToRegularHour from 'utilities/convert/convertMilitaryHourToRegularHour'
import moment from 'moment'
import { TIME_ZONE_TO_SHORT_DISPLAY_MAP } from 'constants/timeConstants'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import {
  NET,
  CUSTOM,
  STANDARD,
  DAILY,
  MANUAL,
} from 'constants/payoutSettingsConstants'

const formatFrequencyDisplayMessage = ({ frequency, day }) => {
  if (frequency && day) {
    return `${capitalize(frequency)} on the ${moment.localeData().ordinal(toNumber(day))}`
  }
  return capitalize(frequency)
}

const frontendPayoutSettingsModel = ({ data: payoutProfile }) => {
  const [
    id,
    createdAt,
    updatedAt,
    tags,
    linkedId,
    linkedType,
    type,
    net,
    gross,
    custom,
  ] = getMany(payoutProfile, [
    'id',
    'created_at',
    'updated_at',
    'tags',
    'linked_id',
    'linked_type',
    'type',
    'net',
    'gross',
    'custom',
  ])

  const isTypeNet = type === NET
  const payoutSettings = isTypeNet ? net : get(gross, 'payouts')
  const feeSettings = get(gross, 'fees')

  const payoutPaymentInstrumentId = get(payoutSettings, 'payment_instrument_id')
  const payoutRail = get(payoutSettings, 'rail')
  const payoutFrequency = get(payoutSettings, 'frequency')
  const payoutMonthlyDay = get(payoutSettings, 'day_of_month')
  const payoutSubmissionDelayDays = get(payoutSettings, 'submission_delay_days')
  const payoutStartTime = get(payoutSettings, 'start_time')
  const payoutTimeZone = get(payoutSettings, 'time_zone')

  const feePaymentInstrumentId = get(feeSettings, 'payment_instrument_id')
  const feeFrequency = get(feeSettings, 'frequency')
  const feeMonthlyDay = get(feeSettings, 'day_of_month')
  const feeSubmissionDelayDays = get(feeSettings, 'submission_delay_days')
  const feeRail = get(feeSettings, 'rail')
  const feeStartTime = get(feeSettings, 'start_time')
  const feeTimeZone = get(feeSettings, 'time_zone')

  const displayRail = payoutRail === STANDARD ? 'Next-day ACH' : 'Same-day ACH'
  const displayRailHeader = payoutRail === STANDARD ? 'Next-day ACH' : 'Same-day ACH'

  let payoutHour
  let payoutMinute
  let payoutTimeMeridian
  if (payoutStartTime) {
    payoutHour = convertMilitaryHourToRegularHour({ militaryHour: payoutStartTime.substring(0, 2) })
    payoutMinute = payoutStartTime.substring(2)
    payoutTimeMeridian = parseInt(payoutStartTime.substring(0, 2), 10) >= 12 ? 'PM' : 'AM'
  }

  let feeHour
  let feeMinute
  let feeTimeMeridian
  if (feeStartTime) {
    feeHour = convertMilitaryHourToRegularHour({ militaryHour: feeStartTime.substring(0, 2) })
    feeMinute = feeStartTime.substring(2)
    feeTimeMeridian = parseInt(feeStartTime.substring(0, 2), 10) >= 12 ? 'PM' : 'AM'
  }

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    tags,
    linkedId,
    linkedType,
    type,
    displayType: convertSnakeToSentenceCase(type),
    payoutFrequency,
    payoutMonthlyDay: toString(payoutMonthlyDay),
    displayPayoutFrequency: formatFrequencyDisplayMessage({ frequency: payoutFrequency, day: payoutMonthlyDay }),
    payoutSubmissionDelayDays,
    payoutPaymentInstrumentId,
    feeFrequency,
    feeMonthlyDay: toString(feeMonthlyDay),
    displayFeeFrequency: formatFrequencyDisplayMessage({ frequency: feeFrequency, day: feeMonthlyDay }),
    payoutRail,
    displayPayoutRail: payoutRail ? displayRail : undefined,
    displayRailHeader: payoutRail ? displayRailHeader : undefined,
    feeSubmissionDelayDays,
    feePaymentInstrumentId,
    feeRail,
    displayFeeRail: feeRail ? displayRail : undefined,
    payoutStartTime,
    payoutTimeZone,
    displayPayoutStartTime: `${payoutHour}:${payoutMinute} ${payoutTimeMeridian} ${TIME_ZONE_TO_SHORT_DISPLAY_MAP[payoutTimeZone]}`,
    payoutTimeData: {
      hour: payoutHour,
      minute: payoutMinute,
      timeMeridian: payoutTimeMeridian,
      timeZone: payoutTimeZone,
    },
    feeStartTime,
    feeTimeZone,
    displayFeeStartTime: `${feeHour}:${feeMinute} ${feeTimeMeridian} ${TIME_ZONE_TO_SHORT_DISPLAY_MAP[feeTimeZone]}`,
    feeTimeData: {
      hour: feeHour,
      minute: feeMinute,
      timeMeridian: feeTimeMeridian,
      timeZone: feeTimeZone,
    },
  })
}

export default frontendPayoutSettingsModel
