import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendTransfersModel = ({ data: transfers }) => {
  const transfersModels = map(transfers, (transfer) => frontendTransferModel({ data: transfer }))

  return keyBy(transfersModels, 'id')
}

export default frontendTransfersModel
