import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateReceiptForm from './CreateReceiptForm'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getMerchantDevicesRequest from 'utilities/actions/get/getMerchantDevicesRequest'
import getAuthorizationRequest from 'utilities/actions/get/getAuthorizationRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PAYMENT_CARD_PRESENT } from 'constants/paymentInstrumentConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_RECEIPT_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'

import {
  getAuthorizationSelector,
  getEnabledDevicesSelector,
  getTransferWithRelatedSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const transferId = get(props, 'params.transferId')
  const authorizationId = get(props, 'params.authorizationId')
  let isFetching = false
  if (transferId) {
    isFetching = get(state, `receiptsR.${FETCHING}`) || get(state, `transfersR.${FETCHING}`)
  } else if (authorizationId) {
    isFetching = get(state, `receiptsR.${FETCHING}`) || get(state, `authorizationsR.${FETCHING}`)
  }

  let paymentInstrument = {}
  let merchantId
  let amount
  let displayAmount

  if (transferId) {
    const transfer = getTransferWithRelatedSelector(state, transferId)
    paymentInstrument = get(transfer, 'paymentInstrument', {})
    merchantId = get(transfer, 'merchantId')
    amount = get(transfer, 'amount')
    displayAmount = get(transfer, 'displayAmount')
  } else if (authorizationId) {
    const authorization = getAuthorizationSelector(state, authorizationId)
    paymentInstrument = get(authorization, 'paymentInstrument', {})
    merchantId = get(authorization, 'merchant')
    amount = get(authorization, 'amount')
    displayAmount = get(authorization, 'displayAmount')
  }

  const transactionType = get(paymentInstrument, 'type')
  const isCardPresent = isEqual(transactionType, PAYMENT_CARD_PRESENT)
  const devices = isCardPresent ? getEnabledDevicesSelector(state) : []
  const deviceOptions = map(devices, ({ id, name }) => ({ label: name, value: id }))

  const formError = get(state, `form.${CREATE_RECEIPT_FORM}.error`)

  return removeUndefined({
    credentials,
    isCardPresent,
    deviceOptions,
    isFetching,
    transferId,
    amount,
    displayAmount,
    formError,
    merchantId,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => dispatch(getTransferRequest({ credentials, transferId })),
    getAuthorization: ({ credentials, authorizationId }) => dispatch(getAuthorizationRequest({ credentials, authorizationId })),
    getMerchantDevices: ({ credentials, merchantId }) => dispatch(getMerchantDevicesRequest({ credentials, merchantId })),
  }
}

class CreateReceiptFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      transferId,
      getTransfer,
      merchantId,
      getMerchantDevices,
      authorizationId,
      getAuthorization,
      isCardPresent,
    } = this.props

    if (transferId) {
      getTransfer({ credentials, transferId })
    } else if (authorizationId) {
      getAuthorization({ credentials, authorizationId })
    }

    if (isCardPresent && merchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      transferId,
      getTransfer,
      merchantId,
      getMerchantDevices,
      authorizationId,
      getAuthorization,
      isCardPresent,
    } = this.props

    const {
      transferId: prevTransferId,
      merchantId: prevMerchantId,
      authorizationId: prevAuthorizationId,
    } = prevProps

    if (transferId && transferId !== prevTransferId) {
      getTransfer({ credentials, transferId })
    } else if (authorizationId && authorizationId !== prevAuthorizationId) {
      getAuthorization({ credentials, authorizationId })
    }

    if (isCardPresent && merchantId && merchantId !== prevMerchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  render() {
    return (
      <CreateReceiptForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReceiptFormC)
