import React from 'react'
import PropTypes from 'prop-types'
import InstantBankAccountVerificationC from 'components/Customer/Pages/InstantBankAccountVerification/InstantBankAccountVerificationC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { INSTANT_BANK_VERIFICATION } from 'constants/language/languageConstants'

const InstantBankAccountVerificationModal = ({
  identityId,
  elavonEnabled,
  settlementAliasWarningObject,
  successFunction,
  merchantId,
  warningMessage,
}) => {
  return (
    <GenericModal
      className='InstantBankAccountVerificationModal'
      Component={InstantBankAccountVerificationC}
      title={INSTANT_BANK_VERIFICATION}
      identityId={identityId}
      elavonEnabled={elavonEnabled}
      settlementAliasWarningObject={settlementAliasWarningObject}
      successFunction={successFunction}
      merchantId={merchantId}
      warningMessage={warningMessage}
    />
  )
}

InstantBankAccountVerificationModal.propTypes = {
  identityId: PropTypes.string,
  elavonEnabled: PropTypes.bool,
  settlementAliasWarningObject: PropTypes.object,
  successFunction: PropTypes.func,
  merchantId: PropTypes.string,
  warningMessage: PropTypes.string,
}

export default InstantBankAccountVerificationModal
