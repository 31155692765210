import { getDispatch } from 'state-layer/configureStore'

const dispatch = (action) => {
  const dispatchAction = getDispatch()

  if (dispatchAction) {
    dispatchAction(action)
  }
}

export default dispatch
