import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccessFormsSelector } from 'state-layer/selectors'
import StartLiveAccountApplicationFormC from 'components/Customer/Forms/StartLiveAccountApplicationForm/StartLiveAccountApplicationFormC'
import LiveAccountApplicationsC from 'components/Customer/Pages/LiveAccountApplications/LiveAccountApplicationsC'
import Loader from 'components/Shared/Loader/Loader'
import getAccessFormsRequest from 'utilities/actions/get/getAccessFormsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import size from 'lodash/size'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const sandboxApplicationId = get(getCurrentUser(), 'applicationId', '')
  const accessForms = getAccessFormsSelector(state)
  const isFetching = get(state, `accessFormsR.${FETCHING}`)
  const hasAccessForms = size(accessForms) > 0

  return {
    credentials,
    sandboxApplicationId,
    accessForms,
    isFetching,
    hasAccessForms,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessForms: ({ credentials, applicationId }) => dispatch(getAccessFormsRequest({ credentials, applicationId })),
  }
}

class LiveAccountApplicationStatusC extends Component {
  componentDidMount() {
    const {
      credentials,
      sandboxApplicationId,
      getAccessForms,
    } = this.props

    getAccessForms({
      credentials,
      applicationId: sandboxApplicationId,
    })
  }

  render() {
    const {
      accessForms,
      isFetching,
      hasAccessForms,
    } = this.props

    return (
      <>
        {isFetching && <Loader />}

        {hasAccessForms && !isFetching && (
        <LiveAccountApplicationsC
          accessForms={accessForms}
        />
        )}

        {!hasAccessForms && !isFetching && (
        <StartLiveAccountApplicationFormC />
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationStatusC)
