import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SetIdleScreenFormC from 'components/Customer/Forms/SetIdleScreenForm/SetIdleScreenFormC'
import includes from 'lodash/includes'

import {
  SET_IDLE_MESSAGE_DESCRIPTION,
  UPDATE_IDLE_SCREEN,
  UPDATE_IDLE_SCREEN_DESCRIPTION,
} from 'constants/language/languageConstants'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

const SetIdleScreenModal = ({
  deviceId = '',
  deviceName = '',
  merchantName = '',
  idleMessage = '',
  processor = '',
  gateway = '',
  processorGatewayValue = '',
  merchantId = '',
}) => {
  return (
    <GenericModal
      className='SetIdleScreenModal'
      title={UPDATE_IDLE_SCREEN}
      subTitle={includes([FINIX_V1, DUMMY_V1], processorGatewayValue) ? UPDATE_IDLE_SCREEN_DESCRIPTION : SET_IDLE_MESSAGE_DESCRIPTION}
      Component={SetIdleScreenFormC}
      deviceId={deviceId}
      deviceName={deviceName}
      merchantName={merchantName}
      merchantId={merchantId}
      idleMessage={idleMessage}
      processor={processor}
      gateway={gateway}
      processorGatewayValue={processorGatewayValue}
    />
  )
}

SetIdleScreenModal.propTypes = {
  deviceId: PropTypes.string,
  deviceName: PropTypes.string,
  merchantName: PropTypes.string,
  idleMessage: PropTypes.string,
  processor: PropTypes.string,
  gateway: PropTypes.string,
  processorGatewayValue: PropTypes.string,
  merchantId: PropTypes.string,
}

export default SetIdleScreenModal
