import './MultiSelectFieldS.scss'
import { Field } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import MultiSelectInput from 'components/Shared/Inputs/MultiSelectInput/MultiSelectInput'

const colors = {
  borderInputDefault: '#7D90A5',
  actionActive: '#0B5DBC',
  textPrimary: '#020F1C',
  actionHoverLight: '#E6F3FD',
  bgLight: '#F6F7F8',
}

const defaultStyles = {
  control: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    boxShadow: state.isFocused ? '0px 0px 1px 1px rgba(11, 93, 188, 0.50)' : 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    backgroundColor: state.isSelected ? colors?.actionHoverLight : 'none',
    color: colors?.textPrimary,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors?.bgLight,
    },
  }),
  menu: (styles = {}) => ({
    ...styles,
    border: '1px solid #7D90A5',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
}

// TODO: we should add a class for error to turn the field red when this field is required
const MultiSelectField = ({
  name = '',
  label = '',
  options = [],
  className = '',
  classNamePrefix = 'multi-select',
  placeholder = 'Select Any',
  disabled = false,
  tooltip = '',
  tooltipPosition,
  helperText = '',
  hideSelectedOptions = true,
  formatOptionLabel,
  maxMenuHeight,
  isRequired = false,
}) => {
  return (
    <div className='MultiSelectField'>
      {
        (label || tooltip || isRequired) ? (
          <div className='label flex'>
            { label && <label className='label-2'>{label}</label> }
            { isRequired && <span className='required'>{'\u00a0'}*</span> }
            { tooltip && <TooltipLabelC message={tooltip} position={tooltipPosition} /> }
          </div>
        ) : null
      }

      <Field
        name={name}
        label={label}
        options={options}
        placeholder={placeholder}
        component={MultiSelectInput}
        disabled={disabled}
        className={className}
        classNamePrefix={classNamePrefix}
        styles={defaultStyles}
        helperText={helperText}
        hideSelectedOptions={hideSelectedOptions}
        formatOptionLabel={formatOptionLabel}
        maxMenuHeight={maxMenuHeight}
        isRequired={isRequired}
      />
    </div>
  )
}

MultiSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  helperText: PropTypes.string,
  hideSelectedOptions: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  isRequired: PropTypes.bool,
}

export default MultiSelectField
