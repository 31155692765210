import React, { Component } from 'react'
import { connect } from 'react-redux'
import TabsC from 'components/Shared/Tabs/TabsC'
import MembersC from 'components/Customer/Pages/Members/MembersC'
import RolesC from 'components/Customer/Pages/Roles/RolesC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hasPermissions from 'utilities/hasPermissions'
import { CREATE_TEAM_MEMBER_PERMISSION } from 'constants/permissionConstants'
import { CUSTOM } from 'constants/roleConstants'
import get from 'lodash/get'

import {
  entityTypeToRoleMap,
  roleToEntityIdMap,
  roleToEntityTypeMap,
} from 'constants/memberConstants'

import {
  MEMBERS,
  ROLES,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const currentUser = getCurrentUser(state)
  const credentials = getCurrentCredentials(state)
  const currentUserRole = get(credentials, 'role')
  const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')
  const entityType = get(props, 'entityType') || get(roleToEntityTypeMap, currentUserRole)
  const entityIdKey = get(roleToEntityIdMap, currentUserRole)
  const entityId = get(props, 'entityId') || get(currentUser, entityIdKey)
  const authRole = entityTypeToRoleMap[entityType]

  return {
    entityId,
    entityType,
    dashboardUserRoleType,
    authRole,
  }
}
class TeamTabs extends Component {
  render() {
    const {
      entityId,
      entityType,
      dashboardUserRoleType,
      authRole,
    } = this.props

    const tabs = [
      {
        id: 'members',
        name: MEMBERS,
        component: MembersC,
        props: {
          entityId,
          entityType,
        },
      },
      {
        id: 'roles',
        name: ROLES,
        component: RolesC,
        condition: dashboardUserRoleType !== undefined && dashboardUserRoleType !== CUSTOM && hasPermissions([CREATE_TEAM_MEMBER_PERMISSION]),
        props: {
          entityId,
          entityType,
          authRole,
        },
      },
    ]

    return (
      <div className='TeamTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(TeamTabs)
