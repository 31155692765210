import './EditMastercardPayoutConfigModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import EditVisaPayoutConfigsFormC from 'components/Customer/Forms/EditApplicationPayoutConfigsForm/EditApplicationPayoutConfigsFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { EDIT_MASTERCARD_APPLICATION_CONFIGS } from 'constants/language/languageConstants'

const EditMastercardPayoutConfigModal = ({
  credentialId,
  applicationId,
  mcCardAcceptorId,
  mcDefaultPaymentType,
  mcDefaultCurrencies,
  mcDefaultMcc,
  mcDefaultSenderFirstName,
  mcDefaultSenderLastName,
  mcDefaultSenderFullName,
  mcDefaultSenderAddressLine1,
  mcDefaultSenderAddressLine2,
  mcDefaultSenderCity,
  mcDefaultSenderPostalCode,
  mcDefaultSenderCountry,
  mcDefaultSenderCountryCode,
  isMastercard = true,
  mcDefaultSenderAccountNumber,
  mcSubdivision,
  mcStatementDescriptor,
}) => {
  return (
    <GenericModal
      className='EditMastercardPayoutConfigModal'
      title={EDIT_MASTERCARD_APPLICATION_CONFIGS}
      Component={EditVisaPayoutConfigsFormC}
      credentialId={credentialId}
      applicationId={applicationId}
      mcCardAcceptorId={mcCardAcceptorId}
      mcDefaultPaymentType={mcDefaultPaymentType}
      mcDefaultCurrencies={mcDefaultCurrencies}
      mcDefaultMcc={mcDefaultMcc}
      mcDefaultSenderFirstName={mcDefaultSenderFirstName}
      mcDefaultSenderLastName={mcDefaultSenderLastName}
      mcDefaultSenderFullName={mcDefaultSenderFullName}
      mcDefaultSenderAddressLine1={mcDefaultSenderAddressLine1}
      mcDefaultSenderAddressLine2={mcDefaultSenderAddressLine2}
      mcDefaultSenderCity={mcDefaultSenderCity}
      mcDefaultSenderPostalCode={mcDefaultSenderPostalCode}
      mcDefaultSenderCountry={mcDefaultSenderCountry}
      mcDefaultSenderCountryCode={mcDefaultSenderCountryCode}
      isMastercard={isMastercard}
      mcDefaultSenderAccountNumber={mcDefaultSenderAccountNumber}
      mcSubdivision={mcSubdivision}
      mcStatementDescriptor={mcStatementDescriptor}
    />
  )
}
EditMastercardPayoutConfigModal.propTypes = {
  credentialId: PropTypes.string,
  applicationId: PropTypes.string,
  mcCardAcceptorId: PropTypes.string,
  mcDefaultPaymentType: PropTypes.string,
  mcDefaultCurrencies: PropTypes.array,
  mcDefaultMcc: PropTypes.string,
  mcDefaultSenderFirstName: PropTypes.string,
  mcDefaultSenderLastName: PropTypes.string,
  mcDefaultSenderFullName: PropTypes.string,
  mcDefaultSenderAddressLine1: PropTypes.string,
  mcDefaultSenderAddressLine2: PropTypes.string,
  mcDefaultSenderCity: PropTypes.string,
  mcDefaultSenderPostalCode: PropTypes.string,
  mcDefaultSenderCountry: PropTypes.string,
  mcDefaultSenderCountryCode: PropTypes.string,
  isMastercard: PropTypes.bool,
  mcDefaultSenderAccountNumber: PropTypes.string,
  mcSubdivision: PropTypes.string,
  mcStatementDescriptor: PropTypes.string,
}

export default EditMastercardPayoutConfigModal
