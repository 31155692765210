import React, { Component } from 'react'
import { connect } from 'react-redux'
import CancelSubscriptionForm from './CancelSubscriptionForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CancelSubscriptionFormC extends Component {
  render() {
    return (
      <CancelSubscriptionForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionFormC)
