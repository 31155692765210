import './SendEmailFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { SEND_EMAIL_FORM } from 'constants/formConstants'
import validateSendEmailForm from 'utilities/validate/validateSendEmailForm'
import submitSendEmailForm from 'utilities/submit/submitSendEmailForm'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import { SEND } from 'constants/language/languageConstants'

const SendEmailForm = ({
  handleSubmit = () => {},
  submitting = false,
  email = '',
  emailTemplateOptions = [],
  templateFieldValue = '',
}) => {
  return (
    <form className='SendEmailForm' onSubmit={handleSubmit(submitSendEmailForm)}>
      <div className='section'>
        <pair className='flex'>
          <key className='key'>User email address:</key>
          <value className='value'>{email}</value>
        </pair>

        <Field
          name='template'
          label='Template'
          component={SelectField}
          options={emailTemplateOptions}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={submitting || templateFieldValue === ''} label={SEND} />
      </div>
    </form>
  )
}

SendEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  email: PropTypes.string,
  emailTemplateOptions: PropTypes.array,
  templateFieldValue: PropTypes.string,
}

export default reduxForm({
  form: SEND_EMAIL_FORM,
  validate: validateSendEmailForm,
})(SendEmailForm)
