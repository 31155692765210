import { goToPath } from 'state-layer/history'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'
import { PATCH_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import { EDIT_RECIPIENT_IDENTITY } from 'constants/amplitudeConstants'
import { UPDATE_RECIPIENT_CONTACT_INFO_SUCCESS_MSG } from 'constants/language/languageConstants'
import { RECIPIENT_PATH } from 'constants/pathConstants'

const submitEditRecipientIdentityForm = (values, dispatch, props) => {
  const {
    identityId,
    credentials,
    credentialId,
  } = props

  const {
    firstName,
    lastName,
    email,
    phone,
    personalAddress,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
  } = values

  const valuesToSubmit = removeUndefined({
    firstName,
    lastName,
    email,
    phone,
    personalAddress,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
  })

  dispatch({
    type: PATCH_IDENTITY_F_REQUEST,
    payload: {
      id: identityId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: () => {
        goToPath({
          pathname: RECIPIENT_PATH({ credentialId, recipientId: identityId }),
        })
      },
      actionId: sendAmplitudeActionEvent(EDIT_RECIPIENT_IDENTITY, {
        identityId,
        valuesToSubmit,
      }),
      successMessage: UPDATE_RECIPIENT_CONTACT_INFO_SUCCESS_MSG,
    },
  })
}
export default submitEditRecipientIdentityForm
