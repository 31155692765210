import { GET_CURRENT_USAGES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getCurrentUsagesRequest = ({
  entityId,
  currency,
  credentials,
}) => createAction({
  type: GET_CURRENT_USAGES_F_REQUEST,
  queries: {
    linked_to: entityId,
    currency,
  },
  credentials,
  meta: {
    showErrors: false,
  },
})

export default getCurrentUsagesRequest
