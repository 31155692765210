import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTION_PLAN } from 'constants/apiConstants'

const getSubscriptionPlanAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: SUBSCRIPTION_PLAN({ subscriptionPlanId: id }),
    credentials,
    meta,
  })
}

export default getSubscriptionPlanAPI
