import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'

import {
  createdAtRange,
  updatedAtRange,
} from 'utilities/table/sort'

import {
  ID,
  CREATED_ON,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  APPLICATION,
  DEVICE_NAME,
  DESCRIPTION,
  MODEL,
  GATEWAY,
  STATE,
  UPDATED_ON,
  SERIAL_NUMBER,
  ENABLED,
  DISABLED,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchant.name', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: ({ merchant, application }) => {
      const merchantName = get(merchant, 'name')
      const applicationBusinessName = get(application, 'businessName')

      return (
        <TableItemSubtitle
          title={merchantName}
          subtitle={applicationBusinessName}
        />
      )
    },
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(DEVICE_NAME, 'name', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={DEVICE_NAME} subtitle={DESCRIPTION} />,
    formatter: ({ name, description }) => (
      <TableItemSubtitle
        title={name}
        subtitle={description}
      />
    ),
  }),
  column(MODEL, 'displayDeviceModel', { className: 'name' }),
  column(GATEWAY, 'merchant.displayGateway', { className: 'name' }),
  column(STATE, 'displayState', {
    className: 'state',
    formatter: ({ enabled }) => <EnabledStatus value={enabled ? ENABLED : DISABLED} />,
  }),
  column(UPDATED_ON, 'displayUpdatedAt', {
    className: 'date',
    sort: updatedAtRange,
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedAt }) => <DateTimestampColumn timestamp={updatedAt} />,
  }),
  column(SERIAL_NUMBER, 'serialNumber', { className: 'name' }),
]

export default columnDescriptors
