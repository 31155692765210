import './UpdateUserS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CONFIRM,
  CANCEL,
} from 'constants/language/languageConstants'

const UpdateUser = ({
  toggleUser = () => {},
  closeModal = () => {},
  actionType = '',
}) => {
  const message = `Do you want to ${actionType.toLowerCase()}?`

  return (
    <div className='UpdateUser'>
      <div className='section'>
        <div>{message}</div>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={toggleUser} label={CONFIRM} />
      </div>
    </div>
  )
}

UpdateUser.propTypes = {
  toggleUser: PropTypes.func,
  closeModal: PropTypes.func,
  actionType: PropTypes.string,
}

export default UpdateUser
