import './IdentityUploaderConfirmationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  DONE,
  EXPORT, UPLOAD_IDENTITIES,
} from 'constants/language/languageConstants'

const IdentityUploaderConfirmationForm = ({
  cancelIdentityUpload,
  isUploading,
  totalSucceeded = 0,
  totalFailed = 0,
  totalCount = 0,
  confirmationAction,
  closeModal,
  exportCSV,
  message,
}) => {
  const widthPercent = ((totalSucceeded + totalFailed) / totalCount) * 100
  const width = `${widthPercent}%`

  return (
    <div className='IdentityUploaderConfirmationForm'>
      <div className='message'>
        {
          totalCount === 0 ? message : (
            <><strong>{isUploading ? totalCount : totalSucceeded + totalFailed}</strong> identities and payment instruments {isUploading ? 'are being' : 'have been'} processed.</>
          )
        }
      </div>

      <div className='IdentityUploader flex space-between items-center'>
        <div className='IdentityUploaderInfo flex column'>
          <span>Succeeded: <strong>{totalSucceeded}</strong></span>
          <span>Failed: <strong>{totalFailed}</strong></span>
        </div>

        <div className='progressBar'>
          <div className='bar' style={{ width }} />
        </div>
      </div>

      <div className='buttons flex flex-end'>
        { !isUploading && totalCount === 0 && (<Button submitting={isUploading} onClick={confirmationAction} label={UPLOAD_IDENTITIES} />) }
        { isUploading && (<Button onClick={cancelIdentityUpload} label={CANCEL} />) }

        {
          !isUploading && totalCount > 0 && (
            <>
              <Button onClick={exportCSV} label={EXPORT} variant='secondary' />
              <Button onClick={closeModal} label={DONE} />
            </>
          )
        }
      </div>
    </div>
  )
}

IdentityUploaderConfirmationForm.propTypes = {
  cancelIdentityUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  totalSucceeded: PropTypes.number,
  totalFailed: PropTypes.number,
  totalCount: PropTypes.number,
  confirmationAction: PropTypes.func,
  closeModal: PropTypes.func,
  exportCSV: PropTypes.func,
  message: PropTypes.object,
}

export default IdentityUploaderConfirmationForm
