import './SearchUserFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import validateUserEmailForm from 'utilities/validate/validateUserEmailForm'
import submitSearchUserForm from 'utilities/submit/submitSearchUserForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import { SEARCH_USER_FORM } from 'constants/formConstants'

import {
  EMAIL_ADDRESS,
  SEARCH,
} from 'constants/language/languageConstants'

const SearchUserForm = ({
  handleSubmit = () => {},
  submitting = false,
  retryMessage = false,
}) => {
  return (
    <form className='SearchUserForm' onSubmit={handleSubmit(submitSearchUserForm)}>
      <div className='section'>
        { retryMessage && <div>Please verify email and search user again</div> }

        { !retryMessage && <div>Enter email to find user</div>}

        <Field
          name='email'
          label={EMAIL_ADDRESS}
          component={InputField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} label={SEARCH} />
      </div>
    </form>
  )
}

SearchUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  retryMessage: PropTypes.bool,
}

export default reduxForm({
  form: SEARCH_USER_FORM,
  validate: validateUserEmailForm,
})(SearchUserForm)
