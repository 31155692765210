import './FileViewerS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import Button from 'components/Shared/Button/Button'
import { fileStatusMap } from 'constants/statusConstants'

import {
  CREATED_ON,
  FILE_NAME,
  FILE_TYPE,
  FILE_ID,
  STATUS,
  DOWNLOAD,
} from 'constants/language/languageConstants'

const FileViewer = ({
  src = '',
  fileType = '',
  height = '80%',
  width = '100%',
  id = '',
  displayType = '',
  fileName = '',
  displayName = '',
  displayCreatedAt = '',
  isPDF,
  isTIFF,
  pendingUpload = false,
  fileStatus,
  displayStatusLabel,
}) => {
  return (
    <div className='FileViewer'>
      <h6>{displayName}</h6>

      <div className='flex'>
        <div className='title bold'>{CREATED_ON}</div>
        <div className='file-created-at'>{displayCreatedAt}</div>
      </div>

      <div className='flex'>
        <div className='title bold'>{FILE_NAME}</div>
        <div className='file-name'>{displayName}</div>
      </div>

      <div className='flex'>
        <div className='title bold'>{FILE_TYPE}</div>
        <div className='file-type'>{displayType}</div>
      </div>

      <div className='flex'>
        <div className='title bold'>{FILE_ID}</div>
        <div className='file-id'>{id}</div>
      </div>

      <div className='flex'>
        <div className='title bold'>{STATUS}</div>
        <div className='file-id'><ColorcodedStatus value={fileStatus} data={fileStatusMap} customValue={displayStatusLabel} /></div>
      </div>

      {isTIFF && !pendingUpload && (
        <div className='download-tiff-file'>
          <a href={src} download><Button variant='secondary' label={DOWNLOAD} /></a>
        </div>
      )}

      <div className='embed-content mtm'>
        { isPDF && !pendingUpload && (
          <embed
            src={src}
            type={fileType}
            frameBorder='0'
            scrolling='auto'
            width={width}
            height={height}
          />
        )}

        { !isPDF && !pendingUpload && (
          <img src={src} alt={fileName} width='100%' />
        )}
      </div>
    </div>
  )
}

FileViewer.propTypes = {
  src: PropTypes.string,
  fileType: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  id: PropTypes.string,
  displayType: PropTypes.string,
  fileName: PropTypes.string,
  displayName: PropTypes.string,
  displayCreatedAt: PropTypes.string,
  isPDF: PropTypes.bool,
  isTIFF: PropTypes.bool,
  pendingUpload: PropTypes.bool,
  fileStatus: PropTypes.string,
  displayStatusLabel: PropTypes.string,
}

export default FileViewer
