import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_FEE_PROFILE_RULES_F_REQUEST } from 'constants/flowConstants'
import { CREATE_FEE_PROFILE_RULES } from 'constants/amplitudeConstants'

const postDefaultFeeProfileRulesRequest = ({
  id,
  credentials,
}) => createAction({
  type: POST_FEE_PROFILE_RULES_F_REQUEST,
  id,
  values: {
    blended: {
      CAD: {},
      USD: {
        achBasisPointsMax: '20',
        achBasisPointsMin: '0',
        achCreditReturnFixedFeeMax: '30',
        achCreditReturnFixedFeeMin: null,
        achDebitReturnFixedFeeMax: '30',
        achDebitReturnFixedFeeMin: null,
        achFixedFeeMax: '10',
        achFixedFeeMin: '0',
        allowCardBrandOverrides: true,
        allowChargebackFees: true,
        americanExpressAssessmentBasisPointsMin: null,
        americanExpressAssessmentBasisPointsMax: null,
        americanExpressBasisPointsMax: '5',
        americanExpressBasisPointsMin: null,
        americanExpressFixedFeeMax: '.5',
        americanExpressFixedFeeMin: null,
        americanExpressExternallyFundedBasisPointsMin: null,
        americanExpressExternallyFundedBasisPointsMax: null,
        americanExpressExternallyFundedFixedFeeMin: null,
        americanExpressExternallyFundedFixedFeeMax: null,
        ancillaryFixedFeePrimaryMax: '5',
        ancillaryFixedFeePrimaryMin: null,
        ancillaryFixedFeeSecondaryMax: '5',
        ancillaryFixedFeeSecondaryMin: null,
        basisPointsMax: '5',
        basisPointsMin: '0',
        cardCrossBorderBasisPointsMax: '2',
        cardCrossBorderBasisPointsMin: null,
        cardCrossBorderFixedFeeMax: '.5',
        cardCrossBorderFixedFeeMin: null,
        dinersClubBasisPointsMax: '5',
        dinersClubBasisPointsMin: null,
        dinersClubFixedFeeMax: '.5',
        dinersClubFixedFeeMin: null,
        discoverAssessmentBasisPointsMin: null,
        discoverAssessmentBasisPointsMax: null,
        discoverBasisPointsMax: '5',
        discoverBasisPointsMin: null,
        discoverFixedFeeMax: '.5',
        discoverFixedFeeMin: null,
        discoverDataUsageFixedFeeMin: null,
        discoverDataUsageFixedFeeMax: null,
        disputeFixedFeeMax: '40',
        disputeFixedFeeMin: null,
        disputeInquiryFixedFeeMax: '40',
        disputeInquiryFixedFeeMin: null,
        fixedFeeMax: '.5',
        fixedFeeMin: '0',
        jcbBasisPointsMax: '5',
        jcbBasisPointsMin: null,
        jcbFixedFeeMax: '.5',
        jcbFixedFeeMin: null,
        mastercardBasisPointsMax: '5',
        mastercardBasisPointsMin: null,
        mastercardFixedFeeMax: '.5',
        mastercardFixedFeeMin: null,
        open: true,
        visaBasisPointsMax: '5',
        visaBasisPointsMin: null,
        visaFixedFeeMax: '.5',
        visaFixedFeeMin: null,
      },
    },
    'interchange-plus': {
      CAD: {},
      USD: {
        achBasisPointsMax: '20',
        achBasisPointsMin: '0',
        achCreditReturnFixedFeeMax: '30',
        achCreditReturnFixedFeeMin: null,
        achDebitReturnFixedFeeMax: '30',
        achDebitReturnFixedFeeMin: null,
        achFixedFeeMax: '10',
        achFixedFeeMin: '0',
        allowCardBrandOverrides: true,
        allowChargebackFees: true,
        americanExpressAssessmentBasisPointsMin: null,
        americanExpressAssessmentBasisPointsMax: '1',
        americanExpressBasisPointsMax: '1',
        americanExpressBasisPointsMin: null,
        americanExpressFixedFeeMax: null,
        americanExpressFixedFeeMin: null,
        americanExpressExternallyFundedBasisPointsMin: null,
        americanExpressExternallyFundedBasisPointsMax: null,
        americanExpressExternallyFundedFixedFeeMin: null,
        americanExpressExternallyFundedFixedFeeMax: null,
        ancillaryFixedFeePrimaryMax: '5',
        ancillaryFixedFeePrimaryMin: null,
        ancillaryFixedFeeSecondaryMax: '5',
        ancillaryFixedFeeSecondaryMin: null,
        basisPointsMax: '1',
        basisPointsMin: '0',
        cardCrossBorderBasisPointsMax: '1',
        cardCrossBorderBasisPointsMin: null,
        cardCrossBorderFixedFeeMax: '.5',
        cardCrossBorderFixedFeeMin: null,
        dinersClubBasisPointsMax: '1',
        dinersClubBasisPointsMin: null,
        dinersClubFixedFeeMax: null,
        dinersClubFixedFeeMin: null,
        discoverAssessmentBasisPointsMin: null,
        discoverAssessmentBasisPointsMax: '1',
        discoverBasisPointsMax: '1',
        discoverBasisPointsMin: null,
        discoverFixedFeeMax: null,
        discoverFixedFeeMin: null,
        discoverDataUsageFixedFeeMin: null,
        discoverDataUsageFixedFeeMax: '1',
        discoverNetworkAuthorizationFixedFeeMin: null,
        discoverNetworkAuthorizationFixedFeeMax: '1',
        disputeFixedFeeMax: '40',
        disputeFixedFeeMin: null,
        disputeInquiryFixedFeeMax: '40',
        disputeInquiryFixedFeeMin: null,
        fixedFeeMax: '.5',
        fixedFeeMin: '0',
        jcbBasisPointsMax: '1',
        jcbBasisPointsMin: null,
        jcbFixedFeeMax: null,
        jcbFixedFeeMin: null,
        mastercardBasisPointsMax: '1',
        mastercardBasisPointsMin: null,
        mastercardFixedFeeMax: null,
        mastercardFixedFeeMin: null,
        mastercardAcquirerFeesBasisPointsMin: null,
        mastercardAcquirerFeesBasisPointsMax: '1',
        mastercardAssessmentsOver1kBasisPointsMin: null,
        mastercardAssessmentsOver1kBasisPointsMax: '1',
        mastercardAssessmentsUnder1kBasisPointsMin: null,
        mastercardAssessmentsUnder1kBasisPointsMax: '1',
        open: true,
        visaAcquirerProcessingFixedFeeMin: null,
        visaAcquirerProcessingFixedFeeMax: '1',
        visaAssessmentsBasisPointsMin: null,
        visaAssessmentsBasisPointsMax: '1',
        visaBaseIICreditVoucherFixedFeeMin: null,
        visaBaseIICreditVoucherFixedFeeMax: null,
        visaBaseIISystemFileTransmissionFixedFeeMin: null,
        visaBaseIISystemFileTransmissionFixedFeeMax: '1',
        visaCreditVoucherFixedFeeMin: null,
        visaCreditVoucherFixedFeeMax: '1',
        visaKilobyteAccessFixedFeeMin: null,
        visaKilobyteAccessFixedFeeMax: '1',
        visaBasisPointsMax: '1',
        visaBasisPointsMin: null,
        visaFixedFeeMax: null,
        visaFixedFeeMin: null,
      },
    },
  },
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_FEE_PROFILE_RULES, {
      credentials,
      entityId: id,
    }),
  },
})

export default postDefaultFeeProfileRulesRequest
