import { SETTINGS } from 'constants/flowConstants'

import {
  SANDBOX_ENVIRONMENT,
  QA_ENVIRONMENT,
  LIVE_ENVIRONMENT,
} from 'constants/environmentConstants'

import {
  TRANSFERS,
  AUTHORIZATIONS,
  SETTLEMENTS,
  DISPUTES,
  REPORTS,
  IDENTITIES,
  APPLICATIONS,
  MERCHANTS,
  UNDERWRITING,
} from 'constants/linkConstants'

// MAIN NAV ICONS
export const OVERVIEW_ICON = 'chart-bar'
// export const PAYMENTS_ICON = 'money-bill'
export const PAYOUTS_ICON = 'book'
export const AUTHORIZATIONS_ICON = 'lock'
export const SETTLEMENTS_ICON = 'book'
export const REFUNDS_ICON = 'undo'
export const BUYER_PAYMENT_INSTRUMENTS_ICON = 'file-invoice'
export const FEES_ICON = 'coin'
export const BALANCE_TRANSFERS_ICON = 'balance-scale-left'
export const DEPOSITS_ICON = 'money-bill'
export const EXCEPTIONS_ICON = 'exclamation'
export const ACH_RETURNS_ICON = 'money-check'
export const REPORTS_ICON = 'chart-bar'
export const DEVELOPER_ICON = 'tools'
export const EXPORTS_ICON = 'file-download'
export const COMPANY_ICON = 'store'
export const MERCHANTS_ICON = 'address-card'
export const CLIPBOARD_CHECK_ICON = 'clipboard-check'
export const COMPLIANCE_FORMS_ICON = 'clipboard-check'
export const APPLICATIONS_ICON = 'list-alt'
export const PAYMENTS_ICON = 'credit-card'
export const USERS_ICON = 'user'
export const CONFIGURATIONS_ICON = 'cogs'
export const CONFIGURATION_ICON = 'cog'
export const EMAILS_ICON = 'envelope'
export const UPDATE_TRANSFERS_ICON = 'comment-dollar'
export const UPDATE_VERIFICATIONS_ICON = 'check-square'
export const PLATFORMS_ICON = 'microchip'
export const IDENTITY_UPLOADER_ICON = 'upload'
export const PAYOUT_PLANS_ICON = 'money-check-alt'
export const SUBSCRIPTION_BILLING_ICON = 'usd-circle'
export const SEND_MONEY_ICON = 'hand-holding-usd'
export const UNDERWRITING_ICON = 'list'
export const CHECK_CIRCLE_ICON = 'check-circle'
export const TIMES_CIRCLE_ICON = 'times-circle'
export const ADDITIONAL_VERIFICATION_NEEDED_ICON = 'file-invoice'
export const WITHDRAW_ICON = 'reply'
export const LAPTOP_ICON = 'laptop'
export const ADMIN_ICON = 'user-cog'
export const HOME_ICON = 'house'
export const EXPORT_ICON = 'file-export'
export const SANDBOX_ICON = 'cube'
export const QA_ICON = 'clipboard-list-check'
export const LIVE_ICON = 'signal-stream'
export const API_LOGS_ICON = 'ellipsis-vertical'
export const COPY = 'copy'
export const EXTERNAL_LINK = 'external-link'
export const FILE_SIGNATURE = 'file-signature'
export const CALENDAR = 'calendar-alt'
export const HAND_HOLDING_USD = 'hand-holding-usd'
export const STORE_ICON = 'store'
export const RETURNED_ICON = 'rotate-left'
export const RECURRING_BILLING_ICON = 'file-invoice-dollar'

// CARD BRAND ICONS
export const VISA_ICON = 'visa'
export const MASTERCARD_ICON = 'mastercard'
export const DISCOVER_ICON = 'discover'
export const DINERS_CLUB_ICON = 'diners-club'
export const JCB_ICON = 'jcb'
export const AMERICAN_EXPRESS_ICON = 'amex'
export const APPLE_PAY_ICON = 'apple-pay'
export const GOOGLE_PAY_ICON = 'google-pay'
export const AMAZON_PAY_ICON = 'amazon-pay'
export const PAYPAL_ICON = 'paypal'

// USER MENU ICONS
export const LOGOUT_ICON = 'fas fa-sign-out'
export const SWITCH_ACCOUNTS_ICON = 'far fa-random'

export const GROUP_ICON = 'users'
export const LINK_ICON = 'link'
export const ADD_ICON = 'plus'
export const CHECK_ICON = 'check'
export const REMOVE_ICON = 'times'
export const BANK_ICON = 'university'
export const TRASH_ICON = 'trash'
export const TRASH_ALT_ICON = 'trash-alt'
export const FILE_ICON = 'file'
export const CSV_FILE_ICON = 'file-csv'
export const IMAGE_FILE_ICON = 'file-image'
export const CODE_FILE_ICON = 'file-code'
export const UPLOAD_ICON = 'cloud-upload'
export const FILE_UPLOAD_ICON = 'upload'
export const ANGLE_DOWN_ICON = 'angle-down'
export const ANGLE_UP_ICON = 'angle-up'
export const ANGLE_RIGHT = 'angle-right'
export const CHEVRON_UP_ICON = 'chevron-up'
export const CHEVRON_DOWN_ICON = 'chevron-down'
export const LOAD_ICON = 'spinner'
export const LOAD_ICON_V2 = 'circle-notch'
export const EDIT_ICON = 'edit'
export const TOOLTIP_ICON = 'info-circle'
export const SEARCH_ICON = 'search'
export const HELP_ICON = 'question-circle'
export const NOTIFICATIONS_ICON = 'bell'
export const CREATE_APPLICATION_ICON = 'building'
export const TAG_ICON = 'tag'
export const ALERT_ICON = 'exclamation-triangle'
export const ALERT_CIRCLE_ICON = 'exclamation-circle'
export const FILTER_ICON = 'filter'
export const SCROLL_ICON = 'sort-amount-down'
export const PAGINATION_ICON = 'page-break'
export const BACKWARD_ICON = 'angle-double-left'
export const PREV_ICON = 'angle-left'
export const NEXT_ICON = 'angle-right'
export const FORWARD_ICON = 'angle-double-right'
export const CIRCLE_ICON = 'circle'
export const NEXT_ARROW_ICON = 'arrow-right'
export const PREV_ARROW_ICON = 'arrow-left'
export const SEND_ICON = 'paper-plane'
export const ANGLE_LEFT_ICON = 'angle-left'
export const ANGLE_RIGHT_ICON = 'angle-right'
export const DOWNLOAD_ICON = 'download'
export const MOBILE_ICON = 'mobile'
export const FILE_CERT_ICON = 'file-certificate'
export const DO_NOT_ENTER = 'do-not-enter'
export const PLUS_LIGHT = 'fal fa-plus'
export const EDIT_LIGHT = 'fal fa-edit'
export const USER_LIGHT = 'fal fa-user'
export const ENVELOPE_LIGHT = 'fal a-envelope'
export const MINUS_LIGHT = 'fal fa-minus'
export const CIRCLE_DOWNLOAD_ICON = 'arrow-circle-down'
export const ARROW_DOWN_TO_LINE_ICON = 'arrow-to-bottom'
export const LONG_ARROW_ALT_LEFT = 'fa-long-arrow-alt-left'
export const LONG_ARROW_RIGHT = 'long-arrow-right'
export const REDO = 'far fa-redo'
export const ARROW_ALT_LEFT_ICON = 'arrow-alt-left'
export const TIMES_OCTAGON = 'times-octagon'
export const ELLIPSIS_ICON = 'fa fa-ellipsis-v'
export const FILE_CHECK = 'file-check'
export const CIRCLE_MINUS_ICON = 'fa-minus-circle'
export const DOT_CIRCLE = 'fa-dot-circle'
export const CLOCK_ICON = 'fa-clock'
export const HOURGLASS_ICON = 'hourglass-half'
export const PERCENTAGE_ICON = 'percent'
export const SMS_ICON = 'sms'
export const PIGGY_BANK_ICON = 'piggy-bank'
export const FAX_ICON = 'fax'
export const HOURGLASS_END_ICON = 'fa-hourglass-end'
export const SHIELD_ICON = 'shield-alt'
export const LIGHTNING_BOLT_ICON = 'fas fa-bolt'

export const RESOURCE_ICON_MAP = {
  PAYMENTS: PAYMENTS_ICON,
  PAYOUTS: PAYOUTS_ICON,
  AUTHORIZATIONS: AUTHORIZATIONS_ICON,
  SETTLEMENTS: SETTLEMENTS_ICON,
  DISPUTES: EXCEPTIONS_ICON,
  REPORTS: REPORTS_ICON,
  IDENTITIES: MERCHANTS_ICON,
  APPLICATIONS: APPLICATIONS_ICON,
  UNDERWRITING: UNDERWRITING_ICON,
  MERCHANTS: MERCHANTS_ICON,
  SETTINGS: CONFIGURATIONS_ICON,
  USERS: USERS_ICON,
  PLATFORMS: PLATFORMS_ICON,
  // TODO: add logic to find a way to put multiple word resource name in constant to map to
  'PAYMENT INSTRUMENTS': PAYMENTS_ICON,
  'SUBSCRIPTION SCHEDULES': SUBSCRIPTION_BILLING_ICON,
  'PAYOUT PLANS': PAYOUT_PLANS_ICON,
}

export const ENVIRONMENT_ICON_MAP = {
  [SANDBOX_ENVIRONMENT]: SANDBOX_ICON,
  [QA_ENVIRONMENT]: QA_ICON,
  [LIVE_ENVIRONMENT]: LIVE_ICON,
}
