import { EMAIL_REGEX } from 'constants/regexConstants'

import {
  EMAIL_ADDRESS,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  validateDate,
  validateTaxId,
  validateAddress,
  validatePercentage,
} from 'utilities/validate'

const validateIdentityForm = (values) => {
  const {
    title,
    firstName,
    lastName,
    email,
    phone,
    principalPercentageOwnership,
    personalAddress,
    dateOfBirth,
    ssn,
    ownershipType,
  } = values

  const principalPercentageOwnershipErrors = validatePercentage({ field: principalPercentageOwnership, name: 'Principal Percentage Ownership' })

  return {
    title: fieldEmpty(title, 'Title'),
    firstName: fieldEmpty(firstName, 'First Name'),
    lastName: fieldEmpty(lastName, 'Last Name'),
    email: invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
    phone: fieldEmpty(phone, 'Phone'),
    personalAddress: validateAddress({ address: personalAddress }),
    dateOfBirth: validateDate({ date: dateOfBirth }),
    principalPercentageOwnership: principalPercentageOwnershipErrors,
    ownershipType: fieldEmpty(ownershipType, 'Ownership Type'),
    ssn: validateTaxId({ taxId: ssn, name: SOCIAL_SECURITY_NUMBER }),
  }
}

export default validateIdentityForm
