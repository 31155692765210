import { POST_ADMIN_USER_F_REQUEST } from 'constants/flowConstants'
import { ADMIN_USER_PATH } from 'constants/pathConstants'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import redirectRequest from 'utilities/actions/redirectRequest'
import keys from 'lodash/keys'
import head from 'lodash/head'

const submitUserForm = (values, dispatch) => {
  const { email } = values

  const emailPayload = isDashboardServiceV2() ? {
    email_address: email,
    dashboard_id: getCurrentDashboardId(),
  } : {
    type: 'email-address',
    properties: {
      email_address: email,
    },
  }

  dispatch({
    type: POST_ADMIN_USER_F_REQUEST,
    payload: {
      values: emailPayload,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const userId = head(keys(newValues))
        const userPath = ADMIN_USER_PATH({ userId })

        dispatch(redirectRequest({ path: userPath }))
      },
    },
  })
}

export default submitUserForm
