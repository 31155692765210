import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import getMany from 'utilities/get/getMany'
import { DASHBOARD_GUEST_ACCESS_FORM_FILE_LINKS } from 'constants/apiConstants'

const postGuestAccessFormFileExternalLinksAPI = ({ id, values, meta }) => {
  const [
    accessFormId,
    type,
    duration,
  ] = getMany(values, [
    'accessFormId',
    'type',
    'duration',
  ])

  return guestDashboardAPI.post({
    meta,
    path: DASHBOARD_GUEST_ACCESS_FORM_FILE_LINKS({ fileId: id, accessFormId }),
    values: { type, duration },
  })
}

export default postGuestAccessFormFileExternalLinksAPI
