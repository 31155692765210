import frontendTransferAttemptModel from 'utilities/create/models/frontendTransferAttemptModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendTransferAttemptsModel = ({ data: transferAttempts }) => {
  const transferAttemptsModel = map(transferAttempts, (transferAttempt) => frontendTransferAttemptModel({ data: transferAttempt }))

  return keyBy(transferAttemptsModel, 'id')
}

export default frontendTransferAttemptsModel
