import { POST_DASHBOARD_USER_MFA_ASSOCIATE_F_REQUEST } from 'constants/flowConstants'

const postDashboardUserMFAAssociateRequest = ({
  token,
  values,
  successCallback = () => {},
}) => {
  return {
    type: POST_DASHBOARD_USER_MFA_ASSOCIATE_F_REQUEST,
    payload: {
      values,
    },
    meta: {
      customHeaders: {
        'X-Mfa-Token': token,
      },
      successCallback,
    },
  }
}

export default postDashboardUserMFAAssociateRequest
