// TODO: unit test
import { GET_SPLIT_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSplitTransferRequest = ({
  splitTransferId,
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_SPLIT_TRANSFER_F_REQUEST,
  id: splitTransferId,
  credentials,
  queries,
  meta,
})

export default getSplitTransferRequest
