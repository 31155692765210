import { EMAIL_REGEX } from 'constants/regexConstants'

import {
  fieldEmpty,
} from 'utilities/validate'

const validateUpdateAllPermissionsForm = ({
  access,
}) => {
  return {
    access: fieldEmpty(access, 'Access'),
  }
}

export default validateUpdateAllPermissionsForm
