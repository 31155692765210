import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYOUT_PLANS,
  SETTLEMENTS,
} from 'constants/apiConstants'

const getPayoutPlansAPI = ({ queries, credentials }) => {
  return paymentsAPI.get({
    path: PAYOUT_PLANS,
    queries,
    credentials,
    service: SETTLEMENTS,
  })
}

export default getPayoutPlansAPI
