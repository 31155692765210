import './UpdateVerificationsS.scss'
import React from 'react'
import UpdateAdminToolsFormC from 'components/Customer/Forms/UpdateAdminToolsForm/UpdateAdminToolsFormC'
import selectOption from 'utilities/forms/selectOption'
import { UPDATE_VERIFICATIONS_FORM } from 'constants/formConstants'
import { PATCH_VERIFICATIONS_UPDATE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import head from 'lodash/head'

import {
  SUBMITTED,
  STAGED,
} from 'constants/stateConstants'

import {
  FAILED,
  SUCCEEDED,
} from 'constants/transferConstants'


const verificationStateOptions = [
  selectOption('Submitted', SUBMITTED),
  selectOption('Staged', STAGED),
]

const verificationCodeOptions = [
  selectOption('Failed', FAILED),
  selectOption('Succeeded', SUCCEEDED),
]

const initialValues = {
  state: get(head(verificationStateOptions), 'value'),
  code: get(head(verificationCodeOptions), 'value'),
}

const UpdateVerifications = () => {
  return (
    <div className='UpdateVerifications'>
      <div className='content-header flex space-between'>
        <h1>Update Verification(s)</h1>
      </div>

      <div className='content-card'>
        <UpdateAdminToolsFormC
          title='Verification'
          adminOperationKey='VERIFICATION_UPDATE'
          actionType={PATCH_VERIFICATIONS_UPDATE_F_REQUEST}
          form={UPDATE_VERIFICATIONS_FORM}
          initialValues={initialValues}
          stateOptions={verificationStateOptions}
          codeOptions={verificationCodeOptions}
          hideType
        />
      </div>
    </div>
  )
}

export default UpdateVerifications
