import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitInvalidateComplianceForm from 'utilities/submit/submitInvalidateComplianceForm'
import { DEACTIVATE_DEVICE_FORM } from 'constants/formConstants'

import {
  CANCEL,
  INVALIDATE,
  THIS_ACTION_CANNOT_BE_UNDONE,
} from 'constants/language/languageConstants'

const InvalidateComplianceForm = ({
  handleSubmit = () => {},
  closeModal,
  invalid,
  isUpdating = false,
}) => {
  return (
    <form className='InvalidateComplianceForm' onSubmit={handleSubmit(submitInvalidateComplianceForm)}>
      <div className='modal-content'>
        <div>{THIS_ACTION_CANNOT_BE_UNDONE}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} submitting={isUpdating} variant='destructive' label={INVALIDATE} className='submit-button' />
      </div>
    </form>
  )
}

InvalidateComplianceForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default reduxForm({
  form: DEACTIVATE_DEVICE_FORM,
})(InvalidateComplianceForm)
