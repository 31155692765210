import './DisputeReviewS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import DisputeCaseDetailsC from 'components/Customer/Shared/Display/DisputeCaseDetails/DisputeCaseDetailsC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import { adjustmentTransfersColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import { PATCH_DISPUTE_F_REQUEST } from 'constants/flowConstants'
import isEmpty from 'lodash/isEmpty'

import {
  DISPUTE_DETAILS,
  ORIGINAL_PAYMENT,
  BUYER_DETAILS,
  ADJUSTMENTS,
  PLATFORM_ADJUSTMENTS,
} from 'constants/language/languageConstants'

const DisputeReview = ({
  isFetching = false,
  dispute = {},
  isVantivDispute = false,
  disputeDetailsSection = [],
  parentPaymentClickToCopies = [],
  parentPaymentSection = [],
  parentPaymentLink,
  paymentsPath = '',
  isRolePlatform = false,
  isRoleMerchant = false,
  buyerIdentity = {},
  paymentInstrument = {},
  merchantAdjustmentTransfers = [],
  platformAdjustmentTransfers = [],
  tagsSectionData = [],
}) => {
  return (
    <div className='DisputeReview'>
      <DisputeCaseDetailsC
        dispute={dispute}
        isFetching={isFetching}
        isVantivDispute={isVantivDispute}
      />

      <PageSectionV2C
        isFetching={isFetching}
        title={DISPUTE_DETAILS}
        columns={disputeDetailsSection}
      />

      <PageSectionV2C
        isFetching={isFetching}
        title={ORIGINAL_PAYMENT}
        clickToCopies={parentPaymentClickToCopies}
        columns={parentPaymentSection}
        asideTitle={parentPaymentLink}
      />

      {!isRoleMerchant && (
        <BuyerDetailsC
          title={BUYER_DETAILS}
          buyerIdentity={buyerIdentity}
          isFetching={isFetching}
        />
      )}

      {!isEmpty(paymentInstrument) && !isRoleMerchant && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          paymentInstrument={paymentInstrument}
        />
      )}

      { isFetching && <Loader />}
      { !isFetching && (
      <TableC
        title={ADJUSTMENTS}
        data={merchantAdjustmentTransfers}
        columnDescriptors={adjustmentTransfersColumnDescriptors}
        classNames='Adjustment-Transfers-Table'
        path={paymentsPath}
        showTableFooter={false}
      />
      )}

      { isFetching && <Loader />}
      { isRolePlatform && !isFetching && (
        <TableC
          title={PLATFORM_ADJUSTMENTS}
          data={platformAdjustmentTransfers}
          columnDescriptors={adjustmentTransfersColumnDescriptors}
          classNames='Platform-Adjustment-Transfers-Table'
          path={paymentsPath}
          showTableFooter={false}
        />
      )}

      <TagsPageSectionV2
        isFetching={isFetching}
        data={tagsSectionData}
        actionType={PATCH_DISPUTE_F_REQUEST}
        record={dispute}
      />
    </div>
  )
}

DisputeReview.propTypes = {
  dispute: PropTypes.object,
  isFetching: PropTypes.bool,
  isVantivDispute: PropTypes.bool,
  disputeDetailsSection: PropTypes.array,
  parentPaymentClickToCopies: PropTypes.array,
  parentPaymentSection: PropTypes.array,
  parentPaymentLink: PropTypes.object,
  isRolePlatform: PropTypes.bool,
  isRoleMerchant: PropTypes.bool,
  buyerIdentity: PropTypes.object,
  paymentInstrument: PropTypes.object,
  merchantAdjustmentTransfers: PropTypes.array,
  platformAdjustmentTransfers: PropTypes.array,
}

export default DisputeReview
