import './GatewayAdminToolsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const GatewayAdminTools = ({
  children,
  isAdmin,
  redirectPath,
}) => {
  if (!isAdmin) {
    return (
      <div className='GatewayAdminTools no-access'>
        <h3>You are not an administrator</h3>
        <Link to={redirectPath}>
          Back to Dashboard
        </Link>
      </div>
    )
  }

  return (
    <div className='GatewayAdminTools'>
      {children}
    </div>
  )
}

GatewayAdminTools.propTypes = {
  children: PropTypes.object,
  isAdmin: PropTypes.bool,
  redirectPath: PropTypes.string,
}

export default GatewayAdminTools
