import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_ADJUSTMENT } from 'constants/apiConstants'

const getBalanceAdjustmentAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: BALANCE_ADJUSTMENT({ balanceAdjustmentId: id }),
    credentials,
  })
}

export default getBalanceAdjustmentAPI
