import './AuditLogEventS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import APIResponse from 'components/Customer/Shared/Display/APIResponse/APIResponse'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import getAuditLogEventAPI from 'api/finix/get/getAuditLogEventAPI'
import { IDENTITY_UPDATED } from 'constants/AuditLogEventConstants'

import {
  WORKFLOW,
  EVENT_RESPONSE_BODY,
  RESOURCE_DETAILS,
  UPDATE_ACTIVITY,
} from 'constants/language/languageConstants'

const AuditLogEvent = ({
  detailsDataSection = [],
  isFetching = false,
  headerData = {},
  contextBarData = {},
  subjectId = '',
  credentials = {},
  auditLogEventId = '',
  detailSectionClickToCopies = [],
  workflowSectionClickToCopies = [],
  workflowDataSection = [],
  showWorkflowSection = false,
  type = '',
  updateActivityDataSection = [],
}) => {
  return (
    <div className='AuditLogEvent'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        isFetching={isFetching}
        {...headerData}
      />

      { type === IDENTITY_UPDATED && (
        <PageSectionV2C
          title={UPDATE_ACTIVITY}
          columns={updateActivityDataSection}
          isFetching={isFetching}
        />
      )}

      <PageSectionV2C
        title={RESOURCE_DETAILS}
        columns={detailsDataSection}
        isFetching={isFetching}
        clickToCopies={detailSectionClickToCopies}
      />

      {showWorkflowSection && (
      <PageSectionV2C
        title={WORKFLOW}
        columns={workflowDataSection}
        isFetching={isFetching}
        clickToCopies={workflowSectionClickToCopies}
      />
      )}

      <PageSectionV2C
        title={EVENT_RESPONSE_BODY}
      >
        <APIResponse
          api={getAuditLogEventAPI}
          apiProps={{ id: auditLogEventId, credentials, meta: { subjectId } }}
        />
      </PageSectionV2C>
    </div>
  )
}

AuditLogEvent.propTypes = {
  detailsDataSection: PropTypes.array,
  isFetching: PropTypes.bool,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  subjectId: PropTypes.string,
  credentials: PropTypes.object,
  auditLogEventId: PropTypes.string,
  workflowSectionClickToCopies: PropTypes.array,
  workflowDataSection: PropTypes.array,
  showWorkflowSection: PropTypes.bool,
  detailSectionClickToCopies: PropTypes.array,
  type: PropTypes.string,
  updateActivityDataSection: PropTypes.array,
}

export default AuditLogEvent
