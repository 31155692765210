import createAction from 'utilities/actions/createAction'
import { SHOW_HIGHLIGHT } from 'constants/flowConstants'
import { sendAmplitudeActionEvent } from '../amplitude'
import { SHOW_DASHBOARD_TUTORIAL } from 'constants/amplitudeConstants'

const showHighlightsActionRequest = ({
  highlights,
  credentials,
}) => {
  return createAction({
    type: SHOW_HIGHLIGHT,
    values: highlights,
    meta: {
      actionId: sendAmplitudeActionEvent(SHOW_DASHBOARD_TUTORIAL, {
        credentials,
      }),
    },
  })
}

export default showHighlightsActionRequest
