import SelfServiceSignup from './SelfServiceSignup'
import React, { Component, createRef } from 'react'
import { change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import redirectRequest from 'utilities/actions/redirectRequest'
import { DASHBOARD_PATH } from 'constants/pathConstants'
import { SELF_SERVICE_SIGNUP_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'
import { FINIX_PRIVACY_POLICY_URL } from 'constants/urlConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  HAVE_8_TO_20_CHARACTERS_REGEX,
  HAVE_ONE_LOWERCASE_REGEX,
  HAVE_ONE_NUMBER_REGEX,
  HAVE_ONE_SPECIAL_CHARACTER_REGEX,
  HAVE_ONE_UPPERCASE_REGEX,
} from 'constants/regexConstants'

import {
  HAVE_8_TO_20_CHARACTERS,
  HAVE_ONE_LOWERCASE,
  HAVE_ONE_NUMBER,
  HAVE_ONE_SPECIAL_CHARACTER,
  HAVE_ONE_UPPERCASE,
} from 'constants/language/languageConstants'

const passwordRequirements = [
  {
    title: HAVE_8_TO_20_CHARACTERS,
    regex: HAVE_8_TO_20_CHARACTERS_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_LOWERCASE,
    regex: HAVE_ONE_LOWERCASE_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_UPPERCASE,
    regex: HAVE_ONE_UPPERCASE_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_NUMBER,
    regex: HAVE_ONE_NUMBER_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_SPECIAL_CHARACTER,
    regex: HAVE_ONE_SPECIAL_CHARACTER_REGEX,
    passed: false,
  },
]

const mapStateToProps = (state) => {
  const hCaptchaFormError = !!get(state, ['form', SELF_SERVICE_SIGNUP_FORM, 'syncErrors', 'hCaptchaToken']) && !!get(state, ['form', SELF_SERVICE_SIGNUP_FORM, 'submitFailed'])
  const formSelector = formValueSelector(SELF_SERVICE_SIGNUP_FORM)
  const selectedCountry = formSelector(state, 'country')
  const checkboxLabel = <div>Opt in to receive product and update emails from Finix. You may withdraw your consent at any time, refer to our <a className='text-link' href={FINIX_PRIVACY_POLICY_URL} target='blank'>privacy policy</a> for more details.</div>

  return {
    hCaptchaFormError,
    selectedCountry,
    checkboxLabel,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirectLoggedInUser: () => { dispatch(redirectRequest({ path: DASHBOARD_PATH })) },
    updateSignupFormWithToken: (token) => { dispatch(change(SELF_SERVICE_SIGNUP_FORM, 'hCaptchaToken', token)) },
    uncheckMarketingCheckbox: () => dispatch(change(SELF_SERVICE_SIGNUP_FORM, 'marketingCheckbox', false)),
  }
}

class SelfServiceSignupC extends Component {
  constructor(props) {
    super(props)

    const captchaRef = createRef()

    this.state = {
      captchaRef,
    }
  }

  componentDidMount() {
    const { redirectLoggedInUser } = this.props
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

    if (isLoggedIn) {
      redirectLoggedInUser()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedCountry,
      uncheckMarketingCheckbox,
    } = this.props

    const {
      selectedCountry: prevSelectedCountry,
    } = prevProps

    if (!isEqual(prevSelectedCountry, selectedCountry) && !isEqual(selectedCountry, USA)) {
      uncheckMarketingCheckbox()
    }
  }

  onVerifyCaptcha = (token) => {
    const { updateSignupFormWithToken } = this.props
    updateSignupFormWithToken(token)
  }

  render () {
    const { captchaRef } = this.state
    return (
      <SelfServiceSignup
        {...this.props}
        passwordRequirements={passwordRequirements}
        onVerifyCaptcha={this.onVerifyCaptcha}
        captchaRef={captchaRef}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelfServiceSignupC)
