import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  PLATFORMS,
  PLATFORM_PROCESSOR_CONFIG,
} from 'constants/apiConstants'

const getPlatformProcessorConfigAPI = ({ id, values, credentials, meta }) => {
  const { processorConfigId } = values

  return paymentsAPI.get({
    meta,
    path: PLATFORM_PROCESSOR_CONFIG({ platformId: id, processorConfigId }),
    credentials,
    service: PLATFORMS,
  })
}

export default getPlatformProcessorConfigAPI
