import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidRegex,
  validateAddress,
} from 'utilities/validate'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE,
  PHONE_NUMBER_LENGTH_VALIDATION_ERROR,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
} from 'constants/language/languageConstants'

const validateEditRecipientIdentityContactForm = (values) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    personalAddress,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
  } = values

  return {
    firstName: fieldEmpty(firstName, FIRST_NAME),
    lastName: fieldEmpty(lastName, LAST_NAME),
    email: invalidRegex({ field: email, name: EMAIL, regex: EMAIL_REGEX }),
    phone: invalidRegex({ field: phone, name: PHONE, regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
    personalAddress: validateAddress({ address: personalAddress }),
    businessName: fieldEmpty(businessName, BUSINESS_NAME),
    doingBusinessAs: fieldEmpty(doingBusinessAs, DOING_BUSINESS_AS),
    businessPhone: invalidRegex({ field: businessPhone, name: BUSINESS_PHONE, regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
    businessAddress: validateAddress({ address: businessAddress }),
  }
}

export default validateEditRecipientIdentityContactForm
