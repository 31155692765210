import './SubscriptionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'

import {
  BUYER_DETAILS,
  DETAILS,
  OPTIONAL_ADD_ONS,
  PLAN_DETAILS,
  PLAN_DETAILS_EMPTY_MESSAGE,
  SUBSCRIPTION_PLANS_NO_ADD_ONS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const Subscription = ({
  isFetching = false,
  actions = [],
  headerData = {},
  contextBarData = {},
  subscriptionDetails = [],
  planDetails = [],
  planDetailsClickToCopies = [],
  subscriptionPlanDetailsPath = '',
  subscriptionHasPlan = false,
  optionalAddOnsDetails = [],
  paymentInstrument = {},
  buyerIdentity = {},
}) => {
  return (
    <div className='Subscription'>
      <ContextBar {...contextBarData} />

      {!isFetching && (
        <HeaderV2C
          actions={actions}
          isFetching={isFetching}
          {...headerData}
        />
      )}

      <PageSectionV2C
        title={DETAILS}
        className='subscription-details-section'
        columns={subscriptionDetails}
        isFetching={isFetching}
      />

      {/* <PageSectionV2C */}
      {/*  title={CUSTOMER_DETAILS} */}
      {/*  className='customer-details-section' */}
      {/*  columns={customerDetails} */}
      {/*  clickToCopies={customerDetailsClickToCopies} */}
      {/*  asideTitle={customerDetailsPath} */}
      {/*  isFetching={isFetching} */}
      {/* /> */}

      <BuyerDetailsC
        title={BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {subscriptionHasPlan && (
        <>
          <PageSectionV2C
            title={PLAN_DETAILS}
            className='plan-details-section'
            columns={planDetails}
            clickToCopies={planDetailsClickToCopies}
            asideTitle={subscriptionPlanDetailsPath}
            isFetching={isFetching}
            emptyMessage={PLAN_DETAILS_EMPTY_MESSAGE}
          />

          <PageSectionV2C
            title={OPTIONAL_ADD_ONS}
            className='optional-add-ons-section'
            columns={optionalAddOnsDetails}
            emptyMessage={SUBSCRIPTION_PLANS_NO_ADD_ONS_EMPTY_MESSAGE}
            isFetching={isFetching}
          />
        </>
      )}

      <PaymentInstrumentDetailsC
        isFetching={isFetching}
        paymentInstrument={paymentInstrument}
      />
    </div>
  )
}

Subscription.propTypes = {
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  subscriptionDetails: PropTypes.array,
  planDetails: PropTypes.array,
  planDetailsClickToCopies: PropTypes.array,
  subscriptionPlanDetailsPath: PropTypes.string,
  subscriptionHasPlan: PropTypes.bool,
  optionalAddOnsDetails: PropTypes.array,
  paymentInstrument: PropTypes.object,
  buyerIdentity: PropTypes.object,
}

export default Subscription
