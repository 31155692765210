import './PreviewPanelS.scss'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import React from 'react'
import Button from 'components/Shared/Button/Button'
import { VIEW_ALL_DETAILS } from 'constants/language/languageConstants'

import {
  REMOVE_ICON,
  ANGLE_UP_ICON,
  ANGLE_DOWN_ICON,
} from 'constants/iconConstants'

const PreviewPanel = ({
  setWrapperRef,
  viewPageLink = '',
  closePanel = () => {},
  resourceId = '',
  Component = () => <div />,
  panelOpen = false,
  handlePreviewItemsNavigation = () => {},
  prevId,
  nextId,
}) => {
  const classNames = classnames('PreviewPanel', {
    flex: true,
    column: true,
    'panel-open': panelOpen,
  })

  const prevIconClassnames = classnames('prev-row-icon', {
    [`fa fa-${ANGLE_UP_ICON}`]: true,
    disabled: !prevId,
  })

  const nextIconClassnames = classnames('next-row-icon', {
    [`fa fa-${ANGLE_DOWN_ICON}`]: true,
    disabled: !nextId,
  })

  return (
    <div className={classNames} ref={setWrapperRef}>
      <div className='preview-actions flex space-between items-center'>
        <div className='flex flex-start arrow-icons'>
          <i className={prevIconClassnames} onClick={prevId ? () => handlePreviewItemsNavigation({ prev: true }) : () => {}} />
          <i className={nextIconClassnames} onClick={nextId ? () => handlePreviewItemsNavigation({ next: true }) : () => {}} />
        </div>

        <div className='flex flex-end items-center right-actions'>
          <Link target='blank' to={viewPageLink} className='view-all-details'>
            <Button
              label={VIEW_ALL_DETAILS}
              variant='secondary'
            />
          </Link>

          <div
            role='button'
            tabIndex='0'
            aria-label='close dialog'
            className={`close-panel fa fa-${REMOVE_ICON}`}
            onClick={closePanel}
            onKeyDown={closePanel}
          />
        </div>
      </div>

      <div className='preview-content flex-grow'>
        <Component
          className='resource-component'
          resourceId={resourceId}
        />
      </div>
    </div>
  )
}

PreviewPanel.propTypes = {
  setWrapperRef: PropTypes.func,
  viewPageLink: PropTypes.string,
  closePanel: PropTypes.func,
  resourceId: PropTypes.string,
  Component: PropTypes.object,
  panelOpen: PropTypes.bool,
  prevId: PropTypes.string,
  nextId: PropTypes.string,
  handlePreviewItemsNavigation: PropTypes.func,
}

export default PreviewPanel
