import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chart from './Chart'
import removeUndefined from 'utilities/remove/removeUndefined'
import { GET_CHART_F_REQUEST } from 'constants/flowConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  normalizeBackendStartDate,
  normalizeBackendEndDate,
} from 'constants/normalizationConstants'

import {
  getChartByChartName,
  getChartsByChartNames,
} from 'state-layer/selectors/index'

const mapStateToProps = (state, props) => {
  const {
    name: chartName,
    filters = {},
  } = props

  const chart = getChartByChartName(state, chartName) || {}
  const chartId = get(chart, 'id')

  const {
    name: sliceName = '',
    type = '',
    data = [],
    formData = {},
    metadata,
  } = chart

  const groupBy = get(metadata, 'metadata.group_by')
  const pivots = get(metadata, 'metadata.pivot')
  const pivotChartNames = map(pivots, ({ chart_name: pivotChartName }) => pivotChartName)
  const pivotCharts = getChartsByChartNames(state, pivotChartNames)

  return {
    id: chartId,
    name: sliceName,
    chartName,
    type,
    data,
    formData,
    filters,
    groupBy,
    chart,
    pivots,
    pivotCharts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChart: ({ id, filters, timeRange, timeGrain, groupBy }) => {
      const timeRangeLte = get(timeRange, 'lte')
      const timeRangeGte = get(timeRange, 'gte')

      const queries = removeUndefined({
        'time_range.gte': timeRangeGte ? normalizeBackendStartDate(timeRangeGte) : undefined,
        'time_range.lte': timeRangeLte ? normalizeBackendEndDate(timeRangeLte) : undefined,
        time_grain: timeGrain,
        group_by: groupBy,
      })

      dispatch({
        type: GET_CHART_F_REQUEST,
        payload: {
          id,
          queries,
        },
      })
    },
  }
}

class ChartC extends Component {
  constructor(props) {
    super(props)

    const {
      id,
      groupBy,
      chartName,
      showGroupBy,
    } = props

    this.state = {
      selectedPivot: chartName,
      selectedGroupBy: showGroupBy ? get(groupBy, '[0].column') : undefined,
    }
  }

  componentDidMount() {
    const {
      id,
      filters,
      getChart,
      timeRange = {},
      timeGrain,
    } = this.props

    const { selectedGroupBy } = this.state

    if (id) {
      getChart({ id, filters, timeRange, timeGrain, groupBy: selectedGroupBy })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters: prevFilters,
      timeRange: prevTimeRange = {},
      timeGrain: prevTimeGrain,
    } = prevProps

    const {
      selectedPivot: prevSelectedPivot,
      selectedGroupBy: prevSelectedGroupBy,
    } = prevState

    const {
      id,
      filters,
      getChart,
      timeRange = {},
      timeGrain,
      pivotCharts,
    } = this.props

    const {
      selectedPivot,
      selectedGroupBy,
    } = this.state

    const filtersChanged = !isEqual(prevFilters, filters)
    const timeRangeChanged = !isEqual(prevTimeRange, timeRange)
    const timeGrainChanged = prevTimeGrain !== timeGrain
    const groupByChanged = prevSelectedGroupBy !== selectedGroupBy
    const pivotChanged = prevSelectedPivot !== selectedPivot

    if (filtersChanged || timeRangeChanged || timeGrainChanged || groupByChanged || pivotChanged) {
      const chartId = get(pivotCharts, `${selectedPivot}.id`, id)

      getChart({ id: chartId, filters, timeRange, timeGrain, groupBy: selectedGroupBy })
    }
  }

  selectGroupByOption = (selectedGroupBy) => {
    this.setState({ selectedGroupBy })
  }

  selectPivot = (selectedPivot) => {
    this.setState({ selectedPivot })
  }

  render() {
    const {
      chart,
      pivotCharts,
    } = this.props

    const {
      selectedPivot,
    } = this.state

    const selectedChart = get(pivotCharts, selectedPivot, chart)
    const isFetching = get(selectedChart, FETCHING)

    const {
      id,
      name: sliceName = '',
      type = '',
      data = [],
      formData = {},
      metadata,
    } = selectedChart

    return (
      <Chart
        {...this.props}
        selectGroupByOption={this.selectGroupByOption}
        selectPivot={this.selectPivot}
        selectedPivot={selectedPivot}
        isFetching={isFetching}
        id={id}
        name={sliceName}
        chartName={selectedPivot}
        type={type}
        data={data}
        formData={formData}
        metadata={metadata}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartC)
