import { fieldEmpty } from 'utilities/validate'
import get from 'lodash/get'

const validateCreateInstantPayoutForm = (values, props) => {
  const {
    paymentMethod,
  } = values

  return {
    paymentMethod: fieldEmpty(get(paymentMethod, 'value'), 'Payment Method'),
  }
}

export default validateCreateInstantPayoutForm
