import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'
import { MM_DD_YYYY } from 'constants/timeConstants'

const frontendDashboardCustomizationModel = ({ data: dashboardCustomization }) => {
  const [
    createdAt,
    entityId,
    entityType,
    id,
    sidebarBackgroundColor,
    sidebarLogoImageUrl,
    sidebarTextColor,
    sidebarHighlightColor,
    enabled,
    subDomainName,
    hostedOnboardingFormSettings,
    companyName,
    emailLogoImageUrl,
    dashboardType,
    platformType,
    paymentLinkSettings,
    paymentLinkTemplate,
  ] = getMany(dashboardCustomization, [
    'created_at',
    'entity_id',
    'entity_type',
    'id',
    'sidebar_background_color',
    'sidebar_logo_image_url',
    'sidebar_text_color',
    'sidebar_secondary_color',
    'enabled',
    'sub_domain_name',
    'hosted_onboarding_form_settings',
    'company_name',
    'email_logo_image_url',
    'dashboard_type',
    'platform_type',
    'payment_link_settings',
    'payment_link_settings.payment_link_template.[0]',
  ])

  const [
    onboardingDisplayName,
    onboardingLogoImageUrl,
    onboardingPrimaryColor,
    onboardingLinkExpiration,
    onboardingExpiredUrl,
    onboardingFeeUrl,
    onboardingReturnUrl,
    onboardingTermsOfServiceUrl,
  ] = getMany(hostedOnboardingFormSettings, [
    'display_name',
    'logo_image_url',
    'primary_color',
    'expiration_in_days',
    'expired_session_url',
    'fee_details_url',
    'return_url',
    'terms_of_service_url',
  ])

  const [
    paymentLinkLogo,
    paymentLinkHeaderColor,
    paymentLinkButtonBackgroundColor,
    paymentLinkButtonTextColor,
  ] = getMany(paymentLinkSettings, [
    'logo',
    'brand_color',
    'accent_color',
    'button_font_color',
  ])

  const [
    templateName,
    successReturnUrl,
    unsuccessfulReturnUrl,
    termsOfServiceUrl,
    expiredSessionUrl,
    oneTimeAmountType,
    oneTimeAllowPaymentCard,
    oneTimeAllowBankTransfer,
    oneTimeExpiresIn,
    oneTimeExpirationDate,
    oneTimeCollectBuyerDetails,
    oneTimeCollectName,
    oneTimeCollectPhoneNumber,
    oneTimeCollectShippingAddress,
    oneTimeCollectBillingAddress,
    oneTimeCustomFields,
    multiUseAmountType,
    multiUseAllowPaymentCard,
    multiUseAllowBankTransfer,
    multiUseExpiresIn,
    multiUseExpirationDate,
    multiUseCollectBuyerDetails,
    multiUseCollectName,
    multiUseCollectPhoneNumber,
    multiUseCollectShippingAddress,
    multiUseCollectBillingAddress,
    multiUseCustomFields,
    sendReceipt,
    sendReceiptToSelf,
  ] = getMany(paymentLinkTemplate, [
    'template_name',
    'success_return_url',
    'unsuccessful_return_url',
    'terms_of_service_url',
    'expired_session_url',
    'one_time.amount_type',
    'one_time.allow_payment_card',
    'one_time.allow_bank_transfer',
    'one_time.expires_in',
    'one_time.expiration_date',
    'one_time.collect_buyer_details',
    'one_time.collect_name',
    'one_time.collect_phone',
    'one_time.collect_shipping_address',
    'one_time.collect_billing_address',
    'one_time.custom_fields',
    'multi_use.amount_type',
    'multi_use.allow_payment_card',
    'multi_use.allow_bank_transfer',
    'multi_use.expires_in',
    'multi_use.expiration_date',
    'multi_use.collect_buyer_details',
    'multi_use.collect_name',
    'multi_use.collect_phone',
    'multi_use.collect_shipping_address',
    'multi_use.collect_billing_address',
    'multi_use.custom_fields',
    'send_receipt',
    'send_receipt_to_self',
  ])

  return removeUndefined({
    id,
    enabled: toString(enabled),
    createdAt,
    entityId,
    entityType,
    sidebarBackgroundColor,
    sidebarLogoImageUrl,
    sidebarTextColor,
    sidebarHighlightColor,
    subDomainName,
    onboardingDisplayName,
    onboardingLogoImageUrl,
    onboardingPrimaryColor,
    companyName,
    emailLogoImageUrl,
    dashboardType,
    platformType,
    onboardingLinkExpiration,
    onboardingExpiredUrl,
    onboardingFeeUrl,
    onboardingReturnUrl,
    onboardingTermsOfServiceUrl,
    paymentLinkLogo,
    paymentLinkHeaderColor,
    paymentLinkButtonBackgroundColor,
    paymentLinkButtonTextColor,
    paymentLinkTemplate: {
      templateName,
      successReturnUrl,
      unsuccessfulReturnUrl,
      termsOfServiceUrl,
      expiredSessionUrl,
      sendReceipt,
      sendReceiptToSelf,
      oneTime: {
        amountType: oneTimeAmountType,
        allowPaymentCard: oneTimeAllowPaymentCard,
        allowBankTransfer: oneTimeAllowBankTransfer,
        expiresIn: oneTimeExpiresIn,
        expirationDate: formatDate({ date: oneTimeExpirationDate }),
        collectBuyerDetails: oneTimeCollectBuyerDetails,
        collectName: oneTimeCollectName,
        collectPhoneNumber: oneTimeCollectPhoneNumber,
        collectShippingAddress: oneTimeCollectShippingAddress,
        collectBillingAddress: oneTimeCollectBillingAddress,
        collectCustomFields: !isEmpty(oneTimeCustomFields),
        customFields: oneTimeCustomFields,
      },
      multiUse: {
        amountType: multiUseAmountType,
        allowPaymentCard: multiUseAllowPaymentCard,
        allowBankTransfer: multiUseAllowBankTransfer,
        expiresIn: multiUseExpiresIn,
        expirationDate: formatDate({ date: multiUseExpirationDate, format: MM_DD_YYYY }),
        collectBuyerDetails: multiUseCollectBuyerDetails,
        collectName: multiUseCollectName,
        collectPhoneNumber: multiUseCollectPhoneNumber,
        collectShippingAddress: multiUseCollectShippingAddress,
        collectBillingAddress: multiUseCollectBillingAddress,
        collectCustomFields: !isEmpty(multiUseCustomFields),
        customFields: multiUseCustomFields,
      },
    },
  })
}

export default frontendDashboardCustomizationModel
