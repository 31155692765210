import paymentsAPI from 'utilities/api/paymentsAPI'
import { AUTHORIZATIONS } from 'constants/apiConstants'

const postAuthorizationAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: AUTHORIZATIONS,
    service: AUTHORIZATIONS,
  })
}

export default postAuthorizationAPI
