import React, { Component } from 'react'
import { connect } from 'react-redux'
import ChangePasswordForm from './ChangePasswordForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'

import {
  HAVE_12_TO_20_CHARACTERS_REGEX,
  HAVE_ONE_LOWERCASE_REGEX,
  HAVE_ONE_NUMBER_REGEX,
  HAVE_ONE_SPECIAL_CHARACTER_REGEX,
  HAVE_ONE_UPPERCASE_REGEX,
} from 'constants/regexConstants'

import {
  HAVE_12_TO_20_CHARACTERS,
  HAVE_ONE_LOWERCASE,
  HAVE_ONE_NUMBER,
  HAVE_ONE_SPECIAL_CHARACTER,
  HAVE_ONE_UPPERCASE,
} from 'constants/language/languageConstants'

const passwordRequirements = [
  {
    title: HAVE_12_TO_20_CHARACTERS,
    regex: HAVE_12_TO_20_CHARACTERS_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_LOWERCASE,
    regex: HAVE_ONE_LOWERCASE_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_UPPERCASE,
    regex: HAVE_ONE_UPPERCASE_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_NUMBER,
    regex: HAVE_ONE_NUMBER_REGEX,
    passed: false,
  },
  {
    title: HAVE_ONE_SPECIAL_CHARACTER,
    regex: HAVE_ONE_SPECIAL_CHARACTER_REGEX,
    passed: false,
  },
]

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ChangePasswordFormC extends Component {
  render() {
    return (
      <ChangePasswordForm
        {...this.props}
        passwordRequirements={passwordRequirements}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormC)
