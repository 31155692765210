import './ReversePhoneLookupReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  ADDITIONAL_DETAILS,
  PHONE_LINE_TYPE_CHECK,
  PHONE_OWNERSHIP_CHECK,
} from 'constants/language/languageConstants'

const ReversePhoneLookupReport = ({
  phoneOwnershipCheckData = [],
  phoneLineTypeCheckData = [],
  additionalDetailsData = [],
}) => {
  return (
    <div className='ReversePhoneLookupReport'>
      <PageSectionV2C className='reverse-phone-lookup-data-section'>
        <h6>{PHONE_OWNERSHIP_CHECK}</h6>
        <PageSectionV2C className='phone-ownership-check-section' columns={phoneOwnershipCheckData} />

        <h6>{PHONE_LINE_TYPE_CHECK}</h6>
        <PageSectionV2C className='phone-line-type-check-section' columns={phoneLineTypeCheckData} />

        <h6>{ADDITIONAL_DETAILS}</h6>
        <PageSectionV2C className='additional-details-section' columns={additionalDetailsData} />
      </PageSectionV2C>
    </div>
  )
}

ReversePhoneLookupReport.propTypes = {
  phoneOwnershipCheckData: PropTypes.array,
  phoneLineTypeCheckData: PropTypes.array,
  additionalDetailsData: PropTypes.array,
}

export default ReversePhoneLookupReport
