import './ManagePermissionS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PermissionFormC from './PermissionFormC'
import QuickFilterPageC from 'components/Customer/Shared/QuickFilterPage/QuickFilterPageC'
import Button from 'components/Shared/Button/Button'
import { PERMISSIONS_EMPTY_MESSAGE } from 'constants/language/languageConstants'
import get from 'lodash/get'
import trim from 'lodash/trim'
import includes from 'lodash/includes'

const filterPermissionByName = (permission, query) => {
  const formattedQuery = trim(query.toLowerCase()).split(' ').join(' ')

  const name = get(permission, 'name', '')
  const separatorRegex = /[:_]/g
  const formattedName = name.split(separatorRegex).join(' ')

  const alias = get(permission, 'alias_name', '').toLowerCase()

  return includes(formattedName, formattedQuery) || includes(alias, formattedQuery)
}

const ManagePermission = ({
  isFetching = false,
  permissions = [],
  userId,
  credentials,
  isReadOnly,
  disableAllPermissions,
}) => {
  const extraProps = { userId, credentials, isReadOnly }
  const hasPermissions = permissions.length > 0

  return (
    <div className='ManagePermission'>
      <div className='header flex flex-end'>
        { hasPermissions && <Button onClick={disableAllPermissions} label='Update All' className='update-all-user-permissions-button' /> }
      </div>

      { !hasPermissions && !isFetching && <div>{PERMISSIONS_EMPTY_MESSAGE}</div> }

      { hasPermissions && (
        <QuickFilterPageC
          title='User Permissions'
          records={permissions}
          isFetching={isFetching}
          component={PermissionFormC}
          extraProps={extraProps}
          filterFunc={filterPermissionByName}
        />
      )}
    </div>
  )
}

ManagePermission.propTypes = {
  isFetching: PropTypes.bool,
  permissions: PropTypes.array,
  userId: PropTypes.string,
  credentials: PropTypes.object,
  isReadOnly: PropTypes.bool,
  disableAllPermissions: PropTypes.func,
}

export default ManagePermission
