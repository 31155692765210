import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'

const frontendBalanceModel = ({ data: balance }) => {
  const [
    id,
    applicationId,
    availableAmount,
    currency,
    createdAt,
    updatedAt,
    tags,
  ] = getMany(balance, [
    'id',
    'linked_to',
    'available_amount',
    'currency',
    'created_at',
    'updated_at',
    'tags',
  ])

  return removeUndefined({
    id,
    applicationId,
    availableAmount,
    displayAvailableAmount: formatMoney({ amount: availableAmount, currency }),
    currency,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    tags,
  })
}

export default frontendBalanceModel
