import { put, take } from 'redux-saga/effects'
import { replace } from 'react-router-redux'

import {
  REDIRECT_F_REQUEST,
  REDIRECT_F_SUCCESS,
  REDIRECT_F_FAILURE,
} from 'constants/flowConstants'

const redirectF = function * () {
  while (true) {
    const { payload } = yield take([REDIRECT_F_REQUEST])
    const { path } = payload

    if (path) {
      yield put({ type: REDIRECT_F_SUCCESS })
      yield put(replace(path))
    } else {
      yield put({ type: REDIRECT_F_FAILURE })
    }
  }
}

export default redirectF
