import moment from 'moment'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'

import {
  REPORT_CATEGORY_TO_DISPLAY_MAPPING,
  REPORT_TYPE_TO_DISPLAY_MAPPING,
} from 'constants/reportingConstants'

import {
  DAY_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'constants/timeConstants'

const frontendCategorizedReportModel = ({ data: report }) => {
  const [
    id,
    category,
    subcategory,
    fileName,
    version,
    period,
    startDate,
    endDate,
    createdAt,
    reportType,
  ] = getMany(report, [
    'id',
    'category',
    'subcategory',
    'file_name',
    'version',
    'period_type',
    'start_date_time',
    'end_date_time',
    'created_at',
    'report_type',
  ])

  return removeUndefined({
    id,
    category,
    subcategory,
    fileName,
    version,
    period,
    startDate,
    endDate,
    createdAt,
    reportType,
    displayCategory: REPORT_CATEGORY_TO_DISPLAY_MAPPING[category],
    displaySubcategory: REPORT_TYPE_TO_DISPLAY_MAPPING[subcategory],
    displayCreatedAt: formatDate({ date: createdAt }),
    displayDailyPeriod: formatDate({ date: createdAt, createdAt, format: DAY_FORMAT }),
    displayMonthlyPeriod: moment(createdAt).add(-1, 'months').format(MONTH_YEAR_FORMAT),
  })
}

export default frontendCategorizedReportModel
