import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import ApplicationMerchantsC from 'components/Customer/Pages/Application/ApplicationMerchants/ApplicationMerchantsC'
import ApplicationSettlementsC from 'components/Customer/Pages/Application/ApplicationSettlements/ApplicationSettlementsC'
import ApplicationPayoutPlanC from 'components/Customer/Pages/Application/ApplicationPayoutPlan/ApplicationPayoutPlanC'
import ApplicationMerchantDepositsC from 'components/Customer/Pages/Application/ApplicationMerchantDeposits/ApplicationMerchantDepositsC'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

import {
  MERCHANT_ACCOUNTS,
  MERCHANT_DEPOSITS,
  PAYOUT_PLANS,
  SETTLEMENTS,
} from 'constants/language/languageConstants'


class ApplicationMerchantsTabs extends Component {
  render() {
    const { applicationId } = this.props

    const credentials = getCurrentCredentials()
    const isPayout = isPayoutFeature({ credentials })

    const tabs = [
      {
        id: 'merchants-old',
        name: MERCHANT_ACCOUNTS,
        component: ApplicationMerchantsC,
        condition: !isPayout,
        props: {
          applicationId,
        },
      },
      {
        id: 'settlements',
        name: SETTLEMENTS,
        component: ApplicationSettlementsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'merchant-deposits',
        name: MERCHANT_DEPOSITS,
        component: ApplicationMerchantDepositsC,
        props: {
          applicationId,
        },
      },
      {
        id: 'payout-plans',
        name: PAYOUT_PLANS,
        component: ApplicationPayoutPlanC,
        props: {
          applicationId,
        },
      },
    ]

    return (
      <div className='ApplicationMerchantsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationMerchantsTabs
