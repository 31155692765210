import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  V2_AUTHENTICATION,
  USER_PERMISSIONS,
} from 'constants/apiConstants'

const patchUserPermissionsAPI = ({ values, credentials, id, meta }) => {
  const path = USER_PERMISSIONS({ id })

  return paymentsAPI.patch({
    values,
    credentials,
    path,
    meta,
    service: V2_AUTHENTICATION,
  })
}

export default patchUserPermissionsAPI
