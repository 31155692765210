import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import redirectRequest from 'utilities/actions/redirectRequest'
import { PATCH_SUBSCRIPTION_PLAN_F_REQUEST } from 'constants/flowConstants'
import { SUBSCRIPTION_PLANS_PATH } from 'constants/pathConstants'
import { EDIT_SUBSCRIPTION_PLAN } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const submitEditSubscriptionPlanForm = (values, dispatch, props) => {
  const {
    subscriptionPlanId,
    credentials,
  } = props

  const credentialId = get(credentials, 'id')
  const { description } = values

  const valuesToSubmit = {
    description,
  }

  dispatch({
    type: PATCH_SUBSCRIPTION_PLAN_F_REQUEST,
    payload: {
      id: subscriptionPlanId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_SUBSCRIPTION_PLAN, {
        subscriptionPlanId,
        values,
        credentials,
      }),
      successCallback: () => {
        const path = SUBSCRIPTION_PLANS_PATH({ credentialId })
        dispatch(redirectRequest({ path }))
      },
    },
  })
}

export default submitEditSubscriptionPlanForm
