import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import APIKeysC from 'components/Customer/Shared/Page/APIKeys/APIKeysC'
import WebhooksC from 'components/Customer/Pages/Webhooks/WebhooksC'
import WebhookLogsC from 'components/Customer/Pages/WebhookLogs/WebhookLogsC'
import AltPaymentMethodsC from 'components/Customer/Pages/AltPaymentMethods/AltPaymentMethodsC'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isPlatform from 'utilities/is/isPlatform'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { SHOW_WEBHOOK_LOGS } from 'constants/featureFlagConstants'

import {
  API_KEYS,
  WEBHOOKS,
  WEBHOOK_LOGS,
  ALT_PAYMENT_METHODS,
} from 'constants/language/languageConstants'

class ApplicationDeveloperTabs extends Component {
  render() {
    const {
      applicationId,
      identityId,
    } = this.props

    const credentials = getCurrentCredentials()
    const isPayout = isPayoutFeature({ credentials })
    const isElavon = isPlatform('elavonpartnerconnect')
    const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()

    const webhooks = isRolePlatform({ credentials }) ? [{
      id: 'webhooks',
      name: WEBHOOKS,
      component: WebhooksC,
      props: {
        applicationId,
      },
    }] : []

    const webhookLogs = (isRolePlatform({ credentials }) && getFeatureFlag(SHOW_WEBHOOK_LOGS)) ? [{
      id: 'webhook-logs',
      name: WEBHOOK_LOGS,
      component: WebhookLogsC,
      props: {
        applicationId,
      },
    }] : []

    const tabs = [
      ...webhooks,
      ...webhookLogs,
      {
        id: 'api-keys',
        name: API_KEYS,
        component: APIKeysC,
        props: {
          applicationId,
        },
      },
      {
        id: 'alt-payment-methods',
        name: ALT_PAYMENT_METHODS,
        component: AltPaymentMethodsC,
        condition: () => { return isRolePlatform({ credentials }) && !(isPayout || isElavon || isStandaloneUnderwriting) },
        props: {
          identityId,
        },
      },
    ]

    return (
      <div className='ApplicationDeveloperTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationDeveloperTabs
