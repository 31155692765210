import React, { Component } from 'react'
import { connect } from 'react-redux'
import Members from './Members'
import columnDescriptors from './columnDescriptors'
import getEntityConfigurationsRequest from 'utilities/actions/get/getEntityConfigurationsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import showModalAction from 'utilities/actions/showModalAction'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import hasPermissions from 'utilities/hasPermissions'
import createUrl from 'utilities/create/createUrl'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import { goToPath } from 'state-layer/history'
import { ADD_NEW_TEAM_MEMBER } from 'constants/language/languageConstants'
import { CUSTOM } from 'constants/roleConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_TEAM_MEMBER_PERMISSION } from 'constants/permissionConstants'
import { SHOW_MFA } from 'constants/featureFlagConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  getEntityConfigurationByLinkedToSelector,
} from 'state-layer/selectors'

import {
  RESEND_DASHBOARD_INVITE_MODAL,
  DEACTIVATE_TEAM_MEMBER_MODAL,
  REINSTATE_TEAM_MEMBER_MODAL,
  REQUIRE_TEAM_MFA_MODAL,
  RESET_MEMBER_MFA_MODAL,
} from 'constants/modalConstants'

import {
  ADD_TEAM_MEMBER_PATH,
  MEMBER_PATH,
} from 'constants/pathConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  DASHBOARD_ROLE,
} from 'constants/filterConstants'

import {
  DATE_QUICK_FILTER,
  MEMBER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

const allowedFilters = [
  DASHBOARD_ROLE,
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
]

const quickFilters = [
  MEMBER_STATE_QUICK_FILTER,
  // DATE_QUICK_FILTER,
]

const initialQueries = {
  offset: 0,
  sort: 'last_activity,desc',
}

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')
  const isFetching = get(state, `membersR.${FETCHING}`)

  const {
    entityId,
    entityType,
  } = props

  const entityConfiguration = getEntityConfigurationByLinkedToSelector(state, entityId)
  const entityConfigurationId = get(entityConfiguration, 'id')
  const mfaEnabled = get(entityConfiguration, 'mfaEnabled', false)

  const memberPath = (data) => {
    const memberId = get(data, 'id')

    return createUrl({
      url: MEMBER_PATH({ credentialId, memberId }),
      queries: {
        entityId,
        entityType,
      },
    })
  }

  const flowValues = removeUndefined({
    entityId,
    entityType,
  })

  const showMfa = getFeatureFlag(SHOW_MFA) && isAdministratorRole()

  return {
    credentials,
    credentialId,
    isFetching,
    quickFilters,
    initialQueries,
    flowValues,
    allowedFilters,
    entityDataFound: !isEmpty(entityId) && !isEmpty(entityType),
    memberPath,
    dashboardUserRoleType,
    entityType,
    entityId,
    mfaEnabled,
    showMfa,
    entityConfigurationId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showResendDashboardInviteModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_DASHBOARD_INVITE_MODAL, modalProps })),
    showDeactivateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_TEAM_MEMBER_MODAL, modalProps })),
    showReinstateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: REINSTATE_TEAM_MEMBER_MODAL, modalProps })),
    getEntityConfigurations: (queries) => dispatch(getEntityConfigurationsRequest({ queries })),
    showRequireTeamMFAModal: (modalProps) => dispatch(showModalAction({ modalType: REQUIRE_TEAM_MFA_MODAL, modalProps, className: 'modal-md no-pad' })),
    showResetMemberMFAModal: (modalProps) => dispatch(showModalAction({
      modalType: RESET_MEMBER_MFA_MODAL,
      modalProps,
      className: 'modal-md no-pad',
    })),
  }
}

class MembersC extends Component {
  componentDidMount() {
    const {
      entityId,
      entityType,
      getEntityConfigurations,
    } = this.props

    if (entityId && entityType) {
      getEntityConfigurations({ linked_to: entityId, linked_type: entityType })
    }
  }

  // on component did update, check if the entityId or entityType has changed from null to a value, if so then fetch the entity configurations
  componentDidUpdate(prevProps) {
    const {
      entityId,
      entityType,
      getEntityConfigurations,
    } = this.props

    const {
      entityId: prevEntityId,
      entityType: prevEntityType,
    } = prevProps

    if (entityId && entityType && (entityId !== prevEntityId || entityType !== prevEntityType)) {
      getEntityConfigurations({ linked_to: entityId, linked_type: entityType })
    }
  }

  showChangeTeamMFAModal = () => {
    const {
      entityId,
      entityType,
      entityConfigurationId,
      showRequireTeamMFAModal,
      mfaEnabled,
    } = this.props

    showRequireTeamMFAModal({
      linkedTo: entityId,
      linkedType: entityType,
      entityConfigurationId,
      mfaEnabled: !mfaEnabled,
    })
  }

  render() {
    const {
      credentialId,
      showResendDashboardInviteModal,
      dashboardUserRoleType,
      showDeactivateTeamMemberModal,
      showReinstateTeamMemberModal,
      showResetMemberMFAModal,
      entityType,
      entityId,
    } = this.props

    const actions = [
      {
        label: ADD_NEW_TEAM_MEMBER,
        className: 'add-team-member-button',
        action: () => goToPath({
          pathname: ADD_TEAM_MEMBER_PATH({ credentialId }),
          queries: {
            entityType,
            entityId,
          },
        }),
        condition: dashboardUserRoleType !== undefined && dashboardUserRoleType !== CUSTOM && hasPermissions([CREATE_TEAM_MEMBER_PERMISSION]),
      },
    ]

    const columnActions = {
      showResendDashboardInviteModal,
      showDeactivateTeamMemberModal,
      showReinstateTeamMemberModal,
      showResetMemberMFAModal,
    }

    const normalizedColumnDescriptors = columnDescriptors({ columnActions })

    return (
      <Members
        {...this.props}
        actions={actions}
        columnDescriptors={normalizedColumnDescriptors}
        showChangeTeamMFAModal={this.showChangeTeamMFAModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersC)
