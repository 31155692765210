import './ApplicationProcessorS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'

const ApplicationProcessor = ({
  processorDetailsSectionData = [],
  isFetching = false,
  headerTitle,
  headerSubTitle,
}) => {
  return (
    <div className='ApplicationProcessor'>
      <HeaderC
        title={headerTitle}
        subTitle={headerSubTitle}
        breadcrumbs
      />

      <div className='header-page-content'>
        <PageSection
          title='Processor Details'
          data={processorDetailsSectionData}
          isFetching={isFetching}
        />
      </div>
    </div>
  )
}

ApplicationProcessor.propTypes = {
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  processorDetailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default ApplicationProcessor
