import './RadioButtonS.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import { ALERT_ICON } from 'constants/iconConstants'

// This is our classic radio button component to be used in Forms
const RadioButton = ({
  input,
  label,
  helperText,
  disabled = false,
  meta = {},
  options,
  row = false,
  buttonsRow = false,
  tooltip,
  tooltipPosition,
  required = true,
  hideInput = false,
}) => {
  const {
    touched,
    error,
    warning,
  } = meta

  const { name } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const inputClassName = name.replace('.', '_')
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

  const radioButtonsContainerClassNames = classnames({
    'radio-button-container': true,
    flex: row,
    row,
  })

  const radioButtonsClassNames = classnames({
    'radio-buttons': true,
    flex: true,
    row: buttonsRow,
    column: !buttonsRow,
  })

  return (
    <div className={`RadioButton ${inputClassName}`}>
      <div className={radioButtonsContainerClassNames}>
        {label && (
        <div className='flex column radio-button-label-container'>
          <div className='flex'>
            { label && <label className='radio-button-label label-2'>{label}</label> }
            { required && <div className='required-field'>*</div> }
            { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
          </div>

          { helperText && <label className='radio-button-helper-text label-2'>{helperText}</label> }
        </div>
        )}

        <div className={radioButtonsClassNames}>
          {
            map(options, ({
              value,
              label: radioButtonLabel,
              labelHTML: radioButtonLabelHTML,
              helperText: radioButtonHelperText,
              disabled: optionDisabled,
              tooltip: radioButtonTooltip,
              tooltipPosition: radioButtonTooltipPosition,
              disabledTooltip,
              disabledTooltipPosition = 'top-of-radio',
            }) => {
              const radioButtonClassNames = classnames({
                'radio-button': true,
                flex: true,
                [hasError]: !!hasError,
                disabled: optionDisabled || disabled,
                hideInput,
              })

              return (
                <Fragment key={kebabCase(value)}>
                  {/* if input is disabled, wrap the input with a tooltip */}
                  {((optionDisabled || disabled) && disabledTooltip) ? (
                    <TooltipLabelC
                      showIcon={false}
                      message={disabledTooltip}
                      position={disabledTooltipPosition}
                    >
                      <label className={radioButtonClassNames} key={`${name}.${value}`}>
                        <input
                          type='radio'
                          {...input}
                          disabled={optionDisabled || disabled}
                          value={value}
                          checked={value === input.value}
                          onChange={() => input.onChange(value)}
                        />

                        { (radioButtonLabel || radioButtonLabelHTML) && (
                          <div>
                            <div className='flex'>
                              <div className='radio-button-label label-2'>{radioButtonLabelHTML ? radioButtonLabelHTML : radioButtonLabel}</div>
                              { radioButtonTooltip && <TooltipLabel message={radioButtonTooltip} position={radioButtonTooltipPosition} /> }
                            </div>
                            {radioButtonHelperText && <div className='radio-button-helper-text label-2'>{radioButtonHelperText}</div>}
                          </div>
                        )}
                      </label>
                    </TooltipLabelC>
                  ) : (
                    <label className={radioButtonClassNames} key={`${name}.${value}`}>
                      <input
                        type='radio'
                        {...input}
                        disabled={optionDisabled || disabled}
                        value={value}
                        checked={value === input.value}
                        onChange={() => input.onChange(value)}
                      />

                      { (radioButtonLabel || radioButtonLabelHTML) && (
                        <div>
                          <div className='flex'>
                            <div className='radio-button-label label-2'>{radioButtonLabelHTML ? radioButtonLabelHTML : radioButtonLabel}</div>
                            { radioButtonTooltip && <TooltipLabel message={radioButtonTooltip} position={radioButtonTooltipPosition} /> }
                          </div>
                          {radioButtonHelperText && <div className='radio-button-helper-text label-2'>{radioButtonHelperText}</div>}
                        </div>
                      )}
                    </label>
                  )}
                </Fragment>
              )
            })
          }
        </div>
      </div>

      { (touched && (errorJSX || warningJSX)) }
    </div>
  )
}

RadioButton.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.array,
  row: PropTypes.bool,
  buttonsRow: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipLabel: PropTypes.string,
  required: PropTypes.bool,
  hideInput: PropTypes.bool,
}

export default RadioButton
