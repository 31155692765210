import { GET_UNDERWRITING_PROFILES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUnderwritingProfileByEntityIdRequest = ({
  credentials,
  queries,
}) => createAction({
  type: GET_UNDERWRITING_PROFILES_F_REQUEST,
  queries,
  credentials,
})

export default getUnderwritingProfileByEntityIdRequest
