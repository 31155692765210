import './formatCustomFieldArrayDisplayDataS.scss'
import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'

const formatCustomFieldArrayDisplayData = ({ data = [], itemName, optionals = {} }) => {
  return (
    <div className='field-array-items'>
      { !isEmpty(data) ? map(data, (item, index) => {
        return (
          <div className='field-array-item'>
            <span className='field-array-item-name'>{`${itemName} ${index + 1}`}</span>

            {/* TODO: convert to reusable tooltip component? */}
            <div className='field-array-item-tooltip'>
              { map(item, (value, name = '') => {
                const itemOptionals = get(optionals, name)
                const itemFormatter = get(itemOptionals, 'formatter')
                const itemLabel = get(itemOptionals, 'label')
                const defaultItemLabel = map(name.split('_'), (word) => capitalize(word)).join(' ')

                return (
                  <div className='flex column'>
                    <strong>{itemLabel ? itemLabel : defaultItemLabel}</strong>
                    <span>{itemFormatter ? itemFormatter(value) : value}</span>
                  </div>
                )
              }) }
            </div>
          </div>
        )
      }) : '-' }
    </div>
  )
}

export default formatCustomFieldArrayDisplayData
