import { GET_MERCHANT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantRequest = ({
  merchantId,
  credentials,
}) => createAction({
  type: GET_MERCHANT_F_REQUEST,
  id: merchantId,
  credentials,
})

export default getMerchantRequest
