import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ROLES } from 'constants/apiConstants'

const getRolesAPI = ({ meta, queries }) => {
  const path = DASHBOARD_SERVICE_ROLES

  return dashboardAPI.get({
    meta,
    path,
    queries,
  })
}
export default getRolesAPI
