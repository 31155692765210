import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'

import {
  ADMIN_USER,
  DASHBOARD_SERVICE_USER_V2,
} from 'constants/apiConstants'

const getAdminUserAPI = ({ id, meta }) => {
  const dashboardId = getCurrentDashboardId()
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_USER_V2({ dashboardId, userId: id }) : ADMIN_USER({ userId: id })

  return dashboardAPI.get({ path, meta })
}

export default getAdminUserAPI
