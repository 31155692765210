import frontendPlatformModel from 'utilities/create/models/frontendPlatformModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPlatformsModel = ({ data: platforms }) => {
  const platformsModels = map(platforms, (platform) => frontendPlatformModel({ data: platform }))

  return keyBy(platformsModels, 'id')
}

export default frontendPlatformsModel
