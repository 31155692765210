import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import formatMoney from 'utilities/formatters/formatMoney'
import map from 'lodash/map'
import get from 'lodash/get'

const SubscriptionAmountFields = [
  {
    name: 'amountNickname',
    label: 'Amount Nickname',
  },
  {
    name: 'amount',
    label: 'Amount',
    formatter: (amount) => formatMoney({ amount }),
  },
  {
    name: 'label',
    label: 'Label',
  },
  {
    name: 'tags',
    label: 'Tags',
    formatter: (tags) => TagsDisplaySection({
      data: map(tags, ({ name, value }) => ({ value, label: name })),
      emptyMessage: '-',
    }),
  },
]

const SubscriptionAmountDisplayData = (values) => {
  return map(SubscriptionAmountFields, ({ name, label, formatter }) => ({
    label,
    value: formatter ? formatter(get(values, name, {})) : get(values, name),
  }))
}

export default SubscriptionAmountDisplayData
