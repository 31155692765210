import './RejectUnderwritingEntityFormS.scss'
import { reduxForm, Field } from 'redux-form'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import NestedCheckboxesTable from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import submitRequestAdditionInfoOrRejectUnderwritingEntityForm from 'utilities/submit/submitRequestAdditionInfoOrRejectUnderwritingEntityForm'
import validateRejectUnderwritingEntityForm from 'utilities/validate/validateRejectUnderwritingEntityForm'
import { ALERT_ICON } from 'constants/iconConstants'
import { REJECT_UNDERWRITING_ENTITY_FORM } from 'constants/formConstants'
import { REJECT_UNDERWRITING_ENTITY_OPTIONS } from 'constants/reviewQueueConstants'

import {
  REJECT,
  CANCEL,
  UNDERWRITING_ACTIONS_WARNING_MESSAGE,
} from 'constants/language/languageConstants'

const RejectUnderwritingEntityForm = ({
  closeModal = () => {},
  currentSelectedEvents = {},
  handleSubmit = () => {},
  invalid = false,
  isMerchantUnderwriting,
}) => {
  return (
    <form className='RejectUnderwritingEntityForm' onSubmit={handleSubmit(submitRequestAdditionInfoOrRejectUnderwritingEntityForm)}>
      <div className='modal-content'>
        <div className='p-2 flex header'>Are you sure you want to <div className='p-2-bold'>reject</div> this {isMerchantUnderwriting ? 'merchant' : 'applicant'}? Select the reasons for rejecting this {isMerchantUnderwriting ? 'merchant' : 'applicant'}.</div>
        <Field
          name='selectedEvents'
          component={NestedCheckboxesTable}
          options={REJECT_UNDERWRITING_ENTITY_OPTIONS}
          formName={REJECT_UNDERWRITING_ENTITY_FORM}
          showHeaderCheckboxes={false}
          currentSelectedEvents={currentSelectedEvents}
          showActions={false}
        />
        <div className='warning-message flex items-center'><i className={`fa fa-${ALERT_ICON}`} />{UNDERWRITING_ACTIONS_WARNING_MESSAGE}</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={invalid} label={REJECT} />
      </div>
    </form>
  )
}

RejectUnderwritingEntityForm.propTypes = {
  closeModal: PropTypes.func,
  currentSelectedEvents: PropTypes.object,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  isMerchantUnderwriting: PropTypes.bool,
}

export default reduxForm({
  form: REJECT_UNDERWRITING_ENTITY_FORM,
  validate: validateRejectUnderwritingEntityForm,
})(RejectUnderwritingEntityForm)
