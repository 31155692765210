import './MerchantAccountInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import formatNumber from 'utilities/formatters/formatNumber'
import formatChartAmount from 'utilities/formatters/formatChartAmount'
import Loader from 'components/Shared/Loader/Loader'


import {
  MERCHANT_ACCOUNTS_ONBOARDED_MERCHANTS,
  MERCHANT_ACCOUNTS_REJECTED_MERCHANTS,
  MERCHANT_ACCOUNTS_ACTIVE_MERCHANTS,
  MERCHANT_ACCOUNTS_MERCHANTS_ONBOARDING_STATE,
  MERCHANT_ACCOUNTS_MERCHANTS_ACTIVE_MERCHANTS,
  MERCHANT_ACCOUNTS_MERCHANTS_BY_LOCATION,
  MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACTION_VOLUME_BY_MERCHANT,
  MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACATION_BY_MCC,
  MERCHANT_ACCOUNTS_TOTAL_TRANSACTION_VOLUME,
  MERCHANT_ACCOUNTS_PROFITABLE_MERCHANTS,
} from 'constants/chartConstants'

import {
  ACTIVE_MERCHANTS,
  TIME_FRAME,
  SUMMARY,
  ONBOARDED_MERCHANTS,
  REJECTED_MERCHANTS,
  PROFITABLE_MERCHANTS,
  MERCHANT_TRENDS,
  TOTAL_TRANSACTION_VOLUME_BY_MERCHANT,
  TOTAL_TRANSACTION_VOLUME_BY_MCC,
  TOTAL_TRANSACTION_VOLUME,
  MERCHANTS_BY_LOCATION,
  ONBOARDEDMERCHANTS_TOOLTIP_MSG,
  REJECTED_MERCHANTS_TOOLTIP_MSG,
  ACTIVE_MERCHANTS_TOOLTIP_MSG,
  PROFITABLE_MERCHANTS_TOOLTIP_MSG,
  TOTAL_TRANSACTION_VOLUME_BY_MERCHANT_TOOLTIP_MSG,
  TOTAL_TRANSACTION_VOLUME_BY_MCC_TOOLTIP_MSG,
  MERCHANTS_BY_LOCATION_TOOLTIP_MSG,
} from 'constants/language/languageConstants'


const MerchantAccountInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isFlex = false,
  isRolePlatformCredential = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='MerchantAccountInsights flex column'>
      <div className='time-frame-container flex space-between'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{TIME_FRAME}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>Summary of your merchant accounts withing the filtered time frame.</p>
                <p><span className='bold'>{ONBOARDED_MERCHANTS}</span>{ONBOARDEDMERCHANTS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{REJECTED_MERCHANTS}</span>{REJECTED_MERCHANTS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{ACTIVE_MERCHANTS}</span>{ACTIVE_MERCHANTS_TOOLTIP_MSG}</p>
                <p><span className='bold'>{PROFITABLE_MERCHANTS}</span>{PROFITABLE_MERCHANTS_TOOLTIP_MSG}</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={ONBOARDED_MERCHANTS}
            headerData={{
              name: MERCHANT_ACCOUNTS_ONBOARDED_MERCHANTS,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
          <CustomBigNumberC
            title={REJECTED_MERCHANTS}
            headerData={{
              name: MERCHANT_ACCOUNTS_REJECTED_MERCHANTS,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />
          <CustomBigNumberC
            title={ACTIVE_MERCHANTS}
            headerData={{
              name: MERCHANT_ACCOUNTS_ACTIVE_MERCHANTS,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ minHeight: '100px' }}
          />

          { isFlex && (
            <CustomBigNumberC
              title={PROFITABLE_MERCHANTS}
              headerData={{
                name: MERCHANT_ACCOUNTS_PROFITABLE_MERCHANTS,
                formatter: (count) => `${formatNumber(count)}`,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />
          )}
          { !isFlex && (
            <CustomBigNumberC
              title={TOTAL_TRANSACTION_VOLUME}
              headerData={{
                name: MERCHANT_ACCOUNTS_TOTAL_TRANSACTION_VOLUME,
                formatter: formatChartAmount,
              }}
              timeRange={timeRange}
              style={{ minHeight: '100px' }}
            />
          )}
        </div>

        <GroupedCharts
          title={MERCHANT_TRENDS}
          classNames='merchant-trends'
          chartClassNames='merchant-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            { name: MERCHANT_ACCOUNTS_MERCHANTS_ONBOARDING_STATE, height: 260 },
            { name: MERCHANT_ACCOUNTS_MERCHANTS_ACTIVE_MERCHANTS, height: 260 },
          ]}
        />

        <div className='mtl'>
          <ChartC
            title={TOTAL_TRANSACTION_VOLUME_BY_MERCHANT}
            name={MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACTION_VOLUME_BY_MERCHANT}
            height={484}
            className='table'
            timeRange={timeRange}
            hideTitle
            tooltipMessage={<p>{TOTAL_TRANSACTION_VOLUME_BY_MERCHANT_TOOLTIP_MSG}</p>}
          />
        </div>


        <div className='mtl'>
          <ChartC
            title={TOTAL_TRANSACTION_VOLUME_BY_MCC}
            name={MERCHANT_ACCOUNTS_MERCHANTS_TOTAL_TRANSACATION_BY_MCC}
            height={280}
            className='table'
            timeRange={timeRange}
            hideTitle
            tooltipMessage={<p>{TOTAL_TRANSACTION_VOLUME_BY_MCC_TOOLTIP_MSG}</p>}
          />
        </div>
        <div className='mtl'>
          <ChartC
            title={MERCHANTS_BY_LOCATION}
            name={MERCHANT_ACCOUNTS_MERCHANTS_BY_LOCATION}
            height={280}
            className='table'
            timeRange={timeRange}
            hideTitle
            tooltipMessage={<p>{MERCHANTS_BY_LOCATION_TOOLTIP_MSG}</p>}
          />
        </div>
      </div>
    </div>
  )
}

MerchantAccountInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  isFlex: PropTypes.bool,
  isRolePlatformCredential: PropTypes.bool,
}

export default MerchantAccountInsights
