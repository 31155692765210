import removeUndefined from 'utilities/remove/removeUndefined'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import includes from 'lodash/includes'

const backendDeviceModel = ({ values }) => {
  const {
    deviceName,
    model,
    description,
    tags,
    allowDebit,
    bypassDeviceOnCapture,
    promptSignature,
    checkForDuplicateTransactions,
    promptAmountConfirmation,
    promptManualEntryOfCardInformation,
    signatureThresholdAmount,
    idleMessage,
    idleImageFileId,
    enabled,
    allowCashback,
    allowGiftCards,
    allowManualEntryOfCardInformation,
    allowStandaloneAuthorizations,
    allowStandaloneSales,
    allowStandaloneRefunds,
    serialNumber,
    action,
    activationCode,
    displayTipOnReceipt,
    percentTippingThreshold,
    percentOptions,
    fixedOptions,
    allowCustomTip,
    promptTipOnScreen,
    promptReceiptConfirmation,
    automaticReceiptDeliveryMethods,
    availableReceiptMethods,
    processorGateway,
    promptForSignature,
  } = values

  const deviceModelValue = get(model, 'value')
  const promptSignatureValue = get(promptSignature, 'value')
  const normalizedFixedOptions = map(fixedOptions, (amount) => normalizeBackendAmount(amount)) // doing this here because this is an array of amounts, not an array of individual fields
  const useConfigDetails = !includes(['TRIPOS_CLOUD_V1', 'DATACAP_V1', 'TRIPOS_MOBILE_V1'], processorGateway)
  const normalizedPercentTippingThreshold = normalizeBackendAmount(percentTippingThreshold)

  const configurationDetailValues = removeUndefined({
    allow_debit: allowDebit,
    bypass_device_on_capture: bypassDeviceOnCapture,
    prompt_signature: promptSignatureValue,
    check_for_duplicate_transactions: checkForDuplicateTransactions,
    prompt_amount_confirmation: promptAmountConfirmation,
    prompt_manual_entry: promptManualEntryOfCardInformation,
    signature_threshold_amount: signatureThresholdAmount,
    allow_standalone_authorizations: allowStandaloneAuthorizations,
    allow_standalone_sales: allowStandaloneSales,
    allow_standalone_refunds: allowStandaloneRefunds,
    is_cash_back_allowed: allowCashback,
    is_gift_supported: allowGiftCards,
    is_manual_entry_allowed: allowManualEntryOfCardInformation,
    display_tip_on_receipt: displayTipOnReceipt,
    prompt_tip_on_screen: promptTipOnScreen,
    tipping_details: promptTipOnScreen ? {
      percent_tipping_threshold: normalizedPercentTippingThreshold,
      percent_options: percentOptions,
      fixed_options: normalizedFixedOptions,
      allow_custom_tip: allowCustomTip,
    } : undefined,
    prompt_receipt_confirmation: promptReceiptConfirmation,
    automatic_receipt_delivery_methods: automaticReceiptDeliveryMethods,
    available_receipt_methods: availableReceiptMethods,
    idle_message: useConfigDetails ? idleMessage : undefined,
    idle_image_file_id: idleImageFileId,
    prompt_for_signature: promptForSignature,
  })

  return removeUndefined({
    configuration: !isEmpty(configurationDetailValues) ? configurationDetailValues : undefined,
    description,
    model: deviceModelValue,
    name: deviceName,
    tags,
    serial_number: serialNumber,
    idle_message: useConfigDetails ? undefined : idleMessage,
    enabled,
    action,
    activation_code: activationCode,
  })
}

export default backendDeviceModel
