import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import getSettlementsAPI from 'api/finix/get/getSettlementsAPI'
import { getState } from 'state-layer/configureStore'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import get from 'lodash/get'
import co from 'co'

function * getTransferFeesSettlementEntriesO ({
  api,
  items,
  credentials,
  frontendModel = ({ data }) => data,
}) {
  // only perform orchestration when debit filter is applied
  const state = getState()
  const typeDebitFilter = get(state, 'tableFiltersR.type.eq') === 'debit'
  if (!typeDebitFilter) return {}

  // filter out related fee arrays
  const filteredItems = filter(items, ({ id }) => id)

  const transferSettlements = yield map(filteredItems, ({ id: transferId }) => {
    // fetch doesn't work on TRxxx in exception buckets so using FExxx instead
    const feeId = 'FE'.concat(transferId.slice(2))

    return getSettlementsAPI({ credentials, queries: { entry_id: feeId } })
  })

  // get list of unique settlements to fetch entries for
  const newSettlements = reduce(transferSettlements, (total, item) => {
    const settlementId = get(item, 'data._embedded.settlements.0.id')
    const totalDoesNotContainSettlement = !get(total, settlementId)

    return totalDoesNotContainSettlement ? merge({}, total, {
      [settlementId]: true,
    }) : total
  }, {})

  // uses getSettlementEntriesO which fetches ALL (not just first 20) entries of given settlement
  const responses = yield map(newSettlements, (value, settlementId) => {
    return api({ id: settlementId, credentials })
  })

  const normalizedResponses = normalizeResponse({ content: responses })

  const relatedResourcesData = reduce(normalizedResponses, (total, { data }) => {
    const keyedData = keyBy(data, 'id')

    return merge({}, total, keyedData)
  }, {})

  const relatedResourcesModels = frontendModel({ data: relatedResourcesData })

  return createSuccessResponse({
    data: relatedResourcesModels,
  })
}

export default co.wrap(getTransferFeesSettlementEntriesO)
