import './TableItemSubtitleS.scss'
import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

const TableItemSubtitle = ({
  title = '',
  titleComponent,
  subtitle = '',
  subtitleCondition = true,
  subtitleIndent = false,
  emptySignifierCheck = true,
}) => {
  const subtitleClassnames = classnames({
    subtitle: true,
    'p-3': true,
    indent: subtitleIndent,
  })

  const noValues = isEmpty(title) && isEmpty(subtitle) && !titleComponent

  return (
    <div className='TableItemSubtitle'>
      {noValues ? '-' : (
        <>
          {titleComponent && titleComponent()}
          <div className='title' title={title}>{(isEmpty(title) && !titleComponent) ? '-' : title}</div>
          {subtitleCondition && !isEmpty(subtitle) && <div className={subtitleClassnames} title={subtitle}>{subtitle}</div>}
          {subtitleCondition && isEmpty(subtitle) && emptySignifierCheck && <div className={subtitleClassnames}>-</div>}
        </>
      )}
    </div>
  )
}

TableItemSubtitle.propTypes = {
  title: PropTypes.string,
  titleComponent: PropTypes.func,
  subtitle: PropTypes.string,
  subtitleCondition: PropTypes.bool,
  subtitleIndent: PropTypes.bool,
  emptySignifierCheck: PropTypes.bool,
}

export default TableItemSubtitle
