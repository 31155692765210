import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import { httpCodeStatusMap } from 'constants/statusConstants'
import capitalize from 'lodash/capitalize'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'

const frontendWebhookLogModel = ({ data: webhookLog }) => {
  const [
    id,
    webhookId,
    eventId,
    nickname,
    url,
    attemptCount,
    platformId,
    applicationId,
    responseStatusCode,
    eventCreatedAt,
    eventAttemptedAt,
    entityType,
    eventType,
    createdAt,
    insertedAt,
    webhookEventHeaders,
    webhookEvent,
    webhook,
  ] = getMany(webhookLog, [
    'id',
    'webhook_id',
    'system_generated_idempotency_id',
    'nickname',
    'url',
    'event_attempt_index',
    'platform_id',
    'application_id',
    'response_status',
    'event_created_at',
    'event_attempted_at',
    'entity_type',
    'event_type',
    'created_at',
    'inserted_at',
    'webhook_event_headers',
    'webhook_event',
    'webhook',
  ])

  const responseStatusCodeFirstDigit = responseStatusCode?.charAt(0)

  return removeUndefined({
    id,
    webhookId,
    eventId,
    nickname,
    url,
    attemptCount,
    platformId,
    applicationId,
    responseStatusCode,
    responseStatus: httpCodeStatusMap[responseStatusCodeFirstDigit],
    eventCreatedAt,
    eventAttemptedAt,
    entityType,
    eventType,
    createdAt,
    displayEntityType: convertSnakeToSentenceCase(entityType),
    displayEventType: snakeCaseToTitleCase({ key: eventType }),
    displayCreatedAt: formatDate({ date: createdAt }),
    displayEventCreatedAt: formatDate({ date: eventCreatedAt }),
    displayEventAttemptedAt: formatDate({ date: eventAttemptedAt }),
    insertedAt,
    webhookEventHeaders,
    webhookEventHeadersObj: isString(webhookEventHeaders) && !isEmpty(webhookEventHeaders) && JSON.parse(webhookEventHeaders),
    webhookEvent,
    webhook,
  })
}

export default frontendWebhookLogModel
