import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import get from 'lodash/get'

// TODO: write unit tests for this function
const convertGooglePlaceToAddressObject = (place) => {
  const addressComponents = get(place, 'address_components', [])

  return reduce(addressComponents, (total, component = {}) => {
    const {
      types,
      long_name: longName,
      short_name: shortName,
    } = component

    if (includes(types, 'street_number')) total.line1 += longName
    if (includes(types, 'route')) total.line1 += ` ${longName}`
    if (includes(types, 'subpremise')) total.line2 = longName
    if (includes(types, 'locality')) total.city = longName
    if (includes(types, 'administrative_area_level_1')) total.region = shortName
    if (includes(types, 'postal_code')) total.postalCode = longName
    if (includes(types, 'country')) total.country = shortName

    return total
  }, {
    line1: '',
    line2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
  })
}

export default convertGooglePlaceToAddressObject
