import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import normalizeObject from 'utilities/api/normalizeObject'
import normalizeArray from 'utilities/api/normalizeArray'
import { frontendNormalizationMap } from 'constants/normalizationConstants'

const normalizeResponse = ({
  content,
  normalizationMap = frontendNormalizationMap,
}) => {
  if (isArray(content)) {
    return normalizeArray(content, normalizationMap)
  }

  if (isObject(content)) {
    return normalizeObject(content, normalizationMap)
  }

  return content
}

export default normalizeResponse
