import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingProfile from './UnderwritingProfile'
import UnderwritingWorkflowsColumnDescriptors from './UnderwritingWorkflowsColumnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import getUnderwritingWorkflowByApplicationIdRequest from 'utilities/actions/get/getUnderwritingWorkflowByApplicationIdRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import showModalAction from 'utilities/actions/showModalAction'
import hasPermissions from 'utilities/hasPermissions'
import getMany from 'utilities/get/getMany'
import { finixMCCsList } from 'constants/mccConstants'
import { FETCHING } from 'constants/reducerConstants'
import map from 'lodash/map'
import size from 'lodash/size'
import mapKeys from 'lodash/mapKeys'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import get from 'lodash/get'

import {
  isRolePartner,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  getUnderwritingProfileByEntityId,
  getUnderwritingProfileByPlatform,
  getUnderwritingWorkflowsSelector,
} from 'state-layer/selectors'

import {
  FINIX_V1,
  LITLE_V1,
  VANTIV_V1,
} from 'constants/processorConstants'

import {
  CREATE_UNDERWRITING_PROFILE_PERMISSION,
  UPDATE_UNDERWRITING_PROFILE_PERMISSION,
} from 'constants/permissionConstants'

import {
  ADVERSE_MEDIA_REVIEW_CADENCE,
  ALL,
  EDIT,
  MAX_ACH_TRANSACTION_AMOUNT_LABEL,
  MAX_CARD_TRANSACTION_AMOUNT_LABEL,
  MERCHANT_UNDERWRITING_RESOURCE_TITLE,
  RECIPIENT_UNDERWRITING_RESOURCE_TITLE,
  SANCTIONS_SCREENING_CADENCE,
  UNDERWRITING_PROFILE_ROLE_PARTNER_TOOLTIP,
  UNDERWRITING_PROFILE_ROLE_PLATFORM_TOOLTIP,
  CREATE,
  INDIVIDUAL_RECIPIENT_ENABLED,
  BUSINESS_RECIPIENT_ENABLED,
} from 'constants/language/languageConstants'

import {
  FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID,
  FINIX_V1_USA_PROCESSOR_WORKFLOW_ID,
  LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID,
  recipientWorkflowByEnvironmentMap,
} from 'constants/underwritingConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

import {
  CONTINUOUS_MONITORING_SETTINGS_MODAL,
  EDIT_UNDERWRITING_PROFILE_MODAL,
  EDIT_UNDERWRITING_WORKFLOW_MODAL,
  MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL,
  UPDATE_RECIPIENT_SETTINGS_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const recipientWorkflowId = recipientWorkflowByEnvironmentMap[environment]
  const isRolePartnerCredential = isRolePartner({ credentials })
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const underwritingWorkflows = getUnderwritingWorkflowsSelector(state)
  const platformId = get(props, 'platformId')
  const applicationId = get(props, 'applicationId')
  const isFetching = get(state, `underwritingProfilesR.${FETCHING}`)
  const isApplicationInfoPage = get(props, 'isApplicationInfoPage')
  const entityId = (!isRolePartnerCredential && !isApplicationInfoPage) ? platformId : applicationId
  const hasEditUnderwritingProfilePermission = hasPermissions([UPDATE_UNDERWRITING_PROFILE_PERMISSION, CREATE_UNDERWRITING_PROFILE_PERMISSION])
  const enabledProcessors = map(get(props, 'processors'), 'processor')
  const isFinixV1Enabled = includes(enabledProcessors, FINIX_V1)
  const isLitleVantivV1Enabled = includes(enabledProcessors, LITLE_V1) || includes(enabledProcessors, VANTIV_V1)
  const showUnderwritingAutomationSettingsSection = (isFinixV1Enabled || isLitleVantivV1Enabled) && hasEditUnderwritingProfilePermission
  const isDisbursementsApplication = get(props, 'isDisbursementsApplication', false)

  // we should always select the data from reducer by entity id except for when a role platform is looked at a company page
  let entityUnderwritingProfile = getUnderwritingProfileByEntityId(state, applicationId)
  if (!isApplicationInfoPage && !isRolePartnerCredential) {
    entityUnderwritingProfile = getUnderwritingProfileByPlatform(state)
  }

  const [
    underwritingProfileId,
    achMaxTransactionAmount,
    maxTransactionAmount,
    displayAchMaxTransactionAmount,
    displayMaxTransactionAmount,
    mccDetails,
    displayBiometricDataCollection,
    displayAdverseMediaCadence,
    displaySanctionsScreeningCadence,
    displayPersonalRecipientsEnabled,
    displayBusinessRecipientsEnabled,
  ] = getMany(entityUnderwritingProfile, [
    'id',
    'achMaxTransactionAmount',
    'maxTransactionAmount',
    'displayAchMaxTransactionAmount',
    'displayMaxTransactionAmount',
    'mccDetails',
    'displayBiometricDataCollection',
    'displayAdverseMediaCadence',
    'displaySanctionsScreeningCadence',
    'displayPersonalRecipientsEnabled',
    'displayBusinessRecipientsEnabled',
  ])

  const enabledMCCs = get(mccDetails, 'enabledMCCs')
  const allowAllMCCs = get(mccDetails, 'allowAllMCCs')
  const enabledMCCsCount = size(enabledMCCs)
  const totalMCCsString = `Total MCCs: ${enabledMCCsCount}`
  const areTransactionLimitsEmpty = displayMaxTransactionAmount === undefined && displayAchMaxTransactionAmount === undefined
  const isMCCsEmpty = enabledMCCsCount === 0 && allowAllMCCs === false
  const isUnderwritingProfileEmpty = enabledMCCsCount === 0 && areTransactionLimitsEmpty

  const transactionLimitsSectionData = convertPageSectionDataToV2([
    {
      label: MAX_CARD_TRANSACTION_AMOUNT_LABEL,
      value: displayMaxTransactionAmount,
    },
    {
      label: MAX_ACH_TRANSACTION_AMOUNT_LABEL,
      value: displayAchMaxTransactionAmount,
    },
  ])

  const continuousMonitoringSettingsData = convertPageSectionDataToV2([
    {
      label: ADVERSE_MEDIA_REVIEW_CADENCE,
      value: displayAdverseMediaCadence,
    },
    {
      label: SANCTIONS_SCREENING_CADENCE,
      value: displaySanctionsScreeningCadence,
    },
  ])

  const recipientSettingsData = convertPageSectionDataToV2([
    {
      label: INDIVIDUAL_RECIPIENT_ENABLED,
      value: displayPersonalRecipientsEnabled,
    },
    {
      label: BUSINESS_RECIPIENT_ENABLED,
      value: displayBusinessRecipientsEnabled,
    },
  ])

  const clickToCopies = [
    {
      content: underwritingProfileId,
    },
  ]

  const enabledMCCsTableItems = map(enabledMCCs, (mcc) => {
    return {
      mcc,
      description: finixMCCsList[mcc],
    }
  })

  const litleVantivV1Workflows = [
    {
      id: LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID,
      workflow: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
      processor: 'LITLE_V1 / VANTIV_V1',
      country: USA,
      actOnDecision: get(underwritingWorkflows, `${LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID}.actOnDecision`, false),
    },
  ]

  const finixV1Workflows = [
    {
      id: FINIX_V1_USA_PROCESSOR_WORKFLOW_ID,
      workflow: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
      processor: FINIX_V1,
      country: USA,
      actOnDecision: get(underwritingWorkflows, `${FINIX_V1_USA_PROCESSOR_WORKFLOW_ID}.actOnDecision`, false),
    },
    {
      id: FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID,
      workflow: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
      processor: FINIX_V1,
      country: CAN,
      actOnDecision: get(underwritingWorkflows, `${FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID}.actOnDecision`, false),
    },
    {
      id: recipientWorkflowId,
      workflow: RECIPIENT_UNDERWRITING_RESOURCE_TITLE,
      processor: FINIX_V1,
      country: ALL,
      actOnDecision: get(underwritingWorkflows, `${recipientWorkflowId}.actOnDecision`, false),
    },
  ]

  const workflowsTableItems = mapKeys(concat(isLitleVantivV1Enabled ? litleVantivV1Workflows : [], isFinixV1Enabled ? finixV1Workflows : []), 'id')

  const extraProps = {
    applicationId,
    environment,
  }

  return {
    credentials,
    isFetching,
    isRolePartnerCredential,
    transactionLimitsSectionData,
    clickToCopies,
    enabledMCCs,
    underwritingProfileId,
    enabledMCCsTableItems,
    areTransactionLimitsEmpty,
    enabledMCCsCount,
    totalMCCsString,
    isUnderwritingProfileEmpty,
    isMCCsEmpty,
    maxTransactionAmount,
    achMaxTransactionAmount,
    allowAllMCCs,
    isApplicationInfoPage,
    entityId,
    hasEditUnderwritingProfilePermission,
    showUnderwritingAutomationSettingsSection,
    workflowsTableItems,
    isLitleVantivV1Enabled,
    isFinixV1Enabled,
    environment,
    recipientWorkflowId,
    extraProps,
    underwritingProfile: entityUnderwritingProfile,
    displayBiometricDataCollection,
    continuousMonitoringSettingsData,
    recipientSettingsData,
    isDisbursementsApplication,
    isRolePlatformCredential,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
    showEditUnderwritingProfileModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_UNDERWRITING_PROFILE_MODAL, modalProps })),
    showEditUnderwritingWorkflowModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_UNDERWRITING_WORKFLOW_MODAL, modalProps, className: 'modal-md no-pad' })),
    getUnderwritingWorkflowByApplicationId: ({ workflowId, credentials, queries, meta }) => dispatch(getUnderwritingWorkflowByApplicationIdRequest({ workflowId, credentials, queries, meta })),
    showMerchantOnboardingFormSettingsModal: ({ underwritingProfile }) => dispatch(showModalAction({
      modalType: MERCHANT_ONBOARDING_FORM_SETTINGS_MODAL,
      modalProps: {
        underwritingProfile,
      },
      className: 'modal-md no-pad overflow-visible',
    })),
    showContinuousMonitoringSettingsModal: ({ underwritingProfile }) => dispatch(showModalAction({
      modalType: CONTINUOUS_MONITORING_SETTINGS_MODAL,
      modalProps: { underwritingProfile },
      className: 'modal-md no-pad overflow-visible',
    })),
    showRecipientSettingsModal: ({ underwritingProfile }) => dispatch(showModalAction({
      modalType: UPDATE_RECIPIENT_SETTINGS_MODAL,
      modalProps: { underwritingProfile },
      className: 'modal-sm no-pad overflow-visible',
    })),
  }
}

class UnderwritingProfileC extends Component {
  componentDidMount() {
    const {
      credentials,
      platformId,
      applicationId,
      getEntityUnderwritingProfile,
      isRolePartnerCredential,
      isApplicationInfoPage,
    } = this.props

    if (!isRolePartnerCredential && !isApplicationInfoPage) {
      getEntityUnderwritingProfile({ credentials, queries: { linked_to: platformId } })
    } else {
      getEntityUnderwritingProfile({ credentials, queries: { linked_to: applicationId, admin: true } })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      credentials,
      applicationId,
      isFinixV1Enabled,
      isLitleVantivV1Enabled,
      getUnderwritingWorkflowByApplicationId,
      recipientWorkflowId,
    } = this.props

    const {
      isFinixV1Enabled: prevIsFinixV1Enabled,
      isLitleVantivV1Enabled: prevIsLitleVantivV1Enabled,
    } = prevProps

    /*
   underwriting workflows are what enabled or disables underwriting automation. the actOnDecision field is what determines if automated underwriting is enabled for a specific processor and country.
   currently we're fetching them by hard-coding the workflow IDs because there's no API that returns a list of available workflows for an application.
     */
    if (isFinixV1Enabled && isFinixV1Enabled !== prevIsFinixV1Enabled) {
      getUnderwritingWorkflowByApplicationId({ workflowId: FINIX_V1_USA_PROCESSOR_WORKFLOW_ID, credentials, queries: { linked_to: applicationId } })
      getUnderwritingWorkflowByApplicationId({ workflowId: FINIX_V1_CAN_PROCESSOR_WORKFLOW_ID, credentials, queries: { linked_to: applicationId } })
      getUnderwritingWorkflowByApplicationId({ workflowId: recipientWorkflowId, credentials, queries: { linked_to: applicationId } })
    }

    if (isLitleVantivV1Enabled && isLitleVantivV1Enabled !== prevIsLitleVantivV1Enabled) {
      getUnderwritingWorkflowByApplicationId({ workflowId: LITLE_V1_VANTIV_V1_USA_PROCESSOR_WORKFLOW_ID, credentials, queries: { linked_to: applicationId } })
    }
  }

  render() {
    const {
      showEditUnderwritingProfileModal,
      showEditUnderwritingWorkflowModal,
      underwritingProfile,
      underwritingProfileId,
      credentials,
      isRolePartnerCredential,
      isApplicationInfoPage,
      entityId,
      hasEditUnderwritingProfilePermission,
      showMerchantOnboardingFormSettingsModal,
      showContinuousMonitoringSettingsModal,
      isUnderwritingProfileEmpty,
      showRecipientSettingsModal,
      isRolePlatformCredential,
    } = this.props


    const showEditModal = () => showEditUnderwritingProfileModal({
      underwritingProfileId,
      credentials,
      isRolePartnerCredential,
      isApplicationInfoPage,
      entityId,
    })

    const underwritingProfileActions = [
      {
        label: isUnderwritingProfileEmpty ? CREATE : EDIT,
        buttonClassName: 'edit-underwriting-profile-button',
        action: showEditModal,
        condition: isRolePlatformCredential && hasEditUnderwritingProfilePermission,
      },
    ]

    const merchantOnboardingFormSettingsActions = [
      {
        label: EDIT,
        action: () => showMerchantOnboardingFormSettingsModal({ underwritingProfile }),
        condition: isRolePlatformCredential && hasEditUnderwritingProfilePermission && !isUnderwritingProfileEmpty,
      },
    ]

    const continuousMonitoringSettingsActions = [
      {
        label: EDIT,
        action: () => showContinuousMonitoringSettingsModal({ underwritingProfile }),
        condition: isRolePlatformCredential && hasEditUnderwritingProfilePermission && !isUnderwritingProfileEmpty,
      },
    ]

    const recipientSettingsActions = [
      {
        label: EDIT,
        action: () => showRecipientSettingsModal({ underwritingProfile }),
        condition: isRolePlatformCredential && hasEditUnderwritingProfilePermission && !isUnderwritingProfileEmpty,
      },
    ]

    let tooltipMessage = UNDERWRITING_PROFILE_ROLE_PARTNER_TOOLTIP
    if (!isRolePartnerCredential && !isApplicationInfoPage) {
      tooltipMessage = UNDERWRITING_PROFILE_ROLE_PLATFORM_TOOLTIP
    }

    const columnActions = {
      showEditUnderwritingWorkflowModal,
    }

    const normalizedWorkflowsColumnDescriptors = UnderwritingWorkflowsColumnDescriptors({ columnActions })

    return (
      <UnderwritingProfile
        underwritingProfileActions={underwritingProfileActions}
        merchantOnboardingFormSettingsActions={merchantOnboardingFormSettingsActions}
        showEditModal={showEditModal}
        workflowsColumnDescriptors={normalizedWorkflowsColumnDescriptors}
        isApplicationInfoPage={false}
        tooltipMessage={tooltipMessage}
        continuousMonitoringSettingsActions={continuousMonitoringSettingsActions}
        recipientSettingsActions={recipientSettingsActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingProfileC)
