import './ResendUnacceptedEventsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ResendUnacceptedEventsC from 'components/Customer/Pages/ResendUnacceptedEvents/ResendUnacceptedEventsC'
import { RESEND_UNACCEPTED_EVENTS } from 'constants/language/languageConstants'

const ResendUnacceptedEventsModal = ({
  webhookId = '',
  credentials,
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title={RESEND_UNACCEPTED_EVENTS}
      className='ResendUnacceptedEventsModal'
      Component={ResendUnacceptedEventsC}
      webhookId={webhookId}
      credentials={credentials}
      closeModal={closeModal}
    />
  )
}

ResendUnacceptedEventsModal.propTypes = {
  webhookId: PropTypes.string,
  credentials: PropTypes.object,
  closeModal: PropTypes.func,
}

export default ResendUnacceptedEventsModal
