import React from 'react'
import PropTypes from 'prop-types'
import FilesC from 'components/Customer/Pages/Files/FilesC'
import { MERCHANT_FILES } from 'constants/linkConstants'
import { GET_MERCHANT_FILES } from 'constants/flowConstants'
import defaultColumnDescriptors from './columnDescriptors'

const MerchantFiles = ({
  merchantId = '',
  merchant,
  showUploadButton = false,
  fileNameCSV = '',
  data,
  title = '',
  urlQueriesToIgnore = [],
  selector,
  flow = GET_MERCHANT_FILES,
  columnDescriptors = defaultColumnDescriptors,
}) => {
  return (
    <div className='MerchantFiles'>
      <FilesC
        merchantId={merchantId}
        merchant={merchant}
        flow={flow}
        linksKey={MERCHANT_FILES}
        fileNameCSV={fileNameCSV}
        showUploadButton={showUploadButton}
        title={title}
        urlQueriesToIgnore={urlQueriesToIgnore}
        selector={selector}
        data={data}
        columnDescriptors={columnDescriptors}
        initialQueries={{ linked_to: merchantId }}
      />
    </div>
  )
}

MerchantFiles.propTypes = {
  merchantId: PropTypes.string,
  merchant: PropTypes.object,
  showUploadButton: PropTypes.bool,
  fileNameCSV: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
  urlQueriesToIgnore: PropTypes.array,
}

export default MerchantFiles
