import './UnderwritingApplicationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'

const UnderwritingApplication = ({
  headerData = {},
  actions = [],
  tabs = [],
  isFetching = false,
}) => {
  return (
    <div className='UnderwritingApplication'>
      <ContextBar />

      <HeaderV2C
        {...headerData}
        actions={actions}
      />

      <TabsC
        tabs={tabs}
        isFetching={isFetching}
        persistQueries={['subjectId', 'entityType', 'entityId']}
      />

    </div>
  )
}

UnderwritingApplication.propTypes = {
  headerData: PropTypes.object,
  actions: PropTypes.array,
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  entityId: PropTypes.string,
  tabs: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default UnderwritingApplication
