import React, { Component } from 'react'
import { connect } from 'react-redux'
import BalanceTransfers from './BalanceTransfers'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hasPermission from 'utilities/hasPermission'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_BALANCE_TRANSFER } from 'constants/modalConstants'
import { BALANCE_TRANSFERS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  BALANCE_TRANSFER_CREATE_PERMISSION,
  BALANCE_TRANSFER_READ_PERMISSION,
} from 'constants/permissionConstants'

const mapStateToProps = (state) => {
  const isFetching = get(state, `balanceTransfersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const canCreateBalanceTransfer = hasPermission(state, BALANCE_TRANSFER_CREATE_PERMISSION)
  const canReadBalanceTransfer = hasPermission(state, BALANCE_TRANSFER_READ_PERMISSION)
  const balanceTransferPath = canReadBalanceTransfer ? BALANCE_TRANSFERS_PATH({ credentialId }) : undefined

  return {
    credentials,
    isFetching,
    canCreateBalanceTransfer,
    balanceTransferPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateBalanceTransferModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_BALANCE_TRANSFER, modalProps })),
  }
}

class BalanceTransfersC extends Component {
  render() {
    const {
      showCreateBalanceTransferModal,
      canCreateBalanceTransfer,
      credentials,
    } = this.props

    const actions = canCreateBalanceTransfer ? [
      {
        label: 'Create Balance Transfer',
        action: () => {
          showCreateBalanceTransferModal({ credentials })
        },
        className: 'create-balance-transfer-button',
      },
    ] : []

    return (
      <BalanceTransfers
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceTransfersC)
