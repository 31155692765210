import removeUndefined from 'utilities/remove/removeUndefined'

const backendMerchantModel = ({ values }) => {
  const {
    processingEnabled,
    settlementEnabled,
    processorSpecificParameters,
    settlementFundingIdentifier,
    cardCvvRequired,
    convenienceChargesEnabled,
    cardExpirationDateRequired,
    levelTwoLevelThreeDataEnabled,
    rentSurchargesEnabled,
    surchargesEnabled,
    readyToSettleUpon,
    feeReadyToSettleUpon,
    visaMerchantVerificationValue,
    mastercardAssignedId,
  } = values

  return removeUndefined({
    processing_enabled: processingEnabled,
    settlement_enabled: settlementEnabled,
    processor_specific_parameters: processorSpecificParameters,
    settlement_funding_identifier: settlementFundingIdentifier,
    card_cvv_required: cardCvvRequired,
    convenience_charges_enabled: convenienceChargesEnabled,
    card_expiration_date_required: cardExpirationDateRequired,
    level_two_level_three_data_enabled: levelTwoLevelThreeDataEnabled,
    rent_surcharges_enabled: rentSurchargesEnabled,
    surcharges_enabled: surchargesEnabled,
    ready_to_settle_upon: readyToSettleUpon,
    fee_ready_to_settle_upon: feeReadyToSettleUpon,
    card_network_details: {
      visa_merchant_verification_value: visaMerchantVerificationValue,
      mastercard_assigned_id: mastercardAssignedId,
    },
  })
}

export default backendMerchantModel
