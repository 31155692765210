import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import { createdAtRange } from 'utilities/table/sort'
import column from 'utilities/table/column'
import { enabledTruthyMap } from 'constants/statusConstants'
import toString from 'lodash/toString'

import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  CREATED_ON,
  COUNTRY,
  DISABLED,
  ENABLED,
  ID,
  NAME,
  STATE,
  ROUTING_NUMBER,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'medium',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
    sort: createdAtRange,
  }),
  column(NAME, 'name', { className: 'medium' }),
  column(ACCOUNT_NUMBER, 'information.maskedAccountNumber', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={ACCOUNT_NUMBER} subtitle={ROUTING_NUMBER} />,
    formatter: ({ information }) => (
      <TableItemSubtitle
        title={information.maskedAccountNumber}
        subtitle={information.bankCode}
      />
    ),
  }),
  column(ACCOUNT_TYPE, 'displayAccountType', { className: 'medium' }),
  column(COUNTRY, 'country', {
    formatter: ({ country }) => <CountryFlagIcon country={country} />,
  }),
  column(STATE, 'enabled', {
    className: 'state',
    formatter: ({ enabled }) => <ColorcodedStatus value={toString(enabled)} data={enabledTruthyMap} customValue={enabled ? ENABLED : DISABLED} />,
  }),
]

export default columnDescriptors
