import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'

const frontendBalanceAdjustmentModel = ({ data: balanceAdjustment }) => {
  const [
    id,
    createdAt,
    updatedAt,
    amount,
    currency,
    estimateAvailabilityDate,
    failureCode,
    failureMessage,
    description,
    rail,
    instrumentId,
    state,
    tags,
    balanceEntryId,
  ] = getMany(balanceAdjustment, [
    'id',
    'created_at',
    'updated_at',
    'amount',
    'currency',
    'estimate_availability_date',
    'failure_code',
    'failure_message',
    'description',
    'rail',
    'instrument_id',
    'state',
    'tags',
    'balance_entry_id',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    amount,
    displayAmount: formatMoney({ amount, currency }),
    currency,
    estimateAvailabilityDate,
    displayEstimateAvailabilityDate: formatDate({ date: estimateAvailabilityDate }),
    failureCode,
    displayFailureCode: convertSnakeToSentenceCase(failureCode),
    failureMessage,
    description,
    rail,
    instrumentId,
    state,
    tags,
    balanceEntryId,
  })
}

export default frontendBalanceAdjustmentModel
