import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutsLimitReached from './PayoutsLimitReached'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import redirectRequest from 'utilities/actions/redirectRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { COMPANY_PATH, HOME_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  return {
    credentialId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { credentialId } = ownProps

  return {
    redirectUserToHomePage: () => {
      dispatch(redirectRequest({ path: HOME_PATH({ credentialId }) }))
      dispatch(hideModalAction())
    },
    redirectUserToCompanyPage: () => {
      dispatch(redirectRequest({ path: COMPANY_PATH({ credentialId }) }))
      dispatch(hideModalAction())
    },
    closeModal: () => dispatch(hideModalAction()),
  }
}

class PayoutsLimitReachedC extends Component {
  render() {
    return (
      <PayoutsLimitReached
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutsLimitReachedC)
