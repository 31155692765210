import { getState } from 'state-layer/configureStore'
import { getComplianceSelfAssessmentQuestionnaireASelector } from 'state-layer/selectors'
import formatCustomFieldArrayDisplayData from 'utilities/formatters/formatCustomFieldArrayDisplayData'
import get from 'lodash/get'
import map from 'lodash/map'
import some from 'lodash/some'

const ComplianceExplanationOfNonApplicabilityFields = [
  {
    name: 'explanationOfNonApplicability',
    label: 'Explanation Of Non-Applicability',
    formatter: (explanationOfNonApplicability) => {
      const state = getState()
      const complianceSelfAssessmentQuestionnaireAValues = getComplianceSelfAssessmentQuestionnaireASelector(state)
      const saqAFormHasNA = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'N/A')

      return (saqAFormHasNA ? formatCustomFieldArrayDisplayData({
        data: explanationOfNonApplicability,
        itemName: 'Explanation',
        optionals: {
          reason_requirement_is_not_applicable: { label: 'Reason' },
        },
      }) : '-')
    },
  },
]

const ComplianceExplanationOfNonApplicabilityDisplayData = (values) => map(ComplianceExplanationOfNonApplicabilityFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name)) : get(values, name),
}))

export default ComplianceExplanationOfNonApplicabilityDisplayData
