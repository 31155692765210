import { GET_IDENTITY_MERCHANTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getIdentityMerchantsRequest = ({
  identityId,
  credentials,
}) => createAction({
  type: GET_IDENTITY_MERCHANTS_F_REQUEST,
  values: {
    identityId,
  },
  meta: {
    selectorId: identityId,
  },
  credentials,
})

export default getIdentityMerchantsRequest
