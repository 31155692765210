import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTION_BILLING } from 'constants/apiConstants'

const getSubscriptionAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: SUBSCRIPTION_BILLING({ subscriptionId: id }),
    credentials,
    meta,
  })
}

export default getSubscriptionAPI
