import invert from 'lodash/invert'
import selectOption from 'utilities/forms/selectOption'
import { PAYMENT_INSTRUMENT_PATH } from 'constants/pathConstants'
import { ALL  } from 'constants/transferConstants'
import { UPDATED_AT_LABEL, CREATED_AT_LABEL } from 'constants/language/languageConstants'

export const BE_UNKNOWN = 'UNKNOWN'
export const BE_VIRTUAL = 'VIRTUAL'
export const BE_PAYMENT_CARD = 'PAYMENT_CARD'
export const BE_PAYMENT_CARD_PRESENT = 'PAYMENT_CARD_PRESENT'
export const BE_BANK_ACCOUNT = 'BANK_ACCOUNT'
export const BE_VANTIV_OMNI_TOKEN = 'VANTIV_OMNI_TOKEN'
export const BE_GOOGLE_PAY = 'GOOGLE_PAY'
export const BE_APPLE_PAY = 'APPLE_PAY'
export const USA = 'USA'
export const NON_USA = 'NON_USA'

export const UNKNOWN = 'Unknown'
export const VIRTUAL = 'Virtual'
export const PAYMENT_CARD = 'Card'
export const PAYMENT_CARD_PRESENT = 'Card Present'
export const BANK_ACCOUNT = 'Bank Account'
export const VANTIV_OMNI_TOKEN = 'Vantiv OmniToken'
export const GOOGLE_PAY = 'Google Pay'
export const APPLE_PAY = 'Apple Pay'

export const MERCHANT_BANK_ACCOUNT = 'MERCHANT_BANK_ACCOUNT'
export const APPLICATION_BANK_ACCOUNT = 'APPLICATION_BANK_ACCOUNT'
export const MERCHANT_PRIMARY_BANK_ACCOUNT = 'MERCHANT_PRIMARY_BANK_ACCOUNT'
export const MERCHANT_SECONDARY_BANK_ACCOUNT = 'MERCHANT_SECONDARY_BANK_ACCOUNT'
export const MERCHANT_TERTIARY_BANK_ACCOUNT = 'MERCHANT_TERTIARY_BANK_ACCOUNT'

export const MERCHANT_BANK_ACCOUNT_FE = 'merchantBankAccount'
export const APPLICATION_BANK_ACCOUNT_FE = 'applicationBankAccount'
export const MERCHANT_PRIMARY_BANK_ACCOUNT_FE = 'merchantPrimaryBankAccount'
export const MERCHANT_SECONDARY_BANK_ACCOUNT_FE = 'merchantSecondaryBankAccount'
export const MERCHANT_TERTIARY_BANK_ACCOUNT_FE = 'merchantTertiaryBankAccount'

export const MERCHANT_BANK_ACCOUNT_DISPLAY = 'Merchant Bank Account'
export const APPLICATION_BANK_ACCOUNT_DISPLAY = 'Application Bank Account'
export const MERCHANT_PRIMARY_BANK_ACCOUNT_DISPLAY = 'Merchant Primary Bank Account'
export const MERCHANT_SECONDARY_BANK_ACCOUNT_DISPLAY = 'Merchant Secondary Bank Account'
export const MERCHANT_TERTIARY_BANK_ACCOUNT_DISPLAY = 'Merchant Tertiary Bank Account'

export const ACH_DISBURSEMENT = 'ACH Disbursement'
export const CARD_DISBURSEMENT = 'Card Disbursement'

const pair = (label, key, path) => ({ label, key, path })

export const PAYMENT_INSTRUMENT_TYPE_OPTIONS = [
  selectOption('All', ALL),
  selectOption('Bank Account', BE_BANK_ACCOUNT),
  selectOption('Card', BE_PAYMENT_CARD),
  selectOption('Card Present', BE_PAYMENT_CARD_PRESENT),
  selectOption('Google Pay', BE_GOOGLE_PAY),
  selectOption('Apple Pay', BE_APPLE_PAY),
]
export const PAYMENT_INSTRUMENT_ENABLED_OPTIONS = [
  selectOption('Yes', 'TRUE'),
  selectOption('No', 'FALSE'),
]


export const FE_TO_BE_PAYMENT_INSTRUMENT_TYPE = {
  [UNKNOWN]: BE_UNKNOWN,
  [VIRTUAL]: BE_VIRTUAL,
  [PAYMENT_CARD]: BE_PAYMENT_CARD,
  [PAYMENT_CARD_PRESENT]: BE_PAYMENT_CARD_PRESENT,
  [BANK_ACCOUNT]: BE_BANK_ACCOUNT,
  [VANTIV_OMNI_TOKEN]: BE_VANTIV_OMNI_TOKEN,
  [GOOGLE_PAY]: BE_GOOGLE_PAY,
  [APPLE_PAY]: BE_APPLE_PAY,
}

export const BE_TO_FE_PAYMENT_INSTRUMENT_TYPE = invert(FE_TO_BE_PAYMENT_INSTRUMENT_TYPE)

const paymentInstrumentPath = (credentialId, paymentInstrumentId) => PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId })

export const PAYMENT_INSTRUMENT_COLUMNS = {
  [PAYMENT_CARD]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Name', 'name'),
    pair('Address', 'address'),
    pair('Brand', 'displayBrand'),
    pair('Masked Number', 'maskedFullCardNumber'),
    pair('Expiration Date', 'expirationDate'),
    pair('Address Verification', 'displayAddressVerification'),
    pair('Security Code Verification', 'displaySecurityCodeVerification'),
    pair('Card Type', 'cardType'),
    pair('Enabled', 'displayEnabled'),
    pair('Issuer Country', 'displayIssuerCountry'),
    pair('Created Via', 'displayCreatedVia'),
  ],
  [PAYMENT_CARD_PRESENT]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    // pair('Fingerprint', 'fingerprint'),
    pair('Name', 'name'),
    // pair('Address', 'address'),
    pair('Expiration Date', 'expirationDate'),
    pair('Masked Number', 'maskedFullCardNumber'),
    pair('Brand', 'brand'),
    // pair('Address Verification', 'addressVerification'),
    // pair('Security Code Verification', 'securityCodeVerification'),
    // pair('Card Type', 'cardType'),
    pair('Enabled', 'displayEnabled'),
    pair('Issuer Country', 'displayIssuerCountry'),
    pair('Created Via', 'displayCreatedVia'),
  ],
  // TODO: look into how we will handle these (most of the data is already available, but why do these types exist?)
  'paymentCard/payout': [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Name', 'name'),
    pair('Brand', 'brand'),
    pair('Last 4', 'maskedCardNumber'),
    pair('Expiration Date', 'expirationDate'),
    pair('Address Verification', 'addressVerification'),
    pair('Security Code Verification', 'securityCodeVerification'),
    pair('Card Type', 'cardType'),
    pair('Enabled', 'displayEnabled'),
    pair('Issuer Country', 'displayIssuerCountry'),
    pair('Created Via', 'displayCreatedVia'),
  ],
  [BANK_ACCOUNT]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Routing Number', 'bankCode'),
    pair('Masked Account Number', 'maskedAccountNumber'),
    pair('Name', 'name'),
    pair('Enabled', 'displayEnabled'),
    pair('Created Via', 'displayCreatedVia'),
  ],
  // TODO: look into how we will handle these (most of the data is already available, but why do these types exist?)
  'bankAccount/payout': [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Routing Number', 'bankCode'),
    pair('Masked Account Number', 'maskedAccountNumber'),
    pair('Name', 'name'),
    pair('Enabled', 'displayEnabled'),
  ],
  [VANTIV_OMNI_TOKEN]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair(UPDATED_AT_LABEL, 'displayUpdatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Brand', 'brand'),
    pair('Last 4', 'lastFour'),
    pair('BIN', 'bin'),
    pair('Expiration Date', 'expirationDate'),
    pair('Currency', 'currency'),
    pair('Enabled', 'displayEnabled'),
  ],
  [GOOGLE_PAY]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Brand', 'brand'),
    pair('Masked Number', 'maskedFullCardNumber'),
    pair('Expiration Date', 'expirationDate'),
    pair('Name', 'name'),
    pair('Enabled', 'displayEnabled'),
    pair('Issuer Country', 'displayIssuerCountry'),
    pair('Created Via', 'displayCreatedVia'),
  ],
  [APPLE_PAY]: [
    pair('Payment Instrument ID', 'id', paymentInstrumentPath),
    pair(CREATED_AT_LABEL, 'displayCreatedAt'),
    pair('Type', 'type'),
    pair('Fingerprint', 'fingerprint'),
    pair('Brand', 'brand'),
    pair('Masked Number', 'maskedFullCardNumber'),
    pair('Expiration Date', 'expirationDate'),
    pair('Name', 'name'),
    pair('Enabled', 'displayEnabled'),
    pair('Issuer Country', 'displayIssuerCountry'),
    pair('Created Via', 'displayCreatedVia'),
  ],
}

export const PAYMENT_INSTRUMENT_CSV_COLUMNS = ['account_number', 'bank_code', 'account_type', 'name']

export const SETTLEMENT_ALIAS_OPTIONS = [
  selectOption('Select Settlement Alias', ''),
  selectOption(MERCHANT_PRIMARY_BANK_ACCOUNT_DISPLAY, MERCHANT_PRIMARY_BANK_ACCOUNT),
  selectOption(MERCHANT_SECONDARY_BANK_ACCOUNT_DISPLAY, MERCHANT_SECONDARY_BANK_ACCOUNT),
  selectOption(MERCHANT_TERTIARY_BANK_ACCOUNT_DISPLAY, MERCHANT_TERTIARY_BANK_ACCOUNT),
]

export const SETTLEMENT_ALIAS_FE_BE_MAPPING = {
  [MERCHANT_BANK_ACCOUNT_FE]: MERCHANT_BANK_ACCOUNT,
  [APPLICATION_BANK_ACCOUNT_FE]: APPLICATION_BANK_ACCOUNT,
  [MERCHANT_PRIMARY_BANK_ACCOUNT_FE]: MERCHANT_PRIMARY_BANK_ACCOUNT,
  [MERCHANT_SECONDARY_BANK_ACCOUNT_FE]: MERCHANT_SECONDARY_BANK_ACCOUNT,
  [MERCHANT_TERTIARY_BANK_ACCOUNT_FE]: MERCHANT_TERTIARY_BANK_ACCOUNT,
}

export const SETTLEMENT_ALIAS_BE_FE_MAPPING = {
  [MERCHANT_BANK_ACCOUNT]: MERCHANT_BANK_ACCOUNT_FE,
  [APPLICATION_BANK_ACCOUNT]: APPLICATION_BANK_ACCOUNT_FE,
  [MERCHANT_PRIMARY_BANK_ACCOUNT]: MERCHANT_PRIMARY_BANK_ACCOUNT_FE,
  [MERCHANT_SECONDARY_BANK_ACCOUNT]: MERCHANT_SECONDARY_BANK_ACCOUNT_FE,
  [MERCHANT_TERTIARY_BANK_ACCOUNT]: MERCHANT_TERTIARY_BANK_ACCOUNT_FE,
}

export const SETTLEMENT_ALIAS_DISPLAY_MAPPING = {
  [MERCHANT_BANK_ACCOUNT]: MERCHANT_BANK_ACCOUNT_DISPLAY,
  [APPLICATION_BANK_ACCOUNT]: APPLICATION_BANK_ACCOUNT_DISPLAY,
  [MERCHANT_PRIMARY_BANK_ACCOUNT]: MERCHANT_PRIMARY_BANK_ACCOUNT_DISPLAY,
  [MERCHANT_SECONDARY_BANK_ACCOUNT]: MERCHANT_SECONDARY_BANK_ACCOUNT_DISPLAY,
  [MERCHANT_TERTIARY_BANK_ACCOUNT]: MERCHANT_TERTIARY_BANK_ACCOUNT_DISPLAY,
}

export const SETTLEMENT_ALIASES_BE = [
  MERCHANT_PRIMARY_BANK_ACCOUNT,
  MERCHANT_SECONDARY_BANK_ACCOUNT,
  MERCHANT_TERTIARY_BANK_ACCOUNT,
]

export const ALT_PAYMENT_METHODS_BE_MAPPING = {
  [APPLE_PAY]: BE_APPLE_PAY,
  [GOOGLE_PAY]: BE_GOOGLE_PAY,
}
