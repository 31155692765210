import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import CustomFieldArray from 'components/Shared/Inputs/CustomFieldArray/CustomFieldArray'
import validateComplianceExplanationOfNonApplicabilityForm from 'utilities/validate/validateComplianceExplanationOfNonApplicabilityForm'
import { COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM } from 'constants/formConstants'
import { EXPLANATION_OF_NON_APPLICABILITY_OPTIONS } from 'constants/complianceConstants'

const ComplianceExplanationOfNonApplicabilityForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  saqAFormHasNA,
}) => {
  return (
    <form className='ComplianceExplanationOfNonApplicabilityForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Appendix C: Explanation of Non-Applicability</h1>
      </div>

      <div className='section'>
        <Field
          name='explanationOfNonApplicability'
          label='If the "N/A" (Not Applicable) column was checked in the questionnaire, use this worksheet to explain why the related requirement is not applicable to your organization. (Max of 16)'
          component={CustomFieldArray}
          fieldOptions={EXPLANATION_OF_NON_APPLICABILITY_OPTIONS}
          addButtonLabel='Requirement'
          maxFields={16}
          disabled={!saqAFormHasNA}
        />
      </div>

      {buttonGroup}
    </form>
  )
}

ComplianceExplanationOfNonApplicabilityForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  saqAFormHasNA: PropTypes.bool,
}

export default reduxForm({
  form: COMPLIANCE_EXPLANATION_OF_NON_APPLICABILITY_FORM,
  validate: validateComplianceExplanationOfNonApplicabilityForm,
})(ComplianceExplanationOfNonApplicabilityForm)
