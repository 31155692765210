import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { normalizeAmount } from 'constants/normalizationConstants'

const frontendHostedFormTokenVerificationModel = (values) => {
  const { data: tokenVerificationData } = values

  const [
    onboardingFormId,
    expiredSessionUrl,
    iss,
    returnUrl,
    exp,
    iat,
    termsOfServiceUrl,
    isValid,
    maxTransactionAmount,
    achMaxTransactionAmount,
    feeDetailsUrl,
  ] = getMany(tokenVerificationData, [
    'onboarding_form_id',
    'expired_session_url',
    'iss',
    'return_url',
    'exp',
    'iat',
    'terms_of_service_url',
    'is_valid',
    'merchant_max_transaction_amount',
    'merchant_ach_max_transaction_amount',
    'fee_details_url',
  ])

  return removeUndefined({
    onboardingFormId,
    expiredSessionUrl,
    iss,
    returnUrl,
    exp,
    iat,
    termsOfServiceUrl,
    isValid,
    maxTransactionAmount: maxTransactionAmount ? normalizeAmount(maxTransactionAmount) : undefined,
    achMaxTransactionAmount: achMaxTransactionAmount ? normalizeAmount(achMaxTransactionAmount) : undefined,
    feeDetailsUrl,
  })
}

export default frontendHostedFormTokenVerificationModel
