import {
  fieldEmpty,
} from 'utilities/validate'

const validatePayoutPlanForm = ({
  name,
  platformId,
  processor,
}) => {
  return {
    name: fieldEmpty(name, 'Name'),
    platformId: fieldEmpty(platformId, 'Platform ID'),
    processor: fieldEmpty(processor, 'Processor'),
  }
}

export default validatePayoutPlanForm
