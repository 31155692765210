import './RiskProfileToolTipMessageS.scss'
import React from 'react'
import { CIRCLE_ICON } from 'constants/iconConstants'
import map from 'lodash/map'

import {
  ALLOW_AVS_FAILURE_DESCRIPTION,
  ALLOW_CVV_FAILURE_DESCRIPTION,
  RISK_PROFILE_DESCRIPTION,
} from 'constants/language/languageConstants'

const RiskProfileToolTipMessage = () => {
  return (
    <div className='RiskProfileToolTipMessage'>{RISK_PROFILE_DESCRIPTION}
      {
        map([ALLOW_CVV_FAILURE_DESCRIPTION, ALLOW_AVS_FAILURE_DESCRIPTION], (description) => (
          <div className='section flex'>
            <i className={`fa fa-${CIRCLE_ICON}`} />
            <div className='description'>{description}</div>
          </div>
        ))
      }
    </div>
  )
}

export default RiskProfileToolTipMessage
