import formatDate from 'utilities/formatters/formatDate'
import formatAddress from 'utilities/formatters/formatAddress'
import formatPhone from 'utilities/forms/format/formatPhone'
import formatDateObject from 'utilities/formatters/formatDateObject'
import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatPercentage from 'utilities/formatters/formatPercentage'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { selfServiceApplicationMccCodesSelectOptions } from 'constants/mccConstants'
import { DASHBOARD_TYPE_BE_TO_LABEL_MAP } from 'constants/dashboardConfigurationsConstants'
import { countryNameByCountryCode } from 'constants/countryConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import findIndex from 'lodash/findIndex'
import size from 'lodash/size'
import includes from 'lodash/includes'

import {
  DYNAMIC,
  FLAT_RATE,
  SALES_PROSPECT,
  SELF_SERVICE_DASH,
} from 'constants/language/languageConstants'

const formatPaymentSolutionsFrontend = (acceptedPaymentTypes = []) => {
  const paymentSolutions = {
    onlinePayments: false,
    inPersonPayments: false,
  }
  if (includes(acceptedPaymentTypes, 'ONLINE_PAYMENTS')) {
    paymentSolutions.onlinePayments = true
  }
  if (includes(acceptedPaymentTypes, 'IN_PERSON_PAYMENTS')) {
    paymentSolutions.inPersonPayments = true
  }
  return paymentSolutions
}

const formatMccCodesFrontend = (mccCodes = []) => {
  return map(mccCodes, (code) => {
    return find(selfServiceApplicationMccCodesSelectOptions, { value: code })
  })
}

const getCompletedSteps = (form, steps, checks) => {
  return map(checks, (key, index) => {
    return { name: get(steps, index), completed: !!get(form, key) }
  })
}

export const PRICING_TYPE_FRONTEND_VALUE_MAP = {
  BLENDED: FLAT_RATE,
  INTERCHANGE_PLUS: DYNAMIC,
}

const ACCESS_FORM_TYPE_MAP = {
  SELF_SERVICE: SELF_SERVICE_DASH,
  PROSPECT: SALES_PROSPECT,
}


const frontendAccessFormModel = ({ data: accessForm }) => {
  const [
    id,
    createdAt,
    customerType,
    pricingType,
    reviewId,
    businessData,
    controlPerson,
    beneficialOwners = [],
    paymentInstrument,
    submittedAt,
    submittedBy,
    updatedAt,
    status,
    contractId,
    acceptedAt,
    acceptedBy,
    additionalDocumentsFiles,
    ipAddress,
    personaInquiryId,
    type,
    country,
    applicationId,
    applicationName,
  ] = getMany(accessForm, [
    'id',
    'created_at',
    'customer_model',
    'pricing_type',
    'review_id',
    'business_data',
    'principal_data.principal',
    'principal_data.associated_identities',
    'payment_instruments',
    'submitted_at',
    'submitted_by',
    'updated_at',
    'status',
    'agreement_details.agreement_id',
    'agreement_details.accepted_at',
    'agreement_details.email_address',
    'additionalDocumentsFiles',
    'agreement_details.ip_address',
    'agreement_details.persona_inquiry_id',
    'type',
    'country',
    'live_application_id',
    'live_application_name',
  ])

  const [
    businessName,
    doingBusinessAs,
    businessDescription,
    businessType,
    businessOwnershipType,
    businessIncorporationDate,
    businessTaxId,
    businessWebsite,
    businessWebsiteDomains,
    businessEmail,
    businessPhone,
    businessMerchantCount,
    businessAddress = {},
    acceptedPaymentTypes,
    mccCodes,
    defaultStatementDescriptor,
    annualTransactionCount,
    annualCardVolume,
    annualACHVolume,
    maxCardTransactionAmount,
    maxACHTransactionAmount,
    ecommercePercentage,
    cardPresentPercentage,
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    otherVolumePercentage,
    einVerificationFile,
    additionalDocuments,
  ] = getMany(businessData, [
    'business_name',
    'doing_business_as',
    'business_description',
    'business_type',
    'business_ownership_type',
    'business_incorporation_date',
    'business_tax_id',
    'business_website',
    'business_website_domains',
    'business_email',
    'business_phone',
    'business_merchant_count',
    'business_address',
    'accepted_payment_types',
    'mcc',
    'default_statement_descriptor',
    'annual_transaction_count',
    'annual_card_volume',
    'annual_ach_volume',
    'max_transaction_amount',
    'ach_max_transaction_amount',
    'card_volume_distribution.ecommerce_percentage',
    'card_volume_distribution.card_present_percentage',
    'volume_distribution_by_business_type.business_to_business_volume_percentage',
    'volume_distribution_by_business_type.business_to_consumer_volume_percentage',
    'volume_distribution_by_business_type.other_volume_percentage',
    'business_ein_form',
    'business_additional_documents',
  ])

  const frontendBusinessDataValues = removeUndefined({
    businessName,
    doingBusinessAs,
    businessDescription,
    businessType,
    displayBusinessType: convertSnakeToSentenceCase(businessType),
    businessOwnershipType,
    displayBusinessOwnershipType: convertSnakeToSentenceCase(businessOwnershipType),
    businessIncorporationDate,
    displayBusinessIncorporationDate: formatDateObject({ date: businessIncorporationDate }),
    businessTaxId,
    businessWebsite: businessWebsite || 'https://',
    businessWebsiteDomains,
    businessEmail,
    businessPhone,
    displayBusinessPhone: formatPhone(businessPhone),
    businessMerchantCount,
    businessAddress: removeUndefined({
      line1: get(businessAddress, 'line1'),
      line2: get(businessAddress, 'line2'),
      region: get(businessAddress, 'region'),
      postalCode: get(businessAddress, 'postal_code'),
      city: get(businessAddress, 'city'),
      country: get(businessAddress, 'country') || country,
    }),
    displayBusinessAddress: formatAddress({ address: businessAddress }),
  })

  const [
    controlPersonFirstName,
    controlPersonLastName,
    controlPersonEmail,
    controlPersonPersonalAddress = {},
    controlPersonPrincipalPercentageOwnership,
    controlPersonDateOfBirth = {},
    controlPersonTaxId,
    controlPersonPhone,
    controlPersonTitle,
  ] = getMany(controlPerson, [
    'first_name',
    'last_name',
    'email',
    'personal_address',
    'percentage_ownership',
    'dob',
    'tax_id',
    'phone',
    'title',
  ])

  const frontendControlPersonValues = removeUndefined({
    firstName: controlPersonFirstName,
    lastName: controlPersonLastName,
    title: controlPersonTitle,
    personalAddress: removeUndefined({
      line1: get(controlPersonPersonalAddress, 'line1'),
      line2: get(controlPersonPersonalAddress, 'line2'),
      region: get(controlPersonPersonalAddress, 'region'),
      postalCode: get(controlPersonPersonalAddress, 'postal_code'),
      city: get(controlPersonPersonalAddress, 'city'),
      country: get(controlPersonPersonalAddress, 'country') || country,
    }),
    displayControlPersonAddress: formatAddress({ address: controlPersonPersonalAddress }),
    dateOfBirth: {
      month: get(controlPersonDateOfBirth, 'month'),
      day: get(controlPersonDateOfBirth, 'day'),
      year: get(controlPersonDateOfBirth, 'year'),
    },
    displayDateOfBirth: formatDateObject({ date: controlPersonDateOfBirth }),
    taxId: controlPersonTaxId,
    principalPercentageOwnership: controlPersonPrincipalPercentageOwnership,
    displayPhone: formatPhone(controlPersonPhone),
    phone: controlPersonPhone,
    email: controlPersonEmail,
  })

  const frontendBeneficialOwnerValues = map(beneficialOwners, (beneficialOwner) => {
    const [
      beneficialOwnerFirstName,
      beneficialOwnerLastName,
      beneficialOwnerEmail,
      beneficialOwnerPersonalAddress = {},
      beneficialOwnerPrincipalPercentageOwnership,
      beneficialOwnerDateOfBirth = {},
      beneficialOwnerTaxId,
      beneficialOwnerPhone,
      beneficialOwnerTitle,
      beneficialOwnerIndex,
    ] = getMany(beneficialOwner, [
      'first_name',
      'last_name',
      'email',
      'personal_address',
      'percentage_ownership',
      'dob',
      'tax_id',
      'phone',
      'title',
      'index',
    ])

    return removeUndefined({
      index: beneficialOwnerIndex,
      firstName: beneficialOwnerFirstName,
      lastName: beneficialOwnerLastName,
      title: beneficialOwnerTitle,
      personalAddress: removeUndefined({
        line1: get(beneficialOwnerPersonalAddress, 'line1'),
        line2: get(beneficialOwnerPersonalAddress, 'line2'),
        region: get(beneficialOwnerPersonalAddress, 'region'),
        postalCode: get(beneficialOwnerPersonalAddress, 'postal_code'),
        city: get(beneficialOwnerPersonalAddress, 'city'),
        country: get(beneficialOwnerPersonalAddress, 'country') || country,
      }),
      displayPersonalAddress: formatAddress({ address: beneficialOwnerPersonalAddress }),
      dateOfBirth: {
        month: get(beneficialOwnerDateOfBirth, 'month'),
        day: get(beneficialOwnerDateOfBirth, 'day'),
        year: get(beneficialOwnerDateOfBirth, 'year'),
      },
      displayDateOfBirth: formatDateObject({ date: beneficialOwnerDateOfBirth }),
      taxId: beneficialOwnerTaxId,
      principalPercentageOwnership: beneficialOwnerPrincipalPercentageOwnership,
      phone: beneficialOwnerPhone,
      displayPhone: formatPhone(beneficialOwnerPhone),
      email: beneficialOwnerEmail,
    })
  })

  const paymentSolutionsForFrontend = isEmpty(acceptedPaymentTypes) ? undefined : formatPaymentSolutionsFrontend(acceptedPaymentTypes)

  const frontendProcessingDataValues = removeUndefined({
    acceptedPaymentTypes: paymentSolutionsForFrontend,
    displayAcceptedPaymentTypes: snakeCaseToTitleCase({ key: join(acceptedPaymentTypes, ', ') }),
    mccCode: formatMccCodesFrontend(mccCodes),
    displayMccCodes: join(mccCodes, ', '),
    defaultStatementDescriptor,
    annualTransactionCount,
    displayAnnualTransactionCount: annualTransactionCount ? formatNumber(annualTransactionCount) : undefined,
    annualCardVolume,
    displayAnnualCardVolume: formatMoney({ amount: annualCardVolume }),
    annualACHVolume,
    displayAnnualACHVolume: formatMoney({ amount: annualACHVolume }),
    maxCardTransactionAmount,
    displayMaxCardTransactionAmount: formatMoney({ amount: maxCardTransactionAmount }),
    maxACHTransactionAmount,
    displayMaxACHTransactionAmount: formatMoney({ amount: maxACHTransactionAmount }),
    ecommercePercentage,
    displayEcommercePercentage: formatPercentage({ percentage: ecommercePercentage }),
    cardPresentPercentage,
    displayCardPresentPercentage: formatPercentage({ percentage: cardPresentPercentage }),
    businessToBusinessVolumePercentage,
    displayBusinessToBusinessVolumePercentage: formatPercentage({ percentage: businessToBusinessVolumePercentage }),
    businessToConsumerVolumePercentage,
    displayBusinessToConsumerVolumePercentage: formatPercentage({ percentage: businessToConsumerVolumePercentage }),
    otherVolumePercentage,
    displayOtherVolumePercentage: formatPercentage({ percentage: otherVolumePercentage }),
  })

  const [
    bankDocument,
    bankAccountName,
    bankAccountBankCode,
    bankAccountAccountNumber,
    bankAccountAccountType,
    transitNumber,
    institutionNumber,
    thirdParty,
    thirdPartyToken,
  ] = getMany(paymentInstrument, [
    'bank_account_verification',
    'name',
    'bank_code',
    'account_number',
    'account_type',
    'transit_number',
    'institution_number',
    'third_party',
    'third_party_token',
  ])

  const frontendBankAccountDataValues = removeUndefined({
    name: bankAccountName,
    accountType: bankAccountAccountType || 'CHECKING',
    displayAccountType: convertSnakeToSentenceCase(bankAccountAccountType),
    bankCode: bankAccountBankCode,
    transitNumber,
    institutionNumber,
    accountNumber: bankAccountAccountNumber,
    accountNumberVerification: bankAccountAccountNumber,
    thirdParty,
    thirdPartyToken,
  })

  // find the active step the user is in on the form
  const accessFormFlowSteps = ['Pricing Info', 'Business Info', 'Owner Info', 'Processing Info', 'Bank Account Info', 'Review and Submit']
  const formStepsToCheck = ['pricingType', 'businessName', 'controlPersonFirstName', 'defaultStatementDescriptor', 'bankAccountAccountNumber', 'submittedAt']
  const applicationFormCompletedSteps = getCompletedSteps({ pricingType, businessName, controlPersonFirstName, defaultStatementDescriptor, bankAccountAccountNumber, submittedAt }, accessFormFlowSteps, formStepsToCheck)
  const lastCompletedIndex = findIndex(applicationFormCompletedSteps, { completed: false })
  const activeStepIndex = lastCompletedIndex >= 0 ? lastCompletedIndex : size(applicationFormCompletedSteps) - 1

  return {
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    customerType,
    displayCustomerType: DASHBOARD_TYPE_BE_TO_LABEL_MAP[customerType],
    pricingType,
    displayPricingType: PRICING_TYPE_FRONTEND_VALUE_MAP[pricingType],
    businessData: frontendBusinessDataValues,
    einVerificationFile,
    bankDocument,
    controlPerson: frontendControlPersonValues,
    beneficialOwners: frontendBeneficialOwnerValues,
    processingData: frontendProcessingDataValues,
    bankAccountData: frontendBankAccountDataValues,
    submittedAt,
    displaySubmittedAt: formatDate({ date: submittedAt }),
    submittedBy,
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    displayStatus: snakeCaseToTitleCase({ key: status }),
    status,
    activeStepIndex,
    contractId,
    additionalDocuments,
    additionalDocumentsFiles,
    acceptedAt,
    displayAcceptedAt: formatDate({ date: acceptedAt }),
    acceptedBy,
    ipAddress,
    personaInquiryId,
    type,
    displayType: ACCESS_FORM_TYPE_MAP[type],
    country,
    displayCountry: countryNameByCountryCode[country],
    applicationId,
    applicationName,
    reviewId,
  }
}

export default frontendAccessFormModel
