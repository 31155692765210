import createAction from 'utilities/actions/createAction'
import getDisputeEvidencesRequest from 'utilities/actions/get/getDisputeEvidencesRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { UPLOAD_DISPUTE_EVIDENCE } from 'constants/amplitudeConstants'
import { POST_DISPUTE_EVIDENCES_F_REQUEST } from 'constants/flowConstants'

const postUploadDisputeEvidenceRequest = ({
  credentials,
  disputeId,
  formData,
  dispatch,
}) => createAction({
  type: POST_DISPUTE_EVIDENCES_F_REQUEST,
  id: disputeId,
  values: formData,
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(UPLOAD_DISPUTE_EVIDENCE, {
      credentials,
      disputeId,
    }),
    successCallback: () => {
      dispatch(getDisputeEvidencesRequest({ credentials, disputeId }))

      setTimeout(() => dispatch(getDisputeEvidencesRequest({ credentials, disputeId })), 10000)
    },
  },
})

export default postUploadDisputeEvidenceRequest
