import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { EDIT_SUBSCRIPTION_PLAN } from 'constants/amplitudeConstants'

import {
  PATCH_SUBSCRIPTION_PLAN_F_REQUEST,
  GET_SUBSCRIPTION_PLAN_F_REQUEST,
} from 'constants/flowConstants'

const submitArchivePlanForm = (values, dispatch, props) => {
  const {
    credentials,
    subscriptionPlanId,
  } = props

  dispatch({
    type: PATCH_SUBSCRIPTION_PLAN_F_REQUEST,
    payload: {
      id: subscriptionPlanId,
      values: { state: 'INACTIVE' },
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch({
          type: GET_SUBSCRIPTION_PLAN_F_REQUEST,
          payload: {
            id: subscriptionPlanId,
            credentials,
          },
        })
      },
      actionId: sendAmplitudeActionEvent(EDIT_SUBSCRIPTION_PLAN, {
        subscriptionPlanId,
      }),
      closeModal: true,
    },
  })
}

export default submitArchivePlanForm
