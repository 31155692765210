import './DashboardFeedbackEmailS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericEmailFormC from 'components/Admin/Forms/GenericEmailForm/GenericEmailFormC'
import { DASHBOARD_FEEDBACK } from 'constants/language/languageConstants'

const DashboardFeedbackEmail = ({
  isFetching = false,
  dashboardFeedbackEmail = {},
}) => {
  return (
    <div className='DashboardFeedbackEmail'>
      <GenericEmailFormC
        isFetching={isFetching}
        email={dashboardFeedbackEmail}
        emailName={DASHBOARD_FEEDBACK}
      />
    </div>
  )
}

DashboardFeedbackEmail.propTypes = {
  isFetching: PropTypes.bool,
  dashboardFeedbackEmail: PropTypes.object,
}

export default DashboardFeedbackEmail
