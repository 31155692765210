import './AdverseMediaReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  MATCH_SUMMARY,
  PERSONA_REPORT_ID,
  VIEW_DETAILS,
} from 'constants/language/languageConstants'

const AdverseMediaReport = ({
  personaReportId = '',
  viewDetailsPath = '',
  hitsCount,
  hitsContent = [],
  isStandaloneUnderwriting = false,
}) => {
  return (
    <div className='AdverseMediaReport'>
      <div className='flex items-center'>
        <h6>{MATCH_SUMMARY}</h6>
        <span className='number-of-hits'>{hitsCount}</span>
        {!isStandaloneUnderwriting && <ClickToCopyC position='right' label={PERSONA_REPORT_ID} content={personaReportId} />}
      </div>
      {map(hitsContent, ({ name, mediaCount, birthdates, birthdateString, categories, countries, countriesString }, index) => {
        return (
          <div className='hit-content' key={`hit-content-${index}`}>
            <div className='header-content flex space-between'>
              <div className='p-2-bold'>{name}</div>
              <a className='view-details-link text-link p-2' href={`${viewDetailsPath}?hitIndex=${index}`} target='blank'>{VIEW_DETAILS}</a>
            </div>
            <div className='body-content flex items-center p-2'>
              <div className='adverse-media-count'>Adverse Media: {mediaCount}</div>
              {<><div className='dot' /><div className='birth-year'>{birthdateString}</div></>}
              {!isEmpty(countries) && <><div className='dot' /><div className='country'>{countriesString}</div></>}
            </div>
            <div className='pill-content flex'>
              {map(categories, (category, idx) => {
                return <div className='category' key={`category-${idx}`}>{snakeCaseToTitleCase({ key: category })}</div>
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

AdverseMediaReport.propTypes = {
  personaReportId: PropTypes.string,
  viewDetailsPath: PropTypes.string,
  hitsCount: PropTypes.number,
  hitsContent: PropTypes.array,
  isStandaloneUnderwriting: PropTypes.bool,
}

export default AdverseMediaReport
