import './LiveAccountApplicationAdditionalVerificationNeededDetailsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  ADDITIONAL_VERIFICATION_NEEDED,
  LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_1,
  LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_2,
  LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_NEXT_STEPS_MSG,
  NEXT_STEPS,
} from 'constants/language/languageConstants'

const LiveAccountApplicationAdditionalVerificationNeededDetails = ({
  title = ADDITIONAL_VERIFICATION_NEEDED,
  rightTitle = '',
}) => {
  return (
    <div className='LiveAccountApplicationAdditionalVerificationNeededDetails'>
      <div className='additional-verification-needed-details-header flex space-between items-center'>
        <h4>{title}</h4>
        <div className='additional-verification-needed-details-right-title'>{rightTitle}</div>
      </div>
      <div className='additional-verification-needed-details-highlight'>
        <h6>{LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_1}</h6>
        <div className='info-pt-2 p-1'>{LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_INFO_PT_2}</div>

        <div className='next-steps-container flex items-center wrap'>
          <h6>{NEXT_STEPS}:</h6>
          <div className='next-steps-msg p-1'>{LIVE_ACCOUNT_APPLICATION_ADDITIONAL_VERIFICATION_NEEDED_NEXT_STEPS_MSG}</div>
        </div>
      </div>
    </div>
  )
}

LiveAccountApplicationAdditionalVerificationNeededDetails.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
}

export default LiveAccountApplicationAdditionalVerificationNeededDetails
