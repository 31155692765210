import selectOption from 'utilities/forms/selectOption'
import { CAN } from 'constants/countryConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'

import {
  ANNUAL_ACH_VOLUME,
  ANNUAL_EFT_VOLUME,
} from 'constants/language/languageConstants'


export const IN_PROGRESS = 'IN_PROGRESS'
export const COMPLETED = 'COMPLETED'
export const ALL = 'ALL'

export const IN_PROGRESS_STATUS = 'In Progress'

export const onboardingProcessorOptions = (env, country, isStandaloneUnderwriting) => {
  if (isStandaloneUnderwriting) return [{ label: 'UNDERWRITING_ONLY_V1', value: 'UNDERWRITING_ONLY_V1', helperText: 'Used to underwrite merchants' }]

  if (env !== LIVE_ENVIRONMENT) return [{ label: 'DUMMY_V1', value: 'DUMMY_V1', helperText: 'Used to process online test transactions' }]

  switch (country) {
    case CAN:
      return [{ label: 'FINIX_V1', value: 'FINIX_V1', helperText: 'Used to process Online and In-Person Payments on Finix\'s Processor' }]

    default:
      return [
        { label: 'FINIX_V1', value: 'FINIX_V1', helperText: 'Used to process Online and In-Person Payments on Finix\'s Processor' },
        { label: 'LITLE_V1', value: 'LITLE_V1', helperText: 'Used to process online transactions' },
        { label: 'VANTIV_V1', value: 'VANTIV_V1-VANTIV_V1', helperText: 'Used to process via alternative gateways' },
        { label: 'TRIPOS_MOBILE_V1', value: 'VANTIV_V1-TRIPOS_MOBILE_V1', helperText: 'Used to process In-Person Payments via Finix Mobile SDK' },
        { label: 'DATACAP_V1', value: 'VANTIV_V1-DATACAP_V1', helperText: 'Used to process In-Person Payments via Finix API' },
      ]
  }
}

const annualACHVolumeLabelMap = (country) => {
  switch (country) {
    case CAN:
      return ANNUAL_EFT_VOLUME

    default:
      return ANNUAL_ACH_VOLUME
  }
}

export const ONBOARDING_FORMS_STATUS_OPTIONS = [
  selectOption('All', ALL),
  selectOption('In Progress', IN_PROGRESS),
  selectOption('Completed', COMPLETED),
]

// plaid constants
export const PLAID_LINK_TOKEN = 'PLAID_LINK_TOKEN'
export const PLAID_PROCESSOR_TOKEN = 'PLAID_PROCESSOR_TOKEN'
export const PLAID = 'PLAID'
