import './LoaderS.scss'
import React from 'react'
import { LOAD_ICON } from 'constants/iconConstants'

const Loader = ({ icon = LOAD_ICON }) => {
  return (
    <div className='Loader'>
      <span className={`spin-icon fa fa-${icon}`} />
    </div>
  )
}

export default Loader
