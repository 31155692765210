import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import SubscriptionPlans from './SubscriptionPlans'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import showModalAction from 'utilities/actions/showModalAction'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import { SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL } from 'constants/modalConstants'
import { CREATE_A_PLAN } from 'constants/language/languageConstants'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import {
  CREATE_SUBSCRIPTION_PLAN_PATH,
  SUBSCRIPTION_PLANS_PATH,
} from 'constants/pathConstants'

import {
  MERCHANT_ACCOUNT_LINKED_TO,
} from 'constants/filterConstants'

import {
  DATE_QUICK_FILTER,
  SUBSCRIPTION_PLAN_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import isEqual from 'lodash/isEqual'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  SUBSCRIPTION_PLAN_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const allowedFilters = sortBy([
  MERCHANT_ACCOUNT_LINKED_TO,
  // SUBSCRIPTION_PLAN_NAME_EXACT, // TODO: comment back in once BE supports this.
], 'label')

const mapStateToProps = (state) => {
  const isFetching = get(state, `subscriptionPlansR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const applicationId = get(currentUser, 'applicationId')
  const environment = get(credentials, 'environment')
  const subscriptionPlansPath = SUBSCRIPTION_PLANS_PATH({ credentialId })
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const createPaymentEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))
  const isRolePlatformCredential = isRolePlatform({ credentials })

  return {
    isFetching,
    credentialId,
    initialQueries,
    quickFilters,
    allowedFilters,
    subscriptionPlansPath,
    credentials,
    applicationId,
    createPaymentEnabledApplicationMerchants,
    isRolePlatformCredential,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSelectSenderForCreateSubscriptionPlanModal: (modalProps) => dispatch(showModalAction({
      modalType: SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
      className: 'modal-md no-pad overflow-visible',
      modalProps: {
        canCloseModal: false,
        isCreateSubscriptionPlanFlow: true,
      } })),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

class SubscriptionPlansC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId,
      credentials,
      getApplicationMerchants,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      createPaymentEnabledApplicationMerchants,
      credentialId,
      isRolePlatformCredential,
    } = this.props

    const hasOneMerchant = size(createPaymentEnabledApplicationMerchants) === 1

    const actions = [
      {
        label: CREATE_A_PLAN,
        action: () => goToPath({
          pathname: CREATE_SUBSCRIPTION_PLAN_PATH({ credentialId }),
          queries: hasOneMerchant ? { merchantId: get(createPaymentEnabledApplicationMerchants, '[0].id') } : {},
        }),
        className: 'button',
        condition: !isRolePlatformCredential,
      },
    ]

    return (
      <SubscriptionPlans
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlansC)
