import './UpdateDisputeStateFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import Button from 'components/Shared/Button/Button'
import validateUpdateDisputeStateForm from 'utilities/validate/validateUpdateDisputeStateForm'
import { UPDATE_DISPUTE_STATE_FORM } from 'constants/formConstants'

import {
  CANCEL,
  DISPUTE_UPDATE_NOTE,
  ISSUER_RECALLED, LOST,
  UPDATE_DISPUTE_STATE,
  UPDATE_STATE_TO,
  WON,
} from 'constants/language/languageConstants'

const DISPUTE_STATE_OPTIONS = [
  { label: WON, value: 'WON' },
  { label: LOST, value: 'LOST' },
  { label: ISSUER_RECALLED, value: 'ISSUER_RECALLED' },
]

// TODO: write submit function once API is ready
const UpdateDisputeStateForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
}) => {
  return (
    <form className='UpdateDisputeStateForm' onSubmit={handleSubmit()}>
      <Field
        name='disputeState'
        label={UPDATE_STATE_TO}
        component={RadioButton}
        options={DISPUTE_STATE_OPTIONS}
      />
      <Field
        name='disputeNote'
        label={DISPUTE_UPDATE_NOTE}
        component={TextAreaField}
      />
      <div className='buttons flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={UPDATE_DISPUTE_STATE} />
      </div>
    </form>
  )
}

UpdateDisputeStateForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: UPDATE_DISPUTE_STATE_FORM,
  validate: validateUpdateDisputeStateForm,
})(UpdateDisputeStateForm)
