import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_PLAID_TOKEN } from 'constants/amplitudeConstants'
import { POST_PLAID_TOKEN_F_REQUEST } from 'constants/flowConstants'

// this request gets the token we need to open plaid modal
const postPlaidTokenRequest = ({
  id,
  tokenType,
  country,
  overwriteReducer = true,
  entityType,
  credentials,
}) => createAction({
  type: POST_PLAID_TOKEN_F_REQUEST,
  id,
  credentials: ['ONBOARDING_FORM', 'ACCESS_FORM'].includes(entityType) ? null : credentials,
  values: {
    type: tokenType,
    countries: [country],
    language: 'en',
    actionType: POST_PLAID_TOKEN_F_REQUEST,
    entityType,
  },
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_PLAID_TOKEN, {
      id,
    }),
    overwriteReducer,
  },
})

export default postPlaidTokenRequest
