import paymentsAPI from 'utilities/api/paymentsAPI'
import { BALANCE_ENTRIES } from 'constants/apiConstants'
import get from 'lodash/get'

const getBalanceEntriesAPI = ({ id, queries, credentials, meta, values }) => {
  const balanceId = id || get(values, 'balanceId')

  return paymentsAPI.get({
    meta,
    path: BALANCE_ENTRIES({ balanceId }),
    queries,
    credentials,
  })
}

export default getBalanceEntriesAPI
