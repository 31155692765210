import './EditPlatformProcessorConfigFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'
import EditPlatformProcessorConfig from 'components/Customer/Forms/EditPlatformProcessorConfigForm/EditPlatformProcessorConfig'
import Button from 'components/Shared/Button/Button'
import submitEditProcessorConfigForm from 'utilities/submit/submitEditProcessorConfigForm'
import isEqual from 'lodash/isEqual'
import { SAVE } from 'constants/language/languageConstants'

const EditPlatformProcessorConfigForm = ({
  handleSubmit = () => {},
  configValue = '',
  currentFieldValue = '',
}) => {
  const disabled = isEqual(configValue, currentFieldValue)

  return (
    <form className='EditPlatformProcessorConfigForm' onSubmit={handleSubmit(submitEditProcessorConfigForm)}>
      <FieldArray name='configPair' component={EditPlatformProcessorConfig} />

      <div className='buttons flex flex-end'>
        <Button type='submit' disabled={disabled} label={SAVE} />
      </div>
    </form>
  )
}

EditPlatformProcessorConfigForm.propTypes = {
  handleSubmit: PropTypes.func,
  configValue: PropTypes.string,
  currentFieldValue: PropTypes.string,
}

export default EditPlatformProcessorConfigForm
