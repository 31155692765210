import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import MerchantUnderwritingReviewC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReview/MerchantUnderwritingReview/MerchantUnderwritingReviewC'

const UnderwritingMerchant = ({
  identityId = '',
  reviewId = '',
  subjectId = '',
  entityId = '',
  runId = '',
  entityType = '',
  tabs = [],
  contextBarData = {},
  headerData = {},
  isFetching = false,
  isPastRunView = false,
}) => {
  return (
    <div className='UnderwritingMerchant'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
      />

      {isPastRunView ? (
        <MerchantUnderwritingReviewC
          reviewId={reviewId}
          subjectId={subjectId}
          entityId={entityId}
          entityType={entityType}
          runId={runId}
          isPastRunView
        />
      ) : (
        <TabsC
          identityId={identityId}
          tabs={tabs}
          isFetching={isFetching}
          persistQueries={['subjectId', 'entityType', 'entityId']}
        />
      )}
    </div>
  )
}

UnderwritingMerchant.propTypes = {
  identityId: PropTypes.string,
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  entityId: PropTypes.string,
  runId: PropTypes.string,
  entityType: PropTypes.string,
  tabs: PropTypes.array,
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  isFetching: PropTypes.bool,
  isPastRunView: PropTypes.bool,
}

export default UnderwritingMerchant
