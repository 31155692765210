import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

const frontendUnderwritingRunModel = ({ data: underwritingRun }) => {
  const [
    id,
    createdAt,
    updatedAt,
    decision,
    reviewId,
    subject,
    workflow,
  ] = getMany(underwritingRun, [
    'id',
    'created_at',
    'updated_at',
    'decision',
    'review_id',
    'context.subject',
    'context.workflow',
  ])

  const [
    decisionCreatedAt,
    recommendation,
    scores,
    state,
  ] = getMany(decision, [
    'created_at',
    'recommendation',
    'scores',
    'state',
  ])

  const actOnDecision = get(workflow, 'definition.act_on_decision')
  const displayActOnDecision = actOnDecision ? 'Enabled' : 'Disabled'

  return removeUndefined({
    id,
    reviewId,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    subject,
    decision: {
      createdAt: decisionCreatedAt,
      displayCreatedAt: formatDate({ date: decisionCreatedAt }),
      recommendation,
      scores,
      state,
      actOnDecision,
      displayActOnDecision,
    },
  })
}

export default frontendUnderwritingRunModel
