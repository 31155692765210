import './HighlightS.scss'
import React from 'react'
import Button from 'components/Shared/Button/Button'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import classnames from 'classnames'

const Highlight = ({
  style = {},
  descriptionStyle = {},
  description,
  closeHighlights = () => {},
  showPrevHighlight = () => {},
  showNextHighlight = () => {},
  goToHighlight = () => {},
  isFirst = false,
  isLast = false,
  highlights = [],
  index = 0,
}) => {
  const nextClass = isLast ? 'next success' : 'next'
  const nextLabel = isLast ? 'Got it!' : 'Next'

  return (
    <div className='Highlight' style={style}>
      <div className='dots flex space-between'>
        <div />

        <div className='dotsContainer'>
          {
            map(highlights, (highlight, idx) => {
              const className = classnames('dot', { active: idx === index })

              return (
                <div className={className} onClick={() => goToHighlight(idx)}>
                  <div className='dotBackground' />
                </div>
              )
            })
          }
        </div>

        <div />
      </div>

      <div className='close fa fa-times-circle' onClick={closeHighlights} />

      <div className='description' style={descriptionStyle}>
        <div>{description}</div>

        <div className='actions flex space-between'>
          <Button className='prev' onClick={showPrevHighlight} disabled={isFirst} label='Prev' />
          <Button className={nextClass} onClick={showNextHighlight} label={nextLabel} />
        </div>
      </div>
    </div>
  )
}

Highlight.propTypes = {
  style: PropTypes.object,
  descriptionStyle: PropTypes.object,
  description: PropTypes.string,
  closeHighlights: PropTypes.func,
  showPrevHighlight: PropTypes.func,
  showNextHighlight: PropTypes.func,
  goToHighlight: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  highlights: PropTypes.array,
  index: PropTypes.number,
}

export default Highlight
