import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATION,
  APPLICATIONS,
} from 'constants/apiConstants'

const getApplicationAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: APPLICATION({ applicationId: id }),
    credentials,
    service: APPLICATIONS,
  })
}

export default getApplicationAPI
