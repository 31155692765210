import pluralizeWord from 'utilities/pluralizeWord'
import getMany from 'utilities/get/getMany'
import moment from 'moment'

import {
  PERIODIC_MONTHLY,
  PERIODIC_YEARLY,
  FIXED_TIME_INTERVAL,
  FIXED_TIME,
} from 'constants/subscriptionConstants'

// given a subscription type, period offset, and fixed time interval offset, display proper string
const convertSubscriptionScheduleOffsetObjectToString = ({ subscriptionType, periodOffset, fixedTimeIntervalOffset, lowerCase = false }) => {
  const showFixedTimeIntervalOffset = subscriptionType === FIXED_TIME_INTERVAL || subscriptionType === FIXED_TIME
  const showPeriodicMonthlyOffset = subscriptionType === PERIODIC_MONTHLY
  const showPeriodicYearlyOffset = subscriptionType === PERIODIC_YEARLY
  const firstLetter = lowerCase ? 'e' : 'E'

  if (showPeriodicMonthlyOffset || showPeriodicYearlyOffset) {
    const [day, month] = getMany(periodOffset, ['day', 'month'])
    const formattedDay = moment(day, 'DD').format('Do')
    const formattedMonth = moment(month, 'M').format('MMM')

    return showPeriodicYearlyOffset ? `${firstLetter}very ${formattedMonth}. ${formattedDay}` : `${firstLetter}very ${formattedDay} of the month`
  }

  if (showFixedTimeIntervalOffset) {
    const [hourlyInterval, intervalCount] = getMany(fixedTimeIntervalOffset, ['hourlyInterval', 'intervalCount'])

    return `${firstLetter}very ${hourlyInterval} ${pluralizeWord({ count: hourlyInterval, word: 'hour' })} for ${intervalCount} ${pluralizeWord({ count: intervalCount, word: 'count' })}`
  }

  return '-'
}

export default convertSubscriptionScheduleOffsetObjectToString
