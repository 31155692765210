import './VerificationResultsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import APIResponse from 'components/Customer/Shared/Display/APIResponse/APIResponse'
import getMany from 'utilities/get/getMany'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getVerificationAPI from 'api/finix/get/getVerificationAPI'

import {
  VERIFICATION_ID,
  CREATED_ON,
  STATE,
  PROCESSOR,
  RAW_RESPONSE,
} from 'constants/language/languageConstants'

const VerificationResults = ({
  verification = {},
}) => {
  const [
    id,
    displayCreatedAt,
    processor,
    state,
  ] = getMany(verification, [
    'id',
    'displayCreatedAt',
    'processor',
    'state',
  ])

  return (
    <div className='VerificationResults'>
      <div className='label'>{VERIFICATION_ID}</div>
      <div>{id}</div>

      <div className='label'>{CREATED_ON}</div>
      <div>{displayCreatedAt}</div>

      <div className='label'>{STATE}</div>
      <TransferStatus value={state} />

      <div className='label'>{PROCESSOR}</div>
      <div>{snakeCaseToTitleCase({ key: processor })}</div>

      <div className='label'>{RAW_RESPONSE}</div>

      <APIResponse
        api={getVerificationAPI}
        apiProps={{ id, credentials: getCurrentCredentials() }}
      />
    </div>
  )
}

VerificationResults.propTypes = {
  verification: PropTypes.object,
}

export default VerificationResults
