import paymentsAPI from 'utilities/api/paymentsAPI'
import { MERCHANTS } from 'constants/apiConstants'

const getMerchantsAPI = ({ absolutePath, queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: MERCHANTS,
    absolutePath,
    queries,
    credentials,
    service: MERCHANTS,
  })
}

export default getMerchantsAPI
