import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import formatMoney from 'utilities/formatters/formatMoney'
import { fieldEmpty } from 'utilities/validate'
import { REFUND_AMOUNT } from 'constants/language/languageConstants'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

const validateCreateRefundForm = (values, props) => {
  const { refundAmount, splitRefund } = values
  const { transferAmount, splitTransfers } = props

  const normalizedRefundAmount = convertAmountStringToNumber({ amount: refundAmount })
  const normalizedTransferAmount = convertAmountStringToNumber({ amount: transferAmount })

  const splitRefundAmounts = reduce(splitTransfers, (total, splitTransfer) => {
    const splitTransferId = get(splitTransfer, 'id')
    const splitTransferAmount = convertAmountStringToNumber({ amount: get(splitTransfer, 'amount') })
    const splitRefundAmountName = `splitRefundAmount-${splitTransferId}`
    const splitRefundInput = convertAmountStringToNumber({ amount: get(values, splitRefundAmountName) })

    return merge({}, total, {
      [splitRefundAmountName]: (splitRefundInput > splitTransferAmount) && `Refund up to ${formatMoney({ amount: splitTransferAmount })}`,
    })
  }, {})

  return (splitRefund === 'split') ? splitRefundAmounts : { refundAmount: fieldEmpty(refundAmount, REFUND_AMOUNT) || ((normalizedRefundAmount > normalizedTransferAmount) && `Refund up to ${formatMoney({ amount: transferAmount })}`) }
}

export default validateCreateRefundForm
