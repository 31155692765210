import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getSubscriptionScheduleEnrollmentsRequest from 'utilities/actions/get/getSubscriptionScheduleEnrollmentsRequest'
import get from 'lodash/get'
import _values from 'lodash/values'
import some from 'lodash/some'
import { DELETE_SUBSCRIPTION_ENROLLMENTS } from 'constants/amplitudeConstants'

import {
  DELETE_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS,
  DELETE_SUBSCRIPTION_ENROLLMENTS_F_REQUEST,
  DELETE_SUBSCRIPTION_ENROLLMENTS_F_FAILURE,
  UNSELECT_ALL_ITEMS,
} from 'constants/flowConstants'

const deleteSubscriptionEnrollmentsRequest = ({
  enrollmentIds,
  credentials,
  dispatch,
  selectedItemsKey,
  subscriptionScheduleId,
}) => {
  return createAction({
    type: DELETE_SUBSCRIPTION_ENROLLMENTS_F_REQUEST,
    values: {
      enrollmentIds,
    },
    credentials,
    meta: {
      successCallback: ({ newValues }) => {
        const hasErrors = some(_values(newValues), (newValue) => get(newValue, 'errors'))

        if (!dispatch) return

        if (!hasErrors) {
          dispatch({
            type: UNSELECT_ALL_ITEMS,
            payload: {
              key: selectedItemsKey,
            },
          })

          dispatch({ type: DELETE_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS })
        } else {
          // TODO: handle path for displaying multiple errors
          dispatch({ type: DELETE_SUBSCRIPTION_ENROLLMENTS_F_FAILURE })
        }

        dispatch(getSubscriptionScheduleEnrollmentsRequest({
          credentials,
          queries: { subscription_schedule_id: subscriptionScheduleId },
          meta: { selectorId: subscriptionScheduleId },
        }))
      },
      actionId: sendAmplitudeActionEvent(DELETE_SUBSCRIPTION_ENROLLMENTS, {
        credentials,
        enrollmentIds,
      }),
    },
  })
}

export default deleteSubscriptionEnrollmentsRequest
