import frontendAccessFormModel from 'utilities/create/models/frontendAccessFormModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendAccessFormsModel = ({ data: accessForms }) => {
  const accessFormModels = map(accessForms, (accessForm) => frontendAccessFormModel({ data: accessForm }))

  return keyBy(accessFormModels, 'id')
}

export default frontendAccessFormsModel
