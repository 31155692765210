import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors/index'
import EmailConfigurationsForm from './EmailConfigurationsForm'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { EMAIL_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'

import {
  reset,
  change,
} from 'redux-form'

import {
  FILE_UPLOAD_INSTRUCTIONS,
  FILE_UPLOAD_SUB_INSTRUCTIONS,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 10000000,
  accept: 'image/jpeg, image/png, image/jpg',
}

const instructions = [
  {
    name: 'main',
    message: FILE_UPLOAD_INSTRUCTIONS,
  },
  {
    name: 'sub',
    message: FILE_UPLOAD_SUB_INSTRUCTIONS,
  },
]

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => {
      dispatch(change(EMAIL_CONFIGURATIONS_FORM, fieldName, color.hex))
    },
    resetForm: () => dispatch(reset(EMAIL_CONFIGURATIONS_FORM)),
  }
}

const mapStateToProps = (state, props) => {
  const {
    entityType,
    entityId,
  } = props

  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const isLiveEnvironment = get(credentials, 'environment') === LIVE_ENVIRONMENT

  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const customSettings = entityType && entityId ? getDashboardConfigByEntityTypeAndId(state, entityType, entityId) : get(currentUser, 'customSettings')
  const isFetching = entityType && entityId ? isFetchingConfiguration : isFetchingCurrentUser

  return removeUndefined({
    id: get(customSettings, 'id'),
    isFetching,
    credentials,
    customSettings,
    initialValues: customSettings,
    isLiveEnvironment,
    isFetchingCurrentUser,
    config,
    instructions,
  })
}

class EmailConfigurationsFormC extends Component {
  componentDidMount() {
    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isFetchingCurrentUser: prevIsFetchingCurrentUser } = prevProps

    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && prevIsFetchingCurrentUser && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  render() {
    const { resetForm } = this.props
    return (
      <EmailConfigurationsForm
        {...this.props}
        resetForm={resetForm}
        getDashboardCustomization={this.getDashboardCustomization}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfigurationsFormC)
