import { GET_MERCHANT_PAYOUT_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantPayoutSettingsRequest = ({
  merchantId,
  credentials,
}) => createAction({
  type: GET_MERCHANT_PAYOUT_SETTINGS_F_REQUEST,
  id: merchantId,
  credentials,
  meta: {
    selectorId: merchantId,
    showErrors: false,
  },
})

export default getMerchantPayoutSettingsRequest
