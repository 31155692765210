import React from 'react'
import PropTypes from 'prop-types'
import CreateSubscriptionAmountForm from 'components/Customer/Forms/CreateSubscriptionAmountForm/CreateSubscriptionAmountForm'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const CreateSubscriptionAmountModal = ({
  subscriptionScheduleId,
  closeModal,
  credentials,
}) => {
  return (
    <GenericModal
      title='Create Subscription Amount'
      className='CreateSubscriptionAmountModal'
      Component={CreateSubscriptionAmountForm}
      subscriptionScheduleId={subscriptionScheduleId}
      closeModal={closeModal}
      credentials={credentials}
    />
  )
}

CreateSubscriptionAmountModal.propTypes = {
  subscriptionScheduleId: PropTypes.string,
  closeModal: PropTypes.func,
  credentials: PropTypes.object,
}

export default CreateSubscriptionAmountModal
