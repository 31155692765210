import './CheckboxGroupFieldS.scss'
import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import Loader from 'components/Shared/Loader/Loader'
import map from 'lodash/map'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_ICON,
  TIMES_OCTAGON,
} from 'constants/iconConstants'

// used in redux forms for checkbox groups
const CheckboxGroupField = ({
  input,
  label,
  helperText,
  options,
  disabled = false,
  tooltip,
  tooltipPosition,
  required = true,
  meta: {
    touched,
    error,
    warning,
  },
  emptyOptionsMessage = '',
  emptyOptionsTitle = '',
  isFetchingOptions = false,
}) => {
  const { name = '', value } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const inputClassName = name.replace('.', '_')
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

  return (
    <div className={`CheckboxGroupField ${inputClassName}`}>
      <div className='flex column label-container'>
        <div className='flex'>
          { label && <label className='checkbox-group-field-label label-2'>{label}</label> }
          { label && required && <div className='required-field'>*</div> }
          { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
        </div>
        { helperText && <label className='checkbox-group-field-helper-text label-2'>{helperText}</label> }
      </div>

      <div className='checkbox-group flex column'>
        {isFetchingOptions && <Loader />}
        {!isFetchingOptions && isEmpty(options) &&
            (
            <div className='ErrorMessage'>
              <div className='flex'>
                <i className={`icon fa fa-${TIMES_OCTAGON}`} />
                <h6 className='p-2-bold'>{emptyOptionsTitle}</h6>
              </div>
              <div className='p-2 error-message-text'>{emptyOptionsMessage}</div>
            </div>
            )
        }
        {
          !isEmpty(options) && !isFetchingOptions && map(options, ({ label: checkboxLabel, value: checkboxValue, header, disabled: optionDisabled, helperText: checkboxHelperText }) => {
            const checkboxClassNames = classnames({
              checkbox: true,
              flex: true,
              'items-flex-start': true,
              'items-center': true,
              [hasError]: !!hasError,
              disabled: optionDisabled || disabled,
            })

            return (
              <div className='checkbox-container' key={`${name}.${checkboxValue}`}>
                {/* NOTE: Used in compliance forms - please use the label above instead of this header moving forward */}
                { header && <div className='checkbox-option-header'>{header}</div>}

                <label className={checkboxClassNames}>
                  <input
                    type='checkbox'
                    name={`${name}.${checkboxValue}`}
                    value={checkboxValue}
                    checked={get(value, checkboxValue)}
                    disabled={optionDisabled || disabled}
                    onChange={(event) => {
                      const newValue = merge({}, value, {
                        [checkboxValue]: event.target.checked,
                      })

                      return input.onChange(newValue)
                    }}
                  />

                  { checkboxLabel && <span className='checkbox-option-label label-2'>{checkboxLabel}</span> }
                </label>
                { checkboxHelperText && <label className='checkbox-option-helper-text label-2 secondary'>{checkboxHelperText}</label> }
              </div>
            )
          })
        }
      </div>

      { (touched && (errorJSX || warningJSX)) }
    </div>
  )
}

CheckboxGroupField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object,
  emptyOptionsMessage: PropTypes.string,
  emptyOptionsTitle: PropTypes.string,
  isFetchingOptions: PropTypes.bool,
}

export default CheckboxGroupField
