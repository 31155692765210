import { GET_SUBSCRIPTION_PLANS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSubscriptionPlansRequest = ({
  credentials,
  queries,
  meta,
}) => createAction({
  type: GET_SUBSCRIPTION_PLANS_F_REQUEST,
  credentials,
  queries,
  meta,
})

export default getSubscriptionPlansRequest
