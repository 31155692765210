import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import { getTransferAttemptsListSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import { TRANSFER_ATTEMPTS } from 'constants/linkConstants'

import {
  GET_TRANSFER_ATTEMPTS,
  TRANSFER_ATTEMPT,
} from 'constants/flowConstants'

import {
  PAYOUT_ATTEMPTS,
  TRANSFER_ATTEMPTS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const PayoutLinkTransferAttempts = ({
  isFetching = false,
  initialQueries = {},
  transferAttemptPath = '',
}) => {
  return (
    <div className='PayoutLinkTransferAttempts'>
      <TableC
        flow={GET_TRANSFER_ATTEMPTS}
        columnDescriptors={columnDescriptors}
        selector={getTransferAttemptsListSelector}
        initialQueries={initialQueries}
        isFetching={isFetching}
        path={transferAttemptPath}
        emptyMessage={TRANSFER_ATTEMPTS_EMPTY_TABLE_MESSAGE}
        fileResourceTitleCSV={PAYOUT_ATTEMPTS}
        fileNameCSV='Payout_Link_Payout_Attempts'
        entityType={TRANSFER_ATTEMPT}
        linksKey={TRANSFER_ATTEMPTS}
      />
    </div>
  )
}

PayoutLinkTransferAttempts.propTypes = {
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  transferAttemptPath: PropTypes.string,
}

export default PayoutLinkTransferAttempts
