import React, { Component } from 'react'
import { connect } from 'react-redux'
import PastRuns from './PastRuns'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import createUrl from 'utilities/create/createUrl'
import removeUndefined from 'utilities/remove/removeUndefined'
import { UNDERWRITING_PAST_RUN_PATH } from 'constants/pathConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `runsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    reviewId,
    subjectId,
    entityId,
    entityType,
  ] = getMany(props, [
    'reviewId',
    'subjectId',
    'entityId',
    'entityType',
  ])

  const pastRunPath = ({ id }) => createUrl({
    url: UNDERWRITING_PAST_RUN_PATH({ credentialId, reviewId, runId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
    }),
  })

  const flowValues = {
    id: reviewId,
  }

  return {
    isFetching,
    credentialId,
    flowValues,
    pastRunPath,
  }
}

class PastRunsC extends Component {
  render() {
    return (
      <PastRuns
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(PastRunsC)
