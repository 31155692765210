import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionPlanTabs from './SubscriptionPlanTabs'
import SubscriptionPlanC from './SubscriptionPlanC'
import { goToPath } from 'state-layer/history'
import { getSubscriptionPlanDataSelector } from 'state-layer/selectors'
import SubscriptionsC from 'components/Customer/Pages/Subscriptions/SubscriptionsC'
import SubscriptionPlanStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SubscriptionPlanStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getSubscriptionPlanRequest from 'utilities/actions/get/getSubscriptionPlanRequest'
import showModalAction from 'utilities/actions/showModalAction'
import { ARCHIVE_A_PLAN_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import { INACTIVE } from 'constants/statusConstants'
import { SUBSCRIPTION_PLAN_ID } from 'constants/filterConstants'
import get from 'lodash/get'

import {
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  RECURRING_PRICE,
  SUBSCRIPTION_PLAN,
  STATUS,
  GENERAL_INFO,
  SUBSCRIPTIONS,
  CREATE_A_SUBSCRIPTION,
  EDIT_A_SUBSCRIPTION_PLAN,
  ARCHIVE_A_PLAN,
} from 'constants/language/languageConstants'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  CREATE_SUBSCRIPTION_PATH,
  EDIT_SUBSCRIPTION_PLAN_PATH,
  MERCHANT_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionPlansR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const subscriptionPlanId = get(props, 'params.subscriptionPlanId')
  const subscriptionPlan = getSubscriptionPlanDataSelector(state, subscriptionPlanId)

  const [
    applicationId,
    applicationName,
    merchantName,
    merchantId,
    identityId,
    displayAmount,
    planName,
    status,
  ] = getMany(subscriptionPlan, [
    'application.id',
    'application.businessName',
    'merchant.name',
    'merchant.id',
    'identity.id',
    'displayAmount',
    'planName',
    'status',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_ACCOUNT_RESOURCE_TITLE,
        value: merchantName,
        path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ credentialId, identityId, merchantId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: SUBSCRIPTION_PLAN,
      id: subscriptionPlanId,
    },
    items: [
      {
        value: <h1>{planName}</h1>,
      },
      {
        label: RECURRING_PRICE,
        value: displayAmount,
      },
      {
        label: STATUS,
        value: <SubscriptionPlanStatus value={status} />,
      },
    ],
  }

  const queryKey = 'subscription_plan_id'

  const tabs = [
    {
      id: 'general-info',
      name: GENERAL_INFO,
      component: SubscriptionPlanC,
      props: {
        subscriptionPlanId,
      },
    },
    {
      id: 'subscriptions',
      name: SUBSCRIPTIONS,
      component: SubscriptionsC,
      props: {
        subscriptionPlanId,
        merchantId,
        hiddenFilters: ['subscription_plan_id'],
        hideHeader: true,
        queries: {
          [queryKey]: [subscriptionPlanId],
        },
      },
    },
  ]

  return {
    isFetching,
    credentials,
    credentialId,
    subscriptionPlanId,
    subscriptionPlan,
    merchantId,
    identityId,
    status,
    contextBarData,
    headerData,
    tabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionPlan: ({ credentials, subscriptionPlanId }) => { dispatch(getSubscriptionPlanRequest({ credentials, subscriptionPlanId })) },
    showArchiveSubscriptionModal: (modalProps) => dispatch(showModalAction({ modalType: ARCHIVE_A_PLAN_MODAL, modalProps, className: 'modal-sm no-pad' })),
  }
}

class SubscriptionPlanTabsC extends Component {
  componentDidMount() {
    const {
      subscriptionPlanId,
      credentials,
      getSubscriptionPlan,
    } = this.props

    if (subscriptionPlanId) {
      getSubscriptionPlan({ credentials, subscriptionPlanId })
    }
  }

  componentDidUpdate(prevProps) {
    const { subscriptionPlanId: prevSubscriptionPlanId } = prevProps
    const {
      subscriptionPlanId,
      credentials,
      getSubscriptionPlan,
    } = this.props

    if (subscriptionPlanId && prevSubscriptionPlanId !== subscriptionPlanId) {
      getSubscriptionPlan({ credentials, subscriptionPlanId })
    }
  }

  render() {
    const {
      subscriptionPlanId,
      credentialId,
      merchantId,
      identityId,
      showArchiveSubscriptionModal,
      status,
    } = this.props

    const actions = [
      {
        label: CREATE_A_SUBSCRIPTION,
        action: () => goToPath({
          pathname: CREATE_SUBSCRIPTION_PATH({ credentialId }),
          queries: {
            subscriptionPlanId,
            merchantId,
            identityId,
          },
        }),
      },
      {
        label: EDIT_A_SUBSCRIPTION_PLAN,
        className: 'edit-subscription-plan-button',
        action: () => goToPath({ pathname: EDIT_SUBSCRIPTION_PLAN_PATH({ credentialId, subscriptionPlanId }),
        }),
      },
      {
        label: ARCHIVE_A_PLAN,
        action: () => showArchiveSubscriptionModal({ subscriptionPlanId }),
        condition: status !== INACTIVE,
      },
    ]

    return (
      <SubscriptionPlanTabs
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanTabsC)
