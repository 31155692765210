import dashboardAPI from 'utilities/api/dashboardAPI'

import {
  DASHBOARD_UNDERWRITING,
  DASHBOARD_UNDERWRITING_REVIEWS,
} from 'constants/apiConstants'

const getUnderwritingReviewsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_UNDERWRITING_REVIEWS,
    service: DASHBOARD_UNDERWRITING,
    queries,
    meta,
  })
}

export default getUnderwritingReviewsAPI
