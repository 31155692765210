import guestCheckoutAPI from 'utilities/api/guestCheckoutAPI'
import { HOSTED_PAYMENTS_MERCHANT_PAYMENT_INSTRUMENTS } from 'constants/apiConstants'

const getGuestCheckoutMerchantPaymentInstrumentsAPI = ({ id }) => {
  return guestCheckoutAPI.get({
    path: HOSTED_PAYMENTS_MERCHANT_PAYMENT_INSTRUMENTS({ merchantId: id }),
  })
}

export default getGuestCheckoutMerchantPaymentInstrumentsAPI
