import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import { getMerchantsByApplicationIdSelector } from 'state-layer/selectors'
import PaymentDevices from 'components/Customer/Pages/PaymentDevices/PaymentDevices'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import { SELECT_ADD_DEVICE_MERCHANT_MODAL } from 'constants/modalConstants'
import { ADD_NEW_DEVICE } from 'constants/language/languageConstants'
import { FETCHING } from 'constants/reducerConstants'
import { SANDBOX_ENVIRONMENT } from 'constants/environmentConstants'
import { ADD_NEW_DEVICE_PATH, PAYMENT_DEVICES_PATH } from 'constants/pathConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import {
  DEVICE_DATES_QUICK_FILTER,
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  DEVICE_MODEL,
  DEVICE_NAME,
  SERIAL_NUMBER,
  MERCHANT_ID,
} from 'constants/filterConstants'

import {
  DUMMY_V1,
  FINIX_V1,
  LITLE_V1,
} from 'constants/processorConstants'

const allowedFilters = sortBy([
  DEVICE_MODEL,
  DEVICE_NAME,
  MERCHANT_ID,
  SERIAL_NUMBER,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  PAYMENT_DEVICES_STATE_QUICK_FILTER,
  DEVICE_DATES_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `devicesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const paymentDevicePath = PAYMENT_DEVICES_PATH({ credentialId })
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, SANDBOX_ENVIRONMENT) ? [DUMMY_V1] : [LITLE_V1, FINIX_V1]

  const [
    queries = {},
    applicationId,
  ] = getMany(props, [
    'queries',
    'applicationId',
  ])

  const mergedQueries = merge({}, initialQueries, queries)
  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)
  const createDeviceEnabledApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))

  return {
    quickFilters,
    allowedFilters,
    initialQueries: mergedQueries,
    isFetching,
    paymentDevicePath,
    credentials,
    credentialId,
    applicationId,
    showHeader: false,
    applicationMerchants,
    createDeviceEnabledApplicationMerchants,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSelectAddDeviceMerchantModal: (modalProps => dispatch(showModalAction({
      modalType: SELECT_ADD_DEVICE_MERCHANT_MODAL,
      className: 'modal-lg no-pad overflow-visible',
      modalProps,
    }))),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
  }
}

class ApplicationPaymentDevicesC extends Component {
  componentDidMount() {
    const {
      getApplicationMerchants,
      applicationId,
      credentials,
    } = this.props

    if (applicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getApplicationMerchants,
      applicationId,
      credentials,
    } = this.props

    const { applicationId: prevApplicationId } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getApplicationMerchants({ applicationId, credentials })
    }
  }

  render() {
    const {
      credentialId,
      createDeviceEnabledApplicationMerchants,
      applicationId,
    } = this.props

    const hasOneMerchant = size(createDeviceEnabledApplicationMerchants) === 1

    const actions = [
      {
        label: ADD_NEW_DEVICE,
        className: 'add-new-device',
        action: () => goToPath({
          pathname: ADD_NEW_DEVICE_PATH({ credentialId }),
          queries: hasOneMerchant ? {
            merchantId: get(createDeviceEnabledApplicationMerchants, '[0].id'),
            applicationId,
          } : { applicationId },
        }),
      },
    ]

    return (
      <PaymentDevices
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPaymentDevicesC)
