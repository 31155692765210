import './RequiredFieldNoticeS.scss'
import React from 'react'

const RequiredFieldNotice = () => {
  return (<div className='RequiredFieldNotice flex flex-end label-2'>* = required field</div>)
}

RequiredFieldNotice.propTypes = {}

export default RequiredFieldNotice
