import frontendComplianceFormTemplateModel from 'utilities/create/models/frontendComplianceFormTemplateModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendComplianceFormTemplatesModel = ({ data: complianceFormTemplates }) => {
  const complianceFormTemplatesModels = map(complianceFormTemplates, (complianceFormTemplate) => frontendComplianceFormTemplateModel({ data: complianceFormTemplate }))

  return keyBy(complianceFormTemplatesModels, 'id')
}

export default frontendComplianceFormTemplatesModel
