const A = 'A'
const B = 'B'
const C = 'C'
const D = 'D'
const F = 'F'
const G = 'G'
const I = 'I'
const M = 'M'
const N = 'N'
const P = 'P'
const R = 'R'
const S = 'S'
const U = 'U'
const W = 'W'
const Y = 'Y'
const Z = 'Z'

// verification types
export const ADDRESS = 'ADDRESS'
export const CVV2 = 'CVV2'
export const FAST_FUNDS = 'FAST_FUNDS'
export const PUSH_FUNDS_BLOCK = 'PUSH_FUNDS_BLOCK'

// CVC Status
export const MATCHED = 'MATCHED'
export const NOT_MATCHED = 'NOT_MATCHED'
export const NOT_VERIFIED_CVC = 'NOT_VERIFIED'

// Fast Funds Availability
export const IMMEDIATE = 'IMMEDIATE'

export const VERIFIED = 'Verified'

export const NOT_VERIFIED = 'Not verified'

export const ELIGIBLE = 'Eligible'

export const NON_ELIGIBLE = 'Not eligible'

export const ACCEPTED = 'Accepted'

export const NOT_ACCEPTED = 'Not Accepted'

export const VERIFIED_ADDRESS_WARNING = 'Postal Match; Street Address Unverified'

export const SUCCESSFUL_ADDRESS_VERIFICATIONS = [D,F,M,Y]

export const FAILED_ADDRESS_VERIFICATIONS = [A,B,C,G,I,N,P,R,S,U,W]

export const WARNING_ADDRESS_VERIFICATIONS = [Z]

export const NOT_VERIFIED_CVV2 = 'Incorrect CVV or Expiration'

export const UNKNOWN_CVV2_VERIFICATION= 'Unable to Verify'

export const SUCCESSFUL_CVV2_VERIFICATIONS = [M, MATCHED,]

export const FAILED_CVV2_VERIFICATIONS = [N,P,S, NOT_MATCHED]

export const WARNING_CVV2_VERIFICATIONS = [U, NOT_VERIFIED_CVC]

export const ELIGIBLE_FAST_FUNDS = [B,D, IMMEDIATE]

export const NON_ELIGIBLE_FAST_FUNDS = [N]

export const ACCEPTED_PUSH_FUNDS = [A, B, C]

export const NOT_ACCEPTED_PUSH_FUNDS = [N]
