import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { SUBMIT_GUEST_DASHBOARD_ACCESS_FORM } from 'constants/apiConstants'
import get from 'lodash/get'

const postSubmitGuestAccessFormAPI = ({ values, meta }) => {
  const id = get(values, 'id')

  return guestDashboardAPI.post({
    path: SUBMIT_GUEST_DASHBOARD_ACCESS_FORM({ id }),
    values,
    meta,
  })
}

export default postSubmitGuestAccessFormAPI
