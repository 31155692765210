import React from 'react'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import getMany from 'utilities/get/getMany'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

const PaymentInstrumentCardColumn = ({ paymentInstrument = {} }) => {
  const [
    brand,
    cardType,
    instrumentType,
  ] = getMany(paymentInstrument, [
    'displayBrand',
    'cardType',
    'type',
  ])

  const subTitle = brand && cardType ? `${brand} / ${cardType}` : '-'

  return (
    <div className='PaymentInstrumentCardColumn'>
      <TableItemSubtitle
        title={instrumentType}
        subtitle={subTitle}
      />
    </div>
  )
}

export default PaymentInstrumentCardColumn
