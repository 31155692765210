import dashboardAPI from 'utilities/api/dashboardAPI'
import { FILES } from 'constants/apiConstants'

const getFilesAPI = ({ queries, credentials, meta }) => {
  return dashboardAPI.get({
    meta,
    credentials,
    path: FILES,
    queries,
  })
}

export default getFilesAPI
