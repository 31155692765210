import './AddressFieldsetOneColS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  USA,
  defaultRegionLabel,
  defaultPostalCodeLabel,
  countryNamesOptions,
  countryToRegionLabelMap,
  countryToPostalCodeLabelMap,
  regionNameOptionsByCountryCode,
} from 'constants/countryConstants'

const AddressFieldsetOneCol = ({
  name = 'address',
  label = '',
  tooltips = {},
  lowerLabels = {},
  fieldLabel = 'Address',
  countryCode = USA,
  disabledFields = {},
}) => {
  const fieldName = (field) => `${name}.${field}`
  const labelName = (labelString) => `${fieldLabel} ${labelString}`
  const { addressLine1, addressLine2, city, state, postalCode, country } = tooltips

  const [
    addressLine1LowerLabel,
    addressLine2LowerLabel,
    cityLowerLabel,
    stateLowerLabel,
    postalCodeLowerLabel,
    countryLowerLabel,
  ] = getMany(lowerLabels, [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postalCode',
    'country',
  ])

  const { disabledCountry = false } = disabledFields

  const regionNameOptions = regionNameOptionsByCountryCode(countryCode)
  const regionLabel = get(countryToRegionLabelMap, countryCode, defaultRegionLabel)
  const postalCodeLabel = get(countryToPostalCodeLabelMap, countryCode, defaultPostalCodeLabel)

  return (
    <fieldset className='AddressFieldsetOneCol'>
      { label && <label className='label-2'>{label}</label> }

      <Field
        name={fieldName('line1')}
        label={labelName('Line 1')}
        component={InputField}
        ariaLabel={`${label} line1`}
        tooltip={addressLine1}
        helperText={addressLine1LowerLabel}
        autoComplete='address-line1'
      />

      <Field
        name={fieldName('line2')}
        label={labelName('Line 2')}
        component={InputField}
        ariaLabel={`${label} line2`}
        tooltip={addressLine2}
        helperText={addressLine2LowerLabel}
        autoComplete='address-line2'
        required={false}
      />

      <Field
        name={fieldName('city')}
        label='City'
        component={InputField}
        ariaLabel={`${label} city`}
        tooltip={city}
        helperText={cityLowerLabel}
        autoComplete='address-level2'
      />

      { regionNameOptions && (
        <Field
          name={fieldName('region')}
          label={regionLabel}
          component={SelectField}
          options={regionNameOptions}
          ariaLabel={`${label} region`}
          tooltip={state}
          helperText={stateLowerLabel}
          autoComplete='address-level1'
        />
      )}

      { !regionNameOptions && (
        <Field
          name={fieldName('region')}
          label={regionLabel}
          component={InputField}
          ariaLabel={`${label} region`}
          tooltip={state}
          helperText={stateLowerLabel}
          autoComplete='address-level1'
        />
      )}

      <Field
        name={fieldName('postalCode')}
        label={postalCodeLabel}
        component={InputField}
        ariaLabel={`${label} postal code`}
        tooltip={postalCode}
        helperText={postalCodeLowerLabel}
        placeholder='00000'
        autoComplete='postal-code'
      />

      <Field
        name={fieldName('country')}
        label='Country'
        component={SelectField}
        options={countryNamesOptions}
        ariaLabel={`${label} country`}
        tooltip={country}
        disabled={disabledCountry}
        helperText={countryLowerLabel}
        autoComplete='country'
      />
    </fieldset>
  )
}

AddressFieldsetOneCol.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tooltips: PropTypes.object,
  lowerLabels: PropTypes.object,
  fieldLabel: PropTypes.string,
  countryCode: PropTypes.string,
  disabledFields: PropTypes.object,
}

export default AddressFieldsetOneCol
