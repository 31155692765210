import removeUndefined from 'utilities/remove/removeUndefined'
import pick from 'lodash/pick'

import {
  FIXED_TIME_INTERVAL,
  PERIODIC_MONTHLY,
  FEE,
} from 'constants/subscriptionConstants'

const backendSubscriptionScheduleModel = ({ values }) => {
  const {
    scheduleNickname,
    subscriptionType,
    periodOffset,
    fixedTimeIntervalOffset = {},
    tags,
  } = values

  const showPeriodicMonthlyOffset = subscriptionType === PERIODIC_MONTHLY
  const formattedPeriodOffset = showPeriodicMonthlyOffset ? pick(periodOffset, 'day') : periodOffset

  const showFixedTimeIntervalOffset = subscriptionType === FIXED_TIME_INTERVAL
  const { hourlyInterval, intervalCount } = fixedTimeIntervalOffset
  const formattedFixedTimeIntervalOffset = { hourly_interval: hourlyInterval, interval_count: intervalCount }

  return removeUndefined({
    subscription_type: subscriptionType,
    nickname: scheduleNickname,
    line_item_type: FEE,
    tags,
    period_offset: !showFixedTimeIntervalOffset ? formattedPeriodOffset : undefined,
    fixed_time_interval_offset: showFixedTimeIntervalOffset ? formattedFixedTimeIntervalOffset : undefined,
  })
}

export default backendSubscriptionScheduleModel
