import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  IDENTITIES,
  APPLICATION_ASSOCIATED_IDENTITIES,
} from 'constants/apiConstants'

const postApplicationAssociatedIdentityAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: APPLICATION_ASSOCIATED_IDENTITIES({ identityId: id }),
    service: IDENTITIES,
  })
}

export default postApplicationAssociatedIdentityAPI
