import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ALERT_CIRCLE_ICON,
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  CIRCLE_MINUS_ICON,
} from 'constants/iconConstants'

import {
  ACCEPTED,
  MANUAL_REVIEW,
  REJECTED,
} from 'constants/underwritingConstants'

const formatUnderwritingReportTitle = (
  report,
  reportTitle,
  isAdverseMedia = false,
) => {
  if (isAdverseMedia) {
    const hasMatch = !!get(report, 'found')

    if (isEmpty(report)) {
      return (<div className='report-title flex items-center'><i className={`fa ${CIRCLE_MINUS_ICON}`} />{reportTitle}</div>)
    }

    return (<div className='report-title flex items-center'><i className={`fa fa-${hasMatch ? ALERT_ICON : CHECK_CIRCLE_ICON}`} />{reportTitle}</div>)
  }

  const decisionedResponse = get(report, 'decisioned_response')
  let iconName = `${CIRCLE_MINUS_ICON}`

  if (decisionedResponse === MANUAL_REVIEW) {
    iconName = `fa-${ALERT_ICON}`
  }

  if (decisionedResponse === ACCEPTED) {
    iconName = `fa-${CHECK_CIRCLE_ICON}`
  }

  if (decisionedResponse === REJECTED) {
    iconName = `fa-${ALERT_CIRCLE_ICON}`
  }

  return (
    <div className='report-title flex items-center'><i className={`fa ${iconName}`} />{reportTitle}</div>
  )
}

export default formatUnderwritingReportTitle
