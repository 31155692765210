import includes from 'lodash/includes'
import numeral from 'numeral'
import toString from 'lodash/toString'
import replace from 'lodash/replace'

import {
  USD,
  currenciesWithCents,
  CURRENCY_NAME_TO_SYMBOL_MAP,
} from 'constants/currencyConstants'

const displayAmountToAmount = (displayAmount, currency = USD) => {
  if (!displayAmount) {
    return 0
  }

  // if currency does not support cents, just return integer representation of displayAmount
  if (!includes(currenciesWithCents, currency)) {
    return numeral(displayAmount).value()
  }

  const currencySymbol = CURRENCY_NAME_TO_SYMBOL_MAP[currency] || '$'
  const numeralValue = replace(replace(displayAmount, currencySymbol, ''), /,/g, '')
  const normalizedAmountString = toString(numeralValue) || '0.00'
  const dollarsAndCents = normalizedAmountString.split('.')
  const dollars = parseInt(dollarsAndCents[0], 10) * 100
  const normalizedCentsString = dollarsAndCents[1] && dollarsAndCents[1].length === 1 ? `${dollarsAndCents[1]}0` : dollarsAndCents[1]
  const cents = parseInt(normalizedCentsString, 10) || 0

  return dollars + cents
}

export default displayAmountToAmount
