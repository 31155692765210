import React, { Component } from 'react'
import { connect } from 'react-redux'
import FeeProfileRules from './FeeProfileRules'
import { goToPath } from 'state-layer/history'
import { getFeeProfileRuleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getFeeProfileRulesRequest from 'utilities/actions/get/getFeeProfileRulesRequest'
import postDefaultFeeProfileRulesRequest from 'utilities/actions/post/postDefaultFeeProfileRulesRequest'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATION } from 'constants/dashboardConfigurationsConstants'
import { EDIT_APPLICATION_FEE_PROFILE_RULES_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import {
  ACH,
  ACH_CREDIT,
  ACH_DEBIT,
  ALL_CARD_BRANDS,
  AMERICAN_EXPRESS_ASSESSMENTS,
  CARD_CROSS_BORDER,
  CHARGEBACK_INQUIRY,
  CHARGEBACK_NOTIFICATION,
  DISCOVER_ASSESSMENTS,
  DISCOVER_DATA_USAGE,
  DISCOVER_NETWORK_AUTHORIZATION,
  EDIT,
  MASTERCARD_ACQUIRER_FEES,
  MASTERCARD_ASSESSMENTS_OVER_1000,
  MASTERCARD_ASSESSMENTS_UNDER_1000,
  SET_DEFAULT_LIMITS,
  SUPPLEMENTAL_FEE_1,
  SUPPLEMENTAL_FEE_2,
  VISA_ACQUIRER_PROCESSING,
  VISA_ASSESSMENTS,
  VISA_BASE_SYSTEM_FILE_TRANSMISSION,
  VISA_CREDIT_VOUCHER,
  VISA_KILOBYTE_ACCESS,
  CREATE,
} from 'constants/language/languageConstants'

import {
  AMEX_LABEL,
  DINERS_CLUB_LABEL,
  DISCOVER_LABEL,
  JCB_LABEL,
  MASTERCARD_LABEL,
  VISA_LABEL,
} from 'constants/cardConstants'

const createDataForBlendedFeeProfileRules = (data) => {
  if (!data) {
    return null
  }

  const [
    country,
    currency,
    basisPointsString,
    fixedFeeString,
    cardCrossBorderBasisPointsString,
    cardCrossBorderFixedFeeString,
    visaBasisPointsString,
    visaFixedFeeString,
    mastercardBasisPointsString,
    mastercardFixedFeeString,
    americanExpressBasisPointsString,
    americanExpressFixedFeeString,
    discoverBasisPointsString,
    discoverFixedFeeString,
    jcbBasisPointsString,
    jcbFixedFeeString,
    dinersClubBasisPointsString,
    dinersClubFixedFeeString,
    disputeFixedFeeString,
    disputeInquiryFixedFeeString,
  ] = getMany(data, [
    'country',
    'currency',
    'basisPointsString',
    'fixedFeeString',
    'cardCrossBorderBasisPointsString',
    'cardCrossBorderFixedFeeString',
    'visaBasisPointsString',
    'visaFixedFeeString',
    'mastercardBasisPointsString',
    'mastercardFixedFeeString',
    'americanExpressBasisPointsString',
    'americanExpressFixedFeeString',
    'discoverBasisPointsString',
    'discoverFixedFeeString',
    'jcbBasisPointsString',
    'jcbFixedFeeString',
    'dinersClubBasisPointsString',
    'dinersClubFixedFeeString',
    'disputeFixedFeeString',
    'disputeInquiryFixedFeeString',
  ])

  const feeTypeRows = [
    { isVisible: !!basisPointsString || !!fixedFeeString,
      columns: [
        ALL_CARD_BRANDS,
        basisPointsString,
        fixedFeeString,
      ],
    },
    { isVisible: !!cardCrossBorderBasisPointsString || !!cardCrossBorderFixedFeeString,
      columns: [
        CARD_CROSS_BORDER,
        cardCrossBorderBasisPointsString,
        cardCrossBorderFixedFeeString,
      ],
    },
  ]

  const cardBrandRows = [
    { isVisible: !!visaBasisPointsString || !!visaFixedFeeString,
      columns: [
        VISA_LABEL,
        visaBasisPointsString,
        visaFixedFeeString,
      ],
    },
    { isVisible: !!mastercardBasisPointsString || !!mastercardFixedFeeString,
      columns: [
        MASTERCARD_LABEL,
        mastercardBasisPointsString,
        mastercardFixedFeeString,
      ],
    },
    { isVisible: !!americanExpressBasisPointsString || !!americanExpressFixedFeeString,
      columns: [
        AMEX_LABEL,
        americanExpressBasisPointsString,
        americanExpressFixedFeeString,
      ],
    },
    { isVisible: !!discoverBasisPointsString || !!discoverFixedFeeString,
      columns: [
        DISCOVER_LABEL,
        discoverBasisPointsString,
        discoverFixedFeeString,
      ],
    },
    { isVisible: !!jcbBasisPointsString || !!jcbFixedFeeString,
      columns: [
        JCB_LABEL,
        jcbBasisPointsString,
        jcbFixedFeeString,
      ],
    },
    { isVisible: !!dinersClubBasisPointsString || !!dinersClubFixedFeeString,
      columns: [
        DINERS_CLUB_LABEL,
        dinersClubBasisPointsString,
        dinersClubFixedFeeString,
      ],
    },
  ]

  const hasCardBrandOverrides = some(cardBrandRows, 'isVisible')

  const hideChargebackRows = !disputeFixedFeeString && !disputeInquiryFixedFeeString
  const chargebackRows = [
    { isVisible: !!disputeFixedFeeString,
      columns: [
        CHARGEBACK_NOTIFICATION,
        disputeFixedFeeString,
      ],
    },
    { isVisible: !!disputeInquiryFixedFeeString,
      columns: [
        CHARGEBACK_INQUIRY,
        disputeInquiryFixedFeeString,
      ],
    },
  ]

  return {
    feeTypeRows,
    cardBrandRows,
    hasCardBrandOverrides,
    hideChargebackRows,
    chargebackRows,
    country,
    currency,
  }
}

const createDataForInterchangeFeeProfileRules = (data) => {
  if (!data) {
    return null
  }

  const blendedData = createDataForBlendedFeeProfileRules(data)

  const [
    country,
    currency,
    visaAssessmentsBasisPointsString,
    visaAcquirerProcessingFixedFeeString,
    visaCreditVoucherFixedFeeString,
    visaBaseIISystemFileTransmissionFixedFeeString,
    visaKilobyteAccessFixedFeeString,
    mastercardAssessmentsUnder1kBasisPointsString,
    mastercardAssessmentsOver1kBasisPointsString,
    mastercardAcquirerFeesBasisPointsString,
    americanExpressAssessmentBasisPointsString,
    discoverAssessmentsBasisPointsString,
    discoverDataUsageFixedFeeString,
    discoverNetworkAuthorizationFixedFeeString,
  ] = getMany(data, [
    'country',
    'currency',
    'visaAssessmentsBasisPointsString',
    'visaAcquirerProcessingFixedFeeString',
    'visaCreditVoucherFixedFeeString',
    'visaBaseIISystemFileTransmissionFixedFeeString',
    'visaKilobyteAccessFixedFeeString',
    'mastercardAssessmentsUnder1kBasisPointsString',
    'mastercardAssessmentsOver1kBasisPointsString',
    'mastercardAcquirerFeesBasisPointsString',
    'americanExpressAssessmentBasisPointsString',
    'discoverAssessmentsBasisPointsString',
    'discoverDataUsageFixedFeeString',
    'discoverNetworkAuthorizationFixedFeeString',
  ])

  const interchangeVisaRows = [
    { isVisible: !!visaAssessmentsBasisPointsString,
      columns: [
        VISA_ASSESSMENTS,
        visaAssessmentsBasisPointsString,
        '',
      ],
    },
    { isVisible: !!visaAcquirerProcessingFixedFeeString,
      columns: [
        VISA_ACQUIRER_PROCESSING,
        '',
        visaAcquirerProcessingFixedFeeString,
      ],
    },
    { isVisible: !!visaCreditVoucherFixedFeeString,
      columns: [
        VISA_CREDIT_VOUCHER,
        '',
        visaCreditVoucherFixedFeeString,
      ],
    },
    { isVisible: !!visaBaseIISystemFileTransmissionFixedFeeString,
      columns: [
        VISA_BASE_SYSTEM_FILE_TRANSMISSION,
        '',
        visaBaseIISystemFileTransmissionFixedFeeString,
      ],
    },
    { isVisible: !!visaKilobyteAccessFixedFeeString,
      columns: [
        VISA_KILOBYTE_ACCESS,
        '',
        visaKilobyteAccessFixedFeeString,
      ],
    },
  ]

  const hasInterchangeVisaRows = some(interchangeVisaRows, 'isVisible')

  const interchangeMastercardRows = [
    { isVisible: !!mastercardAssessmentsUnder1kBasisPointsString,
      columns: [
        MASTERCARD_ASSESSMENTS_UNDER_1000,
        mastercardAssessmentsUnder1kBasisPointsString,
        '',
      ],
    },
    { isVisible: !!mastercardAssessmentsOver1kBasisPointsString,
      columns: [
        MASTERCARD_ASSESSMENTS_OVER_1000,
        mastercardAssessmentsOver1kBasisPointsString,
        '',
      ],
    },
    { isVisible: !!mastercardAcquirerFeesBasisPointsString,
      columns: [
        MASTERCARD_ACQUIRER_FEES,
        mastercardAcquirerFeesBasisPointsString,
        '',
      ],
    },
  ]

  const hasInterchangeMastercardRows = some(interchangeMastercardRows, 'isVisible')

  const interchangeAmexRows = [
    { isVisible: !!americanExpressAssessmentBasisPointsString,
      columns: [
        AMERICAN_EXPRESS_ASSESSMENTS,
        americanExpressAssessmentBasisPointsString,
        '',
      ],
    },
  ]

  const hasInterchangeAmexRows = some(interchangeAmexRows, 'isVisible')

  const interchangeDiscoverRows = [
    { isVisible: !!discoverAssessmentsBasisPointsString,
      columns: [
        DISCOVER_ASSESSMENTS,
        discoverAssessmentsBasisPointsString,
        '',
      ],
    },
    { isVisible: !!discoverDataUsageFixedFeeString,
      columns: [
        DISCOVER_DATA_USAGE,
        '',
        discoverDataUsageFixedFeeString,
      ],
    },
    { isVisible: !!discoverNetworkAuthorizationFixedFeeString,
      columns: [
        DISCOVER_NETWORK_AUTHORIZATION,
        '',
        discoverNetworkAuthorizationFixedFeeString,
      ],
    },
  ]

  const hasInterchangeDiscoverRows = some(interchangeDiscoverRows, 'isVisible')
  const showPassThroughFeesSection = some([hasInterchangeVisaRows, hasInterchangeMastercardRows, hasInterchangeAmexRows, hasInterchangeDiscoverRows])

  return {
    ...blendedData,
    hasInterchangeVisaRows,
    interchangeVisaRows,
    hasInterchangeMastercardRows,
    interchangeMastercardRows,
    hasInterchangeAmexRows,
    interchangeAmexRows,
    hasInterchangeDiscoverRows,
    interchangeDiscoverRows,
    showPassThroughFeesSection,
    country,
    currency,
  }
}

const createDataForAchTransfersFeeProfileRules = (data) => {
  if (!data) {
    return null
  }

  const [
    country,
    currency,
    achBasisPointsString,
    achFixedFeeString,
    achCreditReturnFixedFeeString,
    achDebitReturnFixedFeeString,
  ] = getMany(data, [
    'country',
    'currency',
    'achBasisPointsString',
    'achFixedFeeString',
    'achCreditReturnFixedFeeString',
    'achDebitReturnFixedFeeString',
  ])

  const achFeeTypesRows = [
    { isVisible: !!achBasisPointsString || !!achFixedFeeString,
      columns: [
        ACH,
        achBasisPointsString,
        achFixedFeeString,
      ],
    },
  ]

  const hideAchReturnsRows = !achCreditReturnFixedFeeString && !achDebitReturnFixedFeeString
  const achReturnsRows = [
    { isVisible: !!achCreditReturnFixedFeeString,
      columns: [
        ACH_CREDIT,
        '',
        achCreditReturnFixedFeeString,
      ],
    },
    { isVisible: !!achDebitReturnFixedFeeString,
      columns: [
        ACH_DEBIT,
        '',
        achDebitReturnFixedFeeString,
      ],
    },
  ]

  return {
    achFeeTypesRows,
    hideAchReturnsRows,
    achReturnsRows,
    country,
    currency,
  }
}

const createDataForAdditionalFeesFeeProfileRules = (data) => {
  if (!data) {
    return null
  }

  const [
    country,
    currency,
    ancillaryFixedFeePrimaryString,
    ancillaryFixedFeeSecondaryString,
  ] = getMany(data, [
    'country',
    'currency',
    'ancillaryFixedFeePrimaryString',
    'ancillaryFixedFeeSecondaryString',
  ])

  const hideAdditionalFeesSection = !ancillaryFixedFeePrimaryString && !ancillaryFixedFeeSecondaryString
  const additionalFeesRows = [
    { isVisible: !!ancillaryFixedFeePrimaryString,
      columns: [
        SUPPLEMENTAL_FEE_1,
        ancillaryFixedFeePrimaryString,
      ],
    },
    { isVisible: !!ancillaryFixedFeeSecondaryString,
      columns: [
        SUPPLEMENTAL_FEE_2,
        ancillaryFixedFeeSecondaryString,
      ],
    },
  ]

  return {
    hideAdditionalFeesSection,
    additionalFeesRows,
    country,
    currency,
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feeProfileRulesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const entityId = get(props, 'entityId')
  const entityType = get(props, 'entityType')
  const isReviewPage = get(props, 'isReviewPage', false)
  const feeProfileRulesForReviewPage = get(props, 'feeProfileRulesForReviewPage')
  const feeProfileRules = isReviewPage ? feeProfileRulesForReviewPage[entityId] : getFeeProfileRuleSelector(state, entityId)
  const hasNoFeeProfileRules = isEmpty(feeProfileRules)

  const [
    blendedUSD,
    interchangeUSD,
    blendedCAD,
    interchangeCAD,
  ] = getMany(feeProfileRules, [
    'BLENDED.USD',
    'INTERCHANGE_PLUS.USD',
    'BLENDED.CAD',
    'INTERCHANGE_PLUS.CAD',
  ])

  const blendedData = filter([createDataForBlendedFeeProfileRules(blendedUSD), createDataForBlendedFeeProfileRules(blendedCAD)], item => !isEmpty(item))
  const interchangeData = filter([createDataForInterchangeFeeProfileRules(interchangeUSD), createDataForInterchangeFeeProfileRules(interchangeCAD)], item => !isEmpty(item))
  const achTransfersData = filter([createDataForAchTransfersFeeProfileRules(blendedUSD || interchangeUSD), createDataForAchTransfersFeeProfileRules(blendedCAD || interchangeCAD)], item => !isEmpty(item))
  const additionalFeesData = filter([createDataForAdditionalFeesFeeProfileRules(blendedUSD || interchangeUSD), createDataForAdditionalFeesFeeProfileRules(blendedCAD || interchangeCAD)], item => !get(item, 'hideAdditionalFeesSection', true))

  return {
    isFetching,
    credentials,
    credentialId,
    entityId,
    entityType,
    blendedData,
    interchangeData,
    achTransfersData,
    additionalFeesData,
    hasNoFeeProfileRules,
    isReviewPage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeProfileRules: ({ credentials, values }) => dispatch(getFeeProfileRulesRequest({ credentials, values })),
    postDefaultFeeProfileRules: ({ credentials, id }) => dispatch(postDefaultFeeProfileRulesRequest({ id, credentials })),
  }
}

class FeeProfileRulesC extends Component {
  componentDidMount() {
    const {
      getFeeProfileRules,
      entityId,
      credentials,
    } = this.props

    const values = {
      applicationId: entityId,
    }

    if (entityId) {
      getFeeProfileRules({ credentials, values })
    }
  }

  componentDidUpdate(prevProps) {
    const { entityId: prevEntityId } = prevProps
    const {
      entityId,
      credentials,
      getFeeProfileRules,
    } = this.props

    const values = {
      applicationId: entityId,
    }

    if (entityId && entityId !== prevEntityId) {
      getFeeProfileRules({ credentials, values })
    }
  }

  render() {
    const {
      hasNoFeeProfileRules,
      entityId,
      entityType,
      postDefaultFeeProfileRules,
      credentials,
      credentialId,
      isReviewPage,
    } = this.props

    const editFeeProfileRulesActions = [{
      label: hasNoFeeProfileRules ? CREATE : EDIT,
      action: () => goToPath({
        pathname: EDIT_APPLICATION_FEE_PROFILE_RULES_PATH({ credentialId, applicationId: entityId }),
        queries: {
          entityId,
          entityType,
          activeStep: '0',
        },
      }),
      condition: !isReviewPage && entityType === APPLICATION && isRolePlatform({ credentials }),
    },
    {
      label: SET_DEFAULT_LIMITS,
      action: () => postDefaultFeeProfileRules({ credentials, id: entityId }),
      condition: !isReviewPage && entityType === APPLICATION && isRolePlatform({ credentials }) && hasNoFeeProfileRules,
    },
    ]

    return (
      <FeeProfileRules
        {...this.props}
        editFeeProfileRulesActions={editFeeProfileRulesActions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeeProfileRulesC)
