import getTransferAPI from 'api/dashboard/get/getTransferAPI'
import getSettlementsAPI from 'api/finix/get/getSettlementsAPI'
import getSettlementEntriesAPI from 'api/finix/get/getSettlementEntriesAPI'
import getFeesAPI from 'api/finix/get/getFeesAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import getReceiptsAPI from 'api/finix/get/getReceiptsAPI'
import getPurchasesAPI from 'api/finix/get/getPurchasesAPI'
import getPaymentLinkAPI from 'api/finix/get/getPaymentLinkAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import frontendSettlementModel from 'utilities/create/models/frontendSettlementModel'
import frontendSettlementEntryModel from 'utilities/create/models/frontendSettlementEntryModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendDeviceModel from 'utilities/create/models/frontendDeviceModel'
import frontendFeesModel from 'utilities/create/models/frontendFeesModel'
import frontendPaymentLinkModel from 'utilities/create/models/frontendPaymentLinkModel'
import frontendReceiptsModel from 'utilities/create/models/frontendReceiptsModel'
import getMany from 'utilities/get/getMany'
import { SETTLEMENT_MERCHANT } from 'constants/transferConstants'
import get from 'lodash/get'
import last from 'lodash/last'
import merge from 'lodash/merge'
import co from 'co'

import {
  TRANSFER,
  DEVICE,
  IDENTITY,
} from 'constants/apiConstants'

function * getTransferO ({
  id,
  credentials,
}) {
  const { data: response } = yield getTransferAPI({ id, credentials })
  const links = getResponseLinks(response)

  const [
    parentLink = '',
  ] = getMany(links, [
    'parent',
  ])

  const parentId = last(parentLink.split('/'))

  const [
    deviceId,
    subtype,
  ] = getMany(response, [
    'device',
    'subtype',
  ])

  const { data: feesResponse } = yield getFeesAPI({
    credentials,
    queries: {
      linked_id: id,
    },
  })

  const fees = get(feesResponse, '_embedded.fees')
  const feesModels = fees ? frontendFeesModel({ data: normalizeResponse({ content: fees }) }) : undefined

  const { data: settlementsResponse } = yield getSettlementsAPI({ credentials,
    queries: subtype === SETTLEMENT_MERCHANT ? { funding_transfer_id: id } : { transfer_id: id },
  })

  const settlement = get(settlementsResponse, '_embedded.settlements.0')
  const normalizedSettlement = settlement ? frontendSettlementModel({ data: normalizeResponse({ content: settlement }) }) : undefined
  const settlementId = get(settlementsResponse, '_embedded.settlements.0.id')
  let settlementEntriesResponse
  let settlementEntry

  if (settlementId) {
    settlementEntriesResponse = yield getSettlementEntriesAPI({ credentials,
      id: settlementId,
      queries: { entry_id: id },
    })
    const firstSettlementEntry = get(settlementEntriesResponse, 'data._embedded.settlement_entries.0')
    settlementEntry = frontendSettlementEntryModel({ data: normalizeResponse({ content: firstSettlementEntry }) })
  }

  let parentTransferModel
  let parentTransferSettlementModel

  if (parentId) {
    const parentTransferPath = { path: TRANSFER({ transferId: parentId }), credentials }
    const { data: parentTransferResponse } = yield getPaymentsAPI(parentTransferPath)
    parentTransferModel = parentTransferResponse ? frontendTransferModel({ data: normalizeResponse({ content: parentTransferResponse }) }) : undefined

    const parentTransferSubtype = get(parentTransferResponse, 'subtype')

    const { data: parentSettlementsResponse } = yield getSettlementsAPI({
      credentials,
      queries: parentTransferSubtype === SETTLEMENT_MERCHANT ? { funding_transfer_id: parentId } : { transfer_id: parentId },
    })

    const parentTransferSettlement = get(parentSettlementsResponse, '_embedded.settlements.0')
    parentTransferSettlementModel = parentTransferSettlement ? frontendSettlementModel({ data: normalizeResponse({ content: parentTransferSettlement }) }) : undefined
  }

  let deviceResponseModel

  if (deviceId) {
    // TODO: device reducer?
    const { data: deviceResponse } = yield getPaymentsAPI({ path: DEVICE({ deviceId }), credentials })
    deviceResponseModel = deviceResponse ? frontendDeviceModel({ data: normalizeResponse({ content: deviceResponse }) }) : undefined
  }

  // get buyer identity from the payment instrument
  const buyerIdentityId = get(response, 'related.payment_instrument_details.identity')
  const { data: buyerIdentityResponse } = yield getPaymentsAPI({ path: IDENTITY({ identityId: buyerIdentityId }), credentials })
  const buyerIdentityResponseModel = frontendIdentityModel({ data: normalizeResponse({ content: buyerIdentityResponse }) })

  // receipts
  let receiptsResponseModel
  if (id) {
    const { data: receiptsResponse } = yield getReceiptsAPI({ credentials, queries: { entity_id: id } })
    const receipts = get(receiptsResponse, '_embedded.receipts')
    receiptsResponseModel = receipts ? frontendReceiptsModel({ data: normalizeResponse({ content: receipts }) }) : undefined
  }

  // fetch related payment link details
  const { data: purchases } = yield getPurchasesAPI({ credentials, queries: { transfer_id: id } })
  const paymentLinkId = get(purchases, '_embedded.purchases.[0].entity_id')
  let paymentLinkModel
  if (paymentLinkId) {
    const { data: paymentLink } = yield getPaymentLinkAPI({ credentials, id: paymentLinkId })
    paymentLinkModel = frontendPaymentLinkModel({ data: normalizeResponse({ content: paymentLink }) })
  }

  const normalizedTransfer = normalizeResponse({ content: response })
  // need to consider whether we want to store all these data points on the Transfer model itself or break them out into separate reducers
  // pros: makes fetching the data very simple as it is all in one place
  // pros: doesn't require fetching data from many different reducers to get all the info (we would have to make a buildTransfer util to simplify that)
  // cons: goes against redux design methodology og keeping separate data models int heir own reducers
  // cons: could have duplicate data stored in the reducer, which is generally something you do not want to do
  const transfer = merge({}, normalizedTransfer, {
    parentId,
    settlement: normalizedSettlement,
    settlementId,
    settlementEntry,
    parentTransfer: parentTransferModel,
    parentTransferSettlement: parentTransferSettlementModel,
    deviceId,
    device: deviceResponseModel,
    fees: feesModels,
    buyerIdentity: buyerIdentityResponseModel,
    receipts: receiptsResponseModel,
    paymentLink: paymentLinkModel,
  })

  return createSuccessResponse({
    data: [transfer],
  })
}

export default co.wrap(getTransferO)
