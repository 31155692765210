import { EMAIL_REGEX } from 'constants/regexConstants'
import trim from 'lodash/trim'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
} from 'utilities/validate'

import {
  EMAIL_ADDRESS,
  FIRST_NAME,
  LAST_NAME,
} from 'constants/language/languageConstants'

const validateAddNewBuyerIdentityForm = (values) => {
  const {
    firstName,
    lastName,
    email,
  } = values

  return {
    firstName: fieldEmpty(trim(firstName), FIRST_NAME),
    lastName: fieldEmpty(trim(lastName), LAST_NAME),
    email: fieldEmpty(trim(email), 'Email') || invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
  }
}

export default validateAddNewBuyerIdentityForm
