import './GroupedChartsS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ChartC from 'components/Charts/Chart/ChartC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import get from 'lodash/get'

const GroupedCharts = ({
  title,
  charts,
  chartsWithSubtitles,
  classNames = '',
  chartClassNames = '',
  timeGrainFilters = [],
  timeRange = {},
  tooltipMessage = '',
  tooltipPosition = 'right',
  skeletonLoadingHeight = '132px',
  showSkeletonLoading = false,
}) => {
  const initialActiveTimeGrainFilterIndex = findIndex(timeGrainFilters, ({ active }) => active === true)
  const [activeTimeGrainFilterIndex, setActiveTimeGrainFilterIndex] = useState(initialActiveTimeGrainFilterIndex >= 0 ? initialActiveTimeGrainFilterIndex : 0)
  const activeToggleFilter = get(timeGrainFilters, activeTimeGrainFilterIndex, {})
  const timeGrain = get(activeToggleFilter, 'value')

  const groupedChartsHeaderClassnames = classnames('grouped-charts-header flex space-between', {
    'not-charts-with-subtitles': !chartsWithSubtitles,
  })

  return (
    <div className={`GroupedCharts ${classNames}`}>
      <div className={groupedChartsHeaderClassnames}>
        <div className='flex'>
          { title && <h4>{title}</h4> }

          { tooltipMessage && (
            <TooltipLabelC
              position={tooltipPosition}
              message={tooltipMessage}
            />
          )}
        </div>

        <div className='filters'>
          { !isEmpty(timeGrainFilters) && (
            <div className='grouped-charts-filters flex'>
              { map(timeGrainFilters, ({ name }, toggleFilterIndex) => {
                return (
                  <div
                    key={kebabCase(`${title}-filter-${name}`)}
                    className={`grouped-charts-filter ${toggleFilterIndex === activeTimeGrainFilterIndex ? 'active' : ''}`}
                    onClick={() => setActiveTimeGrainFilterIndex(toggleFilterIndex)}
                    title={name}
                  >
                    {name}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      {charts && (
      <div className={`grouped-charts-content ${chartClassNames}`}>
        {map(charts, (chartData) => {
          return (
            <ChartC
              key={kebabCase(`${title}-chart-${get(chartData, 'name', 'unknown')}`)}
              timeGrain={timeGrain}
              timeRange={timeRange}
              skeletonLoadingHeight={skeletonLoadingHeight}
              showSkeletonLoading={showSkeletonLoading}
              {...chartData}
            />
          )
        })}
      </div>
      )}

      {chartsWithSubtitles && (
      <div className='charts-with-subtitles'>
        { chartsWithSubtitles && map(chartsWithSubtitles, ({ subtitle, subtitleTooltipMessage, charts: chartsData }) => {
          return (
            <div className='grouped-charts-with-subtitles'>
              <div className='flex'>
                { subtitle && <h6 className='subtitle'>{subtitle}</h6> }

                { subtitleTooltipMessage && (
                <TooltipLabelC
                  position={tooltipPosition}
                  message={subtitleTooltipMessage}
                />
                )}
              </div>

              <div className={`grouped-charts-content ${chartClassNames}`}>
                { chartsData && map(chartsData, (chartData) => {
                  return (
                    <ChartC
                      key={kebabCase(`${title}-chart-${get(chartData, 'name', 'unknown')}`)}
                      timeGrain={timeGrain}
                      timeRange={timeRange}
                      skeletonLoadingHeight={skeletonLoadingHeight}
                      showSkeletonLoading={showSkeletonLoading}
                      {...chartData}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      )}
    </div>
  )
}

GroupedCharts.propTypes = {
  title: PropTypes.string,
  charts: PropTypes.array,
  chartsWithSubtitles: PropTypes.array,
  classNames: PropTypes.string,
  chartClassNames: PropTypes.string,
  timeGrainFilters: PropTypes.array,
  timeRange: PropTypes.object,
  skeletonLoadingHeight: PropTypes.string,
  showSkeletonLoading: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

export default GroupedCharts
