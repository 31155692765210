import nextFlowStep from 'utilities/nextFlowStep'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_2 } from 'constants/amplitudeConstants'
import get from 'lodash/get'

import {
  PATCH_ACCESS_FORM_F_REQUEST,
  PATCH_GUEST_ACCESS_FORM_F_REQUEST,
} from 'constants/flowConstants'

const submitLiveAccountApplicationBusinessInfoForm = (values, dispatch, props = {}) => {
  const accessFormId = get(props, 'accessFormId')
  const dashboardType = get(props, 'dashboardType')
  const isHostedAccessForm = get(props, 'isHostedAccessForm', false)

  dispatch({
    type: isHostedAccessForm ? PATCH_GUEST_ACCESS_FORM_F_REQUEST : PATCH_ACCESS_FORM_F_REQUEST,
    payload: {
      id: accessFormId,
      values,
    },
    meta: {
      successCallback: nextFlowStep,
      actionId: sendAmplitudeActionEvent(SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_2, {
        accessFormId,
        dashboardType,
      }),
    },
  })
}

export default submitLiveAccountApplicationBusinessInfoForm
