import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import TableFiltersMenu from 'components/Shared/Table/TableFiltersV2/TableFiltersMenu'
import { getTableFiltersSelector } from 'state-layer/selectors'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import { CLEAR_TABLE_FILTERS } from 'constants/flowConstants'
import { TABLE_FILTERS_FORM } from 'constants/formConstants'
import { DATE_FILTERS } from 'constants/filterConstants'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'
import find from 'lodash/find'
import split from 'lodash/split'
import map from 'lodash/map'

// TODO: use string constants for operand and field names
const mapStateToProps = (state, props) => {
  const activeFilters = getTableFiltersSelector(state)
  const tableFiltersFormValues = get(state, `form.${TABLE_FILTERS_FORM}.values`)
  const allowedFilters = get(props, 'allowedFilters', [])

  const initialValues = reduce(activeFilters, (total, fieldValues, fieldName) => {
    const filterData = find(allowedFilters, { field: fieldName })
    const isMultiSelectFilter = get(filterData, 'isMultiSelectFilter')
    const multiSelectFilterOptions = get(filterData, 'options', [])

    const operand = head(Object.keys(fieldValues))
    const value = get(fieldValues, operand)

    // if multi select filter, need to pass in values as objects for react select component
    if (isMultiSelectFilter) {
      const arrayOfValues = split(value, ',')
      const valueToPass = map(arrayOfValues, (val) => {
        return find(multiSelectFilterOptions, { value: val })
      })
      return merge({}, total, { [fieldName]: { value: valueToPass, operand } })
    }

    if (!get(DATE_FILTERS, fieldName)) return merge({}, total, { [fieldName]: { value, operand } })

    // this code handles setting created at (on/after/before/between) filters based on the active filters
    const gteValue = get(fieldValues, 'gte')
    const lteValue = get(fieldValues, 'lte')
    const isBetween = gteValue && lteValue && gteValue !== lteValue

    if (isBetween) {
      return merge({}, total, {
        [fieldName]: {
          gte: {
            value: moment(gteValue),
            operand: 'gte',
          },
          lte: {
            value: moment(lteValue),
            operand: 'lte',
          },
          operand: 'between',
        },
      })
    }

    let operandValue

    if (gteValue === lteValue) {
      operandValue = 'on'
    } else {
      operandValue = gteValue ? 'gte' : 'lte'
    }

    return merge({}, total, {
      [fieldName]: {
        value: moment(value),
        operand: operandValue,
      },
    })
  }, {})

  return {
    activeFilters,
    initialValues,
    tableFiltersFormValues,
  }
}

class TableFiltersMenuC extends Component {
  constructor (props) {
    super(props)

    const {
      activeFilters,
      allowedFilters,
    } = props

    const checkedFilters = reduce(allowedFilters, (total, { field }) => {
      return merge({}, total, {
        [field]: !isEmpty(get(activeFilters, field)),
      })
    }, {})

    this.state = {
      checkedFilters,
    }
  }

  toggleCheckedFilter = ({ event, objectId: field }) => {
    const { checkedFilters } = this.state

    if (allowedKeyPressControls(event)) {
      const currentChecked = get(checkedFilters, field, false)
      const updatedCheckedFilters = merge({}, checkedFilters, { [field]: !currentChecked })

      this.setState({ checkedFilters: updatedCheckedFilters })
    }
  }

  clearFilters = () => {
    const {
      dispatch,
      activeFilters,
      allowedFilters,
    } = this.props

    forEach(activeFilters, (operands, field) => {
      dispatch(change(TABLE_FILTERS_FORM, `${field}.value`, ''))

      if (field === 'created_at') {
        dispatch(change(TABLE_FILTERS_FORM, `${field}.gte.value`, ''))
        dispatch(change(TABLE_FILTERS_FORM, `${field}.lte.value`, ''))
      }
    })

    dispatch({
      type: CLEAR_TABLE_FILTERS,
    })

    const checkedFilters = reduce(allowedFilters, (total, { field }) => merge({}, total, { [field]: false }), {})

    this.setState({ checkedFilters })
  }

  render() {
    const {
      checkedFilters,
    } = this.state

    return (
      <TableFiltersMenu
        checkedFilters={checkedFilters}
        toggleCheckedFilter={this.toggleCheckedFilter}
        clearFilters={this.clearFilters}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(TableFiltersMenuC)
