import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_NOTIFICATION_ACKNOWLEDGMENT } from 'constants/apiConstants'

const postNotificationAcknowledgmentAPI = ({ credentials, values }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_NOTIFICATION_ACKNOWLEDGMENT,
    values,
    credentials,
  })
}

export default postNotificationAcknowledgmentAPI
