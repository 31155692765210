import './CreateNewOnboardingFormModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import CreateOnboardingFormBasicsFormC from 'components/Customer/Forms/CreateOnboardingFormBasicsForm/CreateOnboardingFormBasicsFormC'
import CreateOnboardingFormDetailsFormC from 'components/Customer/Forms/CreateOnboardingFormDetailsForm/CreateOnboardingFormDetailsFormC'
import GenericModalC from 'components/Customer/Modals/GenericModal/GenericModalC'

import {
  CREATE_ONBOARDING_FORM_DETAILS_DATA_KEY,
  CREATE_ONBOARDING_FORM_BASICS_DATA_KEY,
} from 'constants/flowsConstants'

import {
  CONTINUE,
  FORM_INFO,
  GENERATE_FORM,
  ONBOARDING_FORM_MODAL_SUBTITLE,
  ONBOARDING_FORM_MODAL_TITLE,
  PROCESSING_INFO,
} from 'constants/language/languageConstants'

const steps = [
  {
    stepTitle: FORM_INFO,
    component: CreateOnboardingFormBasicsFormC,
    props: {
      submitLabel: CONTINUE,
    },
    dataKey: CREATE_ONBOARDING_FORM_BASICS_DATA_KEY,
  },
  {
    stepTitle: PROCESSING_INFO,
    component: CreateOnboardingFormDetailsFormC,
    props: {
      submitLabel: GENERATE_FORM,
      leftButton: true,
    },
    dataKey: CREATE_ONBOARDING_FORM_DETAILS_DATA_KEY,
  },
]

const CreateNewOnboardingFormModal = ({
  credentials,
}) => {
  return (
    <GenericModalC
      title={ONBOARDING_FORM_MODAL_TITLE}
      subTitle={ONBOARDING_FORM_MODAL_SUBTITLE}
      className='CreateNewOnboardingFormModal'
      steps={steps}
      credentials={credentials}
      multiSteps
    />
  )
}

CreateNewOnboardingFormModal.propTypes = {
  credentials: PropTypes.object,
}

export default CreateNewOnboardingFormModal
