import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_USER_MEMBERSHIP_V2 } from 'constants/apiConstants'

const patchMembershipAPI = ({
  id,
  values,
  meta,
}) => {
  const dashboardId = getCurrentDashboardId()

  return dashboardAPI.patch({
    path: DASHBOARD_SERVICE_USER_MEMBERSHIP_V2({
      id,
      dashboardId,
    }),
    values,
    meta,
  })
}

export default patchMembershipAPI
