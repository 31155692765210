import React from 'react'
import { normalizeTimestamp } from 'constants/normalizationConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import head from 'lodash/head'
import isInteger from 'lodash/isInteger'

const defaultFormatters = {
  createdAt: normalizeTimestamp,
  updatedAt: normalizeTimestamp,
}

const createTableItems = ({
  data,
  columnDescriptors,
  extraProps = {},
  idKey,
  credentials,
  relatedItemsKey,
  selectedItemActions,
}) => {
  const role = get(credentials, 'role')
  const platform = getCurrentPlatform()

  return map(data, (item) => {
    const itemIDKey = idKey || 'id'
    const itemID = get(item, itemIDKey)
    const itemData = merge({}, get(item, 'attributes', item), { credentials })
    const relatedItems = get(item, relatedItemsKey)

    const relatedItemsAttributes = !isEmpty(relatedItems) ? createTableItems({
      data: relatedItems,
      columnDescriptors,
      extraProps,
      idKey,
    }) : {}

    const attributes = map(columnDescriptors, (columnDescriptor) => {
      const {
        getKeys = ['id'],
        className = 'medium',
        formatter,
        condition = true,
        title = '',
        highlight = false,
        actionIndex,
        actionLabel,
      } = columnDescriptor

      const defaultKey = head(getKeys)
      const attributeKeyID = map(getKeys, (getKey) => getKey.replace('.', '-')).join('-')
      const attributeID = `${itemID}-${attributeKeyID}`

      const defaultDisplayValue = getKeys.length > 1
        ? map(getKeys, (getKey) => get(itemData, getKey)).join('-')
        : get(itemData, getKeys[0])

      const normalizedDisplayValue = isObject(defaultDisplayValue)
        ? JSON.stringify(defaultDisplayValue)
        : defaultDisplayValue

      const defaultNormalizeDisplayValue = defaultFormatters[defaultKey]
        ? defaultFormatters[defaultKey](normalizedDisplayValue)
        : normalizedDisplayValue

      let displayValue = formatter
        ? formatter(itemData, extraProps)
        : defaultNormalizeDisplayValue

      const actionIndexValue = isFunction(actionIndex) ? actionIndex(itemData) : actionIndex

      if (isInteger(actionIndexValue) && actionIndexValue >= 0) {
        const actionLabelValue = isFunction(actionLabel) ? actionLabel(itemData) : actionLabel
        displayValue = <div className='flex flex-end selected-item-action' onClick={() => selectedItemActions[actionIndexValue](itemData)}>{actionLabelValue}</div>
      }

      const showColumn = isFunction(condition) ? condition({ platform, credentials, role }) : condition

      return {
        id: attributeID,
        key: defaultKey,
        className,
        value: defaultNormalizeDisplayValue,
        displayValue,
        showColumn,
        title,
        highlight,
        actionLabel,
      }
    })

    return {
      id: itemID,
      attributes,
      data: item,
      relatedItems: relatedItemsAttributes,
    }
  })
}

export default createTableItems
