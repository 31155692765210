import {
  fieldEmpty,
  validateTruthyValue,
} from 'utilities/validate'

const validateAttestComplianceForm = (values) => {
  const {
    merchantExecutiveName,
    title,
    attestComplianceAgreementAccepted,
  } = values

  return {
    merchantExecutiveName: fieldEmpty(merchantExecutiveName, 'Merchant executive name'),
    title: fieldEmpty(title, 'Title'),
    attestComplianceAgreementAccepted: validateTruthyValue({ name: 'Terms of Service', field: attestComplianceAgreementAccepted, customErrorMessage: 'Terms of Service must be accepted.' }),
  }
}

export default validateAttestComplianceForm
