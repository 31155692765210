import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { DONE } from 'constants/language/languageConstants'

const PayoutLimitsReached = ({
  redirectUserToHomePage = () => {},
  redirectUserToCompanyPage = () => {},
}) => {
  return (
    <div className='PayoutLimitsReached'>
      <div className='modal-content'>
        <div>You have already reached your payout volume limit for both cards and bank accounts. You cannot send any more money at this time.</div>
        <div><a className='text-link' onClick={redirectUserToCompanyPage}>View your velocity limits</a> to learn more.</div>
      </div>
      <div className='buttons flex flex-end'>
        <Button label={DONE} onClick={redirectUserToHomePage} />
      </div>
    </div>
  )
}

PayoutLimitsReached.propTypes = {
  redirectUserToHomePage: PropTypes.func,
  redirectUserToCompanyPage: PropTypes.func,
}

export default PayoutLimitsReached
