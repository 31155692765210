import co from 'co'
import postIdentityAPI from 'api/finix/post/postIdentityAPI'
import postApplicationIdentityAPI from 'api/finix/post/postApplicationIdentityAPI'
import postApplicationAssociatedIdentityAPI from 'api/finix/post/postApplicationAssociatedIdentityAPI'
import postPaymentInstrumentAPI from 'api/finix/post/postPaymentInstrumentAPI'
import patchIdentityAPI from 'api/finix/patch/patchIdentityAPI'
import { BANK_ACCOUNT } from 'constants/bankConstants'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import backendIdentityModel from 'utilities/create/models/backendIdentityModel'
import backendBankAccountModel from 'utilities/create/models/backendBankAccountModel'
import getMany from 'utilities/get/getMany'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import every from 'lodash/every'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  createMerchantIdentity,
  createAssociatedIdentity,
  createBankAccount,
} from 'constants/orchestrationConstants'

/* ============ Post Merchant Identity Flow ==========
1) postApplicationIdentity => identityId
2) postAssociatedIdentity [1. identityId]
3) postPaymentInstrumentAPI [1. identityId]
*/

function * postMerchantIdentityO ({
  values,
  credentials,
  id,
}) {
  const [
    merchantIdentity,
    associatedIdentities,
    bankAccount,
  ] = getMany(values, [
    'merchantIdentity',
    'associatedIdentities',
    'bankAccount',
  ])

  const isRolePartnerCredential = isRolePartner({ credentials })
  const applicationId = get(merchantIdentity, 'selectedApplicationId')
  const backendMerchantIdentityModel = backendIdentityModel({ values: merchantIdentity })
  const backendMerchantIdentity = normalizeResponse({ content: backendMerchantIdentityModel, normalizationMap: backendNormalizationMap })
  const backendMerchantIdentityValues = omit(backendMerchantIdentity, 'additional_underwriting_data')

  const response = isRolePartnerCredential ?
    yield postIdentityAPI({ values: backendMerchantIdentityValues, credentials }) :
    yield postApplicationIdentityAPI({ values: backendMerchantIdentityValues, credentials, id: applicationId ? applicationId : id })

  const { data: merchantIdentityResponse } = response
  const merchantIdentityErrors = get(response, 'error.response.data._embedded.errors')

  const identityId = get(merchantIdentityResponse, 'id')
  const additionalUnderwritingData = get(backendMerchantIdentity, 'additional_underwriting_data')

  const backendMerchantIdentityAdditionalUnderwritingDataValues = {
    additional_underwriting_data: additionalUnderwritingData,
    id: identityId,
  }

  // post application identity does not accept additional underwriting data
  // so this data is patched once the identity is created instead
  if (additionalUnderwritingData) {
    yield patchIdentityAPI({ values: backendMerchantIdentityAdditionalUnderwritingDataValues, credentials })
  }

  const associatedIdentityResponse = !isEmpty(associatedIdentities) && identityId ?
    yield map(associatedIdentities, (associatedIdentity) => {
      const associatedIdentityModel = backendIdentityModel({ values: associatedIdentity, associatedIdentity })
      const associatedIdentityValues = normalizeResponse({ content: associatedIdentityModel, normalizationMap: backendNormalizationMap })

      return postApplicationAssociatedIdentityAPI({
        values: associatedIdentityValues,
        credentials,
        id: identityId,
      })
    }) : []

  const bankAccountPayload = merge({}, backendBankAccountModel({ values: bankAccount }), {
    identity: identityId,
    type: BANK_ACCOUNT,
  })

  const { data: bankAccountResponseData } = identityId ? yield postPaymentInstrumentAPI({
    values: bankAccountPayload,
    credentials,
  }) : {}

  const orchestrationStatus = {
    [createMerchantIdentity]: !!identityId,
    [createBankAccount]: !!bankAccountResponseData,
    [createAssociatedIdentity]: isEmpty(associatedIdentities) || every(associatedIdentityResponse, ({ data }) => !isEmpty(data)),
    errors: merchantIdentityErrors,
  }

  if (merchantIdentityResponse && associatedIdentityResponse && bankAccountResponseData) {
    return createSuccessResponse({
      data: {
        identities: merchantIdentityResponse,
        orchestrationStatus,
      },
    })
  }

  return {
    error: {
      orchestrationStatus,
    },
  }
}

export default co.wrap(postMerchantIdentityO)
