import './ErrorMessageS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { TIMES_OCTAGON } from 'constants/iconConstants'

const ErrorMessage = ({
  errorMessage = '',
  icon = TIMES_OCTAGON,
}) => {
  return errorMessage && (
    <div className='ErrorMessage flex'>
      <i className={`icon fa fa-${icon}`} />
      <div className='error-message-text'>{errorMessage}</div>
    </div>
  )
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  icon: PropTypes.string,
}

export default ErrorMessage
