import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import getBalanceEntryAPI from 'api/finix/get/getBalanceEntryAPI'
import getSettlementAPI from 'api/finix/get/getSettlementAPI'
import getTransferAPI from 'api/finix/get/getTransferAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getFeeAPI from 'api/finix/get/getFeeAPI'
import getComplianceFormTemplateAPI from 'api/dashboard/get/getComplianceFormTemplateAPI'
import getVerificationAPI from 'api/finix/get/getVerificationAPI'
import getOnboardingFormDataAPI from 'api/finix/get/getOnboardingFormDataAPI'
import getRoleAPI from 'api/dashboard/get/getRoleAPI'
import getSplitTransferAPI from 'api/finix/get/getSplitTransferAPI'
import getFWSTransferAPI from 'api/dashboard/get/getTransferAPI'
import getDevicesAPI from 'api/finix/get/getDevicesAPI'
import getSubscriptionPlanAPI from 'api/finix/get/getSubscriptionPlanAPI'
import getPayoutLinkAPI from 'api/finix/get/getPayoutLinkAPI'

import getTransfersRelatedFeesO from 'state-layer/orchestration/get/getTransfersRelatedFeesO'
import getTransferFeesO from 'state-layer/orchestration/get/getTransferFeesO'
import getSettlementEntriesO from 'state-layer/orchestration/get/getSettlementEntriesO'
import getTransferFeesSettlementEntriesO from 'state-layer/orchestration/get/getTransferFeesSettlementEntriesO'
import getSubscriptionScheduleAmountsO from 'state-layer/orchestration/get/getSubscriptionScheduleAmountsO'
import getIdentityO from 'state-layer/orchestration/get/getIdentityO'
import getRecipientIdentityO from 'state-layer/orchestration/get/getRecipientIdentityO'
import getUnderwritingIdentityFilesO from 'state-layer/orchestration/get/getUnderwritingIdentityFilesO'
import getUnderwritingIdentityMerchantsO from 'state-layer/orchestration/get/getUnderwritingIdentityMerchantsO'

import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import frontendBalanceEntryModel from 'utilities/create/models/frontendBalanceEntryModel'
import frontendSettlementModel from 'utilities/create/models/frontendSettlementModel'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendFeeModel from 'utilities/create/models/frontendFeeModel'
import frontendSettlementEntriesModel from 'utilities/create/models/frontendSettlementEntriesModel'
import frontendSubscriptionScheduleModel from 'utilities/create/models/frontendSubscriptionScheduleModel'
import frontendComplianceFormTemplateModel from 'utilities/create/models/frontendComplianceFormTemplateModel'
import frontendVerificationModel from 'utilities/create/models/frontendVerificationModel'
import frontendOnboardingFormDataModel from 'utilities/create/models/frontendOnboardingFormDataModel'
import frontendRoleModel from 'utilities/create/models/frontendRoleModel'
import frontendSplitTransferModel from 'utilities/create/models/frontendSplitTransferModel'
import frontendSubscriptionPlanModel from 'utilities/create/models/frontendSubscriptionPlanModel'
import frontendPayoutLinkModel from 'utilities/create/models/frontendPayoutLinkModel'

import {
  GET_REVIEW_QUEUE_F_SUCCESS,
  GET_REVIEW_QUEUE_SETTLEMENTS,
  GET_REVIEW_QUEUE_MERCHANTS,
  GET_REVIEW_QUEUE_MERCHANTS_IDENTITY_F_SUCCESS,
  GET_REVIEW_QUEUE_MERCHANTS_F_SUCCESS,
  GET_REVIEW_QUEUE_IDENTITIES,
  GET_IDENTITIES_F_SUCCESS,
  GET_DISPUTES_F_SUCCESS,
  GET_DISPUTE_TRANSFER_F_SUCCESS,
  GET_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS,
  GET_TRANSFERS_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_TRANSFER_APPLICATION_F_SUCCESS,
  GET_AUTHORIZATIONS_F_SUCCESS,
  GET_AUTHORIZATIONS_MERCHANT_IDENTITY_F_SUCCESS,
  GET_AUTHORIZATIONS_APPLICATION_F_SUCCESS,
  GET_TRANSFERS_F_SUCCESS,
  GET_MERCHANTS_F_SUCCESS,
  GET_MERCHANT_IDENTITY_F_SUCCESS,
  GET_REVIEW_QUEUE_APPLICATION_F_SUCCESS,
  GET_REVIEW_QUEUE_SETTLEMENTS_F_SUCCESS,
  GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT_F_SUCCESS,
  GET_SETTLEMENTS_F_SUCCESS,
  GET_SETTLEMENT_GROUP_SETTLEMENTS_F_SUCCESS,
  GET_SETTLEMENTS_MERCHANT_F_SUCCESS,
  GET_IDENTITY_AUTHORIZATIONS_F_SUCCESS,
  GET_APPLICATION_SETTLEMENTS_F_SUCCESS,
  GET_IDENTITY_TRANSFERS_F_SUCCESS,
  GET_IDENTITY_SETTLEMENTS_F_SUCCESS,
  GET_SETTLEMENTS_IDENTITY_F_SUCCESS,
  GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS_F_SUCCESS,
  GET_PAYMENT_INSTRUMENT_TRANSFERS_F_SUCCESS,
  GET_APPLICATION_MERCHANTS_F_SUCCESS,
  GET_APPLICATION_TRANSFERS_F_SUCCESS,
  GET_APPLICATION_DISPUTES_F_SUCCESS,
  GET_IDENTITY_DISPUTES_F_SUCCESS,
  GET_SETTLEMENT_TRANSFERS_F_SUCCESS,
  GET_SETTLEMENT_FUNDING_TRANSFERS_F_SUCCESS,
  GET_FUNDING_TRANSFERS_RELATED_TRANSFER_F_SUCCESS,
  GET_SETTLEMENT_TRANSFER_FEES_F_SUCCESS,
  GET_SETTLEMENT_TRANSFERS_RELATED_TRANSFER_F_SUCCESS,
  GET_SETTLEMENT_TRANSFER_FEE_COUNTS_F_SUCCESS,
  GET_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_PAYMENT_INSTRUMENT_APPLICATION_F_SUCCESS,
  GET_MERCHANT_TRANSFERS_F_SUCCESS,
  GET_MERCHANT_AUTHORIZATIONS_F_SUCCESS,
  GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_SETTLEMENT_ENTRIES_F_SUCCESS,
  GET_SETTLEMENT_ENTRIES_FEE_F_SUCCESS,
  GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS,
  GET_TRANSFER_FEES_SETTLEMENT_ENTRIES_F_SUCCESS,
  GET_FEES_F_SUCCESS,
  GET_FEES_MERCHANT_F_SUCCESS,
  GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS,
  GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS_F_SUCCESS,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_SUCCESS,
  GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT_F_SUCCESS,
  GET_APPLICATIONS_F_SUCCESS,
  GET_APPLICATIONS_IDENTITY_F_SUCCESS,
  GET_APPLICATION_SETTLEMENTS_IDENTITY_F_SUCCESS,
  GET_MERCHANT_SETTLEMENTS_F_SUCCESS,
  GET_COMPLIANCE_FORM_F_SUCCESS,
  GET_COMPLIANCE_FORMS_F_SUCCESS,
  GET_MERCHANT_COMPLIANCE_FORMS_F_SUCCESS,
  GET_COMPLIANCE_FORM_APPLICATION_F_SUCCESS,
  GET_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS,
  GET_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS,
  GET_SETTLEMENTS_APPLICATIONS_F_SUCCESS,
  GET_DISPUTE_APPLICATION_F_SUCCESS,
  GET_COMPLIANCE_FORM_TEMPLATES_F_SUCCESS,
  GET_COMPLIANCE_FORMS_APPLICATION_F_SUCCESS,
  GET_MERCHANT_APPLICATION_F_SUCCESS,
  GET_MERCHANT_VERIFICATION_F_SUCCESS,
  GET_ONBOARDING_FORMS_F_SUCCESS,
  GET_ONBOARDING_FORMS_APPLICATION_F_SUCCESS,
  GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA_F_SUCCESS,
  GET_ONBOARDING_FORM_DATA_F_SUCCESS,
  GET_COMPLIANCE_FORM_TEMPLATE_FORMS_F_SUCCESS,
  GET_ROLE_F_SUCCESS,
  GET_RELATED_APPLICATION_F_SUCCESS,
  GET_APPLICATION_F_REQUEST,
  GET_APPLICATION_F_SUCCESS,
  GET_ROLE_APPLICATION_F_SUCCESS,
  GET_MEMBER_F_SUCCESS,
  GET_MEMBER_ROLE_F_SUCCESS,
  GET_MERCHANT_UNDERWRITING_REVIEWS_F_SUCCESS,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
  GET_RECIPIENT_IDENTITY_MERCHANTS_F_SUCCESS,
  GET_TRANSFER_REVERSALS_F_SUCCESS,
  GET_TRANSFER_DISPUTES_F_SUCCESS,
  GET_TRANSFER_REVERSALS_MERCHANT_IDENTITY_F_SUCCESS,
  GET_TRANSFER_REVERSALS_APPLICATION_F_SUCCESS,
  GET_TRANSFER_DISPUTES_MERCHANT_IDENTITY_F_SUCCESS,
  GET_TRANSFER_DISPUTES_APPLICATION_F_SUCCESS,
  GET_SPLIT_TRANSFERS_F_SUCCESS,
  GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS,
  GET_SPLIT_TRANSFERS_APPLICATION_F_SUCCESS,
  GET_SPLIT_TRANSFERS_MERCHANT_F_SUCCESS,
  GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS_F_SUCCESS,
  GET_RECIPIENT_UNDERWRITING_REVIEWS_F_SUCCESS,
  GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
  GET_RECIPIENT_IDENTITIES_F_SUCCESS,
  GET_BALANCE_ENTRIES_F_SUCCESS,
  GET_BALANCE_ENTRIES_TRANSFER_F_SUCCESS,
  GET_BALANCE_ENTRIES_APPLICATION_F_SUCCESS,
  GET_PAYMENT_LINK_F_SUCCESS,
  GET_PAYMENT_LINKS_F_SUCCESS,
  GET_PAYMENT_LINKS_MERCHANT_F_SUCCESS,
  GET_PAYMENT_LINKS_APPLICATION_F_SUCCESS,
  GET_PURCHASES_F_SUCCESS,
  GET_PURCHASES_TRANSFER_F_SUCCESS,
  GET_PURCHASE_F_SUCCESS,
  GET_PURCHASE_APPLICATION_F_SUCCESS,
  GET_PURCHASE_MERCHANT_F_SUCCESS,
  GET_PURCHASE_MERCHANT_IDENTITY_F_SUCCESS,
  GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_TRANSFER_F_SUCCESS,
  GET_TRANSFER_MERCHANT_F_SUCCESS,
  GET_DEVICES_F_SUCCESS,
  GET_DEVICE_MERCHANT_F_SUCCESS,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS_F_SUCCESS,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES_F_SUCCESS,
  GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS_F_SUCCESS,
  GET_BALANCE_ADJUSTMENTS_F_SUCCESS,
  GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_MERCHANT_TRANSFERS_APPLICATION_F_SUCCESS,
  GET_SUBSCRIPTIONS_F_SUCCESS,
  GET_SUBSCRIPTION_F_SUCCESS,
  GET_SUBSCRIPTIONS_IDENTITY_F_SUCCESS,
  GET_SUBSCRIPTIONS_MERCHANT_F_SUCCESS,
  GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_SUBSCRIPTION_PLANS_F_SUCCESS,
  GET_SUBSCRIPTION_PLANS_MERCHANT_F_SUCCESS,
  GET_SUBSCRIPTION_PLAN_F_SUCCESS,
  GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY_F_SUCCESS,
  GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION_F_SUCCESS,
  GET_SUBSCRIPTIONS_PLAN_F_SUCCESS,
  GET_TRANSFER_ATTEMPT_F_SUCCESS,
  GET_TRANSFER_ATTEMPT_PAYOUT_LINK_F_SUCCESS,
  GET_INSTANT_PAYOUT_MERCHANT_F_SUCCESS,
  GET_INSTANT_PAYOUTS_F_SUCCESS,
  GET_INSTANT_PAYOUT_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_BALANCE_ADJUSTMENT_F_SUCCESS,
  GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY_F_SUCCESS,
  GET_BALANCE_ENTRY_F_SUCCESS,
  GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY_F_SUCCESS,
  GET_RELATED_PAYMENT_INSTRUMENT_F_SUCCESS,
  GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS, GET_TRANSFER_ATTEMPTS_F_SUCCESS,
} from 'constants/flowConstants'

export default [
  {
    name: 'getComplianceFormTemplatesApplication',
    idKey: 'linkedTo',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    resourceSuccessF: GET_COMPLIANCE_FORM_TEMPLATES_F_SUCCESS,
    relatedResourceSuccessF: GET_COMPLIANCE_FORMS_APPLICATION_F_SUCCESS,
    condition: ({ relatedResourceId = '' }) => relatedResourceId.slice(0, 2) === 'AP', // TODO: replace with resource ID check util
  },
  {
    name: 'getComplianceFormTemplate',
    idKey: 'complianceFormTemplateId',
    api: getComplianceFormTemplateAPI,
    frontendModel: frontendComplianceFormTemplateModel,
    resourceSuccessF: GET_COMPLIANCE_FORM_F_SUCCESS,
    relatedResourceSuccessF: GET_COMPLIANCE_FORM_TEMPLATE_F_SUCCESS,
  },
  {
    name: 'getComplianceFormApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    resourceSuccessF: [
      GET_COMPLIANCE_FORM_F_SUCCESS,
      GET_COMPLIANCE_FORMS_F_SUCCESS,
      GET_MERCHANT_COMPLIANCE_FORMS_F_SUCCESS,
      GET_COMPLIANCE_FORM_TEMPLATE_FORMS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_COMPLIANCE_FORM_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSubscriptionScheduleEnrollmentsMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    resourceSuccessF: GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_F_SUCCESS,
    relatedResourceSuccessF: GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getSubscriptionEnrollmentsScheduleAmounts',
    idKey: 'subscriptionScheduleId',
    api: getSubscriptionScheduleAmountsO,
    frontendModel: frontendSubscriptionScheduleModel,
    resourceSuccessF: GET_MERCHANT_SUBSCRIPTION_ENROLLMENTS_F_SUCCESS,
    relatedResourceSuccessF: GET_SUBSCRIPTION_ENROLLMENTS_SCHEDULE_AMOUNTS_F_SUCCESS,
  },
  {
    name: 'getReviewQueueApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: GET_REVIEW_QUEUE_F_SUCCESS,
    relatedResourceSuccessF: GET_REVIEW_QUEUE_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getReviewQueueSettlements',
    idKey: 'entityId',
    api: getSettlementAPI,
    frontendModel: frontendSettlementModel,
    reducer: 'settlementsR',
    resourceSuccessF: GET_REVIEW_QUEUE_SETTLEMENTS,
    relatedResourceSuccessF: GET_REVIEW_QUEUE_SETTLEMENTS_F_SUCCESS,
  },
  {
    name: 'getReviewQueueSettlementsMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: GET_REVIEW_QUEUE_SETTLEMENTS_F_SUCCESS,
    relatedResourceSuccessF: GET_REVIEW_QUEUE_SETTLEMENTS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getReviewQueueMerchants',
    idKey: 'entityId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: GET_REVIEW_QUEUE_MERCHANTS,
    relatedResourceSuccessF: GET_REVIEW_QUEUE_MERCHANTS_F_SUCCESS,
  },
  {
    name: 'getReviewQueueMerchantsIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: GET_REVIEW_QUEUE_MERCHANTS_F_SUCCESS,
    relatedResourceSuccessF: GET_REVIEW_QUEUE_MERCHANTS_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getReviewQueueIdentities',
    idKey: 'entityId',
    api: getIdentityO,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: GET_REVIEW_QUEUE_IDENTITIES,
    relatedResourceSuccessF: GET_IDENTITIES_F_SUCCESS,
  },
  {
    name: 'getDisputeTransfer',
    idKey: 'transferId',
    api: getTransferAPI,
    frontendModel: frontendTransferModel,
    reducer: 'transfersR',
    resourceSuccessF: [
      GET_DISPUTES_F_SUCCESS,
      GET_APPLICATION_DISPUTES_F_SUCCESS,
      GET_IDENTITY_DISPUTES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_DISPUTE_TRANSFER_F_SUCCESS,
  },
  {
    name: 'getDisputeApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_DISPUTES_F_SUCCESS,
      GET_IDENTITY_DISPUTES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_DISPUTE_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getDeviceMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_DEVICES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_DEVICE_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getTransfersMerchantIdentity',
    idKey: 'merchantIdentityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_DISPUTE_TRANSFER_F_SUCCESS,
      GET_PAYMENT_INSTRUMENT_TRANSFERS_F_SUCCESS,
      GET_APPLICATION_TRANSFERS_F_SUCCESS,
      GET_SETTLEMENT_TRANSFERS_F_SUCCESS,
      GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS,
      GET_MERCHANT_TRANSFERS_F_SUCCESS,
      GET_PURCHASES_TRANSFER_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getSplitTransfersMerchantIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_SPLIT_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SPLIT_TRANSFERS_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getSplitTransfersApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_SPLIT_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SPLIT_TRANSFERS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSplitTransfersMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_SPLIT_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SPLIT_TRANSFERS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getTransferReversalsMerchantIdentity',
    idKey: 'merchantIdentityId',
    reducer: 'identitiesR',
    frontendModel: frontendIdentityModel,
    api: getIdentityAPI,
    resourceSuccessF: [
      GET_TRANSFER_REVERSALS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_REVERSALS_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getTransferReversalsApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_TRANSFER_REVERSALS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_REVERSALS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getTransferDisputesMerchantIdentity',
    idKey: 'merchantIdentityId',
    reducer: 'identitiesR',
    frontendModel: frontendIdentityModel,
    api: getIdentityAPI,
    resourceSuccessF: [
      GET_TRANSFER_DISPUTES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_DISPUTES_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getTransferDisputesApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_TRANSFER_DISPUTES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_DISPUTES_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSettlementEntriesTransfer',
    idKey: 'transferId',
    api: getFWSTransferAPI,
    frontendModel: frontendTransferModel,
    resourceSuccessF: [
      GET_SETTLEMENT_ENTRIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS,
  },
  {
    name: 'getSettlementEntriesSplitTransfers',
    idKey: 'entryId',
    api: getSplitTransferAPI,
    frontendModel: frontendSplitTransferModel,
    resourceSuccessF: [
      GET_SETTLEMENT_ENTRIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENT_ENTRIES_SPLIT_TRANSFERS_F_SUCCESS,
  },
  {
    name: 'getSettlementEntriesFee',
    idKey: 'entryId',
    api: getFeeAPI,
    frontendModel: frontendFeeModel,
    resourceSuccessF: [
      GET_SETTLEMENT_ENTRIES_F_SUCCESS,
      GET_TRANSFER_FEES_SETTLEMENT_ENTRIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENT_ENTRIES_FEE_F_SUCCESS,
  },
  {
    name: 'getFundingTransfersRelatedTransfer', // for v2 settlement/transfers tab
    idKey: 'id',
    api: getTransferAPI,
    frontendModel: frontendTransferModel,
    reducer: 'transfersR',
    resourceSuccessF: GET_SETTLEMENT_FUNDING_TRANSFERS_F_SUCCESS,
    relatedResourceSuccessF: GET_FUNDING_TRANSFERS_RELATED_TRANSFER_F_SUCCESS,
  },
  {
    name: 'getTransfersPaymentInstrument',
    idKey: 'paymentInstrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_DISPUTE_TRANSFER_F_SUCCESS,
      GET_APPLICATION_TRANSFERS_F_SUCCESS,
      GET_IDENTITY_TRANSFERS_F_SUCCESS,
      GET_MERCHANT_TRANSFERS_F_SUCCESS,
      GET_SETTLEMENT_TRANSFERS_F_SUCCESS,
      GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS,
      GET_BALANCE_ENTRIES_TRANSFER_F_SUCCESS,
      GET_TRANSFER_ATTEMPTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFERS_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getSettlementTransferFees',
    idKey: 'id',
    api: getTransferFeesO,
    orchestration: getTransfersRelatedFeesO,
    frontendModel: frontendTransferModel,
    resourceSuccessF: [
      GET_SETTLEMENT_TRANSFERS_F_SUCCESS,
      GET_SETTLEMENT_ENTRIES_TRANSFER_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENT_TRANSFER_FEES_F_SUCCESS,
  },
  {
    name: 'getTransferFeesSettlementEntries',
    api: getSettlementEntriesO,
    orchestration: getTransferFeesSettlementEntriesO,
    frontendModel: frontendSettlementEntriesModel,
    resourceSuccessF: [
      GET_SETTLEMENT_TRANSFER_FEES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_FEES_SETTLEMENT_ENTRIES_F_SUCCESS,
  },
  {
    name: 'getAuthorizationsMerchantIdentity',
    idKey: 'merchantIdentityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_AUTHORIZATIONS_F_SUCCESS,
      GET_MERCHANT_AUTHORIZATIONS_F_SUCCESS,
      GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_AUTHORIZATIONS_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getAuthorizationsPaymentInstrument',
    idKey: 'paymentInstrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_AUTHORIZATIONS_F_SUCCESS,
      GET_IDENTITY_AUTHORIZATIONS_F_SUCCESS,
      GET_MERCHANT_AUTHORIZATIONS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getAuthorizationsApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_AUTHORIZATIONS_F_SUCCESS,
      GET_MERCHANT_AUTHORIZATIONS_F_SUCCESS,
      GET_PAYMENT_INSTRUMENT_AUTHORIZATIONS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_AUTHORIZATIONS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getTransferApplicationIdentity',
    idKey: 'application',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_IDENTITY_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSettlementsMerchantIdentity',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_SETTLEMENTS_F_SUCCESS,
      GET_REVIEW_QUEUE_SETTLEMENTS_F_SUCCESS,
      GET_IDENTITY_SETTLEMENTS_F_SUCCESS,
      GET_APPLICATION_SETTLEMENTS_F_SUCCESS,
      GET_SETTLEMENT_GROUP_SETTLEMENTS_F_SUCCESS,
      GET_MERCHANT_SETTLEMENTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENTS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getSettlementsApplications',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_SETTLEMENTS_F_SUCCESS,
      GET_IDENTITY_SETTLEMENTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENTS_APPLICATIONS_F_SUCCESS,
  },
  {
    name: 'getFeesMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    resourceSuccessF: [
      GET_FEES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_FEES_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getMerchantApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_FEES_MERCHANT_F_SUCCESS,
      GET_MERCHANTS_F_SUCCESS,
      GET_APPLICATION_MERCHANTS_F_SUCCESS,
      GET_DEVICE_MERCHANT_F_SUCCESS,
      GET_SUBSCRIPTIONS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSettlementsIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_SETTLEMENTS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SETTLEMENTS_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getApplicationSettlementsIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_SETTLEMENTS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_APPLICATION_SETTLEMENTS_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getMerchantIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_MERCHANTS_F_SUCCESS,
      GET_APPLICATION_MERCHANTS_F_SUCCESS,
      GET_FEES_MERCHANT_F_SUCCESS,
      GET_SUBSCRIPTION_SCHEDULE_ENROLLMENTS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getMerchantVerification',
    idKey: 'verificationId',
    api: getVerificationAPI,
    frontendModel: frontendVerificationModel,
    reducer: 'verificationsR',
    resourceSuccessF: [
      GET_MERCHANTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_VERIFICATION_F_SUCCESS,
  },
  {
    name: 'getPaymentInstrumentApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_PAYMENT_INSTRUMENT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PAYMENT_INSTRUMENT_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getPaymentInstrumentIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_PAYMENT_INSTRUMENT_F_SUCCESS,
      GET_TRANSFERS_PAYMENT_INSTRUMENT_F_SUCCESS,
      GET_AUTHORIZATIONS_PAYMENT_INSTRUMENT_F_SUCCESS,
      GET_RELATED_PAYMENT_INSTRUMENT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getTransferPaymentInstrumentIdentity',
    idKey: 'buyerIdentityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_PAYMENT_INSTRUMENT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getApplicationsIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_APPLICATIONS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_APPLICATIONS_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getOnboardingFormsApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_ONBOARDING_FORMS_F_SUCCESS,
      GET_ONBOARDING_FORM_DATA_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_ONBOARDING_FORMS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getOnboardingFormsOnboardingFormData',
    idKey: 'id',
    api: getOnboardingFormDataAPI,
    frontendModel: frontendOnboardingFormDataModel,
    reducer: 'onboardingFormDataR',
    resourceSuccessF: [
      GET_ONBOARDING_FORMS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_ONBOARDING_FORMS_ONBOARDING_FORM_DATA_F_SUCCESS,
  },
  {
    name: 'getRoleApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_ROLE_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_ROLE_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getMemberRole',
    idKey: 'roleId',
    api: getRoleAPI,
    frontendModel: frontendRoleModel,
    reducer: 'rolesR',
    resourceSuccessF: [
      GET_MEMBER_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MEMBER_ROLE_F_SUCCESS,
  },
  {
    name: 'getMerchantUnderwritingReviewIdentities',
    idKey: 'entityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_MERCHANT_UNDERWRITING_REVIEWS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
  },
  {
    name: 'getRecipientUnderwritingReviewIdentities',
    idKey: 'entityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_RECIPIENT_UNDERWRITING_REVIEWS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
  },
  {
    name: 'getMerchantUnderwritingReviewIdentitiesMerchants',
    idKey: 'id',
    api: getUnderwritingIdentityMerchantsO,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
      GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_MERCHANTS_F_SUCCESS,
  },
  {
    name: 'getMerchantUnderwritingReviewIdentitiesFiles',
    idKey: 'id',
    api: getUnderwritingIdentityFilesO,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
      GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_FILES_F_SUCCESS,
  },
  {
    name: 'getMerchantUnderwritingReviewIdentitiesApplications',
    idKey: 'application',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
      GET_RECIPIENT_UNDERWRITING_REVIEW_IDENTITIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_UNDERWRITING_REVIEW_IDENTITIES_APPLICATIONS_F_SUCCESS,
  },
  {
    name: 'getRecipientIdentities',
    idKey: 'id',
    api: getRecipientIdentityO,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_RECIPIENT_IDENTITIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_RECIPIENT_IDENTITY_MERCHANTS_F_SUCCESS,
  },
  {
    name: 'getBalanceEntriesApplication',
    idKey: 'linkedTo',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_BALANCE_ENTRIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_BALANCE_ENTRIES_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getBalanceEntriesTransfer',
    idKey: 'transferId',
    api: getTransferAPI,
    frontendModel: frontendTransferModel,
    reducer: 'transfersR',
    resourceSuccessF: [
      GET_BALANCE_ENTRIES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_BALANCE_ENTRIES_TRANSFER_F_SUCCESS,
  },
  {
    name: 'getPaymentLinksMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_PAYMENT_LINK_F_SUCCESS,
      GET_PAYMENT_LINKS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PAYMENT_LINKS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getPaymentLinksApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_PAYMENT_LINK_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PAYMENT_LINKS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getPurchasesTransfer',
    idKey: 'transferId',
    api: getTransferAPI,
    frontendModel: frontendTransferModel,
    reducer: 'transfersR',
    resourceSuccessF: [
      GET_PURCHASE_F_SUCCESS,
      GET_PURCHASES_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PURCHASES_TRANSFER_F_SUCCESS,
  },
  {
    name: 'getPurchaseApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_PURCHASE_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PURCHASE_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getPurchaseMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_PURCHASE_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PURCHASE_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getPurchaseMerchantIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_PURCHASE_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PURCHASE_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getPurchaseTransferPaymentInstrument',
    idKey: 'paymentInstrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_PURCHASES_TRANSFER_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_PURCHASE_TRANSFER_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getTransferMerchantProcessor',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_TRANSFER_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getBalanceAdjustmentsPaymentInstrument',
    idKey: 'instrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_BALANCE_ADJUSTMENTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_BALANCE_ADJUSTMENTS_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getSubscriptionsIdentity',
    idKey: 'buyerIdentityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_SUBSCRIPTIONS_F_SUCCESS,
      GET_SUBSCRIPTION_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTIONS_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getSubscriptionsMerchant',
    idKey: 'linkedTo',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_SUBSCRIPTIONS_F_SUCCESS,
      GET_SUBSCRIPTION_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTIONS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getSubscriptionsPaymentInstrument',
    idKey: 'buyerInstrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_SUBSCRIPTIONS_F_SUCCESS,
      GET_SUBSCRIPTION_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTIONS_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getSubscriptionsPlan',
    id: 'subscriptionPlanId',
    api: getSubscriptionPlanAPI,
    frontendModel: frontendSubscriptionPlanModel,
    reducer: 'subscriptionPlansR',
    resourceSuccessF: [
      GET_SUBSCRIPTIONS_F_SUCCESS,
      GET_SUBSCRIPTION_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTIONS_PLAN_F_SUCCESS,
  },
  {
    name: 'getMerchantTransfersApplication',
    idKey: 'application',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_MERCHANT_TRANSFERS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_MERCHANT_TRANSFERS_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getSubscriptionPlanMerchant',
    idKey: 'linkedTo',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_SUBSCRIPTION_PLANS_F_SUCCESS,
      GET_SUBSCRIPTION_PLAN_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTION_PLANS_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getSubscriptionPlanMerchantIdentity',
    idKey: 'identityId',
    api: getIdentityAPI,
    frontendModel: frontendIdentityModel,
    reducer: 'identitiesR',
    resourceSuccessF: [
      GET_SUBSCRIPTION_PLANS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTION_PLAN_MERCHANT_IDENTITY_F_SUCCESS,
  },
  {
    name: 'getSubscriptionPlanMerchantApplication',
    idKey: 'applicationId',
    api: getApplicationAPI,
    frontendModel: frontendApplicationModel,
    reducer: 'applicationsR',
    resourceSuccessF: [
      GET_SUBSCRIPTION_PLANS_MERCHANT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_SUBSCRIPTION_PLAN_MERCHANT_APPLICATION_F_SUCCESS,
  },
  {
    name: 'getTransferAttemptPayoutLink',
    idKey: 'entityId',
    api: getPayoutLinkAPI,
    frontendModel: frontendPayoutLinkModel,
    reducer: 'payoutLinksR',
    resourceSuccessF: [
      GET_TRANSFER_ATTEMPT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_TRANSFER_ATTEMPT_PAYOUT_LINK_F_SUCCESS,
  },
  {
    name: 'getInstantPayoutsMerchant',
    idKey: 'merchantId',
    api: getMerchantAPI,
    frontendModel: frontendMerchantModel,
    reducer: 'merchantsR',
    resourceSuccessF: [
      GET_INSTANT_PAYOUTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_INSTANT_PAYOUT_MERCHANT_F_SUCCESS,
  },
  {
    name: 'getInstantPayoutsPaymentInstrument',
    idKey: 'instrumentId',
    api: getPaymentInstrumentAPI,
    frontendModel: frontendPaymentInstrumentModel,
    reducer: 'paymentInstrumentsR',
    resourceSuccessF: [
      GET_INSTANT_PAYOUTS_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_INSTANT_PAYOUT_PAYMENT_INSTRUMENT_F_SUCCESS,
  },
  {
    name: 'getBalanceAdjustmentBalanceEntry',
    idKey: 'balanceEntryId',
    api: getBalanceEntryAPI,
    frontendModel: frontendBalanceEntryModel,
    reducer: 'balanceEntriesR',
    resourceSuccessF: [
      GET_BALANCE_ADJUSTMENT_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_BALANCE_ADJUSTMENT_BALANCE_ENTRY_F_SUCCESS,
  },
  {
    name: 'getBalanceEntryParentBalanceEntry',
    idKey: 'parentBalanceEntryId',
    api: getBalanceEntryAPI,
    frontendModel: frontendBalanceEntryModel,
    reducer: 'balanceEntriesR',
    resourceSuccessF: [
      GET_BALANCE_ENTRY_F_SUCCESS,
    ],
    relatedResourceSuccessF: GET_BALANCE_ENTRY_PARENT_BALANCE_ENTRY_F_SUCCESS,
  },
]
