import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComplianceFormTemplateInfo from './ComplianceFormTemplateInfo'
import Loader from 'components/Shared/Loader/Loader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import showModalAction from 'utilities/actions/showModalAction'
import { EDIT_COMPLIANCE_FORM_TEMPLATE, FILE_VIEWER_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING } from 'constants/complianceConstants'
import get from 'lodash/get'

import {
  EDIT_NAME,
  VIEW_TEMPLATE_PDF,
  TYPE,
} from 'constants/language/languageConstants'

import {
  APPLICATION_PATH,
  COMPANY_PATH,
  DUPLICATE_COMPLIANCE_FORM_TEMPLATE_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `complianceFormTemplatesR.${FETCHING}`)
  const complianceFormTemplate = get(props, 'complianceFormTemplate')
  const complianceFormTemplateId = get(complianceFormTemplate, 'id')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const duplicateComplianceTemplatePath = DUPLICATE_COMPLIANCE_FORM_TEMPLATE_PATH({ credentialId, complianceFormTemplateId })

  const [
    name,
    type,
    displayUpdatedAt,
    file,
    linkedTo,
    linkedType,
    applicationName,
    platformName,
  ] = getMany(complianceFormTemplate, [
    'name',
    'type',
    'displayUpdatedAt',
    'file',
    'linkedTo',
    'linkedType',
    'applicationName',
    'platformName',
  ])

  const platformSettingsPath = `${COMPANY_PATH({ credentialId })}?tab=compliance-settings`
  const applicationSettingsPath = `${APPLICATION_PATH({ credentialId, applicationId: linkedTo })}?tab=application-settings`
  const linkedToIsPlatform = linkedType === 'PLATFORM'
  const linkedToPath = linkedToIsPlatform ? platformSettingsPath : applicationSettingsPath
  const status = get(file, 'status')
  const fileUploaded = status === 'UPLOADED'

  const templateInfoSectionData = [
    {
      type: 'data',
      data: [
        {
          label: 'Template Name',
          value: name,
        },
        {
          label: TYPE,
          value: COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type],
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: 'Linked To',
          value: linkedToIsPlatform ? platformName : applicationName,
          path: linkedTo ? linkedToPath : undefined,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: 'Updated On',
          value: displayUpdatedAt,
        },
      ],
    },
  ]

  return {
    isFetching,
    credentials,
    complianceFormTemplate,
    credentialId,
    duplicateComplianceTemplatePath,
    templateInfoSectionData,
    file,
    fileUploaded,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showEditComplianceFormTemplateModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_COMPLIANCE_FORM_TEMPLATE, modalProps })),
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
  }
}

class ComplianceFormTemplateInfoC extends Component {
  render() {
    const {
      complianceFormTemplate,
      showEditComplianceFormTemplateModal,
      showFileViewer,
      file,
      fileUploaded,
    } = this.props

    const actions = [{
      label: EDIT_NAME,
      action: () => showEditComplianceFormTemplateModal({ complianceFormTemplate }),
    }]

    const viewPDFAsideTitle = fileUploaded ? (
      <div
        className='view-pdf-template aside-title'
        onClick={() => showFileViewer(file)}
      >
        {VIEW_TEMPLATE_PDF}
      </div>
    ) : <div className='aside-title'>PDF (Pending) <Loader /></div>

    return (
      <ComplianceFormTemplateInfo
        {...this.props}
        viewPDFAsideTitle={viewPDFAsideTitle}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceFormTemplateInfoC)
