import NotificationSettings from './NotificationSettings'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import getNotificationSettingsRequest from 'utilities/actions/get/getNotificationSettingsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isPaymentOperationsManagerRole from 'utilities/is/isPaymentOperationsManagerRole'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { isRolePartner as isRolePartnerCheck, isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { getNotificationSettingsSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import { NOTIFICATIONS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  SHOW_ACH_RETURN_NOTIFICATION_SETTING,
  SHOW_WEBHOOK_LOGS,
} from 'constants/featureFlagConstants'

import {
  NOTIFICATION_SETTINGS_PAYMENT_FAILED_TITLE,
  NOTIFICATION_SETTINGS_DISPUTE_CREATED_TITLE,
  NOTIFICATION_SETTINGS_DISPUTE_UPDATED_TITLE,
  NOTIFICATION_SETTINGS_ECHECK_RETURN_TITLE,
  NOTIFICATION_SETTINGS_FAILED_FUNDING_TRANSFER_TITLE,
  NOTIFICATION_SETTINGS_WEBHOOK_ISSUE_TITLE,
  NOTIFICATION_SETTINGS_WEBHOOK_DISABLED_TITLE,
  WEBHOOK_ISSUE_TOOLTIP,
  COMPLIANCE_FORMS_DUE_MONTHLY,
  COMPLIANCE_FORMS_DUE,
  WEBHOOKS,
  DISPUTES,
  EXCEPTIONS,
  TRANSACTION_MONITORING,
  FRAUD_MONITORING,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `notificationSettingsR.${FETCHING}`)
  const viewAllPath = NOTIFICATIONS_PATH({ credentialId })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)
  const isRolePartner = isRolePartnerCheck({ credentials })
  const isRolePlatformAdminRole = isRolePlatform({ credentials }) && (isPaymentOperationsManagerRole(credentials) || isAdministratorRole(credentials))

  // fetch any existing notification settings
  const notificationSettings = getNotificationSettingsSelector(state)

  const settingOptions = [
    {
      title: EXCEPTIONS,
      condition: !isStandaloneUnderwriting,
      data: [
        {
          id: 'PAYMENT_FAILED',
          title: NOTIFICATION_SETTINGS_PAYMENT_FAILED_TITLE,
        },
        {
          id: 'ECHECK_RETURN',
          title: NOTIFICATION_SETTINGS_ECHECK_RETURN_TITLE,
          condition: getFeatureFlag(SHOW_ACH_RETURN_NOTIFICATION_SETTING),
        },
        {
          id: 'FAILED_FUNDING_TRANSFER',
          title: NOTIFICATION_SETTINGS_FAILED_FUNDING_TRANSFER_TITLE,
        }],
    },
    {
      title: DISPUTES,
      condition: !isStandaloneUnderwriting,
      data: [
        {
          id: 'DISPUTE_CREATED',
          title: NOTIFICATION_SETTINGS_DISPUTE_CREATED_TITLE,
        },
        {
          id: 'DISPUTE_UPDATED',
          title: NOTIFICATION_SETTINGS_DISPUTE_UPDATED_TITLE,
        }],
    },
    {
      title: WEBHOOKS,
      condition: getFeatureFlag(SHOW_WEBHOOK_LOGS),
      data: [
        {
          id: 'WEBHOOK_ISSUE',
          title: <div className='flex items-center'>{NOTIFICATION_SETTINGS_WEBHOOK_ISSUE_TITLE}<TooltipLabelC message={WEBHOOK_ISSUE_TOOLTIP} position='right' /></div>,
        },
        {
          id: 'WEBHOOK_DISABLED',
          title: NOTIFICATION_SETTINGS_WEBHOOK_DISABLED_TITLE,
          noEmail: true,
        }],
    },
    {
      title: COMPLIANCE_FORMS_DUE,
      condition: isRolePartner,
      data: [
        {
          id: 'COMPLIANCE_FORMS_DUE',
          title: COMPLIANCE_FORMS_DUE_MONTHLY,
        },
      ],
    },
    {
      title: TRANSACTION_MONITORING,
      condition: isRolePlatformAdminRole,
      data: [
        {
          id: 'FRAUD_MONITORING_ALERT',
          title: FRAUD_MONITORING,
          noEmail: true,
        }],
    },
  ]

  return {
    credentials,
    settingOptions,
    initialValues: notificationSettings,
    isFetching,
    viewAllPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationSettings: ({ credentials }) => { dispatch(getNotificationSettingsRequest({ credentials })) },
  }
}

class NotificationSettingsC extends Component {
  componentDidMount() {
    const { getNotificationSettings, credentials } = this.props

    getNotificationSettings({ credentials })
  }

  render() {
    return (
      <NotificationSettings
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsC)
