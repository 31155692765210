import './AddNewIOSApplicationModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import IOSApplicationForm from 'components/Customer/Forms/IOSApplicationForm/IOSApplicationForm'
import DownloadCSRFileFormC from 'components/Customer/Forms/DownloadCSRFileForm/DownloadCSRFileFormC'
import ApplePayObtainCertInstructionsForm from 'components/Customer/Forms/ApplePayObtainCertInstructionsForm/ApplePayObtainCertInstructionsForm'
import UploadPaymentProcessingCertFormC from 'components/Customer/Forms/UploadPaymentProcessingCertForm/UploadPaymentProcessingCertFormC'
import CertificatePendingFormC from 'components/Customer/Forms/CertificatePendingForm/CertificatePendingFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import { ADD_IOS_APPLICATION_NAME_DATA_KEY } from 'constants/flowsConstants'
import { ADD_IOS_APPLICATION_NAME_FORM } from 'constants/formConstants'

const AddNewIOSApplicationModal = ({
  identityId = '',
  hasPendingCert = false,
  resumeProcess = false,
  showResumeModal = () => {},
  rowApplicationName,
}) => {
  const steps = [
    {
      component: IOSApplicationForm,
      stepTitle: 'Application Name',
      props: {
        submitLabel: 'Next',
        form: ADD_IOS_APPLICATION_NAME_FORM,
      },
      dataKey: ADD_IOS_APPLICATION_NAME_DATA_KEY,
    },
    {
      component: DownloadCSRFileFormC,
      stepTitle: 'Download File',
      props: {
        submitLabel: 'Next',
        leftButton: true,
        identityId,
        leftButtonDisabled: resumeProcess,
        rowApplicationName,
      },
    },
    {
      component: ApplePayObtainCertInstructionsForm,
      stepTitle: 'Obtain Certificate',
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
    },
    {
      component: UploadPaymentProcessingCertFormC,
      stepTitle: 'Upload Certificate',
      props: {
        identityId,
      },
    },
  ]

  return (
    (!hasPendingCert || resumeProcess) ? (
      <GenericModal
        className='AddNewIOSApplicationModal'
        identityId={identityId}
        steps={steps}
        multiSteps
        title='Add iOS Application'
      />
    ) : (
      <GenericModal
        className='AddNewIOSApplicationModal'
        title='There is already a pending iOS certificate'
        Component={CertificatePendingFormC}
        showResumeModal={showResumeModal}
      />
    )
  )
}

AddNewIOSApplicationModal.propTypes = {
  identityId: PropTypes.string,
  hasPendingCert: PropTypes.bool,
  resumeProcess: PropTypes.bool,
  showResumeModal: PropTypes.func,
  rowApplicationName: PropTypes.string,
}

export default AddNewIOSApplicationModal
