import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getPlatformAPI from 'api/finix/get/getPlatformAPI'
import getPayoutPlanO from 'state-layer/orchestration/get/getPayoutPlanO'
import getResponseLinks from 'utilities/get/getResponseLinks'
import getCurrentUser from 'utilities/get/getCurrentUser'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendPaymentInstrumentsModel from 'utilities/create/models/frontendPaymentInstrumentsModel'
import frontendPayoutPlanModel from 'utilities/create/models/frontendPayoutPlanModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import { FEE_PROFILE } from 'constants/apiConstants'
import head from 'lodash/head'
import get from 'lodash/get'
import merge from 'lodash/merge'
import co from 'co'

// TODO: split out logic of getting payment plan and payment settings info
function * getMerchantO ({
  id,
  credentials,
}) {
  const { data: response } = yield getMerchantAPI({ id, credentials })
  const links = getResponseLinks(response)

  const [
    applicationLink,
    merchantProfileLink,
    identityLink,
  ] = getMany(links, [
    'application',
    'merchant_profile',
    'identity',
  ])

  const [
    { data: applicationData },
    { data: merchantProfile },
    { data: identityResponse },
  ] = yield [
    getPaymentsAPI({ absolutePath: applicationLink, credentials }),
    getPaymentsAPI({ absolutePath: merchantProfileLink, credentials }),
    getPaymentsAPI({ absolutePath: identityLink, credentials }),
  ]

  const riskProfileId = get(merchantProfile, 'risk_profile')

  const { data: riskProfile } = riskProfileId ? yield getPaymentsAPI({
    path: `risk_profiles/${riskProfileId}`,
    credentials,
  }) : { data: null }

  const identityPaymentInstrumentsLink = get(identityResponse, '_links.payment_instruments.href')

  const identityPaymentInstrumentsResponse = yield getPaymentsAPI({
    absolutePath: `${identityPaymentInstrumentsLink}?limit=100`,
    credentials,
  })

  const identityPaymentInstruments = get(identityPaymentInstrumentsResponse, 'data._embedded.payment_instruments')
  const identityPaymentInstrumentModels = frontendPaymentInstrumentsModel({ data: identityPaymentInstruments })

  const payoutPlanResponse = hasPartnerAccess({ credentials }) ? head(get(yield getPayoutPlanO({
    merchantId: id,
    credentials,
  }), 'data')) : {}

  const merchant = removeUndefined(merge({}, response, {
    applicationData,
    riskProfile,
    merchantProfile,
    merchantIdentity: identityResponse,
    paymentInstruments: identityPaymentInstrumentModels,
    payoutPlan: frontendPayoutPlanModel({ data: payoutPlanResponse }),
  }))

  return createSuccessResponse({
    data: [merchant],
  })
}

export default co.wrap(getMerchantO)
