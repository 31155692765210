import Persona from 'persona'

export const TEMPLATE_ID = 'itmpl_mbuqc4bwUWC6Q8ttdNE6sfMxB6q5'
export const SANDBOX_ENV = 'env_hXsPwsiNXwVtFRyUmtGndzc6'
export const PRODUCTION_ENV = 'env_ojW7ze5caik4AZAx5N8M2fqY'

export const PASSED = 'PASSED'
export const FAILED = 'FAILED'
export const NOT_APPLICABLE = 'NOT_APPLICABLE'

const statusToLabelMap = {
  [PASSED]: 'Passed',
  [FAILED]: 'Failed',
  [NOT_APPLICABLE]: 'Not Applicable',
}

/**
 * Persona Client initialization helper
 *
 * @param {Object} [options]
 * @param {string} [options.templateId] - the templateId of the persona flow
 * @param {string} [options.environmentId] - the environmentId of the persona flow
 * @param {function} [options.onReady] - the callback function that is called when the persona client is ready
 * @param {function} [options.onComplete] - the callback function that is called when the persona flow is completed
 * @param {function} [options.onCancel] - the callback function that is called when the persona flow is cancelled
 * @param {function} [options.onError] - the callback function that is called when the persona flow encounters an error
 * @returns {Client} - the persona client instance
 */
export const initializePersonaClient = ({
  templateId = TEMPLATE_ID,
  environmentId = PRODUCTION_ENV,
  onReady = () => {},
  onComplete = ({ inquiryId, status, fields }) => ({ inquiryId, status, fields }),
  onCancel = ({ inquiryId, sessionToken }) => ({ inquiryId, sessionToken }),
  onError = (error) => ({ error }),
} = {}) => {
  return new Persona.Client({
    templateId,
    environmentId,
    onReady,
    onComplete,
    onCancel,
    onError,
  })
}
