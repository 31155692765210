import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { convertTagValues } from 'utilities/convert/convertTagValues'
import redirectRequest from 'utilities/actions/redirectRequest'
import { SUBSCRIPTION_SCHEDULE_PATH } from 'constants/pathConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import get from 'lodash/get'

import {
  CREATE_SUBSCRIPTION_ENROLLMENT,
  CREATE_SUBSCRIPTION_SCHEDULE,
  CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT,
  CREATE_SUBSCRIPTION_SCHEDULE_ENROLLMENT,
} from 'constants/amplitudeConstants'

import {
  POST_SUBSCRIPTION_SCHEDULE_F_REQUEST,
  POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
  POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_REQUEST,
} from 'constants/flowConstants'

const submitCreateSubscriptionForm = (values, dispatch, props) => {
  const { credentials, merchantId } = props

  const {
    subscriptionScheduleForm = {},
    subscriptionAmountForm = {},
    selectSubscriptionScheduleForm = {},
    subscriptionEnrollmentForm = {},
  } = values

  const { subscriptionScheduleId: enrollmentSubscriptionScheduleId } = selectSubscriptionScheduleForm
  const scheduleValues = merge(omit(subscriptionScheduleForm, 'tags'), { tags: convertTagValues(subscriptionScheduleForm) })
  const amountValues = merge(omit(subscriptionAmountForm, 'tags'), { tags: convertTagValues(subscriptionAmountForm) })
  const enrollmentValues = merge(omit(subscriptionEnrollmentForm, 'tags'), { merchantId, tags: convertTagValues(subscriptionEnrollmentForm) })
  const credentialId = get(credentials, 'id')

  // submit 'Enroll in an existing schedule'
  if (enrollmentSubscriptionScheduleId && merchantId) {
    dispatch({
      type: POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_REQUEST,
      payload: {
        id: enrollmentSubscriptionScheduleId,
        values: enrollmentValues,
        credentials,
      },
      meta: {
        selectorId: merchantId,
        actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION_ENROLLMENT, {
          credentials,
          subscriptionScheduleId: enrollmentSubscriptionScheduleId,
          ...enrollmentValues,
        }),
        successCallback: () => {
          const subscriptionSchedulePath = SUBSCRIPTION_SCHEDULE_PATH({ subscriptionScheduleId: enrollmentSubscriptionScheduleId, credentialId })

          dispatch(redirectRequest({ path: subscriptionSchedulePath }))
        },
      },
    })
  // submit Merchant -> 'Enroll in a new schedule' + Subscription Billing -> 'Create a Subscription Schedule'
  } else {
    dispatch({
      type: POST_SUBSCRIPTION_SCHEDULE_F_REQUEST,
      payload: {
        values: scheduleValues,
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION_SCHEDULE, {
          credentials,
          ...scheduleValues,
        }),
        successCallback: ({ newValues: scheduleNewValues }) => {
          const subscriptionScheduleId = head(keys(scheduleNewValues))

          dispatch({
            type: POST_SUBSCRIPTION_SCHEDULE_AMOUNT_F_REQUEST,
            payload: {
              id: subscriptionScheduleId,
              values: amountValues,
              credentials,
            },
            meta: {
              actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT, {
                credentials,
                subscriptionScheduleId,
                ...amountValues,
              }),
              successCallback: () => {
                const subscriptionSchedulePath = SUBSCRIPTION_SCHEDULE_PATH({ subscriptionScheduleId, credentialId })

                dispatch(redirectRequest({ path: subscriptionSchedulePath }))
              },
            },
          })

          // submit Merchant -> Enroll in a new schedule
          if (merchantId) {
            dispatch({
              type: POST_SUBSCRIPTION_SCHEDULE_ENROLLMENT_F_REQUEST,
              payload: {
                id: subscriptionScheduleId,
                values: enrollmentValues,
                credentials,
              },
              meta: {
                selectorId: merchantId,
                actionId: sendAmplitudeActionEvent(CREATE_SUBSCRIPTION_SCHEDULE_ENROLLMENT, {
                  credentials,
                  subscriptionScheduleId,
                  ...enrollmentValues,
                }),
              },
            })
          }
        },
      },
    })
  }
}

export default submitCreateSubscriptionForm
