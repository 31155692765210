import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import ComplianceFormTemplateInfoC from 'components/Customer/Pages/Compliance/ComplianceFormTemplate/ComplianceFormTemplateInfo/ComplianceFormTemplateInfoC'
import ComplianceFormTemplateFormsC from 'components/Customer/Pages/Compliance/ComplianceFormTemplate/ComplianceFormTemplateForms/ComplianceFormTemplateFormsC'
import { GENERATED_FORMS } from 'constants/language/languageConstants'

const ComplianceFormTemplate = ({
  contextBarData = {},
  headerData = {},
  complianceFormTemplate = {},
  complianceFormTemplateId = '',
}) => {
  return (
    <div className='ComplianceFormTemplate'>
      <ContextBar {...contextBarData} />

      <HeaderV2C {...headerData} />

      <ComplianceFormTemplateInfoC
        complianceFormTemplate={complianceFormTemplate}
      />

      <PageSectionV2C title={GENERATED_FORMS}>
        <ComplianceFormTemplateFormsC
          complianceFormTemplateId={complianceFormTemplateId}
        />
      </PageSectionV2C>
    </div>
  )
}

ComplianceFormTemplate.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  complianceFormTemplate: PropTypes.object,
  complianceFormTemplateId: PropTypes.string,
}

export default ComplianceFormTemplate
