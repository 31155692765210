import getMany from 'utilities/get/getMany'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SUBMIT_WELCOME_NAME_CAPTURE } from 'constants/amplitudeConstants'
import { PATCH_DASHBOARD_USER_F_REQUEST } from 'constants/flowConstants'
import hideModalAction from 'utilities/actions/hideModalAction'
import trim from 'lodash/trim'

const submitWelcomeNameCaptureForm = (values, dispatch, props) => {
  const { firstName, lastName } = values

  const [
    credentials,
    dashboardUserId,
  ] = getMany(props, [
    'credentials',
    'dashboardUserId',
  ])

  const formValues = {
    first_name: trim(firstName),
    last_name: trim(lastName),
  }

  dispatch({
    type: PATCH_DASHBOARD_USER_F_REQUEST,
    payload: {
      id: dashboardUserId,
      values: formValues,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_WELCOME_NAME_CAPTURE, {
        credentials,
        firstName,
        lastName,
      }),
      successCallback: () => dispatch(hideModalAction()),
    },
  })
}

export default submitWelcomeNameCaptureForm
