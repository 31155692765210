import dashboardAPI from 'utilities/api/dashboardAPI'

import {
  DASHBOARD_SERVICE_TRANSFER,
  DASHBOARD_SERVICE_TRANSFERS,
} from 'constants/apiConstants'

const getTransferAPI = ({ id, meta }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_TRANSFER({ id }),
    service: DASHBOARD_SERVICE_TRANSFERS,
    meta,
  })
}

export default getTransferAPI
