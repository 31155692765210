import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYMENT_INSTRUMENTS,
  PAYMENT_INSTRUMENT_VERIFICATIONS,
} from 'constants/apiConstants'

const postPaymentInstrumentVerificationsAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: PAYMENT_INSTRUMENT_VERIFICATIONS({ paymentInstrumentId: id }),
    service: PAYMENT_INSTRUMENTS,
  })
}

export default postPaymentInstrumentVerificationsAPI
