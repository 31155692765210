import getMany from 'utilities/get/getMany'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import split from 'lodash/split'
import find from 'lodash/find'
import filter from 'lodash/filter'

import {
  CREATED,
  UPDATED,
  UNDERWRITTEN,
  ACCRUING_STARTED,
} from 'constants/language/languageConstants'

const formatEnabledEventString = (enabledEvent = {}) => {
  if (isEmpty(enabledEvent)) return ''

  const [
    entity,
    types,
  ] = getMany(enabledEvent, [
    'entity',
    'types',
  ])

  const normalizedTypes = map(types, (type) => snakeCaseToTitleCase({ key: type }))
  const order = [CREATED, UPDATED, UNDERWRITTEN, ACCRUING_STARTED]
  const orderedTypes = filter(map(order, (type) => find(split(normalizedTypes, ','), (displayType) => displayType === type)), (eventType) => eventType !== undefined)
  const displayTypes = !isEmpty(types) ? join(orderedTypes, ', ') : ''

  return { label: convertSnakeToSentenceCase(entity, { ach: 'ACH' }), value: displayTypes }
}

export default formatEnabledEventString
