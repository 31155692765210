import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import formatSSN from 'utilities/forms/format/formatSSN'
import formatSIN from 'utilities/forms/format/formatSIN'
import parseTaxId from 'utilities/forms/parse/parseTaxId'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

import {
  PERSONAL_TAX_ID_SSN_ITIN_HELPER_TEXT,
  SOCIAL_INSURANCE_NUMBER,
  SOCIAL_INSURANCE_NUMBER_HELPER_TEXT,
  SOCIAL_SECURITY_NUMBER_LABEL,
} from 'constants/language/languageConstants'

const helperTextMap = (country) => {
  switch (country) {
    case CAN:
      return SOCIAL_INSURANCE_NUMBER_HELPER_TEXT
    default:
      return PERSONAL_TAX_ID_SSN_ITIN_HELPER_TEXT
  }
}

const labelMap = (country) => {
  switch (country) {
    case CAN:
      return SOCIAL_INSURANCE_NUMBER
    default:
      return SOCIAL_SECURITY_NUMBER_LABEL
  }
}

const formatterMap = (country) => {
  switch (country) {
    case CAN:
      return formatSIN

    default:
      return formatSSN
  }
}

const placeholderMap = (country) => {
  switch (country) {
    case CAN:
      return '123-456-789'
    default:
      return '123-45-6789'
  }
}

const requiredMap = (country) => {
  switch (country) {
    case CAN:
      return false
    default:
      return true
  }
}


const PersonalTaxIdField = ({
  name = 'taxId',
  country = USA,
  onboardingFormCountry = USA,
}) => {
  return (
    <Field
      name={name}
      label={labelMap(country)}
      helperText={helperTextMap(country)}
      component={InputField}
      format={formatterMap(country)}
      parse={parseTaxId}
      placeholder={placeholderMap(country)}
      required={requiredMap(onboardingFormCountry)}
    />
  )
}

PersonalTaxIdField.propTypes = {
  name: PropTypes.string,
  country: PropTypes.string,
  onboardingFormCountry: PropTypes.string,
}

export default PersonalTaxIdField
