import patchComplianceSettingsAPI from 'api/dashboard/patch/patchComplianceSettingsAPI'
import getComplianceSettingsAPI from 'api/dashboard/get/getComplianceSettingsAPI'
import removeUndefined from 'utilities/remove/removeUndefined'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import co from 'co'

// for now, only applications can patch their compliance settings. This orchestration file works under that assumption.
function * patchComplianceSettingsO ({
  queries,
  credentials,
  values,
}) {
  // patch the compliance settings
  yield patchComplianceSettingsAPI({ queries, values })

  // get the compliance settings based on applicationId
  const applicationId = get(queries, 'linked_to')
  const complianceSettingsResponse = yield getComplianceSettingsAPI({
    credentials,
    queries: { linked_to: applicationId },
  })

  const complianceSettings = removeUndefined({
    id: applicationId,
    autoGenerateComplianceForms: get(complianceSettingsResponse, 'data.allow_automatic_form_generation'),
    autoRenewComplianceForms: get(complianceSettingsResponse, 'data.allow_annual_form_generation'),
  })

  return createSuccessResponse({
    data: complianceSettings,
  })
}

export default co.wrap(patchComplianceSettingsO)
