import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACTIVE_COMPLIANCE_FORM_TEMPLATE } from 'constants/apiConstants'

const getActiveComplianceFormTemplateAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    queries,
    meta,
    path: ACTIVE_COMPLIANCE_FORM_TEMPLATE,
  })
}

export default getActiveComplianceFormTemplateAPI
