import './UnderwritingMerchantCaseNotesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Notes from 'components/Customer/Pages/Notes/Notes'
import { underwritingNoteTemplates } from 'constants/noteConstants'
import { UW_CASE_NOTE_EMPTY_MSG } from 'constants/language/languageConstants'

const UnderwritingMerchantCaseNotes = ({
  reviewId,
  emptyMessage = '',
  showAddNewNote = false,
}) => {
  return (
    <div className='UnderwritingMerchantCaseNotes'>
      <Notes
        linkedTo={reviewId}
        templates={underwritingNoteTemplates}
        emptyMessage={UW_CASE_NOTE_EMPTY_MSG}
        showAddNewNote={showAddNewNote}
      />
    </div>
  )
}

UnderwritingMerchantCaseNotes.propTypes = {
  reviewId: PropTypes.string,
  emptyMessage: PropTypes.string,
  showAddNewNote: PropTypes.bool,
}

export default UnderwritingMerchantCaseNotes
