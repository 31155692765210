import hideModalAction from 'utilities/actions/hideModalAction'
import printCSV from 'utilities/printCSV'
import convertFieldItemToExportData from 'utilities/convert/convertFieldItemToExportData'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { ADVANCED_EXPORT_CSV } from 'constants/amplitudeConstants'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import map from 'lodash/map'

const submitExportDataForm = (values, dispatch, props) => {
  const {
    items,
    fileNameCSV,
    pageName,
  } = props

  const selectedFields = omitBy(values, ({ checked }) => checked === false)

  const exportData = flatten(
    map(items, ({ data }) => [convertFieldItemToExportData(selectedFields, data)]),
  )

  sendAmplitudeActionEvent(ADVANCED_EXPORT_CSV, {
    pageName,
    fileNameCSV,
    columns: Object.keys(selectedFields),
  })

  if (!isEmpty(exportData)) {
    printCSV(exportData, fileNameCSV)
  }

  dispatch(hideModalAction())
}

export default submitExportDataForm
