import getMany from 'utilities/get/getMany'
import numeral from 'numeral'

import {
  nonEmptyNumber,
  validateDefaultStatementDescriptor,
  invalidRegex,
  fieldEmpty,
} from 'utilities/validate'

import {
  AMEX_MID_REGEX,
  DISCOVER_MID_REGEX,
} from 'constants/regexConstants'

const validateIdentityProcessingForm = (values, props) => {
  const {
    annualCardVolume,
    maxTransactionAmount,
    achMaxTransactionAmount,
    defaultStatementDescriptor,
    amexMid,
    discoverMid,
    mcc,
    taxAuthority,
  } = values

  const [
    underwritingProfileMaxTransactionAmount,
    underwritingProfileACHMaxTransactionAmount,
    underwritingProfileDisplayMaxTransactionAmount,
    underwritingProfileDisplayACHMaxTransactionAmount,
    isRolePartnerWithEditIdentityPermissions,
  ] = getMany(props, [
    'underwritingProfileMaxTransactionAmount',
    'underwritingProfileACHMaxTransactionAmount',
    'underwritingProfileDisplayMaxTransactionAmount',
    'underwritingProfileDisplayACHMaxTransactionAmount',
    'isRolePartnerWithEditIdentityPermissions',
  ])

  const invalidMaxTransactionAmount = numeral(maxTransactionAmount).value() > numeral(underwritingProfileMaxTransactionAmount).value()
  const inValidAchMaxTransactionAmount = numeral(achMaxTransactionAmount).value() > numeral(underwritingProfileACHMaxTransactionAmount).value()

  return {
    defaultStatementDescriptor: validateDefaultStatementDescriptor({ descriptor: defaultStatementDescriptor, name: 'Default Statement Descriptor' }),
    annualCardVolume: nonEmptyNumber({ field: annualCardVolume, name: 'Annual Card Volume' }),
    maxTransactionAmount: nonEmptyNumber({ field: maxTransactionAmount, name: 'Max Transaction Amount' }) || ((invalidMaxTransactionAmount && isRolePartnerWithEditIdentityPermissions) && `Value exceeds the Application's max amount of ${underwritingProfileDisplayMaxTransactionAmount}`),
    achMaxTransactionAmount: nonEmptyNumber({ field: achMaxTransactionAmount, name: 'ACH Max Transaction Amount' }) || ((inValidAchMaxTransactionAmount && isRolePartnerWithEditIdentityPermissions) && `Value exceeds the Application's ACH max amount of ${underwritingProfileDisplayACHMaxTransactionAmount}`),
    amexMid: invalidRegex({ field: amexMid, name: 'Amex MID', regex: AMEX_MID_REGEX, customErrorMessage: 'American Express MID must be 10 digits.' }),
    discoverMid: invalidRegex({ field: discoverMid, name: 'Discover MID', regex: DISCOVER_MID_REGEX, customErrorMessage: 'Discover MID must be 15 digits.' }),
    mcc: fieldEmpty(mcc, 'MCC'),
    taxAuthority: mcc === '9311' && fieldEmpty(taxAuthority, 'Tax Authority'),
  }
}

export default validateIdentityProcessingForm
