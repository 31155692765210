import './CreateAPIKeyFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitAPIKeyForm from 'utilities/submit/submitAPIKeyForm'
import { CREATE_API_KEY_FORM } from 'constants/formConstants'
import { APPLICATION, PLATFORM } from 'constants/language/languageConstants'

const CreateAPIKeyForm = ({
  handleSubmit = () => {},
  submitting = false,
  applicationId,
  platformId,
}) => {
  return (
    <form className='CreateAPIKeyForm' onSubmit={handleSubmit(submitAPIKeyForm)}>
      <div className='section'>

        {applicationId && (
        <div>
          <label className='label-2'>{APPLICATION}</label>
          <div className='p-2-bold'>{applicationId}</div>
        </div>
        )}

        {platformId && (
        <div>
          <label className='label-2'>{PLATFORM}</label>
          <div className='p-2-bold'>{platformId}</div>
        </div>
        )}

        <Field
          name='name'
          label='Display Name'
          component={InputField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' label='Create' submitting={submitting} />
      </div>
    </form>
  )
}

CreateAPIKeyForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  applicationId: PropTypes.string,
  platformId: PropTypes.string,
}

export default reduxForm({
  form: CREATE_API_KEY_FORM,
})(CreateAPIKeyForm)
