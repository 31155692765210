import React from 'react'
import PropTypes from 'prop-types'
import EditSubscriptionAmountFormC from 'components/Customer/Forms/EditSubscriptionAmountForm/EditSubscriptionAmountFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const EditSubscriptionAmountModal = ({
  subscriptionScheduleId,
  subscriptionAmounts,
  credentials,
  closeModal,
}) => {
  return (
    <GenericModal
      title='Edit Subscription Amount'
      className='EditSubscriptionAmountModal'
      Component={EditSubscriptionAmountFormC}
      subscriptionScheduleId={subscriptionScheduleId}
      subscriptionAmounts={subscriptionAmounts}
      credentials={credentials}
      closeModal={closeModal}
    />
  )
}

EditSubscriptionAmountModal.propTypes = {
  subscriptionScheduleId: PropTypes.string,
  subscriptionAmounts: PropTypes.object,
  credentials: PropTypes.object,
  closeModal: PropTypes.func,
}

export default EditSubscriptionAmountModal
