import React from 'react'
import PropTypes from 'prop-types'
import { getIdentitiesSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { IDENTITIES } from 'constants/linkConstants'
import { GET_IDENTITIES } from 'constants/flowConstants'
import { GROUP_ICON } from 'constants/iconConstants'
import { MERCHANT_IDENTITIES_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  BUYERS,
  BUYERS_SUBTITLE_DESCRIPTION,
  BUYERS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const Buyers = ({
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  actions = [],
  quickFilters = [],
  buyersPath = '',
}) => {
  return (
    <div className='Buyers'>
      <HeaderV2C
        title={BUYERS}
        subTitle={BUYERS_SUBTITLE_DESCRIPTION}
        // TODO: FE-3060 update after product/design gets back to us on what link they want to use
        learnMoreLink={MERCHANT_IDENTITIES_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_IDENTITIES}
        columnDescriptors={columnDescriptors}
        selector={getIdentitiesSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={IDENTITIES}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        actions={actions}
        emptyMessage={BUYERS_EMPTY_MESSAGE}
        icon={`fa fa-${GROUP_ICON}`}
        fileNameCSV={BUYERS}
        quickFilters={quickFilters}
        path={buyersPath}
      />
    </div>
  )
}

Buyers.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  buyersPath: PropTypes.string,
}

export default Buyers
