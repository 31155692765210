import get from 'lodash/get'

import {
  fieldEmpty,
  validateBankCode,
  validateMaskedBankAccountNumber,
  validateBankAccountNumber,
} from 'utilities/validate'

const validateBankAccountForm = (values, props) => {
  const {
    name,
    bankCode,
    accountNumber,
    confirmAccountNumber,
    accountType,
  } = values

  const isHostedOnboarding = get(props, 'onboardingFormId')

  return {
    name: fieldEmpty(name, 'Name on Account'),
    bankCode: validateBankCode({ bankCode, name: 'Routing Number' }),
    accountNumber: isHostedOnboarding ? validateMaskedBankAccountNumber({ accountNumber, name: 'Account Number' }) : validateBankAccountNumber({ accountNumber, name: 'Account Number' }),
    confirmAccountNumber: fieldEmpty(confirmAccountNumber, 'Confirm Account Number'),
    accountType: fieldEmpty(accountType, 'Account Type'),
  }
}

export default validateBankAccountForm
