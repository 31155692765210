import './CreatePaymentInstrumentModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreatePaymentInstrumentFormC from 'components/Customer/Forms/CreatePaymentInstrumentForm/CreatePaymentInstrumentFormC'

import {
  ADD_A_NEW_CARD,
  ADD_A_NEW_PAYMENT_METHOD,
} from 'constants/language/languageConstants'

const CreatePaymentInstrumentModal = ({
  credentials = {},
  successFunction = () => {},
  applicationId = '',
  buyerIdentityId = '',
  environment = '',
  showTokenForm = false,
  formName = '',
  fieldName = '',
}) => {
  return (
    <GenericModal
      title={showTokenForm ? ADD_A_NEW_PAYMENT_METHOD : ADD_A_NEW_CARD}
      className='CreatePaymentInstrumentModal'
      Component={CreatePaymentInstrumentFormC}
      successFunction={successFunction}
      credentials={credentials}
      applicationId={applicationId}
      buyerIdentityId={buyerIdentityId}
      environment={environment}
      showTokenForm={showTokenForm}
      formName={formName}
      fieldName={fieldName}
    />
  )
}

CreatePaymentInstrumentModal.propTypes = {
  credentials: PropTypes.object,
  successFunction: PropTypes.func,
  applicationId: PropTypes.string,
  buyerIdentityId: PropTypes.string,
  environment: PropTypes.string,
  showTokenForm: PropTypes.bool,
}

export default CreatePaymentInstrumentModal
