import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { getApplicationsSelector } from 'state-layer/selectors'
import CreateOnboardingFormBasicsForm from 'components/Customer/Forms/CreateOnboardingFormBasicsForm/CreateOnboardingFormBasicsForm'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import { CREATE_ONBOARDING_FORM_BASICS_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import values from 'lodash/values'

import {
  GET_APPLICATIONS_F_REQUEST,
  GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const credentials = get(props, 'credentials')
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const applications = getApplicationsSelector(state)
  const formValues = getFormValues(CREATE_ONBOARDING_FORM_BASICS_FORM)(state) || get(state, 'flowsR.formValues.createOnboardingFormBasicsForm')
  const applicationId = get(formValues, 'applicationId.value') || get(getCurrentUser(), 'applicationId')

  const applicationsForInput = map(applications, (application) => {
    const {
      id,
      businessName,
    } = application

    return {
      label: businessName,
      value: id,
    }
  })

  return {
    credentials,
    isRolePlatformCredential,
    applicationsForInput,
    applicationId,
    initialValues: { country: USA, ...formValues },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplications: ({ credentials }) => dispatch({
      type: GET_APPLICATIONS_F_REQUEST,
      payload: {
        credentials,
        queries: {
          limit: 200,
        },
      },
    }),
    getDashboardCustomization: ({ entityId, successCallback }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: 'APPLICATION',
        },
      },
      meta: {
        successCallback,
      },
    }),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CreateOnboardingFormBasicsFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      isRolePlatformCredential,
      applicationId,
      getApplications,
      getDashboardCustomization,
    } = this.props

    // for role platform, fetch all related applications
    if (isRolePlatformCredential) {
      getApplications({ credentials })
    }

    // otherwise, fetch current applications dashboard branding
    if (applicationId) {
      getDashboardCustomization({
        entityId: applicationId,
        successCallback: this.fetchCustomizationSuccessCallback,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      applicationId,
      getDashboardCustomization,
    } = this.props

    const {
      applicationId: prevApplicationId,
    } = prevProps

    if (applicationId && applicationId !== prevApplicationId) {
      getDashboardCustomization({
        entityId: applicationId,
        successCallback: this.fetchCustomizationSuccessCallback,
      })
    }
  }

  fetchCustomizationSuccessCallback = ({ newValues }) => {
    const { dispatch } = this.props
    const response = values(newValues)[0]
    const [
      onboardingLinkExpiration,
      onboardingExpiredUrl,
      onboardingFeeUrl,
      onboardingTermsOfServiceUrl,
      onboardingReturnUrl,
    ] = getMany(response, [
      'onboardingLinkExpiration',
      'onboardingExpiredUrl',
      'onboardingFeeUrl',
      'onboardingTermsOfServiceUrl',
      'onboardingReturnUrl',
    ])
    dispatch(change(CREATE_ONBOARDING_FORM_BASICS_FORM, 'expirationTime', onboardingLinkExpiration))
    dispatch(change(CREATE_ONBOARDING_FORM_BASICS_FORM, 'feeUrl', onboardingFeeUrl))
    dispatch(change(CREATE_ONBOARDING_FORM_BASICS_FORM, 'returnUrl', onboardingReturnUrl))
    dispatch(change(CREATE_ONBOARDING_FORM_BASICS_FORM, 'termsOfServiceUrl', onboardingTermsOfServiceUrl))
    dispatch(change(CREATE_ONBOARDING_FORM_BASICS_FORM, 'expiredSessionUrl', onboardingExpiredUrl))
  }

  render() {
    return (
      <CreateOnboardingFormBasicsForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOnboardingFormBasicsFormC)
