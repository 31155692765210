import './TimeFieldsetS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import formatNumberField from 'utilities/forms/format/formatNumberField'

import {
  meridianOptions,
  settlementsTimeZoneOptions,
} from 'constants/timeConstants'

const TimeFieldset = ({
  name = 'time',
}) => {
  const fieldName = (field) => `${name}.${field}`

  return (
    <fieldset className='TimeFieldset'>
      <Field
        name={fieldName('hour')}
        component={InputField}
        className='hour'
        placeholder='HH'
        format={formatNumberField}
      />

      <div className='colon'>:</div>

      <Field
        name={fieldName('minute')}
        component={InputField}
        classNames='minutes'
        placeholder='MM'
        format={formatNumberField}
      />

      <Field
        name={fieldName('timeMeridian')}
        classNames={['time-meridian']}
        component={ReactSelect}
        options={meridianOptions}
      />

      <Field
        name={fieldName('timeZone')}
        classNames={['time-zone']}
        component={ReactSelect}
        options={settlementsTimeZoneOptions}
      />
    </fieldset>
  )
}

TimeFieldset.propTypes = {
  name: PropTypes.string,
}

export default TimeFieldset
