import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import SchedulesForm from './SchedulesForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { TEMPLATES_FORM_DATA_KEY } from 'constants/flowsConstants'
import { SCHEDULES_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const formSelector = formValueSelector(SCHEDULES_FORM)
  const templateNames = map(get(state, `flowsR.formValues.${TEMPLATES_FORM_DATA_KEY}.templates`), ({ name }) => name)
  const schedulesValues = formSelector(state, 'schedules')

  return {
    credentials,
    templateNames,
    schedulesValues,
  }
}

class SchedulesFormC extends Component {
  render() {
    return (
      <SchedulesForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(SchedulesFormC)
