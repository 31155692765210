import API from 'utilities/api/API'
import createUrl from 'utilities/create/createUrl'
import getGuestCheckoutHost from 'utilities/api/getGuestCheckoutHost'
import getGuestDashboardAxiosConfigs from './getGuestDashboardAxiosConfigs'

const guestCheckoutAPI = {
  get: ({ path, absolutePath, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getGuestCheckoutHost()}/${path}`,
      queries,
    })

    return API.get(url, getGuestDashboardAxiosConfigs())
  },

  post: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getGuestCheckoutHost()}/${path}`,
      queries,
    })

    return API.post(url, values, getGuestDashboardAxiosConfigs())
  },

  patch: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getGuestCheckoutHost()}/${path}`,
      queries,
    })

    return API.patch(url, values, getGuestDashboardAxiosConfigs())
  },

  put: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getGuestCheckoutHost()}/${path}`,
      queries,
    })

    return API.put(url, values, getGuestDashboardAxiosConfigs())
  },

  delete: ({ path, absolutePath, values, queries }) => {
    const url = absolutePath || createUrl({
      url: `${getGuestCheckoutHost()}/${path}`,
      queries,
    })

    return API.delete(url, values, getGuestDashboardAxiosConfigs())
  },
}

export default guestCheckoutAPI
