import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'

import {
  DASHBOARD_SERVICE_CONFIGURATIONS,
  DASHBOARD_SERVICE_CONFIGURATIONS_V2,
} from 'constants/apiConstants'

const getConfigurationsAPI = ({ meta }) => {
  const dashboardId = getCurrentDashboardId()
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_CONFIGURATIONS_V2({ dashboardId }) : DASHBOARD_SERVICE_CONFIGURATIONS

  return dashboardAPI.get({ path, meta })
}

export default getConfigurationsAPI
