import './CreateAPIKeyConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'

const CreateAPIKeyConfirmation = ({
  username = '',
  password = '',
}) => {
  return (
    <div className='CreateAPIKeyConfirmation'>
      <div className='section'>
        <div className='alert'>Please store the username and password. For security purposes the password will not be available after closing this dialog.</div>

        <div>
          <label className='label-2'>Username</label>
          <div className='username'>{username}</div>
        </div>

        <div>
          <label className='label-2'>Password</label>
          <div className='password'>{password}</div>
        </div>
      </div>
    </div>
  )
}

CreateAPIKeyConfirmation.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
}

export default CreateAPIKeyConfirmation
