import frontendReviewQueueItemModel from 'utilities/create/models/frontendReviewQueueItemModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendReviewQueueItemsModel = ({ data: reviewQueueItems }) => {
  const reviewQueueItemsModels = map(reviewQueueItems, (reviewQueueItem) => frontendReviewQueueItemModel({ data: reviewQueueItem }))

  return keyBy(reviewQueueItemsModels, 'id')
}

export default frontendReviewQueueItemsModel
