import { GET_DISBURSEMENT_RULES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getDisbursementRulesRequest = ({
  entityId,
  credentials,
}) => createAction({
  type: GET_DISBURSEMENT_RULES_F_REQUEST,
  queries: {
    linked_to: entityId,
  },
  credentials,
  meta: {
    showErrors: false,
    overwriteReducer: true,
  },
})

export default getDisbursementRulesRequest
