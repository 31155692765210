import getPayoutPlanAPI from 'api/finix/get/getPayoutPlanAPI'
import getPayoutPlansAPI from 'api/finix/get/getPayoutPlansAPI'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getTemplatesAPI from 'api/finix/get/getPayoutPlanTemplatesAPI'
import getCombinationPlansAPI from 'api/finix/get/getPayoutPlanCombinationPlansAPI'
import getMerchantPayoutSettingsAPI from 'api/finix/get/getMerchantPayoutSettingsAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import { getState } from 'state-layer/configureStore'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import _values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import co from 'co'

import {
  MERCHANT,
  APPLICATION,
} from 'constants/payoutPlanConstants'

import {
  getMerchantSelector,
  getApplicationSelector,
} from 'state-layer/selectors'

function * getPayoutPlanO ({
  id,
  credentials,
  payoutPlan,
  merchantId,
}) {
  let payoutPlanId
  let payoutPlanResponseData
  // fetches payout plan + templates/combination plans given payout plan ID
  if (id) {
    const payoutPlanResponse = yield getPayoutPlanAPI({ id, credentials })

    payoutPlanResponseData = get(payoutPlanResponse, 'data')
    payoutPlanId = get(payoutPlanResponseData, 'id')

  // fetches payout plan + templates/combination plans  given merchant ID
  } else if (merchantId) {
    let payoutPlansResponse

    if (isRolePlatform({ credentials })) {
      payoutPlansResponse = yield getPayoutPlansAPI({
        queries: { entity_id: merchantId },
        credentials,
      })

      payoutPlanResponseData = get(payoutPlansResponse, 'data._embedded.payout_plans.0')
    } else {
      payoutPlansResponse = yield getMerchantPayoutSettingsAPI({
        id: merchantId,
        credentials,
      })

      payoutPlanResponseData = get(payoutPlansResponse, 'data')
    }

    payoutPlanId = get(payoutPlanResponseData, 'id')

  // fetches payout plan + templates/combination plans given payout plan object
  } else {
    payoutPlanResponseData = payoutPlan
    payoutPlanId = get(payoutPlan, 'id')
  }

  if (isEmpty(payoutPlanResponseData)) return {}

  let merchantResponse
  let applicationResponse
  let templates
  let combinationPlans

  if (isRolePlatform({ credentials })) {
    const [
      { data: templatesResponse },
      { data: combinationPlansResponse },
    ] = yield [
      getTemplatesAPI({ payoutPlanId, credentials }),
      getCombinationPlansAPI({ payoutPlanId, credentials }),
    ]

    // remove null subtypes + prevent issue where null fee is converted to '$0.00' due to frontendNormalizationMap
    templates = _values(removeUndefined(get(templatesResponse, '_embedded.payout_plan_templates')))
    combinationPlans = get(combinationPlansResponse, '_embedded.combination_plans')

    const [level, entityId] = getMany(payoutPlanResponseData, ['level', 'entity_id'])
    const state = getState()


    if (level === MERCHANT) {
      const merchant = getMerchantSelector(state, entityId)

      merchantResponse = isEmpty(merchant) ? get(yield getMerchantAPI({
        id: entityId,
        credentials,
      }), 'data') : {}
    }

    if (level === MERCHANT || level === APPLICATION) {
      const applicationId = level === APPLICATION ? entityId : get(merchantResponse, 'application')
      const application = getApplicationSelector(state)

      applicationResponse = isEmpty(application) && applicationId ? get(yield getApplicationAPI({
        id: applicationId,
        credentials,
      }), 'data') : {}
    }
  }

  const payoutPlanCombined = merge({}, payoutPlanResponseData, {
    templates,
    combination_plans: combinationPlans,
    merchant: merchantResponse,
    application: applicationResponse,
  })

  return createSuccessResponse({
    data: [payoutPlanCombined],
  })
}

export default co.wrap(getPayoutPlanO)
