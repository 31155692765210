import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditRecipientIdentityContactForm from './EditRecipientIdentityContactForm'
import { getIdentitySelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const identityId = get(props, 'params.identityId') || get(props, 'identityId')
  const recipientIdentity = getIdentitySelector(state, identityId)

  const [
    displayFirstName,
    displayLastName,
    displayEmail,
    phoneNumber,
    line1,
    line2,
    city,
    region,
    postalCode,
    country,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
  ] = getMany(recipientIdentity, [
    'firstName',
    'lastName',
    'email',
    'displayPhone',
    'personalAddress.line1',
    'personalAddress.line2',
    'personalAddress.city',
    'personalAddress.region',
    'personalAddress.postalCode',
    'personalAddress.country',
    'businessName',
    'doingBusinessAs',
    'businessPhone',
    'businessAddress',
  ])

  const initialValues = isEmpty(recipientIdentity) ? null : {
    firstName: displayFirstName,
    lastName: displayLastName,
    email: displayEmail,
    phone: phoneNumber,
    personalAddress: {
      line1,
      line2,
      city,
      region,
      postalCode,
      country,
    },
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
  }

  return {
    credentials,
    credentialId,
    identityId,
    recipientIdentity,
    initialValues,
    patching: isPatching(state),
    showBusinessInformation: !!businessName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}

class EditRecipientIdentityContactFormC extends Component {
  componentDidMount() {
    const {
      identityId,
      credentials,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  render() {
    return (
      <EditRecipientIdentityContactForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRecipientIdentityContactFormC)
