import selectOption from 'utilities/forms/selectOption'
import map from 'lodash/map'

const visaAllowedBusinessApplicationIdsList = {
  // 'AA': 'Account to Account',
  // 'BB': 'Business to Business',
  // 'BI': 'Money Transfer (bank initiated)',
  // 'CP': 'Card Bill Payment',
  'FD': 'Funds Disbursement (general)',
  // 'FT': 'Funds Transfer',
  // 'GD': 'Government Disbursement',
  // 'GP': 'Gambling Payment (other than online gambling)',
  // 'LO': 'Loyalty and Offers',
  // 'BP': 'Non Card Bill Payment',
  // 'MI': 'Merchant Initiated OCT for Faster Refund',
  // 'RP': 'Request-to-Pay Service',
  // 'CI': 'Cash In',
  // 'CO': 'Cash Out',
  // 'MP': 'Face-to-face Merchant Payment',
  'MD': 'Merchant Disbursement',
  // 'OG': 'Online Gambling Payout',
  // 'PD': 'Payroll/pension disbursement',
  'PP': 'Person to Person',
  // 'TU': 'Prepaid Reload/Top Up',
  // 'WT': 'Wallet Transfer',
  // 'PS': 'Payment for goods and services',
  // 'CD': 'Cash Deposit',
}

const currenciesList = {
  'USD' : 'United States Dollar',
  'CAD': 'Canadian Dollar',
}

export const visaAllowedBusinessApplicationIdsSelectOptions = map(visaAllowedBusinessApplicationIdsList, (value, key) => selectOption(`${key} - ${value}`, key))
export const currenciesListOptions = map(currenciesList, (value, key) => selectOption(`${key} - ${value}`, key))
