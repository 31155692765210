import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingApplications from 'components/Customer/Pages/UnderwritingApplications/UnderwritingApplications'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import createUrl from 'utilities/create/createUrl'
import { FETCHING } from 'constants/reducerConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { MANUAL_REVIEW } from 'constants/underwritingReviewConstants'
import { ONBOARDING_APPLICATION_PATH } from 'constants/pathConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'

import {
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  SORT,
  LIMIT,
  OFFSET,
  createdAtNoDefaultQueries,
} from 'constants/queryConstants'

import {
  REVIEW_ID,
  RUN_ID,
  UNDERWRITING_BUSINESS_TYPE,
  BUSINESS_NAME_EXACT,
  DOING_BUSINESS_AS_EXACT,
  ASSIGNEE,
  RECOMMENDATION,
  COUNTRY,
} from 'constants/filterConstants'

const initialQueries = {
  sort: 'updated_at,desc',
}

const quickFilters = [
  UNDERWRITING_REVIEW_STATE_QUICK_FILTER,
  UNDERWRITING_REVIEW_DATES_QUICK_FILTER,
]

const allowedFilters = [
  ASSIGNEE,
  BUSINESS_NAME_EXACT,
  UNDERWRITING_BUSINESS_TYPE,
  DOING_BUSINESS_AS_EXACT,
  // RECOMMENDATION,
  REVIEW_ID,
  RUN_ID,
  COUNTRY,
]

const initialFilters = { state: { eq: MANUAL_REVIEW } }

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const queries = get(props, 'queries', {})
  const limit = get(state, LIMIT)
  const offset = get(state, OFFSET)
  const sort = get(state, SORT)
  const mergedQueries = merge({}, initialQueries, queries)

  const underwritingReviewPath = ({ id, subjectId, entityId, entityType }) => createUrl({
    url: ONBOARDING_APPLICATION_PATH({ credentialId, reviewId: id }),
    queries: removeUndefined({
      subjectId,
      entityId,
      entityType,
      tab: 'underwriting',
    }),
  })

  return {
    initialQueries: mergedQueries,
    isFetching,
    underwritingReviewPath,
    limit,
    offset,
    sort,
    credentials,
    currentUserEmail,
    quickFilters,
    allowedFilters,
    initialFilters,
  }
}

class UnderwritingApplicationsC extends Component {
  render() {
    return (
      <UnderwritingApplications
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UnderwritingApplicationsC)
