import './AddRecipientFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import AddressC from 'components/Shared/Inputs/Address/Address'
import { goBack } from 'state-layer/history'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import PaymentInstrumentFieldset from 'components/Shared/Fieldsets/PaymentInstrumentFieldset/PaymentInstrumentFieldset'
import submitAddRecipientForm from 'utilities/submit/submitAddRecipientForm'
import validateAddRecipientForm from 'utilities/validate/validateAddRecipientForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { ADD_NEW_RECIPIENT_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'
import { TOOLTIP_ICON } from 'constants/iconConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  CANCEL,
  ADD_RECIPIENT,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
  BUSINESS_ADDRESS,
  BUSINESS_DETAILS,
  ADD_NEW_RECIPIENT,
  PERSONAL_ADDRESS,
  RECIPIENT_DETAILS,
  BUSINESS_RECIPIENT,
  RECIPIENT_TYPE,
  PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  RECIPIENT_VALIDATION_MESSAGE,
  INDIVIDUAL_RECIPIENT,
} from 'constants/language/languageConstants'

const recipientTypeOptions = [
  { value: 'personal', label: INDIVIDUAL_RECIPIENT },
  { value: 'business', label: BUSINESS_RECIPIENT },
]

const AddRecipientForm = ({
  handleSubmit = () => {},
  submitting = false,
  invalid = false,
  showBusinessInformation = false,
  disableTypeField = false,
}) => {
  return (
    <form className='AddRecipientForm' onSubmit={handleSubmit(submitAddRecipientForm)}>
      <div className='form-header'>
        <h3 className='title'>{ADD_NEW_RECIPIENT}</h3>
      </div>
      <div className='form-content'>
        <div className='info-message'>
          <div className='flex'>
            <i className={`fa fa-${TOOLTIP_ICON}`} />
            <div className='description'>{RECIPIENT_VALIDATION_MESSAGE}</div>
          </div>
        </div>
        <div className='contact-details'>
          <h5>{RECIPIENT_DETAILS}</h5>
          <Field
            name='type'
            label={RECIPIENT_TYPE}
            component={ReactSelect}
            options={recipientTypeOptions}
            isDisabled={disableTypeField}
          />
          <div className='name flex space-between'>
            <Field
              name='firstName'
              label={FIRST_NAME}
              component={InputField}
            />
            <Field
              name='lastName'
              label={LAST_NAME}
              component={InputField}
            />
          </div>
          <div className='contact-info flex space-between'>
            <Field
              name='email'
              label={EMAIL}
              component={InputField}
              required={false}
            />
            <Field
              name='phone'
              label={PHONE_NUMBER}
              component={InputField}
              format={formatPhone}
              parse={parsePhone}
              required={false}
            />
          </div>

          <AddressC
            name='personalAddress'
            label={PERSONAL_ADDRESS}
            helperText={{ addressLine1HelperText: '' }}
            form={ADD_NEW_RECIPIENT_FORM}
            presetCountry={USA}
          />
        </div>

        {showBusinessInformation && (
        <div className='business-info'>
          <h5>{BUSINESS_DETAILS}</h5>
          <Field
            name='businessName'
            label={BUSINESS_NAME}
            component={InputField}
          />

          <Field
            name='doingBusinessAs'
            label={DOING_BUSINESS_AS}
            component={InputField}
          />

          <Field
            name='businessPhone'
            label={BUSINESS_PHONE}
            component={InputField}
            format={formatPhone}
            parse={parsePhone}
            required={false}
          />

          <AddressC
            name='businessAddress'
            label={BUSINESS_ADDRESS}
            helperText={{ addressLine1HelperText: '' }}
            form={ADD_NEW_RECIPIENT_FORM}
            presetCountry={USA}
          />
        </div>
        )}

        <div className='payment-instrument-section'>
          <h5>{PAYMENT_INSTRUMENT_RESOURCE_TITLE}</h5>
          <PaymentInstrumentFieldset
            disableCountry
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' size='large' />
          <Button className='submit-button' submitting={submitting} disabled={invalid} label={ADD_RECIPIENT} variant='primary' type='submit' size='large' />
        </div>
      </div>
    </form>
  )
}

AddRecipientForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  showBusinessInformation: PropTypes.bool,
}

export default reduxForm({
  form: ADD_NEW_RECIPIENT_FORM,
  validate: validateAddRecipientForm,
})(AddRecipientForm)
