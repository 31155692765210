import isPlainObject from 'lodash/isPlainObject'

const removeUndefined = (obj, allowNull = false) => {
  const newObj = {}

  Object.keys(obj).forEach(key => {
    const val = obj[key]

    if (val !== undefined && (allowNull ? true : val !== null)) {
      if (isPlainObject(val)) {
        newObj[key] = removeUndefined(val, allowNull)
      } else {
        newObj[key] = val
      }
    }
  })

  return newObj
}

export default removeUndefined
