import './ReportsTableS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getReportsSelector } from 'state-layer/selectors'
import columnDescriptors from './columnDescriptors'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { REPORTS } from 'constants/linkConstants'
import { GET_REPORTS } from 'constants/flowConstants'
import { ALL_REPORTS_LABEL } from 'constants/language/languageConstants'
import { TOP_LEVEL_REPORT_TYPE_URL } from 'constants/urlConstants'
import isEmpty from 'lodash/isEmpty'

const ReportsTable = ({
  allowedFilters,
  initialQueries,
  isFetching = false,
  reportType = '',
  description = '',
  learnMoreLink = TOP_LEVEL_REPORT_TYPE_URL,
  selectedItemsKey = '',
  selectedItemsAction = [],
  totalSelectedReports = 0,
  showCheckboxes = false,
  selectedItemsData = {},
  contextBarData = {},
}) => {
  const reportTitle = isEmpty(reportType) ? ALL_REPORTS_LABEL : reportType
  const reportSubTitle = !isEmpty(reportType) ? description : undefined
  const reportLearnMoreLink = !isEmpty(reportType) ? learnMoreLink : undefined
  return (
    <div className='ReportsTable'>
      {showCheckboxes && <ContextBar {...contextBarData} />}

      <HeaderV2C
        title={reportTitle}
        subTitle={reportSubTitle}
        learnMoreLink={reportLearnMoreLink}
      />

      <TableC
        flow={GET_REPORTS}
        columnDescriptors={columnDescriptors}
        selector={getReportsSelector}
        initialQueries={initialQueries}
        linksKey={REPORTS}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        selectedItemsKey={selectedItemsKey}
        selectedItemsAction={showCheckboxes ? selectedItemsAction : undefined}
        selectedItemsData={selectedItemsData}
      />
    </div>
  )
}

ReportsTable.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  reportType: PropTypes.string,
  learnMoreLink: PropTypes.string,
  selectedItemsKey: PropTypes.string,
  selectedItemsAction: PropTypes.array,
  totalSelectedReports: PropTypes.number,
  showCheckboxes: PropTypes.bool,
  selectedItemsData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default ReportsTable
