import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoleMembers from './RoleMembers'
import columnDescriptors from './columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { MEMBERS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  DEACTIVATE_TEAM_MEMBER_MODAL,
  REINSTATE_TEAM_MEMBER_MODAL,
  RESEND_DASHBOARD_INVITE_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `membersR.${FETCHING}`)
  const credentialId = get(credentials, 'id')
  const memberPath = MEMBERS_PATH({ credentialId })
  const [
    roleId,
    entityType,
    entityId,
  ] = getMany(props, [
    'roleId',
    'entityType',
    'entityId',
  ])

  const flowValues = removeUndefined({
    entityId: roleId,
    entityType: 'ROLE',
  })

  const extraProps = {
    entityType,
    entityId,
  }

  return {
    credentials,
    isFetching,
    flowValues,
    entityDataFound: !isEmpty(roleId),
    memberPath,
    extraProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showResendDashboardInviteModal: (modalProps) => dispatch(showModalAction({ modalType: RESEND_DASHBOARD_INVITE_MODAL, modalProps })),
    showDeactivateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: DEACTIVATE_TEAM_MEMBER_MODAL, modalProps })),
    showReinstateTeamMemberModal: (modalProps) => dispatch(showModalAction({ modalType: REINSTATE_TEAM_MEMBER_MODAL, modalProps })),
  }
}

class RoleMembersC extends Component {
  render() {
    const {
      showResendDashboardInviteModal,
      showDeactivateTeamMemberModal,
      showReinstateTeamMemberModal,
    } = this.props

    const columnActions = {
      showResendDashboardInviteModal,
      showDeactivateTeamMemberModal,
      showReinstateTeamMemberModal,
    }

    const normalizedColumnDescriptors = columnDescriptors({ columnActions })

    return (
      <RoleMembers
        {...this.props}
        columnDescriptors={normalizedColumnDescriptors}
      />
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleMembersC)
