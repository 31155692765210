import React, { Component } from 'react'
import { connect } from 'react-redux'
import DownloadVerificationFileForm from './DownloadVerificationFileForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'

import {
  SANDBOX_QA_APPLE_PAY_VERIFICATION_FILE_PATH,
  PROD_APPLE_PAY_VERIFICATION_FILE_PATH,
} from 'constants/mobileWalletConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const environment = get(credentials, 'environment')
  const verificationFile = environment === LIVE_ENVIRONMENT ? PROD_APPLE_PAY_VERIFICATION_FILE_PATH : SANDBOX_QA_APPLE_PAY_VERIFICATION_FILE_PATH

  return {
    credentials,
    environment,
    verificationFile,
  }
}

class DownloadVerificationFileFormC extends Component {
  render() {
    return (
      <DownloadVerificationFileForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(DownloadVerificationFileFormC)
