import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  DISPUTES,
  DISPUTE,
} from 'constants/apiConstants'

const getDisputeAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: DISPUTE({ disputeId: id }),
    credentials,
    service: DISPUTES,
  })
}

export default getDisputeAPI
