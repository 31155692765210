import { GET_MONITORING_ALERT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMonitoringAlertRequest = ({
  monitoringAlertId,
  credentials,
}) => createAction({
  type: GET_MONITORING_ALERT_F_REQUEST,
  id: monitoringAlertId,
  credentials,
})

export default getMonitoringAlertRequest
