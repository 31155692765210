import React, { Component } from 'react'
import { connect } from 'react-redux'
import Identity from './Identity'
import { getIdentitySelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import IdentityInfoC from 'components/Customer/Pages/Identity/IdentityInfo/IdentityInfoC'
import IdentityAuditLogsC from 'components/Customer/Pages/Identity/IdentityAuditLogs/IdentityAuditLogsC'
import RecipientDetailC from 'components/Customer/Pages/Recipient/RecipientDetail/RecipientDetailC'
import IdentityTransactionsTabs from 'components/Shared/Tabs/IdentityTransactionsTabs'
import IdentityPayoutsTabs from 'components/Shared/Tabs/IdentityPayoutsTabs'
import IdentityExceptionsTabs from 'components/Shared/Tabs/IdentityExceptionsTabs'
import IdentityPaymentInstrumentsC from 'components/Customer/Pages/Identity/IdentityPaymentInstruments/IdentityPaymentInstrumentsC'
import IdentityMerchantsC from 'components/Customer/Pages/Identity/IdentityMerchants/IdentityMerchantsC'
import AltPaymentMethodsC from 'components/Customer/Pages/AltPaymentMethods/AltPaymentMethodsC'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import IdentityPaymentsC from 'components/Customer/Pages/Identity/IdentityPayments/IdentityPaymentsC'
import TeamTabs from 'components/Shared/Tabs/TeamTabs'
import showModalAction from 'utilities/actions/showModalAction'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hasPermission from 'utilities/hasPermission'
import getMany from 'utilities/get/getMany'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { isFlexPlatform } from 'constants/flexConstants'
import { hasTransferEnabledMerchants } from 'constants/adjustmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import { UNDERWRITING_ENABLED_FINIX_EMPLOYEES } from 'constants/underwritingConstants'
import { ELAVON_V1 } from 'constants/processorConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import map from 'lodash/map'
import difference from 'lodash/difference'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'

import {
  FEE_CREATE_PERMISSION,
  ADJUSTMENT_CREATE_PERMISSION,
} from 'constants/permissionConstants'

import {
  hasPartnerAccess,
  hasPlatformAccess,
  isRolePlatform,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  EDIT_IDENTITY_PATH,
  EDIT_IDENTITY_PATH_NEW,
  EDIT_RECIPIENT_IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  EDIT_ICON,
} from 'constants/iconConstants'

import {
  INVITE_USER,
  CREATE_ADJUSTMENT,
  CREATE_FEE,
} from 'constants/modalConstants'

import {
  SETTLEMENT_ALIAS_DISPLAY_MAPPING,
  SETTLEMENT_ALIASES_BE,
} from 'constants/paymentInstrumentConstants'

import {
  SELLER_IDENTITY_ROLE,
  RECIPIENT_IDENTITY_ROLE,
} from 'constants/identityConstants'

import {
  SYSTEM,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  MERCHANT_IDENTITY,
  APPLICATION_RESOURCE_TITLE,
  IDENTITY_RESOURCE_TITLE,
  DOING_BUSINESS_AS,
  BUSINESS_INFO,
  CREATED_ON,
  TRANSACTIONS,
  PAYOUTS,
  MERCHANT_ACCOUNTS,
  ALT_PAYMENT_METHODS,
  EDIT_IDENTITY,
  CREATE_MERCHANT_ADJUSTMENT,
  CREATE_MERCHANT_FEE,
  EXCEPTIONS,
  PAYMENT_INSTRUMENTS,
  TEAM,
  HISTORY,
  COUNTRY,
  DETAILS,
  RECIPIENT_IDENTITY,
  PERSONAL,
  BUSINESS,
  EDIT_RECIPIENT_CONTACT_DETAILS,
  AUDIT_LOG,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)
  const isPayout = isPayoutFeature({ credentials })
  const currentUserEmail = get(getCurrentUser(state), 'email')
  const underwritingEnabled = includes(UNDERWRITING_ENABLED_FINIX_EMPLOYEES, currentUserEmail)
  const isFlex = isFlexPlatform()
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()

  const [
    credentialId,
    dashboardUserRoleName,
    dashboardUserRoleType,
  ] = getMany(credentials, [
    'id',
    'dashboardUserRoleName',
    'dashboardUserRoleType',
  ])

  const identityMerchants = get(identity, 'merchants', {})
  const hasIdentityMerchants = !isEmpty(identityMerchants)
  const allowAdjustment = hasTransferEnabledMerchants(identityMerchants)
  const enabledProcessors = map(identityMerchants, ({ processor }) => processor)
  const elavonEnabled = includes(enabledProcessors, ELAVON_V1)
  const activeSettlementAliases = Object.keys(get(identity, 'payoutPlanInstrumentMapping', {}))
  const missingSettlementAliases = difference(SETTLEMENT_ALIASES_BE, activeSettlementAliases)
  const canCreateFee = hasPermission(state, FEE_CREATE_PERMISSION)
  const canCreateAdjustment = hasPermission(state, ADJUSTMENT_CREATE_PERMISSION)

  const [
    applicationName,
    applicationId,
    doingBusinessAs,
    businessName,
    displayCreatedAt,
    displayBusinessName,
    resourceTitle,
    id,
    identityRoles,
    underwritingReviews,
    country,
  ] = getMany(identity, [
    'application.businessName',
    'applicationId',
    'doingBusinessAs',
    'businessName',
    'displayCreatedAt',
    'displayBusinessName',
    'resourceTitle',
    'id',
    'identityRoles',
    'underwritingReviews',
    'country',
  ])

  const orderedUnderwritingReviews = orderBy(underwritingReviews, ['created_at'], ['desc'])
  const latestReview = get(orderedUnderwritingReviews, '[0]')
  const underwritingReviewSubjectId = get(latestReview, 'subjectId')

  const hasSellerRole = includes(identityRoles, SELLER_IDENTITY_ROLE)
  const hasRecipientRole = includes(identityRoles, RECIPIENT_IDENTITY_ROLE)

  const settlementAliasWarningMessage = () => (
    <div className='settlement-alias-warning flex column'>
      <span>The following settlement {missingSettlementAliases.length > 1 ? 'aliases' : 'alias' } must be assigned a bank account before funding and payouts will work for this merchant:</span>
      { map(missingSettlementAliases, (settlementAlias) => (<div>&#8226; {SETTLEMENT_ALIAS_DISPLAY_MAPPING[settlementAlias]}</div>)) }
    </div>
  )

  const settlementAliasWarningObject = {
    message: settlementAliasWarningMessage,
    condition: elavonEnabled && !isEmpty(missingSettlementAliases) && !isFetching,
  }

  let tabs = [
    {
      id: 'business-info',
      name: BUSINESS_INFO,
      component: IdentityInfoC,
      props: {
        isPayout,
        applicationId,
        underwritingReviews,
        underwritingReviewSubjectId,
      },
    },
    {
      id: 'identity-merchant-accounts',
      name: MERCHANT_ACCOUNTS,
      component: IdentityMerchantsC,
      props: {
        elavonEnabled,
        settlementAliasWarningObject,
      },
    },
    {
      id: 'transactions',
      name: TRANSACTIONS,
      component: IdentityTransactionsTabs,
      condition: !isStandaloneUnderwriting,
    },
    {
      id: 'payouts',
      name: PAYOUTS,
      component: IdentityPayoutsTabs,
      condition: !isPayout && !isStandaloneUnderwriting,
      props: {
        identityId: id,
      },
    },
    {
      id: 'exceptions',
      name: EXCEPTIONS,
      component: IdentityExceptionsTabs,
      condition: !isPayout && !isStandaloneUnderwriting,
      props: {
        identityId,
      },
    },
    {
      id: 'payment-instruments',
      name: PAYMENT_INSTRUMENTS,
      component: IdentityPaymentInstrumentsC,
      props: {
        identityId,
      },
    },
    {
      id: 'alt-payment-methods',
      name: ALT_PAYMENT_METHODS,
      component: AltPaymentMethodsC,
      condition: () => { return hasPartnerAccess({ credentials }) && !isPayout && !isStandaloneUnderwriting },
      props: {
        identityId,
      },
    },
    {
      id: 'team',
      name: TEAM,
      component: TeamTabs,
      condition: hasIdentityMerchants && !isStandaloneUnderwriting,
      props: {
        entityId: identityId,
        entityType: 'IDENTITY',
      },
    },
    {
      id: 'audit-log',
      name: AUDIT_LOG,
      component: IdentityAuditLogsC,
      condition: isFlex && isRolePlatform({ credentials }) && hasSellerRole && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
      props: {
        identityId,
        underwritingReviewSubjectId,
        underwritingReviews,
      },
    },
  ]

  if (hasRecipientRole) {
    tabs = [
      {
        id: 'details',
        name: DETAILS,
        component: RecipientDetailC,
        props: {
          identityId,
          underwritingReviews,
        },
      },
      {
        id: 'payment-instruments',
        name: PAYMENT_INSTRUMENTS,
        component: IdentityPaymentInstrumentsC,
      },
      {
        id: 'payouts',
        name: PAYOUTS,
        component: IdentityPaymentsC,
      },
      {
        id: 'identity-merchant-accounts',
        name: MERCHANT_ACCOUNTS,
        component: IdentityMerchantsC,
        props: {
          elavonEnabled,
          settlementAliasWarningObject,
        },
      },
      {
        id: 'audit-log',
        name: AUDIT_LOG,
        component: IdentityAuditLogsC,
        condition: isFlex && isRolePlatform({ credentials }) && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
        props: {
          identityId,
          underwritingReviewSubjectId,
          underwritingReviews,
        },
      },
    ]
  }

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
    ],
  }

  const headerLabel = hasSellerRole ? MERCHANT_IDENTITY : (hasRecipientRole ? RECIPIENT_IDENTITY : IDENTITY_RESOURCE_TITLE)
  const headerBadge = !hasRecipientRole ? null : (get(identity, 'businessName') ? BUSINESS : PERSONAL)

  const headerData = {
    resource: {
      label: headerLabel,
      id: identityId,
      badge: headerBadge,
    },
    items: [
      {
        value: <h1>{resourceTitle}</h1>,
      },
      {
        label: DOING_BUSINESS_AS,
        value: doingBusinessAs,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: COUNTRY,
        value: CountryFlagIcon({ country }),
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    identityId,
    businessName,
    displayBusinessName,
    identity,
    credentials,
    credentialId,
    tabs,
    allowAdjustment,
    applicationId,
    hasIdentityMerchants,
    identityMerchants,
    underwritingEnabled,
    isFlex,
    contextBarData,
    headerData,
    canCreateFee,
    canCreateAdjustment,
    isStandaloneUnderwriting,
    hasRecipientRole,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
    showInviteUserModal: (modalProps) => dispatch(showModalAction({ modalType: INVITE_USER, modalProps })),
    showCreateAdjustmentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_ADJUSTMENT, modalProps })),
    showCreateFeeModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_FEE, modalProps })),
  }
}

class IdentityC extends Component {
  componentDidMount() {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { identityId, getIdentity, credentials } = this.props
    const { identityId: prevIdentityId } = prevProps

    if (identityId !== prevIdentityId && identityId) {
      getIdentity({ identityId, credentials })
    }
  }

  render() {
    const {
      showCreateAdjustmentModal,
      showCreateFeeModal,
      identityId,
      credentialId,
      allowAdjustment,
      credentials,
      hasIdentityMerchants,
      identityMerchants,
      isFlex,
      canCreateFee,
      canCreateAdjustment,
      isStandaloneUnderwriting,
      hasRecipientRole,
    } = this.props

    const actions = [
      {
        label: EDIT_IDENTITY,
        link: EDIT_IDENTITY_PATH({ credentialId, identityId }),
        buttonIcon: `fal fa-${EDIT_ICON}`,
        className: 'large edit-identity-button',
        condition: !isFlex && !hasRecipientRole,
      },
      {
        label: EDIT_IDENTITY,
        link: EDIT_IDENTITY_PATH_NEW({ credentialId, identityId }),
        buttonIcon: `fal fa-${EDIT_ICON}`,
        className: 'large edit-identity-button',
        condition: isFlex && !hasRecipientRole,
      },
      {
        label: CREATE_MERCHANT_ADJUSTMENT,
        action: () => {
          showCreateAdjustmentModal({
            identityId,
          })
        },
        className: 'create-merchant-adjustment',
        condition: canCreateAdjustment && allowAdjustment && hasPlatformAccess({ credentials }) && !isStandaloneUnderwriting,
      },
      {
        label: CREATE_MERCHANT_FEE,
        action: () => {
          showCreateFeeModal({
            identityMerchants,
            credentials,
          })
        },
        className: 'create-merchant-fee',
        condition: canCreateFee && hasIdentityMerchants && hasPlatformAccess({ credentials }) && !isStandaloneUnderwriting,
      },
      {
        label: EDIT_RECIPIENT_CONTACT_DETAILS,
        action: () => goToPath({
          pathname: EDIT_RECIPIENT_IDENTITY_PATH({ credentialId, recipientId: identityId }),
        }),
        condition: hasRecipientRole,
      },
    ]

    return (
      <Identity
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityC)
