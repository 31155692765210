import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  DISBURSEMENT_RULE,
  DISBURSEMENT_RULES,
} from 'constants/apiConstants'

const patchDisbursementRulesAPI = ({ values, credentials, meta, id }) => {
  const path = DISBURSEMENT_RULE({ ruleId: get(values, 'id', id) })

  return paymentsAPI.patch({
    values,
    credentials,
    path,
    meta,
    service: DISBURSEMENT_RULES,
  })
}

export default patchDisbursementRulesAPI
