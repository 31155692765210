import './LevelThreeItemsS.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import stripNonNumericChars from 'utilities/string/stripNonNumericChars'
import { USD } from 'constants/currencyConstants'
import { UNIT_OF_MEASURE_OPTIONS } from 'constants/measurementConstants'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'

import {
  QUANTITY,
  DELETE_ITEM,
  ADD_ANOTHER_ITEM,
  MERCHANT_PRODUCT_CODE,
  PRODUCT_DESCRIPTION,
  COST_PER_UNIT,
  DISCOUNT_PER_UNIT,
  UNIT_OF_MEASURE,
  ITEM_INFORMATION,
  TOTAL_ITEM_COST,
  EXCLUDING_SALES_TAX,
  INCLUDING_SALES_TAX,
  COMMODITY_CODE,
  DISCOUNT_PER_UNIT_TOOLTIP_MSG,
  TOTAL_ITEM_COST_INCLUDING_SALES_TAX_TOOLTIP_MSG,
  COMMODITY_CODE_TOOLTIP_MSG,
} from 'constants/language/languageConstants'

import {
  ADD_ICON,
  TRASH_ALT_ICON,
} from 'constants/iconConstants'

const LevelThreeItems = ({
  fields = [],
  levelThreeItems = [],
}) => {
  useEffect(() => {
    if (isEmpty(levelThreeItems)) {
      fields.push({})
    }
  }, [])

  return (
    <div className='LevelThreeItems'>
      { fields.map((item, index) => {
        const key = `item_${index}`

        return (
          <div key={key} className='item'>
            <h6>{ITEM_INFORMATION}</h6>

            <Field
              name={`${item}.merchantProductCode`}
              label={MERCHANT_PRODUCT_CODE}
              placeholder='123456'
              component={InputField}
            />

            <Field
              name={`${item}.productDescription`}
              label={PRODUCT_DESCRIPTION}
              placeholder='Product or service'
              component={InputField}
            />

            <AmountField
              name={`${item}.costPerUnit`}
              label={COST_PER_UNIT}
              placeholder='0.00'
              contextLabel={USD}
            />


            <AmountField
              name={`${item}.discountPerUnit`}
              label={DISCOUNT_PER_UNIT}
              placeholder='0.00'
              contextLabel={USD}
              tooltip={DISCOUNT_PER_UNIT_TOOLTIP_MSG}
              tooltipPosition='right'
            />

            <div className='flex space-between'>
              <Field
                name={`${item}.quantity`}
                label={QUANTITY}
                placeholder={QUANTITY}
                component={InputField}
                format={stripNonNumericChars}
              />

              <Field
                name={`${item}.unitOfMeasure`}
                label={UNIT_OF_MEASURE}
                component={ReactSelect}
                options={UNIT_OF_MEASURE_OPTIONS}
              />
            </div>

            <AmountField
              name={`${item}.totalItemCostExcludingSalesTax`}
              label={`${TOTAL_ITEM_COST} (${EXCLUDING_SALES_TAX})`}
              placeholder='0.00'
              contextLabel={USD}
            />

            <AmountField
              name={`${item}.totalItemCostIncludingSalesTax`}
              label={`${TOTAL_ITEM_COST} (${INCLUDING_SALES_TAX})`}
              placeholder='0.00'
              contextLabel={USD}
              tooltip={TOTAL_ITEM_COST_INCLUDING_SALES_TAX_TOOLTIP_MSG}
              tooltipPosition='right'
            />

            <Field
              name={`${item}.commodityCode`}
              label={COMMODITY_CODE}
              placeholder='000000'
              component={InputField}
              tooltip={COMMODITY_CODE_TOOLTIP_MSG}
              tooltipPosition='right'
            />

            {size(fields) > 1 && (
              <div className='delete-item flex' onClick={() => fields.remove(index)}>
                <i className={`fal fa-${TRASH_ALT_ICON}`} />
                <span>{DELETE_ITEM}</span>
              </div>
            )}
          </div>
        )
      })}

      <div className='add-item flex' onClick={() => fields.push({})}>
        <i className={`fal fa-${ADD_ICON}`} />
        <span>{ADD_ANOTHER_ITEM}</span>
      </div>

    </div>
  )
}

LevelThreeItems.propTypes = {
  fields: PropTypes.object,
  levelThreeItems: PropTypes.array,
}

export default LevelThreeItems
