import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CreateAdjustmentFormC from 'components/Customer/Forms/CreateAdjustmentForm/CreateAdjustmentFormC'

const CreateAdjustmentModal = ({
  identityId,
}) => {
  return (
    <GenericModal
      title='Create Merchant Adjustment'
      className='CreateAdjustmentModal'
      Component={CreateAdjustmentFormC}
      submitLabel='Create'
      identityId={identityId}
    />
  )
}

CreateAdjustmentModal.propTypes = {
  identityId: PropTypes.string,
}

export default CreateAdjustmentModal
