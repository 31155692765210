import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import SettlementsC from 'components/Customer/Pages/Settlements/SettlementsC'
import MerchantDepositsC from 'components/Customer/Pages/MerchantDeposits/MerchantDepositsC'
import { PAYOUTS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  MERCHANT_PAYOUTS_PATH_MERCHANT_DEPOSITS,
  MERCHANT_PAYOUTS_PATH_SETTLEMENTS,
} from 'constants/pathConstants'

import {
  MERCHANT_DEPOSITS,
  MERCHANT_PAYOUTS,
  MERCHANT_PAYOUTS_SUBTITLE,
  SETTLEMENTS,
} from 'constants/language/languageConstants'

class MerchantPayoutsTabs extends Component {
  render() {
    const tabs = [
      {
        id: 'settlements',
        name: SETTLEMENTS,
        component: SettlementsC,
        props: {
          showHeader: false,
          customPath: MERCHANT_PAYOUTS_PATH_SETTLEMENTS,
        },
      },
      {
        id: 'merchant-deposits',
        name: MERCHANT_DEPOSITS,
        component: MerchantDepositsC,
        props: {
          showHeader: false,
          customPath: MERCHANT_PAYOUTS_PATH_MERCHANT_DEPOSITS,
        },
      },
    ]

    return (
      <div className='MerchantPayoutsTabs'>
        <HeaderV2C
          title={MERCHANT_PAYOUTS}
          subTitle={MERCHANT_PAYOUTS_SUBTITLE}
          learnMoreLink={PAYOUTS_LEARN_MORE_LINK}
        />

        <TabsC tabs={tabs} />
      </div>
    )
  }
}

export default MerchantPayoutsTabs
