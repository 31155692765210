import './GuestPaymentLinkFormS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import PhoneField from 'components/Shared/Inputs/PhoneField/PhoneField'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import LanguageSelectorC from 'components/Customer/Shared/LanguageSelector/LanguageSelectorC'
import formatPhone from 'utilities/forms/format/formatPhone'
import ReactSelectCreatable from 'components/Shared/Inputs/ReactSelectCreatable/ReactSelectCreatable'
import Button from 'components/Shared/Button/Button'
import Loader from 'components/Shared/Loader/Loader'
import formatMoney from 'utilities/formatters/formatMoney'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import every from 'lodash/every'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'
import get from 'lodash/get'
import numeral from 'numeral'
import includes from 'lodash/includes'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

import {
  t,
  PAY,
  NAME,
  EMAIL,
  PHONE,
  CONTACT,
  SUBTOTAL,
  DISCOUNT,
  TOTAL_DUE,
  PROCESSING,
  TIP_AMOUNT,
  ESTIMATED_TAX,
  ORDER_SUMMARY,
  PRIVACY_POLICY,
  PAYMENT_AMOUNT,
  ADDITIONAL_INFORMATION,
  SHIPPING_AMOUNT,
  BILLING_DETAILS,
  SHIPPING_DETAILS,
  TERMS_OF_SERVICE,
  PAYMENT_AMOUNT_HELPER_TEXT,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5,
  PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
  RECIPIENT_PHONE_NUMBER_PLACEHOLDER,
  A_RECEIPT_WILL_BE_EMAILED_TO_YOU,
  HIDE_OTHER_RECEIPT_OPTIONS,
  VIEW_OTHER_RECEIPT_OPTIONS,
  VALID_US_CAN_NUMBERS_ONLY_RATES_APPLY,
} from 'constants/language/languageConstants'

const countriesWithCustomACHWarnings = [CAN]

const GuestPaymentLinkForm = ({
  paymentLink = {},
  checkoutForm = {},
  displayVariableAmount = '0.00',
  formIsInvalid = true,
  formIsPristine = true,
  formHasErrors = true,
  isProcessingPayment = false,
  showAchWarning = false,
  previewMode = '',
  noValidation = false,
  openOrderSummary = false,
  country = USA,
  initializeReceiptEmails = () => {},
}) => {
  const [showReceiptOptions, setShowReceiptOptions] = useState(false)

  const checkoutResource = isEmpty(paymentLink) ? checkoutForm : paymentLink

  const {
    collectEmail,
    collectName,
    collectPhoneNumber,
    collectShippingAddress,
    termsOfServiceUrl,
    brandColor = '#FFFFFF',
    accentColor,
    buttonFontColor,
    logo,
    items,
    amountType,
    subtotalAmount,
    estimatedTaxAmount,
    discountAmount,
    shippingAmount,
    tipAmount,
    displayTotalAmount,
    displaySubtotalAmount,
    displayEstimatedTaxAmount,
    displayDiscountAmount,
    displayShippingAmount,
    displayTipAmount,
    tags,
    useCustomBranding,
    sendReceipt,
  } = checkoutResource

  const customFields = filter(tags, (tagValue, tagKey) => startsWith(tagKey, 'custom_field'))
  const showCustomFields = size(customFields) > 0

  const isFixedAmount = amountType === 'FIXED'
  const showContactInfo = collectName || collectEmail || collectPhoneNumber
  const submitButtonDisabled = !noValidation && !useCustomBranding && (formIsInvalid || formIsPristine || formHasErrors || isProcessingPayment)
  const showTotalBreakdown = subtotalAmount || estimatedTaxAmount || discountAmount || shippingAmount
  const hideImagePreviews = every(items, ({ primaryImageUrl }) => !primaryImageUrl)
  const previewClassNames = previewMode ? `preview-mode ${previewMode}` : ''
  const emptyItems = isEqual(size(items), 1) && isEmpty(get(items, '[0]'))
  const showStandardAchWarningText = !includes(countriesWithCustomACHWarnings, country)
  const toggleShowReceiptOptions = () => {
    setShowReceiptOptions(!showReceiptOptions)

    if (!showReceiptOptions) {
      initializeReceiptEmails()
    }
  }

  return (
    <form className={`GuestPaymentLinkForm ${previewClassNames}`}>
      { isEmpty(checkoutResource) && <div className='mask' /> }

      <div className='header' style={{ backgroundColor: brandColor }}>
        { logo && <img src={logo} alt='company-logo' /> }
      </div>

      <div className='container flex'>
        <div className='left-col'>
          <div className='form-container'>
            <div className='mobile-order-summary'>
              <Accordion title={ORDER_SUMMARY} open={openOrderSummary}>
                <div className='items'>
                  { !emptyItems && map(items, (item, index) => {
                    const {
                      name,
                      quantity,
                      description,
                      primaryImageUrl,
                      saleAmount,
                      totalAmount: itemTotalAmount,
                    } = item

                    const itemKey = `mobile-item-preview-${index}`

                    if (!isFixedAmount) {
                      return (
                        <div className='item-preview not-fixed-amount flex column' key={itemKey}>
                          <div className='order-summary-description-container flex'>
                            {primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={80} height={80} />}

                            <div className={primaryImageUrl ? 'right-column' : ''}>
                              <div className='name'>{name}</div>
                              {description && <div className='order-description'>{description}</div>}
                            </div>
                          </div>

                          <AmountField
                            name='saleAmount'
                            className='sale-amount-field-input'
                            label={PAYMENT_AMOUNT}
                            placeholder={saleAmount}
                            helperText={PAYMENT_AMOUNT_HELPER_TEXT}
                            normalize={(val = '') => val.replace(/[^\d,.]/g, '').replace(/(\.\d{2})[.,]*\d*/g, '$1')}
                          />

                        </div>
                      )
                    }

                    return (
                      <div className='item-preview flex' key={itemKey}>
                        { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }
                        { !primaryImageUrl && !hideImagePreviews && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                        <div className='info flex column flex-grow space-between'>
                          <div>
                            <p className='name p-2'>{name}</p>
                            <p className='description p-3 secondary'>{description}</p>
                          </div>

                          { isFixedAmount && saleAmount && quantity && <p className='quantity p-3 secondary'>{formatMoney({ amount: saleAmount })} x {quantity}</p> }
                        </div>

                        <h6 className='total'>{itemTotalAmount}</h6>
                      </div>
                    )
                  })}
                </div>

                { showTotalBreakdown && (
                  <div className='total-breakdown'>
                    { subtotalAmount && numeral(subtotalAmount).value() > 0 && (
                      <div className='flex space-between'>
                        <p className='p-1'>{t(SUBTOTAL)}</p>
                        <p className='p-1'>{displaySubtotalAmount}</p>
                      </div>
                    )}

                    { discountAmount && numeral(discountAmount).value() > 0 && (
                      <div className='flex space-between'>
                        <p className='p-1'>{t(DISCOUNT)}</p>
                        <p className='p-1 success'>- {displayDiscountAmount}</p>
                      </div>
                    )}

                    { shippingAmount && numeral(shippingAmount).value() > 0 && (
                      <div className='flex space-between'>
                        <p className='p-1'>{t(SHIPPING_AMOUNT)}</p>
                        <p className='p-1'>{displayShippingAmount}</p>
                      </div>
                    )}

                    { estimatedTaxAmount && numeral(estimatedTaxAmount).value() > 0 && (
                      <div className='flex space-between'>
                        <p className='p-1'>{t(ESTIMATED_TAX)}</p>
                        <p className='p-1'>{displayEstimatedTaxAmount}</p>
                      </div>
                    )}

                    { tipAmount && numeral(tipAmount).value() > 0 && (
                      <div className='flex space-between'>
                        <p className='p-1'>{t(TIP_AMOUNT)}</p>
                        <p className='p-1'>{displayTipAmount}</p>
                      </div>
                    )}
                  </div>
                )}

                <div className='cart-total flex space-between'>
                  <h5>{t(TOTAL_DUE)}</h5>
                  <h5>{isFixedAmount ? displayTotalAmount : displayVariableAmount}</h5>
                </div>
              </Accordion>
            </div>

            { showContactInfo && (
              <div className='contact-info'>
                <h5>{t(CONTACT)}</h5>

                { collectName && (
                  <Field
                    name='name'
                    type='text'
                    component={InputField}
                    label={t(NAME)}
                    placeholder='John Doe'
                    required={false}
                  />
                )}

                { collectEmail && (
                  <Field
                    name='email'
                    type='text'
                    component={InputField}
                    label={t(EMAIL)}
                    placeholder='johndoe@gmail.com'
                    required={false}
                  />
                )}

                { collectPhoneNumber && (
                  <Field
                    name='phone'
                    label={t(PHONE)}
                    component={PhoneField}
                    required={false}
                  />
                )}

                { sendReceipt && (
                  <div className='send-receipt'>
                    <span className='label-2'>
                      {t(A_RECEIPT_WILL_BE_EMAILED_TO_YOU)}. <span className='link-2' onClick={toggleShowReceiptOptions}>{showReceiptOptions ? t(HIDE_OTHER_RECEIPT_OPTIONS) : t(VIEW_OTHER_RECEIPT_OPTIONS)}.</span>
                    </span>

                    { showReceiptOptions && (
                      <div className='receipt-options'>
                        <Field
                          name='receiptEmails'
                          label='Email Receipt to'
                          component={ReactSelectCreatable}
                          placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
                          disableDropdown
                        />

                        <Field
                          name='receiptPhoneNumbers'
                          label='Text Receipt to'
                          component={ReactSelectCreatable}
                          placeholder={RECIPIENT_PHONE_NUMBER_PLACEHOLDER}
                          className='send-method-input'
                          formatValue={formatPhone}
                          disableDropdown
                        />

                        <div className='label-2 secondary mts'>{t(VALID_US_CAN_NUMBERS_ONLY_RATES_APPLY)}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            { collectShippingAddress && (
              <div className='shipping-info'>
                <h5>{t(SHIPPING_DETAILS)}</h5>

                <AddressFieldset
                  name='shippingAddress'
                  noPlaceholders
                  showLabels
                  required={false}
                />
              </div>
            )}

            { showCustomFields && (
              <div className='custom-info'>
                <h5>{t(ADDITIONAL_INFORMATION)}</h5>

                { map(customFields, (customFieldName) => {
                  return (
                    <Field
                      key={customFieldName}
                      name={`tags.${customFieldName}`}
                      label={customFieldName}
                      component={InputField}
                      required={false}
                    />
                  )
                })}
              </div>
            )}

            <div className='billing-info'>
              <h5>{t(BILLING_DETAILS)}</h5>
              <div id='payment-instrument-form' />

              { showAchWarning && (
                <div className='ach-warning-text'>
                  { showStandardAchWarningText && (
                    <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_US)}</p>
                  )}

                  { country === CAN && (
                    <>
                      <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P1)}</p>
                      <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P2)}</p>
                      <p className='label-3 secondary'>
                        {t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4)} <span className='bold underline'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P4_BOLD)}</span>
                      </p>
                      <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P5)}</p>
                      <p className='label-3 secondary'>{t(PAYMENT_LINK_ACH_AUTHORIZATION_WARNING_TEXT_CA_P6)}</p>
                    </>
                  )}
                </div>
              ) }

              <Button
                label={isProcessingPayment ? <span className='flex'>{t(PROCESSING)} <Loader /></span> : t(PAY)}
                type='button'
                className='pay-button'
                style={{ backgroundColor: accentColor, borderColor: accentColor, color: buttonFontColor }}
                disabled={submitButtonDisabled}
              />
            </div>

            <div className='links flex center'>
              <a href={termsOfServiceUrl} className='link-3' target='_blank' rel='noopener noreferrer'>{t(TERMS_OF_SERVICE)}</a>
              <a href='https://finix.com/terms-and-policies/privacy-policy' className='link-3' target='_blank' rel='noopener noreferrer'>{t(PRIVACY_POLICY)}</a>
            </div>

            <div className='mobile-language-selector flex flex-end'>
              <LanguageSelectorC menuPlacement='top' />
            </div>
          </div>
        </div>

        <div className='right-col'>
          <h5 className='order-summary'>{t(ORDER_SUMMARY)}</h5>

          <div className='items'>
            { map(items, (item, index) => {
              const {
                name,
                quantity,
                description,
                primaryImageUrl,
                totalAmount: itemTotalAmount,
                saleAmount,
              } = item

              const itemKey = `item-preview-${index}`

              if (!isFixedAmount) {
                return (
                  <div className='item-preview flex column' key={itemKey}>
                    <div className='order-summary-description-container flex'>
                      {primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={80} height={80} />}

                      <div className={primaryImageUrl ? 'right-column' : ''}>
                        <div className='name'>{name}</div>
                        {description && <div className='order-description'>{description}</div>}
                      </div>
                    </div>

                    <AmountField
                      name='saleAmount'
                      className='sale-amount-field-input'
                      label={PAYMENT_AMOUNT}
                      placeholder={saleAmount}
                      helperText='Including taxes, tips, fees, etc.'
                      normalize={(val = '') => val.replace(/[^\d,.]/g, '').replace(/(\.\d{2})[.,]*\d*/g, '$1')}
                    />
                  </div>
                )
              }

              return (
                <div className='item-preview flex' key={itemKey}>
                  { primaryImageUrl && <img src={primaryImageUrl} alt='preview' width={100} /> }
                  { !primaryImageUrl && !hideImagePreviews && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                  <div className='info flex column flex-grow space-between'>
                    <div>
                      <p className='name p-2'>{name}</p>
                      <p className='description p-3 secondary'>{description}</p>
                    </div>

                    { isFixedAmount && saleAmount && quantity && <p className='quantity p-3 secondary'>{formatMoney({ amount: saleAmount })} x {quantity}</p> }
                  </div>

                  { isFixedAmount && <h6 className='total'>{itemTotalAmount}</h6> }
                </div>
              )
            })}
          </div>

          { showTotalBreakdown && (
            <div className='total-breakdown'>
              { subtotalAmount && numeral(subtotalAmount).value() > 0 && (
                <div className='flex space-between'>
                  <p className='p-1'>{t(SUBTOTAL)}</p>
                  <p className='p-1'>{displaySubtotalAmount}</p>
                </div>
              )}

              { discountAmount && numeral(discountAmount).value() > 0 && (
                <div className='flex space-between'>
                  <p className='p-1'>{t(DISCOUNT)}</p>
                  <p className='p-1 success'>- {displayDiscountAmount}</p>
                </div>
              )}

              { shippingAmount && numeral(shippingAmount).value() > 0 && (
                <div className='flex space-between'>
                  <p className='p-1'>{t(SHIPPING_AMOUNT)}</p>
                  <p className='p-1'>{displayShippingAmount}</p>
                </div>
              )}

              { estimatedTaxAmount && numeral(estimatedTaxAmount).value() > 0 && (
                <div className='flex space-between'>
                  <p className='p-1'>{t(ESTIMATED_TAX)}</p>
                  <p className='p-1'>{displayEstimatedTaxAmount}</p>
                </div>
              )}

              { tipAmount && numeral(tipAmount).value() > 0 && (
                <div className='flex space-between'>
                  <p className='p-1'>{t(TIP_AMOUNT)}</p>
                  <p className='p-1'>{displayTipAmount}</p>
                </div>
              )}
            </div>
          )}

          <div className='cart-total flex space-between'>
            <h5>{t(TOTAL_DUE)}</h5>
            <h5>{isFixedAmount ? displayTotalAmount : displayVariableAmount}</h5>
          </div>

          <LanguageSelectorC menuPlacement='top' />
        </div>
      </div>
    </form>
  )
}

GuestPaymentLinkForm.propTypes = {
  paymentLink: PropTypes.object,
  checkoutForm: PropTypes.object,
  displayVariableAmount: PropTypes.string,
  formIsInvalid: PropTypes.bool,
  formIsPristine: PropTypes.bool,
  formHasErrors: PropTypes.bool,
  isProcessingPayment: PropTypes.bool,
  showAchWarning: PropTypes.bool,
  previewMode: PropTypes.string,
  noValidation: PropTypes.bool,
  openOrderSummary: PropTypes.bool,
  country: PropTypes.string,
  initializeReceiptEmails: PropTypes.func,
}

export default GuestPaymentLinkForm
