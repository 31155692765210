import { CANNOT_BE_EMPTY } from 'constants/language/languageConstants'
import get from 'lodash/get'
import lt from 'lodash/lt'
import gt from 'lodash/gt'
import isNumber from 'lodash/isNumber'

const validateFeeProfileValues = ({ value, min, displayMin, max, displayMax, applyGuardRails, canBeEmpty = true }) => {
  if (!canBeEmpty && (!value && !isNumber(value))) {
    return CANNOT_BE_EMPTY
  }

  if (applyGuardRails && !!min && (!value && !isNumber(value))) {
    return CANNOT_BE_EMPTY
  }

  if (applyGuardRails && lt(parseFloat(value), parseFloat(min))) {
    return `Minimum is ${displayMin}`
  }

  if (applyGuardRails && gt(parseFloat(value), parseFloat(max))) {
    return `Maximum is ${displayMax}`
  }

  return false
}

const validateAchAndAdditionalForm = (values, props) => {
  const { guardRail, applyGuardRails } = props

  const {
    achBasisPoints,
    achFixedFee,
    achBasisPointsFeeLimit,
    achDebitReturnFixedFee,
    achCreditReturnFixedFee,
    ancillaryFixedFeePrimary,
    ancillaryFixedFeeSecondary,
  } = values

  return {
    achBasisPoints: validateFeeProfileValues({
      value: achBasisPoints,
      min: get(guardRail, 'achBasisPointsMin'),
      displayMin: get(guardRail, 'displayAchBasisPointsMin'),
      max: get(guardRail, 'achBasisPointsMax'),
      displayMax: get(guardRail, 'displayAchBasisPointsMax'),
      applyGuardRails,
      canBeEmpty: false,
    }),
    achFixedFee: validateFeeProfileValues({
      value: achFixedFee,
      min: get(guardRail, 'achFixedFeeMin'),
      displayMin: get(guardRail, 'displayAchFixedFeeMin'),
      max: get(guardRail, 'achFixedFeeMax'),
      displayMax: get(guardRail, 'displayAchFixedFeeMax'),
      applyGuardRails,
      canBeEmpty: false,
    }),
    achBasisPointsFeeLimit: validateFeeProfileValues({
      value: achBasisPointsFeeLimit,
      min: get(guardRail, 'achBasisPointsFeeLimitMin'),
      displayMin: get(guardRail, 'displayAchBasisPointsFeeLimitMin'),
      max: get(guardRail, 'achBasisPointsFeeLimitMax'),
      displayMax: get(guardRail, 'displayAchBasisPointsFeeLimitMax'),
      applyGuardRails,
    }),
    achDebitReturnFixedFee: validateFeeProfileValues({
      value: achDebitReturnFixedFee,
      min: get(guardRail, 'achDebitReturnFixedFeeMin'),
      displayMin: get(guardRail, 'displayAchDebitReturnFixedFeeMin'),
      max: get(guardRail, 'achDebitReturnFixedFeeMax'),
      displayMax: get(guardRail, 'displayAchDebitReturnFixedFeeMax'),
      applyGuardRails,
    }),
    achCreditReturnFixedFee: validateFeeProfileValues({
      value: achCreditReturnFixedFee,
      min: get(guardRail, 'achCreditReturnFixedFeeMin'),
      displayMin: get(guardRail, 'displayAchCreditReturnFixedFeeMin'),
      max: get(guardRail, 'achCreditReturnFixedFeeMax'),
      displayMax: get(guardRail, 'displayAchCreditReturnFixedFeeMax'),
      applyGuardRails,
    }),
    ancillaryFixedFeePrimary: validateFeeProfileValues({
      value: ancillaryFixedFeePrimary,
      min: get(guardRail, 'ancillaryFixedFeePrimaryMin'),
      displayMin: get(guardRail, 'displayAncillaryFixedFeePrimaryMin'),
      max: get(guardRail, 'ancillaryFixedFeePrimaryMax'),
      displayMax: get(guardRail, 'displayAncillaryFixedFeePrimaryMax'),
      applyGuardRails,
    }),
    ancillaryFixedFeeSecondary: validateFeeProfileValues({
      value: ancillaryFixedFeeSecondary,
      min: get(guardRail, 'ancillaryFixedFeeSecondaryMin'),
      displayMin: get(guardRail, 'displayAncillaryFixedFeeSecondaryMin'),
      max: get(guardRail, 'ancillaryFixedFeeSecondaryMax'),
      displayMax: get(guardRail, 'displayAncillaryFixedFeeSecondaryMax'),
      applyGuardRails,
    }),
  }
}

export default validateAchAndAdditionalForm
