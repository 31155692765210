import selectOption from 'utilities/forms/selectOption'
// NOTE: These are from the legacy 2020 - 2021 subscription billing endpoint.
export const FEE = 'FEE'
export const PERIODIC_MONTHLY = 'PERIODIC_MONTHLY'
export const PERIODIC_YEARLY = 'PERIODIC_YEARLY'
export const FIXED_TIME_INTERVAL = 'FIXED_TIME_INTERVAL'
export const FIXED_TIME = 'FIXED_TIME' // displayed legacy fixed type value

export const SUBSCRIPTION_TYPE_OPTIONS = [
  selectOption('Select Type', ''),
  selectOption('Fixed', FIXED_TIME_INTERVAL),
  selectOption('Monthly', PERIODIC_MONTHLY),
  selectOption('Yearly', PERIODIC_YEARLY),
]

export const subscriptionScheduleTypeMapping = {
  PERIODIC_MONTHLY: 'Monthly',
  PERIODIC_YEARLY: 'Yearly',
  FIXED_TIME_INTERVAL: 'Fixed',
  FIXED_TIME: 'Fixed', // displayed legacy fixed type value
}
