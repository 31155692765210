import TagsDisplaySection from 'components/Customer/Shared/Page/PageSection/TagsDisplaySection'
import formatMoney from 'utilities/formatters/formatMoney'
import { sourceDestinationMapping } from 'constants/balanceTransferConstants'
import map from 'lodash/map'
import get from 'lodash/get'

const balanceTransferFields = [
  {
    name: 'source',
    label: 'Source',
    formatter: (source) => sourceDestinationMapping[source],
  },
  {
    name: 'destination',
    label: 'Destination',
    formatter: (destination) => sourceDestinationMapping[destination],
  },
  {
    name: 'amount',
    label: 'Amount',
    formatter: (amount) => formatMoney({ amount }),
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'tags',
    label: 'Tags',
    formatter: (tags) => TagsDisplaySection({
      data: map(tags, ({ name, value }) => ({ value, label: name })),
      emptyMessage: '-',
    }),
  },
]

const CreateBalanceTransferDisplayData = (values) => map(balanceTransferFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name, {}), values) : get(values, name),
}))

export default CreateBalanceTransferDisplayData
