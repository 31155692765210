import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ENTITY_CONFIGURATION } from 'constants/apiConstants'

const patchEntityConfigurationAPI = ({
  id,
  values,
  meta,
}) => {
  return dashboardAPI.patch({
    path: DASHBOARD_SERVICE_ENTITY_CONFIGURATION({ id }),
    values,
    meta,
  })
}

export default patchEntityConfigurationAPI
