import './IdentityPrincipalInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import BeneficialOwners from 'components/Customer/Forms/LiveAccountApplicationOwnerInfoForm/BeneficialOwners'
import Button from 'components/Shared/Button/Button'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import PersonalTaxIdField from 'components/Shared/Inputs/PersonalTaxIdField/PersonalTaxIdField'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import prevFlowStep from 'utilities/prevFlowStep'
import submitOnboardingStepForm from 'utilities/submit/submitOnboardingStepForm'
import validateIdentityPrincipalInfoForm from 'utilities/validate/validateIdentityPrincipalInfoForm'
import warnIdentityPrincipalInfoForm from 'utilities/warn/warnIdentityPrincipalInfoForm'
import { IDENTITY_PRINCIPAL_INFO_FORM } from 'constants/formConstants'
import { INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE } from 'constants/identityConstants'
import { USA } from 'constants/countryConstants'

import {
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form'

import {
  ARE_THERE_ANY_BENEFICIAL_OWNERS,
  ARE_THERE_ANY_BENEFICIAL_OWNERS_SUBTITLE,
  BENEFICIAL_OWNER_NOTICE,
  CONTROL_PERSON,
  CONTROL_PERSON_INFO_SUBHEADER,
  CONTROL_PERSON_INFO_SUBHEADER_ISP,
  DATE_OF_BIRTH,
  EMAIL_ADDRESS,
  FIRST_NAME,
  HOME_ADDRESS,
  LAST_NAME,
  NO,
  OWNER_INFORMATION,
  OWNERSHIP_PERCENTAGE,
  PHONE_NUMBER,
  PLEASE_COMPLETE_STEP_MESSAGE,
  JOB_TITLE,
  PREVIOUS_STEP,
  SAVE_AND_CONTINUE,
  YES,
  PERSONAL_ADDRESS_LINE1_LOWER_LABEL,
} from 'constants/language/languageConstants'

const BENEFICIAL_OWNER_OPTIONS = [
  { label: YES, value: 'true' },
  { label: NO, value: 'false' },
]

const IdentityPrincipalInfoForm = ({
  handleSubmit = () => {},
  handleBeneficialOwnersRadioClick = () => {},
  ownershipPercentageError,
  isFormInvalid = false,
  submitting = false,
  disabled = false,
  businessType,
  onboardingFormCountry = USA,
  controlPersonCurrentCountry = USA,
  beneficialOwnersCurrentCountries = [],
  isBoardingToFinixV1Only = false,
}) => {
  return (
    <form className='IdentityPrincipalInfoForm' onSubmit={handleSubmit(submitOnboardingStepForm)}>
      <div className='form-header'>
        <h3>{OWNER_INFORMATION}</h3>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} />}
        {!isFormInvalid && ownershipPercentageError && (<ErrorMessage errorMessage={ownershipPercentageError} />)}
        <div className='control-person-content'>
          <h5>{CONTROL_PERSON}</h5>
          <div className='sub-title'>{businessType === INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE ? CONTROL_PERSON_INFO_SUBHEADER_ISP : CONTROL_PERSON_INFO_SUBHEADER}</div>
          <div className='flex space-between'>
            <Field
              name='controlPerson.firstName'
              label={FIRST_NAME}
              component={InputField}
              autoComplete='given-name'
            />

            <Field
              name='controlPerson.lastName'
              label={LAST_NAME}
              component={InputField}
              autoComplete='family-name'
            />
          </div>

          <Field
            name='controlPerson.title'
            label={JOB_TITLE}
            component={InputField}
            autoComplete='organization-title'
          />

          <DateFieldset
            name='controlPerson.dateOfBirth'
            label={DATE_OF_BIRTH}
            autoComplete={{
              day: 'bday-day',
              month: 'bday-month',
              year: 'bday-year',
            }}
          />

          <PersonalTaxIdField
            name='controlPerson.taxId'
            country={controlPersonCurrentCountry}
            onboardingFormCountry={onboardingFormCountry}
          />

          <div className='flex space-between mt-0'>
            <Field
              name='controlPerson.email'
              label={EMAIL_ADDRESS}
              component={InputField}
              autoComplete='email'
            />

            <Field
              name='controlPerson.phone'
              label={PHONE_NUMBER}
              component={InputField}
              format={formatPhone}
              parse={parsePhone}
              placeholder='000-000-0000'
              autoComplete='tel-national'
            />
          </div>

          <AddressC
            name='controlPerson.personalAddress'
            label={HOME_ADDRESS}
            showLabels
            form={IDENTITY_PRINCIPAL_INFO_FORM}
            helperText={{ addressLine1HelperText: PERSONAL_ADDRESS_LINE1_LOWER_LABEL }}
          />

          <PercentageField
            name='controlPerson.principalPercentageOwnership'
            label={OWNERSHIP_PERCENTAGE}
            placeholder='0'
            onlyWholeNumbers
          />
        </div>

        {businessType && businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE && (
        <>
          <div className='add-beneficial-owners-content'>
            <h5>{ARE_THERE_ANY_BENEFICIAL_OWNERS}</h5>
            <div className='sub-title'>{ARE_THERE_ANY_BENEFICIAL_OWNERS_SUBTITLE}</div>
            <Field
              component={RadioButton}
              options={BENEFICIAL_OWNER_OPTIONS}
              onChange={handleBeneficialOwnersRadioClick}
              name='beneficialOwnersQuestion'
            />
            <div className='beneficial-owner-notice'>{BENEFICIAL_OWNER_NOTICE}</div>
          </div>

          <div className='beneficial-owners-content'>
            <FieldArray
              name='beneficialOwners'
              component={BeneficialOwners}
              ownershipPercentageError={ownershipPercentageError}
              form={IDENTITY_PRINCIPAL_INFO_FORM}
              onboardingFormCountry={onboardingFormCountry}
              beneficialOwnersCurrentCountries={beneficialOwnersCurrentCountries}
              isBoardingToFinixV1Only={isBoardingToFinixV1Only}
            />
          </div>
        </>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button variant='tertiary' className='left-button' onClick={prevFlowStep} label={PREVIOUS_STEP} />
          </div>
          <div className='exit-and-save-container'>
            <Button type='submit' label={SAVE_AND_CONTINUE} submitting={submitting} disabled={disabled || ownershipPercentageError} />
          </div>
        </div>
      </div>
    </form>
  )
}

IdentityPrincipalInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleBeneficialOwnersRadioClick: PropTypes.func,
  ownershipPercentageError: PropTypes.string,
  isFormInvalid: PropTypes.bool,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  businessType: PropTypes.string,
  onboardingFormCountry: PropTypes.string,
  controlPersonCurrentCountry: PropTypes.string,
  beneficialOwnersCurrentCountries: PropTypes.array,
  isBoardingToFinixV1Only: PropTypes.bool,
}

export default reduxForm({
  form: IDENTITY_PRINCIPAL_INFO_FORM,
  validate: validateIdentityPrincipalInfoForm,
  warn: warnIdentityPrincipalInfoForm,
})(IdentityPrincipalInfoForm)
