import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'

import {
  USD,
  CAD,
} from 'constants/currencyConstants'

import {
  normalizeNullableBackendAmount,
  normalizePercentageToBasisPoints,
} from 'constants/normalizationConstants'


const backendFeeProfileRulesModel = ({ values }) => {
  const blendedUsd = {
    currency: USD,
    strategy: 'BLENDED',
    fields: get(values, 'blended.USD'),
  }
  const interchangeUsd = {
    currency: USD,
    strategy: 'INTERCHANGE_PLUS',
    fields: get(values, 'interchange-plus.USD'),
  }
  const blendedCad = {
    currency: CAD,
    strategy: 'BLENDED',
    fields: get(values, 'blended.CAD'),
  }
  const interchangeCad = {
    currency: CAD,
    strategy: 'INTERCHANGE_PLUS',
    fields: get(values, 'interchange-plus.CAD'),
  }

  const itemsToMap = filter([blendedUsd, interchangeUsd, blendedCad, interchangeCad], item => get(item, 'fields.open') === true)

  const test = map(itemsToMap, ruleset => {
    const [
      currency,
      strategy,
      fields,
    ] = getMany(ruleset, [
      'currency',
      'strategy',
      'fields',
    ])

    const [
      achBasisPointsMin,
      achBasisPointsMax,
      achCreditReturnFixedFeeMin,
      achCreditReturnFixedFeeMax,
      achDebitReturnFixedFeeMin,
      achDebitReturnFixedFeeMax,
      achFixedFeeMin,
      achFixedFeeMax,
      americanExpressAssessmentBasisPointsMin,
      americanExpressAssessmentBasisPointsMax,
      americanExpressBasisPointsMin,
      americanExpressBasisPointsMax,
      americanExpressFixedFeeMin,
      americanExpressFixedFeeMax,
      americanExpressExternallyFundedBasisPointsMin,
      americanExpressExternallyFundedBasisPointsMax,
      americanExpressExternallyFundedFixedFeeMin,
      americanExpressExternallyFundedFixedFeeMax,
      ancillaryFixedFeePrimaryMin,
      ancillaryFixedFeePrimaryMax,
      ancillaryFixedFeeSecondaryMin,
      ancillaryFixedFeeSecondaryMax,
      basisPointsMin,
      basisPointsMax,
      cardCrossBorderBasisPointsMin,
      cardCrossBorderBasisPointsMax,
      cardCrossBorderFixedFeeMin,
      cardCrossBorderFixedFeeMax,
      dinersClubBasisPointsMin,
      dinersClubBasisPointsMax,
      dinersClubFixedFeeMin,
      dinersClubFixedFeeMax,
      discoverAssessmentsBasisPointsMin,
      discoverAssessmentsBasisPointsMax,
      discoverBasisPointsMin,
      discoverBasisPointsMax,
      discoverDataUsageFixedFeeMin,
      discoverDataUsageFixedFeeMax,
      discoverFixedFeeMin,
      discoverFixedFeeMax,
      discoverExternallyFundedBasisPointsMin,
      discoverExternallyFundedBasisPointsMax,
      discoverExternallyFundedFixedFeeMin,
      discoverExternallyFundedFixedFeeMax,
      discoverNetworkAuthorizationFixedFeeMin,
      discoverNetworkAuthorizationFixedFeeMax,
      disputeFixedFeeMin,
      disputeFixedFeeMax,
      disputeInquiryFixedFeeMin,
      disputeInquiryFixedFeeMax,
      externallyFundedBasisPointsMin,
      externallyFundedBasisPointsMax,
      externallyFundedFixedFeeMin,
      externallyFundedFixedFeeMax,
      fixedFeeMin,
      fixedFeeMax,
      jcbBasisPointsMin,
      jcbBasisPointsMax,
      jcbFixedFeeMin,
      jcbFixedFeeMax,
      mastercardAcquirerFeesBasisPointsMin,
      mastercardAcquirerFeesBasisPointsMax,
      mastercardAssessmentsOver1kBasisPointsMin,
      mastercardAssessmentsOver1kBasisPointsMax,
      mastercardAssessmentsUnder1kBasisPointsMin,
      mastercardAssessmentsUnder1kBasisPointsMax,
      mastercardBasisPointsMin,
      mastercardBasisPointsMax,
      mastercardFixedFeeMin,
      mastercardFixedFeeMax,
      visaAcquirerProcessingFixedFeeMin,
      visaAcquirerProcessingFixedFeeMax,
      visaAssessmentsBasisPointsMin,
      visaAssessmentsBasisPointsMax,
      visaBaseIICreditVoucherFixedFeeMin,
      visaBaseIICreditVoucherFixedFeeMax,
      visaBaseIISystemFileTransmissionFixedFeeMin,
      visaBaseIISystemFileTransmissionFixedFeeMax,
      visaBasisPointsMin,
      visaBasisPointsMax,
      visaCreditVoucherFixedFeeMin,
      visaCreditVoucherFixedFeeMax,
      visaFixedFeeMin,
      visaFixedFeeMax,
      visaKilobyteAccessFixedFeeMin,
      visaKilobyteAccessFixedFeeMax,
    ] = getMany(fields, [
      'achBasisPointsMin',
      'achBasisPointsMax',
      'achCreditReturnFixedFeeMin',
      'achCreditReturnFixedFeeMax',
      'achDebitReturnFixedFeeMin',
      'achDebitReturnFixedFeeMax',
      'achFixedFeeMin',
      'achFixedFeeMax',
      'americanExpressAssessmentBasisPointsMin',
      'americanExpressAssessmentBasisPointsMax',
      'americanExpressBasisPointsMin',
      'americanExpressBasisPointsMax',
      'americanExpressFixedFeeMin',
      'americanExpressFixedFeeMax',
      'americanExpressExternallyFundedBasisPointsMin',
      'americanExpressExternallyFundedBasisPointsMax',
      'americanExpressExternallyFundedFixedFeeMin',
      'americanExpressExternallyFundedFixedFeeMax',
      'ancillaryFixedFeePrimaryMin',
      'ancillaryFixedFeePrimaryMax',
      'ancillaryFixedFeeSecondaryMin',
      'ancillaryFixedFeeSecondaryMax',
      'basisPointsMin',
      'basisPointsMax',
      'cardCrossBorderBasisPointsMin',
      'cardCrossBorderBasisPointsMax',
      'cardCrossBorderFixedFeeMin',
      'cardCrossBorderFixedFeeMax',
      'dinersClubBasisPointsMin',
      'dinersClubBasisPointsMax',
      'dinersClubFixedFeeMin',
      'dinersClubFixedFeeMax',
      'discoverAssessmentsBasisPointsMin',
      'discoverAssessmentsBasisPointsMax',
      'discoverBasisPointsMin',
      'discoverBasisPointsMax',
      'discoverDataUsageFixedFeeMin',
      'discoverDataUsageFixedFeeMax',
      'discoverFixedFeeMin',
      'discoverFixedFeeMax',
      'discoverExternallyFundedBasisPointsMin',
      'discoverExternallyFundedBasisPointsMax',
      'discoverExternallyFundedFixedFeeMin',
      'discoverExternallyFundedFixedFeeMax',
      'discoverNetworkAuthorizationFixedFeeMin',
      'discoverNetworkAuthorizationFixedFeeMax',
      'disputeFixedFeeMin',
      'disputeFixedFeeMax',
      'disputeInquiryFixedFeeMin',
      'disputeInquiryFixedFeeMax',
      'externallyFundedBasisPointsMin',
      'externallyFundedBasisPointsMax',
      'externallyFundedFixedFeeMin',
      'externallyFundedFixedFeeMax',
      'fixedFeeMin',
      'fixedFeeMax',
      'jcbBasisPointsMin',
      'jcbBasisPointsMax',
      'jcbFixedFeeMin',
      'jcbFixedFeeMax',
      'mastercardAcquirerFeesBasisPointsMin',
      'mastercardAcquirerFeesBasisPointsMax',
      'mastercardAssessmentsOver1kBasisPointsMin',
      'mastercardAssessmentsOver1kBasisPointsMax',
      'mastercardAssessmentsUnder1kBasisPointsMin',
      'mastercardAssessmentsUnder1kBasisPointsMax',
      'mastercardBasisPointsMin',
      'mastercardBasisPointsMax',
      'mastercardFixedFeeMin',
      'mastercardFixedFeeMax',
      'visaAcquirerProcessingFixedFeeMin',
      'visaAcquirerProcessingFixedFeeMax',
      'visaAssessmentsBasisPointsMin',
      'visaAssessmentsBasisPointsMax',
      'visaBaseIICreditVoucherFixedFeeMin',
      'visaBaseIICreditVoucherFixedFeeMax',
      'visaBaseIISystemFileTransmissionFixedFeeMin',
      'visaBaseIISystemFileTransmissionFixedFeeMax',
      'visaBasisPointsMin',
      'visaBasisPointsMax',
      'visaCreditVoucherFixedFeeMin',
      'visaCreditVoucherFixedFeeMax',
      'visaFixedFeeMin',
      'visaFixedFeeMax',
      'visaKilobyteAccessFixedFeeMin',
      'visaKilobyteAccessFixedFeeMax',
    ])

    return {
      currency,
      fee_strategy: strategy,
      is_allowed: true,
      fee_bounds: {
        ach_basis_points: {
          min: normalizePercentageToBasisPoints(achBasisPointsMin),
          max: normalizePercentageToBasisPoints(achBasisPointsMax),
        },
        ach_credit_return_fixed_fee: {
          min: normalizeNullableBackendAmount(achCreditReturnFixedFeeMin),
          max: normalizeNullableBackendAmount(achCreditReturnFixedFeeMax),
        },
        ach_debit_return_fixed_fee: {
          min: normalizeNullableBackendAmount(achDebitReturnFixedFeeMin),
          max: normalizeNullableBackendAmount(achDebitReturnFixedFeeMax),
        },
        ach_fixed_fee: {
          min: normalizeNullableBackendAmount(achFixedFeeMin),
          max: normalizeNullableBackendAmount(achFixedFeeMax),
        },
        american_express_assessment_basis_points: {
          min: normalizePercentageToBasisPoints(americanExpressAssessmentBasisPointsMin),
          max: normalizePercentageToBasisPoints(americanExpressAssessmentBasisPointsMax),
        },
        american_express_basis_points: {
          min: normalizePercentageToBasisPoints(americanExpressBasisPointsMin),
          max: normalizePercentageToBasisPoints(americanExpressBasisPointsMax),
        },
        american_express_fixed_fee: {
          min: normalizeNullableBackendAmount(americanExpressFixedFeeMin),
          max: normalizeNullableBackendAmount(americanExpressFixedFeeMax),
        },
        american_express_externally_funded_basis_points: {
          min: normalizePercentageToBasisPoints(americanExpressExternallyFundedBasisPointsMin),
          max: normalizePercentageToBasisPoints(americanExpressExternallyFundedBasisPointsMax),
        },
        american_express_externally_funded_fixed_fee: {
          min: normalizeNullableBackendAmount(americanExpressExternallyFundedFixedFeeMin),
          max: normalizeNullableBackendAmount(americanExpressExternallyFundedFixedFeeMax),
        },
        ancillary_fixed_fee_primary: {
          min: normalizeNullableBackendAmount(ancillaryFixedFeePrimaryMin),
          max: normalizeNullableBackendAmount(ancillaryFixedFeePrimaryMax),
        },
        ancillary_fixed_fee_secondary: {
          min: normalizeNullableBackendAmount(ancillaryFixedFeeSecondaryMin),
          max: normalizeNullableBackendAmount(ancillaryFixedFeeSecondaryMax),
        },
        basis_points: {
          min: normalizePercentageToBasisPoints(basisPointsMin),
          max: normalizePercentageToBasisPoints(basisPointsMax),
        },
        card_cross_border_basis_points: {
          min: normalizePercentageToBasisPoints(cardCrossBorderBasisPointsMin),
          max: normalizePercentageToBasisPoints(cardCrossBorderBasisPointsMax),
        },
        card_cross_border_fixed_fee: {
          min: normalizeNullableBackendAmount(cardCrossBorderFixedFeeMin),
          max: normalizeNullableBackendAmount(cardCrossBorderFixedFeeMax),
        },
        diners_club_basis_points: {
          min: normalizePercentageToBasisPoints(dinersClubBasisPointsMin),
          max: normalizePercentageToBasisPoints(dinersClubBasisPointsMax),
        },
        diners_club_fixed_fee: {
          min: normalizeNullableBackendAmount(dinersClubFixedFeeMin),
          max: normalizeNullableBackendAmount(dinersClubFixedFeeMax),
        },
        discover_assessments_basis_points: {
          min: normalizePercentageToBasisPoints(discoverAssessmentsBasisPointsMin),
          max: normalizePercentageToBasisPoints(discoverAssessmentsBasisPointsMax),
        },
        discover_basis_points: {
          min: normalizePercentageToBasisPoints(discoverBasisPointsMin),
          max: normalizePercentageToBasisPoints(discoverBasisPointsMax),
        },
        discover_data_usage_fixed_fee: {
          min: normalizeNullableBackendAmount(discoverDataUsageFixedFeeMin),
          max: normalizeNullableBackendAmount(discoverDataUsageFixedFeeMax),
        },
        discover_fixed_fee: {
          min: normalizeNullableBackendAmount(discoverFixedFeeMin),
          max: normalizeNullableBackendAmount(discoverFixedFeeMax),
        },
        discover_externally_funded_basis_points: {
          min: normalizePercentageToBasisPoints(discoverExternallyFundedBasisPointsMin),
          max: normalizePercentageToBasisPoints(discoverExternallyFundedBasisPointsMax),
        },
        discover_externally_funded_fixed_fee: {
          min: normalizeNullableBackendAmount(discoverExternallyFundedFixedFeeMin),
          max: normalizeNullableBackendAmount(discoverExternallyFundedFixedFeeMax),
        },
        discover_network_authorization_fixed_fee: {
          min: normalizeNullableBackendAmount(discoverNetworkAuthorizationFixedFeeMin),
          max: normalizeNullableBackendAmount(discoverNetworkAuthorizationFixedFeeMax),
        },
        dispute_fixed_fee: {
          min: normalizeNullableBackendAmount(disputeFixedFeeMin),
          max: normalizeNullableBackendAmount(disputeFixedFeeMax),
        },
        dispute_inquiry_fixed_fee: {
          min: normalizeNullableBackendAmount(disputeInquiryFixedFeeMin),
          max: normalizeNullableBackendAmount(disputeInquiryFixedFeeMax),
        },
        externally_funded_basis_points: {
          min: normalizePercentageToBasisPoints(externallyFundedBasisPointsMin),
          max: normalizePercentageToBasisPoints(externallyFundedBasisPointsMax),
        },
        externally_funded_fixed_fee: {
          min: normalizeNullableBackendAmount(externallyFundedFixedFeeMin),
          max: normalizeNullableBackendAmount(externallyFundedFixedFeeMax),
        },
        fixed_fee: {
          min: normalizeNullableBackendAmount(fixedFeeMin),
          max: normalizeNullableBackendAmount(fixedFeeMax),
        },
        jcb_basis_points: {
          min: normalizePercentageToBasisPoints(jcbBasisPointsMin),
          max: normalizePercentageToBasisPoints(jcbBasisPointsMax),
        },
        jcb_fixed_fee: {
          min: normalizeNullableBackendAmount(jcbFixedFeeMin),
          max: normalizeNullableBackendAmount(jcbFixedFeeMax),
        },
        mastercard_acquirer_fees_basis_points: {
          min: normalizePercentageToBasisPoints(mastercardAcquirerFeesBasisPointsMin),
          max: normalizePercentageToBasisPoints(mastercardAcquirerFeesBasisPointsMax),
        },
        mastercard_assessments_over1k_basis_points: {
          min: normalizePercentageToBasisPoints(mastercardAssessmentsOver1kBasisPointsMin),
          max: normalizePercentageToBasisPoints(mastercardAssessmentsOver1kBasisPointsMax),
        },
        mastercard_assessments_under1k_basis_points: {
          min: normalizePercentageToBasisPoints(mastercardAssessmentsUnder1kBasisPointsMin),
          max: normalizePercentageToBasisPoints(mastercardAssessmentsUnder1kBasisPointsMax),
        },
        mastercard_basis_points: {
          min: normalizePercentageToBasisPoints(mastercardBasisPointsMin),
          max: normalizePercentageToBasisPoints(mastercardBasisPointsMax),
        },
        mastercard_fixed_fee: {
          min: normalizeNullableBackendAmount(mastercardFixedFeeMin),
          max: normalizeNullableBackendAmount(mastercardFixedFeeMax),
        },
        visa_acquirer_processing_fixed_fee: {
          min: normalizeNullableBackendAmount(visaAcquirerProcessingFixedFeeMin),
          max: normalizeNullableBackendAmount(visaAcquirerProcessingFixedFeeMax),
        },
        visa_assessments_basis_points: {
          min: normalizePercentageToBasisPoints(visaAssessmentsBasisPointsMin),
          max: normalizePercentageToBasisPoints(visaAssessmentsBasisPointsMax),
        },
        visa_base_II_credit_voucher_fixed_fee: {
          min: normalizeNullableBackendAmount(visaBaseIICreditVoucherFixedFeeMin),
          max: normalizeNullableBackendAmount(visaBaseIICreditVoucherFixedFeeMax),
        },
        visa_base_II_system_file_transmission_fixed_fee: {
          min: normalizeNullableBackendAmount(visaBaseIISystemFileTransmissionFixedFeeMin),
          max: normalizeNullableBackendAmount(visaBaseIISystemFileTransmissionFixedFeeMax),
        },
        visa_basis_points: {
          min: normalizePercentageToBasisPoints(visaBasisPointsMin),
          max: normalizePercentageToBasisPoints(visaBasisPointsMax),
        },
        visa_credit_voucher_fixed_fee: {
          min: normalizeNullableBackendAmount(visaCreditVoucherFixedFeeMin),
          max: normalizeNullableBackendAmount(visaCreditVoucherFixedFeeMax),
        },
        visa_fixed_fee: {
          min: normalizeNullableBackendAmount(visaFixedFeeMin),
          max: normalizeNullableBackendAmount(visaFixedFeeMax),
        },
        visa_kilobyte_access_fixed_fee: {
          min: normalizeNullableBackendAmount(visaKilobyteAccessFixedFeeMin),
          max: normalizeNullableBackendAmount(visaKilobyteAccessFixedFeeMax),
        },
        charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        visa_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        discover_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        diners_club_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        mastercard_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        jcb_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
        american_express_charge_interchange: {
          allowed_values: strategy === 'BLENDED' ? [false] : [true, false],
        },
      },
    }
  })

  return { rules: test }
}

export default backendFeeProfileRulesModel
