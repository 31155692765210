import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetupMFAMobileAuthenticatorForm from 'components/Customer/Forms/SetupMFAMobileAuthenticatorForm/SetupMFAMobileAuthenticatorForm'
import showModalAction from 'utilities/actions/showModalAction'
import { CONFIRM_PASSWORD_MFA_MODAL } from 'constants/modalConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmPasswordMFAModal: (modalProps) => dispatch(showModalAction({ modalType: CONFIRM_PASSWORD_MFA_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class SetupMFAMobileAuthenticatorFormC extends Component {
  render() {
    return <SetupMFAMobileAuthenticatorForm {...this.props} />
  }
}

export default connect(null, mapDispatchToProps)(SetupMFAMobileAuthenticatorFormC)
