import './CustomBigNumberS.scss'
import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import Skeleton from 'components/Shared/Skeleton/Skeleton'
import isInteger from 'lodash/isInteger'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const CustomBigNumber = ({
  title = '',
  headerChartValue = '',
  headerChartDeltaPercent,
  subHeaderChartValue,
  style = {},
  isFetching = false,
  skeletonLoadingHeight = '132px',
  showSkeletonLoading = false,
}) => {
  const percent = headerChartDeltaPercent ? !isInteger(parseInt(headerChartDeltaPercent, 10)) ? 0 : headerChartDeltaPercent : ''

  const classNames = classnames('CustomBigNumber', {
    'is-fetching': isFetching && showSkeletonLoading,
  })

  return (
    <div className={classNames} style={style}>
      { isFetching && (showSkeletonLoading ? <Skeleton height={skeletonLoadingHeight} /> : <Loader />) }

      { !isFetching && (
        <div className='container flex column space-between'>
          <div className='flex space-between'>
            <div className='title'>{title}</div>
            { !!percent && <div className={`header-delta-percent ${percent >= 0 ? 'positive' : 'negative'}`}>{percent > 0 && '+'}{percent}%</div> }
          </div>
          <div>
            { !isNil(headerChartValue) && <h3 className='header'>{headerChartValue}</h3> }
            { !isEmpty(subHeaderChartValue) && <div className='sub-header'>{subHeaderChartValue}</div> }
          </div>
        </div>
      )}
    </div>
  )
}

CustomBigNumber.propTypes = {
  title: PropTypes.string,
  headerChartValue: PropTypes.string,
  headerChartDeltaPercent: PropTypes.string,
  subHeaderChartValue: PropTypes.string,
  style: PropTypes.object,
  isFetching: PropTypes.bool,
  skeletonLoadingHeight: PropTypes.string,
  showSkeletonLoading: PropTypes.bool,
}

export default CustomBigNumber
