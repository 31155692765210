import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import FeeProfileC from 'components/Customer/Pages/FeeProfile/FeeProfileC'
import FeeProfileRulesC from 'components/Customer/Pages/FeeProfileRules/FeeProfileRulesC'

import {
  FEE_PROFILE,
  FEE_PROFILE_LIMITS,
} from 'constants/language/languageConstants'

class ApplicationFeeProfileTabs extends Component {
  render() {
    const {
      applicationId,
      isFlex,
    } = this.props

    const tabs = [
      {
        id: 'fee-profile',
        name: FEE_PROFILE,
        component: FeeProfileC,
        props: {
          entityId: applicationId,
          entityType: 'APPLICATION',
        },
      },
      {
        id: 'fee-profile-limits',
        name: FEE_PROFILE_LIMITS,
        component: FeeProfileRulesC,
        condition: isFlex,
        props: {
          entityId: applicationId,
          entityType: 'APPLICATION',
        },
      },
    ]

    return (
      <div className='ApplicationFeeProfileTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default ApplicationFeeProfileTabs
