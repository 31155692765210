import {
  fieldEmpty,
} from 'utilities/validate'

const validateContactSalesForm = (values) => {
  const {
    title,
    processPayments,
  } = values

  return {
    title: fieldEmpty(title, 'Title'),
    processPayments: fieldEmpty(processPayments, 'This field'),
  }
}

export default validateContactSalesForm
