import frontendFileModel from 'utilities/create/models/frontendFileModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendFilesModel = ({ data: files }) => {
  const fileModels = map(files, (file) => frontendFileModel({ data: file }))

  return keyBy(fileModels, 'id')
}

export default frontendFilesModel
