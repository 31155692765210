import './DropdownButtonS.scss'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { t } from 'constants/language/languageConstants'
import classnames from 'classnames'
import filter from 'lodash/filter'
import map from 'lodash/map'

import {
  CHEVRON_UP_ICON,
  CHEVRON_DOWN_ICON,
} from 'constants/iconConstants'

const DropdownButton = ({
  label = '',
  variant = 'primary',
  className = 'dropdown-button',
  actions = [],
  hideChevron = false,
  alignment = 'left',
}) => {
  const [showActions, setShowActions] = useState(false)

  const handleOutsideClick = (event) => {
    const clickedOutsideButton = !event?.target.closest('.dropdown-actions')

    if (clickedOutsideButton) {
      setShowActions(false)
      document.removeEventListener('click', handleOutsideClick)
    }
  }

  const toggleActions = () => {
    if (showActions) {
      document.removeEventListener('click', handleOutsideClick)
    } else {
      document.addEventListener('click', handleOutsideClick)
    }

    setShowActions(!showActions)
  }

  useEffect(() => {
    // return function in useEffect([]) acts as componentWillUnmount
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const filteredActions = filter(actions, ({ condition = true }) => condition)
  const chevronIcon = showActions ? <i className={`fa fa-${CHEVRON_UP_ICON}`} /> : <i className={`fa fa-${CHEVRON_DOWN_ICON}`} />
  const buttonLabel = hideChevron ? label : <>{t(label)} {chevronIcon}</>

  return (
    <div className='DropdownButton'>
      <div className='dropdown-actions flex' onClick={toggleActions}>
        <Button
          label={buttonLabel}
          variant={variant}
          className={className}
        />

        <div className={`actions-items ${alignment} ${showActions ? 'open' : 'closed'}`}>
          { map(filteredActions, ({ label: actionLabel, action, className: actionClassName = '', link }) => {
            const itemClassNames = classnames('item', actionClassName)

            return link
              ? <Link className={itemClassNames} to={link} key={actionLabel}>{t(actionLabel)}</Link>
              : <div className={itemClassNames} onClick={action} key={actionLabel}>{t(actionLabel)}</div>
          })}
        </div>
      </div>
    </div>
  )
}

DropdownButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  variant: PropTypes.string,
  className: PropTypes.string,
  actions: PropTypes.array,
  hideChevron: PropTypes.bool,
  alignment: PropTypes.string,
}

export default DropdownButton
