import getUserPermissionsO from 'state-layer/orchestration/get/getUserPermissionsO'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import co from 'co'

function * getCredentialPermissionsO ({
  id,
  credentials,
}) {
  const { data: permissionsResponse } = yield getUserPermissionsO({ id, credentials })
  const permissions = get(permissionsResponse, '0.permissions')
  const formattedPermissions = keyBy(permissions, 'name')

  const data = {
    id: get(credentials, 'id'),
    permissions: formattedPermissions,
  }

  return createSuccessResponse({ data })
}

export default co.wrap(getCredentialPermissionsO)
