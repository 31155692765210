import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlertDetails from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertDetails/MonitoringAlertDetails'
import Loader from 'components/Shared/Loader/Loader'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  CADENCE,
  LINKED_APPLICATION,
  LINKED_MERCHANT,
  TRANSACTION_MONITORING_ACTUAL_VALUE,
  TRANSACTION_MONITORING_LOOKBACK,
  TRANSACTION_MONITORING_LOOKBACK_END_DATE,
  TRANSACTION_MONITORING_LOOKBACK_START_DATE,
  TRANSACTION_MONITORING_TARGET,
  TRANSACTION_MONITORING_THRESHOLD,
} from 'constants/language/languageConstants'

import {
  APPLICATION,
} from 'constants/flowConstants'
import { FETCHING } from 'constants/reducerConstants'


const mapStateToProps = (state, props) => {
  const monitoringAlert = get(props, 'monitoringAlert')
  const isFetching = get(state, `monitoringAlertsR.${FETCHING}`)

  const [
    displayActualValue,
    displayThresholdValue,
    target,
    linkedApplication,
    linkedType,
    displayPeriodStart,
    displayPeriodEnd,
    displayIntervalValue,
    cadence,
    alertType,
  ] = getMany(monitoringAlert, [
    'displayResult',
    'monitoringRuleDetails.displayThresholdValue',
    'groupedBy',
    'linkedName',
    'linkedType',
    'displayPeriodStart',
    'displayPeriodEnd',
    'monitoringRuleDetails.displayIntervalValue',
    'monitoringRuleDetails.displayCadenceValue',
    'monitoringRuleDetails.type',
  ])

  const monitoringAlertInfoCardData = convertPageSectionDataToV2([
    {
      label: TRANSACTION_MONITORING_ACTUAL_VALUE,
      value: displayActualValue,
    },
    {
      label: TRANSACTION_MONITORING_THRESHOLD,
      value: displayThresholdValue,
    },
  ], 1)

  const monitoringAlertDetailsCardData = convertPageSectionDataToV2([
    {
      label: TRANSACTION_MONITORING_TARGET,
      value: target,
    },
    {
      label: linkedType === APPLICATION ? LINKED_APPLICATION : LINKED_MERCHANT,
      value: linkedApplication,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK_START_DATE,
      value: displayPeriodStart,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK_END_DATE,
      value: displayPeriodEnd,
    },
    {
      label: TRANSACTION_MONITORING_LOOKBACK,
      value: displayIntervalValue,
    },
    {
      label: CADENCE,
      value: cadence,
    },
  ], 3)

  return {
    monitoringAlertInfoCardData,
    monitoringAlertDetailsCardData,
    monitoringAlert,
    alertType,
  }
}

class MonitoringAlertDetailsC extends Component {
  render() {
    const {
      isFetching,
    } = this.props

    return (
      <>
        {isFetching && <Loader />}
        {!isFetching && (
        <MonitoringAlertDetails
          {...this.props}
        />
        )}
      </>
    )
  }
}

export default connect(mapStateToProps)(MonitoringAlertDetailsC)
