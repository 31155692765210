import paymentsAPI from 'utilities/api/paymentsAPI'
import { THIRD_PARTY_TOKENS } from 'constants/apiConstants'

const postGenericPlaidTokenAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    path: THIRD_PARTY_TOKENS,
    meta,
    credentials,
    values,
  })
}

export default postGenericPlaidTokenAPI
