import React, { Component } from 'react'
import { connect } from 'react-redux'
import PasswordInputField from './PasswordInputField'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const { passwordRequirements, input, formName } = props

  const showPasswordRequirements = get(state, ['form', formName, 'fields', 'password', 'active'], false)

  // TODO: move from a forEach to a map to not mutate the object directly
  if (showPasswordRequirements && !isEmpty(passwordRequirements)) {
    forEach(passwordRequirements, (req) => {
      const { regex } = req
      const { value } = input

      // check that char limit is not over max char limit to avoid ReDos attacks via regex
      if (value && value.length > 100) {
        req.passed = false
        return
      }

      const fieldRegex = new RegExp(regex)
      const passedReq = !!value.match(fieldRegex)
      req.passed = passedReq
    })
  }

  return { passwordRequirements, showPasswordRequirements }
}

class PasswordInputFieldC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passwordType: 'password',
    }
  }

  togglePasswordType = () => {
    const { passwordType } = this.state

    if (passwordType === 'password') {
      this.setState({ passwordType: 'text' })
    } else {
      this.setState({ passwordType: 'password' })
    }
  }

  render() {
    const { togglePasswordType } = this

    const { passwordType } = this.state

    return (
      <PasswordInputField
        {...this.props}
        togglePasswordType={togglePasswordType}
        passwordType={passwordType}
      />
    )
  }
}

export default connect(mapStateToProps)(PasswordInputFieldC)
