import getTransferO from './getTransferO'
import getSplitTransferAPI from 'api/finix/get/getSplitTransferAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import frontendTransferModel from 'utilities/create/models/frontendTransferModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendFeesModel from 'utilities/create/models/frontendFeesModel'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import getMany from 'utilities/get/getMany'
import merge from 'lodash/merge'
import co from 'co'
import get from 'lodash/get'

import {
  APPLICATION,
  MERCHANT,
  IDENTITY,
  PAYMENT_INSTRUMENT,
  SPLIT_TRANSFER_FEES,
} from 'constants/apiConstants'

function * getSplitTransferO ({
  id,
  credentials,
}) {
  const { data: response } = yield getSplitTransferAPI({ id, credentials })

  const [
    parentPaymentId,
    applicationId,
    identityId,
    merchantId,
  ] = getMany(response, [
    'parent_transfer_id',
    'application_id',
    'identity_id',
    'merchant_id',
  ])

  let parentTransferModel

  if (parentPaymentId) {
    const { data: parentTransferResponse } = yield getTransferO({ id: parentPaymentId, credentials })
    parentTransferModel = parentTransferResponse ? frontendTransferModel({ data: get(parentTransferResponse, '[0]') }) : undefined

    const [
      paymentInstrumentId,
      parentApplicationId,
      merchantIdentityId,
    ] = getMany(parentTransferModel, [
      'paymentInstrumentId',
      'application',
      'merchantIdentityId',
    ])

    if (paymentInstrumentId) {
      const paymentInstrumentPath = { path: PAYMENT_INSTRUMENT({ id: paymentInstrumentId }), credentials }
      const { data: paymentInstrumentResponse } = yield getPaymentsAPI(paymentInstrumentPath)
      const paymentInstrumentModel = paymentInstrumentResponse ? frontendPaymentInstrumentModel({ data: normalizeResponse({ content: paymentInstrumentResponse }) }) : undefined
      parentTransferModel = merge({}, parentTransferModel, { paymentInstrument: paymentInstrumentModel })
    }

    if (parentApplicationId) {
      const applicationPath = { path: APPLICATION({ applicationId: parentApplicationId }), credentials }
      const { data: applicationResponse } = yield getPaymentsAPI(applicationPath)
      const applicationModel = applicationResponse ? frontendApplicationModel({ data: normalizeResponse({ content: applicationResponse }) }) : undefined
      parentTransferModel = merge({}, parentTransferModel, { application: applicationModel })
    }

    if (merchantIdentityId) {
      const identityPath = { path: IDENTITY({ identityId: merchantIdentityId }), credentials }
      const { data: identityResponse } = yield getPaymentsAPI(identityPath)
      const identityModel = identityResponse ? frontendIdentityModel({ data: normalizeResponse({ content: identityResponse }) }) : undefined
      parentTransferModel = merge({}, parentTransferModel, { identity: identityModel })
    }
  }

  let applicationModel
  let identityModel
  let merchantModel

  if (applicationId) {
    const applicationPath = { path: APPLICATION({ applicationId }), credentials }
    const { data: applicationResponse } = yield getPaymentsAPI(applicationPath)
    applicationModel = applicationResponse ? frontendApplicationModel({ data: normalizeResponse({ content: applicationResponse }) }) : undefined
  }

  if (identityId) {
    const identityPath = { path: IDENTITY({ identityId }), credentials }
    const { data: identityResponse } = yield getPaymentsAPI(identityPath)
    identityModel = identityResponse ? frontendIdentityModel({ data: normalizeResponse({ content: identityResponse }) }) : undefined
  }

  if (merchantId) {
    const merchantPath = { path: MERCHANT({ merchantId }), credentials }
    const { data: merchantResponse } = yield getPaymentsAPI(merchantPath)
    merchantModel = merchantResponse ? frontendMerchantModel({ data: normalizeResponse({ content: merchantResponse }) }) : undefined
  }

  const feesPath = { path: SPLIT_TRANSFER_FEES({ splitTransferId: id }), credentials }
  const { data: feesResponse } = yield getPaymentsAPI(feesPath)
  const feesModel = feesResponse ? frontendFeesModel({ data: normalizeResponse({ content: get(feesResponse, '_embedded.transfers') }) }) : undefined

  const normalizedTransfer = normalizeResponse({ content: response })

  const splitTransfer = merge({}, normalizedTransfer, {
    parentTransfer: parentTransferModel,
    application: applicationModel,
    identity: identityModel,
    merchant: merchantModel,
    fees: feesModel,
  })

  return createSuccessResponse({
    data: [splitTransfer],
  })
}

export default co.wrap(getSplitTransferO)
