import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import removeUndefined from 'utilities/remove/removeUndefined'
import { goBack } from 'state-layer/history'
import values from 'lodash/values'
import get from 'lodash/get'

import {
  POST_DASHBOARD_V2_EMAILS_F_REQUEST,
  POST_MEMBER_F_REQUEST,
  POST_USERS_EMAIL_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_TEAM_MEMBER,
  SEND_WELCOME_EMAIL,
} from 'constants/amplitudeConstants'


const postTeamMemberRequest = ({
  dashboardAccountName,
  firstName,
  lastName,
  email,
  rolesChoice,
  host,
  dashboardId,
  pushToCardEnabled,
  paymentFacilitatorEnabled,
  credentials,
  dispatch,
  isFlex,
  entityId,
  entityTypeKey,
}) => createAction({
  type: POST_MEMBER_F_REQUEST,
  values: removeUndefined({
    credential_name: dashboardAccountName,
    first_name: firstName,
    last_name: lastName,
    email_address: email,
    dashboard_user_role_id: rolesChoice,
    dashboard_host: host,
    dashboard_id: dashboardId,
    push_to_card_enabled: pushToCardEnabled,
    payment_facilitator_enabled: paymentFacilitatorEnabled,
    [entityTypeKey]: entityId,
  }),
  meta: {
    successCallback: ({ newValues }) => {
      const dashboardUserId = get(values(newValues), ['[0]', 'dashboard_user_id'])
      const formattedEmail = email.toLowerCase()
      // send welcome email
      const dashboardConfig = getCurrentDashboardConfig()
      const dashboardConfigEntityId = get(dashboardConfig, 'entityId')

      const valuesToSubmitForEmail = isFlex ? {
        email_recipient: formattedEmail,
        entity_id: dashboardConfigEntityId || entityId,
        email_template: 'PAYMENTS_DASHBOARD_WELCOME_EMAIL',
      } : {
        recipient_email: formattedEmail,
        email_template_name: `${getCurrentPlatformName()}-welcome-email-password`,
        email_template_data: {
          url: 'www.finixpayments.com', // BE is not using this data
        },
      }

      dispatch({
        type: isFlex ? POST_DASHBOARD_V2_EMAILS_F_REQUEST : POST_USERS_EMAIL_F_REQUEST,
        payload: {
          id: dashboardUserId,
          values: valuesToSubmitForEmail,
        },
        meta: {
          actionId: sendAmplitudeActionEvent(SEND_WELCOME_EMAIL, {
            userId: dashboardUserId,
            credentials,
          }),
        },
      })

      // redirect user back to team members page
      dispatch(goBack)
    },
    actionId: sendAmplitudeActionEvent(CREATE_TEAM_MEMBER, {
      credentials,
      email,
      role: rolesChoice,
    }),
  },
})

export default postTeamMemberRequest
