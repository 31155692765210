import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditPayoutProfileForm from 'components/Customer/Forms/EditPayoutProfileForm/EditPayoutProfileForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import get from 'lodash/get'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)
  const applicationId = get(props, 'applicationId')

  const [
    pushToCard,
    pullFromCard,
    pushToAch,
    pullFromAch,
    pushToSameDayAch,
    pullFromSameDayAch,
  ] = getMany(props, [
    'disbursementsCardPushEnabled',
    'disbursementsCardPullEnabled',
    'disbursementsAchPushEnabled',
    'disbursementsAchPullEnabled',
    'disbursementsSameDayAchPushEnabled',
    'disbursementsSameDayAchPullEnabled',
  ])

  const initialValues = {
    disbursementsCardPushEnabled: pushToCard,
    disbursementsCardPullEnabled: pullFromCard,
    disbursementsAchPushEnabled: pushToAch,
    disbursementsAchPullEnabled: pullFromAch,
    disbursementsSameDayAchPushEnabled: pushToSameDayAch,
    disbursementsSameDayAchPullEnabled: pullFromSameDayAch,
  }

  return {
    credentials,
    pushToCard,
    pullFromCard,
    pushToAch,
    pullFromAch,
    applicationId,
    initialValues,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditPayoutProfileFormC extends Component {
  render() {
    return (
      <EditPayoutProfileForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayoutProfileFormC)
