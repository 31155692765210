import './PurchaseS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import TransferReceiptHistoryC from 'components/Customer/Pages/TransferReceiptHistory/TransferReceiptHistoryC'
import isEmpty from 'lodash/isEmpty'

import {
  DETAILS,
  BUYER_DETAILS,
  PAYMENT_LINK_DETAILS,
} from 'constants/language/languageConstants'

const Purchase = ({
  actions = [],
  contextBarData = {},
  headerData = {},
  purchaseData = [],
  paymentLinkDetailsData = [],
  buyerData = [],
  isFetching = false,
  paymentInstrument = {},
  buyerDetailsPath,
  buyerIdentityId = '',
  paymentLinkId = '',
  paymentLinkPath,
  transfer = {},
  receipts = [],
}) => {
  return (
    <div className='Purchase'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={purchaseData}
        isFetching={isFetching}
        className='details-section'
      />

      <PageSectionV2C
        title={BUYER_DETAILS}
        columns={buyerData}
        isFetching={isFetching}
        clickToCopies={[{ content: buyerIdentityId }]}
        asideTitle={buyerDetailsPath}
        className='buyer-details-section'
      />

      <PageSectionV2C
        title={PAYMENT_LINK_DETAILS}
        columns={paymentLinkDetailsData}
        isFetching={isFetching}
        clickToCopies={[{ content: paymentLinkId }]}
        asideTitle={paymentLinkPath}
        className='payment-link-details-section'
      />

      { !isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          paymentInstrument={paymentInstrument}
          isFetching={isFetching}
        />
      )}

      <TransferReceiptHistoryC
        receipts={receipts}
        transfer={transfer}
        isFetching={isFetching}
      />
    </div>
  )
}

Purchase.propTypes = {
  actions: PropTypes.array,
  contextBarData: PropTypes.object,
  headerData: PropTypes.object,
  purchaseData: PropTypes.array,
  paymentLinkDetailsData: PropTypes.array,
  buyerData: PropTypes.array,
  isFetching: PropTypes.bool,
  paymentInstrument: PropTypes.object,
  buyerDetailsPath: PropTypes.object,
  buyerIdentityId: PropTypes.string,
  paymentLinkId: PropTypes.string,
  paymentLinkPath: PropTypes.object,
  transfer: PropTypes.object,
  receipts: PropTypes.array,
}

export default Purchase
