import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import CompanyBusinessInfo from './CompanyBusinessInfo'
import Address from 'components/Customer/Shared/Display/Address/Address'
import { isFlexPlatform } from 'constants/flexConstants'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import validateWebsiteLink from 'utilities/validate/validateWebsiteLink'
import formatRiskProfile from 'utilities/formatters/formatRiskProfile'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import getMany from 'utilities/get/getMany'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import get from 'lodash/get'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

import {
  isRoleMerchant,
  isRolePlatform as isRolePlatformCheck,
  isRolePartner as isRolePartnerCheck,
} from 'utilities/validate/checkRoleCredentials'

import {
  getApplicationSelector,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

import {
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  WEBSITE,
  OWNERSHIP_TYPE,
  BUSINESS_TYPE,
  BUSINESS_TAX_ID_PROVIDED,
  INCORPORATION_DATE,
  BUSINESS_PHONE,
  BUSINESS_ADDRESS,
  EDIT,
} from 'constants/language/languageConstants'

import {
  UPDATE_PLATFORM_LEVEL_APPLICATION_CARD_LIMITS_PATH,
  UPDATE_PLATFORM_LEVEL_APPLICATION_ACH_LIMITS_PATH,
  UPDATE_PLATFORM_LEVEL_RECIPIENT_CARD_LIMITS_PATH,
  UPDATE_PLATFORM_LEVEL_RECIPIENT_ACH_LIMITS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')
  const platformId = get(props, 'platformId')
  const application = getApplicationSelector(state, applicationId)
  const identity = get(props, 'identity')
  const identityMerchants = get(identity, 'merchants')
  const merchant = get(values(identityMerchants), '[0]')
  const merchantId = get(merchant, 'id')
  const isFetching = get(props, 'isFetching')
  const riskProfile = get(application, 'riskProfile')
  const isRoleMerchantCredential = isRoleMerchant({ credentials })
  const isFlex = isFlexPlatform()
  const isStandAloneUnderwriting = isStandaloneUnderwritingPlatform()
  const disbursementRules = getDisbursementRulesSelector(state)
  const isRolePlatform = isRolePlatformCheck({ credentials })

  const [
    applicationACHRules,
    applicationCardRules,
    recipientACHRules,
    recipientCardRules,
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
  ] = getMany(disbursementRules, [
    'applicationACHRules',
    'applicationCardRules',
    'recipientACHRules',
    'recipientCardRules',
    'applicationACHRulesMaxTransactionAmount',
    'applicationCardRulesMaxTransactionAmount',
    'recipientACHRulesMaxTransactionAmount',
    'recipientCardRulesMaxTransactionAmount',
  ])

  const noDisbursementRulesSet = isEmpty(applicationACHRules) && isEmpty(applicationCardRules) && isEmpty(recipientACHRules) && isEmpty(recipientCardRules)

  const setApplicationCardVelocityLimitActions = [
    {
      label: EDIT,
      condition: isRolePlatform,
      action: () => goToPath({
        pathname: UPDATE_PLATFORM_LEVEL_APPLICATION_CARD_LIMITS_PATH({ credentialId, applicationId }),
      }),
    },
  ]

  const setApplicationACHVelocityLimitActions = [
    {
      label: EDIT,
      condition: isRolePlatform,
      action: () => goToPath({
        pathname: UPDATE_PLATFORM_LEVEL_APPLICATION_ACH_LIMITS_PATH({ credentialId, applicationId }),
      }),
    },
  ]

  const setRecipientCardVelocityLimitActions = [
    {
      label: EDIT,
      condition: isRolePlatform,
      action: () => goToPath({
        pathname: UPDATE_PLATFORM_LEVEL_RECIPIENT_CARD_LIMITS_PATH({ credentialId, applicationId }),
      }),
    },
  ]

  const setRecipientACHVelocityLimitActions = [
    {
      label: EDIT,
      condition: isRolePlatform,
      action: () => goToPath({
        pathname: UPDATE_PLATFORM_LEVEL_RECIPIENT_ACH_LIMITS_PATH({ credentialId, applicationId }),
      }),
    },
  ]

  const {
    businessAddress,
    businessName,
    businessTaxIdProvided,
    displayBusinessPhone,
    displayBusinessType,
    displayIncorporationDate,
    displayOwnershipType,
    doingBusinessAs,
    website,
  } = identity

  const businessInfoSectionData = [
    {
      type: 'data',
      data: [
        {
          label: BUSINESS_NAME,
          value: businessName,
        },
        {
          label: DOING_BUSINESS_AS,
          value: doingBusinessAs,
        },
        {
          label: BUSINESS_TYPE,
          value: displayBusinessType,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: WEBSITE,
          value: validateWebsiteLink({ website }) ? <a className='text-link' href={website} target='blank'>{website}</a> : website,
        },
        {
          label: BUSINESS_PHONE,
          value: displayBusinessPhone,
        },
        {
          label: BUSINESS_ADDRESS,
          value: <Address address={businessAddress} />,
        },
      ],
    },
    {
      type: 'data',
      data: [
        {
          label: INCORPORATION_DATE,
          value: displayIncorporationDate,
        },
        {
          label: OWNERSHIP_TYPE,
          value: displayOwnershipType,
        },
        {
          label: BUSINESS_TAX_ID_PROVIDED,
          value: businessTaxIdProvided,
        },
      ],
    },
  ]

  const riskProfileSectionData = formatRiskProfile(riskProfile)

  return {
    credentials,
    businessInfoSectionData,
    riskProfile,
    riskProfileSectionData,
    isFetching,
    platformId,
    applicationId,
    merchantId,
    isFlex,
    isRoleMerchantCredential,
    isStandAloneUnderwriting,
    isDisbursementsDashboard: isDisbursementsPartnerDashboard(state),
    applicationACHRules,
    applicationCardRules,
    recipientACHRules,
    recipientCardRules,
    applicationACHRulesMaxTransactionAmount,
    applicationCardRulesMaxTransactionAmount,
    recipientACHRulesMaxTransactionAmount,
    recipientCardRulesMaxTransactionAmount,
    isRolePlatform,
    isRolePartner: isRolePartnerCheck({ credentials }),
    noDisbursementRulesSet,
    setApplicationCardVelocityLimitActions,
    setApplicationACHVelocityLimitActions,
    setRecipientCardVelocityLimitActions,
    setRecipientACHVelocityLimitActions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDisbursementRules: ({ entityId, credentials }) => dispatch(getDisbursementRulesRequest({ entityId, credentials })),
  }
}

class CompanyBusinessInfoC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      platformId,
      isDisbursementsDashboard,
      getDisbursementRules,
      isRolePlatform,
      isRolePartner,
    } = this.props

    if (isDisbursementsDashboard && platformId && isRolePlatform) {
      getDisbursementRules({
        credentials,
        entityId: platformId,
      })
    }

    if (isDisbursementsDashboard && applicationId && isRolePartner) {
      getDisbursementRules({
        credentials,
        entityId: applicationId,
      })
    }
  }


  render() {
    return (
      <CompanyBusinessInfo
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBusinessInfoC)
