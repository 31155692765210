import selectOption from 'utilities/forms/selectOption'
import invert from 'lodash/invert'
import {
  getApplicationSelector,
  getIdentitySelector,
  getPlatformSelector,
} from 'state-layer/selectors'

export const roleToEntityIdMap = {
  ROLE_PLATFORM: 'platformId',
  ROLE_PARTNER: 'applicationId',
  ROLE_MERCHANT: 'identityId',
}

export const roleToEntityTypeMap = {
  ROLE_PLATFORM: 'PLATFORM',
  ROLE_PARTNER: 'APPLICATION',
  ROLE_MERCHANT: 'IDENTITY',
}

export const entityTypeToEntityTypeBEKeyMap = {
  PLATFORM: 'platform_id',
  APPLICATION: 'application_id',
  IDENTITY: 'identity_id',
}

export const entityTypeToSelectorMap = {
  PLATFORM: getPlatformSelector,
  APPLICATION: getApplicationSelector,
  IDENTITY: getIdentitySelector,
}

export const entityTypeToRoleMap = invert(roleToEntityTypeMap)

export const MEMBER_STATE_OPTIONS = [
  selectOption('All', 'ALL'),
  selectOption('Active', 'true'),
  selectOption('Inactive', 'false'),
]

export const ROLE_STATE_OPTIONS = [
  selectOption('Enabled', true),
  selectOption('Disabled', false),
]

export const ROLE_TYPE_OPTIONS = [
  selectOption('All', 'ALL'),
  selectOption('Default', 'SYSTEM'),
  selectOption('Custom', 'CUSTOM'),
]
