import showModalAction from 'utilities/actions/showModalAction'
import get from 'lodash/get'
import { CONNECT_DEVICE_FOR_PAYMENT_MODAL } from 'constants/modalConstants'

const submitCreateTransactionForm = (values, dispatch, props) => {
  const {
    isSale,
    onSubmit,
    currency,
    sendMethod,
    credentials,
  } = props

  const {
    amount,
  } = values

  if (sendMethod === 'manual') {
    onSubmit(values, dispatch, props)
  } else {
    const deviceId = get(values, 'device.value')
    const deviceName = get(values, 'device.label')

    dispatch(showModalAction({
      modalType: CONNECT_DEVICE_FOR_PAYMENT_MODAL,
      className: 'modal-md no-pad',
      modalProps: {
        amount,
        currency,
        deviceId,
        deviceName,
        credentials,
        isSale,
      },
    }))
  }
}

export default submitCreateTransactionForm
