import './MiniNavS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import NotificationsDropdownC from 'components/Customer/Shared/Notifications/NotificationsDropdownC'
import HelpC from 'components/Customer/Shared/Help/HelpC'
import { HELP } from 'constants/language/languageConstants'

import {
  NOTIFICATIONS_ICON,
  CIRCLE_ICON,
} from 'constants/iconConstants'

const MiniNav = ({
  showNotificationsIcon = true,
  showNotifications = false,
  toggleNotificationsDialog = () => {},
  newNotificationsIndicator = false,
  showSupport = false,
  showSupportToggle = true,
  toggleSupportDropdown = () => {},
  formattedPath = '',
}) => {
  return (
    <div className='MiniNav flex flex-end items-center'>
      {showSupportToggle && (
      <div
        className='nav-item support-container'
        role='button'
        tabIndex='0'
        aria-label='support'
        aria-expanded={showSupport}
        onKeyDown={toggleSupportDropdown}
        onClick={toggleSupportDropdown}
      >
        <div className={`support-icon ${showSupport && 'support-active-state'}`}>{HELP}</div>
        {showSupport && <HelpC formattedPath={formattedPath} />}
      </div>
      )}

      {showNotificationsIcon && (
        <div
          className='nav-item notifications-container'
          role='button'
          tabIndex='0'
          aria-label='notifications'
          aria-expanded={showNotifications}
        >
          <span
            className='notification-icons fa-stack fa-2x'
            onKeyDown={toggleNotificationsDialog}
            onClick={toggleNotificationsDialog}
          >
            <i className={`notifications-icon fal fa-${NOTIFICATIONS_ICON} fa-stack-1x ${showNotifications && 'notification-active-state'}`} />
            {newNotificationsIndicator && <i className={`indicator-icon fas fa-${CIRCLE_ICON} fa-stack-2x`} />}
          </span>
          { showNotifications && <NotificationsDropdownC /> }
        </div>
      )}
    </div>
  )
}

MiniNav.propTypes = {
  showNotificationsIcon: PropTypes.bool,
  showNotifications: PropTypes.bool,
  toggleNotificationsDialog: PropTypes.func,
  newNotificationsIndicator: PropTypes.bool,
  showSupport: PropTypes.bool,
  showSupportToggle: PropTypes.bool,
  toggleSupportDropdown: PropTypes.func,
  formattedPath: PropTypes.string,
}

export default MiniNav
