import { getDispatch } from 'state-layer/configureStore'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import { SUCCESS } from 'constants/flashNotificationConstants'

const copyToClipboard = (text) => {
  const dispatch = getDispatch()
  const input = document.createElement('input')

  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()

  const result = document.execCommand('copy')

  document.body.removeChild(input)

  dispatch(flashNotificationAction({
    message: 'Successfully copied to clipboard.',
    type: SUCCESS,
    time: 3000,
  }))
}

export default copyToClipboard
