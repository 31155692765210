import './ResendUnacceptedEventsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  RESEND,
  CANCEL,
  RESEND_UNACCEPTED_EVENTS_DESCRIPTION,
} from 'constants/language/languageConstants'

const ResendUnacceptedEvents = ({
  closeModal = () => {},
  resendUnacceptedEvents = () => {},
}) => {
  return (
    <div className='ResendUnacceptedEvents'>
      <div className='confirmation-description'>{RESEND_UNACCEPTED_EVENTS_DESCRIPTION }</div>

      <div className='btn-container flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={resendUnacceptedEvents} label={RESEND} />
      </div>
    </div>
  )
}

ResendUnacceptedEvents.propTypes = {
  closeModal: PropTypes.func,
  resendUnacceptedEvents: PropTypes.func,
}

export default ResendUnacceptedEvents
