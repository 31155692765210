import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Refunds/columnDescriptors'
import { TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { getTransferMerchantsBuyersSelector } from 'state-layer/selectors'

import {
  GET_TRANSFERS,
  TRANSFER,
} from 'constants/flowConstants'

const ApplicationRefunds = ({
  initialQueries = {},
  allowedFilters = [],
  refundsPath = '',
  isFetching = false,
  applicationId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='ApplicationRefunds'>
      <TableC
        flow={GET_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getTransferMerchantsBuyersSelector}
        initialQueries={initialQueries}
        linksKey={TRANSFERS}
        allowedFilters={allowedFilters}
        path={refundsPath}
        isFetching={isFetching}
        fileNameCSV={`Application_${applicationId}_Refunds`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={TRANSFER}
      />
    </div>
  )
}

ApplicationRefunds.propTypes = {
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  refundsPath: PropTypes.string,
  isFetching: PropTypes.bool,
  applicationId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default ApplicationRefunds
