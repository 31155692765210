import get from 'lodash/get'
import merge from 'lodash/merge'

const getCredentialAxiosConfigs = ({
  credentials,
  contentType = 'application/json',
  headers = {},
  customApiConfigs = {},
}) => {
  const authToken = get(credentials, 'authToken')

  const config = authToken ? {
    headers: merge({}, {
      Authorization: `Basic ${authToken}`,
      'Content-Type': contentType,
    }, headers),
    ...customApiConfigs,
  } : customApiConfigs

  return config
}

export default getCredentialAxiosConfigs
