import get from 'lodash/get'
import map from 'lodash/map'

const payload = {
  '_embedded' : {
    'permissions' : [ {
      'name' : 'subscription:subscription_item:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION ITEM'
    }, {
      'name' : 'processing:transfer:read',
      'category' : 'transfer',
      'alias_name' : 'READ TRANSFER'
    }, {
      'name' : 'subscription:subscription_schedule:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION SCHEDULE'
    }, {
      'name' : 'processing:processor:read',
      'category' : 'application_processor',
      'alias_name' : 'READ PROCESSOR'
    }, {
      'name' : 'processing:risk_profile:list',
      'category' : 'risk_profile',
      'alias_name' : 'LIST RISK PROFILE'
    }, {
      'name' : 'processing:view:application_list_all',
      'category' : 'view',
      'alias_name' : 'VIEW ALL APPLICATIONS PERMISSION'
    }, {
      'name' : 'processing:fee_strategy:update',
      'category' : 'fee_strategy',
      'alias_name' : 'UPDATE FEE STRATEGY'
    }, {
      'name' : 'processing:application:processors_list',
      'category' : 'application',
      'alias_name' : 'LIST APPLICATION PROCESSORS'
    }, {
      'name' : 'processing:settlement:transfers_list',
      'category' : 'settlement',
      'alias_name' : 'LIST SETTLEMENT TRANSFERS'
    }, {
      'name' : 'processing:reserve_profile:list',
      'category' : 'reserve_profile',
      'alias_name' : 'LIST RESERVE PROFILE'
    }, {
      'name' : 'processing:fee_profile:create',
      'category' : 'fee_profile',
      'alias_name' : 'CREATE FEE PROFILE'
    }, {
      'name' : 'processing:merchant_profile:create',
      'category' : 'merchant_profile',
      'alias_name' : 'CREATE MERCHANT PROFILE'
    }, {
      'name' : 'processing:webhook:create',
      'category' : 'webhook',
      'alias_name' : 'CREATE WEBHOOK'
    }, {
      'name' : 'processing:fee_strategy:list',
      'category' : 'fee_strategy',
      'alias_name' : 'LIST FEE STRATEGY'
    }, {
      'name' : 'subscription:subscription_enrollment:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION ENROLLMENT'
    }, {
      'name' : 'processing:application:merchant_profile_update',
      'category' : 'application',
      'alias_name' : 'UPDATE APPLICATION MERCHANT PROFILE'
    }, {
      'name' : 'processing:application:processor_disable',
      'category' : 'application',
      'alias_name' : 'APPLICATION DISABLE PROCESSOR'
    }, {
      'name' : 'processing:view:identity_list_all',
      'category' : 'view',
      'alias_name' : 'VIEW ALL IDENTITIES PERMISSION'
    }, {
      'name' : 'processing:authorization:list',
      'category' : 'authorization',
      'alias_name' : 'LIST AUTHORIZATION'
    }, {
        'name' : 'processing:transaction:list',
        'category' : 'transaction',
        'alias_name' : 'LIST TRANSACTION'
    }, {
      'name' : 'processing:fee_strategy:create',
      'category' : 'fee_strategy',
      'alias_name' : 'CREATE FEE STRATEGY'
    }, {
      'name' : 'subscription:subscription_amount:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION AMOUNT'
    }, {
      'name' : 'settlement:settlement_group_settings:read',
      'category' : 'settlement',
      'alias_name' : 'READ SETTLEMENT SETTING GROUP'
    }, {
      'name' : 'settlement:payout_plans:update',
      'category' : 'payout_plans',
      'alias_name' : 'UPDATE PAYOUT PLAN'
    }, {
      'name' : 'processing:platform_webhook:create',
      'category' : 'platform_webhook',
      'alias_name' : 'CREATE PLATFORM WEBHOOK'
    }, {
      'name' : 'processing:processor:update',
      'category' : 'application_processor',
      'alias_name' : 'UPDATE PROCESSOR'
    }, {
      'name' : 'processing:verification:list',
      'category' : 'verification',
      'alias_name' : 'LIST VERIFICATION'
    }, {
      'name' : 'subscription:subscription_schedule:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION SCHEDULE'
    }, {
      'name' : 'processing:merchant:list',
      'category' : 'merchant',
      'alias_name' : 'LIST MERCHANT'
    }, {
      'name' : 'authentication:user:update',
      'category' : 'user',
      'alias_name' : 'UPDATE USER'
    }, {
      'name' : 'processing:dispute:evidence_submit',
      'category' : 'dispute',
      'alias_name' : 'DISPUTE SUBMIT EVIDENCE'
    }, {
      'name' : 'processing:instrument:list',
      'category' : 'instrument',
      'alias_name' : 'LIST INSTRUMENT'
    }, {
      'name' : 'search:search:read',
      'category' : 'search',
      'alias_name' : 'READ SEARCH'
    }, {
      'name' : 'risk:rule_instance:update',
      'category' : 'rule_instance',
      'alias_name' : 'UPDATE RISK RULE'
    }, {
      'name' : 'processing:dispute:read',
      'category' : 'dispute',
      'alias_name' : 'READ DISPUTE'
    }, {
      'name' : 'processing:verification:update',
      'category' : 'verification',
      'alias_name' : 'UPDATE VERIFICATION'
    }, {
      'name' : 'processing:black_listed_instrument:read',
      'category' : 'black_listed_instrument',
      'alias_name' : 'READ LIST BLACKED INSTRUMENT'
    }, {
      'name' : 'processing:reserve_profile:create',
      'category' : 'reserve_profile',
      'alias_name' : 'CREATE RESERVE PROFILE'
    }, {
      'name' : 'processing:dispute:files_list',
      'category' : 'dispute',
      'alias_name' : 'LIST DISPUTE FILES'
    }, {
      'name' : 'processing:application_profile:list',
      'category' : 'application_profile',
      'alias_name' : 'LIST APPLICATION PROFILE'
    }, {
      'name' : 'subscription:subscription_item:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION ITEM'
    }, {
      'name' : 'settlement:funding_instruction:update',
      'category' : 'funding_instruction',
      'alias_name' : 'UPDATE FUNDING INSTRUCTION'
    }, {
      'name' : 'processing:instrument:update',
      'category' : 'instrument',
      'alias_name' : 'UPDATE INSTRUMENT'
    }, {
      'name' : 'processing:verification:read',
      'category' : 'verification',
      'alias_name' : 'READ VERIFICATION'
    }, {
      'name' : 'processing:settlement:read',
      'category' : 'settlement',
      'alias_name' : 'READ SETTLEMENT'
    }, {
      'name' : 'processing:instrument_update:list',
      'category' : 'instrument_update',
      'alias_name' : 'LIST INSTRUMENT UPDATE'
    }, {
      'name' : 'processing:settlement:update',
      'category' : 'settlement',
      'alias_name' : 'UPDATE SETTLEMENT'
    }, {
      'name' : 'processing:reserve_profile:read',
      'category' : 'reserve_profile',
      'alias_name' : 'READ RESERVE PROFILE'
    }, {
      'name' : 'processing:transfer:refund',
      'category' : 'transfer',
      'alias_name' : 'REFUND TRANSFER'
    }, {
      'name' : 'subscription:subscription_group:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION GROUP'
    }, {
      'name' : 'settlement:funding_instruction:create',
      'category' : 'funding_instruction',
      'alias_name' : 'CREATE FUNDING INSTRUCTION'
    }, {
      'name' : 'processing:identity:update',
      'category' : 'identity',
      'alias_name' : 'UPDATE IDENTITY'
    }, {
      'name' : 'subscription:subscription_amount:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION AMOUNT'
    }, {
      'name' : 'subscription:subscription_schedule:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION SCHEDULE'
    }, {
      'name' : 'search:search:list',
      'category' : 'search',
      'alias_name' : 'LIST SEARCH'
    }, {
      'name' : 'processing:transfer:update',
      'category' : 'transfer',
      'alias_name' : 'UPDATE TRANSFER'
    }, {
      'name' : 'subscription:subscription_amount:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION AMOUNT'
    }, {
      'name' : 'processing:dispute:update',
      'category' : 'dispute',
      'alias_name' : 'UPDATE DISPUTE'
    }, {
      'name' : 'processing:risk_profile:create',
      'category' : 'risk_profile',
      'alias_name' : 'CREATE RISK PROFILE'
    }, {
      'name' : 'processing:processor:create',
      'category' : 'application_processor',
      'alias_name' : 'CREATE PROCESSOR'
    }, {
      'name' : 'processing:merchant:update',
      'category' : 'merchant',
      'alias_name' : 'UPDATE MERCHANT'
    }, {
      'name' : 'processing:transaction:read',
      'category' : 'transaction',
      'alias_name' : 'READ TRANSACTION'
    }, {
      'name' : 'processing:platform_fee_profile:update',
      'category' : 'platform_fee_profile',
      'alias_name' : 'UPDATE PLATFORM FEE PROFILE'
    }, {
      'name' : 'processing:fee:read',
      'category' : 'fee',
      'alias_name' : 'READ FEE'
    }, {
      'name' : 'processing:transfer:fee_return',
      'category' : 'transfer',
      'alias_name' : 'RETURN TRANSFER FEE'
    }, {
      'name' : 'processing:fee:create',
      'category' : 'fee',
      'alias_name' : 'CREATE FEE'
    }, {
      'name' : 'processing:merchant:read',
      'category' : 'merchant',
      'alias_name' : 'READ MERCHANT'
    }, {
      'name' : 'processing:review_queue:list',
      'category' : 'review_queue',
      'alias_name' : 'LIST REVIEW QUEUE'
    }, {
      'name' : 'processing:transfer:list',
      'category' : 'transfer',
      'alias_name' : 'LIST TRANSFER'
    }, {
      'name' : 'processing:dispute:list',
      'category' : 'dispute',
      'alias_name' : 'LIST DISPUTE'
    }, {
      'name' : 'processing:identity:create',
      'category' : 'identity',
      'alias_name' : 'CREATE IDENTITY'
    }, {
      'name' : 'processing:application:processor_enable',
      'category' : 'application',
      'alias_name' : 'APPLICATION ENABLE PROCESSOR'
    }, {
      'name' : 'processing:transfer:accounting_create',
      'category' : 'transfer',
      'alias_name' : 'CREATE TRANSFER ACCOUNTING'
    }, {
      'name' : 'settlement:settlement_group_settings:delete',
      'category' : 'settlement',
      'alias_name' : 'DELETE SETTLEMENT SETTING GROUP'
    }, {
      'name' : 'processing:webhook:list',
      'category' : 'webhook',
      'alias_name' : 'LIST WEBHOOK'
    }, {
      'name' : 'processing:transaction:create',
      'category' : 'transaction',
      'alias_name' : 'CREATE TRANSACTION'
    }, {
      'name' : 'reporting:report:list',
      'category' : 'report',
      'alias_name' : 'LIST REPORT'
    }, {
      'name' : 'settlement:payout_plans:read',
      'category' : 'payout_plans',
      'alias_name' : 'READ PAYOUT PLAN'
    }, {
      'name' : 'processing:platform:read',
      'category' : 'platform',
      'alias_name' : 'READ PLATFORM'
    }, {
      'name' : 'subscription:subscription_amount:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION AMOUNT'
    }, {
      'name' : 'processing:transfer:funds_release',
      'category' : 'transfer',
      'alias_name' : 'RELEASE TRANSFER FUNDS'
    }, {
      'name' : 'settlement:funding_instruction_preview:list',
      'category' : 'funding_instruction_preview',
      'alias_name' : 'LIST FUNDING INSTRUCTION PREVIEW'
    }, {
      'name' : 'processing:application:read',
      'category' : 'application',
      'alias_name' : 'READ APPLICATION'
    }, {
      'name' : 'authentication:user:create',
      'category' : 'user',
      'alias_name' : 'CREATE USER'
    }, {
      'name' : 'processing:settlement:create',
      'category' : 'settlement',
      'alias_name' : 'CREATE SETTLEMENT'
    }, {
      'name' : 'processing:review_queue:read',
      'category' : 'review_queue',
      'alias_name' : 'READ REVIEW QUEUE'
    }, {
      'name' : 'processing:application:create',
      'category' : 'application',
      'alias_name' : 'CREATE APPLICATION'
    }, {
      'name' : 'processing:application:users_list',
      'category' : 'application',
      'alias_name' : 'LIST APPLICATION USERS'
    }, {
      'name' : 'processing:view:entity_system',
      'category' : 'view',
      'alias_name' : 'VIEW SYSTEM ENTITIES PERMISSION'
    }, {
      'name' : 'reporting:report:read',
      'category' : 'report',
      'alias_name' : 'READ REPORT'
    }, {
      'name' : 'authentication:user:partner_create',
      'category' : 'user',
      'alias_name' : 'CREATE PARTNER USER'
    }, {
      'name' : 'processing:dispute:create',
      'category' : 'dispute',
      'alias_name' : 'CREATE DISPUTE'
    }, {
      'name' : 'processing:dispute:simulate_state',
      'category' : 'dispute',
      'alias_name' : 'SIMULATE DISPUTE STATE CHANGE'
    }, {
      'name' : 'subscription:subscription_enrollment:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION ENROLLMENT'
    }, {
      'name' : 'subscription:subscription_enrollment:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION ENROLLMENT'
    }, {
      'name' : 'risk:rule_instance:list',
      'category' : 'rule_instance',
      'alias_name' : 'LIST RISK RULE'
    }, {
      'name' : 'processing:instrument_update:read',
      'category' : 'instrument_update',
      'alias_name' : 'READ INSTRUMENT UPDATE'
    }, {
      'name' : 'processing:review_queue:update',
      'category' : 'review_queue',
      'alias_name' : 'UPDATE REVIEW QUEUE'
    }, {
      'name' : 'settlement:settlement_group:read',
      'category' : 'settlement',
      'alias_name' : 'READ SETTLEMENT GROUP'
    }, {
      'name' : 'risk:rule_instance:create',
      'category' : 'rule_instance',
      'alias_name' : 'CREATE RISK RULE'
    },
    {
      'name' : 'subscription:subscription_item:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION ITEM'
    }, {
      'name' : 'processing:webhook:read',
      'category' : 'webhook',
      'alias_name' : 'READ WEBHOOK'
    }, {
      'name' : 'subscription:subscription_group:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION GROUP'
    }, {
      'name' : 'processing:risk_profile_rule:update',
      'category' : 'risk_profile_rule',
      'alias_name' : 'UPDATE RISK PROFILE RULE'
    }, {
      'name' : 'settlement:settlement_group_settings:create',
      'category' : 'settlement',
      'alias_name' : 'CREATE SETTLEMENT SETTING GROUP'
    }, {
      'name' : 'processing:authorization:read',
      'category' : 'authorization',
      'alias_name' : 'READ AUTHORIZATION'
    }, {
      'name' : 'subscription:subscription_plan:create',
      'category' : 'subscription',
      'alias_name' : 'CREATE SUBSCRIPTION PLAN'
    }, {
      'name' : 'authentication:user:permission_list',
      'category' : 'user',
      'alias_name' : 'LIST USER PERMISSION'
    }, {
      'name' : 'processing:transfer:payout',
      'category' : 'transfer',
      'alias_name' : 'PAYOUT TRANSFER'
    }, {
      'name' : 'processing:transfer:create',
      'category' : 'transfer',
      'alias_name' : 'CREATE TRANSFER'
    }, {
      'name' : 'processing:platform:update',
      'category' : 'platform',
      'alias_name' : 'UPDATE PLATFORM'
    }, {
      'name' : 'subscription:subscription_plan:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION PLAN'
    }, {
      'name' : 'processing:application_profile:create',
      'category' : 'application_profile',
      'alias_name' : 'CREATE APPLICATION PROFILE'
    }, {
      'name' : 'authentication:user:merchant_create',
      'category' : 'user',
      'alias_name' : 'CREATE MERCHANT USER'
    }, {
      'name' : 'processing:black_listed_instrument:delete',
      'category' : 'black_listed_instrument',
      'alias_name' : 'DELETE LIST BLACKED INSTRUMENT'
    }, {
      'name' : 'processing:transfer:adjustment_create',
      'category' : 'transfer',
      'alias_name' : 'CREATE TRANSFER ADJUSTMENT PERMISSION'
    }, {
      'name' : 'processing:merchant_profile:list',
      'category' : 'merchant_profile',
      'alias_name' : 'LIST MERCHANT PROFILE'
    }, {
      'name' : 'processing:fee:update',
      'category' : 'fee',
      'alias_name' : 'UPDATE FEE'
    }, {
      'name' : 'processing:fee_profile:read',
      'category' : 'fee_profile',
      'alias_name' : 'READ FEE PROFILE'
    }, {
      'name' : 'processing:risk_profile_rule:create',
      'category' : 'risk_profile_rule',
      'alias_name' : 'CREATE RISK PROFILE RULE'
    }, {
      'name' : 'subscription:subscription_schedule:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION SCHEDULE'
    }, {
      'name' : 'authentication:user:read',
      'category' : 'user',
      'alias_name' : 'READ USER'
    }, {
      'name' : 'authentication:user:list',
      'category' : 'user',
      'alias_name' : 'LIST USER'
    }, {
      'name' : 'processing:transfer:refunds_list',
      'category' : 'transfer',
      'alias_name' : 'LIST TRANSFER REFUNDS'
    }, {
      'name' : 'processing:application_profile:update',
      'category' : 'application_profile',
      'alias_name' : 'UPDATE APPLICATION PROFILE'
    }, {
      'name' : 'processing:risk_profile_rule:list',
      'category' : 'risk_profile_rule',
      'alias_name' : 'LIST RISK PROFILE RULE'
    }, {
      'name' : 'processing:settlement:list',
      'category' : 'settlement',
      'alias_name' : 'LIST SETTLEMENT'
    }, {
      'name' : 'processing:risk_profile:read',
      'category' : 'risk_profile',
      'alias_name' : 'READ RISK PROFILE'
    }, {
      'name' : 'processing:instrument_update:update',
      'category' : 'instrument_update',
      'alias_name' : 'UPDATE INSTRUMENT UPDATE'
    }, {
      'name' : 'processing:application:list',
      'category' : 'application',
      'alias_name' : 'LIST APPLICATION'
    }, {
      'name' : 'authentication:user:permission_update',
      'category' : 'user',
      'alias_name' : 'UPDATE USER PERMISSION'
    }, {
      'name' : 'processing:instrument:read',
      'category' : 'instrument',
      'alias_name' : 'READ INSTRUMENT'
    }, {
      'name' : 'processing:merchant_profile:update',
      'category' : 'merchant_profile',
      'alias_name' : 'UPDATE MERCHANT PROFILE'
    }, {
      'name' : 'processing:transfer:platform_fee_return',
      'category' : 'transfer',
      'alias_name' : 'RETURN TRANSFER PLATFORM FEE'
    }, {
      'name' : 'processing:merchant:create',
      'category' : 'merchant',
      'alias_name' : 'CREATE MERCHANT'
    }, {
      'name' : 'processing:risk_profile:update',
      'category' : 'risk_profile',
      'alias_name' : 'UPDATE RISK PROFILE'
    }, {
      'name' : 'processing:identity:list',
      'category' : 'identity',
      'alias_name' : 'LIST IDENTITY'
    }, {
      'name' : 'processing:fee_profile:list',
      'category' : 'fee_profile',
      'alias_name' : 'LIST FEE PROFILE'
    }, {
      'name' : 'subscription:subscription_enrollment:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION ENROLLMENT'
    }, {
      'name' : 'processing:instrument_update:create',
      'category' : 'instrument_update',
      'alias_name' : 'CREATE INSTRUMENT UPDATE'
    }, {
      'name' : 'processing:transfer:fees_list',
      'category' : 'transfer',
      'alias_name' : 'LIST TRANSFER FEES'
    }, {
      'name' : 'processing:view:settlement_system',
      'category' : 'view',
      'alias_name' : 'VIEW SYSTEM SETTLEMENTS PERMISSION'
    }, {
      'name' : 'subscription:subscription_plan:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION PLAN'
    }, {
      'name' : 'processing:fee:list',
      'category' : 'fee',
      'alias_name' : 'LIST FEE'
    }, {
      'name' : 'subscription:subscription_group:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION GROUP'
    }, {
      'name' : 'processing:processor:list',
      'category' : 'application_processor',
      'alias_name' : 'LIST PROCESSOR'
    }, {
      'name' : 'processing:identity:read',
      'category' : 'identity',
      'alias_name' : 'READ IDENTITY'
    }, {
      'name' : 'processing:application_profile:read',
      'category' : 'application_profile',
      'alias_name' : 'READ APPLICATION PROFILE'
    }, {
      'name' : 'processing:application:update',
      'category' : 'application',
      'alias_name' : 'UPDATE APPLICATION'
    }, {
      'name' : 'processing:review_queue:create',
      'category' : 'review_queue',
      'alias_name' : 'CREATE REVIEW QUEUE'
    }, {
      'name' : 'processing:risk_profile_rule:read',
      'category' : 'risk_profile_rule',
      'alias_name' : 'READ RISK PROFILE RULE'
    }, {
      'name' : 'subscription:subscription_item:update',
      'category' : 'subscription',
      'alias_name' : 'UPDATE SUBSCRIPTION ITEM'
    }, {
      'name' : 'processing:settlement:settling_transfer_create',
      'category' : 'settlement',
      'alias_name' : 'CREATE SETTLEMENT SETTLING TRANSFER'
    }, {
      'name' : 'processing:fee_strategy:read',
      'category' : 'fee_strategy',
      'alias_name' : 'READ FEE STRATEGY'
    }, {
      'name' : 'processing:webhook:update',
      'category' : 'webhook',
      'alias_name' : 'UPDATE WEBHOOK'
    }, {
      'name' : 'settlement:funding_instruction:resubmit',
      'category' : 'funding_instruction',
      'alias_name' : 'RESUBMIT FUNDING INSTRUCTION'
    }, {
      'name' : 'processing:platform:list',
      'category' : 'platform',
      'alias_name' : 'LIST PLATFORM'
    }, {
      'name' : 'subscription:subscription_plan:list',
      'category' : 'subscription',
      'alias_name' : 'LIST SUBSCRIPTION PLAN'
    }, {
      'name' : 'processing:black_listed_instrument:create',
      'category' : 'black_listed_instrument',
      'alias_name' : 'LIST CREATE BLACKED INSTRUMENT'
    }, {
      'name' : 'processing:merchant_profile:read',
      'category' : 'merchant_profile',
      'alias_name' : 'READ MERCHANT PROFILE'
    }, {
      'name' : 'processing:transaction:update',
      'category' : 'transaction',
      'alias_name' : 'UPDATE TRANSACTION'
    }, {
      'name' : 'subscription:subscription_group:read',
      'category' : 'subscription',
      'alias_name' : 'READ SUBSCRIPTION GROUP'
    }, {
      'name' : 'processing:instrument:create',
      'category' : 'instrument',
      'alias_name' : 'CREATE INSTRUMENT'
    }, {
      'name' : 'processing:fee_profile:update',
      'category' : 'fee_profile',
      'alias_name' : 'UPDATE FEE PROFILE'
    }, {
      'name' : 'processing:verification:create',
      'category' : 'verification',
      'alias_name' : 'CREATE VERIFICATION'
    }, {
      'name' : 'processing:reserve_profile:update',
      'category' : 'reserve_profile',
      'alias_name' : 'UPDATE RESERVE PROFILE'
    }, {
      'name' : 'settlement:settlement_group_settings:list',
      'category' : 'settlement',
      'alias_name' : 'LIST SETTLEMENT SETTING GROUP'
    }, {
      'name' : 'settlement:payout_plans:list',
      'category' : 'payout_plans',
      'alias_name' : 'LIST PAYOUT PLAN'
    }, {
      'name' : 'settlement:funding_instruction:list',
      'category' : 'funding_instruction',
      'alias_name' : 'LIST FUNDING INSTRUCTIONS'
    }, {
      'name' : 'processing:balance_transfer:list',
      'category' : 'balance_transfer',
      'alias_name' : 'LIST FUNDING INSTRUCTIONS'
    }, {
      'name' : 'processing:balance_transfer:read',
      'category' : 'balance_transfer',
      'alias_name' : 'LIST FUNDING INSTRUCTIONS'
    }, {
      'name' : 'processing:balance_transfer:create',
      'category' : 'balance_transfer',
      'alias_name' : 'LIST FUNDING INSTRUCTIONS'
    } ]
  },
  'page' : {
    'offset' : 100,
    'limit' : 100,
    'count' : 169
  }
}

const permissions = get(payload, '_embedded.permissions')
const rolePlatformPermissionNames = map(permissions, ({ name }) => name )

export default rolePlatformPermissionNames
