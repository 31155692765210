import './UpdateAllPermissionsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import { UPDATE_ALL_PERMISSIONS_FORM } from 'constants/formConstants'
import submitUpdateAllPermissionsForm from 'utilities/submit/submitUpdateAllPermissionsForm'
import validateUpdateAllPermissionsForm from 'utilities/validate/validateUpdateAllPermissionsForm'

import {
  SAVE,
  CANCEL,
} from 'constants/language/languageConstants'

const UpdateAllPermissionsForm = ({
  closeModal = () => {},
  handleSubmit = () => {},
  submitting = false,
  updateOptions = [],
}) => {
  return (
    <form className='UpdateAllPermissionsForm' onSubmit={handleSubmit(submitUpdateAllPermissionsForm)}>
      <div className='section'>
        <div>Select and update permissions</div>

        <Field
          name='role'
          label='Role'
          component={InputField}
          disabled
        />

        <Field
          name='access'
          label='Permissions'
          component={SelectField}
          options={updateOptions}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' submitting={submitting} label={SAVE} />
      </div>
    </form>
  )
}

UpdateAllPermissionsForm.propTypes = {
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  updateOptions: PropTypes.array,
}

export default reduxForm({
  form: UPDATE_ALL_PERMISSIONS_FORM,
  validate: validateUpdateAllPermissionsForm,
})(UpdateAllPermissionsForm)
