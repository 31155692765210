import './CreateSubscriptionModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import CreateSubscriptionScheduleFormC from 'components/Customer/Forms/CreateSubscriptionScheduleForm/CreateSubscriptionScheduleFormC'
import CreateSubscriptionAmountForm from 'components/Customer/Forms/CreateSubscriptionAmountForm/CreateSubscriptionAmountForm'
import CreateSubscriptionEnrollmentFormC from 'components/Customer/Forms/CreateSubscriptionEnrollmentForm/CreateSubscriptionEnrollmentFormC'
import GenericModalC from 'components/Customer/Modals/GenericModal/GenericModalC'
import CreateSubscriptionConfirmation from 'components/Customer/Pages/CreateSubscriptionConfirmation/CreateSubscriptionConfirmation'
import SubscriptionScheduleDisplayData from 'components/Customer/Forms/CreateSubscriptionScheduleForm/SubscriptionScheduleDisplayData'
import SubscriptionAmountDisplayData from 'components/Customer/Forms/CreateSubscriptionAmountForm/SubscriptionAmountDisplayData'
import SubscriptionEnrollmentDisplayData from 'components/Customer/Forms/CreateSubscriptionEnrollmentForm/SubscriptionEnrollmentDisplayData'

import {
  CREATE_SUBSCRIPTION_SCHEDULE_DATA_KEY,
  CREATE_SUBSCRIPTION_AMOUNT_DATA_KEY,
  CREATE_SUBSCRIPTION_ENROLLMENT_DATA_KEY,
} from 'constants/flowsConstants'

const CreateSubscriptionModal = ({
  merchantId,
  credentials,
}) => {
  const steps = [
    {
      stepTitle: 'Schedule',
      component: CreateSubscriptionScheduleFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: CREATE_SUBSCRIPTION_SCHEDULE_DATA_KEY,
      getDisplayData: SubscriptionScheduleDisplayData,
    },
    {
      stepTitle: 'Amount',
      component: CreateSubscriptionAmountForm,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: CREATE_SUBSCRIPTION_AMOUNT_DATA_KEY,
      getDisplayData: SubscriptionAmountDisplayData,
    },
    {
      stepTitle: 'Enrollment',
      component: CreateSubscriptionEnrollmentFormC,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: CREATE_SUBSCRIPTION_ENROLLMENT_DATA_KEY,
      getDisplayData: SubscriptionEnrollmentDisplayData,
    },
    {
      stepTitle: 'Confirmation of Subscription',
      component: CreateSubscriptionConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
        merchantId,
      },
    },
  ]

  return (
    <GenericModalC
      title='Create Subscription'
      className='CreateSubscriptionModal'
      steps={steps}
      credentials={credentials}
      multiSteps
    />
  )
}

CreateSubscriptionModal.propTypes = {
  merchantId: PropTypes.string,
  credentials: PropTypes.object,
}

export default CreateSubscriptionModal
