import React from 'react'
import PropTypes from 'prop-types'
import { getPaymentInstrumentsSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/PaymentInstruments/columnDescriptors'
import { PAYMENT_INSTRUMENTS } from 'constants/linkConstants'
import { GET_PAYMENT_INSTRUMENTS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  PAYMENT_INSTRUMENTS_RESOURCE_TITLE,
  PAYMENT_INSTRUMENTS_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const ApplicationPaymentInstruments = ({
  paymentInstrumentsPath = '',
  allowedFilters,
  initialQueries,
  isFetching = false,
  quickFilters,
}) => {
  return (
    <div className='ApplicationPaymentInstruments'>
      <TableC
        flow={GET_PAYMENT_INSTRUMENTS}
        columnDescriptors={columnDescriptors}
        selector={getPaymentInstrumentsSelector}
        initialQueries={initialQueries}
        linksKey={PAYMENT_INSTRUMENTS}
        allowedFilters={allowedFilters}
        path={paymentInstrumentsPath}
        isFetching={isFetching}
        emptyMessage={PAYMENT_INSTRUMENTS_EMPTY_TABLE_MESSAGE}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV='Payment_Instruments'
        fileResourceTitleCSV={PAYMENT_INSTRUMENTS_RESOURCE_TITLE}
        quickFilters={quickFilters}
      />
    </div>
  )
}

ApplicationPaymentInstruments.propTypes = {
  paymentInstrumentsPath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
}

export default ApplicationPaymentInstruments
