import './CheckboxInputS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import { LEARN_MORE_LABEL } from 'constants/language/languageConstants'
import { ALERT_ICON } from 'constants/iconConstants'

// CheckboxInput is used for Redux Form values
const CheckboxInput = ({
  input: {
    name = 'checkbox',
    value,
    ...input
  },
  meta: {
    touched,
    error,
  },
  label = '',
  helperText = '',
  helperTextLearnMoreLink = '',
  disabled = false,
  className = '',
  checkboxLabel,
  checkboxHelperText,
  tooltip = '',
  checkboxTooltip = '',
  checkboxTooltipPosition = 'right',
  disabledTooltip = '',
  disabledTooltipPosition = 'top-of-radio',
}) => {
  const classNames = classnames({
    CheckboxInput: true,
    [className]: true,
    disabled,
  })

  const learnMoreLinkDisplay = helperTextLearnMoreLink ? <a className='text-link' href={helperTextLearnMoreLink} target='blank'>&nbsp;{LEARN_MORE_LABEL}</a> : undefined
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{error}</span>
  const hasError = touched && errorJSX ? 'error' : ''

  const checkboxClassNames = classnames({
    checkbox: true,
    flex: true,
    'items-flex-start': true,
    'items-center': checkboxHelperText ? false : true,
    disabled,
    [hasError]: !!hasError,
  })

  return (
    <div className={classNames}>
      <div>
        {/* NOTE: we should not be using parent labels and sublabels for singular checkboxes */}
        {(label || tooltip || helperText) && (
        <div className='label-container'>
          <div className='flex'>
            { label && <label htmlFor={name} className='label label-2'>{label}</label> }
            { tooltip && <TooltipLabelC message={tooltip} /> }
          </div>
          { (helperText && learnMoreLinkDisplay) && <div className='helper-text label-2'>{helperText}{learnMoreLinkDisplay}</div> }
          { (helperText && !learnMoreLinkDisplay) && <div className='helper-text label-2'>{helperText}</div> }
        </div>
        )}

        <label className={checkboxClassNames}>
          {/* if input is disabled, wrap the input with a tooltip */}
          {(disabled && disabledTooltip) ? (
            <TooltipLabelC
              showIcon={false}
              message={disabledTooltip}
              position={disabledTooltipPosition}
            >
              <input
                {...input}
                type='checkbox'
                id={name}
                name={name}
                checked={!!value}
                disabled={disabled}
              />
            </TooltipLabelC>
          ) : (
            <input
              {...input}
              type='checkbox'
              id={name}
              name={name}
              checked={!!value}
              disabled={disabled}
            />
          )}

          <div className='checkbox-label-container'>
            <div className='flex'>
              { checkboxLabel && <span className='checkbox-label label-2' htmlFor={name}>{checkboxLabel}</span> }
              { checkboxTooltip && <TooltipLabelC message={checkboxTooltip} position={checkboxTooltipPosition} /> }
            </div>
            { checkboxHelperText && <div className='checkbox-helper-text label-2'>{checkboxHelperText}</div> }
          </div>
        </label>
      </div>
      { touched && errorJSX }
    </div>
  )
}

CheckboxInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  labelRight: PropTypes.bool,
  tooltip: PropTypes.string,
  checkboxTooltip: PropTypes.string,
  helperText: PropTypes.string,
  checkboxTooltipPosition: PropTypes.string,
  checkboxLabel: PropTypes.string,
  checkboxHelperText: PropTypes.string,
  helperTextLearnMoreLink: PropTypes.string,
  className: PropTypes.string,
  disabledTooltip: PropTypes.string,
  disabledTooltipPosition: PropTypes.string,
}

export default CheckboxInput
