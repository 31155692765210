import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'

const SanctionsScreeningReport = ({
  sanctionsData = [],
}) => {
  return (
    <div className='SanctionsScreeningReport'>
      { map(sanctionsData, (sanction, index) => {
        const [
          columns,
          title,
        ] = getMany(sanction, [
          'columns',
          'title',
        ])

        return (
          <PageSectionV2C
            title={title}
            columns={columns}
            className={`sanction-section-${index}`}
          />
        )
      })}
    </div>
  )
}

SanctionsScreeningReport.propTypes = {
  sanctionsData: PropTypes.array,
}

export default SanctionsScreeningReport
