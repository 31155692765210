import React, { Component } from 'react'
import ComplianceSelfAssessmentQuestionnaireAForm from './ComplianceSelfAssessmentQuestionnaireAForm'
import { connect } from 'react-redux'
import some from 'lodash/some'
import { getComplianceSelfAssessmentQuestionnaireASelector } from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const complianceSelfAssessmentQuestionnaireAValues = getComplianceSelfAssessmentQuestionnaireASelector(state)
  const saqAFormHasYesWithCCW = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'YES_WITH_CCW')
  const saqAFormHasNA = some(complianceSelfAssessmentQuestionnaireAValues, (value) => value === 'N/A')

  return {
    saqAFormHasNA,
    saqAFormHasYesWithCCW,
  }
}

class ComplianceSelfAssessmentQuestionnaireAFormC extends Component {
  render() {
    return (
      <ComplianceSelfAssessmentQuestionnaireAForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(ComplianceSelfAssessmentQuestionnaireAFormC)
