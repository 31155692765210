import './EmailAddressReportV2S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  DOMAIN_MATCH_CHECK,
  RISK_CHECKS,
} from 'constants/language/languageConstants'

const EmailAddressReportV2 = ({
  emailAddressDomainMatchCheckData = [],
  emailAddressRiskChecksData = [],
}) => {
  return (
    <div className='EmailAddressReportV2'>
      <PageSectionV2C className='email-address-data-section'>
        <h6>{DOMAIN_MATCH_CHECK}</h6>
        <PageSectionV2C className='domain-match-check-section' columns={emailAddressDomainMatchCheckData} />

        <h6>{RISK_CHECKS}</h6>
        <PageSectionV2C className='risk-checks-section' columns={emailAddressRiskChecksData} />
      </PageSectionV2C>
    </div>
  )
}

EmailAddressReportV2.propTypes = {
  emailAddressDomainMatchCheckData: PropTypes.array,
  emailAddressRiskChecksData: PropTypes.array,
}

export default EmailAddressReportV2
