import './BusinessIdentityVerificationReportV2S.scss'
import industryClassificationsColumnDescriptors from './industryClassificationsColumnDescriptors'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TableC from 'components/Shared/Table/TableC'
import { infoTableTheme } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  ADDRESS_RECORDS_EMPTY_MESSAGE,
  BUSINESS_INFORMATION,
  BUSINESS_REGISTRATIONS_EMPTY_MESSAGE,
  INDUSTRY_CLASSIFICATIONS,
  INDUSTRY_CLASSIFICATIONS_EMPTY_MESSAGE,
  DATABASE_CHECK_RESULT,
  ADDRESS_INFORMATION,
  ASSOCIATED_PERSONS_INFORMATION,
  ASSOCIATED_PERSONS_EMPTY_MESSAGE,
  REGISTRATIONS,
} from 'constants/language/languageConstants'

const BusinessIdentityVerificationReportV2 = ({
  verificationSummaryData = [],
  businessInformationData = [],
  addressRecordsData = [],
  associatedPersonsInformation = [],
  industriesData = [],
  businessRegistrationsData = [],
}) => {
  return (
    <div className='BusinessIdentityVerificationReportV2'>
      <h5 className='database-check-result-title'>{DATABASE_CHECK_RESULT}</h5>
      <PageSectionV2C
        columns={verificationSummaryData}
        className='verification-summary-section'
      />

      <h6 className='business-information-title'>{BUSINESS_INFORMATION}</h6>
      <PageSectionV2C
        columns={businessInformationData}
        className='business-information-section'
      />

      <h6 className='address-records-title'>{ADDRESS_INFORMATION}</h6>
      <PageSectionV2C
        columns={addressRecordsData}
        className='address-records-section'
        emptyMessage={ADDRESS_RECORDS_EMPTY_MESSAGE}
      />

      <h6 className='business-contacts-title'>{ASSOCIATED_PERSONS_INFORMATION}</h6>
      <PageSectionV2C
        columns={associatedPersonsInformation}
        className='business-contacts-section'
        emptyMessage={ASSOCIATED_PERSONS_EMPTY_MESSAGE}
      />

      <h5 className='business-registrations-title'>{REGISTRATIONS}</h5>
      {isEmpty(businessRegistrationsData) && <div className='business-registrations-empty-message'>{BUSINESS_REGISTRATIONS_EMPTY_MESSAGE}</div>}
      {!isEmpty(businessRegistrationsData) && map(businessRegistrationsData, ({ title, data }) => {
        return (
          <>
            <h6>{title}</h6>
            <PageSectionV2C
              columns={data}
              className='business-registrations-section'
            />
          </>
        )
      })}

      <h5 className='industry-classification-title'>{INDUSTRY_CLASSIFICATIONS}</h5>
      {isEmpty(industriesData) ? (
        <div className='industry-classification-empty-message'>{INDUSTRY_CLASSIFICATIONS_EMPTY_MESSAGE}</div>
      ) : (
        <TableC
          data={industriesData}
          theme={infoTableTheme}
          columnDescriptors={industryClassificationsColumnDescriptors}
          showTableFooter={false}
        />
      )}
    </div>
  )
}

BusinessIdentityVerificationReportV2.propTypes = {
  verificationSummaryData: PropTypes.array,
  businessInformationData: PropTypes.array,
  addressRecordsData: PropTypes.array,
  associatedPersonsInformation: PropTypes.array,
  industriesData: PropTypes.array,
  businessRegistrationsData: PropTypes.array,
}

export default BusinessIdentityVerificationReportV2
