import React from 'react'
import PropTypes from 'prop-types'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import MonitoringAlertRelatedAlertsPreviewPanelC from './MonitoringAlertRelatedAlertsPreviewPanel/MonitoringAlertRelatedAlertsPreviewPanelC'
import { GET_MONITORING_ALERTS } from 'constants/flowConstants'

import {
  TRANSACTION_MONITORING_RESOURCE_TITLE,
  TRANSACTION_MONITORING_TABLE_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'


const MonitoringAlertRelatedAlerts = ({
  allowedFilters = [],
  initialQueries = {},
  selector = () => {},
  quickFilters,
  initialFilters,
  monitoringAlertPath = '',
  linksKey = '',
}) => {
  return (
    <div className='MonitoringAlertRelatedAlerts'>
      <TableC
        flow={GET_MONITORING_ALERTS}
        columnDescriptors={columnDescriptors}
        selector={selector}
        initialQueries={initialQueries}
        allowedFilters={allowedFilters}
        fileNameCSV='Monitoring_Alerts'
        fileResourceTitleCSV={TRANSACTION_MONITORING_RESOURCE_TITLE}
        emptyMessage={TRANSACTION_MONITORING_TABLE_EMPTY_MESSAGE}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        linksKey={linksKey}
        path={monitoringAlertPath}
        previewPanel={MonitoringAlertRelatedAlertsPreviewPanelC}
        shouldUseV2DataFlow
      />
    </div>
  )
}

MonitoringAlertRelatedAlerts.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  selector: PropTypes.func,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
  monitoringAlertPath: PropTypes.string,
  linksKey: PropTypes.string,
}

export default MonitoringAlertRelatedAlerts
