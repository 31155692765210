import get from 'lodash/get'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import { FETCHING } from 'constants/reducerConstants'

const removeItems = (state, ids) => {
  const items = get(state, 'items')

  if (isEmpty(items) || isEmpty(ids)) {
    return state
  }

  return merge({}, omit(state, 'items'), { items: omit(items, ids), [FETCHING]: false })
}

export default removeItems
