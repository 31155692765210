import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  PAYMENT_INSTRUMENTS,
  PAYMENT_INSTRUMENT_VERIFICATIONS,
} from 'constants/apiConstants'

const getPaymentInstrumentVerificationsAPI = ({ queries, values, credentials, meta }) => {
  const {
    paymentInstrumentId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: PAYMENT_INSTRUMENT_VERIFICATIONS({ paymentInstrumentId }),
    queries,
    service: PAYMENT_INSTRUMENTS,
  })
}

export default getPaymentInstrumentVerificationsAPI
