import React, { Component } from 'react'
import { connect } from 'react-redux'
import ACHReturnsInsights from './ACHReturnsInsights'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getExceptionChartsRequest from 'utilities/actions/get/getExceptionChartsRequest'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { FETCHING } from 'constants/reducerConstants'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const isFetching = get(state, `chartsR.${FETCHING}`, true)
  const tableFilters = get(state, 'tableFiltersR')
  const createdAtFilter = pick(tableFilters, 'created_at')
  const normalizedCreatedAtFilter = isEmpty(createdAtFilter) ? createdLast3MonthQueries() : createdAtFilter
  const timeRange = get(normalizedCreatedAtFilter, 'created_at', {})
  const credentials = getCurrentCredentials(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)

  return {
    isFetching,
    createdAtFilters: normalizedCreatedAtFilter,
    timeRange,
    isRolePlatformCredential,
    isStandaloneMerchant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getACHReturnsCharts: ({ queries }) => dispatch(getExceptionChartsRequest({ queries })),
  }
}

class ACHReturnsInsightsC extends Component {
  componentDidMount() {
    const { getACHReturnsCharts } = this.props

    getACHReturnsCharts({
      queries: {
        tab: 'ach-returns',
      },
    })
  }

  render() {
    return (
      <ACHReturnsInsights {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ACHReturnsInsightsC)
