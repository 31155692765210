import numeral from 'numeral'
import getMany from 'utilities/get/getMany'
import formatMoney from 'utilities/formatters/formatMoney'
import formatDate from 'utilities/formatters/formatDate'
import formatNumber from 'utilities/formatters/formatNumber'
import removeUndefined from 'utilities/remove/removeUndefined'
import sum from 'lodash/sum'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import capitalize from 'lodash/capitalize'

import {
  PENDING,
  ACCRUING,
} from 'constants/settlementConstants'

const frontendSettlementModel = ({ data: settlement }) => {
  const [
    id,
    status,
    createdAt,
    processor,
    totalFees,
    totalAmount,
    currency,
    tags,
    applicationId,
    merchantIdentityId,
    paymentInstrument,
    destination,
    transferDebitAmount,
    transferCreditAmount,
    reverseAmount,
    disputeDebitAmount,
    disputeCreditAmount,
    adjustmentDebitAmount,
    adjustmentCreditAmount,
    transferDebitCount,
    transferCreditCount,
    feeCount,
    reverseCount,
    disputeDebitCount,
    disputeCreditCount,
    adjustmentDebitCount,
    adjustmentCreditCount,
    merchant,
    paymentInstruments,
    reviewQueueItem,
    errors,
    scheduleType,
    windowStart,
    windowEnd,
    autoCloseTime,
    exception,
    settlementGroupId,
    calculatedFundingDetails,
    externallyFundedCount,
    externallyFundedAmount,
    applicationModel,
    merchantIdentity,
    payoutProfile,
    splitTransferDebitCount,
    splitTransferDebitAmount,
    splitTransferCreditCount,
    splitTransferCreditAmount,
  ] = getMany(settlement, [
    'id',
    'status',
    'created_at',
    'processor_type',
    'total_fee_amount',
    'total_amount',
    'currency',
    'tags',
    'application',
    'identity',
    'paymentInstrument',
    'destination',
    'transfer_debit_amount',
    'transfer_credit_amount',
    'reverse_amount',
    'dispute_debit_amount',
    'dispute_credit_amount',
    'adjustment_debit_amount',
    'adjustment_credit_amount',
    'transfer_debit_count',
    'transfer_credit_count',
    'fee_count',
    'reverse_count',
    'dispute_debit_count',
    'dispute_credit_count',
    'adjustment_debit_count',
    'adjustment_credit_count',
    'merchant',
    'paymentInstruments',
    'reviewQueueItem',
    'errors',
    'schedule_type',
    'window_start',
    'window_end',
    'auto_close_time',
    'exception',
    'settlement_group',
    'calculatedFundingDetails',
    'externally_funded_count',
    'externally_funded_amount',
    'applicationModel',
    'merchantIdentity',
    'payoutProfile',
    'split_transfer_debit_count',
    'split_transfer_debit_amount',
    'split_transfer_credit_count',
    'split_transfer_credit_amount',
  ])

  const merchantId = get(settlement, !isObject(merchant) ? 'merchant' : 'merchant_id')

  const normalizedTransferCount = sum([
    adjustmentCreditCount,
    adjustmentDebitCount,
    disputeCreditCount,
    disputeDebitCount,
    transferCreditCount,
    transferDebitCount,
    reverseCount,
    splitTransferDebitCount,
    splitTransferCreditCount,
  ])

  const totalAmountValue = numeral(totalAmount).value()
  const totalFeesValue = numeral(totalFees).value()
  const calculatedNetAmount = totalAmount ? numeral((totalAmountValue - totalFeesValue).toFixed(2)).value() : undefined

  // add split transfer amounts to transfer amounts and counts
  const transferDebitAmountValue = sum([numeral(transferDebitAmount).value(), numeral(splitTransferDebitAmount).value()])
  const transferCreditAmountValue = sum([numeral(transferCreditAmount).value(), numeral(splitTransferCreditAmount).value()])
  const transferDebitCountValue = sum([transferDebitCount, splitTransferDebitCount])
  const transferCreditCountValue = sum([transferCreditCount, splitTransferCreditCount])

  return removeUndefined({
    id,
    merchantId,
    merchant: isObject(merchant) ? merchant : undefined,
    transferCount: normalizedTransferCount,
    status: status === PENDING ? ACCRUING : status,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    processor,
    totalFees,
    totalAmount,
    netAmount: calculatedNetAmount,
    currency,
    tags,
    applicationId,
    merchantIdentityId,
    displayTotalAmount: formatMoney({ amount: totalAmount, currency }),
    paymentInstrument,
    adjustmentCreditCount,
    adjustmentDebitCount,
    disputeCreditCount,
    disputeDebitCount,
    transferCreditCount: transferCreditCountValue,
    transferDebitCount: transferDebitCountValue,
    feeCount,
    reverseCount,
    adjustmentCreditAmount,
    adjustmentDebitAmount,
    disputeCreditAmount,
    disputeDebitAmount,
    transferCreditAmount: transferCreditAmountValue,
    transferDebitAmount: transferDebitAmountValue,
    reverseAmount,
    paymentInstruments,
    reviewQueueItem,
    errors,
    scheduleType,
    displayScheduleType: capitalize(scheduleType),
    windowStart,
    displayWindowStart: formatDate({ date: windowStart }),
    windowEnd,
    displayWindowEnd: formatDate({ date: windowEnd }),
    exception,
    autoCloseTime,
    displayAutoCloseTime: formatDate({ date: autoCloseTime }),
    settlementGroupId,
    calculatedFundingDetails,
    externallyFundedCount,
    externallyFundedAmount,
    applicationModel,
    merchantIdentity,
    payoutProfile,
    displayTransferDebitCount: formatNumber(transferDebitCountValue),
    displayTransferDebitAmount: formatMoney({ amount: transferDebitAmountValue, currency }),
    displayTransferCreditCount: formatNumber(transferCreditCountValue),
    displayTransferCreditAmount: formatMoney({ amount: transferCreditAmountValue, currency, displayNegativeAmount: true }),
    displayTransferFeesCount: formatNumber(feeCount),
    displayTransferFeesAmount: formatMoney({ amount: totalFees, currency, displayNegativeAmount: true }),
    displayTransferSettledExternallyCount: formatNumber(externallyFundedCount),
    displayTransferSettledExternallyAmount: formatMoney({ amount: externallyFundedAmount, currency }),
    displayTransferRefundsCount: formatNumber(reverseCount),
    displayTransferRefundsAmount: formatMoney({ amount: reverseAmount, currency, displayNegativeAmount: true }),
    displayDisputeDebitCount: formatNumber(disputeDebitCount),
    displayDisputeDebitAmount: formatMoney({ amount: disputeDebitAmount, currency, displayNegativeAmount: true }),
    displayDisputeCreditCount: formatNumber(disputeCreditCount),
    displayDisputeCreditAmount: formatMoney({ amount: disputeCreditAmount, currency }),
    displayAdjustmentDebitCount: formatNumber(adjustmentDebitCount),
    displayAdjustmentDebitAmount: formatMoney({ amount: adjustmentDebitAmount, currency }),
    displayAdjustmentCreditCount: formatNumber(adjustmentCreditCount),
    displayAdjustmentCreditAmount: formatMoney({ amount: adjustmentCreditAmount, currency, displayNegativeAmount: true }),
    displayNetAmount: formatMoney({ amount: calculatedNetAmount, currency }),
    displayNetAmountWithCurrencyCode: formatMoney({ amount: calculatedNetAmount, currency, showCurrencyCode: true }),
  })
}

export default frontendSettlementModel
