import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import size from 'lodash/size'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'

import {
  ID,
  CREATED_ON,
  LAST_RUN_DATE,
  UPDATED_ON,
  TOTAL_RUN_COUNT,
  ASSIGNEE,
  UNDERWRITING_STATUS,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdOn }) => <DateTimestampColumn timestamp={createdOn} />,
  }),
  column(LAST_RUN_DATE, 'lastRun', {
    className: 'date',
    formatter: ({ runs }) => {
      const orderedRuns = orderBy(runs, ['created_at'], ['desc'])

      const date = get(orderedRuns, '[0].createdAt')
      return <DateTimestampColumn timestamp={date} />
    },
    headerFormatter: () => <DateTimestampHeader title={LAST_RUN_DATE} />,
  }),
  column(UPDATED_ON, 'displayUpdatedOn', {
    className: 'date',
    headerFormatter: () => <DateTimestampHeader title={UPDATED_ON} />,
    formatter: ({ updatedOn }) => <DateTimestampColumn timestamp={updatedOn} />,
  }),
  column(TOTAL_RUN_COUNT, 'runs', {
    className: 'small',
    formatter: ({ runs }) => size(runs),
  }),
  column(ASSIGNEE, 'assignee', { className: 'medium' }),
  column(UNDERWRITING_STATUS, 'state', {
    formatter: ({ state }) => <UnderwritingReviewStatus value={state} />,
  }),
]

export default columnDescriptors
