import paymentsAPI from 'utilities/api/paymentsAPI'
import { FEES } from 'constants/apiConstants'

const postFeeAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: FEES,
    service: FEES,
  })
}

export default postFeeAPI
