import React from 'react'
import PropTypes from 'prop-types'
import PanelContextBar from 'components/Shared/PanelContextBar/PanelContextBar'

const PaymentPanel = ({
  panelContextBarData = [],
  isFetching = false,
  goToPath = () => {},
}) => {
  return (
    <div className='TransactionPanel'>
      <PanelContextBar
        isFetching={isFetching}
        data={panelContextBarData}
        goToPath={goToPath}
      />
    </div>
  )
}

PaymentPanel.propTypes = {
  panelContextBarData: PropTypes.array,
  isFetching: PropTypes.bool,
  goToPath: PropTypes.func,
}

export default PaymentPanel
