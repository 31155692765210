import './ReviewQueueItemS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import BreadcrumbsC from 'components/Customer/Shared/Page/Breadcrumbs/BreadcrumbsC'
import Header from 'components/Customer/Shared/Page/Header/Header'
import PageSection from 'components/Customer/Shared/Page/PageSection/PageSection'

const ReviewQueueItem = ({
  detailsSectionData = [],
  entitySectionData = [],
  entitySectionTitle = 'Entity',
  headerTitle = '',
  headerSubTitle = '',
  isFetching = false,
}) => {
  return (
    <div className='ReviewQueueItem'>
      <BreadcrumbsC />

      <Header
        title={headerTitle}
        subTitle={headerSubTitle}
      />

      <PageSection
        title='Details'
        data={detailsSectionData}
        isFetching={isFetching}
      />

      <PageSection
        title={entitySectionTitle}
        data={entitySectionData}
        isFetching={isFetching}
      />
    </div>
  )
}

ReviewQueueItem.propTypes = {
  detailsSectionData: PropTypes.array,
  entitySectionData: PropTypes.array,
  entitySectionTitle: PropTypes.string,
  headerTitle: PropTypes.string,
  headerSubTitle: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default ReviewQueueItem
