import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateTransaction from './CreateTransaction'
import CreateTransactionFormC from 'components/Customer/Forms/CreateTransactionForm/CreateTransactionFormC'
import CreateQuickTransactionFormC from 'components/Customer/Forms/CreateQuickTransactionForm/CreateQuickTransactionFormC'
import CreateTransactionReviewFormC from 'components/Customer/Forms/CreateTransactionReviewForm/CreateTransactionReviewFormC'
import Loader from 'components/Shared/Loader/Loader'
import getApplicationMerchantsRequest from 'utilities/actions/get/getApplicationMerchantsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import updateUrlQueries from 'utilities/updateUrlQueries'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentUser from 'utilities/get/getCurrentUser'
import prevFlowStep from 'utilities/prevFlowStep'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getMany from 'utilities/get/getMany'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import head from 'lodash/head'
import size from 'lodash/size'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'

import {
  getMerchantSelector,
  getMerchantsByApplicationIdSelector,
} from 'state-layer/selectors'

import {
  SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
  FEATURE_NOT_AVAILABLE_MODAL,
} from 'constants/modalConstants'

import {
  LITLE_V1,
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  CREATE_TRANSACTION_FORM,
  CREATE_TRANSACTION_REVIEW_FORM,
} from 'constants/formConstants'

import {
  REVIEW_PAYMENT,
  CREATE_A_PAYMENT,
  VIRTUAL_TERMINAL_IS_NOT_AVAILABLE_AT_THIS_TIME,
  MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_THE_VIRTUAL_TERMINAL,
} from 'constants/language/languageConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (props) => dispatch(showModalAction(props)),
    getApplicationMerchants: ({ applicationId, credentials }) => dispatch(getApplicationMerchantsRequest({ applicationId, credentials })),
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
    showFeatureNotAvailableModal: () => dispatch(showModalAction({
      modalType: FEATURE_NOT_AVAILABLE_MODAL,
      modalProps: {
        canCloseModal: false,
        title: VIRTUAL_TERMINAL_IS_NOT_AVAILABLE_AT_THIS_TIME,
        className: 'VirtualTerminalNotAvailableModal',
        description: MERCHANT_ACCOUNT_IS_REQUIRED_TO_ACCESS_THE_VIRTUAL_TERMINAL,
      },
      className: 'modal-md no-pad',
    })),
  }
}

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const senderMerchantId = getUrlQuery('senderMerchantId')
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)
  const isFetchingMerchants = get(state, `merchantsR.${FETCHING}`, true)
  const isFetching = isFetchingCurrentUser || isFetchingMerchants
  const environment = get(credentials, 'environment')
  const allowedProcessors = isEqual(environment, LIVE_ENVIRONMENT) ? [LITLE_V1, FINIX_V1] : [DUMMY_V1]
  const merchantIdQuery = getUrlQuery('merchantId')
  const type = getUrlQuery('type') || 'sale'
  const isSale = type === 'sale'
  const merchant = getMerchantSelector(state, merchantIdQuery)
  const merchantLevelTwoLevelThreeDataEnabled = get(merchant, 'levelTwoLevelThreeDataEnabled')
  const merchantIdentity = get(merchant, 'merchantIdentity')
  const merchantIdentityMaxTransactionAmount = get(merchantIdentity, 'entity.max_transaction_amount')

  const [
    platformId,
    applicationId,
  ] = getMany(currentUser, [
    'platformId',
    'applicationId',
  ])

  const applicationMerchants = getMerchantsByApplicationIdSelector(state, applicationId)

  const steps = [
    {
      name: CREATE_A_PAYMENT,
      component: (!merchantIdQuery && isFetching) ? Loader : isRoleMerchant({ credentials }) ? CreateQuickTransactionFormC : CreateTransactionFormC,
      props: {
        credentials,
        platformId,
        applicationId,
        merchantLevelTwoLevelThreeDataEnabled,
        isSale,
        identityMaxTransactionAmount: merchantIdentityMaxTransactionAmount,
      },
      dataKey: 'createPayment',
      formValues: () => reduxFormValues(CREATE_TRANSACTION_FORM),
    },
    {
      name: REVIEW_PAYMENT,
      component: CreateTransactionReviewFormC,
      props: {
        credentials,
        identityId: get(merchantIdentity, 'id'),
        isSale,
        goToPreviousStep: prevFlowStep,
      },
      dataKey: 'createPayment',
      formValues: () => reduxFormValues(CREATE_TRANSACTION_REVIEW_FORM),
    },
  ]

  return {
    credentials,
    platformId,
    applicationId,
    senderMerchantId,
    steps,
    applicationMerchants,
    allowedProcessors,
    merchantIdQuery,
    isFetchingMerchants,
    merchantLevelTwoLevelThreeDataEnabled,
  }
}

class CreateTransactionC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplicationMerchants,
      merchantIdQuery,
      getMerchant,
    } = this.props

    if (!merchantIdQuery) {
      if (applicationId) {
        getApplicationMerchants({ applicationId, credentials })
      }
    }

    if (merchantIdQuery) {
      getMerchant({ merchantId: merchantIdQuery, credentials })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      showModal,
      applicationMerchants,
      allowedProcessors,
      merchantIdQuery,
      isFetchingMerchants,
      getMerchant,
      credentials,
      showFeatureNotAvailableModal,
    } = this.props

    const {
      isFetchingMerchants: prevIsFetchingMerchants,
      merchantIdQuery: prevMerchantIdQuery,
    } = prevProps

    if (!merchantIdQuery) {
      if (prevIsFetchingMerchants === true && isFetchingMerchants === false && !isEmpty(applicationMerchants)) {
        const filteredApplicationMerchants = filter(applicationMerchants, ({ mid, processor }) => includes(allowedProcessors, processor) && !isEmpty(mid))

        if (size(filteredApplicationMerchants) === 0) {
          showFeatureNotAvailableModal()
        }

        if (size(filteredApplicationMerchants) === 1) {
          const { id } = head(filteredApplicationMerchants)
          updateUrlQueries({ merchantId: id })
        }

        if (size(filteredApplicationMerchants) > 1) {
          showModal({
            modalType: SELECT_SENDER_FOR_CREATE_PAYMENT_MODAL,
            className: 'modal-md no-pad overflow-visible',
            modalProps: { canCloseModal: false },
          })
        }
      }
    }

    if (merchantIdQuery && !isEqual(prevMerchantIdQuery, merchantIdQuery)) {
      getMerchant({ merchantId: merchantIdQuery, credentials })
    }
  }

  render() {
    return (
      <CreateTransaction
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionC)
