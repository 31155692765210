import './SplitPaymentS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Loader from 'components/Shared/Loader/Loader'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import TableC from 'components/Shared/Table/TableC'
import { transferFeesColumnDescriptors } from 'components/Customer/Shared/ColumnDescriptors/columnDescriptors'
import { resourceTableThemeV2 } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  PAYMENT_DETAILS,
  ASSOCIATED_FEES,
  BUYER_DETAILS,
  SPLIT_DETAILS,
  REFUNDS,
  DISPUTES,
  CHILD_PAYMENT_SPLIT_DETAILS_DESCRIPTION,
  PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE,
  VIEW_API_RESPONSE,
} from 'constants/language/languageConstants'

const SplitPayment = ({
  tagsSectionData = [],
  transfer = {},
  paymentInstrument = {},
  isFetching = false,
  actions = [],
  headerData = {},
  contextBarData = {},
  detailsDataSection = [],
  device = {},
  buyerIdentity = {},
  splitDetailsTableData = [],
  parentPaymentPath = '',
  splitTransfersColumnDescriptors = [],
  fees = [],
  feesPath = '',
  showResponseModal = {},
}) => {
  return (
    <div className='SplitPayment'>
      { isFetching && <Loader /> }
      <ContextBar {...contextBarData} />

      { isFetching && <Loader /> }
      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      {/* TODO: add in once API is ready */}
      {/* <PageSectionV2C */}
      {/*   title={PAYMENT_DETAILS} */}
      {/*   columns={detailsDataSection} */}
      {/*   isFetching={isFetching} */}
      {/* /> */}

      { isFetching && <Loader /> }
      <TableC
        title={SPLIT_DETAILS}
        subtitle={CHILD_PAYMENT_SPLIT_DETAILS_DESCRIPTION}
        classNames='SplitDetails Table'
        data={splitDetailsTableData}
        columnDescriptors={splitTransfersColumnDescriptors}
        showTableFooter={false}
        theme={resourceTableThemeV2}
        doNotSortData
        isFetching={isFetching}
        tableLink={(
          <div className='view-api-response-link' onClick={showResponseModal}>
            {VIEW_API_RESPONSE}
          </div>
        )}
      />

      <BuyerDetailsC
        title={BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      {isEmpty(fees) && <PageSectionV2C title={ASSOCIATED_FEES} emptyMessage={PAYMENT_ASSOCIATED_FEES_EMPTY_MESSAGE} />}

      {!isEmpty(fees) && (
      <TableC
        title={ASSOCIATED_FEES}
        columnDescriptors={transferFeesColumnDescriptors}
        data={fees}
        isFetching={isFetching}
        path={feesPath}
        showTableFooter={false}
        theme={resourceTableThemeV2}
        classNames='Associated-Fees-Table'
      />
      )}

      <PageSectionV2C className='refunds-section' title={REFUNDS}>
        Please go to the <Link to={parentPaymentPath}>parent payment</Link> to create a refund.
      </PageSectionV2C>

      <PageSectionV2C className='disputes-section' title={DISPUTES}>
        Please go to the <Link to={parentPaymentPath}>parent payment</Link> to view dispute details.
      </PageSectionV2C>

      <TagsPageSectionV2
        data={tagsSectionData}
        record={transfer}
        isFetching={isFetching}
      />
    </div>
  )
}

SplitPayment.propTypes = {
  tagsSectionData: PropTypes.array,
  paymentInstrument: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsDataSection: PropTypes.array,
  buyerIdentity: PropTypes.object,
  splitDetailsTableData: PropTypes.array,
  parentPaymentPath: PropTypes.string,
  splitTransfersColumnDescriptors: PropTypes.array,
  fees: PropTypes.object,
  feesPath: PropTypes.string,
  showAPIResponseModal: PropTypes.func,
}

export default SplitPayment
