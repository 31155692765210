import React, { Component } from 'react'
import { connect } from 'react-redux'
import SkeletonInsights from 'components/Shared/SkeletonInsights/SkeletonInsights'
import PaymentsC from 'components/Customer/Pages/Payments/PaymentsC'
import CompanyTabsC from 'components/Customer/Pages/Company/CompanyTabsC'
import DashboardOverviewC from 'components/Customer/Pages/DashboardOverview/DashboardOverviewC'
import PayoutsC from 'components/Customer/Pages/Payouts/PayoutsC'
import MerchantUnderwritingInsightsC from 'components/Customer/Pages/Insights/UnderwritingInsights/MerchantUnderwritingInsights/MerchantUnderwritingInsightsC'
import DisbursementsDashboardOverviewC from 'components/Customer/Pages/DisbursementsDashboardOverview/DisbursementsDashboardOverviewC'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import hasPermissions from 'utilities/hasPermissions'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { SHOW_LANDING_PAGE } from 'constants/featureFlagConstants'
import { VIEW_TRANSACTIONS_LIST_PERMISSION } from 'constants/permissionConstants'
import get from 'lodash/get'

import {
  FETCHING,
  LOADED,
} from 'constants/reducerConstants'

const mapStateToProps = (state) => {
  const featureFlagsLoaded = get(state, `featureFlagsR.${LOADED}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`)
  const isFetchingDashboardUser = get(state, `usersR.${FETCHING}`, false)
  const isFetchingCredentials = get(state, `credentialsR.${FETCHING}`, false)
  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)

  const isFetching = isFetchingCredentials || isFetchingCurrentUser || isFetchingConfiguration || isFetchingDashboardUser || !featureFlagsLoaded

  const currentUser = getCurrentUser(state)
  const credentials = getCurrentCredentials(state)
  const isPayout = isPayoutFeature({ credentials })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)
  const isDisbursements = isDisbursementsPartnerDashboard(state)

  return {
    isFetching,
    currentUser,
    isPayout,
    isStandaloneUnderwriting,
    isDisbursements,
  }
}

class HomeC extends Component {
  state= {
    postFetchTimeout: false,
  }

  componentDidUpdate(prevProps) {
    const { isFetching: prevIsFetching } = prevProps
    const { isFetching } = this.props

    // hack to wait half a second until all feature flag data is completely loaded in to avoid payments page flash
    if (prevIsFetching && !isFetching) {
      this.setState({ postFetchTimeout: true })

      setTimeout(() => {
        this.setState({ postFetchTimeout: false })
      }, 500)
    }
  }

  render() {
    const {
      isFetching,
      isPayout,
      isStandaloneUnderwriting,
      isDisbursements,
    } = this.props

    const { postFetchTimeout } = this.state

    if (isFetching || postFetchTimeout) return <SkeletonInsights />

    let landingPage = isPayout ? <PayoutsC /> : <PaymentsC />

    // if the user does not have view transactions permissions, direct them to the company page
    if (!hasPermissions([VIEW_TRANSACTIONS_LIST_PERMISSION])) {
      landingPage = <CompanyTabsC />
    }

    if (getFeatureFlag(SHOW_LANDING_PAGE)) {
      // if the user should see the landing page, direct them there
      landingPage = <DashboardOverviewC />
    }

    if (isStandaloneUnderwriting) {
      // if a standalone underwriting user, direct them to the merchant underwriting insights page
      landingPage = <MerchantUnderwritingInsightsC />
    }

    if (isDisbursements) {
      landingPage = <DisbursementsDashboardOverviewC />
    }

    return landingPage
  }
}

export default connect(mapStateToProps)(HomeC)
