import frontendBalanceEntryModel from 'utilities/create/models/frontendBalanceEntryModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendBalanceEntriesModel = ({ data: balanceEntries }) => {
  const balanceEntriesModels = map(balanceEntries, (balanceEntry) => frontendBalanceEntryModel({ data: balanceEntry }))

  return keyBy(balanceEntriesModels, 'id')
}

export default frontendBalanceEntriesModel
