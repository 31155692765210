import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'

const frontendPlatformModel = ({ data: platform }) => {
  const [
    id,
    tags,
    name,
    billingPrefix,
    identityId,
    feeProfileId,
    creatingTransferFromReportEnabled,
    createdAt,
    updatedAt,
    settlementFundingIdentifier,
  ] = getMany(platform, [
    'id',
    'tags',
    'name',
    'billing_prefix',
    'identity',
    'fee_profile_id',
    'creating_transfer_from_report_enabled',
    'created_at',
    'updated_at',
    'settlement_funding_identifier',
  ])

  return removeUndefined({
    id,
    tags,
    name,
    billingPrefix,
    identityId,
    feeProfileId,
    creatingTransferFromReportEnabled,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    settlementFundingIdentifier,
  })
}

export default frontendPlatformModel
