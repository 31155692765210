import React from 'react'
import PropTypes from 'prop-types'

const BusinessTypeComponent = ({
  icon = '',
  name = '',
  info = '',
  example = '',
  className = '',
}) => {
  return (
    <div className={`business-type ${className} flex`}>
      <div className='icon'>
        <i className={icon} />
      </div>

      <div className='information'>
        <div className='p-1'>{name}</div>
        <div className='info p-2'>{info}</div>
        <div className='example p-2'>{example}</div>
      </div>
    </div>
  )
}

BusinessTypeComponent.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.string,
  example: PropTypes.string,
  className: PropTypes.string,
}

export default BusinessTypeComponent
