import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getAccessFormFileAPI from 'api/dashboard/get/getAccessFormFileAPI'
import postAccessFormFileExternalLinksAPI from 'api/dashboard/post/postAccessFormFileExternalLinksAPI'
import getGuestAccessFormFileAPI from 'api/guestDashboard/get/getGuestAccessFormFileAPI'
import postGuestAccessFormFileExternalLinksAPI from 'api/guestDashboard/post/postGuestAccessFormFileExternalLinksAPI'
import frontendFileModel from 'utilities/create/models/frontendFileModel'
import co from 'co'
import get from 'lodash/get'

function * getAccessFormFileO ({
  id,
  meta,
  credentials,
}) {
  let fileResponse
  let externalLinksResponse
  const accessFormId = get(meta, 'accessFormId', undefined)
  const isHostedAccessForm = get(meta, 'isHostedAccessForm', false)

  if (isHostedAccessForm) {
    [
      { data: fileResponse },
      { data: externalLinksResponse },
    ] = yield [
      getGuestAccessFormFileAPI({ id, meta, values: { accessFormId } }),
      postGuestAccessFormFileExternalLinksAPI({
        id,
        values: {
          type: 'VIEW',
          duration: '30',
          accessFormId,
        },
        meta: {
          contentType: 'application/vnd.api+json',
        },
      }),
    ]
  } else {
    [
      { data: fileResponse },
      { data: externalLinksResponse },
    ] = yield [
      getAccessFormFileAPI({ id, credentials, meta, values: { accessFormId } }),
      postAccessFormFileExternalLinksAPI({
        id,
        values: {
          type: 'VIEW',
          duration: '30',
          accessFormId,
        },
        meta: {
          contentType: 'application/vnd.api+json',
        },
        credentials,
      }),
    ]
  }

  const fileModel = frontendFileModel({
    data: {
      externalLink: externalLinksResponse,
      ...fileResponse,
    },
  })

  return createSuccessResponse({
    data: [fileModel],
  })
}

export default co.wrap(getAccessFormFileO)
