import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getState } from 'state-layer/configureStore'
import TableFiltersV2 from './TableFiltersV2'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import isPatching from 'utilities/is/isPatching'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getPageName from 'utilities/get/getPageName'
import { allowedKeyPressControls } from 'constants/focusControlConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import head from 'lodash/head'
import find from 'lodash/find'
import split from 'lodash/split'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import every from 'lodash/every'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import filter from 'lodash/filter'

import {
  isRolePartner,
  isRoleMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  getTableFiltersSelector,
  getSelectedItemsByKeySelector,
} from 'state-layer/selectors'

import {
  ADD_TABLE_FILTER,
  REMOVE_TABLE_FILTER,
  UNSELECT_ALL_ITEMS,
} from 'constants/flowConstants'

import {
  ADD_FILTER_SET,
  REMOVE_FILTER_SET,
} from 'constants/amplitudeConstants'

import {
  EQUALS,
  LESS_THAN_EQUALS,
  GREATER_THAN_EQUALS,
  IS_BETWEEN,
  ON,
  DATE_FILTERS,
  NOT_ALLOWED_PARTNER_FILTERS,
  NOT_ALLOWED_MERCHANT_FILTERS,
} from 'constants/filterConstants'

// TODO: look into another way instead of having to map
const STATE_ACTION_NAMES = [
  'showManageSubscriptionsDropdown',
  'showManageAmountsDropdown',
]

const ACTION_NAME_TO_STATE_MAP = {
  'Manage Subscriptions': 'showManageSubscriptionsDropdown',
  'Manage Amounts': 'showManageAmountsDropdown',
  'Manage Bank Accounts': 'showManageBankAccountsDropdown',
}

const createFilterItems = ({
  activeFilters,
  allowedFilters,
}) => reduce(activeFilters, (total, operands, fieldName) => {
  const foundFilter = find(allowedFilters, ({ field }) => { return field === fieldName })
  const label = get(foundFilter, 'label')

  const fieldItems = map(operands, (value, operand) => {
    return {
      id: `${fieldName}.${operand}`,
      field: fieldName,
      label,
      value,
      operand,
    }
  })

  return merge({}, total, keyBy(fieldItems, 'id'))
}, {})

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isRolePartnerCred = isRolePartner({ credentials })
  const isRoleMerchantCred = isRoleMerchant({ credentials })
  let allowedFilters = get(props, 'allowedFilters')

  if (isRolePartnerCred) {
    allowedFilters = filter(allowedFilters, ({ field }) => !includes(NOT_ALLOWED_PARTNER_FILTERS, field))
  }

  if (isRoleMerchantCred) {
    allowedFilters = filter(allowedFilters, ({ field }) => !includes(NOT_ALLOWED_MERCHANT_FILTERS, field))
  }

  const filterSets = get(props, 'filterSets')
  const activeFilters = getTableFiltersSelector(state)
  const selectedItemsKey = get(props, 'selectedItemsKey')
  const selectedItems = getSelectedItemsByKeySelector(state, selectedItemsKey)
  const patching = isPatching(state)

  const filterOptions = map(allowedFilters, ({ field, label }) => ({ label, value: field }))
  const filterItems = createFilterItems({ activeFilters, allowedFilters }) || {}
  const activeFilterKeys = keys(activeFilters)
  const allowedFilterKeys = keys(keyBy(allowedFilters, 'field'))

  const dropdownFilterCount =
    reduce(activeFilterKeys, (totalCount, activeFilter) => ((includes(allowedFilterKeys, activeFilter) && !isEmpty(get(state, `tableFiltersR.${activeFilter}`)))
      ? totalCount + 1 : totalCount), 0)

  return {
    credentials,
    filterOptions,
    allowedFilters,
    filterItems,
    hasFilters: !isEmpty(activeFilters),
    patching,
    activeFilters,
    filterSets,
    dropdownFilterCount,
    allowedFilterKeys,
    hasSelectedItems: !isEmpty(selectedItems),
  }
}

class TableFiltersV2C extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showFilters: false,
      showManageSubscriptionsDropdown: false,
      showManageAmountsDropdown: false,
      showManageBankAccountsDropdown: false,
      filterSetsStates: {},
    }
  }

  componentDidMount() {
    const {
      dispatch,
      initialQueries,
      allowedFilterKeys,
    } = this.props

    const queries = parseUrlQueries()

    forEach([initialQueries, queries], queryObject => {
      forEach(queryObject, (value, param) => {
        if (includes(allowedFilterKeys, head(split(param, '.')))) {
          const parts = split(param, '.')
          const field = get(parts, '[0]')
          const operand = get(parts, '[1]', EQUALS)

          if (field && operand && value) {
            dispatch({
              type: ADD_TABLE_FILTER,
              payload: {
                field,
                operand,
                value,
              },
            })
          }
        }
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeFilters: prevActiveFilters } = prevProps
    const { filterSets, activeFilters } = this.props

    // TODO: create utility + tests
    // update each filter set's checked status when filters change
    if (!isEqual(prevActiveFilters, activeFilters)) {
      const filterSetsStates = reduce(filterSets, (total, { filterSetName, filters }) => {
        const filterSetState = every(filters, (filterData = {}, filterName) => {
          const {
            operand,
            value,
            gte,
            lte,
          } = filterData

          if (get(DATE_FILTERS, filterName)) {
            const activeFilterValue = get(activeFilters, `${filterName}.${operand}`)
            const activeFilterGteValue = get(activeFilters, `${filterName}.gte`)
            const activeFilterLteValue = get(activeFilters, `${filterName}.lte`)

            if (operand === LESS_THAN_EQUALS) return value <= activeFilterValue && !activeFilterGteValue
            if (operand === GREATER_THAN_EQUALS) return value >= activeFilterValue && !activeFilterLteValue
            if (operand === ON) return lte === activeFilterLteValue && gte === activeFilterGteValue

            if (operand === IS_BETWEEN) {
              const lteState = lte <= activeFilterLteValue || (activeFilterGteValue && !activeFilterLteValue)
              const gteState = gte >= activeFilterGteValue || (activeFilterLteValue && !activeFilterGteValue)

              return lteState && gteState
            }
          }

          return get(activeFilters, `${filterName}.eq`) === value
        })

        return merge({}, total, {
          [filterSetName]: filterSetState,
        })
      }, {})

      this.setState({
        filterSetsStates,
      })
    }
  }

  handleEventClick = (event) => {
    const { target } = event

    const clickedOutside = !document.querySelector('.TableFiltersV2')?.contains(target)

    if (clickedOutside) {
      this.toggleFiltersMenu()
    }
  }

  toggleFiltersMenu = () => {
    const { showFilters } = this.state

    if (!showFilters) {
      document.addEventListener('mouseup', this.handleEventClick)
    } else {
      document.removeEventListener('mouseup', this.handleEventClick)
    }

    this.setState({ showFilters: !showFilters })
  }

  handleCloseDropdowns = () => {
    map(STATE_ACTION_NAMES, (action) => {
      this.setState({ [action]: false })
    })
  }

  handleCloseOtherDropdowns = (e, name) => {
    const currentActionName = ACTION_NAME_TO_STATE_MAP[name]

    map(STATE_ACTION_NAMES, (action) => {
      if (action !== currentActionName) {
        this.setState({ [action]: false })
      }
    })
  }

  // apply a filter set's filters
  handleClickFilterSet = ({ filterSetName, filters }) => {
    const {
      dispatch,
      credentials,
      activeFilters,
      filterSets,
    } = this.props

    const state = getState()
    const pathName = get(state, 'routing.locationBeforeTransitions.pathname')
    const search = get(state, 'routing.locationBeforeTransitions.search')
    const queries = parseUrlQueries(search)
    const pageName = getPageName(pathName, queries)
    const filterSetsStates = get(this.state, 'filterSetsStates')

    const clickedFilterSetState = get(filterSetsStates, filterSetName)
    const overdueChecked = get(filterSetsStates, 'overdue')
    const dueInLessThan30DaysChecked = get(filterSetsStates, 'dueInLessThan30Days')
    const setOverdueOff = filterSetName === 'overdue' && overdueChecked
    const setDueInLessThan30DaysOff = filterSetName === 'dueInLessThan30Days' && dueInLessThan30DaysChecked

    forEach(filters, (filterData = {}, field) => {
      const {
        operand,
        value,
        gte,
        lte,
      } = filterData

      // closes filter menu if opened when clicking a filter set
      const { showFilters } = this.state
      if (showFilters) this.setState({ showFilters: false })

      // handles adding/removing non-date filters
      if (!get(DATE_FILTERS, field)) {
        if (overdueChecked && dueInLessThan30DaysChecked) return

        dispatch({
          type: clickedFilterSetState ? REMOVE_TABLE_FILTER : ADD_TABLE_FILTER,
          payload: {
            field,
            operand,
            value,
          },
          meta: {
            actionId: sendAmplitudeActionEvent(clickedFilterSetState ? REMOVE_FILTER_SET : ADD_FILTER_SET, {
              filterSetName,
              credentials,
              filters,
              page: pageName,
            }),
          },
        })
      } else {
        // handles when checking overdue filter -> dueInLessThan30Days filter is disabled
        if (dueInLessThan30DaysChecked && !setOverdueOff) {
          dispatch({
            type: REMOVE_TABLE_FILTER,
            payload: {
              field,
              operand: GREATER_THAN_EQUALS,
              value: undefined,
            },
          })
        }
        // handles when both overdue + dueInLessThan30Days filter sets are active -> 1 will be disabled
        if (overdueChecked && dueInLessThan30DaysChecked) {
          const dueInLessThan30DaysGTE = get(filterSets, '1.filters.due_at.gte')
          const overdueLTE = get(filterSets, '0.filters.due_at.value')

          dispatch({
            type: setOverdueOff ? ADD_TABLE_FILTER : REMOVE_TABLE_FILTER,
            payload: {
              field,
              operand: GREATER_THAN_EQUALS,
              value: setOverdueOff ? dueInLessThan30DaysGTE : undefined,
            },
          })

          if (setDueInLessThan30DaysOff) {
            dispatch({
              type: ADD_TABLE_FILTER,
              payload: {
                field,
                operand: LESS_THAN_EQUALS,
                value: overdueLTE,
              },
            })
          }

          return
        }

        // handles adding/removing date filters without overlapping filter sets (supports gte/lte/on/between)
        if (operand !== LESS_THAN_EQUALS) {
          dispatch({
            type: clickedFilterSetState ? REMOVE_TABLE_FILTER : ADD_TABLE_FILTER,
            payload: {
              field,
              operand: GREATER_THAN_EQUALS,
              value: operand === GREATER_THAN_EQUALS ? value : gte,
            },
          })
        }

        if (operand !== GREATER_THAN_EQUALS) {
          dispatch({
            type: clickedFilterSetState ? REMOVE_TABLE_FILTER : ADD_TABLE_FILTER,
            payload: {
              field,
              operand: LESS_THAN_EQUALS,
              value: operand === LESS_THAN_EQUALS ? value : lte,
            },
          })
        }
      }
    })
  }

  toggleManageSubscriptionsDropdown = (e, name) => {
    const { showManageSubscriptionsDropdown } = this.state

    if (allowedKeyPressControls(e)) {
      this.handleCloseOtherDropdowns(e, name)

      this.setState({
        showManageSubscriptionsDropdown: !showManageSubscriptionsDropdown,
        currentActionName: 'Manage Subscriptions',
      })
    }
  }

  toggleManageAmountsDropdown = (e, name) => {
    const { showManageAmountsDropdown } = this.state

    if (allowedKeyPressControls(e)) {
      this.handleCloseOtherDropdowns(e, name)

      this.setState({
        showManageAmountsDropdown: !showManageAmountsDropdown,
        currentActionName: 'Manage Amounts',
      })
    }
  }

  toggleManageBankAccountsDropdown = (e, name) => {
    const { showManageBankAccountsDropdown } = this.state

    if (allowedKeyPressControls(e)) {
      this.handleCloseOtherDropdowns(e, name)

      this.setState({
        showManageBankAccountsDropdown: !showManageBankAccountsDropdown,
        currentActionName: 'Manage Bank Accounts',
      })
    }
  }

  toggleShowActionsDropdown = (e, name) => {
    switch (name) {
      case 'Manage Subscriptions':
        return this.toggleManageSubscriptionsDropdown(e, name)

      case 'Manage Amounts':
        return this.toggleManageAmountsDropdown(e, name)

      case 'Manage Bank Accounts':
        return this.toggleManageBankAccountsDropdown(e, name)

      default:
        return () => {}
    }
  }

  deselectAllItems = () => {
    const {
      dispatch,
      selectedItemsKey,
    } = this.props

    dispatch({
      type: UNSELECT_ALL_ITEMS,
      payload: {
        key: selectedItemsKey,
      },
    })
  }

  render() {
    const {
      showFilters,
      currentActionName,
      showManageSubscriptionsDropdown,
      showManageAmountsDropdown,
      showManageBankAccountsDropdown,
      filterSetsStates,
    } = this.state

    const actionDropdownMap = {
      'Manage Subscriptions': showManageSubscriptionsDropdown,
      'Manage Amounts': showManageAmountsDropdown,
      'Manage Bank Accounts': showManageBankAccountsDropdown,
    }

    return (
      <TableFiltersV2
        {...this.props}
        showFilters={showFilters}
        toggleFiltersMenu={this.toggleFiltersMenu}
        currentActionName={currentActionName}
        toggleShowActionsDropdown={this.toggleShowActionsDropdown}
        handleCloseDropdowns={this.handleCloseDropdowns}
        handleClickFilterSet={this.handleClickFilterSet}
        actionDropdownMap={actionDropdownMap}
        filterSetsStates={filterSetsStates}
        deselectAllItems={this.deselectAllItems}
      />
    )
  }
}

export default connect(mapStateToProps)(TableFiltersV2C)
