import React from 'react'
import PropTypes from 'prop-types'
import AccordionGroup from 'components/Customer/Shared/Page/PageSection/AccordionGroup'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import SanctionsScreeningReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReport/SanctionsScreeningReportTabs'
import OwnerIdentityVerificationReportV2C from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/OwnerIdentityVerificationReportV2/OwnerIdentityVerificationReportV2C'
import OwnerIdentityVerificationReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/OwnerIdentityVerificationReport/OwnerIdentityVerificationReportTabs'
import OwnerIdentityVerificationReportTabsV2 from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/OwnerIdentityVerificationReportV2/OwnerIdentityVerificationReportTabsV2'
import SocialMediaReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SocialMediaReport/SocialMediaReportTabs'
import ReversePhoneLookupReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/ReversePhoneLookupReport/ReversePhoneLookupReportTabs'
import AdverseMediaReportC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/AdverseMediaReport/AdverseMediaReportC'
import SanctionsScreeningReportV2C from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReportV2/SanctionsScreeningReportV2C'
import SanctionsScreeningReportTabsV2 from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReportV2/SanctionsScreeningReportTabsV2'
import formatUnderwritingReportTitle from 'utilities/formatters/formatUnderwritingReportTitle'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ADVERSE_MEDIA_SCREENING,
  ERROR as ERROR_LABEL,
  EXACT_HIT,
  HIGH_MATCH_HIT,
  IDENTITY_VERIFICATION,
  MATCHES_FOUND,
  MATCHES_NOT_FOUND,
  MEDIUM_MATCH_HIT,
  NO_HIT as NO_HIT_LABEL,
  NO_REPORT_DETAILS_AVAILABLE,
  REPORT_FAILED_TO_RUN,
  SANCTIONS_SCREENING,
  UNKNOWN as UNKNOWN_LABEL,
  SOCIAL_MEDIA_REPORT,
  REVERSE_PHONE_LOOKUP,
  EMAIL_ADDRESS_REPORT,
} from 'constants/language/languageConstants'

import {
  ADVERSE_MEDIA_REVIEW,
  ONBOARDING_REVIEW,
  SANCTIONS_REVIEW,
} from 'constants/reviewQueueConstants'
import EmailAddressReportV2Tabs from '../EmailAddressReportV2/EmailAddressReportV2Tabs'

const sanctionsScreeningNormalizationMap = {
  ERROR: ERROR_LABEL,
  NO_HIT: NO_HIT_LABEL,
  UNKNOWN: UNKNOWN_LABEL,
  HIT_EXACT: EXACT_HIT,
  HIT_HIGH_MATCH: HIGH_MATCH_HIT,
  HIT_MEDIUM_MATCH: MEDIUM_MATCH_HIT,
}

// NOTE: This component shows the various types of reports for underwriting
// It shows GIACT, PERSONA, or MATCH reports depending on what type of report is returned to use from the API
// If it is a standalone underwriting dashboard - we want to show V2 reports with no Raw data.

const OwnerReportAccordions = ({
  ownerIdentityReport = {},
  sanctionsReportV1 = {},
  sanctionsReportV2 = {},
  adverseMediaReport = {},
  emailReport = {},
  socialMediaReport = {},
  reversePhoneLookupReport = {},
  isStandaloneUnderwriting = false,
  reviewType = '',
}) => {
  const showIdentityVerificationReport = reviewType === ONBOARDING_REVIEW
  const showSanctionsScreeningReport = reviewType === ONBOARDING_REVIEW || reviewType === SANCTIONS_REVIEW
  const showAdverseMediaReport = reviewType === ONBOARDING_REVIEW || reviewType === ADVERSE_MEDIA_REVIEW
  const showEmailAddressReportV2 = reviewType === ONBOARDING_REVIEW
  const showSocialMediaReport = reviewType === ONBOARDING_REVIEW
  const showReversePhoneLookupReport = reviewType === ONBOARDING_REVIEW

  return (
    <AccordionGroup>
      {showIdentityVerificationReport && (
      <>
        {(get(ownerIdentityReport, 'version') === 'V2' || get(ownerIdentityReport, 'type') === 'PERSON_IDENTITY_REPORT_V2') && (
        <Accordion
          title={formatUnderwritingReportTitle(ownerIdentityReport, IDENTITY_VERIFICATION)}
          description={`${get(ownerIdentityReport, 'normalized_response')}: ${get(ownerIdentityReport, 'normalized_message')}`}
          isEmpty={isEmpty(get(ownerIdentityReport, 'details'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(ownerIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          {isStandaloneUnderwriting ? <OwnerIdentityVerificationReportV2C report={ownerIdentityReport} /> : <OwnerIdentityVerificationReportTabsV2 report={ownerIdentityReport} />}
        </Accordion>
        )}

        {get(ownerIdentityReport, 'source') === 'GIACT' && (
        <Accordion
          title={formatUnderwritingReportTitle(ownerIdentityReport, IDENTITY_VERIFICATION)}
          description={get(ownerIdentityReport, 'normalized_message') || ''}
          isEmpty={isEmpty(get(ownerIdentityReport, 'raw.PersonData'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(ownerIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <OwnerIdentityVerificationReportTabs report={ownerIdentityReport} />
        </Accordion>
        )}

        {/* if no report is run show the disabled accordion state */}
        {isEmpty(ownerIdentityReport) && (
        <Accordion
          title={formatUnderwritingReportTitle(ownerIdentityReport, IDENTITY_VERIFICATION)}
          isDisabled={isEmpty(ownerIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        />
        )}
      </>
      )}

      {showSanctionsScreeningReport && (
      <>
        {!isEmpty(sanctionsReportV1) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV1, SANCTIONS_SCREENING)}
          description={get(sanctionsReportV1, 'normalized_response') ? `${sanctionsScreeningNormalizationMap[get(sanctionsReportV1, 'normalized_response')]}: ${get(sanctionsReportV1, 'normalized_message')}` : ''}
          isEmpty={isEmpty(get(sanctionsReportV1, 'raw.PotentialMatchers')) || get(sanctionsReportV1, 'normalized_response') === 'NO_HIT'}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(sanctionsReportV1)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <SanctionsScreeningReportTabs report={sanctionsReportV1} />
        </Accordion>
        )}

        {!isEmpty(sanctionsReportV2) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV2, SANCTIONS_SCREENING)}
          description={`${get(sanctionsReportV2, 'normalized_response')}: ${get(sanctionsReportV2, 'normalized_message')}`}
          isEmpty={isEmpty(get(sanctionsReportV2, 'details.hits'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(sanctionsReportV2)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          {isStandaloneUnderwriting ? <SanctionsScreeningReportV2C report={sanctionsReportV2} /> : <SanctionsScreeningReportTabsV2 report={sanctionsReportV2} />}
        </Accordion>
        )}

        {/* if no sanctions report is run show the disabled accordion state */}
        {(isEmpty(sanctionsReportV2) && isEmpty(sanctionsReportV1)) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV2, SANCTIONS_SCREENING)}
          isDisabled={isEmpty(sanctionsReportV2)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        />
        )}
      </>
      )}

      { showEmailAddressReportV2 && !isEmpty(emailReport) && (
        <Accordion
          title={formatUnderwritingReportTitle(emailReport, EMAIL_ADDRESS_REPORT)}
          description={`${get(emailReport, 'normalized_response')}: ${get(emailReport, 'normalized_message')}`}
          isEmpty={isEmpty(emailReport, 'details')}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(emailReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <EmailAddressReportV2Tabs report={emailReport} />
        </Accordion>
      )}

      {showAdverseMediaReport && (
        <Accordion
          title={formatUnderwritingReportTitle(adverseMediaReport, ADVERSE_MEDIA_SCREENING, true)}
          description={get(adverseMediaReport, 'found') ? MATCHES_FOUND : MATCHES_NOT_FOUND}
          isEmpty={!get(adverseMediaReport, 'found')}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(adverseMediaReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <AdverseMediaReportC report={adverseMediaReport} />
        </Accordion>
      )}

      {showSocialMediaReport && (
      <Accordion
        title={SOCIAL_MEDIA_REPORT}
        isEmpty={isEmpty(socialMediaReport, 'details')}
        isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
        isDisabled={isEmpty(socialMediaReport)}
        isDisabledMessage={REPORT_FAILED_TO_RUN}
      >
        <SocialMediaReportTabs report={socialMediaReport} />
      </Accordion>
      )}

      {showReversePhoneLookupReport && (
        <Accordion
          title={formatUnderwritingReportTitle(reversePhoneLookupReport, REVERSE_PHONE_LOOKUP)}
          description={`${get(reversePhoneLookupReport, 'normalized_response')}: ${get(reversePhoneLookupReport, 'normalized_message')}`}
          isEmpty={isEmpty(reversePhoneLookupReport, 'details')}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(reversePhoneLookupReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <ReversePhoneLookupReportTabs report={reversePhoneLookupReport} />
        </Accordion>
      )}
    </AccordionGroup>
  )
}

OwnerReportAccordions.propTypes = {
  ownerIdentityReport: PropTypes.object,
  sanctionsReportV1: PropTypes.object,
  sanctionsReportV2: PropTypes.object,
  adverseMediaReport: PropTypes.object,
  emailReport: PropTypes.object,
  isStandaloneUnderwriting: PropTypes.bool,
  socialMediaReport: PropTypes.object,
  reviewType: PropTypes.string,
  reversePhoneLookupReport: PropTypes.object,
}

export default OwnerReportAccordions
