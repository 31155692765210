import { goToPath } from 'state-layer/history'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_RECIPIENT_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import { ADD_RECIPIENT_IDENTITY } from 'constants/amplitudeConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import omit from 'lodash/omit'
import head from 'lodash/head'
import keys from 'lodash/keys'

import {
  DUMMY_V1,
  FINIX_V1,
} from 'constants/processorConstants'

import {
  PAYMENT_CARD,
  BANK_ACCOUNT,
} from 'constants/bankConstants'

import {
  RECIPIENT_PATH,
  RECIPIENTS_PATH,
} from 'constants/pathConstants'

const submitAddRecipientForm = (values, dispatch, props) => {
  const {
    credentials,
  } = props
  const credentialId = get(credentials, 'id')
  const recipientType = get(values, 'type.value')
  const paymentInstrumentValues = get(values, 'paymentInstrument', {})
  const identityValues = recipientType === 'personal' ? omit(values, ['paymentInstrument', 'businessAddress']) : omit(values, ['paymentInstrument'])
  const env = get(credentials, 'environment')
  const isProductionEnv = env === LIVE_ENVIRONMENT
  const processor = isProductionEnv ? FINIX_V1 : DUMMY_V1

  const paymentInstrumentValuesToSubmit = get(paymentInstrumentValues, 'accountNumber') ? {
    ...paymentInstrumentValues,
    type: BANK_ACCOUNT,
    attemptBankAccountValidationCheck: true,
    accountType: get(paymentInstrumentValues, 'accountType.value'),
  } : {
    type: PAYMENT_CARD,
    ...paymentInstrumentValues,
  }

  dispatch({
    type: POST_RECIPIENT_IDENTITY_F_REQUEST,
    payload: {
      values: {
        ...identityValues,
        paymentInstrument: paymentInstrumentValuesToSubmit,
        processor,
      },
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const identityId = head(keys(newValues))
        goToPath({
          pathname: RECIPIENT_PATH({ credentialId, recipientId: identityId }),
        })
      },
      errorCallback: () => {
        goToPath({
          pathname: RECIPIENTS_PATH({ credentialId }),
        })
      },
      actionId: sendAmplitudeActionEvent(ADD_RECIPIENT_IDENTITY, {
        values: identityValues,
      }),
    },
  })
}
export default submitAddRecipientForm
