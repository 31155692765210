import './DashboardConfigurationFormS.scss'
import React from 'react'
import { ChromePicker } from 'react-color'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import FileUploader from 'components/Shared/Inputs/FileUploader/FileUploader'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import Button from 'components/Shared/Button/Button'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Loader from 'components/Shared/Loader/Loader'
import submitDashboardConfigurationsForm from 'utilities/submit/submitDashboardConfigurationsForm'
import validateDashboardConfigurationForm from 'utilities/validate/validateDashboardConfigurationForm'
import { IMAGE_FILE_ICON } from 'constants/iconConstants'
import { DASHBOARD_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { DASHBOARD_BRANDING_LEARN_MORE_LINK } from 'constants/urlConstants'
import { BRANDING_ENABLED_OPTIONS } from 'constants/dashboardConfigurationsConstants'

import {
  GENERAL_BRANDING,
  GENERAL_BRANDING_OVERVIEW,
  LEARN_MORE_LABEL,
  SIDEBAR_BACKGROUND,
  SIDEBAR_TEXT,
  SIDEBAR_TEXT_HIGHLIGHT,
  SHOW_DASHBOARD_BRANDING_TO_MERCHANTS,
  SHOW_DASHBOARD_BRANDING_SUB_DESCRIPTION,
  SUBDOMAIN,
  UPLOAD_LOGO,
  CUSTOM_COLORS,
  LIVE_PREVIEW,
  LIVE_PREVIEW_DESCRIPTION,
  SUBDOMAIN_CONFIG_TOOLTIP,
  SAVE,
  RESET_ALL,
} from 'constants/language/languageConstants'

const DashboardConfigurationForm = ({
  handleSubmit = () => {},
  isFetching = true,
  showEnabledInput = false,
  updateColor = () => {},
  handleClick = () => {},
  handleClose = () => {},
  sidebarBackgroundColor = '',
  sidebarTextColor = '',
  sidebarHighlightColor = '',
  displaySidebarHighlightColorPicker = false,
  displaySidebarTextColorPicker = false,
  displaySidebarBackgroundColorPicker = false,
  isLiveEnvironment,
  config,
  instructions,
  resetForm = () => {},
}) => {
  return (
    <form className='DashboardConfigurationForm' onSubmit={handleSubmit(submitDashboardConfigurationsForm)}>
      <h3>{GENERAL_BRANDING}</h3>
      <div className='general-branding-overview p-1 secondary'>
        {GENERAL_BRANDING_OVERVIEW}
        <a target='blank' href={DASHBOARD_BRANDING_LEARN_MORE_LINK} className='learn-more-link'>{LEARN_MORE_LABEL}</a>
      </div>

      { isFetching && <Loader /> }

      { !isFetching && (
      <>
        <div className='section'>
          { showEnabledInput && (
            <div className='enabled-section'>
              <Field
                name='enabled'
                component={RadioButton}
                options={BRANDING_ENABLED_OPTIONS}
                label={SHOW_DASHBOARD_BRANDING_TO_MERCHANTS}
                helperText={SHOW_DASHBOARD_BRANDING_SUB_DESCRIPTION}
              />
            </div>
          )}

          { showEnabledInput && isLiveEnvironment && (
          <div className='subdomain-customization-section flex items-flex-end'>
            <Field
              name='subDomainName'
              label={SUBDOMAIN}
              component={InputField}
              tooltip={SUBDOMAIN_CONFIG_TOOLTIP}
              tooltipPosition='right'
            />.payments-dashboard.com
          </div>
          )}

          <Field
            name='dashboardSidebarLogo'
            label={UPLOAD_LOGO}
            component={FileUploader}
            fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
            config={config}
            instructions={instructions}
          />

          <div className='color-customization-section'>
            <div className='custom-color-label label-2'>{CUSTOM_COLORS}</div>

            <Field
              name='showLivePreview'
              label={LIVE_PREVIEW}
              helperText={LIVE_PREVIEW_DESCRIPTION}
              component={ToggleSwitchC}
              formName={DASHBOARD_CONFIGURATIONS_FORM}
            />

            <div className='flex items-center'>
              <Field
                name='sidebarBackgroundColor'
                label={SIDEBAR_BACKGROUND}
                placeholder='#FFFFFF'
                component={InputField}
              />

              <div>
                <div
                  className='color-block'
                  style={{ backgroundColor: sidebarBackgroundColor }}
                  onClick={() => handleClick('displaySidebarBackgroundColorPicker')}
                />
                { displaySidebarBackgroundColorPicker ? (
                  <div className='color-picker-popup'>
                    <div className='color-picker-cover' onClick={() => handleClose('displaySidebarBackgroundColorPicker')} />
                    <ChromePicker
                      color={sidebarBackgroundColor}
                      onChange={(color) => { updateColor('sidebarBackgroundColor', color) }}
                      disableAlpha
                    />
                  </div>
                ) : null }
              </div>
            </div>

            <div className='flex items-center'>
              <Field
                name='sidebarTextColor'
                label={SIDEBAR_TEXT}
                placeholder='#020F1C'
                component={InputField}
              />

              <div>
                <div
                  className='color-block'
                  style={{ backgroundColor: sidebarTextColor }}
                  onClick={() => handleClick('displaySidebarTextColorPicker')}
                />
                { displaySidebarTextColorPicker ? (
                  <div className='color-picker-popup'>
                    <div className='color-picker-cover' onClick={() => handleClose('displaySidebarTextColorPicker')} />
                    <ChromePicker
                      color={sidebarTextColor}
                      onChange={(color) => { updateColor('sidebarTextColor', color) }}
                      disableAlpha
                    />
                  </div>
                ) : null }
              </div>
            </div>

            <div className='flex items-center'>
              <Field
                name='sidebarHighlightColor'
                label={SIDEBAR_TEXT_HIGHLIGHT}
                placeholder='#0B5DBC'
                component={InputField}
              />

              <div>
                <div
                  className='color-block'
                  style={{ backgroundColor: sidebarHighlightColor }}
                  onClick={() => handleClick('displaySidebarHighlightColorPicker')}
                />
                { displaySidebarHighlightColorPicker ? (
                  <div className='color-picker-popup'>
                    <div className='color-picker-cover' onClick={() => handleClose('displaySidebarHighlightColorPicker')} />
                    <ChromePicker
                      color={sidebarHighlightColor}
                      onChange={(color) => { updateColor('sidebarHighlightColor', color) }}
                      disableAlpha
                    />
                  </div>
                ) : null }
              </div>
            </div>
          </div>
        </div>

        <div className='buttons flex'>
          <Button onClick={resetForm} label={RESET_ALL} variant='secondary' className='reset-all-button' />
          <Button type='submit' label={SAVE} />
        </div>
      </>
      )}
    </form>
  )
}

DashboardConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  showEnabledInput: PropTypes.bool,
  updateColor: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  sidebarBackgroundColor: PropTypes.string,
  sidebarTextColor: PropTypes.string,
  sidebarHighlightColor: PropTypes.string,
  displaySidebarHighlightColorPicker: PropTypes.bool,
  displaySidebarTextColorPicker: PropTypes.bool,
  displaySidebarBackgroundColorPicker: PropTypes.bool,
  isLiveEnvironment: PropTypes.bool,
  config: PropTypes.object,
  instructions: PropTypes.array,
  resetForm: PropTypes.func,
}

export default reduxForm({
  form: DASHBOARD_CONFIGURATIONS_FORM,
  validate: validateDashboardConfigurationForm,
  enableReinitialize: true,
})(DashboardConfigurationForm)
