import { Preset } from '@superset-ui/core'

// NOTE:Any specific plugin that we add support for will require an npm install of the related package

// import CalendarChartPlugin from '@superset-ui/legacy-plugin-chart-calendar';
// import ChordChartPlugin from '@superset-ui/legacy-plugin-chart-chord';
// import CountryMapChartPlugin from '@superset-ui/legacy-plugin-chart-country-map';
// import EventFlowChartPlugin from '@superset-ui/legacy-plugin-chart-event-flow';
// import HeatmapChartPlugin from '@superset-ui/legacy-plugin-chart-heatmap';
// import HistogramChartPlugin from '@superset-ui/legacy-plugin-chart-histogram';
// import HorizonChartPlugin from '@superset-ui/legacy-plugin-chart-horizon';
// import MapBoxChartPlugin from '@superset-ui/legacy-plugin-chart-map-box';
// import PairedTTestChartPlugin from '@superset-ui/legacy-plugin-chart-paired-t-test';
// import ParallelCoordinatesChartPlugin from '@superset-ui/legacy-plugin-chart-parallel-coordinates';
// import PartitionChartPlugin from '@superset-ui/legacy-plugin-chart-partition';
// import PivotTableChartPlugin from '@superset-ui/legacy-plugin-chart-pivot-table';
// import RoseChartPlugin from '@superset-ui/legacy-plugin-chart-rose';
// import SankeyChartPlugin from '@superset-ui/legacy-plugin-chart-sankey';
// import SunburstChartPlugin from '@superset-ui/legacy-plugin-chart-sunburst';
import TableChartPlugin from '@superset-ui/plugin-chart-table'
// import TreemapChartPlugin from '@superset-ui/legacy-plugin-chart-treemap';
// import { WordCloudChartPlugin } from '@superset-ui/plugin-chart-word-cloud';
// import WorldMapChartPlugin from '@superset-ui/legacy-plugin-chart-world-map';

import {
  // AreaChartPlugin,
  // BarChartPlugin,
  // BubbleChartPlugin,
  // BulletChartPlugin,
  // CompareChartPlugin,
  DistBarChartPlugin,
  // DualLineChartPlugin,
  // LineChartPlugin,
  // LineMultiChartPlugin,
  // TimePivotChartPlugin,
} from '@superset-ui/legacy-preset-chart-nvd3'

// import { DeckGLChartPreset } from '@superset-ui/legacy-preset-chart-deckgl';

import {
  BigNumberChartPlugin,
  BigNumberTotalChartPlugin,
  EchartsPieChartPlugin,
  // EchartsBoxPlotChartPlugin,
  // EchartsAreaChartPlugin,
  // EchartsTimeseriesChartPlugin,
  EchartsTimeseriesBarChartPlugin,
  EchartsTimeseriesLineChartPlugin,
  // EchartsTimeseriesScatterChartPlugin,
  // EchartsTimeseriesSmoothLineChartPlugin,
  // EchartsTimeseriesStepChartPlugin,
  // EchartsGraphChartPlugin,
  // EchartsGaugeChartPlugin,
  // EchartsRadarChartPlugin,
  // EchartsFunnelChartPlugin,
  // EchartsTreemapChartPlugin,
  EchartsMixedTimeseriesChartPlugin,
  // EchartsTreeChartPlugin,
  // DistBarChartPlugin
} from '@superset-ui/plugin-chart-echarts'

// import { PivotTableChartPlugin as PivotTableChartPluginV2 } from '@superset-ui/plugin-chart-pivot-table';
// import { HandlebarsChartPlugin } from '@superset-ui/plugin-chart-handlebars';

import {
  BIG_NUMBER,
  BIG_NUMBER_TOTAL,
  TIMESERIES_LINE,
  MIXED_TIMESERIES,
  TABLE,
  ECHART_TIMESERIES_BAR,
  DIST_BAR,
  PIE,
} from 'constants/chartConstants'

class MainPreset extends Preset {
  constructor() {
    super({
      name: 'Legacy charts',
      // presets: [new DeckGLChartPreset()],
      plugins: [
        // new AreaChartPlugin().configure({ key: 'area' }),
        // new BarChartPlugin().configure({ key: 'bar' }),
        new BigNumberChartPlugin().configure({ key: BIG_NUMBER }),
        new BigNumberTotalChartPlugin().configure({ key: BIG_NUMBER_TOTAL }),
        // new EchartsBoxPlotChartPlugin().configure({ key: 'box_plot' }),
        // new BubbleChartPlugin().configure({ key: 'bubble' }),
        // new BulletChartPlugin().configure({ key: 'bullet' }),
        // new CalendarChartPlugin().configure({ key: 'cal_heatmap' }),
        // new ChordChartPlugin().configure({ key: 'chord' }),
        // new CompareChartPlugin().configure({ key: 'compare' }),
        // new CountryMapChartPlugin().configure({ key: 'country_map' }),
        // new DistBarChartPlugin().configure({ key: DIST_BAR }),
        // new DualLineChartPlugin().configure({ key: 'dual_line' }),
        // new EventFlowChartPlugin().configure({ key: 'event_flow' }),
        // new FilterBoxChartPlugin().configure({ key: 'filter_box' }),
        // new EchartsFunnelChartPlugin().configure({ key: 'funnel' }),
        // new EchartsTreemapChartPlugin().configure({ key: 'treemap_v2' }),
        // new EchartsGaugeChartPlugin().configure({ key: 'gauge_chart' }),
        // new EchartsGraphChartPlugin().configure({ key: 'graph_chart' }),
        // new EchartsRadarChartPlugin().configure({ key: 'radar' }),
        new EchartsMixedTimeseriesChartPlugin().configure({ key: MIXED_TIMESERIES }),
        // new HeatmapChartPlugin().configure({ key: 'heatmap' }),
        // new HistogramChartPlugin().configure({ key: 'histogram' }),
        // new HorizonChartPlugin().configure({ key: 'horizon' }),
        // new LineChartPlugin().configure({ key: 'line' }),
        // new LineMultiChartPlugin().configure({ key: 'line_multi' }),
        // new MapBoxChartPlugin().configure({ key: 'mapbox' }),
        // new PairedTTestChartPlugin().configure({ key: 'paired_ttest' }),
        // new ParallelCoordinatesChartPlugin().configure({ key: 'para' }),
        // new PartitionChartPlugin().configure({ key: 'partition' }),
        new EchartsPieChartPlugin().configure({ key: PIE }),
        // new PivotTableChartPlugin().configure({ key: 'pivot_table' }),
        // new PivotTableChartPluginV2().configure({ key: 'pivot_table_v2' }),
        // new RoseChartPlugin().configure({ key: 'rose' }),
        // new SankeyChartPlugin().configure({ key: 'sankey' }),
        // new SunburstChartPlugin().configure({ key: 'sunburst' }),
        new TableChartPlugin().configure({ key: TABLE }),
        // new TimePivotChartPlugin().configure({ key: 'time_pivot' }),
        // new TimeTableChartPlugin().configure({ key: 'time_table' }),
        // new TreemapChartPlugin().configure({ key: 'treemap' }),
        // new WordCloudChartPlugin().configure({ key: 'word_cloud' }),
        // new WorldMapChartPlugin().configure({ key: 'world_map' }),
        // new EchartsAreaChartPlugin().configure({
        //   key: 'echarts_area',
        // }),
        // new EchartsTimeseriesChartPlugin().configure({
        //   key: 'echarts_timeseries',
        // }),
        new EchartsTimeseriesBarChartPlugin().configure({ key: ECHART_TIMESERIES_BAR }),
        new EchartsTimeseriesLineChartPlugin().configure({ key: TIMESERIES_LINE }),
        // new EchartsTimeseriesSmoothLineChartPlugin().configure({
        //   key: 'echarts_timeseries_smooth',
        // }),
        // new EchartsTimeseriesScatterChartPlugin().configure({
        //   key: 'echarts_timeseries_scatter',
        // }),
        // new EchartsTimeseriesStepChartPlugin().configure({
        //   key: 'echarts_timeseries_step',
        // }),
        // new SelectFilterPlugin().configure({ key: 'filter_select' }),
        // new RangeFilterPlugin().configure({ key: 'filter_range' }),
        // new TimeFilterPlugin().configure({ key: 'filter_time' }),
        // new TimeColumnFilterPlugin().configure({ key: 'filter_timecolumn' }),
        // new TimeGrainFilterPlugin().configure({ key: 'filter_timegrain' }),
        // new EchartsTreeChartPlugin().configure({ key: 'tree_chart' }),
        // new HandlebarsChartPlugin().configure({ key: 'handlebars' }),
      ],
    })
  }
}

export default function setupPlugins() {
  new MainPreset().register()
}
