import React from 'react'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import getMany from 'utilities/get/getMany'
import { BANK_ACCOUNT } from 'constants/paymentInstrumentConstants'

// TODO: change the name of this component to something else, we use this outside of Disbursements dashboard
const DisbursementsAccountColumn = ({ paymentInstrument = {} }) => {
  const [
    maskedFullCardNumber,
    maskedAccountNumber,
    displayBrand,
    cardType,
    type,
    displayAccountType,
  ] = getMany(paymentInstrument, [
    'maskedFullCardNumber',
    'maskedAccountNumber',
    'displayBrand',
    'cardType',
    'type',
    'displayAccountType',
  ])

  const setUpTitle = () => {
    if (type === undefined || type === null) {
      return ''
    } if (type === BANK_ACCOUNT) {
      return maskedAccountNumber
    }
    return maskedFullCardNumber
  }

  const setUpSubtitle = () => {
    if (type === undefined || type === null) {
      return ''
    } if (type === BANK_ACCOUNT) {
      return displayAccountType
    }
    return `${displayBrand} ${cardType}`
  }

  const title = setUpTitle()
  const subtitle = setUpSubtitle()

  return (
    <div className='DisbursementsAccountColumn'>
      <TableItemSubtitle
        title={setUpTitle()}
        subtitle={setUpSubtitle()}
      />
    </div>
  )
}

export default DisbursementsAccountColumn
