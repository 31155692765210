import EmailTemplates from './EmailTemplates'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import WelcomeEmailC from 'components/Admin/Pages/EmailTemplates/WelcomeEmail/WelcomeEmailC'
import ContactSupportEmailC from 'components/Admin/Pages/EmailTemplates/ContactSupportEmail/ContactSupportEmailC'
import ContactSalesEmailC from 'components/Admin/Pages/EmailTemplates/ContactSalesEmail/ContactSalesEmailC'
import DashboardFeedbackEmailC from 'components/Admin/Pages/EmailTemplates/DashboardFeedbackEmail/DashboardFeedbackEmailC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const tabs = [
  {
    id: 'welcome-email',
    name: 'Welcome Email',
    component: WelcomeEmailC,
  },
  {
    id: 'dashboard-feedback-email',
    name: 'Dashboard Feedback Email',
    component: DashboardFeedbackEmailC,
  },
  {
    id: 'contact-support-email',
    name: 'Contact Support Email',
    component: ContactSupportEmailC,
  },
  {
    id: 'contact-sales-email',
    name: 'Contact Sales Email',
    component: ContactSalesEmailC,
  },
]

const mapStateToProps = (state) => {
  const isFetching = get(state, `emailTemplatesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)

  const headerTitle = 'Email Templates'

  return {
    isFetching,
    credentials,
    headerTitle,
    tabs,
  }
}

class EmailTemplatesC extends Component {
  render() {
    return (
      <EmailTemplates {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EmailTemplatesC)
