import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, hasSubmitFailed, isInvalid } from 'redux-form'
import IdentityProcessingInfoForm from './IdentityProcessingInfoForm'
import { getOnboardingFormDataItemSelector } from 'state-layer/selectors'
import patchOnboardingFormDocumentsRequest from 'utilities/actions/patch/patchOnboardingFormDocumentsRequest'
import getApplicationEnabledMCCs from 'utilities/get/getApplicationEnabledMCCs'
import showModalAction from 'utilities/actions/showModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { UPDATE_ONBOARDING_FORM_FILE_MODAL } from 'constants/modalConstants'
import { IDENTITY_PROCESSING_INFO_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import head from 'lodash/head'
import find from 'lodash/find'
import get from 'lodash/get'

import {
  getRequiredEDDDocuments,
  getHighRiskIndustriesByMCC,
} from 'constants/eddDocumentConstants'

import {
  finixMCCsList,
  finixMCCsSelectOptions,
} from 'constants/mccConstants'

const mapStateToProps = (state, props) => {
  const {
    onboardingFormId,
    underwritingProfile,
    maxTransactionAmount,
    achMaxTransactionAmount,
  } = props

  const isFetchingOnboardingFormData = get(state, `onboardingFormDataR.${FETCHING}`)
  const formSelector = formValueSelector(IDENTITY_PROCESSING_INFO_FORM)
  const previouslyProcessedCreditCards = formSelector(state, 'processingData.previouslyProcessedCreditCards')
  const onboardingForm = getOnboardingFormDataItemSelector(state, onboardingFormId)
  const processingData = get(onboardingForm, 'processingData')
  const processorGatewayDetails = get(onboardingForm, 'processorGatewayDetails')
  const processors = get(processorGatewayDetails, 'processors')
  const associatedFiles = get(onboardingForm, 'associatedFiles')
  const applicationId = get(onboardingForm, 'applicationId')

  const [
    underwritingProfileEnabledMCCs,
    underwritingProfileAllowAllMCCs,
  ] = getMany(underwritingProfile, [
    'mccDetails.enabledMCCs',
    'mccDetails.allowAllMCCs',
  ])

  const mccList = underwritingProfileAllowAllMCCs === false ? getApplicationEnabledMCCs(underwritingProfileEnabledMCCs, finixMCCsList) : finixMCCsSelectOptions
  const mcc = formSelector(state, 'processingData.mcc')
  const showTaxAuthority = mcc === '9311'
  const highRiskIndustries = getHighRiskIndustriesByMCC(mcc, 'ROLE_MERCHANT')
  const industries = formSelector(state, 'processingData.industries')
  const noHighRiskIndustries = formSelector(state, 'processingData.noHighRiskIndustries')
  const selectedIndustries = reduce(industries, (total, selected, industry) => (selected ? [...total, industry] : total), [])
  const requiredDocuments = getRequiredEDDDocuments(selectedIndustries, 'ROLE_MERCHANT')
  const optOut = formSelector(state, 'processingData.optOut')

  const missingDocuments = reduce(requiredDocuments, (total, requiredDocument) => {
    const requiredDocumentType = get(requiredDocument, 'type')
    const documentExists = find(associatedFiles, ({ type: fileType }) => fileType === requiredDocumentType)
    const hasOptedOut = formSelector(state, `processingData.optOut.${requiredDocumentType}`) || false

    return (documentExists || hasOptedOut) ? total : [...total, requiredDocument]
  }, [])

  const isMissingDocuments = size(missingDocuments) > 0

  const initialValues = isFetchingOnboardingFormData ? undefined : removeUndefined({ processingData: {
    ...processingData,
    maxTransactionAmount,
    achMaxTransactionAmount,
  } })

  const isFormInvalid = isInvalid(IDENTITY_PROCESSING_INFO_FORM)(state) && hasSubmitFailed(IDENTITY_PROCESSING_INFO_FORM)(state)
  const isBoardingToFinixV1Only = size(processors) === 1 && head(processors) === FINIX_V1

  return {
    previouslyProcessedCreditCards,
    initialValues,
    showTaxAuthority,
    achMaxTransactionAmount,
    mcc,
    mccList,
    isFormInvalid,
    isBoardingToFinixV1Only,
    highRiskIndustries,
    requiredDocuments,
    associatedFiles,
    selectedIndustries,
    noHighRiskIndustries,
    showHighRiskDocumentWarning: size(selectedIndustries) > 0,
    onboardingFormId,
    applicationId,
    isMissingDocuments,
    optOut,
    isFetchingOnboardingFormData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patchOnboardingFormDocuments: ({
      files,
      fileType,
      onboardingFormId,
      applicationId,
      associatedFiles,
    }) => dispatch(patchOnboardingFormDocumentsRequest({
      files,
      fileType,
      onboardingFormId,
      applicationId,
      associatedFiles,
    })),
    showUpdateFileNameModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_ONBOARDING_FORM_FILE_MODAL, modalProps, className: 'modal-md no-pad' })),
    showRemoveFileModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_ONBOARDING_FORM_FILE_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class IdentityProcessingInfoFormC extends Component {
  componentDidUpdate(prevProps) {
    const {
      selectedIndustries: prevSelectedIndustries,
      noHighRiskIndustries: prevNoHighRiskIndustries,
      previouslyProcessedCreditCards: prevPreviouslyProcessedCreditCards,
      mcc: prevMCC,
    } = prevProps

    const {
      dispatch,
      selectedIndustries,
      noHighRiskIndustries,
      previouslyProcessedCreditCards,
      mcc: MCC,
    } = this.props

    // clear out Amex MID when changing previouslyProcessedCreditCards to false
    if (previouslyProcessedCreditCards === 'false' && prevPreviouslyProcessedCreditCards !== previouslyProcessedCreditCards) {
      dispatch(change(IDENTITY_PROCESSING_INFO_FORM, 'processingData.amexMid', ''))
    }

    // if prevSelectedIndusrtries goes from empty to not empty, dispatch event to change processing.noHighRiskIndustries to false
    if (size(prevSelectedIndustries) === 0 && size(selectedIndustries) > 0) {
      dispatch(change(IDENTITY_PROCESSING_INFO_FORM, 'processingData.noHighRiskIndustries', false))
    }

    // if noHighRiskIndustries goes from false to true, dispatch event to clear out selected industries
    if (prevNoHighRiskIndustries === false && noHighRiskIndustries === true) {
      dispatch(change(IDENTITY_PROCESSING_INFO_FORM, 'processingData.industries', {}))
    }

    // if MCC is changed, update high risk fields to reset
    if (prevMCC !== MCC) {
      dispatch(change(IDENTITY_PROCESSING_INFO_FORM, 'processingData.noHighRiskIndustries', false))
      dispatch(change(IDENTITY_PROCESSING_INFO_FORM, 'processingData.industries', {}))
    }
  }

  onEDDFileDrop = ({
    files = [],
    fileType,
  }) => {
    const {
      onboardingFormId,
      applicationId,
      associatedFiles,
      patchOnboardingFormDocuments,
    } = this.props

    patchOnboardingFormDocuments({
      files,
      fileType,
      onboardingFormId,
      applicationId,
      associatedFiles,
    })
  }

  render() {
    return (
      <IdentityProcessingInfoForm
        {...this.props}
        onEDDFileDrop={this.onEDDFileDrop}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityProcessingInfoFormC)
