import './EnableProcessorsFormS.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import EnableProcessorsForm from 'components/Customer/Forms/EnableProcessorsForm/EnableProcessorsForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import submitAddProcessorsForm from 'utilities/submit/submitAddProcessorsForm'
import { getApplicationSelector } from 'state-layer/selectors'
import { ENABLE_PROCESSORS_FORM } from 'constants/formConstants'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import every from 'lodash/every'
import merge from 'lodash/merge'

import {
  getAvailableProcessors,
  getAvailableProcessorInputsSection,
} from 'constants/processorConstants'

const patchSections = (sections, processors) => {
  const newSections = map(sections, (section) => {
    const { inputs } = section

    const disableInputs = map(inputs, (input) => {
      return {
        props: {
          disabled: get(processors, get(input, 'props.name'), false),
        },
      }
    })

    return merge({}, section, { inputs: disableInputs })
  })

  return newSections
}

const mapStateToProps = (state, props) => {
  const applicationId = get(props, 'applicationId')
  const credentials = getCurrentCredentials(state)
  const credentialId = getCurrentCredentialsId(state)
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform(state)
  const availableProcessors = getAvailableProcessors()
  const availableProcessorsSection = getAvailableProcessorInputsSection({ isStandaloneUnderwriting })
  const application = getApplicationSelector(state, applicationId)
  const processors = get(application, 'processors')
  const formSelector = formValueSelector(ENABLE_PROCESSORS_FORM)

  const activeProcessors = reduce(processors, (result, processor) => {
    const processorName = get(processor, 'processor')

    return merge({}, result, { [processorName]: true })
  }, {})

  const disabled = every(availableProcessors, (processor) => {
    return get(activeProcessors, processor, false) === !!(formSelector(state, processor))
  })

  const sections = patchSections(availableProcessorsSection, activeProcessors)

  return {
    credentials,
    credentialId,
    disabled,
    initialValues: activeProcessors,
    sections,
    isStandaloneUnderwriting,
  }
}

class EnableProcessorsFormC extends Component {
  render() {
    const { applicationId } = this.props

    return (
      <EnableProcessorsForm
        {...this.props}
        formName='EnableProcessorsForm'
        id={applicationId}
        onSubmit={submitAddProcessorsForm}
      />
    )
  }
}

export default connect(mapStateToProps)(EnableProcessorsFormC)
