import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM_TEMPLATE_FORMS } from 'constants/apiConstants'
import get from 'lodash/get'

const getComplianceFormTemplateFormsAPI = ({ meta, values, queries }) => {
  const complianceFormTemplateId = get(values, 'id')

  return dashboardAPI.get({
    path: COMPLIANCE_FORM_TEMPLATE_FORMS({ complianceFormTemplateId }),
    queries,
    meta,
  })
}

export default getComplianceFormTemplateFormsAPI
