import './CreateCredentialFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { CREATE_CREDENTIAL_FORM } from 'constants/formConstants'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import validateCreateCredentialForm from 'utilities/validate/validateCreateCredentialForm'
import Button from 'components/Shared/Button/Button'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import { EMAIL_ADDRESS } from 'constants/language/languageConstants'

const CreateCredentialForm = ({
  handleSubmit = () => {},
  submitLabel = 'Save',
  credentialTypeOptions = [],
  apiHostOptions = [],
}) => {
  return (
    <form className='CreateCredentialForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h2>Create Credential</h2>

        <Field
          name='credentialName'
          label='Credential Name'
          component={InputField}
        />

        <Field
          name='credentialType'
          label='Type'
          component={SelectField}
          options={credentialTypeOptions}
        />

        <Field
          name='apiHost'
          label='API Host '
          component={SelectField}
          options={apiHostOptions}
        />

        <Field
          name='userName'
          label='Username'
          component={InputField}
        />

        <Field
          name='password'
          label='Password'
          component={InputField}
        />

        <Field
          name='credentialEmail'
          label={EMAIL_ADDRESS}
          component={InputField}
        />

        <div className='buttons flex flex-end'>
          <Button type='submit' label={submitLabel} />
        </div>

      </div>
    </form>
  )
}

CreateCredentialForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  credentialTypeOptions: PropTypes.array,
  apiHostOptions: PropTypes.array,
}

export default reduxForm({
  form: CREATE_CREDENTIAL_FORM,
  validate: validateCreateCredentialForm,
})(CreateCredentialForm)
