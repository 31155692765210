import React, { Component } from 'react'
import { connect } from 'react-redux'
import VelocityLimits from './VelocityLimits'
import { goToPath } from 'state-layer/history'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { SET_LIMITS } from 'constants/language/languageConstants'
import get from 'lodash/get'

import {
  SET_APPLICATION_LEVEL_VELOCITY_LIMITS_PATH,
  SET_PLATFORM_LEVEL_VELOCITY_LIMITS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const applicationId = get(props, 'applicationId')

  const path = applicationId ? SET_APPLICATION_LEVEL_VELOCITY_LIMITS_PATH({ credentialId, applicationId }) :
    SET_PLATFORM_LEVEL_VELOCITY_LIMITS_PATH({ credentialId })

  const setLimitsActions = [
    {
      label: SET_LIMITS,
      condition: isRolePlatform({ credentials }),
      action: () => goToPath({
        pathname: path,
      }),
    },
  ]

  return {
    credentials,
    setLimitsActions,
  }
}

class VelocityLimitsC extends Component {
  render() {
    return (
      <VelocityLimits
        {...this.props}
      />
    )
  }
}
export default connect(mapStateToProps)(VelocityLimitsC)
