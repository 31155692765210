import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import AdditionalUnderwritingDataForm from './AdditionalUnderwritingDataForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import toggleCheckedStatus from 'utilities/toggleCheckedStatus'
import { isFlexPlatform } from 'constants/flexConstants'
import { ADDITIONAL_UNDERWRITING_DATA_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const identityId = get(props, 'identityId')
  const formSelector = formValueSelector(ADDITIONAL_UNDERWRITING_DATA_FORM)
  const creditCheckAllowed = formSelector(state, 'creditCheckAllowed') || false
  const merchantAgreementAccepted = formSelector(state, 'merchantAgreementAccepted') || false
  const isFlex = isFlexPlatform()

  return {
    credentials,
    identityId,
    creditCheckAllowed,
    merchantAgreementAccepted,
    isFlex,
  }
}

class AdditionalUnderwritingDataFormC extends Component {
  toggleAllowCreditCheck = ({ event }) => {
    const { dispatch } = this.props

    toggleCheckedStatus({ dispatch, event, name: 'creditCheckAllowed', form: ADDITIONAL_UNDERWRITING_DATA_FORM })
  }

  toggleAcceptMerchantAgreement = ({ event }) => {
    const { dispatch } = this.props

    toggleCheckedStatus({ dispatch, event, name: 'merchantAgreementAccepted', form: ADDITIONAL_UNDERWRITING_DATA_FORM })
  }

  render() {
    return (
      <AdditionalUnderwritingDataForm
        {...this.props}
        toggleAllowCreditCheck={this.toggleAllowCreditCheck}
        toggleAcceptMerchantAgreement={this.toggleAcceptMerchantAgreement}
      />
    )
  }
}

export default connect(mapStateToProps)(AdditionalUnderwritingDataFormC)
