import patchSettlementAPI from 'api/finix/patch/patchSettlementAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import get from 'lodash/get'
import map from 'lodash/map'
import co from 'co'
import { CLOSE } from 'constants/settlementConstants'

function * patchSettlementO ({
  values: {
    settlementIds,
  },
  credentials,
  meta,
}) {
  const responses = yield map(settlementIds, (id) => patchSettlementAPI({
    id,
    values: {
      action: CLOSE,
    },
    credentials,
    meta,
  }))

  const formattedResponse = responses.map(({ data, error }, idx) => {
    const id = get(settlementIds, idx)
    const errors = get(error, 'response.data._embedded.errors')

    return error ? { id, errors } : data
  })

  return createSuccessResponse({ data: formattedResponse })
}

export default co.wrap(patchSettlementO)
