import React, { Component } from 'react'
import { connect } from 'react-redux'
import OwnerIdentityVerificationReportV2 from './OwnerIdentityVerificationReportV2'
import Address from 'components/Customer/Shared/Display/Address/Address'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import formatPhone from 'utilities/forms/format/formatPhone'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  NO_MATCH as NO_MATCH_LABEL,
  MATCH as MATCH_LABEL,
  UNKNOWN,
  OWNER_NAME,
  OWNER_TAX_ID,
  OWNER_BIRTHDATE,
  OWNER_ADDRESS,
  OWNER_PHONE,
  OWNER_EMAIL,
  PASSED as PASSED_LABEL,
  FAILED as FAILED_LABEL,
  NAME,
  BIRTHDATE,
  TAX_ID_ISSUANCE,
  ADDRESS,
  PHONE_NUMBER,
  PERSON,
} from 'constants/language/languageConstants'

import {
  MATCH,
  NO_MATCH,
  PASSED,
  FAILED,
} from 'constants/underwritingConstants'

import {
  CHECK_CIRCLE_ICON,
  DOT_CIRCLE,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const report = get(props, 'report', {})

  const [
    addressDetailsResult,
    addressDetailsDescription,
    nameDetailsResult,
    nameDetailsDescription,
    phoneNumberDetailsResult,
    phoneNumberDetailsDescription,
    taxIdDetailsResult,
    taxIdDetailsDescription,
    birthdateDetailsResult,
    birthdateDetailsDescription,
    emailDetailsResult,
    emailDetailsDescription,
    personDetails = [],
  ] = getMany(report, [
    'details.address_details.result',
    'details.address_details.description',
    'details.name_details.result',
    'details.name_details.description',
    'details.phone_number_details.result',
    'details.phone_number_details.description',
    'details.tax_id_details.result',
    'details.tax_id_details.description',
    'details.birthdate_details.result',
    'details.birthdate_details.description',
    'details.email_details.result',
    'details.email_details.description',
    'details.person_details',
  ])

  const formatVerificationSummaryValue = (value, description) => {
    switch (value) {
      case NO_MATCH:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${TIMES_CIRCLE_ICON}`} />
            {NO_MATCH_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case FAILED:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${TIMES_CIRCLE_ICON}`} />
            {FAILED_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case MATCH:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />
            {MATCH_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      case PASSED:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa fa-${CHECK_CIRCLE_ICON}`} />
            {PASSED_LABEL}
            { description && <TooltipLabel message={description} /> }
          </div>
        )

      default:
        return (
          <div className='flex items-center verification-summary-string'>
            <i className={`icon fa ${DOT_CIRCLE}`} />
            {UNKNOWN}
            { description && <TooltipLabel message={description} /> }
          </div>
        )
    }
  }

  const verificationSummaryData = convertPageSectionDataToV2([
    { label: OWNER_TAX_ID, value: formatVerificationSummaryValue(taxIdDetailsResult, taxIdDetailsDescription) },
    { label: OWNER_NAME, value: formatVerificationSummaryValue(nameDetailsResult, nameDetailsDescription) },
    { label: OWNER_BIRTHDATE, value: formatVerificationSummaryValue(birthdateDetailsResult, birthdateDetailsDescription) },
    { label: OWNER_ADDRESS, value: formatVerificationSummaryValue(addressDetailsResult, addressDetailsDescription) },
    { label: OWNER_EMAIL, value: formatVerificationSummaryValue(emailDetailsResult, emailDetailsDescription) },
    { label: OWNER_PHONE, value: formatVerificationSummaryValue(phoneNumberDetailsResult, phoneNumberDetailsDescription) },
  ])

  const showPersonsData = !isEmpty(personDetails)
  const personsData = map(personDetails, (person, index) => {
    const [
      name,
      birthdate,
      taxIdState,
      taxIdYearStart,
      taxIdYearEnd,
      addresses,
      numbers,
    ] = getMany(person, [
      'name',
      'birthdate',
      'tax_id.issue_state.state',
      'tax_id.issue_year_start.year',
      'tax_id.issue_year_end.year',
      'addresses',
      'phone_numbers',
    ])

    const addressesMap = map(addresses, (address, addressIdx) => {
      const [
        line1,
        line2,
        city,
        region,
        postalCode,
        status,
      ] = getMany(address, [
        'line1',
        'line2',
        'city',
        'region',
        'postal_code',
        'status',
      ])

      return {
        label: `${ADDRESS} #${addressIdx + 1}`,
        value: <><Address address={{ line1, line2, city, region, postalCode }} />{status === 'Current' ? <div>({status})</div> : null}</>,
      }
    })

    const phoneNumbersMap = map(numbers, (number, numberIdx) => {
      const [
        type,
        phoneNumber,
      ] = getMany(number, [
        'type',
        'phone_number',
      ])

      return {
        label: `${PHONE_NUMBER} #${numberIdx + 1}`,
        value: <><div>{formatPhone(phoneNumber)}</div><div>({type})</div></>,
      }
    })

    return {
      title: `${PERSON} #${index + 1}`,
      personalInformationData: convertPageSectionDataToV2([
        { label: NAME, value: name },
        { label: BIRTHDATE, value: birthdate },
        { label: TAX_ID_ISSUANCE, value: taxIdState ? `${taxIdState} (${taxIdYearStart}/${taxIdYearEnd})` : '-' },
      ]),
      showAddressData: !isEmpty(addresses),
      addressData: convertPageSectionDataToV2(addressesMap),
      showPhoneNumberData: !isEmpty(numbers),
      phoneNumberData: convertPageSectionDataToV2(phoneNumbersMap),
    }
  })

  return {
    credentials,
    verificationSummaryData,
    showPersonsData,
    personsData,
  }
}

class OwnerIdentityVerificationReportV2C extends Component {
  render() {
    return (
      <OwnerIdentityVerificationReportV2
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(OwnerIdentityVerificationReportV2C)
