import React, { Component } from 'react'
import { connect } from 'react-redux'
import DownloadCSRFileForm from './DownloadCSRFileForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import postDownloadCSRFileRequest from 'utilities/actions/post/postDownloadCSRFileRequest'
import getMany from 'utilities/get/getMany'
import { ADD_IOS_APPLICATION_NAME_DATA_KEY } from 'constants/flowsConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    identityId,
    formValues,
    rowApplicationName,
  ] = getMany(props, [
    'identityId',
    'formValues',
    'rowApplicationName',
  ])

  const iOSApplicationName = rowApplicationName ? rowApplicationName : get(formValues, `${ADD_IOS_APPLICATION_NAME_DATA_KEY}.iOSApplicationName`)

  return {
    credentials,
    identityId,
    iOSApplicationName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postDownloadCSRFile: ({ credentials, identityId, iOSApplicationName }) => dispatch(postDownloadCSRFileRequest({ credentials, identityId, iOSApplicationName })),
  }
}

class DownloadCSRFileFormC extends Component {
  downloadCSRFile = () => {
    const {
      credentials,
      identityId,
      postDownloadCSRFile,
      iOSApplicationName,
    } = this.props

    postDownloadCSRFile({ credentials, identityId, iOSApplicationName })
  }

  render() {
    return (
      <DownloadCSRFileForm
        {...this.props}
        downloadCSRFile={this.downloadCSRFile}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCSRFileFormC)
