import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORMS } from 'constants/apiConstants'

const getComplianceFormsAPI = ({ queries, meta }) => {
  return dashboardAPI.get({
    path: COMPLIANCE_FORMS,
    queries,
    meta,
  })
}

export default getComplianceFormsAPI
