import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYOUT_PROFILE } from 'constants/apiConstants'

const patchPayoutSettingsAPI = ({ id, credentials, queries, meta, values }) => {
  return paymentsAPI.put({
    path: PAYOUT_PROFILE({ payoutProfileId: id }),
    credentials,
    values,
    queries,
    meta,
  })
}

export default patchPayoutSettingsAPI
