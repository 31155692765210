import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const AddNewDevice = ({
  steps = [],
  edit = false,
}) => {
  return (
    <div className='AddNewDevice'>
      <FlowsC
        flowClassName='add-new-device-flow'
        steps={steps}
        hideFlowProgress
        layout='vertical'
        flowVariant='form-page-borderless'
        edit={edit}
      />
    </div>
  )
}

AddNewDevice.propTypes = {
  steps: PropTypes.array,
  edit: PropTypes.bool,
}

export default AddNewDevice
