import moment from 'moment'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { ATTEST_COMPLIANCE_FORM } from 'constants/amplitudeConstants'
import split from 'lodash/split'
import get from 'lodash/get'

import {
  PATCH_COMPLIANCE_FORM_F_REQUEST,
  GET_COMPLIANCE_FORM_F_REQUEST,
} from 'constants/flowConstants'

const submitAttestComplianceForm = (values, dispatch, props) => {
  const { credentials, complianceFormId } = props
  const { merchantExecutiveName, title } = values

  const valuesToSubmit = {
    pci_saq_a: {
      name: merchantExecutiveName,
      signed_at: get(split(moment().toISOString(), '.'), [0]).concat('Z'),
      title,
    },
  }

  dispatch({
    type: PATCH_COMPLIANCE_FORM_F_REQUEST,
    payload: {
      values: valuesToSubmit,
      credentials,
      id: complianceFormId,
    },
    meta: {
      successCallback: () => {
        dispatch({
          type: GET_COMPLIANCE_FORM_F_REQUEST,
          payload: {
            id: complianceFormId,
            credentials,
          },
        })
      },
      actionId: sendAmplitudeActionEvent(ATTEST_COMPLIANCE_FORM, {
        id: complianceFormId,
        values: valuesToSubmit,
        credentials,
      }),
    },
  })
}

export default submitAttestComplianceForm
