import './SocialMediaReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

const SocialMediaReport = ({
  socialMediaData = [],
}) => {
  return (
    <div className='SocialMediaReport'>
      <PageSectionV2C
        className='social-media-data-section'
        columns={socialMediaData}
      />
    </div>
  )
}

SocialMediaReport.propTypes = {
  socialMediaData: PropTypes.array,
}

export default SocialMediaReport
