import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendNotificationSettingModel = ({ data: notificationSetting }) => {
  const [
    eventType,
    notificationType,
  ] = getMany(notificationSetting, [
    'event_type',
    'notification_type',
  ])

  return { notificationType, eventType }
}

export default frontendNotificationSettingModel
