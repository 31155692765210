import React, { Component } from 'react'
import { connect } from 'react-redux'
import TransferFailureDetails from './TransferFailureDetails'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferAPI from 'api/finix/get/getTransferAPI'
import getAuthorizationAPI from 'api/finix/get/getAuthorizationAPI'
import { VIEW_API_RESPONSE_MODAL } from 'constants/modalConstants'
import {
  MAKING_A_PAYMENT,
  TRANSFERS_API_REFERENCE,
} from 'constants/language/languageConstants'
import {
  MAKING_A_PAYMENT_API_DOC_LINK,
  PAYMENTS_LEARN_MORE_LINK,
} from 'constants/urlConstants'
import {
  TRANSFER,
  AUTHORIZATION,
} from 'constants/apiConstants'
import { hasPartnerAccess } from 'utilities/validate/checkRoleCredentials'
import get from 'lodash/get'


const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const hasAPIAccess = hasPartnerAccess({ credentials })
  const transfer = get(props, 'transfer')
  const transferId = get(transfer, 'id')

  return {
    transfer,
    transferId,
    hasAPIAccess,
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({ modalType: VIEW_API_RESPONSE_MODAL, modalProps })),
  }
}

class TransferFailureDetailsC extends Component {
  showTransferApiResponseModal = () => {
    const {
      transferId,
      credentials,
      showAPIResponseModal,
    } = this.props

    const host = get(credentials, 'host')
    const transferAPIDocs = [
      {
        label: MAKING_A_PAYMENT,
        link: MAKING_A_PAYMENT_API_DOC_LINK,
      },
      {
        label: TRANSFERS_API_REFERENCE,
        link: PAYMENTS_LEARN_MORE_LINK,
      },
    ]

    showAPIResponseModal({
      api: transferId?.substring(0, 2) === 'TR' ? getTransferAPI : getAuthorizationAPI,
      apiProps: {
        id: transferId,
        credentials,
      },
      apiRoute: host && `${host}/${transferId?.substring(0, 2) === 'TR' ? TRANSFER({ transferId }) : AUTHORIZATION({ transferId })}`,
      apiMethod: 'GET',
      apiDocuments: transferAPIDocs,
    })
  }

  render() {
    return (
      <TransferFailureDetails
        {...this.props}
        showTransferApiResponseModal={this.showTransferApiResponseModal}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferFailureDetailsC)
