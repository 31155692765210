import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UpdateRecipientSettingsFormC from 'components/Customer/Forms/UpdateRecipientSettingsForm/UpdateRecipientSettingsFormC'

import {
  UPDATE_RECIPIENT_SETTINGS,
  UPDATE_RECIPIENT_SETTINGS_SUBTITLE,
} from 'constants/language/languageConstants'

const UpdateRecipientSettingsModal = ({
  underwritingProfile = {},
}) => {
  return (
    <GenericModal
      title={UPDATE_RECIPIENT_SETTINGS}
      subTitle={UPDATE_RECIPIENT_SETTINGS_SUBTITLE}
      className='UpdateRecipientSettingsModal'
      Component={UpdateRecipientSettingsFormC}
      underwritingProfile={underwritingProfile}
    />
  )
}

UpdateRecipientSettingsModal.propTypes = {
  underwritingProfile: PropTypes.object,
}

export default UpdateRecipientSettingsModal
