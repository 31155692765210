import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import formatAddress from 'utilities/formatters/formatAddress'
import formatWebsite from 'utilities/formatters/formatWebsite'
import formatDateObject from 'utilities/formatters/formatDateObject'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatPhone from 'utilities/forms/format/formatPhone'
import { normalizeAddress } from 'constants/normalizationConstants'
import { countryNameByCountryCode } from 'constants/countryConstants'
import { IDENTITY_ROLES_LABEL_MAP } from 'constants/identityConstants'
import { BUYER } from 'constants/language/languageConstants'
import capitalize from 'lodash/capitalize'
import keyBy from 'lodash/keyBy'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'
import join from 'lodash/join'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

const frontendIdentityModel = ({ data: identity }) => {
  const [
    id,
    businessType,
    businessName,
    doingBusinessAs,
    title,
    firstName,
    lastName,
    middleName,
    dob,
    email,
    phone,
    principalPercentageOwnership,
    taxIdProvided,
    businessPhone,
    businessTaxIdProvided,
    businessAddress,
    personalAddress,
    incorporationDate,
    annualCardVolume,
    maxTransactionAmount = 0,
    maxACHTransactionAmount,
    mcc,
    defaultStatementDescriptor,
    ownershipType,
    hasAcceptedCreditCardsPreviously,
    website,
    amexMid,
    discoverMid,
    taxAuthority,
    createdAt,
    tags,
    merchants,
    paymentInstruments,
    application,
    applicationId,
    additionalUnderwritingData,
    payoutPlanInstrumentMapping,
    files,
    identityRoles,
    underwritingReviews,
    onboardingForm,
  ] = getMany(identity, [
    'id',
    'entity.business_type',
    'entity.business_name',
    'entity.doing_business_as',
    'entity.title',
    'entity.first_name',
    'entity.last_name',
    'entity.middle_name',
    'entity.dob',
    'entity.email',
    'entity.phone',
    'entity.principal_percentage_ownership',
    'entity.tax_id_provided',
    'entity.business_phone',
    'entity.business_tax_id_provided',
    'entity.business_address',
    'entity.personal_address',
    'entity.incorporation_date',
    'entity.annual_card_volume',
    'entity.max_transaction_amount',
    'entity.ach_max_transaction_amount',
    'entity.mcc',
    'entity.default_statement_descriptor',
    'entity.ownership_type',
    'entity.has_accepted_credit_cards_previously',
    'entity.url',
    'entity.amex_mid',
    'entity.discover_mid',
    'entity.tax_authority',
    'created_at',
    'tags',
    'merchants',
    'paymentInstruments',
    'application',
    'application.id',
    'additional_underwriting_data',
    'payoutPlanInstrumentMapping',
    'files',
    'identity_roles',
    'underwritingReviews',
    'onboardingForm',
  ])

  const [
    averageCardTransferAmount,
    averageACHTransferAmount,
    annualACHVolume,
    businessDescription,
    cardVolumeDistribution,
    creditCheckAllowed,
    creditCheckIPAddress,
    creditCheckTimestamp,
    creditCheckUserAgent,
    merchantAgreementAccepted,
    merchantAgreementIPAddress,
    merchantAgreementTimestamp,
    merchantAgreementUserAgent,
    refundPolicy,
    volumeDistributionByBusinessType,
    amexMarketingConsent,
  ] = getMany(additionalUnderwritingData, [
    'average_card_transfer_amount',
    'average_ach_transfer_amount',
    'annual_ach_volume',
    'business_description',
    'card_volume_distribution',
    'credit_check_allowed',
    'credit_check_ip_address',
    'credit_check_timestamp',
    'credit_check_user_agent',
    'merchant_agreement_accepted',
    'merchant_agreement_ip_address',
    'merchant_agreement_timestamp',
    'merchant_agreement_user_agent',
    'refund_policy',
    'volume_distribution_by_business_type',
    'amex_marketing_consent',
  ])

  const [
    cardPresentPercentage,
    ecommercePercentage,
    mailOrderTelephoneOrderPercentage,
  ] = getMany(cardVolumeDistribution, [
    'card_present_percentage',
    'ecommerce_percentage',
    'mail_order_telephone_order_percentage',
  ])

  const [
    businessToBusinessVolumePercentage,
    businessToConsumerVolumePercentage,
    personToPersonVolumePercentage,
    consumerToConsumerVolumePercentage,
    otherVolumePercentage,
  ] = getMany(volumeDistributionByBusinessType, [
    'business_to_business_volume_percentage',
    'business_to_consumer_volume_percentage',
    'person_to_person_volume_percentage',
    'consumer_to_consumer_volume_percentage',
    'other_volume_percentage',
  ])

  const [
    amexMarketingConsentAccepted,
    amexMarketingConsentTimestamp,
    amexMarketingConsentUserAgent,
    amexMarketingConsentIPAddress,
  ] = getMany(amexMarketingConsent, [
    'accepted',
    'timestamp',
    'user_agent',
    'ip_address',
  ])

  const displayBusinessAddress = formatAddress({ address: businessAddress })
  const displayPersonalAddress = formatAddress({ address: personalAddress })

  const formattedDateOfBirth = formatDateObject({ date: dob })
  const formattedIncorporationDate = formatDateObject({ date: incorporationDate })

  const formattedWebsite = formatWebsite(website)

  // TODO: once we support default currency for an identity in the API we can use the currency code for all formatMoney calls
  const formattedAverageCardTransferAmount = formatMoney({ amount: averageCardTransferAmount })
  const formattedAverageACHTransferAmount = formatMoney({ amount: averageACHTransferAmount })
  const formattedAnnualACHVolume = formatMoney({ amount: annualACHVolume })

  const formattedPayoutPlanInstrumentMapping = keyBy(payoutPlanInstrumentMapping, 'settlementAlias')

  const formattedFullName = firstName && lastName ? `${startCase(firstName)} ${startCase(lastName)}` : undefined

  const resourceTitle = businessName || formattedFullName || email || id
  const buyerName = businessName || formattedFullName

  const existingPercentage = (percentage) => {
    return percentage || percentage === 0
  }

  const merchantProcessors = merchants ? map(merchants, ({ processor }) => processor) : undefined
  const merchantProcessorsString = merchantProcessors ? merchantProcessors.join(', ') : undefined

  const merchantProcessorOnboardingStates = merchants ? map(merchants, ({ onboardingState }) => onboardingState) : undefined
  const merchantProcessorOnboardingStatesString = merchantProcessorOnboardingStates ? merchantProcessorOnboardingStates.join(', ') : undefined

  const country = get(businessAddress, 'country') || get(personalAddress, 'country')

  return removeUndefined({
    id,
    businessName,
    businessType,
    displayBusinessType: convertSnakeToSentenceCase(businessType),
    doingBusinessAs,
    displayBusinessName: formatBusinessNameString({ businessName, doingBusinessAs }),
    title,
    firstName,
    lastName,
    middleName,
    fullName: formattedFullName,
    email,
    phone,
    displayPhone: formatPhone(phone),
    taxIdProvided: taxIdProvided ? 'Yes' : 'No',
    principalPercentageOwnership,
    displayPrincipalPercentageOwnership: principalPercentageOwnership ? `${principalPercentageOwnership}%` : undefined,
    businessPhone,
    displayBusinessPhone: formatPhone(businessPhone),
    businessTaxIdProvided: businessTaxIdProvided ? 'Yes' : 'No',
    businessAddress: normalizeAddress(businessAddress),
    displayBusinessAddress,
    displayRefundPolicy: convertSnakeToSentenceCase(refundPolicy),
    personalAddress: normalizeAddress(personalAddress),
    displayPersonalAddress,
    dateOfBirth: dob,
    displayDateOfBirth: formattedDateOfBirth,
    incorporationDate,
    displayIncorporationDate: formattedIncorporationDate,
    annualCardVolume,
    displayAnnualCardVolume: formatMoney({ amount: annualCardVolume }),
    maxTransactionAmount,
    displayMaxTransactionAmount: formatMoney({ amount: maxTransactionAmount }),
    maxACHTransactionAmount,
    displayMaxACHTransactionAmount: formatMoney({ amount: maxACHTransactionAmount }),
    mcc,
    defaultStatementDescriptor,
    ownershipType,
    displayOwnershipType: capitalize(ownershipType),
    previouslyProcessedCreditCards: hasAcceptedCreditCardsPreviously,
    hasAcceptedCreditCardsPreviously: hasAcceptedCreditCardsPreviously ? 'Yes' : 'No',
    website: formattedWebsite,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    identityRole: head(identityRoles),
    displayIdentityRole: IDENTITY_ROLES_LABEL_MAP[head(identityRoles)] || BUYER,
    tags,
    merchants,
    paymentInstruments,
    application,
    applicationId,
    amexMid,
    discoverMid,
    taxAuthority,
    additionalUnderwritingData,
    averageCardTransferAmount,
    formattedAverageCardTransferAmount,
    averageACHTransferAmount,
    formattedAverageACHTransferAmount,
    annualACHVolume,
    formattedAnnualACHVolume,
    businessDescription,
    cardVolumeDistribution,
    cardPresentPercentage,
    displayCardPresentPercentage: existingPercentage(cardPresentPercentage) ? `${cardPresentPercentage}%` : undefined,
    ecommercePercentage,
    displayEcommercePercentage: existingPercentage(ecommercePercentage) ? `${ecommercePercentage}%` : undefined,
    mailOrderTelephoneOrderPercentage,
    displayMailOrderTelephoneOrderPercentage: existingPercentage(mailOrderTelephoneOrderPercentage) ? `${mailOrderTelephoneOrderPercentage}%` : undefined,
    creditCheckAllowed,
    displayCreditCheckAllowed: creditCheckAllowed ? 'Yes' : 'No',
    creditCheckIPAddress,
    creditCheckTimestamp: formatDate({ date: creditCheckTimestamp }),
    creditCheckUserAgent,
    merchantAgreementAccepted,
    displayMerchantAgreementAccepted: merchantAgreementAccepted ? 'Yes' : 'No',
    merchantAgreementIPAddress,
    merchantAgreementTimestamp: formatDate({ date: merchantAgreementTimestamp }),
    merchantAgreementUserAgent,
    refundPolicy,
    volumeDistributionByBusinessType,
    businessToBusinessVolumePercentage,
    displayBusinessToBusinessVolumePercentage: existingPercentage(businessToBusinessVolumePercentage) ? `${businessToBusinessVolumePercentage}%` : undefined,
    businessToConsumerVolumePercentage,
    displayBusinessToConsumerVolumePercentage: existingPercentage(businessToConsumerVolumePercentage) ? `${businessToConsumerVolumePercentage}%` : undefined,
    personToPersonVolumePercentage,
    displayPersonToPersonVolumePercentage: existingPercentage(personToPersonVolumePercentage) ? `${personToPersonVolumePercentage}%` : undefined,
    consumerToConsumerVolumePercentage,
    displayConsumerToConsumerVolumePercentage: existingPercentage(consumerToConsumerVolumePercentage) ? `${consumerToConsumerVolumePercentage}%` : undefined,
    otherVolumePercentage,
    displayOtherVolumePercentage: existingPercentage(otherVolumePercentage) ? `${otherVolumePercentage}%` : undefined,
    payoutPlanInstrumentMapping: formattedPayoutPlanInstrumentMapping,
    resourceTitle,
    merchantProcessors,
    merchantProcessorsString,
    merchantProcessorOnboardingStates,
    merchantProcessorOnboardingStatesString,
    files,
    identityRoles,
    displayIdentityRoles: startCase(toLower(join(identityRoles, ', '))),
    underwritingReviews,
    country,
    displayCountry: countryNameByCountryCode[country],
    amexMarketingConsentAccepted,
    amexMarketingConsentTimestamp,
    amexMarketingConsentUserAgent,
    amexMarketingConsentIPAddress,
    onboardingForm,
    buyerName,
  })
}

export default frontendIdentityModel
