import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingMerchant from './UnderwritingMerchant'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import MerchantUnderwritingReviewC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReview/MerchantUnderwritingReview/MerchantUnderwritingReviewC'
import IdentityMerchantsC from 'components/Customer/Pages/Identity/IdentityMerchants/IdentityMerchantsC'
import UnderwritingMerchantCaseNotesC from 'components/Customer/Pages/UnderwritingMerchantCaseNotes/UnderwritingMerchantCaseNotesC'
import PastRunsC from 'components/Customer/Pages/UnderwritingV2/PastRuns/PastRunsC'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import UnderwritingAssignee from 'components/Customer/Shared/Display/UnderwritingAssignee/UnderwritingAssignee'
import patchUnderwritingReviewRequest from 'utilities/actions/patch/patchUnderwritingReviewRequest'
import getUnderwritingReviewRequest from 'utilities/actions/get/getUnderwritingReviewRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import hasPermission from 'utilities/hasPermission'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import { ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL } from 'constants/modalConstants'
import { REVIEW_QUEUE_UPDATE_PERMISSION } from 'constants/permissionConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import some from 'lodash/some'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  MERCHANT_UNDERWRITING_RESOURCE_TITLE,
  MERCHANT_ACCOUNTS,
  CASE_NOTES,
  ASSIGNEE,
  COUNTRY,
  UW_CASE_NOTE_EMPTY_MSG,
  LATEST_RUN,
  PAST_RUNS,
  REVIEW_STATUS,
  REVIEW_CREATED_ON,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const reviewId = get(props, 'params.reviewId')
  const runId = get(props, 'params.runId')
  const review = getUnderwritingReviewSelector(state, reviewId)
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const hasUpdatePermission = hasPermission(state, REVIEW_QUEUE_UPDATE_PERMISSION)
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const isPastRunView = some(get(props, 'routes'), ({ path }) => path === 'past-run')

  const {
    subjectId,
    entityType,
    entityId,
  } = parseUrlQueries()

  const [
    applicationName,
    applicationId,
    identityId,
    displayBusinessName,
  ] = getMany(review, [
    'entity.application.businessName',
    'entity.application.id',
    'entity.id',
    'entity.displayBusinessName',
  ])

  const tabs = [
    {
      id: 'latest-run',
      name: LATEST_RUN,
      component: MerchantUnderwritingReviewC,
      props: {
        reviewId,
        subjectId,
        entityId,
        entityType,
      },
    },
    {
      id: 'past-runs',
      name: PAST_RUNS,
      component: PastRunsC,
      props: {
        reviewId,
        subjectId,
        entityId,
        entityType,
      },
    },
    {
      id: 'merchant-accounts',
      name: MERCHANT_ACCOUNTS,
      component: IdentityMerchantsC,
      props: {
        identityId,
      },
    },
    {
      id: 'merchant-case-notes',
      name: CASE_NOTES,
      component: UnderwritingMerchantCaseNotesC,
      props: {
        reviewId,
        emptyMessage: UW_CASE_NOTE_EMPTY_MSG,
      },
    },
  ]

  const contextBarData = {
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: displayBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    identityId,
    credentials,
    credentialId,
    tabs,
    reviewId,
    runId,
    review,
    currentUserEmail,
    hasUpdatePermission,
    contextBarData,
    subjectId,
    entityType,
    entityId,
    isPastRunView,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnderwritingReview: ({ subjectId, reviewId, entityType, entityId, runId, credentials }) => dispatch(getUnderwritingReviewRequest({ subjectId, reviewId, entityType, entityId, runId, credentials })),
    patchUnderwritingReview: (actionProps) => dispatch(patchUnderwritingReviewRequest(actionProps)),
    showAssignToMeModal: (modalProps) => dispatch(showModalAction({ modalType: ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL, modalProps })),
  }
}

class UnderwritingMerchantC extends Component {
  componentDidMount() {
    const {
      reviewId,
      subjectId,
      entityType,
      entityId,
      credentials,
      getUnderwritingReview,
      runId,
    } = this.props

    getUnderwritingReview({
      subjectId,
      reviewId,
      entityType,
      entityId,
      runId,
      credentials,
    })
  }

  render() {
    const {
      hasUpdatePermission,
      showAssignToMeModal,
      isFetching,
      reviewId,
      subjectId,
      review,
      currentUserEmail,
      isPastRunView,
      runId,
    } = this.props

    const [
      businessName,
      displayCreatedOn,
      outcome,
      assignee,
      displayCountry,
      reviewType,
      displayReviewType,
    ] = getMany(review, [
      'entity.businessName',
      'displayCreatedOn',
      'state',
      'assignee',
      'displayCountry',
      'reviewType',
      'displayReviewType',
    ])

    const headerData = {
      resource: {
        label: MERCHANT_UNDERWRITING_RESOURCE_TITLE,
        id: isPastRunView ? runId : reviewId,
        badge: isPastRunView ? `${displayReviewType} - Past Run` : displayReviewType,
      },
      items: [
        {
          value: <h1>{businessName}</h1>,
        },
        {
          label: REVIEW_STATUS,
          value: <UnderwritingReviewStatus value={outcome} />,
        },
        {
          label: REVIEW_CREATED_ON,
          value: displayCreatedOn,
        },
        {
          label: COUNTRY,
          value: displayCountry,
        },
        {
          label: ASSIGNEE,
          value: <UnderwritingAssignee outcome={outcome} assignee={assignee} hasUpdatePermission={hasUpdatePermission} showAssignToMeModal={showAssignToMeModal} reviewId={reviewId} subjectId={subjectId} currentUserEmail={currentUserEmail} />,
          condition: !isPastRunView,
        },
      ],
      isFetching,
    }

    return (
      <UnderwritingMerchant
        headerData={headerData}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingMerchantC)
