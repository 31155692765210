import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendApplePayCertModel = ({ data: applicationCert }) => {
  const [
    id,
    status,
    type,
    linkedTo,
    fileName,
    fileData,
    expiresAt,
    name,
  ] = getMany(applicationCert, [
    'id',
    'status',
    'type',
    'linked_to',
    'file_name',
    'data',
    'valid_before',
    'name',
  ])

  return removeUndefined({
    id,
    status,
    type,
    linkedTo,
    fileName,
    fileData,
    expiresAt,
    name,
  })
}

export default frontendApplePayCertModel
