import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditUnderwritingWorkflowModal from './EditUnderwritingWorkflowModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import patchUnderwritingWorkflowRequest from 'utilities/actions/patch/patchUnderwritingWorkflowRequest'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingWorkflowsR.${FETCHING}`)

  return {
    credentials: getCurrentCredentials(state),
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editUnderwritingWorkflow: ({ credentials, applicationId, workflowId, actOnDecision, successCallback }) => dispatch(patchUnderwritingWorkflowRequest({
      workflowId,
      credentials,
      values: {
        linkedTo: applicationId,
        actOnDecision,
      },
      successCallback,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditUnderwritingWorkflowModalC extends Component {
  updateUnderwritingWorkflow = () => {
    const {
      credentials,
      applicationId,
      workflowId,
      actOnDecision,
      editUnderwritingWorkflow,
      closeModal,
    } = this.props

    editUnderwritingWorkflow({
      credentials,
      applicationId,
      workflowId,
      actOnDecision: !actOnDecision,
      successCallback: closeModal,
    })
  }

  render() {
    return (
      <EditUnderwritingWorkflowModal
        {...this.props}
        updateUnderwritingWorkflow={this.updateUnderwritingWorkflow}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUnderwritingWorkflowModalC)
