import React from 'react'
import PropTypes from 'prop-types'
import { getSubscriptionsIdentityMerchantPaymentInstrumentAndPlanSelector } from 'state-layer/selectors'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_SUBSCRIPTIONS } from 'constants/flowConstants'
import { SUBSCRIPTIONS_LINKS_KEY } from 'constants/linkConstants'

import {
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_SUBTITLE,
} from 'constants/language/languageConstants'

const Subscriptions = ({
  isFetching = false,
  initialQueries = {},
  quickFilters = [],
  subscriptionsPath = '',
  actions = [],
  allowedFilters = [],
  hideHeader = false,
  initialFilters = {},
}) => {
  return (
    <div className='Subscriptions'>
      {!hideHeader && (
      <HeaderV2C
        title={SUBSCRIPTIONS}
        subTitle={SUBSCRIPTIONS_SUBTITLE}
      />
      )}

      <TableC
        isFetching={isFetching}
        flow={GET_SUBSCRIPTIONS}
        columnDescriptors={columnDescriptors}
        linksKey={SUBSCRIPTIONS_LINKS_KEY}
        selector={getSubscriptionsIdentityMerchantPaymentInstrumentAndPlanSelector}
        initialQueries={initialQueries}
        quickFilters={quickFilters}
        path={subscriptionsPath}
        actions={actions}
        allowedFilters={allowedFilters}
        initialFilters={initialFilters}
      />
    </div>
  )
}

Subscriptions.propTypes = {
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  quickFilters: PropTypes.array,
  subscriptionsPath: PropTypes.string,
  actions: PropTypes.array,
  allowedFilters: PropTypes.array,
  hideHeader: PropTypes.bool,
  initialFilters: PropTypes.object,
}

export default Subscriptions
