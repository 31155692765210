import getDisputeRequest from 'utilities/actions/get/getDisputeRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_ACCEPT_DISPUTE_F_REQUEST } from 'constants/flowConstants'
import { ACCEPT_LIABILITY_FOR_DISPUTE } from 'constants/amplitudeConstants'

const submitAcceptLiabilityForm = (values, dispatch, props) => {
  const {
    disputeId,
    credentials,
    closeModal,
  } = props

  const { note } = values

  dispatch({
    type: POST_ACCEPT_DISPUTE_F_REQUEST,
    payload: {
      credentials,
      id: disputeId,
      values: { note },
    },
    meta: {
      actionId: sendAmplitudeActionEvent(ACCEPT_LIABILITY_FOR_DISPUTE, {
        credentials,
        disputeId,
        note,
      }),
      successCallback: () => {
        dispatch(getDisputeRequest({ disputeId, credentials }))
        closeModal()
      },
    },
  })
}

export default submitAcceptLiabilityForm
