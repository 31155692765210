import getSubscriptionSchedulesAPI from 'api/finix/get/getSubscriptionSchedulesAPI'
import getSubscriptionScheduleAmountsAPI from 'api/finix/get/getSubscriptionScheduleAmountsAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendSubscriptionAmountsModel from 'utilities/create/models/frontendSubscriptionAmountsModel'
import map from 'lodash/map'
import merge from 'lodash/merge'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import co from 'co'

function * getSubscriptionSchedulesAmountsO ({
  credentials,
  queries = {},
  meta = {},
}) {
  const schedulesResponse = yield getSubscriptionSchedulesAPI({ credentials, queries, meta })
  const schedules = get(schedulesResponse, 'data._embedded.subscription_schedules')

  const amounts = yield map(schedules, (schedule) => getSubscriptionScheduleAmountsAPI({ id: get(schedule, 'id'), credentials }))
  const amountsModels = map(amounts, (amount) => frontendSubscriptionAmountsModel({ data: get(amount, 'data._embedded.subscription_amounts') }))

  const mergedSchedules = map(schedules, (schedule, index) => {
    return merge({}, schedule, {
      amounts: keyBy(get(amountsModels, index), 'id'),
    })
  })

  return createSuccessResponse({
    data: {
      schedules: mergedSchedules,
      page: get(schedulesResponse, 'data.page'),
      links: get(schedulesResponse, 'data._links'),
    },
  })
}

export default co.wrap(getSubscriptionSchedulesAmountsO)
