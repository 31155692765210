import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import EditPlatformProcessorConfigForm from 'components/Customer/Forms/EditPlatformProcessorConfigForm/EditPlatformProcessorConfigForm'
import { EDIT_PROCESSOR_CONFIG_FORM } from 'constants/formConstants'

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(EDIT_PROCESSOR_CONFIG_FORM)
  const currentFieldValue = formSelector(state, 'configPair[0].value')

  return {
    currentFieldValue,
  }
}

class EditPlatformProcessorConfigFormC extends Component {
  componentDidMount() {
    const {
      initialize,
      configKey,
      configValue,
    } = this.props

    initialize({ configPair: [{ name: configKey, value: configValue }] })
  }

  render() {
    return (
      <EditPlatformProcessorConfigForm {...this.props} />
    )
  }
}

EditPlatformProcessorConfigFormC.propTypes = {
  initialize: PropTypes.func,
  configKey: PropTypes.string,
  configValue: PropTypes.string,
}

export default connect(mapStateToProps)(reduxForm({
  form: EDIT_PROCESSOR_CONFIG_FORM,
})(EditPlatformProcessorConfigFormC))
