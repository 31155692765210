import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingMerchantCaseNotes from './UnderwritingMerchantCaseNotes'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hasPermissions from 'utilities/hasPermissions'
import { REVIEW_QUEUE_UPDATE_PERMISSION } from 'constants/permissionConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const showAddNewNote = hasPermissions([REVIEW_QUEUE_UPDATE_PERMISSION])

  return {
    credentials,
    credentialId,
    showAddNewNote,
  }
}

class UnderwritingMerchantCaseNotesC extends Component {
  render() {
    return (
      <UnderwritingMerchantCaseNotes
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(UnderwritingMerchantCaseNotesC)
