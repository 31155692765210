import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_WORKFLOW } from 'constants/apiConstants'

const patchUnderwritingWorkflowAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.patch({
    credentials,
    path: UNDERWRITING_WORKFLOW({ workflowId: id }),
    values,
    meta,
  })
}

export default patchUnderwritingWorkflowAPI
