import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { FETCHING } from 'constants/reducerConstants'
import { getPaymentLinkDataSelector } from 'state-layer/selectors'
import PaymentLinkOverview from './PaymentLinkOverview'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { MERCHANT_PATH } from 'constants/pathConstants'
import includes from 'lodash/includes'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

import {
  PAYMENT_METHOD_TYPE_TO_LABEL_MAP,
  ALLOWED_PAYMENT_METHODS,
} from 'constants/paymentLinkConstants'

import {
  NO,
  YES,
  LINK_TYPE,
  AMOUNT_TYPE,
  TOTAL_AMOUNT,
  CREATED_ON,
  EXPIRES_ON,
  MERCHANT_ACCOUNT,
  PAYMENT_LINK_NAME,
  NAME,
  DESCRIPTION,
  IMAGE_URL,
  UNIT_PRICE,
  QUANTITY,
  COLLECT_BUYER_EMAIL,
  COLLECT_BUYER_PHONE_NUMBER,
  COLLECT_BILLING_ADDRESS,
  COLLECT_SHIPPING_ADDRESS,
  TERMS_OF_SERVICE_URL,
  CUSTOM_SUCCESS_RETURN_URL,
  CUSTOM_FAILURE_RETURN_URL,
  CUSTOM_EXPIRED_SESSION_URL,
  MINIMUM_ALLOWED,
  MAXIMUM_ALLOWED,
  SUGGESTED_AMOUNT,
  UPDATED_ON,
  RECEIPT,
  NO_RECEIPT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const paymentLinkId = get(props, 'paymentLinkId')
  const paymentLink = getPaymentLinkDataSelector(state, paymentLinkId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    merchantId,
    merchantName,
    displayTotalAmount,
    nickname,
    displayCreatedAt,
    displayLinkExpiresAt,
    linkType,
    displayAmountType,
    items,
    allowedPaymentMethods,
    collectEmail,
    collectPhoneNumber,
    collectBillingAddress,
    collectShippingAddress,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    tags,
    amountType,
    displayMinAmount,
    displayMaxAmount,
    displayUpdatedAt,
    merchantIdentityId,
    sendReceipt,
    sendReceiptToUser,
    displayReceiptRecipients,
  ] = getMany(paymentLink, [
    'merchant.id',
    'merchant.name',
    'displayTotalAmount',
    'nickname',
    'displayCreatedAt',
    'displayLinkExpiresAt',
    'linkType',
    'displayAmountType',
    'items',
    'allowedPaymentMethods',
    'collectEmail',
    'collectPhoneNumber',
    'collectBillingAddress',
    'collectShippingAddress',
    'termsOfServiceUrl',
    'successReturnUrl',
    'unsuccessfulReturnUrl',
    'expiredSessionUrl',
    'tags',
    'amountType',
    'displayMinAmount',
    'displayMaxAmount',
    'displayUpdatedAt',
    'merchant.identityId',
    'sendReceipt',
    'sendReceiptToUser',
    'displayReceiptRecipients',
  ])

  const isFixedAmount = amountType === 'FIXED'

  const amountValues = isFixedAmount ? [
    {
      label: TOTAL_AMOUNT,
      value: displayTotalAmount,
    },
  ] : [
    {
      label: MINIMUM_ALLOWED,
      value: displayMinAmount,
    },
    {
      label: MAXIMUM_ALLOWED,
      value: displayMaxAmount,
    },
  ]

  const detailsData = convertPageSectionDataToV2([
    {
      label: MERCHANT_ACCOUNT,
      value: <Link className='text-link' to={MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId })}>{merchantName}</Link>,
    },
    {
      label: LINK_TYPE,
      value: linkType,
    },
    {
      label: PAYMENT_LINK_NAME,
      value: nickname,
    },
    {
      label: AMOUNT_TYPE,
      value: displayAmountType,
    },
    ...amountValues,
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: EXPIRES_ON,
      value: displayLinkExpiresAt,
    },
    {
      label: UPDATED_ON,
      value: displayUpdatedAt,
    },
    {
      label: RECEIPT,
      value: (sendReceipt || sendReceiptToUser) ? displayReceiptRecipients : NO_RECEIPT,
    },
  ])

  const itemDetailsData = map(items, (item, index) => {
    const {
      name,
      description,
      primaryImageUrl,
      displaySaleAmount,
      quantity,
    } = item

    return {
      largeTitle: isFixedAmount ? `Item #${index + 1}` : undefined,
      data: [
        {
          label: NAME,
          value: name,
        },
        {
          label: DESCRIPTION,
          value: description,
        },
        {
          label: IMAGE_URL,
          value: <a href={primaryImageUrl} target='blank' className='text-link'>{primaryImageUrl}</a>,
          condition: !!primaryImageUrl,
        },
        {
          label: isFixedAmount ? UNIT_PRICE : SUGGESTED_AMOUNT,
          value: displaySaleAmount,
        },
        {
          label: QUANTITY,
          value: quantity,
          condition: isFixedAmount,
        },
      ],
    }
  })

  const allowedPaymentMethodsData = map(ALLOWED_PAYMENT_METHODS, (paymentMethod) => {
    const isAllowed = includes(allowedPaymentMethods, paymentMethod)
    const paymentMethodIcon = isAllowed ? CHECK_CIRCLE_ICON : 'minus-circle'
    const paymentMethodLabel = isAllowed ? 'Allowed' : 'Not Allowed'

    return {
      type: 'data',
      data: [
        {
          label: PAYMENT_METHOD_TYPE_TO_LABEL_MAP[paymentMethod],
          value: <span><i className={`fas fa-${paymentMethodIcon}`} /> {paymentMethodLabel}</span>,
        },
      ],
    }
  })

  allowedPaymentMethodsData.push({ type: 'data', data: [] })

  const advancedDetailsData = convertPageSectionDataToV2([
    {
      label: COLLECT_BUYER_EMAIL,
      value: collectEmail ? YES : NO,
    },
    {
      label: COLLECT_BUYER_PHONE_NUMBER,
      value: collectPhoneNumber ? YES : NO,
    },
    {
      label: COLLECT_BILLING_ADDRESS,
      value: collectBillingAddress ? YES : NO,
    },
    {
      label: COLLECT_SHIPPING_ADDRESS,
      value: collectShippingAddress ? YES : NO,
    },
    {
      label: TERMS_OF_SERVICE_URL,
      value: termsOfServiceUrl ? <a href={termsOfServiceUrl} target='blank'>{termsOfServiceUrl}</a> : '-',
    },
    {
      label: CUSTOM_SUCCESS_RETURN_URL,
      value: successReturnUrl ? <a href={successReturnUrl} target='blank'>{successReturnUrl}</a> : '-',
    },
    {
      label: CUSTOM_FAILURE_RETURN_URL,
      value: unsuccessfulReturnUrl ? <a href={unsuccessfulReturnUrl} target='blank'>{unsuccessfulReturnUrl}</a> : '-',
    },
    {
      label: CUSTOM_EXPIRED_SESSION_URL,
      value: expiredSessionUrl ? <a href={expiredSessionUrl} target='blank'>{expiredSessionUrl}</a> : '-',
    },
  ])

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  return {
    isFetching,
    detailsData,
    itemDetailsData,
    allowedPaymentMethodsData,
    advancedDetailsData,
    paymentLink,
    tagsSectionData,
  }
}

class PaymentLinkOverviewC extends Component {
  render() {
    return (
      <PaymentLinkOverview {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PaymentLinkOverviewC)
