import './CategorizedReportsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ReportCard from 'components/Customer/Pages/Report/ReportCard/ReportCard'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { CATEGORIZED_REPORTS_PATH } from 'constants/pathConstants'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import every from 'lodash/every'

import {
  REPORT_CATEGORY_TO_ICON_MAPPING,
  REPORT_TYPE_TO_DESCRIPTION_MAPPING,
  REPORT_TYPE_TO_LEARN_MORE_LINK_MAPPING,
  REPORTS_SORTING_OBJECT,
} from 'constants/reportingConstants'

const CategorizedReports = ({
  credentials = {},
  credentialId = '',
  categorizedReports = {},
  searchQuery = '',
  goToCategorizedReportsTable = () => {},
  isFlex = false,
  role = '',
}) => {
  return !isEmpty(categorizedReports) && (
    <div className='CategorizedReports'>
      {map(REPORTS_SORTING_OBJECT({ flex: isFlex, role, isStandaloneMerchant: isStandaloneMerchantDashboard() }), ({ category, condition: categoryCondition = true, subcategories }) => {
        const reportCategoryExists = categorizedReports[category]
        const searchQueryInputExists = !isEmpty(searchQuery)
        const allSubcategoriesHidden = every(subcategories, ({ condition }) => condition === false)
        const showFilteredReportCard = categoryCondition && !allSubcategoriesHidden && ((searchQueryInputExists && reportCategoryExists) || !searchQueryInputExists)

        return showFilteredReportCard && (
          <div className='report-category'>
            <h5 className='category-title-area flex items-center'>
              {get(REPORT_CATEGORY_TO_ICON_MAPPING, category)}
              <div className='category-title-text'>{category}</div>
            </h5>

            <div className='subcategories-area flex'>
              {map(subcategories, ({ name: subcategory, condition = true }) => {
                const reports = reportCategoryExists ? categorizedReports[category][subcategory] : {}
                const hideReportCard = !condition || (isEmpty(reports) && searchQueryInputExists)
                const redirectPath = CATEGORIZED_REPORTS_PATH({ credentialId, reportCategory: category, reportType: subcategory })

                return !hideReportCard && (
                  <ReportCard
                    credentials={credentials}
                    title={subcategory}
                    description={REPORT_TYPE_TO_DESCRIPTION_MAPPING[subcategory]}
                    learnMoreLink={REPORT_TYPE_TO_LEARN_MORE_LINK_MAPPING[subcategory]}
                    reports={reports}
                    redirectPath={redirectPath}
                    goToCategorizedReportsTable={goToCategorizedReportsTable}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

CategorizedReports.propTypes = {
  credentialId: PropTypes.string,
  categorizedReports: PropTypes.object,
  searchQuery: PropTypes.string,
  credentials: PropTypes.object,
  goToCategorizedReportsTable: PropTypes.func,
  isFlex: PropTypes.bool,
  role: PropTypes.string,
}

export default CategorizedReports
