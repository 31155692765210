import './MonitoringAlertRelatedAlertsPreviewPanelS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PanelContextBar from 'components/Shared/PanelContextBar/PanelContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  TRANSACTION_MONITORING_ALERT_DETAILS,
} from 'constants/language/languageConstants'

const MonitoringAlertRelatedAlertsPreviewPanel = ({
  panelContextBarData = [],
  headerData = {},
  secondaryHeaderData = [],
  monitoringAlertInfoCardData = [],
  monitoringAlertDetailsCardData = [],
  name,
  description,
}) => {
  return (
    <div className='MonitoringAlertRelatedAlertsPreviewPanel'>
      <PanelContextBar data={panelContextBarData} />

      <HeaderV2C {...headerData} />

      <PageSectionV2C columns={secondaryHeaderData} />

      <div className='info-highlight-panel'>
        <PageSectionV2C
          title={name}
          subTitle={description}
          columns={monitoringAlertInfoCardData}
        />
      </div>

      <PageSectionV2C
        title={TRANSACTION_MONITORING_ALERT_DETAILS}
        columns={monitoringAlertDetailsCardData}
      />
    </div>
  )
}

MonitoringAlertRelatedAlertsPreviewPanel.propTypes = {
  panelContextBarData: PropTypes.array,
  headerData: PropTypes.object,
  secondaryHeaderData: PropTypes.array,
  monitoringAlertInfoCardData: PropTypes.array,
  monitoringAlertDetailsCardData: PropTypes.array,
  name: PropTypes.string,
  description: PropTypes.string,
}

export default MonitoringAlertRelatedAlertsPreviewPanel
