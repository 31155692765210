import './ActivateDeviceModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ActivateDeviceFormC from 'components/Customer/Forms/ActivateDeviceForm/ActivateDeviceFormC'
import { TRIPOS_CLOUD_V1 } from 'constants/processorConstants'

import {
  ACTIVATE_DEVICE,
  CONFIRM_DEVICE_ACTIVATION,
} from 'constants/language/languageConstants'

const ActivateDeviceModal = ({
  deviceStatusEnabled = false,
  deviceName = '',
  gateway = '',
  deviceId = '',
  activateDeviceModalSubtitle = '',
  processor = '',
  serialNumber = '',
  title = '',
  subTitle = '',
  isAddAndActivateDeviceFlow = false,
  canCloseModal = false,
}) => {
  return (
    <GenericModal
      title={title ? title : gateway === TRIPOS_CLOUD_V1 ? ACTIVATE_DEVICE : CONFIRM_DEVICE_ACTIVATION}
      subTitle={subTitle ? subTitle : activateDeviceModalSubtitle}
      className='ActivateDeviceModal'
      Component={ActivateDeviceFormC}
      deviceName={deviceName}
      deviceStatusEnabled={deviceStatusEnabled}
      gateway={gateway}
      deviceId={deviceId}
      processor={processor}
      serialNumber={serialNumber}
      isAddAndActivateDeviceFlow={isAddAndActivateDeviceFlow}
      canCloseModal={canCloseModal}
    />
  )
}

ActivateDeviceModal.propTypes = {
  deviceStatusEnabled: PropTypes.bool,
  deviceName: PropTypes.string,
  gateway: PropTypes.string,
  deviceId: PropTypes.string,
  activateDeviceModalSubtitle: PropTypes.string,
  processor: PropTypes.string,
  serialNumber: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isAddAndActivateDeviceFlow: PropTypes.bool,
  canCloseModal: PropTypes.bool,
}

export default ActivateDeviceModal
