import { GET_BALANCE_TRANSFER_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getBalanceTransferRequest = ({
  balanceTransferId,
  credentials,
}) => createAction({
  type: GET_BALANCE_TRANSFER_F_REQUEST,
  id: balanceTransferId,
  credentials,
})

export default getBalanceTransferRequest
