import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  MERCHANTS,
  MERCHANT_SETTLEMENTS,
} from 'constants/apiConstants'

const getMerchantSettlementsAPI = ({ queries, values, credentials, meta }) => {
  const {
    merchantId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: MERCHANT_SETTLEMENTS({ merchantId }),
    queries,
    service: MERCHANTS,
  })
}

export default getMerchantSettlementsAPI
