import React, { Component } from 'react'
import { connect } from 'react-redux'
import Compliance from './Compliance'
import ComplianceFormsC from 'components/Customer/Pages/Compliance/ComplianceForms/ComplianceFormsC'
import ComplianceFormTemplatesC from 'components/Customer/Pages/Compliance/ComplianceFormTemplates/ComplianceFormTemplatesC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isFlexPlatform } from 'constants/flexConstants'

import {
  COMPLIANCE_FORM_TEMPLATES_DESCRIPTION,
  PCI_COMPLIANCE_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const subTitle = isRolePlatform({ credentials }) ? `${PCI_COMPLIANCE_SUBTITLE_DESCRIPTION} ${COMPLIANCE_FORM_TEMPLATES_DESCRIPTION}` : `${PCI_COMPLIANCE_SUBTITLE_DESCRIPTION}`

  const tabs = [
    {
      id: 'forms',
      name: 'Forms',
      component: ComplianceFormsC,
    },
    {
      id: 'form-templates',
      name: 'Form Templates',
      component: ComplianceFormTemplatesC,
      condition: isFlexPlatform() && isRolePlatform({ credentials }),
    },
  ]

  return {
    tabs,
    subTitle,
  }
}

class ComplianceC extends Component {
  render() {
    return (
      <Compliance
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ComplianceC)
