import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import PurchaseStatus from 'components/Customer/Shared/Display/ColorcodedStatus/PurchaseStatus'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import PaymentInstrumentCardColumn from 'components/Customer/Shared/Display/Columns/PaymentInstrumentCardColumn/PaymentInstrumentCardColumn'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import getUrlQuery from 'utilities/get/getUrlQuery'
import includes from 'lodash/includes'

import {
  ALL,
  FAILED,
} from 'constants/transferConstants'

import {
  AMOUNT,
  CREATED_ON,
  ID,
  PAYMENT_INSTRUMENT_NAME,
  METHOD,
  REASON_CODE,
  RECIPIENT_NAME,
  STATE,
  INSTRUMENT_TYPE,
  BRAND_SLASH_TYPE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC content={id} position='right' />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(RECIPIENT_NAME, 'recipientFullName', {
    className: 'name',
    formatter: ({ recipientFullName, recipientBusinessName }) => recipientBusinessName || recipientFullName,
  }),
  column(AMOUNT, 'displayAmount', {
    className: 'amount divider',
    formatter: ({ displayAmount }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} />,
  }),
  column(STATE, 'status', {
    className: 'normal',
    headerFormatter: () => <TableItemSubtitle title={STATE} subtitle={REASON_CODE} subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))} />,
    formatter: ({ status, displayFailureCode }) => (
      <TableItemSubtitle
        titleComponent={() => <PurchaseStatus value={status} />}
        subtitle={displayFailureCode}
        subtitleCondition={includes([ALL, FAILED], getUrlQuery('state', ALL))}
        subtitleIndent
      />
    ),
  }),
  column(METHOD, 'displayOperationKey'),
  column(PAYMENT_INSTRUMENT_NAME, 'paymentInstrument.name', { className: 'medium' }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    headerFormatter: () => <TableItemSubtitle title={INSTRUMENT_TYPE} subtitle={BRAND_SLASH_TYPE} />,
    formatter: ({ paymentInstrument }) => <PaymentInstrumentCardColumn paymentInstrument={paymentInstrument} />,
    className: 'normal',
  }),
]

export default columnDescriptors
