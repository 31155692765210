import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_ROLE } from 'constants/apiConstants'

const patchRoleAPI = ({ id, values, meta }) => {
  return dashboardAPI.patch({
    meta,
    values,
    path: DASHBOARD_SERVICE_ROLE({ roleId: id }),
  })
}

export default patchRoleAPI
