import { GET_PAYOUT_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPayoutSettingsRequest = ({
  payoutSettingsId,
  credentials,
}) => createAction({
  type: GET_PAYOUT_SETTINGS_F_REQUEST,
  id: payoutSettingsId,
  credentials,
})

export default getPayoutSettingsRequest
