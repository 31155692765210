import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL } from 'constants/apiConstants'

const postSelfServiceResendEmailAPI = ({ values }) => {
  const path = DASHBOARD_SERVICE_SELF_SERVICE_RESEND_EMAIL

  return guestDashboardAPI.post({
    path,
    values,
  })
}

export default postSelfServiceResendEmailAPI
