import './ExitApplicationFormModalS.scss'
import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import ExitApplicationFormC from './ExitApplicationFormC'
import { EXIT_APPLICATION_FORM } from 'constants/language/languageConstants'

const ExitApplicationFormModal = () => {
  return (
    <GenericModal
      title={EXIT_APPLICATION_FORM}
      className='ExitApplicationFormModal'
      Component={ExitApplicationFormC}
    />
  )
}

export default ExitApplicationFormModal
