import './CaptureAuthorizationFormS.scss'
import React from 'react'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import ReactSelectCreatable from 'components/Shared/Inputs/ReactSelectCreatable/ReactSelectCreatable'
import submitCaptureAuthorizationForm from 'utilities/submit/submitCaptureAuthorizationForm'
import validateCaptureAuthorizationForm from 'utilities/validate/validateCaptureAuthorizationForm'
import formatAmountField from 'utilities/formatters/fields/formatAmountField'
import { CAPTURE_AUTHORIZATION_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'

import {
  AMOUNT,
  AMOUNT_TO_BE_ADDED,
  AMOUNT_TO_BE_DEDUCTED,
  AMOUNT_TO_BE_CAPTURED,
  CANCEL,
  CAPTURE_AUTHORIZATION,
  CAPTURE_AUTHORIZATION_SUBTITLE,
  CARD_PRESENT_CAPTURE_LABEL,
  CAPTURE_MORE_THAN_THE_AUTH_AMOUNT,
  CAPTURE_LESS_THAN_THE_AUTH_AMOUNT,
  CAPTURE_ORIGINAL_AUTH_AMOUNT,
  FINAL_AMOUNT_TO_BE_CHARGED_TO_CARDHOLDER,
  ORIGINAL_AUTHORIZATION,
  RETURN_AMOUNT_EXAMPLE,
  TOTAL_CAPTURE_AMOUNT,
  TIP_AMOUNT_EXAMPLE,
  TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS,
  EMAIL_MULTI_INPUT_HELPER_TEXT,
  RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER,
  TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF,
} from 'constants/language/languageConstants'


const CaptureAuthorizationForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  submitting = false,
  invalid = false,
  allowCardPresentCapture = false,
  captureCardPresentOption = 'captureOriginalAmount',
  sendReceiptToBuyer = false,
}) => {
  return (
    <form className='CaptureAuthorizationForm' onSubmit={handleSubmit(submitCaptureAuthorizationForm)}>
      <div className='modal-header'>
        <div className='subtitle p-2'>{CAPTURE_AUTHORIZATION_SUBTITLE}</div>
      </div>

      { !allowCardPresentCapture && (
        <div className='modal-content'>
          <AmountField
            name='captureAmountCardNotPresent'
            label={AMOUNT}
            contextLabel={USD}
            required
          />

          <div className='receipt-details'>
            <div>
              <Field
                name='sendReceiptToBuyer'
                label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS}
                component={CheckboxC}
              />


              {sendReceiptToBuyer && (
              <Field
                name='buyerEmails'
                component={ReactSelectCreatable}
                helperText={EMAIL_MULTI_INPUT_HELPER_TEXT}
                disableDropdown
                placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
              />
              )}
            </div>

            <Field
              name='sendReceiptToSelf'
              label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF}
              component={CheckboxC}
            />
          </div>
        </div>
      )}

      { allowCardPresentCapture && (
        <div className='modal-content'>
          <Field
            name='captureCardPresentOption'
            component={RadioButton}
            label={CARD_PRESENT_CAPTURE_LABEL}
            options={[
              { value: 'captureOriginalAmount', label: CAPTURE_ORIGINAL_AUTH_AMOUNT },
              { value: 'amountToAdd', label: CAPTURE_MORE_THAN_THE_AUTH_AMOUNT },
              { value: 'lessThanCaptureAmount', label: CAPTURE_LESS_THAN_THE_AUTH_AMOUNT },
            ]}
          />

          { captureCardPresentOption === 'captureOriginalAmount' && (
            <div>
              <AmountField
                name='originalAmount'
                label={ORIGINAL_AUTHORIZATION}
                contextLabel={USD}
                disabled
              />

              <AmountField
                name='originalAmount'
                label={TOTAL_CAPTURE_AMOUNT}
                contextLabel={USD}
                disabled
                helperText={AMOUNT_TO_BE_CAPTURED}
              />
            </div>
          )}

          { captureCardPresentOption === 'amountToAdd' && (
            <div>
              <AmountField
                name='originalAmount'
                label={ORIGINAL_AUTHORIZATION}
                contextLabel={USD}
                disabled
              />

              <AmountField
                name='addToAuthAmount'
                label={AMOUNT_TO_BE_ADDED}
                contextLabel={USD}
                helperText={TIP_AMOUNT_EXAMPLE}
                normalize={formatAmountField}
              />

              <AmountField
                name='totalCaptureAmount'
                label={TOTAL_CAPTURE_AMOUNT}
                contextLabel={USD}
                helperText={FINAL_AMOUNT_TO_BE_CHARGED_TO_CARDHOLDER}
                normalize={formatAmountField}
              />
            </div>
          )}

          { captureCardPresentOption === 'lessThanCaptureAmount' && (
            <div>
              <AmountField
                name='originalAmount'
                label={ORIGINAL_AUTHORIZATION}
                contextLabel={USD}
                disabled
              />

              <AmountField
                name='subtractFromAuthAmount'
                label={AMOUNT_TO_BE_DEDUCTED}
                contextLabel={USD}
                helperText={RETURN_AMOUNT_EXAMPLE}
                normalize={formatAmountField}
              />

              <AmountField
                name='totalSubtractedCaptureAmount'
                label={TOTAL_CAPTURE_AMOUNT}
                contextLabel={USD}
                helperText={FINAL_AMOUNT_TO_BE_CHARGED_TO_CARDHOLDER}
                normalize={formatAmountField}
              />
            </div>
          )}

          <div className='receipt-details'>
            <div>
              <Field
                name='sendReceiptToBuyer'
                label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_OTHERS}
                component={CheckboxC}
              />


              {sendReceiptToBuyer && (
              <Field
                name='buyerEmails'
                component={ReactSelectCreatable}
                helperText={EMAIL_MULTI_INPUT_HELPER_TEXT}
                disableDropdown
                placeholder={RECIPIENT_EMAIL_ADDRESS_PLACEHOLDER}
              />
              )}
            </div>

            <Field
              name='sendReceiptToSelf'
              label={TRANSACTION_REVIEW_EMAIL_RECEIPT_TO_SELF}
              component={CheckboxC}
            />
          </div>
        </div>
      )}

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={CAPTURE_AUTHORIZATION} submit={submitting} disabled={invalid} />
      </div>
    </form>
  )
}

CaptureAuthorizationForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  closeModal: PropTypes.func,
  allowCardPresentCapture: PropTypes.bool,
  captureCardPresentOption: PropTypes.string,
  sendReceiptToBuyer: PropTypes.bool,
}

export default reduxForm({
  form: CAPTURE_AUTHORIZATION_FORM,
  validate: validateCaptureAuthorizationForm,
})(CaptureAuthorizationForm)
