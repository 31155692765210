import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMerchantSelector } from 'state-layer/selectors'
import AddNewDevice from './AddNewDevice'
import AddDeviceFormC from 'components/Customer/Forms/AddDeviceForm/AddDeviceFormC'
import AddDeviceReviewFormC from 'components/Customer/Forms/AddDeviceReviewForm/AddDeviceReviewFormC'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import prevFlowStep from 'utilities/prevFlowStep'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import { DEVICE_CONFIGURATIONS_SELECT_OPTIONS } from 'constants/deviceConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'

import {
  ADD_NEW_DEVICE_FORM,
  ADD_NEW_DEVICE_REVIEW_FORM,
} from 'constants/formConstants'

import {
  ADD_A_DEVICE,
  REVIEW_DEVICE_DETAILS,
} from 'constants/language/languageConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
  }
}

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const reduxFormValues = (formName) => get(state, `form.${formName}.values`)
  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantName = get(merchant, 'name')
  const merchantGateway = get(merchant, 'gateway', get(merchant, 'processor'))
  const deviceConfigurationSelectNames = map(filter(DEVICE_CONFIGURATIONS_SELECT_OPTIONS, ({ visibleToCustomers }) => visibleToCustomers), ({ name, disabledForGateways }) => ({ name, disabledForGateways }))

  const steps = [
    {
      name: ADD_A_DEVICE,
      component: AddDeviceFormC,
      dataKey: 'addDevice',
      formValues: () => reduxFormValues(ADD_NEW_DEVICE_FORM),
    },
    {
      name: REVIEW_DEVICE_DETAILS,
      component: AddDeviceReviewFormC,
      props: {
        credentials,
        merchantName,
        deviceConfigurationSelectNames,
        merchantId,
        merchantGateway,
        goToPreviousStep: prevFlowStep,
      },
      dataKey: 'addDevice',
      formValues: () => reduxFormValues(ADD_NEW_DEVICE_REVIEW_FORM),
    },
  ]

  return {
    credentials,
    merchantId,
    steps,
  }
}

class AddNewDeviceC extends Component {
  componentDidMount() {
    const {
      merchantId,
      credentials,
      getMerchant,

    } = this.props

    if (merchantId) {
      getMerchant({ merchantId, credentials })
    }
  }

  render() {
    return (
      <AddNewDevice
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDeviceC)
