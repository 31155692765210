import removeUndefined from 'utilities/remove/removeUndefined'

const backendUnderwritingReviewModel = ({ values }) => {
  const {
    id,
    reasons,
    state,
    subjectId,
    workflow,
    assignee,
    businessName,
    customerType,
    submittedBy,
    submittedOn,
    updatedOn,
    entityId,
    entityType,
    report,
  } = values

  return removeUndefined({
    id,
    reasons,
    state,
    subject_id: subjectId,
    workflow,
    assignee,
    business_name: businessName,
    customer_model: customerType,
    submitted_by: submittedBy,
    submitted_at: submittedOn,
    updated_at: updatedOn,
    entity_id: entityId,
    entity_type: entityType,
    report,
  })
}

export default backendUnderwritingReviewModel
