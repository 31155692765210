import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_USER_V2 } from 'constants/apiConstants'

const patchDashboardUserAPI = ({
  id,
  values,
  meta,
}) => {
  const dashboardId = getCurrentDashboardId()

  return dashboardAPI.patch({
    path: DASHBOARD_SERVICE_USER_V2({ userId: id, dashboardId }),
    values,
    meta,
  })
}

export default patchDashboardUserAPI
