import HeaderV2 from './HeaderV2'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { LEARN_MORE_LABEL } from 'constants/language/languageConstants'
import get from 'lodash/get'
import isString from 'lodash/isString'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isRoleMerchantCredential = isRoleMerchant({ credentials })
  const learnMoreLink = get(props, 'learnMoreLink')
  const subTitle = get(props, 'subTitle')

  const learnMoreLinkDisplay = learnMoreLink && !isRoleMerchantCredential ? <a href={learnMoreLink} target='blank'>{LEARN_MORE_LABEL}</a> : undefined
  const subTitleDisplay = <div className='subtitle-description p-1'>{subTitle}{learnMoreLinkDisplay}</div>
  const formattedSubTitleDisplay = isString(subTitle) ? <div className='sub-title'>{subTitleDisplay}</div> : subTitleDisplay

  return {
    isRoleMerchantCredential,
    learnMoreLinkDisplay,
    formattedSubTitleDisplay,
  }
}

class HeaderV2C extends Component {
  render() {
    return (
      <HeaderV2 {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(HeaderV2C)
