import frontendDisputeModel from 'utilities/create/models/frontendDisputeModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendDisputesModel = ({ data: disputes }) => {
  const disputeModels = map(disputes, (dispute) => frontendDisputeModel({ data: dispute }))

  return keyBy(disputeModels, 'id')
}

export default frontendDisputesModel
