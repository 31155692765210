import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { SHOW_LANDING_PAGE } from 'constants/featureFlagConstants'
import isEqual from 'lodash/isEqual'
import split from 'lodash/split'
import includes from 'lodash/includes'
import isFunction from 'lodash/isFunction'
import last from 'lodash/last'
import get from 'lodash/get'

import {
  OVERVIEW_ICON,
  PAYMENTS_ICON,
  PAYOUTS_ICON,
  AUTHORIZATIONS_ICON,
  SETTLEMENTS_ICON,
  BALANCE_TRANSFERS_ICON,
  EXCEPTIONS_ICON,
  REPORTS_ICON,
  MERCHANTS_ICON,
  APPLICATIONS_ICON,
  USERS_ICON,
  CONFIGURATIONS_ICON,
  EMAILS_ICON,
  UPDATE_TRANSFERS_ICON,
  UPDATE_VERIFICATIONS_ICON,
  PLATFORMS_ICON,
  IDENTITY_UPLOADER_ICON,
  PAYOUT_PLANS_ICON,
  SUBSCRIPTION_BILLING_ICON,
  UNDERWRITING_ICON,
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

export const items = {
  transfers: {
    id: 'transfers',
    display: 'Transfers',
    icon: PAYMENTS_ICON,
  },
  authorizations: {
    id: 'authorizations',
    display: 'Authorizations',
    icon: AUTHORIZATIONS_ICON,
  },
  disputes: {
    id: 'disputes',
    display: 'Disputes',
    icon: EXCEPTIONS_ICON,
  },
  settlements: {
    id: 'settlements',
    display: 'Settlements',
    icon: SETTLEMENTS_ICON,
  },
  reports: {
    id: 'reports',
    display: 'Reports',
    icon: REPORTS_ICON,
  },
  identities: {
    id: 'identities',
    display: 'Identities',
    icon: MERCHANTS_ICON,
  },
  merchants: {
    id: 'merchants',
    display: 'Merchants',
    icon: MERCHANTS_ICON,
  },
  applications: {
    id: 'applications',
    display: 'Applications',
    icon: APPLICATIONS_ICON,
  },
  payment_instruments: {
    id: 'payment_instruments',
    display: 'Payment Instruments',
    icon: PAYMENTS_ICON,
  },
  review_settlements: {
    id: 'review_settlements',
    display: 'Review Settlements',
    icon: SETTLEMENTS_ICON,
  },
  review_merchants: {
    id: 'review_merchants',
    display: 'Review Merchants',
    icon: MERCHANTS_ICON,
  },
  review_identities: {
    id: 'review_identities',
    display: 'Review Identities',
    icon: MERCHANTS_ICON,
  },
  subcription_billing: {
    id: 'subcription_billing',
    display: 'Subscription Billing',
    icon: SUBSCRIPTION_BILLING_ICON,
  }
}

export const sections = {
  transactions: {
    id: 'transactions',
    display: 'TRANSACTIONS',
    itemIds: [
      'transfers',
      'authorizations',
      'disputes',
      'settlements',
      'reports',
    ]
  },
  people: {
    id: 'people',
    display: 'PEOPLE',
    itemIds: [
      'identities',
      'applications',
      'merchants',
      'payment_instruments',
    ]
  },
  review: {
    id:  'review',
    display: 'REVIEW',
    itemIds: [
      'review_settlements',
      'review_merchants',
      'review_identities',
    ],
  },
  system: {
    id: 'system',
    display: 'SYSTEM',
    itemIds: [
      'subcription_billing',
    ],
  },
  hidden: {
    id: 'hidden',
    display: 'Hidden',
    itemIds: [],
  },
}

export const sectionsOrder = [
  'transactions',
  'people',
  'review',
  'system',
]

export const isAllowedCheck = ({allowedRoles, adminCheck, currentRole }) => {
  const roleAccess = allowedRoles ? includes(allowedRoles, currentRole) : true
  const adminAccess = adminCheck(currentRole)

  return roleAccess && adminAccess
}

export const normalizeRoute = ({route, credentialId }) => isFunction(route) ? route({ credentialId }) :route

export const isActiveLinkStatus = ({ route, pathname, key }) => {
  let isActiveLink = isEqual(pathname,  route)

  // if isActiveLink is false, check to see if we are on a child page of one of our sidebar links
  if (!isActiveLink) {
    const pathDetails = split(pathname, '/')
    const childPagePath = pathDetails.splice(0, 3).concat(pathDetails.splice(-2, 1)).join('/')

    if (includes(pathname, route)) {
      isActiveLink = true
    }

    // special handler for situations where the route is found in the pathname due to restful reasons, but should not be highlighted
    // ex: /identities/{identityId}/merchants/{merchantId} contains /identities, but we really only want merchants to be highlighted
    // sadly, this may need to be hard coded for situations like this, but as of now only merchants view page follows this standard
    // it's possible in the future we would just ideally move the route to get to a merchant to just /merchants/{merchantId} without the identity prepended in the URL
    if (includes(pathname, '/merchants')) {
      // if the pathname has /merchants, we only want to highlight the merchant-accounts item
      isActiveLink = key === 'merchant-accounts'
    }

    // TODO: keeping this as a backup in case we ever need it back
    // isActiveLink = includes(route, childPagePath) || includes(childPagePath, route) || isEqual(key, last(split(childPagePath, '/'))) || isEqual(key, get(pathDetails, '[0]'))

    setTimeout(() => {
      if (!getFeatureFlag(SHOW_LANDING_PAGE) && childPagePath?.split('/')[3] === 'home' && (route?.split('/')[3] === 'payments' || route?.split('/')[3] === 'payouts')) {
        isActiveLink = true
      }
    }, 500)
  }

  return isActiveLink
}
