import './PaymentInstrumentInfoS.scss'
import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import isEmpty from 'lodash/isEmpty'

import {
  ID,
  CREATED_ON,
  EVENT_NAME,
  USER,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(USER, 'userId', {
    className: 'large',
    formatter: ({ userId, userEmail }) => {
      return (
        <div className='detail-column'>
          {isEmpty(userEmail) ? (
            <span>{'-'}{' '}</span>
          ) : (
            <span>{userEmail}{' '}</span>
          )}
          <div className='click-to-copy-container'>
            <ClickToCopyC position='right' content={userId} />
          </div>
        </div>
      )
    },
  }),
  column(EVENT_NAME, 'displayInstrumentCreatedType'),
]

export default columnDescriptors
