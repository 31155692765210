import React, { Component } from 'react'
import { connect } from 'react-redux'
import MonitoringAlertNotes from 'components/Customer/Pages/MonitoringAlert/MonitoringAlertNotes/MonitoringAlertNotes'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const monitoringAlert = get(props, 'monitoringAlert')
  const monitoringAlertId = get(monitoringAlert, 'id')

  return {
    monitoringAlertId,
  }
}

class MonitoringAlertNotesC extends Component {
  render() {
    return (
      <MonitoringAlertNotes
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(MonitoringAlertNotesC)
