import Highlight from './Highlight'
import React from 'react'
import { connect } from 'react-redux'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import removeUndefined from 'utilities/remove/removeUndefined'

import {
  SHOW_HIGHLIGHT,
  HIDE_HIGHLIGHT,
  PREV_HIGHLIGHT,
  NEXT_HIGHLIGHT,
} from 'constants/flowConstants'

const clipRectShape = ({
  left = 0,
  top = 0,
  width = 0,
  height = 0,
  padding = 0,
}) => {
  const right = left + width
  const bottom = top + height

  const rightPad = right + padding
  const leftPad = left - padding
  const topPad = top - padding
  const bottomPad = bottom + padding

  return `0% 0%, 0% 100%, ${leftPad}px 100%, ${leftPad}px ${topPad}px, ${rightPad}px ${topPad}px, ${rightPad}px ${bottomPad}px, ${leftPad}px ${bottomPad}px, ${leftPad}px 100%, 100% 100%, 100% 0%`
}

const mapStateToProps = (state, props) => {
  const formattedPath = get(props, 'formattedPath')
  const highlights = get(state, 'highlightR.highlights', [])
  const stateIndex = get(state, 'highlightR.index', 0)
  const localStoreIndex = Number(localStorage.getItem(formattedPath))
  const index = localStoreIndex || stateIndex || 0

  const [
    selector,
    description,
    padding = 0,
    topOverride,
    leftOverride,
  ] = getMany(highlights[index], [
    'selector',
    'description',
    'padding',
    'top',
    'left',
  ])

  const ele = selector ? document.querySelector(selector) : null
  const bounds = ele && ele.getBoundingClientRect && ele.getBoundingClientRect()

  const [
    left = 0,
    top = 0,
    width = 0,
    height = 0,
  ] = getMany(bounds, [
    'left',
    'top',
    'width',
    'height',
  ])

  const shape = clipRectShape({ left, top, width, height, padding })

  const descriptionTop = topOverride ? topOverride : `${top + height}px`
  const descriptionLeft = leftOverride ? leftOverride : undefined
  const isFirst = index === 0
  const isLast = index === highlights.length - 1

  const style = {
    display: ele ? 'block' : 'none',
    clipPath: `polygon(${shape})`,
  }

  const descriptionStyle = removeUndefined({
    marginTop: descriptionTop,
    marginLeft: descriptionLeft,
  })

  return {
    style,
    descriptionStyle,
    selector,
    description,
    isFirst,
    isLast,
    formattedPath,
    index,
    highlights,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    prevHighlight: () => dispatch({ type: PREV_HIGHLIGHT }),
    nextHighlight: () => dispatch({ type: NEXT_HIGHLIGHT }),
    hideHighlight: () => dispatch({ type: HIDE_HIGHLIGHT }),
    showHighlight: ({ index, highlights }) => dispatch({ type: SHOW_HIGHLIGHT, payload: { index, highlights } }),
  }
}

class HighlightC extends React.Component {
  closeHighlights = () => {
    const {
      index = 0,
      highlights = [],
      formattedPath,
      hideHighlight,
    } = this.props

    if (index === highlights.length - 1) {
      localStorage.setItem(formattedPath, '0')
    }

    hideHighlight()
  }

  showPrevHighlight = () => {
    const {
      index,
      formattedPath,
      prevHighlight,
    } = this.props

    localStorage.setItem(formattedPath, index - 1)

    prevHighlight()
  }

  showNextHighlight = () => {
    const {
      index,
      isLast,
      formattedPath,
      nextHighlight,
    } = this.props

    if (isLast) {
      this.closeHighlights()
    } else {
      localStorage.setItem(formattedPath, index + 1)

      nextHighlight()
    }
  }

  goToHighlight = (index) => {
    const {
      formattedPath,
      highlights,
      showHighlight,
    } = this.props

    localStorage.setItem(formattedPath, index)

    showHighlight({ index, highlights })
  }

  render() {
    return (
      <Highlight
        {...this.props}
        closeHighlights={this.closeHighlights}
        showPrevHighlight={this.showPrevHighlight}
        showNextHighlight={this.showNextHighlight}
        goToHighlight={this.goToHighlight}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightC)
