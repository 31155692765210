import React, { Component } from 'react'
import { connect } from 'react-redux'
import InstantBankAccountVerification from './InstantBankAccountVerification'
import { getIdentitySelector } from 'state-layer/selectors'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import postPaymentInstrumentRequest from 'utilities/actions/post/postPaymentInstrumentRequest'
import { isDisbursementsPartnerDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import { ADD_IDENTITY_BANK_ACCOUNT } from 'constants/modalConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `paymentInstrumentsR.${FETCHING}`)
  const identityId = get(props, 'identityId')
  const identity = getIdentitySelector(state, identityId)
  const country = get(identity, 'country', 'USA')
  const isDisbursementsDashboard = isDisbursementsPartnerDashboard(state)

  return {
    credentials,
    isFetching,
    country,
    isDisbursementsDashboard,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    showAddBankModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_IDENTITY_BANK_ACCOUNT, modalProps })),
    createBankAccount: ({ values, credentials, isDisbursementsDashboard }) => dispatch(postPaymentInstrumentRequest({ values, credentials, isDisbursementsDashboard })),
  }
}

// a modal to manually enter a bank account or link an account via PLAID
class InstantBankAccountVerificationC extends Component {
  addManualBankAccount = () => {
    const {
      showAddBankModal,
      closeModal,
      identityId,
      elavonEnabled,
      settlementAliasWarningObject,
      successFunction,
      merchantId,
      warningMessage,
    } = this.props

    closeModal()

    showAddBankModal({
      identityId,
      elavonEnabled,
      settlementAliasWarningObject,
      successFunction,
      merchantId,
      warningMessage,
    })
  }

  plaidSuccessFunc = (token) => {
    const {
      credentials,
      identityId,
      createBankAccount,
      closeModal,
      isDisbursementsDashboard,
    } = this.props

    closeModal()

    createBankAccount({
      values: {
        thirdParty: 'PLAID',
        thirdPartyToken: token,
        identity: identityId,
        type: 'BANK_ACCOUNT',
      },
      credentials,
      isDisbursementsDashboard,
    })
  }

  render() {
    return (
      <InstantBankAccountVerification
        {...this.props}
        addManualBankAccount={this.addManualBankAccount}
        plaidSuccessFunc={this.plaidSuccessFunc}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantBankAccountVerificationC)
