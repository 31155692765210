import './IdentityDisputesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getDisputeMerchantsBuyersSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import { DISPUTES } from 'constants/linkConstants'
import { GET_DISPUTES } from 'constants/flowConstants'
import { EXCEPTIONS_ICON } from 'constants/iconConstants'

import {
  IDENTITY_DISPUTES_RESOURCE_TITLE,
  IDENTITY_DISPUTES_EMPTY_TABLE_MESSAGE,
} from 'constants/language/languageConstants'

const IdentityDisputes = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  disputePath = '',
  isFetching = false,
  identityId = '',
  columnDescriptors = [],
  initialFilters = {},
  quickFilters = [],
}) => {
  return (
    <div className='IdentityDisputes'>
      <TableC
        flow={GET_DISPUTES}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getDisputeMerchantsBuyersSelector}
        initialQueries={initialQueries}
        linksKey={DISPUTES}
        allowedFilters={allowedFilters}
        path={disputePath}
        isFetching={isFetching}
        icon={`fa fa-${EXCEPTIONS_ICON}`}
        fileNameCSV={`Identity_${identityId}_Disputes`}
        fileResourceTitleCSV={IDENTITY_DISPUTES_RESOURCE_TITLE}
        emptyMessage={IDENTITY_DISPUTES_EMPTY_TABLE_MESSAGE}
        initialFilters={initialFilters}
        quickFilters={quickFilters}
      />
    </div>
  )
}

IdentityDisputes.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  disputePath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  columnDescriptors: PropTypes.array,
  initialFilters: PropTypes.object,
  quickFilters: PropTypes.array,
}

export default IdentityDisputes
