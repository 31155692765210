import './ReviewQueueSettlementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ReviewQueueSettlementsPreviewPanelC from 'components/Customer/Pages/ReviewQueue/ReviewQueueSettlements/ReviewQueueSettlementsPreviewPanel/ReviewQueueSettlementsPreviewPanelC'
import columnDescriptors from './columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_REVIEW_QUEUE, REVIEW_QUEUE as REVIEW_QUEUE_ENTITY_TYPE } from 'constants/flowConstants'
import { REVIEW_QUEUE } from 'constants/linkConstants'
import { SETTLEMENTS_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  REVIEW_QUEUE_SETTLEMENTS_RESOURCE_TITLE,
  REVIEW_SETTLEMENTS_RESOURCE_TITLE,
  REVIEW_SETTLEMENTS_SUB_TITLE,
} from 'constants/language/languageConstants'

const ReviewQueueSettlements = ({
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  selectedItemsKey,
  reviewSettlementPath = '',
  selectedItemsData = {},
  tableActions = [],
  quickFilters = [],
  selector = () => {},
}) => {
  return (
    <div className='ReviewQueueSettlements'>
      <HeaderV2C
        title={REVIEW_SETTLEMENTS_RESOURCE_TITLE}
        subTitle={REVIEW_SETTLEMENTS_SUB_TITLE}
        learnMoreLink={SETTLEMENTS_LEARN_MORE_LINK}
      />

      <TableC
        flow={GET_REVIEW_QUEUE}
        columnDescriptors={columnDescriptors}
        selector={selector}
        linksKey={REVIEW_QUEUE}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        allowedFilters={allowedFilters}
        isFetching={isFetching}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        path={reviewSettlementPath}
        fileNameCSV='ReviewQueue_Settlements'
        fileResourceTitleCSV={REVIEW_QUEUE_SETTLEMENTS_RESOURCE_TITLE}
        overwriteReducer={false}
        entityType={REVIEW_QUEUE_ENTITY_TYPE}
        showAdvancedExport
        actions={tableActions}
        quickFilters={quickFilters}
        previewPanel={ReviewQueueSettlementsPreviewPanelC}
      />
    </div>
  )
}

ReviewQueueSettlements.propTypes = {
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  selectedItemsKey: PropTypes.string,
  reviewSettlementPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  selectedItemsData: PropTypes.object,
  tableActions: PropTypes.array,
  quickFilters: PropTypes.array,
  selector: PropTypes.func,
}

export default ReviewQueueSettlements
