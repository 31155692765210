import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'

const backendApplicationProcessorConfigModel = ({ values }) => {
  const {
    applicationConfig,
  } = values

  const [
    visaObject,
    mastercardObject,
  ] = getMany(applicationConfig, [
    'visaObject',
    'mastercardObject',
  ])

  const [
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    visaDefaultSenderName,
  ] = getMany(visaObject, [
    'visaAllowedBusinessApplicationIds',
    'visaCardAcceptorIdCode',
    'visaAcceptorTerminalId',
    'visaDefaultCurrencies',
    'visaDefaultSenderAddress',
    'visaDefaultSenderCity',
    'visaDefaultSenderStateCode',
    'visaDefaultSenderZipCode',
    'visaDefaultSenderCountryCode',
    'visaDefaultSenderCountry',
    'visaDefaultSenderName',
  ])

  const [
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultMcc,
    mcDefaultSenderAccountNumber,
    mcStatementDescriptor,
    mcDefaultSenderFullName,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderCountry,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountryCode,
    mcSubdivision,
  ] = getMany(mastercardObject, [
    'mcCardAcceptorId',
    'mcDefaultPaymentType',
    'mcDefaultCurrencies',
    'mcDefaultMcc',
    'mcDefaultSenderAccountNumber',
    'mcStatementDescriptor',
    'mcDefaultSenderFullName',
    'mcDefaultSenderFirstName',
    'mcDefaultSenderLastName',
    'mcDefaultSenderAddressLine1',
    'mcDefaultSenderAddressLine2',
    'mcDefaultSenderCity',
    'mcDefaultSenderCountry',
    'mcDefaultSenderPostalCode',
    'mcDefaultSenderCountryCode',
    'mcSubdivision',
  ])

  const allowedBusinessAppIdsVisaBackEndValue = map(visaAllowedBusinessApplicationIds, (id) => { return get(id, 'value') })
  const defaultVisaCurrenciesBackEndValue = map(visaDefaultCurrencies, (currency) => { return get(currency, 'value') })
  const defaultMastercardCurrenciesBackEndValue = map(mcDefaultCurrencies, (currency) => { return get(currency, 'value') })

  return removeUndefined({
    application_config: {
      visa_application_config: isEmpty(visaObject) ? undefined : {
        allowed_business_application_ids: allowedBusinessAppIdsVisaBackEndValue,
        card_acceptor_id_code: visaCardAcceptorIdCode,
        card_acceptor_terminal_id: visaAcceptorTerminalId,
        default_currencies: defaultVisaCurrenciesBackEndValue,
        default_sender_address: visaDefaultSenderAddress,
        default_sender_city: visaDefaultSenderCity,
        default_sender_state_code: visaDefaultSenderStateCode,
        default_sender_zip_code: visaDefaultSenderZipCode,
        default_sender_country: visaDefaultSenderCountry,
        default_sender_country_code: visaDefaultSenderCountryCode,
        default_sender_name: visaDefaultSenderName,
        configuration_templates: {
          country: {
            USA: {
              default_sender_address: visaDefaultSenderAddress,
              default_sender_city: visaDefaultSenderCity,
              default_sender_state_code: visaDefaultSenderStateCode,
              default_sender_zip_code: visaDefaultSenderZipCode,
              default_sender_country_code: visaDefaultSenderCountryCode,
            },
          },
        },
      },
      mastercard_application_config: isEmpty(mastercardObject) ? undefined : {
        card_acceptor_id: mcCardAcceptorId,
        default_payment_type: mcDefaultPaymentType,
        default_currencies: defaultMastercardCurrenciesBackEndValue,
        default_mcc: mcDefaultMcc,
        default_sender_account_number: mcDefaultSenderAccountNumber,
        statement_descriptor: mcStatementDescriptor,
        default_sender_full_name: mcDefaultSenderFullName,
        default_sender_first_name: mcDefaultSenderFirstName,
        default_sender_last_name: mcDefaultSenderLastName,
        default_sender_address_line1: mcDefaultSenderAddressLine1,
        default_sender_address_line2: mcDefaultSenderAddressLine2,
        default_sender_city: mcDefaultSenderCity,
        default_sender_country: mcDefaultSenderCountry,
        default_sender_postal_code: mcDefaultSenderPostalCode,
        default_sender_country_code: mcDefaultSenderCountryCode,
        configuration_templates: {
          country: {
            USA: {
              default_sender_city: mcDefaultSenderCity,
              default_sender_country: mcDefaultSenderCountry,
              default_sender_postal_code: mcDefaultSenderPostalCode,
              default_sender_address_line1: mcDefaultSenderAddressLine1,
              default_sender_address_line2: mcDefaultSenderAddressLine2,
              default_sender_country_code: mcDefaultSenderCountryCode,
              default_sender_country_subdivision: mcSubdivision,
            },
          },
        },
      },
    },
  })
}

export default backendApplicationProcessorConfigModel
