import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PayoutLinkOverview from './PayoutLinkOverview'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMerchantRequest from 'utilities/actions/get/getMerchantRequest'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { RECIPIENT_PATH } from 'constants/pathConstants'
import includes from 'lodash/includes'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  SUCCEEDED,
  FAILED,
} from 'constants/statusConstants'

import {
  ALLOWED,
  AMOUNT,
  BANK_ACCOUNT,
  CARD,
  COLLECT_RECIPIENT_EMAIL,
  COLLECT_RECIPIENT_PHONE_NUMBER,
  CUSTOM,
  CUSTOM_EXPIRED_SESSION_URL,
  CUSTOM_FAILURE_RETURN_URL,
  CUSTOM_SUCCESS_RETURN_URL,
  DESCRIPTION,
  FORTY_EIGHT_HOURS,
  IMAGE_URL,
  ITEM,
  LINK_VALIDITY,
  NAME,
  NO,
  NOT_ALLOWED,
  ONE_WEEK,
  PAYOUT_LINK_NAME,
  QUANTITY,
  RECIPIENT_IDENTITY,
  SIXTY_DAYS,
  TERMS_OF_SERVICE_URL,
  TOTAL_AMOUNT,
  TWENTY_FOUR_HOURS,
  YES,
} from 'constants/language/languageConstants'

import {
  getMerchantSelector,
  getPayoutLinkSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const isFetchingPayoutLinks = get(state, `payoutLinksR.${FETCHING}`)
  const isFetchingTransferAttempts = get(state, `transferAttemptsR.${FETCHING}`)
  const isFetching = isFetchingPayoutLinks || isFetchingTransferAttempts
  const payoutLinkId = get(props, 'payoutLinkId')
  const payoutLink = get(props, 'payoutLink')
  const succeededTransferAttempt = get(props, 'succeededTransferAttempt')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    transferAttemptRecipientFullName,
    transferAttemptRecipientBusinessName,
  ] = getMany(succeededTransferAttempt, [
    'recipientFullName',
    'recipientBusinessName',
  ])

  const [
    recipientFullName,
    recipientBusinessName,
    recipientMerchantId,
    nickname,
    displayTotalAmount,
    items,
    allowedPayoutOperations,
    tags,
    termsOfServiceUrl,
    expiredSessionUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    displayExpirationMinutesInDays,
  ] = getMany(payoutLink, [
    'recipientFullName',
    'recipientBusinessName',
    'recipientMerchantId',
    'nickname',
    'displayTotalAmount',
    'items',
    'allowedPayoutOperations',
    'tags',
    'termsOfServiceUrl',
    'expiredSessionUrl',
    'successReturnUrl',
    'unsuccessfulReturnUrl',
    'displayExpirationMinutesInDays',
  ])

  // we get the recipient name from the payout link if it was pre-selected, otherwise we get it from the transfer attempt
  const recipientNameValue = recipientBusinessName || recipientFullName || transferAttemptRecipientBusinessName || transferAttemptRecipientFullName
  const recipientMerchant = getMerchantSelector(state, recipientMerchantId)
  const identityId = get(recipientMerchant, 'identityId')
  const pushToACHAllowed = includes(allowedPayoutOperations, 'PUSH_TO_ACH')
  const pushToCardAllowed = includes(allowedPayoutOperations, 'PUSH_TO_CARD')
  const displayPushToACHAllowed = pushToACHAllowed ? ALLOWED : NOT_ALLOWED
  const displayPushToCardAllowed = pushToCardAllowed ? ALLOWED : NOT_ALLOWED
  const collectEmail = get(tags, 'collectEmail')
  const collectPhoneNumber = get(tags, 'collectPhoneNumber')

  const payoutLinkDetailsData = convertPageSectionDataToV2([
    {
      label: RECIPIENT_IDENTITY,
      value: recipientNameValue ? <Link className='text-link' to={RECIPIENT_PATH({ credentialId, recipientId: identityId })}>{recipientNameValue}</Link> : '-',
    },
    {
      label: PAYOUT_LINK_NAME,
      value: nickname,
    },
    {
      label: TOTAL_AMOUNT,
      value: displayTotalAmount,
    },
  ], 3)

  const payoutLinkItemsData = map(items, (item, index) => {
    const {
      name,
      description,
      displayAmount,
      primaryImageUrl,
      quantity,
    } = item

    return {
      largeTitle: `${ITEM} #${index + 1}`,
      data: [
        {
          label: NAME,
          value: name,
        },
        {
          label: DESCRIPTION,
          value: description,
        },
        {
          label: IMAGE_URL,
          value: primaryImageUrl ? <a className='text-link' target='_blank' href={primaryImageUrl} rel='noopener noreferrer'>{primaryImageUrl}</a> : '-',
        },
        {
          label: AMOUNT,
          value: displayAmount,
        },
        {
          label: QUANTITY,
          value: quantity,
        },
      ],
    }
  })

  const allowedPayoutOperationsMap = {
    true: SUCCEEDED,
    false: FAILED,
  }

  const allowedPayoutOperationsData = convertPageSectionDataToV2([
    {
      label: CARD,
      value: <ColorcodedStatus data={allowedPayoutOperationsMap} value={pushToCardAllowed} customValue={displayPushToCardAllowed} falsyValue />,
    },
    {
      label: BANK_ACCOUNT,
      value: <ColorcodedStatus data={allowedPayoutOperationsMap} value={pushToACHAllowed} customValue={displayPushToACHAllowed} falsyValue />,
    },
  ], 3)

  const advancedSettingsData = convertPageSectionDataToV2([
    {
      label: LINK_VALIDITY,
      value: displayExpirationMinutesInDays,
    },
    {
      label: COLLECT_RECIPIENT_EMAIL,
      value: collectEmail === 'true' ? YES : NO,
    },
    {
      label: COLLECT_RECIPIENT_PHONE_NUMBER,
      value: collectPhoneNumber === 'true' ? YES : NO,
    },
    {
      label: TERMS_OF_SERVICE_URL,
      value: termsOfServiceUrl ? <a className='text-link' target='_blank' href={termsOfServiceUrl} rel='noopener noreferrer'>{termsOfServiceUrl}</a> : '-',
    },
    {
      label: CUSTOM_SUCCESS_RETURN_URL,
      value: successReturnUrl ? <a className='text-link' target='_blank' href={successReturnUrl} rel='noopener noreferrer'>{successReturnUrl}</a> : '-',
    },
    {
      label: CUSTOM_FAILURE_RETURN_URL,
      value: unsuccessfulReturnUrl ? <a className='text-link' target='_blank' href={unsuccessfulReturnUrl} rel='noopener noreferrer'>{unsuccessfulReturnUrl}</a> : '-',
    },
    {
      label: CUSTOM_EXPIRED_SESSION_URL,
      value: expiredSessionUrl ? <a className='text-link' target='_blank' href={expiredSessionUrl} rel='noopener noreferrer'>{expiredSessionUrl}</a> : '-',
    },
  ], 3)

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  return {
    isFetching,
    payoutLink,
    payoutLinkDetailsData,
    payoutLinkItemsData,
    recipientMerchantId,
    credentials,
    allowedPayoutOperationsData,
    advancedSettingsData,
    tagsSectionData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: ({ merchantId, credentials }) => dispatch(getMerchantRequest({ merchantId, credentials })),
  }
}

class PayoutLinkOverviewC extends Component {
  componentDidUpdate(prevProps) {
    const {
      recipientMerchantId,
      getMerchant,
      credentials,
    } = this.props

    if (recipientMerchantId && recipientMerchantId !== prevProps.recipientMerchantId) {
      getMerchant({ merchantId: recipientMerchantId, credentials })
    }
  }

  render() {
    return (
      <PayoutLinkOverview {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutLinkOverviewC)
