import { fieldEmpty } from 'utilities/validate'

const validateCreateComplianceFormTemplateForm = ({
  name,
  type,
}) => {
  return {
    name: fieldEmpty(name, 'Name'),
    type: fieldEmpty(type, 'Type'),
  }
}

export default validateCreateComplianceFormTemplateForm
