import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyTabs from './CompanyTabs'
import { goToPath } from 'state-layer/history'
import BrandingTabs from 'components/Shared/Tabs/BrandingTabs'
import IdentityPaymentInstrumentsC from 'components/Customer/Pages/Identity/IdentityPaymentInstruments/IdentityPaymentInstrumentsC'
import CompanyBusinessInfoC from 'components/Customer/Pages/Company/CompanyBusinessInfo/CompanyBusinessInfoC'
import LiveAccountApplicationStatusC from 'components/Customer/Pages/LiveAccountApplicationStatus/LiveAccountApplicationStatusC'
import TeamTabs from 'components/Shared/Tabs/TeamTabs'
import BalanceTransfersC from 'components/Customer/Pages/BalanceTransfers/BalanceTransfersC'
import ApplicationOwnerBankAccountsC from 'components/Customer/Pages/ApplicationOwnerBankAccounts/ApplicationOwnerBankAccountsC'
import ComplianceFormsC from 'components/Customer/Pages/Compliance/ComplianceForms/ComplianceFormsC'
import IdentityMerchantsC from 'components/Customer/Pages/Identity/IdentityMerchants/IdentityMerchantsC'
import ComplianceFormSettingsC from 'components/Customer/Pages/Compliance/ComplianceSettings/ComplianceSettingsC'
import ApplicationFeeProfileTabs from 'components/Customer/Pages/Application/ApplicationFeeProfile/ApplicationFeeProfileTabs'
import FeeProfileC from 'components/Customer/Pages/FeeProfile/FeeProfileC'
import ResourcesC from 'components/Customer/Pages/Resources/ResourcesC'
import IdentityFiles from 'components/Customer/Pages/IdentityFiles/IdentityFiles'
import getPlatformRequest from 'utilities/actions/get/getPlatformRequest'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentDashboardConfig from 'utilities/get/getCurrentDashboardConfig'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import getCurrentUser from 'utilities/get/getCurrentUser'
import hasPermissions from 'utilities/hasPermissions'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import getEntityConfigurationsRequest from 'utilities/actions/get/getEntityConfigurationsRequest'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { REQUIRE_TEAM_MFA_MODAL } from 'constants/modalConstants'
import { SHOW_MFA } from 'constants/featureFlagConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { CUSTOM } from 'constants/roleConstants'
import { DASHBOARD_TYPE_TO_BADGE_LABEL_MAP } from 'constants/dashboardConfigurationsConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  isDisbursementsPartnerDashboard,
  isStandaloneMerchantDashboard,
} from 'utilities/is/isDashboardType'

import {
  getPlatformSelector,
  getApplicationSelector,
  getIdentitySelector,
  getEntityConfigurationByLinkedToSelector,
  getIdentityFilesWithMerchantDataByIdentityId,
} from 'state-layer/selectors'

import {
  isRolePartner,
  isRoleMerchant,
  hasPartnerAccess,
  isRolePlatform,
  isRolePartnerOrMerchant,
} from 'utilities/validate/checkRoleCredentials'

import {
  ADD_TEAM_MEMBER_PATH,
  EDIT_IDENTITY_PATH,
} from 'constants/pathConstants'

import {
  BALANCE_TRANSFERS,
  COMPANY,
  COMPLIANCE,
  COMPANY_SUBTITLE_DESCRIPTION,
  BUSINESS_INFO,
  ADD_NEW_TEAM_MEMBER,
  TEAM,
  PAYMENT_INSTRUMENTS,
  MERCHANTS,
  FEE_PROFILE,
  FINIX_FEES,
  LIVE_ACCOUNT_APPLICATION_FORM,
  RESOURCES,
  SOFTWARE_COMPANY_BADGE_LABEL,
  FILES,
  BANK_ACCOUNTS,
  DASHBOARD_SETTINGS,
} from 'constants/language/languageConstants'

import {
  roleToEntityIdMap,
  roleToEntityTypeMap,
} from 'constants/memberConstants'

import {
  BALANCE_TRANSFER_LIST_PERMISSION,
  CREATE_TEAM_MEMBER_PERMISSION,
  VIEW_COMPLIANCE_FORMS_LIST_PERMISSION,
  VIEW_MERCHANTS_LIST_PERMISSION,
  VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION,
  VIEW_USERS_PERMISSION,
} from 'constants/permissionConstants'

const mapStateToProps = (state, props) => {
  const {
    showModal,
  } = props

  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUser = getCurrentUser(state)
  const role = get(credentials, 'role')
  const dashboardUserRoleType = get(credentials, 'dashboardUserRoleType')
  const entityType = get(roleToEntityTypeMap, role)
  const entityIdKey = get(roleToEntityIdMap, role)
  const entityId = get(currentUser, entityIdKey)
  const isSelfServiceSandboxUser = get(currentUser, 'isSelfServiceSandboxUser', false)

  const isRolePlatformCredential = isRolePlatform({ credentials })
  const isRolePartnerCredential = isRolePartner({ credentials })
  const isRoleMerchantCredential = isRoleMerchant({ credentials })
  const isPayoutCredential = isPayoutFeature({ credentials })
  const isFlexCredential = isFlexPlatform()

  const platformId = get(currentUser, 'platformId')
  const platform = getPlatformSelector(state, platformId)
  const platformFeeProfileId = get(platform, 'feeProfileId')
  const applicationId = get(currentUser, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const platformIdentityId = get(platform, 'identityId')
  const userIdentityId = get(currentUser, 'identityId')
  const identityId = isRolePlatformCredential ? platformIdentityId : userIdentityId
  const identity = getIdentitySelector(state, identityId)
  const identityMerchants = get(identity, 'merchants', [])
  const dashboardConfig = getCurrentDashboardConfig(state)
  const dashboardType = get(dashboardConfig, 'dashboardType')
  const companyBadgeLabel = DASHBOARD_TYPE_TO_BADGE_LABEL_MAP[dashboardType]
  const showCompanyBadgeLabel = !(isRoleMerchantCredential && companyBadgeLabel === SOFTWARE_COMPANY_BADGE_LABEL)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()
  const isDisbursements = isDisbursementsPartnerDashboard(state)

  // TODO: make a utility for this
  const dashboardUserRoleName = get(credentials, 'dashboardUserRoleName')
  const isRoleAdministrator = dashboardUserRoleName === 'Administrator'

  const entityConfiguration = getEntityConfigurationByLinkedToSelector(state, entityId)
  const entityConfigurationId = get(entityConfiguration, 'id')
  const mfaEnabled = get(entityConfiguration, 'mfaEnabled', false)
  const showMfa = getFeatureFlag(SHOW_MFA) && isAdministratorRole()

  const headerData = {
    title: <div className='flex items-center'>{COMPANY} {(showCompanyBadgeLabel && companyBadgeLabel) && <div className='badge-label'>{companyBadgeLabel}</div>}</div>,
    subTitle: <span className='subtitle-description'>{COMPANY_SUBTITLE_DESCRIPTION}</span>,
  }

  const firstTab = isSelfServiceSandboxUser && isRoleAdministrator ?
    {
      id: 'live-account-application-form',
      name: LIVE_ACCOUNT_APPLICATION_FORM,
      component: LiveAccountApplicationStatusC,
    } : {
      id: 'business-info',
      name: BUSINESS_INFO,
      component: CompanyBusinessInfoC,
      props: {
        identity,
        application,
        applicationId,
        platformId,
        isRolePartnerCredential,
        isPayoutCredential,
      },
      isFetching: !identityId,
      condition: () => !isSelfServiceSandboxUser,
    }

  const tabs = [
    firstTab,
    {
      id: 'business-info',
      name: BUSINESS_INFO,
      component: CompanyBusinessInfoC,
      props: {
        identity,
        application,
        applicationId,
        isRolePartnerCredential,
        isPayoutCredential,
      },
      isFetching: !identityId,
      condition: () => isSelfServiceSandboxUser,
    },
    {
      id: 'team',
      name: TEAM,
      component: TeamTabs,
      condition: hasPermissions([VIEW_USERS_PERMISSION]),
    },
    {
      id: 'payment-instruments',
      name: PAYMENT_INSTRUMENTS,
      component: IdentityPaymentInstrumentsC,
      props: {
        identityId,
      },
      condition: isRoleMerchantCredential && hasPermissions([VIEW_PAYMENT_INSTRUMENTS_LIST_PERMISSION]),
    },
    {
      id: 'merchants',
      name: MERCHANTS,
      component: IdentityMerchantsC,
      condition: isRoleMerchantCredential && hasPermissions([VIEW_MERCHANTS_LIST_PERMISSION]),
      props: { identityId },
      isFetching: !identityId,
    },
    {
      id: 'fee-and-risk-profile',
      name: isStandaloneMerchant ? FINIX_FEES : FEE_PROFILE,
      component: ApplicationFeeProfileTabs,
      condition: isRolePartnerCredential && !isPayoutCredential && !isStandaloneUnderwriting && !isDisbursements,
      props: { applicationId },
      isFetching: !applicationId,
    },
    {
      id: 'platform-fee-profile',
      name: FEE_PROFILE,
      component: FeeProfileC,
      condition: isRolePlatformCredential && !isPayoutCredential && !isStandaloneUnderwriting,
      props: { entityId: platformFeeProfileId, entityType: 'PLATFORM' },
      isFetching: !platformId,
    },
    {
      id: 'branding',
      name: DASHBOARD_SETTINGS,
      component: BrandingTabs,
      condition: isFlexCredential && !isPayoutCredential && isRolePartnerCredential,
      props: {
        entityId: applicationId || platformId,
        entityType: isRolePlatformCredential ? 'PLATFORM' : 'APPLICATION',
      },
      isFetching: !applicationId && !platformId,
    },
    {
      id: 'balance-transfers',
      name: BALANCE_TRANSFERS,
      component: BalanceTransfersC,
      condition: isRolePlatformCredential && !isPayoutCredential && hasPermissions([BALANCE_TRANSFER_LIST_PERMISSION]) && !isStandaloneUnderwriting,
    },
    {
      id: 'compliance-settings',
      name: COMPLIANCE,
      component: ComplianceFormSettingsC,
      condition: isFlexCredential && !isPayoutCredential && isRolePlatformCredential && hasPermissions([VIEW_COMPLIANCE_FORMS_LIST_PERMISSION]),
      props: {
        platformId,
      },
      isFetching: !platformId,
    },
    {
      id: 'compliance',
      name: COMPLIANCE,
      component: ComplianceFormsC,
      condition: (isRoleMerchantCredential && !isPayoutCredential && hasPermissions([VIEW_COMPLIANCE_FORMS_LIST_PERMISSION])) || isStandaloneMerchant,
      props: {
        isRoleMerchantView: true,
      },
    },
    {
      id: 'resources',
      name: RESOURCES,
      component: ResourcesC,
      condition: !isDisbursements,
    },
    {
      id: 'identity-files',
      name: FILES,
      component: IdentityFiles,
      props: {
        selector: () => getIdentityFilesWithMerchantDataByIdentityId(state, identityId),
        identityId,
        identityMerchants,
        showUploadButton: true,
        fileNameCSV: 'IdentityFiles',
      },
      isFetching: !identityId,
      condition: isRoleMerchant,
    },
    {
      id: 'bank-accounts',
      name: BANK_ACCOUNTS,
      component: ApplicationOwnerBankAccountsC,
      condition: isDisbursements,
      props: {
        identityId,
      },
    },
  ]

  return {
    tabs,
    headerData,
    platformId,
    applicationId,
    identityId,
    platform,
    application,
    identity,
    credentials,
    credentialId,
    entityId,
    entityType,
    mfaEnabled,
    showMfa,
    entityConfigurationId,
    dashboardUserRoleType,
  }
}

const mapDispatchToProps = {
  getPlatform: getPlatformRequest,
  getApplication: getApplicationRequest,
  getIdentity: getIdentityRequest,
  getEntityConfigurations: getEntityConfigurationsRequest,
  showModal: showModalAction,
}

class CompanyTabsC extends Component {
  componentDidMount() {
    const {
      getPlatform,
      getApplication,
      getIdentity,
      platformId,
      applicationId,
      identityId,
      platform,
      application,
      identity,
      credentials,
      entityId,
      entityType,
      getEntityConfigurations,
    } = this.props

    if (platformId && isEmpty(platform) && isRolePlatform({ credentials })) {
      getPlatform({ platformId, credentials })
    }

    if (applicationId && isEmpty(application) && hasPartnerAccess({ credentials })) {
      getApplication({ applicationId, credentials })
    }

    if (identityId && isEmpty(identity)) {
      getIdentity({ identityId, credentials })
    }

    if (entityId && entityType) {
      getEntityConfigurations({
        queries: {
          linked_to: entityId,
          linked_type: entityType,
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      platformId: prevPlatformId,
      applicationId: prevApplicationId,
      identityId: prevIdentityId,
      entityId: prevEntityId,
      entityType: prevEntityType,
    } = prevProps

    const {
      getPlatform,
      getApplication,
      getIdentity,
      platformId,
      applicationId,
      identityId,
      credentials,
      platform,
      application,
      identity,
      entityId,
      entityType,
      getEntityConfigurations,
    } = this.props

    if (!prevPlatformId && platformId && isEmpty(platform) && isRolePlatform({ credentials })) {
      getPlatform({ platformId, credentials })
    }

    if (!prevApplicationId && applicationId && isEmpty(application) && hasPartnerAccess({ credentials })) {
      getApplication({ applicationId, credentials })
    }

    if (!prevIdentityId && identityId && isEmpty(identity)) {
      getIdentity({ identityId, credentials })
    }

    if (entityId && entityType && (entityId !== prevEntityId || entityType !== prevEntityType)) {
      getEntityConfigurations({
        queries: {
          linked_to: entityId,
          linked_type: entityType,
        },
      })
    }
  }

  showChangeTeamMFAModal = () => {
    const {
      entityId,
      entityType,
      entityConfigurationId,
      showModal,
      mfaEnabled,
    } = this.props

    showModal({
      modalType: REQUIRE_TEAM_MFA_MODAL,
      modalProps: {
        linkedTo: entityId,
        linkedType: entityType,
        entityConfigurationId,
        mfaEnabled: !mfaEnabled,
      },
      className: 'modal-md no-pad',
    })
  }

  render() {
    const {
      credentials,
      credentialId,
      identityId,
      entityType,
      entityId,
      mfaEnabled,
      dashboardUserRoleType,
    } = this.props

    const actions = [
      {
        label: 'Edit Identity',
        link: EDIT_IDENTITY_PATH({ credentialId, identityId }),
        condition: isRolePartnerOrMerchant({ credentials }),
      },
      {
        label: ADD_NEW_TEAM_MEMBER,
        className: 'add-team-member-button',
        action: () => goToPath({
          pathname: ADD_TEAM_MEMBER_PATH({ credentialId }),
          queries: {
            entityType,
            entityId,
          },
        }),
        condition: dashboardUserRoleType !== undefined && dashboardUserRoleType !== CUSTOM && hasPermissions([CREATE_TEAM_MEMBER_PERMISSION]),
      },
      {
        label: mfaEnabled ? 'Remove Multi-Factor Authentication' : 'Require Multi-Factor Authentication',
        action: this.showChangeTeamMFAModal,
        condition: getFeatureFlag(SHOW_MFA) && isAdministratorRole(),
      },
    ]

    return (
      <CompanyTabs
        actions={actions}
        {...this.props}
      />
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CompanyTabsC)
