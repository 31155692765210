import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_WORKFLOW } from 'constants/apiConstants'

const getUnderwritingWorkflowAPI = ({ id, queries, credentials, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: UNDERWRITING_WORKFLOW({ workflowId: id }),
    queries,
    meta,
  })
}

export default getUnderwritingWorkflowAPI
