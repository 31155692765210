import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_INSTRUMENTS } from 'constants/apiConstants'

const getPaymentInstrumentsAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: PAYMENT_INSTRUMENTS,
    queries,
    credentials,
    service: PAYMENT_INSTRUMENTS,
  })
}

export default getPaymentInstrumentsAPI
