import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import EditVisaPayoutConfigsForm from 'components/Customer/Forms/EditApplicationPayoutConfigsForm/EditApplicationPayoutConfigsForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import selectOption from 'utilities/forms/selectOption'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM } from 'constants/formConstants'
import { FINIX_V1 } from 'constants/processorConstants'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)
  const formSelector = formValueSelector('EDIT_APPLICATION_PAYOUT_CONFIGURATION_FORM')
  const allowedBusinessAppId = formSelector(state, 'visaAllowedBusinessApplicationIds')
  const visaDefaultCurrenciesOnForm = formSelector(state, 'visaDefaultCurrencies')

  const [
    visaAllowedBusinessApplicationIds,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies,
    visaDefaultMcc,
    visaDefaultSenderAccountNumber,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    isVisa,
    visaDefaultSenderName,
  ] = getMany(props, [
    'visaAllowedBusinessApplicationIds',
    'visaCardAcceptorIdCode',
    'visaAcceptorTerminalId',
    'visaDefaultCurrencies',
    'visaDefaultMcc',
    'visaDefaultSenderAccountNumber',
    'visaDefaultSenderAddress',
    'visaDefaultSenderCity',
    'visaDefaultSenderStateCode',
    'visaDefaultSenderZipCode',
    'visaDefaultSenderCountryCode',
    'visaDefaultSenderCountry',
    'isVisa',
    'visaDefaultSenderName',
  ])

  const displayBusinessIdArrayVisa = map(visaAllowedBusinessApplicationIds, (value) => selectOption(value, value))
  const displayDefaultCurrencies = map(visaDefaultCurrencies, (value) => selectOption(value, value))

  const [
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies,
    mcDefaultMcc,
    mcDefaultSenderAccountNumber,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    isMastercard,
    mcSubdivision,
    mcStatementDescriptor,
  ] = getMany(props, [
    'mcCardAcceptorId',
    'mcDefaultPaymentType',
    'mcDefaultCurrencies',
    'mcDefaultMcc',
    'mcDefaultSenderAccountNumber',
    'mcDefaultSenderFirstName',
    'mcDefaultSenderLastName',
    'mcDefaultSenderFullName',
    'mcDefaultSenderAddressLine1',
    'mcDefaultSenderAddressLine2',
    'mcDefaultSenderCity',
    'mcDefaultSenderPostalCode',
    'mcDefaultSenderCountry',
    'mcDefaultSenderCountryCode',
    'isMastercard',
    'mcSubdivision',
    'mcStatementDescriptor',
  ])

  const displayMastercardCurrencies = map(mcDefaultCurrencies, (value) => selectOption(value, value))

  const initialValues = {
    visaAllowedBusinessApplicationIds: displayBusinessIdArrayVisa,
    visaCardAcceptorIdCode,
    visaAcceptorTerminalId,
    visaDefaultCurrencies: displayDefaultCurrencies,
    visaDefaultMcc,
    visaDefaultSenderName,
    visaDefaultSenderAccountNumber,
    visaDefaultSenderAddress,
    visaDefaultSenderCity,
    visaDefaultSenderStateCode,
    visaDefaultSenderZipCode,
    visaDefaultSenderCountryCode,
    visaDefaultSenderCountry,
    mcCardAcceptorId,
    mcDefaultPaymentType,
    mcDefaultCurrencies: displayMastercardCurrencies,
    mcDefaultMcc,
    mcDefaultSenderAccountNumber,
    mcDefaultSenderFirstName,
    mcDefaultSenderLastName,
    mcDefaultSenderFullName,
    mcDefaultSenderAddressLine1,
    mcDefaultSenderAddressLine2,
    mcDefaultSenderCity,
    mcDefaultSenderPostalCode,
    mcDefaultSenderCountry,
    mcDefaultSenderCountryCode,
    isMastercard,
    mcSubdivision,
    mcStatementDescriptor,
  }

  return {
    credentials,
    initialValues,
    processorFinix: FINIX_V1,
    allowedBusinessAppId,
    visaDefaultCurrenciesOnForm,
    isVisa,
    isMastercard,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class EditApplicationPayoutConfigsFormC extends Component {
  render() {
    return (
      <EditVisaPayoutConfigsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditApplicationPayoutConfigsFormC)
