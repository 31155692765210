import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import IdentitySettlementsC from 'components/Customer/Pages/Identity/IdentitySettlements/IdentitySettlementsC'
import IdentityMerchantDepositsC from 'components/Customer/Pages/Identity/IdentityMerchantDeposits/IdentityMerchantDepositsC'

import {
  SETTLEMENTS,
  MERCHANT_DEPOSITS,
} from 'constants/language/languageConstants'

class IdentityPayoutsTabs extends Component {
  render() {
    const {
      identityId,
    } = this.props

    const tabs = [
      {
        id: 'settlements',
        name: SETTLEMENTS,
        component: IdentitySettlementsC,
        props: {
          identityId,
        },
      },
      {
        id: 'merchant_deposits',
        name: MERCHANT_DEPOSITS,
        component: IdentityMerchantDepositsC,
        props: {
          identityId,
        },
      },
    ]

    return (
      <div className='IdentityPayoutsTabs'>
        <TabsC
          tabs={tabs}
          isSecondaryTab
        />
      </div>
    )
  }
}

export default IdentityPayoutsTabs
