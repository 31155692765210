import { fieldEmpty } from 'utilities/validate'

const validateAddNewDomainForm = (values) => {
  const {
    websiteDomain,
  } = values

  return {
    websiteDomain: fieldEmpty(websiteDomain, 'Domain'),
  }
}

export default validateAddNewDomainForm
