import React from 'react'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'

import {
  ID,
  NAME,
  CREATED_ON,
  PAYMENT_PROCESSING,
  ONBOARDING_STATE_TITLE,
  SETTLEMENTS,
  PROCESSOR,
  COUNTRY,
  MID,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NAME, 'identity.displayBusinessName', { className: 'large' }),
  column(ONBOARDING_STATE_TITLE, 'onboardingState', {
    className: 'state',
    formatter: ({ onboardingState }) => <OnboardingState value={onboardingState} />,
  }),
  column(PAYMENT_PROCESSING, 'processingEnabled', {
    className: 'state',
    formatter: ({ processingEnabled }) => <EnabledStatus value={processingEnabled ? 'Enabled' : 'Disabled'} />,
  }),
  column(SETTLEMENTS, 'settlementEnabled', {
    className: 'state',
    formatter: ({ settlementEnabled }) => <EnabledStatus value={settlementEnabled ? 'Enabled' : 'Disabled'} />,
  }),
  column(PROCESSOR, 'processor', { className: 'small' }),
  column(MID, 'mid', { className: 'medium' }),
  column(COUNTRY, 'displayCountry'),
]

export default columnDescriptors
