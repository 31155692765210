import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

const frontendApplePayRegisteredMerchantModel = ({ data: registeredMerchant }) => {
  const [
    id,
    domains,
    merchantIdentity,
    merchantName,
  ] = getMany(get(registeredMerchant, '[0]'), [
    'id',
    'domains',
    'merchant_identity',
    'merchant_name',
  ])

  return removeUndefined({
    id,
    domains,
    merchantIdentity,
    merchantName,
  })
}

export default frontendApplePayRegisteredMerchantModel
