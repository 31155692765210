import './EditDeviceConfigurationDetailsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import PercentageField from 'components/Shared/Inputs/PercentageField/PercentageField'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import submitEditDeviceConfigurationDetailsForm from 'utilities/submit/submitEditDeviceConfigurationDetailsForm'
import validateEditDeviceConfigurationDetailsForm from 'utilities/validate/validateEditDeviceConfigurationDetailsForm'
import { EDIT_DEVICE_CONFIGURATION_DETAILS_FORM } from 'constants/formConstants'
import map from 'lodash/map'
import includes from 'lodash/includes'
import filter from 'lodash/filter'

import {
  PROMPT_FOR_SIGNATURE_OPTIONS,
  PROMPT_SIGNATURE_OPTIONS,
} from 'constants/deviceConstants'

import {
  CANCEL,
  EMAIL,
  OPTIONAL_SETTINGS,
  PERCENTAGE_TIP_OPTIONS,
  PERCENTAGE_TIPPING_THRESHOLD_LABEL,
  PRINT,
  PROMPT_RECEIPT_CONFIRMATION,
  PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG,
  PROMPT_SIGNATURE,
  RECEIPT_DELIVERY_METHODS_LABEL,
  RECEIPT_DELIVERY_METHODS_TOOLTIP,
  RECEIPT_SETTINGS,
  SAVE,
  SIGNATURE_THRESHOLD_AMOUNT,
  SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG,
  SMS,
  SUPPORTED_AUTOMATIC_DELIVERY_METHODS_LABEL,
  SUPPORTED_AUTOMATIC_DELIVERY_METHODS_TOOLTIP,
  TEXT,
  THRESHOLD_FIXED_AMOUNT_OPTIONS_LABEL,
  TIP_COLLECTION_ON_TERMINAL,
  TIPPING_SETTINGS,
} from 'constants/language/languageConstants'

const EditDeviceConfigurationDetailsForm = ({
  handleSubmit = () => {},
  deviceConfigurationSelectOptions = [],
  invalid = false,
  pristine = false,
  isUpdating = false,
  closeModal = () => {},
  showPromptSignatureFields = false,
  showSignatureAmountField = false,
  merchantGateway = '',
  showTippingSettings = false,
  showReceiptSettingsError = false,
  showReceiptAndTipConfigOptions = false,
  showPromptReceiptConfirmationSettings = false,
  currentDisplayAvailableReceiptMethodsValue = [],
  currentDisplayAutomaticReceiptDeliveryMethodsValue = [],
  showPromptForSignatureField = false,
}) => {
  return (
    <form className='EditDeviceConfigurationDetailsForm' onSubmit={handleSubmit(submitEditDeviceConfigurationDetailsForm)}>
      <div className='modal-content'>
        {map(filter(deviceConfigurationSelectOptions, ({ visibleToCustomers, disabledForGateways }) => visibleToCustomers && !includes(disabledForGateways, merchantGateway)), ({ name, label, tooltip }) => {
          return (
            <Field
              key={name}
              className='device-config-toggle-field'
              name={name}
              label={label}
              component={ToggleSwitchC}
              tooltip={tooltip}
              formName={EDIT_DEVICE_CONFIGURATION_DETAILS_FORM}
            />
          )
        })}

        {showReceiptAndTipConfigOptions && (
          <>
            <Field
              name='promptReceiptConfirmation'
              className='prompt-receipt-confirmation-toggle'
              label={PROMPT_RECEIPT_CONFIRMATION}
              component={ToggleSwitchC}
              tooltip={PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG}
              tooltipPosition='right'
            />

            {showPromptReceiptConfirmationSettings && (
            <div className='receipt-confirmation-settings'>
              <h6>{RECEIPT_SETTINGS}</h6>
              {showReceiptSettingsError && <ErrorMessage errorMessage='Please select one receipt confirmation method.' />}

              <span className='flex row'><p>{RECEIPT_DELIVERY_METHODS_LABEL}</p><TooltipLabelC message={RECEIPT_DELIVERY_METHODS_TOOLTIP} position='top' /></span>
              <div className='delivery-methods'>
                <Field
                  name='receiptDeliveryPrint'
                  label={PRINT}
                  component={CheckboxC}
                  checked={includes(currentDisplayAvailableReceiptMethodsValue, PRINT)}
                />

                <Field
                  name='receiptDeliveryEmail'
                  label={EMAIL}
                  component={CheckboxC}
                  checked={includes(currentDisplayAvailableReceiptMethodsValue, EMAIL)}
                />

                <Field
                  name='receiptDeliverySms'
                  label={TEXT}
                  component={CheckboxC}
                  checked={includes(currentDisplayAvailableReceiptMethodsValue, SMS)}
                />
              </div>

              <span className='flex row'><p>{SUPPORTED_AUTOMATIC_DELIVERY_METHODS_LABEL}</p><TooltipLabelC message={SUPPORTED_AUTOMATIC_DELIVERY_METHODS_TOOLTIP} position='top' /></span>
              <div className='automatic-delivery-options'>
                <Field
                  name='automaticDeliveryPrint'
                  label={PRINT}
                  component={CheckboxC}
                  checked={includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, PRINT)}
                />

                <Field
                  name='automaticDeliveryEmail'
                  label={EMAIL}
                  component={CheckboxC}
                  checked={includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, EMAIL)}
                />

                <Field
                  name='automaticDeliverySms'
                  label={TEXT}
                  component={CheckboxC}
                  checked={includes(currentDisplayAutomaticReceiptDeliveryMethodsValue, SMS)}
                />
              </div>
            </div>
            )}

            <Field
              name='promptTipOnScreen'
              className='prompt-tip-on-screen-toggle'
              label={TIP_COLLECTION_ON_TERMINAL}
              component={ToggleSwitchC}
              tooltip={PROMPT_RECEIPT_CONFIRMATION_TOOLTIP_MSG}
              tooltipPosition='right'
            />

            { showTippingSettings && (
              <div className='tipping-settings'>
                <h6>{TIPPING_SETTINGS}</h6>
                <div className='tipping-percentages'>
                  <p>{PERCENTAGE_TIP_OPTIONS}</p>
                  <div className='flex row'>
                    <PercentageField
                      name='percentageOne'
                    />
                    <PercentageField
                      name='percentageTwo'
                    />
                    <PercentageField
                      name='percentageThree'
                    />
                  </div>
                </div>

                <div className='tipping-optional-settings'>
                  <h6>{OPTIONAL_SETTINGS}</h6>
                  <AmountField
                    name='percentTippingThreshold'
                    label={PERCENTAGE_TIPPING_THRESHOLD_LABEL}
                    placeholder='5.00'
                  />

                  <p>{THRESHOLD_FIXED_AMOUNT_OPTIONS_LABEL}</p>
                  <div className='flex row'>
                    <AmountField
                      name='amountOne'
                      placeholder='1.00'
                    />
                    <AmountField
                      name='amountTwo'
                      placeholder='1.50'
                    />
                    <AmountField
                      name='amountThree'
                      placeholder='2.00'
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        { showPromptSignatureFields && (
        <Field
          name='promptSignature'
          className='prompt-signature-field'
          label={PROMPT_SIGNATURE}
          component={ReactSelect}
          menuPlacement='top'
          options={PROMPT_SIGNATURE_OPTIONS}
        />
        )}

        { showPromptForSignatureField && (
        <Field
          name='promptForSignature'
          className='prompt-signature-field'
          label={PROMPT_SIGNATURE}
          component={ReactSelect}
          menuPlacement='top'
          options={PROMPT_FOR_SIGNATURE_OPTIONS}
        />
        )}

        { showSignatureAmountField && (
        <AmountField
          className='signature-threshold-amount-field'
          name='signatureThresholdAmount'
          label={SIGNATURE_THRESHOLD_AMOUNT}
          tooltip={SIGNATURE_THRESHOLD_AMOUNT_TOOLTIP_MSG}
          required
        />
        )}
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={SAVE} submitting={isUpdating} disabled={invalid || pristine} />
      </div>
    </form>
  )
}

EditDeviceConfigurationDetailsForm.propTypes = {
  handleSubmit: PropTypes.func,
  deviceConfigurationSelectOptions: PropTypes.array,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  isUpdating: PropTypes.bool,
  closeModal: PropTypes.func,
  showPromptSignatureFields: PropTypes.bool,
  showSignatureAmountField: PropTypes.bool,
  merchantGateway: PropTypes.string,
  showTippingSettings: PropTypes.bool,
  showReceiptAndTipConfigOptions: PropTypes.bool,
  showPromptReceiptConfirmationSettings: PropTypes.bool,
  currentDisplayAvailableReceiptMethodsValue: PropTypes.array,
  currentDisplayAutomaticReceiptDeliveryMethodsValue: PropTypes.array,
  showReceiptSettingsError: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_DEVICE_CONFIGURATION_DETAILS_FORM,
  validate: validateEditDeviceConfigurationDetailsForm,
})(EditDeviceConfigurationDetailsForm)
