import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import DeviceRefunds from './DeviceRefunds'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { REVERSAL } from 'constants/transferConstants'
import { REFUND } from 'constants/language/languageConstants'
import { REFUNDS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  APPLICATION_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  AMOUNT(),
  APPLICATION_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  TRACE_ID,
  convertFilterToResourceIdFilter(REFUND),
], 'label')

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
  type: REVERSAL,
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const refundsPath = REFUNDS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const deviceId = get(props, 'deviceId')
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    isFetching,
    refundsPath,
    initialQueries: mergedQueries,
    allowedFilters,
    quickFilters,
    deviceId,
  }
}

class DeviceRefundsC extends Component {
  render() {
    return (
      <DeviceRefunds {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(DeviceRefundsC)
