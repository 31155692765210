import './IdentityReviewInfoS.scss'
import React from 'react'
import DictionaryList from 'components/Customer/Shared/Page/DictionaryList/DictionaryList'

const IdentityReviewInfo = ({
  businessContactInfo,
  personalContactInfo,
  additionalInfo,
}) => {
  return (
    <>
      <h5>Business Contact Information</h5>

      <DictionaryList
        data={businessContactInfo}
        className='onboardingIdentityInfo'
      />

      <h5>Personal Contact Information</h5>

      <DictionaryList
        data={personalContactInfo}
        className='onboardingIdentityInfo'
      />

      <h5>Additional Information</h5>

      <DictionaryList
        data={additionalInfo}
        className='onboardingIdentityInfo'
      />
    </>
  )
}

export default IdentityReviewInfo
