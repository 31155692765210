import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import get from 'lodash/get'

const createTableHeaders = ({
  columnDescriptors,
  credentials,
}) => {
  const role = get(credentials, 'role')
  const platform = getCurrentPlatform()

  return map(columnDescriptors, (columnDescriptor) => {
    const {
      title = '',
      className = 'medium',
      sort,
      condition = true,
      headerFormatter,
    } = columnDescriptor

    const headerID = title.split(' ').join('-').toLowerCase()
    const showColumn = isFunction(condition) ? condition({ platform, credentials, role }) : condition
    const displayValue = headerFormatter ? headerFormatter() : title

    return {
      id: headerID,
      displayValue,
      className,
      sort,
      showColumn,
    }
  })
}

export default createTableHeaders
