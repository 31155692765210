import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  PATCH_APPLICATION_F_REQUEST,
  PATCH_AUTHORIZATION_F_REQUEST,
  PATCH_IDENTITY_F_REQUEST,
  PATCH_PAYMENT_INSTRUMENT_F_REQUEST,
  PATCH_TRANSFER_F_REQUEST,
  PATCH_FEE_F_REQUEST,
  PATCH_COMPLIANCE_FORM_F_REQUEST,
  PATCH_DISPUTE_F_REQUEST,
  PATCH_PAYMENT_LINK_F_REQUEST,
  PATCH_DEVICE_F_REQUEST,
} from 'constants/flowConstants'

import {
  ALL_ROLES,
  NON_MERCHANT_ROLES,
  ADMIN_PLATFORM_ROLES,
  PARTNER_AND_MERCHANT_ROLES,
} from 'constants/roleConstants'

const allowedRoles = {
  [PATCH_APPLICATION_F_REQUEST]: ADMIN_PLATFORM_ROLES,
  [PATCH_AUTHORIZATION_F_REQUEST]: PARTNER_AND_MERCHANT_ROLES,
  [PATCH_IDENTITY_F_REQUEST]: NON_MERCHANT_ROLES,
  [PATCH_PAYMENT_INSTRUMENT_F_REQUEST]: NON_MERCHANT_ROLES,
  [PATCH_TRANSFER_F_REQUEST]: ALL_ROLES,
  [PATCH_FEE_F_REQUEST]: ADMIN_PLATFORM_ROLES,
  [PATCH_COMPLIANCE_FORM_F_REQUEST]: NON_MERCHANT_ROLES,
  [PATCH_DISPUTE_F_REQUEST]: NON_MERCHANT_ROLES,
  [PATCH_PAYMENT_LINK_F_REQUEST]: NON_MERCHANT_ROLES,
  [PATCH_DEVICE_F_REQUEST]: NON_MERCHANT_ROLES,
}

export const hasEditPermission = (credentials, actionType) => {
  const roles = get(allowedRoles, actionType, [])
  return includes(roles, get(credentials, 'role'))
}
