import './PaymentDevicesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getDeviceMerchantSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import columnDescriptors from './columnDescriptors'
import { FAX_ICON } from 'constants/iconConstants'
import { GET_DEVICES } from 'constants/flowConstants'
import { PAYMENT_DEVICES } from 'constants/linkConstants'
import { PAYMENT_DEVICES_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  PAYMENT_DEVICES as PAYMENT_DEVICES_TITLE,
  PAYMENT_DEVICES_TABLE_SUBTITLE,
  PAYMENT_DEVICES_EMPTY_TABLE_MESSAGE,
  LEARN_MORE_LABEL,
} from 'constants/language/languageConstants'

const PaymentDevices = ({
  paymentDevicePath = '',
  allowedFilters,
  initialQueries,
  initialFilters,
  isFetching = false,
  quickFilters,
  actions = [],
  showHeader = true,
}) => {
  return (
    <div className='PaymentDevices'>
      {showHeader && (
        <HeaderV2C
          title={PAYMENT_DEVICES_TITLE}
          subTitle={(
            <div className='flex'>
              <div>{PAYMENT_DEVICES_TABLE_SUBTITLE}</div>
              <a className='text-link learn-more-link' href={PAYMENT_DEVICES_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
            </div>
          )}
        />
      )}

      <TableC
        flow={GET_DEVICES}
        columnDescriptors={columnDescriptors}
        selector={getDeviceMerchantSelector}
        initialQueries={initialQueries}
        initialFilters={initialFilters}
        linksKey={PAYMENT_DEVICES}
        allowedFilters={allowedFilters}
        path={paymentDevicePath}
        isFetching={isFetching}
        fileNameCSV={PAYMENT_DEVICES_TITLE}
        icon={`fa fa-${FAX_ICON}`}
        emptyMessage={PAYMENT_DEVICES_EMPTY_TABLE_MESSAGE}
        quickFilters={quickFilters}
        entityType={PAYMENT_DEVICES}
        actions={actions}
      />
    </div>
  )
}

PaymentDevices.propTypes = {
  paymentDevicePath: PropTypes.string,
  allowedFilters: PropTypes.array,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  isFetching: PropTypes.bool,
  quickFilters: PropTypes.array,
  actions: PropTypes.array,
  showHeader: PropTypes.bool,
}

export default PaymentDevices
