import './DashboardFeedbackModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import DashboardFeedbackForm from 'components/Customer/Forms/DashboardFeedbackForm/DashboardFeedbackForm'

const DashboardFeedbackModal = ({
  credentials,
  currentUserEmail = '',
}) => {
  return (
    <GenericModal
      title='Give Us Feedback'
      className='DashboardFeedbackModal'
      Component={DashboardFeedbackForm}
      credentials={credentials}
      currentUserEmail={currentUserEmail}
    />
  )
}

DashboardFeedbackModal.propTypes = {
  credentials: PropTypes.object,
  currentUserEmail: PropTypes.string,
}

export default DashboardFeedbackModal
