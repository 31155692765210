import React, { Component } from 'react'
import { connect } from 'react-redux'
import ArchiveSubscriptionPlanForm from './ArchiveSubscriptionPlanForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ArchiveSubscriptionPlanFormC extends Component {
  render() {
    return (
      <ArchiveSubscriptionPlanForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveSubscriptionPlanFormC)
