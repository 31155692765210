import React from 'react'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import getMany from 'utilities/get/getMany'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import get from 'lodash/get'

import {
  amountRange,
  createdAtRange,
} from 'utilities/table/sort'

import {
  AMOUNT,
  APPLICATION,
  BUYER,
  CREATED_ON,
  ID,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
  REASON_CODE,
  DESCRIPTION,
  PAYMENT_INSTRUMENT,
  MASKED_NUMBER,
  INSTRUMENT_TYPE,
  EMAIL,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT_RESOURCE_TITLE, 'merchantIdentity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (transfer) => <TransferMerchantApplicationColumn transfer={transfer} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(BUYER, 'buyerIdentity.buyerName', {
    className: 'name',
    headerFormatter: () => <TableItemSubtitle title={BUYER} subtitle={EMAIL} />,
    formatter: ({ buyerIdentity }) => <TableItemSubtitle title={get(buyerIdentity, 'buyerName')} subtitle={get(buyerIdentity, 'email')} />,
    condition: notRoleMerchant,
  }),
  column(AMOUNT, ['displayAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ displayAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={displayAmount} currency={currency} />,
    // TODO: re-enable once BE fixes amount sorting bug in search service
    // sort: amountRange,
  }),
  column(PAYMENT_INSTRUMENT, 'paymentInstrument.name', {
    headerFormatter: () => <TableItemSubtitle title={PAYMENT_INSTRUMENT} subtitle={MASKED_NUMBER} />,
    formatter: ({ paymentInstrument }) => {
      const [
        name,
        maskedAccountNumber,
        maskedFullCardNumber,
      ] = getMany(paymentInstrument, [
        'name',
        'maskedAccountNumber',
        'maskedFullCardNumber',
      ])

      return (
        <TableItemSubtitle
          title={name}
          subtitle={maskedAccountNumber || maskedFullCardNumber}
        />
      )
    },
    className: 'normal',
  }),
  column(INSTRUMENT_TYPE, 'paymentInstrument.type', {
    className: 'normal',
  }),
  column('', '', {
    className: 'medium',
    headerFormatter: () => <TableItemSubtitle title={REASON_CODE} subtitle={DESCRIPTION} />,
    formatter: (transfer) => {
      const reasonCode = get(transfer, 'tags.result_message_0', '').split(':')[1]
      const reasonDescription = get(transfer, 'tags.result_message_1', '').split(':')[1]

      return <TableItemSubtitle title={reasonCode} subtitle={reasonDescription} />
    },
  }),
]

export default columnDescriptors
