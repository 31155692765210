import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  ONBOARDING_FORM_LINK,
  ONBOARDING_FORMS,
} from 'constants/apiConstants'

const postOnboardingFormLinkAPI = ({ id, values, meta, credentials }) => {
  return paymentsAPI.post({
    path: ONBOARDING_FORM_LINK({ onboardingFormId: id }),
    values,
    meta,
    service: ONBOARDING_FORMS,
    credentials,
  })
}

export default postOnboardingFormLinkAPI
