import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import removeUndefined from 'utilities/remove/removeUndefined'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import { USD } from 'constants/currencyConstants'
import map from 'lodash/map'

const backendPayoutLinkModel = ({ values }) => {
  const {
    applicationId,
    allowedPayoutOperations,
    nickname,
    items,
    email,
    firstName,
    lastName,
    recipientMerchantId,
    businessName,
    doingBusinessAs,
    currency = USD,
    expirationInMinutes,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    icon,
    brandColor,
    accentColor,
    buttonFontColor,
    logo,
    tags,
  } = values

  let totalAmount = 0

  const normalizedItems = map(items, (item) => {
    const {
      name,
      quantity = '1',
      amount,
      description,
      primaryImageUrl,
    } = item

    const itemAmount = displayAmountToAmount(amount, currency)
    totalAmount += (itemAmount * parseInt(quantity, 10))

    return {
      name,
      description,
      quantity,
      image_details: {
        primary_image_url: primaryImageUrl,
      },
      price_details: {
        amount,
        currency,
      },
    }
  })

  return removeUndefined({
    application_id: applicationId,
    allowed_payout_operations: allowedPayoutOperations,
    merchant_id: null,
    nickname,
    tags,
    items: normalizedItems,
    amount_details: {
      total_amount: amountToDisplayAmount(totalAmount, currency),
      currency,
    },
    additional_details: {
      expiration_in_minutes: expirationInMinutes,
      terms_of_service_url: termsOfServiceUrl,
      success_return_url: successReturnUrl,
      unsuccessful_return_url: unsuccessfulReturnUrl,
      expired_session_url: expiredSessionUrl,
    },
    branding: {
      icon,
      brand_color: brandColor,
      accent_color: accentColor,
      button_font_color: buttonFontColor,
      logo,
    },
    recipient_details: {
      first_name: firstName,
      last_name: lastName,
      business_name: businessName,
      doing_business_as: doingBusinessAs,
      email,
      merchant_id: recipientMerchantId,
    },
  })
}

export default backendPayoutLinkModel
