import React, { Component } from 'react'
import { connect } from 'react-redux'
import CertificatePendingForm from './CertificatePendingForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)

  return {
    credentials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CertificatePendingFormC extends Component {
  render() {
    return (
      <CertificatePendingForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificatePendingFormC)
