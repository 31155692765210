import './PaymentInstrumentS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TabsC from 'components/Shared/Tabs/TabsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import Loader from 'components/Shared/Loader/Loader'

const PaymentInstrument = ({
  tabs = [],
  headerData = {},
  contextBarData = {},
  isFetching = false,
}) => {
  return (
    <div className='PaymentInstrument'>
      <ContextBar {...contextBarData} />

      <HeaderV2C {...headerData} />

      { isFetching && <Loader />}

      { !isFetching && (
        <TabsC
          tabs={tabs}
        />
      )}
    </div>
  )
}

PaymentInstrument.propTypes = {
  tabs: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
}

export default PaymentInstrument
