import { GET_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getIdentityRequest = ({
  identityId,
  credentials,
  meta,
}) => createAction({
  type: GET_IDENTITY_F_REQUEST,
  id: identityId,
  credentials,
  meta,
})

export default getIdentityRequest
