import './ApplicationInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import RiskProfileToolTipMessage from 'components/Customer/Pages/RiskProfileToolTipMessage/RiskProfileToolTipMessage'
import UnderwritingProfileC from 'components/Customer/Pages/UnderwritingProfile/UnderwritingProfileC'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { PATCH_APPLICATION_F_REQUEST } from 'constants/flowConstants'

import {
  BUSINESS_PRINCIPALS,
  RISK_PROFILE,
  DETAILS,
  PROCESSING_INFORMATION,
} from 'constants/language/languageConstants'

const ApplicationInfo = ({
  application = {},
  applicationId = '',
  platformId = '',
  processingDetailsSectionData = [],
  businessInfoSectionData = [],
  riskProfileSectionData = [],
  businessInfoActions = [],
  processingDetailsActions = [],
  riskProfileActions = [],
  businessPrincipalsDataCards = [],
  tagsSectionData = [],
  isFetching = false,
  isPayout = false,
  businessDescriptionString = '',
  isFlex = false,
  isStandaloneUnderwriting = false,
  processors = [],
  isDisbursementsApplication = false,
}) => {
  return (
    <div className='ApplicationInfo'>
      <PageSectionV2C
        title={DETAILS}
        columns={convertPageSectionDataToV2(businessInfoSectionData)}
        actions={businessInfoActions}
        isFetching={isFetching}
        subTitle={businessDescriptionString}
      />
      {!isPayout && !isStandaloneUnderwriting && (
        <PageSectionV2C
          title={PROCESSING_INFORMATION}
          columns={convertPageSectionDataToV2(processingDetailsSectionData)}
          isFetching={isFetching}
          actions={processingDetailsActions}
        />
      )}
      {(isFlex || isStandaloneUnderwriting) && (
        <UnderwritingProfileC
          isFetching={isFetching}
          applicationId={applicationId}
          platformId={platformId}
          isApplicationInfoPage
          processors={processors}
          isDisbursementsApplication={isDisbursementsApplication}
        />
      )}
      {!isPayout && !isStandaloneUnderwriting && (
        <PageSectionV2C
          title={RISK_PROFILE}
          columns={riskProfileSectionData}
          actions={riskProfileActions}
          isFetching={isFetching}
          className='RiskProfile'
          tooltip
          tooltipMessage={<RiskProfileToolTipMessage />}
          tooltipPosition='right'
        />
      )}

      <PageSectionV2C
        title={BUSINESS_PRINCIPALS}
        columns={businessPrincipalsDataCards}
        isFetching={isFetching}
      />

      <TagsPageSectionV2
        actionType={PATCH_APPLICATION_F_REQUEST}
        data={tagsSectionData}
        record={application}
        isFetching={isFetching}
      />
    </div>
  )
}

ApplicationInfo.propTypes = {
  application: PropTypes.object,
  processingDetailsSectionData: PropTypes.array,
  businessInfoSectionData: PropTypes.array,
  businessInfoActions: PropTypes.array,
  processingDetailsActions: PropTypes.array,
  businessPrincipalsDataCards: PropTypes.array,
  tagsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  isPayout: PropTypes.bool,
  businessDescriptionString: PropTypes.string,
  applicationId: PropTypes.string,
  riskProfileSectionData: PropTypes.array,
  riskProfileActions: PropTypes.array,
  platformId: PropTypes.string,
  isFlex: PropTypes.bool,
  isStandaloneUnderwriting: PropTypes.bool,
  processors: PropTypes.array,
  isDisbursementsApplication: PropTypes.bool,
}

export default ApplicationInfo
