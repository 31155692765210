import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'

const frontendInstantPayoutModel = ({ data: instantPayout }) => {
  const [
    id,
    createdAt,
    updatedAt,
    amount,
    applicationId,
    currency,
    failureCode,
    failureMessage,
    instrumentId,
    merchantId,
    settlementId,
    state,
  ] = getMany(instantPayout, [
    'id',
    'created_at',
    'updated_at',
    'amount',
    'application_id',
    'currency',
    'failure_code',
    'failure_message',
    'instrument_id',
    'merchant_id',
    'settlement_id',
    'state',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    displayAmount: formatMoney({ amount, currency }),
    amount,
    applicationId,
    currency,
    failureCode,
    failureMessage,
    instrumentId,
    merchantId,
    settlementId,
    state,
    displayState: snakeCaseToTitleCase({ key: state }),
  })
}

export default frontendInstantPayoutModel
