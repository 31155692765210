import frontendUnderwritingReviewModel from 'utilities/create/models/frontendUnderwritingReviewModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendUnderwritingReviewsModel = ({ data: underwritingReviews }) => {
  const underwritingReviewsModel = map(underwritingReviews, (underwritingReview) => frontendUnderwritingReviewModel({ data: underwritingReview }))

  return keyBy(underwritingReviewsModel, 'id')
}

export default frontendUnderwritingReviewsModel
