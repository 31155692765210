import React from 'react'

const HamburgerMenu = ({
  width = '14',
  height = '14',
  fill = '#020F1C',
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 14 14' fill='none'>
      <path d='M13.625 2.875H0.375C0.15625 2.875 0 2.71875 0 2.5V1.5C0 1.3125 0.15625 1.125 0.375 1.125H13.625C13.8125 1.125 14 1.3125 14 1.5V2.5C14 2.71875 13.8125 2.875 13.625 2.875ZM13.625 7.875H0.375C0.15625 7.875 0 7.71875 0 7.5V6.5C0 6.3125 0.15625 6.125 0.375 6.125H13.625C13.8125 6.125 14 6.3125 14 6.5V7.5C14 7.71875 13.8125 7.875 13.625 7.875ZM13.625 12.875H0.375C0.15625 12.875 0 12.7188 0 12.5V11.5C0 11.3125 0.15625 11.125 0.375 11.125H13.625C13.8125 11.125 14 11.3125 14 11.5V12.5C14 12.7188 13.8125 12.875 13.625 12.875Z' fill={fill} />
    </svg>
  )
}

export default HamburgerMenu
