import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'

const backendPurchaseModel = ({ values }) => {
  const {
    merchantId,
    applicationId,
    amount,
    currency,
    paymentFrequency,
    token,
    entityId,
    entityType,
    firstName,
    lastName,
    email,
    phone,
    billingAddress,
    shippingAddress,
    tags,
    receiptEmails,
    receiptPhoneNumbers,
  } = values

  const [
    billingLine1,
    billingLine2,
    billingCity,
    billingRegion,
    billingPostalCode,
    billingCountry,
  ] = getMany(billingAddress, [
    'line1',
    'line2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  const [
    shippingLine1,
    shippingLine2,
    shippingCity,
    shippingRegion,
    shippingPostalCode,
    shippingCountry,
  ] = getMany(shippingAddress, [
    'line1',
    'line2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  const receiptRequestedDeliveryMethods = filter([
    {
      type: 'EMAIL',
      destinations: map(receiptEmails, ({ value }) => value),
    },
    {
      type: 'SMS',
      destinations: map(receiptPhoneNumbers, ({ value }) => value),
    },
  ], ({ destinations }) => !isEmpty(destinations))

  return removeUndefined({
    merchant_id: merchantId,
    application_id: applicationId,
    amount,
    currency,
    payment_frequency: paymentFrequency,
    token,
    entity_id: entityId,
    entity_type: entityType,
    buyer_details: {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      billing_address: billingAddress ? {
        line1: billingLine1,
        line2: billingLine2,
        city: billingCity,
        region: billingRegion,
        country: billingCountry,
        postal_code: billingPostalCode,
      } : undefined,
      shipping_address: shippingAddress ? {
        line1: shippingLine1,
        line2: shippingLine2,
        city: shippingCity,
        region: shippingRegion,
        country: shippingCountry,
        postal_code: shippingPostalCode,
      } : undefined,
    },
    tags,
    receipt_requested_delivery_methods: !isEmpty(receiptRequestedDeliveryMethods) ? receiptRequestedDeliveryMethods : undefined,
  })
}

export default backendPurchaseModel
