import frontendConfigurationModel from 'utilities/create/models/frontendConfigurationModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendConfigurationsModel = ({ data: configurations }) => {
  const configurationsModels = map(configurations, (configuration) => frontendConfigurationModel({ data: configuration }))

  return keyBy(configurationsModels, 'id')
}

export default frontendConfigurationsModel
