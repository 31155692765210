import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_AUTHORIZATION_COUNT,
  TRANSACTION_MONITORING_AVERAGE_AUTHORIZATION_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_FAILED_AUTHORIZATION_AMOUNT,
  TRANSACTION_MONITORING_FAILED_AUTHORIZATION_COUNT,
  TRANSACTION_MONITORING_FAILED_AUTHORIZATION_RATE,
  TRANSACTION_MONITORING_TOTAL_AUTHORIZATION_AMOUNT,
  TRANSACTION_MONITORING_TOTAL_FAILED_AUTHORIZATION_AMOUNT,
} from 'constants/language/languageConstants'

const FailedAuthorizationCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    authorizationCount,
    failedAuthorizationCount,
    displayAuthorizationAmountDollarsSum,
    displayFailedAuthorizationAmountDollarsSum,
    displayAuthorizationAmountDollarsAverage,
    displayFailedAuthorizationAmountDollarsAverage,
    displayFailedAuthorizationRate,
  ] = getMany(calculations, [
    'authorizationCount',
    'failedAuthorizationCount',
    'displayAuthorizationAmountDollarsSum',
    'displayFailedAuthorizationAmountDollarsSum',
    'displayAuthorizationAmountDollarsAverage',
    'displayFailedAuthorizationAmountDollarsAverage',
    'displayFailedAuthorizationRate',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_FAILED_AUTHORIZATION_COUNT}
        headerData={{
          value: failedAuthorizationCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_FAILED_AUTHORIZATION_AMOUNT}
        headerData={{
          value: displayFailedAuthorizationAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_FAILED_AUTHORIZATION_AMOUNT}
        headerData={{
          value: displayFailedAuthorizationAmountDollarsAverage,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_FAILED_AUTHORIZATION_RATE}
        headerData={{
          value: displayFailedAuthorizationRate,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AUTHORIZATION_COUNT}
        headerData={{
          value: authorizationCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_AUTHORIZATION_AMOUNT}
        headerData={{
          value: displayAuthorizationAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_AUTHORIZATION_AMOUNT}
        headerData={{
          value: displayAuthorizationAmountDollarsAverage,
        }}
      />
    </div>
  )
}

export default FailedAuthorizationCards
