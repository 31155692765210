import TableFooter from './TableFooter'
import React, { Component } from 'react'

class TableFooterC extends Component {
  render () {
    return (
      <TableFooter {...this.props} />
    )
  }
}

export default TableFooterC
