import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateBalanceAdjustmentForm from './CreateBalanceAdjustmentForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import isPatching from 'utilities/is/isPatching'
import showModalAction from 'utilities/actions/showModalAction'
import { BE_BANK_ACCOUNT } from 'constants/paymentInstrumentConstants'
import { ADD_ICON, BANK_ICON } from 'constants/iconConstants'
import { ADD_NEW_BANK_ACCOUNT_LABEL } from 'constants/language/languageConstants'
import { ADD_IDENTITY_BANK_ACCOUNT } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_BALANCE_ADJUSTMENT_FORM } from 'constants/formConstants'
import { GET_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import concat from 'lodash/concat'

import {
  getDisbursementRulesSelector,
  getPaymentInstrumentsSelector,
} from 'state-layer/selectors'

import {
  change,
  formValueSelector,
} from 'redux-form'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const applicationId = get(getCurrentUser(state), 'applicationId')
  const environment = get(credentials, 'environment')
  const identityId = get(props, 'identityId')
  const ownerIdentityPaymentInstruments = getPaymentInstrumentsSelector(state)
  const formSelector = formValueSelector(CREATE_BALANCE_ADJUSTMENT_FORM)
  const existingFormValues = get(state, 'flowsR.createBalanceAdjustment.formValues')
  const instrumentIdField = get(formSelector(state, 'instrumentId'), 'value') || get(existingFormValues, 'instrumentId.value')
  const disbursementRules = getDisbursementRulesSelector(state)
  const applicationBalanceAdjustmentMaxTransactionAmount = get(disbursementRules, 'applicationBalanceAdjustmentsRulesMaxTransactionAmount')

  const filteredOwnerIdentityPaymentInstruments = filter(ownerIdentityPaymentInstruments, ({ instrumentType, identityId: paymentInstrumentIdentityId }) => {
    return instrumentType === BE_BANK_ACCOUNT && paymentInstrumentIdentityId === identityId
  })

  const existingOwnerIdentityPaymentInstruments = map(filteredOwnerIdentityPaymentInstruments, (paymentInstrument) => {
    const {
      id,
      maskedAccountNumber,
      name,
    } = paymentInstrument

    return {
      label: <div className='flex items-center'><i className={`bank-account-icon fa fa-${BANK_ICON}`} /><span className='account-name'>{name}</span> {maskedAccountNumber}</div>,
      value: id,
    }
  })

  const ownerIdentityPaymentInstrumentOptions = concat({
    label: <div className='flex items-center'><span className={`add-icon fa fa-${ADD_ICON}`} /> {ADD_NEW_BANK_ACCOUNT_LABEL}</div>,
    value: 'newBankAccount',
  }, existingOwnerIdentityPaymentInstruments)

  return {
    ownerIdentityPaymentInstrumentOptions,
    isFetching,
    isUpdating: isPatching(state),
    credentials,
    instrumentIdField,
    identityId,
    environment,
    applicationId,
    applicationBalanceAdjustmentMaxTransactionAmount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDisbursementRules: ({ credentials, entityId }) => dispatch(getDisbursementRulesRequest({ credentials, entityId })),
    showAddBankAccountModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_IDENTITY_BANK_ACCOUNT, modalProps })),
    updateCreateBalanceAdjustmentForm: ({ value, field }) => dispatch(change(CREATE_BALANCE_ADJUSTMENT_FORM, field, value)),
    getIdentityPaymentInstruments: ({ identityId, credentials }) => dispatch({
      type: GET_IDENTITY_PAYMENT_INSTRUMENTS_F_REQUEST,
      payload: {
        queries: {
          owner_identity_id: identityId,
        },
        credentials,
      },
    }),
  }
}

class CreateBalanceAdjustmentC extends Component {
  componentDidMount() {
    const {
      getIdentityPaymentInstruments,
      identityId,
      credentials,
      getDisbursementRules,
      applicationId,
    } = this.props

    if (identityId) {
      getIdentityPaymentInstruments({ identityId, credentials })
    }

    if (applicationId) {
      getDisbursementRules({ credentials, entityId: applicationId })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      instrumentIdField,
      showAddBankAccountModal,
      updateCreateBalanceAdjustmentForm,
      getIdentityPaymentInstruments,
      identityId,
      credentials,
      applicationId,
      getDisbursementRules,
    } = this.props

    const {
      instrumentIdField: prevInstrumentIdField,
      identityId: prevIdentityId,
      applicationId: prevApplicationId,
    } = prevProps

    const showNewPaymentInstrumentModal = prevInstrumentIdField !== instrumentIdField && instrumentIdField === 'newBankAccount'

    if (showNewPaymentInstrumentModal) {
      updateCreateBalanceAdjustmentForm({ value: null, field: 'instrumentId' })
      showAddBankAccountModal({
        identityId,
        successFunction: (paymentInstrumentId, newName, newMaskedAccountNumber) => updateCreateBalanceAdjustmentForm({
          value: {
            value: paymentInstrumentId,
            label: <div className='flex items-center'><i className={`bank-account-icon fa fa-${BANK_ICON}`} /><span className='account-name'>{newName}</span> {newMaskedAccountNumber}</div>,
          },
          field: 'instrumentId',
        }),
      })
    }

    if (identityId && identityId !== prevIdentityId) {
      getIdentityPaymentInstruments({ identityId, credentials })
    }

    if (applicationId && applicationId !== prevApplicationId) {
      getDisbursementRules({ credentials, entityId: applicationId })
    }
  }

  render() {
    return (
      <CreateBalanceAdjustmentForm
        {...this.props}
        reactSelectCustomStyles={reactSelectCustomStyles}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBalanceAdjustmentC)
