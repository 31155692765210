import './WelcomeEmailS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import WelcomeEmailFormC from 'components/Admin/Forms/WelcomeEmailForm/WelcomeEmailFormC'

const WelcomeEmail = ({
  isFetching = false,
  welcomeEmail = {},
}) => {
  return (
    <div className='WelcomeEmail'>
      <WelcomeEmailFormC
        isFetching={isFetching}
        welcomeEmail={welcomeEmail}
      />
    </div>
  )
}

WelcomeEmail.propTypes = {
  isFetching: PropTypes.bool,
  welcomeEmail: PropTypes.object,
}

export default WelcomeEmail
