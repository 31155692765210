import axios from 'axios'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import postOnboardingFormFileAPI from 'api/guestDashboard/post/postOnboardingFormFileAPI'
import createErrorResponse from 'utilities/api/createErrorResponse'
import { MM_DD_YYYY_FILE_NAME } from 'constants/timeConstants'
import get from 'lodash/get'
import co from 'co'

function * postOnboardingFormFileO ({
  fileType,
  fileName,
  file,
  id,
  applicationId,
}) {
  const { data: fileData } = yield postOnboardingFormFileAPI({
    id,
    values: {
      linked_to: applicationId,
      display_name: fileName,
      file_type: fileType,
      action_type: 'UPLOAD',
    },
  })

  if (fileData) {
    const fileId = get(fileData, 'id')
    const presignedUrl = get(fileData, 'external_link.url', '')
    const blob = new Blob([file])
    // store file in S3 under a secure name
    const normalizedFileName = `${moment().format(MM_DD_YYYY_FILE_NAME)}_${fileName}_${uuidv4()}`
    const newFile = new File([blob], normalizedFileName)

    axios.put(presignedUrl, newFile, {
      headers: { 'Content-Type': get(file, 'type') },
    })

    return {
      fileId,
      fileName,
      hostedFileName: normalizedFileName,
    }
  }

  return createErrorResponse({ error: true })
}

export default co.wrap(postOnboardingFormFileO)
