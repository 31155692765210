import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SUBMIT_CONTACT_SUPPORT_FORM } from 'constants/amplitudeConstants'
import { POST_CONTACT_SUPPORT_EMAIL_F_REQUEST } from 'constants/flowConstants'
import { CONTACT_SUPPORT_TEMPLATE } from 'constants/emailTemplateConstants'
import get from 'lodash/get'
import { getState } from 'state-layer/configureStore'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getPageName from 'utilities/get/getPageName'


const submitContactSupportForm = (values, dispatch, props) => {
  const credentials = get(props, 'credentials')
  const currentUserEmail = get(props, 'currentUserEmail')

  // get path name for support use
  const origin = get(window, 'location.origin')
  const path = get(window, 'location.pathname')
  const completePathName = origin.concat(path)

  // get page name for amplitude event
  const state = getState()
  const pathName = get(state, 'routing.locationBeforeTransitions.pathname')
  const search = get(state, 'routing.locationBeforeTransitions.search')
  const queries = parseUrlQueries(search)
  const pageName = getPageName(pathName, queries)

  const formValues = {
    email_template_name: CONTACT_SUPPORT_TEMPLATE,
    reply_to_email: currentUserEmail,
    email_template_data: {
      ...values,
      pathName: completePathName,
      currentUserEmail,
    },
  }

  dispatch({
    type: POST_CONTACT_SUPPORT_EMAIL_F_REQUEST,
    payload: {
      values: formValues,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_CONTACT_SUPPORT_FORM, {
        credentials,
        pageName,
        userName: currentUserEmail,
      }),
    },
  })
}

export default submitContactSupportForm
