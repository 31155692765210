import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  TRANSFER_ATTEMPT,
  TRANSFER_ATTEMPTS,
} from 'constants/apiConstants'

const getTransferAttemptAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    path: TRANSFER_ATTEMPT({ transferAttemptId: id }),
    service: TRANSFER_ATTEMPTS,
    credentials,
    meta,
  })
}

export default getTransferAttemptAPI
