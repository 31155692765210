import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import formatEIN from 'utilities/forms/format/formatEIN'
import formatBN from 'utilities/forms/format/formatBN'
import parseTaxId from 'utilities/forms/parse/parseTaxId'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import { ASSOCIATION_ESTATE_TRUST_VALUE } from 'constants/identityConstants'

import {
  BUSINESS_TAX_ID_BN,
  BUSINESS_TAX_ID_BN_HELPER_TEXT,
  BUSINESS_TAX_ID_EIN,
  BUSINESS_TAX_ID_EIN_HELPER_TEXT,
  BUSINESS_TAX_ID_TAN_HELPER_TEXT,
} from 'constants/language/languageConstants'

import {
  USA,
  CAN,
} from 'constants/countryConstants'

const helperTextMap = (country, businessType) => {
  switch (country) {
    case CAN:
      switch (businessType) {
        case ASSOCIATION_ESTATE_TRUST_VALUE:
          return BUSINESS_TAX_ID_TAN_HELPER_TEXT

        default:
          return BUSINESS_TAX_ID_BN_HELPER_TEXT
      }

    default:
      return BUSINESS_TAX_ID_EIN_HELPER_TEXT
  }
}

const formatterMap = (country, businessType) => {
  switch (country) {
    case CAN:
      switch (businessType) {
        case ASSOCIATION_ESTATE_TRUST_VALUE:
          return formatBN

        default:
          return formatNumberField
      }

    default:
      return formatEIN
  }
}

const placeholderMap = (country, businessType) => {
  switch (country) {
    case CAN:
      switch (businessType) {
        case ASSOCIATION_ESTATE_TRUST_VALUE:
          return 'T12345678'

        default:
          return '123456789'
      }

    default:
      return '12-3456789'
  }
}

const labelMap = (country) => {
  switch (country) {
    case CAN:
      return BUSINESS_TAX_ID_BN

    default:
      return BUSINESS_TAX_ID_EIN
  }
}

const BusinessTaxIdField = ({
  name = 'businessTaxId',
  country = USA,
  businessType,
  required = true,
}) => {
  return (
    <Field
      name={name}
      label={labelMap(country)}
      helperText={helperTextMap(country, businessType)}
      component={InputField}
      format={formatterMap(country, businessType)}
      parse={parseTaxId}
      placeholder={placeholderMap(country, businessType)}
      required={required}
    />
  )
}

BusinessTaxIdField.propTypes = {
  name: PropTypes.string,
  country: PropTypes.string,
  businessType: PropTypes.string,
  required: PropTypes.bool,
}

export default BusinessTaxIdField
