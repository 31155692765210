import './EditIdentityProcessingInformationFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import submitEditIdentityProcessingInformationForm from 'utilities/submit/submitEditIdentityProcessingInformationForm'
import validateEditIdentityProcessingInformationForm from 'utilities/validate/validateEditIdentityProcessingInformationForm'
import warnEditIdentityProcessingInformationForm from 'utilities/warn/warnEditIdentityProcessingInformationForm'
import { IDENTITY_PROCESSING_INFO_FORM } from 'constants/formConstants'

import {
  CANCEL,
  CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP,
  CREATE_IDENTITY_MCC_CODE_TOOLTIP,
  CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP,
  MAX_ACH_TRANSACTION_AMOUNT_LABEL,
  MAX_CARD_TRANSACTION_AMOUNT_LABEL,
  MERCHANT_CATEGORY_CODE,
  SAVE,
  TAX_AUTHORITY,
} from 'constants/language/languageConstants'

const EditIdentityProcessingInformationForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  showTaxAuthority = false,
  isRolePartnerCredential = false,
  mccListForModal = [],
}) => {
  return (
    <form className='EditIdentityProcessingInformationForm' onSubmit={handleSubmit(submitEditIdentityProcessingInformationForm)}>
      <div className='form-content'>
        { !isRolePartnerCredential && (
          <Field
            name='mcc'
            label={MERCHANT_CATEGORY_CODE}
            tooltip={CREATE_IDENTITY_MCC_CODE_TOOLTIP}
            component={ReactSelect}
            options={mccListForModal}
            maxMenuHeight={200}
          />
        )}

        { showTaxAuthority && (
          <Field
            name='taxAuthority'
            label={TAX_AUTHORITY}
            placeholder='Required...'
            component={InputField}
          />
        )}

        <AmountField
          name='maxTransactionAmount'
          placeholder='0,00'
          label={MAX_CARD_TRANSACTION_AMOUNT_LABEL}
          tooltip={CREATE_IDENTITY_TRANSACTION_AMOUNT_TOOLTIP}
        />

        <AmountField
          name='achMaxTransactionAmount'
          placeholder='0,00'
          label={MAX_ACH_TRANSACTION_AMOUNT_LABEL}
          tooltip={CREATE_IDENTITY_ACH_TRANSACTION_AMOUNT_TOOLTIP}
        />
      </div>

      <div className='btn-container flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={SAVE} />
      </div>
    </form>
  )
}

EditIdentityProcessingInformationForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  showTaxAuthority: PropTypes.bool,
  isRolePartnerCredential: PropTypes.bool,
  mccListForModal: PropTypes.array,
}

export default reduxForm({
  form: IDENTITY_PROCESSING_INFO_FORM,
  validate: validateEditIdentityProcessingInformationForm,
  warn: warnEditIdentityProcessingInformationForm,
})(EditIdentityProcessingInformationForm)
