import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitEditDashboardAccountNameForm from 'utilities/submit/submitEditDashboardAccountNameForm'
import validateEditDashboardAccountNameForm from 'utilities/validate/validateEditDashboardAccountNameForm'
import { EDIT_DASHBOARD_ACCOUNT_NAME_FORM } from 'constants/formConstants'

import {
  CANCEL,
  DASHBOARD_NAME,
  EDIT_DASHBOARD_ACCOUNT_NAME_MESSAGE,
  UPDATE,
} from 'constants/language/languageConstants'

const EditDashboardAccountNameForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  pristine,
}) => {
  return (
    <form className='EditDashboardAccountNameForm' onSubmit={handleSubmit(submitEditDashboardAccountNameForm)}>
      <div className='message'>{EDIT_DASHBOARD_ACCOUNT_NAME_MESSAGE}</div>

      <Field
        name='dashboardName'
        label={DASHBOARD_NAME}
        component={InputField}
      />

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={UPDATE} disabled={pristine} />
      </div>
    </form>
  )
}

EditDashboardAccountNameForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: EDIT_DASHBOARD_ACCOUNT_NAME_FORM,
  validate: validateEditDashboardAccountNameForm,
})(EditDashboardAccountNameForm)
