import './LiveAccountApplicationBusinessFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import DateFieldset from 'components/Shared/Fieldsets/DateFieldset/DateFieldset'
import AddressC from 'components/Shared/Inputs/Address/AddressC'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import Button from 'components/Shared/Button/Button'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import FormFileUploaderButton from 'components/Shared/Inputs/FormFileUploaderButton/FormFileUploaderButton'
import BusinessTaxIdField from 'components/Shared/Inputs/BusinessTaxIdField/BusinessTaxIdField'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import submitLiveAccountApplicationBusinessInfoForm from 'utilities/submit/submitLiveAccountApplicationBusinessInfoForm'
import validateLiveAccountApplicationBusinessForm from 'utilities/validate/validateLiveAccountApplicationBusinessForm'
import { LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM } from 'constants/formConstants'
import { TAX_DOCUMENT } from 'constants/fileConstants'
import { CAN } from 'constants/countryConstants'

import {
  INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE,
  LIVE_ACCOUNT_BUSINESS_TYPE_OPTIONS,
} from 'constants/identityConstants'

import {
  FILE_CHECK,
  IDENTITY_UPLOADER_ICON,
  IMAGE_FILE_ICON,
} from 'constants/iconConstants'

import {
  TELL_US_ABOUT_YOUR_BUSINESS,
  SAVE_AND_CONTINUE,
  BUSINESS_INFO_DESCRIPTION,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_DESCRIPTION,
  BUSINESS_TYPE,
  OWNERSHIP_TYPE,
  DATE_OF_INCORPORATION,
  EIN_VERIFICATION_DOCUMENT,
  MERCHANT_COUNT,
  WEBSITE,
  SUBDOMAINS,
  EMAIL_ADDRESS,
  ADD_ADDITIONAL_DETAILS_ABOUT_YOUR_BUSINESS,
  ADDITIONAL_BUSINESS_DETAILS_DESCRIPTION,
  BUSINESS_NAME_LOWER_LABEL,
  WEBSITE_LOWER_LABEL,
  SUBDOMAINS_LOWER_LABEL,
  EIN_VERIFICATION_DOCUMENT_LOWER_LABEL,
  BUSINESS_DESCRIPTION_TOOLTIP,
  CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP,
  CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP,
  EMAIL_ADDRESS_TOOLTIP,
  BUSINESS_PHONE_NUMBER_TOOLTIP,
  MERCHANT_COUNT_TOOLTIP,
  REMOVE,
  EXIT_APPLICATION_FORM,
  PLEASE_COMPLETE_STEP_MESSAGE,
  PREVIOUS_STEP,
  BUSINESS_INFORMATION,
  BUSINESS_DBA_LOWER_LABEL,
  BUSINESS_PHONE_NUMBER_LOWER_LABEL,
  BUSINESS_PHONE_NUMBER,
  BUSINESS_ADDRESS,
  TAX_ID_REGISTRATION_DOCUMENT,
  TAX_ID_REGISTRATION_HELPER_TEXT,
} from 'constants/language/languageConstants'

const LiveAccountApplicationBusinessForm = ({
  handleSubmit = () => {},
  ownershipTypeOptions = [],
  isStandaloneMerchant = false,
  showUploadFileModal = () => {},
  removeVerificationFile = () => {},
  einVerificationFileName,
  hideOwnershipTypeDropdown = false,
  einVerificationDocumentErrorMsg = '',
  showExitApplicationFormModal,
  isFormInvalid = false,
  goToPreviousStep,
  dirty = false,
  businessType = '',
  accessFormCountry = '',
  isCanadianSoleProp = false,
}) => {
  return (
    <form className='LiveAccountApplicationBusinessForm' onSubmit={handleSubmit(submitLiveAccountApplicationBusinessInfoForm)}>
      <div className='form-header'>
        <h3>{BUSINESS_INFORMATION}</h3>
      </div>

      <RequiredFieldNotice />
      <div className='form-content'>
        {isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} />}
        <h5>{TELL_US_ABOUT_YOUR_BUSINESS}</h5>
        <div className='sub-title'>{BUSINESS_INFO_DESCRIPTION}</div>

        <div className='primary-business-section'>
          <Field
            name='businessData.businessName'
            label={BUSINESS_NAME}
            component={InputField}
            helperText={BUSINESS_NAME_LOWER_LABEL}
            autoComplete='organization'
          />

          <Field
            name='businessData.doingBusinessAs'
            label={DOING_BUSINESS_AS}
            helperText={BUSINESS_DBA_LOWER_LABEL}
            component={InputField}
          />

          <Field
            name='businessData.businessDescription'
            label={BUSINESS_DESCRIPTION}
            component={TextAreaField}
            tooltip={BUSINESS_DESCRIPTION_TOOLTIP}
            tooltipPosition='right'
            characterLimit={200}
          />

          <Field
            name='businessData.businessType'
            label={BUSINESS_TYPE}
            component={SelectField}
            options={LIVE_ACCOUNT_BUSINESS_TYPE_OPTIONS}
            tooltip={CREATE_IDENTITY_BUSINESS_TYPE_TOOLTIP}
            tooltipPosition='right'
          />

          {!hideOwnershipTypeDropdown && (
          <Field
            name='businessData.businessOwnershipType'
            label={OWNERSHIP_TYPE}
            component={RadioButton}
            options={ownershipTypeOptions}
            tooltip={CREATE_IDENTITY_OWNERSHIP_TYPE_TOOLTIP}
            tooltipPosition='right'
          />
          )}

          <DateFieldset
            name='businessData.businessIncorporationDate'
            label={DATE_OF_INCORPORATION}
          />

          <BusinessTaxIdField
            name='businessData.businessTaxId'
            country={accessFormCountry}
            required={!isCanadianSoleProp}
            businessType={businessType}
          />

          {!einVerificationFileName ? (
            <Field
              component={FormFileUploaderButton}
              fieldName='einVerificationFile'
              name='einVerificationFile'
              label={accessFormCountry === CAN ? TAX_ID_REGISTRATION_DOCUMENT : EIN_VERIFICATION_DOCUMENT}
              helperText={accessFormCountry === CAN ? TAX_ID_REGISTRATION_HELPER_TEXT : EIN_VERIFICATION_DOCUMENT_LOWER_LABEL}
              formName={LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM}
              showUploadFileModal={showUploadFileModal}
              fileIcon={`fal fa-${IMAGE_FILE_ICON}`}
              fileType={TAX_DOCUMENT}
              displayFieldName={EIN_VERIFICATION_DOCUMENT}
              buttonIcon={IDENTITY_UPLOADER_ICON}
              errorMessage={einVerificationDocumentErrorMsg}
              isFormInvalid={isFormInvalid}
              required={businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE}
            />
          ) : (
            <div className='ein-verification-file-uploaded'>
              <div className='label'>{businessType !== INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE ? <div className='flex'>{accessFormCountry === CAN ? TAX_ID_REGISTRATION_DOCUMENT : EIN_VERIFICATION_DOCUMENT} <span className='required-field-string'>*</span></div> : accessFormCountry === CAN ? TAX_ID_REGISTRATION_DOCUMENT : EIN_VERIFICATION_DOCUMENT}</div>
              <div className='lower-label'>{accessFormCountry === CAN ? TAX_ID_REGISTRATION_HELPER_TEXT : EIN_VERIFICATION_DOCUMENT_LOWER_LABEL}</div>

              <div className='file-name-area flex space-between'>
                <div><i className={`file-check-icon fa fa-${FILE_CHECK}`} />{einVerificationFileName}</div>
                <div className='remove-action' onClick={removeVerificationFile}>{REMOVE}</div>
              </div>
            </div>
          )}

          {!isStandaloneMerchant && (
          <Field
            name='businessData.businessMerchantCount'
            label={MERCHANT_COUNT}
            component={InputField}
            tooltip={MERCHANT_COUNT_TOOLTIP}
            tooltipPosition='right'
            required={businessType === INDIVIDUAL_SOLE_PROPRIETORSHIP_VALUE ? false : true}
          />
          )}
        </div>

        <div className='additional-business-details-section'>
          <h5>{ADD_ADDITIONAL_DETAILS_ABOUT_YOUR_BUSINESS}</h5>
          <div className='sub-title'>{ADDITIONAL_BUSINESS_DETAILS_DESCRIPTION}</div>

          <div className=''>
            <Field
              name='businessData.businessWebsite'
              label={WEBSITE}
              component={InputField}
              helperText={WEBSITE_LOWER_LABEL}
              autoComplete='url'
            />

            <Field
              name='businessData.businessWebsiteDomains'
              label={SUBDOMAINS}
              component={InputField}
              helperText={SUBDOMAINS_LOWER_LABEL}
              placeholder='app.company.com, store.company.com'
            />

            <Field
              name='businessData.businessEmail'
              label={EMAIL_ADDRESS}
              component={InputField}
              placeholder='contact@company.com'
              tooltip={EMAIL_ADDRESS_TOOLTIP}
              tooltipPosition='right'
              autoComplete='email'
            />

            <Field
              name='businessData.businessPhone'
              label={BUSINESS_PHONE_NUMBER}
              component={InputField}
              autofill={false}
              placeholder='000-000-0000'
              format={formatPhone}
              parse={parsePhone}
              tooltip={BUSINESS_PHONE_NUMBER_TOOLTIP}
              helperText={BUSINESS_PHONE_NUMBER_LOWER_LABEL}
              tooltipPosition='right'
              autoComplete='tel-national'
            />

            <AddressC
              name='businessData.businessAddress'
              label={BUSINESS_ADDRESS}
              form={LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM}
              presetCountry={accessFormCountry}
            />
          </div>
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          {goToPreviousStep && (
          <div className='previous-step-container'>
            <Button onClick={() => goToPreviousStep({ isFormDirty: dirty })} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          )}
          <div className='exit-and-save-container'>
            {showExitApplicationFormModal && <Button onClick={showExitApplicationFormModal} label={EXIT_APPLICATION_FORM} variant='ghost' size='large' />}
            <Button type='submit' label={SAVE_AND_CONTINUE} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

LiveAccountApplicationBusinessForm.propTypes = {
  handleSubmit: PropTypes.func,
  ownershipTypeOptions: PropTypes.array,
  isStandaloneMerchant: PropTypes.bool,
  showUploadFileModal: PropTypes.func,
  einVerificationFileName: PropTypes.string,
  removeVerificationFile: PropTypes.func,
  hideOwnershipTypeDropdown: PropTypes.bool,
  einVerificationDocumentErrorMsg: PropTypes.string,
  showExitApplicationFormModal: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  goToPreviousStep: PropTypes.func,
  dirty: PropTypes.bool,
  businessType: PropTypes.string,
  accessFormCountry: PropTypes.string,
  isCanadianSoleProp: PropTypes.bool,
}

export default reduxForm({
  form: LIVE_ACCOUNT_APPLICATION_BUSINESS_FORM,
  validate: validateLiveAccountApplicationBusinessForm,
})(LiveAccountApplicationBusinessForm)
