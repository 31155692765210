import './DisputeCaseDetailsS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Shared/Loader/Loader'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import DisputeEvidencesSectionC from 'components/Customer/Pages/Dispute/DisputeEvidencesSection/DisputeEvidencesSectionC'
import DisputeEvidencesSectionTable from 'components/Customer/Pages/Dispute/DisputeEvidencesSection/DisputeEvidencesSectionTable'
import isEmpty from 'lodash/isEmpty'

import {
  ACTION_REQUIRED,
  DISPUTE_CASE_DETAILS_EMPTY_MESSAGE,
  SHOW_LESS_LABEL,
  SHOW_MORE_LABEL,
  SUBMITTED_FILES,
  UPLOADED_FILES,
} from 'constants/language/languageConstants'

const DisputeCaseDetails = ({
  title,
  respondWithinString,
  disputeId,
  disputeAmount,
  isNeedsResponseDispute = false,
  isSubmittedDispute = false,
  className = '',
  reason = '',
  message = '',
  isOverflowing,
  isShowingMore,
  toggleShowMore = () => {},
  toggleShowMoreAction = () => {},
  disputeEvidenceFiles = {},
  isFetching = false,
  isFetchingEvidences = false,
  isVantivDispute = false,
  action = '',
  actionMessageIsOverflowing = false,
  actionMessageIsShowingMore = false,
}) => {
  const noDataAvailable = !reason && !message
  const showDisputeEvidencesSection = isNeedsResponseDispute && !isSubmittedDispute && !isVantivDispute

  return (
    <>
      {isFetching && <Loader /> }

      {!isFetching && (
      <PageSectionV2C
        isFetching={isFetching}
        title={<h5 className='dispute-case-title'>{title}</h5>}
        className={`dispute-case-details-highlight ${className}`}
        rightTitle={isNeedsResponseDispute && !isSubmittedDispute ? respondWithinString : null}
      >
        {noDataAvailable ?
          <span className='empty-message'>{ DISPUTE_CASE_DETAILS_EMPTY_MESSAGE}</span>
          : (
            <div className='highlight-information'>
              <h6 className='reason-string'>{message ? `${reason}` : reason}</h6>
              <div className='reason-container'>
                <span className='sub-title title'>
                  <span className='dispute-message dispute-reason-message-line-clamp flex p-1'>{message}</span>
                  {isOverflowing && !isShowingMore && (
                    <span
                      className='show-more'
                      id='show-more'
                      onClick={toggleShowMore}
                    >({(SHOW_MORE_LABEL)})
                    </span>
                  )}
                </span>
                {isShowingMore && (
                  <span
                    className='show-less'
                    id='show-less'
                    onClick={toggleShowMore}
                  >({(SHOW_LESS_LABEL)})
                  </span>
                )}
              </div>

              <div className='action-container'>
                {!isEmpty(action) && (
                  <>
                    <h6 className='action-item'>{ACTION_REQUIRED}</h6>
                    <span className='action-message line-clamp p-1'>{action}</span>
                    {actionMessageIsOverflowing && !actionMessageIsShowingMore && (
                      <span
                        className='show-more-action'
                        id='show-more-action'
                        onClick={toggleShowMoreAction}
                      >
                        ({SHOW_MORE_LABEL})
                      </span>
                    )}

                    {actionMessageIsShowingMore && (
                      <span
                        className='show-less-action'
                        id='show-less-action'
                        onClick={toggleShowMoreAction}
                      >
                        ({SHOW_LESS_LABEL})
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

        {showDisputeEvidencesSection && (
        <DisputeEvidencesSectionC
          disputeId={disputeId}
          disputeAmount={disputeAmount}
          disputeEvidenceFiles={disputeEvidenceFiles}
          isFetching={isFetching}
          isFetchingEvidences={isFetchingEvidences}
        />
        )}

        {!showDisputeEvidencesSection && !isEmpty(disputeEvidenceFiles) && !isVantivDispute && (
          <DisputeEvidencesSectionTable
            title={isSubmittedDispute ? SUBMITTED_FILES : UPLOADED_FILES}
            disputeEvidenceFiles={disputeEvidenceFiles}
            isFetchingEvidences={isFetchingEvidences}
            classNames='responded-dispute-table'
          />
        )}
      </PageSectionV2C>
      )}
    </>
  )
}

DisputeCaseDetails.propTypes = {
  title: PropTypes.string,
  respondWithinString: PropTypes.string,
  disputeId: PropTypes.string,
  isNeedsResponseDispute: PropTypes.bool,
  className: PropTypes.string,
  reason: PropTypes.string,
  message: PropTypes.string,
  isOverflowing: PropTypes.bool,
  isShowingMore: PropTypes.bool,
  toggleShowMore: PropTypes.func,
  toggleShowMoreAction: PropTypes.func,
  disputeAmount: PropTypes.string,
  disputeEvidenceFiles: PropTypes.object,
  isSubmittedDispute: PropTypes.bool,
  isFetching: PropTypes.bool,
  isVantivDispute: PropTypes.bool,
  action: PropTypes.string,
  actionMessageIsOverflowing: PropTypes.bool,
  actionMessageIsShowingMore: PropTypes.bool,
}

export default DisputeCaseDetails
