import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import redirectRequest from 'utilities/actions/redirectRequest'
import { CREATE_CUSTOM_ROLE } from 'constants/amplitudeConstants'
import { entityTypeToRoleMap } from 'constants/memberConstants'
import { COMPANY_PATH } from 'constants/pathConstants'
import { POST_ROLE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import keys from 'lodash/keys'
import omitBy from 'lodash/omitBy'
import forEach from 'lodash/forEach'
import concat from 'lodash/concat'

import {
  ALL_PERMISSIONS_LIST,
  PERMISSIONS_FOR_ALL,
} from 'constants/permissionConstants'

const submitDuplicateRoleForm = (values, dispatch, props) => {
  const [
    credentials,
    entityId,
    entityType,
  ] = getMany(props, [
    'credentials',
    'entityId',
    'entityType',
  ])

  const authDomainRole = get(entityTypeToRoleMap, entityType)

  const [
    roleDescription,
    roleName,
    selectedEvents,
  ] = getMany(values, [
    'roleDescription',
    'roleName',
    'selectedEvents',
  ])

  let permissions = PERMISSIONS_FOR_ALL
  forEach(selectedEvents, (event) => {
    const truthySelectedEvents = omitBy(event, (permission) => !permission)
    const selectedEventsPerGroup = keys(truthySelectedEvents)
    forEach(selectedEventsPerGroup, (eventLabel) => {
      const permissionsPerEvent = get(ALL_PERMISSIONS_LIST, [eventLabel, 'permissions'])
      permissions = concat(permissions, permissionsPerEvent)
    })
  })

  dispatch({
    type: POST_ROLE_F_REQUEST,
    payload: {
      values: {
        permissions,
        name: roleName,
        description: roleDescription,
        entity_id: entityId,
        auth_role: authDomainRole,
      },
      credentials,
    },
    meta: {
      successCallback: () => {
        const credentialId = get(credentials, 'id')
        const teamPath = `${COMPANY_PATH({ credentialId })}?tab=team&secondaryTab=roles`

        dispatch(redirectRequest({ path: teamPath }))
      },
      actionId: sendAmplitudeActionEvent(CREATE_CUSTOM_ROLE, {
        credentials,
        roleName,
        roleDescription,
        entityId,
        permissions,
      }),
    },
  })
}

export default submitDuplicateRoleForm
