import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasSubmitFailed, isInvalid } from 'redux-form'
import UpdateBalanceAdjustmentsVelocityLimitsForm from './UpdateBalanceAdjustmentsVelocityLimitsForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getApplicationRequest from 'utilities/actions/get/getApplicationRequest'
import convertMilitaryHourToRegularHour from 'utilities/convert/convertMilitaryHourToRegularHour'
import getDisbursementRulesRequest from 'utilities/actions/get/getDisbursementRulesRequest'
import getMany from 'utilities/get/getMany'
import { UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import toString from 'lodash/toString'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'

import {
  DAILY,
  MONTHLY,
} from 'constants/payoutSettingsConstants'

import {
  AM,
  AMERICA_NY_TIMEZONE,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

import {
  getApplicationSelector,
  getDisbursementRulesSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetchingDisbursementRules = get(state, `disbursementRulesR.${FETCHING}`, true)
  const isFetchingApplication = get(state, `applicationsR.${FETCHING}`)
  const applicationId = get(props, 'applicationId')
  const application = getApplicationSelector(state, applicationId)
  const businessName = get(application, 'businessName')
  const disbursementRules = getDisbursementRulesSelector(state)
  const disbursementRulesId = get(disbursementRules, 'id')
  const formValues = get(props, 'formValues.balanceAdjustmentsVelocityLimits')
  const isFormInvalid = isInvalid(UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM)(state) && hasSubmitFailed(UPDATE_BALANCE_ADJUSTMENTS_VELOCITY_LIMITS_FORM)(state)

  const [
    formValueMaxTransactionAmountForACH,
    formValueStartTime,
    formValueTimeMeridian,
    formValueDailyACHCount,
    formValueDailyACHVolume,
    formValueMonthlyStartDate,
    formValueMonthlyACHCount,
    formValueMonthlyACHVolume,
  ] = getMany(formValues, [
    'maxTransactionAmountForACH',
    'startTime.value',
    'timeMeridian.value',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyACHCount',
    'monthlyACHVolume',
  ])

  const maxTransactionAmountForACH = get(disbursementRules, 'applicationBalanceAdjustmentsRulesMaxTransactionAmount')
  const rulesSet = get(disbursementRules, 'applicationBalanceAdjustmentRules')
  const dailyRulesSet = get(filter(rulesSet, (set) => isEqual(get(set, 'timeFrame'), DAILY)), '[0]')
  const monthlyRulesSet = get(filter(rulesSet, (set) => isEqual(get(set, 'timeFrame'), MONTHLY)), '[0]')

  const [
    startTime,
    timeMeridian,
    dailyACHCount,
    dailyACHVolume,
  ] = getMany(dailyRulesSet, [
    'startTime',
    'startTimeMeridian',
    'rules[0].countLimit',
    'rules[0].displayVolumeLimit',
  ])

  const [
    monthlyACHCount,
    monthlyACHVolume,
    monthlyStartDate,
  ] = getMany(monthlyRulesSet, [
    'rules[0].countLimit',
    'rules[0].displayVolumeLimit',
    'startDate',
  ])

  const normalizedStartTimeValue = (formValueStartTime || startTime) ? convertMilitaryHourToRegularHour({ militaryHour: formValueStartTime || startTime }) : ''

  const initialValues = isFetchingDisbursementRules ? undefined : {
    startTime: ({ label: toString(normalizedStartTimeValue) || toString(normalizedStartTimeValue) || 'Select...', value: normalizedStartTimeValue }),
    timeZone: ({ label: AMERICA_NY_TIMEZONE, value: AMERICA_NY_TIMEZONE_BE_FIELD }),
    timeMeridian: ({ label: formValueTimeMeridian || timeMeridian || AM, value: formValueTimeMeridian || timeMeridian || AM }),
    dailyACHVolume: formValueDailyACHVolume || dailyACHVolume,
    dailyACHCount: formValueDailyACHCount || dailyACHCount,
    monthlyStartDate: ({ label: toString(formValueMonthlyStartDate) || toString(monthlyStartDate) || 'Select...', value: formValueMonthlyStartDate || monthlyStartDate || '' }),
    maxTransactionAmountForACH: formValueMaxTransactionAmountForACH || maxTransactionAmountForACH,
    monthlyACHCount: formValueMonthlyACHCount || monthlyACHCount,
    monthlyACHVolume: formValueMonthlyACHVolume || monthlyACHVolume,
  }

  let subtitle = ''
  if (applicationId) {
    subtitle = `Set limits for adding funds to ${businessName}.`
  }

  return {
    credentials,
    credentialId,
    applicationId,
    subtitle,
    initialValues,
    isFormInvalid,
    disbursementRulesId,
    isFetchingApplication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplication: ({ credentials, applicationId }) => dispatch(getApplicationRequest({ credentials, applicationId })),
    getDisbursementRules: ({ entityId, credentials }) => dispatch(getDisbursementRulesRequest({ entityId, credentials })),
  }
}

class UpdateBalanceAdjustmentsVelocityLimitsFormC extends Component {
  componentDidMount() {
    const {
      credentials,
      applicationId,
      getApplication,
      getDisbursementRules,
    } = this.props

    if (applicationId) {
      getApplication({
        credentials,
        applicationId,
      })
    }

    if (applicationId) {
      getDisbursementRules({
        credentials,
        entityId: applicationId,
      })
    }
  }

  render() {
    return (
      <UpdateBalanceAdjustmentsVelocityLimitsForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBalanceAdjustmentsVelocityLimitsFormC)
