import './MerchantSettingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import submitMerchantSettingForm from 'utilities/submit/submitMerchantSettingForm'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import { MERCHANT_SETTING_FORM } from 'constants/formConstants'

import {
  MASTERCARD_ASSIGNED_ID,
  SAVE,
  VISA_MERCHANT_VERIFICATION_VALUE,
  SURCHARGES_ENABLED,
  RENT_SURCHARGES_ENABLED,
  CONVENIENCE_CHARGES_ENABLED,
  CARD_CVV_REQUIRED,
  SETTLEMENTS_ENABLED,
  PROCESSING_ENABLED,
  MERCHANT,
} from 'constants/language/languageConstants'

const MerchantSettingForm = ({
  handleSubmit = () => {},
  submitting = false,
  isRolePlatformCred = false,
  showOptionalMerchantIdentifierFields = false,
}) => {
  return (
    <form className='MerchantSettingForm' onSubmit={handleSubmit(submitMerchantSettingForm)}>
      <div className='section'>
        <Field
          name='id'
          label={MERCHANT}
          component={InputField}
          disabled
        />

        {isRolePlatformCred && (
          <ToggleSwitchC
            name='processingEnabled'
            label={PROCESSING_ENABLED}
            form={MERCHANT_SETTING_FORM}
          />
        )}

        {isRolePlatformCred && (
          <ToggleSwitchC
            name='settlementEnabled'
            label={SETTLEMENTS_ENABLED}
            form={MERCHANT_SETTING_FORM}
          />
        )}

        <ToggleSwitchC
          name='cardCvvRequired'
          label={CARD_CVV_REQUIRED}
          form={MERCHANT_SETTING_FORM}
        />

        <ToggleSwitchC
          name='levelTwoLevelThreeDataEnabled'
          label='Level 2/Level 3 Data Enabled'
          form={MERCHANT_SETTING_FORM}
        />

        <ToggleSwitchC
          name='convenienceChargesEnabled'
          label={CONVENIENCE_CHARGES_ENABLED}
          form={MERCHANT_SETTING_FORM}
        />

        {/* <ToggleSwitchC */}
        {/*  name='cardExpirationDateRequired' */}
        {/*  label='Card Expiration Date Required' */}
        {/*  form={MERCHANT_SETTING_FORM} */}
        {/* /> */}


        <ToggleSwitchC
          name='rentSurchargesEnabled'
          label={RENT_SURCHARGES_ENABLED}
          form={MERCHANT_SETTING_FORM}
        />

        {isRolePlatformCred && (
        <ToggleSwitchC
          name='surchargesEnabled'
          label={SURCHARGES_ENABLED}
          form={MERCHANT_SETTING_FORM}
        />
        )}

        {showOptionalMerchantIdentifierFields && (
        <>
          <Field
            name='visaMerchantVerificationValue'
            label={VISA_MERCHANT_VERIFICATION_VALUE}
            component={InputField}
            required={false}
            format={formatNumberField}
          />

          <Field
            name='mastercardAssignedId'
            label={MASTERCARD_ASSIGNED_ID}
            component={InputField}
            required={false}
            format={formatNumberField}
          />
        </>
        )}

        {/* <Field */}
        {/*  name='settlementFundingIdentifier' */}
        {/*  label='Deposit Statement Descriptor' */}
        {/*  component={SelectField} */}
        {/*  options={SETTLEMENT_FUNDING_IDENTIFIER_OPTIONS} */}
        {/* /> */}
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' className='save-merchant-setting-button' submitting={submitting} label={SAVE} />
      </div>
    </form>
  )
}

MerchantSettingForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isRolePlatformCred: PropTypes.bool,
  showOptionalMerchantIdentifierFields: PropTypes.bool,
}

export default MerchantSettingForm
