import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import FileUploadFormC from 'components/Customer/Forms/FileUploadForm/FileUploadFormC'

const FileUploadModal = ({
  merchantId,
  identityId,
  credentials,
  merchant,
  identityMerchants,
  uploadForIdentity = false,
  redirectToFilesTab = false,
}) => {
  return (
    <GenericModal
      title='Upload a File'
      className='FileUploadModal'
      Component={FileUploadFormC}
      submitLabel='Upload File'
      merchantId={merchantId}
      identityId={identityId}
      credentials={credentials}
      merchant={merchant}
      identityMerchants={identityMerchants}
      uploadForIdentity={uploadForIdentity}
      redirectToFilesTab={redirectToFilesTab}
    />
  )
}

FileUploadModal.propTypes = {
  merchantId: PropTypes.string,
  identityId: PropTypes.string,
  credentials: PropTypes.object,
  merchant: PropTypes.object,
  identityMerchants: PropTypes.array,
  uploadForIdentity: PropTypes.bool,
  redirectToFilesTab: PropTypes.bool,
}

export default FileUploadModal
