import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import CheckboxGroupField from 'components/Shared/Inputs/CheckboxGroupField/CheckboxGroupField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import CustomFieldArray from 'components/Shared/Inputs/CustomFieldArray/CustomFieldArray'
import validateComplianceValidationAndAttestationDetailsForm from 'utilities/validate/validateComplianceValidationAndAttestationDetailsForm'
import { COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM } from 'constants/formConstants'

import {
  PCI_DSS_VALIDATION_OPTIONS,
  ACKNOWLEDGEMENT_OF_STATUS_OPTIONS,
  YES_NO_YES_NO_RADIO_OPTIONS,
} from 'constants/complianceConstants'

const ComplianceValidationAndAttestationDetailsForm = ({
  handleSubmit = () => {},
  buttonGroup = {},
  pciDssValidation = '',
  doNotUserVendorSuppliedDefaults = '',
  developAndMaintainSecureSystems = '',
  identifyAndAuthenticateAccessToSystemComponents = '',
  restrictPhysicalAccessToCardholder = '',
  maintainAPolicyAddressesInformationSecurity = '',
}) => {
  return (
    <form className='ComplianceValidationAndAttestationDetailsForm' onSubmit={handleSubmit}>
      <div className='section'>
        <h1 className='headerBorder'>Section 3: Validation and Attestation Details</h1>
      </div>

      <div className='section'>
        <div className='subsection'>
          <Field
            name='pciDssValidation'
            label={(
              <div className='description flex column'>
                <span>This AOC is based on results noted in SAQ A (Section 2), dated (SAQ completion date).</span>
                <span>Based on the results documented in the SAQ A noted above, the signatories identified in Parts 3b-3d, as applicable, assert(s) the following compliance status for the entity identified in Part 2 of this document: (check one):</span>
              </div>
            )}
            component={RadioButton}
            options={PCI_DSS_VALIDATION_OPTIONS}
          />

          { pciDssValidation === 'compliant_with_legal_exception' && (
            <Field
              name='compliantWithLegalExceptionDetails'
              component={CustomFieldArray}
              addButtonLabel='Requirement'
              maxFields={2}
              fieldOptions={[
                {
                  label: 'Affected Requirement',
                  value: 'affected_requirement',
                  fieldComponent: InputField,
                },
                {
                  label: 'Details of how legal constraint prevents requirement being met',
                  value: 'details',
                  fieldComponent: InputField,
                },
              ]}
            />
          )}
        </div>

        <div className='subsection'>
          <h4>Part 3a. Acknowledgement of Status</h4>

          <Field
            helperText='Signatory(s) confirms: (Check all that apply)'
            name='acknowledgementOfStatus'
            component={CheckboxGroupField}
            options={ACKNOWLEDGEMENT_OF_STATUS_OPTIONS}
          />
        </div>

        <div className='subsection'>
          <h4>Part 3b. Merchant Attestation</h4>

          <Field
            name='signatureOfMerchantExecutiveOfficer'
            label='Signature of Merchant Executive Officer'
            component={TextAreaField}
            disabled
          />

          <Field
            name='merchantAttestationDate'
            label='Date'
            component={InputField}
            disabled
          />

          <Field
            name='merchantExecutiveOfficerName'
            label='Merchant Executive Officer Name'
            component={InputField}
            disabled
          />

          <Field
            name='merchantExecutiveOfficerTitle'
            label='Title'
            component={InputField}
            disabled
          />
        </div>

        <div className='subsection'>
          <h4>Part 3c. Qualified Security Assessor (QSA) Acknowledgement (if applicable)</h4>

          <Field
            name='qsaInvolvedDescribeRolePerformed'
            label='If a QSA was involved or assisted with this assessment, describe the role performed'
            component={TextAreaField}
          />

          <Field
            name='qsaSignature'
            label='Signature of Duly Authorized Officer of QSA Company'
            component={TextAreaField}
          />

          <Field
            name='qsaDate'
            label='Date'
            component={DatePickerInput}
            autofill={false}
          />

          <Field
            name='qsaDulyAuthorizedOfficerName'
            label='Duly Authorized Officer Name'
            component={InputField}
          />

          <Field
            name='qsaCompany'
            label='QSA Company'
            component={InputField}
          />
        </div>

        <div className='subsection'>
          <h4>Part 3d. Internal Security Assessor (ISA) Involvement (if applicable)</h4>

          <Field
            name='isaInvolvedDescribeRolePerformed'
            label='If a QSA was involved or assisted with this assessment, describe the role performed'
            component={TextAreaField}
          />
        </div>
      </div>

      <div className='section'>
        <h3 className='headerBorder'>Part 4: Action Plan for Non-Compliant Requirements</h3>

        <div className='description flex column'>
          <span>Select the appropriate response for &#34;Compliant to PCI DSS Requirements&#34; for each requirement. If you answer &#34;No&#34; to any of the requirements, you may be required to provide the date your Company expects to be compliant with the requirement and a brief description of the actions being taken to meet the requirement.</span>
          <span>Check with your acquirer or the payment brand(s) before completing Part 4.</span>
        </div>

        <div className='subsection'>
          <h4>PCI DSS Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters.</h4>

          <Field
            name='doNotUserVendorSuppliedDefaults'
            label='Compliant to PCI DSS Requirement?'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { doNotUserVendorSuppliedDefaults === 'NO' && (
            <>
              <Field
                name='doNotUserVendorSuppliedDefaultsDate'
                label='Remediation Date'
                component={DatePickerInput}
                autofill={false}
              />

              <Field
                name='doNotUserVendorSuppliedDefaultsDescription'
                label='Remediation Actions'
                component={TextAreaField}
              />
            </>
          )}
        </div>

        <div className='subsection'>
          <h4>PCI DSS Requirement 6: Develop and maintain secure systems and applications.</h4>

          <Field
            name='developAndMaintainSecureSystems'
            label='Compliant to PCI DSS Requirement?'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { developAndMaintainSecureSystems === 'NO' && (
            <>
              <Field
                name='developAndMaintainSecureSystemsDate'
                label='Remediation Date'
                component={DatePickerInput}
                autofill={false}
              />

              <Field
                name='developAndMaintainSecureSystemsDescription'
                label='Remediation Actions'
                component={TextAreaField}
              />
            </>
          )}
        </div>

        <div className='subsection'>
          <h4>PCI DSS Requirement 8: Identify and authenticate access to system components.</h4>

          <Field
            name='identifyAndAuthenticateAccessToSystemComponents'
            label='Compliant to PCI DSS Requirement?'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { identifyAndAuthenticateAccessToSystemComponents === 'NO' && (
            <>
              <Field
                name='identifyAndAuthenticateAccessToSystemComponentsDate'
                label='Remediation Date'
                component={DatePickerInput}
                autofill={false}
              />

              <Field
                name='identifyAndAuthenticateAccessToSystemComponentsDescription'
                label='Remediation Actions'
                component={TextAreaField}
              />
            </>
          )}
        </div>

        <div className='subsection'>
          <h4>PCI DSS Requirement 9: Restrict physical access to cardholder data.</h4>

          <Field
            name='restrictPhysicalAccessToCardholder'
            label='Compliant to PCI DSS Requirement?'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { restrictPhysicalAccessToCardholder === 'NO' && (
            <>
              <Field
                name='restrictPhysicalAccessToCardholderDate'
                label='Remediation Date'
                component={DatePickerInput}
                autofill={false}
              />

              <Field
                name='restrictPhysicalAccessToCardholderDescription'
                label='Remediation Actions'
                component={TextAreaField}
              />
            </>
          )}
        </div>

        <div className='subsection'>
          <h4>PCI DSS Requirement 12: Maintain a policy that addresses information security for all personnel.</h4>

          <Field
            name='maintainAPolicyAddressesInformationSecurity'
            label='Compliant to PCI DSS Requirement?'
            component={RadioButton}
            options={YES_NO_YES_NO_RADIO_OPTIONS}
          />

          { maintainAPolicyAddressesInformationSecurity === 'NO' && (
            <>
              <Field
                name='maintainAPolicyAddressesInformationSecurityDate'
                label='Remediation Date'
                component={DatePickerInput}
                autofill={false}
              />

              <Field
                name='maintainAPolicyAddressesInformationSecurityDescription'
                label='Remediation Actions'
                component={TextAreaField}
              />
            </>
          )}
        </div>
      </div>

      {buttonGroup}
    </form>
  )
}

ComplianceValidationAndAttestationDetailsForm.propTypes = {
  handleSubmit: PropTypes.func,
  buttonGroup: PropTypes.object,
  pciDssValidation: PropTypes.string,
  doNotUserVendorSuppliedDefaults: PropTypes.string,
  developAndMaintainSecureSystems: PropTypes.string,
  identifyAndAuthenticateAccessToSystemComponents: PropTypes.string,
  restrictPhysicalAccessToCardholder: PropTypes.string,
  maintainAPolicyAddressesInformationSecurity: PropTypes.string,
}

export default reduxForm({
  form: COMPLIANCE_VALIDATION_AND_ATTESTATION_DETAILS_FORM,
  validate: validateComplianceValidationAndAttestationDetailsForm,
})(ComplianceValidationAndAttestationDetailsForm)
