export const invalidEmailsMap = {
'1033edge.com': true,
'11mail.com': true,
'123.com': true,
'123box.net': true,
'123india.com': true,
'123mail.cl': true,
'123qwe.co.uk': true,
'126.com': true,
'150ml.com': true,
'15meg4free.com': true,
'163.com': true,
'1coolplace.com': true,
'1freeemail.com': true,
'1funplace.com': true,
'1internetdrive.com': true,
'1mail.net': true,
'1me.net': true,
'1mum.com': true,
'1musicrow.com': true,
'1netdrive.com': true,
'1nsyncfan.com': true,
'1under.com': true,
'1webave.com': true,
'1webhighway.com': true,
'212.com': true,
'24horas.com': true,
'2911.net': true,
'2bmail.co.uk': true,
'2d2i.com': true,
'2die4.com': true,
'3000.it': true,
'321media.com': true,
'37.com': true,
'3ammagazine.com': true,
'3dmail.com': true,
'3email.com': true,
'3xl.net': true,
'444.net': true,
'4email.com': true,
'4email.net': true,
'4mg.com': true,
'4newyork.com': true,
'4x4man.com': true,
'5iron.com': true,
'5star.com': true,
'88.am': true,
'8848.net': true,
'888.nu': true,
'97rock.com': true,
'aaamail.zzn.com': true,
'aamail.net': true,
'aaronkwok.net': true,
'abbeyroadlondon.co.uk': true,
'abcflash.net': true,
'abdulnour.com': true,
'aberystwyth.com': true,
'abolition-now.com': true,
'about.com': true,
'academycougars.com': true,
'acceso.or.cr': true,
'access4less.net': true,
'accessgcc.com': true,
'ace-of-base.com': true,
'acmecity.com': true,
'acmemail.net': true,
'acninc.net': true,
'adelphia.net': true,
'adexec.com': true,
'adfarrow.com': true,
'adios.net': true,
'ados.fr': true,
'advalvas.be': true,
'aeiou.pt': true,
'aemail4u.com': true,
'aeneasmail.com': true,
'afreeinternet.com': true,
'africamail.com': true,
'agoodmail.com': true,
'ahaa.dk': true,
'aichi.com': true,
'aim.com': true,
'airforce.net': true,
'airforceemail.com': true,
'airmail.net': true,
'airpost.net': true,
'ajacied.com': true,
'ak47.hu': true,
'aknet.kg': true,
'albawaba.com': true,
'alex4all.com': true,
'alexandria.cc': true,
'algeria.com': true,
'alhilal.net': true,
'alibaba.com': true,
'alice.it': true,
'aliceadsl.fr': true,
'alive.cz': true,
'aliyun.com': true,
'allmail.net': true,
'alloymail.com': true,
'allracing.com': true,
'allsaintsfan.com': true,
'alltel.net': true,
'alskens.dk': true,
'altavista.com': true,
'altavista.net': true,
'altavista.se': true,
'alternativagratis.com': true,
'alumnidirector.com': true,
'alvilag.hu': true,
'amele.com': true,
'america.hm': true,
'ameritech.net': true,
'amnetsal.com': true,
'amrer.net': true,
'amuro.net': true,
'amuromail.com': true,
'ananzi.co.za': true,
'ancestry.com': true,
'andylau.net': true,
'anfmail.com': true,
'angelfan.com': true,
'angelfire.com': true,
'animal.net': true,
'animalhouse.com': true,
'animalwoman.net': true,
'anjungcafe.com': true,
'anote.com': true,
'another.com': true,
'anotherwin95.com': true,
'anti-social.com': true,
'antisocial.com': true,
'antongijsen.com': true,
'antwerpen.com': true,
'anymoment.com': true,
'anytimenow.com': true,
'aol.com': true,
'aol.it': true,
'apexmail.com': true,
'apmail.com': true,
'apollo.lv': true,
'approvers.net': true,
'arabia.com': true,
'arabtop.net': true,
'arcademaster.com': true,
'archaeologist.com': true,
'arcor.de': true,
'arcotronics.bg': true,
'argentina.com': true,
'aristotle.org': true,
'army.net': true,
'arnet.com.ar': true,
'artlover.com': true,
'artlover.com.au': true,
'as-if.com': true,
'asean-mail.com': true,
'asheville.com': true,
'asia-links.com': true,
'asia.com': true,
'asiafind.com': true,
'asianavenue.com': true,
'asiancityweb.com': true,
'asiansonly.net': true,
'asianwired.net': true,
'asiapoint.net': true,
'assala.com': true,
'assamesemail.com': true,
'astroboymail.com': true,
'astrolover.com': true,
'astrosfan.com': true,
'astrosfan.net': true,
'asurfer.com': true,
'athenachu.net': true,
'atina.cl': true,
'atl.lv': true,
'atlanticbb.net': true,
'atlaswebmail.com': true,
'atlink.com': true,
'ato.check.com': true,
'atozasia.com': true,
'att.net': true,
'attbi.com': true,
'attglobal.net': true,
'att-global.net': true,
'attymail.com': true,
'au.ru': true,
'ausi.com': true,
'austin.rr.com': true,
'australia.edu': true,
'australiamail.com': true,
'austrosearch.net': true,
'autoescuelanerja.com': true,
'automotiveauthority.com': true,
'avh.hu': true,
'awsom.net': true,
'axoskate.com': true,
'ayna.com': true,
'azimiweb.com': true,
'bachelorboy.com': true,
'bachelorgal.com': true,
'backpackers.com': true,
'backstreet-boys.com': true,
'backstreetboysclub.com': true,
'bagherpour.com': true,
'bangkok.com': true,
'bangkok2000.com': true,
'bannertown.net': true,
'baptistmail.com': true,
'baptized.com': true,
'barcelona.com': true,
'baseballmail.com': true,
'basketballmail.com': true,
'batuta.net': true,
'baudoinconsulting.com': true,
'bboy.zzn.com': true,
'bcpl.net': true,
'bcvibes.com': true,
'beeebank.com': true,
'beenhad.com': true,
'beep.ru': true,
'beer.com': true,
'beethoven.com': true,
'belice.com': true,
'belizehome.com': true,
'bellatlantic.net': true,
'bellnet.ca': true,
'bellsouth.net': true,
'bell-south.net': true,
'berkscounty.com': true,
'berlin.com': true,
'berlin.de': true,
'berlinexpo.de': true,
'bestmail.us': true,
'bestweb.net': true,
'bettergolf.net': true,
'bev.net': true,
'bharatmail.com': true,
'bigassweb.com': true,
'bigblue.net.au': true,
'bigboab.com': true,
'bigfoot.com': true,
'bigfoot.de': true,
'bigger.com': true,
'bigmailbox.com': true,
'bigpond.com': true,
'bigpond.com.au': true,
'bigpond.net.au': true,
'bigramp.com': true,
'bikemechanics.com': true,
'bikeracer.com': true,
'bikeracers.net': true,
'bikerider.com': true,
'billsfan.com': true,
'billsfan.net': true,
'bimamail.com': true,
'bimla.net': true,
'birdowner.net': true,
'bisons.com': true,
'bitmail.com': true,
'bitpage.net': true,
'bizhosting.com': true,
'bla-bla.com': true,
'blackburnmail.com': true,
'blackplanet.com': true,
'blacksburg.net': true,
'blazemail.com': true,
'blazenet.net': true,
'bluehyppo.com': true,
'bluemail.ch': true,
'bluemail.dk': true,
'bluesfan.com': true,
'bluewin.ch': true,
'blueyonder.co.uk': true,
'blushmail.com': true,
'bmlsports.net': true,
'boardermail.com': true,
'boatracers.com': true,
'bol.com.br': true,
'bolando.com': true,
'bollywoodz.com': true,
'bolt.com': true,
'boltonfans.com': true,
'bombdiggity.com': true,
'bonbon.net': true,
'boom.com': true,
'bootmail.com': true,
'bornnaked.com': true,
'bossofthemoss.com': true,
'bostonoffice.com': true,
'bounce.net': true,
'box.az': true,
'boxbg.com': true,
'boxemail.com': true,
'boxfrog.com': true,
'boyzoneclub.com': true,
'bradfordfans.com': true,
'brasilia.net': true,
'brazilmail.com.br': true,
'breathe.com': true,
'bresnan.net': true,
'brfree.com.br': true,
'bright.net': true,
'britneyclub.com': true,
'brittonsign.com': true,
'broadcast.net': true,
'bt.com': true,
'btinternet.com': true,
'btopenworld.co.uk': true,
'buffymail.com': true,
'bullsfan.com': true,
'bullsgame.com': true,
'bumerang.ro': true,
'bunko.com': true,
'buryfans.com': true,
'business-man.com': true,
'businessman.net': true,
'businessweekmail.com': true,
'busta-rhymes.com': true,
'busymail.com': true,
'buyersusa.com': true,
'bvimailbox.com': true,
'byteme.com': true,
'c2i.net': true,
'c3.hu': true,
'c4.com': true,
'cabacabana.com': true,
'cableone.net': true,
'caere.it': true,
'cairomail.com': true,
'cais.net': true,
'callnetuk.com': true,
'callsign.net': true,
'caltanet.it': true,
'camidge.com': true,
'canada-11.com': true,
'canada.com': true,
'canadianmail.com': true,
'canoemail.com': true,
'canwetalk.com': true,
'capu.net': true,
'caramail.com': true,
'care2.com': true,
'careerbuildermail.com': true,
'carioca.net': true,
'cartestraina.ro': true,
'casablancaresort.com': true,
'casino.com': true,
'catcha.com': true,
'catholic.org': true,
'catlover.com': true,
'catsrule.garfield.com': true,
'ccnmail.com': true,
'cd2.com': true,
'celineclub.com': true,
'celtic.com': true,
'centoper.it': true,
'centralpets.com': true,
'centrum.cz': true,
'centrum.sk': true,
'centurytel.net': true,
'cfl.rr.com': true,
'cgac.es': true,
'chaiyomail.com': true,
'chance2mail.com': true,
'chandrasekar.net': true,
'charm.net': true,
'charmedmail.com': true,
'charter.net': true,
'chat.ru': true,
'chattown.com': true,
'chauhanweb.com': true,
'check.com': true,
'check1check.com': true,
'cheerful.com': true,
'chek.com': true,
'chello.nl': true,
'chemist.com': true,
'chequemail.com': true,
'cheyenneweb.com': true,
'chez.com': true,
'chickmail.com': true,
'china.net.vg': true,
'chinalook.com': true,
'chirk.com': true,
'chocaholic.com.au': true,
'christianmail.net': true,
'churchusa.com': true,
'cia-agent.com': true,
'cia.hu': true,
'ciaoweb.it': true,
'cicciociccio.com': true,
'cincinow.net': true,
'citeweb.net': true,
'citlink.net': true,
'city-of-bath.org': true,
'city-of-birmingham.com': true,
'city-of-brighton.org': true,
'city-of-cambridge.com': true,
'city-of-coventry.com': true,
'city-of-edinburgh.com': true,
'city-of-lichfield.com': true,
'city-of-lincoln.com': true,
'city-of-liverpool.com': true,
'city-of-manchester.com': true,
'city-of-nottingham.com': true,
'city-of-oxford.com': true,
'city-of-swansea.com': true,
'city-of-westminster.com': true,
'city-of-westminster.net': true,
'city-of-york.net': true,
'city2city.com': true,
'cityofcardiff.net': true,
'cityoflondon.org': true,
'claramail.com': true,
'classicalfan.com': true,
'classicmail.co.za': true,
'clerk.com': true,
'cliffhanger.com': true,
'close2you.net': true,
'club-internet.fr': true,
'club4x4.net': true,
'clubalfa.com': true,
'clubbers.net': true,
'clubducati.com': true,
'clubhonda.net': true,
'clubvdo.net': true,
'cluemail.com': true,
'cmpmail.com': true,
'cnnsimail.com': true,
'codec.ro': true,
'coder.hu': true,
'coid.biz': true,
'coldmail.com': true,
'collectiblesuperstore.com': true,
'collegebeat.com': true,
'collegeclub.com': true,
'collegemail.com': true,
'colleges.com': true,
'columbus.rr.com': true,
'columbusrr.com': true,
'columnist.com': true,
'comcast.net': true,
'comic.com': true,
'communityconnect.com': true,
'comprendemail.com': true,
'compuserve.com': true,
'computer-freak.com': true,
'computermail.net': true,
'concentric.net': true,
'conexcol.com': true,
'conk.com': true,
'connect4free.net': true,
'connectbox.com': true,
'conok.com': true,
'consultant.com': true,
'cookiemonster.com': true,
'cool.br': true,
'coolgoose.ca': true,
'coolgoose.com': true,
'coolkiwi.com': true,
'coollist.com': true,
'coolmail.com': true,
'coolmail.net': true,
'coolsend.com': true,
'cooooool.com': true,
'cooperation.net': true,
'cooperationtogo.net': true,
'copacabana.com': true,
'cornells.com': true,
'cornerpub.com': true,
'corporatedirtbag.com': true,
'correo.terra.com.gt': true,
'cortinet.com': true,
'cotas.net': true,
'counsellor.com': true,
'countrylover.com': true,
'covad.net': true,
'cox.net': true,
'coxinet.net': true,
'coxmail.com': true,
'cpaonline.net': true,
'cracker.hu': true,
'crazedanddazed.com': true,
'crazysexycool.com': true,
'cristianemail.com': true,
'critterpost.com': true,
'croeso.com': true,
'crosshairs.com': true,
'crosslink.net': true,
'crosswinds.net': true,
'crwmail.com': true,
'cry4helponline.com': true,
'cs.com': true,
'csi.com': true,
'csinibaba.hu': true,
'cuemail.com': true,
'curio-city.com': true,
'cute-girl.com': true,
'cuteandcuddly.com': true,
'cutey.com': true,
'cww.de': true,
'cyber-africa.net': true,
'cyber4all.com': true,
'cyberbabies.com': true,
'cybercafemaui.com': true,
'cyberdude.com': true,
'cyberforeplay.net': true,
'cybergal.com': true,
'cybergrrl.com': true,
'cyberinbox.com': true,
'cyberleports.com': true,
'cybermail.net': true,
'cybernet.it': true,
'cyberspace-asia.com': true,
'cybertrains.org': true,
'cyclefanz.com': true,
'cynetcity.com': true,
'dabsol.net': true,
'dadacasa.com': true,
'daha.com': true,
'dailypioneer.com': true,
'dallas.theboys.com': true,
'dangerous-minds.com': true,
'dansegulvet.com': true,
'data54.com': true,
'daum.net': true,
'davegracey.com': true,
'dawnsonmail.com': true,
'dawsonmail.com': true,
'dazedandconfused.com': true,
'dbzmail.com': true,
'dca.net': true,
'dcemail.com': true,
'deadlymob.org': true,
'deal-maker.com': true,
'dearriba.com': true,
'death-star.com': true,
'dejanews.com': true,
'deliveryman.com': true,
'deltanet.com': true,
'deneg.net': true,
'depechemode.com': true,
'deseretmail.com': true,
'desertmail.com': true,
'desilota.com': true,
'deskmail.com': true,
'deskpilot.com': true,
'destin.com': true,
'detik.com': true,
'deutschland-net.com': true,
'devotedcouples.com': true,
'dfwatson.com': true,
'di-ve.com': true,
'digibel.be': true,
'diplomats.com': true,
'direcway.com': true,
'dirtracer.com': true,
'discofan.com': true,
'discovery.com': true,
'discoverymail.com': true,
'disinfo.net': true,
'dmailman.com': true,
'dmv.com': true,
'dnsmadeeasy.com': true,
'doctor.com': true,
'dog.com': true,
'doglover.com': true,
'dogmail.co.uk': true,
'dogsnob.net': true,
'doityourself.com': true,
'doneasy.com': true,
'donjuan.com': true,
'dontgotmail.com': true,
'dontmesswithtexas.com': true,
'doramail.com': true,
'dostmail.com': true,
'dotcom.fr': true,
'dott.it': true,
'dplanet.ch': true,
'dr.com': true,
'dragoncon.net': true,
'dragracer.com': true,
'dropzone.com': true,
'drotposta.hu': true,
'dslextreme.com': true,
'dubaimail.com': true,
'dublin.com': true,
'dublin.ie': true,
'dunlopdriver.com': true,
'dunloprider.com': true,
'duno.com': true,
'dwp.net': true,
'dygo.com': true,
'dynamitemail.com': true,
'e-apollo.lv': true,
'e-mail.dk': true,
'e-mail.ru': true,
'e-mailanywhere.com': true,
'e-mails.ru': true,
'e-tapaal.com': true,
'earthalliance.com': true,
'earthcam.net': true,
'earthdome.com': true,
'earthling.net': true,
'earthlink.net': true,
'earthonline.net': true,
'eastcoast.co.za': true,
'eastmail.com': true,
'easy.to': true,
'easypost.com': true,
'eatmydirt.com': true,
'ecardmail.com': true,
'ecbsolutions.net': true,
'echina.com': true,
'ecompare.com': true,
'edge.net': true,
'edmail.com': true,
'ednatx.com': true,
'edtnmail.com': true,
'educacao.te.pt': true,
'educastmail.com': true,
'ehmail.com': true,
'eircom.net': true,
'elsitio.com': true,
'elvis.com': true,
'email-london.co.uk': true,
'email.com': true,
'email.cz': true,
'email.ee': true,
'email.it': true,
'email.nu': true,
'email.ro': true,
'email.ru': true,
'email.si': true,
'email.women.com': true,
'email2me.net': true,
'emailacc.com': true,
'emailaccount.com': true,
'emailchoice.com': true,
'emailcorner.net': true,
'emailem.com': true,
'emailengine.net': true,
'emailforyou.net': true,
'emailgroups.net': true,
'emailpinoy.com': true,
'emailplanet.com': true,
'emails.ru': true,
'emailuser.net': true,
'emailx.net': true,
'ematic.com': true,
'embarqmail.com': true,
'emumail.com': true,
'end-war.com': true,
'enel.net': true,
'engineer.com': true,
'england.com': true,
'england.edu': true,
'enter.net': true,
'epatra.com': true,
'epix.net': true,
'epost.de': true,
'eposta.hu': true,
'eqqu.com': true,
'eramail.co.za': true,
'eresmas.com': true,
'eriga.lv': true,
'erols.com': true,
'estranet.it': true,
'ethos.st': true,
'etoast.com': true,
'etrademail.com': true,
'eudoramail.com': true,
'europe.com': true,
'euroseek.com': true,
'every1.net': true,
'everyday.com.kh': true,
'everyone.net': true,
'examnotes.net': true,
'excite.co.jp': true,
'excite.com': true,
'excite.it': true,
'execs.com': true,
'expressasia.com': true,
'extenda.net': true,
'extended.com': true,
'eyou.com': true,
'ezcybersearch.com': true,
'ezmail.egine.com': true,
'ezmail.ru': true,
'ezrs.com': true,
'ezy.net': true,
'f1fans.net': true,
'facebook.com': true,
'fan.com': true,
'fan.theboys.com': true,
'fansonlymail.com': true,
'fantasticmail.com': true,
'farang.net': true,
'faroweb.com': true,
'fastem.com': true,
'fastemail.us': true,
'fastemailer.com': true,
'fastermail.com': true,
'fastimap.com': true,
'fastmail.fm': true,
'fastmailbox.net': true,
'fastmessaging.com': true,
'fatcock.net': true,
'fathersrightsne.org': true,
'fbi-agent.com': true,
'fbi.hu': true,
'fcc.net': true,
'federalcontractors.com': true,
'felicity.com': true,
'felicitymail.com': true,
'femenino.com': true,
'fetchmail.co.uk': true,
'fetchmail.com': true,
'feyenoorder.com': true,
'ffanet.com': true,
'fiberia.com': true,
'fibertel.com.ar': true,
'filipinolinks.com': true,
'financemail.net': true,
'financier.com': true,
'findmail.com': true,
'finebody.com': true,
'finfin.com': true,
'fire-brigade.com': true,
'fishburne.org': true,
'flashcom.net': true,
'flashemail.com': true,
'flashmail.com': true,
'flashmail.net': true,
'flipcode.com': true,
'fmail.co.uk': true,
'fmailbox.com': true,
'fmgirl.com': true,
'fmguy.com': true,
'fnbmail.co.za': true,
'fnmail.com': true,
'folkfan.com': true,
'foodmail.com': true,
'football.theboys.com': true,
'footballmail.com': true,
'for-president.com': true,
'forfree.at': true,
'forpresident.com': true,
'forthnet.gr': true,
'fortuncity.com': true,
'fortunecity.com': true,
'forum.dk': true,
'foxmail.com': true,
'free-org.com': true,
'free.com.pe': true,
'free.fr': true,
'freeaccess.nl': true,
'freeaccount.com': true,
'freeandsingle.com': true,
'freedom.usa.com': true,
'freedomlover.com': true,
'freegates.be': true,
'freeghana.com': true,
'freeler.nl': true,
'freemail.c3.hu': true,
'freemail.com.au': true,
'freemail.com.pk': true,
'freemail.de': true,
'freemail.et': true,
'freemail.gr': true,
'freemail.hu': true,
'freemail.it': true,
'freemail.lt': true,
'freemail.nl': true,
'freemail.org.mk': true,
'freenet.de': true,
'freenet.kg': true,
'freeola.com': true,
'freeola.net': true,
'freeserve.co.uk': true,
'freestamp.com': true,
'freestart.hu': true,
'freesurf.fr': true,
'freesurf.nl': true,
'freeuk.com': true,
'freeuk.net': true,
'freeukisp.co.uk': true,
'freeweb.org': true,
'freewebemail.com': true,
'freeyellow.com': true,
'freezone.co.uk': true,
'fresnomail.com': true,
'friends-cafe.com': true,
'friendsfan.com': true,
'from-africa.com': true,
'from-america.com': true,
'from-argentina.com': true,
'from-asia.com': true,
'from-australia.com': true,
'from-belgium.com': true,
'from-brazil.com': true,
'from-canada.com': true,
'from-china.net': true,
'from-england.com': true,
'from-europe.com': true,
'from-france.net': true,
'from-germany.net': true,
'from-holland.com': true,
'from-israel.com': true,
'from-italy.net': true,
'from-japan.net': true,
'from-korea.com': true,
'from-mexico.com': true,
'from-outerspace.com': true,
'from-russia.com': true,
'from-spain.net': true,
'fromalabama.com': true,
'fromalaska.com': true,
'fromarizona.com': true,
'fromarkansas.com': true,
'fromcalifornia.com': true,
'fromcolorado.com': true,
'fromconnecticut.com': true,
'fromdelaware.com': true,
'fromflorida.net': true,
'fromgeorgia.com': true,
'fromhawaii.net': true,
'fromidaho.com': true,
'fromillinois.com': true,
'fromindiana.com': true,
'fromiowa.com': true,
'fromjupiter.com': true,
'fromkansas.com': true,
'fromkentucky.com': true,
'fromlouisiana.com': true,
'frommaine.net': true,
'frommaryland.com': true,
'frommassachusetts.com': true,
'frommiami.com': true,
'frommichigan.com': true,
'fromminnesota.com': true,
'frommississippi.com': true,
'frommissouri.com': true,
'frommontana.com': true,
'fromnebraska.com': true,
'fromnevada.com': true,
'fromnewhampshire.com': true,
'fromnewjersey.com': true,
'fromnewmexico.com': true,
'fromnewyork.net': true,
'fromnorthcarolina.com': true,
'fromnorthdakota.com': true,
'fromohio.com': true,
'fromoklahoma.com': true,
'fromoregon.net': true,
'frompennsylvania.com': true,
'fromrhodeisland.com': true,
'fromru.com': true,
'fromsouthcarolina.com': true,
'fromsouthdakota.com': true,
'fromtennessee.com': true,
'fromtexas.com': true,
'fromthestates.com': true,
'fromutah.com': true,
'fromvermont.com': true,
'fromvirginia.com': true,
'fromwashington.com': true,
'fromwashingtondc.com': true,
'fromwestvirginia.com': true,
'fromwisconsin.com': true,
'fromwyoming.com': true,
'front.ru': true,
'frontier.com': true,
'frontiernet.net': true,
'frostbyte.uk.net': true,
'fsmail.net': true,
'ftml.net': true,
'fullchannel.net': true,
'fullmail.com': true,
'funkfan.com': true,
'fuorissimo.com': true,
'furnitureprovider.com': true,
'fuse.net': true,
'fut.es': true,
'fwnb.com': true,
'fxsmails.com': true,
'galamb.net': true,
'galaxy5.com': true,
'gamebox.net': true,
'gamegeek.com': true,
'games.com': true,
'gamespotmail.com': true,
'garbage.com': true,
'gardener.com': true,
'gateway.net': true,
'gawab.com': true,
'gaybrighton.co.uk': true,
'gaza.net': true,
'gazeta.pl': true,
'gazibooks.com': true,
'gci.net': true,
'gee-wiz.com': true,
'geecities.com': true,
'geek.com': true,
'geek.hu': true,
'geeklife.com': true,
'general-hospital.com': true,
'geocities.com': true,
'geologist.com': true,
'geopia.com': true,
'gh2000.com': true,
'ghanamail.com': true,
'ghostmail.com': true,
'giantsfan.com': true,
'giga4u.de': true,
'gigileung.org': true,
'givepeaceachance.com': true,
'glay.org': true,
'glendale.net': true,
'globalfree.it': true,
'globalpagan.com': true,
'globalsite.com.br': true,
'globo.com': true,
'globomail.com': true,
'gmail.com': true,
'gmx.at': true,
'gmx.com': true,
'gmx.de': true,
'gmx.fr': true,
'gmx.li': true,
'gmx.net': true,
'gnwmail.com': true,
'go.com': true,
'go.ro': true,
'go.ru': true,
'go2.com.py': true,
'go2net.com': true,
'gocollege.com': true,
'gocubs.com': true,
'gofree.co.uk': true,
'goldenmail.ru': true,
'goldmail.ru': true,
'golfemail.com': true,
'golfmail.be': true,
'gonavy.net': true,
'goodstick.com': true,
'google.com': true,
'googlemail.com': true,
'goplay.com': true,
'gorontalo.net': true,
'gospelfan.com': true,
'gothere.uk.com': true,
'gotmail.com': true,
'gotomy.com': true,
'govolsfan.com': true,
'gportal.hu': true,
'grabmail.com': true,
'graffiti.net': true,
'gramszu.net': true,
'grapplers.com': true,
'gratisweb.com': true,
'grungecafe.com': true,
'gtemail.net': true,
'gti.net': true,
'gtmc.net': true,
'gua.net': true,
'guessmail.com': true,
'guju.net': true,
'gurlmail.com': true,
'guy.com': true,
'guy2.com': true,
'guyanafriends.com': true,
'gyorsposta.com': true,
'gyorsposta.hu': true,
'hackermail.net': true,
'hailmail.net': true,
'hairdresser.net': true,
'hamptonroads.com': true,
'handbag.com': true,
'handleit.com': true,
'hang-ten.com': true,
'hanmail.net': true,
'happemail.com': true,
'happycounsel.com': true,
'happypuppy.com': true,
'hardcorefreak.com': true,
'hawaii.rr.com': true,
'hawaiiantel.net': true,
'headbone.com': true,
'heartthrob.com': true,
'heerschap.com': true,
'heesun.net': true,
'hehe.com': true,
'hello.hu': true,
'hello.net.au': true,
'hello.to': true,
'helter-skelter.com': true,
'hempseed.com': true,
'herediano.com': true,
'heremail.com': true,
'herono1.com': true,
'hetnet.nl': true,
'hey.to': true,
'hhdevel.com': true,
'highmilton.com': true,
'highquality.com': true,
'highveldmail.co.za': true,
'hiphopfan.com': true,
'hispavista.com': true,
'hitthe.net': true,
'hkg.net': true,
'hkstarphoto.com': true,
'hockeymail.com': true,
'hollywoodkids.com': true,
'home-email.com': true,
'home.nl': true,
'home.no.net': true,
'home.ro': true,
'home.se': true,
'homeart.com': true,
'homelocator.com': true,
'homemail.com': true,
'homestead.com': true,
'homeworkcentral.com': true,
'hongkong.com': true,
'hookup.net': true,
'hoopsmail.com': true,
'horrormail.com': true,
'host-it.com.sg': true,
'hot-shot.com': true,
'hot.ee': true,
'hotbot.com': true,
'hotbrev.com': true,
'hotepmail.com': true,
'hotfire.net': true,
'hotletter.com': true,
'hotmail.be': true,
'hotmail.co.il': true,
'hotmail.co.uk': true,
'hotmail.com': true,
'hotmail.com.ar': true,
'hotmail.com.br': true,
'hotmail.com.mx': true,
'hotmail.de': true,
'hotmail.es': true,
'hotmail.fr': true,
'hotmail.it': true,
'hotmail.kg': true,
'hotmail.kz': true,
'hotmail.ru': true,
'hotpop.com': true,
'hotpop3.com': true,
'hotvoice.com': true,
'housefancom': true,
'housemail.com': true,
'hsuchi.net': true,
'html.tou.com': true,
'hughes.net': true,
'hunsa.com': true,
'hush.com': true,
'hushmail.com': true,
'hypernautica.com': true,
'i-connect.com': true,
'i-france.com': true,
'i-mail.com.au': true,
'i-p.com': true,
'i-plus.net': true,
'i.am': true,
'i12.com': true,
'iamawoman.com': true,
'iamwaiting.com': true,
'iamwasted.com': true,
'iamyours.com': true,
'ibm.net': true,
'icestorm.com': true,
'icloud.com': true,
'icmsconsultants.com': true,
'icq.com': true,
'icqmail.com': true,
'icrazy.com': true,
'id-base.com': true,
'ididitmyway.com': true,
'idirect.com': true,
'iespana.es': true,
'ifoward.com': true,
'ig.com.br': true,
'ignazio.it': true,
'ignmail.com': true,
'ihateclowns.com': true,
'ihwy.com': true,
'iinet.net.au': true,
'ijustdontcare.com': true,
'ilovechocolate.com': true,
'ilovejesus.com': true,
'ilovethemovies.com': true,
'ilovetocollect.net': true,
'ilse.nl': true,
'imaginemail.com': true,
'imail.org': true,
'imail.ru': true,
'imailbox.com': true,
'imel.org': true,
'imneverwrong.com': true,
'imposter.co.uk': true,
'imstressed.com': true,
'imtoosexy.com': true,
'in-box.net': true,
'iname.com': true,
'inbox.com': true,
'inbox.net': true,
'inbox.ru': true,
'incamail.com': true,
'incredimail.com': true,
'indexa.fr': true,
'india.com': true,
'indiatimes.com': true,
'indo-mail.com': true,
'indocities.com': true,
'indomail.com': true,
'indyracers.com': true,
'info-media.de': true,
'info66.com': true,
'infohq.com': true,
'infomail.es': true,
'infomart.or.jp': true,
'infospacemail.com': true,
'infovia.com.ar': true,
'inicia.es': true,
'inmail.sk': true,
'innocent.com': true,
'inorbit.com': true,
'insidebaltimore.net': true,
'insight.rr.com': true,
'insurer.com': true,
'integra.net': true,
'interaccess.com': true,
'interburp.com': true,
'interfree.it': true,
'interia.pl': true,
'interlap.com.ar': true,
'intermail.co.il': true,
'internet-club.com': true,
'internet-police.com': true,
'internetbiz.com': true,
'internetdrive.com': true,
'internetegypt.com': true,
'internetemails.net': true,
'internetmailing.net': true,
'internetmci.com': true,
'investormail.com': true,
'inwind.it': true,
'iobox.com': true,
'iobox.fi': true,
'iol.it': true,
'ionet.net': true,
'iowaemail.com': true,
'ip3.com': true,
'iprimus.com.au': true,
'iqemail.com': true,
'irangate.net': true,
'iraqmail.com': true,
'ireland.com': true,
'irj.hu': true,
'isellcars.com': true,
'islamonline.net': true,
'isleuthmail.com': true,
'ismart.net': true,
'isonfire.com': true,
'isp9.net': true,
'itelefonica.com.br': true,
'itloox.com': true,
'itmom.com': true,
'itol.com': true,
'ivebeenframed.com': true,
'ivillage.com': true,
'iwan-fals.com': true,
'iwmail.com': true,
'iwon.com': true,
'izadpanah.com': true,
'jahoopa.com': true,
'jakuza.hu': true,
'japan.com': true,
'jaydemail.com': true,
'jazzandjava.com': true,
'jazzfan.com': true,
'jazzgame.com': true,
'jerusalemmail.com': true,
'jetemail.net': true,
'jewishmail.com': true,
'jippii.fi': true,
'jmail.co.za': true,
'joinme.com': true,
'jokes.com': true,
'jordanmail.com': true,
'journalist.com': true,
'jovem.te.pt': true,
'joymail.com': true,
'jpopmail.com': true,
'jubiimail.dk': true,
'jump.com': true,
'jumpy.it': true,
'juniormail.com': true,
'juno.com': true,
'justemail.net': true,
'justicemail.com': true,
'kaazoo.com': true,
'kaixo.com': true,
'kalpoint.com': true,
'kansascity.com': true,
'kapoorweb.com': true,
'karachian.com': true,
'karachioye.com': true,
'karbasi.com': true,
'katamail.com': true,
'kayafmmail.co.za': true,
'kbjrmail.com': true,
'kcks.com': true,
'keg-party.com': true,
'keko.com.ar': true,
'kellychen.com': true,
'keromail.com': true,
'keyemail.com': true,
'kgb.hu': true,
'khosropour.com': true,
'kickassmail.com': true,
'killermail.com': true,
'kimo.com': true,
'kinki-kids.com': true,
'kittymail.com': true,
'kitznet.at': true,
'kiwibox.com': true,
'kiwitown.com': true,
'kmail.com.au': true,
'konx.com': true,
'korea.com': true,
'kozmail.com': true,
'krongthip.com': true,
'krunis.com': true,
'ksanmail.com': true,
'ksee24mail.com': true,
'kube93mail.com': true,
'kukamail.com': true,
'kumarweb.com': true,
'kuwait-mail.com': true,
'la.com': true,
'ladymail.cz': true,
'lagerlouts.com': true,
'lahoreoye.com': true,
'lakmail.com': true,
'lamer.hu': true,
'land.ru': true,
'lankamail.com': true,
'laposte.net': true,
'latemodels.com': true,
'latinmail.com': true,
'latino.com': true,
'lavabit.com': true,
'law.com': true,
'lawyer.com': true,
'leehom.net': true,
'legalactions.com': true,
'legislator.com': true,
'leonlai.net': true,
'letsgomets.net': true,
'letterbox.com': true,
'levele.com': true,
'levele.hu': true,
'lex.bg': true,
'lexis-nexis-mail.com': true,
'libero.it': true,
'liberomail.com': true,
'lick101.com': true,
'linkmaster.com': true,
'linktrader.com': true,
'linuxfreemail.com': true,
'linuxmail.org': true,
'lionsfan.com.au': true,
'liontrucks.com': true,
'liquidinformation.net': true,
'list.ru': true,
'littleblueroom.com': true,
'live.be': true,
'live.ca': true,
'live.co.uk': true,
'live.com': true,
'live.com.ar': true,
'live.com.au': true,
'live.com.mx': true,
'live.de': true,
'live.fr': true,
'live.it': true,
'live.nl': true,
'liverpoolfans.com': true,
'llandudno.com': true,
'llangollen.com': true,
'lmxmail.sk': true,
'lobbyist.com': true,
'localbar.com': true,
'london.com': true,
'loobie.com': true,
'looksmart.co.uk': true,
'looksmart.com': true,
'looksmart.com.au': true,
'lopezclub.com': true,
'louiskoo.com': true,
'love.com': true,
'love.cz': true,
'loveable.com': true,
'lovelygirl.net': true,
'lovemail.com': true,
'lover-boy.com': true,
'lovergirl.com': true,
'lovingjesus.com': true,
'lowandslow.com': true,
'luso.pt': true,
'luukku.com': true,
'lycos.co.uk': true,
'lycos.com': true,
'lycos.es': true,
'lycos.it': true,
'lycos.ne.jp': true,
'lycosemail.com': true,
'lycosmail.com': true,
'm-a-i-l.com': true,
'm-hmail.com': true,
'm4.org': true,
'mac.com': true,
'macbox.com': true,
'macfreak.com': true,
'machinecandy.com': true,
'macmail.com': true,
'madcreations.com': true,
'madrid.com': true,
'maffia.hu': true,
'magicmail.co.za': true,
'mahmoodweb.com': true,
'mail-awu.de': true,
'mail-box.cz': true,
'mail-center.com': true,
'mail-central.com': true,
'mail-page.com': true,
'mail.austria.com': true,
'mail.az': true,
'mail.be': true,
'mail.bulgaria.com': true,
'mail.byte.it': true,
'mail.co.za': true,
'mail.com': true,
'mail.ee': true,
'mail.entrepeneurmag.com': true,
'mail.freetown.com': true,
'mail.gr': true,
'mail.hitthebeach.com': true,
'mail.kmsp.com': true,
'mail.md': true,
'mail.nu': true,
'mail.org.uk': true,
'mail.pf': true,
'mail.pharmacy.com': true,
'mail.pt': true,
'mail.r-o-o-t.com': true,
'mail.ru': true,
'mail.salu.net': true,
'mail.sisna.com': true,
'mail.spaceports.com': true,
'mail.theboys.com': true,
'mail.usa.com': true,
'mail.vasarhely.hu': true,
'mail15.com': true,
'mail1st.com': true,
'mail2007.com': true,
'mail2aaron.com': true,
'mail2abby.com': true,
'mail2abc.com': true,
'mail2actor.com': true,
'mail2admiral.com': true,
'mail2adorable.com': true,
'mail2adoration.com': true,
'mail2adore.com': true,
'mail2adventure.com': true,
'mail2aeolus.com': true,
'mail2aether.com': true,
'mail2affection.com': true,
'mail2afghanistan.com': true,
'mail2africa.com': true,
'mail2agent.com': true,
'mail2aha.com': true,
'mail2ahoy.com': true,
'mail2aim.com': true,
'mail2air.com': true,
'mail2airbag.com': true,
'mail2airforce.com': true,
'mail2airport.com': true,
'mail2alabama.com': true,
'mail2alan.com': true,
'mail2alaska.com': true,
'mail2albania.com': true,
'mail2alcoholic.com': true,
'mail2alec.com': true,
'mail2alexa.com': true,
'mail2algeria.com': true,
'mail2alicia.com': true,
'mail2alien.com': true,
'mail2allan.com': true,
'mail2allen.com': true,
'mail2allison.com': true,
'mail2alpha.com': true,
'mail2alyssa.com': true,
'mail2amanda.com': true,
'mail2amazing.com': true,
'mail2amber.com': true,
'mail2america.com': true,
'mail2american.com': true,
'mail2andorra.com': true,
'mail2andrea.com': true,
'mail2andy.com': true,
'mail2anesthesiologist.com': true,
'mail2angela.com': true,
'mail2angola.com': true,
'mail2ann.com': true,
'mail2anna.com': true,
'mail2anne.com': true,
'mail2anthony.com': true,
'mail2anything.com': true,
'mail2aphrodite.com': true,
'mail2apollo.com': true,
'mail2april.com': true,
'mail2aquarius.com': true,
'mail2arabia.com': true,
'mail2arabic.com': true,
'mail2architect.com': true,
'mail2ares.com': true,
'mail2argentina.com': true,
'mail2aries.com': true,
'mail2arizona.com': true,
'mail2arkansas.com': true,
'mail2armenia.com': true,
'mail2army.com': true,
'mail2arnold.com': true,
'mail2art.com': true,
'mail2artemus.com': true,
'mail2arthur.com': true,
'mail2artist.com': true,
'mail2ashley.com': true,
'mail2ask.com': true,
'mail2astronomer.com': true,
'mail2athena.com': true,
'mail2athlete.com': true,
'mail2atlas.com': true,
'mail2atom.com': true,
'mail2attitude.com': true,
'mail2auction.com': true,
'mail2aunt.com': true,
'mail2australia.com': true,
'mail2austria.com': true,
'mail2azerbaijan.com': true,
'mail2baby.com': true,
'mail2bahamas.com': true,
'mail2bahrain.com': true,
'mail2ballerina.com': true,
'mail2ballplayer.com': true,
'mail2band.com': true,
'mail2bangladesh.com': true,
'mail2bank.com': true,
'mail2banker.com': true,
'mail2bankrupt.com': true,
'mail2baptist.com': true,
'mail2bar.com': true,
'mail2barbados.com': true,
'mail2barbara.com': true,
'mail2barter.com': true,
'mail2basketball.com': true,
'mail2batter.com': true,
'mail2beach.com': true,
'mail2beast.com': true,
'mail2beatles.com': true,
'mail2beauty.com': true,
'mail2becky.com': true,
'mail2beijing.com': true,
'mail2belgium.com': true,
'mail2belize.com': true,
'mail2ben.com': true,
'mail2bernard.com': true,
'mail2beth.com': true,
'mail2betty.com': true,
'mail2beverly.com': true,
'mail2beyond.com': true,
'mail2biker.com': true,
'mail2bill.com': true,
'mail2billionaire.com': true,
'mail2billy.com': true,
'mail2bio.com': true,
'mail2biologist.com': true,
'mail2black.com': true,
'mail2blackbelt.com': true,
'mail2blake.com': true,
'mail2blind.com': true,
'mail2blonde.com': true,
'mail2blues.com': true,
'mail2bob.com': true,
'mail2bobby.com': true,
'mail2bolivia.com': true,
'mail2bombay.com': true,
'mail2bonn.com': true,
'mail2bookmark.com': true,
'mail2boreas.com': true,
'mail2bosnia.com': true,
'mail2boston.com': true,
'mail2botswana.com': true,
'mail2bradley.com': true,
'mail2brazil.com': true,
'mail2breakfast.com': true,
'mail2brian.com': true,
'mail2bride.com': true,
'mail2brittany.com': true,
'mail2broker.com': true,
'mail2brook.com': true,
'mail2bruce.com': true,
'mail2brunei.com': true,
'mail2brunette.com': true,
'mail2brussels.com': true,
'mail2bryan.com': true,
'mail2bug.com': true,
'mail2bulgaria.com': true,
'mail2business.com': true,
'mail2buy.com': true,
'mail2ca.com': true,
'mail2california.com': true,
'mail2calvin.com': true,
'mail2cambodia.com': true,
'mail2cameroon.com': true,
'mail2canada.com': true,
'mail2cancer.com': true,
'mail2capeverde.com': true,
'mail2capricorn.com': true,
'mail2cardinal.com': true,
'mail2cardiologist.com': true,
'mail2care.com': true,
'mail2caroline.com': true,
'mail2carolyn.com': true,
'mail2casey.com': true,
'mail2cat.com': true,
'mail2caterer.com': true,
'mail2cathy.com': true,
'mail2catlover.com': true,
'mail2catwalk.com': true,
'mail2cell.com': true,
'mail2chad.com': true,
'mail2champaign.com': true,
'mail2charles.com': true,
'mail2chef.com': true,
'mail2chemist.com': true,
'mail2cherry.com': true,
'mail2chicago.com': true,
'mail2chile.com': true,
'mail2china.com': true,
'mail2chinese.com': true,
'mail2chocolate.com': true,
'mail2christian.com': true,
'mail2christie.com': true,
'mail2christmas.com': true,
'mail2christy.com': true,
'mail2chuck.com': true,
'mail2cindy.com': true,
'mail2clark.com': true,
'mail2classifieds.com': true,
'mail2claude.com': true,
'mail2cliff.com': true,
'mail2clinic.com': true,
'mail2clint.com': true,
'mail2close.com': true,
'mail2club.com': true,
'mail2coach.com': true,
'mail2coastguard.com': true,
'mail2colin.com': true,
'mail2college.com': true,
'mail2colombia.com': true,
'mail2color.com': true,
'mail2colorado.com': true,
'mail2columbia.com': true,
'mail2comedian.com': true,
'mail2composer.com': true,
'mail2computer.com': true,
'mail2computers.com': true,
'mail2concert.com': true,
'mail2congo.com': true,
'mail2connect.com': true,
'mail2connecticut.com': true,
'mail2consultant.com': true,
'mail2convict.com': true,
'mail2cook.com': true,
'mail2cool.com': true,
'mail2cory.com': true,
'mail2costarica.com': true,
'mail2country.com': true,
'mail2courtney.com': true,
'mail2cowboy.com': true,
'mail2cowgirl.com': true,
'mail2craig.com': true,
'mail2crave.com': true,
'mail2crazy.com': true,
'mail2create.com': true,
'mail2croatia.com': true,
'mail2cry.com': true,
'mail2crystal.com': true,
'mail2cuba.com': true,
'mail2culture.com': true,
'mail2curt.com': true,
'mail2customs.com': true,
'mail2cute.com': true,
'mail2cutey.com': true,
'mail2cynthia.com': true,
'mail2cyprus.com': true,
'mail2czechrepublic.com': true,
'mail2dad.com': true,
'mail2dale.com': true,
'mail2dallas.com': true,
'mail2dan.com': true,
'mail2dana.com': true,
'mail2dance.com': true,
'mail2dancer.com': true,
'mail2danielle.com': true,
'mail2danny.com': true,
'mail2darlene.com': true,
'mail2darling.com': true,
'mail2darren.com': true,
'mail2daughter.com': true,
'mail2dave.com': true,
'mail2dawn.com': true,
'mail2dc.com': true,
'mail2dealer.com': true,
'mail2deanna.com': true,
'mail2dearest.com': true,
'mail2debbie.com': true,
'mail2debby.com': true,
'mail2deer.com': true,
'mail2delaware.com': true,
'mail2delicious.com': true,
'mail2demeter.com': true,
'mail2democrat.com': true,
'mail2denise.com': true,
'mail2denmark.com': true,
'mail2dennis.com': true,
'mail2dentist.com': true,
'mail2derek.com': true,
'mail2desert.com': true,
'mail2devoted.com': true,
'mail2devotion.com': true,
'mail2diamond.com': true,
'mail2diana.com': true,
'mail2diane.com': true,
'mail2diehard.com': true,
'mail2dilemma.com': true,
'mail2dillon.com': true,
'mail2dinner.com': true,
'mail2dinosaur.com': true,
'mail2dionysos.com': true,
'mail2diplomat.com': true,
'mail2director.com': true,
'mail2dirk.com': true,
'mail2disco.com': true,
'mail2dive.com': true,
'mail2diver.com': true,
'mail2divorced.com': true,
'mail2djibouti.com': true,
'mail2doctor.com': true,
'mail2doglover.com': true,
'mail2dominic.com': true,
'mail2dominica.com': true,
'mail2dominicanrepublic.com': true,
'mail2don.com': true,
'mail2donald.com': true,
'mail2donna.com': true,
'mail2doris.com': true,
'mail2dorothy.com': true,
'mail2doug.com': true,
'mail2dough.com': true,
'mail2douglas.com': true,
'mail2dow.com': true,
'mail2downtown.com': true,
'mail2dream.com': true,
'mail2dreamer.com': true,
'mail2dude.com': true,
'mail2dustin.com': true,
'mail2dyke.com': true,
'mail2dylan.com': true,
'mail2earl.com': true,
'mail2earth.com': true,
'mail2eastend.com': true,
'mail2eat.com': true,
'mail2economist.com': true,
'mail2ecuador.com': true,
'mail2eddie.com': true,
'mail2edgar.com': true,
'mail2edwin.com': true,
'mail2egypt.com': true,
'mail2electron.com': true,
'mail2eli.com': true,
'mail2elizabeth.com': true,
'mail2ellen.com': true,
'mail2elliot.com': true,
'mail2elsalvador.com': true,
'mail2elvis.com': true,
'mail2emergency.com': true,
'mail2emily.com': true,
'mail2engineer.com': true,
'mail2english.com': true,
'mail2environmentalist.com': true,
'mail2eos.com': true,
'mail2eric.com': true,
'mail2erica.com': true,
'mail2erin.com': true,
'mail2erinyes.com': true,
'mail2eris.com': true,
'mail2eritrea.com': true,
'mail2ernie.com': true,
'mail2eros.com': true,
'mail2estonia.com': true,
'mail2ethan.com': true,
'mail2ethiopia.com': true,
'mail2eu.com': true,
'mail2europe.com': true,
'mail2eurus.com': true,
'mail2eva.com': true,
'mail2evan.com': true,
'mail2evelyn.com': true,
'mail2everything.com': true,
'mail2exciting.com': true,
'mail2expert.com': true,
'mail2fairy.com': true,
'mail2faith.com': true,
'mail2fanatic.com': true,
'mail2fancy.com': true,
'mail2fantasy.com': true,
'mail2farm.com': true,
'mail2farmer.com': true,
'mail2fashion.com': true,
'mail2fat.com': true,
'mail2feeling.com': true,
'mail2female.com': true,
'mail2fever.com': true,
'mail2fighter.com': true,
'mail2fiji.com': true,
'mail2filmfestival.com': true,
'mail2films.com': true,
'mail2finance.com': true,
'mail2finland.com': true,
'mail2fireman.com': true,
'mail2firm.com': true,
'mail2fisherman.com': true,
'mail2flexible.com': true,
'mail2florence.com': true,
'mail2florida.com': true,
'mail2floyd.com': true,
'mail2fly.com': true,
'mail2fond.com': true,
'mail2fondness.com': true,
'mail2football.com': true,
'mail2footballfan.com': true,
'mail2found.com': true,
'mail2france.com': true,
'mail2frank.com': true,
'mail2frankfurt.com': true,
'mail2franklin.com': true,
'mail2fred.com': true,
'mail2freddie.com': true,
'mail2free.com': true,
'mail2freedom.com': true,
'mail2french.com': true,
'mail2freudian.com': true,
'mail2friendship.com': true,
'mail2from.com': true,
'mail2fun.com': true,
'mail2gabon.com': true,
'mail2gabriel.com': true,
'mail2gail.com': true,
'mail2galaxy.com': true,
'mail2gambia.com': true,
'mail2games.com': true,
'mail2gary.com': true,
'mail2gavin.com': true,
'mail2gemini.com': true,
'mail2gene.com': true,
'mail2genes.com': true,
'mail2geneva.com': true,
'mail2george.com': true,
'mail2georgia.com': true,
'mail2gerald.com': true,
'mail2german.com': true,
'mail2germany.com': true,
'mail2ghana.com': true,
'mail2gilbert.com': true,
'mail2gina.com': true,
'mail2girl.com': true,
'mail2glen.com': true,
'mail2gloria.com': true,
'mail2goddess.com': true,
'mail2gold.com': true,
'mail2golfclub.com': true,
'mail2golfer.com': true,
'mail2gordon.com': true,
'mail2government.com': true,
'mail2grab.com': true,
'mail2grace.com': true,
'mail2graham.com': true,
'mail2grandma.com': true,
'mail2grandpa.com': true,
'mail2grant.com': true,
'mail2greece.com': true,
'mail2green.com': true,
'mail2greg.com': true,
'mail2grenada.com': true,
'mail2gsm.com': true,
'mail2guard.com': true,
'mail2guatemala.com': true,
'mail2guy.com': true,
'mail2hades.com': true,
'mail2haiti.com': true,
'mail2hal.com': true,
'mail2handhelds.com': true,
'mail2hank.com': true,
'mail2hannah.com': true,
'mail2harold.com': true,
'mail2harry.com': true,
'mail2hawaii.com': true,
'mail2headhunter.com': true,
'mail2heal.com': true,
'mail2heather.com': true,
'mail2heaven.com': true,
'mail2hebe.com': true,
'mail2hecate.com': true,
'mail2heidi.com': true,
'mail2helen.com': true,
'mail2hell.com': true,
'mail2help.com': true,
'mail2helpdesk.com': true,
'mail2henry.com': true,
'mail2hephaestus.com': true,
'mail2hera.com': true,
'mail2hercules.com': true,
'mail2herman.com': true,
'mail2hermes.com': true,
'mail2hespera.com': true,
'mail2hestia.com': true,
'mail2highschool.com': true,
'mail2hindu.com': true,
'mail2hip.com': true,
'mail2hiphop.com': true,
'mail2holland.com': true,
'mail2holly.com': true,
'mail2hollywood.com': true,
'mail2homer.com': true,
'mail2honduras.com': true,
'mail2honey.com': true,
'mail2hongkong.com': true,
'mail2hope.com': true,
'mail2horse.com': true,
'mail2hot.com': true,
'mail2hotel.com': true,
'mail2houston.com': true,
'mail2howard.com': true,
'mail2hugh.com': true,
'mail2human.com': true,
'mail2hungary.com': true,
'mail2hungry.com': true,
'mail2hygeia.com': true,
'mail2hyperspace.com': true,
'mail2hypnos.com': true,
'mail2ian.com': true,
'mail2ice-cream.com': true,
'mail2iceland.com': true,
'mail2idaho.com': true,
'mail2idontknow.com': true,
'mail2illinois.com': true,
'mail2imam.com': true,
'mail2in.com': true,
'mail2india.com': true,
'mail2indian.com': true,
'mail2indiana.com': true,
'mail2indonesia.com': true,
'mail2infinity.com': true,
'mail2intense.com': true,
'mail2iowa.com': true,
'mail2iran.com': true,
'mail2iraq.com': true,
'mail2ireland.com': true,
'mail2irene.com': true,
'mail2iris.com': true,
'mail2irresistible.com': true,
'mail2irving.com': true,
'mail2irwin.com': true,
'mail2isaac.com': true,
'mail2israel.com': true,
'mail2italian.com': true,
'mail2italy.com': true,
'mail2jackie.com': true,
'mail2jacob.com': true,
'mail2jail.com': true,
'mail2jaime.com': true,
'mail2jake.com': true,
'mail2jamaica.com': true,
'mail2james.com': true,
'mail2jamie.com': true,
'mail2jan.com': true,
'mail2jane.com': true,
'mail2janet.com': true,
'mail2janice.com': true,
'mail2japan.com': true,
'mail2japanese.com': true,
'mail2jasmine.com': true,
'mail2jason.com': true,
'mail2java.com': true,
'mail2jay.com': true,
'mail2jazz.com': true,
'mail2jed.com': true,
'mail2jeffrey.com': true,
'mail2jennifer.com': true,
'mail2jenny.com': true,
'mail2jeremy.com': true,
'mail2jerry.com': true,
'mail2jessica.com': true,
'mail2jessie.com': true,
'mail2jesus.com': true,
'mail2jew.com': true,
'mail2jeweler.com': true,
'mail2jim.com': true,
'mail2jimmy.com': true,
'mail2joan.com': true,
'mail2joann.com': true,
'mail2joanna.com': true,
'mail2jody.com': true,
'mail2joe.com': true,
'mail2joel.com': true,
'mail2joey.com': true,
'mail2john.com': true,
'mail2join.com': true,
'mail2jon.com': true,
'mail2jonathan.com': true,
'mail2jones.com': true,
'mail2jordan.com': true,
'mail2joseph.com': true,
'mail2josh.com': true,
'mail2joy.com': true,
'mail2juan.com': true,
'mail2judge.com': true,
'mail2judy.com': true,
'mail2juggler.com': true,
'mail2julian.com': true,
'mail2julie.com': true,
'mail2jumbo.com': true,
'mail2junk.com': true,
'mail2justin.com': true,
'mail2justme.com': true,
'mail2kansas.com': true,
'mail2karate.com': true,
'mail2karen.com': true,
'mail2karl.com': true,
'mail2karma.com': true,
'mail2kathleen.com': true,
'mail2kathy.com': true,
'mail2katie.com': true,
'mail2kay.com': true,
'mail2kazakhstan.com': true,
'mail2keen.com': true,
'mail2keith.com': true,
'mail2kelly.com': true,
'mail2kelsey.com': true,
'mail2ken.com': true,
'mail2kendall.com': true,
'mail2kennedy.com': true,
'mail2kenneth.com': true,
'mail2kenny.com': true,
'mail2kentucky.com': true,
'mail2kenya.com': true,
'mail2kerry.com': true,
'mail2kevin.com': true,
'mail2kim.com': true,
'mail2kimberly.com': true,
'mail2king.com': true,
'mail2kirk.com': true,
'mail2kiss.com': true,
'mail2kosher.com': true,
'mail2kristin.com': true,
'mail2kurt.com': true,
'mail2kuwait.com': true,
'mail2kyle.com': true,
'mail2kyrgyzstan.com': true,
'mail2la.com': true,
'mail2lacrosse.com': true,
'mail2lance.com': true,
'mail2lao.com': true,
'mail2larry.com': true,
'mail2latvia.com': true,
'mail2laugh.com': true,
'mail2laura.com': true,
'mail2lauren.com': true,
'mail2laurie.com': true,
'mail2lawrence.com': true,
'mail2lawyer.com': true,
'mail2lebanon.com': true,
'mail2lee.com': true,
'mail2leo.com': true,
'mail2leon.com': true,
'mail2leonard.com': true,
'mail2leone.com': true,
'mail2leslie.com': true,
'mail2letter.com': true,
'mail2liberia.com': true,
'mail2libertarian.com': true,
'mail2libra.com': true,
'mail2libya.com': true,
'mail2liechtenstein.com': true,
'mail2life.com': true,
'mail2linda.com': true,
'mail2linux.com': true,
'mail2lionel.com': true,
'mail2lipstick.com': true,
'mail2liquid.com': true,
'mail2lisa.com': true,
'mail2lithuania.com': true,
'mail2litigator.com': true,
'mail2liz.com': true,
'mail2lloyd.com': true,
'mail2lois.com': true,
'mail2lola.com': true,
'mail2london.com': true,
'mail2looking.com': true,
'mail2lori.com': true,
'mail2lost.com': true,
'mail2lou.com': true,
'mail2louis.com': true,
'mail2louisiana.com': true,
'mail2lovable.com': true,
'mail2love.com': true,
'mail2lucky.com': true,
'mail2lucy.com': true,
'mail2lunch.com': true,
'mail2lust.com': true,
'mail2luxembourg.com': true,
'mail2luxury.com': true,
'mail2lyle.com': true,
'mail2lynn.com': true,
'mail2madagascar.com': true,
'mail2madison.com': true,
'mail2madrid.com': true,
'mail2maggie.com': true,
'mail2mail4.com': true,
'mail2maine.com': true,
'mail2malawi.com': true,
'mail2malaysia.com': true,
'mail2maldives.com': true,
'mail2mali.com': true,
'mail2malta.com': true,
'mail2mambo.com': true,
'mail2man.com': true,
'mail2mandy.com': true,
'mail2manhunter.com': true,
'mail2mankind.com': true,
'mail2many.com': true,
'mail2marc.com': true,
'mail2marcia.com': true,
'mail2margaret.com': true,
'mail2margie.com': true,
'mail2marhaba.com': true,
'mail2maria.com': true,
'mail2marilyn.com': true,
'mail2marines.com': true,
'mail2mark.com': true,
'mail2marriage.com': true,
'mail2married.com': true,
'mail2marries.com': true,
'mail2mars.com': true,
'mail2marsha.com': true,
'mail2marshallislands.com': true,
'mail2martha.com': true,
'mail2martin.com': true,
'mail2marty.com': true,
'mail2marvin.com': true,
'mail2mary.com': true,
'mail2maryland.com': true,
'mail2mason.com': true,
'mail2massachusetts.com': true,
'mail2matt.com': true,
'mail2matthew.com': true,
'mail2maurice.com': true,
'mail2mauritania.com': true,
'mail2mauritius.com': true,
'mail2max.com': true,
'mail2maxwell.com': true,
'mail2maybe.com': true,
'mail2mba.com': true,
'mail2me4u.com': true,
'mail2mechanic.com': true,
'mail2medieval.com': true,
'mail2megan.com': true,
'mail2mel.com': true,
'mail2melanie.com': true,
'mail2melissa.com': true,
'mail2melody.com': true,
'mail2member.com': true,
'mail2memphis.com': true,
'mail2methodist.com': true,
'mail2mexican.com': true,
'mail2mexico.com': true,
'mail2mgz.com': true,
'mail2miami.com': true,
'mail2michael.com': true,
'mail2michelle.com': true,
'mail2michigan.com': true,
'mail2mike.com': true,
'mail2milan.com': true,
'mail2milano.com': true,
'mail2mildred.com': true,
'mail2milkyway.com': true,
'mail2millennium.com': true,
'mail2millionaire.com': true,
'mail2milton.com': true,
'mail2mime.com': true,
'mail2mindreader.com': true,
'mail2mini.com': true,
'mail2minister.com': true,
'mail2minneapolis.com': true,
'mail2minnesota.com': true,
'mail2miracle.com': true,
'mail2missionary.com': true,
'mail2mississippi.com': true,
'mail2missouri.com': true,
'mail2mitch.com': true,
'mail2model.com': true,
'mail2moldova.commail2molly.com': true,
'mail2mom.com': true,
'mail2monaco.com': true,
'mail2money.com': true,
'mail2mongolia.com': true,
'mail2monica.com': true,
'mail2montana.com': true,
'mail2monty.com': true,
'mail2moon.com': true,
'mail2morocco.com': true,
'mail2morpheus.com': true,
'mail2mors.com': true,
'mail2moscow.com': true,
'mail2moslem.com': true,
'mail2mouseketeer.com': true,
'mail2movies.com': true,
'mail2mozambique.com': true,
'mail2mp3.com': true,
'mail2mrright.com': true,
'mail2msright.com': true,
'mail2museum.com': true,
'mail2music.com': true,
'mail2musician.com': true,
'mail2muslim.com': true,
'mail2my.com': true,
'mail2myboat.com': true,
'mail2mycar.com': true,
'mail2mycell.com': true,
'mail2mygsm.com': true,
'mail2mylaptop.com': true,
'mail2mymac.com': true,
'mail2mypager.com': true,
'mail2mypalm.com': true,
'mail2mypc.com': true,
'mail2myphone.com': true,
'mail2myplane.com': true,
'mail2namibia.com': true,
'mail2nancy.com': true,
'mail2nasdaq.com': true,
'mail2nathan.com': true,
'mail2nauru.com': true,
'mail2navy.com': true,
'mail2neal.com': true,
'mail2nebraska.com': true,
'mail2ned.com': true,
'mail2neil.com': true,
'mail2nelson.com': true,
'mail2nemesis.com': true,
'mail2nepal.com': true,
'mail2netherlands.com': true,
'mail2network.com': true,
'mail2nevada.com': true,
'mail2newhampshire.com': true,
'mail2newjersey.com': true,
'mail2newmexico.com': true,
'mail2newyork.com': true,
'mail2newzealand.com': true,
'mail2nicaragua.com': true,
'mail2nick.com': true,
'mail2nicole.com': true,
'mail2niger.com': true,
'mail2nigeria.com': true,
'mail2nike.com': true,
'mail2no.com': true,
'mail2noah.com': true,
'mail2noel.com': true,
'mail2noelle.com': true,
'mail2normal.com': true,
'mail2norman.com': true,
'mail2northamerica.com': true,
'mail2northcarolina.com': true,
'mail2northdakota.com': true,
'mail2northpole.com': true,
'mail2norway.com': true,
'mail2notus.com': true,
'mail2noway.com': true,
'mail2nowhere.com': true,
'mail2nuclear.com': true,
'mail2nun.com': true,
'mail2ny.com': true,
'mail2oasis.com': true,
'mail2oceanographer.com': true,
'mail2ohio.com': true,
'mail2ok.com': true,
'mail2oklahoma.com': true,
'mail2oliver.com': true,
'mail2oman.com': true,
'mail2one.com': true,
'mail2onfire.com': true,
'mail2online.com': true,
'mail2oops.com': true,
'mail2open.com': true,
'mail2ophthalmologist.com': true,
'mail2optometrist.com': true,
'mail2oregon.com': true,
'mail2oscars.com': true,
'mail2oslo.com': true,
'mail2painter.com': true,
'mail2pakistan.com': true,
'mail2palau.com': true,
'mail2pan.com': true,
'mail2panama.com': true,
'mail2paraguay.com': true,
'mail2paralegal.com': true,
'mail2paris.com': true,
'mail2park.com': true,
'mail2parker.com': true,
'mail2party.com': true,
'mail2passion.com': true,
'mail2pat.com': true,
'mail2patricia.com': true,
'mail2patrick.com': true,
'mail2patty.com': true,
'mail2paul.com': true,
'mail2paula.com': true,
'mail2pay.com': true,
'mail2peace.com': true,
'mail2pediatrician.com': true,
'mail2peggy.com': true,
'mail2pennsylvania.com': true,
'mail2perry.com': true,
'mail2persephone.com': true,
'mail2persian.com': true,
'mail2peru.com': true,
'mail2pete.com': true,
'mail2peter.com': true,
'mail2pharmacist.com': true,
'mail2phil.com': true,
'mail2philippines.com': true,
'mail2phoenix.com': true,
'mail2phonecall.com': true,
'mail2phyllis.com': true,
'mail2pickup.com': true,
'mail2pilot.com': true,
'mail2pisces.com': true,
'mail2planet.com': true,
'mail2platinum.com': true,
'mail2plato.com': true,
'mail2pluto.com': true,
'mail2pm.com': true,
'mail2podiatrist.com': true,
'mail2poet.com': true,
'mail2poland.com': true,
'mail2policeman.com': true,
'mail2policewoman.com': true,
'mail2politician.com': true,
'mail2pop.com': true,
'mail2pope.com': true,
'mail2popular.com': true,
'mail2portugal.com': true,
'mail2poseidon.com': true,
'mail2potatohead.com': true,
'mail2power.com': true,
'mail2presbyterian.com': true,
'mail2president.com': true,
'mail2priest.com': true,
'mail2prince.com': true,
'mail2princess.com': true,
'mail2producer.com': true,
'mail2professor.com': true,
'mail2protect.com': true,
'mail2psychiatrist.com': true,
'mail2psycho.com': true,
'mail2psychologist.com': true,
'mail2qatar.com': true,
'mail2queen.com': true,
'mail2rabbi.com': true,
'mail2race.com': true,
'mail2racer.com': true,
'mail2rachel.com': true,
'mail2rage.com': true,
'mail2rainmaker.com': true,
'mail2ralph.com': true,
'mail2randy.com': true,
'mail2rap.com': true,
'mail2rare.com': true,
'mail2rave.com': true,
'mail2ray.com': true,
'mail2raymond.com': true,
'mail2realtor.com': true,
'mail2rebecca.com': true,
'mail2recruiter.com': true,
'mail2recycle.com': true,
'mail2redhead.com': true,
'mail2reed.com': true,
'mail2reggie.com': true,
'mail2register.com': true,
'mail2rent.com': true,
'mail2republican.com': true,
'mail2resort.com': true,
'mail2rex.com': true,
'mail2rhodeisland.com': true,
'mail2rich.com': true,
'mail2richard.com': true,
'mail2ricky.com': true,
'mail2ride.com': true,
'mail2riley.com': true,
'mail2rita.com': true,
'mail2rob.com': true,
'mail2robert.com': true,
'mail2roberta.com': true,
'mail2robin.com': true,
'mail2rock.com': true,
'mail2rocker.com': true,
'mail2rod.com': true,
'mail2rodney.com': true,
'mail2romania.com': true,
'mail2rome.com': true,
'mail2ron.com': true,
'mail2ronald.com': true,
'mail2ronnie.com': true,
'mail2rose.com': true,
'mail2rosie.com': true,
'mail2roy.com': true,
'mail2rudy.com': true,
'mail2rugby.com': true,
'mail2runner.com': true,
'mail2russell.com': true,
'mail2russia.com': true,
'mail2russian.com': true,
'mail2rusty.com': true,
'mail2ruth.com': true,
'mail2rwanda.com': true,
'mail2ryan.com': true,
'mail2sa.com': true,
'mail2sabrina.com': true,
'mail2safe.com': true,
'mail2sagittarius.com': true,
'mail2sail.com': true,
'mail2sailor.com': true,
'mail2sal.com': true,
'mail2salaam.com': true,
'mail2sam.com': true,
'mail2samantha.com': true,
'mail2samoa.com': true,
'mail2samurai.com': true,
'mail2sandra.com': true,
'mail2sandy.com': true,
'mail2sanfrancisco.com': true,
'mail2sanmarino.com': true,
'mail2santa.com': true,
'mail2sara.com': true,
'mail2sarah.com': true,
'mail2sat.com': true,
'mail2saturn.com': true,
'mail2saudi.com': true,
'mail2saudiarabia.com': true,
'mail2save.com': true,
'mail2savings.com': true,
'mail2school.com': true,
'mail2scientist.com': true,
'mail2scorpio.com': true,
'mail2scott.com': true,
'mail2sean.com': true,
'mail2search.com': true,
'mail2seattle.com': true,
'mail2secretagent.com': true,
'mail2senate.com': true,
'mail2senegal.com': true,
'mail2sensual.com': true,
'mail2seth.com': true,
'mail2sevenseas.com': true,
'mail2sexy.com': true,
'mail2seychelles.com': true,
'mail2shane.com': true,
'mail2sharon.com': true,
'mail2shawn.com': true,
'mail2ship.com': true,
'mail2shirley.com': true,
'mail2shoot.com': true,
'mail2shuttle.com': true,
'mail2sierraleone.com': true,
'mail2simon.com': true,
'mail2singapore.com': true,
'mail2single.com': true,
'mail2site.com': true,
'mail2skater.com': true,
'mail2skier.com': true,
'mail2sky.com': true,
'mail2sleek.com': true,
'mail2slim.com': true,
'mail2slovakia.com': true,
'mail2slovenia.com': true,
'mail2smile.com': true,
'mail2smith.com': true,
'mail2smooth.com': true,
'mail2soccer.com': true,
'mail2soccerfan.com': true,
'mail2socialist.com': true,
'mail2soldier.com': true,
'mail2somalia.com': true,
'mail2son.com': true,
'mail2song.com': true,
'mail2sos.com': true,
'mail2sound.com': true,
'mail2southafrica.com': true,
'mail2southamerica.com': true,
'mail2southcarolina.com': true,
'mail2southdakota.com': true,
'mail2southkorea.com': true,
'mail2southpole.com': true,
'mail2spain.com': true,
'mail2spanish.com': true,
'mail2spare.com': true,
'mail2spectrum.com': true,
'mail2splash.com': true,
'mail2sponsor.com': true,
'mail2sports.com': true,
'mail2srilanka.com': true,
'mail2stacy.com': true,
'mail2stan.com': true,
'mail2stanley.com': true,
'mail2star.com': true,
'mail2state.com': true,
'mail2stephanie.com': true,
'mail2steve.com': true,
'mail2steven.com': true,
'mail2stewart.com': true,
'mail2stlouis.com': true,
'mail2stock.com': true,
'mail2stockholm.com': true,
'mail2stockmarket.com': true,
'mail2storage.com': true,
'mail2store.com': true,
'mail2strong.com': true,
'mail2student.com': true,
'mail2studio.com': true,
'mail2studio54.com': true,
'mail2stuntman.com': true,
'mail2subscribe.com': true,
'mail2sudan.com': true,
'mail2superstar.com': true,
'mail2surfer.com': true,
'mail2suriname.com': true,
'mail2susan.com': true,
'mail2suzie.com': true,
'mail2swaziland.com': true,
'mail2sweden.com': true,
'mail2sweetheart.com': true,
'mail2swim.com': true,
'mail2swimmer.com': true,
'mail2swiss.com': true,
'mail2switzerland.com': true,
'mail2sydney.com': true,
'mail2sylvia.com': true,
'mail2syria.com': true,
'mail2taboo.com': true,
'mail2taiwan.com': true,
'mail2tajikistan.com': true,
'mail2tammy.com': true,
'mail2tango.com': true,
'mail2tanya.com': true,
'mail2tanzania.com': true,
'mail2tara.com': true,
'mail2taurus.com': true,
'mail2taxi.com': true,
'mail2taxidermist.com': true,
'mail2taylor.com': true,
'mail2taz.com': true,
'mail2teacher.com': true,
'mail2technician.com': true,
'mail2ted.com': true,
'mail2telephone.com': true,
'mail2teletubbie.com': true,
'mail2tenderness.com': true,
'mail2tennessee.com': true,
'mail2tennis.com': true,
'mail2tennisfan.com': true,
'mail2terri.com': true,
'mail2terry.com': true,
'mail2test.com': true,
'mail2texas.com': true,
'mail2thailand.com': true,
'mail2therapy.com': true,
'mail2think.com': true,
'mail2tickets.com': true,
'mail2tiffany.com': true,
'mail2tim.com': true,
'mail2time.com': true,
'mail2timothy.com': true,
'mail2tina.com': true,
'mail2titanic.com': true,
'mail2toby.com': true,
'mail2todd.com': true,
'mail2togo.com': true,
'mail2tom.com': true,
'mail2tommy.com': true,
'mail2tonga.com': true,
'mail2tony.com': true,
'mail2touch.com': true,
'mail2tourist.com': true,
'mail2tracey.com': true,
'mail2tracy.com': true,
'mail2tramp.com': true,
'mail2travel.com': true,
'mail2traveler.com': true,
'mail2travis.com': true,
'mail2trekkie.com': true,
'mail2trex.com': true,
'mail2triallawyer.com': true,
'mail2trick.com': true,
'mail2trillionaire.com': true,
'mail2troy.com': true,
'mail2truck.com': true,
'mail2trump.com': true,
'mail2try.com': true,
'mail2tunisia.com': true,
'mail2turbo.com': true,
'mail2turkey.com': true,
'mail2turkmenistan.com': true,
'mail2tv.com': true,
'mail2tycoon.com': true,
'mail2tyler.com': true,
'mail2u4me.com': true,
'mail2uae.com': true,
'mail2uganda.com': true,
'mail2uk.com': true,
'mail2ukraine.com': true,
'mail2uncle.com': true,
'mail2unsubscribe.com': true,
'mail2uptown.com': true,
'mail2uruguay.com': true,
'mail2usa.com': true,
'mail2utah.com': true,
'mail2uzbekistan.com': true,
'mail2v.com': true,
'mail2vacation.com': true,
'mail2valentines.com': true,
'mail2valerie.com': true,
'mail2valley.com': true,
'mail2vamoose.com': true,
'mail2vanessa.com': true,
'mail2vanuatu.com': true,
'mail2venezuela.com': true,
'mail2venous.com': true,
'mail2venus.com': true,
'mail2vermont.com': true,
'mail2vickie.com': true,
'mail2victor.com': true,
'mail2victoria.com': true,
'mail2vienna.com': true,
'mail2vietnam.com': true,
'mail2vince.com': true,
'mail2virginia.com': true,
'mail2virgo.com': true,
'mail2visionary.com': true,
'mail2vodka.com': true,
'mail2volleyball.com': true,
'mail2waiter.com': true,
'mail2wallstreet.com': true,
'mail2wally.com': true,
'mail2walter.com': true,
'mail2warren.com': true,
'mail2washington.com': true,
'mail2wave.com': true,
'mail2way.com': true,
'mail2waycool.com': true,
'mail2wayne.com': true,
'mail2webmaster.com': true,
'mail2webtop.com': true,
'mail2webtv.com': true,
'mail2weird.com': true,
'mail2wendell.com': true,
'mail2wendy.com': true,
'mail2westend.com': true,
'mail2westvirginia.com': true,
'mail2whether.com': true,
'mail2whip.com': true,
'mail2white.com': true,
'mail2whitehouse.com': true,
'mail2whitney.com': true,
'mail2why.com': true,
'mail2wilbur.com': true,
'mail2wild.com': true,
'mail2willard.com': true,
'mail2willie.com': true,
'mail2wine.com': true,
'mail2winner.com': true,
'mail2wired.com': true,
'mail2wisconsin.com': true,
'mail2woman.com': true,
'mail2wonder.com': true,
'mail2world.com': true,
'mail2worship.com': true,
'mail2wow.com': true,
'mail2www.com': true,
'mail2wyoming.com': true,
'mail2xfiles.com': true,
'mail2xox.com': true,
'mail2yachtclub.com': true,
'mail2yahalla.com': true,
'mail2yemen.com': true,
'mail2yes.com': true,
'mail2yugoslavia.com': true,
'mail2zack.com': true,
'mail2zambia.com': true,
'mail2zenith.com': true,
'mail2zephir.com': true,
'mail2zeus.com': true,
'mail2zipper.com': true,
'mail2zoo.com': true,
'mail2zoologist.com': true,
'mail2zurich.com': true,
'mail3000.com': true,
'mail333.com': true,
'mailandftp.com': true,
'mailandnews.com': true,
'mailas.com': true,
'mailasia.com': true,
'mailbolt.com': true,
'mailbomb.net': true,
'mailboom.com': true,
'mailbox.as': true,
'mailbox.co.za': true,
'mailbox.gr': true,
'mailbox.hu': true,
'mailbr.com.br': true,
'mailc.net': true,
'mailcan.com': true,
'mailcc.com': true,
'mailchoose.co': true,
'mailcity.com': true,
'mailclub.fr': true,
'mailclub.net': true,
'mailexcite.com': true,
'mailforce.net': true,
'mailftp.com': true,
'mailgate.gr': true,
'mailgenie.net': true,
'mailhaven.com': true,
'mailhood.com': true,
'mailingweb.com': true,
'mailisent.com': true,
'mailite.com': true,
'mailme.dk': true,
'mailmight.com': true,
'mailmij.nl': true,
'mailnew.com': true,
'mailops.com': true,
'mailoye.com': true,
'mailpanda.com': true,
'mailpost.zzn.com': true,
'mailpride.com': true,
'mailpuppy.com': true,
'mailroom.com': true,
'mailru.com': true,
'mailsent.net': true,
'mailshuttle.com': true,
'mailstart.com': true,
'mailstartplus.com': true,
'mailsurf.com': true,
'mailtag.com': true,
'mailto.de': true,
'mailup.net': true,
'mailwire.com': true,
'maktoob.com': true,
'malayalamtelevision.net': true,
'manager.de': true,
'mantrafreenet.com': true,
'mantramail.com': true,
'mantraonline.com': true,
'marchmail.com': true,
'mariah-carey.ml.org': true,
'mariahc.com': true,
'marijuana.nl': true,
'marketing.lu': true,
'married-not.com': true,
'marsattack.com': true,
'martindalemail.com': true,
'masrawy.com': true,
'matmail.com': true,
'mauimail.com': true,
'mauritius.com': true,
'maxleft.com': true,
'maxmail.co.uk': true,
'mbox.com.au': true,
'mchsi.com': true,
'mciworldcom.net': true,
'me-mail.hu': true,
'me.com': true,
'medical.net.au': true,
'medione.net': true,
'medmail.com': true,
'medscape.com': true,
'meetingmall.com': true,
'megago.com': true,
'megamail.pt': true,
'megapathdsl.net': true,
'megapoint.com': true,
'mehrani.com': true,
'mehtaweb.com': true,
'mekhong.com': true,
'melodymail.com': true,
'meloo.com': true,
'members.student.com': true,
'message.hu': true,
'messages.to': true,
'metacrawler.com': true,
'metalfan.com': true,
'metta.lk': true,
'miatadriver.com': true,
'miesto.sk': true,
'mighty.co.za': true,
'miho-nakayama.com': true,
'mikrotamanet.com': true,
'millionaireintraining.com': true,
'milmail.com': true,
'mindless.com': true,
'mindspring.com': true,
'mini-mail.com': true,
'misery.net': true,
'mittalweb.com': true,
'mixmail.com': true,
'mjfrogmail.com': true,
'ml1.net': true,
'mobilbatam.com': true,
'mochamail.com': true,
'mohammed.com': true,
'moldova.cc': true,
'moldova.com': true,
'moldovacc.com': true,
'money.net': true,
'montevideo.com.uy': true,
'moonman.com': true,
'moose-mail.com': true,
'mortaza.com': true,
'mosaicfx.com': true,
'most-wanted.com': true,
'mostlysunny.com': true,
'motormania.com': true,
'movemail.com': true,
'movieluver.com': true,
'mp4.it': true,
'mr-potatohead.com': true,
'mrpost.com': true,
'mscold.com': true,
'msgbox.com': true,
'msn.com': true,
'mttestdriver.com': true,
'mundomail.net': true,
'munich.com': true,
'music.com': true,
'musician.org': true,
'musicscene.org': true,
'mybox.it': true,
'mycabin.com': true,
'mycampus.com': true,
'mycity.com': true,
'mycool.com': true,
'mydomain.com': true,
'mydotcomaddress.com': true,
'myfamily.com': true,
'mygo.com': true,
'myiris.com': true,
'mynamedot.com': true,
'mynetaddress.com': true,
'myownemail.com': true,
'myownfriends.com': true,
'mypad.com': true,
'mypersonalemail.com': true,
'myplace.com': true,
'myrealbox.com': true,
'myremarq.com': true,
'myself.com': true,
'mystupidjob.com': true,
'mythirdage.com': true,
'myway.com': true,
'myworldmail.com': true,
'n2.com': true,
'n2business.com': true,
'n2mail.com': true,
'n2software.com': true,
'nabc.biz': true,
'nafe.com': true,
'nagpal.net': true,
'nakedgreens.com': true,
'name.com': true,
'nameplanet.com': true,
'nandomail.com': true,
'naplesnews.net': true,
'naseej.com': true,
'nate.com': true,
'nativestar.net': true,
'nativeweb.net': true,
'naui.net': true,
'nauticom.net': true,
'naver.com': true,
'navigator.lv': true,
'navy.org': true,
'naz.com': true,
'nchoicemail.com': true,
'neeva.net': true,
'nemra1.com': true,
'nenter.com': true,
'neo.rr.com': true,
'nervhq.org': true,
'net-pager.net': true,
'net4b.pt': true,
'net4you.at': true,
'netbounce.com': true,
'netbroadcaster.com': true,
'netby.dk': true,
'netcenter-vn.net': true,
'netcom.ca': true,
'netcom.com': true,
'netcourrier.com': true,
'netexecutive.com': true,
'netexpressway.com': true,
'netgenie.com': true,
'netian.com': true,
'netizen.com.ar': true,
'netlane.com': true,
'netlimit.com': true,
'netmanor.com': true,
'netmongol.com': true,
'netnet.com.sg': true,
'netpiper.com': true,
'netposta.net': true,
'netradiomail.com': true,
'netralink.com': true,
'netscape.com': true,
'netscape.net': true,
'netscapeonline.co.uk': true,
'netsero.net': true,
'netspeedway.com': true,
'netsquare.com': true,
'netster.com': true,
'nettaxi.com': true,
'netzero.com': true,
'netzero.net': true,
'neuf.fr': true,
'newmail.com': true,
'newmail.net': true,
'newmail.ru': true,
'newyork.com': true,
'nexxmail.com': true,
'nfmail.com': true,
'nhmail.com': true,
'nicebush.com': true,
'nicegal.com': true,
'nicholastse.net': true,
'nicolastse.com': true,
'nightmail.com': true,
'nikopage.com': true,
'nimail.com': true,
'nirvanafan.com': true,
'noavar.com': true,
'norika-fujiwara.com': true,
'norikomail.com': true,
'northgates.net': true,
'nospammail.net': true,
'ntlworld.com': true,
'ntscan.com': true,
'ny.com': true,
'nyc.com': true,
'nycmail.com': true,
'nzoomail.com': true,
'o-tay.com': true,
'o2.co.uk': true,
'oaklandas-fan.com': true,
'oceanfree.net': true,
'oddpost.com': true,
'odmail.com': true,
'office-email.com': true,
'officedomain.com': true,
'offroadwarrior.com': true,
'oi.com.br': true,
'oicexchange.com': true,
'okbank.com': true,
'okhuman.com': true,
'okmad.com': true,
'okmagic.com': true,
'okname.net': true,
'okuk.com': true,
'oldies1041.com': true,
'oldies104mail.com': true,
'ole.com': true,
'olemail.com': true,
'olg.com': true,
'olympist.net': true,
'omaninfo.com': true,
'onebox.com': true,
'onenet.com.ar': true,
'onet.pl': true,
'oninet.pt': true,
'online.de': true,
'online.ie': true,
'onlinewiz.com': true,
'onmilwaukee.com': true,
'onobox.com': true,
'onvillage.com': true,
'operafan.com': true,
'operamail.com': true,
'optician.com': true,
'optonline.net': true,
'optusnet.com.au': true,
'orange.fr': true,
'orange.net': true,
'orbitel.bg': true,
'orgmail.net': true,
'osite.com.br': true,
'oso.com': true,
'otakumail.com': true,
'our-computer.com': true,
'our-office.com': true,
'our.st': true,
'ourbrisbane.com': true,
'ournet.md': true,
'outel.com': true,
'outgun.com': true,
'outlook.com': true,
'outlook.com.br': true,
'over-the-rainbow.com': true,
'ownmail.net': true,
'ozbytes.net.au': true,
'ozemail.com.au': true,
'pacbell.net': true,
'pacific-re.com': true,
'packersfan.com': true,
'pagina.de': true,
'pagons.org': true,
'pakistanoye.com': true,
'palestinemail.com': true,
'parkjiyoon.com': true,
'parrot.com': true,
'parsmail.com': true,
'partlycloudy.com': true,
'partynight.at': true,
'parvazi.com': true,
'passwordmail.com': true,
'pathfindermail.com': true,
'patmedia.net': true,
'pconnections.net': true,
'pcpostal.com': true,
'pcsrock.com': true,
'peachworld.com': true,
'pediatrician.com': true,
'pemail.net': true,
'penpen.com': true,
'peoplepc.com': true,
'peopleweb.com': true,
'perfectmail.com': true,
'personal.ro': true,
'personales.com': true,
'petml.com': true,
'pettypool.com': true,
'pezeshkpour.com': true,
'phayze.com': true,
'phreaker.net': true,
'pickupman.com': true,
'picusnet.com': true,
'pigpig.net': true,
'pinoymail.com': true,
'pipeline.com': true,
'piracha.net': true,
'pisem.net': true,
'planet-mail.com': true,
'planet.nl': true,
'planetaccess.com': true,
'planetall.com': true,
'planetarymotion.net': true,
'planetdirect.com': true,
'planetearthinter.net': true,
'planetout.com': true,
'plasa.com': true,
'playersodds.com': true,
'playful.com': true,
'plusmail.com.br': true,
'pmail.net': true,
'pobox.com': true,
'pobox.hu': true,
'pobox.sk': true,
'pochta.ru': true,
'poczta.fm': true,
'poetic.com': true,
'polbox.com': true,
'policeoffice.com': true,
'pool-sharks.com': true,
'poond.com': true,
'popaccount.com': true,
'popmail.com': true,
'popsmail.com': true,
'popstar.com': true,
'populus.net': true,
'portableoffice.com': true,
'portugalmail.com': true,
'portugalmail.pt': true,
'portugalnet.com': true,
'positive-thinking.com': true,
'post.com': true,
'post.cz': true,
'post.sk': true,
'posta.net': true,
'posta.ro': true,
'postaccesslite.com': true,
'postafree.com': true,
'postaweb.com': true,
'poste.it': true,
'postinbox.com': true,
'postino.ch': true,
'postmark.net': true,
'postmaster.co.uk': true,
'postpro.net': true,
'pousa.com': true,
'powerfan.com': true,
'praize.com': true,
'premiumservice.com': true,
'presidency.com': true,
'press.co.jp': true,
'priest.com': true,
'primposta.com': true,
'primposta.hu': true,
'pro.hu': true,
'probemail.com': true,
'prodigy.net': true,
'prodigy.net.mx': true,
'progetplus.it': true,
'programmer.net': true,
'programozo.hu': true,
'proinbox.com': true,
'project2k.com': true,
'prolaunch.com': true,
'promessage.com': true,
'prontomail.com': true,
'protonmail.com': true,
'psi.net': true,
'psv-supporter.com': true,
'ptd.net': true,
'public.usa.com': true,
'publicist.com': true,
'pulp-fiction.com': true,
'punkass.com': true,
'qatarmail.com': true,
'qis.net': true,
'qprfans.com': true,
'qq.com': true,
'qrio.com': true,
'quackquack.com': true,
'quakemail.com': true,
'qudsmail.com': true,
'quepasa.com': true,
'quickwebmail.com': true,
'quiklinks.com': true,
'quikmail.com': true,
'qwest.net': true,
'qwestoffice.net': true,
'r-o-o-t.com': true,
'r7.com': true,
'raakim.com': true,
'racedriver.com': true,
'racefanz.com': true,
'racingfan.com.au': true,
'racingmail.com': true,
'radicalz.com': true,
'ragingbull.com': true,
'rambler.ru': true,
'ranmamail.com': true,
'rastogi.net': true,
'ratt-n-roll.com': true,
'rattle-snake.com': true,
'ravearena.com': true,
'ravemail.com': true,
'razormail.com': true,
'rccgmail.org': true,
'rcn.com': true,
'realemail.net': true,
'reallyfast.biz': true,
'realradiomail.com': true,
'recycler.com': true,
'rediffmail.com': true,
'rediffmailpro.com': true,
'rednecks.com': true,
'redseven.de': true,
'redsfans.com': true,
'reggafan.com': true,
'registerednurses.com': true,
'repairman.com': true,
'reply.hu': true,
'representative.com': true,
'rescueteam.com': true,
'resumemail.com': true,
'rezai.com': true,
'richmondhill.com': true,
'rickymail.com': true,
'rin.ru': true,
'riopreto.com.br': true,
'rn.com': true,
'roadrunner.com': true,
'roanokemail.com': true,
'rock.com': true,
'rocketmail.com': true,
'rockfan.com': true,
'rodrun.com': true,
'rome.com': true,
'roosh.com': true,
'rotfl.com': true,
'roughnet.com': true,
'rr.com': true,
'rrohio.com': true,
'rsub.com': true,
'rubyridge.com': true,
'runbox.com': true,
'rushpost.com': true,
'ruttolibero.com': true,
'rvshop.com': true,
's-mail.com': true,
'sabreshockey.com': true,
'sacbeemail.com': true,
'safarimail.com': true,
'safe-mail.net': true,
'sagra.lu': true,
'sailormoon.com': true,
'saintly.com': true,
'saintmail.net': true,
'sale-sale-sale.com': true,
'salehi.net': true,
'samerica.com': true,
'samilan.net': true,
'sammimail.com': true,
'sanfranmail.com': true,
'sanook.com': true,
'sapo.pt': true,
'sativa.ro.org': true,
'saudia.com': true,
'sayhi.net': true,
'sbcglobal.net': true,
'scandalmail.com': true,
'schizo.com': true,
'schoolemail.com': true,
'schoolmail.com': true,
'schoolsucks.com': true,
'schweiz.org': true,
'sci.fi': true,
'science.com.au': true,
'scientist.com': true,
'scifianime.com': true,
'scottishmail.co.uk': true,
'scubadiving.com': true,
'seanet.com': true,
'searchwales.com': true,
'sebil.com': true,
'secret-police.com': true,
'secretservices.net': true,
'seductive.com': true,
'seekstoyboy.com': true,
'seguros.com.br': true,
'send.hu': true,
'sendme.cz': true,
'sent.com': true,
'sentrismail.com': true,
'serga.com.ar': true,
'servemymail.com': true,
'sesmail.com': true,
'sexmagnet.com': true,
'seznam.cz': true,
'sfr.fr': true,
'shahweb.net': true,
'shaniastuff.com': true,
'sharewaredevelopers.com': true,
'sharmaweb.com': true,
'shaw.ca': true,
'she.com': true,
'shootmail.com': true,
'shotgun.hu': true,
'shuf.com': true,
'sialkotcity.com': true,
'sialkotian.com': true,
'sialkotoye.com': true,
'sify.com': true,
'silkroad.net': true,
'sina.cn': true,
'sina.com': true,
'sinamail.com': true,
'singapore.com': true,
'singmail.com': true,
'singnet.com.sg': true,
'singpost.com': true,
'skafan.com': true,
'skim.com': true,
'skizo.hu': true,
'sky.com': true,
'skynet.be': true,
'slamdunkfan.com': true,
'slingshot.com': true,
'slo.net': true,
'slotter.com': true,
'smapxsmap.net': true,
'smileyface.comsmithemail.net': true,
'smoothmail.com': true,
'snail-mail.net': true,
'snail-mail.ney': true,
'snakemail.com': true,
'sndt.net': true,
'sneakemail.com': true,
'snet.net': true,
'snip.net': true,
'sniper.hu': true,
'snoopymail.com': true,
'snowboarding.com': true,
'snowdonia.net': true,
'socamail.com': true,
'socceramerica.net': true,
'soccermail.com': true,
'soccermomz.com': true,
'sociologist.com': true,
'softhome.net': true,
'sol.dk': true,
'soldier.hu': true,
'soon.com': true,
'soulfoodcookbook.com': true,
'sp.nl': true,
'space-bank.com': true,
'space-man.com': true,
'space-ship.com': true,
'space-travel.com': true,
'space.com': true,
'spaceart.com': true,
'spacebank.com': true,
'spacemart.com': true,
'spacetowns.com': true,
'spacewar.com': true,
'spamex.com': true,
'spartapiet.com': true,
'spazmail.com': true,
'speedemail.net': true,
'speedpost.net': true,
'speedrules.com': true,
'speedrulz.com': true,
'speedy.com.ar': true,
'spils.com': true,
'spinfinder.com': true,
'sportemail.com': true,
'sportsmail.com': true,
'sporttruckdriver.com': true,
'spray.no': true,
'spray.se': true,
'sprintmail.com': true,
'sprynet.com': true,
'spymac.com': true,
'srilankan.net': true,
'st-davids.net': true,
'stade.fr': true,
'stalag13.com': true,
'stargateradio.com': true,
'starmail.com': true,
'starmail.org': true,
'starmedia.com': true,
'starplace.com': true,
'starpower.net': true,
'starspath.com': true,
'start.com.au': true,
'starting-point.com': true,
'startrekmail.com': true,
'stealthmail.com': true,
'stockracer.com': true,
'stones.com': true,
'stopdropandroll.com': true,
'storksite.com': true,
'stribmail.com': true,
'strompost.com': true,
'strongguy.com': true,
'studentcenter.org': true,
'subnetwork.com': true,
'subram.com': true,
'sudanmail.net': true,
'suhabi.com': true,
'suisse.org': true,
'sukhumvit.net': true,
'sunpoint.net': true,
'sunrise-sunset.com': true,
'sunsgame.com': true,
'sunumail.sn': true,
'superdada.com': true,
'supereva.it': true,
'supermail.ru': true,
'surat.com': true,
'surf3.net': true,
'surfree.com': true,
'surfy.net': true,
'surimail.com': true,
'survivormail.com': true,
'swbell.net': true,
'sweb.cz': true,
'swiftdesk.com': true,
'swingeasyhithard.com': true,
'swingfan.com': true,
'swipermail.zzn.com': true,
'swirve.com': true,
'swissinfo.org': true,
'swissmail.net': true,
'switchboardmail.com': true,
'switzerland.org': true,
'swva.net': true,
'sx172.com': true,
'sympatico.ca': true,
'syom.com': true,
'syriamail.com': true,
't-online.de': true,
't2mail.com': true,
'takuyakimura.com': true,
'talk21.com': true,
'talkcity.com': true,
'talktalk.co.uk': true,
'tamil.com': true,
'tampabay.rr.com': true,
'tatanova.com': true,
'tbwt.com': true,
'tds.net': true,
'teamdiscovery.com': true,
'teamtulsa.net': true,
'tech4peace.org': true,
'techemail.com': true,
'techie.com': true,
'technisamail.co.za': true,
'technologist.com': true,
'techpointer.com': true,
'techscout.com': true,
'techseek.com': true,
'techspot.com': true,
'teenagedirtbag.com': true,
'telebot.com': true,
'telebot.net': true,
'teleline.es': true,
'telenet.be': true,
'telerymd.com': true,
'teleserve.dynip.com': true,
'teletu.it': true,
'telinco.net': true,
'telkom.net': true,
'telpage.net': true,
'telus.net': true,
'temtulsa.net': true,
'tenchiclub.com': true,
'tenderkiss.com': true,
'tennismail.com': true,
'terra.cl': true,
'terra.com': true,
'terra.com.ar': true,
'terra.com.br': true,
'terra.es': true,
'tfanus.com.er': true,
'tfz.net': true,
'thai.com': true,
'thaimail.com': true,
'thaimail.net': true,
'the-african.com': true,
'the-airforce.com': true,
'the-aliens.com': true,
'the-american.com': true,
'the-animal.com': true,
'the-army.com': true,
'the-astronaut.com': true,
'the-beauty.com': true,
'the-big-apple.com': true,
'the-biker.com': true,
'the-boss.com': true,
'the-brazilian.com': true,
'the-canadian.com': true,
'the-canuck.com': true,
'the-captain.com': true,
'the-chinese.com': true,
'the-country.com': true,
'the-cowboy.com': true,
'the-davis-home.com': true,
'the-dutchman.com': true,
'the-eagles.com': true,
'the-englishman.com': true,
'the-fastest.net': true,
'the-fool.com': true,
'the-frenchman.com': true,
'the-galaxy.net': true,
'the-genius.com': true,
'the-gentleman.com': true,
'the-german.com': true,
'the-gremlin.com': true,
'the-hooligan.com': true,
'the-italian.com': true,
'the-japanese.com': true,
'the-lair.com': true,
'the-madman.com': true,
'the-mailinglist.com': true,
'the-marine.com': true,
'the-master.com': true,
'the-mexican.com': true,
'the-ministry.com': true,
'the-monkey.com': true,
'the-newsletter.net': true,
'the-pentagon.com': true,
'the-police.com': true,
'the-prayer.com': true,
'the-professional.com': true,
'the-quickest.com': true,
'the-russian.com': true,
'the-snake.com': true,
'the-spaceman.com': true,
'the-stock-market.com': true,
'the-student.net': true,
'the-whitehouse.net': true,
'the-wild-west.com': true,
'the18th.com': true,
'thecoolguy.com': true,
'thecriminals.com': true,
'thedoghousemail.com': true,
'thedorm.com': true,
'theend.hu': true,
'theglobe.com': true,
'thegolfcourse.com': true,
'thegooner.com': true,
'theheadoffice.com': true,
'thelanddownunder.com': true,
'themillionare.net': true,
'theoffice.net': true,
'thepokerface.com': true,
'thepostmaster.net': true,
'theraces.com': true,
'theracetrack.com': true,
'thestreetfighter.com': true,
'theteebox.com': true,
'thewatercooler.com': true,
'thewebpros.co.uk': true,
'thewizzard.com': true,
'thewizzkid.com': true,
'thezhangs.net': true,
'thirdage.com': true,
'thisgirl.com': true,
'thoic.com': true,
'thundermail.com': true,
'tidni.com': true,
'timein.net': true,
'tin.it': true,
'tiscali.at': true,
'tiscali.be': true,
'tiscali.co.uk': true,
'tiscali.it': true,
'tiscali.lu': true,
'tiscali.se': true,
'tkcity.com': true,
'toolsource.com': true,
'topchat.com': true,
'topgamers.co.uk': true,
'topletter.com': true,
'topmail.com.ar': true,
'topsurf.com': true,
'topteam.bg': true,
'torchmail.com': true,
'tot.net': true,
'totalmusic.net': true,
'toughguy.net': true,
'tpg.com.au': true,
'travel.li': true,
'trialbytrivia.com': true,
'tritium.net': true,
'trmailbox.com': true,
'tropicalstorm.com': true,
'truckers.com': true,
'truckerz.com': true,
'truckracer.com': true,
'trust-me.com': true,
'tsamail.co.za': true,
'ttml.co.in': true,
'tunisiamail.com': true,
'turkey.com': true,
'tvcablenet.be': true,
'twinstarsmail.com': true,
'tycoonmail.com': true,
'typemail.com': true,
'u2club.com': true,
'uae.ac': true,
'uaemail.com': true,
'ubbi.com': true,
'ubbi.com.br': true,
'uboot.com': true,
'uk2k.com': true,
'uk2net.com': true,
'uk7.net': true,
'uk8.net': true,
'ukbuilder.com': true,
'ukcool.com': true,
'ukdreamcast.com': true,
'ukmail.org': true,
'ukmax.com': true,
'ukr.net': true,
'uku.co.uk': true,
'ultapulta.com': true,
'ultrapostman.com': true,
'ummah.org': true,
'umpire.com': true,
'unbounded.com': true,
'unforgettable.com': true,
'uni.de': true,
'unican.es': true,
'unihome.com': true,
'universal.pt': true,
'uno.ee': true,
'uno.it': true,
'unofree.it': true,
'unomail.com': true,
'uol.com.ar': true,
'uol.com.br': true,
'uol.com.co': true,
'uol.com.mx': true,
'uol.com.ve': true,
'uole.com': true,
'uole.com.ve': true,
'uolmail.com': true,
'uomail.com': true,
'upf.org': true,
'ureach.com': true,
'urgentmail.biz': true,
'usa.com': true,
'usa.net': true,
'usaaccess.net': true,
'usanetmail.com': true,
'usermail.com': true,
'usit.net': true,
'usma.net': true,
'usmc.net': true,
'uswestmail.net': true,
'uu.net': true,
'uymail.com': true,
'uyuyuy.com': true,
'v-sexi.com': true,
'vahoo.com': true,
'varbizmail.com': true,
'vcmail.com': true,
'velnet.co.uk': true,
'velocall.com': true,
'verizon.net': true,
'verizonmail.com': true,
'veryfast.biz': true,
'veryspeedy.net': true,
'videotron.ca': true,
'violinmakers.co.uk': true,
'vip.gr': true,
'vipmail.ru': true,
'virgilio.it': true,
'virgin.net': true,
'virginmedia.com': true,
'virtualactive.com': true,
'virtualmail.com': true,
'visitmail.com': true,
'visitweb.com': true,
'visto.com': true,
'visualcities.com': true,
'vivavelocity.com': true,
'vivianhsu.net': true,
'vjmail.com': true,
'vjtimail.com': true,
'vlmail.com': true,
'vnn.vn': true,
'voila.fr': true,
'volcanomail.com': true,
'voo.be': true,
'vote-democrats.com': true,
'vote-hillary.com': true,
'vote-republicans.com': true,
'vote4gop.org': true,
'votenet.com': true,
'vr9.com': true,
'w3.to': true,
'wahoye.com': true,
'wales2000.net': true,
'wam.co.za': true,
'wanadoo.co.uk': true,
'wanadoo.es': true,
'wanadoo.fr': true,
'warmmail.com': true,
'warpmail.net': true,
'warrior.hu': true,
'waumail.com': true,
'wbdet.com': true,
'wearab.net': true,
'web-mail.com.ar': true,
'web-police.com': true,
'web.de': true,
'webave.com': true,
'webcammail.com': true,
'webcity.ca': true,
'webdream.com': true,
'webinbox.com': true,
'webindia123.com': true,
'webjump.com': true,
'webmail.bellsouth.net': true,
'webmail.co.yu': true,
'webmail.co.za': true,
'webmail.hu': true,
'webmails.com': true,
'webprogramming.com': true,
'webstable.net': true,
'webstation.com': true,
'websurfer.co.za': true,
'webtopmail.com': true,
'weedmail.com': true,
'weekmail.com': true,
'weekonline.com': true,
'wehshee.com': true,
'welsh-lady.com': true,
'whale-mail.com': true,
'whartontx.com': true,
'wheelweb.com': true,
'whipmail.com': true,
'whoever.com': true,
'whoopymail.com': true,
'wickedmail.com': true,
'wideopenwest.com': true,
'wildmail.com': true,
'windrivers.net': true,
'windstream.net': true,
'wingnutz.com': true,
'winmail.com.au': true,
'winning.com': true,
'witty.com': true,
'wiz.cc': true,
'wkbwmail.com': true,
'woh.rr.com': true,
'wolf-web.com': true,
'wombles.com': true,
'wonder-net.com': true,
'wongfaye.com': true,
'wooow.it': true,
'workmail.com': true,
'worldemail.com': true,
'worldmailer.com': true,
'worldnet.att.net': true,
'wosaddict.com': true,
'wouldilie.com': true,
'wow.com': true,
'wowgirl.com': true,
'wowmail.com': true,
'wowway.com': true,
'wp.pl': true,
'wptamail.com': true,
'wrestlingpages.com': true,
'wrexham.net': true,
'writeme.com': true,
'writemeback.com': true,
'wrongmail.com': true,
'wtvhmail.com': true,
'wwdg.com': true,
'www.com': true,
'www2000.net': true,
'wx88.net': true,
'wxs.net': true,
'wyrm.supernews.com': true,
'x-mail.net': true,
'x-networks.net': true,
'x5g.com': true,
'xmastime.com': true,
'xmsg.com': true,
'xoom.com': true,
'xoommail.com': true,
'xpressmail.zzn.com': true,
'xsmail.com': true,
'xuno.com': true,
'xzapmail.com': true,
'ya.ru': true,
'yada-yada.com': true,
'yaho.com': true,
'yahoo.ca': true,
'yahoo.co.id': true,
'yahoo.co.in': true,
'yahoo.co.jp': true,
'yahoo.co.kr': true,
'yahoo.co.nz': true,
'yahoo.co.uk': true,
'yahoo.com': true,
'yahoo.com.ar': true,
'yahoo.com.au': true,
'yahoo.com.br': true,
'yahoo.com.cn': true,
'yahoo.com.hk': true,
'yahoo.com.is': true,
'yahoo.com.mx': true,
'yahoo.com.ph': true,
'yahoo.com.ru': true,
'yahoo.com.sg': true,
'yahoo.de': true,
'yahoo.dk': true,
'yahoo.es': true,
'yahoo.fr': true,
'yahoo.ie': true,
'yahoo.in': true,
'yahoo.it': true,
'yahoo.jp': true,
'yahoo.ru': true,
'yahoo.se': true,
'yahoofs.com': true,
'yalla.com': true,
'yalla.com.lb': true,
'yalook.com': true,
'yam.com': true,
'yandex.com': true,
'yandex.ru': true,
'yapost.com': true,
'yawmail.com': true,
'yclub.com': true,
'yebox.com': true,
'yehaa.com': true,
'yehey.com': true,
'yemenmail.com': true,
'yepmail.net': true,
'yesbox.net': true,
'ygm.com': true,
'yifan.net': true,
'ymail.com': true,
'ynnmail.com': true,
'yogotemail.com': true,
'yopolis.com': true,
'youareadork.com': true,
'youpy.com': true,
'your-house.com': true,
'yourinbox.com': true,
'yourlover.net': true,
'yourname.ddns.org': true,
'yourname.freeservers.com': true,
'yournightmare.com': true,
'yours.com': true,
'yourssincerely.com': true,
'yoursubdomain.findhere.com': true,
'yoursubdomain.zzn.com': true,
'yourteacher.net': true,
'yourwap.com': true,
'youvegotmail.net': true,
'yuuhuu.net': true,
'yyhmail.com': true,
'zahadum.com': true,
'zcities.com': true,
'zdnetmail.com': true,
'zeeks.com': true,
'zeepost.nl': true,
'zensearch.net': true,
'zhaowei.net': true,
'zionweb.org': true,
'zip.net': true,
'zipido.com': true,
'ziplink.net': true,
'ziplip.com': true,
'zipmail.com': true,
'zipmail.com.br': true,
'zipmax.com': true,
'zmail.ru': true,
'zoho.com': true,
'zonnet.nl': true,
'zoominternet.net': true,
'zubee.com': true,
'zuvio.com': true,
'zuzzurello.com': true,
'zwallet.com': true,
'zybermail.com': true,
'zydecofan.com': true,
'zzn.com': true,
'zzom.co.uk': true,
'fisglobal.com': true,
'fiserv.com': true,
'infinicept.com': true,
'payrix.com': true,
'paysafe.com': true,
'squareup.com': true,
'staxpayments.com': true,
'stripe.com': true,
'tabapay.com': true,
'tilled.com': true,
'tsys.com': true,
'wellsfargo.com': true,
'adyen.com': true,
'bambora.com': true,
'braintreepayments.com': true,
'cardconnect.com': true,
'elavon.com': true,
'fortispay.com': true,
'paydock.com': true,
'svb.com': true,
'cardknox.com': true,
'nium.com': true,
'amaryllispay.com': true,
'worldpay.com': true,
'checkout.com': true,
'clover.com': true,
'nmi.com': true,
'moov.io' : true,
}

