export const TRANSFERS = 'TRANSFERS'
export const BALANCE_TRANSFERS = 'BALANCE_TRANSFERS'
export const PAYOUTS = 'PAYOUTS'
export const AUTHORIZATIONS = 'AUTHORIZATIONS'
export const FEES = 'FEES'
export const SETTLEMENTS = 'SETTLEMENTS'
export const DISPUTES = 'DISPUTES'
export const REPORTS = 'REPORTS'
export const IDENTITIES = 'IDENTITIES'
export const MERCHANTS = 'MERCHANTS'
export const APPLICATIONS = 'APPLICATIONS'
export const APPLICATION_USERS = 'APPLICATION_USERS'
export const PAYMENT_INSTRUMENTS = 'PAYMENT_INSTRUMENTS'
export const REVIEW_QUEUE = 'REVIEW_QUEUE'
export const EVIDENCES = 'EVIDENCES'
export const USERS = 'USERS'
export const ADMIN_USERS = 'ADMIN_USERS'
export const VERIFICATIONS = 'VERIFICATIONS'
export const PROCESSORS = 'PROCESSORS'
export const WEBHOOKS = 'WEBHOOKS'
export const WEBHOOK_LOGS = 'WEBHOOK_LOGS'
export const CREDENTIALS = 'CREDENTIALS'
export const CONFIGURATIONS = 'CONFIGURATIONS'
export const PLATFORMS = 'PLATFORMS'
export const PLATFORM_PROCESSOR_CONFIGS = 'PLATFORM_PROCESSOR_CONFIGS'
export const MERCHANT_COMPLIANCE_FORMS = 'MERCHANT_COMPLIANCE_FORMS'
export const COMPLIANCE_FORMS = 'COMPLIANCE_FORMS'
export const COMPLIANCE_FORM_TEMPLATES = 'COMPLIANCE_FORM_TEMPLATES'
export const COMPLIANCE_FORM_TEMPLATE_FORMS = 'COMPLIANCE_FORM_TEMPLATE_FORMS'
export const UNDERWRITING = 'UNDERWRITING'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const ONBOARDING_FORMS = 'ONBOARDING_FORMS'
export const MEMBERS = 'MEMBERS'
export const UNDERWRITING_REVIEWS = 'UNDERWRITING_REVIEWS'
export const CUSTOMER_ONBOARDING_FORMS = 'CUSTOMER_ONBOARDING_FORMS'
export const UNDERWRITING_EVENTS = 'UNDERWRITING_EVENTS'
export const PAYMENT_LINKS = 'PAYMENT_LINKS'
export const PURCHASES = 'PURCHASES'
export const PAYMENT_DEVICES = 'PAYMENT_DEVICES'
export const BALANCE_ENTRIES = 'BALANCE_ENTRIES'
export const BALANCE_ADJUSTMENTS_LINK = 'BALANCE_ADJUSTMENTS'
export const SUBSCRIPTIONS_LINKS_KEY = 'SUBSCRIPTIONS'
export const SUBSCRIPTION_PLANS_LINKS_KEY = 'SUBSCRIPTION_PLANS'

export const IDENTITY_ASSOCIATED_IDENTITIES = 'IDENTITY_ASSOCIATED_IDENTITIES'
export const IDENTITY_AUTHORIZATIONS = 'IDENTITY_AUTHORIZATIONS'
export const IDENTITY_DISPUTES = 'IDENTITY_DISPUTES'
export const IDENTITY_MERCHANTS = 'IDENTITY_MERCHANTS'
export const IDENTITY_TRANSFERS = 'IDENTITY_TRANSFERS'
export const IDENTITY_PAYOUTS = 'IDENTITY_PAYOUTS'
export const IDENTITY_SETTLEMENTS = 'IDENTITY_SETTLEMENTS'
export const IDENTITY_PAYMENT_INSTRUMENTS = 'IDENTITY_PAYMENT_INSTRUMENTS'
export const IDENTITY_VERIFICATIONS = 'IDENTITY_VERIFICATIONS'
export const IDENTITY_FILES = 'IDENTITY_FILES'

export const MERCHANT_TRANSFERS = 'MERCHANT_TRANSFERS'
export const MERCHANT_AUTHORIZATIONS = 'MERCHANT_AUTHORIZATIONS'
export const MERCHANT_SETTLEMENTS = 'MERCHANT_SETTLEMENTS'
export const MERCHANT_FEES = 'MERCHANT_FEES'
export const MERCHANT_SUBSCRIPTION_ENROLLMENTS = 'MERCHANT_SUBSCRIPTION_ENROLLMENTS'
export const MERCHANT_FILES = 'MERCHANT_FILES'
export const MERCHANT_STATEMENTS = 'MERCHANT_STATEMENTS'

export const APPLICATION_DISPUTES = 'APPLICATION_DISPUTES'
export const APPLICATION_IDENTITIES = 'APPLICATION_IDENTITIES'
export const APPLICATION_MERCHANTS = 'APPLICATION_MERCHANTS'
export const APPLICATION_PROCESSORS = 'APPLICATION_PROCESSORS'
export const APPLICATION_SETTLEMENTS = 'APPLICATION_SETTLEMENTS'
export const APPLICATION_TRANSFERS = 'APPLICATION_TRANSFERS'
export const APPLICATION_PAYOUTS = 'APPLICATION_PAYOUTS'
export const APPLICATION_ASSOCIATED_IDENTITIES = 'APPLICATION_ASSOCIATED_IDENTITIES'

export const PAYMENT_INSTRUMENT_TRANSFERS = 'PAYMENT_INSTRUMENT_TRANSFERS'
export const PAYMENT_INSTRUMENT_AUTHORIZATIONS = 'PAYMENT_INSTRUMENT_AUTHORIZATIONS'
export const PAYMENT_INSTRUMENT_VERIFICATIONS = 'PAYMENT_INSTRUMENT_VERIFICATIONS'

export const SETTLEMENT_ENTRIES = 'SETTLEMENT_ENTRIES'
export const SETTLEMENT_FUNDING_TRANSFERS = 'SETTLEMENT_FUNDING_TRANSFERS'

export const DISPUTE_ADJUSTMENT_TRANSFERS = 'DISPUTE_ADJUSTMENT_TRANSFERS'
export const DISPUTE_EVIDENCES = 'DISPUTE_EVIDENCES'

export const TRANSFERS_SCORE = 'TRANSFERS_SCORE'
export const DISPUTES_SCORE = 'DISPUTES_SCORE'
export const AUTHORIZATIONS_SCORE = 'AUTHORIZATIONS_SCORE'
export const SETTLEMENTS_SCORE = 'SETTLEMENTS_SCORE'
export const IDENTITIES_SCORE = 'IDENTITIES_SCORE'
export const MERCHANTS_SCORE = 'MERCHANTS_SCORE'
export const PAYMENT_INSTRUMENTS_SCORE = 'PAYMENT_INSTRUMENTS_SCORE'

export const SUBSCRIPTION_SCHEDULES = 'SUBSCRIPTION_SCHEDULES'
export const SUBSCRIPTION_SCHEDULE_AMOUNTS = 'SUBSCRIPTION_SCHEDULE_AMOUNTS'
export const SUBSCRIPTION_SCHEDULE_ENROLLMENTS = 'SUBSCRIPTION_SCHEDULE_ENROLLMENTS'
export const SUBSCRIPTION_SCHEDULE_FEES = 'SUBSCRIPTION_SCHEDULE_FEES'
export const SUBSCRIPTION_FEES = 'SUBSCRIPTION_FEES'

export const PAYOUT_PLANS = 'PAYOUT_PLANS'
export const API_LOGS = 'API_LOGS'
export const REVIEW_RUNS = 'REVIEW_RUNS'
export const TRANSFER_ATTEMPTS = 'TRANSFER_ATTEMPTS'

export const MONITORING_ALERTS = 'MONITORING_ALERTS'
export const MONITORING_ALERT_RELATED_TRANSFERS = 'MONITORING_ALERT_RELATED_TRANSFERS'
export const MONITORING_ALERT_RELATED_ALERTS = 'MONITORING_ALERT_RELATED_ALERTS'
