import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeactivateTeamMember from './DeactivateTeamMember'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import patchCredentialRequest from 'utilities/actions/patch/patchCredentialRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { DEACTIVATE_TEAM_MEMBER } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const memberId = get(props, 'memberId')
  const memberName = get(props, 'memberName')

  return {
    credentials,
    credentialId,
    memberId,
    memberName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deactivateTeamMemberAction: ({ credentials, memberName, memberId, dashboardName }) => dispatch(patchCredentialRequest({
      credentials,
      memberName,
      memberId,
      dashboardName,
      amplitudeActionType: DEACTIVATE_TEAM_MEMBER,
      values: {
        enabled: false,
      },
      successMessage: `${memberName} has been successfully deactivated from this dashboard`,
    })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class DeactivateTeamMemberC extends Component {
  deactivateTeamMember = () => {
    const {
      credentials,
      memberId,
      memberName,
      deactivateTeamMemberAction,
      closeModal,
      dashboardName,
    } = this.props

    deactivateTeamMemberAction({ credentials, memberId, memberName, dashboardName })
    closeModal()
  }

  render() {
    return (
      <DeactivateTeamMember
        {...this.props}
        deactivateTeamMember={this.deactivateTeamMember}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateTeamMemberC)
