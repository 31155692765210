import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import SubmitToUnderwritingC from 'components/Customer/Pages/SubmitToUnderwriting/SubmitToUnderwritingC'

const SendToUnderwritingModal = ({
  customerOnboardingFormId = '',
}) => {
  return (
    <GenericModal
      title='Submit To Underwriting'
      customerOnboardingFormId={customerOnboardingFormId}
      Component={SubmitToUnderwritingC}
    />
  )
}

SendToUnderwritingModal.propTypes = {
  customerOnboardingFormId: PropTypes.string,
}

export default SendToUnderwritingModal
