import './ActionStatusFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Shared/Button/Button'
import PrettyJSON from 'components/Customer/Shared/Page/PrettyJSON/PrettyJSON'
import get from 'lodash/get'
import map from 'lodash/map'
import { SUBMIT } from 'constants/language/languageConstants'

const ActionStatusForm = ({
  closeModal = () => {},
  confirmationAction = () => {},
  recordIds = [],
  recordType = 'Record',
  status = {},
  values = {},
  completed = false,
}) => {
  const headerMessage = `You are about to update ${recordIds.length} ${recordType}(s)`
  const closeLabel = completed ? 'Done' : 'Cancel'

  return (
    <div className='ActionStatusForm'>
      <h3>{headerMessage}</h3>

      <div className='section'>
        <h2>Values</h2>
        <PrettyJSON classNames='value-json' data={values} />
      </div>

      <div className='section'>
        <h2>Status</h2>

        <div className='status-entries'>
          { map(recordIds, (recordId) => {
            const recordStatus = get(status, recordId)
            const iconClass = classnames('fa', {
              'fa-check-circle': recordStatus,
              'fa-exclamation-triangle': recordStatus === false,
              'fa-question-circle': recordStatus === undefined,
            })

            return (
              <div className='flex status-entry' key={recordId}>
                <i className={iconClass} />
                <span className='record-id'>{recordId}</span>
              </div>
            )
          })}
        </div>
      </div>

      <div className='section flex flex-end'>
        <Button onClick={closeModal} label={closeLabel} variant='ghost' className='cancel-button' />
        {!completed && <Button onClick={confirmationAction} label={SUBMIT} /> }
      </div>
    </div>
  )
}

ActionStatusForm.propTypes = {
  closeModal: PropTypes.func,
  confirmationAction: PropTypes.func,
  recordIds: PropTypes.array,
  recordType: PropTypes.string,
  status: PropTypes.object,
  values: PropTypes.object,
  completed: PropTypes.bool,
}

export default ActionStatusForm
