import './AddIdentityBankAccountFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import submitAddIdentityBankAccountForm from 'utilities/submit/submitAddIdentityBankAccountForm'
import validateAddIdentityBankAccountForm from 'utilities/validate/validateAddIdentityBankAccountForm'
import formatNumberField from 'utilities/forms/format/formatNumberField'
import { ADD_IDENTITY_BANK_ACCOUNT_FORM } from 'constants/formConstants'
import { SETTLEMENT_ALIAS_OPTIONS } from 'constants/paymentInstrumentConstants'

import {
  CANCEL,
  ADD_BANK_ACCOUNT,
  IDENTITY_ID,
  RECIPIENT_IDENTITY_ID,
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_TYPE,
  ROUTING_NUMBER,
  ACCOUNT_NUMBER,
  SUBMIT,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
} from 'constants/language/languageConstants'

import {
  CAN,
  USA,
} from 'constants/countryConstants'

const AddIdentityBankAccountForm = ({
  handleSubmit = () => {},
  submitting = false,
  accountTypeOptions = [],
  showSettlementAlias = false,
  warningMessage,
  hasRecipientRole = true,
  isPatching = false,
  closeModal = () => {},
  invalid = false,
  identityCountry = '',
}) => {
  const showRoutingNumber = identityCountry === USA
  const showTransitNumber = identityCountry === CAN
  const showInstitutionNumber = identityCountry === CAN

  return (
    <form className='AddIdentityBankAccountForm' onSubmit={handleSubmit(submitAddIdentityBankAccountForm)}>

      <div className='title'>
        <h4>{ADD_BANK_ACCOUNT}</h4>
      </div>

      <Field
        name='identity'
        className='identity-field'
        label={hasRecipientRole ? RECIPIENT_IDENTITY_ID : IDENTITY_ID}
        component={InputField}
        disabled
      />

      <Field
        name='name'
        label={ACCOUNT_HOLDER_NAME}
        component={InputField}
      />

      <Field
        name='accountType'
        label={ACCOUNT_TYPE}
        component={SelectField}
        options={accountTypeOptions}
      />

      {showRoutingNumber && (
        <Field
          name='routingNumber'
          label={ROUTING_NUMBER}
          component={InputField}
          format={formatNumberField}
        />
      )}

      {showTransitNumber && (
        <Field
          name='transitNumber'
          label={TRANSIT_NUMBER}
          component={InputField}
          format={formatNumberField}
        />
      )}

      {showInstitutionNumber && (
        <Field
          name='institutionNumber'
          label={INSTITUTION_NUMBER}
          component={InputField}
          format={formatNumberField}
        />
      )}

      <Field
        name='accountNumber'
        label={ACCOUNT_NUMBER}
        component={InputField}
        format={formatNumberField}
      />

      <Field
        name='confirmAccountNumber'
        label='Confirm Account Number'
        component={InputField}
        format={formatNumberField}
      />

      { showSettlementAlias && (
        <Field
          name='settlementAlias'
          label='Settlement Alias'
          component={SelectField}
          options={SETTLEMENT_ALIAS_OPTIONS}
        />
      ) }

      <WarningMessage warningMessage={warningMessage} />

      <div className='buttons flex flex-end'>
        <Button className='cancel-button' onClick={closeModal} size='large' label={CANCEL} variant='ghost' disabled={isPatching} />
        <Button type='submit' size='large' label={SUBMIT} submitting={submitting} disabled={isPatching || invalid} />
      </div>
    </form>
  )
}

AddIdentityBankAccountForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  accountTypeOptions: PropTypes.array,
  showSettlementAlias: PropTypes.bool,
  warningMessage: PropTypes.string,
  hasRecipientRole: PropTypes.bool,
  isPatching: PropTypes.bool,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  identityCountry: PropTypes.string,
}

export default reduxForm({
  form: ADD_IDENTITY_BANK_ACCOUNT_FORM,
  validate: validateAddIdentityBankAccountForm,
})(AddIdentityBankAccountForm)
