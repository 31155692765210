import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_RUN_REPORTS } from 'constants/apiConstants'

const getUnderwritingRunReportsAPI = ({ id, credentials, queries, meta }) => {
  return paymentsAPI.get({
    path: UNDERWRITING_RUN_REPORTS({ runId: id }),
    credentials,
    queries,
    meta,
  })
}

export default getUnderwritingRunReportsAPI
