import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'

const frontendPlaidTokenModel = ({ data: plaidToken }) => {
  const [
    countries,
    createdAt,
    expiresAt,
    id,
    language,
    token,
    type,
    updatedAt,
  ] = getMany(plaidToken, [
    'countries',
    'created_at',
    'expires_at',
    'id',
    'language',
    'token',
    'type',
    'updated_at',
  ])

  return removeUndefined({
    countries,
    createdAt: formatDate({ date: createdAt }),
    expiresAt: formatDate({ date: expiresAt }),
    id,
    language,
    token,
    type,
    updatedAt: formatDate({ date: updatedAt }),
  })
}

export default frontendPlaidTokenModel
