import { PATHNAME } from 'constants/queryConstants'
import { getState } from 'state-layer/configureStore'
import split from 'lodash/split'
import get from 'lodash/get'

const getCurrentCredentialIdFromURL = (state = getState()) => {
  const pathname = get(state, PATHNAME) ? get(state, PATHNAME) : window.location.pathname.substr(1)
  return get(split(pathname, '/'), '[1]')
}

export default getCurrentCredentialIdFromURL
