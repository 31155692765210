import React, { Component } from 'react'
import { connect } from 'react-redux'
import WithdrawOrApproveUnderwritingEntity from './WithdrawOrApproveUnderwritingEntity'
import hideModalAction from 'utilities/actions/hideModalAction'
import patchUnderwritingReviewRequest from 'utilities/actions/patch/patchUnderwritingReviewRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import postUnderwritingRunSilenceRequest from 'utilities/actions/post/postUnderwritingRunSilenceRequest'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { FETCHING } from 'constants/reducerConstants'
import { UPDATE_UNDERWRITING_REVIEW_ITEM } from 'constants/amplitudeConstants'
import get from 'lodash/get'

import {
  ADVERSE_MEDIA_WORKFLOW,
  APPROVED,
  CLOSED,
  SANCTIONS_WORKFLOW,
} from 'constants/underwritingReviewConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const reviewId = get(props, 'reviewId')
  const isApproveMerchantModal = get(props, 'isApproveMerchantModal')
  const credentials = getCurrentCredentials(state)
  const dashboardUserEmail = get(getDashboardUser(), CLIENT_EMAIL)

  return {
    isFetching,
    isApproveMerchantModal,
    reviewId,
    credentials,
    dashboardUserEmail,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    patchUnderwritingReview: (actionProps) => dispatch(patchUnderwritingReviewRequest(actionProps)),
    postRunSilence: (actionProps) => dispatch(postUnderwritingRunSilenceRequest(actionProps)),
  }
}

class WithdrawOrApproveUnderwritingEntityC extends Component {
  updateUnderwritingEntityStatus = () => {
    const {
      reviewId,
      isApproveMerchantModal,
      credentials,
      patchUnderwritingReview,
      closeModal,
      subjectId,
      dashboardUserEmail,
      reviewType,
      runId,
      postRunSilence,
    } = this.props

    patchUnderwritingReview({
      id: reviewId,
      subjectId,
      state: isApproveMerchantModal ? APPROVED : CLOSED,
      credentials,
      meta: {
        actionId: sendAmplitudeActionEvent(UPDATE_UNDERWRITING_REVIEW_ITEM, {
          credentials,
          id: reviewId,
          subjectId,
          state: isApproveMerchantModal ? APPROVED : CLOSED,
          decisionBy: dashboardUserEmail,
        }),
        successCallback: () => {
          if ((reviewType === SANCTIONS_WORKFLOW || reviewType === ADVERSE_MEDIA_WORKFLOW) && isApproveMerchantModal) {
            postRunSilence({
              id: runId,
              credentials,
            })
            closeModal()
          } else {
            closeModal()
          }
        },
      },
    })
  }

  render() {
    return (
      <WithdrawOrApproveUnderwritingEntity
        {...this.props}
        updateUnderwritingEntityStatus={this.updateUnderwritingEntityStatus}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawOrApproveUnderwritingEntityC)
