import createAction from 'utilities/actions/createAction'
import updateUrlQueries from 'utilities/updateUrlQueries'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { getReviewQueueAmplitudeAction } from 'constants/reviewQueueConstants'
import { REVIEW_QUEUE } from 'constants/linkConstants'
import get from 'lodash/get'
import head from 'lodash/head'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  UNSELECT_ALL_ITEMS,
  PATCH_REVIEW_QUEUE_ITEMS_F_REQUEST,
} from 'constants/flowConstants'

const patchReviewQueueItemsRequest = ({
  items,
  queries = {},
  credentials,
  selectedItemsKey,
  dispatch,
}) => {
  const firstItem = head(items) || {}

  const {
    entityType,
    outcome,
  } = firstItem

  const entityIds = map(items, ({ entityId }) => entityId)
  const reviewQueueIds = map(items, ({ id }) => id)
  const amplitudeActionName = getReviewQueueAmplitudeAction({ entityType, outcome })
  const hour = new Date().getHours()

  return createAction({
    type: PATCH_REVIEW_QUEUE_ITEMS_F_REQUEST,
    values: {
      items,
    },
    linksKey: REVIEW_QUEUE,
    queries,
    credentials,
    meta: {
      successCallback: () => {
        // TODO: create action dispatcher function for this
        if (dispatch) {
          dispatch({
            type: UNSELECT_ALL_ITEMS,
            payload: {
              key: selectedItemsKey,
            },
          })
        }
      },
      actionId: sendAmplitudeActionEvent(amplitudeActionName, {
        credentials,
        entityIds,
        reviewQueueIds,
        entityType,
        outcome,
        count: get(reviewQueueIds, 'length', 0),
        hour,
      }),
    },
  })
}


export default patchReviewQueueItemsRequest
