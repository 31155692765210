import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { EDIT_COMPLIANCE_FORM_TEMPLATE } from 'constants/amplitudeConstants'
import get from 'lodash/get'

import {
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
  GET_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
} from 'constants/flowConstants'

const submitEditComplianceFormTemplateForm = (values, dispatch, props) => {
  const { credentials, complianceFormTemplate } = props
  const id = get(complianceFormTemplate, 'id')

  dispatch({
    type: PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
    payload: {
      values,
      credentials,
      id,
    },
    meta: {
      successCallback: () => {
        dispatch({
          type: GET_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
          payload: {
            id,
            credentials,
          },
        })
      },
      actionId: sendAmplitudeActionEvent(EDIT_COMPLIANCE_FORM_TEMPLATE, {
        id,
        values,
        credentials,
      }),
    },
  })
}

export default submitEditComplianceFormTemplateForm
