import removeUndefined from 'utilities/remove/removeUndefined'
import formatDate from 'utilities/formatters/formatDate'
import convertSnakeToSentenceCase from 'utilities/convert/convertSnakeToSentenceCase'
import getMany from 'utilities/get/getMany'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'

const frontendFileModel = ({ data: file }) => {
  const [
    id,
    createdAt,
    updatedAt,
    displayName,
    extension,
    linkedTo,
    linkedType,
    status,
    type,
    tags,
    externalLink,
  ] = getMany(file, [
    'id',
    'created_at',
    'updated_at',
    'display_name',
    'extension',
    'linked_to',
    'linked_type',
    'status',
    'type',
    'tags',
    'externalLink',
  ])

  const displayCreatedAt = formatDate({ date: createdAt })
  const displayUpdatedAt = formatDate({ date: updatedAt })
  const displayType = convertSnakeToSentenceCase(type)
  const displayStatus = startCase(lowerCase(status))

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    displayCreatedAt,
    displayUpdatedAt,
    displayName,
    extension,
    linkedTo,
    linkedType,
    status,
    displayStatus,
    type,
    displayType,
    tags,
    externalLink,
  })
}

export default frontendFileModel
