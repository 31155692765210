import './IdentityEditS.scss'
import React from 'react'
import IdentityEditFormC from 'components/Customer/Forms/IdentityEditForm/IdentityEditFormC'

const IdentityEdit = (props) => {
  return (
    <div className='IdentityEdit'>
      <div className='content-header flex space-between'>
        <h1>Edit Identity</h1>
      </div>

      <div className='content-card'>
        <IdentityEditFormC {...props} />
      </div>
    </div>
  )
}

export default IdentityEdit
