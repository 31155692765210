import BalanceTransfer from './BalanceTransfer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getBalanceTransferSelector } from 'state-layer/selectors'
import BalanceTransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/BalanceTransferStatus'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getBalanceTransferRequest from 'utilities/actions/get/getBalanceTransferRequest'
import formatAmountHeaderTitle from 'utilities/formatters/formatAmountHeaderTitle'
import getMany from 'utilities/get/getMany'
import { sourceDestinationMapping } from 'constants/balanceTransferConstants'
import { FETCHING } from 'constants/reducerConstants'
import { COMPANY_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `balanceTransfersR.${FETCHING}`)
  const balanceTransferId = get(props, 'params.balanceTransferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const balanceTransfer = getBalanceTransferSelector(state, balanceTransferId)

  const [
    displayAmount,
    description,
    source,
    destination,
    tags,
    displayCreatedAt,
    status,
    externalReferenceId,
    referenceId,
    processor,
  ] = getMany(balanceTransfer, [
    'displayAmount',
    'description',
    'source',
    'destination',
    'tags',
    'displayCreatedAt',
    'state',
    'externalReferenceId',
    'referenceId',
    'processor',
  ])

  const contextBarData = {
    back: {
      label: 'Balance Transfers',
      path: `${COMPANY_PATH({ credentialId })}?tab=balance-transfers`,
    },
  }

  const headerData = {
    resource: {
      label: 'Balance Transfer',
      id: balanceTransferId,
    },
    items: [
      {
        value: <h1>{formatAmountHeaderTitle({ displayAmount })}</h1>,
      },
      {
        label: 'State',
        value: <BalanceTransferStatus value={status} />,
      },
      {
        label: 'Created On',
        value: displayCreatedAt,
      },
    ],
    isFetching,
  }

  const detailsSectionData = [
    {
      label: 'Description',
      value: description,
    },
    {
      label: 'Source',
      value: source,
      formatter: ({ value }) => sourceDestinationMapping[value],
    },
    {
      label: 'Destination',
      value: destination,
      formatter: ({ value }) => sourceDestinationMapping[value],
    },
    {
      label: 'Processor',
      value: processor,
    },
    {
      label: 'External Reference ID',
      value: externalReferenceId,
    },
    {
      label: 'Reference ID',
      value: referenceId,
    },
    {
      label: 'State',
      value: status,
      formatter: ({ value }) => <BalanceTransferStatus value={value} />,
    },
  ]

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  return {
    isFetching,
    balanceTransferId,
    credentials,
    detailsSectionData,
    tagsSectionData,
    balanceTransfer,
    contextBarData,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceTransfer: ({ credentials, balanceTransferId }) => { dispatch(getBalanceTransferRequest({ credentials, balanceTransferId })) },
  }
}

class BalanceTransferC extends Component {
  componentDidMount() {
    const { balanceTransferId, getBalanceTransfer, credentials } = this.props

    getBalanceTransfer({ credentials, balanceTransferId })
  }

  componentDidUpdate(prevProps) {
    const { balanceTransferId, getBalanceTransfer, credentials } = this.props
    const { balanceTransferId: prevBalanceTransferId } = prevProps

    if (balanceTransferId !== prevBalanceTransferId) {
      getBalanceTransfer({ credentials, balanceTransferId })
    }
  }

  render() {
    return (
      <BalanceTransfer
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceTransferC)
