import hideModalAction from 'utilities/actions/hideModalAction'
import updateUrlQueries from 'utilities/updateUrlQueries'
import get from 'lodash/get'

const submitSelectPaymentLinkMerchantForm = (values, dispatch) => {
  const merchantId = get(values, 'merchant.value')

  dispatch(hideModalAction())

  updateUrlQueries({ merchantId })
}

export default submitSelectPaymentLinkMerchantForm
