import './TableFooterS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Loader from 'components/Shared/Loader/Loader'
import formatNumber from 'utilities/formatters/formatNumber'
import { TABLE_FOOTER_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const TableFooter = ({
  page = {},
  itemsCount = 0,
  isFetching = false,
}) => {
  const totalCount = get(page, 'count')
  const formattedItemCount = formatNumber(itemsCount)
  const formattedTotalCount = itemsCount > totalCount ? formatNumber(itemsCount) : formatNumber(totalCount)
  const showCount = itemsCount > 0 && totalCount > 0

  return (
    <form className='TableFooter flex space-between items-center'>
      { isFetching && <Loader /> }

      <div>
        <div>{ showCount && (<>Showing: <span className='resultCounts'>{formattedItemCount} / {formattedTotalCount}{totalCount === 10000 && '+'}</span></>) }</div>
      </div>
    </form>
  )
}

TableFooter.propTypes = {
  page: PropTypes.object,
  itemsCount: PropTypes.number,
  isFetching: PropTypes.bool,
}

export default reduxForm({
  form: TABLE_FOOTER_FORM,
})(TableFooter)
