import './PaymentInstrumentPaymentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import defaultColumnDescriptors from './columnDescriptors'
import { PAYMENT_INSTRUMENT_TRANSFERS } from 'constants/linkConstants'
import { GET_PAYMENT_INSTRUMENT_TRANSFERS } from 'constants/flowConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'
import { buildPaymentInstrumentTransfers } from 'state-layer/selectors'

const PaymentInstrumentPayments = ({
  flowValues = {},
  initialQueries = {},
  paymentPath = '',
  isFetching = false,
  paymentInstrumentId = '',
  allowedFilters = [],
  quickFilters = [],
  columnDescriptors = defaultColumnDescriptors,
}) => {
  return (
    <div className='PaymentInstrumentPayments'>
      <TableC
        flow={GET_PAYMENT_INSTRUMENT_TRANSFERS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={buildPaymentInstrumentTransfers}
        selectorId={paymentInstrumentId}
        initialQueries={initialQueries}
        linksKey={PAYMENT_INSTRUMENT_TRANSFERS}
        path={paymentPath}
        isFetching={isFetching}
        icon={`fa fa-${PAYMENTS_ICON}`}
        fileNameCSV={`PaymentInstrument_${paymentInstrumentId}_Transfers`}
        allowedFilters={allowedFilters}
        quickFilters={quickFilters}
      />
    </div>
  )
}

PaymentInstrumentPayments.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  paymentPath: PropTypes.string,
  isFetching: PropTypes.bool,
  paymentInstrumentId: PropTypes.string,
  allowedFilters: PropTypes.array,
  quickFilters: PropTypes.array,
  columnDescriptors: PropTypes.array,
}

export default PaymentInstrumentPayments
