import './WebhookFormS.scss'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import WarningMessage from 'components/Customer/Shared/WarningMessage/WarningMessage'
import NestedCheckboxesTable from 'components/Shared/Inputs/NestedCheckboxesTable/NestedCheckboxesTable'
import Button from 'components/Shared/Button/Button'
import submitWebhookForm from 'utilities/submit/submitWebhookForm'
import validateWebhookForm from 'utilities/validate/validateWebhookForm'
import { WEBHOOK_FORM } from 'constants/formConstants'

import {
  WEBHOOKS_AUTHENTICATION_LEARN_MORE_LINK,
  WEBHOOK_EVENTS_LEARN_MORE_LINK,
} from 'constants/urlConstants'

import {
  BASIC_VALUE,
  BEARER_TOKEN_VALUE,
  NONE_VALUE,
  AUTHENTICATION_TYPE_OPTIONS,
  WEBHOOK_ENABLE_EVENTS_OPTIONS,
  WEBHOOK_EVENT_CHECKBOX_OPTIONS,
} from 'constants/webhookConstants'

import {
  LEARN_MORE_LABEL,
  ENDPOINT,
  URL,
  AUTHENTICATION,
  AUTHENTICATION_TYPE,
  EVENTS,
  WEBHOOK_EVENTS_DESCRIPTION,
  ADD_BASIC_AUTHENTICATION_CREDENTIALS,
  ADD_A_BEARER_TOKEN,
  BEARER_TOKEN,
  CREATE_USERNAME,
  CREATE_PASSWORD,
  WEBHOOKS_BASIC_AUTHENTICATION_DESCRIPTION,
  WEBHOOKS_BEARER_TOKEN_AUTHENTICATION_DESCRIPTION,
  WEBHOOKS_NO_AUTHENTICATION_DESCRIPTION,
  AVAILABLE_ENTITIES_AND_TYPES,
  CANCEL,
  SET_NEW_CREDENTIALS,
  SET_NEW_BEARER_TOKEN,
  NICKNAME,
} from 'constants/language/languageConstants'

const WebhookForm = ({
  handleSubmit = () => {},
  currentAuthenticationType = BASIC_VALUE,
  allEventsSelected = true,
  noItemsSelected = false,
  enableAuthorizationInput = () => {},
  disableBasicAuthorizationInput = false,
  disableBearerTokenInput = false,
  currentSelectedEvents,
  cancelRedirectPath,
  eventsTableValidationMsg,
  submitButtonLabel,
  selectedEventsTouched = false,
}) => {
  return (
    <form className='WebhookForm' onSubmit={handleSubmit(submitWebhookForm)}>
      <div className='section'>
        <h2 className='section-title'>{ENDPOINT}</h2>

        <Field
          name='endpointUrl'
          label={URL}
          placeholder='https://example.com'
          component={InputField}
        />

        <Field
          name='nickname'
          label={NICKNAME}
          component={InputField}
          required={false}
        />
      </div>

      <div className='section'>
        <h2 className='section-title'>{AUTHENTICATION}</h2>

        <Field
          className='authentication-type'
          name='authenticationType'
          label={AUTHENTICATION_TYPE}
          component={SelectField}
          options={AUTHENTICATION_TYPE_OPTIONS}
        />

        {currentAuthenticationType === BASIC_VALUE && (
          <div className='basic-authentication-form-section'>
            <div>{ADD_BASIC_AUTHENTICATION_CREDENTIALS}:</div>

            <div className='basic-auth-inputs'>
              <Field
                className='username-input'
                name='basicAuthenticationUsername'
                label={CREATE_USERNAME}
                component={InputField}
                disabled={disableBasicAuthorizationInput}
              />

              <Field
                className='password-input'
                name='basicAuthenticationPassword'
                label={CREATE_PASSWORD}
                component={InputField}
                disabled={disableBasicAuthorizationInput}
              />
            </div>

            {disableBasicAuthorizationInput ? <Button onClick={() => enableAuthorizationInput('basic')} label={SET_NEW_CREDENTIALS} variant='secondary' /> : (
              <div>{WEBHOOKS_BASIC_AUTHENTICATION_DESCRIPTION}
                <a className='text-link learn-more-link' href={WEBHOOKS_AUTHENTICATION_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
              </div>
            )}
          </div>
        )}

        {currentAuthenticationType === BEARER_TOKEN_VALUE && (
          <div className='bearer-token-form-section'>
            <div>{ADD_A_BEARER_TOKEN}:</div>

            <div className='bearer-token-input-section'>
              <Field
                className='bearer-token-input'
                name='bearerToken'
                label={`${BEARER_TOKEN} (max 4,000 characters)`}
                component={TextAreaField}
                disabled={disableBearerTokenInput}
              />
            </div>

            {disableBearerTokenInput ? <Button onClick={() => enableAuthorizationInput('bearer')} label={SET_NEW_BEARER_TOKEN} /> : (
              <div>{WEBHOOKS_BEARER_TOKEN_AUTHENTICATION_DESCRIPTION}
                <a className='text-link learn-more-link' href={WEBHOOKS_AUTHENTICATION_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
              </div>
            )}
          </div>
        )}

        {currentAuthenticationType === NONE_VALUE && (
          <WarningMessage warningMessage={WEBHOOKS_NO_AUTHENTICATION_DESCRIPTION} />
        )}
      </div>

      <div className='section'>
        <h2 className='section-title'>{EVENTS}</h2>

        <div className='events-description'>{WEBHOOK_EVENTS_DESCRIPTION}
          <a className='text-link learn-more-link' href={WEBHOOK_EVENTS_LEARN_MORE_LINK} target='blank'>{LEARN_MORE_LABEL}</a>
        </div>

        <Field
          name='eventsChoice'
          component={RadioButton}
          options={WEBHOOK_ENABLE_EVENTS_OPTIONS}
        />

        <Field
          name='selectedEvents'
          title={AVAILABLE_ENTITIES_AND_TYPES}
          component={NestedCheckboxesTable}
          options={WEBHOOK_EVENT_CHECKBOX_OPTIONS}
          disabled={allEventsSelected}
          currentSelectedEvents={currentSelectedEvents}
          eventsTableValidationMsg={eventsTableValidationMsg}
          formName={WEBHOOK_FORM}
        />
      </div>

      <div className='buttons flex flex-end items-center'>
        <Link to={cancelRedirectPath}><div className='cancel'>{CANCEL}</div></Link>
        <Button type='submit' label={submitButtonLabel} disabled={noItemsSelected && selectedEventsTouched} />
      </div>
    </form>
  )
}

WebhookForm.propTypes = {
  handleSubmit: PropTypes.func,
  currentAuthenticationType: PropTypes.string,
  allEventsSelected: PropTypes.bool,
  noItemsSelected: PropTypes.bool,
  disableBasicAuthorizationInput: PropTypes.bool,
  disableBearerTokenInput: PropTypes.bool,
  enableAuthorizationInput: PropTypes.func,
  currentSelectedEvents: PropTypes.object,
  cancelRedirectPath: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  eventsTableValidationMsg: PropTypes.string,
  selectedEventsTouched: PropTypes.bool,
}

export default reduxForm({
  form: WEBHOOK_FORM,
  validate: validateWebhookForm,
  enableReinitialize: true,
})(WebhookForm)
