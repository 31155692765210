import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  ROLE_ADMIN,
  ROLE_PLATFORM,
  ROLE_PARTNER,
  ROLE_MERCHANT,
  NON_MERCHANT_ROLES,
  ADMIN_PLATFORM_ROLES,
  PARTNER_AND_MERCHANT_ROLES,
  PLATFORM_AND_PARTNER_ROLES,
} from 'constants/roleConstants'

const getRole = ({ credentials }) => {
  return get(credentials, 'role')
}

export const isRoleAdmin = ({ credentials }) => {
  return getRole({ credentials }) === ROLE_ADMIN
}

export const isRolePlatform = ({ credentials }) => {
  return getRole({ credentials }) === ROLE_PLATFORM
}

export const isRolePartner = ({ credentials }) => {
  return getRole({ credentials }) === ROLE_PARTNER
}

export const isRoleMerchant = ({ credentials }) => {
  return getRole({ credentials }) === ROLE_MERCHANT
}

export const notRoleMerchant = ({ credentials }) => {
  return !isRoleMerchant({ credentials })
}

export const isRolePartnerOrMerchant = ({ credentials }) => {
  return includes(PARTNER_AND_MERCHANT_ROLES, getRole({ credentials }))
}

export const hasPlatformAccess = ({ credentials }) => {
  return includes(ADMIN_PLATFORM_ROLES, getRole({ credentials }))
}

export const hasPartnerAccess = ({ credentials }) => {
  return includes(NON_MERCHANT_ROLES, getRole({ credentials }))
}

export const hasPartnerPlatformAccess = ({ credentials }) => {
  return includes(PLATFORM_AND_PARTNER_ROLES, getRole({ credentials }))
}
