import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutPlan from './PayoutPlan'
import { getPayoutPlanSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import getPayoutPlanRequest from 'utilities/actions/get/getPayoutPlanRequest'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import removeUndefined from 'utilities/remove/removeUndefined'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION_PATH,
  IDENTITY_PATH,
  PAYOUT_PLANS_PATH,
  EDIT_PAYOUT_PLANS_PATH,
} from 'constants/pathConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `payoutPlansR.${FETCHING}`)
  const payoutPlanId = get(props, 'params.payoutPlanId') || get(props, 'payoutPlanId')
  const payoutPlan = getPayoutPlanSelector(state, payoutPlanId)

  const [
    name,
    entityId,
    level,
    processor,
    displayCreatedAt,
    merchant,
    application,
  ] = getMany(payoutPlan, [
    'name',
    'entityId',
    'level',
    'processor',
    'displayCreatedAt',
    'merchant',
    'application',
  ])

  const { path, idName } = getIDPrefixMapping(entityId)
  const identityId = get(merchant, 'identityId')
  const applicationId = get(application, 'id')
  const applicationName = get(application, 'businessName')
  const merchantName = get(merchant, 'displayBusinessName')

  const contextBarData = {
    back: {
      label: 'Payout Plans',
      path: PAYOUT_PLANS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: name,
      id: payoutPlanId,
    },
    items: [
      {
        label: 'Created',
        value: displayCreatedAt,
      },
    ],
  }

  const detailsSectionData = convertPageSectionDataToV2([
    {
      label: 'Entity ID',
      value: entityId,
      path: path && path(removeUndefined({
        credentialId,
        [idName]: entityId,
        identityId: get(merchant, 'identityId'),
      })),
    },
    {
      label: 'Level',
      value: capitalize(level),
    },
    {
      label: 'Processor',
      value: processor,
    },
  ])

  // const actions = [
  //   {
  //     label: 'Edit Payout Plan',
  //     link: EDIT_PAYOUT_PLANS_PATH({ credentialId, payoutPlanId }),
  //     className: 'editPayoutPlan',
  //   },
  // ]

  const headerTitle = <span>{name}</span>

  return {
    credentials,
    isFetching,
    headerTitle,
    payoutPlan,
    payoutPlanId,
    detailsSectionData,
    contextBarData,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPayoutPlan: ({ credentials, payoutPlanId }) => { dispatch(getPayoutPlanRequest({ credentials, payoutPlanId })) },
  }
}


class PayoutPlanC extends Component {
  componentDidMount() {
    const {
      credentials,
      payoutPlanId,
      getPayoutPlan,
    } = this.props

    if (payoutPlanId) {
      getPayoutPlan({ payoutPlanId, credentials })
    }
  }

  render() {
    return (
      <PayoutPlan {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutPlanC)
