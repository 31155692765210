import './CustomerOnboardingFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import AccessFormViewC from 'components/Customer/Pages/AccessForm/AccessFormViewC'
import VerificationRejectionDetailsC from 'components/Customer/Shared/Display/VerificationRejectionDetails/VerificationRejectionDetailsC'

import {
  DETAILS,
  REJECTION_DETAILS,
} from 'constants/language/languageConstants'

const CustomerOnboardingForm = ({
  headerData = {},
  contextBarData = {},
  detailsDataSection = [],
  actions = [],
  accessFormId = '',
  showRejectInformationSection = false,
  rejectedReviewReasons = [],
  isFetching,
  isFetchingUnderwritingReview,
}) => {
  return (
    <div className='CustomerOnboardingForm'>
      <ContextBar
        {...contextBarData}
      />

      <HeaderV2C
        {...headerData}
        actions={actions}
        isFetching={isFetching}
      />

      { showRejectInformationSection && (
        <VerificationRejectionDetailsC
          isFetching={isFetchingUnderwritingReview}
          reasons={rejectedReviewReasons}
          asideTitle=''
          title={REJECTION_DETAILS}
        />
      )}

      <PageSectionV2C
        smallTitle={DETAILS}
        columns={detailsDataSection}
        className='details-page-section'
        isFetching={isFetching}
      />

      <AccessFormViewC
        accessFormId={accessFormId}
        isCustomerOnboardingForm
        isFetching={isFetching}
      />
    </div>
  )
}

CustomerOnboardingForm.propTypes = {
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsDataSection: PropTypes.array,
  actions: PropTypes.array,
  accessFormId: PropTypes.string,
  showRejectInformationSection: PropTypes.bool,
  rejectedReviewReasons: PropTypes.array,
  isFetching: PropTypes.bool,
  isFetchingUnderwritingReview: PropTypes.bool,
}

export default CustomerOnboardingForm
