import './LanguageSelectorS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import { changeLanguage } from 'constants/language/i18n'
import { languageCodeToCountryCodeISO3 } from 'constants/language/isoCodeConstants'
import { CHEVRON_DOWN_ICON } from 'constants/iconConstants'
import find from 'lodash/find'
import get from 'lodash/get'

const languageOptions = [
  {
    label: 'English (US)',
    value: 'en-US',
  },
  {
    label: 'French (Canada)',
    value: 'fr-CA',
  },
  // {
  //   label: 'French (France)',
  //   value: 'fr-FR',
  // },
]

const customStyles = {
  menu: (provided) => ({
    ...provided,
    padding: '0px',
    marginTop: '3px',
  }),
}

const customOptionRenderer = (props) => {
  const { data, innerProps, getStyles } = props
  const defaultStyle = getStyles('option', props)
  const country = get(languageCodeToCountryCodeISO3, get(data, 'value'))

  const style = {
    ...defaultStyle,
    display: 'flex',
  }

  return (
    <div className='language-selector-option' {...innerProps} style={style}>
      <CountryFlagIcon
        country={country}
        showCountryName={false}
      />
      <span style={{ marginLeft: '10px' }}>{get(data, 'label')}</span>
    </div>
  )
}

const customValueRenderer = ({ data }) => {
  return (
    <div className='language-selector-value' style={{ display: 'flex' }}>
      <CountryFlagIcon
        country={get(languageCodeToCountryCodeISO3, get(data, 'value'))}
        showCountryName={false}
      />
      <span style={{ marginLeft: '10px' }}>{get(data, 'label')}</span>
    </div>
  )
}

const customDropdownIndicator = ({ innerProps }) => {
  const style = {
    fontSize: '14px',
    lineHeight: '16px',
    padding: '0px 8px',
  }

  return (
    <div className='language-selector-dropdown-indicator' style={style}>
      <i className={`far fa-${CHEVRON_DOWN_ICON}`} />
    </div>
  )
}

const LanguageSelector = ({
  lng = '',
  menuPlacement = 'bottom',
}) => {
  return (
    <div className='LanguageSelector'>
      <Select
        options={languageOptions}
        defaultValue={find(languageOptions, ({ value }) => value === lng)}
        onChange={({ value }) => changeLanguage(value)}
        components={{
          Option: customOptionRenderer,
          SingleValue: customValueRenderer,
          DropdownIndicator: customDropdownIndicator,
        }}
        styles={customStyles}
        menuPlacement={menuPlacement}
      />
    </div>
  )
}

LanguageSelector.propTypes = {
  lng: PropTypes.string,
  menuPlacement: PropTypes.string,
}

export default LanguageSelector
