import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTIONS } from 'constants/apiConstants'

const getSubscriptionsAPI = ({ meta, queries, credentials }) => {
  return paymentsAPI.get({
    meta,
    path: SUBSCRIPTIONS,
    queries,
    credentials,
  })
}

export default getSubscriptionsAPI
