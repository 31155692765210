import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import getMany from 'utilities/get/getMany'
import convertToMilitaryTime from 'utilities/convert/convertToMilitaryTime'
import { normalizeBackendAmount } from 'constants/normalizationConstants'
import redirectRequest from 'utilities/actions/redirectRequest'
import convertAmountStringToNumber from 'utilities/convert/convertAmountStringToNumber'
import { USD } from 'constants/currencyConstants'
import { PATCH_DISBURSEMENT_RULES_F_REQUEST } from 'constants/flowConstants'
import { ALL } from 'constants/transferConstants'
import { GLOBAL } from 'constants/disbursementsConstants'
import { UPDATE_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS } from 'constants/amplitudeConstants'
import { APPLICATION_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  DAILY,
  MONTHLY,
  AMERICA_NY_TIMEZONE_BE_FIELD,
} from 'constants/timeConstants'

const submitBalanceAdjustmentsVelocityLimitsForm = (values, dispatch, props) => {
  const {
    credentials,
    disbursementRulesId,
    applicationId,
  } = props

  const credentialId = get(credentials, 'id')
  const redirectPath = `${APPLICATION_PATH({ credentialId, applicationId })}?tab=velocity-info&secondaryTab=balance-adjustments-limits`
  const formValues = get(props, 'formValues.balanceAdjustmentsVelocityLimits')

  const [
    maxTransactionAmountForACH,
    startTime,
    timeMeridian,
    dailyACHCount,
    dailyACHVolume,
    monthlyStartDate,
    monthlyACHCount,
    monthlyACHVolume,
  ] = getMany(formValues, [
    'maxTransactionAmountForACH',
    'startTime.value',
    'timeMeridian.value',
    'dailyACHCount',
    'dailyACHVolume',
    'monthlyStartDate.value',
    'monthlyACHCount',
    'monthlyACHVolume',
  ])

  const valuesObject = {
    application_details: {
      balance_adjustment_rules: [
        {
          currency: USD,
          type: GLOBAL,
          max_transaction_amount: normalizeBackendAmount(maxTransactionAmountForACH),
        },
        {
          currency: USD,
          time_frame: DAILY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: startTime, meridian: timeMeridian }),
          rules: [
            {
              day: ALL,
              count_limit: convertAmountStringToNumber({ amount: dailyACHCount }),
              volume_limit: normalizeBackendAmount(dailyACHVolume),
              enabled: true,
            },
          ],
        },
        {
          currency: USD,
          time_frame: MONTHLY,
          time_zone: AMERICA_NY_TIMEZONE_BE_FIELD,
          start_time: convertToMilitaryTime({ hour: startTime, meridian: timeMeridian }),
          start_date: monthlyStartDate,
          rules: [
            {
              count_limit: convertAmountStringToNumber({ amount: monthlyACHCount }),
              volume_limit: normalizeBackendAmount(monthlyACHVolume),
              enabled: true,
            },
          ],
        },
      ],
    },
  }

  const valuesToSubmit = {
    linked_to: applicationId,
    ...valuesObject,
  }

  dispatch({
    type: PATCH_DISBURSEMENT_RULES_F_REQUEST,
    payload: {
      id: disbursementRulesId,
      values: valuesToSubmit,
      credentials,
    },
    meta: {
      successCallback: () => {
        dispatch(redirectRequest({ path: redirectPath }))
      },
      actionId: sendAmplitudeActionEvent(UPDATE_VELOCITY_LIMITS_ON_BALANCE_ADJUSTMENTS, {
        credentials,
        applicationId,
      }),
    },
  })
}

export default submitBalanceAdjustmentsVelocityLimitsForm
