import React, { Component } from 'react'
import { connect } from 'react-redux'
import Exports from './Exports'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import getExportsRequest from 'utilities/actions/get/getExportsRequest'

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `exportsR.${FETCHING}`)

  return {
    initialQueries,
    isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getExports: (credentials) => dispatch(getExportsRequest({ credentials })),
  }
}

class ExportsC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      interval: null,
    }
  }

  componentDidMount() {
    const { credentials, getExports } = this.props

    const exportsInterval = setInterval(() => {
      getExports(credentials)
    }, 30000)

    this.setState({ interval: exportsInterval })
  }

  componentWillUnmount() {
    const { interval } = this.state

    if (interval) {
      clearInterval(interval)
    }
  }

  render() {
    return (
      <Exports
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportsC)
