import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change, isInvalid, hasSubmitFailed } from 'redux-form'
import { getAccessFormSelector } from 'state-layer/selectors'
import LiveAccountApplicationOwnerInfoForm from 'components/Customer/Forms/LiveAccountApplicationOwnerInfoForm/LiveAccountApplicationOwnerInfoForm'
import Loader from 'components/Shared/Loader/Loader'
import { LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM } from 'constants/formConstants'
import removeUndefined from 'utilities/remove/removeUndefined'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const accessFormId = get(props, 'accessFormId')
  const accessForm = getAccessFormSelector(state, accessFormId)
  const dataLoaded = !isEmpty(accessForm)

  const formSelector = formValueSelector(LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM)
  const controlPerson = formSelector(state, 'controlPerson') || get(accessForm, 'controlPerson')
  const beneficialOwners = formSelector(state, 'beneficialOwners') || get(accessForm, 'beneficialOwners') || []
  const businessType = get(accessForm, 'businessData.businessType')
  const controlPersonCurrentCountry = get(controlPerson, 'personalAddress.country')
  const beneficialOwnersCurrentCountries = map(beneficialOwners, 'personalAddress.country')

  const totalPrincipalPercentageOwnership = reduce([controlPerson, ...beneficialOwners], (total, principal) => {
    const principalPercentageOwnership = get(principal, 'principalPercentageOwnership', '0')

    return total + parseFloat(principalPercentageOwnership)
  }, 0)

  const ownershipPercentageError = totalPrincipalPercentageOwnership > 100 ? 'The total Ownership Percentage for all individuals must not exceed 100%' : undefined
  const hasBeneficialOwners = !isEmpty(beneficialOwners)

  const isFormInvalid = isInvalid(LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM)(state) && hasSubmitFailed(LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM)(state)

  const initialValues = dataLoaded ? {
    controlPerson,
    beneficialOwners,
    beneficialOwnersQuestion: hasBeneficialOwners ? 'true' : 'false',
  } : undefined

  return removeUndefined({
    initialValues,
    ownershipPercentageError,
    isFormInvalid,
    dataLoaded,
    businessType,
    controlPersonCurrentCountry,
    beneficialOwnersCurrentCountries,
  })
}

class LiveAccountApplicationOwnerInfoFormC extends Component {
  handleBeneficialOwnersRadioClick = (event, value) => {
    const { dispatch } = this.props
    if (value === 'true') {
      dispatch(change(LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM, 'beneficialOwners', [{ personalAddress: { country: 'USA' } }]))
    } else {
      dispatch(change(LIVE_ACCOUNT_APPLICATION_OWNERSHIP_INFO_FORM, 'beneficialOwners', []))
    }
  }

  render() {
    const { dataLoaded } = this.props
    if (!dataLoaded) return <Loader />

    return (
      <LiveAccountApplicationOwnerInfoForm
        {...this.props}
        handleBeneficialOwnersRadioClick={this.handleBeneficialOwnersRadioClick}
      />
    )
  }
}

export default connect(mapStateToProps)(LiveAccountApplicationOwnerInfoFormC)
