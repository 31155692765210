import showModalAction from 'utilities/actions/showModalAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_APPLICATION_USERS_F_REQUEST, POST_PLATFORM_USER_F_REQUEST } from 'constants/flowConstants'
import { CREATE_API_KEY_SUCCESS } from 'constants/modalConstants'
import { CREATE_API_KEY } from 'constants/amplitudeConstants'
import { ROLE_PLATFORM } from 'constants/roleConstants'
import head from 'lodash/head'
import _values from 'lodash/values'


const submitAPIKeyForm = (values, dispatch, props) => {
  const { applicationId, platformId, credentials } = props
  const { name } = values

  const valuesToSubmit = {
    tags: {
      name,
    },
  }

  // depending on the role, create a platform or application level api key
  if (applicationId) {
    dispatch({
      type: POST_APPLICATION_USERS_F_REQUEST,
      payload: {
        values: valuesToSubmit,
        credentials,
        id: applicationId,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(CREATE_API_KEY, {
          applicationId,
          name,
          credentials,
        }),
        successCallback: ({ newValues }) => {
          const {
            id: username,
            password,
          } = head(_values(newValues))

          dispatch(showModalAction({
            modalType: CREATE_API_KEY_SUCCESS,
            modalProps: {
              username,
              password,
            },
          }))
        },
      },
    })
  }
  if (platformId) {
    dispatch({
      type: POST_PLATFORM_USER_F_REQUEST,
      payload: {
        values: { role: ROLE_PLATFORM, platform_id: platformId, ...valuesToSubmit },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(CREATE_API_KEY, {
          platformId,
          name,
          credentials,
        }),
        successCallback: ({ newValues }) => {
          const {
            id: username,
            password,
          } = head(_values(newValues))

          dispatch(showModalAction({
            modalType: CREATE_API_KEY_SUCCESS,
            modalProps: {
              username,
              password,
            },
          }))
        },
      },
    })
  }
}

export default submitAPIKeyForm
