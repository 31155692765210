import './UserNewS.scss'
import React from 'react'
import NewUserFormC from 'components/Customer/Forms/NewUserForm/NewUserFormC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { CREATE_NEW_USER } from 'constants/language/languageConstants'

const UserNew = () => {
  return (
    <div className='UserNew'>
      <HeaderV2C
        title={CREATE_NEW_USER}
      />

      <div className='header-page-content'>
        <NewUserFormC />
      </div>
    </div>
  )
}

export default UserNew
