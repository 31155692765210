import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_USER_PERMISSIONS_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_USER_PERMISSIONS } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'

const submitPermissionsForm = (values, dispatch, props) => {
  const { records, extraProps } = props

  const userId = get(extraProps, 'userId')
  const credentials = get(extraProps, 'credentials')

  const recordMap = keyBy(records, 'name')

  const valuesToSubmit = reduce(values, (total, value, key) => {
    const currPermission = get(recordMap, key)

    if (get(currPermission, 'enabled') !== value) {
      total.push(merge({}, currPermission, { enabled: value }))
    }

    return total
  }, [])

  if (!isEmpty(valuesToSubmit)) {
    const submitValuesMap = keyBy(valuesToSubmit, 'name')
    const newValuesOnSuccess = map(recordMap, (value, key) => get(submitValuesMap, key, value))

    dispatch({
      type: PATCH_USER_PERMISSIONS_F_REQUEST,
      payload: {
        values: valuesToSubmit,
        credentials,
        id: userId,
        newValuesOnSuccess,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(UPDATE_USER_PERMISSIONS, {
          credentials,
          userId,
          oldValues: valuesToSubmit,
          newValues: newValuesOnSuccess,
        }),
      },
    })
  }
}

export default submitPermissionsForm
