import getMany from 'utilities/get/getMany'
import convertCreatedAtFilterToQuery from 'utilities/convert/convertCreatedAtFilterToQuery'
import formatDate from 'utilities/formatters/formatDate'
import { MONITORING_ALERT_TYPE } from 'constants/monitoringAlertConstants'
import { APPLICATION, MERCHANT } from 'constants/flowConstants'
import { FAILED, SUCCEEDED } from 'constants/stateConstants'
import { ISO_8601_WITHOUT_TIMEZONE } from 'constants/timeConstants'
import { REVERSAL } from 'constants/transferConstants'
import { PAYMENT_CARD } from 'constants/bankConstants'
import set from 'lodash/set'
import unset from 'lodash/unset'
import merge from 'lodash/merge'
import takeRight from 'lodash/takeRight'
import take from 'lodash/take'
import join from 'lodash/join'

const createMonitoringAlertRelatedTransfersFilters = ({ monitoringAlert }) => {
  const queries = {}
  set(queries, 'state', SUCCEEDED)

  const [
    target,
    linkedTo,
    linkedType,
    periodStart,
    periodEnd,
    alertType,
  ] = getMany(monitoringAlert, [
    'groupedBy',
    'linkedTo',
    'linkedType',
    'periodStart',
    'periodEnd',
    'monitoringRuleDetails.type',
  ])


  const createdAt = convertCreatedAtFilterToQuery({
    gte: formatDate({ date: periodStart, format: ISO_8601_WITHOUT_TIMEZONE }),
    lte: formatDate({ date: periodEnd, format: ISO_8601_WITHOUT_TIMEZONE }),
  })

  if (linkedType === MERCHANT) {
    set(queries, 'merchant_id', linkedTo)
  } else if (linkedType === APPLICATION) {
    set(queries, 'application_id', linkedTo)
  }

  switch (alertType) {
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME:
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME:
    case MONITORING_ALERT_TYPE.PAYOUTS_GREATER_THAN_10K:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_GREATER_THAN_10K:
      set(queries, 'merchant_identity_name*like', target)
      break
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD:
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_CARD:
      set(queries, 'instrument_bin', join(take(target, 6), ''))
      set(queries, 'instrument_card_last4', join(takeRight(target, 4), ''))
      break
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK:
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_BANK:
      set(queries, 'instrument_account_last4', target)
      break
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_NAME:
    case MONITORING_ALERT_TYPE.REPEAT_DONATIONS:
      set(queries, 'instrument_name*like', target)
      break
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE:
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE_ADMINISTRATIVE:
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE_UNAUTHORIZED:
      set(queries, 'custom_transfer_type', 'echeck_return')
      break
    case MONITORING_ALERT_TYPE.UNREFERENCED_REFUNDS:
      set(queries, 'instrument_bin', join(take(target, 6), ''))
      set(queries, 'instrument_card_last4', join(takeRight(target, 4), ''))
      break
    case MONITORING_ALERT_TYPE.REFUND_RATE:
      set(queries, 'type', REVERSAL)
      break
    case MONITORING_ALERT_TYPE.FAILED_AUTHORIZATION_RATE:
      set(queries, 'state', FAILED)
      break
    case MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK:
    case MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW:
      set(queries, 'instrument_brand_type', target)
      unset(queries, 'state')
      break
    case MONITORING_ALERT_TYPE.FIRST_SETTLEMENT:
    case MONITORING_ALERT_TYPE.HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT:
    case MONITORING_ALERT_TYPE.DUPLICATE_SETTLEMENT:
    case MONITORING_ALERT_TYPE.DISPUTE_COUNT:
      unset(queries, 'state')
      break
    case MONITORING_ALERT_TYPE.INTERNATIONAL_CARD_RATIO:
      set(queries, 'instrument_type', PAYMENT_CARD)
      break
    case MONITORING_ALERT_TYPE.LENDING_CREDIT_CARD_TRANSFERS:
    case MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_DAILY:
    case MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_WEEKLY:
    case MONITORING_ALERT_TYPE.DORMANT_MERCHANT_SETTLEMENT:
    case MONITORING_ALERT_TYPE.SETTLEMENT_SIZE_DIFFERENCE:
    case MONITORING_ALERT_TYPE.HIGH_TICKET:
    default:
      break
  }

  return merge({}, queries, createdAt)
}

export default createMonitoringAlertRelatedTransfersFilters
