import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateRefundConfirmation from './CreateRefundConfirmation'
import hideModalAction from 'utilities/actions/hideModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import postCreateRefundRequest from 'utilities/actions/post/postCreateRefundRequest'
import isPatching from 'utilities/is/isPatching'
import getMany from 'utilities/get/getMany'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const submitting = isPatching(state)

  const [
    transferId,
    refundAmount,
    selectedSplitRefundExperience,
    splitRefundAmountSum,
    splitRefundsValuesToSubmit,
  ] = getMany(props, [
    'transferId',
    'refundAmount',
    'selectedSplitRefundExperience',
    'splitRefundAmountSum',
    'splitRefundsValuesToSubmit',
  ])

  return {
    credentials,
    transferId,
    refundAmount,
    selectedSplitRefundExperience,
    splitRefundAmountSum,
    splitRefundsValuesToSubmit,
    submitting,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRefundFunc: ({ credentials, transferId, refundAmount, closeModal, valuesToSubmit }) => dispatch(postCreateRefundRequest({ credentials, transferId, refundAmount, closeModal, valuesToSubmit })),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class CreateRefundConfirmationC extends Component {
  createRefund = () => {
    const {
      credentials,
      transferId,
      refundAmount,
      createRefundFunc,
      selectedSplitRefundExperience,
      splitRefundAmountSum,
      splitRefundsValuesToSubmit,
      closeModal,
    } = this.props

    const valuesToSubmit = selectedSplitRefundExperience ?
      {
        refund_amount: splitRefundAmountSum,
        split_transfers: splitRefundsValuesToSubmit,
      } : {
        refund_amount: refundAmount,
      }

    createRefundFunc({ credentials, transferId, refundAmount, closeModal, valuesToSubmit })
  }

  render() {
    return (
      <CreateRefundConfirmation
        {...this.props}
        createRefund={this.createRefund}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRefundConfirmationC)
