import React, { Component } from 'react'
import { connect } from 'react-redux'
import ActivateDeviceForm from 'components/Customer/Forms/ActivateDeviceForm/ActivateDeviceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import getMany from 'utilities/get/getMany'
import isPatching from 'utilities/is/isPatching'
import redirectRequest from 'utilities/actions/redirectRequest'
import { PAYMENT_DEVICE_PATH } from 'constants/pathConstants'
import { ACTIVATE_VALUE } from 'constants/deviceConstants'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import get from 'lodash/get'

import {
  DATACAP_V1,
  DUMMY_V1,
  FINIX_V1,
  TRIPOS_CLOUD_V1,
} from 'constants/processorConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    deviceStatusEnabled,
    gateway,
    deviceName,
    deviceId,
    processor,
    serialNumber,
  ] = getMany(props, [
    'deviceStatusEnabled',
    'gateway',
    'deviceName',
    'deviceId',
    'processor',
    'serialNumber',
  ])

  const showActivationCodeInput = gateway === TRIPOS_CLOUD_V1
  const initialValues = { serialNumber }

  return {
    deviceStatusEnabled,
    showActivationCodeInput,
    deviceName,
    deviceId,
    credentials,
    credentialId,
    actionType: ACTIVATE_VALUE,
    isUpdating: isPatching(state),
    currentSerialNumber: serialNumber,
    initialValues,
    gateway,
    processor,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    redirectToDetailPage: ({ credentialId, deviceId }) => dispatch(redirectRequest({ path: PAYMENT_DEVICE_PATH({ credentialId, deviceId }) })),
  }
}

class ActivateDeviceFormC extends Component {
  render() {
    const {
      redirectToDetailPage,
      closeModal,
      credentialId,
      deviceId,
    } = this.props

    const redirectToDevicePage = () => {
      redirectToDetailPage({ credentialId, deviceId })
      closeModal()
    }

    return (
      <ActivateDeviceForm
        {...this.props}
        redirectToDevicePage={redirectToDevicePage}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateDeviceFormC)
