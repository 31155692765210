import { GET_RECENTLY_ACTIVE_CREDENTIALS_F_REQUEST } from 'constants/flowConstants'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'

const getRecentlyActiveCredentialsRequest = ({ queries }) => ({
  type: GET_RECENTLY_ACTIVE_CREDENTIALS_F_REQUEST,
  payload: {
    values: { dashboardId: getCurrentDashboardId() },
    queries,
  },
})

export default getRecentlyActiveCredentialsRequest
