import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import IdentityPayments from './IdentityPayments'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { PAYMENTS_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { TRANSFER } from 'constants/transferConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  DATE_QUICK_FILTER,
  TRANSFER_STATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const quickFilters = [
  TRANSFER_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter('Transfer'),
  AMOUNT(),
  DEVICE_ID,
  INSTRUMENT_ACCOUNT_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_NAME,
  INSTRUMENT_TYPE,
  MERCHANT_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  TRACE_ID,
  ...TAG_FILTERS,
], 'label')

const mapStateToProps = (state, props) => {
  const identityId = get(props, 'identityId')
  const isFetching = get(state, `identityTransfersR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const paymentPath = PAYMENTS_PATH({ credentialId })

  const flowValues = {
    identityId,
  }

  const initialQueries = {
    merchant_identity_id: identityId,
  }

  return {
    identityId,
    flowValues,
    isFetching,
    paymentPath,
    initialQueries,
    allowedFilters,
    quickFilters,
    initialFilters: merge({}, createdLastMonthQueries(), { type: { eq: TRANSFER } }),
  }
}

class IdentityPaymentsC extends Component {
  render() {
    return (
      <IdentityPayments {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(IdentityPaymentsC)
