import './ResponseFilesFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import { RESPONSE_FILES_FORM } from 'constants/formConstants'
import validateCreateAdjustmentForm from 'utilities/validate/validateCreateAdjustmentForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'

import {
  CLEAR,
  SEARCH,
} from 'constants/language/languageConstants'

const ResponseFilesForm = ({
  toggle = () => {},
  show = false,
}) => {
  return (
    <form className='ResponseFilesForm'>
      <Field
        name='fileName'
        label='Enter file name:'
        placeholder='FNXxxx'
        component={InputField}
      />

      <div className='flex'>
        <Button onClick={() => toggle(true)} label={SEARCH} variant='secondary' />
        <Button onClick={() => toggle(false)} label={CLEAR} className='clear' />
      </div>


      {/* <div className='responses'> */}
      {/*  <div> */}
      {/*  File found! Click directly on file link to download: */}
      {/*  </div> */}
      {/*  <div className='file-result'> */}
      {/*    <div>FNXxxx1</div> */}
      {/*  </div> */}
      {/* </div> */}
    </form>
  )
}

ResponseFilesForm.propTypes = {
  toggle: PropTypes.func,
}

export default reduxForm({
  form: RESPONSE_FILES_FORM,
})(ResponseFilesForm)
