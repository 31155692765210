export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i
export const AMOUNT_REGEX = /^\$?[0-9]+(\.[0-9][0-9])?$/
export const MM_DD_YYYY_REGEX = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/(199\d)|([2][0]\d{2})$/
export const WEBSITE_REGEX = /^(ftp|http|https?:\/\/)[^ "]+/
export const FULL_WEBSITE_REGEX = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
export const PROTOCOL_REGEX= /^(http[s]?:\/\/)/
export const ROUTING_NUMBER_REGEX = /^[0-9]{9}$/
export const ACCOUNT_NUMBER_REGEX = /^[0-9]{5,17}$/
export const MCC_REGEX = /^[0-9]{3,4}$/
export const TAX_ID_REGEX = /^[0-9]{9}$/
export const MASKED_TAX_ID_REGEX = /^[0-9*]{9}$/
export const TAG_REGEX = /^[^\\,"']*$/
export const CANADA_ASSOCIATE_ESTATE_TRUST_TAX_ID_REGEX = /^[T*][0-9*]{8}$/
export const AMEX_MID_REGEX = /^[0-9]{10,11}$/
export const DISCOVER_MID_REGEX = /^[0-9]{15}$/
export const BANK_CODE_REGEX = /^[0-9]{9}$/
export const TRANSIT_NUMBER_REGEX = /^[0-9]{5}$/
export const INSTITUTION_NUMBER_REGEX = /^[0-9]{3}$/
export const MASKED_BANK_ACCOUNT_NUMBER_REGEX = /^[0-9*]{5,17}$/
export const CAMEL_CASE_REGEX = /[0-9][^A-Z,a-z]|([A-Z]?[^A-Z,0-9]*)/g
export const IPV4_ADDRESS_REGEX = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
export const IPV6_ADDRESS_REGEX = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/
export const PHONE_NUMBER_REGEX = /^[0-9]{10,11}$/
export const WEBSITE_DOMAIN_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/
export const DEFAULT_STATEMENT_DESCRIPTOR_REGEX = /^[a-zA-Z0-9-.,&# ]{1,20}$/
export const MASKED_NUMBER_REGEX = /^[0-9*]*$/
export const SUBDOMAIN_REGEX = /^[a-z0-9]+$/i
export const SALESFORCE_OPPORTUNITY_RECORD_ID_REGEX = /^[0-9a-zA-Z]{18}$/
export const HUBSPOT_DEAL_ID_REGEX = /^[0-9a-zA-Z]{11}$/
export const REGEX_CANADIAN_POSTAL_CODE = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
export const USA_POSTAL_CODE_REGEX = /^[0-9]{5}$/
export const ALPHANUMERIC_NO_SPECIAL_CHARS_REGEX = /^[a-zA-Z0-9]+$/
export const CVV_REGEX = /^[0-9]{3,4}$/
export const HOUR_REGEX = /^([1-9]|1[012])$/
export const MINUTES_REGEX = /^[0-5][0-9]$/

// PASSWORD REGEX
export const SELF_SERVICE_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*#?&()._-])[A-Za-z\d@$!%^*#?&()._-]{8,20}$/
export const HAVE_8_TO_20_CHARACTERS_REGEX = /^[\w\W]{8,20}$/
export const HAVE_12_TO_20_CHARACTERS_REGEX = /^[\w\W]{12,20}$/
export const HAVE_ONE_LOWERCASE_REGEX = /(?=.*[a-z])/
export const HAVE_ONE_UPPERCASE_REGEX = /(?=.*[A-Z])/
export const HAVE_ONE_NUMBER_REGEX = /(?=.*\d)/

// STRING LENGTH REGEX
export const HAVE_LESS_THAN_60_CHARACTERS_REGEX = /^[\w\W]{1,60}$/
export const HAVE_LESS_THAN_20_CHARACTERS_REGEX = /^[\w\W]{1,20}$/
export const HAVE_LESS_THAN_23_INTEGERS_REGEX = /^[0-9]{1,23}$/
export const HAVE_ONE_SPECIAL_CHARACTER_REGEX = /(?=.*[@$!%^*#?&()._-])/
