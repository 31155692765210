import './EditRecipientIdentityContactFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import AddressFieldset from 'components/Shared/Fieldsets/AddressFieldset/AddressFieldset'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import submitEditRecipientIdentityForm from 'utilities/submit/submitEditRecipientIdentityForm'
import validateEditRecipientIdentityContactForm from 'utilities/validate/validateEditRecipientIdentityContactForm'
import formatPhone from 'utilities/forms/format/formatPhone'
import parsePhone from 'utilities/forms/parse/parsePhone'
import { EDIT_RECIPIENT_IDENTITY_CONTACT_FORM } from 'constants/formConstants'

import {
  EDIT_RECIPIENT,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  CANCEL,
  SAVE_CHANGES,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
  BUSINESS_ADDRESS,
  BUSINESS_DETAILS,
} from 'constants/language/languageConstants'

const EditRecipientIdentityContactForm = ({
  handleSubmit = () => {},
  submitting = false,
  disabled = false,
  patching = false,
  showBusinessInformation = false,
}) => {
  return (
    <form className='EditRecipientIdentityContactForm' onSubmit={handleSubmit(submitEditRecipientIdentityForm)}>
      <div className='form-header'>
        <h3 className='title'>{EDIT_RECIPIENT}</h3>
      </div>
      <div className='form-content'>
        <div className='contact-details'>
          <div className='name flex space-between'>
            <Field
              name='firstName'
              label={FIRST_NAME}
              component={InputField}
            />
            <Field
              name='lastName'
              label={LAST_NAME}
              component={InputField}
            />
          </div>
          <div className='contact-info flex space-between'>
            <Field
              name='email'
              label={EMAIL}
              component={InputField}
              required={false}
            />
            <Field
              name='phone'
              label={PHONE_NUMBER}
              component={InputField}
              format={formatPhone}
              parse={parsePhone}
              required={false}
            />
          </div>

          <AddressFieldset
            name='personalAddress'
            showLabels
            disabledCountry
          />
        </div>

        {showBusinessInformation && (
        <div className='business-info'>
          <h5>{BUSINESS_DETAILS}</h5>
          <Field
            name='businessName'
            label={BUSINESS_NAME}
            component={InputField}
          />

          <Field
            name='doingBusinessAs'
            label={DOING_BUSINESS_AS}
            component={InputField}
          />

          <Field
            name='businessPhone'
            label={BUSINESS_PHONE}
            component={InputField}
            format={formatPhone}
            parse={parsePhone}
            required={false}
          />

          <AddressFieldset
            name='businessAddress'
            showLabels
            disabledCountry
            fieldLabel={BUSINESS_ADDRESS}
          />
        </div>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button className='cancel-button' onClick={goBack} label={CANCEL} variant='ghost' size='large' />
          <Button className='submit-button' submitting={submitting || patching} disabled={disabled} label={SAVE_CHANGES} variant='primary' type='submit' size='large' />
        </div>
      </div>
    </form>
  )
}

EditRecipientIdentityContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  patching: PropTypes.bool,
  showBusinessInformation: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_RECIPIENT_IDENTITY_CONTACT_FORM,
  validate: validateEditRecipientIdentityContactForm,
})(EditRecipientIdentityContactForm)
