import React, { Component } from 'react'
import { connect } from 'react-redux'
import UnderwritingApplication from 'components/Customer/Pages/UnderwritingApplication/UnderwritingApplication'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import UnderwritingAssignee from 'components/Customer/Shared/Display/UnderwritingAssignee/UnderwritingAssignee'
import ApplicationUnderwritingReviewC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReview/ApplicationUnderwritingReview/ApplicationUnderwritingReviewC'
import Notes from 'components/Customer/Pages/Notes/Notes'
import patchUnderwritingReviewRequest from 'utilities/actions/patch/patchUnderwritingReviewRequest'
import getUnderwritingReviewRequest from 'utilities/actions/get/getUnderwritingReviewRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getDashboardUser from 'utilities/get/getDashboardUser'
import hasPermission from 'utilities/hasPermission'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getMany from 'utilities/get/getMany'
import hasPermissions from 'utilities/hasPermissions'
import showModalAction from 'utilities/actions/showModalAction'
import { getUnderwritingReviewSelector } from 'state-layer/selectors'
import { underwritingNoteTemplates } from 'constants/noteConstants'
import { REVIEW_QUEUE_UPDATE_PERMISSION } from 'constants/permissionConstants'
import { CLIENT_EMAIL } from 'constants/cookieConstants'
import { FETCHING } from 'constants/reducerConstants'
import { ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL } from 'constants/modalConstants'
import get from 'lodash/get'

import {
  APPLICATION_UNDERWRITING,
  ASSIGNEE,
  UNASSIGNED,
  UPDATED_ON,
  STATUS,
  CASE_NOTES,
  REVIEW,
  UW_CASE_NOTE_EMPTY_MSG,
  COUNTRY,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const reviewId = get(props, 'params.reviewId')
  const isFetching = get(state, `underwritingReviewsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const review = getUnderwritingReviewSelector(state, reviewId)
  const accessForm = get(review, 'entity', {})
  const accessFormType = get(accessForm, 'displayType')
  const outcome = get(review, 'state')
  const assignee = get(review, 'assignee')
  const hasUpdatePermission = hasPermission(state, REVIEW_QUEUE_UPDATE_PERMISSION)
  const currentUserEmail = get(getDashboardUser(), CLIENT_EMAIL)
  const noAssignee = !assignee || assignee === UNASSIGNED

  const {
    subjectId,
    entityType,
    entityId,
  } = parseUrlQueries()

  const tabs = [
    {
      id: 'review',
      name: REVIEW,
      component: ApplicationUnderwritingReviewC,
      props: {
        reviewId,
        subjectId,
        entityId,
      },
    },
    {
      id: 'application-case-notes',
      name: CASE_NOTES,
      component: Notes,
      props: {
        linkedTo: reviewId,
        templates: underwritingNoteTemplates,
        emptyMessage: UW_CASE_NOTE_EMPTY_MSG,
        showAddNewNote: hasPermissions([REVIEW_QUEUE_UPDATE_PERMISSION]),
      },
    },
  ]
  return {
    isFetching,
    credentials,
    reviewId,
    subjectId,
    entityType,
    entityId,
    assignee,
    hasUpdatePermission,
    currentUserEmail,
    noAssignee,
    accessForm,
    outcome,
    accessFormType,
    tabs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUnderwritingReview: ({ subjectId, reviewId, entityType, entityId, credentials }) => dispatch(getUnderwritingReviewRequest({ subjectId, reviewId, entityType, entityId, credentials })),
    patchUnderwritingReview: (actionProps) => dispatch(patchUnderwritingReviewRequest(actionProps)),
    showAssignToMeModal: (modalProps) => dispatch(showModalAction({ modalType: ASSIGN_UNDERWRITING_ENTITY_TO_ME_MODAL, modalProps })),
  }
}

class UnderwritingApplicationC extends Component {
  componentDidMount() {
    const {
      reviewId,
      subjectId,
      entityType,
      entityId,
      credentials,
      getUnderwritingReview,
    } = this.props

    getUnderwritingReview({
      subjectId,
      reviewId,
      entityType,
      entityId,
      credentials,
    })
  }

  render() {
    const {
      reviewId,
      subjectId,
      hasUpdatePermission,
      accessForm,
      outcome,
      showAssignToMeModal,
      assignee,
      isFetching,
      currentUserEmail,
      accessFormType,
    } = this.props

    const [
      businessName,
      displayUpdatedAt,
      displayCountry,
    ] = getMany(accessForm, [
      'businessData.businessName',
      'displayUpdatedAt',
      'displayCountry',
    ])

    const headerData = {
      resource: {
        label: APPLICATION_UNDERWRITING,
        id: reviewId,
        badge: accessFormType,
      },
      items: [
        {
          value: <h1>{businessName}</h1>,
        },
        {
          label: STATUS,
          value: <UnderwritingReviewStatus value={outcome} />,
        },
        {
          label: UPDATED_ON,
          value: displayUpdatedAt,
        },
        {
          label: COUNTRY,
          value: displayCountry,
        },
        {
          label: ASSIGNEE,
          value: <UnderwritingAssignee outcome={outcome} assignee={assignee} hasUpdatePermission={hasUpdatePermission} showAssignToMeModal={showAssignToMeModal} reviewId={reviewId} subjectId={subjectId} currentUserEmail={currentUserEmail} />,
        },
      ],
      isFetching,
    }

    return (
      <UnderwritingApplication
        {...this.props}
        headerData={headerData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingApplicationC)
