import dashboardAPI from 'utilities/api/dashboardAPI'

import { DASHBOARD_SERVICE_SELF_SERVICE_SIGNUP } from 'constants/apiConstants'

const getSelfServiceSandboxFormAPI = ({ queries, credentials, meta }) => {
  return dashboardAPI.get({
    meta,
    credentials,
    path: DASHBOARD_SERVICE_SELF_SERVICE_SIGNUP,
    queries,
  })
}

export default getSelfServiceSandboxFormAPI
