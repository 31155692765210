import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SUBSCRIPTIONS,
  SUBSCRIPTION_BILLING,
} from 'constants/apiConstants'

const patchSubscriptionAPI = ({ id, values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: SUBSCRIPTION_BILLING({ subscriptionId: id }),
    service: SUBSCRIPTIONS,
  })
}

export default patchSubscriptionAPI
