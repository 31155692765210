import normalizeArray from 'utilities/api/normalizeArray'
import reduce from 'lodash/reduce'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import { frontendNormalizationMap } from 'constants/normalizationConstants'

const normalizeObject = (object, normalizationMap = frontendNormalizationMap) => {
  if (!isPlainObject(object)) {
    return object
  }

  return reduce(object, (total, value, key) => {
    if (isArray(value)) {
      return merge({}, total, {
        [key]: normalizeArray(value, normalizationMap),
      })
    }

    if (isPlainObject(value)) {
      return merge({}, total, {
        [key]: normalizeObject(value, normalizationMap),
      })
    }

    const normalizeFn = normalizationMap[key]
    const normalizedValue = normalizeFn ? normalizeFn(value) : value

    return merge({}, total, {
      [key]: normalizedValue,
    })
  }, {})
}

export default normalizeObject
