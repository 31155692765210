import './SettlementInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import SettlementFundingTransfersC from 'components/Customer/Pages/Settlement/SettlementFundingTransfers/SettlementFundingTransfersC'
import SettlementCalculatedFundingDetailsC from 'components/Customer/Pages/Settlement/SettlementCalculatedFundingDetails/SettlementCalculatedFundingDetailsC'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import isEmpty from 'lodash/isEmpty'

import {
  AMOUNT,
  CREDITS,
  DEBITS,
  DETAILS,
  DISPUTES,
  FEES,
  FUNDING_INSTRUCTIONS,
  FUNDING_TRANSFERS,
  MERCHANT_PAYOUT_PROFILE,
  NET_AMOUNT,
  OVERVIEW,
  REFUNDS,
  SETTLED_EXTERNALLY,
  TRANSACTIONS,
  PAYOUT_PROFILE,
  MERCHANT_ADJUSTMENTS,
} from 'constants/language/languageConstants'

const SettlementInfo = ({
  detailsSectionData = [],
  merchantPayoutProfileSectionData = [],
  tagsSectionData = [],
  isFetching = false,
  settlementId,
  settlementPaymentInstruments,
  isApprovedSettlement,
  displayTransferDebitCount,
  displayTransferDebitAmount,
  displayTransferCreditCount,
  displayTransferCreditAmount,
  displayTransferFeesCount,
  displayTransferFeesAmount,
  displayTransferSettledExternallyCount,
  displayTransferSettledExternallyAmount,
  displayTransferRefundsCount,
  displayTransferRefundsAmount,
  displayDisputeDebitCount,
  displayDisputeDebitAmount,
  displayDisputeCreditCount,
  displayDisputeCreditAmount,
  displayAdjustmentDebitCount,
  displayAdjustmentDebitAmount,
  displayAdjustmentCreditCount,
  displayAdjustmentCreditAmount,
  displayNetAmount,
  fundingTransfersTooltipMessage = {},
  currency,
  bankAccountPaymentInstruments = [],
}) => {
  return (
    <div className='SettlementInfo'>
      <PageSectionV2C
        title={DETAILS}
        columns={detailsSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={isStandaloneMerchantDashboard() ? PAYOUT_PROFILE : MERCHANT_PAYOUT_PROFILE}
        columns={merchantPayoutProfileSectionData}
        isFetching={isFetching}
      />

      <PageSectionV2C title={OVERVIEW}>
        <div className='overview-table flex'>
          <div className='amount-column flex-grow'>
            <div className='title' />
            <div className='flex'>
              <div className='column flex-grow'>
                <div className='sub-title' />
                <div className='data flex'>
                  <div className='bold'>{AMOUNT}</div>
                  <div className='currency-string secondary'>({currency})</div>
                </div>
              </div>
            </div>
          </div>
          <div className='transactions flex-grow'>
            <div className='title bold'>{TRANSACTIONS}</div>
            <div className='flex'>
              <div className='debits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{DEBITS}</div>
                  <div className='count'>{displayTransferDebitCount}</div>
                </div>
                <div className='data'>{displayTransferDebitAmount}</div>
              </div>
              <div className='credits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{CREDITS}</div>
                  <div className='count'>{displayTransferCreditCount}</div>
                </div>
                <div className='data'>{displayTransferCreditAmount}</div>
              </div>
              <div className='fees-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{FEES}</div>
                  <div className='count'>{displayTransferFeesCount}</div>
                </div>
                <div className='data'>{displayTransferFeesAmount}</div>
              </div>
              <div className='refunds-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{REFUNDS}</div>
                  <div className='count'>{displayTransferRefundsCount}</div>
                </div>
                <div className='data'>{displayTransferRefundsAmount}</div>
              </div>
              <div className='settled-externally-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{SETTLED_EXTERNALLY}</div>
                  <div className='count'>{displayTransferSettledExternallyCount}</div>
                </div>
                <div className='data'>{displayTransferSettledExternallyAmount}</div>
              </div>
            </div>
          </div>
          <div className='disputes flex-grow'>
            <div className='title bold'>{DISPUTES}</div>
            <div className='flex'>
              <div className='debits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{DEBITS}</div>
                  <div className='count'>{displayDisputeDebitCount}</div>
                </div>
                <div className='data'>{displayDisputeDebitAmount}</div>
              </div>
              <div className='credits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{CREDITS}</div>
                  <div className='count'>{displayDisputeCreditCount}</div>
                </div>
                <div className='data'>{displayDisputeCreditAmount}</div>
              </div>
            </div>
          </div>
          <div className='adjustments flex-grow'>
            <div className='title bold'>{MERCHANT_ADJUSTMENTS}</div>
            <div className='flex'>
              <div className='debits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{DEBITS}</div>
                  <div className='count'>{displayAdjustmentDebitCount}</div>
                </div>
                <div className='data'>{displayAdjustmentDebitAmount}</div>
              </div>
              <div className='credits-column flex-grow'>
                <div className='sub-title flex flex-end'>
                  <div className='name bold'>{CREDITS}</div>
                  <div className='count'>{displayAdjustmentCreditCount}</div>
                </div>
                <div className='data'>{displayAdjustmentCreditAmount}</div>
              </div>
            </div>
          </div>
          <div className='net-amount flex-grow'>
            <div className='title bold'>{NET_AMOUNT}</div>
            <div className='flex'>
              <div className='column flex-grow'>
                <div className='sub-title' />
                <div className='data bold'>{displayNetAmount}</div>
              </div>
            </div>
          </div>
        </div>
      </PageSectionV2C>

      {isApprovedSettlement && (
        <PageSectionV2C
          className='funding-transfers-section'
          title={FUNDING_TRANSFERS}
          tooltip={!isEmpty(fundingTransfersTooltipMessage)}
          tooltipMessage={fundingTransfersTooltipMessage}
          tooltipPosition='top'
        >
          <SettlementFundingTransfersC
            settlementId={settlementId}
            bankAccountPaymentInstruments={bankAccountPaymentInstruments}
            showExportButton={false}
          />
        </PageSectionV2C>
      )}

      {!isApprovedSettlement && (
        <SettlementCalculatedFundingDetailsC
          settlementId={settlementId}
          title={FUNDING_INSTRUCTIONS}
          showExportButton={false}
        />
      )}

      <TagsPageSectionV2
        data={tagsSectionData}
        isFetching={isFetching}
      />
    </div>
  )
}

SettlementInfo.propTypes = {
  detailsSectionData: PropTypes.array,
  merchantPayoutProfileSectionData: PropTypes.array,
  tagsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  settlementId: PropTypes.string,
  isApprovedSettlement: PropTypes.bool,
  displayTransferDebitCount: PropTypes.number,
  displayTransferDebitAmount: PropTypes.string,
  displayTransferCreditCount: PropTypes.number,
  displayTransferCreditAmount: PropTypes.string,
  displayTransferFeesCount: PropTypes.number,
  displayTransferFeesAmount: PropTypes.string,
  displayTransferSettledExternallyCount: PropTypes.number,
  displayTransferSettledExternallyAmount: PropTypes.string,
  displayTransferRefundsCount: PropTypes.number,
  displayTransferRefundsAmount: PropTypes.string,
  displayDisputeDebitCount: PropTypes.number,
  displayDisputeDebitAmount: PropTypes.string,
  displayDisputeCreditCount: PropTypes.number,
  displayDisputeCreditAmount: PropTypes.string,
  displayAdjustmentDebitCount: PropTypes.number,
  displayAdjustmentDebitAmount: PropTypes.string,
  displayAdjustmentCreditCount: PropTypes.number,
  displayAdjustmentCreditAmount: PropTypes.string,
  displayNetAmount: PropTypes.string,
  fundingTransfersTooltipMessage: PropTypes.object,
  currency: PropTypes.string,
  settlementPaymentInstruments: PropTypes.object,
  bankAccountPaymentInstruments: PropTypes.array,
}

export default SettlementInfo
