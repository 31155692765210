import getWebhookLogAPI from 'api/finix/get/getWebhookLogAPI'
import getWebhookO from 'state-layer/orchestration/get/getWebhookO'
import frontendWebhookModel from 'utilities/create/models/frontendWebhookModel'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'
import head from 'lodash/head'

function * getWebhookLogO ({
  id,
  credentials,
}) {
  const { data: response } = yield getWebhookLogAPI({ id, credentials })

  const webhookId = get(response, 'webhook_id')
  const { data: webhookResponse } = yield getWebhookO({ id: webhookId, credentials })
  const normalizedWebhook = frontendWebhookModel({ data: head(webhookResponse) })

  const webhookLog = merge({}, response, { webhook: normalizedWebhook })

  return createSuccessResponse({
    data: [webhookLog],
  })
}

export default co.wrap(getWebhookLogO)
