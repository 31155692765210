import { GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getGuestOnboardingFormUnderwritingProfileRequest = ({
  id,
}) => createAction({
  type: GET_GUEST_ONBOARDING_FORM_UNDERWRITING_PROFILE_F_REQUEST,
  id,
})

export default getGuestOnboardingFormUnderwritingProfileRequest
