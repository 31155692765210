import './AccordionGroupS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const AccordionGroup = ({
  children,
  classNames = '',
}) => {
  return (
    <div className={classnames('AccordionGroup', classNames)}>
      {children}
    </div>
  )
}

AccordionGroup.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.string,
}

export default AccordionGroup
