import get from 'lodash/get'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  PATCH_TRANSFERS_UPDATE_F_SUCCESS,
  PATCH_VERIFICATIONS_UPDATE_F_SUCCESS,
} from 'constants/flowConstants'

import {
  UPDATE_ACTION_STATUS,
  HIDE_MODAL,
} from 'constants/actionConstants'

const getStatus = (payload, state) => {
  const statusKey = get(state, 'statusKey')

  return reduce(get(payload, 'newValues', {}), (total, record) => {
    const id = get(record, 'id')
    const statusValue = get(record, `response.${statusKey}`, false)

    return merge(total, { [id]: statusValue })
  }, {})
}

const actionStatusR = (state = {}, action) => {
  const payload = get(action, 'payload', {})
  const status = getStatus(payload, state)

  switch (action.type) {
    case UPDATE_ACTION_STATUS:
      return merge({}, state, payload)

    case PATCH_TRANSFERS_UPDATE_F_SUCCESS:
    case PATCH_VERIFICATIONS_UPDATE_F_SUCCESS:
      return merge({}, omit(state, 'status'), { status, completed: true })

    case HIDE_MODAL:
      return {}

    default:
      return state
  }
}

export default actionStatusR
