import removeUndefined from 'utilities/remove/removeUndefined'

const backendInstantPayoutModel = ({ values }) => {
  const {
    amount,
    paymentInstrumentId,
  } = values

  return removeUndefined({
    amount,
    instrument_id: paymentInstrumentId,
  })
}

export default backendInstantPayoutModel
