import React from 'react'
import { dormantSettlementColumnDescriptors } from '../columnDescriptors'
import TableC from 'components/Shared/Table/TableC'
import getMany from 'utilities/get/getMany'
import { infoTableTheme } from 'constants/themeConstants'
import get from 'lodash/get'

const DormantSettlementTable = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    displaySettlementCreatedAtDay,
    displaySettlementCreatedAtDayPrior,
    settlementEntryCount,
    displaySettlementAmountDollarsSum,
    displaySettlementAmountDollarsSumPrior,
    displaySettlementAmountDollarsAverage,
    displaySettlementAmountDollarsAveragePrior,
    daysSincePriorSettlement,
    settlementId,
    settlementIdPrior,
  ] = getMany(calculations, [
    'displaySettlementCreatedAtDay',
    'displaySettlementCreatedAtDayPrior',
    'settlementEntryCount',
    'displaySettlementAmountDollarsSum',
    'displaySettlementAmountDollarsSumPrior',
    'displaySettlementAmountDollarsAverage',
    'displaySettlementAmountDollarsAveragePrior',
    'daysSincePriorSettlement',
    'settlementId',
    'settlementIdPrior',
  ])

  const settlementRows = [
    {
      id: settlementId,
      createdAt: displaySettlementCreatedAtDay,
      count: settlementEntryCount,
      sum: displaySettlementAmountDollarsSum,
      average: displaySettlementAmountDollarsAverage,
      days: daysSincePriorSettlement,
    },
    {
      id: settlementIdPrior,
      createdAt: displaySettlementCreatedAtDayPrior,
      count: settlementEntryCount,
      sum: displaySettlementAmountDollarsSumPrior,
      average: displaySettlementAmountDollarsAveragePrior,
      days: null,
    },
  ]

  return (
    <TableC
      classNames='alert-insights-table'
      showTableFooter={false}
      theme={infoTableTheme}
      columnDescriptors={dormantSettlementColumnDescriptors}
      data={settlementRows}
    />
  )
}

export default DormantSettlementTable
