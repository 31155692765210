import './ViewDeviceInfoModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import DeviceInfo from 'components/Customer/Shared/Display/DeviceInfo/DeviceInfo'
import { DEVICE_INFORMATION } from 'constants/language/languageConstants'

// DEPRECATED: 02/07/2024 this modal has been deprecated and is no longer in use

const ViewDeviceInfoModal = ({
  deviceDataSection = [],
  deviceImageUrl,
  model,
}) => {
  return (
    <GenericModal
      title={DEVICE_INFORMATION}
      className='ViewDeviceInfoModal'
      Component={DeviceInfo}
      deviceDataSection={deviceDataSection}
      deviceImageUrl={deviceImageUrl}
      model={model}
    />
  )
}

ViewDeviceInfoModal.propTypes = {
  deviceDataSection: PropTypes.array,
  deviceImageUrl: PropTypes.string,
  model: PropTypes.string,
}

export default ViewDeviceInfoModal
