import postApplicationProcessorAPI from 'api/finix/post/postApplicationProcessorAPI'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import co from 'co'

function * postApplicationProcessorsO ({
  id,
  credentials,
  values,
}) {
  const { processors } = values
  const responses = yield map(processors, (processor) => postApplicationProcessorAPI({
    id,
    credentials,
    values: {
      processor,
      type: processor,
    },
  }))

  const applicationProcessorErrorResponses = filter(responses, ({ error, data }) => {
    return error
  })

  if (!isEmpty(applicationProcessorErrorResponses)) {
    return { error: applicationProcessorErrorResponses[0] }
  }

  const processorsData = map(responses, ({ data }) => data)

  return createSuccessResponse({
    data: processorsData,
  })
}

export default co.wrap(postApplicationProcessorsO)
