import './CreateCustomerOnboardingFormFlowS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'
import { CREATE_NEW_CUSTOMER_ONBOARDING_FORM } from 'constants/language/languageConstants'

const CreateCustomerOnboardingFormFlow = ({
  steps,
}) => {
  return (
    <div className='CreateCustomerOnboardingFormFlow'>
      <FlowsC
        layout='vertical'
        steps={steps}
        title={CREATE_NEW_CUSTOMER_ONBOARDING_FORM}
      />
    </div>
  )
}

CreateCustomerOnboardingFormFlow.propTypes = {
  steps: PropTypes.array,
}

export default CreateCustomerOnboardingFormFlow
