import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'

import {
  ACTIVE,
  COMPLETED,
  EXPIRED,
  DEACTIVATED,
  AWAITING_PAYOUT,
} from 'constants/paymentLinkConstants'

import {
  SUCCEEDED,
  CANCELED,
  FAILED,
  SUCCEEDED_COMPLIANCE,
  WARNING,
} from 'constants/statusConstants'

const PaymentLinkColorData = {
  [ACTIVE]: SUCCEEDED,
  [COMPLETED]: SUCCEEDED_COMPLIANCE,
  [EXPIRED]: FAILED,
  [DEACTIVATED]: CANCELED,
  [AWAITING_PAYOUT]: WARNING, // this is a status for payout links
}

const iconStyles = {
  [COMPLETED]: 'fal',
}

const PaymentLinkStatus = ({
  value = '',
}) => {
  return (
    <ColorcodedStatus
      data={PaymentLinkColorData}
      value={value}
      iconStyles={iconStyles}
    />
  )
}

PaymentLinkStatus.propTypes = {
  value: PropTypes.string,
}

export default PaymentLinkStatus
