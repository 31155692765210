import validateTagsForm from 'utilities/validate/validateTagsForm'

import {
  fieldEmpty,
  nonEmptyNumber,
} from 'utilities/validate'

const validateEditSubscriptionAmountForm = (values) => {
  const {
    subscriptionAmountId,
    amountNickname,
    amount,
    tags,
  } = values

  const tagErrors = validateTagsForm({ tags })

  return {
    subscriptionAmountId: fieldEmpty(subscriptionAmountId, 'Subscription amount'),
    amountNickname: fieldEmpty(amountNickname, 'Amount nickname'),
    amount: nonEmptyNumber({ field: amount, name: 'Amount' }),
    ...tagErrors,
  }
}

export default validateEditSubscriptionAmountForm
