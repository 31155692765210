import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import RebootDeviceC from 'components/Customer/Pages/RebootDevice/RebootDeviceC'
import { REBOOT_DEVICE } from 'constants/language/languageConstants'

const RebootDeviceModal = ({
  deviceId = '',
  deviceName = '',
}) => {
  return (
    <GenericModal
      className='RebootDeviceModal'
      title={REBOOT_DEVICE}
      Component={RebootDeviceC}
      deviceId={deviceId}
      deviceName={deviceName}
    />
  )
}

RebootDeviceModal.propTypes = {
  deviceId: PropTypes.string,
  deviceName: PropTypes.string,
}

export default RebootDeviceModal
