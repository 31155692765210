import './ReportSearchFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import { REPORT_SEARCH_FORM } from 'constants/formConstants'
import { REPORTS_SEARCH_BAR_PLACEHOLDER } from 'constants/language/languageConstants'
import { SEARCH_ICON } from 'constants/iconConstants'

const ReportSearchForm = () => {
  return (
    <form className='ReportSearchForm flex'>
      <Field
        name='reportFilter'
        type='text'
        component={InputField}
        placeholder={REPORTS_SEARCH_BAR_PLACEHOLDER}
        leadingIcon={`search fal fa-${SEARCH_ICON}`}
      />
    </form>
  )
}

export default reduxForm({
  form: REPORT_SEARCH_FORM,
})(ReportSearchForm)
