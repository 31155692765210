import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_SELF_SERVICE_SIGNUP_FORM_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import concat from 'lodash/concat'
import trim from 'lodash/trim'

import {
  SELF_SERVICE_SIGNUP_REQUEST_FAILURE,
  SELF_SERVICE_SIGNUP_REQUEST_SUCCESSFUL,
  SUBMIT_SELF_SERVICE_SIGNUP_FORM,
} from 'constants/amplitudeConstants'

const submitSelfServiceSignupForm = (values, dispatch, props) => {
  const {
    companyEmail,
    companyName,
    firstName,
    lastName,
    password,
    marketingCheckbox,
    country,
    hCaptchaToken,
  } = values

  const valuesToSubmit = {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    password,
    email_address: trim(companyEmail),
    marketing_consent_accepted: marketingCheckbox,
    country,
  }

  // clear hCaptcha on submit because no token can be used twice
  const { captchaRef } = props
  if (captchaRef?.current) {
    captchaRef.current.resetCaptcha()
  }
  dispatch({
    type: POST_SELF_SERVICE_SIGNUP_FORM_F_REQUEST,
    payload: {
      values: valuesToSubmit,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const formId = get(newValues, 'id')
        const email = get(newValues, 'emailAddress')

        const { origin } = window.location
        const returnUrlString = concat(origin, `/verify-email-pending?id=${formId}&email=${email}`).join('')

        // kick the user to the verify email screen after successful submission
        window.location.replace(returnUrlString)

        sendAmplitudeActionEvent(SELF_SERVICE_SIGNUP_REQUEST_SUCCESSFUL, {
          email: companyEmail,
          companyName,
          firstName,
          lastName,
          marketingCheckboxEnabled: marketingCheckbox,
          country,
        })
      },
      errorCallback: () => {
        sendAmplitudeActionEvent(SELF_SERVICE_SIGNUP_REQUEST_FAILURE, {
          email: companyEmail,
          companyName,
          firstName,
          lastName,
          marketingCheckboxEnabled: marketingCheckbox,
          country,
        })
      },
      actionId: sendAmplitudeActionEvent(SUBMIT_SELF_SERVICE_SIGNUP_FORM, {
        email: companyEmail,
        companyName,
        firstName,
        lastName,
        marketingCheckboxEnabled: marketingCheckbox,
        country,
      }),
      hCaptchaToken,
    },
  })
}

export default submitSelfServiceSignupForm
