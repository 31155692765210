import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PlaidLink } from 'react-plaid-link'
import getMany from 'utilities/get/getMany'
import postPlaidPublicTokenRequest from 'utilities/actions/post/postPlaidPublicTokenRequest'
import { PATCH_PLAID_TOKEN_METADATA_F_REQUEST } from 'constants/flowConstants'
import postPlaidTokenRequest from 'utilities/actions/post/postPlaidTokenRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

import {
  PLAID_LINK_TOKEN,
  PLAID_PROCESSOR_TOKEN,
} from 'constants/onboardingFormConstants'

import {
  getPlaidLinkModalTokensSelector,
  getPlaidPublicTokensSelector,
} from 'state-layer/selectors'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state, props)
  const plaidLinkModalToken = get(getPlaidLinkModalTokensSelector(state), 'token')
  const plaidProcessorToken = get(getPlaidPublicTokensSelector(state), 'token')

  const [
    children,
    style,
    id,
  ] = getMany(props, [
    'children',
    'style',
    'id',
  ])

  return {
    id,
    tokenType: PLAID_PROCESSOR_TOKEN,
    plaidLinkModalToken,
    children,
    style,
    credentials,
    plaidProcessorToken,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendPlaidPublicToken: ({ id, tokenType, accountId, plaidPublicToken, entityType, credentials }) => dispatch(postPlaidPublicTokenRequest({ id, tokenType, accountId, plaidPublicToken, entityType, credentials })),
    addLinkedBankAccountData: ({ metadata }) => dispatch({
      type: PATCH_PLAID_TOKEN_METADATA_F_REQUEST,
      payload: {
        newValues: {
          metadata,
        },
        meta: {
          overwriteReducer: true,
        },
      },
    }),
    getPlaidLinkToken: ({ id, tokenType, country, entityType, credentials }) => dispatch(postPlaidTokenRequest({ id, tokenType, country, entityType, credentials })),
  }
}

class PlaidLinkC extends Component {
  componentDidMount() {
    const {
      id,
      getPlaidLinkToken,
      country,
      entityType,
      credentials,
    } = this.props

    // on mount, get the link modal token to begin the plaid experience
    if (id && country) {
      getPlaidLinkToken({ id, tokenType: PLAID_LINK_TOKEN, country, entityType, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      getPlaidLinkToken,
      country,
      entityType,
      credentials,
      plaidProcessorToken,
      onSuccess,
    } = this.props

    const {
      country: prevCountry,
      plaidProcessorToken: prevPlaidProcessorToken,
    } = prevProps

    if (id && country && country !== prevCountry) {
      getPlaidLinkToken({ id, tokenType: PLAID_LINK_TOKEN, country, entityType, credentials })
    }

    // run any follow-on requests after successful link
    if (plaidProcessorToken && onSuccess && plaidProcessorToken !== prevPlaidProcessorToken) {
      onSuccess(plaidProcessorToken)
    }
  }

  onSuccess = (publicToken, metadata) => {
    const {
      sendPlaidPublicToken,
      id,
      tokenType,
      addLinkedBankAccountData,
      entityType,
      credentials,
    } = this.props

    const accountId = get(metadata, 'account_id')
    sendPlaidPublicToken({ id, tokenType, accountId, plaidPublicToken: publicToken, entityType, credentials })

    // temporarily store the metadata in the redux store
    addLinkedBankAccountData({ metadata })
  }

  render() {
    const {
      plaidLinkModalToken,
      children,
      style,
      className,
    } = this.props

    return (
      <PlaidLink
        style={style}
        className={className}
        token={plaidLinkModalToken}
        onSuccess={this.onSuccess}
      >{children}
      </PlaidLink>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLinkC)
