import './MemberS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import PermissionsC from 'components/Customer/Pages/Permissions/PermissionsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'

import {
  DETAILS,
  PERMISSIONS,
} from 'constants/language/languageConstants'

const Member = ({
  headerData = {},
  detailsSectionData = [],
  isFetching = false,
  actions = [],
  userPermissions = [],
  type = '',
  authRole = '',
}) => {
  return (
    <div className='Member'>
      <ContextBar />

      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <PageSectionV2C
        title={DETAILS}
        columns={convertPageSectionDataToV2(detailsSectionData)}
        isFetching={isFetching}
      />

      <PageSectionV2C
        title={PERMISSIONS}
        isFetching={isFetching}
      >
        <PermissionsC
          userPermissions={userPermissions}
          type={type}
          authRole={authRole}
        />
      </PageSectionV2C>
    </div>
  )
}

Member.propTypes = {
  headerData: PropTypes.object,
  detailsSectionData: PropTypes.array,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  userPermissions: PropTypes.array,
  type: PropTypes.string,
  authRole: PropTypes.string,
}

export default Member
