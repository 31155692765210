import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { PATCH_DEVICE_F_REQUEST } from 'constants/flowConstants'
import { EDIT_DEVICE_CONFIGURATION_DETAILS } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import numeral from 'numeral'
import filter from 'lodash/filter'

import {
  AMOUNT_VALUE,
  EMAIL_VALUE,
  PRINT_VALUE,
  SMS_VALUE,
} from 'constants/deviceConstants'

const submitEditDeviceConfigurationDetailsForm = (values, dispatch, props) => {
  const {
    credentials,
    deviceId,
    deviceName,
  } = props

  const {
    promptSignature,
    amountOne,
    amountTwo,
    amountThree,
    percentageOne,
    percentageTwo,
    percentageThree,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
    signatureThresholdAmount,
    promptForSignature,
  } = values

  const availableReceiptMethods = filter([
    receiptDeliveryPrint ? PRINT_VALUE : undefined,
    receiptDeliveryEmail ? EMAIL_VALUE : undefined,
    receiptDeliverySms ? SMS_VALUE : undefined,
  ], undefined)

  const automaticReceiptDeliveryMethods = filter([
    automaticDeliveryPrint ? PRINT_VALUE : undefined,
    automaticDeliveryEmail ? EMAIL_VALUE : undefined,
    automaticDeliverySms ? SMS_VALUE : undefined,
  ], undefined)

  const percentOptions = [percentageOne, percentageTwo, percentageThree]
  const fixedOptions = [amountOne, amountTwo, amountThree]

  const valuesToSubmit = {
    ...values,
    promptSignature: get(promptSignature, 'value'),
    promptForSignature: get(promptForSignature, 'value'),
    signatureThresholdAmount: get(promptSignature, 'value') === AMOUNT_VALUE ? numeral(signatureThresholdAmount).value() : 0,
    availableReceiptMethods,
    automaticReceiptDeliveryMethods,
    percentOptions,
    fixedOptions,
  }

  dispatch({
    type: PATCH_DEVICE_F_REQUEST,
    payload: {
      id: deviceId,
      credentials,
      values: valuesToSubmit,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(EDIT_DEVICE_CONFIGURATION_DETAILS, {
        credentials,
        values,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
      successMessage: `${deviceName} has been successfully updated.`,
    },
  })
}

export default submitEditDeviceConfigurationDetailsForm
