import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateMerchantVerificationForm from 'components/Customer/Forms/CreateMerchantVerificationForm/CreateMerchantVerificationForm'
import { getMerchantSelector } from 'state-layer/selectors'
import { FETCHING } from 'constants/reducerConstants'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import includes from 'lodash/includes'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'
import { VANTIV_V1 } from 'constants/processorConstants'
import { REJECTED } from 'constants/settlementConstants'
import { ADVANCED_PROCESSOR_ENABLED_PLATFORMS } from 'constants/platformConstants'

const mapStateToProps = (state, props) => {
  const {
    merchantId,
  } = props

  const merchant = getMerchantSelector(state, merchantId)
  const credentials = getCurrentCredentials(state)

  const {
    processor,
    paymentInstruments,
    onboardingState,
  } = merchant

  const isFetching = get(state, `merchantsR.${FETCHING}`)

  const paymentInstrumentOptions = map(paymentInstruments, (paymentInstrument) => {
    const {
      id,
      name,
      accountType,
      maskedAccountNumber,
    } = paymentInstrument

    return {
      label: id,
      value: { id, name, maskedAccountNumber },
      accountType,
    }
  })

  const defaultPaymentInstrumentOption = head(paymentInstrumentOptions)

  const initialValues = !isFetching ? {
    defaultBankAccount: defaultPaymentInstrumentOption,
  } : undefined

  const platformName = getCurrentPlatformName()
  const advancedProcessorEnabled = includes(ADVANCED_PROCESSOR_ENABLED_PLATFORMS, platformName)
  const vantivProcessor = processor === VANTIV_V1
  const optionalProcessorParamsAllowed = vantivProcessor && advancedProcessorEnabled
  const showCodeInputOptions = onboardingState === REJECTED

  return removeUndefined({
    processor,
    credentials,
    initialValues,
    paymentInstrumentOptions,
    optionalProcessorParamsAllowed,
    showCodeInputOptions,
  })
}

class CreateMerchantVerificationFormC extends Component {
  render() {
    return (
      <CreateMerchantVerificationForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(CreateMerchantVerificationFormC)
