import './SplitRefundS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import TagsPageSectionV2 from 'components/Customer/Shared/Page/PageSection/TagsPageSectionV2'
import PaymentInstrumentDetailsC from 'components/Customer/Shared/Display/PaymentInstrumentDetails/PaymentInstrumentDetailsC'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import BuyerDetailsC from 'components/Customer/Shared/Display/BuyerDetails/BuyerDetailsC'
import TableC from 'components/Shared/Table/TableC'
import { resourceTableThemeV2 } from 'constants/themeConstants'
import isEmpty from 'lodash/isEmpty'

import {
  BUYER_DETAILS,
  SPLIT_DETAILS,
  SPLIT_REFUND_DETAILS_CHILD_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const SplitRefund = ({
  tagsSectionData = [],
  transfer = {},
  paymentInstrument = {},
  isFetching = false,
  actions = [],
  headerData = {},
  contextBarData = {},
  detailsDataSection = [],
  device = {},
  buyerIdentity = {},
  splitDetailsTableData = [],
  splitRefundsColumnDescriptors = [],
}) => {
  return (
    <div className='SplitRefund'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        actions={actions}
        isFetching={isFetching}
        {...headerData}
      />

      {/* TODO: add in once API is ready */}
      {/* <PageSectionV2C */}
      {/*   title={PAYMENT_DETAILS} */}
      {/*   columns={detailsDataSection} */}
      {/*   isFetching={isFetching} */}
      {/* /> */}

      <TableC
        title={SPLIT_DETAILS}
        subtitle={SPLIT_REFUND_DETAILS_CHILD_SUBTITLE_DESCRIPTION}
        classNames='SplitDetails Table'
        data={splitDetailsTableData}
        columnDescriptors={splitRefundsColumnDescriptors}
        showTableFooter={false}
        theme={resourceTableThemeV2}
        noEmptyMessageIcon
        doNotSortData
        isFetching={isFetching}
      />

      <BuyerDetailsC
        title={BUYER_DETAILS}
        buyerIdentity={buyerIdentity}
        isFetching={isFetching}
      />

      {!isEmpty(paymentInstrument) && (
        <PaymentInstrumentDetailsC
          isFetching={isFetching}
          device={device}
          paymentInstrument={paymentInstrument}
        />
      )}

      <TagsPageSectionV2
        data={tagsSectionData}
        record={transfer}
        isFetching={isFetching}
      />
    </div>
  )
}

SplitRefund.propTypes = {
  tagsSectionData: PropTypes.array,
  paymentInstrument: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  detailsDataSection: PropTypes.array,
  buyerIdentity: PropTypes.object,
  splitDetailsTableData: PropTypes.array,
  splitRefundsColumnDescriptors: PropTypes.array,
}

export default SplitRefund
