import './RiskProfileFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import submitRiskProfileForm from 'utilities/submit/submitRiskProfileForm'
import { RISK_PROFILE_FORM } from 'constants/formConstants'

import {
  ALLOW_AVS_FAILURE,
  ALLOW_AVS_FAILURE_HELPER_TEXT,
  ALLOW_CVV_FAILURE,
  ALLOW_CVV_FAILURE_HELPER_TEXT,
  CANCEL,
  SUBMIT,
} from 'constants/language/languageConstants'

const RiskProfileForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  submitting = false,
  cvvFailureAllowed = false,
  avsFailureAllowed = false,
}) => {
  return (
    <form className='RiskProfileForm' onSubmit={handleSubmit(submitRiskProfileForm)}>
      <div className='section'>
        <ToggleSwitchC
          name='csc_failure_allowed'
          label={ALLOW_CVV_FAILURE}
          checked={cvvFailureAllowed}
          helperText={ALLOW_CVV_FAILURE_HELPER_TEXT}
          form={RISK_PROFILE_FORM}
        />

        <ToggleSwitchC
          name='avs_failure_allowed'
          label={ALLOW_AVS_FAILURE}
          checked={avsFailureAllowed}
          helperText={ALLOW_AVS_FAILURE_HELPER_TEXT}
          form={RISK_PROFILE_FORM}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' submitting={submitting} label={SUBMIT} />
      </div>
    </form>
  )
}

RiskProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  submitting: PropTypes.bool,
  cvvFailureAllowed: PropTypes.bool,
  avsFailureAllowed: PropTypes.bool,
}

export default RiskProfileForm
