import frontendBalanceTransferModel from 'utilities/create/models/frontendBalanceTransferModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendBalanceTransfersModel = ({ data: balanceTransfers }) => {
  const balanceTransfersModels = map(balanceTransfers, (balanceTransfer) => frontendBalanceTransferModel({ data: balanceTransfer }))

  return keyBy(balanceTransfersModels, 'id')
}

export default frontendBalanceTransfersModel
