import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateTransactionForm from './CreateTransactionForm'
import Loader from 'components/Shared/Loader/Loader'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import getMany from 'utilities/get/getMany'
import isFetching from 'utilities/is/isFetching'
import getUrlQuery from 'utilities/get/getUrlQuery'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import showModalAction from 'utilities/actions/showModalAction'
import removeUndefined from 'utilities/remove/removeUndefined'
import updateUrlQueries from 'utilities/updateUrlQueries'
import clearReducerRequest from 'utilities/actions/clearReducerRequest'
import getMerchantDevicesRequest from 'utilities/actions/get/getMerchantDevicesRequest'
import { SHOW_ACH_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { CREATE_TRANSACTION_FORM } from 'constants/formConstants'
import { USA } from 'constants/countryConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import capitalize from 'lodash/capitalize'
import values from 'lodash/values'

import {
  L2_ENABLED_FORM_FIELDS,
  L3_ENABLED_FORM_FIELDS,
} from 'constants/processorConstants'

import {
  ADD_ICON,
  BANK_ICON,
} from 'constants/iconConstants'

import {
  change,
  untouch,
  formValueSelector,
} from 'redux-form'

import {
  getCardBrand,
  PAYMENT_CARD,
  BANK_ACCOUNT,
} from 'constants/bankConstants'

import {
  CREATE_PAYMENT_INSTRUMENT_MODAL,
  NEW_BUYER_IDENTITY_MODAL,
} from 'constants/modalConstants'

import {
  USD,
  COUNTRY_TO_CURRENCY_NAME_MAP,
} from 'constants/currencyConstants'

import {
  GET_IDENTITIES_F_REQUEST,
  GET_PAYMENT_INSTRUMENTS_F_REQUEST,
} from 'constants/flowConstants'

import {
  getDevicesSelector,
  getIdentitySelector,
  getMerchantSelector,
  getIdentitiesSelector,
  getPaymentInstrumentsSelector,
  getPaymentInstrumentSelector,
} from 'state-layer/selectors'

import {
  ADD_A_NEW_PAYMENT_METHOD,
  ADD_NEW_BUYER,
  ADD_NEW_CARD,
  CARD,
  NOT_PROVIDED,
} from 'constants/language/languageConstants'

const reactSelectCustomStyles = {
  customOption: {
    color: '#0B5DBC',
  },
  option: {},
}

const mapStateToProps = (state, props) => {
  const [
    credentials,
    applicationId,
    isSale,
    identityMaxTransactionAmount,
  ] = getMany(props, [
    'credentials',
    'applicationId',
    'isSale',
    'identityMaxTransactionAmount',
  ])

  const environment = get(credentials, 'environment')
  const isFetchingDevices = isFetching(state, 'devicesR')
  const isFetchingIdentities = isFetching(state, 'identitiesR')
  const isFetchingDashboardConfig = isFetching(state, 'dashboardConfigR')
  const isFetchingPaymentInstruments = isFetching(state, 'paymentInstrumentsR')
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const isRolePlatformCredential = isRolePlatform({ credentials })
  const identities = getIdentitiesSelector(state)
  const formSelector = formValueSelector(CREATE_TRANSACTION_FORM)
  const existingFormValues = get(state, 'flowsR.formValues.createPayment')
  const buyerIdentityId = get(formSelector(state, 'buyerName'), 'value') || get(existingFormValues, 'buyerName.value') || getUrlQuery('buyerIdentityId')
  const buyerIdentity = getIdentitySelector(state, buyerIdentityId)
  const paymentInstrumentId = get(formSelector(state, 'paymentMethod'), 'value') || get(existingFormValues, 'paymentMethod.value') || getUrlQuery('paymentInstrumentId')
  const paymentInstrument = getPaymentInstrumentSelector(state, paymentInstrumentId)
  const isCardMethod = isEqual(get(paymentInstrument, 'instrumentType'), PAYMENT_CARD)
  const merchantId = getUrlQuery('merchantId')
  const merchant = getMerchantSelector(state, merchantId)
  const merchantCountry = get(merchant, 'country', USA)
  const currency = COUNTRY_TO_CURRENCY_NAME_MAP[merchantCountry] || USD
  const devices = getDevicesSelector(state)
  const deviceOptions = map(devices, ({ id, name }) => ({ label: name, value: id }))
  const hasDevices = !isFetchingDevices && !isEmpty(devices)
  const selectedDevice = get(formSelector(state, 'device'), 'value')

  // get payment instruments for the buyer of type bank account or payment card
  const buyerIdentityPaymentInstruments = buyerIdentityId ? filter(getPaymentInstrumentsSelector(state), ({ instrumentType }) => instrumentType === PAYMENT_CARD || instrumentType === BANK_ACCOUNT) : []
  const identitiesWithFullName = filter(identities, (identity) => !!get(identity, 'fullName'))
  const amount = formSelector(state, 'amount') || get(existingFormValues, 'amount') || getUrlQuery('amount')
  const transferDescription = formSelector(state, 'description') || get(existingFormValues, 'description') || getUrlQuery('transferDescription')
  const additionalDataSelection = formSelector(state, 'additionalDataSelection.value') || get(existingFormValues, 'additionalDataSelection.value')
  const levelThreeItemsValue = formSelector(state, 'levelThreeItems') || get(existingFormValues, 'levelThreeItems')
  const canUseACHPayment = isSale && getFeatureFlag(SHOW_ACH_VIRTUAL_TERMINAL)

  const identitiesNames = map(identitiesWithFullName, (identity) => {
    const {
      id,
      fullName,
      email,
    } = identity

    return {
      label: <div className='flex items-center'>{fullName} <span className='secondary'>({ email ? email : '-' })</span></div>,
      value: id,
    }
  })

  const sortedIdentitiesNames = concat({ label: <div className='flex items-center'><span className={`add-icon fa fa-${ADD_ICON}`} /> {ADD_NEW_BUYER}</div>, value: 'newBuyer' }, identitiesNames)

  const initialValuesRequired = !isEmpty(getUrlQuery('paymentInstrumentId')) || !isEmpty(getUrlQuery('buyerIdentityId')) || !isEmpty(existingFormValues)
  const isPaymentInstrumentLoaded = getUrlQuery('paymentInstrumentId') || get(existingFormValues, 'paymentMethod') ? !isEmpty(paymentInstrument) : true
  const isIdentityLoaded = getUrlQuery('buyerIdentityId') || get(existingFormValues, 'buyerName') ? !isEmpty(buyerIdentity) : true
  const isInitialValuesLoaded = isPaymentInstrumentLoaded && isIdentityLoaded

  const initialValues = isInitialValuesLoaded ? {
    buyerName: buyerIdentityId ? { value: buyerIdentityId, label: `${get(buyerIdentity, 'fullName')} (${get(buyerIdentity, 'email')})` } : null,
    paymentMethod: paymentInstrumentId ? { value: paymentInstrumentId, label: `${get(paymentInstrument, 'displayBrand') === 'Unknown' ? CARD : get(paymentInstrument, 'displayBrand')} ${get(paymentInstrument, 'maskedFullCardNumber')}` } : null,
    amount,
    description: transferDescription || null,
    additionalDataSelection: { label: capitalize(NOT_PROVIDED), value: 'N/A' },
  } : undefined

  // TODO: all that's comment out below is for future use when enabling this functionality for ROLE_PLATFORM
  // const formApplicationId = get(formSelector(state, 'applicationId'), 'value')
  // const filteredMerchants = filter(merchants, (merchant) => { return merchant.processor === allowedProcessors && merchant.mid })
  // const isLiveEnvironment = isEqual(get(credentials, 'environment'), LIVE_ENVIRONMENT)
  // const allowedProcessors = isLiveEnvironment ? LITLE_V1 : DUMMY_V1
  // const applications = getApplicationsSelector(state)

  // const applicationNames = map(applications, (application) => {
  //   const {
  //     id,
  //     businessName,
  //   } = application
  //
  //   return {
  //     label: businessName,
  //     value: id,
  //   }
  // })
  //
  // const merchantAccounts = map(filteredMerchants, (merchant) => {
  //   const {
  //     id,
  //     name,
  //   } = merchant
  //
  //   return {
  //     label: name,
  //     value: id,
  //   }
  // })

  return removeUndefined({
    isFetchingDevices,
    isFetchingIdentities,
    isFetchingDashboardConfig,
    isFetchingPaymentInstruments,
    credentials,
    isStandaloneMerchant,
    isRolePlatformCredential,
    applicationId,
    identityMaxTransactionAmount,
    sortedIdentitiesNames,
    identities,
    buyerIdentityId,
    paymentInstrumentId,
    environment,
    paymentInstrument,
    initialValues,
    isInitialValuesLoaded,
    initialValuesRequired,
    additionalDataSelection,
    isCardMethod,
    levelThreeItemsValue,
    buyerIdentityPaymentInstruments,
    isSale,
    canUseACHPayment,
    currency,
    merchantId,
    hasDevices,
    deviceOptions,
    selectedDevice,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationIdentities: ({ applicationId, credentials }) => dispatch({
      type: GET_IDENTITIES_F_REQUEST,
      payload: {
        values: {
          applicationId,
        },
        credentials,
        queries: {
          limit: 200,
        },
      },
    }),
    getBuyerIdentityPaymentInstruments: ({ credentials, queries }) => dispatch({
      type: GET_PAYMENT_INSTRUMENTS_F_REQUEST,
      payload: {
        credentials,
        queries,
      },
    }),
    clearReducer: (reducerNames) => dispatch(clearReducerRequest(reducerNames)),
    updateCreateTransactionFormField: ({ value, field }) => dispatch(change(CREATE_TRANSACTION_FORM, field, value)),
    untouchCreateTransactionFormField: ({ value, field }) => dispatch(untouch(CREATE_TRANSACTION_FORM, field)),
    showNewBuyerIdentityModal: (modalProps) => dispatch(showModalAction({ modalType: NEW_BUYER_IDENTITY_MODAL, modalProps })),
    showCreatePaymentInstrumentModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_PAYMENT_INSTRUMENT_MODAL, className: 'modal-md no-pad', modalProps })),
    getMerchantDevices: ({ credentials, merchantId }) => dispatch(getMerchantDevicesRequest({ credentials, merchantId })),
    // TODO: all that's commented out below is for future use when enabling this functionality for ROLE_PLATFORM
    // getApplications: ({ credentials }) => dispatch({
    //   type: GET_APPLICATIONS_F_REQUEST,
    //   payload: {
    //     credentials,
    //     queries: {
    //       limit: 200,
    //     },
    //   },
    // }),
    // getApplicationMerchants: ({ formApplicationId, credentials }) => dispatch({
    //   type: GET_APPLICATION_MERCHANTS_F_REQUEST,
    //   payload: {
    //     values: {
    //       applicationId: formApplicationId,
    //     },
    //     credentials,
    //     queries: {
    //       limit: 200,
    //     },
    //   },
    // }),
  }
}

class CreateTransactionFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formInitialized: false,
      sendMethod: 'manual',
    }
  }

  componentDidMount() {
    const {
      merchantId,
      applicationId,
      buyerIdentityId,
      getMerchantDevices,
      getApplicationIdentities,
      getBuyerIdentityPaymentInstruments,
      clearReducer,
      credentials,
    } = this.props

    const type = getUrlQuery('type')

    if (!type) {
      updateUrlQueries({ type: 'sale' })
    }

    if (applicationId) {
      getApplicationIdentities({ applicationId, credentials })
    }

    if (buyerIdentityId) {
      clearReducer(['paymentInstrumentsR'])

      getBuyerIdentityPaymentInstruments({
        credentials,
        queries: { owner_identity_id: buyerIdentityId },
      })
    }

    if (merchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      credentials,
      applicationId,
      buyerIdentityId,
      paymentInstrumentId,
      getBuyerIdentityPaymentInstruments,
      showNewBuyerIdentityModal,
      showCreatePaymentInstrumentModal,
      updateCreateTransactionFormField,
      untouchCreateTransactionFormField,
      environment,
      initialValues,
      initialValuesRequired,
      additionalDataSelection,
      clearReducer,
      isSale,
      canUseACHPayment,
      merchantId,
      getMerchantDevices,
    } = this.props

    const { formInitialized } = this.state

    const {
      buyerIdentityId: prevBuyerIdentityId,
      paymentInstrumentId: prevPaymentInstrumentId,
      additionalDataSelection: prevAdditionalDataSelection,
      isSale: prevIsSale,
      merchantId: prevMerchantId,
    } = prevProps

    // if they switch from sale to auth, we need to clear out the payment instrument
    if (isSale !== prevIsSale) {
      updateCreateTransactionFormField({ field: 'paymentMethod', value: null })
    }

    if (buyerIdentityId && buyerIdentityId !== prevBuyerIdentityId && buyerIdentityId !== 'newBuyer') {
      if (prevBuyerIdentityId) {
        updateCreateTransactionFormField({ field: 'paymentMethod', value: null })
      }

      clearReducer(['paymentInstrumentsR'])

      getBuyerIdentityPaymentInstruments({
        credentials,
        queries: { owner_identity_id: buyerIdentityId },
      })
    }

    const showNewBuyerModal = prevBuyerIdentityId !== buyerIdentityId && buyerIdentityId === 'newBuyer'
    const showNewPaymentMethodModal = prevPaymentInstrumentId !== paymentInstrumentId && paymentInstrumentId === 'newPaymentMethod'

    if (showNewBuyerModal) {
      updateCreateTransactionFormField({ field: 'buyerName', value: null })
      showNewBuyerIdentityModal({
        successFunction: (newIdentityId, newFullName, newEmail) => updateCreateTransactionFormField({ field: 'buyerName', value: { value: newIdentityId, label: `${newFullName} (${newEmail})` } }),
      })
    }

    if (showNewPaymentMethodModal) {
      updateCreateTransactionFormField({ field: 'paymentMethod', value: null })
      showCreatePaymentInstrumentModal({ modalProps: credentials, applicationId, buyerIdentityId, environment, showTokenForm: canUseACHPayment })
    }

    if (!formInitialized && (!isEmpty(initialValues) || !initialValuesRequired)) {
      this.setState({ formInitialized: true })
    }

    // when switching additional data options, clear out redux form values associated with level 2 or level 3 fields only
    if (!isEqual(prevAdditionalDataSelection, additionalDataSelection)) {
      forEach(concat(L2_ENABLED_FORM_FIELDS, L3_ENABLED_FORM_FIELDS), (field) => {
        updateCreateTransactionFormField({
          field,
          value: null,
        })

        untouchCreateTransactionFormField({ field })
      })
    }

    if (merchantId && merchantId !== prevMerchantId) {
      getMerchantDevices({ credentials, merchantId })
    }
  }

  setIsSale = (query) => {
    updateUrlQueries({ type: query })
  }

  render() {
    const {
      sendMethod,
      formInitialized,
    } = this.state

    const { buyerIdentityPaymentInstruments } = this.props

    // get payment method options for sale (card & banks) vs auth (only cards)
    const paymentInstrumentMaskedNumbers = map(buyerIdentityPaymentInstruments, (buyerIdentityPaymentInstrument) => {
      const {
        id,
        brand,
        expirationDate,
        maskedFullCardNumber,
        maskedAccountNumber,
        type,
      } = buyerIdentityPaymentInstrument

      const cardIcon = getCardBrand(brand)

      return {
        label: type === 'Bank Account' ? (
          <div className='flex items-center'>
            <i className={`card-brand-icon fa fa-${BANK_ICON}`} />
            {maskedAccountNumber}
          </div>
        ) : (
          <div className='flex items-center'>
            <i className={`card-brand-icon ${cardIcon}`} />
            {maskedFullCardNumber}
            <span className='secondary'>({expirationDate})</span>
          </div>
        ),
        value: id,
      }
    })

    const buyerIdentityPaymentInstrumentsOnlyCard = filter(values(buyerIdentityPaymentInstruments), ({ instrumentType }) => instrumentType === PAYMENT_CARD)
    const paymentInstrumentMaskedNumbersOnlyCard = map(buyerIdentityPaymentInstrumentsOnlyCard, (buyerIdentityPaymentInstrument) => {
      const {
        id,
        brand,
        expirationDate,
        maskedFullCardNumber,
      } = buyerIdentityPaymentInstrument

      const cardIcon = getCardBrand(brand)

      return {
        label: (
          <div className='flex items-center'>
            <i className={`card-brand-icon ${cardIcon}`} />
            {maskedFullCardNumber}
            <span className='secondary'>({expirationDate})</span>
          </div>
        ),
        value: id,
      }
    })

    const paymentInstrumentOptions = concat({ label: <div className='flex items-center'><span className={`add-icon fa fa-${ADD_ICON}`} /> {ADD_NEW_CARD}</div>, value: 'newPaymentMethod' }, paymentInstrumentMaskedNumbersOnlyCard)
    // Add feature flag to see if we will allow users to add bank accounts?
    const paymentInstrumentOptionsWithBankAccounts = concat({ label: <div className='flex items-center'><span className={`add-icon fa fa-${ADD_ICON}`} /> {ADD_A_NEW_PAYMENT_METHOD}</div>, value: 'newPaymentMethod' }, paymentInstrumentMaskedNumbers)

    if (!formInitialized) {
      return <Loader />
    }

    return (
      <CreateTransactionForm
        {...this.props}
        reactSelectCustomStyles={reactSelectCustomStyles}
        setIsSale={this.setIsSale}
        paymentInstrumentOptions={paymentInstrumentOptions}
        paymentInstrumentOptionsWithBankAccounts={paymentInstrumentOptionsWithBankAccounts}
        sendMethod={sendMethod}
        setSendMethod={(method) => this.setState({ sendMethod: method })}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionFormC)
