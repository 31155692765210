import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_MFA_VERIFY } from 'constants/apiConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'

const postDashboardUserMFAVerifyAPI = ({ values, meta }) => {
  const userId = get(getCurrentCredentials(), 'userId')

  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_MFA_VERIFY({ userId }),
    values,
    meta,
  })
}

export default postDashboardUserMFAVerifyAPI
