import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import flashNotificationAction from 'utilities/actions/flashNotificationAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import get from 'lodash/get'
import filter from 'lodash/filter'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const dropdownActions = get(props, 'dropdownActions')
  const filteredDropdownActions = filter(dropdownActions, ({ condition = true }) => condition)

  return {
    credentials,
    filteredDropdownActions,
  }
}

class PageSectionV2C extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showInfo: true,
      showDropdownActions: false,
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = (event) => {
    const clickedOutside = !event?.target.closest('.dropdown-actions')

    if (clickedOutside) {
      this.setState({ showDropdownActions: false })
      document.removeEventListener('click', this.handleOutsideClick)
    }
  }

  toggleInfo = () => {
    const { showInfo } = this.state

    this.setState({ showInfo: !showInfo })
  }

  toggleDropdownActions = () => {
    const { showDropdownActions } = this.state

    this.setState({ showDropdownActions: !showDropdownActions })

    if (!showDropdownActions) {
      document.addEventListener('click', this.handleOutsideClick)
    } else {
      document.removeEventListener('click', this.handleOutsideClick)
    }
  }

  render() {
    const { toggleInfo, toggleDropdownActions } = this
    const { showInfo, showDropdownActions } = this.state

    return (
      <PageSectionV2
        {...this.props}
        showInfo={showInfo}
        showDropdownActions={showDropdownActions}
        toggleInfo={toggleInfo}
        toggleDropdownActions={toggleDropdownActions}
      />
    )
  }
}

export default connect(mapStateToProps)(PageSectionV2C)
