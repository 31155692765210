import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComplianceFormTemplate from './ComplianceFormTemplate'
import { getComplianceFormTemplateSelector } from 'state-layer/selectors'
import getComplianceFormTemplateRequest from 'utilities/actions/get/getComplianceFormTemplateRequest'
import getMany from 'utilities/get/getMany'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { APPLICATION_PATH, COMPLIANCE_PATH, COMPANY_PATH } from 'constants/pathConstants'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING } from 'constants/complianceConstants'
import get from 'lodash/get'

import {
  TYPE,
  TEMPLATE_CREATED_ON,
  COMPLIANCE_FORM_TEMPLATE,
  COMPLIANCE_FORM_TEMPLATES,
  PLATFORM,
  APPLICATION,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `complianceFormTemplatesR.${FETCHING}`)
  const complianceFormTemplateId = get(props, 'params.complianceFormTemplateId')
  const complianceFormTemplate = getComplianceFormTemplateSelector(state, complianceFormTemplateId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    name,
    displayCreatedAt,
    linkedTo,
    linkedType,
    type,
    applicationName,
    platformName,
  ] = getMany(complianceFormTemplate, [
    'name',
    'displayCreatedAt',
    'linkedTo',
    'linkedType',
    'type',
    'applicationName',
    'platformName',
  ])

  const contextBarData = {
    back: {
      label: COMPLIANCE_FORM_TEMPLATES,
      path: `${COMPLIANCE_PATH({ credentialId })}?tab=form-templates`,
    },
    items: [
      {
        label: PLATFORM,
        value: platformName,
        path: `${COMPANY_PATH({ credentialId })}?tab=compliance-settings`,
        condition: linkedType === 'PLATFORM',
      },
      {
        label: APPLICATION,
        value: applicationName,
        path: APPLICATION_PATH({ credentialId, applicationId: linkedTo }),
        condition: linkedType === 'APPLICATION',
      },
    ],
  }

  const headerData = {
    resource: {
      label: COMPLIANCE_FORM_TEMPLATE,
      id: complianceFormTemplateId,
    },
    items: [
      {
        value: <h1>{name}</h1>,
      },
      {
        label: TEMPLATE_CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: TYPE,
        value: COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type],
      },
    ],
    isFetching,
  }

  return {
    complianceFormTemplate,
    complianceFormTemplateId,
    credentials,
    contextBarData,
    headerData,
    linkedTo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComplianceFormTemplate: ({ complianceFormTemplateId, credentials }) => dispatch(getComplianceFormTemplateRequest({ complianceFormTemplateId, credentials })),
  }
}

class ComplianceFormTemplateC extends Component {
  componentDidMount() {
    const {
      complianceFormTemplateId,
      getComplianceFormTemplate,
      credentials,
    } = this.props

    getComplianceFormTemplate({ complianceFormTemplateId, credentials })
  }

  render() {
    return (
      <ComplianceFormTemplate
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceFormTemplateC)
