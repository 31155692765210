import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { getMerchantPayoutSettingsConnector } from 'state-layer/selectors'
import PayoutSettings from './PayoutSettings'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMerchantSettlementsRequest from 'utilities/actions/get/getMerchantSettlementsRequest'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getMany from 'utilities/get/getMany'
import { hasPlatformAccess } from 'utilities/validate/checkRoleCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { PENDING } from 'constants/settlementConstants'
import { EDIT_PAYOUT_SETTINGS_WARNING } from 'constants/modalConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  PAYOUT_SETTING_PATH,
  PAYMENT_INSTRUMENT_PATH,
} from 'constants/pathConstants'

import {
  CONFIGURABLE_WINDOW,
  GROSS,
  PROCESSOR_WINDOW,
  SETTLE_UPON_LABEL_MAPS,
} from 'constants/payoutSettingsConstants'

import {
  ID,
  ROUTING_NUMBER,
  ACCOUNT_NUMBER,
  PAYOUT_TYPE,
  PAYOUT_BANK_ACCOUNT_TITLE,
  PAYOUT_BANK_ACCOUNT_DESCRIPTION,
  FEE_BANK_ACCOUNT_TITLE,
  FEE_BANK_ACCOUNT_DESCRIPTION,
  EDIT,
  PAYOUT_SCHEDULE_LABEL,
  PAYOUT_SCHEDULE_DESCRIPTION,
  SETTLEMENT_SCHEDULE,
  TRANSFER_SPEED,
  FEE_SCHEDULE,
  FEE_SCHEDULE_DESCRIPTION,
  READY_TO_SETTLE_LABEL,
  FEE_READY_TO_SETTLE_AT_LABEL,
  MANUAL_SETTLEMENT_SCHEDULE_TOOLTIP_MESSAGE,
  MANUAL_OPTION,
  ACCOUNT_NAME,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  SETTLEMENT_BATCH_TIME,
  SETTLEMENT_BATCH_TIME_SUBTITLE,
  BATCH_TIME_SETTING,
  CUTOFF_TIME,
  PAYOUT_SETTING,
  FEE_SETTING,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentUserEmail = get(getCurrentUser(), 'userEmail')
  const isFetching = get(state, `merchantsR.${FETCHING}`) || get(state, `merchantPayoutSettingsR.${FETCHING}`)
  const merchantId = get(props, 'merchantId')
  const merchant = get(props, 'merchant')
  const merchantPayoutSettings = getMerchantPayoutSettingsConnector(state, merchantId)
  const payoutPlan = get(props, 'payoutPlan')
  const payoutPlanId = get(payoutPlan, 'id')
  const showPayoutPlan = hasPlatformAccess({ credentials }) && !!payoutPlanId
  const isFlexCredential = isFlexPlatform(credentials)

  const merchantPaymentInstruments = get(merchant, 'paymentInstruments')
  const payoutPaymentInstrumentId = get(merchantPayoutSettings, 'payoutPaymentInstrumentId')
  const feePaymentInstrumentId = get(merchantPayoutSettings, 'feePaymentInstrumentId')
  const payoutPaymentInstrument = get(merchantPaymentInstruments, payoutPaymentInstrumentId)
  const feePaymentInstrument = get(merchantPaymentInstruments, feePaymentInstrumentId)
  const merchantDisplayReadyToSettleUpon = get(merchant, 'displayReadyToSettleUpon')
  const merchantDisplayFeeReadyToSettleUpon = get(merchant, 'displayFeeReadyToSettleUpon')
  const merchantReadyToSettleUpon = get(merchant, 'readyToSettleUpon')
  const merchantFeeReadyToSettleUpon = get(merchant, 'feeReadyToSettleUpon')

  const [
    payoutPIId,
    payoutPIBankCode,
    payoutPITransitNumber,
    payoutPIInstitutionNumber,
    payoutPIAccountNumber,
    payoutPIName,
  ] = getMany(payoutPaymentInstrument, [
    'id',
    'bankCode',
    'transitNumber',
    'institutionNumber',
    'maskedAccountNumber',
    'name',
  ])

  const [
    feePIId,
    feePIBankCode,
    feePIAccountNumber,
    feePIName,
  ] = getMany(feePaymentInstrument, [
    'id',
    'bankCode',
    'maskedAccountNumber',
    'name',
  ])

  const payoutPIPath = PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId: payoutPIId })
  const feePIPath = PAYMENT_INSTRUMENT_PATH({ credentialId, paymentInstrumentId: feePIId })

  const [
    payoutSettingsId,
    payoutType,
    feeFrequency,
    payoutFrequency,
    displayPayoutRail,
    displayFeeRail,
    type,
    displayPayoutStartTime,
  ] = getMany(merchantPayoutSettings, [
    'id',
    'displayType',
    'displayFeeFrequency',
    'displayPayoutFrequency',
    'displayPayoutRail',
    'displayFeeRail',
    'type',
    'displayPayoutStartTime',
  ])

  const isManualSchedule = payoutFrequency === MANUAL_OPTION
  const isFeeManualSchedule = feeFrequency === MANUAL_OPTION
  const isTypeGross = type === GROSS

  let cutoffTime
  const batchTimeSetting = merchantReadyToSettleUpon === merchantFeeReadyToSettleUpon ? SETTLE_UPON_LABEL_MAPS[merchantReadyToSettleUpon] : undefined
  const payoutSetting = merchantReadyToSettleUpon !== merchantFeeReadyToSettleUpon ? merchantDisplayReadyToSettleUpon : undefined
  const feeSetting = merchantReadyToSettleUpon !== merchantFeeReadyToSettleUpon ? merchantDisplayFeeReadyToSettleUpon : undefined

  if (merchantReadyToSettleUpon === PROCESSOR_WINDOW && merchantFeeReadyToSettleUpon === PROCESSOR_WINDOW) {
    cutoffTime = (
      <div>
        <div>Card - 10:00 PM ET</div>
        <div>ACH - 8:00 PM ET</div>
      </div>
    )
  }

  if (merchantReadyToSettleUpon === CONFIGURABLE_WINDOW && merchantFeeReadyToSettleUpon === CONFIGURABLE_WINDOW) {
    cutoffTime = displayPayoutStartTime
  }

  const payoutPreferenceCardData = [
    {
      largeTitle: PAYOUT_BANK_ACCOUNT_TITLE,
      subtitle: PAYOUT_BANK_ACCOUNT_DESCRIPTION,
      idKey: ID,
      idValue: payoutPaymentInstrumentId,
      data: [
        {
          label: ROUTING_NUMBER,
          value: payoutPIBankCode,
          condition: !!payoutPIBankCode,
        },
        {
          label: INSTITUTION_NUMBER,
          value: payoutPIInstitutionNumber,
          condition: !!payoutPIInstitutionNumber,
        },
        {
          label: TRANSIT_NUMBER,
          value: payoutPITransitNumber,
          condition: !!payoutPITransitNumber,
        },
        {
          label: ACCOUNT_NUMBER,
          value: <Link className='text-link' to={payoutPIPath}>{payoutPIAccountNumber}</Link>,
        },
        {
          label: ACCOUNT_NAME,
          value: payoutPIName,
        },
      ],
    },
    {
      largeTitle: PAYOUT_SCHEDULE_LABEL,
      subtitle: PAYOUT_SCHEDULE_DESCRIPTION,
      data: [
        {
          label: PAYOUT_TYPE,
          value: payoutType,
        },
        {
          label: SETTLEMENT_SCHEDULE,
          value: payoutFrequency,
          tooltip: isManualSchedule ? MANUAL_SETTLEMENT_SCHEDULE_TOOLTIP_MESSAGE : '',
          tooltipPosition: 'top',
        },
        {
          label: TRANSFER_SPEED,
          value: displayPayoutRail,
        },
        {
          label: READY_TO_SETTLE_LABEL,
          value: merchantDisplayReadyToSettleUpon,
          condition: !isFlexCredential,
        },
      ],
    },
    {
      largeTitle: SETTLEMENT_BATCH_TIME,
      subtitle: SETTLEMENT_BATCH_TIME_SUBTITLE,
      data: [
        {
          label: BATCH_TIME_SETTING,
          value: batchTimeSetting,
          condition: !!batchTimeSetting,
        },
        {
          label: CUTOFF_TIME,
          value: cutoffTime,
          condition: !!cutoffTime,
        },
        {
          label: PAYOUT_SETTING,
          value: payoutSetting,
          condition: !!payoutSetting,
        },
        {
          label: FEE_SETTING,
          value: feeSetting,
          condition: !!feeSetting,
        },
      ],
    },
  ]

  const feePreferenceCardData = [
    {
      largeTitle: FEE_BANK_ACCOUNT_TITLE,
      subtitle: FEE_BANK_ACCOUNT_DESCRIPTION,
      idKey: ID,
      idValue: feePaymentInstrumentId,
      data: [
        {
          label: ROUTING_NUMBER,
          value: feePIBankCode,
        },
        {
          label: ACCOUNT_NUMBER,
          value: <Link className='text-link' to={feePIPath}>{feePIAccountNumber}</Link>,
        },
        {
          label: ACCOUNT_NAME,
          value: feePIName,
        },
      ],
    },
    {
      largeTitle: FEE_SCHEDULE,
      subtitle: FEE_SCHEDULE_DESCRIPTION,
      data: [
        {
          label: PAYOUT_TYPE,
          value: payoutType,
        },
        {
          label: SETTLEMENT_SCHEDULE,
          value: feeFrequency,
          tooltip: isFeeManualSchedule ? MANUAL_SETTLEMENT_SCHEDULE_TOOLTIP_MESSAGE : '',
          tooltipPosition: 'top',
        },
        {
          label: TRANSFER_SPEED,
          value: displayFeeRail,
        },
        {
          label: FEE_READY_TO_SETTLE_AT_LABEL,
          value: merchantDisplayFeeReadyToSettleUpon,
          condition: !isFlexCredential,
        },
      ],
    },
  ]

  return {
    merchantId,
    payoutPreferenceCardData,
    feePreferenceCardData,
    isFetching,
    isTypeGross,
    credentials,
    payoutSettingsId,
    credentialId,
    currentUserEmail,
    payoutSettings: merchantPayoutSettings,
    showPayoutPlan,
    payoutPlanId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantSettlements: ({ merchantId, credentials, queries, meta }) => dispatch(getMerchantSettlementsRequest({ merchantId, credentials, queries, meta })),
    showEditPayoutSettingsWarningModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_PAYOUT_SETTINGS_WARNING, modalProps })),
  }
}

class PayoutSettingsC extends Component {
  constructor(props) {
    super(props)

    this.state = { showWarning: false }
  }

  componentDidMount() {
    this.getAccruingSettlements()
  }

  componentDidUpdate(prevProps) {
    const { isFetching: prevIsFetching } = prevProps
    const { isFetching } = this.props

    if (prevIsFetching && !isFetching) {
      this.getAccruingSettlements()
    }
  }

  getAccruingSettlements = () => {
    const {
      getMerchantSettlements,
      merchantId,
      credentials,
    } = this.props

    getMerchantSettlements({
      merchantId,
      credentials,
      queries: {
        status: PENDING,
      },
      meta: {
        selectorId: merchantId,
        skipRelated: true,
        successCallback: ({ newValues }) => {
          if (!isEmpty(newValues)) {
            this.setState({ showWarning: true })
          } else {
            this.setState({ showWarning: false })
          }
        },
      },
    })
  }

  render() {
    const {
      showEditPayoutSettingsWarningModal,
      payoutSettingsId,
      credentialId,
    } = this.props

    const { showWarning } = this.state

    const payoutSettingsActions = [
      {
        label: EDIT,
        action: () => (showWarning ? showEditPayoutSettingsWarningModal({ credentialId, payoutSettingsId }) : {}),
        className: 'edit-merchant-payout-settings',
        link: showWarning ? '' : PAYOUT_SETTING_PATH({ credentialId, payoutSettingsId }),
      },
    ]

    const payoutProfileActions = [
      {
        label: EDIT,
        action: () => (showWarning ? showEditPayoutSettingsWarningModal({ credentialId, payoutSettingsId }) : {}),
        className: 'edit-merchant-payout-settings',
        link: showWarning ? '' : PAYOUT_SETTING_PATH({ credentialId, payoutSettingsId }),
      },
    ]

    return (
      <PayoutSettings
        payoutProfileActions={payoutProfileActions}
        payoutSettingsActions={payoutSettingsActions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutSettingsC)
