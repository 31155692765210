import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import formatPercentage from 'utilities/formatters/formatPercentage'

import {
  BLENDED,
  DISABLED,
  ENABLED,
  INTERCHANGE_PLUS,
} from 'constants/language/languageConstants'

const formatCardBrandDuesAssessments = (cardBrandValue, parentValue) => {
  if (cardBrandValue === undefined) {
    return parentValue ? ENABLED : DISABLED
  }
  return cardBrandValue ? ENABLED : DISABLED
}

const frontendFeeProfileModel = ({ data: feeProfile }) => {
  const [
    id,
    createdAt,
    updatedAt,
    achBasisPoints,
    achCreditReturnFixedFee,
    achDebitReturnFixedFee,
    achFixedFee,
    americanExpressAssessmentBasisPoints,
    americanExpressBasisPoints,
    americanExpressChargeInterchange,
    americanExpressExternallyFundedBasisPoints,
    americanExpressExternallyFundedFixedFee,
    americanExpressFixedFee,
    ancillaryFixedFeePrimary,
    ancillaryFixedFeeSecondary,
    applicationId,
    basisPoints,
    chargeInterchange,
    dinersClubBasisPoints,
    dinersClubChargeInterchange,
    dinersClubFixedFee,
    discoverAssessmentsBasisPoints,
    discoverBasisPoints,
    discoverChargeInterchange,
    discoverDataUsageFixedFee,
    discoverExternallyFundedBasisPoints,
    discoverExternallyFundedFixedFee,
    discoverFixedFee,
    discoverNetworkAuthorizationFixedFee,
    disputeFixedFee,
    disputeInquiryFixedFee,
    externallyFundedBasisPoints,
    externallyFundedFixedFee,
    fixedFee,
    jcbBasisPoints,
    jcbChargeInterchange,
    jcbFixedFee,
    mastercardAcquirerFeesBasisPoints,
    mastercardAssessmentsOver1kBasisPoints,
    mastercardAssessmentsUnder1kBasisPoints,
    mastercardBasisPoints,
    mastercardChargeInterchange,
    mastercardFixedFee,
    qualifiedTiers,
    roundingMode,
    tags,
    visaAcquirerProcessingFixedFee,
    visaAssessmentsBasisPoints,
    visaBaseIICreditVoucherFixedFee,
    visaBaseIISystemFileTransmissionFixedFee,
    visaBasisPoints,
    visaChargeInterchange,
    visaCreditVoucherFixedFee,
    visaFixedFee,
    visaKilobyteAccessFixedFee,
    feeProfileInheritanceId,
    feeProfileInheritanceName,
    feeProfileInheritanceType,
    cardCrossBorderBasisPoints,
    cardCrossBorderFixedFee,
    achBasisPointsFeeLimit,
    chargeDuesAssessments,
    visaChargeDuesAssessments,
    mastercardChargeDuesAssessments,
    americanExpressChargeDuesAssessments,
    discoverChargeDuesAssessments,
  ] = getMany(feeProfile, [
    'id',
    'created_at',
    'updated_at',
    'ach_basis_points',
    'ach_credit_return_fixed_fee',
    'ach_debit_return_fixed_fee',
    'ach_fixed_fee',
    'american_express_assessment_basis_points',
    'american_express_basis_points',
    'american_express_charge_interchange',
    'american_express_externally_funded_basis_points',
    'american_express_externally_funded_fixed_fee',
    'american_express_fixed_fee',
    'ancillary_fixed_fee_primary',
    'ancillary_fixed_fee_secondary',
    'application',
    'basis_points',
    'charge_interchange',
    'diners_club_basis_points',
    'diners_club_charge_interchange',
    'diners_club_fixed_fee',
    'discover_assessments_basis_points',
    'discover_basis_points',
    'discover_charge_interchange',
    'discover_data_usage_fixed_fee',
    'discover_externally_funded_basis_points',
    'discover_externally_funded_fixed_fee',
    'discover_fixed_fee',
    'discover_network_authorization_fixed_fee',
    'dispute_fixed_fee',
    'dispute_inquiry_fixed_fee',
    'externally_funded_basis_points',
    'externally_funded_fixed_fee',
    'fixed_fee',
    'jcb_basis_points',
    'jcb_charge_interchange',
    'jcb_fixed_fee',
    'mastercard_acquirer_fees_basis_points',
    'mastercard_assessments_over1k_basis_points',
    'mastercard_assessments_under1k_basis_points',
    'mastercard_basis_points',
    'mastercard_charge_interchange',
    'mastercard_fixed_fee',
    'qualified_tiers',
    'rounding_mode',
    'tags',
    'visa_acquirer_processing_fixed_fee',
    'visa_assessments_basis_points',
    'visa_base_II_credit_voucher_fixed_fee',
    'visa_base_II_system_file_transmission_fixed_fee',
    'visa_basis_points',
    'visa_charge_interchange',
    'visa_credit_voucher_fixed_fee',
    'visa_fixed_fee',
    'visa_kilobyte_access_fixed_fee',
    'fee_profile_inheritance_id',
    'fee_profile_inheritance_name',
    'fee_profile_inheritance_type',
    'card_cross_border_basis_points',
    'card_cross_border_fixed_fee',
    'ach_basis_points_fee_limit',
    'charge_dues_assessments',
    'visa_charge_dues_assessments',
    'mastercard_charge_dues_assessments',
    'american_express_charge_dues_assessments',
    'discover_charge_dues_assessments',
  ])

  return removeUndefined({
    id,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    achBasisPoints,
    displayAchBasisPoints: formatPercentage({ percentage: achBasisPoints }),
    achCreditReturnFixedFee,
    displayAchCreditReturnFixedFee: formatMoney({ amount: achCreditReturnFixedFee }),
    achDebitReturnFixedFee,
    displayAchDebitReturnFixedFee: formatMoney({ amount: achDebitReturnFixedFee }),
    achFixedFee,
    displayAchFixedFee: formatMoney({ amount: achFixedFee }),
    americanExpressAssessmentBasisPoints,
    displayAmericanExpressAssessmentBasisPoints: formatPercentage({ percentage: americanExpressAssessmentBasisPoints }),
    americanExpressBasisPoints,
    displayAmericanExpressBasisPoints: formatPercentage({ percentage: americanExpressBasisPoints }),
    americanExpressChargeInterchange,
    americanExpressExternallyFundedBasisPoints,
    americanExpressExternallyFundedFixedFee,
    americanExpressFixedFee,
    displayAmericanExpressFixedFee: formatMoney({ amount: americanExpressFixedFee }),
    ancillaryFixedFeePrimary,
    displayAncillaryFixedFeePrimary: formatMoney({ amount: ancillaryFixedFeePrimary }),
    ancillaryFixedFeeSecondary,
    displayAncillaryFixedFeeSecondary: formatMoney({ amount: ancillaryFixedFeeSecondary }),
    applicationId,
    basisPoints,
    displayBasisPoints: formatPercentage({ percentage: basisPoints }),
    chargeInterchange,
    feeProfileType: chargeInterchange ? INTERCHANGE_PLUS : BLENDED,
    dinersClubBasisPoints,
    displayDinersClubBasisPoints: formatPercentage({ percentage: dinersClubBasisPoints }),
    dinersClubChargeInterchange,
    dinersClubFixedFee,
    displayDinersClubFixedFee: formatMoney({ amount: dinersClubFixedFee }),
    discoverAssessmentsBasisPoints,
    displayDiscoverAssessmentsBasisPoints: formatPercentage({ percentage: discoverAssessmentsBasisPoints }),
    discoverBasisPoints,
    displayDiscoverBasisPoints: formatPercentage({ percentage: discoverBasisPoints }),
    discoverChargeInterchange,
    discoverDataUsageFixedFee,
    displayDiscoverDataUsageFixedFee: formatMoney({ amount: discoverDataUsageFixedFee }),
    discoverExternallyFundedBasisPoints,
    discoverExternallyFundedFixedFee,
    discoverFixedFee,
    displayDiscoverFixedFee: formatMoney({ amount: discoverFixedFee }),
    discoverNetworkAuthorizationFixedFee,
    displayDiscoverNetworkAuthorizationFixedFee: formatMoney({ amount: discoverNetworkAuthorizationFixedFee }),
    disputeFixedFee,
    displayDisputeFixedFee: formatMoney({ amount: disputeFixedFee }),
    disputeInquiryFixedFee,
    displayDisputeInquiryFixedFee: formatMoney({ amount: disputeInquiryFixedFee }),
    externallyFundedBasisPoints,
    externallyFundedFixedFee,
    fixedFee,
    displayFixedFee: formatMoney({ amount: fixedFee }),
    jcbBasisPoints,
    displayJcbBasisPoints: formatPercentage({ percentage: jcbBasisPoints }),
    jcbChargeInterchange,
    jcbFixedFee,
    displayJcbFixedFee: formatMoney({ amount: jcbFixedFee }),
    mastercardAcquirerFeesBasisPoints,
    displayMastercardAcquirerFeesBasisPoints: formatPercentage({ percentage: mastercardAcquirerFeesBasisPoints }),
    mastercardAssessmentsOver1kBasisPoints,
    displayMastercardAssessmentsOver1kBasisPoints: formatPercentage({ percentage: mastercardAssessmentsOver1kBasisPoints }),
    mastercardAssessmentsUnder1kBasisPoints,
    displayMastercardAssessmentsUnder1kBasisPoints: formatPercentage({ percentage: mastercardAssessmentsUnder1kBasisPoints }),
    mastercardBasisPoints,
    displayMastercardBasisPoints: formatPercentage({ percentage: mastercardBasisPoints }),
    mastercardChargeInterchange,
    mastercardFixedFee,
    displayMastercardFixedFee: formatMoney({ amount: mastercardFixedFee }),
    qualifiedTiers,
    roundingMode,
    tags,
    visaAcquirerProcessingFixedFee,
    displayVisaAcquirerProcessingFixedFee: formatMoney({ amount: visaAcquirerProcessingFixedFee }),
    visaAssessmentsBasisPoints,
    displayVisaAssessmentsBasisPoints: formatPercentage({ percentage: visaAssessmentsBasisPoints }),
    visaBaseIICreditVoucherFixedFee,
    visaBaseIISystemFileTransmissionFixedFee,
    displayVisaBaseIISystemFileTransmissionFixedFee: formatMoney({ amount: visaBaseIISystemFileTransmissionFixedFee }),
    visaBasisPoints,
    displayVisaBasisPoints: formatPercentage({ percentage: visaBasisPoints }),
    visaChargeInterchange,
    visaCreditVoucherFixedFee,
    displayVisaCreditVoucherFixedFee: formatMoney({ amount: visaCreditVoucherFixedFee }),
    visaFixedFee,
    displayVisaFixedFee: formatMoney({ amount: visaFixedFee }),
    visaKilobyteAccessFixedFee,
    displayVisaKilobyteAccessFixedFee: formatMoney({ amount: visaKilobyteAccessFixedFee }),
    feeProfileInheritanceId,
    feeProfileInheritanceName,
    feeProfileInheritanceType,
    cardCrossBorderBasisPoints,
    displayCardCrossBorderBasisPoints: formatPercentage({ percentage: cardCrossBorderBasisPoints }),
    cardCrossBorderFixedFee,
    displayCardCrossBorderFixedFee: formatMoney({ amount: cardCrossBorderFixedFee }),
    achBasisPointsFeeLimit,
    displayAchBasisPointsFeeLimit: formatMoney({ amount: achBasisPointsFeeLimit }),
    chargeDuesAssessments,
    displayChargeDuesAssessments: chargeDuesAssessments ? ENABLED : DISABLED,
    visaChargeDuesAssessments,
    displayVisaChargeDuesAssessments: formatCardBrandDuesAssessments(visaChargeDuesAssessments, chargeDuesAssessments),
    mastercardChargeDuesAssessments,
    displayMastercardChargeDuesAssessments: formatCardBrandDuesAssessments(mastercardChargeDuesAssessments, chargeDuesAssessments),
    americanExpressChargeDuesAssessments,
    displayAmericanExpressChargeDuesAssessments: formatCardBrandDuesAssessments(americanExpressChargeDuesAssessments, chargeDuesAssessments),
    discoverChargeDuesAssessments,
    displayDiscoverChargeDuesAssessments: formatCardBrandDuesAssessments(discoverChargeDuesAssessments, chargeDuesAssessments),
  })
}

export default frontendFeeProfileModel
