import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReviewAndSubmitForm from './ReviewAndSubmitForm'
import frontendFeeProfileRulesModel from 'utilities/create/models/frontendFeeProfileRulesModel'
import backendFeeProfileRulesModel from 'utilities/create/models/backendFeeProfileRulesModel'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const formValues = get(state, 'flowsR.formValues')
  const entityId = get(props, 'entityId')
  const cardValues = get(formValues, 'card')
  const achValues = get(formValues, 'ach')

  const backendValues = backendFeeProfileRulesModel({
    values: {
      blended: {
        USD: {
          ...get(cardValues, 'blended.USD', {}),
          ...get(achValues, 'USD', {}),
        },
        CAD: {
          ...get(cardValues, 'blended.CAD', {}),
          ...get(achValues, 'CAD', {}),
        },
      },
      'interchange-plus': {
        USD: {
          ...get(cardValues, 'interchange-plus.USD', {}),
          ...get(achValues, 'USD', {}),
        },
        CAD: {
          ...get(cardValues, 'interchange-plus.CAD', {}),
          ...get(achValues, 'CAD', {}),
        },
      },
    },
  })

  const feeProfileRulesFormValues = frontendFeeProfileRulesModel({ data: [{ ...backendValues, entity_id: entityId }] })

  return {
    feeProfileRulesFormValues,
    entityId,
  }
}

class ReviewAndSubmitFormC extends Component {
  render() {
    return (
      <ReviewAndSubmitForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(ReviewAndSubmitFormC)
