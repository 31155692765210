import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_NOTE_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_NOTE } from 'constants/amplitudeConstants'

const patchNoteRequest = ({
  id,
  tags,
  status,
  content,
  jsonContent,
  credentials,
  successCallback = () => {},
  flashNotification,
}) => createAction({
  type: PATCH_NOTE_F_REQUEST,
  id,
  values: {
    tags,
    status,
    content,
    jsonContent,
  },
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(UPDATE_NOTE, {
      credentials,
    }),
    successCallback,
    flashNotification,
  },
})

export default patchNoteRequest
