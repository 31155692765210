import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors/index'
import PaymentLinkConfigurationsForm from './PaymentLinkConfigurationsForm'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { FINIX_LOGO_WHITE } from 'constants/logoConstants'
import { PAYMENT_LINK_CONFIGURATION_FORM } from 'constants/formConstants'
import { FETCHING } from 'constants/reducerConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  reset,
  change,
  getFormValues,
} from 'redux-form'

import {
  FILE_UPLOAD_INSTRUCTIONS,
  FILE_UPLOAD_PAYMENT_LINK_BRANDING_DETAILS,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 10000000,
  accept: 'image/jpeg, image/png, image/jpg',
}

const instructions = [
  {
    name: 'main',
    message: FILE_UPLOAD_INSTRUCTIONS,
  },
  {
    name: 'sub',
    message: FILE_UPLOAD_PAYMENT_LINK_BRANDING_DETAILS,
  },
]

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => {
      dispatch(change(PAYMENT_LINK_CONFIGURATION_FORM, fieldName, color.hex))
    },
    resetForm: () => dispatch(reset(PAYMENT_LINK_CONFIGURATION_FORM)),
  }
}

const mapStateToProps = (state, props) => {
  const {
    entityType,
    entityId,
    isPayoutLink,
  } = props

  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const isLiveEnvironment = get(credentials, 'environment') === LIVE_ENVIRONMENT

  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const customSettings = entityType && entityId ? getDashboardConfigByEntityTypeAndId(state, entityType, entityId) : get(currentUser, 'customSettings')
  const initialSidebarBackgroundColor = get(customSettings, 'sidebarBackgroundColor', '#0D121A')
  const sidebarLogoImageUrl = get(customSettings, 'sidebarLogoImageUrl', FINIX_LOGO_WHITE)

  const isFetching = entityType && entityId ? isFetchingConfiguration : isFetchingCurrentUser
  const formValues = getFormValues(PAYMENT_LINK_CONFIGURATION_FORM)(state)
  const showLivePreview = get(formValues, 'showLivePreview')

  const [
    existingPaymentLinkHeaderColor = '#0D121A',
    existingPaymentLinkButtonBackgroundColor = '#0B5DBC',
    existingPaymentLinkButtonTextColor = '#FFFFFF',
  ] = getMany(customSettings, [
    'paymentLinkHeaderColor',
    'paymentLinkButtonBackgroundColor',
    'paymentLinkButtonTextColor',
  ])

  const headerColor = get(formValues, 'paymentLinkHeaderColor') || existingPaymentLinkHeaderColor || initialSidebarBackgroundColor
  const buttonBackgroundColor = get(formValues, 'paymentLinkButtonBackgroundColor') || existingPaymentLinkButtonBackgroundColor
  const buttonTextColor = get(formValues, 'paymentLinkButtonTextColor') || existingPaymentLinkButtonTextColor
  const paymentLinkLogo = get(customSettings, 'paymentLinkLogo') || sidebarLogoImageUrl

  const brandingInitialValues = {
    paymentLinkLogo,
    paymentLinkHeaderColor: headerColor,
    paymentLinkButtonBackgroundColor: buttonBackgroundColor,
    paymentLinkButtonTextColor: buttonTextColor,
  }

  const initialValues = merge({}, brandingInitialValues, customSettings)

  const previewPaymentLink = showLivePreview ? {
    logo: paymentLinkLogo,
    brandColor: headerColor,
    accentColor: buttonBackgroundColor,
    buttonFontColor: buttonTextColor,
    collectName: true,
  } : {
    logo: paymentLinkLogo,
    brandColor: existingPaymentLinkHeaderColor,
    accentColor: existingPaymentLinkButtonBackgroundColor,
    buttonFontColor: existingPaymentLinkButtonTextColor,
    collectName: true,
  }

  return removeUndefined({
    id: get(customSettings, 'id'),
    isFetching,
    credentials,
    customSettings,
    initialValues,
    headerColor,
    buttonBackgroundColor,
    buttonTextColor,
    isLiveEnvironment,
    isFetchingCurrentUser,
    config,
    instructions,
    initialSidebarBackgroundColor,
    previewPaymentLink,
    entityId,
    entityType,
    isPayoutLink,
  })
}

class PaymentLinkConfigurationsFormC extends Component {
  state = {
    displayHeaderColorPicker: false,
    displayButtonBackgroundColorPicker: false,
    displayButtonTextColorPicker: false,
  }

  componentDidMount() {
    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingCurrentUser: prevIsFetchingCurrentUser,
    } = prevProps

    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && prevIsFetchingCurrentUser && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  handleClick = (fieldName) => {
    const {
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    if (fieldName === 'displayHeaderColorPicker') {
      this.setState({ displayHeaderColorPicker: !displayHeaderColorPicker })
    }
    if (fieldName === 'displayButtonBackgroundColorPicker') {
      this.setState({ displayButtonBackgroundColorPicker: !displayButtonBackgroundColorPicker })
    }
    if (fieldName === 'displayButtonTextColorPicker') {
      this.setState({ displayButtonTextColorPicker: !displayButtonTextColorPicker })
    }
  }

  handleClose = (fieldName) => {
    this.setState({ [fieldName]: false })
  }

  render() {
    const { resetForm } = this.props

    const {
      displayHeaderColorPicker,
      displayButtonBackgroundColorPicker,
      displayButtonTextColorPicker,
    } = this.state

    return (
      <PaymentLinkConfigurationsForm
        {...this.props}
        getDashboardCustomization={this.getDashboardCustomization}
        displayHeaderColorPicker={displayHeaderColorPicker}
        displayButtonBackgroundColorPicker={displayButtonBackgroundColorPicker}
        displayButtonTextColorPicker={displayButtonTextColorPicker}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        resetForm={resetForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkConfigurationsFormC)
