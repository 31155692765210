import './SubscriptionBillingTabsS.scss'
import React from 'react'
import SubscriptionSchedulesC from 'components/Admin/Pages/SubscriptionBilling/SubscriptionSchedules/SubscriptionSchedulesC'
import SubscriptionFeesC from 'components/Admin/Pages/SubscriptionBilling/SubscriptionFees/SubscriptionFeesC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'
import { SUBSCRIPTION_BILLING_LEARN_MORE_LINK } from 'constants/urlConstants'

import {
  SUBSCRIPTION_BILLING_RESOURCE_TITLE,
  SUBSCRIPTION_BILLING_SUBTITLE_DESCRIPTION,
} from 'constants/language/languageConstants'

const tabs = [
  {
    id: 'subscription-schedules',
    name: 'Subscription Schedules',
    component: SubscriptionSchedulesC,
  },
  {
    id: 'subscription-fees',
    name: 'Subscription Fees',
    component: SubscriptionFeesC,
  },
]

const SubscriptionBillingTabs = () => {
  return (
    <div className='SubscriptionBillingTabs'>
      <HeaderV2C
        title={SUBSCRIPTION_BILLING_RESOURCE_TITLE}
        subTitle={SUBSCRIPTION_BILLING_SUBTITLE_DESCRIPTION}
        learnMoreLink={SUBSCRIPTION_BILLING_LEARN_MORE_LINK}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

export default SubscriptionBillingTabs
