import { GET_REVIEW_QUEUE_ITEM_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getReviewQueueItemRequest = ({
  reviewQueueId,
  credentials,
}) => createAction({
  type: GET_REVIEW_QUEUE_ITEM_F_REQUEST,
  id: reviewQueueId,
  credentials,
})

export default getReviewQueueItemRequest
