import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_REVIEW_RUNS } from 'constants/apiConstants'

const postUnderwritingReviewRunsAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: UNDERWRITING_REVIEW_RUNS({ reviewId: id }),
  })
}

export default postUnderwritingReviewRunsAPI
