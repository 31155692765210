const createRangeArray = ({ start = 0, end }) => {
  let idx = start
  const rangeArray = []

  if (!end) {
    return rangeArray
  }

  while (idx <= end) {
    rangeArray.push(idx)
    idx += 1
  }

  return rangeArray
}

export default createRangeArray
