import paymentsAPI from 'utilities/api/paymentsAPI'
import { PAYMENT_METHOD_CONFIGURATION } from 'constants/apiConstants'

const patchPaymentMethodConfigurationsAPI = ({ id, values, credentials }) => {
  const path = PAYMENT_METHOD_CONFIGURATION({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
  })
}

export default patchPaymentMethodConfigurationsAPI
