import './RejectUnderwritingEntityModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import RejectUnderwritingEntityFormC from 'components/Customer/Forms/RejectUnderwritingEntityForm/RejectUnderwritingEntityFormC'

import {
  REJECT_APPLICANT,
  REJECT_MERCHANT,
} from 'constants/language/languageConstants'

const RejectUnderwritingEntityModal = ({
  reviewId = '',
  subjectId = '',
  isMerchantUnderwriting,
}) => {
  return (
    <GenericModal
      title={isMerchantUnderwriting ? REJECT_MERCHANT : REJECT_APPLICANT}
      className='RejectUnderwritingEntityModal'
      Component={RejectUnderwritingEntityFormC}
      reviewId={reviewId}
      subjectId={subjectId}
      isMerchantUnderwriting={isMerchantUnderwriting}
    />
  )
}

RejectUnderwritingEntityModal.propTypes = {
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  isMerchantUnderwriting: PropTypes.bool,
}

export default RejectUnderwritingEntityModal
