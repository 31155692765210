import './MerchantUnderwritingReviewS.scss'
import PropTypes from 'prop-types'
import React from 'react'
import UnderwritingInformationC from 'components/Customer/Pages/UnderwritingV2/UnderwritingInformation/UnderwritingInformationC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import BankAccountVerificationReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BankAccountVerificationReport/BankAccountVerificationReportTabs'
import PlaidReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/PlaidReport/PlaidReportTabs'
import GovernmentIdReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/GovernmentIdReport/GovernmentIdReportTabs'
import SelfieCheckReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SelfieCheckReport/SelfieCheckReportTabs'
import UnderwritingOwnerPageSectionC from 'components/Customer/Pages/UnderwritingV2/UnderwritingOwnerPageSection/UnderwritingOwnerPageSectionC'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import IdentityFiles from 'components/Customer/Pages/IdentityFiles/IdentityFiles'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import AccordionGroup from 'components/Customer/Shared/Page/PageSection/AccordionGroup'
import { ALERT_ICON, CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import toUpper from 'lodash/toUpper'
import BusinessReportAccordions from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/ReportAccordions/BusinessReportAccordions'
import OwnerReportAccordions from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/ReportAccordions/OwnerReportAccordions'
import Loader from 'components/Shared/Loader/Loader'
import DataSectionV2 from 'components/Customer/Shared/Page/PageSection/DataSectionV2'
import getMany from 'utilities/get/getMany'
import formatUnderwritingReportTitle from 'utilities/formatters/formatUnderwritingReportTitle'
import { EDD_FILES } from 'constants/eddDocumentConstants'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import lowerCase from 'lodash/lowerCase'

import {
  ADVERSE_MEDIA_REVIEW,
  ONBOARDING_REVIEW,
  SANCTIONS_REVIEW,
} from 'constants/reviewQueueConstants'

import {
  t,
  ADDITIONAL_INFORMATION,
  BANK_ACCOUNT_INFORMATION,
  BANK_ACCOUNT_VERIFICATION,
  BENEFICIAL_OWNERSHIP,
  BUSINESS_INFORMATION,
  CARD_VOLUME,
  CONTROL_PERSON,
  PAYMENT_VOLUME_BY_BUSINESS_TYPE,
  PROCESSING_INFORMATION,
  RELATED_FILES,
  GENERAL_INFORMATION,
  REPORT_FAILED_TO_RUN,
  FILE_NAME,
  TYPE,
  UPLOADED_ON,
  FILE_STATUS,
  FILE_ID,
  FILE_TYPE,
  MERCHANT_IN_HIGH_RISK_VERTICAL_EDD_FILES_REQUIRED,
} from 'constants/language/languageConstants'

const MerchantUnderwritingReview = ({
  isFetching,
  reviewId = '',
  subjectId = '',
  entityId = '',
  businessInformationDataSection = [],
  businessInformationSubTitle = '',
  businessReports = {},
  processingInformationDataSection = [],
  paymentVolumeDataSection = [],
  cardVolumeDataSection = [],
  bankAccountInformationDataSection = [],
  bankAccountReport = {},
  additionalInformationDataSection = [],
  identityFilesSelector,
  identityMerchants,
  primaryOwner = [],
  beneficialOwners = [],
  isStandaloneUnderwriting = false,
  bankAccountReportVersion,
  selfieReport,
  governmentIdReport,
  isPastRunView = false,
  reviewType = '',
  groupedEDDFiles = [],
  showFileViewer = () => {},
}) => {
  const showSummary = reviewType === SANCTIONS_REVIEW || reviewType === ADVERSE_MEDIA_REVIEW
  const showAdditionalInformation = reviewType === ONBOARDING_REVIEW
  const hasEDDFiles = !isEmpty(groupedEDDFiles)

  const [
    businessIdentityReport,
    businessSanctionsReportV1,
    businessSanctionsReportV2,
    businessAdverseMediaReport,
    businessMatchReport,
  ] = getMany(businessReports, [
    'businesses',
    'sanctions',
    'business_sanctions',
    'adverse_media',
    'matches',
  ])

  return (
    <div className='MerchantUnderwritingReview'>
      {hasEDDFiles && (
        <div className='high-risk-document-warning-container'>
          <div className='high-risk-document-warning'>
            <i className={`fa fa-${ALERT_ICON}`} />
            <span className='p-1'>{t(MERCHANT_IN_HIGH_RISK_VERTICAL_EDD_FILES_REQUIRED)}</span>
          </div>
        </div>
      )}

      <UnderwritingInformationC
        reviewId={reviewId}
        subjectId={subjectId}
        entityId={entityId}
        isMerchantUnderwriting
        isPastRunView={isPastRunView}
        showSummaryVersion={showSummary}
      />

      <PageSectionV2C
        title={BUSINESS_INFORMATION}
        subTitle={businessInformationSubTitle}
        className='business-information-section'
        isFetching={isFetching}
      >
        <ul className='flex actions wrap data-list'>
          { map(businessInformationDataSection, ({
            data,
            type,
          }, index) => {
            return (
              <DataSectionV2
                data={data}
                type={type}
                title={kebabCase(`${BUSINESS_INFORMATION}-${index}`)}
                key={kebabCase(`${BUSINESS_INFORMATION}-${index}`)}
              />
            )
          })}
        </ul>
        {isFetching && <Loader />}
        {!isFetching && (
          <BusinessReportAccordions
            businessIdentityReport={businessIdentityReport}
            sanctionsReportV1={businessSanctionsReportV1}
            sanctionsReportV2={businessSanctionsReportV2}
            adverseMediaReport={businessAdverseMediaReport}
            matchReport={businessMatchReport}
            isStandaloneUnderwriting={isStandaloneUnderwriting}
            reviewType={reviewType}
          />
        )}
      </PageSectionV2C>

      {!isEmpty(primaryOwner) && (
        <PageSectionV2C
          title={CONTROL_PERSON}
          isFetching={isFetching}
        >
            {!isEmpty(primaryOwner) && map(primaryOwner, (owner, key) => {
              const [
                ownerIdentityReport,
                ownerSanctionsReportV1,
                ownerSanctionsReportV2,
                ownerAdverseMediaReport,
                ownerEmailReport,
                ownerSocialMediaReport,
                ownerReversePhoneLookupReport,
              ] = getMany(owner, [
                'reports.owners',
                'reports.sanctions',
                'reports.person_sanctions',
                'reports.adverse_media',
                'reports.emails',
                'reports.social_media',
                'reports.phones',
              ])
              return (
                <div key={`primary-owner-${key}`}>
                  <UnderwritingOwnerPageSectionC owner={owner} subjectId={subjectId} />
                  <OwnerReportAccordions
                    ownerIdentityReport={ownerIdentityReport}
                    sanctionsReportV1={ownerSanctionsReportV1}
                    sanctionsReportV2={ownerSanctionsReportV2}
                    adverseMediaReport={ownerAdverseMediaReport}
                    socialMediaReport={ownerSocialMediaReport}
                    reversePhoneLookupReport={ownerReversePhoneLookupReport}
                    emailReport={ownerEmailReport}
                    isStandaloneUnderwriting={isStandaloneUnderwriting}
                    reviewType={reviewType}
                  />
                </div>
              )
            })}
        </PageSectionV2C>
      )}

      {!isEmpty(beneficialOwners) && (
        <PageSectionV2C
          title={BENEFICIAL_OWNERSHIP}
          isFetching={isFetching}
        >
          {map(beneficialOwners, (owner, key) => {
            const [
              ownerIdentityReport,
              ownerSanctionsReportV1,
              ownerSanctionsReportV2,
              ownerAdverseMediaReport,
              ownerEmailReport,
              ownerSocialMediaReport,
              ownerReversePhoneLookupReport,
            ] = getMany(owner, [
              'reports.owners',
              'reports.sanctions',
              'reports.person_sanctions',
              'reports.adverse_media',
              'reports.emails',
              'reports.social_media',
              'reports.phones',
            ])

            return (
              <div key={`beneficial-owner-${key}`}>
                <UnderwritingOwnerPageSectionC owner={owner} subjectId={subjectId} />
                <OwnerReportAccordions
                  ownerIdentityReport={ownerIdentityReport}
                  sanctionsReportV1={ownerSanctionsReportV1}
                  sanctionsReportV2={ownerSanctionsReportV2}
                  adverseMediaReport={ownerAdverseMediaReport}
                  socialMediaReport={ownerSocialMediaReport}
                  reversePhoneLookupReport={ownerReversePhoneLookupReport}
                  emailReport={ownerEmailReport}
                  isStandaloneUnderwriting={isStandaloneUnderwriting}
                  reviewType={reviewType}
                />
              </div>
            )
          })}
        </PageSectionV2C>
      )}

      {showAdditionalInformation && (
      <>
        <PageSectionV2C
          title={BANK_ACCOUNT_INFORMATION}
          columns={bankAccountInformationDataSection}
          isFetching={isFetching}
          className='bank-account-information-section'
        >
          {isFetching && <Loader />}
          {!isFetching && (
          <Accordion
            title={formatUnderwritingReportTitle(bankAccountReport, BANK_ACCOUNT_VERIFICATION)}
            description={`${get(bankAccountReport, 'normalized_response')}: ${lowerCase(get(bankAccountReport, 'normalized_message'))}`}
            isDisabled={isEmpty(bankAccountReport)}
            isDisabledMessage={REPORT_FAILED_TO_RUN}
          >
            { isEqual(bankAccountReportVersion, 'V2') ?
              <PlaidReportTabs report={bankAccountReport} /> :
              <BankAccountVerificationReportTabs report={bankAccountReport} />
                    }
          </Accordion>
          )}
        </PageSectionV2C>

        { (selfieReport || governmentIdReport) && (
        <PageSectionV2C title='Supported Evidence'>
          <h5>Biometric Data Checks</h5>
          <span className='p-1 secondary'>Personal identity verification data from the person who submitted the application.</span>

          <AccordionGroup classNames='mt-lg'>
            <Accordion
              title={formatUnderwritingReportTitle(governmentIdReport, 'Government-ID Check')}
              description={get(governmentIdReport, 'normalized_message') || ''}
              isDisabled={isEmpty(governmentIdReport)}
              isDisabledMessage={REPORT_FAILED_TO_RUN}
            >
              <GovernmentIdReportTabs report={governmentIdReport} />
            </Accordion>
            <Accordion
              title={formatUnderwritingReportTitle(selfieReport, 'Selfie Check')}
              description={get(selfieReport, 'normalized_message') || ''}
              isDisabled={isEmpty(selfieReport)}
              isDisabledMessage={REPORT_FAILED_TO_RUN}
            >
              <SelfieCheckReportTabs report={selfieReport} />
            </Accordion>
          </AccordionGroup>
        </PageSectionV2C>
        )}

        <IdentityFiles
          identityId={entityId}
          showUploadButton={!isPastRunView}
          title={RELATED_FILES}
          urlQueriesToIgnore={['subjectId', 'entityType', 'entityId']}
          selector={identityFilesSelector}
          identityMerchants={identityMerchants}
        />

        {hasEDDFiles && (
          <PageSectionV2C title='EDD Documents'>
            <div className='edd-table'>
              <div className='header tac'>{t(FILE_ID)}</div>
              <div className='header'>
                <div>{t(UPLOADED_ON)}</div>
                <div className='p-3 secondary'>Time (PST)</div>
              </div>
              <div className='header'>{t(FILE_NAME)}</div>
              <div className='header'>{t(FILE_STATUS)}</div>
              <div className='header'>{t(FILE_TYPE)}</div>

              {map(groupedEDDFiles, (files, fileType) => (
                <>
                  <div className='sub-header'>{get(EDD_FILES, [fileType, 'name'])}</div>
                  {map(files, ({ id, display_name: fileName, type, fileData }) => {
                    const displayCreatedAt = get(fileData, 'displayCreatedAt')
                    const displayStatus = get(fileData, 'displayStatus', '')
                    const extension = toUpper(get(fileData, 'extension', ''))
                    const [date, time, ampm] = displayCreatedAt ? displayCreatedAt.split(' ') : []

                    const showFile = () => showFileViewer({
                      id,
                      displayType: fileType,
                      displayName: fileName,
                      displayCreatedAt,
                    })

                    return (
                      <>
                        <div className='tac'><ClickToCopyC position='right' content={id} /></div>
                        <div>
                          <div>{date}</div>
                          <div className='p-3 secondary'>{time} {ampm}</div>
                        </div>
                        <div className='link-1' onClick={showFile}>{fileName}</div>
                        <div><i className={`fa fa-${CHECK_CIRCLE_ICON}`} />{displayStatus}</div>
                        <div>{extension}</div>
                      </>
                    )
                  })}
                </>
              ))}
            </div>
          </PageSectionV2C>
        )}

        <PageSectionV2C
          title={PROCESSING_INFORMATION}
          isFetching={isFetching}
        >
          <PageSectionV2C
            columns={processingInformationDataSection}
            smallTitle={GENERAL_INFORMATION}
            className='general-information-section'
          />
          <PageSectionV2C
            columns={cardVolumeDataSection}
            smallTitle={CARD_VOLUME}
            className='card-volume-section'
          />
          <PageSectionV2C
            columns={paymentVolumeDataSection}
            smallTitle={PAYMENT_VOLUME_BY_BUSINESS_TYPE}
            className='payment-volume-section'
          />
        </PageSectionV2C>

        <PageSectionV2C
          title={ADDITIONAL_INFORMATION}
          columns={additionalInformationDataSection}
          isFetching={isFetching}
        />
      </>
      )}
    </div>
  )
}

MerchantUnderwritingReview.propTypes = {
  isFetching: PropTypes.bool,
  reviewId: PropTypes.string,
  subjectId: PropTypes.string,
  entityId: PropTypes.string,
  businessInformationDataSection: PropTypes.array,
  businessReports: PropTypes.object,
  processingInformationDataSection: PropTypes.array,
  paymentVolumeDataSection: PropTypes.array,
  cardVolumeDataSection: PropTypes.array,
  bankAccountInformationDataSection: PropTypes.array,
  bankAccountReport: PropTypes.object,
  additionalInformationDataSection: PropTypes.array,
  identityMerchants: PropTypes.array,
  primaryOwner: PropTypes.array,
  beneficialOwners: PropTypes.array,
  isStandaloneUnderwriting: PropTypes.bool,
  bankAccountReportVersion: PropTypes.string,
  selfieReport: PropTypes.object,
  governmentIdReport: PropTypes.object,
  isPastRunView: PropTypes.bool,
  reviewType: PropTypes.string,
  groupedEDDFiles: PropTypes.array,
  showFileViewer: PropTypes.func,
}

export default MerchantUnderwritingReview
