import './EditPaymentInstrumentModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import EditPaymentInstrumentFormC from 'components/Customer/Forms/EditPaymentInstrumentForm/EditPaymentInstrumentFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const EditPaymentInstrumentModal = ({
  paymentInstrument,
  actionType,
  credentials,
}) => {
  return (
    <GenericModal
      title='Update Payment Instrument'
      className='EditPaymentInstrumentModal'
      Component={EditPaymentInstrumentFormC}
      paymentInstrument={paymentInstrument}
      actionType={actionType}
      credentials={credentials}
    />
  )
}

EditPaymentInstrumentModal.propTypes = {
  paymentInstrument: PropTypes.object,
  actionType: PropTypes.string,
  credentials: PropTypes.object,
}

export default EditPaymentInstrumentModal
