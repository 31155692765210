import { formValueSelector } from 'redux-form'
import { getState } from 'state-layer/configureStore'

const getCheckedStatus = (name, form) => {
  const state = getState()
  const formSelector = formValueSelector(form)

  return formSelector(state, name)
}

export default getCheckedStatus
