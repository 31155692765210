import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendUnderwritingDecisionSettings = ({ data: settings }) => {
  const [
    actOnDecisions,
    calculateDecisions,
    calculateRiskScores,
  ] = getMany(settings, [
    'act_on_decisions',
    'calculate_decisions',
    'calculate_risk_scores',
  ])

  return removeUndefined({
    actOnDecisions,
    calculateDecisions,
    calculateRiskScores,
  })
}

export default frontendUnderwritingDecisionSettings
