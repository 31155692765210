import React, { Component } from 'react'
import { connect } from 'react-redux'
import ACHReturn from './ACHReturn'
import { getTransferWithRelatedSelector } from 'state-layer/selectors'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import showModalAction from 'utilities/actions/showModalAction'
import formatAmountHeaderTitle from 'utilities/formatters/formatAmountHeaderTitle'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import getMany from 'utilities/get/getMany'
import { isPayoutFeature } from 'utilities/validate/checkCredentialFeatures'
import { getCardBrand } from 'constants/bankConstants'
import { FETCHING } from 'constants/reducerConstants'
import { PAYMENT_CARD } from 'constants/paymentInstrumentConstants'
import { BANK_ICON } from 'constants/iconConstants'
import { APPROVED } from 'constants/settlementConstants'
import { REMOVE_SETTLEMENT_ENTRIES } from 'constants/modalConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  ROLE_PLATFORM,
  NON_MERCHANT_ROLES,
} from 'constants/roleConstants'

import {
  ACH_RETURN_RESOURCE_TITLE,
  ACH_RETURNS_RESOURCE_TITLE,
  APPLICATION_RESOURCE_TITLE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  TRACE_ID,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  REMOVE_FROM_SETTLEMENT,
  PARENT_PAYMENT,
  SETTLEMENT_RESOURCE_TITLE,
  CREATED_ON,
  SETTLEMENT_CLOSED_ON,
} from 'constants/language/languageConstants'

import {
  PAYMENTS_PATH,
  APPLICATION_PATH,
  IDENTITY_PATH,
  ACH_RETURNS_PATH,
  PAYMENT_PATH,
  SETTLEMENT_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `transfersR.${FETCHING}`)
  const transferId = get(props, 'params.transferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const paymentPath = PAYMENTS_PATH({ credentialId })

  const [
    fee = '',
    type = '',
    traceId = '',
    currency = '',
    transferState = '',
    readyToSettleAt = '',
    statementDescriptor = '',
    displayAmount = '',
    displayAmountWithCurrencyCode = '',
    displayCreatedAt = '',
    paymentInstrument = {},
    tags,
    reversals,
    messages,
    raw,
    subType,
    settlement,
    settlementEntry,
    disputes,
    reversalsDisputes,
    shouldFund,
    externallyFunded,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    failureCode,
    failureMessage,
    displayFailureCode,
    parentId,
    buyerIdentity,
  ] = getMany(transfer, [
    'fee',
    'type',
    'traceId',
    'currency',
    'state',
    'readyToSettleAt',
    'statementDescriptor',
    'displayAmount',
    'displayAmountWithCurrencyCode',
    'displayCreatedAt',
    'paymentInstrument',
    'tags',
    'reversals',
    'messages',
    'raw',
    'subtype',
    'settlement',
    'settlementEntry',
    'disputes',
    'reversalsDisputes',
    'settlementEntry.shouldFund',
    'externallyFunded',
    'applicationId',
    'application.name',
    'merchantIdentityId',
    'merchantIdentity.entity.business_name',
    'merchantIdentity.entity.doing_business_as',
    'failureCode',
    'failureMessage',
    'displayFailureCode',
    'parentId',
    'buyerIdentity',
  ])

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })

  const transactionType = get(paymentInstrument, 'type')
  const isPayout = isPayoutFeature({ credentials })
  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const [
    settlementId,
    settlementStatus,
    settlementException,
    displayAutoCloseTime,
  ] = getMany(settlement, [
    'id',
    'status',
    'exception',
    'displayAutoCloseTime',
  ])

  const settlementEntryId = get(settlementEntry, 'id')
  const enableRemoveFromSettlement = settlementEntryId && settlementStatus && settlementStatus !== APPROVED && !settlementException
  const showAction = !isEmpty(paymentInstrument) && includes(NON_MERCHANT_ROLES, role) && enableRemoveFromSettlement
  const parentPaymentPath = PAYMENT_PATH({ credentialId, transferId: parentId })

  const [
    paymentInstrumentId,
    maskedAccountNumber,
    maskedFullCardNumber,
    paymentInstrumentBuyerName,
  ] = getMany(paymentInstrument, [
    'id',
    'maskedAccountNumber',
    'maskedFullCardNumber',
    'name',
  ])

  const contextBarData = {
    back: {
      label: ACH_RETURNS_RESOURCE_TITLE,
      path: ACH_RETURNS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantBusinessName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: SETTLEMENT_PATH({ credentialId, settlementId }),
      },
    ],
  }

  const reasonCode = get(tags, 'result_message_0', '')
  const reasonDecision = get(tags, 'result_message_1', '')
  const reasonCodeValue = last(reasonCode.split(':'))
  const reasonDecisionValue = last(reasonDecision.split(':'))

  const headerData = {
    resource: {
      label: ACH_RETURN_RESOURCE_TITLE,
      id: transferId,
      additionalIds: [
        {
          label: TRACE_ID,
          id: traceId,
        },
      ],
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: PARENT_PAYMENT,
        value: displayAmountWithCurrencyCode,
        path: parentPaymentPath,
      },
      {
        label: SETTLEMENT_CLOSED_ON,
        value: displayAutoCloseTime,
      },
      {
        label: BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: transactionType,
          credentialId,
          paymentInstrument,
          paymentInstrumentId,
          maskedAccountNumber,
          maskedFullCardNumber,
          paymentInstrumentBuyerName,
        }),
        condition: !!paymentInstrumentId,
      },
    ],
  }

  return {
    isFetching,
    transferId,
    credentials,
    transfer,
    paymentInstrument,
    tagsSectionData,
    messages,
    raw,
    paymentPath,
    isPayout,
    displayAmount,
    showAction,
    settlementId,
    settlementEntryId,
    headerData,
    contextBarData,
    reasonCodeValue,
    reasonDecisionValue,
    buyerIdentity,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfer: ({ credentials, transferId }) => { dispatch(getTransferRequest({ credentials, transferId })) },
    showRemoveFromSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: REMOVE_SETTLEMENT_ENTRIES, modalProps })),
  }
}

class ACHReturnC extends Component {
  componentDidMount() {
    this.fetchTransfer()
  }

  componentDidUpdate(prevProps) {
    const { transferId } = this.props
    const { transferId: prevTransferId } = prevProps

    if (transferId !== prevTransferId) {
      this.fetchTransfer()
    }
  }

  fetchTransfer = () => {
    const {
      transferId,
      credentials,
      getTransfer,
    } = this.props

    getTransfer({ credentials, transferId })
  }

  render() {
    const {
      transferId,
      displayAmountWithCurrencyCode,
      showAction,
      showRemoveFromSettlementModal,
      settlementId,
      settlementEntryId,
    } = this.props

    const actions = showAction ? [
      {
        label: REMOVE_FROM_SETTLEMENT,
        action: () => {
          showRemoveFromSettlementModal({
            settlementId,
            settlementEntryIds: [settlementEntryId],
            entriesData: [{ id: transferId, amount: displayAmountWithCurrencyCode }],
          })
        },
        className: 'remove-from-settlement-button',
      },
    ] : []

    return (
      <ACHReturn
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ACHReturnC)
