import isString from 'lodash/isString'
import isArray from 'lodash/isArray'

const convertToString = (value) => {
  if (isString(value)) {
    return value
  }

  // TODO: in the future work with Matty to find out a better way to handle more complex arrays (i.e. array of objects)
  if (isArray(value)) {
    return `[${value}]`
  }

  return JSON.stringify(value)
}

export default convertToString
