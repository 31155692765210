import createAction from 'utilities/actions/createAction'
import { POST_ENTITY_CONFIGURATION_F_REQUEST } from 'constants/flowConstants'

const postEntityConfigurationRequest = ({
  linkedTo,
  linkedType,
  mfaEnabled,
}) => createAction({
  type: POST_ENTITY_CONFIGURATION_F_REQUEST,
  values: {
    linkedTo,
    linkedType,
    mfaEnabled,
  },
})

export default postEntityConfigurationRequest
