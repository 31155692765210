import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_APPLICATION_PROCESSORS_F_REQUEST } from 'constants/flowConstants'
import { ADD_PROCESSORS } from 'constants/amplitudeConstants'

const submitAddProcessorsForm = (values, dispatch, props) => {
  const {
    id,
    credentials,
    initialValues,
  } = props

  const addedProcessors = reduce(values, (processorsArray, value, processorName) => {
    if (value && value !== get(initialValues, processorName)) {
      processorsArray.push(processorName)
    }

    return processorsArray
  }, [])

  dispatch({
    type: POST_APPLICATION_PROCESSORS_F_REQUEST,
    payload: {
      id,
      credentials,
      values: { processors: addedProcessors },
    },
    meta: {
      selectorId: id,
      actionId: sendAmplitudeActionEvent(ADD_PROCESSORS, {
        credentials,
        applicationId: id,
        processors: addedProcessors,
      }),
    },
  })
}

export default submitAddProcessorsForm
