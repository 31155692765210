const formatEIN = (value) => {
  if (!value) {
    return ''
  }

  const onlyNums = value.replace(/[^\d*]/g, '')

  if (onlyNums.length <= 2) return onlyNums
  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 9)}`
}

export default formatEIN
