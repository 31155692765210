import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_SERVICE_ONBOARDING_FORM_UNDERWRITING_PROFILES } from 'constants/apiConstants'

const getGuestOnboardingFormUnderwritingProfileAPI = ({ id }) => {
  return guestDashboardAPI.get({
    path: DASHBOARD_SERVICE_ONBOARDING_FORM_UNDERWRITING_PROFILES({ id }),
  })
}

export default getGuestOnboardingFormUnderwritingProfileAPI
