import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditMerchantOnboardingFormSettingsForm from './EditMerchantOnboardingFormSettingsForm'
import { BIOMETRIC_COLLECTION_TYPE_LABELS } from 'constants/underwritingProfileConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const biometricDataCollection = get(props, 'underwritingProfile.biometricDataCollection')

  return {
    initialValues: {
      biometricDataCollection: {
        value: biometricDataCollection,
        label: BIOMETRIC_COLLECTION_TYPE_LABELS[biometricDataCollection],
      },
    },
  }
}

class EditMerchantOnboardingFormSettingsFormC extends Component {
  render() {
    return (
      <EditMerchantOnboardingFormSettingsForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(EditMerchantOnboardingFormSettingsFormC)
