import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_CUSTOMIZATION } from 'constants/apiConstants'

const getDashboardCustomizationAPI = ({ id, meta, queries }) => {
  return dashboardAPI.get({
    path: DASHBOARD_SERVICE_CUSTOMIZATION({ id }),
    meta,
    queries,
  })
}

export default getDashboardCustomizationAPI
