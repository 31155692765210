import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import isNil from 'lodash/isNil'

import {
  DISABLED,
  ENABLED,
} from 'constants/language/languageConstants'

const frontendApplicationModel = ({ data: application }) => {
  const [
    id,
    identityId,
    businessName,
    createdAt,
    processingEnabled,
    settlementsEnabled,
    applicationProfile,
    authorizations,
    disputes,
    identities,
    merchants,
    ownerIdentity,
    paymentInstruments,
    processors,
    settlements,
    tokens,
    transfers,
    users,
    webHooks,
    tags,
    feeProfile,
    riskProfile,
    creatingTransferEnabled,
    cardExpirationDateRequired,
    cardCvvRequired,
    payoutPlan,
    settlementFundingIdentifier,
    underwritingDecisionSettings,
    associatedIdentities,
    disbursementsAchPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsCardPushEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
    settlementApprovalEnabled,
  ] = getMany(application, [
    'id',
    'owner',
    'name',
    'created_at',
    'processing_enabled',
    'settlement_enabled',
    'applicationProfile',
    'authorizations',
    'disputes',
    'identities',
    'merchants',
    'ownerIdentity',
    'paymentInstruments',
    'processors',
    'settlements',
    'tokens',
    'transfers',
    'users',
    'webHooks',
    'tags',
    'feeProfile',
    'riskProfile',
    'creating_transfer_from_report_enabled',
    'card_expiration_date_required',
    'card_cvv_required',
    'payoutPlan',
    'settlement_funding_identifier',
    'underwritingDecisionSettings',
    'associatedIdentities',
    'disbursements_ach_pull_enabled',
    'disbursements_ach_push_enabled',
    'disbursements_card_pull_enabled',
    'disbursements_card_push_enabled',
    'disbursements_same_day_ach_push_enabled',
    'disbursements_same_day_ach_pull_enabled',
    'settlement_approval_enabled',
  ])

  const processingLabel = processingEnabled ? ENABLED : DISABLED
  const settlementsEnabledLabel = settlementsEnabled ? ENABLED : DISABLED

  return removeUndefined({
    id,
    identityId,
    businessName,
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    processingEnabled,
    settlementsEnabled,
    processing: !isNil(processingEnabled) ? processingLabel : undefined,
    settlementsEnabledLabel: !isNil(settlementsEnabled) ? settlementsEnabledLabel : undefined,
    applicationProfile,
    authorizations,
    disputes,
    identities,
    merchants,
    ownerIdentity,
    paymentInstruments,
    processors,
    settlements,
    tokens,
    transfers,
    users,
    webHooks,
    tags,
    riskProfile,
    creatingTransferEnabled,
    cardExpirationDateRequired,
    cardCvvRequired,
    payoutPlan,
    settlementFundingIdentifier,
    underwritingDecisionSettings,
    associatedIdentities,
    disbursementsAchPullEnabled,
    disbursementsAchPushEnabled,
    disbursementsCardPullEnabled,
    disbursementsCardPushEnabled,
    disbursementsSameDayAchPushEnabled,
    disbursementsSameDayAchPullEnabled,
    settlementApprovalEnabled,
  })
}

export default frontendApplicationModel
