import './BankAccountVerificationReportS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  ACCOUNT_DETAILS,
  VERIFICATION_DETAILS,
} from 'constants/language/languageConstants'

const BankAccountVerificationReport = ({
  verificationDetailsData = [],
  accountDetailsData = [],
}) => {
  return (
    <div className='BankAccountVerificationReport'>
      <PageSectionV2C
        smallTitle={VERIFICATION_DETAILS}
        className='verification-details-data-section'
        columns={verificationDetailsData}
      />

      <PageSectionV2C
        smallTitle={ACCOUNT_DETAILS}
        className='account-details-data-section'
        columns={accountDetailsData}
      />
    </div>
  )
}

BankAccountVerificationReport.propTypes = {
  verificationDetailsData: PropTypes.array,
  accountDetailsData: PropTypes.array,
}

export default BankAccountVerificationReport
