import dashboardAPI from 'utilities/api/dashboardAPI'

import { FILES } from 'constants/apiConstants'

const postFileAPI = ({ values, meta }) => {
  return dashboardAPI.post({
    meta,
    path: FILES,
    values,
  })
}

export default postFileAPI
