import getMany from 'utilities/get/getMany'
import toString from 'lodash/toString'
import isEqual from 'lodash/isEqual'

import {
  fieldEmpty,
  nonEmptyPositiveAmount,
  validateMaxAmount,
} from 'utilities/validate'

import {
  ACH,
  APPLICATION,
  START_TIME,
  DAILY_CARD_COUNT,
  DAILY_CARD_VOLUME,
  DAILY_ACH_COUNT,
  DAILY_ACH_VOLUME,
  MONTHLY_START_DATE,
  MONTHLY_CARD_COUNT,
  MONTHLY_CARD_VOLUME,
  MONTHLY_ACH_COUNT,
  MONTHLY_ACH_VOLUME,
  MAX_TRANSACTION_AMOUNT,
} from 'constants/language/languageConstants'

import {
  MONTHLY_APPLICATION_CARD_MAX_COUNT,
  DAILY_APPLICATION_CARD_MAX_COUNT,
  MONTHLY_RECIPIENT_CARD_MAX_COUNT,
  DAILY_RECIPIENT_CARD_MAX_COUNT,
  MONTHLY_APPLICATION_CARD_MAX_VOLUME,
  DAILY_APPLICATION_CARD_MAX_VOLUME,
  MONTHLY_RECIPIENT_CARD_MAX_VOLUME,
  DAILY_RECIPIENT_CARD_MAX_VOLUME,
  MONTHLY_APPLICATION_ACH_MAX_COUNT,
  DAILY_APPLICATION_ACH_MAX_COUNT,
  MONTHLY_RECIPIENT_ACH_MAX_COUNT,
  DAILY_RECIPIENT_ACH_MAX_COUNT,
  MONTHLY_APPLICATION_ACH_MAX_VOLUME,
  DAILY_APPLICATION_ACH_MAX_VOLUME,
  MONTHLY_RECIPIENT_ACH_MAX_VOLUME,
  DAILY_RECIPIENT_ACH_MAX_VOLUME,
} from 'constants/velocityLimitsConstants'

const validateUpdateLimitsForm = (values, props) => {
  const [
    entityType,
    limitType,
  ] = getMany(props, [
    'entityType',
    'limitType',
  ])

  const applicationType = isEqual(entityType, APPLICATION)
  const achLimitType = isEqual(limitType, ACH)

  const [
    dailyStartTime,
    dailyCount,
    dailyVolume,
    monthlyDate,
    monthlyStartTime,
    monthlyCount,
    monthlyVolume,
    maxTransactionAmount,
  ] = getMany(values, [
    'dailyStartTime.value',
    'dailyCount',
    'dailyVolume',
    'monthlyDate.value',
    'monthlyStartTime.value',
    'monthlyCount',
    'monthlyVolume',
    'maxTransactionAmount',
  ])

  const dailyCountName = achLimitType ? DAILY_ACH_COUNT : DAILY_CARD_COUNT
  const dailyVolumeName = achLimitType ? DAILY_ACH_VOLUME : DAILY_CARD_VOLUME
  const monthlyCountName = achLimitType ? MONTHLY_ACH_COUNT : MONTHLY_CARD_COUNT
  const monthlyVolumeName = achLimitType ? MONTHLY_ACH_VOLUME : MONTHLY_CARD_VOLUME

  return {
    dailyStartTime: fieldEmpty(toString(dailyStartTime), START_TIME),
    monthlyStartTime: fieldEmpty(toString(monthlyStartTime), START_TIME),
    monthlyDate: fieldEmpty(toString(monthlyDate), MONTHLY_START_DATE),
    dailyCount: fieldEmpty(toString(dailyCount), dailyCountName),
    dailyVolume: fieldEmpty(toString(dailyVolume), dailyVolumeName),
    monthlyCount: fieldEmpty(toString(monthlyCount), monthlyCountName),
    monthlyVolume: fieldEmpty(toString(monthlyVolume), monthlyVolumeName),
    maxTransactionAmount: nonEmptyPositiveAmount({ field: maxTransactionAmount, name: MAX_TRANSACTION_AMOUNT }),
    // TODO: comment out max amount checks until product finalizes
    // dailyCount: applicationType ? validateMaxAmount({ field: dailyCount, name: dailyCountName, maxAmount: achLimitType ? DAILY_APPLICATION_ACH_MAX_COUNT : DAILY_APPLICATION_CARD_MAX_COUNT }) :
    //   validateMaxAmount({ field: dailyCount, name: dailyCountName, maxAmount: achLimitType ? DAILY_RECIPIENT_ACH_MAX_COUNT : DAILY_RECIPIENT_CARD_MAX_COUNT }),
    // dailyVolume: applicationType ? validateMaxAmount({ field: dailyVolume, name: dailyVolumeName, maxAmount: achLimitType ? DAILY_APPLICATION_ACH_MAX_VOLUME : DAILY_APPLICATION_CARD_MAX_VOLUME, showCurrencySymbol: true }) :
    //   validateMaxAmount({ field: dailyVolume, name: dailyVolumeName, maxAmount: achLimitType ? DAILY_RECIPIENT_ACH_MAX_VOLUME : DAILY_RECIPIENT_CARD_MAX_VOLUME, showCurrencySymbol: true }),
    // monthlyCount: applicationType ? validateMaxAmount({ field: monthlyCount, name: monthlyCountName, maxAmount: achLimitType ? MONTHLY_APPLICATION_ACH_MAX_COUNT : MONTHLY_APPLICATION_CARD_MAX_COUNT }) :
    //   validateMaxAmount({ field: monthlyCount, name: monthlyCountName, maxAmount: achLimitType ? MONTHLY_RECIPIENT_ACH_MAX_COUNT : MONTHLY_RECIPIENT_CARD_MAX_COUNT }),
    // monthlyVolume: applicationType ? validateMaxAmount({ field: monthlyVolume, name: monthlyVolumeName, maxAmount: achLimitType ? MONTHLY_APPLICATION_ACH_MAX_VOLUME : MONTHLY_APPLICATION_CARD_MAX_VOLUME, showCurrencySymbol: true }) :
    //   validateMaxAmount({ field: monthlyVolume, name: monthlyVolumeName, maxAmount: achLimitType ? MONTHLY_RECIPIENT_ACH_MAX_VOLUME : MONTHLY_RECIPIENT_CARD_MAX_VOLUME, showCurrencySymbol: true }),
  }
}

export default validateUpdateLimitsForm
