import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DatePicker from 'components/Shared/Inputs/DatePicker/DatePicker'
import { t } from 'constants/language/languageConstants'

const DatePickerInput = ({
  input = {},
  meta,
  placeholderText = 'MM/DD/YYYY',
  minDate,
  maxDate,
  label,
  disabled,
  dateFormat,
  showTimeSelect,
  autofill = true,
  helperText,
}) => {
  const { name } = input
  const { error, touched } = meta

  const classNames = classnames({
    InputField: true,
    [name]: true,
    error: touched && error,
  })

  return (
    <div className={classNames}>
      { label && (<label className='label label-2'>{t(label)}</label>) }

      <DatePicker
        input={input}
        meta={meta}
        placeholderText={placeholderText}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        autofill={autofill}
      />

      { helperText && (<span className='helper-text'>{t(helperText)}</span>) }
    </div>
  )
}

DatePickerInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholderText: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  autofill: PropTypes.bool,
  helperText: PropTypes.string,
}

export default DatePickerInput
