import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  AVERAGE_CARD_TRANSFER_AMOUNT,
  SETTLEMENT_AMOUNT,
  TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_COUNT,
  TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_RATIO,
  TRANSACTION_MONITORING_TOTAL_TRANSFER_COUNT,
} from 'constants/language/languageConstants'

const SmallSettlementCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    settlementEntryCount,
    settlementEntryCountSmallDollar,
    displaySettlementEntryRatioSmallDollar,
    displaySettlementAmountDollarsSum,
    displaySettlementAmountDollarsAverage,
  ] = getMany(calculations, [
    'settlementEntryCount',
    'settlementEntryCountSmallDollar',
    'displaySettlementEntryRatioSmallDollar',
    'displaySettlementAmountDollarsSum',
    'displaySettlementAmountDollarsAverage',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_RATIO}
        headerData={{
          value: displaySettlementEntryRatioSmallDollar,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_SMALL_DOLLAR_TRANSFER_COUNT}
        headerData={{
          value: settlementEntryCountSmallDollar,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_TRANSFER_COUNT}
        headerData={{
          value: settlementEntryCount,
        }}
      />
      <CustomBigNumberC
        title={AVERAGE_CARD_TRANSFER_AMOUNT}
        headerData={{
          value: displaySettlementAmountDollarsAverage,
        }}
      />
      <CustomBigNumberC
        title={SETTLEMENT_AMOUNT}
        headerData={{
          value: displaySettlementAmountDollarsSum,
        }}
      />
    </div>
  )
}

export default SmallSettlementCards
