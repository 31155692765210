import './GenerateNewURLLinkModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import CustomerOnboardingFormNewUrlLinkC from 'components/Customer/Pages/CustomerOnboardingForm/CustomerOnboardingFormNewUrlLink/CustomerOnboardingFormNewUrlLinkC'
import { NEW_URL_LINK_GENERATED } from 'constants/language/languageConstants'

const GenerateNewURLLinkModal = ({
  customerOnboardingFormId = '',
  isAdditionalVerificationNeeded,
}) => {
  return (
    <GenericModal
      title={NEW_URL_LINK_GENERATED}
      className='GenerateNewURLLinkModal'
      Component={CustomerOnboardingFormNewUrlLinkC}
      customerOnboardingFormId={customerOnboardingFormId}
      isAdditionalVerificationNeeded={isAdditionalVerificationNeeded}
    />
  )
}

GenerateNewURLLinkModal.propTypes = {
  customerOnboardingFormId: PropTypes.string,
  isAdditionalVerificationNeeded: PropTypes.bool,
}

export default GenerateNewURLLinkModal
