import dashboardAPI from 'utilities/api/dashboardAPI'
import { NOTES } from 'constants/apiConstants'

const getNotesAPI = ({ credentials, queries, meta }) => {
  return dashboardAPI.get({
    credentials,
    queries,
    meta,
    path: NOTES,
    service: NOTES,
  })
}

export default getNotesAPI
