import './GenericEmailFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import submitGenericEmailForm from 'utilities/submit/submitGenericEmailForm'
import { SAVE } from 'constants/language/languageConstants'

const GenericEmailForm = ({
  handleSubmit = () => {},
  submitting = false,
  invalid = false,
  emailName = '',
}) => {
  return (
    <form className='GenericEmailForm' onSubmit={handleSubmit(submitGenericEmailForm)}>
      <div className='section'>
        <h3>{emailName} Email Template</h3>

        <Field
          name='subject'
          label='Subject'
          component={InputField}
        />

        <Field
          name='html'
          label='HTML Body'
          component={TextAreaField}
        />

        <Field
          name='text'
          label='Text Body (when HTML is not Supported)'
          component={TextAreaField}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} disabled={invalid} label={SAVE} />
      </div>
    </form>
  )
}
GenericEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  emailName: PropTypes.string,
}

export default GenericEmailForm
