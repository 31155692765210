import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'

const frontendPostOnboardingFormLinkModel = ({ data: onboardingForm }) => {
  const [
    onboardingFormLink,
  ] = getMany(onboardingForm, [
    'link_url',
  ])

  return removeUndefined({
    onboardingFormLink,
  })
}

export default frontendPostOnboardingFormLinkModel
