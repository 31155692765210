import { ID_OPERANDS } from 'constants/filterConstants'
import isString from 'lodash/isString'

const convertFilterToResourceIdFilter = (resource) => {
  if (isString(resource)) {
    return {
      field: 'id',
      label: `${resource} ID`,
      operands: ID_OPERANDS,
    }
  }

  return {
    field: 'id',
    label: 'ID',
    operands: ID_OPERANDS,
  }
}

export default convertFilterToResourceIdFilter
