import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableHeaders from './TableHeaders'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { getSelectedItemsByKeySelector } from 'state-layer/selectors'
import { allowedKeyPressControls } from 'constants/focusControlConstants'

import {
  SELECT_ITEMS,
  UNSELECT_ALL_ITEMS,
} from 'constants/flowConstants'

const mapStateToProps = (state, props) => {
  const selectedItemsKey = get(props, 'selectedItemsKey')
  const selectedItems = getSelectedItemsByKeySelector(state, selectedItemsKey)
  const action = get(state, 'form.TABLE_FOOTER_FORM.values.actions')

  return {
    selectedItemsKey,
    selectedItems,
    action,
  }
}

class TableHeadersC extends Component {
  selectAllItemsHandler = ({ event, checked }) => {
    const {
      dispatch,
      items,
      selectedItemsKey,
    } = this.props

    if (allowedKeyPressControls(event)) {
      dispatch({
        type: !checked ? SELECT_ITEMS : UNSELECT_ALL_ITEMS,
        payload: {
          key: selectedItemsKey,
          ids: Object.keys(keyBy(items, 'id')),
        },
      })
    }
  }

  render() {
    return (
      <TableHeaders
        {...this.props}
        selectAllItemsHandler={this.selectAllItemsHandler}
      />
    )
  }
}

export default connect(mapStateToProps)(TableHeadersC)
