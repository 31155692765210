import { GET_SUBSCRIPTION_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getSubscriptionRequest = ({
  subscriptionId,
  credentials,
}) => createAction({
  type: GET_SUBSCRIPTION_F_REQUEST,
  id: subscriptionId,
  credentials,
})

export default getSubscriptionRequest
