import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import formatDate from 'utilities/formatters/formatDate'
import { AUTHENTICATION_VALUE_TO_LABEL_MAP } from 'constants/webhookConstants'
import get from 'lodash/get'

import {
  ENABLED,
  DISABLED,
  YES,
  NO,
} from 'constants/language/languageConstants'

const frontendWebhookModel = ({ data: webhook }) => {
  const [
    id,
    createdAt,
    updatedAt,
    enabled,
    url,
    application,
    authentication,
    enabledEvents,
    nickname,
    isAcceptingEvents,
  ] = getMany(webhook, [
    'id',
    'created_at',
    'updated_at',
    'enabled',
    'url',
    'application',
    'authentication',
    'enabled_events',
    'nickname',
    'is_accepting_events',
  ])

  const authenticationType = get(authentication, 'type')

  return removeUndefined({
    id,
    createdAt,
    updatedAt,
    displayCreatedAt: formatDate({ date: createdAt }),
    displayUpdatedAt: formatDate({ date: updatedAt }),
    enabled,
    enabledStatus: enabled ? ENABLED : DISABLED,
    url,
    application,
    authentication,
    authenticationType,
    displayAuthenticationType: get(AUTHENTICATION_VALUE_TO_LABEL_MAP, authenticationType),
    enabledEvents,
    nickname,
    isAcceptingEvents,
    displayIsAcceptingEvents: isAcceptingEvents ? YES : NO,
  })
}

export default frontendWebhookModel
