import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { ACCESS_FORM_PLAID_TOKEN } from 'constants/apiConstants'

const postGuestAccessFormPlaidTokenAPI = ({ id, values, meta }) => {
  return guestDashboardAPI.post({
    path: ACCESS_FORM_PLAID_TOKEN({ id }),
    values,
    meta,
  })
}

export default postGuestAccessFormPlaidTokenAPI
