import moment from 'moment'
import { CREATED_AT_DEFAULT_FORMAT } from 'constants/timeConstants'
import isString from 'lodash/isString'

// TODO: add unit test
const formatDateWithTimeZoneAbrreviation = ({ date, format = CREATED_AT_DEFAULT_FORMAT }) => {
  if (date && !(/^[a-zA-Z]/.test(date)) && isString(date)) {
    const formattedDate = moment.utc(date).local().format(format)
    const timezoneAbbreviation = moment.tz ? moment.tz(moment.tz.guess()).zoneAbbr() : ''

    return timezoneAbbreviation ? `${formattedDate} ${timezoneAbbreviation}` : formattedDate
  }
  return undefined
}

export default formatDateWithTimeZoneAbrreviation
