import frontendSubscriptionAmountModel from 'utilities/create/models/frontendSubscriptionAmountModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSubscriptionAmountsModel = ({ data: subscriptionAmounts }) => {
  const subscriptionAmountsModels = map(subscriptionAmounts, (subscriptionAmount) => frontendSubscriptionAmountModel({ data: subscriptionAmount }))

  return keyBy(subscriptionAmountsModels, 'id')
}

export default frontendSubscriptionAmountsModel
