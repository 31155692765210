import './RadioButtonWithDropdownS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import map from 'lodash/map'
import get from 'lodash/get'

// Custom component to be used in forms. Radio button with dropdown component attached.
const RadioButtonWithDropdown = ({
  input,
  label,
  helperText,
  disabled = false,
  meta: {
    touched,
    error,
    warning,
  },
  options,
  row = false,
  buttonsRow = false,
  required = true,
}) => {
  const { name } = input
  const errorJSX = error && <span className='error'>{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const inputClassName = name.replace('.', '_')
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

  const radioButtonsContainerClassNames = classnames({
    'radio-button-container': true,
    flex: row,
    row,
  })

  const radioButtonsClassNames = classnames({
    'radio-buttons': true,
    flex: true,
    row: buttonsRow,
    column: !buttonsRow,
  })

  return (
    <div className={`RadioButtonWithDropdown ${inputClassName}`}>
      <div className={radioButtonsContainerClassNames}>
        <div className='flex column radio-button-parent-label-container'>
          <div className='flex'>
            { label && <label className='radio-button-label label-2'>{label}</label> }
            { required && <div className='required-field'>*</div> }
          </div>
          { helperText && <label className='radio-button-helper-text label-2'>{helperText}</label> }
        </div>

        <div className={radioButtonsClassNames}>
          {
            map(options, ({
              value,
              label: radioButtonLabel,
              labelHTML: radioButtonLabelHTML,
              disabled: optionDisabled,
              description,
              sublabel,
              dropdownComponent: Component,
              dropdownProps = {},
            }) => {
              const toggleDropdownComponent = (e) => {
                e.preventDefault()
                const element = document.getElementById(`${name}.${value}-dropdown`)
                const chevron = e.currentTarget.querySelector('.arrow')
                if (element) {
                  get(element, 'classList').toggle('show')
                }
                if (chevron) {
                  const chevronIcon = get(chevron, ['classList', 1])
                  if (chevronIcon === 'fa-chevron-down') {
                    chevron.classList.replace('fa-chevron-down', 'fa-chevron-up')
                  } else {
                    chevron.classList.replace('fa-chevron-up', 'fa-chevron-down')
                  }
                }
              }

              const radioButtonClassNames = classnames({
                'radio-button': true,
                [hasError]: !!hasError,
                disabled: optionDisabled || disabled,
              })

              const dropdownComponentClassNames = classnames({
                'dropdown-component': true,
              })

              return (
                <div key={`${name}.${value}`} className={radioButtonClassNames}>
                  <div className='flex radio-button-content'>
                    <label className='flex items-center radio-button-toggle'>
                      <input
                        type='radio'
                        {...input}
                        disabled={optionDisabled || disabled}
                        value={value}
                        checked={value === input.value}
                      />
                      { (radioButtonLabel || radioButtonLabelHTML) && (
                      <div className='radio-button-label-container'>
                        <div className='radio-button-label label-2'>{radioButtonLabelHTML ? radioButtonLabelHTML : radioButtonLabel}</div>
                        {sublabel && (<div className='radio-button-sublabel p-2'>{sublabel}</div>)}
                      </div>
                      )}
                    </label>
                    <div className='flex space-between items-center dropdown-component-toggle' onClick={toggleDropdownComponent}>
                      {description && (
                      <div className='radio-button-description'>{description}</div>
                      )}
                      {Component && <div className='arrow-icon flex items-center'><i className='fa fa-chevron-down arrow' /></div>}
                    </div>
                  </div>
                  {Component && (
                    <div id={`${name}.${value}-dropdown`} className={dropdownComponentClassNames}>
                      <Component
                        {...dropdownProps}
                      />
                    </div>
                  )}
                </div>
              )
            })
          }
        </div>
      </div>

      { (touched && (errorJSX || warningJSX)) }
    </div>
  )
}

RadioButtonWithDropdown.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.array,
  row: PropTypes.bool,
  buttonsRow: PropTypes.bool,
  required: PropTypes.bool,
}

export default RadioButtonWithDropdown
