import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import Checkbox from 'components/Shared/Inputs/Checkbox/Checkbox'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const colors = {
  borderInputDefault: '#7D90A5',
  actionActive: '#0B5DBC',
  textPrimary: '#020F1C',
  actionHoverLight: '#E6F3FD',
  bgLight: '#F6F7F8',
}

const defaultStyles = {
  control: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    boxShadow: state.isFocused ? '0px 0px 1px 1px rgba(11, 93, 188, 0.50)' : 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (styles = {}, state = {}) => ({
    ...styles,
    borderColor: state.isFocused ? colors?.actionActive : colors?.borderInputDefault,
    backgroundColor: state.isSelected ? colors?.actionHoverLight : 'none',
    color: colors?.textPrimary,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors?.bgLight,
    },
  }),
  menu: (styles = {}) => ({
    ...styles,
    border: '1px solid #7D90A5',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
  }),
}

// custom component for options in dropdown with checkboxes
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false)
  const onMouseDown = () => setIsActive(true)
  const onMouseUp = () => setIsActive(false)
  const onMouseLeave = () => setIsActive(false)

  const style = {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex ',
  }

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  }

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <Checkbox checked={isSelected} />
      {children}
    </components.Option>
  )
}

const ReactMultiSelectWithCheckboxes = ({
  input = {},
  options = [],
  className = '',
  meta: {
    touched,
    error,
    warning,
  },
  placeholder = '',
  helperText = '',
  hideSelectedOptions = false,
  closeMenuOnSelect = false,
  disabled = false,
  maxMenuHeight,
}) => {
  const errorJSX = error && <span className='error'>{error}</span>
  const warningJSX = warning && <span className='warning'>{warning}</span>
  const hasError = touched && (errorJSX || warningJSX) ? 'error' : ''

  return (
    <>
      <Select
        {...input}
        isMulti
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={value => input.onChange(value)}
        onBlur={(e) => { e.preventDefault() }}
        options={options}
        components={{
          Option: InputOption,
        }}
        className={classnames('ReactMultiSelectWithCheckboxes', { [className]: !!className, [hasError]: !!hasError })}
        placeholder={placeholder}
        styles={defaultStyles}
        isDisabled={disabled}
        maxMenuHeight={maxMenuHeight}
      />
      { helperText && <div className='lower-label p-2'>{helperText}</div> }
      { touched && (errorJSX || warningJSX) }
    </>
  )
}

ReactMultiSelectWithCheckboxes.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  hideSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
}

export default ReactMultiSelectWithCheckboxes
