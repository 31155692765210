import { GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'
import isEmpty from 'lodash/isEmpty'

const getAccessFormAdditionalDocumentsRequest = ({
  accessFormId,
  credentials,
  meta,
}) => createAction({
  type: GET_ACCESS_FORM_ADDITIONAL_DOCUMENTS_F_REQUEST,
  id: accessFormId,
  credentials: isEmpty(credentials) ? undefined : credentials,
  meta,
})

export default getAccessFormAdditionalDocumentsRequest
