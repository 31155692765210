import React, { Component } from 'react'
import { connect } from 'react-redux'
import PayoutLinkTransferAttempts from './PayoutLinkTransferAttempts'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { createdLast3MonthQueries } from 'constants/queryConstants'
import { PAYOUT_ATTEMPTS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const payoutLinkId = get(props, 'payoutLinkId')
  const transferAttemptPath = PAYOUT_ATTEMPTS_PATH({ credentialId })

  const initialQueries = {
    offset: 0,
    sort: 'created_at,desc',
    entity_id: payoutLinkId,
  }

  return {
    initialQueries,
    credentials,
    initialFilters: createdLast3MonthQueries(),
    transferAttemptPath,
  }
}

class PayoutLinkTransferAttemptsC extends Component {
  render() {
    return (
      <PayoutLinkTransferAttempts {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(PayoutLinkTransferAttemptsC)
