import numeral from 'numeral'
import redirectRequest from 'utilities/actions/redirectRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { IDENTITY_PATH } from 'constants/pathConstants'
import merge from 'lodash/merge'
import toString from 'lodash/toString'
import keys from 'lodash/keys'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  CREATE_IDENTITY,
  EDIT_IDENTITY,
  CREATE_APPLICATION_IDENTITY,
} from 'constants/amplitudeConstants'

const submitIdentityForm = (values, dispatch, props) => {
  const {
    annualCardVolume,
    maxTransactionAmount,
    principalPercentageOwnership,
    id,
  } = values

  const {
    actionType,
    credentials,
    recordId,
    applicationId,
    customSuccessFunc,
    customSuccessMsg,
  } = props

  const formattedACV = numeral(annualCardVolume).value()
  const formattedMTA = numeral(maxTransactionAmount).value()
  const formattedPPO = toString(principalPercentageOwnership).replace('%', '')
  let eventType = ''

  if (id) {
    eventType = EDIT_IDENTITY
  } else {
    eventType = recordId ? CREATE_APPLICATION_IDENTITY : CREATE_IDENTITY
  }

  const valuesToSubmit = merge({}, values, {
    application: applicationId,
    annualCardVolume: formattedACV,
    maxTransactionAmount: formattedMTA,
    principalPercentageOwnership: formattedPPO,
  })

  dispatch({
    type: actionType,
    payload: {
      values: valuesToSubmit,
      credentials,
      id: recordId,
    },
    meta: {
      // TODO: fix bug where identity edit is being redirected when it should not
      successCallback: customSuccessFunc ? customSuccessFunc :
        ({ newValues }) => {
          const identityId = head(keys(newValues))
          const credentialId = get(credentials, 'id')
          const identityPath = IDENTITY_PATH({ identityId, credentialId })

          dispatch(redirectRequest({ path: identityPath }))
        },
      actionId: sendAmplitudeActionEvent(eventType, {
        identityId: id,
        credentials,
        applicationId: recordId || undefined,
      }),
      successMessage: customSuccessMsg,
    },
  })
}

export default submitIdentityForm
