import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'

const CreateBalanceAdjustment = ({
  steps = [],
}) => {
  return (
    <div className='CreateBalanceAdjustment'>
      <FlowsC
        flowClassName='create-balance-adjustment-flow'
        steps={steps}
        hideFlowProgress
        layout='vertical'
        flowVariant='form-page-borderless'
      />
    </div>
  )
}

CreateBalanceAdjustment.propTypes = {
  steps: PropTypes.array,
}

export default CreateBalanceAdjustment
