import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM_TEMPLATE } from 'constants/apiConstants'

const getComplianceFormTemplateAPI = ({ id, meta, credentials }) => {
  return dashboardAPI.get({
    path: COMPLIANCE_FORM_TEMPLATE({ complianceFormTemplateId: id }),
    meta,
    credentials,
  })
}

export default getComplianceFormTemplateAPI
