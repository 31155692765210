import selectOption from 'utilities/forms/selectOption'

import {
  MERCHANT_PAYOUT_PLAN,
  APPLICATION_PAYOUT_PLAN,
  PLATFORM_PAYOUT_PLAN,
} from 'constants/language/languageConstants'

// templates constants
export const ALL = '*'

export const API = 'API'
export const SYSTEM = 'SYSTEM'

export const PLATFORM_FEE = 'PLATFORM_FEE'
export const APPLICATION_FEE = 'APPLICATION_FEE'

export const MERCHANT_CREDIT = 'MERCHANT_CREDIT'
export const MERCHANT_DEBIT = 'MERCHANT_DEBIT'
export const PLATFORM_CREDIT = 'PLATFORM_CREDIT'
export const PLATFORM_DEBIT = 'PLATFORM_DEBIT'

export const MERCHANT_CREDIT_ADJUSTMENT = 'MERCHANT_CREDIT_ADJUSTMENT'
export const MERCHANT_DEBIT_ADJUSTMENT = 'MERCHANT_DEBIT_ADJUSTMENT'
export const PLATFORM_CREDIT_ADJUSTMENT = 'PLATFORM_CREDIT_ADJUSTMENT'
export const PLATFORM_DEBIT_ADJUSTMENT = 'PLATFORM_DEBIT_ADJUSTMENT'

export const TRANSFER = 'transfer'
export const FEE = 'fee'
export const DISPUTE_TRANSFER = 'dispute_transfer'
export const ADJUSTMENT = 'adjustment'
export const REVERSE = 'reverse'

export const DAILY = 'DAILY'
export const MONTHLY = 'MONTHLY'
export const CONTINUOUS = 'CONTINUOUS'

export const MERCHANT = 'MERCHANT'
export const PLATFORM = 'PLATFORM'
export const APPLICATION = 'APPLICATION'

export const PAYFAC_FBO = 'PAYFAC_FBO'
export const MERCHANT_BANK_ACCOUNT = 'MERCHANT_BANK_ACCOUNT'
export const PLATFORM_BANK_ACCOUNT = 'PLATFORM_BANK_ACCOUNT'

export const PAYFAC_CREDIT = 'PAYFAC_CREDIT'
export const SAME_DAY_ACH = 'SAME_DAY_ACH'
export const NOOP = 'NOOP'

export const IMMEDIATE = 'IMMEDIATE'
export const DEFERRED = 'DEFERRED'

export const INCLUDES = 'includes'
export const EXCLUDES = 'excludes'

export const transferSubtypeOptions = [
  {
    includesExcludes: INCLUDES,
    templateConfigOptions: [
      selectOption('All (*)', ALL),
      selectOption('API', API),
      selectOption('System', SYSTEM),
    ],
  },
  {
    includesExcludes: EXCLUDES,
    templateConfigOptions: [
      selectOption('API', API),
      selectOption('System', SYSTEM),
    ],
  },
]

export const feeSubtypeOptions = [
  {
    includesExcludes: INCLUDES,
    templateConfigOptions: [
      selectOption('All (*)', ALL),
      selectOption('Platform Fee', PLATFORM_FEE),
      selectOption('Application Fee', APPLICATION_FEE),
    ],
  },
  {
    includesExcludes: EXCLUDES,
    templateConfigOptions: [
      selectOption('Platform Fee', PLATFORM_FEE),
      selectOption('Application Fee', APPLICATION_FEE),
    ],
  },
]

export const disputeTransferSubtypeOptions = [
  {
    includesExcludes: INCLUDES,
    templateConfigOptions: [
      selectOption('All (*)', ALL),
      selectOption('Merchant Credit', MERCHANT_CREDIT),
      selectOption('Merchant Debit', MERCHANT_DEBIT),
      selectOption('Platform Credit', PLATFORM_CREDIT),
      selectOption('Platform Debit', PLATFORM_DEBIT),
    ],
  },
  {
    includesExcludes: EXCLUDES,
    templateConfigOptions: [
      selectOption('Merchant Credit', MERCHANT_CREDIT),
      selectOption('Merchant Debit', MERCHANT_DEBIT),
      selectOption('Platform Credit', PLATFORM_CREDIT),
      selectOption('Platform Debit', PLATFORM_DEBIT),
    ],
  },
]

export const adjustmentSubtypeOptions = [
  {
    includesExcludes: INCLUDES,
    templateConfigOptions: [
      selectOption('All (*)', ALL),
      selectOption('Merchant Credit Adjustment', MERCHANT_CREDIT_ADJUSTMENT),
      selectOption('Merchant Debit Adjustment', MERCHANT_DEBIT_ADJUSTMENT),
      selectOption('Platform Credit Adjustment', PLATFORM_CREDIT_ADJUSTMENT),
      selectOption('Platform Debit Adjustment', PLATFORM_DEBIT_ADJUSTMENT),
    ],
  },
  {
    includesExcludes: EXCLUDES,
    templateConfigOptions: [
      selectOption('Merchant Credit Adjustment', MERCHANT_CREDIT_ADJUSTMENT),
      selectOption('Merchant Debit Adjustment', MERCHANT_DEBIT_ADJUSTMENT),
      selectOption('Platform Credit Adjustment', PLATFORM_CREDIT_ADJUSTMENT),
      selectOption('Platform Debit Adjustment', PLATFORM_DEBIT_ADJUSTMENT),
    ],
  },
]

export const reversalSubtypeOptions = [
  {
    includesExcludes: INCLUDES,
    templateConfigOptions: [
      selectOption('All (*)', ALL),
      selectOption('API' , API),
      selectOption('Merchant Debit Adjustment', MERCHANT_DEBIT_ADJUSTMENT),
      selectOption('System', SYSTEM),
    ],
  },
  {
    includesExcludes: EXCLUDES,
    templateConfigOptions: [
      selectOption('API' , API),
      selectOption('Merchant Debit Adjustment', MERCHANT_DEBIT_ADJUSTMENT),
      selectOption('System', SYSTEM),
    ],
  },
]

export const templatesConfigLabels = [
  selectOption('Transfer', TRANSFER),
  selectOption('Fee', FEE),
  selectOption('Dispute Transfer', DISPUTE_TRANSFER),
  selectOption('Adjustment', ADJUSTMENT),
  selectOption('Reverse', REVERSE),
]

export const templatesConfigOptionsMap = {
  [TRANSFER]: transferSubtypeOptions,
  [FEE]: feeSubtypeOptions,
  [DISPUTE_TRANSFER]: disputeTransferSubtypeOptions,
  [ADJUSTMENT]: adjustmentSubtypeOptions,
  [REVERSE]: reversalSubtypeOptions,
}

// schedules constants
export const scheduleTypeOptions = [
  selectOption('Select Type' , ''),
  selectOption('Daily' , DAILY),
  selectOption('Monthly' , MONTHLY),
  selectOption('Continuous' , CONTINUOUS),
]

// combination plans constants
export const levelOptions = [
  selectOption('Select Type' , ''),
  selectOption('Platform' , PLATFORM),
  selectOption('Merchant' , MERCHANT),
]

export const instrumentLabelOptions = [
  selectOption('Select Type' , ''),
  selectOption('PayFac FBO' , PAYFAC_FBO),
  selectOption('Merchant Bank Account' , MERCHANT_BANK_ACCOUNT),
  selectOption('Platform Bank Account' , PLATFORM_BANK_ACCOUNT),
]

export const railOptions = [
  selectOption('Select Type' , ''),
  selectOption('PayFac Credit' , PAYFAC_CREDIT),
  selectOption('Same Day ACH' , SAME_DAY_ACH),
  selectOption('NOOP' , NOOP),
]

export const submissionSettingsOptions = [
  selectOption('Select Type' , ''),
  selectOption('Immediate' , IMMEDIATE),
  selectOption('Deferred' , DEFERRED),
]

export const payoutPlanLevelToTitleMap = {
  MERCHANT: MERCHANT_PAYOUT_PLAN,
  APPLICATION: APPLICATION_PAYOUT_PLAN,
  PLATFORM: PLATFORM_PAYOUT_PLAN,
}
