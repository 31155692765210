import { PATCH_EMAIL_TEMPLATE_F_REQUEST } from 'constants/flowConstants'
import get from 'lodash/get'
import getMany from 'utilities/get/getMany'

const submitGenericEmailForm = (values, dispatch, props = {}) => {
  const emailId = get(props, 'email.id')
  const templateName = get(props, 'email.templateName')

  const [
    subject,
    html,
    text,
  ] = getMany(values, [
    'subject',
    'html',
    'text',
  ])

  dispatch({
    type: PATCH_EMAIL_TEMPLATE_F_REQUEST,
    payload: {
      id: emailId,
      values: {
        email_text_part: text,
        email_subject_part: subject,
        email_html_part: html,
        email_template_name: templateName,
      },
    },
  })
}

export default submitGenericEmailForm
