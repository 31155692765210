import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FETCHING } from 'constants/reducerConstants'
import SubscriptionSchedules from './SubscriptionSchedules'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { SUBSCRIPTION_SCHEDULES_PATH } from 'constants/pathConstants'
import { CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT } from 'constants/modalConstants'
import get from 'lodash/get'

import {
  SUBSCRIPTION_TYPE,
  SCHEDULE_NICKNAME,
} from 'constants/filterConstants'

const allowedFilters = [
  SUBSCRIPTION_TYPE,
  SCHEDULE_NICKNAME,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `subscriptionSchedulesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const subscriptionSchedulePath = SUBSCRIPTION_SCHEDULES_PATH({ credentialId })

  return {
    allowedFilters,
    isFetching,
    subscriptionSchedulePath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateSubscriptionScheduleAmountModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION_SCHEDULE_AMOUNT, modalProps })),
  }
}

class SubscriptionSchedulesC extends Component {
  render() {
    const {
      credentials,
      showCreateSubscriptionScheduleAmountModal,
    } = this.props

    const actions = [
      {
        label: 'Create a Subscription Schedule',
        action: () => {
          showCreateSubscriptionScheduleAmountModal({ credentials })
        },
        className: 'create-subscription-schedule-button',
      },
    ]

    return (
      <SubscriptionSchedules
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSchedulesC)
