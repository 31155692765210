import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_SUBJECT_REVIEW } from 'constants/apiConstants'
import omit from 'lodash/omit'
import get from 'lodash/get'

const patchUnderwritingReviewAPI = ({ values, credentials, meta }) => {
  const reviewId = get(values, 'id')
  const subjectId = get(values, 'subject_id')
  const valuesToPatch = omit(values, ['id', 'subject_id'])

  return paymentsAPI.patch({
    path: UNDERWRITING_SUBJECT_REVIEW({ subjectId, reviewId }),
    values: valuesToPatch,
    credentials,
    meta,
  })
}

export default patchUnderwritingReviewAPI
