import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import get from 'lodash/get'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  invalidRegex,
} from 'utilities/validate'

import {
  EMAIL_REGEX,
  TAG_REGEX,
} from 'constants/regexConstants'

const validateGuestPayoutLinkForm = (values, props) => {
  const {
    payoutLink = {},
    checkoutForm = {},
  } = props

  const checkoutResource = isEmpty(payoutLink) ? checkoutForm : payoutLink

  const {
    totalAmount,
    currency,
    tags,
  } = checkoutResource

  const customFields = filter(tags, (tagValue, tagKey) => startsWith(tagKey, 'custom_field'))

  const {
    firstName,
    lastName,
    personalAddress = {},
    businessName,
    doingBusinessAs,
    businessAddress = {},
    paymentInstrumentId = '',
  } = values

  return {
    firstName: fieldEmpty(firstName, 'First Name'),
    lastName: fieldEmpty(lastName, 'Last Name'),
    personalAddress: {
      line1: fieldEmpty(personalAddress.line1, 'Address Line 1'),
      city: fieldEmpty(personalAddress.city, 'City'),
      region: fieldEmpty(personalAddress.region, 'State'),
      postalCode: fieldEmpty(personalAddress.postalCode, 'Zip Code'),
      country: fieldEmpty(personalAddress.country, 'Country'),
    },
    businessName: fieldEmpty(businessName, 'Business Name'),
    doingBusinessAs: fieldEmpty(doingBusinessAs, 'Doing Business As'),
    businessAddress: {
      line1: fieldEmpty(businessAddress.line1, 'Address Line 1'),
      city: fieldEmpty(businessAddress.city, 'City'),
      region: fieldEmpty(businessAddress.region, 'State'),
      postalCode: fieldEmpty(businessAddress.postalCode, 'Zip Code'),
      country: fieldEmpty(businessAddress.country, 'Country'),
    },
    paymentInstrumentId: fieldEmpty(paymentInstrumentId, 'Payment Instrument'),
  }
}

export default validateGuestPayoutLinkForm
