import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDashboardConfigByEntityTypeAndId } from 'state-layer/selectors/index'
import OnboardingConfigurationForm from './OnboardingConfigurationForm'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import { isFlexPlatform } from 'constants/flexConstants'
import { GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST } from 'constants/flowConstants'
import { ONBOARDING_CONFIGURATIONS_FORM } from 'constants/formConstants'
import { LIVE_ENVIRONMENT } from 'constants/environmentConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  reset,
  change,
  getFormValues,
} from 'redux-form'

import {
  FILE_UPLOAD_INSTRUCTIONS,
  FILE_UPLOAD_SUB_INSTRUCTIONS,
} from 'constants/language/languageConstants'

const config = {
  multiple: false,
  maxSize: 10000000,
  accept: 'image/jpeg, image/png, image/jpg',
}

const instructions = [
  {
    name: 'main',
    message: FILE_UPLOAD_INSTRUCTIONS,
  },
  {
    name: 'sub',
    message: FILE_UPLOAD_SUB_INSTRUCTIONS,
  },
]

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCustomization: ({ entityId, entityType }) => dispatch({
      type: GET_DASHBOARD_CUSTOMIZATIONS_F_REQUEST,
      payload: {
        queries: {
          entity_id: entityId,
          entity_type: entityType,
        },
      },
    }),
    updateColor: (fieldName, color) => {
      dispatch(change(ONBOARDING_CONFIGURATIONS_FORM, fieldName, color.hex))
    },
    resetForm: () => dispatch(reset(ONBOARDING_CONFIGURATIONS_FORM)),
  }
}

const mapStateToProps = (state, props) => {
  const {
    entityType,
    entityId,
  } = props

  const credentials = getCurrentCredentials(state)
  const currentUser = getCurrentUser(state)
  const environment = get(credentials, 'environment')
  const isLiveEnvironment = environment === LIVE_ENVIRONMENT

  const isFetchingConfiguration = get(state, `dashboardConfigurationsR.${FETCHING}`, false)
  const isFetchingCurrentUser = get(state, `currentUserR.${FETCHING}`, true)
  const customSettings = entityType && entityId ? getDashboardConfigByEntityTypeAndId(state, entityType, entityId) : get(currentUser, 'customSettings')
  const initialSidebarBackgroundColor = get(customSettings, 'sidebarBackgroundColor', '#FFFFFF')
  const isFetching = entityType && entityId ? isFetchingConfiguration : isFetchingCurrentUser
  const formValues = getFormValues(ONBOARDING_CONFIGURATIONS_FORM)(state)
  const onboardingPrimaryColor = get(formValues, 'onboardingPrimaryColor')

  return removeUndefined({
    id: get(customSettings, 'id'),
    isFetching,
    credentials,
    customSettings,
    initialValues: merge({}, { onboardingPrimaryColor: initialSidebarBackgroundColor }, customSettings),
    showEnabledInput: isRolePlatform({ credentials }) && isFlexPlatform(),
    onboardingPrimaryColor,
    isLiveEnvironment,
    isFetchingCurrentUser,
    config,
    instructions,
  })
}

class OnboardingConfigurationFormC extends Component {
  constructor(props) {
    super(props)

    this.state = { displayColorPicker: false }
  }

  componentDidMount() {
    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isFetchingCurrentUser: prevIsFetchingCurrentUser } = prevProps

    const {
      entityId,
      entityType,
      getDashboardCustomization,
      isFetchingCurrentUser,
    } = this.props

    if (entityId && entityType && prevIsFetchingCurrentUser && !isFetchingCurrentUser) {
      getDashboardCustomization({ entityId, entityType })
    }
  }

  handleClick = () => {
    const { displayColorPicker } = this.state
    this.setState({ displayColorPicker: !displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { resetForm } = this.props
    const { displayColorPicker } = this.state

    return (
      <OnboardingConfigurationForm
        {...this.props}
        getDashboardCustomization={this.getDashboardCustomization}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        displayColorPicker={displayColorPicker}
        resetForm={resetForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingConfigurationFormC)
