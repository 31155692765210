import './CompanyTabsS.scss'
import React from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import PropTypes from 'prop-types'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'

const CompanyTabs = ({
  tabs = [],
  headerData = {},
  actions = [],
}) => {
  return (
    <div className='CompanyTabs'>
      <HeaderV2C
        actions={actions}
        {...headerData}
      />

      <TabsC
        tabs={tabs}
      />
    </div>
  )
}

CompanyTabs.propTypes = {
  tabs: PropTypes.array,
  headerData: PropTypes.object,
}

export default CompanyTabs
