import './ProfileInfoS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import { PROFILE } from 'constants/language/languageConstants'

const ProfileInfo = ({
  profileInfoCardsData = [],
  isFetching = false,
}) => {
  return (
    <div className='ProfileInfo'>
      <PageSectionV2C
        title={PROFILE}
        columns={profileInfoCardsData}
        isFetching={isFetching}
      />
    </div>
  )
}

ProfileInfo.propTypes = {
  profileInfoCardsData: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default ProfileInfo
