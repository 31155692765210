import './AddressFieldsetS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  USA,
  defaultRegionLabel,
  defaultPostalCodeLabel,
  countryNamesOptions,
  countryToRegionLabelMap,
  countryToPostalCodeLabelMap,
  regionNameOptionsByCountryCode,
} from 'constants/countryConstants'

import {
  t,
  LINE_1,
  LINE_2,
  CITY,
  STATE,
  POSTAL_CODE,
  COUNTRY,
  REGION,
  SELECT_COUNTRY,
} from 'constants/language/languageConstants'

const AddressFieldset = ({
  name = 'address',
  label = '',
  hideLine2 = false,
  countryCode = USA,
  noPlaceholders = false,
  showLabels = false,
  lowerLabels = {},
  fieldLabel = 'Address',
  required = true,
  disabledCountry = false,
}) => {
  const fieldName = (field) => `${name}.${field}`
  const labelName = (labelString) => `${fieldLabel} ${labelString}`
  const regionNameOptions = regionNameOptionsByCountryCode(countryCode)
  const regionLabel = t(get(countryToRegionLabelMap, countryCode, defaultRegionLabel))
  const postalCodeLabel = t(get(countryToPostalCodeLabelMap, countryCode, defaultPostalCodeLabel))

  const [
    addressLine1LowerLabel,
    addressLine2LowerLabel,
    cityLowerLabel,
    regionLowerLabel,
    postalCodeLowerLabel,
    countryLowerLabel,
  ] = getMany(lowerLabels, [
    'addressLine1',
    'addressLine2',
    'city',
    'region',
    'postalCode',
    'country',
  ])

  return (
    <fieldset className='AddressFieldset'>
      { label && <label className='label-2'>{label}</label> }

      <Field
        name={fieldName('line1')}
        label={showLabels && labelName(t(LINE_1))}
        placeholder={!noPlaceholders && t(LINE_1)}
        component={InputField}
        ariaLabel={`${label} line1`}
        helperText={addressLine1LowerLabel}
        autoComplete='address-line1'
        required={required}
      />

      { !hideLine2 && (
        <Field
          name={fieldName('line2')}
          label={showLabels && labelName(t(LINE_2))}
          placeholder={!noPlaceholders && t(LINE_2)}
          component={InputField}
          ariaLabel={`${label} line2`}
          helperText={addressLine2LowerLabel}
          autoComplete='address-line2'
          required={false}
        />
      )}

      <div className='locale flex space-between items-flex-start'>
        <Field
          name={fieldName('city')}
          label={showLabels && t(CITY)}
          placeholder={!noPlaceholders && t(CITY)}
          component={InputField}
          ariaLabel={`${label} city`}
          helperText={cityLowerLabel}
          autoComplete='address-level2'
          required={required}
        />

        { regionNameOptions && (
          <Field
            name={fieldName('region')}
            label={showLabels && countryCode === USA ? t(STATE) : t(REGION)}
            placeholder={!noPlaceholders && regionLabel}
            component={SelectField}
            options={regionNameOptions}
            ariaLabel={`${label} region`}
            helperText={regionLowerLabel}
            autoComplete='address-level1'
            required={required}
          />
        )}

        { !regionNameOptions && (
          <Field
            name={fieldName('region')}
            label={showLabels && t(STATE)}
            placeholder={!noPlaceholders && regionLabel}
            component={InputField}
            ariaLabel={`${label} region`}
            helperText={regionLowerLabel}
            autoComplete='address-level1'
            required={required}
          />
        )}

        <Field
          name={fieldName('postalCode')}
          label={showLabels && t(POSTAL_CODE)}
          placeholder={!noPlaceholders && postalCodeLabel}
          component={InputField}
          ariaLabel={`${label} postal code`}
          helperText={postalCodeLowerLabel}
          autoComplete='postal-code'
          required={required}
        />
      </div>

      <Field
        name={fieldName('country')}
        label={showLabels && t(COUNTRY)}
        placeholder={!noPlaceholders && t(SELECT_COUNTRY)}
        component={SelectField}
        options={countryNamesOptions}
        ariaLabel={`${label} country`}
        helperText={countryLowerLabel}
        autoComplete='country'
        required={required}
        disabled={disabledCountry}
      />
    </fieldset>
  )
}

AddressFieldset.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hideLine2: PropTypes.bool,
  countryCode: PropTypes.bool,
  noPlaceholders: PropTypes.bool,
  showLabels: PropTypes.bool,
  lowerLabels: PropTypes.object,
  fieldLabel: PropTypes.string,
  disabledCountry: PropTypes.bool,
}

export default AddressFieldset
