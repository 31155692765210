import { GET_UNDERWRITING_REVIEW_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getUnderwritingReviewRequest = ({
  subjectId,
  reviewId,
  entityType,
  entityId,
  runId,
  credentials,
}) => createAction({
  type: GET_UNDERWRITING_REVIEW_F_REQUEST,
  values: {
    subjectId,
    reviewId,
    entityType,
    entityId,
    runId,
  },
  credentials,
})

export default getUnderwritingReviewRequest
