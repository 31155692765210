import NotificationsDropdown from './NotificationsDropdown'
import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { get20LatestNotifications } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST } from 'constants/flowConstants'
import { MARK_ALL_NOTIFICATIONS_AS_READ } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'

import {
  NOTIFICATIONS_PATH,
  SETTINGS_PATH,
} from 'constants/pathConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const notifications = get20LatestNotifications(state)

  const settingsPath = `${SETTINGS_PATH({ credentialId })}?tab=notification-settings`
  const viewAllPath = NOTIFICATIONS_PATH({ credentialId })
  const noNotifications = isEmpty(notifications)
  const unreadNotificationsCount = filter(notifications, ({ seenAt }) => !seenAt).length || null

  // group notifications by the last day
  const dayAgo = moment().subtract(1, 'days')
  const groupedNotifications = groupBy(notifications, ({ createdAt }) => {
    return moment(createdAt) > dayAgo ? 'day' : 'month'
  })

  return {
    credentials,
    settingsPath,
    viewAllPath,
    role,
    noNotifications,
    notifications,
    groupedNotifications,
    unreadNotificationsCount,
  }
}

class NotificationsDropdownC extends Component {
  clickMarkAllAsRead = () => {
    const { dispatch, credentials, notifications } = this.props
    const notificationIds = map(notifications, ({ id }) => id)
    dispatch({
      type: POST_NOTIFICATION_ACKNOWLEDGMENT_F_REQUEST,
      payload: {
        values: { ids: notificationIds },
        credentials,
      },
      meta: {
        actionId: sendAmplitudeActionEvent(MARK_ALL_NOTIFICATIONS_AS_READ, {
          credentials,
        }),
      },
    })
  }

  render() {
    return (
      <NotificationsDropdown
        {...this.props}
        clickMarkAllAsRead={this.clickMarkAllAsRead}
      />
    )
  }
}

export default connect(mapStateToProps)(NotificationsDropdownC)
