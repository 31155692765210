import './PayoutLinkItemsS.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import stripNonNumericChars from 'utilities/string/stripNonNumericChars'
import formatMoney from 'utilities/formatters/formatMoney'
import get from 'lodash/get'
import size from 'lodash/size'

import {
  ADD_ANOTHER_ITEM,
  DELETE_ITEM,
  DESCRIPTION,
  IMAGE_URL,
  ITEM_PREVIEW,
  NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS,
  OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY,
  PRODUCT_OR_SERVICE_NAME,
  QUANTITY,
  UNIT_PRICE,
} from 'constants/language/languageConstants'

import {
  ADD_ICON,
  TRASH_ALT_ICON,
} from 'constants/iconConstants'

const PayoutLinkItems = ({
  fields,
  currentItems = [],
}) => {
  useEffect(() => {
    fields.push({})
  }, [])

  useEffect(() => {
    fields.removeAll()
    fields.push({})
  }, [])

  return (
    <div className='PayoutLinkItems'>
      {fields.map((item, index) => {
        const key = `item_${index}`
        const image = get(currentItems, `[${index}].primaryImageUrl`, '')
        const amount = get(currentItems, `[${index}].amount`, '')
        const quantity = get(currentItems, `[${index}].quantity`, '')
        const name = get(currentItems, `[${index}].name`, '')
        const description = get(currentItems, `[${index}].description`, '')

        return (
          <div key={key} className='item'>
            <Field
              name={`${item}.name`}
              label={PRODUCT_OR_SERVICE_NAME}
              placeholder={NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS}
              component={InputField}
            />

            <Field
              name={`${item}.description`}
              label={DESCRIPTION}
              placeholder={OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY}
              component={InputField}
              required={false}
            />

            <div className='amount-container flex space-between'>
              <AmountField
                name={`${item}.amount`}
                label={UNIT_PRICE}
                placeholder='0.00'
              />

              <Field
                name={`${item}.quantity`}
                label={QUANTITY}
                placeholder={QUANTITY}
                component={InputField}
                format={stripNonNumericChars}
                required={false}
              />
            </div>

            <Field
              name={`${item}.primaryImageUrl`}
              label={IMAGE_URL}
              placeholder='https://www.example.com/123rw21w.jpg'
              component={InputField}
              required={false}
            />

            { image && (
              <>
                <h6 className='preview-title'>{ITEM_PREVIEW}</h6>
                <div className='item-preview flex'>
                  { image && <img src={image} alt='preview' width={100} /> }
                  { !image && <div className='image-placeholder'><i className='fas fa-image' /></div> }

                  <div className='info flex column flex-grow space-between'>
                    <div>
                      <p className='name p-2'>{name}</p>
                      <p className='description p-3 secondary'>{description}</p>
                    </div>

                    <p className='quantity p-3 secondary'>{quantity >= 1 ? `Qty: ${quantity}` : ''}</p>
                  </div>
                  <h6 className='total'>{formatMoney({ amount })}</h6>
                </div>
              </>
            )}

            { size(fields) > 1 && (
              <div className='delete-item flex' onClick={() => fields.remove(index)}>
                <i className={`fal fa-${TRASH_ALT_ICON}`} />
                <span>{DELETE_ITEM}</span>
              </div>
            )}
          </div>
        )
      })}

      <div className='add-item flex' onClick={() => fields.push({})}>
        <i className={`fal fa-${ADD_ICON}`} />
        <span>{ADD_ANOTHER_ITEM}</span>
      </div>
    </div>
  )
}

PayoutLinkItems.propTypes = {
  fields: PropTypes.object,
  currentItems: PropTypes.array,
}

export default PayoutLinkItems
