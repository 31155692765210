import './ShowNotesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import NoteC from 'components/Customer/Pages/Note/NoteC'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  PREVIOUS_NOTES,
} from 'constants/language/languageConstants'

const ShowNotes = ({
  notes = [],
  initialFetch = true,
  emptyMessage = 'There are no notes for this resource.',
}) => {
  return (
    <div className='ShowNotes'>
      <div className='header'>
        <h4>{PREVIOUS_NOTES}</h4>
      </div>

      { !initialFetch && isEmpty(notes) && (
        <div className='emptyMessage'>
          <span>{emptyMessage}</span>
        </div>
      )}

      { !isEmpty(notes) && (
        <div className='preview-container'>
          {map(notes, (note) => {
            const noteId = get(note, 'id')

            return (
              <NoteC
                key={noteId}
                note={note}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

ShowNotes.propTypes = {
  notes: PropTypes.array,
  initialFetch: PropTypes.bool,
  emptyMessage: PropTypes.string,
}

export default ShowNotes
