import { BANK_ACCOUNT_VERIFICATION_DOCUMENT_UPLOAD_ERROR } from 'constants/language/languageConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  validateBankCode,
  validateMaskedBankAccountNumber,
} from 'utilities/validate'

import {
  INSTITUTION_NUMBER_REGEX,
  TRANSIT_NUMBER_REGEX,
} from 'constants/regexConstants'

const validateLiveAccountApplicationBankAccountInfoForm = (values, props) => {
  const bankDocumentName = get(props, 'bankDocumentName', '')
  const {
    bankAccountData = {},
  } = values

  const {
    name,
    accountType,
    bankCode,
    accountNumber,
    transitNumber,
    institutionNumber,
  } = bankAccountData

  return {
    bankAccountData: {
      name: fieldEmpty(name, 'Account Name'),
      bankCode: validateBankCode({ bankCode, name: 'Routing Number' }),
      accountNumber: validateMaskedBankAccountNumber({ accountNumber, name: 'Account Number' }),
      accountType: fieldEmpty(accountType, 'Account Type'),
      transitNumber: invalidOrEmptyRegex({ field: transitNumber, name: 'Transit Number', regex: TRANSIT_NUMBER_REGEX }),
      institutionNumber: invalidOrEmptyRegex({ field: institutionNumber, name: 'Institution Number', regex: INSTITUTION_NUMBER_REGEX }),
      bankDocument: isEmpty(bankDocumentName) && BANK_ACCOUNT_VERIFICATION_DOCUMENT_UPLOAD_ERROR,
    },
  }
}

export default validateLiveAccountApplicationBankAccountInfoForm
