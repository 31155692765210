import './ApplicationNewS.scss'
import React from 'react'
import FlowsC from 'components/Shared/Flows/FlowsC'
import ApplicationNewConfirmation from 'components/Customer/Pages/ApplicationNew/ApplicationNewConfirmation'
import AssociatedIdentitiesForm from 'components/Customer/Forms/AssociatedIdentitiesForm/AssociatedIdentitiesForm'
import AssociatedIdentityDisplayData from 'components/Customer/Forms/AssociatedIdentitiesForm/AssociatedIdentityDisplayData'
import BankAccountForm from 'components/Customer/Forms/BankAccountForm/BankAccountForm'
import BankAccountDisplayData from 'components/Customer/Forms/BankAccountForm/BankAccountDisplayData'
import EnableProcessorsForm from 'components/Customer/Forms/EnableProcessorsForm/EnableProcessorsForm'
import ProcessorsDisplayData from 'components/Customer/Forms/EnableProcessorsForm/ProcessorsDisplayData'
import IdentityFormC from 'components/Customer/Forms/IdentityForm/IdentityFormC'
import IdentityDisplayData from 'components/Customer/Forms/IdentityForm/IdentityDisplayData'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import isPlatform from 'utilities/is/isPlatform'

const ApplicationNew = () => {
  const isElavon = isPlatform('elavonpartnerconnect')

  const steps = [
    {
      name: isElavon ? 'Application' : 'Owner Identity',
      iconName: 'fa-user',
      component: IdentityFormC,
      props: {
        submitLabel: 'Next',
        skipIdentity: isElavon,
        showSkipIdentity: !isElavon,
      },
      dataKey: 'ownerIdentity',
      getDisplayData: IdentityDisplayData,
    },
    {
      name: 'Bank Account',
      iconName: 'fa-university',
      component: BankAccountForm,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: 'bankAccount',
      getDisplayData: BankAccountDisplayData,
      hidden: isElavon,
    },
    {
      name: 'Processors',
      iconName: 'fa-cog',
      component: EnableProcessorsForm,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: 'processors',
      getDisplayData: ProcessorsDisplayData,
    },
    {
      name: 'Associated Identities',
      iconName: 'fa-store-alt',
      component: AssociatedIdentitiesForm,
      props: {
        submitLabel: 'Next',
        leftButton: true,
      },
      dataKey: 'associatedIdentity',
      getDisplayData: AssociatedIdentityDisplayData,
      optional: true,
      hidden: isElavon,
    },
    {
      name: 'Confirmation',
      iconName: 'fa-check',
      component: ApplicationNewConfirmation,
      props: {
        submitLabel: 'Submit',
        leftButton: true,
      },
    },
  ]

  return (
    <div className='ApplicationNew'>
      <HeaderC
        title='Create New Application'
        breadcrumbs
      />

      <FlowsC flowClassName='new-application-flows' steps={steps} />
    </div>
  )
}

export default ApplicationNew
