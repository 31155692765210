import paymentsAPI from 'utilities/api/paymentsAPI'
import { SUBSCRIPTION_PLANS } from 'constants/apiConstants'
import { SUBSCRIPTION_PLANS as SUBSCRIPTION_PLANS_SERVICE } from 'constants/pathConstants'

const getSubscriptionPlansAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    path: SUBSCRIPTION_PLANS,
    queries,
    credentials,
    meta,
    service: SUBSCRIPTION_PLANS_SERVICE,
  })
}

export default getSubscriptionPlansAPI
