import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_SETTINGS } from 'constants/apiConstants'

const patchComplianceFormAPI = ({ queries, meta, values }) => {
  return dashboardAPI.put({
    values,
    queries,
    meta,
    path: COMPLIANCE_SETTINGS,
  })
}

export default patchComplianceFormAPI
