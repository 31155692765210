import './DataSectionV2S.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DictionaryListV2 from 'components/Customer/Shared/Page/DictionaryList/DictionaryListV2'
import Button from 'components/Shared/Button/Button'
import { t } from 'constants/language/languageConstants'
import { EMPTY_DATA_SECTION } from 'constants/pageSectionContants'
import filter from 'lodash/filter'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'

const DataSectionV2 = ({
  data = [],
  largeTitle = '',
  smallTitle = '',
  subtitle,
  type = 'card',
  idKey = '',
  idValue = '',
  displayDataVertical = false,
  headerActions = [],
  oneColumn = false,
  asideTitle = '',
  title = 'no-title',
}) => {
  const filteredData = filter(data, ({ condition = true }) => condition === true)

  return (
    <Fragment>
      <div className={`data-section ${type} ${(isEqual(data, EMPTY_DATA_SECTION)) ? 'empty-data-section' : ''}`}>
        { largeTitle && (
          <div className='header'>
            <div className='flex items-center space-between'>
              <div className='left-header-section flex items-center'>
                <h5 className='large-title'>{t(largeTitle)}</h5>
                {idKey && idValue && (
                  <ClickToCopyC
                    className='page-section'
                    label={t(idKey)}
                    content={idValue}
                  />
                )}
                {asideTitle && <div className='aside-title'>{t(asideTitle)}</div>}
              </div>

              <div className='header-actions'>
                {map(headerActions, ({ label, link, onClick }, index) => {
                  return link ?
                    <a className='text-link' href={link} key={`${title}-action-link-${index}`}><Button variant='secondary' label={t(label)} /></a> :
                    <Button variant='secondary' onClick={onClick} key={`${title}-action-button-${index}`} label={t(label)} />
                })}
              </div>
            </div>

            {subtitle && <div className='subtitle'>{t(subtitle)}</div> }
          </div>
        )}

        {smallTitle && <h6 className='small-title'>{t(smallTitle)}</h6>}

        <DictionaryListV2
          data={filteredData}
          displayDataVertical={displayDataVertical}
          oneColumn={oneColumn}
        />
      </div>
    </Fragment>
  )
}

DataSectionV2.propTypes = {
  data: PropTypes.array,
  largeTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  smallTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.string,
  idKey: PropTypes.string,
  idValue: PropTypes.string,
  headerActions: PropTypes.array,
  oneColumn: PropTypes.bool,
  asideTitle: PropTypes.string,
  title: PropTypes.string,
}

export default DataSectionV2
