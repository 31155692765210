import getMany from 'utilities/get/getMany'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  invalidRegex,
  validateAddress,
  validateBankAccountNumber,
  validateBankCode,
  validateCardExpirationDate,
  validateCardNumber,
} from 'utilities/validate'

import {
  CVV_REGEX,
  EMAIL_REGEX,
  INSTITUTION_NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  TRANSIT_NUMBER_REGEX,
  USA_POSTAL_CODE_REGEX,
} from 'constants/regexConstants'

import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE,
  PHONE_NUMBER_LENGTH_VALIDATION_ERROR,
  BUSINESS_NAME,
  DOING_BUSINESS_AS,
  BUSINESS_PHONE,
  NAME_ON_CARD,
  CARD_NUMBER,
  EXPIRATION_DATE,
  CVV,
  COUNTRY,
  ACCOUNT_NUMBER,
  ROUTING_NUMBER,
  TRANSIT_NUMBER,
  INSTITUTION_NUMBER,
  ACCOUNT_TYPE,
  TYPE,
} from 'constants/language/languageConstants'

const validateAddRecipientForm = (values) => {
  const {
    type,
    firstName,
    lastName,
    email,
    phone,
    personalAddress,
    businessName,
    doingBusinessAs,
    businessPhone,
    businessAddress,
    paymentInstrument,
  } = values

  const [
    nameOnCard,
    cardNumber,
    expiration,
    cvv,
    postalCode,
    paymentInstrumentCountry,
    accountNumber,
    routingNumber,
    transitNumber,
    institutionNumber,
    accountType,
  ] = getMany(paymentInstrument, [
    'nameOnCard',
    'cardNumber',
    'expiration',
    'cvv',
    'postalCode',
    'country',
    'accountNumber',
    'routingNumber',
    'transitNumber',
    'institutionNumber',
    'accountType.value',
  ])

  return {
    type: fieldEmpty(type, TYPE),
    firstName: fieldEmpty(firstName, FIRST_NAME),
    lastName: fieldEmpty(lastName, LAST_NAME),
    email: invalidRegex({ field: email, name: EMAIL, regex: EMAIL_REGEX }),
    phone: invalidRegex({ field: phone, name: PHONE, regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
    personalAddress: validateAddress({ address: personalAddress }),
    businessName: fieldEmpty(businessName, BUSINESS_NAME),
    doingBusinessAs: fieldEmpty(doingBusinessAs, DOING_BUSINESS_AS),
    businessPhone: invalidRegex({ field: businessPhone, name: BUSINESS_PHONE, regex: PHONE_NUMBER_REGEX, customErrorMessage: PHONE_NUMBER_LENGTH_VALIDATION_ERROR }),
    businessAddress: validateAddress({ address: businessAddress }),
    paymentInstrument: {
      nameOnCard: fieldEmpty(nameOnCard, NAME_ON_CARD),
      cardNumber: validateCardNumber({ field: cardNumber, name: CARD_NUMBER }),
      expiration: validateCardExpirationDate({ field: expiration, name: EXPIRATION_DATE }),
      cvv: invalidOrEmptyRegex({ field: cvv, name: CVV, regex: CVV_REGEX, customErrorMessage: 'CVV must be 3 or 4 digits.' }),
      postalCode: invalidOrEmptyRegex({ field: postalCode, name: 'Zip Code', regex: USA_POSTAL_CODE_REGEX, customErrorMessage: 'Zip Code must be exactly 5 digits' }),
      country: fieldEmpty(paymentInstrumentCountry, COUNTRY),
      accountNumber: validateBankAccountNumber({ accountNumber, name: ACCOUNT_NUMBER }),
      routingNumber: validateBankCode({ bankCode: routingNumber, name: ROUTING_NUMBER }),
      transitNumber: invalidOrEmptyRegex({ field: transitNumber, name: TRANSIT_NUMBER, regex: TRANSIT_NUMBER_REGEX, customErrorMessage: 'Transit Number must be 5 digits.' }),
      institutionNumber: invalidOrEmptyRegex({ field: institutionNumber, name: INSTITUTION_NUMBER, regex: INSTITUTION_NUMBER_REGEX, customErrorMessage: 'Institution Number must be 3 digits.' }),
      accountType: fieldEmpty(accountType, ACCOUNT_TYPE),
    },
  }
}

export default validateAddRecipientForm
