import { GET_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getPaymentLinkRequest = ({
  paymentLinkId,
  credentials,
}) => createAction({
  type: GET_PAYMENT_LINK_F_REQUEST,
  id: paymentLinkId,
  credentials,
})

export default getPaymentLinkRequest
