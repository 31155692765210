import hideModalAction from 'utilities/actions/hideModalAction'
import redirectRequest from 'utilities/actions/redirectRequest'
import isArrayString from 'utilities/is/isArrayString'
import convertStringToArray from 'utilities/convert/convertStringToArray'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PLATFORM_PROCESSOR_CONFIG_PATH } from 'constants/pathConstants'
import { SYSTEM_CONFIG } from 'constants/configConstants'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'

import {
  EDIT_SYSTEM_CONFIG,
  EDIT_APPLICATION_CONFIG,
} from 'constants/amplitudeConstants'

const submitEditProcessorConfigForm = (values, dispatch, props) => {
  const {
    actionType,
    credentials,
    platformId,
    sectionId,
    processorConfigId,
    configKey,
    processorType,
  } = props

  let configValue = get(values, 'configPair[0].value')
  const amplitudeEventType = sectionId === SYSTEM_CONFIG ? EDIT_SYSTEM_CONFIG : EDIT_APPLICATION_CONFIG

  // TODO: in the future account for array values a different way - need feedback from Matty for design
  if (isArrayString(configValue)) {
    configValue = convertStringToArray(configValue)
  }

  dispatch({
    type: actionType,
    payload: {
      values: {
        sectionId,
        configKey,
        configValue,
        processorType,
      },
      id: processorConfigId,
      credentials,
    },
    meta: {
      platformId,
      successCallback: ({ newValues }) => {
        const newProcessorConfigId = head(keys(newValues))

        const credentialId = get(credentials, 'id')
        const platformProcessorConfigPath = PLATFORM_PROCESSOR_CONFIG_PATH({ platformId, processorConfigId: newProcessorConfigId, credentialId })
        dispatch(hideModalAction())
        dispatch(redirectRequest({ path: platformProcessorConfigPath }))
      },
      actionId: sendAmplitudeActionEvent(amplitudeEventType, {
        configKey,
        configValue,
        processorType,
        credentials,
      }),
    },
  })
}

export default submitEditProcessorConfigForm
