import nextFlowStep from 'utilities/nextFlowStep'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_ACCESS_FORM_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_PRICING_TYPE_FOR_LIVE_ACCOUNT_APPLICATION_FORM } from 'constants/amplitudeConstants'
import get from 'lodash/get'

const submitLiveAccountApplicationPricingForm = (values, dispatch, props = {}) => {
  const pricingType = get(values, 'pricingType')
  const accessFormId = get(props, 'accessFormId')

  dispatch({
    type: PATCH_ACCESS_FORM_F_REQUEST,
    payload: {
      id: accessFormId,
      values,
    },
    meta: {
      successCallback: ({ newValues }) => {
        nextFlowStep()
      },
      actionId: sendAmplitudeActionEvent(UPDATE_PRICING_TYPE_FOR_LIVE_ACCOUNT_APPLICATION_FORM, {
        accessFormId,
        pricingType,
      }),
    },
  })
}

export default submitLiveAccountApplicationPricingForm
