import React, { Component } from 'react'
import { connect } from 'react-redux'
import GenericModal from './GenericModal'
import removeUndefined from 'utilities/remove/removeUndefined'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const activeStep = get(state, 'flowsR.activeStep')

  return removeUndefined({
    activeStep,
  })
}

class GenericModalC extends Component {
  render() {
    return (
      <GenericModal {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(GenericModalC)
