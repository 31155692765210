import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantVerifications from './MerchantVerifications'
import { getMerchantVerificationsSelector } from 'state-layer/selectors'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMerchantVerificationsRequest from 'utilities/actions/get/getMerchantVerificationsRequest'
import { FETCHING } from 'constants/reducerConstants'
import { CREATE_VERIFICATION } from 'constants/language/languageConstants'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import {
  CREATE_MERCHANT_VERIFICATION,
  VERIFICATION_RESULT_MODAL,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const merchantId = get(props, 'merchantId')
  const isFetching = get(state, `merchantVerificationsR.${FETCHING}`)
  const verifications = Object.values(getMerchantVerificationsSelector(state, merchantId))

  return {
    credentials,
    merchantId,
    isFetching,
    verifications,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantVerifications: ({ credentials, merchantId }) => dispatch(getMerchantVerificationsRequest({ credentials, merchantId })),
    showMerchantVerificationModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_MERCHANT_VERIFICATION, modalProps })),
    showVerificationResultsModal: (modalProps) => dispatch(showModalAction({ modalType: VERIFICATION_RESULT_MODAL, modalProps })),
  }
}


class MerchantVerificationsC extends Component {
  componentDidMount() {
    const { credentials, merchantId, getMerchantVerifications } = this.props

    if (merchantId) {
      getMerchantVerifications({
        credentials,
        merchantId,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { merchantId: prevMerchantId } = prevProps
    const { merchantId, getMerchantVerifications, credentials } = this.props

    if (!isEqual(prevMerchantId, merchantId) && merchantId) {
      getMerchantVerifications({ credentials, merchantId })
    }
  }

  render() {
    const {
      merchantId,
      showMerchantVerificationModal,
      showVerificationResultsModal,
    } = this.props

    const actions = [
      {
        label: CREATE_VERIFICATION,
        action: () => {
          showMerchantVerificationModal({
            merchantId,
          })
        },
        className: 'create-merchant-verification-button',
      },
    ]

    return (
      <MerchantVerifications
        {...this.props}
        showVerificationResultsModal={showVerificationResultsModal}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantVerificationsC)
