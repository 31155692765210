import './LiveAccountApplicationReviewAndSubmitFormS.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import AccessFormReviewC from 'components/Customer/Pages/AccessFormReview/AccessFormReviewC'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import LiveAccountApplicationAdditionalVerificationNeededDetails from 'components/Customer/Shared/Display/LiveAccountApplicationAdditionalVerificationNeededDetails/LiveAccountApplicationAdditionalVerificationNeededDetails'
import validateLiveAccountApplicationReviewAndSubmitForm from 'utilities/validate/validateLiveAccountApplicationReviewAndSubmitForm'
import submitLiveAccountApplicationReviewAndSubmitForm from 'utilities/submit/submitLiveAccountApplicationReviewAndSubmitForm'
import prevFlowStep from 'utilities/prevFlowStep'
import getCurrentPlatform from 'utilities/get/getCurrentPlatform'
import { LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM } from 'constants/formConstants'
import { ALERT_ICON } from 'constants/iconConstants'
import { LIVE_HOST } from 'constants/dashboardServiceConstants'
import isEqual from 'lodash/isEqual'

import {
  IN_PROGRESS_BE_VALUE,
  ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE,
} from 'constants/statusConstants'

import {
  initializePersonaClient,
  PRODUCTION_ENV,
  SANDBOX_ENV,
} from 'constants/personaConstants'

import {
  PREVIOUS_STEP,
  EXIT_APPLICATION_FORM,
  SUBMIT_APPLICATION_FORM,
  LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE,
  UPDATED,
  REVIEW_AND_SUBMIT,
  HOSTED_LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE,
  ADDITIONAL_VERIFICATION_NEEDED,
  HOSTED_LIVE_ACCOUNT_ADDITIONAL_VERIFICATION_NEEDED_MESSAGE,
  VERIFY,
  PERSONAL_IDENTITY_VERIFICATION,
  REQUIRED,
  VERIFICATION_STATUS,
  SUBMITTED,
  CONTROL_PERSON_PREFERRED,
  PROVIDE_GOVERNMENT_ISSUED_ID_AND_SELFIE,
  COLLECTING_GOVERNMENT_ID_AND_SELFIE,
  USE_A_LAPTOP_OR_MOBILE_DEVICE,
} from 'constants/language/languageConstants'

const LiveAccountApplicationReviewAndSubmitForm = ({
  goToStep = () => {},
  handleSubmit = () => {},
  showExitApplicationFormModal,
  accessFormId = '',
  confirmationCheckboxString = '',
  invalid = false,
  status,
  displayUpdatedAt,
  showUploadFileModal,
  isHostedAccessForm = false,
  personaInquiryOnComplete = () => {},
  inquiryId: formInquiryId,
  showPersonaInquirySection = true,
}) => {
  const additionalVerificationNeeded = isEqual(status, ADDITIONAL_VERIFICATION_REQUIRED_BE_VALUE)
  const currentPlatform = getCurrentPlatform() || {}
  const { credentialsHostV2 } = currentPlatform
  const isProduction = credentialsHostV2 === LIVE_HOST
  const [client, setClient] = useState(null)
  const [clientLoaded, setClientLoaded] = useState(false)

  useEffect(() => {
    if (!client) {
      setClient(initializePersonaClient({
        onReady: () => setClientLoaded(true),
        onComplete: personaInquiryOnComplete,
        environmentId: isProduction ? PRODUCTION_ENV : SANDBOX_ENV,
      }))
    }
  }, [])

  const openClient = () => {
    if (clientLoaded) {
      client.open()
    }
  }

  const identityVerificationActions = !formInquiryId ? [{
    label: VERIFY,
    variant: 'primary',
    action: () => openClient(),
  }] : []

  return (
    <form className='LiveAccountApplicationReviewAndSubmitForm' onSubmit={handleSubmit(submitLiveAccountApplicationReviewAndSubmitForm)}>
      <div className='form-header'>
        <h3>{REVIEW_AND_SUBMIT}</h3>
      </div>

      <div className='form-content'>
        {isEqual(status, IN_PROGRESS_BE_VALUE) && (
        <div className='review-message-container'>
          <div className='review-message'>{isHostedAccessForm ? HOSTED_LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE : LIVE_ACCOUNT_APPLICATION_REVIEW_MESSAGE}</div>
        </div>
        )}

        {additionalVerificationNeeded && !isHostedAccessForm && (
        <LiveAccountApplicationAdditionalVerificationNeededDetails
          rightTitle={`${UPDATED} ${displayUpdatedAt}`}
        />
        )}

        {additionalVerificationNeeded && isHostedAccessForm && (
        <div className='additional-verification-needed-container flex'>
          <div className='title-container'>
            <i className={`icon fa fa-${ALERT_ICON}`} />
          </div>
          <div className='message-container'>
            <h6>{ADDITIONAL_VERIFICATION_NEEDED}</h6>
            {HOSTED_LIVE_ACCOUNT_ADDITIONAL_VERIFICATION_NEEDED_MESSAGE}
          </div>
        </div>
        )}

        {showPersonaInquirySection && (
        <PageSectionV2C
          smallTitle={PERSONAL_IDENTITY_VERIFICATION}
          asideTitle={<div className='identity-verification-badge'><i className='fas fa-star' /><span className='p-2-bold'>{REQUIRED}</span></div>}
          actions={identityVerificationActions}
          className='personal-identity-verification'
        >
          { formInquiryId ? (
            <div className='mt-xl flex gap-32'>
              <span>{VERIFICATION_STATUS}</span>
              <span>{SUBMITTED}</span>
            </div>
          ) : (
            <>
              <div className='label-2 secondary'>{COLLECTING_GOVERNMENT_ID_AND_SELFIE}</div>

              <div className='identity-verification-details'>
                <div>
                  <i className='far fa-id-card' />
                  <span className='p-2'>{PROVIDE_GOVERNMENT_ISSUED_ID_AND_SELFIE}</span>
                </div>

                <div>
                  <i className='far fa-user-shield' />
                  <span className='p-2'>{CONTROL_PERSON_PREFERRED}</span>
                </div>

                <div>
                  <i className='far fa-camera' />
                  <span className='p-2'>{USE_A_LAPTOP_OR_MOBILE_DEVICE}</span>
                </div>

                <Field name='inquiryId' component='input' type='hidden' />
              </div>
            </>
          )}
        </PageSectionV2C>
        )}

        <AccessFormReviewC
          accessFormId={accessFormId}
          goToStep={goToStep}
          showUploadFileModal={showUploadFileModal}
          isHostedAccessForm={isHostedAccessForm}
        />

        <div className='termsOfServiceCheckbox'>
          <Field
            name='agreementAccepted'
            checkboxLabel={confirmationCheckboxString}
            component={CheckboxInput}
          />
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          <div className='exit-and-save-container'>
            {showExitApplicationFormModal && (<Button onClick={showExitApplicationFormModal} label={EXIT_APPLICATION_FORM} variant='ghost' size='large' />)}
            <Button type='submit' disabled={invalid} label={SUBMIT_APPLICATION_FORM} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

LiveAccountApplicationReviewAndSubmitForm.propTypes = {
  goToStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  showExitApplicationFormModal: PropTypes.func,
  accessFormId: PropTypes.string,
  confirmationCheckboxString: PropTypes.string,
  invalid: PropTypes.bool,
  displayUpdatedAt: PropTypes.string,
  status: PropTypes.string,
  isHostedAccessForm: PropTypes.bool,
  personaInquiryOnComplete: PropTypes.func,
  inquiryId: PropTypes.string,
  showPersonaInquirySection: PropTypes.bool,
}

export default reduxForm({
  form: LIVE_ACCOUNT_APPLICATION_REVIEW_AND_SUBMIT_FORM,
  validate: validateLiveAccountApplicationReviewAndSubmitForm,
})(LiveAccountApplicationReviewAndSubmitForm)
