import './GooglePlaceDetailsS.scss'
import React, { useState, useEffect } from 'react'
import { GoogleApiWrapper } from 'google-maps-react'
import PropTypes from 'prop-types'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Loader from 'components/Shared/Loader/Loader'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'
import { GOOGLE_MAP_API_KEY } from 'constants/googleMapConstants'
import { EXTERNAL_LINK } from 'constants/iconConstants'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  compareTwoStrings,
  findBestMatch,
} from 'string-similarity'

import {
  BUSINESS_ADDRESS,
  BUSINESS_HOURS,
  BUSINESS_INFORMATION,
  BUSINESS_NAME,
  BUSINESS_STATUS,
  INTERNATIONAL_PHONE_NUMBER,
  OVERALL_RATING,
  PHONE_NUMBER,
  PHOTOS,
  RATING,
  REVIEWS,
  VIEW_ALL,
  WEBSITE,
} from 'constants/language/languageConstants'

const GooglePlaceDetails = ({
  address,
}) => {
  const [place, setPlace] = useState({})
  const [mapLoaded, setMapLoaded] = useState(false)

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: GOOGLE_MAP_API_KEY,
    debounce: 500,
  })

  useEffect(() => {
    getPlacePredictions({
      input: address,
    })
  }, [])

  useEffect(() => {
    if (placePredictions && placePredictions.length > 0) {
      const placeDescriptions = map(placePredictions, ({ description }) => toLower(description))
      const bestMatch = findBestMatch(toLower(address), placeDescriptions)
      const matchComparison = compareTwoStrings(toLower(address), toLower(bestMatch.bestMatch.target))
      const bestMatchIndex = get(bestMatch, 'bestMatchIndex', 0)

      if (matchComparison > 0.5) {
        const placeId = get(placePredictions, `[${bestMatchIndex}].place_id`)

        placesService.getDetails({ placeId }, (placeDetails) => {
          setPlace(placeDetails)
        })
      }
      // once the google api call has been made, set the map to loaded
      setMapLoaded(true)
    }
  }, [placePredictions])

  const [
    name = '-',
    businessStatus = '-',
    formattedAddress = '-',
    formattedPhoneNumber = '-',
    internationalPhoneNumber = '-',
    photos,
    rating = '-',
    reviews,
    website = '',
    weekdayText,
    url,
  ] = getMany(place, [
    'name',
    'business_status',
    'formatted_address',
    'formatted_phone_number',
    'international_phone_number',
    'photos',
    'rating',
    'reviews',
    'website',
    'current_opening_hours.weekday_text',
    'url',
  ])

  const businessInformationData = [
    {
      type: 'data',
      data: [
        { label: BUSINESS_NAME, value: name },
        { label: BUSINESS_ADDRESS, value: formattedAddress },
        { label: PHONE_NUMBER, value: formattedPhoneNumber },
        { label: INTERNATIONAL_PHONE_NUMBER, value: internationalPhoneNumber },
      ],
    },
    {
      type: 'data',
      data: [
        { label: BUSINESS_STATUS, value: businessStatus },
        { label: WEBSITE, value: website ? <a href={website} target='blank'>{website}</a> : '-' },
        { label: OVERALL_RATING, value: rating },
        { label: BUSINESS_HOURS, value: map(weekdayText, (text, index) => <div key={`weekday-text-${index}`}>{text}</div>) },
      ],
    },
  ]

  return (
    <>
      { !mapLoaded && <Loader /> }
      { mapLoaded && (
        <div className='GooglePlaceDetails'>
          <PageSectionV2C
            smallTitle={BUSINESS_INFORMATION}
            columns={businessInformationData}
            className='business-information-page-sections'
          />

          { !isEmpty(photos) && (
          <div className='photos'>
            <h6 className='photos-title'>{PHOTOS}</h6>

            <div className='photos-container flex'>
              { map(photos, (photo, index) => {
                const getURL = get(photo, 'getUrl', () => (''))
                const photoURL = getURL()

                return (
                  <div className='photo' key={`photo-${index}`}>
                    <img src={photoURL} height={200} alt={`Business ${index}`} />
                  </div>
                )
              })}
            </div>
          </div>
          )}

          { !isEmpty(reviews) && (
          <div className='reviews'>
            <div className='reviews-header flex space-between'>
              <h6 className='reviews-title'>{REVIEWS}</h6>
              <a className='view-all-link' href={url} target='blank'>{VIEW_ALL}<i className={`fa fa-${EXTERNAL_LINK}`} /></a>
            </div>

            <div className='reviews-container'>
              { map(reviews, (review, index) => {
                const [
                  authorName,
                  reviewRating,
                  relativeTimeDescription,
                  text,
                ] = getMany(review, [
                  'author_name',
                  'rating',
                  'relative_time_description',
                  'text',
                ])

                return (
                  <div className='review' key={`review-${index}`}>
                    <div className='review-title flex items-center'>
                      <div>{authorName}</div>
                      <div className='dot' />
                      <div>{RATING}: {reviewRating}</div>
                      <div className='dot' />
                      <div>{relativeTimeDescription}</div>
                    </div>
                    <div className='review-body'>{`"${text}"`}</div>
                  </div>
                )
              })}
            </div>
          </div>
          )}
        </div>
      )}
    </>
  )
}

GooglePlaceDetails.propTypes = {
  address: PropTypes.string,
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(GooglePlaceDetails)
