import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM_TEMPLATES } from 'constants/apiConstants'

const postComplianceFormTemplateAPI = ({ values, credentials, meta }) => {
  return dashboardAPI.post({
    meta,
    values,
    credentials,
    path: COMPLIANCE_FORM_TEMPLATES,
  })
}

export default postComplianceFormTemplateAPI
