import removeUndefined from 'utilities/remove/removeUndefined'
import backendIdentityModel from 'utilities/create/models/backendIdentityModel'
import backendBankAccountModel from 'utilities/create/models/backendBankAccountModel'
import backendAssociatedIdentityModel from 'utilities/create/models/backendAssociatedIdentityModel'
import keys from 'lodash/keys'

const backendApplicationOModel = ({ values }) => {
  const {
    ownerIdentity = {},
    bankAccount = {},
    associatedIdentity = {},
    processors = {},
  } = values

  const { entity } = backendIdentityModel({ values: ownerIdentity })
  const bankAccountInfo = backendBankAccountModel({ values: bankAccount })
  const associatedIdentityPayload = backendAssociatedIdentityModel({ values: associatedIdentity })

  return removeUndefined({
    entity,
    bankAccountInfo,
    associatedIdentityPayload,
    processors: keys(processors),
  })
}

export default backendApplicationOModel
