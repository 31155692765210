import {
  REQUIRED,
  OPTIONAL,
  NOT_COLLECTED,
  DEFAULT,
  ONE_MONTH as ONE_MONTH_LABEL,
  THREE_MONTHS as THREE_MONTHS_LABEL,
  SIX_MONTHS as SIX_MONTHS_LABEL,
  NEVER as NEVER_LABEL,
  TRUE,
  FALSE,
} from 'constants/language/languageConstants'

export const BIOMETRIC_COLLECTION_REQUIRED = 'REQUIRED'
export const BIOMETRIC_COLLECTION_OPTIONAL = 'OPTIONAL'
export const BIOMETRIC_COLLECTION_NOT_COLLECTED = 'NOT_COLLECTED'
export const BIOMETRIC_COLLECTION_DEFAULT = 'DEFAULT'

export const ONE_MONTH = 'ONE_MONTH'
export const THREE_MONTHS = 'THREE_MONTHS'
export const SIX_MONTHS = 'SIX_MONTHS'
export const NEVER = 'NEVER'

export const BIOMETRIC_COLLECTION_TYPES = [
  BIOMETRIC_COLLECTION_REQUIRED,
  BIOMETRIC_COLLECTION_OPTIONAL,
  BIOMETRIC_COLLECTION_NOT_COLLECTED,
  BIOMETRIC_COLLECTION_DEFAULT,
]

export const BIOMETRIC_COLLECTION_TYPE_LABELS = {
  [BIOMETRIC_COLLECTION_REQUIRED]: REQUIRED,
  [BIOMETRIC_COLLECTION_OPTIONAL]: OPTIONAL,
  [BIOMETRIC_COLLECTION_NOT_COLLECTED]: NOT_COLLECTED,
  [BIOMETRIC_COLLECTION_DEFAULT]: DEFAULT,
}

export const BIOMETRIC_COLLECTION_TYPE_DESCRIPTIONS = {
  [BIOMETRIC_COLLECTION_REQUIRED]: 'Require photo IDs and selfies in all onboarding forms under this application.',
  [BIOMETRIC_COLLECTION_OPTIONAL]: 'Collect photo IDs and selfies optionally in all onboarding forms under this application.',
  [BIOMETRIC_COLLECTION_NOT_COLLECTED]: 'Do not collect photo ids and selfies in any onboarding forms under this application.',
  [BIOMETRIC_COLLECTION_DEFAULT]: 'Use onboarding form\'s default settings for collecting photo IDs and selfies.',
}

export const BIOMETRIC_COLLECTION_TYPE_OPTIONS = BIOMETRIC_COLLECTION_TYPES.map((type) => ({
  label: BIOMETRIC_COLLECTION_TYPE_LABELS[type],
  value: type,
}))

export const CONTINUOUS_MONITORING_LABELS = {
  [ONE_MONTH]: ONE_MONTH_LABEL,
  [THREE_MONTHS]: THREE_MONTHS_LABEL,
  [SIX_MONTHS]: SIX_MONTHS_LABEL,
  [NEVER]: NEVER_LABEL,
}

export const adverseMediaReviewCadenceOptions = [
  {
    label: CONTINUOUS_MONITORING_LABELS[ONE_MONTH],
    value: ONE_MONTH,
  },
  {
    label: CONTINUOUS_MONITORING_LABELS[THREE_MONTHS],
    value: THREE_MONTHS,
  },
  {
    label: CONTINUOUS_MONITORING_LABELS[SIX_MONTHS],
    value: SIX_MONTHS,
  },
  {
    label: CONTINUOUS_MONITORING_LABELS[NEVER],
    value: NEVER,
  }
]

export const sanctionsScreeningCadenceOptions = [
  {
    label: CONTINUOUS_MONITORING_LABELS[ONE_MONTH],
    value: ONE_MONTH,
  },
  {
    label: CONTINUOUS_MONITORING_LABELS[NEVER],
    value: NEVER,
  }
]

export const RECIPIENT_SETTINGS_LABELS = {
  true: TRUE,
  false: FALSE,
}
