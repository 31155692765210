import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import EditIdentityProcessingInformationForm from './EditIdentityProcessingInformationForm'
import getUnderwritingProfileByEntityIdRequest from 'utilities/actions/get/getUnderwritingProfileByEntityIdRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import selectOption from 'utilities/forms/selectOption'
import { isRolePartner } from 'utilities/validate/checkRoleCredentials'
import { getUnderwritingProfileByEntityId } from 'state-layer/selectors'
import getMany from 'utilities/get/getMany'
import { IDENTITY_PROCESSING_INFO_FORM } from 'constants/formConstants'
import { finixMCCsSelectOptions } from 'constants/mccConstants'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'

const mapStateToProps = (state, props) => {
  const credentials = get(props, 'credentials')
  const isRolePartnerCredential = isRolePartner({ credentials })
  const formSelector = formValueSelector(IDENTITY_PROCESSING_INFO_FORM)
  const formMCCValue = formSelector(state, 'mcc')
  const mcc = formSelector(state, 'mcc')
  const showTaxAuthority = mcc === '9311'
  const identity = get(props, 'identity')

  const [
    identityId,
    applicationId,
    displayMaxTransactionAmount,
    displayMaxACHTransactionAmount,
    identityMCC,
  ] = getMany(identity, [
    'id',
    'applicationId',
    'displayMaxTransactionAmount',
    'displayMaxACHTransactionAmount',
    'mcc',
  ])

  const underwritingProfile = get(props, 'applicationUnderwritingProfile') || getUnderwritingProfileByEntityId(state, applicationId)

  const [
    underwritingProfileId,
    applicationMaxTransactionAmount,
    applicationMaxACHTransactionAmount,
    applicationMCCDetails,
    displayApplicationMaxACHTransactionAmount,
    displayApplicationMaxTransactionAmount,
  ] = getMany(underwritingProfile, [
    'id',
    'maxTransactionAmount',
    'achMaxTransactionAmount',
    'mccDetails',
    'displayMaxTransactionAmount',
    'displayAchMaxTransactionAmount',
  ])

  const [
    applicationEnabledMCCs,
    allowAllMCCs,
  ] = getMany(applicationMCCDetails, [
    'enabledMCCs',
    'allowAllMCCs',
  ])

  const initialMCCFromList = find(finixMCCsSelectOptions, { value: identityMCC }) || { label: identityMCC, value: identityMCC }

  const initialValues = {
    mcc: initialMCCFromList,
    maxTransactionAmount: displayMaxTransactionAmount,
    achMaxTransactionAmount: displayMaxACHTransactionAmount,
  }

  return {
    credentials,
    isRolePartnerCredential,
    showTaxAuthority,
    applicationId,
    applicationMaxTransactionAmount,
    applicationMaxACHTransactionAmount,
    displayApplicationMaxACHTransactionAmount,
    displayApplicationMaxTransactionAmount,
    identityId,
    initialValues,
    applicationEnabledMCCs,
    allowAllMCCs,
    formMCCValue,
    underwritingProfileId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(hideModalAction()),
  getUnderwritingProfile: ({ credentials, queries }) => dispatch(getUnderwritingProfileByEntityIdRequest({ credentials, queries })),
})

class EditIdentityProcessingInformationFormC extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      applicationId,
      credentials,
      getUnderwritingProfile,
      underwritingProfileId,
    } = this.props

    const { underwritingProfileId: prevUnderwritingProfileId } = prevProps

    if (prevUnderwritingProfileId !== underwritingProfileId) {
      getUnderwritingProfile({ credentials, queries: { linked_to: applicationId } })
    }
  }

  render() {
    const {
      applicationEnabledMCCs,
      allowAllMCCs,
      formMCCValue,
    } = this.props

    const getApplicationEnabledMCCsSelectOptions = () => {
      return map(applicationEnabledMCCs, (enabledMCC) => {
        const mccFromList = find(finixMCCsSelectOptions, { value: enabledMCC })
        return selectOption(get(mccFromList, 'label'), enabledMCC)
      })
    }

    // show only the mccs enabled for the application/platform if enabledMCCs is present
    let mccListForModal = finixMCCsSelectOptions
    if (!allowAllMCCs) {
      mccListForModal = getApplicationEnabledMCCsSelectOptions()
    }

    const isInitialMCCOnList = find(finixMCCsSelectOptions, { value: formMCCValue })

    return (
      <EditIdentityProcessingInformationForm
        {...this.props}
        mccListForModal={mccListForModal}
        isInitialMCCOnList={isInitialMCCOnList}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIdentityProcessingInformationFormC)
