import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_FEE_PROFILE_RULES_V2,
} from 'constants/apiConstants'

const getFeeProfileRulesAPI = ({ values, credentials, meta }) => {
  const {
    applicationId,
  } = values

  return paymentsAPI.get({
    meta,
    credentials,
    path: APPLICATION_FEE_PROFILE_RULES_V2({ applicationId }),
    service: APPLICATIONS,
  })
}

export default getFeeProfileRulesAPI
