import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import get from 'lodash/get'

import {
  COMPLIANCE_SCORE,
  ID,
  RECOMMENDED_ACTION,
  RUN_DATE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(RUN_DATE, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={RUN_DATE} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(COMPLIANCE_SCORE, 'decision.scores.COMPLIANCE'),
  column(RECOMMENDED_ACTION, 'decision.recommendation', {
    formatter: ({ decision }) => <UnderwritingReviewStatus value={get(decision, 'recommendation')} />,
  }),
]

export default columnDescriptors
