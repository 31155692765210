import React from 'react'
import column from 'utilities/table/column'
import ColorcodedStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ColorcodedStatus'

import {
  certStatusMap,
  PENDING,
} from 'constants/statusConstants'

const columnDescriptors = [
  column('Application', 'name', { className: 'id' }),
  column('Status', 'status', {
    className: 'state',
    formatter: ({ status }) => <ColorcodedStatus value={status} data={certStatusMap} />,
  }),
  column('Expires', 'expiresAt', { className: 'date' }),
  // TODO: this is temp solution, rethink how allow specific column items in tables to have click functions that also have access to dispatch
  column('', '', {
    className: 'medium',
    actionIndex: ({ status }) => ((status === PENDING) ? 0 : 1),
    actionLabel: ({ status }) => ((status === PENDING) ? 'Resume Process' : 'Revoke'),
  }),
]

export default columnDescriptors
