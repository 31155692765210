import paymentsAPI from 'utilities/api/paymentsAPI'
import { DEVICE } from 'constants/apiConstants'

const getDeviceAPI = ({ id, credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    queries,
    path: DEVICE({ deviceId: id }),
    credentials,
  })
}

export default getDeviceAPI
