import './UpdateRecipientSettingsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import submitUpdateRecipientSettingsForm from 'utilities/submit/submitUpdateRecipientSettingsForm'
import { UPDATE_RECIPIENT_SETTINGS_FORM } from 'constants/formConstants'

import {
  CANCEL,
  ENABLE_BUSINESS_RECIPIENTS,
  ENABLE_INDIVIDUAL_RECIPIENTS,
  UPDATE,
} from 'constants/language/languageConstants'

const UpdateRecipientSettingsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  invalid = false,
  pristine = false,
  isUpdating = false,
}) => {
  return (
    <form className='UpdateRecipientSettingsForm' onSubmit={handleSubmit(submitUpdateRecipientSettingsForm)}>
      <div className='modal-content'>
        <Field
          name='personalRecipientsEnabled'
          className='personal-recipients-enabled'
          component={ToggleSwitchC}
          label={ENABLE_INDIVIDUAL_RECIPIENTS}
          formName={UPDATE_RECIPIENT_SETTINGS_FORM}
        />

        <Field
          name='businessRecipientsEnabled'
          className='business-recipients-enabled'
          component={ToggleSwitchC}
          label={ENABLE_BUSINESS_RECIPIENTS}
          formName={UPDATE_RECIPIENT_SETTINGS_FORM}
        />
      </div>

      <div className='buttons flex flex-end'>
        <Button variant='ghost' label={CANCEL} className='cancel-button' onClick={closeModal} />
        <Button type='submit' label={UPDATE} submitting={isUpdating} disabled={invalid || pristine} />
      </div>
    </form>
  )
}

UpdateRecipientSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default reduxForm({
  form: UPDATE_RECIPIENT_SETTINGS_FORM,
})(UpdateRecipientSettingsForm)
