import React from 'react'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'

import {
  TRANSACTION_MONITORING_TRANSACTION_COUNT,
  TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT,
  TRANSACTION_MONITORING_DISPUTE_COUNT,
  TRANSACTION_MONITORING_TOTAL_DISPUTE_AMOUNT,
  TRANSACTION_MONITORING_AVERAGE_DISPUTE_AMOUNT,
  TRANSACTION_MONITORING_DISPUTE_RATE,
} from 'constants/language/languageConstants'

const DisputeCards = ({ monitoringAlert }) => {
  const calculations = get(monitoringAlert, 'calculations', {})

  const [
    disputeCount,
    displayDisputeAmountDollarsSum,
    displayDisputeAmountDollarsAverage,
    transferCount,
    displayTransferAmountDollarsSum,
    displayTransferAmountDollarsAverage,
    displayDisputeRate,
  ] = getMany(calculations, [
    'disputeCount',
    'displayDisputeAmountDollarsSum',
    'displayDisputeAmountDollarsAverage',
    'transferCount',
    'displayTransferAmountDollarsSum',
    'displayTransferAmountDollarsAverage',
    'displayDisputeRate',
  ])

  return (
    <div className='custom-big-numbers grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_DISPUTE_COUNT}
        headerData={{
          value: disputeCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_DISPUTE_AMOUNT}
        headerData={{
          value: displayDisputeAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_DISPUTE_AMOUNT}
        headerData={{
          value: displayDisputeAmountDollarsAverage,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_DISPUTE_RATE}
        headerData={{
          value: displayDisputeRate,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TRANSACTION_COUNT}
        headerData={{
          value: transferCount,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_TOTAL_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsSum,
        }}
      />
      <CustomBigNumberC
        title={TRANSACTION_MONITORING_AVERAGE_TRANSACTION_AMOUNT}
        headerData={{
          value: displayTransferAmountDollarsAverage,
        }}
      />
    </div>
  )
}

export default DisputeCards
