import './DisableCustomRoleModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import DisableCustomRoleC from 'components/Customer/Pages/DisableCustomRole/DisableCustomRoleC'
import { DISABLE_CUSTOM_ROLE } from 'constants/language/languageConstants'

const DisableCustomRoleModal = ({
  credentials,
  roleId,
  roleName,
}) => {
  return (
    <GenericModal
      title={DISABLE_CUSTOM_ROLE}
      className='DisableCustomRoleModal'
      Component={DisableCustomRoleC}
      roleName={roleName}
      credentials={credentials}
      roleId={roleId}
    />
  )
}

DisableCustomRoleModal.propTypes = {
  roleId: PropTypes.string,
  roleName: PropTypes.string,
  credentials: PropTypes.object,
}

export default DisableCustomRoleModal
