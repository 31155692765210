import './EnableDisableWebhookS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'

import {
  ENABLE,
  DISABLE,
  CANCEL,
  ENABLE_WEBHOOK_CONFIRMATION_MSG,
  DISABLE_WEBHOOK_CONFIRMATION_MSG,
  DISABLE_WEBHOOK_DESCRIPTION,
  DISABLE_WEBHOOK_WITH_ACCEPTING_EVENTS_DESCRIPTION,
} from 'constants/language/languageConstants'

const EnableDisableWebhook = ({
  enabled = false,
  closeModal = () => {},
  toggleWebhookEnabled = () => {},
  isAcceptingEvents = false,
}) => {
  const label = enabled ? DISABLE : ENABLE

  return (
    <div className='EnableDisableWebhook'>
      <div className='confirmation-msg'>{enabled ? DISABLE_WEBHOOK_CONFIRMATION_MSG : ENABLE_WEBHOOK_CONFIRMATION_MSG}</div>
      {enabled && <div className='description'>{isAcceptingEvents ? DISABLE_WEBHOOK_WITH_ACCEPTING_EVENTS_DESCRIPTION : DISABLE_WEBHOOK_DESCRIPTION}</div>}

      <div className='btn-container flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button onClick={toggleWebhookEnabled} className='enable-disable-btn' label={label} />
      </div>
    </div>
  )
}

EnableDisableWebhook.propTypes = {
  enabled: PropTypes.bool,
  closeModal: PropTypes.func,
  toggleWebhookEnabled: PropTypes.func,
  isAcceptingEvents: PropTypes.bool,
}

export default EnableDisableWebhook
