import removeUndefined from 'utilities/remove/removeUndefined'

const backendMonitoringAlertModel = ({ values }) => {
  const {
    state,
  } = values

  return removeUndefined({
    state,
  })
}

export default backendMonitoringAlertModel
