import './NotificationSettingsFormS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import CheckboxInput from 'components/Shared/Inputs/CheckboxInput/CheckboxInput'
import Button from 'components/Shared/Button/Button'
import submitNotificationSettingsForm from 'utilities/submit/submitNotificationSettingsForm'
import map from 'lodash/map'
import keys from 'lodash/keys'
import values from 'lodash/values'
import get from 'lodash/get'

import {
  SAVE_CHANGES,
  EMAIL,
  DASHBOARD,
} from 'constants/language/languageConstants'

const NotificationSettingsForm = ({
  data = [],
  extraProps = {},
  submitLabel = SAVE_CHANGES,
}) => {
  const handleSubmitFunction = get(extraProps, 'handleSubmit')
  const pristine = get(extraProps, 'pristine')
  return (
    <form className='NotificationSettingsForm' onSubmit={handleSubmitFunction(submitNotificationSettingsForm)}>
      <table className='section'>
        {map(data, ({ title: header, data: notificationOptions, condition = true }) => {
          return (condition ? (
            <tbody>
              <tr>
                <th className='header-column bold'>{header}</th>
                <th className='dashboard-col bold'>{DASHBOARD}</th>
                <th className='email-col bold'>{EMAIL}</th>
              </tr>

              {map(notificationOptions, ({ id, title: subHeader, condition: show = true, noApp = false, noEmail = false }) => {
                return (show ? (
                  <tr>
                    <td>{subHeader}</td>

                    <td className='checkbox-column'>
                      <Field
                        name={`APP.${id}`}
                        component={CheckboxInput}
                        disabled={noApp}
                      />
                    </td>

                    <td className='checkbox-column'>
                      <Field
                        name={`EMAIL.${id}`}
                        component={CheckboxInput}
                        disabled={noEmail}
                      />
                    </td>
                  </tr>
                ) : null
                )
              })}
            </tbody>
          ) : null
          )
        })}
      </table>

      <div className='buttons flex flex-end'>
        <Button type='submit' label={submitLabel} disabled={pristine} />
      </div>
    </form>
  )
}

NotificationSettingsForm.propTypes = {
  data: PropTypes.array,
  extraProps: PropTypes.func,
  submitLabel: PropTypes.string,
}

export default NotificationSettingsForm
