import dashboardAPI from 'utilities/api/dashboardAPI'
import { ACCESS_FORM_BUSINESS_TAX_ID_UNMASK } from 'constants/apiConstants'

const getAccessFormBusinessTaxIdUnmaskAPI = ({ id }) => {
  return dashboardAPI.get({
    path: ACCESS_FORM_BUSINESS_TAX_ID_UNMASK({ accessFormId: id }),
  })
}

export default getAccessFormBusinessTaxIdUnmaskAPI
