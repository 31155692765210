import { take, put, fork, cancel, cancelled } from 'redux-saga/effects'
import { convertIdentityCsvRowToModel } from 'utilities/convert/convertIdentityCsvRowToModel'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import pick from 'lodash/pick'
import size from 'lodash/size'
import nth from 'lodash/nth'
import postApplicationIdentityAPI from 'api/finix/post/postApplicationIdentityAPI'
import postPaymentInstrumentAPI from 'api/finix/post/postPaymentInstrumentAPI'
import { BANK_ACCOUNT } from 'constants/bankConstants'
import { PAYMENT_INSTRUMENT_CSV_COLUMNS } from 'constants/paymentInstrumentConstants'

import {
  POST_IDENTITY_UPLOAD_F_REQUEST,
  POST_IDENTITY_UPLOAD_F_SUCCESS,
  POST_IDENTITY_UPLOAD_F_FAILURE,
  POST_IDENTITY_UPLOAD_F_CANCEL,
  POST_IDENTITY_UPLOAD_F_CANCELLED,
  POST_IDENTITY_UPLOAD_BATCH_F_SUCCESS,
  POST_IDENTITY_UPLOAD_BATCH_F_FAILURE,
} from 'constants/flowConstants'

const identityUploadLoop = function * ({
  payload,
}) {
  try {
    const credentials = get(payload, 'credentials')
    const csvData = get(payload, 'values.csv.data')
    const csvSize = size(csvData)
    const exportArray = []
    let rowNumber = 0
    let totalSucceeded = 0
    let totalFailed = 0
    let error

    while (rowNumber < csvSize) {
      const row = nth(csvData, rowNumber)
      const application = get(row, 'application')
      const identity = convertIdentityCsvRowToModel(row)

      const [
        vantivLegalEntityId,
        vantivSubMerchantId,
        merchantIdentString,
      ] = getMany(row, [
        'vantivLegalEntityId',
        'vantivSubMerchantId',
        'merchantIdentString',
      ])

      const postApplicationIdentityResponse = yield postApplicationIdentityAPI({
        id: application,
        values: identity,
        credentials,
      })

      const identityId = get(postApplicationIdentityResponse, 'data.id')
      const identityError = get(postApplicationIdentityResponse, 'error.response.data._embedded.errors[0].message')
      let paymentInstrumentError
      let paymentInstrumentId

      if (!identityError) {
        const paymentInstrument = {
          identity: identityId,
          type: BANK_ACCOUNT,
          ...pick(row, PAYMENT_INSTRUMENT_CSV_COLUMNS),
        }

        const postPaymentInstrumentResponse = yield postPaymentInstrumentAPI({
          values: paymentInstrument,
          credentials,
        })

        paymentInstrumentId = get(postPaymentInstrumentResponse, 'data.id')
        paymentInstrumentError = get(postPaymentInstrumentResponse, 'error.response.data._embedded.errors[0].message')
      }

      exportArray.push({
        identity: identityError || identityId,
        identityName: identityError ? '' : `${get(row, 'first_name')} ${get(row, 'last_name')}`,
        paymentInstrumentId: paymentInstrumentError || paymentInstrumentId,
        vantivLegalEntityId,
        vantivSubMerchantId,
        merchantIdentString,
      })

      const succeeded = (!identityError && !paymentInstrumentError)
      totalSucceeded = succeeded ? totalSucceeded + 1 : totalSucceeded
      totalFailed = !succeeded ? totalFailed + 1 : totalFailed
      rowNumber += 1
      error = identityError || paymentInstrumentError

      yield put({
        type: error ? POST_IDENTITY_UPLOAD_BATCH_F_FAILURE : POST_IDENTITY_UPLOAD_BATCH_F_SUCCESS,
        payload: {
          newValues: {
            totalCount: csvSize,
            totalSucceeded,
            totalFailed,
            export: exportArray,
          },
        },
      })
    }

    yield put({ type: error ? POST_IDENTITY_UPLOAD_F_FAILURE : POST_IDENTITY_UPLOAD_F_SUCCESS })
  } finally {
    if (yield cancelled()) {
      yield put({ type: POST_IDENTITY_UPLOAD_F_CANCELLED })
    }
  }
}

const postIdentityUploadF = function * () {
  let lastTask = null

  while (true) {
    const {
      type,
      payload,
    } = yield take([
      POST_IDENTITY_UPLOAD_F_CANCEL,
      POST_IDENTITY_UPLOAD_F_REQUEST,
    ])

    if (lastTask) {
      yield cancel(lastTask)
    }

    if (type === POST_IDENTITY_UPLOAD_F_REQUEST) {
      lastTask = yield fork(identityUploadLoop, { payload })
    }
  }
}

export default postIdentityUploadF
