import { SYSTEM_CONFIG } from 'constants/configConstants'
import removeUndefined from 'utilities/remove/removeUndefined'

const backendPlatformProcessorConfigModel = ({ values }) => {
  const { processorType, sectionId, configKey, configValue } = values
  // TODO: in the future have ability to test the logic of having the configType set correctly to being system_config or application_config
  // may require BE to structure the ingested payload differently
  const configType = sectionId === SYSTEM_CONFIG ? 'system_config' : 'application_config'

  return removeUndefined({
    processor_type: processorType,
    [configType]: {
      [configKey]: configValue,
    },
  })
}

export default backendPlatformProcessorConfigModel
