import React, { Component } from 'react'
import { connect } from 'react-redux'
import Settlement from './Settlement'
import { getSettlementSelector } from 'state-layer/selectors'
import { goToPath } from 'state-layer/history'
import SettlementInfoC from 'components/Customer/Pages/Settlement/SettlementInfo/SettlementInfoC'
import SettlementEntriesC from 'components/Customer/Pages/Settlement/SettlementEntries/SettlementEntriesC'
import InstantPayoutsC from 'components/Customer/Pages/Settlement/InstantPayouts/InstantPayoutsC'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import getSettlementRequest from 'utilities/actions/get/getSettlementRequest'
import getSettlementGroupRequest from 'utilities/actions/get/getSettlementGroupRequest'
import getSettlementGroupSettlementsRequest from 'utilities/actions/get/getSettlementGroupSettlementsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getMany from 'utilities/get/getMany'
import formatBusinessNameString from 'utilities/formatters/formatBusinessNameString'
import formatMoney from 'utilities/formatters/formatMoney'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { CLOSE_SETTLEMENT } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import { NON_MERCHANT_ROLES } from 'constants/roleConstants'
import { LIGHTNING_BOLT_ICON } from 'constants/iconConstants'
import { SHOW_INSTANT_PAYOUTS } from 'constants/featureFlagConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  APPLICATION_PATH,
  SETTLEMENTS_PATH,
  IDENTITY_PATH,
  SETTLEMENT_INSTANT_PAYOUT_PATH,
} from 'constants/pathConstants'

import {
  ACCRUING,
  AWAITING_APPROVAL,
} from 'constants/settlementConstants'

import {
  APPLICATION_RESOURCE_TITLE,
  CREATED_ON,
  EXCEPTION_SETTLEMENT,
  FEES_AMOUNT,
  MERCHANT_IDENTITY,
  NET_AMOUNT,
  INSTANT_PAYOUTS,
  PAYOUT_SETTLEMENT_SCHEDULE,
  PAYOUT_TYPE,
  PAYOUT_INSTANTLY,
  SETTLEMENT,
  SETTLEMENTS,
  STATE,
  STOP_ACCRUING,
} from 'constants/language/languageConstants'

import {
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const role = get(credentials, 'role')
  const isFetching = get(state, `settlementsR.${FETCHING}`)
  const settlementId = get(props, 'params.settlementId')
  const settlement = getSettlementSelector(state, settlementId)
  const hasPartnerAccess = includes(NON_MERCHANT_ROLES, role)
  const isStandaloneMerchant = isStandaloneMerchantDashboard(state)
  const canUseInstantPayouts = getFeatureFlag(SHOW_INSTANT_PAYOUTS)

  const [
    settlementGroupId,
    applicationId,
    applicationName,
    merchantIdentityId,
    merchantIdentityBusinessName,
    merchantIdentityDoingBusinessAs,
    status,
    displayCreatedAt,
    displayScheduleType,
    displayTotalAmount,
    exception,
    currency,
    totalFees,
    netAmount,
    payoutProfileDisplayType,
    totalAmount,
  ] = getMany(settlement, [
    'settlementGroupId',
    'applicationId',
    'applicationModel.businessName',
    'merchantIdentity.id',
    'merchantIdentity.businessName',
    'merchantIdentity.doingBusinessAs',
    'status',
    'displayCreatedAt',
    'displayScheduleType',
    'displayTotalAmount',
    'exception',
    'currency',
    'totalFees',
    'netAmount',
    'payoutProfile.displayType',
    'totalAmount',
  ])

  const isZeroOrNegativeTotalAmount = totalAmount <= 0

  const merchantBusinessName = formatBusinessNameString({ businessName: merchantIdentityBusinessName, doingBusinessAs: merchantIdentityDoingBusinessAs })

  const tabs = [
    {
      id: 'general-info',
      name: 'General Info',
      component: SettlementInfoC,
      props: {
        settlementId,
      },
    },
    {
      id: 'entries',
      name: 'Entries',
      component: SettlementEntriesC,
      props: {
        settlementId,
      },
    },
    {
      id: 'instant-payouts',
      name: INSTANT_PAYOUTS,
      // TO DO: Add another condition here to only show this tab if SETTLEMENT resource was paid out using instant payouts, waiting for BE to deploy.
      condition: canUseInstantPayouts,
      component: InstantPayoutsC,
      props: {
        settlementId,
      },
    },
  ]

  const headerResourceTitle = exception ? EXCEPTION_SETTLEMENT : SETTLEMENT
  const contextBarData = {
    back: {
      label: SETTLEMENTS,
      path: SETTLEMENTS_PATH({ credentialId }),
    },
    items: [
      {
        label: APPLICATION_RESOURCE_TITLE,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY,
        value: merchantBusinessName,
        path: hasPartnerAccess ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
        condition: !isStandaloneMerchant,
      },
    ],
  }

  const headerData = {
    resource: {
      label: headerResourceTitle,
      id: settlementId,
      // TODO: Add instant payouts badge once BE releases the update to Settlement resource
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayTotalAmount} currency={currency} />,
      },
      {
        label: FEES_AMOUNT,
        value: formatMoney({ amount: totalFees, currency, displayNegativeAmount: true, showCurrencyCode: true }),
      },
      {
        label: NET_AMOUNT,
        value: formatMoney({ amount: netAmount, currency, showCurrencyCode: true }),
      },
      {
        label: STATE,
        value: <SettlementStatus value={status} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: PAYOUT_SETTLEMENT_SCHEDULE,
        value: displayScheduleType,
      },
      {
        label: PAYOUT_TYPE,
        value: payoutProfileDisplayType,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    settlementId,
    settlement,
    credentials,
    tabs,
    settlementGroupId,
    hasPartnerAccess,
    contextBarData,
    headerData,
    status,
    canUseInstantPayouts,
    credentialId,
    isZeroOrNegativeTotalAmount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSettlement: ({ settlementId, credentials }) => dispatch(getSettlementRequest({ settlementId, credentials })),
    showCloseSettlementModal: (modalProps) => dispatch(showModalAction({ modalType: CLOSE_SETTLEMENT, modalProps })),
    getSettlementGroup: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupRequest({ credentials, settlementGroupId })) },
    getSettlementGroupSettlements: ({ credentials, settlementGroupId }) => { dispatch(getSettlementGroupSettlementsRequest({ credentials, settlementGroupId })) },
  }
}

class SettlementC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fetchedSettlementGroup: false,
    }
  }

  componentDidMount() {
    const { settlementId, getSettlement, credentials } = this.props

    getSettlement({ settlementId, credentials })
  }

  componentDidUpdate(prevProps) {
    const { settlementId: prevSettlementId } = prevProps
    const { settlementId, settlementGroupId, getSettlement, credentials, hasPartnerAccess } = this.props
    const { fetchedSettlementGroup } = this.state

    if (prevSettlementId !== settlementId) {
      getSettlement({ settlementId, credentials })
    }

    if (hasPartnerAccess && settlementGroupId && (!fetchedSettlementGroup || prevSettlementId !== settlementId)) {
      this.fetchSettlementGroup()

      this.setState({ fetchedSettlementGroup: true })
    }
  }

  componentWillUnmount() {
    this.setState({ fetchedSettlementGroup: false })
  }

  fetchSettlementGroup = () => {
    const {
      settlementGroupId,
      credentials,
      getSettlementGroup,
      getSettlementGroupSettlements,
    } = this.props

    getSettlementGroup({ credentials, settlementGroupId })
    getSettlementGroupSettlements({ credentials, settlementGroupId })
  }

  render() {
    const {
      settlementId,
      credentials,
      showCloseSettlementModal,
      status,
      canUseInstantPayouts,
      credentialId,
      merchantIdentityId,
      isZeroOrNegativeTotalAmount,
    } = this.props

    const actions = [
      {
        label: STOP_ACCRUING,
        action: () => {
          showCloseSettlementModal({ settlementId, credentials })
        },
        condition: hasPlatformAccess({ credentials }) && status === ACCRUING,
      },
      {
        label: PAYOUT_INSTANTLY,
        condition: (canUseInstantPayouts && status === AWAITING_APPROVAL) && !isZeroOrNegativeTotalAmount,
        action: () => goToPath({
          pathname: SETTLEMENT_INSTANT_PAYOUT_PATH({ credentialId, settlementId }),
          queries: {
            settlementId,
            merchantIdentityId,
          },
        }),
      },
    ]

    return (
      <Settlement
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementC)
