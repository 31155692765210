import frontendNotificationSettingModel from 'utilities/create/models/frontendNotificationSettingModel'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'
import map from 'lodash/map'

const frontendNotificationSettingsModel = ({ data: notificationSettings }) => {
  const notificationModels = map(notificationSettings, (notificationSetting) => {
    return frontendNotificationSettingModel({ data: notificationSetting })
  })

  const groupedNotifications = reduce(notificationModels, (total, { notificationType, eventType }) => {
    return merge({}, total, {
      [notificationType]: {
        [eventType]: true,
      },
    })
  }, {})

  return groupedNotifications
}

export default frontendNotificationSettingsModel
