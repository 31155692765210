import dashboardAPI from 'utilities/api/dashboardAPI'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import isDashboardServiceV2 from 'utilities/is/isDashboardServiceV2'
import get from 'lodash/get'

import {
  DASHBOARD_SERVICE_USERS_MEMBERSHIPS,
  DASHBOARD_SERVICE_USERS_MEMBERSHIPS_V2,
} from 'constants/apiConstants'

const postUserMembershipsAPI = ({ values, id, meta }) => {
  const dashboardId = get(values, 'dashboard_id', getCurrentDashboardId())
  const path = isDashboardServiceV2() ? DASHBOARD_SERVICE_USERS_MEMBERSHIPS_V2({ dashboardId })
    : DASHBOARD_SERVICE_USERS_MEMBERSHIPS({ id })

  return dashboardAPI.post({
    path,
    values,
    meta,
  })
}

export default postUserMembershipsAPI
