import './BalanceAdjustmentsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import columnDescriptors from './columnDescriptors'
import { getBalanceAdjustmentsPaymentInstruments } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import Loader from 'components/Shared/Loader/Loader'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import { GET_BALANCE_ADJUSTMENTS } from 'constants/flowConstants'
import { BALANCE_ADJUSTMENTS_LINK } from 'constants/linkConstants'
import { ADDING_FUNDS_PATH } from 'constants/pathConstants'

import {
  BALANCE_ADJUSTMENTS,
  BALANCE_ADJUSTMENTS_LINK_TEXT,
  BALANCE_ADJUSTMENTS_SUBTITLE,
  DAILY_ADJUSTMENT_VOLUME,
  DAILY_ADJUSTMENT_COUNT,
  MONTHLY_ADJUSTMENT_VOLUME,
  MONTHLY_ADJUSTMENT_COUNT,
} from 'constants/language/languageConstants'


const BalanceAdjustments = ({
  credentialId = '',
  actions = [],
  isFetching = false,
  initialQueries = {},
  quickFilters = [],
  balanceAdjustmentPath = '',
  isFetchingCurrentUsages = false,
  displayBalanceAdjustmentDailyVelocityVolumeLimit = '',
  displayBalanceAdjustmentDailyVelocityCurrentVolume = '',
  balanceAdjustmentDailyVelocityCurrentCount = '',
  balanceAdjustmentDailyVelocityCountLimit = '',
  displayBalanceAdjustmentMonthlyVelocityCurrentVolume = '',
  displayBalanceAdjustmentMonthlyVelocityVolumeLimit = '',
  balanceAdjustmentMonthlyVelocityCurrentCount = '',
  balanceAdjustmentMonthlyVelocityCountLimit = '',
  hasApplicationVelocityLimit = false,
}) => {
  return (
    <div className='BalanceAdjustments'>
      <HeaderV2C
        title={BALANCE_ADJUSTMENTS}
        subTitle={(
          <span className='flex'>{BALANCE_ADJUSTMENTS_SUBTITLE}
            <span>&nbsp;<Link className='text-link' to={ADDING_FUNDS_PATH({ credentialId })}>{BALANCE_ADJUSTMENTS_LINK_TEXT}</Link></span>
          </span>
        )}
      />

      { isFetchingCurrentUsages && <Loader /> }

      { hasApplicationVelocityLimit && (
        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={DAILY_ADJUSTMENT_VOLUME}
            headerData={{
              value: displayBalanceAdjustmentDailyVelocityCurrentVolume,
            }}
            subHeaderData={{
              value: displayBalanceAdjustmentDailyVelocityVolumeLimit,
              formatter: () => `of ${displayBalanceAdjustmentDailyVelocityVolumeLimit} limit`,
            }}
            style={{ minHeight: '100px' }}
          />
          <CustomBigNumberC
            title={DAILY_ADJUSTMENT_COUNT}
            headerData={{
              value: balanceAdjustmentDailyVelocityCurrentCount,
            }}
            subHeaderData={{
              value: balanceAdjustmentDailyVelocityCountLimit,
              formatter: () => `of ${balanceAdjustmentDailyVelocityCountLimit} transactions limit`,
            }}
            style={{ minHeight: '100px' }}
          />
          <CustomBigNumberC
            title={MONTHLY_ADJUSTMENT_VOLUME}
            headerData={{
              value: displayBalanceAdjustmentMonthlyVelocityCurrentVolume,
            }}
            subHeaderData={{
              value: displayBalanceAdjustmentMonthlyVelocityVolumeLimit,
              formatter: () => `of ${displayBalanceAdjustmentMonthlyVelocityVolumeLimit} limit`,
            }}
            style={{ minHeight: '100px' }}
          />
          <CustomBigNumberC
            title={MONTHLY_ADJUSTMENT_COUNT}
            headerData={{
              value: balanceAdjustmentMonthlyVelocityCurrentCount,
            }}
            subHeaderData={{
              value: balanceAdjustmentMonthlyVelocityCountLimit,
              formatter: () => `of ${balanceAdjustmentMonthlyVelocityCountLimit} transactions limit`,
            }}
            style={{ minHeight: '100px' }}
          />
        </div>
      )}
      <TableC
        flow={GET_BALANCE_ADJUSTMENTS}
        columnDescriptors={columnDescriptors}
        selector={getBalanceAdjustmentsPaymentInstruments}
        linksKey={BALANCE_ADJUSTMENTS_LINK}
        actions={actions}
        isFetching={isFetching}
        initialQueries={initialQueries}
        fileNameCSV='Balance_Adjustments'
        fileResourceTitleCSV={BALANCE_ADJUSTMENTS}
        quickFilters={quickFilters}
        path={balanceAdjustmentPath}
      />
    </div>
  )
}

BalanceAdjustments.propTypes = {
  credentialId: PropTypes.string,
  actions: PropTypes.array,
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  quickFilters: PropTypes.array,
  isFetchingCurrentUsages: PropTypes.bool,
  displayBalanceAdjustmentDailyVelocityVolumeLimit: PropTypes.string,
  displayBalanceAdjustmentDailyVelocityCurrentVolume: PropTypes.string,
  balanceAdjustmentDailyVelocityCurrentCount: PropTypes.string,
  balanceAdjustmentDailyVelocityCountLimit: PropTypes.string,
  displayBalanceAdjustmentMonthlyVelocityCurrentVolume: PropTypes.string,
  displayBalanceAdjustmentMonthlyVelocityVolumeLimit: PropTypes.string,
  balanceAdjustmentMonthlyVelocityCurrentCount: PropTypes.string,
  balanceAdjustmentMonthlyVelocityCountLimit: PropTypes.string,
}

export default BalanceAdjustments
