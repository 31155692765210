import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  REVIEW_QUEUE,
  REVIEW_QUEUE_ITEM,
} from 'constants/apiConstants'

const getReviewQueueItemAPI = ({ id, credentials, meta }) => {
  return paymentsAPI.get({
    meta,
    path: REVIEW_QUEUE_ITEM({ id }),
    credentials,
    service: REVIEW_QUEUE,
  })
}

export default getReviewQueueItemAPI
