import updateUrlQueries from 'utilities/updateUrlQueries'
import getCurrentUser from 'utilities/get/getCurrentUser'
import removeUndefined from 'utilities/remove/removeUndefined'
import redirectRequest from 'utilities/actions/redirectRequest'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH } from 'constants/pathConstants'
import get from 'lodash/get'
import set from 'lodash/set'
import head from 'lodash/head'
import keys from 'lodash/keys'
import includes from 'lodash/includes'

import {
  PATCH_GUEST_ACCESS_FORM_F_REQUEST,
  POST_ACCESS_FORM_F_REQUEST,
} from 'constants/flowConstants'

import {
  BUSINESS_TO_OWNERSHIP_TYPE_MAP,
  HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS,
} from 'constants/identityConstants'

const submitCustomerOnboardingFormWelcomeStepForm = (values, dispatch, props) => {
  const isSelfService = get(props, 'isSelfService', false)
  const credentialId = getCurrentCredentialsId()
  const dashboardType = get(props, 'dashboardType')
  const accessFormId = get(props, 'accessFormId')
  const sandboxApplicationId = get(getCurrentUser(), 'applicationId')

  // if self-service, create new access form. Otherwise, patch existing accessForm
  const action = (isSelfService && !accessFormId) ? POST_ACCESS_FORM_F_REQUEST : PATCH_GUEST_ACCESS_FORM_F_REQUEST

  // patch ownership type if business type field is one of our hidden ownership type options
  const businessType = get(values, 'businessData.businessType')
  if (includes(HIDDEN_OWNERSHIP_TYPE_BUSINESS_OPTIONS, businessType)) {
    set(values, 'businessData.businessOwnershipType', BUSINESS_TO_OWNERSHIP_TYPE_MAP[businessType])
  }

  const valuesToSubmit = (isSelfService && !accessFormId) ? { ...values, customerType: dashboardType, sandboxApplicationId, pricingType: 'INTERCHANGE_PLUS' } : values

  dispatch({
    type: action,
    payload: removeUndefined({
      id: accessFormId,
      values: valuesToSubmit,
    }),
    meta: {
      successCallback: ({ newValues }) => {
        if (action === POST_ACCESS_FORM_F_REQUEST) {
          const newAccessId = head(keys(newValues))

          dispatch(redirectRequest({
            path: `${EDIT_LIVE_ACCOUNT_APPLICATION_FORM_PATH({
              credentialId,
              accessFormId: newAccessId,
            })}?activeStep=0`,
          }))
        } else {
          updateUrlQueries({ activeStep: 0 })
        }
      },
    },
  })
}

export default submitCustomerOnboardingFormWelcomeStepForm
