import './ResetMemberMFAModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'
import { CANCEL } from 'constants/language/languageConstants'

const ResetMemberMFAModal = ({
  name,
  dashboardUserId,
  resetMemberMFA = () => {},
  closeModal = () => {},
}) => {
  return (
    <GenericModal
      title={`Reset MFA for ${name}?`}
      className='ResetMemberMFAModal'
    >
      <div className='modal-content'>
        <p className='p-1'>This will force {name} to reset their multi-factor authentication method before they can log in.</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />

        <Button
          type='submit'
          label='Reset MFA'
          onClick={() => resetMemberMFA({ id: dashboardUserId })}
        />
      </div>
    </GenericModal>
  )
}

ResetMemberMFAModal.propTypes = {
  name: PropTypes.string,
  dashboardUserId: PropTypes.string,
  resetMemberMFA: PropTypes.func,
  closeModal: PropTypes.func,
}

export default ResetMemberMFAModal
