import './OwnerIdentityVerificationReportV2S.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'

import {
  DATABASE_CHECK_RESULT,
  ADDRESSES,
  PHONE_NUMBERS,
  PERSONAL_INFORMATION,
} from 'constants/language/languageConstants'

const OwnerIdentityVerificationReportV2 = ({
  verificationSummaryData = [],
  showPersonsData = false,
  personsData = [],
}) => {
  return (
    <div className='OwnerIdentityVerificationReportV2'>
      <h5 className='verification-summary-title'>{DATABASE_CHECK_RESULT}</h5>
      <PageSectionV2C
        columns={verificationSummaryData}
        className='verification-summary-section'
      />
      {showPersonsData && (
        map(personsData, (person, index) => {
          const [
            title,
            personalInformationData,
            addressData,
            showAddressData,
            phoneNumberData,
            showPhoneNumberData,
          ] = getMany(person, [
            'title',
            'personalInformationData',
            'addressData',
            'showAddressData',
            'phoneNumberData',
            'showPhoneNumberData',
          ])

          return (
            <div className='person-summary-section'>
              <h5>{title}</h5>
              <h6>{PERSONAL_INFORMATION}</h6>
              <PageSectionV2C
                key={`person-${index}`}
                columns={personalInformationData}
                className='internal-section'
              />
              {showAddressData && (
                <>
                  <h6>{ADDRESSES}</h6>
                  <PageSectionV2C
                    key={`addresses-${index}`}
                    columns={addressData}
                    className='internal-section'
                  />
                </>
              )}
              {showPhoneNumberData && (
                <>
                  <h6>{PHONE_NUMBERS}</h6>
                  <PageSectionV2C
                    key={`numbers-${index}`}
                    columns={phoneNumberData}
                    className='internal-section'
                  />
                </>
              )}
            </div>
          )
        })
      )}
    </div>
  )
}

OwnerIdentityVerificationReportV2.propTypes = {
  verificationSummaryData: PropTypes.array,
  showPersonsData: PropTypes.bool,
  personsData: PropTypes.array,
}

export default OwnerIdentityVerificationReportV2
