import React, { Component } from 'react'
import { connect } from 'react-redux'
import DisablePaymentLinkModal from './DisablePaymentLinkModal'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'
import { PATCH_PAYMENT_LINK_F_REQUEST } from 'constants/flowConstants'
import { DEACTIVATED } from 'constants/paymentLinkConstants'

const mapStateToProps = (state, props) => {
  return {
    credentials: getCurrentCredentials(state),
    isPatching: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deactivatePaymentLink: ({ credentials, paymentLinkId }) => dispatch({
      type: PATCH_PAYMENT_LINK_F_REQUEST,
      payload: {
        id: paymentLinkId,
        values: {
          state: DEACTIVATED,
        },
        credentials,
      },
    }),
    closeModal: () => dispatch(hideModalAction()),
  }
}

class DisablePaymentLinkModalC extends Component {
  disablePaymentLink = () => {
    const {
      credentials,
      paymentLinkId,
      deactivatePaymentLink,
    } = this.props

    deactivatePaymentLink({ credentials, paymentLinkId })
  }

  render() {
    return (
      <DisablePaymentLinkModal
        {...this.props}
        disablePaymentLink={this.disablePaymentLink}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisablePaymentLinkModalC)
