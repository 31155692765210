import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import CreateBalanceAdjustmentConfirmation from './CreateBalanceAdjustmentConfirmation'
import getBalanceAdjustmentRequest from 'utilities/actions/get/getBalanceAdjustmentRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import getMany from 'utilities/get/getMany'
import getPaymentInstrumentRequest from 'utilities/actions/get/getPaymentInstrumentRequest'
import { BALANCE_ADJUSTMENT_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  getBalanceAdjustmentSelector,
  getPaymentInstrumentSelector,
} from 'state-layer/selectors'

import {
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  AMOUNT,
  BALANCE_ADJUSTMENT_ID,
  CREATED_ON,
  NOTE,
  ROUTING_NUMBER,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const balanceAdjustmentId = get(props, 'params.balanceAdjustmentId')
  const balanceAdjustment = getBalanceAdjustmentSelector(state, balanceAdjustmentId)

  const [
    id,
    displayCreatedAt,
    displayAmount,
    description,
    instrumentId,
  ] = getMany(balanceAdjustment, [
    'id',
    'displayCreatedAt',
    'displayAmount',
    'description',
    'instrumentId',
  ])

  const paymentInstrument = getPaymentInstrumentSelector(state, instrumentId)

  const [
    name,
    routingNumber,
    maskedAccountNumber,
  ] = getMany(paymentInstrument, [
    'name',
    'information.bankCode',
    'information.maskedAccountNumber',
  ])

  const transactionDetailsSectionData = convertPageSectionDataToV2([
    {
      label: CREATED_ON,
      value: displayCreatedAt,
    },
    {
      label: ACCOUNT_NAME,
      value: name,
    },
    {
      label: ROUTING_NUMBER,
      value: routingNumber,
    },
    {
      label: ACCOUNT_NUMBER,
      value: maskedAccountNumber,
    },
    {
      label: AMOUNT,
      value: displayAmount,
    },
    {
      label: NOTE,
      value: description,
    },
    {
      label: BALANCE_ADJUSTMENT_ID,
      value: <Link to={BALANCE_ADJUSTMENT_PATH({ credentialId, balanceAdjustmentId })} className='text-link'>{id}</Link>,
    },
  ], 1)

  return {
    credentials,
    transactionDetailsSectionData,
    credentialId,
    balanceAdjustmentId,
    instrumentId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceAdjustment: ({ balanceAdjustmentId, credentials }) => dispatch(getBalanceAdjustmentRequest({ balanceAdjustmentId, credentials })),
    getPaymentInstrument: ({ paymentInstrumentId, credentials }) => dispatch(getPaymentInstrumentRequest({ paymentInstrumentId, credentials })),
  }
}

class CreateBalanceAdjustmentConfirmationC extends Component {
  componentDidMount() {
    const {
      balanceAdjustmentId,
      getBalanceAdjustment,
      credentials,
    } = this.props

    if (balanceAdjustmentId) {
      getBalanceAdjustment({ balanceAdjustmentId, credentials })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      instrumentId,
      getPaymentInstrument,
      credentials,
    } = this.props

    const { instrumentId: prevInstrumentId } = prevProps

    if (instrumentId && instrumentId !== prevInstrumentId) {
      getPaymentInstrument({ paymentInstrumentId: instrumentId, credentials })
    }
  }

  render() {
    return (
      <CreateBalanceAdjustmentConfirmation {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBalanceAdjustmentConfirmationC)
