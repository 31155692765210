import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { IDENTITY_SETTLEMENT_FORM } from 'constants/formConstants'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import submitIdentitySettlementForm from 'utilities/submit/submitIdentitySettlementForm'
import { SUBMIT } from 'constants/language/languageConstants'

const IdentitySettlementForm = ({
  handleSubmit = () => {},
  submitting = false,
  processorOptions = [],
}) => {
  return (
    <form className='IdentitySettlementForm' onSubmit={handleSubmit(submitIdentitySettlementForm)}>
      <Field
        name='identity'
        label='Identity ID'
        component={InputField}
        disabled
      />

      <Field
        name='currency'
        label='Currency'
        component={InputField}
        disabled
      />

      <Field
        name='processor'
        label='Processors'
        component={SelectField}
        options={processorOptions}
      />

      <div className='buttons flex flex-end'>
        <Button type='submit' submitting={submitting} label={SUBMIT} />
      </div>
    </form>
  )
}

IdentitySettlementForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  processorOptions: PropTypes.array,
}

export default reduxForm({
  form: IDENTITY_SETTLEMENT_FORM,
})(IdentitySettlementForm)
