import selectOption from 'utilities/forms/selectOption'
import { CHECK_CIRCLE_ICON, CLOCK_ICON } from 'constants/iconConstants'

import {
  ALL,
  TRANSACTION_MONITORING_OPEN_STATUS as OPEN_LABEL,
  TRANSACTION_MONITORING_RESOLVED_STATUS as RESOLVED_LABEL,
  TRANSACTION_MONITORING_RELATED_PAYMENTS,
  TRANSACTION_MONITORING_RELATED_PAYOUTS,
  TRANSACTION_MONITORING_RELATED_ACH_RETURNS,
  TRANSACTION_MONITORING_RELATED_REFUNDS,
  TRANSACTION_MONITORING_RELATED_AUTHORIZATIONS,
  TRANSACTION_MONITORING_RELATED_SETTLEMENTS,
  TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  TRANSACTION_MONITORING_RECIPIENT_PAYOUT_INSIGHTS,
  TRANSACTION_MONITORING_ACH_RETURN_INSIGHTS,
  TRANSACTION_MONITORING_REFUND_INSIGHTS,
  TRANSACTION_MONITORING_FAILED_AUTHORIZATION_INSIGHTS,
  TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  TRANSACTION_MONITORING_TICKET_INSIGHTS,
  TRANSACTION_MONITORING_DISPUTE_INSIGHTS,
  TRANSACTION_MONITORING_RELATED_DISPUTES,
} from 'constants/language/languageConstants'

export const APPLICATION = 'APPLICATION'
export const MERCHANT = 'MERCHANT'

export const OPEN = 'OPEN'
export const RESOLVED = 'RESOLVED'

export const MONITORING_ALERT_STATUS_TO_DISPLAY_MAP = {
  [OPEN]: OPEN_LABEL,
  [RESOLVED]: RESOLVED_LABEL,
}

export const MONITORING_ALERT_STATUS_MAP = {
  [OPEN_LABEL]: OPEN,
  [RESOLVED_LABEL]: RESOLVED,
}

export const MONITORING_ALERT_STATUS_ICON_MAP = {
  [OPEN]: CLOCK_ICON,
  [RESOLVED]: `fa-${CHECK_CIRCLE_ICON}`,
}

export const MONITORING_ALERT_STATUS_OPTIONS = [
  selectOption(ALL, ''),
  selectOption(OPEN_LABEL, OPEN),
  selectOption(RESOLVED_LABEL, RESOLVED),
]

export const MONITORING_ALERT_TYPE = {
  PAYOUTS_VOLUME_CHANGE_DAILY: 'PAYOUTS_VOLUME_CHANGE_DAILY',
  PAYOUTS_VOLUME_CHANGE_WEEKLY: 'PAYOUTS_VOLUME_CHANGE_WEEKLY',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: 'PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: 'PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: 'PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: 'SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: 'SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: 'SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK',
  PAYOUTS_GREATER_THAN_10K: 'PAYOUTS_GREATER_THAN_10K',
  SPLIT_PAYOUTS_GREATER_THAN_10K: 'SPLIT_PAYOUTS_GREATER_THAN_10K',
  DUPLICATE_TRANSFERS_BY_NAME: 'DUPLICATE_TRANSFERS_BY_NAME',
  DUPLICATE_TRANSFERS_BY_CARD: 'DUPLICATE_TRANSFERS_BY_CARD',
  INTERNATIONAL_CARD_RATIO: 'INTERNATIONAL_CARD_RATIO',
  ACH_RETURN_RATE: 'ACH_RETURN_RATE',
  ACH_RETURN_RATE_ADMINISTRATIVE: 'ACH_RETURN_RATE_ADMINISTRATIVE',
  ACH_RETURN_RATE_UNAUTHORIZED: 'ACH_RETURN_RATE_UNAUTHORIZED',
  DUPLICATE_TRANSFERS_BY_BANK: 'DUPLICATE_TRANSFERS_BY_BANK',
  UNREFERENCED_REFUNDS: 'UNREFERENCED_REFUNDS',
  REFUND_RATE: 'REFUND_RATE',
  FAILED_AUTHORIZATION_RATE: 'FAILED_AUTHORIZATION_RATE',
  REPEAT_DONATIONS: 'REPEAT_DONATIONS',
  LENDING_CREDIT_CARD_TRANSFERS: 'LENDING_CREDIT_CARD_TRANSFERS',
  FIRST_SETTLEMENT: 'FIRST_SETTLEMENT',
  HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT: 'HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT',
  DUPLICATE_SETTLEMENT: 'DUPLICATE_SETTLEMENT',
  DORMANT_MERCHANT_SETTLEMENT: 'DORMANT_MERCHANT_SETTLEMENT',
  SETTLEMENT_SIZE_DIFFERENCE: 'SETTLEMENT_SIZE_DIFFERENCE',
  HIGH_TICKET: 'HIGH_TICKET',
  DISPUTE_RATE_BY_NETWORK: 'DISPUTE_RATE_BY_NETWORK',
  DISPUTE_COUNT: 'DISPUTE_COUNT',
  DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW: 'DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW',
}


export const MONITORING_ALERT_NAME_MAPPING = {
  PAYOUTS_VOLUME_CHANGE_DAILY: 'Daily Payouts Volume Change',
  PAYOUTS_VOLUME_CHANGE_WEEKLY: 'Weekly Payouts Volume Change',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: 'Duplicate Payouts by Recipient Name',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: 'Duplicate Payouts by Recipient Card',
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: 'Duplicate Payouts by Recipient Bank Account',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: 'Duplicate Split Payouts by Recipient Name',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: 'Duplicate Split Payouts by Recipient Card',
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: 'Duplicate Split Payouts by Recipient Bank Account',
  // PAYOUTS_GREATER_THAN_10K: 'Payouts Greater than $10K',
  // SPLIT_PAYOUTS_GREATER_THAN_10K: 'Split Payouts Greater than $10K',
  DUPLICATE_TRANSFERS_BY_NAME: 'Duplicate Transfers by Buyer Name',
  DUPLICATE_TRANSFERS_BY_CARD: 'Duplicate Transfers by Buyer Card',
  INTERNATIONAL_CARD_RATIO: 'International Card Ratio',
  ACH_RETURN_RATE: 'Total ACH Return Rate',
  ACH_RETURN_RATE_ADMINISTRATIVE: 'Administrative ACH Return Rate',
  ACH_RETURN_RATE_UNAUTHORIZED: 'Unauthorized ACH Return Rate',
  DUPLICATE_TRANSFERS_BY_BANK: 'Duplicate Transfers by Buyer Bank Account',
  UNREFERENCED_REFUNDS: 'Unreferenced Refunds (Card-Present)',
  REFUND_RATE: 'Refund Rate',
  FAILED_AUTHORIZATION_RATE: 'Failed Authorization Rate',
  REPEAT_DONATIONS: 'Repeat Donations',
  LENDING_CREDIT_CARD_TRANSFERS: 'Lending Credit Card Transfers',
  FIRST_SETTLEMENT: 'First Settlement',
  HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT: 'High Ratio of Small Dollar Settlement Entries',
  DUPLICATE_SETTLEMENT: 'Duplicate Settlements',
  DORMANT_MERCHANT_SETTLEMENT: 'Dormant Merchant Settlement',
  SETTLEMENT_SIZE_DIFFERENCE: 'Settlement Size Difference',
  HIGH_TICKET: 'High Ticket',
  DISPUTE_RATE_BY_NETWORK: 'Dispute Rate by Network',
  DISPUTE_COUNT: 'Dispute Count',
  DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW: 'Dispute Rate by Network (Initial Review)',
}

export const MONITORING_ALERT_INSIGHTS_TITLE_MAPPING = {
  PAYOUTS_VOLUME_CHANGE_DAILY: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  PAYOUTS_VOLUME_CHANGE_WEEKLY: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: TRANSACTION_MONITORING_RECIPIENT_PAYOUT_INSIGHTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: TRANSACTION_MONITORING_RECIPIENT_PAYOUT_INSIGHTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: TRANSACTION_MONITORING_RECIPIENT_PAYOUT_INSIGHTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  PAYOUTS_GREATER_THAN_10K: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  SPLIT_PAYOUTS_GREATER_THAN_10K: TRANSACTION_MONITORING_APPLICATION_PAYOUT_INSIGHTS,
  DUPLICATE_TRANSFERS_BY_NAME: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  DUPLICATE_TRANSFERS_BY_CARD: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  INTERNATIONAL_CARD_RATIO: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  ACH_RETURN_RATE: TRANSACTION_MONITORING_ACH_RETURN_INSIGHTS,
  ACH_RETURN_RATE_ADMINISTRATIVE: TRANSACTION_MONITORING_ACH_RETURN_INSIGHTS,
  ACH_RETURN_RATE_UNAUTHORIZED: TRANSACTION_MONITORING_ACH_RETURN_INSIGHTS,
  DUPLICATE_TRANSFERS_BY_BANK: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  UNREFERENCED_REFUNDS: TRANSACTION_MONITORING_REFUND_INSIGHTS,
  REFUND_RATE: TRANSACTION_MONITORING_REFUND_INSIGHTS,
  FAILED_AUTHORIZATION_RATE: TRANSACTION_MONITORING_FAILED_AUTHORIZATION_INSIGHTS,
  REPEAT_DONATIONS: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  LENDING_CREDIT_CARD_TRANSFERS: TRANSACTION_MONITORING_PAYMENT_INSIGHTS,
  FIRST_SETTLEMENT: TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT: TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  DUPLICATE_SETTLEMENT: TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  DORMANT_MERCHANT_SETTLEMENT: TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  SETTLEMENT_SIZE_DIFFERENCE: TRANSACTION_MONITORING_SETTLEMENT_INSIGHTS,
  HIGH_TICKET: TRANSACTION_MONITORING_TICKET_INSIGHTS,
  DISPUTE_RATE_BY_NETWORK: TRANSACTION_MONITORING_DISPUTE_INSIGHTS,
  DISPUTE_COUNT: TRANSACTION_MONITORING_DISPUTE_INSIGHTS,
  DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW: TRANSACTION_MONITORING_DISPUTE_INSIGHTS,
}

export const MONITORING_ALERT_RELATED_TRANSFERS_TITLE_MAPPING = {
  PAYOUTS_VOLUME_CHANGE_DAILY: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  PAYOUTS_VOLUME_CHANGE_WEEKLY: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  PAYOUTS_GREATER_THAN_10K: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  SPLIT_PAYOUTS_GREATER_THAN_10K: TRANSACTION_MONITORING_RELATED_PAYOUTS,
  DUPLICATE_TRANSFERS_BY_NAME: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  DUPLICATE_TRANSFERS_BY_CARD: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  INTERNATIONAL_CARD_RATIO: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  ACH_RETURN_RATE: TRANSACTION_MONITORING_RELATED_ACH_RETURNS,
  ACH_RETURN_RATE_ADMINISTRATIVE: TRANSACTION_MONITORING_RELATED_ACH_RETURNS,
  ACH_RETURN_RATE_UNAUTHORIZED: TRANSACTION_MONITORING_RELATED_ACH_RETURNS,
  DUPLICATE_TRANSFERS_BY_BANK: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  UNREFERENCED_REFUNDS: TRANSACTION_MONITORING_RELATED_REFUNDS,
  REFUND_RATE: TRANSACTION_MONITORING_RELATED_REFUNDS,
  FAILED_AUTHORIZATION_RATE: TRANSACTION_MONITORING_RELATED_AUTHORIZATIONS,
  REPEAT_DONATIONS: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  LENDING_CREDIT_CARD_TRANSFERS: TRANSACTION_MONITORING_RELATED_PAYMENTS,
  FIRST_SETTLEMENT: TRANSACTION_MONITORING_RELATED_SETTLEMENTS,
  HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT: TRANSACTION_MONITORING_RELATED_SETTLEMENTS,
  DUPLICATE_SETTLEMENT: TRANSACTION_MONITORING_RELATED_SETTLEMENTS,
  DORMANT_MERCHANT_SETTLEMENT: null,
  SETTLEMENT_SIZE_DIFFERENCE: null,
  HIGH_TICKET: null,
  DISPUTE_RATE_BY_NETWORK: TRANSACTION_MONITORING_RELATED_DISPUTES,
  DISPUTE_COUNT: TRANSACTION_MONITORING_RELATED_DISPUTES,
  DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW: TRANSACTION_MONITORING_RELATED_DISPUTES,
}

export const MONITORING_ALERT_THRESHOLD_MAPPING = {
  DOLLARS: 'DOLLARS',
  PERCENTAGE: 'PERCENTAGE',
  COUNT: 'COUNT',
}
