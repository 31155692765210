import get from 'lodash/get'
import endsWith from 'lodash/endsWith'
import trimEnd from 'lodash/trimEnd'

const specialWordsSingular = {
  are: 'is',
}

const singularizeWord = ({ count = 1, word = '' }) => {
  const specialSingularWord = get(specialWordsSingular, word)

  if (specialSingularWord) {
    return (count === 1) ? specialSingularWord : word
  }

  return (count === 1 && endsWith(word, 's')) ? trimEnd(word, 's') : word
}

export default singularizeWord
