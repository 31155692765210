import './LoginS.scss'
import React from 'react'
import { Link } from 'react-router'
import { SELF_SERVICE_SIGNUP_PATH } from 'constants/pathConstants'
import { CREATE_NEW_SANDBOX_ACCOUNT } from 'constants/language/languageConstants'


const Login = ({ showSignUpButton }) => {
  return (
    <div className='Login'>
      <div id='login-container' className='login-container' />
      {showSignUpButton &&
      <div className='signup p-2'>Don&apos;t have an account? <Link to={SELF_SERVICE_SIGNUP_PATH} onlyActiveOnIndex><span className='link-2'>{CREATE_NEW_SANDBOX_ACCOUNT}</span></Link></div>
      }
    </div>
  )
}

export default Login
