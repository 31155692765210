import './SelectFieldS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import classnames from 'classnames'
import TooltipLabel from 'components/Shared/TooltipLabel/TooltipLabel'
import { t } from 'constants/language/languageConstants'

import {
  ALERT_ICON,
  ANGLE_DOWN_ICON,
  CHECK_CIRCLE_ICON,
} from 'constants/iconConstants'

const SelectField = ({
  id,
  ariaLabel,
  input,
  options = [],
  label,
  disabled = false,
  rowFormat = false,
  className = '',
  successMessage,
  tooltip = '',
  tooltipPosition,
  autoComplete = 'on',
  required = true,
  helperText,
  hiddenLabel,
  meta: {
    active,
    touched,
    error,
    valid,
  },
}) => {
  const { name } = input
  const errorJSX = error && <span className='error'><i className={`fal fa-${ALERT_ICON} error-icon`} />{t(error)}</span>
  const successJSX = successMessage && valid && <span className='success'><i className={`fal fa-${CHECK_CIRCLE_ICON} success-icon`} />{t(successMessage)}</span>
  const isActive = active ? 'focus' : ''
  const hasError = touched && errorJSX ? 'error' : ''
  const format = rowFormat ? 'formatRow flex flex-start items-center' : ''
  const isDisabled = disabled ? 'disabled-select' : ''

  const classNames = classnames({
    SelectField: true,
    [name]: true,
    [isActive]: !!isActive,
    [className]: !!className,
    [hasError]: !!hasError,
    [isDisabled]: !!isDisabled,
  })

  return (
    <div className={classNames}>
      <div className={format}>
        <div className='flex'>
          { (label || hiddenLabel) && <label className={`label-2 ${hiddenLabel && 'hidden-label'}`}>{t(label)}</label> }

          {required && label && <div className='required-field'>*</div>}

          { tooltip && <TooltipLabel message={tooltip} position={tooltipPosition} /> }
        </div>

        <div className={`selectArea ${isActive} ${hasError} ${isDisabled}`}>
          {options.length > 0 && (
            <select
              id={id}
              {...input}
              disabled={disabled}
              className={name}
              aria-label={ariaLabel ? ariaLabel : label}
              autoComplete={autoComplete}
            >
              { map(options, ({ label: optionLabel, value: optionValue }) => <option value={optionValue} key={optionValue || 'key'}>{optionLabel}</option>) }
            </select>
          )}
        </div>
      </div>
      <span className={`arrow fa fa-${ANGLE_DOWN_ICON} ${label ? '' : 'missing-label'}`} />

      { helperText && <div className='helper-text'>{t(helperText)}</div> }

      { touched && (errorJSX || successJSX) }
    </div>
  )
}

SelectField.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  rowFormat: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  hiddenLabel: PropTypes.string,
}

export default SelectField
