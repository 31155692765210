import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import UploadEvidenceFormC from 'components/Customer/Forms/UploadEvidenceForm/UploadEvidenceFormC'

const UploadEvidenceModal = ({
  id = '',
  type = '',
}) => {
  return (
    <GenericModal
      title='Upload Evidence'
      className='UploadEvidenceModal'
      Component={UploadEvidenceFormC}
      id={id}
      type={type}
    />
  )
}

UploadEvidenceModal.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
}

export default UploadEvidenceModal
