import paymentsAPI from 'utilities/api/paymentsAPI'
import { UNDERWRITING_SUBJECT_REVIEW } from 'constants/apiConstants'

const getUnderwritingReviewAPI = ({ values, credentials, queries, meta }) => {
  const {
    subjectId,
    reviewId,
  } = values

  return paymentsAPI.get({
    path: UNDERWRITING_SUBJECT_REVIEW({ subjectId, reviewId }),
    credentials,
    queries,
    meta,
  })
}

export default getUnderwritingReviewAPI
