import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import formatPercentage from 'utilities/formatters/formatPercentage'
import singularizeWord from 'utilities/singularizeWord'
import { CREATED_AT_DEFAULT_FORMAT } from 'constants/timeConstants'
import { USD } from 'constants/currencyConstants'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import toString from 'lodash/toString'

import {
  MONITORING_ALERT_THRESHOLD_MAPPING,
  MONITORING_ALERT_TYPE,
} from 'constants/monitoringAlertConstants'

const formatValueWithSymbol = (value, type) => {
  switch (type) {
    case MONITORING_ALERT_THRESHOLD_MAPPING.DOLLARS:
      return formatMoney({ amount: value, currency: USD })
    case MONITORING_ALERT_THRESHOLD_MAPPING.PERCENTAGE:
      return formatPercentage({ percentage: value ? value.toFixed(2) : 0 })
    case MONITORING_ALERT_THRESHOLD_MAPPING.COUNT:
      return toString(value)
    default:
      return toString(value)
  }
}

const getPayoutsVolumeChangeDaily = (calculations) => {
  const [
    payoutCreatedAtDay,
    payoutCreatedAtDayPrior,
    payoutAmountDollarsSum,
    payoutAmountDollarsSumPriorDay,
    payoutAmountDollarsAverage,
    payoutAmountDollarsAveragePriorDay,
    payoutAmountDifference,
    payoutCount,
    payoutCountPriorDay,
  ] = getMany(calculations, [
    'payout_created_at_day',
    'payout_created_at_day_prior',
    'payout_amount_dollars_sum',
    'payout_amount_dollars_sum_prior_day',
    'payout_amount_dollars_average',
    'payout_amount_dollars_average_prior_day',
    'payout_amount_difference',
    'payout_count',
    'payout_count_prior_day',
  ])

  return removeUndefined({
    payoutCreatedAtDay,
    displayPayoutCreatedAtDay: payoutCreatedAtDay ? formatDate({ date: payoutCreatedAtDay, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    payoutCreatedAtDayPrior,
    displayPayoutCreatedAtDayPrior: payoutCreatedAtDayPrior ? formatDate({ date: payoutCreatedAtDayPrior, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    payoutAmountDollarsSum,
    displayPayoutAmountDollarsSum: formatMoney({ amount: payoutAmountDollarsSum, currency: USD }),
    payoutAmountDollarsSumPriorDay,
    displayPayoutAmountDollarsSumPriorDay: formatMoney({ amount: payoutAmountDollarsSumPriorDay, currency: USD }),
    payoutAmountDollarsAverage,
    displayPayoutAmountDollarsAverage: formatMoney({ amount: payoutAmountDollarsAverage, currency: USD }),
    payoutAmountDollarsAveragePriorDay,
    displayPayoutAmountDollarsAveragePriorDay: formatMoney({ amount: payoutAmountDollarsAveragePriorDay, currency: USD }),
    payoutAmountDifference,
    displayPayoutAmountDifference: formatMoney({ amount: payoutAmountDifference, currency: USD }),
    payoutCount,
    payoutCountPriorDay,
    payoutCountDifference: payoutCount - payoutCountPriorDay,
  })
}

const getPayoutsVolumeChangeWeekly = (calculations) => {
  const [
    payoutAmountDollarsSum,
    payoutAmountDollarsSumPriorWeek,
    payoutAmountDollarsAverage,
    payoutAmountDollarsAveragePriorWeek,
    payoutCreatedAtWeek,
    payoutCreatedAtWeekPrior,
    payoutCount,
    payoutCountPriorWeek,
    payoutAmountDifference,
  ] = getMany(calculations, [
    'payout_amount_dollars_sum',
    'payout_amount_dollars_sum_prior_week',
    'payout_amount_dollars_average',
    'payout_amount_dollars_average_prior_week',
    'payout_created_at_week',
    'payout_created_at_week_prior',
    'payout_count',
    'payout_count_prior_week',
    'payout_amount_difference',
  ])

  return removeUndefined({
    payoutAmountDollarsSum,
    displayPayoutAmountDollarsSum: formatMoney({ amount: payoutAmountDollarsSum, currency: USD }),
    payoutAmountDollarsSumPriorWeek,
    displayPayoutAmountDollarsSumPriorWeek: formatMoney({ amount: payoutAmountDollarsSumPriorWeek, currency: USD }),
    payoutAmountDollarsAverage,
    displayPayoutAmountDollarsAverage: formatMoney({ amount: payoutAmountDollarsAverage, currency: USD }),
    payoutAmountDollarsAveragePriorWeek,
    displayPayoutAmountDollarsAveragePriorWeek: formatMoney({ amount: payoutAmountDollarsAveragePriorWeek, currency: USD }),
    payoutCreatedAtWeek,
    displayPayoutCreatedAtWeek: payoutCreatedAtWeek ? formatDate({ date: payoutCreatedAtWeek, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    payoutCreatedAtWeekPrior,
    displayPayoutCreatedAtWeekPrior: payoutCreatedAtWeekPrior ? formatDate({ date: payoutCreatedAtWeekPrior, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    payoutCount,
    payoutCountPriorWeek,
    payoutCountDifference: payoutCount - payoutCountPriorWeek,
    payoutAmountDifference,
    displayPayoutAmountDifference: formatMoney({ amount: payoutAmountDifference, currency: USD }),
  })
}

const getPayouts = (calculations) => {
  const [
    payoutAmountDollarsSum,
    payoutAmountDollarsAverage,
    payoutCount,
  ] = getMany(calculations, [
    'payout_amount_dollars_sum',
    'payout_amount_dollars_average',
    'payout_count',
  ])

  return removeUndefined({
    payoutAmountDollarsSum,
    displayPayoutAmountDollarsSum: formatMoney({ amount: payoutAmountDollarsSum, currency: USD }),
    payoutAmountDollarsAverage,
    displayPayoutAmountDollarsAverage: formatMoney({ amount: payoutAmountDollarsAverage, currency: USD }),
    payoutCount,
  })
}

const getSplitPayouts = (calculations) => {
  const [
    splitPayoutAmountDollarsSum,
    splitPayoutAmountDollarsAverage,
    splitPayoutCount,
  ] = getMany(calculations, [
    'split_payout_amount_dollars_sum',
    'split_payout_amount_dollars_average',
    'split_payout_count',
  ])

  return removeUndefined({
    splitPayoutAmountDollarsSum,
    displaySplitPayoutAmountDollarsSum: formatMoney({ amount: splitPayoutAmountDollarsSum, currency: USD }),
    splitPayoutAmountDollarsAverage,
    displaySplitPayoutAmountDollarsAverage: formatMoney({ amount: splitPayoutAmountDollarsAverage, currency: USD }),
    splitPayoutCount,
  })
}

const getInternationalTransactions = (calculations) => {
  const [
    internationalCardCount,
    domesticCardCount,
    internationalCardRatio,
  ] = getMany(calculations, [
    'international_card_count',
    'domestic_card_count',
    'international_card_ratio',
  ])

  return removeUndefined({
    internationalCardCount,
    domesticCardCount,
    internationalCardRatio,
    displayInternationalCardRatio: formatPercentage({ percentage: internationalCardRatio }),
  })
}

const getTransactions = (calculations) => {
  const [
    transferCount,
    transferDollarsSum,
    transferDollarsAverage,
  ] = getMany(calculations, [
    'transfer_count',
    'transfer_dollars_sum',
    'transfer_dollars_average',
  ])

  return removeUndefined({
    transferCount,
    transferDollarsSum,
    displayTransferDollarsSum: formatMoney({ amount: transferDollarsSum, currency: USD }),
    transferDollarsAverage,
    displayTransferDollarsAverage: formatMoney({ amount: transferDollarsAverage, currency: USD }),
  })
}

const getACHReturns = (calculations) => {
  const [
    transferCount,
    achReturnCount,
    transferAmountDollarsSum,
    transferAmountDollarsAverage,
    achReturnAmountDollarsSum,
    achReturnAmountDollarsAverage,
    achReturnRate,
  ] = getMany(calculations, [
    'transfer_count',
    'ach_return_count',
    'transfer_amount_dollars_sum',
    'transfer_amount_dollars_average',
    'ach_return_amount_dollars_sum',
    'ach_return_amount_dollars_average',
    'ach_return_rate',
  ])

  return removeUndefined({
    transferCount,
    achReturnCount,
    transferAmountDollarsSum,
    displayTransferAmountDollarsSum: formatMoney({ amount: transferAmountDollarsSum, currency: USD }),
    transferAmountDollarsAverage,
    displayTransferAmountDollarsAverage: formatMoney({ amount: transferAmountDollarsAverage, currency: USD }),
    achReturnAmountDollarsSum,
    displayAchReturnAmountDollarsSum: formatMoney({ amount: achReturnAmountDollarsSum, currency: USD }),
    achReturnAmountDollarsAverage,
    displayAchReturnAmountDollarsAverage: formatMoney({ amount: achReturnAmountDollarsAverage, currency: USD }),
    achReturnRate,
    displayAchReturnRate: formatPercentage({ percentage: achReturnRate }),
  })
}

const getRefunds = (calculations) => {
  const [
    transferCount,
    refundCount,
    transferAmountDollarsSum,
    refundAmountDollarsSum,
    transferAmountDollarsAverage,
    refundAmountDollarsAverage,
    refundRate,
  ] = getMany(calculations, [
    'transfer_count',
    'refund_count',
    'transfer_amount_dollars_sum',
    'refund_amount_dollars_sum',
    'transfer_amount_dollars_average',
    'refund_amount_dollars_average',
    'refund_rate',
  ])

  return removeUndefined({
    transferCount,
    refundCount,
    transferAmountDollarsSum,
    displayTransferAmountDollarsSum: formatMoney({ amount: transferAmountDollarsSum, currency: USD }),
    refundAmountDollarsSum,
    displayRefundAmountDollarsSum: formatMoney({ amount: refundAmountDollarsSum, currency: USD }),
    transferAmountDollarsAverage,
    displayTransferAmountDollarsAverage: formatMoney({ amount: transferAmountDollarsAverage, currency: USD }),
    refundAmountDollarsAverage,
    displayRefundAmountDollarsAverage: formatMoney({ amount: refundAmountDollarsAverage, currency: USD }),
    refundRate,
    displayRefundRate: formatPercentage({ percentage: refundRate }),
  })
}

const getAuthorizations = (calculations) => {
  const [
    authorizationCount,
    failedAuthorizationCount,
    authorizationAmountDollarsSum,
    failedAuthorizationAmountDollarsSum,
    authorizationAmountDollarsAverage,
    failedAuthorizationAmountDollarsAverage,
    failedAuthorizationRate,
  ] = getMany(calculations, [
    'authorization_count',
    'failed_authorization_count',
    'authorization_amount_dollars_sum',
    'failed_authorization_amount_dollars_sum',
    'authorization_amount_dollars_average',
    'failed_authorization_amount_dollars_average',
    'failed_authorization_rate',
  ])

  return removeUndefined({
    authorizationCount,
    failedAuthorizationCount,
    authorizationAmountDollarsSum,
    displayAuthorizationAmountDollarsSum: formatMoney({ amount: authorizationAmountDollarsSum, currency: USD }),
    failedAuthorizationAmountDollarsSum,
    displayFailedAuthorizationAmountDollarsSum: formatMoney({ amount: failedAuthorizationAmountDollarsSum, currency: USD }),
    authorizationAmountDollarsAverage,
    displayAuthorizationAmountDollarsAverage: formatMoney({ amount: authorizationAmountDollarsAverage, currency: USD }),
    failedAuthorizationAmountDollarsAverage,
    displayFailedAuthorizationAmountDollarsAverage: formatMoney({ amount: failedAuthorizationAmountDollarsAverage, currency: USD }),
    failedAuthorizationRate,
    displayFailedAuthorizationRate: formatPercentage({ percentage: failedAuthorizationRate }),
  })
}

const getFirstSettlement = (calculations) => {
  const [
    settlementEntryCount,
    settlementAmountDollarsSum,
    settlementAmountDollarsAverage,
    daysSinceApproval,
  ] = getMany(calculations, [
    'settlement_entry_count',
    'settlement_amount_dollars_sum',
    'settlement_amount_dollars_average',
    'days_since_approval',
  ])

  return removeUndefined({
    settlementEntryCount,
    settlementAmountDollarsSum,
    displaySettlementAmountDollarsSum: formatMoney({ amount: settlementAmountDollarsSum, currency: USD }),
    settlementAmountDollarsAverage,
    displaySettlementAmountDollarsAverage: formatMoney({ amount: settlementAmountDollarsAverage, currency: USD }),
    daysSinceApproval,
  })
}

const getSmallSettlements = (calculations) => {
  const [
    settlementEntryCount,
    settlementEntryCountSmallDollar,
    settlementEntryRatioSmallDollar,
    settlementAmountDollarsSum,
    settlementAmountDollarsAverage,
  ] = getMany(calculations, [
    'settlement_entry_count',
    'settlement_entry_count_small_dollar',
    'settlement_entry_ratio_small_dollar',
    'settlement_amount_dollars_sum',
    'settlement_amount_dollars_average',
  ])

  return removeUndefined({
    settlementEntryCount,
    settlementEntryCountSmallDollar,
    settlementEntryRatioSmallDollar,
    displaySettlementEntryRatioSmallDollar: formatPercentage({ percentage: settlementEntryRatioSmallDollar }),
    settlementAmountDollarsSum,
    displaySettlementAmountDollarsSum: formatMoney({ amount: settlementAmountDollarsSum, currency: USD }),
    settlementAmountDollarsAverage,
    displaySettlementAmountDollarsAverage: formatMoney({ amount: settlementAmountDollarsAverage, currency: USD }),
  })
}

const getDuplicateSettlements = (calculations) => {
  const [
    settlementEntryCount,
    settlementAmountDollarsSum,
    settlementAmountDollarsAverage,
    settlementCount,
  ] = getMany(calculations, [
    'settlement_entry_count',
    'settlement_amount_dollars_sum',
    'settlement_amount_dollars_average',
    'settlement_count',
  ])

  return removeUndefined({
    settlementEntryCount,
    settlementAmountDollarsSum,
    displaySettlementAmountDollarsSum: formatMoney({ amount: settlementAmountDollarsSum, currency: USD }),
    settlementAmountDollarsAverage,
    displaySettlementAmountDollarsAverage: formatMoney({ amount: settlementAmountDollarsAverage, currency: USD }),
    settlementCount,
  })
}

const getDormantSettlement = (calculations) => {
  const [
    settlementCreatedAtDay,
    settlementCreatedAtDayPrior,
    settlementEntryCount,
    settlementEntryCountPrior,
    settlementAmountDollarsSum,
    settlementAmountDollarsSumPrior,
    settlementAmountDollarsAverage,
    settlementAmountDollarsAveragePrior,
    daysSincePriorSettlement,
    settlementId,
    settlementIdPrior,
  ] = getMany(calculations, [
    'settlement_created_at_day',
    'settlement_created_at_day_prior',
    'settlement_entry_count',
    'settlement_entry_count_prior',
    'settlement_amount_dollars_sum',
    'settlement_amount_dollars_sum_prior',
    'settlement_amount_dollars_average',
    'settlement_amount_dollars_average_prior',
    'days_since_prior_settlement',
    'settlement_id',
    'settlement_id_prior',
  ])

  return removeUndefined({
    settlementCreatedAtDay,
    displaySettlementCreatedAtDay: settlementCreatedAtDay ? formatDate({ date: settlementCreatedAtDay, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    settlementCreatedAtDayPrior,
    displaySettlementCreatedAtDayPrior: settlementCreatedAtDayPrior ? formatDate({ date: settlementCreatedAtDayPrior, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    settlementEntryCount,
    settlementEntryCountPrior,
    settlementAmountDollarsSum,
    displaySettlementAmountDollarsSum: formatMoney({ amount: settlementAmountDollarsSum, currency: USD }),
    settlementAmountDollarsSumPrior,
    displaySettlementAmountDollarsSumPrior: formatMoney({ amount: settlementAmountDollarsSumPrior, currency: USD }),
    settlementAmountDollarsAverage,
    displaySettlementAmountDollarsAverage: formatMoney({ amount: settlementAmountDollarsAverage, currency: USD }),
    settlementAmountDollarsAveragePrior,
    displaySettlementAmountDollarsAveragePrior: formatMoney({ amount: settlementAmountDollarsAveragePrior, currency: USD }),
    daysSincePriorSettlement,
    settlementId,
    settlementIdPrior,
  })
}

const getSettlementSizeDifference = (calculations) => {
  const [
    settlementCreatedAtDay,
    settlementCreatedAtDayPrior,
    settlementEntryCount,
    settlementEntryCountPrior,
    settlementAmountDollarsSum,
    settlementAmountDollarsSumPrior,
    settlementAmountDollarsAverage,
    settlementAmountDollarsAveragePrior,
    settlementSizeDifference,
    settlementId,
    settlementIdPrior,
  ] = getMany(calculations, [
    'settlement_created_at_day',
    'settlement_created_at_day_prior',
    'settlement_entry_count',
    'settlement_entry_count_prior',
    'settlement_amount_dollars_sum',
    'settlement_amount_dollars_sum_prior',
    'settlement_amount_dollars_average',
    'settlement_amount_dollars_average_prior',
    'settlement_size_difference',
    'settlement_id',
    'settlement_id_prior',
  ])

  return removeUndefined({
    settlementCreatedAtDay,
    displaySettlementCreatedAtDay: settlementCreatedAtDay ? formatDate({ date: settlementCreatedAtDay, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    settlementCreatedAtDayPrior,
    displaySettlementCreatedAtDayPrior: settlementCreatedAtDayPrior ? formatDate({ date: settlementCreatedAtDayPrior, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    settlementEntryCount,
    settlementEntryCountPrior,
    settlementAmountDollarsSum,
    displaySettlementAmountDollarsSum: formatMoney({ amount: settlementAmountDollarsSum, currency: USD }),
    settlementAmountDollarsSumPrior,
    displaySettlementAmountDollarsSumPrior: formatMoney({ amount: settlementAmountDollarsSumPrior, currency: USD }),
    settlementAmountDollarsAverage,
    displaySettlementAmountDollarsAverage: formatMoney({ amount: settlementAmountDollarsAverage, currency: USD }),
    settlementAmountDollarsAveragePrior,
    displaySettlementAmountDollarsAveragePrior: formatMoney({ amount: settlementAmountDollarsAveragePrior, currency: USD }),
    settlementSizeDifference: formatPercentage({ percentage: settlementSizeDifference }),
    settlementId,
    settlementIdPrior,
  })
}

const getHighTicket = (calculations) => {
  const [
    transferCreatedAtDay,
    transferCreatedAtDayPrior,
    transferAmountDollars,
    transferAmountDollarsPrior,
    transferAmountDifference,
    transferId,
    transferIdPrior,
  ] = getMany(calculations, [
    'transfer_created_at_day',
    'transfer_created_at_day_prior',
    'transfer_amount_dollars',
    'transfer_amount_dollars_prior',
    'transfer_amount_difference',
    'transfer_id',
    'transfer_id_prior',
  ])

  return removeUndefined({
    transferCreatedAtDay,
    displayTransferCreatedAtDay: transferCreatedAtDay ? formatDate({ date: transferCreatedAtDay, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    transferCreatedAtDayPrior,
    displayTransferCreatedAtDayPrior: transferCreatedAtDayPrior ? formatDate({ date: transferCreatedAtDayPrior, format: CREATED_AT_DEFAULT_FORMAT }) : null,
    transferAmountDollars,
    displayTransferAmountDollars: formatMoney({ amount: transferAmountDollars, currency: USD }),
    transferAmountDollarsPrior,
    displayTransferAmountDollarsPrior: formatMoney({ amount: transferAmountDollarsPrior, currency: USD }),
    transferAmountDifference,
    displayTransferAmountDifference: formatPercentage({ percentage: transferAmountDifference }),
    transferId,
    transferIdPrior,
  })
}

const getDisputes = (calculations) => {
  const [
    transferCount,
    disputeCount,
    transferAmountDollarsSum,
    disputeAmountDollarsSum,
    transferAmountDollarsAverage,
    disputeAmountDollarsAverage,
    disputeRate,
  ] = getMany(calculations, [
    'transfer_count',
    'dispute_count',
    'transfer_amount_dollars_sum',
    'dispute_amount_dollars_sum',
    'transfer_amount_dollars_average',
    'dispute_amount_dollars_average',
    'dispute_rate',
  ])

  return removeUndefined({
    transferCount,
    disputeCount,
    transferAmountDollarsSum,
    displayTransferAmountDollarsSum: formatMoney({ amount: transferAmountDollarsSum, currency: USD }),
    disputeAmountDollarsSum,
    displayDisputeAmountDollarsSum: formatMoney({ amount: disputeAmountDollarsSum, currency: USD }),
    transferAmountDollarsAverage,
    displayTransferAmountDollarsAverage: formatMoney({ amount: transferAmountDollarsAverage, currency: USD }),
    disputeAmountDollarsAverage,
    displayDisputeAmountDollarsAverage: formatMoney({ amount: disputeAmountDollarsAverage, currency: USD }),
    disputeRate,
    displayDisputeRate: formatPercentage({ percentage: disputeRate }),
  })
}

const getCalculations = (ruleType, calculations) => {
  switch (ruleType) {
    case MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_DAILY:
      return getPayoutsVolumeChangeDaily(calculations)
    case MONITORING_ALERT_TYPE.PAYOUTS_VOLUME_CHANGE_WEEKLY:
      return getPayoutsVolumeChangeWeekly(calculations)
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME:
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD:
    case MONITORING_ALERT_TYPE.PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK:
    case MONITORING_ALERT_TYPE.PAYOUTS_GREATER_THAN_10K:
      return getPayouts(calculations)
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_NAME:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_CARD:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_DUPLICATE_RECIPIENTS_BY_BANK:
    case MONITORING_ALERT_TYPE.SPLIT_PAYOUTS_GREATER_THAN_10K:
      return getSplitPayouts(calculations)
    case MONITORING_ALERT_TYPE.INTERNATIONAL_CARD_RATIO:
      return getInternationalTransactions(calculations)
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_NAME:
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_CARD:
    case MONITORING_ALERT_TYPE.DUPLICATE_TRANSFERS_BY_BANK:
    case MONITORING_ALERT_TYPE.REPEAT_DONATIONS:
    case MONITORING_ALERT_TYPE.LENDING_CREDIT_CARD_TRANSFERS:
      return getTransactions(calculations)
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE:
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE_ADMINISTRATIVE:
    case MONITORING_ALERT_TYPE.ACH_RETURN_RATE_UNAUTHORIZED:
      return getACHReturns(calculations)
    case MONITORING_ALERT_TYPE.UNREFERENCED_REFUNDS:
    case MONITORING_ALERT_TYPE.REFUND_RATE:
      return getRefunds(calculations)
    case MONITORING_ALERT_TYPE.FAILED_AUTHORIZATION_RATE:
      return getAuthorizations(calculations)
    case MONITORING_ALERT_TYPE.FIRST_SETTLEMENT:
      return getFirstSettlement(calculations)
    case MONITORING_ALERT_TYPE.HIGH_RATIO_SMALL_DOLLAR_SETTLEMENT:
      return getSmallSettlements(calculations)
    case MONITORING_ALERT_TYPE.DUPLICATE_SETTLEMENT:
      return getDuplicateSettlements(calculations)
    case MONITORING_ALERT_TYPE.DORMANT_MERCHANT_SETTLEMENT:
      return getDormantSettlement(calculations)
    case MONITORING_ALERT_TYPE.SETTLEMENT_SIZE_DIFFERENCE:
      return getSettlementSizeDifference(calculations)
    case MONITORING_ALERT_TYPE.HIGH_TICKET:
      return getHighTicket(calculations)
    case MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK:
    case MONITORING_ALERT_TYPE.DISPUTE_RATE_BY_NETWORK_INITIAL_REVIEW:
    case MONITORING_ALERT_TYPE.DISPUTE_COUNT:
      return getDisputes(calculations)
    default:
      return {}
  }
}

const frontendMonitoringAlertModel = ({ data: alert }) => {
  const [
    id,
    state,
    result,
    createdAt,
    updatedAt,
    periodStart,
    periodEnd,
    linkedTo,
    linkedType,
    linkedName,
    groupedBy,
    monitoringRuleDetails,
    calculations,
  ] = getMany(alert, [
    'id',
    'state',
    'result',
    'created_at',
    'updated_at',
    'period_start',
    'period_end',
    'linked_to',
    'linked_type',
    'linked_name',
    'grouped_by',
    'monitoring_rule_details',
    'calculations',
  ])

  const [
    ruleId,
    ruleType,
    ruleName,
    ruleDescription,
    ruleLinkedType,
    ruleCadenceType,
    ruleCadenceValue,
    ruleIntervalType,
    ruleIntervalValue,
    ruleThresholdType,
    ruleThresholdValue,
  ] = getMany(monitoringRuleDetails, [
    'id',
    'type',
    'nickname',
    'description',
    'linked_type',
    'cadence_type',
    'cadence_value',
    'look_back_interval_type',
    'look_back_interval_value',
    'threshold_type',
    'threshold_value',
  ])

  return removeUndefined({
    id,
    state,
    displayState: startCase(lowerCase(state)),
    result,
    displayResult: toString(formatValueWithSymbol(result, ruleThresholdType)),
    createdAt,
    displayCreatedAt: formatDate({ date: createdAt, format: CREATED_AT_DEFAULT_FORMAT }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt, format: CREATED_AT_DEFAULT_FORMAT }),
    periodStart,
    displayPeriodStart: formatDate({ date: periodStart, format: CREATED_AT_DEFAULT_FORMAT }),
    periodEnd,
    displayPeriodEnd: formatDate({ date: periodEnd, format: CREATED_AT_DEFAULT_FORMAT }),
    linkedTo,
    linkedType,
    displayLinkedType: startCase(lowerCase(linkedType)),
    linkedName,
    groupedBy,
    monitoringRuleDetails: {
      id: ruleId,
      type: ruleType,
      displayType: snakeCaseToTitleCase(ruleType),
      name: ruleName,
      description: ruleDescription,
      linkedType: ruleLinkedType,
      displayLinkedType: startCase(lowerCase(ruleLinkedType)),
      cadenceType: ruleCadenceType,
      displayCadenceType: startCase(lowerCase(ruleCadenceType)),
      cadenceValue: ruleCadenceValue,
      displayCadenceValue: `Every ${ruleCadenceValue} ${singularizeWord({ count: ruleCadenceValue, word: lowerCase(ruleCadenceType) })}`,
      intervalType: ruleIntervalType,
      displayIntervalType: startCase(lowerCase(ruleIntervalType)),
      intervalValue: ruleIntervalValue,
      displayIntervalValue: `Past ${ruleIntervalValue} ${singularizeWord({ count: ruleIntervalValue, word: lowerCase(ruleIntervalType) })}`,
      thresholdType: ruleThresholdType,
      displayThresholdType: startCase(lowerCase(ruleThresholdType)),
      thresholdValue: ruleThresholdValue || 0, // Default to 0 if thresholdValue is null; fallback for problematic BE data
      displayThresholdValue: toString(formatValueWithSymbol(ruleThresholdValue || 0, ruleThresholdType)),
    },
    calculations: getCalculations(ruleType, calculations),
  })
}

export default frontendMonitoringAlertModel
