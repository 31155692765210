import React, { Component } from 'react'
import { connect } from 'react-redux'
import Buyers from './Buyers'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { ADD_BUYER } from 'constants/language/languageConstants'
import { ADD_EDIT_BUYER_INFORMATION_MODAL } from 'constants/modalConstants'
import { BUYERS_PATH } from 'constants/pathConstants'
import get from 'lodash/get'

import {
  EMAIL_ADDRESS_V2,
  FIRST_NAME,
  LAST_NAME,
} from 'constants/filterConstants'

const quickFilters = [
  DATE_QUICK_FILTER,
]

const allowedFilters = [
  EMAIL_ADDRESS_V2,
  FIRST_NAME,
  LAST_NAME,
]

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const mapStateToProps = (state) => {
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const buyersPath = BUYERS_PATH({ credentialId })

  return {
    quickFilters,
    allowedFilters,
    initialQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    buyersPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAddBuyerModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_EDIT_BUYER_INFORMATION_MODAL, modalProps, className: 'modal-md no-pad' })),
  }
}

class BuyersC extends Component {
  render() {
    const { showAddBuyerModal } = this.props

    const actions = [
      {
        label: ADD_BUYER,
        className: 'add-buyer-action',
        action: () => showAddBuyerModal(),
      },
    ]

    return (
      <Buyers
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyersC)
