import React, { Component } from 'react'
import { connect } from 'react-redux'
import OnboardingForms from './OnboardingForms'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { ONBOARDING_FORMS_PATH } from 'constants/pathConstants'
import { CREATE_NEW_ONBOARDING_FORM } from 'constants/modalConstants'
import { CREATE_NEW_FORM } from 'constants/language/languageConstants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'

import {
  DATE_QUICK_FILTER,
  ONBOARDING_FORMS_STATUS_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  APPLICATION_ID,
  IDENTITY_ID,
  ONBOARDING_FORM_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  APPLICATION_ID,
  IDENTITY_ID,
  ONBOARDING_FORM_ID,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  ONBOARDING_FORMS_STATUS_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `onboardingFormsR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const onboardingFormsPath = ONBOARDING_FORMS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)

  return {
    allowedFilters,
    initialQueries: mergedQueries,
    quickFilters,
    isFetching,
    onboardingFormsPath,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateOnboardingFormModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_NEW_ONBOARDING_FORM, modalProps, className: 'modal-md no-pad' })),
  }
}

class OnboardingFormsC extends Component {
  render() {
    const {
      credentials,
      showCreateOnboardingFormModal,
    } = this.props

    const actions = [
      {
        label: CREATE_NEW_FORM,
        action: () => {
          showCreateOnboardingFormModal({ credentials })
        },
        className: 'create-new-onboarding-form-button',
      },
    ]

    return (
      <OnboardingForms
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFormsC)
