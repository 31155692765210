import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetupMFAModal from 'components/Customer/Modals/SetupMFAModal/SetupMFAModal'
import showModalAction from 'utilities/actions/showModalAction'

import {
  CONFIRM_PASSWORD_MFA_MODAL,
  SETUP_MFA_MODAL,
} from 'constants/modalConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmPasswordMFAModal: (modalProps) => dispatch(showModalAction({
      modalType: CONFIRM_PASSWORD_MFA_MODAL,
      modalProps,
      className: 'modal-md no-pad',
    })),
    showSetupMFAModal: () => dispatch(showModalAction({
      modalType: SETUP_MFA_MODAL,
      modalProps: { canCloseModal: false },
      className: 'modal-md no-pad',
    })),
  }
}

class SetupMFAModalC extends Component {
  render() {
    const {
      showConfirmPasswordMFAModal,
      showSetupMFAModal,
    } = this.props

    const showConfirmPasswordModalSMS = () => showConfirmPasswordMFAModal({ type: 'sms', back: showSetupMFAModal })
    const showConfirmPasswordModalAuthenticator = () => showConfirmPasswordMFAModal({ type: 'totp', back: showSetupMFAModal })

    return (
      <SetupMFAModal
        {...this.props}
        showConfirmPasswordModalSMS={showConfirmPasswordModalSMS}
        showConfirmPasswordModalAuthenticator={showConfirmPasswordModalAuthenticator}
      />
    )
  }
}

export default connect(null, mapDispatchToProps)(SetupMFAModalC)
