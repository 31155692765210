import './PaymentLinkCreatedConfirmationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import DictionaryListV2 from 'components/Customer/Shared/Page/DictionaryList/DictionaryListV2'
import Button from 'components/Shared/Button/Button'
import copyToClipboard from 'utilities/copyToClipboard'
import { CHECK_ICON } from 'constants/iconConstants'

import {
  PAYMENT_LINK_DETAILS,
  PAYMENT_LINK_CREATED_SUCCESSFULLY,
  CREATE_NEW_PAYMENT_LINK,
  VIEW_PAYMENT_LINK_DETAILS,
} from 'constants/language/languageConstants'

const PaymentLinkCreatedConfirmation = ({
  paymentLinkData = [],
  goToNewPaymentLinkForm = () => {},
  paymentLinkPath = '',
  linkUrl = '',
}) => {
  return (
    <div className='PaymentLinkCreatedConfirmation'>
      <div className='banner flex'>
        <i className={`check-icon fa fa-${CHECK_ICON}`} />

        <div>
          <h6>{PAYMENT_LINK_CREATED_SUCCESSFULLY}</h6>
          <p className='p-2'><span className='link' onClick={() => copyToClipboard(linkUrl)}>Copy Link URL</span> to send to your buyer. </p>
        </div>
      </div>

      <div className='details'>
        <h5>{PAYMENT_LINK_DETAILS}</h5>

        <DictionaryListV2 data={paymentLinkData} className='payment-link-details' />

        <div className='buttons flex'>
          <Button label={CREATE_NEW_PAYMENT_LINK} onClick={goToNewPaymentLinkForm} />
          <Link to={paymentLinkPath}><Button variant='ghost' label={VIEW_PAYMENT_LINK_DETAILS} /></Link>
        </div>
      </div>
    </div>
  )
}

PaymentLinkCreatedConfirmation.propTypes = {
  paymentLinkData: PropTypes.array,
  goToNewPaymentLinkForm: PropTypes.func,
  paymentLinkPath: PropTypes.string,
  linkUrl: PropTypes.string,
}

export default PaymentLinkCreatedConfirmation
