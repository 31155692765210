import React from 'react'
import PropTypes from 'prop-types'
import ColorcodedStatus from './ColorcodedStatus'
import get from 'lodash/get'

import {
  SUCCEEDED,
  WARNING,
  CANCELED,
  FAILED,
  UNKNOWN,
  RETURNED,
} from 'constants/statusConstants'

const TransferData = {
  SUCCEEDED,
  PENDING: WARNING,
  FAILED,
  CANCELED,
  UNKNOWN,
  RETURNED,
}

const TransferStatus = ({
  value = '',
  rawMessage = '',
}) => {
  let statusDescription
  if (value === FAILED && rawMessage) {
    // TODO: Ask BE Team for a list of supported languages to avoid JSON.parse() errors
    try {
      const errorMessage = get(JSON.parse(rawMessage), ['Errors', 'Error', 0, 'Description'], null)
      if (errorMessage === 'Account not Eligible') {
        statusDescription = errorMessage
      }
    } catch {
      statusDescription = ''
    }
  }
  return (
    <ColorcodedStatus data={TransferData} value={value} statusDescription={statusDescription} />
  )
}

TransferStatus.propTypes = {
  value: PropTypes.string,
  rawMessage: PropTypes.string,
}

export default TransferStatus
