import './ACHReturnsInsightsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GroupedCharts from 'components/Charts/GroupedCharts/GroupedCharts'
import DatePickerNew from 'components/Shared/Inputs/DatePickerNew/DatePickerNew'
import CustomBigNumberC from 'components/Charts/CustomBigNumberChart/CustomBigNumberC'
import LastUpdatedAtC from 'components/Charts/LastUpdatedAt/LastUpdatedAtC'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import ChartC from 'components/Charts/Chart/ChartC'
import Loader from 'components/Shared/Loader/Loader'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import formatPercentage from 'utilities/formatters/formatPercentage'

import {
  ACH_RETURN_COUNT,
  ACH_RETURN_RATE,
  ACH_RETURN_VOLUME,
  ACH_RETURNS_BY_REASON_CODE,
  ACH_RETURNS_BY_REASON_CODE_TOOLTIP,
  ACH_RETURNS_INSIGHTS_TOOLTIP_TITLE,
  ACH_RETURNS_PER_MERCHANT_OR_APPLICATION_TOOLTIP,
  ACH_RETURNS_PER_MERCHANT,
  ACH_RETURNS_PER_MERCHANT_OR_APPLICATION,
  ACH_RETURNS_PER_MERCHANT_TOOLTIP,
  ACH_RETURNS_TRENDS,
  ACH_RETURNS_TRENDS_TOOLTIP,
  SUMMARY,
  TIME_FRAME,
  TOTAL_ACH_VOLUME,
} from 'constants/language/languageConstants'

import {
  ACH_RETURNS_VOLUME_BIG_NUMBER_TOTAL,
  ACH_RETURNS_BY_REASON_CODE_TABLE,
  ACH_RETURN_COUNT_BIG_NUMBER_TOTAL,
  ACH_RETURN_RATE_BIG_NUMBER_TOTAL,
  TOTAL_ACH_VOLUME_BIG_NUMBER_TOTAL,
  ACH_RETURN_VOLUME_AND_COUNT_TIMESERIES_BAR,
  ACH_RETURN_RATE_ECHARTS_TIMESERIES_LINE,
  ACH_RETURNS_BY_MERCHANT_TABLE,
} from 'constants/chartConstants'

const ACHReturnsInsights = ({
  isFetching = true,
  createdAtFilters = {},
  timeRange = {},
  isRolePlatformCredential = false,
  isStandaloneMerchant = false,
}) => {
  if (isFetching) {
    return <div className='mtl'><Loader /></div>
  }

  return (
    <div className='ACHReturnsInsights flex column'>
      <div className='time-frame-container flex space-between'>
        <div className='time-frame flex'>
          <div className='time-frame-title'>{TIME_FRAME}</div>
          <div className='time-frame-content'>
            <DatePickerNew
              name='created_at'
              initialFilters={createdAtFilters}
            />
          </div>
        </div>

        <div className='last-updated'><LastUpdatedAtC /></div>
      </div>

      <div className='content-padding'>
        <div className='flex'>
          <h4>{SUMMARY}</h4>
          <TooltipLabelC
            position='right'
            message={(
              <>
                <p>{ACH_RETURNS_INSIGHTS_TOOLTIP_TITLE}</p>
                <p><span className='bold'>{ACH_RETURN_VOLUME}</span> represents the total monetary volume of ACH transactions that were returned.</p>
                <p><span className='bold'>{ACH_RETURN_COUNT}</span> represents the quantity of ACH transactions that were returned.</p>
                <p><span className='bold'>{ACH_RETURN_RATE}</span> represents the average rate of ACH returns for the filtered time frame.</p>
                <p><span className='bold'>{TOTAL_ACH_VOLUME}</span> represents the total monetary volume of ACH transactions that were successfully processed.</p>
              </>
            )}
          />
        </div>

        <div className='custom-big-numbers grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1'>
          <CustomBigNumberC
            title={ACH_RETURN_VOLUME}
            headerData={{
              name: ACH_RETURNS_VOLUME_BIG_NUMBER_TOTAL,
              formatter: (amount = 0) => formatMoney({ amount: formatNumber(amount) }),
            }}
            timeRange={timeRange}
            style={{ height: 'fit-content' }}
          />

          <CustomBigNumberC
            title={ACH_RETURN_COUNT}
            headerData={{
              name: ACH_RETURN_COUNT_BIG_NUMBER_TOTAL,
              formatter: (count) => `${formatNumber(count)}`,
            }}
            timeRange={timeRange}
            style={{ height: 'fit-content' }}
          />

          <CustomBigNumberC
            title={ACH_RETURN_RATE}
            headerData={{
              name: ACH_RETURN_RATE_BIG_NUMBER_TOTAL,
              formatter: (percentage) => formatPercentage({ percentage, isDecimal: true }),
            }}
            timeRange={timeRange}
            style={{ height: 'fit-content' }}
          />

          <CustomBigNumberC
            title={TOTAL_ACH_VOLUME}
            headerData={{
              name: TOTAL_ACH_VOLUME_BIG_NUMBER_TOTAL,
              formatter: (amount = 0) => formatMoney({ amount: formatNumber(amount) }),
            }}
            timeRange={timeRange}
            style={{ height: 'fit-content' }}
          />
        </div>

        <GroupedCharts
          title={ACH_RETURNS_TRENDS}
          classNames='ach-returns-trends'
          chartClassNames='ach-returns-trends-charts grid grid-cols-2 md:grid-cols-1'
          timeRange={timeRange}
          timeGrainFilters={[
            { name: 'Daily', value: 'daily' },
            { name: 'Weekly', value: 'weekly', active: true },
            { name: 'Monthly', value: 'monthly' },
          ]}
          charts={[
            // TODO: replace all these name fields w/ disputes chart constants
            { name: ACH_RETURN_VOLUME_AND_COUNT_TIMESERIES_BAR, height: 260 },
            { name: ACH_RETURN_RATE_ECHARTS_TIMESERIES_LINE, height: 260 },
          ]}
          tooltipMessage={<p>{ACH_RETURNS_TRENDS_TOOLTIP}</p>}
        />

        { !isStandaloneMerchant && (
          <div className='mtl'>
            <ChartC
              title={isRolePlatformCredential ? ACH_RETURNS_PER_MERCHANT_OR_APPLICATION : ACH_RETURNS_PER_MERCHANT}
              name={ACH_RETURNS_BY_MERCHANT_TABLE}
              height={484}
              className='table'
              timeRange={timeRange}
              hideTitle
              showGroupBy
              showPivots
              tooltipMessage={<p>{isRolePlatformCredential ? ACH_RETURNS_PER_MERCHANT_OR_APPLICATION_TOOLTIP : ACH_RETURNS_PER_MERCHANT_TOOLTIP}</p>}
            />
          </div>
        )}

        <div className='mtl'>
          <ChartC
            title={ACH_RETURNS_BY_REASON_CODE}
            name={ACH_RETURNS_BY_REASON_CODE_TABLE}
            height={484}
            className='table'
            timeRange={timeRange}
            showGroupBy
            hideTitle
            tooltipMessage={<p>{ACH_RETURNS_BY_REASON_CODE_TOOLTIP}</p>}
          />
        </div>
      </div>
    </div>
  )
}

ACHReturnsInsights.propTypes = {
  isFetching: PropTypes.bool,
  createdAtFilters: PropTypes.object,
  timeRange: PropTypes.object,
  isRolePlatformCredential: PropTypes.bool,
  isStandaloneMerchant: PropTypes.bool,
}

export default ACHReturnsInsights
