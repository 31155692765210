import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import { SUBMIT_EDIT_DASHBOARD_ACCOUNT_NAME_FORM } from 'constants/amplitudeConstants'
import { PATCH_CREDENTIAL_F_REQUEST } from 'constants/flowConstants'
import { UPDATE_DASHBOARD_ACCOUNT_NAME_SUCCESS } from 'constants/language/languageConstants'
import get from 'lodash/get'

const submitEditDashboardAccountNameForm = (values, dispatch, props) => {
  const credentialId = get(props, 'credentialId')
  const dashboardName = get(values, 'dashboardName')

  const formValues = {
    credential_name: dashboardName,
  }

  dispatch({
    type: PATCH_CREDENTIAL_F_REQUEST,
    payload: {
      id: credentialId,
      values: formValues,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(SUBMIT_EDIT_DASHBOARD_ACCOUNT_NAME_FORM, {
        credentialId,
      }),
      successCallback: () => {
        dispatch(hideModalAction())
      },
      successMessage: UPDATE_DASHBOARD_ACCOUNT_NAME_SUCCESS,
      customHeaders: {
        'x-finix-api-credential': credentialId,
      },
    },
  })
}

export default submitEditDashboardAccountNameForm
