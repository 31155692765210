import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Refunds/columnDescriptors'
import { getIdentityTransfers } from 'state-layer/selectors'
import { IDENTITY_TRANSFERS } from 'constants/linkConstants'
import { PAYMENTS_ICON } from 'constants/iconConstants'

import {
  GET_IDENTITY_TRANSFERS,
  TRANSFER,
} from 'constants/flowConstants'

const IdentityRefunds = ({
  initialQueries = {},
  allowedFilters = [],
  refundsPath = '',
  isFetching = false,
  identityId = '',
  quickFilters,
  initialFilters,
}) => {
  return (
    <div className='IdentityRefunds'>
      <TableC
        flow={GET_IDENTITY_TRANSFERS}
        columnDescriptors={columnDescriptors}
        selector={getIdentityTransfers}
        selectorId={identityId}
        initialQueries={initialQueries}
        linksKey={IDENTITY_TRANSFERS}
        allowedFilters={allowedFilters}
        path={refundsPath}
        isFetching={isFetching}
        fileNameCSV={`Identity_${identityId}_Refunds`}
        icon={`fa fa-${PAYMENTS_ICON}`}
        quickFilters={quickFilters}
        initialFilters={initialFilters}
        showAdvancedExport
        entityType={TRANSFER}
      />
    </div>
  )
}

IdentityRefunds.propTypes = {
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  refundsPath: PropTypes.string,
  isFetching: PropTypes.bool,
  identityId: PropTypes.string,
  quickFilters: PropTypes.array,
  initialFilters: PropTypes.object,
}

export default IdentityRefunds
