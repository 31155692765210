import { ADMINISTRATOR } from 'constants/pathConstants'
import split from 'lodash/split'
import head from 'lodash/head'

const isAdministratorView = (path) => {
  const topLevelPath = head(split(path, '/'))

  return topLevelPath === ADMINISTRATOR
}

export default isAdministratorView
