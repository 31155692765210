import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import SearchForm from './SearchForm'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import redirectRequest from 'utilities/actions/redirectRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { SEARCH_FORM } from 'constants/formConstants'
import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import trim from 'lodash/trim'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `searchR.${FETCHING}`)
  const formSelector = formValueSelector(SEARCH_FORM)
  const currentInput = formSelector(state, 'input')
  const modalOpen = get(state, 'modalR.isOpen')
  const searchResult = get(state, 'searchR.items', {})

  const matchResult = filter(searchResult, ({ id, traceId }) => includes([id, traceId], trim(currentInput)))

  return {
    credentials,
    isFetching,
    currentInput,
    modalOpen,
    matchResult,
  }
}

class SearchFormC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newSearch: false,
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress)
  }

  componentDidUpdate(prevProps) {
    const { currentInput: prevInput } = prevProps
    const { currentInput } = this.props

    if (currentInput !== prevInput) {
      this.setState({ newSearch: true })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress)
  }

  onSelectionClick = (id) => {
    const { idName, path } = getIDPrefixMapping(id)
    const { credentials, dispatch } = this.props

    this.setNewSearch(false)

    dispatch(redirectRequest({
      path: path({
        credentialId: get(credentials, 'id'),
        [idName]: id,
      }),
    }))
  }

  setNewSearch = (status) => {
    this.setState({ newSearch: status })
  }

  handleKeypress = (event) => {
    // TODO refactor to be more React-like
    const { modalOpen, dispatch } = this.props
    const { activeElement } = document
    const searchFormInput = document.querySelector('.SearchForm input')

    if (!modalOpen && (get(activeElement, 'type') !== 'text' || activeElement === searchFormInput)) {
      if (event.key === 'Escape') {
        dispatch(change(SEARCH_FORM, 'input', ''))
        searchFormInput.focus()
      }

      if (event.key === '/') {
        event.preventDefault()
        searchFormInput.select()
      }
    }
  }

  handleFocus = () => {
    const searchFormInput = document.querySelector('.SearchForm input')
    searchFormInput.select()
  }

  render() {
    const { newSearch } = this.state
    const { handleFocus, setNewSearch, onSelectionClick } = this
    return (
      <SearchForm
        handleFocus={handleFocus}
        newSearch={newSearch}
        setNewSearch={setNewSearch}
        onSelectionClick={onSelectionClick}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(SearchFormC)
