import './ReceiptItemsS.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import stripNonNumericChars from 'utilities/string/stripNonNumericChars'
import size from 'lodash/size'

import {
  QUANTITY,
  IMAGE_URL,
  UNIT_PRICE,
  DESCRIPTION,
  PRODUCT_OR_SERVICE_NAME,
  NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS,
  OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY,
  DELETE_ITEM,
  ADD_ANOTHER_ITEM,
} from 'constants/language/languageConstants'

import {
  ADD_ICON,
  TRASH_ALT_ICON,
} from 'constants/iconConstants'

const ReceiptItems = ({
  fields,
  productDetails,
}) => {
  useEffect(() => {
    if (productDetails && size(fields) === 0) {
      fields.push({})
    }
  }, [productDetails, fields])

  return (
    <div className='ReceiptItems'>
      { fields.map((item, index) => {
        const key = `item_${index}`

        return (
          <div key={key} className='item'>
            <Field
              name={`${item}.name`}
              label={PRODUCT_OR_SERVICE_NAME}
              placeholder={NAME_OF_ITEM_OR_SERVICE_SHOWN_TO_CUSTOMERS}
              component={InputField}
              className='name'
            />

            <Field
              name={`${item}.description`}
              label={DESCRIPTION}
              placeholder={OPTIONAL_DESCRIPTION_SHOWN_TO_CUSTOMERS_WHEN_THEY_PAY}
              component={InputField}
              required={false}
            />

            <div className='flex space-between'>
              <AmountField
                name={`${item}.saleAmount`}
                label={UNIT_PRICE}
                placeholder='0.00'
              />

              <Field
                name={`${item}.quantity`}
                label={QUANTITY}
                placeholder={QUANTITY}
                component={InputField}
                format={stripNonNumericChars}
                required={false}
              />
            </div>

            <Field
              name={`${item}.primaryImageUrl`}
              label={IMAGE_URL}
              placeholder='https://www.example.com/123rw21w.jpg'
              component={InputField}
              required={false}
            />

            { size(fields) > 1 && (
              <div className='delete-item flex' onClick={() => fields.remove(index)}>
                <i className={`fal fa-${TRASH_ALT_ICON}`} />
                <span>{DELETE_ITEM}</span>
              </div>
            )}
          </div>
        )
      })}

      <div className='add-item flex' onClick={() => fields.push({})}>
        <i className={`fal fa-${ADD_ICON}`} />
        <span>{ADD_ANOTHER_ITEM}</span>
      </div>
    </div>
  )
}

ReceiptItems.propTypes = {
  fields: PropTypes.object,
}

export default ReceiptItems
