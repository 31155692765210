import './CreateOnboardingFormDetailsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Button from 'components/Shared/Button/Button'
import CheckboxGroupField from 'components/Shared/Inputs/CheckboxGroupField/CheckboxGroupField'
import prevFlowStep from 'utilities/prevFlowStep'
import submitCreateOnboardingFormDetailsForm from 'utilities/submit/submitCreateOnboardingFormDetailsForm'
import validateCreateOnboardingFormDetailsForm from 'utilities/validate/validateCreateOnboardingFormDetailsForm'
import { CREATE_ONBOARDING_FORM_DETAILS_FORM } from 'constants/formConstants'

import {
  PAYMENT_PROCESSORS,
  PAYMENT_PROCESSORS_SUBTITLE,
  MAX_CARD_TRANSACTION_AMOUNT,
  MAX_ACH_TRANSACTION_AMOUNT,
  CANCEL,
  CREATE_FORM,
  PREVIOUS_STEP,
  MAX_ACH_TRANSACTION_AMOUNT_HELPER_TEXT,
  MAX_CARD_TRANSACTION_AMOUNT_HELPER_TEXT,
} from 'constants/language/languageConstants'

const CreateOnboardingFormDetailsForm = ({
  handleSubmit = () => {},
  processorCheckboxOptions = [],
  isCanadianOnboardingForm = false,
  closeModal = () => {},
  invalid,
  isFetchingProcessors = false,
}) => {
  return (
    <form className='CreateOnboardingFormDetailsForm' onSubmit={handleSubmit(submitCreateOnboardingFormDetailsForm)}>
      <div className='section'>
        <AmountField
          name='maxTransactionAmount'
          label={MAX_CARD_TRANSACTION_AMOUNT}
          helperText={MAX_CARD_TRANSACTION_AMOUNT_HELPER_TEXT}
        />

        {!isCanadianOnboardingForm && (
          <AmountField
            name='maxACHTransactionAmount'
            label={MAX_ACH_TRANSACTION_AMOUNT}
            helperText={MAX_ACH_TRANSACTION_AMOUNT_HELPER_TEXT}
          />
        )}

        <Field
          label={PAYMENT_PROCESSORS}
          helperText={PAYMENT_PROCESSORS_SUBTITLE}
          name='paymentProcessors'
          component={CheckboxGroupField}
          options={processorCheckboxOptions}
          emptyOptionsMessage='Please reach out to Finix Support or your Finix point of contact to enable payment processors on your account.'
          emptyOptionsTitle='No processor enabled for this account'
          isFetchingOptions={isFetchingProcessors}
        />
      </div>

      <div className='buttons flex space-between'>
        <div className='previous-step-container'>
          <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
        </div>
        <div className='flex'>
          <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button type='submit' label={CREATE_FORM} disabled={invalid} />
        </div>
      </div>
    </form>
  )
}

CreateOnboardingFormDetailsForm.propTypes = {
  handleSubmit: PropTypes.func,
  processorCheckboxOptions: PropTypes.array,
  isCanadianOnboardingForm: PropTypes.bool,
  closeModal: PropTypes.func,
  invalid: PropTypes.bool,
  isFetchingProcessors: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_ONBOARDING_FORM_DETAILS_FORM,
  validate: validateCreateOnboardingFormDetailsForm,
})(CreateOnboardingFormDetailsForm)
