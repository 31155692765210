import nextFlowStep from 'utilities/nextFlowStep'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_5 } from 'constants/amplitudeConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

import {
  PATCH_ACCESS_FORM_F_REQUEST,
  PATCH_GUEST_ACCESS_FORM_F_REQUEST,
} from 'constants/flowConstants'

const submitLiveAccountApplicationBankAccountInfoForm = (values, dispatch, props = {}) => {
  const accessFormId = get(props, 'accessFormId')
  const dashboardType = get(props, 'dashboardType')
  const country = get(props, 'accessFormCountry')
  const isHostedAccessForm = get(props, 'isHostedAccessForm', false)
  const showBankFieldset = get(props, 'showBankFieldset')
  const plaidProcessorToken = get(props, 'plaidProcessorToken')

  // overwrite any manual or plaid data when submitting a new bank account
  const valuesToSubmit = !showBankFieldset ? {
    bankAccountData: {
      thirdParty: 'PLAID',
      thirdPartyToken: plaidProcessorToken,
      name: null,
      bankCode: null,
      accountNumber: null,
      accountType: null,
      transitNumber: null,
      institutionNumber: null,
      country: null,
      currency: null,
      bankAccountVerification: null,
    },
  } : merge({}, values, {
    bankAccountData: {
      thirdParty: null,
      thirdPartyToken: null,
    },
    country,
  })

  dispatch({
    type: isHostedAccessForm ? PATCH_GUEST_ACCESS_FORM_F_REQUEST : PATCH_ACCESS_FORM_F_REQUEST,
    payload: {
      id: accessFormId,
      values: valuesToSubmit,
    },
    meta: {
      successCallback: nextFlowStep,
      actionId: sendAmplitudeActionEvent(SUBMIT_LIVE_ACCOUNT_APPLICATION_STEP_5, {
        accessFormId,
        dashboardType,
      }),
      overwriteReducer: true,
    },
  })
}

export default submitLiveAccountApplicationBankAccountInfoForm
