import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { CREATE_NOTE } from 'constants/amplitudeConstants'
import { POST_NOTE_F_REQUEST } from 'constants/flowConstants'

const postNoteRequest = ({
  linkedTo,
  content,
  jsonContent,
  firstName,
  lastName,
  email,
  tags,
  status,
  credentials,
  successCallback = () => {},
  flashNotification,
}) => createAction({
  type: POST_NOTE_F_REQUEST,
  values: {
    linkedTo,
    content,
    status,
    tags,
    firstName,
    lastName,
    email,
    jsonContent,
  },
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_NOTE, {
      credentials,
    }),
    successCallback,
    flashNotification,
  },
})

export default postNoteRequest
