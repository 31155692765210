import './NoteS.scss'
import 'react-quill/dist/quill.snow.css'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import getMany from 'utilities/get/getMany'
import get from 'lodash/get'
import toUpper from 'lodash/toUpper'

import {
  SHOW_MORE,
  SHOW_LESS,
} from 'constants/language/languageConstants'

const Note = ({
  note = {},
  readOnly = true,
  quillRef,
  overflowY = false,
  jsonContent = {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const showMoreLabel = isExpanded ? SHOW_LESS : SHOW_MORE
  const quillContainerClass = isExpanded ? 'quill-container expanded' : 'quill-container'
  const noteContainerClass = isExpanded ? 'note-container expanded' : 'note-container'
  const modules = { toolbar: false }

  const [
    createdAt,
    firstName,
    lastName,
  ] = getMany(note, [
    'displayCreatedAt',
    'firstName',
    'lastName',
  ])

  const initial = toUpper(get(firstName, '[0]'))

  return (
    <div className='Note'>
      <div className='name-container'>
        <div className='initial'>{initial}</div>
        <div className='full-name'>
          <p>{firstName} {lastName}</p>
        </div>
        <p className='created-at'>{createdAt}</p>
      </div>

      <div className={noteContainerClass}>
        { overflowY && <div className='text-mask' /> }
        <div className='quill-container'>
          <div className={quillContainerClass}>
            <ReactQuill
              theme='snow'
              value={jsonContent}
              readOnly={readOnly}
              modules={modules}
              ref={quillRef}
            />
          </div>
        </div>
      </div>

      { overflowY && (
        <div className='show-more-container'>
          <p onClick={() => setIsExpanded(!isExpanded)}>{showMoreLabel}</p>
        </div>
      )}
    </div>
  )
}

Note.propTypes = {
  note: PropTypes.object,
  readOnly: PropTypes.bool,
  quillRef: PropTypes.object,
  overflowY: PropTypes.bool,
}

export default Note
