import React from 'react'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'
import { COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING } from 'constants/complianceConstants'
import get from 'lodash/get'

import {
  ID,
  CREATED_ON,
  NAME,
  FORM_TYPE,
  FORMS_GENERATED,
  LINKED_NAME,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'displayCreatedAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(NAME, 'name', { className: 'large' }),
  column(FORM_TYPE, 'type', {
    className: 'medium',
    formatter: ({ type }) => COMPLIANCE_FORM_TEMPLATE_TYPE_MAPPING[type],
  }),
  column(LINKED_NAME, 'linkedName', {
    formatter: ({ application, platform }) => {
      return get(application, 'businessName') || get(platform, 'name')
    },
  }),
  column(FORMS_GENERATED, 'formsCount', {
    className: 'medium',
  }),
]

export default columnDescriptors
