import frontendReceiptModel from 'utilities/create/models/frontendReceiptModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendReceiptsModel = ({ data: receipts }) => {
  const receiptModels = map(receipts, (receipt) => frontendReceiptModel({ data: receipt }))

  return keyBy(receiptModels, 'id')
}

export default frontendReceiptsModel
