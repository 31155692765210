import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { DASHBOARD_SERVICE_POST_ONBOARDING_FORM_DATA } from 'constants/apiConstants'

const postOnboardingFormDataAPI = ({ values, meta }) => {
  const path = DASHBOARD_SERVICE_POST_ONBOARDING_FORM_DATA

  return guestDashboardAPI.post({
    path,
    values,
    meta,
  })
}

export default postOnboardingFormDataAPI
