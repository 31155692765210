import './GoogleMapDetailsModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import BusinessLocationTabs from 'components/Customer/Pages/UnderwritingV2/BusinessLocation/BusinessLocationTabs'
import { MAP_DETAILS } from 'constants/language/languageConstants'

const GoogleMapDetailsModal = ({
  doingBusinessAs = '',
  businessName = '',
  businessAddress = {},
}) => {
  return (
    <GenericModal
      title={MAP_DETAILS}
      className='GoogleMapDetailsModal'
      Component={BusinessLocationTabs}
      doingBusinessAs={doingBusinessAs}
      businessName={businessName}
      businessAddress={businessAddress}
    />
  )
}

GoogleMapDetailsModal.propTypes = {
  doingBusinessAs: PropTypes.string,
  businessName: PropTypes.string,
  businessAddress: PropTypes.object,
}

export default GoogleMapDetailsModal
