import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  SPLIT_TRANSFER,
  SPLIT_TRANSFERS,
} from 'constants/apiConstants'

const getSplitTransferAPI = ({ id, credentials, meta, queries }) => {
  return paymentsAPI.get({
    path: SPLIT_TRANSFER({ splitTransferId: id }),
    credentials,
    meta,
    queries,
    service: SPLIT_TRANSFERS,
  })
}

export default getSplitTransferAPI
