import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_ACCESS_FORM } from 'constants/apiConstants'

const patchAccessFormAPI = ({ id, values }) => {
  return dashboardAPI.patch({
    path: DASHBOARD_ACCESS_FORM({ id }),
    values,
  })
}

export default patchAccessFormAPI
