import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import formatRadioDisplayData from 'utilities/formatters/formatRadioDisplayData'
import { YES_NO_YESCCW_NA_RADIO_OPTIONS } from 'constants/complianceConstants'

const ComplianceAssessmentInformationFields = [
  {
    name: 'vendorSuppliedDefaultsChangedBeforeInstallOnNetwork',
    label: 'Question 2.1 (a)',
    formatter: (vendorSuppliedDefaultsChangedBeforeInstallOnNetwork) => formatRadioDisplayData({ value: vendorSuppliedDefaultsChangedBeforeInstallOnNetwork, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork',
    label: 'Question 2.1 (b)',
    formatter: (unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork) => formatRadioDisplayData({ value: unnecessaryDefaultAccountsRemovedOrDisabledBeforeInstallOnNetwork, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'systemAndSoftwareProtectedFromKnownVulnerabilities',
    label: 'Question 6.2 (a)',
    formatter: (systemAndSoftwareProtectedFromKnownVulnerabilities) => formatRadioDisplayData({ value: systemAndSoftwareProtectedFromKnownVulnerabilities, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'criticalSecurityPatchesInstalledWithinOneMonthOfRelease',
    label: 'Question 6.2 (b)',
    formatter: (criticalSecurityPatchesInstalledWithinOneMonthOfRelease) => formatRadioDisplayData({ value: criticalSecurityPatchesInstalledWithinOneMonthOfRelease, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'usersAssignedUniqueIdBeforeAccessToSystem',
    label: 'Question 8.1.1',
    formatter: (usersAssignedUniqueIdBeforeAccessToSystem) => formatRadioDisplayData({ value: usersAssignedUniqueIdBeforeAccessToSystem, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'accessForTerminatedUsersRemoved',
    label: 'Question 8.1.3',
    formatter: (accessForTerminatedUsersRemoved) => formatRadioDisplayData({ value: accessForTerminatedUsersRemoved, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'authenticateUsersUsingPasswordOrTokenOrBiometric',
    label: 'Question 8.2',
    formatter: (authenticateUsersUsingPasswordOrTokenOrBiometric) => formatRadioDisplayData({ value: authenticateUsersUsingPasswordOrTokenOrBiometric, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'userPasswordParametersConfiguredToMeetReqs',
    label: 'Question 8.2.3 (a)',
    formatter: (userPasswordParametersConfiguredToMeetReqs) => formatRadioDisplayData({ value: userPasswordParametersConfiguredToMeetReqs, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'groupSharedAccountsPasswordsAuthenticationMethodsProhibited',
    label: 'Question 8.5',
    formatter: (groupSharedAccountsPasswordsAuthenticationMethodsProhibited) => formatRadioDisplayData({ value: groupSharedAccountsPasswordsAuthenticationMethodsProhibited, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'allMediaPhysicallySecured',
    label: 'Question 9.5',
    formatter: (allMediaPhysicallySecured) => formatRadioDisplayData({ value: allMediaPhysicallySecured, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'strictControlOverDistributionOfMedia',
    label: 'Question 9.6 (a)',
    formatter: (strictControlOverDistributionOfMedia) => formatRadioDisplayData({ value: strictControlOverDistributionOfMedia, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'mediaClassifiedToDetermineSensitivityOfData',
    label: 'Question 9.6 (b)',
    formatter: (mediaClassifiedToDetermineSensitivityOfData) => formatRadioDisplayData({ value: mediaClassifiedToDetermineSensitivityOfData, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'mediaSentCanBeAccuratelyTracked',
    label: 'Question 9.6.2',
    formatter: (mediaSentCanBeAccuratelyTracked) => formatRadioDisplayData({ value: mediaSentCanBeAccuratelyTracked, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'managementApprovalObtainedToMoveMedia',
    label: 'Question 9.6.3',
    formatter: (managementApprovalObtainedToMoveMedia) => formatRadioDisplayData({ value: managementApprovalObtainedToMoveMedia, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'strictControlMaintainedOverStorageAndAccessibilityOfMedia',
    label: 'Question 9.7',
    formatter: (strictControlMaintainedOverStorageAndAccessibilityOfMedia) => formatRadioDisplayData({ value: strictControlMaintainedOverStorageAndAccessibilityOfMedia, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'mediaDestroyedWhenNoLongerNeeded',
    label: 'Question 9.8 (a)',
    formatter: (mediaDestroyedWhenNoLongerNeeded) => formatRadioDisplayData({ value: mediaDestroyedWhenNoLongerNeeded, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'hardCopyMaterialsCrosscutShreddedIncineratedOrPulped',
    label: 'Question 9.8.1 (a)',
    formatter: (hardCopyMaterialsCrosscutShreddedIncineratedOrPulped) => formatRadioDisplayData({ value: hardCopyMaterialsCrosscutShreddedIncineratedOrPulped, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'storageContainersForMaterialsDestroyedToPreventAccessToContents',
    label: 'Question 9.8.1 (b)',
    formatter: (storageContainersForMaterialsDestroyedToPreventAccessToContents) => formatRadioDisplayData({ value: storageContainersForMaterialsDestroyedToPreventAccessToContents, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'listOfServiceProvidersMaintained',
    label: 'Question 12.8.1',
    formatter: (listOfServiceProvidersMaintained) => formatRadioDisplayData({ value: listOfServiceProvidersMaintained, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'writtenAgreementMaintained',
    label: 'Question 12.8.2',
    formatter: (writtenAgreementMaintained) => formatRadioDisplayData({ value: writtenAgreementMaintained, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'establishedProcessForEngagingServicesProviders',
    label: 'Question 12.8.3',
    formatter: (establishedProcessForEngagingServicesProviders) => formatRadioDisplayData({ value: establishedProcessForEngagingServicesProviders, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually',
    label: 'Question 12.8.4',
    formatter: (programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually) => formatRadioDisplayData({ value: programMaintainedToMonitorServiceProvidersPciDssStatusAtLeastAnnually, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity',
    label: 'Question 12.8.5',
    formatter: (informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity) => formatRadioDisplayData({ value: informationMaintainedAboutWhichPciDssReqsPerServiceProviderAndManagedByEntity, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
  {
    name: 'incidentResponsePlanBeenCreatedInCaseOfSystemBreach',
    label: 'Question 12.8.10 (a)',
    formatter: (incidentResponsePlanBeenCreatedInCaseOfSystemBreach) => formatRadioDisplayData({ value: incidentResponsePlanBeenCreatedInCaseOfSystemBreach, options: YES_NO_YESCCW_NA_RADIO_OPTIONS }),
  },
]

const ComplianceSelfAssessmentQuestionnaireADisplayData = (values) => map(ComplianceAssessmentInformationFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name)) : get(values, name),
}))

export default ComplianceSelfAssessmentQuestionnaireADisplayData
