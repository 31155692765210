import './CreateSubscriptionFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Button from 'components/Shared/Button/Button'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Loader from 'components/Shared/Loader/Loader'
import validateCreateSubscriptionForm from 'utilities/validate/validateCreateSubscriptionForm'
import submitCreateSubscriptionBillingForm from 'utilities/submit/submitCreateSubscriptionBillingForm'
import formatNumberFormatField from 'utilities/forms/format/formatNumberFormatField'
import parseNumber from 'utilities/forms/parse/parseNumber'
import { CREATE_SUBSCRIPTION_FORM } from 'constants/formConstants'
import { USD } from 'constants/currencyConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ADD_A_TRIAL_PHASE_LABEL,
  ADD_A_TRIAL_PHASE_TOOLTIP,
  BILLING_FREQUENCY,
  BILLING_SETTING,
  BILLING_SETTINGS,
  BUYER_NAME_PLACEHOLDER,
  CANCEL,
  CREATE_A_SUBSCRIPTION,
  CREATE_A_SUBSCRIPTION_SUBTITLE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_WITHOUT_PLAN_LABEL,
  CREATE_SUBSCRIPTION_LEGAL_LANGUAGE,
  CUSTOMER,
  OPTIONAL_ADD_ONS,
  PAYMENT_INSTRUMENT_LABEL,
  RECURRING_PRICE,
  SELECT_A_CUSTOMER_LABEL,
  SELECT_A_PLAN,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_NAME,
  SUBSCRIPTION_NAME_TOOLTIP,
  SUBSCRIPTION_PHASE,
  SUBSCRIPTION_NAME_PLACEHOLDER,
  TRIAL_DURATION,
  TRIAL_HELPER_TEXT,
  FREE_TRIAL_PHASE,
} from 'constants/language/languageConstants'

import {
  intervalTypeOptions,
  subscriptionCollectionMethod,
  subscriptionFrequencyOptions,
} from 'constants/subscriptionsConstants'

const CreateSubscriptionForm = ({
  handleSubmit = () => {},
  identityPaymentInstrumentOptions = [],
  invalid = false,
  reactSelectCustomStyles = {},
  isPosting = false,
  showTrialPhaseDetails = false,
  plan = {},
  trialPhaseSectionData = [],
  subscriptionPhaseSectionData = [],
  subscriptionPlansOptions = [],
  createCustomPlan = false,
  sortedIdentitiesNames = [],
  isFetchingSubscriptionPlans = false,
}) => {
  const customOptionStyles = get(reactSelectCustomStyles, 'customOption', {})
  const optionStyles = get(reactSelectCustomStyles, 'option', {})

  return (
    <form className='CreateSubscriptionForm' onSubmit={handleSubmit(submitCreateSubscriptionBillingForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{CREATE_A_SUBSCRIPTION}</h3>
        <div className='p-1'>{CREATE_A_SUBSCRIPTION_SUBTITLE}</div>
      </div>

      {isFetchingSubscriptionPlans && <Loader />}

      {!isFetchingSubscriptionPlans && (
        <>
          <RequiredFieldNotice />
          <div className='form-content'>
            <div className='subscription-details-section'>
              <h5 className='subtitle'>{SUBSCRIPTION_DETAILS}</h5>

              <Field
                label={CREATE_SUBSCRIPTION_WITHOUT_PLAN_LABEL}
                name='createSubscriptionWithoutPlanCheckbox'
                component={CheckboxC}
              />

              { !createCustomPlan && (
                <Field
                  name='plan'
                  className='select-a-plan-field'
                  component={ReactSelect}
                  label={SELECT_A_PLAN}
                  required={false}
                  options={subscriptionPlansOptions}
                />
              )}

              { !createCustomPlan && !isEmpty(plan) && (
                <div className='subscription-plan-settings'>
                  <h6 className='trial-phase-title'>{FREE_TRIAL_PHASE}</h6>
                  <PageSectionV2C
                    className='trial-phase-details'
                    columns={convertPageSectionDataToV2(trialPhaseSectionData, 1)}
                  />

                  <h6 className='subscription-phase-title'>{SUBSCRIPTION_PHASE}</h6>
                  <PageSectionV2C
                    className='subscription-phase-details'
                    columns={convertPageSectionDataToV2(subscriptionPhaseSectionData, 1)}
                  />
                </div>
              )}

              { createCustomPlan && (
                <Field
                  name='nickname'
                  className='nickname-field'
                  label={SUBSCRIPTION_NAME}
                  component={InputField}
                  tooltip={SUBSCRIPTION_NAME_TOOLTIP}
                  tooltipPosition='right'
                  placeholder={SUBSCRIPTION_NAME_PLACEHOLDER}
                />
              )}
            </div>

            { createCustomPlan && (
              <div className='billing-settings-section'>
                <h5 className='subtitle'>{BILLING_SETTINGS}</h5>
                <div className='flex row items-flex-start'>
                  <Field
                    name='billingInterval'
                    className='billing-interval-field'
                    label={BILLING_FREQUENCY}
                    component={ReactSelect}
                    options={subscriptionFrequencyOptions}
                  />
                  <AmountField
                    name='amount'
                    className='billing-amount-field'
                    label={RECURRING_PRICE}
                    contextLabel={USD}
                  />
                </div>
                <Field
                  name='collectionMethod'
                  className='collection-method-field'
                  label={BILLING_SETTING}
                  component={ReactSelect}
                  options={subscriptionCollectionMethod}
                  isDisabled
                  placeholder='Automatically bill card on file'
                />
              </div>
            )}

            { createCustomPlan && (
              <div className='trial-options-section'>
                <h5 className='subtitle'>{OPTIONAL_ADD_ONS}</h5>
                <Field
                  name='enableTrialPhase'
                  className='enable-trial-phase-field'
                  component={ToggleSwitchC}
                  label={ADD_A_TRIAL_PHASE_LABEL}
                  tooltip={ADD_A_TRIAL_PHASE_TOOLTIP}
                  tooltipPosition='right'
                  formName={CREATE_SUBSCRIPTION_FORM}
                />

                { createCustomPlan && showTrialPhaseDetails && (
                  <>
                    <div className='trial-details flex row items-flex-start'>
                      <Field
                        name='trialIntervalCount'
                        label={TRIAL_DURATION}
                        component={InputField}
                        format={formatNumberFormatField}
                        parse={parseNumber}
                        placeholder='30'
                      />

                      <Field
                        name='trialIntervalType'
                        className='trial-interval-type-field'
                        component={ReactSelect}
                        options={intervalTypeOptions}
                      />
                    </div>

                    <div className='trial-helper-text p-2'>{TRIAL_HELPER_TEXT}</div>
                  </>
                )}
              </div>
            )}

            <div className='customer-details-section'>
              <h5 className='subtitle'>{CUSTOMER}</h5>
              <Field
                name='buyerIdentityId'
                className='buyer-identity-field'
                label={SELECT_A_CUSTOMER_LABEL}
                component={ReactSelect}
                options={sortedIdentitiesNames}
                placeholder={BUYER_NAME_PLACEHOLDER}
                maxMenuHeight={200}
                customStyles={{
                  option: (styles, state) => (state.data.value === 'newBuyer' ? customOptionStyles : optionStyles),
                }}
              />

              <Field
                name='buyerInstrumentId'
                className='payment-instrument-field'
                label={PAYMENT_INSTRUMENT_LABEL}
                component={ReactSelect}
                options={identityPaymentInstrumentOptions}
                customStyles={{
                  option: (styles, state) => (state.data.value === 'newPaymentInstrument' ? customOptionStyles : optionStyles),
                }}
              />
            </div>

            <div className='description flex p-2'>
              <div className='description-label'>{CREATE_SUBSCRIPTION_LEGAL_LANGUAGE}</div>
            </div>
          </div>

          <div className='form-footer'>
            <div className='footer-button-container'>
              <div className='exit-and-save-container'>
                <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
                <Button className='submit-button' type='submit' disabled={invalid || isPosting} submitting={isPosting} label={CREATE_SUBSCRIPTION} />
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

CreateSubscriptionForm.propTypes = {
  identityPaymentInstrumentOptions: PropTypes.array,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  reactSelectCustomStyles: PropTypes.object,
  isPosting: PropTypes.bool,
  showTrialPhaseDetails: PropTypes.bool,
  createCustomPlan: PropTypes.bool,
  sortedIdentitiesNames: PropTypes.array,
  isFetchingSubscriptionPlans: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_SUBSCRIPTION_FORM,
  validate: validateCreateSubscriptionForm,
})(CreateSubscriptionForm)
