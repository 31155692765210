import './EditPayoutSettingsConfirmationModalS.scss'
import React from 'react'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import submitEditPayoutSettingsForm from 'utilities/submit/submitEditPayoutSettingsForm'
import Button from 'components/Shared/Button/Button'

import {
  UPDATE_PAYOUT_SETTINGS_CONFIRMATION,
  UPDATE_PAYOUT_SETTINGS_CONFIRMATION_DESCRIPTION,
  CANCEL,
  UPDATE,
} from 'constants/language/languageConstants'

const EditPayoutSettingsWarningConfirmation = ({
  handleSubmit = () => {},
  closeModal = () => {},
  isPatching = false,
}) => {
  return (
    <GenericModal
      title={UPDATE_PAYOUT_SETTINGS_CONFIRMATION}
      className='EditPayoutSettingsWarningConfirmation'
      closeModal={closeModal}
      Component={() => (
        <>
          <div>{UPDATE_PAYOUT_SETTINGS_CONFIRMATION_DESCRIPTION}</div>

          <div className='buttons flex flex-end items-center'>
            <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
            <Button onClick={handleSubmit(submitEditPayoutSettingsForm)} label={UPDATE} disabled={isPatching} />
          </div>
        </>
      )}
    />
  )
}

export default EditPayoutSettingsWarningConfirmation
