import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import { EDIT_ICON, PLATFORMS_ICON } from 'constants/iconConstants'
import get from 'lodash/get'

const isEmptyField = fields => {
  return get(fields, 'name') === 'empty'
}

const PlatformProcessorConfigs = ({ fields, sectionId, emptyMessage, updateConfigValue }) => (
  <ul className='PlatformProcessorConfigs flex wrap'>
    { !isEmptyField(fields) && fields ? fields.map((field, index) => {
      const key = `field_${index}`
      return (
        <li key={key} className='flex field-item'>
          <Field
            disabled
            name={`${field}.name`}
            placeholder='key'
            component={InputField}
            className='key'
          />
          <Field
            name={`${field}.value`}
            disabled
            placeholder=''
            component={InputField}
            className='editValue'
          />
          <Button className='update-config-value-button' onClick={() => updateConfigValue(index, sectionId)} icon={EDIT_ICON} />
        </li>
      )
    }) : (
      <div className='emptyConfig'>
        <div className={`fa fa-${PLATFORMS_ICON}`} />
        <div className='emptyMessage'>{emptyMessage}</div>
      </div>
    )}

  </ul>
)

PlatformProcessorConfigs.propTypes = {
  fields: PropTypes.object,
  sectionId: PropTypes.string,
  updateConfigValue: PropTypes.func,
}

export default PlatformProcessorConfigs
