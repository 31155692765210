import { GET_AUTHORIZATION_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAuthorizationRequest = ({
  authorizationId,
  credentials,
}) => createAction({
  type: GET_AUTHORIZATION_F_REQUEST,
  id: authorizationId,
  credentials,
})

export default getAuthorizationRequest
