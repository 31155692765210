import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  roleAdminAccessLevels,
  roleAdminAccessPermissionMap,
} from 'constants/userPermissions/roleAdminPermissions'

import {
  rolePartnerAccessLevels,
  rolePartnerAccessPermissionMap,
} from 'constants/userPermissions/rolePartnerPermissions'

import {
  rolePlatformAccessLevels,
  rolePlatformAccessPermissionMap,
} from 'constants/userPermissions/rolePlatformPermissions'

import {
  roleMerchantAccessLevels,
  roleMerchantAccessPermissionMap,
} from 'constants/userPermissions/roleMerchantPermissions'

import {
  ROLE_ADMIN,
  ROLE_PLATFORM,
  ROLE_PARTNER,
  ROLE_MERCHANT,
} from 'constants/roleConstants'

export const ENABLE_ALL = 'Enable All'
export const DISABLE_ALL = 'Disable All'

export const roleAccessLevels = {
  [ROLE_ADMIN]: roleAdminAccessLevels,
  [ROLE_PLATFORM]: rolePlatformAccessLevels,
  [ROLE_PARTNER]: rolePartnerAccessLevels,
  [ROLE_MERCHANT]: roleMerchantAccessLevels,
}

export const roleAccessPermissions = {
  [ROLE_ADMIN]: roleAdminAccessPermissionMap,
  [ROLE_PLATFORM]: rolePlatformAccessPermissionMap,
  [ROLE_PARTNER]: rolePartnerAccessPermissionMap,
  [ROLE_MERCHANT]: roleMerchantAccessPermissionMap,
}

export const getUpdateOptionsByRole = (role) => {
  const accessLevels = get(roleAccessLevels, role, [])

  return [
    selectOption('Select Permissions', ''),
    selectOption(ENABLE_ALL, ENABLE_ALL),
    selectOption(DISABLE_ALL, DISABLE_ALL),
    ...map(accessLevels, (access) => selectOption(access))
  ]
}

export const getPermissionsByRoleAndAccess = (role, access, permissions = {}) => {
  if (access === ENABLE_ALL) {
    return map(permissions, ({ name }) => { 
      return { name, enabled: true }
    })
  }

  if (access === DISABLE_ALL) {
    return map(permissions, ({ name }) => {
      return { name, enabled: false }
    })
  }

  return map(get(roleAccessPermissions, `${role}.${access}`, {}), (value, key) => {
    return { name: key, enabled: value }
  })
}
