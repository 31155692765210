import './EditSubscriptionPlanFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Loader from 'components/Shared/Loader/Loader'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Button from 'components/Shared/Button/Button'
import submitEditSubscriptionPlanForm from 'utilities/submit/submitEditSubscriptionPlanForm'
import validateEditSubscriptionPlanForm from 'utilities/validate/validateEditSubscriptionPlanForm'
import { USD } from 'constants/currencyConstants'
import { intervalTypeOptions } from 'constants/subscriptionsConstants'

import {
  CREATE_SUBSCRIPTION_PLAN_FORM,
  EDIT_SUBSCRIPTION_PLAN_FORM,
} from 'constants/formConstants'

import {
  ADD_A_TRIAL_PHASE_LABEL,
  ADD_A_TRIAL_PHASE_TOOLTIP,
  BILLING_FREQUENCY,
  CANCEL,
  DESCRIPTION,
  EDIT_A_SUBSCRIPTION_PLAN,
  EDIT_A_SUBSCRIPTION_PLAN_SUBTITLE,
  PLAN_DETAILS,
  PLAN_NAME,
  UPDATE_PLAN,
  OPTIONAL_ADD_ONS,
  RECURRING_PRICE,
  TRIAL_DURATION,
} from 'constants/language/languageConstants'

const EditSubscriptionPlanForm = ({
  handleSubmit = () => {},
  isFetching = false,
  pristine = false,
  invalid = false,
}) => {
  return (
    <form className='EditSubscriptionPlanForm' onSubmit={handleSubmit(submitEditSubscriptionPlanForm)}>
      <div className='form-header'>
        <h3 className='title'>{EDIT_A_SUBSCRIPTION_PLAN}</h3>
        <div className='p-1'>{EDIT_A_SUBSCRIPTION_PLAN_SUBTITLE}</div>
      </div>

      { isFetching && <Loader /> }

      { !isFetching && (
      <div className='form-content'>
        <div className='plan-name-description-section'>
          <h5 className='subtitle'>{PLAN_DETAILS}</h5>
          <Field
            name='subscriptionPlanName'
            className='subscription-plan-name'
            label={PLAN_NAME}
            component={InputField}
            disabled
          />

          <Field
            name='description'
            className='plan-details'
            label={DESCRIPTION}
            component={InputField}
            required={false}
          />
        </div>

        <div className='billing-settings-section'>
          <div className='flex row items-flex-start'>
            <AmountField
              name='amount'
              className='billing-amount-field'
              label={RECURRING_PRICE}
              contextLabel={USD}
              disabled
            />

            <Field
              name='billingInterval'
              className='billing-interval-field'
              label={BILLING_FREQUENCY}
              component={ReactSelect}
              isDisabled
            />
          </div>
        </div>

        <div className='trial-options-section'>
          <h5 className='subtitle'>{OPTIONAL_ADD_ONS}</h5>
          <Field
            name='enableTrialPhase'
            className='enable-trial-phase-field'
            component={ToggleSwitchC}
            label={ADD_A_TRIAL_PHASE_LABEL}
            tooltip={ADD_A_TRIAL_PHASE_TOOLTIP}
            tooltipPosition='right'
            formName={CREATE_SUBSCRIPTION_PLAN_FORM}
            disabled
          />

          <div className='trial-details flex space-between'>
            <Field
              name='trialIntervalCount'
              className='trial-interval-count-field'
              label={TRIAL_DURATION}
              component={InputField}
              disabled
            />

            <Field
              name='trialIntervalType'
              className='trial-interval-type-field'
              component={ReactSelect}
              options={intervalTypeOptions}
              isDisabled
            />
          </div>
        </div>
      </div>
      )}

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button onClick={goBack} label={CANCEL} variant='ghost' className='cancel-button' />
          <Button className='submit-button' type='submit' label={UPDATE_PLAN} submitting={isFetching} disabled={pristine || invalid || isFetching} />
        </div>
      </div>

    </form>
  )
}

EditSubscriptionPlanForm.propTypes = {
  handleSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: EDIT_SUBSCRIPTION_PLAN_FORM,
  // validate: validateEditSubscriptionPlanForm,
})(EditSubscriptionPlanForm)
