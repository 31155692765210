import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateRole from './CreateRole'
import { getRoleSelector } from 'state-layer/selectors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitRoleForm from 'utilities/submit/submitRoleForm'
import get from 'lodash/get'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getMany from 'utilities/get/getMany'
import getRoleRequest from 'utilities/actions/get/getRoleRequest'
import { formatPermissionsForForm } from 'utilities/formatters/formatPermissions'
import { entityTypeToRoleMap } from 'constants/memberConstants'
import isEmpty from 'lodash/isEmpty'

import {
  CREATE_CUSTOM_ROLE,
  CREATE_ROLE,
  EDIT_CUSTOM_ROLE,
  UPDATE_ROLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const currentQueries = parseUrlQueries()

  const [
    entityType,
    entityId,
    roleId,
  ] = getMany(currentQueries, [
    'entityType',
    'entityId',
    'roleId',
  ])

  const headerTitle = roleId ? EDIT_CUSTOM_ROLE : CREATE_CUSTOM_ROLE
  const buttonText = roleId ? UPDATE_ROLE : CREATE_ROLE
  const role = getRoleSelector(state, roleId)

  const [
    name,
    description,
    permissions,
    type,
    roleAuthRole,
  ] = getMany(role, [
    'roleName',
    'description',
    'permissions',
    'type',
    'authRole',
  ])

  const authRole = roleId ? roleAuthRole : entityTypeToRoleMap[entityType]
  const selectedEvents = formatPermissionsForForm({ type, userPermissions: permissions, authRole })

  const initialRoleValues = {
    roleName: name,
    roleDescription: description,
    selectedEvents,
  }

  return {
    entityId,
    entityType,
    roleId,
    credentials,
    credentialId,
    headerTitle: roleId ? EDIT_CUSTOM_ROLE : CREATE_CUSTOM_ROLE,
    initialRoleValues,
    buttonText: roleId ? UPDATE_ROLE : CREATE_ROLE,
    submitFunction: submitRoleForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRole: ({ credentials, roleId }) => dispatch(getRoleRequest({ credentials, roleId })),
  }
}
class CreateRoleC extends Component {
  componentDidMount() {
    const {
      credentials,
      roleId,
      getRole,
    } = this.props

    if (roleId) {
      getRole({ credentials, roleId })
    }
  }

  componentDidUpdate(prevProps) {
    const prevCredentials = get(prevProps, 'credentials')
    const [
      credentials,
      roleId,
      getRole,
    ] = getMany(this.props, [
      'credentials',
      'roleId',
      'getRole',
    ])

    if ((!isEmpty(credentials) && isEmpty(prevCredentials) && roleId)) {
      getRole({ roleId, credentials })
    }
  }

  render() {
    return (
      <CreateRole
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleC)
