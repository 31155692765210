import { goToPath } from 'state-layer/history'
import removeUndefined from 'utilities/remove/removeUndefined'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { USD } from 'constants/currencyConstants'
import { POST_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'
import { CREATE_PAYOUT_LINK } from 'constants/amplitudeConstants'
import { PAYOUT_LINK_CREATE_CONFIRMATION_PATH } from 'constants/pathConstants'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import head from 'lodash/head'

import {
  PUSH_TO_ACH,
  PUSH_TO_CARD,
} from 'constants/transferConstants'

const submitCreatePayoutLinkForm = (values, dispatch, props) => {
  const {
    applicationId,
    credentials,
    currency = USD,
    payoutLinkLogo,
  } = props

  const {
    nickname,
    allowPaymentCard,
    allowBankTransfer,
    items,
    expiresIn,
    expirationDate,
    collectName = false,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    customFields,
    payoutLinkLogoURL,
    payoutLinkHeaderColor,
    payoutLinkButtonBackgroundColor,
    payoutLinkButtonTextColor,
    recipient,
    collectEmail,
    collectPhoneNumber,
    collectCustomFields,
  } = values

  const recipientData = get(recipient, 'data', {})
  const email = get(recipientData, 'email', '')
  const firstName = get(recipientData, 'firstName', '')
  const lastName = get(recipientData, 'lastName', '')
  const businessName = get(recipientData, 'businessName', '')
  const doingBusinessAs = get(recipientData, 'doingBusinessAs', '')
  const recipientMerchantId = get(recipientData, 'recipientMerchantId', null)

  const customFieldTags = collectCustomFields ? reduce(customFields, (result, { name }, index) => {
    if (name) {
      result[`custom_field_${index + 1}`] = name
    }
    return result
  }, {}) : {}

  const recipientAdditionalDetails = removeUndefined({
    collectEmail: !!collectEmail,
    collectPhoneNumber: !!collectPhoneNumber,
    ...customFieldTags,
  })

  const allowedPayoutOperations = []
  if (allowPaymentCard) allowedPayoutOperations.push(PUSH_TO_CARD)
  if (allowBankTransfer) allowedPayoutOperations.push(PUSH_TO_ACH)

  let expirationInMinutes
  if (expiresIn === 'custom') {
    // custom means the user selected a date, which is inclusive of the selected date
    const currentDate = new Date()
    const oneDayInMilliseconds = 86400000
    // we need to add additional day because the expirationDate is they date they selected at 12:00 AM. We want to include the entire day.
    const timeDifference = expirationDate - currentDate + oneDayInMilliseconds
    expirationInMinutes = Math.round(timeDifference / 60000)
  } else {
    expirationInMinutes = parseInt(expiresIn, 10) * 60
  }

  const valuesToSubmit = removeUndefined({
    applicationId,
    nickname,
    allowedPayoutOperations,
    items,
    termsOfServiceUrl,
    successReturnUrl,
    unsuccessfulReturnUrl,
    expiredSessionUrl,
    brandColor: payoutLinkHeaderColor,
    accentColor: payoutLinkButtonBackgroundColor,
    buttonFontColor: payoutLinkButtonTextColor,
    logo: payoutLinkLogoURL || payoutLinkLogo,
    icon: '',
    tags: recipientAdditionalDetails,
    email,
    firstName,
    lastName,
    businessName,
    doingBusinessAs,
    recipientMerchantId,
    currency,
    expirationInMinutes,
  })

  dispatch({
    type: POST_PAYOUT_LINK_F_REQUEST,
    payload: {
      credentials,
      values: valuesToSubmit,
    },
    actionId: sendAmplitudeActionEvent(CREATE_PAYOUT_LINK, {
      applicationId,
      nickname,
      collectName,
      collectEmail,
      collectPhoneNumber,
      currency,
      termsOfServiceUrl,
      successReturnUrl,
      unsuccessfulReturnUrl,
      expiredSessionUrl,
      expirationInMinutes,
    }),
    meta: {
      successCallback: ({ newValues }) => {
        const payoutLinkId = head(Object.keys(newValues))
        const credentialId = get(credentials, 'id')

        if (payoutLinkId) {
          goToPath({
            pathname: PAYOUT_LINK_CREATE_CONFIRMATION_PATH({ credentialId, payoutLinkId }),
          })
        }
      },
    },
  })
}

export default submitCreatePayoutLinkForm
