import './MonitoringAlertInfoPanelS.scss'
import React from 'react'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import getMany from 'utilities/get/getMany'

const MonitoringAlertInfoPanel = ({ monitoringAlert, infoCardData }) => {
  const [
    status,
    name,
    description,
    displayCreatedAt,
  ] = getMany(monitoringAlert, [
    'state',
    'monitoringRuleDetails.name',
    'monitoringRuleDetails.description',
    'displayCreatedAt',
  ])

  return (
    <div className={`MonitoringAlertInfoPanel ${status}`}>
      <PageSectionV2C
        title={name}
        subTitle={description}
        rightTitle={displayCreatedAt}
        columns={infoCardData}
      />
    </div>
  )
}

export default MonitoringAlertInfoPanel
