import frontendReceiptDeliveryAttemptModel from 'utilities/create/models/frontendReceiptDeliveryAttemptModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendReceiptDeliveryAttemptsModel = ({ data: receiptDeliveryAttempts }) => {
  const receiptDeliveryAttemptsModels = map(receiptDeliveryAttempts, (receiptDeliveryAttempt) => frontendReceiptDeliveryAttemptModel({ data: receiptDeliveryAttempt }))
  return keyBy(receiptDeliveryAttemptsModels, 'id')
}

export default frontendReceiptDeliveryAttemptsModel
