import getDisputeAPI from 'api/finix/get/getDisputeAPI'
import getPaymentsAPI from 'api/finix/get/getPaymentsAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import getResponseLinks from 'utilities/get/getResponseLinks'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import frontendTransfersModel from 'utilities/create/models/frontendTransfersModel'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendPaymentInstrumentModel from 'utilities/create/models/frontendPaymentInstrumentModel'
import frontendIdentityModel from 'utilities/create/models/frontendIdentityModel'
import getPaymentInstrumentAPI from 'api/finix/get/getPaymentInstrumentAPI'
import getIdentityAPI from 'api/finix/get/getIdentityAPI'
import normalizeResponse from 'utilities/api/normalizeResponse'
import getMany from 'utilities/get/getMany'
import { IDENTITY } from 'constants/apiConstants'
import merge from 'lodash/merge'
import get from 'lodash/get'
import co from 'co'

function * getDisputeO ({
  id,
  credentials,
}) {
  const { data: response } = yield getDisputeAPI({ id, credentials })
  const normalizedDispute = normalizeResponse({ content: response })
  const merchantId = get(response, 'merchant')

  const links = getResponseLinks(response)

  const [
    adjustmentTransfersLink,
    applicationLink,
    transferLink,
  ] = getMany(links, [
    'adjustment_transfers',
    'application',
    'transfer',
  ])

  const [
    { data: adjustmentTransfersResponse },
    { data: application },
    { data: transferDetails },
    { data: merchantDetails },
  ] = yield [
    getPaymentsAPI({ absolutePath: adjustmentTransfersLink, credentials }),
    getPaymentsAPI({ absolutePath: applicationLink, credentials }),
    getPaymentsAPI({ absolutePath: transferLink, credentials }),
    getMerchantAPI({ id: merchantId, credentials }),
  ]

  const adjustmentTransfersCount = get(adjustmentTransfersResponse, 'page.count')
  const adjustmentTransfers = get(adjustmentTransfersResponse, '_embedded.transfers')
  const adjustmentTransfersModels = frontendTransfersModel({ data: normalizeResponse({ content: adjustmentTransfers }) })
  const normalizedTransferDetails = normalizeResponse({ content: transferDetails })
  const merchantModel = frontendMerchantModel({ data: merchantDetails })

  const {
    source,
    destination,
  } = transferDetails

  const paymentInstrumentId = source || destination

  const { data: paymentInstrument } = yield paymentInstrumentId ? getPaymentInstrumentAPI({ id: paymentInstrumentId, credentials }) : {}
  const paymentInstrumentModel = frontendPaymentInstrumentModel({ data: normalizeResponse({ content: paymentInstrument }) })

  const buyerIdentityId = get(paymentInstrumentModel, 'identityId')
  const { data: buyerIdentityResponse } = yield buyerIdentityId ? getPaymentsAPI({ path: IDENTITY({ identityId: buyerIdentityId }), credentials }) : {}
  const buyerIdentityResponseModel = frontendIdentityModel({ data: normalizeResponse({ content: buyerIdentityResponse }) })

  const identityId = get(response, 'identity')
  const { data: identityResponse } = yield identityId ? getIdentityAPI({ id: identityId, credentials }) : {}
  const identity = frontendIdentityModel({ data: normalizeResponse({ content: identityResponse }) })

  const dispute = merge({}, normalizedDispute, {
    adjustmentTransfersCount,
    adjustmentTransfers: adjustmentTransfersModels,
    application,
    transfer: normalizedTransferDetails,
    paymentInstrument: paymentInstrumentModel,
    identityObj: identity,
    buyerIdentity: buyerIdentityResponseModel,
    merchantObj: merchantModel,
  })

  return createSuccessResponse({
    data: [dispute],
  })
}

export default co.wrap(getDisputeO)
