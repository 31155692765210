import { EMAIL_REGEX } from 'constants/regexConstants'
import { invalidOrEmptyRegex } from 'utilities/validate'
import { EMAIL_ADDRESS } from 'constants/language/languageConstants'

const validateUserEmailForm = ({ email }) => {
  return {
    email: invalidOrEmptyRegex({ field: email, name: EMAIL_ADDRESS, regex: EMAIL_REGEX }),
  }
}

export default validateUserEmailForm
