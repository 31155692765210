import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import some from 'lodash/some'
import get from 'lodash/get'

import {
  fieldEmpty,
  invalidOrEmptyRegex,
  invalidRegex,
  invalidOrEmptyEmail,
  validatePhoneNumber,
} from 'utilities/validate'

import {
  EMAIL_REGEX,
  TAG_REGEX,
} from 'constants/regexConstants'

import {
  t,
  ONE_OR_MORE_EMAILS_ARE_INCORRECT,
  ONE_OR_MORE_PHONE_NUMBERS_ARE_INCORRECT,
  PAYMENT_AMOUNT_CANNOT_BE_GREATER_THAN_AMOUNT_OF,
  PAYMENT_AMOUNT_CANNOT_BE_LESS_THAN_MINIMUM_AMOUNT_OF,
  PAYMENT_AMOUNT,
} from 'constants/language/languageConstants'

// TODO: Add unit tests
const validateGuestPaymentLinkForm = (values, props) => {
  const {
    paymentLink = {},
    checkoutForm = {},
    pristine,
  } = props

  const checkoutResource = isEmpty(paymentLink) ? checkoutForm : paymentLink

  const {
    collectName,
    collectEmail,
    collectPhoneNumber,
    collectShippingAddress,
    amountType,
    currency,
    minAmount = '0.00',
    maxAmount = '0.00',
    tags: resourceTags = {},
  } = checkoutResource

  const customTags = filter(resourceTags, (tagValue, tagKey) => startsWith(tagKey, 'custom_field'))
  const customTagNames = Object.values(customTags)

  const {
    name,
    email,
    phone,
    saleAmount,
    shippingAddress = {},
    tags,
    receiptEmails,
    receiptPhoneNumbers,
  } = values

  const isFixedAmount = amountType === 'FIXED'
  const variableAmountGreaterThanMax = displayAmountToAmount(saleAmount, currency) > displayAmountToAmount(maxAmount, currency) && `${t(PAYMENT_AMOUNT_CANNOT_BE_GREATER_THAN_AMOUNT_OF)} ${maxAmount}`
  const variableAmountLessThanMin = displayAmountToAmount(saleAmount, currency) < displayAmountToAmount(minAmount, currency) && `${t(PAYMENT_AMOUNT_CANNOT_BE_LESS_THAN_MINIMUM_AMOUNT_OF)} ${minAmount}`
  const variableAmountEmpty = fieldEmpty(saleAmount, PAYMENT_AMOUNT)

  const tagErrors = reduce(customTagNames, (total, customTagName) => {
    const tagValue = get(tags, customTagName)
    const tagValueEmpty = fieldEmpty(tagValue, customTagName)
    const tagValueInvalid = invalidRegex({ field: tagValue, name: customTagName, regex: TAG_REGEX, customErrorMessage: 'Special characters , " \' \\ are not supported.' })

    return {
      ...total,
      [customTagName]: tagValueEmpty || tagValueInvalid,
    }
  }, {})

  const receiptEmailErrors = some(receiptEmails, ({ value: emailValue }) => invalidOrEmptyEmail(emailValue))
  const receiptPhoneErrors = some(receiptPhoneNumbers, ({ value: phoneNumber }) => validatePhoneNumber({ phoneNumber }))

  return {
    name: collectName && fieldEmpty(name, 'Name'),
    email: (collectEmail || pristine) && invalidOrEmptyRegex({ field: email, name: 'Email', regex: EMAIL_REGEX }),
    phone: collectPhoneNumber && fieldEmpty(phone, 'Phone'),
    shippingAddress: {
      line1: collectShippingAddress && fieldEmpty(shippingAddress.line1, 'Address Line 1'),
      city: collectShippingAddress && fieldEmpty(shippingAddress.city, 'City'),
      region: collectShippingAddress && fieldEmpty(shippingAddress.region, 'State'),
      postalCode: collectShippingAddress && fieldEmpty(shippingAddress.postalCode, 'Postal Code'),
      country: collectShippingAddress && fieldEmpty(shippingAddress.country, 'Country'),
    },
    saleAmount: !isFixedAmount ? (variableAmountEmpty || variableAmountGreaterThanMax || variableAmountLessThanMin) : false,
    tags: tagErrors,
    receiptEmails: receiptEmailErrors ? t(ONE_OR_MORE_EMAILS_ARE_INCORRECT) : false,
    receiptPhoneNumbers: receiptPhoneErrors ? t(ONE_OR_MORE_PHONE_NUMBERS_ARE_INCORRECT) : false,
  }
}

export default validateGuestPaymentLinkForm
