import displayAmountToAmount from 'utilities/money/displayAmountToAmount'
import amountToDisplayAmount from 'utilities/money/amountToDisplayAmount'
import formatMoney from 'utilities/formatters/formatMoney'
import { USD } from 'constants/currencyConstants'
import map from 'lodash/map'
import get from 'lodash/get'

import {
  TAG_REGEX,
  WEBSITE_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidRegex,
  nonEmptyNumber,
  nonEmptyInteger,
  invalidOrEmptyRegex,
} from 'utilities/validate'

const validateCreatePaymentLinkForm = (values, props) => {
  const {
    maxTransactionAmount,
    maxACHTransactionAmount,
  } = props

  const {
    amountType,
    items,
    allowPaymentCard,
    allowBankTransfer,
    termsOfServiceUrl,
    customFields,
  } = values

  const amountTypeValue = get(amountType, 'value')
  const isFixed = amountTypeValue === 'FIXED'

  const itemsValidation = map(items, (item) => {
    const {
      name,
      saleAmount,
      primaryImageUrl,
      quantity,
      currency = USD,
      minAmount = '0.00',
      maxAmount = maxTransactionAmount,
    } = item

    const maxTransactionValue = displayAmountToAmount(maxTransactionAmount, currency)
    const maxACHTransactionValue = displayAmountToAmount(maxACHTransactionAmount, currency)

    // if both card and bank accepted take the lower max transaction amount, otherwise use the method specific max transaction amount
    const normalizedMaxTransactionValue = (allowPaymentCard && allowBankTransfer)
      ? (maxTransactionValue > maxACHTransactionValue ? maxACHTransactionValue : maxTransactionValue)
      : (allowPaymentCard ? maxTransactionValue : maxACHTransactionValue)

    const normalizedMaxTransactionAmount = formatMoney({ amount: amountToDisplayAmount(normalizedMaxTransactionValue, currency), currency })

    const saleAmountGreaterThanMaxTransactionAmount = displayAmountToAmount(saleAmount, currency) > normalizedMaxTransactionValue && `Price must be less than or equal to ${normalizedMaxTransactionAmount}`
    const minAmountGreaterThanMaxTransactionAmount = displayAmountToAmount(minAmount, currency) > normalizedMaxTransactionValue && `Min amount must be less than or equal to ${normalizedMaxTransactionAmount}`
    const maxAmountGreaterThanMaxTransactionAmount = displayAmountToAmount(maxAmount, currency) > normalizedMaxTransactionValue && `Max amount must be less than or equal to ${normalizedMaxTransactionAmount}`

    return {
      name: fieldEmpty(name, 'Name'),
      quantity: quantity ? (nonEmptyNumber({ field: quantity, name: 'Quantity' }) || nonEmptyInteger({ field: quantity, name: 'Quantity' })) : false,
      saleAmount: isFixed ? (nonEmptyNumber({ field: saleAmount, name: 'Price' }) || saleAmountGreaterThanMaxTransactionAmount) : false,
      primaryImageUrl: invalidRegex({ field: primaryImageUrl, name: 'URL', regex: WEBSITE_REGEX }),
      minAmount: !isFixed ? minAmountGreaterThanMaxTransactionAmount : false,
      maxAmount: !isFixed ? maxAmountGreaterThanMaxTransactionAmount : false,
    }
  })

  const customFieldErrors = map(customFields, ({ name: customFieldName = '' }) => {
    const customFieldEmpty = fieldEmpty(customFieldName, 'Custom field name')
    const customFieldNameTooLong = customFieldName.length > 40 ? 'Custom field names must be less than 40 characters long' : false
    const customFieldNameInvalid = invalidRegex({ field: customFieldName, name: 'Custom field name', regex: TAG_REGEX, customErrorMessage: 'Special characters , " \' \\ are not supported.' })

    return {
      name: customFieldEmpty || customFieldNameTooLong || customFieldNameInvalid,
    }
  })

  return {
    amountType: fieldEmpty(amountType, 'Amount Type'),
    items: itemsValidation,
    allowPaymentCard: !(allowPaymentCard || allowBankTransfer) ? 'Please select at least one payment method' : undefined,
    allowBankTransfer: !(allowPaymentCard || allowBankTransfer) ? 'Please select at least one payment method' : undefined,
    termsOfServiceUrl: invalidOrEmptyRegex({ field: termsOfServiceUrl, name: 'Website', regex: WEBSITE_REGEX }),
    customFields: customFieldErrors,
  }
}

export default validateCreatePaymentLinkForm
