import './MerchantIdentityEditS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'
import HeaderC from 'components/Customer/Shared/Page/Header/HeaderC'
import Loader from 'components/Shared/Loader/Loader'

const MerchantIdentityEdit = ({
  isFetching,
  layout,
  steps,
}) => {
  return (
    <div className='MerchantIdentityEdit'>
      <HeaderC
        breadcrumbs
      />

      { isFetching && <Loader /> }

      { !isFetching && (
        <FlowsC
          flowClassName='edit-merchant-identity'
          layout={layout}
          steps={steps}
          edit // TODO: convert edit flag into something automatically built into flows
        />
      )}
    </div>
  )
}

MerchantIdentityEdit.propTypes = {
  isFetching: PropTypes.bool,
  layout: PropTypes.string,
  steps: PropTypes.array,
}

export default MerchantIdentityEdit
