import './MerchantOnboardingWelcomeFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import Button from 'components/Shared/Button/Button'
import validateMerchantOnboardingWelcomeForm from 'utilities/validate/validateMerchantOnboardingWelcomeForm'
import submitOnboardingWelcomeStepForm from 'utilities/submit/submitOnboardingWelcomeStepForm'
import { MERCHANT_ONBOARDING_WELCOME_FORM } from 'constants/formConstants'
import { MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS } from 'constants/identityConstants'
import { countryOptionsForFlex } from 'constants/countryConstants'

import {
  BUSINESS_TYPE,
  START_APPLICATION,
  COUNTRY,
  ONBOARDING_FORM_WELCOME_FORM_TEXT,
} from 'constants/language/languageConstants'

const MerchantOnboardingWelcomeForm = ({
  handleSubmit = () => {},
  onboardingFormCountry,
}) => {
  return (
    <form className='MerchantOnboardingWelcomeForm' onSubmit={handleSubmit(submitOnboardingWelcomeStepForm)}>
      <h6>{ONBOARDING_FORM_WELCOME_FORM_TEXT}</h6>
      <div className='form-content'>
        <Field
          name='country'
          label={COUNTRY}
          component={SelectField}
          options={countryOptionsForFlex}
          disabled={onboardingFormCountry ? true : false}
          required
        />

        <Field
          name='businessData.businessType'
          label={BUSINESS_TYPE}
          component={SelectField}
          options={MERCHANT_ONBOARDING_BUSINESS_TYPE_OPTIONS}
          required
        />
      </div>
      <Button type='submit' label={START_APPLICATION} />
    </form>
  )
}

MerchantOnboardingWelcomeForm.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingFormCountry: PropTypes.string,
}

export default reduxForm({
  form: MERCHANT_ONBOARDING_WELCOME_FORM,
  validate: validateMerchantOnboardingWelcomeForm,
})(MerchantOnboardingWelcomeForm)
