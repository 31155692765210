import './CreateSubscriptionPlanFormS.scss'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import ReactSelect from 'components/Shared/Inputs/ReactSelect/ReactSelect'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import Loader from 'components/Shared/Loader/Loader'
import ToggleSwitchC from 'components/Shared/Inputs/ToggleSwitch/ToggleSwitchC'
import submitCreateSubscriptionPlanForm from 'utilities/submit/submitCreateSubscriptionPlanForm'
import validateCreateSubscriptionPlanForm from 'utilities/validate/validateCreateSubscriptionPlanForm'
import { USD } from 'constants/currencyConstants'
import { CREATE_SUBSCRIPTION_PLAN_FORM } from 'constants/formConstants'

import {
  CANCEL,
  CREATE_A_SUBSCRIPTION_PLAN,
  CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_1,
  CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_2,
  CREATE_PLAN,
  PLAN_DETAILS,
  PLAN_DETAILS_INFO_MSG,
  OPTIONAL_ADD_ONS,
  ADD_A_TRIAL_PHASE_LABEL,
  ADD_A_TRIAL_PHASE_TOOLTIP,
  TRIAL_DURATION,
  TRIAL_HELPER_TEXT,
  RECURRING_PRICE,
} from 'constants/language/languageConstants'

import {
  intervalTypeOptions,
  subscriptionFrequencyOptions,
} from 'constants/subscriptionsConstants'

const CreateSubscriptionPlanForm = ({
  handleSubmit = () => {},
  showTrialPhaseDetails = false,
  invalid = false,
  isFetching = false,
  isFetchingMerchants = false,
}) => {
  return (
    <form className='CreateSubscriptionPlanForm' onSubmit={handleSubmit(submitCreateSubscriptionPlanForm)}>
      <div className='form-header p-1'>
        <h3 className='title'>{CREATE_A_SUBSCRIPTION_PLAN}</h3>
        <div className='p-1'>{CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_1}</div>
        <div className='p-1'>{CREATE_A_SUBSCRIPTION_PLAN_MORE_INFO_PT_2}</div>
      </div>

      {isFetchingMerchants && <Loader />}

      {!isFetchingMerchants && (
        <>
          <div className='form-content'>
            <div className='subscription-plan-details'>
              <h5 className='subtitle'>{PLAN_DETAILS}</h5>
              <div className='message-container'>
                <div className='flex row items-flex-start'>
                  <div className='p-2'>{PLAN_DETAILS_INFO_MSG}</div>
                </div>
              </div>
            </div>

            <div className='plan-name-info'>
              <Field
                name='planName'
                component={InputField}
                label='Plan Name'
                placeholder='Name of the plan people will subscribe to'
              />

              <Field
                name='description'
                component={InputField}
                label='Description'
                required={false}
                placeholder='Optional description of the plan'
              />
            </div>

            <div className='recurring-price-billing-section'>
              <div className='flex space-between'>
                <AmountField
                  name='amount'
                  label={RECURRING_PRICE}
                  placeholder='0.00'
                  contextLabel={USD}
                />

                <Field
                  name='billingInterval'
                  className='billing-interval-field'
                  label='Billing Frequency'
                  component={ReactSelect}
                  options={subscriptionFrequencyOptions}
                />
              </div>
            </div>

            <div className='trial-options-section'>
              <h5 className='subtitle'>{OPTIONAL_ADD_ONS}</h5>
              <Field
                name='enableTrialPhase'
                className='enable-trial-phase-field'
                component={ToggleSwitchC}
                label={ADD_A_TRIAL_PHASE_LABEL}
                tooltip={ADD_A_TRIAL_PHASE_TOOLTIP}
                tooltipPosition='right'
                formName={CREATE_SUBSCRIPTION_PLAN_FORM}
              />

              { showTrialPhaseDetails && (
                <>
                  <div className='trial-details flex space-between'>
                    <Field
                      name='trialIntervalCount'
                      className='trial-interval-count-field'
                      label={TRIAL_DURATION}
                      component={InputField}
                    />

                    <Field
                      name='trialIntervalType'
                      className='trial-interval-type-field'
                      component={ReactSelect}
                      options={intervalTypeOptions}
                    />
                  </div>

                  <div className='trial-helper-text p-2'>{TRIAL_HELPER_TEXT}</div>
                </>
              )}
            </div>

          </div>

          <div className='form-footer'>
            <div className='footer-button-container'>
              <div className='exit-and-save-container'>
                <Button label={CANCEL} className='button' variant='ghost' onClick={goBack} />
                <Button label={CREATE_PLAN} className='submit-button' type='submit' disabled={invalid || isFetching} submitting={isFetching} />
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

CreateSubscriptionPlanForm.propTypes = {
  handleSubmit: PropTypes.func,
  showTrialPhaseDetails: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingMerchants: PropTypes.bool,
}

export default reduxForm({
  form: CREATE_SUBSCRIPTION_PLAN_FORM,
  validate: validateCreateSubscriptionPlanForm,
})(CreateSubscriptionPlanForm)
