import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { PAYMENT_PATH } from 'constants/pathConstants'
import { getCardBrand } from 'constants/bankConstants'
import { USD } from 'constants/currencyConstants'

const displayOriginalPaymentLink = ({
  displayAmount = '',
  currency = USD,
  brand = '',
  credentialId = '',
  transferId = '',
}) => {
  const icon = getCardBrand(brand)

  return (
    <>
      <i className={`icon ${icon}`} />
      <Link to={PAYMENT_PATH({ credentialId, transferId })}>
        <div className='displayOriginalPaymentLink'>{displayAmount} <span className='currency-string'>{currency}</span></div>
      </Link>
    </>
  )
}

displayOriginalPaymentLink.propTypes = {
  displayAmount: PropTypes.string,
  currency: PropTypes.string,
  brand: PropTypes.string,
  credentialId: PropTypes.string,
  transferId: PropTypes.string,
}

export default displayOriginalPaymentLink
