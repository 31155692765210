import './MerchantSettlementsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from 'components/Customer/Pages/Settlements/columnDescriptors'
import { MERCHANT_SETTLEMENTS } from 'constants/linkConstants'
import { SETTLEMENTS_ICON } from 'constants/iconConstants'
import { getMerchantSettlementsByStatus } from 'state-layer/selectors'

import {
  GET_MERCHANT_SETTLEMENTS,
  SETTLEMENT,
} from 'constants/flowConstants'

const MerchantSettlements = ({
  flowValues = {},
  initialQueries = {},
  initialFilters,
  allowedFilters = [],
  settlementPath = '',
  isFetching = false,
  merchantId = '',
  selectedItemsKey,
  selectedItemsData = {},
  statusQuery,
  quickFilters,
}) => {
  return (
    <div className='MerchantSettlements'>
      <TableC
        flow={GET_MERCHANT_SETTLEMENTS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getMerchantSettlementsByStatus(statusQuery)}
        selectorId={merchantId}
        initialFilters={initialFilters}
        initialQueries={initialQueries}
        linksKey={MERCHANT_SETTLEMENTS}
        allowedFilters={allowedFilters}
        path={settlementPath}
        isFetching={isFetching}
        selectedItemsKey={selectedItemsKey}
        selectedItemsData={selectedItemsData}
        fileNameCSV={`Merchant_${merchantId}_Settlements`}
        icon={`fa fa-${SETTLEMENTS_ICON}`}
        quickFilters={quickFilters}
        showAdvancedExport
        entityType={SETTLEMENT}
      />
    </div>
  )
}

MerchantSettlements.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  initialFilters: PropTypes.object,
  allowedFilters: PropTypes.array,
  settlementPath: PropTypes.string,
  isFetching: PropTypes.bool,
  merchantId: PropTypes.string,
  selectedItemsKey: PropTypes.string,
  selectedItemsData: PropTypes.object,
  statusQuery: PropTypes.string,
  quickFilters: PropTypes.array,
}

export default MerchantSettlements
