import dashboardAPI from 'utilities/api/dashboardAPI'
import { COMPLIANCE_FORM } from 'constants/apiConstants'

const getComplianceFormAPI = ({ id, meta }) => {
  return dashboardAPI.get({
    meta,
    path: COMPLIANCE_FORM({ complianceFormId: id }),
  })
}

export default getComplianceFormAPI
