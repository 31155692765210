import frontendPayoutPlanModel from 'utilities/create/models/frontendPayoutPlanModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendPayoutPlansModel = ({ data: payoutPlans }) => {
  const payoutPlanModels = map(payoutPlans, (payoutPlan) => frontendPayoutPlanModel({ data: payoutPlan }))

  return keyBy(payoutPlanModels, 'id')
}

export default frontendPayoutPlansModel
