import React from 'react'
import PropTypes from 'prop-types'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'
import Button from 'components/Shared/Button/Button'

import {
  CANCEL,
  ENABLE,
  DISABLE,
} from 'constants/language/languageConstants'

const EditUnderwritingWorkflowModal = ({
  closeModal = () => {},
  updateUnderwritingWorkflow = () => {},
  actOnDecision = false,
  displayCountry = '',
  processor = '',
  isFetching = false,
}) => {
  return (
    <GenericModal
      title={`Are you sure you want to ${actOnDecision ? 'disable' : 'enable'} underwriting automation for ${processor} in ${displayCountry ? displayCountry : 'all countries'}?`}
    >
      <div className='modal-content'>
        <p className='p-1'>{`The automation will affect all new onboarding ${displayCountry ? 'merchants' : 'recipients'} immediately.`}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button className='cancel-button' variant='ghost' label={CANCEL} onClick={closeModal} />
        <Button className='submit-button' type='submit' variant={actOnDecision ? 'destructive' : 'primary'} label={actOnDecision ? DISABLE : ENABLE} onClick={updateUnderwritingWorkflow} submitting={isFetching} />
      </div>
    </GenericModal>
  )
}

EditUnderwritingWorkflowModal.propTypes = {
  closeModal: PropTypes.func,
  updateUnderwritingWorkflow: PropTypes.func,
  actOnDecision: PropTypes.bool,
  displayCountry: PropTypes.string,
  processor: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default EditUnderwritingWorkflowModal
