import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  APPLICATIONS,
  APPLICATION_PROCESSORS,
} from 'constants/apiConstants'

const postApplicationProcessorAPI = ({ values, credentials, id, meta }) => {
  return paymentsAPI.post({
    meta,
    values,
    credentials,
    path: APPLICATION_PROCESSORS({ applicationId: id }),
    service: APPLICATIONS,
  })
}

export default postApplicationProcessorAPI
