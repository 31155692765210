import React from 'react'
import column from 'utilities/table/column'
import { DOWNLOAD_ICON } from 'constants/iconConstants'

import {
  FILE_NAME,
  DESCRIPTION,
  FILE_LINK,
  DOWNLOAD,
} from 'constants/language/languageConstants'

export const merchantResourcesColumnDescriptors = [
  column(FILE_NAME, 'fileName', { className: 'x-large' }),
  column(DESCRIPTION, 'description', { className: 'resource-description' }),
  column(FILE_LINK, 'fileLink', {
    formatter: ({ fileLink }) => (
      <a className='text-link' href={fileLink} target='blank'>
        {DOWNLOAD} <i className={`fal fa-${DOWNLOAD_ICON}`} />
      </a>
    ),
  }),
]

export const partnerPlatformResourcesColumnDescriptors = [
  column(FILE_NAME, 'fileName', { className: 'x-large' }),
  column(DESCRIPTION, 'description', { className: 'resource-description' }), column(FILE_LINK, 'fileLink', {
    formatter: ({ fileLink }) => (
      <a className='text-link' href={fileLink} target='blank'>
        {DOWNLOAD} <i className={`fal fa-${DOWNLOAD_ICON}`} />
      </a>
    ),
  }),
]
