import paymentsAPI from 'utilities/api/paymentsAPI'
import { AUDIT_LOG_EVENTS } from 'constants/apiConstants'

const getAuditLogEventsAPI = ({ credentials, meta, queries }) => {
  return paymentsAPI.get({
    meta,
    path: AUDIT_LOG_EVENTS,
    credentials,
    queries,
  })
}

export default getAuditLogEventsAPI
