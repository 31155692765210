import getComplianceSettingsRequest from 'utilities/actions/get/getComplianceSettingsRequest'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import removeUndefined from 'utilities/remove/removeUndefined'

import {
  PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
  UNSELECT_ALL_ITEMS,
} from 'constants/flowConstants'

import {
  EDIT_APPLICATION_COMPLIANCE_TEMPLATE,
  EDIT_PLATFORM_COMPLIANCE_TEMPLATE,
} from 'constants/amplitudeConstants'

const submitEditActiveComplianceTemplateForm = (values = {}, dispatch, props) => {
  const { complianceTemplateIdInput } = values
  const { credentials, itemId, complianceTemplateIdTable, selectInput } = props

  const amplitudeEventType = itemId.slice(0, 2) === 'AP' ? EDIT_APPLICATION_COMPLIANCE_TEMPLATE : EDIT_PLATFORM_COMPLIANCE_TEMPLATE
  const complianceTemplateId = selectInput ? complianceTemplateIdInput : complianceTemplateIdTable

  dispatch({
    type: PATCH_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
    payload: {
      id: complianceTemplateId,
      credentials,
      values: {
        linkTo: itemId,
      },
    },
    meta: {
      actionId: sendAmplitudeActionEvent(amplitudeEventType, removeUndefined({
        credentials,
        linkTo: itemId,
        complianceTemplateId,
      })),
      successCallback: () => {
        dispatch({ type: UNSELECT_ALL_ITEMS })
        setTimeout(() => dispatch(getComplianceSettingsRequest({ id: itemId, credentials })), 3000)
      },
    },
  })
}

export default submitEditActiveComplianceTemplateForm
