import getMany from 'utilities/get/getMany'
import removeUndefined from 'utilities/remove/removeUndefined'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import formatDate from 'utilities/formatters/formatDate'
import formatMoney from 'utilities/formatters/formatMoney'
import { MM_DD_YYYY } from 'constants/timeConstants'
import { PAYMENT_TYPE } from '../../../constants/language/languageConstants'

const frontendSubscriptionModel = ({ data: subscription }) => {
  const [
    id,
    createdAt,
    updatedAt,
    firstChargeAt,
    amount,
    buyerDetails,
    currency,
    linkedTo,
    linkedType,
    nickname,
    billingInterval,
    subscriptionDetails,
    subscriptionPhase,
    state,
    subscriptionPlanId,
    subscriptionPlan,
    paymentInstrument,
  ] = getMany(subscription, [
    'id',
    'created_at',
    'updated_at',
    'first_charge_at',
    'amount',
    'buyer_details',
    'currency',
    'linked_to',
    'linked_type',
    'nickname',
    'billing_interval',
    'subscription_details',
    'subscription_phase',
    'state',
    'subscription_plan_id',
    'subscription_plan', // TODO: remove this once BE consistently returns subscription_plan_id on both singular and list responses
    'paymentInstrument',
  ])

  const [
    buyerIdentityId,
    buyerInstrumentId,
  ] = getMany(buyerDetails, [
    'identity_id',
    'instrument_id',
  ])


  const [
    collectionMethod,
    sendInvoice,
    sendReceipt,
    trialIntervalType,
    trialIntervalCount,
    trialStartedAt,
    trialExpectedEndAt,
  ] = getMany(subscriptionDetails, [
    'collection_method',
    'send_invoice',
    'send_receipt',
    'trial_details.interval_type',
    'trial_details.interval_count',
    'trial_details.trial_started_at',
    'trial_details.trial_expected_end_at',
  ])

  return removeUndefined({
    id,
    createdAt: formatDate({ date: createdAt }),
    displayCreatedAt: formatDate({ date: createdAt, format: MM_DD_YYYY }),
    updatedAt,
    displayUpdatedAt: formatDate({ date: updatedAt }),
    firstChargeAt,
    displayFirstChargeAt: formatDate({ date: firstChargeAt, format: MM_DD_YYYY }),
    amount: formatMoney({ amount, currency }),
    buyerIdentityId,
    buyerInstrumentId,
    currency,
    linkedTo,
    linkedType,
    nickname,
    billingInterval,
    displayBillingInterval: snakeCaseToTitleCase({ key: billingInterval }),
    collectionMethod,
    displayCollectionMethod: snakeCaseToTitleCase({ key: collectionMethod }),
    sendInvoice,
    sendReceipt,
    trialIntervalType,
    trialIntervalCount,
    trialStartedAt: formatDate({ date: trialStartedAt }),
    trialExpectedEndAt: formatDate({ date: trialExpectedEndAt }),
    subscriptionPhase,
    state,
    subscriptionPlanId,
    subscriptionPlan,
    paymentInstrument,
  })
}

export default frontendSubscriptionModel
