import formatMoney from 'utilities/formatters/formatMoney'

export const fields = {
  'entity.id': { title: 'ID' },
  'merchant.name': { title: 'Merchant' },
  'merchant.id': { title: 'Merchant ID' },
  'entity.status': { title: 'Status' },
  'entity.transferCount': { title: 'Transfers' },
  'entity.feeCount': { title: 'Fees' },
  createdAt: { title: 'Created' },
  'entity.processor': { title: 'Processor' },
  'application.businessName': { title: 'Application' },
  outcome: { title: 'Approval State' },
  'entity.totalAmount': {
    title: 'Amount',
    formatter: (totalAmount) => formatMoney({ amount: totalAmount }),
  },
  'entity.totalFees': {
    title: 'Total Fees',
    formatter: (totalFees) => formatMoney({ amount: totalFees }),

  },
  'entity.netAmount': {
    title: 'Net Amount',
    formatter: (netAmount) => formatMoney({ amount: netAmount }),
  },
}
