// TODO: may be used later to store current user, for now using utility getDashboardUser.js
import get from 'lodash/get'

import {
  UPDATE_CURRENT_USER,
} from 'constants/flowConstants'

const currentUserR = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return get(action, 'payload.data', {})

    default:
      return state
  }
}

export default currentUserR
