import paymentsAPI from 'utilities/api/paymentsAPI'
import get from 'lodash/get'

import {
  SUBSCRIPTION_SCHEDULE_AMOUNTS,
  SUBSCRIPTION,
} from 'constants/apiConstants'

const getSubscriptionScheduleAmountsAPI = ({ id, queries, credentials, meta, values }) => {
  const subscriptionScheduleId = id || get(values, 'subscriptionScheduleId')

  return paymentsAPI.get({
    meta,
    credentials,
    path: SUBSCRIPTION_SCHEDULE_AMOUNTS({ subscriptionScheduleId }),
    queries,
    service: SUBSCRIPTION,
  })
}

export default getSubscriptionScheduleAmountsAPI
