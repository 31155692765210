import './ConfirmationFormS.scss'
import React from 'react'
import Button from 'components/Shared/Button/Button'
import PropTypes from 'prop-types'

import {
  NO,
  YES,
} from 'constants/language/languageConstants'

const ConfirmationForm = ({
  message = '',
  description = '',
  closeModal = () => {},
  handleConfirmationClick = () => {},
  customButtonGroup,
}) => {
  return (
    <div className='ConfirmationForm'>
      <div className='message'>{message}</div>

      { description && <div className='description'>{description}</div> }

      {!customButtonGroup && (
      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={NO} variant='ghost' className='cancel-button' />
        <Button onClick={handleConfirmationClick} label={YES} />
      </div>
      )}

      {customButtonGroup && <div className='buttons flex flex-end'>{customButtonGroup}</div>}
    </div>
  )
}

ConfirmationForm.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  closeModal: PropTypes.func,
  handleConfirmationClick: PropTypes.func,
  customButtonGroup: PropTypes.object,
}

export default ConfirmationForm
