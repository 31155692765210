import React, { Component } from 'react'

class AuthCallback extends Component {
  render() {
    return (
      <div className='AuthCallback'>
        Loading...
      </div>
    )
  }
}

export default AuthCallback
