import { GET_DEVICES_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getMerchantDevicesRequest = ({
  merchantId,
  credentials,
}) => createAction({
  type: GET_DEVICES_F_REQUEST,
  queries: {
    merchant_id: merchantId,
  },
  credentials,
})

export default getMerchantDevicesRequest
