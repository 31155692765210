import React, { Component } from 'react'
import { connect } from 'react-redux'
import Authorizations from './Authorizations'
import getFeatureFlag from 'utilities/get/getFeatureFlag'
import isAdministratorRole from 'utilities/is/isAdministratorRole'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import convertFilterToResourceIdFilter from 'utilities/convert/convertIdFilterToResourceIdFilter'
import { FETCHING } from 'constants/reducerConstants'
import { AUTHORIZATIONS_PATH, CREATE_PAYMENT_PATH } from 'constants/pathConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { SHOW_VIRTUAL_TERMINAL } from 'constants/featureFlagConstants'
import { QA_ENVIRONMENT } from 'constants/environmentConstants'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import get from 'lodash/get'
import filter from 'lodash/filter'

import {
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
} from 'constants/quickFilterConstants'

import {
  AUTHORIZATION,
  CREATE_AUTHORIZATION,
} from 'constants/language/languageConstants'

import {
  TRACE_ID,
  AMOUNT,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  APPLICATION_ID,
  MERCHANT_PROCESSOR_ID,
  MERCHANT_MID,
  INSTRUMENT_FINGERPRINT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  TAG_FILTERS,
  DEVICE_ID,
} from 'constants/filterConstants'

const allowedFilters = sortBy([
  convertFilterToResourceIdFilter(AUTHORIZATION),
  AMOUNT(),
  APPLICATION_ID,
  DEVICE_ID,
  INSTRUMENT_FINGERPRINT,
  INSTRUMENT_CARD_LAST4,
  INSTRUMENT_BIN,
  INSTRUMENT_BRAND_TYPE,
  INSTRUMENT_TYPE,
  INSTRUMENT_NAME,
  MERCHANT_ID,
  MERCHANT_IDENTITY_ID,
  MERCHANT_IDENTITY_NAME,
  MERCHANT_MID,
  MERCHANT_PROCESSOR_ID,
  TRACE_ID,
  ...TAG_FILTERS,
], 'label')

const initialQueries = {
  offset: 0,
  sort: 'created_at,desc',
}

const quickFilters = [
  AUTHORIZATION_STATE_QUICK_FILTER,
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `authorizationsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const authorizationPath = AUTHORIZATIONS_PATH({ credentialId })
  const queries = get(props, 'queries', {})
  const mergedQueries = merge({}, initialQueries, queries)
  const hiddenFilters = get(props, 'hiddenFilters', [])
  const mergedFilters = filter(allowedFilters, ({ field }) => !hiddenFilters.includes(field))

  return {
    quickFilters,
    allowedFilters: mergedFilters,
    initialQueries: mergedQueries,
    initialFilters: createdLastMonthQueries(),
    isFetching,
    authorizationPath,
    credentials,
  }
}

class AuthorizationsC extends Component {
  render() {
    const { credentials } = this.props
    const credentialId = get(credentials, 'id')
    const environment = get(credentials, 'environment')

    const actions = [
      {
        label: CREATE_AUTHORIZATION,
        className: 'create-manual-payment',
        link: CREATE_PAYMENT_PATH({ credentialId }),
        queries: { type: 'authorization' },
        condition: isFlexPlatform() && environment !== QA_ENVIRONMENT && isAdministratorRole() && getFeatureFlag(SHOW_VIRTUAL_TERMINAL),
      },
    ]

    return (
      <Authorizations
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps)(AuthorizationsC)
