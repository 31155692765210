import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import createAction from 'utilities/actions/createAction'
import { WITHDRAW_ACCESS_FORM } from 'constants/amplitudeConstants'
import { DELETE_ACCESS_FORM_F_REQUEST } from 'constants/flowConstants'

const deleteAccessFormRequest = ({
  accessFormId = '',
}) => createAction({
  type: DELETE_ACCESS_FORM_F_REQUEST,
  id: accessFormId,
  meta: {
    actionId: sendAmplitudeActionEvent(WITHDRAW_ACCESS_FORM, {
      accessFormId,
    }),
  },
})

export default deleteAccessFormRequest
