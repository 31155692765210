import selectOption from 'utilities/forms/selectOption'
import get from 'lodash/get'
import includes from 'lodash/includes'

import {
  SANDBOX_ENVIRONMENT,
  QA_ENVIRONMENT,
  LIVE_ENVIRONMENT,
} from 'constants/environmentConstants'

import {
  VISA_ICON,
  MASTERCARD_ICON,
  DISCOVER_ICON,
  DINERS_CLUB_ICON,
  JCB_ICON,
  AMERICAN_EXPRESS_ICON,
  PAYMENTS_ICON,
  APPLE_PAY_ICON,
  GOOGLE_PAY_ICON,
  AMAZON_PAY_ICON,
  PAYPAL_ICON,
} from 'constants/iconConstants'

import {
  BUSINESS_CHECKING as businessCheckingLabel,
  PERSONAL_CHECKING as personalCheckingLabel,
  BUSINESS_SAVINGS as businessSavingsLabel,
  PERSONAL_SAVINGS as personalSavingsLabel,
} from 'constants/language/languageConstants'

export const VIRTUAL = 'VIRTUAL'
export const BANK_ACCOUNT = 'BANK_ACCOUNT'
export const PAYMENT_CARD = 'PAYMENT_CARD'
export const VANTIV_OMNI_TOKEN = 'VANTIV_OMNI_TOKEN'

export const SAVINGS = 'SAVINGS'
export const CHECKING = 'CHECKING'

export const BUSINESS_SAVINGS = 'BUSINESS_SAVINGS'
export const BUSINESS_CHECKING = 'BUSINESS_CHECKING'
export const PERSONAL_SAVINGS = 'PERSONAL_SAVINGS'
export const PERSONAL_CHECKING = 'PERSONAL_CHECKING'

export const accountTypeOptions = [
  selectOption('Select Type', ''),
  selectOption(businessCheckingLabel, BUSINESS_CHECKING),
  selectOption(businessSavingsLabel, BUSINESS_SAVINGS),
  selectOption(personalCheckingLabel, PERSONAL_CHECKING),
  selectOption(personalSavingsLabel, PERSONAL_SAVINGS),
]

export const accountTypeOptionsV2 = [
  selectOption(businessCheckingLabel, BUSINESS_CHECKING),
  selectOption(businessSavingsLabel, BUSINESS_SAVINGS),
  selectOption(personalCheckingLabel, PERSONAL_CHECKING),
  selectOption(personalSavingsLabel, PERSONAL_SAVINGS),
]

// bank card types
const VISA = 'VISA'
const MASTERCARD = 'MASTERCARD'
const DISCOVER = 'DISCOVER'
const DINERS_CLUB = 'DINERS_CLUB'
const JCB = 'JCB'
const AMERICAN_EXPRESS = 'AMERICAN_EXPRESS'
const APPLE_PAY = 'APPLE_PAY'
const GOOGLE_PAY = 'GOOGLE_PAY'
const AMAZON_PAY = 'AMAZON_PAY'
const PAYPAL = 'PAYPAL'

export const BANK_CARD_BRANDS = [VISA, MASTERCARD, DISCOVER, DINERS_CLUB, JCB, AMERICAN_EXPRESS, APPLE_PAY, AMAZON_PAY, PAYPAL, GOOGLE_PAY]

const brandToIconMap = {
  [VISA]: `fab fa-cc-${VISA_ICON}`,
  [MASTERCARD]: `fab fa-cc-${MASTERCARD_ICON}`,
  [DISCOVER]: `fab fa-cc-${DISCOVER_ICON}`,
  [DINERS_CLUB]: `fab fa-cc-${DINERS_CLUB_ICON}`,
  [JCB]: `fab fa-cc-${JCB_ICON}`,
  [AMERICAN_EXPRESS]: `fab fa-cc-${AMERICAN_EXPRESS_ICON}`,
  [APPLE_PAY]: `fab fa-${APPLE_PAY_ICON}`,
  [AMAZON_PAY]: `fab fa-cc-${AMAZON_PAY_ICON}`,
  [PAYPAL]: `fab fa-cc-${PAYPAL_ICON}`,
  [GOOGLE_PAY]:`fab fa-${GOOGLE_PAY_ICON}`,
}

const DEFAULT_PAYMENT_CARD_ICON = `far fa-${PAYMENTS_ICON}`

export const getCardBrand = (brand = '') => {
  return includes(BANK_CARD_BRANDS, brand) ? get(brandToIconMap, brand) : DEFAULT_PAYMENT_CARD_ICON
}

// disbursements - finix bank account constants
export const BENEFICIARY_COUNTRY = 'United States'
export const BANK_ACCOUNT_NAME = 'Finix Payments Inc.'
export const BANK_NAME_VALUE = 'Pathward'
export const BANK_NAME_VALUE_SANDBOX = 'Dummy Bank'
export const BANK_ADDRESS_VALUE = '5501 S. Broadway Lane, Sioux Falls, SD 57108'
export const BANK_ADDRESS_VALUE_SANDBOX = '100 Main Street, Anytown, CA 00000'

// TODO: Cata - add production routing number whenever they give it to us

const environmentToRoutingNumberMap = {
  [SANDBOX_ENVIRONMENT]: '021000021',
  [QA_ENVIRONMENT]: '000000000',
  [LIVE_ENVIRONMENT]: '273970116',
}

const environmentToSwiftCodeMap = {
  [SANDBOX_ENVIRONMENT]: 'BOMUSS33',
  [QA_ENVIRONMENT]: 'WEFGO33',
  [LIVE_ENVIRONMENT]: 'CHASUS33',
}

const environmentAccountNumberMap = {
  [SANDBOX_ENVIRONMENT]: '111111111',
  [QA_ENVIRONMENT]: '000000000',
  [LIVE_ENVIRONMENT]: '1700117483',
}

const environmentBankNameMap = {
  [SANDBOX_ENVIRONMENT]: BANK_NAME_VALUE_SANDBOX,
  [QA_ENVIRONMENT]: BANK_NAME_VALUE_SANDBOX,
  [LIVE_ENVIRONMENT]: BANK_NAME_VALUE,
}

const environmentBankAddressMap = {
  [SANDBOX_ENVIRONMENT]: BANK_ADDRESS_VALUE_SANDBOX,
  [QA_ENVIRONMENT]: BANK_ADDRESS_VALUE_SANDBOX,
  [LIVE_ENVIRONMENT]: BANK_ADDRESS_VALUE,
}


export const getEnvironmentRoutingNumber = (environment) => {
  return get(environmentToRoutingNumberMap, environment)
}

export const getEnvironmentSwiftCode = (environment) => {
  return get(environmentToSwiftCodeMap, environment)
}

export const getEnvironmentAccountNumber = (environment) => {
  return get(environmentAccountNumberMap, environment)
}

export const getEnvironmentBankName = (environment) => {
  return get(environmentBankNameMap, environment)
}

export const getEnvironmentBankAddress = (environment) => {
  return get(environmentBankAddressMap, environment)
}
