import React from 'react'
import column from 'utilities/table/column'

import {
  CLASSIFICATION,
  TYPE,
  CODE,
} from 'constants/language/languageConstants'

const industryClassificationsColumnDescriptors = [
  column(TYPE, 'type'),
  column(CODE, 'code'),
  column(CLASSIFICATION, 'title', { className: 'large' }),
]

export default industryClassificationsColumnDescriptors
