import createAction from 'utilities/actions/createAction'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_REQUEST_CHANGES_F_REQUEST } from 'constants/flowConstants'
import { REQUEST_ADDITIONAL_VERIFICATION } from 'constants/amplitudeConstants'
import dispatch from 'utilities/dispatch'
import getCustomerOnboardingFormRequest from 'utilities/actions/get/getCustomerOnboardingFormRequest'

const postCustomerOnboardingFormRequestChangesRequest = ({
  credentials,
  customerOnboardingFormId,
}) => createAction({
  type: POST_REQUEST_CHANGES_F_REQUEST,
  credentials,
  id: customerOnboardingFormId,
  meta: {
    closeModal: true,
    successCallback: () => {
      dispatch(getCustomerOnboardingFormRequest({ credentials, customerOnboardingFormId }))
    },
    actionId: sendAmplitudeActionEvent(REQUEST_ADDITIONAL_VERIFICATION, {
    }),
  },
})

export default postCustomerOnboardingFormRequestChangesRequest
