import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_SERVICE_NOTIFICATION_SETTINGS } from 'constants/apiConstants'

const postNotificationSettingsAPI = ({ credentials, values }) => {
  return dashboardAPI.post({
    path: DASHBOARD_SERVICE_NOTIFICATION_SETTINGS,
    values,
    credentials,
  })
}

export default postNotificationSettingsAPI
