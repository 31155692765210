import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubscriptionScheduleInfo from './SubscriptionScheduleInfo'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import convertSubscriptionScheduleOffsetObjectToString from 'utilities/convert/convertSubscriptionScheduleOffsetObjectToString'
import showModalAction from 'utilities/actions/showModalAction'
import { FETCHING } from 'constants/reducerConstants'
import { subscriptionScheduleTypeMapping } from 'constants/subscriptionConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { CREATED_AT_LABEL } from 'constants/language/languageConstants'

import {
  getSubscriptionScheduleAmountsSelector,
  getSubscriptionScheduleSelector,
} from 'state-layer/selectors'

import {
  CREATE_SUBSCRIPTION_AMOUNT,
  EDIT_SUBSCRIPTION_AMOUNT,
  DELETE_SUBSCRIPTION_AMOUNT,
} from 'constants/modalConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetchingSchedule = get(state, `subscriptionSchedulesR.${FETCHING}`)
  const isFetchingAmounts = get(state, `subscriptionScheduleAmountsR.${FETCHING}`)
  const subscriptionScheduleId = get(props, 'subscriptionScheduleId')
  const subscriptionSchedule = getSubscriptionScheduleSelector(state, subscriptionScheduleId)
  const subscriptionAmounts = getSubscriptionScheduleAmountsSelector(state, subscriptionScheduleId)

  const [
    periodOffset = {},
    subscriptionType,
    fixedTimeIntervalOffset = {},
    tags,
    userId,
    displayCreatedAt,
  ] = getMany(subscriptionSchedule, [
    'periodOffset',
    'subscriptionType',
    'fixedTimeIntervalOffset',
    'tags',
    'userId',
    'displayCreatedAt',
  ])

  const detailsSectionData = [
    {
      label: 'Type',
      value: subscriptionScheduleTypeMapping[subscriptionType],
    },
    {
      label: 'Schedule',
      value: convertSubscriptionScheduleOffsetObjectToString({ subscriptionType, periodOffset, fixedTimeIntervalOffset }),
    },
    {
      label: 'Created By User',
      value: userId,
    },
    {
      label: CREATED_AT_LABEL,
      value: displayCreatedAt,
    },
  ]

  const tagsSectionData = map(tags, (value, label) => ({ value, label }))

  const flowValues = { subscriptionScheduleId }

  return {
    credentials,
    isFetchingSchedule,
    isFetchingAmounts,
    subscriptionSchedule,
    subscriptionScheduleId,
    detailsSectionData,
    tagsSectionData,
    subscriptionAmounts,
    flowValues,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showCreateSubscriptionAmountModal: (modalProps) => dispatch(showModalAction({ modalType: CREATE_SUBSCRIPTION_AMOUNT, modalProps })),
    showEditSubscriptionAmountModal: (modalProps) => dispatch(showModalAction({ modalType: EDIT_SUBSCRIPTION_AMOUNT, modalProps })),
    showDeleteSubscriptionAmountModal: (modalProps) => dispatch(showModalAction({ modalType: DELETE_SUBSCRIPTION_AMOUNT, modalProps })),
  }
}

class SubscriptionScheduleInfoC extends Component {
  render() {
    const {
      credentials,
      showCreateSubscriptionAmountModal,
      showEditSubscriptionAmountModal,
      showDeleteSubscriptionAmountModal,
      subscriptionAmounts,
      subscriptionScheduleId,
    } = this.props

    const actions = [
      {
        label: 'Manage Amounts',
        actionList: [
          {
            label: 'Add an amount',
            actionFunc: () => {
              showCreateSubscriptionAmountModal({
                credentials,
                subscriptionScheduleId,
              })
            },
          },
          {
            label: 'Edit an amount',
            actionFunc: () => {
              showEditSubscriptionAmountModal({
                credentials,
                subscriptionScheduleId,
                subscriptionAmounts,
              })
            },
            condition: !isEmpty(subscriptionAmounts),
          },
          {
            label: 'Delete an amount',
            actionFunc: () => {
              showDeleteSubscriptionAmountModal({
                credentials,
                subscriptionScheduleId,
                subscriptionAmounts,
              })
            },
            condition: !isEmpty(subscriptionAmounts),
          },
        ],
        className: 'large manage-amounts-button',
      },
    ]

    return (
      <SubscriptionScheduleInfo
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionScheduleInfoC)
