import paymentsAPI from 'utilities/api/paymentsAPI'
import { FRAUD_SCORES } from 'constants/apiConstants'

const getFraudScoresAPI = ({ queries, credentials, meta }) => {
  return paymentsAPI.get({
    credentials,
    path: FRAUD_SCORES,
    queries,
    meta,
  })
}

export default getFraudScoresAPI
