import { take, put } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import { getState } from 'state-layer/configureStore'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import redirectRequest from 'utilities/actions/redirectRequest'
import getCurrentDashboardId from 'utilities/get/getCurrentDashboardId'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import getPageName from 'utilities/get/getPageName'
import { sendAmplitudePageViewEvent } from 'utilities/amplitude'
import { CREDENTIALS } from 'constants/linkConstants'
import omit from 'lodash/omit'
import get from 'lodash/get'

import {
  LOGIN_PATH,
  UNPROTECTED_PATHS,
} from 'constants/pathConstants'

import {
  requestF,
  GET_CREDENTIAL,
} from 'constants/flowConstants'

let lastPathName = ''
const SENSITIVE_QUERIES = ['bearer_token', 'token', 'access_token', 'refresh_token']

const locationWatcherF = function * () {
  while (true) {
    const { payload } = yield take([LOCATION_CHANGE])

    const pathname = get(payload, 'pathname')
    const search = get(payload, 'search')
    const queries = parseUrlQueries(search)
    const pageName = getPageName(pathname, queries)

    const state = getState()
    const credentials = getCurrentCredentials(state)

    // need to check if pathname is same but tab is different
    if (pathname && pathname !== lastPathName) {
      lastPathName = pathname

      // TODO: finalize taxonomy on page views and update current user logic
      const eventProperties = removeUndefined({
        page: pageName,
        queries: omit(queries, SENSITIVE_QUERIES), // TODO: should we break this out even more?
        fullUrl: pathname,
        credentials,
      })

      sendAmplitudePageViewEvent(eventProperties, pathname)
    }

    // TODO: make util for isLoggedIn?
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'
    const dashboardRedirect = localStorage.getItem('dashboardRedirect')
    const isProtectedPath = !UNPROTECTED_PATHS.includes(pathname)

    if (isLoggedIn && dashboardRedirect) {
      const credentialId = dashboardRedirect.split('/')[4]

      if (credentialId) {
        yield put({
          type: requestF(GET_CREDENTIAL),
          payload: {
            id: credentialId,
            linksKey: CREDENTIALS,
            values: {
              dashboardId: getCurrentDashboardId(),
            },
          },
        })
      }

      yield put(redirectRequest({ path: dashboardRedirect }))

      localStorage.removeItem('dashboardRedirect')
    }

    if (isProtectedPath && !isLoggedIn) {
      localStorage.setItem('dashboardRedirect', window.location.href)

      yield put(redirectRequest({ path: LOGIN_PATH }))
    }
  }
}

export default locationWatcherF
