import { TERMS_OF_SERVICE } from 'constants/language/languageConstants'

import {
  fieldEmpty,
  validateTruthyValue,
} from 'utilities/validate'

const validateMerchantIdentityConfirmationForm = (values, props) => {
  const {
    inquiryId,
    merchantAgreementAccepted,
  } = values

  const {
    biometricDataCollectionRequired = false,
  } = props

  return {
    merchantAgreementAccepted: validateTruthyValue({ field: merchantAgreementAccepted, name: TERMS_OF_SERVICE, customErrorMessage: 'Terms of Service must be accepted.' }),
    inquiryId: biometricDataCollectionRequired ? fieldEmpty(inquiryId, 'Inquiry ID') : false,
  }
}

export default validateMerchantIdentityConfirmationForm
