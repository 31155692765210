import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  PAYMENT_INSTRUMENT,
  PAYMENT_INSTRUMENTS,
} from 'constants/apiConstants'

const getPaymentInstrumentAPI = ({ id, queries, credentials, meta }) => {
  if (!id) {
    return {}
  }

  return paymentsAPI.get({
    meta,
    path: PAYMENT_INSTRUMENT({ id }),
    queries,
    credentials,
    service: PAYMENT_INSTRUMENTS,
  })
}

export default getPaymentInstrumentAPI
