import selectOption from 'utilities/forms/selectOption'

import {
  ALL,
  RESPONDED,
  ACCEPTED,
  NEEDS_RESPONSE,
  UNKNOWN,
} from 'constants/statusConstants'

import {
  ALL as ALL_LABEL,
  RESPONDED as RESPONDED_LABEL,
  ACCEPTED as ACCEPTED_LABEL,
  NEEDS_RESPONSE as NEEDS_RESPONSE_LABEL,
  UNKNOWN as UNKNOWN_LABEL,
} from 'constants/language/languageConstants'

export const INQUIRY = 'INQUIRY'
export const PENDING = 'PENDING'
export const WON = 'WON'
export const LOST = 'LOST'

export const MAX_EVIDENCE_FILES_ALLOWED = 8

export const DISPUTE_RESPONSE_STATE_OPTIONS = [
  selectOption(RESPONDED_LABEL, RESPONDED),
  selectOption(ACCEPTED_LABEL, ACCEPTED),
  selectOption(NEEDS_RESPONSE_LABEL, NEEDS_RESPONSE),
  selectOption(UNKNOWN_LABEL, UNKNOWN),
]

export const DISPUTE_RESPONSE_STATE_FILTER_OPTIONS = [
  selectOption(ALL_LABEL, ALL),
  ...DISPUTE_RESPONSE_STATE_OPTIONS,
]

export const DISPUTE_RESPONSE_STATE_SELECT_OPTIONS = [
  selectOption('Select...', ''),
  ...DISPUTE_RESPONSE_STATE_OPTIONS,
]

export const DISPUTE_STATE_OPTIONS = [
  selectOption('Inquiry', INQUIRY),
  selectOption('Pending', PENDING),
  selectOption('Won', WON),
  selectOption('Lost', LOST),
]

export const DISPUTE_STATE_SELECT_OPTIONS = [
  selectOption('Select...', ''),
  ...DISPUTE_STATE_OPTIONS,
]
