import get from 'lodash/get'
import getBrowserLanguage from 'utilities/get/getBrowserLanguage'

const initialState = {
  lng: getBrowserLanguage(),
}

const languageR = (state = initialState, action) => {
  if (action.type === 'SET_LANGUAGE') {
    return {
      lng: get(action, 'payload.lng', 'en'),
    }
  }

  return state
}

export default languageR
