import paymentsAPI from 'utilities/api/paymentsAPI'
import { DISBURSEMENT_RULES } from 'constants/apiConstants'

const postDisbursementRulesAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.post({
    values,
    credentials,
    path: DISBURSEMENT_RULES,
    meta,
    service: DISBURSEMENT_RULES,
  })
}

export default postDisbursementRulesAPI
