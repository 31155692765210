import React, { Component } from 'react'
import { connect } from 'react-redux'
import Recipient from './Recipient'
import { goToPath } from 'state-layer/history'
import { getIdentitySelector } from 'state-layer/selectors'
import RecipientDetailC from 'components/Customer/Pages/Recipient/RecipientDetail/RecipientDetailC'
import IdentityPaymentInstrumentsC from 'components/Customer/Pages/Identity/IdentityPaymentInstruments/IdentityPaymentInstrumentsC'
import OnboardingState from 'components/Customer/Shared/Display/ColorcodedStatus/OnboardingState'
import CountryFlagIcon from 'components/Customer/Shared/Display/CountryFlagIcon/CountryFlagIcon'
import IdentityPaymentsC from 'components/Customer/Pages/Identity/IdentityPayments/IdentityPaymentsC'
import IdentityMerchantsC from 'components/Customer/Pages/Identity/IdentityMerchants/IdentityMerchantsC'
import IdentityAuditLogsC from 'components/Customer/Pages/Identity/IdentityAuditLogs/IdentityAuditLogsC'
import { isRolePlatform } from 'utilities/validate/checkRoleCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getIdentityRequest from 'utilities/actions/get/getIdentityRequest'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { RECIPIENT_IDENTITY_ROLE } from 'constants/identityConstants'
import { EDIT_RECIPIENT_IDENTITY_PATH } from 'constants/pathConstants'
import { ADD_IDENTITY_BANK_ACCOUNT } from 'constants/modalConstants'
import { isFlexPlatform } from 'constants/flexConstants'
import { ELAVON_V1 } from 'constants/processorConstants'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import difference from 'lodash/difference'
import orderBy from 'lodash/orderBy'

import {
  ADDED_ON,
  BUSINESS,
  COUNTRY,
  DETAILS,
  EDIT_RECIPIENT_CONTACT_DETAILS,
  ONBOARDING_STATE_TITLE,
  PAYMENT_INSTRUMENTS,
  PERSONAL,
  RECIPIENT_IDENTITY,
  PAYOUTS,
  MERCHANT_ACCOUNTS,
  AUDIT_LOG,
} from 'constants/language/languageConstants'

import {
  SYSTEM,
  SYSTEM_ADMINISTRATOR_ROLE,
  SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE,
} from 'constants/roleConstants'

import {
  SETTLEMENT_ALIAS_DISPLAY_MAPPING,
  SETTLEMENT_ALIASES_BE,
} from 'constants/paymentInstrumentConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const isFetching = get(state, `identitiesR.${FETCHING}`)
  const identityId = get(props, 'params.identityId')
  const identity = getIdentitySelector(state, identityId)
  const isFlex = isFlexPlatform()

  const [
    credentialId,
    dashboardUserRoleName,
    dashboardUserRoleType,
  ] = getMany(credentials, [
    'id',
    'dashboardUserRoleName',
    'dashboardUserRoleType',
  ])

  const [
    applicationId,
    resourceTitle,
    onboardingState,
    displayCreatedAt,
    personalAddressCountry,
    identityRoles,
    businessName,
    identityMerchants,
    underwritingReviews,
  ] = getMany(identity, [
    'applicationId',
    'resourceTitle',
    'merchantProcessorOnboardingStatesString',
    'displayCreatedAt',
    'personalAddress.country',
    'identityRoles',
    'businessName',
    'merchants',
    'underwritingReviews',
  ])

  const hasRecipientRole = includes(identityRoles, RECIPIENT_IDENTITY_ROLE)

  const enabledProcessors = map(identityMerchants, ({ processor }) => processor)
  const elavonEnabled = includes(enabledProcessors, ELAVON_V1)
  const activeSettlementAliases = Object.keys(get(identity, 'payoutPlanInstrumentMapping', {}))
  const missingSettlementAliases = difference(SETTLEMENT_ALIASES_BE, activeSettlementAliases)
  const orderedUnderwritingReviews = orderBy(underwritingReviews, ['created_at'], ['desc'])
  const latestReview = get(orderedUnderwritingReviews, '[0]')
  const underwritingReviewSubjectId = get(latestReview, 'subjectId')

  const settlementAliasWarningMessage = () => (
    <div className='settlement-alias-warning flex column'>
      <span>The following settlement {missingSettlementAliases.length > 1 ? 'aliases' : 'alias' } must be assigned a bank account before funding and payouts will work for this merchant:</span>
      { map(missingSettlementAliases, (settlementAlias) => (<div>&#8226; {SETTLEMENT_ALIAS_DISPLAY_MAPPING[settlementAlias]}</div>)) }
    </div>
  )

  const settlementAliasWarningObject = {
    message: settlementAliasWarningMessage,
    condition: elavonEnabled && !isEmpty(missingSettlementAliases) && !isFetching,
  }

  const tabs = [
    {
      id: 'details',
      name: DETAILS,
      component: RecipientDetailC,
    },
    {
      id: 'payment-instruments',
      name: PAYMENT_INSTRUMENTS,
      component: IdentityPaymentInstrumentsC,
      props: {
        showAddPaymentInstrumentAction: true,
      },
    },
    {
      id: 'Payouts',
      name: PAYOUTS,
      component: IdentityPaymentsC,
      props: {
        identityId,
      },
    },
    {
      id: 'identity-merchant-accounts',
      name: MERCHANT_ACCOUNTS,
      component: IdentityMerchantsC,
      props: {
        elavonEnabled,
        settlementAliasWarningObject,
      },
    },
    {
      id: 'audit-log',
      name: AUDIT_LOG,
      component: IdentityAuditLogsC,
      condition: isFlex && isRolePlatform({ credentials }) && isEqual(dashboardUserRoleType, SYSTEM) && includes([SYSTEM_ADMINISTRATOR_ROLE, SYSTEM_PAYMENT_OPERATIONS_MANAGER_ROLE], dashboardUserRoleName),
      props: {
        identityId,
        underwritingReviewSubjectId,
        underwritingReviews,
      },
    },
  ]

  const headerBadge = !hasRecipientRole ? null : businessName ? BUSINESS : PERSONAL

  const headerData = {
    resource: {
      label: RECIPIENT_IDENTITY,
      id: identityId,
      badge: headerBadge,
    },
    items: [
      {
        value: <h1>{resourceTitle}</h1>,
      },
      {
        label: ONBOARDING_STATE_TITLE,
        value: <OnboardingState value={onboardingState} />,
      },
      {
        label: ADDED_ON,
        value: displayCreatedAt,
      },
      {
        label: COUNTRY,
        value: CountryFlagIcon({ country: personalAddressCountry }),
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    identity,
    identityId,
    applicationId,
    credentials,
    credentialId,
    tabs,
    headerData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAddBankModal: (modalProps) => dispatch(showModalAction({ modalType: ADD_IDENTITY_BANK_ACCOUNT, modalProps })),
    getIdentity: ({ identityId, credentials }) => dispatch(getIdentityRequest({ identityId, credentials })),
  }
}

class RecipientC extends Component {
  componentDidMount() {
    this.fetchIdentity()
  }

  componentDidUpdate(prevProps) {
    const {
      identityId,
    } = this.props

    const {
      identityId: prevIdentityId,
    } = prevProps

    if (identityId !== prevIdentityId) {
      this.fetchIdentity()
    }
  }

  fetchIdentity = () => {
    const {
      credentials,
      identityId,
      getIdentity,
    } = this.props

    getIdentity({ identityId, credentials })
  }

  render() {
    const {
      identityId,
      credentialId,
    } = this.props

    const actions = [
      {
        label: EDIT_RECIPIENT_CONTACT_DETAILS,
        action: () => goToPath({
          pathname: EDIT_RECIPIENT_IDENTITY_PATH({ credentialId, recipientId: identityId }),
        }),
      },
    ]

    return (
      <Recipient
        actions={actions}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientC)
