import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import getMany from 'utilities/get/getMany'
import replace from 'lodash/replace'
import upperCase from 'lodash/upperCase'

const frontendEvidencesModel = ({ data: evidences }) => {
  const evidencesModels = map(evidences, (evidence) => {
    const [
      id,
      createdAt,
      state,
      fileName,
      fileType,
      fileExtension,
      dispute,
    ] = getMany(evidence, [
      'id',
      'created_at',
      'state',
      'tags.file-name',
      'tags.content-type',
      'tags.file-extension',
      'dispute',
    ])

    return {
      id,
      createdAt,
      state,
      fileName,
      fileType,
      fileExtension,
      displayFileExtension: upperCase(replace(fileExtension, '.', '')),
      dispute,
    }
  })

  return keyBy(evidencesModels, 'id')
}

export default frontendEvidencesModel
