import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'

import {
  VELOCITY_LIMITS,
  VELOCITY_LIMITS_EMPTY_MESSAGE,
} from 'constants/language/languageConstants'

const VelocityLimits = ({
  setLimitsActions = [],
}) => {
  return (
    <div className='VelocityLimits'>
      <PageSectionV2C
        title={VELOCITY_LIMITS}
        actions={setLimitsActions}
        emptyMessage={VELOCITY_LIMITS_EMPTY_MESSAGE}
      />
    </div>
  )
}

VelocityLimits.propTypes = {
  setLimitsActions: PropTypes.array,
}

export default VelocityLimits
