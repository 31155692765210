import './SetVelocityLimitsFlowS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import FlowsC from 'components/Shared/Flows/FlowsC'
import { SET_VELOCITY_LIMITS } from 'constants/language/languageConstants'

const SetVelocityLimitsFlow = ({
  steps,
}) => {
  return (
    <div className='SetVelocityLimitsFlow'>
      <FlowsC
        layout='vertical'
        steps={steps}
        title={SET_VELOCITY_LIMITS}
      />
    </div>
  )
}

SetVelocityLimitsFlow.propTypes = {
  steps: PropTypes.array,
}

export default SetVelocityLimitsFlow
