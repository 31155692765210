import './ButtonGroupS.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import removeUndefined from 'utilities/remove/removeUndefined'
import map from 'lodash/map'

const ButtonGroup = ({
  options = [],
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className='ButtonGroup flex center'>
      { map(options, (option, index) => {
        const {
          label,
          value = 'button-group-value',
          values,
          onClick = () => {},
        } = option

        const key = `${value}-${index}`
        const classNames = classnames({ active: index === activeIndex })

        const clickHandler = () => {
          setActiveIndex(index)
          onClick(removeUndefined({ label, value, values }))
        }

        return (
          <button
            type='button'
            key={key}
            onClick={clickHandler}
            className={classNames}
            title={label}
          >
            {label}
          </button>
        )
      })}
    </div>
  )
}

ButtonGroup.propTypes = {
  options: PropTypes.array,
}

export default ButtonGroup
