import React from 'react'
import PropTypes from 'prop-types'
import { getFeesMerchantSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { SUBSCRIPTION_FEES } from 'constants/linkConstants'
import { FEES_ICON } from 'constants/iconConstants'
import { GET_FEES } from 'constants/flowConstants'
import { SUBSCRIPTION_FEES_RESOURCE_TITLE } from 'constants/language/languageConstants'

const SubscriptionFees = ({
  feePath = '',
  isFetching = false,
  initialQueries,
  allowedFilters,
}) => {
  return (
    <div className='SubscriptionFees'>
      <TableC
        allowedFilters={allowedFilters}
        columnDescriptors={columnDescriptors}
        flow={GET_FEES}
        linksKey={SUBSCRIPTION_FEES}
        selector={getFeesMerchantSelector}
        path={feePath}
        isFetching={isFetching}
        initialQueries={initialQueries}
        icon={`fa fa-${FEES_ICON}`}
        fileNameCSV='Subscription_Fees'
        fileResourceTitleCSV={SUBSCRIPTION_FEES_RESOURCE_TITLE}
        emptyMessage='No Subscription Fees Available'
      />
    </div>
  )
}

SubscriptionFees.propTypes = {
  feePath: PropTypes.string,
  isFetching: PropTypes.bool,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
}

export default SubscriptionFees
