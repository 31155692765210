import './ApplicationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ContextBar from 'components/Customer/Shared/Page/ContextBar/ContextBar'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import TabsC from 'components/Shared/Tabs/TabsC'

const Application = ({
  applicationId = '',
  tabs = [],
  headerData = {},
  contextBarData = {},
  actions = [],
}) => {
  return (
    <div className='Application'>
      <ContextBar {...contextBarData} />

      <HeaderV2C
        {...headerData}
        actions={actions}
      />

      <TabsC
        tabs={tabs}
        applicationId={applicationId}
      />
    </div>
  )
}

Application.propTypes = {
  applicationId: PropTypes.string,
  tabs: PropTypes.array,
  headerData: PropTypes.object,
  contextBarData: PropTypes.object,
  actions: PropTypes.array,
}

export default Application
