import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import hideModalAction from 'utilities/actions/hideModalAction'
import map from 'lodash/map'

import {
  PATCH_UNDERWRITING_PROFILE_F_REQUEST,
  POST_UNDERWRITING_PROFILE_F_REQUEST,
} from 'constants/flowConstants'

import {
  CREATE_UNDERWRITING_PROFILE,
  EDIT_UNDERWRITING_PROFILE,
} from 'constants/language/languageConstants'

const submitEditUnderwritingProfileForm = (values, dispatch, props) => {
  const {
    merchantCategoryCodes,
    maxTransactionAmount,
    achMaxTransactionAmount,
    allowAllMCCs,
  } = values

  const {
    underwritingProfileId,
    credentials,
    entityId,
  } = props

  const merchantCategoryCodesOnly = map(merchantCategoryCodes, 'value')

  if (!underwritingProfileId) {
    dispatch({
      type: POST_UNDERWRITING_PROFILE_F_REQUEST,
      payload: {
        values: {
          linked_to: entityId,
          max_transaction_amount: maxTransactionAmount,
          ach_max_transaction_amount: achMaxTransactionAmount,
          mcc_details: {
            allow_all_mccs: allowAllMCCs,
            enabled_mccs: allowAllMCCs ? [] : merchantCategoryCodesOnly,
          },
        },
        credentials,
      },
      meta: {
        successCallback: () => dispatch(hideModalAction()),
        actionId: sendAmplitudeActionEvent(CREATE_UNDERWRITING_PROFILE, {
          allow_all_mccs: allowAllMCCs,
          enabled_mccs: merchantCategoryCodesOnly,
          max_transaction_amount: maxTransactionAmount,
          ach_max_transaction_amount: achMaxTransactionAmount,
          credentials,
        }),
      },
    })
  } else {
    dispatch({
      type: PATCH_UNDERWRITING_PROFILE_F_REQUEST,
      payload: {
        values: {
          max_transaction_amount: maxTransactionAmount,
          ach_max_transaction_amount: achMaxTransactionAmount,
          mcc_details: {
            allow_all_mccs: allowAllMCCs,
            enabled_mccs: allowAllMCCs ? [] : merchantCategoryCodesOnly,
          },
        },
        credentials,
        id: underwritingProfileId,
      },
      meta: {
        successCallback: () => dispatch(hideModalAction()),
        actionId: sendAmplitudeActionEvent(EDIT_UNDERWRITING_PROFILE, {
          allow_all_mccs: allowAllMCCs,
          enabled_mccs: merchantCategoryCodesOnly,
          max_transaction_amount: maxTransactionAmount,
          ach_max_transaction_amount: achMaxTransactionAmount,
          credentials,
        }),
      },
    })
  }
}

export default submitEditUnderwritingProfileForm
