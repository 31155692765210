import React from 'react'
import TransferMerchantApplicationColumn from 'components/Customer/Shared/Display/Columns/TransferMerchantApplicationColumn/TransferMerchantApplicationColumn'
import TableItemSubtitle from 'components/Customer/Shared/Display/TableItemDisplayContent/TableItemSubtitle/TableItemSubtitle'
import SettlementStatus from 'components/Customer/Shared/Display/ColorcodedStatus/SettlementStatus'
import ClickToCopyC from 'components/Shared/ClickToCopy/ClickToCopyC'
import AmountCurrencyDisplayTable from 'components/Customer/Shared/Display/AmountCurrencyDisplayTable/AmountCurrencyDisplayTable'
import DateTimestampColumn from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampColumn'
import DateTimestampHeader from 'components/Customer/Shared/Display/Columns/DateTimestampColumn/DateTimestampHeader'
import formatMoney from 'utilities/formatters/formatMoney'
import formatNumber from 'utilities/formatters/formatNumber'
import column from 'utilities/table/column'
import { notRoleMerchant } from 'utilities/validate/checkRoleCredentials'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { createdAtRange } from 'utilities/table/sort'

import {
  ID,
  FEES,
  STATE,
  AMOUNT,
  PROCESSOR,
  CREATED_ON,
  NET_AMOUNT,
  APPLICATION,
  FEES_AMOUNT,
  TRANSACTIONS,
  MERCHANT_ACCOUNT,
  MERCHANT_ACCOUNT_RESOURCE_TITLE,
} from 'constants/language/languageConstants'

const columnDescriptors = [
  column(ID, 'id', {
    className: 'copy-click-id',
    formatter: ({ id }) => <ClickToCopyC position='right' content={id} />,
  }),
  column(CREATED_ON, 'createdAt', {
    className: 'date',
    sort: createdAtRange,
    headerFormatter: () => <DateTimestampHeader title={CREATED_ON} />,
    formatter: ({ createdAt }) => <DateTimestampColumn timestamp={createdAt} />,
  }),
  column(MERCHANT_ACCOUNT, 'identity.displayBusinessName', {
    className: 'large',
    headerFormatter: () => <TableItemSubtitle title={MERCHANT_ACCOUNT_RESOURCE_TITLE} subtitle={APPLICATION} />,
    formatter: (settlement) => <TransferMerchantApplicationColumn transfer={settlement} />,
    condition: ({ credentials }) => notRoleMerchant({ credentials }) && !isStandaloneMerchantDashboard(),
  }),
  column(TRANSACTIONS, 'transferCount', {
    className: 'small',
    formatter: ({ transferCount }) => (transferCount !== undefined ? formatNumber(transferCount) : '-'),
  }),
  column(FEES, 'feeCount', {
    className: 'small',
    formatter: ({ feeCount }) => (feeCount !== undefined ? formatNumber(feeCount) : '-'),
  }),
  column(AMOUNT, ['totalAmount', 'currency'], {
    className: 'amount',
    formatter: ({ totalAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />,
  }),
  column(FEES_AMOUNT, ['totalFees', 'currency'], {
    className: 'amount',
    formatter: ({ totalFees, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: totalFees, currency })} currency={currency} />,
  }),
  column(NET_AMOUNT, ['netAmount', 'currency'], {
    className: 'amount divider',
    formatter: ({ netAmount, currency }) => <AmountCurrencyDisplayTable displayAmount={formatMoney({ amount: netAmount, currency, displayNegativeAsAbsolute: true })} currency={currency} />,
  }),
  column(PROCESSOR, 'processor', {
    className: 'small',
    condition: ({ credentials }) => notRoleMerchant({ credentials }),
  }),
  column(STATE, 'status', {
    className: 'status',
    formatter: ({ status }) => <SettlementStatus value={status} />,
  }),
]

export default columnDescriptors
