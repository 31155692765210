import React, { Component } from 'react'
import { connect } from 'react-redux'
import SplitPayment from './SplitPayment'
import getSplitTransferAPI from 'api/finix/get/getSplitTransferAPI'
import AmountCurrencyDisplayHeader from 'components/Customer/Shared/Display/AmountCurrencyDisplayHeader/AmountCurrencyDisplayHeader'
import TransferStatus from 'components/Customer/Shared/Display/ColorcodedStatus/TransferStatus'
import showModalAction from 'utilities/actions/showModalAction'
import getSplitTransferSettlementRequest from 'utilities/actions/get/getSplitTransferSettlementRequest'
import { splitTransfersColumnDescriptors } from 'components/Customer/Pages/Payment/columnDescriptors'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import displayBuyerPaymentInstrumentLink from 'utilities/display/displayBuyerPaymentInstrumentLink'
import getMany from 'utilities/get/getMany'
import getSplitTransfersRequest from 'utilities/actions/get/getSplitTransfersRequest'
import getSplitTransferRequest from 'utilities/actions/get/getSplitTransferRequest'
import { isStandaloneMerchantDashboard } from 'utilities/is/isDashboardType'
import { FETCHING } from 'constants/reducerConstants'
import { VIEW_API_RESPONSE_MODAL } from 'constants/modalConstants'
import { SPLIT_TRANSFER } from 'constants/apiConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import concat from 'lodash/concat'
import filter from 'lodash/filter'

import {
  getSplitTransferSelector,
  getSplitTransfersByParentIdSelector,
  getSplitTransferSettlementSelector,
} from 'state-layer/selectors'

import {
  hasPartnerAccess,
  hasPlatformAccess,
} from 'utilities/validate/checkRoleCredentials'

import {
  APPLICATION,
  MERCHANT_IDENTITY,
  BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
  CREATED_ON,
  REMOVE_FROM_SETTLEMENT,
  PARENT_PAYMENT,
  SPLIT_TRANSACTION,
  TYPE,
  PAYMENT,
  STATE,
  MERCHANT_IDENTITY_RESOURCE_TITLE,
  MERCHANT_ACCOUNT,
  SETTLEMENT_RESOURCE_TITLE,
  TRANSFERS_API_REFERENCE,
  SPLIT_TRANSFERS_API_REFERENCE,
} from 'constants/language/languageConstants'

import {
  PAYMENT_PATH,
  APPLICATION_PATH,
  MERCHANT_PATH,
  FEES_PATH,
  IDENTITY_PATH,
  SETTLEMENT_PATH,
} from 'constants/pathConstants'

import {
  PAYMENTS_LEARN_MORE_LINK,
  SPLIT_TRANSFERS_API_DOC_LINK,
} from 'constants/urlConstants'

const mapStateToProps = (state, props) => {
  const splitTransferId = get(props, 'params.splitTransferId')
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const splitTransfer = getSplitTransferSelector(state, splitTransferId)
  const settlementObj = getSplitTransferSettlementSelector(state, splitTransferId)

  const [
    settlementId,
    settlementStatus,
  ] = getMany(settlementObj, [
    'id',
    'status',
  ])

  const isStandaloneMerchant = isStandaloneMerchantDashboard()

  // TODO: remove this hack once BE can return the Fee ID properly for the /fees endpoint
  const feesPath = ({ id = '' }) => `${FEES_PATH({ credentialId })}/${id.replace('TR', 'FE')}`

  const [
    displayCreatedAt,
    parentTransfer,
    currency,
    displayAmount,
    applicationName,
    applicationId,
    merchantName,
    merchantId,
    merchantIdentityName,
    merchantIdentityId,
    fees,
  ] = getMany(splitTransfer, [
    'displayCreatedAt',
    'parentTransfer',
    'currency',
    'displayAmount',
    'application.businessName',
    'application.id',
    'merchant.businessName',
    'merchant.id',
    'identity.businessName',
    'identity.id',
    'fees',
  ])

  const [
    parentPaymentId,
    paymentInstrument,
    parentPaymentAmount,
    buyerIdentity,
    primaryMerchantId,
    parentTransferState,
    parentRaw,
  ] = getMany(parentTransfer, [
    'id',
    'paymentInstrument',
    'displayAmount',
    'buyerIdentity',
    'merchantId',
    'state',
    'raw',
  ])

  const allSplitTransfers = getSplitTransfersByParentIdSelector(state, parentPaymentId)
  const parentPaymentPath = PAYMENT_PATH({ credentialId, transferId: parentPaymentId })
  const primaryMerchantSplitTransfer = filter(allSplitTransfers, ({ merchantId: splitTransferMerchantId }) => isEqual(splitTransferMerchantId, primaryMerchantId))
  const allOtherSplitTransfers = filter(allSplitTransfers, ({ merchantId: splitTransferMerchantId }) => !isEqual(splitTransferMerchantId, primaryMerchantId))
  const splitDetailsTableData = !isEmpty(allSplitTransfers) ? concat([parentTransfer], primaryMerchantSplitTransfer, allOtherSplitTransfers) : []
  const splitTransfersColumnDescriptorsData = splitTransfersColumnDescriptors({ isParent: false, rowId: splitTransferId })
  const isFetching = get(state, `splitTransfersR.${FETCHING}`) || get(state, `merchantsR.${FETCHING}`) || get(state, `applicationsR.${FETCHING}`) || get(state, `identitiesR.${FETCHING}`) || get(state, `settlementsR.${FETCHING}` || isEmpty(allOtherSplitTransfers))

  const [
    paymentInstrumentType,
    maskedAccountNumber,
    maskedFullCardNumber,
  ] = getMany(paymentInstrument, [
    'type',
    'maskedAccountNumber',
    'maskedFullCardNumber',
  ])

  const contextBarData = {
    items: [
      {
        label: APPLICATION,
        value: applicationName,
        path: hasPlatformAccess({ credentials }) ? APPLICATION_PATH({ credentialId, applicationId }) : null,
        condition: !isStandaloneMerchant,
      },
      {
        label: MERCHANT_IDENTITY_RESOURCE_TITLE,
        value: merchantIdentityName,
        path: hasPartnerAccess({ credentials }) ? IDENTITY_PATH({ credentialId, identityId: merchantIdentityId }) : null,
      },
      {
        label: MERCHANT_ACCOUNT,
        value: merchantName,
        path: hasPartnerAccess({ credentials }) ? MERCHANT_PATH({ credentialId, identityId: merchantIdentityId, merchantId }) : null,
      },
      {
        label: SETTLEMENT_RESOURCE_TITLE,
        value: settlementId,
        path: hasPartnerAccess({ credentials }) ? SETTLEMENT_PATH({ credentialId, settlementId }) : null,
      },
    ],
  }

  const headerData = {
    resource: {
      label: <div className='flex items-center'>{PAYMENT} <div className='split-transaction-badge'>{SPLIT_TRANSACTION}</div></div>,
      id: splitTransferId,
    },
    items: [
      {
        value: <AmountCurrencyDisplayHeader displayAmount={displayAmount} currency={currency} />,
      },
      {
        label: STATE,
        value: <TransferStatus value={parentTransferState} rawMessage={parentRaw} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: TYPE,
        value: paymentInstrumentType,
      },
      {
        label: BUYER_PAYMENT_INSTRUMENT_RESOURCE_TITLE,
        value: displayBuyerPaymentInstrumentLink({
          type: paymentInstrumentType,
          credentialId,
          paymentInstrument,
        }),
        condition: !isEmpty(paymentInstrument) && (!!maskedAccountNumber || !!maskedFullCardNumber),
      },
      {
        label: PARENT_PAYMENT,
        value: parentPaymentAmount,
        path: parentPaymentPath,
        condition: !!parentPaymentId,
      },
    ],
  }

  // TODO: add in once API is ready
  // const paymentDetailsDataSection = convertPageSectionDataToV2([
  //   {
  //     label: SETTLEMENT_STATE,
  //     value: settlementStatusValue,
  //   },
  //   {
  //     label: FUNDING_STATUS,
  //     value: fundingStatusValue,
  //     condition: showFundingStatus,
  //   },
  // ])

  return {
    isFetching,
    splitTransferId,
    contextBarData,
    credentials,
    credentialId,
    paymentInstrument,
    headerData,
    // paymentDetailsDataSection,
    parentPaymentPath,
    parentPaymentId,
    splitDetailsTableData,
    buyerIdentity,
    fees,
    feesPath,
    splitTransfersColumnDescriptors: splitTransfersColumnDescriptorsData,
    settlementObj,
    settlementId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSplitTransfer: ({ credentials, splitTransferId }) => { dispatch(getSplitTransferRequest({ credentials, splitTransferId })) },
    getSplitTransfers: ({ credentials, queries }) => { dispatch(getSplitTransfersRequest({ credentials, queries })) },
    getSettlement: ({ credentials, queries }) => { dispatch(getSplitTransferSettlementRequest({ credentials, queries })) },
    showAPIResponseModal: (modalProps) => dispatch(showModalAction({ modalType: VIEW_API_RESPONSE_MODAL, modalProps })),
  }
}

class SplitPaymentC extends Component {
  componentDidMount() {
    const { parentPaymentId } = this.props
    this.fetchSplitTransfer()

    if (parentPaymentId) {
      this.fetchAllSplitTransfers()
    }
  }

  componentDidUpdate(prevProps) {
    const { splitTransferId, parentPaymentId } = this.props

    const {
      splitTransferId: prevSplitTransferId,
      parentPaymentId: prevParentPaymentId,
    } = prevProps

    if (!isEqual(splitTransferId, prevSplitTransferId)) {
      this.fetchSplitTransfer()
    }

    if (parentPaymentId && !isEqual(parentPaymentId, prevParentPaymentId)) {
      this.fetchAllSplitTransfers()
      this.fetchSettlement()
    }

    // add className to table item to highlight selected row
    const element = document.getElementById(splitTransferId)
    if (element && !element.classList.contains('highlight')) {
      element.classList.add('highlight')
    }
  }

  fetchSplitTransfer = () => {
    const {
      credentials,
      splitTransferId,
      getSplitTransfer,
    } = this.props

    getSplitTransfer({ credentials, splitTransferId })
  }

  fetchAllSplitTransfers = () => {
    const {
      parentPaymentId,
      credentials,
      getSplitTransfers,
    } = this.props

    const queries = { parent_transfer_id: parentPaymentId }
    getSplitTransfers({ credentials, queries })
  }

  fetchSettlement = () => {
    const {
      credentials,
      splitTransferId,
      getSettlement,
    } = this.props

    getSettlement({ credentials, queries: { split_transfer_id: splitTransferId }, meta: { overwriteReducer: true } })
  }

  render() {
    const {
      transferId,
      credentials,
      splitTransferId,
      displayAmount,
      getSplitTransfer,
      showAction,
      showRemoveFromSettlementModal,
      settlementId,
      settlementEntryId,
      showAPIResponseModal,
    } = this.props

    const host = get(credentials, 'host')

    const paymentAPIDocs = [
      {
        label: SPLIT_TRANSFERS_API_REFERENCE,
        link: SPLIT_TRANSFERS_API_DOC_LINK,
      },
      {
        label: TRANSFERS_API_REFERENCE,
        link: PAYMENTS_LEARN_MORE_LINK,
      },
    ]

    const responseModalProps = () => showAPIResponseModal({
      api: getSplitTransferAPI,
      apiProps: {
        id: splitTransferId,
        credentials,
      },
      apiRoute: host && `${host}/${SPLIT_TRANSFER({ splitTransferId })}`,
      apiMethod: 'GET',
      apiDocuments: paymentAPIDocs,
    })

    // TODO: add in once API is ready
    // const actions = showAction ? ([
    //   {
    //     label: REMOVE_FROM_SETTLEMENT,
    //     action: () => {
    //       showRemoveFromSettlementModal({
    //         settlementId,
    //         settlementEntryIds: [settlementEntryId],
    //         entriesData: [{ id: transferId, amount: displayAmount }],
    //       })
    //     },
    //     className: 'remove-from-settlement-button',
    //   },
    // ]) : []

    return (
      <SplitPayment
        {...this.props}
        showResponseModal={responseModalProps}
        // actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplitPaymentC)
