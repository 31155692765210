import React, { Component } from 'react'
import { connect } from 'react-redux'
import AchAndAdditionalRulesForm from './AchAndAdditionalRulesForm'
import { FETCHING } from 'constants/reducerConstants'
import { EDIT_FEE_PROFILE_RULES_ACH_FORM } from 'constants/formConstants'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import merge from 'lodash/merge'

import {
  ACH_FEE_PROFILE_RULES_FIELDS,
  CURRENCIES_TO_COLLECT,
} from 'constants/feeProfileConstants'

const guardRailsInitialValues = ({ currencies, formValues, feeProfileRules }) => {
  return reduce(currencies, (total, currency) => {
    const values = reduce(ACH_FEE_PROFILE_RULES_FIELDS, (valuesTotal, field) => {
      return merge({}, valuesTotal, {
        [field]: get(formValues, `${currency}.${field}`) || get(feeProfileRules, `BLENDED.${currency}.${field}`) || get(feeProfileRules, `INTERCHANGE_PLUS.${currency}.${field}`),
      })
    }, {})

    return merge({}, total, {
      [currency]: values,
    })
  }, {})
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `feeProfileRulesR.${FETCHING}`, true)
  const formValues = get(state, 'flowsR.formValues.ach') || get(state, `form.${EDIT_FEE_PROFILE_RULES_ACH_FORM}.values`)
  const feeProfileRules = get(props, 'feeProfileRules')

  const currencies = CURRENCIES_TO_COLLECT

  const initialValues = !isFetching ? guardRailsInitialValues({ currencies, formValues, feeProfileRules }) : null

  return {
    initialValues,
    currencies,
  }
}


class AchAndAdditionalRulesFormC extends Component {
  render() {
    return (
      <AchAndAdditionalRulesForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(AchAndAdditionalRulesFormC)
