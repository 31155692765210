import { GET_COMPLIANCE_FORM_TEMPLATE_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getComplianceFormTemplateRequest = ({
  complianceFormTemplateId,
  credentials,
}) => createAction({
  type: GET_COMPLIANCE_FORM_TEMPLATE_F_REQUEST,
  id: complianceFormTemplateId,
  credentials,
})

export default getComplianceFormTemplateRequest
