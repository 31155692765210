import './EditActiveComplianceTemplateModalS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import EditActiveComplianceTemplateFormC from 'components/Customer/Forms/EditActiveComplianceTemplateForm/EditActiveComplianceTemplateFormC'
import GenericModal from 'components/Customer/Modals/GenericModal/GenericModal'

const EditActiveComplianceTemplateModal = ({
  credentials,
  complianceTemplateType,
  itemName,
  itemId,
}) => {
  return (
    <GenericModal
      title={`Choose a new ${complianceTemplateType} template for ${itemName}`}
      className='EditActiveComplianceTemplateModal'
      Component={EditActiveComplianceTemplateFormC}
      credentials={credentials}
      itemId={itemId}
    />
  )
}

EditActiveComplianceTemplateModal.propTypes = {
  credentials: PropTypes.object,
  complianceTemplateType: PropTypes.string,
  itemName: PropTypes.string,
  itemId: PropTypes.string,
}

export default EditActiveComplianceTemplateModal
