import { take, put, fork, cancel, cancelled } from 'redux-saga/effects'
import getReviewQueueAPI from 'api/finix/get/getReviewQueueAPI'
import getRelatedResourcesO from 'state-layer/orchestration/get/getRelatedResourcesO'
import getSettlementAPI from 'api/finix/get/getSettlementAPI'
import getApplicationAPI from 'api/finix/get/getApplicationAPI'
import getMerchantAPI from 'api/finix/get/getMerchantAPI'
import { fields } from 'components/Customer/Forms/ExportDataForm/exportAllFields'
import normalizeQueries from 'utilities/api/normalizeQueries'
import printCSV from 'utilities/printCSV'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import convertFieldItemToExportData from 'utilities/convert/convertFieldItemToExportData'
import { parseUrlQueries } from 'utilities/parseUrlQueries'
import frontendReviewQueueItemsModel from 'utilities/create/models/frontendReviewQueueItemsModel'
import frontendSettlementModel from 'utilities/create/models/frontendSettlementModel'
import frontendMerchantModel from 'utilities/create/models/frontendMerchantModel'
import frontendApplicationModel from 'utilities/create/models/frontendApplicationModel'
import removeUndefined from 'utilities/remove/removeUndefined'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import { EXPORT_ALL_CSV } from 'constants/amplitudeConstants'
import { FETCHING } from 'constants/reducerConstants'
import { ONBOARDING_SETTLEMENTS_LIST_PAGE } from 'constants/pageConstants'
import map from 'lodash/map'
import get from 'lodash/get'
import merge from 'lodash/merge'
import flatten from 'lodash/flatten'
import concat from 'lodash/concat'
import reduce from 'lodash/reduce'

import {
  GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
  GET_ALL_REVIEW_QUEUE_ITEMS_F_SUCCESS,
  GET_ALL_REVIEW_QUEUE_ITEMS_F_FAILURE,
  GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST,
  GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS,
} from 'constants/flowConstants'

const approveLoop = function * ({
  payload,
}) {
  try {
    const credentials = get(payload, 'credentials')
    const queries = parseUrlQueries()
    let totalReviewQueueItems = []
    let hasMore = true
    let currentOffset = 0
    let progressCount = 0
    let progressTotal

    // opted to split the progress bar into 4 quarters: rq, applications, settlements, merchants
    // even though the 4 quarters don't progress equally quickly, I believe this is good enough for now
    const numResources = 4

    // opted to fetch ALL rq settlements then related resources (instead of all resources in batches)
    // because getRelatedResourcesO avoids fetching repeated items, making it more efficient this way overall
    while (hasMore) {
      const currentQueries = merge({}, queries, { offset: currentOffset, limit: 100 })
      const normalizedCurrentQueries = normalizeQueries({ queries: currentQueries, normalizationMap: backendNormalizationMap })
      const reviewQueueItemsResponse = yield getReviewQueueAPI({ queries: normalizedCurrentQueries, credentials })
      const reviewQueueItems = get(reviewQueueItemsResponse, 'data._embedded.review_queue_items', [])
      totalReviewQueueItems = concat(totalReviewQueueItems, reviewQueueItems)

      // set total + offset
      const totalCount = get(reviewQueueItemsResponse, 'data.page.count', 0)
      if (!progressTotal) progressTotal = totalCount * numResources
      currentOffset += 100

      if (currentOffset < totalCount) {
        progressCount = currentOffset
      } else {
        progressCount = totalCount
        hasMore = false
      }

      yield put({
        type: GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS,
        payload: {
          newValues: {
            progressCount,
            progressTotal,
          },
        },
      })
    }

    const reviewQueueItemsModels = frontendReviewQueueItemsModel({ data: totalReviewQueueItems })

    const { data: totalApplications } = yield getRelatedResourcesO({
      credentials,
      items: totalReviewQueueItems,
      idKey: 'application',
      api: getApplicationAPI,
      frontendModel: frontendApplicationModel,
    })

    progressCount += progressTotal / numResources

    yield put({
      type: GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS,
      payload: {
        newValues: {
          progressCount,
          progressTotal,
        },
      },
    })

    const { data: totalSettlements } = yield getRelatedResourcesO({
      credentials,
      items: totalReviewQueueItems,
      idKey: 'entity_id',
      api: getSettlementAPI,
      frontendModel: frontendSettlementModel,
    })

    progressCount += progressTotal / numResources

    yield put({
      type: GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS,
      payload: {
        newValues: {
          progressCount,
          progressTotal,
        },
      },
    })

    const { data: totalMerchants } = yield getRelatedResourcesO({
      credentials,
      items: totalSettlements,
      idKey: 'merchantId',
      api: getMerchantAPI,
      frontendModel: frontendMerchantModel,
    })

    progressCount += progressTotal / numResources

    yield put({
      type: GET_ALL_REVIEW_QUEUE_ITEMS_BATCH_F_SUCCESS,
      payload: {
        newValues: {
          progressCount,
          progressTotal,
        },
      },
    })

    // combine resources
    const reviewQueueItemsCombined = reduce(reviewQueueItemsModels, (total, item, id) => {
      const applicationId = get(item, 'applicationId')
      const application = get(totalApplications, applicationId)

      const settlementId = get(item, 'entityId')
      const settlement = get(totalSettlements, settlementId)

      const merchantId = get(settlement, 'merchantId')
      const merchant = get(totalMerchants, merchantId)

      const mergedReviewQueueItem = removeUndefined(merge({}, item, {
        entity: settlement,
        application,
        merchant,
      }))

      return merge({}, total, {
        [id]: mergedReviewQueueItem,
      })
    }, {})

    const exportData = flatten(map(reviewQueueItemsCombined, (reviewQueueItem) => [convertFieldItemToExportData(fields, reviewQueueItem)]))
    const fileNameCSV = get(payload, 'fileNameCSV')

    printCSV(exportData, fileNameCSV)

    yield put({ type: GET_ALL_REVIEW_QUEUE_ITEMS_F_SUCCESS })

    sendAmplitudeActionEvent(EXPORT_ALL_CSV, {
      pageName: ONBOARDING_SETTLEMENTS_LIST_PAGE,
      fileNameCSV,
      columns: Object.keys(fields),
    })
  } finally {
    if (yield cancelled()) {
      yield put({
        type: GET_ALL_REVIEW_QUEUE_ITEMS_F_FAILURE,
        payload: {
          newValues: { // resets progress bar after clicking out of Advanced Export modal
            progressCount: 0,
            progressTotal: 0,
            [FETCHING]: false,
          },
        },
      })
    }
  }
}

const getAllReviewQueueItemsF = function * () {
  let lastTask = null

  while (true) {
    const { type, payload } = yield take([
      GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST,
      GET_ALL_REVIEW_QUEUE_ITEMS_CANCEL_F_REQUEST,
    ])

    if (lastTask) {
      yield cancel(lastTask)
    }

    if (type === GET_ALL_REVIEW_QUEUE_ITEMS_F_REQUEST) {
      lastTask = yield fork(approveLoop, { payload })
    }
  }
}

export default getAllReviewQueueItemsF
