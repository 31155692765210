import './FieldSectionV2S.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Loader from 'components/Shared/Loader/Loader'
import map from 'lodash/map'

const FieldSectionV2 = ({
  title = '',
  subHeader = '',
  fields = [],
  isFetching = false,
  displayOption,
  className = '',
}) => {
  const classNames = classnames({
    FieldSectionV2: true,
    [className]: !!className,
  })

  return (
    <section className={classNames}>
      <div className='header-section flex column'>
        <h5 className='title'>{title}</h5>
        {subHeader && <div className='subheader'>{subHeader}</div>}
      </div>

      { isFetching ? <Loader /> : (
        <div className='field-sections'>
          { map(fields, ({ label, subLabel, name, FieldComponent, Component, options, condition = true, className: fieldClassName }) => {
            const fieldClassNames = classnames({
              'field-section flex row': true,
              [fieldClassName]: !!fieldClassName,
            })

            return condition && (
              <div className={fieldClassNames}>
                <div className='flex column field-section-options'>
                  { FieldComponent && (
                    <Field
                      name={name}
                      component={FieldComponent}
                      options={options}
                    />
                  )}

                  { Component ? Component : null }

                  { displayOption ? displayOption : null }
                </div>
              </div>
            )
          }) }
        </div>
      ) }
    </section>
  )
}

FieldSectionV2.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  fields: PropTypes.array,
  isFetching: PropTypes.bool,
  displayOption: PropTypes.bool,
  className: PropTypes.string,
}

export default FieldSectionV2
