import React, { Component } from 'react'
import { connect } from 'react-redux'
import InvalidateComplianceForm from 'components/Customer/Forms/InvalidateComplianceForm/InvalidateComplianceForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import hideModalAction from 'utilities/actions/hideModalAction'
import isPatching from 'utilities/is/isPatching'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)

  return {
    credentials,
    isUpdating: isPatching(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class InvalidateComplianceFormC extends Component {
  render() {
    return (
      <InvalidateComplianceForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvalidateComplianceFormC)
