import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { POST_IDENTITY_F_REQUEST } from 'constants/flowConstants'
import { ADD_BUYER_SUCCESS_MSG } from 'constants/language/languageConstants'
import keys from 'lodash/keys'
import head from 'lodash/head'
import get from 'lodash/get'

const submitAddNewBuyerIdentityForm = (values, dispatch, props) => {
  const {
    firstName,
    lastName,
    email,
    phone,
  } = values

  const {
    credentials,
    closeModal,
    successFunction,
  } = props

  dispatch({
    type: POST_IDENTITY_F_REQUEST,
    payload: {
      values: {
        firstName,
        lastName,
        email,
        phone,
      },
      credentials,
    },
    meta: {
      successCallback: ({ newValues }) => {
        const newIdentityId = head(keys(newValues))
        const newFullName = keys(newValues).map((key) => get(newValues, `${key}.fullName`))
        const newEmail = keys(newValues).map((key) => get(newValues, `${key}.email`))
        if (successFunction) {
          successFunction(newIdentityId, newFullName, newEmail)
          closeModal()
        }
      },
      actionId: sendAmplitudeActionEvent(POST_IDENTITY_F_REQUEST, {
        credentials,
        firstName,
        lastName,
        email,
        phone,
      }),
      successMessage: ADD_BUYER_SUCCESS_MSG,
    },
  })
}

export default submitAddNewBuyerIdentityForm
