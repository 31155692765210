import { fieldEmpty, nonEmptyNumber } from 'utilities/validate'
import { AMOUNT_VALUE } from 'constants/deviceConstants'
import { SIGNATURE_THRESHOLD_AMOUNT } from 'constants/language/languageConstants'
import numeral from 'numeral'
import get from 'lodash/get'

const validateEditDeviceConfigurationDetailsForm = (values) => {
  const {
    promptSignature,
    signatureThresholdAmount,
    percentageOne,
    percentageTwo,
    percentageThree,
    amountOne,
    amountTwo,
    amountThree,
    promptReceiptConfirmation,
    receiptDeliveryPrint,
    receiptDeliveryEmail,
    receiptDeliverySms,
    automaticDeliveryPrint,
    automaticDeliveryEmail,
    automaticDeliverySms,
  } = values

  const promptSignatureValue = get(promptSignature, 'value')

  return {
    signatureThresholdAmount: promptSignatureValue === AMOUNT_VALUE ? (fieldEmpty(signatureThresholdAmount, SIGNATURE_THRESHOLD_AMOUNT)
      || (numeral(signatureThresholdAmount).value() <= 0 && `${SIGNATURE_THRESHOLD_AMOUNT} must be greater than $0.00`)) : false,
    percentageOne: nonEmptyNumber({ field: percentageOne, name: 'Percentage' }),
    percentageTwo: !fieldEmpty(percentageOne) ? nonEmptyNumber({ field: percentageTwo, name: 'Percentage' }) : false,
    percentageThree: !fieldEmpty(percentageOne) ? nonEmptyNumber({ field: percentageThree, name: 'Percentage' }) : false,
    amountTwo: !fieldEmpty(amountOne) ? nonEmptyNumber({ field: amountTwo, name: 'Amount' }) : false,
    amountThree: !fieldEmpty(amountOne) ? nonEmptyNumber({ field: amountThree, name: 'Amount' }) : false,
    receiptSettingsError: promptReceiptConfirmation && !receiptDeliveryPrint && !receiptDeliveryEmail && !receiptDeliverySms && !automaticDeliveryPrint && !automaticDeliveryEmail && !automaticDeliverySms ? true : false,
  }
}

export default validateEditDeviceConfigurationDetailsForm
