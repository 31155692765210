import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_REQUEST } from 'constants/flowConstants'
import assign from 'lodash/assign'

import {
  UPDATE_APPLICATION_SETTINGS,
  UPDATE_APPLICATION_UNDERWRITING_SETTINGS,
} from 'constants/amplitudeConstants'

const submitApplicationSettingForm = (values, dispatch, props) => {
  const {
    id,
    processingEnabled,
    settlementsEnabled,
    settlementFundingIdentifier,
    actOnDecisions,
  } = values

  const {
    record,
    actionType,
    credentials,
  } = props

  const newRecord = assign({}, record, { processingEnabled, settlementsEnabled, settlementFundingIdentifier })

  dispatch({
    type: actionType,
    payload: {
      values: newRecord,
      credentials,
      id,
    },
    meta: {
      actionId: sendAmplitudeActionEvent(UPDATE_APPLICATION_SETTINGS, {
        applicationId: id,
        processingEnabled,
        settlementsEnabled,
        credentials,
      }),
    },
  })

  // TODO: commented out 7/21 - uncomment again once backend EP is properly migrated to new engine (Sharath is BE POC)
  // dispatch({
  //   type: PATCH_APPLICATION_UNDERWRITING_SETTINGS_F_REQUEST,
  //   payload: {
  //     values: {
  //       // TODO: look into using BE model
  //       act_on_decisions: actOnDecisions,
  //     },
  //     credentials,
  //     id,
  //   },
  //   meta: {
  //     actionId: sendAmplitudeActionEvent(UPDATE_APPLICATION_UNDERWRITING_SETTINGS, {
  //       applicationId: id,
  //       actOnDecisions,
  //       credentials,
  //     }),
  //   },
  // })
}

export default submitApplicationSettingForm
