import React from 'react'
import moment from 'moment'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import ReactMultiSelectWithCheckboxes from 'components/Shared/Inputs/ReactMultiSelectWithCheckboxes/ReactMultiSelectWithCheckboxes'
import DatePicker from 'components/Shared/Inputs/DatePicker/DatePicker'
import selectOption from 'utilities/forms/selectOption'
import formatAmountField from 'utilities/formatters/fields/formatAmountField'
import { getAvailableProcessorsOptions } from 'constants/processorConstants'
import { CARD_BRAND_OPTIONS } from 'constants/cardConstants'
import { UNDERWRITING_STATUS_OPTIONS } from 'constants/underwritingConstants'
import { COMPLIANCE_FORM_OPTIONS } from 'constants/complianceConstants'
import { WEBHOOK_ACCEPTING_EVENTS_OPTIONS} from 'constants/webhookConstants'
import { CURRENCY_NAME_TO_SYMBOL_MAP } from 'constants/currencyConstants'
import { DISPUTE_RESPONSE_STATE_FILTER_OPTIONS } from 'constants/disputeConstants'
import { ROLE_STATE_OPTIONS} from 'constants/memberConstants'
import { RECOMMENDATION_ACTION_OPTIONS } from 'constants/underwritingConstants'
import { countryOptionsForFlex } from 'constants/countryConstants'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import split from 'lodash/split'
import sortBy from 'lodash/sortBy'

import {
  BUSINESS_TYPE_OPTIONS,
  UNDERWRITING_BUSINESS_TYPE_OPTIONS,
} from 'constants/identityConstants'

import {
  SETTLEMENT_STATUS_OPTIONS,
  APPROVED,
  AWAITING_APPROVAL,
  STAGED,
} from 'constants/settlementConstants'

import {
  ALL,
  TRANSFER,
  SETTLEMENT,
  DEBIT,
  CREDIT,
  REVERSAL,
  FAILED,
  SUCCEEDED,
  PENDING,
  CANCELED,
  TRANSFER_TYPE_OPTIONS,
  TRANSFER_STATE_OPTIONS,
} from 'constants/transferConstants'

import {
  PAYMENT_INSTRUMENT_ENABLED_OPTIONS,
  PAYMENT_INSTRUMENT_TYPE_OPTIONS,
} from 'constants/paymentInstrumentConstants'

import {
  PLATFORM,
  APPLICATION,
  MERCHANT
} from 'constants/payoutPlanConstants'

import {
  APPLICATION as APPLICATION_OPTION,
  MERCHANT as MERCHANT_OPTION,
  MONITORING_ALERT_NAME_MAPPING,
} from 'constants/monitoringAlertConstants'

import {
  EMAIL_ADDRESS as EMAIL_ADDRESS_LABEL,
  CREATED_AT_LABEL,
  DUE_ON_LABEL,
  HTTP_STATUS,
  HTTP_METHOD,
  HTTP_LOG_ENTITY_ID as HTTP_LOG_ENTITY_ID_LABEL,
  REQUEST_ID as REQUEST_ID_LABEL,
  REQUESTING_USER as REQUESTING_USER_LABEL,
  ATTEMPT_ID as ATTEMPT_ID_LABEL,
  ACCEPTING_EVENTS as ACCEPTING_EVENTS_LABEL,
  BUSINESS_NAME as BUSINESS_NAME_LABEL,
  DOING_BUSINESS_AS as DOING_BUSINESS_AS_LABEL,
  FIRST_NAME as FIRST_NAME_LABEL,
  LAST_NAME as LAST_NAME_LABEL,
  TITLE as TITLE_LABEL,
  LAST_FOUR as LAST_FOUR_LABEL,
  BIN as BIN_LABEL,
  ADJUSTMENT_TRANSFER_ID as ADJUSTMENT_TRANSFER_ID_LABEL,
  IDENTITY_ID as IDENTITY_ID_LABEL,
  BUSINESS_TYPE as BUSINESS_TYPE_LABEL,
  STRATEGIC_METRICS,
  FINANCE_DATA,
  OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT,
  RISK_MANAGEMENT,
  NET_PROFIT_REPORT_TYPE_DISPLAY,
  TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY,
  INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY,
  FEES_REPORT_TYPE_DISPLAY,
  FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY,
  MERCHANT_BALANCES_REPORT_TYPE_DISPLAY,
  SETTLEMENTS_REPORT_TYPE_DISPLAY,
  FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY,
  AUTHORIZATIONS_REPORT_TYPE_DISPLAY,
  TRANSACTIONS_REPORT_TYPE_DISPLAY,
  DISPUTES_REPORT_TYPE_DISPLAY,
  TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY,
  WEBHOOK_ID as WEBHOOK_ID_LABEL,
  WEBHOOK_EVENT_ID as WEBHOOK_EVENT_ID_LABEL,
  EVENT_ENTITY as EVENT_ENTITY_LABEL,
  EVENT_TYPE as EVENT_TYPE_LABEL,
  RESPONSE_STATE as RESPONSE_STATE_LABEL,
  IDENTITY_NAME,
  MERCHANT_ID as MERCHANT_ID_LABEL,
  FINGERPRINT as FINGERPRINT_LABEL,
  INSTRUMENT_CARD_TYPE as INSTRUMENT_CARD_TYPE_LABEL,
  INSTRUMENT_FINGERPRINT as INSTRUMENT_FINGERPRINT_LABEL,
  APPLICATION_ID as APPLICATION_ID_LABEL,
  LINKED_TO as LINKED_TO_LABEL,
  PROCESSOR as PROCESSOR_LABEL,
  AMOUNT as AMOUNT_LABEL,
  INSTRUMENT_TYPE as INSTRUMENT_TYPE_LABEL,
  INSTRUMENT_NAME as INSTRUMENT_NAME_LABEL,
  OWNER_IDENTITY_ID as OWNER_IDENTITY_ID_LABEL,
  NAME as NAME_LABEL,
  ACCOUNT_ROUTING_NUMBER as ACCOUNT_ROUTING_NUMBER_LABEL,
  DASHBOARD_ROLE as DASHBOARD_ROLE_LABEL,
  STATUS as STATUS_LABEL,
  TYPE as TYPE_LABEL,
  START_DATE as START_DATE_LABEL,
  END_DATE as END_DATE_LABEL,
  ENTITY_ID as ENTITY_ID_LABEL,
  REVIEW_ID as REVIEW_ID_LABEL,
  RUN_ID as RUN_ID_LABEL,
  RECOMMENDATION as RECOMMENDATION_LABEL,
  ASSIGNEE as ASSIGNEE_LABEL,
  MERCHANT_IDENTITY_ID as MERCHANT_IDENTITY_ID_LABEL,
  ONBOARDING_FORM_ID as ONBOARDING_FORM_ID_LABEL,
  COUNTRY as COUNTRY_LABEL,
  SERIAL_NUMBER as SERIAL_NUMBER_LABEL,
  DEVICE_NAME as DEVICE_NAME_LABEL,
  DEVICE_MODEL as DEVICE_MODEL_LABEL,
  DEVICE_ID as DEVICE_ID_LABEL,
  PAYOUT_LINK_ID_LABEL,
  SPLIT_TRANSFER_ID as SPLIT_TRANSFER_ID_LABEL,
  SETTLEMENT_ENTRY as SETTLEMENT_ENTRY_LABEL,
  IDEMPOTENCY_ID as IDEMPOTENCY_ID_LABEL,
  PLAN_NAME as SUBSCRIPTION_PLAN_NAME_LABEL,
  MERCHANT_ACCOUNT_LABEL,
  LINKED_TYPE as LINKED_TYPE_LABEL,
  APPLICATION as APPLICATION_LABEL,
  MERCHANT as MERCHANT_LABEL,
  APPLICATION_MERCHANT_NAME as APPLICATION_MERCHANT_NAME_LABEL,
  LINKED_APPLICATION_MERCHANT_ID as LINKED_APPLICATION_MERCHANT_ID_LABEL,
  TRANSACTION_MONITORING_ALERT_NAME as TRANSACTION_MONITORING_ALERT_NAME_LABEL,
  SUBSCRIPTION_PLAN_ID as SUBSCRIPTION_PLAN_ID_LABEL,
} from 'constants/language/languageConstants'

import {
  PERIODIC_MONTHLY,
  PERIODIC_YEARLY,
  FIXED_TIME_INTERVAL,
} from 'constants/subscriptionConstants'

import {
  FEE_LINKED_TYPE_SUBSCRIPTION,
  FEE_LINKED_TYPE_TRANSFER,
} from 'constants/feeConstants'

import {
  WEBHOOK_EVENT_TYPE_OPTIONS,
  EVENT_ENTITY_OPTIONS,
} from 'constants/webhookConstants'

import { DEVICE_MODEL_SELECT_OPTIONS } from './deviceConstants'

export const EQUALS = 'eq'
export const GREATER_THAN = 'gt'
export const GREATER_THAN_EQUALS = 'gte'
export const LESS_THAN = 'lt'
export const LESS_THAN_EQUALS = 'lte'
export const IS_BETWEEN = 'between'
export const ON = 'on'

export const EQUALS_LABEL = 'equal to'
export const GREATER_THAN_LABEL = 'greater than'
export const GREATER_THAN_EQUALS_LABEL = 'greater or equal to'
export const LESS_THAN_LABEL = 'less than'
export const LESS_THAN_EQUALS_LABEL = 'less or equal to'
export const IS_BETWEEN_LABEL = 'is between'
export const IS_BEFORE_LABEL = 'is on or before'
export const IS_AFTER_LABEL = 'is on or after'

export const EQUALS_FA_ICON = 'equals'
export const GREATER_THAN_FA_ICON = 'greater-than'
export const GREATER_THAN_EQUALS_FA_ICON = 'greater-than-equal'
export const LESS_THAN_FA_ICON = 'less-than'
export const LESS_THAN_EQUALS_FA_ICON = 'less-than-equal'

export const CREATED_AT_FIELD = 'created_at'
export const DUE_AT_FIELD = 'due_at'
export const START_DATE_FIELD = 'start_date'
export const END_DATE_FIELD = 'end_date'
export const RESPOND_BY_FIELD = 'respond_by'
export const UPDATED_AT_FIELD = 'updated_at'

export const OPERAND_ICON_MAP = {
  [EQUALS]: EQUALS_FA_ICON,
  [GREATER_THAN]: GREATER_THAN_FA_ICON,
  [GREATER_THAN_EQUALS]: GREATER_THAN_EQUALS_FA_ICON,
  [LESS_THAN]: LESS_THAN_FA_ICON,
  [LESS_THAN_EQUALS]: LESS_THAN_EQUALS_FA_ICON,
}

export const OPERAND_LABEL_MAP = {
  [EQUALS]: EQUALS_LABEL,
  [GREATER_THAN]: GREATER_THAN_LABEL,
  [GREATER_THAN_EQUALS]: GREATER_THAN_EQUALS_LABEL,
  [LESS_THAN]: LESS_THAN_LABEL,
  [LESS_THAN_EQUALS]: LESS_THAN_EQUALS_LABEL,
  [IS_BETWEEN]: IS_BETWEEN_LABEL,
  [ON]: ON,
}

export const DATE_OPERAND_LABEL_MAP = {
  [GREATER_THAN_EQUALS]: IS_AFTER_LABEL,
  [LESS_THAN_EQUALS]: IS_BEFORE_LABEL,
  [IS_BETWEEN]: IS_BETWEEN_LABEL,
  [ON]: ON,
}

export const DATE_FILTERS = {
  [CREATED_AT_FIELD]: true,
  [DUE_AT_FIELD]: true,
  [END_DATE_FIELD]: true,
  [START_DATE_FIELD]: true,
  [RESPOND_BY_FIELD]: true,
  [UPDATED_AT_FIELD]: true,
}

export const ID_OPERANDS = [EQUALS]
export const ON_OPERANDS = [ON]
export const DATE_OPERANDS = [ON, GREATER_THAN_EQUALS, LESS_THAN_EQUALS, IS_BETWEEN]
export const ALL_OPERANDS = [EQUALS, GREATER_THAN, GREATER_THAN_EQUALS, LESS_THAN, LESS_THAN_EQUALS]
export const SUBSCRIPTIONS_AMOUNT_OPERANDS = [GREATER_THAN_EQUALS, LESS_THAN_EQUALS]

export const DEFAULT_OPERAND_OPTIONS = [
  {
    label: EQUALS_LABEL,
    value: EQUALS,
  },
  {
    label: GREATER_THAN_LABEL,
    value: GREATER_THAN,
  },
  {
    label: GREATER_THAN_EQUALS_LABEL,
    value: GREATER_THAN_EQUALS,
  },
  {
    label: LESS_THAN_LABEL,
    value: LESS_THAN,
  },
  {
    label: LESS_THAN_EQUALS_LABEL,
    value: LESS_THAN_EQUALS,
  },
]

export const ID = {
  field: 'id',
  label: 'ID',
  operands: ID_OPERANDS,
}

export const TRANSFER_ID = {
  field: 'transfer_id',
  label: 'Transfer ID',
  operands: ID_OPERANDS,
}

export const EMAIL_ADDRESS = {
  field: 'email_address',
  label: EMAIL_ADDRESS_LABEL,
  operands: ID_OPERANDS,
}

export const EMAIL_ADDRESS_V2 = {
  field: 'email',
  label: EMAIL_ADDRESS_LABEL,
  operands: ID_OPERANDS,
}

export const TRACE_ID = {
  field: 'trace_id',
  label: 'Trace ID',
  operands: ID_OPERANDS,
}

export const AMOUNT = (currency) => {
  const currencySymbol = get(CURRENCY_NAME_TO_SYMBOL_MAP, currency, '$')

  return {
    field: 'amount',
    label: 'Amount',
    placeholder: '0.00',
    format: formatAmountField,
    leadingIconString: currencySymbol,
  }
}

export const SUBSCRIPTIONS_AMOUNT = (currency) => {
  const currencySymbol = get(CURRENCY_NAME_TO_SYMBOL_MAP, currency, '$')

  return {
    field: 'amount',
    label: 'Amount',
    placeholder: '0.00',
    format: formatAmountField,
    operands: SUBSCRIPTIONS_AMOUNT_OPERANDS,
    leadingIconString: currencySymbol,
  }
}

export const TOTAL_AMOUNT  = (currency) => {
  const currencySymbol = get(CURRENCY_NAME_TO_SYMBOL_MAP, currency, '$')

  return {
    field: 'total_amount',
    label: 'Amount',
    placeholder: '0.00',
    format: formatAmountField,
    operands: ID_OPERANDS,
    leadingIconString: currencySymbol,
  }
}

export const CREATED_AT = {
  field: 'created_at',
  label: CREATED_AT_LABEL,
  component: DatePicker,
  operands: DATE_OPERANDS,
  operandLabelMap: DATE_OPERAND_LABEL_MAP,
}

export const DUE_AT = {
  field: 'due_at',
  label: DUE_ON_LABEL,
  component: DatePicker,
  operands: DATE_OPERANDS,
  operandLabelMap: DATE_OPERAND_LABEL_MAP,
}

export const START_DATE = {
  field: 'start_date',
  label: START_DATE_LABEL,
  component: DatePicker,
  operands: ON_OPERANDS,
  operandLabelMap: DATE_OPERAND_LABEL_MAP,
}

export const END_DATE = {
  field: 'end_date',
  label: END_DATE_LABEL,
  component: DatePicker,
  operands: ON_OPERANDS,
  operandLabelMap: DATE_OPERAND_LABEL_MAP,
}

export const TRANSFER_TYPE = {
  field: 'type',
  label: TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: TRANSFER_TYPE_OPTIONS,
}

export const COMPLIANCE_FORM_STATUS = {
  field: 'state',
  label: STATUS_LABEL,
  operands: ID_OPERANDS,
  component: ReactMultiSelectWithCheckboxes,
  options: COMPLIANCE_FORM_OPTIONS,
  isMultiSelectFilter: true,
}

export const DISPUTE_RESPONSE_STATE = {
  field: 'response_state',
  label: RESPONSE_STATE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: DISPUTE_RESPONSE_STATE_FILTER_OPTIONS,
}

export const SETTLEMENT_ID = {
  field: 'entity_id',
  label: 'Settlement ID',
  operands: ID_OPERANDS,
}

export const SETTLEMENT_STATUS = {
  field: 'status',
  label: 'Status',
  operands: ID_OPERANDS,
  component: SelectField,
  options: SETTLEMENT_STATUS_OPTIONS,
}

export const PAYMENT_INSTRUMENT_TYPE = {
  field: 'type',
  label: 'Type',
  operands: ID_OPERANDS,
  component: SelectField,
  options: PAYMENT_INSTRUMENT_TYPE_OPTIONS,
}

export const PAYMENT_INSTRUMENT_ENABLED = {
  field: 'enabled',
  label: 'Enabled',
  operands: ID_OPERANDS,
  component: SelectField,
  options: PAYMENT_INSTRUMENT_ENABLED_OPTIONS,
}

export const SETTLEMENT_TRANSFER_TYPE = {
  field: 'type',
  label: 'Type',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('All', ALL),
    selectOption('Debits', 'debit'),
    selectOption('Credits', 'credit'),
    selectOption('Refunds', 'reverse'),
    selectOption('Disputes', 'dispute'),
    selectOption('Fees', 'fee'),
  ],
}

export const MERCHANT_ID = {
  field: 'merchant_id',
  label: MERCHANT_ID_LABEL,
  operands: ID_OPERANDS,
}

export const MERCHANT_REVIEW_QUEUE = {
  field: 'merchant',
  label: MERCHANT_ID_LABEL,
  operands: ID_OPERANDS,
}

export const FINGERPRINT = {
  field: 'fingerprint',
  label: FINGERPRINT_LABEL,
  operands: ID_OPERANDS,
}

export const INSTRUMENT_FINGERPRINT = {
  field: 'instrument_fingerprint',
  label: FINGERPRINT_LABEL,
  operands: ID_OPERANDS,
}

export const IDEMPOTENCY_ID = {
  field: 'idempotency_id',
  label: IDEMPOTENCY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const MERCHANT_IDENTITY_ID = {
  field: 'merchant_identity_id',
  label: MERCHANT_IDENTITY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const IDENTITY_ID = {
  field: 'identity_id',
  label: IDENTITY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const MERCHANT_IDENTITY_NAME = {
  field: 'merchant_identity_name*like',
  label: IDENTITY_NAME,
  operands: ID_OPERANDS,
}

export const MERCHANT_IDENTITY_NAME_EXACT = {
  field: 'merchant_identity_name',
  label: IDENTITY_NAME,
  operands: ID_OPERANDS,
}

export const APPLICATION_ID = {
  field: 'application_id',
  label: APPLICATION_ID_LABEL,
  operands: ID_OPERANDS,
}

export const APPLICATION_REVIEW_QUEUE = {
  field: 'application',
  label: APPLICATION_ID_LABEL,
  operands: ID_OPERANDS,
}

export const LINKED_TO = {
  field: 'linked_to',
  label: LINKED_TO_LABEL,
  operands: ID_OPERANDS,
}

export const MERCHANT_PROCESSOR_ID = {
  field: 'merchant_processor_id',
  label: 'Processor ID',
  operands: ID_OPERANDS,
}

export const MERCHANT_MID = {
  field: 'merchant_mid',
  label: 'MID',
  operands: ID_OPERANDS,
}

export const INSTRUMENT_CARD_LAST4 = {
  field: 'instrument_card_last4',
  label: 'Payment Card Last 4',
  operands: ID_OPERANDS,
}

export const INSTRUMENT_ACCOUNT_LAST4 = {
  field: 'instrument_account_last4',
  label: 'Bank Account Last 4',
  operands: ID_OPERANDS,
}

export const INSTRUMENT_BIN = {
  field: 'instrument_bin',
  label: 'BIN',
  operands: ID_OPERANDS,
}

export const INSTRUMENT_CARD_TYPE = {
  field: 'instrument_card_type',
  label: INSTRUMENT_CARD_TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('All', ALL),
    ...CARD_BRAND_OPTIONS
  ],
}

export const INSTRUMENT_BRAND_TYPE = {
  field: 'instrument_brand_type',
  label: 'Card Brand',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('All', ALL),
    ...CARD_BRAND_OPTIONS,
  ],
}

export const BRAND = {
  field: 'brand',
  label: 'Card Brand',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('All', ALL),
    ...CARD_BRAND_OPTIONS,
  ],
}

export const INSTRUMENT_TYPE = {
  field: 'instrument_type',
  label: INSTRUMENT_TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: PAYMENT_INSTRUMENT_TYPE_OPTIONS,
}

export const INSTRUMENT_NAME = {
  field: 'instrument_name*like',
  label: INSTRUMENT_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const INSTRUMENT_NAME_EXACT = {
  field: 'instrument_name',
  label: INSTRUMENT_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const OWNER_IDENTITY_ID = {
  field: 'owner_identity_id',
  label: OWNER_IDENTITY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const NAME = {
  field: 'name*like',
  label: NAME_LABEL,
  operands: ID_OPERANDS,
}

export const ACCOUNT_ROUTING_NUMBER = {
  field: 'account_routing_number',
  label: ACCOUNT_ROUTING_NUMBER_LABEL,
  operands: ID_OPERANDS,
}

export const ACCOUNT_LAST4 = {
  field: 'account_last4',
  label: 'Account Last Four',
  operands: ID_OPERANDS,
}

export const EXPIRATION_YEAR = {
  field: 'expiration_year',
  label: 'Expiration Year',
  operands: ID_OPERANDS,
}

export const EXPIRATION_MONTH = {
  field: 'expiration_month',
  label: 'Expiration Month',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Month', ''),
    selectOption('1 - January', '1'),
    selectOption('2 - February', '2'),
    selectOption('3 - March', '3'),
    selectOption('4 - April', '4'),
    selectOption('5 - May', '5'),
    selectOption('6 - June', '6'),
    selectOption('7 - July', '7'),
    selectOption('8 - August', '8'),
    selectOption('9 - September', '9'),
    selectOption('10 - October', '10'),
    selectOption('11 - November', '11'),
    selectOption('12 - December', '12'),
  ],
}

export const BUSINESS_TYPE = {
  field: 'business_type',
  label: BUSINESS_TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: BUSINESS_TYPE_OPTIONS,
}

export const UNDERWRITING_BUSINESS_TYPE = {
  field: 'business_type',
  label: BUSINESS_TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: UNDERWRITING_BUSINESS_TYPE_OPTIONS,
}

export const getAvailableProcessorsFilter = () => {
  return {
    field: 'processor_type',
    label: PROCESSOR_LABEL,
    operands: ID_OPERANDS,
    component: SelectField,
    options: getAvailableProcessorsOptions(),
  }
}

export const READY_FOR_UNDERWRITING_REVIEW = {
  field: 'ready_for_underwriting_review',
  label: 'Ready for Underwriting Review',
  operands: ID_OPERANDS,
}

export const UNDERWRITING_APPROVED = {
  field: 'approved',
  label: 'Approved',
  operands: ID_OPERANDS,
  labelClassname: 'approved-filter'
}

export const UNDERWRITING_REJECTED = {
  field: 'rejected',
  label: 'Rejected',
  operands: ID_OPERANDS,
  labelClassname: 'rejected-filter'
}

export const UNDERWRITING_WITHDRAWN = {
  field: 'withdrawn',
  label: 'Withdrawn',
  operands: ID_OPERANDS,
  labelClassname: 'withdrawn-filter'
}


export const UNDERWRITING_STATUS = {
  field: 'outcome',
  label: 'Status',
  operands: ID_OPERANDS,
  component: SelectField,
  options: UNDERWRITING_STATUS_OPTIONS,
}

export const DASHBOARD_ROLE = {
  field: 'dashboard_user_role_name',
  label: DASHBOARD_ROLE_LABEL,
  operands: ID_OPERANDS,
}

export const YYYY_MM_DD_FORMAT = (value) => value ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

export const FILTER_FORMATTERS = {
  created_at: YYYY_MM_DD_FORMAT,
  start_date: YYYY_MM_DD_FORMAT,
  end_date: YYYY_MM_DD_FORMAT,
  due_at: YYYY_MM_DD_FORMAT,
  updated_at: YYYY_MM_DD_FORMAT,
  respond_by: YYYY_MM_DD_FORMAT,
}

// TODO: TBD use lodash/capitalize or JS native "toLowerCase" instead of lodash/lowerCase?
export const FILTER_VALUES_MAP = {
  [TRANSFER]: [lowerCase(DEBIT)],
  [SETTLEMENT]: [lowerCase(CREDIT)],
  [REVERSAL]: [lowerCase(REVERSAL)],
  [FAILED]: [lowerCase(FAILED)],
  [SUCCEEDED]: [lowerCase(SUCCEEDED)],
  [PENDING]: [lowerCase(PENDING)],
  [CANCELED]: [lowerCase(CANCELED)],
  [APPROVED]: [lowerCase(APPROVED)],
  [AWAITING_APPROVAL]: [lowerCase(AWAITING_APPROVAL)],
  [STAGED]: [lowerCase(STAGED)],
}

export const TRANSFER_TYPE_VALUE_TO_DISPLAY_MAP = {
  [TRANSFER]: `${capitalize(DEBIT)}s`,
  [SETTLEMENT]: `${capitalize(CREDIT)}s`,
  [REVERSAL]: 'Refunds',
}

export const generateCreatedAtQueries = (createdAtRange) => {
  return createdAtRange && {
    'created_at.gte': YYYY_MM_DD_FORMAT(moment().startOf(createdAtRange).toDate()),
    'created_at.lte': YYYY_MM_DD_FORMAT(moment().endOf(createdAtRange).toDate()),
  }
}

export const generateAction = ({ queries, action = {} }) => {
  const createdAtRange = get(queries, 'createdAtRange')
  const generatedCreatedAtQueries = generateCreatedAtQueries(createdAtRange)

  return merge({}, action, {
      payload: {
        queries: {
          ...omit(queries, ['createdAtRange']),
          ...generatedCreatedAtQueries,
        }
      }
    }
  )
}

export const LEVEL = {
  field: 'level',
  label: 'Level',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Level', ''),
    selectOption('Platform', PLATFORM),
    selectOption('Application', APPLICATION),
    selectOption('Merchant', MERCHANT),
  ],
}

export const ENTRY_TYPE = {
  field: 'entry_type',
  label: 'Entry Type',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('All', ''),
    selectOption('Transfers', 'TRANSFER'),
    selectOption('Fees', 'FEE'),
    selectOption('Refunds', 'REVERSE'),
    selectOption('Disputes', 'DISPUTE_TRANSFER'),
    selectOption('Adjustments', 'ADJUSTMENT'),
  ],
}

export const SPLIT_TRANSFER_ID = {
  field: 'split_transfer_id',
  label: SPLIT_TRANSFER_ID_LABEL,
  operands: ID_OPERANDS,
}

export const ENTRY_ID = {
  field: 'entry_id',
  label: SETTLEMENT_ENTRY_LABEL,
  operands: ID_OPERANDS,
}

export const ENTITY_ID = {
  field: 'entity_id',
  label: ENTITY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const REVIEW_ID = {
  field: 'review_id',
  label: REVIEW_ID_LABEL,
  operands: ID_OPERANDS,
}

export const RUN_ID = {
  field: 'run_id',
  label: RUN_ID_LABEL,
  operands: ID_OPERANDS,
}

export const ASSIGNEE = {
  field: 'assignee',
  label: ASSIGNEE_LABEL,
  operands: ID_OPERANDS,
}

export const RECOMMENDATION = {
  field: 'recommendation',
  label: RECOMMENDATION_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: RECOMMENDATION_ACTION_OPTIONS,
}

export const ADJUSTMENT_TRANSFER_ID = {
  field: 'adjustment_transfer_id',
  label: ADJUSTMENT_TRANSFER_ID_LABEL,
  operands: ID_OPERANDS,
}

export const SUBSCRIPTION_TYPE = {
  field: 'subscription_type',
  label: 'Subscription Type',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Type', ''),
    selectOption('Fixed', FIXED_TIME_INTERVAL),
    selectOption('Monthly', PERIODIC_MONTHLY),
    selectOption('Yearly', PERIODIC_YEARLY),
  ]
}

export const SCHEDULE_NICKNAME = {
  field: 'nickname',
  label: 'Schedule Nickname',
  operands: ID_OPERANDS,
}

export const LINKED_TYPE = {
  field: 'linked_type',
  label: 'Linked Type',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Linked Type', ''),
    selectOption('Subscription', FEE_LINKED_TYPE_SUBSCRIPTION),
    selectOption('Transfer', FEE_LINKED_TYPE_TRANSFER),
  ]
}

export const REPORT_FREQUENCY = {
  field: 'period_type',
  label: 'Frequency',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Frequency', ''),
    selectOption('Daily', 'DAILY'),
    selectOption('Monthly', 'MONTHLY'),
  ]
}

export const REPORT_VERSION = {
  field: 'version',
  label: 'Version',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Version', ''),
    selectOption('V1.0', '1.0'),
    selectOption('V2.0', '2.0'),
    selectOption('V2.1', '2.1'),
    selectOption('V3.0', '3.0'),
  ]
}

export const REPORT_CATEGORY = {
  field: 'category',
  label: 'Category',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Category', ''),
    selectOption(STRATEGIC_METRICS, 'strategic'),
    selectOption(OPERATIONS_RECONCILIATION_AND_EXCEPTIONS_MANAGEMENT, 'operations'),
    selectOption(FINANCE_DATA, 'finance'),
    selectOption(RISK_MANAGEMENT, 'risk_management'),
  ]
}

export const REPORT_SUBCATEGORY = {
  field: 'subcategory',
  label: 'Subcategory',
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select Subcategory', ''),
    selectOption( NET_PROFIT_REPORT_TYPE_DISPLAY, 'net_profit'),
    selectOption(TRANSACTION_INTERCHANGE_REPORT_TYPE_DISPLAY, 'transaction_interchange'),
    selectOption(INTERCHANGE_OPTIMIZATION_REPORT_TYPE_DISPLAY, 'interchange_optimization'),
    selectOption(FEES_REPORT_TYPE_DISPLAY, 'fees'),
    selectOption(FLEX_CLIENT_BALANCES_REPORT_TYPE_DISPLAY, 'client_balance'),
    selectOption(MERCHANT_BALANCES_REPORT_TYPE_DISPLAY, 'merchant_balance'),
    selectOption(SETTLEMENTS_REPORT_TYPE_DISPLAY, 'settlements'),
    selectOption(FUNDING_INSTRUCTIONS_REPORT_TYPE_DISPLAY, 'funding_instructions'),
    selectOption(AUTHORIZATIONS_REPORT_TYPE_DISPLAY, 'authorizations'),
    selectOption(TRANSACTIONS_REPORT_TYPE_DISPLAY, 'transactions'),
    selectOption(DISPUTES_REPORT_TYPE_DISPLAY, 'disputes'),
    selectOption(TRANSACTION_MONITORING_REPORT_TYPE_DISPLAY, 'transaction_monitoring'),
  ]
}

export const AMOUNT_FILTERS = ['amount', 'total_amount']

// TODO: move each into own constant in the future?
const httpStatusCodes = ['200 - OK', '201 - Created', '202 - Accepted', '204 - No Content', '301 - Moved Permanently', '400 - Bad Request', '401 - Unauthorized', '402 - Payment Required', '403 - Forbidden', '404 - Not Found', '405 - Method Not Allowed', '409 - Conflict', '422 - Unprocessable Entity', '499 - Client Disconnected', '500 - Internal Server Error', '502 - Bad Gateway', '503 - Service Unavailable', '504 - Gateway Timeout']

const httpStatusCodesSelectOptions = map(httpStatusCodes, (codeLabel) => selectOption(codeLabel, get(split(codeLabel, '-'), '[0]').trim()))

export const HTTP_STATUS_CODES = {
  field: 'http_status_code',
  label: HTTP_STATUS,
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select HTTP Code', ''),
    ...httpStatusCodesSelectOptions,
  ]
}

const httpMethods = ['GET', 'PUT', 'POST', 'PATCH', 'DELETE']

const httpMethodsSelectOptions = map(httpMethods, (codeLabel) => selectOption(codeLabel, codeLabel))

export const HTTP_METHODS = {
  field: 'http_method',
  label: HTTP_METHOD,
  operands: ID_OPERANDS,
  component: SelectField,
  options: [
    selectOption('Select HTTP Method', ''),
    ...httpMethodsSelectOptions,
  ]
}

export const HTTP_LOG_ENTITY_ID = {
  field: 'entity_id',
  label: HTTP_LOG_ENTITY_ID_LABEL,
  operands: ID_OPERANDS,
}

export const REQUESTING_USER = {
  field: 'requesting_user',
  label: REQUESTING_USER_LABEL,
  operands: ID_OPERANDS,
}

export const REQUEST_ID = {
  field: 'request_id',
  label: REQUEST_ID_LABEL,
  operands: ID_OPERANDS,
}

export const ATTEMPT_ID = {
  field: 'id',
  label: ATTEMPT_ID_LABEL,
  operands: ID_OPERANDS,
}

export const WEBHOOK_ID = {
  field:  'webhook_id',
  label: WEBHOOK_ID_LABEL,
  operands: ID_OPERANDS,
}

export const EVENT_ID = {
  field: 'event_id',
  label: WEBHOOK_EVENT_ID_LABEL,
  operands: ID_OPERANDS,
}

export const EVENT_ENTITY = {
  field: 'entity_type',
  label: EVENT_ENTITY_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: EVENT_ENTITY_OPTIONS,
}

export const EVENT_TYPE = {
  field: 'event_type',
  label: EVENT_TYPE_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: WEBHOOK_EVENT_TYPE_OPTIONS,
}

export const ACCEPTING_EVENTS = {
  field: 'is_accepting_events',
  label: ACCEPTING_EVENTS_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: WEBHOOK_ACCEPTING_EVENTS_OPTIONS,
}

export const ROLE_STATE = {
  field: 'role_enabled',
  label: 'State',
  operands: ID_OPERANDS,
  component: SelectField,
  options: ROLE_STATE_OPTIONS,
}

export const TAGS_KEY_LIKE = {
  field: 'tags*key*like',
  label: 'Tag Key',
  operands: ID_OPERANDS,
}

export const TAGS_VALUE_LIKE = {
  field: 'tags*value*like',
  label: 'Tag Value',
  operands: ID_OPERANDS,
}

export const TAG_FILTERS = [
  TAGS_KEY_LIKE,
  TAGS_VALUE_LIKE,
]

export const BUSINESS_NAME = {
  field: 'business_name*like',
  label: BUSINESS_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const BUSINESS_NAME_EXACT = {
  field: 'business_name',
  label: BUSINESS_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const DOING_BUSINESS_AS = {
  field: 'doing_business_as*like',
  label: DOING_BUSINESS_AS_LABEL,
  operands: ID_OPERANDS,
}

export const DOING_BUSINESS_AS_EXACT = {
  field: 'doing_business_as',
  label: DOING_BUSINESS_AS_LABEL,
  operands: ID_OPERANDS,
}

export const EMAIL = {
  field: 'email*like',
  label: EMAIL_ADDRESS_LABEL,
  operands: ID_OPERANDS,
}

export const FIRST_NAME = {
  field: 'first_name*like',
  label: FIRST_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const LAST_NAME = {
  field: 'last_name*like',
  label: LAST_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const TITLE = {
  field: 'title*like',
  label: TITLE_LABEL,
  operands: ID_OPERANDS,
}

export const LAST_4 = {
  field: 'last4',
  label: LAST_FOUR_LABEL,
  operands: ID_OPERANDS,
}

export const BIN = {
  field: 'bin',
  label: BIN_LABEL,
  operands: ID_OPERANDS,
}

export const ONBOARDING_FORM_ID = {
  field: 'id',
  label: ONBOARDING_FORM_ID_LABEL,
  operands: ID_OPERANDS,
}

export const COUNTRY = {
  field: 'country',
  label: COUNTRY_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: countryOptionsForFlex,
}

export const SERIAL_NUMBER = {
  field: 'serial_number',
  label: SERIAL_NUMBER_LABEL,
  operands: ID_OPERANDS,
}

export const DEVICE_ID = {
  field: 'device',
  label: DEVICE_ID_LABEL,
  operands: ID_OPERANDS,
}
export const DEVICE_NAME = {
  field: 'name',
  label: DEVICE_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const DEVICE_MODEL = {
  field: 'model',
  label: DEVICE_MODEL_LABEL,
  operands: ID_OPERANDS,
  component: SelectField,
  options: DEVICE_MODEL_SELECT_OPTIONS,
}

export const PAYOUT_LINK_ID = {
  field: 'id',
  label: PAYOUT_LINK_ID_LABEL,
  operands: ID_OPERANDS,
}

export const SUBSCRIPTION_PLAN_NAME_EXACT = {
  field: 'plan_name',
  label: SUBSCRIPTION_PLAN_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const SUBSCRIPTION_PLAN_ID = {
  field: 'subscription_plan_id',
  label: SUBSCRIPTION_PLAN_ID_LABEL,
  operands: ID_OPERANDS,
}

export const MERCHANT_ACCOUNT_LINKED_TO = {
  field: 'linked_to',
  label: MERCHANT_ACCOUNT_LABEL,
  operands: ID_OPERANDS,
}

const monitoringAlertNameOptions = sortBy(map(MONITORING_ALERT_NAME_MAPPING, (label, value) => selectOption(label, value)), 'label')

export const MONITORING_ALERT_NAME = {
  field: 'type',
  label: TRANSACTION_MONITORING_ALERT_NAME_LABEL,
  component: SelectField,
  operands: ID_OPERANDS,
  options: [
    selectOption('Select Alert Name', ''),
    ...monitoringAlertNameOptions,
  ]
}

export const MONITORING_ALERT_LINKED_NAME = {
  field: 'linked_name',
  label: APPLICATION_MERCHANT_NAME_LABEL,
  operands: ID_OPERANDS,
}

export const MONITORING_ALERT_LINKED_TO = {
  field: 'linked_to',
  label: LINKED_APPLICATION_MERCHANT_ID_LABEL,
  operands: ID_OPERANDS,
}

export const MONITORING_ALERT_LINKED_TYPE = {
  field: 'linked_type',
  label: LINKED_TYPE_LABEL,
  component: SelectField,
  operands: ID_OPERANDS,
  options: [
    selectOption('Select Linked Type', ''),
    selectOption(APPLICATION_LABEL, APPLICATION_OPTION),
    selectOption(MERCHANT_LABEL, MERCHANT_OPTION),
  ]
}

export const NOT_ALLOWED_PARTNER_FILTERS = ['application_id']

export const NOT_ALLOWED_MERCHANT_FILTERS = ['merchant_identity_id', 'identity_id']
