import './DisputeTransferFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goBack } from 'state-layer/history'
import Button from 'components/Shared/Button/Button'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import DatePickerInput from 'components/Shared/Inputs/DatePicker/DatePickerInput'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import submitDisputeTransferForm from 'utilities/submit/submitDisputeTransferForm'
import validateDisputeTransferForm from 'utilities/validate/validateDisputeTransferForm'
import { DISPUTE_TRANSFER_FORM } from 'constants/formConstants'
import moment from 'moment'

import {
  DISPUTE_STATE_SELECT_OPTIONS,
  DISPUTE_RESPONSE_STATE_SELECT_OPTIONS,
} from 'constants/disputeConstants'

import {
  t,
  CANCEL,
  AMOUNT,
  ACTION,
  CASE_ID,
  MESSAGE,
  RESPOND_BY,
  REASON_CODE,
  PAYMENT_DETAILS,
  ENTER_DISPUTE_DETAILS,
} from 'constants/language/languageConstants'

const DisputeTransferForm = ({
  disputeId = '',
  headerLabel = '',
  submitLabel = '',
  handleSubmit = () => {},
  paymentInstrumentClickToCopies = [],
  paymentDetailsDataSection = [],
  isFetchingTransfer = false,
  isPatching = false,
  invalid = false,
}) => {
  return (
    <form className='DisputeTransferForm full-page-gray' onSubmit={handleSubmit(submitDisputeTransferForm)}>
      <div className='form-header'>
        <h3>{t(headerLabel)}</h3>
      </div>

      <RequiredFieldNotice />

      <div className='form-content'>
        <PageSectionV2C
          smallTitle={PAYMENT_DETAILS}
          clickToCopies={paymentInstrumentClickToCopies}
          columns={paymentDetailsDataSection}
          className='payment-details card'
          isFetching={isFetchingTransfer}
        />

        <div className='dispute-details'>
          <h5>{t(ENTER_DISPUTE_DETAILS)}</h5>

          <AmountField
            name='amount'
            label={AMOUNT}
          />

          <Field
            name='caseId'
            label={CASE_ID}
            component={InputField}
          />

          <div className='flex space-between'>
            <Field
              name='reasonCode'
              label={REASON_CODE}
              component={InputField}
            />

            <Field
              name='respondBy'
              label={RESPOND_BY}
              component={DatePickerInput}
              minDate={moment().toDate()}
            />
          </div>

          { disputeId ? (
            <div className='flex space-between'>
              <Field
                name='state'
                label='State'
                component={SelectField}
                options={DISPUTE_STATE_SELECT_OPTIONS}
              />

              <Field
                name='responseState'
                label='Response State'
                component={SelectField}
                options={DISPUTE_RESPONSE_STATE_SELECT_OPTIONS}
              />
            </div>
          ) : (
            <Field
              name='state'
              label='State'
              component={SelectField}
              options={DISPUTE_STATE_SELECT_OPTIONS}
            />
          )}

          { disputeId && (
            <>
              <Field
                name='message'
                label={MESSAGE}
                component={TextAreaField}
                required={false}
              />

              <Field
                name='action'
                label={ACTION}
                component={TextAreaField}
                required={false}
              />
            </>
          )}
        </div>
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <Button type='button' variant='ghost' label={CANCEL} onClick={goBack} />
          <Button type='submit' label={submitLabel} disabled={invalid} submitting={isPatching} />
        </div>
      </div>
    </form>
  )
}

DisputeTransferForm.propTypes = {
  disputeId: PropTypes.string,
  headerLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  paymentInstrumentClickToCopies: PropTypes.array,
  paymentDetailsDataSection: PropTypes.array,
  isFetchingTransfer: PropTypes.bool,
  isPatching: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default reduxForm({
  form: DISPUTE_TRANSFER_FORM,
  validate: validateDisputeTransferForm,
})(DisputeTransferForm)
