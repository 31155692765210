import './DateFieldsetS.scss'
import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import SelectField from 'components/Shared/Inputs/SelectField/SelectField'
import selectOption from 'utilities/forms/selectOption'
import createRangeArray from 'utilities/create/createRangeArray'
import map from 'lodash/map'

const monthOptions = [
  selectOption('Month', ''),
  selectOption('January', 1),
  selectOption('February', 2),
  selectOption('March', 3),
  selectOption('April', 4),
  selectOption('May', 5),
  selectOption('June', 6),
  selectOption('July', 7),
  selectOption('August', 8),
  selectOption('September', 9),
  selectOption('October', 10),
  selectOption('November', 11),
  selectOption('December', 12),
]

export const createDayOptions = (end = 31) => {
  const daysArray = createRangeArray({ start: 1, end })

  return [selectOption('Day', ''), ...map(daysArray, (day) => selectOption(day, day))]
}

const oldestYear = 1900
const currentYear = new Date().getFullYear()
const yearsArray = createRangeArray({ start: oldestYear, end: currentYear })
const yearOptions = [selectOption('Year', ''), ...map(yearsArray, (year) => selectOption(year, year)).reverse()]

const DateFieldset = ({
  name = 'date',
  label = '',
  hideField = {},
  dayOptions = createDayOptions(),
  autoComplete = {},
  required = true,
}) => {
  const fieldName = (field) => `${name}.${field}`
  const { month: hideMonth, day: hideDay, year: hideYear } = hideField
  const { month: autoCompleteMonth, day: autoCompleteDay, year: autoCompleteYear } = autoComplete

  return (
    <fieldset className='DateFieldset'>
      <div className='flex label-area items-flex-start'>
        { label && <label className='label-2'>{label}</label> }

        {required && label && <div className='required-field'>*</div>}
      </div>

      <div className='fields flex space-between'>
        { !hideMonth && (
          <Field
            name={fieldName('month')}
            component={SelectField}
            options={monthOptions}
            ariaLabel={`${label} month`}
            autoComplete={autoCompleteMonth}
          />
        ) }

        { !hideDay && (
          <Field
            name={fieldName('day')}
            component={SelectField}
            options={dayOptions}
            ariaLabel={`${label} day`}
            autoComplete={autoCompleteDay}
          />
        ) }

        {
          !hideYear && (
            <Field
              name={fieldName('year')}
              component={SelectField}
              options={yearOptions}
              ariaLabel={`${label} year`}
              autoComplete={autoCompleteYear}
            />
          )
        }
      </div>
    </fieldset>
  )
}

DateFieldset.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hideField: PropTypes.object,
  dayOptions: PropTypes.array,
  autoComplete: PropTypes.object,
  required: PropTypes.bool,
}

export default DateFieldset
