import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResendTransferForm from 'components/Customer/Forms/ResendTransferForm/ResendTransferForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import submitResendTransferForm from 'utilities/submit/submitResendTransferForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import snakeCaseToTitleCase from 'utilities/display/snakeCaseToTitleCase'
import { BANK_ICON } from 'constants/iconConstants'
import get from 'lodash/get'
import map from 'lodash/map'
import lowerCase from 'lodash/lowerCase'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const bankAccountPaymentInstruments = get(props, 'bankAccountPaymentInstruments')
  const spaceBetween = '\u00A0'
  const settlementId = get(props, 'settlementId')

  const settlementPaymentInstrumentOptions = map(bankAccountPaymentInstruments, (instrument) => {
    // eslint-disable-next-line camelcase
    const { id, type, name, masked_account_number } = instrument
    const dispayMaskedAccountNumber = lowerCase(masked_account_number)
    const displayType = snakeCaseToTitleCase({ key: type })

    return { label: (
      <div className='flex items-center'>
        {id}{spaceBetween}
        <span className='secondary'>({name}{spaceBetween} <i className={`card-brand-icon fa fa-${BANK_ICON}`} />{spaceBetween}{displayType}{spaceBetween}{dispayMaskedAccountNumber})</span>
      </div>
    ),
    value: id }
  })

  return {
    credentials,
    settlementId,
    settlementPaymentInstrumentOptions,
    bankAccountPaymentInstruments,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ResendTransferFormC extends Component {
  render() {
    return (
      <ResendTransferForm
        {...this.props}
        onSubmit={submitResendTransferForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendTransferFormC)
