import User from './User'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import showModalAction from 'utilities/actions/showModalAction'
import getPlatformUserRequest from 'utilities/actions/get/getPlatformUserRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { FETCHING } from 'constants/reducerConstants'
import { UPDATE_USER } from 'constants/modalConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  getApplicationUserSelector,
  getIdentitySelector,
} from 'state-layer/selectors'

import {
  USER,
  USERS,
  STATE,
  CREATED_ON,
  ROLE,
  DISABLED,
  ENABLED,
  IDENTITY_RESOURCE_TITLE,
  DISABLE_USER,
  ENABLE_USER,
} from 'constants/language/languageConstants'

import {
  COMPANY_PATH,
  IDENTITY_PATH,
} from 'constants/pathConstants'

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: ({ userId, credentials }) => dispatch(getPlatformUserRequest({ userId, credentials })),
    showUpdateUserModal: (modalProps) => dispatch(showModalAction({ modalType: UPDATE_USER, modalProps })),
  }
}

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `applicationUsersR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const userId = get(props, 'params.userId')
  const user = getApplicationUserSelector(state, userId)

  const [
    displayCreatedAt = '',
    identityId,
    name,
    id = '',
    status,
    displayRole,
  ] = getMany(user, [
    'displayCreatedAt',
    'identityId',
    'name',
    'id',
    'state',
    'displayRole',
  ])

  const identity = getIdentitySelector(state, identityId)
  const displayBusinessName = get(identity, 'displayBusinessName')
  const userIdDisplayString = `US-${id.substring(id.length - 4)}`

  const contextBarData = {
    back: {
      label: USERS,
      path: `${COMPANY_PATH({ credentialId })}?tab=users-and-roles`,
    },
    items: [
      {
        label: IDENTITY_RESOURCE_TITLE,
        value: displayBusinessName ? displayBusinessName : identityId,
        path: IDENTITY_PATH({ credentialId, identityId }),
      },
    ],
  }

  const headerData = {
    resource: {
      label: USER,
      id,
      clickToCopyPosition: 'right',
    },
    items: [
      {
        value: <h1>{name || userIdDisplayString || identityId || '-'}</h1>,
      },
      {
        label: STATE,
        value: <EnabledStatus value={status ? ENABLED : DISABLED} />,
      },
      {
        label: CREATED_ON,
        value: displayCreatedAt,
      },
      {
        label: ROLE,
        value: displayRole,
      },
    ],
    isFetching,
  }

  return {
    isFetching,
    credentials,
    userId,
    user,
    contextBarData,
    headerData,
  }
}

class UserC extends Component {
  componentDidMount() {
    const {
      credentials,
      userId,
      getUser,
    } = this.props

    getUser({ credentials, userId })
  }

  render() {
    const {
      showUpdateUserModal,
      userId,
      user,
      credentials,
    } = this.props

    const actionType = get(user, 'state') ? DISABLE_USER : ENABLE_USER

    const actions = !isEmpty(user) ? [
      {
        label: actionType,
        action: () => {
          showUpdateUserModal({
            userId,
            user,
            credentials,
            actionType,
          })
        },
        className: 'large disable-user-button',
      },
    ] : []

    return (
      <User
        {...this.props}
        actions={actions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserC)
