import dashboardAPI from 'utilities/api/dashboardAPI'
import { DASHBOARD_CUSTOMER_ONBOARDING_FORM_LINKS } from 'constants/apiConstants'

const postCustomerOnboardingFormLinksAPI = ({ id, values }) => {
  return dashboardAPI.post({
    path: DASHBOARD_CUSTOMER_ONBOARDING_FORM_LINKS({ id }),
    values,
  })
}

export default postCustomerOnboardingFormLinksAPI
