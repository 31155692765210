import { isNumber } from 'utilities/validate/index'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import {
  WEBSITE_REGEX,
  PROTOCOL_REGEX,
} from 'constants/regexConstants'

const formatWebsite = (website = '') => {
  if (!(isNil(website) || isEmpty(website) || isNumber(website))) {
    if (WEBSITE_REGEX.test(website)) {
      return website
    }

    // TODO: default to https instead?
    if (!(PROTOCOL_REGEX).test(website)) {
      website = `http://${website}`
    }

    return website
  }

  return '-'
}

export default formatWebsite
