import './SearchFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import submitSearchForm from 'utilities/submit/submitSearchForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Loader from 'components/Shared/Loader/Loader'
import { getIDPrefixMapping } from 'constants/IDPrefixMapping'
import { SEARCH_FORM } from 'constants/formConstants'
import { ID_SEARCH } from 'constants/language/languageConstants'
import { SEARCH_ICON } from 'constants/iconConstants'
import map from 'lodash/map'
import get from 'lodash/get'

const SearchForm = ({
  handleSubmit = () => {},
  handleFocus = () => {},
  isFetching = false,
  matchResult = [],
  newSearch = false,
  onSelectionClick = () => {},
}) => {
  const showResults = newSearch && matchResult.length > 1

  const SearchResultClassNames = classnames('SearchResults', {
    hide: !showResults,
  })

  const SearchFormClassNames = classnames('SearchForm flex space-between', {
    bottomRightAdjustment: showResults,
  })

  return (
    <form className={SearchFormClassNames} onSubmit={handleSubmit(submitSearchForm)} autoComplete='off'>
      <div className='flex search-container'>
        <Field
          id='universal-ID-search-bar'
          name='input'
          className='SearchFormInput'
          placeholder='Enter an ID...'
          component={InputField}
          onFocus={handleFocus}
          required={false}
          contextLabel={ID_SEARCH}
          trailingIcon={`search-icon fal fa-${SEARCH_ICON}`}
          trailingIconAction={handleSubmit(submitSearchForm)}
          isFetching={isFetching}
        />
      </div>

      <div className={SearchResultClassNames}>
        { map(matchResult, (result) => {
          const id = get(result, 'id')
          const { recordType } = getIDPrefixMapping(id)

          return (
            <div key={id} className='SearchResult flex space-between' onClick={() => { onSelectionClick(id) }}>
              <span className='result-info id'>{id}</span>
              <span className='result-info type'>{recordType}</span>
            </div>
          )
        })}
      </div>
    </form>
  )
}

SearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleFocus: PropTypes.func,
  isFetching: PropTypes.bool,
  matchResult: PropTypes.array,
  newSearch: PropTypes.bool,
  onSelectionClick: PropTypes.func,
}

export default reduxForm({
  form: SEARCH_FORM,
})(SearchForm)
