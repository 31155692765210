import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComplianceApplyTemplateForm from './ComplianceApplyTemplateForm'
import validateApplyTemplateForm from 'utilities/validate/validateApplyTemplateForm'
import { COMPLIANCE_APPLY_TEMPLATE_FORM } from 'constants/formConstants'
import { APPLICATION } from 'constants/payoutPlanConstants'
import { FETCHING } from 'constants/reducerConstants'
import { APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE } from 'constants/selectedItemsConstants'
import get from 'lodash/get'
import find from 'lodash/find'

import {
  formValueSelector,
  reduxForm,
  change,
} from 'redux-form'

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(COMPLIANCE_APPLY_TEMPLATE_FORM)
  const typeApplied = formSelector(state, 'typeApplied')
  const showApplicationTable = typeApplied === APPLICATION
  const isFetching = get(state, `applicationsR.${FETCHING}`)
  const selectedApplications = get(state, `selectedItemsR.${APPLY_APPLICATION_TO_COMPLIANCE_TEMPLATE}`, {})
  const selectedApplication = find(Object.keys(selectedApplications), (application) => selectedApplications[application])

  return {
    showApplicationTable,
    isFetching,
    selectedApplication,
    typeApplied,
  }
}

class ComplianceApplyTemplateFormC extends Component {
  componentDidUpdate(prevProps) {
    const { selectedApplication: prevSelectedApplication } = prevProps
    const { selectedApplication, dispatch } = this.props

    if (prevSelectedApplication !== selectedApplication) {
      dispatch(change(COMPLIANCE_APPLY_TEMPLATE_FORM, '_forceValidation', Date.now()))
    }
  }

  render() {
    return (
      <ComplianceApplyTemplateForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: COMPLIANCE_APPLY_TEMPLATE_FORM,
  validate: validateApplyTemplateForm,
})(ComplianceApplyTemplateFormC))
