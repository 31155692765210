import map from 'lodash/map'
import get from 'lodash/get'
import formatAddress from 'utilities/formatters/formatAddress'
import formatDateObject from 'utilities/formatters/formatDateObject'

import {
  EMAIL_ADDRESS,
  BUSINESS_ADDRESS,
  MERCHANT_CATEGORY_CODE,
  SOCIAL_SECURITY_NUMBER,
} from 'constants/language/languageConstants'

const IdentityFields = [
  {
    name: 'businessName',
    label: 'Business Name',
  },
  {
    name: 'doingBusinessAs',
    label: 'Doing Business As',
  },
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'businessPhone',
    label: 'Business Phone',
  },
  {
    name: 'businessAddress',
    label: BUSINESS_ADDRESS,
    formatter: (address) => formatAddress({ address }),
  },
  {
    name: 'businessType',
    label: 'Business Type',
  },
  {
    name: 'incorporationDate',
    label: 'Date of Incorporation',
    formatter: (date) => formatDateObject({ date }),
  },
  {
    name: 'businessTaxId',
    label: 'EIN',
  },
  {
    name: 'mcc',
    label: MERCHANT_CATEGORY_CODE,
  },
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    formatter: (date) => formatDateObject({ date }),
  },
  {
    name: 'personalAddress',
    label: 'Address',
    formatter: (address) => formatAddress({ address }),
  },
  {
    name: 'taxId',
    label: SOCIAL_SECURITY_NUMBER,
  },
  {
    name: 'principalPercentageOwnership',
    label: 'Principal Percentage Ownership (%)',
  },
  {
    name: 'ownershipType',
    label: 'Ownership Type',
  },
  {
    name: 'phone',
    label: 'Personal Phone',
  },
  {
    name: 'email',
    label: EMAIL_ADDRESS,
  },
  {
    name: 'previouslyProcessedCreditCards',
    label: 'Processed credit cards before?',
    formatter: (bool) => (bool ? 'Yes' : 'No'),
  },
  {
    name: 'annualCardVolume',
    label: 'Annual Card Volume',
  },
  {
    name: 'defaultStatementDescriptor',
    label: 'Default Statement Descriptor',
  },
  {
    name: 'maxTransactionAmount',
    label: 'Max Transaction Amount',
  },
]

const IdentityDisplayData = (values) => map(IdentityFields, ({ name, label, formatter }) => ({
  label,
  value: formatter ? formatter(get(values, name, {})) : get(values, name),
}))

export default IdentityDisplayData
