import './NestedCheckboxesTableS.scss'
import classnames from 'classnames'
import React from 'react'
import { change } from 'redux-form'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button/Button'
import { getDispatch } from 'state-layer/configureStore'
import getMany from 'utilities/get/getMany'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import every from 'lodash/every'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'

import {
  SELECT_ALL,
  DESELECT_ALL,
} from 'constants/language/languageConstants'

// set every possible event to true when the user clicks select all
export const allEventsEnabledFormValues = ({ allEvents }) => {
  const allEventsEnabled = {}
  forEach(allEvents, (event) => {
    const [
      eventValue,
      children,
    ] = getMany(event, [
      'value',
      'children',
    ])

    const mappedChildren = {}
    forEach(children, ({ value: childValue }) => {
      mappedChildren[childValue] = true
    })

    allEventsEnabled[eventValue] = mappedChildren
  })
  return allEventsEnabled
}

// update the event that has been clicked to be set to true on the form
const updateSelectedEvents = ({
  eventEntity,
  eventType,
  eventTypeValue = true,
  selectAll = false,
  deselectAll = false,
  formName = '',
  currentSelectedEvents,
  options,
  children,
}) => {
  const dispatch = getDispatch()

  let updatedEvents
  const currentSelectedEvent = get(currentSelectedEvents, eventEntity)

  if (selectAll) {
    dispatch(change(formName, 'selectedEvents', allEventsEnabledFormValues({ allEvents: options })))
    return
  }

  if (deselectAll) {
    dispatch(change(formName, 'selectedEvents', {}))
    return
  }

  if (eventType) {
    updatedEvents = merge({}, currentSelectedEvents, {
      [eventEntity]: { [eventType]: eventTypeValue },
    })

    // if all event types are unchecked, uncheck parent entity
    if (every(updatedEvents[eventEntity], (value) => !value)) {
      updatedEvents = omit(currentSelectedEvents, eventEntity)
    }

    dispatch(change(formName, 'selectedEvents', updatedEvents))
    return
  }


  if (!eventType && !currentSelectedEvent && children) {
    const mappedChildren = {}
    forEach(children, ({ value: childValue }) => {
      mappedChildren[childValue] = true
    })

    updatedEvents = merge({}, currentSelectedEvents, {
      [eventEntity]: mappedChildren,
    })

    dispatch(change(formName, 'selectedEvents', updatedEvents))
    return
  }

  if (!eventType && currentSelectedEvent) {
    dispatch(change(formName, 'selectedEvents', omit(currentSelectedEvents, eventEntity)))
  }
}

// check if all possible events are currently selected.
export const allPossibleEventsSelected = ({ currentSelectedEvents, allEvents }) => {
  return every(allEvents, (event) => {
    const [
      value,
      children,
    ] = getMany(event, [
      'value',
      'children',
    ])
    const selectedEvent = get(currentSelectedEvents, value)

    if (!selectedEvent) {
      return false
    }

    const mappedChildren = {}
    forEach(children, ({ value: childValue }) => {
      mappedChildren[childValue] = true
    })

    if (!isEqual(mappedChildren, selectedEvent)) {
      return false
    }

    return true
  })
}

// TODO: refactor nested checkbox table to become generic component and not webhook event specific
const NestedCheckboxesTable = ({
  input,
  title,
  subLabel,
  options,
  disabled = false,
  formName = '',
  currentSelectedEvents = {},
  eventsTableValidationMsg,
  meta: { touched },
  showActions = true,
  showHeaderCheckboxes = true,
}) => {
  const selectedEventName = get(input, 'name', '')
  const noItemsSelected = isEmpty(currentSelectedEvents)
  const allSelected = allPossibleEventsSelected({ currentSelectedEvents, allEvents: options })

  const nestedCheckboxesClassNames = classnames({
    NestedCheckboxesTable: true,
    disabled: !!disabled,
  })

  return (
    <div className={nestedCheckboxesClassNames}>
      <div className='flex items-center space-between'>
        { title && <label className='title label-2'>{title}</label> }

        {!disabled && showActions && (
        <div className='flex'>
          {!allSelected && (
            <Button onClick={() => updateSelectedEvents({ selectAll: true, formName, currentSelectedEvents, options })} label={SELECT_ALL} variant='secondary' />
          )}

          {!noItemsSelected && (
            <Button onClick={() => updateSelectedEvents({ deselectAll: true, formName, currentSelectedEvents, options })} label={DESELECT_ALL} className='deselect-all-button' variant='secondary' />
          )}
        </div>
        )}
      </div>

      { subLabel && <div className='sub-label label-2'>{subLabel}</div> }

      <table className='table-body'>
        <tbody>
          {map(options, ({ label: checkboxLabel, value: entityType, children = [] }) => {
            const checkboxClassNames = classnames({
              checkbox: true,
              flex: true,
              'items-flex-start': true,
            })

            const currentSelectedEvent = get(currentSelectedEvents, entityType)
            const checked = !!currentSelectedEvent

            return (
              <tr className='checkbox-container' key={`${selectedEventName}.${entityType}`}>
                <td className={checkboxClassNames}>
                  <ul className='checkbox-list'>
                    <li>
                      {showHeaderCheckboxes ? (
                        <div className='flex items-center parent-checkbox-list'>
                          <input
                            type='checkbox'
                            disabled={disabled}
                            name={entityType}
                            value={entityType}
                            checked={checked}
                            onChange={() => updateSelectedEvents({ eventEntity: entityType, formName, currentSelectedEvents, options, children })}
                          />

                          { checkboxLabel && (
                            <span
                              className='checkbox-option-label label-2'
                              onClick={!disabled ? () => updateSelectedEvents({ eventEntity: entityType, formName, currentSelectedEvents, options, children }) : () => {}}
                            >
                              {checkboxLabel}
                            </span>
                          ) }
                        </div>
                      ) : (
                        <div className='flex items-center parent-checkbox-list'>
                          <span className='checkbox-option-label label-2'>{checkboxLabel}</span>
                        </div>
                      )}

                      <ul className='nested-checkboxes-list flex row'>
                        {map(children, ({ label: nestedLabel, value: nestedValue }, idx) => {
                          const nestedCheckboxName = `${entityType}.${nestedValue}`
                          const nestedChecked = get(currentSelectedEvents, `${entityType}.${nestedValue}`, false)
                          const key = `${entityType}.${nestedValue}.${idx}`

                          return (
                            <li key={key}>
                              <div className='flex items-center'>
                                <input
                                  id={nestedCheckboxName}
                                  type='checkbox'
                                  name={nestedCheckboxName}
                                  value={nestedValue}
                                  checked={nestedChecked}
                                  disabled={disabled}
                                  onChange={(event) => {
                                    updateSelectedEvents({ eventEntity: entityType, eventType: nestedValue, eventTypeValue: event.target.checked, formName, currentSelectedEvents, options })
                                  }}
                                />

                                <span
                                  className='checkbox-option-label label-2'
                                  onClick={!disabled ?
                                    (e) => {
                                      const eventTypeValue = document.getElementById(nestedCheckboxName).checked
                                      updateSelectedEvents({ eventEntity: entityType, eventType: nestedValue, eventTypeValue: !eventTypeValue, formName, currentSelectedEvents, options })
                                    } : () => {}}
                                >
                                  {nestedLabel}
                                </span>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
            )
          })
          }

          {eventsTableValidationMsg && touched && <span className='empty-validation-msg'>{eventsTableValidationMsg}</span>}
        </tbody>
      </table>
    </div>
  )
}

NestedCheckboxesTable.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  eventsTableValidationMsg: PropTypes.string,
  formName: PropTypes.string,
  currentSelectedEvents: PropTypes.object,
  showActions: PropTypes.bool,
}

export default NestedCheckboxesTable
