import frontendSettlementModel from 'utilities/create/models/frontendSettlementModel'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const frontendSettlementsModel = ({ data: settlements }) => {
  const settlementModels = map(settlements, (settlement) => frontendSettlementModel({ data: settlement }))

  return keyBy(settlementModels, 'id')
}

export default frontendSettlementsModel
