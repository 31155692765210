import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccessFormSelector } from 'state-layer/selectors'
import LiveAccountApplicationAdditionalDocuments from './LiveAccountApplicationAdditionalDocuments'
import getAccessFormAdditionalDocumentsRequest from 'utilities/actions/get/getAccessFormAdditionalDocumentsRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import showModalAction from 'utilities/actions/showModalAction'
import { FILE_VIEWER_MODAL } from 'constants/modalConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const isFetching = get(state, `accessFormsR.${FETCHING}`)
  const credentials = getCurrentCredentials(state)
  const accessFormId = get(props, 'accessFormId')
  const accessFormAdditionalDocuments = get(getAccessFormSelector(state, accessFormId), 'additionalDocumentsFiles')

  return {
    credentials,
    isFetching,
    accessFormAdditionalDocuments,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessFormAdditionalDocuments: ({ accessFormId, credentials, meta }) => dispatch(getAccessFormAdditionalDocumentsRequest({ accessFormId, credentials, meta })),
    showFileViewer: (modalProps) => dispatch(showModalAction({ modalType: FILE_VIEWER_MODAL, modalProps })),
  }
}


class LiveAccountApplicationAdditionalDocumentsC extends Component {
  componentDidMount() {
    const {
      getAccessFormAdditionalDocuments,
      credentials,
      accessFormId,
      isHostedAccessForm,
    } = this.props

    getAccessFormAdditionalDocuments({ credentials, accessFormId, meta: { isHostedAccessForm } })
  }

  render() {
    const {
      accessFormId,
      showFileViewer,
      isHostedAccessForm,
    } = this.props

    const showFileViewerOnClick = ({ id }) => showFileViewer({ id, accessFormId, isHostedAccessForm })

    return (
      <LiveAccountApplicationAdditionalDocuments
        showFileViewerOnClick={showFileViewerOnClick}
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAccountApplicationAdditionalDocumentsC)
