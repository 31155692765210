import './AltPaymentMethodsS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2 from 'components/Customer/Shared/Page/PageSection/PageSectionV2'
import ApplePayDomainRegistrationsC from 'components/Customer/Pages/ApplePayDomainRegistrations/ApplePayDomainRegistrationsC'
import ApplePayCertManagementC from 'components/Customer/Pages/ApplePayCertManagement/ApplePayCertManagementC'
import Loader from 'components/Shared/Loader/Loader'
import isEmpty from 'lodash/isEmpty'

import {
  APPLE_PAY_FINIX_DEV_DOCS_URL,
  GOOGLE_PAY_DEVELOPER_PORTAL_URL,
} from 'constants/urlConstants'

import {
  APPLE_PAY,
  APPLE_PAY_DESCRIPTION,
  APPLE_PAY_LINK_DESCRIPTION,
  GOOGLE_PAY_DESCRIPTION,
  GOOGLE_PAY_FINIX_DESCRIPTION,
  GOOGLE_PAY_LINK_DESCRIPTION,
} from 'constants/language/languageConstants'

const AltPaymentMethods = ({
  identityId,
  isFetchingDomains = false,
  isFetchingCerts = false,
}) => {
  const showTables = !!identityId

  return (
    <div className='AltPaymentMethods'>
      <PageSectionV2>
        <div className='apple-pay-section'>
          <h4 className='section-title'>{APPLE_PAY}</h4>
          <div className='apple-pay-description'>{APPLE_PAY_DESCRIPTION} <a className='text-link' href={APPLE_PAY_FINIX_DEV_DOCS_URL} target='blank'>{APPLE_PAY_LINK_DESCRIPTION}</a></div>
          { showTables ?
            (
              <>
                <ApplePayDomainRegistrationsC
                  identityId={identityId}
                  isFetching={isFetchingDomains}
                />

                <ApplePayCertManagementC
                  identityId={identityId}
                  isFetching={isFetchingCerts}
                />
              </>
            ) : <Loader />
          }
        </div>
      </PageSectionV2>

      <PageSectionV2>
        <div className='google-pay-section'>
          <div className='section-title'> Google Pay </div>
          <div className='google-pay-description'>{GOOGLE_PAY_DESCRIPTION}</div>
          <div className='google-pay-description'>{GOOGLE_PAY_FINIX_DESCRIPTION} <a className='text-link' href={GOOGLE_PAY_DEVELOPER_PORTAL_URL} target='blank'>{GOOGLE_PAY_LINK_DESCRIPTION}</a></div>
        </div>
      </PageSectionV2>
    </div>
  )
}

AltPaymentMethods.propTypes = {
  identityId: PropTypes.string,
  isFetchingDomains: PropTypes.bool,
  isFetchingCerts: PropTypes.bool,
}

export default AltPaymentMethods
