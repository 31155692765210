import './TransactionInsightsTabsS.scss'
import React, { Component } from 'react'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import PaymentsInsightsC from 'components/Customer/Pages/Insights/TransactionInsights/PaymentsInsights/PaymentsInsightsC'
import AuthorizationsInsightsC from 'components/Customer/Pages/Insights/TransactionInsights/AuthorizationsInsights/AuthorizationsInsightsC'
import RefundsInsightsC from 'components/Customer/Pages/Insights/TransactionInsights/RefundsInsights/RefundsInsightsC'
import DisputesInsightsC from 'components/Customer/Pages/Insights/ExceptionsInsights/DisputesInsights/DisputesInsightsC'
import ACHReturnsInsightsC from 'components/Customer/Pages/Insights/ExceptionsInsights/ACHReturnsInsights/ACHReturnsInsightsC'
import { BUSINESS_ANALYTICS_LINK } from 'constants/urlConstants'

import {
  ACH_RETURNS,
  AUTHORIZATIONS,
  DISPUTES_RESOURCE_TITLE,
  PAYMENTS,
  REFUNDS,
  TRANSACTION_INSIGHTS,
  TRANSACTION_INSIGHTS_SUBTITLE,
} from 'constants/language/languageConstants'

class TransactionInsightsTabs extends Component {
  render() {
    const tabs = [
      {
        id: 'payments-insights',
        name: PAYMENTS,
        component: PaymentsInsightsC,
      },
      {
        id: 'authorizations-insights',
        name: AUTHORIZATIONS,
        component: AuthorizationsInsightsC,
      },
      {
        id: 'refunds-insights',
        name: REFUNDS,
        component: RefundsInsightsC,
      },
      {
        id: 'disputes-insights',
        name: DISPUTES_RESOURCE_TITLE,
        component: DisputesInsightsC,
      },
      {
        id: 'ach-returns-insights',
        name: ACH_RETURNS,
        component: ACHReturnsInsightsC,
      },
    ]

    return (
      <div className='TransactionInsightsTabs'>
        <HeaderV2C
          title={TRANSACTION_INSIGHTS}
          subTitle={TRANSACTION_INSIGHTS_SUBTITLE}
          learnMoreLink={BUSINESS_ANALYTICS_LINK}
        />

        <TabsC tabs={tabs} />
      </div>
    )
  }
}

export default TransactionInsightsTabs
