import paymentsAPI from 'utilities/api/paymentsAPI'
import {
  PLATFORMS,
  PLATFORM_PROCESSOR_CONFIGS,
} from 'constants/apiConstants'

const getPlatformProcessorConfigsAPI = ({ values, credentials, meta }) => {
  const {
    platformId,
  } = values

  return paymentsAPI.get({
    meta,
    path: PLATFORM_PROCESSOR_CONFIGS({ platformId }),
    credentials,
    service: PLATFORMS,
  })
}

export default getPlatformProcessorConfigsAPI
