import get from 'lodash/get'

import {
  WEBSITE_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regexConstants'

import {
  fieldEmpty,
  invalidRegex,
  validateDate,
  validateTaxId,
  validateAddress,
  validateMaskedTaxId,
} from 'utilities/validate'

const validateIdentityBusinessForm = (values, props) => {
  const {
    businessName,
    doingBusinessAs,
    businessPhone = '',
    website,
    businessType,
    ownershipType,
    incorporationDate,
    businessTaxId,
    businessAddress,
  } = values

  const editIdentity = get(props, 'edit', false)
  const isHostedOnboarding = get(props, 'onboardingFormId')
  let businessTaxIdValidation = false

  if (isHostedOnboarding) {
    businessTaxIdValidation = validateMaskedTaxId({ taxId: businessTaxId, name: 'Tax ID or SSN' })
  } else if (!editIdentity) {
    businessTaxIdValidation = validateTaxId({ taxId: businessTaxId, name: 'Tax ID or SSN' })
  }

  return {
    businessName: fieldEmpty(businessName, 'Business Name'),
    doingBusinessAs: fieldEmpty(doingBusinessAs, 'Doing Business As'),
    businessPhone: fieldEmpty(businessPhone, 'Business Phone') || invalidRegex({ field: businessPhone, name: 'Business Phone', regex: PHONE_NUMBER_REGEX, customErrorMessage: 'Phone number must be a max of 11 digits.' }),
    website: fieldEmpty(website, 'Website') || invalidRegex({ field: website, name: 'Website', regex: WEBSITE_REGEX }),
    businessType: fieldEmpty(businessType, 'Business Type'),
    ownershipType: fieldEmpty(ownershipType, 'Ownership Type'),
    incorporationDate: validateDate({ date: incorporationDate }),
    businessTaxId: businessTaxIdValidation,
    businessAddress: validateAddress({ address: businessAddress }),
  }
}

export default validateIdentityBusinessForm
