import paymentsAPI from 'utilities/api/paymentsAPI'

import {
  USERS,
  USER,
} from 'constants/apiConstants'

const patchUserAPI = ({ values, credentials, id, meta }) => {
  const path = USER({ id })

  return paymentsAPI.put({
    values,
    credentials,
    path,
    meta,
    service: USERS,
  })
}

export default patchUserAPI
