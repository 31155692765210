import React from 'react'
import ReviewQueueOnboardingStatus from 'components/Customer/Shared/Display/ColorcodedStatus/ReviewQueueOnboardingStatus'
import column from 'utilities/table/column'
import { createdAtRange } from 'utilities/table/sort'

const columnDescriptors = [
  column('Merchant', 'merchantIdentity.displayBusinessName', { className: 'large' }),
  column('Onboarding State', 'outcome', {
    className: 'large',
    formatter: ({ outcome }) => <ReviewQueueOnboardingStatus value={outcome} />,
  }),
  column('Created', 'createdAt', {
    className: 'date',
    sort: createdAtRange,
  }),
  column('Processor', 'entity.processor', { className: 'small' }),
]

export default columnDescriptors
