import paymentsAPI from 'utilities/api/paymentsAPI'
import { ADMIN } from 'constants/apiConstants'

const patchAdminUpdateAPI = ({ values, credentials, meta }) => {
  return paymentsAPI.put({
    meta,
    values,
    credentials,
    path: ADMIN,
    service: ADMIN,
  })
}

export default patchAdminUpdateAPI
