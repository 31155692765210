import postAccessFormFileO from 'state-layer/orchestration/post/postAccessFormFileO'
import patchGuestAccessFormAPI from 'api/guestDashboard/patch/patchGuestAccessFormAPI'
import co from 'co'
import createSuccessResponse from 'utilities/api/createSuccessResponse'
import getMany from 'utilities/get/getMany'
import createErrorResponse from 'utilities/api/createErrorResponse'
import normalizeResponse from 'utilities/api/normalizeResponse'
import backendAccessFormModel from 'utilities/create/models/backendAccessFormModel'
import { backendNormalizationMap } from 'constants/normalizationConstants'
import get from 'lodash/get'
import merge from 'lodash/merge'

function * patchGuestAccessFormO ({
  id,
  values,
  credentials,
}) {
  // upload ein verification file to s3 if it exists
  const einFile = get(values, 'einVerificationFile')
  let einVerificationFileId

  const [
    einVerificationFile,
    einVerificationFileType,
    einVerificationFileName,
    existingFileId,
  ] = getMany(einFile, [
    'file',
    'fileType',
    'file.name',
    'file_id',
  ])

  if (einFile && !existingFileId) {
    const { fileId } = yield postAccessFormFileO({
      id,
      credentials,
      file: einVerificationFile,
      fileType: einVerificationFileType,
      fileName: einVerificationFileName,
      isGuestAccessForm: true,
    })

    einVerificationFileId = fileId
  }

  // upload bank account verification file to s3 if it exists
  const bankAccountFile = get(values, 'bankDocument')
  let bankAccountVerificationFileId

  const [
    bankAccountVerificationFile,
    bankAccountVerificationFileType,
    bankAccountVerificationFileName,
    bankAccountFileId,
  ] = getMany(bankAccountFile, [
    'file',
    'fileType',
    'file.name',
    'file_id',
  ])

  if (bankAccountFile && !bankAccountFileId) {
    const { fileId } = yield postAccessFormFileO({
      id,
      credentials,
      file: bankAccountVerificationFile,
      fileType: bankAccountVerificationFileType,
      fileName: bankAccountVerificationFileName,
      isGuestAccessForm: true,
    })

    bankAccountVerificationFileId = fileId
  }

  // once files are uploaded, pass the id and name to the access form patch
  const valuesToSubmit = merge({}, values, { einVerificationFileName, einVerificationFileId, bankAccountVerificationFileName, bankAccountVerificationFileId })

  // run through BE model
  const backendModelValuesToSubmit = backendAccessFormModel({ values: valuesToSubmit })

  // run through normalizer
  const normalizedValuesToSubmit = normalizeResponse({ content: backendModelValuesToSubmit, normalizationMap: backendNormalizationMap })

  const { data: dashboardCustomizationResponse, error } = yield patchGuestAccessFormAPI({
    id,
    values: normalizedValuesToSubmit,
  })

  if (error) {
    return createErrorResponse({ error })
  }

  return createSuccessResponse({
    data: dashboardCustomizationResponse,
  })
}

export default co.wrap(patchGuestAccessFormO)
