// TODO: write unit test for this
import createAction from 'utilities/actions/createAction'
import { GET_MERCHANT_VERIFICATIONS_F_REQUEST } from 'constants/flowConstants'

const getMerchantVerificationsRequest = ({
  merchantId,
  credentials,
}) => createAction({
  type: GET_MERCHANT_VERIFICATIONS_F_REQUEST,
  values: { merchantId },
  credentials,
  meta: {
    selectorId: merchantId,
    showErrors: false,
  },
})

export default getMerchantVerificationsRequest
