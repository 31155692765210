/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import './ToggleSwitchS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import { CHECK_ICON } from 'constants/iconConstants'

const ToggleSwitch = ({
  input,
  name,
  handleChange = () => {},
  checked,
  label = '',
  disabled = false,
  className = '',
  helperText,
  tooltip,
  tooltipPosition,
  disabledTooltip = '',
  disabledTooltipPosition = 'top-of-children',
}) => {
  const classNames = classnames('ToggleSwitch flex', className,
    { 'disabled-toggle': disabled,
      'toggle-on': !!checked,
      'items-center': !helperText,
    })

  return (
    <div className={classNames}>
      {/* if input is disabled, wrap the input with a tooltip */}
      {(disabled && disabledTooltip) ? (
        <TooltipLabelC
          showIcon={false}
          message={disabledTooltip}
          position={disabledTooltipPosition}
        >
          <div className='toggle-switch-area flex items-center'>
            <input
              {...input}
              id={name}
              name={name}
              type='checkbox'
              checked={checked}
              disabled={disabled}
              onChange={handleChange}
              aria-checked={checked}
              tabIndex='0'
            />
            <label
              htmlFor={name}
              aria-label={`${label} switch`}
              aria-checked={checked}
              onKeyDown={disabled ? () => {} : handleChange}
            >
              {checked && <i className={`check-icon fa fa-${CHECK_ICON}`} />}
            </label>
          </div>
        </TooltipLabelC>
      ) : (
        <div className='toggle-switch-area flex items-center'>
          <input
            {...input}
            id={name}
            name={name}
            type='checkbox'
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
            aria-checked={checked}
            tabIndex='0'
          />

          <label
            htmlFor={name}
            aria-label={`${label} switch`}
            aria-checked={checked}
            onKeyDown={disabled ? () => {} : handleChange}
          >
            {checked && <i className={`check-icon fa fa-${CHECK_ICON}`} />}
          </label>
        </div>
      )}

      <div className='label-section'>
        <div className='flex'>
          { label && <label className='label-1 label-text'>{label}</label> }
          { tooltip && <TooltipLabelC message={tooltip} position={tooltipPosition} /> }
        </div>
        { helperText && <div className='helper-text'>{helperText}</div> }
      </div>
    </div>
  )
}

ToggleSwitch.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  helperText: PropTypes.string,
  disabledTooltip: PropTypes.string,
  disabledTooltipPosition: PropTypes.string,
}

export default ToggleSwitch
