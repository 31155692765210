import { CLEAR_REDUCER } from 'constants/flowConstants'

const clearReducerRequest = (reducerNames) => {
  return {
    type: CLEAR_REDUCER,
    payload: {
      names: reducerNames,
    },
  }
}

export default clearReducerRequest
