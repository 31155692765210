import React, { Component } from 'react'
import { connect } from 'react-redux'
import HostFileForm from './HostFileForm'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import getMany from 'utilities/get/getMany'
import { ADD_NEW_DOMAIN_DATA_KEY } from 'constants/flowsConstants'
import get from 'lodash/get'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    identityId,
    formValues,
    dispatch,
  ] = getMany(props, [
    'identityId',
    'formValues',
    'dispatch',
  ])

  const domainInput = get(get(formValues, ADD_NEW_DOMAIN_DATA_KEY), 'websiteDomain')

  const submitValues = {
    credentials,
    identityId,
    websiteDomain: domainInput,
  }

  return {
    dispatch,
    domainInput,
    submitValues,
  }
}

class HostFileFormC extends Component {
  render() {
    return (
      <HostFileForm
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(HostFileFormC)
