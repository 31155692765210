import React, { Component } from 'react'
import { connect } from 'react-redux'
import MerchantFees from './MerchantFees'
import getCurrentCredentialsId from 'utilities/get/getCurrentCredentialsId'
import { FETCHING } from 'constants/reducerConstants'
import { FEE_TABLE_ITEM_PATH } from 'constants/pathConstants'
import { LINKED_TYPE } from 'constants/filterConstants'
import { createdLastMonthQueries } from 'constants/queryConstants'
import { DATE_QUICK_FILTER } from 'constants/quickFilterConstants'
import get from 'lodash/get'

const allowedFilters = [LINKED_TYPE]

const quickFilters = [
  DATE_QUICK_FILTER,
]

const mapStateToProps = (state, props) => {
  const merchantId = get(props, 'merchantId')
  const isFetching = get(state, `feesR.${FETCHING}`)
  const credentialId = getCurrentCredentialsId(state)
  const feePath = ({ settlementId, id }) => FEE_TABLE_ITEM_PATH({ credentialId, settlementId, id })

  const initialQueries = {
    merchant_id: merchantId,
  }

  return {
    isFetching,
    initialQueries,
    merchantId,
    feePath,
    allowedFilters,
    quickFilters,
    initialFilters: createdLastMonthQueries(),
  }
}

class MerchantFeesC extends Component {
  render() {
    return (
      <MerchantFees
        {...this.props}
      />
    )
  }
}

export default connect(mapStateToProps)(MerchantFeesC)
