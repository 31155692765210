import './WebhooksS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getWebhooksSelector } from 'state-layer/selectors'
import TableC from 'components/Shared/Table/TableC'
import columnDescriptors from './columnDescriptors'
import { WEBHOOKS } from 'constants/linkConstants'
import { GET_WEBHOOKS } from 'constants/flowConstants'

import {
  WEBHOOKS_EMPTY_TABLE_MESSAGE,
  WEBHOOKS_LIST_WARNING_MSG,
  WEBHOOKS_LIST_WARNING_DESCRIPTION,
  VIEW_WEBHOOKS_WITH_ISSUES,
} from 'constants/language/languageConstants'

const Webhooks = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  webhookPath = '',
  isFetching = false,
  actions = [],
  quickFilters,
  webhooksWithStoppedEventsExist = false,
  filterByWebhooksWithStoppedEvents = () => {},
}) => {
  return (
    <div className={`Webhooks ${webhooksWithStoppedEventsExist ? 'has-banner' : 'no-banner'}`}>
      {webhooksWithStoppedEventsExist && (
      <div className='banner'>
        <div className='warning-msg flex items-center'>
          <div>{WEBHOOKS_LIST_WARNING_MSG}</div>
          <span className='view-webhooks-with-issues' onClick={() => filterByWebhooksWithStoppedEvents()}>{VIEW_WEBHOOKS_WITH_ISSUES}</span>
        </div>

        <div className='warning-description'>{WEBHOOKS_LIST_WARNING_DESCRIPTION}</div>
      </div>
      )}

      <TableC
        flow={GET_WEBHOOKS}
        flowValues={flowValues}
        columnDescriptors={columnDescriptors}
        selector={getWebhooksSelector}
        initialQueries={initialQueries}
        quickFilters={quickFilters}
        linksKey={WEBHOOKS}
        allowedFilters={allowedFilters}
        path={webhookPath}
        isFetching={isFetching}
        actions={actions}
        emptyMessage={WEBHOOKS_EMPTY_TABLE_MESSAGE}
      />
    </div>
  )
}

Webhooks.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  webhookPath: PropTypes.string,
  isFetching: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  webhooksWithStoppedEventsExist: PropTypes.bool,
  filterByWebhooksWithStoppedEvents: PropTypes.func,
}

export default Webhooks
