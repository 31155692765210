import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import ResendEmailForm from 'components/Customer/Forms/ResendEmailForm/ResendEmailForm'
import postSelfServiceResendEmailWithCaptchaRequest from 'utilities/actions/post/postSelfServiceResendEmailWithCaptchaRequest'
import hideModalAction from 'utilities/actions/hideModalAction'
import { RESEND_EMAIL_FORM } from 'constants/formConstants'
import get from 'lodash/get'

const mapStateToProps = (state) => {
  const hCaptchaFormError = !!get(state, ['form', RESEND_EMAIL_FORM, 'syncErrors', 'hCaptchaToken']) && !!get(state, ['form', RESEND_EMAIL_FORM, 'submitFailed'])

  return { hCaptchaFormError }
}

const mapDispatchToProps = dispatch => {
  return {
    resendEmailWithCaptcha: ({ dashboardSandboxId, hCaptchaToken, emailAddress }) => { dispatch(postSelfServiceResendEmailWithCaptchaRequest({ dashboardSandboxId, hCaptchaToken, emailAddress })) },
    updateSignupFormWithToken: (token) => { dispatch(change(RESEND_EMAIL_FORM, 'hCaptchaToken', token)) },
    closeModal: () => dispatch(hideModalAction()),
  }
}

class ResendEmailFormC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      token: null,
    }
  }

  resendEmail = () => {
    const { resendEmailWithCaptcha, closeModal, dashboardSandboxId, emailAddress } = this.props
    const { token } = this.state

    if (token && dashboardSandboxId) {
      resendEmailWithCaptcha({ dashboardSandboxId, hCaptchaToken: token, emailAddress })
      closeModal()
    }
  }

  onVerifyCaptcha = (token) => {
    const { updateSignupFormWithToken } = this.props

    // update the form for validation purposes
    updateSignupFormWithToken(token)

    this.setState({
      token,
    })
  }

  render() {
    return (
      <ResendEmailForm
        {...this.props}
        onVerifyCaptcha={this.onVerifyCaptcha}
        resendEmail={this.resendEmail}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmailFormC)
