import { getDispatch } from 'state-layer/configureStore'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import createAction from 'utilities/actions/createAction'
import getMerchantComplianceFormsRequest from 'utilities/actions/get/getMerchantComplianceFormsRequest'
import { POST_COMPLIANCE_FORM_F_REQUEST } from 'constants/flowConstants'
import { CREATE_COMPLIANCE_FORM } from 'constants/amplitudeConstants'
import { PCI_SAQ_A } from 'constants/complianceConstants'

const postComplianceFormRequest = ({
  merchantId,
  closeModal = () => {},
  credentials,
}) => createAction({
  type: POST_COMPLIANCE_FORM_F_REQUEST,
  values: {
    linked_to: merchantId,
    type: PCI_SAQ_A,
  },
  credentials,
  meta: {
    actionId: sendAmplitudeActionEvent(CREATE_COMPLIANCE_FORM, {
      credentials,
      linkedTo: merchantId,
      type: PCI_SAQ_A,
    }),
    successCallback: () => {
      closeModal()
      const dispatch = getDispatch()
      dispatch(getMerchantComplianceFormsRequest({ merchantId, credentials }))
    },
    selectorId: merchantId,
  },
})

export default postComplianceFormRequest
