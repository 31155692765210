import './FilesS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TableC from 'components/Shared/Table/TableC'
import { MERCHANT_FILES_EMPTY_TABLE_MESSAGE } from 'constants/language/languageConstants'

const Files = ({
  showFileViewer = () => {},
  allowedFilters = [],
  showLimitCount = false,
  fileNameCSV = '',
  flow = '',
  linksKey = '',
  initialQueries = {},
  selector = () => {},
  actions = [],
  columnDescriptors,
  isFetching = false,
  data,
  title = '',
  urlQueriesToIgnore = [],
}) => {
  return (
    <div className='Files'>
      { data && (
        <TableC
          columnDescriptors={columnDescriptors}
          showLimitCount={showLimitCount}
          allowedFilters={allowedFilters}
          clickAction={showFileViewer}
          emptyMessage={MERCHANT_FILES_EMPTY_TABLE_MESSAGE}
          fileNameCSV={fileNameCSV}
          data={data}
          linksKey={linksKey}
          initialQueries={initialQueries}
          actions={actions}
          isFetching={isFetching}
          title={title}
          urlQueriesToIgnore={urlQueriesToIgnore}
        />
      )}

      { !data && (
        <TableC
          columnDescriptors={columnDescriptors}
          showLimitCount={showLimitCount}
          allowedFilters={allowedFilters}
          clickAction={showFileViewer}
          emptyMessage={MERCHANT_FILES_EMPTY_TABLE_MESSAGE}
          fileNameCSV={fileNameCSV}
          flow={flow}
          linksKey={linksKey}
          initialQueries={initialQueries}
          selector={selector}
          actions={actions}
          isFetching={isFetching}
          title={title}
          urlQueriesToIgnore={urlQueriesToIgnore}
        />
      )}
    </div>
  )
}

Files.propTypes = {
  showFileViewer: PropTypes.func,
  allowedFilters: PropTypes.array,
  showLimitCount: PropTypes.bool,
  fileNameCSV: PropTypes.string,
  flow: PropTypes.string,
  linksKey: PropTypes.string,
  initialQueries: PropTypes.object,
  selector: PropTypes.func,
  actions: PropTypes.array,
  columnDescriptors: PropTypes.array,
  isFetching: PropTypes.bool,
  data: PropTypes.object,
  title: PropTypes.string,
  urlQueriesToIgnore: PropTypes.array,
}

export default Files
