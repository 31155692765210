import './MerchantInsightsTabsS.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TabsC from 'components/Shared/Tabs/TabsC'
import HeaderV2C from 'components/Customer/Shared/Page/HeaderV2/HeaderV2C'
import MerchantAccountsInsightsC from 'components/Customer/Pages/Insights/MerchantInsights/MerchantAccountsInsights/MerchantAccountsInsightsC'
import ComplianceInsightsC from 'components/Customer/Pages/Insights/MerchantInsights/ComplianceInsights/ComplianceInsightsC'
import OnboardingFormsInsightsC from 'components/Customer/Pages/Insights/MerchantInsights/OnboardingFormsInsights/OnboardingFormsInsightsC'
import { isFlexPlatform } from 'constants/flexConstants'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { isStandaloneUnderwritingPlatform } from 'utilities/is/isPlatformType'
import { BUSINESS_ANALYTICS_LINK } from 'constants/urlConstants'

import {
  MERCHANT_ACCOUNTS_RESOURCE_TITLE,
  COMPLIANCE_RESOURCE_TITLE,
  ONBOARDING_FORMS,
  MERCHANT_INSIGHTS,
  MERCHANT_INSIGHTS_SUBTITLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const credentials = getCurrentCredentials(state)
  const isFlex = isFlexPlatform({ credentials })
  const isStandaloneUnderwriting = isStandaloneUnderwritingPlatform()

  const tabs = isStandaloneUnderwriting ? [
    {
      id: 'compliance-insights',
      name: COMPLIANCE_RESOURCE_TITLE,
      component: ComplianceInsightsC,
    },
    {
      id: 'onboarding-forms-insights',
      name: ONBOARDING_FORMS,
      component: OnboardingFormsInsightsC,
    },
  ] : [
    {
      id: 'merchant-accounts-insights',
      name: MERCHANT_ACCOUNTS_RESOURCE_TITLE,
      component: MerchantAccountsInsightsC,
    },
    {
      id: 'compliance-insights',
      name: COMPLIANCE_RESOURCE_TITLE,
      component: ComplianceInsightsC,
      condition: isFlex,
    },
    {
      id: 'onboarding-forms-insights',
      name: ONBOARDING_FORMS,
      component: OnboardingFormsInsightsC,
      condition: isFlex,
    },
  ]

  return {
    tabs,
  }
}


class MerchantInsightsTabs extends Component {
  render() {
    const {
      tabs,
    } = this.props

    return (
      <div className='MerchantInsightsTabs'>
        <HeaderV2C
          title={MERCHANT_INSIGHTS}
          subTitle={MERCHANT_INSIGHTS_SUBTITLE}
          learnMoreLink={BUSINESS_ANALYTICS_LINK}
        />

        <TabsC tabs={tabs} />
      </div>
    )
  }
}

export default connect(mapStateToProps)(MerchantInsightsTabs)
