import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPayoutLinksSelector } from 'state-layer/selectors'
import PayoutLinkConfirmation from 'components/Customer/Pages/PayoutLinkConfirmation/PayoutLinkConfirmation'
import getGuestCheckoutTokenVerificationRequest from 'utilities/actions/get/getGuestCheckoutTokenVerificationRequest'
import getUrlQuery from 'utilities/get/getUrlQuery'
import { sendAmplitudeActionEvent } from 'utilities/amplitude'
import { GET_GUEST_CHECKOUT_PAYOUT_LINK_F_REQUEST } from 'constants/flowConstants'
import { LOAD_PAYOUT_LINK_CONFIRMATION } from 'constants/amplitudeConstants'
import { PUSH_TO_ACH } from 'constants/transferConstants'
import { COMPLETED } from 'constants/paymentLinkConstants'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  ALERT_ICON,
  CHECK_CIRCLE_ICON,
  CLOCK_ICON,
  TIMES_CIRCLE_ICON,
} from 'constants/iconConstants'

import {
  CONFIRMATION_PAGE_DEACTIVATED_LINK_STATUS_TITLE,
  CONFIRMATION_PAGE_EXPIRED_LINK_STATUS_TITLE,
  CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
  CONFIRMATION_PAGE_FAILURE_STATUS_MSG,
  CONFIRMATION_PAGE_FAILURE_STATUS_TITLE,
  CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_1,
  CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_2,
  CONFIRMATION_PAGE_PAYOUT_COMPLETED_TITLE,
  CONFIRMATION_PAGE_TRANSACTION_PENDING_MSG,
  CONFIRMATION_PAGE_TRANSACTION_PENDING_TITLE,
  CONFIRMATION_PAGE_TRANSACTION_SUCCESSFUL_TITLE,
} from 'constants/language/languageConstants'

const mapStateToProps = (state) => {
  const payoutLinks = getPayoutLinksSelector(state)
  const payoutLinkId = head(Object.keys(payoutLinks))
  const payoutLink = get(payoutLinks, payoutLinkId, {})
  const status = getUrlQuery('status')
  const payoutLinkUrl = get(payoutLink, 'linkUrl')
  const payoutLinkDisplayTotalAmount = get(payoutLink, 'displayTotalAmount')
  const payoutLinkState = get(payoutLink, 'state')
  const operationKey = getUrlQuery('operationKey')
  const showACHMessage = operationKey === PUSH_TO_ACH
  const today = new Date().toLocaleDateString()

  const statusToIconMap = {
    success: `far fa-${CHECK_CIRCLE_ICON}`,
    failure: `far fa-${TIMES_CIRCLE_ICON}`,
    expired: `far fa-${ALERT_ICON}`,
    deactivated: `far fa-${ALERT_ICON}`,
    awaiting_payout: `far ${CLOCK_ICON}`,
    completed: `far fa-${CHECK_CIRCLE_ICON}`,
  }

  const statusToTitleMap = {
    success: CONFIRMATION_PAGE_TRANSACTION_SUCCESSFUL_TITLE,
    failure: CONFIRMATION_PAGE_FAILURE_STATUS_TITLE,
    expired: CONFIRMATION_PAGE_EXPIRED_LINK_STATUS_TITLE,
    deactivated: CONFIRMATION_PAGE_DEACTIVATED_LINK_STATUS_TITLE,
    awaiting_payout: CONFIRMATION_PAGE_TRANSACTION_PENDING_TITLE,
    completed: CONFIRMATION_PAGE_PAYOUT_COMPLETED_TITLE,
  }

  const statusToMessageMap = {
    success: <p>Your payout claim of {payoutLinkDisplayTotalAmount} was successful.</p>,
    failure: CONFIRMATION_PAGE_FAILURE_STATUS_MSG,
    expired: CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
    deactivated: CONFIRMATION_PAGE_EXPIRED_OR_DEACTIVATED_LINK_STATUS_MSG,
    awaiting_payout: <div className='flex column'><span>Your payout claim of {payoutLinkDisplayTotalAmount} is being processed.</span><span>{CONFIRMATION_PAGE_TRANSACTION_PENDING_MSG}</span></div>,
    completed: <div className='flex column'><span>{CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_1}</span><span>{CONFIRMATION_PAGE_PAYOUT_COMPLETED_MSG_2}</span></div>,
  }

  return {
    status,
    payoutLink,
    isFetching: isEmpty(payoutLink),
    payoutLinkUrl,
    payoutLinkDisplayTotalAmount,
    statusToIconMap,
    statusToTitleMap,
    statusToMessageMap,
    showACHMessage,
    today,
    payoutLinkState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestCheckoutPayoutLink: ({ payoutLinkId }) => {
      dispatch({
        type: GET_GUEST_CHECKOUT_PAYOUT_LINK_F_REQUEST,
        payload: {
          id: payoutLinkId,
        },
        meta: {
          actionId: sendAmplitudeActionEvent(LOAD_PAYOUT_LINK_CONFIRMATION, {
            payoutLinkId,
          }),
        },
      })
    },
    getHostedFormTokenVerification: ({ meta }) => { dispatch(getGuestCheckoutTokenVerificationRequest({ meta })) },
  }
}

class PayoutLinkConfirmationC extends Component {
  state = {
    interval: null,
  }

  componentDidMount() {
    const {
      getGuestCheckoutPayoutLink,
      getHostedFormTokenVerification,
    } = this.props

    // verify bearer token from url
    getHostedFormTokenVerification({
      meta: {
        successCallback: ({ newValues: tokenResponse }) => {
          const entityId = get(tokenResponse, 'entity_id')

          if (entityId) {
            getGuestCheckoutPayoutLink({ payoutLinkId: entityId })

            // check status every 20 seconds
            this.interval = setInterval(() => {
              const { payoutLinkState } = this.props

              if (payoutLinkState === COMPLETED) {
                clearInterval(this.interval)
              } else {
                getGuestCheckoutPayoutLink({ payoutLinkId: entityId })
              }
            }, 60000)
          }
        },
      },
    })
  }

  componentWillUnmount() {
    const { interval } = this.state
    clearInterval(interval)
  }

  render() {
    return (
      <PayoutLinkConfirmation {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutLinkConfirmationC)
