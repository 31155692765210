import React from 'react'
import PropTypes from 'prop-types'
import AccordionGroup from 'components/Customer/Shared/Page/PageSection/AccordionGroup'
import Accordion from 'components/Customer/Shared/Page/PageSection/Accordion'
import SanctionsScreeningReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReport/SanctionsScreeningReportTabs'
import BusinessIdentityVerificationReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BusinessIdentityVerificationReport/BusinessIdentityVerificationReportTabs'
import BusinessMatchReportTabs from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BusinessMatchReport/BusinessMatchReportTabs'
import AdverseMediaReportC from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/AdverseMediaReport/AdverseMediaReportC'
import BusinessIdentityVerificationReportV2C from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BusinessIdentityVerificationReportV2/BusinessIdentityVerificationReportV2C'
import BusinessIdentityVerificationReportTabsV2 from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/BusinessIdentityVerificationReportV2/BusinessIdentityVerificationReportTabsV2'
import SanctionsScreeningReportV2C from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReportV2/SanctionsScreeningReportV2C'
import SanctionsScreeningReportTabsV2 from 'components/Customer/Pages/UnderwritingV2/UnderwritingReports/SanctionsScreeningReportV2/SanctionsScreeningReportTabsV2'
import formatUnderwritingReportTitle from 'utilities/formatters/formatUnderwritingReportTitle'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  ADVERSE_MEDIA_SCREENING,
  ERROR as ERROR_LABEL,
  EXACT_HIT,
  HIGH_MATCH_HIT,
  IDENTITY_VERIFICATION,
  MATCHES_FOUND,
  MATCHES_NOT_FOUND,
  MEDIUM_MATCH_HIT,
  NO_HIT as NO_HIT_LABEL,
  NO_REPORT_DETAILS_AVAILABLE,
  REPORT_FAILED_TO_RUN,
  SANCTIONS_SCREENING,
  UNKNOWN as UNKNOWN_LABEL,
  MATCH_LIST_SCREENING,
} from 'constants/language/languageConstants'

import {
  ADVERSE_MEDIA_REVIEW,
  ONBOARDING_REVIEW,
  SANCTIONS_REVIEW,
} from 'constants/reviewQueueConstants'

const sanctionsScreeningNormalizationMap = {
  ERROR: ERROR_LABEL,
  NO_HIT: NO_HIT_LABEL,
  UNKNOWN: UNKNOWN_LABEL,
  HIT_EXACT: EXACT_HIT,
  HIT_HIGH_MATCH: HIGH_MATCH_HIT,
  HIT_MEDIUM_MATCH: MEDIUM_MATCH_HIT,
}

// NOTE: This component shows the various types of reports for underwriting
// It shows GIACT, PERSONA, or MATCH reports depending on what type of report is returned to use from the API
// If it is a standalone underwriting dashboard - we want to show V2 reports with no Raw data.

const BusinessReportAccordions = ({
  businessIdentityReport = {},
  sanctionsReportV1 = {},
  sanctionsReportV2 = {},
  adverseMediaReport = {},
  matchReport = {},
  isStandaloneUnderwriting = false,
  reviewType = '',
}) => {
  const showBusinessVerificationReport = reviewType === ONBOARDING_REVIEW
  const showSanctionsScreeningReport = reviewType === ONBOARDING_REVIEW || reviewType === SANCTIONS_REVIEW
  const showAdverseMediaReport = reviewType === ONBOARDING_REVIEW || reviewType === ADVERSE_MEDIA_REVIEW
  const showMatchReport = reviewType === ONBOARDING_REVIEW

  return (
    <AccordionGroup>
      {showBusinessVerificationReport && (
      <>
        {(get(businessIdentityReport, 'version') === 'V2' || get(businessIdentityReport, 'type') === 'BUSINESS_IDENTITY_REPORT_V2') && (
        <Accordion
          title={formatUnderwritingReportTitle(businessIdentityReport, IDENTITY_VERIFICATION)}
          description={`${get(businessIdentityReport, 'normalized_response')}: ${get(businessIdentityReport, 'normalized_message')}`}
          isEmpty={isEmpty(get(businessIdentityReport, 'details'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(businessIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          {isStandaloneUnderwriting ? <BusinessIdentityVerificationReportV2C report={businessIdentityReport} /> : <BusinessIdentityVerificationReportTabsV2 report={businessIdentityReport} /> }
        </Accordion>
        )}

        {get(businessIdentityReport, 'source') === 'GIACT' && (
        <Accordion
          title={formatUnderwritingReportTitle(businessIdentityReport, IDENTITY_VERIFICATION)}
          description={get(businessIdentityReport, 'normalized_message') || ''}
          isEmpty={isEmpty(get(businessIdentityReport, 'raw.BusinessData'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(businessIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <BusinessIdentityVerificationReportTabs report={businessIdentityReport} />
        </Accordion>
        )}

        {/* if no report is run show the disabled accordion state */}
        {isEmpty(businessIdentityReport) && (
        <Accordion
          title={formatUnderwritingReportTitle(businessIdentityReport, IDENTITY_VERIFICATION)}
          isDisabled={isEmpty(businessIdentityReport)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        />
        )}
      </>
      )}

      {showMatchReport && !isEmpty(matchReport) && (
      <Accordion
        title={formatUnderwritingReportTitle(matchReport, MATCH_LIST_SCREENING)}
        description={get(matchReport, 'normalized_response') ? `${get(matchReport, 'normalized_response')}: ${get(matchReport, 'normalized_message')}` : ''}
        isEmpty={isEmpty(get(matchReport, 'details.hits'))}
        isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
      >
        <BusinessMatchReportTabs report={matchReport} />
      </Accordion>
      )}

      {showSanctionsScreeningReport && (
      <>
        {!isEmpty(sanctionsReportV1) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV1, SANCTIONS_SCREENING)}
          description={get(sanctionsReportV1, 'normalized_response') ? `${sanctionsScreeningNormalizationMap[get(sanctionsReportV1, 'normalized_response')]}: ${get(sanctionsReportV1, 'normalized_message')}` : ''}
          isEmpty={isEmpty(get(sanctionsReportV1, 'raw.PotentialMatchers')) || get(sanctionsReportV1, 'normalized_response') === 'NO_HIT'}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(sanctionsReportV1)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          <SanctionsScreeningReportTabs report={sanctionsReportV1} />
        </Accordion>
        )}

        {!isEmpty(sanctionsReportV2) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV2, SANCTIONS_SCREENING)}
          description={`${get(sanctionsReportV2, 'normalized_response')}: ${get(sanctionsReportV2, 'normalized_message')}`}
          isEmpty={isEmpty(get(sanctionsReportV2, 'details.hits'))}
          isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
          isDisabled={isEmpty(sanctionsReportV2)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        >
          {isStandaloneUnderwriting ? <SanctionsScreeningReportV2C report={sanctionsReportV2} /> : <SanctionsScreeningReportTabsV2 report={sanctionsReportV2} />}
        </Accordion>
        )}

        {/* if no sanctions report is run show the disabled accordion state */}
        {(isEmpty(sanctionsReportV2) && isEmpty(sanctionsReportV1)) && (
        <Accordion
          title={formatUnderwritingReportTitle(sanctionsReportV2, SANCTIONS_SCREENING)}
          isDisabled={isEmpty(sanctionsReportV2)}
          isDisabledMessage={REPORT_FAILED_TO_RUN}
        />
        )}
      </>
      )}

      {showAdverseMediaReport && (
      <Accordion
        title={formatUnderwritingReportTitle(adverseMediaReport, ADVERSE_MEDIA_SCREENING, true)}
        description={get(adverseMediaReport, 'found') ? MATCHES_FOUND : MATCHES_NOT_FOUND}
        isEmpty={!get(adverseMediaReport, 'found')}
        isEmptyMessage={NO_REPORT_DETAILS_AVAILABLE}
        isDisabled={isEmpty(adverseMediaReport)}
        isDisabledMessage={REPORT_FAILED_TO_RUN}
      >
        <AdverseMediaReportC report={adverseMediaReport} />
      </Accordion>
      )}
    </AccordionGroup>
  )
}

BusinessReportAccordions.propTypes = {
  businessIdentityReport: PropTypes.object,
  sanctionsReportV1: PropTypes.object,
  sanctionsReportV2: PropTypes.object,
  adverseMediaReport: PropTypes.object,
  matchReport: PropTypes.object,
  isStandaloneUnderwriting: PropTypes.bool,
  reviewType: PropTypes.string,
}

export default BusinessReportAccordions
