import './SendPayoutAmountFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { goToPath } from 'state-layer/history'
import Loader from 'components/Shared/Loader/Loader'
import Button from 'components/Shared/Button/Button'
import RadioButton from 'components/Shared/Inputs/RadioButton/RadioButton'
import AmountField from 'components/Shared/Inputs/AmountField/AmountField'
import TextAreaField from 'components/Shared/Inputs/TextAreaField/TextAreaField'
import validateSendPayoutAmountForm from 'utilities/validate/validateSendPayoutAmountForm'
import prevFlowStep from 'utilities/prevFlowStep'
import { SEND_PAYOUT_AMOUNT_FORM } from 'constants/formConstants'
import { HOME_PATH } from 'constants/pathConstants'
import { USD } from 'constants/currencyConstants'

import {
  CANCEL,
  NEXT,
  AMOUNT,
  AMOUNT_DETAILS,
  OPTIONAL_NOTE,
  PREVIOUS_STEP,
  SELECT_BANK_TRANSFER_SPEED_LABEL,
  TRANSFER_SPEED,
  SAME_DAY_ACH_LABEL,
  SAME_DAY_ACH_HELPER_TEXT,
  NEXT_DAY_ACH_LABEL,
  NEXT_DAY_ACH_HELPER_TEXT,
  PAYOUT_AMOUNT,
  REQUIRED_FIELD_NOTICE,
} from 'constants/language/languageConstants'

const SendPayoutAmountForm = ({
  handleSubmit = () => {},
  credentialId = '',
  displayAvailableAmount = '',
  invalid = false,
  isBankAccount = false,
  isSameDayAchEnabled = false,
  applicationSameDayAchEnabled = false,
  isFetchingMerchantData = false,
}) => {
  return (
    <form className='SendPayoutAmountForm' onSubmit={handleSubmit}>
      <div className='form-header'>
        <h5>{PAYOUT_AMOUNT}</h5>
        <div className='sub-header label-2'>Your account balance is {displayAvailableAmount}.</div>
      </div>

      <div className='form-content'>
        <div className='required-text-sub-header label-2'>{REQUIRED_FIELD_NOTICE}</div>
        <div className='form-content-header'>
          <h5>{AMOUNT_DETAILS}</h5>
        </div>
        <AmountField
          name='amount'
          placeholder='0.00'
          label={AMOUNT}
          contextLabel={USD}
        />

        <Field
          name='note'
          className='note-field'
          label={OPTIONAL_NOTE}
          component={TextAreaField}
          required={false}
          characterLimit={500}
        />

        {isBankAccount && isFetchingMerchantData && <Loader /> }

        {isBankAccount && isSameDayAchEnabled && applicationSameDayAchEnabled && (
          <div className='payout-speed'>
            <h5>{TRANSFER_SPEED}</h5>
            <Field
              name='transferSpeed'
              label={SELECT_BANK_TRANSFER_SPEED_LABEL}
              component={RadioButton}
              required
              options={[
                { label: NEXT_DAY_ACH_LABEL, value: 'NEXT_DAY_ACH', helperText: NEXT_DAY_ACH_HELPER_TEXT },
                { label: SAME_DAY_ACH_LABEL, value: 'PUSH_TO_SAME_DAY_ACH', helperText: SAME_DAY_ACH_HELPER_TEXT },
              ]}
            />
          </div>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={prevFlowStep} label={PREVIOUS_STEP} variant='tertiary' />
          </div>
          <div className='exit-and-save-container'>
            <Button onClick={() => goToPath({ pathname: HOME_PATH({ credentialId }) })} label={CANCEL} variant='ghost' />
            <Button type='submit' label={NEXT} disabled={invalid} />
          </div>
        </div>
      </div>
    </form>
  )
}

SendPayoutAmountForm.propTypes = {
  handleSubmit: PropTypes.func,
  credentialId: PropTypes.string,
  displayAvailableAmount: PropTypes.string,
  invalid: PropTypes.bool,
  isBankAccount: PropTypes.bool,
  isSameDayAchEnabled: PropTypes.bool,
  isFetchingMerchantData: PropTypes.bool,
  applicationSameDayAchEnabled: PropTypes.bool,
}

export default reduxForm({
  form: SEND_PAYOUT_AMOUNT_FORM,
  validate: validateSendPayoutAmountForm,
})(SendPayoutAmountForm)
