import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToPath } from 'state-layer/history'
import PaymentLinkCreatedConfirmation from './PaymentLinkCreatedConfirmation'
import copyToClipboard from 'utilities/copyToClipboard'
import getPaymentLinkRequest from 'utilities/actions/get/getPaymentLinkRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { getPaymentLinkSelector } from 'state-layer/selectors'
import { COPY } from 'constants/iconConstants'
import { FETCHING } from 'constants/reducerConstants'
import get from 'lodash/get'
import split from 'lodash/split'

import {
  COMPANY_PATH,
  PAYMENT_LINK_PATH,
  CREATE_PAYMENT_LINK_PATH,
} from 'constants/pathConstants'

import {
  AMOUNT,
  AMOUNT_TYPE,
  LINK_NAME,
  LINK_TYPE,
  LINK_URL,
  MAX_AMOUNT,
  MIN_AMOUNT,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')
  const isFetching = get(state, `paymentLinksR.${FETCHING}`)
  const paymentLinkId = get(props, 'params.paymentLinkId')
  const paymentLink = getPaymentLinkSelector(state, paymentLinkId)
  const paymentLinkPath = PAYMENT_LINK_PATH({ credentialId, paymentLinkId })
  const brandingPath = `${COMPANY_PATH({ credentialId })}?tab=branding`

  const {
    nickname,
    amountType,
    displayAmountType,
    linkType,
    displayTotalAmount,
    linkUrl = '',
    displayMinAmount,
    displayMaxAmount,
  } = paymentLink

  const isFixedAmount = amountType === 'FIXED'

  const amountData = isFixedAmount ? [
    {
      label: AMOUNT,
      value: displayTotalAmount,
    },
  ] : [
    {
      label: MIN_AMOUNT,
      value: displayMinAmount,
    },
    {
      label: MAX_AMOUNT,
      value: displayMaxAmount,
    },
  ]

  const paymentLinkData = [
    {
      label: LINK_TYPE,
      value: linkType,
    },
    {
      label: LINK_NAME,
      value: nickname,
    },
    {
      label: AMOUNT_TYPE,
      value: displayAmountType,
    },
    ...amountData,
    {
      label: LINK_URL,
      value: <><a href={linkUrl} target='blank'>{split(linkUrl, '?')[0]}</a> <i className={`far fa-${COPY} copy-icon`} onClick={() => copyToClipboard(linkUrl)} /></>,
    },
  ]

  return {
    credentials,
    isFetching,
    paymentLinkId,
    paymentLink,
    paymentLinkData,
    paymentLinkPath,
    linkUrl,
    brandingPath,
  }
}

const mapDispatchToProps = {
  getPaymentLink: getPaymentLinkRequest,
}

class PaymentLinkCreatedConfirmationC extends Component {
  componentDidMount() {
    const {
      credentials,
      paymentLinkId,
      getPaymentLink,
    } = this.props

    getPaymentLink({ paymentLinkId, credentials })
  }

  goToNewPaymentLinkForm = () => {
    const {
      credentials,
      paymentLink,
    } = this.props

    const merchantId = get(paymentLink, 'merchantId')
    const credentialId = get(credentials, 'id')

    goToPath({
      pathname: CREATE_PAYMENT_LINK_PATH({ credentialId }),
      queries: {
        merchantId,
      },
    })
  }

  render() {
    return (
      <PaymentLinkCreatedConfirmation
        {...this.props}
        goToNewPaymentLinkForm={this.goToNewPaymentLinkForm}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkCreatedConfirmationC)
