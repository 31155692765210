import './LiveAccountApplicationBankAccountInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import PlaidLinkC from 'components/Customer/Forms/MerchantOnboardingForms/IdentityBankAccountInfoWithPlaidLinkForm/PlaidLinkC'
import ErrorMessage from 'components/Customer/Shared/ErrorMessage/ErrorMessage'
import BankFieldset from 'components/Shared/Fieldsets/BankFieldset/BankFieldset'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import RequiredFieldNotice from 'components/Customer/Shared/RequiredFieldNotice/RequiredFieldNotice'
import submitLiveAccountApplicationBankAccountInfoForm from 'utilities/submit/submitLiveAccountApplicationBankAccountInfoForm'
import validateLiveAccountApplicationBankAccountInfoForm from 'utilities/validate/validateLiveAccountApplicationBankAccountInfoForm'
import { CHECK_CIRCLE_ICON } from 'constants/iconConstants'
import { LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM } from 'constants/formConstants'

import {
  checkingAccountLabelMap,
  businessCheckingAccountLabelMap,
} from 'constants/labelConstants'

import {
  BANK_ACCOUNT_INFO,
  SAVE_AND_CONTINUE,
  PREVIOUS_STEP,
  EXIT_APPLICATION_FORM,
  PLEASE_COMPLETE_STEP_MESSAGE,
  BANK_ACCOUNT_SUCCESSFULLY_LINKED_MSG,
  BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT,
  ADD_BANK_ACCOUNT_MANUALLY,
  INSTANTLY_LINK_BANK_ACCOUNT,
} from 'constants/language/languageConstants'

const LiveAccountApplicationBankAccountInfoForm = ({
  handleSubmit = () => {},
  showUploadFileModal = () => {},
  removeFile = () => {},
  bankDocumentName,
  bankDocumentErrorMsg = '',
  showExitApplicationFormModal,
  isFormInvalid = false,
  goToPreviousStep = () => {},
  dirty = false,
  accessFormCountry = '',
  showBankFieldset = false,
  plaidProcessorToken,
  accessFormId,
  plaidLinkAccountDataSection,
  showBankFields = () => {},
  plaidEnabled = false,
}) => {
  return (
    <form className='LiveAccountApplicationBankAccountInfoForm' onSubmit={handleSubmit(submitLiveAccountApplicationBankAccountInfoForm)}>
      <div className='form-header'>
        <h3>{BANK_ACCOUNT_INFO}</h3>
      </div>

      <RequiredFieldNotice />

      <div className='form-content'>
        { isFormInvalid && <ErrorMessage errorMessage={PLEASE_COMPLETE_STEP_MESSAGE} /> }

        { !showBankFieldset && plaidEnabled && (
          <>
            { plaidProcessorToken && (
              <div className='flex flex-start success-highlight-panel'>
                <i className={`fa fa-${CHECK_CIRCLE_ICON}`} />
                <div className='icon icon-checkmark' />

                <div className='label-2'>{BANK_ACCOUNT_SUCCESSFULLY_LINKED_MSG}
                  If you wish to change your selection, you can&nbsp;
                  <PlaidLinkC
                    id={accessFormId}
                    country={accessFormCountry}
                    entityType='ACCESS_FORM'
                    className='plaid-link'
                    style={{ border: 'none', padding: '0px', background: 'inherit' }}
                  >link a different bank account
                  </PlaidLinkC>&nbsp;
                  or <a className='text-link' onClick={showBankFields}>add a different bank account manually</a>.
                </div>
              </div>
            )}

            <h5>{businessCheckingAccountLabelMap(accessFormCountry)}</h5>

            <div className='plaid'>
              <div className='label-2 secondary'>
                Instantly link and verify your business {checkingAccountLabelMap(accessFormCountry)} account to let us know where your business should receive payouts.
                {BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT}
              </div>

              { plaidProcessorToken ? (
                <PageSectionV2C
                  className='plaid-linked-account-details'
                  columns={plaidLinkAccountDataSection}
                />
              ) : (
                <div className='buttons flex space-between items-center'>
                  <a className='text-link' onClick={showBankFields}>{ADD_BANK_ACCOUNT_MANUALLY}</a>

                  <PlaidLinkC
                    id={accessFormId}
                    country={accessFormCountry}
                    entityType='ACCESS_FORM'
                    style={{ border: 'none', padding: '0px', background: 'inherit' }}
                  >
                    <Button label={INSTANTLY_LINK_BANK_ACCOUNT} />
                  </PlaidLinkC>
                </div>
              )}
            </div>
          </>
        )}

        { showBankFieldset && (
          <>
            <h5>{businessCheckingAccountLabelMap(accessFormCountry)}</h5>

            <div className='label-2 secondary'>
              Provide your business {checkingAccountLabelMap(accessFormCountry)} account details to let us know and verify where your business should receive payouts.
              {BUSINESS_CHECKING_ACCOUNT_LINKING_HELPER_TEXT}
              { plaidEnabled && (
                <>
                  If you wish to change your selection, you can&nbsp;
                  <PlaidLinkC
                    id={accessFormId}
                    country={accessFormCountry}
                    entityType='ACCESS_FORM'
                    className='plaid-link'
                    style={{ border: 'none', padding: '0px', background: 'inherit' }}
                  >link a bank account instantly
                  </PlaidLinkC>.
                </>
              )}
            </div>

            <BankFieldset
              country={accessFormCountry}
              showUploadFileModal={showUploadFileModal}
              removeFile={removeFile}
              bankDocumentFileName={bankDocumentName}
              bankDocumentFileErrorMsg={bankDocumentErrorMsg}
              isFormInvalid={isFormInvalid}
              formName={LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM}
              isAccessForm
            />
          </>
        )}
      </div>

      <div className='form-footer'>
        <div className='footer-button-container'>
          <div className='previous-step-container'>
            <Button onClick={() => goToPreviousStep({ isFormDirty: dirty })} label={PREVIOUS_STEP} variant='tertiary' size='large' />
          </div>
          <div className='exit-and-save-container'>
            {showExitApplicationFormModal && <Button onClick={showExitApplicationFormModal} label={EXIT_APPLICATION_FORM} variant='ghost' size='large' />}
            <Button type='submit' label={SAVE_AND_CONTINUE} size='large' />
          </div>
        </div>
      </div>
    </form>
  )
}

LiveAccountApplicationBankAccountInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  showUploadFileModal: PropTypes.func,
  removeFile: PropTypes.func,
  bankDocumentName: PropTypes.string,
  bankDocumentErrorMsg: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  showExitApplicationFormModal: PropTypes.func,
  isFormInvalid: PropTypes.bool,
  goToPreviousStep: PropTypes.func,
  dirty: PropTypes.bool,
  accessFormCountry: PropTypes.string,
  showBankFieldset: PropTypes.bool,
  plaidProcessorToken: PropTypes.string,
  accessFormId: PropTypes.string,
  plaidLinkAccountDataSection: PropTypes.array,
  showBankFields: PropTypes.func,
  plaidEnabled: PropTypes.bool,
}

export default reduxForm({
  form: LIVE_ACCOUNT_APPLICATION_BANK_ACCOUNT_INFO_FORM,
  validate: validateLiveAccountApplicationBankAccountInfoForm,
})(LiveAccountApplicationBankAccountInfoForm)
