import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import DisputeTransferForm from './DisputeTransferForm'
import getMany from 'utilities/get/getMany'
import isFetching from 'utilities/is/isFetching'
import isPatching from 'utilities/is/isPatching'
import removeUndefined from 'utilities/remove/removeUndefined'
import getTransferRequest from 'utilities/actions/get/getTransferRequest'
import getDisputeRequest from 'utilities/actions/get/getDisputeRequest'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import formatCardBrandWithIcon from 'utilities/formatters/formatCardBrandWithIcon'
import convertPageSectionDataToV2 from 'utilities/convert/convertPageSectionDataToV2'
import { SETTLEMENT_PATH } from 'constants/pathConstants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  getDisputeSelector,
  getTransferWithRelatedSelector,
} from 'state-layer/selectors'

import {
  CARD_BRAND,
  SETTLEMENT,
  MERCHANT_ID,
  PAYMENT_DATE,
  MERCHANT_NAME,
  APPLICATION_ID,
  CREATE_DISPUTE,
  PAYMENT_AMOUNT,
  UPDATE_DISPUTE,
  CREATE_A_DISPUTE,
  APPLICATION_NAME,
} from 'constants/language/languageConstants'

const mapStateToProps = (state, props) => {
  const disputeId = get(props, 'params.disputeId')
  const dispute = getDisputeSelector(state, disputeId)
  const transferId = get(props, 'params.transferId') || get(dispute, 'transferDetails.id')
  const transfer = getTransferWithRelatedSelector(state, transferId)
  const credentials = getCurrentCredentials(state)
  const credentialId = get(credentials, 'id')

  const [
    applicationId,
    merchantId,
    merchantName,
    applicationName,
    settlementId,
    displayAmountWithCurrencyCode,
    displayCreatedAt,
    brand,
  ] = getMany(transfer, [
    'application.id',
    'merchantId',
    'merchantIdentity.displayBusinessName',
    'application.businessName',
    'settlementId',
    'displayAmountWithCurrencyCode',
    'displayCreatedAt',
    'paymentInstrument.brand',
  ])

  const paymentInstrumentClickToCopies = [
    {
      content: transferId,
    },
    {
      label: MERCHANT_ID,
      content: merchantId,
    },
    {
      label: APPLICATION_ID,
      content: applicationId,
    },
  ]

  const paymentDetailsDataSection = convertPageSectionDataToV2([
    {
      label: MERCHANT_NAME,
      value: merchantName,
    },
    {
      label: APPLICATION_NAME,
      value: applicationName,
    },
    {
      label: SETTLEMENT,
      value: settlementId ? <Link to={SETTLEMENT_PATH({ credentialId, settlementId })}>{settlementId}</Link> : undefined,
    },
    {
      label: PAYMENT_AMOUNT,
      value: displayAmountWithCurrencyCode,
    },
    {
      label: PAYMENT_DATE,
      value: displayCreatedAt,
    },
    {
      label: CARD_BRAND,
      value: formatCardBrandWithIcon({ brand }),
    },
  ], 1)

  const [
    amount,
    caseId,
    reasonCode,
    respondBy,
    disputeState,
    responseState,
    message,
    action,
  ] = getMany(dispute, [
    'amount',
    'caseId',
    'reasonCode',
    'respondBy',
    'state',
    'responseState',
    'message',
    'action',
  ])

  const initialValues = !isEmpty(dispute) ? removeUndefined({
    amount,
    caseId,
    reasonCode,
    respondBy: respondBy ? new Date(respondBy) : undefined,
    state: disputeState,
    responseState,
    message,
    action,
  }) : undefined

  return {
    dispute,
    transfer,
    transferId,
    disputeId,
    credentials,
    initialValues,
    paymentDetailsDataSection,
    paymentInstrumentClickToCopies,
    isPatching: isPatching(state),
    isFetchingTransfer: isFetching(state, 'transfersR'),
    headerLabel: disputeId ? UPDATE_DISPUTE : CREATE_A_DISPUTE,
    submitLabel: disputeId ? UPDATE_DISPUTE : CREATE_DISPUTE,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTransfer: ({ credentials, transferId }) => dispatch(getTransferRequest({ credentials, transferId })),
  getDispute: ({ credentials, disputeId }) => dispatch(getDisputeRequest({ credentials, disputeId })),
})

class DisputeTransferFormC extends Component {
  componentDidMount() {
    const {
      disputeId,
      transferId,
      getDispute,
      getTransfer,
      credentials,
    } = this.props

    if (transferId) {
      getTransfer({ credentials, transferId })
    }

    if (disputeId) {
      getDispute({ credentials, disputeId })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      transferId: prevTransferId,
    } = prevProps

    const {
      transferId,
      getTransfer,
      credentials,
    } = this.props

    if (transferId && transferId !== prevTransferId) {
      getTransfer({ credentials, transferId })
    }
  }

  render() {
    return (
      <DisputeTransferForm {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeTransferFormC)
