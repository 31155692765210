import './ModalS.scss'
import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { REMOVE_ICON } from 'constants/iconConstants'
import get from 'lodash/get'

const appElement = document.getElementById('root')

const Modal = ({
  modalProps = {},
  isOpen = false,
  closeModal = () => {},
  ModalComponent = () => {},
  className = '',
  overlayClassName = '',
}) => {
  const canCloseModal = get(modalProps, 'canCloseModal', true)

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={`Modal ${className}`}
      overlayClassName={`Overlay ${overlayClassName}`}
      appElement={appElement}
      shouldCloseOnOverlayClick={canCloseModal}
    >
      {canCloseModal && (
      <span
        role='button'
        tabIndex='0'
        aria-label='close dialog'
        className={`closeModal fa fa-${REMOVE_ICON}`}
        onClick={closeModal}
        onKeyDown={closeModal}
      />
      ) }

      <ModalComponent
        closeModal={closeModal}
        {...modalProps}
      />
    </ReactModal>
  )
}

Modal.propTypes = {
  modalProps: PropTypes.object,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  ModalComponent: PropTypes.func,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
}

export default Modal
