import { getState } from 'state-layer/configureStore'
import getCurrentUser from 'utilities/get/getCurrentUser'
import getCurrentPlatformName from 'utilities/get/getCurrentPlatformName'
import { getDashboardConfigBySubDomain } from 'state-layer/selectors/index'
import merge from 'lodash/merge'
import get from 'lodash/get'

const getCurrentDashboardConfig = (state = getState()) => {
  const currentUser = getCurrentUser(state)

  // get domain configurations
  const domainConfiguration = getDashboardConfigBySubDomain(state, getCurrentPlatformName()) || {}

  // get current dashboard config by id
  const dashboardConfigurationId = get(currentUser, 'customSettings.id')
  const dashboardConfiguration = get(state, `dashboardConfigurationsR.items.${dashboardConfigurationId}`, domainConfiguration)
  const isEnabled = get(dashboardConfiguration, 'enabled', false)

  return isEnabled ? merge({}, domainConfiguration, dashboardConfiguration) : domainConfiguration
}

export default getCurrentDashboardConfig
