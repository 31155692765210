import { GET_ACCESS_FORM_CONTRACT_F_REQUEST } from 'constants/flowConstants'
import createAction from 'utilities/actions/createAction'

const getAccessFormContractRequest = ({
  accessFormId,
  credentials,
}) => createAction({
  type: GET_ACCESS_FORM_CONTRACT_F_REQUEST,
  id: accessFormId,
  credentials,
})

export default getAccessFormContractRequest
