import './ResolveMonitoringAlertsFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Button from 'components/Shared/Button/Button'
import submitResolveMonitoringAlertsForm from 'utilities/submit/submitResolveMonitoringAlertsForm'
import { RESOLVE_MONITORING_ALERTS_FORM } from 'constants/formConstants'
import size from 'lodash/size'

import {
  TRANSACTION_MONITORING_RESOLVE_ONE_DESCRIPTION,
  TRANSACTION_MONITORING_RESOLVE_MULTIPLE_DESCRIPTION,
  RESOLVE,
  CANCEL,
} from 'constants/language/languageConstants'

const ResolveMonitoringAlertsForm = ({
  handleSubmit = () => {},
  closeModal = () => {},
  items = [],
  selectedItemsKey = '',
}) => {
  return (
    <form className='ResolveMonitoringAlertsForm' onSubmit={handleSubmit(submitResolveMonitoringAlertsForm)}>
      <div>
        <p>{size(items) === 1 ? TRANSACTION_MONITORING_RESOLVE_ONE_DESCRIPTION : TRANSACTION_MONITORING_RESOLVE_MULTIPLE_DESCRIPTION}</p>
      </div>

      <div className='buttons flex flex-end'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' label={RESOLVE} />
      </div>
    </form>
  )
}

ResolveMonitoringAlertsForm.propTypes = {
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  items: PropTypes.array,
}

export default reduxForm({ form: RESOLVE_MONITORING_ALERTS_FORM })(ResolveMonitoringAlertsForm)
