import './UnderwritingInformationS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import PageSectionV2C from 'components/Customer/Shared/Page/PageSection/PageSectionV2C'
import Loader from 'components/Shared/Loader/Loader'
import UnderwritingReviewStatus from 'components/Customer/Shared/Display/ColorcodedStatus/UnderwritingReviewStatus'
import EnabledStatus from 'components/Customer/Shared/Display/ColorcodedStatus/EnabledStatus'
import TooltipLabelC from 'components/Shared/TooltipLabel/TooltipLabelC'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  UNDERWRITING_INFORMATION,
  SUMMARY,
  RECOMMENDED_ACTION,
  OUTCOME_REASONS,
  IDENTITY_VERIFICATION_SCORE,
  COMPLIANCE_SCREENING_SCORE,
  IDENTITY_VERIFICATION_SCORE_TOOLTIP,
  COMPLIANCE_SCREENING_SCORE_TOOLTIP,
  AUTOMATED_UNDERWRITING,
  ACTIONS,
} from 'constants/language/languageConstants'

const UnderwritingInformation = ({
  underwritingDecisionActions = [],
  isFetching,
  identityScoreColor = '',
  identityScore,
  complianceScoreColor = '',
  complianceScore,
  isMerchantUnderwriting = false,
  automatedUnderwriting = '',
  recommendation = '',
  formattedReasons = [],
  dropdownActionsDisabled = false,
  dropdownActionsDisabledMessage = '',
  isRecipientUnderwriting = false,
  showSummaryVersion = false,
  summaryDataSection = [],
}) => {
  return (
    <div className='UnderwritingInformation'>
      {showSummaryVersion ? (
        <PageSectionV2C
          title={SUMMARY}
          isFetching={isFetching}
          dropdownActions={underwritingDecisionActions}
          dropdownActionsTitle={ACTIONS}
          dropdownActionsDisabled={dropdownActionsDisabled}
          dropdownActionsDisabledMessage={dropdownActionsDisabledMessage}
          columns={summaryDataSection}
        />
      ) : (
        <PageSectionV2C
          title={UNDERWRITING_INFORMATION}
          isFetching={isFetching}
          dropdownActions={underwritingDecisionActions}
          dropdownActionsTitle={ACTIONS}
          dropdownActionsDisabled={dropdownActionsDisabled}
          dropdownActionsDisabledMessage={dropdownActionsDisabledMessage}
        >
          { isFetching && <Loader /> }
          {!isFetching && (
            <>
              <div className='flex info-section'>
                <div className='summary-section'>
                  <h6>{SUMMARY}</h6>
                  {(isMerchantUnderwriting || isRecipientUnderwriting) && (
                  <div className='flex summary-data-point'>
                    <div className='key p-2'>{AUTOMATED_UNDERWRITING}</div>
                    <EnabledStatus value={automatedUnderwriting} />
                  </div>
                  )}

                  <div className='flex summary-data-point'>
                    <div className='key p-2'>{RECOMMENDED_ACTION}</div>
                    <UnderwritingReviewStatus value={recommendation} />
                  </div>
                </div>

                {!isRecipientUnderwriting && (
                <div className='identity-score'>
                  <div className='identity-score-header flex'>
                    <h6>{IDENTITY_VERIFICATION_SCORE}</h6>
                    <TooltipLabelC message={IDENTITY_VERIFICATION_SCORE_TOOLTIP} position='top' />
                  </div>
                  <div className={`identity-score-number bold ${identityScoreColor}`}>{identityScore}</div>
                </div>
                )}

                <div className='compliance-score'>
                  <div className='compliance-score-header flex'>
                    <h6>{COMPLIANCE_SCREENING_SCORE}</h6>
                    <TooltipLabelC message={COMPLIANCE_SCREENING_SCORE_TOOLTIP} position='top' />
                  </div>
                  <div className={`compliance-score-number bold ${complianceScoreColor}`}>{complianceScore}</div>
                </div>
              </div>

              {!isEmpty(formattedReasons) && (
              <div className='flex'>
                <div className='outcome-reasons flex-grow'>
                  <h6>{OUTCOME_REASONS}</h6>
                  <div className='outcome-reasons-list flex'>
                    {map(formattedReasons, ({ code, description }) => {
                      return (
                        <TooltipLabelC
                          showIcon={false}
                          message={description}
                          position='top-of-children'
                        >
                          <div className='reason'>{code}</div>
                        </TooltipLabelC>
                      )
                    })}
                  </div>
                </div>
              </div>
              )}
            </>
          )}
        </PageSectionV2C>
      )}
    </div>
  )
}

UnderwritingInformation.propTypes = {
  underwritingDecisionActions: PropTypes.array,
  isFetching: PropTypes.bool,
  identityScoreColor: PropTypes.string,
  complianceScoreColor: PropTypes.string,
  isMerchantUnderwriting: PropTypes.bool,
  automatedUnderwriting: PropTypes.string,
  recommendation: PropTypes.string,
  formattedReasons: PropTypes.array,
  dropdownActionsDisabled: PropTypes.bool,
  dropdownActionsDisabledMessage: PropTypes.string,
  isRecipientUnderwriting: PropTypes.bool,
  showSummaryVersion: PropTypes.bool,
  summaryDataSection: PropTypes.array,
}

export default UnderwritingInformation
