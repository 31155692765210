import './EditUserInfoFormS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import validateWelcomeNameCaptureForm from 'utilities/validate/validateWelcomeNameCaptureForm'
import submitWelcomeNameCaptureForm from 'utilities/submit/submitWelcomeNameCaptureForm'
import InputField from 'components/Shared/Inputs/InputField/InputField'
import Button from 'components/Shared/Button/Button'
import { EDIT_USER_INFO_FORM } from 'constants/formConstants'

import {
  CANCEL,
  FIRST_NAME,
  LAST_NAME,
  UPDATE,
  UPDATE_PERSONAL_INFO_MODAL_INSTRUCTIONS,
} from 'constants/language/languageConstants'

// TODO: We can reuse the WelcomeNameCaptureForm instead of this one, we just need to pass instructions through the container
const EditUserInfoForm = ({
  handleSubmit = () => {},
  pristine = false,
  closeModal = () => {},
}) => {
  return (
    <form className='EditUserInfoForm' onSubmit={handleSubmit(submitWelcomeNameCaptureForm)}>
      <br />
      <div className='instructions'>
        {UPDATE_PERSONAL_INFO_MODAL_INSTRUCTIONS}
      </div>

      <Field
        name='firstName'
        label={FIRST_NAME}
        component={InputField}
      />

      <Field
        name='lastName'
        label={LAST_NAME}
        component={InputField}
      />
      <br />
      <div className='buttons flex flex-end items-center'>
        <Button onClick={closeModal} label={CANCEL} variant='ghost' className='cancel-button' />
        <Button type='submit' disabled={pristine} label={UPDATE} />
      </div>
    </form>
  )
}

EditUserInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default reduxForm({
  form: EDIT_USER_INFO_FORM,
  validate: validateWelcomeNameCaptureForm,
})(EditUserInfoForm)
