import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import ConfirmSubmitNoteForm from './ConfirmSubmitNoteForm'
import hideModalAction from 'utilities/actions/hideModalAction'
import postNoteRequest from 'utilities/actions/post/postNoteRequest'
import patchNoteRequest from 'utilities/actions/patch/patchNoteRequest'
import getMany from 'utilities/get/getMany'
import { PUBLISHED } from 'constants/noteConstants'

const mapStateToProps = (state, props) => {
  const credentials = getCurrentCredentials(state)

  const [
    reviewId,
    firstName,
    lastName,
    email,
    jsonContent,
  ] = getMany(props, [
    'reviewId',
    'firstName',
    'lastName',
    'email',
    'jsonContent',
  ])

  return {
    credentials,
    reviewId,
    firstName,
    lastName,
    email,
    jsonContent,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideModalAction()),
    postNote: (props) => dispatch(postNoteRequest(props)),
    patchNote: (props) => dispatch(patchNoteRequest(props)),
  }
}

class ConfirmSubmitNoteFormC extends Component {
  submitNote = () => {
    const {
      postNote,
      patchNote,
      credentials,
      linkedTo,
      jsonContent,
      email,
      firstName,
      lastName,
      noteId,
      closeModal,
      successCallback,
    } = this.props

    const flashNotification = {
      success: {
        message: 'Note created successfully!',
      },
      error: {
        message: 'There was an error creating the note. Please try again.',
      },
    }

    if (noteId) {
      patchNote({
        id: noteId,
        status: PUBLISHED,
        jsonContent,
        credentials,
        successCallback: () => {
          successCallback()
          closeModal()
        },
        flashNotification,
      })
    } else {
      postNote({
        linkedTo,
        email,
        firstName,
        lastName,
        jsonContent,
        credentials,
        successCallback: () => {
          successCallback()
          closeModal()
        },
        flashNotification,
      })
    }
  }

  render() {
    return (
      <ConfirmSubmitNoteForm
        {...this.props}
        submitNote={this.submitNote}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSubmitNoteFormC)
