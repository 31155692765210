import paymentsAPI from 'utilities/api/paymentsAPI'
import { CERTIFICATE_SIGNING_REQUESTS } from 'constants/apiConstants'

const getCertificateSigningRequestsAPI = ({ credentials, queries, meta }) => {
  return paymentsAPI.get({
    meta,
    credentials,
    queries,
    path: CERTIFICATE_SIGNING_REQUESTS,
  })
}

export default getCertificateSigningRequestsAPI
