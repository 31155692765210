import { CLEAR_IDENTITY_UPLOADER_REDUCER_F_REQUEST } from 'constants/flowConstants'

const clearIdentityUploaderReducerRequest = () => {
  return {
    type: CLEAR_IDENTITY_UPLOADER_REDUCER_F_REQUEST,
    payload: {
      newValues: {
        totalCount: 0,
        totalSucceeded: 0,
        totalFailed: 0,
        export: {},
      },
    },
  }
}

export default clearIdentityUploaderReducerRequest
