import paymentsAPI from 'utilities/api/paymentsAPI'
import { MERCHANT_UNDERWRITING_SUBJECT_EVENT } from 'constants/apiConstants'
import get from 'lodash/get'

const getMerchantUnderwritingSubjectEventAPI = ({ id, credentials, meta }) => {
  const subjectId = get(meta, 'subjectId')

  return paymentsAPI.get({
    meta,
    path: MERCHANT_UNDERWRITING_SUBJECT_EVENT({ subjectId, eventId: id }),
    credentials,
  })
}

export default getMerchantUnderwritingSubjectEventAPI
