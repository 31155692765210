import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import removeUndefined from 'utilities/remove/removeUndefined'
import getMany from 'utilities/get/getMany'
import { PATCH_UNDERWRITING_PROFILE_F_REQUEST } from 'constants/flowConstants'

const submitEditMerchantOnboardingFormSettingsForm = (values, dispatch, props) => {
  const {
    underwritingProfile,
  } = props

  const [
    underwritingProfileId,
    achMaxTransactionAmount,
    maxTransactionAmount,
    allowAllMCCs,
    enabledMCCs,
    adverseMediaCadence,
    sanctionsScreeningCadence,
  ] = getMany(underwritingProfile, [
    'id',
    'achMaxTransactionAmount',
    'maxTransactionAmount',
    'mccDetails.allowAllMCCs',
    'mccDetails.enabledMCCs',
    'adverseMediaCadence',
    'sanctionsScreeningCadence',
  ])

  const [
    biometricDataCollection,
  ] = getMany(values, [
    'biometricDataCollection.value',
  ])

  // TODO: There is a bug in BE where we need to pass all data for this to work, including mcc_details and max transaction amounts
  // Once they have fixed the bug and it works like a patch we will only need to pass biometric_data_collection
  const newValues = {
    biometric_data_collection: biometricDataCollection,
    continuous_monitoring_details: {
      adverse_media_cadence: adverseMediaCadence,
      ofac_cadence: sanctionsScreeningCadence,
    },
    max_transaction_amount: maxTransactionAmount,
    ach_max_transaction_amount: achMaxTransactionAmount,
    mcc_details: {
      allow_all_mccs: allowAllMCCs,
      enabled_mccs: enabledMCCs,
    },
  }

  dispatch({
    type: PATCH_UNDERWRITING_PROFILE_F_REQUEST,
    payload: removeUndefined({
      id: underwritingProfileId,
      values: newValues,
      credentials: getCurrentCredentials(),
    }),
    meta: {
      closeModal: true,
    },
  })
}

export default submitEditMerchantOnboardingFormSettingsForm
