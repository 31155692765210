import './MembersS.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { getMembersSelector } from 'state-layer/selectors'
import CheckboxC from 'components/Shared/Inputs/Checkbox/CheckboxC'
import Loader from 'components/Shared/Loader/Loader'
import TableC from 'components/Shared/Table/TableC'
import { MEMBERS } from 'constants/linkConstants'
import { GET_MEMBERS } from 'constants/flowConstants'

const Members = ({
  flowValues = {},
  initialQueries = {},
  allowedFilters = [],
  memberPath = '',
  isFetching = false,
  entityDataFound = false,
  actions = [],
  quickFilters = [],
  columnDescriptors = [],
  mfaEnabled = false,
  showMfa = false,
  showChangeTeamMFAModal = () => {},
}) => {
  return (
    <div className='Members'>
      { !entityDataFound && <Loader />}

      { entityDataFound && (
        <>
          { showMfa && (
            <div className='mfa-container'>
              <div className='mfa flex'>
                <CheckboxC
                  name='mfaEnabled'
                  objectId='mfaEnabled'
                  checked={mfaEnabled}
                  handleChange={showChangeTeamMFAModal}
                />

                <div className='flex column'>
                  <span className='label-2'>Require multi-factor authentication for all members</span>
                  <span className='label-2 helper'>All members of your team will have to use multi-factor authentication to sign in.</span>
                </div>
              </div>
            </div>
          )}

          <TableC
            flow={GET_MEMBERS}
            flowValues={flowValues}
            extraProps={flowValues}
            columnDescriptors={columnDescriptors}
            selector={getMembersSelector}
            initialQueries={initialQueries}
            quickFilters={quickFilters}
            linksKey={MEMBERS}
            // allowedFilters={allowedFilters} // TODO: once API has these working add them in
            path={memberPath}
            isFetching={isFetching}
            actions={actions}
            fileNameCSV='Team_Members'
          />
        </>
      )}
    </div>
  )
}

Members.propTypes = {
  flowValues: PropTypes.object,
  initialQueries: PropTypes.object,
  allowedFilters: PropTypes.array,
  memberPath: PropTypes.string,
  isFetching: PropTypes.bool,
  entityDataFound: PropTypes.bool,
  actions: PropTypes.array,
  quickFilters: PropTypes.array,
  columnDescriptors: PropTypes.array,
  mfaEnabled: PropTypes.bool,
  showMfa: PropTypes.bool,
  showChangeTeamMFAModal: PropTypes.func,
}

export default Members
