import React, { Component } from 'react'
import { connect } from 'react-redux'
import FileViewer from './FileViewer'
import getCurrentCredentials from 'utilities/get/getCurrentCredentials'
import { FETCHING } from 'constants/reducerConstants'
import { UPLOADING } from 'constants/language/languageConstants'
import { PENDING } from 'constants/statusConstants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  GET_ACCESS_FORM_FILE_F_REQUEST,
  GET_FILE_F_REQUEST,
} from 'constants/flowConstants'

import {
  FILE_EXTENSION_TO_FILE_TYPE_MAP,
  TYPE_PDF,
  TYPE_TIFF,
  REQUIRES_UPLOAD,
} from 'constants/fileConstants'

const mapStateToProps = (state, props) => {
  const { id, extension } = props
  const isFetching = get(state, `filesR.${FETCHING}`, false)
  const credentials = getCurrentCredentials(state)
  const file = get(state, `filesR.items.${id}`)
  const src = get(file, 'externalLink.url')
  const fileExtension = get(file, 'extension', extension)
  const fileType = get(FILE_EXTENSION_TO_FILE_TYPE_MAP, fileExtension)
  const isPDF = fileType === TYPE_PDF
  const isTIFF = fileType === TYPE_TIFF
  const fileStatus = get(file, 'status')
  const pendingUpload = (fileStatus === REQUIRES_UPLOAD) || (fileStatus === PENDING)
  const displayStatusLabel = pendingUpload ? UPLOADING : fileStatus

  return {
    isFetching,
    credentials,
    src,
    fileType,
    isPDF,
    isTIFF,
    fileStatus,
    displayStatusLabel,
    pendingUpload,
    ...(isEmpty(file) ? {} : file),
  }
}

class FileViewerC extends Component {
  componentDidMount() {
    const {
      id,
      credentials,
      dispatch,
      accessFormId,
      isHostedAccessForm,
    } = this.props

    dispatch({
      type: accessFormId ? GET_ACCESS_FORM_FILE_F_REQUEST : GET_FILE_F_REQUEST,
      payload: accessFormId ? { id } : {
        id,
        credentials,
      },
      meta: {
        accessFormId,
        isHostedAccessForm,
      },
    })
  }

  render() {
    return (
      <FileViewer {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(FileViewerC)
