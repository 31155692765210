import toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'

// this utility is helpful for converting amounts into the necessary state that we to perform JS math on
const convertAmountStringToNumber = ({ amount = '' }) => {
  if (isNumber(amount) || isEmpty(amount)) {
    return amount
  }

  // get rid of commas
  const amountWithoutCommas = replace(amount, /,/g, '')
  return toNumber(amountWithoutCommas)
}

export default convertAmountStringToNumber
