import { fieldEmpty } from 'utilities/validate'

const validateEditComplianceFormTemplateForm = (values) => {
  const { name } = values

  return {
    name: fieldEmpty(name, 'Name'),
  }
}

export default validateEditComplianceFormTemplateForm
