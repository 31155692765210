import guestDashboardAPI from 'utilities/api/guestDashboardAPI'
import { SUBMIT_SALES_REVIEW_ACCESS_FORM } from 'constants/apiConstants'

const postSubmitAccessFormSalesReviewAPI = ({ values, meta }) => {
  const path = SUBMIT_SALES_REVIEW_ACCESS_FORM
  return guestDashboardAPI.post({
    path,
    values,
    meta,
  })
}

export default postSubmitAccessFormSalesReviewAPI
